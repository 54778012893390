import { Injectable } from '@angular/core';
import { catchError, of, tap } from 'rxjs';
import { ApiService } from '../../../core/base/api.service';
import { TlMobileSettingsStore } from './tl-mobile-settings.store';

@Injectable({
	providedIn: 'root',
})
export class TlMobileSettingsService {
	baseUrl = '/businesses/mobile';
	iosVersionEndpoint = '/iosappversion';
	constructor(
		private api: ApiService,
		private store: TlMobileSettingsStore,
	) {}

	getIosAppVersion() {
		const endpoint = this.baseUrl + this.iosVersionEndpoint;

		return this.api.get2<string>(endpoint).pipe(
			tap((x) => {
				this.store.setIosVersion(x);
			}),
			catchError(() => {
				return of({});
			}),
		);
	}

	updateIosAppVersion(iosVersion: string) {
		const endpoint = this.baseUrl + this.iosVersionEndpoint;

		const body = {
			newAppVersion: iosVersion,
		};

		return this.api.put<string>(endpoint, body).pipe(
			tap((x) => {
				if (x === 'Success') this.store.setIosVersion(iosVersion);
			}),
			catchError(() => {
				return of({});
			}),
		);
	}
}
