export enum ServicesCodes {
	LR = 'LR',
	Mortgage = 'M',
	FG = 'FG',
	KiwiSaver = 'K',
	AdviceProcess = 'AP',
	BlanketAdvice = 'B',
	ComplaintAdvice = 'C',
	Investment = 'I',
	Property = 'PA',
	Asset = 'A',
	Others = 'O',
	ClientReviewTemplate = 'CRT',
	MortgageOnlineAdviceTool = 'MOAT',
	KiwisaverOnlineAdviceTool = 'KOAT',
	CustomerPortalMOAT = 'CPMOAT',
	CustomerPortalMOATDocuments = 'CPMOATD',
	Security = 'S',
	CustomerPortal = 'CP',
	UserTracker = 'UT',
	Liability = 'L',
	ClientAlterationRequest = 'CAR',

	// EMAIL CLIENT
	CRTNotesAdviceProcess = 'ETAP',
	NotesLR = 'ETLR',
	NotesMortgage = 'ETM',
	NotesFG = 'ETFG',
	NotesInvestment = 'ETI',
	NotesKiwisaver = 'ETK',
	NotesClaims = 'ETC'
}
