import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PDGoalsSettingsState } from '../state/pd-goals.model';

export interface PDGoalsSettingsStoreState {
  pdGoalsSettings: PDGoalsSettingsState | any;
  pdGoalsSettingsLoading: boolean;
}

export function createInitialState(): PDGoalsSettingsStoreState {
  return {
    pdGoalsSettings: null,
    pdGoalsSettingsLoading: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'pdGoalsSettings' })
export class PDGoalsSettingsStore extends Store<PDGoalsSettingsStoreState> {
	constructor() {
		super(createInitialState());
	}

  setPDGoalsSettings(p: PDGoalsSettingsState) {
		this.update({ pdGoalsSettings: p });
	}

  setPDGoalsSettingsLoading(isLoading) {
		this.update({ pdGoalsSettingsLoading: isLoading });
	}
}