export interface Note {
  adviceProcessId: number;
	sectionCode: string;
	cRTId: number;
	parentCRTId: number;
	purposeId: number;
	clientBackgroundCharacterId: number;
	recommendationId: number;
	otherInformationId: number;
	noteContents?: NoteContentState;
}

export interface NoteContentState {
	purpose?: string;
	clientBackgroundCharacter?: string;
	recommendation?: string;
	otherInformation?: string;
}

export const DocumentTypes = {
	PURPOSE: 'purposeId',
	CLIENT_BACKGROUND_CHARACTER: 'clientBackgroundCharacterId',
	RECOMMENDATION: 'recommendationId',
	OTHER_INFORMATION: 'otherInformationId'
};
