import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, defer, of, throwError, zip } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import {
	ClientReferralEmailModalFormComponent,
} from './client-referral-email-modal-form/client-referral-email-modal-form.component';
import { ClientReferralSettingReferral } from '@modules/special-features/client-referral-setting/state/client-referral-setting.model';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { ClientReferralSettingService } from '@modules/special-features/client-referral-setting/state/client-referral-setting.service';
import { UserQuery } from '@domain/user/user.query';
import { ApiService } from '@core/base/api.service';
import { convertUtil, objectUtil } from '@util/util';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';

@Component({
	selector: 'app-client-referral-email-modal',
	template: '',
})
export class ClientReferralEmailModalComponent {
	@Input() documentInfo: {
		documentType: any;
		type: any;
		referenceId: number;
		customerId: number;
	};

	@Input() documnetBodyLoaderFn$: (
		data: ClientReferralSettingReferral
	) => Observable<[string, string]>;

	@Input() isSettings = false;

	@Input() allowDocumentLinking = false;

	@Input() showOnInit = false;

	@Input() subject: Subject<ClientReferralSettingReferral | null>;

	@Input() referral: ClientReferralSettingReferral;

	@Input() adviser: any;

	@Input() sendFn$?: (
		referral: ClientReferralSettingReferral
	) => Observable<ClientReferralSettingReferral>;

	@Input() mergeTags: MergeTagState[];

	bsModalRef: BsModalRef;

	constructor(
		private modalService: BsModalService,
		private blStaffsQuery: BLStaffsQuery,
		private clientReferralSettings: ClientReferralSettingService,
		private userQuery: UserQuery,
		private apiService: ApiService
	) {}

	ngOnInit(): void {
		if (this.showOnInit) {
			this.show(this.referral).pipe(take(1)).subscribe();
		}
	}

	private getStaffByEmail(email: string): Observable<BLStaff> {
		return this.apiService.get(`staff?email=${email}`);
	}

	show(
		data: ClientReferralSettingReferral,
		isSettings?: boolean
	): Observable<ClientReferralSettingReferral> {
		data.adviser = this.adviser;
		return defer(() => {
			const currentUser = this.userQuery.getValue();
			const subject =
				this.subject ?? new Subject<ClientReferralSettingReferral | null>();
			this.bsModalRef = this.modalService.show(
				ClientReferralEmailModalFormComponent,
				{
					id: 'client-referral-email-modal',
					ignoreBackdropClick: true,
					class: 'modal-dialog-centered modal-lg client-referral-email-modal',
					initialState: {
						currentUser,
						mergeTags: this.mergeTags || [],
						isSettings: isSettings ?? this.isSettings,
						allowDocumentLinking: this.allowDocumentLinking,
						documentInfo: this.documentInfo,
						loadDataFn$: () =>
							of(data).pipe(
								map((data) => {
									return data;
								})
							),
						documnetBodyLoaderFn$: this.documnetBodyLoaderFn$,
						saveFn$: (r: ClientReferralSettingReferral) => {
							return of(0).pipe(
								catchError((err) => {
									return throwError(err);
								}),
								mergeMap(() => {
									if (this.isSettings) {
										return of({ referral: r, staff: {} as any });
									}
									return this.getStaffByEmail(currentUser.EmailAddress).pipe(
										map((staff) => ({ referral: r, staff }))
									);
								}),
								map(({ referral, staff }) => {
									if (!referral || this.isSettings) {
										return referral;
									}
									const blindCarbonCopy = referral.blindCarbonCopy;
									referral.blindCarbonCopy = [
										{
											Email: staff.EmailAddress,
											FirstName: staff.FirstName,
											Name: `${staff.FirstName} ${staff.LastName}`,
										},
									];
									if (Boolean(blindCarbonCopy)) {
										referral.blindCarbonCopy.push({
											Email: blindCarbonCopy,
											FirstName: blindCarbonCopy,
											Name: blindCarbonCopy,
										});
									}

									if (Boolean(referral.carbonCopy)) {
										referral.carbonCopy = [
											{
												Email: referral.carbonCopy,
												FirstName: referral.carbonCopy,
												Name: referral.carbonCopy,
											},
										];
									} else {
										referral.carbonCopy = [];
									}

									if (Boolean(referral.emailDestinationName)) {
										referral.emailDestination = [
											{
												Email: referral.emailDestinationEmailAddress,
												Name: `${referral.emailDestinationFirstName} ${referral.emailDestinationLastName}`,
												FirstName: referral.emailDestinationFirstName,
											},
										];
									}

									return referral;
								}),
								mergeMap((referral) => {
									if (!referral) {
										return of(referral);
									}
									if (!referral?.attachmentFiles?.length) {
										// attachment property in settings is string of array while in CRM or OATs its array of attachment
										const attachmentDefaultValue = this.isSettings ? '' : [];
										referral.attachment = Boolean(referral?.attachment)
											? referral.attachment
											: (attachmentDefaultValue as any);
										return of(referral);
									}
									const obs = referral.attachmentFiles.map(
										(attachment: any) => {
											const doc =
												objectUtil.mapPascalCaseToCamelCase(attachment);
											const content = doc?.content ?? doc.document;
											const fileName = doc?.fileName ?? doc?.filename;
											const documentId = doc?.documentId ?? doc?.documentID;
											if (content) {
												return of({
													IsDeleted: doc.isDeleted,
													filename: fileName,
													disposition: 'attachment',
													content,
													documentId: documentId,
												});
											}
											return this.apiService
												.getExternalResourceAsBlob(doc.documentLink)
												.pipe(
													take(1),
													mergeMap((content) => {
														return convertUtil.blobToBase64(content);
													}),
													map((content) => ({
														IsDeleted: doc.isDeleted,
														documentId: documentId,
														content: content?.replace(/^data:(.*,)?/, ''),
														filename: fileName,
														disposition: 'attachment',
													}))
												);
										}
									);
									return zip(obs).pipe(
										take(1),
										map((attachments) => {
											if (this.isSettings) {
												// @ts-ignore-next
												referral.attachmentFiles = attachments;
											} else {
												// @ts-ignore-next
												referral.attachment = attachments;
												delete referral.attachmentFiles;
											}
											return referral;
										})
									);
								}),
								mergeMap((referral) =>
									this.sendFn$ && referral
										? this.sendFn$(referral)
										: of(referral)
								),
								tap((referral) => {
									subject.next(referral);
								})
							);
						},
						subject,
						users$: this.blStaffsQuery.activeStaffs$.pipe(
							map((users) =>
								users.map((user) => {
									return {
										display: `${user.FirstName} ${user.LastName}`,
										value: user.StaffID,
										email: user.EmailAddress,
										data: user,
									};
								})
							)
						),
						loadAttachmentFn$: (referral: ClientReferralSettingReferral) => {
							if (referral.attachmentFiles?.length) {
								return of(referral.attachmentFiles);
							}
							if (!referral.attachment?.length) {
								return of([]);
							}
							return this.clientReferralSettings.getAttachments(referral);
						},
					},
					show: true,
					keyboard: false,
				}
			);
			return subject.asObservable().pipe(tap(() => this.bsModalRef.hide()));
		});
	}
}
