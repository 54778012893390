import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { SharedModule } from '../../../shared/shared.module';
import { AssetsLiabilitiesComponent } from './assets-liabilities/assets-liabilities.component';
import { KiwisaverComponent } from './assets-liabilities/kiwisaver/kiwisaver.component';
import { LiabilitiesComponent } from './assets-liabilities/liabilities/liabilities.component';
import { MortgageComponent } from './assets-liabilities/mortgage/mortgage.component';
import { OtherAssetsInvestmentsComponent } from './assets-liabilities/other-assets-investments/other-assets-investments.component';
import { PropertyComponent } from './assets-liabilities/property/property.component';
import { ClientAcceptanceComponent } from './client-acceptance/client-acceptance.component';
import { ClientReviewTemplateProgressComponent } from './client-review-template-progress/client-review-template-progress.component';
import { ClientReviewTemplateRoutingModule } from './client-review-template-routing.module';
import { ClientReviewTemplateTabsComponent } from './client-review-template-tabs/client-review-template-tabs.component';
import { ClientReviewTemplateComponent } from './client-review-template.component';
import { ClientReviewTemplateResolver } from './client-review-template.resolver';
import { CurrentInsuranceComponent } from './current-insurance/current-insurance.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { FgInsuranceComponent } from './fg-insurance/fg-insurance.component';
import { FinalStructureComponent } from './final-structure/final-structure.component';
import { FamilyComponent } from './history/family/family.component';
import { GpComponent } from './history/gp/gp.component';
import { HistoryComponent } from './history/history.component';
import { MedicalComponent } from './history/medical/medical.component';
import { FullBudgetFormComponent } from './income-budget/full-budget-form/full-budget-form.component';
import { IncomeBudgetComponent } from './income-budget/income-budget.component';
import { IncomeSourceComponent } from './income-budget/income-source/income-source.component';
import { OtherIncomeComponent } from './income-budget/other-income/other-income.component';
import { RentalIncomeComponent } from './income-budget/rental-income/rental-income.component';
import { IntroComponent } from './intro/intro.component';
import { CompanyComponent } from './people/company/company.component';
import { DependentComponent } from './people/dependent/dependent.component';
import { PeopleComponent } from './people/people.component';
import { TrustComponent } from './people/trust/trust.component';
import { CriticalIllnessComponent } from './risk-analysis/critical-illness/critical-illness.component';
import { IllnessCalculationComponent } from './risk-analysis/critical-illness/illness-calculation/illness-calculation.component';
import { PeopleTabsComponent } from './risk-analysis/critical-illness/people-tabs/people-tabs.component';
import { DisabilityComponent } from './risk-analysis/disability/disability.component';
import { ClientGoalsComponent } from './risk-analysis/goals/client-goals/client-goals.component';
import { GoalsComponent } from './risk-analysis/goals/goals.component';
import { LongTermGoalsComponent } from './risk-analysis/goals/long-term-goals/long-term-goals.component';
import { RetireGoalComponent } from './risk-analysis/goals/retire-goal/retire-goal.component';
import { ShortTermGoalsComponent } from './risk-analysis/goals/short-term-goals/short-term-goals.component';
import { LifeComponent } from './risk-analysis/life/life.component';
import { RiskMedicalComponent } from './risk-analysis/medical/medical.component';
import { InsurancePreferenceComponent } from './risk-analysis/risk-profile/insurance-preference/insurance-preference.component';
import { InsurancePriorityComponent } from './risk-analysis/risk-profile/insurance-priority/insurance-priority.component';
import { RiskProfileComponent } from './risk-analysis/risk-profile/risk-profile.component';
import { TpdComponent } from './risk-analysis/tpd/tpd.component';
import { ScopeOfServicesComponent } from './scope-of-services/scope-of-services.component';
import { StatementOfAdviceIndexComponent } from './statement-of-advice/index/index.component';
import { StatementOfAdviceComponent } from './statement-of-advice/statement-of-advice.component';
import { SoaCurrentInsuranceComponent } from './statement-of-advice/subpage/current-insurance/current-insurance.component';
import { SoaProposedInsuranceComponent } from './statement-of-advice/subpage/proposed-insurance/proposed-insurance.component';
import { SoaScopeOfServiceComponent } from './statement-of-advice/subpage/scope-of-service/scope-of-service.component';
import { SoaReviewComponent } from './statement-of-advice/subpage/soa-review/soa-review.component';
import { SoaSubpageComponent } from './statement-of-advice/subpage/subpage.component';
import { AssetsLiabilitiesService } from './states/assets-liabilities/assets-liabilities.service';
import { ClientAcceptanceService } from './states/client-acceptance/client-acceptance.service';
import { ClientReviewTemplateQuery } from './states/client-review-template.query';
import { ClientReviewTemplateService } from './states/client-review-template.service';
import { ClientReviewTemplateStore } from './states/client-review-template.store';
import { CurrentInsuranceService } from './states/current-insurance/current-insurance.service';
import { DeclarationService } from './states/declaration/declaration.service';
import { DisclosureService } from './states/disclosure/disclosure.service';
import { FgService } from './states/fg-insurance/fg.service';
import { FinalStructureService } from './states/final-structure/final-structure.service';
import { HistoryService } from './states/history/history.service';
import { IncomeService } from './states/income-budget/income.service';
import { IntroService } from './states/intro/intro.service';
import { CrtNoteQuery } from './states/note/crt-note.query';
import { CrtNoteService } from './states/note/crt-note.service';
import { CrtNoteStore } from './states/note/crt-note.store';
import { PeopleService } from './states/people/people.service';
import { CriticalIllnessService } from './states/risk-analysis/critical-illness/critical-illness.service';
import { DisabilityQuery } from './states/risk-analysis/disability/disability.query';
import { DisabilityService } from './states/risk-analysis/disability/disability.service';
import { DisabilityStore } from './states/risk-analysis/disability/disability.store';
import { GoalsService } from './states/risk-analysis/goals/goals.service';
import { LifeService } from './states/risk-analysis/life/life.service';
import { RiskAnalysisMedicalService } from './states/risk-analysis/medical/medical.service';
import { RiskAnalysisService } from './states/risk-analysis/risk-analysis.service';
import { RiskProfileService } from './states/risk-analysis/risk-profile/risk-profile.service';
import { TpdService } from './states/risk-analysis/tpd/tpd.service';
import { ScopeOfServiceService } from './states/scope-of-service/scope-of-service.service';
import { StatementOfAdviceService } from './states/statement-of-advice/statement-of-advice.service';
import { LrAdviceprocessService } from './states/lr-adviceprocess/lr-adviceprocess.service';
import { CrtMergeTagsService } from './states/merge-tags/crt-merge-tags.service';
import { LoatDocumentService } from './states/document/loat-document.service';
import { StatementOfAdviceStore } from './states/statement-of-advice/statement-of-advice.store';
import { StatementOfAdviceQuery } from './states/statement-of-advice/statement-of-advice.query';
import { EmailService } from './states/email/email.service';
import { LifeGuard } from './guards/life.guard';
import { DisabilityGuard } from './guards/disability.guard';
import { CriticalIllnessGuard } from './guards/critical-illness.guard';
import { TpdGuard } from './guards/tpd.guard';
import { MedicalGuard } from './guards/medical.guard';
import { FinalStructureGuard } from './guards/final-structure.guard';
import { StatementOfAdviceGuard } from './guards/statement-of-advice.guard';
import { AuthToProceedGuard } from './guards/auth-to-proceed.guard';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { AdviceProcessEndedGuard } from './guards/advice-process-ended.guard';
import { SoaDocumentService } from './statement-of-advice/subpage/documents/state/soa-documents.service';
import { SoaDocumentStore } from './statement-of-advice/subpage/documents/state/soa-documents.store';
import { SoaDocumentQuery } from './statement-of-advice/subpage/documents/state/soa-documents.query';
import { SoaDocumentsComponent } from './statement-of-advice/subpage/documents/soa-documents.component';
import { ExistingPolicyStructureComponent } from './final-structure/existing-policy-structure/existing-policy-structure.component';
import { ExistingPolicyStructureService } from './states/existing-policy-structure/existing-policy-structure.service';
import { ExistingPolicyStructureQuery } from './states/existing-policy-structure/existing-policy-structure.query';
import { ExistingPolicyStructureStore } from './states/existing-policy-structure/existing-policy-structure.store';
import { IncomeBudgetGuard } from './income-budget/income-budget.guard';

const DragConfig = {
	zIndex: 10000
};
@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		RouterModule,
		ClientReviewTemplateRoutingModule,
		ChartistModule,
	],
	declarations: [
		ClientReviewTemplateComponent,
		ClientReviewTemplateTabsComponent,
		DisclosureComponent,
		ClientReviewTemplateProgressComponent,
		PeopleComponent,
		IncomeBudgetComponent,
		AssetsLiabilitiesComponent,
		FgInsuranceComponent,
		HistoryComponent,
		CurrentInsuranceComponent,
		DependentComponent,
		TrustComponent,
		CompanyComponent,
		IncomeSourceComponent,
		RentalIncomeComponent,
		OtherIncomeComponent,
		FullBudgetFormComponent,
		MedicalComponent,
		FamilyComponent,
		GpComponent,
		PropertyComponent,
		KiwisaverComponent,
		OtherAssetsInvestmentsComponent,
		MortgageComponent,
		LiabilitiesComponent,
		LifeComponent,
		GoalsComponent,
		ShortTermGoalsComponent,
		LongTermGoalsComponent,
		RetireGoalComponent,
		ClientGoalsComponent,
		DisabilityComponent,
		TpdComponent,
		CriticalIllnessComponent,
		IllnessCalculationComponent,
		PeopleTabsComponent,
		IntroComponent,
		RiskMedicalComponent,
		RiskProfileComponent,
		InsurancePreferenceComponent,
		InsurancePriorityComponent,
		ScopeOfServicesComponent,
		DeclarationComponent,
		StatementOfAdviceComponent,
		ClientAcceptanceComponent,
		FinalStructureComponent,
		StatementOfAdviceIndexComponent,
		SoaSubpageComponent,
		SoaScopeOfServiceComponent,
		SoaProposedInsuranceComponent,
		SoaCurrentInsuranceComponent,
		SoaReviewComponent,
		SoaDocumentsComponent,
		ExistingPolicyStructureComponent,
	],
	providers: [
		CurrencyPipe,
		ClientReviewTemplateResolver,

		ClientReviewTemplateService,
		ClientReviewTemplateStore,
		ClientReviewTemplateQuery,

		CrtNoteService,
		CrtNoteStore,
		CrtNoteQuery,

		PeopleService,
		AssetsLiabilitiesService,
		IncomeService,
		HistoryService,
		CurrentInsuranceService,
		FgService,

		GoalsService,
		LifeService,

		DisabilityService,
		DisabilityStore,
		DisabilityQuery,

		TpdService,
		CriticalIllnessService,
		RiskProfileService,
		RiskAnalysisService,
		RiskAnalysisMedicalService,

		CrtMergeTagsService,
		DisclosureService,
		ScopeOfServiceService,
		LoatDocumentService,

		LrAdviceprocessService,

		StatementOfAdviceService,
		StatementOfAdviceStore,
		StatementOfAdviceQuery,

		DeclarationService,
		IntroService,

		ClientAcceptanceService,
		FinalStructureService,
		EmailService,

		SoaDocumentService,
		SoaDocumentStore,
		SoaDocumentQuery,

		ExistingPolicyStructureService,
		ExistingPolicyStructureStore,
		ExistingPolicyStructureQuery,

		LifeGuard,
		DisabilityGuard,
		CriticalIllnessGuard,
		TpdGuard,
		MedicalGuard,
		FinalStructureGuard,
		StatementOfAdviceGuard,
		AuthToProceedGuard,
		AdviceProcessEndedGuard,
		IncomeBudgetGuard,
		{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }

		// CrtUtilService,
	],
})
export class ClientReviewTemplateModule {}
