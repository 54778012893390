<form 
	[formGroup]="form" 
	[class.submitted]="submitted" 
	[class.loatv2]="enableLoatV2$ | async" 
	novalidate>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2 mr-0 pr-0">
			<label for="" class="position-relative font-weight-bold">
				Owner
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2 ml-0 pl-0">
			<app-chips
				class="crt-form-control-field"
				formControlName="owner"
				[choices]="policyOwnerChoices(form.get('owner').value)"
				textboxClass="theme-crm-field"
				[attr.disabled]="viewMode || !!fromCRM"
			></app-chips>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Contribution Rate
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<input
				formControlName="contributionRate"
				id="kiwiSaverContributionRate"
				class="form-control crt-form-control"
				type="text"
			/>
		</div>
	</div>

	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2 mr-0 pr-0">
			<label for="" class="position-relative font-weight-bold">
				Provider
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2 ml-0 pl-0">
			<span class="w-100" [class.crt-custom-dropdown]="!fromCRM && !viewMode">
				<select
					formControlName="provider"
					id="kiwiSaverProvider"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of provider" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Current Balance
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<div class="dollar-icon">
				<input
					formControlName="currentBalance"
					class="form-control crt-form-control"
					id="kiwisaverCurrentBalance"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>

	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2 mr-0 pr-0">
			<label for="" class="position-relative font-weight-bold">
				Fund Type
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2 ml-0 pl-0">
			<ng-template #fundTypeChips>
				<app-chips
					id="fundType"
					textboxId="fundType"
					class="crt-form-control-field"
					formControlName="fundType"
					[choices]="fundType"
					textboxClass="theme-crm-field"
					[attr.disabled]="viewMode || !!fromCRM"
				></app-chips>
			</ng-template>
			<ng-container *ngIf="fromCRM; else fundTypeChips">
				<app-chips
					id="fundType"
					textboxId="fundType"
					class="crt-form-control-field"
					formControlName="fundType"
					[choices]="fundType"
					textboxClass="theme-crm-field"
					[attr.disabled]="viewMode || !!fromCRM"
				></app-chips>
			</ng-container>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Risk Profile
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!fromCRM && !viewMode">
				<select
					formControlName="riskProfile"
					id="kiwiSaverRiskProfile"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of riskProfile" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>

	<div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button
				id="closeButton"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
			>
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
				[disabled]="isLoading || isLoadingBtn"
				(click)="saveBtnClick()"
			>
				Save
				<i *ngIf="isLoading || isLoadingBtn" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
			>
				Cancel
			</button>
		</ng-template>
	</div>
</form>
