<div class="clearfix">
	<div
		class="form-row font-weight-bold d-none d-lg-flex mb-2"
		[class.tap-opacity-25]="(!mortgages || mortgages?.length === 0) && !isAdd"
	>
		<div class="col-12 col-lg-3">Lender</div>
		<div class="col-12 col-lg-3">Interest Rate</div>
		<div class="col-12 col-lg-3">Fixed Until</div>
		<div class="col-12 col-lg-3">Loan Value</div>
	</div>

	<ng-content select="app-manual-loader-mini"></ng-content>

	<div class="mb-4">
		<app-crt-mortgage-template-form
			*ngFor="
				let m of mortgages;
				let i = index;
				let last = last;
				trackBy: trackByFn
			"
			[formId]="i"
			[mortgage]="m"
			[borrowers$]="borrowers$"
			[securities$]="securities$"
			[MP$]="MP$"
			[MLT$]="MLT$"
			[APCRTF$]="APCRTF$"
			[addFn$]="addFn$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="d-block mb-2"
			[class.border-bottom]="!last"
			[getOwnerChoices]="getOwnerChoices"
			[crmMortgagesChoices]="crmMortgagesChoices"
		></app-crt-mortgage-template-form>
	</div>

	<div class="clearfix mb-4">
		<app-crt-mortgage-template-form
			*ngIf="isAdd"
			[addMode]="true"
			[borrowers$]="borrowers$"
			[securities$]="securities$"
			[crmMortgages]="crmMortgages"
			[crmMortgagesChoices]="crmMortgagesChoices"
			[MP$]="MP$"
			[MLT$]="MLT$"
			[APCRTF$]="APCRTF$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[addFn$]="addFn$"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="mb-2"
			[getOwnerChoices]="getOwnerChoices"
		></app-crt-mortgage-template-form>
	</div>

	<button
		id="mortgageAddButton"
		(click)="add()"
		type="button"
		[disabled]="isAdd"
		class="tap-btn tap-btn-outline tap-btn--shadow px-4"
	>
		Add Mortgage +
	</button>
</div>
