import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientReferralEmailModalComponent } from './client-referral-email-modal.component';
import { ClientReferralEmailModalFormComponent } from './client-referral-email-modal-form/client-referral-email-modal-form.component';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientReferralEmailModalFormAttachDocComponent } from './client-referral-email-modal-form-attach-doc/client-referral-email-modal-form-attach-doc.component';
import { LoaderMiniModule } from '@shared/components/loader-mini/loader-mini.module';

@NgModule({
	declarations: [
		ClientReferralEmailModalComponent,
		ClientReferralEmailModalFormComponent,
		ClientReferralEmailModalFormAttachDocComponent,
	],
	exports: [ClientReferralEmailModalComponent, ClientReferralEmailModalFormAttachDocComponent],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		LoaderMiniModule,
	],
})
export class ClientReferralEmailModalModule {}
