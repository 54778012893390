import { viewSecGroup } from './viewSecGroup';
import { viewStaff } from './viewStaff';
import { BlStaffViewmodel } from './bl-staff.viewmodel';

export default class UserViewModel {
  /**
   *
   */
  constructor(
    public secGroup: viewSecGroup,
    public activeStaffs: (viewStaff | BlStaffViewmodel)[],
    public inactiveStaffs: (viewStaff | BlStaffViewmodel)[],
    public showAll: boolean,
    public collapse: boolean
  ) { }
}
