import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	AfterViewInit,
	OnChanges,
	ViewChild,
	SimpleChanges,
	ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { NoWhitespaceValidator } from '../../../../../shared/directive/no-whitespace/no-whitespace.directive';
import { Subject, Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { ClientSearchControlComponent } from '../../../../../shared/search-controls/client-search-control/client-search-control.component';
import { computeAgeBasedOnBirthdate, strUtil } from '../../../../../util/util';
import * as R from 'ramda';
import { PrimaryClientState } from '../../../../../shared/models/client-profile/primary-client/primary-client.model';
import { ClientProfilePrimaryMapper } from '../../../../../shared/models/client-profile/primary-client/primary-client.mapper';
import { AddPhotoRequest } from '../../../../../core/customer/customer.service';
import { NoteState } from '../../../../../shared/models/notes/note.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { TablePaginateNotesComponent } from '../../../../../shared/table-paginate-notes/table-paginate-notes.component';
import MomentUtil from '../../../../../util/moment.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from 'src/app/shared/date-input/date-input.component';
import {
	CustomerTypes,
	GetGenderViewDisplay,
	RelationshipTypes,
} from 'src/app/shared/models/_general/client.model';
import { Fields, getInvalidWarning, getRequiredWarning } from 'src/app/shared/error-message/error-message';
import { BsModalService } from 'ngx-bootstrap/modal';
import { InfoModalComponent } from '@shared/modal/info-modal/info-modal.component';
import { prop, uniqBy } from 'ramda';
import { BusinessConfig } from '@domain/business-config/business-config.model';
import { omit } from 'ramda';
import { EmailDuplicateService } from '@core/services/email-duplicate.service';

@Component({
	selector: 'app-form-person',
	templateUrl: './form-person.component.html',
	styleUrls: ['./form-person.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPersonComponent
	implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	@Input() formID: any;
	@Input() isLead: boolean;
	@Input() addMode: boolean;
	@Input() data: PrimaryClientState;
	@Input() isAddNew: boolean;
	@Input() disableDelete: boolean;
	@Input() title: ViewDisplayValue[];
	@Input() employment: ViewDisplayValue[];
	@Input() preferredContact: ViewDisplayValue[];
	@Input() relationship: ViewDisplayValue[];
	@Input() leadTypeChoices: ViewDisplayValue[];
	@Input() leadOriginChoices: ViewDisplayValue[];
	@Input() leadGenChoices: ViewDisplayValue[];
	@Input() adviserGenChoices: ViewDisplayValue[];
	@Input() allStaffChoices: ViewDisplayValue[];
	@Input() professionalContacts: ViewDisplayValue[];
	@Input() industry: ViewDisplayValue[];
	@Input() businessConfig$: Observable<BusinessConfig>;

	@Input() addPhoto$: (req: AddPhotoRequest) => Observable<any>;
	// Notes
	@Input() getNotesFn$: (req: any) => Observable<any>;
	@Input() addNotesFn$: (req: any) => Observable<any>;
	@Input() deleteNotesFn$: (req: any) => Observable<any>;

	gender: ViewDisplayValue[] = GetGenderViewDisplay();
	allAdviserGenChoices: ViewDisplayValue[] = [];
	refreshNotes$ = new BehaviorSubject<any>(null);

	private _isSaving = false;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	isSaving$ = new ReplaySubject<boolean>(1);

	private _isEdit = false;
	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() cancelEvent = new EventEmitter<boolean>();
	@Output() saveEvent = new EventEmitter<any>();

	@ViewChild(ClientSearchControlComponent)
	referredInput: ClientSearchControlComponent;
	@ViewChild(TablePaginateNotesComponent)
	noteTable: TablePaginateNotesComponent;
	@ViewChild('dateOfBirthDateInput') dateOfBirthDateInput: DateInputComponent;

	form: UntypedFormGroup = this.fb.group({
		title: [''],
		firstName: [null, [Validators.required, NoWhitespaceValidator]],
		lastName: [null, [Validators.required, NoWhitespaceValidator]],

		middleName: [''],
		dateOfBirth: [''],
		gender: [''],

		knownAs: [''],
		relationship: ['Primary'],

		employment: [''],
		occupation: [''],
		income: [null],

		preferredContact: [''],
		mobile: [''],
		homePhone: [''],
		work: [''],

		email: [''],
		alternateEmail: [''],

		mailingAddress1: [''],
		mailingAddress2: [''],
		city: [''],
		postcode: [''],

		physicalAddress: [''],

		leadType: [''],
		leadOrigin: [''],
		leadGen: [''],
		adviserGen: [''],
		referredBy: [''],

		customerType: [CustomerTypes.PrimaryCustomerIndividual],
		note: [''],
		professionalContacts: [''],
		industry: [''],
		employer: [''],
		countryOfOrigin: [''],
		isVulnerable: false,
		age: [0],
	});

	notes: any;

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService,
		private emailDupService: EmailDuplicateService,
		private modalService: BsModalService
	) {}

	get firstName() {
		return this.form.get('firstName');
	}

	get lastName() {
		return this.form.get('lastName');
	}

	get age() {
		return this.form.get('age');
	}

	get isKeyContact() {
		// Adviser Rework Field
		return this.form.get('isKeyContact');
	}

	get vulnerableNotes() {
		// Adviser Rework Field
		return this.form.get('vulnerableNotes');
	}

	ngOnInit() {
		this.setAdviserGenChoices();
		this.patchDefaultValues();
		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x || this.addMode ? this.form.enable() : this.form.disable();
		});
		this.isSaving$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isSaving = x;
		});
	}

	ngOnChanges(changes?: SimpleChanges) {
		this.relationship = [
			{ display: 'Primary', value: 'Primary' },
			...this.relationship,
		];
		if (this.addMode) {
			this.isEdit$.next(true);
			this.form.reset({
				...this.form.value,
				customerType: CustomerTypes.PrimaryCustomerIndividual,
				relationship: RelationshipTypes.Primary,
			});
		} else {
			// if (!!changes.data && !!changes.data.currentValue) {
			if (this.data) {
				const data = this.data
					? Object.assign(
							new ClientProfilePrimaryMapper(),
							ClientProfilePrimaryMapper.mapToView(this.data)
					  )
					: Object.assign(new ClientProfilePrimaryMapper(), this.form.value);
				if (
					changes.data &&
					changes.data.currentValue.customerID !==
					changes.data.previousValue?.customerID
				) {
					this.referredInput?.searchBox.clear();
				}
				this.form.reset({
					...data,
					customerType: CustomerTypes.PrimaryCustomerIndividual,
					relationship: RelationshipTypes.Primary,
				});

				if (
					this.isEdit && changes.data && changes.data.currentValue &&
					changes.data.currentValue.photoURL !==
						changes.data.previousValue?.photoURL
				) {
					this.isEdit$.next(true);
				} else {
					this.isEdit$.next(false);
				}
				this.refreshNotes$.next(changes);
			}
		}
		this.computeAge();
		this.setAdviserGenChoices();
		this.prepAdviserRework();
	}

	setAdviserGenChoices() {
		const adviserGenId = this.form.getRawValue().adviserGen;
		const isAdviserGenOnDropdown = this.adviserGenChoices?.find(
			(x) => +x?.value === +adviserGenId
		);
		if (!!isAdviserGenOnDropdown) {
			this.allAdviserGenChoices = uniqBy(prop('value'), [
				...this.adviserGenChoices,
			]) as ViewDisplayValue[];
			return;
		}
		const getAdviserGen = this.allStaffChoices?.find(
			(x) => +x?.value === +adviserGenId
		);
		const list = [...this.adviserGenChoices, getAdviserGen]
			?.filter(Boolean)
			?.sort((a, b) => a.display?.localeCompare(b.display));

		this.allAdviserGenChoices = uniqBy(prop('value'), [
			...list,
		]) as ViewDisplayValue[];
	}

	addNotes$ = (note) => {
		return this.addNotesFn$({
			customerID: note.customerID,
			customerServiceID: note.customerServiceID,
			notes: note.notes,
		});
	};

	deleteNotes$ = (note: NoteState) => {
		return this.deleteNotesFn$(note);
	};

	getNotes$ = (req: GetNotes) => {
		return this.getNotesFn$(req).pipe(
			tap((x) => {
				this.notes = x;
			})
		);
	};

	ngAfterViewInit() {}

	patchDefaultValues() {
		if (this.addMode) {
			const findDefault = R.find<ViewDisplayValue>((x) => x.isDefault);
			const getValue = (v: ViewDisplayValue): string =>
				R.propOr('', 'value', v);
			const mapToDefaultValues = R.pipe(R.map(findDefault), R.map(getValue));
			const defaults = mapToDefaultValues([
				this.title,
				this.employment,
				this.preferredContact,
				this.leadOriginChoices,
				this.leadTypeChoices,
			]);
			this.setDropdownDefaults(defaults);
		}
		this.computeAge();
	}

	private setDropdownDefaults = ([
		title,
		employment,
		preferredContact,
		leadOrigin,
		leadType,
	]: string[]): void => {
		// tslint:disable-next-line: no-use-before-declare
		patchValue<PrimaryClientState>(this.form, {
			title,
			employment,
			preferredContact,
			leadOrigin,
			leadType,
		});
	};

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	edit() {
		this.isEdit$.next(true);
	}

	prepareFormValue() {
		const form = R.omit(['age'], this.form.value);

		return {
			...form,
			firstName: strUtil.safeTrimExtraSpace(form.firstName),
			lastName: strUtil.safeTrimExtraSpace(form.lastName),
			middleName: strUtil.safeTrimExtraSpace(form.middleName),
			dateOfBirth: MomentUtil.formatToServerDate(form.dateOfBirth),
			knownAs: strUtil.safeTrimExtraSpace(form.knownAs),
			mailingAddress1: strUtil.safeTrimExtraSpace(form.mailingAddress1),
			mailingAddress2: strUtil.safeTrimExtraSpace(form.mailingAddress2),
			city: strUtil.safeTrimExtraSpace(form.city),
			postcode: strUtil.safeTrimExtraSpace(form.postcode),
			occupation: strUtil.safeTrimExtraSpace(form.occupation),
			physicalAddress: strUtil.safeTrimExtraSpace(form.physicalAddress),

			email: strUtil.removeSpace(form.email),
			physicalEmail: strUtil.removeSpace(form.physicalEmail),
			homePhone: strUtil.removeSpace(form.homePhone),
			work: strUtil.removeSpace(form.work),
			mobile: strUtil.removeSpace(form.mobile),
			alternateEmail: strUtil.removeSpace(form.alternateEmail),
			income: +form.income,
			countryOfOrigin: strUtil.safeTrimExtraSpace(form.countryOfOrigin),
			// tslint:disable-next-line: max-line-length
			professionalContacts:
				form.professionalContacts && form.professionalContacts.length > 0
					? JSON.stringify(form.professionalContacts)
					: null,
		};
	}

	prepAdviserRework() {
		this.businessConfig$
			.pipe(
				map((config) => config?.AdviserRework),
				tap((isEnabled) => {
					if (isEnabled) {
						if (!this.form?.controls?.isKeyContact) {
							this.form.addControl(
								'isKeyContact',
								this.fb.control(false)
							);
						}
						if (!this.form?.controls?.vulnerableNotes) {
							this.form.addControl('vulnerableNotes', this.fb.control(''));
						}
						const customerId = +this.data?.customerID;
						const keyContact = +this.data?.preferredEmailContact;
						this.isKeyContact.setValue(customerId === keyContact);
						this.vulnerableNotes.setValue(this.data?.vulnerableNotes || '');
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save() {
		if (
			!this.form.valid ||
			this.prepareFormValue().firstName?.trim() === '' ||
			this.prepareFormValue().lastName?.trim() === '' ||
			this.dateOfBirthDateInput?.isInvalid() ||
			isNaN(this.prepareFormValue().income)
		) {
			if (
				this.prepareFormValue().firstName?.trim() === '' ||
				!this.prepareFormValue().firstName
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.FirstName));
				return;
			}
			if (
				this.prepareFormValue().lastName?.trim() === '' ||
				!this.prepareFormValue().lastName
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.LastName));
				return;
			}
			if (this.dateOfBirthDateInput?.isInvalid()) {
				this.loggerService.Log({}, getInvalidWarning(Fields.DateOfBirth));
				return;
			}
			if (isNaN(this.prepareFormValue().income)) {
				this.loggerService.Log({}, getInvalidWarning(Fields.Income));
				return;
			}
		}

		if (this.form.value.preferredContact === 'Deceased') {
			this.modalService.show(InfoModalComponent, {
				class: 'modal-dialog-centered',
				initialState: {
					header: 'Saving not valid',
					message: 'Please swap this client with a secondary client in the CRM to mark as deceased.',
					hasOkay: true
				},
				ignoreBackdropClick: true,
			});
			return;
		}

		const formValue = omit(['preferredEmailContact'], {
			...this.data,
			...this.prepareFormValue()
		});
			this.saveEvent.emit(formValue);
	}

	cancel() {
		if (!this.addMode) {
			const data = this.data
				? Object.assign(
						new ClientProfilePrimaryMapper(),
						ClientProfilePrimaryMapper.mapToView(this.data)
				  )
				: Object.assign(new ClientProfilePrimaryMapper(), this.form.value);

			if (!data?.dateOfBirth._i) {
				this.dateOfBirthDateInput.reset();
			}
			this.form.reset({
				...data,
				professionalContacts: data?.professionalContacts || [],
				customerType: CustomerTypes.PrimaryCustomerIndividual,
				relationship: RelationshipTypes.Primary,
			});
			this.prepAdviserRework();
		}
		this.computeAge();
		this.isEdit$.next(false);
		this.cancelEvent.emit(true);
	}

	computeAge() {
		const dob = this.form.get('dateOfBirth')?.value;
		const age = computeAgeBasedOnBirthdate(dob);
		this.age.setValue(age);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

const patchValue: <T>(form: UntypedFormGroup, partialViewModel: Partial<T>) => void = (
	form,
	partialViewModel
) => {
	const FormValueToPatch = Object.keys(partialViewModel)?.reduce((acc, v) => {
		const notEmpty = !['', null, undefined]?.some((x) => x === v);
		if (notEmpty) {
			const prop = { [v]: partialViewModel[v] };
			Object.assign(acc, prop);
		}
		return acc;
	}, {});
	form.patchValue(FormValueToPatch);
};
