import { Moment } from 'moment';
import {
  ActivityModel,
  PutActivityParam,
  PostActivityParam,
  GetActivityParam,
  ActivityDelete,
  ActivityComplete
} from './activity.model';
import { CustomerSearchModel } from './customerSearchModel';
import MomentUtil from '../../../util/moment.util';
import { util } from '../../../core/util/util.service';

export class ActivityViewModel {
  public ActivityId: number;
  public CreatedByStaffId?: number;
  public ActivityType: string;
  public DueDate: Moment;
  public DueTime: string;
  public ActivityName: string;
  public Details: string;
  public IsCompleted: boolean;
  public IsCancelled: boolean;
  public Adviser?: number;
  public Appointment: boolean;
  public AssignedToAdviserName: string;
  public CreatedByStaffName: string;
  public Customer: CustomerSearchModel;
  public CreatedByName?: string; // For Pending

  public Location: string;
  public Duration: number;
  public IsActive: boolean;

  public Meeting: string;

  static MapToViewModel(m: ActivityModel, isCompany: boolean = false): ActivityViewModel {
    return Object.assign(new ActivityViewModel(), {
      ActivityId: m.ActivityId,
      CreatedByStaffId: m.CreatedByStaffId,
      ActivityType: m.ActivityType,
      DueDate: util.DateStringToMomentLoose(m.DueDate),
      DueTime: m.DueTime,
      ActivityName: m.ActivityName,
      Details: m.Details,
      IsCompleted: m.IsCompleted,
      IsCancelled: m.IsCancelled,
      Adviser: m.Adviser,
      Appointment: m.Appointment,
      AssignedToAdviserName: m.AssignedToAdviserName,
      CreatedByStaffName: m.CreatedByStaffName,
			CreatedByName: m.CreatedByName, // For Pending
      Customer: m.Client,
      Location: m.Location,
      Duration: m.Duration,
      IsActive: m.IsActive,
      Meeting: m.Meeting
    } as ActivityViewModel);
  }

  static MapToModel(vm: ActivityViewModel): ActivityModel {
    return {
      ActivityId: vm.ActivityId,
      ActivityType: vm.ActivityType,
      ActivityName: vm.ActivityName,
      Adviser: vm.Adviser,
      AssignedToAdviserName: vm.AssignedToAdviserName,
      CreatedByStaffId: vm.CreatedByStaffId,
      DueDate: MomentUtil.formatToServerDate(vm.DueDate),
      DueTime: vm.DueTime,
      Details: vm.Details,
      IsCompleted: vm.IsCompleted,
      IsCancelled: vm.IsCancelled,
      Appointment: vm.Appointment,
      CreatedByStaffName: vm.CreatedByStaffName,
      Client: vm.Customer,
      Location: vm.Location,
      Duration: vm.Duration,
      IsActive: vm.IsActive,
      Meeting: vm.Meeting,
      CreatedByName: vm.CreatedByName, // For Pending
    };
  }

  static MapToGet(adviserIds: number[]): GetActivityParam {
    return {
      Advisers: adviserIds
    };
  }
  static MapToDelete(v: ActivityViewModel): ActivityDelete {
    return {
      activityId: v.ActivityId
    };
  }
  static MapToComplete(v: ActivityViewModel): ActivityComplete {
    return {
      activityId: v.ActivityId
    };
  }

  static MapToAdd(v: ActivityViewModel): PostActivityParam {
    return {
      ActivityName: v.ActivityName,
      ActivityType: v.ActivityType,
      Adviser: v.Adviser,
      Location: v.Location,
      Appointment: v.Appointment,
      CustomerId: v.Customer && v.Customer.CustomerId ? v.Customer.CustomerId : null,
      CustomerName: v.Customer && v.Customer.Name ? v.Customer.Name : '',
      Details: v.Details,
      DueDate: util.MomentToDateString(v.DueDate),
      DueTime: v.DueTime,
      Duration: v.Duration,
      IsCompleted: v.IsCompleted,
      IsCancelled: v.IsCancelled,
      IsActive: v.IsActive ? v.IsActive : true,
      Meeting: v?.Meeting
    };
  }

  static MapToQuickAdd(v: ActivityViewModel): PostActivityParam {
    return {
      ActivityName: v?.ActivityName,
      ActivityType: v?.ActivityType,
      Adviser: v?.Adviser,
      Location: v?.Location,
      Appointment: v?.Appointment,
      CustomerId: v?.Customer && v?.Customer?.CustomerId ? v?.Customer?.CustomerId : null,
      CustomerName: v?.Customer && v?.Customer?.Name ? v?.Customer?.Name : '',
      Details: v?.Details,
      DueDate: !!v?.DueDate ? util?.MomentToDateString(v?.DueDate) : null,
      DueTime: v?.DueTime,
      Duration: v?.Duration,
      IsCompleted: v?.IsCompleted,
      IsActive: v?.IsActive ? v?.IsActive : true,
      Meeting: v?.Meeting
    };
  }

  static MapToEdit(v: ActivityViewModel): PutActivityParam {
    const param = this.MapToAdd(v) as PutActivityParam;
    param.ActivityId = v.ActivityId;
    return param;
  }

  static CloneViewModel(v: ActivityViewModel): ActivityViewModel {
    if (v) {
      return Object.assign({}, v, {
        DueDate: util.isValidMoment(v.DueDate)
          ? v.DueDate.clone()
          : util.emptyMoment(),
        Customer: v.Customer ? Object.assign({}, v.Customer) : null
      } as Pick<ActivityViewModel, 'DueDate' | 'Customer'>);
    } else { return null; }
  }

  static CreateInitial = (): ActivityViewModel => ({
    ActivityId: null,
    CreatedByStaffId: null,
    ActivityType: '',
    DueDate: null,
    DueTime: '',
    ActivityName: '',
    Details: '',
    IsCompleted: false,
    IsCancelled: false,
    Adviser: null,
    Appointment: false,
    AssignedToAdviserName: '',
    CreatedByStaffName: '',
    Customer: null,
    Location: '',
    Duration: null,
    IsActive: true,
    Meeting: null
  })

  static CreateInitialWithClient = (customerSearchValue: CustomerSearchModel): ActivityViewModel =>
    Object.assign(
      ActivityViewModel.CreateInitial(),
      {
        Customer: customerSearchValue
      }
    )
}
