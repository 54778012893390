<div class="app-business-container">
    <tabset #staticTabs>
      <!-- Report Form -->
      <tab heading="Reports" id="reports"  customClass="mx-0">
        <form novalidate [formGroup]="form">
            <div class="container-fluid px-4 py-5">
              <div class="row d-flex justify-content-center">
                <div class="col-lg-6 px-0 py-2">
                  <div class="form-row align-items-center p-2">
                    <div class="col-lg-6">
                      <div class="form-row align-items-center">
                        <div class="col-12">
                          <label for=""><strong>Report Dates</strong></label>
                        </div>
                        <div class="col-12 d-flex">
                          <app-date-input class="mr-lg-2" formControlName="DateMin" textboxClass="theme-crm-field-disabled" placeholder="Min"
                            style="flex:1;"></app-date-input>
                          <app-date-input formControlName="DateMax" textboxClass="theme-crm-field-disabled" placeholder="Max"
                            style="flex:1;"></app-date-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-row align-items-center">
                        <div class="col-12">
                          <label for=""><strong>Report Type</strong></label>
                        </div>
                        <div class="col-12">
                          <select formControlName="ReportType" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let reportType of reportTypes" [value]="reportType.value">{{reportType.display}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto px-0 py-2 d-flex align-items-center">
                  <div class="form-row align-items-center py-4">
                      <ng-container  >
                      <div class="col-auto">
                        <button type="button" class="btn btn btn-primary btn-export-report" [disabled]="form.invalid || (isExporting$ | async)"
                          (click)="export()">
                          Export
                        </button>
                      </div>
                    </ng-container>
                    <div class="col-auto">
                      <app-manual-loader-mini [isLoading]="(isExporting$ | async)">
                          </app-manual-loader-mini>
                    </div>
                  </div>
        
                </div>
              </div>
            </div>
          </form>
      </tab>
  
    </tabset>
  
  </div>