import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	CrtKiwiSaverState,
	CrtKiwiSaverStore,
} from '../../state/crt-kiwisaver.store';

@Injectable()
export class MergeTagsQuery extends Query<CrtKiwiSaverState> {
	constructor(protected store: CrtKiwiSaverStore) {
		super(store);
	}

	/**
	 * @returns Observable list of Merge Tags
	 */
	mergeTags$ = this.select((state) => state.mergeTags);
}
