import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { applyTransaction } from '@datorama/akita';
import { AdviceProcessSectionCodes } from '@shared/models/advice-process/advice-process.model';
import { objectUtil } from '@util/util';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CrtKiwiSaverQuery } from '../../../state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../../../state/crt-kiwisaver.service';
import { CrtKiwiSaverStore } from '../../../state/crt-kiwisaver.store';
import {
	Dependant,
	DependantDetailsState,
	DependantState,
} from './dependants.model';
import { DependantsQuery } from './dependants.query';
import { DependantsStore } from './dependants.store';

@Injectable()
export class DependantsService {
	dependants$ = this.query.dependants$;
	isLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		protected store: DependantsStore,
		protected query: DependantsQuery,
		protected koatService: CrtKiwiSaverService,
		protected koatQuery: CrtKiwiSaverQuery,
		protected koatStore: CrtKiwiSaverStore
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(adviceProcessId: number) {
		const sectionCode = AdviceProcessSectionCodes.Dependants;
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<Dependant[]>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? (x?.map(objectUtil.mapPascalCaseToCamelCase) as DependantState[])
						: [];
					this.store.set(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	update(dependent: DependantDetailsState) {
		const endpoint = `crt/${dependent.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(dependent);
		return this.api.put<DependantDetailsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = moment().diff(dependent.dateOfBirth, 'years');
					const data = this.query.getValue()?.map((y) =>
						y.cRTId === dependent.cRTId
							? {
									customerId: dependent.customerID,
									age: dependent.dateOfBirth ? (age < 0 ? 0 : age) : null,
									birthDate: dependent.dateOfBirth,
									cRTId: dependent.cRTId,
									name:
										dependent.firstName && dependent.lastName
											? dependent.firstName?.concat(' ', dependent.lastName)
											: null,
									relationship: dependent.relationship,
							  }
							: y
					) as DependantState[];
					this.store.set(data);
				})
			)
		);
	}
}
