import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface InformationGathered {
	cRTId: number;
	adviceProcessId: number;
	sectionCode: 'KOATFFIG' | 'KLOATFFIG';
	parentCRTId: number;
	customerId: number;
	inKiwiSaver: boolean;
	currentProvider: string;
	fundType: string;
	pIR: number | string;
	mostImportantInProvider: string | string[];
	aboutCurrentProvider: string;
	isRetirement?: boolean;
	firstHomePurchaseGoal?: string;
	currentPage: string;

	kiwisaverKnowledge?: number | string;
	levelOfKiwiSaverKnowledge?: number;
	createDateTime?: string;
}

export interface CrtKiwisaverFactFind extends EntityState<InformationGathered> {}

export const CrtKiwisaverFactFindGatheredInformationStoreName = 'CrtKiwisaver:FactFind GatheredInformation';
@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: CrtKiwisaverFactFindGatheredInformationStoreName,
	idKey: 'cRTId',
})
export class CrtKiwisaverFactFindGatheredInformationStore extends EntityStore<CrtKiwisaverFactFind> {
	constructor() {
		super([]);
	}

	resetWithdrawalState(crtId: number): void {
		this.update(crtId, {
			isRetirement: null,
			firstHomePurchaseGoal: null,
		});
	}
}
