import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OtherStore, OtherState } from './other.store';

@Injectable()
export class OtherQuery extends QueryEntity<OtherState> {
	others$ = this.selectAll({ filterBy: (x) => !!x.status });

	constructor(protected store: OtherStore) {
		super(store);
	}
}
