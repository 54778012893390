import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { BusinessProfileRoutingModule } from './business-profile-routing.module';
import { BusinessProfileGroupComponent } from './business-profile-group/business-profile-group.component';
import { BusinessProfileService } from './states/business-profile.service';
import { BusinessProfileAddComponent } from './business-profile-add/business-profile-add.component';
import { BusinessProfileStore } from './states/business-profile.store';
import { BusinessProfileQuery } from './states/business-profile.query';
import { FormCompanyComponent } from './forms/form-company/form-company.component';
import { FormOtherComponent } from './forms/form-other/form-other.component';
import { FormDirectorComponent } from './forms/form-director/form-director.component';
import { FormShareholderComponent } from './forms/form-shareholder/form-shareholder.component';
import { BusinessProfileEditComponent } from './business-profile-edit/business-profile-edit.component';
import { BusinessProfileTabsComponent } from './business-profile-tabs/business-profile-tabs.component';
import { ShareholdersDirectorsTabComponent } from './business-profile-tabs/shareholders-directors-tab/shareholders-directors-tab.component';
import { BusinessProfileAddResolver } from './business-profile-add/business-profile-add.resolver';
import { BusinessProfileEditResolver } from './business-profile-edit/business-profile-edit.resolver';
import { LrInsuranceService } from './states/pipelines/lr-insurance.service';
import { MortgageService } from './states/pipelines/mortgage.service';
import { FgInsuranceService } from './states/pipelines/fg-insurance.service';
import { KiwisaverService } from './states/pipelines/kiwisaver.service';
import { InvestmentService } from './states/pipelines/investment.service';
import { PropertyAssetService } from './states/pipelines/property-asset.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BusinessProfileRoutingModule
  ],
  declarations: [
    BusinessProfileGroupComponent,
    BusinessProfileTabsComponent,
    ShareholdersDirectorsTabComponent,
    BusinessProfileAddComponent,
    BusinessProfileEditComponent,
    FormCompanyComponent,
    FormDirectorComponent,
    FormShareholderComponent,
    FormOtherComponent
  ],
  providers: [
    BusinessProfileAddResolver,
    BusinessProfileEditResolver,
    BusinessProfileStore,
    BusinessProfileQuery,
    BusinessProfileService,
    LrInsuranceService,
    MortgageService,
    PropertyAssetService,
    FgInsuranceService,
    KiwisaverService,
		InvestmentService
  ]
})
export class BusinessProfileModule { }
