import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';

export interface DisclosureDocumentState {
	settingsId?: number;
	referenceId?: number;
	type?: string;
	template?: string;
	isEnableEmailOption?: boolean;
	sender?: string;
	carbonCopy?: string;
	blindCarbonCopy?: string;
	subject?: string;
	templateID?: string;
	unsubscribeGroupID?: number;
	mergeTag?: MergeTagState[];
	body?: string | number;
	crtId?: number;
	templateType?: string;
	uploadTemplate?: string | number;
}

export interface DisclosureState {
	cRTId: number;
	adviceProcessId: number;
	document: DocumentState;
	sectionCode: string;
	status: number;
	parentCRTId?: number;
	content?: string;
	clickedNext?: boolean;
}

export interface DocumentState {
	referenceId: number;
	value: string;
}

export interface DocumentUploadState {
	referenceId?: number;
	document: string;
	documentId?: number;
	fileName?: string;
	type?: string;
}

export interface DisclosureDocument {
	documentID: number;
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	status: number;
	createDateTime: string;
	createdByStaffId: number;
	createdByStaffLevel: number;
	modifiedDateTime: string;
	modifiedByStaffId?: any;
}

export const defaultDD = {
	template: null,
	isEnableEmailOption: false,
	sender: '',
	carbonCopy: '',
	blindCarbonCopy: '',
	subject: '',
	templateID: '',
	unsubscribeGroupID: null,
	mergeTag: [],
	body: null,
};

export enum DisclosureTemplateType {
	UploadDocument = 'UD',
	FroalaTemplate = 'FT',
}

export enum DisclosureFileExt {
	text = '.txt',
	pdf = '.pdf',
}
