import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '@core/base/api.service';
import { BusinessService } from '@core/business/business.service';
import { CustomerService } from '@core/customer/customer.service';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '@util/util';
import { ExistingPolicyStructureStore } from './existing-policy-structure.store';
import { ExistingPolicyStructureQuery } from './existing-policy-structure.query';
import {
	ExistingPolicyStructure,
	ExistingPolicyStructureState,
} from '@models/client-review-template/existing-policy-structure/existing-policy-structure.model';
import { AdviceProcessSectionCodes } from '@shared/models/advice-process/advice-process.model';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';

@Injectable({
	providedIn: 'root',
})
export class ExistingPolicyStructureService {
	data$ = this.existingPolicyQuery.getAll();

	constructor(
		private api: ApiService,
		protected existingPolicyStore: ExistingPolicyStructureStore,
		protected existingPolicyQuery: ExistingPolicyStructureQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.existingPolicyStore.reset();
		});
	}

	get(adviceProcessId: number) {
		return this.api
			.get<ExistingPolicyStructure[]>(
				`crt/${adviceProcessId}/${AdviceProcessSectionCodes.ExistingPolicyStructure}`
			)
			.pipe(
				map(
					(x) =>
						x.map(
							objectUtil.mapPascalCaseToCamelCase
						) as ExistingPolicyStructureState[]
				),
				tap((x) =>
					applyTransaction(() => {
						this.existingPolicyStore.set(x);
					})
				),
				catchError(() => of([]))
			);
	}

	update(data: ExistingPolicyStructureState) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api
			.put<ExistingPolicyStructureState>(`crt/${data.cRTId}`, body)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						this.existingPolicyStore.update(data.cRTId, data);
					})
				),
				catchError(() => EMPTY)
			);
	}

	delete(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					let data =
						this.existingPolicyQuery.getAll() as ExistingPolicyStructureState[];
					data = data?.filter((y) => y?.cRTId !== id);
					this.existingPolicyStore.set(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}
}
