<div class="row tap-container mortgage-end-process pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<tab heading="Email Settings">
				<app-mortgage-end-process-email-settings
					[emailSettings$]="emailSettings$"
					[mergeTags$]="mergeTags$"
				></app-mortgage-end-process-email-settings>
			</tab>
		</tabset>
	</div>
</div>
