import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-input-select',
	templateUrl: './input-select.component.html',
	styleUrls: ['./input-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputSelectComponent),
			multi: true,
		},
	],
})
export class InputSelectComponent<T extends any>
	implements ControlValueAccessor, OnInit
{
	@ViewChild('input', { static: true }) input?: ElementRef<HTMLInputElement>;

	/**
	 * remove all attribute from host element. so we dont have a
	 * duplicate attribute when we supplied tabindex, type, size, and id
	 */
	@HostBinding('attr.tabindex') attrTabindex = null;
	@HostBinding('attr.type') attrType = null;
	@HostBinding('attr.size') attrSize = null;
	@HostBinding('attr.id') attrId = null;
	@HostBinding('attr.readonly') attrReadonly = null;
	@HostBinding('attr.height') attrHeight = null;

	@Input() valueProperty = 'value';

	@Input() displayProperty = 'text';

	@Input() appearance: 'bootstrap' | 'crt' = 'bootstrap';

	@Input() height: null;

	@Input() width: null;

	/**
	 * if true empty select option will prepent
	 */
	@Input() allowEmpty = true;

	@Input() allowEmptyInitial = false;

	/**
	 * empty select option display text
	 */
	@Input() emptyText?: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() items$?: Observable<T[] | any[]>;

	@Input() items?: T[] | any[];

	@Input() value?: number | string;

	@Input() disabled = false;

	@Input() tabindex?: number | string;

	@Input() size: 'sx' | 'sm' | 'md' | 'lg' = 'md';

	@Input() id?: string = '';

	@Input() placeholder?: string;

	@Input() readonly = false;

	@Input() required = false;

	@Input() inputClassname: string;

	@Input() isInvalid = false;

	@Output() valueChange = new EventEmitter<number | string>();

	onChange?: (value: number | string) => void;

	get hasValue(): boolean {
		return Boolean(this.input?.nativeElement?.value) || Boolean(this.value);
	}

	get inputClass(): string {
		return `form-control form-select form-select-${this.size} w-100 ${
			this.inputClassname ?? ''
		} ${this.appearance === 'crt' ? 'crt-form-control' : ''} ${
			!!this.isInvalid ? 'invalid-control' : ''
		}`;
	}

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

	writeValue(value: number | string): void {
		this.value = value;
		if (this.input?.nativeElement) {
			// @ts-ignore-next
			this.input.nativeElement.value = this.value;
		}
		this.cdr.detectChanges();
	}

	registerOnChange(fn: (value: number | string) => void): void {
		this.onChange = fn;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.cdr.detectChanges();
	}

	registerOnTouched(): void {
		// throw new Error('Method not implemented.');
	}

	change(e: Event): void {
		// @ts-ignore-next
		const value = e.target.value ?? null;
		// @ts-ignore-next
		const parsedValue = isNaN(value) ? e.target.value : value;
		if (this.onChange) {
			this.onChange(parsedValue);
			this.value = value;
			this.cdr.detectChanges();
		}
		this.valueChange.emit(parsedValue);
	}
}
