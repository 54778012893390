import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { concatMap, map, mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { BusinessConfigService } from '../../../domain/business-config/business-config.service';
import { ServicesService } from '../../../domain/service/services.service';
import { ViewBusiness } from '../viewmodels/business.viewmodel';
import { FeatureToggleModel, SpecialFeaturesSettingsCode } from '@modules/special-features/state/special-features-settings.model';

const getCompanyCode = () => {
	let cc = '';
	if (location.pathname && location.pathname?.split('/').length > 0) {
		cc = location.pathname?.split('/')[1];
		cc = cc === 'admin' ? '' : cc;
	} else {
		return '';
	}
	return cc;
};
@Injectable()
export class BlBusinessComponentService implements Resolve<boolean> {
	private servicesSubj = new BehaviorSubject([]);
	public readonly services$ = this.servicesSubj.asObservable();
	public viewBusiness$ = this.businessConfigQuery.businessConfig$.pipe(
		withLatestFrom(this.services$),
		map(([business, services]) =>
			business ? ViewBusiness.MapFromModel(business, services) : null
		)
	);
	public businessName$ = this.businessConfigQuery.businessConfig$.pipe(
		map((x) => (x ? x.BusinessName : ''))
	);
	// client alteration request
	public cARFeatureEnabled$ = this.businessConfigQuery.businessConfig$.pipe(
		map((config) => config?.SpecialFeature?.includes(SpecialFeaturesSettingsCode.CAR) ?? false)
	);
	constructor(
		private businessConfigService: BusinessConfigService,
		private businessConfigQuery: BusinessConfigQuery,
		private servicesService: ServicesService
	) { }

	resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
		return forkJoin([this.servicesService.addList()])
			.pipe(
				tap(([services]) => this.servicesSubj.next(services)),
				mapTo(true)
			);
	}

	save(data: ViewBusiness) {
		const model = ViewBusiness.MapToModel(data);
		return this.businessConfigService.updateBusinessConfig(model, getCompanyCode());
	}

	updateFeature(data: FeatureToggleModel) {
		const company = getCompanyCode();
		return this.businessConfigService.patchUpdateFeature(data, company);
	}
}
