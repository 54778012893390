import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { Observable, forkJoin, of, EMPTY } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { RiskAnalysisTPDState } from '../../../../../../shared/models/client-review-template/risk-analysis/tpd/tpd.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../client-review-template.query';
import { ClientReviewTemplateService } from '../../client-review-template.service';
import { ClientReviewTemplateStore } from '../../client-review-template.store';
import { TpdQuery } from './tpd.query';
import { TpdStore } from './tpd.store';
import { find, propEq } from 'ramda';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

@Injectable()
export class TpdService
	extends ClientReviewTemplateService
	implements Resolve<boolean>
{
	formValue$ = this.tpdQuery.select((x) => x.formValue);
	riskTpd$ = this.query.riskAnalysisTpd$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected tpdStore: TpdStore,
		protected tpdQuery: TpdQuery
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	clearData() {
		applyTransaction(() => {
			this.tpdStore.reset();
		});
	}

	getTpd() {
		return this.api
			.get<[]>(
				`crt/risk-analysis/advice-process/${
					this.query.getValue().adviceProcessId
				}/${AdviceProcessSectionCodes.TPD}`
			)
			.pipe(
				map(
					(x) =>
						x.map(objectUtil.mapPascalCaseToCamelCase) as RiskAnalysisTPDState[]
				),
				tap((x) =>
					applyTransaction(() => {
						this.tpdStore.set(x);
					})
				),
				catchError(() => of(undefined))
			);
	}

	addTpd(tpd: RiskAnalysisTPDState) {
		const data = objectUtil.mapCamelCaseToPascalCase(tpd);
		return this.api.post3<number>(`crt`, data).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.tpdStore.update(tpd.parentCRTId, { ...tpd, cRTId: x });
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateTpd(tpd: RiskAnalysisTPDState) {
		const data = objectUtil.mapCamelCaseToPascalCase(tpd);
		return this.api.put<number>(`crt/${tpd.cRTId}`, data).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.tpdStore.update(tpd.parentCRTId, tpd);
				})
			),
			catchError(() => EMPTY)
		);
	}

	setActiveParentCRTId(parentCRTId: number) {
		this.tpdStore.setActiveParentCRTId(parentCRTId);
	}

	setFormValue(data: RiskAnalysisTPDState) {
		this.setHasFormChanges(true);
		const list = this.tpdQuery.getAll();
		const isExisting = find(propEq('parentCRTId', data?.parentCRTId))(list);
		if (isExisting) {
			this.tpdStore.update(data?.parentCRTId, data);
		} else {
			this.tpdStore.add(data);
		}
	}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		const getTpd$ = !!this.tpdQuery.getHasCache() ? of(null) : this.getTpd();

		return forkJoin([getTpd$]).pipe(mapTo(true));
	}
}
