import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { viewSecGroup } from '@modules/user/viewmodels/viewSecGroup';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
	selector: 'app-accessibility-settings',
	templateUrl: './accessibility-settings.component.html',
	styleUrls: ['./accessibility-settings.component.scss'],
})
export class AccessibilitySettingsComponent implements OnInit, OnChanges, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() data: BlStaffViewmodel;
	@Input() staffChoices: BLStaff[] = [];

	@Input() updateAccessiblitySettingsFn$: ({
		staff,
		adviserIds,
	}) => Observable<any>;
	@Input() secGroups: viewSecGroup[] = [];

	isEditing = false;
	isLoading = false;

	showAllStaffs = false;

	form: FormGroup;

	adviserIdsAssigned = [];

	staff: any;

	constructor(private fb: FormBuilder) {
		this.buildForm();
	}

	ngOnInit() {
		this.updateStaff();
		this.form.disable();

		this.adviserIdsAssigned = this.data.AdviserIdsAssigned;

		this.form.get('AdviserIdsAssigned').setValue(this.adviserIdsAssigned);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { data } = changes;
		if (data?.previousValue?.IsActive !== data?.currentValue?.IsActive) {
			this.updateStaff();
		}
	}

	buildForm() {
		this.form = this.fb.group({
			AdviserIdsAssigned: [],
		});
	}

	updateStaff() {
		this.staff = {
			display: `${this.data?.FirstName} ${this.data?.LastName}`,
			value: this.data?.StaffID,
			isDefault: false,
			isActive: this.data?.IsActive,
			securityGroup: this.mapSecGroup(this.data?.SecurityGroup),
			status: this.data?.IsActive,
		};
	}

	edit() {
		this.isEditing = true;
		this.form.enable();
	}

	cancelEdit() {
		this.isEditing = false;

		this.form.reset({
			AdviserIdsAssigned: this.adviserIdsAssigned,
		});

		this.form.disable();
	}

	showAll() {
		this.showAllStaffs = !this.showAllStaffs;
	}

	save() {
		this.isLoading = true;
		const values = this.form.getRawValue();

		this.updateAccessiblitySettingsFn$({
			staff: this.data,
			adviserIds: values,
		})
			.pipe(
				take(1),
				tap(() => {
					const { AdviserIdsAssigned } = values;
					this.adviserIdsAssigned = AdviserIdsAssigned;
				})
			)
			.subscribe(
				() => {
					this.isEditing = false;
					this.form.disable();
					this.isLoading = false;
				},
				() => {
					this.isLoading = false;
				}
			);
	}

	mapSecGroup(secGroup: string) {
		const security = this.secGroups.find(
			(sec) => sec.securityGroupCode === secGroup
		);

		if (security) {
			return security.securityGroupName;
		}

		return secGroup;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
