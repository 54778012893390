import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import {
	BlStaffPdTypes,
	BlStaffSettingTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'app-user-pd-tracking',
	templateUrl: './user-pd-tracking.component.html',
})
export class UserPdTrackingComponent implements OnInit {
	@Input() staff: BlStaffViewmodel;
	@Input() staffSettings: BlStaffSettingsModel[];
	@Input() userSecurityGroup: string;
	@Input() isTapLevel: boolean;
	@Input() loggedUserId: number;
	@Input() UPDTP: ViewDisplayValue[] = []; // Provider Dropdown
	@Input() UPDTC: ViewDisplayValue[] = []; // Category Dropdown
	@Input() UPDTS: ViewDisplayValue[] = []; // Status Dropdown
	@Input() upsertPdTrackingFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() downloadDocumentFn$: (data: {
		documentId: number;
		fileName: string;
	}) => Observable<any> = (data) => of(data);

	scheduledPd: BlStaffSettingsModel[];
	completedPd: BlStaffSettingsModel[];

	ngOnInit(): void {
		this.setPdTracker(this.staffSettings);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.staffSettings) {
			this.setPdTracker(this.staffSettings);
		}
	}

	setPdTracker(data: BlStaffSettingsModel[]) {
		const type = BlStaffSettingTypes.PersonalDevelopment;
		const individualType =
			BlStaffSettingTypes.PersonalDevelopmentManagementIndividual;

		this.scheduledPd = data
			?.filter(
				(x) =>
					(x?.SettingsCode === type || x?.SettingsCode === individualType) &&
					x?.Status === BlStaffPdTypes.Upcoming
			)
			?.sort(
				(a, b) => new Date(a.DueDate).valueOf() - new Date(b.DueDate).valueOf()
			);

		this.completedPd = data
			?.filter(
				(x) =>
					(x?.SettingsCode === type || x?.SettingsCode === individualType) &&
					(x?.Status === BlStaffPdTypes.Completed ||
						x?.Status === BlStaffPdTypes.DidntAttend)
			)
			?.sort(
				(a, b) =>
					new Date(b.CompletedDate).valueOf() -
					new Date(a.CompletedDate).valueOf()
			);
	}
}
