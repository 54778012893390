<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.Disclosure"></app-header>
</div>

<!-- Content -->
<div role="main-content">
	<ng-container *ngIf="crtData && crtFileType === '.txt' || (!crtData && defaultSettings && defaultSettings?.templateType === 'FT')">
		<app-wysiwyg
			#contentEditor
			[content]="content"
			[readOnly]="true"
			[generatePdf$]="generatePdf$"
			class="d-block"
			[zoom]="zoom$ | async"
			(zoomEvent)="zoomFn($event)"
		></app-wysiwyg>
	</ng-container>

	<ng-container *ngIf="crtData && crtFileType === '.pdf' || (!crtData && defaultSettings && defaultSettings?.templateType === 'UD')">
		<div class="wrapper">
			<app-view-pdf
				*ngIf="fileUrl"
				[fileSource]="fileUrl"
				[renderOnNotesCollapse]="true"
				[isNotesCollapse$]="isNotesCollapseSubject$"
				(onPageRendered)="onPageRendered($event)"
			></app-view-pdf>
		</div>
	</ng-container>

	<div class="clearfix p-lg-0 mt-2">
		<ng-container *ngIf="isEnableEmailOption != null && (disclosureSettings$ | async)">
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-2"
				(click)="saveDocument(); generatedPdf()"
				[disabled]="!!isCompLoading"
			>
				Save to File
			</button>
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-2"
				(click)="downloadDocument()"
				[disabled]="!!isCompLoading"
			>
				Download Document
			</button>
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-2"
				(click)="reloadTemplate()"
				[disabled]="!!isCompLoading"
			>
				Reload Template
			</button>
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mb-2"
				(click)="sendEmail(); generatedPdf()"
				[disabled]="!!isCompLoading"
				*ngIf="isEnableEmailOption"
			>
				Email Client
			</button>
			<ng-container *ngIf="!!isCompLoading">
				<app-manual-loader-mini
					[isLoading]="!!isCompLoading"
					class="pl-1"
				></app-manual-loader-mini>
			</ng-container>
		</ng-container>
		
		
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer
		[currentPageId]="pageIds.Disclosure"
		[callbackFn$]="saveFn"
		[sidebar]="sidebar"
	></app-footer>
</div>
