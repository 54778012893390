import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GlobalSearchV2Service } from './global-search-v2.service';
import { tap, finalize, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';

@Component({
	selector: 'app-global-search-v2',
	templateUrl: './global-search-v2.component.html',
	styleUrls: ['./global-search-v2.component.scss'],
})
export class GlobalSearchV2Component {
	@Input() isExpanded: boolean;

	private isShownSubj = new BehaviorSubject(false);
	private isLoadingSubj = new BehaviorSubject(false);
	public readonly isShown$ = this.isShownSubj.asObservable();
	public readonly isLoading$ = this.isLoadingSubj.asObservable();
	public readonly data$ = this.compService.searchResult$;

	isFocused: boolean;
	keyword = '';

	constructor(private compService: GlobalSearchV2Service) {}

	@ViewChild('searchInput') searchInput: ElementRef;
	@ViewChild('searchWrapper') searchWrapper: ElementRef;

	get searchWrapperEl() {
		return this.searchWrapper?.nativeElement;
	}

	onEnter(keyword: string) {
		this.setFocusInput();
		const searchInput = this.searchInput?.nativeElement;
		if (!searchInput?.value) {
			return;
		}
		of(keyword)
			.pipe(
				tap(() => this.isLoadingSubj.next(true)),
				mergeMap((x) => this.compService.search(x?.trim())),
				tap(() => this.isShownSubj.next(true)),
				finalize(() => {
					this.isLoadingSubj.next(false);
				})
			)
			.subscribe(
				(x) => x,
				(err) => {
					throw new Error(err);
				}
			);
	}

	expandSearch() {
		this.searchWrapperEl?.classList?.add('active');
	}

	closeSearch() {
		if (this.isExpanded) {
			return;
		}
		if (this.searchInput?.nativeElement?.value) {
			this.searchInput.nativeElement.value = '';
		}
		this.searchWrapperEl?.classList?.remove('active');
		this.isFocused = false;
	}

	toggleOrSearch() {
		if (this.isExpanded) {
			this.onEnter(this.searchInput?.nativeElement?.value);
			return;
		}
		if (this.searchWrapperEl?.classList?.contains('active')) {
			this.onEnter(this.searchInput?.nativeElement?.value);
		} else {
			this.setFocusInput();
			this.expandSearch();
		}
	}

	setFocusInput() {
		this.isFocused = true;
		this.searchInput?.nativeElement?.focus();
	}

	focusOutSearch() {
		if (this.isExpanded) {
			return;
		}
		const searchInput = this.searchInput?.nativeElement;
		setTimeout(() => {
			if (
				this.searchWrapperEl?.classList?.contains('active') &&
				!searchInput?.value &&
				!this.isFocused
			) {
				this.closeSearch();
			}
		}, 1000);
	}

	onFocus(event?) {
		this.isFocused = !!event;
	}

	close() {
		this.closeSearch();
		this.closeOverlay();
	}

	closeOverlay() {
		this.isShownSubj.next(false);
	}
}
