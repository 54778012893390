import { HtmlToPdfService } from '../modules/crm/crt-page/_shared/service/html-pdf/html-to-pdf.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './authentication/authentication.service';
import { ConfigService } from './config/config.service';
import { LoggerService } from './logger/logger.service';
import { RedirectGuard } from './general/redirect.guard';
import { RouteService } from './config/route.service';
import { MODEL_PROVIDER } from './base/model.service';
import { TlAdminDataService } from './general/tl-admin-data.service';
import { CrmDataService } from './general/crm-data.service';
import { ControlsService } from './controls/controls.service';
import { DomEventService } from './dom-event/dom-event.service';
import { CustomerService } from './customer/customer.service';
import { NoteService } from './note/note.service';
import { CustomerSearchService } from './customer/search.service';
import { BusinessService } from './business/business.service';
import { GlobalSearchService } from './customer/global-search/global-search.service';
import { BlStaffService } from './staff/bl-staff.service';
import { ToastService } from './services/toast.service';
import { EmailDuplicateService } from './services/email-duplicate.service';

// Guards
import { BusinessSetterGuard } from './general/business-setter.guard';
import { BusinessClearGuard } from './general/business-clear.guard';
import { SetViewTapLevelGuard } from './general/set-view-tap-level.guard';
import { SetViewBusLevelGuard } from './general/set-view-bus-level.guard';
import { SetViewCrmLevelGuard, SetViewNotCrmLevelGuard } from './general/set-view-crm-level.guard';
import { StaffService } from '../modules/user/service/staff.service';
import { ActivityService } from './services/activity/activity.service';
import { ScriptService } from './general/script.store';
import { BusinessLeaveGuard } from './general/business-leave.guard';
import { BusinessAdminGuard } from './general/business-admin.guard';


@NgModule({
	imports: [CommonModule],
	providers: [
		MODEL_PROVIDER,
		ScriptService,
		AuthenticationService,
		ConfigService,
		RouteService,
		LoggerService,
		RedirectGuard,
		TlAdminDataService,
		CrmDataService,
		StaffService,
		ControlsService,
		CustomerService,
		NoteService,
		CustomerSearchService,
		BusinessService,
		GlobalSearchService,
		ToastService,
		EmailDuplicateService,

		BusinessSetterGuard,
		BusinessClearGuard,
		SetViewTapLevelGuard,
		SetViewBusLevelGuard,
		SetViewCrmLevelGuard,
		SetViewNotCrmLevelGuard,
		BusinessLeaveGuard,
		BusinessAdminGuard,
		DomEventService,
		ActivityService,

		BlStaffService,
		HtmlToPdfService,
	],
})
export class CoreModule {}
