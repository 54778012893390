import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { takeUntil, tap, take, finalize, concatMap, map } from 'rxjs/operators';
import { ServicingCalculatorState } from '../state/servicing-calculators-settings.model';
import { ComponentBase } from '@core/base/component-base';
import { ServicingCalculatorSettingsService } from '../state/servicing-calculators-settings.service';
import { ServicingCalculatorSettingsMapper } from '../state/servicing-calculators-settings.mapper';
import { CalcTemplateType } from '@modules/tl-moat-settings/state/tl-moat-settings.model';

@Component({
	selector: 'app-servicing-integrated-templates',
	templateUrl: './servicing-integrated-templates.component.html',
	styleUrls: ['./servicing-integrated-templates.component.scss'],
})
export class ServicingIntegratedTemplatesComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@Input() data$: Observable<ServicingCalculatorState[]>;

	form: UntypedFormGroup;

	constructor(
		@Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
		private service: ServicingCalculatorSettingsService
	) {
		super();
		this.buildForm();
	}

	get TemplateList() {
		return this.form.get('templateList') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			templateList: this.fb.array([]),
		});
	}

	prepList(data: ServicingCalculatorState[]) {
		while (this.TemplateList.length > 0) {
			this.TemplateList.removeAt(0);
		}
		data?.forEach((document: ServicingCalculatorState) => {
			this.TemplateList.push(this.patchValue(document));
		});
	}

	patchValue(template: ServicingCalculatorState): UntypedFormGroup {
		return this.fb.group({
			...template,
			documentName: template?.documentName || '',
			templateType: CalcTemplateType.Integrated,
			isActive: template?.isActive,
			isLoading: false,
		});
	}

	ngOnInit(): void {
		this.data$
			.pipe(
				tap((data) => this.prepList(data)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	updateTick(i) {
		const item = this.TemplateList.controls[i];
		const formValue = this.TemplateList.getRawValue()[i];
		const currentValue = item.get('isActive').value;
		item.get('isLoading').setValue(true);

		of(formValue)
			.pipe(
				map((data) =>
					ServicingCalculatorSettingsMapper.mapToUpsert({
						...data,
						isActive: !currentValue,
					})
				),
				concatMap((x) => this.service.updateTemplate(x)),
				take(1),
				finalize(() => {
					item.get('isLoading').setValue(false);
					item.get('isActive').setValue(!currentValue);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
