export const adviceSummaryWordingsMetaKey = {
	// Advice Summary Wordings
	raiseComplaint: 'KOAT_RAISE_COMPLAINT',
	thingsToConsider: 'KOAT_THINGS_TO_CONSIDER',
	nextStep: 'KOAT_NEXT_STEP',
	investmentAdvice: 'INVESTMENT_ADVICE',
};

export const adviceSummaryMetaKeys = {
	// From Fact Find > People
	peopleFirstName: 'PEOPLE_FIRST_NAME',
	peopleFirstNameAmp: 'ADVICE_SUMMARY_PEOPLE_FIRST_NAME', // With "ampersand" separator
	peopleNameWithRef: 'PEOPLE_NAME_WITH_REFERENCE',
	dependantNameWithRef: 'DEPENDANT_NAME_WITH_REFERENCE',
	// From Fact Find > Your KiwiSaver information
	informationImportant: 'INFORMATION_WHATS_MOST_IMPORTANT',
	informationCurrentFund: 'INFORMATION_CURRENT_FUND',
	informationPriority: 'INFORMATION_PRIORITY',
	// From Provider & Commission Settings
	kiwisaverMaxUpfront: 'KS_MAX_UPFRONT',
	kiwisaverMaxRenewal: 'KS_MAX_RENEWAL',
	// From Report Builder
	peopleProviderReasoning: 'PEOPLE_PROVIDER_REASONING',
	dependantProviderReasoning: 'DEPENDANT_PROVIDER_REASONING',
	peopleFundTypeReasoning: 'PEOPLE_FUND_TYPE_REASONING',
	dependantFundTypeReasoning: 'DEPENDANT_FUND_TYPE_REASONING',
	peopleChosenKsProvider: 'PEOPLE_CHOSEN_KS_PROVIDER',
	dependantChosenKsProvider: 'DEPENDANT_CHOSEN_KS_PROVIDER',
	chosenKsProviderAll: 'ALL_CHOSEN_KS_PROVIDER',
	chosenKsProviderAllOr: 'THINGS_TO_CONSIDER_ALL_CHOSEN_KS_PROVIDER', // With "or" separator
	peopleFund: 'PEOPLE_FUND_TYPE',
	dependantFund: 'DEPENDANT_FUND_TYPE',
	peopleKsChosenFundType: 'PEOPLE_CHOSEN_KS_PROVIDER_AND_FUND_TYPE',
	dependantKsChosenFundType: 'DEPENDANT_CHOSEN_KS_PROVIDER_AND_FUND_TYPE',
};

export const kiwisaverAdviseSummaryMT = [
	{
		metaKey: adviceSummaryMetaKeys.kiwisaverMaxUpfront,
		description: 'Staff > Provider and Commission > Min Upfront',
		value: null,
		type: 'T',
		secondaryValue: `$123.00`,
	},
	{
		metaKey: adviceSummaryMetaKeys.kiwisaverMaxRenewal,
		description: 'Staff > Provider and Commission > Max Upfront',
		value: null,
		type: 'T',
		secondaryValue: `$123.00`,
	},
	{
		metaKey: adviceSummaryMetaKeys.chosenKsProviderAll,
		description: 'All Chosen KiwiSaver Providers',
		value: null,
		type: 'T',
		secondaryValue: `ANZ`,
	},
	{
		metaKey: adviceSummaryMetaKeys.chosenKsProviderAllOr,
		description: 'All Chosen KiwiSaver Providers (or separator)',
		value: null,
		type: 'T',
		secondaryValue: `ANZ or ASB`,
	},
	{
		metaKey: adviceSummaryMetaKeys.peopleKsChosenFundType,
		description:
			'People Fact Find > Chosen KiwiSaver Provider & Report Builder > Fund',
		value: '',
		type: 'RV',
		secondaryValue: `ANZ`,
	},
	{
		metaKey: adviceSummaryMetaKeys.dependantKsChosenFundType,
		description:
			'Dependant Fact Find > Chosen KiwiSaver Provider & Report Builder > Fund',
		value: '',
		type: 'RV',
		secondaryValue: `ANZ`,
	},
];

export const yourKiwiSaverMT = [
	{
		metaKey: adviceSummaryMetaKeys.peopleFirstNameAmp,
		description: 'Fact Find > PEOPLE FIRST NAME (with or separator)',
		value: '',
		type: 'T',
		secondaryValue: `John Smith`,
	},
	{
		metaKey: adviceSummaryMetaKeys.informationPriority,
		description: 'Your KiwiSaver Information > Retirement or First Home',
		value: null,
		type: 'T',
		secondaryValue: `Your first KiwiSaver objective is to purchase a first home in approximately X years`,
	},
];
