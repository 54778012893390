<table class="table table-hover">
	<thead>
		<tr>
			<th scope="col">Loan Amount</th>
			<th scope="col">Structure</th>
			<th scope="col">Fixed Term</th>
			<th scope="col">Interest Rate</th>
			<th scope="col">Repayment</th>
			<th scope="col">Borrowers</th>
			<th scope="col"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let loan of loanStructure$ | async; let i = index">
			<td>$ {{ loan?.loanAmount | currency: "USD":"":"1.2-2" }}</td>
			<td>{{ loan?.loanType }}</td>
			<td>{{ loan?.fixedPeriod }}</td>
			<td>{{ loan?.interestRate | number: "1.2" }} %</td>
			<td>
				$ {{ loan?.repaymentAmount | currency: "USD":"":"1.2-2" }}
				{{ loan?.perFrequency }}
			</td>
			<td>{{ loan?.borrowerList }}</td>
			<td>
				<ng-container *ngIf="loan.cRTId && !(isLoading$ | async)">
					<button
						id="loanEditPersonButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="editLoanSplit(loan)"
						[disabled]="!!(isLoading$ | async)"
					>
						<i class="material-icons md-16"> edit </i>
					</button>
					<button
						id="loanDeletePersonButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="deleteLoanSplit(loan.cRTId)"
						[disabled]="!!(isLoading$ | async)"
					>
						<i class="material-icons md-16">delete</i>
					</button>
				</ng-container>

				<ng-container *ngIf="loan.cRTId && (isLoading$ | async)">
					<app-manual-loader-mini
						[isLoading]="isLoading$ | async"
					></app-manual-loader-mini>
				</ng-container>
			</td>
		</tr>
	</tbody>
	<tfoot>
		<tr>
			<td>{{ remainingToAllocate$ | async }}</td>
			<td colspan="6">remaining to allocate</td>
		</tr>
	</tfoot>
</table>
<!-- .controls-container -->

<!-- .table.table-hover -->
<form class="d-flex align-items-center" [formGroup]="formGroup">
	<button
		class="tap-btn tap-btn--primary text-white"
		[ngClass]="{
			disabled:
				loanStructureToFollowIsSaving ||
				((loanStructure$ | async).length ? false : loanStructureToFollow.value)
		}"
		(click)="addLoanSplit()"
	>
		Add Loan Split +
	</button>
	<div
		class="col-12 col-lg-6 mb-2 mt-1"
		*ngIf="!(loanStructure$ | async)?.length"
	>
		<div class="custom-control custom-checkbox pt-2">
			<input
				type="checkbox"
				class="chckbox custom-control-input"
				id="loanStructureToFollow"
				formControlName="loanStructureToFollow"
				[class.disabled]="loanStructureToFollowIsSaving"
			/>
			<label
				class="custom-control-label"
				[class.disabled]="loanStructureToFollowIsSaving"
				for="loanStructureToFollow"
			>
				Structure to be confirmed after approval
			</label>

			<app-manual-loader-mini
				*ngIf="loanStructureToFollowIsSaving"
				[isLoading]="true"
			></app-manual-loader-mini>
		</div>
	</div>
</form>
