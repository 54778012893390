import {
	Component,
	OnInit,
	AfterViewInit,
	ViewChild,
	ElementRef,
	OnDestroy,
} from '@angular/core';
import {
	Router,
	NavigationStart,
	NavigationEnd,
	NavigationCancel,
	NavigationError,
	GuardsCheckStart,
	GuardsCheckEnd,
} from '@angular/router';
import { Spinner } from 'spin.js';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { UserQuery } from '@domain/user/user.query';
import { RouteLoaderQuery } from '@domain/route-loader/route-loader.query';

const skipLoadingOnUrls = ['crt-page/kiwisaver', '/car/'];

@Component({
	selector: 'app-route-loader',
	template: `<div
		class="loader-container"
		[ngClass]="{ 'd-none': !isLoading }"
		#target
	></div>`,
	styleUrls: ['./route-loader.component.scss'],
})
export class RouteLoaderComponent implements OnInit, AfterViewInit, OnDestroy {
	constructor(
		private router: Router,
		private businessConfigQuery: BusinessConfigQuery,
		private routeLoaderQuery: RouteLoaderQuery,
		private userQuery: UserQuery
	) {
		this.setupProps();
	}
	private onDestroy$ = new Subject<void>();
	defaultColor: string;
	opts: any;
	@ViewChild('target') spinnerTarget: ElementRef;
	spinner: Spinner;
	skippedUrl: string;
	isLoading = false;
	setupProps() {
		this.setupOpts();
		this.spinner = new Spinner();
	}
	setupOpts(primaryColor?: string) {
		this.defaultColor = '#00263e';
		this.opts = {
			lines: 9, // The number of lines to draw
			length: 27, // The length of each line
			width: 20, // The line thickness
			radius: 45, // The radius of the inner circle
			scale: 0.5, // Scales overall size of the spinner
			corners: 1, // Corner roundness (0..1)
			color: primaryColor || this.defaultColor, // CSS color or array of colors
			fadeColor: 'transparent', // CSS color or array of colors
			opacity: 0.25, // Opacity of the lines
			rotate: 0, // The rotation offset
			animation: 'spinner-line-fade-quick',
			direction: 1, // 1: clockwise, -1: counterclockwise
			speed: 1, // Rounds per second
			trail: 60, // Afterglow percentage
			fps: 20, // Frames per second when using setTimeout() as a fallback in IE 9
			zIndex: 2e9, // The z-index (defaults to 2000000000)
			className: 'spinner', // The CSS class to assign to the spinner
			top: '50%', // Top position relative to parent
			left: '50%', // Left position relative to parent
			position: 'fixed', // Element positioning
		};
	}

	navigating = false;

	ngOnInit() {}

	ngAfterViewInit(): void {
		combineLatest([
			this.router.events,
			this.businessConfigQuery.themeConfig$,
			this.userQuery.isLoading$,
			this.routeLoaderQuery.skipLoading$,
		])
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(([router, theme, isUserLoading, skipRouteLoading]) => {
				const primaryColor = theme ? theme.primarycolor : '';
				this.setupOpts(primaryColor);
				if (isUserLoading) {
					this.isLoading = true;
					this.spin();
				}
				if (
					router instanceof GuardsCheckStart &&
					!skipRouteLoading &&
					this.skippedUrl === router.url
				) {
					this.isLoading = true;
					this.navigating = true;
					this.spin();
				}
				if (
					router instanceof GuardsCheckEnd &&
					!skipRouteLoading &&
					this.skippedUrl === router.url
				) {
					this.skippedUrl = null;
				}
				if (router instanceof NavigationStart) {
					if (skipRouteLoading) {
						this.skippedUrl = router.url;
						return;
					}
					if (
						skipLoadingOnUrls?.some((url) => router.url?.indexOf(url) > -1) &&
						skipLoadingOnUrls?.some((url) => this.router.url?.indexOf(url) > -1)
					) {
						return;
					}

					if (this.isLoading) {
						return;
					}
					this.navigating = true;
					this.isLoading = true;
					this.spin();
				} else if (
					router instanceof NavigationEnd ||
					router instanceof NavigationCancel ||
					router instanceof NavigationError
				) {
					if (!this.isLoading) {
						return;
					}

					this.navigating = false;
					this.isLoading = false;
					this.stop();
				} else {
					if (this.navigating) {
						return;
					}

					if (!this.navigating && !isUserLoading) {
						this.isLoading = false;
						this.stop();
					}
				}
			});
	}

	private spin() {
		if (this.spinner) {
			this.spinner.stop();
		}

		this.spinner = new Spinner(this.opts).spin(
			this.spinnerTarget.nativeElement
		);
	}
	private stop() {
		this.spinner.stop();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
