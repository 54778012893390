import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';

import { ApiService } from '../../../../core/base/api.service';
import { CrtSettingsQuery } from '../../state/crt-settings.query';
import { CrtSettingsStore } from '../../state/crt-settings.store';
import { objectUtil } from 'src/app/util/util';
import { ClientAcceptanceSettingsState } from '../state/client-acceptance-settings.model';

@Injectable()
export class ClientAcceptanceSettingsService {
  clientAcceptance$ = this.query.clientAcceptance$;

  constructor(
    private api: ApiService,
    private store: CrtSettingsStore,
    private query: CrtSettingsQuery
  ) {}


  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  getClientAcceptance(data: number, settingsCode: string) {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<ClientAcceptanceSettingsState>(endpoint).pipe(
      tap((res) =>
        applyTransaction(() => {
          const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
          this.store.setClientAcceptance(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateFinalStructure(data: ClientAcceptanceSettingsState) {
    const newValue = {
      ...this.query.getValue().clientAcceptance,
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<ClientAcceptanceSettingsState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setClientAcceptance(newValue);
        })
      )
    );
  }
}
