import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdt-info-widget',
  templateUrl: './pdt-info-widget.component.html',
  styleUrls: ['./pdt-info-widget.component.scss'],
})
export class PDTInfoWidgetComponent implements OnInit {

  @Input() total: number = 0;
  @Input() label: string;
  @Input() suffix: string;

  @Input() color: 'success' | 'error';
  @Input() icon: 'timer' | 'error_outline' = 'timer';

  constructor() {}

  ngOnInit(): void {}

}
