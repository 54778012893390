import {
	AbstractControl,
	UntypedFormControl,
	UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import { strUtil } from './util';

const emailValidator = (
	control: UntypedFormControl
): { [key: string]: any } => {
	if (
		!control.value ||
		control.value?.trim() === '' ||
		typeof control.value !== 'string'
	) {
		return null;
	}

	const hasInvalid = strUtil
		.removeSpace(control.value)
		?.split(',')
		?.some((e) => !!e && !strUtil.isEmail(e));
	return hasInvalid
		? { emailCommaSeparated: 'Please provide a valid email address' }
		: null;
};

const allDuplicateFields = (arr, val) =>
	arr?.reduce((acc, el, i) => {
		if (el.value?.toLowerCase() === val?.toLowerCase()) {
			return [...acc, el];
		}
		if (el?.value?.includes(',')) {
			// For value that contains multiple (comma-separated) email addresses
			const values = el?.value?.split(',')?.map((x) => x?.trim()) || [];
			return values?.find((x) => x?.toLowerCase() === val?.toLowerCase()) ? [...acc, el] : acc;
		}
		return acc;
	}, []);

export function uniqueFieldsValidator(fields: string[], exempted?:Array<{field:string, valueToCheckExempt?:string,exemptedField?:string}>) {
	return (formGroup: UntypedFormGroup) => {
		if (fields.length < 1) {
			return null;
		}

		const controls: FieldControl[] = [];
		fields?.forEach((field) => {
			const control = formGroup.controls[field];
			controls.push({
				field,
				value: control.value,
				control,
			});
		});
		const exemptedFields:string[] = [];
		exempted?.forEach((field)=>{
			if(field.valueToCheckExempt){
				const control = formGroup.controls[field.field];
				if(control.value !== field.valueToCheckExempt)
					exemptedFields.push(field.exemptedField ? field.exemptedField : field.field);
			}else{
				exemptedFields.push(field.field);
			}
		})

		let duplicateFields: FieldControl[] = [];

		controls?.forEach((control, index) => {
			if (
				duplicateFields.length > 1 &&
				duplicateFields?.findIndex((el) => el.field === control.field)
			) {
				return;
			}
			
			const indexes = allDuplicateFields(controls, control.value);
			if (indexes.length > 1) {
				duplicateFields = {
					...duplicateFields,
					...indexes,
				};
			}
		});

		duplicateFields = Object.values(duplicateFields)?.filter((d) =>
			Boolean(d.value?.trim())
		);

		if(exemptedFields.length>0)
			duplicateFields = duplicateFields.filter(obj => !exemptedFields.includes(obj.field));

		for (const duplicate of duplicateFields) {
			if (!duplicate.control.getError('duplicate')) {
				const errors = duplicate.control.errors;
				duplicate.control.setErrors({
					...errors,
					duplicate: true,
				});
			}
		}
		const uniqueFields: FieldControl[] =
			duplicateFields.length > 1
				? controls?.filter((control) => {
						return (
							duplicateFields?.findIndex(
								(duplicate) => duplicate.field === control.field
							) === -1
						);
					})
				: controls;
		uniqueFields?.forEach((unique) => {
			if (unique.control.getError('duplicate')) {
				let errors = unique.control.errors;
				delete errors.duplicate;
				errors = Object.values(errors).length > 0 ? errors : null;
				unique.control.setErrors(errors);
			}
		});
	};
}

function requiredString(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (Boolean(control.value?.trim?.())) {
			return null;
		}
		return { required: true };
	};
}

const uniqueEmailsInSameInputValidator = (
	control: UntypedFormControl
): { [key: string]: any } => {
	if (
		!control.value ||
		control.value?.trim() === '' ||
		typeof control.value !== 'string'
	) {
		return null;
	}

	const emails = control.value.split(',').map((email) => email.trim());

	const uniqueEmails = new Set(emails);

	if (emails.length !== uniqueEmails.size) {
		return { nonUniqueEmails: true }; // Return an error if there are duplicate emails
	}

	return null; // Return null if emails are unique
};

const urlValidator = (control: UntypedFormControl) => {
	if (!control.value) {
		return null;
	}

	const reg = new RegExp(
		'http(s)?://?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
	);

	return reg.test(control.value) ? null : { url: 'Please enter a valid url.' };
};

const ftInchesValidator = (control: UntypedFormControl) => {
	const value = control?.value;
	const errorMessage = { ftInches: 'Invalid value' };
	const ftInchesRegex = `^(?!$|.*\\'[^\\x22]+$)(?:([0-9]+)\\')?(?:([0-9]+)\\x22?)?$`;
	const reg = new RegExp(ftInchesRegex);
	if (!value) {
		return null;
	}
	if (value && value?.includes('"') && !value?.includes("'")) {
		// Do not allow inches only
		return errorMessage;
	}
	return reg.test(value) ? null : errorMessage;
};

export interface FieldControl {
	field: string;
	value: string;
	control: AbstractControl;
}

export const validatorUtil = {
	emailValidator,
	uniqueFieldsValidator,
	urlValidator,
	ftInchesValidator,
	uniqueEmailsInSameInputValidator,
	requiredString
};
