import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { FactFindSubpagesSettingsService } from '@modules/kiwisaver-settings/fact-find-settings/subpages/state/fact-find-subpages-settings.service';
import { SettingsTypes } from '@modules/kiwisaver-settings/state/kiwisaver-settings.model';
import { Observable, of, zip, forkJoin } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import { CrtKiwiSaverService } from '../../state/crt-kiwisaver.service';
import { CrtKiwiSaverRiskProfileService } from './crt-kiwisaver-risk-profile.service';
import { InformationGathered } from './crt-ks-fact-find-gathered-information.store';
import { CrtFactFindPeople } from './crt-ks-fact-find-people.store';
import { CrtKsFactFindService } from './crt-ks-fact-find.service';
import {
	CrtKiwiSaverFactFind,
	CrtKiwisaverFactFindStore,
} from './crt-ks-fact-find.store';
import { CrtKiwiSaverRiskProfileQuestionOutcome } from './risk-profile/risk-profile-outcomes.store';
import { CrtKiwiSaverRiskProfile } from './risk-profile/risk-profile.store';
import { FactFindSubpagesSettingsState } from '@modules/kiwisaver-settings/fact-find-settings/subpages/state/fact-find-subpages-settings.model';

@Injectable({
	providedIn: 'root',
})
export class CrtKiwiSaverFactFindResolver implements CanActivate {
	constructor(
		private crtKsFactFindService: CrtKsFactFindService,
		private crtKSService: CrtKiwiSaverService,
		private crtKiwiSaverRiskProfileService: CrtKiwiSaverRiskProfileService,
		private facFindSettingsService: FactFindSubpagesSettingsService,
		private crtKiwisaverFactFindStore: CrtKiwisaverFactFindStore
	) {}

	private getKOATFFData(adviceProcessId: number) {
		return this.crtKsFactFindService
			.getFactFind(adviceProcessId)
			.pipe(tap((ffData) => this.crtKiwisaverFactFindStore.setData(ffData)));
	}

	private getData(adviceProcessId: number) {
		const koatFF$ = this.getKOATFFData(adviceProcessId).pipe(
			mergeMap((factFind) => {
				if (factFind) {
					return of(factFind);
				}
				return this.crtKsFactFindService
					.addFactFind({
						adviceProcessId,
						sectionCode: 'KOATFF',
						currentPage: '1',
					})
					.pipe(
						tap(() => this.crtKSService.isTabLoading(true)),
						mergeMap(() => this.getKOATFFData(adviceProcessId)),
						finalize(() => this.crtKSService.isTabLoading(false))
					);
			})
		);

		return forkJoin([
			koatFF$,
			this.crtKsFactFindService.getPeople(adviceProcessId, 'FPP'),
			this.crtKsFactFindService.getInformationGathered(adviceProcessId),
			this.crtKiwiSaverRiskProfileService.getAll(adviceProcessId),
			this.crtKiwiSaverRiskProfileService.getOutComes(),
			this.crtKsFactFindService.getProviders(adviceProcessId),
			this.facFindSettingsService
				.getFactFindSubpagesSettings(0, SettingsTypes.KOATFactFind)
				.pipe(
					tap((settings) =>
						this.crtKiwisaverFactFindStore.setSettings(settings)
					),
				),
		]);
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.getData(+route.params.adviceProcessId).pipe(map(() => true));
	}
}
