import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { DeclarationEmailSettingsService } from './declaration-email-settings/state/declaration-email-settings.service';


@Component({
  selector: 'app-declaration-settings',
  templateUrl: './declaration-settings.component.html',
  styleUrls: ['./declaration-settings.component.scss'],
})
export class DeclarationSettingsComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  emailSettings$ = this.declarationEmailService.declarationEmailSettings$;
  mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);

  constructor(
    private declarationEmailService: DeclarationEmailSettingsService
  ) {}

  ngOnInit(): void {
    this.prepData();
  }

  prepData() {
    combineLatest([
      this.emailSettings$
    ]).pipe(
      map(([dec]) => MergeTagsMapper.mapMergeTags(dec?.mergeTag || [])),
      tap((mt) => this.mergeTags$.next(mt)),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
