<!-- Former name: Lead Conversion -->
<app-secondary-base-widget
	headerText="N.B. Conversion"
	[widgetCode]="widgetCode"
	class="nb-conversion"
>
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<app-date-range
		ngProjectAs="input"
		[formControl]="dateControl"
	></app-date-range>
	<!-- Former Lead Conversion Widget -->
	<!-- <app-gauge-chart
		[parts]="parts$ | async"client-leads
		[actual]="convertions$ | async"
		[target]="target$ | async"
		[title]="convertionPercent$ | async"
		[sub]="fortmatToCount(clients$ | async) + ' / ' + fortmatToCount(leads$ | async)"
	></app-gauge-chart> -->

	<!-- L&R Conversion -->
	<div *ngIf="!(isLoading$ | async)" class="nb-conversion-widget">
		<div class="form-row">
			<div class="col-12 col-lg-8">
				<label class="mt-2 conversion-name">Life & Risk</label>
			</div>
			<div class="d-none d-lg-block col-lg-4 text-right pr-3">
				<label class="mt-2 conversion-name">Leads</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart
					[barData]="[(lrConversion$ | async)?.Conversion|| 0]"
					[targetValue]="(lrConversion$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(lrConversion$ | async)?.Activities || 0
				}}</span>
			</div>
		</div>

		<!-- KiwiSaver Conversion -->
		<div class="form-row">
			<div class="col-12">
				<label class="mt-4 conversion-name">KiwiSaver</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart
					[barData]="[(kiwisaverConversion$ | async)?.Conversion || 0]"
					[targetValue]="(kiwisaverConversion$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(kiwisaverConversion$ | async)?.Activities || 0
				}}</span>
			</div>
		</div>

		<!-- Blanket Conversion -->
		<div class="form-row">
			<div class="col-12">
				<label class="mt-4 conversion-name">Blanket</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart
					[barData]="[(blanketConversion$ | async)?.Conversion || 0]"
					[targetValue]="(blanketConversion$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(blanketConversion$ | async)?.Activities || 0
				}}</span>
			</div>
		</div>
	</div>
</app-secondary-base-widget>
