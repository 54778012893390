<div class="row mx-0 my-3">
	<form [formGroup]="formGroup" class="row w-100">
		<div class="col-12 pl-4 es-header d-flex align-items-center">
			<label>Is it active</label>
		</div>
		<div class="col-12 pl-4 d-flex align-items-center my-2">
			<div>
				<app-input-switch
					class="tap-text-primary"
					height="20"
					width="39"
					formControlName="isActive"
				>
				</app-input-switch>
			</div>
			<div>
				<label for="" class="ml-5">Email Signature</label>
			</div>
		</div>

		<div class="col-12 pl-4 es-header d-flex align-items-center">
			<label>Content</label>
		</div>

		<div
			class="col-12 px-4 py-4"
			[ngClass]="{
			 'disabled': disableFormControls,
			 'user-select-none': disableFormControls
			}"
		>
			<div class="row m-0 p-0">
				<div class="col-12 m-0 p-0 d-flex align-items-center">
					<input
						type="checkbox"
						id="isLogoChecked"
						formcontrolname="isLogoChecked"
						class="chckbox form-control"
						formControlName="isLogoChecked"
					/>
					<label class="ml-3" for="isLogoChecked">Logo</label>
				</div>
			</div>

			<hr />

			<div class="row m-0 p-0">
				<div class="col-12 m-0 p-0 d-flex align-items-center">
					<input
						type="checkbox"
						id="isCellphoneNumberChecked"
						formcontrolname="isCellphoneNumberChecked"
						class="chckbox form-control"
						formControlName="isCellphoneNumberChecked"
					/>
					<label class="ml-3" for="isCellphoneNumberChecked"
						>Cell Phone Number</label
					>
				</div>
			</div>

			<hr />

			<div class="row m-0 p-0">
				<div class="col-12 m-0 p-0 d-flex align-items-center">
					<input
						type="checkbox"
						id="isOfficePhoneNumberChecked"
						formControlName="isOfficePhoneNumberChecked"
						class="chckbox form-control"
					/>
					<label class="ml-3" for="isOfficePhoneNumberChecked"
						>Office Phone Number</label
					>
				</div>
			</div>

			<hr />

			<div class="row m-0 p-0">
				<div class="col-12 m-0 p-0 d-flex align-items-center">
					<input
						type="checkbox"
						id="isAddressChecked"
						formControlName="isAddressChecked"
						class="chckbox form-control"
					/>
					<label class="ml-3" for="isAddressChecked">Address</label>
				</div>
			</div>

			<hr />

			<div class="row m-0 p-0">
				<div class="col-6 col-lg-2 m-0 p-0 d-flex align-items-center">
					<input
						type="checkbox"
						id="isMailAddressChecked"
						class="chckbox form-control"
						formControlName="isMailAddressChecked"
					/>
					<label class="ml-3" for="isMailAddressChecked">Mail Address</label>
				</div>

				<div class="col-6 col-lg-4 m-0 p-0 d-flex align-items-center">
					<app-input
						id="address"
						class="input-address ml-3 w-100"
						appearance="crt"
						placeholder="Address"
						formControlName="mailAddress"
					></app-input>
				</div>
			</div>

			<hr />

			<div class="row m-0 p-0">
				<div class="col-12 m-0 p-0 d-flex align-items-center">
					<input
						type="checkbox"
						id="isWebsiteURLChecked"
						formControlName="isWebsiteURLChecked"
						class="chckbox form-control"
					/>
					<label class="ml-3" for="isWebsiteURLChecked">Website URL</label>
				</div>
			</div>

			<hr />

			<div class="col col-lg-6 preview-container border row m-0 p-5">
				<div
					class="header-container row p-0 m-0 d-flex justify-content-between tap-text-primary w-100 align-items-center"
				>
					<h1 class="tap-text-primary name m-0" style="font-size: 19px">
						{{ formGroup.value.previewFullName }}
					</h1>
					<div id="preview-logo-container" class="d-flex align-items-center">
						<img
							[src]="formGroup.value.previewLogo"
							alt=""
							id="preview-logo"
							*ngIf="showLogo"
						/>
					</div>
				</div>

				<hr class="tap-text-primary w-100" />

				<div
					*ngIf="showPhone"
					class="row mt-1 align-items-center mx-0 p-0 w-100"
				>
					<span class="material-icons"> call </span>
					<span class="preview-text" *ngIf="showPhoneNumber"
						>{{ formGroup.value.previewPhoneNumber }}</span
					>
					<span *ngIf="showPhoneSeparator" class="preview-text">|</span>
					<span class="preview-text" *ngIf="showMobileNumber"
						>{{ formGroup.value.previewMobileNumber }}</span
					>
				</div>

				<div
					class="row mt-1 align-items-center mx-0 p-0 w-100"
					*ngIf="showAddress"
				>
					<span class="material-icons"> location_on </span>
					<span class="preview-text">
						{{ formGroup.value.previewAddress}}
					</span>
				</div>

				<div
					class="row mt-1 align-items-center mx-0 p-0 w-100"
					*ngIf="showMailAddress && formGroup.value.mailAddress"
				>
					<span class="material-icons"> mail_outline </span>
					<span
						class="preview-text"
						*ngIf="formGroup.value.mailAddress; else emptyTemplate"
						>{{ formGroup.value.mailAddress }}</span
					>
					<ng-template #emptyTemplate>
						<span class="preview-text"> &nbsp; </span>
					</ng-template>
				</div>

				<div
					*ngIf="showWebsiteURL"
					class="row mt-1 align-items-center mx-0 p-0 w-100 tap-text-primary"
				>
					<span class="material-icons website-icon"> link </span>
					<a
						[href]="formGroup.value.previewWebsiteURL"
						class="preview-text tap-text-primary"
						target="_blank"
					>
						{{ formGroup.value.previewWebsiteURL }}
					</a>
				</div>
			</div>
		</div>
	</form>
	<div class="col-12 col-lg-6 pl-2 m-0 d-flex justify-content-end">
		<ng-container *ngIf="!isSaving; else spinner">
			<button
				class="btn btn-primary save-btn"
				(click)="save()"
				[disabled]="isSaving ? '' : null"
			>
				Save
			</button>
		</ng-container>
		<ng-template #spinner>
			<app-manual-loader-mini
				*ngIf="isSaving"
				[isLoading]="isSaving"
			></app-manual-loader-mini>
		</ng-template>
	</div>
</div>
