import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	OnChanges,
	ChangeDetectorRef,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UploadModalComponent } from '../../../shared/modal/upload-modal/upload-modal.component';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';
import { Subject, Observer } from 'rxjs';
import { TransferModalComponent } from '../../../shared/modal/transfer-modal/transfer-modal.component';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { DocumentModelState } from '../../models/documents/document.model';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ServicesCodes } from '../../models/services/services.model';
import { RouteService } from '../../../core/config/route.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-edit-document',
	templateUrl: './edit-document.component.html',
	styleUrls: ['./edit-document.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDocumentComponent implements OnInit, OnChanges {
	@Input() isLead: boolean;
	@Input() customerId: number;
	@Input() document: DocumentGroupState;
	@Input() transferDocFn$: ({ doc, doctype }) => Observable<any>;
	@Input() downloadLink$: ({ documentID }) => Observable<string>;
	@Input() uploadDocFn$: ({
		doc,
		doctype,
		customerId,
	}) => Observable<any>;
	@Input() deleteDocFn$: (doc) => Observable<any>;
	@Input() hasLR: boolean;
	@Input() hasM: boolean;
	@Input() hasFG: boolean;
	@Input() hasK: boolean;
	@Input() hasI: boolean;
	@Input() isLoading: boolean;
	@Input() hasPermission: boolean;
	@Input() hasAP: boolean;
  @Input() hasCustomerPortalDocument: boolean;

	activeDocType: string;
	companyCode: string;
	request: any;

	currentpage = 1;
	page: number;
	filteredListLR: DocumentModelState[];
	filteredListM: DocumentModelState[];
	filteredListFG: DocumentModelState[];
	filteredListK: DocumentModelState[];
	filteredListO: DocumentModelState[];
	filteredListAP: DocumentModelState[];
	filteredListCP: DocumentModelState[];

	listPerPageLR: any[];
	listPerPageM: any[];
	listPerPageFG: any[];
	listPerPageK: any[];
	listPerPageO: any[];
	listPerPageAP: any[];
  listPerPageCP: any[];

	itemsPerPage = 10;
	types: ViewDisplayValue[];
	isSaving = false;
	private tabSet: TabsetComponent;

	@ViewChild(TabsetComponent) set content(content: TabsetComponent) {
		if (content) {
				this.tabSet = content;
		}
	}
	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private routeService: RouteService,
		private router: Router
	) {}

	get getDocument() {
		return !!this.document ? this.document : null;
	}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.document && changes.document.currentValue !== undefined) {
			this.filteredListLR =
				this.document && this.document.lR && this.document.lR.length > 0
					? this.document.lR
					: [];
			this.filteredListM =
				this.document && this.document.m && this.document.m.length > 0
					? this.document.m
					: [];
			this.filteredListFG =
				this.document && this.document.fG && this.document.fG.length > 0
					? this.document.fG
					: [];
			this.filteredListK =
				this.document && this.document.k && this.document.k.length > 0
					? this.document.k
					: [];
			this.filteredListO =
				this.document && this.document.o && this.document.o.length > 0
					? this.document.o
					: [];
			this.filteredListAP =
				this.document && this.document.aP && this.document.aP.length > 0
					? this.document.aP
					: [];
			this.filteredListCP =
				this.document && this.document.cP && this.document.cP.length > 0
					? this.document.cP
					: [];


			const typeKeys = this.document ? Object.keys(this.document) : [];
			this.types = typeKeys?.map((x) => {
				return ViewDisplayValue.Map(
					x,
					x === 'lR' && this.hasLR
						? 'L&R Insurance'
						: x === 'm' && this.hasM
						? 'Mortgage'
						: x === 'fG' && this.hasFG
						? 'F&G Insurance'
						: x === 'k' && this.hasK
						? 'Investment'
						: x === 'o'
						? 'Others'
						: x === 'cP' || x === 'cP'
						? 'Client Portal'
						: x === 'ap' || x === 'aP'
						? 'Advice Process'
						: null
				);
			});

			if (this.getDocument && this.activeDocType) {
				this.setPage(
					{ page: this.currentpage, itemsPerPage: this.itemsPerPage },
					this.activeDocType.toLowerCase()
				);
			}

			if (!this.tabSet) {
				this.refresh();
			}
			// tslint:disable-next-line: align
			if (this.getDocument && !this.activeDocType) {
				const activeTab = this.tabSet?.tabs?.find((tab) => tab?.active);
				this.activeDocType = activeTab?.id?.toUpperCase();
				this.setPage(
					{ page: this.currentpage, itemsPerPage: this.itemsPerPage },
					activeTab?.id
				);
			}
		}
	}

	refresh() {
		this.cd.detectChanges();
	}

	trackById(index: number, item) {
		return item.id;
	}

	setPage(event: PageChangedEvent, type: string): void {
		const page = !!event.page ? event.page : 1;
		this.currentpage = page;
		const startItem = (page - 1) * event.itemsPerPage;
		const endItem = page * event.itemsPerPage;
		if (type === ServicesCodes.LR?.toLowerCase()) {
			this.listPerPageLR = this.getDocument.lR?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.Mortgage?.toLowerCase()) {
			this.listPerPageM = this.getDocument.m?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.FG?.toLowerCase()) {
			this.listPerPageFG = this.getDocument.fG?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.KiwiSaver?.toLowerCase()) {
			this.listPerPageK = this.getDocument.k?.slice(startItem, endItem);
		}
		if (type === 'o') {
			this.listPerPageO = this.getDocument.o?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.AdviceProcess?.toLowerCase()) {
			this.listPerPageAP = this.getDocument.aP?.slice(startItem, endItem);
		}
    if (type === ServicesCodes.CustomerPortal?.toLowerCase()) {
			this.listPerPageCP = this.getDocument.cP?.slice(startItem, endItem);
    }
		this.refresh();
	}

	selectType(type: string) {
		this.activeDocType = type;
		this.setPage(
			{ page: 1, itemsPerPage: this.itemsPerPage },
			type.toLowerCase()
		);
	}

	downloadLink = (data) => {
		if (data) {
			if (data?.fileExtension?.toLowerCase() === '.pdf') {
				const pdfUrl = this.router.serializeUrl(
					this.router.createUrlTree(
						this.routeService.viewPdf({
							documentId: data?.id,
							name: data?.fileName,
						})
					)
				);

				window.open(pdfUrl, '_blank');
			} else {
				window.location.href = data?.documentLink;
			}
		}
	};

	upload(req, dataForFrontUpdate): Observable<any> {
		this.refresh();
		return this.uploadDocFn$({
			doc: req,
			doctype: dataForFrontUpdate,
			customerId: this.customerId,
		}).pipe(
			tap(() => {
				this.refresh();
				this.refreshPage();
			})
		);
	}

	openUploadModal() {
		const doctype = this.activeDocType;
		const upload = (req) => this.upload(req, doctype);

		const initState = {
			upload,
			data: {
				document_type: doctype,
				customer_id: this.customerId,
			},
			headerTitle: 'Upload Document',
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	transferDocumentConfirm(doc: any) {
		const confirm: Subject<string> = new Subject();
		const newTypes = this.types?.filter(
			(x) =>
				x.display &&
				x.value?.toLowerCase() !== doc.documentTypeCode?.toLowerCase() &&
				x.value?.toLowerCase() !== 'ad' &&
				x.value?.toLowerCase() !== 'od' &&
				x.value?.toLowerCase() !== 'xd' &&
				x.value?.toLowerCase() !== 'ap' &&
				x.value?.toLowerCase() !== 'cp'
		);

		const initState = {
			message: `'${doc.fileName}' will be transferred, are you sure?`,
			transferSubject: confirm,
			types: newTypes,
		};
		this.modalService.show(TransferModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		confirm.asObservable().subscribe((x) => this.transferDocument(doc, x));
	}

	transferDocument(doc, type) {
		this.isSaving = true;
		this.refresh();
		this.transferDocFn$({ doc, doctype: type }).subscribe(() => {
			this.isSaving = false;
			this.refresh();
			this.refreshPage();
		});
	}

	delete(doc) {
		this.isSaving = true;
		this.refresh();
		this.deleteDocFn$(doc).subscribe(() => {
			this.isSaving = false;
			this.refresh();
			this.refreshPage();
		});
	}

	deleteConfirm(doc: any) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete(doc);
			obs.complete();
		});
		const initState = {
			header: 'Delete',
			message: `Are you sure you want to delete?`,
			delete$: confirm,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	refreshPage() {
		if (this.activeDocType === ServicesCodes.LR) {
			this.currentpage =
				this.document.lR && this.document.lR.length > 0
					? this.currentpage <=
					  Math.ceil((this.document.lR.length - 1) / this.itemsPerPage)
						? this.currentpage
						: this.currentpage >
						  Math.ceil((this.document.lR.length - 1) / this.itemsPerPage)
						? Math.ceil((this.document.lR.length - 1) / this.itemsPerPage)
						: 1
					: 1;
		}
		if (this.activeDocType === ServicesCodes.Mortgage) {
			this.currentpage =
				this.document.m && this.document.m.length > 0
					? this.currentpage <=
					  Math.ceil((this.document.m.length - 1) / this.itemsPerPage)
						? this.currentpage
						: this.currentpage >
						  Math.ceil((this.document.m.length - 1) / this.itemsPerPage)
						? Math.ceil((this.document.m.length - 1) / this.itemsPerPage)
						: 1
					: 1;
		}
		if (this.activeDocType === ServicesCodes.FG) {
			this.currentpage =
				this.document.fG && this.document.fG.length > 0
					? this.currentpage <=
					  Math.ceil((this.document.fG.length - 1) / this.itemsPerPage)
						? this.currentpage
						: this.currentpage >
						  Math.ceil((this.document.fG.length - 1) / this.itemsPerPage)
						? Math.ceil((this.document.fG.length - 1) / this.itemsPerPage)
						: 1
					: 1;
		}
		if (this.activeDocType === ServicesCodes.KiwiSaver) {
			this.currentpage =
				this.document.k && this.document.k.length > 0
					? this.currentpage <=
					  Math.ceil((this.document.k.length - 1) / this.itemsPerPage)
						? this.currentpage
						: this.currentpage >
						  Math.ceil((this.document.k.length - 1) / this.itemsPerPage)
						? Math.ceil((this.document.k.length - 1) / this.itemsPerPage)
						: 1
					: 1;
		}
		if (this.activeDocType === 'O') {
			this.currentpage =
				this.document.o && this.document.o.length > 0
					? this.currentpage <=
					  Math.ceil((this.document.o.length - 1) / this.itemsPerPage)
						? this.currentpage
						: this.currentpage >
						  Math.ceil((this.document.o.length - 1) / this.itemsPerPage)
						? Math.ceil((this.document.o.length - 1) / this.itemsPerPage)
						: 1
					: 1;
		}
		if (this.activeDocType === ServicesCodes.AdviceProcess) {
			this.currentpage =
				this.document.aP && this.document.aP.length > 0
					? this.currentpage <=
					  Math.ceil((this.document.aP.length - 1) / this.itemsPerPage)
						? this.currentpage
						: this.currentpage >
						  Math.ceil((this.document.aP.length - 1) / this.itemsPerPage)
						? Math.ceil((this.document.aP.length - 1) / this.itemsPerPage)
						: 1
					: 1;
		}
		if (this.activeDocType === ServicesCodes.CustomerPortal) {
			this.currentpage =
				this.document.cP && this.document.cP.length > 0
					? this.currentpage <=
					  Math.ceil((this.document.cP.length - 1) / this.itemsPerPage)
						? this.currentpage
						: this.currentpage >
						  Math.ceil((this.document.cP.length - 1) / this.itemsPerPage)
						? Math.ceil((this.document.cP.length - 1) / this.itemsPerPage)
						: 1
					: 1;
		}
		this.cd.detectChanges();
		this.setPage(
			{ page: this.currentpage, itemsPerPage: this.itemsPerPage },
			this.activeDocType?.toLowerCase()
		);
	}
}
