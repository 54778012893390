import { SimpleDataTableModel } from '@models/_general/simple-data-table.model';

export const displayedColumns = [
	'RequirementName',
	'DueDate',
	'ProviderBusiness',
	'Category',
	'StructuredHours',
	'EditAction',
];

export const tableColumns: SimpleDataTableModel[] = [
	{
		columnId: 'RequirementName',
		columnName: 'Requirement',
		headerCellClass: '',
		bodyCellClass: '',
		width: '15%',
		type: 'text',
	},
	{
		columnId: 'DueDate',
		columnName: 'Due',
		hasOwnClass: true,
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'moment',
	},
	{
		columnId: 'ProviderBusiness',
		columnName: 'Provider/Business',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
	{
		columnId: 'Category',
		columnName: 'Category',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
	{
		columnId: 'StructuredHours',
		columnName: 'Est. Hours',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
	{
		columnId: 'EditAction',
		columnName: '',
		actionCallback: null,
		actionCallbackText: 'Edit or Complete',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'action',
	},
];
