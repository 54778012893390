import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SendManualEmailService } from '../send-manual-email.service';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { util } from '../../../../core/util/util.service';
import { SendManualEmail } from '../../../../shared/models/emails/send-manual-email/send-manual-email.model';
import { Observable, Observer, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { logMessage } from 'src/app/shared/error-message/error-message';

declare var $;

@Component({
  selector: 'app-send-manual-email-form',
  templateUrl: './send-manual-email-form.component.html',
})
export class SendManualEmailFormComponent implements OnInit, OnChanges {
  private onDestroy$ = new Subject<void>();
  @Input() emailTypes: ViewDisplayValue[];
  form: UntypedFormGroup;
  prepData: SendManualEmail;
  isSaving = false;
  isRestart = false;
  isSent = false;
  emailTypeValue: string;
  public bsModalRef: BsModalRef;

  constructor(
    private fb: UntypedFormBuilder,
    private sendManualEmailService: SendManualEmailService,
    private modalService: BsModalService,
    private loggerService: LoggerService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.emailTypeValue) {
      setTimeout(() => {
        this.form.controls.emailType.setValue([this.emailTypeValue]);
      }, 1);
    }
  }

  buildForm() {
    this.form = this.fb.group({
      emailType: ['', Validators.required],
      dateTarget: ['', Validators.required],
    });
  }

  get EmailType() {
    return this.form.get('emailType');
  }
  get DateTarget() {
    return this.form.get('dateTarget');
  }

  selectOption(event) {
    this.emailTypeValue = event.target.value;
  }

  prepareData(values): SendManualEmail {
    return (this.prepData = {
      EmailType: values.emailType ? values.emailType : '',
      DateTarget: values.dateTarget
        ? util.MomentToDateString(values.dateTarget)
        : '',
    });
  }

  sendBtnClick() {
    const emailTypeName = this.emailTypes?.filter(
      (x) => x.value === this.emailTypeValue
    )[0].display;

    if (this.form.invalid) {
      return;
    }
    const confirm = new Observable((obs: Observer<any>) => {
      this.sendYes();
      obs.complete();
    });
    const decline = new Observable((obs: Observer<any>) => {
      obs.complete();
    });
    const initState = {
      header: '',
      message: `Are you sure you want to send a manual email?`,
      secondaryMessage: `${emailTypeName} -
			${util.DateStringToDateStringDisplay(this.DateTarget.value)}`,
      confirm$: confirm,
      decline$: decline,
    };
    this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog-centered modal-dialog',
      initialState: initState,
      ignoreBackdropClick: true,
    });
  }

  sendYes() {
    if (this.form.invalid) {
      return;
    }
    this.isSaving = true;
    this.isRestart = false;
    const values = this.prepareData(this.form.value);
    this.sendManualEmailService
      .send(values)
      .pipe(
        tap(
          () => {
            $('#mi-modal').modal('hide');
            this.isSent = true;
          },
          (error) => {
            this.loggerService.Log({}, logMessage.settings.error.sendEmailFail);
            this.isRestart = true;
          }
        ),
        finalize(() => {
          this.isSaving = false;
          setTimeout(() => {
            this.isRestart = false;
            this.isSent = false;
          }, 3000);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }
}
