export enum SpecialFeaturesSettingsCode {
	CC = 'Confirmation Call',
	CAR = 'Client Alteration Request',
}

export interface SpecialFeaturesSettingsModel {
	SpecialFeature: string[];
}

// For boolean feature toggle
export interface FeatureToggleModel {
	MetaKey: string;
	Value: boolean;
}

