import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeleteModalComponent } from '@shared/modal/delete-modal/delete-modal.component';
import { NoteAddModalComponent } from '@shared/modal/note-modal/note-modal.component';
import { LiabilityCustomerServiceState } from '@shared/models/services/assets/assets';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { clone } from 'ramda';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { LiabilityServiceFormComponent } from '../liability-service-form/liability-service-form.component';

@Component({
	selector: 'app-liability-service',
	templateUrl: './liability-service.component.html',
	styleUrls: ['./liability-service.component.scss'],
})
export class LiabilityServiceComponent implements OnInit, OnDestroy {
	@Input() hasLiabilities = true;
	@Input() addNewLiability = false;

	private _liabilities: LiabilityCustomerServiceState[];
	@Input()
	set liabilities(liabilities: LiabilityCustomerServiceState[]) {
		this._liabilities = liabilities;
	}
	get liabilities() {
		return this._liabilities;
	}

	@Output() onCancelAddOrEdit = new EventEmitter<any>();
	@Output() onAddLiability = new EventEmitter<any>();
	@Output() onEditLiability = new EventEmitter<any>();

	@Input() liabilityStatus: ViewDisplayValue[];
	@Input() borrowers: ViewDisplayValue[];
	@Input() loanTypes: ViewDisplayValue[];
	@Input() lenders: ViewDisplayValue[];
	@Input() repaymentFrequency: ViewDisplayValue[];
	@Input() liabilityChoices: ViewDisplayValue[];

	@Input() customerID: number;

	@Input() isLead = false;

	@Input() type: 'current' | 'discharged' | '' = 'current';

	@Input() saveFn$: (
		liability: LiabilityCustomerServiceState
	) => Observable<boolean>;

	@Input() updateFn$: (
		liability: LiabilityCustomerServiceState
	) => Observable<boolean>;

	@Input() deleteFn$: (
		liability: LiabilityCustomerServiceState
	) => Observable<boolean>;

	@Input() notes: any[];

	@Output() addNoteEvent = new EventEmitter<any>();

	@Output() deleteNoteEvent = new EventEmitter<any>();

	isLoading: boolean;
	isAdd: boolean;
	bsModalRef: BsModalRef;
	isEditing = false;

	constructor(
		private cdr: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		private modalService: BsModalService
	) {}

	ngOnInit(): void {
		// if (this.liabilities.length) {
		// 	// reset liabilities edit or add state upon Assets & Liabilities tab navigation
		// 	this.onCancelAddOrEdit.emit(0);
		// }
	}

	trackByFn = (index: number, data: LiabilityCustomerServiceState) => {
		return data.customerServiceID;
	};

	addNew(): void {
		this.isAdd = true;
		const unsavedLiability: LiabilityCustomerServiceState = {
			serviceCode: 'L',
			customerID: this.activatedRoute.snapshot.params.customerId,
			customerServiceID: 0,
			borrower: null,
			liability: null,
			loanType: null,
			lender: null,
			interestRate: null,
			loanBalance: null,
			loanLimit: null,
			loanRepayment: null,
			repaymentFrequency: null,
			status: this.type === 'current' ? 'Current Liability' : 'Discharged',
			isAdd: true,
		};
		if (this.liabilities?.length) {
			this.liabilities.push(unsavedLiability);
		} else {
			this.liabilities = [unsavedLiability];
		}
		this.cdr.detectChanges();
	}

	disableEditing(): void {
		// disable editing when navigated to another tab
		this.liabilities?.forEach((liability, index) => {
			if (liability.isEditing) {
				this.cancelAddOrEdit(index);
			}
		});
	}

	disableAdding(): void {
		// disable editing when navigated to another tab
		this.liabilities?.forEach((liability, index) => {
			if (liability.isAdd) {
				this.cancelAddOrEdit(index);
				this.onCancelAddOrEdit.emit(index);
			}
		});
	}

	edit(index: number): void {
		const liability = {
			...this.liabilities[index],
			isEditing: true,
		};
		this.liabilities[index] = liability;
		this.isEditing = true;
		this.cdr.detectChanges();

		this.onEditLiability.emit(true);
	}

	cancelAddOrEdit(index: number): void {
		if (this.liabilities[index].isAdd) {
			this.liabilities.splice(index, 1);
			this.isAdd = false;
		} else {
			this.liabilities[index] = {
				...this.liabilities[index],
				isEditing: false,
			};
		}
		this.isEditing = false;
		this.cdr.detectChanges();

		this.onCancelAddOrEdit.emit(index);
	}

	updateIsDeleting(index: number, isDeleting: boolean): void {
		this.liabilities[index] = {
			...this.liabilities[index],
			isDeleting: isDeleting,
		};
		this.cdr.detectChanges();
	}

	save(
		index: number,
		liabilityServiceForm: LiabilityServiceFormComponent
	): void {
		this.isLoading = true;
		this.cdr.detectChanges();
		liabilityServiceForm
			.save()
			.pipe(
				take(1),
				tap(() => {
					if (liabilityServiceForm.formGroup.valid) {
						this.cancelAddOrEdit(index);
					}
				}),
				catchError((err) => {
					return throwError(err);
				}),
				finalize(() => {
					this.isLoading = false;
					this.cdr.detectChanges();
				})
			)
			.subscribe();
	}

	delete(index: number): void {
		const confirm = new Observable((obs: Observer<any>) => {
			this.isLoading = true;
			this.cdr.detectChanges();
			this.updateIsDeleting(index, true);
			this.deleteFn$(this.liabilities[index])
				.pipe(
					take(1),
					finalize(() => {
						this.isLoading = false;
						this.cdr.detectChanges();
						this.updateIsDeleting(index, false);
					})
				)
				.subscribe();
			obs.complete();
		});
		const initState = {
			header: 'Delete Liability',
			message: `Are you sure you want to delete this?`,
			delete$: confirm,
			canDelete: true,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteModal(index: number) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete(index);
			obs.complete();
		});
		const initState = {
			header: 'Delete Liability',
			message: `Are you sure you want to delete this?`,
			delete$: confirm,
			canDelete: true,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteNote$ = (data) => {
		return new Observable((obs) => {
			const clonedData = clone(data);
			this.deleteNoteEvent.emit({
				noteId: clonedData.notesID,
				data: clonedData,
			});
			obs.next();
			obs.complete();
		});
	};

	openModalAddNote() {
		const saveNote = (notes: string) =>
			new Observable((obs) => {
				const form = {
					customerID: this.customerID,
					note: notes,
				};
				this.addNoteEvent.emit(form);
				obs.next();
				obs.complete();
			});
		const initState: any = {
			header: 'Add Note',
			savefn: saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	ngOnDestroy(): void {
		if (this.isAdd) {
			this.onCancelAddOrEdit.emit(this.liabilities?.length - 1 || 0);
		}
	}
}
