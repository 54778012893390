import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService } from '../base/api.service';
import { tap, map } from 'rxjs/operators';
import {
	PrimaryClient,
	PrimaryClientState,
} from '../../shared/models/client-profile/primary-client/primary-client.model';
import {
	SecondaryClient,
	SecondaryClientState,
} from '../../shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryBusiness } from '../../shared/models/client-profile/seondary-business/secondary-business.model';
import {
	SecondaryTrust,
	SecondaryTrustState,
} from '../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { LrInsurance } from '../../shared/models/services/lr-insurance/lr-provider-group.model';
import { LrCustomerServiceState } from '../../shared/models/services/lr-insurance/lr-insurance.model';
import {
	AddNote,
	GetNotes,
	NoteResponse,
} from '../../shared/models/notes/note-params.model';
import {
	SecondaryProfessional,
	SecondaryProfessionalState,
} from '../../shared/models/client-profile/secondary-professional/secondary-professional.model';
import { Mortgage } from '../../shared/models/services/mortgage/mortgage-group.model';
import { DocumentGroup } from '../../shared/models/documents/document-group.model';
import { MortgageCustomerServiceState } from '../../shared/models/services/mortgage/mortgage.model';
import { PropertyAssetCustomerServiceState } from '../../shared/models/services/property-asset/property-asset';
import { FgCustomerServiceState } from '../../shared/models/services/fg-insurance/fg-insurance.model';
import {
	KiwisaverState,
	Kiwisaver,
} from '../../shared/models/services/kiwisaver/kiwisaver.model';
import { ActivityTimeline } from '../../shared/models/activity-timeline/activity-timeline.model';
import { EditHistory } from '../../shared/models/history/history.model';
import { CurrentActivityCriteria } from '../../shared/models/current-activity-criteria/current-activity-criteria.model';
import {
	Customer,
	CustomerDelete,
	SecondaryCustomerIndividualUpdate,
	SecondaryCustomerIndividualAdd,
	SecondaryCustomerIndividualDelete,
	SecondaryCustomerTrustAdd,
	SecondaryCustomerTrustUpdate,
	SecondaryCustomerTrustDelete,
	SecondaryCustomerProfessionalUpdate,
	SecondaryCustomerProfessionalAdd,
	SecondaryCustomerProfessionalDelete,
} from '../../shared/models/_general/client-customer/customer';
import {
	LrInsuranceService,
	SecurityService,
	MortgageService,
	FgInsuranceService,
	KiwisaverService,
} from './lrInsurance.service';
import {
	PrimaryCustomerUpdate,
	PrimaryCustomerAdd,
} from '../../shared/models/_general/client-customer/request/client-customer-update.model';
import { objectUtil } from 'src/app/util/util';
import {
	AdviceProcess,
	ServiceAdviceProcess,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { AssetCustomerServiceState, LiabilityCustomerServiceState } from 'src/app/shared/models/services/assets/assets';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { Investment, InvestmentState } from 'src/app/shared/models/services/investments/investments.model';
import { LinkedContact, LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { ClientReviewTemplateContacts } from '@modules/crm/client-review-template/states/client-review-template.store';

@Injectable()
export class CustomerService {
	LrInsuranceService: LrInsuranceService;
	SecurityService: SecurityService;
	MortgageService: MortgageService;
	FgInsuranceService: FgInsuranceService;
	KiwisaverService: KiwisaverService;
	constructor(private api: ApiService) {
		this.LrInsuranceService = new LrInsuranceService(api);
		this.SecurityService = new SecurityService(api);
		this.MortgageService = new MortgageService(api);
		this.FgInsuranceService = new FgInsuranceService(api);
		this.KiwisaverService = new KiwisaverService(api);
	}
	Customer = new ReplaySubject<Customer>(1);
	/** @deprecated old fetch */
	GetCustomer(id: number) {
		const endpoint = 'Customer/GetPrimaryCustomerIndividual';
		const body = {
			customerId: id,
		};
		return this.api
			.post3<Customer>(endpoint, body)
			.pipe(tap((x) => this.Customer.next(x)));
	}

	GetContacts(customerId: number): Observable<ClientReviewTemplateContacts> {
		const endpoint = `contacts/group/${customerId}`;
		return this.api.get<ClientReviewTemplateContacts>(endpoint);
	}

	GetPrimaryClient(id: number) {
		const endpoint = `contacts/${id}`;
		return this.api.get<PrimaryClient>(endpoint);
	}
	DeactivateCustomer(customer: CustomerDelete) {
		const endpoint = 'customer/DeactivateCustomer';
		return this.api.post3<any>(endpoint, customer);
	}
	SavePrimaryCustomerEdit(customer: PrimaryCustomerUpdate) {
		const endpoint = 'Customer/UpdatePrimaryCustomerIndividual';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	UpdatePrimaryClient(customer: PrimaryClientState) {
		const endpoint = `contacts/${customer.customerID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.put<any>(endpoint, body);
	}
	SavePrimaryCustomerAdd(customer: PrimaryCustomerAdd) {
		const endpoint = 'Customer/AddPrimaryCustomerIndividual';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	SavePrimaryClient(customer: PrimaryClientState) {
		const endpoint = 'contacts';
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.post3<any>(endpoint, body);
	}
	DeactivatePrimaryCustomer(customer: CustomerDelete) {
		const endpoint = 'customer/DeactivateCustomer';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}

	// Secondary Individual
	GetSecondaryClientsByPrimaryClient(id: number) {
		const endpoint = `contacts/${id}/secondary-customers?type=sci`;
		const a = this.api.get<SecondaryClient[]>(endpoint);
		return a;
	}
	UpdateSecondaryIndividual(customer: SecondaryCustomerIndividualUpdate) {
		const endpoint = 'customer/UpdateSecondaryCustomerIndividual';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	UpdateSecondaryClient(customer: SecondaryClientState) {
		const endpoint = `contacts/${customer.customerID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.put<any>(endpoint, body);
	}
	AddSecondaryIndividual(customer: SecondaryCustomerIndividualAdd) {
		const endpoint = 'customer/AddSecondaryCustomerIndividual';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	AddSecondaryClient(customer: SecondaryClientState) {
		const endpoint = `contacts`;
		const body = objectUtil.mapCamelCaseToPascalCase(customer);

		return this.api.post3<any>(endpoint, body);
	}
	DeactivateSecondaryIndividual(customer: SecondaryCustomerIndividualDelete) {
		const endpoint = 'Customer/DeactivateCustomer';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	DeactivateSecondaryClient(req: SecondaryClientState) {
		const endpoint = `contacts/${req.customerID}`;
		return this.api.delete<any>(endpoint);
	}
	TransferSCI(customer: SecondaryClientState) {
		const endpoint = `contacts/${customer.customerID}/transfer`;
		return this.api.put<any>(endpoint);
	}
	CopySecondaryClient(id: number) {
		const endpoint = `contacts/${id}/copy`;
		return this.api.put<any>(endpoint);
	}

	UpdateCustomerDetail(customerID:string, metaDetails: any) {
		const endpoint = `contacts/${customerID}/?isPatch=true`;
		const body = metaDetails;
		return this.api.put<any>(endpoint, body);
	}

	// Secondary Business / Company
	GetSecondaryBusinessesByPrimaryClient(id: number) {
		const endpoint = `contacts/${id}/companies`;
		return this.api.get<SecondaryBusiness[]>(endpoint);
	}
	// Secondary Trust
	GetSecondaryTrustByPrimaryClient(id: number) {
		const endpoint = `contacts/${id}/secondary-customers?type=sct`;
		return this.api.get<SecondaryTrust[]>(endpoint);
	}
	AddSecondaryTrust(customer: SecondaryCustomerTrustAdd) {
		const endpoint = 'Customer/AddSecondaryCustomerTrust';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	AddSecondaryTrustByPrimaryClient(customer: SecondaryTrustState) {
		const endpoint = 'contacts';
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.post3<any>(endpoint, body);
	}
	UpdateSecondaryTrust(customer: SecondaryCustomerTrustUpdate) {
		const endpoint = 'Customer/UpdateSecondaryCustomerTrust';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	UpdateSecondaryTrustByPrimaryClient(customer: SecondaryTrustState) {
		const endpoint = `contacts/${customer.customerID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.put<any>(endpoint, body);
	}
	DeactivateSecondaryTrust(customer: SecondaryCustomerTrustDelete) {
		const endpoint = 'Customer/DeactivateCustomer';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	DeactivateSecondaryTrustByPrimaryClient(customer: SecondaryTrustState) {
		const endpoint = `contacts/${customer.customerID}`;
		return this.api.delete<any>(endpoint);
	}

	// Secondary Professional
	GetSecondaryProfessionalsByPrimaryClient(id: number) {
		const endpoint = `contacts/${id}/secondary-customers?type=scp`;
		return this.api.get<SecondaryProfessional[]>(endpoint);
	}
	UpdateSecondaryProfessional(customer: SecondaryCustomerProfessionalUpdate) {
		const endpoint = 'Customer/UpdateSecondaryCustomerProfessional';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	UpdateSecondaryProfessionalByPrimaryClient(
		customer: SecondaryProfessionalState
	) {
		const endpoint = `contacts/${customer.customerID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.put<any>(endpoint, body);
	}
	AddSecondaryProfessional(customer: SecondaryCustomerProfessionalAdd) {
		const endpoint = 'Customer/AddSecondaryCustomerProfessional';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	AddSecondaryProfessionalByPrimaryClient(
		customer: SecondaryProfessionalState
	) {
		const endpoint = 'contacts';
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.post3<any>(endpoint, body);
	}
	DeactivateSecondaryProfessional(
		customer: SecondaryCustomerProfessionalDelete
	) {
		const endpoint = 'Customer/DeactivateCustomer';
		const body = customer;
		return this.api.post3<any>(endpoint, body);
	}
	DeactivateSecondaryProfessionalByPrimaryClient(
		customer: SecondaryProfessionalState
	) {
		const endpoint = `contacts/${customer.customerID}`;
		return this.api.delete<any>(endpoint);
	}

	// Get notes
	GetNotes(request: GetNotes) {
		const endpoint = `contacts/${request.CustomerID}/notes?pageNumber=${request.PageNumber}&pageSize=${request.PageSize}`;
		return this.api.get<NoteResponse>(endpoint);
	}

	// Notes
	GetClientNotes(id: number) {
		const endpoint = `contacts/${id}/notes/0`;
		return this.api.get<any>(endpoint);
	}

	// Add note
	AddNote(request: AddNote) {
		const endpoint = 'notes';
		const body = objectUtil.mapCamelCaseToPascalCase(request);
		return this.api.post3<any>(endpoint, body);
	}

	PinNote = (noteId: number, pin: boolean, isAp?: boolean) => {
		const endpoint = !isAp
			? `notes/pin/${noteId}/${pin}`
			: `notes/pin/${noteId}?type=crt&pin=${pin}`;
		const body = null;
		return this.api.put<any>(endpoint, body);
	};

	// Document
	GetDocumentsClientId(id: number) {
		const endpoint = `contacts/${id}/documents?isGrouped=true`;
		return this.api.get<DocumentGroup>(endpoint);
	}

	GetActivityTimeline(customerId: number) {
		const endpoint = `contacts/${customerId}/activity-timeline`;
		return this.api.get<ActivityTimeline>(endpoint);
	}

	TransferDocument(req) {
		const endpoint = `documents/${req.Id}`;
		return this.api.put<any>(endpoint, req);
	}

	UploadDocument(req) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

  UploadCombineDocument(req) {
		const endpoint = 'documents/combine';
		return this.api.post3<any>(endpoint, req);
	}

	UpdateDocument(req, id: number) {
		const endpoint = `documents/${id}`;
		return this.api.put<any>(endpoint, req);
	}

	DeactivateDocument(id) {
		const endpoint = `documents/${id}`;
		return this.api.delete<any>(endpoint);
	}

	GetHistoriesClientId(id: number) {
		const endpoint = `contacts/${id}/notes/0/history`;
		return this.api.get<EditHistory[]>(endpoint);
	}

	GetCriteriaClientId(id: number) {
		const endpoint = `contacts/${id}/services/service-timeline`;
		return this.api.get<CurrentActivityCriteria[]>(endpoint);
	}
	// Services
	// Change data
	ArchiveService(req: { set_archive: boolean; customerServiceID: number }) {
		const endpoint = `Customerservice/ArchiveService`;
		const body = {
			set_archive: req.set_archive,
			customerservice_id: req.customerServiceID,
		};
		return this.api.put<any>(endpoint, body);
	}
	// LR
	GetLrInsurancesByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.LR?.toLowerCase()}&isGrouped=true`;
		return this.api.get<LrInsurance>(endpoint);
	}

	AddLrInsurance(lr: LrCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(lr);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateLrInsurance(lr: LrCustomerServiceState) {
		const endpoint = `services/${lr.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(lr);
		return this.api.put<any>(endpoint, body);
	}

	DeactivateService(id: number) {
		const endpoint = `services/${id}`;
		return this.api.delete<any>(endpoint);
	}

	// Mortage
	GetMortgagesByPrimaryClientId(id: number, isGroup = true) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Mortgage?.toLowerCase()}&isGrouped=${isGroup}`;
		return this.api.get<Mortgage | MortgageCustomerServiceState[]>(endpoint);
	}

	AddMortgage(m: MortgageCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateMortgage(m: MortgageCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	// Change data
	ArchiveMortgage(req: { set_archive: boolean; customerServiceID: number }) {
		const endpoint = `Customerservice/ArchiveService`;
		const body = {
			set_archive: req.set_archive,
			customerservice_id: req.customerServiceID,
		};
		return this.api.put<any>(endpoint, body);
	}

	DeleteNote(id: number, isAp?: boolean) {
		const endpoint = !isAp ? `notes/${id}` : `/notes/${id}/crt`;
		return this.api.delete(endpoint);
	}

	UpsertDocument(req) {
		const endpoint = `services/${req.CustomerServiceId}?IsPatch=true`;
		const body = req;
		return this.api.put<any>(endpoint, body);
	}

	// Security
	GetSecuritiesByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Security?.toLowerCase()}&isGrouped=true`;
		return this.api.get<any>(endpoint);
	}

	AddSecurity(m: PropertyAssetCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateSecurity(m: PropertyAssetCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	// Assets
	GetAssetsByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Asset?.toLowerCase()}&isGrouped=true`;
		return this.api.get<any>(endpoint);
	}

	AddAsset(m: AssetCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateAsset(m: AssetCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	// Assets
	GetLiabilityByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services/${ServicesCodes.Liability?.toLowerCase()}/true`;
		return this.api.get<any>(endpoint);
	}

	AddLiability(liability: LiabilityCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(liability);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateLiability(liability: LiabilityCustomerServiceState) {
		const endpoint = `services/${liability.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(liability);
		return this.api.put2<any>(endpoint, body);
	}

	GetFgInsurancesByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.FG?.toLowerCase()}&isGrouped=true`;
		return this.api.get<any>(endpoint);
	}

	AddFgInsurance(m: FgCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateFgInsurance(m: FgCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	GetKiwisaversByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.KiwiSaver?.toLowerCase()}`;
		return this.api.get<Kiwisaver[]>(endpoint);
	}

	AddKiwisaver(m: KiwisaverState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateKiwisaver(m: KiwisaverState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	GetInvestmentsByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Investment?.toLowerCase()}`;
		return this.api.get<Investment[]>(endpoint);
	}

	AddInvestment(m: InvestmentState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateInvestment(m: InvestmentState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	convert = (request: ConvertCustomerModel) =>
		this.api.post3<any>('Customer/ConvertCustomer', request);

	convertProfile = (request) => {
		const endpoint = `contacts/${request.customerID}/convert`;
		const body = objectUtil.mapCamelCaseToPascalCase(request);
		return this.api.put<any>(endpoint, body);
	};

	AddPhoto(req: AddPhotoRequest) {
		const body = {
			FileName: req.FileName,
			Photo: req.Photo,
		};
		const endpoint = `contacts/${req.CustomerID}/upload-photo`;
		return this.api.post<AddPhotoRequest>(endpoint, body);
	}

	/**
	 * Get Advice Processes
	 * @param id CustomerID
	 */
	GetAdviceProcessesByPrimaryId(
		id: number,
		params?: any
	): Observable<AdviceProcess> {
		const endpoint = `contacts/${id}/adviceprocesses`;
		return this.api.get<AdviceProcess>(endpoint, params);
	}

	/**
	 * Add Advice Process
	 * @param req AdviceProcess
	 */
	AddAdviceProcess(req: ServiceAdviceProcess) {
		const endpoint = `adviceprocesses`;
		return this.api.post3<AdviceProcess>(endpoint, req);
	}

	/**
	 * Update Advice Process
	 * @param req AdviceProcess
	 */
	UpdateAdviceProcess(req: ServiceAdviceProcess) {
		const endpoint = `adviceprocesses/${req?.AdviceProcessID}`;
		return this.api.put<AdviceProcess>(endpoint, req);
	}

	/**
	 * Delete Advice Process
	 * @param id AdviceProcessID
	 */
	DeleteAdviceProcess(id: number) {
		const endpoint = `adviceprocesses/${id}`;
		return this.api.delete<ServiceAdviceProcess>(endpoint);
	}

	UpdateRenewal(
		customerID: number,
		data: { reference: string; referenceNo: string; sourceId: number }
	) {
		const endpoint = `contacts/${customerID}/customer-services/${data.sourceId}/renew`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<any>(endpoint, body);
	}

	GetLinkedContactsByPrimaryClient(id: number) {
		const endpoint = `contacts/${id}/linked-contacts`;
		const a = this.api.get<LinkedContact[]>(endpoint);
		return a;
	}

	UpdateLinkedContacts(customer: LinkedContactState) {
		const endpoint = `contacts/${customer.customerId}/linked-contacts`;
		const body = objectUtil.mapCamelCaseToPascalCase(customer);
		return this.api.put<any>(endpoint, body);
	}

}

export interface ConvertCustomerModel {
	CustomerId: number;
	ContactStatus: string;
	Note: string;
	LostType: string;
}

export interface ConvertProfileModel {
	customerID: number;
	contactStatus: string;
	note: string;
	lostType: string;
}

export interface AddPhotoRequest {
	CustomerID?: number;
	Photo?: any;
	FileName?: string;
	PhotoURL?: string;
}
