import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { mapTo } from 'rxjs/operators';
import { BusinessPageQuery } from './state/business-page.query';
import { BusinessPageService } from './state/business-page.service';

@Injectable()
export class BusinessPageResolver implements Resolve<boolean> {
  constructor(private service: BusinessPageService, private query: BusinessPageQuery) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.query.getCount() > 0) {
      return of(true);
    }

    const businesses$ = this.service.getBusinesses();

    return forkJoin([businesses$]).pipe(
      tap(() => this.service.getColumns().pipe(take(1)).subscribe()),
      tap(() => this.service.getColumnWidths().pipe(take(1)).subscribe()),
      mapTo(true)
    );
  }
}
