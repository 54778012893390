import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { SignatureFieldComponent } from '../../signature-field/signature-field.component';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
})
export class SignatureModalComponent implements OnInit, AfterViewInit {
  header = '';
  subHeader = '';
  signature: UntypedFormControl;
  isSaving: boolean;

  saveFn$: (model: string) => Observable<string>;

  @ViewChild(SignatureFieldComponent) signaturePad: SignatureFieldComponent;
  @ViewChild('signatureContainer') signatureContainer: ElementRef;

  constructor(public bsModalRef: BsModalRef) {
    this.createFormControl();
  }

  ngOnInit(): void {}

  createFormControl() {
    this.signature = new UntypedFormControl('');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.beResponsive();
      this.setOptions();
    }, 0);
  }

  // set the dimensions of the signature pad canvas
  beResponsive() {
    this.size(this.signatureContainer, this.signaturePad);
  }

  size(container: ElementRef, sig: SignatureFieldComponent) {
    sig?.signaturePad?.set('canvasWidth', container.nativeElement.clientWidth);
    sig?.signaturePad.set('canvasHeight', 200);
  }

  setOptions() {
    this.signaturePad?.signaturePad?.set('penColor', 'rgb(0, 0, 0)');
  }

  clear() {
    this.signaturePad?.clear();
  }

  save() {
    this.isSaving = true;
    this.saveFn$(this.signature.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
          this.bsModalRef.hide();
        }),
        take(1)
      )
      .subscribe();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
