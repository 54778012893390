import { DocumentDeclarationState } from './declaration.model';
import MomentUtil from '../../../../../../util/moment.util';
import { MortgageAdviceProcessSectionCodes } from '../../../../../../shared/models/advice-process/advice-process.model';
import { SignatureTypesMOAT } from '../../../../../../shared/models/documents/document.model';


export class DeclarationDocumentMapper {
  public static mapDocumentUpload(reference, signature) {
    return {
      document: signature,
      referenceId: reference,
      fileName: 'sig' + reference + '.png',
      type: SignatureTypesMOAT.Declaration,
    } as DocumentDeclarationState;
  }

  public static mapDeclarationToUpsert(data, adviceProcessId, signature) {
    return {
      ...data,
      date: MomentUtil.formatToServerDate(data.date),
      signature,
      adviceProcessId,
      sectionCode: MortgageAdviceProcessSectionCodes.Declaration,
    };
  }
}
