import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	CrtMortgageState,
	CrtMortgageStore,
} from '../../../state/crt-mortgage.store';

@Injectable()
export class IncomeQuery extends Query<CrtMortgageState> {
	constructor(protected store: CrtMortgageStore) {
		super(store);
	}

	/**
	 * @returns Observable list of Income Sources
	 */
	incomeSource$ = this.select((state) => state.incomeSource);

	/**
	 * @returns Observable list of Previous Income Sources
	 */
	 previousIncomeSource$ = this.select((state) => state.previousIncomeSource);

	/**
	 * @returns Observable list of Total Previous Income Sources
	 */
	totalPreviousIncome$ = this.select((state) => state.totalPreviousIncome);

	/**
	 * @returns Observable list of Net Rental Income
	 */
	netRentalIncome$ = this.select((state) => state.rentalDetails);

	/**
	 * @returns Observable list of Other Income
	 */
	otherIncome$ = this.select((state) => state.otherIncomeDetails);

	/**
	 * @returns Observable list of Monthly Expense
	 */
	monthlyExpense$ = this.select((state) => state.monthlyExpense);

	/**
	 * @returns Observable list of Fact Find Computation
	 */
	factFindComputation$ = this.select((state) => state.factFindComputation);
}
