import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	ViewChild,
	OnChanges,
	ChangeDetectionStrategy,
	ViewChildren,
	QueryList,
	ChangeDetectorRef,
	SimpleChanges,
	OnDestroy,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, Observer, of } from 'rxjs';
import { tap, takeUntil, map, concatMap, take } from 'rxjs/operators';

import {
	MortgageState,
	MortgageProviderGroupState,
} from '../../models/services/mortgage/mortgage-group.model';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import { LinkDocumentComponent } from '../link-document/link-document.component';

import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { documentUtil } from '../service-utils/document.util';
import { MortgageCustomerServiceState } from '../../models/services/mortgage/mortgage.model';
import { MortgageServiceFormComponent } from '../mortgage-service-form/mortgage-service-form.component';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import MomentUtil from '@util/moment.util';
import { MortgageMapper } from '@shared/models/services/mortgage/mortgage.mapper';
import { mainGroupSorter } from '../service-utils/mortgage-sorter.util';

@Component({
	selector: 'app-mortgage-service',
	templateUrl: './mortgage-service.component.html',
	styleUrls: ['./mortgage-service.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MortgageServiceComponent implements OnInit, OnChanges, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() isLead: boolean;
	@Input() isCompany: boolean;
	@Input() addFn$: ({ data, linkDocumentId }) => Observable<any>;
	@Input() saveFn$: (data) => Observable<any>;
	@Input() deleteFn$: (data) => Observable<any>;
	@Input() archiveFn$: ({ data, isArchive }) => Observable<any>;
	@Input() deleteNoteFn$: ({ id, data }) => Observable<any>;
	@Input() updateDocumentFn$: ({
		linkDocId,
		provider,
		loanNumber,
	}) => Observable<any>;
	@Input() isLoading: boolean;
	@Input() addNoteFn$: (data) => Observable<any>;
	@Input() updateNextReviewFn$: (mortgageNextReview) => Observable<string>;

	bsModalRef: BsModalRef;

	@Input() customerId: number;

	@Input() status: ViewDisplayValue[];
	@Input() providers: ViewDisplayValue[];
	@Input() loanTypes: ViewDisplayValue[];
	@Input() mortgageTypes: ViewDisplayValue[];
	@Input() repaymentFrequency: ViewDisplayValue[];
	@Input() fixedPeriods: ViewDisplayValue[];
	@Input() refixStatus: ViewDisplayValue[];
	@Input() lvrs: ViewDisplayValue[];
	@Input() borrowingEntities: ViewDisplayValue[];
	@Input() loanValue: ViewDisplayValue[];
	@Input() securities: ViewDisplayValue[];
	@Input() loanNumber: ViewDisplayValue[];
	@Input() loanNumberSuffix: ViewDisplayValue[];
	@Input() approved: ViewDisplayValue[];
	@Input() loanDrawdown: ViewDisplayValue[];
	@Input() rates: ViewDisplayValue[];
	@Input() expectedSettlement: ViewDisplayValue[];
	@Input() loanTerms: ViewDisplayValue[];
	@Input() fixedPeriodEnd: ViewDisplayValue[];
	@Input() loanRepayment: ViewDisplayValue[];
	@Input() interestOnlyType: ViewDisplayValue[];
	@Input() primaryClient: PrimaryClientState;
	@Input() primaryCompany: any;
	@Input() mortgage: MortgageState;
	@Input() document: DocumentGroupState;

	@Output() saveEvent = new EventEmitter<any>();

	@ViewChild('sidebarNav') sidebarNav: TabsetComponent;
	@ViewChildren('mortgageForms')
	mortgageForms: QueryList<MortgageServiceFormComponent>;
	@ViewChild('addForm') addForm: MortgageServiceFormComponent;

	mortgageState: MortgageState;
	activeGroupNavId: string;

	isAddNew = false;
	isSaving = false;
	isSavingDocument = false;
	isAddNewGroup = false;
	hasSelectedDocument = false;
	isShowArchived = false;
	isSavingNRD = false;

	activeProvider: string;
	activeLoanNumber: string;

	currentLinkedDocument: any;
	currentLinkedDocumentId: string;

	form: FormGroup;
	mList: MortgageProviderGroupState[];
	mFormState: MortgageCustomerServiceState;
	popupCount = 0;

	constructor(
		private fb: FormBuilder,
		private modalService: BsModalService,
		private cd: ChangeDetectorRef
	) {
		this.form = this.fb.group({
			mortgageNextReview: '',
		});
	}

	refresh() {
		this.cd.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.primaryClient || changes.primaryCompany) {
				this.setMortgageReviewDate();
			}
			if (changes.mortgage && changes.mortgage.currentValue !== null) {
				this.mortgageState = Object.assign({}, this.mortgage);
				this.mortgageState.mortgages = this.mortgageState.mortgages.map(m => {
					const allInactive = m.customerServices.every(cs => cs.isActive === 0);
					const anyActive = m.customerServices.some(cs => cs.isActive === 1);
					const allArchived = m.customerServices.every(cs => cs.isActive === 2);
					const isActive = allInactive ? 0 : (anyActive ? 1 : (allArchived ? 2 : null));

					return {...m, isActive};
				})?.sort(mainGroupSorter);
				this.mList = this.mortgageState.mortgages;

				if (
					this.mortgageState &&
					this.mortgageState.mortgages.length > 0 &&
					!this.activeProvider
				) {
					this.setInit();
				}
			}

			if (
				changes.mortgage &&
				changes.mortgage.previousValue &&
				this.activeProvider
			) {
				let i = -1;
				this.mortgageState.mortgages.filter((lr, index) => {
					if (
						lr.provider === this.activeProvider &&
						lr.loanNumber === this.activeLoanNumber
					) {
						return (i = index);
					}
				});

				if (i === -1) {
					// set last tab to active
					const lastIndex = this.mortgageState.mortgages.length - 1;
					if (!this.sidebarNav?.tabs[lastIndex]) {
						this.activeProvider =
							this.mortgageState.mortgages[lastIndex]?.provider;
						this.setDefaults(this.mortgageState.mortgages[lastIndex]);
						return;
					}
					return setTimeout(
						() =>
							(this.sidebarNav.tabs[
								this.mortgageState.mortgages.length - 1
							].active = true),
						0
					);
				} else {
					return setTimeout(
						() =>
							this.sidebarNav?.tabs?.[i]
								? (this.sidebarNav.tabs[i].active = true)
								: null,
						0
					);
				}
			}
		}
	}

	ngOnInit() {
		if (!this.mortgage) {
			this.mortgageState = Object.assign(
				{},
				{
					totaLending: 0,
					mortgages: [],
				}
			);

			this.mList = [];
		}
		if (!this.mortgageState || this.mortgageState.mortgages.length < 1) {
			this.activeProvider = '';
			this.activeLoanNumber = '';

			this.activeGroupNavId = '';
			this.currentLinkedDocumentId = '';
			this.currentLinkedDocument = {};
		} else {
			this.setInit();
		}
	}

	/**
	 * Show/Hide archived services
	 * @param isShowArchived boolean
	 */
	showArchived(isShowArchived: boolean) {
		this.isShowArchived = isShowArchived;
	}

	trackByFn(index: number, item: MortgageProviderGroupState) {
		return index;
	}

	trackById(index: number, item: MortgageCustomerServiceState) {
		return item.customerServiceID;
	}

	setInit() {
		this.setDefaults(this.mortgage.mortgages[0]);
	}

	setMortgageReviewDate() {
		const data = this.isCompany ? this.primaryCompany : this.primaryClient;
		const mortgageNextReview = MortgageMapper.mapNextReviewView(
			data?.mortgageNextReview
		);
		this.form.reset({ mortgageNextReview });
	}

	setDefaults(data) {
		this.activeProvider = data?.provider;
		this.activeLoanNumber = data?.loanNumber;
		this.activeGroupNavId = data?.provider + '-' + data?.loanNumber;
		this.currentLinkedDocumentId = data?.linkDocument;
		this.currentLinkedDocument = documentUtil(
			this.document,
			+this.currentLinkedDocumentId
		);
	}

	selectMTab = (item) => {
		const id = !!item.id ? item.id : item.customerServiceId;
		const mGroup = this.mList?.find((m) =>
			m.customerServices?.some((cs) => cs.customerServiceID === id)
		);
		if (mGroup) {
			const groupNavId = mGroup.provider + '-' + mGroup.loanNumber;
			setTimeout(
				() =>
					(this.sidebarNav.tabs.find((x) => x.id === groupNavId).active = true),
				2
			);
			const form = this.mortgageForms?.find(
				(m) => m.form.value.customerServiceID === id
			);
			if (form) {
				form.collapseForm(true);

				setTimeout(() => {
					form.formProp.nativeElement.scrollIntoView({ behavior: 'smooth' });
				}, 550);
			}
		}
	};

	confirmModal(data, addMode: boolean, isDelete: boolean) {
		const confirm = new Observable((obs: Observer<any>) => {
			if (!isDelete) {
				addMode ? this.createMortgage(data) : this.saveMortgage(data);
			} else {
				this.deleteMortgage(data);
			}
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (addMode) {
				this.addForm.editForm(true, true);
			}
			obs.complete();
		});

		const initState = {
			header: 'Confirmation Message',
			message: `There is no primary split in this group. Are you sure you want to continue?`,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.refresh();
	}

	confirmCreate(data) {
		if (this.isAddNewGroup) {
			if (data.mortgageType !== 'Primary') {
				this.confirmModal(data, true, false);
			} else {
				this.createMortgage(data);
			}
		} else if (
			!this.isAddNewGroup &&
			this.isAddNew &&
			(!this.mFormState || this.mFormState.mortgageType !== 'Primary') &&
			data.mortgageType !== 'Primary'
		) {
			this.confirmModal(data, true, false);
		} else {
			this.createMortgage(data);
		}
	}

	createMortgage(data) {
		data.customerID = this.customerId;

		setTimeout(() => {
			this.addForm.formSaving(true);
			this.addForm.collapseForm(true);
		}, 0);

		this.addFn$({ data, linkDocumentId: this.currentLinkedDocumentId })
			.pipe(
				tap((x) => {
					if (x) {
						if (this.isAddNewGroup) {
							this.currentLinkedDocument = {};
						}
						this.isSaving = false;
						this.isAddNew = false;
						this.isAddNewGroup = false;
						this.popupCount = 0;
						this.activeProvider = data.provider;
						this.activeLoanNumber = data.loanNumber;
						this.refresh();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.addForm?.editForm(false, true);
				},
				() => {
					this.addForm.formSaving(false);
					this.addForm.editForm(true, true);
				},
				() => {
					this.addForm?.editForm(false, true);
				}
			);
	}

	confirmSaveMortgage(req: { data; isAddNote }) {
		if (req.isAddNote) {
			this.saveMortgage(req.data);
		} else {
			// Same group
			if (
				req.data.provider === this.activeProvider &&
				req.data.loanNumber === this.activeLoanNumber
			) {
				const mortgages = this.mortgageState.mortgages?.find(
					(x) =>
						x.provider === req.data.provider &&
						x.loanNumber === req.data.loanNumber
				);
				const primaryCount = mortgages?.customerServices
					? mortgages.customerServices?.filter(
							(x) => x.mortgageType === 'Primary'
					  )
					: [];

				if (
					(primaryCount.length === 1 &&
						req.data.mortgageType !== 'Primary' &&
						req.data.customerServiceID === primaryCount[0].customerServiceID) ||
					(primaryCount.length < 1 && req.data.mortgageType !== 'Primary')
				) {
					this.confirmModal(req.data, false, false);
				} else {
					this.saveMortgage(req.data);
				}
			} else {
				// Transfering to other group
				const mortgages = this.mortgageState?.mortgages?.find(
					(x) =>
						x.provider === this.activeProvider &&
						x.loanNumber === this.activeLoanNumber
				);
				const primaryCount =
					mortgages?.customerServices?.filter(
						(x) => x.mortgageType === 'Primary'
					) || [];

				if (
					(primaryCount?.length === 1 &&
						req?.data?.customerServiceID ===
							primaryCount?.[0]?.customerServiceID &&
						(mortgages?.customerServices?.length > 1 ||
							(mortgages?.customerServices?.length === 1 &&
								req?.data?.mortgageType !== 'Primary'))) ||
					(primaryCount?.length === 0 &&
						mortgages?.customerServices?.length > 1) ||
					(primaryCount?.length === 0 &&
						mortgages?.customerServices?.length === 1 &&
						req?.data?.mortgageType !== 'Primary')
				) {
					this.confirmModal(req.data, false, false);
				} else {
					this.saveMortgage(req.data);
				}
			}
		}
	}

	saveMortgage(data) {
		this.mortgageForms
			?.find((x) => x.form.value.customerServiceID === data.customerServiceID)
			.formSaving(true);
		this.mortgageForms
			?.find((m) => m.form.value.customerServiceID === data.customerServiceID)
			.editForm(false, true);

		this.isAddNewGroup = false;

		data.customerID = this.customerId;

		this.saveFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.activeProvider = data.provider;
						this.activeLoanNumber = data.loanNumber;
						this.isAddNew = false;
						this.popupCount = 0;
						this.refresh();

						setTimeout(() => {
							this.isSaving = false;
							this.mortgageForms
								?.find(
									(m) =>
										m.form.value.customerServiceID === data.customerServiceID
								)
								.formSaving(false);
							this.mortgageForms
								?.find(
									(m) =>
										m.form.value.customerServiceID === data.customerServiceID
								)
								.collapseForm(true);
						}, 0);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.mortgageForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						?.formSaving(false);
				},
				() => {
					const m = this.mortgageForms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (m) {
						m.formSaving(false);
						m.prepData();
						m.editForm(true, true);
					}
				},
				() => {
					this.mortgageForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						?.formSaving(false);
				}
			);
	}

	confirmDeleteMortgage(data) {
		const lrs = this.mortgageState.mortgages?.find(
			(x) => x.provider === data.provider && x.loanNumber === data.loanNumber
		);
		const primaryCount = lrs.customerServices?.filter(
			(x) => x.mortgageType === 'Primary'
		);

		if (
			(primaryCount.length === 1 &&
				data.customerServiceID === primaryCount[0].customerServiceID) ||
			primaryCount.length === 0
		) {
			this.confirmModal(data, false, true);
		} else {
			this.deleteMortgage(data);
		}
	}

	deleteMortgage(data) {
		this.mortgageForms
			?.find((x) => x.mortgage.customerServiceID === data.customerServiceID)
			.formSaving(true);
		data.customerID = this.customerId;

		this.deleteFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.mortgageForms
							?.find(
								(m) => m.mortgage.customerServiceID === data.customerServiceID
							)
							.formSaving(false);
						this.activeProvider = data.provider;
						this.activeLoanNumber = data.loanNumber;
						this.refresh();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.mortgageForms
						?.find(
							(x) => x.mortgage.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.mortgageForms
						?.find(
							(x) => x.mortgage.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.mortgageForms
						.find(
							(x) => x.mortgage.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	addNewGroup() {
		this.isAddNew = true;
		this.isAddNewGroup = true;
		this.activeProvider = '';
		this.activeLoanNumber = '';
		this.mFormState = null;
		this.popupCount = 0;

		// Should add new data on the list
		this.mortgageState.mortgages = [
			...this.mortgageState.mortgages,
			{
				provider: '',
				totalLending: 0,
				fixedUntil: '',
				loanNumber: '',
				customerServices: [],
				color: 'dark',
				linkDocument: '',
			},
		];

		this.currentLinkedDocumentId = '';
		this.currentLinkedDocument = {};

		setTimeout(
			() =>
				(this.sidebarNav.tabs[this.sidebarNav.tabs.length - 1].active =
					true),
			0
		);
	}

	cancelNewGroup(cancel: boolean) {
		this.isAddNew = false;
		this.isAddNewGroup = false;
		this.popupCount = 0;

		// Removes last data on array
		this.mortgageState.mortgages?.pop();

		this.sidebarNav.tabs[0].active = true;
	}

	addNewSplit() {
		this.mFormState = null;
		// Todo change this
		const mState = this.mortgageState.mortgages?.find(
			(lr) =>
				lr.provider === this.activeProvider &&
				lr.loanNumber === this.activeLoanNumber
		);

		if (mState) {
			const state = mState.customerServices?.find(
				(cs) => cs.mortgageType && cs.mortgageType?.toLowerCase() === 'primary'
			);
			state
				? (this.mFormState = state)
				: (this.mFormState = mState.customerServices[0]);
		}
		this.isAddNew = true;
	}

	cancelAddNewSplit() {
		this.isAddNew = false;
		this.popupCount = 0;
	}

	archiveMortgage(req: { mortgage; isArchive }) {
		this.mortgageForms
			?.find(
				(x) => x.form.value.customerServiceID === req.mortgage.customerServiceID
			)
			.formSaving(true);
		req.mortgage.customerID = this.customerId;

		this.archiveFn$({ data: req.mortgage, isArchive: req.isArchive })
			.pipe(
				tap((x) => {
					if (x) {
						this.mortgageForms
							?.find(
								(m) =>
									m.form.value.customerServiceID ===
									req.mortgage.customerServiceID
							)
							.formSaving(false);
						this.activeProvider = req.mortgage.provider;
						this.activeLoanNumber = req.mortgage.loanNumber;
						this.refresh();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.mortgageForms
						?.find(
							(x) =>
								x.form.value.customerServiceID ===
								req.mortgage.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.mortgageForms
						?.find(
							(x) =>
								x.form.value.customerServiceID ===
								req.mortgage.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.mortgageForms
						?.find(
							(x) =>
								x.form.value.customerServiceID ===
								req.mortgage.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	split = (data: any) => {
		this.confirmSaveMortgage(data);
	};

	deleteNote = (req: { noteId; data }) => {
		this.mortgageForms
			?.find(
				(m) => m.form.value.customerServiceID === req.data.customerServiceID
			)
			.formSaving(true);
		this.deleteNoteFn$({
			id: req.noteId,
			data: req.data,
		})
			.pipe(
				tap((x) => {
					if (x) {
						this.mortgageForms
							?.find(
								(m) =>
									m.form.value.customerServiceID === req.data.customerServiceID
							)
							.formSaving(false);
						this.activeProvider = req.data.provider;
						this.activeLoanNumber = req.data.loanNumber;
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.mortgageForms
						?.find(
							(m) =>
								m.form.value.customerServiceID === req.data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.mortgageForms
						?.find(
							(m) =>
								m.form.value.customerServiceID === req.data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.mortgageForms
						?.find(
							(m) =>
								m.form.value.customerServiceID === req.data.customerServiceID
						)
						.formSaving(false);
				}
			);
	};

	linkDocument() {
		const initState = {
			selectedDetail: 'Link Document',
			document: this.document,
			initialSelectedTab: 'm',
		};
		this.bsModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
		this.bsModalRef.content.getSelectedDocumentValue$.subscribe(
			(doc) => {
				this.upsertDocument(doc.id);
				this.hasSelectedDocument = true;
			},
			() => {
				this.hasSelectedDocument = false;
			},
			() => {}
		);
	}

	upsertDocument(id) {
		this.isSavingDocument = true;

		const provider = this.activeProvider;
		const loanNumber = this.activeLoanNumber;
		this.refresh();

		this.updateDocumentFn$({ linkDocId: id, provider, loanNumber })
			.pipe(
				tap((x) => {
					if (x) {
						this.isSavingDocument = false;
						this.currentLinkedDocumentId = id;
						this.currentLinkedDocument = id
							? documentUtil(this.document, +this.currentLinkedDocumentId)
							: '';
						this.activeProvider = provider;
						this.activeLoanNumber = loanNumber;
						this.refresh();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSavingDocument = false;
				},
				() => {
					this.isSavingDocument = false;
				},
				() => {
					this.isSavingDocument = false;
				}
			);
	}

	unlinkedDocument() {
		this.upsertDocument('');
		this.hasSelectedDocument = false;
	}

	selectGroupConfirm(mortgage) {
		if (!mortgage.provider) {
			return;
		}
		const selectedTab = mortgage.provider + '-' + mortgage.loanNumber;

		if (
			!this.isAddNewGroup &&
			this.isAddNew &&
			(mortgage.provider !== this.activeProvider ||
				mortgage.loanNumber !== this.activeLoanNumber) &&
			this.popupCount < 1
		) {
			this.selectGroup(selectedTab);
		}

		if (this.isAddNewGroup) {
			this.activeProvider = '';
			this.activeLoanNumber = '';
			this.selectGroup(selectedTab);
		} else {
			this.currentLinkedDocumentId = mortgage.linkDocument;
			this.currentLinkedDocument = this.currentLinkedDocumentId
				? documentUtil(this.document, +this.currentLinkedDocumentId)
				: '';
			this.activeProvider = mortgage.provider;
			this.activeLoanNumber = mortgage.loanNumber;
		}
	}

	selectGroup(selectedTab: string) {
		this.popupCount++;
		const groupNavId = this.activeProvider + '-' + this.activeLoanNumber;

		const confirm = new Observable((obs: Observer<any>) => {
			if (this.isAddNew && this.isAddNewGroup) {
				this.mortgageState.mortgages?.pop();
			}
			this.isAddNew = false;
			this.isAddNewGroup = false;
			this.popupCount = 0;
			this.refresh();
			setTimeout(
				() =>
					(this.sidebarNav.tabs.find((x) => x.id === selectedTab).active =
						true),
				2
			);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.popupCount = 0;
			this.refresh();
			obs.complete();
		});

		const initState = {
			header: 'Discard Confirmation',
			message: `Current information will be discarded?`,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		if (this.isAddNew && this.isAddNewGroup) {
			setTimeout(
				() =>
					(this.sidebarNav.tabs[this.sidebarNav.tabs.length - 1].active = true), 2);
		} else {
			setTimeout(
				() =>
					(this.sidebarNav.tabs.find((x) => x.id === groupNavId).active = true),
				2
			);
		}
	}

	addNote(data) {
		data.customerID = this.customerId;

		this.addNoteFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						setTimeout(() => {
							this.isSaving = false;
							this.mortgageForms
								?.find(
									(m) =>
										m.form.value.customerServiceID === data.customerServiceID
								)
								.formSaving(false);
							this.mortgageForms
								?.find(
									(m) =>
										m.form.value.customerServiceID === data.customerServiceID
								)
								.collapseForm(true);
						}, 0);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.mortgageForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					const m = this.mortgageForms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (m) {
						m.formSaving(false);
						m.prepData();
					}
				},
				() => {
					this.mortgageForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	updateReviewDateFn$ = (event: MatDatepickerInputEvent<Moment>) =>
		of(event).pipe(
			map((x) => (!!x?.value ? MomentUtil.formatToServerDate(x.value) : '')),
			concatMap(this.updateNextReviewFn$)
		);

	updateReviewDate() {
		this.isSavingNRD = true;
		const formControl = this.form.get('mortgageNextReview');
		const formatedDate = !!formControl?.value
			? MomentUtil.formatToServerDate(formControl?.value)
			: null;

		// if (formatedDate) {
			const currentErrors = formControl.errors;
			// If there are errors and 'dateError' is present, remove it
			if (currentErrors && currentErrors.dateError) {
				delete currentErrors.dateError;

				// Set the updated errors
				formControl.setErrors(
					Object.keys(currentErrors).length > 0 ? currentErrors : null
				);
			}
			return this.updateNextReviewFn$(formatedDate)
				.pipe(take(1))
				.subscribe(() => {
					this.isSavingNRD = false;
				});
		// } else {
		// 	this.setMortgageReviewDate();
		// 	this.isSavingNRD = false;
		// }
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
