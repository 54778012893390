import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BusinessPageQuery } from '../state/business-page.query';
import { Metakey } from '../business-page-datatable.config';
import { TableColumn } from '@swimlane/ngx-datatable';
import { UntypedFormGroup } from '@angular/forms';
import { BusinessPageModel } from '../state/business-page.model';
import { BusinessPageService } from '../state/business-page.service';

declare var $: any;
@Component({
	selector: 'app-business-page-columns-form',
	templateUrl: './business-page-columns-form.component.html',
	styleUrls: ['./business-page-columns-form.component.scss']
})
export class BusinessPageColumnsFormComponent implements OnInit, OnDestroy, AfterViewInit {
	private onDestroy$: Subject<void> = new Subject<void>();

	visibleColumns$ = this.query.tableColumns$;
	hiddenColumns$ = this.query.hiddenTableColumns$;

	visibleColumns: (TableColumn & { metakey: Metakey; prop: keyof BusinessPageModel })[];
	hiddenColumns: (TableColumn & { metakey: Metakey; prop: keyof BusinessPageModel })[];

	isColumnSaving$ = this.query.isColumnSaving$;

	form = new UntypedFormGroup({});
	formInitialized = false;

	constructor(private service: BusinessPageService, private query: BusinessPageQuery) { }

	ngOnInit() {
		this.visibleColumns$.subscribe(res => (this.visibleColumns = res));
		this.hiddenColumns$.subscribe(res => (this.hiddenColumns = res));
	}

	ngAfterViewInit() {
		$(document).ready(() => {
			$('.disabled-sortable').parent().addClass('disabled d-none');
		});
	}

	prepareFormValue() {
		return this.visibleColumns?.map(x => x.metakey);
	}

	save() {
		if (this.visibleColumns.length === 0) {
			return;
		}
		this.service.saveVisibleColumns(this.prepareFormValue()).subscribe(
			() => { },
			() => { },
			() => {
				this.service.toggleColumnsSetting(false);
			}
		);
	}

	cancel() {
		this.service.toggleColumnsSetting(false);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
