import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ServicesState, ServicesStore } from './services.store';
import { Service } from './service.model';

// Query list of services
@Injectable()
export class ServicesQuery extends QueryEntity<ServicesState, Service> {
    constructor(protected store: ServicesStore) {
        super(store);
    }
}
