import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { staticConf } from 'src/app/core/config/static-config.service';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import {
  canBulkTransfer,
	canUpdateLeadStatus,
	getId,
	hasPopover,
	isAutoSelectUser,
	isTapLevel,
	isUserWithOptFields,
	User,
} from './user.model';
import { UserStore } from './user.store';
import { environment as env } from '@environment';

@Injectable()
export class UserQuery extends Query<User> {
	constructor(
		protected store: UserStore,
		protected authService: AuthenticationService
	) {
		super(store);
	}
	userInfo$ = this.select((x) => x);
	isTapLevel$ = this.select(isTapLevel);
  canBulkTransfer$ = this.select(canBulkTransfer);
	isAutoSelectUser$ = this.select(isAutoSelectUser);
	hasPopover$ = this.select(hasPopover);
	canUpdateLeadStatus$ = this.select(canUpdateLeadStatus);
	profilePic$ = of(`${env.defaultImgUrl}/default-user.png`);
	userId$ = this.select(getId);
	userSecurityRoles$ = this.select((x) => x.SecurityRoles);
	userSecurityGroup$ = this.select((x) => x.SecurityGroup);
	isUserWithOptFields$ = this.select(isUserWithOptFields);
	staffSettings$ = this.select((x) => x.StaffSettings);

	adviserIdsAssigned$ = this.select((x) => x.AdviserIdsAssigned);

	services$: Observable<string[]> = this.select((x) =>
		typeof x?.Services === 'string'
			? JSON.parse(x.Services)
			: isTapLevel(x)
			? [
					ServicesCodes.LR,
					ServicesCodes.Mortgage,
					ServicesCodes.FG,
					ServicesCodes.KiwiSaver,
					ServicesCodes.Investment,
					ServicesCodes.AdviceProcess,
					ServicesCodes.ClientReviewTemplate,
					ServicesCodes.CustomerPortalMOAT,
			  ]
			: []
	);

	isLoading$ = this.select((x) => x.IsLoading);

	/** Indicator whether the User has L&R */
	public readonly isUserHasLR$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map((x) => isTapLevel(x) || x?.Services?.includes(staticConf.lrServiceCode))
	);
	/** Indicator whether the User has Mortgage */
	public readonly isUserHasM$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map(
			(x) =>
				isTapLevel(x) || x?.Services?.includes(staticConf.mortgageServiceCode)
		)
	);
	/** Indicator whether the User has FG */
	public readonly isUserHasFG$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map((x) => isTapLevel(x) || x?.Services?.includes(staticConf.fgServiceCode))
	);
	/** Indicator whether the User has Kiwisaver */
	public readonly isUserHasK$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map(
			(x) =>
				isTapLevel(x) || x?.Services?.includes(staticConf.kiwisaverServiceCode)
		)
	);
	/** Indicator whether the User has Investment */
	public readonly isUserHasI$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map(
			(x) =>
				isTapLevel(x) || x?.Services?.includes(staticConf.investmentServiceCode)
		)
	);
	/** Indicator whether the User has AP */
	public readonly isUserHasAP$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map(
			(x) =>
				isTapLevel(x) || x?.Services?.includes(staticConf.adviceProcessCode)
		)
	);
	/** Indicator whether the User has CRT */
	public readonly isUserHasCRT$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map(
			(x) =>
				isTapLevel(x) ||
				x?.Services?.includes(staticConf.clientReviewTemplateCode)
		)
	);

	/** Indicator whether the User has MOAT */
	public readonly isUserHasMOAT$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map((x) => {
			return (
				isTapLevel(x) ||
				x?.Services?.includes(staticConf.mortgageOnlineAdviceToolCode)
			);
		})
	);

	/** Indicator whether the User has KOAT */
	public readonly isUserHasKOAT$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map(
			(x) =>
				isTapLevel(x) ||
				x?.Services?.includes(staticConf.kiwisaverOnlineAdviceToolCode)
		)
	);

	/** Indicator whether the User has CPMOAT */
	public readonly isUserHasCPMOAT$ = this.select((x) => x).pipe(
		map((x) => {
			return {
				...x,
				Services:
					typeof x?.Services === 'string'
						? JSON.parse(x.Services)
						: x?.Services,
			};
		}),
		map((x) => {
			return (
				isTapLevel(x) ||
				x?.Services?.includes(staticConf.customerPortalMOATCode)
			);
		})
	);

	hasPermission$ = (permissions: string[]) =>
		this.select((x) => x.SecurityRoles).pipe(
			map((sr) => permissions?.filter((p) => sr?.find((s) => s === p))),
			map(this.hasPermission)
		);

	private hasPermission = (p: string[]) => p && p.length > 0;

	isTapLevel() {
		return isTapLevel(this.getValue());
	}
}
