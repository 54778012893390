import { Injectable, OnDestroy } from '@angular/core';
// import { ThemeConfig } from '../../../models/config';
import { Observable, Subject, throwError } from 'rxjs';
// import { ConfigService } from '../../../core/config/config.service';
// import { ModelFactory, Model } from '../../../core/base/model.service';
import { tap, withLatestFrom, catchError, mergeMap } from 'rxjs/operators';
import { ThemeConfig } from '../../../../shared/models/_general/config';
import { Model, ModelFactory } from '../../../../core/base/model.service';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { BusinessConfigService } from '../../../../domain/business-config/business-config.service';
import { ConfigService } from '../../../../core/config/config.service';
// import { BusinessConfigQuery, BusinessConfigService } from '../../../domain/business-config';

@Injectable()
export class ThemeComponentService implements OnDestroy {
  private themeModel: Model<ThemeConfig>;
  public readonly theme$: Observable<ThemeConfig>;
  private isLoadingModel: Model<boolean>;
  public readonly isLoading$: Observable<boolean>;
  private onDestroy$: Subject<void>;
  constructor(
    private themeModelFactory: ModelFactory<ThemeConfig>,
    private boolFactory: ModelFactory<boolean>,
    private businessConfigQuery: BusinessConfigQuery,
    private businessConfigService: BusinessConfigService,
    private configService: ConfigService
  ) {
    this.themeModel = this.themeModelFactory.create(null);
    this.theme$ = this.themeModel.data$;
    this.isLoadingModel = this.boolFactory.create(false);
    this.isLoading$ = this.isLoadingModel.data$;
    this.onDestroy$ = new Subject<void>();

    this.businessConfigQuery.themeConfig$.subscribe(x => this.themeModel.set(x));
  }

  save = (data: ThemeConfig) =>
    new Observable<ThemeConfig>(obs => {
      obs.next(data);
      obs.complete();
    }).pipe(
      tap(() => this.isLoadingModel.set(true)),
      withLatestFrom(this.configService.CompanyCode),
      mergeMap(([res, compCode]) => {
        const req = Object.assign({}, res, { company_code: compCode });
        return this.businessConfigService.updateThemeConfig(req);
      }),
      catchError(err => {
        this.isLoadingModel.set(false);
        return throwError(err);
      }),
      tap(() => this.isLoadingModel.set(false)),
    )

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
