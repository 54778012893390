<tabset
	#medicalTabset
	class="
		tap-tabset tap-tabset--border-radius-left-right
		tap-client-profile__tabset
		tabset-ra
	"
>
	<tab
		*ngFor="let person of people$ | async; let arrayIndex = index"
		heading="{{ person.name }}"
		id="medicalTabset"
		[customClass]="'m-0 medical-container'"
		(selectTab)="setActive(arrayIndex)"
		[disabled]="isLoading"
	>
		<div *ngIf="isLoading; else showForm" class="d-block ml-4">
			<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
		</div>

		<ng-template #showForm>
			<div class="main-form">
				<div class="container-fluid px-3 px-lg-4">
					<div class="col-12">
						<div class="row collapse-label-background pt-4">
							<div class="col-8 pl-0 mb-3">
								<label
									for=""
									class="font-weight-bold label-font tap-text-primary"
								>
									{{ person.name }}, in the event you need medical treatment in
									NZ, what level of care would you want?
								</label>
							</div>
						</div>

						<form [formGroup]="form">
							<div class="row title">
								<div class="col-9"></div>
								<div class="col-3">
									<div class="form-check form-check-inline m-0">
										<label class="form-check-label mr-3 mr-md-5 mr-lg-5"
											>Yes</label
										>

										<label class="form-check-label">No</label>
									</div>
								</div>
							</div>
							<div class="row title">
								<div class="col-9">
									<span class="font-weight-bold"
										>Rely on New Zealand Public Health System</span
									>
								</div>
								<div class="col-3">
									<div class="form-check form-check-inline m-0">
										<div
											class="check custom-radio ml-4"
											[class]="
												RelyOnNewZealandPublicHealthSystem.touched &&
												RelyOnNewZealandPublicHealthSystem.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="relyOnNewZealandPublicHealthSystem"
												type="radio"
												formControlName="relyOnNewZealandPublicHealthSystem"
												value="Yes"
												class="custom-control-input rdbtn"
												id="publicHealthYesId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="custom-control-label rdbtn mr-3 mr-md-5 mr-lg-5"
												for="publicHealthYesId_{{arrayIndex}}"
											></label>
										</div>

										<div
											class="check custom-radio"
											[class]="
												RelyOnNewZealandPublicHealthSystem.touched &&
												RelyOnNewZealandPublicHealthSystem.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="relyOnNewZealandPublicHealthSystem"
												type="radio"
												formControlName="relyOnNewZealandPublicHealthSystem"
												value="No"
												class="custom-control-input rdbtn"
												id="publicHealthNoId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="custom-control-label rdbtn"
												for="publicHealthNoId_{{arrayIndex}}"
											></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-1"></div>
								<div class="col-11">
									<li>
										Limited access to available medical diagnostics on public
										waitlists.
									</li>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-1"></div>
								<div class="col-11">
									<li>
										Limited access to available medical procedures on public
										waitlists.
									</li>
								</div>
							</div>
							<div class="row mb-4">
								<div class="col-1"></div>
								<div class="col-11">
									<li>
										Limited access to available drug treatments on public
										waitlists.
									</li>
								</div>
							</div>
							<div class="row title">
								<div class="col-9">
									<span class="font-weight-bold"
										>New Zealand Private Medical Policy Base Plan:</span
									>
								</div>
								<div class="col-3">
									<div class="form-check form-check-inline m-0">
										<div
											class="check custom-radio ml-4"
											[class]="
												NewZealandPrivateMedicalPolicyBasePlan.touched &&
												NewZealandPrivateMedicalPolicyBasePlan.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="newZealandPrivateMedicalPolicyBasePlan"
												type="radio"
												formControlName="newZealandPrivateMedicalPolicyBasePlan"
												value="Yes"
												class="custom-control-input rdbtn"
												id="policyYesId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="rdbtn custom-control-label mr-3 mr-md-5 mr-lg-5"
												for="policyYesId_{{arrayIndex}}"
											></label>
										</div>

										<div
											class="check custom-radio"
											[class]="
												NewZealandPrivateMedicalPolicyBasePlan.touched &&
												NewZealandPrivateMedicalPolicyBasePlan.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="newZealandPrivateMedicalPolicyBasePlan"
												type="radio"
												formControlName="newZealandPrivateMedicalPolicyBasePlan"
												value="No"
												class="custom-control-input rdbtn"
												id="policyNoId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="custom-control-label rdbtn"
												for="policyNoId_{{arrayIndex}}"
											></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-4">
								<div class="col-1"></div>
								<div class="col-11">
									<li>
										Access to all private hospital treatment available and
										without public waitlists.
									</li>
								</div>
							</div>
							<div class="row title">
								<div class="col-9">
									<span class="font-weight-bold"
										>Your Medical Policy could also include:</span
									>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-1"></div>
								<div class="col-8">
									<li>
										Access to 100% of medical drug treatments approved for use
										in NZ?
									</li>
								</div>
								<div class="col-3">
									<div class="form-check form-check-inline m-0">
										<div
											class="check custom-radio ml-4"
											[class]="
												MedicalDrugTreatmentsApprovedforUseInNZ.touched &&
												MedicalDrugTreatmentsApprovedforUseInNZ.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="medicalDrugTreatmentsApprovedforUseInNZ"
												type="radio"
												formControlName="medicalDrugTreatmentsApprovedforUseInNZ"
												value="Yes"
												class="custom-control-input rdbtn"
												id="accessYesId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="rdbtn custom-control-label mr-3 mr-md-5 mr-lg-5"
												for="accessYesId_{{arrayIndex}}"
											></label>
										</div>

										<div
											class="check custom-radio"
											[class]="
												MedicalDrugTreatmentsApprovedforUseInNZ.touched &&
												MedicalDrugTreatmentsApprovedforUseInNZ.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="medicalDrugTreatmentsApprovedforUseInNZ"
												type="radio"
												formControlName="medicalDrugTreatmentsApprovedforUseInNZ"
												value="No"
												class="custom-control-input rdbtn"
												id="accessNoId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="custom-control-label rdbtn"
												for="accessNoId_{{arrayIndex}}"
											></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-1"></div>
								<div class="col-8">
									<li>
										A Policy providing access to Specialists and tests
									</li>
								</div>
								<div class="col-3">
									<div class="form-check form-check-inline m-0">
										<div
											class="check custom-radio ml-4"
											[class]="
												PolicyProvidingAccessToSpecialistsAndTests.touched &&
												PolicyProvidingAccessToSpecialistsAndTests.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="policyProvidingAccessToSpecialistsAndTests"
												type="radio"
												formControlName="policyProvidingAccessToSpecialistsAndTests"
												value="Yes"
												class="custom-control-input rdbtn"
												id="specialistsYesId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="rdbtn custom-control-label mr-3 mr-md-5 mr-lg-5"
												for="specialistsYesId_{{arrayIndex}}"
											></label>
										</div>

										<div
											class="check custom-radio"
											[class]="
												PolicyProvidingAccessToSpecialistsAndTests.touched &&
												PolicyProvidingAccessToSpecialistsAndTests.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="policyProvidingAccessToSpecialistsAndTests"
												type="radio"
												formControlName="policyProvidingAccessToSpecialistsAndTests"
												value="No"
												class="custom-control-input rdbtn"
												id="specialistsNoId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="custom-control-label rdbtn"
												for="specialistsNoId_{{arrayIndex}}"
											></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-1"></div>
								<div class="col-8">
									<li>
										A Policy providing access to Optical and Dental treatment
									</li>
								</div>
								<div class="col-3">
									<div class="form-check form-check-inline m-0">
										<div
											class="check custom-radio ml-4"
											[class]="
												PolicyProvidingAccessToOpticalAndDentalTreatment.touched &&
												PolicyProvidingAccessToOpticalAndDentalTreatment.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="policyProvidingAccessToOpticalAndDentalTreatment"
												type="radio"
												formControlName="policyProvidingAccessToOpticalAndDentalTreatment"
												value="Yes"
												class="custom-control-input rdbtn"
												id="dentalTreatmentYesId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="
													rdbtn
													custom-control-label
													mr-3 mr-md-5 mr-lg-5 mr-md-1
												"
												for="dentalTreatmentYesId_{{arrayIndex}}"
											></label>
										</div>

										<div
											class="check custom-radio"
											[class]="
												PolicyProvidingAccessToOpticalAndDentalTreatment.touched &&
												PolicyProvidingAccessToOpticalAndDentalTreatment.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="policyProvidingAccessToOpticalAndDentalTreatment"
												type="radio"
												formControlName="policyProvidingAccessToOpticalAndDentalTreatment"
												value="No"
												class="custom-control-input rdbtn"
												id="dentalTreatmentNoId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="custom-control-label rdbtn"
												for="dentalTreatmentNoId_{{arrayIndex}}"
											></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-4">
								<div class="col-1"></div>
								<div class="col-8">
									<li>
										A Policy providing access to GP visits and prescriptions
									</li>
								</div>
								<div class="col-3">
									<div class="form-check form-check-inline m-0">
										<div
											class="check custom-radio ml-4"
											[class]="
												PolicyProvidingAccessToGPVisitsAndPrescriptions.touched &&
												PolicyProvidingAccessToGPVisitsAndPrescriptions.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="policyProvidingAccessToGPVisitsAndPrescriptions"
												type="radio"
												formControlName="policyProvidingAccessToGPVisitsAndPrescriptions"
												value="Yes"
												class="custom-control-input rdbtn"
												id="prescriptionsYesId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="rdbtn custom-control-label mr-3 mr-md-5 mr-lg-5"
												for="prescriptionsYesId_{{arrayIndex}}"
											></label>
										</div>

										<div
											class="check custom-radio"
											[class]="
												PolicyProvidingAccessToGPVisitsAndPrescriptions.touched &&
												PolicyProvidingAccessToGPVisitsAndPrescriptions.invalid
													? 'ng-invalid ng-touched'
													: ''
											"
										>
											<input
												name="policyProvidingAccessToGPVisitsAndPrescriptions"
												type="radio"
												formControlName="policyProvidingAccessToGPVisitsAndPrescriptions"
												value="No"
												class="custom-control-input rdbtn"
												id="prescriptionsNoId_{{arrayIndex}}"
												(change)="onChange($event)"
											/>
											<label
												class="custom-control-label rdbtn"
												for="prescriptionsNoId_{{arrayIndex}}"
											></label>
										</div>
									</div>
								</div>
							</div>
							<div
								class="row mb-4 title"
								*ngIf="RelyOnNewZealandPublicHealthSystem.value === 'No'"
							>
								<div class="col-9">
									<span class="font-weight-bold"
										>What level of excess are you comfortable self insuring
										for?</span
									>
								</div>
								<div class="col-3">
									<span class="crt-custom-dropdown w-100">
										<select
											class="crt-form-control form-control"
											formControlName="whatLevelOfExcessAreYouComfortableSelfInsuring"
											(change)="onChange()"
											id="whatLevelOfExcessAreYouComfortableSelfInsuringId_{{arrayIndex}}"
										>
											<option value="" [attr.disabled]="true" selected hidden></option>
											<option
												*ngFor="let dropdown of APCRTME$ | async"
												[value]="dropdown.value"
											>
												{{ dropdown.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</ng-template>
	</tab>
</tabset>
