import { ViewDisplayValue } from './display-value.viewmodel';
import * as R from 'ramda';

export enum CustomerTypes {
  PrimaryCustomerIndividual = 'PCI',
  PrimaryCustomerCompany = 'PCC',
  SecondaryCustomerIndividual = 'SCI',
  SecondaryCustomerTrust = 'SCT',
  SecondaryCustomerCompany = 'SCC',
  SecondaryCustomerProfessional = 'SCP',
  LinkedContact = 'LC'
}

export enum RelationshipTypes {
  Primary = 'Primary',
  Child = 'Child',
  Friend = 'Friend',
  PartnerSpouse = 'Partner/Spouse'
}

export enum ContactTypes {
  Client = 'Client',
  Business = 'Business',
  Trust = 'Trust'
}

export enum ContactStatusCode {
  CurrentClient = 'C',
  Lead = 'L',
  ExClient = 'X'
}

export enum ContactStatusLabel {
  CurrentClient = 'Current Client',
  Lead = 'Lead',
  ExClient = 'Ex-Client'
}

export const GetStatusLabelByCode = (code: string) => {
  if (code === '') {
    return ContactStatusLabel.CurrentClient;
  }

  const statusCodeKey = R.find((key: string) => ContactStatusCode[key as keyof typeof ContactStatusCode] === code, Object.keys(ContactStatusCode));
  if (statusCodeKey) {
    const label = ContactStatusLabel[statusCodeKey as keyof typeof ContactStatusLabel];
    return label;
  }
  else {
    return ContactStatusLabel.ExClient;
  }

}

export const GetContactStatusViewDisplay = () => {
  const keys: string[] = Object.keys(ContactStatusCode);
  const contactStatuses: ViewDisplayValue[] = [];
  const makeViewDisplay = (key: string) => {
    contactStatuses.push({
      display: ContactStatusLabel[key as keyof typeof ContactStatusLabel],
      value: ContactStatusCode[key as keyof typeof ContactStatusCode]
    })
  }
  R.forEach(makeViewDisplay, keys);
  return contactStatuses;
}

export const GetActiveContactStatuses = () => {
  return [ContactStatusCode.CurrentClient, ContactStatusCode.Lead];
}

export enum Gender {
  Male = 'M',
  Female = 'F',
  Diverse = 'D'
}

export enum GenderLabel {
  Male = 'Male',
  Female = 'Female',
  Diverse = 'Diverse'
}

export const GetGenderViewDisplay = () => {
  const genders: ViewDisplayValue[] = [];
  const genderKeys: string[] = Object.keys(Gender);
  const makeViewDisplay = (key: string) => {
    const viewDisplay: ViewDisplayValue = {
      display: key,
      value: Gender[key as keyof typeof Gender]
    }
    genders.push(viewDisplay);
  }
  R.forEach(makeViewDisplay, genderKeys);
  return genders;
}
