import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, iif, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { convertUtil, objectUtil } from '../../../../../app/util/util';
import * as R from 'ramda';
import { MergeTagsMapper } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { DeclarationEmailSettingsState } from './state/declaration-email-settings.model';
import { DeclarationEmailSettingsService } from './state/declaration-email-settings.service';
import { DeclarationEmailSettingsMapper } from './state/declaration-email-settings.mapper';

@Component({
  selector: 'app-declaration-email-settings',
  templateUrl: './declaration-email-settings.component.html'
})
export class DeclarationEmailSettingsComponent implements OnInit {

  @Input() staffId: number;
  @Input() emailSettings: DeclarationEmailSettingsState;
  @Input() mergeTags$: Observable<MergeTagState[]>;

  declarationEmailSettings$ = this.declarationEmailService.declarationEmailSettings$;
  isLoading = false;
  bodyContent = '<p></p>';
  bodyId: number;
  shortCodes: object;

  constructor(
    private declarationEmailService: DeclarationEmailSettingsService
  ) {}

  ngOnInit(): void { this.prepData(); }

  prepData() {
    combineLatest([this.declarationEmailSettings$, this.mergeTags$])
      .pipe(
        filter(([data, mt]) => !!data && !!mt),
        tap(
          ([data, mt]) =>
            (this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
        ),
        tap(([data, mt]) => (this.bodyId = +data?.body || 0)),

        map(([data, mt]) =>
          DeclarationEmailSettingsMapper.mapEmailSettingsData(data)
        ),
        mergeMap((data) =>
          iif(
            () => R.isNil(data?.body),
            of(null),
            this.declarationEmailService.getFile(+data.body)
          )
        ),
        map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
        mergeMap((res) =>
          iif(
            () => res?.documentLink,
            this.declarationEmailService.getDocumentFromURL(res?.documentLink),
            of(null)
          )
        ),
        tap((data) => {
          if (data) {
            this.bodyContent = data;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  save(emailSettings) {
    this.isLoading = true;
    of(emailSettings)
      .pipe(
        mergeMap((currentTemplate) => {
          return convertUtil.convertToBase64(currentTemplate?.bodyContent)
        }),
        withLatestFrom(of(this.bodyId)),
        map(([currentTemplate, bodyId]) =>
          DeclarationEmailSettingsMapper.mapStaffEmailBody(
            currentTemplate,
            bodyId,
            0
          )
        ),
        concatMap((data) =>
          iif(
            () => R.isNil(data.documentId),
            this.declarationEmailService.newFileUpload(data).pipe(catchError((err) => of(err))),
            this.declarationEmailService.updateFileUpload(data)
          )
        ),
        withLatestFrom(of(this.bodyId)),
        concatMap(([data, body]) => {
          const isNewBody = body === 0 || !body;
          const newBodyId = isNewBody ? data : body;
          const formValue = DeclarationEmailSettingsMapper.mapEmailSettingsToUpsert({
            ...emailSettings,
            referenceId: 0,
            body: newBodyId,
          });
          this.bodyId = newBodyId;
          return this.declarationEmailService.updateDeclarationEmailSettings(formValue);
        }),
        take(1),
        finalize(() => (this.isLoading = false)),

      )
      .subscribe();
  }

}
