import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { BLStaffSecGroupsService } from '../../../../domain/bl-staff-security-group/bl-staff-security-groups.service';
import { Resolve } from '@angular/router';

@Injectable()
export class BlSecGroupsResolver implements Resolve<any> {
  resolve(): Observable<boolean> {
    const secGroups$ = this.blStaffSecGroupsService.addList();
    return forkJoin([secGroups$]).pipe(mapTo(true));
  }

  constructor(private blStaffSecGroupsService: BLStaffSecGroupsService) { }
}
