import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  AdviceProcessUiState,
  AdviceProcessUiStore,
} from './advice-process-ui.store';

@Injectable()
export class AdviceProcessUiQuery extends Query<AdviceProcessUiState> {
  isSearching$ = this.select((x) => x.isSearching);
  isExporting$ = this.select((x) => x.isExporting);
  columnFormPopupOpen$ = this.select((x) => x.columnFormPopupOpen);
  isColumnSaving$ = this.select((x) => x.isColumnSaving);
  adviceStagesDropdown$ = this.select((x) => x.adviceStages);

  propSort$ = this.select((x) => x.propSort);
  sort$ = this.select((x) => x.sort);

  currentSort$ = this.select((x) => x.currentSort);

  constructor(protected adviceProcessUiStore: AdviceProcessUiStore) {
    super(adviceProcessUiStore);
  }
}
