<form
	[formGroup]="form"
	[class.loatv2]="addLOATv2ClassName"
	[class.submitted]="formSubmitted"
	class="position-relative"
>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> Name </label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<input
				id="firstName"
				formControlName="firstName"
				class="form-control crt-form-control"
				type="text"
				placeholder="First Name"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-3 mb-2">
			<input
				id="middleName"
				formControlName="middleName"
				class="form-control crt-form-control"
				type="text"
				placeholder="Middle Name"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-4 mb-2">
			<input
				id="lastName"
				formControlName="lastName"
				class="form-control crt-form-control"
				type="text"
				placeholder="Last Name"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Known As
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<input
				formControlName="knownAs"
				class="form-control crt-form-control"
				type="text"
			/>
		</div>
		<div class="col-4 col-md-3 text-sm-left text-md-right mb-2">
			<label for="" class="position-relative font-weight-bold"> Gender </label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="gender"
					formControlName="gender"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of gender" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> D.O.B </label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<app-date-input
				#dateOfBirthInput
				id="dateOfBirth"
				textboxId="dateOfBirth"
				class="crt-form-control-field"
				formControlName="dateOfBirth"
				[attr.disabled]="viewMode"
				placeholder="dd/mm/yyyy"
			>
			</app-date-input>
		</div>
		<div class="col-4 col-md-3 text-sm-left text-md-right mb-2">
			<label for="" class="position-relative font-weight-bold">
				Residency Status
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					formControlName="residencyStatus"
					id="residencyStatus"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of visaDetails" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div
		class="form-row align-items-center"
		*ngIf="hasCountry && this.Residency.value && this.Residency.value !== nzCitizenStatus"
	>
		<div class="col-4 col-md-3 text-sm-left text-md-right mb-2 offset-md-5">
			<label for="countryOfOrigin" class="position-relative font-weight-bold">
				Country of Origin
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<input
				formControlName="countryOfOrigin"
				id="countryOfOrigin"
				class="form-control crt-form-control"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> Phone </label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<input
				formControlName="mobilePhone"
				id="mobilePhone"
				class="form-control crt-form-control"
				type="text"
				placeholder="Mobile Phone"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-3 mb-2">
			<input
				formControlName="homePhone"
				id="homePhone"
				class="form-control crt-form-control"
				type="text"
				placeholder="Home Phone"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-4 mb-2">
			<input
				formControlName="workPhone"
				id="workPhone"
				class="form-control crt-form-control"
				type="text"
				placeholder="Work Phone"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> Email </label>
		</div>
		<div class="col-8 col-md-10 mb-2">
			<input
				formControlName="email"
				id="email"
				class="form-control crt-form-control"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Physical Address
			</label>
		</div>
		<div class="col-8 col-md-10 mb-2">
			<app-address-input
				inputId="physicalAddress"
				id="physicalAddress"
				formControlName="physicalAddress"
				textboxClass="theme-crm-field-disabled"
				class="crt-form-control-field"
				tabindex="24"
				[attr.disabled]="viewMode"
			></app-address-input>
		</div>
	</div>
	<div class="form-row align-items-center">
		<ng-container *ngIf="showRelationShipField else noRelationship">
			<div class="col-4 col-md-2 text-sm-left text-md-left mb-2">
				<label for="" class="position-relative font-weight-bold">
					Relationship
				</label>
			</div>
			<div class="col-8 col-md-3 mb-2">
				<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
					<select
						formControlName="relationship"
						id="relationship"
						class="form-control theme-crm-field-disabled crt-form-control"
					>
						<option value=""></option>
						<option *ngFor="let d of relationship" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
			</div>
		</ng-container>
		<ng-template #noRelationship>
			<div class="col-12 col-md-5"></div>
		</ng-template>
		<ng-container *ngIf="maritalStatus && maritalStatus.length">
			<div class="col-md-3 mb-2 text-right">
				<label for="" class="position-relative font-weight-bold">
					Marital Status
				</label>
			</div>
			<div class="col-4 mb-2">
				<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
					<select
						formControlName="maritalStatus"
						id="maritalStatus"
						class="form-control theme-crm-field-disabled crt-form-control"
					>
						<option *ngFor="let d of maritalStatus" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
			</div>
		</ng-container>
	</div>

	<div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button
				id="closeButton"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
				[disabled]="submitted || loading"
			>
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
				id="saveButton"
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
				(click)="save()"
				[disabled]="submitted || loading"
			>
				Save
				<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				id="cancelButton"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
			>
				Cancel
			</button>
		</ng-template>
	</div>
</form>
