<form [formGroup]="form">
	<div class="form-row collapse-label-background">
		<div class="col-8 mb-3">
			<label for="" class="font-weight-bold label-font tap-text-primary">
				GP Details
			</label>
		</div>
	</div>

	<div class="">
		<ng-container *ngIf="!isLoading">
			<div formArrayName="gpArray">
				<div
					class="form-row mb-2 font-weight-bold"
					[class.tap-opacity-25]="GPArray.controls.length === 0"
				>
					<div class="col-12 col-lg-3">Name</div>
					<div class="col-12 col-lg-4">Clients</div>
					<div class="col-12 col-lg-3">Business Name</div>
				</div>
				<div
					class="form-row align-items-center"
					*ngFor="
						let item of GPArray.controls;
						let pointIndex = index;
						let last = last
					"
					[formGroupName]="pointIndex"
				>
					<div class="d-lg-none col-lg-2 text-right">
						<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
							<ng-container *ngIf="item.get('btnSaveGP').value">
								<button
									id="gp_saveGPButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="saveGP(pointIndex)"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnEditGP').value">
								<button
									id="gp_editGPButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editGP(pointIndex)"
									[disabled]="
										isAddMode || (isEditMode && +crtEdit !== +pointIndex)
									"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnEditGP').value && pointIndex < gpHistory.length
								"
							>
								<button
									id="gp_deleteGPButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="deleteGP(pointIndex, item)"
									[disabled]="
										isAddMode || (isEditMode && +crtEdit !== +pointIndex)
									"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnSaveGP').value">
								<button
									id="gp_removeGPButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removeGp(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</ng-container>
						<ng-template #viewOnlySmall>
							<button
								id="gp_viewGPButton_{{ pointIndex }}"
								type="button"
								class="icon-btn"
								(click)="viewGP(pointIndex)"
								[disabled]="isAddMode || isEditMode"
							>
								<i class="material-icons md-16"> info </i>
							</button>
						</ng-template>
					</div>
					<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
						<span
							class="w-100"
							[class.crt-custom-dropdown]="item.get('customerId').enabled"
						>
							<select
								class="crt-form-control form-control theme-crm-field-disabled"
								name="nameGP"
								formControlName="customerId"
								[id]="'nameGp-' + pointIndex"
								(change)="selectNewGpClient($event, pointIndex)"
								[class.select-text-muted]="
									!item.get('customerId').value ||
									item.get('customerId').value === 'addNew'
								"
							>
								<option
									class="select-text-muted"
									value=""
									disabled
									selected
									hidden
								>
									GP Name
								</option>
								<option
									*ngIf="!!item.get('customerId').value"
									[value]="item.get('customerId').value"
								>
									{{ item.get("gPName").value }}
								</option>
								<option value=""></option>
								<option
									*ngFor="let gpClient of gpClients"
									[value]="gpClient.customerID"
									class="select-text-option"
								>
									{{ gpClient.name }}
								</option>
								<option class="select-text-option" value="addNew">
									+ Add New GP Details
								</option>
							</select>
						</span>
					</div>
					<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
						<app-chips
							id="gp_client_{{ pointIndex }}"
							textboxId="gp_client_{{ pointIndex }}"
							*ngIf="
								item.get('customerId').value &&
								item.get('customerId').value !== 'addNew'
							"
							class="crt-form-control-field"
							[attr.disabled]="item.get('client').disabled"
							formControlName="client"
							[choices]="peopleList$ | async"
							[isRemoveChipsPadding]="item.get('client').disabled"
							textboxClass="theme-crm-field"
						></app-chips>
					</div>
					<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
						<input
							id="gp_businessName_{{ pointIndex }}"
							*ngIf="
								item.get('customerId').value &&
								item.get('customerId').value !== 'addNew'
							"
							class="crt-form-control form-control theme-crm-field-disabled"
							type="text"
							formControlName="businessName"
							placeholder="Business Name"
						/>
					</div>
					<div
						class="d-none d-lg-block col-lg-1 text-right gp-btns"
						[class.border-bottom]="!last"
					>
						<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
							<ng-container
								*ngIf="
									item.get('btnSaveGP').value && !item.get('isSaving').value
								"
							>
								<button
									id="gp_saveGPButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="saveGP(pointIndex)"
									[disabled]="item.get('btnDisable').value"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnEditGP').value && !item.get('isSaving').value
								"
							>
								<button
									id="gp_editGPButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editGP(pointIndex)"
									[disabled]="
										isAddMode || (isEditMode && +crtEdit !== +pointIndex)
									"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnEditGP').value &&
									pointIndex < gpHistory.length &&
									!item.get('isSaving').value
								"
							>
								<button
									id="gp_deleteGPButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="deleteGP(pointIndex, item)"
									[disabled]="
										isAddMode || (isEditMode && +crtEdit !== +pointIndex)
									"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnSaveGP').value && !item.get('isSaving').value
								"
							>
								<button
									id="gp_removeGPButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removeGp(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</ng-container>
						<ng-template #viewOnly>
							<button
								id="gp_viewGPButton_{{ pointIndex }}"
								type="button"
								class="icon-btn"
								(click)="viewGP(pointIndex)"
								[disabled]="isAddMode || isEditMode"
							>
								<i class="material-icons md-16"> info </i>
							</button>
						</ng-template>
						<ng-container *ngIf="item.get('isSaving').value">
							<app-manual-loader-mini
								[isLoading]="true"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="form-row my-4">
			<div class="col-5 col-lg-2">
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="gp_addGPButton"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 border-0 col-auto mb-2"
						(click)="addGP()"
						[disabled]="
							isLoading ||
							isAddMode ||
							isEditMode ||
							(isGpClientsLoading$ | async)
						"
					>
						Add GP Details +
					</button>
				</ng-container>
				<ng-container *ngIf="isLoading || (isGpClientsLoading$ | async)">
					<app-manual-loader-mini
						[isLoading]="isLoading || (isGpClientsLoading$ | async)"
					></app-manual-loader-mini>
				</ng-container>
			</div>
		</div>
	</div>
</form>
