import { SimpleDataTableModel } from '@shared/models/_general/simple-data-table.model';

export const displayedColumns = [
	'DocumentTag',
	'CreateDateTime',
	'CreatedBy',
	'DocumentName',
	'DeleteAction',
];

export const tableColumns: SimpleDataTableModel[] = [
	{
		columnId: 'DocumentTag',
		columnName: 'Document Tag',
		headerCellClass: 'document-tag',
		bodyCellClass: 'document-tag',
		showToolTip: true,
		width: '40%',
		type: 'fileLink',
	},
	{
		columnId: 'CreateDateTime',
		columnName: 'Date Uploaded',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'moment',
	},
	{
		columnId: 'CreatedBy',
		columnName: 'Uploaded By',
		headerCellClass: '',
		bodyCellClass: '',
		width: '20%',
		type: 'text',
	},
	{
		columnId: 'DocumentName',
		columnName: 'Name',
		headerCellClass: '',
		bodyCellClass: '',
		showToolTip: true,
		width: '20%',
		type: 'text',
	},
	{
		columnId: 'DeleteAction',
		columnName: '',
		headerCellClass: '',
		bodyCellClass: '',
		actionCallback: null,
		actionCallbackText: 'Delete',
		width: '10%',
		type: 'action',
	},
];
