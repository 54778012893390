import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { either, isNil, isEmpty } from 'ramda';
import { Observable, of, Subject } from 'rxjs';
import {
	withLatestFrom,
	map,
	mergeMap,
	take,
	tap,
	filter,
	finalize,
} from 'rxjs/operators';
import { PeopleState } from '../../../../../shared/models/client-review-template/people/people.model';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';
import { RiskProfileService } from '../../states/risk-analysis/risk-profile/risk-profile.service';
import { RiskProfileMapper } from '../../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.mapper';
import { ScopeOfServiceService } from '../../states/scope-of-service/scope-of-service.service';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import {
	EventState,
	InsurancePreferenceState,
	PeopleEventState,
	RiskProfileState,
} from '../../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.model';
import { ScopeOfServiceState } from '../../../../../shared/models/client-review-template/scope-of-service/scope-of-service.model';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';

@Component({
	selector: 'app-profile',
	templateUrl: './risk-profile.component.html',
	styleUrls: ['./risk-profile.component.scss'],
})
export class RiskProfileComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	APCRTIP$: Observable<ViewDisplayValue[]> = this.service.APCRTIP$;
	APCRTP$: Observable<ViewDisplayValue[]> = this.service.APCRTP$;
	riskProfile$: Observable<RiskProfileState> =
		this.riskProfileService.riskProfile$;
	people$: Observable<PeopleState[]> =
		this.riskProfileService.peopleWithDependants$;
	sosCRT$: Observable<ScopeOfServiceState> = this.sosService.scopeOfService$;

	event: EventState[];
	insurancePreferences: InsurancePreferenceState[];

	isNew = false;
	isLoading = true;
	cRTId: number;
	activePerson: PeopleState;
	peopleList: PeopleState[];
	peopleEvent: PeopleEventState[];

	isAdviceProcessEnded$ = this.crtQuery.isAdviceProcessEnded$;

	constructor(
		private service: ClientReviewTemplateService,
		private riskProfileService: RiskProfileService,
		private sosService: ScopeOfServiceService,
		private crtQuery: ClientReviewTemplateQuery
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.riskProfile$
			.pipe(
				filter((data) => !!data),
				tap((data) => {
					if (either(isNil, isEmpty)(data)) {
						this.isNew = true;
					}
					this.cRTId = data?.cRTId;
					this.event = data?.event || [];
					this.insurancePreferences = data?.insurancePreferences || [];
				}),
				withLatestFrom(this.people$),
				map(([, people]) => people),
				mergeMap((people) =>
					of(this.event).pipe(
						take(1),
						map((data) =>
							this.checkPeopleList(
								RiskProfileMapper.mapEventToView(data),
								people
							)
						)
					)
				),
				finalize(() => {
					this.isLoading = false;
					this.setActive(this.peopleEvent[0]);
				}),
				take(1)
			)
			.subscribe();
	}

	setActive(data: PeopleState) {
		if (!data) {
			return;
		}
		this.activePerson = data;
	}

	checkPeopleList(events: EventState[], people: PeopleState[]) {
		const list =
			people?.map((person) => {
				const getEvent = events?.find(
					(event) => event.peopleCRTId === person.cRTId
				);
				return { ...person, ...getEvent, peopleCRTId: person.cRTId };
			}) || [];

		this.peopleEvent = list;
		return list;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
