import { Injectable } from '@angular/core';
import { EBCState, EBCStore } from './ebc.store';
import { Query } from '@datorama/akita';

@Injectable()
export class EBCQuery extends Query<EBCState> {

  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create lead Conversions query
   */
  data$ = this.select(state => state.EBConversion);

  constructor(protected store: EBCStore) {
    super(store);
  }
}
