<div>
  <div class="row align-items-center mb-3">
    <div class="col-auto">
      <strong>{{ groupName }}</strong>
    </div>

    <div class="col">
      <div class="d-flex flex-row bd-highlight justify-content-end">
        <span *ngIf="isSaving">
          <div class="d-flex flex-row bd-highlight justify-content-end">
            <app-manual-loader-mini
              class="d-flex"
              [isLoading]="isSaving"
            ></app-manual-loader-mini>
          </div>
        </span>

        <!-- Save Service -->
        <ng-container *appRestrictTo="['FES']">
          <div *ngIf="(isEditForm || addMode) && !isSaving">
            <span class="position-relative">
              <button type="submit" class="icon-btn" (click)="save()" id="propertyAssetSave_{{ formId }}">
                <i class="material-icons md-20 tap-text-color-h6"> save </i>
              </button>
            </span>
            <span class="position-relative">
              <button type="cancel" class="icon-btn" (click)="cancel()" id="propertyAssetCancel_{{ formId }}">
                <i class="material-icons md-20 tap-text-color-h6"> cancel </i>
              </button>
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="!addMode && !isEditForm && !isSaving">
          <div class="d-flex flex-row bd-highlight justify-content-end">
            <!-- Archive Service -->
            <ng-container *appRestrictTo="['FARS']">
              <span>
                <button
                  *ngIf="propertyAsset.isActive === 1"
                  class="icon-btn"
                  type="button"
                  (click)="archive(true)"
                  id="propertyAssetArchive"
                >
                  <i
                    data-toggle="tooltip"
                    title="archive"
                    class="material-icons md-20 tap-text-color-h6 pt-1"
                  >
                    archive
                  </i>
                </button>
                <button
                  *ngIf="propertyAsset.isActive !== 1"
                  class="icon-btn"
                  type="button"
                  (click)="archive(false)"
                  id="propertyAssetUnarchive"
                >
                  <i
                    data-toggle="tooltip"
                    title="unarchive"
                    class="material-icons md-20 tap-text-color-h6 pt-1"
                  >
                    unarchive
                  </i>
                </button>
              </span>
            </ng-container>

            <!-- Edit Service -->
            <ng-container *appRestrictTo="['FES']">
              <span>
                <button
                  data-toggle="tooltip"
                  title="edit"
                  class="icon-btn"
                  type="button"
                  (click)="editForm(true)"
                  id="propertyAssetEdit"
                >
                  <i class="material-icons md-20 tap-text-color-h6 pt-1">
                    edit
                  </i>
                </button>
              </span>
            </ng-container>

            <!-- Delete Service -->
            <ng-container *appRestrictTo="['FDS']">
              <span>
                <button
                  data-toggle="tooltip"
                  title="delete"
                  class="btn btn-link p-1 ng-star-inserted"
                  type="button"
                  (click)="deleteConfirm($event)"
                  id="propertyAssetDelete"
                >
                  <i class="material-icons md-20 tap-text-color-h6">  </i>
                </button>
              </span>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <form novalidate [formGroup]="form" class="pb-3">
    <div
      class="container-fluid clearfix px-0"
      [class.archived]="
        propertyAsset && propertyAsset.isActive
          ? propertyAsset.isActive === 2
          : false
      "
    >
      <!-- First Row -->
      <div class="form-row clearfix align-items-center mb-2">
        <div class="col-lg-6 col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-3 col-12">
              <span class="tap-required-field" *ngIf="propertyAddress.invalid"
                >*</span
              >
              <label for="">Property Address</label>
            </div>
            <div class="col-lg-9 col-12">
              <app-address-input
                ngProjectAs="input"
                formControlName="propertyAddress"
                textboxClass="theme-crm-field-disabled"
                id="propertyAddress_{{ formId }}"
                tabindex="19"
              >
              </app-address-input>
            </div>
          </div>
        </div>
        <div class="col-lg col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-3 col-12">
              <label for="">Valuation 1</label>
            </div>
            <div class="col-lg-3 col-12">
              <span class="custom-dropdown w-100">
                <select
                  formControlName="valuation1"
                  class="form-control theme-crm-field-disabled"
                  id="valuation1_{{ formId }}"
                  tabindex="24"
                >
                  <option value=""></option>
                  <option *ngFor="let p of valuations1; trackBy: trackByValue" [value]="p.value">
                    {{ p.display }}
                  </option>
                </select>
              </span>
            </div>
            <div class="col-lg-3 col-12">
              <div class="dollar-icon">
                <input
                  type="text"
                  formControlName="gRM1Value"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="gRM1Value_{{ formId }}"
                  tabindex="25"
                  appTwoDigitDecimalNumber
                />
                <i>$</i>
              </div>
            </div>

            <div class="col-lg-3 col-12">
              <app-date-input
                #gRM1DateInput
                textboxClass="theme-crm-field-disabled"
                formControlName="gRM1Date"
                id="gRM1Date_{{ formId }}"
                tabindex="29"
              >
              </app-date-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Second Row -->
      <div class="form-row clearfix align-items-center mb-2">
        <div class="col-lg-6 col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-3 col-12">
              <label for="">Property Owner</label>
            </div>
            <div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="propertyOwner"
								[choices]="propertyOwnersDropdown"
								id="propertyOwner_{{ formId }}"
								tabindex="20"
							></app-chips>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-3 col-12">
              <label for="">Valuation 2</label>
            </div>
            <div class="col-lg-3 col-12">
              <span class="custom-dropdown w-100">
                <select
                  formControlName="valuation2"
                  class="form-control theme-crm-field-disabled"
                  id="valuation2_{{ formId }}"
                  tabindex="26"
                >
                  <option value=""></option>
                  <option *ngFor="let p of valuations2; trackBy: trackByValue" [value]="p.value">
                    {{ p.display }}
                  </option>
                </select>
              </span>
            </div>
            <div class="col-lg-3 col-12">
              <div class="dollar-icon">
                <input
                  type="text"
                  formControlName="gRM2Value"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="gRM2Value_{{ formId }}"
                  tabindex="27"
                  appTwoDigitDecimalNumber
                />
                <i>$</i>
              </div>
            </div>
            <div class="col-lg-3 col-12">
              <app-date-input
                #gRM2DateInput
                textboxClass="theme-crm-field-disabled"
                formControlName="gRM2Date"
                id="gRM2Date_{{ formId }}"
                tabindex="30"
              >
              </app-date-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Third Row -->
      <div class="form-row clearfix align-items-center mb-2">
        <div class="col-lg col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-6 col-12">
              <label for="">Type</label>
            </div>
            <div class="col-lg-6 col-12">
              <span class="custom-dropdown w-100">
                <select
                  formControlName="type"
                  class="form-control theme-crm-field-disabled"
                  id="type_{{ formId }}"
                  tabindex="21"
                >
                  <option value=""></option>
                  <option *ngFor="let t of types; trackBy: trackByValue" [value]="t.value">
                    {{ t.display }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-6 col-12">
              <label for="">Title</label>
            </div>
            <div class="col-lg-6 col-12">
              <span class="custom-dropdown w-100">
                <select
                  formControlName="title"
                  class="form-control theme-crm-field-disabled"
                  id="title_{{ formId }}"
                  tabindex="23"
                >
                  <option value=""></option>
                  <option *ngFor="let t of titles; trackBy: trackByValue" [value]="t.value">
                    {{ t.display }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-6 col-12">
              <label for="">Rental Income</label>
            </div>
            <div class="col-lg-6 col-12">
              <div class="dollar-icon">
                <input
                  type="text"
                  formControlName="rentalIncome"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="rentalIncome_{{ formId }}"
                  tabindex="28"
                  appTwoDigitDecimalNumber
                />
                <i>$</i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-6 col-12">
              <label for="">Property Settlement Date</label>
            </div>
            <div class="col-lg-6 col-12">
              <app-date-input
                #purchaseDateInput
                textboxClass="theme-crm-field-disabled"
                formControlName="purchaseDate"
                id="purchasedDate_{{ formId }}"
                tabindex="31"
              >
              </app-date-input>
            </div>
          </div>
        </div>
      </div>
      <!-- fouth row -->
      <div class="form-row clearfix align-items-center mb-2">
        <div class="col-lg col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-6 col-12">
              <label for="">Use</label>
            </div>
            <div class="col-lg-6 col-12">
              <span class="custom-dropdown w-100">
                <select
                  formControlName="use"
                  class="form-control theme-crm-field-disabled"
                  id="use_{{ formId }}"
                  tabindex="22"
                >
                  <option value=""></option>
                  <option *ngFor="let u of uses; trackBy: trackByValue" [value]="u.value">
                    {{ u.display }}
                  </option>
                </select>
              </span>
            </div>
            <div class="col-lg-6 col-12"></div>
          </div>
        </div>
        <div class="col-lg col-12">
          <div class="form-row align-items-center">
            <div class="col-lg-6 col-12">
              <label for="">Status</label>
            </div>
            <div class="col-lg-6 col-12">
              <span class="custom-dropdown w-100">
                <select
                  formControlName="status"
                  class="form-control theme-crm-field-disabled"
                  id="use_{{ formId }}"
                  tabindex="23"
                >
                  <option value=""></option>
                  <option *ngFor="let u of status; trackBy: trackByValue" [value]="u.value">
                    {{ u.display }}
                  </option>
                </select>
              </span>
            </div>
            <div class="col-lg-6 col-12"></div>
          </div>
        </div>

        <div class="col-lg-6 col-12"></div>
        <div class="col-lg-6 col-12"></div>
      </div>

      <ng-container *ngIf="addMode">
        <div class="form-row clearfix align-items-center mb-2">
          <div class="col-lg">
            <label for="note_{{ formId }}">Add a note</label>
            <textarea
              class="form-control theme-crm-field-disabled"
              formControlName="note"
              rows="4"
              id="note_{{ formId }}"
              tabindex="32"
            ></textarea>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!addMode">
        <br />
        <div class="form-row clearfix align-items-center py-2">
          <div class="col-lg d-flex align-items-center">
            <strong class="mr-3">Notes</strong>
            <ng-container *ngIf="isLead">
              <button
                *appRestrictTo="['FALN']"
                type="button"
                class="icon-btn h-auto"
                [disabled]="isSaving"
                (click)="openModalAddNote()"
                id="propertyAssetAddNote"
              >
                <i class="material-icons md-20 tap-text-color-h6">note_add</i>
              </button>
            </ng-container>
            <ng-container *ngIf="!isLead">
              <button
                *appRestrictTo="['FAN']"
                type="button"
                class="icon-btn h-auto"
                [disabled]="isSaving"
                (click)="openModalAddNote()"
                id="propertyAssetAddNote"
              >
                <i class="material-icons md-20 tap-text-color-h6">note_add</i>
              </button>
            </ng-container>
          </div>
        </div>
        <div class="form-row clearfix align-items-center mb-2">
          <div class="col-lg col-12">
            <app-table-paginate-notes
              [isService]="true"
              [isLead]="isLead"
              [notes]="notes"
              [itemsPerPage]="5"
              [deleteFn$]="deleteNote$"
              [showHeader]="false"
            >
            </app-table-paginate-notes>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>
