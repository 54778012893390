import { Injectable } from '@angular/core';
import { NBLRStore } from './nblr.store';
import { ApiService } from '../../../../../../core/base/api.service';
import { NblrOpportunity } from './nblr.model';
import { produce } from 'immer';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class NBLRService {
  private readonly endpoint = 'widgets?type=NBLR';

  constructor(private api: ApiService, private store: NBLRStore) {}

  /**
   * Fetch and store Client Birthday data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetLROpportunity(req: {
    advisers: string[],
    dateRangeMin: string,
    dateRangeMax: string
  }): void {
    this.store.reset();
    this.store.setLoading(true);
    this.api.post<NblrOpportunity[]>(this.endpoint, {
      Advisers: req.advisers,
      DateRangeMin: req.dateRangeMin,
      DateRangeMax: req.dateRangeMax
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.NblrOpportunity = res;
      })),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
