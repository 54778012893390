<div class="row px-3 pt-4 disclosure-template">
	<div class="col-12 disclosure-template__froala position-relative">
		<app-wysiwyg
			#contentEditor
			[shortcodes]="shortcodes"
			[content]="currentTemplate"
			designClass="pdf-design-v2-froala"
		></app-wysiwyg>

		<div class="row">
			<div class="col-12 text-right">
				<ng-container *ngIf="!isLoading">
					<button
						type="button"
						class="btn btn-primary my-2 mx-1 tap-bg-primary"
						(click)="backToEdit()"
						*ngIf="isPreview"
					>
						BACK TO EDIT
					</button>
					<button
						type="button"
						class="btn btn-primary my-2 mx-1 tap-bg-primary"
						(click)="showPreview()"
						*ngIf="!isPreview"
					>
						PREVIEW
					</button>
					<button
						type="button"
						class="btn btn-primary my-2 ml-1 tap-bg-primary"
						(click)="save()"
						*ngIf="!isPreview"
					>
						SAVE
					</button>
				</ng-container>

				<ng-container *ngIf="isLoading">
					<app-manual-loader-mini
						[isLoading]="isLoading"
					></app-manual-loader-mini>
				</ng-container>
			</div>
		</div>
	</div>
</div>
