<form
	[formGroup]="form"
	class="clearfix"
	[class.ng-invalid]="!addMode && liability?.pendingAdviserApproval"
	novalidate
>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-3">
			<input
				formControlName="liability"
				type="text"
				id="liabilityLiability_{{ formId }}"
				class="form-control crt-form-control"
				placeholder="Liability"
			/>
		</div>
		<div class="col-12 col-lg-3">
			<input
				formControlName="interestRate"
				type="text"
				id="liabilityInterestRate_{{ formId }}"
				class="form-control crt-form-control"
				placeholder="Interest Rate"
				[value]="+liability?.interestRate + '%'"
			/>
		</div>
		<div class="col-12 col-lg-3">
			<div class="dollar-icon">
				<input
					formControlName="loanLimit"
					type="text"
					id="liabilityLoanLimit_{{ formId }}"
					class="form-control crt-form-control"
					placeholder="Loan / Limit"
					currencyMask
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-12 col-lg-2">
			<div class="dollar-icon">
				<input
					formControlName="loanBalance"
					type="text"
					id="liabilityLoanBalance_{{ formId }}"
					class="form-control crt-form-control"
					placeholder="Loan Balance crt-form-control"
					currencyMask
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-lg-1 order-first text-right order-lg-last">
			<ng-container *ngIf="isSaving$ | async as isSaving; else buttons">
				<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
			</ng-container>
			<ng-template #buttons>
				<ng-container *ngIf="!(isEdit$ | async)">
					<ng-container
						*ngIf="
							!liability?.pendingAdviserApproval;
							else pendingAdviserApproval
						"
					>
						<button
							id="liabiltyEditLiabilityButton_{{ formId }}"
							(click)="editLiability()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">edit</i>
						</button>
						<button
							id="liabiltyDeleteLiabilityButton_{{ formId }}"
							(click)="deleteLiability()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
					<ng-template #pendingAdviserApproval>
						<button
							id="pendingAdviserApprovalButton"
							(click)="approveConfirmation()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="fas fa-exclamation-triangle fa-16"></i>
						</button>
					</ng-template>
				</ng-container>
				<ng-container *ngIf="isEdit$ | async">
					<button
						id="liabilitySaveButton_{{ formId }}"
						(click)="save()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
					>
						<i class="material-icons md-16 tap-text-primary">save</i>
					</button>
					<button
						id="liabilityCancelButton_{{ formId }}"
						(click)="cancel()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
					>
						<i class="material-icons md-16">close</i>
					</button>
				</ng-container>
			</ng-template>
		</div>
	</div>
</form>
