import { Injectable } from '@angular/core';
import { ReportState, ReportStore } from './report.store';
import { QueryEntity } from '@datorama/akita';
import { Report } from './report.model';

@Injectable()
export class ReportQuery extends QueryEntity<ReportState, Report> {

  reportType$ = this.select(x => x.reportType);
  isExporting$ = this.select(x => x.isExporting);
  isLoading$ = this.selectLoading();

  constructor(protected store: ReportStore) {
    super(store);
  }
}
