<div
	class="align-self-center position-fixed w-100 toast-wrapper"
	*ngIf="!!logs?.length"
>
	<div class="toast-outer-container container p-0">
		<div
			[class]="
				'toast-inner-container mb-2 toast-status-' +
				l?.status +
				' ' +
				(l?.containerClass || '')
			"
			*ngFor="let l of logs"
		>
			<div
				class="d-flex align-items-center justify-content-between toast-header p-0"
			>
				<div class="toast-header-text px-2 py-1">
					<i
						class="fas fa-check fa-14 mr-2"
						*ngIf="l?.status === 'success'"
					></i>{{ l?.header || "" }}
				</div>
				<div class="toast-header-btn">
					<button class="icon-btn" (click)="remove(l)">
						<i class="material-icons md-20">close</i>
					</button>
				</div>
			</div>
			<div class="d-flex toast-message py-3">
				<ng-container *ngIf="l?.allowHtmlMessage; else defaultMessage">
					<div [innerHTML]="l?.message || ''"></div>
				</ng-container>
				<ng-template #defaultMessage>
					<span>{{ l?.message || "" }}</span>
				</ng-template>
			</div>
		</div>
	</div>
</div>
