import { SecondaryClientState } from './secondary-client.model';
import { ClientProfilePrimaryMapper as PrimaryMapper } from '../primary-client/primary-client.mapper';
import { omit } from 'ramda';

export class ClientProfileSecondaryMapper {
	/**
	 * Map to view
	 * @param data SecondaryClientState
	 */
	public static mapToView(data: SecondaryClientState): SecondaryClientState {
		return {
			...data,
			professionalContacts: data.professionalContacts ? JSON.parse(data.professionalContacts) : null,
			dateOfBirth: PrimaryMapper.formatDateToMoment(data.dateOfBirth)
		};
	}

	/**
	 * Map to udpate
	 * @param data SecondaryClientState
	 */
	public static mapToUpsert(formData: SecondaryClientState): SecondaryClientState {
		const data = omit(['age'], formData);
		return {
			...data,
			// tslint:disable-next-line: max-line-length
			professionalContacts:
				data.professionalContacts && data.professionalContacts.length > 0
					? JSON.stringify(data.professionalContacts)
					: null,
			income: data.income ? +data.income : null,
			dateOfBirth: PrimaryMapper.formatDateToServerDate(data.dateOfBirth),
			isActive: 1,
			saveAnyway:false,
		};
	}

	/**
	 * Map to udpate
	 * @param data SecondaryClientState
	 */
	public static mapToState(data: SecondaryClientState): SecondaryClientState {
		return {
			...data
		};
	}
}
