import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientBirthday } from './state/cb.model';
import { Observable, Subject } from 'rxjs';
import MomentUtil from '../../../../../util/moment.util';
import { UntypedFormControl } from '@angular/forms';
import { DateRange } from '../../../../../shared/date-range/date-range.component';
import { CBQuery } from './state/cb.query';
import { DashboardQuery } from '../../state/dashboard.query';
import { filter, takeUntil, combineLatest, startWith } from 'rxjs/operators';
import { CBService } from './state/cb.service';
import { RouteService, CommandRoute } from '../../../../../core/config/route.service';

@Component({
  selector: 'app-cb',
  templateUrl: './cb.component.html',
  styleUrls: ['./cb.component.scss']
})
export class CBComponent implements OnInit, OnDestroy {
  public static widgetCode = 'cb';
  public static widgetName = 'Client Birthdays';
  public static sizeX = 350;
  public static sizeY = 480;
  public static minSizeX = 350;
  public static minSizeY = 480;

  widgetCode = CBComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  data$: Observable<ClientBirthday[]> = this.query.data$;

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;
  /**
   * Error message string. Show error if not empty.
   */
  error$ = this.query.error$;

  /** form control for date range.
   * Should default to 1 week behind up to now.
   */
  dateControl: UntypedFormControl = new UntypedFormControl({
    min: MomentUtil.formatToServerDate(MomentUtil.createMomentNz()),
    max: MomentUtil.formatToServerDate(MomentUtil.createMomentNz().add(5, 'day'))
  } as DateRange);

  constructor(
    private query: CBQuery,
    private service: CBService,
    private dashboardQuery: DashboardQuery,
    private routeService: RouteService
  ) {}

  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        combineLatest(this.dateControl.valueChanges.pipe(startWith(this.dateControl.value))),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([advisers, dateRange]) => {
        this.service.GetClientBirthdays({
          advisers,
          dateRangeMin: dateRange.min,
          dateRangeMax: dateRange.max
        });
      });
  }

  goToClientProfile(id: number): CommandRoute {
    return this.routeService.customerView(id);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
