<app-header-v2 *ngIf="(menuReworkFeature$ | async); else v1"></app-header-v2>
<ng-template #v1>
  <app-header></app-header>
</ng-template>
<div class="container-fluid app-container">
  <div class="row">
    <section class="app-nav col-lg-2 col-12 border-right">
      <app-business-sidenav *ngIf="!isOnTapLevelView"></app-business-sidenav>
      <app-backend-sidenav *ngIf="isOnTapLevelView"></app-backend-sidenav>
    </section>
    <main class="app-main col-lg-10 col-12 px-0 bg-white">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
