import {
  Directive,
  ElementRef,
  HostListener,
  Input,
} from "@angular/core";

const CLASS_NAMES = {
  showClass: 'show',
  openClass: 'open',
  hoverClass: 'hover',
  expandedAttr: 'aria-expanded',
  popperAttr: 'data-bs-popper',
  twocol: 'dropdown-2col'
};
@Directive({
  selector: '[appHeaderNavHover]',
  standalone: true
})
export class HeaderNavHoverDirective {
  private activeDropdown: HTMLLIElement | null = null;
  private dropdownMenuLink: HTMLAnchorElement | null = null;
  private dropdownMenuList: HTMLUListElement | null = null;
  
  @Input('isNavOpen') isNavOpen: boolean;
  @Input('isMobile') isMobile = false;

  itemToggle:boolean;

  @HostListener('click', ['$event'])
  onMouseClick(event) {
    if(!this.isMobile) {
      return;
    }
    const elementIsParent = event.target.className.includes('nav-link-container');
    if(!elementIsParent) {
      return;
    }

    const element = this.el.nativeElement;
    const isOpen = element.className.includes('open');
    
    if(isOpen) {
      this.onLeave(element);
    } else {
      this.onEnter(element);
    }
    this.itemToggle = !this.itemToggle;
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if(this.isMobile && !this.hasTouchSupport()) {
      return;
    }
    const element = this.el.nativeElement;
    this.dropdownMenuLink = element.querySelector('.dropdown > div > .nav-link')
    this.dropdownMenuList = element.querySelector('ul.nav-subitem');
    this.onEnter(element);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if(this.isMobile && !this.hasTouchSupport()) {
      return;
    }
    this.onLeave(this.el.nativeElement);
  }

  @HostListener('document:scroll')
  onWindowScroll() {
    const submenuList = this.dropdownMenuList?.querySelectorAll('li');

    if (this.activeDropdown && submenuList) {
      const isLong = submenuList.length > 3;
      this.positionMenu(
        this.activeDropdown.getBoundingClientRect(), 
        this.dropdownMenuList,
        isLong
      );
    }
  }

  constructor(
    private el: ElementRef
  ) {}
  
  private onEnter(element?: HTMLLIElement) {
    const submenuList = element.querySelectorAll('ul.nav-subitem > li');

    if (element && this.dropdownMenuList && submenuList) {
      const isLong = submenuList.length > 3;
      this.positionMenu(
        element.getBoundingClientRect(), 
        this.dropdownMenuList,
        isLong
      );
    }
    
    if (element) {
      this.activeDropdown = element;
      element.classList.add(CLASS_NAMES.showClass, CLASS_NAMES.openClass, CLASS_NAMES.hoverClass);
      element.setAttribute(CLASS_NAMES.expandedAttr, 'true');
      element.setAttribute(CLASS_NAMES.popperAttr, 'none'); // Assuming disabling popper on open
    }

    if (this.dropdownMenuLink) {
      this.dropdownMenuLink.setAttribute(CLASS_NAMES.expandedAttr, 'true');
    }
    
    if (this.dropdownMenuList) {
      this.dropdownMenuList.classList.add(CLASS_NAMES.showClass);
    }
  }

  private onLeave(element?: HTMLLIElement) {
    if (element) {
      this.activeDropdown = null;
      element.classList.remove(CLASS_NAMES.showClass, CLASS_NAMES.openClass, CLASS_NAMES.hoverClass);
      element.setAttribute(CLASS_NAMES.expandedAttr, 'false');
      element.removeAttribute(CLASS_NAMES.popperAttr);
    }

    if(this.dropdownMenuList) {
      this.dropdownMenuList.classList.remove(CLASS_NAMES.showClass);
    }

    if (this.dropdownMenuLink) {
      this.dropdownMenuLink.setAttribute(CLASS_NAMES.expandedAttr, 'false');
    }
  }

  positionMenu (parent: DOMRect, menu: HTMLUListElement, isLong: boolean) {
    const viewPortWidth = window.innerWidth;
    const menuWidth = isLong ? 600 : 300;
    const menuPosX = parent.x;
    const menuPosY = parent.y + parent.height
    const menuOutboundWidth = menuWidth - (viewPortWidth - menuPosX);
    const menuPosXAdj = Math.sign(menuOutboundWidth) > 0 ? (menuPosX - menuOutboundWidth) : menuPosX
    
    menu.style.left = menuPosXAdj + 'px';
    menu.style.top = menuPosY + 'px';
 }

  private hasTouchSupport() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }
}