import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { takeUntil } from 'rxjs/operators';
import { ThemeConfig } from '../models/_general/config';
import { TinyColor } from '@ctrl/tinycolor';

@Component({
	selector: 'app-tap-theme',
	templateUrl: './tap-theme.component.html',
})
export class TapThemeComponent implements OnInit, OnDestroy, AfterViewInit {
	root: HTMLElement = document.documentElement;

	/** Observable that should trigger unsubscription to others */
	onDestroy$ = new Subject<void>();

	/** Theme config object */
	themeConfig$ = this.businessConfigQuery.themeConfig$;

	constructor(private businessConfigQuery: BusinessConfigQuery) {}

	ngOnInit() {}

	ngAfterViewInit() {
		// Load theme config
		this.themeConfig$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => this.setThemeConfig(x));
		const currentTheme = localStorage.getItem('theme')
			? localStorage.getItem('theme')
			: null;
		if (currentTheme) {
			this.root.setAttribute('data-theme', currentTheme);
		} else {
			this.root.setAttribute('data-theme', 'light');
		}
	}

	setThemeConfig(theme: ThemeConfig) {
		if (theme === undefined || theme === null) {
			this.root.removeAttribute('style');
			return;
		}

		const isDarkPrimaryColor = new TinyColor(theme.primarycolor).isDark();

		this.root.setAttribute(
			'style',
			`
      --tap-bg-light-color: ${new TinyColor(
				new TinyColor('#dde2e6').toRgbString()
			)
				.setAlpha(0.2)
				.toRgbString()};

      --tap-primary-color: ${theme.primarycolor};
      --tap-secondary-color: ${theme.secondarycolor};
      --tap-tertiary-color: ${theme.tertiarycolor};

      --tap-h1-color: ${theme.h1color};
      --tap-h2-color: ${theme.h2color};
      --tap-h3-color: ${theme.h3color};
      --tap-h4-color: ${theme.h4color};
      --tap-h5-color: ${theme.h5color};
      --tap-h6-color: ${theme.h6color};

      --tap-field-text-color: ${theme.fieldTextColor};
      --tap-field-bg-color: ${theme.fieldBackgroundColor};
      --tap-field-text-placeholder-color: ${new TinyColor(
				new TinyColor(theme.fieldTextColor).toRgbString()
			)
				.setAlpha(0.6)
				.toRgbString()};

      --tap-widget-colour-1: ${theme.widgetcolor1};
      --tap-widget-colour-2: ${theme.widgetcolor2};

      --tap-primary-lighter-color: ${theme['lighterprimarycolor'] || ''};
      --tap-secondary-lighter-color: ${theme['lightersecondarycolor'] || ''};

      --tap-dark1-color:	#00263e;
      --tap-dark2-color:	#395e76;
      --tap-dark3-color:	#ffffff;
      --tap-dark4-color:	#96b7c2;
      --tap-dark5-color:	#001726;
      --tap-dark6-color:	#2b2b2b;
      --tap-dark7-color:	#d5dee1;

      --tap-light1-color:	#dde2e6;
      --tap-light2-color:	#d8d8d8;
      --tap-light3-color:	#ffffff;
      --tap-light4-color:	#96b7c2;
      --tap-light5-color:	#d5dee1;

      --tap-search-bg-input: ${new TinyColor(
				new TinyColor('#96b7c2').toRgbString()
			)
				.setAlpha(0.1)
				.toRgbString()};
      --tap-search-bg-column: ${new TinyColor(
				new TinyColor('#d5dee1').toRgbString()
			)
				.setAlpha(0.35)
				.toRgbString()};
      --tap-bg-not-visible-columns: ${new TinyColor(
				new TinyColor('#d5dee1').toRgbString()
			)
				.setAlpha(0.15)
				.toRgbString()};
      --tap-header-username-color: ${
				isDarkPrimaryColor ? '#ffffff' : '#000000'
			};

      --tap-dropdown-text-color: ${isDarkPrimaryColor ? '#ffffff' : '#000000'};
			--tap-soa-heading-color: ${theme.soaHeadingColor || '#000000'};
      `
				?.replace(/(\r\n|\n|\r)/gm, '')
				?.replace(/ /g, '')
		);
	}

	/** Trigger destroying of observables. */
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
