import { Component, OnInit } from '@angular/core';
import { ConfirmationCallQuestionQuery } from '../states/confirmation-call-question.query';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import {
	ConfirmationCallQuestion,
	DEFAULT_FINAL_SECTION,
} from '../states/confirmation-call-question.model';
import { AppFormGroup } from '@util/form-group';
import {
	CdkDragDrop,
	moveItemInArray,
	CdkDropList,
	CdkDrag,
} from '@angular/cdk/drag-drop';
import { ConfirmationCallSettingsService } from '../states/confirmation-call-settings.service';
import { finalize, map, mergeMap, take } from 'rxjs/operators';
import { LoggerService } from '@core/logger/logger.service';
import { logMessage } from '@shared/error-message/error-message';
import { ConfirmationCallFinalSectionQuery } from '../states/confirmation-call-final-section.query';
import * as R from 'ramda';
import { combineLatest, Observable, of } from 'rxjs';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { ManageUsersListService } from '@modules/user/comps/manage-users/manage-users-list/states/manage-users-list.service';
import { ManageUsersListMapper } from '@modules/user/comps/manage-users/manage-users-list/states/manage-users-list.mapper';
import { defaultManageUserConfig } from '@modules/user/comps/manage-users/manage-users-list/manage-users-list.table-config';

@Component({
	selector: 'app-confirmation-call-question',
	templateUrl: './confirmation-call-question.component.html',
	styleUrls: ['./confirmation-call-question.component.scss'],
})
export class ConfirmationCallQuestionComponent implements OnInit {
	formGroup = new FormGroup({
		questions: this.fb.array<ConfirmationCallQuestion>([]),
	});
	finalSection: string;
	enableActivityAssignedTo: boolean;
	activityAssignedTo: string;
	activityAssignedToName: string;

	allUserChoises$ =  of(defaultManageUserConfig.filter).pipe(
		map(ManageUsersListMapper.mapSearchFilter),
		mergeMap(this.manageUserListService.get),
		map(ManageUsersListMapper.mapToView),
		map((res) => res?.SearchResults || []),
		map((res) => res.map(u => ({value: u?.StaffID, display: `${u.FirstName} ${u.LastName}`}))),
		take(1)
		) as Observable<ViewDisplayValue<any>[]>;
	allStaff: ViewDisplayValue[];

	get questions(): FormArray {
		return this.formGroup.controls.questions;
	}

	questions$ = this.confirmationCallSettingsService.questions$;
	finalSection$ = this.confirmationCallSettingsService.finalSection$;
	activityAssignedTo$ = this.confirmationCallSettingsService.activityAssignedTo$;
	automaticActivity$ = this.confirmationCallSettingsService.automaticActivity$;

	isSaving = false;

	constructor(
		private confirmationCallSettingsService: ConfirmationCallSettingsService,
		private confirmationCallQuestionQuery: ConfirmationCallQuestionQuery,
		private confirmationCallFinalSectionQuery: ConfirmationCallFinalSectionQuery,
		private manageUserListService: ManageUsersListService,
		private fb: FormBuilder,
		private loggerService: LoggerService
	) {}

	ngOnInit(): void {
		combineLatest([
			this.confirmationCallSettingsService.getQuestions(),
			this.confirmationCallSettingsService.getFinalSection(),
			this.questions$,
			this.finalSection$,
			this.allUserChoises$,
			this.activityAssignedTo$,
			this.automaticActivity$
		])
		.pipe(
			take(1),
			map(([,,q, fs, allUsers, assignedTo, automaticActivity]) => {
				// questions
				AppFormGroup.clearFormArray(this.questions);
				q.forEach((q) => {
					this.questions.push(
						this.fb.group({
							question: new FormControl(q.question, [Validators.required]),
							answer: new FormControl(q.answer),
							orderNo: new FormControl(q.orderNo),
							isDefault: new FormControl(q.isDefault),
							qId: new FormControl(q.qId),
							status: new FormControl(q.status),
						})
					);
				});
				this.formGroup.disable();

				// final section
				if(fs) {
					this.finalSection = fs.replace(/[^\S\r\n]+/g, ' ').replace(/\n (.*\S)+/g, '\n$1').trim()
				}

				this.allStaff = allUsers;

				// activity assigned to
				this.activityAssignedTo = assignedTo;
				this.activityAssignedToName = allUsers.find(x => x.value.toString() === assignedTo.toString())?.display;

				// automatic activty assigned to
				this.enableActivityAssignedTo = automaticActivity;
			})
		).subscribe();
	}

	drop(e: CdkDragDrop<ConfirmationCallQuestion[]>): void {
		moveItemInArray(e.container.data, e.previousIndex, e.currentIndex);
	}

	delete(question: ConfirmationCallQuestion, index: number): void {
		this.questions.controls.forEach((formGroup: FormGroup) => {
			if (formGroup.value.orderNo > question.orderNo) {
				formGroup.controls.orderNo.setValue(formGroup.value.orderNo - 1);
			}
		});
		this.questions.removeAt(index);
	}

	private generateLastOrderNumber(): number {
		return (
			this.questions.controls.reduce(
				// @ts-ignore-next
				(prev, cur) => Math.max(prev, cur.value.orderNo),
				0
			) + 1
		);
	}

	addQuestion(): void {
		this.questions.push(
			this.fb.group({
				question: new FormControl('', [Validators.required]),
				answer: new FormControl(''),
				orderNo: new FormControl(this.generateLastOrderNumber()),
				isDefault: new FormControl(false),
				qId: new FormControl(0),
				status: new FormControl(true),
			})
		);
	}

	// this will disable drop on non custom list item
	cdkDropListSortPredicate = (
		index: any,
		_drag: CdkDrag<FormGroup>,
		dropList: CdkDropList<FormGroup>
	) => {
		return this.formGroup.enabled;
	};

	save(): void {
		if (this.isSaving) {
			return;
		}
		if (this.formGroup.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		this.isSaving = true;

		this.confirmationCallFinalSectionQuery
			.select((state) => state)
			.pipe(
				take(1),
				mergeMap((settings) => {
					if(settings?.finalSection !== this.finalSection) {
						settings.finalSection = this.finalSection;
						return this.confirmationCallSettingsService.updateFinalSection(settings)
					}
					return of(false);
				})
			).subscribe();

		this.confirmationCallQuestionQuery
			.select((state) => state)
			.pipe(
				take(1),
				mergeMap((settings) => {
					const updatedQuestions = this.questions.getRawValue() as any;
					settings.questions = updatedQuestions?.map((q, index) => {
						q.orderNo = index;
						const questionSetting = settings.questions.find(
							(s) => s.qId === q.qId
						);
						if (questionSetting?.isDefault) {
							q.question = questionSetting.question;
						}
						return q;
					});
					settings.automaticActivity = this.enableActivityAssignedTo;
					settings.activityAssignedUser = this.activityAssignedTo;
					settings.activityAssignedUserName = this.activityAssignedToName;

					return this.confirmationCallSettingsService.updateQuestions(settings);
				}),
				finalize(() => this.formGroup.disable())
			)
			.subscribe(() => (this.isSaving = false));
	}

	modelIsEmpty(value) {
		return !R.complement(R.either(R.isNil, R.isEmpty))(value);
	}

}
