<div class="card-header font-weight-bold text-light tap-bg-primary  d-flex align-items-center w-100 justify-content-between">
  {{ header }}
  <button
    class="close-btn text-white border bg-transparent d-flex align-items-center justify-content-center"
    (click)="close()"
    aria-label="Close"
  >
    <span class="crm-close-btn"></span>
  </button>
</div>
<div class="card-body">
  <app-email-form
		[onModalClosed]="onModalClosed"
		[sendToLabel]="sendToLabel"
    [emailSettings]="emailSettings$ | async"
    [attachments]="attachments"
		[getCrtInfoFn]="getCrtInfoFn"
		[initFormValue]="initFormValue"
    [emailDocumentTemplate]="generateFinalisedEmailTemplateFn$ | async"
		[defaultLinkDocumentTab]="defaultLinkDocumentTab"
    [bodyContent]="bodyContent"
		[clientDocumentsLoader]="clientDocumentsLoader"
    [shortCodes]="shortCodes"
    [peopleList]="peopleList"
    (cancel)="decline()"
    (sent)="sent()"
		(next)="next()"
    [saveEmailFn$]="saveEmailFn$"
    [beforeSendFn$]="beforeSendFn$"
    [sendEmailFn$]="sendEmailFn$"
    [downloadOfferTermsFn$]="downloadOfferTermsFn$"
    [showEmailTemplate]="showEmailTemplate"
    [successMessage]="successMessage"
    [attachFiles]="attachFiles"
		[adviceProcess]="adviceProcess"
		[businessConfig]="businessConfig$ | async"
    [mergeTags$]="mergeTags$"
    [documentInfo]="documentInfo"
		[disableEmailUpdate]="disableEmailUpdate"
		[showNextBtn]="showNextBtn"
		[isLoading]="isLoading && isLoadingECList"
    [isECTemplate]="isEmailClientTemplate"
    [ecTemplateList]="emailClientTemplateList"
		[onUploadedDocument]="onUploadedDocument"
		[adviserId]="adviserId"
		[enableDefaultBcc]="true"
  >
    <div
				*ngIf="showAttachmentUI"
				[ngClass]="{ 'disabled': isLoading }"
			>
      <div class="form-row pl-1 font-weight-bold">Email {{ attachments?.length > 1 || offerTerms?.length ? 'Attachments' : 'Attachment' }}</div>

      <div class="col mt-2 pt-2 attachments">
        <div class="row">
          <div class="col-9 pl-0">
            <div *ngFor="let attachment of attachments; let i = index" class="card-footer attachment">
              <div
                id="downloadBtn_{{i}}"
                class="btn btn-md document-button-container p-0"
                (click)="!!attachment?.queue || !!attachment?.disableDownload ? null : downloadPdfAttachment(attachment)"
                [class.disabled]="!!attachment?.queue || !!attachment?.disableDownload"
              >
                <i class="far fa-file-pdf text-danger pr-1"></i>
                <small class="text-muted">{{attachment?.fileName || ''}}</small>
              </div>
							<ng-container *ngIf="attachment?.allowDeleting !== false">
								<button
										id="file_{{ i }}"
										type="button"
										class="icon-btn w-auto px-1 h-auto"
										(click)="removeAttachment(i)"
										*ngIf="
											!attachment?.generateContentCallback$ &&
											(!attachment?.fileUrl || attachment?.type === 'linked')
										"
										>
										<i class="material-icons md-16 close-icon"> close </i>
								</button>
							</ng-container>
            </div>
            <div *ngFor="let offerTerm of offerTerms" class="card-footer attachment">
              <div *ngIf="offerTerm"
                id="downloadOfferOfTermsBtn"
                class="btn btn-md document-button-container px-0"
                (click)="downloadOfferTerms(offerTerm?.referenceId)"
              >
                <i class="far fa-file-pdf text-danger pr-1"></i>
                <small class="text-muted">{{offerTerm?.value}}</small>
              </div>
            </div>
          </div>
          <div class="col-3 text-right pt-2 pr-0">
            <button
              id="attachDocument"
              class="tap-btn tap-btn-outline tap-btn--shadow"
              (click)="openAttachmentModal()"
            >
              Attach Document
            </button>
          </div>
        </div>
      </div>
    </div>

  </app-email-form>
</div>
