import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from './report.component';
import { ReportPageResolver } from './report.resolver';

const routes: Routes = [{
  path: '',
  data: {
    breadcrumb: 'Reports',
  },
  component: ReportComponent,
  resolve: {
    data: ReportPageResolver
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
