import { loatRiskAnalysisSosNotes } from '@shared/models/client-review-template/merge-tags/crt-lr-insurance/risk-analysis/risk-analysis.merge-tags';

export const KEYCODES = {
	enter: 13,
	backspace: 8,
	delete: 46,
};

export class WysiwygMapper {
	public static preventOnLoatRAMTnotes(
		ancestorElement: HTMLElement,
		parentElement: HTMLElement
	) {
		const parentClasses = Array.from(parentElement?.classList);
		const containerElem = document.getElementById(ancestorElement?.id);
		const containerElemLength = containerElem?.textContent?.length;
		const position = this.getCaretCharacterOffsetWithin(containerElem);
		const isCaretInStartOrEnd =
			position === 0 || position === containerElemLength;
		const isWholeTagSelected = parentClasses?.includes('fr-element');
		return isCaretInStartOrEnd || isWholeTagSelected;
	}

	public static getCaretCharacterOffsetWithin(element) {
		var caretOffset = 0;
		var doc = element.ownerDocument || element.document;
		var win = doc.defaultView || doc.parentWindow;
		var sel;
		if (typeof win.getSelection != 'undefined') {
			sel = win.getSelection();
			if (sel.rangeCount > 0) {
				var range = win.getSelection().getRangeAt(0);
				var preCaretRange = range.cloneRange();
				preCaretRange.selectNodeContents(element);
				preCaretRange.setEnd(range.endContainer, range.endOffset);
				caretOffset = preCaretRange.toString().length;
			}
		} else if ((sel = doc.selection) && sel.type != 'Control') {
			var textRange = sel.createRange();
			var preCaretTextRange = doc.body.createTextRange();
			preCaretTextRange.moveToElementText(element);
			preCaretTextRange.setEndPoint('EndToEnd', textRange);
			caretOffset = preCaretTextRange.text.length;
		}
		return caretOffset;
	}
	public static preventOnLoatRAInputNotes(
		element: Node,
		ancestor: HTMLElement,
		previous: HTMLElement,
		key: number
	) {
		const id = ancestor?.id;
		const content = element?.textContent?.trim();
		const noteId = !!id ? loatRiskAnalysisSosNotes[id] : null;
		if (!noteId) {
			return false;
		}
		const noteElement = document.getElementById(noteId);
		const nextSibling = noteElement?.nextSibling?.nextSibling as any;
		const pos = this.getCaretCharacterOffsetWithin(element);
		const elemLength = element?.textContent?.length;
		if (key == KEYCODES.backspace) {
			if (
				(pos === 0 || !content) &&
				(previous?.tagName === 'DIV' || nextSibling?.tagName !== 'P')
			) {
				return true;
			}
		}
		if (key == KEYCODES.delete) {
			if ((pos === elemLength || !content) && nextSibling?.tagName !== 'P') {
				return true;
			}
		}
		return false;
	}

}
