import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	OnChanges,
	Renderer2,
} from '@angular/core';
import { Observable, Subscription, Observer } from 'rxjs';
import UserViewModel from '../../viewmodels/userViewModel';
import { viewStaff } from '../../viewmodels/viewStaff';
import { viewSecGroup } from '../../viewmodels/viewSecGroup';
import { BlStaffViewmodel } from '../../viewmodels/bl-staff.viewmodel';
import { mergeMap, tap } from 'rxjs/operators';
import {
	BLStaffsService,
	TransferActivityModel,
} from '../../../../domain/bl-staff/bl-staffs.service';
import { DeleteModalComponent } from '../../../../shared/modal/delete-modal/delete-modal.component';
import { DeleteDropdownModalComponent } from '../../../../shared/modal/delete-dropdown-modal/delete-dropdown-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { mainSecGroupSorter } from './sec-group-sorter.util';
@Component({
	selector: 'app-list-user',
	templateUrl: './list-user.component.html',
	styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit, OnDestroy, OnChanges {
	constructor(
		private modalService: BsModalService,
		private blStaffService: BLStaffsService,
		private renderer: Renderer2
	) {}
	subs: Subscription[] = [];
	userViewModel: UserViewModel[] = [];

	@Input() staffs: (viewStaff | BlStaffViewmodel)[] = [];
	@Input() secGroups: viewSecGroup[] = [];
	@Input() deleteSecGroups: string[] = [];
	@Input() isTapLevel = false;
	@Output() deleteUser: EventEmitter<number> = new EventEmitter<number>();
	ngOnDestroy(): void {
		this.subs.forEach((element) => {
			element.unsubscribe();
		});
	}

	ngOnInit() {}

	ngOnChanges(): void {
		const newUserViewModelList: UserViewModel[] = [];
		const sortSecGroup = this.secGroups.sort(mainSecGroupSorter);
		sortSecGroup.forEach((x) => {
			const filteredStaffs = this.staffs.filter(
				(y) => y.SecurityGroup === x.securityGroupCode
			);
			const active = filteredStaffs
				.sort(this.compare)
				.filter((valFilter1) => valFilter1.IsActive);
			const inactive = filteredStaffs
				.sort(this.compare)
				.filter((valFilter2) => !valFilter2.IsActive);
			newUserViewModelList.push(
				new UserViewModel(x, active, inactive, false, false)
			);
		});
		this.userViewModel = newUserViewModelList;
	}

	delete(id: number) {
		this.deleteUser.emit(id);
	}
	confirmDelete(staff: viewStaff | BlStaffViewmodel) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete(staff.StaffID);
			obs.complete();
		});

		const blInitState = {
			header: 'Delete Staff',
			staff,
			advisers: this.staffs
				.filter((x) => x.IsActive && x.StaffID !== staff.StaffID)
				.map((x) => ({ ...x, fullName: `${x.FirstName} ${x.LastName}` }))
				.sort((a, b) => a.fullName.localeCompare(b.fullName)),
			confirm$: confirm,
			transfer: this.transferActivity,
		};

		const tlInitialState = {
			header: 'Delete Staff',
			message: `Are you sure you want to delete '${staff.FirstName} ${staff.LastName}'?`,
			delete$: confirm,
		};

		const deleteModal: any = this.isTapLevel
			? DeleteModalComponent
			: DeleteDropdownModalComponent;

		this.modalService.show(deleteModal, {
			class: 'modal-dialog-centered',
			initialState: this.isTapLevel ? tlInitialState : blInitState,
			ignoreBackdropClick: true,
		});
	}

	transferActivity = (data: TransferActivityModel) =>
		new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.blStaffService.TransferActivity(x)),
			tap((x) => {
				if (x) {
					this.exportHistory(x);
				}
			})
		);

	exportHistory(file: any) {
		const name = 'activities.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		const url = window.URL.createObjectURL(file);
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
		window.URL.revokeObjectURL(url);
	}

	compare(a, b) {
		const fnameA = a.firstName;
		const fnameB = b.firstName;

		const lnameA = a.lastName;
		const lnameB = b.lastName;

		if (fnameA > fnameB) {
			return 1;
		} else if (fnameA < fnameB) {
			return -1;
		} else {
			if (lnameA < lnameB) {
				return -1;
			} else if (lnameA > lnameB) {
				return 1;
			} else {
				return 0;
			}
		}
	}
}
