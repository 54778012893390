import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { Observable, forkJoin, of, EMPTY } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { RiskAnalysisLifeState } from '../../../../../../shared/models/client-review-template/risk-analysis/life/life.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../client-review-template.query';
import { ClientReviewTemplateService } from '../../client-review-template.service';
import { ClientReviewTemplateStore } from '../../client-review-template.store';
import { LifeQuery } from './life.query';
import { LifeStore } from './life.store';
import { find, propEq } from 'ramda';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

const section = {
  FAP: 'properties',
  FAA: 'assets',
  FAM: 'mortgages',
  FAL: 'liabilities',
  FAO: 'others',
};

@Injectable()
export class LifeService
  extends ClientReviewTemplateService
  implements Resolve<boolean>
{
  formValue$ = this.lifeQuery.select((x) => x.formValue);
  riskLife$ = this.query.riskAnalysisLife$;
  riskLifeList$ = this.lifeQuery.getAll();

  constructor(
    private api: ApiService,
    protected dropdownValueQuery: DropdownValueQuery,
    protected store: ClientReviewTemplateStore,
    protected query: ClientReviewTemplateQuery,
    protected customerService: CustomerService,
    protected businessService: BusinessService,
    protected lifeStore: LifeStore,
    protected lifeQuery: LifeQuery
  ) {
    super(dropdownValueQuery, store, query, customerService, businessService);
  }

  clearData() {
    applyTransaction(() => {
      this.lifeStore.reset();
    });
  }

  getLife() {
    return this.api
      .get<[]>(
        `crt/risk-analysis/advice-process/${
          this.query.getValue().adviceProcessId
        }/${AdviceProcessSectionCodes.Life}`
      )
      .pipe(
        map(
          (x) =>
            x.map(
              objectUtil.mapPascalCaseToCamelCase
            ) as RiskAnalysisLifeState[]
        ),
        tap((x) =>
          applyTransaction(() => {
            this.lifeStore.set(x);
          })
        ),
        catchError(() => of(undefined))
      );
  }

  addLife(life: RiskAnalysisLifeState) {
    const data = objectUtil.mapCamelCaseToPascalCase(life);
    return this.api.post3<number>(`crt`, data).pipe(
      tap((x) =>
        applyTransaction(() => {
          this.lifeStore.update(life.parentCRTId, { ...life, cRTId: x });
        })
      ),
      catchError(() => EMPTY)
    );
  }

  updateLife(life: RiskAnalysisLifeState) {
    const data = objectUtil.mapCamelCaseToPascalCase(life);
    return this.api.put<number>(`crt/${life.cRTId}`, data).pipe(
      tap((x) =>
        applyTransaction(() => {
          this.lifeStore.update(life.parentCRTId, life);
        })
      ),
      catchError(() => EMPTY)
    );
  }

  setActiveParentCRTId(parentCRTId: number) {
    this.lifeStore.setActiveParentCRTId(parentCRTId);
  }

  setFormValue(data: RiskAnalysisLifeState) {
    this.setHasFormChanges(true);
    const list = this.lifeQuery.getAll();
    const isExisting = find(propEq('parentCRTId', data?.parentCRTId))(list);
    if (isExisting) {
      this.lifeStore.update(data?.parentCRTId, data);
    } else {
      this.lifeStore.add(data);
    }
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const getLife$ = !!this.lifeQuery.getHasCache() ? of(null) : this.getLife();

    return forkJoin([getLife$]).pipe(mapTo(true));
  }
}
