import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BlBusinessComponentService } from '../../bl-business-component.service';
import { Subscription } from 'rxjs';
import { ViewBusiness } from '../../../viewmodels/business.viewmodel';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, FormControl } from '@angular/forms';
import { ServicesCodes } from '@shared/models/services/services.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, OnChanges {
	subs: Subscription[];
	@Input() data: ViewBusiness;
	@Input() isEdit: boolean;
	@Input() isLoading: boolean;
	@Input() hasPermission: boolean;
	@Input() clientAlterationRequestEnabled = false;
	dataForm: UntypedFormGroup = this.fb.group({
		businessId: [''],
		businessName: ['', [Validators.required]],
		description: [''],
		website: [''],
		domain: [''],
		address1: [''],
		address2: [''],
		city: [''],
		postcode: [''],
		country: [''],
		firstName: [''],
		lastName: [''],
		position: [''],
		emailAddress: [''],
		phoneNumber: [''],
		services: this.fb.array([]),
		companyCode: [''],
    isActive: [''],
		fAP: [''],
		fSPNumber: []
	});
	@Output() saveEvent = new EventEmitter<ViewBusiness>();
	@Output() editEvent = new EventEmitter<void>();
	@Output() cancelEvent = new EventEmitter<void>();
	countries: string[] = ['Australia', 'New Zealand'];
	services$ = this.compService.services$;
	serviceMap = {};

	get servicesForm(): UntypedFormArray {
		return this.dataForm.get('services') as UntypedFormArray;
	}
	constructor(private fb: UntypedFormBuilder, private compService: BlBusinessComponentService) { }
	ngOnInit(): void { }
	ngOnDestroy(): void { }
	ngOnChanges(changes: SimpleChanges): void {
		const isEdit = changes.isEdit ? changes.isEdit.currentValue : this.isEdit;
		const isLoading = changes.isLoading ? changes.isLoading.currentValue : this.isLoading;
		const newData = changes.data ? changes.data.currentValue : null;
		const data = changes.data ? changes.data.currentValue : this.data;

		if (isEdit === false || isLoading) {
			this.dataForm.disable();
		} else if (isEdit) {
			this.dataForm.enable();
		}

		if ((!isEdit && !isLoading) || (newData && !isEdit && !isLoading)) {
			if (data !== null) {
				this.dataForm.reset(data);
				this.setServices();
			}
		}

		// set Service Map to get service code index
		this.serviceMap = {};
		for(const [i, val] of Object.values(this.servicesForm.controls).entries()) {
			const code = val.value.code;
			this.serviceMap = Object.assign({[code]: i},this.serviceMap);
		}

		this.controlServiceCheck(ServicesCodes.LR);
		this.controlServiceCheck(ServicesCodes.Mortgage);
		this.controlServiceCheck(ServicesCodes.KiwiSaver);
		this.controlServiceCheck(ServicesCodes.MortgageOnlineAdviceTool);

	}

	private controlServiceCheck(serviceCode: string) {
		const getService = (code) => this.servicesForm.controls[this.serviceMap[code]] as FormControl;
		const service = getService(serviceCode);
		const selectedService = service.get('selected');

		const toggle = (value) => {
			const toggleChildService = (...codes) => {
				for(const code of codes) {
					if(value) {
						getService(code).enable();
					}else {
						getService(code).disable();
						getService(code).get('selected').patchValue(false);
					}
				}
			}
			switch(serviceCode) {
				case ServicesCodes.LR:
					toggleChildService(ServicesCodes.ClientReviewTemplate, ServicesCodes.ClientAlterationRequest);
					break;
				case ServicesCodes.Mortgage:
					toggleChildService(ServicesCodes.MortgageOnlineAdviceTool);
					break;
				case ServicesCodes.KiwiSaver:
					toggleChildService(ServicesCodes.KiwisaverOnlineAdviceTool);
					break;
				case ServicesCodes.MortgageOnlineAdviceTool:
					toggleChildService(ServicesCodes.CustomerPortalMOAT, ServicesCodes.CustomerPortalMOATDocuments);
					break;
				default:
					return;
			}
		}

		toggle(selectedService.value);
		selectedService.valueChanges.subscribe(value => {
			toggle(value);
		});
	}

	private setServices(): void {
		const serviceGroup = this.data.services?.map(x => this.fb.group(x));
		const serviceGroupArray = this.fb.array(serviceGroup);
		this.dataForm.setControl('services', serviceGroupArray);
	}

	get businessName() {
		return this.dataForm.get('businessName');
	}

	edit() {
		this.editEvent.emit();
	}
	cancel() {
		this.cancelEvent.emit();
	}
	save() {
		if (this.dataForm.invalid) {
			return;
		}
		const data = Object.assign(new ViewBusiness(), this.data, this.dataForm.value);
		this.saveEvent.emit(data);
	}

	showService(code: ServicesCodes): boolean {
		switch (code) {
			case ServicesCodes.ClientReviewTemplate:
				return this.hasPermission;
			case ServicesCodes.ClientAlterationRequest:
				return this.clientAlterationRequestEnabled;
		}
		return true;
	}

}
