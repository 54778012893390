export const inviteHeader = (businessNameMergeTag: string) =>
	`<p contenteditable="false">Hi %CP_PERSON_FIRST_NAME%,</p><p contenteditable="false">%ADVISER_FIRST_NAME% from ${businessNameMergeTag} has invited you to complete a new Loan Application form.</p>`;

export const inviteFooter = (
	cpSiteUrl: string
) => `<p contenteditable="false">To get started please click <a href="${cpSiteUrl}" target="_blank">here</a> to login to your secure online portal.</p>
<p contenteditable="false">Please note: If your email address is a Google Account, you can log in via Google. If not, you will need to reset your password before logging in.</p>
<p contenteditable="false">If you have any questions or concerns please contact your adviser on %ADVISER_MOBILE_PHONE%.</p>
<p contenteditable="false">Kind regards,</p>
<p contenteditable="false">The MyApp Team</p>`;

const inviteTemplate = (cpSiteUrl: string, template: string) =>
	`${inviteHeader}${template ?? ''}${inviteFooter}`;

export default inviteTemplate;
