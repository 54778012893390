// When using this email link list, add also in froala config:
// linkAutoPrefix: '',
// This is to allow merge tags on links w/o prepending http://
export const EmailLinkList = [
	{
		text: 'Unsubscribe From This Email',
		href: '%ASM_GROUP_UNSUBSCRIBE_RAW_URL%',
		class: 'footer-link',
	},
	{
		text: 'Manage Email Preferences',
		href: '%ASM_PREFERENCES_RAW_URL%',
		class: 'footer-link',
	},
];

export const EmailLinksMT = [
	{
		metaKey: 'ASM_GROUP_UNSUBSCRIBE_RAW_URL',
		description: 'Unsubscribe Link',
		value: '<%asm_group_unsubscribe_raw_url%>',
		type: 'T',
		secondaryValue: '',
	},
	{
		metaKey: 'ASM_PREFERENCES_RAW_URL',
		description: 'Manage Email Preferences Link',
		value: '<%asm_preferences_raw_url%>',
		type: 'T',
		secondaryValue: '',
	},
];
