(function (root, factory) {
	if (typeof define === "function" && define.amd) {
		// AMD. Register as an anonymous module.
		define(["chartist"], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	} else if (typeof exports === "object") {
		// Node. Does not work with strict CommonJS, but
		// only CommonJS-like enviroments that support module.exports,
		// like Node.
		module.exports = factory(require("chartist"));
	} else {
		root["Chartist.plugins.ctBarOverlap"] = factory(root.Chartist);
	}
})(this, function (Chartist) {
	(function (window, document, Chartist) {
		"use strict";
		var defaultOptions = {
			// The class name so you can style the text
			labelClass: "ct-bar-overlap",
			chartId: "chartjs_" + Math.floor(Math.random() * 999),
			borderColor: "#b2b2b2",
			borderOpacity: "1",
		};

		Chartist.plugins = Chartist.plugins || {};
		Chartist.plugins.ctBarOverlap = function (options) {
			options = Chartist.extend({}, defaultOptions, options);

			var positionX = function (data) {
				const i = data.index;
				const seriesIndex = +data.seriesIndex;
				const gridHorizontal = $(document).find(
					"#" + options.chartId + " .ct-grids line.ct-horizontal"
				);
				if (!gridHorizontal) {
					return 0;
				}

				const gridX1 = +gridHorizontal[i].getAttribute("x1");
				const step = data.axisX.stepLength / 3;
				const stepBuffer = step / 2;

				if (seriesIndex === 0) {
					// line
					const newX1 = gridX1 + step;
					return newX1 - stepBuffer;
				} else if (seriesIndex === 1) {
					// line
					const seriesStep = step * 2;
					const newX1 = gridX1 + seriesStep;
					return newX1 - stepBuffer;
				} else if (seriesIndex === 2) {
					// line
					const seriesStep = step * 3;
					const newX1 = gridX1 + seriesStep;
					return newX1 - stepBuffer;
				} else if (seriesIndex === 3) {
					// path
					return gridX1;
				} else if (seriesIndex === 4) {
					// path
					return gridX1 + step;
				} else if (seriesIndex === 5) {
					// path
					return gridX1 + step * 2;
				}
			};

			var positionY = function (data) {
				return data.y2;
			};

			var positionH = function (data) {
				return data.axisX.stepLength / 3;
			};

			var positionV = function (data) {
				return data.y1 - data.y2;
			};

			return function ctBarOverlap(chart) {
				// Since it's specific to bars, verify its a bar chart
				if (chart instanceof Chartist.Bar) {
					chart.on("draw", function (data) {
						if (data.type === "bar") {
							if (data.series.name.includes("_OB")) {
								// Initial state value of path
								const pathShapeFrom = `M${positionX(data)},169 h${positionH(
									data
								)} v0 h-${positionH(data)} Z`;
								// Final transformed state value of path
								const pathShapeTo = `M${positionX(data)},${positionY(
									data
								)} h${positionH(data)} v${positionV(data)} h-${positionH(
									data
								)} Z`;

								const pathStyle = `stroke-dasharray: 3; pointer-events: none; stroke-opacity: ${options.borderOpacity}`;

								// Create path
								data.group
									.elem(
										"path",
										{
											d: pathShapeTo,
											style: pathStyle,
											stroke: options.borderColor,
											fill: "transparent",
											value: data.value.y,
										},
										`${options.labelClass} ct-bar-${data.series.name}`
									)
									.animate({
										d: {
											dur: ".5s",
											from: pathShapeFrom,
											to: pathShapeTo,
											easing: "easeOutQuad",
										},
									});
							}
							data.element.attr({ x1: positionX(data), x2: positionX(data) });
						}
					});
				}
			};
		};
	})(window, document, Chartist);
	return Chartist.plugins.ctBarOverlap;
});
