import { Injectable } from '@angular/core';
import {
	Resolve
} from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ServicesService } from '../../../../domain/service/services.service';

@Injectable()
export class ServicesResolverService implements Resolve<any> {

	constructor(private servicesService: ServicesService) { }

	resolve(): Observable<boolean> {
		const services$ = this.servicesService.addList();
		return forkJoin([services$]).pipe(mapTo(true));
	}
}
