<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
    close
  </span>
</button>
<div class="clearfix">
  <div class="modal-header">
    <h5 class="modal-title">
      {{ header }}
    </h5>
  </div>
  <div>
    <form novalidate [formGroup]="form">
      <div class="form-group m-0">
        <div class="clearfix">
          <app-chips textboxId="multiselect" textboxClass="theme-crm-field" formControlName="multiselect" [choices]="choices"> </app-chips>
        </div>
      </div>

      <div class="justify-content-end float-right modal-footer">
        <ng-container *ngIf="!isSaving; else loader">
          <button id="saveButton" type="button" class="tap-btn tap-btn--default modal-btn-confirm" (click)="save()" [disabled]="form.invalid">Save</button>
        </ng-container>
        <ng-template #loader>
          <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
        </ng-template>
      </div>
    </form>
  </div>
</div>
