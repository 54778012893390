<form [formGroup]="form" novalidate>
	<div class="row clearfix align-items-center mb-1">
		<div class="col-lg col-12"></div>
		<div class="col-lg-auto col-12 d-flex">
			<span class="position-relative" *ngIf="isSaving$ | async">
				<app-manual-loader-mini
					[isLoading]="isSaving$ | async"
					class="p-0"
				></app-manual-loader-mini>
			</span>
			<ng-container *ngIf="!(isSaving$ | async)">
				<!-- Restriction for lead -->
				<ng-container *ngIf="isLead">
					<ng-container *appRestrictTo="['FEL']">
						<ng-container *ngIf="!(isEdit$ | async)">
							<span class="position-relative">
								<button
									type="button"
									class="icon-btn"
									(click)="edit()"
									id="businessOtherEdit"
								>
									<i class="material-icons md-20 tap-text-color-h6"> edit </i>
								</button>
							</span>
						</ng-container>
					</ng-container>
					<ng-container *appRestrictTo="['FAL', 'FEL']">
						<ng-container *ngIf="isEdit$ | async">
							<span
								class="position-relative"
								*ngIf="(isEdit$ | async) && !isSaving"
								id="businessOtherSave"
							>
								<button type="button" class="icon-btn" (click)="save()">
									<i class="material-icons md-20 tap-text-color-h6"> save </i>
								</button>
							</span>
						</ng-container>
						<span class="position-relative" *ngIf="isEdit$ | async">
							<button
								type="button"
								class="icon-btn"
								[disabled]="isSaving"
								(click)="cancel()"
								id="businessOtherCancel"
							>
								<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
							</button>
						</span>
					</ng-container>
				</ng-container>
				<!-- Restriction for current client -->
				<ng-container *ngIf="!isLead">
					<ng-container *appRestrictTo="['FEC']">
						<ng-container *ngIf="!(isEdit$ | async)">
							<span class="position-relative">
								<button
									type="button"
									class="icon-btn"
									(click)="edit()"
									id="businessOtherEdit"
								>
									<i class="material-icons md-20 tap-text-color-h6"> edit </i>
								</button>
							</span>
						</ng-container>
					</ng-container>
					<ng-container *appRestrictTo="['FEC', 'FAC']">
						<ng-container *ngIf="isEdit$ | async">
							<span
								class="position-relative"
								*ngIf="(isEdit$ | async) && !isSaving"
								id="businessOtherSave"
							>
								<button type="button" class="icon-btn" (click)="save()">
									<i class="material-icons md-20 tap-text-color-h6"> save </i>
								</button>
							</span>
						</ng-container>
						<span class="position-relative" *ngIf="isEdit$ | async">
							<button
								type="button"
								class="icon-btn"
								[disabled]="isSaving"
								(click)="cancel()"
								id="businessOtherCancel"
							>
								<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
							</button>
						</span>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="row align-items-center">
		<div class="col-lg-6 col-12">
			<div class="row" formArrayName="others">
				<div
					class="col-lg-9 d-flex mb-2"
					*ngFor="let d of others.controls; let i = index"
				>
					<app-client-search-control
						#searchClient
						style="flex: 1"
						id="otherSearch_{{ i }}"
						textboxClass="theme-crm-field-disabled"
						[formControlName]="i"
						[excludeChoiceIds]="otherIds"
						searchMode="Other"
						[hasQuickAdd]="true"
						[advisers]="advisers"
						[leadOriginChoice]="leadOriginChoice"
						[leadTypeChoice]="leadTypeChoice"
						[trustTypes]="trustTypes"
						[customerStatus]="customerStatusChange"
						(quickAddEvent)="quickAdd($event, i)"
						(selectEvent)="selectEvent($event)"
						(clearEvent)="clearEvent($event)"
						[businessInfo]="others.at(i).value"
						textboxId="other_{{ i }}"
						[showDeceased]="true"
					></app-client-search-control>
					<button
						*ngIf="isSaving$ | async"
						type="button"
						class="tap-btn tap-btn-outline border-0 p-0"
						[disabled]="isDeletable(d, otherIds)"
						(click)="remove(i)"
						id="businessOtherRemove"
					>
						<i class="material-icons align-middle p-1">&#xE872;</i>
					</button>
				</div>
				<button
					*ngIf="(isEdit$ | async) || (isSaving$ | async)"
					type="button"
					class="
						tap-btn tap-btn-outline
						border-0
						tap-text-primary
						px-1
						col-auto
						mb-2
					"
					(click)="addOthers()"
					id="businessOtherAdd"
				>
					Add Other +
				</button>
			</div>
		</div>
	</div>
</form>
