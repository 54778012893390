<div class="card-header font-weight-bold text-light tap-bg-primary">
  {{ header }}
</div>
<div class="card-body p-4">
  <ng-container [ngSwitch]="message">
    <ng-container *ngSwitchCase="'LifeAssuredDetails'">      
      <app-people-form
        [relationship]="scr$ | async"
        [employment]="pce$ | async"
        [visaDetails]="apcrtvd$ | async"
        [gender]="apcrtg$ | async"
        [personInfo]="personInfo"
        [timeInResidence]="apcrttir$ | async"
        [maritalStatus]="apcrtms$ | async"
        [title]="pct$ | async"
        [hasCountry]="hasCountry"
        (saveEvent)="save($event)"
        (cancelEvent)="cancelChange($event)"
        [loadingState]="loadingState"
      >
      </app-people-form>
    </ng-container>

    <!--<ng-container *ngSwitchCase="'DependentDetails'">
      <app-dependents-form
        [gender]="apcrtg$ | async"
        [relationship]="scr$ | async"
        [dependentInfo]="dependentInfo"
        (saveEvent)="save($event)"
        (cancelEvent)="cancelChange($event)"
      >
      </app-dependents-form>
    </ng-container>

    <ng-container *ngSwitchCase="'TrustDetails'">
      <app-trusts-form
        [trustTypes]="sctt$ | async"
        [trustInfo]="trustInfo"
        (saveEvent)="save($event)"
        (cancelEvent)="cancelChange($event)"
      ></app-trusts-form>
    </ng-container>-->

    <ng-container *ngSwitchCase="'CompanyDetails'">
      <app-company-form
        [businessPurpose]="apcrtbp$ | async"
        [agree]="apcrtynna$ | async"
        [companyInfo]="companyInfo"
        (cancelEvent)="cancelChange($event)"
        (saveEvent)="save($event)"
        [loading]="loadingState"
      >
      </app-company-form>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="false">
  <div class="card-footer bg-transparent footer-height">
    <label class="pt-3 pl-3"> + Add Additional [Information] </label>
  </div>
</ng-container>
