import { Dropdown } from '../../model/dropdown';

export class ViewDropdown {
  dropdownId: number;
  dropdownName: string;
  dropdownCode: string;

  /**
   *
   */
  constructor(id: number = null, name: string = '', code: string = '') {
    this.dropdownId = id;
    this.dropdownName = name;
    this.dropdownCode = code;
  }
  static mapFromModel(d: Dropdown) {
    return new ViewDropdown(d.DropdownID, d.DropdownName, d.DropdownCode);
  }
  mapToModel() {
    return new Dropdown(this.dropdownId, this.dropdownName, this.dropdownCode);
  }
}
