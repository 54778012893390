import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OtherDetails } from './other-details.model';

export interface OtherDetailsState extends EntityState<OtherDetails> {
	otherDetails?: OtherDetails;
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'otherDetails', idKey: 'cRTId' })
export class OtherDetailsStore extends EntityStore<
	OtherDetailsState,
	OtherDetails
> {
	constructor() {
		super();
	}
}
