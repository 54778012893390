import * as R from 'ramda';
import * as numeral from 'numeral';
import { LoanRefinanceState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/loan-refinance/state/loan-refinance.model';
import { PropertyPurchaseDetailsState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/property-purchase/state/property-purchase.model';
import { FundingRequiredLoanState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.store';
import { PropertySoldState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/selling-section/property-sold/state/property-sold.store';
import { TopupRequirementsState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/topup-requirements/state/topup-requirements.store';
import {
	PurposeDetailsState,
	PurposeTypes,
} from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/purpose/state/purpose.model';
import { ApplicationComputationsState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/review-application/state/review-application.model';
import { PropertyAddressState } from 'src/app/modules/crm/crt-page/crt-mortgage/client-sop/assets-and-liabilities/state/property/property.store';
import { numUtil, util } from 'src/app/util/util';
import { Asset, AssetState } from '../../../../assets-liabilities/asset/asset.model';
import { LiabilityState } from '../../../../assets-liabilities/liability/liability.model';
import { MergeTagState } from '../../../merge-tags.model';
import {
	appFundingRequiredMergeTag,
	appFundingRequiredMetaKey,
} from './funding-required.merge-tags';
import { TopupRequirementsDetailsState } from '@modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/topup-requirements/state/topup-requirements.model';
import { PropertySoldDetailsState } from '@modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/selling-section/property-sold/state/property-sold.model';
import { FundingRequiredLoan } from '@modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.model';

export const prefix = {
	propertyPurchase: 'Purchase Price',
	refinance: 'Refinance',
	propertyToBeSold: 'Sale Price',
	loansRepaid: 'Repayment of existing lending',
};

export class AppFundingRequiredMergeTagMapper {
	public static formatCurrency(value) {
		return numUtil.isNumber(+value)
			? numUtil.formatWholeNumNoDecimalNoSymbol(+value)
			: '0';
	}

	public static getMergeTagValues(
		purpose: PurposeDetailsState,
		propertyPurchase: PropertyPurchaseDetailsState[],
		refinance: LoanRefinanceState[],
		topupReq: TopupRequirementsState[] | any[],
		propertyToBeSold: PropertySoldState[] | any[],
		loansRepaid: FundingRequiredLoanState[] | any[],
		aLAssets: AssetState[] | any[],
		ffLiabilities: LiabilityState[] | any[],
		propertyAddress: PropertyAddressState[]| any[],
		computations: ApplicationComputationsState
	) {
		// Purpose Status
		const isRefinance = purpose?.types?.find((p) =>
			p?.includes(PurposeTypes.Refinance)
		);
		const isLendingTopup = purpose?.types?.find((p) =>
			p?.includes(PurposeTypes.LendingTopup)
		);
		const refinanceList = isRefinance
			? this.getRefinanceList(refinance, propertyAddress, ffLiabilities)
			: [];
		const loansRepaidList = isRefinance
			? this.getLoansRepaidList(loansRepaid, aLAssets, propertyAddress)
			: [];

		return appFundingRequiredMergeTag?.map((x) => {
			let value = '';
			if (x?.metaKey === appFundingRequiredMetaKey.propertyPurchaseAddress) {
				value = this.getValue(propertyPurchase, 'propertyAddress')?.map(
					(i) => `${prefix.propertyPurchase} - ${i}`
				);
			}
			if (x?.metaKey === appFundingRequiredMetaKey.propertyPurchasePrice) {
				value = this.getAmount(propertyPurchase, 'propertyValue');
			}
			if (x?.metaKey === appFundingRequiredMetaKey.refinancedLoanAddress) {
				value = this.getValue(refinanceList, 'refinanceDescription')?.map(
					(i) => `${prefix.refinance} - ${i}`
				);
			}
			if (x?.metaKey === appFundingRequiredMetaKey.refinancedLoanAmount) {
				value = this.getAmount(refinanceList, 'refinanceValue');
			}
			if (x?.metaKey === appFundingRequiredMetaKey.topupRequirementAddress) {
				value = isLendingTopup ? this.getTopupLabelValue(topupReq) : [];
			}
			if (x?.metaKey === appFundingRequiredMetaKey.topupRequirementAmount) {
				value = isLendingTopup
					? this.getAmount(topupReq, 'topupRequirementValue')
					: [];
			}
			if (x?.metaKey === appFundingRequiredMetaKey.propertyToBeSoldAddress) {
				value = this.getValue(propertyToBeSold, 'propertyAddress')?.map(
					(i) => `${prefix.propertyToBeSold} - ${i}`
				);
			}
			if (x?.metaKey === appFundingRequiredMetaKey.propertyToBeSoldPrice) {
				value = this.getAmount(propertyToBeSold, 'salesPrice');
			}
			if (x?.metaKey === appFundingRequiredMetaKey.loansToBeRepaidSecurity) {
				value = this.getValue(loansRepaidList, 'loansRepaidDescription')?.map(
					(i) => `${prefix.loansRepaid} - ${i}`
				);
			}
			if (x?.metaKey === appFundingRequiredMetaKey.loansToBeRepaidAmount) {
				value = this.getAmount(loansRepaidList, 'loansRepaidValue');
			}
			if (x?.metaKey === appFundingRequiredMetaKey.propertyPurchaseLvr) {
				value = numUtil.formatToPercentNoDecimal(
					computations?.proposedLVR || 0
				);
			}
			if (x?.metaKey === appFundingRequiredMetaKey.propertyPurchaseTotalPrice) {
				value = this.formatCurrency(computations?.totalPurchasePrice);
			}
			if (x?.metaKey === appFundingRequiredMetaKey.totalSecurityLvr) {
				value = this.formatCurrency(computations?.propertyLVR);
			}
			if (x?.metaKey === appFundingRequiredMetaKey.totalSecurityScaledLvr) {
				value = this.formatCurrency(computations?.scaledLVR);
			}
			return {
				...x,
				value,
			};
		});
	}

	public static getAmount(data, paramKey: string) {
		return data?.map((x) => this.formatCurrency(x?.[paramKey])) ?? [];
	}

	public static getValue(data, paramKey: string) {
		return data?.map((x) => x?.[paramKey] || '') ?? [];
	}

	public static getTopupLabelValue(data) {
		return data?.map(
			(x) =>
				(x.topupRequirement === 'Other' ? x.other : x.topupRequirement) || ''
		);
	}

	public static getTotalValueOnMT(mt: MergeTagState[], metaKey: string) {
		const data = mt?.find((i) => i?.metaKey === metaKey);
		if (data?.value?.length > 0) {
			return +(data?.value[0]?.replace('$', '') ?? 0);
		}
		return 0;
	}

	public static getSecurityValues(value) {
		const security = R.complement(R.either(R.isNil, R.isEmpty))(value)
			? value
			: null;
		return security && util.tryCatchParse(security) ? JSON.parse(security) : [];
	}

	public static getRefinanceList(
		refinance: LoanRefinanceState[],
		propertyAddress: PropertyAddressState[],
		ffLiabilities: LiabilityState[]
	) {
		const list = refinance?.filter((x) => x?.isRefinance) || [];

		return list?.map((x) => {
			if (x?.sourceType === 'L') {
				// Refinance is from Fact Find > Liabilities
				const data = ffLiabilities?.find((i) => i?.cRTId === x?.linkedCRTId);
				return {
					...x,
					refinanceDescription: data?.liability || '',
					refinanceValue: x?.loanValue,
				};
			} else {
				// Refinance is from Fact Find > Mortgage OR from Application > Funding Required
				const data = this.getSecurityValues(x?.security);
				const securities =
					data?.reduce((a, c) => {
						const address =
							propertyAddress?.find((p) => +p?.customerServiceID === +c)
								?.propertyAddress || '';
						return [...a, address];
					}, []) || [];
				return {
					...x,
					refinanceDescription: securities?.join(' & '),
					refinanceValue: x?.loanValue,
				};
			}
		});
	}

	public static getLoansRepaidList(
		loansRepaid: FundingRequiredLoanState[] | any[],
		aLAssets: AssetState[] | any[],
		propertyAddress: PropertyAddressState[]
	) {
		const list = loansRepaid?.filter((x) => x?.isTicked) || [];

		return list?.map((x) => {
			if (x?.sourceType === 'M') {
				// Loans to be Repaid is from Fact Find > Mortgage
				const security = x?.security
					? propertyAddress?.find((a) => +a?.customerServiceID === +x?.security)
							?.propertyAddress
					: null;
				return {
					...x,
					loansRepaidDescription: security || '',
					loansRepaidValue: x?.loanBalance,
				};
			} else {
				// Loans to be Repaid is from Fact Find > Liabilities OR from Application > Funding Required
				const security = x?.security
					? aLAssets?.find((a: any) => +a?.cRTId === +x?.security)?.asset
					: null;
				return {
					...x,
					loansRepaidDescription: security || '',
					loansRepaidValue: x?.loanBalance,
				};
			}
		});
	}
}
