import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import {
  DocumentModelState,
  DocumentUploadState,
} from '../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../util/util';
import { ApiService } from '../../../../../core/base/api.service';
import { KiwiSaverSettingsQuery } from '../../../state/kiwisaver-settings.query'
import { KiwiSaverSettingsStore } from '../../../state/kiwisaver-settings.store'
import { AdviceSummaryEmailSettingsState } from './advice-summary-email-settings.model';
import { SettingsTypes } from '../../../state/kiwisaver-settings.model';

@Injectable()
export class AdviceSummaryEmailSettingsService {
  adviceSummaryEmailSettings$ = this.query.adviceSummaryEmailSettings$;

  constructor(
    private api: ApiService,
    private store: KiwiSaverSettingsStore,
    private query: KiwiSaverSettingsQuery
  ) {}

  getEmailSettings(data: number, settingsCode: SettingsTypes): any {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<AdviceSummaryEmailSettingsState>(endpoint).pipe(
      tap((x) => {
        applyTransaction(() => {
          const state = x ? objectUtil.mapPascalCaseToCamelCase(x) : {};
          this.store.setAdviceSummaryEmailSettings(state);
        });
      }),
      catchError(() => of({}))
    );
  }

  updateSettings(data: AdviceSummaryEmailSettingsState) {
    const newValue = {
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<AdviceSummaryEmailSettingsState>(endpoint, body).pipe(
      tap(() => applyTransaction(() => this.store.setAdviceSummaryEmailSettings(newValue))),
      take(1)
    );
  }

  newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
