import {
	Component,
	OnInit,
	OnDestroy,
	NgZone,
	HostListener,
	ElementRef,
} from '@angular/core';
import { UserQuery } from '../../domain/user/user.query';
import { ConfigService } from '../../core/config/config.service';
import { RouteService } from '../../core/config/route.service';
import { map, takeUntil, take, filter, tap, mergeMap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, of, combineLatest } from 'rxjs';
import { BusinessConfigQuery } from '../../domain/business-config/business-config.query';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { DropdownValueQuery } from '../../domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';
import { BLStaffsQuery } from '../../domain/bl-staff/bl-staffs.query';
import { ActivityModalComponent } from '../../shared/modal/activity/activity-modal/activity-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivityViewModel } from '../../shared/models/_general/activity.viewmodel';
import { ActivityService } from './activity/states/activity.service';
import { LocalService } from 'src/app/core/services/local.service';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { AnnouncementPopupService } from '@modules/annoucements/shared/announcement-popup/announcement-popup.service';
import { environment as env } from '@environment';

const defaultLogoUrl = `${env.defaultImgUrl}/logo.png`;

@Component({
	selector: 'app-crm',
	templateUrl: './crm.component.html',
	styleUrls: ['./crm.component.scss'],
})
export class CrmComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	private routeChanges$ = this.routeService.RouteChange$;
	private isTapLevelView$ = this.configService.IsViewTapLevel;
	public hasPopover$ = this.userQuery.hasPopover$;
	public profilePic$ = this.userQuery.profilePic$;
	public name$ = this.userQuery.userInfo$.pipe(
		takeUntil(this.onDestroy$),
		map((x) => x.FirstName + ' ' + x.LastName)
	);
	public googleImageUrl$ = of(
		this.authService.user ? this.authService.user.picture : ''
	);
	private popoverIsShownSubj = new BehaviorSubject(false);

	public popoverIsShown$ = this.popoverIsShownSubj.asObservable();
	private orderedServiceCodes$: Observable<string[]> = combineLatest([
		this.businessConfigQuery.businessServices$,
		this.userQuery.services$,
	]).pipe(
		map(([bServices, uServices]) => {
			const userServices = (uServices as string[]).filter((us) =>
				bServices.some((bs) => bs === us)
			);
			return bServices.filter((x) => userServices.includes(x));
		}),
		takeUntil(this.onDestroy$)
	);
	private unorderedServiceLabelLinks$: Observable<LabelLinkPair[]> =
		this.routeChanges$.pipe(
			map((routes) => [
				new LabelLinkPair(
					ServicesCodes.LR,
					'L&R Insurance Pipeline',
					routes.lrPipeline
				),
				new LabelLinkPair(
					ServicesCodes.Mortgage,
					'Mortgage Pipeline',
					routes.mPipeline
				),
				new LabelLinkPair(
					ServicesCodes.FG,
					'F&G Insurance Pipeline',
					routes.fgPipeline
				),
				new LabelLinkPair(
					ServicesCodes.KiwiSaver,
					'KiwiSaver Pipeline',
					routes.kPipeline
				),
				new LabelLinkPair(
					ServicesCodes.Investment,
					'Investment Pipeline',
					routes.iPipeline
				),
				new LabelLinkPair(
					ServicesCodes.AdviceProcess,
					'Advice Process Pipeline',
					routes.apPipeline
				),
			])
		);
	public orderedServiceLabelLinkModel$: Observable<LabelLinkPair[]> =
		combineLatest([
			this.orderedServiceCodes$,
			this.unorderedServiceLabelLinks$,
		]).pipe(
			map(([codes, linkLabels]) =>
				sortLinkAndLabelAccordingToCode(linkLabels, codes)
			)
		);

	public fullName$ = this.userQuery.userInfo$.pipe(
		map((x) => x.FirstName + ' ' + x.LastName)
	);
	public dashboardLink$ = this.routeChanges$.pipe(map((x) => x.dashboard));
	public logoUrl$ = combineLatest([
		this.businessConfigQuery.businessLogoUrl$,
		this.isTapLevelView$,
		of(defaultLogoUrl),
	]).pipe(
		map(([url, isTapView, defaultUrl]) => {
			if (isTapView) {
				return defaultUrl;
			} else {
				return url;
			}
		})
	);
	public customerSearchLink$ = this.routeChanges$.pipe(
		map((x) => x.customerSearch)
	);
	public customerAddLink$ = this.routeChanges$.pipe(
		takeUntil(this.onDestroy$),
		map((x) => x.customerAdd)
	);
	public businessSearchLink$ = this.routeChanges$.pipe(
		map((x) => x.businessSearch)
	);
	public businessAddLink$ = this.routeChanges$.pipe(map((x) => x.businessAdd));
	public apPipelineLink$ = this.routeChanges$.pipe(map((x) => x.apPipeline));
	public activityLink$ = this.routeChanges$.pipe(map((x) => x.activity('')));
	public documentLink$ = this.routeChanges$.pipe(map((x) => x.document));
	public reportLink$ = this.routeChanges$.pipe(map((x) => x.report));

	public leadSearchLink$ = this.routeChanges$.pipe(map((x) => x.leadSearch));
	public leadClientAddLink$ = this.routeChanges$.pipe(
		map((x) => x.leadClientAdd)
	);
	public leadBusinessAddLink$ = this.routeChanges$.pipe(
		map((x) => x.leadBusinessAdd)
	);
	menuReworkFeature$ = this.businessConfigQuery.menuReworkFeature$;

	breadcrumb: string;

	// Todo: remove this
	navIsShown: boolean;
	AT$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('AT');
	AM$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('AM');
	/** Adviser choices */
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	/** Adviser calendar choices */
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;
	constructor(
		private userQuery: UserQuery,
		private configService: ConfigService,
		private routeService: RouteService,
		private businessConfigQuery: BusinessConfigQuery,
		private route: ActivatedRoute,
		private router: Router,
		private ngZone: NgZone,
		private authService: AuthenticationService,
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private modalService: BsModalService,
		private activityService: ActivityService,
		private localService: LocalService,
		private el: ElementRef,
		private announcementPopupService: AnnouncementPopupService
	) {}

	@HostListener('document:click', ['$event'])
	onClick(event) {
		if (!this.el.nativeElement.querySelector('header').contains(event.target)) {
			this.popoverIsShown$
				.pipe(
					take(1),
					map((x) => false)
				)
				.subscribe((x) => this.popoverIsShownSubj.next(false));
		}
	}

	ngOnInit() {
		this.userQuery
			.select()
			.pipe(
				take(1),
				mergeMap(({ StaffID: staffId }) => {
					return staffId
						? this.announcementPopupService.show(staffId)
						: of(true);
				})
			)
			.subscribe();

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.findBreadcrumb()),
				takeUntil(this.onDestroy$)
			)
			.subscribe((breadcrumb: string | null) => {
				this.breadcrumb = breadcrumb;
			});
		this.breadcrumb = this.findBreadcrumb(); // Initialize value

	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	navigateRoute(link) {
		return this.ngZone.run(() => link);
	}

	/**
	 * Get breadcrumb from route data
	 * @return string | null
	 */
	findBreadcrumb(): string | null {
		let child = this.route.firstChild;
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else if (child.snapshot.data && child.snapshot.data.breadcrumb) {
				if (
					child.parent &&
					child.parent.snapshot.data &&
					child.parent.snapshot.data.breadcrumb &&
					child.parent.snapshot.data.ignoreChildBreadcrumb
				) {
					return child.parent.snapshot.data.ignoreChildBreadcrumb
						? child.parent.snapshot.data.breadcrumb
						: child.snapshot.data.breadcrumb;
				}
				return child.snapshot.data.breadcrumb;
			} else {
				return null;
			}
		}
		return null;
	}

	createActivityModal() {
		const initState: any = {
			formItem: {},
			AT$: this.AT$,
			AM$: this.AM$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: false,
			savefn: this.saveActivityFn,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: ['FCOA'],
		};
		this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft'
					? 'modal-dialog-outlook-xl'
					: 'modal-xl'
			}`,
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveActivityFn = (ac: ActivityViewModel) =>
		this.activityService.addActivityFromOtherPage(
			ActivityViewModel.MapToAdd(ac)
		);

	public togglePopover = () =>
		this.popoverIsShown$
			.pipe(
				take(1),
				map((x) => !x)
			)
			.subscribe((x) => this.popoverIsShownSubj.next(x));
}
function sortLinkAndLabelAccordingToCode(
	labelLinks: LabelLinkPair[],
	code: string[]
): LabelLinkPair[] {
	return code.map((x) => getLabelLink(labelLinks, x));
}
function getLabelLink(
	labelLinks: LabelLinkPair[],
	code: string
): LabelLinkPair {
	return labelLinks.find(
		(x) => (x.code || '').toUpperCase() === (code || '').toUpperCase()
	);
}
class LabelLinkPair {
	constructor(public code: string, public label: string, public link: string) {}
}
