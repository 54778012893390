import { ScopeOfServiceState } from './scope-of-service.model';
import { util } from '../../../../../../util/util';
import { SettingsTypes } from '../../../../../mortgage-settings/state/mortgage-settings.model';
import { AdviceProcessSectionCodes } from '../../../../../../shared/models/advice-process/advice-process.model';

export class ScopeOfServiceMapper {
  public static mapToView(data: ScopeOfServiceState) {
    return {
      referenceId: data?.referenceId || 0,
      whatWeWillTalkAboutToday: this.parseData(data?.whatWeWillTalkAboutToday) || '',
      whatWeWontTalkAboutToday: this.parseData(data?.whatWeWontTalkAboutToday) || '',
      lifeAndRiskReview: data?.lifeAndRiskReview || false,
      lifeAndRiskReviewDetails:
        this.parseData(data?.lifeAndRiskReviewDetails) || '',
      houseCarAndContentsInsurance: data?.houseCarAndContentsInsurance || false,
      houseCarAndContentsInsuranceDetails:
        this.parseData(data?.houseCarAndContentsInsuranceDetails) || '',
      residentialMortgageOrLending: data?.residentialMortgageOrLending || false,
      residentialMortgageOrLendingDetails:
        this.parseData(data?.residentialMortgageOrLendingDetails) || '',
      otherAdvice: this.parseData(data?.otherAdvice) || '',
      otherAdviceNotes: this.parseData(data?.otherAdviceNotes) || '',
      type: SettingsTypes.SOS,
    };
  }

  public static mapCheckboxes(
    data: ScopeOfServiceState,
    settings: ScopeOfServiceState
  ) {
    if (!data?.cRTId) {
      // CRT Page > SOS checkbox status:
      // 0 - (Ticked on CRT Settings) Unticked on CRT Page
      // 1 - (Ticked on CRT Settings) Ticked on CRT Page
      // 2 - (Unticked on CRT Settings) Don't show on CRT Page
      return {
				// Other Advice
        lifeAndRiskReview: settings?.lifeAndRiskReview ? 0 : 2,
        houseCarAndContentsInsurance: settings?.houseCarAndContentsInsurance ? 0 : 2,
        residentialMortgageOrLending: settings?.residentialMortgageOrLending ? 0 : 2,
				otherAdvice: settings?.other ? 0 : 2
      };
    }
    return data;
  }

  public static mapToViewCrt(
    data: ScopeOfServiceState,
    settings: ScopeOfServiceState
  ) {
    return {
      cRTId: data?.cRTId,
      adviceProcessId: data?.adviceProcessId,
      sectionCode: AdviceProcessSectionCodes.SOS,
      ...this.mapCheckboxes(data, settings),
      whatWeWillTalkAboutToday: this.parseData(data?.whatWeWillTalkAboutToday) ?? this.parseData(settings?.whatWeWillTalkAboutToday),
      whatWeWontTalkAboutToday: this.parseData(data?.whatWeWontTalkAboutToday) ?? this.parseData(settings?.whatWeWontTalkAboutToday),
      lifeAndRiskReviewDetails: this.parseData(data?.lifeAndRiskReviewDetails) ?? this.parseData(settings?.lifeAndRiskReviewDetails),
      houseCarAndContentsInsuranceDetails: this.parseData(data?.houseCarAndContentsInsuranceDetails) ?? this.parseData(settings?.houseCarAndContentsInsuranceDetails),
      residentialMortgageOrLendingDetails: this.parseData(data?.residentialMortgageOrLendingDetails) ?? this.parseData(settings?.residentialMortgageOrLendingDetails),
      otherAdviceNotes: this.parseData(data?.otherAdviceNotes) ?? this.parseData(settings?.otherAdviceNotes),
    };
  }

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }

  public static mapToUpsertSosSettings(data: ScopeOfServiceState) {
    return {
      ...data,
      whatWeWillTalkAboutToday: !!data.whatWeWillTalkAboutToday ? JSON.stringify(data.whatWeWillTalkAboutToday) : '',
      whatWeWontTalkAboutToday: !!data.whatWeWontTalkAboutToday ? JSON.stringify(data.whatWeWontTalkAboutToday) : '',
      lifeAndRiskReviewDetails: !!data.lifeAndRiskReviewDetails
        ? JSON.stringify(data.lifeAndRiskReviewDetails)
        : '',
      kiwiresidentialMortgageOrLendingDetailsaverAdviceDetails: !!data.residentialMortgageOrLendingDetails
        ? JSON.stringify(data.residentialMortgageOrLendingDetails)
        : '',
      houseCarAndContentsInsuranceDetails: !!data.houseCarAndContentsInsuranceDetails
        ? JSON.stringify(data.houseCarAndContentsInsuranceDetails)
        : '',
      otherAdviceNotes: !!data.otherAdviceNotes
        ? JSON.stringify(data.otherAdviceNotes)
        : '',
      type: SettingsTypes.SOS,
    };
  }
}
