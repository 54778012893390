import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnInit,
	Output,
	Renderer2,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { split, isNil } from 'ramda';
import { combineLatest, iif, Observable, Observer, of } from 'rxjs';
import {
	catchError,
	concatMap,
	debounceTime,
	filter,
	finalize,
	map,
	mergeMap,
	startWith,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	AdviceProcessDocumentField,
	AdviceProcessPageCodes,
	ServiceAdviceProcessState,
	SOASubSectionCodes,
} from '../../../../../shared/models/advice-process/advice-process.model';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import {
	getContentWithMergeTags,
	removeCustomNotes,
	removeHTMLContentWhiteSpaces,
	removeMtWrappers,
	removeSoaDivs,
} from '../../../../../shared/converter/content-merge-tags';
import { ConfirmModalComponent } from '../../../../../shared/modal/confirm-modal/confirm-modal.component';
import { PrimaryClientState } from '../../../../../shared/models/client-profile/primary-client/primary-client.model';
import { MergeTagState } from '../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { StatementOfAdviceState } from '../../../../../shared/models/client-review-template/statement-of-advice/statement-of-advice.model';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { objectUtil } from '../../../../../util/util';
import { AssetsLiabilitiesService } from '../../states/assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';
import { DisclosureService } from '../../states/disclosure/disclosure.service';
import { LoatDocumentService } from '../../states/document/loat-document.service';
import { CrtMergeTagsService } from '../../states/merge-tags/crt-merge-tags.service';
import { ScopeOfServiceService } from '../../states/scope-of-service/scope-of-service.service';
import { StatementOfAdviceQuery } from '../../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../../states/statement-of-advice/statement-of-advice.store';
import { BusinessConfigService } from '../../../../../domain/business-config/business-config.service';
import { CurrentInsuranceService } from '../../states/current-insurance/current-insurance.service';
import { CrtDocumentService } from '../../../crt-page/_shared/service/crt-document.service';
import {
	Fields,
	getAlreadyExists,
	logMessage,
} from 'src/app/shared/error-message/error-message';
import { DisclosureState } from 'src/app/shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { PropertyAssetCustomerService } from 'src/app/shared/models/services/property-asset/property-asset';
import { ScopeOfServiceState } from 'src/app/shared/models/client-review-template/scope-of-service/scope-of-service.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';

@Component({
	selector: 'app-soa-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss'],
})
export class StatementOfAdviceIndexComponent implements OnInit {
	@Input() otherMergeTags$: Observable<MergeTagState[]>;
	@Output() changeView = new EventEmitter();
	@Output() resetView = new EventEmitter();

	public bsModalRef: BsModalRef;

	isLoading$: Observable<boolean> = this.soaQuery.isLoading$;
	isSavingSOA$: Observable<boolean> = this.soaQuery.isSavingSOA$;
	statementOfAdviceCount$: Observable<number> = this.soaQuery.soaCount$;
	hasIncomplete$: Observable<boolean> = this.soaQuery.hasIncomplete$;

	statementOfAdvice$: Observable<StatementOfAdviceState[]> = this.soaQuery.soa$;
	incompleteSoa$: Observable<StatementOfAdviceState[]> =
		this.soaQuery.incompleteSOA$;
	completeSoa$: Observable<StatementOfAdviceState[]> =
		this.soaQuery.completedSOA$;
	completedSoa$: Observable<StatementOfAdviceState[]> =
		this.soaQuery.finalizedSOA$;
	soaCompletedAndFinalized$ = this.soaQuery.soaCompletedAndFinalized$.pipe(
		map((x) =>
			x
				?.map((soa) => ({
					...soa,
					isDeleting: false,
					isDownloading: false,
					isFinalizing: false,
					isSaving: false,
					isRenaming: false,
				}))
				.sort((a, b) => (b.createDateTime > a.createDateTime ? 1 : -1))
		)
	);

	customerId$: Observable<number> = this.crtService.primaryClient$.pipe(
		map((x: PrimaryClientState) => x.customerID)
	);
	disclosureDocument$: Observable<DisclosureState> =
		this.disclosureService.disclosureDocument$;
	policyOwners$: Observable<ViewDisplayValue[]> =
		this.query.policyOwnersWithCRT$;
	security$: Observable<PropertyAssetCustomerService[]> =
		this.aLService.propertyAddresses$;

	// dropdowns
	APCRTLTCR$: Observable<ViewDisplayValue[]> = this.crtService.APCRTLTCR$;
	APCRTE$: Observable<ViewDisplayValue[]> = this.crtService.APCRTE$;
	APCRTESI$: Observable<ViewDisplayValue[]> = this.crtService.APCRTESI$;
	APCRTIID$: Observable<ViewDisplayValue[]> = this.crtService.APCRTIID$;

	adviceProcess: ServiceAdviceProcessState =
		this.query.getValue().adviceProcess;
	document;

	isNew: boolean = false;
	currentTemplate: string;
	templateId: number;
	rawContent: string;
	mergeTag: MergeTagState[];
	mergeTagValues: object;
	soaDefault = {
		adviceProcessId: this.query.getValue().adviceProcessId,
		sectionCode: 'SOA',
		currentPage: SOASubSectionCodes.ProposedInsurance,
		document: null,
		paymentFrequency:
			this.dropdownValueQuery
				.getAll({ filterBy: (x) => x.DropdownCode === 'APCRTF' })
				.find((x) => !!x.IsDefault)?.DropdownValue ?? '',
	} as StatementOfAdviceState;

	sosDefault$: Observable<ScopeOfServiceState> = this.sosService.sosDefault$;
	sosCrt$: Observable<ScopeOfServiceState> = this.sosService.scopeOfService$;

	renamingSOA: number;
	form: UntypedFormGroup;
	isMergeTagLoading: boolean;

	constructor(
		private modalService: BsModalService,
		private crtService: ClientReviewTemplateService,
		private query: ClientReviewTemplateQuery,
		private soaService: StatementOfAdviceService,
		private soaQuery: StatementOfAdviceQuery,
		private soaStore: StatementOfAdviceStore,
		private disclosureService: DisclosureService,
		private aLService: AssetsLiabilitiesService,
		private loggerService: LoggerService,
		private zone: NgZone,
		private loatDocumentService: LoatDocumentService,
		private dropdownValueQuery: DropdownValueQuery,
		private fb: UntypedFormBuilder,
		private mtService: CrtMergeTagsService,
		private sosService: ScopeOfServiceService,
		private renderer: Renderer2,
		private businessService: BusinessConfigService,
		private ciService: CurrentInsuranceService,
		private crtDocService: CrtDocumentService
	) {
		this.form = this.fb.group({ soaName: ['', Validators.required] });
	}

	get SoaName() {
		return this.form.get('soaName');
	}

	ngOnInit(): void {
		this.form.reset();

		this.isMergeTagLoading = true;
		combineLatest([
			this.sosCrt$.pipe(startWith(null as any)),
			this.sosDefault$.pipe(startWith(null as any)),
			this.otherMergeTags$.pipe(startWith(null as any)),
		])
			.pipe(
				filter(
					([sosCrt, sosDefault, otherMergeTags]) =>
						!!sosCrt && !!sosDefault && !!otherMergeTags
				),
				tap(() => (this.isMergeTagLoading = false)),
				take(1)
			)
			.subscribe();
	}

	createNewSoa() {
		this.soaService
			.getSoa()
			.pipe(
				map((data) => {
					const incomplete = data?.filter((x) => !x?.document?.referenceId);
					if (incomplete?.length > 0) {
						this.refreshModal();
						return false;
					}
					return true;
				}),
				filter((x) => !!x),
				concatMap(() => this.soaService.addSoa(this.soaDefault)),
				tap((value) => {
					this.changeView.emit(value);
					this.soaStore.setActive(value);
				}),
				take(1)
			)
			.subscribe();
	}

	viewSoa(value: number) {
		const goToView = (cRTId: number) => {
			this.soaService
				.getDefaultSoaSettings(0, 'SOA')
				.pipe(
					finalize(() => {
						this.changeView.emit(cRTId);
						this.soaStore.setActive(cRTId);
					}),
					take(1)
				)
				.subscribe();
		};
		this.checkSoaForUpdate(value, goToView);
	}

	editSoa(value: number) {
		this.soaService
			.getDefaultSoaSettings(0, 'SOA')
			.pipe(
				finalize(() => {
					this.changeView.emit(value);
					this.soaStore.setActive(value);
				}),
				take(1)
			)
			.subscribe();
	}

	resetSoa(value: number) {
		const resetModal = (cRTId: number) => {
			const confirm = new Observable((obs: Observer<any>) => {
				this.reset(cRTId);
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: 'Reset Confirmation',
				message: `Confirm reset of Statement of Advice?`,
				confirm$: confirm,
				decline$: decline,
			};

			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
			});
		};
		this.checkSoaForUpdate(value, resetModal);
	}

	reset(currentSoa: number) {
		this.soaStore.setSOALoading(true, 'SOA');
		this.soaStore.setSOASaving(true, 'SOA');
		this.soaService
			.deleteSoa(currentSoa)
			.pipe(
				concatMap(() => this.refetchSoaSettingsAndMergeTags()),
				concatMap(() => this.soaService.addSoa(this.soaDefault)),
				tap((x) => {
					this.changeView.emit(x);
					this.soaStore.setActive(x);
				}),
				finalize(() => {
					this.soaStore.setSOALoading(false, 'SOA');
					this.soaStore.setSOASaving(false, 'SOA');
				}),
				take(1)
			)
			.subscribe();
	}

	// tslint:disable-next-line: ban-types
	checkSoaForUpdate(cRTId: number, callback?: Function) {
		this.soaService
			.getSoaByCRT(cRTId)
			.pipe(
				withLatestFrom(this.soaQuery.selectEntity(cRTId)),
				tap(([refetchedData, currentData]) => {
					if (
						!!refetchedData?.document?.referenceId ||
						currentData?.currentPage !== refetchedData?.currentPage
					) {
						// If there are changes on the SOA; Refresh SOA list
						this.refreshModal();
					} else {
						if (typeof callback === 'function') {
							callback(cRTId);
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}

	refreshModal() {
		const confirm = new Observable((obs) => {
			this.refreshSoaList();
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.refreshSoaList();
			obs.complete();
		});

		const initState = {
			header: 'SOA update',
			message: Fields.SOAupdated,
			confirm$: confirm,
			decline$: decline,
			isOkBtn: true,
			isAcceptBtn: false,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	refreshSoaList() {
		this.soaStore.setSOASaving(true, 'SOA');
		this.soaStore.setSOALoading(true, 'SOA');
		this.soaService
			.getSoa()
			.pipe(
				finalize(() => {
					this.resetSOAList();
					this.soaStore.setSOALoading(false, 'SOA');
					this.soaStore.setSOASaving(false, 'SOA');
					this.soaService.setHasSoaChanges(false);
					this.soaService.setTriggerLeaveSoa(null);
				}),
				take(1)
			)
			.subscribe();
	}

	refetchSoaSettingsAndMergeTags = () =>
		this.mtService.getDefaultMergeTags(true).pipe(
			concatMap(() => this.businessService.get(true)),
			concatMap(() => this.mtService.getAdviserProviderMt()),
			concatMap(() =>
				this.mtService.getFactFindMt(+this.query.getValue().adviceProcessId)
			),
			concatMap(() =>
				this.mtService.getRiskAnalysisMt(+this.query.getValue().adviceProcessId)
			),
			concatMap(() =>
				this.mtService.getSosMt(+this.query.getValue().adviceProcessId)
			),
			concatMap(() => this.soaService.getDefaultSoaSettings(0, 'SOA')),
			concatMap(() =>
				this.ciService.getCurrentInsurance(
					+this.query.getValue().adviceProcessId,
					'FCL'
				)
			),
			take(1)
		);

	resetSOAList() {
		this.soaCompletedAndFinalized$ = this.soaCompletedAndFinalized$.pipe(
			map((x) =>
				x?.map((soa) => ({
					...soa,
					isDeleting: false,
					isDownloading: false,
					isFinalizing: false,
					isSaving: false,
					isRenaming: false,
				}))
			)
		);
	}

	removeSoa(soa: StatementOfAdviceState) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.updateSoaFlag({ isSaving: true }, soa);
			this.soaService
				.deleteSoa(soa?.cRTId)
				.pipe(
					take(1),
					finalize(() => {
						this.resetSOAList();
					})
				)
				.subscribe();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.updateSoaFlag({ isDeleting: false }, soa);
			obs.complete();
		});

		const initState = {
			header: 'Delete Confirmation',
			message: `Are you sure you want to delete this ${
				soa.name ?? 'Statement of Advice'
			}?`,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	/**
	 * Advice Process update document
	 * @param id DocumentID
	 * @param field Document in Advice Process CRM
	 * @returns Observable<string>
	 */
	updateAdviceProcess(
		id: number,
		field: string = AdviceProcessDocumentField.SOA
	) {
		const adviceProcess = this.query.getValue().adviceProcess;
		const lradvice = {
			...adviceProcess,
			documents: adviceProcess.documents?.map((doc) => {
				return {
					...doc,
					value:
						doc.field.toLowerCase() === field.toLowerCase()
							? id
							: doc.value?.documentID ?? null,
				};
			}),
		};
		return this.soaService.updateLRAdviceProcess(lradvice).pipe(take(1));
	}

	saveDocument(soa: StatementOfAdviceState) {
		const soaDocument = this.adviceProcess.documents.find(
			(d) => d.field === AdviceProcessDocumentField.SOA
		)?.value;
		if (!soaDocument?.documentID) {
			this.saveNewDocument(soa).pipe(take(1)).subscribe();
		} else {
			this.saveExistingDocument(soa);
		}
	}

	saveNewDocument(soa: StatementOfAdviceState) {
		this.updateSoaFlag({ isSaving: true }, soa);

		return this.soaService.getTemplateContent(soa?.document?.referenceId).pipe(
			switchMap((x) => this.cleanContentForDownload(x)),
			debounceTime(100),
			map((x) => `<div class="soa-pdf-file">${x}</div>`),
			concatMap((x) =>
				this.soaService.convertSoaTemplateToBase64Pdf(x, soa.name)
			),
			map((currentTemplate) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: currentTemplate,
				FileName: `${soa.name}.pdf`,
				DocumentType: ServicesCodes.LR,
				Type: AdviceProcessPageCodes.SOA,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			concatMap((x) =>
				this.loatDocumentService.updateAdviceProcess(
					x,
					AdviceProcessPageCodes.SOA
				)
			),
			tap((x) =>
				this.zone.run(() =>
					this.loggerService.Success({}, logMessage.oat.lr.soa.success.save)
				)
			),
			catchError((err) => {
				this.zone.run(() =>
					this.loggerService.Log(
						err,
						err?.error?.Message || logMessage.shared.general.error
					)
				);
				return of(err);
			}),
			finalize(() => {
				this.updateSoaFlag({ isSaving: false }, soa);
			})
		);
	}

	saveExistingDocument(
		soa: StatementOfAdviceState,
		isToFinalize: boolean = false
	) {
		const document = this.adviceProcess.documents.find(
			(d) => d.field === AdviceProcessDocumentField.SOA
		)?.value;

		const confirm = new Observable((obs) => {
			this.saveNewDocument(soa)
				.pipe(
					concatMap((x) =>
						iif(() => isToFinalize, this.finalizeSOA(soa), of(x))
					),
					take(1)
				)
				.subscribe();
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (isToFinalize) {
				this.updateStatus(soa, { isFinalizing: false, isSaving: false })
					.pipe(take(1))
					.subscribe();
			}
			obs.complete();
		});

		const initState = {
			header: '',
			message: 'A document is already uploaded for Statement of Advice,',
			subMessage: document?.fileName,
			secondaryMessage: `Do you want to replace this?`,
			confirm$: confirm,
			decline$: decline,
			isAcceptBtn: true,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	downloadPDF(soa: StatementOfAdviceState) {
		if (!soa || (!!soa && !soa?.document?.referenceId)) {
			return;
		}

		const getTemplateContent = (docId?: number, isNew?: boolean) =>
			of(docId).pipe(
				tap((id) => {
					this.templateId = isNew ? 0 : +id;
					this.isNew = isNew;
				}),
				mergeMap((id) => this.soaService.getSoaDocumentFile(+id)),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.soaService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => (this.rawContent = data))
			);

		const setMergeTags = (mergeTags: MergeTagState[]) =>
			of(mergeTags)
				.pipe(
					tap((x) => (this.mergeTag = x)),
					take(1)
				)
				.subscribe();

		const previewContent = () =>
			of(true).pipe(
				withLatestFrom([this.mergeTag], [this.rawContent]),
				map(([_, mergeTag, template]) =>
					this.isNew ? getContentWithMergeTags(template, mergeTag) : template
				),
				take(1)
			);

		this.soaQuery
			.selectEntity(+soa?.cRTId)
			.pipe(
				filter((data) => !!data),
				map((data: StatementOfAdviceState) => ({
					documentId: data?.document?.referenceId,
					isCompleted: data?.currentPage === 'Completed',
				})),
				withLatestFrom(this.soaService.soaSettings$),
				tap(([, settings]) => setMergeTags(settings?.mergeTag)),
				switchMap(([data, settings]) =>
					iif(
						() => isNil(data?.documentId),
						getTemplateContent(+settings?.template, true),
						getTemplateContent(+data?.documentId, false)
					)
				),
				concatMap(() => previewContent()),
				switchMap((x) => this.cleanContentForDownload(x)),
				map((x) => `<div class="soa-pdf-file">${x}</div>`),
				withLatestFrom(this.soaQuery.selectEntity(+soa?.cRTId)),
				mergeMap(([x, data]) =>
					this.renderPdfDownload(x, {
						...this.soaService.getSoaPdfOptions(),
						FileName: data?.name || 'STATEMENT-OF-ADVICE',
					})
				),
				debounceTime(100),
				take(1),
				finalize(() => this.resetSOAList())
			)
			.subscribe();
	}

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, 'STATEMENT OF ADVICE', pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	cleanContentForDownload = (content = '') =>
		// Use this only when converting final contents of SOA to PDF
		of(content).pipe(
			concatMap((x) => this.updateSoaSosMt(x)),
			concatMap((x) => this.updateSoaSosPages(x)),
			map((x) => removeSoaDivs(x)),
			map((x) => removeCustomNotes(x)),
			map((x) => removeMtWrappers(x)),
			map((x) => removeHTMLContentWhiteSpaces(x, true)),
			take(1)
		);

	updateSoaSosMt = (content = '') =>
		of(true).pipe(
			withLatestFrom(
				this.mtService.mergeTags$,
				this.sosService.scopeOfService$,
				this.sosService.sosDefault$
			),
			switchMap(([, mergeTags, sosCrt, sosDefault]) =>
				this.soaService.updateSosMt(content, mergeTags, sosCrt, sosDefault)
			),
			take(1)
		);

	updateSoaSosPages = (content = '') =>
		of(true).pipe(
			withLatestFrom(
				this.sosService.scopeOfService$,
				this.sosService.sosDefault$
			),
			switchMap(([, sosCrt, sosDefault]) =>
				this.soaService.updateSosPages(content, sosCrt, sosDefault)
			),
			take(1)
		);

	updateSoaFlag(flags: {}, soa: StatementOfAdviceState = null) {
		this.soaCompletedAndFinalized$ = this.soaCompletedAndFinalized$.pipe(
			map((x) =>
				x?.map((advice) => {
					const updatedSoa = {
						...advice,
						...flags,
					};

					if (!soa || advice?.cRTId === soa?.cRTId) {
						return updatedSoa;
					}

					return advice;
				})
			)
		);
	}

	trackBySOA = (index: number, item: any) => item?.cRTId;

	finalizeSOA = (soa: StatementOfAdviceState) => {
		return this.soaService.finalize(soa.cRTId).pipe(take(1));
	};

	finalize(soa: StatementOfAdviceState) {
		const soaDocument = this.adviceProcess.documents.find(
			(d) => d.field === AdviceProcessDocumentField.SOA
		)?.value;
		if (!soaDocument?.documentID) {
			this.saveNewDocument(soa)
				.pipe(
					mergeMap(() => this.finalizeSOA(soa)),
					take(1)
				)
				.subscribe();
		} else {
			this.saveExistingDocument(soa, true);
		}
	}

	updateStatus(data: StatementOfAdviceState, props) {
		return (this.soaCompletedAndFinalized$ =
			this.soaQuery.soaCompletedAndFinalized$.pipe(
				map((x) =>
					x?.map((soa) =>
						soa?.cRTId === data?.cRTId
							? {
									...soa,
									...props,
							  }
							: soa
					)
				)
			));
	}

	cloneSOA(soa: StatementOfAdviceState) {
		this.soaService
			.cloneSOA(soa)
			.pipe(
				take(1),
				concatMap(() => this.soaService.getSoa()),
				finalize(() => {
					this.resetSOAList();
				})
			)
			.subscribe();
	}

	placeholderName(name: string) {
		// Split name based on dash
		const splitName = split('-', name)?.map((split: string) => split?.trim());
		return splitName.length > 0 ? splitName[0] : 'STATEMENT OF ADVICE';
	}

	renameSOA(cRTId: number, name: string) {
		this.renamingSOA = cRTId;
		// Split name based on dash
		const splitName = split('-', name).map((split: string) => split?.trim());
		// Edit only the next part of name based on dash
		const nameToEdit = Array.from(splitName)?.reduce(
			(a, c, i) => (i === 0 ? a : [...a, c]),
			[]
		) as string[];

		this.form.patchValue({
			soaName: splitName.length > 0 ? nameToEdit?.join(' - ') : '',
		});
	}

	cancelRename() {
		this.form.reset();
		this.renamingSOA = null;
	}

	isRenamingSOA(id: number) {
		return +this.renamingSOA === id;
	}

	saveRename(cRTId: number, name: string) {
		const soaData = this.soaQuery.getEntity(+cRTId);
		const splitName = split('-', name).map((split: string) => split?.trim());
		const formValue = this.form.getRawValue();
		const soaName = `${
			splitName.length > 0 ? splitName[0] : 'STATEMENT OF ADVICE'
		} - ${formValue?.soaName}`?.toUpperCase();

		if (
			this.soaQuery
				.getAll()
				?.some(
					(x) =>
						x?.name?.toLowerCase()?.replace(/\s/g, '') ===
							soaName?.toLowerCase()?.replace(/\s/g, '') && +cRTId !== +x.cRTId
				)
		) {
			this.loggerService.Warning({}, getAlreadyExists(`"${soaName}"`));
			return;
		}

		of(true)
			.pipe(
				mergeMap(() => this.updateStatus(soaData, { isRenaming: true })),
				concatMap(() => this.soaService.renameSoa(cRTId, soaName)),
				mergeMap(() => this.updateStatus(soaData, { isRenaming: false })),
				finalize(() => {
					this.loggerService.Success(
						{},
						logMessage.oat.lr.soa.success.update + soaName
					);
					this.form.reset();
					this.renamingSOA = null;
				}),
				take(1)
			)
			.subscribe();
	}

	showLoader(soa) {
		return (
			soa?.isDownloading ||
			soa?.isSaving ||
			soa?.isFinalizing ||
			soa?.isDeleting ||
			soa?.isRenaming ||
			this.isMergeTagLoading
		);
	}
}
