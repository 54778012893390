import {
  Directive,
  HostBinding,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import 'geocomplete';
declare var $: any;

@Directive({
  selector: '[appAddressField]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AddressFieldDirective,
      multi: true,
    },
  ],
})
export class AddressFieldDirective implements AfterViewInit {
  @HostBinding('disabled') isDisabled: boolean;
  @HostBinding('value') value = '';

  @Output() valueChange = new EventEmitter<string>();

  onChange: (value: string) => void;

  setValue = (value: string) => {
    this.value = value;
    this.renderer.setProperty(this.elRef.nativeElement, 'value', value);
  };

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const element = this.elRef.nativeElement;
    $(element)
      .geocomplete({
        country: 'NZ',
        types: ['geocode', 'establishment'],
      })
      .on('geocode:result', () => this.emitValue(element.value));

    fromEvent(element, 'input').subscribe(($event) => {
      this.emitValue($event['target'].value);
    });
  }

  private emitValue = (value: string) => {
    this.valueChange.emit(value);
    this.onChange(value);
  };
  public writeValue = (obj?: string) => {
    this.setValue(obj);
  };

  public registerOnChange = (fn: (value: string) => void) => {
    this.onChange = fn;
  };

  public registerOnTouched = () => {};
  public setDisabledState = (isDisabled: boolean) =>
    (this.isDisabled = isDisabled);
}
