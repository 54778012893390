import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PropertyAssetCustomerServiceState } from 'src/app/shared/models/services/property-asset/property-asset';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { PropertyState } from '../../../crt-mortgage/client-sop/assets-and-liabilities/state/property/property.model';

@Component({
	selector: 'app-property',
	templateUrl: './property.component.html',
})
export class PropertyComponent implements OnInit {
	private onDestroy$ = new Subject<void>();

	@Input() properties: PropertyState[];
	@Input() totalPropertyValue: number;
	@Input() policyOwners: ViewDisplayValue[];
	@Input() SU$: Observable<ViewDisplayValue[]>;
	@Input() SV1$: Observable<ViewDisplayValue[]>;
	@Input() ST$: Observable<ViewDisplayValue[]>;
	@Input() STI$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() propertyAddresses: PropertyAssetCustomerServiceState[] = [];
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	isAdd: boolean;

	action$ = new Subject<void>();

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];
	@Input() pciAddress:{PropertyAddress:string};

	constructor() {}

	ngOnInit(): void {}

	trackByFn(index: number, property: PropertyState): number {
		return property.cRTId;
	}

	add() {
		this.isAdd = true;
	}

	cancelEvent(event?) {
		this.isAdd = false;
	}

	saveEvent(event?) {
		this.isAdd = false;
	}

	checkForPciAddress(){
		if(!this.pciAddress){
			return false;
		}
		return !this.properties.some(prop => prop.propertyAddress === this.pciAddress?.PropertyAddress);
	}
}
