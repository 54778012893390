import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TlMoatSettingsQuery } from './state/tl-moat-settings.query';
import { TlMoatSettingsStore } from './state/tl-moat-settings.store';
import { TlMoatSettingsComponent } from './tl-moat-settings.component';
import { TlMoatSettingsResolver } from './tl-moat-settings.resolver';
import { ServicingCalculatorSettingsService } from './servicing-calculators/state/servicing-calculators-settings.service'

const routes: Routes = [
	{
		path: '',
		component: TlMoatSettingsComponent,
		resolve: { data: TlMoatSettingsResolver },
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
	providers: [
		TlMoatSettingsResolver,
		TlMoatSettingsStore,
		TlMoatSettingsQuery,
		ServicingCalculatorSettingsService,
	],
})
export class TlMoatSettingsRoutingModule {}
