import * as R from 'ramda';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { DefaultFileNames } from 'src/app/shared/models/documents/document.model';
import {
	DocumentSettingsTypes,
	SettingsTypes,
} from '../../../state/mortgage-settings.model';
import { SosTemplateSettingsState } from './sos-template-settings.model';

export class ScopeOfServiceTemplateSettingsMapper {
	public static mapToUpsert(data: SosTemplateSettingsState) {
		return {
			...data,
			referenceId: data.referenceId || 0,
			type: SettingsTypes.MOATSosTemplate,
		};
	}

	public static filterMergeTagsToShow(data: MergeTagState[]) {
		// Merge Tags to Show that includes this metaKey prefixes
		return (
			data?.filter(
				(x) =>
					x?.metaKey?.includes('DATE_TODAY') ||
					x?.metaKey?.includes('BUSINESS_') ||
					x?.metaKey?.includes('ADVISER_') ||
					x?.metaKey?.includes('MOAT_SOS_')
			) || []
		);
	}

	public static mapDocumentUpload(document, template) {
		if (
			template === 0 ||
			isNaN(template) ||
			R.either(R.isNil, R.isEmpty)(template)
		) {
			return {
				document,
				referenceId: 0,
				fileName: DefaultFileNames.SosTemplateSettings,
				type: DocumentSettingsTypes.SosTemplate,
			};
		}
		return {
			document,
			documentId: +template,
		};
	}
}
