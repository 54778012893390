import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
	combineLatest,
	filter,
	takeUntil,
	map,
	startWith,
} from 'rxjs/operators';
import { DateRange } from 'src/app/shared/date-range/date-range.component';
import { DashboardQuery } from '../../state/dashboard.query';
import { MeetingStatsService } from './state/msw.service';
import { MeetingStatsQuery } from './state/msw.query';
import { MeetingStats, MswActivityTypes } from './state/msw.model';
import { ComponentBase } from 'src/app/core/base/component-base';
import MomentUtil from 'src/app/util/moment.util';

@Component({
	selector: 'app-msw',
	templateUrl: './msw.component.html',
	styleUrls: ['./msw.component.scss'],
})
export class MswComponent extends ComponentBase implements OnInit, OnDestroy {
	public static widgetCode = 'msw';
	public static widgetName = 'Meeting Stats';
	public static sizeX = 500;
	public static sizeY = 430;
	public static minSizeX = 500;
	public static maxSizeX = 500;
	public static minSizeY = 430;
	public static maxSizeY = 430;

	private subs: Subscription[] = [];

	widgetCode = MswComponent.widgetCode;

	/**
	 * MSW Meeting Stats data
	 */
	data$: Observable<MeetingStats[]> = this.query.MeetingStatsData$;

	ccFirstMeeting$ = this.data$.pipe(
		map((data) =>
			data?.find((x) => x?.ActivityType === MswActivityTypes.CcFirstMeeting)
		)
	);
	selfGenFirstMeeting$ = this.data$.pipe(
		map((data) =>
			data?.find(
				(x) => x?.ActivityType === MswActivityTypes.SelfGenFirstMeeting
			)
		)
	);
	ebReviewMeeting$ = this.data$.pipe(
		map((data) =>
			data?.find((x) => x?.ActivityType === MswActivityTypes.EbReviewMeeting)
		)
	);
	total1$ = this.data$.pipe(
		map((data) =>
			data?.find((x) => x?.ActivityType === MswActivityTypes.Total1)
		)
	);
	ccSoaMeeting$ = this.data$.pipe(
		map((data) =>
			data?.find((x) => x?.ActivityType === MswActivityTypes.CcSoaMeeting)
		)
	);
	ccSelfGenSoaMeeting$ = this.data$.pipe(
		map((data) =>
			data?.find(
				(x) => x?.ActivityType === MswActivityTypes.CcSelfGenSoaMeeting
			)
		)
	);
	ebSoaMeeting$ = this.data$.pipe(
		map((data) =>
			data?.find((x) => x?.ActivityType === MswActivityTypes.EbSoaMeeting)
		)
	);
	total2$ = this.data$.pipe(
		map((data) =>
			data?.find((x) => x?.ActivityType === MswActivityTypes.Total2)
		)
	);

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.isLoading$;

	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.error$;

	/**
	 * Form control for date range
	 * Default date range is set to a fortnight on either side of today’s date
	 * 2 weeks before as min date and 2 weeks after as max date
	 */
	dateControl: UntypedFormControl = new UntypedFormControl({
		min: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().subtract(2, 'weeks')
		),
		max: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().add(2, 'weeks')
		),
	} as DateRange);

	constructor(
		private dashboardQuery: DashboardQuery,
		private query: MeetingStatsQuery,
		private service: MeetingStatsService
	) {
		super();
	}

	ngOnInit() {
		this.subs.push(
			this.dashboardQuery.adviserFilter$
				.pipe(
					filter((x) => x.length > 0),
					combineLatest(
						this.dateControl.valueChanges.pipe(
							startWith(this.dateControl.value)
						)
					),
					takeUntil(this.onDestroy$)
				)
				.subscribe(([advisers, dateRange]) => {
					this.service.GetMeetingStats({
						advisers,
						dateRangeMin: dateRange.min,
						dateRangeMax: dateRange.max,
					});
				})
		);
	}

	ngOnDestroy() {
		this.subs?.forEach((x) => x.unsubscribe);
		this.subs = [];
		super.dispose();
	}
}
