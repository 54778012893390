<div class="d-flex align-items-center">
	<input
		#date
		[matDatepicker]="picker"
		[min]="min"
		[max]="max"
		type="text"
		[class]="'form-control ' + textboxClass"
		[placeholder]="placeholder"
		aria-label=""
		aria-describedby="basic-addon2"
		[disabled]="disabled"
		id="{{ textboxId }}"
		[(ngModel)]="datafacade"
		(dateInput)="changeDate($event)"
		(dateChange)="changeDate($event)"
		[tabindex]="tabindex"
		(click)="picker.open()"
		(blur)="saveDate()"
	/>
	<ng-container *ngIf="!!isSaving || !!isSavingDate; else showCalendarIcon">
		<i class="ml-2 tap-text-primary fas fa-spinner fa-pulse"></i>
	</ng-container>
	<ng-template #showCalendarIcon>
		<button
			*ngIf="showButton"
			(click)="picker.open()"
			class="btn btn-outline-secondary border-0 p-0 pl-1"
			type="button"
			[disabled]="disabled"
		>
			<i class="material-icons align-middle py-1 px-0">date_range</i>
		</button>
	</ng-template>
	<mat-datepicker #picker>
		<ng-container *ngIf="!!useActionButtons">
			<mat-datepicker-actions>
				<button
					mat-raised-button
					type="button"
					class="mat-action-button btn btn-primary tap-bg-primary mr-3 px-4"
					[disabled]="!!isSaving || !!isSavingDate"
					matDatepickerApply
					(click)="saveDate()"
				>
					Save
				</button>
			</mat-datepicker-actions>
		</ng-container>
	</mat-datepicker>
</div>
