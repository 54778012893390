import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { DeclarationTemplateSettingsService } from './declaration-settings/declaration-template-settings/state/declaration-template-settings.service';
import { MergeTagsCrtSettingsService } from './merge-tags/state/merge-tags-crt-settings.service';
import { ScopeOfServiceService } from './scope-of-service-settings/state/scope-of-service.service';
import { SettingsTypes } from './state/mortgage-settings.model';
import { ScopeOfServiceSettingsService } from './scope-of-service-settings/state/scope-of-service-settings.service';
import { MoatIntroSettingsService } from './intro-settings/state/intro-settings.service';
import { DocumentListService } from './document-list/state/document-list.service';
import { SoaSettingsService } from './soa-settings/state/soa-settings.service';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { ReviewApplicationSettingsService } from './review-application-settings/state/review-application-settings.service';
import { DeclarationEmailSettingsService } from './declaration-settings/declaration-email-settings/state/declaration-email-settings.service';
import { ClientFactFindSettingsService } from './declaration-settings/client-fact-find-template-settings/state/client-fact-find-template-settings.service';
import { ReviewApplicationEmailSettingsService } from './review-application-settings/review-application-email-settings/state/review-application-email-settings.service';
import { SosTemplateSettingsService } from './scope-of-service-settings/sos-template-settings/state/sos-template-settings.service';
import { MoatSoaEmailSettingsService } from './soa-settings/moat-soa-email-settings/state/moat-soa-email-settings.service';
import { NoteSettingsService } from './review-application-settings/note-settings/state/note-settings.service';
import { MortgageEndProcessSettingsService } from './mortgage-end-process/state/mortgage-end-process.service';
import { RouteService } from '@core/config/route.service';
import { BusinessConfigService } from '@domain/business-config/business-config.service';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';

@Injectable()
export class MortgageSettingsResolver implements Resolve<boolean> {
	constructor(
		private sService: ScopeOfServiceService,
		private decService: DeclarationTemplateSettingsService,
		private mtService: MergeTagsCrtSettingsService,
		private sosEmailSettings: ScopeOfServiceSettingsService,
		private introSettings: MoatIntroSettingsService,
		private documentListService: DocumentListService,
		private soaService: SoaSettingsService,
		private reviewAppService: ReviewApplicationSettingsService,
		private decEmailSettingsService: DeclarationEmailSettingsService,
		private clientFactFindSettingsService: ClientFactFindSettingsService,
		private rapEmailSettingsService: ReviewApplicationEmailSettingsService,
		private sosTemplateSettingsService: SosTemplateSettingsService,
		private moatSoaEmailSettingsService: MoatSoaEmailSettingsService,
		private noteSettingsService: NoteSettingsService,
    private epService: MortgageEndProcessSettingsService,
    private router: Router,
    private routeService: RouteService,
		private bConfigService: BusinessConfigService,
		private bProfileService: BusinessProfileService
	) {}

	resolve(): Observable<boolean> {
		const companyCode = this.bConfigService.companyCode();
		const refId = 0;
		const getScopeOfService$ = this.sService.getScopeOfService(
			refId,
			SettingsTypes.SOS
		);
		const getSosTemplateSettings$ =
			this.sosTemplateSettingsService.getSettings();
		const getDeclaration$ = this.decService.getDeclarationDocument(
			refId,
			SettingsTypes.Declaration
		);
		const getDeclarationEmailSettings$ =
			this.decEmailSettingsService.getDeclarationEmailSettings(
				refId,
				SettingsTypes.MOATDeclarationEmail
			);
		const getReviewApplicationEmailSettings$ =
			this.rapEmailSettingsService.getReviewApplicationEmailSettings(
				refId,
				SettingsTypes.MOATReviewApplicationEmail
			);
		const getSoaSettings$ = this.soaService.getSoaSettings(
			refId,
			AdviceProcessSectionCodes.MOATRecordOfAdvice
		);
		const getClientFactFindSettings$ =
			this.clientFactFindSettingsService.getClientFactFindDocument(
				refId,
				SettingsTypes.MOATClientFactFind
			);
		const getStatementOfAdviceEmailSettings$ =
			this.moatSoaEmailSettingsService.getEmailSettings(
				refId,
				SettingsTypes.MOATRoaEmail
			);

		const getReviewApplication$ =
			this.reviewAppService.getReviewApplicationSetting(
				refId,
				SettingsTypes.ReviewApplication
			);

		const getNoteSettingsSettings$ = this.noteSettingsService.getNoteSettings(
			refId,
			SettingsTypes.MOATReviewNotes
		);
		// const getSoaSettings$ = this.soaService.getSoaSettings(refId, 'SOA');
		const getIntroSettings$ = this.introSettings.getIntroSettings(refId);
		const getSosEmailSettings$ = this.sosEmailSettings.getEmailSettings(refId);

		// Merge Tags
		let i = 0;
		const getMtGeneral$ = this.mtService.getMergeTags(i++, 'general', refId);
		const getMtBusiness$ = this.mtService.getMergeTags(i++, 'business', refId);
		const getMTStaff$ = this.mtService.getMergeTags(i++, 'staff', +refId);
		// Settings
		const getMTProviders$ = this.mtService.getMtSettings(i++, 'PCT');
		const getMTLrProvider$ = this.mtService.getMtSettings(i++, 'PCLR');
		const getMTLrDetails$ = this.mtService.getMtSettingDetails(i++, 'PCLR');
		const getDocumentList$ = this.documentListService.getDocumentList(
			0,
			SettingsTypes.DocumentListSettings
		);
		// Fact Find > People
		const getPeople$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.People
		);
		const getDependants$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Dependants
		);
		const getTrusts$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Trust
		);
		const getCompany$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Company
		);
		// Fact Find > A&L
		const getProperties$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Property
		);
		const getKiwiSaver$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.KiwiSaver
		);
		const getAssets$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.AssetsInvestments
		);
		const getMortages$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Mortgages
		);
		const getLiabilities$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Liabilities
		);
		const getIncomeSources$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.IncomeSource
		);
		const getPreviousIncome$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.PreviousIncome
		);
		const getRental$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.RentalIncome
		);
		const getOther$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.OtherIncome
		);
		const getLsSoaMergeTags$ = this.mtService.getLsSoaMt();
		const getSosMergeTags$ = this.mtService.getSosMt();
		const getApplicationMergeTags$ = this.mtService.getApplicationMT();
		const getApplicationReasonMergeTags$ = this.mtService.getApplicationReasonMT();
    const getEndProcessEmail$ = this.epService.getSettings(refId, AdviceProcessSectionCodes.MortgageEndProcess);

		return forkJoin([
			getIntroSettings$,
			getScopeOfService$,
			getSosTemplateSettings$,
			getDeclaration$,
			getDeclarationEmailSettings$,
			getClientFactFindSettings$,
			getSoaSettings$,
			getReviewApplication$,
			getSosEmailSettings$,
			getDocumentList$,
			getReviewApplicationEmailSettings$,
			getStatementOfAdviceEmailSettings$,
			getNoteSettingsSettings$,
			getEndProcessEmail$,

			// Merge Tags
			getMtGeneral$,
			getMtBusiness$,
			getMTProviders$,
			getMTLrProvider$,
			getMTLrDetails$,
			getMTStaff$,
			getSosMergeTags$,
			getApplicationMergeTags$,
			getApplicationReasonMergeTags$,
			getPeople$,
			getDependants$,
			getTrusts$,
			getCompany$,
			getProperties$,
			getKiwiSaver$,
			getAssets$,
			getMortages$,
			getLiabilities$,
			getIncomeSources$,
			getPreviousIncome$,
			getLsSoaMergeTags$,
			getRental$,
			getOther$
		]).pipe(
			withLatestFrom(
				this.bProfileService.hasM$,
				this.bProfileService.hasMOAT$
			),
			tap(([, hasM, hasMOAT]) => {
				if (!hasM || !hasMOAT) {
					const redirect = this.routeService.customBusiness(companyCode);
					this.router.navigate(this.routeService.splitToRoutePath(redirect));
				}
			}),
			mapTo(true)
		);
	}
}
