import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { find, propEq } from 'ramda';
import { Observable, Subject } from 'rxjs';
import { delay, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ScopeOfServiceState } from '../../../../../../shared/models/client-review-template/scope-of-service/scope-of-service.model';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { RiskProfileMapper } from '../../../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.mapper';
import { EventState, PeopleEventState } from '../../../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { RiskProfileService } from '../../../states/risk-analysis/risk-profile/risk-profile.service';

declare var $: any;

@Component({
	selector: 'app-insurance-priority',
	templateUrl: './insurance-priority.component.html',
	styleUrls: ['./insurance-priority.component.scss'],
})
export class InsurancePriorityComponent
	implements OnInit, OnDestroy, OnChanges
{
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
	showInvalid$: Observable<boolean> = new Observable<boolean>();

	@Input() isAdviceProcessEnded: boolean;

	@Input() event: EventState[];
	@Input() APCRTP$: Observable<ViewDisplayValue[]>;
	@Input() sosCRT$: Observable<ScopeOfServiceState>;
	@Input() cRTId: number;
	@Input() activePerson: PeopleState;
	@Input() peopleList: PeopleEventState[];

	people$: Observable<PeopleState[]> = this.riskProfileService.people$;
	defaultValues: EventState;

	elseMinus = true;
	form: UntypedFormGroup;
	hasDependents: boolean;
	eventQuestions = {
		prematureDeath: {
			label: 'Premature Death:',
		},
		unableToWorkPermanently: {
			label: 'Unable to work permanently:',
		},
		sufferFromCriticalIllnessOrTrauma: {
			label:
				'Suffer from a Critical Illness / Trauma (Incl. Cancer, Stroke, Heart attack):',
		},
		unableToWorkDueToTemporarySickness: {
			label: 'Unable to work due to temporary sickness or injury:',
		},
		medicalConditionRequiringHospitalTreatment: {
			label: 'Medical condition requiring hospital treatment:',
		},
	};

	constructor(
		private fb: UntypedFormBuilder,
		private riskProfileService: RiskProfileService
	) {
		this.buildForm();
	}

	get DetailsList() {
		return this.form.get('detailsList') as UntypedFormArray;
	}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.peopleList) {
			this.prepData();
		}
	}

	prepData() {
		this.sosCRT$
			.pipe(
				filter((sosCrt) => !!sosCrt),
				map((sosCrt) => RiskProfileMapper.mapToSosDefault(sosCrt)),
				tap((data) => {
					this.defaultValues = data;
					this.peopleList?.map((item) => this.addEvent(item));
					this.hasDependents = this.peopleList?.some((x) => !x?.cRTId);
				}),
				delay(500),
				tap(() => {
					this.updateState();
					this.onValueChanges();
					this.form.markAllAsTouched();

					if (this.isAdviceProcessEnded) {
						this.form.disable();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	buildForm() {
		this.form = this.fb.group({
			detailsList: this.fb.array([]),
		});
	}

	addEvent(data?: PeopleEventState) {
		if (data?.isDependents || data?.peopleCRTId === 0) {
			this.DetailsList?.push(
				this.fb.group({
					peopleCRTId: data?.peopleCRTId || 0,
					personName:
						this.getName(data?.peopleCRTId, data?.isDependents) || 'Dependants',
					prematureDeath: [
						data?.prematureDeath || this?.defaultValues?.prematureDeath,
						Validators.required,
					],
					sufferFromCriticalIllnessOrTrauma: [
						data?.sufferFromCriticalIllnessOrTrauma ||
							this?.defaultValues?.sufferFromCriticalIllnessOrTrauma,
						Validators.required,
					],
					medicalConditionRequiringHospitalTreatment: [
						data?.medicalConditionRequiringHospitalTreatment ||
							this?.defaultValues?.medicalConditionRequiringHospitalTreatment,
						Validators.required,
					],
					isDependents: true,
				})
			);
		} else {
			this.DetailsList?.push(
				this.fb.group({
					peopleCRTId: data?.peopleCRTId || 0,
					personName:
						this.getName(data?.peopleCRTId, data?.isDependents) || 'Dependants',
					prematureDeath: [
						data?.prematureDeath || this?.defaultValues?.prematureDeath,
						Validators.required,
					],
					unableToWorkPermanently: [
						data?.unableToWorkPermanently ||
							this?.defaultValues?.unableToWorkPermanently,
						Validators.required,
					],
					sufferFromCriticalIllnessOrTrauma: [
						data?.sufferFromCriticalIllnessOrTrauma ||
							this?.defaultValues?.sufferFromCriticalIllnessOrTrauma,
						Validators.required,
					],
					unableToWorkDueToTemporarySickness: [
						data?.unableToWorkDueToTemporarySickness ||
							this?.defaultValues?.unableToWorkDueToTemporarySickness,
						Validators.required,
					],
					medicalConditionRequiringHospitalTreatment: [
						data?.medicalConditionRequiringHospitalTreatment ||
							this?.defaultValues?.medicalConditionRequiringHospitalTreatment,
						Validators.required,
					],
					isDependents: data?.isDependents || false,
				})
			);
		}
	}

	getName(id: number, isDependents: boolean = false) {
		let getName = '';
		if (isDependents) {
			return 'Dependants';
		}
		if (this.peopleList) {
			const nameDisplay = find(propEq('cRTId', +id))(
				this.peopleList
			) as PeopleState;
			getName = nameDisplay?.name;
		}
		return getName || '';
	}

	onValueChanges(): void {
		this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
			this.updateState();
		});
	}

	updateState() {
		const getData = RiskProfileMapper.mapEventToView(
			this.form.getRawValue().detailsList
		);
		this.riskProfileService.updateRiskProfileState({
			event: getData,
		});
	}

	collapseMore() {
		$('#collapse').toggle();
		this.elseMinus = false;
	}

	collapseLess() {
		$('#collapse').toggle();
		this.elseMinus = true;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
