import {
	Component,
	EventEmitter,
	Output,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	HostBinding,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Moment } from 'moment';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import MomentUtil from '../../util/moment.util';
import * as moment from 'moment';
import { LoggerService } from '../../core/logger/logger.service';
import { DateInputComponent } from '../date-input/date-input.component';
import { logMessage } from '../error-message/error-message';
declare var $: any;

/** data type passed and expected by date range control */
export interface DateRange {
	min: string;
	max: string;
}

/**
 * Reusable component for daterange input.
 * opens up a popover that shows 2 date input.
 *
 * @Caveat
 * Can't make it close on outside click as doing
 * so would close popover upon choosing a date.
 */
@Component({
	selector: 'app-date-range',
	templateUrl: './date-range.component.html',
	styleUrls: ['./date-range.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateRangeComponent,
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeComponent implements ControlValueAccessor {

	constructor(private cd: ChangeDetectorRef, private fb: UntypedFormBuilder,
		           private loggerService: LoggerService) { }
	/**
	 * Attribute binding to `position-relative` class of bootstrap.
	 * Used to make ngx-bootstrap popover show up near this control.
	 */
	@HostBinding('class.position-relative') isRelative = true;

	/** Element binding to popover component */
	@ViewChild(PopoverDirective) popover: PopoverDirective;
	@ViewChild('minDateInput') minDateInput: DateInputComponent;
	@ViewChild('maxDateInput') maxDateInput: DateInputComponent;

	/** Event Emitter that emits when a date range is chosen */
	@Output() setEvent = new EventEmitter<any>();
	/** Indicator whether control is disabled. */
	disabled: boolean;

	/** Control Min state. Used to reset the form. */
	nzDateMin: Moment;
	/** Control Max state. Used to reset the form. */
	nzDateMax: Moment;

	/** Angular form to manage fields. */
	form: UntypedFormGroup = this.fb.group({
		ActivityDateMin: [''],
		ActivityDateMax: ['']
	});
	/** placeholder for onChange function to be overwritten by Angular */
	onChange?: (value: DateRange) => void;
	/** placeholder for onTouched function to be overwritten by Angular */
	onTouched?: (value: DateRange) => void;

	writeValue(obj: DateRange): void {
		this.nzDateMin = MomentUtil.DatetimeStringToMoment(obj.min);
		this.nzDateMax = MomentUtil.DatetimeStringToMoment(obj.max);
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.cd.detectChanges();
	}

	/** open popover */
	open() {
		this.popover.show();
	}
	/** close popover */
	close() {
		this.popover.hide();
	}

	/** reset form. Trigger on open to set to current value.  */
	resetForm(e) {
		this.form.setValue({
			ActivityDateMin: this.nzDateMin || '',
			ActivityDateMax: this.nzDateMax || ''
		});
	}

	/**
	 * * Sets control state.
	 * * calls OnChange for form control update.
	 * * emit searchEvent(should be renamed).
	 */
	set() {
		const max = MomentUtil.formatToServerDate(this.form.value.ActivityDateMax);
		const min = MomentUtil.formatToServerDate(this.form.value.ActivityDateMin);

		if (!max && !min) {
			return;
		}

		const minDate = MomentUtil.DatetimeStringToMoment(this.form.value.ActivityDateMin);
		const maxDate = MomentUtil.DatetimeStringToMoment(this.form.value.ActivityDateMax);


		if (this.minDateInput?.isInvalid()) {
			this.loggerService.Log({}, logMessage.shared.general.warning.invalidDate);
			return;
		}

		if (this.maxDateInput?.isInvalid()) {
			this.loggerService.Log({}, logMessage.shared.general.warning.invalidDate);
			return;
		}

		if (moment(minDate).isAfter(maxDate)) {
			this.loggerService.Warning({}, logMessage.shared.general.warning.minNotGreaterThanMaxDate);
			return;
		}

		this.nzDateMin = min ? MomentUtil.DatetimeStringToMoment(this.form.value.ActivityDateMin) : null;
		this.nzDateMax = max ? MomentUtil.DatetimeStringToMoment(this.form.value.ActivityDateMax) : null;

		this.popover.hide();
		if (this.onChange) { this.onChange({ min, max }); }

		this.setEvent.emit({ max, min });
	}
}
