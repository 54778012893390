<span class="tap-required" *ngIf="isEditing">*</span>
<div class="field">
	<div #longText>
		<a
			*ngIf="route; else textTemplate"
			class="m-auto"
			[ngClass]="{ clickable: route }"
			[routerLink]="route"
			(click)="onClick()"
			id="{{ fieldId }}Link_{{ index }}"
		>
			{{ display }}
		</a>

		<ng-template #textTemplate>
			<span class="m-auto">
				{{ display }}
			</span>
		</ng-template>
	</div>
</div>

<ng-container *ngIf="!restrict; else hasRestriction">
	<div *ngIf="isEditable" class="control">
		<button
			*ngIf="!isEditing"
			type="button"
			class="icon-btn-bg btn-edit"
			(click)="editMultiSelectModal()"
			id="{{ fieldId }}EditBtn_{{ index }}"
		>
			<i class="material-icons md-16 dark">&#xE3C9;</i>
		</button>
	</div>
</ng-container>

<ng-template #hasRestriction>
	<ng-container *appRestrictTo="restrict">
		<div *ngIf="isEditable" class="control">
			<button
				*ngIf="!isEditing"
				type="button"
				class="icon-btn-bg btn-edit"
				(click)="editMultiSelectModal()"
				id="{{ fieldId }}EditBtn_{{ index }}"
			>
				<i class="material-icons md-16 dark">&#xE3C9;</i>
			</button>
		</div>
	</ng-container>
</ng-template>

<div>
	<app-manual-loader-mini
		*ngIf="isLoading"
		[isLoading]="isLoading"
		class="loader-mini"
	></app-manual-loader-mini>
</div>

<!-- edit mode field -->
<ng-template #fieldTemplate>
	<select class="form-control" id="{{ fieldId }}RowId_{{ index }}">
		<option value=""></option>
		<option *ngFor="let d of choices" [value]="d.value">{{ d.display }}</option>
	</select>
</ng-template>
