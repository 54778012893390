<table class="table table-bordered">
  <tbody>
    <tr *ngFor="let data of 4">
      <td class="p-0">
        <table class="table table-bordered m-0">
          <thead>
            <tr>
              <th scope="col">Report {{data}}: Call Centre KPIs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-0">
                <table class="table table-bordered table-striped m-0">
                  <thead>
                    <tr>
                      <th scope="col">Leadgen Name:</th>
                      <th scope="col">Leads Created (Defined Timeframe)</th>
                      <th scope="col">Current Confirm Rate:</th>
                      <th scope="col">Confirmed Meetings (Defined Timeframe)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{data}}</td>
                      <td>{{data}}</td>
                      <td>{{data}}</td>
                      <td>{{data}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>