import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackendSidenavComponent } from './backend-sidenav/backend-sidenav.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { BusinessSidenavComponent } from './business-sidenav/business-sidenav.component';
import { AdminSectionComponent } from './business-sidenav/sections/admin-section/admin-section.component';

import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule,
    CollapseModule.forRoot()],
  declarations: [BackendSidenavComponent, HeaderComponent, BusinessSidenavComponent, AdminSectionComponent],
  exports: [BackendSidenavComponent, HeaderComponent, BusinessSidenavComponent]
})
export class NavigationModule { }
