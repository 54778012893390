<div
	class="mat-data-table-scroll"
	[class.disabled]="!!isLoading"
	[class.is-dragging]="isDragging"
>
	<div
		class="mat-data-table-scroll__table"
		[style.height]="containerHeight"
	>
		<cdk-virtual-scroll-viewport
			#viewPort
			orientation="vertical"
			[itemSize]="itemSize"
			[minBufferPx]="viewPort.getViewportSize()"
  		[maxBufferPx]="itemSize * viewPort.getViewportSize()"
		>
			<table
				mat-table
				#table
				[dataSource]="dataSource"
				cdkDropList
				cdkDropListOrientation="horizontal"
				(cdkDropListDropped)="drop($event)"
				(matSortChange)="sortData($event)"
				matSort
			>
				<ng-container
					*ngFor="
						let col of tableColumns;
						let colIndex = index;
						trackBy: trackByFn
					"
				>
					<ng-container [matColumnDef]="col.columnId" [sticky]="col.sticky">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="tbl-header text-truncate"
							[class]="col.headerCellClass"
							[class.locked-cell]="!!col.sticky"
							[resizeColumn]="!!col.allowResize"
							[style.width]="(col?.width || '150') + 'px'"
							[index]="colIndex"
							(onResize)="onResizeColumn($event)"
							[style.top.px]="offset"
						>
							<span
								cdkDrag
								mat-sort-header
								cdkDragLockAxis="x"
								(cdkDragStarted)="enter($event)"
								[cdkDragDisabled]="!col.draggable || !!col.sticky"
								[cdkDragData]="col"
								[class.locked-cell]="!!col.sticky"
								[class.draggable-cell]="!!col.draggable && !col.sticky"
								class="tbl-header-text font-weight-bold"
							>
								<i
									class="material-icons draggable ng-star-inserted mr-1"
									*ngIf="!!col.draggable"
									>drag_indicator</i
								>{{ col?.columnName }}
							</span>
						</th>
						<td
							mat-cell
							*matCellDef="let element; let rowIndex = index"
							class="tbl-cell text-truncate"
							[class]="col.bodyCellClass"
							[class.locked-cell]="!!col.sticky"
							[style.width]="(col?.width || '150') + 'px'"
						>
							<!-- Type: Text -->
							<ng-container *ngIf="col.type === 'text'">
								<span
									#cellText
									class="tbl-data tbl-data-text text-truncate"
									[class]="
										col.hasOwnClass
											? element.class + ' ' + col.columnId
											: col.columnId
									"
									[matTooltip]="element?.[col.columnId] || ''"
									[matTooltipDisabled]="!col.showToolTip"
									matTooltipPosition="right"
									matTooltipClass="tbl-data-tooltip"
								>
									{{ element?.[col.columnId] || ''}}
								</span>
							</ng-container>

							<!-- Type: Moment Date -->
							<ng-container *ngIf="col.type === 'moment'">
								<span
									class="tbl-data tbl-data-moment"
									[class]="
										col.hasOwnClass
											? element.class + ' ' + col.columnId
											: col.columnId
									"
									>{{ element?.[col.columnId] | momentDatetime }}</span
								>
							</ng-container>

							<!-- Type: File Link -->
							<ng-container *ngIf="col.type === 'fileLink'">
								<span
									#cellTextFile
									class="tbl-data tbl-data-text text-truncate clickable"
									[class]="
										col.hasOwnClass
											? element.class + ' ' + col.columnId
											: col.columnId
									"
									[matTooltip]="element?.[col.columnId] || ''"
									[matTooltipDisabled]="!col.showToolTip"
									matTooltipPosition="right"
									matTooltipClass="tbl-data-tooltip"
									(click)="viewFile(element)"
								>
									{{ element?.[col.columnId] || ''}}
								</span>
							</ng-container>

							<!-- Type: Route -->
							<ng-container *ngIf="col.type === 'route'">
								<a
									#cellTextRoute
									class="tbl-data tbl-data-text text-truncate clickable"
									[class]="
										col.hasOwnClass
											? element.class + ' ' + col.columnId
											: col.columnId
									"
									[routerLink]="element.routerLink"
									[matTooltip]="element?.[col.columnId] || ''"
									[matTooltipDisabled]="!col.showToolTip"
									matTooltipPosition="right"
									matTooltipClass="tbl-data-tooltip"
								>
									{{ element?.[col.columnId] || ''}}
								</a>
							</ng-container>

							<!-- Type: Action -->
							<ng-container *ngIf="col?.type === 'action'">
								<span
									class="tbl-data tbl-data-action clickable"
									[class]="
										col.hasOwnClass
											? element.class + ' ' + col.columnId
											: col.columnId
									"
									(click)="
										!!col.actionCallback ? !!col.actionCallback(element) : null
									"
									>{{ col?.actionCallbackText || "" }}</span
								>
							</ng-container>
						</td>
					</ng-container>
				</ng-container>

				<tr
					mat-header-row
					*matHeaderRowDef="displayedColumns; sticky: true"
					[style.top.px]="offset"
				></tr>

				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				<tr class="mat-row" *matNoDataRow>
					<td
						class="mat-cell text-center"
						[attr.colspan]="displayedColumns.length"
					>
						<ng-container *ngIf="!isLoading; else showLoading">
							<em>No data to display</em>
						</ng-container>
						<ng-template #showLoading>
							<em>Loading...</em>
						</ng-template>
					</td>
				</tr>
			</table>
		</cdk-virtual-scroll-viewport>
	</div>
</div>
