import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { CashDepositState } from '../state/cash-deposit.model';

@Injectable()
@StoreConfig({ name: 'cashDeposit', idKey: 'parentCRTId' })
export class CashDepositStore extends EntityStore<CashDepositState> {
	constructor() {
		super();
	}
}
