import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { InputModule } from '@shared/components/input/input.module';
import { InputSelectModule } from '@shared/components/input-select/input-select.module';
import { UserPdTaskModalComponent } from './user-pd-task-modal.component';
import { InputChipsModule } from '@shared/components/input-chips/input-chips.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		InputModule,
		InputSelectModule,
		InputChipsModule,
	],
	declarations: [UserPdTaskModalComponent],
	exports: [UserPdTaskModalComponent],
})
export class UserPdTaskModalModule {}
