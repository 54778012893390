import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { of, Subject, Observable } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  @Output() serviceEvent = new EventEmitter();

  // LR
  CSLRF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('CSLRF');
  // Security
  CSSF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('CSSF');
  // Mortgage
  CSMF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('CSMF');
  // FG
  CSFGF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('CSFGF');
  // Kiwisaver
  CSKF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('CSKF');
  // Investment
  CSIF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('CSIF');

  constructor(private fb: UntypedFormBuilder, private dropdownValueQuery: DropdownValueQuery) { }

  public serviceForm = this.fb.group({
    serviceType: ['', Validators.required]
  });

  ngOnInit() {
    this.serviceForm
      .get('serviceType')
      .valueChanges.pipe(
        mergeMap(type => {
          switch (type) {
            case 'SLDI':
              return this.CSLRF$;
            case 'SSDI':
              return this.CSSF$;
            case 'SMDI':
              return this.CSMF$;
            case 'SFDI':
              return this.CSFGF$;
            case 'SKDI':
              return this.CSKF$;
            case 'SIDI':
              return this.CSIF$;
            default:
              return of<ViewDisplayValue[]>([]);
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(x => {
        this.serviceEvent.emit({ serviceType: this.serviceForm.get('serviceType').value, dropdownValues: x });
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
