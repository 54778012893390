import { Component, OnInit, Renderer2 } from '@angular/core';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import MomentUtil from '../../util/moment.util';
import { TlReportService } from './tl-report.service';

@Component({
	selector: 'app-tl-report',
	templateUrl: './tl-report.component.html'
})
export class TlReportComponent implements OnInit {

	private isExportingSubj: BehaviorSubject<boolean>;
	public readonly isExporting$: Observable<boolean>;

	/** List of report types */
	reportTypes: ViewDisplayValue[] = [
		{ display: 'Production Report', value: 'ProductionReport' },
		{ display: 'Production Report (by Adviser)', value: 'ProductionReportByAdviser' },
		{ display: 'Book Summary Report', value: 'BookBreakdown' },
	];

	form: UntypedFormGroup = this.fb.group({
		ReportType: this.fb.control(this.reportTypes.find(x => x.value === 'ProductionReport').value, [
			Validators.required
		]),
		DateMin: this.fb.control(null),
		DateMax: this.fb.control(null)
	});

	constructor(private fb: UntypedFormBuilder, private renderer: Renderer2, private service: TlReportService) {
		this.isExportingSubj = new BehaviorSubject(false);
		this.isExporting$ = this.isExportingSubj.asObservable();
	}

	ngOnInit(): void {
	}

	export() {
		this.isExportingSubj.next(true);
		const req = {
			ReportType: this.form.value.ReportType,
			DateMin: MomentUtil.formatToServerDate(this.form.value.DateMin),
			DateMax: MomentUtil.formatToServerDate(this.form.value.DateMax)
		};
		this.service
			.export(req)
			.subscribe(
				file => {
					const name = req.ReportType === 'ProductionReport' ?
						`ProductionReport.csv` : (req.ReportType === 'ProductionReportByAdviser' ? `ProductionReportByAdviser.csv` : `BookBreakdownSummaryReport.csv`);
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(file);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
					this.isExportingSubj.next(false);
				},
				err => {
					this.isExportingSubj.next(false);
					return throwError(err);
				}
			);
	}
}
