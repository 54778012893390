<form
	[formGroup]="form"
	[class.loatv2]="addLOATv2ClassName"
	[class.submitted]="submitted"
>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-3 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Trust Name
			</label>
		</div>
		<div class="col-8 col-md-9 mb-2">
			<input
				id="trustName"
				class="form-control crt-form-control"
				formControlName="trustName"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-3 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Trading Name
			</label>
		</div>
		<div class="col-8 col-md-9 mb-2">
			<input
				id="tradingName"
				class="form-control crt-form-control crt-form-control"
				formControlName="tradingName"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-3 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Trust Type
			</label>
		</div>
		<div class="col-8 col-md-9 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="trustType"
					formControlName="trustType"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of trustTypes" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row">
		<div class="col-4 col-md-3 text-left mb-2 beneficiary-label">
			<label for="" class="position-relative font-weight-bold">
				Beneficiary
			</label>
		</div>
		<div class="col-8 col-md-9 mb-2" formArrayName="beneficiary">
			<div
				*ngFor="let t of beneficiary.controls; let i = index"
				class="clearfix pb-2"
			>
				<div class="form-row" [formGroupName]="i">
					<div class="col" *oatFeature="oATFeatures.LOATV2;else loatv1Template">
						<span
							class="w-100 crt-custom-dropdown"
							*ngIf="!t.value.isCustomValue; else newPersonTemplate"
						>
							<select
								id="trustType"
								formControlName="name"
								class="form-control theme-crm-field-disabled crt-form-control"
								(change)="beneficiaryChange($event.target.value, i)"
							>
								<option
									*ngFor="let beneficiary of getBeneficiaryDd(i)"
									[selected]="t.value.name === beneficiary.name"
									[value]="beneficiary.name"
								>
									{{ beneficiary.name }}
								</option>
								<option value="new">+Add New Person</option>
							</select>
						</span>
						<ng-template #newPersonTemplate>
							<input
								id="beneficiary_{{ i }}"
								class="form-control crt-form-control"
								formControlName="name"
								type="text"
							/>
						</ng-template>
					</div>

					<ng-template #loatv1Template>
						<div class="col">
							<input
								id="beneficiary_{{ i }}"
								class="form-control crt-form-control"
								formControlName="name"
								type="text"
							/>
						</div>
					</ng-template>

					<div class="col-auto" *ngIf="!viewMode">
						<button
							id="removebeneficiaryButton_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto pt-2"
							(click)="removeBeneficiary(i)"
						>
							<i class="material-icons md-16"> delete </i>
						</button>
					</div>
				</div>
			</div>
			<div class="col-1">
				<ng-container *ngIf="!viewMode">
					<button
						id="addTrusteeButton"
						type="button"
						class="tap-btn tap-btn-outline border-0 px-1 col-auto mb-2"
						(click)="addBeneficiary()"
					>
						+
					</button>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="form-row">
		<div class="col-4 col-md-3 text-left mb-2 trustee-label">
			<label for="" class="position-relative font-weight-bold"> Trustee </label>
		</div>
		<div class="col-8 col-md-9 mb-2" formArrayName="trustee">
			<div
				*ngFor="let t of trustee.controls; let i = index"
				class="clearfix pb-2"
			>
				<div class="form-row" [formGroupName]="i">
					<div class="col">
						<ng-container
							*oatFeature="oATFeatures.LOATV2;else loatv1TrusteeTemplate"
						>
							<span
								class="w-100 crt-custom-dropdown"
								*ngIf="!t.value.isCustomValue; else newPersonTemplate"
							>
								<select
									id="trustType"
									formControlName="name"
									class="form-control theme-crm-field-disabled crt-form-control trust-type-v2"
									(change)="trusteeChange($event.target.value, i)"
								>
									<option
										*ngFor="let person of getTrusteeDd(i)"
										[value]="person.name"
										[selected]="t.value.name === person.name"
									>
										{{ person.name }}
									</option>
									<option value="new">+Add New Person</option>
								</select>
							</span>
							<ng-template #newPersonTemplate>
								<input
									id="trustee_{{ i }}"
									class="form-control crt-form-control"
									formControlName="name"
									type="text"
								/>
							</ng-template>
						</ng-container>
						<ng-template #loatv1TrusteeTemplate>
							<input
								id="trustee_{{ i }}"
								class="form-control crt-form-control"
								formControlName="name"
								type="text"
							/>
						</ng-template>
					</div>
					<div class="col-auto" *ngIf="!viewMode">
						<button
							id="removeTrusteeButton_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto pt-2"
							(click)="removeTrustee(i)"
						>
							<i class="material-icons md-16"> delete </i>
						</button>
					</div>
				</div>
			</div>
			<div class="col-1">
				<ng-container *ngIf="!viewMode">
					<button
						id="addTrusteeButton"
						type="button"
						class="tap-btn tap-btn-outline border-0 px-1 col-auto mb-2"
						(click)="addTrustee()"
					>
						+
					</button>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="form-row">
		<div class="col-4 col-md-3 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Independent Trustee
			</label>
		</div>
		<div class="col-8 col-md-9 mb-2" formArrayName="independentTrustName">
			<div
				*ngFor="let it of independentTrustName.controls; let i = index"
				class="clearfix pb-2"
			>
				<div class="form-row" [formGroupName]="i">
					<div class="col">
						<ng-container
							*oatFeature="oATFeatures.LOATV2;else loatv1IndependentTrustTemplate"
						>
							<span
								class="w-100 crt-custom-dropdown"
								*ngIf="!it.value.isCustomValue; else newPersonIndependentTrustTemplate"
							>
								<select
									id="trustType"
									formControlName="name"
									class="form-control theme-crm-field-disabled crt-form-control trust-type-v2"
									(change)="independentTrustChange($event.target.value, i)"
								>
									<option
										*ngFor="let person of getIndependentTrusteeDd(i)"
										[value]="person.name"
										[selected]="it.value.name === person.name"
									>
										{{ person.name }}
									</option>
									<option value="new">+Add New Person</option>
								</select>
							</span>
							<ng-template #newPersonIndependentTrustTemplate>
								<input
									id="independant_{{ i }}"
									class="form-control crt-form-control"
									formControlName="name"
									type="text"
								/>
							</ng-template>
						</ng-container>
						<ng-template #loatv1IndependentTrustTemplate>
							<input
								id="independant_{{ i }}"
								class="form-control crt-form-control"
								formControlName="name"
								type="text"
							/>
						</ng-template>
					</div>
					<div class="col-auto" *ngIf="!viewMode">
						<button
							id="removeIndependentTrusteeButton_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto pt-2"
							(click)="removeIndependentTrustee(i)"
						>
							<i class="material-icons md-16"> delete </i>
						</button>
					</div>
				</div>
			</div>
			<div class="col-1">
				<ng-container *ngIf="!viewMode">
					<button
						id="addIndependentTrusteeButton"
						type="button"
						class="tap-btn tap-btn-outline border-0 px-1 col-auto mb-2"
						(click)="addIndependentTrustee()"
					>
						+
					</button>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button
				id="closeButton"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
				[disabled]="loading"
			>
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
				id="saveButton"
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
				(click)="save()"
				[disabled]="loading"
			>
				Save
				<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				id="cancelButton"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
				[disabled]="loading"
			>
				Cancel
			</button>
		</ng-template>
	</div>
</form>
