import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as R from 'ramda';
import { combineLatest, iif, Observable, of } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { WysiwygComponent } from '../../../../../../shared/wysiwyg/wysiwyg.component';
import { EmailLinkList } from '../../../../../../shared/models/client-review-template/merge-tags/email-settings/email-links';
import { DisclosureSettingsService } from '../../../../../../modules/user/service/disclosure-settings.service';
import { DisclosureDocumentMapper } from '../../../../../../shared/models/client-review-template/disclosure-document/disclosure-document.mapper';
import { MergeTagsMapper } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { convertUtil, objectUtil } from '../../../../../../util/util';
import { MergeTagsService } from '@modules/user/service/merge-tags.service';

@Component({
	selector: 'app-disclosure-email',
	templateUrl: './disclosure-email.component.html',
	styleUrls: ['./disclosure-email.component.scss'],
})
export class DisclosureEmailComponent implements OnInit {
	@Input() staffId: number;
	@Input() settingsId$: Observable<number>;

	mergeTags$ = this.mtService.mergeTags$;

	form: UntypedFormGroup;
	isLoading = false;
	bodyContent = '<p></p>';
	bodyId: number;
	shortcodes: object;

	disclosureSettings$ = this.dService.disclosureSettings$;

	optionsWysiswyg = {
		heightMax: null,
		heightMin: 250,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkList: EmailLinkList,
		linkAutoPrefix: '',
	};

	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private dService: DisclosureSettingsService,
		private mtService: MergeTagsService
	) {
		this.buildForm();
	}

	get IsEnableEmailOption() {
		return this.form.get('isEnableEmailOption');
	}

	ngOnInit(): void {
		this.prepData();
	}

	buildForm() {
		this.form = this.fb.group({
			isEnableEmailOption: [false],
			carbonCopy: [null],
			blindCarbonCopy: [null],
			subject: [null],
			templateID: [null],
			unsubscribeGroupID: [null],
		});
	}

	prepData() {
		combineLatest([this.disclosureSettings$, this.mergeTags$])
			.pipe(
				filter(([data, mt]) => !!data && !!mt),
				tap(
					([data, mt]) =>
						(this.shortcodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
				),
				tap(([data, mt]) => (this.bodyId = +data?.body || 0)),

				map(([data, mt]) =>
					DisclosureDocumentMapper.mapEmailSettingsData(data)
				),
				tap((data) => {
					if (data) {
						this.form.reset(data);
					}
				}),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.dService.getDisclosureDocumentFile(+data?.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.dService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save() {
		this.isLoading = true;

		of(this.editor?.content)
			.pipe(
				mergeMap((currentTemplate) =>
					convertUtil.convertToBase64(currentTemplate)
				),
				withLatestFrom(of(this.bodyId)),
				map(([currentTemplate, bodyId]) =>
					DisclosureDocumentMapper.mapStaffEmailBody(
						currentTemplate,
						bodyId,
						+this.staffId
					)
				),
				concatMap((data) =>
					iif(
						() => R.isNil(data.documentId),
						this.dService.newFileUploadDD(data),
						this.dService.updateFileUploadDD(data)
					)
				),
				withLatestFrom(of(this.bodyId), this.settingsId$),
				concatMap(([data, body, settingsId]) => {
					const isNewBody = body === 0 || !body;
					const newBodyId = isNewBody ? data : body;
					const formValue = DisclosureDocumentMapper.mapEmailSettingsToUpsert({
						...this.form.getRawValue(),
						referenceId: this.staffId,
						body: newBodyId,
					});
					this.bodyId = newBodyId;

					return iif(
						() => R.isNil(settingsId),
						this.dService.addDisclosureDocument(+this.staffId, {
							...formValue,
							body: data,
						}),
						this.dService.updateDisclosureDocument(+this.staffId, {
							...formValue,
							settingsId,
						})
					);
				}),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}
}
