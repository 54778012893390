import { Component } from '@angular/core';
import { UserQuery } from '@domain/user/user.query';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-call',
  templateUrl: './confirmation-call.component.html',
  styleUrls: ['./confirmation-call.component.scss']
})
export class ConfirmationCallComponent {

	userSecurityGroup$ = this.userQuery.userSecurityGroup$;

	showTabs$ = this.userSecurityGroup$.pipe(
		map((userGroup) => ['SO', 'SM'].includes(userGroup))
	);

	constructor(private userQuery: UserQuery) {}

	ngOnInit(): void {}

}
