import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { MOATAssetsLiabilitiesModalComponent } from 'src/app/shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { PropertyAssetCustomerServiceState } from 'src/app/shared/models/services/property-asset/property-asset';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { PropertyState } from '../../../../crt-mortgage/client-sop/assets-and-liabilities/state/property/property.model';
import { PropertyMapper } from '../../../mapper/property.mapper';
import { DeleteModalComponent } from './../../../../../../../shared/modal/delete-modal/delete-modal.component';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-property-form',
	templateUrl: './property-form.component.html',
	styleUrls: ['./property-form.component.scss'],
})
export class PropertyFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() formId: number;
	@Input() property: PropertyState;
	@Input() policyOwners: ViewDisplayValue[];
	@Input() SU$: Observable<ViewDisplayValue[]>;
	@Input() SV1$: Observable<ViewDisplayValue[]>;
	@Input() ST$: Observable<ViewDisplayValue[]>;
	@Input() STI$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() propertyAddresses: PropertyAssetCustomerServiceState[] = [];
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;
	@Input() openFn: (req: any) => Observable<any>;

	@Input() addMode: boolean;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	// tslint:disable-next-line: variable-name
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	// tslint:disable-next-line: variable-name
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<boolean>();

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;

	isMoatV2Enabled: Observable<boolean>;
	isMoatV2: boolean;
	selectedPropertyAddress: any;

	filteredOwners: any[] = [];
	ownerTooltipDisplay: string;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];
	@Input() pciAddress:{PropertyAddress:string|null};

	constructor(private fb: UntypedFormBuilder, private modalService: BsModalService, private businessConfigQuery: BusinessConfigQuery) {
		this.buildForm();
		this.isMoatV2Enabled = this.businessConfigQuery.moatv2Enabled$;
	}

	ngOnInit(): void {

		this.isMoatV2Enabled.pipe(
			take(1)
		).subscribe((enabled)=> {
			if(enabled) {
				this.prepareDataV2();
				this.isMoatV2 = true;
			} else {
				this.prepareData();
				this.isMoatV2 = false;
			}
		});

		//this.prepareData();

		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));
	}

	prepareData() {
		const owners =
			!!this?.property?.propertyOwner &&
			typeof this.property.propertyOwner === 'string'
				? JSON.parse(this?.property?.propertyOwner)
				: [];

		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);
		this.form.reset({
			propertyAddress: this?.property?.propertyAddress || null,
			propertyOwner: owners,
			propertyUse: this?.property?.propertyUse || null,
			propertyValue: this?.property?.propertyValue || 0,
			customerServiceID: this?.property?.customerServiceID || null,
		});
	}

	prepareDataV2() {

		const owners =
			!!this?.property?.propertyOwner &&
			typeof this.property.propertyOwner === 'string'
				? JSON.parse(this?.property?.propertyOwner)
				: [];

		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);
		if(owners.length !== 0) {
			this.form.reset({
				propertyAddress: this?.property?.propertyAddress || null,
				propertyOwner: owners,
				propertyUse: this?.property?.propertyUse || null,
				propertyValue: this?.property?.propertyValue || 0,
				customerServiceID: this?.property?.customerServiceID || null,
			});
		} else {
			this.setUnsavedPropertyInfo(this.propertyAddresses[0]?.customerServiceID);

		}
	}

	setUnsavedPropertyInfo(propertyId): void {
		const propertyChoicesLength = this.propertyAddresses?.length;
		if(propertyChoicesLength === 0) {
			this.onSelectProperty('new');
			this.cancelEvent.emit(true);
		} else {
			const p: PropertyAssetCustomerServiceState = this.propertyAddresses?.find(
				(x) => +x.customerServiceID === +propertyId
			);
			this.onSelectProperty(propertyId);
			this.selectedPropertyAddress = p?.propertyAddress;
		}

	}


	policyOwnerChoices = (owners: (string | number)[]) => {
		return this.getOwnerChoices(owners, this.policyOwners);
	};

	buildForm() {
		this.form = this.fb.group({
			propertyAddress: this.fb.control(null, [Validators.required]),
			propertyOwner: this.fb.control([], [Validators.required]),
			propertyUse: this.fb.control(null),
			propertyValue: this.fb.control(0, [Validators.required]),
			customerServiceID: this.fb.control(null),
		});
	}

	prepareFormValue() {
		const form = this.form.getRawValue();
		return {
			...form,
		};
	}

	validateForm() {
		// Your validation here
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	save() {
		const data = this.prepareFormValue();
		this.toggleSaving(true);
		if (!!this.addMode) {
			this?.addFn$({
				...PropertyMapper.mapToPropertyDetailsExisting(
					{
						...objectUtil.mapCamelCaseToPascalCase(
							this.propertyAddresses?.find(
								(x) => +x.customerServiceID === +data.customerServiceID
							)
						),
						PropertyAddress: data.propertyAddress,
						PropertyValue: +data.propertyValue,
						PropertyUse: data.propertyUse,
						PropertyOwner: JSON.stringify(data.propertyOwner),
					},
					this.adviceProcessId
				),
			})
				.pipe(
					take(1),
					finalize(() => {
						this.toggleSaving(false);
						this.saveEvent.emit(data);
						this.form.reset();
					})
				)
				.subscribe();
		} else {
			this?.updateFn$({
				...PropertyMapper.mapToPropertyDetails(data, this.adviceProcessId),
			})
				.pipe(
					take(1),
					finalize(() => {
						this.toggleSaving(false);
						this.prepareData();
					})
				)
				.subscribe();
		}
	}

	delete(remove?: boolean) {
		this.toggleSaving(true);
		this.deleteFn$({
			...PropertyMapper.mapToPropertyDetails(
				this?.property,
				this?.property.adviceProcessId
			),
			PropertyStatus: !remove ? 'Sold' : '',
			Status: 0,
		})
			.pipe(
				finalize(() => {
					this.toggleSaving(false);
				})
			)
			.subscribe();
	}

	deleteProperty() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this.delete(false);
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this.delete(true);
			obs.complete();
		});

		const initState = {
			header: 'Delete Property',
			message: `Has this property been sold or removed from this Advice process?`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'Sold',
			confirmButton2: 'Remove',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	edit() {
		this.toggleEdit(true);
	}

	cancel() {
		if (!!this.addMode) {
			this.cancelEvent.emit(true);
		} else {
			this.toggleEdit(false);
		}
		this.prepareData();
	}

	onSelectProperty(property?) {
		if (property === 'new') {
			const decline$ = new Observable((obs: Observer<any>) => {
				this.cancel();
				obs.complete();
			});

			const saveFn$ = (data) =>
				this.addFn$(
					PropertyMapper.mapToPropertyDetails(data, this.adviceProcessId)
				).pipe(
					finalize(() => {
						this.saveEvent.emit(data);
					})
				);
			const crtInfo =this.pciAddress??{};
			const initState = {
				header: 'Property Details',
				message: `Property`,
				policyOwners: this.policyOwners,
				su$: this.SU$,
				sv1$: this.SV1$,
				st$: this.ST$,
				sti$: this.STI$,
				apcrtf$: this.APCRTF$,
				saveFn$,
				decline$,
				getOwnerChoices: this.getOwnerChoices,
				crtInfo:crtInfo
			};
			this.bsModalRef = this.modalService.show(
				MOATAssetsLiabilitiesModalComponent,
				{
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				}
			);
		} else {
			const p: PropertyAssetCustomerServiceState = this.propertyAddresses?.find(
				(x) => +x.customerServiceID === +property
			);
			this.form.reset({
				propertyAddress: p.propertyAddress,
				propertyOwner:
					!!p?.propertyOwner && typeof p?.propertyOwner === 'string'
						? JSON.parse(p?.propertyOwner)
						: [],
				propertyUse: p?.use,
				propertyValue: !!p?.gRM1Value ? p?.gRM1Value : 0,
				customerServiceID: p?.customerServiceID,
			});
			
			const owners = JSON.parse(p?.propertyOwner);
			if(owners && owners.length !== 0) {
				owners?.forEach((owner, index) => {
					const ownerChoices = this.policyOwnerChoices(owners);
					this.ownerTooltipDisplay = owners.map((owner) => {
						const ownerChoice = ownerChoices.find((x) => x.value === owner);
						return ownerChoice?.display || '';
					}).join(', ');
				});
			}
			
		}
	}

	editProperty() {
		const decline$ = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const saveFn$ = (data) =>
			this.updateFn$(
				PropertyMapper.mapToPropertyDetails(data, this.adviceProcessId)
			).pipe(
				tap((x) => {
					this.property = x;
					this.prepareData();
				}),
				finalize(() => {
					this.saveEvent.emit(data);
				})
			);

		const initState = {
			header: 'Property Details',
			message: `Property`,
			policyOwners: this.policyOwners,
			su$: this.SU$,
			sv1$: this.SV1$,
			st$: this.ST$,
			sti$: this.STI$,
			apcrtf$: this.APCRTF$,
			crtInfo: objectUtil.mapCamelCaseToPascalCase(this.property),
			saveFn$,
			decline$,
			getOwnerChoices: this.getOwnerChoices,
		};
		this.bsModalRef = this.modalService.show(
			MOATAssetsLiabilitiesModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}

	approveConfirmation() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this.delete(false);
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this?.updateFn$({
				...PropertyMapper.mapToPropertyDetails(
					{
						...this.property,
						...this.prepareFormValue(),
						pendingAdviserApproval: false,
					},
					this.adviceProcessId
				),
			})
				.pipe(
					take(1),
					finalize(() => {
						this.toggleSaving(false);
						this.prepareData();
					})
				)
				.subscribe();
			obs.complete();
		});

		const initState = {
			header: 'Delete Property',
			message: `This property has been marked as sold in the CP. Please confirm this property should be marked as sold.`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'Yes',
			confirmButton2: 'No',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
