import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-transfer-service',
  templateUrl: './transfer-service.component.html',
  styleUrls: ['./transfer-service.component.scss']
})
export class TransferServiceComponent implements OnInit {
  public getTransferedValue: Subject<string>;

  groupList: any[];
  pageTitle: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.getTransferedValue = new Subject();
  }

  selectTransferedValue(value) {
    this.getTransferedValue.next(value);
    this.bsModalRef.hide();
  }

}
