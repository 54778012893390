import {
	ManageUsersListFilter,
	ManageUsersSearchResult,
} from './manage-users-list.model';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { MatDataTableModel } from '@shared/models/_general/mat-data-table.model';
import MomentUtil from '@util/moment.util';
import { util } from '@util/util';
import { util as utilService } from '@core/util/util.service';
import { prop, uniqBy } from 'ramda';

export class ManageUsersListMapper {
	public static mapToView(data: ManageUsersSearchResult) {
		const result = data?.SearchResults?.map((x: any) => ({
			...x,
			AdviserServices: util.tryParseJson(x.AdviserServices)?.join(', ') || '',
			BirthDate: MomentUtil.formatServerDateToDateDisplay(x.BirthDate),
			StartDate: MomentUtil.formatServerDateToDateDisplay(x.StartDate),
			ExitDate: MomentUtil.formatServerDateToDateDisplay(x.ExitDate),
			LastLogin: utilService.DatetimeStringToDatetimeStringDisplay(x.LastLogin),
			routerLink: x?.StaffID,
		}));

		return {
			SearchResults: result || [],
			TotalCount: data?.TotalCount || 0,
			IsComplete: data?.IsComplete,
		} as ManageUsersSearchResult;
	}

	public static mapTableColumns(
		user: BlStaffViewmodel,
		tableColumns: MatDataTableModel[]
	) {
		const columnDefault =
			util.tryParseJson(user?.StaffSettings?.UserSearchColumns) || [];
		const columnDefaultWidth =
			util.tryParseJson(user?.StaffSettings?.UserSearchColumnsWidth) || [];

		const getWidth = (columnName: string) => {
			return (
				columnDefaultWidth?.find((x) => x?.metakey === columnName)?.width ||
				null
			);
		};

		// Stick column first
		const stickyColumns = tableColumns
			?.filter((data) => !!data?.sticky)
			?.map((data) => {
				return {
					...data,
					width: getWidth(data?.columnName),
					visible: true,
				};
			});

		// Visible columns from settings
		const visibleColumns = columnDefault?.reduce((a, c) => {
			const data = tableColumns?.find((col) => col?.columnName === c);
			const isSticky = stickyColumns?.find((x) => x?.columnName === c);
			return !!data && !isSticky
				? [...a, { ...data, width: getWidth(c), visible: true }]
				: a;
		}, []);

		// Remaining columns set as hidden
		const hiddenColumns = tableColumns?.reduce((a, c) => {
			const data = visibleColumns?.find((col) => col?.columnId === c?.columnId);
			const isSticky = stickyColumns?.find((x) => x?.columnId == c?.columnId);
			return !!data || !!isSticky
				? a
				: [...a, { ...c, width: getWidth(c?.columnName), visible: false }];
		}, []);

		const cols =
			[...stickyColumns, ...visibleColumns, ...hiddenColumns]?.filter(
				Boolean
			) || [];

		return uniqBy(prop('columnName'), cols);
	}

	public static mapToUpsertColumn(user, cols: MatDataTableModel[]) {
		let columns = cols?.map((x) => x?.columnName);
		const data = !!columns ? JSON.stringify(columns) : null;

		return {
			...user,
			StaffSettings: {
				...user?.StaffSettings,
				UserSearchColumns: data || '',
			},
		};
	}

	public static mapToUpsertColWidth(user, col: MatDataTableModel) {
		let cols =
			util.tryParseJson(user?.StaffSettings?.UserSearchColumnsWidth) ?? [];
		const data = cols?.find((x) => x?.metakey === col?.columnName);
		if (!!data) {
			cols = cols?.map((x) =>
				x?.metakey === col?.columnName ? { ...x, width: col?.width } : x
			);
		} else {
			cols = [...cols, { metakey: col?.columnName, width: col?.width }];
		}

		return {
			...user,
			StaffSettings: {
				...user?.StaffSettings,
				UserSearchColumnsWidth: JSON.stringify(cols),
			},
		};
	}

	public static mapSearchFilter(
		data: ManageUsersListFilter
	): ManageUsersListFilter {
		return {
			...data,
			StartDateMin: utilService.MomentToDateString(data.StartDateMin),
			StartDateMax: utilService.MomentToDateString(data.StartDateMax),
			LastLoginMin: utilService.MomentToDateString(data.LastLoginMin),
			LastLoginMax: utilService.MomentToDateString(data.LastLoginMax),
			Paging: {
				Column: data?.Paging?.Column,
				Direction: data?.Paging?.Direction,
			},
		};
	}
}
