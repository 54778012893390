import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { finalize, takeUntil, tap, map } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { LiabilityMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/liability/liability.mapper';
import { MOATAssetsLiabilitiesModalComponent } from '../../../../../../../shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { ViewDisplayValue } from '../../../../../../../shared/models/_general/display-value.viewmodel';
import { objectUtil } from '../../../../../../../util/util';
import { Liability } from './../../../../crt-mortgage/client-sop/assets-and-liabilities/state/liability/liability.model';

@Component({
	selector: 'app-crt-liability-form',
	templateUrl: './crt-liability-form.component.html',
	styleUrls: ['./crt-liability-form.component.scss'],
})
export class CrtLiabilityFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() formId: number;
	@Input() liability: Liability;
	@Input() borrowers$: Observable<ViewDisplayValue[]>;
	@Input() securities$: Observable<ViewDisplayValue[]>;
	@Input() MP$: Observable<ViewDisplayValue[]>;
	@Input() MLT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() APCRTL$: Observable<ViewDisplayValue[]>;
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	@Input() addMode: boolean;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	// tslint:disable-next-line: variable-name
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	// tslint:disable-next-line: variable-name
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<boolean>();

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();

		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));
	}

	prepareData() {
		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);
		this.form.reset({
			liability: this?.liability?.liability || null,
			interestRate:
				(this?.liability?.interestRate ? this?.liability?.interestRate : 0) +
				'%',
			loanLimit: this?.liability?.loanLimit || null,
			loanBalance: this?.liability?.loanBalance || 0,
		});
	}

	buildForm() {
		this.form = this.fb.group({
			liability: this.fb.control(null),
			interestRate: this.fb.control(null),
			loanLimit: this.fb.control(null),
			loanBalance: this.fb.control(null),
		});
	}

	prepareFormValue() {
		const form = this.form.getRawValue();
		return {
			...form,
		};
	}

	validateForm() {
		// Your validation here
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	save() {}

	delete() {
		this.toggleSaving(true);
		this.deleteFn$(this.liability?.cRTId)
			.pipe(
				finalize(() => {
					this.toggleSaving(false);
				})
			)
			.subscribe();
	}

	deleteLiability() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Delete Liability',
			message: `Are you sure you want to delete this?`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	edit() {
		this.toggleEdit(true);
	}

	cancel() {
		if (!!this.addMode) {
			this.cancelEvent.emit(true);
		} else {
			this.toggleEdit(false);
		}
		this.prepareData();
	}

	editLiability() {
		const decline$ = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const saveFn$ = (data) =>
			this.updateFn$(
				LiabilityMapper.mapToDetails(data, this.adviceProcessId)
			).pipe(
				tap((x) => {
					this.liability = x;
					this.prepareData();
				}),
				finalize(() => {
					this.saveEvent.emit(data);
				})
			);

		let crtInfo = objectUtil.mapCamelCaseToPascalCase(this.liability);

		const initState = {
			header: 'Liabilities Details',
			message: `Liabilities`,
			borrowers: this.borrowers$,
			securities: this.securities$,
			mp$: this.MP$,
			mlt$: this.MLT$,
			apcrtf$: this.APCRTF$,
			apcrtyn$: this.APCRTYN$,
			apcrtynna$: this.APCRTYNNA$,
			apcrtl$: this.APCRTL$,
			crtInfo,
			saveFn$,
			decline$,
			getOwnerChoices: this.getOwnerChoices,
		};
		this.bsModalRef = this.modalService.show(
			MOATAssetsLiabilitiesModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}

	approveConfirmation() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			const data = {
				...this.liability,
				pendingAdviserApproval: false,
			};
			this.updateFn$(LiabilityMapper.mapToDetails(data, this.adviceProcessId))
				.pipe(
					tap((x) => {
						this.liability = x;
						this.prepareData();
					}),
					finalize(() => {
						this.saveEvent.emit(data);
					})
				)
				.subscribe();
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Delete Liability',
			message: `This Liability has been removed from the CP. Please confirm this Liability should be removed.`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'Remove',
			confirmButton2: 'Cancel',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
