import { Observable, Subject, of, concat, from } from 'rxjs';
import {
	ApiService,
	JsonResultStatus,
} from '../../../../core/base/api.service';

import { DocumentQuery } from './document.query';
import { DocumentStore } from './document.store';
import { CustomerDocument, CustomerDocumentGet } from './document.model';
import { ConfigService } from '../../../../core/config/config.service';
import {
	tap,
	mergeMap,
	withLatestFrom,
	switchMap,
	map,
	concatMap,
	catchError,
} from 'rxjs/operators';
import { ViewCustomerDocument } from '../../../../shared/models/_general/customer-document.viewmodel';
import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import produce from 'immer';
import {
	CRMDocumentTypeCodes,
	DocumentModelState,
	DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';

@Injectable()
export class DocumentService {
	private onDestroy$ = new Subject<void>();

	constructor(
		protected api: ApiService,
		protected configService: ConfigService,
		protected store: DocumentStore,
		private query: DocumentQuery
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	search(docType: string) {
		const req = {
			customer_id: 0,
			documenttypecode: docType,
		} as CustomerDocumentGet;

		return of(req).pipe(
			mergeMap(() =>
				this.api.get<CustomerDocument[]>(`documents?type=${docType}`, req)
			),
			tap((x) =>
				applyTransaction(() => {
					if (docType === CRMDocumentTypeCodes.Adviser) {
						this.store.setDocuments(x, docType);
					}
					if (docType === CRMDocumentTypeCodes.Office) {
						this.store.setDocuments(x, docType);
					}
					if (docType === CRMDocumentTypeCodes.FAP) {
						this.store.setDocuments(x, docType);
					}
					if (docType === CRMDocumentTypeCodes.Other) {
						this.store.setDocuments(x, docType);
					}
				})
			)
		);
	}

	uploadDocument(req: { doc: any; doctype: string }) {
		const docs: any[] = req.doc.getAll('');
		const first$ = of({
			CustomerID: docs[0].customer_id,
			Document: '',
			FileName: docs[0].name,
			DocumentType: req.doctype,
		});

		let failedCount = 0;
		let successCount = 0;

		return first$.pipe(
			switchMap(() =>
				concat(
					first$,
					from(docs).pipe(
						mergeMap(
							(x) => this.convertToBase64(x),
							(o, i) => [o, i]
						),
						map(([o, i]) => {
							return {
								CustomerID: o.customer_id,
								Document: i ? i?.split(',')[1] : '',
								FileName: o.name,
								DocumentType: req.doctype,
							};
						}),
						concatMap((req2) =>
							this.api.post('documents', req2).pipe(
								tap(() => {
									successCount++;
								}),
								catchError(() => {
									failedCount++;
									return of('failed');
								})
							)
						)
					)
				)
			),
			mergeMap(() => this.search(req.doctype)),
			map(() => {
				return {
					success: successCount,
					failed: failedCount,
				};
			})
		);
	}

	deleteDocument(doc: ViewCustomerDocument, docType: string) {
		const req = {
			document_id: doc.DocumentID,
		};
		return of(req).pipe(
			mergeMap(() =>
				this.api.delete<JsonResultStatus>('documents/' + req.document_id)
			),
			withLatestFrom(
				this.query.adDocuments$,
				this.query.odDocuments$,
				this.query.xdDocuments$,
				this.query.fapDocuments$
			),
			tap(([res, ad, od, xd, fd]) =>
				applyTransaction(() => {
					const filterState =
						docType === CRMDocumentTypeCodes.Adviser
							? ad
							: docType === CRMDocumentTypeCodes.Office
							? od
							: docType === CRMDocumentTypeCodes.FAP
							? fd
							: xd;
					const newDoc = filterState?.filter(
						(a) => a.DocumentID !== doc.DocumentID
					);
					this.store.setDocuments(newDoc, docType);
					return res;
				})
			)
		);
	}

	downloadLink(documentID) {
		return this.api.get<string>(`documents/download/${documentID}`);
	}

	transfer(doc: CustomerDocument, transferToCode: string) {
		return of(doc).pipe(
			mergeMap((x) => {
				return this.api.put<JsonResultStatus>('documents/' + doc.DocumentID, {
					...x,
					DocumentID: x.DocumentID,
					DocumentTypeCode: transferToCode,
					DocumentName: x.DocumentName,
				});
			}),
			withLatestFrom(
				this.query.adDocuments$,
				this.query.odDocuments$,
				this.query.fapDocuments$,
				this.query.xdDocuments$
			),
			tap(([res, ad, od, fap, xd]) =>
				applyTransaction(() => {
					const filterState =
						doc.DocumentTypeCode === CRMDocumentTypeCodes.Adviser
							? ad
							: doc.DocumentTypeCode === CRMDocumentTypeCodes.Office
							? od
							: doc.DocumentTypeCode === CRMDocumentTypeCodes.Other
							? xd
							: fap;

					const newDoc = filterState?.filter(
						(a) => a.DocumentID !== doc.DocumentID
					);

					this.store.setDocuments(newDoc, doc.DocumentTypeCode);

					const state =
						transferToCode === CRMDocumentTypeCodes.Adviser
							? ad
							: transferToCode === CRMDocumentTypeCodes.Office
							? od
							: transferToCode === CRMDocumentTypeCodes.Other
							? xd
							: fap;

					const newState = produce(state, (draft) => {
						doc.DocumentTypeCode = transferToCode;
						draft?.unshift(doc);
					});

					this.store.setDocuments(newState, transferToCode);
				})
			)
		);
	}

	convertToBase64 = (file, reader = new FileReader()) =>
		new Observable((obs) => {
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(file);
		});

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	getFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	/**
	 *  TAP LEVEL DOCUMENTS
	 */
	tlGetFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/global-settings/${id}`);
	}

	tlNewFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents/global-settings';
		return this.api.post3<any>(endpoint, req);
	}

	tlDownloadLink(documentID: number) {
		return this.api.get<string>(`documents/download/${documentID}/tl`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	splitContentPerPage(content: string): string[] {
		const docPages: string[] = [];
		const pageBreakSelector = '[name="pagebreak"]';
		const fragment = document.createDocumentFragment();
		const tempContainer = document.createElement('div') as HTMLDivElement;
		fragment.appendChild(tempContainer);
		tempContainer.innerHTML = content;

		const pageBreaks = tempContainer.querySelectorAll(pageBreakSelector);
		if (!pageBreaks.length) {
			return [];
		}

		let pageBreaksParentFirstChild = pageBreaks[0].parentElement.firstChild;
		pageBreaks.forEach((pageBreaks: HTMLElement, index: number) => {
			const pageElementContainer = document.createElement('div');
			let nextSibling = pageBreaksParentFirstChild as HTMLElement;
			while (nextSibling) {
				if (nextSibling.getAttribute('name') === 'pagebreak') {
					docPages.push(pageElementContainer.innerHTML);
					pageElementContainer.innerHTML = '';
				} else {
				pageElementContainer.appendChild(nextSibling.cloneNode(true));
				}
				nextSibling = nextSibling?.nextElementSibling as HTMLElement;
				if (!nextSibling) {
					docPages.push(pageElementContainer.innerHTML);
				}
			}
		});
		return docPages;
	}
}
