<table class="table table-bordered table-striped">
  <thead>
    <tr>
      <th scope="col">CC - 1st Meeting Confirmed Date</th>
      <th scope="col">Adviser Name</th>
      <th scope="col">Client Full Name</th>
      <th scope="col">NB Recomendation Date:</th>
      <th scope="col">L&R Applications Submitted Date</th>
      <th scope="col">L&R Issued Date</th>
      <th scope="col">KS Completed Date</th>
      <th scope="col">Mortgage Load Drawdown</th>
      <th scope="col">F&G Completed Date</th>
      <th scope="col">Contact Status</th>
      <th scope="col">Client Start Date</th>
      <th scope="col">No of Services</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of 12">
      <td>{{data}}</td>
    </tr>
  </tbody>
</table>