import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, EventEmitter, Output, HostBinding } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, ConnectableObservable, of, concat, Observable, combineLatest } from 'rxjs';
import {
	take,
	publishBehavior,
	withLatestFrom,
	map,
	tap,
	mergeMap,
	combineLatest as combineLatestOperator,
} from 'rxjs/operators';
import { RouteService } from '../../../core/config/route.service';
import { ClientAddModalComponent } from '../../modal/client-add-modal/client-add-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfigService } from '../../../core/config/config.service';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { CustomerSearchModel } from '../../models/_general/customerSearchModel';
import { SearchControlComponent } from '../search-control/search-control.component';
import * as R from 'ramda';
import produce from 'immer';
import { CustomerSearchService } from '../../../core/customer/search.service';
import { CustomerService } from '../../../core/customer/customer.service';
import { BusinessService } from '../../../core/business/business.service';
import { NoteService } from '../../../core/note/note.service';
import { CustomerTypes } from '../../models/_general/client.model';

@Component({
	selector: 'app-client-search-control,[app-client-search-control]',
	template: `<app-search-control
		[formControl]="control"
		(searchEvent)="search($event)"
		(quickAddEvent)="invokeQuickAdd($event)"
		(selectEvent)="selectSearchEvent($event)"
		(clearEvent)="clearSearchEvent($event)"
		[commandRoute]="commandRoute$ | async"
		[choices]="choices$ | async"
		[isLoading]="isSearching$ | async"
		[textboxClass]="textboxClass"
		[textboxId]="textboxId"
		[tabindex]="tabindex"
		[showQuickAddIcon]="showQuickAddIcon"
		[businessInfo]="businessInfo"
	></app-search-control>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ClientSearchControlComponent,
			multi: true,
		},
	],
})
export class ClientSearchControlComponent implements OnInit, ControlValueAccessor {
	@Input() set excludeChoiceIds(v: number[]) {
		this.excludedIds.next(v);
	}

	constructor(
		private customerSearchService: CustomerSearchService,
		private routeService: RouteService,
		private modalService: BsModalService,
		private customerService: CustomerService,
		private businessService: BusinessService,
		private configService: ConfigService,
		private noteService: NoteService
	) {}
	@Input() textboxClass = '';
	@Input() textboxId = '';
	@Input() tabindex: number;
	@Input() showQuickAddIcon = false;
	@Input() showDeceased = false;

	excludedIds = new BehaviorSubject<number[]>([]);
	@Input() searchMode: 'LinkedContacts' | 'ReferredBy' | 'Director' | 'Shareholder' | 'Other' | 'PrimaryCustomerIndividual' = 'ReferredBy';
	@Input() hasQuickAdd = false;
	@Input() advisers: ViewDisplayValue[] = [];
	@Input() leadOriginChoice: ViewDisplayValue[] = [];
	@Input() leadTypeChoice: ViewDisplayValue[] = [];
	@Input() trustTypes: ViewDisplayValue[] = [];
	@Input() customerStatus: string;
	@Input() businessInfo = null;
	@Output()
	quickAddEvent = new EventEmitter<any>();
	@Output()	selectEvent = new EventEmitter<number>();
	@Output()	clearEvent = new EventEmitter<number>();

	@ViewChild(SearchControlComponent) searchBox: SearchControlComponent;
	/**
	 * remove the tabindex attribute from host element
	 * to remove duplicate tab index that is attached to input element
	 * */
	@HostBinding('attr.tabindex') get tabIndex() { return null; }

	public control: UntypedFormControl = new UntypedFormControl();
	public formValue$ = this.control.valueChanges.pipe(publishBehavior<CustomerSearchModel>(this.control.value));
	private isDisabled = new BehaviorSubject<boolean>(false);
	public isDisabled$ = this.isDisabled.asObservable();
	companyCode$ = this.configService.CompanyCode;
	private stateSubj = new BehaviorSubject(UsersSearchComponentState.createState());
	private latestState = this.stateSubj.pipe(take(1));
	public choices$ = combineLatest(this.stateSubj, this.excludedIds).pipe(
		map(([state, ids]) => state.choices?.filter(x => !ids?.some(id => id === x.CustomerId))),
		map((list) => this.showDeceased ? list : list?.filter((x) => x.ContactMethod != 'Deceased'))
	);
	public isSearching$ = this.stateSubj.pipe(map(x => x.isSearching));
	public commandRoute$ = this.stateSubj.pipe(
		combineLatestOperator(this.routeService.RouteChange$, this.formValue$),
		map(([, route, formValue]) => {
			if (R.isNil(formValue)) {
				return null;
			}
			if (formValue.IsCompany) {
				return route.businessEdit(formValue.PrimaryCustomerId || formValue.CustomerId);
			} else {
				return route.customerView(formValue.PrimaryCustomerId || formValue.CustomerId);
			}
		})
	);

	private setIsSearching = this.latestState.pipe(map(UsersSearchComponentState.setIsSearching));

	private setIsNotSearching = this.latestState.pipe(map(UsersSearchComponentState.setIsNotSearching));
	private onChange: (value: CustomerSearchModel) => void = () => {};

	ngOnInit() {
		(this.formValue$ as ConnectableObservable<CustomerSearchModel>).connect();
		this.formValue$.subscribe(this.inputChange);
		this.isDisabled$.subscribe(x => (x ? this.control.disable() : this.control.enable()));
	}

	private setState = (newState: UsersSearchComponentState) => this.stateSubj.next(newState);

	private newStateWithUsers = (q: string) => {
		if (!q || q === '') {
			return of([]).pipe(
				map(UsersSearchComponentState.setUsers),
				withLatestFrom(this.latestState),
				map(([fn, state]) => fn(state))
			);
		}
		let search: Observable<CustomerSearchModel[]>;
		if (this.searchMode === 'Director') {
			search = this.customerSearchService.searchDirectors(q);
		} else if (this.searchMode === 'Shareholder') {
			search = this.customerSearchService.searchShareholders(q);
		} else if (this.searchMode === 'Other') {
			search = this.customerSearchService.searchOthers(q);
		} else if (this.searchMode === 'PrimaryCustomerIndividual') {
			search = this.customerSearchService.searchPrimaryCustomerIndividual(q);
		} else if (this.searchMode === 'LinkedContacts') {
			search = this.customerSearchService.searchLinkedContacts(q);
		} else {
			search = this.customerSearchService.searchClients(q);
		}

		return search.pipe(
			map(UsersSearchComponentState.setUsers),
			withLatestFrom(this.latestState),
			map(([fn, state]) => fn(state)),
		);
	};

	private setValue = (v: CustomerSearchModel) => this.control.setValue(v);
	private inputChange = (v: CustomerSearchModel) => this.onChange(v);

	public quickAddModal = (q: string) => () => {
		if (q !== '' && this.hasQuickAdd) {
			this.latestState
				.pipe(
					tap(x => {
						const name = x.choices?.filter(val => val.Name?.toLowerCase() === R.trim(q?.toLowerCase()));
						const containName = x.choices?.filter(val => val.Name?.toLowerCase()?.indexOf(R.trim(q?.toLowerCase())) !== -1);

						// If name doesn't exist
						if (name.length < 1 && containName.length < 1) {
							this.popupQuickAddModal(q);
						}

						// For showQuickAddIcon
						if (name.length < 1 && containName.length > 0) {
							this.showQuickAddIcon = true;
						}
					})
				)
				.subscribe();
		}
	};

	public quickAdd = (model: any, note: any) =>
		new Observable<any>(obs => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap(m => {
				m.contactStatus = this.customerStatus === null ? 'C' : !m.contactStatus ? this.customerStatus : m.contactStatus;
				if (m.companyName !== undefined) {
					m.customerType = CustomerTypes.PrimaryCustomerCompany;
					return this.businessService.AddCompany(m);
				}
				if (m.firstName !== undefined && m.lastName !== undefined) {
					m.customerType = CustomerTypes.PrimaryCustomerIndividual;
					return this.customerService.SavePrimaryClient(m);
				}
				if (m.trustName !== undefined) {
					m.customerType = CustomerTypes.SecondaryCustomerTrust;
					return this.customerService.AddSecondaryTrustByPrimaryClient(m);
				}
			}),
			mergeMap(
				id => {
					const noteModel = {
						customerID: +id,
						customerServiceID: 0,
						notes: note.notes,
					};
					return !noteModel.notes ? of(id) : this.noteService.SaveNote(noteModel);
				},
				o => o
			),
			tap(id => {
				model.CustomerId = id;

				model.searchMode = this.searchMode;
				this.quickAddEvent.emit(model);
			})
		);
	public invokeQuickAdd = (q: string) => {
		this.popupQuickAddModal(q);
	};

	public selectSearchEvent = (q: number) => {
		this.selectEvent.emit(q);
	};

	public clearSearchEvent = (q: number) => {
		this.clearEvent.emit(q);
	};

	public search = (q: string) =>
		concat(this.setIsSearching, this.newStateWithUsers(q), this.setIsNotSearching).subscribe(
			this.setState,
			this.quickAddModal(q)
		);

	public goToUser = () => {
		const a = document.createElement('a');
		a.target = '_blank';
	};

	public writeValue = (obj: CustomerSearchModel) => {
		if (obj) {
			this.setValue(obj);
		}
	};

	public registerOnChange = (fn: (value: any | CustomerSearchModel) => void) => {
		this.onChange = fn;
	};
	public registerOnTouched = () => {};
	public setDisabledState = (isDisabled: boolean) => this.isDisabled.next(isDisabled);

	public setInputValueToNull = () => {
		this.searchBox.setInputValueToNull();
	};

	public setSearchedValue = (value: any) => {
		this.searchBox.select(value);
	};

	popupQuickAddModal(q: string) {
		new Observable(obs => {
			const initState: any = {
				header: 'Quick Add',
				name: q,
				advisers: this.advisers,
				leadOriginChoice: this.leadOriginChoice,
				leadTypeChoice: this.leadTypeChoice,
				trustTypes: this.trustTypes,
				savefn: this.quickAdd,
				searchMode: this.searchMode,
			};
			this.modalService.show(ClientAddModalComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
			});
			obs.complete();
		}).subscribe();
	}
}
class UsersSearchComponentState {
	choices: CustomerSearchModel[];
	isSearching: boolean;

	static createState = () =>
		({
			choices: [],
			isSearching: false,
		} as UsersSearchComponentState);

	// tslint:disable-next-line: member-ordering
	static setIsSearching = produce<any>(draft => {
		draft.isSearching = true;
	});

	// tslint:disable-next-line: member-ordering
	static setIsNotSearching = produce<any>(draft => {
		draft.isSearching = false;
	});

	static setUsers = (newUsers: CustomerSearchModel[]) =>
		produce<any>(draft => {
			draft.choices = newUsers;
		});
}
