import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Security } from './security.model';

export interface SecurityState extends EntityState<Security> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'security', idKey: 'cRTId' })
export class SecurityStore extends EntityStore<SecurityState, Security> {
	constructor() {
		super();
	}
}
