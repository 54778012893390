import {
  Component,
  Input,
  OnDestroy,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {
  takeUntil,
  withLatestFrom,
  tap,
  map,
  mergeMap,
} from 'rxjs/operators';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { BLStaffsQuery } from '../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../domain/dropdown-value/dropdown-value.query';
import { ActivityModalComponent } from '../../modal/activity/activity-modal/activity-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivityViewModel } from '../../models/_general/activity.viewmodel';
import { PutActivityParam } from '../../models/_general/activity.model';
import * as moment from 'moment';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { CommandRoute } from 'src/app/core/config/route.service';
import { LocalService } from 'src/app/core/services/local.service';
declare var $: any;

/**
 * Control for activity details.
 * Opens activity modal, no edit mode.
 */
@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDetailComponent implements OnChanges, OnDestroy {
  /** destroy subject that will trigger unsubscription */
  private onDestroy$ = new Subject<void>();
  /** element to attach the tooltip */
  @ViewChild('longText', { static: true }) longText: ElementRef;
  /** route to go to */
  @Input() route: CommandRoute;
  /** value to display */
  @Input() value: string;
  /** index number for use in ID */
  @Input() index: any;
  /** field name for use in ID */
  @Input() fieldId: any;
  /** restrictions */
  @Input() restrict: string[];
  /** input for activity ID to edit */
  @Input() activityId: number;
  /** event emitter for activity saving */
  @Output() editActivityEvent = new EventEmitter<any>();
  /** if currently saving */
  @Input() isLoading: boolean;
  /** class for overdue color code */
  @Input() cellClass: any;

  @Input() permissionsToComplete: string[];

  /** Activity Type choices */
  AT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$(
    'AT'
  );
  /** Activity Meetings choices */
  AM$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$(
    'AM'
  );
  /** Adviser choices */
  adviserChoices$: Observable<ViewDisplayValue[]> = this.blStaffsQuery.allActiveStaffs$;
  /** Adviser calendar choices */
  adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

  constructor(
    private activityService: ActivityService,
    private modalService: BsModalService,
    private dropdownValueQuery: DropdownValueQuery,
    private blStaffsQuery: BLStaffsQuery,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
		  private localService: LocalService
  ) {}

  /** run all changes updater */
  ngOnChanges(changes: SimpleChanges): void {
    this.valueChanges(changes);
  }

  /** unsubscribe to field subscription on destroy */
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  /**
   * routeLink click, gets stuck when using ngx-datatable.
   * Somehow solves it.
   */
  onClick() {
    this.ngZone.run(() => this.cd.detectChanges());
  }

  /** reAdd tooltip */
  valueChanges(changes: SimpleChanges) {
    const notChanged =
      changes.value === undefined ||
      changes.value.currentValue === changes.value.previousValue;
    if (notChanged) {
      return;
    }

    this.removeTooltip();
    this.addTooltip(changes.value.currentValue);
  }

  /** add tooltip.  */
  private addTooltip(title: string) {
    if (!this.longText) {
      return;
    }
    $(this.longText.nativeElement).tooltip({
      placement: 'right',
      title: () => title,
    });
  }
  /** remove tooltip */
  private removeTooltip() {
    $('.tooltip').remove();
    $('.tooltip-inner').remove();
    if (!this.longText) {
      return;
    }
    $(this.longText.nativeElement).tooltip('dispose');
  }

  get getPermissionsToComplete() {
    return !!this.permissionsToComplete ? this.permissionsToComplete : [];
  }

  /** Open activity modal.
   * * Fetch activity data for editing.
   * * on saving, call `saveEditActivity()`
   */
  editActivityModal() {
    if (this.activityId) {
      this.isLoading = true;
      this.activityService
        .GetById(this.activityId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (activity) => {
            if (activity) {
              const formItem = {
                ...activity,
                DueDate: moment(activity.DueDate),
                Customer: activity.Client,
              };
              const initState: any = {
                formItem,
                AT$: this.AT$,
                AM$: this.AM$,
                adviserChoices$: this.adviserChoices$,
                adviserCalendarChoices$: this.adviserCalendarChoices$,
                header: 'Schedule Activity',
                hideClient: true,
                savefn: this.saveEditActivity,
                isModal: true,
                isRequiredDate: true,
                permissionsToComplete: this.getPermissionsToComplete,
              };
              this.modalService.show(ActivityModalComponent, {
                class: `modal-dialog-centered ${this.localService.getValue('loginType') === 'microsoft' ? 'modal-dialog-outlook-xl' : 'modal-xl'}`,
                initialState: initState,
                ignoreBackdropClick: true,
              });
            }
          },
          () => {},
          () => {
            this.isLoading = false;
            this.cd.detectChanges();
          }
        );
    }
  }

  /**
   * Sends request to API to persist changes.
   * Emits changes to put into state store.
   */
  saveEditActivity = (ac: ActivityViewModel) =>
    new Observable<PutActivityParam>((obs) => {
      const model = ActivityViewModel.MapToEdit(ac);
      obs.next(model);
      obs.complete();
    }).pipe(
      mergeMap((x) => {
        return this.activityService.Put(x);
      }),
      tap(() => this.editActivityEvent.emit(ac))
    );
}
