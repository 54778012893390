import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ConfirmationCallQuestionSetting } from './confirmation-call-question.model';
import { ConfirmationCallQuestionStore } from './confirmation-call-question.store';

@Injectable({ providedIn: 'root' })
export class ConfirmationCallQuestionQuery extends Query<ConfirmationCallQuestionSetting> {
	constructor(protected store: ConfirmationCallQuestionStore) {
		super(store);
	}
}
