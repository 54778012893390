import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ClientProfileRoutingModule } from './client-profile-routing.module';
import { ClientProfileAddResolver } from './client-profile-add/client-profile-add.resolver';
import { ClientProfileEditResolver } from './client-profile-edit/client-profile-edit.resolver';
import { ClientProfileGroupComponent } from './client-profile-group/client-profile-group.component';
import { ClientProfileTabsComponent } from './client-profile-tabs/client-profile-tabs.component';
import { ClientProfileService } from './states/client-profile.service';
import { PeopleEntitiesTabComponent } from './client-profile-tabs/people-entities-tab/people-entities-tab.component';
import { ClientProfileAddComponent } from './client-profile-add/client-profile-add.component';
import { ClientProfileEditComponent } from './client-profile-edit/client-profile-edit.component';
import { ClientProfileStore } from './states/client-profile.store';
import { ClientProfileQuery } from './states/client-profile.query';
import { FormPersonComponent } from './forms/form-person/form-person.component';
import { FormTrustComponent } from './forms/form-trust/form-trust.component';
import { FormBusinessComponent } from './forms/form-business/form-business.component';
import { FormSecondaryComponent } from './forms/form-secondary/form-secondary.component';
import { FormProfessionalComponent } from './forms/form-professional/form-professional.component';

import { FormLinkedContactComponent } from './forms/form-linked-contact/form-linked-contact.component';

// Pipelines
import { LrInsuranceService } from './states/pipelines/lr-insurance.service';
import { PropertyAssetService } from './states/pipelines/property-asset.service';
import { MortgageService } from './states/pipelines/mortgage.service';
import { FgInsuranceService } from './states/pipelines/fg-insurance.service';
import { KiwisaverService } from './states/pipelines/kiwisaver.service';
import { InvestmentService } from './states/pipelines/investment.service';
import { ClientReferralEmailModalModule } from '@shared/client-referral-email-modal/client-referral-email-modal.module';

@NgModule({
  imports: [
    CommonModule,
    ClientProfileRoutingModule,
    SharedModule,
		ClientReferralEmailModalModule,
  ],
  declarations: [
    ClientProfileGroupComponent,
    ClientProfileTabsComponent,
    PeopleEntitiesTabComponent,
    ClientProfileAddComponent,
    ClientProfileEditComponent,
    FormPersonComponent,
    FormBusinessComponent,
    FormTrustComponent,
    FormSecondaryComponent,
    FormProfessionalComponent,
    FormLinkedContactComponent
  ],
  providers: [
    ClientProfileAddResolver,
    ClientProfileEditResolver,
    ClientProfileStore,
    ClientProfileQuery,
    ClientProfileService,
    LrInsuranceService,
    MortgageService,
    PropertyAssetService,
    FgInsuranceService,
    KiwisaverService,
		InvestmentService
  ]
})
export class ClientProfileModule { }
