<div class="border-0 rounded position-relative">
	<ng-container *ngIf="hasValue$ | async; then selectedView; else searchView">
	</ng-container>
</div>

<ng-template #selectedView>
	<div class="d-flex align-items-center">
		<input
			[id]="textboxId"
			[value]="valueName$ | async"
			type="text"
			class="form-control"
			placeholder=""
			aria-label=""
			aria-describedby="basic-addon2"
			disabled
			[ngClass]="textboxNgClass$ | async"
		/>
		<a
			[id]="textboxId === '' ? 'clientLink' : 'clientLink_' + textboxId"
			*ngIf="isAccessible$ | async; else noLinkClientButton"
			class="btn btn-outline-secondary border-0 p-0"
			[routerLink]="commandRoute"
			target="_blank"
		>
			<i class="material-icons align-middle p-1">&#xE7FD;</i>
		</a>
		<ng-template #noLinkClientButton>
			<button
				[id]="textboxId === '' ? 'noClientLink' : 'noClientLink_' + textboxId"
				class="btn btn-outline-secondary border-0 p-0 mx-1"
				disabled
			>
				<i class="material-icons align-middle p-1">&#xE7FD;</i>
			</button>
		</ng-template>
		<ng-container>
			<button
				[id]="textboxId === '' ? 'searchClear' : 'searchClear_' + textboxId"
				type="button"
				class="btn btn-outline-secondary border-0 p-0 mx-1"
				(click)="clear()"
				[disabled]="isDisabled$ | async"
			>
				<i class="material-icons align-middle p-1">&#xE5C9;</i>
			</button>
		</ng-container>
	</div>
</ng-template>

<ng-template #searchView>
	<div class="d-flex align-items-center">
		<input
			[id]="textboxId"
			#searchBox
			type="text"
			[class]="'form-control' + ' ' + textboxClass"
			placeholder="- -"
			aria-label=""
			aria-describedby="basic-addon2"
			[disabled]="isDisabled$ | async"
			[tabindex]="tabindex"
		/>
		<button
			[id]="textboxId === '' ? 'searchQuickAdd' : 'searchQuickAdd_' + textboxId"
			*ngIf="showQuickAddIcon"
			(click)="quickAdd(searchBox.value)"
			class="btn btn-outline-secondary border-0 p-0 mx-2"
			type="button"
			[disabled]="isDisabled$ | async"
		>
			<i class="material-icons align-middle p-1">person_add</i>
		</button>
		<button
			[id]="textboxId === '' ? 'search' : 'search_' + textboxId"
			*ngIf="!isLoading; else loader"
			(click)="search(searchBox.value)"
			class="btn btn-outline-secondary border-0 p-0 mx-2"
			type="button"
			[disabled]="isDisabled$ | async"
		>
			<svg id="searchIcon">
				<title>Search Icon</title>
				<path
					d="M1.07143,5.43956c0,-1.18042 0.50808,-2.3126 1.41241,-3.14678c0.90368,-0.83478 2.13019,-1.30377 3.40902,-1.30377c1.27882,0 2.50532,0.46899 3.40901,1.30377c0.90434,0.83416 1.41242,1.96633 1.41242,3.14678c0,1.18045 -0.50808,2.3126 -1.41242,3.14678c-0.90368,0.83478 -2.13019,1.30377 -3.40901,1.30377c-1.27813,-0.00122 -2.50396,-0.47082 -3.40766,-1.305c-0.90368,-0.83417 -1.41242,-1.96571 -1.41375,-3.14553zM14.84145,12.99828l-4.31901,-3.98679c1.32057,-1.46231 1.72733,-3.45135 1.07438,-5.25702c-0.65294,-1.80567 -2.27194,-3.17043 -4.27876,-3.60754c-2.00768,-0.4372 -4.11624,0.1161 -5.57469,1.4623c-1.45845,1.34621 -2.05786,3.29263 -1.58416,5.14589c0.47364,1.85261 1.95202,3.34701 3.90816,3.94963c1.95613,0.60263 4.11093,0.22725 5.69509,-0.99174l4.319,3.98679c0.10035,0.09325 0.23749,0.14635 0.38,0.14635c0.1425,0 0.27963,-0.0531 0.37999,-0.14635c0.10102,-0.09263 0.15855,-0.21922 0.15855,-0.35076c0,-0.13155 -0.05753,-0.25813 -0.15855,-0.35077z"
				></path>
			</svg>
		</button>
	</div>

	<!-- search result -->
	<div class="" *ngIf="isShown$ | async">
		<div class="position-relative">
			<div
				class="search-result-container"
				style="position: absolute; top: 100%; left: 0; right: 0"
				*ngIf="
					choices && choices.length > 0 && !isLoading && !(isDisabled$ | async)
				"
			>
				<button
					[id]="textboxId === '' ? 'searchChoices' : 'searchChoices_' + textboxId"
					*ngFor="let c of choices"
					(click)="select(c)"
					class="btn btn-link text-dark text-left w-100"
				>
					{{ c.Name }}
				</button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #loader>
	<app-manual-loader-mini
		[isLoading]="isLoading"
		class="align-items-center d-flex"
	></app-manual-loader-mini>
</ng-template>
