import { LiabilityDetails, LiabilityInfo } from './liability.model';

export class LiabilityMapper {
	static mapToView(m: LiabilityInfo) {
		return !!m
			? {
					borrower: JSON.parse(m.Borrower),
					security: m.Security,
					liability: m.Liability,
					loanLimit: +m.LoanLimit,
					lender: m.Lender,
					loanBalance: +m.LoanBalance,
					loanRepayment: +m.LoanRepayment,
					mortgageFrequency: m.MortgageFrequency,
					interestRate: +m.InterestRate,
					paidInFull: m.PaidInFull,
					loanType: m.LoanType,
			  }
			: {};
	}
	static mapToDetails(m, adviceProcessId) {
		return {
			AdviceProcessId: adviceProcessId,
			SectionCode: 'FAL',
			Liability: m.liability,
			LoanLimit: +m.loanLimit,
			LoanBalance: +m.loanBalance,
			InterestRate: +m.interestRate,
			Borrower: !!m?.borrower
				? typeof m.borrower === 'string'
					? m?.borrower
					: JSON.stringify(m?.borrower)
				: null,
			Lender: m.lender,
			LoanRepayment: +m.loanRepayment,
			MortgageFrequency: m.mortgageFrequency,
			PaidInFull: m.paidInFull,
			LoanType: m.loanType,
			Security: m.security,
			CRTId: m?.cRTId ?? null,
			Status: 1,
			PendingAdviserApproval: m?.pendingAdviserApproval ?? false,
		};
	}
	static mapToUpsert(m: LiabilityDetails, cRTId: number) {
		return {
			adviceProcessId: m.AdviceProcessId,
			cRTId,
			interestRate: m.InterestRate,
			liability: m.Liability,
			loanBalance: m.LoanBalance,
			loanLimit: m.LoanLimit,
			loanRepayment: m.LoanRepayment,
			mortgageFrequency: m?.MortgageFrequency,
		};
	}
}
