import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LoanStore, LoanState } from './loan.store';

@Injectable({ providedIn: 'root' })
export class LoanQuery extends QueryEntity<LoanState> {

  constructor(protected store: LoanStore) {
    super(store);
  }

}
