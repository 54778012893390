import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isNil, find } from 'ramda';
import { Observable, Observer, Subject } from 'rxjs';
import {
	concatMap,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { PrimaryClientState } from 'src/app/shared/models/client-profile/primary-client/primary-client.model';
import { PropertyState } from 'src/app/shared/models/client-review-template/assets-liabilities/property.model';
import { RentalIncomeState } from 'src/app/shared/models/client-review-template/income-budget/rental-income.model';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { LoggerService } from './../../../../../core/logger/logger.service';
import { DropdownValue } from './../../../../../modules/dropdown/model/dropdownValue';
import { AssetsLiabilitiesModalComponent } from './../../../../../shared/modal/crt/fact-find/assets-liabilities/assets-liabilities.modal.component';
import { DeleteModalComponent } from './../../../../../shared/modal/delete-modal/delete-modal.component';
import { InfoModalComponent } from './../../../../../shared/modal/info-modal/info-modal.component';
import { PropertyMapper } from './../../../../../shared/models/client-review-template/assets-liabilities/property/property.mapper';
import {
	ExistingProperty,
	PropertyListItemState,
	PropertyStatus,
	PropertyUse
} from './../../../../../shared/models/client-review-template/assets-liabilities/property/property.model';
import { PropertyAssetCustomerService } from './../../../../../shared/models/services/property-asset/property-asset';
import { ViewDisplayValue } from './../../../../../shared/models/_general/display-value.viewmodel';
import { objectUtil, util } from './../../../../../util/util';
import { AssetsLiabilitiesService } from './../../states/assets-liabilities/assets-liabilities.service';

declare var $: any;

@Component({
	selector: 'app-property',
	templateUrl: './property.component.html',
	styleUrls: ['./property.component.scss'],
})
export class PropertyComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Input() isAdviceProcessEnded: boolean;

	properties$: Observable<PropertyListItemState[]> = this.query.properties$;
	@Input() dropdownCodes: DropdownValue[];
	@Input() policyOwners: ViewDisplayValue[];
	@Input() propertyAddresses: PropertyAssetCustomerService[];

	@Input() totalPropertyValues$: Observable<number>;

	su$ = this.service.SU$;
	propertyAdressChoices$: Observable<ViewDisplayValue[]>;

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;

	elseMinusProperty = true;
	isLoading = false;
	isAdding = false;
	isAddNew = false;
	submitted = false;
	isEditing = false;

	isLoading$ = this.query.isPropertiesLoading$;

	addressToolTip: string;

	propertyUse = PropertyUse;

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private service: AssetsLiabilitiesService,
		private query: ClientReviewTemplateQuery,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();
		this.propertyAdressChoices$ = this.query.propertyAddresses$.pipe(
			withLatestFrom(this.properties$),
			map(([x, properties]) => {
				const data = x
					?.filter(
						(z) =>
							!properties?.some(
								(p) => p.propertyAddress === z.PropertyAddress
							) && z?.Status === PropertyStatus.Current
					)
					?.map((y) =>
						ViewDisplayValue.Map(
							`${y.CustomerServiceID}`,
							`${y.PropertyAddress}`
						)
					);
				return data;
			})
		);
	}

	get PropertyFormList() {
		return this.form.get('properties') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			properties: this.fb.array([]),
		});
	}

	prepareData() {
		this.properties$
			.pipe(
				tap((x) => {
					this.PropertyFormList.clear();
					(!isNil(x) ? x : []).forEach((p) => {
						this.PropertyFormList?.push(this.patchValue(p));
					});
					this.form.disable();
				})
			)
			.subscribe();
	}

	patchValue(p?: PropertyListItemState): UntypedFormGroup {
		const pOwner =
			p?.owner && util.tryCatchParse(p.owner) ? JSON.parse(p.owner) : [];
		return !p
			? this.fb.group({
					cRTId: [0],
					propertyAddress: [null, Validators.required],
					propertyUse: [null],
					propertyValue: [0, Validators.required],
					owner: [[], [Validators.required]],
					customerServiceID: [null],
					rentalIncome: [null],
					rentalFrequency: [null],
					isEdit: [true],
					isLoading: [false],
					isNew: [true],
			  })
			: this.fb.group({
					cRTId: [p.cRTId],
					propertyAddress: [p.propertyAddress, Validators.required],
					propertyUse: [p.propertyUse],
					propertyValue: [p.propertyValue, Validators.required],
					owner: [[...pOwner], [Validators.required]],
					customerServiceID: [p.customerServiceID],
					rentalIncome: [p.rentalIncome],
					rentalFrequency: [p.rentalFrequency],
					isEdit: [false],
					isLoading: [false],
					isNew: [false],
			  });
	}

	policyOwnerChoices = (owners: (string | number)[]) => {
		return this.service.getOwnerChoices(owners, this.policyOwners);
	};

	disableFormItem(index: number) {
		this.setEdit(index, false);
		this.PropertyFormList.controls[index].disable();
	}

	enableFormItem(index: number) {
		this.PropertyFormList.controls[index].enable();
		this.setEdit(index, true);
	}

	setEdit(index: number, isLoading: boolean) {
		this.PropertyFormList.controls[index].get('isEdit').setValue(isLoading);
	}

	setLoading(index: number, isLoading: boolean) {
		this.PropertyFormList.controls[index]?.get('isLoading').setValue(isLoading);
	}

	collapseFalse() {
		this.elseMinusProperty = false;
	}

	collapseMoreProperty() {
		$('#collapseProperty').toggle();
		this.elseMinusProperty = false;
	}

	collapseLessProperty() {
		this.elseMinusProperty = true;
	}

	trackByFn(_index: number, customerServiceID: number) {
		return customerServiceID;
	}

	saveNew$ = (model) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) =>
				PropertyMapper.mapToPropertyDetails(
					x,
					this.query.getValue().adviceProcessId
				)
			),
			mergeMap((x) =>
				this.service.addAL(x, AdviceProcessSectionCodes.Property)
			),
			concatMap((x) =>
				this.service
					.getPropertyAddresses$(
						this.query.getValue()?.primaryClient?.customerID,
						true
					)
					.pipe(map(() => x))
			),
			finalize(() => {
				this.isAddNew = false;
				this.prepareData();
			})
		);

	onSelectProperty(e: string, i: number) {
		if (e === 'new') {
			const currentProperties: PropertyListItemState[] =
				this.query.getValue().properties;
			const hasFromCRT: PropertyListItemState = find(
				(property: PropertyListItemState) => !property.fromCRM,
				currentProperties
			);
			const propertyInfo = { PropertyAddress: null };

			if (!hasFromCRT) {
				const pciAddress = (
					this.query.getValue().primaryClient as PrimaryClientState
				).physicalAddress;
				propertyInfo.PropertyAddress = pciAddress;
			}

			const decline = new Observable((obs: Observer<any>) => {
				this.PropertyFormList.at(i).get('propertyAddress').setValue('');
				obs.complete();
			});
	
			const initState = {
				header: 'Property Details',
				message: `Property`,
				policyOwners:  this.policyOwners,
				getOwnerChoices: this.service.getOwnerChoices,
				su$: this.service.SU$,
				sv1$: this.service.SV1$,
				st$: this.service.ST$,
				sti$: this.service.STI$,
				apcrtf$: this.service.APCRTF$,
				saveFn$: this.saveNew$,
				decline$: decline,
				crtInfo: propertyInfo,
			};
			this.bsModalRef = this.modalService.show(
				AssetsLiabilitiesModalComponent,
				{
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				}
			);
		} else {
			const selected = this.propertyAddresses.find(
				(x) => x.CustomerServiceID === +e
			);
			const owners = !!selected.PropertyOwner &&
				(JSON.parse(selected.PropertyOwner) as Array<string | number>).length >
					0
					? JSON.parse(selected.PropertyOwner)
					: [];

			this.PropertyFormList.controls[i].reset({
				cRTId: 0,
				propertyAddress: selected.PropertyAddress,
				propertyUse: selected.Use,
				propertyValue: !!selected?.GRM1Value ? selected?.GRM1Value : 0,
				owner: owners,
				customerServiceID: selected.CustomerServiceID,
				rentalIncome: selected?.RentalIncome,
				rentalFrequency: selected?.RentalFrequency,
				isEdit: true,
				isLoading: false,
			});
		}
	}

	saveProperty(index: number) {
		this.submitted = true;
		if (this.PropertyFormList.controls[index].invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		this.PropertyFormList.controls[index].get('isLoading').setValue(true);
		const property = this.PropertyFormList.controls[index].value;
		const propertyCrmDetails = this.propertyAddresses?.find(
			(x) => x.CustomerServiceID === property.customerServiceID
		);

		const p: ExistingProperty = {
			...propertyCrmDetails,
			PropertyAddress: property.propertyAddress.toString(),
			PropertyValue: +property.propertyValue,
			PropertyUse: property.propertyUse?.toString(),
			PropertyOwner: JSON.stringify(property.owner),
		};

		const data = PropertyMapper.mapToPropertyDetailsExisting(
			p,
			this.query.getValue().adviceProcessId
		);

		// Disable form item upon clicking save.
		this.disableFormItem(index);
		this.service
			.addAL(data, AdviceProcessSectionCodes.Property, true)
			.pipe(take(1))
			.subscribe(
				() => {
					this.setLoading(index, false);
					this.submitted = false;
					this.isAddNew = false;
				},
				() => {
					// Enable form if something went wrong while saving.
					this.enableFormItem(index);
					this.setLoading(index, false);
					this.submitted = false;
				},
				() => {
					this.isAddNew = false;
					this.submitted = false;
					this.setEdit(index, false);
					this.setLoading(index, false);
					this.buildForm();
					this.prepareData();
				}
			);
	}

	addProperty() {
		this.isAddNew = true;
		this.PropertyFormList?.push(this.patchValue());
	}

	updateFn$ = (model) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) =>
				PropertyMapper.mapToPropertyDetails(
					x,
					this.query.getValue().adviceProcessId
				)
			),
			mergeMap((x) =>
				this.service.updateAL(x, AdviceProcessSectionCodes.Property)
			),
			tap(() => {
				this.isAddNew = false;
				this.prepareData();
			})
		);

	editProperty(id: number, index: number) {
		this.isEditing = true;
		this.PropertyFormList.controls[index].get('isLoading').setValue(true);
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		this.service
			.getCrtById(id)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.PropertyFormList.controls[index]
						.get('isLoading')
						.setValue(false);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x: any) => {
				const initState = {
					header: 'Property Details',
					message: `Property`,
					policyOwners: this.policyOwners,
					getOwnerChoices: this.service.getOwnerChoices,
					su$: this.service.SU$,
					sv1$: this.service.SV1$,
					st$: this.service.ST$,
					sti$: this.service.STI$,
					apcrtf$: this.service.APCRTF$,
					crtInfo: x,
					saveFn$: this.updateFn$,
					decline$: decline,
				};

				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	viewProperty(id: number, index: number) {
		this.isEditing = true;
		this.PropertyFormList.controls[index].get('isLoading').setValue(true);
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		this.service
			.getCrtById(id)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.PropertyFormList.controls[index]
						.get('isLoading')
						.setValue(false);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => {
				const initState = {
					header: 'Property Details',
					message: `Property`,
					policyOwners: this.policyOwners,
					getOwnerChoices: this.service.getOwnerChoices,
					su$: this.service.SU$,
					sv1$: this.service.SV1$,
					st$: this.service.ST$,
					sti$: this.service.STI$,
					apcrtf$: this.service.APCRTF$,
					crtInfo: x,
					decline$: decline,
					viewMode: true
				};
				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	removeProperty(index: number) {
		this.submitted = false;
		this.isAddNew = false;
		this.PropertyFormList.removeAt(index);
	}

	deleteProperty(index: number, remove?: boolean) {
		this.setLoading(index, true);
		const cRTId = this.PropertyFormList.controls[index].get('cRTId').value;
		const adviceProcessId = +this.query.getValue().adviceProcessId;

		this.service
			.getCrtById(cRTId)
			.pipe(
				map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
				map((x) => PropertyMapper.mapToPropertyDetails(x, adviceProcessId)),
				map((x) => ({
					...x,
					propertyStatus: !remove ? 'Sold' : '',
					CRTId: cRTId,
					status: 0,
				})),
				mergeMap((x) =>
					this.service.updateAL(x, AdviceProcessSectionCodes.Property)
				),
				tap(() =>
					this.service.deleteState(cRTId, AdviceProcessSectionCodes.Property)
				),
				take(1)
			)
			.subscribe(
				() => {
					this.isAddNew = false;
					this.setLoading(index, false);
					this.prepareData();
				},
				() => {
					this.isAddNew = false;
					this.setLoading(index, false);
					this.prepareData();
				}
			);
	}

	handleDelete(index: number) {
		const rentalDetails = this.query.getValue().rentalDetails;
		const propertyToDelete =
			+this.PropertyFormList.controls[index].get('cRTId').value;
		const propertyExistsInRental = rentalDetails.netRentalIncomeList?.find(
			(rentalIncome: RentalIncomeState) =>
				rentalIncome.propertyAsset === propertyToDelete
		);
		if (propertyExistsInRental) {
			this.existsInRentalWarning(index);
		} else {
			this.deleteModal(index);
		}
	}

	existsInRentalWarning(index: number) {
		const close = new Observable((obs: Observer<any>) => {
			this.setLoading(index, false);
			obs.complete();
		});

		const initState = {
			header: 'Property in use',
			message: `This Property is being included in the Rental Income section. Please remove it before deleting.`,
			close$: close,
		};

		this.modalService.show(InfoModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteModal(index: number) {
		this.setLoading(index, true);
		const confirm = new Observable((obs: Observer<any>) => {
			this.setLoading(index, false);
			this.deleteProperty(index);
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.setLoading(index, false);
			this.deleteProperty(index, true);
			obs.complete();
		});
		const close = new Observable((obs: Observer<any>) => {
			this.setLoading(index, false);
			obs.complete();
		});

		const initState = {
			header: 'Delete Property',
			message: `Has this property been sold or removed from this Advice process?`,
			delete$: confirm,
			decline$: decline,
			close$: close,
			canDelete: true,
			confirmButton: 'Sold',
			confirmButton2: 'Remove',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	isEllipsisActive(value: PropertyState) {
		this.addressToolTip = value.propertyAddress;
	}
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
