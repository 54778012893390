<div class="position-relative">
	<ng-select
		#ngSelect
		*ngIf="!readonly"
		[items]="items$ | async"
		[tabIndex]="tabindex"
		[multiple]="multiple"
		[attr.class]="className"
		[attr.disabled]="disabled ? '' : null"
		[closeOnSelect]="!!closeOnSelect"
		[clearable]="clearable"
		[clearOnBackspace]="true"
		[hideSelected]="true"
		[openOnEnter]="true"
		[bindLabel]="displayProperty"
		[bindValue]="valueProperty"
		[labelForId]="id"
		[ngModel]="value"
		[readonly]="disabled"
		[trackByFn]="trackByFn"
		[placeholder]="placeholder"
		[inputAttrs]="{ class: 'form-control' }"
		[dropdownPosition]="dropdownPosition"
		[compareWith]="compareWith"

		(add)="onAdd($event)"
		(change)="onChange($event)"
		(focus)="onFocus($event)"
		(blur)="onBlur($event)"
		(remove)='onRemove($event)'
	>
		<ng-template ng-option-tmp let-item="item" let-search="searchTerm">
			<div
				class="cp-ng-option"
				[ngClass]="{ 'cp-not-selectable': item.selectable === false }"
			>
				{{ item[displayProperty] }}
			</div>
		</ng-template>

		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
			<div
				class="chips-selected-item d-flex align-items-center"
				*appInputChipsFor="let item of items; showAll: ngSelect.focused"
			>
				<div class="chips-selected-item-text text-nowrap">
					{{ item[displayProperty] }}
				</div>
				<span
					(click)="clear(item)"
					(mousedown)="clear(item)"
					class="chips-selected-item-clear d-flex align-items-center justify-content-center z-10"
				>
					<span class="material-icons"> close </span>
				</span>
			</div>

			<div
				class="input-chips-badge badge text-nowrap bg-light text-center"
				*ngIf="inputChipsFor?.overFlowingItems.length"
				[tooltip]="tooltipTemplate"
				container="body"
			>
				{{ inputChipsFor?.overFlowingItems.length }} more
			</div>
		</ng-template>
	</ng-select>
	<ng-template #tooltipTemplate> {{ overflowingTags }} </ng-template>
</div>
