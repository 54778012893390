import {
	Component,
	OnInit,
	OnDestroy,
	NgZone,
	HostListener,
	ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmRoutingModule } from '@modules/crm/crm-routing.module';
import { SharedModule } from '@shared/shared.module';
import { UserQuery } from '@domain/user/user.query';
import { ConfigService } from '@core/config/config.service';
import { RouteService } from '@core/config/route.service';
import { map, takeUntil, take, filter, mergeMap, startWith } from 'rxjs/operators';
import { Observable, Subject, of, combineLatest } from 'rxjs';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { AnnouncementPopupService } from '@modules/annoucements/shared/announcement-popup/announcement-popup.service';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { QuickAddComponent } from './quick-add/quick-add.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { ViewportSizeDirective, ResponsiveDirective } from '@shared/directive/responsive';
import { MatIconModule } from '@angular/material/icon';
import { GlobalSearchV2Module } from '@crm/global-search-v2/global-search-v2.module';
import { HeaderService } from './header.service';
import { environment as env } from '@environment';
import { TooltipV2Directive } from '@shared/directive/tooltip/tooltip-v2.directive';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocalService } from '@core/services/local.service';
import { TooltipV2Component } from '@shared/directive/tooltip/tooltip-v2.component';

const DEFAULT_LOGO_URL = `${env.defaultImgUrl}/logo.png`;

@Component({
	selector: 'app-header-v2',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
  imports: [
    HeaderNavComponent,
		QuickAddComponent,
		UserAccountComponent,
		ResponsiveDirective,
    ViewportSizeDirective,
    CrmRoutingModule,
    CommonModule,
    SharedModule,
		GlobalSearchV2Module,
    MatIconModule,
		TooltipV2Directive,
		TooltipV2Component
  ],
  standalone: true
})
export class HeaderComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	screenSize: number;
  public currentSelectedMenuItem: string;
  
	searchReworkFeature$ = this.businessConfigQuery.searchReworkFeature$;
	private routeChanges$ = this.routeService.RouteChange$;
	private isTapLevelView$ = this.configService.IsViewTapLevel;
	public dashboardLink$ = this.routeChanges$.pipe(map((x) => x.dashboard));
	public logoUrl$ = combineLatest([
		this.businessConfigQuery.businessLogoUrl$,
		this.isTapLevelView$,
		of(DEFAULT_LOGO_URL),
	]).pipe(
		map(([url, isTapView, defaultUrl]) => {
			if (isTapView) {
				return defaultUrl;
			} else {
				return url;
			}
		})
	);

	breadcrumb: string;

	toggleNav: boolean;
	AT$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('AT');
	/** Adviser choices */
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	/** Adviser calendar choices */
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;
	constructor(
		private userQuery: UserQuery,
		private configService: ConfigService,
		private routeService: RouteService,
		private businessConfigQuery: BusinessConfigQuery,
		private route: ActivatedRoute,
		private router: Router,
		private ngZone: NgZone,
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private el: ElementRef,
		private announcementPopupService: AnnouncementPopupService,
		private headerService: HeaderService,
		private modalService: BsModalService,
		private localService: LocalService
	) {}

	@HostListener('document:click', ['$event'])
	onClick(event) {
		const clickedElement = event.target as HTMLElement;
		if (
			!this.el.nativeElement.contains(clickedElement) || 
			clickedElement.classList.contains('menu-item-selected')
		) {
			this.handleToggleNav(false);
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.screenSize = window.innerWidth;
	}

	ngOnInit() {
		this.userQuery
			.select()
			.pipe(
				take(1),
				mergeMap(({ StaffID: staffId }) => {
					return staffId
						? this.announcementPopupService.show(staffId)
						: of(true);
				})
			)
			.subscribe();

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					return this.findBreadcrumb();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe((breadcrumb: string | null) => {
				this.breadcrumb = breadcrumb;
			});
		this.breadcrumb = this.findBreadcrumb(); // Initialize value

		this.screenSize = window.innerWidth;
		
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	navigateRoute(link) {
		return this.ngZone.run(() => link);
	}

	handleToggleNav(toggle) {
		this.toggleNav = toggle;
		this.headerService.setMenuState(toggle);
	}

	showAnnouncementHeader$ = this.router.events.pipe(
		startWith(null),
		filter((event) => event instanceof NavigationEnd || event === null),
		map(() => this.router.url.startsWith('/admin/announcements')),
	);

	/**
	 * Get breadcrumb from route data
	 * @return string | null
	 */
	findBreadcrumb(): string | null {
		let child = this.route.firstChild;
		if(child?.snapshot?.data?.isTapLevelView) {
			this.currentSelectedMenuItem = 'tap'
		} else {
			this.currentSelectedMenuItem = child?.snapshot?.routeConfig?.path;
		}
		
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else if (child.snapshot.data && child.snapshot.data.breadcrumb) {
				if (
					child.parent &&
					child.parent.snapshot.data &&
					child.parent.snapshot.data.breadcrumb &&
					child.parent.snapshot.data.ignoreChildBreadcrumb
				) {
					return child.parent.snapshot.data.ignoreChildBreadcrumb
						? child.parent.snapshot.data.breadcrumb
						: child.snapshot.data.breadcrumb;
				}
				return child.snapshot.data.breadcrumb;
			} else {
				return null;
			}
		}
		return null;
	}

	createGlobalSearchModal(){
		const initState = {
			isModal: true,
			isFocused: true,
			showMostRecentCount: 10,
			onClose: () => this.modalService.hide()
		};
		this.modalService.show(GlobalSearchComponent, {
      class: `modal-global-search modal-dialog-centered ${
        this.localService.getValue('loginType') === 'microsoft'
          ? 'modal-dialog-outlook-xl'
          : 'modal-xl'
      }`,
      initialState: initState,
      ignoreBackdropClick: false,
      keyboard: false
    });
	}
}