import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BlStaffModel } from '@models/_general/bl-staff.model';

export interface ManageUsersListState
	extends EntityState<BlStaffModel, number> {
	rangeIDs: { [key: string]: number[] };
	setRangeData: (
		data: ManageUsersListState[],
		startId: number,
		endId: number
	) => void;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ManageUsersList', idKey: 'StaffID' })
export class ManageUsersListStore extends EntityStore<ManageUsersListState> {
	constructor() {
		super({
			rangeIDs: {},
		});
	}

	setRangeData(data: any[], startIndex: number, endIndex: number): void {
		this.update((state) => {
			const newRangeIDs = {};
			newRangeIDs[`${startIndex}-${startIndex + endIndex}`] = data.map(
				(d) => d[this.idKey]
			);
			return {
				...state,
				rangeIDs: {
					...state.rangeIDs,
					...newRangeIDs,
				},
			};
		});
		this.add(data);
	}

	resetRangeData(): void {
		this.update((state) => {
			return {
				...state,
				...{
					rangeIDs: {},
				},
			};
		});
		this.reset();
	}
}
