import { Breakpoints } from "@angular/cdk/layout";

export const View = {
  Small: 'mobile-view',
  Medium: 'tablet-view',
  Large: 'desktop-view'
};

export type ScreenSize = "small" | "medium" | "large";
export const HeaderBreakpoints = {
  XMediumNarrow: '(min-width: 661px)',
  MediumNarrow: '(max-width: 1030px)',
}
export const CrmBreakpoints = {
  Small: '(max-width: 660px)',
  Medium: '(max-width: 1136px)',
  Large: '(min-width: 1137px)'//'(min-width: 1280px)'
};

export const breakpointConfig = {
  small: [CrmBreakpoints.Small],
  medium: ['(min-width: 661px)', CrmBreakpoints.Medium],
  large: [CrmBreakpoints.Large]
};