import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, zip, of, Observer } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PropertyAssetCustomerServiceState } from '../../models/services/property-asset/property-asset';
import { PropertyAssetMapper } from '../../models/services/property-asset/property-asset.mapper';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { strUtil } from '../../../util/util';
import { ArchiveModalComponent } from '../../modal/archive-modal/archive-modal.component';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import { take, map, filter, tap, takeUntil } from 'rxjs/operators';
import { patchValue } from '../service-utils/service.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from '../../date-input/date-input.component';
import { Fields, getInvalidWarning, getRequiredWarning } from '../../error-message/error-message';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ComponentBase } from '@core/base/component-base';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { ServicesCodes } from '@shared/models/services/services.model';

@Component({
	selector: 'app-property-assets-form',
	templateUrl: './property-assets-form.component.html',
	styleUrls: ['./property-assets-form.component.scss'],
})
export class PropertyAssetsFormComponent
	extends ComponentBase
	implements OnInit, OnChanges, OnDestroy
{
	public bsModalRef: BsModalRef;

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() archiveEvent = new EventEmitter<any>();
	@Output() deleteNoteEvent = new EventEmitter<any>();
	@Output() cancelAddEvent = new EventEmitter<boolean>();
	@Output() addNoteEvent = new EventEmitter<any>();

	@Input() isLead: boolean;
	@Input() isCompany: boolean;
	@Input() addMode: boolean;
	@Input() isSaving: boolean;

	@Input() formId: string;
	@Input() propertyAsset: PropertyAssetCustomerServiceState;

	@Input() propertyOwners: ViewDisplayValue[];
	@Input() valuations1: ViewDisplayValue[];
	@Input() valuations2: ViewDisplayValue[];
	@Input() types: ViewDisplayValue[];
	@Input() status: ViewDisplayValue[];
	@Input() uses: ViewDisplayValue[];
	@Input() titles: ViewDisplayValue[];
	@Input() groupName: string;

	@ViewChild('gRM1DateInput') gRM1DateInput: DateInputComponent;
	@ViewChild('gRM2DateInput') gRM2DateInput: DateInputComponent;
	@ViewChild('purchaseDateInput') purchaseDateInput: DateInputComponent;

	notes: any[];
	propertyOwnersDropdown: ViewDisplayValue[];
	form: UntypedFormGroup = this.fb.group({
		propertyAddress: ['', [Validators.required, NoWhitespaceValidator]],
		propertyOwner: '',
		valuation1: '',
		valuation2: '',
		gRM1Value: null,
		gRM1Date: '',
		gRM2Value: null,
		gRM2Date: '',
		type: '',
		title: '',
		rentalIncome: null,
		purchaseDate: '',
		use: '',
		customerServiceID: '',
		note: '',
		isActive: 1,
		notes: [],
		serviceCode: '',
		status: ''
	});

	get propertyAddress() {
		return this.form.get('propertyAddress');
	}
	get propertyOwner() {
		return this.form?.get('propertyOwner');
	}

	isEditForm = false;

	noteHeader = [
		{
			title: 'Created By',
		},
		{
			title: 'Created date & Time',
		},
		{
			title: 'Details',
		},
		{
			title: ' ',
		},
	];

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private loggerService: LoggerService,
		private clientService: ClientProfileService,
		private businessService: BusinessProfileService
	) {
		super();
	 }

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.isSaving && !this.isSaving) {
				this.prepData();
			}

			if (changes.propertyAsset) {
				this.isSaving = false;
				this.prepData();
			}
		}
		this.setPropertyOwners();
	}

	ngOnInit() {
		this.addMode ? this.form.enable() : this.form.disable();

		if (this.addMode) {
			zip(of(this.valuations1), of(this.valuations2), of(this.types), of(this.uses), of(this.titles))
				.pipe(
					take(1),
					map(ddListList => {
						const defaultValueList: string[] = ddListList
							?.map(ddList => ddList?.find(dd => dd.isDefault))
							?.map(def => def && def.value);
						return defaultValueList;
					})
				)
				.subscribe(this.setDropdownDefaults);
		}

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap(() => this.setPropertyOwners()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	refresh() {
		this.cd.detectChanges();
	}

	setPropertyOwners() {
		this.propertyOwnersDropdown = this.getOwnerChoices(this.propertyOwner.value);
	}

	getOwnerChoices(owners: (string | number)[]) {
		if (this.isCompany) {
			return this.businessService.getOwnerChoices(owners || [], this.propertyOwners);
		}
		return this.clientService.getOwnerChoices(owners || [], this.propertyOwners);
	}

	prepData() {
		this.notes =
			this.propertyAsset && this.propertyAsset.notes && this.propertyAsset.notes.length > 0
				? this.propertyAsset.notes
				: null;

		this.form.reset(PropertyAssetMapper.mapToView(this.propertyAsset));
	}

	formSaving(isSaving: boolean) {
		this.isSaving = isSaving;
		this.refresh();
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([sv1, sv2, st, su, sti]) => {
		patchValue<any>(this.form, {
			valuation1: sv1,
			valuation2: sv2,
			type: st,
			use: su,
			title: sti,
			serviceCode: ServicesCodes.Security,
		});
	}

	archive(isArchive) {
		const msg = this.propertyAsset.isActive === 1 ? 'archive' : 'unarchive';
		const initState: any = {
			confirm$: new Observable(obs => {
				const form = this.form.value;
				form.note = strUtil.safeTrim(form.note);
				const data = PropertyAssetMapper.mapToUpsert(form);

				this.archiveEvent.emit({ propertyAsset: data, isArchive });
				obs.complete();
			}),
			header: 'Archive service',
			message: `Are you sure you want to ${msg} this item?`,
			isArchive: isArchive ? true : false,
		};
		this.modalService.show(ArchiveModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
	}

	save() {
		if (!this.form.valid) {
			if (this.form.value.propertyAddress?.trim() === '' || !this.form.value.propertyAddress) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.PropertyAddress));
				return;
			}
		}

		if (this.gRM1DateInput?.isInvalid()) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.GRM1Date));
			return;
		}

		if (this.gRM2DateInput?.isInvalid()) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.GRM2Date));
			return;
		}

		if (this.purchaseDateInput?.isInvalid()) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.PropertySettlementDate));
			return;
		}

		if (isNaN(this.form.value.gRM1Value)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.Valuation1));
			return;
		}

		if (isNaN(this.form.value.gRM2Value)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.Valuation2));
			return;
		}

		if (isNaN(this.form.value.rentalIncome)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.RentalIncome));
			return;
		}

		if (this.addMode) {
			this.isSaving = true;
		}

		const form = this.form.value;
		this.editForm(false);
		form.customerServiceID = this.propertyAsset ? this.propertyAsset.customerServiceID : '';
		form.isActive = this.addMode ? 1 : this.propertyAsset.isActive;
		form.note = form.note;

		const data = PropertyAssetMapper.mapToUpsert(form);

		this.saveEvent.emit(data);
	}

	cancel() {
		if (this.addMode) {
			const confirm = new Observable((obs: Observer<any>) => {
				if (this.addMode) {
					this.cancelAddEvent.emit(false);
				}
				this.isEditForm = false;
				this.prepData();
				this.form.disable();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: 'Discard Confirmation',
				message: `Current information will be discarded?`,
				confirm$: confirm,
				decline$: decline,
			};

			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false
			});
		} else {
			this.isEditForm = false;
			this.prepData();
			this.form.disable();
		}
	}

	editForm(edit: boolean) {
		this.isEditForm = edit;
		edit ? this.form.enable() : this.form.disable();
	}

	delete() {
		this.isSaving = true;
		const form = this.form.value;
		const data = PropertyAssetMapper.mapToUpsert(form);

		this.deleteEvent.emit(data);
	}

	deleteConfirm(doc: any) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const initState = {
			header: 'Delete',
			message: `Are you sure you want to delete ?`,
			delete$: confirm,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
	}

	deleteNote$ = data => {
		return new Observable(obs => {
			this.isSaving = true;
			const form = this.form.value;
			const mappedForm = PropertyAssetMapper.mapToUpsert(form);
			this.deleteNoteEvent.emit({ noteId: data.notesID, data: mappedForm });
			obs.next();
			obs.complete();
		});
	}

	openModalAddNote() {
		const saveNote = (notes: string) =>
			new Observable(obs => {
				const form = PropertyAssetMapper.mapToView(this.propertyAsset);
				form.note = notes;
				this.addNoteEvent.emit(form);
				obs.next();
				obs.complete();
			});
		const initState: any = {
			header: 'Add Note',
			savefn: saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
	}

	trackByValue(index, item: ViewDisplayValue) {
		return item.value;
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
