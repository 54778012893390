import { util } from '../../../../../../util/util';
import {
	contentEditable,
	getContentWithMergeTags,
} from '../../../../../converter/content-merge-tags';
import {
	soaSosPages,
	sosMergeTags,
	sosMetaKey,
	sosOtherMt,
} from './sos.merge-tag';
import { sosMtTemplate as mtTemplate } from './sos.template';
import { MergeTagsMapper } from '../../merge-tags.mapper';
import { MergeTagState } from '../../merge-tags.model';
import { ScopeOfServiceState } from '../../../scope-of-service/scope-of-service.model';

export class SosMergeTagsMapper {
	public static cleanTemplate(template: string) {
		// Remove \n and \t from content string
		return template?.replace(/\n|\t/g, '') || '';
	}

	public static getTemplate(template: string, mergeTag: MergeTagState[]) {
		// Get template for each insurance merge tag
		const content = this.cleanTemplate(template);
		return getContentWithMergeTags(content, mergeTag) ?? '';
	}

	public static getTextAreaContent(data: any, mt: MergeTagState[]) {
		// Parse content from textareas
		const content = util.tryCatchParse(data) ? JSON.parse(data) : data;
		return getContentWithMergeTags(content, mt, true) ?? '';
	}

	public static parseValues(data = []) {
		// Parse merge tag values
		return (
			data?.reduce((a, c) => {
				const newVal = util.tryCatchParse(c) ? JSON.parse(c) : c;
				return [...a, newVal];
			}, []) || []
		);
	}

	/**
	 * Toggle of Pages under Scope of Services
	 */
	public static toggleSosPages(template: string, sos: any) {
		const sections = Object.keys(soaSosPages) || [];
		const content = this.cleanTemplate(template);

		return sections?.reduce((a, c) => this.hideShowDiv(c, a, sos), content);
	}

	public static hideShowDiv(key: string, content: string, sos: any) {
		const id = 'hideShowDiv';
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${id}">${content}</div>`);

		newHtml.querySelectorAll(`#${key}`).forEach((e: HTMLElement) => {
			e.style.display = +sos[key] === 1 ? 'block' : 'none';
		});

		return newHtml.querySelector(`#${id}`).innerHTML || '';
	}

	/**
	 * Remove existing DIV wrapper for SOS Pages
	 * @param key : KeyName from soaSosPages object <string>
	 * @param content : HTML String content <string>
	 * @param isLast : Check if index is from SOS Page is Last <boolean>
	 * @returns : Updated HTML content <string>
	 */
	public static cleanDivPageWrapper(
		key: string,
		content: string,
		isLast: boolean
	) {
		let newContent = content;
		const regexCode = isLast
			? `(?:<div id="${key}")(?=.* style="[^>]*">)(.*?)(?:<\/div>)(?=<hr[^>]*>)`
			: `(?:<div id="${key}")(?=.* style="[^>]*">)(.*?)(?:<\/div>)(?=<div id="[^>]*>)`;

		const regExSosInc = new RegExp(regexCode, 'g');
		const checkSosInc = content?.match(regExSosInc);

		if (checkSosInc?.length > 0) {
			newContent = checkSosInc?.reduce((acc, cur) => {
				const regExInner = new RegExp(
					`(?:<div id="${key}"[^>]*>)(.*?)(?=<\/div>)`,
					'g'
				);
				const checkInner = content?.match(regExInner);
				const newVal = checkInner?.reduce((a, c) => {
					let val = c?.replace(`<div id="${key}">`, '');
					val = val?.replace(`<div id="${key}" style="display:none;">`, '');
					val = val?.replace(`<div id="${key}" style="display:block;">`, '');
					return val;
				}, cur);
				return acc?.replace(cur, newVal);
			}, newContent);
		}
		return newContent;
	}

	/**
	 * Add DIV wrapper for SOS Pages with hide/show attr
	 * @param key : KeyName from soaSosPages object <string>
	 * @param content : HTML String content <string>
	 * @param sos : Scope of Services <Object>
	 * @returns : Updated HTML content <string>
	 */
	public static addDivPageToggle(key: string, content: string, sos: any) {
		let newContent = content;
		const regExSosInc = new RegExp(
			`(?:<hr[^>]*><h1[^>]*>)(<[^>]*>+)?(${soaSosPages[key]})(.*?)(?=<hr[^>]*>)`,
			'g'
		);
		const checkSosInc = content?.match(regExSosInc);

		if (checkSosInc?.length > 0) {
			newContent = checkSosInc?.reduce((acc, cur) => {
				const toShow = +sos[key] === 1 ? 'block' : 'none';
				const newVal = `<div id="${key}" style="display:${toShow};">${cur}</div>`;
				return acc?.replace(cur, newVal);
			}, newContent);
		}
		return newContent;
	}

	/**
	 * Scope of Service Page Merge Tags
	 * @param content : HTML String content
	 * @returns : Updated HTML content <string>
	 */
	public static revertSosMergeTags(content: string) {
		// Convert back all dynamic SOS sections to Merge Tag Codes
		let newContent = content || '';

		// SOS Services included list
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.servicesIncludedList,
			`(?:<div ${contentEditable.false} id="${sosMetaKey.servicesIncludedList}"[^>]*>)(.*?)(?:<\/div>)`
		);

		// SOS Services excluded list
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.servicesExcludedList,
			`(?:<div ${contentEditable.false} id="${sosMetaKey.servicesExcludedList}"[^>]*>)(.*?)(?:<\/div>)`
		);

		// SOS Notes text
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.notesText,
			`(?:<div ${contentEditable.false} id="${sosMetaKey.notesText}"[^>]*>)(.*?)(?:<\/div>)`
		);

		// SOS Other advise list
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.otherAdviseList,
			`(?:<div ${contentEditable.false} id="${sosMetaKey.otherAdviseList}"[^>]*>)(.*?)(?:<\/div>)`
		);

		// SOS Intro text
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.introText,
			`(?:<div ${contentEditable.false} id="${sosMetaKey.introText}"[^>]*>)(.*?)(?=<\/div>)(?:<\/div>)`
		);

		return newContent;
	}

	/**
	 * Get Dynamic SOS Merge Tags
	 * @param mergeTags : MergeTagState[]
	 * @param sosCrt : CRT > SOS Object
	 * @param sosSettings : SOS Settings Object
	 * @returns : Updated list of merge tags with updated SOS
	 */
	public static getSosMergeTags(
		mergeTags: MergeTagState[] = [],
		sosCrt: ScopeOfServiceState,
		sosSettings: ScopeOfServiceState
	) {
		// Get converted sections
		const updatedMT = this.getUpdatedMergeTag(mergeTags, sosCrt, sosSettings);

		return (
			sosMergeTags?.map((i) => {
				let value = '';
				if (i?.metaKey === sosMetaKey.introText) {
					// re-update intro for merge tags inside merge tags
					const intro = this.getTemplate(mtTemplate.sosIntro, updatedMT);
					value = this.getTemplate(intro, updatedMT);
				} else if (i?.metaKey === sosMetaKey.servicesIncludedList) {
					value = this.getTemplate(mtTemplate.sosIncluded, updatedMT);
				} else if (i?.metaKey === sosMetaKey.servicesExcludedList) {
					value = this.getTemplate(mtTemplate.sosExcluded, updatedMT);
				} else if (i?.metaKey === sosMetaKey.notesText) {
					value = this.getTemplate(mtTemplate.sosNotes, updatedMT);
				} else if (i?.metaKey === sosMetaKey.otherAdviseList) {
					value = this.getTemplate(mtTemplate.sosOtherAdvice, updatedMT);
				}
				return {
					...i,
					value,
				};
			}) ?? []
		);
	}

	public static getUpdatedMergeTag(
		data: MergeTagState[],
		soaCrt: ScopeOfServiceState,
		sosSettings: ScopeOfServiceState
	) {
		const updatedMT = this.updateSosMergeTags(data || [], soaCrt, sosSettings);

		return updatedMT?.map((i) =>
			i?.metaKey === sosMetaKey.intro
				? { ...i, value: this.parseValues(i?.value) }
				: i
		);
	}

	/**
	 * Update all SOS related merge tag values based from state
	 * @param mt: MergeTagState[]
	 * @param soaCrt: CRT > SOS Object
	 * @param sosSettings: SOS Settings Object
	 * @returns : Updated list of merge tags with updated SOS
	 */
	public static updateSosMergeTags(
		mt: MergeTagState[],
		soaCrt: ScopeOfServiceState,
		sosSettings: ScopeOfServiceState
	) {
		if (soaCrt && mt) {
			const mergeTags = mt?.map((i) => {
				let item = i;
				if (i?.metaKey === sosMetaKey.intro) {
					item.value = MergeTagsMapper.updateNewLine([
						this.getTextAreaContent(sosSettings?.intro, mt),
					]);
				} else if (i?.metaKey === sosMetaKey.servicesIncluded) {
					item.value = this.getServices(soaCrt, 1);
				} else if (i?.metaKey === sosMetaKey.servicesExcluded) {
					item.value = this.getServices(soaCrt, 0);
				} else if (i?.metaKey === sosMetaKey.servicesExcludedNotes) {
					item.value = MergeTagsMapper.updateNewLine(
						this.getServicesNotes(soaCrt, 0)
					);
				} else if (i?.metaKey === sosMetaKey.notes) {
					item.value = MergeTagsMapper.updateNewLine([
						this.getTextAreaContent(soaCrt?.instructionOrLimitation, mt),
					]);
				} else if (i?.metaKey === sosMetaKey.otherAdvice) {
					item.value = MergeTagsMapper.updateNewLine(
						this.getOtherAdvice(soaCrt, 1)
					);
				}
				return item;
			});

			const sosOtherAdviceNotesMt = sosOtherMt?.map((i) => {
				if (i?.metaKey === sosMetaKey.otherAdviceNotes) {
					i.value = this.parseValues(
						this.getOtherAdviceNotes(soaCrt, sosSettings)
					);
				}
				return i;
			});

			return [...mergeTags, ...sosOtherAdviceNotesMt];
		}
		return mt || [];
	}

	public static getServices(sosData: ScopeOfServiceState, status: number) {
		// Get services wherein their status value === status param
		const newData = [];
		if (sosData?.lifeInsurance === status) {
			newData.push('Life Insurance');
		}
		if (sosData?.criticalIllness === status) {
			newData.push('Critical Illness');
		}
		if (sosData?.tPDInsurance === status) {
			newData.push('TPD Insurance');
		}
		if (sosData?.disabilityInsurance === status) {
			newData.push('Disability Insurance');
		}
		if (sosData?.medicalInsurance === status) {
			newData.push('Medical Insurance');
		}
		return newData;
	}

	public static getServicesNotes(sosData: ScopeOfServiceState, status: number) {
		// Get services notes wherein their status value === status param
		const newData = [];
		if (sosData?.lifeInsurance === status) {
			newData.push(sosData?.lifeInsuranceDetails || '');
		}
		if (sosData?.criticalIllness === status) {
			newData.push(sosData?.criticalIllnessDetails || '');
		}
		if (sosData?.tPDInsurance === status) {
			newData.push(sosData?.tPDInsuranceDetails || '');
		}
		if (sosData?.disabilityInsurance === status) {
			newData.push(sosData?.disabilityInsuranceDetails || '');
		}
		if (sosData?.medicalInsurance === status) {
			newData.push(sosData?.medicalInsuranceDetails || '');
		}
		return newData;
	}

	public static getOtherAdvice(sosData: ScopeOfServiceState, status: number) {
		// Get other advice wherein their status value === status param
		const newData = [];
		if (sosData?.businessRiskInsurance === status) {
			newData.push('Business Risk Insurance');
		}
		if (sosData?.kiwiSaver === status) {
			newData.push('KiwiSaver');
		}
		if (sosData?.homeCarAndContentsInsurance === status) {
			newData.push('Home, Car & Contents Insurance');
		}
		if (sosData?.residentialMortgageOrLending === status) {
			newData.push('Residential Mortgage / Lending');
		}
		if (sosData?.other === status) {
			newData.push('Other');
		}
		return newData;
	}

	public static getOtherAdviceNotes(
		sosCrt: ScopeOfServiceState,
		sosSettings: ScopeOfServiceState
	) {
		// Get other advice notes wherein their status value === status param
		const newData = [];
		if (+sosCrt?.businessRiskInsurance === 1) {
			newData.push(sosSettings?.businessRiskInsuranceNote || '');
		}
		if (+sosCrt?.kiwiSaver === 1) {
			newData.push(sosSettings?.kiwiSaverNote || '');
		}
		if (+sosCrt?.homeCarAndContentsInsurance === 1) {
			newData.push(sosSettings?.homeCarAndContentsInsuranceNote || '');
		}
		if (+sosCrt?.residentialMortgageOrLending === 1) {
			newData.push(sosSettings?.residentialMortgageOrLendingNote || '');
		}
		if (+sosCrt?.other === 1) {
			newData.push(
				(sosCrt?.otherNotes || '')?.toString().replace(/\n|\t/g, '<br />') || ''
			);
		}
		return newData;
	}
}
