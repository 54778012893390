<!-- default form -->
<div #formProp class="pb-50px" appResponsive>
	<div class="row align-items-center mb-3">
		<div class="col-auto">
			<strong>{{
				groupName +
					(lr && lr.policyNumberSuffix ? " - " + lr.policyNumberSuffix : "")
			}}</strong>
		</div>
		<div class="col">
			<div class="d-flex flex-row bd-highlight justify-content-end">
				<span *ngIf="isSaving">
					<div class="d-flex flex-row bd-highlight justify-content-end">
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isSaving"
						></app-manual-loader-mini>
					</div>
				</span>

				<!-- Save Service -->
				<ng-container *appRestrictTo="['FES']">
					<div *ngIf="(isEditForm || addMode) && !isSaving">
						<span class="position-relative">
							<button
								type="submit"
								class="icon-btn"
								(click)="save(false)"
								id="lrSave_{{ formId }}"
							>
								<i class="material-icons md-20 tap-text-color-h6"> save </i>
							</button>
						</span>
						<span class="position-relative">
							<button
								type="cancel"
								class="icon-btn"
								(click)="cancel()"
								id="lrCancel_{{ formId }}"
							>
								<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
							</button>
						</span>
					</div>
				</ng-container>

				<ng-container *ngIf="!addMode && !isEditForm && !isSaving">
					<div class="d-flex flex-row bd-highlight justify-content-end">
						<!-- Transfer Service -->
						<ng-container *appRestrictTo="['FES']">
							<span *ngIf="lr.policyType !== 'Primary'">
								<button
									data-toggle="tooltip"
									title="Transfer Policy"
									class="icon-btn"
									type="button"
									(click)="transferGroup()"
									id="lrTransferPolicy_{{ formId }}"
								>
									<i class="material-icons md-20 pt-1 tap-text-color-h6"
										>redo</i
									>
								</button>
							</span>
						</ng-container>

						<!-- Archive Service -->
						<ng-container *appRestrictTo="['FARS']">
							<span *ngIf="lr.policyType !== 'Primary'">
								<button
									*ngIf="lr.isActive === 1"
									class="icon-btn"
									type="button"
									id="lrArchive_{{ formId }}"
									(click)="archive(true)"
								>
									<i
										data-toggle="tooltip"
										title="archive"
										class="material-icons md-20 tap-text-color-h6 pt-1"
									>
										archive
									</i>
								</button>
								<button
									*ngIf="lr.isActive !== 1"
									class="icon-btn"
									type="button"
									id="lrUnarchive_{{ formId }}"
									(click)="archive(false)"
								>
									<i
										data-toggle="tooltip"
										title="unarchive"
										class="material-icons md-20 tap-text-color-h6 pt-1"
									>
										unarchive
									</i>
								</button>
							</span>
						</ng-container>

						<!-- Edit Service -->
						<ng-container *appRestrictTo="['FES']">
							<span>
								<button
									data-toggle="tooltip"
									title="edit"
									class="icon-btn"
									type="button"
									(click)="editForm(true)"
									id="lrEdit_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6 pt-1">
										edit
									</i>
								</button>
							</span>
						</ng-container>

						<!-- Delete Service -->
						<ng-container *appRestrictTo="['FDS']">
							<span>
								<button
									data-toggle="tooltip"
									title="delete"
									class="btn btn-link p-1 ng-star-inserted"
									type="button"
									(click)="deleteConfirm()"
									id="lrDelete_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">  </i>
								</button>
							</span>
						</ng-container>
					</div>
				</ng-container>

				<button
					type="button"
					class="btn-toggle collapse-btn"
					(click)="collapseForm.Toggle()"
					id="lrToggle_{{ formId }}"
				>
					<span
						*ngIf="
							collapseForm &&
								collapseForm.IsExpanded$ &&
								(collapseForm.IsExpanded$ | async);
							else elsePlus
						"
					>
						<i class="material-icons md-20 tap-text-color-h6 pt-1"
							>expand_less</i
						>
					</span>
					<ng-template #elsePlus>
						<span>
							<i class="material-icons md-20 tap-text-color-h6 pt-1"
								>expand_more</i
							>
						</span>
					</ng-template>
				</button>
			</div>
		</div>
	</div>

	<form novalidate [formGroup]="form" class="pb-3">
		<div
			class="container-fluid clearfix px-0"
			[class.archived]="lr && lr.isActive ? lr.isActive === 2 : false"
		>
			<!-- First Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12 label-padding-bottom-md">
							<label for="">Products</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="products"
								[choices]="products"
								id="products_{{ formId }}"
								tabIndex="7"
								tabindex="7"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">API</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="premium"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="premium_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="17"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Submitted Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#submittedDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="submittedDate"
								id="submittedDate_{{ formId }}"
								tabindex="22"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>

			<!-- Second Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12 label-padding-bottom-md">
							<label for="">Policy Owners</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="policyOwners"
								[choices]="policyOwnersDropdown"
								id="policyOwners_{{ formId }}"
								tabindex="8"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<span class="tap-required-field" *ngIf="policyNumber.invalid"
								>*</span
							>
							<label for="">Policy Number</label>
						</div>
						<div class="col-lg-6 col-12 form-inline">
							<input
								type="text"
								formControlName="policyNumber"
								class="form-control theme-crm-field-disabled w-58"
								placeholder="- -"
								id="policyNumber_{{ formId }}"
								tabindex="18"
							/>
							<span class="p-1 font-weight-bold text-dark">-</span>
							<input
								type="text"
								formControlName="policyNumberSuffix"
								placeholder="- -"
								class="form-control theme-crm-field-disabled px-2 w-30"
								id="policyNumberSuffix_{{ formId }}"
								tabindex="19"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Start Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#startDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="startDate"
								id="startDate_{{ formId }}"
								tabindex="23"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>

			<!-- Third Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<span class="tap-required-field" *ngIf="policyStatus.invalid"
								>*</span
							>
							<label for="">Policy Status</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="policyStatus"
									class="form-control theme-crm-field-disabled"
									id="policyStatus_{{ formId }}"
									tabindex="9"
								>
									<option value=""></option>
									<option
										*ngFor="let p of policyStatuses; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Retention Status</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown">
								<select
									formControlName="policyRetentionStatus"
									class="form-control theme-crm-field-disabled select"
									id="policyRetentionStatus_{{ formId }}"
									tabindex="13"
								>
									<option value=""></option>
									<option
										*ngFor="let d of retentionStatus; trackBy: trackByValue"
										[value]="d.value"
									>
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<span class="tap-required-field" *ngIf="provider.invalid">*</span>
							<label for="">Provider</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="provider"
									class="form-control theme-crm-field-disabled"
									id="provider_{{ formId }}"
									id="label_r3_employment"
									tabindex="20"
								>
									<option value=""></option>
									<option
										*ngFor="let p of providers; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Anniversary Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#anniversaryDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="anniversaryDate"
								id="anniversaryDate_{{ formId }}"
								tabindex="24"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>

			<!-- Hide Row -->
			<div
				app-collapse
				#collapseForm
				[initialExpandedState]="addMode || undefined"
			>
				<!-- Fourth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Commission</label>
							</div>
							<div class="col-lg-6 col-12">
								<input
									type="text"
									formControlName="commission"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="commission_{{ formId }}"
									tabindex="10"
									appTwoDigitDecimalNumber
								/>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Claim Status</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="policyClaimStatus"
										class="form-control theme-crm-field-disabled"
										id="claimStatus_{{ formId }}"
										tabindex="14"
									>
										<option value=""></option>
										<option
											*ngFor="let p of claimStatus; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Payment Frequency</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="paymentFrequency"
										class="form-control theme-crm-field-disabled"
										id="paymentFrequency_{{ formId }}"
										tabindex="21"
									>
										<option value=""></option>
										<option
											*ngFor="let p of payments; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Commission Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#startTransDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="startTrans"
									id="startTrans_{{ formId }}"
									tabindex="25"
								>
								</app-date-input>
							</div>
						</div>
					</div>
				</div>

				<!-- Fifth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Policy Writer</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="policyType"
										class="form-control theme-crm-field-disabled"
										id="policyType_{{ formId }}"
										tabindex="11"
									>
										<option value=""></option>
										<option
											*ngFor="let p of policyTypes; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Discount</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="policyDiscount"
										class="form-control theme-crm-field-disabled"
										id="policyDiscount_{{ formId }}"
										tabindex="15"
									>
										<option value=""></option>
										<option
											*ngFor="let p of discounts; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row form-inline">
							<div class="col-lg form-inline px-0">
								<div class="col-lg-9 col-12 px-1">
									<label for="replacement_{{ formId }}"
										>Internal Replacement</label
									>
								</div>
								<div class="col-lg-3 col-12 replacement-container">
									<input
										class="chckbox form-control theme-crm-field-disabled"
										formControlName="replacement"
										type="checkbox"
										id="replacement_{{ formId }}"
										tabindex="26"
									/>
								</div>
							</div>

							<div class="col-lg form-inline px-0">
								<div class="col-lg-9 col-12 px-1 pl-4 double-sub-container">
									<label for="doubleSub_{{ formId }}">Double Sub</label>
								</div>
								<div class="col-lg-3 col-12 double-sub-container">
									<input
										class="chckbox form-control theme-crm-field-disabled"
										formControlName="doubleSub"
										type="checkbox"
										id="doubleSub_{{ formId }}"
										tabindex="27"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12 pl-4 adviser-sp-container">
								<label for="adviserSp_{{ formId }}">Adviser SP</label>
							</div>
							<div class="col-lg-6 col-12 adviser-sp-container">
								<input
									class="chckbox form-control theme-crm-field-disabled"
									formControlName="adviserSP"
									type="checkbox"
									id="adviserSp_{{ formId }}"
									tabindex="28"
								/>
							</div>
						</div>
					</div>
				</div>

				<!-- Sixth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<span class="tap-required-field" *ngIf="originalAdviser.invalid"
									>*</span
								>
								<label for="">Original Adviser</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										[class.disabled]="
											addMode
												? false
												: (hasPermission(['FEO']) | async)
												? false
												: true
										"
										formControlName="originalAdviser"
										class="form-control theme-crm-field-disabled"
										id="originalAdviser_{{ formId }}"
										tabindex="12"
									>
										<option value=""></option>
										<option
											*ngFor="let p of originalAdvisers$ | async"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Submitted API</label>
							</div>
							<div class="col-lg-6 col-12">
								<div class="dollar-icon">
									<input
										type="text"
										formControlName="submittedAPI"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										id="submittedApi_{{ formId }}"
										tabindex="16"
										appTwoDigitDecimalNumber
									/>
									<i>$</i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row">
							<div class="col-lg form-inline px-0">
								<div class="col-lg-9 col-12 px-1 externalReplacement-container">
									<label for="externalReplacement_{{ formId }}"
										>External Replacement</label
									>
								</div>
								<div class="col-lg-3 col-12 externalReplacement-container">
									<input
										class="chckbox form-control theme-crm-field-disabled"
										formControlName="externalReplacement"
										type="checkbox"
										id="externalReplacement_{{ formId }}"
										tabindex="29"
									/>
								</div>
							</div>

							<div class="col-lg form-inline px-0">
								<div class="col-lg-9 col-12 px-1 pl-4 trail-generating-container">
									<label for="trailGenerating_{{ formId }}"
										>Trail Generating</label
									>
								</div>
								<div class="col-lg-3 col-12 trail-generating-container">
									<input
										class="chckbox form-control theme-crm-field-disabled"
										formControlName="trailGenerating"
										type="checkbox"
										id="trailGenerating_{{ formId }}"
										tabindex="30"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<ng-container *ngIf="policyStatus.value === 'Cancelled'">
							<div class="form-row align-items-center">
								<div class="col-lg-6 col-12 pl-4">
									<label for="endTrans_{{ formId }}">End Trans</label>
								</div>
								<div class="col-lg-6 col-12">
									<app-date-input
										#endTransDateInput
										textboxClass="theme-crm-field-disabled"
										formControlName="endTrans"
										id="endTrans_{{ formId }}"
									>
									</app-date-input>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<!-- Seventh Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12"></div>
					<div class="col-lg col-12"></div>
					<div class="col-lg col-12">
						<ng-container *ngIf="policyStatus.value === 'Cancelled'">
							<div class="form-row align-items-center">
								<div class="col-lg-6 col-12">
									<label for="">Cancellation Code</label>
								</div>
								<div class="col-lg-6 col-12">
									<span class="custom-dropdown w-100">
										<select
											formControlName="cancellationCode"
											class="form-control theme-crm-field-disabled"
											id="cancellationCode_{{ formId }}"
										>
											<option value=""></option>
											<option
												*ngFor="
													let p of cancellationCodes;
													trackBy: trackByValue
												"
												[value]="p.value"
											>
												{{ p.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</ng-container>
					</div>
					<div class="col-lg col-12">
						<ng-container *ngIf="policyStatus.value === 'Cancelled'">
							<div class="form-row align-items-center">
								<div class="col-lg-6 col-12">
									<label for="">Cancellation Date</label>
								</div>
								<div class="col-lg-6 col-12">
									<app-date-input
										#cancellationDateInput
										textboxClass="theme-crm-field-disabled"
										formControlName="cancellationDate"
										id="cancellationDate_{{ formId }}"
									>
									</app-date-input>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<!-- Eight Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12"></div>
					<div class="col-lg col-12"></div>
					<div class="col-lg col-12"></div>
					<div class="col-lg col-12">
						<ng-container *ngIf="policyStatus.value === 'Cancelled'">
							<div class="form-row align-items-center">
								<div class="col-lg-6 col-12">
									<label for="">Clawback Rate</label>
								</div>
								<div class="col-lg-6 col-12">
									<input
										type="text"
										formControlName="clawbackRate"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										id="clawbackRate_{{ formId }}"
										appTwoDigitDecimalNumber
									/>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<!-- Nineth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col">
						<label for="exampleFormControlTextarea1">Notes / Exclusions</label>
						<textarea
							*ngIf="form.enabled; else exclusionsReadonly"
							class="form-control theme-crm-field-disabled"
							formControlName="exclusions"
							rows="4"
							id="exclusions_{{ formId }}"
							tabindex="31"
						></textarea>

						<ng-template #exclusionsReadonly>
							<textarea
								class="form-control theme-crm-field-disabled"
								rows="4"
								id="exclusions_{{ formId }}"
								tabindex="31"
								[value]="form.get('exclusions').value"
								readonly
							></textarea>
						</ng-template>
					</div>
					<div class="col">
						<label for="exampleFormControlTextarea1">Required</label>
						<textarea
							*ngIf="form.enabled; else requiredReadonly"
							class="form-control theme-crm-field-disabled"
							formControlName="required"
							rows="4"
							id="required_{{ formId }}"
							tabindex="32"
						></textarea>
						<ng-template #requiredReadonly>
							<textarea
								class="form-control theme-crm-field-disabled"
								rows="4"
								id="required_{{ formId }}"
								tabindex="32"
								[value]="form.get('required').value"
								readonly
							></textarea>
						</ng-template>
					</div>
				</div>

				<!-- Tenth Row -->
				<ng-container *ngIf="addMode">
					<div class="form-row clearfix align-items-center my-1">
						<div class="col-lg">
							<label for="note_{{ formId }}">Add a note</label>
							<textarea
								class="form-control theme-crm-field-disabled"
								formControlName="note"
								rows="4"
								id="note_{{ formId }}"
								tabindex="33"
							></textarea>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="claimsFeature">
					<ng-container *ngIf="lrClaims&&lrClaims.length>0">
						<div class="row clearfix align-items-center py-2">
							<div class="col-lg d-flex align-items-center">
								<strong class="mr-3">Claims</strong>
								</div>
						</div>
						<div class="row clearfix align-items-center">
							<div class="col-lg col-12">
								<app-table-paginate-claims
									id="claims_{{ formId }}"
									[claims]="lrClaims"
									[itemsPerPage]="5"
									[redirectToAP$]="redirectToAP$"
									claimsType="APLRC"
								>
								</app-table-paginate-claims>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="!addMode">
					<br />
					<div class="row clearfix align-items-center pb-2">
						<div class="col-lg d-flex align-items-center">
							<strong class="mr-3">Notes</strong>
							<ng-container *ngIf="isLead">
								<button
									*appRestrictTo="['FALN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									id="addNote_{{ formId }}"
									(click)="openModalAddNote()"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<ng-container *ngIf="emailClientTemplateFeature$ | async">
									<button
										*appRestrictTo="['FALN']"
										type="button"
										class="icon-btn h-auto"
										[disabled]="isSaving"
										id="openEmailClient_{{ formId }}"
										(click)="openEmailPopup()"
									>
										<i class="material-icons md-20 tap-text-color-h6">email</i>
									</button>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="!isLead">
								<button
									*appRestrictTo="['FAN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									id="addNote_{{ formId }}"
									(click)="openModalAddNote()"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<ng-container *ngIf="emailClientTemplateFeature$ | async">
									<button
										*appRestrictTo="['FAN']"
										type="button"
										class="icon-btn h-auto"
										[disabled]="isSaving"
										id="openEmailClient_{{ formId }}"
										(click)="openEmailPopup()"
									>
										<i class="material-icons md-20 tap-text-color-h6">email</i>
									</button>
								</ng-container>
							</ng-container>
						</div>
					</div>
					<div class="row clearfix align-items-center mb-2">
						<div class="col-lg col-12">
							<app-table-paginate-notes
								id="notes_{{ formId }}"
								[isService]="true"
								[isLead]="isLead"
								[notes]="notes"
								[itemsPerPage]="5"
								[deleteFn$]="deleteNote$"
								id="notes_{{ formId }}"
								[showHeader]="false"
								documentType="LR"
							>
							</app-table-paginate-notes>
						</div>
					</div>
				</ng-container>
				<br />
			</div>
		</div>
	</form>
</div>
