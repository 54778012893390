<div class="row align-items-center">
	<div *ngIf="isEditing" class="col-auto py-2 pr-0">
		<input
			[attr.disabled]="isDisabled || isLoading || null"
			class="align-middle"
			type="checkbox"
			id="selectAllUsers"
			(change)="toggleSelectAll($event.target.checked)"
			[checked]="isAllChecked()"
		/>
	</div>
	<div class="col py-2">
		<ng-content></ng-content>
	</div>
	<div class="border-bottom w-100"></div>
</div>
<div class="row">
	<div id="userList" class="col">
		<div class="item row align-items-center" *ngFor="let a of list; trackBy: trackByFn; let i = index;">
				<div class="col">
					<div class="row">
						<div *ngIf="isEditing" class="col-auto py-2 pr-0" [class.disabledStaff]="!a.isActive">
							<input
								[attr.disabled]="isDisabled || isLoading || null"
								class="align-middle"
								type="checkbox"
								[id]="'user_' + i"
								(change)="checkChange(a, $event.target.checked)"
								[checked]="isChecked(a)"	
							/> 
						</div>
						<div class="col-lg-3 col-md-4 py-2" [class.disabledStaff]="!a.isActive">
							<label [for]="'user_' + i" class="m-0">
								{{ a.display }}
							</label>
						</div>
						<div class="col-lg-3 col-md-4 p-2" [class.disabledStaff]="!a.isActive" [ngClass]="{ 'px-3': !isEditing }">
							<label [for]="'user_security_group' + i" class="m-0">
								{{ a.securityGroup }}
							</label>
						</div>
						<div class="col py-2" [class.disabledStaff]="!a.isActive" [ngClass]="{ 'px-0': isEditing }">
							<label [for]="'user_status_' + i" class="m-0">
								{{ a.status === 1 ? 'Active' : a.status === 2 ? 'Paused' : 'Deactivated' }}
							</label>
						</div>
						<div class="border-bottom w-100"></div>
					</div>
				</div>
		</div>
	</div>
</div>
