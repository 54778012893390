import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CrtFactFindPeople {
	customerID: number;
	customerType: string;
	firstName: string;
	lastName: string;
	middleName: string;
	email: string;
	fromCRM: boolean;
	fullName: string;
	dateOfBirth: string;
	relationship: string;
	age: number;
	cRTId: number;
	adviceProcessId: number;
	sectionCode: 'FPP';
	status: number;
	createDateTime: string;
	createdByStaffId: number;
	createdByStaffLevel: number;
	modifiedDateTime: string;
	modifiedByStaffId: number;
}

export interface CrtKiwiSaverFactFindPeopleState
	extends EntityState<CrtFactFindPeople, number> {}

export const CrtKiwisaverFactFindPeopleStoreName = 'CrtKiwisaver:FactFind People';
@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: CrtKiwisaverFactFindPeopleStoreName,
	idKey: 'customerID',
  resettable: false,
})
export class CrtKiwisaverFactFindPeopleStore extends EntityStore<CrtKiwiSaverFactFindPeopleState> {
	constructor() {
		super([]);
	}

  set(data: any): void {
    super.set(data);
  }

}
