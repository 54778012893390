import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	ChangeDetectionStrategy,
} from '@angular/core';
import { EmailSettingsModel } from '../state/email-settings.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import MomentUtil from '@util/moment.util';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-email-settings-form',
	templateUrl: './email-settings-form.component.html',
	styleUrls: ['./email-settings-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailSettingsFormComponent implements OnInit, OnChanges {
	@Input() row: EmailSettingsModel;
	@Input() prop: string;
	@Input() metakey: string;
	@Input() restrict: string[];

	@Input() isLoading: boolean;
	@Input() isEditing: boolean;

	/** event for saving */
	@Output() saveEvent = new EventEmitter<any>();
	/** event for cancel */
	@Output() cancelEvent = new EventEmitter<void>();
	/** event for edit */
	@Output() editEvent = new EventEmitter<void>();

	form: UntypedFormGroup;

	contacts$ = of([
		{
			display: 'Adviser',
			value: 'A',
		},
		{
			display: 'Other',
			value: 'O',
		},
	]);

	contactsWithTradingName$ = of([
		{
			display: 'Adviser',
			value: 'A',
		},
		{
			display: 'Trading Name',
			value: 'T',
		},
		{
			display: 'Other',
			value: 'O',
		},
	]);

	intervalDays$ = of([
		{
			display: 'days before',
			value: -1,
		},
		{
			display: 'days after',
			value: 1,
		},
		{
			display: 'same day',
			value: 0,
		}
	]);

	intervalDaysWithFixedDay$ = this.intervalDays$.pipe(
		map((intervals) => {
			return [
				...intervals,
				{
					display: 'fixed date',
					value: 'date',
				}
			]
		})
	);

	tradingNameAndOther$ = of([
		{
			display: 'Trading Name',
			value: 'T',
		},
		{
			display: 'Other',
			value: 'O',
		},
	]);

	businessEmailAndOther$ = of([
		{
			display: 'Business Email',
			value: 'B',
		},
		{
			display: 'Other',
			value: 'O',
		},
	]);

	emailFieldsDropdown$ = combineLatest([
		this.bConfigQuery.adviserReworkFeature$,
		this.businessEmailAndOther$,
		this.contacts$,
	]).pipe(
		map(([adviserRework, businessEmailAndOther, contacts]) => {
			if (
				this.row.EmailType === 'Birthday Email' ||
				this.row.EmailType === 'Holiday Email'
			) {
				return adviserRework ? businessEmailAndOther : contacts;
			}
			return contacts;
		})
	);

	fromNameDropdown$ = combineLatest([
		this.bConfigQuery.adviserReworkFeature$,
		this.tradingNameAndOther$,
		this.contactsWithTradingName$,
	]).pipe(
		map(([adviserRework, tradingNameAndOther, contactsWithTradingName]) => {
			if (
				this.row.EmailType === 'Birthday Email' ||
				this.row.EmailType === 'Holiday Email'
			) {
				return adviserRework ? tradingNameAndOther : contactsWithTradingName;
			}
			return contactsWithTradingName;
		})
	);

	constructor(
		private fb: UntypedFormBuilder,
		private bConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit(): void {}

	ngOnChanges() {
		if (this.row) {
			this.createFormContact();
		}
	}

	createFormContact() {
		this.form = new UntypedFormGroup({
			// Interval
			IntervalDateStart: this.fb.control(
				this.row?.IntervalDateStart > 0
					? this.row?.IntervalDateStart?.toString()
					: this.row?.IntervalDateStart < 0
					? this.displayPositive(this.row?.IntervalDateStart)?.toString()
					: '0'
			),
			IntervalTimeStart: this.fb.control(this.row?.IntervalTimeStart),
			IntervalDayStart: this.fb.control(
				this.row?.IntervalFixDate
					? 'date'
					: this.row.IntervalDateStart > 0
					? '1'
					: this.row?.IntervalDateStart < 0
					? '-1'
					: '0'
			),
			IntervalDateEnd: this.fb.control(
				this.row.IntervalDateEnd > 0
					? this.row?.IntervalDateEnd?.toString()
					: this.row?.IntervalDateEnd < 0
					? this.displayPositive(this.row?.IntervalDateEnd)?.toString()
					: '0'
			),
			IntervalTimeEnd: this.fb.control(this.row?.IntervalTimeEnd),
			IntervalDayEnd: this.fb.control(
				this.row?.IntervalDateEnd > 0
					? '1'
					: this.row?.IntervalDateEnd < 0
					? '-1'
					: '0'
			),

			Sender: this.fb.control(
				this.row?.Sender !== 'A' && this.row?.Sender !== 'B'
					? this.row?.Sender
					: ''
			),
			SenderDD: this.fb.control(
				this.row?.Sender === 'A'
					? 'A'
					: this.row?.Sender === 'B'
					? 'B'
					: !!this.row?.Sender
					? 'O'
					: ''
			),

			FromName: this.fb.control(
				this.row?.FromName !== 'A' && this.row?.FromName !== 'T'
					? this.row?.FromName
					: ''
			),
			FromNameDD: this.fb.control(
				this.row.FromName === 'A'
					? 'A'
					: this.row?.FromName === 'T'
					? 'T'
					: !!this.row?.FromName
					? 'O'
					: ''
			),

			CarbonCopy: this.fb.control(
				this.row?.CarbonCopy !== 'A' && this.row?.CarbonCopy !== 'B'
					? this.row?.CarbonCopy
					: ''
			),
			CarbonCopyDD: this.fb.control(
				this.row?.CarbonCopy === 'A'
					? 'A'
					: this.row?.CarbonCopy === 'B'
					? 'B'
					: !!this.row?.CarbonCopy
					? 'O'
					: ''
			),

			BlindCarbonCopy: this.fb.control(
				this.row?.BlindCarbonCopy !== 'A' && this.row?.BlindCarbonCopy !== 'B'
					? this.row?.BlindCarbonCopy
					: ''
			),
			BlindCarbonCopyDD: this.fb.control(
				this.row?.BlindCarbonCopy === 'A'
					? 'A'
					: this.row?.BlindCarbonCopy === 'B'
					? 'B'
					: !!this.row?.BlindCarbonCopy
					? 'O'
					: ''
			),
			IntervalFixDate: this.fb.control(moment(this.row?.IntervalFixDate)),
		});
	}

	/** trigger edit event */
	edit() {
		this.editEvent.emit();
	}
	/** trigger cancel event */
	cancel() {
		this.cancelEvent.emit();
	}
	/** trigger save event */
	save() {
		if (this.metakey.includes('Interval')) {
			if (
				!this.form.value.IntervalDateStart ||
				!this.form.value.IntervalDayStart ||
				!this.form.value.IntervalDateEnd ||
				!this.form.value.IntervalDayEnd ||
				(this.row.IsCustom === 1 &&
					(!this.form.value.IntervalTimeStart ||
						!this.form.value.IntervalTimeEnd)) ||
				(this.row.IsCustom === 2 && !this.form.value.IntervalFixDate)
			) {
				return;
			}
		}
		if (this.form.valid) {
			const data = this.prapareData();
			this.saveEvent.emit(data);
		}
	}

	prapareData() {
		if (!this.metakey?.includes('Interval')) {
			return {
				...this.row,
				[this.prop]:
					this.form.get(this.prop + 'DD').value &&
					this.form.get(this.prop + 'DD').value !== 'O'
						? this.form.get(this.prop + 'DD').value
						: !this.form.get(this.prop + 'DD').value
						? null
						: this.form.get(this.prop).value,
			};
		} else {
			return {
				...this.row,
				IntervalDateStart:
					+this.form.value.IntervalDayStart > 0
						? +this.form.value.IntervalDateStart
						: this.form.value.IntervalDayStart < 0
						? +`-${this.form.value.IntervalDateStart}`
						: 0,
				IntervalTimeStart: this.form.value.IntervalTimeStart,
				IntervalDateEnd:
					+this.form.value.IntervalDayEnd > 0
						? +this.form.value.IntervalDateEnd
						: this.form.value.IntervalDayEnd < 0
						? +`-${this.form.value.IntervalDateEnd}`
						: 0,
				IntervalTimeEnd: this.form.value.IntervalTimeEnd,
				IntervalFixDate: isNaN(this.form.value.IntervalDayStart)
					? MomentUtil.formatDateToServerDate(this.form.value.IntervalFixDate)
					: null,
			};
		}
	}

	intervalDateStartChange(e) {
		if (
			e.target &&
			e.target.value &&
			!isNaN(+e.target.value) &&
			+e.target.value === 0
		) {
			this.form.get('IntervalDayStart').setValue('0');
		} else {
			this.form.get('IntervalDayStart').setValue('');
		}
	}

	intervalDayStartChange(e) {
		if (
			e.target &&
			e.target.value &&
			!isNaN(+e.target.value) &&
			+e.target.value === 0
		) {
			this.form.get('IntervalDateStart').setValue('0');
		}

		if (e.target && e.target.value && isNaN(+e.target.value)) {
			this.form
				.get('IntervalFixDate')
				.setValue(
					this.row.IntervalFixDate
						? moment(this.row.IntervalFixDate)
						: MomentUtil.momentNowNz()
				);
		}
	}

	intervalDateEndChange(e) {
		if (
			e.target &&
			e.target.value &&
			!isNaN(+e.target.value) &&
			+e.target.value === 0
		) {
			this.form.get('IntervalDayEnd').setValue('0');
		} else {
			this.form.get('IntervalDayEnd').setValue('');
		}
	}

	intervalDayEndChange(e) {
		if (
			e.target &&
			e.target.value &&
			!isNaN(+e.target.value) &&
			+e.target.value === 0
		) {
			this.form.get('IntervalDateEnd').setValue('0');
		}
	}

	displayPositive(num) {
		return Math.abs(num);
	}

	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email)?.toLowerCase());
	}

	checkIsNaN(value) {
		return isNaN(value);
	}

	format(date) {
		return MomentUtil.formatToDisplayDateNoYear(moment(date));
	}
}
