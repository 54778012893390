import { Injectable } from '@angular/core';
import { BNBState, BNBStore } from './bnb.store';
import { Query } from '@datorama/akita';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class BNBQuery extends Query<BNBState> {

	/**
	 * Goal track data.
	 */
	data$ = this.select(state => {
		return state.GoalTrack;
	}).pipe(
		shareReplay(1)
	);
	/**
	 * indicator if widget is loading.
	 */
	isLoading$ = this.select(state => state.isLoading);
	/**
	 * error message to show.
	 */
	error$ = this.select(state => state.error);
	/**
	 * Create LR Goal Track query
	 */
	constructor(protected store: BNBStore) {
		super(store);
	}
}
