import { MoatVersionService } from './../../../service/moat-version.service';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isNil } from 'ramda';
import {
	Observable,
	Observer,
	ReplaySubject,
	Subject
} from 'rxjs';
import {
	finalize,
	map,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { MortgageMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/mortgage/mortgage.mapper';
import { MortgageInfoState } from 'src/app/shared/models/client-review-template/assets-liabilities/mortgage/mortgage.model';
import { MortgageCustomerServiceState } from 'src/app/shared/models/services/mortgage/mortgage.model';
import { PropertyAssetCustomerServiceState } from 'src/app/shared/models/services/property-asset/property-asset';
import MomentUtil from 'src/app/util/moment.util';
import { MOATAssetsLiabilitiesModalComponent } from '../../../../../../../shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { ViewDisplayValue } from '../../../../../../../shared/models/_general/display-value.viewmodel';
import { objectUtil } from '../../../../../../../util/util';
import {
	PropertyState,
	PropertyStatus,
} from '../../../../crt-mortgage/client-sop/assets-and-liabilities/state/property/property.model';
import { Mortgage } from './../../../../crt-mortgage/client-sop/assets-and-liabilities/state/mortgage/mortgage.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
@Component({
	selector: 'app-crt-mortgage-template-form',
	templateUrl: './crt-mortgage-template-form.component.html',
	styleUrls: ['./crt-mortgage-template-form.component.scss'],
})
export class CrtMortgageTemplateFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() formId: number;
	@Input() mortgage: Mortgage;
	@Input() borrowers$: Observable<ViewDisplayValue[]>;
	@Input() securities$: Observable<PropertyAssetCustomerServiceState[]>;
	@Input() crmMortgagesChoices: ViewDisplayValue[];
	@Input() crmMortgages: MortgageCustomerServiceState[];
	@Input() MP$: Observable<ViewDisplayValue[]>;
	@Input() MLT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	@Input() addMode: boolean;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	// tslint:disable-next-line: variable-name
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	get getCrmMortgagesChoices(): ViewDisplayValue[] {
		return this.crmMortgagesChoices;
	}
	// tslint:disable-next-line: variable-name
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<boolean>();

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;
	submitted: boolean;

	isMoatV2Enabled: Observable<boolean>;
	isMoatV2: boolean;
	crmMortgagesChoicesV2: any;
	selectedMortgage: string;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		this.buildForm();
		this.isMoatV2Enabled = this.businessConfigQuery.moatv2Enabled$;
	}

	ngOnInit(): void {
		this.isMoatV2Enabled.pipe(take(1)).subscribe((enabled) => {
			if (enabled) {
				this.prepareDataV2();
				this.isMoatV2 = true;
			} else {
				this.prepareData();
				this.isMoatV2 = false;
				if (!this.addMode) {
					this.form.disable();
				}
			}
		});

		//this.prepareData();

		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));
	}

	prepareData() {
		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);

		this.form.reset({
			lender: this?.mortgage?.lender || null,
			interestRate: this?.mortgage?.interestRate + '%' || null,
			fixedPeriodEnd:
				MomentUtil.formatDateToMoment(this?.mortgage?.fixedPeriodEnd) || null,
			loanValue: this?.mortgage?.loanValue || 0,
			customerServiceID: this?.mortgage?.customerServiceID || null,
			lenderLoanValue: null,
		});
	}

	prepareDataV2() {
		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);

		if (this?.mortgage?.lender !== undefined) {
			this.form.reset({
				lender: this?.mortgage?.lender || null,
				interestRate: this?.mortgage?.interestRate + '%' || null,
				fixedPeriodEnd:
					MomentUtil.formatDateToMoment(this?.mortgage?.fixedPeriodEnd) || null,
				loanValue: this?.mortgage?.loanValue || 0,
				customerServiceID: this?.mortgage?.customerServiceID || null,
				lenderLoanValue: null,
			});
		} else {
			this.setUnsavedMortageInfo(this.crmMortgagesChoices[0]?.value);
		}
	}

	//set the unsaved mortgage info
	setUnsavedMortageInfo(mortageId): void {
		const mortgagesLength = this.crmMortgagesChoices?.length;
		if (mortgagesLength === 0) {
			this.onSelectMortgage('new');
			this.cancelEvent.emit(true);
		} else {
			this.onSelectMortgage(mortageId);
		}
	}

	buildForm() {
		this.form = this.fb.group({
			lender: this.fb.control(null),
			interestRate: this.fb.control(null),
			fixedPeriodEnd: this.fb.control(null),
			loanValue: this.fb.control(0, [Validators.min(0), Validators.required]),
			customerServiceID: this.fb.control(null),
			lenderLoanValue: this.fb.control(null),
		});
	}

	prepareFormValue() {
		const form = this.form.getRawValue();
		return {
			...form,
		};
	}

	validateForm() {
		// Your validation here
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	save() {
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}

		const data = this.prepareFormValue();
		this.toggleSaving(true);
		const d = this.crmMortgages?.find(
			(x) => x.customerServiceID === +data.customerServiceID
		);

		this.addFn$(
			MortgageMapper.mapToMortgageDetails(
				{
					...data,
					fixedPeriodEnd: data.fixedPeriodEnd,
					borrowingEntities:
						!!d && !!d.borrowingEntities
							? JSON.parse(d.borrowingEntities)
							: null,
					actualRepayment: !!d && !!d.loanRepayment ? d.loanRepayment : null,
					mortgageFrequency:
						!!d && !!d.repaymentFrequency ? d.repaymentFrequency : null,
					loanType: !!d && !!d.loanType ? d.loanType : null,
					loanTerm: !!d && !!d.loanTerm ? d.loanTerm : null,
					security: !!d && !!d.security ? JSON.parse(d?.security) : null,
					purpose: '',
				},
				this.adviceProcessId
			)
		)
			.pipe(
				take(1),
				finalize(() => {
					this.saveEvent.emit(data);
					this.toggleSaving(false);
					this.form.reset();
				})
			)
			.subscribe();
	}

	delete() {
		this.toggleSaving(true);
		this.deleteFn$(this.mortgage.cRTId)
			.pipe(
				finalize(() => {
					this.toggleSaving(false);
				})
			)
			.subscribe();
	}

	deleteMortgage() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Delete Mortgage',
			message: logMessage.oat.shared.factFind.delete,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'OK',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	edit() {
		this.toggleEdit(true);
	}

	cancel() {
		if (!!this.addMode) {
			this.cancelEvent.emit(true);
		} else {
			this.toggleEdit(false);
		}
		this.prepareData();
	}

	mortgageSecurities() {
		return this.securities$.pipe(
			map((x) => {
				const data = this.mortgage as MortgageInfoState;
				let securities = data?.security ? JSON.parse(data?.security) : [];
				securities = securities?.map((i) => +i);
				const ACTIVE = 1;

				return x?.filter(
					(p: PropertyAssetCustomerServiceState | PropertyState) => {
						// @ts-ignore-next
						const isProperty = !isNil(p?.cRTId) && p?.status === ACTIVE;
						if (isProperty) {
							return true;
						}
						return (
							p?.status === PropertyStatus.Current ||
							securities?.includes(p?.customerServiceID)
						);
					}
				);
			}),
			map((x) =>
				x?.map((a) =>
					ViewDisplayValue.Map(`${a.customerServiceID}`, a.propertyAddress)
				)
			)
		);
	}

	onSelectMortgage(mortgage) {
		if (mortgage === 'new') {
			const decline$ = new Observable((obs: Observer<any>) => {
				this.cancel();
				obs.complete();
			});

			const saveFn$ = (data) =>
				this.addFn$(
					MortgageMapper.mapToMortgageDetails(data, this.adviceProcessId)
				).pipe(
					finalize(() => {
						this.saveEvent.emit(data);
					})
				);

			const initState = {
				header: 'Mortgages Details',
				message: `Mortgages`,
				borrowers: this.borrowers$,
				securities: this.mortgageSecurities(),
				mp$: this.MP$,
				mlt$: this.MLT$,
				apcrtf$: this.APCRTF$,
				hasLoanLimit: true,
				hasLoanRepaymentDate: true,
				saveFn$,
				decline$,
				getOwnerChoices: this.getOwnerChoices,
			};
			this.bsModalRef = this.modalService.show(
				MOATAssetsLiabilitiesModalComponent,
				{
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				}
			);
		} else {
			const data = this.form.getRawValue();
			const m: MortgageCustomerServiceState = this.crmMortgages?.find(
				(x) => x.customerServiceID === +mortgage
			);

			this.form.reset({
				lender: m.provider || null,
				interestRate: m.interestRate || null,
				fixedPeriodEnd:
					MomentUtil.formatDateToMoment(m.fixedPeriodEndDate) || null,
				loanValue: m.loanValue || 0,
				customerServiceID: m.customerServiceID || null,
				lenderLoanValue: mortgage,
			});
			if (!this.isMoatV2) {
				this.form.disable();
			}
		}
	}

	editMortgage() {
		const decline$ = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const saveFn$ = (data) =>
			this.updateFn$(
				MortgageMapper.mapToMortgageDetails(data, this.adviceProcessId)
			).pipe(
				tap((x) => {
					this.mortgage = x;
					this.prepareData();
				}),
				finalize(() => {
					this.saveEvent.emit(data);
				})
			);

		let crtInfo = objectUtil.mapCamelCaseToPascalCase(this.mortgage);

		const initState = {
			header: 'Mortgage Details',
			message: `Mortgages`,
			borrowers: this.borrowers$,
			securities: this.mortgageSecurities(),
			mp$: this.MP$,
			mlt$: this.MLT$,
			apcrtf$: this.APCRTF$,
			hasLoanLimit: true,
			hasLoanRepaymentDate: true,
			crtInfo,
			saveFn$,
			decline$,
			getOwnerChoices: this.getOwnerChoices,
		};
		this.bsModalRef = this.modalService.show(
			MOATAssetsLiabilitiesModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}

	approveConfirmation() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			const data = {
				...this.mortgage,
				pendingAdviserApproval: false,
			};
			this.updateFn$(
				MortgageMapper.mapToMortgageDetails(data, this.adviceProcessId)
			)
				.pipe(
					tap((x) => {
						this.mortgage = x;
						this.prepareData();
					}),
					finalize(() => {
						this.saveEvent.emit(data);
					})
				)
				.subscribe();
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Delete Mortgage',
			message:
				logMessage.oat.shared.factFind.assetsLiabilities.mortgages
					.deleteApproval,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'Remove',
			confirmButton2: 'Cancel',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
