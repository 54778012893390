import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { find, prop, propEq, uniqBy, isNil } from 'ramda';
import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import {
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { DependentState } from '../../../../../../shared/models/client-review-template/dependent/dependent.model';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { ClientGoal, DangerousPastimesParsedState } from '../../../../../../shared/models/client-review-template/risk-analysis/goals/goals.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { GoalsService } from '../../../states/risk-analysis/goals/goals.service';

declare var $: any;

@Component({
	selector: 'app-client-goals',
	templateUrl: './client-goals.component.html',
})
export class ClientGoalsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
	@Input() clientGoals$: Observable<DangerousPastimesParsedState[]>;
	@Input() people$: Observable<PeopleState[]>;
	@Input() dependents$: Observable<DependentState[]>;
	@Input() cRTId$: Observable<number>;
	@Input() APCRTDP: ViewDisplayValue[];
	@Input() isAdviceProcessEnded: boolean;
	@Input() isNew: boolean;

	elseMinus = true;
	submitted = false;
	isListLoading = true;
	peopleList: (PeopleState | DependentState)[] = [];
	form: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder, private goalsService: GoalsService) {
		this.buildForm();
	}

	get DetailsList() {
		return this.form.get('detailsList') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.getPeopleList();
		this.prepData();
	}

	buildForm() {
		this.form = this.fb.group({
			detailsList: this.fb.array([]),
		});
	}

	prepData() {
		this.clientGoals$
			.pipe(
				filter((data) => !!data),
				withLatestFrom([this.cRTId$]),
				mergeMap(([goals, cRTId]) =>
					iif(() => isNil(cRTId), this.addPeople(), this.addFromData(goals))
				),
				tap(x => {
					if(this.isAdviceProcessEnded) {
						this.form.disable();
					}
				}),
				finalize(() => (this.isListLoading = false)),
				take(1)
			)
			.subscribe(() => this.onValueChanges());
	}

	addPeople = () =>
		of(this.peopleList).pipe(
			map((data) =>
				data?.map((p) =>
					this.addItem({
						cRTId: p.cRTId,
						pastimes: [],
						clientName: p.name,
					})
				)
			),
			take(1)
		);

	addFromData = (data: DangerousPastimesParsedState[]) =>
		of(data).pipe(
			map((x) => this.checkPeopleList(x)),
			map((x) => x?.map((item) => this.addItem(item))),
			delay(100),
			tap(() => this.updateState()),
			take(1)
		);

	getPeopleList() {
		combineLatest([this.people$, this.dependents$])
			.pipe(
				filter(([people, dependents]) => !!people && !!dependents),
				map(([people, dependents]) => {
					const list: (PeopleState | DependentState)[] = uniqBy(
						prop('customerId') as any,
						[...people, ...dependents]
					);
					return list.sort((item1, item2) =>
						item1.name?.localeCompare(item2.name)
					);
				}),
				tap((x) => (this.peopleList = x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	checkPeopleList(data: DangerousPastimesParsedState[]): ClientGoal[] {
		const list: ClientGoal[] = this.peopleList?.map(({ cRTId }) => {
			const getPastTime = data?.find((pastime) => +pastime.cRTId === cRTId);
			return {
				cRTId,
				pastimes: getPastTime?.pastimes || [],
				clientName: this.getClientName(+cRTId),
			};
		});
		return list;
	}

	getClientName(id: number) {
		const nameDisplay = find(propEq('cRTId', id))(
			this.peopleList
		) as PeopleState;
		return nameDisplay?.name || '';
	}

	addItem(data?: ClientGoal) {
		this.DetailsList.push(
			this.fb.group({
				cRTId: {
					value: data?.cRTId || '',
					disabled: true,
				},
				clientName: {
					value: data?.clientName || '',
					disabled: true,
				},
				pastimes: [data?.pastimes || ''],
			})
		);
	}

	deleteItem(index: number) {
		this.DetailsList.removeAt(index);
	}

	updateState() {
		this.goalsService.updateGoalsState({
			dangerousPastimes: this.form.getRawValue().detailsList,
		});
	}

	onValueChanges(): void {
		this.form.valueChanges
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => this.updateState());
	}

	collapseMore() {
		$('#collapse').toggle();
		this.elseMinus = false;
	}

	collapseLess() {
		$('#collapse').toggle();
		this.elseMinus = true;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
