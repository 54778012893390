import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, Input } from '@angular/core';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { EmailStatusQuery } from './state/email-status.query';
import { map, takeUntil, tap, debounceTime } from 'rxjs/operators';
import { Subject, of, fromEvent } from 'rxjs';
import { linkColumn } from './email-status-datatable.config';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { EmailStatusService } from './state/email-status.service';
import { EmailStatusStore } from './state/email-status.store';
import { Router, Event } from '@angular/router';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { ValueReference, Page } from '../../../shared/models/emails/email-history/email-history.model';
import * as R from 'ramda';

@Component({
	selector: 'app-email-status',
	templateUrl: './email-status.component.html',
	styleUrls: ['./email-status.component.scss']
})
export class EmailStatusComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	resizeEvent$ = fromEvent(window, 'resize');
	@ViewChild('mydatatable') table: DatatableComponent;
	@ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
	@Input() activeRow: [];

	obj: {} = {};
	page = new Page();

	minRange = 0;
	maxRange = 0;
	resizeChange: boolean;

	public emailModeList$: ViewDisplayValue[];
	emailModeList: ValueReference[] = [
		{ name: 'Initialized', value: '0' },
		{ name: 'Automated', value: '1' },
		{ name: 'Manual', value: '2' },
		{ name: 'Test Email', value: '3' },
		{ name: 'Resend', value: '4' }
	];

	rowsLoading$ = this.emailStatusQuery.rowsLoading$;
	public emailStatus$ = this.emailStatusQuery.emailStatus$;
	public emailType$ = this.emailStatusQuery.emailType$;
	public tableResult$ = this.emailStatusQuery.tableResult$;
	isSaving = true;
	isShowTable = false;

	tblHeight: SafeStyle;
	linkColumn = linkColumn;
	rows$ = this.emailStatusQuery.sortedRows$;
	columns$ = this.emailStatusQuery.tableColumns$;
	columnsWithLink$ = this.columns$.pipe(
		map(x => [...x, linkColumn])
	);

	constructor(private emailStatusQuery: EmailStatusQuery,
		           private emailStatusService: EmailStatusService,
		           private emailStatusStore: EmailStatusStore,
		           private router: Router,
		           private sanitizer: DomSanitizer) {
		this.router.events.subscribe((event: Event) => {
			this.isShowTable = false;
		});

		this.page.pageNumber = 1;
		this.page.size = 500;
		this.page.totalElements = 20;
	}

	onFooterPage(pageInfo) {
		const setObj = {
			...this.obj,
			Paging: {
				Index: pageInfo.page,
				Size: this.page.size
			}
		};
		this.page.pageNumber = pageInfo.page;
		this.minRange = (this.page.size * this.page.pageNumber) - (this.page.size - 1);
		let valueCount = 0;
		this.tableResult$.subscribe(x => valueCount = (x && x.total) | 0);
		this.maxRange = (this.page.size * this.page.pageNumber) > valueCount ? valueCount : (this.page.size * this.page.pageNumber);

		return this.emailStatusService.getReport(setObj).subscribe();
	}

	getReport = (values) => {
		this.obj = {
			...values,
			Paging: {
				Index: 1,
				Size: this.page.size
			}
		};
		return this.emailStatusService.getReport(this.obj).pipe(
			tap(x => {
				this.page.totalElements = x.Total;

				this.minRange = (this.page.size * this.page.pageNumber) - (this.page.size - 1);
				let valueCount = 0;
				this.tableResult$.subscribe(val => valueCount = (val && val.total) | 0);
				this.maxRange = (this.page.size * this.page.pageNumber) > valueCount ? valueCount : (this.page.size * this.page.pageNumber);

			}),
		);
	}

	acceptValueChange(change) {
		this.isShowTable = change;
	}

	onSort(a: { sorts; column; prevValue; newValue }) {
		this.emailStatusService.sort(a.sorts[0].prop, a.sorts[0].dir);
		this.table.offset = this.page.pageNumber - 1;
	}

	ngOnInit() {
		this.resizeEvent$.pipe(debounceTime(500), takeUntil(this.onDestroy$)).subscribe(() => {
			this.emailStatusService.reloadData();
		});

		this.tblHeight = this.sanitizer.bypassSecurityTrustStyle('calc(100vh - 200px)');

		of(this.emailModeList).pipe(
			map((emailMode) => {
				const em = R.map(y => ViewDisplayValue.Map(!y.value ? '' : y.value?.toString(), `${y.name}`), emailMode);
				this.emailModeList$ = [...em];
			})
		).subscribe();
	}

	resize = (event: { column: TableColumn; newValue: number }) => {
		this.resizeChange = true;
		if (event && (!event.column || !event.newValue)) {
			return;
		}
	}

	getRowClass = (row) => {
		return {
			'row-color': true
		};
	}

	resend(row) {
		this.emailStatusStore.setLoad(row, true);
		this.emailStatusService.resend(row).pipe(
			tap(() => {
				this.emailStatusStore.setLoad(row, false);
			}),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

	ngOnDestroy() {
		this.isShowTable = false;
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

}

