import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeadSearchPageComponent } from './lead-search-page/lead-search-page.component';
import { LeadSearchResolver } from './lead-search.resolver';

const routes: Routes = [
  {
    path: '',
    component: LeadSearchPageComponent,
    resolve: {
      data: LeadSearchResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadSearchRoutingModule { }
