import { Injectable } from '@angular/core';
import {
	SendManualEmail,
} from '../../../shared/models/emails/send-manual-email/send-manual-email.model';
import { ApiService } from '../../../core/base/api.service';

@Injectable()
export class SendManualEmailService {
	constructor(private api: ApiService) {}

	send(sendManual: SendManualEmail) {
		const endpoint = `emails/sendfailed/${sendManual.EmailType}/${sendManual.DateTarget}`;
		return this.api.post<string>(endpoint);
	}
}
