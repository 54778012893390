import { StoreConfig, Store } from '@datorama/akita';
import { LeadConversion } from './lc.model';
import { produce } from 'immer';
import { Injectable } from "@angular/core";

export interface LCState {
  LeadConversion: LeadConversion[];
  error: string;
  isLoading: boolean;
}

export function CreateInitialState(): LCState {
  return {
    LeadConversion: null,
    error: '',
    isLoading: false
  };
}

@Injectable()
@StoreConfig({
  name: 'LeadConversion'
})
export class LCStore extends Store<LCState> {
  constructor() {
    super(CreateInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(
      produce(draft => {
        draft.isLoading = isLoading;
      })
    );
  }
}
