import { Store, StoreConfig } from '@datorama/akita';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { AuthorityToProceedState } from '../authority-to-proceed-settings/state/authority-to-proceed-settings.model';
import { ClientAcceptanceSettingsState } from '../client-acceptance-settings/state/client-acceptance-settings.model';
import { DeclarationSettingState } from '../declaration-settings/declaration-template-settings/state/declaration-settings.model';
import { DeclarationEmailSettingsState } from '../declaration-settings/declaration-email-settings/state/declaration-email-settings.model';
import { EndProcessEmailSettingsState } from '../end-process/state/end-process.model';
import { FinalStructureState } from '../final-structure-settings/state/final-structure-settings.model';
import { IntroSettingsState } from '../intro-page-settings/state/intro-settings.model';
import { ScopeOfServiceState } from '../scope-of-service-settings/state/scope-of-service.model';
import { SOAEmailSettings, SoaSettingsState } from '../soa-settings/state/soa-settings.model';
import { DocumentListState } from '../../crt-settings/soa-settings/document-list/state/document-list.model';
import { Injectable } from "@angular/core";
import { LoatCffSettingState } from '../loat-cff-template-settings/state/loat-cff-template-settings.model';

export interface CrtSettingsState {
	intro: IntroSettingsState;
	scopeOfService: ScopeOfServiceState;
	authorityToProceed: AuthorityToProceedState;
	declaration: DeclarationSettingState;
	declarationEmailSettings: DeclarationEmailSettingsState;
	soaEmailSettings: SOAEmailSettings;
	statementOfAdvice: SoaSettingsState;
	mergeTags: MergeTagState[];
	finalStructure: FinalStructureState;
	clientAcceptance: ClientAcceptanceSettingsState;
	endProcessEmailSettings: EndProcessEmailSettingsState;
	documentList: DocumentListState[];
	loatCffSettings: LoatCffSettingState;
}

export function createInitialState(): CrtSettingsState {
	return {
		intro: null,
		scopeOfService: null,
		authorityToProceed: null,
		declaration: null,
		statementOfAdvice: null,
		mergeTags: [],
		finalStructure: null,
		clientAcceptance: null,
		declarationEmailSettings: null,
		soaEmailSettings: null,
		endProcessEmailSettings: null,
		documentList: [],
		loatCffSettings: null,
	};
}

@Injectable()
@StoreConfig({ name: 'crtSettings' })
export class CrtSettingsStore extends Store<CrtSettingsState> {
	constructor() {
		super(createInitialState());
	}

	setIntroSettings(data: IntroSettingsState) {
		this.update({ intro: data });
	}

	setScopeOfService(data: ScopeOfServiceState) {
		this.update({ scopeOfService: data });
	}

	setDeclarationSettings(data: DeclarationSettingState) {
		this.update({ declaration: data });
	}

	setDeclarationEmailSettings(data: DeclarationEmailSettingsState) {
		this.update({ declarationEmailSettings: data });
	}

	setSoaSettings(data: SoaSettingsState) {
		this.update({ statementOfAdvice: data });
	}

	setSoaEmailSettings(settings: SOAEmailSettings): void {
		this.update({ soaEmailSettings: settings });
	}

	setMergeTags(data: MergeTagState[]) {
		this.update({ mergeTags: data });
	}

	setFinalStructure(data: FinalStructureState) {
		this.update({ finalStructure: data });
	}

	setClientAcceptance(data: ClientAcceptanceSettingsState) {
		this.update({ clientAcceptance: data });
	}

	setAuthorityToProceed(data: AuthorityToProceedState) {
		this.update({ authorityToProceed: data });
	}

	setEndProcessEmailSettings(data: any) {
		this.update({ endProcessEmailSettings: data });
	}

	setDocumentList(data: any) {
		this.update({ documentList: data });
	}

	setLoatCffSettings(data: any) {
		this.update({ loatCffSettings: data });
	}
}
