<div class="app-backend-sidenav-container d-flex flex-column align-items-start">
	<div class="app-user-container d-flex flex-column align-items-center mt-4">
		<ngx-avatars
			[src]="googleImageUrl"
			[name]="name"
			[round]="true"
			size="72"
		></ngx-avatars>
		<div class="app-user-name pt-4 pr-0 tap-text-color">{{name}}</div>
	</div>
	<div class="app-backend-sidenav-links-container mt-4">
		<!--TL - Businesses Access-->
		<ng-container *appRestrictTo="['TBA']">
			<div class="app-backend-sidenav-link mb-3">
				<a
					[routerLink]="adminBList"
					routerLinkActive="active"
					class="list-businesses"
				>
					<h6 class="d-flex align-items-center">
						<i class="material-icons main-color mr-2">&#xE0AF;</i> BUSINESSES
					</h6>
				</a>
			</div>
		</ng-container>
		<!--TL - Businesses Access-->
		<ng-container *appRestrictTo="['TTUA']">
			<div class="app-backend-sidenav-link mb-3">
				<h6
					class="d-flex align-items-center clickable"
					(click)="isUsersCollapsed = !isUsersCollapsed"
				>
					<i class="material-icons main-color mr-2">&#xE838;</i> TAP LEVEL USERS
				</h6>
				<div
					class="app-backend-sidenav-sublinks d-flex flex-column pl-4"
					*ngIf="!isUsersCollapsed"
				>
					<a
						[routerLink]="adminUList"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{exact:true}"
						class="pb-2"
						>Manage Users</a
					>
					<!--TL - Add User-->
					<ng-container *appRestrictTo="['TAU']">
						<a [routerLink]="adminUAdd" routerLinkActive="active" class="pb-2"
							>Add a user</a
						>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<!--TL - View Reports-->
		<ng-container *appRestrictTo="['TVR']">
			<div class="app-backend-sidenav-link mb-3">
				<a
					[routerLink]="adminBReport"
					routerLinkActive="active"
					class="list-businesses"
				>
					<h6 class="d-flex align-items-center">
						<i class="material-icons main-color mr-2">assessment</i> REPORTS
					</h6>
				</a>
			</div>
		</ng-container>

		<!--TL - Settings -->
		<ng-container *appRestrictTo="['TMCMSA']">
			<div class="app-backend-sidenav-link mb-3">
				<h6
					class="d-flex align-items-center clickable"
					(click)="isSettingsCollapsed = !isSettingsCollapsed"
				>
					<i class="material-icons main-color mr-2">settings</i> SETTINGS
				</h6>
				<div
					class="app-backend-sidenav-sublinks d-flex flex-column pl-4"
					*ngIf="!isSettingsCollapsed"
				>
					<a
						[routerLink]="adminMOAT"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{exact:true}"
						class="pb-2"
						>Mortgage Online Advice Tool</a
					>
					<a
						[routerLink]="adminMobileApp"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{exact:true}"
						class="pb-2"
						>Mobile App</a
					>
				</div>
			</div>
		</ng-container>

		<!--TL - Announcement -->
		<ng-container *appRestrictTo="['TAS']">
			<div class="app-backend-sidenav-link mb-3">
				<h6 class="d-flex align-items-center clickable">
					<svg
						class="announcement-icon mr-2"
						viewBox="0 0 28 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
						<g
							id="SVGRepo_tracerCarrier"
							stroke-linecap="round"
							stroke-linejoin="round"
						></g>
						<g id="SVGRepo_iconCarrier">
							<path
								fill="#00263e"
								d="M26.0008 7.35334C26.0008 5.54646 24.2879 4.23059 22.5421 4.69622L4.04548 9.62939C2.84171 9.95045 2.00415 11.0407 2.00415 12.2865V15.7136C2.00415 16.9594 2.84171 18.0496 4.04548 18.3707L7 19.1587V19.5C7 21.9853 9.01472 24 11.5 24C13.2899 24 14.8357 22.955 15.5606 21.4419L22.5421 23.3039C24.2879 23.7695 26.0008 22.4536 26.0008 20.6468V7.35334ZM8.50057 19.5589L14.0722 21.0449C13.5474 21.9168 12.5918 22.5 11.5 22.5C9.86282 22.5 8.53195 21.1886 8.50057 19.5589Z"
							></path>
						</g>
					</svg>
					<a
						class="annoucement-link"
						[routerLink]="adminAnnouncements"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{exact:true}"
					>
						ANNOUNCEMENT
					</a>
				</h6>
			</div>
		</ng-container>
	</div>
</div>
