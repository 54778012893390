import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { RouteService } from 'src/app/core/config/route.service';
import { numUtil } from 'src/app/util/util';
import { BodyRow, HeaderRow } from '../../../shared/simple-table/simple-table.model';
import { DashboardQuery } from '../../../state/dashboard.query';
import { FGPipeline } from '../state/p.model';
import { PQuery } from '../state/p.query';
import { PService } from '../state/p.service';

@Component({
  selector: 'app-fgp',
  templateUrl: './fgp.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FgpComponent implements OnInit, OnDestroy {
	/**
	 * key pair value where the key is keys in mortgage pipeline response
	 * and value is the real status value.
	 *
	 * The real status value is used for values to search when user
	 * is routed to pipeline search due to clicking a status.
	 */
	/**
	 * Destroy event observable. Is watched to know when to
	 * unsubscribe subscribers. emits on ngOnDestroy.
	 */
	private onDestroy$ = new Subject<void>();

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.FGIsLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.FGError$;
	/**
	 * F&G pipeline data
	 */
	data$: Observable<FGPipeline> = this.query.FGData$;

	isServiceLoading$: Observable<boolean> = this.query.isServiceLoading$;
	/**
	 * transform FG data to simple-table object.
	 *
	 * For status label, show text from FGPropertyName
	 * For route get from routeService with status from FGPropertyRealStatus
	 */
	viewData$ = this.data$.pipe(
		withLatestFrom(
			this.dashboardQuery.hasSelectedAllAdvisers$,
			this.dashboardQuery.allAdvisersId$
		),
		map(([data, hasSelectedAllAdvisers, allAdviserIds]): BodyRow[] => {
			if (data === null || data === undefined) {
				return [];
			} else {
				const selectedAdvisers = this.dashboardQuery.getValue();
				const totalStatus = Object.keys(data)
					?.filter((key) => key !== 'Total')
					?.map((key) => data[key].Status);

				return Object.keys(data)?.map<BodyRow>((key) => {
					return [
						{
							text: data[key].Status,
							route: this.routeService.fgPipelineWithParams({
								advisers: !hasSelectedAllAdvisers
									? selectedAdvisers.adviserFilter?.join(',')
									: allAdviserIds?.join(','),
								statuses:
									data[key].Status !== 'Total'
										? data[key].Status
										: totalStatus?.join(','),
							}),
						},
						{
							text: numUtil.formatToCurrency(data[key].Premium),
							class: 'col-4',
						},
						{
							text: numUtil.formatToCount(data[key].Count),
							class: 'col-2',
						},
					];
				});
			}
		})
	);

	/** header for simple-table header object */
	header: HeaderRow = [
		{ text: 'Status' },
		{ text: 'Total Premium', class: 'col-4' },
		{ text: 'Count', class: 'col-2' }
	];

	constructor(
		private routeService: RouteService,
		private dashboardQuery: DashboardQuery,
		private query: PQuery,
		private service: PService
	) {}

	/** trigger FG pipeline data fetch when adviser changes */
	ngOnInit() {
		this.dashboardQuery.adviserFilter$
		.pipe(
			filter((x) => x.length > 0),
			withLatestFrom(
				this.isServiceLoading$
			),
			takeUntil(this.onDestroy$)
		)
		.subscribe(([advisers, isServiceLoading]) => {
			if (!isServiceLoading) {
				this.service.GetAllPipelines(advisers);
			}
		});
	}

	/**
	 * Gets onDestroy$ to emit and unsubscribe.
	 */
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
