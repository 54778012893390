import { Query } from '@datorama/akita';
import { KiwisaverUiState, KiwisaverUiStore } from './kiwisaver-ui.store';
import { Injectable } from '@angular/core';

@Injectable()
export class KiwisaverUiQuery extends Query<KiwisaverUiState> {
	isSearching$ = this.select(x => x.isSearching);
	isExporting$ = this.select(x => x.isExporting);
	columnFormPopupOpen$ = this.select(x => x.columnFormPopupOpen);
	isColumnSaving$ = this.select(x => x.isColumnSaving);

	propSort$ = this.select(x => x.propSort);
	sort$ = this.select(x => x.sort);

	currentSort$ = this.select(x => x.currentSort);

	constructor(protected kiwisaverUiStore: KiwisaverUiStore) {
		super(kiwisaverUiStore);
	}
}
