import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';
import { computeAgeBasedOnBirthdate } from 'src/app/util/util';
import { FamilyHistoryMapper } from '../../../../../shared/models/client-review-template/history/family.mapper';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';
import { HistoryService } from '../../states/history/history.service';
import { FamilyHistoryClient } from 'src/app/shared/models/client-review-template/history/family.model';
import { DependentState } from 'src/app/shared/models/client-review-template/dependent/dependent.model';

declare var $: any;

@Component({
	selector: 'app-history-family',
	templateUrl: './family.component.html',
	styleUrls: ['./family.component.scss'],
})
export class FamilyComponent implements OnInit, OnDestroy {
	@Input() isAdviceProcessEnded: boolean;

	@Input() isFamilyLoading$: Observable<boolean>;
	@Input() clients: Observable<DependentState[]>;
	@Input() currentTerms$: Observable<ViewDisplayValue[]>;
	@Input() addFn: ({ family, adviceProcessId }) => Observable<any>;
	@Input() editFn: ({ family }) => Observable<any>;
	form: UntypedFormGroup;
	elseMinusFamily = true;
	apcrtfm = this.service.APCRTFM$;
	adviceProcessId: string;
	clientList: FamilyHistoryClient[] = [];

	onDestroy$ = new Subject<void>();
	familyArray = [];
	arrayItemsFamily: {
		id: number;
		title: string;
	}[];
	submitted: boolean;
	isAddMode = false;
	isEditMode = false;

	get FamilyArray() {
		return this.form.get('familyArray') as UntypedFormArray;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private query: ClientReviewTemplateQuery,
		private service: ClientReviewTemplateService,
		private route: ActivatedRoute,
		private historyService: HistoryService
	) {
		this.form = this.fb.group({
			familyArray: this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.adviceProcessId = this.route.snapshot.paramMap.get('adviceProcessId');

		this.clients.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
			if (data) {
				this.clientList = data?.map((x) => {
					return {
						display: x.name,
						value: x.customerId,
						isDefault: false,
						birthDate: x?.birthDate,
						age: computeAgeBasedOnBirthdate(x?.birthDate),
					};
				});
			}
		});

		this.query.familyHistory$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.familyArray = data;
				this.prepFamilyHistory();
			});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	prepFamilyHistory() {
		while (this.FamilyArray.length !== 0) {
			this.FamilyArray.removeAt(0);
		}
		(this.familyArray ?? [])?.forEach((x) => {
			// const client = this.clientList?.find((y) => y.value === x.client);

			const fg = this.fb.group({
				client: [x.client, [Validators.required, NoWhitespaceValidator]],
				familyMember: x.familyMember,
				ageAtDiagnosis: x.ageAtDiagnosis,
				healthIssue: x.healthIssue,
				details: x.details,
				currentlyExcludedLoaded: x.currentlyExcludedLoaded,

				btnDisable: true,
				btnSaveFamily: false,
				btnEditFamily: true,
				isSaving: false,
				cRTId: x.cRTId,
			});
			fg.patchValue({ detailsFamily: x.details });
			fg.disable();
			this.FamilyArray?.push(fg);
		});
	}

	saveFamily(index: number) {
		this.submitted = true;
		if (this.form.invalid) {
      this.submitted = false;
      return;
    }

		this.FamilyArray.controls[index].get('btnSaveFamily').setValue(false);
		this.FamilyArray.controls[index].get('isSaving').setValue(true);

		const formArray = this.form.controls.familyArray as UntypedFormArray;
		formArray.controls[index].disable();

		const formValue = this.form.getRawValue().familyArray[index];
		const family = FamilyHistoryMapper.mapToUpsert(
			this.form.controls.familyArray.value[index],
			formValue || null
		);
		if (family.cRTId > 0) {
			this.editFn({ family })
				.pipe(
					finalize(() => {
						this.submitted = false;
						this.isEditMode = false;
						this.FamilyArray.controls[index].get('isSaving').setValue(false);
					})
				)
				.subscribe();
		} else {
			this.addFn({
				family,
				adviceProcessId: Number(
					this.route.snapshot.paramMap.get('adviceProcessId')
				),
			})
				.pipe(
					finalize(() => {
						this.submitted = false;
						this.isAddMode = false;
						this.FamilyArray.controls[index].get('isSaving').setValue(false);
						this.FamilyArray.controls[index]
							.get('btnEditFamily')
							.setValue(true);
					})
				)
				.subscribe();
		}
	}

	editFamily(index: number) {
		this.isEditMode = true;
		this.FamilyArray.controls[index].get('btnSaveFamily').setValue(true);
		this.FamilyArray.controls[index].get('btnDisable').setValue(false);
		this.FamilyArray.controls[index].get('btnEditFamily').setValue(false);

		const formArray = this.form.controls.familyArray as UntypedFormArray;
		formArray.controls[index].enable();
	}

	deleteFamily(index: number) {
		this.historyService.removeCrt(this.familyArray[index].cRTId).subscribe(
			() => {
				this.FamilyArray.removeAt(index);
				this.familyArray?.splice(index, 1);
				this.isEditMode = false;
				this.isAddMode = false;
			},
			() => this.FamilyArray.controls[index].get('isSaving').setValue(false)
		);
	}

	removeFamily(index: number) {
		if (index < this.familyArray.length) {
			this.FamilyArray.controls[index].get('btnSaveFamily').setValue(false);
			this.FamilyArray.controls[index].get('btnEditFamily').setValue(true);
			const formArray = this.form.controls.familyArray as UntypedFormArray;
			formArray.controls[index].disable();
			this.revertToOriginalData(index);
		} else {
			this.FamilyArray.removeAt(index);
		}
		this.isAddMode = false;
		this.isEditMode = false;
	}

	revertToOriginalData(index: number) {
    const cRTId = this.FamilyArray.controls[index].get('cRTId').value;
    const originalData = this.query.getValue().familyHistory.find(asset => asset.cRTId === +cRTId);
    if (originalData) {
      this.FamilyArray.controls[index].patchValue({
        ...originalData,
      });
    }
  }

	collapseFamily() {
		$('#collapseFamily').toggle();
		this.elseMinusFamily = !this.elseMinusFamily;
	}

	onSelect(index: number) {
		this.FamilyArray.controls[index].get('btnDisable').setValue(false);
	}

	addFamily() {
		this.isAddMode = true;
		this.FamilyArray?.push(
			this.fb.group({
				client: ['', [Validators.required, NoWhitespaceValidator]],
				familyMember: [''],
				ageAtDiagnosis: [''],
				healthIssue: [''],
				details: [''],
				currentlyExcludedLoaded: [''],

				btnDisable: true,
				btnSaveFamily: true,
				btnEditFamily: false,
				isSaving: false,
			})
		);
	}
}
