<form [formGroup]='form' [class.form-submitted]="formSubmitted">
  <div class="container-fluid px-0">

    <div class="row tap-container">
      <div class="col mb-4"></div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-2 text-secondary">Trading Name</div>
      <div class="col-sm-4">
        <input class="form-control" type="text" id="TradingName" formControlName="tradingName" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-2 text-secondary">Business Phone Number</div>
      <div class="col-sm-4">
        <input class="form-control" type="text" id="BusinessPhoneNumber" formControlName="businessPhoneNumber" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-2 text-secondary">Business Email Address</div>
      <div class="col-sm-4">
        <input class="form-control" type="text" id="BusinessEmailAddress" formControlName="businessEmailAddress" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-2 text-secondary">Facebook Link</div>
      <div class="col-sm-4">
        <input class="form-control" type="text" id="FacebookLink" formControlName="facebookLink" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-2 text-secondary">LinkedIn Link</div>
      <div class="col-sm-4">
        <input class="form-control" type="text" id="LinkedInLink" formControlName="linkedInLink" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-2 text-secondary">Unsubscribe Grouping ID</div>
      <div class="col-sm-4">
        <input class="form-control" type="text" id="LinkedInLink" formControlName="unsubscribeGroupingID" />
      </div>
    </div>

    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary"></div>
      <ng-container *appRestrictTo="['BBEE']">
        <div class="col-sm-4 text-right" *ngIf="!isSaving">
          <div class="custom-file">
            <button class="btn btn-primary btn-Stat" (click)="sendBtnClick()">
              Save
            </button>
          </div>
        </div>
        <div class="col-sm-4 text-right">
          <div class="custom-file">
            <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
          </div>
        </div>
      </ng-container>
    </div>

  </div>



  <div id="gridContainer">
    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary font-weight-bold">Email Logos</div>
    </div>



    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary">Business Header Logo</div>
      <ng-container *ngIf="!showbusinessHeaderLogoLabel">
        <div class="col-sm-4 d-flex align-items-center word-break">
          <div class="row">
            <span class="text-primary cursor-pointer pl-3" (click)="downloadLink(businessConfig.BusinessHeaderLogo)">
              {{businessHeaderLogoLabel}}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="!showbusinessHeaderLogoLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button *appRestrictTo="['BBEE']" class="tap-btn tap-btn--default btn-sm mr-2" type="button"
              (click)="uploadModal('HeaderLogo')">
              Replace
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showbusinessHeaderLogoLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('HeaderLogo');">
              Delete
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showbusinessHeaderLogoLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('HeaderLogo')">
                  Upload
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>


    <!-- Business Footer Logo -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary">Business Footer Logo</div>
      <ng-container *ngIf="!showbusinessFooterLogoLabel">
        <div class="col-sm-4 d-flex align-items-center word-break">
          <div class="row">
            <span class="text-primary cursor-pointer pl-3" (click)="downloadLink(businessConfig.BusinessFooterLogo)">
              {{businessFooterLogoLabel}}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="!showbusinessFooterLogoLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button *appRestrictTo="['BBEE']" class="tap-btn tap-btn--default btn-sm mr-2" type="button"
              (click)="uploadModal('FooterLogo')">
              Replace
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showbusinessFooterLogoLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('FooterLogo');">
              Delete
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showbusinessFooterLogoLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('FooterLogo')">
                  Upload
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary font-weight-bold">Attachments</div>
    </div>

    <!-- Disclosure Document -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary">Disclosure Document</div>
      <ng-container *ngIf="!showdisclosureDocumentLabel">
        <div class="col-sm-4 d-flex align-items-center word-break">
          <div class="row">
            <span class="text-primary cursor-pointer pl-3" (click)="downloadLink(businessConfig.DisclosureDocument)">
              {{disclosureDocumentLabel}}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="!showdisclosureDocumentLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button *appRestrictTo="['BBEE']" class="tap-btn tap-btn--default btn-sm mr-2" type="button"
              (click)="uploadModal('Disclosure')">
              Replace
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showdisclosureDocumentLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Disclosure');">
              Delete
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showdisclosureDocumentLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Disclosure')">
                  Upload
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- Declaration Document -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary">Declaration Document</div>
      <ng-container *ngIf="!showdeclarationDocumentLabel">
        <div class="col-sm-4 d-flex align-items-center word-break">
          <div class="row">
            <span class="text-primary cursor-pointer pl-3" (click)="downloadLink(businessConfig.DeclarationDocument)">
              {{declarationDocumentLabel}}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="!showdeclarationDocumentLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button *appRestrictTo="['BBEE']" class="tap-btn tap-btn--default btn-sm mr-2" type="button"
              (click)="uploadModal('Declaration')">
              Replace
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showdeclarationDocumentLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Declaration');">
              Delete
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showdeclarationDocumentLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Declaration')">
                  Upload
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>


    <!-- Scope of Services -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary">Scope of Services</div>
      <ng-container *ngIf="!showscopeOfServiceLabel">
        <div class="col-sm-4 d-flex align-items-center word-break">
          <div class="row">
            <span class="text-primary cursor-pointer pl-3" (click)="downloadLink(businessConfig.ScopeOfService)">
              {{scopeOfServiceLabel}}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="!showscopeOfServiceLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button *appRestrictTo="['BBEE']" class="tap-btn tap-btn--default btn-sm mr-2" type="button"
              (click)="uploadModal('Scope of Service')">
              Replace
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showscopeOfServiceLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Scope of Service');">
              Delete
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showscopeOfServiceLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Scope of Service')">
                  Upload
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>


    <!-- Letter of Authority -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary">Letter of Authority</div>
      <ng-container *ngIf="!showletterOfAuthorityLabel">
        <div class="col-sm-4 d-flex align-items-center word-break">
          <div class="row">
            <span class="text-primary cursor-pointer pl-3" (click)="downloadLink(businessConfig.LetterOfAuthority)">
              {{letterOfAuthorityLabel}}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="!showletterOfAuthorityLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button *appRestrictTo="['BBEE']" class="tap-btn tap-btn--default btn-sm mr-2" type="button"
              (click)="uploadModal('Letter of Authority')">
              Replace
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showletterOfAuthorityLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Letter of Authority');">
              Delete
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showletterOfAuthorityLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Letter of Authority')">
                  Upload
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>


    <!-- Business Overview -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 text-secondary">Business Overview</div>
      <ng-container *ngIf="!showbusinessOverviewLabel">
        <div class="col-sm-4 d-flex align-items-center word-break">
          <div class="row">
            <span class="text-primary cursor-pointer pl-3" (click)="downloadLink(businessConfig.BusinessOverview)">
              {{businessOverviewLabel}}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="!showbusinessOverviewLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button *appRestrictTo="['BBEE']" class="tap-btn tap-btn--default btn-sm mr-2" type="button"
              (click)="uploadModal('Business Overview')">
              Replace
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showbusinessOverviewLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Business Overview');">
              Delete
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showbusinessOverviewLabel">
          <ng-container *appRestrictTo="['BBEE']">
            <div class="btn-toolbar" role="toolbar">
              <div class="btn-group mr-2" role="group">
                <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Business Overview')">
                  Upload
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

  </div>


</form>