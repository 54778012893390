<button
  id="hideModalButton"
  class="tap-btn tap-btn-outline modal-btn-close"
  (click)="bsModalRef.hide()"
>
  <span class="material-icons"> close </span>
</button>
<div class="modal-header">
  <h5 class="modal-title">
    {{ header }}
  </h5>
</div>
<div class="modal-body">
  <form [formGroup]="form" novalidate *ngIf="form">
    <div class="form-group m-0">
      <div class="position-relative clearfix mb-2">
        <span class="tap-required-field" *ngIf="adviceProcessForm?.invalid"
          >*</span
        >
        <label for="adviserAp" class="font-weight-bold pl-1"
          >Advice Process</label
        >
        <select
          formControlName="processCode"
          (change)="handleProcessSelectionChange()"
          class="form-control"
          id="selectAp"
        >
          <option value=""></option>
          <option *ngFor="let ap of processCodes" [value]="ap.value">
            {{ ap.display }}
          </option>
        </select>
      </div>
      <div class="position-relative clearfix mb-2" 
        *ngIf="
          adviceProcessForm?.value === adviceProcessCode?.LRClaim ||
          adviceProcessForm?.value === adviceProcessCode?.FGClaim">
        <span class="tap-required-field" *ngIf="customerServiceIdForm?.invalid">*</span>
        <label for="policyAp" class="font-weight-bold pl-1">Policies</label>
        <div class="d-flex align-items-center">
        <app-chips
            id="customerServiceId"
            textboxId="customerServiceId"
            #customerServiceIdChips
            formControlName="customerServiceId"
            [choices]="( adviceProcessForm?.value === adviceProcessCode?.LRClaim) ? filteredLrPolicyTypes : filteredFgPolicyTypes | sortBy: 'display'"
            textboxClass="theme-crm-field"
            [collapsible]="false"
            (onChangeEvent)="checkPolicyChange($event)"
            >
          </app-chips>
        </div>
      </div>
      <div class="position-relative clearfix mb-2">
        <span class="tap-required-field" *ngIf="adviserForm?.invalid">*</span>
        <label for="adviserAp" class="font-weight-bold pl-1">Adviser</label>
        <span class="custom-dropdown w-100">
          <select
            formControlName="adviser"
            class="form-control theme-crm-field-disabled"
            id="adviserAp"
          >
            <option value=""></option>
            <option *ngFor="let d of advisers" [value]="d.value">
              {{ d.display }}
            </option>
          </select>
        </span>
      </div>
      <div class="position-relative clearfix mb-2" 
        *ngIf="
          adviceProcessForm?.value === adviceProcessCode?.LRClaim ||
          adviceProcessForm?.value === adviceProcessCode?.FGClaim">
        <label for="claimManagerAp" class="font-weight-bold pl-1">Claim Manager</label>
        <span class="custom-dropdown w-100">
          <select
            formControlName="claimManager"
            class="form-control theme-crm-field-disabled"
            id="claimManagerAp"
          >
            <option value=""></option>
            <option *ngFor="let d of claimManagers" [value]="d.value">
              {{ d.display }}
            </option>
          </select>
        </span>
      </div>
      <div class="position-relative clearfix mb-2">
        <span class="tap-required-field" *ngIf="clientsInvolvedForm?.invalid"
          >*</span
        >
        <label for="adviserAp" class="font-weight-bold pl-1"
          >{{(adviceProcessForm?.value === adviceProcessCode?.LRClaim ||
           adviceProcessForm?.value === adviceProcessCode?.FGClaim)? 'Claimant':'Clients Involved'}}</label
        >
        <div class="d-flex align-items-center">
          <app-chips
            id="clientsInvolved"
            textboxId="clientsInvolved"
            #clientsInvolvedChips
            formControlName="clientsInvolved"
            [choices]="(adviceProcessForm?.value === adviceProcessCode?.MortgageAdvice && !isCompany) ? mortgageClientsInvolved : clientsInvolved | sortBy: 'display'"
            textboxClass="theme-crm-field"
            [collapsible]="false"
          >
          </app-chips>
          
          <ng-container *ngIf="hasQuickAdd && showQuickAdd">
            <button
              id="quickAddModalButton"
              (click)="quickAddModal()"
              class="icon-btn pr-0 pl-1 w-auto h-auto tap-text-color-h6"
            >
              <i class="material-icons md-16">person_add</i>
            </button>
          </ng-container>
        </div>
        <ng-container *ngIf="clientsInvolvedForm.hasError('loatMaxClients')">
          <p class="error">{{ errorMessages.loatMaxClients }}</p>
        </ng-container>
        <ng-container *ngIf="clientsInvolvedForm.hasError('moatMaxClients')">
          <p class="error">{{ errorMessages.moatMaxClients }}</p>
        </ng-container>
        <ng-container *ngIf="clientsInvolvedForm.hasError('koatMaxClients')">
          <p class="error">{{ errorMessages.koatMaxClients }}</p>
        </ng-container>
      </div>
      <div
        *ngIf="
          adviceProcessForm.value === adviceProcessCode.LRAdviceNew ||
          adviceProcessForm.value === adviceProcessCode.LRAdviceReview ||
          adviceProcessForm.value === adviceProcessCode.MortgageAdvice || 
          adviceProcessForm.value === adviceProcessCode.KiwiSaverAdvice
        "
        class="position-relative clearfix mb-2"
      >
        <div *ngIf="isOnlineAvailable" class="form-check form-check-inline m-0">
          <span class="tap-required-field" *ngIf="isOnlineForm?.invalid"
          >*</span
        >
          <label [class]="isOnlineForm?.invalid ? 'rdbtn mr-1 ml-2' : 'rdbtn mr-1'">
            <input
              id="isOnline"
              name="isOnline"
              type="radio"
              formControlName="isOnline"
              [value]="true"
              class="rdbtn"
              (change)="recheckValidity()"
            />
          </label>
          <label class="form-check-label pr-2 font-weight-bold" for="isOnline">Online</label>

          <label class="rdbtn mr-1">
            <input
              id="isOffline"
              name="isOnline"
              type="radio"
              formControlName="isOnline"
              [value]="false"
              class="rdbtn"
              (change)="recheckValidity()"
            />
          </label>
          <label class="form-check-label font-weight-bold" for="isOffline">Offline</label>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end modal-footer">
      <ng-container *ngIf="!isSaving; else loader">
        <button
          id="saveButton"
          type="button"
          class="tap-btn tap-btn--default m-0"
          (click)="save()"
          [disabled]="form.invalid"
        >
          Add New
        </button>
      </ng-container>
      <ng-template #loader>
        <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
      </ng-template>
    </div>
  </form>
</div>
