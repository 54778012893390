import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { BlStaffService } from '@core/staff/bl-staff.service';
import { util } from '@core/util/util.service';
import { BLStaff, StaffSettingTypes } from '@domain/bl-staff/bl-staff.model';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import {
	BLStaffsService,
	TransferActivityModel,
} from '@domain/bl-staff/bl-staffs.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ServicesQuery } from '@domain/service/services.query';
import { UserQuery } from '@domain/user/user.query';
import { ViewDisplayValue } from '@models/_general/display-value.viewmodel';
import { viewBusiness } from '@modules/user/viewmodels/viewBusiness';
import { DeleteDropdownModalComponent } from '@shared/modal/delete-dropdown-modal/delete-dropdown-modal.component';
import { ProvideAccessModalComponent } from '@shared/modal/provide-access-modal/provide-access-modal.component';
import { TransferActivityModalComponent } from '@shared/modal/transfer-activity-modal/transfer-activity-modal.component';
import {
	BlStaffSettingsModel,
	IsActiveOptionsDd,
} from '@shared/models/_general/bl-staff.model';
import { validMomentDate } from '@shared/validator/valid-moment-date/valid-moment-date';
import { strUtil, util as utility } from '@util/util';
import { validatorUtil } from '@util/validator.util';
import sort from 'fast-sort';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	BehaviorSubject,
	Observable,
	Observer,
	Subject,
	combineLatest,
	firstValueFrom,
	of,
	throwError,
} from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	startWith,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { BlStaffViewmodel } from '../../viewmodels/bl-staff.viewmodel';
import { viewSecGroup } from '../../viewmodels/viewSecGroup';
import { DisabledFields } from './user-details.form-fields';
import { SasReference } from '@shared/models/client-profile/primary-client/primary-client.model';
import { omit } from 'ramda';

@Component({
	selector: 'app-user-details',
	templateUrl: './user-details.component.html',
	styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
	private onDestroy$: Subject<void> = new Subject<void>();
	userStatus: number;
	@Input()
	set staff(value: BlStaffViewmodel) {
		this._data = value;
		this.userStatus = +value.IsActive;
	}
	@Input() staffSettings: BlStaffSettingsModel[];
	@Input() companyCode: string;
	@Input() secGroups: viewSecGroup[] = [];
	@Input() LRP: ViewDisplayValue[] = [];
	@Input() SCS: ViewDisplayValue[] = [];
	@Input() SCT: ViewDisplayValue[] = [];
	@Input() SAS: ViewDisplayValue[] = [];
	@Input() SQCKI: ViewDisplayValue[] = [];
	@Input() UPDTP: ViewDisplayValue[] = [];
	@Input() UPDTC: ViewDisplayValue[] = [];
	@Input() UPDTS: ViewDisplayValue[] = [];
	@Input() businesses: viewBusiness[] = [];
	@Input() isTapLevel: boolean;
	@Input() header = null;
	@Input() isAdd = false;
	@Input() userSecurityGroup: string;
	@Input() updateStaffFn$: (data: BlStaffViewmodel) => Observable<any> = (
		data
	) => of(data);
	@Input() onSubmitProvideAccess$: (data: BlStaffViewmodel) => Observable<any> =
		(data) => of(data);
	@Input() addPhoto$: (data: {
		FileName: string;
		Photo: string;
	}) => Observable<any> = (data) => of(data);
	@Input() upsertStaffQualificationFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() updateStaffGoalsFn$: (data: any) => Observable<any> = (data) =>
		of(data);
	@Input() upsertStaffDocumentFn$: (data: any) => Observable<any> = (data) =>
		of(data);
	@Input() removeStaffDocumentFn$: (
		staffId: number,
		settingsId: number
	) => Observable<any> = (data) => of(data);
	@Input() getUserDocumentsFn$: (data: any) => Observable<any> = (data) =>
		of(data);
	@Input() deleteUserDocumentsFn$: (data: any) => Observable<any> = (data) =>
		of(data);
	@Input() upsertPdTrackingFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Output() cancelEvent = new EventEmitter();
	@Output() deleteUser: EventEmitter<number> = new EventEmitter<number>();
	@ViewChild('showInAdviser') showInAdviserRef: ElementRef;
	_data: BlStaffViewmodel;
	form: FormGroup;
	tempServices: string[];
	serviceChoices: ViewDisplayValue[] = [];
	adviserServicesAllowed: ViewDisplayValue[] = [];
	adviserServices$ = new BehaviorSubject<ViewDisplayValue[]>([]);
	adviserIdsAssignedList = [];
	submitted = false;
	isSaving = false;
	isSavingProvideAccess = false;
	isSavingShowAdviserList = false;
	isSavingLeadHolingUser = false;
	isSavingTransferActivity = false;
	isSavingPhoto = false;
	isEdit = false;
	isActiveOptions = IsActiveOptionsDd;
	staffChoices: any[] = [];
	showInAdviserListFilter = ['BO', 'AM', 'A'];
	leadGeneratorSecurityGroup = ['LG', 'LGI', 'LGE'];

	hasCPMOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasMOAT$,
		this.businessConfigQuery.hasCPMOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasMOAT$,
		this.userQuery.isUserHasCPMOAT$,
	]).pipe(
		map(
			([
				hasbusinessAP,
				hasbusinessMOAT,
				hasBusinessCPMOAT,
				hasUserAP,
				hasUserMOAT,
				hasUserCPMOAT,
			]) =>
				hasbusinessAP &&
				hasbusinessMOAT &&
				hasBusinessCPMOAT &&
				hasUserAP &&
				hasUserMOAT &&
				hasUserCPMOAT
		)
	);
	isConversionEnabled$ = this.businessConfigQuery.conversionFeature$;
	loggedUserId$ = this.userQuery.userId$;

	hasBusinessService: string[] = [];

	get CanEdit() {
		return this.isTapLevel || ['BO', 'BM']?.includes(this.userSecurityGroup);
	}

	// Making this to use in Transfer Activity, Document Tab, Disclosure Documents Tab and Provider & Commission tab permission check
	// atm, TAPNZ-13286, TAPNZ-13092 & TAPNZ-13093
	// If the requirements change in the future, create a separate one
	get CanModifyOtherUserSettings() {
		return (
			(this.isTapLevel || ['BM', 'BO']?.includes(this.userSecurityGroup)) &&
			!this.isAdd
		);
	}
	get CanAccessPdTracking() {
		return (
			!this.isAdd &&
			['SO', 'SA', 'SM', 'BO', 'BAI', 'A', 'AM', 'BAV', 'BM']?.includes(
				this.userSecurityGroup
			)
		);
	}
	get CanProvideAccess() {
		return this.isTapLevel && !this.isAdd;
	}
	get CanUpdateShowInAdviserList() {
		return this.isTapLevel && !this.isAdd;
	}
	get CanUpdateAdviserServices() {
		return (
			!!this.ShowInAdviserList.value ||
			!!this.showInAdviserListFilter.includes(this.SecurityGroup?.value)
		);
	}
	get IsBlanket() {
		return this.companyCode?.toLowerCase() === 'blanket';
	}
	get isSavingOthers() {
		return (
			this.isSavingShowAdviserList ||
			this.isSavingLeadHolingUser ||
			this.isSavingProvideAccess ||
			this.isSavingTransferActivity ||
			this.isSavingPhoto
		);
	}
	get FirstName() {
		return this.form.get('FirstName');
	}
	get LastName() {
		return this.form.get('LastName');
	}
	get EmailAddress() {
		return this.form.get('EmailAddress');
	}
	get UserServices() {
		return this.form.get('Services');
	}
	get PersonalEmailAddress() {
		return this.form.get('StaffSettings.PersonalEmailAddress');
	}
	get ShowInAdviserList() {
		return this.form.get('StaffSettings.ShowInAdviserList');
	}
	get LeadHoldingUser() {
		return this.form.get('StaffSettings.LeadHoldingUser');
	}
	get AdviserServices() {
		return this.form.get('StaffSettings.AdviserServices');
	}
	get SecurityGroup() {
		return this.form.get('SecurityGroup');
	}
	get StartDate() {
		return this.form.get('StartDate');
	}
	get UserStatus() {
		return this.form.get('IsActive');
	}
	get isHaven() {
		return this.companyCode?.toLowerCase() === 'haven';
	}
	get CreationDate() {
		return !!this._data?.CreationDate
			? util.MomentToDateStringDisplay(this._data?.CreationDate)
			: '';
	}
	get DeactivationDate() {
		return !!this._data?.StaffSettings?.DeactivationDate
			? util.MomentToDateStringDisplay(
					this._data?.StaffSettings?.DeactivationDate
			  )
			: '';
	}
	get ReactivationDate() {
		return !!this._data?.StaffSettings?.ReactivationDate
			? util.MomentToDateStringDisplay(
					this._data?.StaffSettings?.ReactivationDate
			  )
			: '';
	}

	constructor(
		private fb: FormBuilder,
		private blStaffQuery: BLStaffsQuery,
		private servicesQuery: ServicesQuery,
		private modalService: BsModalService,
		private blStaffService: BLStaffsService,
		private blService: BlStaffService,
		private blsStaffService: BLStaffsService,
		private businessConfigQuery: BusinessConfigQuery,
		private loggerService: LoggerService,
		private userQuery: UserQuery,
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef
	) {
		this.form = this.fb.group({
			AdviserManager: null,
			BirthDate: [''],
			BusinessPhone: [''],
			EmailAddress: ['', [Validators.required, validatorUtil.emailValidator]],
			FirstName: ['', Validators.required],
			HomeAddress: [''],
			IsActive: [1, Validators.required],
			LastName: ['', Validators.required],
			MobilePhone: [''],
			SecurityGroup: ['', Validators.required],
			Services: [],
			StaffID: null,
			StartDate: ['', [Validators.required, validMomentDate()]],
			AdviserIdsAssigned: [],
			StaffSettings: this.fb.group({
				FacebookLink: [''],
				AdviserServices: [],
				LinkedInLink: [''],
				SignOffName: [''],
				CorrespondenceEmail: [''],
				ShowInAdviserList: [{ value: false, disabled: false }],
				LeadHoldingUser: [{ value: false, disabled: false }],
				CommisionStructure: [''],
				FSPRegistrationDate: [''],
				FSPNumber: [''],
				ContractType: [''],
				ResignationDate: [''],
				ExitDate: [''],
				PersonalEmailAddress: ['', validatorUtil.emailValidator],
				PersonalMobilePhone: [''],
				BusinessName: [''],
				HomePhone: [''],
				EmergencyFullName: [''],
				EmergencyRelationship: [''],
				EmergencyContactNumber: [''],
				PhotoURL: [''],
			}),
		});
	}

	ngOnInit() {
		this.getBusinessProfileServices();
		this.adviserIdsAssignedList = this._data.AdviserIdsAssigned;
		this.serviceChoices = this.servicesQuery
			.getAll()
			.filter((x) => this.hasBusinessService.includes(x.ServiceCode))
			?.map((x) => ViewDisplayValue.Map(x.ServiceCode, x.Service));

		if (this.isAdd) {
			this.form.enable();
			this.ShowInAdviserList?.disable();
		} else {
			this.form.reset(this._data);
			this.disableEdit();
		}

		combineLatest([
			this.UserServices.valueChanges.pipe(startWith(null)),
			this.AdviserServices.valueChanges.pipe(startWith(null)),
		])
			.pipe(
				tap(([formServices]) => {
					const services = formServices ?? this._data?.Services;
					this.setAllowedAdviserServices(services);
					this.setAdviserServicesChoices(services);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.blStaffQuery.selectAll().subscribe((staff) => {
			this.staffChoices = staff
				?.filter((x) => x.IsActive || x.IsActive === 0)
				?.map((s) => {
					const viewDisplay = ViewDisplayValue.Map(
						s.StaffID?.toString(),
						`${s.FirstName} ${s.LastName}`,
						false,
						s.IsActive === 0 ? false : true
					);

					return {
						securityGroup: this.mapSecGroup(s?.SecurityGroup),
						status: s.IsActive,
						...viewDisplay,
						value: +viewDisplay.value,
					};
				})
				?.sort(this.compare)
				?.filter((x) => {
					if (this._data && this._data.StaffID) {
						return x.value !== (this._data.StaffID || '');
					}
					return true;
				});

			this.staffChoices = sort(this.staffChoices).by([
				{ desc: (u) => u.isActive },
				{
					asc: (u) =>
						u.display && typeof u.display === 'string'
							? u.display?.toLowerCase()
							: u.display,
				},
			]);
		});
	}

	ngAfterViewInit() {
		if (this.showInAdviserRef?.nativeElement) {
			this.renderer?.removeAttribute(
				this.showInAdviserRef?.nativeElement,
				'disabled'
			);
		}
	}

	getAdviserServicesValue() {
		const formValue = this.AdviserServices?.value;

		return typeof formValue == 'string'
			? utility.tryParseJson(formValue)
			: formValue;
	}

	setAllowedAdviserServices(services: string[]) {
		const adviserServices = this.SAS;
		const allowedServices =
			adviserServices?.filter((x) => {
				const code = SasReference?.find(
					(r) => r?.display === x?.value
				)?.service;
				return (
					!!services?.includes(code) &&
					!!this.hasBusinessService?.includes(code)
				);
			}) || [];
		this.adviserServicesAllowed = allowedServices;
	}

	setAdviserServicesChoices(services: string[]) {
		const adviserServices = this.SAS;
		if (!this.isEdit && !this.isAdd) {
			this.adviserServices$.next(adviserServices);
			return;
		}
		// Set Adviser Dropdown
		const formServices = this.getAdviserServicesValue();
		const choices =
			adviserServices?.filter((x) => {
				const code = SasReference?.find(
					(r) => r?.display === x?.value
				)?.service;
				return (
					(!!services?.includes(code) &&
						!!this.hasBusinessService?.includes(code)) ||
					!!formServices?.includes(x?.value)
				);
			}) || [];

		this.adviserServices$.next(choices);
		this.cdr.detectChanges();
	}

	resetAdviserServices(event) {
		this.tempServices = [...(this.tempServices || [])];
		const reset = () => {
			// Reset selected adviser services values
			const services = this.getAdviserServicesValue();
			const newServices = services?.filter(
				(x) => !!this.adviserServicesAllowed?.find((c) => x === c?.value)
			);
			this.AdviserServices.setValue(JSON.stringify(newServices || []));
		};
		setTimeout(() => {
			reset();
		}, 10);
	}

	prepareData(): BlStaffViewmodel {
		const formValue = this.form.getRawValue();
		const staffSettings = formValue.StaffSettings;
		return {
			...this._data,
			...formValue,
			Services: this.form.controls['Services'].value?.filter((x) => x),
			EmailAddress: strUtil.removeSpace(
				this.form.controls['EmailAddress'].value
			),
			StaffSettings: {
				...this._data.StaffSettings,
				AdviserServices: staffSettings.AdviserServices,
				FacebookLink: staffSettings.FacebookLink,
				LinkedInLink: staffSettings.LinkedInLink,
				SignOffName: staffSettings.SignOffName,
				CorrespondenceEmail: strUtil.removeSpace(
					staffSettings.CorrespondenceEmail
				),
				PersonalEmailAddress: strUtil.removeSpace(
					staffSettings.PersonalEmailAddress
				),
				CommisionStructure: staffSettings.CommisionStructure,
				FSPRegistrationDate: staffSettings.FSPRegistrationDate,
				FSPNumber: staffSettings.FSPNumber,
				ContractType: staffSettings.ContractType,
				ResignationDate: staffSettings.ResignationDate,
				ExitDate: staffSettings.ExitDate,
				PersonalMobilePhone: staffSettings.PersonalMobilePhone,
				BusinessName: staffSettings.BusinessName,
				HomePhone: staffSettings.HomePhone,
				EmergencyFullName: staffSettings.EmergencyFullName,
				EmergencyRelationship: staffSettings.EmergencyRelationship,
				EmergencyContactNumber: staffSettings.EmergencyContactNumber,
				ShowInAdviserList: staffSettings?.ShowInAdviserList,
				LeadHoldingUser: staffSettings?.LeadHoldingUser,
			},
		};
	}

	mapSecGroup(secGroup: string) {
		const security = this.secGroups.find(
			(sec) => sec.securityGroupCode === secGroup
		);

		if (security) {
			return security.securityGroupName;
		}

		return secGroup;
	}

	removeSpaces() {
		return (this.form.value.emailAddress = (
			this.form.value.emailAddress || ''
		)?.replace(/\s/g, ''));
	}

	delete(id: number) {
		this.deleteUser.emit(id);
	}

	enableEdit() {
		this.tempServices = this.UserServices.getRawValue() || [];
		this.isEdit = true;
		this.form.enable();
		this.disableFieldsByRole();
	}

	disableFieldsByRole() {
		if (!!this.isTapLevel) {
			return;
		}
		const formConfig = DisabledFields.find(
			(x) => x?.code === this.userSecurityGroup
		);
		if (!!formConfig) {
			formConfig?.basicfields?.forEach((x) => {
				this.form.get(x)?.disable();
			});
			formConfig?.staffSettingFields?.forEach((x) => {
				this.form.get(`StaffSettings.${x}`)?.disable();
			});
		}
	}

	disableEdit() {
		this.tempServices = null;
		this.isEdit = false;
		this.isSaving = false;
		this.submitted = false;
		this.form.disable();
		this.ShowInAdviserList.enable();
		this.LeadHoldingUser.enable();
	}

	cancelEdit() {
		const data = omit(['Services'], this._data);
		this.form.patchValue(data);
		this.UserServices.setValue(this.tempServices);
		this.disableEdit();
	}

	cancel() {
		this.submitted = false;
		this.cancelEvent.emit();
	}

	compare(a, b) {
		const splitA = a.display?.split(' ');
		const splitB = b.display?.split(' ');

		const firstA = splitA[0];
		const firstB = splitB[0];

		const lastA = splitA[splitA.length - 1];
		const lastB = splitB[splitB.length - 1];

		if (firstA > firstB) {
			return 1;
		} else if (firstA < firstB) {
			return -1;
		} else {
			if (lastA < lastB) {
				return -1;
			} else if (lastA > lastB) {
				return 1;
			} else {
				return 0;
			}
		}
	}

	saveBtn() {
		this.submitted = true;
		if (
			(this.EmailAddress.invalid && this.EmailAddress.value?.trim() !== '') ||
			(this.PersonalEmailAddress.invalid &&
				this.PersonalEmailAddress.value?.trim() !== '')
		) {
			this.loggerService.Warning({}, 'Please enter a valid email');
			return;
		}
		if (this.form.invalid) {
			return;
		}
		this.EmailAddress.invalid;
		const formValue = this.form.getRawValue();
		if (
			+formValue?.IsActive === 0 &&
			+this._data.IsActive !== 0 &&
			!!this._data?.StaffID
		) {
			this.deleteStaff();
		} else {
			this.saveStaff();
		}
	}

	saveStaff() {
		of(this.prepareData())
			.pipe(
				tap(() => (this.isSaving = true)),
				concatMap(this.updateStaffFn$),
				catchError((error) => {
					if (error) {
						this.isSaving = false;
					}
					throw error;
				}),
				tap(() => {
					this.isSaving = false;
					this.disableEdit();
				}),
				take(1)
			)
			.subscribe();
	}

	deleteStaff() {
		const confirm = new Observable((obs: Observer<any>) => {
			obs.next(obs);
			obs.complete();
		}).pipe(
			delay(1000), // Need to add delay after transferring
			tap(() => this.saveStaff()),
			take(1)
		);

		const staffs = this.blStaffQuery.getAll();
		const activeBlStaffs = staffs
			?.filter(
				(x) => x.IsActive && x.StaffID !== this.form?.get('StaffID')?.value
			)
			?.map((y) => {
				return {
					StaffID: y.StaffID,
					FirstName: y.FirstName,
					LastName: y.LastName,
				};
			})
			?.sort((a, b) => {
				const mapA = a.FirstName?.concat(' ', a.LastName);
				const mapB = b.FirstName?.concat(' ', b.LastName);
				return mapA?.localeCompare(mapB);
			});

		const blInitState = {
			header: 'Delete Staff',
			staff: {
				StaffID: this.form?.get('StaffID')?.value,
				FirstName: this.form?.get('FirstName')?.value,
				LastName: this.form?.get('LastName')?.value,
			},
			advisers: activeBlStaffs,
			confirm$: confirm,
			transfer: this.transferActivity,
		};

		this.modalService.show(DeleteDropdownModalComponent, {
			class: 'modal-dialog-centered',
			initialState: blInitState,
			ignoreBackdropClick: true,
		});
	}

	uploadPhoto$ = (req: { FileName: string; Photo: string }) =>
		of(req).pipe(
			tap(() => (this.isSavingPhoto = true)),
			map((x) => ({ ...x, StaffID: +this.form?.get('StaffID')?.value })),
			concatMap(this.addPhoto$),
			finalize(() => (this.isSavingPhoto = false)),
			take(1)
		);

	downloadDocumentFn$ = (req: { documentId: number; fileName: string }) => {
		return this.blService.getDocumentUrlById(req?.documentId).pipe(
			tap((fileUrl) => {
				if (!fileUrl) {
					return;
				}
				const a = document.createElement('a');
				a.href = fileUrl;
				a.setAttribute('download', req.fileName || '');
				a.click();
			}),
			take(1)
		);
	};

	exportHistory(file: any, fileName: string) {
		const name = `${fileName}.csv`;
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		const url = window.URL.createObjectURL(file);
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
		window.URL.revokeObjectURL(url);
	}

	/** SHOW IN ADVISER LIST */
	updateShowAdviserList() {
		of(this.prepareData())
			.pipe(
				filter(() => !this.isSaving && !this.isEdit && !this.isSavingOthers),
				map((formValue) => {
					this.isSavingShowAdviserList = true;
					const newValue = !formValue.StaffSettings?.ShowInAdviserList;
					this.ShowInAdviserList.setValue(newValue);
					return {
						...formValue,
						StaffSettings: {
							...formValue.StaffSettings,
							ShowInAdviserList: newValue,
						},
					};
				}),
				concatMap(this.updateStaffFn$),
				finalize(() => (this.isSavingShowAdviserList = false)),
				take(1)
			)
			.subscribe();
	}
	/** End of SHOW IN ADVISER LIST */

	/** LEAD HOLDING USER */
	updateLeadHoldingUser() {
		of(this.prepareData())
			.pipe(
				filter(() => !this.isSaving && !this.isEdit && !this.isSavingOthers),
				map((formValue) => {
					this.isSavingLeadHolingUser = true;
					const newValue = !formValue.StaffSettings?.LeadHoldingUser;
					this.LeadHoldingUser.setValue(newValue);
					return {
						...formValue,
						StaffSettings: {
							...formValue.StaffSettings,
							LeadHoldingUser: newValue,
						},
					};
				}),
				concatMap(this.updateStaffFn$),
				finalize(() => (this.isSavingLeadHolingUser = false)),
				take(1)
			)
			.subscribe();
	}
	/** End of LEAD HOLDING USER */


	/** PROVIDE ACCESS */
	saveProvideAccess = (data) =>
		new Observable<any>((obs) => {
			obs.next({ ...this.prepareData(), BLSecurityGroups: data });
			obs.complete();
		}).pipe(
			tap(() => (this.isSavingProvideAccess = true)),
			mergeMap(this.onSubmitProvideAccess$),
			finalize(() => (this.isSavingProvideAccess = false)),
			take(1)
		);

	provideAccess() {
		const initialState = {
			header: 'Provide Access',
			securityGroups: this.secGroups,
			savefn: this.saveProvideAccess,
		};
		this.modalService.show(ProvideAccessModalComponent, {
			class: 'modal-dialog-centered',
			initialState,
			ignoreBackdropClick: true,
		});
	}
	/** End of PROVIDE ACCESS */

	/** TRANSFER ACTIVITY */
	transferActivity = (data: TransferActivityModel) =>
		new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.blStaffService.TransferActivity(x)),
			tap((x) => {
				if (x) {
					this.exportHistory(x, 'activities');
				}
			}),
			finalize(() => {
				this.saveStaff();
			}),
			take(1)
		);

	TransferActivity() {
		const activeBlStaffs = this.blStaffQuery
			.getAll()
			?.filter(
				(x) => x.IsActive && x.StaffID !== this.form?.get('StaffID')?.value
			)
			?.map((y) => {
				return {
					StaffID: y.StaffID,
					FirstName: y.FirstName,
					LastName: y.LastName,
				};
			});

		const blInitState = {
			header: 'Transfer Activities',
			staff: {
				StaffID: this.form?.get('StaffID')?.value,
				FirstName: this.form?.get('FirstName')?.value,
				LastName: this.form?.get('LastName')?.value,
			},
			advisers: activeBlStaffs?.sort((a, b) => {
				const mapA = a.FirstName?.concat(' ', a.LastName);
				const mapB = b.FirstName?.concat(' ', b.LastName);
				return mapA?.localeCompare(mapB);
			}),
			transferFn: this.transferActivityNoSave,
		};

		this.modalService.show(TransferActivityModalComponent, {
			class: 'modal-dialog-centered',
			initialState: blInitState,
			ignoreBackdropClick: true,
		});
	}

	transferActivityNoSave = (data: TransferActivityModel) =>
		this.blStaffService.TransferActivity(data).pipe(
			tap((x) => {
				if (x) {
					this.exportHistory(x, 'activities');
				}
			}),
			take(1)
		);

	/** End of TRANSFER ACTIVITY */

	/** TRANSFER CLIENT */
	transferClient = (data: TransferActivityModel) =>
		new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.blStaffService.TransferClient(x)),
			tap((x) => {
				if (x) {
					this.exportHistory(x, 'clients');
				}
			}),
			finalize(() => {
				this.saveStaff();
			}),
			take(1)
		);

	getTransferClientAdviserChoices(): Observable<BLStaff[]> {
		const staffList = this.blStaffQuery.allUnfilteredAdvisers$;
		return staffList.pipe(
			take(1),
			map((list) => {
				return list
					.filter(
						(x: BLStaff) => x.StaffID !== this.form?.get('StaffID')?.value
					)
					.sort((a, b) => {
						const mapA = a.FirstName?.concat(' ', a.LastName);
						const mapB = b.FirstName?.concat(' ', b.LastName);
						return mapA?.localeCompare(mapB) ?? 0;
					});
			})
		);
	}

	async TransferClient() {
		const activeBlStaffs = await firstValueFrom(
			this.getTransferClientAdviserChoices()
		);

		const customMessage = `Choose an Adviser to replace '${
			this._data.FirstName || ''
		} ${this._data.LastName || ''}'`;

		const blInitState = {
			header: 'Transfer Clients',
			customMessage,
			staff: {
				StaffID: this.form?.get('StaffID')?.value,
				FirstName: this.form?.get('FirstName')?.value,
				LastName: this.form?.get('LastName')?.value,
			},
			advisers: activeBlStaffs,
			transferFn: this.transferClientNoSave,
		};

		this.modalService.show(TransferActivityModalComponent, {
			class: 'modal-dialog-centered',
			initialState: blInitState,
			ignoreBackdropClick: true,
		});
	}

	transferClientNoSave = (data: TransferActivityModel) =>
		this.blStaffService.TransferClient(data).pipe(
			tap((x) => {
				if (x) {
					this.exportHistory(x, 'clients');
				}
			}),
			take(1)
		);
	/** End of TRANSFER CLIENTS */

	/** DOCUMENTS TAB */
	removeFileFn$ = ({ file, type }) => {
		return of(this.removeSetDocument(file, type)).pipe(
			mergeMap(() => of(this.prepareData()).pipe(mergeMap(this.update))),
			catchError(() => {
				this.removeSetDocument(file, type);
				return throwError('error');
			}),
			take(1)
		);
	};

	removeSetDocument(data, type) {
		this._data = Object.assign(this._data, {
			...this._data,
			StaffSettings: {
				...this._data.StaffSettings,
				DisclosureDocument:
					type === 'Disclosure' && !data.FileName
						? null
						: this._data.StaffSettings.DisclosureDocument,
				// tslint:disable-next-line: max-line-length
				DeclarationDocument:
					type === 'Declaration' && !data.FileName
						? null
						: this._data.StaffSettings.DeclarationDocument,
				ScopeOfService:
					type === 'Scope of Service' && !data.FileName
						? null
						: this._data.StaffSettings.ScopeOfService,
				// tslint:disable-next-line: max-line-length
				LetterOfAuthority:
					type === 'Letter of Authority' && !data.FileName
						? null
						: this._data.StaffSettings.LetterOfAuthority,
				// tslint:disable-next-line: max-line-length
				BusinessOverview:
					type === 'Business Overview' && !data.FileName
						? null
						: this._data.StaffSettings.BusinessOverview,
			},
		});
	}

	uploadFileFn$ = (data: { file; docType }) => {
		return this.blStaffService.UploadDocument(data.file).pipe(
			mergeMap((id: any) => this.blService.getDocumentById(+id)),
			mergeMap((document: any) => {
				this.setDocument(data, document.DocumentID);
				return of('Success');
			}),
			mergeMap(() => of(this.prepareData()).pipe(mergeMap(this.update))),
			catchError(() => {
				this.setDocument(data, null);

				return throwError('error');
			}),
			take(1)
		);
	};

	setDocument(data, documentId) {
		this._data = Object.assign(this._data, {
			...this._data,
			StaffSettings: {
				...this._data.StaffSettings,
				DisclosureDocument:
					data.docType === 'Disclosure' && document
						? documentId
						: this._data.StaffSettings.DisclosureDocument,
				// tslint:disable-next-line: max-line-length
				DeclarationDocument:
					data.docType === 'Declaration' && document
						? documentId
						: this._data.StaffSettings.DeclarationDocument,
				ScopeOfService:
					data.docType === 'Scope of Service' && document
						? documentId
						: this._data.StaffSettings.ScopeOfService,
				// tslint:disable-next-line: max-line-length
				LetterOfAuthority:
					data.docType === 'Letter of Authority' && document
						? documentId
						: this._data.StaffSettings.LetterOfAuthority,
				// tslint:disable-next-line: max-line-length
				BusinessOverview:
					data.docType === 'Business Overview' && document
						? documentId
						: this._data.StaffSettings.BusinessOverview,
			},
		});
	}

	update = (data) =>
		of(data).pipe(
			map((x) => BlStaffViewmodel.mapToEditModel(x)),
			mergeMap((x) => this.blsStaffService.update(x)),
			take(1)
		);

	getDocumentById(id) {
		return this.blService.getDocumentById(+id);
	}
	/** End of DOCUMENTS TAB */

	/** ACCESSIBILITY SETTINGS TAB */
	updateAccessiblitySettings$ = (event: any) => {
		const { staff, adviserIds } = event;
		return this.blStaffService
			.updateStaffSettings(
				staff.StaffID,
				adviserIds,
				StaffSettingTypes.UserTrackerAccessibility
			)
			.pipe(
				tap(() => {
					this._data.AdviserIdsAssigned = adviserIds?.AdviserIdsAssigned || [];
				})
			);
	};
	/** End of ACCESSIBILITY SETTINGS TAB */

	private getBusinessProfileServices() {
		const hasService$ = combineLatest([
			this.businessConfigQuery.hasLR$,
			this.businessConfigQuery.hasM$,
			this.businessConfigQuery.hasFG$,
			this.businessConfigQuery.hasK$,
			this.businessConfigQuery.hasI$,
			this.businessConfigQuery.hasAP$,
			this.businessConfigQuery.hasCRT$,
			this.businessConfigQuery.hasMOAT$,
			this.businessConfigQuery.hasKOAT$,
			this.businessConfigQuery.hasCPMOAT$,
			this.businessConfigQuery.hasCPMOATD$,
			this.businessConfigQuery.hasCAR$
		]).pipe(
			map(([LR, M, FG, K, I, AP, CRT, MOAT, KOAT, CPMOAT, CPMOATD, CAR]) => {
				return { LR, M, FG, K, I, AP, CRT, MOAT, KOAT, CPMOAT, CPMOATD, CAR };
			})
		);
		hasService$.subscribe((services) => {
			this.hasBusinessService = Object.keys(services).filter(
				(k) => services[k]
			);
		});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
