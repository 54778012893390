import { numUtil } from '../../../../../util/util';
import { divide } from 'ramda';

export type curFreqType =
	| 'Annually'
	| '6-Monthly'
	| 'Quarterly'
	| 'Monthly'
	| 'Fortnightly'
	| 'Weekly'
	| 'Daily'
	| 'Hourly';

export const convertToAnnual = (amount: number, curFreq: string) => {
	const result = curFreq as curFreqType;
	return amount && numUtil.isNumber(amount) && result
		? amount * +freqToAnnual[result]
		: 0;
};

export const convertToMonthly = (
	amount: number,
	curFreq: curFreqType = 'Annually',
	noRounding: boolean = false
) => {
	const getAnnual = convertToAnnual(+amount, curFreq);
	return noRounding
		? +divide(+getAnnual, 12)
		: +numUtil.formatToNumCurrency(divide(+getAnnual, 12));
};

const freqToAnnual = {
	Annually: 1,
	'6-Monthly': 2,
	Quarterly: 4,
	Monthly: 12,
	Fortnightly: 26,
	Weekly: 52,
	Daily: 365,
	Hourly: 8760,
};

export const convertAnnualToCustomFreq = (
	amount: number,
	frequency: curFreqType = 'Monthly'
) => {
	// Convert annual to any frequency specified
	const freq = freqToAnnual[frequency];
	return +divide(+amount, freq);
};
