<div class="cash-deposit-form">
	<form [formGroup]="form">
		<!-- Cash / Deposit -->
		<div class="form-row">
			<div class="col-12 col-md-6 col-lg-6 p-0">
				<div class="form-row m-0 p-0">
					<div class="col-12 mb-4">
						<label class="font-weight-bold">Cash / Deposit</label>
					</div>
				</div>

				<!-- Sale Proceeds -->
				<div class="form-row m-0 p-0" *ngIf="purpose?.isSellingProperties">
					<div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
						<label for="cash">Sale Proceeds</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="saleProceeds"
								class="form-control crt-form-control"
								type="text"
								formControlName="saleProceeds"
								(focusout)="runComputations()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>

				<!-- Cash -->
				<div class="form-row m-0 p-0">
					<div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
						<label for="cash">Cash</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="cash"
								class="form-control crt-form-control"
								type="text"
								formControlName="cash"
								(focusout)="runComputations()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>

				<!-- Gift -->
				<div class="form-row m-0 p-0">
					<div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
						<label for="gift">Gift</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="gift"
								class="form-control crt-form-control"
								type="text"
								formControlName="gift"
								(focusout)="runComputations()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>

				<!-- KiwiSaver -->
				<div class="form-row m-0 p-0">
					<div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
						<label for="kiwisaver">KiwiSaver</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="kiwisaver"
								class="form-control crt-form-control"
								type="text"
								formControlName="kiwiSaver"
								(focusout)="runComputations()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>

				<!-- Cash from existing loan -->
				<div class="form-row m-0 p-0">
					<div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
						<label for="cashFromExistingLoan">Cash from existing loan</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="cashFromExistingLoan"
								class="form-control crt-form-control"
								type="text"
								formControlName="cashFromExistingLoan"
								(focusout)="runComputations()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>

				<!-- Other -->
				<div formArrayName="other">
					<div
						class="form-row align-items-center"
						*ngFor="let arrayItem of other.controls; let pIndex = index"
						[formGroupName]="pIndex"
					>
						<div class="col-12 col-md-6">
							<input
								id="other_{{ pIndex }}"
								class="form-control crt-form-control other-field"
								type="text"
								formControlName="otherField"
								placeholder="Other"
							/>
						</div>
						<div class="col-12 col-md-5">
							<div class="dollar-icon">
								<input
									id="otherValue_{{ pIndex }}"
									class="form-control crt-form-control"
									type="text"
									formControlName="otherValue"
									placeholder="Amount"
									(focusout)="runComputations()"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
						<div class="col-12 col-md-1">
							<button
								id="deleteOtherItemButton_{{ pIndex }}"
								type="button"
								class="d-none d-lg-inline d-md-none d-xs-none d-sm-none	icon-btn h-auto w-auto pt-2"
								(click)="deleteOtherItem(pIndex)"
							>
								<i class="material-icons md-16">delete</i>
							</button>
						</div>
					</div>
				</div>

				<div class="form-row m-0 p-0 mt-4 pl-2">
					<button
						id="addOthereItemButton"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow col-auto p-1 px-4"
						(click)="addOtherItem()"
					>
						Add Other +
					</button>
				</div>

				<!-- Total Contribution -->
				<div class="form-row m-0 p-0 mt-4">
					<div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
						<label
							for="totalContribution"
							class="font-weight-bold tap-text-primary"
							>Total Deposit Contribution</label
						>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="totalContribution"
								class="form-control crt-form-control"
								type="text"
								formControlName="totalContribution"
								currencyMask
							/><i>$</i>
						</div>
					</div>
				</div>

				<!-- New Lending Required -->
				<div class="form-row m-0 p-0 mt-2">
					<div class="col-12 col-md-6 col-lg-6 pt-1 d-flex align-items-center">
						<label
							for="newLendingRequired"
							class="font-weight-bold tap-text-primary"
							>Total New Lending at {{ activeBank }}</label
						>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="newLendingRequired"
								class="form-control crt-form-control"
								type="text"
								formControlName="newLendingRequired"
								currencyMask
							/><i>$</i>
						</div>
					</div>
				</div>
			</div>

			<!-- Available in Assets -->
			<div class="col-12 col-md-6 col-lg-6 p-0 pl-2">
				<div class="form-row m-0 p-0 mb-4">
					<div class="col-12 col-md-6 col-lg-6">
						<label class="font-weight-bold">Available in Assets?:</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6 text-right">
						<label>Use?</label>
					</div>
				</div>

				<!-- Sale Proceeds -->
				<div class="form-row m-0 p-0" *ngIf="purpose?.isSellingProperties">
					<div class="col-12 col-md-4 col-lg-4 d-flex align-items-center">
						<label for="availableSaleProceeds">Sale Proceeds</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="availableSaleProceeds"
								class="form-control crt-form-control"
								type="text"
								formControlName="availableSaleProceeds"
								currencyMask
							/>
							<i>$</i>
						</div>
					</div>
					<div class="col-12 col-md-2 col-lg-2">
						<div class="custom-control custom-checkbox pt-2 pb-1 text-right">
							<input
								id="saleProceedsTick"
								type="checkbox"
								class="chckbox custom-control-input"
								formControlName="saleProceedsTick"
								(change)="availableSaleProceeds()"
							/>
							<label
								class="custom-control-label"
								for="saleProceedsTick"
							></label>
						</div>
					</div>
				</div>

				<!-- Cash -->
				<div class="form-row m-0 p-0">
					<div class="col-12 col-md-4 col-lg-4 d-flex align-items-center">
						<label for="availableCash">Cash</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="availableCash"
								class="form-control crt-form-control"
								type="text"
								formControlName="availableCash"
								currencyMask
							/>
							<i>$</i>
						</div>
					</div>
					<div class="col-12 col-md-2 col-lg-2">
						<div class="custom-control custom-checkbox pt-2 pb-1 text-right">
							<input
								id="cashTick"
								type="checkbox"
								class="chckbox custom-control-input"
								formControlName="cashTick"
								(change)="availableCashTick()"
							/>
							<label class="custom-control-label" for="cashTick"></label>
						</div>
					</div>
				</div>

				<!-- Blank space -->
				<div class="form-row m-0 p-0 d-none d-md-block d-lg-block">
					<div class="col-12 col-md-12 col-lg-12 d-flex align-items-center">
						<span class="blank-space">&nbsp;</span>
					</div>
				</div>

				<!-- KiwiSaver -->
				<div class="form-row m-0 p-0">
					<div class="col-12 col-md-4 col-lg-4 d-flex align-items-center">
						<label for="availableKiwiSaver">KiwiSaver</label>
					</div>
					<div class="col-12 col-md-6 col-lg-6">
						<div class="dollar-icon">
							<input
								id="availableKiwiSaver"
								class="form-control crt-form-control"
								type="text"
								formControlName="availableKiwiSaver"
								currencyMask
							/>
							<i>$</i>
						</div>
					</div>
					<div class="col-12 col-md-2 col-lg-2">
						<div class="custom-control custom-checkbox pt-2 pb-1 text-right">
							<input
								id="kiwiSaverTick"
								type="checkbox"
								class="chckbox custom-control-input"
								formControlName="kiwiSaverTick"
								(change)="availableKiwiSaverTick()"
							/>
							<label class="custom-control-label" for="kiwiSaverTick"></label>
						</div>
					</div>
				</div>

				<!-- END-->
			</div>
		</div>
	</form>
</div>
