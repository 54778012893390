import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[numeric]'
})

export class NumericalDirective {

    constructor(private el: ElementRef) {
    }

    // tslint:disable-next-line:no-input-rename
    @Input('decimals') decimals = 0;

    private specialKeys: Array<string> = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
      'Control'
    ];

    private check(value: string, decimals: number)
    {
      if (decimals <= 0) {
        return String(value)?.match(new RegExp(/^\d+$/));
      } else {
          const regExpString = '^\\s*((\\d+(\\.\\d{0,' + decimals + '})?)|((\\d*(\\.\\d{1,' + decimals + '}))))\\s*$'
          return String(value)?.match(new RegExp(regExpString));
      }
    }

    @HostListener('keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {
      const ckey = ((event.ctrlKey || event.metaKey) && event.key === 'c');
      const vkey = ((event.ctrlKey || event.metaKey) && event.key === 'v');

      // Allow Backspace, tab, end, delete and home keys
      if (this.specialKeys?.indexOf(event.key) !== -1) {
        return;
      }

      const current: string = this.el.nativeElement.value;
      const next: string = current?.concat(event.key);

      // Checks if user hits copy/paste
      if ((ckey || vkey) && (next?.match(/[A-Za-z]/g).length === 1) && (event.key === 'v' || event.key === 'c')) {
        return;
      }

      if (next && !this.check(next, this.decimals)) {
        event.preventDefault();
      }
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
      const clipboardData = event.clipboardData;
      const pastedText = clipboardData.getData('text');
      const prevValue = +(this.el.nativeElement.value?.match(/\./g)?.length);
      const currValue = +(pastedText?.match(/\./g)?.length);

      if ((prevValue + currValue) > 1 || !this.check(pastedText, this.decimals)) {
        event.preventDefault();
      }
    }
}
