<button class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
    close
  </span>
</button>
<div class="modal-header">
  <h1 class="modal-title" id="myModalLabel">
    {{ pageTitle }}
  </h1>
</div>
<div class="modal-body">
  <div class="fallback clearfix">
    <div class="clearfix">
      <div *ngFor="let g of groupList" class="d-flex tab-row-container">
        <input
          type="radio"
          name="selectedGroup"
          value="g.groupID"
          class="radio-btn mr-2"
          (click)="selectTransferedValue(g)"
        />

        <div
          class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 w-100"
          [ngClass]="{
            'list--green': g.color == 'green',
            'list--yellow': g.color == 'yellow',
            'list--red': g.color == 'red',
            'list--grey': g.color == 'grey'
          }"
        >
          <strong>{{ g.name }}</strong>

          <div *ngIf="g.serviceCode === 'lr'">
            <span class="font-italic">Inforce API:{{ g.aPI }}</span>
          </div>
          <div *ngIf="g.serviceCode === 'm'">
            <span class="font-italic">Total Lending :{{ g.total }}</span>
          </div>

          <span class="font-italic">Start Date {{ g.startDate | momentDatetime }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
