import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, iif, of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../../../../core/base/api.service';
import { ReviewApplicationSettingsState } from '../../../../../../../../modules/mortgage-settings/review-application-settings/state/review-application-settings.model';
import { SettingsTypes } from '../../../../../../../../modules/mortgage-settings/state/mortgage-settings.model';
import { AdviceProcessSectionCodes } from '../../../../../../../../shared/models/advice-process/advice-process.model';
import {
	DocumentModelState,
	DocumentUploadState,
} from '../../../../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../../../../util/util';
import { CrtMortgageStore } from '../../../../state/crt-mortgage.store';
import { QueingDownloadState, ReviewApplicationState } from './review-application.model';
import { ReviewApplicationQuery } from './review-application.query';
import { ReviewApplicationStore } from './review-application.store';

@Injectable({ providedIn: 'root' })
export class ReviewApplicationService {
	reviewApplication$ = this.query.activeReviewApplication$;
	isLoading$ = this.query.selectLoading();

	constructor(
		private store: ReviewApplicationStore,
		private query: ReviewApplicationQuery,
		private api: ApiService,
		private moatStore: CrtMortgageStore
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(parentCRTId: number) {
		this.store.setLoading(true);
		const endpoint = `crt/${AdviceProcessSectionCodes.Application}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.ReviewApplication}`;

		return this.api.get<ReviewApplicationState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) =>
				x?.map((data, i) => (i === 0 ? { ...data, parentCRTId } : data))
			),
			tap((x) =>
				applyTransaction(() => {
					this.store.set(x);
					this.store.setActive(parentCRTId);
				})
			),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of({ parentCRTId }))
		);
	}

	add(data) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.post(`crt`, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.set([{ ...data, cRTId: +x }]);
					this.store.setActive(data?.parentCRTId);
				})
			),
			catchError(() => of(null))
		);
	}

	update(data) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.put(`crt/${data?.cRTId}`, body).pipe(
			tap(() =>
				applyTransaction(() => this.store.update(+data?.parentCRTId, data))
			),
			catchError(() => of(null))
		);
	}

	getReviewAppSettings() {
		const endpoint = `crt/settings/0/${SettingsTypes.ReviewApplication}`;
		return this.api.get<ReviewApplicationSettingsState>(endpoint).pipe(
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.moatStore.setReviewApplicationSettings(x);
				})
			),
			catchError(() => of(null))
		);
	}

	getDocumentByID(documentId: number) {
		return this.getDocumentFile(documentId).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			concatMap((x) =>
				iif(
					() => x?.documentLink,
					this.getDocumentFromURL(x?.documentLink),
					of(null)
				)
			),
			catchError(() => of(null))
		);
	}

	getDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`).pipe(
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			catchError(() => EMPTY)
		);
	}

	getDocumentFromURL(url: string) {
		const dateTime = new Date().getTime();
		url = `${url}?${dateTime}`;

		return this.api
			.getExternalResource(url, { responseType: 'text' })
			.pipe(catchError(() => EMPTY));
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	finalizedAndDownload(req: QueingDownloadState) {
		const endpoint = `crt/function/queing`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.post(endpoint, body);
	}

	setTriggerLeaveSoa(b: any) {
		applyTransaction(() => {
			this.moatStore.setTriggerLeaveSoa(b);
		});
	}

	setHasSoaChanges(b: boolean) {
		applyTransaction(() => {
			this.moatStore.setHasSoaChanges(b);
		});
	}

	setHasReviewApplicationChanges(b: boolean) {
		applyTransaction(() => {
			this.moatStore.setHasReviewApplicationChanges(b);
		});
	}

}
