import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ClientProfileState, ClientProfileStore } from './client-profile.store';

@Injectable()
export class ClientProfileQuery extends Query<ClientProfileState> {
	customerID$ = this.select((state) =>
		state.primaryClient ? state.primaryClient.customerID : null
	);
	contactStatus$ = this.select((state) =>
		state.primaryClient ? state.primaryClient.contactStatus : null
	);

	/**
	 * @returns observable object of primary customer
	 */
	primaryClient$ = this.select((state) => state.primaryClient);

	/**
	 * @returns observable array of secondary clients
	 */
	secondaryClients$ = this.select((state) => state.secondaryClients);

	/**
	 * @returns observable array of secondary companies
	 */
	secondaryBusinesses$ = this.select((state) => state.secondaryBusinesses);

	/**
	 * @returns observable array of secondary trusts
	 */
	secondaryTrusts$ = this.select((state) => state.secondaryTrusts);

	/**
	 * @returns observable array of secondary professionals
	 */
	secondaryProfessionals$ = this.select(
		(state) => state.secondaryProfessionals
	);

	/**
	 * @returns observable array of linked contacts
	 */
	linkedContacts$ = this.select((state) => state.linkedContacts);

	/**
	 * @returns observable array of l&R Insurance
	 */
	lrInsurance$ = this.select((state) => state.lrInsurance);

	/**
	 * @returns observable array of Mortgage
	 */
	mortgage$ = this.select((state) => state.mortgage);

	/**
	 * @returns observable array of Property & Assets (Security)
	 */
	propertyAsset$ = this.select((state) => state.propertyAsset);

	/**
	 * @returns observable array of Assets
	 */
	asset$ = this.select((state) => state.asset);

	/**
	 * @returns observable array of Liabilities
	 */
	liability$ = this.select((state) => state.liability);

	/**
	 * @returns observable array of F&G Insurance
	 */
	fgInsurance$ = this.select((state) => state.fgInsurance);

	/**
	 * @returns observable array of KiwiSaver
	 */
	kiwiSavers$ = this.select((state) => state.kiwiSavers);

	/**
	 * @returns observable array of Investments
	 */
	investments$ = this.select((state) => state.investments);

	/**
	 * @returns observable array of Edit History
	 */
	histories$ = this.select((state) => state.histories);

	/**
	 * @returns observable array of documents
	 */
	documents$ = this.select((state) => state.documents);

	activityTimeline$ = this.select((state) => state.activityTimeline);

	currentActivityCriterias$ = this.select(
		(state) => state.currentActivityCriterias
	);

	/**
	 * Advice Process List
	 */
	adviceProcesses$ = this.select((state) => state.adviceProcesses);

	sciIsLoading$ = this.select((state) => state.sciIsLoading);
	sccIsLoading$ = this.select((state) => state.sccIsLoading);
	sctIsLoading$ = this.select((state) => state.sctIsLoading);
	scpIsLoading$ = this.select((state) => state.scpIsLoading);

	lrIsLoading$ = this.select((state) => state.lrIsLoading);
	mIsLoading$ = this.select((state) => state.mIsLoading);
	paIsLoading$ = this.select((state) => state.paIsLoading);
	aIsLoading$ = this.select((state) => state.aIsLoading);
	fgIsLoading$ = this.select((state) => state.fgIsLoading);
	kIsLoading$ = this.select((state) => state.kIsLoading);
	iIsLoading$ = this.select((state) => state.iIsLoading);

	documentIsLoading$ = this.select((state) => state.documentIsLoading);
	historyIsLoading$ = this.select((state) => state.historyIsLoading);
	adviceProcessIsLoading$ = this.select(
		(state) => state?.adviceProcessIsLoading
	);

	constructor(protected store: ClientProfileStore) {
		super(store);
	}
}
