import { Injectable } from '@angular/core';
import { Observable, concat, from, throwError } from 'rxjs';
import { shareReplay, concatMap, reduce, catchError, tap, withLatestFrom, map, mergeMap } from 'rxjs/operators';
import { AStore } from './a.store';
import { AQuery } from './a.query';
import { produce } from 'immer';
import * as R from 'ramda';
import { ApiService, JsonResultStatus } from '../../../../../../core/base/api.service';
import { GetActivityWidgetParam, GetActivitiesWidgetResponse } from '../../../../../../shared/models/_general/activity.model';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { staticConf } from '../../../../../../core/config/static-config.service';
import { UserQuery } from '../../../../../../domain/user/user.query';
import { UpdateBLStaffDetailRequest, StaffSettings } from '../../../../../../shared/models/_general/staff-settings.model';
import { ActivityViewModel } from '../../../../../../shared/models/_general/activity.viewmodel';
import { ActivityService } from 'src/app/core/services/activity/activity.service';

@Injectable()
export class AService {

	constructor(
		private api: ApiService,
		private store: AStore,
		private query: AQuery,
		private activityService: ActivityService,
		private userQuery: UserQuery
	) { }


	/**
	 * Get all activities
	 * Store all not complete activities in state
	 * @param param GetActivityParam
	 */
	public GetActivities(param: GetActivityWidgetParam) {
		this.store.setLoading(true);

		const batchLength = 100;
		const getIndexesToFetch: (res: GetActivitiesWidgetResponse) => number[] = R.pipe(
			(res: GetActivitiesWidgetResponse) => Math.ceil(res.TotalCount / batchLength),
			(totalPages: number) =>
				Array(totalPages)
					?.fill(1)
					?.map((x, i) => i + 1)
					?.slice(1)
		);
		const searchRequest = (request: GetActivityWidgetParam) =>
			this.api.post3<GetActivitiesWidgetResponse>('widgets?type=A', request);

		const firstPage$ = of(param).pipe(
			map(
				produce(draft => {
					draft.Paging = {
						Index: 1,
					};
				})
			),
			mergeMap(searchRequest),
			shareReplay()
		);

		return firstPage$.pipe(
			mergeMap(res =>
				concat(
					firstPage$,
					from(getIndexesToFetch(res)).pipe(
						map(i =>
							produce(param, draft => {
								draft.Paging = {
									Index: i,
								};
							})
						),
						concatMap(req2 => searchRequest(req2))
					)
				)
			),
			reduce((acc, v) =>
				produce(acc, draft => {
					draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
				})
			),
			catchError(err => {
				this.store.setError('Error fetching data!');
				return throwError(new Error(err));
			})
		).subscribe(
			res => this.store.set(res.SearchResults),
			() => applyTransaction(() => {
				this.store.setError('Error fetching data!');
				this.store.setLoading(false);
			}),
			() => this.store.setLoading(false)
		);
	}

	/**
	 * Relaod data in state
	 * Usaully use to refresh data
	 * This will be also usefull for resizing ngx-datatable
	 * when resizing the table
	 */
	public reloadData() {
		const data = this.query.getAll();
		const isLoading = this.query.getValue().loading;
		this.store.set([]);
		this.store.set(data);
		this.store.setLoading(isLoading);
	}

	complete(data) {
		this.store.setLoading(true);
		return this.activityService.Put(data).pipe(
			tap(() => applyTransaction(() => {
				this.store.update(data.ActivityId, produce(draft => { draft.IsCompleted = true; }));
				this.store.setLoading(false);
				this.reloadData();
			})),
			catchError(err => {
				this.store.setLoading(true);
				return throwError(new Error(err));
			})
		);
	}

	cancelActivity(data): Observable<string> {
		return this.activityService.Put(data).pipe(
			tap(status => applyTransaction(() => {
				this.store.update(data.ActivityId, produce(draft => { draft.IsCancelled = true; }));
			})),
			tap(() => this.reloadData()),
			catchError(err => {
				return throwError(new Error(err));
			})
		)
	}

	public reorderColumns(prevIndex, newIndex) {
		const oldCol = this.query.getValue().columns;
		let newCol: any;
		newCol = produce(oldCol, draft => {
			const movedCol = draft?.splice(prevIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});

		return of(newCol).pipe(
			tap(() => this.store.setColumns(newCol)),
			withLatestFrom(this.userQuery.userId$),
			map(([newVal, userId]): UpdateBLStaffDetailRequest => ({
				MetaKey: staticConf.activitiesWidgetColumns,
				DashboardWidgetConfig: JSON.stringify(newVal),
				StaffId: userId
			})
			),
			withLatestFrom(this.userQuery.isTapLevel$),
			mergeMap(([req, isTop]) => isTop ? of(null) : this.api.post3<JsonResultStatus>('Staff/UpdateStaffSettingsByKey', req)),
		);
	}

	resizeColumns(prop: string, width: number): Observable<any> {
		const oldColWidths = this.query.getValue().columns;
		let newColWidths: any;
		newColWidths = produce(oldColWidths, draft => {
			draft.find(x => prop === x.prop).width = width;
		});

		return of(newColWidths).pipe(
			tap(x => this.store.setColumns(x)),
			withLatestFrom(this.userQuery.userId$),
			map(([newVal, userId]): UpdateBLStaffDetailRequest => ({
				MetaKey: staticConf.activitiesWidgetColumns,
				DashboardWidgetConfig: JSON.stringify(newVal),
				StaffId: userId
			})),
			withLatestFrom(this.userQuery.isTapLevel$),
			mergeMap(([req, isTop]) => isTop ? of(null) : this.api.post3<JsonResultStatus>('Staff/UpdateStaffSettingsByKey', req))
		);
	}

	saveActivity = (activity: ActivityViewModel) => of(ActivityViewModel.MapToAdd(activity)).pipe(mergeMap(x => this.activityService.Post(x)));
}
