import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RouteService, CommandRoute } from '../../../../../core/config/route.service';
import { Router } from '@angular/router';
import { SecondaryBusinessState } from '../../../../../shared/models/client-profile/seondary-business/secondary-business.model';
import { Observable } from 'rxjs';
import { NoteState } from '../../../../../shared/models/notes/note.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { tap } from 'rxjs/operators';
import { objectUtil } from '../../../../../util/util';
import { TablePaginateNotesComponent } from '../../../../../shared/table-paginate-notes/table-paginate-notes.component';

@Component({
  selector: 'app-form-business',
  templateUrl: './form-business.component.html',
  styleUrls: ['./form-business.component.scss']
})
export class FormBusinessComponent implements OnInit, OnChanges {
  @Input() formID: any;
  @Input() isLead: boolean;
  @Input() business: SecondaryBusinessState;
  @Input() LS: any;
  // Notes
  @Input() getNotesFn$: (req: any) => Observable<any>;
  @Input() addNotesFn$: (req: any) => Observable<any>;
  @Input() deleteNotesFn$: (req: any) => Observable<any>;

  form: UntypedFormGroup = this.fb.group({
    companyName: this.fb.control(''),
    tradingName: this.fb.control(''),

    primaryContact: this.fb.control(''),
    industry: this.fb.control(''),
    work: this.fb.control(''),
    email: this.fb.control(''),

    physicalAddress: this.fb.control('')
  } as { [key in keyof SecondaryBusinessState]: any });

  notes: { notes: NoteState[]; count: number };

  @ViewChild(TablePaginateNotesComponent) noteTable: TablePaginateNotesComponent;

  constructor(private fb: UntypedFormBuilder, private routeService: RouteService, private route: Router) { }
  businessRoute: CommandRoute = [];

  ngOnInit() {
    this.form.disable();
    this.businessRoute = this.routeService.businessEdit(+this.business.customerID);
  }

  ngOnChanges(changes: SimpleChanges & { business: SecondaryBusinessState }) {
    if (changes) {
      if (changes.business) {
        const data = {
          ...this.business,
          primaryContact:
            this.business && this.business.primaryContact
              ? objectUtil.mapCamelCaseToPascalCase(this.business.primaryContact)
              : null
        };
        this.form.reset(data);
      }
    }
  }

  addNotes$ = note => {
    return this.addNotesFn$({
      customerID: note.customerID,
      customerServiceID: note.customerServiceID,
      notes: note.notes
    });
  }

  deleteNotes$ = (note: NoteState) => {
    return this.deleteNotesFn$(note);
  }

  getNotes$ = (req: GetNotes) => {
    return this.getNotesFn$(req).pipe(
      tap(x => {
        this.notes = x;
      })
    );
  }
}
