import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PState, PStore } from './p.store';

/**
 * Query for Pipeline widget data
 */
@Injectable()
export class PQuery extends Query<PState> {
  isServiceLoading$ = this.select(state =>
    state.isServiceLoading
  );

  /** LR Pipeline data. */
  lrData$ = this.select(state =>
    state.LRState.LRPipelineStats
  );
  /** Indicator if LR pipeline is loading */
  lrIsLoading$ = this.select(state => state.LRState.isLoading);
  /** error message for LR pipeline */
  lrError$ = this.select(state => state.LRState.error);

  /** Mortgage Pipeline data */
  mortgageData$ = this.select(state =>
    state.MortgageState.MortgagePipelineStats
  );
  /** Indicator if Mortgage pipeline is loading */
  mortgageIsLoading$ = this.select(state =>
    state.MortgageState.isLoading
  );
  /** error message for Mortgage pipeline */
  mortgageError$ = this.select(state =>
    state.MortgageState.error
  );

  /** Kiwisaver Pipeline data. */
  KiwisaverData$ = this.select(state =>
    state.KiwisaverState.KiwisaverPipelineStats
  );
  /** Indicator if Kiwisaver pipeline is loading */
  KiwisaverIsLoading$ = this.select(state =>
    state.KiwisaverState.isLoading
  );
  /** error message for Kiwisaver pipeline */
  KiwisaverError$ = this.select(state =>
    state.KiwisaverState.error
  );

  /** Investment Pipeline data. */
  InvestmentData$ = this.select(state =>
    state.InvestmentState.InvestmentPipelineStats
  );
  /** Indicator if Investment pipeline is loading */
  InvestmentIsLoading$ = this.select(state =>
    state.InvestmentState.isLoading
  );
  /** error message for Investment pipeline */
	InvestmentError$ = this.select(state =>
    state.InvestmentState.error
  );

  /** FG Pipeline data. */
  FGData$ = this.select(state =>
    state.FGState.FGPipelineStats
  );
  /** Indicator if FG pipeline is loading */
  FGIsLoading$ = this.select(state =>
    state.FGState.isLoading
  );
  /** error message for FG pipeline */
  FGError$ = this.select(state =>
    state.FGState.error
  );

  constructor(protected store: PStore) {
    super(store);
  }
}
