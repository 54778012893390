<div
	class="container d-flex exports-popup"
	[ngClass]="{
		'flex-column': isExpanded,
		'justify-content-between align-items-center': !isExpanded
	}"
>
	<ng-container *ngIf="isExpanded; else minimizedMode">
		<div class="d-flex ml-auto buttons">
			<button class="btn-toggle" (click)="toggleMode()">
				<i class="material-icons md-20">expand_more</i>
			</button>
			<button class="btn-toggle" (click)="cancelExport()">
				<i class="material-icons md-20">close</i>
			</button>
		</div>
		<div class="header mb-3">
			<span>Export Progress</span>
		</div>
		<div class="body d-flex flex-column mb-2">
			<!-- <div class="info d-flex justify-content-between mb-1">
				<span>{{ timeStr }}</span>
				<span>{{ percentage }}% Complete</span>
			</div> -->
			<div class="info d-flex justify-content-between mb-1">
				<span>{{ reportLabel$ | async }}</span>
				<span>{{ percentage }}% Complete</span>
			</div>
			<div class="progress custom-progress">
				<div
					class="progress-bar custom-progress-bar bg-white"
					role="progressbar"
					aria-valuenow="75"
					aria-valuemin="0"
					aria-valuemax="100"
					[ngStyle]="progress"
				></div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #minimizedMode>
	<div class="info">
		<span *ngIf="hasError">{{ reportLabel$ | async }}</span>
		<span *ngIf="!hasError">{{ percentage }}% Complete</span>
	</div>
	<div class="d-flex buttons">
		<button class="btn-toggle" (click)="toggleMode()">
			<i class="material-icons md-20">expand_less</i>
		</button>
		<button class="btn-toggle" (click)="cancelExport()">
			<i class="material-icons md-20">close</i>
		</button>
	</div>
</ng-template>
