<div class="card-header font-weight-bold text-light tap-bg-primary">
  {{ header }}
</div>
<div class="card-body">
  <app-email-form
    [emailSettings]="emailSettings$ | async"
    [attachments]="attachments"
    [emailDocumentTemplate]="generateFinalisedEmailTemplateFn$ | async"
    [bodyContent]="bodyContent"
    [shortCodes]="shortCodes"
    [peopleList]="peopleList"
    (cancel)="decline()"
    (sent)="sent()"
		(next)="next()"
    [saveEmailFn$]="saveEmailFn$"
    [sendEmailFn$]="sendEmailFn$"
    [downloadOfferTermsFn$]="downloadOfferTermsFn$"
    [showEmailTemplate]="showEmailTemplate"
    [successMessage]="successMessage"
    [attachFiles]="attachFiles"
		[adviceProcess]="adviceProcess"
		[businessConfig]="businessConfig$ | async"
    [mergeTags$]="mergeTags$"
    [documentInfo]="documentInfo"
		[enableDefaultBcc]="enableDefaultBcc"
		[showNextBtn]="showNextBtn"
		[nextLabel]="nextLabel"
  >
    <div *ngIf="attachments.length > 0">
      <div class="form-row pl-1 font-weight-bold">Email {{ attachments?.length > 1 || offerTerms?.length ? 'Attachments' : 'Attachment' }}</div>
      <div class="col mt-2 pt-2 attachments">
        <div class="row">
          <div class="col-9 pl-0">
            <div *ngFor="let attachment of attachments; let i = index" class="card-footer attachment">
              <div
                id="downloadBtn_{{i}}"
                class="btn btn-md document-button-container p-0"
                (click)="!!attachment?.queue ? null : downloadPdfAttachment(attachment)"
                [class.disabled]="!!attachment?.queue"
              >
                <i class="far fa-file-pdf text-danger pr-1"></i>
                <small class="text-muted">{{attachment.fileName}}</small>
              </div>
              <button
                id="file_{{ i }}"
                type="button"
                class="icon-btn w-auto px-1 h-auto"
                (click)="removeAttachment(i)"
                *ngIf="!attachment.generateContentCallback$"
              >
                <i class="material-icons md-16 close-icon"> close </i>
              </button>
            </div>
          </div>
          <div class="col-3 text-right pt-2 pr-0">
            <button
              id="attachDocument"
              class="tap-btn tap-btn-outline tap-btn--shadow"
              (click)="openAttachmentModal()"
            >
              Attach Document
            </button>
          </div>
        </div>
      </div>
    </div>

  </app-email-form>
</div>
