<div class="card-header font-weight-bold text-light tap-bg-primary">
	{{ header }}
</div>
<div class="card-body p-4">
	<ng-container [ngSwitch]="message">
		<ng-container *ngSwitchCase="'EmploymentDetails'">
			<app-employment-form
				[incomeFrequency]="apcrtf$ | async"
				[kiwiSaverContribution]="apcrtkc$ | async"
				[taxCode]="apcrttc$ | async"
				[employment]="pce$ | async"
				[industry]="apcrti$ | async"
				[incomeSourceType]="apcrtist$ | async"
				[incomeType]="apcrtit$ | async"
				[primaryClient]="primaryClient$ | async"
				[secondaryClients]="secondaryClients$ | async"
				[people]="people$ | async"
				[transferedSCI]="transferedSCI"
				[isAdd]="isAdd"
				[cRTId]="cRTId"
				[adviceProcessId]="adviceProcessId"
				[incomeSources]="incomeSources$ | async"
				[peopleFromCRMAndCRTExceptChild$]="peopleFromCRMAndCRTExceptChild$"
				[removeFactFindComputation]="removeFactFindComputation"
				[showTimeInJob]="showTimeInJob"
				[getOwnerChoices]="getOwnerChoices"
				(cancelEvent)="cancelChange($event)"
				(saveEvent)="save($event)"
				[loading]="isLoading"
				[viewMode]="viewMode"
			></app-employment-form>
		</ng-container>
	</ng-container>
</div>

<ng-container *ngIf="false">
	<div class="card-footer bg-transparent footer-height">
		<label class="pt-3 pl-3"> + Add Additional [Information] </label>
	</div>
</ng-container>
