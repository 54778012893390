<span class="tap-required" *ngIf="isEditing && !field.valid">*</span>
<div #longText class="field" [ngClass]="{'field-no-padding' : largeSwitch}">
  <div *ngIf="!isEditing; else fieldTemplate;">
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" [checked]="getBooleanValue(!!value)"
        [id]="'switch_read_only_'+fieldId" [disabled]="true">
      <label class="custom-control-label" [for]="'switch_read_only_'+fieldId"></label>
    </div>
  </div>
</div>

<ng-container *ngIf="!restrict; else hasRestriction;">
  <div *ngIf="isEditable || !hideControl" class="control">
    <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()">
      <i class="material-icons md-16 dark">&#xE3C9;</i>
    </button>
  </div>
</ng-container>

<ng-template #hasRestriction>
  <ng-container *appRestrictTo="restrict">
    <div *ngIf="isEditable || !hideControl" class="control">
      <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()">
        <i class="material-icons md-16 dark">&#xE3C9;</i>
      </button>
    </div>
  </ng-container>
</ng-template>

<div>
  <app-manual-loader-mini *ngIf="isLoading" [isLoading]="isLoading" class="loader-mini"></app-manual-loader-mini>
</div>

<div class="edit-controls d-flex flex-column" *ngIf="isEditing && !isLoading && !hideControl">
  <button type="submit" class="icon-btn-bg btn-save" (click)="save()">
    <i class="material-icons md-16 dark">&#xE161;</i>
  </button>
  <button type="button" class="icon-btn-bg btn-cancel" (click)="cancel()">
    <i class="material-icons md-16 dark">&#xE5C9;</i>
  </button>
</div>

<ng-template #fieldTemplate>
  <div>
    <div class="custom-control custom-switch" [ngClass]="{'custom-switch-large' : largeSwitch}">
      <ng-container *ngIf="noRestriction || (hasPermission$(restrict) | async)">
        <input type="checkbox" class="custom-control-input" [ngClass]="{'custom-control-input-large' : largeSwitch}" [formControl]="field" [id]="'switch_read_only_' +fieldId"
          (change)="onChangeSwitch($event)">
        <label class="custom-control-label" [ngClass]="{'custom-control-label-large' : largeSwitch}" [for]="'switch_read_only_'+fieldId"></label>
      </ng-container>
      <ng-container *ngIf="!noRestriction && !(hasPermission$(restrict) | async)">
        <input type="checkbox" class="cursor-disabled custom-control-input" [checked]="getBooleanValue(!!value)"
          [id]="'switch_read_only_' +fieldId" [disabled]="true">
        <label class="custom-control-label cursor-disabled" [for]="'switch_read_only_'+fieldId"></label>
      </ng-container>
    </div>
  </div>
</ng-template>
