<ng-container *ngIf="!isAnchorTag; else anchorTag">
  <button
    class="d-flex justify-content-center align-items-center"
    [attr.disabled]="isLoading || disabled ? '' : null"
    [class]="btnClass"
    [type]="type"
    [style.width]="width"
  >
    <span *ngIf="hideIcon" class="material-icons fs-4">{{ icon }}</span>
    <span *ngIf="hideText"> {{ text }} </span>
		<ng-content></ng-content>
    <div [class]="spinnerClass" role="status" *ngIf="isLoading">
      <span class="sr-only"></span>
    </div>
  </button>
</ng-container>
<ng-template #anchorTag>
  <a
    class="d-flex justify-content-center align-items-center"
    [attr.disabled]="isLoading || disabled ? '' : null"
    [class]="btnClass"
    [type]="type"
    [style.width]="width"
    [target]="target"
    [routerLink]="anchorTagHref"
  >
    <span *ngIf="hideText"> {{ text }} </span>
    <span *ngIf="hideIcon" class="material-icons fs-4">{{ icon }}</span>
    <div [class]="spinnerClass" role="status" *ngIf="isLoading">
      <span class="sr-only"></span>
    </div>
  </a>
</ng-template>
