import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiVsSubmittedYearStat } from './state/asry.model';
import { map, filter, takeUntil, delay, tap } from 'rxjs/operators';
import { DashboardQuery } from '../../state/dashboard.query';
import { ApiVsSubmittedYearStatsQuery } from './state/asry.query';
import { ApiVsSubmittedYearStatsService } from './state/asry.service';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { BipolarBarChartComponent } from '../../../../../shared/bipolar-bar-chart/bipolar-bar-chart.component';

@Component({
  selector: 'app-asry',
	styleUrls: ['./asry.component.scss'],
  templateUrl: './asry.component.html'
})
export class AsryComponent implements OnInit, OnDestroy {
  public static widgetCode = 'asry';
  public static widgetName = 'API Rolling 12 Months';
  public static sizeX = 1000;
  public static sizeY = 400;
  public static minSizeX = 1000;
  // public static maxSizeX = 1000;
  public static minSizeY = 400;
  public static maxSizeY = 400;

  widgetCode = AsryComponent.widgetCode;
	chartId = 'asry-bar-chart';

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * Bipolar Bar chart element. referenced for manual refreshing
   * when gridstack resizes div.
   */
  @ViewChild('asryBipolarBarChart') asryBarChart: BipolarBarChartComponent;

  /**
   * ASRY Data for Rolling 12 Months (Year)
   */
  data$: Observable<ApiVsSubmittedYearStat> = this.query.ApiVsSubmittedYearStatData$;

	viewData$ = this.data$.pipe(
		map((data) => {
			return {
				series: [
					{ name: 'Submitted', data: data?.CurrentYear?.map(x => x.Submitted) || [] },
					{ name: 'Issued', data: data?.CurrentYear?.map(x => x.Completed) || [] },
					{ name: 'Cancelled', data: data?.CurrentYear?.map(x => x.Lost) || [] },
					{ name: 'Submitted_OB', data: data?.PreviousYear?.map(x => x.Submitted) || [] },
					{ name: 'Issued_OB', data: data?.PreviousYear?.map(x => x.Completed) || [] },
					{ name: 'Cancelled_OB', data: data?.PreviousYear?.map(x => x.Lost) || [] }
				],
				labels: data?.CurrentYear?.map((x) => x.Month) || [],
			};
		})
	);

  constructor(
    private dashboardQuery: DashboardQuery,
    private query: ApiVsSubmittedYearStatsQuery,
    private service: ApiVsSubmittedYearStatsService,
    private dashboard: DashboardComponent) { }

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of annual API stats.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$.pipe(
      filter(x => x.length > 0),
      takeUntil(this.onDestroy$)
    ).subscribe(advisers => {
      this.service.GetApiVsSubmittedYearStats(advisers);
    });

    this.dashboard.gridChangeEvent.pipe(
      delay(200),
      takeUntil(this.onDestroy$)
    ).subscribe((res) => {
      if (res === AsryComponent.widgetCode) {
        this.asryBarChart.refresh();
      }
    });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
