import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { staticConf } from '../../../core/config/static-config.service';
import { InputDateComponent } from './input-date.component';
import { InputDateService } from './input-date.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';

const CUSTOM_MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: staticConf.displayDateFormat,
	},
	display: {
		dateInput: staticConf.displayDateFormat,
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [InputDateComponent],
	exports: [InputDateComponent],
	imports: [CommonModule, MatDatepickerModule, FormsModule],
	providers: [
		InputDateService,
		MomentDateAdapter,
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_MOMENT_DATE_FORMATS },
	],
})
export class InputDateModule {}
