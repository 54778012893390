import { numUtil } from '../../../../../util/util';
import * as R from 'ramda';
import { ApplicationSecurityDetails } from '../../crt-mortgage/application/application-steps/review-application/state/review-application.model';

const maxLoanAmount = (securities: ApplicationSecurityDetails[]) => {
	return (securities || [])?.map((x) => {
		if (x?.propertyUse === 'Investment') {
			// TAP1-4600 If Investment compute 70%
			return +numUtil.formatToNumCurrency(
				R.multiply(+(x?.propertyValue || 0), 0.7)
			);
		}
		// Else, 80%
		return +numUtil.formatToNumCurrency(
			R.multiply(+(x?.propertyValue || 0), 0.8)
		);
	});
};

const totalMaxLoanAmount = (securities: ApplicationSecurityDetails[]) => {
	const result = maxLoanAmount(securities)?.reduce(
		(a, c) => R.sum([+a, +c]),
		0
	);
	return +numUtil.formatToNumCurrency(result);
};

const totalPropertyValue = (securities: ApplicationSecurityDetails[]) => {
	const result = (securities || [])?.reduce(
		(a, c) => R.sum([+a, +c?.propertyValue]),
		0
	);
	return +numUtil.formatToNumCurrency(result);
};

const getTotalPropertyLVR = (securities: ApplicationSecurityDetails[]) => {
	// TAPNZ-6790
	// LVR: If Property Type = Investment, this would be prefilled at 60%. If Property Type = Owner Occupied, this would be prefilled at 80%. If there are multiple, this would be an average (eg. 1 Investment & 1 Owner Occupied would be 80%+60%=140%/2=70%
	// TAP1-2502
	// LVF: Investment Properties weighting has been increased from 60% to 65%
	// TAP1-4600
	// LVF: Investment Properties weighting has been increased from 65% to 70%
	let result = 0;
	const totalSecurities = securities?.length || 0;

	if (totalSecurities > 0) {
		const totalPercent = (securities || [])?.reduce((a, c) => {
			if (c?.propertyUse === 'Investment') {
				// If Investment = 70% 
				return R.sum([+a, 70]);
			}
			// Else, 80%
			return R.sum([+a, 80]);
		}, 0);
		result = R.divide(totalPercent, totalSecurities);
	}
	return +numUtil.formatToNumCurrency(result);
};

const getTotalPp = (purchasePrice: number, LVR: number) => {
	// TAPNZ-6790
	// This is (Total Purchase Price x LVR) / 100 = $00
	let result = R.multiply(purchasePrice || 0, LVR || 0);
	if (result !== 0) {
		result = R.divide(+result, 100);
	}
	return +numUtil.formatToNumCurrency(result);
};

const getTotalSecurityValue = (securities: ApplicationSecurityDetails[]) => {
	const result = securities?.reduce((a, c) => {
		return a + +c?.amount || 0;
	}, 0);
	return +numUtil.formatToNumCurrency(result);
};

export const computeMoatAppUtil = {
	maxLoanAmount,
	totalMaxLoanAmount,
	totalPropertyValue,
	getTotalPropertyLVR,
	getTotalPp,
	getTotalSecurityValue,
};
