import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MoatVersionService {

  constructor(private router: Router) { }

  isMoatV2(): boolean {
    const url = this.router.url;
    return url.includes('moat-v2');
  }
}
