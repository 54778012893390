import { CarInsuranceState } from './car-insurance.model';
import { util } from '../../../../core/util/util.service';
import MomentUtil from '../../../../util/moment.util';

export class CarInsuranceMapper {
	/** Mapping for view */
	public static mapToView(data: any){
		return {
            ...data,
			lastReviewed: util.isValidMoment(data.lastReviewed) ? data.lastReviewed : MomentUtil.formatDateToMoment(data.lastReviewed),
			date: util.isValidMoment(data.date) ? data.lastReviewed : MomentUtil.formatDateToMoment(data.date)
		};
	  }

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: CarInsuranceState, cid?: number, adviceProcessId?: number): CarInsuranceState {
		return {
			...data,
			lastReviewed: MomentUtil.formatDateToServerDate(data.lastReviewed),
			date: MomentUtil.formatDateToServerDate(data.date),
			cRTId: cid,
      cost: +data.cost,
      currentExcess: +data.currentExcess,
      sumInsured: +data.sumInsured,
      premium: +data.premium,
      adviceProcessId,
      sectionCode: 'FFGCI'
    };
	}
}
