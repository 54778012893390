import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PeopleAdviceSummary } from './advice-summary-report-builder.service';

export interface AdviceSummaryReportBuilderState
	extends EntityState<PeopleAdviceSummary, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'CrtKiwisaver:Advice Summary Report Builder',
	idKey: 'cRTId',
})
export class AdviceSummaryReportBuilderStore extends EntityStore<
	AdviceSummaryReportBuilderState
> {
	constructor() {
		super();
	}
}
