import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';

import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { FinalStructureSettingsService } from '../state/final-structure-settings.service'
import { FinalStructureDocumentMapper } from '../state/final-structure-settings.mapper'
import { objectUtil, convertUtil } from '../../../../util/util';
import { FinalStructureState } from '../state/final-structure-settings.model';
@Component({
  selector: 'app-final-structure-email-settings',
  templateUrl: './final-structure-email-settings.component.html',
})
export class FinalStructureEmailSettingsComponent implements OnInit {
  @Input() staffId: number;
  @Input() emailSettings: FinalStructureState;
  @Input() mergeTags$: Observable<MergeTagState[]>;

  finalStructureSettings$ = this.fsService.finalStructure$;
  shortCodes: object;
  bodyId: number;
  isLoading: boolean;
  bodyContent = '<p></p>'

  constructor(private fsService: FinalStructureSettingsService) { }

  ngOnInit(): void {
    this.prepData()
  }

  prepData() {
    combineLatest([this.finalStructureSettings$, this.mergeTags$])
    .pipe(
      filter(([data, mt]) => !!data && !!mt),
      tap(
        ([data, mt]) =>
          (this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
      ),
      tap(([data, mt]) => (this.bodyId = +data?.body || 0)),

      map(([data, mt]) =>
        FinalStructureDocumentMapper.mapEmailSettingsData(data)
      ),
      mergeMap((data) =>
        iif(
          () => R.isNil(data?.body),
          of(null),
          this.fsService.getFile(+data.body)
        )
      ),
      map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
      mergeMap((res) =>
        iif(
          () => res?.documentLink,
          this.fsService.getDocumentFromURL(res?.documentLink),
          of(null)
        )
      ),
      tap((data) => {
        if (data) {
          this.bodyContent = data;
        }
      }),
      take(1)
    )
    .subscribe();
  }

  save(emailSettings) {
    this.isLoading = true;
    of(emailSettings)
      .pipe(
        mergeMap((currentTemplate) => {
          return convertUtil.convertToBase64(currentTemplate?.bodyContent)
        }),
        withLatestFrom(of(this.bodyId)),
        map(([currentTemplate, bodyId]) =>
        FinalStructureDocumentMapper.mapStaffEmailBody(
            currentTemplate,
            bodyId,
            0
          )
        ),
        concatMap((data) =>
          iif(
            () => R.isNil(data.documentId),
            this.fsService.newFileUpload(data).pipe(catchError((err) => of(err))),
            this.fsService.updateFileUpload(data)
          )
        ),
        withLatestFrom(of(this.bodyId)),
        concatMap(([data, body]) => {
          const isNewBody = body === 0 || !body;
          const newBodyId = isNewBody ? data : body;
          const formValue = FinalStructureDocumentMapper.mapEmailSettingsToUpsert({
            ...emailSettings,
            referenceId: 0,
            body: newBodyId,
           });
          this.bodyId = newBodyId;
          return this.fsService.updateFinalStructure(formValue);
        }),
        finalize(() => (this.isLoading = false)),
        take(1)
      )
      .subscribe();
  }

}
