import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer } from 'rxjs';
import { finalize, take, tap, first, map, mergeMap } from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { PrimaryClientState } from 'src/app/shared/models/client-profile/primary-client/primary-client.model';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { ApplicationQuery } from '../state/application.query';
import { RouteService } from './../../../../../../core/config/route.service';
import { DeleteModalComponent } from './../../../../../../shared/modal/delete-modal/delete-modal.component';
import {
	AdviceProcessOnlineRoutes,
	MortgageAdviceProcessPageIds
} from './../../../../../../shared/models/advice-process/advice-process.model';
import { CrtMortgageQuery } from './../../state/crt-mortgage.query';
import { Application } from './../state/application.model';
import { ApplicationService } from './../state/application.service';
import { ApplicationStore } from '../state/application.store';
import { ReviewApplicationService } from '../application-steps/review-application/state/review-application.service';

@Component({
	selector: 'app-application-list',
	templateUrl: './application-list.component.html',
	styleUrls: ['./application-list.component.scss'],
})
export class ApplicationListComponent implements OnInit {
	applications$: Observable<Application[]> = this.service.applications$.pipe(
		map((x) => x.sort((a, b) => (b.createDateTime > a.createDateTime ? 1 : -1)))
	);
	adviceProcessId = +this.route.snapshot.paramMap.get('adviceProcessId');
	isAdding: boolean;
	deleteModalRef: BsModalRef;
	pageIds = MortgageAdviceProcessPageIds;
	isLoading$ = this.service.isLoading$;
	primaryClient: PrimaryClientState =
		this.crtMortgageQuery.getValue().primaryClient;

	applicationName: UntypedFormControl;
	isRenaming: boolean;
	renamingApp: number;
	isSavingName: boolean;
	duplicatingApp: number;
	isDuplicating: boolean;

	addFn = (app: Application) => this.service.add(app);
	updateFn = (app: Application) => this.service.update(app);
	deleteFn = (app: Application | number) => this.service.delete(app);

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private routeService: RouteService,
		private service: ApplicationService,
		private modalService: BsModalService,
		private crtMortgageQuery: CrtMortgageQuery,
		private loggerService: LoggerService,
		private applicationQuery: ApplicationQuery,
		private store: ApplicationStore,
		private reviewApplicationService: ReviewApplicationService
	) {}

	ngOnInit(): void {}

	addApplication() {
		this.isAdding = true;
		this.addFn({
			adviceProcessId: this.adviceProcessId,
			currentPage: 'Purpose',
		})
			.pipe(
				mergeMap((x) =>
					this.reviewApplicationService
						.getReviewAppSettings()
						.pipe(map(() => x))
				),
				finalize(() => (this.isAdding = false)),
				take(1)
			)
			.subscribe((res: Application) => {
				// Base route
				const route = this.routeService.crtPage(
					this.route.snapshot.paramMap.get('customerType') !== 'client',
					+this.route.snapshot.paramMap.get('clientId'),
					+this.route.snapshot.paramMap.get('adviceProcessId'),
					AdviceProcessOnlineRoutes.Mortgage
				);

				// Navigate to route with application ID
				this.router.navigate([...route, 'application', res?.cRTId]);
			});
	}

	editApplication(app: Application) {
		const route = this.routeService.crtPage(
			this.route.snapshot.paramMap.get('customerType') !== 'client',
			+this.route.snapshot.paramMap.get('clientId'),
			+this.route.snapshot.paramMap.get('adviceProcessId'),
			AdviceProcessOnlineRoutes.Mortgage
		);

		// Navigate to route with application ID
		return [...route, 'application', app?.cRTId];
	}

	deleteApplication(app: Application) {
		const delete$ = this.deleteFn(app);
		const decline$ = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
    const appName = app?.name.toUpperCase() ?? 'this';
		const initState = {
			header: 'Delete Application',
			message: `Are you sure you want to delete ${appName}?`,
			delete$,
			decline$,
			confirmButton: 'OK',
		};

		this.deleteModalRef = this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
    this.modalService.onHidden
        .pipe(first())
        .subscribe(_ => this.store.setLoading(false));
	}

	isRenamingApp(id: number) {
		return +this.renamingApp === id;
	}

	editName(app: Application) {
		this.renamingApp = app.cRTId;
		this.isRenaming = true;
		const originalName = app?.name.toUpperCase();
		const bankApplication = originalName.substr(
			originalName.indexOf('BANK APPLICATION')
		);
		const name = bankApplication?.substr(19);
		this.applicationName = new UntypedFormControl(name, [
			Validators.required,
			Validators.minLength(1),
			NoWhitespaceValidator,
		]);
	}

	saveRename(app: Application) {
		this.isSavingName = true;
		this.applicationName.disable();
		const clientName = `${this.primaryClient.firstName} ${this.primaryClient.lastName}`;
		let name = `${clientName} Bank Application - ${this.applicationName?.value?.trim()}`;
		name = name.toUpperCase();

		if(this.applicationQuery.getAll()?.some(x => x?.name?.toLowerCase() === name.toLowerCase() && +x.cRTId !== +app.cRTId)) {
			this.loggerService.Warning({}, `"${name}" already exists.`);
			this.isSavingName = false;
			this.applicationName.enable();
			return;
		}

		this.updateFn({ ...app, name })
			.pipe(
				tap(() => {
					this.isRenaming = false;
					this.renamingApp = null;
					this.applicationName.reset();

					this.loggerService.Success(
						{},
						`${logMessage.oat.mortgage.application.success.updateName} ${name}`
					);
				}),
				finalize(() => {
					this.isSavingName = false;
					this.applicationName.enable();
				}),
				take(1)
			)
			.subscribe();
	}

	cancelRename() {
		this.isRenaming = false;
		this.renamingApp = null;
		this.applicationName.reset();
	}

	isDuplicatingApp(id: number) {
		return +this.duplicatingApp === id;
	}

	duplicateApplication(app: Application) {
		this.isDuplicating = true;
		this.duplicatingApp = app.cRTId;
		this.service
			.duplicateApplication(app)
			.pipe(
				take(1),
				finalize(() => {
					this.service.get(this.adviceProcessId).pipe(take(1)).subscribe();
					this.duplicatingApp = null;
					this.isDuplicating = false;
				})
			)
			.subscribe();
	}
}
