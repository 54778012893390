import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailsComponent } from './emails.component';
import { EmailStatusResolver } from './email-status/email-status.resolver';
import { EmailHistoryResolver } from './email-history/email-history.resolver';
import { EmailSettingsResolver } from './email-settings/email-settings.resolver';

const routes: Routes = [
	{
		path: '',
		component: EmailsComponent,
		resolve: {
			data: EmailSettingsResolver,
			EmailHistoryResolver,
			EmailStatusResolver,
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class EmailsRoutingModule {}
