<form [formGroup]="form" [class.submitted]="submitted" class="form">
  <div class="card-header tap-bg-primary font-weight-bold text-light">
    {{ header }}
  </div>
  <div class="card-body p-4">
    <div class="form-row mb-2">
      <div class="col-12 card-body__message">
        {{ message }}
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-lg-5 card-body__select mb-2">
        <span class="custom-dropdown w-100">
          <select
            id="emailDropdown"
            formControlName="emailDropdown"
            (change)="getCrtInfo($event)"
            class="form-control theme-crm-field-disabled"
            [disabled]="isLoading"
          >
            <option value=""></option>
            <option *ngFor="let item of dropdownList" [value]="item.value">
              {{ item.display }}
            </option>
          </select>
        </span>
      </div>

      <div class="col-12 col-lg-5 card-body__input mb-2 pl-lg-0 pr-lg-0">
        <input
          type="text"
          id="email"
          class="form-control"
          placeholder="Enter email address"
          formControlName="emailRecipient"
        />
      </div>

      <div class="col-12 col-lg-2 pl-lg-0">
        <div
          class="card-body__save-button float-lg-left float-right"
          *ngIf="!isLoading"
        >
          <button
            type="button"
            class="icon-btn pr-0 d-inline-block"
            *ngIf="EmailDropdown.value && EmailDropdown.value !== 'Other'"
            (click)="saveCRTEmail()"
          >
            <i class="material-icons md-20 tap-text-color-h6 pr-0"> save </i>
          </button>
        </div>

        <div
          class="card-body__loader float-lg-left float-right"
          *ngIf="isLoading"
        >
          <app-manual-loader-mini
            [isLoading]="isLoading"
          ></app-manual-loader-mini>
        </div>
      </div>
    </div>

    <div class="position-absolute modal-controls modal-controls__send">
      <button
        type="button"
        class="
          tap-bg-primary
          mt-1
          btn btn-primary
          text-light
          modal-controls__send__btn
        "
        (click)="sendEmail()"
      >
        Send
      </button>
    </div>
    <div class="position-absolute modal-controls modal-controls__cancel">
      <button
        type="button"
        class="btn-light modal-controls__cancel__btn"
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
