import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataImportComponent } from './data-import.component';
import { DataImportService } from './data-import.component.service';

const routes: Routes = [
  {
    path: '',
    component: DataImportComponent,
    resolve: { data: DataImportService }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataImportRoutingModule { }
