import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import sort from 'fast-sort';
import * as R from 'ramda';
import { Observable, of } from 'rxjs';
import {
	auditTime,
	combineLatest,
	distinctUntilChanged,
	map,
	mergeMap,
	shareReplay,
	take,
	withLatestFrom,
} from 'rxjs/operators';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { mapToLookupObject } from '../../../../../domain/dropdown-value/dropdown-value.model';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { util } from '../../../../../util/util';
import { EnhancedTableColumn, columns, templateRow } from '../fg-insurance-datatable.config';
import { FgInsuranceUiQuery } from './fg-insurance-ui.query';
import { Row } from './fg-insurance.model';
import { FgInsuranceState, FgInsuranceStore } from './fg-insurance.store';

@Injectable()
export class FgInsuranceQuery extends QueryEntity<FgInsuranceState, Row> {
	private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
		map((x) => R.map((y) => ViewDisplayValue.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
		map((x) => (x ? x?.sort((a, b) => a.display.localeCompare(b.display)) : x)),
	); // Advisers
	private FGS$ = this.dropdownQuery.orderedChoices$('FGS'); // Policy Status
	private FGPT$ = this.dropdownQuery.orderedChoices$('FGPT'); // Policy Type
	private FGPW$ = this.dropdownQuery.orderedChoices$('FGPW'); // Policy Writer
	private FGAS$ = this.dropdownQuery.orderedChoices$('FGAS'); // Account Status
	private FGI$ = this.dropdownQuery.orderedChoices$('FGI'); // Provider
	private FGCS$ = this.dropdownQuery.orderedChoices$('FGCS'); // Claim Status
	private FGPL$ = this.dropdownQuery.orderedChoices$('FGPL'); // Policy Lines
	private PCLE$ = this.dropdownQuery.orderedChoices$('PCLE'); // Lead Origin
	private cancellationReason$ = this.dropdownQuery.orderedChoices$('FGPCR');
	adviserGenChoices$ = this.clientProfileService.adviserChoices$;

	columns$ = this.select((x) => x.columns);
	tableColumns$: Observable<EnhancedTableColumn[]> = this.columns$
		.pipe(
			map((tableColumns) => {
				return tableColumns?.map((col) => columns?.find((y) => y.metakey === col))?.filter((x) => x !== undefined);
			}),
			withLatestFrom(
				this.select((x) => x.columnWidths),
				this.businessConfigQuery.adviserReworkFeature$,
			),
			map(([tableColumns, widths, adviserReworkFeature]) =>
				tableColumns
					?.filter((x: EnhancedTableColumn | string) => (adviserReworkFeature ? x !== 'Adviser' : x !== 'FG Adviser'))
					?.map((column) => {
						const widthConf = widths?.filter((x) => x)?.find((width) => width.metakey === column.metakey);
						const newColumn = { ...column, $$id: column.name };
						if (widthConf) {
							return { ...newColumn, width: widthConf.width };
						} else {
							return newColumn;
						}
					}),
			),
			combineLatest(this.advisers$, this.FGS$, this.FGPT$, this.FGAS$, this.FGPW$),
			auditTime(0),
			combineLatest(this.FGI$, this.FGCS$, this.FGPL$, this.PCLE$, this.cancellationReason$, this.adviserGenChoices$),
			auditTime(0),
			map(
				([
					[tableColumns, advisers, fgs, fgpt, fgas, fgpw],
					fgi,
					fgcs,
					fgpl,
					pcle,
					cancellationReason,
					adviserGenChoices,
				]) => {
					return tableColumns?.map((c) => {
						switch (c.metakey) {
							case 'Adviser':
							case 'FG Adviser':
							case 'Original Adviser':
								return this.fillUpChoices(advisers, c);
							case 'Status':
								return this.fillUpChoices(fgs, c);
							case 'Policy Type':
								return this.fillUpChoices(fgpt, c);
							case 'Policy Writer':
								return this.fillUpChoices(fgpw, c);
							case 'Account Status':
								return this.fillUpChoices(fgas, c);
							case 'Insurer':
								return this.fillUpChoices(fgi, c);
							case 'Claim Status':
								return this.fillUpChoices(fgcs, c);
							case 'Policy Lines':
								return this.fillUpChoices(fgpl, c);
							case 'Lead Origin':
								return this.fillUpChoices(pcle, c);
							case 'Cancellation Reason':
								return this.fillUpChoices(cancellationReason, c);
							case 'Adviser Gen':
								return {
									...c,
									choices: adviserGenChoices,
									choicesAsObject: mapToLookupObject(adviserGenChoices),
								};
							default:
								return c;
						}
					});
				},
			),
			mergeMap((columns) => this.toggleNZFAColumns(columns)),
		)
		.pipe(
			shareReplay(1),
			distinctUntilChanged((x, y) => R.equals(x, y)),
			map((columns) => columns as EnhancedTableColumn[]),
		);

	hiddenTableColumns$ = this.columns$.pipe(
		map((tableColumns) => {
			return columns?.filter((x) => !tableColumns?.includes(x.metakey));
		}),
		mergeMap((columns) => this.toggleNZFAColumns(columns)),
	);

	sorts$ = this.uiQuery.select((x) => [{ dir: x.sort, prop: x.propSort }]);
	cellsLoading$ = this.uiQuery.select((state) => state.cellLoadingStatus);
	cellsEditing$ = this.uiQuery.select((state) => state.cellEditStatus);
	cellsTempvalue$ = this.uiQuery.select((state) => state.cellTempValue);
	rowsLoading$ = this.uiQuery.select((state) => state.rowLoadingStatus);
	isSearching$ = this.uiQuery.select((state) => state.isSearching);
	allAdvisers$ = this.blstaffQuery.allStaffsChoices$;

	templateRow$: Observable<Row> = of(templateRow);

	rows$: Observable<Row[]> = this.selectAll();
	hasRows$: Observable<boolean> = this.selectCount().pipe(map((x) => x > 0));

	count$ = this.select((x) => x.count);
	totalPremium$ = this.select((x) => x.totalPremium);
	searchForm$ = this.select((x) => x.searchForm).pipe(map((x) => x));
	isComplete$ = this.select((x) => x.isComplete);

	sortedRows$ = this.uiQuery
		.select((x) => [x.propSort, x.sort])
		.pipe(
			distinctUntilChanged((p, q) => R.equals(p, q)),
			combineLatest(this.rows$, this.tableColumns$),
			withLatestFrom(this.isSearching$),
			withLatestFrom(this.allAdvisers$),
			auditTime(0),
			map(([[[[prop, sortDirection], rows, tableColumns], isSearching], allAdvisers]) => {
				if (sortDirection === '' || prop === '' || isSearching) {
					return rows;
				} else {
					const column = tableColumns?.find((x) => x.prop === prop);
					if (util.isNullOrEmpty(column)) {
						return rows;
					}
					// @ts-ignore-next
					const decorated = rows?.map<[number, string, number, Row]>((r) => {
						const adviserColumn = ['Adviser', 'OriginalAdviser', 'FGAdviser'].includes(prop)
							? allAdvisers
							: column.choices;
						const actualValue = column.sortValueGetter(r[prop], adviserColumn, r);
						return [this.spaceSortValueGetter(actualValue as number), R.defaultTo('', actualValue), r.CustomerID, r];
					});
					return sort(decorated)
						.by([
							{ asc: (u) => u[0] },
							// @ts-ignore-next
							{
								[sortDirection]: (u) => (u[1] && Number.isNaN(u[1]) ? u[1]?.toLowerCase() : u[1]),
							},
							{ asc: (u) => u[2] },
						])
						?.map((x) => x[3]);
				}
			}),
		);

	getCustomerName = (customerServiceId: number) => this.getEntity(customerServiceId).Name.value;

	getSum(total: number, num: Row) {
		return total + +num.Premium.value;
	}

	constructor(
		protected dropdownQuery: DropdownValueQuery,
		protected blstaffQuery: BLStaffsQuery,
		protected fgInsuranceStore: FgInsuranceStore,
		protected businessConfigQuery: BusinessConfigQuery,
		public uiQuery: FgInsuranceUiQuery,
		protected clientProfileService: ClientProfileService,
	) {
		super(fgInsuranceStore);
	}

	/** fill up choices and choices as object. */
	private fillUpChoices(choices: ViewDisplayValue[], column: EnhancedTableColumn) {
		return {
			...column,
			choices,
			choicesAsObject: mapToLookupObject(choices),
		};
	}

	/** Create a value usable as index for sorting.
	 * Its only necessary to know if value is empty or not.
	 * So if it is not empty, return 1 which is first in sort index.
	 * And 2 if empty.
	 */
	private spaceSortValueGetter(fieldValue: string | number | null | undefined): number {
		let stringValue: string;
		if (util.isNullOrEmpty(fieldValue)) {
			stringValue = '';
		} else if (typeof fieldValue === 'string') {
			stringValue = fieldValue?.trim();
		} else {
			stringValue = fieldValue?.toString();
		}
		if (stringValue === '') {
			return 2;
		} else {
			return 1;
		}
	}

	private toggleNZFAColumns(columns: EnhancedTableColumn[]): Observable<EnhancedTableColumn[]> {
		return this.businessConfigQuery.fgUpdateV1Enabled$.pipe(
			take(1),
			map((fgUpdateV1Enabled) => {
				const NZFAColumns = new Set(['Auto Renewal', 'Cancellation Reason', 'Renewed Policy Number', 'Adviser Gen']);
				return columns.filter((c) => {
					if (NZFAColumns.has(c.metakey)) {
						return fgUpdateV1Enabled;
					}
					return true;
				});
			}),
		);
	}
}
