import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { tap, takeUntil, take, map, mergeMap, switchMap } from 'rxjs/operators';
import { Subject, Observable, Observer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DocumentFileClass } from '../../../../shared/models/emails/business-email-settings/business-email-settings';
import { BusinessConfig } from '../../../../domain/business-config/business-config.model';
import { UserQuery } from 'src/app/domain/user/user.query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { UploadModalComponent } from 'src/app/shared/modal/upload-modal/upload-modal.component';
import { validatorUtil } from 'src/app/util/validator.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { Fields, getInvalidWarning } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-business-email-settings-form',
	templateUrl: './business-email-settings-form.component.html',
	styleUrls: ['./business-email-settings-form.component.scss'],
})
export class BusinessEmailSettingsFormComponent implements OnInit {
	@Input() uploadFileFn$: ({ file, type }) => Observable<any>;
	@Input() removeFileFn$: ({ file, type }) => Observable<any>;
	@Input() sendBtnClick$: ({ values }) => Observable<any>;
	@Input() getDocument$: ({ value }) => Observable<any>;
	@Input() businessConfig: BusinessConfig;
	@Input() downloadLink$: ({ documentID }) => Observable<string>;

	public bsModalRef: BsModalRef;
	private onDestroy$ = new Subject<void>();

	form: UntypedFormGroup;
	prepData = {};
	isSaving = false;
	companyCode = this.route.snapshot.paramMap.get('companyCode');
	formSubmitted = false;

	businessHeaderLogoDocFile = new DocumentFileClass();
	businessFooterLogoDocFile = new DocumentFileClass();
	disclosureDocFile = new DocumentFileClass();
	declarationDocFile = new DocumentFileClass();
	scopeOfServiceDocFile = new DocumentFileClass();
	letterOfAuthorityDocFile = new DocumentFileClass();
	businessOverviewDocFile = new DocumentFileClass();

	uploadBusinessHeaderLogo = false;
	uploadBusinessFooterLogo = false;
	uploadingDisclosure = false;
	uploadingDeclaration = false;
	uploadingScopeOfService = false;
	uploadingletterOfAuthority = false;
	uploadingbusinessOverview = false;

	removeBusinessHeaderLogo = false;
	removeBusinessFooterLogo = false;
	removeDisclosure = false;
	removeDeclaration = false;
	removeScopeOfService = false;
	removeletterOfAuthority = false;
	removebusinessOverview = false;

	showbusinessHeaderLogoLabel = false;
	showbusinessFooterLogoLabel = false;
	showdisclosureDocumentLabel = false;
	showdeclarationDocumentLabel = false;
	showscopeOfServiceLabel = false;
	showletterOfAuthorityLabel = false;
	showbusinessOverviewLabel = false;

	businessHeaderLogoLabel = null;
	businessFooterLogoLabel = null;
	disclosureDocumentLabel = null;
	declarationDocumentLabel = null;
	scopeOfServiceLabel = null;
	letterOfAuthorityLabel = null;
	businessOverviewLabel = null;

	businessHeaderLogoLabelRemove = null;
	businessFooterLogoLabelRemove = null;
	disclosureDocumentLabelRemove = null;
	declarationDocumentLabelRemove = null;
	scopeOfServiceLabelRemove = null;
	letterOfAuthorityLabelRemove = null;
	businessOverviewLabelRemove = null;

	docsHeader = null;
	docsFooter = null;
	docsDisclosure = null;
	docsDeclaration = null;
	docsScope = null;
	docsLetter = null;
	docsOverview = null;

	hasPermission$ = this.userQuery.hasPermission$;

	constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private userQuery: UserQuery, private loggerService: LoggerService,
		           private modalService: BsModalService) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.form.patchValue({
			tradingName: this.businessConfig.TradingName,
			businessPhoneNumber: this.businessConfig.BusinessPhoneNumber,
			businessEmailAddress: this.businessConfig.BusinessEmailAddress,
			facebookLink: this.businessConfig.FacebookLink,
			linkedInLink: this.businessConfig.LinkedInLink,
			unsubscribeGroupingID: this.businessConfig.UnsubscribeGroupingID,
		});
		if (this.businessConfig.BusinessHeaderLogo) {
			this.getDocument(this.businessConfig.BusinessHeaderLogo, 'HeaderLogo');
		} else { this.showbusinessHeaderLogoLabel = true; }
		if (this.businessConfig.BusinessFooterLogo) {
			this.getDocument(this.businessConfig.BusinessFooterLogo, 'FooterLogo');
		} else { this.showbusinessFooterLogoLabel = true; }
		if (this.businessConfig.DisclosureDocument) {
			this.getDocument(this.businessConfig.DisclosureDocument, 'Disclosure');
		} else { this.showdisclosureDocumentLabel = true; }
		if (this.businessConfig.DeclarationDocument) {
			this.getDocument(this.businessConfig.DeclarationDocument, 'Declaration');
		} else { this.showdeclarationDocumentLabel = true; }
		if (this.businessConfig.ScopeOfService) {
			this.getDocument(this.businessConfig.ScopeOfService, 'Scope of Service');
		} else { this.showscopeOfServiceLabel = true; }
		if (this.businessConfig.LetterOfAuthority) {
			this.getDocument(this.businessConfig.LetterOfAuthority, 'Letter of Authority');
		} else { this.showletterOfAuthorityLabel = true; }
		if (this.businessConfig.BusinessOverview) {
			this.getDocument(this.businessConfig.BusinessOverview, 'Business Overview');
		} else { this.showbusinessOverviewLabel = true; }

		this.hasPermission$(['BBEE'])
			.pipe(
				tap(x => (x ? this.form.enable() : this.form.disable())),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	downloadLink = data => {
		this.downloadLink$({ documentID: data })
			.pipe(
				tap(res => {
					window.location.href = res;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	removeDocument(type) {
		let file: RemoveFileRef;
		if (type === 'HeaderLogo') {
			file = {
				fileName: this.businessHeaderLogoLabel,
				docName: 'HeaderLogo'
			};
		}
		if (type === 'FooterLogo') {
			file = {
				fileName: this.businessFooterLogoLabel,
				docName: 'FooterLogo'
			};
		}
		if (type === 'Disclosure') {
			file = {
				fileName: this.disclosureDocumentLabel,
				docName: 'Disclosure'
			};
		}
		if (type === 'Declaration') {
			file = {
				fileName: this.declarationDocumentLabel,
				docName: 'Declaration'
			};
		}
		if (type === 'Scope of Service') {
			file = {
				fileName: this.scopeOfServiceLabel,
				docName: 'Scope of Service'
			};
		}
		if (type === 'Letter of Authority') {
			file = {
				fileName: this.letterOfAuthorityLabel,
				docName: 'Letter of Authority'
			};
		}
		if (type === 'Business Overview') {
			file = {
				fileName: this.businessOverviewLabel,
				docName: 'Business Overview'
			};
		}

		const confirm = new Observable(obs => {
			this.sendConfirm(type);
			obs.next();
			obs.complete();
		}).pipe(
			mergeMap(() => this.saveDocument(type))
		);

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: '',
			message: `Are you sure you want to remove?`,
			secondaryMessage: `${file.docName} = ${file.fileName}`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveDocument(type) {
		let file: {};
		if (type === 'HeaderLogo') {
			this.businessHeaderLogoDocFile = {
				DocumentType: null,
				CustomerId: 0,
				Document: null,
				FileName: null,
			};
			file = this.businessHeaderLogoDocFile;
			this.removeBusinessHeaderLogo = true;

			this.businessHeaderLogoLabel = '';
			this.businessHeaderLogoLabelRemove = '';
		}
		if (type === 'FooterLogo') {
			this.businessFooterLogoDocFile = {
				DocumentType: null,
				CustomerId: 0,
				Document: null,
				FileName: null,
			};
			file = this.businessFooterLogoDocFile;
			this.removeBusinessFooterLogo = true;

			this.businessFooterLogoLabel = '';
			this.businessFooterLogoLabelRemove = '';
		}
		if (type === 'Disclosure') {
			this.disclosureDocFile = {
				DocumentType: null,
				CustomerId: 0,
				Document: null,
				FileName: null,
			};
			file = this.disclosureDocFile;
			this.removeDisclosure = true;

			this.disclosureDocumentLabel = '';
			this.disclosureDocumentLabelRemove = '';
		}
		if (type === 'Declaration') {
			this.declarationDocFile = {
				DocumentType: null,
				CustomerId: 0,
				Document: null,
				FileName: null,
			};
			file = this.declarationDocFile;
			this.removeDeclaration = true;

			this.declarationDocumentLabel = '';
			this.declarationDocumentLabelRemove = '';
		}
		if (type === 'Scope of Service') {
			this.scopeOfServiceDocFile = {
				DocumentType: null,
				CustomerId: 0,
				Document: null,
				FileName: null,
			};
			file = this.scopeOfServiceDocFile;
			this.removeScopeOfService = true;

			this.scopeOfServiceLabel = '';
			this.scopeOfServiceLabelRemove = '';
		}
		if (type === 'Letter of Authority') {
			this.letterOfAuthorityDocFile = {
				DocumentType: null,
				CustomerId: 0,
				Document: null,
				FileName: null,
			};
			file = this.letterOfAuthorityDocFile;
			this.removeletterOfAuthority = true;

			this.letterOfAuthorityLabel = '';
			this.letterOfAuthorityLabelRemove = '';
		}
		if (type === 'Business Overview') {
			this.businessOverviewDocFile = {
				DocumentType: null,
				CustomerId: 0,
				Document: null,
				FileName: null,
			};
			file = this.businessOverviewDocFile;
			this.removebusinessOverview = true;

			this.businessOverviewLabel = '';
			this.businessOverviewLabelRemove = '';
		}

		return this.removeFileFn$({ file, type })
			.pipe(
				take(1),
				tap(() => {
					this.replaceUpload(type, true, false);
				}),
				takeUntil(this.onDestroy$)
			);
	}

	sendConfirm(type) {
		if (type === 'HeaderLogo') {
			this.businessHeaderLogoLabel = '';
		}
		if (type === 'FooterLogo') {
			this.businessFooterLogoLabel = '';
		}
		if (type === 'Disclosure') {
			this.disclosureDocumentLabel = '';
		}
		if (type === 'Declaration') {
			this.declarationDocumentLabel = '';
		}
		if (type === 'Scope of Service') {
			this.scopeOfServiceLabel = '';
		}
		if (type === 'Letter of Authority') {
			this.letterOfAuthorityLabel = '';
		}
		if (type === 'Business Overview') {
			this.businessOverviewLabel = '';
		}
		this.replaceUpload(type, true, true);
	}

	getDocument(value, type) {
		this.getDocument$({ value })
			.pipe(
				tap(res => {
					if (type === 'HeaderLogo') {
						this.businessHeaderLogoLabel = res?.DocumentName;
						this.businessHeaderLogoLabelRemove = res?.DocumentName;
						this.docsHeader = res?.DocumentID;
					}
					if (type === 'FooterLogo') {
						this.businessFooterLogoLabel = res?.DocumentName;
						this.businessFooterLogoLabelRemove = res?.DocumentName;
						this.docsFooter = res?.DocumentID;
					}
					if (type === 'Disclosure') {
						this.disclosureDocumentLabel = res?.DocumentName;
						this.disclosureDocumentLabelRemove = res?.DocumentName;
						this.docsDisclosure = res?.DocumentID;
					}
					if (type === 'Declaration') {
						this.declarationDocumentLabel = res?.DocumentName;
						this.declarationDocumentLabelRemove = res?.DocumentName;
						this.docsDeclaration = res?.DocumentID;
					}
					if (type === 'Scope of Service') {
						this.scopeOfServiceLabel = res?.DocumentName;
						this.scopeOfServiceLabelRemove = res?.DocumentName;
						this.docsScope = res?.DocumentID;
					}
					if (type === 'Letter of Authority') {
						this.letterOfAuthorityLabel = res?.DocumentName;
						this.letterOfAuthorityLabelRemove = res?.DocumentName;
						this.docsLetter = res?.DocumentID;
					}
					if (type === 'Business Overview') {
						this.businessOverviewLabel = res?.DocumentName;
						this.businessOverviewLabelRemove = res?.DocumentName;
						this.docsOverview = res?.DocumentID;
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	replaceUpload(type: string, show: boolean, showNewValue: boolean) {
		if (type === 'HeaderLogo') {
			this.showbusinessHeaderLogoLabel = show;
			this.businessHeaderLogoDocFile.FileName = null;
			this.uploadBusinessHeaderLogo = false;
			this.removeBusinessHeaderLogo = false;

			this.businessHeaderLogoLabel = showNewValue ? this.businessHeaderLogoLabelRemove : this.businessHeaderLogoLabel;
		}
		if (type === 'FooterLogo') {
			this.showbusinessFooterLogoLabel = show;
			this.businessFooterLogoDocFile.FileName = null;
			this.uploadBusinessFooterLogo = false;
			this.removeBusinessFooterLogo = false;

			this.businessFooterLogoLabel = showNewValue ? this.businessFooterLogoLabelRemove : this.businessFooterLogoLabel;
		}
		if (type === 'Disclosure') {
			this.showdisclosureDocumentLabel = show;
			this.disclosureDocFile.FileName = null;
			this.uploadingDisclosure = false;
			this.removeDisclosure = false;

			this.disclosureDocumentLabel = showNewValue ? this.disclosureDocumentLabelRemove : this.disclosureDocumentLabel;
		}
		if (type === 'Declaration') {
			this.showdeclarationDocumentLabel = show;
			this.declarationDocFile.FileName = null;
			this.uploadingDeclaration = false;
			this.removeDeclaration = false;

			this.declarationDocumentLabel = showNewValue ? this.declarationDocumentLabelRemove : this.declarationDocumentLabel;
		}
		if (type === 'Scope of Service') {
			this.showscopeOfServiceLabel = show;
			this.scopeOfServiceDocFile.FileName = null;
			this.uploadingScopeOfService = false;
			this.removeScopeOfService = false;

			this.scopeOfServiceLabel = showNewValue ? this.scopeOfServiceLabelRemove : this.scopeOfServiceLabel;
		}
		if (type === 'Letter of Authority') {
			this.showletterOfAuthorityLabel = show;
			this.letterOfAuthorityDocFile.FileName = null;
			this.uploadingletterOfAuthority = false;
			this.removeletterOfAuthority = false;

			this.letterOfAuthorityLabel = showNewValue ? this.letterOfAuthorityLabelRemove : this.letterOfAuthorityLabel;
		}
		if (type === 'Business Overview') {
			this.showbusinessOverviewLabel = show;
			this.businessOverviewDocFile.FileName = null;
			this.uploadingbusinessOverview = false;
			this.removebusinessOverview = false;

			this.businessOverviewLabel = showNewValue ? this.businessOverviewLabelRemove : this.businessOverviewLabel;
		}
	}

	buildForm() {
		this.form = this.fb.group({
			tradingName: [''],
			businessPhoneNumber: [''],
			businessEmailAddress: ['', validatorUtil.emailValidator],
			facebookLink: [''],
			linkedInLink: [''],
			unsubscribeGroupingID: [''],

			businessHeaderLogo: [''],
			businessFooterLogo: [''],

			disclosureDocument: [''],
			declarationDocument: [''],
			scopeOfService: [''],
			letterOfAuthority: [''],
			businessOverview: [''],
		});

	}

	prepareData(values) {
		return (this.prepData = {
			TradingName: values.tradingName ? values.tradingName : '',
			BusinessPhoneNumber: values.businessPhoneNumber ? values.businessPhoneNumber : '',
			BusinessEmailAddress: values.businessEmailAddress ? values.businessEmailAddress : '',
			FacebookLink: values.facebookLink ? values.facebookLink : '',
			LinkedInLink: values.linkedInLink ? values.linkedInLink : '',
			UnsubscribeGroupingID: values.unsubscribeGroupingID ? values.unsubscribeGroupingID : '',
		});
	}

	chooseFile(fileInput: any, type: string) {
		if (fileInput.target.files && fileInput.target.files[0]) {
			const limitSize = 25 * 1024;
			if ((Array.from(fileInput.target.files) as File[])?.some(x => Math.round(x.size / 1024) > limitSize)) {
				return;
			}
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const imgBase64Path = e.target.result;
				const name = fileInput.target.files[0].name;

				if (type === 'HeaderLogo') {
					this.businessHeaderLogoDocFile = {
						DocumentType: null,
						CustomerId: 0,
						Document: imgBase64Path?.split(',')[1],
						FileName: name,
					};
				}
				if (type === 'FooterLogo') {
					this.businessFooterLogoDocFile = {
						DocumentType: null,
						CustomerId: 0,
						Document: imgBase64Path.split(',')[1],
						FileName: name,
					};
				}
				if (type === 'Disclosure') {
					this.disclosureDocFile = {
						DocumentType: null,
						CustomerId: 0,
						Document: imgBase64Path?.split(',')[1],
						FileName: name,
					};
				}
				if (type === 'Declaration') {
					this.declarationDocFile = {
						DocumentType: null,
						CustomerId: 0,
						Document: imgBase64Path?.split(',')[1],
						FileName: name,
					};
				}
				if (type === 'Scope of Service') {
					this.scopeOfServiceDocFile = {
						DocumentType: null,
						CustomerId: 0,
						Document: imgBase64Path.split(',')[1],
						FileName: name,
					};
				}
				if (type === 'Letter of Authority') {
					this.letterOfAuthorityDocFile = {
						DocumentType: null,
						CustomerId: 0,
						Document: imgBase64Path?.split(',')[1],
						FileName: name,
					};
				}
				if (type === 'Business Overview') {
					this.businessOverviewDocFile = {
						DocumentType: null,
						CustomerId: 0,
						Document: imgBase64Path?.split(',')[1],
						FileName: name,
					};
				}
			};

			reader.readAsDataURL(fileInput.target.files[0]);
		}
	}

	convertFileToBase64 = (blob, reader = new FileReader()) =>
		new Observable(obs => {
			if (!(blob instanceof Blob)) {
				return;
			}
			reader.onabort = () => obs.error(reader.error);
			reader.onerror = () => obs.error(reader.error);
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(blob);
		})

	uploadModal(type: string) {
		const upload = req =>
			new Observable(obs => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				mergeMap(x => this.convertFileToBase64(x[0]).pipe(take(1))),
				map((base64File: any) => {

					if (type === 'HeaderLogo') {
						this.businessHeaderLogoDocFile = {
							DocumentType: null,
							CustomerId: 0,
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'FooterLogo') {
						this.businessFooterLogoDocFile = {
							DocumentType: null,
							CustomerId: 0,
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Disclosure') {
						this.disclosureDocFile = {
							DocumentType: null,
							CustomerId: 0,
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Declaration') {
						this.declarationDocFile = {
							DocumentType: null,
							CustomerId: 0,
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Scope of Service') {
						this.scopeOfServiceDocFile = {
							DocumentType: null,
							CustomerId: 0,
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Letter of Authority') {
						this.letterOfAuthorityDocFile = {
							DocumentType: null,
							CustomerId: 0,
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Business Overview') {
						this.businessOverviewDocFile = {
							DocumentType: null,
							CustomerId: 0,
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
				}),
				switchMap(() => this.uploadFile(type))
			);


		const initState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	uploadFile(type: string) {
		let file = {};
		if (type === 'HeaderLogo') {
			file = this.businessHeaderLogoDocFile;
			this.uploadBusinessHeaderLogo = true;
			this.businessHeaderLogoLabel = this.businessHeaderLogoDocFile.FileName;
			this.businessHeaderLogoLabelRemove = this.businessHeaderLogoDocFile.FileName;
		}
		if (type === 'FooterLogo') {
			file = this.businessFooterLogoDocFile;
			this.uploadBusinessFooterLogo = true;
			this.businessFooterLogoLabel = this.businessFooterLogoDocFile.FileName;
			this.businessFooterLogoLabelRemove = this.businessFooterLogoDocFile.FileName;
		}
		if (type === 'Disclosure') {
			file = this.disclosureDocFile;
			this.uploadingDisclosure = true;
			this.disclosureDocumentLabel = this.disclosureDocFile.FileName;
			this.disclosureDocumentLabelRemove = this.disclosureDocFile.FileName;
		}
		if (type === 'Declaration') {
			file = this.declarationDocFile;
			this.uploadingDeclaration = true;
			this.declarationDocumentLabel = this.declarationDocFile.FileName;
			this.declarationDocumentLabelRemove = this.declarationDocFile.FileName;
		}
		if (type === 'Scope of Service') {
			file = this.scopeOfServiceDocFile;
			this.uploadingScopeOfService = true;
			this.scopeOfServiceLabel = this.scopeOfServiceDocFile.FileName;
			this.scopeOfServiceLabelRemove = this.scopeOfServiceDocFile.FileName;
		}
		if (type === 'Letter of Authority') {
			file = this.letterOfAuthorityDocFile;
			this.uploadingletterOfAuthority = true;
			this.letterOfAuthorityLabel = this.letterOfAuthorityDocFile.FileName;
			this.letterOfAuthorityLabelRemove = this.letterOfAuthorityDocFile.FileName;
		}
		if (type === 'Business Overview') {
			file = this.businessOverviewDocFile;
			this.uploadingbusinessOverview = true;
			this.businessOverviewLabel = this.businessOverviewDocFile.FileName;
			this.businessOverviewLabelRemove = this.businessOverviewDocFile.FileName;
		}

		return this.uploadFileFn$({ file, type })
			.pipe(
				tap((x) => {
					this.replaceUpload(type, false, false);
				}),
				takeUntil(this.onDestroy$)
			);
	}

	sendBtnClick() {
		this.isSaving = true;
		this.formSubmitted =  true;

		if (this.form.invalid) {
			if (this.form.get('businessEmailAddress').invalid) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.BusinessEmailAddress));
			}
			this.isSaving = false;
			return;
		}

		const values = this.prepareData(this.form.value);
		this.sendBtnClick$({ values })
			.pipe(
				tap(() => {
					this.isSaving = false;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}
}

export class RemoveFileRef {
	fileName: string;
	docName: string;
}
