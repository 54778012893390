import { util } from 'src/app/core/util/util.service';
import MomentUtil from 'src/app/util/moment.util';
import { MedicalHistoryState } from './medical.model';

export class MedicalHistoryMapper {
	/** Mapping for view */
	static mapToView(data: MedicalHistoryState) {
		if (!data) {
			return { ...data };
		}
		const val = {
			...data,
			dateRangeMin: util.isValidMoment(data.dateRangeMin) ? data.dateRangeMin : MomentUtil.formatDateToMoment(data.dateRangeMin),
			dateRangeMax: util.isValidMoment(data.dateRangeMax) ? data.dateRangeMax : MomentUtil.formatDateToMoment(data.dateRangeMax)
		};
		return val;
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: MedicalHistoryState, personInfo: MedicalHistoryState): MedicalHistoryState {
		return {
            ...data,
			dateRangeMin: MomentUtil.formatDateToServerDate(data.dateRangeMin),
			dateRangeMax: MomentUtil.formatDateToServerDate(data.dateRangeMax),
			adviceProcessId: personInfo?.adviceProcessId,
			cRTId: personInfo?.cRTId,
			sectionCode: 'FMM',
			state: 1
		};
	}
}
