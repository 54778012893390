import { Injectable } from '@angular/core';
import { environment as env } from '@environment';
import { ApiService } from '@core/base/api.service';
import { Observable, map } from 'rxjs';
import { AiQuotePayloadState, AiQuoteResultState } from './ai-quote.model';

@Injectable({
	providedIn: 'root',
})
export class AiQuoteService {
	endpoint = `${env.aiQuoteApi}pdf-ingestion`;

	constructor(private api: ApiService) {}

	processAiQuotePdf(data: AiQuotePayloadState): Observable<AiQuoteResultState> {
		return this.api
			.externalPost(this.endpoint, data)
			.pipe(map((x) => x as AiQuoteResultState));
	}
}
