import { AdviceProcessSectionCodes } from '../../../../../../../../../shared/models/advice-process/advice-process.model';
import { CashDepositDetails, CashDepositState } from './cash-deposit.model';

export class CashDepositMapper {
	public static mapToView(data: CashDepositState): CashDepositDetails {
		return {
			// Cash/Deposit
			cRTId: data?.cRTId > 0 ? data?.cRTId : null,
			cash: data?.cRTId > 0 ? data?.cash?.cashDepositValue : null,
			gift: data?.cRTId > 0 ? data?.gift?.cashDepositValue : null,
			kiwiSaver: data?.cRTId > 0 ? data?.kiwiSaver?.cashDepositValue : null,
			cashFromExistingLoan:
				data?.cRTId > 0 ? data?.cashFromExistingLoan?.cashDepositValue : null,
			other: data?.cRTId > 0 ? data?.other?.map(val => {
				return {
					otherField: val.name,
					otherValue: val.cashDepositValue,
					isUse: val.isUse
				}
			}) : [],
			saleProceeds: data?.cRTId > 0 ? data?.saleProceeds?.cashDepositValue : null,
			newLendingRequired: data?.cRTId > 0 ? data?.newLendingRequired : 0,

			// Available Assets
			cashTick: data?.cRTId > 0 ? data?.cash?.isUse : false,
			kiwiSaverTick: data?.cRTId > 0 ? data?.kiwiSaver?.isUse : false,
			saleProceedsTick: data?.cRTId > 0 ? data?.saleProceeds?.isUse : false,
		};
	}

	public static mapToUpsert(data: CashDepositDetails): CashDepositState {
		const otherValue = data.other.map(val => {
			return {
				name: val.otherField,
				cashDepositValue: +val?.otherValue || 0,
				isUse: false,
			}
		});
		return {
			adviceProcessId: +data?.adviceProcessId,
			parentCRTId: +data?.parentCRTId,
			sectionCode: AdviceProcessSectionCodes.FundingRequired,
			cRTId: data?.cRTId || null,
			cash: {
				cashDepositValue: +data?.cash || 0,
				isUse: data?.cashTick || false,
			},
			kiwiSaver: {
				cashDepositValue: +data?.kiwiSaver || 0,
				isUse: data?.kiwiSaverTick || false,
			},
			gift: {
				cashDepositValue: +data?.gift || 0,
				isUse: false,
			},
			cashFromExistingLoan: {
				cashDepositValue: +data?.cashFromExistingLoan || 0,
				isUse: false,
			},
			other: otherValue ?? [],
			saleProceeds: {
				cashDepositValue: +data?.saleProceeds || 0,
				isUse: data?.saleProceedsTick || false,
			},
			newLendingRequired: +data?.newLendingRequired || 0,
			status: 1,
		};
	}
}
