import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { TopupRequirementsMapper } from './state/topup-requirements.mapper';
import { TopupRequirementsDetailsState } from './state/topup-requirements.model';
import { TopupRequirementsService } from './state/topup-requirements.service';
import { TopupRequirementsFormComponent } from './topup-requirements-form/topup-requirements-form.component';

@Component({
  selector: 'app-topup-requirements',
  templateUrl: './topup-requirements.component.html',
})
export class TopupRequirementsComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @Input() parentCRTId: number;
  topupRequirementList$ = this.service.topupRequirementList$;
  isLoading$ = this.service.isLoading$;
  options$ = this.service.topupOptions$;

  @ViewChild ('topupForm') topupForm: TopupRequirementsFormComponent;

  constructor(
    private service: TopupRequirementsService,
  ) { }

  ngOnInit(): void {
    this.service.get(this.parentCRTId).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  add = (data: TopupRequirementsDetailsState) => {
    data.parentCRTId = this.parentCRTId;
    const processedData = TopupRequirementsMapper.mapToTopupDetails(data);
    return this.service.add(processedData);
  }

  update = (data: TopupRequirementsDetailsState) => {
    data.parentCRTId = this.parentCRTId;
    const processedData = TopupRequirementsMapper.mapToTopupDetails(data);
    return this.service.update(processedData);
  }

  delete = (data: number) => {
    return this.service.delete(data);
  }

  clear() {
    this.topupForm?.clearAdd();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

}
