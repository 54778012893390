import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, zip, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { patchValue } from '../../services/service-utils/service.util';

@Component({
  selector: 'app-convert-modal',
  templateUrl: './convert-modal.component.html',
  styleUrls: ['./convert-modal.component.scss']
})
export class ConvertModalComponent implements OnInit, ConvertModalModel {
  customerId: number;
  contactStatus: string;
  note: string;
  header: string;
  name: string;
  isSaving = false;
  savefn: (ac: any) => Observable<any>;
  buttonLabel: string;
  LT: any;
  showLost = false;

  constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {
    this.setupProps();
    this.setupSubs();
  }
  form: UntypedFormGroup = this.fb.group({
    note: null,
    lostType: null
  });

  setupProps() {
  }
  setupSubs() { }

  ngOnInit() {
    if (this.contactStatus === 'X') {
      this.buttonLabel = 'Mark as Lost';
      this.showLost = true;
    } else { this.buttonLabel = 'Convert'; }

    zip(
      of(this.LT),
    ).pipe(
      take(1),
      map(ddListList => {
        const defaultValueList: string[] = ddListList?.map(ddList =>
          ddList?.find(dd => dd.isDefault)
        )?.map(def => def && def.value);
        return defaultValueList;
      })
    ).subscribe(this.setDropdownDefaults);
  }

  setDropdownDefaults: (defaultValues: string[]) => void =
    ([lt]) => {
      patchValue<any>(this.form, {
        lostType: lt,
      });
    }

  convert() {
    this.isSaving = true;
    const data = {
      customerID: this.customerId,
      contactStatus: this.contactStatus,
      note: this.form.get('note').value,
      lostType: this.contactStatus === 'X' ? this.form.get('lostType').value : ''
    };
    this.savefn(data).subscribe(x => {
      if (x) {
        this.isSaving = false;
      }
      this.decline();
    }, () => {
      this.isSaving = false;
    });
  }

  decline = () => this.bsModalRef.hide();

}
export interface ConvertModalModel {
  header: string;
  customerId: number;
  name: string;
  contactStatus: string;
  note: string;
}
