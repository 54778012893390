import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { PeopleState } from './people.model';

@Injectable()
@StoreConfig({ name: 'people', idKey: 'cRTId' })
export class PeopleStore extends EntityStore<PeopleState[]> {
	constructor() {
		super();
	}
}
