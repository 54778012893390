<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<div
				class="document-header d-flex align-items-center justify-content-end py-1"
			>
				<div class="document-header-nav col p-0">
					<ng-container *ngIf="isLoading">
						<div class="icon-btn float-right w-auto">
							<app-manual-loader-mini
								class="d-flex"
								[isLoading]="isLoading"
							></app-manual-loader-mini>
						</div>
					</ng-container>
					<ng-container *appRestrictTo="['FACD']">
						<ng-container *ngIf="activeDocType !== 'CP'">
							<button
								*ngIf="!isSaving && !isLoading"
								[disabled]="activeDocType === 'AP'"
								class="icon-btn float-right w-auto"
								id="btn_add_document"
								type="button"
								(click)="openUploadModal()"
							>
								<i
									class="document-plus_icon material-icons md-20 font-weight-bold"
									>add</i
								>
							</button>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="isSaving && !isLoading">
						<div class="float-right pr-3 my-2">
							<app-manual-loader-mini
								class="d-flex"
								[isLoading]="isSaving"
							></app-manual-loader-mini>
						</div>
					</ng-container>
				</div>
				<div class="document-header-content col p-0"></div>
			</div>

			<tabset #sidebarNav class="tap-tabset-sidebar" [vertical]="true">
				<tab
					*ngIf="hasLR"
					heading="L&R Insurance"
					id="lr"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('LR')"
				>
					<table class="table table-sm">
						<thead>
							<tr class="d-flex align-items-center">
								<th class="d-inline-block col-3 text-truncate">Name</th>
								<th class="d-inline-block col-3 text-truncate">
									Date Uploaded
								</th>
								<th class="d-inline-block col-4 text-truncate">Document</th>
								<th class="d-inline-block col-2 align-self-stretch"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of listPerPageLR;
									let i = index;
									trackBy: trackById
								"
								class="d-flex align-items-center"
							>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{ item.fileName }}</span>
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{
										item.createDateTime | momentDatetime : true
									}}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.createDateTime | momentDatetime : true }}</span
									>
								</td>
								<td class="d-inline-block col-4">
									<span *ngIf="!item.id">{{ item.documentName }}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.documentName }}</span
									>
								</td>
								<td class="d-inline-block col-2 text-right">
									<ng-container *appRestrictTo="['FTCD']">
										<button
											[disabled]="isSaving"
											class="btn btn-link p-1"
											(click)="transferDocumentConfirm(item)"
										>
											<i class="material-icons md-20 tap-text-color-h6"
												>arrow_forward</i
											>
										</button>
									</ng-container>
									<ng-container *appRestrictTo="['FDCD']">
										<button
											[disabled]="isSaving"
											type="button"
											class="btn btn-link p-1 ng-star-inserted"
											(click)="deleteConfirm(item)"
										>
											<i class="material-icons tap-text-color-h6 md-20">
												delete
											</i>
										</button>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="
							!!filteredListLR &&
							filteredListLR?.length > itemsPerPage &&
							activeDocType === 'LR'
						"
					>
						<pagination
							#page
							class="p-2"
							[totalItems]="
								!!filteredListLR && filteredListLR?.length > 0
									? filteredListLR?.length
									: 0
							"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemsPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event, 'lr')"
						>
						</pagination>
					</ng-container>
				</tab>
				<tab
					*ngIf="hasM"
					heading="Mortgage"
					id="m"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('M')"
				>
					<table class="table table-sm">
						<thead>
							<tr class="d-flex align-items-center">
								<th class="d-inline-block col-3 text-truncate">Name</th>
								<th class="d-inline-block col-3 text-truncate">
									Date Uploaded
								</th>
								<th class="d-inline-block col-4 text-truncate">Document</th>
								<th class="d-inline-block col-2 align-self-stretch"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of listPerPageM;
									let i = index;
									trackBy: trackById
								"
								class="d-flex align-items-center"
							>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{ item.fileName }}</span>
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.id">{{
										item.createDateTime | momentDatetime : true
									}}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										target="_blank"
										>{{ item.createDateTime | momentDatetime : true }}</span
									>
								</td>
								<td class="d-inline-block col-4">
									<span *ngIf="!item.id">{{ item.documentName }}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.documentName }}</span
									>
								</td>
								<td class="d-inline-block col-2 text-right">
									<ng-container *appRestrictTo="['FTCD']">
										<button
											[disabled]="isSaving"
											class="btn btn-link p-1"
											(click)="transferDocumentConfirm(item)"
										>
											<i class="material-icons md-20 tap-text-color-h6"
												>arrow_forward</i
											>
										</button>
									</ng-container>
									<ng-container *appRestrictTo="['FDCD']">
										<button
											[disabled]="isSaving"
											type="button"
											class="btn btn-link p-1 ng-star-inserted"
											(click)="deleteConfirm(item)"
										>
											<i class="material-icons tap-text-color-h6 md-20">
												delete
											</i>
										</button>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="
							!!filteredListM &&
							filteredListM?.length > itemsPerPage &&
							activeDocType === 'M'
						"
					>
						<pagination
							class="p-2"
							[totalItems]="
								!!filteredListM && filteredListM?.length > 0
									? filteredListM?.length
									: 0
							"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemsPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event, 'm')"
						>
						</pagination>
					</ng-container>
				</tab>
				<tab
					*ngIf="hasFG"
					heading="F&G Insurance"
					id="fg"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('FG')"
				>
					<table class="table table-sm">
						<thead>
							<tr class="d-flex align-items-center">
								<th class="d-inline-block col-3 text-truncate">Name</th>
								<th class="d-inline-block col-3 text-truncate">
									Date Uploaded
								</th>
								<th class="d-inline-block col-4 text-truncate">Document</th>
								<th class="d-inline-block col-2 align-self-stretch"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of listPerPageFG;
									let i = index;
									trackBy: trackById
								"
								class="d-flex align-items-center"
							>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{ item.fielName }}</span>
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.id">{{
										item.createDateTime | momentDatetime : true
									}}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.createDateTime | momentDatetime : true }}</span
									>
								</td>
								<td class="d-inline-block col-4">
									<span *ngIf="!item.id">{{ item.documentName }}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.documentName }}</span
									>
								</td>
								<td class="d-inline-block col-2 text-right">
									<ng-container *appRestrictTo="['FTCD']">
										<button
											[disabled]="isSaving"
											class="btn btn-link p-1"
											(click)="transferDocumentConfirm(item)"
										>
											<i class="material-icons md-20 tap-text-color-h6"
												>arrow_forward</i
											>
										</button>
									</ng-container>
									<ng-container *appRestrictTo="['FDCD']">
										<button
											[disabled]="isSaving"
											type="button"
											class="btn btn-link p-1 ng-star-inserted"
											(click)="deleteConfirm(item)"
										>
											<i class="material-icons tap-text-color-h6 md-20">
												delete
											</i>
										</button>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>

					<ng-container
						*ngIf="
							!!filteredListFG &&
							filteredListFG?.length > itemsPerPage &&
							activeDocType === 'FG'
						"
					>
						<pagination
							class="p-2"
							[totalItems]="!!filteredListFG ? filteredListFG?.length : 0"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemsPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event, 'fg')"
						>
						</pagination>
					</ng-container>
				</tab>
				<tab
					*ngIf="hasK || hasI"
					heading="Investment"
					id="k"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('K')"
				>
					<table class="table table-sm">
						<thead>
							<tr class="d-flex align-items-center">
								<th class="d-inline-block col-3 text-truncate">Name</th>
								<th class="d-inline-block col-3 text-truncate">
									Date Uploaded
								</th>
								<th class="d-inline-block col-4 text-truncate">Document</th>
								<th class="d-inline-block col-2 align-self-stretch"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of listPerPageK;
									let i = index;
									trackBy: trackById
								"
								class="d-flex align-items-center"
							>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{ item.fileName }}</span>
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.id">{{
										item.createDateTime | momentDatetime : true
									}}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.createDateTime | momentDatetime : true }}</span
									>
								</td>
								<td class="d-inline-block col-4">
									<span *ngIf="!item.id">{{ item.documentName }}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.documentName }}</span
									>
								</td>
								<td class="d-inline-block col-2 text-right">
									<ng-container *appRestrictTo="['FTCD']">
										<button
											[disabled]="isSaving"
											class="btn btn-link p-1"
											(click)="transferDocumentConfirm(item)"
										>
											<i class="material-icons md-20 tap-text-color-h6"
												>arrow_forward</i
											>
										</button>
									</ng-container>
									<ng-container *appRestrictTo="['FDCD']">
										<button
											[disabled]="isSaving"
											type="button"
											class="btn btn-link p-1 ng-star-inserted"
											(click)="deleteConfirm(item)"
										>
											<i class="material-icons tap-text-color-h6 md-20">
												delete
											</i>
										</button>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="
							!!filteredListK &&
							filteredListK?.length > itemsPerPage &&
							activeDocType === 'K'
						"
					>
						<pagination
							class="p-2"
							[totalItems]="
								!!filteredListK && filteredListK?.length > 0
									? filteredListK?.length
									: 0
							"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemsPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event, 'k')"
						>
						</pagination>
					</ng-container>
				</tab>
				<tab
					*ngIf="true"
					heading="Others"
					id="o"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('O')"
				>
					<table class="table table-sm">
						<thead>
							<tr class="d-flex align-items-center">
								<th class="d-inline-block col-3 text-truncate">Name</th>
								<th class="d-inline-block col-3 text-truncate">
									Date Uploaded
								</th>
								<th class="d-inline-block col-4 text-truncate">Document</th>
								<th class="d-inline-block col-2 align-self-stretch"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of listPerPageO;
									let i = index;
									trackBy: trackById
								"
								class="d-flex align-items-center"
							>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{ item.fileName }}</span>
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{
										item.createDateTime | momentDatetime : true
									}}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.createDateTime | momentDatetime : true }}</span
									>
								</td>
								<td class="d-inline-block col-4">
									<span *ngIf="!item.documentLink">{{ item.fileName }}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-2 text-right">
									<ng-container *appRestrictTo="['FTCD']">
										<button
											[disabled]="isSaving"
											class="btn btn-link p-1"
											(click)="transferDocumentConfirm(item)"
										>
											<i class="material-icons md-20 tap-text-color-h6"
												>arrow_forward</i
											>
										</button>
									</ng-container>
									<ng-container *appRestrictTo="['FDCD']">
										<button
											[disabled]="isSaving"
											type="button"
											class="btn btn-link p-1 ng-star-inserted"
											(click)="deleteConfirm(item)"
										>
											<i class="material-icons tap-text-color-h6 md-20">
												delete
											</i>
										</button>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="
							filteredListO &&
							filteredListO?.length > itemsPerPage &&
							activeDocType === 'O'
						"
					>
						<pagination
							class="p-2"
							[totalItems]="
								filteredListO && filteredListO?.length > 0
									? filteredListO?.length
									: 0
							"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemsPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event, 'o')"
						>
						</pagination>
					</ng-container>
				</tab>
				<tab
					*ngIf="hasCustomerPortalDocument"
					heading="Client Portal"
					id="cp"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('CP')"
				>
					<table class="table table-sm">
						<thead>
							<tr class="d-flex align-items-center">
								<th class="d-inline-block col-3 text-truncate">Name</th>
								<th class="d-inline-block col-3 text-truncate">
									Date Uploaded
								</th>
								<th class="d-inline-block col-4 text-truncate">Document</th>
								<th class="d-inline-block col-2 align-self-stretch"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								class="d-flex align-items-center"
								*ngFor="
									let item of listPerPageCP;
									let i = index;
									trackBy: trackById
								"
							>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{ item.fileName }}</span>
									<span
										*ngIf="item.documentLink"
										class="cursor-pointera wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{
										item.createDateTime | momentDatetime : true
									}}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.createDateTime | momentDatetime : true }}</span
									>
								</td>
								<td class="d-inline-block col-4">
									<span *ngIf="!item.documentLink">{{
										item.documentName
									}}</span>
									<span
										*ngIf="item.id"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.documentName }}</span
									>
								</td>
								<td class="d-inline-block col-2 text-right">
									<ng-container>
										<button
											[disabled]="isSaving"
											class="btn btn-link p-1"
											(click)="transferDocumentConfirm(item)"
										>
											<i class="material-icons md-20 tap-text-color-h6"
												>arrow_forward</i
											>
										</button>
									</ng-container>
									<ng-container>
										<button
											[disabled]="isSaving"
											type="button"
											class="btn btn-link p-1 ng-star-inserted"
											(click)="deleteConfirm(item)"
										>
											<i class="material-icons tap-text-color-h6 md-20">
												delete
											</i>
										</button>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="
							filteredListCP &&
							filteredListCP?.length > itemsPerPage &&
							activeDocType === 'CP'
						"
					>
						<pagination
							class="p-2"
							[totalItems]="
								filteredListCP && filteredListCP?.length > 0
									? filteredListCP?.length
									: 0
							"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemsPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event, 'cp')"
						>
						</pagination>
					</ng-container>
				</tab>
				<tab
					*ngIf="
						hasAP &&
						hasPermission &&
						filteredListAP && filteredListAP.length > 0
					"
					heading="Advice Process"
					id="ap"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('AP')"
				>
					<table class="table table-sm">
						<thead>
							<tr class="d-flex align-items-center">
								<th class="d-inline-block col-3 text-truncate">Name</th>
								<th class="d-inline-block col-3 text-truncate">
									Date Uploaded
								</th>
								<th class="d-inline-block col-4 text-truncate">Document</th>
								<th class="d-inline-block col-2 align-self-stretch"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of listPerPageAP;
									let i = index;
									trackBy: trackById
								"
								class="d-flex align-items-center"
							>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{ item.fileName }}</span>
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
										>{{ item.fileName }}</span
									>
								</td>
								<td class="d-inline-block col-3">
									<span *ngIf="!item.documentLink">{{
										item.createDateTime | momentDatetime : true
									}}</span>
									<!-- <a
										*ngIf="item.documentLink"
										class="wrap-document"
										[href]="item.documentLink"
									> -->
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
									>
										{{ item.createDateTime | momentDatetime : true }}
									</span>
									<!-- </a> -->
								</td>
								<td class="d-inline-block col-4">
									<span *ngIf="!item.documentLink">{{
										item.documentName
									}}</span>
									<!-- <a
										*ngIf="item.documentLink"
										class="wrap-document"
										[href]="item.documentLink"
									> -->
									<span
										*ngIf="item.documentLink"
										class="cursor-pointer wrap-document"
										(click)="downloadLink(item)"
									>
										{{ item.documentName }}
									</span>
									<!-- </a> -->
								</td>
								<td class="d-inline-block col-2 text-right">
									<ng-container *appRestrictTo="['FTCD']">
										<button
											[disabled]="isSaving"
											class="btn btn-link p-1"
											(click)="transferDocumentConfirm(item)"
										>
											<i class="material-icons md-20 tap-text-color-h6"
												>arrow_forward</i
											>
										</button>
									</ng-container>
									<ng-container *appRestrictTo="['FDCD']">
										<button
											[disabled]="isSaving"
											type="button"
											class="btn btn-link p-1 ng-star-inserted"
											(click)="deleteConfirm(item)"
										>
											<i class="material-icons tap-text-color-h6 md-20">
												delete
											</i>
										</button>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="
							filteredListAP &&
							filteredListAP?.length > itemsPerPage &&
							activeDocType === 'AP'
						"
					>
						<pagination
							class="p-2"
							[totalItems]="
								filteredListAP && filteredListAP?.length > 0
									? filteredListAP?.length
									: 0
							"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemsPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event, 'ap')"
						>
						</pagination>
					</ng-container>
				</tab>
			</tabset>
		</div>
	</div>
</div>
