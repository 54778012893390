<div *ngIf="!checkingReviewApplication; else showLoader">
	<mat-horizontal-stepper
		#stepper
		(selectionChange)="onSelectionChange($event)"
		[@.disabled]="true"
	>
		<!-- Purpose -->
		<mat-step [completed]="isPurposeCompleted" [label]="stepHeaders.Purpose">
			<!-- <ng-template matStepLabel>Purpose</ng-template> -->

			<div class="application-step-content">
				<div class="main-content-section">
					<div class="main-content-section__body mb-3">
						<app-purpose
							#purpose
							(saveCompleted)="handleSaveComplete($event)"
							[applicationBanks]="applicationBanks$ | async"
							[primaryBanks]="primaryBanks$"
							[parentCRTId]="applicationId"
							[isApplicationDocumentLoading]="isApplicationDocumentLoading$"
						></app-purpose>
					</div>
				</div>
			</div>

			<div class="d-flex justify-content-end">
				<button
					(click)="backToApplications()"
					[class.disabled]="isPurposeLoading$ | async"
					class="tap-btn tap-btn--primary mr-auto text-white"
				>
					Back to Applications
				</button>
				<button
					*ngIf="!isLoading"
					mat-button
					class="tap-btn tap-btn--primary text-white ml-2"
					(click)="handleNext(sectionCodes.Purpose)"
					[class.disabled]="isPurposeLoading$ | async"
					[disabled]="isPurposeLoading$ | async"
				>
					Next
				</button>
				<app-manual-loader-mini
					*ngIf="isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</div>
		</mat-step>

		<!-- Funding Required -->
		<mat-step [label]="stepHeaders.FundingRequired">
			<!-- <ng-template matStepLabel>Funding Required</ng-template> -->

			<div class="application-step-content" [class.submitted]="fundingReqSubmitted">
				<ng-container *ngIf="loadSellingSection">
					<app-selling-section
						#sellingSection
						[parentCRTId]="applicationId"
						[isRefinance$]="isRefinance$"
						[deceasedSciList$]="deceasedSciList$"
					></app-selling-section>
				</ng-container>

				<app-property-purchase
					*ngIf="hasNewPurchase$ | async"
					[parentCRTId]="applicationId"
					[deceasedSciList$]="deceasedSciList$"
				></app-property-purchase>

				<ng-container *ngIf="loadRefinanceSection">
					<app-loan-refinance
						[parentCRTId]="applicationId"
						[deceasedSciList$]="deceasedSciList$"
					></app-loan-refinance>
				</ng-container>

				<ng-container *ngIf="isLendingTopup$ | async">
					<app-topup-requirements
						#topups
						[parentCRTId]="applicationId"
					></app-topup-requirements>
				</ng-container>

				<div class="row">
					<div class="col-lg-3 col-12 pt-3 pb-2"></div>
					<div class="col-lg-3 col-12 pt-3 pb-2"></div>
					<div class="col-lg-4 col-12 pt-3 pb-2 section-totals">
						<div class="row">
							<div class="col-4 col-lg-5 text-left mb-2">
								<label class="position-relative font-weight-bold tap-text-primary">Lending continuing at {{ activeBank ? activeBank : 'Bank' }}: </label>
							</div>
							<div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
								<span>{{ sectionTotals.lendingContinuingTotal | currency}} </span>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-3 col-12 pb-2"></div>
					<div class="col-lg-3 col-12 pb-2"></div>
					<div class="col-lg-4 col-12 pb-2 section-totals">
						<div class="row">
							<div class="col-4 col-lg-5 text-left mb-2">
								<label class="position-relative font-weight-bold tap-text-primary">New Funding Required at {{ activeBank ? activeBank : 'Bank' }}: </label>
							</div>
							<div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
								<span>{{ sectionTotals.newFundingRequiredTotal | currency}} </span>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-3 col-12 pb-2"></div>
					<div class="col-lg-3 col-12 pb-2"></div>
					<div class="col-lg-4 col-12 pb-2 section-totals">
						<div class="row">
							<div class="col-4 col-lg-5 text-left mb-2">
								<label class="position-relative font-weight-bold tap-text-primary">Total: </label>
							</div>
							<div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
								<span> {{ sectionTotals.total | currency}} </span>
							</div>
						</div>
					</div>
				</div>

				<div class="main-content-section__footer mb-4">
					<div class="border-bottom"></div>
				</div>

				<app-cash-deposit
					#cashDeposit
					(saveCompleted)="handleSaveComplete($event)"
					(setFundingReqSubmitted)="setFundingReqSubmitted($event)"
					[parentCRTId]="applicationId"
					[hasNewPurchase]="hasNewPurchase$ | async"
					[isRefinance$]="isRefinance$"
					[isSellingProperty$]="isSellingProperty$"
					[isLendingTopup$]="isLendingTopup$"
					[activeBank]="activeBank ? activeBank : 'Bank'"
				></app-cash-deposit>
			</div>

			<div class="d-flex justify-content-end">
				<button
					(click)="backToApplications()"
					class="tap-btn tap-btn--primary mr-auto text-white"
				>
					Back to Applications
				</button>
				<ng-container *ngIf="!isLoading">
					<button
						mat-button
						class="tap-btn tap-btn--default ml-2"
						(click)="handleBack(sectionCodes.FundingRequired)"
					>
						Back
					</button>
					<button
						mat-button
						class="tap-btn tap-btn--primary text-white ml-2"
						(click)="handleNext(sectionCodes.FundingRequired)"
					>
						Next
					</button>
				</ng-container>
				<app-manual-loader-mini
					*ngIf="isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</div>
		</mat-step>

		<!-- Security -->
		<mat-step [label]="stepHeaders.Security">
			<!-- <ng-template matStepLabel>Security</ng-template> -->

			<div class="application-step-content">
				<app-security
					#security
					[parentCRTId]="applicationId"
					[application]="activeApplication$ | async"
					[adviceProcessId]="adviceProcessId"
					[fundingRequiredTotals]="sectionTotals$ | async"
					(saveCompleted)="handleSaveComplete($event)"
				></app-security>
			</div>

			<div class="d-flex justify-content-end">
				<button
					(click)="backToApplications()"
					class="tap-btn tap-btn--primary mr-auto text-white"
				>
					Back to Applications
				</button>
				<ng-container *ngIf="!isLoading">
					<button
						mat-button
						class="tap-btn tap-btn--default ml-2"
						(click)="handleBack(sectionCodes.Security)"
					>
						Back
					</button>
					<button
						mat-button
						class="tap-btn tap-btn--primary text-white ml-2"
						(click)="handleNext(sectionCodes.Security)"
					>
						Next
					</button>
				</ng-container>
				<app-manual-loader-mini
					*ngIf="isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</div>
		</mat-step>

		<mat-step [label]="stepHeaders.LoanStructure">
			<div class="application-step-content">
				<app-loan-structure
					#loanStructure="appLoanStructure"
					(isSaving)="isTabLoading($event)"
					[parentCRTId]="applicationId"
					[adviceProcessId]="adviceProcessId"
					[application]="activeApplication$ | async"
					[getOwnerChoices]="getOwnerChoices"
					[fundingRequiredTotals$]="sectionTotals$"
				>
				</app-loan-structure>
			</div>
			<!-- .application-step-content -->
			<div class="d-flex justify-content-end">
				<button
					(click)="backToApplications(sectionCodes.ApplicationLoanStructure)"
					class="tap-btn tap-btn--primary mr-auto text-white"
					[class.disabled]="
						tabLoading ||
						((loanStructure.loanStructure$ | async)?.length
							? false
							: !loanStructure.loanStructureToFollow.value)
					"
				>
					Back to Applications
				</button>

				<button
					mat-button
					class="tap-btn tap-btn--default ml-2"
					(click)="stepper.previous(); nextSubSection(sectionCodes.ApplicationLoanStructure)"
					[class.disabled]=" tabLoading "
				>
					Back
				</button>
				<button
					mat-button
					class="tap-btn tap-btn--primary text-white ml-2"
					(click)="handleNext(sectionCodes.ApplicationLoanStructure)"
					[class.disabled]="
						tabLoading ||
						((loanStructure.loanStructure$ | async)?.length
							? false
							: !loanStructure.loanStructureToFollow.value)
					"
				>
					Next
				</button>
			</div>
		</mat-step>

		<!-- Notes -->
		<mat-step [label]="stepHeaders.Notes">
			<!-- <ng-template matStepLabel>Notes</ng-template> -->

			<div class="application-step-content">
				<app-notes
					#notes
					(saveCompleted)="handleSaveComplete($event)"
					[parentCRTId]="applicationId"
				></app-notes>
			</div>

			<div class="d-flex justify-content-end">
				<button
					(click)="backToApplications()"
					class="tap-btn tap-btn--primary mr-2 text-white"
				>
					Back to Applications
				</button>
				<button
					(click)="downloadServicingCalculator()"
					class="tap-btn tap-btn--default mr-auto"
				>
					Download Servicing Calculator
				</button>
				<!-- @TODO HANDLE HANDLE BACK LIKE IN FUNDING REQUIRED ONCE MERGE -->
				<ng-container *ngIf="!isLoading">
					<button
						mat-button
						class="tap-btn tap-btn--default ml-2"
						(click)="handleBack(sectionCodes.Notes)"
					>
						Back
					</button>
					<button
						mat-button
						class="tap-btn tap-btn--primary text-white ml-2"
						(click)="handleNext(sectionCodes.Notes)"
					>
						Next
					</button>
				</ng-container>

				<app-manual-loader-mini
					*ngIf="isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</div>
		</mat-step>

		<!-- Documents -->
		<mat-step [label]="stepHeaders.Documents">
			<!-- <ng-template matStepLabel>Documents</ng-template> -->

			<div class="application-step-content tap-scroll--vertical-only">
				<app-documents
					#documents
					[applicationDocuments]="applicationDocuments$ | async"
					[documentSettings]="documentSettings"
					[parentCRTId]="applicationId"
					(fetchClientDocuments)="fetchClientDocuments($event)"
					(saveCompleted)="handleSaveComplete($event)"
				></app-documents>
			</div>

			<div class="d-flex justify-content-end">
				<button
					(click)="backToApplications(sectionCodes.ApplicationDocument)"
					class="tap-btn tap-btn--primary mr-auto text-white"
					[class.disabled]="isApplicationDocumentLoading$ | async"
				>
					Back to Applications
				</button>
				<button
					mat-button
					class="tap-btn tap-btn--default ml-2"
					[class.disabled]="isApplicationDocumentLoading$ | async"
					(click)="handleBack(sectionCodes.ApplicationDocument)"
				>
					Back
				</button>
				<button
					mat-button
					class="tap-btn tap-btn--primary text-white ml-2"
					[class.disabled]="isApplicationDocumentLoading$ | async"
					(click)="handleNext(sectionCodes.ApplicationDocument)"
				>
					Next
				</button>
			</div>
		</mat-step>

		<!-- Review Application -->
		<mat-step [label]="stepHeaders.ReviewApplication">
			<div class="application-step-content">
				<app-review-application
					#reviewApplication
					(saveCompleted)="handleSaveComplete($event)"
					(toggleLoading)="handleToggleLoading($event)"
					[parentCRTId]="applicationId"
					(goBackToList)="backToApplications()"
					(doResetStores)="resetStores()"
					(saveCurrentPage)="saveCurrentPage($event)"
					(lastAutoSavedTime)="lastAutoSavedTimeApp($event)"
					(isAutoSaveLoading)="isAutoSaveLoading($event)"
				></app-review-application>
			</div>

			<div class="d-flex justify-content-end">
				<ng-container *ngIf="!isLoading">
					<button
						(click)="backToApplications(sectionCodes.ReviewApplication)"
						class="tap-btn tap-btn--primary mr-2 text-white"
						[disabled]="isFinalizing || reviewApplication?.isResetting || isAutoSaving"
					>
						Back to Applications
					</button>
					<button
						(click)="resetMergeTags()"
						class="tap-btn tap-btn--default mr-auto"
						[disabled]="isFinalizing || reviewApplication?.isResetting || isAutoSaving"
					>
						Reset Merge Tags
						<i *ngIf="reviewApplication?.isResetting" class="fas fa-spinner fa-pulse"></i>
					</button>
					<span *ngIf="isAutoSaving">
						<app-manual-loader-mini
							[isLoading]="isAutoSaving"
						></app-manual-loader-mini>
					</span>
					<span *ngIf="reviewAppLastSaved" class="pt-2 last-modified">
						Last saved {{ reviewAppLastSaved }}
					</span>
					<button
						mat-button
						class="tap-btn tap-btn--default ml-2"
						(click)="handleBack(sectionCodes.ReviewApplication)"
						[disabled]="isFinalizing || reviewApplication?.isResetting || isAutoSaving"
					>
						Back
					</button>
					<button
						class="tap-btn tap-btn--primary text-white ml-2"
						(click)="handleNext(sectionCodes.ReviewApplication)"
						[disabled]="isFinalizing || reviewApplication?.isResetting || isAutoSaving"
					>
						Save & Exit
					</button>
					<button
						(click)="finalizedAndDownload()"
						[disabled]="isFinalizing || reviewApplication?.isResetting || isAutoSaving"
						class="tap-btn tap-btn--primary text-white ml-2"
					>
						Download Application
						<i *ngIf="isFinalizing" class="fas fa-spinner fa-pulse"></i>
					</button>
				</ng-container>
				<app-manual-loader-mini
					*ngIf="isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>

<ng-template #showLoader>
	<app-manual-loader-mini
		[isLoading]="true"
	></app-manual-loader-mini>
</ng-template>

<ng-template #progressTemplate>
	<app-export-progress
		[progress]="downloadProgress"
		[percent]="downloadPercentage"
		msg="Downloading Service Calculator"
	></app-export-progress>
</ng-template>
