import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { FGPremiumStatsLeadType } from './fgpslt.model';
import { Injectable } from "@angular/core";

export interface FGPremiumStatsLeadTypeState {

  /**
   * FG Premium Stats Lead Type fields
   */
  FGPremiumStatsLeadType: FGPremiumStatsLeadType[];

  /**
   * error message. will show up when not empty
   */
  error: string;

  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;

  isAllLeadTypeSelected: boolean;

  selectedTypes: string[];
}

/**
 * Create initial state store
 */
export function createInitialState(): FGPremiumStatsLeadTypeState {
  return {
    FGPremiumStatsLeadType: [],
    error: '',
    isLoading: false,
    isAllLeadTypeSelected: false,
    selectedTypes: [],
  };
}


/**
 * FG Premium Stats Lead Type widget data store for Rolling 12 Months (Year)
 */
@Injectable()
@StoreConfig({
  name: 'FGPremiumStatsLeadType'
})
export class FGPremiumStatsLeadTypeStore extends Store<FGPremiumStatsLeadTypeState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Set if FGP remium Stats LeadType is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }

  setIsAllLeadTypeSelected(checked: boolean) {
    this.update(produce(draft => { draft.isAllLeadTypeSelected = checked; }));
  }

  setSelectedTypes(types: string[]) {
    this.update(produce(draft => { draft.selectedTypes = types; }));
  }
}
