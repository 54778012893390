import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { complement, isNil, isEmpty, either } from 'ramda';
import { combineLatest, Observable, Observer, of, Subject } from 'rxjs';
import {
	catchError,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { AssetsLiabilitiesService } from '../../states/assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { LoggerService } from './../../../../../core/logger/logger.service';
import { DropdownValue } from './../../../../../modules/dropdown/model/dropdownValue';
import { AssetsLiabilitiesModalComponent } from './../../../../../shared/modal/crt/fact-find/assets-liabilities/assets-liabilities.modal.component';
import { DeleteModalComponent } from './../../../../../shared/modal/delete-modal/delete-modal.component';
import { KiwiSaverMapper } from './../../../../../shared/models/client-review-template/assets-liabilities/kiwiSaver/kiwiSaver.mapper';
import { KiwiSaverState, KiwiSaverStatus } from './../../../../../shared/models/client-review-template/assets-liabilities/kiwiSaver/kiwiSaver.model';
import { PeopleState } from './../../../../../shared/models/client-review-template/people/people.model';
import { Kiwisaver } from './../../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { ViewDisplayValue } from './../../../../../shared/models/_general/display-value.viewmodel';

declare var $: any;

@Component({
	selector: 'app-kiwisaver',
	templateUrl: './kiwisaver.component.html',
	styleUrls: ['./kiwisaver.component.scss'],
})
export class KiwisaverComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Input() isAdviceProcessEnded: boolean;

	@Input() dropdownCodes: DropdownValue[];
	@Input() lifeAssuredList: ViewDisplayValue[];
	kiwisavers$: Observable<KiwiSaverState[]> = this.query.kiwiSavers$;
	kiwiSavers$: Observable<KiwiSaverState[]> = this.query.kiwiSavers$;

	kp$ = this.service.KP$;
	kft$ = this.service.KFT$;
	crmKiwisavers$ = this.query.crmKiwiSavers$.pipe(
		map((x) => x?.filter((p) => p?.Status === KiwiSaverStatus.Active))
	);
	crmKiwisaverChoices$ = combineLatest([
		this.query.crmKiwiSavers$,
		this.query.peopleFromCrmAndCrtExceptChildChoices$,
		this.service.kiwiSavers$,
	]).pipe(
		filter(([x, y, z]) => !!x && !!y && !!z),
		map(([x, po, kiwiSaver]) => {
			const crmKiwiSavers = x?.filter((p) => p?.Status === KiwiSaverStatus.Active);
			return crmKiwiSavers
				?.filter(
					(y) =>
						!kiwiSaver
							?.filter((ks) => !!ks.cRTId)
							?.some((k) => +k.customerServiceID === +y.CustomerServiceID)
				)
				.map((k) => {
					const ownerDisplays: string[] = [];
					po.forEach((person) => {
						if (k.FundOwner?.find((owner) => +owner === +person.value)) {
							ownerDisplays.push(person.display);
						}
					});

					return ViewDisplayValue.Map(
						`${k.CustomerServiceID}`,
						`${ownerDisplays.join(', ')} - ${k.Provider}`
					);
				})
		})
	);

	lifeAssuredList$ = this.query.peopleFromCrmAndCrtChoices$;

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;
	deletingId: number | undefined;

	elseMinusKiwiSaver = true;
	isLoading = false;
	isAdding = false;
	isAddNew = false;
	isEditing = false;
	editIndex: number | undefined;

	adviceProcessId = this.query.getValue().adviceProcessId;
	crmKiwisavers: Kiwisaver[];
	selectedFundOwner: number | undefined;

	isLoading$ = this.query.isKiwiSaversLoading$;
	kft: ViewDisplayValue[];
	transferedSCI: PeopleState[];

	@Input() deceasedSciList: ViewDisplayValue[] = [];

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private service: AssetsLiabilitiesService,
		private query: ClientReviewTemplateQuery,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.crmKiwisavers$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((res) => (this.crmKiwisavers = res));

		this.kft$
			.pipe(
				tap((x) => (this.kft = x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.query.transferedSCIList$
			.pipe(
				filter((x) => !!x),
				tap((x) => (this.transferedSCI = x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.prepareData();
	}

	get KiwiSavers() {
		return this.form.get('kiwiSavers') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			kiwiSavers: this.fb.array([]),
		});
	}

	prepareData() {
		this.form.reset();
		this.kiwiSavers$
			.pipe(
				tap((x) => {
					(!isNil(x) ? x : []).forEach((p) => {
						this.KiwiSavers?.push(this.patchValue(p));
					});
					this.form.disable();
				})
			)
			.subscribe();
	}

	patchValue(k?: KiwiSaverState): UntypedFormGroup {
		return !k
			? this.fb.group({
					cRTId: [0],
					fundOwner: [null],
					owner: [[], Validators.required],
					provider: [null, Validators.required],
					currentBalance: [0, Validators.required],
					contributionRate: [null],
					fundType: [[]], // @TODO PASS THE CORRECT DEFAULT FUND TYPE
					customerServiceID: [null],

					isEdit: [true],
					isLoading: [false],
					isNew: [true],
			  })
			: this.fb.group({
					cRTId: [k?.cRTId],
					fundOwner: [k?.fundOwner, Validators.required],
					owner: [k?.fundOwner, Validators.required],
					provider: [k?.provider, Validators.required],
					currentBalance: [k?.currentBalance, Validators.required],
					contributionRate: [k?.contributionRate],
					fundType: [k?.fundType], // @TODO PASS THE CORRECT DEFAULT FUND TYPE
					customerServiceID: [k?.customerServiceID],

					isEdit: [false],
					isLoading: [false],
					isNew: [false],
			  });
	}

	policyOwnerChoices = (owners: (string | number)[]) => {
		return this.service.getOwnerChoices(owners, this.lifeAssuredList);
	};

	setEdit(index: number, isLoading: boolean) {
		this.KiwiSavers.controls[index].get('isEdit').setValue(isLoading);
	}

	disableFormItem(index: number) {
		this.setEdit(index, false);
		this.KiwiSavers.controls[index].disable();
	}

	enableFormItem(index: number) {
		this.KiwiSavers.controls[index].enable();
		this.setEdit(index, true);
	}

	setLoading(index: number, isLoading: boolean) {
		this.KiwiSavers.controls[index]?.get('isLoading').setValue(isLoading);
	}

	collapseMoreKiwiSaver() {
		$('#collapseKiwiSaver').toggle();
		this.elseMinusKiwiSaver = false;
	}

	collapseLessKiwiSaver() {
		$('#collapseKiwiSaver').toggle();
		this.elseMinusKiwiSaver = true;
	}

	saveNew$ = (model: KiwiSaverState) => {
		return new Observable((obs: Observer<any>) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) => ({
				...x,
				fundType: x?.fundType?.filter((f: string) =>
					this.kft?.some((kft) => kft.value === f)
				),
			})),
			map((x) =>
				KiwiSaverMapper.mapToKiwiSaverDetails(x, this.adviceProcessId)
			),
			mergeMap((x) =>
				this.service.addAL(x, AdviceProcessSectionCodes.KiwiSaver)
			),
			catchError(() => of(undefined)),
			finalize(() => {
				if (+model?.owner && this.checkSCIByCustomerId(+model?.owner)) {
					this.invalidSciWarning();
				}
				this.isAdding = false;
				this.isAddNew = false;
				this.buildForm();
				this.prepareData();
			})
		);
	};

	onSelectFundOwner(data: string, index: number) {
		if (data === 'new') {
			const decline = new Observable((obs: Observer<any>) => {
				(
					document.getElementById('kiwisaveformOwnerAdd') as HTMLInputElement
				).value = '';
				obs.complete();
			});

			let newLifeAssuredList$ = this.lifeAssuredList$;

			if (this.deceasedSciList && this.deceasedSciList.length) {
				const deceasedIds = this.deceasedSciList.map((item) => `${item.value}`);
	
				newLifeAssuredList$ = this.lifeAssuredList$.pipe(
					map((lifeAssuredList) => {
						return lifeAssuredList.filter((item) => !deceasedIds.includes(item.value));
					})
				);
			}

			const initState = {
				header: 'KiwiSaver Details',
				message: `KiwiSaver`,
				lifeAssuredList$: newLifeAssuredList$,
				getOwnerChoices: this.service.getOwnerChoices,
				kp$: this.service.KP$,
				apcrtrp$: this.service.APCRTRP$,
				kft$: this.service.KFT$,
				saveFn$: this.saveNew$,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(
				AssetsLiabilitiesModalComponent,
				{
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				}
			);
		} else {
			const d: Kiwisaver = this.crmKiwisavers?.find(
				(ks) => ks.CustomerServiceID === +data
			);
			this.selectedFundOwner = d.CustomerServiceID;
			this.KiwiSavers.controls[index].reset({
				cRTId: 0,
				fundOwner: d?.FundOwner,
				owner: d?.FundOwner,
				provider: d?.Provider,
				currentBalance: d?.FUM || 0,
				contributionRate: d?.Contribution || null,
				fundType: d?.FundType ?? [], // @TODO PASS THE CORRECT DEFAULT FUND TYPE
				customerServiceID: d?.CustomerServiceID,
				isNew: true,
				isEdit: true,
				isLoading: false,
			});
		}
	}

	saveKiwiSaver(index: number) {
		this.isAdding = true;
		this.KiwiSavers.controls[index].get('isLoading').setValue(true);
		const kiwiSaver = this.KiwiSavers.controls[index].value;
		const data = KiwiSaverMapper.mapToKiwiSaverDetails(
			{
				...kiwiSaver,
				fundType: kiwiSaver?.fundType?.filter((f: string) =>
					this.kft.some((kft) => kft.value === f)
				),
			},
			this.adviceProcessId
		);
		this.service
			.addAL(data, AdviceProcessSectionCodes.KiwiSaver)
			.pipe(
				finalize(() => {
					this.selectedFundOwner = undefined;
					this.isAdding = false;
					this.isAddNew = false;

					this.setEdit(index, false);
					this.setLoading(index, false);

					this.buildForm();
					this.prepareData();
				}),
				catchError(() => {
					// Enable form if something went wrong while saving.
					this.enableFormItem(index);
					this.setLoading(index, false);
					return of(undefined);
				}),
				take(1)
			)
			.subscribe();
	}

	addKiwiSaver() {
		this.isAddNew = true;
		this.KiwiSavers?.push(this.patchValue());
	}

	cancelAdd(index: number) {
		this.kiwisavers$
			.pipe(
				map((x) => x.splice(index, 1)),
				tap(() => (this.isAddNew = false)),
				tap(() => (this.selectedFundOwner = undefined)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	removeProperty(index: number) {
		this.isAddNew = false;
		this.selectedFundOwner = undefined;
		this.KiwiSavers.removeAt(index);
	}

	updateFn$ = (model: KiwiSaverState) =>
		new Observable((obs: Observer<any>) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) => ({
				...x,
				fundType: x?.fundType?.filter((f: string) =>
					this.kft?.some((kft) => kft.value === f)
				),
			})),
			map((x) =>
				KiwiSaverMapper.mapToKiwiSaverDetails(x, this.adviceProcessId)
			),
			mergeMap((x) =>
				this.service.updateAL(x, AdviceProcessSectionCodes.KiwiSaver)
			),
			catchError(() => of(undefined)),
			finalize(() => {
				if (+model?.owner && this.checkSCIByCustomerId(+model?.owner)) {
					this.invalidSciWarning();
				}
				this.isAdding = false;
				this.isAddNew = false;
				this.buildForm();
				this.prepareData();
			})
		);

	editKiwiSaver(cRTId: number, index?: number) {
		this.isAdding = false;
		this.isAddNew = false;
		this.isEditing = true;
		this.editIndex = index;
		this.KiwiSavers.controls[index].get('isLoading').setValue(true);
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		this.service
			.getCrtById(cRTId)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.editIndex = undefined;
					this.KiwiSavers.controls[index].get('isLoading').setValue(false);
				}),
				take(1)
			)
			.subscribe((x: any) => {
				let newLifeAssuredList$ = this.lifeAssuredList$;

				if (this.deceasedSciList && this.deceasedSciList.length) {
					const mappedOwners = (x.Owner as number[])?.map(owner => owner.toString());
					const deceasedIds = this.deceasedSciList.map((item) => `${item.value}`);

					newLifeAssuredList$ = this.lifeAssuredList$.pipe(
						map((lifeAssuredList) => {
							return lifeAssuredList.filter((item) => {
								if (deceasedIds.includes(item.value) && !mappedOwners.includes(item.value)) {
									return false;
								}

								return true;
							});
						})
					);
				}

				const initState = {
					header: 'KiwiSaver Details',
					message: `KiwiSaver`,
					lifeAssuredList$: newLifeAssuredList$,
					getOwnerChoices: this.service.getOwnerChoices,
					kp$: this.service.KP$,
					apcrtrp$: this.service.APCRTRP$,
					kft$: this.service.KFT$,
					crtInfo: x,
					saveFn$: this.updateFn$,
					decline$: decline,
				};
				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	viewKiwiSaver(cRTId: number, index?: number) {
		this.isAdding = false;
		this.isAddNew = false;
		this.isEditing = true;
		this.editIndex = index;
		this.KiwiSavers.controls[index].get('isLoading').setValue(true);
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		this.service
			.getCrtById(cRTId)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.editIndex = undefined;
					this.KiwiSavers.controls[index].get('isLoading').setValue(false);
				}),
				take(1)
			)
			.subscribe((x) => {
				const initState = {
					header: 'KiwiSaver Details',
					message: `KiwiSaver`,
					lifeAssuredList$: this.lifeAssuredList$,
					getOwnerChoices: this.service.getOwnerChoices,
					kp$: this.service.KP$,
					apcrtrp$: this.service.APCRTRP$,
					kft$: this.service.KFT$,
					crtInfo: x,
					decline$: decline,
					viewMode: true
				};
				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	deleteKiwiSaver(index: number) {
		this.KiwiSavers.controls[index]?.get('isLoading').setValue(true);
		const cRTId = this.KiwiSavers.controls[index]?.get('cRTId').value;
		const ownerId = this.KiwiSavers.controls[index]?.get('owner').value;
		this.deletingId = cRTId;
		this.service
			.deleteAL(cRTId, AdviceProcessSectionCodes.KiwiSaver)
			.pipe(
				finalize(() => {
					if (+ownerId && this.checkSCIByCustomerId(+ownerId)) {
						this.invalidSciWarning();
					}
					this.selectedFundOwner = undefined;
					this.isAdding = false;
					this.isAddNew = false;
					this.deletingId = undefined;

					this.buildForm();
					this.prepareData();
					this.setLoading(index, false);
				}),
				take(1)
			)
			.subscribe();
	}

	deleteModal(index: number) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteKiwiSaver(index);
			obs.complete();
		});
		const initState = {
			header: 'Delete KiwiSaver',
			message: logMessage.oat.shared.factFind.delete,
			delete$: confirm,
			canDelete: true,
			confirmButton: 'OK',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	checkSCIByCustomerId(id: number) {
		// Check if SCI, based on customerId
		const sci = this.transferedSCI?.filter((x) => +x?.customerId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	invalidSciWarning() {
		this.loggerService.Warning({}, logMessage.oat.shared.warning.invalidSci);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
