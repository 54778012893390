import { Store, StoreConfig } from '@datorama/akita';
import {
	CARSettings,
	CarTemplateSettingsState,
} from './customer-alteration-request.model';
import { Injectable } from '@angular/core';

export interface CARSettingsState extends Store<CARSettings> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'carSettings' })
export class CARSettingsStore extends Store<CARSettings> {
	constructor() {
		super({});
	}

	setCARChangeRequestSettings(
		settings: Partial<CarTemplateSettingsState>
	): void {
		this.update({ changeRequest: settings });
	}

	setCARUpdateStructureSettings(
		settings: Partial<CarTemplateSettingsState>
	): void {
		this.update({ updatedStructure: settings });
	}
}
