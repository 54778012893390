import { strUtil } from '../util/util';

const formatColumnId = (id: string) => {
  const noSpaces = strUtil.removeSpace(id);
  return strUtil.firstLetterToLower(noSpaces).concat('DragColumnId');
}

const formatFieldId = (id: string) => {
  const noSpaces = strUtil.removeSpace(id);
  return strUtil.firstLetterToLower(noSpaces);
}

export const datatableUtil = {
  formatColumnId,
  formatFieldId
}