import { Injectable } from '@angular/core';
import { StructureSoaSecurityQuery } from './structure-soa-security.query';
import { StructureSoaSecurityStore } from './structure-soa-security.store';
import { ApiService } from '../../../../../../../core/base/api.service';
import { Observable, iif } from 'rxjs';
import { StructureSoaSecurity } from './structure-soa-security.model';
import { objectUtil } from '../../../../../../../util/util';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { map, tap, finalize, catchError, mergeMap, first } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class StructureSoaSecurityService {
	totalSecurityValue$ = this.query.selectAll().pipe(
		map((x) => x[0]),
		map((x) =>
			x?.bankWillTake.reduce(
				(prev, curr) => (prev += curr.isTick ? curr.amount : 0),
				0
			)
		)
	);
	securities$ = this.query.select((a) => a[0]);
	isLoading$ = this.query.selectLoading();

	constructor(
		private store: StructureSoaSecurityStore,
		private query: StructureSoaSecurityQuery,
		private api: ApiService
	) {}

	private getLoanStructures(
		parentCRTId: number
	): Observable<StructureSoaSecurity[]> {
		const url = `crt/${AdviceProcessSectionCodes.ROA}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.RoaSecurity}`;
		return this.api.get(url);
	}

	get(
		parentCRTId: number,
		adviceProcessId: number
	): Observable<StructureSoaSecurity[]> {
		this.store.setLoading(true);
		const addSecurity = () => {
			const data = {
				AdviceProcessId: adviceProcessId,
				SectionCode: AdviceProcessSectionCodes.RoaSecurity,
				CRTId: null,
				ParentCRTId: parentCRTId,
			};
			return this.api
				.post('crt', data)
				.pipe(mergeMap(() => this.getLoanStructures(parentCRTId)));
		};
		return this.getLoanStructures(parentCRTId).pipe(
			mergeMap((v) => iif(() => Boolean(v?.length), of(v), addSecurity())),
			map(
				(x) =>
					x?.map(objectUtil.mapPascalCaseToCamelCase) as StructureSoaSecurity[]
			),
			tap((x) => this.store.set(x)),
			finalize(() => this.store.setLoading(false)),
			catchError((err) => of(err))
		);
	}

	getApplication(parentCRTId: number): Observable<StructureSoaSecurity[]> {
		const endpoint = `crt/${AdviceProcessSectionCodes.Application}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.Security}`;
		return this.api
			.get(endpoint)
			.pipe(
				map((x: StructureSoaSecurity[]) =>
					x?.map(objectUtil.mapPascalCaseToCamelCase)
				)
			);
	}

	update(data: any): Observable<any> {
		this.store.setLoading(true);
		return this.api
			.put(`crt/${data.parentCRTId}`, objectUtil.mapCamelCaseToPascalCase(data))
			.pipe(
				map(() => this.store.set([data])),
				finalize(() => this.store.setLoading(false)),
        first(),
			);
	}
}
