import { Injectable } from '@angular/core';
import { DropdownValuesService } from './dropdown-values.service';
import { ApiService } from '../base/api.service';
import { staticConf } from '../config/static-config.service';
import { Dropdown } from '../../modules/dropdown/model/dropdown';
import { map } from 'rxjs/operators';

@Injectable()
export class DropdownService {
  dropdownValuesService: DropdownValuesService;
  constructor(
    private api: ApiService,
    dropdownValueService: DropdownValuesService
  ) {
    this.dropdownValuesService = dropdownValueService;
  }
  getDropdowns() {
    const endpoint = 'dropdown';
    const body: {} = {};
    return (
      this.api.get<Dropdown[]>(endpoint, body).pipe(
        map(x =>
          x?.filter(
            d =>
              !staticConf.excludedDropdownCodes?.some(
                ed => d.DropdownCode === ed
              )
          )
        )
      )
    );
  }
}
