import { Store, StoreConfig } from '@datorama/akita';
import { Widget } from './widget.model';
import { ReferralResponse } from '../../../../shared/models/_general/referenceResponse';
import { Injectable } from "@angular/core";

/**
 * dashboard state store model
 */
export interface DashboardState {
  /**
   * adviser ids chosen as filter for widget data
   */
  adviserFilter: string[];
  /**
   * list of active widgets together with position and size
   */
  widgets: Widget[];
  /**
   * Saving list of active widgets
   */
  isSavingWidgets: boolean;
  /**
   * Selected all widgets
   */
  isSelectedAllWidgets: boolean;
  /**
   * Referrals
   */
  referrals: ReferralResponse[];

  hasSelectedAllAdvisers: boolean;
}

/**
 * create initial state for dashboard store
 */
export function createInitialState(): DashboardState {
  return {
    adviserFilter: [],
    widgets: [],
    isSavingWidgets: false,
    isSelectedAllWidgets: false,
    referrals: [],
    hasSelectedAllAdvisers: false
  };
}

/**
 * dashboard store to store dashboard page related data
 */
@Injectable()
@StoreConfig({
  name: 'dashboard'
})
export class DashboardStore extends Store<DashboardState> {
  constructor() {
    super(createInitialState());
  }

  /**
   * Update the state for the widgets
   *
   * @param widgets List of widgets
   * @return void
   */
  setWidgets(widgets: Widget[]) {
    this.update({ widgets });
  }

  setIsSavingWidgets(isSaving: boolean) {
    this.update({ isSavingWidgets: isSaving });
  }

  setReferrals(ref: ReferralResponse[]) {
    this.update({ referrals: ref });
  }

  setSelectedAllAdvisers(hasSelectedAll: boolean) {
    this.update({ hasSelectedAllAdvisers: hasSelectedAll });
  }
}
