import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { DisabilityQuery } from '../states/risk-analysis/disability/disability.query';
import { LifeQuery } from '../states/risk-analysis/life/life.query';
import { MedicalQuery } from '../states/risk-analysis/medical/medical.query';
import { TpdQuery } from '../states/risk-analysis/tpd/tpd.query';
import { either, isNil, isEmpty } from 'ramda';

@Injectable({
	providedIn: 'root',
})
export class StatementOfAdviceGuard implements CanActivate {
	hasLife$: Observable<boolean> = this.query.hasLife$;
	hasDisability$: Observable<boolean> = this.query.hasDisability$;
	hasCriticalIllness$: Observable<boolean> = this.query.hasCriticalIllness$;
	hasTPD$: Observable<boolean> = this.query.hasTPD$;
	hasMedical$: Observable<boolean> = this.query.hasMedical$;

	constructor(
		private router: Router,
		private query: ClientReviewTemplateQuery,
		private lifeQuery: LifeQuery,
		private disabilityQuery: DisabilityQuery,
		private tpdQuery: TpdQuery,
		private medicalQuery: MedicalQuery
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const code = next.paramMap.get('companyCode');
		const customerType = next.paramMap.get('customerType');
		const clientId = next.paramMap.get('clientId');
		const adviceProcessId = next.paramMap.get('adviceProcessId');
		const returnUrl = `${code}/crt/${customerType}/${clientId}/advice-process/${adviceProcessId}/declaration`;

		return this.query.select().pipe(
			withLatestFrom(
				this.lifeQuery.select(),
				this.disabilityQuery.select(),
				this.tpdQuery.select(),
				this.medicalQuery.select()
			),
			withLatestFrom(
				this.hasCriticalIllness$,
				this.hasDisability$,
				this.hasLife$,
				this.hasMedical$,
				this.hasTPD$
			),
			map(
				([
					[x, life, disability, tpd, medical],
					hasCriticalIllness,
					hasDisability,
					hasLife,
					hasMedical,
					hasTPD,
				]) => {
					const people = x.people?.length > 0;
					const assetsAndLiabilities =
						x?.others?.length > 0 &&
						!!x?.others[0]?.hasPersonalGuarantees &&
						!!x?.others[0]?.hasFinancialResponsibilities &&
						!!x?.others[0]?.hasCurrentWill &&
						((x.mortgages?.length &&
							!x.mortgages?.some(
								(mortgage) => mortgage.mortgageFrequency === null
							)) ||
							x.mortgages?.length === 0);
					const incomeAndbudget =
						(x?.incomeSource?.length > 0 ||
							x?.rentalDetails?.netRentalIncomeList?.length > 0 ||
							x?.otherIncomeDetails?.otherIncome?.length > 0) &&
						!!x?.monthlyExpense?.completed;
					const smokingStatus = x?.bodyMeasures?.filter(
						(s) => !!s.smokingStatus
					);
					const medicalHistory =
						x?.bodyMeasures?.length === smokingStatus?.length;
					const insurance =
						x?.currentInsurances?.some((c) => c) ||
						x?.lrApPageCompleted?.some((lr) => lr === 'FCLNO');
					const fg =
						x?.houseInsurance?.length > 0 ||
						x?.carInsurance?.length > 0 ||
						x?.contentInsurance?.length > 0 ||
						x?.lrApPageCompleted?.some((lr) => lr === 'APFG');

					const shortTermGoals = [...x?.goals?.shortTermGoals]?.filter(
						(x) => !!x.dropdown
					);
					const longTermGoals = [...x?.goals?.longTermGoals]?.filter(
						(x) => !!x.dropdown
					);
					const riskAnalysisGoals =
						shortTermGoals?.length > 0 || longTermGoals?.length > 0;
					const riskAnalysisCriticalIllness =
						x?.criticalIllness?.some((c) => c?.clickedNext) ||
						x?.criticalIllness?.some((c) => c?.cRTId);
					const riskProfile = x?.riskProfile?.event?.some((rp) => {
						if (rp.isDependents) {
							return (
								!!rp?.prematureDeath &&
								!!rp?.sufferFromCriticalIllnessOrTrauma &&
								!!rp?.medicalConditionRequiringHospitalTreatment
							);
						} else {
							return (
								!!rp?.prematureDeath &&
								!!rp?.sufferFromCriticalIllnessOrTrauma &&
								!!rp?.unableToWorkDueToTemporarySickness &&
								!!rp?.medicalConditionRequiringHospitalTreatment &&
								!!rp?.unableToWorkPermanently
							);
						}
					});
					const riskAnalysisLife = life?.ids?.some(
						(id) => +life?.entities[id]?.yearsShortfallCovered > -1
					);
					const riskAnalysisDisability = disability?.ids?.some(
						(id) =>
							(+disability?.entities[id]
								?.whatPercentageOfPreTaxIncomeLikeCovered > 0 &&
								!!disability?.entities[id]
									?.howLongWouldYouWantYourIncomeCoveredFor) ||
							(!either(
								isNil,
								isEmpty
							)(
								disability?.entities[id]
									?.whatPercentageOfPreTaxIncomeLikeCovered
							) &&
								+disability?.entities[id]
									?.whatPercentageOfPreTaxIncomeLikeCovered === 0)
					);
					const riskAnalysisTPD = tpd.ids?.some(
						(id) => +tpd?.entities[id]?.yearsShortfallCovered > -1
					);
					const riskAnalysisMedical = medical?.ids?.some(
						(id) =>
							!!medical?.entities[id]?.relyOnNewZealandPublicHealthSystem &&
							!!medical?.entities[id]?.newZealandPrivateMedicalPolicyBasePlan &&
							!!medical?.entities[id]
								?.medicalDrugTreatmentsApprovedforUseInNZ &&
							!!medical?.entities[id]
								?.policyProvidingAccessToSpecialistsAndTests &&
							!!medical?.entities[id]
								?.policyProvidingAccessToOpticalAndDentalTreatment &&
							!!medical?.entities[id]
								?.policyProvidingAccessToGPVisitsAndPrescriptions &&
							((medical?.entities[id]?.relyOnNewZealandPublicHealthSystem ===
								'No' &&
								!!medical?.entities[id]
									?.whatLevelOfExcessAreYouComfortableSelfInsuring) ||
								medical?.entities[id]?.relyOnNewZealandPublicHealthSystem ===
									'Yes')
					);

					const factFind =
						people &&
						assetsAndLiabilities &&
						incomeAndbudget &&
						medicalHistory &&
						insurance &&
						fg;
					const riskAnalysis =
						riskAnalysisGoals &&
						(riskAnalysisLife || !hasLife) &&
						(riskAnalysisDisability || !hasDisability) &&
						(riskAnalysisTPD || !hasTPD) &&
						(riskAnalysisCriticalIllness || !hasCriticalIllness) &&
						(riskAnalysisMedical || !hasMedical) &&
						riskProfile;

					const isAdviceProcessEnded =
						x?.adviceProcess?.status > 2 && x?.adviceProcess?.status < 5;

					if (!(factFind && riskAnalysis && !isAdviceProcessEnded)) {
						this.router.navigateByUrl(returnUrl);
					}

					return true;
				}
			)
		);
	}
}
