import { DropdownValue } from './dropdown-value.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from "@angular/core";

export interface DropdownValueState extends EntityState<DropdownValue> { }

@Injectable()
@StoreConfig({
    name: 'DropdownValue',
    idKey: 'DropdownValueID'
})
export class DropdownValueStore extends EntityStore<DropdownValueState, DropdownValue> {
    constructor() {
        super({
            updatedCodes: []
        });
    }

    setUpdatedCodes(codes: any[]) {
		this.update({ updatedCodes: codes });
	}
}
