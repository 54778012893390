import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../../core/config/config.service';
import { RouteService } from '../../../core/config/route.service';
import { Subscription, of } from 'rxjs';
import { UserQuery } from '../../../domain/user/user.query';
import { map, filter, startWith } from 'rxjs/operators';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment as env } from '@environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	constructor(
		private userQuery: UserQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private configService: ConfigService,
		private routeService: RouteService,
		private authService: AuthenticationService,
		private router: Router,
	) {}
	isTapLevelView = false;
	popoverIsShown = false;
	name$ = this.userQuery.userInfo$.pipe(
		map((x) => x.FirstName + ' ' + x.LastName)
	);
	googleImageUrl$ = of(
		this.authService.user ? this.authService.user.picture : ''
	);
	// isTapLevelUser: boolean;
	logoUrl = '';
	tapLevelLogoUrl = `${env.defaultImgUrl}/logo.png`;
	profilePic = '';
	logoLink = '';
	tapLevelLogoLink = '';
	hasPopover = false;

	showAnnouncementHeader$ = this.router.events.pipe(
		startWith(null),
		filter((event) => event instanceof NavigationEnd || event === null),
		map(() => this.router.url.startsWith('/admin/announcements')),
	);

	subs: Subscription[] = [];
	ngOnInit() {
		const sub2 = this.userQuery.profilePic$.subscribe(
			(x) => (this.profilePic = x)
		);
		const sub3 = this.configService.IsViewTapLevel.subscribe((x) => {
			this.isTapLevelView = x;
		});
		const sub4 = this.businessConfigQuery.businessConfig$.subscribe((y) => {
			this.logoUrl = y ? y.Logo : '';
		});
		const sub5 = this.routeService.RouteChange$.subscribe((x) => {
			this.logoLink = x.business;
		});
		this.tapLevelLogoLink = this.routeService.adminBusinessList;
		const sub6 = this.userQuery.hasPopover$.subscribe(
			(x) => (this.hasPopover = x)
		);
		this.subs.push(sub2, sub3, sub4, sub5, sub6);
	}
	ngOnDestroy(): void {
		this.subs?.forEach((x) => x.unsubscribe());
		this.subs = [];
	}
}
