import { objectUtil, util } from 'src/app/util/util';
import {
	ExistingPolicyStructureState,
	LifeAssuredListState,
} from './existing-policy-structure.model';
import { AdviceProcessSectionCodes } from '@shared/models/advice-process/advice-process.model';

export class ExistingPolicyStructureMapper {
	/** Mapping for view */
	static mapToView(data: ExistingPolicyStructureState) {
		if (!data) {
			return data;
		}
		const filterPolicyOwners = (po: string) => {
			const obj = util.tryParseJson(po);
			let a = [];
			if (typeof obj === 'object') {
				a = obj?.map((x) => +x);
			} else {
				a.push(+obj);
			}
			return a?.filter(Boolean);
		};
		return {
			policyOwner:
				data.policyOwner && typeof data.policyOwner === 'string'
					? filterPolicyOwners(data.policyOwner)
					: [],
			lifeAssured: (!!data.lifeAssured
				? data.lifeAssured
				: !!data.lifeAssured
				? typeof data.lifeAssured === 'string'
					? ((JSON.parse(data.lifeAssured) as LifeAssuredListState[])?.map(
							objectUtil.mapPascalCaseToCamelCase
					  ) as LifeAssuredListState[])
					: data.lifeAssured
				: []
			)?.map((x) => {
				return {
					...x,
					// notes: x?.notes,
					// premium: x?.premium,
					// premiumValue: x?.premiumValue,
					// priority: +x?.priority,
					lifeAssured: +x.lifeAssured,
					product: x.product || '',
					benefit: +(x.benefit || 0),
					outcome: x.outcome || '',
				};
			}),
			totalPremium: data?.totalPremium || 0,
			policyFee: data?.policyFee || 0,
			provider: data?.provider || '',
			premiumFrequency: data?.premiumFrequency || '',
			additionalNotes: data?.additionalNotes || '',
			cRTId: data?.cRTId,
			parentCRTId: data?.parentCRTId,
			linkedCRTId: data?.linkedCRTId,
			adviceProcessId: data?.adviceProcessId,
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(data): ExistingPolicyStructureState {
		return {
			provider: data.provider || '',
			policyOwner: JSON.stringify(
				[data?.policyOwner]?.filter(Boolean)?.map((x) => x.toString())
			),
			additionalNotes: data.additionalNotes || null,
			premiumFrequency: data.premiumFrequency,
			policyFee: +(data?.policyFee || 0),
			totalPremium: +(data?.totalPremium || 0),
			lifeAssured: data?.lifeAssured?.map((x) => {
				return {
					// notes: x?.notes,
					// premium: x?.premium,
					// premiumValue: x?.premiumValue,
					// priority: +x?.priority,
					lifeAssured: +x.lifeAssured,
					product: x.product,
					benefit: +x.benefit,
					outcome: x.outcome,
				};
			}),
			cRTId: data?.cRTId,
			sectionCode: AdviceProcessSectionCodes.ExistingPolicyStructure,
			adviceProcessId: +data?.adviceProcessId,
			parentCRTId: +data?.parentCRTId,
			linkedCRTId: +data?.linkedCRTId,
			status: 1,
		};
	}
}
