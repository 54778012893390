<div class="clearfix p-3">
	<div class="d-flex justify-content-end mb-3 btns">
		<div class="form-check form-check-inline">
			<input
				class="form-check-input"
				type="checkbox"
				id="allCheckbox"
				(click)="showAll()"
				[attr.disabled]="isLoading || null"
			/>
			<label class="form-check-label" for="allCheckbox">
				Show all
			</label>
		</div>

		<ng-container *ngIf="!isEditing; else showEdit">
			<button
				type="button"
				class="btn tap-btn--default btn-sm px-4"
				(click)="edit()"
				id="edit"
			>
				Edit
			</button>
		</ng-container>
		<ng-template #showEdit>
			<button
				type="button"
				class="btn tap-btn--default btn-sm px-4"
				(click)="save()"
				id="save"
				[attr.disabled]="isLoading || null"
			>
				Save Changes <i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
			</button>

			<button
				type="button"
				class="btn tap-btn--default btn-sm px-4"
				(click)="cancelEdit()"
				id="cancel"
				[attr.disabled]="isLoading || null"
			>
				Cancel
			</button>
		</ng-template>
	</div>
	<div class="col">
		<form [formGroup]="form">
			<app-table-input
				formControlName="AdviserIdsAssigned"
				[staff]="staff"
				[choices]="staffChoices"
				[showAllStaffs]="showAllStaffs"
				[isEditing]="isEditing"
				[isLoading]="isLoading"
			>
				<div class="row">
					<div class="col-lg-3 col-md-4">
						<label for="selectAllUsers" class="font-weight-bold">Users</label>
					</div>
					<div class="col-lg-3 col-md-4">
						<span class="font-weight-bold">Security Group</span>
					</div>
					<div class="col-lg-3 col-md-4">
						<span class="font-weight-bold">User Status</span>
					</div>
				</div>
			</app-table-input>
		</form>
	</div>
</div>
