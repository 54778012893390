import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PropertyPurchaseDetailsState } from './property-purchase.model';
export interface PropertyPurchaseState extends EntityState<PropertyPurchaseDetailsState> {
	propertyAddresses: PropertyPurchaseDetailsState[];
	totalPurchasePrice: number;
}

@Injectable()
@StoreConfig({ name: 'propertyPurchase', idKey: 'cRTId' })
export class PropertyPurchaseStore extends EntityStore<PropertyPurchaseState, PropertyPurchaseDetailsState> {
	constructor() {
		super({
			propertyAddresses: [],
			totalPurchasePrice: 0
		});
	}

	setTotalPurchasePrice(total: number) {
		this.update({totalPurchasePrice: total});
	}
}
