import { MortgageProviderCommissionState } from 'src/app/modules/crm/crt-page/crt-mortgage/provider-comission/state/provider-commission.model';
import { getPerFrequency } from 'src/app/modules/crm/crt-page/_shared/calculations/monthly-conversion';
import { getContentWithMergeTags } from 'src/app/shared/converter/content-merge-tags';
import { numUtil } from 'src/app/util/util';
import { MergeTagState } from '../../merge-tags.model';
import {
	lsSoaMergeTag,
	lsSoaMetaKeys,
	mortgageLenderMT,
	roaWordingsMetaKey,
	appLoanSplitMetaKey,
	mortgageLoanSplitMT
} from './structure-soa.merge-tag';

export class StructureSoaMapper {
	public static formatCurrency(value) {
		return numUtil.isNumber(+value) && numUtil.isValid(+value)
			? numUtil.formatToCurrency(+value)
			: '$0.00';
	}

	public static roaWordingsMergeTags(mergeTags) {
		return mergeTags?.map((x) => {
			if (Object.values(roaWordingsMetaKey).includes(x.metaKey)) {
				return {
					...x,
					value: getContentWithMergeTags(x?.value, mergeTags) || '',
				};
			}
			return x;
		});
	}

	public static addLsSoaMergeTags(mergeTags) {
		const loanSplits =
			mergeTags?.find(
				(mt) => mt?.metaKey === lsSoaMetaKeys.loanRepaymentAmount.toString()
			)?.value || [];

		const frequencies =
			mergeTags?.find(
				(mt) => mt?.metaKey === lsSoaMetaKeys.loanRepaymentFrequency.toString()
			)?.value || [];

		const lsSoaMergeTags = lsSoaMergeTag?.map((x) => {
			if (x?.metaKey === lsSoaMetaKeys.lsRepaymentAmtFreq) {
				const finalValue = loanSplits?.reduce((a, c, i) => {
					const data = `${c || '$0'} ${getPerFrequency(frequencies[i])}`;
					return [...a, data];
				}, []);
				return {
					...x,
					value: finalValue || [],
				};
			}
			if (x?.metaKey === lsSoaMetaKeys.loanSplitIndex) {
				const value = loanSplits?.reduce((a, c, i) => {
					return [...a, `Loan ${i + 1}`];
				}, []);
				return {
					...x,
					value,
				};
			}
			return x;
		});

		return [...mergeTags, ...lsSoaMergeTags];
	}

	public static addMortgageLenderMergeTags(
		mergeTags: MergeTagState[],
		approvedlender: string,
		providerCommission: MortgageProviderCommissionState[]
	) {
		const providerDetails = providerCommission?.find(
			(x) => x?.provider === approvedlender && x?.isTicked
		);

		const providerMT = mortgageLenderMT?.map((x) => {
			if (x?.metaKey === lsSoaMetaKeys.mortgageMaxUpfront) {
				return {
					...x,
					value: providerDetails?.maxUpfront || '',
				};
			}
			if (x?.metaKey === lsSoaMetaKeys.mortgageMaxRenewal) {
				return {
					...x,
					value: providerDetails?.maxRenewal || '',
				};
			}
			if (x?.metaKey === lsSoaMetaKeys.mortgageFee) {
				return {
					...x,
					value: providerDetails?.fee || '',
				};
			}
			return x;
		});

		return [...mergeTags, ...providerMT];
	}

	public static convertLoanInterestOnlyMergeTags(
		mergeTags: MergeTagState[]
	) {
		const convertMonthsToYears = (months) => {
			if (months === 6) {
				return '6 Months';
			}

			if (months === 12) {
				return '1 Year';
			}
			
			return `${months / 12} Years`;
		};

		const loanSplitMt = mergeTags.find((mt) => mt.metaKey === appLoanSplitMetaKey.interestOnlyPeriod);
		const fMt = mergeTags.filter((mt) => mt.metaKey !== appLoanSplitMetaKey.interestOnlyPeriod);

		const interestOnlyPeriodMT = loanSplitMt ? mortgageLoanSplitMT?.map((x) => {
			if (x?.metaKey === appLoanSplitMetaKey.interestOnlyPeriod) {
				return {
					...x,
					value: loanSplitMt.value.map((value) => convertMonthsToYears(+value))
				};
			}
			return x;
		}) : [];

		return [...fMt, ...interestOnlyPeriodMT];
	}
}
