import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientProfileAddComponent } from './client-profile-add/client-profile-add.component';
import { ClientProfileAddResolver } from './client-profile-add/client-profile-add.resolver';
import { ClientProfileEditComponent } from './client-profile-edit/client-profile-edit.component';
import { ClientProfileEditResolver } from './client-profile-edit/client-profile-edit.resolver';

const routes: Routes = [
  {
    path: 'add',
    data: {
      breadcrumb: 'Client: Add New Person',
    },
    component: ClientProfileAddComponent,
    resolve: {
      data: ClientProfileAddResolver
    }
  },
  {
    path: ':customerId',
    data: {
      breadcrumb: 'Client',
    },
    component: ClientProfileEditComponent,
    resolve: {
      data: ClientProfileEditResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientProfileRoutingModule { }
