import * as R from 'ramda';

import { DocumentSettingsTypes } from '../../state/mortgage-settings.model';
import { DefaultFileNames } from '../../../../shared/models/documents/document.model';

export class ReviewApplicationSettingsMapper {
	public static mapDocumentUpload(document, template) {
		if (
			template === 0 ||
			isNaN(template) ||
			R.either(R.isNil, R.isEmpty)(template)
		) {
			return {
				document,
				referenceId: 0,
				fileName: DefaultFileNames.ReviewApplication,
				type: DocumentSettingsTypes.ReviewApplication,
			};
		}
		return {
			document,
			documentId: +template,
		};
	}
}
