import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { convertUtil, numUtil, util } from 'src/app/util/util';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggerService } from '../logger/logger.service';
import { LocalService } from '../services/local.service';
import { getCompanyCode } from './../../domain/business-config/business-config.service';
import { allowed404Urls } from './allowedUrls';
import { environment as env } from '@environment';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	// Refresh Token Subject tracks the current token, or is null if no token is currently
	// available (e.g. refresh pending).
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	);

	constructor(
		private authService: AuthenticationService,
		private loggerService: LoggerService,
		private localService: LocalService,
		private router: Router
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<any> {
		return next.handle(request).pipe(
			catchError((err) => {
				const urlSplit = request.url?.split('/');
				if (
					request.url?.includes('refreshtoken') ||
					request.url?.includes('login')
				) {
					// We do another check to see if refresh token failed
					// In this case we want to logout user and to redirect it to login page

					if (request.url?.includes('refreshtoken')) {
						this.log(err);
						return this.authService.logout();
					}

					if (
						request.url.endsWith('login') &&
						err.status === 401 &&
						!this.authService.isLoggedIn
					) {
						this.localService.remove('token');
						this.localService.remove('expires_in');
						// window.location.href = '/login/unauthorized';
						this.router.navigateByUrl('/login/unauthorized');
					}

					return this.throwError(err);
				}

				// Do not show error pop up when endpoint returns 404
				// For only in the list of Allowed URLs
				// Which happens where BE returns 404 when there are no data yet
				if (err.status === 404) {
					if (allowed404Urls?.some((x) => request.url?.indexOf(x) > -1) ) {
						return this.throwError(err);
					} else if (request.method === 'GET' && request.url?.includes('crt')) {
						// Bypass error message for GET of CRT by ID endpoints only
						const crtUrl = request.url?.split('crt/');
						if (crtUrl?.length > 0 && numUtil.isValid(+crtUrl[1])) {
							return this.throwError(err);
						}
					} else if (
						request.method === 'GET' &&
						urlSplit?.[5] === 'contacts' &&
						request.url?.split('/')?.length === 7
					) {
						// For deleted CRM Profile > Client & Business
						return this.throwError(err);
					}
				}

				if (request.url?.includes(env.aiQuoteApi)) {
					return this.throwError(err);
				}

				// If error status is different than 401 we want to skip refresh token
				// So we check that and throw the error if it's the case
				if (err.status !== 401) {
					if (err.status === 406 && request.url?.includes('documents')) {
						return this.throwError(err);
					}
					if (err.status === 406 && request.url?.includes('contacts/transfer')) {
						const errMsg = err?.error ? Object.values(JSON.parse(err?.error) || []) : [];
						this.loggerService.Log(err, errMsg?.join(','));
						return throwError(errMsg);
					}
					if (err.status === 406 && request?.body?.SectionCode === 'FMS') {
						return this.throwError(err);
					}
					if (
						err.status === 406 &&
						err?.error?.NotesID?.includes('does not exist')
					) {
						this.loggerService.Warning(err, err?.error?.NotesID);
						return this.throwError(err);
					}
					if (err.status === 408 && request.url?.includes('transfer')) {
						return this.throwError(err);
					}

					if (err.status === 400 && request?.url?.includes('linked-contacts')) {
						return this.throwError(err);
					}

					if (
						err.status === 400 &&
						request.method === 'DELETE' &&
						request?.url?.includes('/staff/') &&
						request?.url?.includes('/documents/')
					) {
						// TAPNZ-13298
						return this.throwError(err);
					}
					// TAP1-1665 remove logging for contacts if duplicate email
					if(
						err.status === 406 && request?.url?.includes('/v2/contacts') 
					){
						return this.throwError(err);
					}

					if (
						!request.url?.includes('/emails/sendfailed') &&
						!err?.error?.NotesID?.includes('does not exist')
					) {
						this.log(err);
					}
					return this.throwError(err);
				}

				// If 401 with response body boolean false, it will redirect to login page
				if (err.status === 401 && !err.error) {
					const user = this.authService.user;
					if (user && !user.IsTL && user.CompanyCode) {
						const companyCode = user.CompanyCode;
						this.localService.remove('token');
						this.localService.remove('expires_in');
						window.location.href = '/' + companyCode;
						// this.router.navigateByUrl(`/${companyCode}`);
					} else {
						this.localService.clear();
						window.location.href = '/';
						// this.router.navigateByUrl('/login');
					}
					return this.throwError(err);
				}

				if (this.refreshTokenInProgress) {
					// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
					// – which means the new token is ready and we can retry the request again
					return this.refreshTokenSubject.pipe(
						filter((result) => result !== null),
						take(1),
						switchMap(() => next.handle(this.addAuthenticationToken(request)))
					);
				} else {
					this.refreshTokenInProgress = true;

					// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
					this.refreshTokenSubject.next(null);

					// Call auth.refreshToken(this is an Observable that will be returned)
					return this.authService.refreshToken().pipe(
						switchMap((token: any) => {
							// When the call to refreshToken completes we reset the refreshTokenInProgress to false
							// for the next time the token needs to be refreshed
							this.refreshTokenInProgress = false;
							this.refreshTokenSubject.next(token);

							return next.handle(this.addAuthenticationToken(request));
						}),
						catchError((error: any) => {
							this.refreshTokenInProgress = false;

							return this.authService.logout();
							// return throwError(err.error && err.error.Message ? err.error.Message : err.error);
						})
					);
				}
			})
		);
	}

	companyCode() {
		let route = this.router.routerState.root;
		while (route.firstChild) {
			route = route.firstChild;
		}
		return route.snapshot.params && route.snapshot.params.companyCode
			? route.snapshot.params.companyCode
			: '';
	}

	addAuthenticationToken(request) {
		// Get access token from Local Storage
		const accessToken = this.authService.user.access_token;

		// If access token is null this means that user is not logged in
		// And we return the original request
		if (!accessToken) {
			return request;
		}

		const code =
			this.companyCode() || (this.localService.getValue('code') ?? '');

		// We clone the request, because the original request is immutable
		return request.clone({
			setHeaders: {
				Authorization: `${this.authService.user.token_type} ${this.authService.user.access_token}`,
				CompanyCode:
					!!getCompanyCode() && getCompanyCode() !== 'login'
						? getCompanyCode()
						: code,
			},
		});
	}

	throwError(err) {
		return throwError(
			err.error && err.error.Message ? err.error.Message : err.error
		);
	}

	log(err) {
		if (err instanceof HttpErrorResponse) {
			if (err.error instanceof Blob) {
				convertUtil.ConvertBlobToText(err.error).then(value => {
					const message = util.tryParseJson(value);
					if (!!message) {
						return this.loggerService.Log(err, message);
					}
				})
			} else if (typeof err?.error === 'string') {
				return this.loggerService.Log(err, err.error);
			} else if (err?.error?.Message) {
				return this.loggerService.Log(err, err.error.Message);
			} else if (typeof err?.error === 'object' && err.error !== null) {
				return this.loggerService.Log(err, Object.values(err.error)?.join(', '));
			} else {
				return this.loggerService.Log(err, err.statusText);
			}
		} else {
			return this.loggerService.Log(err, err);
		}
	}
}
