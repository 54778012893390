import { FundingRequiredLoan } from './loan-repaid.model';
import { util } from '../../../../../../../../../../util/util'
import { AdviceProcessSectionCodes } from '../../../../../../../../../../shared/models/advice-process/advice-process.model'
export class FundingRequiredLoanMapper {
  public static mapToView(data: FundingRequiredLoan) {
    return {
      ...data
    }
  }

	public static mapToUpsert = (data: FundingRequiredLoan) => {
		return {
			cRTId: data?.cRTId,
			adviceProcessId: data?.adviceProcessId,
			parentCRTId: data?.parentCrtId,
			isTicked: data?.isTicked,
			liability: data?.liability,
			loanLimit: +(data?.loanLimit || 0),
			loanBalance: +(data?.loanBalance || 0),
			interestRate: +(data?.interestRate || 0),
			borrower: data?.borrower,
			lender: data?.lender,
			loanRepayment: +(data?.loanRepayment || 0),
			mortgageFrequency: data?.mortgageFrequency,
			paidInFull: data?.paidInFull,
			loanType: data?.loanType,
			security: data?.security,
			sourceType: data?.sourceType,
			sectionCode: AdviceProcessSectionCodes.FundingRequiredLoan,
		};
	}

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }

  public static mapToDetails = (model, isUpdate = false) => {
		return {
			CRTId: model?.cRTId ?? null,
			AdviceProcessId: model.adviceProcessId,
      LinkedCRTId: model.linkedCRTId,
      ParentCRTId: model.parentCRTId,
      IsTicked: model.isTicked,
      Liability: model.liability,
      LoanLimit: model.loanLimit,
      LoanBalance: model.loanBalance,
      InterestRate: model.interestRate,
      Borrower: model.borrower ? typeof model.borrower === 'object' ? JSON.stringify(model.borrower) : model.borrower : null,
      Lender: model.lender,
      LoanRepayment: model.loanRepayment,
      MortgageFrequency: model.mortgageFrequency,
      PaidInFull: model.paidInFull,
      LoanType: model.loanType,
      Security: model.security,
      SourceType: model.sourceType || null,
			SectionCode: AdviceProcessSectionCodes.FundingRequiredLoan
		};
	};
}
