import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observer, Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../../../shared/modal/confirm-modal/confirm-modal.component';

@Component({
	selector: 'app-import-progress',
	templateUrl: './import-progress.component.html',
	styleUrls: ['./import-progress.component.scss'],
})
export class ImportProgressComponent implements OnInit {
	@Input() percent: number;
	@Input() uploadCount: number;
	@Input() rowCount: number;

	@Input() successCount: number;
	@Input() failCount: number;
	@Input() startTime: string;
	@Input() progress: any;

	@Output() cancelImportEvent = new EventEmitter();

	cancelImportBtn = true;
	constructor(private modalService: BsModalService) {}

	ngOnInit() {}

	cancelImport() {
		// Show modal
		const confirm = new Observable((obs: Observer<any>) => {
			// If true
			this.cancelImportEvent.emit();
			obs.complete();
		});

		const initState = {
			header: 'Cancel import',
			message: `Are you sure you want to cancel import?`,
			confirm$: confirm,
		};

		this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		this.cancelImportBtn = false;
	}
}
