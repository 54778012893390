<div class="calculator-chart-container">
	<form [formGroup]="form" class="mb-20">
		<div class="row align-items-center justify-content-center">
			<div class="col-lg-12">
				<h1 class="kiwisaver-header mb-20">How much could you save?</h1>
			</div>
		</div>

		<div
			class="row mb-20 align-items-center justify-content-start justify-content-lg-center"
		>
			<div class="col-lg-12 text-lg-center">
				<span>I was born in</span>
				<span class="crt-custom-dropdown mx-2">
					<select
						#selectMonth
						(change)="onChange()"
						formControlName="birthMonth"
						[attr.disabled]="!disabled ? null : ''"
						class="form-control crt-form-control bg-transparent mr-1 date-input-month px-2"
					>
						<option value="01" selected>January</option>
						<option value="02">February</option>
						<option value="03">March</option>
						<option value="04">April</option>
						<option value="05">May</option>
						<option value="06">June</option>
						<option value="07">July</option>
						<option value="08">August</option>
						<option value="09">September</option>
						<option value="10">October</option>
						<option value="11">November</option>
						<option value="12">December</option>
					</select>
				</span>
				<span class="crt-custom-dropdown mx-2">
					<select
						#selectYear
						formControlName="birthYear"
						(change)="onChange()"
						[value]="1970"
						[attr.disabled]="!disabled ? null : ''"
						class="form-control crt-form-control bg-transparent date-input-year crt-custom-dropdown px-2"
					>
						<option *ngFor="let year of years$ | async" [value]="year" class="">
							{{ year }}
						</option>
					</select>
				</span>
			</div>
		</div>

		<div
			class="row mb-20 align-items-center justify-content-start justify-content-lg-center"
		>
			<div class="col-lg-12 text-lg-center">
				<span>My annual income is</span>
				<span class="dollar-icon d-inline-block mx-2">
					<input
						#annualIncome
						id="annualIncome"
						class="form-control crt-form-control bg-transparent currency-input d-inline"
						type="text"
						formControlName="annualIncome"
						(focusout)="onChange()"
						currencyMask
						appCurrencyMaskExt
						[attr.disabled]="!disabled ? null : ''"
						[tooltip]="isEllipsisActive(annualIncome) ? getAnnualIncome() : '' "
					/>
					<i>$</i>
				</span>
				<span>and my current KiwiSaver balance is</span>
				<span class="dollar-icon d-inline-block mx-2">
					<input
						#kiwisaverBalance
						id="kiwisaverBalance"
						class="form-control crt-form-control bg-transparent currency-input d-inline"
						type="text"
						formControlName="kiwisaverBalance"
						(focusout)="onChange()"
						currencyMask
						appCurrencyMaskExt
						[attr.disabled]="!disabled ? null : ''"
						[tooltip]="isEllipsisActive(kiwisaverBalance) ? getKiwisaverBalance() : '' "
					/>
					<i>$</i>
				</span>
			</div>
		</div>

		<div
			class="row mb-20 align-items-center justify-content-start justify-content-lg-center"
		>
			<div class="col-lg-12 text-lg-center">
				<span>I will contribute</span>
				<span class="crt-custom-dropdown mx-2">
					<select
						id="employeeContribution"
						class="form-control crt-form-control bg-transparent px-2 percentage-input"
						formControlName="employeeContribution"
						(change)="onChange()"
						[attr.disabled]="!disabled ? null : ''"
					>
						<option value="0.03">3%</option>
						<option value="0.04">4%</option>
						<option value="0.06">6%</option>
						<option value="0.08">8%</option>
						<option value="0.1">10%</option>
					</select>
				</span>
				<span>of my income and my employer will contribute</span>
				<span class="crt-custom-dropdown mx-2">
					<select
						id="employerContribution"
						class="form-control crt-form-control bg-transparent px-2 percentage-input"
						formControlName="employerContribution"
						(change)="onChange()"
						[attr.disabled]="!disabled ? null : ''"
					>
						<option value="0.03">3%</option>
						<option value="0.04">4%</option>
						<option value="0.06">6%</option>
						<option value="0.08">8%</option>
						<option value="0.1">10%</option>
					</select>
				</span>
				<span>on top.</span>
			</div>
		</div>
		<div
			class="row mb-20 align-items-center justify-content-start justify-content-lg-center"
		>
			<div class="col-lg-12 text-lg-center">
				<span>Please show my results</span>
				<span class="crt-custom-dropdown mx-2">
					<select
						id="inflation"
						class="form-control crt-form-control bg-transparent px-2 select-text"
						formControlName="inflation"
						(change)="onChange()"
						[attr.disabled]="!disabled ? null : ''"
					>
						<option value="0">without</option>
						<option value="1">with</option>
					</select>
				</span>
				<span>inflation.</span>
			</div>
		</div>
	</form>

	<div class="row pb-20 chart-container">
		<app-vertical-bar-chart
			#verticalBar
			class="col"
			[data]="data"
			[otherOptions]="chartOptions"
			[onDraw]="onDraw"
		></app-vertical-bar-chart>
	</div>

	<div class="row pb-20 chart-results-container" *ngIf="showAccordionResults">
		<app-calculator-results [InvestmentReturnData]="investmentReturnData">
		</app-calculator-results>
	</div>
</div>
