import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import * as R from 'ramda';
import {
	BehaviorSubject,
	combineLatest,
	EMPTY,
	from,
	Observable,
	throwError,
} from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../../../../core/base/api.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { BLStaffsQuery } from '../../../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '../../../../../../domain/user/user.query';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../../../util/moment.util';
import { objectUtil } from '../../../../../../util/util';

import { CrtMortgageQuery } from '../crt-mortgage.query';

import { CRTNoteState } from '../../../../../../shared/models/client-review-template/note/crt-note.model';
import { CrtMortgageNoteQuery } from './crt-mortgage-note.query';
import { CrtMortgageNoteStore } from './crt-mortgage-note.store';

@Injectable()
export class CrtMortgageNoteService {
	activeType$ = this.crtMortgageNoteQuery.activeType$;
	crtNotesFactFind$ = combineLatest([
		this.crtMortgageNoteQuery.crtNotesFactFind$,
		this.crtMortgageNoteQuery.activeType$,
	]).pipe(
		map(([notes, type]) =>
			!!type ? notes?.filter((x) => x.type === type) : notes
		)
	);
	crtTypes$ = this.crtMortgageNoteQuery.crtTypes$;

	public activityAdvisers$ = this.blStaffsQuery.availableStaffs$.pipe(
		map((x) =>
			R.map(
				(y) =>
					ViewDisplayValue.Map(
						y.StaffID.toString(),
						`${y.FirstName} ${y.LastName}`
					),
				x
			)
		)
	);

	public adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	activityAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.query.primaryClient$, this.activityAdvisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, primaryClient, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								x.value === (primaryClient ? primaryClient.adviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display.localeCompare(b.display))
				: all
		)
	);

	private isNotesCollapse$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected crtMortgageNoteStore: CrtMortgageNoteStore,
		protected crtMortgageNoteQuery: CrtMortgageNoteQuery,
		private userQuery: UserQuery,
		private blStaffsQuery: BLStaffsQuery
	) {
		// super(dropdownValueQuery, store, query, customerService, businessService);
	}

	clearData() {
		applyTransaction(() => {
			this.crtMortgageNoteStore.reset();
		});
	}

	getNotes(
		adviceProcessId: number,
		sections: string[]
	): Observable<CRTNoteState[]> {
		this.crtMortgageNoteStore.setLoading(true);
		return from(sections).pipe(
			mergeMap((section) =>
				this.api.get<CRTNoteState[]>(`crt/${adviceProcessId}/notes/${section}`)
			),
			map(objectUtil.mapPascalCaseToCamelCase),
			map((x) => R.valuesIn(x)),
			map((x) => R.flatten(x)),
			map((x) => R.filter((y) => !R.isNil(y), x) as CRTNoteState[]),
			tap((x) =>
				applyTransaction(() => {
					this.crtMortgageNoteStore.upsertMany(x);
					this.crtMortgageNoteStore.setLoading(false);
					this.crtMortgageNoteStore.setHasCache(true);
				})
			),
			catchError(() => EMPTY)
		);
	}

	addNote(notes: string, type: string, adviceProcessId: number) {
		return this.api
			.post3<number>('notes/crt', {
				ReferenceId: adviceProcessId,
				Notes: notes,
				Type: type,
			})
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						this.crtMortgageNoteStore.add({
							referenceId: adviceProcessId,
							cRTNotesId: +x,
							notes,
							type,
							createDateTime: MomentUtil.formatToServerDatetimeSecond(
								MomentUtil.createMomentNz()
							),
							createdByStaffId: this.userQuery.getValue().StaffID,
							staffName: `${this.userQuery.getValue().FirstName} ${
								this.userQuery.getValue().LastName
							}`,
							isActive: 1,
							isPinned: null,
						} as CRTNoteState);
					})
				),
				catchError(() => EMPTY)
			);
	}

	updateNote(note: CRTNoteState) {
		return this.api
			.put<number>(`notes/crt/${note?.cRTNotesId}`, {
				CRTNotesId: note.cRTNotesId,
				ReferenceId: this.query.getValue().adviceProcessId,
				Notes: note.notes,
				Type: note?.type,
			})
			.pipe(
				tap(() =>
					applyTransaction(() => {
						this.crtMortgageNoteStore.update(note.cRTNotesId, {
							...this.crtMortgageNoteQuery.getEntity(note.cRTNotesId),
							notes: note?.notes,
						} as CRTNoteState);
					})
				),
				catchError(() => EMPTY)
			);
	}

	deleteNote(id: number) {
		return this.api.delete(`notes/${id}/crt`).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.crtMortgageNoteStore.remove(id);
				})
			),
			catchError(() => EMPTY)
		);
	}

	setActiveType(type?: string) {
		this.crtMortgageNoteStore.setActiveType(type ?? '');
	}

	export(req) {
		return this.api
			.postFileDownload(`export/crt-notes`, req)
			.pipe(catchError((x) => throwError(x)));
	}

	setNoteIsCollapsed(isCollapsed: boolean) {
		this.isNotesCollapse$.next(isCollapsed);
	}

	getIsNotesCollapse(): Observable<boolean> {
		return this.isNotesCollapse$.asObservable();
	}
}
