import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface KOATProvider {
	cRTId: null;
	adviceProcessId: number;
	sectionCode: string;
	parentCRTId: number;
	status: number;
	isApplied: boolean;
	providersApplied: string; //Not in the requirement; future-proofing only, needed for tracking applied providers
}

export interface CrtKiwiSaverFactFind {
	currentPage: string;
	cRTId: number;
	adviceProcessId: number;
	sectionCode: 'KOATFF' | 'KLOATFF';
	status: number;
	createDateTime: string;
	createdByStaffId: number;
	createdByStaffLevel: number;
	modifiedDateTime: string;
	modifiedByStaffId: number;
	settings?: {
		WhyKiwiSaver: boolean;
		HowKiwiSaverWorks: boolean;
		InvestmentStrategy: boolean;
	};
	provider: KOATProvider;
}

export const CrtKiwisaverFactFindStoreName = 'CrtKiwisaver:FactFind';
@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: CrtKiwisaverFactFindStoreName,
	idKey: 'cRTId',
	resettable: false,
})
export class CrtKiwisaverFactFindStore extends Store<CrtKiwiSaverFactFind> {
	constructor() {
		super({});
	}

	setSettings(settings: any): void {
		this.update((state) => {
			return {
				...state,
				settings,
			};
		});
	}

	setData(data: CrtKiwiSaverFactFind): void {
		this.update((state) => {
			return {
				...state,
				...data,
			};
		});
		this.setHasCache(true);
	}

	updateCurrentPage(currentPage: string): void {
		this.update((state) => {
			return {
				...state,
				...{ currentPage },
			};
		});
	}

	setProvider(provider: KOATProvider | null): void {
		this.update((state) => {
			return {
				...state,
				provider,
			};
		});
	}
}
