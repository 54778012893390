<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Manage Users</h1>
	</div>

	<div class="container-fluid">
		<div class="app-user-table-container pt-3">
			<div
				class="row px-3 justify-content-center"
				*ngFor="let sec of userViewModel"
			>
				<div class="col-12">
					<div class="col-md-0">
						<!--TL - Show All Users-->
						<ng-container *appRestrictTo="['TSAU']">
							<div class="d-flex justify-content-end">
								<label
									[for]="sec.secGroup.securityGroupCode"
									class="app-checkbox d-flex align-items-center"
								>
									<input
										type="checkbox"
										[id]="sec.secGroup.securityGroupCode"
										(change)="sec.showAll = $event.target.checked"
									/>
									<span>&nbsp;</span>
									<span>Show all</span>
								</label>
							</div>
						</ng-container>
						<table class="table table-striped tap-admin-table">
							<thead>
								<th class="absorber py-2 px-1 px-lg-2">
									<span>{{ sec.secGroup.securityGroupName }}</span>
								</th>
								<th class="py-2 pl-1 pl-lg-2 pr-4">
									<span class="app-user-table-collapse-btn text-white">
										<span
											[id]="sec.secGroup.securityGroupCode + '_toggle_collapse'"
											class="clickable"
											(click)="sec.collapse = !sec.collapse"
											>{{ sec.collapse ? "+" : "&minus;" }}</span
										>
									</span>
								</th>
							</thead>
							<tbody *ngIf="!sec.collapse">
								<tr
									class="py-2 px-1 px-lg-2"
									appHoverable
									*ngFor="let staff of sec.activeStaffs; let i = index"
								>
									<td
										[routerLink]="[staff.StaffID]"
										class="py-2 px-1 px-lg-2 clickable"
									>
										<div>{{ staff.FirstName + " " + staff.LastName }}</div>
									</td>
									<td class="pt-2 pb-0 px-1 px-lg-2">
										<!--TL - Delete User-->
										<ng-container *appRestrictTo="['TDU']">
											<button
												id="deleteStaff_{{ i }}"
												(click)="confirmDelete(staff)"
												class="btn bg-transparent transparent py-0"
												hoverClass="not-transparent"
												appHoverable
											>
												<i class="material-icons md-20">&#xE872;</i>
											</button>
										</ng-container>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="sec.showAll && !sec.collapse" class="disabled">
								<tr
									class="py-2 px-1 px-lg-2"
									*ngFor="let staff of sec.inactiveStaffs; let i = index"
								>
									<td
										[routerLink]="[staff.StaffID]"
										class="py-2 px-1 px-lg-2 clickable"
									>
										<div>{{ staff.FirstName + " " + staff.LastName }}</div>
									</td>
									<td class="pt-2 pb-0 px-1 px-lg-2">
										<!--TL - Delete User-->
										<ng-container *appRestrictTo="['TDU']">
											<button
												id="deleteStaff_{{ i }}"
												class="btn bg-transparent transparent py-0"
												hoverClass="not-transparent"
												appHoverable
												[disabled]="true"
											>
												<i class="material-icons md-20">&#xE872;</i>
											</button>
										</ng-container>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
