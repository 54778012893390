import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BusinessProfileState, BusinessProfileStore } from './business-profile.store';

@Injectable()
export class BusinessProfileQuery extends Query<BusinessProfileState> {
	customerID$ = this.select((state) =>
		state.primaryCompany ? state.primaryCompany.customerID : null
	);
	/**
	 * @returns observable object of primary customer
	 */
	primaryCompany$ = this.select((state) => state.primaryCompany);
	contactStatus$ = this.select((state) =>
		state.primaryCompany ? state.primaryCompany.contactStatus : null
	);
	directors$ = this.select((state) => state.directors);
	shareholders$ = this.select((state) => state.shareholders);
	others$ = this.select((state) => state.others);

	/**
	 * @returns observable array of l&R Insurance
	 */
	lrInsurance$ = this.select((state) => state.lrInsurance);

	/**
	 * @returns observable array of Mortgage
	 */
	mortgage$ = this.select((state) => state.mortgage);

	/**
	 * @returns observable array of Property & Assets (Security)
	 */
	propertyAsset$ = this.select((state) => state.propertyAsset);

	/**
	 * @returns observable array of Assets
	 */
	asset$ = this.select((state) => state.asset);

	/**
	 * @returns observable array of Liabilities
	 */
	liability$ = this.select((state) => state.liability);

	/**
	 * @returns observable array of F&G Insurance
	 */
	fgInsurance$ = this.select((state) => state.fgInsurance);

	/**
	 * @returns observable array of KiwiSaver
	 */
	kiwiSavers$ = this.select((state) => state.kiwiSavers);

	/**
	 * @returns observable array of Investments
	 */
	investments$ = this.select((state) => state.investments);

	/**
	 * @returns observable array of Edit History
	 */
	histories$ = this.select((state) => state.histories);

	/**
	 * @returns observable array of documents
	 */
	documents$ = this.select((state) => state.documents);

	activityTimeline$ = this.select((state) => state.activityTimeline);

	currentActivityCriterias$ = this.select(
		(state) => state.currentActivityCriterias
	);

	/**
	 * Advice Process List
	 */
	adviceProcesses$ = this.select((state) => state.adviceProcesses);

	directorIsLoading$ = this.select((state) => state.directorIsLoading);
	shareholderIsLoading$ = this.select((state) => state.shareholderIsLoading);
	othersIsLoading$ = this.select((state) => state.othersIsLoading);

	lrIsLoading$ = this.select((state) => state.lrIsLoading);
	mIsLoading$ = this.select((state) => state.mIsLoading);
	paIsLoading$ = this.select((state) => state.paIsLoading);
	aIsLoading$ = this.select((state) => state.aIsLoading);
	fgIsLoading$ = this.select((state) => state.fgIsLoading);
	kIsLoading$ = this.select((state) => state.kIsLoading);
	iIsLoading$ = this.select((state) => state.iIsLoading);

	documentIsLoading$ = this.select((state) => state.documentIsLoading);
	historyIsLoading$ = this.select((state) => state.historyIsLoading);
	adviceProcessIsLoading$ = this.select(
		(state) => state?.adviceProcessIsLoading
	);

	constructor(protected store: BusinessProfileStore) {
		super(store);
	}
}
