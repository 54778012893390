<div
	class="modal-header text-light tap-bg-primary d-flex justify-content-between"
>
	<h4 class="modal-title font-weight-normal">
		{{ title }}
	</h4>
	<div class="clrearfix d-flex">
		<button type="button" class="close" aria-label="Close" (click)="close()">
			<span class="material-symbols-outlined">close</span>
		</button>
	</div>
</div>
<div class="modal-body" [formGroup]="form" [class.submitted]="submitted">
	<div class="modal-body__inner clearfix py-3">
		<div class="container">
			<ng-container *ngIf="!!isOtherQualification">
				<div class="row mb-2">
					<div class="col-12">
						<label for="qualificationName" class="mb-1"
							>Qualification Name<span
								class="tap-required"
								*ngIf="QualificationName.invalid"
								>*</span
							></label
						><br />
						<app-input
							inputClassname="ui-form-control"
							id="qualificationName"
							formControlName="qualificationName"
							[isInvalid]="QualificationName.invalid"
						></app-input>
					</div>
				</div>
			</ng-container>
			<div class="row mb-2" *ngIf="!!institute">
				<div class="col-12">
					<label for="institute" class="mb-1"
						>Institute<span class="tap-required" *ngIf="Institute.invalid"
							>*</span
						></label
					><br />
					<app-input-select
						id="institute"
						formControlName="institute"
						inputClassname="ui-form-control"
						displayProperty="display"
						emptyText="Select"
						[allowEmptyInitial]="true"
						[items]="institute"
						[isInvalid]="Institute.invalid"
						(change)="setValidators()"
					></app-input-select>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-12">
					<label for="completedDate" class="mb-1"
						>Completed Date<span
							class="tap-required"
							*ngIf="CompletedDate.invalid"
							>*</span
						></label
					><br />
					<app-date-input
						class="ui-form-control"
						formControlName="completedDate"
						textboxId="completedDate"
						[max]="maxDate"
						[attr.disabled]="isLoading"
						[class.invalid-control]="CompletedDate.invalid"
					></app-date-input>
				</div>
			</div>
			<div class="row mb-2">
				<ng-container *ngIf="documents?.length > 0">
					<ng-container *ngFor="let d of documents; let i = index">
						<div class="col-12 mt-2">
							<span
								class="float-left view-document text-truncate"
								(click)="viewDocument(d)"
								>{{ d.Value || "" }}</span
							><button
								id="removeDocument_{{ i }}"
								type="button"
								class="icon-btn float-left"
								(click)="removeDocument(i, d)"
								[disabled]="isLoading"
							>
								<i class="material-icons md-16 pr-0"> delete </i>
							</button>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div class="row mb-2">
				<div class="col-12">
					<button
						class="upload-document-btn ui-btn-gray w-100"
						(click)="uploadDocument()"
						[class.invalid-control]="submitted && documents?.length === 0"
						*ngIf="
							(!!limitSingleDoc && documents?.length === 0) || !limitSingleDoc
						"
						[disabled]="isLoading"
					>
						+ Upload Document
					</button>
				</div>
			</div>

			<!-- Polytech -->
			<ng-container *ngIf="Institute.value === 'Open Polytech'">
				<div class="row mb-2 mt-4">
					<div class="col-12">
						<label for="polytechCompletedDate" class="mb-1"
							>Completed Date<span
								class="tap-required"
								*ngIf="PolytechCompletedDate.invalid"
								>*</span
							></label
						><br />
						<app-date-input
							class="ui-form-control"
							formControlName="polytechCompletedDate"
							textboxId="polytechCompletedDate"
							[max]="maxDate"
							[attr.disabled]="isLoading"
							[class.invalid-control]="PolytechCompletedDate.invalid"
						></app-date-input>
					</div>
				</div>
				<div class="row mb-2">
					<ng-container *ngIf="polytechDocuments?.length > 0">
						<ng-container *ngFor="let d of polytechDocuments; let i = index">
							<div class="col-12 mt-2">
								<span
									class="float-left view-document text-truncate"
									(click)="viewDocument(d)"
									>{{ d.Value || "" }}</span
								><button
									id="removeDocument_{{ i }}"
									type="button"
									class="icon-btn float-left"
									(click)="removePolyDocument(i, d)"
									[disabled]="isLoading"
								>
									<i class="material-icons md-16 pr-0"> delete </i>
								</button>
							</div>
						</ng-container>
					</ng-container>
				</div>
				<div class="row mb-2">
					<div class="col-12">
						<button
							class="upload-document-btn upload-polytech-document ui-btn-gray w-100"
							(click)="uploadDocument(true)"
							[disabled]="isLoading"
							[class.invalid-control]="
								submitted && polytechDocuments?.length === 0
							"
						>
							+ Upload Document
						</button>
					</div>
				</div>
			</ng-container>

			<div class="row mb-2">
				<div class="col-12 mt-2">
					<button
						class="btn btn-primary ui-btn-primary w-100"
						(click)="saveQualification()"
						[disabled]="isLoading"
					>
						{{ btnText || "Save" }}
						<i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
