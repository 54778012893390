import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { BusinessProfileService } from 'src/app/modules/crm/business-profile/states/business-profile.service';
import { ClientProfileService } from 'src/app/modules/crm/client-profile/states/client-profile.service';
import MomentUtil from 'src/app/util/moment.util';
import { ChipsComponent } from '../../chips/chips.component';
import {
	AdviceProcessAddModelState,
	AdviceProcessCode,
	GetAdviserReworkAdviserCode,
	GetAllAdviserReworkCodes,
} from '../../models/advice-process/advice-process.model';
import {
	CustomerTypes,
	RelationshipTypes,
} from '../../models/_general/client.model';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { ClientAddModalComponent } from '../client-add-modal/client-add-modal.component';
import { BusinessService } from '@core/business/business.service';
import { BusinessConfig } from '@domain/business-config/business-config.model';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { PrimaryCustomerCompanyState } from '@shared/models/business-profile/business/business.model';
import { uniq } from 'ramda';
import { FgInsuranceState } from '@shared/models/services/fg-insurance/fg-provider-group.model';
import { LrInsuranceState } from '@shared/models/services/lr-insurance/lr-provider-group.model';

enum ErrorMessages {
	loatMaxClients = 'Maximum of 3 clients allowed',
	moatMaxClients = 'Maximum of 4 clients allowed',
	koatMaxClients = 'Maximum of 2 clients allowed',
}

@Component({
	selector: 'app-advice-process-add-modal',
	templateUrl: './advice-process-add-modal.component.html',
	styleUrls: ['./advice-process-add-modal.component.scss'],
})
export class AdviceProcessAddModalComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	header = '';
	advisers: ViewDisplayValue[];
	adviser: number;
	primaryClient: PrimaryClientState;
	primaryCompany: PrimaryCustomerCompanyState;
	clientsInvolved: ViewDisplayValue[];
	clientsInvolvedRaw: any[];
	processCodes: ViewDisplayValue[];
	leadOriginChoice: ViewDisplayValue[];
	leadTypeChoice: ViewDisplayValue[];
	trustTypes: ViewDisplayValue[];
	lrInsurance: LrInsuranceState;
	fgInsurance: FgInsuranceState;
	convertedLrPolicyTypes : ViewDisplayValue[];
	convertedFgPolicyTypes : ViewDisplayValue[];
	filteredLrPolicyTypes : ViewDisplayValue[];
	filteredFgPolicyTypes : ViewDisplayValue[];
	hasQuickAdd = false;
	customerID: number;
	isCompany: boolean;
	saveFn: (data: AdviceProcessAddModelState) => Observable<any>;
	addSCI: (data) => Observable<any>;
	addSCT: (data) => Observable<any>;
	addNote: (data) => Observable<any>;
	pciAndSciCount = 0;
	mortgageClientsInvolved: ViewDisplayValue[] = [];
	claimManagers: ViewDisplayValue[];
	businessConfig: BusinessConfig;
	selectedInfo:AdviceProcessAddModelState;

	isSaving = false;
	hasCRT = false;
	hasMOAT: boolean;
	hasKOAT: boolean;

	form: UntypedFormGroup = this.fb.group({
		processCode: this.fb.control(null, [Validators.required]),
		adviser: this.fb.control(null, [Validators.required]),
		clientsInvolved: this.fb.control([], [Validators.required]),
		isOnline: this.fb.control(null),
		customerServiceId:this.fb.control([]),
		claimManager:this.fb.control(null),
	});

	quickAddCustomerType = '';
	errorMessages = ErrorMessages;
	adviceProcessCode = AdviceProcessCode;

	@ViewChild('clientsInvolvedChips') clientsInvolvedChips: ChipsComponent;

	showQuickAdd: boolean = true;

	clientsInvolvedRaw$: Observable<any[]>;
	enableLoatV2: boolean;


	constructor(
		public bsModalRef: BsModalRef,
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private service: ClientProfileService,
		private businessService: BusinessProfileService,
		private businessServices: BusinessService
	) {
		// @ts-ignore-next
		window.f = this;
	}

	get adviceProcessForm() {
		return this.form.get('processCode');
	}
	get adviserForm() {
		return this.form.get('adviser');
	}
	get clientsInvolvedForm() {
		return this.form.get('clientsInvolved');
	}

	get isOnlineForm() {
		return this.form.get('isOnline');
	}

	get customerServiceIdForm() {
		return this.form.get('customerServiceId');
	}
	get claimManagerForm() {
		return this.form.get('claimManager');
	}

	get isOnlineAvailable() {
		if (this.isCompany) {
			return false;
		}
		if (this.adviceProcessForm.value === AdviceProcessCode.MortgageAdvice) {
			return this.hasMOAT;
		} else if (
			this.adviceProcessForm.value === AdviceProcessCode.LRAdviceNew ||
			this.adviceProcessForm.value === AdviceProcessCode.LRAdviceReview ||
			this.adviceProcessForm.value ===
				AdviceProcessCode.ClientAlterationRequest
		) {
			return this.hasCRT;
		} else if (
			this.adviceProcessForm.value === AdviceProcessCode.KiwiSaverAdvice
		) {
			return this.hasKOAT;
		}
	}

	ngOnInit(): void {
		if (this.isCompany) {
			this.clientsInvolvedRaw$ = this.businessService.clientsInvolvedRaw$;
		} else {
			this.clientsInvolvedRaw$ = this.service.clientsInvolvedRaw$;
		}

		this.businessServices.loatv2Enabled$
			.pipe(
				tap((enable) => {
					this.enableLoatV2 = enable;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.clientsInvolvedRaw$
			.pipe(
				tap((clientsInvolvedRaw) => {
					this.clientsInvolvedRaw = clientsInvolvedRaw;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.form
			.get('clientsInvolved')
			.valueChanges.pipe(takeUntil(this.onDestroy$))
			.subscribe((clientIds: string[]) => {
				clientIds = clientIds.filter((id) => id || id == '0');
				this.pciAndSciCount = 0;

				const pci = this.clientsInvolvedRaw?.filter(
					(e) =>
						clientIds?.indexOf(e.customerID?.toString()) !== -1 &&
						e.customerType === CustomerTypes.PrimaryCustomerIndividual
				);
				const sci = this.clientsInvolvedRaw?.filter(
					(e) =>
						clientIds?.indexOf(e.customerID?.toString()) !== -1 &&
						e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
						e.relationship !== RelationshipTypes.Child &&
						e.contactMethod !== 'Deceased'
				);
				const lci = this.clientsInvolvedRaw?.filter((e) => {
					const id = e.linkedFromPrimaryCustomer
						? e.relatedCustomerId
						: e.customerId;
					return (
						clientIds?.indexOf(id?.toString()) !== -1 &&
						e.customerType === CustomerTypes.LinkedContact &&
						e.contactMethod !== 'Deceased'
					);
				});

				const scc = this.clientsInvolvedRaw?.filter(
					(e) => e.customerType === CustomerTypes.PrimaryCustomerCompany
				);
				const sct = this.clientsInvolvedRaw?.filter(
					(e) => e.customerType === CustomerTypes.SecondaryCustomerTrust
				);
				const child = this.clientsInvolvedRaw?.filter(
					(e) =>
						e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
						e.relationship === RelationshipTypes.Child &&
						e.contactMethod !== 'Deceased'
				);

				const pci2 = this.clientsInvolvedRaw?.filter(
					(e) => e.customerType === CustomerTypes.PrimaryCustomerIndividual
				);
				const sci2 = this.clientsInvolvedRaw?.filter(
					(e) =>
						e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
						e.relationship !== RelationshipTypes.Child &&
						e.contactMethod !== 'Deceased'
				);
				const scc2 = this.clientsInvolvedRaw?.filter(
					(e) => e.customerType === CustomerTypes.PrimaryCustomerCompany
				);
				const sct2 = this.clientsInvolvedRaw?.filter(
					(e) => e.customerType === CustomerTypes.SecondaryCustomerTrust
				);
				const child2 = this.clientsInvolvedRaw?.filter(
					(e) =>
						e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
						e.relationship === RelationshipTypes.Child &&
						e.contactMethod !== 'Deceased'
				);

				const lci2 = this.clientsInvolvedRaw?.filter(
					(e) =>
						e.customerType === CustomerTypes.LinkedContact &&
						e.contactMethod !== 'Deceased'
				);

				this.pciAndSciCount = this.isCompany
					? R.sum([pci?.length, sci?.length, scc?.length, lci?.length])
					: R.sum([pci?.length, sci?.length, lci?.length]);

				if (
					!this.isCompany &&
					this.pciAndSciCount >= 3 &&
					this.isOnlineForm.value === true &&
					(this.adviceProcessForm.value === AdviceProcessCode.LRAdviceNew ||
						this.adviceProcessForm.value === AdviceProcessCode.LRAdviceReview)
				) {
					this.clientsInvolved = this.mapper(pci, sci, scc, sct, child, lci);
				} else if (
					this.isOnlineForm.value &&
					this.adviceProcessForm.value === AdviceProcessCode.KiwiSaverAdvice
				) {
					// TAPNZ-11580 KOAT: No need for Business & Trust
					this.clientsInvolved = this.mapper(pci2, sci2, [], [], child2, lci2);
				} else {
					this.clientsInvolved = this.mapper(
						pci2,
						sci2,
						scc2,
						sct2,
						child2,
						lci2
					);
				}

				this.clientsInvolvedForm.markAllAsTouched();
				this.clientsInvolvedForm.updateValueAndValidity({ emitEvent: false });
			});

		this.prefill();
		if(!R.isNil(this.lrInsurance)){
			this.convertedLrPolicyTypes = this.transformClaimsDropdownData(this.lrInsurance,'lRs');
			this.filteredLrPolicyTypes = this.convertedLrPolicyTypes;
		}
		if(!R.isNil(this.fgInsurance)){
			this.convertedFgPolicyTypes = this.transformClaimsDropdownData(this.fgInsurance,'fGs');
			this.filteredFgPolicyTypes = this.convertedFgPolicyTypes;
		}
		if(!R.isNil(this.selectedInfo)){
			this.adviceProcessForm.setValue(this.selectedInfo?.processCode);
			this.handleProcessSelectionChange();
			this.adviserForm.setValue(this.selectedInfo?.adviser);
			this.customerServiceIdForm.setValue(this.selectedInfo?.customerServiceId??[]);
			if(!R.isNil(this.selectedInfo?.customerServiceId)){
				this.checkPolicyChange([this.selectedInfo?.customerServiceId[0]]);
			}
			this.clientsInvolvedForm.setValue(this.selectedInfo?.clientsInvolved??[]);
		}
	}

	recheckValidity() {
		this.isOnlineForm.updateValueAndValidity();
		this.clientsInvolvedForm.updateValueAndValidity();
		this.prefillClientsInvolved();
		this.checkQuickAdd();
	}

	prefill(): void {
		if (!this.businessConfig?.AdviserRework) {
			this.form.get('adviser').setValue(this.adviser);
		}
		this.prefillClientsInvolved();

		const peopleOnly: any[] = this.clientsInvolvedRaw?.filter(
			(client: any) =>
				client.customerType === CustomerTypes.PrimaryCustomerIndividual ||
				(client.customerType === CustomerTypes.SecondaryCustomerIndividual &&
					client.contactMethod !== 'Deceased') ||
				(client.customerType === CustomerTypes.LinkedContact &&
					client.contactMethod !== 'Deceased') ||
				client.customerType === CustomerTypes.PrimaryCustomerCompany ||
				client.customerType === CustomerTypes.SecondaryCustomerTrust
		);

		this.mortgageClientsInvolved = (this.clientsInvolved || [])?.filter(
			(client: ViewDisplayValue) => {
				return peopleOnly?.find((person: any) => {
					if (person?.customerType === CustomerTypes.LinkedContact) {
						const id = person?.linkedFromPrimaryCustomer
							? person?.relatedCustomerId
							: person.customerId;
						return id?.toString() === client.value?.toString();
					} else {
						return person.customerID?.toString() === client.value?.toString();
					}
				});
			}
		);
	}

	prefillClientsInvolved() {
		this.clientsInvolvedForm.setValue(
			this.clientsInvolved?.map((client: ViewDisplayValue) => client.value)
		);
		if (this.isCompany) {
			this.clientsInvolvedForm.setValidators([Validators.required]);
		} else {
			this.clientsInvolvedForm.setValidators([
				Validators.required,
				this.clientSelectionValidator,
			]);
		}

		this.clientsInvolvedForm.updateValueAndValidity();
	}

	prefillAdviserRework() {
		if (!this.businessConfig?.AdviserRework) {
			return;
		}
		const code = this.form.get('processCode')?.value;
		const data = this.isCompany ? this.primaryCompany : this.primaryClient;
		let adviserId;
		switch (code) {
			case AdviceProcessCode.Complaint:
				// For Complaints, prepopulate only if there is one adviser on file,
				// if there is more than one, it’s blank and the user has to select the appropriate adviser.
				const ids = GetAllAdviserReworkCodes?.reduce((a, c) => {
					const id = data?.[c] || null;
					return uniq([...a, id]);
				}, [])?.filter(Boolean);
				adviserId = ids?.length === 1 ? ids[0] : null;
				break;
			default:
				const adviserCode = GetAdviserReworkAdviserCode(code);
				adviserId = data?.[adviserCode];
				break;
		}
		// if (code === AdviceProcessCode.Complaint) {
		// 	// For Complaints, prepopulate only if there is one adviser on file,
		// 	// if there is more than one, it’s blank and the user has to select the appropriate adviser.
		// 	const ids =
		// 		GetAllAdviserReworkCodes?.reduce((a, c) => {
		// 			const id = data?.[c] || null;
		// 			return uniq([...a, id]);
		// 		}, [])?.filter(Boolean);
		// 	adviserId = ids?.length === 1 ? ids[0] : null;
		// } else {
		// 	const adviserCode = GetAdviserReworkAdviserCode(code);
		// 	adviserId = data?.[adviserCode];
		// }
		this.form.get('adviser').setValue(adviserId || null);
	}

	prepareFormValue() {
		const data = this.form.value;
		return {
			...data,
			customerID: this.customerID,
			adviser: +data.adviser,
			clientsInvolved: JSON.stringify(R.map((x) => +x, data.clientsInvolved)),
			customerServiceId:JSON.stringify(R.map((x) => +x, data.customerServiceId)),
			isOnline: this.isOnlineAvailable ? this.form.value.isOnline : false,
		};
	}

	save(): void {
		if (!this.form.valid) {
			return;
		}
		this.isSaving = true;
		this.saveFn(this.prepareFormValue())
			.pipe(
				tap(
					(res) => {
						if (res) {
							this.isSaving = false;
						}
						this.decline();
					},
					() => {
						this.isSaving = false;
					}
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	decline = () => this.bsModalRef.hide();

	quickAdd = (model: any, note: any) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((m) => {
				m.primaryCustomer = this.customerID;
				if (m.firstName !== undefined && m.lastName !== undefined) {
					m.customerType = CustomerTypes.SecondaryCustomerIndividual;
					m.dateOfBirth = MomentUtil.formatDateToServerDate(m.dateOfBirth);
					return this.addSCI(m);
				}
				if (m.trustName !== undefined) {
					m.customerType = CustomerTypes.SecondaryCustomerTrust;
					return this.addSCT(m);
				}
			}),
			mergeMap(
				(id) => {
					const noteModel = {
						customerID: +id,
						customerServiceID: 0,
						notes: note.notes,
					};
					return !noteModel.notes ? of(id) : this.addNote(noteModel);
				},
				(o) => o
			),
			tap((x) => {
				this.form
					.get('clientsInvolved')
					.reset([...this.form.get('clientsInvolved').value, x?.toString()]);
			})
		);

	quickAddModal() {
		new Observable((obs) => {
			const initState: any = {
				header: 'Quick Add',
				name: '',
				advisers: this.advisers,
				leadOriginChoice: this.leadOriginChoice,
				leadTypeChoice: this.leadTypeChoice,
				trustTypes: this.trustTypes,
				savefn: this.quickAdd,
				searchMode: 'ClientsInvolved',
			};
			this.modalService.show(ClientAddModalComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
			});
			obs.complete();
		}).subscribe();
	}

	mapper(pci, sci, scc, sct, child, lcc): ViewDisplayValue[] {
		const pc: any =
			!!pci && this.isCompany
				? R.map(
						(c) => ViewDisplayValue.Map(c?.customerID?.toString(), c?.name),
						pci
					)
				: !!pci && pci[0]
					? ViewDisplayValue.Map(
							pci[0].customerID?.toString(),
							`${pci[0].firstName} ${pci[0].lastName}`
						)
					: null;

		const sc: ViewDisplayValue[] = sci
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							this.isCompany ? y.name : `${y.firstName} ${y.lastName}`
						),
					sci
				)
			: [];
		const sb: ViewDisplayValue[] = scc
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							this.isCompany ? y.name : `${y.companyName}`
						),
					scc
				)
			: [];
		const st: ViewDisplayValue[] = sct
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							this.isCompany ? y.name : `${y.trustName}`
						),
					sct
				)
			: [];
		const scic: ViewDisplayValue[] = child
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							`${y.firstName} ${y.lastName}`
						),
					child
				)
			: [];
		const lc: ViewDisplayValue[] = lcc
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							`${
								y?.linkedFromPrimaryCustomer
									? y.relatedCustomerId?.toString()
									: y.customerId?.toString()
							}`,
							`${y.name}`
						),
					lcc
				)
			: [];

		return this.isCompany
			? [...pc, ...sc, ...sb, ...st, ...scic, ...lc].filter((a) => !!a)
			: [pc, ...sc, ...sb, ...st, ...scic, ...lc].filter((a) => !!a);
	}

	handleProcessSelectionChange(): void {
		if (
			this.isOnlineAvailable &&
			this.form.value.processCode !==
				AdviceProcessCode.ClientAlterationRequest
		) {
			this.isOnlineForm.setValidators([Validators.required]);
		} else {
			this.isOnlineForm.setValidators([]);
		}
		if(this.adviceProcessForm.value === AdviceProcessCode.LRClaim ||
				this.adviceProcessForm.value === AdviceProcessCode.FGClaim){
			this.customerServiceIdForm.setValidators([Validators.required]);
		}else{
			this.customerServiceIdForm.setValidators([]);
		}
		if(this.customerServiceIdForm.value) //Clear customerServiceIdForm upon changing selected process
			this.customerServiceIdForm.setValue([]);
		if(this.claimManagerForm.value) //Clear claimManagerForm upon changing selected process
			this.claimManagerForm.setValue(null);

		this.customerServiceIdForm.updateValueAndValidity();
		this.isOnlineForm.updateValueAndValidity();
		this.clientsInvolvedForm.updateValueAndValidity();

		if (
			this.adviceProcessForm.value === AdviceProcessCode.MortgageAdvice &&
			!this.isCompany
		) {
			this.clientsInvolvedForm.setValue(
				this.mortgageClientsInvolved?.map(
					(client: ViewDisplayValue) => client.value
				)
			);
		} else {
			this.clientsInvolvedForm.setValue(
				this.clientsInvolved?.map((client: ViewDisplayValue) => client.value)
			);
		}
		if(this.adviceProcessForm.value!==AdviceProcessCode.LRClaim &&
			 this.adviceProcessForm.value!==AdviceProcessCode.FGClaim){
			this.prefillAdviserRework();
		}else{
			this.adviserForm.setValue('');
		}
		this.prefillClientsInvolved();
		this.checkQuickAdd();
		this.setAdviceProcessToOnline();
	}

	clientSelectionValidator = (): ValidationErrors | null => {
		if (this.isCompany) {
			return null;
		}
		const process: string = this.adviceProcessForm?.value;

		if (this.pciAndSciCount === 0 &&
			(process !== AdviceProcessCode.LRClaim && process !== AdviceProcessCode.FGClaim)) {
			return { minClients: true };
		}

		this.checkQuickAdd();

		if (
			(process === AdviceProcessCode.LRAdviceNew ||
				process === AdviceProcessCode.LRAdviceReview) &&
			this.isOnlineForm.value === true
		) {
			return this.pciAndSciCount > 3 ? { loatMaxClients: true } : null;
		} else if (
			process === AdviceProcessCode.MortgageAdvice &&
			this.isOnlineForm.value === true
		) {
			return this.pciAndSciCount > 4 ? { moatMaxClients: true } : null;
		} else if (
			process === AdviceProcessCode.KiwiSaverAdvice &&
			this.isOnlineForm.value === true
		) {
			return this.pciAndSciCount > 2 ? { koatMaxClients: true } : null;
		} else {
			return null;
		}
	};

	checkQuickAdd() {
		const process: string = this.adviceProcessForm?.value;

		if (
			(process === AdviceProcessCode.LRAdviceNew ||
				process === AdviceProcessCode.LRAdviceReview) &&
			this.pciAndSciCount >= 3 &&
			this.isOnlineForm.value === true
		) {
		} else if (
			process === AdviceProcessCode.MortgageAdvice &&
			this.pciAndSciCount >= 4 &&
			this.isOnlineForm.value === true
		) {
			this.showQuickAdd = false;
		} else if (
			process === AdviceProcessCode.KiwiSaverAdvice &&
			this.pciAndSciCount >= 2 &&
			this.isOnlineForm.value === true
		) {
			this.showQuickAdd = false;
		} else {
			this.showQuickAdd = true;
		}
	}

	transformClaimsDropdownData(data,type:'lRs'|'fGs'):ViewDisplayValue[]{
		const cleanProductString = (data)=>{
			return data.replace(/[\[\]"]+/g,'').trim().replace(/,/g,', ');
		}
		const dataList = data[type].filter((data)=>data.color==='green'); //Get only green items
			const extractedItems = R.map(d=>d.customerServices||[],dataList) // Get all customerServices array
			const flattenedItems = R.flatten(extractedItems); //flatten the array

			return R.map(item=>{
				const provider = dataList.find(d=>d.customerServices&&d.customerServices.includes(item)).provider; // Find respective provider for the iteration
				let display =`${provider??''}: `; //creating display for dropdown
				if(type==='lRs'){
					const productPortion = item.products?`, ${cleanProductString(item.products)}`:'';
					const policyNumberSuffix = item.policyNumberSuffix?`-${item.policyNumberSuffix}`:'';
					display+=`${item.policyNumber}${policyNumberSuffix}${productPortion}`;
				}else{
					const policyLinesPortion = item.policyLines ? `, ${cleanProductString(item.policyLines)}`:'';
					const policyNumberSuffix = item.fGPolicyNumberSuffix?`-${item.fGPolicyNumberSuffix}`:'';
					display+=`${item.fGPolicyNumber}${policyNumberSuffix}${policyLinesPortion}`;
				}
				return{
					value:item.customerServiceID,
					display,
					data:{provider} // added provider on data for filtering
				}
			},flattenedItems)
	}

	checkPolicyChange(event:any[]){
		if(event.length===1){
			if(this.adviceProcessForm.value === this.adviceProcessCode.LRClaim){
				const data = this.convertedLrPolicyTypes.find(item=>item.value===event[0]).data; // Get Provider based on selected value;
				this.filteredLrPolicyTypes = this.convertedLrPolicyTypes.filter(item=>item.data.provider===data.provider);// Filter policyType based on Provider
			}else{
				const data = this.convertedFgPolicyTypes.find(item=>item.value===event[0]).data; // Get Provider based on selected value;
				this.filteredFgPolicyTypes = this.convertedFgPolicyTypes.filter(item=>item.data.provider===data.provider);// Filter policyType based on Provider
			}
			this.prefillAdviserRework();
		}else if(event.length===0){
			this.filteredFgPolicyTypes = this.convertedFgPolicyTypes; //Reset Filtered Dropdowns
			this.filteredLrPolicyTypes = this.convertedLrPolicyTypes;
		}
	}

	setAdviceProcessToOnline(): void {
		if (this.isOnlineAvailable && this.enableLoatV2) {
			this.isOnlineForm.setValue(true);
			this.recheckValidity();
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
