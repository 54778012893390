import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RiskAnalysisService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/risk-analysis.service';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';

@Component({
	selector: 'app-risk-analysis-people-tabs',
	templateUrl: './people-tabs.component.html',
	styleUrls: ['./people-tabs.component.scss'],
})
export class PeopleTabsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	selectedCrt: number;
	people$: Observable<PeopleState[]>;
	selectedPerson$ = this.service.selectedPerson$;
	activePerson$ = this.service.activePerson$;
	@Input() isAdviceProcessEnded: boolean;
	@Input() showDependants: boolean;
	@Output() selectCrtFromTab = new EventEmitter<number>();
	@Output() selectActivePerson = new EventEmitter<PeopleState>();

	constructor(private service: RiskAnalysisService) {}

	ngOnInit(): void {
		this.people$ = this.service.getPeopleTabs(this.showDependants);
		this.activePerson$.pipe(takeUntil(this.onDestroy$)).subscribe((person) => {
			if (!person) {
				return;
			}

			this.selectedCrt = person?.cRTId;
			this.selectCrtFromTab.emit(person?.cRTId);
			this.selectActivePerson.emit(person);
		});
	}

	clickPeople(index: number) {
		this.service.setCurrentPersonIndex(index, true).pipe(take(1)).subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
