import { Component, OnInit, Output, EventEmitter, OnDestroy, TemplateRef, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { map, takeUntil, withLatestFrom, first, finalize, take, tap, mergeMap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { FgInsuranceRequestModel, FgInsuranceRequest } from '../fg-insurance-request.model';
import { FgInsuranceService } from '../states/fg-insurance.service';
import { FgInsuranceQuery } from '../states/fg-insurance.query';
import MomentUtil from '../../../../../util/moment.util';
import { UserQuery } from '../../../../../domain/user/user.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { RecursivePartial } from '../../../../../core/util/util.service';
import { FgInsuranceUiQuery } from '../states/fg-insurance-ui.query';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { request } from '../fg-insurance.util';
import { ActivatedRoute } from '@angular/router';
import { AdviserStatusViewDisplay } from '../../pipeline.model';
import { strUtil } from '@util/util';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsStatus, ExportsType } from '@modules/exports/state/exports.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ExportsService } from '@modules/exports/state/exports.service';
import { omit } from 'ramda';
declare var $: any;

const patchValue: <T>(form: AbstractControl, value: RecursivePartial<T>) => void = (form, value) => {
	form.patchValue(value);
};

@Component({
	selector: 'app-fg-insurance-form',
	templateUrl: './fg-insurance-form.component.html',
	styleUrls: ['./fg-insurance-form.component.scss'],
})
export class FgInsuranceFormComponent implements OnInit, AfterViewInit, OnDestroy {
	onDestroy$: Subject<void> = new Subject<void>();

	@ViewChild(PopoverDirective) popover: PopoverDirective;

  exportStatus$ = this.exportsQuery.status$;

	isSearching$ = this.fgInsuranceQuery.uiQuery.isSearching$;
	isExporting$ = this.fgInsuranceQuery.uiQuery.isExporting$;
	columnFromOpen$ = this.fgInsuranceQuery.uiQuery.columnFormPopupOpen$;
  adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

	openColumnPopup = this.fgInsuranceService.togglePopup;
	count$ = this.fgInsuranceQuery.count$;
	totalPremium$ = this.fgInsuranceQuery.totalPremium$;

	isTapLevel$ = this.userQuery.isTapLevel$;
	userInfo$ = this.userQuery.userInfo$;

	availableStaffChoices$: Observable<ViewDisplayValue[]> = this.bLStaffsQuery.FGAdviserChoicesOption$;

	AdviserStatus$: Observable<ViewDisplayValue[]> = of(AdviserStatusViewDisplay);

	FGI$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('FGI');
	FGPW$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('FGPW');
	FGPT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('FGPT');
	FGS$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('FGS');
	FGCS$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('FGCS');
	FGAS$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('FGAS');
	PCLE$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('PCLE');
	PCLT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('PCLT');

	// tslint:disable-next-line: no-angle-bracket-type-assertion
	form: UntypedFormGroup = this.fb.group(<{ [key in keyof FgInsuranceRequestModel]: UntypedFormControl }> {
		SelectedAdvisers: this.fb.control([]),
		SelectedAltAdvisers: this.fb.control([]),
		SelectedAdviserStatuses: this.fb.control([]),
		SelectedProviders: this.fb.control([]),

		SelectedLeadOrigins: this.fb.control([]),
		SelectedPolicyTypes: this.fb.control([]),
		SelectedPolicyWriters: this.fb.control([]),

		SelectedPolicyStatus: this.fb.control([]),
		SelectedClaimStatus: this.fb.control([]),
		SelectedAccountStatus: this.fb.control([]),
		NextActivityDateMin: this.fb.control(null),
		NextActivityDateMax: this.fb.control(null),
		RenewalDateMin: this.fb.control(null),
		RenewalDateMax: this.fb.control(null),

		FirstPolicyDateMin: this.fb.control(null),
		FirstPolicyDateMax: this.fb.control(null),

		InceptionDateMin: this.fb.control(null),
		InceptionDateMax: this.fb.control(null),

		NextReviewDateMin: this.fb.control(null),
		NextReviewDateMax: this.fb.control(null),
	});

	@Output() toggleSearch = new EventEmitter<{
		showSearch: boolean;
		height: number;
		width: number;
	}>();
	showSearch = false;
	isTapLevel: boolean;

	////////// Progress Bar //////////////
	progress = {
		width: '0%',
	};
	index = 0;
	percent = 0;
	count = 0;
	totalCount = 0;
	modalRef: BsModalRef;
	msg = 'Export Progress';
	///////// END Progress Bar ////////

	params$ = this.activitedRoute.paramMap;

	constructor(
		private fb: UntypedFormBuilder,
		private fgInsuranceService: FgInsuranceService,
		private fgInsuranceQuery: FgInsuranceQuery,
		private userQuery: UserQuery,
		private bLStaffsQuery: BLStaffsQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private fgInsuranceUiQuery: FgInsuranceUiQuery,
		private renderer: Renderer2,
		private activitedRoute: ActivatedRoute,
    private businessConfigQuery: BusinessConfigQuery,
    private exportsQuery: ExportsQuery,
		private exportsService: ExportsService
	) { }

	ngOnInit() {
		let policyStatusList = [
			'Lead',
			'Contact Made',
			'Info Requested',
			'Info Received',
			'Underwriting',
			'Offer of Terms',
			'Policy Accepted'
		];

		this.params$
			.pipe(
				withLatestFrom(this.isTapLevel$, this.userInfo$),
				map(([params, isTapLevel, userinfo]) => {
					this.isTapLevel = isTapLevel;
					const adv = params.get('advisers');
					const stat = params.get('statuses');
					const adviserStat = params.get('adviserStatuses');
					if (params && (!!adv || stat)) {
						const req = JSON.parse(JSON.stringify(this.prepareFormValue()));
						this.fgInsuranceService.setSearchForm({
							...req,
							SelectedAdvisers: !!adv ? adv?.split(',') : [],
							SelectedPolicyStatus: strUtil.stringIsNotEmpty(stat)
								? stat?.split(',')
								: [],
							SelectedAdviserStatuses: strUtil.stringIsNotEmpty(adviserStat)
								? adviserStat?.split(',')
								: []
						});
						return {
							hasStatus: stat ? true : false,
							SelectedAdvisers: !!adv ? adv?.split(',') : [],
							SelectedPolicyStatus: strUtil.stringIsNotEmpty(stat)
								? stat?.split(',')
								: [],
							SelectedAdviserStatuses: strUtil.stringIsNotEmpty(adviserStat)
								? adviserStat?.split(',')
								: [],
						};
					} else if (!isTapLevel) {
						return {
							// SelectedAdvisers: [userinfo.StaffID.toString()],
							SelectedPolicyStatus: policyStatusList,
						};
					} else {
						return {
							SelectedPolicyStatus: policyStatusList,
						};
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(fValue => {
				this.reset();
				if (fValue) {
					patchValue(this.form, fValue);
					if (this.isTapLevel) {
						this.fgInsuranceService.sort('Status', 'desc');
					} else {
						this.fgInsuranceService.sort('ClientNextActivity', 'desc');
					}
				}
				if (fValue && fValue.hasStatus) {
					this.search();
				}
			});

		// this.isTapLevel$
		// 	.pipe(
		// 		withLatestFrom(this.userInfo$, this.FGS$),
		// 		map(([isTapLevel, userinfo, fgs]) => {
		// 			this.isTapLevel = isTapLevel;
		// 			let policyStatusList = [
		// 				'Lead',
		// 				'Contact Made',
		// 				'Info Requested',
		// 				'Info Received',
		// 				'Underwriting',
		// 				'Offer of Terms',
		// 			];
		// 			if (this.businessConfigService.companyCode() === 'blanket') {
		// 				policyStatusList = fgPipelinePreset;
		// 			}
		// 			return {
		// 				SelectedPolicyStatus: policyStatusList?.filter(x => fgs?.find(y => y.value === x)),
		// 			} as Partial<FgInsuranceRequestModel>;
		// 		}),
		// 		takeUntil(this.onDestroy$)
		// 	)
		// 	.subscribe(fValue => {
		// 		this.reset();
		// 		if (fValue) {
		// 			patchValue(this.form, fValue);
		// 			if (this.isTapLevel) {
		// 				this.fgInsuranceService?.sort('Status', 'desc');
		// 			} else {
		// 				this.fgInsuranceService?.sort('ClientNextActivity', 'desc');
		// 			}
		// 		}
		// 	});
	}

	ngAfterViewInit() {
		if (this.fgInsuranceQuery.getValue().count > 0 || this.fgInsuranceQuery.getValue().searchForm) {
			const advisers = this.fgInsuranceQuery.getValue().searchForm.SelectedAdvisers;
			const altAdvisers = this.fgInsuranceQuery.getValue().searchForm.SelectedAltAdvisers;
			const pw = this.fgInsuranceQuery.getValue().searchForm.SelectedPolicyWriters;
			const cs = this.fgInsuranceQuery.getValue().searchForm.SelectedClaimStatus;
			const as = this.fgInsuranceQuery.getValue().searchForm.SelectedAccountStatus;
			const ps = this.fgInsuranceQuery.getValue().searchForm.SelectedPolicyStatus;
			const insurer = this.fgInsuranceQuery.getValue().searchForm.SelectedProviders;
			const lo = this.fgInsuranceQuery.getValue().searchForm.SelectedLeadOrigins;
			const pt = this.fgInsuranceQuery.getValue().searchForm.SelectedPolicyTypes;

			const naMin = this.fgInsuranceQuery.getValue().searchForm.NextActivityDateMin;
			const naMax = this.fgInsuranceQuery.getValue().searchForm.NextActivityDateMax;
			const rMin = this.fgInsuranceQuery.getValue().searchForm.RenewalDateMin;
			const rMax = this.fgInsuranceQuery.getValue().searchForm.RenewalDateMax;
			const iMin = this.fgInsuranceQuery.getValue().searchForm.InceptionDateMin;
			const iMax = this.fgInsuranceQuery.getValue().searchForm.InceptionDateMax;
			const fpMin = this.fgInsuranceQuery.getValue().searchForm.FirstPolicyDateMin;
			const fpMax = this.fgInsuranceQuery.getValue().searchForm.FirstPolicyDateMax;


			this.form.get('SelectedAdvisers').setValue(advisers?.map(x => x?.toString()));
			this.form.get('SelectedAltAdvisers').setValue(altAdvisers?.map(x => x?.toString()));
			this.form.get('SelectedPolicyWriters').setValue(pw?.map(x => x?.toString()));
			this.form.get('SelectedClaimStatus').setValue(cs?.map(x => x?.toString()));
			this.form.get('SelectedAccountStatus').setValue(as?.map(x => x?.toString()));
			this.form.get('SelectedPolicyStatus').setValue(ps?.map(x => x?.toString()));
			this.form.get('SelectedProviders').setValue(insurer?.map(x => x?.toString()));
			this.form.get('SelectedLeadOrigins').setValue(lo?.map(x => x?.toString()));
			this.form.get('SelectedPolicyTypes').setValue(pt?.map(x => x?.toString()));

			this.form.get('NextActivityDateMin').setValue(MomentUtil.formatDateToMoment(naMin));
			this.form.get('NextActivityDateMax').setValue(MomentUtil.formatDateToMoment(naMax));
			this.form.get('RenewalDateMin').setValue(MomentUtil.formatDateToMoment(rMin));
			this.form.get('RenewalDateMax').setValue(MomentUtil.formatDateToMoment(rMax));
			this.form.get('InceptionDateMin').setValue(MomentUtil.formatDateToMoment(iMin));
			this.form.get('InceptionDateMax').setValue(MomentUtil.formatDateToMoment(iMax));
			this.form.get('FirstPolicyDateMin').setValue(MomentUtil.formatDateToMoment(fpMin));
			this.form.get('FirstPolicyDateMax').setValue(MomentUtil.formatDateToMoment(fpMax));
		}
	}

	public prepareFormValue(): FgInsuranceRequest {
		const formValue: formType = this.form.value;

		const req: FgInsuranceRequest = {
			...formValue,
			SelectedAdvisers: formValue.SelectedAdvisers?.map(x => +x),
			SelectedAltAdvisers: formValue.SelectedAltAdvisers?.map(x => +x),
			NextActivityDateMin: MomentUtil.formatToServerDate(formValue.NextActivityDateMin),
			NextActivityDateMax: MomentUtil.formatToServerDate(formValue.NextActivityDateMax),
			RenewalDateMin: MomentUtil.formatToServerDate(formValue.RenewalDateMin),
			RenewalDateMax: MomentUtil.formatToServerDate(formValue.RenewalDateMax),
			FirstPolicyDateMin: MomentUtil.formatToServerDate(formValue.FirstPolicyDateMin),
			FirstPolicyDateMax: MomentUtil.formatToServerDate(formValue.FirstPolicyDateMax),
			InceptionDateMin: MomentUtil.formatToServerDate(formValue.InceptionDateMin),
			InceptionDateMax: MomentUtil.formatToServerDate(formValue.InceptionDateMax),
		};
		return req;
	}

	search() {
		const req = JSON.parse(JSON.stringify(this.prepareFormValue()));

		this.fgInsuranceUiQuery.currentSort$
			.pipe(
				first(),
				map(x => {
					req.Paging = {
						Index: 1,
						Column: x.propSort,
						Direction: x.sort,
					};
				}),
				finalize(() => {
					this.fgInsuranceService
						.search(req)
						.pipe(takeUntil(this.onDestroy$))
						.subscribe(() => {
							setTimeout(() => $('datatable-body').scrollTop(1).scrollLeft(1), 1);
							setTimeout(() => $('datatable-body').scrollTop(0).scrollLeft(0), 1);
						});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	/**
	 * Api calls (Endpoint)
	 * @returns void
	 * Downloads file in .csv format
	 */
	//////////////////////////////////// EXPORT /////////////////////////////////
	export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		this.index++;
		const req = request(
			this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
			this.prepareFormValue(),
			this.index
		);
		const newReq = omit(['Paging'], req);

		this.exportsService
			.queueExport(newReq, ExportsType.FGE)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(
						this.exportsService.getExportStatus(id)
					)
				)
			)
			.subscribe({
				next: (data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport(data.DocumentLink);
						this.hidePopover();
					}
				},
				error: () => {
					this.hidePopover();
				},
			});

		setTimeout(() => {
			this.popover.hide();
		}, 2500);
	}

	downloadExport(url: string) {
		const name = this.activitedRoute.snapshot.paramMap.get('companyCode') + '-F&G.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	clearExportProgress() {
		this.index = 0;
		this.percent = 0;
		this.progress = {
			width: '0%',
		};
		this.totalCount = 0;
	}
	//////////////////////////////////// END EXPORT /////////////////////////////////

	toggle() {
		this.showSearch = !this.showSearch;
		document.body.style.overflowY = 'hidden';
		let x = 0;
		let data;
		const setIntervalHeight = setInterval(() => {
			if (x < 1 && !data) {
				x += 1;
				data = {
					showSearch: this.showSearch,
					height: $('form').height(),
					width: $('form').width(),
				};
			} else if (x > 0) {
				x += 1;
				if (data.height !== $('form').height()) {
					data.height = $('form').height();
				} else {
					this.toggleSearch.emit(data);
					clearInterval(setIntervalHeight);
				}
			}
		}, 100);
	}

	/** Reset form fields value */
	reset() {
    this.form.get('SelectedAdvisers').reset([]);
    this.form.get('SelectedAltAdvisers').reset([]);
    this.form.get('SelectedAdviserStatuses').reset([]);
    this.form.get('SelectedProviders').reset([]);

    this.form.get('SelectedLeadOrigins').reset([]);
    this.form.get('SelectedPolicyTypes').reset([]);
    this.form.get('SelectedPolicyWriters').reset([]);

    this.form.get('SelectedPolicyStatus').reset([]);
    this.form.get('SelectedClaimStatus').reset([]);
    this.form.get('SelectedAccountStatus').reset([]);

    this.form.get('NextActivityDateMin').reset(null);
    this.form.get('NextActivityDateMax').reset(null);
    this.form.get('RenewalDateMin').reset(null);
    this.form.get('RenewalDateMax').reset(null);

    this.form.get('FirstPolicyDateMin').reset(null);
    this.form.get('FirstPolicyDateMax').reset(null);
    this.form.get('InceptionDateMin').reset(null);
    this.form.get('InceptionDateMax').reset(null);

	}

  showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}


	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

type formType = { [key in keyof FgInsuranceRequestModel]: any };
