import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DocumentState, DocumentStore } from './document.store';

import {
  CustomerDocument
} from './document.model';

@Injectable()
export class DocumentQuery extends QueryEntity<DocumentState, CustomerDocument> {
  constructor(protected store: DocumentStore) {
    super(store);
  }

  adDocuments$ = this.select(x => x.adDocuments);
  odDocuments$ = this.select(x => x.odDocuments);
  fapDocuments$ = this.select(x => x.fapDocuments);
  xdDocuments$ = this.select(x => x.xdDocuments);

  isSearching$ = this.select(x => x.isSearching);
}
