import { Asset } from './../../../crt-mortgage/client-sop/assets-and-liabilities/state/asset/asset.model';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';

@Component({
	selector: 'app-crt-asset',
	templateUrl: './crt-asset.component.html',
	styleUrls: ['./crt-asset.component.scss'],
})
export class CrtAssetComponent implements OnInit {
	@Input() assets: Asset[];
	@Input() totalValue: number;
	@Input() policyOwners$: Observable<ViewDisplayValue[]>;
	@Input() APCRTA$: Observable<ViewDisplayValue[]>;
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	isAdd: boolean;
	editMode$ = new BehaviorSubject<any>(null); // contains cRTId of asset being edited

	constructor() {}

	ngOnInit(): void {}

	trackByFn(index: number, ks: Asset): number {
		return ks.cRTId;
	}

	add() {
		this.isAdd = true;
		this.editMode$.next(null);
	}

	cancelEvent(event?) {
		this.isAdd = false;
		this.editMode$.next(null);
	}

	saveEvent(event?) {
		this.isAdd = false;
		this.editMode$.next(null);
	}

	isEditMode(value?) {
		this.isAdd = false;
		this.editMode$.next(value);
	}
}
