import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { iif, Observable, of } from 'rxjs';
import {
  concatMap,
  finalize,
  map,
  mergeMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { LoggerService } from '../../../core/logger/logger.service';
import { UploadModalComponent } from '../../../shared/modal/upload-modal/upload-modal.component';
import { convertUtil, objectUtil } from '../../../util/util';
import { DocumentUploadState } from '../intro-page-settings/state/intro-settings.model';
import { IntroSettingsMapper } from './state/intro-settings.mapper';
import { IntroSettingsService } from './state/intro-settings.service';

@Component({
  selector: 'app-intro-page-settings',
  templateUrl: './intro-page-settings.component.html',
  styleUrls: ['./intro-page-settings.component.scss'],
})
export class IntroPageSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  intro$ = this.introService.intro$;
  documentID: number;
  document: DocumentUploadState;
  tempDoc: DocumentUploadState;
  tempDocBase64: string;
  isLoading = false;

  constructor(
    private introService: IntroSettingsService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private loggerService: LoggerService
  ) {
    this.buildForm();
  }

  get IntroTypeBD() {
    return this.form.get('introTypeBD');
  }
  get IntroTypeUD() {
    return this.form.get('introTypeUD');
  }
  get IntroType() {
    return this.form.get('introType');
  }
  get Intro() {
    return this.form.get('intro');
  }
  get DocumentID() {
    return this.form.get('documentID');
  }

  ngOnInit(): void {
    this.prepData();
  }

  buildForm() {
    this.form = this.fb.group({
      introTypeBD: [false],
      introTypeUD: [false],
      introType: [null],
      type: ['IP'],
      intro: [''],
      documentID: [''],
    });
  }

  prepData() {
    this.intro$
      .pipe(
        map((data) => IntroSettingsMapper.mapToView(data)),
        tap((data) => (this.documentID = +data?.documentID)),
        tap((data) => (data ? this.form.patchValue(data) : null)),
        mergeMap((data) =>
          iif(
            () =>
              R.complement(R.isNil)(data?.documentID) ||
              data?.introType === 'UD',
            this.getIntroFile(+data?.documentID),
            of(null)
          )
        ),
        take(1)
      )
      .subscribe();
  }

  getIntroFile = (documentID) =>
    of(documentID).pipe(
      mergeMap((x) => this.introService.getFile(x)),
      map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
      tap((data) => (this.document = data)),
      take(1)
    );

  openUploadModal() {
    const upload = (req) =>
      new Observable((obs) => {
        obs.next();
        obs.complete();
      }).pipe(
        map(() => req),
        tap((x) => this.addTempDocument(x)),
        mergeMap((x) => convertUtil.convertToBase64(x[0])),
        tap((x) => (this.tempDocBase64 = x))
      );

    const initialState = {
      customUpload: upload,
      isSingleUpload: true,
      isFileList: true,
      headerTitle: 'Upload Intro Document',
      additionalInfo: 'Upload PDF or Image',
			restrict: '.pdf, image/jpg, image/jpeg, image/png',
			allowedFileExtensions: ['pdf', 'jpg', 'jpeg', 'png'],
    };

    this.modalService.show(UploadModalComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState,
      ignoreBackdropClick: true,
    });
  }

  addTempDocument(data) {
    if (R.complement(R.either(R.isNil, R.isEmpty))(data)) {
      this.tempDoc = data[0];
    }
  }

  submitForm() {
    const formValues = IntroSettingsMapper.mapToUpsert(
      this.form.getRawValue(),
      this.tempDoc
    );

    if (formValues.introType === 'UD' && (R.isNil(this.tempDocBase64) && !formValues.documentID)) {
      this.loggerService.Warning(null, logMessage.settings.warning.documentEmpty);
      return;
    }

    of(formValues)
      .pipe(
        tap(() => (this.isLoading = true)),
        concatMap((x) =>
          iif(
            () => x?.introType === 'UD' && (!formValues.documentID || !(R.isNil(this.tempDocBase64))),
            this.uploadDocument(formValues),
            of(x).pipe(
              tap(() => this.clearTempDoc()),
              take(1)
            )
          )
        ),
        concatMap((x) => this.introService.updateIntroSettings(x)),
        concatMap((x) =>
          iif(
            () => formValues?.introType === 'UD',
            this.reloadDocument(),
            of(x)
          )
        ),
        finalize(() => {
          this.clearTempDoc();
          this.isLoading = false;
        }),
        take(1)
      )
      .subscribe();
  }

  reloadDocument = () =>
    of(this.documentID).pipe(
      concatMap((x) => this.getIntroFile(+x)),
      take(1)
    );

  uploadDocument = (formValues) =>
    of(this.documentID).pipe(
      withLatestFrom([this.tempDoc], [this.tempDocBase64]),
      map(([id, doc, base64]) =>
        IntroSettingsMapper.mapDocumentUpload(doc, base64)
      ),
      concatMap((data) => this.introService.newFileUpload(data)),
      tap((res) => (this.documentID = +res)),
      tap((res) => this.DocumentID.setValue(+res)),
      mergeMap((res) => of({ ...formValues, documentID: +res })),
      take(1)
    );

  clearTempDoc() {
    this.tempDoc = null;
    this.tempDocBase64 = null;
  }

  toggleIntroTypeUD() {
    this.IntroType.setValue('BD');
    this.IntroTypeUD.setValue(false);
  }

  toggleIntroTypeBD() {
    this.IntroType.setValue('UD');
    this.IntroTypeBD.setValue(false);
  }
}
