import { ServicesCodes } from 'src/app/shared/models/services/services.model';

export const staticConf = {
	/** API URL. Used to prepend endpoint partial link */
	apiUrl: window.location.host,
	/** Format of date to send to API */
	serverDateFormat: 'YYYY-MM-DD',
	/** Format of date with time to send to API */
	serverDatetimeFormat: 'YYYY-MM-DDTHH:mm',
	/** Format of date with time:second to send to API */
	serverDatetimeSecondFormat: 'YYYY-MM-DDTHH:mm:ss',
	/** Format date to display */
	displayDateFormat: 'DD/MM/YYYY',
	/** Format date with time to display */
	displayDatetimeFormat: 'DD/MM/YYYY HH:mm',
	/** Format date to display with no year */
	displayDateFormatNoYear: 'DD/MM',
	/** Format time only to display */
	displayTimeOnlyFormat: 'HH:mm',
	/** Moment compatible timezone */
	momentTimezone: 'Pacific/Auckland',
	/** Dropdown codes to be excluded
	 * from choices of editable dropdown values
	 */
	excludedDropdownCodes: [
		'LRPR',
		'FGPL',
		'LRPS',
		'LRPT',
		'MS',
		'MT',
		'KS',
		'PCIF',
		'PCCF',
		'SCIF',
		'SCTF',
		'RT',
		'FGS',
	],
	/** Used in Mortgage form.
	 * If in this list, show a field (Expire date)
	 */
	LoanTypeValuesToShowExpiryDateField: ['Interest Only'],

	/** L&R service code */
	lrServiceCode: ServicesCodes.LR,
	/** Mortgage service code */
	mortgageServiceCode: ServicesCodes.Mortgage,
	/** Security service code */
	securityServiceCode: 'S',
	/** F&G service code */
	fgServiceCode: ServicesCodes.FG,
	/** Kiwisaver service code */
	kiwisaverServiceCode: ServicesCodes.KiwiSaver,
	/** Investment service code */
	investmentServiceCode: ServicesCodes.Investment,
	/** Advice Process service code */
	adviceProcessCode: ServicesCodes.AdviceProcess,
	/** CRT code */
	clientReviewTemplateCode: 'CRT',
	/** others document code */
	otherDocumentsCode: 'O',
	/** Mortgage Online Advice Tool code */
	mortgageOnlineAdviceToolCode: 'MOAT',
	/** KiwiSaver Online Advice Tool code */
	kiwisaverOnlineAdviceToolCode: 'KOAT',
	/** Customer Portal MOAT code */
	customerPortalMOATCode: 'CPMOAT',
	/** Client Portal MOAT Document code */
  customerPortalDocuments: 'CPMOATD',
  /** Client Portal LOAT Document code */
  customerPortalLOATCode: 'LOAT',
  /** Client Alteration Request code */
	clientAlterationRequest: 'CAR',

	/** Usual name when showing L&R */
	lrServiceHeader: 'L&R Insurance',
	/** Usual name when showing Mortgage */
	mortgageServiceHeader: 'Mortgages',
	/** Usual name when showing F&G */
	fgServiceHeader: 'F&G Insurance',
	/** Usual name when showing Kiwisaver */
	kiwisaverServiceHeader: 'KiwiSaver',

	/** metakey used to get from settings */
	lrSearchColumnsMetakey: 'LR Search Columns',
	lrSearchColumnsWidthMetakey: 'LR Search Columns Width',
	mortgageSearchColumnsMetakey: 'Mortgage Search Columns',
	mortgageSearchColumnsWidthMetakey: 'Mortgage Search Columns Width',
	fgSearchColumnsMetakey: 'FG Search Columns',
	fgSearchColumnsWidthMetakey: 'FG Search Columns Width',
	kiwiSaverSearchColumnsMetakey: 'KiwiSaver Search Columns',
	kiwisaverColumnsWidthMetaKey: `KiwiSaver Search Columns Width`,

	// Misc
	pageDefaultSize: 5,
	widgetMinSizeX: 400,
	widgetMinSizeY: 500,

	googleMapApi: 'https://www.google.com/maps/search/?api=1&query=',

	leadSearchColumnsMetaKey: `Lead Search Columns`,
	leadSearchColumnsWidthMetaKey: `Lead Search Columns Width`,
	clientSearchColumnsMetaKey: `Customer Search Columns`,
	clientSearchColumnsWidthMetaKey: `Customer Search Columns Width`,
	companySearchColumnsMetaKey: `Company Search Columns`,
	companySearchColumnsWidthMetaKey: `Company Search Columns Width`,
	pendingActivityColumnsWidthMetaKey: `Activity Pending Columns`,
	activityColumnsMetaKey: `Activity Columns`,
	activityColumnsWidthMetaKey: `Activity Columns Width`,

	dashboardWidgetConfig: 'Dashboard Widget Config',
	activitiesWidgetColumns: 'Activities Widget Columns',

	contactTypes: [
		{ value: 'L', display: 'Lead' },
		{ value: 'C', display: 'Client' },
		{ value: 'X', display: 'Ex-Client' },
	],
	businessPageColumnsMetaKey: 'Business Page Columns',
	businessPageColumnsWidthMetaKey: `Business Page Columns Width`,

	newZealandLocale: 'en-NZ',

	newZealandCurrencyCode: 'NZD'

};
