<mat-icon class="tap-icon" svgIcon="add"></mat-icon>
<ul 
  *ngIf="popoverIsShown$ | async" 
  #dropdown 
  class="dropdown" 
  [ngStyle]="{'left': positionPopup().x + 'px', 'top': positionPopup().y + 'px'}"
>
  <li class="nav-item" [routerLink]="leadClientAddLink$ | async">
    <div>
      <mat-icon class="tap-icon menu-icon" svgIcon="leads"></mat-icon>
      <a
        class="nav-link"
        >Add a Lead, Personal File</a
      >
    </div>
  </li>
  <li class="nav-item" [routerLink]="leadBusinessAddLink$ | async">
    <div>
      <mat-icon class="tap-icon menu-icon" svgIcon="business-lead"></mat-icon>
      <a
        class="nav-link"
        >Add a Lead, Business File</a
      >
    </div>
  </li>
  <li class="nav-item" (click)="createActivityModal()">
    <div>
      <mat-icon class="tap-icon menu-icon" svgIcon="activities"></mat-icon>
      <a
        class="nav-link"
        >Add Activity</a
      >
    </div>
  </li>
</ul>
