import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { complement, either, isNil, isEmpty } from 'ramda';
import { Observable, of, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { RiskProfileMapper } from '../../../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.mapper';
import { InsurancePreferenceState } from '../../../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { ClientReviewTemplateQuery } from '../../../states/client-review-template.query';
import { RiskProfileService } from '../../../states/risk-analysis/risk-profile/risk-profile.service';

declare var $: any;

@Component({
	selector: 'app-insurance-preference',
	templateUrl: './insurance-preference.component.html',
	styleUrls: ['./insurance-preference.component.scss'],
})
export class InsurancePreferenceComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	@Input() isAdviceProcessEnded: boolean;

	@Input() insurancePreferences: InsurancePreferenceState[];
	@Input() APCRTIP$: Observable<ViewDisplayValue[]>;
	@Input() cRTId: number;
	@Input() activePerson: PeopleState;

	elseMinus = true;
	form: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder,
		private riskProfileService: RiskProfileService,
		private crtQuery: ClientReviewTemplateQuery
	) {
		this.buildForm();
	}

	get DetailsList() {
		return this.form.get('detailsList') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		of(this.insurancePreferences)
			.pipe(
				tap((data) => {
					if (complement(either(isNil, isEmpty))(data)) {
						data?.map((item) => this.addItem(item));
					} else {
						this.addItem();
					}
					this.form.markAllAsTouched();
					if (this.isAdviceProcessEnded) {
						this.form.disable();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	addItem(data?: InsurancePreferenceState, isAdd: boolean = false) {
		this.DetailsList?.push(
			this.fb.group({
				dropdown: data ? data.dropdown : '',
				value: data ? data.value : '',
				isNew: isAdd,
			})
		);

		const isRequired =
			this.crtQuery.getValue()?.riskProfile?.insurancePreferences?.length ===
				0 ||
			this.crtQuery
				.getValue()
				?.riskProfile?.insurancePreferences?.every((x) => !x.dropdown);

		this.onSelectionChange(isRequired);
		this.form.markAllAsTouched();
	}

	addNewItem() {
		this.addItem(null, true);
	}

	deleteItem(index: number) {
		this.DetailsList.removeAt(index);
		this.onChange();
	}

	selectItem(index: number) {
		this.DetailsList.controls[index].get('value').setValue('');
		this.onChange();
	}

	onChange() {
		const getData = RiskProfileMapper.mapPreferenceToUpsert(
			this.form.getRawValue().detailsList
		);

		this.riskProfileService.updateRiskProfileState({
			insurancePreferences: getData,
		});

		const isRequired =
			this.crtQuery.getValue()?.riskProfile?.insurancePreferences?.length ===
				0 ||
			this.crtQuery
				.getValue()
				?.riskProfile?.insurancePreferences?.every((x) => !x.dropdown);

		this.onSelectionChange(isRequired);
		this.form.markAllAsTouched();
	}

	onSelectionChange(isRequired: boolean) {
		if (isRequired) {
			this.DetailsList.controls.forEach((e) =>
				e.get('dropdown').setValidators(Validators.required)
			);
			this.DetailsList.controls.forEach((e) =>
				e.get('dropdown').updateValueAndValidity()
			);
		} else {
			this.DetailsList.controls.forEach((e) =>
				e.get('dropdown').clearValidators()
			);
			this.DetailsList.controls.forEach((e) =>
				e.get('dropdown').updateValueAndValidity()
			);
		}
	}

	buildForm() {
		this.form = this.fb.group({
			detailsList: this.fb.array([]),
		});
	}

	collapseMore() {
		$('#collapse').toggle();
		this.elseMinus = false;
	}

	collapseLess() {
		$('#collapse').toggle();
		this.elseMinus = true;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
