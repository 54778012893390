<div class="loan-refinance mb-4">
	<div class="row">
		<div class="col-12 col-lg-3 loan-refinance__header font-weight-bold mb-3">
			Loans to Refinance
		</div>
		<div class="col-12 col-lg-3 d-none d-lg-block"></div>
		<div class="col-12 col-lg-3 d-none d-lg-block"></div>
		<div class="col-12 col-lg-3 d-none d-lg-block ml-0 pl-0 text-center">Is Refinance?</div>
	</div>

	<div class="loan-refinance__body mb-4">
		<ng-container *ngIf="isLoading$ | async; else showForm">
			<app-manual-loader-mini [isLoading]="true"> </app-manual-loader-mini>
		</ng-container>
		<ng-template #showForm>
			<app-loan-refinance-form
				[parentCRTId]="parentCRTId"
				[isLoading$]="isLoading$"
				[loanRefinance$]="loanRefinance$"
				[borrowers$]="borrowers$"
				[securities$]="securities$"
				[addFn$]="addRefinance"
				[updateFn$]="updateRefinance"
				[MP$]="MP$"
				[APCRTF$]="APCRTF$"
				[MLT$]="MLT$"
				[getOwnerChoices]="getOwnerChoices"
			>
			</app-loan-refinance-form>
		</ng-template>
	</div>
</div>
