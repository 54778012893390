import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { EBLRState, EBLRStore } from './eblr.store';

@Injectable()
export class EBLRQuery extends Query<EBLRState> {

  /**
   * Indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * Error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Select Data
   */
  data$ = this.select(state => state.EBLROpportunity);

  constructor(protected store: EBLRStore) {
    super(store);
  }
}
