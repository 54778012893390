import { Injectable } from '@angular/core';
import produce from 'immer';
import { ApiService } from '../../../../../../core/base/api.service';
import { PStore } from './p.store';
import { applyTransaction } from '@datorama/akita';

/**
 * Pipeline service for fetching different pipeline data
 */
@Injectable()
export class PService {
  /** CONSTANTS */
  private readonly PipelineWidgetendpoint = 'widgets?type=P';

  constructor(
    private api: ApiService,
    private store: PStore
  ) { }

  /**
   * Fetch and store All pipeline data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetAllPipelines(adviserIdList: string[]): void {
    this.store.setIsServiceLoading(true);
    this.store.setLRLoading(true);
    this.store.setMortgageLoading(true);
    this.store.setKiwisaverLoading(true);
    this.store.setInvestmentLoading(true);
    this.store.setFGLoading(true);
    this.api.post<any>(this.PipelineWidgetendpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.LRState.LRPipelineStats = res?.LRP;
        draft.MortgageState.MortgagePipelineStats = res?.MP;
        draft.KiwisaverState.KiwisaverPipelineStats = res?.KP;
        draft.InvestmentState.InvestmentPipelineStats = res?.IP;
        draft.FGState.FGPipelineStats = res?.FGP;
      })),
      err => applyTransaction(() => {
          this.store.update(produce(draft => {
            draft.LRState.error = 'Error fetching data!';
          }));
          this.store.setLRLoading(false);
          this.store.update(produce(draft => {
            draft.MortgageState.error = 'Error fetching data!';
          }));
          this.store.setMortgageLoading(false);
          this.store.update(produce(draft => {
            draft.KiwisaverState.error = 'Error fetching data!';
          }));
          this.store.setKiwisaverLoading(false);
          this.store.setInvestmentLoading(false);
          this.store.update(produce(draft => {
            draft.FGState.error = 'Error fetching data!';
          }));
          this.store.setFGLoading(false);
          this.store.setIsServiceLoading(false);
        }),
      () => {
        this.store.setLRLoading(false);
        this.store.setMortgageLoading(false);
        this.store.setKiwisaverLoading(false);
        this.store.setInvestmentLoading(false);
        this.store.setFGLoading(false);
        this.store.setIsServiceLoading(false);
      }
    );
  }

  /**
   * Fetch and store LR pipeline data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */

  /**
   * Fetch and store Mortgage pipeline data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */

  /**
   * Fetch and store Kiwisaver pipeline data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
}
