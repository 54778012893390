export const loatRiskAnalysisMetaKey = {
	// LOAT Risk Analysis Notes
	lifeNotes: 'LOAT_LIFE_NOTES',
	disabilityNotes: 'LOAT_DISABILITY_NOTES',
	tpdNotes: 'LOAT_TPD_NOTES',
	traumaNotes: 'LOAT_TRAUMA_NOTES',
	medicalNotes: 'LOAT_MEDICAL_NOTES',
};

export const loatRiskAnalysisSosNotes = {
	// LOAT Risk Analysis Notes
	lifeInsurance: 'LOAT_LIFE_NOTES',
	criticalIllness: 'LOAT_DISABILITY_NOTES',
	disabilityInsurance: 'LOAT_TPD_NOTES',
	tPDInsurance: 'LOAT_TRAUMA_NOTES',
	medicalInsurance: 'LOAT_MEDICAL_NOTES',
};
export const loatRiskAnalysisPageIds = Object.keys(loatRiskAnalysisSosNotes);
export const loatRiskAnalysisIds = Object.values(loatRiskAnalysisMetaKey);

export const loatRiskAnalayisMT = [
	{
		metaKey: loatRiskAnalysisMetaKey.lifeNotes,
		description: 'LOAT > Risk Analysis > Life Notes',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: loatRiskAnalysisMetaKey.disabilityNotes,
		description: 'LOAT > Risk Analysis > Disability Notes',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: loatRiskAnalysisMetaKey.tpdNotes,
		description: 'LOAT > Risk Analysis > TPD Notes',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: loatRiskAnalysisMetaKey.traumaNotes,
		description: 'LOAT > Risk Analysis > Trauma Notes',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: loatRiskAnalysisMetaKey.medicalNotes,
		description: 'LOAT > Risk Analysis > Trauma Notes',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
];
