import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { omit } from 'ramda';
import {
	BehaviorSubject,
	concat,
	EMPTY,
	from,
	iif,
	Observable,
	of,
} from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	finalize,
	map,
	mergeMap,
	reduce,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import {
	AdviceProcessSectionCodes,
	FSSubSectionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import {
	ClientAcceptance,
	ClientAcceptanceState,
} from 'src/app/shared/models/client-review-template/client-acceptance/client-acceptance.model';
import { CurrentInsuranceState } from 'src/app/shared/models/client-review-template/current-insurance/current-insurance.model';
import { DocumentModel } from 'src/app/shared/models/client-review-template/document/document.model';
import {
	AcceptedNewStructureState,
	FinalStructureState,
} from 'src/app/shared/models/client-review-template/final-structure/final-structure.model';
import { DocumentTypes } from 'src/app/shared/models/documents/document.model';
import { objectUtil, convertUtil } from 'src/app/util/util';
import { ProposedInsuranceState } from '../../../../../shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { ToPdfService } from 'src/app/shared/services/to-pdf/to-pdf.service';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ExistingPolicyStructureService } from '../existing-policy-structure/existing-policy-structure.service';
import * as R from 'ramda';

@Injectable({
	providedIn: 'root',
})
export class FinalStructureService extends ClientReviewTemplateService {
	finalStructure$ = this.query.select((x) => x.finalStructure);
	formValue$ = this.query.select((x) => x.finalStructureFormValue);
	formValue = this.query.getValue()?.finalStructureFormValue;
	proposedInsurance$ = this.query.select((x) => x.fsProposedInsurance);

	public underWritingOutcomeSubj = new BehaviorSubject(null);
	public readonly services$ = this.underWritingOutcomeSubj.asObservable();

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		private toPdfService: ToPdfService,
		private existingPolicyStructure: ExistingPolicyStructureService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getFinalStructure(adviceProcessId: number) {
		this.store.setIsLoadingFinalStructure(true);
		return this.api.get<any>(`crt/${adviceProcessId}/FS`).pipe(
			map((x) => {
				const firstOrNull = R.propOr(null, 0 as any);
				return firstOrNull(x);
			}),
			map((x) =>
				!!x
					? (objectUtil.mapPascalCaseToCamelCase(x) as FinalStructureState)
					: null
			),
			tap((x) =>
				applyTransaction(() => {
					this.store.update({ finalStructure: x });
				})
			),
			switchMap(() => this.getProposedInsurance(adviceProcessId)),
			switchMap(() => this.existingPolicyStructure.get(adviceProcessId)),
			finalize(() => this.store.setIsLoadingFinalStructure(false)),
			catchError(() => of(undefined))
		);
	}

	// Final Structure
	getAndGenerateFinalStructure(adviceProcessId: number) {
		this.store.setIsLoadingFinalStructure(true);
		return this.api.get<any>(`crt/${adviceProcessId}/FS`).pipe(
			map((x) =>
				!!x && x?.length > 0 && !!x[0]
					? (objectUtil.mapPascalCaseToCamelCase(x[0]) as FinalStructureState)
					: null
			),
			tap((x) =>
				applyTransaction(() => {
					this.store.update({ finalStructure: x });
				})
			),
			switchMap((x) =>
				iif(
					() => !x,
					this.api.get<ClientAcceptance[]>(`crt/${adviceProcessId}/CA`),
					of(null)
				)
			),
			map((x) =>
				!!x && x?.length > 0 && !!x[0]
					? (objectUtil.mapPascalCaseToCamelCase(x[0]) as ClientAcceptanceState)
					: null
			),
			switchMap((x) =>
				iif(
					() => !!x,
					this.addFinalStructure(
						{
							sectionCode: AdviceProcessSectionCodes.FinalStructure,
							adviceProcessId,
						},
						x
					),
					of(undefined)
				)
			),
			withLatestFrom(this.finalStructure$),
			switchMap(([, x]) =>
				iif(
					() => !!x,
					this.syncFinalStructure(adviceProcessId),
					of(x).pipe(
						delay(500),
						switchMap(() => this.getProposedInsurance(adviceProcessId)),
						switchMap(() => this.existingPolicyStructure.get(adviceProcessId))
					)
				)
			),
			finalize(() => this.store.setIsLoadingFinalStructure(false)),
			catchError(() => of(undefined))
		);
	}

	addFinalStructure(
		data: FinalStructureState,
		clientAcceptance: ClientAcceptanceState
	) {
		return this.api
			.post<number>(`crt`, objectUtil.mapCamelCaseToPascalCase(data))
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						this.store.update({ finalStructure: { ...data, cRTId: x } });
					})
				),
				catchError(() => EMPTY)
			);
	}

	updateFinalStructure(data: FinalStructureState) {
		this.store.update({
			isUpdatingFinalStructure: true,
		});

		const oldData = this.query.getValue().finalStructure;
		const body: FinalStructureState = {
			...oldData,
			isCompleted: data?.isCompleted ?? oldData.isCompleted,
			document: data?.document ?? oldData?.document,
			underwritingOutcome:
				data?.underwritingOutcome ?? oldData?.underwritingOutcome,
			isEmailSent: data?.isEmailSent ?? oldData.isEmailSent,
			paymentFrequency: data?.paymentFrequency ?? oldData.paymentFrequency,
		};
		return this.api
			.put(`crt/${data.cRTId}`, objectUtil.mapCamelCaseToPascalCase(body))
			.pipe(
				tap(() => this.store.update({ finalStructure: body })),
				catchError(() => EMPTY),
				finalize(() => {
					this.store.update({
						isUpdatingFinalStructure: false,
					});
				})
			);
	}

	generateProposeInsurance(
		adviceProcessId: number,
		sectionCode: string,
		cRTId: number
	) {
		return this.getProposedInsurance(adviceProcessId, sectionCode).pipe(
			mergeMap((x) =>
				iif(
					() => x && x.length > 0,
					from(x).pipe(
						mergeMap((pi) =>
							this.addProposedInsurance(
								{
									...pi,
									cRTId: 0,
									parentCRTId: cRTId,
									sectionCode: FSSubSectionCodes.ProposedInsurance,
									policyDocumentsList: null,
								} as any,
								adviceProcessId
							)
						)
					),
					of(x)
				)
			),
			catchError(() => EMPTY)
		);
	}

	// FS - Propose insuranceoad
	getProposedInsurance(
		adviceProcessId: number,
		sectionCode: string = FSSubSectionCodes.ProposedInsurance
	) {
		return this.api.get<any>(`crt/${adviceProcessId}/${sectionCode}`).pipe(
			map(
				(x) =>
					x?.map(
						objectUtil.mapPascalCaseToCamelCase
					) as ProposedInsuranceState[]
			),
			tap((x) =>
				applyTransaction(() => {
					if (sectionCode === FSSubSectionCodes.ProposedInsurance) {
						this.store.update({ fsProposedInsurance: x });
					}
				})
			),
			catchError(() => EMPTY)
		);
	}

	addProposedInsurance(
		proposedInsurance: CurrentInsuranceState | AcceptedNewStructureState,
		adviceProcessId?: number
	) {
		const newCurrentInsurance = Object.assign(
			{},
			omit(['policyDocumentFormData', 'policyDocumentsName'], proposedInsurance)
		);
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(newCurrentInsurance);
		body.AdviceProcessId = adviceProcessId;

		return this.api.post3<number>(endpoint, body).pipe(
			tap((id) =>
				applyTransaction(() => {
					const ci = this.query.getValue().fsProposedInsurance ?? [];

					const data = [
						...ci,
						{
							...(proposedInsurance as AcceptedNewStructureState),
							cRTId: id,
						},
					]?.filter((x) => x.cRTId && x.cRTId !== 0);

					this.store.update({ fsProposedInsurance: data });
				})
			),
			catchError(() => EMPTY)
		);
	}
	updateProposedInsurance(proposedInsurance: ProposedInsuranceState) {
		const newCurrentInsurance = Object.assign(
			{},
			omit(
				['policyDocumentFormData', 'policyDocumentsName', 'LideAssuredList'],
				proposedInsurance
			)
		);
		const endpoint = `crt/${proposedInsurance.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(newCurrentInsurance);
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<string>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					const data = this.query.getValue().fsProposedInsurance?.map((y) =>
						y.cRTId === proposedInsurance.cRTId
							? ({
									...proposedInsurance,
									policyDocuments:
										typeof proposedInsurance?.policyDocuments === 'string'
											? JSON.parse(proposedInsurance?.policyDocuments)
											: proposedInsurance?.policyDocuments,
							  } as ProposedInsuranceState)
							: y
					);
					this.store.update({ fsProposedInsurance: data });
				})
			),
			map(() => proposedInsurance.cRTId),
			catchError(() => EMPTY)
		);
	}

	uploadProposeInsurance(
		proposedInsurance: ProposedInsuranceState,
		crtId?: number,
		clientId?: number
	) {
		const uploadDocs: any[] = [];
		for (const data of proposedInsurance.policyDocumentFormData.entries()) {
			uploadDocs.push(data[1]);
		}

		const first$ = of({
			ReferenceId: crtId,
			Document: '',
			FileName: uploadDocs ? convertUtil.imageFilenameToPDF(uploadDocs[0]) : '',
			Type: DocumentTypes.Upload,
		});

		const documentIds = [];

		return first$.pipe(
			switchMap(() =>
				concat(
					first$,
					from(uploadDocs).pipe(
						mergeMap(
							(x: File) =>
								iif(
									() => x.type.startsWith('image/'),
									this.toPdfService.fromImage(x),
									convertUtil.toBase64(x)
								),
							(file: File, content: any) => [file, content]
						),
						map(([, content]) => {
							return {
								ReferenceId: crtId,
								Document: content.content,
								FileName: content.filename,
								Type: DocumentTypes.Upload,
								DocumentType: ServicesCodes.LR,
								DocumentTypeCode: ServicesCodes.LR,
								// DocumentTypeCode: DocumentTypes.AdviceProcess,
								CustomerId: clientId,
							};
						}),
						concatMap((req2) => this.api.post('documents', req2))
					)
				)
			),
			reduce((acc, v) => documentIds.push(v)),
			map((ids) =>
				applyTransaction(() => {
					const data = [...this.query.getValue().fsProposedInsurance];
					data?.forEach((i) => {
						if (i.cRTId === crtId) {
							const docs = [];
							documentIds?.forEach((a, val) => {
								docs?.push({
									referenceId: a,
									value: convertUtil.imageFilenameToPDF(uploadDocs[val]),
								});
							});
							i.policyDocumentsName = proposedInsurance.policyDocumentsName;
							const list =
								i.policyDocumentsList && i.policyDocumentsList.length > 0
									? [...i.policyDocumentsList, ...docs]
									: docs;
							i.policyDocuments = list;
							i.policyDocumentsList = list;
						}
					});
					this.store.update({ fsProposedInsurance: data });
					return ids;
				})
			),
			catchError(() => EMPTY)
		);
	}
	deleteProposedInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.fsProposedInsurance?.filter((y) => y.cRTId !== id);
					this.store.update({ fsProposedInsurance: data });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	uploadProposedInsuranceDocs(newCRTId: number, prevCRTID: number) {
		let document: DocumentModel;
		return this.getDocumentByRefId(prevCRTID).pipe(
			mergeMap((x: any) =>
				from(x ?? []).pipe(
					map((doc: any) => {
						document = doc;
						return document;
					}),
					mergeMap((doc) =>
						this.api.getExternalResource(doc.DocumentLink, {
							responseType: 'blob',
						})
					),
					mergeMap(
						() =>
							iif(
								() => x[0].type.startsWith('image/'),
								this.toPdfService.fromImage(x[0]),
								convertUtil.convertToBase64(x)
							),
						(o, i) => [o, i]
					),
					map(([o, i]) => {
						return {
							ReferenceId: newCRTId,
							Document: i.content,
							FileName: document.DocumentName,
							Type: DocumentTypes.Upload,
						};
					}),
					mergeMap((val) => this.api.post('documents', val))
				)
			),
			catchError(() => EMPTY)
		);
	}

	syncFinalStructure(adviceProcessId: number) {
		return this.api
			.post<any>(`crt/sync/advice-process/${adviceProcessId}`, null)
			.pipe(
				delay(500),
				mergeMap(() => this.getProposedInsurance(adviceProcessId)),
				mergeMap(() => this.existingPolicyStructure.get(adviceProcessId)),
				catchError((x) => of(x))
			);
	}

	getDocumentByRefId(cRTId: number) {
		return this.api.get<DocumentModel[]>(`documents/reference/${cRTId}/CRTCLR`);
	}

	setFormValue(data: FinalStructureState) {
		this.setHasFormChanges(true);
		this.store.setFinalStructureFormValue(data);
	}

	downloadLink(referenceId: number) {
		return this.api.get<string>(`documents/download/${referenceId}`);
	}

	uploadOfferOfTerms(
		offerOfTerms: {
			Document: string;
			FileName: string;
			DocumentTypeCode?: string;
		},
		proposedInsurance: ProposedInsuranceState
	) {
		return this.api
			.post3<number>(
				`crt/${proposedInsurance?.cRTId}/document/CRTT`,
				offerOfTerms
			)
			.pipe(
				concatMap((x) =>
					this.updateProposedInsurance({
						...proposedInsurance,
						documentTerm: { referenceId: x, value: offerOfTerms?.FileName },
					})
				),
				catchError(() => EMPTY)
			);
	}
}
