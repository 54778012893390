import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { RouteService } from '@core/config/route.service';
import { ApplicationDocumentService } from '../../../crt-mortgage/application/application-steps/documents/state/documents.service';
import { CrtMortgageService } from '../../../crt-mortgage/state/crt-mortgage.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Input() pageHeader = '';
	@Input() pageHeaderCustomClass = '';
	@Input() manualLoading: boolean;
	@Input() callbackFn$: (data) => Observable<any>;
	private onDestroy$ = new Subject<void>();

	hasReviewApplicationChange = false;
	reviewApplicationChangeValue = null;
	loading = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private routeService: RouteService,
		private documentService: ApplicationDocumentService,
		private crtService: CrtMortgageService
	) {}

	ngOnInit(): void {
		this.route.params.subscribe(() => this.init());
	}

	init() {
		combineLatest([
			this.crtService.hasReviewApplicationChanges$,
			this.crtService.reviewApplicationChangeValue$,
			this.crtService.reviewApplicationShowModal$,
		])
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(([hasChange, value]) => {
				this.hasReviewApplicationChange = hasChange;
				this.reviewApplicationChangeValue = value;
				if (hasChange && (value === 'confirm' || value === 'decline')) {
					this.goToCrm();
				}
			});
	}

	pauseAndExit() {
		if (this.callbackFn$) {
			this.loading = true;
			this.callbackFn$(true)
				.pipe(
					finalize(() => {
						this.goToCrm();
					}),
					take(1)
				)
				.subscribe(() => {
					this.loading = false;
				});
		} else {
			if (this.pageHeader === 'Application' &&
				this.hasReviewApplicationChange &&
				this.reviewApplicationChangeValue === null
			) {
				this.init()
			} else {
				this.goToCrm();
			}
		}
	}

	goToCrm() {
		let redirectRoute = [];
		const clientId = +this.route.snapshot.paramMap.get('clientId');
		const apId = +this.route.snapshot.paramMap.get('adviceProcessId');

		if (
			this.hasReviewApplicationChange &&
			this.reviewApplicationChangeValue === null
		) {
			this.crtService.setReviewApplicationModal(true);
		} else {
			if (this.pageHeader === 'Application') {
				this.documentService.shouldUpdateDocumentSort.next(true);
			}

			redirectRoute = this.routeService.customerAdviceProcess(clientId, apId);
			this.router.navigate(redirectRoute);
		}
	}

	ngOnDestroy() {
		this.crtService.setReviewApplicationChangeValue(null);
		this.crtService.setHasReviewApplicationChanges(false);
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
