import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Resolve } from '@angular/router';
import { TLStaffSecGroupsService } from '../../../../domain/tl-staff-security-group/tl-staff-security-groups.service';

@Injectable()
export class TlSecGroupsResolver implements Resolve<boolean> {

  resolve(): Observable<boolean> {
    const secGroups$ = this.tlStaffSecGroupsService.addList();
    return forkJoin([secGroups$]).pipe(mapTo(true));
  }

  constructor(private tlStaffSecGroupsService: TLStaffSecGroupsService) { }
}
