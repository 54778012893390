import { HouseInsuranceState } from './house-insurance.model';
import { util } from '../../../../core/util/util.service';
import MomentUtil from '../../../../util/moment.util';

export class HouseInsuranceMapper {
	/** Mapping for view */
	public static mapToView(data: any){
		return {
            ...data,
			lastReviewed: util.isValidMoment(data.lastReviewed) ? data.lastReviewed : MomentUtil.formatDateToMoment(data.lastReviewed),
			date: util.isValidMoment(data.date) ? data.lastReviewed : MomentUtil.formatDateToMoment(data.date)
		};
	  }

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: HouseInsuranceState, cid?: number, adviceProcessId?: number): HouseInsuranceState {
		return {
			...data,
			lastReviewed: MomentUtil.formatDateToServerDate(data.lastReviewed),
			date: MomentUtil.formatDateToServerDate(data.date),
			cRTId: cid,
      cost: +data.cost,
      currentExcess: +data.currentExcess,
      replacementValue: +data.replacementValue,
      premium: +data.premium,
      adviceProcessId,
      sectionCode: 'FFGHI'
    };
	}
}
