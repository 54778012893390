<form [formGroup]="form" novalidate>
	<div class="container-fluid clearfix px-0">
		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg col-12"></div>
			<div class="col-lg-auto col-12 d-flex action-btns justify-content-end">
				<span class="position-relative" *ngIf="isSaving$ | async">
					<app-manual-loader-mini [isLoading]="isSaving$ | async" class="p-0"></app-manual-loader-mini>
				</span>
				<ng-container *ngIf="!(isSaving$ | async)">
					<!-- Restriction for lead -->
					<ng-container *ngIf="isLead">
						<ng-container *ngIf="!(isEdit$ | async)">
							<ng-container *appRestrictTo="['FEL']">
								<span class="position-relative">
									<button type="button" class="icon-btn" id="edit_{{ formID }}" (click)="edit()">
										<i class="material-icons md-20 tap-text-color-h6"> edit </i>
									</button>
								</span>
							</ng-container>
							<ng-container *appRestrictTo="['FDL']">
								<span class="position-relative" *ngIf="!disableDelete">
									<button type="button" class="icon-btn" id="delete_{{ formID }}" (click)="confirmDelete()">
										<i class="material-icons md-20 tap-text-color-h6"> delete </i>
									</button>
								</span>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="isEdit$ | async">
							<ng-container *appRestrictTo="['FAL', 'FEL']">
								<span class="position-relative" *ngIf="(isEdit$ | async) && !isSaving">
									<button type="button" class="icon-btn" id="save_{{ formID }}" (click)="save()">
										<i class="material-icons md-20 tap-text-color-h6"> save </i>
									</button>
								</span>
								<span class="position-relative" *ngIf="isEdit$ | async">
									<button
										type="button"
										class="icon-btn"
										id="cancel_{{ formID }}"
										[disabled]="isSaving$ | async"
										(click)="cancel()"
									>
										<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
									</button>
								</span>
							</ng-container>
						</ng-container>
					</ng-container>
					<!-- Restriction for current client -->
					<ng-container *ngIf="!isLead">
						<ng-container *ngIf="!(isEdit$ | async)">
							<ng-container *appRestrictTo="['FEC']">
								<span class="position-relative">
									<button type="button" class="icon-btn" id="edit_{{ formID }}" (click)="edit()">
										<i class="material-icons md-20 tap-text-color-h6"> edit </i>
									</button>
								</span>
							</ng-container>
							<ng-container *appRestrictTo="['FDC']">
								<span class="position-relative" *ngIf="!disableDelete">
									<button type="button" class="icon-btn" id="delete_{{ formID }}" (click)="confirmDelete()">
										<i class="material-icons md-20 tap-text-color-h6"> delete </i>
									</button>
								</span>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="isEdit$ | async">
							<ng-container *appRestrictTo="['FAC', 'FEC']">
								<span class="position-relative" *ngIf="(isEdit$ | async) && !isSaving">
									<button type="button" class="icon-btn" id="save_{{ formID }}" (click)="save()">
										<i class="material-icons md-20 tap-text-color-h6"> save </i>
									</button>
								</span>
								<span class="position-relative" *ngIf="isEdit$ | async">
									<button
										type="button"
										class="icon-btn"
										id="cancel_{{ formID }}"
										[disabled]="isSaving$ | async"
										(click)="cancel()"
									>
										<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
									</button>
								</span>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg-4 col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<span class="tap-required-field" *ngIf="trustName.invalid">*</span>
						<label for="trustName_{{ formID }}">Trust Name</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="trustName"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="trustName_{{ formID }}"
							tabindex="7"
						/>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="tradingName_{{ formID }}">Trading Name</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="tradingName"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="tradingName_{{ formID }}"
							tabindex="8"
						/>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<span class="tap-required-field" *ngIf="trustType.invalid">*</span>
						<label for="trustType_{{ formID }}">Trust Type</label>
					</div>
					<div class="col-lg-8 col-12">
						<span class="custom-dropdown w-100">
							<select
								formControlName="trustType"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="trustType_{{ formID }}"
								tabindex="9"
							>
								<option value=""></option>
								<option *ngFor="let d of trustTypes" [value]="d.value">{{ d.display }}</option>
							</select>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row clearfix algin-items-center mb-2">
			<div class="col-lg-2 col-12">
				<label for="">Trustee Name</label>
			</div>
			<div class="col-lg-10 col-12">
				<div class="form-row" formArrayName="fullTrustName">
					<div *ngFor="let ftName of trustNames.controls; let i = index" class="col-lg-3">
						<input
							[formControlName]="i"
							id="trustName_{{ formID }}_{{ i }}"
							class="form-control theme-crm-field-disabled mb-2 input-array"
						/>
					</div>
					<button
						type="button"
						class="tap-btn tap-btn-outline border-0 tap-text-primary px-1 py-0"
						id="addNewTrustName_{{ formID }}"
						(click)="addTrustName()"
						[disabled]="!(isEdit$ | async)"
						*ngIf="isEdit$ | async"
					>
						<i class="material-icons md-20 clickable font-weight-bold">add</i>
					</button>
				</div>
			</div>
		</div>

		<div class="form-row clearfix algin-items-center mb-2">
			<div class="col-lg-2 col-12">
				<label for="">Independent Trustee</label>
			</div>
			<div class="col-lg-10 col-12">
				<div class="form-row" formArrayName="independentTrustName">
					<div *ngFor="let itName of independentTrustNames.controls; let i = index" class="col-lg-3">
						<input
							[formControlName]="i"
							id="independentTrustName_{{ formID }}_{{ i }}"
							class="form-control theme-crm-field-disabled mb-2 input-array"
						/>
					</div>
					<button
						type="button"
						class="tap-btn tap-btn-outline border-0 tap-text-primary px-1 py-0"
						id="addNewIndependentTrustName_{{ formID }}"
						(click)="addIndependentTrustName()"
						[disabled]="!(isEdit$ | async)"
						*ngIf="isEdit$ | async"
					>
						<i class="material-icons md-20 clickable font-weight-bold">add</i>
					</button>
				</div>
			</div>
		</div>

		<ng-container *ngIf="addMode">
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-12">Add a note</div>
				<div class="col-lg-12">
					<textarea
						class="form-control theme-crm-field-disabled"
						formControlName="note"
						rows="4"
						id="note_{{ formID }}"
					></textarea>
				</div>
			</div>
		</ng-container>
	</div>
</form>

<ng-container *ngIf="!!secondaryTrust && !addMode">
	<app-table-paginate-notes
		[isLead]="isLead"
		[hasAddNote]="true"
		[customerId]="secondaryTrust?.customerID"
		[notes]="notes?.notes"
		[count]="notes?.count"
		[hasPaging]="true"
		[getFn$]="getNotes$"
		[addFn$]="addNotes$"
		[deleteFn$]="deleteNotes$"
	></app-table-paginate-notes>
</ng-container>
