import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, finalize, tap, filter } from 'rxjs/operators';
import produce from 'immer';
import * as R from 'ramda';
import { ApiService } from '../../../../../../core/base/api.service';
import { applyTransaction } from '@datorama/akita';
import { CBStore } from './cb.store';
import { ClientBirthday } from './cb.model';

@Injectable()
export class CBService {
  private readonly endpoint = 'widgets?type=CB';
  /**
   *
   */
  constructor(
    private api: ApiService,
    private store: CBStore
  ) { }


  /**
   * Fetch and store Client Birthday data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetClientBirthdays(req: {
    advisers: string[],
    dateRangeMin: string,
    dateRangeMax: string
  }): void {
    this.store.reset();
    this.store.setLoading(true);
    this.api.post<ClientBirthday[]>(this.endpoint, {
      Advisers: req.advisers,
      DateRangeMin: req.dateRangeMin,
      DateRangeMax: req.dateRangeMax
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.ClientBirthdayList = res;
      })),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
