import { QueryEntity } from '@datorama/akita';
import { SendTestEmailStore, SendTestEmailState } from './send-test-email.store';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as R from 'ramda';
import { SendTestEmailModel } from './send-test-email.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { EmailHistoryQuery } from '../../email-history/state/email-history.query';
import { EmailSettingsQuery } from '../../email-settings/state/email-settings.query';

@Injectable()
export class SendTestEmailQuery extends QueryEntity<SendTestEmailState, SendTestEmailModel> {
	settings$ = this.emailSettingsQuery.selectAll({ filterBy: x => x.IsActive > 0 });
	emailType$ = this.settings$.pipe(
		map(emailType => {
			const sc = emailType
				? R.map(
					y => ViewDisplayValue.Map(!y.EmailCode ? '' : y.EmailCode?.toString(), `${y.EmailType}`),
					emailType
				)?.sort((a, b) => a.display.localeCompare(b.display))
				: [];
			return [...sc];
		})
	);
	contentMergeTages$ = this.emailHistoryQuery.select(x => x.contentMergeTages);

	constructor(
		protected store: SendTestEmailStore,
		private emailHistoryQuery: EmailHistoryQuery,
		private emailSettingsQuery: EmailSettingsQuery
	) {
		super(store);
	}
}
