import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PeopleState } from './people.model';
import { PeopleStore } from './people.store';
import { combineLatest } from 'rxjs';
import { DependantsQuery } from '../../dependants/state/dependants.query';
import { prop, uniqBy } from 'ramda';
import { map } from 'rxjs/operators';

@Injectable()
export class PeopleQuery extends QueryEntity<PeopleState[]> {
	people$ = this.selectAll();
	
	peopleAndDependentsFromCRTOnly$ = combineLatest([
		this.people$,
		this.dependantsQuery.dependants$,
	]).pipe(
		map(([people, dependants]) => {
			const list = uniqBy(prop('customerId') as any, [
				...people,
				...dependants,
			]) as any;
			return list?.sort((item1, item2) =>
				item1.name?.localeCompare(item2.name)
			);
		})
	);

	constructor(protected store: PeopleStore, protected dependantsQuery: DependantsQuery) {
		super(store);
	}
}
