import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import {
	BehaviorSubject,
	Observable,
	Observer,
	ReplaySubject,
	Subject,
	throwError,
} from 'rxjs';
import { finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { LocalService } from 'src/app/core/services/local.service';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ActivityViewModel } from '../../shared/models/_general/activity.viewmodel';
import MomentUtil from '../../util/moment.util';
import { strUtil } from '../../util/util';
import { DateInputComponent } from '../date-input/date-input.component';
import { logMessage } from '../error-message/error-message';
import { ActivityModalComponent } from '../modal/activity/activity-modal/activity-modal.component';
import {
	DeleteModalComponent,
	DeleteModalModel,
} from '../modal/delete-modal/delete-modal.component';
import { NoteAddModalComponent } from '../modal/note-modal/note-modal.component';
import {
	ActivityState,
	NoteState,
} from '../models/activity-timeline/activity-timeline.model';
import {
	AdviceProcessPageCodes,
	AdviceProcessPageNamesByCode,
	AdviceProcessMortgagePageNamesByCode,
} from '../models/advice-process/advice-process.model';
import { CRTNoteState } from '../models/client-review-template/note/crt-note.model';
import { ViewDisplayValue } from '../models/_general/display-value.viewmodel';
import {
	ClientReferralPopupType,
	ReferClientPopupService,
} from '@shared/components/refer-client-popup/refer-client-popup.service';

declare var $: any;

@Component({
	selector: 'app-crt-notes-timeline',
	templateUrl: './crt-notes.component.html',
	styleUrls: ['./crt-notes.component.scss'],
})
export class CrtNotesComponent
	implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
	private onDestroy$ = new Subject<void>();

	@Input() crtTypes: ViewDisplayValue[];
	@Input() crtNotes$: Observable<CRTNoteState[]>;
	@Input() activeType$: Observable<string>;

	@Input() addNoteFn$: (req: string, type: string) => Observable<any>;
	@Input() editNoteFn$: (req: CRTNoteState) => Observable<any>;
	@Input() deleteNoteFn$: (req: number) => Observable<any>;
	@Input() pinNoteFn$: ({
		note,
		pin,
	}: {
		note: NoteState;
		pin: boolean;
	}) => Observable<any>;
	@Input() exportFn$: (req) => Observable<any>;

	// Activity
	@Input() location: string;
	@Input() isLead: boolean;
	@Input() customerId: number;
	@Input() customerName: string;
	@Input() activityType$: Observable<ViewDisplayValue[]>;
	@Input() activityMeeting$: Observable<ViewDisplayValue[]>;
	@Input() adviserChoices$: Observable<ViewDisplayValue[]>;
	@Input() adviserCalendarChoices$: Observable<ViewDisplayValue[]>;
	@Input() addActivityFn$: (req: ActivityViewModel) => Observable<any>;

	@Output() deleteNoteEvent = new EventEmitter<any>();
	@Output() setTypeEvent = new EventEmitter<any>();

	@Input() oatType: 'MOAT' | 'KOAT' | 'LOAT';

	@Input() adviserId: any;

	filteredCRTNotes$: Observable<CRTNoteState[]>;
	notes$ = new BehaviorSubject<NoteState[]>([]);
	isSaving$ = new ReplaySubject<boolean>(1);
	isAddingNote$ = new ReplaySubject<boolean>(1);
	isloadingNotes$ = new ReplaySubject<boolean>(1);

	isNotesForm = false;
	isActivityForm = false;
	isNoteSaving = false;
	isActivityEdit = false;
	isActivitySaving = false;
	isAddNote = false;
	isAddActivity = false;

	formItem: ActivityViewModel = null;

	formNote: UntypedFormGroup = this.fb.group({
		note: this.fb.control(null, [Validators.required]),
		type: this.fb.control(null),
	});

	formExport: UntypedFormGroup = this.fb.group({
		DateMin: [
			MomentUtil.formatToServerDateToMomentNowNz(),
			Validators.required,
		],
		DateMax: [
			MomentUtil.formatToServerDateToMomentNowNz(),
			Validators.required,
		],
		ReportType: ['csv', Validators.required],
	});

	public formData$: Observable<ActivityViewModel>;
	public readonly isEdit$: Observable<boolean>;

	pendingActiveId: number;
	completedActiveId: number;
	activeShowMoreIds: NoteState[] = [];

	currentPage = 1;
	isLastPage: boolean;
	itemsPerPage = 10;
	completedList = [];
	currentCompletedFilter: string;
	count;
	isExporting = false;

	@ViewChild('crtNotesElement') crtNotesElement: ElementRef;
	@ViewChild(PopoverDirective) popover: PopoverDirective;
	@ViewChild('minDateInput') minDateInput: DateInputComponent;
	@ViewChild('maxDateInput') maxDateInput: DateInputComponent;

	currentUserName$ = this.userQuery.userInfo$.pipe(
		map((x) => `${x.FirstName} ${x.LastName}`)
	);

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
		private userQuery: UserQuery,
		private renderer: Renderer2,
		private loggerService: LoggerService,
		private localService: LocalService,
		private referClientPopupService: ReferClientPopupService
	) {}

	setPage(event: PageChangedEvent): void {
		this.isloadingNotes$.next(true);
		setTimeout(() => {
			this.currentPage = event.page;
			const startItem = 0;
			const endItem = event.page * event.itemsPerPage;
			this.filteredCRTNotes$ = this.crtNotes$.pipe(
				tap((x) => (this.count = x.length)),
				map((x) => (x && x.length > 0 ? x?.slice(startItem, endItem) : [])),
				takeUntil(this.onDestroy$)
			);
			this.isloadingNotes$.next(false);
			this.isLastPage = Math.ceil(this.count / 10) === this.currentPage;
			this.cd.detectChanges();
		}, 100);
	}

	backToRecentNotes() {
		this.crtNotesElement.nativeElement.scrollIntoView({
			behavior: 'auto',
			block: 'start',
		});
	}

	ngOnInit() {
		this.setPage({ page: this.currentPage, itemsPerPage: this.itemsPerPage });
	}

	ngAfterViewInit() {
		const self = this;
		$('#crtNotes').on('scroll', (e) => {
			e.preventDefault();
			self.scrollHandler(e);
		});
	}

	scrollHandler(event) {
		const scrollMax = event.target.scrollHeight;
		const scrollPosFloor = Math.floor(
			event.target.scrollTop + event.target.offsetHeight
		);
		const scrollPosRound = Math.round(
			event.target.scrollTop + event.target.offsetHeight
		);
		const scrollPos = event.target.scrollTop + event.target.offsetHeight;

		if (
			scrollMax === scrollPos ||
			scrollMax === scrollPosFloor ||
			scrollMax === scrollPosRound
		) {
			const totalPage = Math.ceil(this.count / 10);
			const currentPage = this.currentPage;
			if (totalPage > currentPage) {
				this.setPage({
					page: this.currentPage + 1,
					itemsPerPage: this.itemsPerPage,
				});
			}
		} else if (scrollPos - event.target.offsetHeight === 0) {
			let lst = 0;
			const st = event.target.scrollTop;
			if (st > lst) {
				lst = st;
				return;
			}
		}
	}

	selectType(type?: string) {
		this.setTypeEvent.emit(type);
	}

	ngOnChanges(changes: SimpleChanges) {}

	setActiveShowMoreIds(id) {
		if (id && !this.activeShowMoreIds?.some((x) => x === id)) {
			this.activeShowMoreIds.push(id);
		} else {
			this.activeShowMoreIds = this.activeShowMoreIds?.filter((x) => x !== id);
		}
	}

	existingActiveShowMoreIds(id) {
		return this.activeShowMoreIds?.some((x) => x === id);
	}

	tackByFn(index, item: ActivityState) {
		return item.activityId;
	}

	trackByNotesFn(index, item: NoteState) {
		return item.notesID;
	}

	hasShowMoreBtn(element: any, maxHeight: number) {
		const notes = element.querySelector('.item-wrapper');
		return (!!notes ? notes.offsetHeight : 0) > maxHeight;
	}

	/**
	 * Convert Date time
	 * @param date YYYY-MM-DDTHH:mm
	 */
	formatToDisplayDatetime(date) {
		return MomentUtil.formatToDisplayDatetime(moment(date));
	}

	isCall(acType: string) {
		return (
			!!acType &&
			(acType?.toLowerCase().includes('call') ||
				acType?.toLowerCase().includes('phone'))
		);
	}

	addNote() {
		this.isAddNote = true;
	}

	prepareNoteFormValue() {
		const data = strUtil.safeTrim(this.formNote.value.note);
		return data;
	}

	saveNote() {
		if (!this.formNote.valid) {
			return;
		}
		this.isAddingNote$.next(true);
		this.addNoteFn$(this.prepareNoteFormValue(), this.getType())
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				() => {
					this.formNote.reset();
					this.isAddNote = false;
				}, // Success
				() => {}, // Error
				() => {
					this.isAddingNote$.next(false);
				}
			);
	}

	cancelAddNote() {
		this.isAddNote = false;
	}

	checkIfEmail(email: string) {
		if (!email) {
			return false;
		}

		if (email?.toLowerCase().indexOf('email') > -1) {
			return true;
		}

		return false;
	}

	getType() {
		let child = this.route.firstChild;
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else if (child.snapshot.data && child.snapshot.data.type) {
				return child.snapshot.data.type;
			} else {
				return '';
			}
		}
		return '';
	}

	// TYPES
	// "AP" - Advice Process
	// "APP" - Fact Find People Section
	// "APAL" - Fact Find Assets and Liabilities Section
	// "APIB" - Fact Find Income and Budget Section
	// "APCI" - Fact Find Current Insurance Section
	// "APFG" - Fact Find F&G Section
	// "APMH" - Fact Find Medical History Section
	getSection(type: string): string {
		switch (type) {
			case AdviceProcessPageCodes.Introduction:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.Introduction as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.Disclosure:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.Disclosure as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.SOS:
				return 'Scope of Services';

			// Fact Find
			case AdviceProcessPageCodes.People:
				return 'People';
			case AdviceProcessPageCodes.AssetsLiabilities:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.AssetsLiabilities as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.IncomeExpenses:
				return 'Income and Budget';
			case AdviceProcessPageCodes.CurrentInsurance:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.CurrentInsurance as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.FG:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.FG as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.MedicalHistory:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.MedicalHistory as keyof typeof AdviceProcessPageNamesByCode
				];

			// Risk Analysis
			case AdviceProcessPageCodes.Goals:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.Goals as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.Life:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.Life as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.Disability:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.Disability as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.TPD:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.TPD as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.CriticalIllnness:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.CriticalIllnness as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.Medical:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.Medical as keyof typeof AdviceProcessPageNamesByCode
				];
			case AdviceProcessPageCodes.RiskProfile:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.RiskProfile as keyof typeof AdviceProcessPageNamesByCode
				];

			case AdviceProcessPageCodes.Declaration:
				const declaration = this.crtTypes.find(
					(crtType) => crtType.value === type
				);
				if (
					declaration &&
					declaration.display === AdviceProcessMortgagePageNamesByCode[type]
				) {
					return AdviceProcessMortgagePageNamesByCode[
						AdviceProcessPageCodes.Declaration as keyof typeof AdviceProcessMortgagePageNamesByCode
					];
				}
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.Declaration as keyof typeof AdviceProcessPageNamesByCode
				];

			case AdviceProcessPageCodes.SOA:
				return AdviceProcessPageNamesByCode[
					AdviceProcessPageCodes.SOA as keyof typeof AdviceProcessPageNamesByCode
				];
			case 'APA':
				return 'Application';
			case AdviceProcessPageCodes.Income:
				return AdviceProcessMortgagePageNamesByCode[
					AdviceProcessPageCodes.Income as keyof typeof AdviceProcessMortgagePageNamesByCode
				];
			case AdviceProcessPageCodes.Expenses:
				return AdviceProcessMortgagePageNamesByCode[
					AdviceProcessPageCodes.Expenses as keyof typeof AdviceProcessMortgagePageNamesByCode
				];
			case AdviceProcessPageCodes.Application:
				return AdviceProcessMortgagePageNamesByCode[
					AdviceProcessPageCodes.Application as keyof typeof AdviceProcessMortgagePageNamesByCode
				];
			case AdviceProcessPageCodes.ROA:
				return AdviceProcessMortgagePageNamesByCode[
					AdviceProcessPageCodes.ROA as keyof typeof AdviceProcessMortgagePageNamesByCode
				];

			default:
				return '';
		}
	}

	editNote(notes: CRTNoteState) {
		const saveFn$ = (model: string) =>
			this.editNoteFn$({ ...notes, notes: model });

		const initialState = {
			header: 'Edit Note',
			notes: notes?.notes,
			savefn: saveFn$,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	deleteNote(notes: CRTNoteState) {
		this.isSaving$.next(true);
		this.deleteNoteFn$(notes.cRTNotesId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				() => {
					this.deleteNoteEvent.emit(notes);
				},
				() => {},
				() => {
					this.isSaving$.next(false);
				}
			);
	}

	confirmDeleteNote(notes: CRTNoteState) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteNote(notes);
			obs.complete();
		});
		const initState: DeleteModalModel = {
			delete$: confirm,
			header: 'Delete Note',
			message: `Are you sure you want to delete this note?`,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	pinNote(note: NoteState, pin: boolean) {
		this.isSaving$.next(true);
		return this.pinNoteFn$({ note, pin }).subscribe(
			() => {},
			() => {},
			() => {
				this.isSaving$.next(false);
			}
		);
	}

	get isLeadCustomer() {
		return !!this.isLead;
	}

	addActivity() {
		const formItem = {
			Customer: { CustomerId: this.customerId, Name: this.customerName },
			Location: this.location,
		};
		const initState: any = {
			formItem,
			AT$: this.activityType$,
			AM$: this.activityMeeting$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: true,
			savefn: this.saveActivityFn,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: this.isLeadCustomer ? ['FCLA'] : ['FCCA'],
		};
		this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft'
					? 'modal-dialog-outlook-xl'
					: 'modal-xl'
			}`,
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveActivityFn = (ac: ActivityViewModel) => {
		this.isSaving$.next(true);
		return this.addActivityFn$({
			...ac,
			Adviser: +ac.Adviser,
			IsCompleted: !!ac.IsCompleted,
		}).pipe(
			tap(
				() => {},
				() => {},
				() => {
					this.isSaving$.next(false);
				}
			)
		);
	};

	/** open popover */
	open() {
		this.popover.show();
	}
	/** close popover */
	close() {
		this.popover.hide();
	}

	export() {
		if (this.formExport.invalid) {
			return;
		}

		const minDate = MomentUtil.DatetimeStringToMoment(
			this.formExport.value.DateMin
		);
		const maxDate = MomentUtil.DatetimeStringToMoment(
			this.formExport.value.DateMax
		);

		if (this.minDateInput?.isInvalid()) {
			this.loggerService.Log(
				{},
				logMessage.shared.general.warning.invalidMinDate
			);
			return;
		}

		if (this.maxDateInput?.isInvalid()) {
			this.loggerService.Log(
				{},
				logMessage.shared.general.warning.invalidMaxDate
			);
			return;
		}

		if (moment(minDate).isAfter(maxDate)) {
			this.loggerService.Log(
				{},
				logMessage.shared.general.warning.minNotGreaterThanMaxDate
			);
			return;
		}

		this.isExporting = true;
		const data = {
			...this.formExport.getRawValue(),
			DateMin: MomentUtil.formatDateToServerDate(
				this.formExport.getRawValue().DateMin
			),
			DateMax: MomentUtil.formatDateToServerDate(
				this.formExport.getRawValue().DateMax
			),
			NoteType: this.formNote.value.type,
			ReferenceId: +this.route.snapshot.paramMap.get('adviceProcessId'),
		};
		this.exportFn$(data)
			.pipe(
				finalize(() => (this.isExporting = false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				(file) => {
					const company = this.route.snapshot.paramMap.get('companyCode');
					const name = `${company}-crt-notes.${data.ReportType}`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(file);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				},
				(err) => throwError(err)
			);
	}

	openClientReferralPopUp(): void {
		const getType = () => {
			switch (this.oatType) {
				case 'MOAT':
					return ClientReferralPopupType.MOAT;
				case 'KOAT':
					return ClientReferralPopupType.KOAT;
				case 'LOAT':
					return ClientReferralPopupType.LOAT;
			}
		};
		this.referClientPopupService
			.show(
				getType(),
				this.adviserId,
				this.customerId,
				this.customerName,
				this.route.snapshot.paramMap.get('adviceProcessId'),
			)
			.pipe(take(1))
			.subscribe();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
