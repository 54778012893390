import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownValue } from '../../../../../modules/dropdown/model/dropdownValue';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { PrimaryClientState } from '../../../../../shared/models/client-profile/primary-client/primary-client.model';
import {
	People,
	PeopleState,
} from '../../../../../shared/models/client-review-template/people/people.model';
import { SecondaryClientState } from '../../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { IncomeSourceState } from 'src/app/shared/models/client-review-template/income-budget/income-source-details.model';
import { delay, finalize, take } from 'rxjs/operators';
import { PeopleDetailsState } from '@shared/models/client-review-template/people/people-details.model';

@Component({
	selector: 'app-income-modal',
	templateUrl: './income-modal.component.html',
	styleUrls: ['./income-modal.component.scss'],
})
export class MOATIncomeModalComponent implements ConfirmModalModel, OnInit {
	savefn: (model: any, index: number) => Observable<any>;
	cancelAddUpdate: (index: number) => Observable<any>;
	confirm$: Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	dropdownCodes: DropdownValue[];
	incomeSources$: Observable<IncomeSourceState[]>;
	upsertLoading$ = new BehaviorSubject<boolean>(false);

	public apcrtf$: Observable<ViewDisplayValue[]>;
	public apcrttm$: Observable<ViewDisplayValue[]>;
	public apcrtkc$: Observable<ViewDisplayValue[]>;
	public apcrttc$: Observable<ViewDisplayValue[]>;
	public apcrti$: Observable<ViewDisplayValue[]>;
	public pce$: Observable<ViewDisplayValue[]>;
	public apcrtist$: Observable<ViewDisplayValue[]>;
	public apcrtyn$: Observable<ViewDisplayValue[]>;
	public apcrtynna$: Observable<ViewDisplayValue[]>;
	public apcrtit$: Observable<ViewDisplayValue[]>;
	public primaryClient$: Observable<PrimaryClientState>;
	public secondaryClients$: Observable<SecondaryClientState[]>;
	public peopleFromCRMAndCRTExceptChild$: Observable<SecondaryClientState[]>;
	public people$: Observable<PeopleDetailsState[]>;
	public transferedSCI: PeopleState[];
	public isAdd: boolean;
	public cRTId: number;
	public index: number;
	public adviceProcessId: number;
	public removeFactFindComputation: boolean;
	public showTimeInJob: boolean;
	public deceasedSciList$: Observable<People[]>;

	getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
		this.setupSubs();
	}

	ngOnInit() {}

	setupProps() {
		this.header = 'Confirm';
		this.message = 'Confirm?';
	}
	setupSubs() {}

	continue() {
		this.confirm$.subscribe();
		this.bsModalRef.hide();
	}
	decline() {
		if (!!this.decline$) {
			this.decline$.subscribe();
		}
		this.bsModalRef.hide();
	}

	cancelChange(value) {
		this.bsModalRef.hide();
		this.cancelAddUpdate(+this.index).subscribe();
	}

	save(model) {
		this.upsertLoading$.next(true);
		this.savefn(model, +this.index)
			.pipe(take(1))
			.subscribe(
				() => {
					this.bsModalRef.hide();
				},
				() => {
					this.upsertLoading$.next(false);
				}
			);
	}
}
export interface ConfirmModalModel {
	confirm$: Observable<any>;
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
