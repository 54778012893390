import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, delay, map, mergeMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../../core/base/api.service';
import { objectUtil, util } from '../../../../../../util/util';
import { CrtKiwiSaverQuery } from '../../state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../../state/crt-kiwisaver.service';
import {
	CrtKiwiSaverFactFindStepIndex,
	getRouteNameByIndex,
	KIWISAVER_FF_STEP_COUNT,
} from '../crt-ks-fact-find-step-index';
import { CrtKiwisaverFactFindPeopleQuery } from '../states/crt-ks-fact-find-people.query';
import { CrtKiwisaverFactFindGatheredInformationQuery } from './crt-ks-fact-find-gathered-information.query';
import {
	CrtKiwisaverFactFind,
	CrtKiwisaverFactFindGatheredInformationStore,
	InformationGathered,
} from './crt-ks-fact-find-gathered-information.store';
import {
	CrtFactFindPeople,
	CrtKiwisaverFactFindPeopleStore,
} from './crt-ks-fact-find-people.store';
import { CrtKiwisaverFactFindQuery } from './crt-ks-fact-find.query';
import {
	CrtKiwiSaverFactFind,
	CrtKiwisaverFactFindStore,
	KOATProvider,
} from './crt-ks-fact-find.store';
import { CrtKiwiSaverRiskProfileQuery } from './risk-profile/risk-profile.query';
import { CrtKiwiSaverRiskProfile } from './risk-profile/risk-profile.store';
import { find, propEq, uniq } from 'ramda';
import { KOATPeopleDetailsState } from '@shared/models/client-review-template/people/people-details.model';
import { PeopleDetails } from '../../people-entities/people/state/people.model';
import { applyTransaction } from '@datorama/akita';
import sort from 'fast-sort';

export enum CrtKsFactFindProgressState {
	Current = 'current',
	Visited = 'visited',
	NotVisited = 'notVisited',
}

export interface CrtKsFactFindQueryParams {
	count: string | number;
	person: string | number;
	currentStep: string | number;
	activeStep: string | number;
}

@Injectable({
	providedIn: 'root',
})
export class CrtKsFactFindService {
	selectAllPeople$ = this.crtKiwisaverFactFindPeopleQuery.selectAll({
		sortBy: 'cRTId',
	});

	peopleButtonState$ = combineLatest([
		this.activatedRoute.queryParams,
		this.crtKiwisaverFactFindPeopleQuery.people$,
	]).pipe(
		map(([params, people]) => {
			return people.map((person, index) => {
				return {
					isActive: params.person == index,
					name: `${person.firstName} ${person.lastName}`,
					id: person.customerID,
					cRTId: person.cRTId,
				};
			});
		})
	);

	showPeopleButtons$ = this.activatedRoute.queryParams.pipe(
		map((params) => Boolean(params.count > 1))
	);

	peopleCount$ = this.crtKiwisaverFactFindPeopleQuery.personCount$;

	currentProgressPosition$ = combineLatest([
		this.crtKiwisaverFactFindGatherInformationQuery.selectAll(),
		this.peopleCount$,
		this.crtKiwisaverFactFindQuery.select(),
		this.crtKiwisaverFactFindPeopleQuery.selectActive(),
	]).pipe(
		map(([gatheredInformation]) => {
			return gatheredInformation.reduce((index, gatheredInfo) => {
				index += +gatheredInfo.currentPage;
				return index;
			}, 0);
		})
	);

	activePerson$ = combineLatest([
		this.activatedRoute.queryParams,
		this.selectAllPeople$,
	]).pipe(
		map(([queryParams, people]) => {
			return people?.[queryParams?.person];
		})
	);

	activeRiskProfile$ = combineLatest(
		this.activePerson$,
		this.crtKiwiSaverRiskProfileQuery.selectAll()
	).pipe(
		map(([person, riskProfiles]) => {
			const currentRiskProfileIndex = riskProfiles?.findIndex(
				(r) => r.customerId === person?.customerID
			);
			if (currentRiskProfileIndex !== -1) {
				return riskProfiles?.[currentRiskProfileIndex];
			}
			return {
				sectionCode: 'KOATRPQ',
				cRTId: null,
				status: 1,
				customerId: person?.customerID,
			} as CrtKiwiSaverRiskProfile;
		})
	);

	informationGathered$ =
		this.crtKiwisaverFactFindGatherInformationQuery.selectAll();

	activeInformationGathered$ = combineLatest([
		this.activePerson$,
		this.informationGathered$,
	]).pipe(
		map(([person, gatheredInfo]) => {
			const orderedGatheredInfo = sort(gatheredInfo || [])?.desc(
				(s) => s.createDateTime
			);
			const unsavedGatheredInfo = <InformationGathered>{
				customerId: person?.customerID,
				sectionCode: 'KOATFFIG',
				cRTId: null,
			};
			if (!person || !gatheredInfo?.length) {
				return unsavedGatheredInfo;
			}
			const currentGatheredInfo = orderedGatheredInfo.find(
				(ig) => ig.customerId === person.customerID
			);
			return currentGatheredInfo ?? unsavedGatheredInfo;
		})
	);

	areAllInformationGathered$ = combineLatest([
		this.selectAllPeople$,
		this.crtKiwisaverFactFindGatherInformationQuery.selectAll(),
	]).pipe(
		map(([people, gatheredInfo]) => {
			const validGatheredInfo = gatheredInfo?.filter((x) => !!x.cRTId);
			return people?.length === validGatheredInfo?.length;
		})
	);

	areAllRiskProfileAnswered$ = combineLatest([
		this.selectAllPeople$,
		this.crtKiwiSaverRiskProfileQuery.selectAll(),
	]).pipe(
		map(([people, riskProfile]) => {
			const validGatheredInfo = riskProfile?.filter((x) => !!x.cRTId);
			return people?.length === validGatheredInfo?.length;
		})
	);

	totalAnimationsStepCount$ = this.crtKiwisaverFactFindQuery.select().pipe(
		map(({ settings }) => {
			const disabledPageCount = Object.values(settings).filter(
				(value) => value === false
			).length;
			return KIWISAVER_FF_STEP_COUNT.ANIMATIONS - disabledPageCount;
		})
	);

	progressBarState$ = combineLatest([
		this.activatedRoute.queryParams,
		this.totalAnimationsStepCount$,
	]).pipe(
		map(([params, animationStepCount]) => {
			if (!params.count) {
				return [];
			}
			const totalStepCount = this.getTotalTabCount(params.count);
			return Array(totalStepCount)
				.fill(0)
				.map((_value, stepIndex) => {
					if (
						stepIndex > params.activeStep &&
						stepIndex <= params.currentStep
					) {
						return CrtKsFactFindProgressState.Visited;
					}

					if (stepIndex <= params.currentStep) {
						return CrtKsFactFindProgressState.Current;
					}

					return CrtKsFactFindProgressState.NotVisited;
				});
		})
	);

	numberOfTabs$ = combineLatest([
		this.activatedRoute.queryParams,
		this.totalAnimationsStepCount$,
	]).pipe(map(([params]) => this.getTotalTabCount(params.person)));

	constructor(
		private api: ApiService,
		private crtKiwisaverFactFindQuery: CrtKiwisaverFactFindQuery,
		private crtKiwisaverFactFindStore: CrtKiwisaverFactFindStore,
		private crtKiwisaverFactFindPeopleQuery: CrtKiwisaverFactFindPeopleQuery,
		private crtKiwisaverFactFindPeopleStore: CrtKiwisaverFactFindPeopleStore,
		private crtKSFactFindGatheredInformationStore: CrtKiwisaverFactFindGatheredInformationStore,
		private crtKiwisaverFactFindGatherInformationQuery: CrtKiwisaverFactFindGatheredInformationQuery,
		private activatedRoute: ActivatedRoute,
		private crtKiwiSaverRiskProfileQuery: CrtKiwiSaverRiskProfileQuery,
		private koatQuery: CrtKiwiSaverQuery,
		private koatService: CrtKiwiSaverService
	) {}

	getNextRouteByIndex(nextRouteIndex: CrtKiwiSaverFactFindStepIndex): string {
		const settings = this.crtKiwisaverFactFindQuery.getValue().settings;
		const routes = Object.keys(CrtKiwiSaverFactFindStepIndex);
		for (let i = nextRouteIndex, len = routes.length; i < len; i++) {
			if (
				// if whykiwisaver is disabled
				(i === CrtKiwiSaverFactFindStepIndex.WhyKiwiSaver &&
					!settings.WhyKiwiSaver) ||
				// if howkiwisaver is disabled
				(i === CrtKiwiSaverFactFindStepIndex.HowKiwiSaverWorks &&
					!settings.HowKiwiSaverWorks) ||
				// if passivevsactive is disabled
				(i === CrtKiwiSaverFactFindStepIndex.InvestmentStrategy &&
					!settings.InvestmentStrategy)
			) {
				continue;
			}
			return getRouteNameByIndex(i);
		}
	}

	getPreviousRouteByIndex(
		previousRouteIndex: CrtKiwiSaverFactFindStepIndex
	): string {
		const settings = this.crtKiwisaverFactFindQuery.getValue().settings;
		for (let i = previousRouteIndex; i >= 0; i--) {
			if (
				// if whykiwisaver is disabled
				(i === CrtKiwiSaverFactFindStepIndex.WhyKiwiSaver &&
					!settings.WhyKiwiSaver) ||
				// if howkiwisaver is disabled
				(i === CrtKiwiSaverFactFindStepIndex.HowKiwiSaverWorks &&
					!settings.HowKiwiSaverWorks) ||
				// if passivevsactive is disabled
				(i === CrtKiwiSaverFactFindStepIndex.InvestmentStrategy &&
					!settings.InvestmentStrategy)
			) {
				continue;
			}
			return getRouteNameByIndex(i);
		}
	}

	calculateNextStep(
		activatedRoute: ActivatedRoute,
		currentRouteIndex: CrtKiwiSaverFactFindStepIndex
	): { nextPageParams: string; hasNextPerson: boolean; nextRouteName: string } {
		const params = activatedRoute.snapshot
			.queryParams as CrtKsFactFindQueryParams;
		const hasNextPerson = +params.person < +params.count - 1;
		const informationGatheringNextPerson =
			currentRouteIndex === CrtKiwiSaverFactFindStepIndex.WithdrawalResult &&
			hasNextPerson;
		const riskProfileHasNextPerson =
			currentRouteIndex === CrtKiwiSaverFactFindStepIndex.RiskProfileSummary &&
			hasNextPerson;
		const nextActiveStep = parseInt(params.activeStep as string) + 1;

		const nextPersonIndex =
			informationGatheringNextPerson || riskProfileHasNextPerson
				? parseInt(params.person as string) + 1
				: params.person;

		const nextCurrentStep =
			nextActiveStep < +params.currentStep
				? params.currentStep
				: nextActiveStep;

		const nextPageParams = util.tryStringifyJson({
			...params,
			person: nextPersonIndex,
			currentStep: nextCurrentStep,
			activeStep: nextActiveStep,
		});

		return {
			nextPageParams,
			hasNextPerson: riskProfileHasNextPerson || informationGatheringNextPerson,
			nextRouteName: this.getNextRouteByIndex(currentRouteIndex + 1),
		};
	}

	calculatePreviousStep(
		activatedRoute: ActivatedRoute,
		currentRouteIndex: CrtKiwiSaverFactFindStepIndex
	): {
		nextPageParams: string;
		hasPreviousPerson: boolean;
		previousRouteName: string;
	} {
		const params = activatedRoute.snapshot
			.queryParams as CrtKsFactFindQueryParams;
		const hasPreviousPerson = +params.person > 0;
		const informationGatheringNextPerson =
			currentRouteIndex === CrtKiwiSaverFactFindStepIndex.YourKiwiSaver &&
			hasPreviousPerson;
		const riskProfileHasNextPerson =
			currentRouteIndex ===
				CrtKiwiSaverFactFindStepIndex.RiskProfileInformationGathering &&
			hasPreviousPerson;
		const previousStepIndex = parseInt(params.activeStep as string) - 1;

		const nextPersonIndex =
			informationGatheringNextPerson || riskProfileHasNextPerson
				? parseInt(params.person as string) - 1
				: params.person;

		const nextCurrentStep =
			previousStepIndex < +params.currentStep
				? params.currentStep
				: previousStepIndex;

		const nextPageParams = util.tryStringifyJson({
			...params,
			person: nextPersonIndex,
			currentStep: nextCurrentStep,
			activeStep: previousStepIndex,
		});

		return {
			nextPageParams,
			hasPreviousPerson:
				riskProfileHasNextPerson || informationGatheringNextPerson,
			previousRouteName: this.getPreviousRouteByIndex(currentRouteIndex - 1),
		};
	}

	navigateNextPerson(): Observable<CrtFactFindPeople | null> {
		return combineLatest([
			this.selectAllPeople$,
			this.activatedRoute.queryParams,
		]).pipe(
			take(1),
			map(([people, params]) => {
				const nextPersonIndex = parseInt(params.person) + 1;
				return nextPersonIndex > people.length ? null : people[nextPersonIndex];
			})
		);
	}

	navigatePreviousPerson(): Observable<CrtFactFindPeople | null> {
		return combineLatest([
			this.selectAllPeople$,
			this.activatedRoute.queryParams,
		]).pipe(
			map(([people, params]) => {
				return +params.person === 0 ? null : people[params.person - 1];
			})
		);
	}

	getFactFind(adviceProcessId: number): Observable<CrtKiwiSaverFactFind> {
		return this.api.get(`crt/${adviceProcessId}/KOATFF`).pipe(
			map((result) => result as CrtKiwisaverFactFind[]),
			map((result) => {
				return result?.length
					? objectUtil.mapPascalCaseToCamelCase(result?.[0])
					: null;
			})
		);
	}

	addFactFind(factFind: Partial<CrtKiwiSaverFactFind>): Observable<boolean> {
		return this.api
			.post(`crt`, objectUtil.mapCamelCaseToPascalCase(factFind))
			.pipe(map((result) => Boolean(result)));
	}

	updateFactFind(factFind: CrtKiwiSaverFactFind): Observable<boolean> {
		return this.api
			.put(`crt`, objectUtil.mapCamelCaseToPascalCase(factFind))
			.pipe(map((result) => Boolean(result)));
	}

	getPeople(
		adviceProcessId: number,
		customerType: string
	): Observable<CrtFactFindPeople[]> {
		const endpoint = `crt/${adviceProcessId}/${customerType}`;
		return this.api.get(endpoint).pipe(
			map((people) => people as CrtFactFindPeople[]),
			map((people) =>
				people.map((p) => objectUtil.mapPascalCaseToCamelCase(p))
			),
			map((people) => people.filter((p) => p?.relationship !== 'Child')),
			tap((people) => {
				this.crtKiwisaverFactFindPeopleStore.set(people);
			}),
			catchError(() => of([]))
		);
	}

	getInformationGathered(
		adviceProcessId: number
	): Observable<InformationGathered[]> {
		const endpoint = `crt/${adviceProcessId}/KOATFFIG`;
		return this.api.get(endpoint).pipe(
			map((info) => info as InformationGathered[]),
			map((info) => info.map((p) => objectUtil.mapPascalCaseToCamelCase(p))),
			map((info) => {
				return info.map((g) => {
					g.currentPage ??= '0';
					return g;
				});
			}),
			tap((info) => this.crtKSFactFindGatheredInformationStore.set(info)),
			catchError(() => of([]))
		);
	}

	updateInformationGathered(info: InformationGathered): Observable<boolean> {
		const endpoint = `crt/${info.cRTId}`;
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(info))
			.pipe(
				tap(() => {
					this.crtKSFactFindGatheredInformationStore.update(info.cRTId, info);
					this.crtKiwisaverFactFindStore.updateCurrentPage(info.currentPage);
				}),
				map((result) => Boolean(result)),
				take(1),
				catchError(() => of(undefined))
			);
	}

	addInformationGathered(info: InformationGathered): Observable<boolean> {
		info.sectionCode = 'KOATFFIG';
		info.parentCRTId = this.crtKiwisaverFactFindQuery.getValue().cRTId;
		return this.api.postNoRetry('crt', objectUtil.mapCamelCaseToPascalCase(info)).pipe(
			tap((id: number) => {
				info.cRTId = id;
				const list = this.crtKiwisaverFactFindGatherInformationQuery.getAll();
				const isAlreadyOnState = find(propEq('cRTId', 0))(list);
				if (isAlreadyOnState) {
					this.crtKSFactFindGatheredInformationStore.update(0, info);
				} else {
					this.crtKSFactFindGatheredInformationStore.add(info);
				}
				this.crtKiwisaverFactFindStore.updateCurrentPage(info.currentPage);
			}),
			map((result) => Boolean(result)),
			take(1),
			catchError((err)=>{
				if(err){
					return this.getInformationGathered(info.adviceProcessId).pipe(map(()=>true));
				}
				return of(undefined)
			})
		);
	}

	upsertInformationGathered(info: InformationGathered): Observable<boolean> {
		return Boolean(info.cRTId)
			? this.updateInformationGathered(info)
			: this.addInformationGathered(info);
	}

	updatePageCompleted(pageCode: string) {
		return this.koatQuery.pageCompleted$.pipe(
			mergeMap((list) => {
				const newList = uniq([...list, pageCode])?.filter(Boolean);
				return iif(
					() => !list?.some((x) => x === pageCode),
					this.koatService.updatePageCompleted(newList),
					of(null)
				);
			}),
			take(1)
		);
	}

	updateCurrentPage(currentPage: string): Observable<string> {
		const params = util.tryParseJson(currentPage);
		const crtFFData = this.crtKiwisaverFactFindQuery.getValue();
		const body = objectUtil.mapCamelCaseToPascalCase(crtFFData);
		const endpoint = `crt/${crtFFData.cRTId}`;
		crtFFData.currentPage = currentPage;
		return of(currentPage).pipe(
			mergeMap(() =>
				iif(
					() => params.currentStep < params.activeStep,
					of(currentPage),
					this.api.put(endpoint, body).pipe(take(1))
				)
			),
			map(() => currentPage),
			take(1)
		);
	}

	getProviders(adviceProcessId: number): Observable<KOATProvider> {
		return this.api.get<KOATProvider>(`crt/${adviceProcessId}/KOATPRVDR`).pipe(
			map((x) => x ?? []),
			map((x) => x[0] || null),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.crtKiwisaverFactFindStore.setProvider(x)),
			catchError(() => of(undefined))
		);
	}

	upsertProvider(data: KOATProvider) {
		return of(data).pipe(
			map((x) => objectUtil.mapCamelCaseToPascalCase({ ...x })),
			mergeMap((x) => {
				if (!!this.crtKiwisaverFactFindQuery.getValue()?.provider) {
					return this.api
						.put(`crt/${data?.cRTId}`, x)
						.pipe(map(() => data.cRTId));
				}
				return this.api.post(`crt`, x);
			}),
			map((x) => ({ ...data, cRTId: +x } as KOATProvider)),
			tap((x) => this.crtKiwisaverFactFindStore.setProvider(x))
		);
	}

	getTotalTabCount(numberOfPerson: number): number {
		const { settings } = this.crtKiwisaverFactFindQuery.getValue();
		const disabledPageCount = Object.values(settings).filter(
			(value) => value === false
		).length;
		const animationStepCount =
			KIWISAVER_FF_STEP_COUNT.ANIMATIONS - disabledPageCount;

		const totalInformationGatheringStepCount =
			numberOfPerson * KIWISAVER_FF_STEP_COUNT.INFORMATION_GATHERING;
		const totalRiskProfileStepCount =
			KIWISAVER_FF_STEP_COUNT.RISK_PROFILE_INFORMATION_GATHERING *
			numberOfPerson;
		return (
			totalInformationGatheringStepCount +
			KIWISAVER_FF_STEP_COUNT.KIWISAVER_SUMMARY +
			animationStepCount +
			KIWISAVER_FF_STEP_COUNT.KIWISAVER_PROJECTION +
			totalRiskProfileStepCount +
			KIWISAVER_FF_STEP_COUNT.PROVIDER
		);
	}

	setFormValuesOnState(info: InformationGathered) {
		const list = this.crtKiwisaverFactFindGatherInformationQuery.getAll();
		const isExisting = find(propEq('cRTId', info.cRTId))(list);
		if (isExisting) {
			this.crtKSFactFindGatheredInformationStore.update(info.cRTId, info);
		} else {
			this.crtKSFactFindGatheredInformationStore.add(info);
		}
	}

	getInfoGatheredByCustomerId(customerID: number) {
		return this.crtKiwisaverFactFindGatherInformationQuery
			.selectAll()
			.pipe(
				map((gatheredInfo) =>
					gatheredInfo?.find((ig) => ig.customerId === customerID)
				)
			);
	}

	getRiskProfileByCustomerId(customerID: number) {
		return this.crtKiwiSaverRiskProfileQuery
			.selectAll()
			.pipe(
				map((riskProfiles) =>
					riskProfiles?.find((r) => r.customerId === customerID)
				)
			);
	}

	getPersonInfo(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api
			.get<PeopleDetails>(endpoint)
			.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
	}

	updatePeopleDetails(person: KOATPeopleDetailsState) {
		const endpoint = `crt/${person.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(person);

		return this.api.put(endpoint, body).pipe(
			tap(() => {
				applyTransaction(() => {
					const people = this.crtKiwisaverFactFindPeopleQuery.getValue().entities;
					const data = Object.values(people)?.map((y) =>
						y.cRTId === person.cRTId
							? person
							: y
					);
					this.crtKiwisaverFactFindPeopleStore.set(data);
				})
			}),
		);
	}
}
