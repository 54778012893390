import { ActivityUiStore } from './activity-ui.store';
import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import produce from 'immer';
import * as R from 'ramda';
import { concat, from, iif, Observable, of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	reduce,
	retry,
	shareReplay,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { UserService } from 'src/app/domain/user/user.service';
import { UserStore } from 'src/app/domain/user/user.store';
import { logMessage } from 'src/app/shared/error-message/error-message';
import {
	ActivityComplete,
	ActivityModel,
	PostActivityParam,
	PutActivityParam,
} from 'src/app/shared/models/_general/activity.model';
import { ActivityViewModel } from 'src/app/shared/models/_general/activity.viewmodel';
import MomentUtil from 'src/app/util/moment.util';
import {
	ApiService,
	JsonResultStatus,
} from '../../../../core/base/api.service';
import { CustomerService } from '../../../../core/customer/customer.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '../../../../domain/user/user.query';
import {
	columns,
	createRowFromPrototype,
	Metakey,
} from '../activity-datatable.config';
import {
	ActivityRequest,
	ActivityStatus,
	GetActivitiesResponse,
} from './activity.model';
import { ActivityQuery } from './activity.query';
import { ActivityStore } from './activity.store';

@Injectable()
export class ActivityService {
	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');
	constructor(
		protected api: ApiService,
		protected userQuery: UserQuery,
		protected store: ActivityStore,
    protected activityUiStore: ActivityUiStore,
		protected query: ActivityQuery,
		protected loggerService: LoggerService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected customerService: CustomerService,
		protected bLStaffsQuery: BLStaffsQuery,
		protected userStore: UserStore,
		protected userService: UserService
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	search(req: ActivityRequest) {
		const batchLength = 100;

		const getIndexesToFetch: (res: GetActivitiesResponse) => number[] = R.pipe(
			(res: GetActivitiesResponse) =>
				Math.ceil(res.TotalCount / batchLength - 1),
			(totalPages: number) =>
				Array(totalPages)
					.fill(1)
					.map((x, i) => i + 1)
					.slice(1)
		);
		const searchRequest = (request: ActivityRequest) =>
			this.api.post3<GetActivitiesResponse>('search/activities', request);

		const firstPage$ = of(req).pipe(
			map(
				produce((draft) => {
					draft.Paging = {
						Index: 2,
						Column: req.Paging.Column,
						Direction: req.Paging.Direction,
					};
				})
			),
			mergeMap(searchRequest),
			shareReplay()
		);

		return firstPage$.pipe(
			mergeMap((res) =>
				concat(
					firstPage$,
					from(getIndexesToFetch(res)).pipe(
						map((i) =>
							produce(req, (draft) => {
								draft.Paging = {
									Index: i + 1,
									Column: req.Paging.Column,
									Direction: req.Paging.Direction,
								};
							})
						),
						concatMap((req2) => searchRequest(req2))
					)
				)
			),
			reduce((acc, v) =>
				produce(acc, (draft) => {
					draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
					draft.IsComplete = true;
				})
			),
			map((res) =>
				applyTransaction(() => {
					return res;
				})
			)
		);
	}

	searchActivities(req: ActivityRequest) {
		this.store.uiStore.setIsSearching(true);

		return this.api.post3<GetActivitiesResponse>('search/activities', req).pipe(
			withLatestFrom(this.query.templateRow$),
			switchMap(([x, templateRow]) => {
				if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
					// Saves initial fetch
					const rows: any[] = R?.map(
						createRowFromPrototype(templateRow),
						x.SearchResults
					);
					this.store.set([]);
					this.store.set([...rows]);
					return this.search(req);
				}
				return of(x);
			}),
			withLatestFrom(this.query.templateRow$, (res, template) => ({
				response: res,
				templateRow: template,
			})),
			map((res) =>
				applyTransaction(() => {
					const rows: any[] = R?.map(
						createRowFromPrototype(res.templateRow),
						res.response.SearchResults
					);

					// Doesn't loop api call for <= 100 list so needs to add validation here to remove existing data(pending, completed, cancelled)
					if (
						req.Paging.Index === 1 &&
						this.query.getCount() !== 0 &&
						res.response.TotalCount > 500
					) {
						this.store.set([]);
						res.response.IsComplete = false;
					}

					// Fetch all activities
					const a = this.query.getAll();
					if (
						req.Paging.Index === 1 &&
						res.response.IsComplete &&
						res.response.TotalCount <= 100
					) {
						this.store.set([]);
						this.store.set([...rows]);
					} else {
						this.store.set([...a, ...rows]);
					}
					this.store.update((state) => ({
						...state,
						count: res.response.TotalCount,
						isComplete: res.response.IsComplete,
					}));

					return res.response;
				})
			),
			finalize(() => {
				this.store.setSearchForm(req);
				this.store.uiStore.setIsSearching(false);
			})
		);
	}

	exportActivities(req: ActivityRequest): Observable<any> {
		return this.api.post4('export/activities', req).pipe(
			map((x) => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			retry(1),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.shared.export.error);
				return throwError(err);
			})
		);
	}

	tryParseJSON(jsonString: any): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}

	removeActivities() {
		this.store.remove();
	}

	getColumns(): Observable<{ [key: string]: string }> {
		// @ts-ignore-next
		return this.query
			.select((x) => x.columns)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				mergeMap((x) => this.userQuery.staffSettings$),
				tap((x) => {
					return R?.complement(R?.either(R?.isNil, R?.isEmpty))(x)
						? this.store.setColumns(
								!!x.ActivityColumns ? JSON.parse(x.ActivityColumns) : []
						  )
						: null;
				})
			);
	}

	getColumnWidths(): Observable<{ [key: string]: string }> {
		// @ts-ignore-next
		return this.query
			.select((x) => x.columnWidths)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				mergeMap(() => this.userQuery.staffSettings$),
				tap((x) =>
					R?.complement(R?.either(R?.isNil, R?.isEmpty))(x)
						? this.store.setColumnWidths(
								x.ActivityColumnsWidth
									? JSON.parse(x.ActivityColumnsWidth)
									: []
						  )
						: null
				)
			);
	}

	reorderColumn = (oldIndex: number, newIndex: number) => {
		const oldCol = this.query.getValue().columns;
		const newCol = produce(oldCol, (draft) => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});
		const newColWith = produce(this.query.getValue().columnWidths, (draft) => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});

		return of(newCol).pipe(
			tap(() => {
				this.store.setColumnWidths(newColWith);
				this.store.setColumns(newCol);
			}),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					ActivityColumns: JSON.stringify(newVal),
					ActivityColumnsWidth: JSON.stringify(newColWith),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTop, user]) =>
				isTop
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			)
		);
	};

	resizeColumn(prop: string, width: number): Observable<any> {
		const oldColWidths = this.query.getValue().columnWidths.filter((x) => x);
		const newColWidths = produce(oldColWidths, (draft) => {
			const col = columns?.find((x) => x.prop === prop);
			const exists = draft?.some((x) => col.metakey === x.metakey);
			if (exists) {
				draft.find((x) => col.metakey === x.metakey).width = width;
			} else {
				draft.push({ metakey: col.metakey, width });
			}
		});

		return of(newColWidths).pipe(
			tap((x) => this.store.setColumnWidths(x)),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					ActivityColumnsWidth: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTop, user]) =>
				isTop ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)
			)
		);
	}

  saveVisibleColumns = (metakeys: Metakey[]) => {
		const newColumns = metakeys;
		const oldColumns = this.query.getValue().columns;
		if (R.equals(newColumns, oldColumns)) {
			return of();
		}

		this.activityUiStore.setIsColumnSaving(true);

		const newColumnMetakeys = newColumns;

		return of(newColumnMetakeys).pipe(
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					ActivityColumns: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			),
			finalize(() => this.activityUiStore.setIsColumnSaving(false)),
			tap(() => this.store.setColumns(newColumnMetakeys))
		);
	};

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.store.uiStore.setSort(propSort, sort);
	}

	saveField(
		req: PutActivityParam | number,
		MetaKey: Metakey,
		MetaValue: string
	): Observable<JsonResultStatus> {
		const activityId = typeof req === 'number' ? req : req.ActivityId;
		this.store.uiStore.setLoad(activityId, MetaKey, true);
		return this.api.put<JsonResultStatus>(`activities/${activityId}`, req).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.uiStore.setEdit(activityId, MetaKey, false);
					this.store.updateField(activityId, MetaKey, MetaValue);
					this.setTempValue(activityId, MetaKey, undefined);
				})
			),
			finalize(() => this.store.uiStore.setLoad(activityId, MetaKey, false))
		);
	}

	edit = (customerId: number, metakey: Metakey) =>
		this.store.uiStore.setEdit(customerId, metakey, true);
	cancel = (customerId: number, metakey: Metakey) =>
		applyTransaction(() => {
			this.store.uiStore.setEdit(customerId, metakey, false);
			this.setTempValue(customerId, metakey, undefined);
		});

	setTempValue = (customerId: number, metakey: string, value: any) =>
		this.store.uiStore.setTempValue(customerId, metakey, value);

	openPopup = () => this.store.uiStore.toggleColumnPopup(true);
	closePopup = () => this.store.uiStore.toggleColumnPopup(false);
	togglePopup = () =>
		this.store.uiStore.toggleColumnPopup(
			!this.query.uiQuery.getValue().columnFormPopupOpen
		);

	reloadData() {
		const data = this.query.getAll();
		this.store.set([...data]);
	}

	addActivityFromOtherPage(ac: PostActivityParam) {
		return this.api.post3<number>('activities', ac);
	}

	addActivityReferral(ac: PostActivityParam) {
		return this.api.post3<number>('activities/referral', ac);
	}

	addActivity(ac: PostActivityParam) {
		return this.api.post3<number>('activities', ac).pipe(
			mergeMap((x) =>
				iif(
					() => !ac.IsCancelled,
					this.api.get<ActivityModel>(`activities/${x}`),
					of(null)
				)
			),
			withLatestFrom(this.query.templateRow$, this.query.searchForm$),
			tap(([x, templateRow, form]) => {
				const paging = this.query.uiQuery.getValue().currentSort;
				if (!!form) {
					this.searchActivities({
						...form,
						Paging: {
							Index: 1,
							Column: paging.propSort,
							Direction: paging.sort,
						},
					}).subscribe();
				}
			})
		);
	}

	editActivity(ac: PutActivityParam) {
		return this.api.put<JsonResultStatus>(`activities/${ac.ActivityId}`, ac);
	}

	updateActivity(ac: ActivityModel) {
		return of(ac).pipe(
			withLatestFrom(this.query.templateRow$, this.query.searchForm$),
			tap(([x, templateRow, form]) => {
				const paging = this.query.uiQuery.getValue().currentSort;
				this.searchActivities({
					Advisers: [],
					AltAdvisers: [],
					AssignedTo: [],
					CreatedBy: [],
					ClientFirstName: '',
					ClientSurname: '',
					ActivityStatus: {
						IsPending: true,
						IsCompleted: false,
						IsCancelled: false,
					},
					AdviserStatus: [],
					ActivityCreateDateMin: null,
					ActivityCreateDateMax: null,
					ActivityDueDateMin: null,
					ActivityDueDateMax: null,
					ActivityCompleteDateMin: null,
					ActivityCompleteDateMax: null,
					ActivityType: [],
					...form,
					Paging: { Index: 1, Column: paging.propSort, Direction: paging.sort },
				}).subscribe();
			})
		);
	}

	completeActivity(activityId: number) {
		this.store.uiStore.setIsCompleting(activityId, true);
		return this.api
			.put<ActivityComplete>(`activities/${activityId}`, { isCompleted: true })
			.pipe(
				withLatestFrom(this.query.searchForm$),
				tap(([x, form]) => {
					if (
						form.ActivityStatus.IsCompleted ||
						(!form.ActivityStatus.IsCompleted &&
							!form.ActivityStatus.IsPending &&
							!form.ActivityStatus.IsCancelled)
					) {
						this.store.update(
							activityId,
							produce((draft) => {
								draft.ActivityStatus = {
									...draft.ActivityStatus,
									value: ActivityStatus.Completed,
								};
								draft.CompleteDate = {
									...draft.CompleteDate,
									value: MomentUtil.formatDateToServerDate(
										MomentUtil.createMomentNz()
									),
								};
							})
						);
					} else {
						this.store.remove(activityId);
					}
					this.store.uiStore.setIsCompleting(activityId, false);
				})
			);
	}

	cancelActivity(ac: {
		activity: ActivityViewModel | ActivityModel;
		reason: string;
	}): Observable<JsonResultStatus> {
		this.store.uiStore.setIsCancelling(ac.activity.ActivityId, true);

		return this.api
			.put<JsonResultStatus>(`activities/${ac.activity.ActivityId}`, {
				isCancelled: true,
				reason: ac.reason,
			})
			.pipe(
				withLatestFrom(this.query.searchForm$),
				tap(([status, form]) => {
					if (
						form.ActivityStatus.IsCancelled ||
						(!form.ActivityStatus.IsCancelled &&
							!form.ActivityStatus.IsPending &&
							!form.ActivityStatus.IsCompleted)
					) {
						this.store.update(
							ac.activity.ActivityId,
							produce((draft) => {
								draft.ActivityStatus = {
									...draft.ActivityStatus,
									value: ActivityStatus.Cancelled,
								};
								draft.CancelledDate = {
									...draft.CancelledDate,
									value: MomentUtil.formatDateToServerDate(
										MomentUtil.createMomentNz()
									),
								};
							})
						);
					} else {
						this.store.remove(ac.activity.ActivityId);
					}
					this.store.uiStore.setIsCancelling(ac.activity.ActivityId, false);
				}),
				finalize(() =>
					this.store.uiStore.setIsCancelling(ac.activity.ActivityId, false)
				),
				map(([status, form]) => status)
			);
	}

	deleteActivity(ac: ActivityModel): Observable<JsonResultStatus> {
		this.store.uiStore.setIsDeleting(ac.ActivityId, true);

		return this.api
			.delete<JsonResultStatus>(`activities/${ac.ActivityId}`)
			.pipe(
				tap(() => {
					this.store.remove(ac.ActivityId);
				}),
				finalize(() => this.store.uiStore.setIsDeleting(ac.ActivityId, false))
			);
	}
}
