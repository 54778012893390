import { StaffBusiness } from '../../../shared/models/_general/staff-business';

export class viewBusiness {
	businessId: number;
	businessName: string;
	description: string;
	website: string;
	domain: string;
	address1: string;
	address2: string;
	city: string;
	postcode: string;
	country: string;
	firstName: string;
	lastName: string;
	position: string;
	emailAddress: string;
	phoneNumber: string;
	services: string[];
	companyCode: string;
	isActive: boolean | number;
	static create(
		businessId,
		businessName,
		description,
		website,
		domain,
		address1,
		address2,
		city,
		postcode,
		country,
		firstName,
		lastName,
		position,
		emailAddress,
		phoneNumber,
		services,
		companyCode,
		isActive
	): viewBusiness {
		return {
			businessId,
			businessName,
			description,
			website,
			domain,
			address1,
			address2,
			city,
			postcode,
			country,
			firstName,
			lastName,
			position,
			emailAddress,
			phoneNumber,
			services,
			companyCode,
			isActive
		} as viewBusiness;
	}
	static mapBusiness(s: StaffBusiness): viewBusiness {
		return this.create(
			s.BusinessID,
			s.BusinessName,
			s.Description,
			s.Website,
			s.Domain,
			s.Address1,
			s.Address2,
			s.City,
			s.Postcode,
			s.Country,
			s.FirstName,
			s.LastName,
			s.Position,
			s.EmailAddress,
			s.PhoneNumber,
			s.Services,
			s.CompanyCode,
			s.IsActive
		);

	}
	static mapBusinesses(businesses: StaffBusiness[]): viewBusiness[] {
		return businesses?.map(s => this.mapBusiness(s));
	}
}
