import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface SpecialFeaturesSettingsState {
	specialFeaturesSettings: string[];
}

export function createInitialState(): SpecialFeaturesSettingsState {
	return {
		specialFeaturesSettings: [],
	};
}

@Injectable({
	providedIn: 'root',
})
@StoreConfig({ name: 'special-feature-settings' })
export class SpecialFeaturesSettingsStore extends Store<SpecialFeaturesSettingsState> {
	constructor() {
		super(createInitialState());
	}

	setSpecialFeaturesSettings(data: string[]) {
		this.update({ specialFeaturesSettings: data });
	}
}
