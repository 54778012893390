import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { FGPremiumStatsDomestic } from './fgpsd.model';
import { FGPremiumStatsDomesticStore } from './fgpsd.store';


@Injectable()
export class FGPremiumStatsDomesticService {

  private readonly endpoint = 'widgets?type=FGPSD';

  constructor(
    private api: ApiService,
    private store: FGPremiumStatsDomesticStore
  ) { }

  /**
   * Fetch and store FG Premium Stats Domestic data for Rolling 12 Months (Year)
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public getFGPremiumStatsDomesticStats(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post<FGPremiumStatsDomestic[]>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
        res => this.store.update(produce(draft => {
          draft.FGPremiumStatsDomestic = res;
        })),
        err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
        () => this.store.setLoading(false)
      );
  }
}
