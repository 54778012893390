<div class="card-header font-weight-bold text-light tap-bg-primary">
	{{ header }}
</div>
<div class="card-body">
	<app-email-form
		[primaryClient]="primaryClient"
		[onModalClosed]="onModalClosed"
		[sendToLabel]="sendToLabel"
		[emailSettings]="emailSettings$ | async"
		[attachments]="attachments"
		[combinedAttachments]="combinedAttachments"
		[getCrtInfoFn]="getCrtInfoFn"
		[initFormValue]="initFormValue"
		[emailDocumentTemplate]="generateFinalisedEmailTemplateFn$ | async"
		[defaultLinkDocumentTab]="defaultLinkDocumentTab"
		[bodyContent]="bodyContent"
		[clientDocumentsLoader]="clientDocumentsLoader"
		[shortCodes]="shortCodes"
		[peopleList]="peopleList"
		(cancel)="decline()"
		(sent)="sent()"
		(next)="next()"
		[saveEmailFn$]="saveEmailFn$"
		[beforeSendFn$]="beforeSendFn$"
		[sendEmailFn$]="sendEmailFn$"
		[downloadOfferTermsFn$]="downloadOfferTermsFn$"
		[showEmailTemplate]="showEmailTemplate"
		[successMessage]="successMessage"
		[attachFiles]="attachFiles"
		[adviceProcess]="adviceProcess"
		[businessConfig]="businessConfig$ | async"
		[mergeTags$]="mergeTags$"
		[documentInfo]="documentInfo"
		[disableEmailUpdate]="disableEmailUpdate"
		[showNextBtn]="showNextBtn"
		[isLoading]="isLoading"
		[updateMTOnRecepientChange$]="updateMTOnRecepientChange$"
		[ccAdvisers]="ccAdvisers"
		[isCompany]="isCompany"
		[isECAR]="isEmailCAR"
		[attachloaderIsLoading]="attachloaderIsLoading"
		[nextLabel]="nextLabel"
		[aotAttachmentGenerationState$]="aotAttachmentGenerationState$"
		[onCAREmailSavedFn$]="onCAREmailSavedFn$"
		[enableDefaultBcc]="enableDefaultBcc"
	>
		<div
			*ngIf="showAttachmentUI"
			[ngClass]="{ 'disabled': isLoading || attachloaderIsLoading }"
		>
			<div class="form-row pl-1 font-weight-bold">
				Email {{ attachments?.length > 1 || offerTerms?.length ? 'Attachments' :
				'Attachment' }}
			</div>

			<div class="col mt-2 pt-2 attachments">
				<div class="row">
					<div class="col-9 pl-0">
						<app-manual-loader-mini
							*ngIf="attachloaderIsLoading"
							[isLoading]="true"
							class="p-0"
						></app-manual-loader-mini>
						<div
							*ngFor="let attachment of attachments; let i = index"
							class="card-footer attachment"
						>
							<div
								id="downloadBtn_{{i}}"
								class="btn btn-md document-button-container p-0 text-left"
								(click)="!!attachment?.queue || !!attachment?.disableDownload ? null : downloadPdfAttachment(attachment)"
								[class.disabled]="!!attachment?.queue || !!attachment?.disableDownload"
							>
								<i class="far fa-file-pdf text-danger pr-1"></i>
								<small class="text-muted">{{attachment.fileName}}</small>
							</div>
							<ng-container *ngIf="attachment.forceShowDeleteBtn ?? attachment.allowDeleting !== false">
								<button
									id="file_{{ i }}"
									type="button"
									class="icon-btn w-auto px-1 h-auto"
									(click)="removeAttachment(i)"
									*ngIf="
										!attachment.generateContentCallback$ &&
										(!attachment.fileUrl || attachment.type === 'linked') &&
										!attachment.disableDownload &&
										!attachment.allowDownloadCombine
									"
								>
									<i class="material-icons md-16 close-icon"> close </i>
								</button>
							</ng-container>
						</div>
						<div
							*ngFor="let offerTerm of offerTerms"
							class="card-footer attachment"
						>
							<div
								*ngIf="offerTerm"
								id="downloadOfferOfTermsBtn"
								class="btn btn-md document-button-container px-0"
								(click)="downloadOfferTerms(offerTerm?.referenceId)"
							>
								<i class="far fa-file-pdf text-danger pr-1"></i>
								<small class="text-muted">{{offerTerm?.value}}</small>
							</div>
						</div>
					</div>
					<div class="col-3 text-right pt-2 pr-0">
						<button
							id="attachDocument"
							class="tap-btn tap-btn-outline tap-btn--shadow"
							(click)="openAttachmentModal()"
						>
							Attach Document
						</button>
					</div>
				</div>
			</div>
		</div>
	</app-email-form>
</div>
