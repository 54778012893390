import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { FGPremiumStatsLeadType } from './fgpslt.model';
import { FGPremiumStatsLeadTypeStore } from './fgpslt.store';


@Injectable()
export class FGPremiumStatsLeadTypeService {

  private readonly endpoint = 'widgets?type=FGPSLT';

  constructor(
    private api: ApiService,
    private store: FGPremiumStatsLeadTypeStore
  ) { }

  /**
   * Fetch and store FG Premium Stats Lead Type data for Rolling 12 Months (Year)
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public getFGPremiumStatsLeadTypeStats(adviserIdList: string[], types: string[]): void {
    // this.store.reset();
    this.store.setLoading(true);

    this.api.post<FGPremiumStatsLeadType[]>(this.endpoint, {
      Advisers: adviserIdList,
      LeadType: types,
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.FGPremiumStatsLeadType = res;
      })),
      err => applyTransaction(() => {
        this.store.setError('Error fetching data!');
        this.store.setLoading(false);
      }),
      () => this.store.setLoading(false)
    );
  }
}
