<div class="container-fluid tap-search-page">
	<div class="clearfix py-3">
		<app-client-search-form
			(toggleSearch)="onToggleSearch($event)"
		></app-client-search-form>
		<!-- Table -->
		<div class="clearfix">
			<div class="row">
				<div class="col p-0">
					<ngx-datatable
						#mydatatable
						class="bootstrap"
						[style.height]="tblHeight"
						[ngClass]="{
							showSearchFields: showSearchFields,
							'disabled-table': isSearching$ | async,
							'disable-sort': !(hasRow$ | async)
						}"
						[columns]="columnsWithLink$ | async"
						[rows]="rows$ | async"
						[columnMode]="'standard'"
						[rowIdentity]="rowIdentity"
						[scrollbarH]="true"
						[trackByProp]="'CustomerId'"
						[scrollbarV]="true"
						[rowHeight]="40"
						[headerHeight]="32"
						[footerHeight]="false"
						(reorder)="reorder($event)"
						(resize)="resize($event)"
						[externalSorting]="true"
						sortType="single"
						(sort)="sort($event)"
						[rowClass]="getRowClass"
						[sorts]="sorts$ | async"
						(page)="onPage($event, mydatatable.bodyComponent.indexes)"
					>
						<ngx-datatable-column
							*ngFor="let c of columns$ | async"
							[prop]="c.prop"
							[width]="c.width"
							[resizeable]="true"
							[name]="c.name"
							[cellClass]="c.cellClass"
							[draggable]="!(c.metakey === 'Name')"
							[frozenLeft]="c.metakey === 'Name'"
						>
							<ng-template
								ngx-datatable-header-template
								let-column="column"
								let-sort="sortFn"
							>
								<i
									class="material-icons draggable"
									[id]="c.columnId"
									[class.d-none]="c.metakey === 'Name'"
									>drag_indicator</i
								>
								<span class="datatable-header-cell-wrapper" (click)="sort()">
									<span class="datatable-header-cell-label">{{
										column.name
									}}</span>
								</span>
							</ng-template>
							<ng-template
								ngx-datatable-cell-template
								let-row="row"
								let-value="value"
								let-rowIndex="rowIndex"
							>
								<ng-container [ngSwitch]="c.controlType">
									<app-display
										*ngSwitchCase="'display'"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>
									<app-display
										*ngSwitchCase="'display-date'"
										[route]="route(c.metakey, row)"
										[value]="value.value | momentDatetime"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>
									<app-long-display
										*ngSwitchCase="'long-display'"
										[value]="value.value"
										[route]="route(c.metakey, row)"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									></app-long-display>
									<!-- TODO: Remove Adviser in the condition -->
									<app-dropdown
										*ngSwitchCase="'dropdown'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										[choices]="c.choices"
										[choicesObject]="c.choicesAsObject"
										[hasSort]="
											c.metakey === 'Adviser' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser'"
										[allChoices]="
											c.metakey === 'Adviser' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser'
												? (completeAdvisers$ | async)
												: undefined
										"
										[allChoicesObject]="
											c.metakey === 'Adviser' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser'
												? (adviserChoicesAsObject$ | async)
												: undefined
										"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-dropdown>
									<app-textbox
										*ngSwitchCase="'textbox'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-textbox>
									<app-address
										*ngSwitchCase="'address'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-address>
									<app-date
										*ngSwitchCase="'date'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-date>
									<ng-container
										*ngSwitchCase="'industry'"
										[ngSwitch]="row.IsCompany"
									>
										<app-textbox
											*ngSwitchCase="true"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[value]="value.value"
											[restrict]="value.restrict"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											[isEditing]="
												(cellsEditing$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											[tempValue]="
												(cellsTempvalue$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											[isRequired]="c.isRequired"
											[isEditable]="value.editable"
											(editEvent)="edit(row.CustomerId, c.metakey)"
											(cancelEvent)="cancel(row.CustomerId, c.metakey)"
											(saveEvent)="saveField(row, c.metakey, value.key, $event)"
											(fieldUpdateEvent)="
												setTempValue(row.CustomerId, c.metakey, $event)
											"
										></app-textbox>
										<app-dropdown
											*ngSwitchCase="false"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[value]="value.value"
											[restrict]="value.restrict"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											[isEditing]="
												(cellsEditing$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											[tempValue]="
												(cellsTempvalue$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											[isRequired]="c.isRequired"
											[isEditable]="value.editable"
											[choices]="c.choices"
											[choicesObject]="c.choicesAsObject"
											(editEvent)="edit(row.CustomerId, c.metakey)"
											(cancelEvent)="cancel(row.CustomerId, c.metakey)"
											(saveEvent)="saveField(row, c.metakey, value.key, $event)"
											(fieldUpdateEvent)="
												setTempValue(row.CustomerId, c.metakey, $event)
											"
										></app-dropdown>
									</ng-container>
									<ng-container
										*ngSwitchCase="'activity'"
										[ngSwitch]="c.metakey"
									>
										<app-activity-detail
											*ngSwitchCase="'Client Next Activity'"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[cellClass]="getCellClass(value, row)"
											[value]="value.value"
											[restrict]="value.restrict"
											[activityId]="row.ClientNextActivityId"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											(editActivityEvent)="
												updateClientNextActivityField(row.CustomerId)
											"
											[permissionsToComplete]="['FCCA']"
										></app-activity-detail>
										<app-activity-detail
											*ngSwitchCase="'User Next Activity'"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[cellClass]="getCellClass(value, row)"
											[value]="value.value"
											[restrict]="value.restrict"
											[activityId]="row.UserNextActivityId"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											(editActivityEvent)="
												updateUserNextActivityField(row.CustomerId)
											"
											[permissionsToComplete]="['FCCA']"
										></app-activity-detail>
									</ng-container>
									<app-note
										*ngSwitchCase="'note'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[activityType]="row.IsCompany ? 'PCC' : 'PCI'"
										[isActivity]="true"
										[restrict]="value.restrict"
										[isEditable]="value.editable"
										(updateNoteEvent)="updateNoteField(row.CustomerId, $event)"
										[customerId]="row.CustomerId"
									>
									</app-note>
								</ng-container>
							</ng-template>
						</ngx-datatable-column>
						<ngx-datatable-column
							[prop]="linkColumn.prop"
							[name]="linkColumn.name"
							[width]="linkColumn.width"
							[resizeable]="false"
							headerClass="link-header"
							[cellClass]="'link-ellipses'"
						>
							<ng-template
								ngx-datatable-cell-template
								let-value="value"
								let-row="row"
								let-rowIndex="rowIndex"
							>
								<ng-container *ngIf="!(rowsLoading$ | async)[row.CustomerId]">
									<div
										class="dropdown d-flex"
										dropdown
										container="body"
										placement="bottom right"
									>
										<button
											id="toggleMoreBtn_{{rowIndex}}"
											class="tap-btn tap-btn-outline tap-text-primary px-0 py-0"
											dropdownToggle
										>
											<i
												class="
													material-icons
													icon-btn
													md-18
													d-flex
													align-items-center
													px-0
													py-0
													w-auto
												"
											>
												more_vert
											</i>
										</button>
										<ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
											<a
												id="addActivityBtn_{{rowIndex}}"
												*appRestrictTo="['FACA']"
												class="dropdown-item clickable tap-tex-primary"
												(click)="createClientNextActivity(row.CustomerId)"
											>
												Add Activity
											</a>
											<a
												id="editBtn_{{rowIndex}}"
												*ngIf="row.link.IsCompany"
												[routerLink]="businessRoute(row.CustomerId)"
												class="dropdown-item clickable tap-tex-primary"
											>
												Edit
											</a>
											<a
												id="editBtn_{{rowIndex}}"
												*ngIf="!row.link.IsCompany"
												[routerLink]="clientRoute(row.CustomerId)"
												class="dropdown-item clickable tap-tex-primary"
											>
												Edit
											</a>
											<a
												id="deleteBtn_{{rowIndex}}"
												*appRestrictTo="['FDC']"
												class="dropdown-item clickable tap-tex-primary"
												(click)="delete(row.CustomerId)"
											>
												Delete
											</a>
										</ul>
									</div>
								</ng-container>
								<app-manual-loader-mini
									*ngIf="(rowsLoading$ | async)[row.CustomerId]"
									[isLoading]="(rowsLoading$ | async)[row.CustomerId]"
								></app-manual-loader-mini>
							</ng-template>
						</ngx-datatable-column>
					</ngx-datatable>
				</div>
			</div>
		</div>
	</div>
</div>
