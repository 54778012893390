<div #sosDocument>
	<ng-container *ngIf="!!defaultSetting; else emptySettings">
		<div class="scope-of-service clearfix sos-document mb-3">

			<div
				*ngIf="isLoading; else showForm"
				class="d-block"
				[class.d-none]="!isLoading"
			>
				<app-manual-loader-mini
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</div>

			<ng-template #showForm>
				<div class="scope-of-service__content">
					<form [formGroup]="form" class="form">
						<div class="row mb-2">
							<div class="col-12">
								<div class="row">
									<div class="col-12 scope-of-service__labels">
										<label class="label-font font-weight-bold tap-text-primary"
											>Intro</label
										>
									</div>
									<div class="col-12 scope-of-service__textbox">
										<p [innerHTML]="crtData?.intro || ''"></p>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<div class="row">
									<div class="col-12 scope-of-service__labels">
										<label class="label-font font-weight-bold tap-text-primary"
											>Services</label
										>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-12 col-12">
										<ng-container *ngIf="showLifeInsurance">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="lifeInsurance"
															[checked]="+LifeInsurance?.value === 1"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="lifeInsurance"
															>Life Insurance</label
														>
													</div>
												</div>
											</div>
											<div
												class="row scope-of-service__services"
												*ngIf="LifeInsurance.value === 0"
											>
												<div class="col-12 scope-of-service__textbox">
													<textarea
														rows="4"
														id="lifeInsuranceDetails"
														name="lifeInsuranceDetails"
														formControlName="lifeInsuranceDetails"
														placeholder="Details"
														class="crt-form-control"
														(change)="onChange()"
													></textarea>
												</div>
											</div>
										</ng-container>

										<ng-container *ngIf="showCriticalIllness">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="criticalIllness"
															[checked]="+CriticalIllness?.value === 1"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="criticalIllness"
															>Critical Illness</label
														>
													</div>
												</div>
											</div>
											<div
												class="row scope-of-service__services"
												*ngIf="CriticalIllness?.value === 0"
											>
												<div class="col-12 scope-of-service__textbox">
													<textarea
														rows="4"
														id="criticalIllnessDetails"
														name="criticalIllnessDetails"
														formControlName="criticalIllnessDetails"
														placeholder="Details"
														class="crt-form-control"
														(change)="onChange()"
													></textarea>
												</div>
											</div>
										</ng-container>

										<ng-container *ngIf="showTPDInsurance">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="tPDInsurance"
															[checked]="+TPDInsurance?.value === 1"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="tPDInsurance"
															>TPD Insurance</label
														>
													</div>
												</div>
											</div>
											<div
												class="row scope-of-service__services"
												*ngIf="TPDInsurance?.value === 0"
											>
												<div class="col-12 scope-of-service__textbox">
													<textarea
														rows="4"
														id="tPDInsuranceDetails"
														name="tPDInsuranceDetails"
														formControlName="tPDInsuranceDetails"
														placeholder="Details"
														class="crt-form-control"
														(change)="onChange()"
													></textarea>
												</div>
											</div>
										</ng-container>

										<ng-container *ngIf="showDisabilityInsurance">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="disabilityInsurance"
															[checked]="+DisabilityInsurance?.value === 1"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="disabilityInsurance"
															>Disability Insurance</label
														>
													</div>
												</div>
											</div>
											<div
												class="row scope-of-service__services"
												*ngIf="DisabilityInsurance?.value === 0"
											>
												<div class="col-12 scope-of-service__textbox">
													<textarea
														rows="4"
														id="disabilityInsuranceDetails"
														name="disabilityInsuranceDetails"
														formControlName="disabilityInsuranceDetails"
														placeholder="Details"
														class="crt-form-control"
														(change)="onChange()"
													></textarea>
												</div>
											</div>
										</ng-container>

										<ng-container *ngIf="showMedicalInsurance">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="medicalInsurance"
															[checked]="+MedicalInsurance?.value === 1"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="medicalInsurance"
															>Medical Insurance</label
														>
													</div>
												</div>
											</div>
											<div
												class="row scope-of-service__services"
												*ngIf="MedicalInsurance?.value === 0"
											>
												<div class="col-12 scope-of-service__textbox">
													<textarea
														rows="4"
														id="medicalInsuranceDetails"
														name="medicalInsuranceDetails"
														formControlName="medicalInsuranceDetails"
														placeholder="Details"
														class="crt-form-control"
														(change)="onChange()"
													></textarea>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<div class="row">
									<div class="col-12 scope-of-service__textbox">
										<textarea
											rows="4"
											id="instructionOrLimitation"
											name="instructionOrLimitation"
											formControlName="instructionOrLimitation"
											placeholder="Any specific instruction or limitations to the service"
											class="crt-form-control"
											(change)="onChange()"
										></textarea>
									</div>
								</div>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-12">
								<div class="row">
									<div class="col-12 scope-of-service__textbox">
										<p [innerHTML]="crtData?.otherInformation || ''"></p>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<div class="row">
									<div class="col-12">
										<label class="font-weight-bold">Other Advice</label>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<ng-container *ngIf="showBusinessRiskInsurance">
									<div class="row">
										<div class="col-lg-12 col-12 pb-3">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="businessRiskInsurance"
															[checked]="+BusinessRiskInsurance?.value === 1"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="businessRiskInsurance"
															>Business Risk Insurance</label
														>
													</div>
												</div>
											</div>

											<div class="row scope-of-service__services">
												<div
													class="col-12 scope-of-service__textbox"
													*ngIf="+BusinessRiskInsurance.value === 1"
												>
													<p [innerHTML]="crtData?.businessRiskInsuranceNote || ''"></p>
												</div>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="showKiwiSaver">
									<div class="row">
										<div class="col-lg-12 col-12 pb-3">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="kiwiSaver"
															[checked]="+KiwiSaver?.value === 1"
															(change)="tickChange($event)"
														/>
														<label class="custom-control-label" for="kiwiSaver"
															>KiwiSaver</label
														>
													</div>
												</div>
											</div>

											<div class="row scope-of-service__services">
												<div
													class="col-12 scope-of-service__textbox"
													*ngIf="+KiwiSaver?.value === 1"
												>
													<p [innerHTML]="crtData?.kiwiSaverNote || ''"></p>
												</div>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="showHomeCarAndContentsInsurance">
									<div class="row">
										<div class="col-lg-12 col-12 pb-3">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="homeCarAndContentsInsurance"
															[checked]="
																+HomeCarAndContentsInsurance?.value === 1
															"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="homeCarAndContentsInsurance"
															>Home, Car & Contents Insurance</label
														>
													</div>
												</div>
											</div>

											<div class="row scope-of-service__services">
												<div
													class="col-12 scope-of-service__textbox"
													*ngIf="+HomeCarAndContentsInsurance?.value === 1"
												>
													{{ crtData?.homeCarAndContentsInsuranceNote }}
												</div>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="showResidentialMortgageOrLending">
									<div class="row">
										<div class="col-lg-12 col-12 pb-3">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="residentialMortgageOrLending"
															[checked]="
																+ResidentialMortgageOrLending?.value === 1
															"
															(change)="tickChange($event)"
														/>
														<label
															class="custom-control-label"
															for="residentialMortgageOrLending"
															>Residential Mortgage / Lending</label
														>
													</div>
												</div>
											</div>

											<div class="row scope-of-service__services">
												<div
													class="col-12 scope-of-service__textbox"
													*ngIf="+ResidentialMortgageOrLending?.value === 1"
												>
													{{ crtData?.residentialMortgageOrLendingNote }}
												</div>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="showOther">
									<div class="row">
										<div class="col-lg-12 col-12">
											<div class="row scope-of-service__services">
												<div class="col">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="chckbox custom-control-input"
															id="other"
															[checked]="+Other?.value === 1"
															(change)="tickChange($event)"
														/>
														<label class="custom-control-label" for="other"
															>Other</label
														>
													</div>
												</div>
											</div>

											<div
												class="row scope-of-service__services"
												*ngIf="Other?.value === 1"
											>
												<div class="col-12 scope-of-service__textbox">
													<textarea
														rows="4"
														id="otherNotes"
														name="otherNotes"
														formControlName="otherNotes"
														class="crt-form-control"
														(change)="onChange()"
													></textarea>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</form>
				</div>
			</ng-template>
		</div>
	</ng-container>
</div>
<ng-template #emptySettings>
	<label class="p-3"
		>Contact your business owner to add an introduction template</label
	>
</ng-template>

<div class="p-4 scope-of-service__buttons clearfix">
	<button
		id="saveSosBtn"
		type="button"
		class="tap-btn tap-btn-outline tap-btn--shadow mr-2"
		[disabled]="isSaving || isLoading || !defaultSetting"
		(click)="saveDocument()"
	>
		Save to File
	</button>
	<button
		id="reloadSosBtn"
		type="button"
		class="tap-btn tap-btn-outline tap-btn--shadow mr-2"
		[disabled]="isSaving || isLoading || !defaultSetting"
		(click)="reloadTemplate()"
	>
		Reload Template
	</button>
	<div *ngIf="isSaving" class="d-inline-block">
		<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
	</div>
</div>
