<div class="notes" *ngIf="!isLoading">
	<form action="" class="form">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-12 pb-2">
						<span class="title">Purpose</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
							#purposeEditor
							[simpleEditor]="true"
							[content]="notes.purpose"
							[extendOptions]="optionsWysiswyg"
							[showToolbar]="false"
						></app-wysiwyg>
					</div>
				</div>
				<div class="row">
					<div class="col-12 py-2">
						<span class="title">Client Background & Character</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
							#clientBackgroundEditor
							[simpleEditor]="true"
							[content]="notes.clientBackgroundAndCharacter"
							[extendOptions]="optionsWysiswyg"
							[showToolbar]="false"
						></app-wysiwyg>
					</div>
				</div>
				<div class="row">
					<div class="col-12 py-2">
						<span class="title">Recommendation</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
							#recommendationEditor
							[simpleEditor]="true"
							[content]="notes.recommendation"
							[extendOptions]="optionsWysiswyg"
							[showToolbar]="false"
						></app-wysiwyg>
					</div>
				</div>
				<div class="row">
					<div class="col-12 py-2">
						<span class="title">Other Information</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
							#otherInformationEditor
							[simpleEditor]="true"
							[content]="notes.otherInformation"
							[extendOptions]="optionsWysiswyg"
							[showToolbar]="false"
						></app-wysiwyg>
					</div>
				</div>
				<div class="row justify-content-end">
					<div class="col-auto d-flex justify-content-start">
						<button
							mat-button
							[disabled]="!!isSaving"
							class="tap-btn tap-btn--primary text-white ml-2"
							(click)="saveNotes()"
						>
							Save
						</button>

						<ng-container *ngIf="!!isSaving">
							<app-manual-loader-mini
								class="pt-2 justify-content-end"
								[isLoading]="!!isSaving"
							>
							</app-manual-loader-mini>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
