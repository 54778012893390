import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import {
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { DeleteModalComponent } from '../../../../../shared/modal/delete-modal/delete-modal.component';
import { PeopleModalComponent } from '../../../../../shared/modal/crt/fact-find/people/people-modal.component';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { PeopleService } from '../../states/people/people.service';
import { companyToViewDisplayValueUtil } from '../../util/mapViewDisplayValue.util';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { logMessage } from 'src/app/shared/error-message/error-message';
declare var $: any;

@Component({
	selector: 'app-company',
	templateUrl: './company.component.html',
	styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
	constructor(
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private query: ClientReviewTemplateQuery,
		private peopleService: PeopleService,
		private cd: ChangeDetectorRef
	) {}
	onDestroy$ = new Subject<void>();

	@Input() isAdviceProcessEnded: boolean;

	@Input() company$: Observable<any[]>;

	@Input() APCRTBP$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;

	@Input() addFn$: ({ company, adviceProcessId }) => Observable<any>;
	@Input() addNewFn$: ({ company, adviceProcessId }) => Observable<any>;
	@Input() updateFn$: (company) => Observable<any>;
	@Input() deleteFn$: (id) => Observable<any>;
	@Input() isCompanyLoading$: Observable<boolean>;
	primaryColor: string;
	elseMinusCompany = true;
	public bsModalRef: BsModalRef;

	crtLoading: number;
	isAddNew = false;
	isSaving = false;

	isLoading$ = this.query.selectLoading();
	companyList$ = new BehaviorSubject<ViewDisplayValue[]>(null);

	companyInfo = (crtId) => this.peopleService.getPersonInfo(crtId);

	ngOnInit(): void {}

	collapseMoreCompany() {
		$('#collapseCompany').toggle();
		this.elseMinusCompany = false;
	}
	collapseLessCompany() {
		$('#collapseCompany').toggle();
		this.elseMinusCompany = true;
	}

	toggleLoader(cRTId: number, load: boolean) {
		this.crtLoading = cRTId && load ? +cRTId : null;
	}

	isCrtLoading(cRTId: number) {
		if (this.isAddNew && !this.isSaving) {
			return false;
		}
		if (this.isSaving && cRTId === 0) {
			return true;
		}
		return +this.crtLoading === +cRTId;
	}

	addCompany() {
		this.isAddNew = true;
		this.cd.detectChanges();

		this.peopleService.addBlankCompany();
		this.query.companyFromCRMAndCRT$
			.pipe(
				switchMap((x) => companyToViewDisplayValueUtil(x)),
				tap((x) => this.companyList$.next(x)),
				take(1)
			)
			.subscribe();
	}

	addNewCompany = (model) =>
		new Observable<any>((obs) => {
			this.isSaving = true;
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				this.addNewFn$({
					company: x,
					adviceProcessId: parseInt(
						this.route.snapshot.paramMap.get('adviceProcessId'),
						10
					),
				})
			),
			finalize(() => {
				this.isAddNew = false;
				this.isSaving = false;
				this.cd.detectChanges();
			})
		);

	change(e, i) {
		if (e.target.value === 'new') {
			const decline = new Observable((obs: Observer<any>) => {
				(
					document.getElementById(
						'addNewCompanyDropdown_' + i
					) as HTMLInputElement
				).value = '';
				obs.complete();
			});
			const initState = {
				header: 'Company Details',
				message: `CompanyDetails`,
				apcrtbp$: this.APCRTBP$,
				apcrtyn$: this.APCRTYN$,
				apcrtynna$: this.APCRTYNNA$,
				savefn: this.addNewCompany,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(PeopleModalComponent, {
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.query.companyFromCRMAndCRT$
				.pipe(
					map((x) => x?.find((val) => val.customerID === +e.target.value)),
					mergeMap((x) =>
						this.company$.pipe(
							map((p) => {
								p[i] = {
									cRTId: 0,
									age: null,
									birthDate: null,
									customerType: x.customerType,
									name: x.companyName,
									relationship: null,
									customerId: x.customerID,
									tradingName: x.tradingName,
									industry: x.industry,
								};
							})
						)
					),
					take(1)
				)
				.subscribe();
		}
	}

	addExistingCompany(p) {
		this.isSaving = true;
		this.query.companyFromCRMAndCRTNoFilter$
			.pipe(
				map((x) => x?.find((c) => c.customerID === +p.customerId)),
				map((client) => {
					const adviceProcessId = parseInt(
						this.route.snapshot.paramMap.get('adviceProcessId'),
						10
					);
					const company = {
						customerID: client.customerID,
						adviceProcessId,
						sectionCode: AdviceProcessSectionCodes.Company,
						businessName: client.companyName,
						tradingName: client.tradingName,
						purpose: client.purpose ? client.purpose : '',
						industry: client.industry ? client.industry : '',
						noOfEmployees: client.noOfEmployees ? client.noOfEmployees : 0,
						hasAccountant: client.hasAccountant ? client.hasAccountant : '',
						hasShareholdersAgreement: client.hasShareholdersAgreement
							? client.hasShareholdersAgreement
							: '',
						hasBuySellAgreement: client.hasBuySellAgreement
							? client.hasBuySellAgreement
							: '',
						businessValued: client.businessValued ? client.businessValued : '',
						businessValue: client.businessValue ? client.businessValue : 0,
						shareholderUnableToWork: client.shareholderUnableToWork
							? client.shareholderUnableToWork
							: '',
						shareholderPassesAway: client.shareholderPassesAway
							? client.shareholderPassesAway
							: '',
						shareholdersList: client.shareholdersList
							? client.shareholdersList
							: [],
						directorsList: client.directorsList ? client.directorsList : [],
						otherKeyPeopleList: client.otherKeyPeopleList
							? client.otherKeyPeopleList
							: [],
						accountantList: client.accountantList ? client.accountantList : [],
					};
					return { company, adviceProcessId };
				}),
				take(1),
				mergeMap((res) =>
					this.addFn$({
						company: res.company,
						adviceProcessId: res.adviceProcessId,
					})
				),
				finalize(() => {
					this.isAddNew = false;
					this.isSaving = false;
					this.cd.detectChanges();
				})
			)
			.subscribe();
	}

	removeCompany(index) {
		this.company$
			.pipe(
				map((x) => x?.splice(index, 1)),
				take(1)
			)
			.subscribe(() => {
				this.isAddNew = false;
				this.cd.detectChanges();
			});
	}

	deleteCompany(id: number) {
		this.toggleLoader(id, true);

		const confirm = new Observable((obs) => {
			this.deleteItem(id);
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.toggleLoader(id, false);
			obs.complete();
		});

		const initState = {
			header: 'Delete Company',
			message: logMessage.oat.shared.factFind.delete,
			delete$: confirm,
			decline$: decline,
			confirmButton: 'OK',
			detachCloseIcon: false,
		};
		this.bsModalRef = this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteItem = (id: number) =>
		this.deleteFn$(id)
			.pipe(
				mergeMap(() => this.updateCompanyDd()),
				take(1),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => {
				this.toggleLoader(id, false);
			});

	updateCompany = (model) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.updateFn$(x)),
			finalize(() => {
				this.isAddNew = false;
				this.toggleLoader(+model?.cRTId, false);
				this.cd.detectChanges();
			})
		);

	editCompany(crtId: number) {
		this.toggleLoader(+crtId, true);
		const decline = new Observable((obs: Observer<any>) => {
			this.toggleLoader(crtId, false);
			obs.complete();
		});
		this.companyInfo(crtId).subscribe((x) => {
			const initState = {
				header: 'Company Details',
				message: `CompanyDetails`,
				apcrtbp$: this.APCRTBP$,
				apcrtyn$: this.APCRTYN$,
				apcrtynna$: this.APCRTYNNA$,
				companyInfo: x,
				savefn: this.updateCompany,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(PeopleModalComponent, {
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		});
	}

	viewCompany(crtId: number) {
		this.toggleLoader(+crtId, true);
		const decline = new Observable((obs: Observer<any>) => {
			this.toggleLoader(crtId, false);
			obs.complete();
		});
		this.companyInfo(crtId).subscribe((x) => {
			const initState = {
				header: 'Company Details',
				message: `CompanyDetails`,
				apcrtbp$: this.APCRTBP$,
				apcrtyn$: this.APCRTYN$,
				apcrtynna$: this.APCRTYNNA$,
				companyInfo: x,
				decline$: decline,
				viewMode: true
			};
			this.bsModalRef = this.modalService.show(PeopleModalComponent, {
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		});
	}

	updateCompanyDd = () =>
		this.query.companyFromCRMAndCRTNoFilter$.pipe(
			mergeMap((x) => companyToViewDisplayValueUtil(x)),
			tap((x) => this.companyList$.next(x?.filter((val) => !!val.value))),
			take(1)
		);

	ngOnDestroy() {
		this.company$
			.pipe(
				map((x) => {
					if (x?.some((p) => p?.cRTId === 0 || !p?.cRTId)) {
						x?.splice(
							x.findIndex((p) => p?.cRTId === 0 || !p.cRTId),
							1
						);

						return x;
					}
					return x;
				}),
				take(1)
			)
			.subscribe();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
