import { TableColumn } from '@swimlane/ngx-datatable';
import { EmailHistoryModel } from './state/email-history.model';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';

export type Metakey =
	| 'EmailType'
	| 'DateSent'
	| 'DateTarget'
	| 'Success'
	| 'Failed'
	| 'EmailMode'
	| 'EmailHistoryId';

export type controlType = 'display' | 'money' | 'number' | 'display-datetime' | 'success' | 'failed';
/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */

type EnhancedTableColumn = (
	TableColumn &
	{
		/** Metakey of the column. Used for indexing ui state and requesting changes */
		metakey: Metakey,
		/** Property key in rows. */
		prop: keyof EmailHistoryModel,
		/** Replaces `ascFn` and `descFn`. Provides sortable values. */
		sortValueGetter: ((field: any, choices?: ViewDisplayValue[]) => any),
		/** Dropdown choices used for fields in this column.
		 * To be filled up in Query file.
		 */
		choices?: ViewDisplayValue[],
		/** Dropdown choices used for fields in this column.
		 * To be filled up in Query file.
		 */
		choicesAsObject?: { [key: string]: string },
		/** Type of control.
		 * Determines which datatable field control to use.
		 * Carefully check that the type used is same here and in the template.
		 */
		controlType: controlType;
		/** indicator if column is required */
		isRequired?: boolean;
	});

export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'EmailType',
		prop: 'EmailType',
		name: 'Email Type',
		width: 255,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'DateSent',
		prop: 'DateSent',
		name: 'Date Sent',
		width: 255,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display-datetime'
	},
	{
		metakey: 'DateTarget',
		prop: 'DateTarget',
		name: 'Date Target',
		width: 255,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display-datetime'
	},
	{
		metakey: 'Success',
		prop: 'Success',
		name: 'Success',
		width: 130,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'success'
	},
	{
		metakey: 'Failed',
		prop: 'Failed',
		name: 'Failed',
		width: 130,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'failed'
	},
	{
		metakey: 'EmailMode',
		prop: 'EmailMode',
		name: 'Email Mode',
		width: 255,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'EmailHistoryId',
		prop: 'EmailHistoryId',
		name: 'Email History Id',
		width: 255,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
];



/**
 * column configuration for link
 */
export const linkColumn: (TableColumn & { prop: 'link' }) = {
	prop: 'link',
	name: '',
	width: 119
};
