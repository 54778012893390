import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { MeetingStats } from './msw.model';
import { Injectable } from "@angular/core";

export interface MeetingStatsState {

  /**
   * Meeting Stats fields
   */
  MeetingStats?: MeetingStats[];

  /**
   * error message. will show up when not empty
   */
  error: string;

  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): MeetingStatsState {
  return {
    MeetingStats: [],
    error: '',
    isLoading: false
  };
}


/**
 * Meeting Stats widget data store
 */
@Injectable()
@StoreConfig({
  name: 'MeetingStats'
})
export class MeetingStatsStore extends Store<MeetingStatsState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Set if Meeting Stats is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
