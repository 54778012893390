import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	NgZone,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ScopeOfServiceSettingsService } from 'src/app/modules/mortgage-settings/scope-of-service-settings/state/scope-of-service-settings.service';
import { CrtEmailModalComponent } from 'src/app/shared/modal/crt/email/crt-email-modal/crt-email-modal.component';
import { EmailService } from '../../../client-review-template/states/email/email.service';
import { EmailTypes } from '../../../../../shared/models/emails/mortgage/email.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, iif, Observable, Observer, of } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	MOATDocumentField,
	AdviceProcessPageCodes,
	AdviceProcessPageNamesByCode,
	AdviceProcessRoutes,
	AdviceProcessSectionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { MergeTagsService } from '../state/merge-tags/merge-tags.service';
import { CrtMortgageQuery } from '../state/crt-mortgage.query';
import { ScopeOfServiceState, SoSInputCheckbox } from './state/scope-of-service.model';
import { ScopeOfServiceService, SosCfffOptions } from './state/scope-of-service.service';
import { either, isNil, isEmpty } from 'ramda';
import {
	filterNonEmailMergeTags,
	getContentWithMergeTags,
	removeMtWrappersOnTextarea,
} from 'src/app/shared/converter/content-merge-tags';
import { ScopeOfServiceMapper } from './state/scope-of-service.mapper';
import { CrtMortgageService } from '../state/crt-mortgage.service';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { AdviceProcessDocumentTypesMOAT, DocumentTypesMOAT } from 'src/app/shared/models/documents/document.model';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { CrtDocumentService } from '../../_shared/service/crt-document.service';
import { PeopleEntitiesQuery } from '../client-sop/people-entities/state/people-entities.query';
import { MortgageAdviceProcessService } from '../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { convertUtil } from 'src/app/util/util';
import { SosMTMapper } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/scope-of-service/sos.mapper';

import { BaseCrtMortgageComponent } from '../base-crt-mortgage.component';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { PeopleEntitiesService } from '../client-sop/people-entities/state/people-entities.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { HtmlPdfConfigState } from '../../_shared/service/html-pdf/defaults-config';


@Component({
	selector: 'app-scope-of-services',
	templateUrl: './scope-of-services.component.html',
	styleUrls: ['./scope-of-services.component.scss'],
})
export class ScopeOfServicesComponent
	implements OnInit, BaseCrtMortgageComponent
{
	emailSettings$ = this.sosSettingsService.emailSettings$.pipe(
		map((data) =>
			either(isNil, isEmpty)(data) ? { isEnableEmailOption: false } : data
		)
	);
	adviceProcess = this.query.getValue().adviceProcess;
	public bsModalRef: BsModalRef;

	@ViewChild('sosDocument') sosDocument: ElementRef;

	title = AdviceProcessPageNamesByCode[AdviceProcessPageCodes.SOS];
	form: UntypedFormGroup;
	scopeOfService$ = this.query.scopeOfService$;
	sosDefault$ = this.query.sosDefault$;
	sosTemplateSettings$ = this.query.sosTemplateSettings$;
	crtData: ScopeOfServiceState;
	defaultSetting: ScopeOfServiceState;
	mergeTags: MergeTagState[];
	cRTId: number;
	adviceProcessId = this.query.getValue().adviceProcessId;
	adviceProcess$ = this.query.adviceProcess$;
	sidebar = this.query.getValue().sidebars;
	defaultDetails = 'is not included in the advice process because';
	isLoading = false;
	isSaving = false;
	showNewPurchase = false;
	showRefinance = false;
	showLendingTopup = false;
	showGeneralStructureReview = false;
	showBusinessLending = false;
	showOtherService = false;
	showKiwiSaverAdvice = false;
	showLifeAndRiskReview = false;
	showHouseCarContentsQuote = false;
	showOtherAdvice = false;
	inputChecboxes:SoSInputCheckbox[] = [];

	pageIds = AdviceProcessRoutes;

	optionsWysiswyg = {
		heightMax: null,
		heightMin: 250,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};

	themeConfig$ = this.businessConfigQuery.themeConfig$;
	pdfClasses = 'pdf-design-v2 pdf-design-v2-body';

	@ViewChild('contentEditor') contentEditor: WysiwygComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private sService: ScopeOfServiceService,
		private mtService: MergeTagsService,
		private query: CrtMortgageQuery,
		private mortgageService: CrtMortgageService,
		private modalService: BsModalService,
		private sosSettingsService: ScopeOfServiceSettingsService,
		private emailService: EmailService,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private loggerService: LoggerService,
		private documentService: CrtDocumentService,
		private peopleQuery: PeopleEntitiesQuery,
		private mApService: MortgageAdviceProcessService,
		private peopleService: PeopleEntitiesService,
		private businessConfigQuery: BusinessConfigQuery,
	) {
		this.buildForm();
	}

	get NewPurchase() {
		return this.form.get('newPurchase');
	}

	get LendingTopup() {
		return this.form.get('lendingTopup');
	}

	get BusinessLending() {
		return this.form.get('businessLending');
	}

	get Refinance() {
		return this.form.get('refinance');
	}

	get GeneralStructureReview() {
		return this.form.get('generalStructureReview');
	}

	get OtherService() {
		return this.form.get('otherService');
	}

	get Intro() {
		return this.form.get('intro');
	}

	get OtherSpecificClientGoals() {
		return this.form.get('otherSpecificClientGoals');
	}

	get KiwiSaverAdvice() {
		return this.form.get('kiwiSaverAdvice');
	}

	get HouseCarContentsQuote() {
		return this.form.get('houseCarContentsQuote');
	}

	get LifeAndRiskReview() {
		return this.form.get('lifeAndRiskReview');
	}

	get KiwiSaveAdviceDetails() {
		return this.form.get('kiwiSaverAdviceDetails');
	}

	get HouseCarContentsQuoteDetails() {
		return this.form.get('houseCarContentsQuoteDetails');
	}

	get LifeAndRiskReviewDetails() {
		return this.form.get('lifeAndRiskReviewDetails');
	}

	get OtherAdvice() {
		return this.form.get('otherAdvice');
	}

	ngOnInit(): void {
		this.mApService
			.updateMortApPageStarted(AdviceProcessPageCodes.SOS)
			.pipe(take(1))
			.subscribe();

		combineLatest([
			this.scopeOfService$,
			this.sosDefault$,
			this.mtService.mergeTags$,
		])
			.pipe(
				tap(([, , mt]) => {
					this.mergeTags = mt;
				}),
				filter(([crt, settings, mt]) => {
					isEmpty(settings) || isNil(settings)
						? (this.isLoading = false)
						: (this.isLoading = true);
					return !isNil(crt) && !isNil(settings) && !isNil(mt);
				}),
				tap(([crt, settings]) => {
					this.defaultSetting = settings;
					this.cRTId = crt?.cRTId;
				}),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe(([crt]) => this.loadSos(crt));
	}

	loadSos(crt?: ScopeOfServiceState) {
		combineLatest([of(crt), of(this.defaultSetting), of(this.mergeTags)])
			.pipe(
				tap(() => (this.isLoading = true)),
				concatMap(([getSettingsVal, settings, mt]) =>
					this.getSettings(getSettingsVal, settings, mt)
				),
				tap(([setServiceVal, settings]) =>
					this.setupServices(setServiceVal, settings)
				),
				map(([viewCartVal, settings]) =>
					ScopeOfServiceMapper.mapToViewCrt(viewCartVal, settings)
				),
				map((x) => ({ ...x, cRTId: this.cRTId })),
				map((x) =>
					either(isNil, isEmpty)(crt)
						? this.updateMergeTagsOnContent(x)
						: x
				),
				tap((x) => (this.crtData = x)),
				tap((x) => this.form.reset(x)),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe((x: ScopeOfServiceState) => {
				if (!this.cRTId || isNil(crt)) {
					// trigger if
					// !this.cRTId : first visit of page
					// isNil(crt) : null crt means reload template
					this.isLoading = false;
					this.onChange();
				}
			});
	}

	onChange() {
		const formValue = this.form.getRawValue();
		this.sService.updateScopeOfServiceTemp({
			...formValue,
			cRTId: this.cRTId,
			sectionCode: 'SOS',
			adviceProcessId: +this.adviceProcessId,
		});
	}

	getSettings = (crt, settings, mt) =>
		of(crt).pipe(
			concatMap((val) =>
				iif(() => isNil(val), this.sService.getSosDefault(), of(settings))
			),
			map((data) => [crt, data, mt]),
			take(1)
		);

	setupServices(crtData, settings: ScopeOfServiceState) {
		// if crtData is null, then reset (for reload)
		const reset = isNil(crtData);

		// services
		this.showNewPurchase =
			!reset && crtData?.cRTId
				? crtData?.newPurchase !== 2
				: !!settings?.newPurchase;
		this.showRefinance =
			!reset && crtData?.cRTId
				? crtData?.refinance !== 2
				: !!settings?.refinance;
		this.showLendingTopup =
			!reset && crtData?.cRTId
				? crtData?.lendingTopup !== 2
				: !!settings?.lendingTopup;
		this.showGeneralStructureReview =
			!reset && crtData?.cRTId
				? crtData?.generalStructureReview !== 2
				: !!settings?.generalStructureReview;
		this.showBusinessLending =
			!reset && crtData?.cRTId
				? crtData?.businessLending !== 2
				: !!settings?.businessLending;
		this.showOtherService =
			!reset && crtData?.cRTId
				? crtData?.otherService !== 2
				: !!settings?.otherService;
		this.showKiwiSaverAdvice =
			!reset && crtData?.cRTId
				? crtData?.kiwiSaverAdvice !== 2
				: !!settings?.kiwiSaverAdvice;
		this.showLifeAndRiskReview =
			!reset && crtData?.cRTId
				? crtData?.lifeAndRiskReview !== 2
				: !!settings?.lifeAndRiskReview;
		this.showHouseCarContentsQuote =
			!reset && crtData?.cRTId
				? crtData?.houseCarContentsQuote !== 2
				: !!settings?.houseCarContentsQuote;
		this.showOtherAdvice =
			!reset && crtData?.cRTId
				? crtData?.otherAdvice !== 2
				: !!settings?.otherAdvice;

		this.updateCheckboxArray();
	}

	updateMergeTagsOnContent(data) {
		const newData = {
			...data,
			intro: this.convertMergeTags(data?.intro, true) ?? '',
			otherSpecificClientGoals:
				this.convertMergeTags(data?.otherSpecificClientGoals, false) ?? '',
			kiwiSaverAdviceDetails:
				this.convertMergeTags(data?.kiwiSaverAdviceDetails, true) ?? '',
			lifeAndRiskReviewDetails:
				this.convertMergeTags(data?.lifeAndRiskReviewDetails, true) ?? '',
			houseCarContentsQuoteDetails:
				this.convertMergeTags(data?.houseCarContentsQuoteDetails, true) ?? '',
			otherAdviceNotes:
				this.convertMergeTags(data?.otherAdviceNotes, true) ?? '',
		};
		return newData;
	}

	convertMergeTags(content: string, convertToNonTextarea: boolean = false) {
		if (!convertToNonTextarea) {
			content = content?.trim()?.replace(/(?:\r\n|\r|\n)/gm, '<br />');
		}

		const data = getContentWithMergeTags(
			content,
			this.mergeTags,
			convertToNonTextarea
		);

		return removeMtWrappersOnTextarea(data, convertToNonTextarea);
	}

	buildForm() {
		this.form = this.fb.group({
			cRTId: [null],
			referenceId: [null],
			type: [null],
			newPurchase: [0],
			lendingTopup: [0],
			businessLending: [0],
			refinance: [0],
			generalStructureReview: [0],
			otherService: [0],
			otherServiceNotes: [null],
			otherSpecificClientGoals: [null],
			intro: [null],
			kiwiSaverAdvice: [0],
			kiwiSaverAdviceDetails: [null],
			lifeAndRiskReview: [0],
			lifeAndRiskReviewDetails: [null],
			houseCarContentsQuote: [0],
			houseCarContentsQuoteDetails: [null],
			otherAdvice: [0],
			otherAdviceNotes: [null],
		});
	}

	saveFn = () => {
		this.isLoading = true;
		this.onChange();
		return of(undefined).pipe(
			withLatestFrom(this.scopeOfService$),
			map(([, x]) => x),
			filter((x) => !either(isNil, isEmpty)(x)),
			tap(() => (this.isLoading = true)),
			concatMap((x) =>
				iif(
					() => either(isNil, isEmpty)(this.cRTId),
					this.sService
						.addScopeOfService({
							...x,
							clickedNext: true,
						})
						.pipe(tap((res) => (this.cRTId = +res))),
					this.sService.updateScopeOfService({
						...x,
						clickedNext: true,
					})
				)
			),
			finalize(() => {
				this.isLoading = false;
				this.mortgageService.setHasFormChanges(false);
			})
		);
	};

	onClickEmail() {
		const groupName = this.query
			.getValue()
			.primaryClient?.groupName?.toString()
			?.toUpperCase();
		const fileName = `${groupName} SCOPE OF SERVICE`;

		// Save SOS before sending Email
		// SOS must be saved because the CRT ID is needed for sending the email (used as the ReferenceId)
		this.saveFn()
			.pipe(
				concatMap(() => this.sosSettingsService.getEmailSettings(0)),
				// concatMap(() => this.renderSosTemplate()),
				concatMap(() => this.getPdfHeaderFooterOptions()),
				tap((newPdfOptions) => {
					const initState = {
						header: 'Email Client',
						enableDefaultBcc: true,
						sendEmailFn$: this.sendEmail,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.emailSettings$,
						adviceProcess: this.adviceProcess,
						peopleDropdown$: this.peopleQuery.peopleAndDependentsFromCRTOnly$,
						attachments: [
							{
								fileName: `${fileName}.pdf`,
								generateContentCallback$: this.renderSosTemplate(),
								newPdfOptions: {
									...newPdfOptions,
									FileName: fileName,
								},
							},
						],
						defaultLinkDocumentTab: ServicesCodes.Mortgage?.toLowerCase(),
						mergeTags$: filterNonEmailMergeTags(of(this.mergeTags)),
						documentInfo: {
							documentType: ServicesCodes.Mortgage,
							type: DocumentTypesMOAT.Application,
							referenceId: this.query.getValue().adviceProcessId,
							customerId: this.query.getValue().primaryClient?.customerID,
						}
					};

					const latestSos: ScopeOfServiceState =
						this.query.getValue().scopeOfService;
					this.cRTId = latestSos.cRTId;
					if (!isNil(this.cRTId)) {
						this.modalService.show(CrtEmailModalComponent, {
							class:
								'modal-dialog-centered modal-dialog modal-lg modal-workflow',
							initialState: initState,
							ignoreBackdropClick: true,
							keyboard: false,
						});
					}
				}),
				take(1)
			)
			.subscribe();
	}

	sendEmail = (data) => {
		// tslint:disable-next-line: max-line-length
		return this.emailService.sendToRecipients(
			data,
			EmailTypes.SCOPE_OF_SERVICE,
			this.cRTId,
			of(this.mergeTags)
		); // PASS THE CORRECT CRTID OF THE SCOPE OF SERVICE
	};

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(data),
					this.peopleService.updatePeople(data)
				)
			)
		);
	};

	tickChange(event) {
		const controllerName = event?.target?.id;
		const val = event?.target?.checked ? 1 : 0;
		this.form.get(controllerName).setValue(val);
		this.onChange();
	}

	saveDocument() {
		this.changeDetector.detectChanges();

		this.adviceProcess$.pipe(take(1)).subscribe((x) => {
			const sosDocument = x?.documents?.find(
				(d) => d.field === MOATDocumentField.SOS
			)?.value;

			if (isNil(sosDocument) || isEmpty(sosDocument)) {
				this.saveNewDocument().pipe(take(1)).subscribe();
			} else {
				this.saveExistingDocument(x);
			}
		});
	}

	renderSosTemplate() {
		return this.mtService.getSosMt(+this.adviceProcessId).pipe(
			concatMap(() => this.getSettingsTemplate()),
			withLatestFrom(
				this.mtService.mergeTags$,
				this.scopeOfService$,
				this.sosDefault$
			),
			map(([settingsTemplate, mergeTags, sosData, settings]) => {
				const updatedMT = SosMTMapper.moatSosMergeTags(
					mergeTags,
					sosData,
					settings
				);
				const contentWithMergeTags = getContentWithMergeTags(settingsTemplate, updatedMT);

				const removeParagraphTags = (content) => {
					const regex = /<p>%(MOAT_SOS_SERVICES_CHECKBOXES|MOAT_SOS_OTHER_ADVICE_CHECKBOXES)%<\/p>/g;
					return content.replace(regex, "$1");
				}
				return removeParagraphTags(contentWithMergeTags);
			}),
			map((x) => `<div class="sos-pdf-file ${this.pdfClasses}">${x}</div>`),
			take(1)
		);
	}

	getSettingsTemplate() {
		return this.sosTemplateSettings$.pipe(
			concatMap((x) =>
				iif(
					() => !!x?.templateLink,
					this.sService.getDocumentFromURL(x?.templateLink),
					of('<p></p>')
				)
			),
			take(1)
		);
	}

	saveNewDocument() {
		const groupName = this.query
			.getValue()
			.primaryClient?.groupName?.toString()
			?.toUpperCase();
		const fileName = `${groupName} SCOPE OF SERVICE`;
		return this.saveFn().pipe(
			concatMap(() => this.renderSosTemplate()),
			tap(() => (this.isSaving = true)),
			mergeMap((content) => {
				return this.getPdfHeaderFooterOptions().pipe(
					mergeMap((options) =>
						this.documentService.downloadDocumentPDF(content, fileName, {
							...options,
							FileName: fileName,
						})
					)
				)
			}),
			mergeMap((content) => convertUtil.convertToBase64(content)),
			map((template) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: template,
				FileName: `${fileName}.pdf`,
				DocumentType: ServicesCodes.Mortgage,
				Type: AdviceProcessDocumentTypesMOAT.SOS,
			})),
			concatMap((x) => this.documentService.saveDocument(x)),
			mergeMap((x) =>
				this.documentService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesMOAT.SOS
				)
			),
			tap((x) =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						'Successfully saved Scope of Service Document.'
					)
				)
			),
			finalize(() => (this.isSaving = false)),
			take(1)
		);
	}

	saveExistingDocument(adviceProcess) {
		const document = adviceProcess?.documents?.find(
			(d) => d.field === MOATDocumentField.SOS
		)?.value;

		const confirm = new Observable((obs) => {
			this.saveNewDocument().pipe(take(1)).subscribe();
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: 'Save Document',
			message: `A document is already uploaded for Scope of Service,`,
			subMessage: document?.fileName,
			secondaryMessage: `Do you want to replace this?`,
			confirm$: confirm,
			decline$: decline,
			isAcceptBtn: true,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.loadSos();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveComponentFunction = () => this.saveFn();

	private getPdfHeaderFooterOptions() {
		return this.themeConfig$.pipe(
			withLatestFrom(this.businessConfigQuery.businessOATLogo$),
			map(([theme, oatLogo]) => {
				// Header config For PDF
				const HeaderHtmlUrlParam = new URLSearchParams({
					headerText: 'Scope of Service',
					headerLogo: oatLogo?.toString()?.trim() || '',
				})?.toString();
				// Footer config for PDF
				const FooterHtmlUrlParam = new URLSearchParams({
					footerColor: theme?.primarycolor || '#00263e',
				})?.toString();

				return {
					...this.sService.getSosPdfOptions(),
					...SosCfffOptions,
					HeaderHtmlUrlParam,
					FooterHtmlUrlParam,
				} as HtmlPdfConfigState;
			}),
			take(1)
		);
	}
	updateCheckboxArray(){
		const tempArray:SoSInputCheckbox[] = [];
		if(this.showNewPurchase)
			tempArray.push({id:'newPurchase',checked:this.NewPurchase, label:'New Purchase'});
		if(this.showLendingTopup)
			tempArray.push({id:'lendingTopup',checked:this.LendingTopup, label:'Lending Topup'});
		if(this.showBusinessLending)
			tempArray.push({id:'businessLending',checked:this.BusinessLending, label:'Business Lending'});
		if(this.showRefinance)
			tempArray.push({id:'refinance',checked:this.Refinance, label:'Refinance'});
		if(this.showGeneralStructureReview)
			tempArray.push({id:'generalStructureReview',checked:this.GeneralStructureReview, label:'General Structure Review'});
		if(this.showOtherService)
			tempArray.push({id:'otherService',checked:this.OtherService, label:'Other'});
		this.inputChecboxes = tempArray;
	}

	trackByFn = (_, item: any) => {
		return item.id;
	}

}
