<div class="form-row mb-2 dependents-label-background">
  <div class="col-5 col-lg-9 text-right iconShow">
    <button
      id="dependentCollapseButton"
      type="button"
      class="
        btn-toggle
        bg-white
        collapse-btn
        border-0
        dependents-collapse-background
      "
    >
      <span *ngIf="elseMinusDependents; else elsePlusDependents">
        <i (click)="collapseDependents(true)" class="material-icons md-16 pt-1"
          >expand_more</i
        >
      </span>
      <ng-template #elsePlusDependents>
        <span>
          <i
            (click)="collapseDependents(false)"
            class="material-icons md-16 pt-1"
            >expand_less</i
          >
        </span>
      </ng-template>
    </button>
  </div>
</div>

<div
  class="form-row d-none d-lg-flex mb-2"
  [class.tap-opacity-25]="(dependents$ | async)?.length === 0"
>
  <div class="col-lg-3">
    <span class="font-weight-bold">Name</span>
  </div>
  <div class="col-lg-2">
    <span class="font-weight-bold">Date of Birth</span>
  </div>
  <div class="col-lg-2">
    <span class="font-weight-bold">Age</span>
  </div>
</div>

<div class="collapse expandable" id="collapseDependents">
  <div
    class="form-row align-items-center"
    *ngFor="let d of dependents$ | async; let i = index; let last = last"
  >
    <div class="d-md-none col-lg-2 text-right">
      <button
        id="dependentEditDependent_{{ i }}"
        type="button"
        class="icon-btn w-auto h-auto px-1"
        (click)="editDependent(d.cRTId)"
      >
        <i class="material-icons md-16"> edit </i>
      </button>
      <button type="button" class="icon-btn w-auto h-auto px-1">
        <i class="material-icons md-16"> delete </i>
      </button>
    </div>
    <div class="col-12 col-lg-3 mb-2" [class.border-bottom]="d.name && !last">
      <ng-container *ngIf="d.name">
        <input
          id="dependentName_{{ i }}"
          class="form-control crt-form-control"
          placeholder="People"
          type="text"
          value="{{ d.name }}"
          [disabled]="d.name"
        />
      </ng-container>
      <ng-container *ngIf="!d.name">
        <span class="custom-dropdown w-100">
          <select
            [id]="'addNewDependantsDropdown_' + i"
            name="addNewDependantsDropdown"
            class="form-control crt-form-control"
            (change)="change($event, i)"
          >
            <option value=""></option>
            <option *ngFor="let d of dependentList$ | async" [value]="d.value">
              {{ d.display }}
            </option>
            <option value="new">+ Add New Dependant</option>
          </select>
        </span>
      </ng-container>
    </div>
    <div
			class="col-12 col-lg-2 mb-2"
			[class.border-bottom]="d.name && !last"
		>
      <input
        *ngIf="d.name && d.name !== 'new'"
        id="dependentBirthDate_{{ i }}"
        class="form-control crt-form-control"
        type="text"
        value="{{ d.birthDate | momentDatetime }}"
        [disabled]="d.name"
      />
    </div>
    <div
			class="col-12 col-lg-2 mb-2"
			[class.border-bottom]="d.name && !last"
		>
      <input
        *ngIf="d.name && d.name !== 'new'"
        id="dependentAge_{{ i }}"
        class="form-control crt-form-control"
        placeholder="Age"
        type="text"
        value="{{ d.age }}"
        [disabled]="d.name"
      />
    </div>
    <div class="d-none d-md-block col-auto" [class.border-bottom]="d.name && !last">
      <ng-container *ngIf="d.cRTId && !isCrtLoading(d?.cRTId)">
        <button
          id="dependentEditDependentButton_{{ i }}"
          type="button"
          class="icon-btn w-auto h-auto px-1"
          (click)="editDependent(d.cRTId)"
          [disabled]="!!crtLoading"
        >
          <i class="material-icons md-16"> edit </i>
        </button>
        <button
          id="dependentDeleteDependentButton_{{ i }}"
          type="button"
          class="icon-btn w-auto h-auto px-1"
          (click)="deleteDependent(d.cRTId)"
          [disabled]="!!crtLoading"
        >
          <i class="material-icons md-16"> delete </i>
        </button>
      </ng-container>
      <ng-container *ngIf="isCrtLoading(d?.cRTId)">
        <app-manual-loader-mini
          [isLoading]="isCrtLoading(d?.cRTId)"
        ></app-manual-loader-mini>
      </ng-container>

      <ng-container *ngIf="!d.cRTId && !isCrtLoading(d?.cRTId)">
        <button
          id="dependentAddExistingDependentButton_{{ i }}"
          type="button"
          color="primary"
          class="icon-btn w-auto px-1 h-auto px-1"
          (click)="addExistingDependent(d)"
          [disabled]="!d.name"
        >
          <i class="material-icons md-16"> save </i>
        </button>
        <button
          id="dependentRemoveDependent_{{ i }}"
          type="button"
          class="icon-btn w-auto px-1 h-auto"
          (click)="removeDependent(i)"
        >
          <i class="material-icons md-16"> close </i>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="form-row my-4">
    <div class="col-12 pl-2">
      <button
        id="dependentAddDependentButton"
        type="button"
        (click)="addDependent()"
        class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
        [disabled]="isAddNew || (isDependantsLoading$ | async)"
      >
        Add Dependants +
      </button>
      <ng-container *ngIf="isDependantsLoading$ | async">
        <app-manual-loader-mini
          [isLoading]="isDependantsLoading$ | async"
        ></app-manual-loader-mini>
      </ng-container>
    </div>
  </div>

</div>
