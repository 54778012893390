import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlUserRoutingModule } from './bl-user-routing.module';
import { SharedModule } from '../../../../shared/shared.module';
import { UserComponentsModule } from '../user-components/user-components.module';
import { BlViewComponent } from '../../views/bl/bl-view/bl-view.component';
import { BlListComponent } from '../../views/bl/bl-list/bl-list.component';
import { BlAddComponent } from '../../views/bl/bl-add/bl-add.component';
import { UserDetailsModule } from '@modules/user/comps/user-details/user-details.module';
import { ManageUsersModule } from '@modules/user/comps/manage-users/manage-users.module';

@NgModule({
  imports: [
    CommonModule,
    BlUserRoutingModule,
    SharedModule,
    UserComponentsModule,
		UserDetailsModule,
		ManageUsersModule
  ],
  declarations: [
    BlAddComponent,
    BlListComponent,
    BlViewComponent
  ]
})
export class BlUserModule { }
