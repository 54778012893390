import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from '@angular/router';
import { StaffService } from '../../service/staff.service';
import { take } from 'rxjs/operators';
@Injectable()
export class TlStaffResolver implements Resolve<any> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.paramMap.get('id');
    return this.ss.getTlStaff(id).pipe(take(1));
  }

  constructor(private ss: StaffService) { }
}
