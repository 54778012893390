import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';

import { BehaviorSubject, Observable, Subject, concat, from, of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';

import { BusinessProfileQuery } from './business-profile.query';
import { BusinessProfileStore } from './business-profile.store';

import { EmailDocTypeModel } from '@modules/emails/email-settings/state/email-template.store';
import sort from 'fast-sort';
import produce from 'immer';
import { BusinessConfigService } from 'src/app/domain/business-config/business-config.service';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { AdviceProcessMapper } from 'src/app/shared/models/advice-process/advice-process.mapper';
import {
	AdviceProcessCode,
	ServiceAdviceProcess,
	ServiceAdviceProcessState,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { NoteTypes } from 'src/app/shared/models/notes/note.model';
import { Asset } from 'src/app/shared/models/services/assets/assets';
import { Investment, InvestmentState } from 'src/app/shared/models/services/investments/investments.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ApiService } from '../../../../core/base/api.service';
import { BusinessService } from '../../../../core/business/business.service';
import { NoteService } from '../../../../core/note/note.service';
import { ActivityService } from '../../../../core/services/activity/activity.service';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '../../../../domain/user/user.query';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import {
	ActivityState,
	ActivityTimelineState,
	NoteRequest,
	NoteState,
} from '../../../../shared/models/activity-timeline/activity-timeline.model';
import {
	BusinessPeopleTypes,
	PrimaryCustomerCompany,
	PrimaryCustomerCompanyState,
} from '../../../../shared/models/business-profile/business/business.model';
import { CurrentActivityCriteria } from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { DocumentGroup } from '../../../../shared/models/documents/document-group.model';
import { EditHistory, EditHistoryState } from '../../../../shared/models/history/history.model';
import { GetNotes } from '../../../../shared/models/notes/note-params.model';
import { FgInsurance, FgInsuranceState } from '../../../../shared/models/services/fg-insurance/fg-provider-group.model';
import { Kiwisaver, KiwisaverState } from '../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { LrInsurance, LrInsuranceState } from '../../../../shared/models/services/lr-insurance/lr-provider-group.model';
import { Mortgage, MortgageState } from '../../../../shared/models/services/mortgage/mortgage-group.model';
import { PropertyAsset } from '../../../../shared/models/services/property-asset/property-asset-group.model';
import { capitalizeFirstLetter, smallFirstLetter } from '../../../../shared/services/service-utils/document.util';
import { fgServiceUtil } from '../../../../shared/services/service-utils/fg-insurance.util';
import { kiwiSaverServiceUtil } from '../../../../shared/services/service-utils/kiwisaver.util';
import { lrSortServiceUtil } from '../../../../shared/services/service-utils/lr-insurance.util';
import { mortgageSortServiceUtil } from '../../../../shared/services/service-utils/mortgage-utils';
import {
	assetServiceUtil,
	propAndAssetServiceUtil,
} from '../../../../shared/services/service-utils/property-assets.util';
import { isObject } from '../../../../shared/services/service-utils/service.util';
import MomentUtil from '../../../../util/moment.util';
import { util, objectUtil } from '../../../../util/util';
import { BusinessProfileUtilsService } from './business-profile.utils.service';

@Injectable()
export class BusinessProfileService extends BusinessProfileUtilsService {
	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected api: ApiService,
		protected blStaffsQuery: BLStaffsQuery,
		protected store: BusinessProfileStore,
		protected query: BusinessProfileQuery,
		protected businessService: BusinessService,
		protected userQuery: UserQuery,
		protected noteService: NoteService,
		protected activityService: ActivityService,
		protected businessConfigQuery: BusinessConfigQuery,
		protected businessConfigService: BusinessConfigService,
	) {
		super(store, query, dropdownValueQuery, blStaffsQuery, businessConfigQuery, userQuery);
	}

	invokeApNoteFetchEvent = new BehaviorSubject('');
	apId = this.invokeApNoteFetchEvent.asObservable();

	// Email Client Pop Up
	openECModalSubject$ = new Subject<EmailDocTypeModel>();
	openECModalEmitter$ = this.openECModalSubject$.asObservable();
	closeECModalSubject$ = new Subject<EmailDocTypeModel>();
	closeECModalEmitter$ = this.closeECModalSubject$.asObservable();

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	/**
	 * Get Primary Company
	 * @param clientId Client Id
	 */
	getPrimaryCompany(clientId: number, service?: string, isPrimaryOnly?: boolean): Observable<PrimaryCustomerCompany> {
		return of(clientId).pipe(
			mergeMap(() => this.businessService.GetPrimaryCompany(clientId)),
			tap((x) =>
				applyTransaction(() => {
					const state = objectUtil.mapPascalCaseToCamelCase(x) as PrimaryCustomerCompanyState;
					this.store.setBusiness(state);
				}),
			),
			catchError(() => of({})),
			tap(() => {
				if (!isPrimaryOnly) {
					this.getDirectors(clientId).pipe(take(1)).subscribe();
					this.getShareholders(clientId).pipe(take(1)).subscribe();
					this.getOthers(clientId).pipe(take(1)).subscribe();

					if (service !== ServicesCodes.LR?.toLowerCase()) {
						this.getLRInsurance(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.Mortgage?.toLowerCase()) {
						this.getMortage(clientId).pipe(take(1)).subscribe();
						this.getSecurity(clientId).pipe(take(1)).subscribe();
						this.getAsset(clientId).pipe(take(1)).subscribe();
						this.getLiability(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.FG?.toLowerCase()) {
						this.getFGInsurance(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.KiwiSaver?.toLowerCase()) {
						this.getKiwisavers(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.Investment?.toLowerCase()) {
						this.getInvestments(clientId).pipe(take(1)).subscribe();
					}

					this.getClientDocuments(clientId).pipe(take(1)).subscribe();
					this.getClientHistories(clientId).pipe(take(1)).subscribe();
					this.getAdviceProcessesByPrimaryId(clientId).pipe(take(1)).subscribe();
				}
			}),
		);
	}

	/**
	 * Add Primary Business Individual
	 * @param company primary
	 */
	addCompany(company): Observable<unknown> {
		return of(company).pipe(
			mergeMap(() =>
				this.businessService.AddCompany({
					...company,
					customerType: CustomerTypes.PrimaryCustomerCompany,
				}),
			),
			mergeMap((id) =>
				company.note
					? this.businessService
							.AddNote({
								CustomerID: +id,
								CustomerServiceID: 0,
								Notes: company.note,
								ActivityType: CustomerTypes.PrimaryCustomerCompany,
							})
							.pipe(map(() => id))
					: of(id),
			),
			tap((x) =>
				applyTransaction(() => {
					this.store.setBusiness({ ...company, customerID: x });
				}),
			),
			catchError((err) => {
				return err['DuplicateEmail'] ? of(err) : of('');
			}),
		);
	}

	downloadLink(documentID) {
		return this.api.get<string>(`documents/download/${documentID}`);
	}

	updateNextReview(value: string, code: string) {
		const prepPayload = (pciData) => {
			switch (code) {
				case ServicesCodes.LR:
					return { ...pciData, lRNextReview: value };
				case ServicesCodes.Mortgage:
					return { ...pciData, mortgageNextReview: value };
				case ServicesCodes.FG:
					return { ...pciData, fGNextReview: value };
				case ServicesCodes.Investment:
					return { ...pciData, investmentKSNextReview: value };
				default:
					return pciData;
			}
		};
		return of(value).pipe(
			withLatestFrom(this.primaryCompany$),
			map(([, data]) => prepPayload(data)),
			mergeMap((data) =>
				this.businessService.UpdateCompany(data).pipe(
					tap(() =>
						applyTransaction(() => {
							this.store.setBusiness(data);
						}),
					),
					tap(() => this.getClientHistories(data?.customerID).pipe(take(1)).subscribe()),
					map(() => data),
				),
			),
			catchError(() => of('')),
		);
	}

	updateCompany(company): Observable<unknown> {
		return of(company).pipe(
			mergeMap(() => this.businessService.UpdateCompany(company)),
			tap(() =>
				applyTransaction(() => {
					this.store.setBusiness(company);
				}),
			),
			tap(() => this.getClientHistories(company.customerID).pipe(take(1)).subscribe()),
			catchError((err) => {
				return err['DuplicateEmail'] ? of(err) : of('');
			}),
		);
	}

	convertCompany(data) {
		return of(data).pipe(
			mergeMap((x) => {
				return this.businessService.ConvertCompany(x);
			}),
			tap(() =>
				applyTransaction(() => {
					const company = this.query.getValue().primaryCompany;
					const c = produce(company, (draft) => {
						draft.contactStatus = data.contactStatus;
					});

					this.store.setBusiness(c);
				}),
			),
			tap(() => this.getClientHistories(data.customerID).pipe(take(1)).subscribe()),
			catchError(() => of('')),
		);
	}

	setBusiness(business) {
		this.store.setBusiness(business);
	}

	/**
	 * Get Director
	 * @param clientId Client Id
	 */
	getDirectors(clientId: number): Observable<unknown> {
		this.store.setIsLoading(true, BusinessPeopleTypes.Director?.toLowerCase());

		return of(clientId).pipe(
			mergeMap(() => this.businessService.GetDirectors(clientId)),
			tap((x) =>
				applyTransaction(() => {
					let data = objectUtil.mapPascalCaseToCamelCase(x ? x : []);
					if (isObject(data)) {
						data = data ? Object.keys(data)?.map((i) => data[i]) : [];
					}
					this.store.setDirectors(data);
					this.store.setIsLoading(false, BusinessPeopleTypes.Director?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	/**
	 * Get Shareholder
	 * @param clientId Client Id
	 */
	getShareholders(clientId: number): Observable<unknown> {
		this.store.setIsLoading(true, BusinessPeopleTypes.Shareholder?.toLowerCase());
		return of(clientId).pipe(
			mergeMap(() => this.businessService.GetShareholders(clientId)),
			tap((x) =>
				applyTransaction(() => {
					let data = objectUtil.mapPascalCaseToCamelCase(x ? x : []);
					if (isObject(data)) {
						data = data ? Object.keys(data)?.map((i) => data[i]) : [];
					}
					this.store.setShareholders(data);
					this.store.setIsLoading(false, BusinessPeopleTypes.Shareholder?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	/**
	 * Get Other
	 * @param clientId Client Id
	 */
	getOthers(clientId: number): Observable<unknown> {
		this.store.setIsLoading(true, BusinessPeopleTypes.Others?.toLowerCase());
		return of(clientId).pipe(
			mergeMap(() => this.businessService.GetOthers(clientId)),
			tap((x) =>
				applyTransaction(() => {
					let data = objectUtil.mapPascalCaseToCamelCase(x ? x : []);
					if (isObject(data)) {
						data = data ? Object.keys(data)?.map((i) => data[i]) : [];
					}
					this.store.setOthers(data);
					this.store.setIsLoading(false, BusinessPeopleTypes.Others?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	updateLinkedMembers(req) {
		let customerId: number;
		return of(req).pipe(
			withLatestFrom(this.customerID$),
			map(([r, id]) => {
				customerId = id;
				const request = {
					directors: r.directors?.map((x) => x.customerId),
					shareholders: r.shareholders?.map((x) => x.customerId),
					others: r.others?.map((x) => x.customerId),
				};
				return { customerId: id, ...request };
			}),
			mergeMap((x) => this.businessService.UpdateLinkedMembers(x)),
			tap(() =>
				applyTransaction(() => {
					const state = req;
					this.store.setDirectors(state.directors);
					this.store.setShareholders(state.shareholders);
					this.store.setOthers(state.others);
				}),
			),
			tap(() => this.getClientHistories(customerId).pipe(take(1)).subscribe()),
			catchError(() => of('')),
		);
	}

	/**
	 * Get Client Documents
	 * @param primaryClientId primaryClientId
	 */
	getClientDocuments(primaryClientId: number): Observable<DocumentGroup | unknown> {
		this.store.setIsLoading(true, 'document');
		this.store.setDocuments([]);

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetDocumentsClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const documents = objectUtil.mapPascalCaseToCamelCase(x);
					this.store.setDocuments(documents);
					this.store.setIsLoading(false, 'document');
				}),
			),
			catchError(() => of({})),
		);
	}

	// Transfer document
	TransferDocument(req: { doc; doctype: string }) {
		return of(req).pipe(
			mergeMap((x) =>
				this.businessService.TransferDocument({
					CreateDateTime: x.doc.createDateTime,
					CreatedBy: x.doc.createdBy,
					CreatedByStaffId: x.doc.createdByStaffId,
					CreatedByStaffLevel: x.doc.createdByStaffLevel,
					CustomerID: x.doc.customerID,
					DocumentLink: x.doc.documentLink,
					DocumentName: x.doc.documentName,
					DocumentTypeCode: capitalizeFirstLetter(req.doctype),
					FileExtension: x.doc.fileExtension,
					FileName: x.doc.fileName,
					Id: x.doc.id,
					IsActive: x.doc.isActive,
					ModifiedByStaffId: x.doc.modifiedByStaffId,
					ModifiedDateTime: x.doc.modifiedDateTime,
				}),
			),
			tap(() =>
				applyTransaction(() => {
					const docState = JSON.parse(JSON.stringify(this.query.getValue().documents));
					const prop = req.doctype;

					const newDoc = Object.keys(docState)?.reduce((object, key) => {
						if (key === prop) {
							if (!object[key]) {
								object[key] = [];
							}

							if (docState[key].length > 0) {
								object[key].push(...docState[key]);
							}

							const newObj = Object.assign({}, req.doc);
							newObj.documentTypeCode = req.doctype;
							object[key].push(newObj);
						} else {
							object[key] = docState[key];
						}

						// Removes from prev
						if (key?.toLowerCase() === req.doc.documentTypeCode?.toLowerCase()) {
							object[key] = object[key]?.filter((d) => d.id !== req.doc.id);
						}

						// sort date by createDateTime
						object[key] = object[key].sort(
							(a, b) => new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime(),
						);

						return object;
					}, {});

					this.store.setDocuments(newDoc);
				}),
			),
			catchError(() => of({})),
		);
	}

	// Deactivate Document
	DeactivateDocument(doc) {
		return of(doc).pipe(
			mergeMap((x) => this.businessService.DeactivateDocument(x.id)),
			tap(() =>
				applyTransaction(() => {
					const docState = JSON.parse(JSON.stringify(this.query.getValue().documents));

					const newDoc = Object.keys(docState)?.reduce((object, key) => {
						object[key] = docState[key];

						if (key === smallFirstLetter(doc.documentTypeCode)) {
							object[key] = object[key]?.filter((d) => d.id !== doc.id);
						}
						return object;
					}, {});

					this.store.setDocuments(newDoc);
				}),
			),
			tap(() =>
				this.getAdviceProcessesByPrimaryId(this.query.getValue().primaryCompany.customerID).pipe(take(1)).subscribe(),
			),
			catchError(() => of({})),
		);
	}

	UploadDocument(req: { doc; doctype: string; customerId: number }) {
		const docs = req.doc.getAll('');
		const first$ = of({
			CustomerID: req.customerId,
			Document: '',
			FileName: docs[0].name,
			DocumentType: req.doctype,
		});

		let failedCount = 0;
		let successCount = 0;

		return first$.pipe(
			switchMap(() =>
				concat(
					first$,
					from(docs).pipe(
						mergeMap(
							(x) => this.convertToBase64(x),
							(o, i) => [o, i],
						),
						map(([o, i]) => {
							return {
								CustomerID: req.customerId,
								Document: i ? (i as string)?.split(',')[1] : '',
								FileName: (o as { name: string })?.name,
								DocumentType: req.doctype,
							};
						}),
						concatMap((req2) =>
							this.businessService.UploadDocument(req2).pipe(
								tap(() => {
									successCount++;
								}),
								catchError(() => {
									failedCount++;
									return of('failed');
								}),
							),
						),
					),
				),
			),
			map(() => {
				return {
					success: successCount,
					failed: failedCount,
				};
			}),
			finalize(() => {
				this.getClientDocuments(req.customerId)
					.pipe(map(() => 'success'))
					.subscribe();
				this.getClientHistories(req.customerId).pipe(take(1)).subscribe();
			}),
		);
	}

	/**
	 * Get activity timeline
	 * @params primaryClientId primary client id
	 */
	getActivityTimeline(primaryClientId: number) {
		return of(primaryClientId).pipe(
			mergeMap(() => this.businessService.GetActivityTimeline(primaryClientId)),
			tap((x) => {
				applyTransaction(() => {
					const state = objectUtil.mapPascalCaseToCamelCase(x) as ActivityTimelineState;
					this.store.setActivityTimeline(state);
				});
			}),
			catchError(() => of({})),
		);
	}

	addActivityNote = (note: string) => {
		return of(note).pipe(
			withLatestFrom(this.customerID$, this.userQuery.userInfo$),
			mergeMap(([n, id, _user]) =>
				this.businessService.AddNote({
					CustomerID: id,
					Notes: n,
					CustomerServiceID: 0,
					IsActivity: true,
					StaffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
				}),
			),
			withLatestFrom(this.activityTimeline$, this.userQuery.userInfo$),
			tap(([x, activityTimeline, _user]) =>
				applyTransaction(() => {
					const newNote = {
						notesID: +x,
						notes: note,
						createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
						staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
						dueDateTime: null,
						activityType: null,
						activityName: null,
						assignedToAdviser: null,
					} as NoteState;

					const state = {
						activities: activityTimeline.activities,
						notes: [newNote, ...activityTimeline.notes],
					};
					this.store.setActivityTimeline(state);
				}),
			),
			catchError(() => of({})),
		);
	};

	pinNote = (req: { note: NoteState; pin: boolean }) => {
		const isAp = req.note.activityType === NoteTypes.AdviceProcess;
		return of(req).pipe(
			mergeMap((x) => this.businessService.PinNote(x.note.notesID, x.pin, isAp)),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const state = produce(activityTimeline, (draft) => {
						draft.notes?.forEach((n) => {
							if (n.notesID === req.note.notesID) {
								n.isPinned = req.pin;
							}
						});
					});
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: state.notes,
					});
				}),
			),
			catchError(() => of('')),
		);
	};

	addToActivityTimelineNotes(noteID: number, note: string, aType: string, cusID: number, csID = 0) {
		applyTransaction(() => {
			let timeline = this.query.getValue().activityTimeline;
			if (!timeline) {
				timeline = {
					activities: [],
					notes: [],
				};
			}
			const state = produce<ActivityTimelineState>(timeline, (draft) => {
				draft.notes?.unshift({
					notesID: noteID,
					notes: note,
					createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
					staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
					dueDateTime: null,
					activityType: aType,
					activityName: null,
					assignedToAdviser: null,
					isActivity: false,
					customerID: cusID,
					customerServiceID: csID,
				});
			});
			this.store.setActivityTimeline(state);
		});
	}

	deleteActivityNote = (noteId: number, isAp?: boolean, adviceProcessId?: string) => {
		return of(noteId).pipe(
			mergeMap((x) => this.noteService.DeactivateNote(x, isAp)),
			withLatestFrom(this.activityTimeline$),
			tap(([, activityTimeline]) =>
				applyTransaction(() => {
					const notesState = activityTimeline.notes?.filter((note) => note.notesID !== noteId);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});

					if (isAp) {
						this.invokeApNoteFetchEvent.next(adviceProcessId);
					}
				}),
			),
			map(([x]) => x),
			catchError(() => of({})),
		);
	};

	deleteAllNotesByType = (type: string, customerId: number) => {
		return of(type).pipe(
			mergeMap((x: string) => this.noteService.DeactiveNotesByType(x, customerId)),
			withLatestFrom(this.activityTimeline$),
			tap(([, activityTimeline]) =>
				applyTransaction(() => {
					const notesState: NoteState[] = activityTimeline.notes?.filter((note: NoteState) => note.type !== type);

					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				}),
			),
			map(([x]) => x),
			catchError(() => of('')),
		);
	};

	addActivity = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToAdd(a)),
			mergeMap((x) => this.activityService.Post(x)),
			withLatestFrom(this.customerID$),
			mergeMap(([, customerID]) => this.getActivityTimeline(customerID)),
			catchError((e) => throwError(e)),
		);
	};

	updateActivity = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToEdit(a)),
			mergeMap((x) => this.activityService.Put(x)),
			withLatestFrom(this.activityTimeline$, this.customerID$),
			tap(([x, activityTimeline, customerID]) =>
				applyTransaction(() => {
					const updatedActivity = objectUtil.mapPascalCaseToCamelCase(
						ActivityViewModel.MapToModel(ac),
					) as ActivityState;
					if (updatedActivity.isCompleted) {
						this.getActivityTimeline(customerID).subscribe();
					} else {
						const activities = activityTimeline.activities?.map((a) => (a.activityId === +x ? updatedActivity : a));
						const state = {
							notes: activityTimeline.notes,
							activities: sort(activities).desc(
								(a) => a.dueDate,
								// a => a.dueTime
							),
						};
						this.store.setActivityTimeline(state);
					}
				}),
			),
			catchError((e) => throwError(e)),
		);
	};

	cancelActivity = (ac: { activity; reason }) => {
		return of(ac.activity).pipe(
			map((a) => ActivityViewModel.MapToEdit(a)),
			mergeMap((x) =>
				this.activityService.CancelActivityTimeline({
					...x,
					Reason: ac.reason,
					IsCancelled: true,
				}),
			),
			withLatestFrom(this.activityTimeline$, this.customerID$),
			mergeMap(([_x, , customerID]) => this.getActivityTimeline(customerID)),
		);
	};

	deleteActivity = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToDelete(a)),
			mergeMap((x) => this.activityService.Delete(x)),
			withLatestFrom(this.activityTimeline$, this.customerID$),
			mergeMap(([_x, _activityTimeline, customerID]) => this.getActivityTimeline(customerID)),
		);
	};

	addPhoneCall = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToQuickAdd(a)),
			mergeMap((a) => this.activityService.QuickAddPost(a)),
			withLatestFrom(this.customerID$),
			mergeMap(([x, customerID]) => this.getActivityTimeline(customerID).pipe(map(() => x))),
			withLatestFrom(this.activityTimeline$),
			tap(([x, activityTimeline]) =>
				applyTransaction(() => {
					const newActivity = objectUtil.mapPascalCaseToCamelCase(ac) as ActivityState;
					const state = {
						notes: activityTimeline?.notes,
						activities: sort([...activityTimeline?.activities, { ...newActivity, activityId: +x }]).desc(
							(a) => a.dueDate,
						),
					};
					this.store.setActivityTimeline(state);
				}),
			),
		);
	};

	/**
	 * Get Client History
	 * @param primaryClientId primaryClientId
	 */
	getClientHistories(primaryClientId: number): Observable<EditHistory[]> {
		this.store.setIsLoading(true, 'history');
		this.store.setHistories([]);

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetHistoriesClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const histories = x?.map(objectUtil.mapPascalCaseToCamelCase) as EditHistoryState[];
					this.store.setHistories(histories);
					this.store.setIsLoading(false, 'history');
				}),
			),
			catchError(() => of([])),
		);
	}

	deleteHistory(id: number, isAp?: boolean) {
		return this.businessService.DeleteNote(id, isAp).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query.getValue().histories?.filter((n) => n.notesID !== id);
					this.store.setHistories(data);
				});
			}),
			catchError(() => of('')),
		);
	}

	/**
	 * Get Client History
	 * @param primaryClientId primaryClientId
	 */
	getCriterias(primaryClientId: number): Observable<CurrentActivityCriteria[]> {
		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetCriteriaClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const criterias = x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null;
					// const slicedCriterias =
					// 	criterias && criterias.length > 12
					// 		? criterias?.slice(0, 12)
					// 		: criterias;
					this.store.setCriterias(criterias);
				}),
			),
			catchError(() => of([])),
		);
	}

	getNotes = (req: GetNotes) => {
		return this.businessService.GetNotes(req).pipe(map(objectUtil.mapPascalCaseToCamelCase));
	};

	addNote = (note: NoteRequest) => {
		return of(note).pipe(
			mergeMap((n) =>
				this.noteService.SaveNote({
					customerID: n.customerID,
					notes: n.notes,
					customerServiceID: n.customerServiceID,
					activityType: n.activityType,
					staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
				}),
			),
			tap((x) =>
				this.addToActivityTimelineNotes(+x, note.notes, note.activityType, note.customerID, note.customerServiceID),
			),
			catchError(() => of({})),
		);
	};

	deactivateNote = (note: NoteState) => {
		return of(note).pipe(
			mergeMap((x) => this.noteService.DeactivateNote(x.notesID)),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline.notes?.filter((n) => n.notesID !== note.notesID);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				}),
			),
			catchError(() => of('')),
		);
	};

	/**
	 * Get LrInsurance
	 * @param primaryClientId primaryClientId
	 */
	// biome-ignore lint/complexity/noBannedTypes: allow {}
	getLRInsurance(primaryClientId: number): Observable<LrInsurance | {}> {
		this.store.setIsLoading(true, ServicesCodes.LR?.toLowerCase());
		this.store.setLrInsurance({
			totalInforceAPI: 0,
			lRs: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetLrInsurancesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					const mainLR: Partial<LrInsuranceState> = {};
					if (isObject(data)) {
						mainLR.lRs = data ? Object.keys(data)?.map((i) => data[i]) : [];
					}
					this.store.setLrInsurance(lrSortServiceUtil(mainLR as LrInsuranceState));
					this.store.setIsLoading(false, ServicesCodes.LR?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Mortgage
	 * @param primaryClientId primaryClientId
	 */
	// biome-ignore lint/complexity/noBannedTypes: allow {}
	getMortage(primaryClientId: number): Observable<Mortgage | {}> {
		this.store.setIsLoading(true, ServicesCodes.Mortgage?.toLowerCase());
		this.store.setMortgage({
			totaLending: 0,
			mortgages: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetMortgagesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					const mainMortgage: Partial<MortgageState> = {};
					if (isObject(data)) {
						mainMortgage.mortgages = data ? Object.keys(data)?.map((i) => data[i]) : [];
					}
					this.store.setMortgage(mortgageSortServiceUtil(mainMortgage as MortgageState));
					this.store.setIsLoading(false, ServicesCodes.Mortgage?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Security
	 * @param primaryClientId primaryClientId
	 */
	getSecurity(primaryClientId: number): Observable<PropertyAsset> {
		this.store.setIsLoading(true, ServicesCodes.Property?.toLowerCase());
		this.store.setPropertyAsset({
			totalValue: 0,
			customerServices: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetSecuritiesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					this.store.setPropertyAsset(propAndAssetServiceUtil(data));
					this.store.setIsLoading(false, ServicesCodes.Property?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Assets
	 * @param primaryClientId primaryClientId
	 */
	getAsset(primaryClientId: number): Observable<Asset> {
		this.store.setIsLoading(true, ServicesCodes.Asset?.toLowerCase());
		this.store.setAsset({
			notes: [],
			customerServices: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetAssetsByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					this.store.setAsset(assetServiceUtil(data));
					this.store.setIsLoading(false, ServicesCodes.Asset?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Liabilities
	 * @param primaryClientId primaryClientId
	 */
	getLiability(primaryClientId: number): Observable<Asset> {
		this.store.setIsLoading(true, ServicesCodes.Asset?.toLowerCase());
		this.store.setAsset({
			notes: [],
			customerServices: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetLiabilityByPrimaryClientId(x)),
			withLatestFrom(this.query.liability$),
			tap(([x, _pa]) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					this.store.setLiability(data);

					// const combineData = [
					// 	...pa.customerServices,
					// 	...data.customerServices,
					// ];
					// const sorted = propAndAssetServiceUtil({ customerServices: combineData });

					// this.store.setPropertyAsset(sorted);
					this.store.setIsLoading(false, ServicesCodes.Liability?.toLowerCase());
				}),
			),
			map(([x]) => x),
			catchError(() => of({})),
		);
	}

	// addLiability(liability: LiabilityCustomerServiceState): Observable<boolean> {
	// 	return this.businessService.AddLiability(liability).pipe(
	// 		tap((customerServiceId) => {
	// 			liability.customerServiceID = customerServiceId;
	// 			this.store.update((state) => {
	// 				state.liability.customerServices;
	// 				return {
	// 					...state,
	// 					...{
	// 						liability: {
	// 							...state.liability,
	// 							customerServices: arrayAdd(
	// 								state?.liability?.customerServices,
	// 								liability
	// 							),
	// 						},
	// 					},
	// 				};
	// 			});
	// 		})
	// 	);
	// }

	/**
	 * Get LrInsurance
	 * @param primaryClientId primaryClientId
	 */
	getFGInsurance(primaryClientId: number): Observable<FgInsurance> {
		this.store.setIsLoading(true, ServicesCodes.FG?.toLowerCase());
		this.store.setFgInsurance({
			totalInforceApi: 0,
			fGs: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.businessService.GetFgInsurancesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const fgInsuranceState = objectUtil.mapPascalCaseToCamelCase(x) as FgInsuranceState;
					const mainFg: Partial<FgInsuranceState> = {};
					if (isObject(fgInsuranceState)) {
						mainFg.fGs = fgInsuranceState ? Object.keys(fgInsuranceState)?.map((i) => fgInsuranceState[i]) : [];
					}
					const fgInsurance = fgServiceUtil(mainFg as FgInsuranceState, this.businessConfigService.companyCode());
					this.store.setFgInsurance(fgInsurance);
					this.store.setIsLoading(false, ServicesCodes.FG?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get All Kiwisavers
	 * @param primaryClientID Primary Client ID : number
	 */
	getKiwisavers(primaryClientID: number): Observable<Kiwisaver[]> {
		this.store.setIsLoading(true, ServicesCodes.KiwiSaver?.toLowerCase());
		this.store.setKiwiSaver([]);

		return of(primaryClientID).pipe(
			mergeMap((x) => this.businessService.GetKiwisaversByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x.map(objectUtil.mapPascalCaseToCamelCase) as KiwisaverState[];
					this.store.setKiwiSaver(kiwiSaverServiceUtil(state));
					this.store.setIsLoading(false, ServicesCodes.KiwiSaver?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	/**
	 * Get All Investment
	 * @param primaryClientID Primary Client ID : number
	 */
	getInvestments(primaryClientID: number): Observable<Investment[]> {
		this.store.setIsLoading(true, ServicesCodes.Investment?.toLowerCase());
		this.store.setInvestments([]);

		return of(primaryClientID).pipe(
			mergeMap((x) => this.businessService.GetInvestmentsByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? x.map((ks) => {
								const convertedToStringIds = ks.Investor?.map((k) => k.toString());
								if (convertedToStringIds) ks.Investor = [...convertedToStringIds];
								return objectUtil.mapPascalCaseToCamelCase(ks) as InvestmentState;
							})
						: [];
					this.store.setInvestments(kiwiSaverServiceUtil(state));
					this.store.setIsLoading(false, ServicesCodes.Investment?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	convertToBase64 = (file, reader = new FileReader()) =>
		new Observable((obs) => {
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(file);
		});

	/**
	 * Get Advice Processes
	 * @param id CustomerID
	 */
	getAdviceProcessesByPrimaryId(id: number) {
		this.store.setIsLoading(true, ServicesCodes.AdviceProcess?.toLowerCase());
		return this.businessService.GetAdviceProcessesByPrimaryId(id, { status: 1 }).pipe(
			map((x) => AdviceProcessMapper.mapToSate(x)),
			tap((x) => applyTransaction(() => this.store.setAdviceProcesses(x))),
			finalize(() => this.store.setIsLoading(false, ServicesCodes.AdviceProcess?.toLowerCase())),
		);
	}

	/**
	 * Add Advice Process
	 * @param req AdviceProcess
	 */
	addAdviceProcess(req: ServiceAdviceProcessState) {
		return this.businessService.AddAdviceProcess(objectUtil.mapCamelCaseToPascalCase(req)).pipe(
			filter((x) => !!x),
			switchMap((x) =>
				this.api.get<ServiceAdviceProcess>(`adviceprocesses/${x}`, {
					status: 1,
				}),
			),
			map((x) => objectUtil.mapPascalCaseToCamelCase(x) as ServiceAdviceProcessState),
			tap((x) =>
				applyTransaction(() => {
					let data = this.query.getValue().adviceProcesses;
					if (this.getAdviceProcessService(x.processCode) === ServicesCodes.LR) {
						if (x.processCode === AdviceProcessCode.LRClaim) {
							data = {
								...data,
								lRClaimsAdviceProcesses: [...data.lRClaimsAdviceProcesses, x],
							};
						} else {
							data = {
								...data,
								lRAdviceProcesses: [...data.lRAdviceProcesses, x],
							};
						}
					}
					if (this.getAdviceProcessService(x.processCode) === ServicesCodes.Mortgage) {
						data = {
							...data,
							mortgageAdviceProcesses: [...data.mortgageAdviceProcesses, x],
						};
					}
					if (this.getAdviceProcessService(x.processCode) === ServicesCodes.KiwiSaver) {
						data = {
							...data,
							kiwiSaverAdviceProcesses: [...data.kiwiSaverAdviceProcesses, x],
						};
					}
					if (this.getAdviceProcessService(x.processCode) === ServicesCodes.BlanketAdvice) {
						data = {
							...data,
							blanketAdviceProcesses: [...data.blanketAdviceProcesses, x],
						};
					}
					if (this.getAdviceProcessService(x.processCode) === ServicesCodes.FG) {
						if (x.processCode === AdviceProcessCode.FGClaim) {
							data = {
								...data,
								fGClaimsAdviceProcesses: [...data.fGClaimsAdviceProcesses, x],
							};
						} else {
							data = {
								...data,
								fGAdviceProcesses: [...data.fGAdviceProcesses, x],
							};
						}
					}
					if (this.getAdviceProcessService(x.processCode) === ServicesCodes.ComplaintAdvice) {
						data = {
							...data,
							complaintAdviceProcesses: [...data.complaintAdviceProcesses, x],
						};
					}
					if (this.getAdviceProcessService(x.processCode) === ServicesCodes.Investment) {
						data = {
							...data,
							investmentAdviceProcesses: [...data.investmentAdviceProcesses, x],
						};
					}
					this.store.setAdviceProcesses(data);
				}),
			),
			tap(() => this.getCriterias(req.customerID).pipe(take(1)).subscribe()),
			tap(() => this.getClientHistories(req.customerID).pipe(take(1)).subscribe()),
		);
	}

	/**
	 * Update Advice Process
	 * @param req AdviceProcess
	 */
	updateAdviceProcess(
		req: ServiceAdviceProcessState,
		isEndProcess?: boolean,
		isReopen?: boolean,
		isStatusOnly?: boolean,
	) {
		const request = objectUtil.mapCamelCaseToPascalCase(req) as ServiceAdviceProcess;
		return of(!!isStatusOnly).pipe(
			switchMap((x) => (x ? of(request) : this.businessService.UpdateAdviceProcess(request))),
			map(() => request.AdviceProcessID),
			switchMap((x) =>
				this.api.get<ServiceAdviceProcess>(`adviceprocesses/${x}`, {
					status: 1,
				}),
			),
			switchMap((x) =>
				!!isEndProcess || !!isReopen || !!isStatusOnly
					? this.getActivityTimeline(request.CustomerID).pipe(map(() => x))
					: of(x),
			),
			switchMap((x) =>
				isEndProcess ? this.getPrimaryCompany(request.CustomerID, '', true).pipe(map(() => x)) : of(x),
			),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x) as ServiceAdviceProcessState;
					let adviceProcess = this.query.getValue().adviceProcesses;
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.LR) {
						if (data.processCode === AdviceProcessCode.LRClaim) {
							adviceProcess = {
								...adviceProcess,
								lRClaimsAdviceProcesses: adviceProcess.lRClaimsAdviceProcesses?.map((ap) =>
									ap.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						} else {
							adviceProcess = {
								...adviceProcess,
								lRAdviceProcesses: adviceProcess.lRAdviceProcesses?.map((ap) =>
									ap.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						}
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.Mortgage) {
						adviceProcess = {
							...adviceProcess,
							mortgageAdviceProcesses: adviceProcess.mortgageAdviceProcesses?.map((ap) =>
								ap.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.KiwiSaver) {
						adviceProcess = {
							...adviceProcess,
							kiwiSaverAdviceProcesses: adviceProcess.kiwiSaverAdviceProcesses?.map((ap) =>
								ap.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.BlanketAdvice) {
						adviceProcess = {
							...adviceProcess,
							blanketAdviceProcesses: adviceProcess.blanketAdviceProcesses?.map((ap) =>
								ap.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.FG) {
						if (data.processCode === AdviceProcessCode.FGClaim) {
							adviceProcess = {
								...adviceProcess,
								fGClaimsAdviceProcesses: adviceProcess.fGClaimsAdviceProcesses?.map((ap) =>
									ap.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						} else {
							adviceProcess = {
								...adviceProcess,
								fGAdviceProcesses: adviceProcess.fGAdviceProcesses?.map((ap) =>
									ap.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						}
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.ComplaintAdvice) {
						adviceProcess = {
							...adviceProcess,
							complaintAdviceProcesses: adviceProcess.complaintAdviceProcesses?.map((ap) =>
								ap.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.Investment) {
						adviceProcess = {
							...adviceProcess,
							investmentAdviceProcesses: adviceProcess.investmentAdviceProcesses?.map((ap) =>
								ap.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					this.store.setAdviceProcesses(adviceProcess);
				}),
			),
			tap(() => this.getCriterias(request.CustomerID).pipe(take(1)).subscribe()),
			tap(() => this.getClientHistories(request.CustomerID).pipe(take(1)).subscribe()),
		);
	}

	/**
	 * Delete Advice Process
	 * @param id AdviceProcessID
	 */
	deleteAdviceProcess(id: number, code: string) {
		return this.businessService.DeleteAdviceProcess(id).pipe(
			switchMap((x) => this.getActivityTimeline(this.query.getValue().primaryCompany.customerID).pipe(map(() => x))),
			tap(() =>
				applyTransaction(() => {
					let data = this.query.getValue().adviceProcesses;
					if (this.getAdviceProcessService(code) === ServicesCodes.LR) {
						if (code === AdviceProcessCode.LRClaim) {
							data = {
								...data,
								lRClaimsAdviceProcesses: [...data.lRClaimsAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						} else {
							data = {
								...data,
								lRAdviceProcesses: [...data.lRAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						}
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.Mortgage) {
						data = {
							...data,
							mortgageAdviceProcesses: [...data.mortgageAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.KiwiSaver) {
						data = {
							...data,
							kiwiSaverAdviceProcesses: [...data.kiwiSaverAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.BlanketAdvice) {
						data = {
							...data,
							blanketAdviceProcesses: [...data.blanketAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.FG) {
						if (code === AdviceProcessCode.FGClaim) {
							data = {
								...data,
								fGClaimsAdviceProcesses: [...data.fGClaimsAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						} else {
							data = {
								...data,
								fGAdviceProcesses: [...data.fGAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						}
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.ComplaintAdvice) {
						data = {
							...data,
							complaintAdviceProcesses: [...data.complaintAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.Investment) {
						data = {
							...data,
							investmentAdviceProcesses: [...data.investmentAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					this.store.setAdviceProcesses(data);
				}),
			),
			tap(() => this.getCriterias(this.query.getValue().primaryCompany.customerID).pipe(take(1)).subscribe()),
			tap(() => this.getClientHistories(this.query.getValue().primaryCompany.customerID).pipe(take(1)).subscribe()),
		);
	}

	updateClaimAdviceProcess(apCode: string, data) {
		// Update Policy Numbers on Linked Claim Advice Processes
		const csId = data?.customerServiceID;
		const updatePolicyNumber = (ap: ServiceAdviceProcessState, policyName: string) => {
			const customerServices = util.tryParseJson(ap?.customerServiceID);
			const i = customerServices?.findIndex((x) => +x === +csId);
			if (i < 0) {
				// If Advice Process has no Linked Policy Number related
				return ap;
			}
			const policyNumbers =
				(ap?.policyNumber?.split(',') || [])?.map((p, index) => (index === i ? policyName : p?.trim())) || [];
			return {
				...ap,
				policyNumber: policyNumbers?.join(', '),
			};
		};

		of(true)
			.pipe(
				withLatestFrom(this.adviceProcesses$),
				tap(([, aps]) => {
					switch (apCode) {
						case AdviceProcessCode.LRClaim: {
							const lrPolicyName = `${data?.policyNumber}${
								data?.policyNumberSuffix ? '-' + data?.policyNumberSuffix : ''
							}`;
							const listLr = aps?.lRClaimsAdviceProcesses?.map((x) => updatePolicyNumber(x, lrPolicyName));
							this.store.setAdviceProcesses({
								...aps,
								lRClaimsAdviceProcesses: listLr,
							});
							break;
						}
						case AdviceProcessCode.FGClaim: {
							const fgPolicyName = `${data?.fGPolicyNumber}${
								data?.fGPolicyNumberSuffix ? '-' + data?.fGPolicyNumberSuffix : ''
							}`;
							const listFg = aps?.fGClaimsAdviceProcesses?.map((x) => updatePolicyNumber(x, fgPolicyName));
							this.store.setAdviceProcesses({
								...aps,
								fGClaimsAdviceProcesses: listFg,
							});
							break;
						}
					}
				}),
				take(1),
			)
			.subscribe();
	}

	/**
	 * Get Document By ID
	 * @param documentId DocumentID
	 */
	getDocument$ = (documentId) => this.api.get(`Documents/${documentId}`);

	/**
	 * Upload Document
	 * @param file DocumentFile
	 */
	uploadDocument$ = (file) =>
		this.api
			.post('Documents', file)
			.pipe(mergeMap((x) => this.getClientDocuments(file?.CustomerId).pipe(map(() => x))));

	/**
	 * Download Document
	 * @param documentID DocumentID
	 * @returns Download Link
	 */
	downloadDocument$ = (documentID) => this.api.get(`Documents/${documentID}`);

	/**
	 * Deactivate Document
	 * @param documentID DocumentID
	 */
	deleteDocument$ = (documentID) => this.api.delete<number>(`Documents/Deactivate/${documentID}`);

	/**
	 * Get Questionnaires
	 * @param code Code for questionnaires
	 */
	getAdviceProcessQuestionnaires$ = (code) => this.api.get(`adviceprocesses/settings/${code}`);

	/**
	 * Get Questionnaires for Business
	 * @param code Code for questionnaires
	 */
	getAdviceProcessQuestionnairesBusiness$ = (code, customerId) =>
		this.api.get(`contacts/${customerId}/adviceprocesses/settings/${code}`);

	/**
	 * Get Advice Process Notes
	 * @param customerID number (CustomerID of PCI)
	 */
	getAdviceProcessNotes$ = (customerID, adviceProcessID) =>
		of(customerID).pipe(
			filter((x) => !!x),
			mergeMap(() => this.api.get(`contacts/${customerID}/adviceprocesses/${adviceProcessID}/crt-notes`)),
		);

	/**
	 * Add Advice Process Notes
	 * @param notes { adviceProcessId: number; notes: string; customerID: number }
	 */
	addAdviceProcessNotes$ = (notes: {
		referenceId: number;
		notes: string;
		customerID: number;
		type: string;
	}) =>
		this.api
			.post3('notes/crt', objectUtil.mapCamelCaseToPascalCase(notes))
			.pipe(switchMap((x) => this.getActivityTimeline(notes?.customerID).pipe(map(() => x))));

	/**
	 * Get Advice Process Notes
	 * @param noteID: number
	 */
	deleteAdviceProcessNotes$ = (noteID) =>
		this.api
			.delete(`notes/${noteID}/crt`)
			.pipe(
				switchMap((x) => this.getActivityTimeline(this.query.getValue().primaryCompany.customerID).pipe(map(() => x))),
			);

	cancelAdviceProcess(
		cap: { referenceId: number; cancellationReason: string; notes: string },
		ap?: ServiceAdviceProcessState,
	) {
		const req = { ...cap, type: 'AP' };
		const endpoint = `adviceprocesses/${req.referenceId}/change-status/6`;
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(req))
			.pipe(switchMap(() => this.updateAdviceProcess({ ...ap, status: 6 }, false, false, true)));
	}

	private mapDocumentForUpsert(ap: ServiceAdviceProcessState): ServiceAdviceProcessState {
		if (!ap?.documents) {
			return ap;
		}
		ap.documents = ap.documents?.map((d) => {
			if (d?.value?.documentID) {
				// @ts-ignore-next
				d.value = d?.value?.documentID;
			}
			return d;
		});
		return ap;
	}

	closeClaim(ap: ServiceAdviceProcessState, isEndProcess?: boolean, isReopen?: boolean) {
		ap.endProcessDate = MomentUtil.formatDateToServerDate(MomentUtil.createMomentNz());
		ap = this.mapDocumentForUpsert(ap);
		const endpoint = `adviceprocesses/${ap.adviceProcessID}/change-status/${ap.status}`;
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(ap))
			.pipe(switchMap(() => this.updateAdviceProcess({ ...ap }, isEndProcess || false, isReopen || false, false)));
	}
}
