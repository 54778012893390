import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../../core/base/api.service';
import { objectUtil } from '../../../../../../../../util/util';
import { catchError, concatMap, filter, finalize, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { AdviceProcessSectionCodes } from '../../../../../../../../shared/models/advice-process/advice-process.model'
import { NoteStore } from './note.store';
import { NoteQuery } from './note.query';
import { Note } from './note.model';
import { PurposeQuery } from '../../purpose/state/purpose.query';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class NoteService {
  constructor(
    private api: ApiService,
		  protected store: NoteStore,
		  protected query: NoteQuery,
		  protected crtMortgageQuery: CrtMortgageQuery,
		  protected purposeQuery: PurposeQuery
  ){}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

  get(parentCRTId: number) {
		this.store.setLoading(true);
		return this.api.get<Note[]>(`crt/MA/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.Notes}`).pipe(
			map((x) => (x || [])?.map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => {
				this.store.set(x || []);
				if (x?.length > 0) {
					this.store.setActive(x[0]?.cRTId);
				}
			}),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data, parentCRTId: number) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.Notes,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
			ParentCRTId: parentCRTId,
			CRTId: null
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => {
				this.store.add(x);
				this.store.setActive(x?.cRTId);
			}),
			catchError(() => of(undefined))
		);
	}

	update(data, parentCRTId: number) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.Notes,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
			ParentCRTId: parentCRTId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap(() => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => {
				this.store.upsert(x?.cRTId, x);
				this.store.setActive(x?.cRTId);
			}),
			catchError(() => of(undefined))
		);
	}

	updateNoteContentState(notes) {
		of(true)
			.pipe(
				withLatestFrom(this.query.activeNotes$),
				filter(([, x]) => !!x),
				tap(([, x]) =>
					applyTransaction(() => {
						const data = x as Note;
						this.store.upsert(data?.cRTId, { ...data, noteContents: notes });
					})
				),
				take(1)
			)
			.subscribe();
	}
}
