<form [formGroup]="form" [class.loatv2]="loatv2Enabled" [class.submitted]="submitted">
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Address </label>
		</div>
		<div class="col-12 col-lg-10">
			<app-address-input
				class="crt-form-control-field"
				inputId="houseAddressId"
				formControlName="address"
				textboxClass="theme-crm-field-disabled"
				tabindex="19"
				[attr.disabled]="viewMode"
			>
			</app-address-input>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Current Provider
			</label>
		</div>
		<div class="col-12 col-lg-4 mb-2 mb-lg-0">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectCurrentProviderId"
					formControlName="provider"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of currentProvider$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Premium </label>
		</div>
		<div class="col-12 col-lg-4">
			<div class="dollar-icon">
				<input
					id="premiumId"
					class="form-control crt-form-control"
					formControlName="premium"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Replacement Value
			</label>
		</div>
		<div class="col-12 col-lg-4 mb-2 mb-lg-0">
			<div class="dollar-icon">
				<input
					id="replacementValueId"
					class="form-control crt-form-control"
					formControlName="replacementValue"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Current Excess
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<div class="dollar-icon">
				<input
					id="currentExcessId"
					class="form-control crt-form-control"
					formControlName="currentExcess"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>

	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Last Reviewed
			</label>
		</div>
		<div class="col-12 col-lg-4 not-required">
			<app-date-input
				class="crt-form-control-field"
				textboxId="dateLastReviewedId"
				formControlName="lastReviewed"
				placeholder="dd/mm/yyyy"
				[attr.disabled]="viewMode"
			></app-date-input>
		</div>
	</div>

	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				Claimed In Last 12 months?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectClaimIn12MonthsId"
					class="form-control crt-form-control"
					formControlName="hasClaimedInLast12Months"
					(change)="selectChange()"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>

	<ng-container *ngIf="showClaimInLast12Months">
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold">
					Claimant
				</label>
			</div>
			<div class="col-12 col-lg-4">
				<input
					id="claimDriverId"
					class="form-control crt-form-control"
					formControlName="driverName"
					type="text"
				/>
			</div>
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold">
					What happened
				</label>
			</div>
			<div class="col-12 col-lg-4">
				<ng-container *ngIf="WhatHappened?.value === 'Other'; else whatHappendDropdown">
					<input
						id="whatHappenedOtherId"
						class="form-control crt-form-control"
						formControlName="whatHappenedOther"
						type="text"
						placeholder="Other"
					/>
				</ng-container>
				<ng-template #whatHappendDropdown>
					<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
						<select
							id="selectWhatHappenedId"
							formControlName="whatHappened"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of APCRTCLC$ | async" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</ng-template>
			</div>
		</div>
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold"> Date </label>
			</div>
			<div class="col-12 col-lg-4 not-required">
				<app-date-input
					class="crt-form-control-field"
					texboxId="claimDateId"
					formControlName="date"
					placeholder="dd/mm/yyyy"
					[attr.disabled]="viewMode"
				></app-date-input>
			</div>

			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold"> Cost </label>
			</div>
			<div class="col-12 col-lg-4">
				<div class="dollar-icon">
					<input
						id="claimCostId"
						class="form-control crt-form-control"
						formControlName="cost"
						type="text"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Year Built
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<input
				id="yearBuiltId"
				class="form-control crt-form-control"
				formControlName="yearBuilt"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				House Type
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<input
				id="houseTypeId"
				class="form-control crt-form-control"
				formControlName="houseType"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Floor Area
			</label>
		</div>
		<div class="col-12 col-lg-4 square-meter--right floor-area mb-2 mb-lg-0">
			<input
				id="floorAreaId"
				class="form-control crt-form-control"
				formControlName="floorArea"
				type="text"
			/>
			<i>m<sup>2</sup></i>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Internal Garage
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectInternalGarageId"
					class="form-control crt-form-control"
					formControlName="internalGarage"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Deck </label>
		</div>
		<div class="col-12 col-lg-4">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectDeckId"
					class="form-control crt-form-control"
					formControlName="deck"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Pool </label>
		</div>
		<div class="col-12 col-lg-4">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectPoolId"
					class="form-control crt-form-control"
					formControlName="pool"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Roof Construction
			</label>
		</div>
		<div class="col-12 col-lg-4 mb-2 mb-lg-0">
			<input
				id="roofConstructionId"
				class="form-control crt-form-control"
				formControlName="roofConstruction"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Wall Construction
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<input
				id="wallConstructionId"
				class="form-control crt-form-control"
				formControlName="wallConstruction"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Historical Building
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<input
				id="historicalBuildingId"
				class="form-control crt-form-control"
				formControlName="historicalBuilding"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Alarmed </label>
		</div>
		<div class="col-12 col-lg-4">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectAlarmedId"
					formControlName="alarmed"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-2 text-left w-100">
			<label for="" class="position-relative font-weight-bold">
				House Condition
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<input
				id="houseConditionId"
				class="form-control crt-form-control"
				formControlName="houseCondition"
				type="text"
			/>
		</div>
	</div>

	<div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button
				id="closeHousePolicyBtn"
				type="button"
				class="btn-light btn-block mb-lg-0"
				(click)="cancelClick()"
			>
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
				id="saveHouseBtn"
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
				(click)="save()"
				[disabled]="loading"
			>
				Save
				<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				id="cancelHouseBtn"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
				[disabled]="loading"
			>
				Cancel
			</button>
		</ng-template>
	</div>
</form>
