<div class="selling-section mb-4">
  <app-property-sold
    #propertySold
    [parentCRTId]="parentCRTId"
    [properties]="properties$ | async"
    [deceasedSciList]="deceasedSciList$ | async"
  >
  </app-property-sold>

  <app-loan-repaid
    [parentCRTId]="parentCRTId"
    [loans]="loans$ | async"
    [netProceeds]="netProceeds$ | async"
    [loading]="((propertiesLoading$ || loansLoading$) | async)"
    [isRefinance$]="isRefinance$"
    [deceasedSciList]="deceasedSciList$ | async"
  >
  </app-loan-repaid>
</div>