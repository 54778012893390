import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LrInsuranceComponent } from './lr-insurance/lr-insurance.component';
import { KiwisaverComponent } from './kiwisaver/kiwisaver.component';
import { InvestmentComponent } from './investment/investment.component';
import { LrInsuranceResolver } from './lr-insurance/lr-insurance.resolver';
import { KiwisaverResolver } from './kiwisaver/kiwisaver.resolver';
import { InvestmentResolver } from './investment/investment.resolver';
import { MortgageComponent } from './mortgage/mortgage.component';
import { MortgageResolver } from './mortgage/mortgage.resolver';
import { FgInsuranceComponent } from './fg-insurance/fg-insurance.component';
import { FgInsuranceResolver } from './fg-insurance/fg-insurance.resolver';
import { AdviceProcessComponent } from './advice-process/advice-process.component';
import { NotFoundComponent } from 'src/app/shared/not-found/not-found.component';
import { AdviceProcessResolver } from './advice-process/advice-process.resolver';

const routes: Routes = [
  {
    path: 'lrinsurance',
    data: {
      breadcrumb: 'L&R Insurance Pipeline',
    },
    component: LrInsuranceComponent,
    resolve: { data: LrInsuranceResolver },
  },
  {
    path: 'mortgage',
    data: {
      breadcrumb: 'Mortgage Pipeline',
    },
    component: MortgageComponent,
    resolve: { data: MortgageResolver },
  },
  {
    path: 'kiwisaver',
    data: {
      breadcrumb: 'KiwiSaver Pipeline',
    },
    component: KiwisaverComponent,
    resolve: { data: KiwisaverResolver },
  },
  {
    path: 'investment',
    data: {
      breadcrumb: 'Investment Pipeline',
    },
    component: InvestmentComponent,
    resolve: { data: InvestmentResolver },
  },
  {
    path: 'fginsurance',
    data: {
      breadcrumb: 'F&G Insurance Pipeline',
    },
    component: FgInsuranceComponent,
    resolve: { data: FgInsuranceResolver },
  },
  {
    path: 'adviceprocess',
    data: {
      breadcrumb: 'Advice Process Pipeline',
    },
    component: AdviceProcessComponent,
    resolve: { data: AdviceProcessResolver },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'lrinsurance',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PipelineRoutingModule { }
