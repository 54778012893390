import { Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '@core/customer/customer.service';
import { LocalService } from '@core/services/local.service';
import { UtilService } from '@core/util/util.service';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '@domain/user/user.query';
import { controlType } from '@modules/business-page/business-page-datatable.config';
import { ActivityService } from '@modules/crm/activity/states/activity.service';
import { DEFAULT_INTERVAL_BEFORE_POLLING } from '@modules/exports/state/exports.service';
import { SpecialFeaturesSettingsCode } from '@modules/special-features/state/special-features-settings.model';
import { TransferAdviceProcess, TransferExportPayload, TransferType } from '@modules/transfer/state/transfer.model';
import { TransferQuery } from '@modules/transfer/state/transfer.query';
import { TransferService } from '@modules/transfer/state/transfer.service';
import { FieldMetadata } from '@shared/dynamic-field/field-metadata.model';
import { ActivityBulkModalComponent } from '@shared/modal/activity/activity-bulk-modal/activity-bulk-modal.component';
import { ActivityViewModel } from '@shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { AdviceProcessCode, AdviceProcessTypesList } from '@shared/models/advice-process/advice-process.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { ProgressStatus } from '@shared/progress-popup/progress';
import { TableColumn } from '@swimlane/ngx-datatable';
import { datatableUtil } from '@util/datatable.util';
import { util } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { anyPass, isEmpty, isNil, propOr } from 'ramda';
import {
	Observable,
	Subject,
	combineLatest,
	delay,
	filter,
	finalize,
	map,
	of,
	shareReplay,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';
import { LeadSearchUiStore } from '../state/lead-search-ui.store';
import { Row } from '../state/lead-search.model';
import { LeadSearchStore } from '../state/lead-search.store';
import { LeadSearchQuery } from './../state/lead-search.query';

export const bulkTransferColumns: (TableColumn & {
	metakey: string;
	id: string;
	controlType: 'display' | 'dropdown';
	isRequired?: boolean;
	columnId?: string;
	fieldId?: string;
	sortValueGetter?: (field: FieldMetadata<unknown>, choices?: ViewDisplayValue[]) => unknown;
})[] = [
	{
		metakey: 'L&R Adviser',
		prop: 'LRAdviser',
		id: 'LR Adviser',
		name: 'L&R Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('LRAdviser'),
		fieldId: datatableUtil.formatFieldId('LRAdviser'),
	},
	{
		metakey: 'Group Adviser',
		prop: 'GroupInsuranceAdviser',
		id: 'GI Adviser',
		name: 'Group Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('GroupInsuranceAdviser'),
		fieldId: datatableUtil.formatFieldId('GroupInsuranceAdviser'),
	},
	{
		metakey: 'Mortgage Adviser',
		prop: 'MortgageAdviser',
		id: 'Mortgage Adviser',
		name: 'Mortgage Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('MortgageAdviser'),
		fieldId: datatableUtil.formatFieldId('MortgageAdviser'),
	},
	{
		metakey: 'F&G Adviser',
		prop: 'FGAdviser',
		id: 'FG Adviser',
		name: 'F&G Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('FGAdviser'),
		fieldId: datatableUtil.formatFieldId('FGAdviser'),
	},
	{
		metakey: 'KiwiSaver Adviser',
		prop: 'KiwiSaverAdviser',
		id: 'KS Adviser',
		name: 'KiwiSaver Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('KiwiSaverAdviser'),
		fieldId: datatableUtil.formatFieldId('KiwiSaverAdviser'),
	},
	{
		metakey: 'Investment Adviser',
		prop: 'InvestmentAdviser',
		id: 'Investment Adviser',
		name: 'Investment Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('InvestmentAdviser'),
		fieldId: datatableUtil.formatFieldId('InvestmentAdviser'),
	},
	{
		metakey: 'Lead Gen',
		prop: 'LeadGen',
		id: 'Lead Gen',
		name: 'Lead Gen',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: false,
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('Lead Gen'),
		fieldId: datatableUtil.formatFieldId('Lead Gen'),
	},
	{
		metakey: 'Lead Status',
		prop: 'LeadStatus',
		name: 'Lead Status',
		id: 'Lead Status',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Lead Status'),
		fieldId: datatableUtil.formatFieldId('Lead Status'),
	},
];

@Component({
	selector: 'app-lead-bulk-transfer',
	templateUrl: './lead-bulk-transfer.component.html',
	styleUrls: ['./lead-bulk-transfer.component.scss'],
})
export class LeadBulkTransferComponent implements OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	form: FormGroup;
	tableHeight: SafeStyle;

	isTransferring = false;
	isLoading = false;
	advisers$ = of([]);
	index = 0;

	dataRows$ = of([]);
	rows$ = of([]);

	columns$ = of([]);

	searchLoadingFn: (status: boolean) => void;
	searchFn: () => void;

	/** For datatable optimization to determine row identifier. */
	rowIdentity = (row: Row) => row.CustomerId;

	adviserProviders$ = bulkTransferColumns.filter((x) => x.metakey !== 'Lead Status');

	allAdviserChoices$ = this.bLStaffsQuery.adviserChoices$;
	allAdviserChoicesAsObject$ = this.bLStaffsQuery.adviserChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices,
			),
		),
		shareReplay(1),
	);
	allStaffsChoices$ = this.bLStaffsQuery.allStaffsChoices$;
	allStaffsChoicesAsObject$ = this.bLStaffsQuery.allStaffsChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices,
			),
		),
		shareReplay(1),
	);
	leadGenChoices$ = this.bLStaffsQuery.leadGenChoices$;
	leadGenChoicesAsObject$ = this.bLStaffsQuery.leadGenChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices,
			),
		),
		shareReplay(1),
	);
	adviserChoices$ = this.bLStaffsQuery.allActiveStaffs$;
	adviserCalendarChoices$ = this.bLStaffsQuery.adviserCalendarChoices$;

	AT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AT');
	AM$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AM');
	LST$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('LST');
	defaultLST = this.dropdownValueQuery
		.getAll({ filterBy: (x) => x.DropdownCode === 'LST' })
		?.find((x) => !!x.IsDefault)?.DropdownValue;
	adviceProcessList = AdviceProcessTypesList;
	filteredProcessCodes: ViewDisplayValue[];
	adviceProcessCode = AdviceProcessCode;
	isCompany = false;
	fgUpdateFeature = this.businessConfigQuery.getValue().config.FGUpdateV1;
	userInfo = this.userQuery.getValue();
	hasKOAT: boolean;
	hasMOAT: boolean;
	hasCRT: boolean;

	constructor(
		private fb: FormBuilder,
		private sanitizer: DomSanitizer,
		private activitedRoute: ActivatedRoute,
		private renderer: Renderer2,
		protected bLStaffsQuery: BLStaffsQuery,
		public bsModalRef: BsModalRef,
		public bsModalService: BsModalService,
		protected dropdownValueQuery: DropdownValueQuery,
		private transferService: TransferService,
		private transferQuery: TransferQuery,
		private leadSearchUiStore: LeadSearchUiStore,
		private leadSearchStore: LeadSearchStore,
		private leadSearchQuery: LeadSearchQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery,
		private customerService: CustomerService,
		private activityService: ActivityService,
		private localService: LocalService,
	) {}

	get adviserTypeForm() {
		return this.form.get('adviserType');
	}

	get adviserForm() {
		return this.form.get('adviser');
	}

	get leadStatusForm() {
		return this.form.get('leadStatus');
	}

	get processCodeForm() {
		return this.form.get('processCode');
	}

	get isOnlineForm() {
		return this.form.get('isOnline');
	}

	get AssignActivity() {
		return this.form.get('assignActivity');
	}

	get isLeadGenTransfer() {
		return this.form.get('adviserType')?.value === 'Lead Gen';
	}

	getAllowedServices() {
		const businessServices = this.businessConfigQuery.getValue().config.Services;
		const userServices =
			JSON.parse(this.userQuery.getValue().Services)?.filter((us) => businessServices.some((bs) => bs === us)) || [];
		const allowedServices = this.userQuery.isTapLevel()
			? businessServices
			: businessServices.filter((x) => userServices.includes(x));
		return allowedServices;
	}

	ngOnInit(): void {
		const allowedServices = this.getAllowedServices() || [];
		const customerAlterationRequestEnabled =
			this.businessConfigQuery.getValue()?.config?.SpecialFeature?.includes(SpecialFeaturesSettingsCode.CAR) &&
			allowedServices.includes(ServicesCodes.ClientAlterationRequest);
		this.filteredProcessCodes = this.adviceProcessList.filter((type) => {
			// hide Client Alteration Request in business profile advice process creation
			if (type.value === AdviceProcessCode.ClientAlterationRequest) {
				if (!customerAlterationRequestEnabled) {
					return false;
				}
				return true;
			}
			//Hide Claims if not enabled on business
			if (type.value === AdviceProcessCode.LRClaim || type.value === AdviceProcessCode.FGClaim) {
				return false;
			}
			return !type.code ? true : allowedServices.includes(type.code);
		});

		this.rows$ = this.dataRows$.pipe(
			map((rows) => [...rows]), // Using the spread operator to clone the array
		);
		this.rows$.pipe(take(1)).subscribe((data) => {
			let localIsCompany = true;
			data.map((detail) => {
				if (!detail.link?.IsCompany) {
					localIsCompany = false;
				}
			});
			this.isCompany = localIsCompany;
		});

		this.tableHeight = this.sanitizer.bypassSecurityTrustStyle('calc(100vh - 450px)');

		this.form = this.fb.group({
			adviserType: ['', Validators.required],
			adviser: { value: '', disabled: true }, // Disabled by default
			leadStatus: { value: '', disabled: true }, // Hidden by default
			processCode: [{ value: '', disabled: true }], // Hidden by default
			isOnline: [{ value: null, disabled: true }], // Hidden by default
			assignActivity: [false],
		});

		// Watch for changes in userTag to update userAssign and leadStatus controls
		this.adviserTypeForm.valueChanges
			.pipe(filter((adviserType) => adviserType !== null && adviserType !== ''))
			.pipe(takeUntil(this.destroy$))
			.subscribe((adviserType) => {
				this.adviserForm.patchValue('');

				if (adviserType && adviserType !== '') {
					this.rows$ = this.dataRows$.pipe(
						map((rows) => [...rows]), // Using the spread operator to clone the array
					);

					this.adviserForm.enable();
					this.adviserForm.setValidators(Validators.required);
					this.adviserForm.updateValueAndValidity({ onlySelf: true, emitEvent: true });
					if (this.fgUpdateFeature) {
						this.processCodeForm.enable();
						this.processCodeForm.setValidators(Validators.required);
						this.isOnlineForm.enable();
					}

					if (adviserType.includes('Mortgage')) {
						this.advisers$ = this.advisers$.pipe(switchMap(() => this.bLStaffsQuery.MAdviserChoicesOption$));

						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('Mortgage')])),
						);
					} else if (adviserType.includes('LR')) {
						this.advisers$ = this.bLStaffsQuery.LRAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('LR')])),
						);
					} else if (adviserType.includes('FG')) {
						this.advisers$ = this.bLStaffsQuery.FGAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('FG')])),
						);
					} else if (adviserType.includes('KS')) {
						this.advisers$ = this.bLStaffsQuery.KsAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('KS')])),
						);
					} else if (adviserType.includes('GI')) {
						this.advisers$ = this.bLStaffsQuery.GAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('GI')])),
						);
					} else if (adviserType.includes('Investment')) {
						this.advisers$ = this.bLStaffsQuery.InvestmentAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('Investment')])),
						);
					} else if (adviserType.includes('Lead')) {
						if (this.fgUpdateFeature) {
							this.processCodeForm.disable();
							this.processCodeForm.setValue('');
							this.processCodeForm.clearValidators();
							this.isOnlineForm.disable();
							this.isOnlineForm.setValue(null);
						}

						this.advisers$ = this.advisers$.pipe(
							switchMap(() =>
								this.bLStaffsQuery.leadGens$.pipe(
									map((leadGens) => {
										return leadGens.map((lead) => ({
											display: lead.FullName,
											value: lead.StaffID,
										}));
									}),
								),
							),
						);

						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('Lead')])),
						);
					} else {
						this.advisers$ = of([]);
					}
					if (this.fgUpdateFeature) {
						this.processCodeForm.updateValueAndValidity({ onlySelf: true, emitEvent: true });
						this.isOnlineForm.updateValueAndValidity({ onlySelf: true, emitEvent: true });
					}
				} else {
					this.adviserForm.disable();
				}

				const leadStatusControl = this.leadStatusForm;
				const enableLeadStatus = adviserType.includes('Lead');

				enableLeadStatus ? leadStatusControl.enable({ onlySelf: true }) : leadStatusControl.disable({ onlySelf: true });
				this.setDefaultDropdowns();
			});

		this.adviserForm.valueChanges
			.pipe(filter((adviserId) => adviserId !== null && adviserId !== ''))
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				let adviserType = this.form.get('adviserType').value;

				if (adviserType === 'Lead Gen') {
					adviserType = 'Lead Gen';

					this.form.get('leadStatus').enable();
					this.form.get('leadStatus').setValidators(Validators.required);
					this.form.get('leadStatus').updateValueAndValidity();
				} else {
					this.form.get('leadStatus').disable();
					this.form.get('leadStatus').clearValidators();
					this.form.get('leadStatus').updateValueAndValidity();

					adviserType = this.form.get('adviserType').value;
				}
			});

		this.columns$ = of([...this.generateColumns(), ...this.createColumn(null)]);
		combineLatest([this.leadSearchQuery.hasCRT$, this.leadSearchQuery.hasKOAT$, this.leadSearchQuery.hasMOAT$])
			.pipe(
				take(1),
				map(([crt, koat, moat]) => {
					this.hasCRT = crt;
					this.hasKOAT = koat;
					this.hasMOAT = moat;
				}),
			)
			.subscribe();
	}

	setDefaultDropdowns() {
		this.form.get('leadStatus')?.setValue(this.defaultLST);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	updateDataRowsTable(): void {
		combineLatest([this.rows$, this.leadSearchQuery.selectAll()])
			.pipe(
				take(1),
				map(([newRows, existingRows]) => {
					const updatedExistingRows = [...existingRows];

					const column = bulkTransferColumns.find((c) => c.id.includes(this.form.get('adviserType').value));

					for (const newRow of newRows) {
						const existingRowIndex = existingRows.findIndex((row) => row.CustomerId === newRow.CustomerId);

						if (existingRowIndex >= 0) {
							updatedExistingRows[existingRowIndex] = {
								...newRow,
								bulk: {
									...newRow.bulk,
									value: false,
								},
								...{
									[column.prop]: {
										...newRow[column.prop],
										value: this.form.get('adviser').value,
									},
									...(column.metakey.includes('Lead Gen') &&
									this.form.get('leadStatus').value &&
									this.form.get('leadStatus').value !== 'Unchanged' &&
									this.form.get('leadStatus').value !== ''
										? { LeadStatus: { ...newRow['LeadStatus'], value: this.form.get('leadStatus').value } }
										: {}),
								},
							};
						}
					}
					return updatedExistingRows;
				}),
			)
			.subscribe((updatedRows) => {
				this.leadSearchUiStore.setIsSelectAll(false);
				this.leadSearchStore.set(updatedRows, { idKey: 'CustomerId' } as unknown);
			});
	}

	cancel() {
		this.bsModalRef.hide();
	}

	processPayload(ac?: ActivityViewModel) {
		let payload: TransferExportPayload;

		this.rows$
			.pipe(
				takeUntil(this.destroy$),
				filter((data) => data && data.length > 0),
			)
			.subscribe((data) => {
				const formData = this.form.value;
				let adviserType = this.form.get('adviserType').value;

				if (adviserType === 'Lead Gen') {
					adviserType = 'Lead Gen';
				} else {
					adviserType = this.form.get('adviserType').value;
				}

				const isBulkAdvice = !anyPass([isNil, isEmpty])(formData.processCode) && formData.processCode !== 'none';

				payload = {
					CustomerIds: data.map((row) => row.CustomerId),
					Field: bulkTransferColumns.find((c) => c.id.includes(adviserType))?.id as string,
					NewValue: this.form.get('adviser').value,
					AdditionalValue: this.form.get('leadStatus').enabled ? this.form.get('leadStatus').value : null,
				};

				if (this.fgUpdateFeature) {
					// NZFA Features
					if (this.isLeadGenTransfer && this.AssignActivity?.value) {
						// If Lead Gen transfer && should create Bulk Activity
						const Activity = {
							ActivityName: ac?.ActivityName || '',
							ActivityType: ac?.ActivityType || '',
							Adviser: ac?.Adviser,
							Location: ac?.Location,
							Appointment: ac?.Appointment,
							Details: ac?.Details,
							DueDate: UtilService.MomentToDateString(ac?.DueDate),
							DueTime: ac?.DueTime,
							Duration: ac?.Duration,
							Meeting: ac?.Meeting,
							isCompleted: null,
							IsActive: true,
						};
						payload = {
							...payload,
							Activity,
							IsBulkActivity: true,
						};
					} else if (isBulkAdvice) {
						// If Adviser transfer && should create Bulk Advice Process
						const adviceProcess = isBulkAdvice ? this.processAdvicePayload() : null;
						payload = {
							...payload,
							AdviceProcess: adviceProcess,
							IsBulkAdviceProcess: isBulkAdvice,
						};
					}
				}
			});
		return payload;
	}

	processAdvicePayload() {
		let advicePayload: TransferAdviceProcess;
		const data = this.form.value;
		if (!anyPass([isNil, isEmpty])(data.processCode) && data.processCode !== 'none') {
			advicePayload = {
				ProcessCode: data.processCode,
				CustomerServiceId: null,
				ClientsInvolved: null,
				IsOnline: data.isOnline ?? false,
			};
		}
		return advicePayload;
	}

	transfer() {
		if (this.fgUpdateFeature && this.AssignActivity?.value && this.isLeadGenTransfer) {
			this.advisers$
				.pipe(
					tap((x) => this.showActivityModal(x)),
					take(1),
				)
				.subscribe();
		} else {
			this.processTransfer().pipe(take(1)).subscribe();
		}
	}

	showActivityModal(adviserList: ViewDisplayValue[]) {
		const creatorName = this.userQuery.getValue()?.FullName || '';
		const formValue = this.form.getRawValue();
		const id = +formValue?.adviser;
		const adviserName = adviserList?.find((a) => +a?.value === id)?.display;
		const activity = {
			ActivityId: 1, // Placeholder - so default meeting wont replace the saved meeting value
			ActivityName: 'CC - 1st Meeting Unconfirmed',
			ActivityType: 'CC - 1st Meeting Unconfirmed',
			Adviser: id,
			AssignedToAdviserName: adviserName || '',
			Duration: 60,
			Details: `Activity created by ${creatorName}, Via Bulk Transfer`,
			Location: '',
			Meeting: '',
		};
		const initState: object = {
			formItem: activity,
			AT$: this.AT$,
			AM$: this.AM$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: false,
			savefn: this.processTransfer,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: ['FCOA'],
		};
		this.bsModalService.show(ActivityBulkModalComponent, {
			class: `bulk-activity-modal modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft' ? 'modal-dialog-outlook-md' : 'modal-md'
			}`,
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	processTransfer = (ac?: ActivityViewModel) => {
		this.isTransferring = true;
		const status = this.transferQuery.getValue().status;
		if (status === ProgressStatus.STARTED) {
			return;
		}
		this.index++;
		const req = this.processPayload(ac);
		if (!req) {
			return;
		}
		if (typeof this.searchLoadingFn === 'function') {
			this.searchLoadingFn(true);
		}
		return this.transferService.queue(req, TransferType.LEAD).pipe(
			take(1),
			delay(DEFAULT_INTERVAL_BEFORE_POLLING),
			switchMap((id: string) => {
				this.updateDataRowsTable();
				this.isTransferring = false;
				this.bsModalRef.hide();
				this.transferService.downloadExport(req, TransferType.LEAD);
				return this.transferService.startPolling(this.transferService.getTranferStatus(id)).pipe(take(1));
			}),
			tap((data) => {
				if (data.Status === ProgressStatus.COMPLETE) {
					this.downloadExport(data.DocumentLink);
					this.popover?.hide();
				}
				this.isTransferring = false;
				this.bsModalRef.hide();
			}),
			finalize(() => {
				this.isTransferring = false;
				this.bsModalRef.hide();
				if (typeof this.searchFn === 'function') {
					this.searchFn();
				}
			}),
		);
	};

	downloadExport(url: string) {
		const name = this.activitedRoute.snapshot.paramMap.get('companyCode') + '-Customer.csv';

		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	generateColumns() {
		const columns: (TableColumn & {
			metakey: string;
			controlType: controlType;
			isRequired?: boolean;
			columnId?: string;
			fieldId?: string;
		})[] = [
			{
				metakey: 'Name',
				prop: 'Name',
				name: 'Client Name',
				width: 250,
				headerClass: 'secondary-background',
				cellClass: 'font-weight-bold fixed-column',
				controlType: 'display',
				columnId: datatableUtil.formatColumnId('Name'),
				fieldId: datatableUtil.formatFieldId('Name'),
			},
		];

		return columns;
	}

	createColumn(id?: string) {
		return id
			? bulkTransferColumns.filter((col) => col.id.includes(id))
			: [
					{
						metakey: '',
						prop: '',
						name: '',
						headerClass: 'secondary-background',
						controlType: 'display',
					},
				];
	}

	handleProcessSelectionChange() {
		if (!this.isCompany) {
			if (
				(this.processCodeForm.value === this.adviceProcessCode.LRAdviceNew ||
					this.processCodeForm.value === this.adviceProcessCode.LRAdviceReview ||
					this.processCodeForm.value === this.adviceProcessCode.MortgageAdvice ||
					this.processCodeForm.value === this.adviceProcessCode.KiwiSaverAdvice) &&
				this.isOnlineAvailable
			) {
				this.isOnlineForm.setValidators([Validators.required]);
				this.isOnlineForm.setValue(true);
			} else {
				this.isOnlineForm.setValidators([]);
				this.isOnlineForm.setValue(null);
			}
		} else {
			this.isOnlineForm.setValidators([]);
		}

		this.isOnlineForm.updateValueAndValidity();
	}

	get isOnlineAvailable() {
		if (this.processCodeForm.value === this.adviceProcessCode.MortgageAdvice) {
			return this.hasMOAT;
		} else if (
			this.processCodeForm.value === this.adviceProcessCode.LRAdviceNew ||
			this.processCodeForm.value === this.adviceProcessCode.LRAdviceReview ||
			this.processCodeForm.value === this.adviceProcessCode.ClientAlterationRequest
		) {
			return this.hasCRT;
		} else if (this.processCodeForm.value === this.adviceProcessCode.KiwiSaverAdvice) {
			return this.hasKOAT;
		} else {
			return true;
		}
	}

	resetAssignActivity() {
		if (!this.fgUpdateFeature) {
			return;
		}
		this.AssignActivity.setValue(false);
	}
}

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (choices: ViewDisplayValue[], field: FieldMetadata<unknown>) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return propOr<string, ViewDisplayValue, string>('', 'display' as keyof FieldMetadata<unknown>, choiceItem);
};
