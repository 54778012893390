import {
	Component,
	OnInit,
	AfterViewInit,
	OnChanges,
	OnDestroy,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ChangeDetectionStrategy,
	SimpleChanges,
} from '@angular/core';
import { Subject, Observable, ReplaySubject, zip, of, combineLatest } from 'rxjs';
import {
	ContactStatusCode,
	ContactStatusLabel,
	GetStatusLabelByCode,
} from '../../../../shared/models/_general/client.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { CurrentActivityCriteriaState } from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
	UntypedFormArray,
} from '@angular/forms';
import { staticConf } from '../../../../core/config/static-config.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	take,
	map,
	takeUntil,
	tap,
	catchError,
	withLatestFrom,
} from 'rxjs/operators';
import { patchValue } from '../../../../shared/services/service-utils/service.util';
import { BusinessMapper } from '../../../../shared/models/business-profile/business/business.mapper';
import { strUtil, util } from '../../../../util/util';
import { ConvertModalComponent } from '../../../../shared/modal/convert-modal/convert-modal.component';
import { BusinessProfileService } from '../states/business-profile.service';
import { ClientSearchControlComponent } from '../../../../shared/search-controls/client-search-control/client-search-control.component';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from 'src/app/shared/date-input/date-input.component';
import { PrimaryCustomerCompanyState } from 'src/app/shared/models/business-profile/business/business.model';
import {
	Fields,
	getInvalidWarning,
	getRequiredWarning,
} from 'src/app/shared/error-message/error-message';
import { UserQuery } from 'src/app/domain/user/user.query';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { AdviceProcessCode, AdviceProcessTypesList } from 'src/app/shared/models/advice-process/advice-process.model';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { omit, pluck, uniq } from 'ramda';
import { AdviserServiceState, SasReference } from '@shared/models/client-profile/primary-client/primary-client.model';

@Component({
	selector: 'app-business-profile-group',
	templateUrl: './business-profile-group.component.html',
	styleUrls: ['./business-profile-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessProfileGroupComponent
	implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	@Input() addMode: boolean;
	@Input() isLead: boolean;
	@Input() data: PrimaryCustomerCompanyState;
	@Input() advisers: ViewDisplayValue[];
	@Input() allStaffChoices: ViewDisplayValue[];
	@Input() altAdvisers: ViewDisplayValue[];
	@Input() leadGens: ViewDisplayValue[];
	@Input() PCT: ViewDisplayValue[];
	@Input() PCE: ViewDisplayValue[];
	@Input() PCPC: ViewDisplayValue[];
	@Input() PCLE: ViewDisplayValue[];
	@Input() PCLT: ViewDisplayValue[];
	@Input() PCR: ViewDisplayValue[];
	@Input() LS: ViewDisplayValue[];
	@Input() LT: ViewDisplayValue[];
	@Input() SAS: ViewDisplayValue[];
	@Input() criterias: CurrentActivityCriteriaState[];
	@Input() showPendingFields: boolean;
	@Input() allStaff: BLStaff[];
	@Input() adviserGenChoices: ViewDisplayValue[];

	@Output() saveEvent = new EventEmitter<any>();
	@Output() selectServiceTab = new EventEmitter<any>();
	contactStatusLabel$: Observable<string>;
	contactDropdown$: Observable<any>;
	form: UntypedFormGroup;

	criteriasState = [];
	hasCriteria: boolean;

	companyCode = this.businessConfigQuery.getValue().config?.BusinessCode;

	@ViewChild(ClientSearchControlComponent)
	referredInput: ClientSearchControlComponent;
	@ViewChild('lastReviewDate') lastReviewDate: DateInputComponent;
	@ViewChild('nextReviewDate') nextReviewDate: DateInputComponent;

	contactTypes: ViewDisplayValue[] = staticConf.contactTypes;
	convertHeaderTitle: string;
	adviserReworkFeature: boolean;
	altAdviserDropdown: ViewDisplayValue[];

	private _isSaving = false;

	hasPermissions$ = this.userQuery.hasPermission$;
	canUpdateLeadStatus$ = this.userQuery.canUpdateLeadStatus$;
	claimsFeature = this.businessConfigQuery.getValue().config?.Claims;

	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}

	get isSaving(): boolean {
		return this._isSaving;
	}

	isSaving$ = new ReplaySubject<boolean>(1);
	isSavingLS$ = new ReplaySubject<boolean>(1);

	private _isEdit = false;

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}

	get isEdit(): boolean {
		return this._isEdit;
	}
	isEdit$ = new ReplaySubject<boolean>(1);

	get companyName() {
		return this.form.get('companyName');
	}
	get adviser() {
		return this.form.get('adviser');
	}
	get AdviserList() {
		// Adviser Rework Field
		return this.form.get('advisers') as UntypedFormArray;
	}

	isAM$ = this.userQuery.userInfo$.pipe(
		map((x) => x.SecurityGroup === 'AM')
	);

	hasBusinessService: string[] = [];

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private service: BusinessProfileService,
		private loggerService: LoggerService,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery
	) {
		this.createForm();
	}

	ngOnInit() {
		this.getBusinessProfileServices();
		if (this.addMode) {
			this.form.enable();
			this.isEdit = true;
		} else {
			this.form.disable();
			this.isEdit = false;
		}

		this.isEdit$
			.pipe(
				withLatestFrom(this.canUpdateLeadStatus$, this.isAM$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([x, canUpdateLeadStatus, isAM]) => {
				this._isEdit = x;
				if (x || this.addMode) {
					this.form.enable();
					if(isAM) {
						if(!this.addMode) {
							this.form.get('companyName').disable();
						}
						this.form.get('rank').disable();
						this.form.get('isRecycled').disable();
					}
				} else {
					this.form.disable();
					if (canUpdateLeadStatus && !x) {
						this.form.get('leadStatus').enable();
					}
				}
			});

		this.isSaving$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isSaving = x;
		});

		zip(of(this.PCR), of(this.LS))
			.pipe(
				take(1),
				map((ddListList) => {
					const defaultValueList: string[] = ddListList
						?.map((ddList) => ddList?.find((dd) => dd.isDefault))
						?.map((def) => def && def.value);
					return defaultValueList;
				})
			)
			.subscribe(this.setDropdownDefaults);
		this.getAltAdvisers();
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([pcr, ls]) => {
		patchValue<any>(this.form, {
			rank: this.data ? this.data.rank : pcr,
			leadStatus: this.data ? this.data.leadStatus : this.isLead ? ls : '',
		});
	};

	ngOnChanges(changes: SimpleChanges) {
		if (!this.addMode) {
			this.isEdit$.next(true);
			const data = this.data
				? Object.assign(
						new BusinessMapper(),
						BusinessMapper.mapToView(this.data)
				  )
				: Object.assign(new BusinessMapper(), this.form.value);
			const adviser = this.advisers?.find((x) => x.value === data.adviser);
			data.adviser = adviser === undefined ? null : adviser.value;

			this.contactStatusLabel$ = of(this.data).pipe(
				map((s) => {
					if (s) {
						return GetStatusLabelByCode(s.contactStatus);
					} else {
						return ContactStatusLabel.CurrentClient;
					}
				})
			);

			this.contactDropdown$ = this.contactStatusLabel$.pipe(
				map((x) => {
					if (x === ContactStatusLabel.CurrentClient) {
						return [
							{
								value: ContactStatusCode.Lead,
								display: `Move to ${ContactStatusLabel.Lead}`,
							},
							{
								value: ContactStatusCode.ExClient,
								display: `Move to ${ContactStatusLabel.ExClient}`,
							},
						];
					} else {
						return [
							{
								value: ContactStatusCode.CurrentClient,
								display: `Move to Client`,
							},
						];
					}
				})
			);
			if (!!changes?.data?.currentValue) {
				// Trigger only if data is updated
				this.prepData(data);
			}
			this.prepCriterias();
			this.isEdit$.next(false);
		} else {
			if (!!changes?.addMode?.currentValue) {
				// Trigger only initially on load for Add Mode
				this.prepData();
			}
		}
		this.getAltAdvisers();
	}

	prepCriterias() {
		this.criteriasState = [];
		this.hasCriteria = this.criterias && this.criterias.length > 0;
		const newCriterias = this.filterCriterias();

		// Array starts 0 index
		const row1 =
			newCriterias.length > 0
				? newCriterias?.filter((x, i, array) => i % 2 === 0)
				: [];
		const row2 =
			newCriterias.length > 0
				? newCriterias?.filter((x, i, array) => i % 2 !== 0)
				: [];
		this.criteriasState.push(row1, row2);
	}

	filterCriterias() {
		const criterias = Object.assign([], this.criterias);
		const businessServices =
			this.businessConfigQuery.getValue().config?.Services || [];
		const userServices =
			JSON.parse(this.userQuery.getValue().Services)?.filter((us) =>
				businessServices.some((bs) => bs === us)
			) || [];
		const allowedServices = this.userQuery.isTapLevel()
			? businessServices
			: businessServices.filter((x) => userServices.includes(x));
		const filteredTypes = AdviceProcessTypesList?.filter((type) => {
			if (!type.code) {
				return true;
			}
			if (
				(type.value === AdviceProcessCode.FGClaim ||
					type.value === AdviceProcessCode.LRClaim) &&
				!this.claimsFeature
			) {
				return false;
			}
			return allowedServices?.includes(type.code);
		})?.map((x) => x?.value);
		const newCriterias =
			criterias?.filter(
				(x) =>
					filteredTypes?.includes(x?.serviceCode) || // for advice processes
					allowedServices?.includes(x?.serviceCode) // for services
			) || [];

		return newCriterias?.length > 10
			? newCriterias?.slice(0, 10)
			: newCriterias;
	}

	createForm() {
		this.form = this.fb.group({
			companyName: ['', [Validators.required]],
			adviser: ['', [Validators.required]],
			tradingAs: [''],
			rank: [''],

			lastReview: [''],
			nextReview: [''],
			altAdviser: [[]],
			leadStatus: [''],

			contactStatus: [''],
			reviewPending: false,
			leadPending: false,
			isRecycled: false,
		});
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	ngAfterViewInit() {}

	edit() {
		this.form.enable();
		this.isEdit = true;
	}

	cancel() {
		if (!this.addMode) {
			const data = this.data
				? Object.assign(
						new BusinessMapper(),
						BusinessMapper.mapToView(this.data)
				  )
				: Object.assign(new BusinessMapper(), this.form.value);
			const adviser = this.advisers?.find((x) => x.value === data.adviser);
			data.adviser = adviser === undefined ? null : adviser.value;

			if (!data?.lastReview?._i) {
				this.lastReviewDate?.reset();
			}
			if (!data?.nextReview?._i) {
				this.nextReviewDate?.reset();
			}
			this.prepData(data);
		}
		this.form.disable();
		this.isEdit = false;
		this.toggleEdit(false);
	}

	prepareFormValue() {
		const form = this.form.value;
		const companyName = form.companyName || this.companyName.getRawValue()

		const newData = {
			...form,
			companyName: strUtil.safeTrimExtraSpace(companyName),
		};
		if (!!this.adviserReworkFeature) {
			const adviserServices = BusinessMapper.mapAdviserReworkServicesUpsert(
				form?.advisers || []
			);
			return {
				...newData,
				...adviserServices,
			};
		}
		return newData;
	}

	prepData(data?: PrimaryCustomerCompanyState) {
		this.businessConfigQuery.adviserReworkFeature$
			.pipe(
				tap((isEnabled) => {
					this.adviserReworkFeature = isEnabled;
					if (isEnabled) {
						const adviserServices =
							BusinessMapper.mapAdviserReworkServicesView(data);
						if (!this.AdviserList) {
							this.form.addControl('advisers', this.fb.array([]));
						}
						if (data) {
							this.form.reset(data);
						}
						if (this.addMode) {
							this.clearFormArray(this.AdviserList);
							this.addAdviser(true);
						} else {
							if (!!adviserServices?.length) {
								this.prepAdviserList(false, adviserServices);
							} else {
								this.clearFormArray(this.AdviserList);
								this.addAdviser(true);
							}
						}
					} else {
						setTimeout(() => {
							if (data) {
								this.form.reset(data);
							}
						}, 1);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save() {
		if (!this.form.valid || this.prepareFormValue().companyName.trim() === '') {
			if (
				this.prepareFormValue().companyName.trim() === '' ||
				!this.prepareFormValue().companyName
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.CompanyName));
				return;
			}
			if (!!this.adviserReworkFeature) {
				const advisers = this.prepareFormValue().advisers;
				if (!advisers?.length) {
					this.loggerService.Warning({}, getRequiredWarning(Fields.Adviser));
					return;
				} else if (advisers?.some((z) => !z.adviserId || !z.services?.length)) {
					this.loggerService.Warning({}, 'Adviser & Service are required');
					return;
				}
			} else {
				if (!this.prepareFormValue().adviser) {
					this.loggerService.Warning({}, getRequiredWarning(Fields.Adviser));
					return;
				}
			}
			if (this.lastReviewDate?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.LastReview));
				return;
			}
			if (this.nextReviewDate?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.NextReview));
				return;
			}
		}

		if (this.addMode) {
			const newData = this.adviserReworkFeature
				? omit(['advisers'], this.prepareFormValue())
				: this.prepareFormValue();
			this.saveEvent.emit({
				...newData,
				contactStatus: this.isLead ? 'L' : 'C',
			});
		} else {
			this.isSaving$.next(true);
			this.form.disable();
			const company = BusinessMapper.mapToView(this.data);
			// Combine the existing client with the new changes in group section
			const data: PrimaryCustomerCompanyState = {
				...company,
				...this.prepareFormValue(),
			};
			const newData = this.adviserReworkFeature
				? omit(['advisers'], data)
				: data;

			this.form.disable();
			// Send to API
			this.service
				.updateCompany(
					BusinessMapper.mapToUpsert(newData) as PrimaryCustomerCompanyState
				)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(() => {
					this.isSaving$.next(false);
					this.isEdit$.next(false);
				});
		}
	}

	onChangeLeadStatus(value: string) {
		if (!this._isEdit) {
			this.isSavingLS$.next(true);
			const company = BusinessMapper.mapToView(this.data);

			const data: PrimaryCustomerCompanyState = {
				...company,
				leadStatus: value,
			};

			this.service
				.updateCompany(
					BusinessMapper.mapToUpsert(data) as PrimaryCustomerCompanyState
				)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					() => {
						this.isSavingLS$.next(false);
					},
					() => {
						this.isSavingLS$.next(false);
					},
					() => {
						this.isSavingLS$.next(false);
					}
				);
		}
	}

	convertStatus(selectedContactStatus: string) {
		this.convertHeaderTitle = '';
		if (selectedContactStatus === 'L') {
			this.convertHeaderTitle = ' to Lead';
		} else if (selectedContactStatus === 'C') {
			this.convertHeaderTitle = ' to Client';
		} else if (selectedContactStatus === 'X') {
			this.convertHeaderTitle = ' to Ex-Client';
		}
		const initState: any = {
			header:
				'Convert ' + this.data.companyName + ' ' + this.convertHeaderTitle,
			customerId: this.data.customerID,
			name: this.data.companyName,
			contactStatus: selectedContactStatus,
			LT: this.LT,
			savefn: this.saveConvertBusiness,
		};
		if (this.convertHeaderTitle !== '') {
			this.modalService.show(ConvertModalComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		}
		this.convertHeaderTitle = '';
	}

	saveConvertBusiness = (data: any) => {
		return this.service.convertCompany(data).pipe(
			tap(() => {
				this.isSaving$.next(false);
			}),
			catchError(() => {
				this.isSaving$.next(false);
				return of({
					status_code: 500,
					status_description: '',
					id: 0,
				});
			})
		);
	};

	selectedCriteria(item) {
		this.selectServiceTab.emit(item);
	}

	/**
	 * Advice Reworker Methods
	 */
	addAdviser(isNew: boolean, data?: any) {
		const id = !!isNew ? null : data?.adviserId.toString();
		const servicesList = !!isNew ? [] : data?.services;
		this.AdviserList.push(
			this.fb.group({
				adviserId: [id, [Validators.required]],
				services: [servicesList, [Validators.required]],
			})
		);
	}

	removeAdviser(index) {
		this.AdviserList.removeAt(index);
	}

	prepAdviserList(isNew: boolean, data: AdviserServiceState[]) {
		while (this.AdviserList.length > 0) {
			this.AdviserList.removeAt(0);
		}
		data?.forEach((x) => {
			this.AdviserList.push(this.patchValue(isNew, x));
		});
	}

	patchValue(isNew: boolean, data?: AdviserServiceState) {
		const id = !!isNew ? null : data?.adviserId.toString();
		const servicesList = !!isNew ? [] : data?.services;
		return this.fb.group({
			adviserId: [id, [Validators.required]],
			services: [servicesList, [Validators.required]],
		});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		if (!formArray) {
			return;
		}
		while (formArray.length > 0) {
			formArray?.removeAt(0);
		}
	};

	onChangeAdviser(index: number, adviserId: string) {
		this.AdviserList.controls[index].get('services').setValue([]);
		const id = +adviserId;
		// Get all the services from other advisers
		const selectedChoices = this.getAllCurrentSelectedServices();
		const services = this.getStaffChoices(id);
		// Filter services from other advisers
		const choices = services?.filter(
			(x) =>{
				const code = SasReference?.find(
					(r) => r?.display === x?.value
				)?.service;//Get service code for checking
				return !selectedChoices?.includes(x?.value) && this.hasBusinessService.includes(code) //add business filter
			}
		);
		const defaultSelection = pluck('value', choices);
		// Prepopulate services from User Details
		this.AdviserList.controls[index].get('services').setValue(defaultSelection);
	}

	getAllCurrentSelectedServices(idToExclude?: number) {
		const formValue = this.form.getRawValue()?.advisers || [];
		if (!idToExclude) {
			return formValue?.reduce(
				(acc, cur) => uniq([...acc, ...(cur?.services || [])]),
				[]
			);
		}
		return formValue?.reduce((acc, cur) => {
			if (+cur.adviserId !== +idToExclude) {
				return uniq([...acc, ...(cur?.services || [])]);
			}
			return acc;
		}, []);
	}

	getAltAdvisers() {
		const value = util.tryParseJson(this.data?.altAdviser as any);
		if (this.addMode || !value) {
			this.altAdviserDropdown = this.altAdvisers;
		}
		const list = this.altAdvisers || [];
		const otherAltAdvisers = value
			?.reduce((a, c) => {
				const adviserDataActive = list?.find((x) => +x?.value === +c);
				if (!!adviserDataActive) {
					return a;
				}
				const data = this.allStaff
					?.filter((x) => +x?.StaffID === +c)
					?.map((x) =>
						ViewDisplayValue.Map(
							x.StaffID?.toString(),
							`${x.FirstName} ${x.LastName}`
						)
					);
				return [...data, ...a];
			}, [])
			?.filter(Boolean);
		this.altAdviserDropdown = [...(otherAltAdvisers || []), ...list]?.sort(
			(a, b) => a.display?.localeCompare(b.display)
		);
	}

	getStaffChoices = (id: number) =>
		BusinessMapper.getAdviserReworkChoices(id, this.allStaff);

	adviserChoices(index: number) {
		const adviserId = this.AdviserList.controls[index]?.value?.adviserId;
		const adviser = this.advisers?.find((x) => +x?.value === +adviserId);
		const selectedAdvisers = this.AdviserList.controls
			?.reduce((a, c) => {
				const val = c?.value?.adviserId;
				if (+val !== +adviserId) {
					return uniq([...a, +c?.value?.adviserId]);
				}
				return a;
			}, [])
			?.filter(Boolean);
		const adviserList = this.advisers?.filter(
			(x) => !selectedAdvisers?.includes(+x?.value)
		);

		if (!!adviser) {
			// If selected adviser is in the adviser list (allowed in accessibility settings) of the logged in user
			return adviserList;
		}
		// Should still show the adviser if selected regardless of accessibility settings
		const otherAdviser = this.allStaffChoices?.find(
			(x) => +x?.value === +adviserId
		);
		return !!otherAdviser
			? [otherAdviser, ...adviserList]?.sort((a, b) =>
					a.display?.localeCompare(b.display)
			  )
			: adviserList;
	}

	adviserServicesChoices(index: number) {
		const id = +this.AdviserList.controls[index]?.value?.adviserId;
		// Saved service of Adviser from CRM Profile
		const services = this.AdviserList.controls[index]?.value?.services;
		// Get Adviser Service from User Details Settings
		const availableChoices = this.getStaffChoices(id);
		// Get all the services from other advisers
		const otherSelectedChoices = this.getAllCurrentSelectedServices(id);
		// Get Currently selected services
		const details = this.AdviserList.controls[index].get('services').value;

		return (this.SAS || [])
			?.filter(
				(x) =>{
					const code = SasReference?.find(
						(r) => r?.display === x?.value
					)?.service; //Get Code for business checking
					return(
						(this.isEdit?
							(this.hasBusinessService?.includes(code)||details?.includes(x.value)) // Business Config services checker  and existing selected services checker
							:true) &&
						// Show Services from User Details
						!!availableChoices?.find((c) => c?.value === x?.value) ||
						// Include chosen service from CRM Profile
						// So that even if the service was remove from the User Details, it will still appear
						!!services?.includes(x?.value)
					)
				}
			)
			?.filter(
				// Prevent user from choosing service for diff advisers
				(x) => !otherSelectedChoices?.includes(x?.value)
			);
	}

	private getBusinessProfileServices() {
		combineLatest([
			this.businessConfigQuery.hasLR$,
			this.businessConfigQuery.hasM$,
			this.businessConfigQuery.hasFG$,
			this.businessConfigQuery.hasK$,
			this.businessConfigQuery.hasI$,
			this.businessConfigQuery.hasAP$,
			this.businessConfigQuery.hasCRT$,
			this.businessConfigQuery.hasMOAT$,
			this.businessConfigQuery.hasKOAT$,
			this.businessConfigQuery.hasCPMOAT$,
			this.businessConfigQuery.hasCPMOATD$,
			this.businessConfigQuery.hasCAR$
		]).pipe(
			map(([LR, M, FG, K, I, AP, CRT, MOAT, KOAT, CPMOAT, CPMOATD, CAR]) => {
				return { LR, M, FG, K, I, AP, CRT, MOAT, KOAT, CPMOAT, CPMOATD, CAR };
			}),
			take(1)
		).subscribe((services) => {
			this.hasBusinessService = Object.keys(services).filter(
				(k) => services[k]
			);
		});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
