<div class="font-weight-bold mt-3 mb-3">Current Insurance</div>

<div *ngFor="let insurance of soaCurrentInsurances$ | async; let i = index">
  <h6 *ngIf="insurance?.provider">{{ insurance?.provider }}:</h6>
  <div class="form-row d-none d-lg-flex">
    <div class="col-12 col-lg-11">
      <div class="form-row">
        <div class="col-12 col-lg-3 mb-2">
          <label class="font-weight-bold">Life Assured</label>
        </div>
        <div class="col-12 col-lg-3 mb-2">
          <label class="font-weight-bold">Product</label>
        </div>
        <div class="col-12 col-lg-3 mb-2">
          <label class="font-weight-bold">Sum Assured</label>
        </div>
        <div class="col-12 col-lg-3 mb-2">
          <label class="font-weight-bold">Premium</label>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 col-lg-11">
      <div
        class="form-row mb-2"
        *ngFor="let p of insurance?.lifeAssured; let ip = index"
      >
        <div class="col-12 col-lg-3 mb-2 border-bottom">
          <input
            class="form-control crt-form-control"
            placeholder="Life Assured"
            type="text"
            [value]="convertLifeAssuredToName(p.lifeAssured)"
            disabled
          />
        </div>
        <div class="col-12 col-lg-3 mb-2 border-bottom">
          <input
            class="form-control crt-form-control"
            placeholder="Product"
            type="text"
            value="{{ p.product }}"
            disabled
          />
        </div>
        <div class="col-12 col-lg-3 mb-2 border-bottom">
          <ng-container *ngIf="allowDropdown(p.product); else showInput">
            <input
              class="form-control crt-form-control"
              placeholder="Benefit"
              type="text"
              value="{{ p.benefit | currency }}{{
                allowDropdown(p.product) ? ' excess' : ''
              }}"
              disabled
            />
          </ng-container>
          <ng-template #showInput>
            <div class="dollar-icon">
              <input
                class="form-control crt-form-control"
                placeholder="Benefit"
                type="text"
                [(ngModel)]="p.benefit"
                [ngModelOptions]="{ standalone: true }"
                currencyMask
                disabled
              />
              <i>$</i>
            </div>
          </ng-template>
        </div>
        <div class="col-12 col-lg-3 mb-2 border-bottom">
          <div class="dollar-icon">
            <input
              class="form-control crt-form-control"
              placeholder="Premium"
              type="text"
              [(ngModel)]="p.premium"
              [ngModelOptions]="{ standalone: true }"
              currencyMask
              disabled
            />
            <i>$</i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-1">
      <button
        type="button"
        class="icon-btn w-auto h-auto pr-0"
        (click)="editProduct(insurance)"
      >
        <i class="material-icons md-16"> edit </i>
      </button>
      <button
        type="button"
        class="icon-btn w-auto h-auto pl-0"
        (click)="deleteCurrentInsurance(insurance.cRTId)"
      >
        <i class="material-icons md-16"> delete </i>
      </button>
    </div>
  </div>

  <div class="form-row">
    <div class="col-12 col-lg-11">
      <div class="form-row mb-2 align-items-center">
        <div class="col-0 col-lg-6"></div>
        <div class="col-12 col-lg-3 text-lg-right font-weight-bold tap-text-primary">Policy Fee</div>
        <div class="col-12 col-lg-3">
          <div class="dollar-icon">
            <input
              class="form-control crt-form-control"
              placeholder="Policy Fee"
              type="text"
              [(ngModel)]="insurance.policyFee"
              [ngModelOptions]="{ standalone: true }"
              currencyMask
              disabled
            />
            <i>$</i>
          </div>
        </div>
      </div>
      <div class="form-row mb-2 align-items-center">
        <div class="col-0 col-lg-6"></div>
        <div class="col-12 col-lg-3 text-lg-right font-weight-bold tap-text-primary">Total Premium</div>
        <div class="col-12 col-lg-3">
          <div class="dollar-icon">
            <input
              class="form-control crt-form-control"
              placeholder="Premium"
              type="text"
              value="{{ getSumOfLifeAssuredList(insurance) || 0 }}"
              appCurrencyFormatter
              disabled
            />
            <i>$</i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="!!insurance.policyDocuments && insurance?.policyDocuments.length > 0"
  >
    <div class="form-row mb-2" *ngFor="let d of insurance.policyDocuments">
      <div class="col-12 col-lg-auto word-break font-weight-bold">
        <span
          class="cursor-pointer px-1"
          (click)="downloadLink(d.referenceId)"
          >{{ d.value }}</span
        >
      </div>
    </div>
  </ng-container>
</div>

<div class="row">
  <div class="col-lg-4 mb-3">
    <button
      type="button"
      class="tap-btn tap-btn-outline tap-btn--shadow border-0 px-0 col-auto mb-2 px-4"
      (click)="addProduct()"
    >
      Add New +
    </button>
  </div>
</div>
