<div
	class="modal-header text-light tap-bg-primary d-flex justify-content-between"
>
	<h4 class="modal-title font-weight-normal">
		{{ title }}
	</h4>
	<div class="clrearfix d-flex">
		<button type="button" class="close" aria-label="Close" (click)="close()">
			<span class="material-symbols-outlined">close</span>
		</button>
	</div>
</div>
<div
	class="modal-body px-5"
	[formGroup]="form"
	[class.submitted]="submitted"
	[class.viewMode]="viewMode"
>
	<div class="modal-body__inner clearfix py-3 mx-4">
		<div class="container">
			<div class="row mb-2">
				<div class="col-12">
					<label for="requirementName" class="mb-1"
						>Requirement Name<span
							class="tap-required"
							*ngIf="
								RequirementName.invalid && RequirementName.status !== 'DISABLED'
							"
							>*</span
						></label
					><br />
					<app-input
						inputClassname="ui-form-control"
						id="requirementName"
						formControlName="requirementName"
						[isInvalid]="RequirementName.invalid"
					></app-input>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-6">
					<label for="dueDate" class="mb-1"
						>Due Date<span
							class="tap-required"
							*ngIf="DueDate.invalid && DueDate.status !== 'DISABLED'"
							>*</span
						></label
					><br />
					<app-date-input
						class="ui-form-control"
						formControlName="dueDate"
						textboxId="dueDate"
						[attr.disabled]="isLoading"
						[class.invalid-control]="DueDate.invalid"
					></app-date-input>
				</div>
				<div class="col-6">
					<label for="structuredHours" class="mb-1"
						>Structured Hours<span
							class="tap-required"
							*ngIf="
								StructuredHours.invalid && StructuredHours.status !== 'DISABLED'
							"
							>*</span
						></label
					><br />
					<app-input
						inputClassname="ui-form-control"
						id="structuredHours"
						formControlName="structuredHours"
						type="decimal"
						[isInvalid]="StructuredHours.invalid"
					></app-input>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-6">
					<label for="provider" class="mb-1"
						>Provider<span
							class="tap-required"
							*ngIf="
								(Provider.invalid ||
									(Provider.value === 'Other' && !OtherProvider.value)) &&
								Provider.status !== 'DISABLED'
							"
							>*</span
						></label
					><br />
					<ng-container *ngIf="showOtherProvider; else showProviderDropdown">
						<app-input
							inputClassname="ui-form-control"
							id="otherProvider"
							formControlName="otherProvider"
							[isInvalid]="
								OtherProvider.invalid ||
								(Provider.value === 'Other' && !OtherProvider.value?.trim())
							"
							(keyup)="onChangeOtherTextbox($event)"
							(focusout)="onChangeOtherTextbox($event)"
						></app-input>
					</ng-container>
					<ng-template #showProviderDropdown>
						<app-input-select
							id="provider"
							formControlName="provider"
							inputClassname="ui-form-control"
							displayProperty="display"
							emptyText="Select"
							[allowEmptyInitial]="true"
							[items]="UPDTP"
							[isInvalid]="Provider.invalid"
							(change)="onChangeOther($event)"
						></app-input-select>
					</ng-template>
				</div>
				<div class="col-6">
					<label for="category" class="mb-1"
						>Category<span
							class="tap-required"
							*ngIf="Category.invalid && Category.status !== 'DISABLED'"
							>*</span
						></label
					><br />
					<app-input-select
						id="category"
						formControlName="category"
						inputClassname="ui-form-control"
						displayProperty="display"
						emptyText="Select"
						[allowEmptyInitial]="true"
						[items]="UPDTC"
						[isInvalid]="Category.invalid"
					></app-input-select>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-6">
					<label for="status" class="mb-1"
						>Status<span
							class="tap-required"
							*ngIf="Status.invalid && Status.status !== 'DISABLED'"
							>*</span
						></label
					><br />
					<app-input-select
						id="status"
						formControlName="status"
						inputClassname="ui-form-control"
						displayProperty="display"
						emptyText="Select"
						[allowEmptyInitial]="true"
						[items]="UPDTS"
						[isInvalid]="Status.invalid"
						(change)="onChangeStatus()"
					></app-input-select>
				</div>
				<div class="col-6">
					<ng-container *ngIf="Status.value === 'Completed'">
						<label for="completedDate" class="mb-1"
							>Completed Date<span
								class="tap-required"
								*ngIf="
									CompletedDate.invalid && CompletedDate.status !== 'DISABLED'
								"
								>*</span
							></label
						><br />
						<app-date-input
							class="ui-form-control"
							formControlName="completedDate"
							textboxId="completedDate"
							[attr.disabled]="isLoading"
							[class.invalid-control]="CompletedDate.invalid"
						></app-date-input>
					</ng-container>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-11">
					<label for="securityGroup" class="mb-1"
						>Security Group<span
							class="tap-required"
							*ngIf="
								SecurityGroups.invalid && SecurityGroups.status !== 'DISABLED'
							"
							>*</span
						></label
					><br />
					<app-input-chips
						id="securityGroups"
						[customClass]="'limit-select-container'"
						placeholder="Select"
						formControlName="securityGroups"
						[items$]="securityGroups$"
						displayProperty="display"
						valueProperty="value"
						[disabled]="false"
						dropdownPosition="top"
						class="ui-form-control"
					></app-input-chips>
				</div>
				<div class="col-1 d-flex align-items-center pt-3">
					<input
						class="form-check-input"
						type="checkbox"
						id="allSecurityGroup"
						(click)="selectAllSecurityGroups()"
						[attr.disabled]="null"
						formControlName="selectAllSecurityGroups"
					/>
					<label class="form-check-label pt-1" for="allSecurityGroups">
						All
					</label>
				</div>
			</div>

			<div class="row mb-2">
				<div class="col-11">
					<label for="users" class="mb-1"
						>Users<span
							class="tap-required"
							*ngIf="
								Users.invalid && Users.status !== 'DISABLED'
							"
							>*</span
						></label
					><br />
					<app-input-chips
						id="users"
						[customClass]="'limit-select-container'"
						placeholder="Select"
						formControlName="users"
						[items$]="selectedUsers$"
						displayProperty="display"
						valueProperty="value"
						[disabled]="false"
						class="ui-form-control"
					></app-input-chips>
				</div>
				<div class="col-1 d-flex align-items-center pt-3">
					<input
						class="form-check-input"
						type="checkbox"
						id="allUsers"
						(click)="selectAllUsers()"
						[attr.disabled]="null"
						formControlName="selectAllUsers"
					/>
					<label class="form-check-label pt-1" for="allUsers">
						All
					</label>
				</div>
			</div>

			<div class="row mb-2">
				<div class="col-12">
					<label for="details" class="mb-1">Details</label><br />
					<textarea
						class="ui-form-control w-100"
						name="details"
						id="details"
						cols="30"
						rows="4"
						formControlName="details"
					></textarea>
				</div>
			</div>

			<!-- CTA -->
			<div class="row mb-2">
				<div class="col-12 mt-1" *ngIf="!viewMode">
					<ng-container *ngIf="!initializeEdit; else showDefaultCta">
						<ng-container *ngIf="!!isEditMode">
							<button
								class="btn btn-primary ui-btn-primary w-100"
								[disabled]="isLoading"
								(click)="save()"
							>
								{{ btnText || "Save" }}
								<i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
							</button>
						</ng-container>
						<!-- <ng-template #showAllowEdit>
							<button
								class="btn btn-primary ui-btn-primary w-100"
								(click)="allowEdit()"
							>
								Edit
							</button>
						</ng-template> -->
					</ng-container>
					<ng-template #showDefaultCta>
						<button
							class="btn btn-primary ui-btn-primary w-100"
							[disabled]="isLoading"
							(click)="save()"
						>
							{{ btnText || "Save" }}
							<i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
						</button>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>
