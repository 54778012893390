<ng-container *ngIf="!(isCompany$ | async); else company">
	<div class="crt__header d-flex align-items-center" [class.crt__header-panel--open]="isCollapsed">
		<div class="family-name mr-4">
			<!-- {{ (primaryClient$ | async)?.groupName }} Family -->
			<span
				class="text-truncate crt-title"
				[tooltip]="(primaryClient$ | async)?.groupName?.length > 21 ? (primaryClient$ | async)?.groupName : null"
				placement="right"
			>
				{{ (primaryClient$ | async)?.groupName }}
			</span>
			<span>Family</span>
		</div>
		<app-client-review-template-progress
			class="flex-grow-1"
			[isTopControls]="true"
			[isCompany$]="isCompany$ | async"
			[isCRTLoading]="isCRTLoading"
			(crtLoad)="handleLoad($event)"
		></app-client-review-template-progress>
	</div>
</ng-container>

<div class="crt d-flex flex-column flex-lg-row" [ngClass]="{ 'export-margin': isExporting }">
	<div class="crt__left-sidebar order-sm-0 order-1">
		<app-client-review-template-tabs
			[isCRTLoading]="isCRTLoading"
			[currentTab]="currentTab$ | async"
			(navigateToTab)="handleTabClick($event)"
		></app-client-review-template-tabs>
	</div>

	<div class="crt__main-content order-sm-0 order-2" [class.crt__main-content-panel--open]="isCollapsed">
		<div
			class="crt__main-content__inner-container clearfix"
			[class.h-auto]="
				(currentTab$ | async) === adviceProcessPageCodes?.Introduction ||
				tabInit === adviceProcessPageCodes?.Introduction
			"
		>
			<router-outlet></router-outlet>
		</div>
		<app-client-review-template-progress
			#progress
			(tabSet)="setCurrentTab($event)"
			[isTopControls]="false"
			[isCompany$]="isCompany$ | async"
			[isCRTLoading]="isCRTLoading"
			(crtLoad)="handleLoad($event)"
		></app-client-review-template-progress>
	</div>

	<div
		class="crt-collapse-panel"
		[class.crt-collapse-panel--open]="isCollapsed"
	>
		<div class="toggle-wrapper">
			<span
				class="crt-collapse-panel__toggle material-icons md-20 tap-text-color-h6"
				(click)="toggleCollapsePanel()"
			>
				{{ isCollapsed ? "keyboard_arrow_right" : "keyboard_arrow_left" }}
			</span>
		</div>
		<ng-container *ngIf="isNotes">
			<div
				id="crtNotes"
				class="col tap-client-profile__activity-timeline notes"
			>
				<app-crt-notes-timeline
					oatType="LOAT"
					[adviserId]="staffId$ | async"
					[crtTypes]="crtTypes$ | async"
					[crtNotes$]="crtNotesFactAndFind$"
					[activeType$]="activeType$"
					[addNoteFn$]="addCrtNote$"
					[editNoteFn$]="updateCrtNote$"
					[deleteNoteFn$]="deleteCrtNote$"
					[isLead]="isLead$ | async"
					[customerId]="customerId$ | async"
					[customerName]="customerName$ | async"
					[location]="customerLocation$ | async"
					[activityType$]="AT$"
					[activityMeeting$]="AM$"
					[adviserChoices$]="allActiveStaffs$"
					[adviserCalendarChoices$]="adviserCalendarChoices$"
					[addActivityFn$]="addActivity$"
					[exportFn$]="exportCrtNotes$"
					(setTypeEvent)="setCrtNotesType($event)"
				>
				</app-crt-notes-timeline>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #company>
	<div class="family-name py-3">
		{{ (primaryClient$ | async)?.companyName }}
	</div>
</ng-template>
