import { FactFindComputationState } from '../models/factfind-computation.model';
import {
	AllIncomeListState,
	IncomeSourceState,
} from '../models/income-source-details.model';
import { numUtil, util } from '../../../../../util/util';
import { getTaxAcc } from './tax-and-acc';
import * as R from 'ramda';

// ==============================
//     Use this only on MOAT
// ==============================

export const computeAnnualTaxable = (factFind: FactFindComputationState) => {
	const annualTaxable = R.sum([
		(factFind && factFind.overallTotalGrossIncome) || 0,
		(factFind && factFind.totalNetRentalIncome) || 0,
		(factFind && factFind.totalAnnualIncome) || 0,
	]);
	return +numUtil.formatToNumCurrency(annualTaxable);
};

export const computeMonthlyTaxable = (annualTaxable: number) => {
	return +numUtil.formatToNumCurrency(R.divide(+annualTaxable, 12));
};

export const computeMonthlyAfterTaxIncome = (
	incomeSource: IncomeSourceState[],
	factFind: FactFindComputationState,
	incomeList: AllIncomeListState
) => {
	// Compute TAX+ACC of All Income Earner
	const taxAccIncomeEarner = incomeSource?.reduce((acc, person) => {
		const totalGross = getTotalIndividualIncome(person, incomeList);
		const taxx = getTaxAcc(totalGross);
		return (acc += taxx);
	}, 0);

	// Compute TAX+ACC of Others;
	// From the owners of the property in the Net Rental Income
	const totalTaxAccOthers = computeTaxAccOfOthers(incomeList);

	// TAPNZ-8585: Compute total non-taxable other income
	const totalNonTaxOtherIncome = computeTotalNonTaxOtherIncome(incomeList);

	// Compute Sum of TAX+ACC of Income Earners + Others in Net Rental Income Properties
	const totalTaxAcc = R.sum([+taxAccIncomeEarner, totalTaxAccOthers]);

	// Result is Monthly
	// Compute Monthly After Tax Income
	// TAPNZ-8585: (Annual Taxable Joint Income - Total TAX+ACC) + Total other Non-taxable income / 12
	const getAfterTax = +R.subtract(
		factFind.annualTaxableJointIncome || 0,
		totalTaxAcc
	);
	const result = R.sum([getAfterTax, totalNonTaxOtherIncome]);
	return +numUtil.formatToNumCurrency(R.divide(result, 12));
};

export const computeTaxAccOfOthers = (incomeList: AllIncomeListState) => {
	const incomeSource = incomeList?.incomeSource || [];
	const netRental = incomeList?.rentalList || [];
	const properties = incomeList?.properties || [];
	const incomeEarners = incomeSource?.map((i) => +i.incomeEarner);

	// Compute the Net Rental Income of Other Owners of the property
	const netRentalOther = netRental?.reduce((a, c) => {
		const netRentalIncome = c?.netRentalIncome;
		const property = properties?.find((i) => +i?.cRTId === +c?.propertyAsset);
		const owners = util.tryCatchParse(property?.propertyOwner)
			? JSON.parse(property?.propertyOwner)
			: [];
		const totalTaxAcc = owners?.reduce((acc, cur) => {
			const isIncomeEarner = incomeEarners.includes(+cur);
			const toDivide = owners.length;

			if (!isIncomeEarner) {
				const totalPerPerson = +R.divide(netRentalIncome, toDivide) || 0;
				const taxAccPerPerson = +getTaxAcc(totalPerPerson);
				return (acc += +taxAccPerPerson);
			}
			return acc;
		}, 0);

		return (a += +totalTaxAcc);
	}, 0);
	// Result is Annual
	return +netRentalOther;
};

export const getTotalIndividualIncome = (
	incomeSource: IncomeSourceState,
	incomeList: AllIncomeListState
) => {
	const cRTId = incomeSource?.cRTId;
	const incomeEarners = (incomeList?.incomeSource || []).length;
	const netRental = incomeList?.rentalList || [];
	const otherIncome = incomeList?.otherList || [];
	const properties = incomeList?.properties || [];

	const getPropertyVal = (id, propId) => {
		const property = properties?.find((i) => +i?.cRTId === +propId);
		const isOwner = property?.propertyOwner?.includes(id);
		if (property && isOwner) {
			const owners = util.tryCatchParse(property?.propertyOwner)
				? JSON.parse(property?.propertyOwner)
				: [];
			return owners?.length;
		}
		return 0;
	};

	if (cRTId) {
		// Compute the Total Gross Income of Individual
		const totalGross = incomeSource?.totalGrossIncome;

		// Compute the Net Rental Income of Individual
		// Divide Net Rental Income by the # of owners
		const totalNet = netRental?.reduce((a, c) => {
			const toDivide =
				getPropertyVal(+incomeSource?.incomeEarner, +c?.propertyAsset) || 0;
			const total = toDivide > 0 ? R.divide(c?.netRentalIncome, toDivide) : 0;
			return (a += total);
		}, 0);

		// Compute the Other Income of Individual
		// Divide the Total Income by the # of Income Earners
		// Taxable other incomes only
		let totalOther = otherIncome?.reduce((a, c) => {
			if (!c.isNonTaxable) {
				return R.sum([a, +c?.annualIncome || 0]);
			}
			return a;
		}, 0);
		totalOther = R.divide(totalOther, incomeEarners) || 0;

		// Compute SUM:
		// Total Individual Income = Total Gross Income + Net Rental Income of Individual + Other Income of Individual
		// Result is Annual
		return R.sum([totalGross, totalNet, totalOther]);
	}
	return 0;
};

export const computeTotalNonTaxOtherIncome = (
	incomeList: AllIncomeListState
) => {
	const otherIncome = incomeList?.otherList || [];
	const totalOtherNonTax = otherIncome?.reduce((a, c) => {
		if (c.isNonTaxable) {
			return R.sum([a, +c?.annualIncome || 0]);
		}
		return a;
	}, 0);
	return totalOtherNonTax;
};
