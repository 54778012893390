import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MortgageProviderCommissionState } from './provider-commission.model';
import { MortgageProviderCommissionStore } from './provider-commission.store';

@Injectable()
export class MortgageProviderCommissionQuery extends QueryEntity<MortgageProviderCommissionState[]> {
	all$ = this.selectAll();

	constructor(protected store: MortgageProviderCommissionStore) {
		super(store);
	}
}
