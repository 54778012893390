<div class="scope-of-service pr-2">
  <form [formGroup]="form" class="form">
    <div class="row pb-0 pl-3 pb-2 pt-4">
      <div class="col-12 col-lg-7">
        <div class="row">
          <div class="col-12 scope-of-service__labels">
            <label class="label-font font-weight-bold">Intro</label>
          </div>
          <div class="col-12 scope-of-service__textbox">
            <textarea
              class="form-control"
              name="intro"
              id="intro"
              cols="30"
              rows="2"
              formControlName="intro"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-0 pl-3 pb-2 pt-2">
      <div class="col-12 col-lg-7">
        <div class="row">
          <div class="col-12 scope-of-service__labels">
            <label class="label-font font-weight-bold">Services</label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="lifeInsurance"
                  formControlName="lifeInsurance"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">Life Insurance</label>
              </div>
            </div>

            <div class="row scope-of-service__services pb-3" *ngIf="form.get('lifeInsurance').value">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="lifeInsuranceDetails"
                  id="lifeInsuranceDetails"
                  cols="30"
                  rows="4"
                  formControlName="lifeInsuranceDetails"
                ></textarea>
              </div>
            </div>

            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="criticalIllness"
                  formControlName="criticalIllness"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">Critical Illness</label>
              </div>
            </div>

            <div class="row scope-of-service__services pb-3" *ngIf="form.get('criticalIllness').value">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="criticalIllnessDetails"
                  id="criticalIllnessDetails"
                  cols="30"
                  rows="4"
                  formControlName="criticalIllnessDetails"
                ></textarea>
              </div>
            </div>

            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="tPDInsurance"
                  formControlName="tPDInsurance"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">TPD Insurance</label>
              </div>
            </div>

            <div class="row scope-of-service__services pb-3" *ngIf="form.get('tPDInsurance').value">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="tPDInsuranceDetails"
                  id="tPDInsuranceDetails"
                  cols="30"
                  rows="4"
                  formControlName="tPDInsuranceDetails"
                ></textarea>
              </div>
            </div>

            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="disabilityInsurance"
                  formControlName="disabilityInsurance"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">Disability Insurance</label>
              </div>
            </div>

            <div class="row scope-of-service__services pb-3" *ngIf="form.get('disabilityInsurance').value">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="disabilityInsuranceDetails"
                  id="disabilityInsuranceDetails"
                  cols="30"
                  rows="4"
                  formControlName="disabilityInsuranceDetails"
                ></textarea>
              </div>
            </div>

            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="medicalInsurance"
                  formControlName="medicalInsurance"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">Medical Insurance</label>
              </div>
            </div>

            <div class="row scope-of-service__services pb-3" *ngIf="form.get('medicalInsurance').value">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="medicalInsuranceDetails"
                  id="medicalInsuranceDetails"
                  cols="30"
                  rows="4"
                  formControlName="medicalInsuranceDetails"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-0 pl-3 pb-2 pt-2">
      <div class="col-12 col-lg-7">
        <div class="row">
          <div class="col-12 scope-of-service__textbox">
            <textarea
              class="form-control"
              name="instructionOrLimitation"
              id="instructionOrLimitation"
              cols="30"
              rows="4"
              formControlName="instructionOrLimitation"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-0 pl-3 pb-2 pt-2">
      <div class="col-12 col-lg-7">
        <div class="row">
          <div class="col-12 scope-of-service__textbox">
            <textarea
              class="form-control"
              name="otherInformation"
              id="otherInformation"
              cols="30"
              rows="4"
              formControlName="otherInformation"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-0 pl-3 pt-2">
      <div class="col-12 col-lg-7">
        <div class="row">
          <div class="col-12">
            <label class="font-weight-bold">Other Advice</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-0 pl-3 b-2 pt-2">
      <div class="col-12 col-lg-7">
        <div class="row">
          <div class="col-lg-12 col-12 pb-3">
            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="businessRiskInsurance"
                  formControlName="businessRiskInsurance"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">Business Risk Insurance</label>
              </div>
            </div>

            <div class="row scope-of-service__services">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="businessRiskInsuranceNote"
                  id="businessRiskInsuranceNote"
                  cols="30"
                  rows="4"
                  formControlName="businessRiskInsuranceNote"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-12 pb-3">
            <div class="row scope-of-service__services" *ngIf="!(kloatFeature$|async);else kloatFormat">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="kiwiSaver"
                  formControlName="kiwiSaver"
									(change)="checkKiwisaverCheckboxes('referral')"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">KiwiSaver</label>
              </div>
            </div>
						<ng-template #kloatFormat>
							<div class="row scope-of-service__services kloatFormat">
								<div class="col-1 scope-of-service__services__checkbox">
									<input
										type="checkbox"
										class="chckbox form-control theme-crm-field-disabled"
										id="kiwiSaverAdvice"
										formControlName="kiwiSaverAdvice"
										(change)="checkKiwisaverCheckboxes('advice')"
									/>
								</div>
								<div class="col-4 col-md-2 col-lg-2 pt-1 pl-2 scope-of-service__services__label">
									<label class="form-check-label">KiwiSaver Advice</label>
								</div>

								<div class="col-1 scope-of-service__services__checkbox">
									<input
										type="checkbox"
										class="chckbox form-control theme-crm-field-disabled"
										id="kiwiSaver"
										formControlName="kiwiSaver"
										(change)="checkKiwisaverCheckboxes('referral')"
									/>
								</div>
								<div class="col-4 col-md-2 col-lg-2 pt-1 pl-2 scope-of-service__services__label">
									<label class="form-check-label">KiwiSaver Referral</label>
								</div>
							</div>
						</ng-template>

            <div class="row scope-of-service__services">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="kiwiSaverNote"
                  id="kiwiSaverNote"
                  cols="30"
                  rows="4"
                  formControlName="kiwiSaverNote"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-12 pb-3">
            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="homeCarAndContentsInsurance"
                  formControlName="homeCarAndContentsInsurance"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label"
                  >Home, Car & Contents Insurance</label
                >
              </div>
            </div>

            <div class="row scope-of-service__services">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="homeCarAndContentsInsuranceNote"
                  id="homeCarAndContentsInsuranceNote"
                  cols="30"
                  rows="4"
                  formControlName="homeCarAndContentsInsuranceNote"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-12 pb-3">
            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="residentialMortgageOrLending"
                  formControlName="residentialMortgageOrLending"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label"
                  >Residential Mortgage / Lending</label
                >
              </div>
            </div>

            <div class="row scope-of-service__services">
              <div class="col-12 scope-of-service__textbox">
                <textarea
                  class="form-control"
                  name="residentialMortgageOrLendingNote"
                  id="residentialMortgageOrLendingNote"
                  cols="30"
                  rows="4"
                  formControlName="residentialMortgageOrLendingNote"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="row scope-of-service__services">
              <div class="col-1 scope-of-service__services__checkbox">
                <input
                  type="checkbox"
                  class="chckbox form-control theme-crm-field-disabled"
                  id="other"
                  formControlName="other"
                />
              </div>
              <div class="col-10 pt-1 pl-2 scope-of-service__services__label">
                <label class="form-check-label">Other</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-0 pl-3 mb-4">
      <div class="col-12 col-lg-7 text-right">
        <app-manual-loader-mini
          [isLoading]="isLoading"
        ></app-manual-loader-mini>
        <button
          type="button"
          class="btn btn-primary my-2"
          [appTheme]="{ 'background-color': 'primarycolor' }"
          (click)="save()"
          *ngIf="!isLoading"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>
