import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TransferActivityModel } from '../../../domain/bl-staff/bl-staffs.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-delete-dropdown-modal',
	templateUrl: './delete-dropdown-modal.component.html',
})
export class DeleteDropdownModalComponent implements OnInit, DeleteModalModel {
	confirm$: Observable<any>;
	transfer: (model: TransferActivityModel) => Observable<any>;

	header: string;
	advisers: any[];
	staff: any;

	message: string;
	messageExport: string;
	messageActivity: string;
	isSaving = false;

	isTransfer = false;

	constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {
		this.setupProps();
		this.setupSubs();
	}

	form: UntypedFormGroup = this.fb.group({
		transfer: null,
		adviser: null
	});

	setupProps() {
		this.header = 'Delete';
		this.message = 'Delete?';
	}
	setupSubs() { }

	continue() {
		if (this.isTransfer && !(this.form.get('adviser').value)) {
			return;
		}
		this.isSaving = true;

		of({
			CurrentAdviser: this.staff.StaffID,
			NewAdviser: this.form.get('adviser').value ? +this.form.get('adviser').value : 0
		}).pipe(
			switchMap(x => this.isTransfer ?
				this.transfer(x) : of(x)
			),
			switchMap(() => this.confirm$)
		).subscribe(
			() => { this.decline(); setTimeout(() => { this.isSaving = false; }, 1000); },
			() => { this.decline(); setTimeout(() => { this.isSaving = false; }, 1000); },
			() => { this.decline(); setTimeout(() => { this.isSaving = false; }, 1000); },
		);
	}

	decline() {
		this.bsModalRef.hide();
	}

	ngOnInit() {
		this.message = `Choose an adviser to replace '${this.staff.FirstName} ${this.staff.LastName}'`;
		this.messageActivity = 'Transfer activities';

		this.form.get('transfer').valueChanges.subscribe(val => {
			if (val) {
				this.isTransfer = true;
			} else {
				this.isTransfer = false;
			}
		});
	}
}

export interface DeleteModalModel {
	confirm$: Observable<any>;
	header: string;
	staff: string;
}

