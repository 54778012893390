import {
	DropdownValue,
	DropdownValueUpdate,
	DropdownValueAdd,
	AllDropdownValues,
	DropdownValuev2,
} from '../../model/dropdownValue';
import { ViewDropdownMapper } from './viewDropdown.mapper';

export class ViewDropdownValue {
	constructor(
		public dropdownValueId: number = null,
		public dropdownCode: string = '',
		public dropdownValue: string = '',
		public dropdownOrder: number = 0,
		public isDefault: boolean = false,
		public isEdit: boolean = false,
		public tempId?: number,
		public isNew?: boolean,
		public parsedValue?: any,
		public parsedDefaultValue?: any
	) {}
	isSaving = false;

	static mapToAllUpdateModel(code: string, d: ViewDropdownValue[]) {
		return new AllDropdownValues(
			code,
			d?.map((x) => ViewDropdownValue.mapToModelv2(x))
		);
	}
	static mapToModelv2(value) {
		return {
			DropdownCode: value.dropdownCode,
			DropdownOrder: value.dropdownOrder,
			DropdownValue: value.dropdownValue,
			DropdownValueID: value.dropdownValueId,
			IsDefault: value.isDefault,
		} as DropdownValuev2;
	}
	static mapFromModel(d: DropdownValue) {
		return new ViewDropdownValue(
			d.DropdownValueID,
			d.DropdownCode,
			d.DropdownValue,
			d.DropdownOrder,
			d.IsDefault
		);
	}
	mapToModel() {
		return new DropdownValue(
			this.dropdownValueId,
			this.dropdownCode,
			this.dropdownValue,
			this.dropdownOrder,
			this.isDefault
		);
	}

	// tslint:disable-next-line: member-ordering
	static mapToDropdownValueUpdate(
		dropdownId: number,
		dropdownValue: ViewDropdownValue,
		CompanyCode: string
	) {
		const value = ViewDropdownMapper.formatValueCamelToPascal(
			dropdownValue.dropdownValue
		);
		return new DropdownValueUpdate(
			dropdownId,
			dropdownValue.dropdownValueId,
			dropdownValue.dropdownCode,
			value,
			dropdownValue.dropdownOrder,
			dropdownValue.isDefault,
			CompanyCode
		);
	}

	// tslint:disable-next-line: member-ordering
	static mapToDropdownValueAdd(
		dropdownValue: ViewDropdownValue,
		CompanyCode: string
	) {
		const value = ViewDropdownMapper.formatValueCamelToPascal(
			dropdownValue.dropdownValue
		);
		return new DropdownValueAdd(
			dropdownValue.dropdownCode,
			value,
			dropdownValue.dropdownOrder,
			dropdownValue.isDefault,
			CompanyCode
		);
	}
}
