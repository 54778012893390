import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo, take, tap } from 'rxjs/operators';
import { DropdownValueService } from '../../../domain/dropdown-value/dropdown-value.service';
import { LeadSearchService } from './state/lead-search.service';

const dropdownCodes: string[] = ['PCLE', 'LS', 'PCLT', 'AT', 'SAS', 'LST', 'AM'];

@Injectable()
export class LeadSearchResolver implements Resolve<boolean> {
	constructor(
		private dropdownValueService: DropdownValueService,
		private leadSearchService: LeadSearchService,
	) {}

	resolve(): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);

		return forkJoin([dropdown$]).pipe(
			tap(() => this.leadSearchService.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.leadSearchService.getColumnWidths().pipe(take(1)).subscribe()),
			mapTo(true),
		);
	}
}
