<div class="clearfix">
	<div
		class="form-row font-weight-bold d-none d-lg-flex mb-2"
		[class.tap-opacity-25]="(!properties || properties?.length === 0) && !isAdd"
	>
		<div class="col-lg-4">Property Address</div>
		<div class="col-lg-3">Owner(s)</div>
		<div class="col-lg-2">Property Use</div>
		<div class="col-lg-2">Property Value</div>
	</div>

	<ng-content select="app-manual-loader-mini"></ng-content>

	<div class="mb-4">
		<app-property-form
			*ngFor="
				let p of properties;
				let i = index;
				let last = last;
				trackBy: trackByFn
			"
			[formId]="i"
			[property]="p"
			[policyOwners]="policyOwners"
			[SU$]="SU$"
			[SU$]="SU$"
			[SV1$]="SV1$"
			[ST$]="ST$"
			[STI$]="STI$"
			[APCRTF$]="APCRTF$"
			[addFn$]="addFn$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[propertyAddresses]="propertyAddresses"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="d-block mb-2"
			[class.border-bottom]="!last"
			[getOwnerChoices]="getOwnerChoices"
		></app-property-form>
	</div>

	<div class="clearfix mb-3">
		<app-property-form
			*ngIf="isAdd"
			[addMode]="true"
			[policyOwners]="policyOwners"
			[SU$]="SU$"
			[SV1$]="SV1$"
			[ST$]="ST$"
			[STI$]="STI$"
			[APCRTF$]="APCRTF$"
			[addFn$]="addFn$"
			[propertyAddresses]="propertyAddresses"
			[adviceProcessId]="adviceProcessId"
			[getOwnerChoices]="getOwnerChoices"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			[pciAddress]="checkForPciAddress()? pciAddress :{}"
			class="mb-2"
		></app-property-form>
	</div>

	<div class="form-row align-items-center mb-4">
		<div class="col-lg-4">
			<button
				id="propertyAddPropertyButton"
				(click)="add()"
				type="button"
				[disabled]="isAdd"
				class="tap-btn tap-btn-outline tap-btn--shadow px-4"
			>
				Add Property +
			</button>
		</div>
		<div class="col-lg-3"></div>
		<div class="col-lg-2 font-weight-bold tap-text-primary text-right">
			Total:
		</div>
		<div class="col-lg-3 font-weight-bold">
			{{ totalPropertyValue | currency: "USD":"$":"1.2-2" }}
		</div>
	</div>
</div>
