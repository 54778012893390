import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { EmailTemplateService } from '@modules/emails/email-settings/state/email-template.service';
import { ClaimTypeChoices, EmailTemplateModel } from '@modules/emails/email-settings/state/email-template.store';
import { getEmailContentNoHtml } from '@shared/converter/content-email';
import {
	getContentWithMergeTags,
	removeMtWrappers,
} from '@shared/converter/content-merge-tags';
import { logMessage } from '@shared/error-message/error-message';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { EmailLinkList } from '@shared/models/client-review-template/merge-tags/email-settings/email-links';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { EmailTypes } from '@shared/models/emails/crt/email.model';
import { WysiwygComponent } from '@shared/wysiwyg/wysiwyg.component';
import { AppFormGroup } from '@util/form-group';
import { ObservableUtil } from '@util/observable.util';
import { convertUtil } from '@util/util';
import { validatorUtil } from '@util/validator.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, finalize, map, mergeMap, of } from 'rxjs';

@Component({
	selector: 'app-email-template-modal-form',
	templateUrl: './email-template-modal-form.component.html',
	styleUrls: ['./email-template-modal-form.component.scss'],
})
export class EmailTemplateModalFormComponent implements OnInit {
	@ViewChild('testEmailTemplateRef') testEmailTemplateRef: TemplateRef<any>;

	@ViewChild('editor') editor: WysiwygComponent;

	@Input() documentInfo: {
		documentType: any;
		type: any;
		referenceId: number;
		customerId: number;
	};

	title: string;

	createFn$: (data: any) => Observable<any>;

	updateFn$: (data: any) => Observable<any>;

	testEmailFn$: (data: any) => Observable<boolean>;

	getMT$: () => Observable<MergeTagState[]>;

	isCreate = false;

	closeSubject: Subject<boolean>;

	testEmailModalRef: BsModalRef;

	etTypeChoices: ViewDisplayValue[] = [];
	claimTypeChoices: ViewDisplayValue[] = [];

	formGroup = new AppFormGroup({
		templateName: new FormControl(null, [
			Validators.required,
			this.requiredValidator(),
		]),
		createdDateTime: new FormControl(),
		emailTemplateSettingsType: new FormControl(),
		modifiedDateTime: new FormControl(),
		subject: new FormControl(null, [this.requiredValidator()]),
		carbonCopy: new FormControl(null),
		blindCarbonCopy: new FormControl(null),
		user: new FormControl(),
		userEmail: new FormControl(),
		userFirstname: new FormControl(),
		body: new FormControl(),
		emailTemplateId: new FormControl(null),
		defaultTemplateName: new FormControl(null),
		type: new FormControl(null),
	});

	get body(): AbstractControl {
		return this.formGroup.controls.body;
	}

	get carbonCopy(): AbstractControl {
		return this.formGroup.controls.carbonCopy;
	}

	get blindCarbonCopy(): AbstractControl {
		return this.formGroup.controls.blindCarbonCopy;
	}

	get emailTemplateSettingsType(): AbstractControl {
		return this.formGroup.controls.emailTemplateSettingsType;
	}

	testEmailForm = new AppFormGroup({
		email: new FormControl('', [
			this.requiredValidator(),
			validatorUtil.emailValidator,
		]),
	});

	get testEmailControl(): AbstractControl {
		return this.testEmailForm.controls.email;
	}

	remainOpenETSTypeChips: boolean = true;

	requiredValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (Boolean(control.value?.trim())) {
				return null;
			}
			return { error: true };
		};
	}

	isSendingTestEmail = false;

	bodyContent = '<p></p>';
	initContent = '<p></p>';

	data: any;

	optionsWysiswyg = {
		heightMax: 400,
		heightMin: 500,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkList: EmailLinkList,
		linkAutoPrefix: '',
	};

	shortCodes: any;

	isSaving = false;

	isLoading = false;

	constructor(
		private bsModalService: BsModalService,
		private loggerService: LoggerService,
		private emailTemplateService: EmailTemplateService,
		private bLStaffsQuery: BLStaffsQuery
	) {}

	ngOnInit(): void {
		this.filterChoices();
		this.formGroup.patchValue(this.data);
		this.initContent = this.data.bodyContent;
		this.bodyContent = this.data.bodyContent;

		// On Create Email template by default all Email Types are selected
		if (this.isCreate) {
			const defaultEmailTemplateTypes = this.etTypeChoices?.map((x) => x?.value);
			this.emailTemplateSettingsType.setValue(defaultEmailTemplateTypes);
		}
	}

	filterChoices() {
		let typeChoices = this.etTypeChoices || [];
		if (this.isCreate) {
			this.claimTypeChoices = typeChoices;
		} else {
			const savedTypes = this.data?.emailTemplateSettingsType || [];
			if (savedTypes?.length > 0) {
				const filtered = savedTypes
					?.filter((x) => !this.etTypeChoices?.find((c) => x === c?.value));
				filtered?.forEach((x) => {
						const data = ClaimTypeChoices?.find((ct) => ct?.value === x);
						if (data) {
							typeChoices.push(data);
						}
					});
			}
			this.claimTypeChoices = typeChoices;
		}
	}

	save(): void {
		if (this.isSaving) {
			return;
		}
		if (this.formInvalid()) {
			return;
		}
		this.isSaving = true;
		of(this.getData())
			.pipe(
				mergeMap((data) => {
					return this.isCreate ?
						this.createFn$(data) :
						this.updateFn$(data);
				}),
				finalize(() => (this.isSaving = false)),
				ObservableUtil.finalizeSuccess(() => {
					this.closeSubject.next(true);
					this.closeTestEmail();
					this.close();
				})
			)
			.subscribe();
	}

	private getData(): EmailTemplateModel {
		const data = this.formGroup.getRawValue() as EmailTemplateModel;
		// @ts-ignore-next
		data.bodyContent = this.bodyContent;
		data.mergeTag = [];
		return data;
	}

	getTestEmailData(): any {
		const data = this.formGroup.getRawValue() as EmailTemplateModel;
		// @ts-ignore-next
		data.bodyContent = this.bodyContent;
		data.blindCarbonCopy = Boolean(data.blindCarbonCopy)
			? `${data.blindCarbonCopy},${data.userEmail}`
			: data.userEmail;
		data.bodyContent = this.bodyContent;
		const staffs = this.bLStaffsQuery.getAll();
		const blindCarbonCopy = [];
		data.blindCarbonCopy.split(',').forEach((email) => {
			const user = staffs.find((s) => s.EmailAddress === email);
			if (user) {
				blindCarbonCopy.push({
					Email: user.EmailAddress,
					FirstName: user.FirstName,
					Name: `${user.FirstName} ${user.LastName}`,
				});
			}
		});
		data.blindCarbonCopy = blindCarbonCopy as any;

		if (data.carbonCopy) {
			const ccUser = staffs.find((s) => s.EmailAddress === data.carbonCopy);
			if (ccUser) {
				// @ts-ignore-next
				data.carbonCopy = [
					{
						Email: ccUser.EmailAddress,
						FirstName: ccUser.FirstName,
						Name: `${ccUser.FirstName} ${ccUser.LastName}`,
					},
				];
			} else {
				data.carbonCopy = [
					{
						Email: data.carbonCopy,
						FirstName: data.carbonCopy,
						Name: data.carbonCopy,
					},
				] as any;
			}
		} else {
			data.carbonCopy = [] as any;
		}

		// data.blindCarbonCopy = [] as any;
		// data.carbonCopy = [] as any;
		return data;
	}

	onEditorValueChanged(e: any): void {
		this.body.setValue(e.content);
		this.bodyContent = e.content;
	}

	sendTestEmail(): void {
		if (this.isSendingTestEmail) {
			return;
		}
		this.isSendingTestEmail = true;
		const data = this.getTestEmailData() as any;
		const email = this.testEmailControl.value;
		this.getMT$()
			.pipe(
				mergeMap((mergeTags) =>
					convertUtil
						.convertToBase64(
							getContentWithMergeTags(data.bodyContent, mergeTags)
						)
						.pipe(map((x) => ({ base64Content: x || '', mergeTags })))
				),
				mergeMap(({ base64Content, mergeTags }) => {
					data.HTMLBody = base64Content;
					data.subject = removeMtWrappers(
						getContentWithMergeTags(data.subject || '', mergeTags)
					);
					data.stringBody = getEmailContentNoHtml(
						getContentWithMergeTags(data.bodyContent, mergeTags)
					);
					data.emailDestination = [
						{
							Email: this.testEmailControl.value,
							FirstName: this.testEmailControl.value,
							Name: this.testEmailControl.value,
						},
					];
					return this.emailTemplateService.sendEmail(
						data,
						EmailTypes.EmailTemplate,
						0
					);
				}),
				finalize(() => {
					this.isSendingTestEmail = false;
				})
			)
			.subscribe(() => {
				this.loggerService.Success({}, `Email has been sent to ${email}`);
				this.testEmailModalRef?.hide();
				this.testEmailForm.patchValue({
					email: '',
				});
			});
	}

	close(): void {
		this.closeSubject.next(false);
	}

	closeTestEmail(): void {
		this.testEmailModalRef?.hide();
		this.testEmailForm.patchValue({ email: '' });
	}

	private formInvalid(): boolean {
		if (this.carbonCopy.errors?.duplicate || this.blindCarbonCopy.errors?.duplicate) {
			this.loggerService.Warning(
				null,
				'Duplicate Email Address'
			);
			return true;
		}
		if (this.formGroup.invalid || !this.editor.isValid()) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return true;
		}
		return false;
	}

	testEmail(): void {
		if (this.formInvalid()) {
			return;
		}
		this.testEmailModalRef = this.bsModalService.show(
			this.testEmailTemplateRef,
			{
				class:
					' modal-dialog modal-dialog-centered modal-md email-modal-template',
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}
}
