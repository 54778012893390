import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { CarInsuranceMapper } from '../../../../../../shared/models/client-review-template/fg-insurance/car-insurance.mapper';
import { CarInsuranceState } from '../../../../../../shared/models/client-review-template/fg-insurance/car-insurance.model';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { NoWhitespaceValidator } from '../../../../../directive/no-whitespace/no-whitespace.directive';
import { Observable, Subject, takeUntil } from 'rxjs';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-car-form',
	templateUrl: './car-form.html',
	styleUrls: ['./car-form.scss'],
})
export class CarFormComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Output() cancelEvent = new EventEmitter<any>();
	@Output() saveEvent = new EventEmitter<any>();
	@Input() currentProvider$: Observable<ViewDisplayValue[]>;
	@Input() APCRTCLC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTVP$: Observable<ViewDisplayValue[]>;
	@Input() APCRTKPY$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() cRTId: number;
	@Input() isAdd: boolean;
	@Input() cRTInfo: CarInsuranceState;
	@Input() viewMode: boolean;
	@Input() loading: boolean;
	@Input() loatv2Enabled: boolean;

	public bsModalRef: BsModalRef;

	form: UntypedFormGroup;
	showClaimInLast12Months = false;
	submitted = false;

	cancelLoading: boolean;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		if (!this.isAdd) {
			this.form.reset(CarInsuranceMapper.mapToView(this.cRTInfo));
			this.selectChange();
		}
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	  this.watchFormChanges();
	}

	get Model() {
		return this.form.get('model');
	}
	get LastReviewed() {
		return this.form.get('lastReviewed');
	}
	get ClaimIn12Months() {
		return this.form.get('claimIn12Months');
	}
	get HasCarHaveTurbo() {
		return this.form.get('hasCarHaveTurbo');
	}
	get VehicleParked() {
		return this.form.get('vehicleParked');
	}
	get HasVehicleBeenModified() {
		return this.form.get('hasVehicleBeenModified');
	}
	get KilometerPerYear() {
		return this.form.get('kilometerPerYear');
	}
	get HasExcludeDriversUnder25YearsOld() {
		return this.form.get('hasExcludeDriversUnder25YearsOld');
	}
	get HasClaimedInLast12Months() {
		return this.form.get('hasClaimedInLast12Months');
	}
	get NumberPlate() {
		return this.form.get('numberPlate');
	}
	get Provider() {
		return this.form.get('provider');
	}
	get Premium() {
		return this.form.get('premium');
	}
	get WhatHappened() {
		return this.form.get('whatHappened');
	}
	get WhatHappenedOther() {
		return this.form.get('whatHappenedOther');
	}

	watchFormChanges() {
		this.WhatHappenedOther.valueChanges.pipe(takeUntil(this.onDestroy$))
			.subscribe((value) => {
				if(!value && !this.WhatHappenedOther.pristine) {
					this.WhatHappened.setValue('');
				}
		});
    this.WhatHappened.valueChanges
      .pipe(takeUntil(this.onDestroy$ ))
      .subscribe((value) => {
      if (value === 'Other') {
        this.WhatHappenedOther.setValidators(Validators.required);
      } else {
        this.WhatHappenedOther.clearValidators();
				this.WhatHappenedOther.markAsPristine();
      }
      this.WhatHappenedOther.updateValueAndValidity();
    });
  }

	buildForm() {
		this.form = this.fb.group({
			numberPlate: ['', [Validators.required, NoWhitespaceValidator]],
			model: [''],
			provider: ['', Validators.required],
			premium: [''],
			lastReviewed: [''],
			currentExcess: [''],
			sumInsured: [''],
			hasClaimedInLast12Months: [''],
			driverName: [''],
			whatHappened: [''],
      whatHappenedOther: [''],
			date: [''],
			cost: [''],
			hasCarHaveTurbo: [''],
			vehicleParked: [''],
			hasVehicleBeenModified: [''],
			kilometerPerYear: [''],
			hasExcludeDriversUnder25YearsOld: [''],
		});
	}

	selectChange() {
		this.showClaimInLast12Months =
			this.HasClaimedInLast12Months.value === 'Yes' ? true : false;
	}

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		const adviceProcessId = parseInt(
			this.route.snapshot.paramMap.get('adviceProcessId'),
			10
		);
		const data = CarInsuranceMapper.mapToUpsert(
			this.form.value,
			+this.cRTId,
			+adviceProcessId
		);
		this.saveEvent.emit(data);
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
