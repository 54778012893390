import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { FGBookBreakdown } from './fgbb.model';
import { FGBookBreakdownStore } from './fgbb.store';


@Injectable()
export class FGBookBreakdownService {

  private readonly endpoint = 'widgets?type=FGBB';

  constructor(
    private api: ApiService,
    private store: FGBookBreakdownStore
  ) { }

  /**
   * Fetch and store Book Breakdown data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetFGBookBreakdown(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post3<FGBookBreakdown[]>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.fGBookBreakdowns = res;
      })),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
