import { Component, AfterViewInit, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DashboardQuery } from '../state/dashboard.query';
import { Observable, Subject } from 'rxjs';
import { Widget } from '../state/widget.model';
import { takeUntil, delay, merge, withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';
import { DashboardService } from '../state/dashboard.service';
import * as Muuri from 'muuri';
import * as R from 'ramda';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import produce from 'immer';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
declare var $: any;

/**
 * Component for dashboard page
 */
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	private grid: any;

	@Output() gridChangeEvent = new EventEmitter();

	hasLR$ = this.service.hasLR$;
	hasM$ = this.service.hasM$;
	hasFG$ = this.service.hasFG$;
	hasK$ = this.service.hasK$;
	hasI$ = this.service.hasI$;
	isConversionEnabled$ = this.businessConfigQuery.conversionFeature$;
	/**
	 * WidgetCodes from dashboard query
	 */
	widgets$ = this.dashboardQuery.activeWidgets$.pipe(
		withLatestFrom(this.hasLR$, this.hasM$, this.hasK$, this.hasFG$, this.hasI$, this.isConversionEnabled$),
		map(([w, lr, m, k, fg, i, isConversionEnabled]) => {
			const wState = produce(w, draft => {
				if (!lr) {
					draft = R.filter(
						x =>
							x.code !== 'lrgt' &&
							x.code !== 'lrgtfy' &&
							x.code !== 'lra' &&
							x.code !== 'lrct' &&
							x.code !== 'aas' &&
							x.code !== 'aasf' &&
							x.code !== 'qas' &&
							x.code !== 'mas' &&
							x.code !== 'was' &&
							x.code !== 'bb' &&
							x.code !== 'adv' &&
							x.code !== 'avs' &&
							x.code !== 'asry' &&
							x.code !== 'msw'&&
							x.code !== 'ebc' &&
							x.code !== 'lc' &&
							x.code !== 'eblr' &&
							x.code !== 'nblr',
						draft
					);
				}

				if (!m) {
					draft = R.filter(
						x => 
							x.code !== 'rm' && 
							x.code !== 'mgt' && 
							x.code !== 'mmgt' && 
							x.code !== 'mo',
						draft
					);
				}

				if (!k) {
					draft = R.filter(x => x.code !== 'kgt', draft);
				}

				if (!fg) {
					draft = R.filter(x => x.code !== 'bnb', draft);
					draft = R.filter(x => x.code !== 'fggtd', draft);
					draft = R.filter(x => x.code !== 'fggtc', draft);
					draft = R.filter(x => x.code !== 'fgbb', draft);
					draft = R.filter(x => x.code !== 'fgpsc', draft);
					draft = R.filter(x => x.code !== 'fgpsd', draft);
					draft = R.filter(x => x.code !== 'fgpslt', draft);
				}

				if (!lr && !m && !k && !fg && !i) {
					draft = R.filter(x => x.code !== 'p', draft);
				}

				if (!isConversionEnabled) {
					draft = R.filter(x => x.code !== 'nblr', draft);
					draft = R.filter(x => x.code !== 'mo', draft);
					draft = R.filter(x => x.code !== 'eblr', draft);
				}

				return draft;
			});

			return wState;
		})
	);
	/**
	 * Indicator whether there are already advisers chosen
	 */
	hasAdviserFilter$: Observable<boolean> = this.dashboardQuery.hasAdviserFilter$;

	constructor(
		private dashboardQuery: DashboardQuery,
		private dashboardService: DashboardService,
		private service: ClientProfileService,
		private businessConfigQuery: BusinessConfigQuery,
	) { }

	ngOnInit() {}

	ngAfterViewInit() {
		this.initializeGridMuuri();
		const widgetChange$ = this.widgets$.pipe(distinctUntilChanged(R.equals));
		const hasAdviserFilterChange$ = this.hasAdviserFilter$.pipe(distinctUntilChanged(R.equals));
		widgetChange$.pipe(merge(hasAdviserFilterChange$), delay(0), takeUntil(this.onDestroy$)).subscribe(() => {
			this.updateMuuriItems();
			this.grid.refreshItems().layout();
			this.initializeResizableWidget();
		});
	}

	/**
	 * Update widgets
	 * Add items in muuri grid
	 * Remove items that is not active in muuri
	 */
	updateMuuriItems() {
		const muuriElements: HTMLElement[] = this.grid.getItems()?.map(item => item.getElement());

		const existingWidgetItems: HTMLElement[] = Array.from(document.querySelectorAll('.item.widget-item'));

		const gridItemsToAdd = existingWidgetItems?.filter(gridItem => !muuriElements?.some(x => x.id === gridItem.id));

		const muuriItemsToRemove = muuriElements?.filter(muuriItem => !existingWidgetItems?.some(x => x.id === muuriItem.id));

		gridItemsToAdd?.forEach(item => {
			const width = item.getAttribute('data-grid-width');
			const height = item.getAttribute('data-grid-height');
			item.style.width = `${width}px`;
			item.style.height = `${height}px`;
			item.style.overflow = 'visible';

			this.grid.add(item);
		});
		muuriItemsToRemove?.forEach(item => this.grid.remove(item));
	}

	/**
	 * Initialize Gridstack
	 * @return void
	 */
	private initializeGridMuuri() {
		const self = this;
		const docElem = document.documentElement;
		let dragCounter = 0;

		/**
		 * Get all widget sizes
		 */
		const getAllItems = (): Widget[] => {
			return self.grid.getItems()?.map(
				item =>
					({
						code: item.getElement().getAttribute('data-grid-code'),
						sizeX: item.getWidth(),
						sizeY: item.getHeight(),
					} as Widget)
			);
		};

		/**
		 * Initialize muuri with options
		 * You can find the options here: https://github.com/haltu/muuri#grid-options
		 */

		this.grid = new Muuri('.grid.widget-grid', {
			items: '.item.widget-item',
			layout: {
				fillGaps: false,
			},
			layoutDuration: 300,
			layoutEasing: 'ease',
			dragEnabled: true,
			dragStartPredicate: {
				handle: '.widget__drag-handle',
			},
			dragSortHeuristics: {
				sortInterval: 50,
				minDragDistance: 10,
				minBounceBackAngle: 1,
			},
			dragContainer: document.body,
			dragPlaceholder: {
				enabled: true,
				duration: 400,
				createElement(item) {
					return item.getElement().cloneNode(true);
				},
			},
			dragReleaseDuration: 300,
			dragReleseEasing: 'ease',
		})

			/**
			 * Listen when drag start: add dragging class
			 */
			.on('dragStart', () => {
				++dragCounter;
				docElem.classList.add('dragging');
			})

			/**
			 * Listen when drag move
			 */
			.on('dragMove', (item, event) => {
				self.grid.refreshItems().layout();
			})

			/**
			 * Listen when drag end: remove dragging class
			 * Refresh widgets and layout
			 * Emit change has been made for widgets
			 * Save all widgets size and position
			 */
			.on('dragEnd', (item, event) => {
				if (--dragCounter < 1) {
					docElem.classList.remove('dragging');
					self.grid.refreshItems().layout();
				}
			})

			/**
			 * Listen when drag release end
			 * Save all widgets size and position
			 */
			.on('dragReleaseEnd', item => {
				self.grid.refreshItems().layout();
				self.gridChangeEvent.emit(item.getElement().id);
				self.updateAllWidgets(getAllItems());
			});
	}

	/**
	 * Resizable widget
	 */
	initializeResizableWidget() {
		const self = this;

		/**
		 * Get all widget sizes
		 */
		const getAllItems = (): Widget[] => {
			return this.grid.getItems()?.map(
				item =>
					({
						code: item.getElement().getAttribute('data-grid-code'),
						sizeX: item.getWidth(),
						sizeY: item.getHeight(),
					} as Widget)
			);
		};

		const widgets: HTMLElement[] = Array.from(document.querySelectorAll('.item.widget-item'));

		widgets?.forEach(item => {
			const minWidth = +item.getAttribute('data-grid-min-width');
			const minHeight = +item.getAttribute('data-grid-min-height');
			const maxWidth = +item.getAttribute('data-grid-max-width');
			const maxHeight = +item.getAttribute('data-grid-max-height');

			$(item).resizable({
				minWidth,
				minHeight,
				maxWidth: maxWidth === 0 ? null : maxWidth,
				maxHeight: maxHeight === 0 ? null : maxHeight,
				handles: 'e, s, se',

				/**
				 * Listen when resizing widget
				 * @param event
				 * @param ui item info
				 * Refresh widgets and layout
				 */
				resize: (event, ui) => {
					/**
					 * Resize increment by 5
					 */
					ui.size.width = Math.round(ui.size.width / 5) * 5;
					ui.size.height = Math.round(ui.size.height / 5) * 5;

					/**
					 * Get browser size and make as maximum size
					 */
					const bodyWidth = document.body.clientWidth;
					const maxBodyWidth = bodyWidth - 48;
					if (ui.size.width >= maxBodyWidth) {
						ui.size.width = maxBodyWidth;
					}

					ui.element[0].setAttribute('data-grid-width', ui.size.width);
					ui.element[0].setAttribute('data-grid-height', ui.size.height);

					self.grid.refreshItems().layout();
				},

				/**
				 * Listen when resize stop: save the all widgets size
				 * @param event
				 * @param ui item info
				 * Refresh widgets
				 */
				stop: (event, ui) => {
					self.grid.refreshItems().layout();
					self.gridChangeEvent.emit(ui.element[0].id);
					self.updateAllWidgets(getAllItems());
				},
			});
		});
	}

	/**
	 * Update the positions and sizes of the widgets
	 *
	 * @param widgets : List of widgets
	 * @return void
	 */
	updateAllWidgets(widgets: Widget[]) {
		this.dashboardService.updateAllWidgets(widgets).pipe(takeUntil(this.onDestroy$)).subscribe();
	}

	/**
	 * Track widget
	 * @param index array index
	 * @param element widget
	 */
	trackWidget(index: number, element: Widget) {
		return element.code;
	}

	/**
	 * Dispose all subscribstions
	 */
	ngOnDestroy() {
		const widgets: HTMLElement[] = Array.from(document.querySelectorAll('.item.widget-item'));
		widgets.forEach(item => {
			$(item).resizable('destroy');
		});

		if (this.grid) {
			this.grid.destroy();
		}

		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
