import {
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap, debounceTime, take } from 'rxjs/operators';
import { formUtil } from 'src/app/util/form.util';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { numUtil, objectUtil } from '../../../../../../util/util';
import {
	convertAnnualToCustomFreq,
	curFreqType,
} from '../../calculations/annual-conversion';
import {
	computeTotalExpense,
	ExpensesTotals,
} from '../../calculations/monthly-expense';
import { MonthlyExpenseMapper } from '../../mapper/expenses.mapper';
import { FactFindComputationState } from '../../models/factfind-computation.model';
import {
	ExpenseListObjState,
	MonthlyExpenseDetailsState,
} from '../../models/monthly-expense.model';
import { ExpenseStore } from '../../../crt-mortgage/client-sop/expenses/state/expenses.store';

interface KSDisplay {
	incomeSourceName: string;
	contribution: string;
}

@Component({
	selector: 'app-crt-budget-form',
	templateUrl: './crt-budget-form.component.html',
	styleUrls: ['./crt-budget-form.component.scss'],
})
export class CrtBudgetFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Input() monthlyExpense$: Observable<MonthlyExpenseDetailsState>;
	@Input() updateFn$: (
		monthlyExpense
	) => Observable<MonthlyExpenseDetailsState>;
	@Input() addNewFn$: (
		monthlyExpense
	) => Observable<MonthlyExpenseDetailsState>;
	@Input() updateStateFn: (monthlyExpense: MonthlyExpenseDetailsState) => void;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() monthlyAfterTaxIncome: number;
	@Input() mortgagePayments: number;
	@Input() personalDebtValue: number;
	@Input() factFindComputation: FactFindComputationState;
	@Input() peopleList: ViewDisplayValue[];
	@Input() ownerOccupiedRates: number;
	@Input() investmentRates: number;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	cRTId: number;
	adviceProcessId: number;
	isNew = false;
	idExpense = 0;
	totalExpenses = 0;
	showTrueMonthly = false;
	showfalseMonthly = false;
	submitted = false;
	isListLoading = true;
	defaultFrequencyValue = 'Monthly';
	otherExpenseList = new BehaviorSubject<ExpenseListObjState[]>([]);
	factFind: FactFindComputationState = {};

	baseData = {
		SectionCode: 'FIM',
		MortgagePayments: 0,
		MortgagePaymentsFrequency: 'Monthly',
		MortgagePaymentsNotContinuing: false,
		RentNow: 0,
		RentNowNotContinuing: false,
		RentNowFrequency: 'Monthly',
		RentFuture: 0,
		RentFutureNotContinuing: false,
		RentFutureFrequency: 'Monthly',
		Utilities: 0,
		UtilitiesNotContinuing: false,
		UtilitiesFrequency: 'Monthly',
		OwnerOccupiedRates: 0,
		OwnerOccupiedRatesNotContinuing: false,
		OwnerOccupiedRatesFrequency: 'Annually',
		InvestmentRates: 0,
		InvestmentRatesNotContinuing: false,
		InvestmentRatesFrequency: 'Annually',
		PersonalDebt: 0,
		PersonalDebtNotContinuing: false,
		PersonalDebtFrequency: 'Annually',
		HealthMedicalCare: 0,
		HealthMedicalCareNotContinuing: false,
		HealthMedicalCareFrequency: 'Monthly',
		HouseholdContentsInsurance: 0,
		HouseholdContentsInsuranceNotContinuing: false,
		HouseholdContentsInsuranceFrequency: 'Monthly',
		HouseInsurance: 0,
		HouseInsuranceNotContinuing: false,
		HouseInsuranceFrequency: 'Monthly',
		VehicleInsurance: 0,
		VehicleInsuranceNotContinuing: false,
		VehicleInsuranceFrequency: 'Monthly',
		HMFPInsurance: 0,
		HMFPInsuranceNotContinuing: false,
		HMFPInsuranceFrequency: 'Monthly',
		LRInsurance: 0,
		LRInsuranceNotContinuing: false,
		LRInsuranceFrequency: 'Monthly',
		Groceries: 0,
		GroceriesNotContinuing: false,
		GroceriesFrequency: 'Monthly',
		OngoingHouseholdExpenses: 0,
		OngoingHouseholdExpensesNotContinuing: false,
		OngoingHouseholdExpensesFrequency: 'Monthly',
		ChildCare: 0,
		ChildCareNotContinuing: false,
		ChildCareFrequency: 'Monthly',
		ChildSupportMaintenance: 0,
		ChildSupportMaintenanceNotContinuing: false,
		ChildSupportMaintenanceFrequency: 'Monthly',
		PersonalCareClothing: 0,
		PersonalCareClothingNotContinuing: false,
		PersonalCareClothingFrequency: 'Monthly',
		PublicEducation: 0,
		PublicEducationNotContinuing: false,
		PublicEducationFrequency: 'Monthly',
		PrivateEducation: 0,
		PrivateEducationNotContinuing: false,
		PrivateEducationFrequency: 'Monthly',
		Transport: 0,
		TransportNotContinuing: false,
		TransportFrequency: 'Monthly',
		KiwiSaverContribution: 0,
		KiwiSaverContributionNotContinuing: false,
		KiwiSaverContributionFrequency: 'Monthly',
		EntertainmentRecreation: 0,
		EntertainmentRecreationNotContinuing: false,
		EntertainmentRecreationFrequency: 'Monthly',
		Tithing: 0,
		TithingNotContinuing: false,
		TithingFrequency: 'Monthly',
		ExpenseList: [],
		TotalExpenses: 0,
		BodyCorporate: 0,
		BodyCorporateNotContinuing: false,
		BodyCorporateFrequency: 'Monthly',
	};

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private expenseStore: ExpenseStore
	) {
		this.buildForm();
	}

	buildForm() {
		const data = MonthlyExpenseMapper.mapToView({
			...objectUtil.mapPascalCaseToCamelCase(this.baseData),
			adviceProcessId: +this.adviceProcessId,
			completed: 'Yes',
			totalMinusDiscontinued: 0,
			monthlyIncomeAfterExpenses: 0,
		});
		this.form = formUtil.createFormGroup(this.fb, data);

		this.form
			.valueChanges
			.pipe(
				debounceTime(200),
				takeUntil(this.onDestroy$)
			)
			.subscribe(formValue => this.expenseStore.setFormValue(formValue));
	}

	get expenseList() {
		return this.form.get('expenseList') as UntypedFormArray;
	}
	get TotalExpenses() {
		return this.form.get('totalExpenses');
	}
	get TotalMinusDiscontinued() {
		return this.form.get('totalMinusDiscontinued');
	}

	get MonthlyIncomeAfterExpenses() {
		return this.form.get('monthlyIncomeAfterExpenses');
	}

	get MortgagePayments() {
		return this.form.get('mortgagePayments');
	}
	get PersonalDebt() {
		return this.form.get('personalDebt');
	}
	get Completed() {
		return this.form.get('completed');
	}

	get OwnerOccupiedRates() {
		return this.form.get('ownerOccupiedRates');
	}

	get InvestmentRates() {
		return this.form.get('investmentRates');
	}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.Completed?.disable();
		this.adviceProcessId = +this.route.snapshot.paramMap.get('adviceProcessId');
		this.monthlyExpense$
			.pipe(
				tap(() => (this.isListLoading = true)),
				filter((data) => !!data),
				tap((x) => {
					let data = MonthlyExpenseMapper.mapToView({
						...objectUtil.mapPascalCaseToCamelCase(x),
						adviceProcessId: +this.adviceProcessId,
						completed: 'Yes',
					});
					this.cRTId = x?.cRTId;
					/**
					 * @param savedValue - saved value from the database
					 * @param inputValue - value from input binding
					 * @param frequency - calculation frequency
					 */
					const getValueOrConvertValue = (
						savedValue: number,
						inputValue: number,
						frequency: string
					) =>
						!!this.cRTId
							? savedValue
							: this.convertValueForSavedFrequency(inputValue, frequency);
					data = {
						...data,
						mortgagePayments: getValueOrConvertValue(
							data.mortgagePayments,
							this.mortgagePayments,
							data.mortgagePaymentsFrequency
						),
						personalDebt: getValueOrConvertValue(
							data.personalDebt,
							this.personalDebtValue,
							data.personalDebtFrequency
						),
						investmentRates: getValueOrConvertValue(
							data.investmentRates,
							this.investmentRates,
							data.investmentRatesFrequency
						),
						ownerOccupiedRates: getValueOrConvertValue(
							data.ownerOccupiedRates,
							this.ownerOccupiedRates,
							data.ownerOccupiedRatesFrequency
						),
					};
					this.form.reset(data);
				}),
				tap(() => this.selectChange(true)),
				tap(() => this.Completed?.enable()),
				tap((x) => {
					if (x?.cRTId) {
						this.expenseList.clear();
						if (x?.expenseList && Array.isArray(x?.expenseList)) {
							x?.expenseList?.map((expense) => this.addExpenseItem(expense));
						}
					}
					// @@TODO : Need to remove completed field
					if (this.Completed?.value === 'Yes') {
						this.recompute(true);
					}
				}),
				tap(() => (this.isListLoading = false)),
				take(1)
			)
			.subscribe();
	}

	addExpenseItem(data?: ExpenseListObjState) {
		this.idExpense++;
		this.expenseList.push(
			this.fb.group({
				expenseField: [(data && data.expenseField) || ''],
				expenseValue: [(data && data.expenseValue) || ''],
				expenseFrequency: [
					data?.expenseFrequency ?? this.defaultFrequencyValue,
				],
				expenseNotContinuing: [(data && data.expenseNotContinuing) || false],
			})
		);
		if (!data) {
			this.form.markAllAsTouched();
			this.recompute();
		}
	}

	deleteExpenseItem(index) {
		this.expenseList?.removeAt(index);
		this.recompute();
	}

	selectChange(isInit?) {
		const value = this.Completed?.value;
		if (value === 'Yes') {
			this.showTrueMonthly = true;
			this.showfalseMonthly = false;
			if (!this.cRTId) {
				if (this.expenseList.length === 0) {
					this.addExpenseItem();
				}
			}
			this.recompute(isInit);
		} else if (value === 'No') {
			this.showfalseMonthly = true;
			this.showTrueMonthly = false;
		} else if (value === '') {
			this.showfalseMonthly = false;
			this.showTrueMonthly = false;
		}
		this.submitted = true;
	}

	recompute(isInit: boolean = false) {
		const rawValue = this.form.getRawValue()
		const totals: ExpensesTotals = computeTotalExpense(rawValue);
		const monthlyIncomeAfterExpesnes =
			this.factFindComputation.monthlyAfterTaxIncome - totals.monthlyTotal;
		this.MonthlyIncomeAfterExpenses?.setValue(monthlyIncomeAfterExpesnes);
		this.TotalExpenses.setValue(totals.monthlyTotal);
		this.TotalMinusDiscontinued.setValue(totals.totalMinusDiscontinued);
	}

	onChange() {
		this.submitted = true;
		const formValue = this.form.getRawValue();
		formValue.expenseList = (
			formValue.expenseList as Array<ExpenseListObjState>
		)?.map((x) => ({
			...x,
			expenseValue: R.complement(R.either(R.isNil, R.isEmpty))(x?.expenseValue)
				? x?.expenseValue
				: 0,
			expenseNotContinuing: R.complement(R.either(R.isNil, R.isEmpty))(
				x?.expenseNotContinuing
			)
				? x?.expenseNotContinuing
				: false,
		}));

		delete formValue.incomeSourceList;

		const data = MonthlyExpenseMapper.mapToUpsert(
			formValue,
			+this.adviceProcessId,
			+this.cRTId
		);
		data.isInvalid = !this.form.valid;
	}

	saveMonthlyExpenses() {
		this.submitted = true;
		const formValue = this.form.getRawValue();
		formValue.expenseList = (
			formValue.expenseList as Array<ExpenseListObjState>
		)?.map((x) => ({
			...x,
			expenseValue: R.complement(R.either(R.isNil, R.isEmpty))(x?.expenseValue)
				? x?.expenseValue
				: 0,
			expenseNotContinuing: R.complement(R.either(R.isNil, R.isEmpty))(
				x?.expenseNotContinuing
			)
				? x?.expenseNotContinuing
				: false,
		}));

		delete formValue.incomeSourceList;
		delete formValue.totalMinusDiscontinued;

		const data = MonthlyExpenseMapper.mapToUpsert(
			formValue,
			+this.adviceProcessId,
			+this.cRTId
		);

		data.isInvalid = !this.form.valid;
		return data;
	}

	convertValueForSavedFrequency(value: number, frequency: string) {
		if (frequency === 'Annually') {
			return +numUtil.formatToNumCurrency(value);
		} else {
			return +numUtil.formatToNumCurrency(
				convertAnnualToCustomFreq(value, frequency as curFreqType)
			);
		}
	}

	recalcRates(originalValue: number, field: string) {
		const fieldFreq = this.form.get(`${field}Frequency`).value?.toString();
		if (fieldFreq) {
			this.form
				.get(field)
				?.setValue(
					convertAnnualToCustomFreq(originalValue, fieldFreq as curFreqType)
				);
		}
	}

	onChangeCompute() {
		this.submitted = true;
		this.recompute();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
