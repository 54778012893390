import { Component, OnInit, Input, EventEmitter, Output, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
	SecondaryProfessionalState
} from '../../../../../shared/models/client-profile/secondary-professional/secondary-professional.model';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { strUtil } from '../../../../../util/util';
import {
	ClientProfileSecondaryProfessionalMapper as SecondaryProfessionalMapper
} from '../../../../../shared/models/client-profile/secondary-professional/secondary-professional.mapper';
import { Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { DeleteModalComponent } from '../../../../../shared/modal/delete-modal/delete-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { NoteState } from '../../../../../shared/models/notes/note.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { tap, takeUntil, take } from 'rxjs/operators';
import { TablePaginateNotesComponent } from '../../../../../shared/table-paginate-notes/table-paginate-notes.component';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ClientProfileService } from '../../states/client-profile.service';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { Fields, getRequiredWarning } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-form-professional',
	templateUrl: './form-professional.component.html',
	styleUrls: ['./form-professional.component.scss'],
})
export class FormProfessionalComponent implements OnInit, OnChanges, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() formID: any;
	@Input() isLead: boolean;
	@Input() addMode: boolean;
	@Input() disableDelete: boolean;
	@Input() secondaryProfessional: SecondaryProfessionalState;
	@Input() addFn$: (scp: SecondaryProfessionalState) => Observable<any>;
	@Input() updateFn$: (scp: SecondaryProfessionalState) => Observable<any>;
	@Input() deleteFn$: (scp: SecondaryProfessionalState) => Observable<any>;
	// Notes
	@Input() getNotesFn$: (req: any) => Observable<any>;
	@Input() addNotesFn$: (req: any) => Observable<any>;
	@Input() deleteNotesFn$: (req: any) => Observable<any>;

	private _isSaving = false;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	isSaving$ = new ReplaySubject<boolean>(1);
	private _isEdit = false;
	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	isEdit$ = new ReplaySubject<boolean>(1);

	@Input() professionalTypes: ViewDisplayValue[];

	@Output() cancelEvent = new EventEmitter<boolean>();

	form: UntypedFormGroup;

	notes: { notes: NoteState[]; count: number };

	@ViewChild(TablePaginateNotesComponent) noteTable: TablePaginateNotesComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private profile: ClientProfileService) {
		this.createForm();
	}

	get name() {
		return this.form.get('name');
	}

	get professionalType() {
		return this.form.get('professionalType');
	}

	ngOnInit() {
		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$.pipe(takeUntil(this.onDestroy$)).subscribe(x => (this._isSaving = x));
	}

	ngOnChanges() {
		if (this.addMode) {
			this.isEdit$.next(true);
			this.secondaryProfessional = undefined;
			this.form.reset();
			this.patchDefaultValues();
		} else {
			const data = this.secondaryProfessional
				? Object.assign(new SecondaryProfessionalMapper(), this.secondaryProfessional)
				: this.form.value;
			this.form.reset(data);
		}
	}

	addNotes$ = note => {
		return this.addNotesFn$({
			customerID: note.customerID,
			customerServiceID: note.customerServiceID,
			notes: note.notes,
		});
	};

	deleteNotes$ = (note: NoteState) => {
		return this.deleteNotesFn$(note);
	};

	getNotes$ = (req: GetNotes) => {
		return this.getNotesFn$(req).pipe(
			tap(x => {
				this.notes = x;
			})
		);
	};

	createForm() {
		this.form = this.fb.group({
			name: this.fb.control(''),
			businessName: this.fb.control(''),
			professionalType: this.fb.control('', [Validators.required]),
			phone: this.fb.control(''),
			email: this.fb.control(''),
			physicalAddress: this.fb.control(''),
			note: this.fb.control(''),
		} as { [key in keyof SecondaryProfessionalState]: any });
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	prepareValue() {
		const data = this.form.value;
		return {
			...data,
			name: strUtil.safeTrimExtraSpace(data.name),
			businessName: strUtil.safeTrimExtraSpace(data.businessName),
			phone: strUtil.removeSpace(data.phone),
			email: strUtil.removeSpace(data.email),
			physicalAddress: strUtil.safeTrimExtraSpace(data.physicalAddress),
			customerType: CustomerTypes.SecondaryCustomerProfessional,
		};
	}

	patchDefaultValues() {
		if (this.addMode) {
			const findDefault = R.find<ViewDisplayValue>(x => x.isDefault);
			const getValue = (v: ViewDisplayValue): string => R.propOr('', 'value', v);
			const mapToDefaultValues = R.pipe(R.map(findDefault), R.map(getValue));
			const defaults = mapToDefaultValues([this.professionalTypes]);
			this.setDropdownDefaults(defaults);
		}
	}

	private setDropdownDefaults = ([professionalType]: string[]): void => {
		// tslint:disable-next-line: no-use-before-declare
		patchValue<SecondaryProfessionalState>(this.form, {
			professionalType,
		});
	};

	edit() {
		this.isEdit$.next(true);
	}
	save() {
		if (!this.form.valid || this.prepareValue().name?.trim() === '') {
			if (!this.prepareValue().professionalType) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.ProfessionalType));
				return;
			}
		}
		this.isSaving$.next(true);
		this.isEdit$.next(false);
		if (this.addMode) {
			const data = this.prepareValue();
				this.addFn$(data)
					.pipe(take(1))
					.subscribe(
						res => {
							this.isEdit$.next(true);
							this.isSaving$.next(false);
						},
						err => {
							this.isEdit$.next(true);
							this.isSaving$.next(false);
						}
					);
		} else {
			const data = { ...this.secondaryProfessional, ...this.prepareValue() };
				this.updateFn$(data)
					.pipe(take(1))
					.subscribe(
						res => {
							this.isEdit$.next(false);
						},
						err => {
							this.isEdit$.next(true);
						},
						() => {
							this.isSaving$.next(false);
						}
					);
		}
	}
	confirmDelete() {
		const data = {
			...SecondaryProfessionalMapper.mapToUpsert(this.secondaryProfessional),
		};

		this.profile
			.clients(+data.customerID).pipe(
				tap(x => {
					const message = [];
					if (x) {
						if (x?.pci) {
							message.push(`- ${x.pci.firstName + ' ' + x.pci.lastName} <br />`);
						}
						x.sci?.forEach(s => {
							message.push(` - ${s.firstName + ' ' + s.lastName} <br />`);
						});
					}

					const confirm = new Observable((obs: Observer<any>) => {
						this.delete(data);
						obs.complete();
					});
					const initState = {
						header: 'Delete Professional Contact',
						message: x
							? `'${data.name}' is a a professional contact of: <br /> ${message
								?.toString()
								?.replace(/,/g, ' ')} Kindly remove ${message && message.length > 1 ? 'these' : 'this'
							} before deleting.`
							: `Are you sure you want to delete '${data.name}'?`,
						delete$: confirm,
						canDelete: x ? false : true,
					};

					this.modalService.show(DeleteModalComponent, {
						class: 'modal-dialog-centered',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false
					});
				}),
				take(1)
			).subscribe();
	}
	delete(scp: SecondaryProfessionalState) {
		this.toggleSaving(true);
		this.deleteFn$(scp)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				res => {
					this.toggleEdit(false);
				},
				err => {
					this.toggleEdit(true);
				},
				() => {
					this.toggleSaving(false);
				}
			);
	}
	cancel() {
		const data = this.secondaryProfessional
			? Object.assign(new SecondaryProfessionalMapper(), this.secondaryProfessional)
			: this.form.value;
		this.form.reset(data);

		if (!this.addMode) {
			this.isEdit$.next(false);
		}
		this.cancelEvent.emit(true);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

const patchValue: <T>(form: UntypedFormGroup, partialViewModel: Partial<T>) => void = (form, partialViewModel) => {
	const FormValueToPatch = Object.keys(partialViewModel)?.reduce((acc, v) => {
		const notEmpty = !['', null, undefined]?.some(x => x === v);
		if (notEmpty) {
			const prop = { [v]: partialViewModel[v] };
			Object.assign(acc, prop);
		}
		return acc;
	}, {});
	form.patchValue(FormValueToPatch);
};
