<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.Application"></app-header>
</div>

<!-- Content -->
<div role="main-content">
	<app-manual-loader
		*ngIf="isLoading$ | async as isLoading"
		[isLoading]="isLoading"
	></app-manual-loader>
	<ng-container *ngIf="!(isLoading$ | async)">
		<router-outlet></router-outlet>
	</ng-container>
</div>

<!-- Footer -->
<div
	*ngIf="showFooter"
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer
		[currentPageId]="pageIds.Application"
		[sidebar]="sidebar"
	></app-footer>
</div>