import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Loan, LoanStructure } from './loan.model';

export interface LoanState extends EntityState<Loan> {
	loanStructure?: LoanStructure;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'loan', idKey: 'cRTId' })
export class LoanStore extends EntityStore<LoanState> {
	constructor() {
		super({
			loanStructure: null,
		});
	}
}
