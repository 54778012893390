<form
	[formGroup]="form"
	[class.submitted]="submitted"
	[class.moat-v2]="isMoatV2"
	class="clearfix"
	novalidate
>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-3 mb-2" >
			<span class="w-100" [class.crt-custom-dropdown]="isEdit$ | async" 
			[tooltip]="!(isEdit$ | async) ? getTooltipValueIncomeEarner(form.get('incomeEarner')?.value) : ''">
				<select
					id="incomeEarner"
					formControlName="incomeEarner"
					class="form-control crt-form-control theme-crm-field-disabled"
					[tooltip]="toolTipDisplayIncomeEarner"
					data-toggle="tooltip"
					container="body"
				>
					<option value=""></option>
					<!-- <option *ngFor="let p of peopleList" [value]="p.value">
						{{ p.display }}
					</option> -->
					<option
						*ngFor="
							let p of peopleList;
							let index = i;
							trackBy: trackByFn
						"
						[value]="p.value"
						[selected]="p.value == form.get('incomeEarner')?.value
					">
						{{ p.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-3 mb-2" [tooltip]="getTooltipValue(form.get('previousOccupation'))">
			<input
				id="previousOccupation_{{ formId }}"
				class="form-control crt-form-control"
				placeholder="Previous Occupation"
				formControlName="previousOccupation"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-3 mb-2" [tooltip]="getTooltipValue(form.get('employer'))">
			<input
				id="employer_{{ formId }}"
				class="form-control crt-form-control"
				placeholder="Employer"
				formControlName="employer"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-2 mb-2" [tooltip]="getTooltipValue(form.get('periodOfEmployment'))">
				<input
					id="periodOfEmployment_{{ formId }}"
					class="form-control crt-form-control"
					placeholder="Period of Employment"
					formControlName="periodOfEmployment"
					type="text"
				/>
			</div>
		<div class="col-lg-1 order-first text-right order-lg-last">
			<ng-container *ngIf="isSaving$ | async as isSaving; else buttons">
				<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
			</ng-container>
			<ng-template #buttons>
				<ng-container *ngIf="!(isEdit$ | async)">
					<button
						id="previousIncomeSourceEditButton_{{ formId }}"
						(click)="edit()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="disableUpdate"
					>
						<i class="material-icons md-16">edit</i>
					</button>
					<button
						id="previousIncomeSourceDeleteButton_{{ formId }}"
						(click)="deleteIncome()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="disableUpdate"
					>
						<i class="material-icons md-16">delete</i>
					</button>
				</ng-container>
				<ng-container *ngIf="isEdit$ | async">
					<button
						id="previousIncomeSourceSaveButton_{{ form }}"
						(click)="save()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="form.invalid"
					>
						<i class="material-icons md-16 tap-text-primary">save</i>
					</button>
					<button
						id="previousIncomeSourceCancelButton_{{ form }}"
						(click)="cancel()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
					>
						<i class="material-icons md-16">close</i>
					</button>
				</ng-container>
			</ng-template>
		</div>
	</div>
</form>
