import { StructureSoaComponent } from './structure-soa/structure-soa.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	AdviceProcessPageCodes,
	MOATProcessRoutes,
} from '../../../../shared/models/advice-process/advice-process.model';
import { ApplicationListComponent } from './application/application-list/application-list.component';
import { ApplicationStepsComponent } from './application/application-steps/application-steps.component';
import { ApplicationComponent } from './application/application.component';
import { AssetsAndLiabilitiesComponent } from './client-sop/assets-and-liabilities/assets-and-liabilities.component';
import { ExpensesComponent } from './client-sop/expenses/expenses.component';
import { IncomeComponent } from './client-sop/income/income.component';
import { PeopleEntitiesComponent } from './client-sop/people-entities/people-entities.component';
import { CrtMortgageResolver } from './crt-mortgage-resolver';
import { CrtMortgageComponent } from './crt-mortgage.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { IntroComponent } from './intro/intro.component';
import { ScopeOfServicesComponent } from './scope-of-services/scope-of-services.component';

import { CrtMortgageCanDeactivateGuard } from './crt-mortgage-can-deactivate.guard';
import { CrtMortgageApplicationStepsCanDeactivateGuard } from './application/application-steps/crt-mortgage-application-steps-can-deactivate.guard';
import { StructureSOACanDeactivateGuard } from './structure-soa/structure-soa-can-deactivate.guard';
import { CrtMortgageApplicationStepsCanActivateGuard } from './application/application-steps/crt-mortgage-application-steps-can-activate.guard';

const routes: Routes = [
	{
		path: ':customerType/:clientId/advice-process/:adviceProcessId',
		component: CrtMortgageComponent,
		data: {
			breadcrumb: 'Mortgage Online Application Template',
		},
		resolve: {
			data: CrtMortgageResolver,
		},
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: MOATProcessRoutes.Introduction,
			},
			{
				path: MOATProcessRoutes.Introduction,
				component: IntroComponent,
				data: {
					type: AdviceProcessPageCodes.Introduction,
				},
			},
			{
				path: MOATProcessRoutes.Disclosure,
				component: DisclosureComponent,
				data: {
					type: AdviceProcessPageCodes.Disclosure,
				},
			},
			{
				path: MOATProcessRoutes.ScopeOfService,
				component: ScopeOfServicesComponent,
				data: {
					type: AdviceProcessPageCodes.SOS,
				},
				canDeactivate: [CrtMortgageCanDeactivateGuard],
			},
			{
				path: MOATProcessRoutes.People,
				component: PeopleEntitiesComponent,
				data: {
					type: AdviceProcessPageCodes.MoatPeople,
				},
			},
			{
				path: MOATProcessRoutes.AssetsLiabilities,
				component: AssetsAndLiabilitiesComponent,
				data: {
					type: AdviceProcessPageCodes.AssetsLiabilities,
				},
				canDeactivate: [CrtMortgageCanDeactivateGuard],
			},
			{
				path: MOATProcessRoutes.Income,
				component: IncomeComponent,
				data: {
					type: AdviceProcessPageCodes.Income,
				},
			},
			{
				path: MOATProcessRoutes.Expenses,
				component: ExpensesComponent,
				data: {
					type: AdviceProcessPageCodes.Expenses,
				},
				canDeactivate: [CrtMortgageCanDeactivateGuard],
			},
			{
				path: MOATProcessRoutes.Declaration,
				component: DeclarationComponent,
				data: {
					type: AdviceProcessPageCodes.Declaration,
				},
			},
			{
				path: MOATProcessRoutes.Application,
				component: ApplicationComponent,
				data: {
					type: AdviceProcessPageCodes.Application,
				},
				children: [
					{
						path: '',
						component: ApplicationListComponent,
						data: {
							type: AdviceProcessPageCodes.Application,
						},
					},
					{
						path: ':applicationId',
						component: ApplicationStepsComponent,
						data: {
							type: AdviceProcessPageCodes.Application,
						},
						canActivate: [CrtMortgageApplicationStepsCanActivateGuard],
						canDeactivate: [CrtMortgageApplicationStepsCanDeactivateGuard],
					},
				],
			},
			{
				path: MOATProcessRoutes.ROA,
				component: StructureSoaComponent,
				data: {
					type: AdviceProcessPageCodes.ROA,
				},
				canDeactivate: [StructureSOACanDeactivateGuard]
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CrtMortgageRoutingModule {}
