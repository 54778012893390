import { Injectable } from '@angular/core';
import { clone } from 'ramda';
import { EMPTY, Observable, of } from 'rxjs';
import {
	catchError,
	map,
	mergeMap,
	take,
	withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { getEmailContentNoHtml } from '../../../../../shared/converter/content-email';
import {
	getContentWithMergeTags,
	removeMtWrappers,
	normalizeHTMLSymbols,
} from '../../../../../shared/converter/content-merge-tags';
import { EmailLinksMT } from '../../../../../shared/models/client-review-template/merge-tags/email-settings/email-links';
import { MergeTagsMapper } from '../../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { convertUtil, objectUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { CrtMergeTagsService } from '../merge-tags/crt-merge-tags.service';

@Injectable()
export class EmailService extends ClientReviewTemplateService {
	disclosureDocument$ = this.query.disclosureDocument$;
	mergeTags$ = this.crtMergeTagsService.mergeTags$;

	private endpoint = 'emails';

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		private crtMergeTagsService: CrtMergeTagsService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getBodyContent(content) {
		return of(content).pipe(
			mergeMap((x) => convertUtil.convertToBase64(x)),
			map((x) => ({ HTMLBody: x, StringBody: getEmailContentNoHtml(content) })),
			take(1)
		);
	}

	sendEmail(data, type, referenceId) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);

		const body: any = {
			EmailFrom: d?.EmailFrom,
			EmailDestination: d.EmailDestination,
			EmailCC: d?.EmailCC,
			EmailBCC: d?.EmailBCC,
			EmailSubject: d?.EmailSubject,
			Type: type,
			ReferenceId: referenceId,
			HTMLBody: d.HTMLBody,
			Attachment: d.Attachments,
			CombinedAttachment: d?.CombinedAttachments || [],
			StringBody: d?.StringBody || '',
		};
		if (d.EmailTemplateName) {
			body.EmailTemplateName = d?.EmailTemplateName;
		}
		return this.api.post(`${this.endpoint}/single`, body);
	}

	sendToRecipients = (data, type, crtId, mergeTags$) => {
		const customMTags = [
			{
				metaKey: 'PEOPLE_NAME',
				description: 'People Name',
				value: data?.emailDestination[0]?.Name,
				type: 'T',
				secondaryValue: data?.emailDestination[0]?.Name,
			},
			{
				metaKey: 'PEOPLE_FIRST_NAME',
				description: 'People First Name',
				value: data?.emailDestination[0]?.FirstName,
				type: 'T',
				secondaryValue: data?.emailDestination[0]?.FirstName,
			},
		];

		return new Observable<{
			emailDropdown: string;
			emailRecipient: string;
			bodyContent: string;
			emailSubject: string;
		}>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			withLatestFrom(mergeTags$),
			map(([, mergeTags]) =>
				MergeTagsMapper.updateEmailMergeTags(clone(mergeTags), [
					...clone(customMTags),
					...clone(EmailLinksMT),
				])
			),
			map((mergeTags) => {
				return {
					bodyContent: getContentWithMergeTags(data?.bodyContent, mergeTags),
					emailSubject: getContentWithMergeTags(data?.emailSubject, mergeTags),
				};
			}),
			map((emailData) => {
				return {
					bodyContent: removeMtWrappers(emailData.bodyContent),
					emailSubject: removeMtWrappers(emailData.emailSubject),
				};
			}),
			map((emailData) => {
				const emailSubject = normalizeHTMLSymbols(emailData.emailSubject);
				return  {
					...emailData,
					// remove all non breaking spaces in subject
					// @ts-ignore-next
					emailSubject: emailSubject?.replaceAll('&nbsp;', ' ') ?? '',
				}
			}),
			mergeMap((emailData) => {
				return this.getBodyContent(emailData.bodyContent).pipe(
					map((bodyContentResult) => {
						return {
							bodyContent: bodyContentResult,
							emailSubject: emailData.emailSubject,
						};
					})
				);
			}),
			map((emailData) => {
				return {
					...data,
					emailSubject: emailData.emailSubject,
					...emailData.bodyContent,
				};
			}),
			mergeMap((payload) =>
				this.sendEmail(payload, type, crtId).pipe(
					map(() => {
						let emailRecipients =
							data?.emailDestination?.map((i) => i?.Email) || [];
						emailRecipients = emailRecipients?.filter(Boolean)?.join(', ');
						return emailRecipients;
					}),
					catchError((err) => {
						return EMPTY;
					})
				)
			)
		);
	};
}
