<h2 class="title py-3 pl-4 text-white">
	<span> Confirmation Call & Send to Issue Request </span>
	<button
		type="button"
		aria-label="Close"
		class="close mr-4 text-white border"
		(click)="cancel()"
	>
		<span class="material-icons md-16">close</span>
	</button>
</h2>
<form [formGroup]="formGroup" class="py-3 px-4">
	<div class="row mx-0">
		<div class="col-12">
			<label for="type" class="font-weight-bold">Type of Issue</label>
		</div>
		<div class="col-12 mt-3">
			<app-input-radio-group
				formControlName="type"
				[items]="typeOfIssues"
				displayProperty="text"
				valueProperty="value"
				[radioGap]="35"
			>
			</app-input-radio-group>
		</div>
	</div>

	<div class="row mx-0 mt-4">
		<div class="col-12">
			<label for="provider" class="font-weight-bold">Provider</label>
		</div>
		<div class="col-12 mt-3">
			<app-input-chips
				#providerChips
				id="provider"
				(onAdded)="onProviderAdded($event)"
				(onRemoved)="onProviderRemoved($event)"
				[items$]="LRP$"
				valueProperty="value"
				displayProperty="display"
				formControlName="providers"
				appearance="crt"
			></app-input-chips>
		</div>
	</div>

	<ng-container formArrayName="providerSection">
		<div
			class="row mx-0 mt-4"
			*ngFor="
				let item of providerSection.controls;
				let index = index
			"
			[formGroupName]="index"
		>
			<div class="col-12">
				<label
					for="typeOfIssue"
					class="font-weight-bold w-100 border-bottom pb-2"
					>{{ item.value.provider }}</label
				>
			</div>
			<div class="col-12 mt-2">
				<div class="row mx-0">
					<div class="col-12 col-lg-4 pl-0">
						<label class="font-weight-bold w-100 border-bottom pb-2 w-100"
							>Send to Issue Date</label
						>
						<app-input-date formControlName="sendToIssueDate"></app-input-date>
					</div>
					<div class="col-12 col-lg-4 px-0">
						<label class="font-weight-bold w-100 border-bottom pb-2 w-100"
							>Start Date</label
						>
						<app-input-date formControlName="startDate"></app-input-date>
					</div>
					<div class="col-12 col-lg-4 pr-0">
						<label class="font-weight-bold w-100 border-bottom pb-2 w-100"
							>First Payment Date</label
						>
						<app-input-date formControlName="firstPaymentDate"></app-input-date>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="row mx-0 mt-4">
		<div class="col-12">
			<label class="font-weight-bold">Frequency</label>
		</div>
		<div class="col-12 mt-3">
			<app-input-select
				appearance="crt"
				[items]="APCRTF$ | async"
				valueProperty="value"
				displayProperty="display"
				formControlName="frequency"
			>
			</app-input-select>
		</div>
	</div>

	<div class="row mx-0 mt-4">
		<div class="col-12 col-lg-6 pl-0">
			<div class="row mx-0">
				<div class="col-12">
					<label class="font-weight-bold">Client to Call</label>
				</div>
				<div class="col-12 mt-3 pr-0">
					<app-input-select
						formControlName="clientToCall"
						appearance="crt"
						[items]="clientsInvolved$ | async"
						valueProperty="value"
						displayProperty="text"
						(valueChange)="onClientToCallValueChanged($event)"
					>
					</app-input-select>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-6 pr-0">
			<div class="row mx-0">
				<div class="col-12">
					<label class="font-weight-bold">Mobile</label>
				</div>
				<div class="col-12 mt-3 pl-0">
					<app-input appearance="crt" placeholder=" " formControlName="mobile">
					</app-input>
				</div>
			</div>
		</div>
	</div>

	<div class="row mx-0 mt-4">
		<div class="col-12 mx-0">
			<textarea
				formControlName="notes"
				class="crt-form-control form-control"
				cols="30"
				rows="5"
			></textarea>
		</div>
	</div>

	<div class="row mx-0 mb-4 mt-4">
		<div class="col-12 send-issue-buttons-bottom">
			<button
				[disabled]="isSending"
				class="btn btn-primary bg-light text-dark border"
				(click)="cancel()"
			>
				Cancel
			</button>
			<button
				[disabled]="isSending"
				class="btn btn-primary tap-bg-primary text-light ml-2"
				(click)="sendRequest()"
			>
				Request Confirmation Call
			</button>
		</div>
	</div>
</form>
