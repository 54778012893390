import { LeadSearchRequest } from './lead-search-request.model';

// This returns an object
export const comparable = o =>
	typeof o !== 'object' || !o
		? o
		: Object.keys(o)
				?.sort()
				?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (req: LeadSearchRequest, saveReq: LeadSearchRequest) => {
	let r = true;

	if (!req || !saveReq) {
		return (r = false);
	}

	if (JSON.stringify(comparable(req.AdviserStatuses)) !== JSON.stringify(comparable(saveReq.AdviserStatuses))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.Advisers)) !== JSON.stringify(comparable(saveReq.Advisers))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.FirstName)) !== JSON.stringify(comparable(saveReq.FirstName))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.LastName)) !== JSON.stringify(comparable(saveReq.LastName))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.LeadOrigins)) !== JSON.stringify(comparable(saveReq.LeadOrigins))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.LeadStatuses)) !== JSON.stringify(comparable(saveReq.LeadStatuses))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.LeadType)) !== JSON.stringify(comparable(saveReq.LeadType))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.LeadGens)) !== JSON.stringify(comparable(saveReq.LeadGens))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.NextActivityTypes)) !== JSON.stringify(comparable(saveReq.NextActivityTypes))) {
		r = false;
	}
	if (
		JSON.stringify(comparable(req.NextActivityAssignedTo)) !==
		JSON.stringify(comparable(saveReq.NextActivityAssignedTo))
	) {
		r = false;
	}
	if (JSON.stringify(comparable(req.NextActivityDateMin)) !== JSON.stringify(comparable(saveReq.NextActivityDateMin))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.NextActivityDateMax)) !== JSON.stringify(comparable(saveReq.NextActivityDateMax))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.CreatedDateMin)) !== JSON.stringify(comparable(saveReq.CreatedDateMin))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.CreatedDateMax)) !== JSON.stringify(comparable(saveReq.CreatedDateMax))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.AltAdvisers)) !== JSON.stringify(comparable(saveReq.AltAdvisers))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.ContactTypes)) !== JSON.stringify(comparable(saveReq.ContactTypes))) {
		r = false;
	}

	return r;
};

export const request = (filter: any, form: LeadSearchRequest, index: number) => {
	const req = {
		AdviserStatuses: form.AdviserStatuses,
		Advisers: form.Advisers,
		FirstName: form.FirstName,
		LastName: form.LastName,
		LeadOrigins: form.LeadOrigins,
		LeadStatuses: form.LeadStatuses,
		LeadType: form.LeadType,
		LeadGens: form.LeadGens,
		NextActivityTypes: form.NextActivityTypes,
		NextActivityAssignedTo: form.NextActivityAssignedTo,
		NextActivityDateMin: form.NextActivityDateMin,
		NextActivityDateMax: form.NextActivityDateMax,
		CreatedDateMin: form.CreatedDateMin,
		CreatedDateMax: form.CreatedDateMax,
		LastAssignedDateMin: form.LastAssignedDateMin,
		LastAssignedDateMax: form.LastAssignedDateMax,
		LastInteractionDateMin: form.LastInteractionDateMin,
		LastInteractionDateMax: form.LastInteractionDateMax,
		AltAdvisers: form.AltAdvisers,
		ContactTypes: form.ContactTypes,

		Paging: {
			Index: index,
			Column: filter ? filter.Column : null,
			Direction: filter ? filter.Direction : null,
		},
	};
	return req;
};
