import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DashboardState, DashboardStore } from './dashboard.store';
import { Observable } from 'rxjs';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { UserQuery } from '../../../../domain/user/user.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';

const allWidgets: { widgetCode: string; widgetName: string }[] = [
	{ widgetCode: 'p', widgetName: 'Pipelines' },
	{ widgetCode: 'rm', widgetName: 'Refix Mortgage' },
	{ widgetCode: 'lrgt', widgetName: 'L&R Goal Track - CY' },
	{ widgetCode: 'lrgtfy', widgetName: 'L&R Goal Track - FY' },
	{ widgetCode: 'kgt', widgetName: 'KiwiSaver Goal Track' },
	{ widgetCode: 'mgt', widgetName: 'Mortgage Goal Track' },
	{ widgetCode: 'mmgt', widgetName: 'Monthly Mortgage Goal Track' },
	{ widgetCode: 'fggtd', widgetName: 'F&G NB Goal Track - Domestic' },
	{ widgetCode: 'fggtc', widgetName: 'F&G NB Goal Track - Commercial' },
	{ widgetCode: 'fgbb', widgetName: 'F&G Book Breakdown' },
	// { widgetCode: 'fgpsc', widgetName: 'F&G Premium Stats - Rolling 12 Months - Commercial' },
	// { widgetCode: 'fgpsd', widgetName: 'F&G Premium Stats - Rolling 12 Months - Domestic' },
	// { widgetCode: 'fgpslt', widgetName: 'F&G Premium Stats - Rolling 12 Months - Lead Type' },
	{ widgetCode: 'ebc', widgetName: 'E.B. Conversion' },
	{ widgetCode: 'lra', widgetName: 'L&R Anniversaries' },
	{ widgetCode: 'a', widgetName: 'Activities' },
	{ widgetCode: 'lrct', widgetName: 'L&R Commission Tracker' },
	{ widgetCode: 'aas', widgetName: 'Annual API Stats - CY' },
	{ widgetCode: 'aasf', widgetName: 'Annual API Stats - FY' },
	{ widgetCode: 'qas', widgetName: 'Quarterly API Stats' },
	{ widgetCode: 'mas', widgetName: 'Monthly API Stats' },
	{ widgetCode: 'was', widgetName: 'Weekly API Stats' },
	{ widgetCode: 'bb', widgetName: 'Book breakdown' },
	// { widgetCode: 'os', widgetName: 'Other Statistics' },
	{ widgetCode: 'adv', widgetName: 'Adviser Stats' },
	// { widgetCode: 'avs', widgetName: 'API rolling 6 months' }, // TAP1-3811: API Stats - Rolling 6 Months (Hide widget)
	{ widgetCode: 'asry', widgetName: 'API rolling 12 months' },
	{ widgetCode: 'cb', widgetName: 'Client Birthdays' },
	{ widgetCode: 'lrgtsg', widgetName: 'Self-Gen L&R Goal Track' },
	{ widgetCode: 'lc', widgetName: 'N.B. Conversion' },
	{ widgetCode: 'bnb', widgetName: 'Blanket New Business' },
	{ widgetCode: 'msw', widgetName: 'Meeting Stats' },
	{ widgetCode: 'mo', widgetName: 'Mortgage Opportunity' },
	// { widgetCode: 'apt', widgetName: 'Advice Process Tracking' },
	{ widgetCode: 'eblr', widgetName: 'E.B. L&R Opportunity' },
	{ widgetCode: 'nblr', widgetName: 'N.B. L&R Opportunity' },
];
/**
 * Query to get dashboard related data
 */
@Injectable()
export class DashboardQuery extends Query<DashboardState> {
	/** Existing widgets */
	public availableWidgets: { widgetCode: string; widgetName: string }[] = allWidgets?.filter(x =>
		this.businessConfigQuery.getValue().config.Widgets?.some(c => c?.toLowerCase() === x.widgetCode)
	);

	/** Observable version of Existing widgets */
	public availableWidgets$: Observable<
		{ widgetCode: string; widgetName: string }[]
	> = this.businessConfigQuery.widgets$.pipe(
		map(widgets => ([...widgets, "APT"])),
		map(widgets => {
			return allWidgets?.filter(w => widgets?.some(c => c?.toLowerCase() === w.widgetCode))
		})
	);

	/**
	 * all active/inactive and paused adviser choices for adviser field
	 */
	allAdvisers$ = this.staffQuery.allAvailableStaffs$.pipe(
		withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
		map(([staffs, isTapLevel, userInfo]) => {
			const availableStaffs = staffs
				// Filter only Business Onwers, Adviser Managers and Advisers and ShowInAdviserList
				?.filter(x => x.SecurityGroup === 'A' || x.SecurityGroup === 'AM' || x.SecurityGroup === 'BO' || !!x.StaffSettings.ShowInAdviserList)
				// Filter only assigned advisers for BL
				?.filter(x => (!isTapLevel ? userInfo.AdviserIdsAssigned?.some(y => y === x.StaffID) : x))
				// Map to ViewDisplayValue
				?.map(x => ViewDisplayValue.Map(x.StaffID?.toString(), `${x.FirstName} ${x.LastName}`));

			// If Business Level user, add logged in BL
			const dsiplay = !isTapLevel
				? !userInfo.SecurityRoles?.includes('FDAA')
					? [ViewDisplayValue.Map(userInfo.StaffID?.toString(), `${userInfo.FirstName} ${userInfo.LastName}`)]
					: availableStaffs
				: availableStaffs;

			// Sort adviser dropdowns
			return dsiplay ? dsiplay?.sort((a, b) => a.display?.localeCompare(b.display)) : dsiplay;
		})
	);

	allAdvisersId$ = this.allAdvisers$.pipe(map(choices => choices?.map(choice => choice.value)));

	public hasSelectedAllAdvisers$ = this.select(state => state.hasSelectedAllAdvisers);
	/**
	 * Adviser ids chosen to filter dashboard widget data
	 */
	public adviserFilter$ = this.select(state => state.adviserFilter).pipe(
		withLatestFrom(
			this.hasSelectedAllAdvisers$,
			this.allAdvisersId$,
			(activePausedAdvisers, hasSelectedAllAdvisers, allAdvisers) => ({
				activePausedAdvisers,
				hasSelectedAllAdvisers,
				allAdvisersId: allAdvisers
			})
		),
		map(res => (res.hasSelectedAllAdvisers ? res.allAdvisersId : res.activePausedAdvisers))
	);

	public adviserFilterWithoutInactive$ = this.select(state => state.adviserFilter);

	/**
	 * Indicator if the user has chosen advisers for filter
	 */
	public hasAdviserFilter$ = this.select(state => state.adviserFilter.length > 0);

	/**
	 * Observable Active widget codes for current user
	 */
	public activeWidgetCodes$ = this.select(state => state.widgets?.map(widget => widget.code));

	/**
	 * Active widget for current user
	 */
	public activeWidgets$ = this.select(state => state.widgets?.map(widget => widget));

	/** Indicator if widgets are saving */
	public isSavingWidgets$ = this.select(state => state.isSavingWidgets);

	public isSelectedAllWidgets$ = this.select(state => state.isSelectedAllWidgets);

	public referrals$ = this.select(state => state.referrals);

	constructor(
		protected store: DashboardStore,
		private businessConfigQuery: BusinessConfigQuery,
		private staffQuery: BLStaffsQuery,
		private userQuery: UserQuery
	) {
		super(store);

	}

	/**
	 * Active widget codes for current user
	 */
	public activeWidgetCodes = () => this.getValue().widgets?.map(x => x.code);
}
