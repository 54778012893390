<form [formGroup]="form" class="form" class="mb-3">
	<div formArrayName="loans">
		<ng-container
			*ngFor="let loan of LoanList.controls; let last = last; let i = index"
			[formGroupName]="i"
		>
			<div
				*ngIf="!loan?.get('hidden').value"
				class="row align-items-center"
				[ngClass]="last ? 'pb-0' : 'pb-3'"
			>
				<div class="col-12 col-lg-3">
					<div class="form-row align-items-center">
						<div class="col-12 col-lg-4 text-left mb-2">
							<label for="" class="position-relative font-weight-bold">
								Lender
							</label>
						</div>
						<div class="col-12 col-lg-8 mb-2 pr-md-0">
							<input
								id="lenderName"
								class="form-control crt-form-control"
								type="text"
								formControlName="lender"
								[class.disabled]="true"
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-3">
					<div class="form-row align-items-center">
						<div class="col-12 col-lg-4 text-left mb-2">
							<label for="type" class="position-relative font-weight-bold">
								Type
							</label>
						</div>
						<div class="col-12 col-lg-8 mb-2 pr-md-0">
							<select
								formControlName="sourceType"
								class="form-control theme-crm-field-disabled crt-form-control disabled"
							>
								<option *ngFor="let s of sourceType$ | async" [value]="s.value">
									{{ s.text }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-4">
					<div class="form-row align-items-center">
						<div class="col-4 col-lg-5 text-left mb-2">
							<label for="" class="position-relative font-weight-bold">
								Loan Amount
							</label>
						</div>
						<div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
							<div class="dollar-icon">
								<input
									id="salesPrice"
									class="form-control crt-form-control"
									type="text"
									formControlName="loanAmount"
									[class.disabled]="true"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-2">
					<div class="col mb-2">
						<div class="custom-control custom-checkbox">
							<input
								id="loanToggle_{{ i }}"
								type="checkbox"
								class="chckbox custom-control-input"
								formControlName="isTicked"
								(change)="tickLoan(i)"
								[class.disabled]="isUpdating"
							/>
							<label
								class="custom-control-label"
								for="loanToggle_{{ i }}"
								[class.disabled]="isUpdating"
							></label>
							<span>
								<app-manual-loader-mini
									[isLoading]="!!loan.get('isLoading').value"
								></app-manual-loader-mini>
							</span>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="row my-4">
		<div class="col-12 col-lg-6">
			<div class="form-row align-items-center">
				<div class="col">
					<button
						(click)="addNewLoan()"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 ml-1"
					>
						Add Loan +
					</button>
				</div>
			</div>
		</div>

		<div class="col-12 col-lg-4">
			<div class="form-row">
				<div class="col-4 col-lg-5 text-left mb-2">
					<label
						for=""
						class="position-relative font-weight-bold tap-text-primary"
					>
						Net Proceeds from Sale
					</label>
				</div>
				<div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
					<ng-container *ngIf="isLoading">
						<div class="form-row mt-1">
							<app-manual-loader-mini
								[isLoading]="true"
							></app-manual-loader-mini>
						</div>
					</ng-container>

					<div class="dollar-icon" *ngIf="!isLoading">
						{{ netProceeds | currency : "USD" : "$" : "1.2-2" }}
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
