import { SpecialFeaturesSettingsCode } from '@modules/special-features/state/special-features-settings.model';
import { MemberSearchState } from '@shared/models/business-profile/members/members.model';
import * as R from 'ramda';
import { prop, uniqBy } from 'ramda';
import { combineLatest } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ClaimsTable, GetServiceByAdviceProcessCode } from 'src/app/shared/models/advice-process/advice-process.model';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { numUtil, objectUtil, util } from '../../../../util/util';
import { BusinessProfileQuery } from './business-profile.query';
import { BusinessProfileStore } from './business-profile.store';

export abstract class BusinessProfileUtilsService {
	constructor(
		protected store: BusinessProfileStore,
		protected query: BusinessProfileQuery,
		protected dropdownValueQuery: DropdownValueQuery,
		protected blStaffsQuery: BLStaffsQuery,
		protected businessConfigQuery: BusinessConfigQuery,
		protected userQuery: UserQuery,
	) {}
	/**
	 * Dropdown choices
	 */
	// L&R Insurance
	LRProviders$ = this.dropdownValueQuery.orderedChoices$('LRP');
	LRPolicyStatus$ = this.dropdownValueQuery.orderedChoices$('LRPS');
	LRProducts$ = this.dropdownValueQuery.orderedChoices$('LRPR');
	LRPolicyTypes$ = this.dropdownValueQuery.orderedChoices$('LRPT');
	LRClaimStatus$ = this.dropdownValueQuery.orderedChoices$('LRCS');
	LRDiscounts$ = this.dropdownValueQuery.orderedChoices$('LRPD');
	LRPayments$ = this.dropdownValueQuery.orderedChoices$('LRPF');
	LRCancellationCodes$ = this.dropdownValueQuery.orderedChoices$('LRCC');
	LRPolicyRetentionStatus$ = this.dropdownValueQuery.orderedChoices$('LRRS');

	PCT$ = this.dropdownValueQuery.orderedChoices$('PCT');
	PCE$ = this.dropdownValueQuery.orderedChoices$('PCE');
	PCPC$ = this.dropdownValueQuery.orderedChoices$('PCPC');
	PCLE$ = this.dropdownValueQuery.orderedChoices$('PCLE');
	PCLT$ = this.dropdownValueQuery.orderedChoices$('PCLT');
	PCR$ = this.dropdownValueQuery.orderedChoices$('PCR');
	LS$ = this.dropdownValueQuery.orderedChoices$('LS');
	SCR$ = this.dropdownValueQuery.orderedChoices$('SCR');
	SCTT$ = this.dropdownValueQuery.orderedChoices$('SCTT');
	LRPS$ = this.dropdownValueQuery.orderedChoices$('LRPS');
	LRPF$ = this.dropdownValueQuery.orderedChoices$('LRPF');
	LRCS$ = this.dropdownValueQuery.orderedChoices$('LRCS');
	LRRS$ = this.dropdownValueQuery.orderedChoices$('LRRS');
	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');
	LRPT$ = this.dropdownValueQuery.orderedChoices$('LRPT');
	LRPD$ = this.dropdownValueQuery.orderedChoices$('LRPD');
	LRCC$ = this.dropdownValueQuery.orderedChoices$('LRCC');
	LRPR$ = this.dropdownValueQuery.orderedChoices$('LRPR');
	MP$ = this.dropdownValueQuery.orderedChoices$('MP');
	MS$ = this.dropdownValueQuery.orderedChoices$('MS');
	MT$ = this.dropdownValueQuery.orderedChoices$('MT');
	MFPE$ = this.dropdownValueQuery.orderedChoices$('MFPE');
	MRF$ = this.dropdownValueQuery.orderedChoices$('MRF');
	MLT$ = this.dropdownValueQuery.orderedChoices$('MLT');
	SV1$ = this.dropdownValueQuery.orderedChoices$('SV1');
	SV2$ = this.dropdownValueQuery.orderedChoices$('SV2');
	ST$ = this.dropdownValueQuery.orderedChoices$('ST');
	SU$ = this.dropdownValueQuery.orderedChoices$('SU');
	STI$ = this.dropdownValueQuery.orderedChoices$('STI');
	SS$ = this.dropdownValueQuery.orderedChoices$('SS');
	CLS$ = this.dropdownValueQuery.orderedChoices$('CLS');
	APCRTF$ = this.dropdownValueQuery.orderedChoices$('APCRTF');
	FGS$ = this.dropdownValueQuery.orderedChoices$('FGS');
	FGCS$ = this.dropdownValueQuery.orderedChoices$('FGCS');
	FGAS$ = this.dropdownValueQuery.orderedChoices$('FGAS');
	FGI$ = this.dropdownValueQuery.orderedChoices$('FGI');
	FGPT$ = this.dropdownValueQuery.orderedChoices$('FGPT');
	FGPW$ = this.dropdownValueQuery.orderedChoices$('FGPW');
	FGPL$ = this.dropdownValueQuery.orderedChoices$('FGPL');
	KP$ = this.dropdownValueQuery.orderedChoices$('KP');
	IP$ = this.dropdownValueQuery.orderedChoices$('IP');
	KS$ = this.dropdownValueQuery.orderedChoices$('KS');
	IS$ = this.dropdownValueQuery.orderedChoices$('IS');
	KFT$ = this.dropdownValueQuery.orderedChoices$('KFT');
	KO$ = this.dropdownValueQuery.orderedChoices$('KO');
	KT$ = this.dropdownValueQuery.orderedChoices$('KT');
	IT$ = this.dropdownValueQuery.orderedChoices$('IT');
	KPIRR$ = this.dropdownValueQuery.orderedChoices$('KPIRR');
	IPIRR$ = this.dropdownValueQuery.orderedChoices$('IPIRR');
	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');
	MRS$ = this.dropdownValueQuery.orderedChoices$('MRS');
	LT$ = this.dropdownValueQuery.orderedChoices$('LT');
	SAS$ = this.dropdownValueQuery.orderedChoices$('SAS');
	CT$ = this.dropdownValueQuery.orderedChoices$('CT');
	PT$ = this.dropdownValueQuery.orderedChoices$('PT');
	PAT$ = this.dropdownValueQuery.orderedChoices$('PAT');
	FGPM$ = this.dropdownValueQuery.orderedChoices$('FGPM');
	FGF$ = this.dropdownValueQuery.orderedChoices$('FGF');
	APCRTL$ = this.dropdownValueQuery.orderedChoices$('APCRTL');
	IRS$ = this.dropdownValueQuery.orderedChoices$('IRS');
	KRS$ = this.dropdownValueQuery.orderedChoices$('KRS');
	FGPCR$ = this.dropdownValueQuery.orderedChoices$('FGPCR');

	// Advice Process
	AP$ = this.dropdownValueQuery.orderedChoices$('AP');
	APCRTAS$ = this.dropdownValueQuery.orderedChoices$('APCRTAS');
	APCRTAT$ = this.dropdownValueQuery.orderedChoices$('APCRTAT');
	APCRTCT$ = this.dropdownValueQuery.orderedChoices$('APCRTCT');
	APCRTCO$ = this.dropdownValueQuery.orderedChoices$('APCRTCO');
	APCRTQI1$ = this.dropdownValueQuery.orderedChoices$('APCRTQI1');
	APCRTQI6$ = this.dropdownValueQuery.orderedChoices$('APCRTQI6');
	APCRTQCLR7$ = this.dropdownValueQuery.orderedChoices$('APCRTQCLR7');
	APCRTRL$ = this.dropdownValueQuery.orderedChoices$('APCRTRL');
	APCRTFCC$ = this.dropdownValueQuery.orderedChoices$('APCRTFCC');
	APCRTYNNA$ = this.dropdownValueQuery.orderedChoices$('APCRTYNNA');
	APCRTQIK6$ = this.dropdownValueQuery.orderedChoices$('APCRTQIK6');
	CARQICAR1$ = this.dropdownValueQuery.orderedChoices$('CARQICAR1');
	APCRTQIK15$ = this.dropdownValueQuery.orderedChoices$('APCRTQIK15');
	CCO$ = this.dropdownValueQuery.orderedChoices$('CCO');

	primaryCompany$ = this.query.primaryCompany$;
	primaryCompany = () => this.query.getValue().primaryCompany;
	directors$ = this.query.directors$;
	shareholders$ = this.query.shareholders$;
	others$ = this.query.others$;

	PreferredEmailContacts$ = combineLatest([
		this.primaryCompany$,
		this.directors$,
		this.shareholders$,
		this.others$,
	]).pipe(
		map(([primaryCompany, directors, shareholders, others]) => {
			if (!primaryCompany) {
				return [];
			}

			const mapViewDisplay = (x) => {
				return x ? R.map((y) => ViewDisplayValue.Map(y.customerId?.toString(), y.name), x) : [];
			};

			const pc = primaryCompany?.primaryContact
				? ViewDisplayValue.Map(
						primaryCompany.primaryContact ? primaryCompany.primaryContact?.customerId : '',
						primaryCompany.primaryContact?.name,
					)
				: null;
			const d = mapViewDisplay(directors);
			const s = mapViewDisplay(shareholders);
			const o = mapViewDisplay(others);
			return [pc, ...d, ...s, ...o];
		}),
	);

	public advisers$ = this.blStaffsQuery.adviserChoicesOption$;
	public allowedInAccessibility$ = this.blStaffsQuery.allowedInAccessibility$;

	public reviewedBy$ = this.blStaffsQuery.availableStaffs$.pipe(
		map((x) =>
			R.filter(
				(y) =>
					y.SecurityGroup === 'BO' || y.SecurityGroup === 'BAI' || y.SecurityGroup === 'BM' || y.SecurityGroup === 'AM',
				x,
			),
		),
		map((x) => R.map((y) => ViewDisplayValue.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
	);

	public activityAdvisers$ = this.blStaffsQuery.availableStaffs$.pipe(
		map((x) => R.map((y) => ViewDisplayValue.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
	);

	public availableAdvisers$ = this.blStaffsQuery.availableStaffs$;
	public allStaffChoices$ = this.blStaffsQuery.allStaffsChoices$;
	public allActiveStaffs$ = this.blStaffsQuery.allActiveStaffs$;
	public altAdviserChoices$ = this.blStaffsQuery.altAdviserChoices$;
	public activeAdviserChoices$ = combineLatest([
		this.blStaffsQuery.allStaffsChoices$,
		this.primaryCompany$,
		this.advisers$,
	]).pipe(
		withLatestFrom(this.blStaffsQuery.allStaffs$),
		map(([[all, primaryCompany, adv], staffs]) =>
			all
				?.filter((x) => {
					const adviser = staffs?.find((s) => +s?.StaffID === +x?.value);
					return (
						(x.value === primaryCompany?.adviser?.toString() && adviser?.IsActive > 0) ||
						adv?.find((y) => y.value === x.value)
					);
				})
				?.sort((a, b) => a.display?.localeCompare(b.display)),
		),
	);
	public adviserChoices$ = combineLatest([
		this.blStaffsQuery.allStaffsChoices$,
		this.advisers$,
		this.primaryCompany$,
	]).pipe(
		map(([all, adv, primaryCompany]) =>
			all
				?.filter((x) => x.value === primaryCompany?.adviser?.toString() || adv?.find((y) => y.value === x.value))
				?.sort((a, b) => a.display?.localeCompare(b.display)),
		),
	);

	public activityAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.activityAdvisers$),
		map(([all, adv]) =>
			all
				? all
						?.filter((x) => x.value === '' || adv?.find((y) => y.value === x.value))
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all,
		),
	);

	public adviserCalendars$ = this.blStaffsQuery.adviserCalendarChoices$;
	public adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;
	public leadGenChoices$ = this.blStaffsQuery.leadGenChoices$;

	contactStatus$ = this.query.primaryCompany$.pipe(
		take(1),
		map((x) => x.contactStatus),
	);

	customerID$ = this.query.customerID$;

	// Services
	lrInsurance$ = this.query.lrInsurance$;
	mortgage$ = this.query.mortgage$;
	propertyAsset$ = this.query.propertyAsset$;
	fgInsurance$ = this.query.fgInsurance$;
	kiwiSavers$ = this.query.kiwiSavers$;
	investments$ = this.query.investments$;
	assets$ = this.query.asset$;
	liabilities$ = this.query.liability$;

	// Document
	documents$ = this.query.documents$;
	histories$ = this.query.histories$;

	// Advice Processes
	adviceProcesses$ = this.query.adviceProcesses$;

	// Business Codes
	// Business Codes
	hasLR$ = combineLatest([this.businessConfigQuery.hasLR$, this.userQuery.isUserHasLR$]).pipe(
		map(([hasBusinessLR, hasUserLR]) => hasBusinessLR && hasUserLR),
	);
	hasM$ = combineLatest([this.businessConfigQuery.hasM$, this.userQuery.isUserHasM$]).pipe(
		map(([hasBusinessM, hasUserM]) => hasBusinessM && hasUserM),
	);
	hasFG$ = combineLatest([this.businessConfigQuery.hasFG$, this.userQuery.isUserHasFG$]).pipe(
		map(([hasBusinessFG, hasUserFG]) => hasBusinessFG && hasUserFG),
	);
	hasK$ = combineLatest([this.businessConfigQuery.hasK$, this.userQuery.isUserHasK$]).pipe(
		map(([hasBusinessK, hasUserK]) => hasBusinessK && hasUserK),
	);
	hasI$ = combineLatest([this.businessConfigQuery.hasI$, this.userQuery.isUserHasI$]).pipe(
		map(([hasBusinessI, hasUserI]) => hasBusinessI && hasUserI),
	);
	hasAP$ = combineLatest([this.businessConfigQuery.hasAP$, this.userQuery.isUserHasAP$]).pipe(
		map(([hasbusinessAP, hasUserAP]) => hasbusinessAP && hasUserAP),
	);
	hasCRT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasCRT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasCRT$,
	]).pipe(
		map(
			([hasbusinessAP, hasbusinessCRT, hasUserAP, hasUserCRT]) =>
				hasbusinessAP && hasbusinessCRT && hasUserAP && hasUserCRT,
		),
	);
	hasMOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasMOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasMOAT$,
	]).pipe(
		map(
			([hasbusinessAP, hasbusinessMOAT, hasUserAP, hasUserMOAT]) =>
				hasbusinessAP && hasbusinessMOAT && hasUserAP && hasUserMOAT,
		),
	);
	hasKOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasKOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasKOAT$,
	]).pipe(
		map(
			([hasbusinessAP, hasbusinessKOAT, hasUserAP, hasUserKOAT]) =>
				hasbusinessAP && hasbusinessKOAT && hasUserAP && hasUserKOAT,
		),
	);
	hasClientAlterationRequest$ = this.businessConfigQuery.select((config) => {
		return config?.config?.SpecialFeature?.includes(SpecialFeaturesSettingsCode.CAR) ?? false;
	});

	directorIsLoading$ = this.query.directorIsLoading$;
	shareholderIsLoading$ = this.query.shareholderIsLoading$;
	othersIsLoading$ = this.query.othersIsLoading$;

	lrIsLoading$ = this.query.lrIsLoading$;
	mIsLoading$ = this.query.mIsLoading$;
	paIsLoading$ = this.query.paIsLoading$;
	aIsLoading$ = this.query.aIsLoading$;
	fgIsLoading$ = this.query.fgIsLoading$;
	kIsLoading$ = this.query.kIsLoading$;
	iIsLoading$ = this.query.iIsLoading$;

	documentIsLoading$ = this.query.documentIsLoading$;
	historyIsLoading$ = this.query.historyIsLoading$;
	adviceProcessIsLoading$ = this.query.adviceProcessIsLoading$;

	// Current Activity Criteria
	criterias$ = this.query.currentActivityCriterias$;
	/**
	 * Activity timeline
	 */
	activityTimeline$ = this.query.activityTimeline$.pipe(
		map((x) => (x ? x : { activities: [], notes: [] })),
		// map((x) => {
		//   x.activities = sort(x.activities).desc((a: ActivityState) => a.dateTime);
		//   x.notes = sort(x.notes).desc((a: NoteState) => a.createDateTime);
		//   return x;
		// })
	);
	// Pending Activities
	pendingActivities$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.activities),
		map((activities) => (activities ? R.filter((x) => !x.isCancelled && !x.isCompleted, activities) : null)),
	);

	// Completed Notes
	completedActivities$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.notes),
		map((notes) => (notes ? R.filter((x) => !!x.activityType && !!x.activityName, notes) : null)),
	);
	completedNotes$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.notes),
		map((notes) => (notes ? R.filter((x) => !x.activityName, notes) : null)),
	);
	allCompletedNotes$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.notes),
	);

	clientsInvolvedRaw$ = combineLatest([this.primaryCompany$, this.directors$, this.shareholders$, this.others$]).pipe(
		map(([company, directors, shareholders, others]) => {
			// biome-ignore lint/suspicious/noExplicitAny: dynamic value
			const mappedCompany: any = {
				customerID: company.customerID,
				name: company.companyName,
				customerType: 'PCC',
			};
			// biome-ignore lint/suspicious/noExplicitAny: dynamic value
			const mapData = (data: any[]) => {
				// biome-ignore lint/suspicious/noExplicitAny: dynamic value
				return data?.map((res: any) => {
					return {
						customerID: res.customerId,
						name: res.name,
						customerType: res.customerType,
						contactMethod: res.contactMethod,
					};
				});
			};
			const result = [
				{ ...mappedCompany },
				...mapData(directors ?? []),
				// shareholders
				...mapData(shareholders ?? []),
				// others
				...mapData(others ?? []),
				// primary contact
				...mapData([company?.primaryContact]),
			];
			// remove duplicate customerID
			return [...new Map(result?.map((x) => [x.customerID, x]))?.values()];
		}),
		// const mappedOthers = others.map((other) => {

		// });
		// const mapped = [...(directors ?? []), ...(shareholders ?? []), ...(others ?? [])].map((raw: any) => {
		//   const rawCloned = R.clone(raw);
		//   rawCloned.value = rawCloned.customerId;
		//   rawCloned.display = rawCloned.name;
		//   rawCloned.type = rawCloned.customerType;
		//   return rawCloned;
		// });
		// if (company) {
		//   mapped.push({
		//     value: company.customerID.toString(),
		//     display: company.companyName,
		//     type: company.customerType
		//   });
		// }
		// return mapped;
		// })
	);

	LRPolicyOwners$ = combineLatest([this.primaryCompany$, this.directors$, this.shareholders$, this.others$]).pipe(
		map(([company, directors, sh, others]) => {
			const c = company ? ViewDisplayValue.Map(company.customerID?.toString(), `${company.companyName}`) : null;
			const pcMapped =
				company && company.primaryContact ? objectUtil.mapPascalCaseToCamelCase(company.primaryContact) : null;
			const pc = company
				? pcMapped && pcMapped.customerId
					? ViewDisplayValue.Map(pcMapped.customerId?.toString(), `${pcMapped.name}`)
					: null
				: null;

			const d = directors
				? R.map(
						(y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null),
						directors,
					)
				: [];
			const s = sh
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), sh)
				: [];
			const o = others
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), others)
				: [];

			return [...new Map([c, pc, ...d, ...s, ...o]?.map((x) => [x.value, x]))?.values()].sort((a, b) =>
				a.display?.toLowerCase().localeCompare(b.display?.toLowerCase()),
			);
		}),
	);

	BorrowingEntities$ = combineLatest([this.primaryCompany$, this.directors$, this.shareholders$, this.others$]).pipe(
		map(([company, directors, sh, others]) => {
			const c = company ? ViewDisplayValue.Map(company.customerID?.toString(), `${company.companyName}`) : null;
			const pcMapped =
				company && company.primaryContact ? objectUtil.mapPascalCaseToCamelCase(company.primaryContact) : null;
			const pc = company
				? pcMapped && pcMapped.customerId
					? ViewDisplayValue.Map(pcMapped.customerId?.toString(), `${pcMapped.name}`)
					: null
				: null;

			const d = directors
				? R.map(
						(y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null),
						directors,
					)
				: [];
			const s = sh
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), sh)
				: [];
			const o = others
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), others)
				: [];

			return [...new Map([c, pc, ...d, ...s, ...o]?.map((x) => [x.value, x]))?.values()].sort((a, b) =>
				a.display?.toLowerCase().localeCompare(b.display?.toLowerCase()),
			);
		}),
	);

	PropertyOwners$ = combineLatest([this.primaryCompany$, this.directors$, this.shareholders$, this.others$]).pipe(
		map(([company, directors, sh, others]) => {
			const c = company ? ViewDisplayValue.Map(company.customerID?.toString(), `${company.companyName}`) : null;
			const pcMapped =
				company && company.primaryContact ? objectUtil.mapPascalCaseToCamelCase(company.primaryContact) : null;
			const pc = company
				? pcMapped && pcMapped.customerId
					? ViewDisplayValue.Map(pcMapped.customerId?.toString(), `${pcMapped.name}`)
					: null
				: null;

			const d = directors
				? R.map(
						(y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null),
						directors,
					)
				: [];
			const s = sh
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), sh)
				: [];
			const o = others
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), others)
				: [];

			return [...new Map([c, pc, ...d, ...s, ...o]?.map((x) => [x.value, x]))?.values()].sort((a, b) =>
				a.display?.toLowerCase().localeCompare(b.display?.toLowerCase()),
			);
		}),
	);

	Securities$ = this.propertyAsset$.pipe(
		map((prop) => {
			const pa =
				prop && prop.customerServices
					? R.map(
							(y) => ViewDisplayValue.Map(y.customerServiceID?.toString(), `${y.propertyAddress}`),
							prop.customerServices,
						)
					: null;
			return pa;
		}),
	);

	FGPolicyOwners$ = combineLatest([this.primaryCompany$, this.directors$, this.shareholders$, this.others$]).pipe(
		map(([company, directors, sh, others]) => {
			const c = company ? ViewDisplayValue.Map(company.customerID?.toString(), `${company.companyName}`) : null;
			const pcMapped =
				company && company.primaryContact ? objectUtil.mapPascalCaseToCamelCase(company.primaryContact) : null;
			const pc = company
				? pcMapped && pcMapped.customerId
					? ViewDisplayValue.Map(pcMapped.customerId?.toString(), `${pcMapped.name}`)
					: null
				: null;

			const d = directors
				? R.map(
						(y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null),
						directors,
					)
				: [];
			const s = sh
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), sh)
				: [];
			const o = others
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), others)
				: [];

			return [...new Map([c, pc, ...d, ...s, ...o]?.map((x) => [x.value, x]))?.values()].sort((a, b) =>
				a.display?.toLowerCase().localeCompare(b.display?.toLowerCase()),
			);
		}),
	);

	fundOwners$ = combineLatest([this.primaryCompany$, this.directors$, this.shareholders$, this.others$]).pipe(
		map(([company, directors, sh, others]) => {
			const c = company ? ViewDisplayValue.Map(company.customerID?.toString(), `${company.companyName}`) : null;
			const pcMapped =
				company && company.primaryContact ? objectUtil.mapPascalCaseToCamelCase(company.primaryContact) : null;
			const pc = company
				? pcMapped && pcMapped.customerId
					? ViewDisplayValue.Map(pcMapped.customerId?.toString(), `${pcMapped.name}`)
					: null
				: null;

			const d = directors
				? R.map(
						(y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null),
						directors,
					)
				: [];
			const s = sh
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), sh)
				: [];
			const o = others
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), others)
				: [];

			return [...new Map([c, pc, ...d, ...s, ...o]?.map((x) => [x.value, x]))?.values()].sort((a, b) =>
				a.display?.toLowerCase().localeCompare(b.display?.toLowerCase()),
			);
		}),
	);

	clientsInvolved$ = combineLatest([this.primaryCompany$, this.directors$, this.shareholders$, this.others$]).pipe(
		map(([company, directors, sh, others]) => {
			const c = company ? ViewDisplayValue.Map(company.customerID?.toString(), `${company.companyName}`) : null;
			const pcMapped =
				company && company.primaryContact ? objectUtil.mapPascalCaseToCamelCase(company.primaryContact) : null;
			const pc = company
				? pcMapped && pcMapped.customerId
					? ViewDisplayValue.Map(pcMapped.customerId?.toString(), `${pcMapped.name}`)
					: null
				: null;

			const d = directors
				? R.map(
						(y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null),
						directors,
					)
				: [];
			const s = sh
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), sh)
				: [];
			const o = others
				? R.map((y) => (y && y.customerId ? ViewDisplayValue.Map(y.customerId?.toString(), `${y.name}`) : null), others)
				: [];

			return [...new Map([c, pc, ...d, ...s, ...o]?.map((x) => [x.value, x]))?.values()];
		}),
	);

	listOfServices = (id: number) =>
		this.lrInsurance$.pipe(
			withLatestFrom(this.mortgage$, this.propertyAsset$, this.fgInsurance$, this.kiwiSavers$),
			map(([lr, m, pa, fg, ks]) => {
				const checkLr = lr.lRs
					?.map((l) => {
						return {
							...l,
							customerServices: l.customerServices?.filter((cs) => {
								const a = cs.policyOwners ? JSON.parse(cs.policyOwners) : null;
								return cs.isActive === 1 && !!a && a?.find((po) => po && !!(+po === id));
							}),
						};
					})
					?.filter((x) => !!x.customerServices && x.customerServices !== null && x.customerServices.length > 0);

				const checkM = m.mortgages
					?.map((mortgage) => {
						return {
							...mortgage,
							customerServices: mortgage.customerServices?.filter((cs) => {
								const a = cs.borrowingEntities ? JSON.parse(cs.borrowingEntities) : null;
								return cs.isActive === 1 && !!a && a?.find((po) => po && !!(+po === id));
							}),
						};
					})
					?.filter((x) => !!x.customerServices && x.customerServices !== null && x.customerServices.length > 0);

				const checkPa = pa.customerServices?.filter((cs) => {
					const a = cs.propertyOwner ? JSON.parse(cs.propertyOwner) : null;
					return cs.isActive === 1 && !!a && a?.find((po) => !!(+po === id));
				});

				const checkFg = fg.fGs
					?.map((l) => {
						return {
							...l,
							customerServices: l.customerServices?.filter((cs) => {
								const a = cs.policyOwners ? JSON.parse(cs.policyOwners) : null;
								return cs.isActive === 1 && !!a && a?.find((po) => po && !!(+po === id));
							}),
						};
					})
					?.filter((x) => !!x.customerServices && x.customerServices !== null && x.customerServices.length > 0);

				const checkKs = ks?.filter((x) => x.isActive === 1 && +x.fundOwner === id);
				return checkLr?.length > 0 ||
					checkM?.length > 0 ||
					checkPa?.length > 0 ||
					checkFg?.length > 0 ||
					checkKs?.length > 0
					? {
							lrs: checkLr?.length > 0 ? checkLr : null,
							mortgages: checkM?.length > 0 ? checkM : null,
							propertyAssets: checkPa?.length > 0 ? checkPa : null,
							fgs: checkFg?.length > 0 ? checkFg : null,
							kiwiSavers: checkKs?.length > 0 ? checkKs : null,
						}
					: null;
			}),
		);

	/**
	 * Get the Service for Advice Process
	 * @param code ProcessCode
	 * @returns Service Code or null
	 */
	getAdviceProcessService(code: string) {
		return GetServiceByAdviceProcessCode(code);
	}

	/**
	 * Get the list of available choices for people from CRM
	 * @param ownersIds Value of specific input field selected
	 * @param policyOwners available choices
	 * @returns list of available choices with or without deceased linked others
	 */
	getOwnerChoices = (ownersIds: (string | number)[], policyOwners: ViewDisplayValue[]) => {
		const ids = ownersIds?.map((x) => +x) || [];
		const others = this.query.getValue()?.others || [];
		const otherDeceased = others?.filter((x: MemberSearchState) => x?.contactMethod === 'Deceased') || [];

		let newList =
			policyOwners?.filter((x) => {
				if (ids?.includes(+x?.value)) {
					return true;
				}
				return !otherDeceased?.find((d) => +d?.customerId === +x?.value);
			}) || [];
		newList = uniqBy(prop('value'), newList);

		return newList?.sort((a, b) => a.display?.localeCompare(b.display));
	};

	lRClaimsList$ = this.adviceProcesses$.pipe(map((advice)=>{
		const documents = {};
		advice?.lRClaimsAdviceProcesses?.slice()?.sort(
			(a,b)=>  new Date(b.createdDateTime).valueOf() - new Date(a.createdDateTime).valueOf()
		)?.map((data)=>{
			const ids = JSON.parse(data.customerServiceID) as string[];
			ids.map((ids)=>{
				const tableData:ClaimsTable = {
					adviceProcessID:data.adviceProcessID,
					createdDate:data.createdDateTime,
					totalPaid:numUtil.formatToCurrency2(numUtil.toNumber(data.totalPaid)),
					referenceNumber:data.referenceNumber,
					claimOutcome:data.claimOutcome,
					claimsDetail:data.claimDetails,
					claimType: util.tryParseJson(data.claimType),
					status:data.status
				};
				documents[ids]?documents[ids].push(tableData):documents[ids]=[tableData];
			})
		});
		return documents;
	}));

	fGClaimsList$ = this.adviceProcesses$.pipe(map((advice)=>{
		const documents = {};
		advice?.fGClaimsAdviceProcesses?.slice()?.sort(
			(a,b)=>  new Date(b.createdDateTime).valueOf() - new Date(a.createdDateTime).valueOf()
		)?.map((data)=>{
			const ids = JSON.parse(data.customerServiceID) as string[];
			ids.map((ids)=>{
				const tableData:ClaimsTable = {
					adviceProcessID:data.adviceProcessID,
					createdDate:data.createdDateTime,
					totalPaid:numUtil.formatToCurrency2(numUtil.toNumber(data.totalPaid)),
					referenceNumber:data.referenceNumber,
					claimOutcome:data.claimOutcome,
					claimsDetail:data.claimDetails,
					claimType: util.tryParseJson(data.claimType),
					status:data.status
				};
				documents[ids]?documents[ids].push(tableData):documents[ids]=[tableData];
			})
		});
		return documents;
	}))
}
