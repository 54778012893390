import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnChanges,
  AfterViewInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Spinner } from 'spin.js';
import { Subscription, ReplaySubject } from 'rxjs';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';

@Component({
  selector: 'app-manual-loader',
  template: `<div
    class="loader-container"
    [ngClass]="{ 'd-none': !(isLoading$ | async) }"
    #target
  ></div>`,
  styleUrls: ['./manual-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualLoaderComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  _isLoading: ReplaySubject<boolean>;
  @Input()
  set isLoading(value: boolean) {
    this._isLoading.next(value);
  }
  get isLoading$() {
    return this._isLoading.asObservable();
  }
  spinner: Spinner;
  private _opts: any;
  @Input()
  set opts(value) {
    Object.assign(this._opts, value);
  }
  @ViewChild('target') spinnerTarget: ElementRef;

  defaultColor: string;
  subs: Subscription[];

  constructor(private businessConfigQuery: BusinessConfigQuery) {
    this.setupProps();
  }
  setupProps(): void {
    this._isLoading = new ReplaySubject(1);
    this.defaultColor = '#00263e';
    this._opts = {
      lines: 8, // The number of lines to draw
      length: 46, // The length of each line
      width: 31, // The line thickness
      radius: 41, // The radius of the inner circle
      scale: 0.1, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      color: this.defaultColor, // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      opacity: 0, // Opacity of the lines
      rotate: 0, // The rotation offset
      animation: 'spinner-line-fade-quick',
      direction: 1, // 1: clockwise, -1: counterclockwise
      speed: 1.3, // Rounds per second
      trail: 100, // Afterglow percentage
      fps: 20, // Frames per second when using setTimeout() as a fallback in IE 9
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      className: 'spinner', // The CSS class to assign to the spinner
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
      position: 'absolute', // Element positioning
    };
    this.spinner = new Spinner(this._opts);
    this.subs = [];
    this.isLoading = false;
  }
  setupSubs(): void {
    const sub1 = this.businessConfigQuery.themeConfig$.subscribe((x) => {
      this.opts = {
        ...this.opts,
        color: x ? x.primarycolor : this.defaultColor,
      };
    });

    const sub2 = this.isLoading$.subscribe((x) => {
      if (x) {
        this.spinner = new Spinner(this._opts);
        this.spinner.spin(this.spinnerTarget.nativeElement);
      } else { this.spinner.stop(); }
    });

    this.subs.push(sub1, sub2);
  }

  ngOnInit() {}
  ngOnChanges(): void {}
  ngAfterViewInit(): void {
    this.setupSubs();
  }

  ngOnDestroy(): void {
    this.subs?.forEach((x) => x.unsubscribe());
    this.subs = [];
  }
}
