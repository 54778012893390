import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FGBookBreakdown } from './state/fgbb.model';
import { map, filter, takeUntil } from 'rxjs/operators';
import { BodyRow, HeaderRow } from '../../shared/simple-table/simple-table.model';
import { DashboardQuery } from '../../state/dashboard.query';
import { FGBookBreakdownQuery } from './state/fgbb.query';
import { FGBookBreakdownService } from './state/fgbb.service';
import { numUtil } from '../../../../../util/util';

@Component({
  selector: 'app-fgbb',
  templateUrl: './fgbb.component.html',
  styleUrls: ['./fgbb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FgbbComponent implements OnInit, OnDestroy {
  public static widgetCode = 'fgbb';
  public static widgetName = 'F&G Book Breakdown';
  public static sizeX = 800;
  public static sizeY = 245;
  public static minSizeX = 350;
  public static minSizeY = 245;

  widgetCode = FgbbComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * F&G Book Breakdown Data
   */
  data$: Observable<FGBookBreakdown[]> = this.query.FGBookBreakdownData$;

  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return data?.map<BodyRow>(item => {
          return [
            { text: `${item.Insurer}`, class: 'col' },
            { text: numUtil.formatToCurrency(item.TotalPremium), class: 'col' },
            { text: numUtil.formatToCurrency(item.NewBusiness), class: 'col' },
            { text: numUtil.formatToCurrency(item.Renewal), class: 'col' },
            { text: numUtil.formatToCurrency(item.MTA), class: 'col' },
            { text: numUtil.formatToCurrency(item.Cancelled), class: 'col' },
          ];
        });
      }
    })
  );

  /** header for simple-table header object */
  header: HeaderRow = [
    { text: 'Provider', class: 'col' },
    { text: 'Total Premium', class: 'col' },
    { text: 'New Business', class: 'col' },
    { text: 'Renewal', class: 'col' },
    { text: 'MTA', class: 'col' },
    { text: 'Cancelled', class: 'col' },
  ];

  constructor(
    private dashboardQuery: DashboardQuery,
    private query: FGBookBreakdownQuery,
    private service: FGBookBreakdownService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of book breakdown stats.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetFGBookBreakdown(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
