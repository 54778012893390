import { Component, OnInit } from '@angular/core';
import { GlobalSearchComponentService } from './global-search-component.service';
import { tap, finalize, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';

@Component({
	selector: 'app-global-search',
	templateUrl: './global-search.component.html',
	styleUrls: ['./global-search.component.scss'],
	providers: [GlobalSearchComponentService],
})
export class GlobalSearchComponent implements OnInit {
	keyword = '';
	private isShownSubj = new BehaviorSubject(false);
	private isLoadingSubj = new BehaviorSubject(false);
	public readonly isShown$ = this.isShownSubj.asObservable();
	public readonly isLoading$ = this.isLoadingSubj.asObservable();
	public readonly data$ = this.compService.searchResult$;
	constructor(private compService: GlobalSearchComponentService) {}

	ngOnInit() {}

	onEnter(keyword: string) {
		of(keyword)
			.pipe(
				tap(() => this.isLoadingSubj.next(true)),
				mergeMap(x => this.compService.search(x?.trim())),
				tap(() => this.isShownSubj.next(true)),
				finalize(() => {
					this.isLoadingSubj.next(false);
				})
			)
			.subscribe(
				x => x,
				err => {
					throw new Error(err);
				}
			);
	}
	close() {
		this.isShownSubj.next(false);
	}
}
