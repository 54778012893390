<form [formGroup]="form">
  <div class="container-fluid px-0 py-3">
    <div class="row tap-container">
      <div class="col pt-3">
        <h6>Import Type <small class="mx-3 font-italic text-dark">select import type</small></h6>
      </div>
    </div>
    <div class="row tap-container">
      <div class="col scc-border mb-2"></div>
    </div>

    <!-- Import Type -->
    <div>
      <div class="row tap-container">
        <div class="col-md-8 pr-md-0 my-2">
          <div class="form-group form-inline row mb-1">
            <div class="col-3">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="itStaff" value="SDI" formControlName="importType">
                <label class="custom-control-label pt-1" for="itStaff">Staff</label>
              </div>
            </div>
            <div class="col-3">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="itClient" value="PCI" formControlName="importType">
                <label class="custom-control-label pt-1" for="itClient">Client</label>
              </div>
            </div>
            <div class="col-3">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="itServices" value="Services"
                  formControlName="importType">
                <label class="custom-control-label pt-1" for="itServices">Services</label>
              </div>
            </div>
            <div class="col-3">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="itOthers" value="Others"
                  formControlName="importType">
                <label class="custom-control-label pt-1" for="itOthers">Others</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row tap-container">
        <div class="col scc-border mb-2"></div>
      </div>
    </div>
    <!-- End Import Type -->

    <!-- Client : TODO : USE ControlValueAccessor -->
    <div *ngIf="form.value.importType === 'PCI'">
      <app-client (clientEvent)="clientEvent($event)"></app-client>
    </div>
    <!-- End Client -->

    <!-- Services -->
    <div *ngIf="form.value.importType === 'Services'">
      <app-services (serviceEvent)="serviceEvent($event)"></app-services>
    </div>
    <!-- End Services -->

    <!-- Others -->
    <div *ngIf="form.value.importType === 'Others'">
      <app-others (othersEvent)="othersEvent($event)"></app-others>
    </div>
    <!-- End Others -->

  </div>

  <!-- Upload File -->
  <div class="container-fluid px-0 pt-2">
    <div class="d-flex justify-content-center align-items-center">
      <div class="custom-file w-25 mx-3 my-0">
        <input #file type="file" class="custom-file-input" name="file" type="file" id="file"
          (change)="chooseFile($event)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          aria-describedby="typeError">
        <small *ngIf="fileTypeError" id="typeError" class="form-text text-danger">
          {{fileTypeError}}
        </small>
        <label class="custom-file-label overflow-hidden" for="customFile">
          <div id="fileslist">
            <div *ngIf="filename !== ''; else selectFile">{{filename}}</div>
          </div>
          <ng-template #selectFile>Upload here</ng-template>
        </label>
      </div>
      <button type="button" class="btn btn-primary my-2 mx-2" [appTheme]="{'background-color':'primarycolor'}"
        (click)="uploadFile()" [disabled]="disableUploadBtn">
        <span class="spinner-border spinner-border-sm" *ngIf="isLoading"></span>
        UPLOAD
      </button>
    </div>
  </div>

  <!-- Table -->
  <app-partial-view [excelData]="excelData" [dropdownValues]="dropdownValues" [columnOrder]="columnOrder"
    (reOrderColumnEvent)="reOrderColumnEvent($event)"></app-partial-view>

  <!-- Import and Cancel Button -->
  <div class="container-fluid px-0">
    <div class="row tap-container">
      <div>
        <p class="font-italic mx-3 my-0">{{rowsCountToUpload}} contacts to upload</p>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center mx-3">
      <div *ngIf="showRequiredFieldsMissing" class="mx-1 text-danger">
        Required missing fields: {{requiredFieldsMsg}}
      </div>
      <button *ngIf="!isUpdateGoogleCalendar" type="button" class="btn btn-primary my-2 mx-1" (click)="importData()"
        [appTheme]="{'background-color':'primarycolor'}" [disabled]="disableImportBtn">IMPORT</button>

      <button *ngIf="isUpdateGoogleCalendar" type="button" class="btn btn-primary my-2 mx-1"
        (click)="updateGoogleCalendar()" [appTheme]="{'background-color':'primarycolor'}"
        [disabled]="disableUpdateBtn">UPDATE</button>

      <button type="button" class="btn btn-primary my-2 mx-1" [appTheme]="{'background-color':'primarycolor'}"
        (click)="cancel()">CANCEL</button>
    </div>
  </div>
</form>