import { Component, OnInit, AfterViewInit, ViewChild, Input, OnChanges } from '@angular/core';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { BusinessProfileService } from '../../states/business-profile.service';
import { Observable, of, Subject } from 'rxjs';
import { PrimaryCustomerCompanyState } from '../../../../../shared/models/business-profile/business/business.model';
import { map, withLatestFrom, mergeMap } from 'rxjs/operators';
import { FormCompanyComponent } from '../../forms/form-company/form-company.component';
import * as R from 'ramda';
import { objectUtil } from '../../../../../util/util';
import { NoteState } from '../../../../../shared/models/activity-timeline/activity-timeline.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';

@Component({
  selector: 'app-shareholders-directors-tab',
  templateUrl: './shareholders-directors-tab.component.html',
  styleUrls: ['./shareholders-directors-tab.component.less']
})
export class ShareholdersDirectorsTabComponent implements OnInit, OnChanges, AfterViewInit {
  private onDestroy$ = new Subject<void>();
  @Input() primaryCompany: PrimaryCustomerCompanyState;
  @Input() leadGens: ViewDisplayValue[];
  @Input() leadTypes: ViewDisplayValue[];
  @Input() leadOrigins: ViewDisplayValue[];
  @Input() adviserGenChoices: ViewDisplayValue[];
  @Input() allStaffChoices: ViewDisplayValue[];

  @Input() isLead: boolean;
  @Input() addMode: boolean;
  @ViewChild('businessLinkedContacts') shareholdersAndDirectorsTabset: TabsetComponent;

  activeTab: number;
  activeTabPeId: string;
  disableAdd: boolean;

  advisers$ = this.service.availableAdvisers$.pipe(
    map(x => R.filter(y => y.SecurityGroup === 'BO' || y.SecurityGroup === 'AM' || y.SecurityGroup === 'A', x)),
    map(x => R.map(y => ViewDisplayValue.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
    map(x => (x ? x.sort((a, b) => a.display?.localeCompare(b.display)) : x))
  );
  trustTypes$ = this.service.SCTT$;
  customerStatus$ = this.service.contactStatus$;
  customerStatusChange: string;
  PCE$: Observable<ViewDisplayValue[]> = this.service.PCE$;

  // Business Profile
  leadOriginChoices$ = this.service.PCLE$;
  leadTypeChoices$ = this.service.PCLT$;

  // Directors
  directors$: Observable<any[]> = this.service.directors$;
  // Shareholders
  shareholders$: Observable<any[]> = this.service.shareholders$;
  // Shareholders
  others$: Observable<any[]> = this.service.others$;

  directorIsLoading$ = this.service.directorIsLoading$;
  shareholderIsLoading$ = this.service.shareholderIsLoading$;
  othersIsLoading$ = this.service.othersIsLoading$;

  @ViewChild(FormCompanyComponent) formCompany: FormCompanyComponent;

  constructor(private service: BusinessProfileService) { }

  ngOnInit(): void { }

  ngOnChanges() {
    this.customerStatusChange = this.primaryCompany ? this.primaryCompany.contactStatus : '';
  }

  ngAfterViewInit(): void {
    this.shareholdersAndDirectorsTabset.tabs[0].active = true; // Set first tab active
    this.activeTab = 0; // Get and set the active tab id
  }

  addNotes$ = (note: { customerID: number; customerServiceID: number; notes: string }) => {
    return this.service.addNote({
      customerID: note.customerID,
      customerServiceID: note.customerServiceID,
      notes: note.notes,
      activityType: CustomerTypes.PrimaryCustomerCompany
    });
  }

  deleteNotes$ = (note: NoteState) => {
    return this.service.deactivateNote(note);
  }

  getNotes$ = (req: GetNotes) => {
    return this.service.getNotes(req).pipe(map(x => objectUtil.mapPascalCaseToCamelCase(x)));
  }

  updateBusiness$ = (data: any) => {
    return this.service.updateCompany(data);
  }

  selectCompany() {
    selectTab(0, this);
  }

  selectDirector() {
    selectTab(1, this);
  }

  selectShareholder() {
    selectTab(2, this);
  }

  selectOther() {
    selectTab(3, this);
  }

  updateLinkedMembers$ = data => {
    return of(data).pipe(
      withLatestFrom(this.directors$, this.shareholders$, this.others$),
      map(([, d, s, o]) => {
        const request = {
          directors: d,
          shareholders: s,
          others: o
        };
        if (data.directors) {
          request.directors = data.directors;
        }
        if (data.shareholders) {
          request.shareholders = data.shareholders;
        }
        if (data.others) {
          request.others = data.others;
        }
        return objectUtil.mapPascalCaseToCamelCase(request);
      }),
      mergeMap(x => this.service.updateLinkedMembers(x))
    );
  };
}

/**
 * Set active tab
 * @param index - index of the tab to be selected
 */
function selectTab(index: number, comp: ShareholdersDirectorsTabComponent): void {
  const activeTabPE: TabDirective = comp.shareholdersAndDirectorsTabset.tabs[index];
  if (activeTabPE) {
    activeTabPE.active = true;
    comp.activeTab = index;
  }
}
