export const documentStaticConf = {
	hrTag: `<hr class="generated-pagebreak">`,
	brTag: `<br />`,

	emailDocumentHeader: `<p><br>
			<span
				style="font-size:18pt;
				font-family:Arial;
				color:#595959;
				font-weight:400;">
					Existing Policy Structure:
			</span>
		</p>`,

	emailDocumentLabel1: `<p style="font-size: 14px;">For your reference, below is a summary of your existing insurance structure. If you have any questions or
	concerns please talk to your adviser.</p>
		<p style="font-size: 14px;">Please do not cancel any of your existing cover until you receive confirmation from your adviser that your new
		insurance structure is in place and that it is time to cancel or make adjustments to your existing cover.</p>`,

	emailFinalisedHeader: `<p>
			<span
				class="froala-text-border-bottom"
				style="font-size:18pt;
				font-family:Arial;
				color:#595959;
				font-weight:400;">
					Final Insurance Structure:
			</span>
		</p>`,

	emailFinalisedLabel1: `<p><p>
			<p style="font-size: 14px;">Please see below for confirmation of the final insurance structure we are implementing based on the terms offered by the relevant insurer(s). It is important that you understand the implications of any terms or loadings applied to your policies. Please ensure you read over this document and confirm it is correct.</p>`,

	paragraphSRNMD: (lifeAssured: string) => {
		return `<p style="font-size: 14px;">Based on the applications submitted, ${lifeAssured} has been accepted with standard rates. Please note that you have not provided any details of your medical history in your application form. If this is incorrect, please contact us, as full disclosure is important and could impact your policy at claim time.</p>`
	},

	emailFinalisedLabel2: `<p style="font-size: 14px;">If you have any questions or concerns please talk to your adviser.</p>`
};
