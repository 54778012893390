import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, iif, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { convertUtil, objectUtil } from '../../../../util/util';
import * as R from 'ramda';
import { MergeTagsMapper } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { ReviewApplicationSettingsState } from '../state/review-application-settings.model';
import { ReviewApplicationSettingsService } from '../state/review-application-settings.service';
import { ReviewApplicationSettingsMapper } from '../state/review-application-settings.mapper';

import { ReviewApplicationEmailSettingsService } from './state/review-application-email-settings.service';
import { ReviewApplicationEmailSettingsState } from './state/review-application-email-settings.model';
import { ReviewApplicationEmailSettingsMapper } from './state/review-application-email-settings.mapper';
import { appDocsMergeTag } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/documents/documents.merge-tags';

@Component({
  selector: 'app-review-application-email-settings',
  templateUrl: './review-application-email-settings.component.html'
})
export class ReviewApplicationEmailSettingsComponent implements OnInit {

  @Input() staffId: number;
  @Input() mergeTags$: Observable<MergeTagState[]>;
  @Input() emailSettings: ReviewApplicationEmailSettingsState;

  reviewApplicationEmailSettings$ = this.service.reviewApplicationEmailSettings$;
  isLoading = false;
  bodyContent = '<p></p>';
  bodyId: number;
  shortCodes: object;

  constructor(
    private service: ReviewApplicationEmailSettingsService
  ) { }

  ngOnInit(): void {
    this.prepData();
  }

  prepData() {
    combineLatest([this.reviewApplicationEmailSettings$, this.mergeTags$])
      .pipe(
        filter(([data, mt]) => !!data && !!mt),
        tap(([, mt]) => {
					const mergeTags = MergeTagsMapper.mapMergeTags(
						[...mt, ...appDocsMergeTag],
						true
					);
					this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mergeTags);
				}),
        tap(([data]) => (this.bodyId = +data?.body || 0)),
        map(([data]) =>
        ReviewApplicationEmailSettingsMapper.mapEmailSettingsData(data)
        ),
        mergeMap((data) =>
          iif(
            () => R.isNil(data?.body),
            of(null),
            this.service.getFile(+data.body)
          )
        ),
        map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
        mergeMap((res) =>
          iif(
            () => res?.documentLink,
            this.service.getDocumentFromURL(res?.documentLink),
            of(null)
          )
        ),
        tap((data) => {
          if (data) {
            this.bodyContent = data;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  save(emailSettings) {
    this.isLoading = true;
    of(emailSettings)
      .pipe(
        mergeMap((currentTemplate) => {
          return convertUtil.convertToBase64(currentTemplate?.bodyContent)
        }),
        withLatestFrom(of(this.bodyId)),
        map(([currentTemplate, bodyId]) =>
          ReviewApplicationEmailSettingsMapper.mapStaffEmailBody(
            currentTemplate,
            bodyId,
            0
          )
        ),
        concatMap((data) =>
          iif(
            () => R.isNil(data.documentId),
            this.service.newFileUpload(data).pipe(catchError((err) => of(err))),
            this.service.updateFileUpload(data)
          )
        ),
        withLatestFrom(of(this.bodyId)),
        concatMap(([data, body]) => {
          const isNewBody = body === 0 || !body;
          const newBodyId = isNewBody ? data : body;
          const formValue = ReviewApplicationEmailSettingsMapper.mapEmailSettingsToUpsert({
            ...emailSettings,
            referenceId: 0,
            body: newBodyId,
          });
          this.bodyId = newBodyId;
          return this.service.updateReviewApplicationEmailSettings(formValue);
        }),
        take(1),
        finalize(() => (this.isLoading = false)),

      )
      .subscribe();
  }

}
