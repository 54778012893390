import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CrtMortgageService } from '@modules/crm/crt-page/crt-mortgage/state/crt-mortgage.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PropertyAssetCustomerService, PropertyAssetCustomerServiceState } from 'src/app/shared/models/services/property-asset/property-asset';
import { PeopleEntitiesService } from '../../../../client-sop/people-entities/state/people-entities.service';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { PropertyPurchaseMapper } from './state/property-purchase.mapper';
import { PropertyPurchaseDetailsState } from './state/property-purchase.model';
import { PropertyPurchaseQuery } from './state/property-purchase.query';
import { PropertyPurchaseService } from './state/property-purchase.service';

@Component({
  selector: 'app-property-purchase',
  templateUrl: './property-purchase.component.html',
})
export class PropertyPurchaseComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @Input() parentCRTId: number;

  propertyOwners$ = this.peopleEntitiesService.policyOwnersWithCRT$;
	SU$ = this.service.SU$;
	SV1$ = this.service.SV1$;
	ST$ = this.service.ST$;
  STI$ = this.service.STI$;
	APCRTF$ = this.service.APCRTF$;
  adviceProcessId = this.moatQuery.getValue().adviceProcessId;
  properties$ = this.query.properties$;
  isLoading$ = this.query.selectLoading();
  potentialPurchases: PropertyAssetCustomerServiceState[] | PropertyAssetCustomerService[];
	getOwnerChoices = this.moatService.getOwnerChoices;

  form: UntypedFormGroup;

  @Input() deceasedSciList$: Observable<any[]>;

  constructor(
    private peopleEntitiesService: PeopleEntitiesService,
    private service: PropertyPurchaseService,
    private query: PropertyPurchaseQuery,
    private moatQuery: CrtMortgageQuery,
		private moatService: CrtMortgageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.service.get(this.parentCRTId).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe();

    this.service.getPotentialPurchaseList(+this.route.snapshot.params.clientId).pipe(
      tap((x) => {
        this.potentialPurchases = x;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  add = (data: PropertyPurchaseDetailsState) => {
    data.parentCRTId = this.parentCRTId;
    const processedData = PropertyPurchaseMapper.mapToPropertyDetails(data);
    return this.service.add(processedData);
  }

  update = (data: PropertyPurchaseDetailsState) => {
    data.parentCRTId = this.parentCRTId;
    const processedData = PropertyPurchaseMapper.mapToPropertyDetails(data);
    return this.service.update(processedData);
  }

  delete = (data: number) => {
    return this.service.delete(data);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

}
