import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { PaginationComponent } from 'ngx-bootstrap/pagination';

@Component({
	selector: 'app-mat-table-paginator',
	templateUrl: './mat-table-paginator.component.html',
	styleUrls: ['./mat-table-paginator.component.scss'],
})
export class MatTablePaginatorComponent {
	@ViewChild('pagination') ngxPagination: PaginationComponent;

	@Output() pageIndexChanged = new EventEmitter<number>();

	@Input() pageIndex: number;

	@Input() recordCount: number;

	@Input() pageSize = 10;

	showFirstPageNumber = false;

	showLastPageNumber = false;

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.cd.detectChanges();
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (!this.ngxPagination) {
			return;
		}
		// if AppMatTableDataSourceState pageIndex doesn't match the ngx pagination current page
		// we will update the ngx pagination UI
		if (
			simpleChanges.pageIndex &&
			simpleChanges.pageIndex.currentValue !==
				simpleChanges.pageIndex.previousValue &&
			this.ngxPagination.page - 1 !== simpleChanges.pageIndex.currentValue
		) {
			this.ngxPagination.selectPage(simpleChanges.pageIndex.currentValue);
			this._setFirstAndLastPageState();
		}
	}

	ngAfterViewInit(): void {
		this._setFirstAndLastPageState();
	}

	maxSize = 4;

	navigateByPage(page: number, event?: Event): void {
		if (page === null) {
			return;
		}
		event?.preventDefault();
		this.ngxPagination.selectPage(page, event);
	}

	next(event: Event): void {
		if (this.ngxPagination.page < this.ngxPagination.totalPages) {
			this.ngxPagination.selectPage(this.ngxPagination.page + 1, event);
		}
	}

	previous(event: Event): void {
		if (this.ngxPagination.page > 1) {
			this.ngxPagination.selectPage(this.ngxPagination.page - 1, event);
		}
	}

	pageChanged(e: { page: number; itemsPerPage: number }): void {
		this._setFirstAndLastPageState();
		this.pageIndexChanged.next(e.page - 1);
	}

	private _setFirstAndLastPageState(): void {
		// wait for the ngx paginator to update pages property
		setTimeout(() => {
			this.showFirstPageNumber =
				this.ngxPagination?.pages.findIndex((p) => p.number === 1) === -1;
			this.showLastPageNumber =
				this.ngxPagination?.pages.findIndex(
					(p) => p.number === this.ngxPagination?.totalPages
				) === -1;
			this.cd.detectChanges();
		}, 100);
	}
}
