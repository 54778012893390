import { UntypedFormGroup } from '@angular/forms';

export const patchValue: <T>(form: UntypedFormGroup, partialViewModel: Partial<T>) => void = (
  form,
  partialViewModel
) => {
  const FormValueToPatch = Object.keys(partialViewModel)?.reduce((acc, v) => {
    const notEmpty = !['', null, undefined]?.some(x => x === v);
    if (notEmpty) {
      const prop = { [v]: partialViewModel[v] };
      Object.assign(acc, prop);
    }
    return acc;
  }, {});
  form.patchValue(FormValueToPatch);
};

export const isObject = (obj) => {
  return obj === Object(obj);
};
