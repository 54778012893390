<div class="row tap-container disclosure-document pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<tab heading="Email Settings">
				<app-declaration-email-settings
					[emailSettings]="emailSettings$ | async"
					[mergeTags$]="mergeTags$"
				></app-declaration-email-settings>
			</tab>
			<tab heading="Declaration Document">
				<app-declaration-template-settings></app-declaration-template-settings>
			</tab>
			<tab heading="Client Fact Find">
				<app-loat-cff-template-settings></app-loat-cff-template-settings>
			</tab>
		</tabset>
	</div>
</div>
