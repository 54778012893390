import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { viewStaff } from '../../../viewmodels/viewStaff';
import { viewSecGroup } from '../../../viewmodels/viewSecGroup';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { BLStaffSecGroupsQuery } from '../../../../../domain/bl-staff-security-group/bl-staff-security-groups.query';
import { BLStaffsService } from '../../../../../domain/bl-staff/bl-staffs.service';
import { UserQuery } from '@domain/user/user.query';
import { delay, map, mergeMap, take, tap } from 'rxjs/operators';
import { ManageUsersListMapper } from '@modules/user/comps/manage-users/manage-users-list/states/manage-users-list.mapper';

@Component({
	selector: 'app-bl-list',
	templateUrl: './bl-list.component.html',
})
export class BlListComponent implements OnInit, OnDestroy {
	constructor(
		private blStaffQuery: BLStaffsQuery,
		private blStaffSecGroupQuery: BLStaffSecGroupsQuery,
		private blStaffsService: BLStaffsService,
		private userQuery: UserQuery
	) {}
	subs: Subscription[] = [];
	staffs: viewStaff[] = [];
	secGroups: viewSecGroup[] = [];
	adviserIdsAssigned: number[];
	isTapLevel: boolean;
	secGroups$ = this.blStaffSecGroupQuery.selectAll();
	userInfo$ = this.userQuery.userInfo$;
	userSecurityGroups$ = this.userQuery.userSecurityGroup$;

	ngOnInit() {
		const s = this.blStaffQuery.getAll();
		const sg = this.blStaffSecGroupQuery.getAll();

		this.userQuery.userInfo$
			.pipe(
				tap((x) => {
					this.adviserIdsAssigned = x?.AdviserIdsAssigned || [];
					this.isTapLevel = this.userQuery.isTapLevel();
					this.staffs = this.isTapLevel
						? viewStaff.mapStaffs(s)
						: this.getFilteredStaffs(s);
					this.secGroups = viewSecGroup.mapSecGroups(sg);
				}),
				take(1)
			)
			.subscribe();
	}

	getFilteredStaffs(staffs) {
		const filteredStaffs = (staffs || [])?.filter((x) =>
			this.adviserIdsAssigned?.includes(+x?.StaffID)
		);
		return viewStaff.mapStaffs(filteredStaffs);
	}

	deleteBlStaff(id: number) {
		this.blStaffsService.delete(id).subscribe(() => {
			const i = this.blStaffQuery.getAll();
			this.staffs = this.getFilteredStaffs(i);
		});
	}

	saveColumnSettingsFn$ = (columns) =>
		this.userQuery.userInfo$.pipe(
			map((user) => ManageUsersListMapper.mapToUpsertColumn(user, columns)),
			mergeMap((user) => this.blStaffsService.update(user, true)),
			delay(100),
			mergeMap((user) => this.blStaffsService.getBlStaffById(+user?.StaffID)),
			take(1)
		);

	saveColumnWidthSettingsFn$ = (col) =>
		this.userQuery.userInfo$.pipe(
			map((user) => ManageUsersListMapper.mapToUpsertColWidth(user, col)),
			mergeMap((x) => this.blStaffsService.update(x, true)),
			delay(100),
			mergeMap((x) => this.blStaffsService.getBlStaffById(+x?.StaffID)),
			take(1)
		);

	ngOnDestroy(): void {
		this.subs?.forEach((s) => s.unsubscribe());
	}
}
