import * as R from 'ramda';
import { MergeTagState } from '../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { DefaultFileNames } from '../../../../../shared/models/documents/document.model';
import MomentUtil from '../../../../../util/moment.util';
import { SettingsTypes } from '../../../state/mortgage-settings.model';
import { DeclarationSettingState } from './declaration-template-settings.model';

export class DeclarationDocumentMapper {
  public static mapMergeTags(data: MergeTagState[]) {
    return data?.map((item) =>
      item?.metaKey === 'DATE_TODAY'
        ? {
            ...item,
            value: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
            secondaryValue: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
          }
        : item
    );
  }

  public static mapDocumentUpload(document, template) {
    if (template === 0 || isNaN(template) || R.isNil(template) || R.isEmpty(template)) {
      return {
        document,
        referenceId: 0,
        fileName: DefaultFileNames.Declaration,
        type: SettingsTypes.Declaration,
      };
    }
    return {
      document,
      documentId: +template,
    };
  }

  public static mapEmailSettingsToUpsert(data: DeclarationSettingState) {
    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: SettingsTypes.Declaration,
    };
  }
}
