import { LeadSearchUiQuery } from './lead-search-ui.query';
import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { LeadSearchStore } from './lead-search.store';
import { LeadSearchQuery } from './lead-search.query';
import {
	LeadSearchRequest,
	LeadSearchResponse,
} from '../lead-search-request.model';
import {
	tap,
	mergeMap,
	filter,
	withLatestFrom,
	take,
	finalize,
	map,
	shareReplay,
	concatMap,
	reduce,
	switchMap,
	catchError,
	retry,
} from 'rxjs/operators';
import { Observable, of, concat, forkJoin, from, throwError } from 'rxjs';
import { applyTransaction } from '@datorama/akita';
import {
	Metakey,
	createRowFromPrototype,
	columns,
} from '../lead-search-page/lead-search-datatable.config';
import { produce } from 'immer';
import * as moment from 'moment';
import {
	ApiService,
	JsonResultStatus,
} from '../../../../core/base/api.service';
import { UserQuery } from '../../../../domain/user/user.query';
import { UserStore } from '../../../../domain/user/user.store';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { StaffSettings } from '../../../../shared/models/_general/staff-settings.model';
import MomentUtil from '../../../../util/moment.util';
import { LoggerService } from '../../../../core/logger/logger.service';
import { ConfigService } from '../../../../core/config/config.service';
import { ActivityService } from '../../../../core/services/activity/activity.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { Transfer } from '@modules/transfer/state/transfer.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Injectable()
export class LeadSearchService {
	constructor(
		protected api: ApiService,
		protected userQuery: UserQuery,
		protected userStore: UserStore,
		protected configService: ConfigService,
		protected leadSearchStore: LeadSearchStore,
		protected leadSearchQuery: LeadSearchQuery,
		protected leadSearchUiQuery: LeadSearchUiQuery,
		protected businessConfigQuery: BusinessConfigQuery,
		private activityService: ActivityService,
		private loggerService: LoggerService
	) {}

	index = 0;
	clear(): void {
		applyTransaction(() => {
			this.leadSearchStore.reset();
			this.leadSearchStore.uiStore.reset();
		});
	}

	search2(req: LeadSearchRequest) {
		this.leadSearchStore.uiStore.setSort(null, null);
		const batchLength = 500;

		const getIndexesToFetch: (res: LeadSearchResponse) => number[] = R.pipe(
			(res: LeadSearchResponse) => Math.ceil(res.TotalCount / batchLength - 1),
			(totalPages: number) =>
				Array(totalPages)
					?.fill(1)
					?.map((x, i) => i + 1)
					?.slice(1)
		);
		const searchRequest = (request: LeadSearchRequest) =>
			this.api.post3<LeadSearchResponse>('search/clients/lead', request);

		const firstPage$ = of(req).pipe(
			map(
				produce((draft) => {
					draft.Paging = {
						Index: 2,
						Column: req.Paging.Column,
						Direction: req.Paging.Direction,
					};
				})
			),
			mergeMap(searchRequest),
			shareReplay()
		);

		return firstPage$.pipe(
			mergeMap((res) =>
				concat(
					firstPage$,
					from(getIndexesToFetch(res)).pipe(
						map((i) =>
							produce(req, (draft) => {
								draft.Paging = {
									Index: i + 1,
									Column: req.Paging.Column,
									Direction: req.Paging.Direction,
								};
							})
						),
						concatMap((req2) => searchRequest(req2))
					)
				)
			),
			reduce((acc, v) =>
				produce(acc, (draft) => {
					draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
					draft.IsComplete = true;
				})
			),
			map((res) =>
				applyTransaction(() => {
					return res;
				})
			)
		);
	}

	search(req: LeadSearchRequest) {
		this.leadSearchStore.uiStore.setIsSearching(true);

		return this.api.post3<LeadSearchResponse>('search/clients/lead', req).pipe(
			withLatestFrom(this.leadSearchQuery.templateRow$),
			switchMap(([x, templateRow]) => {
				if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
					// Saves initial fetch
					const rows: any[] = R.map(
						createRowFromPrototype(templateRow),
						x.SearchResults
					);
					this.leadSearchStore.set([]);
					this.leadSearchStore.set([...rows]);
					return this.search2(req);
				}
				return of(x);
			}),
			withLatestFrom(
				this.leadSearchQuery.templateRow$,
				this.leadSearchUiQuery.isSelectAll$,
				(res, template, isSelectAll) => ({
					response: res,
					templateRow: template,
					isSelectAll,
				})
			),
			map((res) =>
				applyTransaction(() => {
					let rows: any[] = R.map(
						createRowFromPrototype(res.templateRow),
						res.response.SearchResults
					);

					if (
						req.Paging.Index === 1 &&
						this.leadSearchQuery.getCount() !== 0 &&
						res.response.TotalCount > 500
					) {
						this.leadSearchStore.set([]);
						res.response.IsComplete = false;
					}

					const a = this.leadSearchQuery.getAll();

					rows = rows.map((row, index) => ({
						...row,
						bulk: !!row?.bulk
							? {
									...row.bulk,
									value: a.length < Transfer.LIMIT ? res.isSelectAll : false,
							  }
							: row.bulk,
					}));

					if (
						req.Paging.Index === 1 &&
						res.response.IsComplete &&
						res.response.TotalCount <= 500
					) {
						this.leadSearchStore.set([]);
						this.leadSearchStore.set([...rows]);
					} else {
						this.leadSearchStore.set([...a, ...rows]);
					}

					this.leadSearchStore.update((state) => ({
						...state,
						count: res.response.TotalCount,
						totalAPI: res.response.TotalAPI,
						isComplete: res.response.IsComplete,
					}));

					return res.response;
				})
			),
			finalize(() => {
				this.leadSearchStore.setSearchForm(req);
				this.leadSearchStore.uiStore.setIsSearching(false);
			})
		);
	}

	reloadData() {
		const data = this.leadSearchQuery.getAll();
		this.leadSearchStore.set([]);
		this.leadSearchStore.set([...data]);
	}

	export(req: LeadSearchRequest): Observable<any> {
		return this.api.post4('export/clients/lead', req).pipe(
			map((x) => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			retry(1),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.shared.export.error);
				return throwError(err);
			})
		);
	}

	tryParseJSON(jsonString: any): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}

	getColumns(): Observable<any> {
		return this.leadSearchQuery
			.select((x) => x.columns)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				withLatestFrom(
					this.userQuery.staffSettings$,
					this.userQuery.isUserWithOptFields$,
					this.businessConfigQuery?.bulkTransferFeature$('L'),
					this.userQuery.canBulkTransfer$
				),
				map(
					([
						,
						staffSettings,
						isUserWithOptionFields,
						bulkTransferFeature,
						canBulkTransfer,
					]) => {
						const columns = !isUserWithOptionFields
							? JSON.parse(staffSettings?.LeadSearchColumns) ?? []
							: (
									(JSON.parse(staffSettings?.LeadSearchColumns) as string[]) ??
									[]
							  ).filter(
									(x) =>
										x !== 'Mobile' && x !== 'Email' && x !== 'Physical Address'
							  );
						if (bulkTransferFeature && canBulkTransfer) {
							return ['bulk', ...columns];
						} else {
							return columns;
						}
					}
				),
				tap((x) => {
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.leadSearchStore.setColumns(x)
						: null;
				})
			);
	}

	getColumnWidths(): Observable<any> {
		return this.leadSearchQuery
			.select((x) => x.columnWidths)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				withLatestFrom(
					this.userQuery.staffSettings$,
					this.userQuery.isUserWithOptFields$
				),
				map(([, staffSettings, isUserWithOptionFields]) =>
					!isUserWithOptionFields
						? JSON.parse(staffSettings?.LeadSearchColumnsWidth) ?? []
						: (
								(JSON.parse(staffSettings?.LeadSearchColumnsWidth) as {
									metakey: string;
									width: number;
								}[]) ?? []
						  ).filter(
								(x) =>
									x?.metakey !== 'Mobile' &&
									x?.metakey !== 'Email' &&
									x?.metakey !== 'Physical Address'
						  )
				),
				tap((x) =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.leadSearchStore.setColumnWidths(x)
						: null
				)
			);
	}

	reorderColumn = (oldIndex: number, newIndex: number) => {
		const oldCol = this.leadSearchQuery.getValue().columns;
		const newCol = produce(oldCol, (draft) => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});
		const newColWith = produce(
			this.leadSearchQuery.getValue().columnWidths,
			(draft) => {
				const movedCol = draft?.splice(oldIndex, 1);
				draft?.splice(newIndex, 0, movedCol[0]);
			}
		);
		const newColData = newCol?.filter((x) => x !== 'bulk');
		return of(newColData).pipe(
			tap(() => {
				this.leadSearchStore.setColumns(newCol);
				this.leadSearchStore.setColumnWidths(newColWith);
			}),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					LeadSearchColumns: JSON.stringify(newVal),
					LeadSearchColumnsWidth: JSON.stringify(newColWith),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			)
		);
	};

	resizeColumn(prop: string, width: number): Observable<any> {
		const oldColWidths = this.leadSearchQuery
			.getValue()
			.columnWidths?.filter((x) => x);
		const newColWidths = produce(oldColWidths, (draft) => {
			const col = columns?.find((x) => x.prop === prop);
			const exists = draft?.some((x) => col.metakey === x.metakey);
			if (exists) {
				draft.find((x) => col.metakey === x.metakey).width = width;
			} else {
				draft.push({ metakey: col.metakey, width });
			}
		});

		return of(newColWidths).pipe(
			tap((x) => this.leadSearchStore.setColumnWidths(x)),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					LeadSearchColumnsWidth: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)
			)
		);
	}

	saveVisibleColumns = (metakeys: Metakey[]) => {
		const newColumns = metakeys;
		const oldColumns = this.leadSearchQuery.getValue().columns;

		if (R.equals(newColumns, oldColumns)) {
			return of();
		}

		this.leadSearchStore.uiStore.setIsColumnSaving(true);

		const newColumnMetakeys = newColumns;

		return of(newColumnMetakeys).pipe(
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					LeadSearchColumns: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			),
			withLatestFrom(
				this.businessConfigQuery?.bulkTransferFeature$('L'),
				this.userQuery.canBulkTransfer$
			),
			finalize(() => this.leadSearchStore.uiStore.setIsColumnSaving(false)),
			tap(([_, bulkTransferFeature, canBulkTransfer]) => {
				if (bulkTransferFeature && canBulkTransfer) {
					this.leadSearchStore.setColumns(['bulk', ...newColumnMetakeys]);
				} else {
					this.leadSearchStore.setColumns(newColumnMetakeys);
				}
			})
		);
	};

	saveField(req: {
		CustomerId: number;
		MetaKey: Metakey;
		MetaValue: string;
	}): Observable<JsonResultStatus> {
		this.leadSearchStore.uiStore.setLoad(req.CustomerId, req.MetaKey, true);
		return this.api
			.put<JsonResultStatus>(
				`contacts/${req.CustomerId}?isPatch=true`,
				R.omit(['CustomerId'], req)
			)
			.pipe(
				tap(() =>
					applyTransaction(() => {
						if (req.MetaKey === 'Alt. Adviser') {
							req.MetaValue = JSON.stringify(
								JSON.parse(req.MetaValue).map((val) => val.toString())
							);
						}
						this.leadSearchStore.updateField(req);
						this.leadSearchStore.uiStore.setEdit(
							req.CustomerId,
							req.MetaKey,
							false
						);
						this.setTempValue(req.CustomerId, req.MetaKey, undefined);
					})
				),
				finalize(() =>
					this.leadSearchStore.uiStore.setLoad(
						req.CustomerId,
						req.MetaKey,
						false
					)
				)
			);
	}

	edit = (customerId: number, metakey: Metakey) =>
		this.leadSearchStore.uiStore.setEdit(customerId, metakey, true);
	cancel = (customerId: number, metakey: Metakey) =>
		applyTransaction(() => {
			this.leadSearchStore.uiStore.setEdit(customerId, metakey, false);
			this.setTempValue(customerId, metakey, undefined);
		});
	updateNote = (customerId: number, value: string) => {
		this.leadSearchStore.update(
			customerId,
			produce((draft) => {
				const nzToday = MomentUtil.createMomentNz().format('DD/MM/YYYY');
				const ln =
					nzToday +
					' - ' +
					this.userQuery.getValue().FirstName +
					' ' +
					this.userQuery.getValue().LastName +
					' - ' +
					value;
				draft.LastNote.value = ln;
			})
		);
	};

	updateClientAndUserNextActivity = (customerId: number) => {
		return forkJoin(
			this.updateClientNextActivity(customerId),
			this.updateUserNextActivity(customerId)
		);
	};

	updateClientNextActivity = (customerId: number) => {
		this.leadSearchStore.uiStore.setLoad(
			customerId,
			'Client Next Activity',
			true
		);

		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/customer`, {
				nextActivityOnly: true,
			})
			.pipe(
				tap((x) => {
					this.leadSearchStore.update(
						customerId,
						produce((draft) => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.ClientNextActivityId = x.ActivityId;
								// tslint:disable-next-line: max-line-length
								draft.ClientNextActivity.value =
									formattedDate +
									' - ' +
									x.AssignedToAdviserName +
									' - ' +
									x.ActivityType +
									' - ' +
									x.ActivityName;
							} else {
								draft.ClientNextActivityId = null;
								draft.ClientNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() =>
					this.leadSearchStore.uiStore.setLoad(
						customerId,
						'Client Next Activity',
						false
					)
				)
			);
	};

	updateUserNextActivity = (customerId: number) => {
		this.leadSearchStore.uiStore.setLoad(
			customerId,
			'User Next Activity',
			true
		);

		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/adviser`, {
				nextActivityOnly: true,
			})
			.pipe(
				tap((x) => {
					this.leadSearchStore.update(
						customerId,
						produce((draft) => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.UserNextActivityId = x.ActivityId;
								draft.UserNextActivity.value =
									formattedDate +
									' - ' +
									x.ActivityType +
									' - ' +
									x.ActivityName;
							} else {
								draft.UserNextActivityId = null;
								draft.UserNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() =>
					this.leadSearchStore.uiStore.setLoad(
						customerId,
						'User Next Activity',
						false
					)
				)
			);
	};

	createClientNextActivity = (ac: ActivityViewModel) =>
		of(ActivityViewModel.MapToAdd(ac)).pipe(
			mergeMap((x) => this.activityService.Post(x)),
			mergeMap(
				(y) => {
					if (y) {
						return this.updateClientAndUserNextActivity(ac.Customer.CustomerId);
					}
				},
				(o) => o
			)
		);

	openPopup = () => this.leadSearchStore.uiStore.toggleColumnPopup(true);
	closePopup = () => this.leadSearchStore.uiStore.toggleColumnPopup(false);
	togglePopup = () =>
		this.leadSearchStore.uiStore.toggleColumnPopup(
			!this.leadSearchQuery.uiStore.getValue().columnFormPopupOpen
		);

	delete(customerId: number): Observable<any> {
		return of(customerId).pipe(
			tap(() => this.leadSearchStore.uiStore.setIsDeleting(customerId, true)),
			mergeMap((id) => this.api.delete<JsonResultStatus>(`contacts/${id}`)),
			finalize(() =>
				this.leadSearchStore.uiStore.setIsDeleting(customerId, false)
			),
			tap(() => {
				this.leadSearchStore.remove(customerId);
			})
		);
	}

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.leadSearchStore.uiStore.setSort(propSort, sort);
	}

	setTempValue = (customerId: number, metakey: string, value: any) =>
		this.leadSearchStore.uiStore.setTempValue(customerId, metakey, value);
}
