import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlSecGroupsResolver } from '../../resolver/bl/bl-sec-groups-resolver.service';
import { BlStaffResolver } from '../../resolver/bl/bl-staff-resolver.service';
import { BlStaffsResolver } from '../../resolver/bl/bl-staffs-resolver.service';
import { BlListComponent } from '../../views/bl/bl-list/bl-list.component';
import { BlViewComponent } from '../../views/bl/bl-view/bl-view.component';
import { BlAddComponent } from '../../views/bl/bl-add/bl-add.component';
import { BlActiveStaffResolver } from '../../resolver/bl/bl-active-staff-resolver.service';
import { ServicesResolverService } from '../../resolver/bl/services-resolver.service';
import { UserSettingsResolver } from '../../resolver/bl/user-settings-resolver.service';
import { BlStaffSettingsResolver } from '../../resolver/bl/bl-staff-settings-resolver.service';

const routes: Routes = [
	{
		// BL add
		path: 'add',
		data: {
			breadcrumb: 'Add User',
		},
		component: BlAddComponent,
		resolve: {
			userSettings: UserSettingsResolver,
			secGroups: BlSecGroupsResolver,
			activeStaffs: BlStaffsResolver,
			services: ServicesResolverService,
		},
	},
	{
		path: ':id',
		resolve: {
			secGroups: BlSecGroupsResolver,
			services: ServicesResolverService,
		},
		children: [
			{
				// BL view
				path: '',
				data: {
					breadcrumb: 'User Details',
				},
				component: BlViewComponent,
				resolve: {
					staff: BlStaffResolver,
					userSettings: UserSettingsResolver,
				},
			},
		],
	},
	{
		// BL view
		path: '',
		pathMatch: 'full',
		component: BlListComponent,
		resolve: {
			// staffs: BlStaffsResolver,
			secGroups: BlSecGroupsResolver,
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [
		BlSecGroupsResolver,
		BlStaffResolver,
		BlStaffSettingsResolver,
		BlStaffsResolver,
		BlActiveStaffResolver,
		ServicesResolverService,
		UserSettingsResolver,
	],
})
export class BlUserRoutingModule {}
