import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PropertyStore, PropertyAddressState } from './property.store';

@Injectable()
export class PropertyQuery extends QueryEntity<PropertyAddressState> {
	properties$ = this.selectAll({ filterBy: (x) => !!x.status });
	isLoading$ = this.selectLoading();

	constructor(protected store: PropertyStore) {
		super(store);
	}
}
