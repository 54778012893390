import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlidesComponent } from './slides.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SlideComponent } from './slide/slide.component';

const components = [SlidesComponent, SlideComponent];

@NgModule({
	declarations: components,
	exports: components,
	imports: [CommonModule, CarouselModule],
})
export class SlidesModule {}
