<div
	class="form-row align-items-center mb-2"
	*ngFor="let d of documents; trackBy: trackByDocument"
>
<div class="col-md-4">{{ d?.field }}</div>

	<div class="col-md d-flex align-items-center">
		<!-- Online Advice Process Buttons -->
		<ng-container *ngIf="hasCRT && adviceProcess?.isOnline; else offlineMode">
			<ng-container
				*ngIf="d?.field !== documentFields.ConfirmationCall; else confirmationCallTemplate"
			>
				<ng-container *ngIf="!!d.value; else onlineProcessNoDocument">
					<ng-container *ngIf="d?.field === documentFields.FactFind && hasLOATV2; else notFactFind">
						<button type="button" class="tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0" (click)="
										downloadFile(d?.value?.documentID, d?.field, d.value.documentName)
									">
							View
						</button>
					</ng-container>

					<ng-template #notFactFind>
						<button *ngIf="
									(d?.field !== documentFields.FactFind) ||
									(d?.field === documentFields.SOA && !!hasFinalized) ||
									(d?.field === documentFields.FinalStructure &&
										!!finalStructure.IsEmailSent &&
										isEditable)

								" type="button" class="tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0" (click)="
									downloadFile(d?.value?.documentID, d?.field, d.value.documentName)
								">
							View
						</button>
					</ng-template>
					<button
						*ngIf="
											 d?.field !== documentFields.FactFind &&
											 d?.field !== documentFields.SOA &&
											 d?.field !== documentFields.AuthorityToProceed &&
											 isEditable
											 "
						type="button"
						class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
						(click)="openUploadModal('Replace', d?.field)"
					>
						Replace
					</button>
					<button
						*ngIf="d?.field === documentFields.SOA && isEditable"
						type="button"
						class="tap-btn tap-btn--default mr-1 w-40 px-sm-0"
						[routerLink]="goToCrtPage(d?.field)"
					>
						{{ d?.field === documentFields.SOA  ? "Open" : "Edit" }}
					</button>
					<button
						*ngIf="d?.field == documentFields.FactFind"
						type="button"
						class="tap-btn tap-btn--default mr-1 w-40 px-sm-0"
						[routerLink]="goToCrtPage(d?.field)"
					>
						Open
					</button>
				</ng-container>
			</ng-container>

			<ng-template #confirmationCallTemplate>
				<ng-container *ngIf="hasFinalStructure && isEditable">
					<ng-container *ngIf="showConfirmationCallButtons$ | async">
						<ng-container [ngSwitch]="d?.value?.status">
							<ng-container
								*ngSwitchCase="comfirmationCallDocumentStatus.NetYetRequested"
							>
								<button
									type="button"
									class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
									(click)="openConfirmationCallModal()"
								>
									Request
								</button>
							</ng-container>

							<ng-container
								*ngSwitchCase="comfirmationCallDocumentStatus.Requested"
							>
								<button
									*ngIf="hasFinalStructure"
									type="button"
									class="tap-btn tap-btn--primary w-40 mr-1 px-sm-0 text-white"
									(click)="openCompletionCallModal(d.value)"
									[disabled]="completeModalIsLoading"
								>
									Complete
								</button>
								<button
									type="button"
									class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
									(click)="openConfirmationCallModal()"
									[disabled]="completeModalIsLoading"
								>
									New Request
								</button>
							</ng-container>

							<ng-container
								*ngSwitchCase="comfirmationCallDocumentStatus.Completed"
							>
								<button
									type="button"
									class="tap-btn tap-btn--primary w-40 mr-1 px-sm-0 text-white"
									[disabled]="completeModalIsLoading"
									(click)="openCallAgainModal(d.value)"
								>
									Call Again
								</button>
								<button
									type="button"
									class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
									(click)="openConfirmationCallModal()"
									[disabled]="completeModalIsLoading"
								>
									New Request
								</button>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="hasFinalStructure && !isEditable && 
				d?.value?.status === comfirmationCallDocumentStatus.Completed">
					<ng-container *ngIf="showConfirmationCallButtons$ | async">
						<button
						type="button"
						class="tap-btn tap-btn--primary w-40 mr-1 px-sm-0 text-white"
						[disabled]="completeModalIsLoading"
						(click)="openCallViewModal(d.value)"
						>
							View
						</button>
					</ng-container>
				</ng-container>
			</ng-template>

			<ng-template #onlineProcessNoDocument>
				<ng-container *appRestrictTo="['FEAP']">
					<ng-container>
						<ng-container *ngIf="adviceProcess.isStarted">
							<ng-container *appRestrictTo="['FCRT']">
								<ng-container
										*ngIf="d?.field === documentFields.CARFinalisedStructure && d?.showCreateButton"
									>
										<button
											type="button"
											title="Create Final Structure"
											class="tap-btn tap-btn--default text-truncate mr-1 px-xs-0"
											(click)="navigateToCreateUpdatedStructure()"
										>
											Create Updated Structure
										</button>
								</ng-container>
								<ng-container
									*ngIf="
										d?.field === documentFields.FinalStructure && isEditable;
										else defaultNoDocumentButtons
									"
								>
									<ng-container *ngIf="!!d?.show">
										<button
											type="button"
											title="Create Final Structure"
											class="tap-btn tap-btn--default text-truncate mr-1 px-xs-0"
											[routerLink]="goToCrtPage(d?.field)"
										>
											Create Final Structure
										</button>
									</ng-container>
								</ng-container>
								<ng-template #defaultNoDocumentButtons>
									<button
										*ngIf="
											d?.field === documentFields.FactFind &&
											(isEditable ||
												(adviceProcess?.status > 2 && adviceProcess?.status < 5))
										"
										type="button"
										class="tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0"
										[routerLink]="goToCrtPage(d?.field)"
									>
										{{ d?.field == documentFields.FactFind || (isEditable && !!d?.show) ? "Open" : "View" }}
									</button>
									<button
										*ngIf="
											d?.field !== documentFields.FactFind &&
											d?.field !== documentFields.FinalStructure &&
											!!d?.show &&
											isEditable
										"
										type="button"
										class="tap-btn tap-btn--default mr-1 w-40 px-sm-0"
										[routerLink]="goToCrtPage(d?.field)"
									>
										Edit
									</button>
								</ng-template>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-template>
		</ng-container>

		<!-- Offline Advice Process Buttons -->
		<ng-template #offlineMode>
			<div class="w-100">
				<app-offline-documents
					[d]="d"
					[isEditable]="isEditable"
					(doDownload)="
						downloadFile(d?.value?.documentID, d?.field, d.value.documentName)
					"
					(doUpload)="openUploadModal('Upload New', d?.field)"
					(doLinkDocument)="linkDocument(d?.field)"
				>
				</app-offline-documents>
			</div>
		</ng-template>
	</div>
</div>
