import {
	Component,
	OnInit,
	Renderer2,
	OnDestroy,
	Output,
	EventEmitter,
	AfterViewInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ClientSearchRequest } from '../client-search-request.model';
import { ClientSearchQuery } from '../state/client-search.query';
import { ClientSearchService } from '../state/client-search.service';
import {
	map,
	combineLatest,
	takeUntil,
	first,
	finalize,
	withLatestFrom,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ClientSearchUiQuery } from '../state/client-search-ui.query';
import { request } from '../client-search.util';
import { ClientSearchUiStore } from '../state/client-search-ui.store';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { Service } from '../../../../domain/service/service.model';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { ServicesQuery } from '../../../../domain/service/services.query';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { UserQuery } from '../../../../domain/user/user.query';
import { RouteService } from '../../../../core/config/route.service';
import { strUtil } from '../../../../util/util';
import MomentUtil from '../../../../util/moment.util';
import { ActivatedRoute } from '@angular/router';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { AdviserStatusViewDisplay } from '../../pipeline/pipeline.model';
import { ExportsService } from '@modules/exports/state/exports.service';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsStatus, ExportsType } from '@modules/exports/state/exports.model';

declare var $: any;

@Component({
	selector: 'app-client-search-form',
	templateUrl: './client-search-form.component.html',
	styleUrls: ['./client-search-form.component.scss'],
})
export class ClientSearchFormComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	onDestroy$: Subject<void> = new Subject<void>();

	exportStatus$ = this.exportsQuery.status$;

	@ViewChild(PopoverDirective) popover: PopoverDirective;
	@ViewChild('serviceSelect') serviceSelect;

	readonly contactStatusChoices = [
		{ display: 'Current Client', value: 'C' },
		{ display: 'Ex-Client', value: 'X' },
	];
	readonly contactTypeChoices = [
		{ display: 'People', value: CustomerTypes.PrimaryCustomerIndividual },
		{ display: 'Business', value: CustomerTypes.PrimaryCustomerCompany },
	];
	availableStaffChoices$: Observable<ViewDisplayValue[]> =
		this.bLStaffsQuery.adviserChoicesOption$;

	staffChoices$: Observable<ViewDisplayValue[]> =
		this.bLStaffsQuery.availableStaffsChoices$.pipe(
			map((x) =>
				x ? x?.sort((a, b) => a.display?.localeCompare(b.display)) : x
			)
		);

	adviserStatus$: Observable<ViewDisplayValue[]> = of(AdviserStatusViewDisplay);
	PCLE$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('PCLE');
	AT$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('AT');
	private availableServices$ = this.businessConfigQuery.businessServices$;
	services$: Observable<ViewDisplayValue[]> = this.serviceQuery
		.selectAll()
		.pipe(
			withLatestFrom(
				this.businessConfigQuery.businessServices$,
				this.userQuery.services$
			),
			map(([allServices, bServices, uServices]) => {
				const userServices = (uServices as string[]).filter((us) =>
					bServices.some((bs) => bs === us)
				);
				const allowedServices = this.userQuery.isTapLevel()
					? bServices
					: bServices.filter((x) => userServices.includes(x));

				return (
					allServices?.filter((type) =>
						allowedServices.includes(type.ServiceCode)
					) || []
				);
			}),
			map((x) =>
				// Hide other services
				x?.filter(
					(sc) =>
						![
							ServicesCodes.ClientReviewTemplate.toString(),
							ServicesCodes.AdviceProcess.toString(),
							ServicesCodes.MortgageOnlineAdviceTool.toString(),
							ServicesCodes.CustomerPortalMOAT.toString(), // TAPNZ-11243
							ServicesCodes.KiwisaverOnlineAdviceTool.toString(), // TAPNZ-11943
							ServicesCodes.CustomerPortalMOATDocuments.toString(), // TAPNZ-12599
							ServicesCodes.ClientAlterationRequest.toString(), // TAP1-2035
						].includes(sc.ServiceCode)
				)
			),
			// map to viewdisplayvalue
			map((x: Service[]): ViewDisplayValue[] =>
				x?.map((y) => ViewDisplayValue.Map(y.ServiceCode, y.Service))
			),
			map((x: ViewDisplayValue[]) =>
				x?.map((y) =>
					y.display?.toLowerCase() === 'kiwisaver'
						? { ...y, display: 'KiwiSaver' }
						: y
				)
			),
			// filter to available services only
			combineLatest(this.availableServices$),
			map(([choices, availableServices]) =>
				choices?.filter((x) => availableServices?.includes(x.value))
			)
		);

	isTapLevel$ = this.userQuery.isTapLevel$;
	userInfo$ = this.userQuery.userInfo$;

	form: UntypedFormGroup = this.fb.group({
		Advisers: this.fb.control([]),
		AltAdvisers: this.fb.control([]),
		FirstName: this.fb.control(null),
		LastName: this.fb.control(null),
		AdviserStatuses: this.fb.control([]),
		LeadOrigins: this.fb.control([]),
		Services: this.fb.control([]),
		ContactStatus: this.fb.control([]),
		ContactTypes: this.fb.control([]),
		NextActivityTypes: this.fb.control([]),
		NextActivityDateMin: this.fb.control(null),
		NextActivityDateMax: this.fb.control(null),
		NextActivityAssignedTo: this.fb.control(null),
		// LastReviewDateMin: this.fb.control(null),
		// LastReviewDateMax: this.fb.control(null),
		NextReviewDateMin: this.fb.control(null),
		NextReviewDateMax: this.fb.control(null),
		ClientSinceDateMin: this.fb.control(null),
		ClientSinceDateMax: this.fb.control(null),
		NextReviewServiceCode: this.fb.control(''),
	} as { [key in keyof ClientSearchRequest]: any });
	count$ = this.clientSearchQuery.count$;

	isSearching$ = this.clientSearchQuery.uiStore.isSearching$;
	isExporting$ = this.clientSearchQuery.uiStore.isExporting$;
	columnFormOpen$ = this.clientSearchQuery.uiStore.columnFormPopupOpen$;
  adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

	openColumnPopup = this.clientSearchService.togglePopup;

	private routeChanges$ = this.routeService.RouteChange$;
	public customerAddLink$ = this.routeChanges$.pipe(map((x) => x.customerAdd));
	public businessAddLink$ = this.routeChanges$.pipe(map((x) => x.businessAdd));

	@Output() toggleSearch = new EventEmitter<{
		showSearch: boolean;
		height: number;	
		width: number;
	}>();
	showSearch = false;

	////////// Progress Bar //////////////
	progress = {
		width: '0%',
	};
	index = 0;
	percent = 0;
	count = 0;
	totalCount = 0;
	modalRef: BsModalRef;
	msg = 'Export Progress';
	///////// END Progress Bar ////////

	services = [
		{
			display: 'All',
			value: 'ALL'
		},
		{
			display: 'L&R',
			value: 'LR'
		},
		{
			display: 'Mortgage',
			value: 'M'
		},
		{
			display: 'F&G',
			value: 'FG'
		},
		{
			display: 'Investment',
			value: 'I'
		},
	];

	constructor(
		private renderer: Renderer2,
		private dropdownValueQuery: DropdownValueQuery,
		private bLStaffsQuery: BLStaffsQuery,
		private fb: UntypedFormBuilder,
		private clientSearchQuery: ClientSearchQuery,
		private clientSearchService: ClientSearchService,
		private serviceQuery: ServicesQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery,
		private routeService: RouteService,
		private modalService: BsModalService,
		private clientSearchUiQuery: ClientSearchUiQuery,
		private clientSearchUiStore: ClientSearchUiStore,
		private activitedRoute: ActivatedRoute,
		private exportsService: ExportsService,
		private exportsQuery: ExportsQuery,
	) {}

	ngOnInit() {
		this.form.get('ContactStatus').setValue(['C']);
	}

	ngAfterViewInit() {
		const data = {
			showSearch: false,
			height: $('form').height(),
			width: $('form').width(),
		};
		this.toggleSearch.emit(data);

		if (
			this.clientSearchQuery.getValue().count > 0 ||
			this.clientSearchQuery.getValue().searchForm
		) {
			const fname = this.clientSearchQuery.getValue().searchForm.FirstName;
			const lname = this.clientSearchQuery.getValue().searchForm.LastName;

			const advisers = this.clientSearchQuery.getValue().searchForm.Advisers;
			const altAdvisers =
				this.clientSearchQuery.getValue().searchForm.AltAdvisers;
			const lo = this.clientSearchQuery.getValue().searchForm.LeadOrigins;
			const naT =
				this.clientSearchQuery.getValue().searchForm.NextActivityTypes;
			const naAT =
				this.clientSearchQuery.getValue().searchForm.NextActivityAssignedTo;
			const cs = this.clientSearchQuery.getValue().searchForm.ContactStatus;
			const ads = this.clientSearchQuery.getValue().searchForm.AdviserStatuses;
			const s = this.clientSearchQuery.getValue().searchForm.Services;
			const ct = this.clientSearchQuery.getValue().searchForm.ContactTypes;

			const naMin =
				this.clientSearchQuery.getValue().searchForm.NextActivityDateMin;
			const naMax =
				this.clientSearchQuery.getValue().searchForm.NextActivityDateMax;
			const csMin =
				this.clientSearchQuery.getValue().searchForm.ClientSinceDateMin;
			const csMax =
				this.clientSearchQuery.getValue().searchForm.ClientSinceDateMax;
			const nrMin =
				this.clientSearchQuery.getValue().searchForm.NextReviewDateMin;
			const nrMax =
				this.clientSearchQuery.getValue().searchForm.NextReviewDateMax;
			// const lrMin = this.clientSearchQuery.getValue().searchForm.LastReviewDateMin;
			// const lrMax = this.clientSearchQuery.getValue().searchForm.LastReviewDateMax;

			this.form.get('Advisers').setValue(advisers?.map((x) => x?.toString()));
			this.form.get('LeadOrigins').setValue(lo?.map((x) => x?.toString()));
			this.form
				.get('NextActivityTypes')
				.setValue(naT?.map((x) => x?.toString()));
			this.form
				.get('NextActivityAssignedTo')
				.setValue(naAT?.map((x) => x?.toString()));
			this.form.get('ContactStatus').setValue(cs?.map((x) => x?.toString()));
			this.form.get('AdviserStatuses').setValue(ads?.map((x) => x?.toString()));
			this.form.get('Services').setValue(s?.map((x) => x?.toString()));
			this.form.get('ContactTypes').setValue(ct?.map((x) => x?.toString()));

			this.form.get('FirstName').setValue(fname);
			this.form.get('LastName').setValue(lname);

			this.form
				.get('NextActivityDateMin')
				.setValue(MomentUtil.formatDateToMoment(naMin));
			this.form
				.get('NextActivityDateMax')
				.setValue(MomentUtil.formatDateToMoment(naMax));
			this.form
				.get('ClientSinceDateMin')
				.setValue(MomentUtil.formatDateToMoment(csMin));
			this.form
				.get('ClientSinceDateMax')
				.setValue(MomentUtil.formatDateToMoment(csMax));
			this.form
				.get('NextReviewDateMin')
				.setValue(MomentUtil.formatDateToMoment(nrMin));
			this.form
				.get('NextReviewDateMax')
				.setValue(MomentUtil.formatDateToMoment(nrMax));
			// this.form.get('LastReviewDateMin').setValue(MomentUtil.formatDateToMoment(lrMin));
			// this.form.get('LastReviewDateMax').setValue(MomentUtil.formatDateToMoment(lrMax));
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	public prepareFormValue(): ClientSearchRequest {
		const formValue: formType = this.form.value;

		const req: ClientSearchRequest = {
			...formValue,
			Advisers: formValue.Advisers?.map((x: string): number => +x),
			AltAdvisers: formValue.AltAdvisers?.map((x: string): number => +x),
			FirstName: strUtil.safeTrim(formValue.FirstName),
			LastName: strUtil.safeTrim(formValue.LastName),
			NextActivityDateMin: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMin
			),
			NextActivityDateMax: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMax
			),
			NextReviewDateMin: MomentUtil.formatToServerDate(
				formValue.NextReviewDateMin
			),
			NextReviewDateMax: MomentUtil.formatToServerDate(
				formValue.NextReviewDateMax
			),
			// LastReviewDateMin: MomentUtil.formatToServerDate(formValue.LastReviewDateMin),
			// LastReviewDateMax: MomentUtil.formatToServerDate(formValue.LastReviewDateMax),
			ClientSinceDateMin: MomentUtil.formatToServerDate(
				formValue.ClientSinceDateMin
			),
			ClientSinceDateMax: MomentUtil.formatToServerDate(
				formValue.ClientSinceDateMax
			),
			NextReviewServiceCode: formValue?.NextReviewServiceCode || 'ALL',
		};
		return req;
	}

	search(): void {
		const req = JSON.parse(JSON.stringify(this.prepareFormValue()));

		this.clientSearchUiQuery.currentSort$
			.pipe(
				first(),
				map((x) => {
					req.Paging = {
						Index: 1,
						Column: x.propSort,
						Direction: x.sort,
					};
				}),
				finalize(() => {
					this.clientSearchService.search(req).subscribe(() => {
						setTimeout(() => {
							$('datatable-body').scrollTop(1);
							$('datatable-body').scrollLeft(1);
						}, 1);
						setTimeout(() => {
							$('datatable-body').scrollTop(0);
							$('datatable-body').scrollLeft(0);
						}, 1);
					});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	//////////////////////////////////// EXPORT /////////////////////////////////
	openModal(exportTemplate: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			exportTemplate,
			Object.assign(
				{},
				{
					class: 'modal-dialog-centered gray modal-xl w-50',
					ignoreBackdropClick: true,
				}
			)
		);
	}

	export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		const req = request(
			this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
			this.prepareFormValue(),
			this.index
		);

		this.exportsService.queueExport(req, ExportsType.CLIENT)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(this.exportsService.getExportStatus(id))
				)
			)
			.subscribe(
				(data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport2(data.DocumentLink);
						this.hidePopover();
					}
				},
				(err) => {
					this.hidePopover();
				}
			);
		
		setTimeout(() => {
			this.popover.hide();
		}, 2500);
	}

	showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

	computeProgess() {
		this.percent = +((this.index * 100) / this.totalCount)?.toFixed(2);
		this.progress = {
			width: this.percent + '%',
		};
	}

	downloadExport(file: any) {
		const name =
			this.activitedRoute.snapshot.paramMap.get('companyCode') +
			'-Customer.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		const url = window.URL.createObjectURL(file);
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
		window.URL.revokeObjectURL(url);
	}

	downloadExport2(url: string) {
		const name = this.activitedRoute.snapshot.paramMap.get('companyCode') + '-Customer.csv';

		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	clearExportProgress() {
		this.index = 0;
		this.percent = 0;
		this.progress = {
			width: '0%',
		};
		this.totalCount = 0;
	}
	//////////////////////////////////// END EXPORT /////////////////////////////////

	toggle() {
		this.showSearch = !this.showSearch;
		document.body.style.overflowY = 'hidden';
		let x = 0;
		let data;
		const setIntervalHeight = setInterval(() => {
			if (x < 1 && !data) {
				x += 1;
				data = {
					showSearch: this.showSearch,
					height: $('form').height(),
					width: $('form').width(),
				};
			} else if (x > 0) {
				x += 1;
				if (data.height !== $('form').height()) {
					data.height = $('form').height();
				} else {
					this.toggleSearch.emit(data);
					clearInterval(setIntervalHeight);
				}
			}
		}, 100);
	}

	reset() {
		this.form.get('Advisers').reset([]);
		this.form.get('AltAdvisers').reset([]);
		this.form.get('FirstName').reset('');
		this.form.get('LastName').reset('');
		this.form.get('AdviserStatuses').reset([]);
		this.form.get('ClientSinceDateMax').reset('');
		this.form.get('ClientSinceDateMin').reset('');
		this.form.get('ContactStatus').reset([]);
		this.form.get('ContactTypes').reset([]);
		this.form.get('LeadOrigins').reset([]);
		this.form.get('NextActivityDateMax').reset('');
		this.form.get('NextActivityDateMin').reset('');
		this.form.get('NextActivityTypes').reset([]);
		this.form.get('NextActivityAssignedTo').reset([]);
		// this.form.get('LastReviewDateMax').reset('');
		// this.form.get('LastReviewDateMin').reset('');
		this.form.get('NextReviewDateMax').reset('');
		this.form.get('NextReviewDateMin').reset('');
		this.form.get('Services').reset([]);
		this.form.get('NextReviewServiceCode').reset('');
	}
}

type formType = { [key in keyof ClientSearchRequest]: any };
