import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DataSource } from '@angular/cdk/table';
import { MatTableDataSource } from '@angular/material/table';
import {
	combineLatest,
	from,
	Observable,
	of,
	Subscription,
	BehaviorSubject,
} from 'rxjs';
import {
	delay,
	exhaustMap,
	filter,
	map,
	mergeMap,
	startWith,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs/operators';

export interface MatTableScrollDataSourceOptions {
	isSinglePage?: boolean;
	searchFilter$?: Observable<any>;
	loadFn$: (filter: any) => Observable<any>;
}

/**
 * Datasource mat-data-table-scroll component
 * This data source supports 1 pager only
 * Refactor if needed
 */
export class ManageUsersListDataSource extends DataSource<any> {
	private _subs = new Subscription();
	private _pageSize = 500; // Initial value for page size
	private _pages = 1; // One pager only currently
	private _pageOffset = 20; // NUmber of elements to show initially
	private _pageCache = new Set<number>();
	private _subscription: Subscription;
	private _viewPort: CdkVirtualScrollViewport;

	// Create MatTableDataSource so we can have all sort,filter bells and whistles
	matTableDataSource: MatTableDataSource<any> = new MatTableDataSource();

	// Expose dataStream to simulate VirtualForOf.dataStream
	dataStream = this.matTableDataSource.connect().asObservable();

	renderedStream = new BehaviorSubject<any[]>([]);
	constructor(private options: MatTableScrollDataSourceOptions) {
		super();

		const search$ = this.options.searchFilter$
			.pipe(
				filter(() => !!this._viewPort),
				exhaustMap((filter) => this._sortData(filter))
			)
			.subscribe();
		this._subs.add(search$);
	}

	attach(viewPort: CdkVirtualScrollViewport) {
		if (!viewPort) {
			throw new Error('ViewPort is undefined');
		}
		this._viewPort = viewPort;

		this.initFetchingOnScrollUpdates();

		// Attach DataSource as CdkVirtualForOf so ViewPort can access dataStream
		this._viewPort.attach(this as any);

		// Trigger range change so that 1st page can be loaded
		this._viewPort.setRenderedRange({ start: 0, end: this._pageOffset });
	}

	// Called by CDK Table
	connect(): Observable<any[]> {
		const tableData = this.matTableDataSource.connect();
		const filtered =
			this._viewPort === undefined
				? tableData
				: this.filterByRangeStream(tableData);

		filtered.subscribe((data) => {
			this.renderedStream.next(data);
		});

		return this.renderedStream.asObservable();
	}

	disconnect(): void {
		this._subscription.unsubscribe();
		this._subs.unsubscribe();
	}

	private initFetchingOnScrollUpdates() {
		this._subscription = this._viewPort.renderedRangeStream
			.pipe(
				switchMap((range) => this._getPagesToDownload(range)),
				filter((page) => !this._pageCache.has(page)),
				exhaustMap((page) => this._fetchData(page))
			)
			.subscribe();
	}

	private _getPagesToDownload({ start, end }: { start: number; end: number }) {
		const startPage = this.getPageForIndex(start);
		const endPage = this.getPageForIndex(end + this._pageOffset);
		const pages: number[] = [];
		for (let i = startPage; i <= endPage && i < this._pages; i++) {
			if (!this._pageCache.has(i)) {
				pages.push(i);
			}
		}
		return from(pages);
	}

	private getPageForIndex(index: number): number {
		return Math.floor(index / this._pageSize);
	}

	private filterByRangeStream(tableData: Observable<any[]>) {
		const rangeStream = this._viewPort.renderedRangeStream.pipe(
			startWith({} as ListRange)
		);
		const filtered = combineLatest(tableData, rangeStream).pipe(
			map(([data, { start, end }]) => {
				return start === null || end === null ? data : data.slice(start, end);
			})
		);
		return filtered;
	}

	private _sortData(filter): Observable<any[]> {
		return of(filter).pipe(
			mergeMap((filter) => this.options?.loadFn$(filter)),
			delay(100),
			tap(() => this._pageCache.add(0)),
			tap((res) => {
				const page = 0;
				this._pageCache.clear();
				this._pageCache.add(page);
				this._pageSize = res?.length;
				this.matTableDataSource.data = res;
			})
		);
	}

	private _fetchData(page: number): Observable<any[]> {
		return of(page).pipe(
			filter((page) => !this._pageCache.has(page)),
			withLatestFrom(this.options?.searchFilter$),
			mergeMap(([page, filter]) => this.options?.loadFn$(filter)),
			delay(100),
			tap(() => this._pageCache.add(page)),
			tap((res) => {
				this._pageSize = res?.length;
				const newData = [...this.matTableDataSource.data];
				newData.splice(page * this._pageSize, this._pageSize, ...res);
				this.matTableDataSource.data = newData;
			})
		);
	}
}
