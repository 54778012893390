<div class="card-header font-weight-bold text-light tap-bg-primary">
  {{ header }}
</div>
<div class="card-body">
  <app-email-form
    [emailSettings]="emailSettings$ | async"
    [attachments]="attachments"
    [emailDocumentTemplate]="generateFinalisedEmailTemplateFn$ | async"
    [bodyContent]="bodyContent"
    [shortCodes]="shortCodes"
    [peopleList]="peopleList"
    (cancel)="decline()"
    (sent)="sent()"
    [saveEmailFn$]="saveEmailFn$"
    [sendEmailFn$]="sendEmailFn$"
    [downloadOfferTermsFn$]="downloadOfferTermsFn$"
    [showEmailTemplate]="showEmailTemplate"
    [successMessage]="successMessage"
    [attachFiles]="attachFiles"
		[adviceProcess]="adviceProcess"
    [businessConfig]="businessConfig"
    [mergeTags$]="mergeTags$"
		[enableDefaultBcc]="enableDefaultBcc"
  >
    <div *ngIf="attachments?.length > 0">
      <div class="form-row pl-1 font-weight-bold">Email {{ attachments?.length > 1 || offerTerms?.length ? 'Attachments' : 'Attachment' }}</div>
      <div *ngFor="let attachment of attachments; let i = index" class="card-footer">
        <div
          id="downloadBtn_{{i}}"
          class="btn btn-md document-button-container px-0"
          (click)="downloadPdfAttachment(attachment)"
        >
          <i class="far fa-file-pdf text-danger pr-1"></i>
          <small class="text-muted">{{attachment.fileName}}</small>
        </div>
      </div>
    </div>

  </app-email-form>
</div>
