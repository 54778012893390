import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AdviserStatsState, AdviserStatsStore } from './adv.store';

/**
 * Query for Adviser widget data
 */
@Injectable()
export class AdviserStatsQuery extends Query<AdviserStatsState> {

  /** Adviser Stat data. */
  AdviserStatData$ = this.select(state =>
    state.AdviserStats
  );

  /** Indicator if Adviser Stat is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for Adviser Stat pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: AdviserStatsStore) {
    super(store);
  }
}
