import { CombinedAttachment } from "@shared/models/_general/attachment.model";
import { DocumentTypes } from "@shared/models/documents/document.model";

export interface Document {
  ReferenceId: number;
  Value: string;
}

export interface Signature {
  ReferenceID: string;
  secondaryReferenceID: string;
  Value: string;
  DateValue: string;
}

export interface Declaration {
  CRTId: number;
  AdviceProcessId: number;
  Document: Document;
  Signatures: SignatureState[];
  SectionCode: string;
  Status: number;
}

export interface DocumentState {
  referenceId: number;
  value: string;
}

export interface SignatureState {
  referenceID: string;
  secondaryReferenceID: string;
  value: string;
  dateValue: string;

  fullName?: string;
  date?: string;
  signature?: string;
}

export interface DeclarationState {
  cRTId: number;
  adviceProcessId: number;
  document: DocumentState;
  signatures: SignatureState[];
  sectionCode: string;
  status: number;
}

export interface DocumentDeclarationState {
  referenceId: number;
  document: string;
  fileName: string;
  type: string;
}

export interface DeclarationDocument {
	documentID: number;
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	status: number;
	createDateTime: string;
	createdByStaffId: number;
	createdByStaffLevel: number;
	modifiedDateTime: string;
	modifiedByStaffId?: any;
}

// OrderNo = 0 is reserved for Aesthetic cover page (combined by BE)
export const MoatCffPdfOrder: CombinedAttachment[] = [
	{
		orderNo: 1,
		document: '',
		type: DocumentTypes.MOATGenericCover,
		toGeneratePdf: true,
	},
	{
		orderNo: 2,
		document: '',
		type: DocumentTypes.MOATFFDocument,
		toGeneratePdf: true,
	},
]