import {
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isEmpty, either, isNil } from 'ramda';
import { BehaviorSubject, iif, Observable, Observer, of, Subject } from 'rxjs';
import {
	combineLatest,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { EmailTypes } from 'src/app/shared/models/emails/crt/email.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ToPdfService } from 'src/app/shared/services/to-pdf/to-pdf.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { DeclarationSettingState } from '../../../../modules/crt-settings/declaration-settings/declaration-template-settings/state/declaration-settings.model';
import {
	filterNonEmailMergeTags,
	getContentWithMergeTags,
} from '../../../../shared/converter/content-merge-tags';
import { ConfirmModalComponent } from '../../../../shared/modal/confirm-modal/confirm-modal.component';
import { EmailModalComponent } from '../../../../shared/modal/crt/email/email-modal.component';
import { SignatureModalComponent } from '../../../../shared/modal/signature-modal/signature-modal.component';
import {
	ALLOWED_DOCUMENT_FILE_TYPES,
	UploadModalComponent,
} from '../../../../shared/modal/upload-modal/upload-modal.component';
import {
	AdviceProcessDocumentField,
	AdviceProcessSectionCodes,
} from '../../../../shared/models/advice-process/advice-process.model';
import {
	DeclarationState,
	SignatureState,
} from '../../../../shared/models/client-review-template/declaration/declaration.model';
import { DocumentModelState } from '../../../../shared/models/client-review-template/document/document.model';
import { PeopleState } from '../../../../shared/models/client-review-template/people/people.model';
import {
	AdviceProcessDocumentTypes,
	DefaultFileNames,
	DocumentTypes,
	SignatureTypes,
} from '../../../../shared/models/documents/document.model';
import { WysiwygComponent } from '../../../../shared/wysiwyg/wysiwyg.component';
import MomentUtil from '../../../../util/moment.util';
import { convertUtil, objectUtil } from '../../../../util/util';
import { CrtDocumentService } from '../../crt-page/_shared/service/crt-document.service';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { DeclarationDocumentMapper } from '../states/declaration/declaration.mapper';
import { DeclarationService } from '../states/declaration/declaration.service';
import { LoatDocumentService } from '../states/document/loat-document.service';
import { EmailService } from '../states/email/email.service';
import { LrAdviceprocessService } from '../states/lr-adviceprocess/lr-adviceprocess.service';
import { CrtMergeTagsService } from '../states/merge-tags/crt-merge-tags.service';
import { PeopleService } from '../states/people/people.service';
import {
	SignatureModel,
	signatureTemplate,
} from '../util/templates/signature-template';

@Component({
	selector: 'app-declaration',
	templateUrl: './declaration.component.html',
	styleUrls: ['./declaration.component.scss'],
})
export class DeclarationComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
	public bsModalRefSign: BsModalRef;
	public bsModalRefUpload: BsModalRef;

	declaration$ = this.query.declaration$;
	people$ = this.query.people$;

	form: UntypedFormGroup;
	signature: any = {
		DocumentLink: '',
	};
	hasSignature = false;
	isEnableEmailOption = false;

	content = '';
	data: DeclarationSettingState;
	triggerPrint = new BehaviorSubject<object>(null);
	generatePdf$ = new BehaviorSubject<string>(null);
	pdf: string;
	document;
	pdfUrlString: string;

	declarationTemplateSettings$ = this.declarationService.declarationDocument$;
	declarationEmailSettings$ = this.declarationService.declarationEmailSettings$;
	peopleAndDependents$ = this.query.peopleAndDependentsFromCRTOnly$.pipe(
		withLatestFrom(this.query.transferedSCIList$),
		map(
			([x, y]) =>
				x?.filter((x) => !y?.find((i) => +i?.cRTId === +x?.cRTId)) || []
		)
	);
	adviceProcess = this.query.getValue().adviceProcess;
	mergeTags$ = this.mtService.mergeTags$;
	isCollapsed: boolean;

	isLoadingDocument: boolean;
	isDownloading: boolean;

	isReloadDocument: boolean;
	isLoading = false;

	groupName = this.query
		.getValue()
		.primaryClient?.groupName.toString()
		.toUpperCase();
	fileName = `${this.groupName} DECLARATION`;

	@ViewChild('contentEditor') editor: WysiwygComponent;

	zoom$ = this.query.lrApPageCompleted$.pipe(
		map((x) =>
			x?.some((y) => y.includes('ZOOM'))
				? +x?.find((y) => y.includes('ZOOM'))?.substr(4)
				: 120
		)
	);
	defaultContent = '<p></p>';

	constructor(
		private fb: UntypedFormBuilder,
		private query: ClientReviewTemplateQuery,
		private declarationService: DeclarationService,
		private modalService: BsModalService,
		private zone: NgZone,
		private loggerService: LoggerService,
		private loatDocumentService: LoatDocumentService,
		private lrAdviceprocessService: LrAdviceprocessService,
		private emailService: EmailService,
		private peopleService: PeopleService,
		private businessConfigQuery: BusinessConfigQuery,
		private mtService: CrtMergeTagsService,
		private renderer: Renderer2,
		private crtDocService: CrtDocumentService,
		private toPdfService: ToPdfService
	) {
		this.buildForm();
	}

	get Signatures() {
		return this.form.get('signatures') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.prepareData();

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap((x) => {
					const data = this.prepareFormValue(x);
					this.declarationService.setDeclarationFormValue({
						...data,
						content: !!this.isReloadDocument ? this.editor?.content : '',
					});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	/**
	 * Build a reactive form
	 */
	buildForm() {
		this.form = this.fb.group({
			document: this.fb.group({
				referenceId: [null],
				value: [''],
			}),
			signatures: this.fb.array([]),
		});
	}

	/**
	 * On load page, prepare the data
	 */
	prepareData() {
		this.declaration$
			.pipe(
				filter((x) => !!x),
				map((x) => DeclarationDocumentMapper.mapToView(x)),
				combineLatest(this.people$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([d, people]) => {
				this.form.reset();
				this.clearFormArray(this.Signatures);

				if (!!d && !!d?.document) {
					this.form.patchValue({
						document: {
							referenceId: d?.document?.referenceId || 0,
							value: d?.document?.value || null,
						},
					});
				}

				if (!!people) {
					people?.forEach((p) => {
						this.Signatures.push(
							this.patchSignature(
								p,
								(d?.signatures ?? [])?.find((x) => +x.referenceID === +p.cRTId)
							)
						);
					});
				}

				if (!!d && d?.signatures?.length > 0) {
					this.Signatures.controls?.forEach((s, i) => {
						this.getSignature(+s.get('secondaryReferenceID')?.value, i);
					});
				}

				if (!this.content) {
					this.loadDeclaration();
				}

				this.loadEmailSettings();
			});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
		// tslint:disable-next-line: semicolon
	};

	/**
	 * Loads the document
	 */
	 loadDeclaration() {
		this.isLoadingDocument = true;
		this.declarationTemplateSettings$
			.pipe(
				filter((data) => !!data),
				tap((data) => {
					this.data = data;
				}),
				withLatestFrom(this.query.declaration$),
				concatMap(([templateSettings, declaration]) =>
					this.declarationService
						.getDeclarationDocumentFile(
							!!declaration?.document?.referenceId
								? +declaration?.document?.referenceId
								: +templateSettings?.template
						)
						.pipe(
							map((res) =>
								res
									? (objectUtil.mapPascalCaseToCamelCase(
											res
									  ) as DocumentModelState)
									: null
							)
						)
				),
				concatMap((data) =>
					iif(
						() => !!data?.documentLink,
						this.declarationService.getDocumentFromURL(data?.documentLink),
						of(this.defaultContent)
					)
				),
				withLatestFrom(this.query.declaration$),
				tap(([content]) => {
					this.content = getContentWithMergeTags(
						content,
						this.query.getValue().mergeTags
					);
				}),
				concatMap(([, declaration]) =>
					iif(
						() => either(isNil, isEmpty)(declaration?.cRTId) || declaration?.cRTId === 0,
						this.addDeclaration(),
						of(declaration).pipe(
							concatMap((x) =>
								iif(
									() => !!x?.cRTId && either(isNil, isEmpty)(x?.document?.referenceId) || x?.document?.referenceId === 0,
									this.updateDeclarationTxt(x?.cRTId),
									of(x),
								)
							)
						)
					)
				),
				tap(() => this.generatedPdf()),
				finalize(() => (this.isLoadingDocument = false)),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Load declaration email settings
	 */

	loadEmailSettings() {
		this.declarationEmailSettings$
			.pipe(
				take(1),
				tap((data) => {
					if (data) {
						this.isEnableEmailOption = data.isEnableEmailOption;
					}
				})
			)
			.subscribe();
	}
	/**
	 * Load declaration from settings
	 */
	reloadSettings() {
		this.isLoadingDocument = true;
		this.declarationService
			.getDeclarationDocument(0, 'D')
			.pipe(
				concatMap((x: any) =>
					iif(
						() => x?.Template,
						this.declarationService
							.getDeclarationDocumentFile(+x?.Template)
							.pipe(take(1)),
						of(null)
					)
				),
				concatMap((data: any) =>
					this.declarationService.getDocumentFromURL(data.DocumentLink)
				),
				map((content) => {
					const template = content || this.defaultContent;
					const newData = getContentWithMergeTags(
						template,
						this.query.getValue().mergeTags
					);
					this.content = newData;
					return newData;
				}),
				tap((x) => {
					this.isReloadDocument = true;
					const form = this.prepareFormValue();
					const data = {
						...form,
						signatures: form.signatures?.map((y) => ({
							referenceID: y.referenceID,
							secondaryReferenceID: null,
							value: y?.fullName,
							dateValue: null,
						})),
						dateValue: null,
					};
					this.form.reset(data);
					this.declarationService.setDeclarationFormValue({
						...data,
						content: x,
					});
				}),
				concatMap((x) => convertUtil.convertToBase64(x)),
				withLatestFrom(this.declaration$),
				concatMap(([x, declaration]) =>
					this.saveDeclarationDocument(
						x,
						declaration.cRTId,
						declaration?.document?.referenceId
					)
				),
				tap((x) =>
					this.form.patchValue({
						document: {
							referenceId: x,
							value: DefaultFileNames.Declaration,
						},
					})
				),
				concatMap((x) => this.updateDeclaration(this.prepareFormValue())),
				finalize(() => (this.isLoadingDocument = false)),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Reload Template
	 */
	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.reloadSettings();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	/**
	 * Generate a PDF
	 * @param event
	 */
	generatedPdf() {
		const signatures = this.Signatures?.controls?.map(
			(x) =>
				({
					name: x.get('fullName').value,
					date: MomentUtil.formatToDisplayDate(
						MomentUtil.DateStringToMoment(x.get('date').value)
					),
					image: x.get('signature').value,
				} as SignatureModel)
		);
		const signatureContent = signatureTemplate(signatures ?? []);
		const content = `<div class="declaration-pdf-file">${this.content}${signatureContent}</div>`;
		this.generatePdf$.next(content);
	}

	/** Get the pdf url string */
	getPdf(pdf) {
		this.pdf = pdf;
	}

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, this.fileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	/**
	 * Get the document signature and
	 * assigning the signature on specific for
	 * @param documentId signature dodument id
	 * @param i index of form
	 * @returns document object for the signaure
	 */
	getSignature(documentId: number, i: number) {
		if (!documentId) {
			return;
		}

		this.declarationService
			.getSignature(+documentId)
			.pipe(take(1))
			.subscribe((data) => {
				this.Signatures.controls[i]
					.get('signature')
					.setValue(data?.DocumentLink);
			});
	}

	patchSignature(p?: PeopleState, s?: SignatureState) {
		return this.fb.group({
			referenceID: [p?.cRTId],
			secondaryReferenceID: [
				!!s?.secondaryReferenceID ? s?.secondaryReferenceID : null,
			],
			fullName: [{ value: p?.name, disabled: true }],
			date: [
				!!s?.dateValue
					? MomentUtil.formatDateToMoment(s?.dateValue)
					: MomentUtil.momentNowNz(),
			],
			signature: [''],
		});
	}

	/**
	 * Form Mapper
	 * @returns form
	 */
	prepareFormValue(x?: DeclarationState) {
		const data = this.form.getRawValue();
		return {
			...(x ? x : data),
			document: {
				referenceId: data?.document?.referenceId || 0,
				value: data?.document?.value || null,
			},
			signatures: data.signatures?.map((y) => ({
				referenceID: y.referenceID,
				secondaryReferenceID: !!y.secondaryReferenceID
					? y?.secondaryReferenceID
					: 0,
				value: y?.fullName,
				dateValue: MomentUtil.formatDateToServerDate(y?.date),
			})),
		};
	}

	/**
	 * Save Document for Declaration
	 * @param document Document to be uploaded
	 */
	saveDeclarationDocument(
		document: string,
		referenceId: number,
		documentId?: number
	) {
		const request = {
			ReferenceId: referenceId,
			Document: document,
			FileName: DefaultFileNames.Declaration,
			Type: DocumentTypes.Declaration,
			DocumentID: documentId,
		};
		return this.declarationService.saveDocument(request);
	}

	/**
	 * Add New Declaration
	 */
	addDeclaration() {
		const data = this.prepareFormValue();

		return of(data).pipe(
			map((x) =>
				DeclarationDocumentMapper.mapDeclarationToUpsert(
					x,
					this.query.getValue().adviceProcessId,
					x?.signatures
				)
			),
			concatMap((x) => this.declarationService.addDeclaration(x)),
			mergeMap((x) => this.updateDeclarationTxt(x)),
			take(1)
		);
	}

	updateDeclarationTxt(cRTId: number) {
		const data = this.prepareFormValue();
		return of(cRTId).pipe(
			filter((x) => !!x),
			concatMap(() =>
				convertUtil.convertToBase64(
					getContentWithMergeTags(this.content, this.query.getValue().mergeTags)
				)
			),
			concatMap((x) => this.saveDeclarationDocument(x, cRTId)),
			tap((x) =>
				this.form.patchValue({
					document: {
						referenceId: x,
						value: DefaultFileNames.Declaration,
					},
				})
			),
			concatMap((x) => {
				const formData = DeclarationDocumentMapper.mapDeclarationToUpsert(
					data,
					this.query.getValue().adviceProcessId,
					data?.signatures
				);
				return this.declarationService.updateDeclaration({
					...formData,
					cRTId: +cRTId,
					document: {
						referenceId: x,
						value: DefaultFileNames.Declaration,
					},
				});
			})
		)
	}

	updateDeclaration(declaration: DeclarationState) {
		return of(declaration).pipe(
			withLatestFrom(this.query.declaration$),
			concatMap(([x, declarationState]) => {
				const data = { ...declarationState, ...x };
				const formData = DeclarationDocumentMapper.mapDeclarationToUpsert(
					data,
					this.query.getValue().adviceProcessId,
					data?.signatures
				);
				return this.declarationService.updateDeclaration(formData);
			}),
			take(1)
		);
	}

	/**
	 * Save Document for Declaration
	 * @param document Document to be uploaded
	 */
	saveSignatureDocument(document: string, name: string) {
		const request = {
			ReferenceId: this.query.getValue()?.declaration?.cRTId,
			Document: document,
			FileName: `${name ?? ' Client'}-Signature.png`,
			Type: SignatureTypes.Declaration,
		};
		return this.declarationService.saveDocument(request);
	}

	saveSignature(signature: string, index: number) {
		const name = this.Signatures.controls[index]?.get('value')?.value;
		return of(signature).pipe(
			concatMap((x) =>
				iif(
					() => !!x,
					this.saveSignatureDocument(x.replace(/^data:(.*,)?/, ''), name),
					of(x)
				)
			),
			tap((x) => {
				this.Signatures.controls[index]
					?.get('secondaryReferenceID')
					.setValue(x);
				this.Signatures.controls[index]?.get('signature').setValue('');
			}),
			concatMap(() => this.updateDeclaration(this.prepareFormValue())),
			take(1)
		);
	}

	/**
	 * Signing the form and openning the popup modal where to sign
	 * @param i Indexed of the form signature
	 */
	sign(i) {
		const saveFn$ = (signature: string) =>
			new Observable((obs) => {
				this.onChange();
				obs.next(signature as string);
				obs.complete();
			}).pipe(mergeMap((x: string) => this.saveSignature(x, i)));

		const initialState = {
			// header: 'Signature',
			subHeader: 'Draw Signature',
			saveFn$,
		};

		this.bsModalRefSign = this.modalService.show(SignatureModalComponent, {
			initialState,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-dialog',
			keyboard: false,
		});
	}

	/**
	 * This will save a document on CRM Advice Process
	 * @param id Document ReferenceId saved in declaration
	 */
	saveDocument() {
		const field = AdviceProcessDocumentField.Declaration;
		this.isLoading = true;
		if (
			!!this.query
				.getValue()
				?.adviceProcess?.documents?.some(
					(x) => x.field === field && x?.value?.documentID
				)
		) {
			const confirm = new Observable((obs) => {
				this.savePDF()
					.pipe(
						tap(() => (this.isLoading = false)),
						take(1)
					)
					.subscribe();
				obs.next();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				this.isLoading = false;
				obs.complete();
			});

			const latestDeclarationFileName = this.query
				.getValue()
				.adviceProcess.documents?.find((document) => document.field === field);

			const initState = {
				header: '',
				message: `A document is already uploaded for Declaration,`,
				subMessage:
					latestDeclarationFileName?.value?.documentName ?? this.fileName,
				secondaryMessage: `Do you want to replace this?`,
				confirm$: confirm,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.savePDF()
				.pipe(
					tap(() => (this.isLoading = false)),
					take(1)
				)
				.subscribe();
		}
	}

	savePDF(file?: {
		Document: string;
		FileName: string;
		DocumentTypeCode?: string;
	}) {
		const data = this.form.getRawValue()?.signatures?.map(
			(x) =>
				({
					name: x.fullName,
					date: MomentUtil.formatToDisplayDate(x.date),
					image: x.signature,
				} as SignatureModel)
		);
		const template = `${this.content}${signatureTemplate(data)}`;
		return of(template).pipe(
			mergeMap((x) => this.crtDocService.downloadDocumentPDF(x, this.fileName)),
			mergeMap((x) => convertUtil.convertToBase64(x)),
			map((x) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: !file ? x : file.Document,
				FileName: !file ? `${this.fileName}.pdf` : file.FileName,
				DocumentType: ServicesCodes.LR,
				Type: AdviceProcessDocumentTypes.Declaration,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			concatMap((x) =>
				this.loatDocumentService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypes.Declaration
				)
			),
			take(1),
			tap(
				() => {
					this.zone.run(() =>
						this.loggerService.Success(
							{},
							logMessage.oat.shared.declaration.success.save
						)
					);
				},
				(err) => {
					this.zone.run(() =>
						this.loggerService.Warning(err, logMessage.shared.general.error)
					);
				}
			)
		);
	}

	downloadDocumentPDF() {
		this.isLoading = true;
		const data = this.form.getRawValue();
		const signatures = data?.signatures?.map(
			(x) =>
				({
					name: x.fullName,
					date: MomentUtil.formatToDisplayDate(x.date),
					image: x.signature,
				} as SignatureModel)
		);
		const signatureContent = signatureTemplate(signatures ?? []);

		of(this.content)
			.pipe(
				map(
					(x) =>
						`<div class="declaration-pdf-file">${x}${signatureContent}</div>`
				),
				mergeMap((x) =>
					this.renderPdfDownload(x, {
						...this.declarationService.getDeclarationPdfOptions(),
						FileName: this.fileName,
					})
				),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	uploadDocument() {
		const upload = (req) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				tap(() => (this.isLoading = true)),
				map(() => req),
				mergeMap((x) =>
					iif(
						() => req[0].type.startsWith('image/'),
						this.toPdfService.fromImage(req[0]),
						convertUtil.toBase64(x[0]).pipe(take(1))
					)
				),
				map((file) => {
					return {
						Document: `${((file.content as string) ?? '')?.replace(
							/^data:(.*,)?/,
							''
						)}`,
						FileName: `${file.filename}`,
					};
				}),
				concatMap((x) => this.savePDF(x)),
				tap(() => (this.isLoading = false)),
				takeUntil(this.onDestroy$)
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Upload Declaration Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
		};
		this.bsModalRefUpload = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	onChange() {
		this.declarationService.setHasFormChanges(true);
	}

	zoomFn(zoom) {
		const update = (d: string[]) =>
			this.lrAdviceprocessService.updateLrApPageCompleted(d).pipe(
				tap(() => this.lrAdviceprocessService.setLrApPageCompletedState(d)),
				take(1)
			);

		of(zoom)
			.pipe(
				// debounceTime(100),
				withLatestFrom(this.query.lrApPageCompleted$),
				concatMap(([z, list]) =>
					update([
						...(list ?? [])?.filter((l) => !l.includes('ZOOM')),
						`ZOOM${z}`,
					])
				),
				take(1)
			)
			.subscribe();
	}

	sendEmail() {
		this.declarationService
			.getDeclarationEmailSettings(0, EmailTypes.Declaration)
			.pipe(
				take(1),
				tap(() => {
					const initState = {
						header: 'Email Client',
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.declarationEmailSettings$,
						peopleDropdown: this.peopleAndDependents$,
						attachments: [
							{
								fileName: `${this.fileName}.pdf`,
								generateContentCallback$: this.generatePdf$,
								content: this.crtDocService.contentForPdf(this.content),
								newPdfOptions: {
									...this.declarationService.getDeclarationPdfOptions(),
									FileName: this.fileName,
								},
							},
						],
						successMessage: 'Declaration document has been emailed to',
						adviceProcess: this.adviceProcess,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.LR,
						documentInfo: {
							documentType: ServicesCodes.LR,
							type: DocumentTypes.soaDocument,
							referenceId: this.query.getValue().adviceProcessId,
							customerId: this.query.getValue().primaryClient?.customerID,
						}
					};
					this.modalService.show(EmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				})
			)
			.subscribe();
	}

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(data),
					this.peopleService.updatePeople(data)
				)
			),
			takeUntil(this.onDestroy$)
		);
		// tslint:disable-next-line: semicolon
	};

	sendToRecipients = (data) => {
		return this.emailService.sendToRecipients(
			data,
			EmailTypes.Declaration,
			this.query.getValue()?.declaration.cRTId,
			filterNonEmailMergeTags(this.mergeTags$)
		);
	}; // tslint:disable-line

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
