<div class="crt-banner-simple-container fluid-container">
	<div class="row" [ngStyle]="{ height: height || '100px' }">
		<div
			class="crt-banner-simple-container__header col-12 d-flex align-items-center px-0"
		>
			<!-- header text -->
			<div class="header-text col-12 col-lg-5 mb-2">
				<div class="pl-sm-2 pl-md-3 pl-lg-0 pl-0 d-block">
					<span class="header-text">{{ headerText || "" }}</span>
					<span class="subheader-text">{{ subHeaderText || "" }}</span>
				</div>
			</div>
			<div class="col-4"></div>
			<!-- logo -->
			<div class="header-logo col-3 text-right pr-0">
				<img *ngIf="!!logoUrl" [src]="logoUrl" />
			</div>
		</div>
	</div>
</div>
