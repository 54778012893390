<form [formGroup]="form" class="form rental-income-form"
[class.moat-v2]="isMoatV2">
	<div class="form-row mb-2 rental-label-background">
		<div class="col-7 col-md-3 mt-1 mt-md-0 mb-md-3">
			<label for="" class="main-content-section__header">
				Rental Income
			</label>
		</div>
		<div class="col-5 col-md-9 text-right iconShow">
			<button
				id="rentalCollapseButton"
				type="button"
				class="
					btn-toggle
					bg-white
					collapse-btn
					border-0
					rental-collapse-background
				"
			>
				<span *ngIf="elseMinusRental; else elsePlusRental">
					<i (click)="collapseMoreRental()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusRental>
					<span>
						<i (click)="collapseLessRental()" class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div
		class="form-row d-none d-md-flex mb-2 font-weight-bold"
		[class.tap-opacity-25]="rentalArray?.controls?.length === 0"
	>
		<div class="col-md-4">
			<span>Property Asset</span>
		</div>
		<div class="col-md-3">
			<span>Type</span>
		</div>
		<div class="col-md-3">
			<span>Rental Income</span>
		</div>
	</div>

	<div class="collapse expandable" id="collapseRental">
		<div formArrayName="rentalArray" class="mb-4">
			<div
				class="form-row align-items-center"
				*ngFor="let item of rentalArray.controls; let pointIndex = index; let last = last"
				[formGroupName]="pointIndex"
			>
				<div class="d-md-none col-md-2 text-right">
					<ng-container *ngIf="!item.get('isLoading').value">
						<ng-container *ngIf="item.get('btnSaveRental').value">
							<button
								id="rentalSaveRentalButton_{{ pointIndex }}"
								color="primary"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="
									item.get('isNew').value && !item.get('propertyAsset').value
								"
								(click)="saveRental(pointIndex)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
						</ng-container>
						<ng-container *ngIf="item.get('btnEditRental').value">
							<button
								id="rentalEditRentalButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="editRental(pointIndex)"
								[disabled]="editMode || addMode || propertyList?.length === 0"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
						</ng-container>

						<ng-container *ngIf="!item.get('isNew').value">
							<button
								id="rentalDeleteRentalButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="deleteRental(pointIndex)"
								[disabled]="editMode || addMode"
							>
								<i class="material-icons md-16"> delete </i>
							</button>
						</ng-container>
						<ng-container *ngIf="item.get('isNew').value">
							<button
								id="rentalDeleteNewRentalButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="deleteNewRental(pointIndex)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="item.get('isLoading').value">
						<span class="d-block mb-2 mt-1">
							<app-manual-loader-mini
								[isLoading]="item.get('isLoading').value"
							></app-manual-loader-mini>
						</span>
					</ng-container>
				</div>

				<ng-container *ngIf="item.get('btnEditRental').value">
					<div class="col-12 col-md-4 mb-2" [class.border-bottom]="!last" [tooltip]="getTooltipValue(item.get('propertyAddress'))" data-toggle="tooltip" container="body">
						<input
							id="rentalPropertyAddress_{{ pointIndex }}"
							class="form-control crt-form-control"
							type="text"
							value="{{ item.get('propertyAddress').value }}"
							[disabled]="true"
						/>
					</div>
				</ng-container>
				<ng-container *ngIf="item.get('btnEditRental').value">
					<div class="col-12 col-md-3 mb-2" [class.border-bottom]="!last">
						<input
							id="incomeType_{{ pointIndex }}"
							class="form-control crt-form-control"
							type="text"
							value="{{ item.get('incomeType').value }}"
							[disabled]="true"
						/>
					</div>
				</ng-container>
				<ng-container *ngIf="!item.get('btnEditRental').value">
					<div class="col-12 col-md-4 mb-2" [class.border-bottom]="!last">
						<span class="custom-dropdown w-100">
							<select
								[tooltip]="selectedPropertyTooltip(pointIndex)"
								id="rentalPropertyAsset_{{ pointIndex }}"
								formControlName="propertyAsset"
								class="form-control crt-form-control theme-crm-field-disabled"
								(change)="selectProperty(pointIndex)"
							>
								<!-- <option value=""></option> -->
								<option *ngFor="let p of propertyList"
									[value]="p?.cRTId">
									{{ p?.propertyAddress }}
								</option>
							</select>
						</span>
					</div>
					<div class="col-12 col-md-3 pb-1" [class.border-bottom]="!last">
						{{ item.get("incomeType").value }}
					</div>
				</ng-container>

				<div class="col-12 col-md-2 mb-2" [class.border-bottom]="!last">
					<input
						id="rentalNetRentalIncome_{{ pointIndex }}"
						class="form-control crt-form-control"
						type="text"
						value="{{ item.get('netRentalIncome').value | currency }}"
						placeholder="Net Rental Income"
						disabled
					/>
				</div>
				<div class="col-12 d-md-none"></div>
				<div class="d-none d-md-block col-md-1 text-right" [class.border-bottom]="!last">
					<ng-container *ngIf="!item.get('isLoading').value">
						<ng-container *ngIf="item.get('btnSaveRental').value">
							<button
								id="rentalSaveRentalButton_{{ pointIndex }}"
								color="primary"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="
									item.get('isNew').value && !item.get('propertyAsset').value
								"
								(click)="saveRental(pointIndex)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
						</ng-container>
						<ng-container *ngIf="item.get('btnEditRental').value">
							<button
								id="rentalEditRentalButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="editRental(pointIndex)"
								[disabled]="editMode || addMode || propertyList?.length === 0"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
						</ng-container>

						<ng-container
							*ngIf="
								!item.get('isNew').value && item.get('btnEditRental').value
							"
						>
							<button
								id="rentalDeleteRentalButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="deleteRental(pointIndex)"
								[disabled]="editMode || addMode"
							>
								<i class="material-icons md-16"> delete </i>
							</button>
						</ng-container>

						<ng-container
							*ngIf="
								!item.get('isNew').value && item.get('btnSaveRental').value
							"
						>
							<button
								id="rentalCancelEditButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="cancelEdit(pointIndex)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>

						<ng-container *ngIf="item.get('isNew').value">
							<button
								id="rentalDeleteNewRentalButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="deleteNewRental(pointIndex)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="item.get('isLoading').value">
						<app-manual-loader-mini
							[isLoading]="item.get('isLoading').value"
						></app-manual-loader-mini>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="form-row">
			<div class="col-4 mb-4">
				<button
					id="rentalAddNewRentalButton"
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
					(click)="addNewRental()"
					[disabled]="isListLoading || editMode || addMode || propertyList?.length === 0"
				>
					Add Investment Property +
				</button>

				<span *ngIf="isListLoading">
					<app-manual-loader-mini
						[isLoading]="isListLoading"
					></app-manual-loader-mini>
				</span>
			</div>
			<div class="col-5 pt-1">
				<label class="w-100 text-right font-weight-bold tap-text-primary"
					>Rental Income</label
				>
			</div>
			<div class="col-3 pt-1 col-md-3 text-left">
				<label>
					{{ totalRental | currency }}
				</label>
			</div>
		</div>

		<div class="form-row line mb-3"></div>
	</div>
</form>
