// Drag n Drop
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// Froala Editor
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ChartistModule } from 'ng-chartist';
// PDF Viewer
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AvatarModule } from 'ngx-avatars';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// NGX Currency Mask
import { NgxCurrencyModule } from 'ngx-currency';
import { CpSettingsAdditionalInfoComponent } from '../modules/cp-settings/cp-settings-additional-info/cp-settings-additional-info.component';
import { ActivityTimelineComponent } from './activity-timeline/activity-timeline.component';
import { ActivityFormDumbComponent } from './activity/activity-form-dumb/activity-form-dumb.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { AdviceProcessStepWizardComponent } from './advice-process-step-wizard/advice-process-step-wizard.component';
import { AdviceProcessDocumentBaseComponent } from './advice-process/advice-process-documents/advice-process-document-base/advice-process-document-base.component';
import { AdviceProcessDocumentsComponent } from './advice-process/advice-process-documents/advice-process-documents.component';
import { AdviceProcessKoatDocumentsComponent } from './advice-process/advice-process-documents/advice-process-koat-documents/advice-process-koat-documents.component';
import { AdviceProcessLoatDocumentsComponent } from './advice-process/advice-process-documents/advice-process-loat-documents/advice-process-loat-documents.component';
import { AdviceProcessMoatDocumentsComponent } from './advice-process/advice-process-documents/advice-process-moat-documents/advice-process-moat-documents.component';
import { OfflineDocumentsComponent } from './advice-process/advice-process-documents/offline-documents/offline-documents.component';
import { AdviceProcessFormComponent } from './advice-process/advice-process-form/advice-process-form.component';
import { AdviceProcessComponent } from './advice-process/advice-process.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BipolarBarChartComponent } from './bipolar-bar-chart/bipolar-bar-chart.component';
import { CalculatorChartComponent } from './calculator-chart/calculator-chart.component';
import { CalculatorResultsComponent } from './calculator-chart/calculator-results/calculator-results.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipsComponent } from './chips/chips.component';
import { ClientReferralLinkModalModule } from './client-referral-link-modal/client-referral-link-modal.module';
import { PeopleTabsComponent } from './client-review-template/risk-analysis/people-tabs/people-tabs.component';
import { CollapseComponent } from './collapse/collapse.component';
import { AddDocumentModalModule } from './components/add-document-modal/add-document-modal.module';
import { ButtonGroupModule } from './components/button-group/button-group.module';
import { InputChipsModule } from './components/input-chips/input-chips.module';
import { InputRadioGroupComponent } from './components/input-radio-group/input-radio-group.component';
import { ReferClientPopupModule } from './components/refer-client-popup/refer-client-popup.module';
import { TabsetModule } from './components/tabset/tabset.module';
import { ConfirmationCallCompleteModalComponent } from './confirmation-call-complete-modal/confirmation-call-complete-modal.component';
import { ConfirmationCallSendIssueModalComponent } from './confirmation-call-send-issue-modal/confirmation-call-send-issue-modal.component';
import { CrtNotesComponent } from './crt-notes/crt-notes.component';
import { customCurrencyMaskConfig } from './currency-mask/default-config';
import { ActivityDetailComponent } from './datatable-controls/activity-detail/activity-detail.component';
import { AddressComponent } from './datatable-controls/address/address.component';
import { DatatableCheckboxComponent } from './datatable-controls/checkbox/checkbox.component';
import { DateComponent } from './datatable-controls/date/date.component';
import { DisplayComponent } from './datatable-controls/display/display.component';
import { DropdownComponent } from './datatable-controls/dropdown/dropdown.component';
import { HostComponent } from './datatable-controls/host/host.component';
import { LongDisplayComponent } from './datatable-controls/long-display/long-display.component';
import { MoneyComponent } from './datatable-controls/money/money.component';
import { MultiselectComponent } from './datatable-controls/multiselect/multiselect.component';
import { NoteComponent } from './datatable-controls/note/note.component';
import { SwitchComponent } from './datatable-controls/switch/switch.component';
import { TextareaComponent } from './datatable-controls/textarea/textarea.component';
// Controls
import { TextboxComponent } from './datatable-controls/textbox/textbox.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { AddressFieldDirective } from './directive/address-field/address-field.directive';
import { ClipboardNoFormatDirective } from './directive/clipboard-no-format/clipboard-no-format.directive';
import { CurrencyFormatterDirective } from './directive/currency-formatter/currency-formatter.directive';
import { CurrencyMaskExtendDirective } from './directive/currency-formatter/currency-mask-extend.directive';
import { FeatureToggleDirective } from './directive/feature-toggle/feature-toggle.directive';
import { AotFeatureDirective } from './directive/feature-toggle/oat-feature.directive';
import { FeetInchesNumberDirective } from './directive/feet-inches-number/feet-inches-number.directive';
import { HoverableDirective } from './directive/hoverable/hoverable.directive';
import { NoDecimalNumericDirective } from './directive/no-decimal-numeric/no-decimal-numeric.directive';
import { NumericalDirective } from './directive/numerical/numerical.directive';
import { OneDigitDecimalNumberDirective } from './directive/one-digit-decimal-number/one-digit-decimal-number.directive';
import { OneDigitDecimalPointFiveNumberDirective } from './directive/one-digit-decimal-point-five-increment-number/one-digit-decimal-point-five-increment-number.directive';
import { PhoneFieldDirective } from './directive/phone-field/phone-field.directive';
import { QuickwinsModule } from './directive/quickwins/quickwins.module';
import { ResizeColumnDirective } from './directive/resize-column/resize-column.directive';
import { ResponsiveDirective } from './directive/responsive';
// Directives
import { RestrictToDirective } from './directive/restrict-to/restrict-to.directive';
import { SelectPlaceholderDirective } from './directive/select-placeholder/select-placeholder.directive';
// Components
import { ThemeComponent } from './directive/theme/theme.component';
import { ThemeDirective } from './directive/theme/theme.directive';
import { TooltipDirective } from './directive/tooltip/tooltip.directive';
import { TwoDigitDecimalNumberV2Directive } from './directive/two-digit-decimal-number-v2/two-digit-decimal-number-v2.directive';
import { TwoDigitDecimalNumberDirective } from './directive/two-digit-decimal-number/two-digit-decimal-number.directive';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { ErrorLogComponent } from './error-log/error-log.component';
import { ExportProgressComponent } from './export-progress/export-progress.component';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { HorizontalBarChartComponent } from './horizontal-bar-chart/horizontal-bar-chart.component';
import { LoaderMiniComponent } from './loaders/loader-mini/loader-mini.component';
import { LoaderComponent } from './loaders/loader/loader.component';
import { ManualLoaderMiniComponent } from './loaders/manual-loader-mini/manual-loader-mini.component';
import { ManualLoaderMiniModule } from './loaders/manual-loader-mini/manual-loader-mini.module';
import { ManualLoaderComponent } from './loaders/manual-loader/manual-loader.component';
import { RouteLoaderComponent } from './loaders/route-loader/route-loader.component';
import { SimpleLoaderComponent } from './loaders/simple-loader/simple-loader.component';
import { LogoComponent } from './logo/logo.component';
import { ActivityBulkModalComponent } from './modal/activity/activity-bulk-modal/activity-bulk-modal.component';
import { ActivityCancelModalComponent } from './modal/activity/activity-cancel-modal/activity-cancel-modal.component';
// Modals
import { ActivityModalComponent } from './modal/activity/activity-modal/activity-modal.component';
import { AdviceProcessAddModalComponent } from './modal/advice-process-add-modal/advice-process-add-modal.component';
import { AdviceProcessCancelModalComponent } from './modal/advice-process-cancel-modal/advice-process-cancel-modal.component';
import { AdviceProcessEndModalComponent } from './modal/advice-process-end-modal/advice-process-end-modal.component';
import { ArchiveBusinessModalComponent } from './modal/archive-business-modal/archive-business-modal.component';
import { ArchiveModalComponent } from './modal/archive-modal/archive-modal.component';
import { CheckDuplicateEmailModalComponent } from './modal/check-duplicate-email-modal/check-duplicate-email-modal.component';
import { ClientAddModalComponent } from './modal/client-add-modal/client-add-modal.component';
import { ConfirmModalComponent } from './modal/confirm-modal/confirm-modal.component';
import { ConvertModalComponent } from './modal/convert-modal/convert-modal.component';
import { CopyClientModalComponent } from './modal/copy-client-modal/copy-client-modal.component';
import { CrmEmailModalComponent } from './modal/crm-email-modal/crm-email-modal.component';
import { AtpEmailModalComponent } from './modal/crt/email/atp-email-modal/atp-email-modal.component';
import { CrtEmailModalComponent } from './modal/crt/email/crt-email-modal/crt-email-modal.component';
import { EmailBusinessModalComponent } from './modal/crt/email/email-business-modal/email-business-modal.component';
import { EmailClientModalComponent } from './modal/crt/email/email-client-modal/email-client-modal.component';
import { EmailFormComponent } from './modal/crt/email/email-form/email-form.component';
import { EmailModalComponent } from './modal/crt/email/email-modal.component';
import { AssetsLiabilitiesModalComponent } from './modal/crt/fact-find/assets-liabilities/assets-liabilities.modal.component';
import { KiwiSaverFormComponent } from './modal/crt/fact-find/assets-liabilities/kiwisaver-form/kiwisaver-form';
import { LiabilitiesFormComponent } from './modal/crt/fact-find/assets-liabilities/liabilities-form/liabilities-form';
import { MortgagesFormComponent } from './modal/crt/fact-find/assets-liabilities/mortgages-form/mortgages-form';
import { PropertyFormComponent } from './modal/crt/fact-find/assets-liabilities/property-form/property-form';
import { CurrentInsuranceModalComponent } from './modal/crt/fact-find/current-insurance/current-insurance-modal.component';
import { ProductFormComponent } from './modal/crt/fact-find/current-insurance/product-form/product-form';
import { CarFormComponent } from './modal/crt/fact-find/fg-insurance/car/car-form';
import { ContentsFormComponent } from './modal/crt/fact-find/fg-insurance/contents/contents-form';
import { FGInsuranceModalComponent } from './modal/crt/fact-find/fg-insurance/fg-insurance-modal.component';
import { HouseFormComponent } from './modal/crt/fact-find/fg-insurance/house/house-form';
import { GpModalComponent } from './modal/crt/fact-find/history/gp-modal/gp-modal.component';
import { EmploymentFormComponent } from './modal/crt/fact-find/income-budget/employment-form/employment-form';
import { IncomeBudgetModalComponent } from './modal/crt/fact-find/income-budget/income-budget-modal.component';
import { CompanyFormComponent } from './modal/crt/fact-find/people/company-form/company-form';
import { DependentsFormComponent } from './modal/crt/fact-find/people/dependents-form/dependents-form';
import { PeopleFormComponent } from './modal/crt/fact-find/people/people-form/people-form';
import { PeopleModalComponent } from './modal/crt/fact-find/people/people-modal.component';
import { TrustsFormComponent } from './modal/crt/fact-find/people/trusts-form/trusts-form';
import { ServiceCalculatorFormComponent } from './modal/crt/moat/application/service-calculator/service-calculator-form/service-calculator-form';
import { ServiceCalculatorModalComponent } from './modal/crt/moat/application/service-calculator/service-calculator-modal.component';
import { MOATAssetsLiabilitiesModalComponent } from './modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { MOATKiwiSaverFormComponent } from './modal/crt/moat/assets-liabilities/kiwisaver-form/kiwisaver-form';
import { MOATLiabilitiesFormComponent } from './modal/crt/moat/assets-liabilities/liabilities-form/liabilities-form';
import { MOATMortgagesFormComponent } from './modal/crt/moat/assets-liabilities/mortgages-form/mortgages-form';
import { MOATPropertyFormComponent } from './modal/crt/moat/assets-liabilities/property-form/property-form';
import { PropertyPurchaseFormComponent } from './modal/crt/moat/assets-liabilities/property-purchase-form/property-purchase-form';
import { IncomeFormComponent } from './modal/crt/moat/income/income-form/income-form';
import { MOATIncomeModalComponent } from './modal/crt/moat/income/income-modal.component';
import { LoanModalComponent } from './modal/crt/moat/loan-modal/loan-modal.component';
import { SendEmailComponent } from './modal/crt/send-email/send-email.component';
import { DeleteDropdownModalComponent } from './modal/delete-dropdown-modal/delete-dropdown-modal.component';
import { DeleteModalComponent } from './modal/delete-modal/delete-modal.component';
import { InfoModalComponent } from './modal/info-modal/info-modal.component';
import { KiwiSaverTypeModalComponent } from './modal/kiwisaver-type-modal/kiwisaver-type-modal.component';
import { MultiSelectModalComponent } from './modal/multiselect-modal/multiselect-modal.component';
import { NoteAddModalComponent } from './modal/note-modal/note-modal.component';
import { PhoneCallAddModalComponent } from './modal/phone-call-add-modal/phone-call-add-modal.component';
import { PropertyAssetsTypeModalComponent } from './modal/property-assets-type-modal/property-assets-type-modal.component';
import { ProvideAccessModalComponent } from './modal/provide-access-modal/provide-access-modal.component';
import { SignatureModalComponent } from './modal/signature-modal/signature-modal.component';
import { TextareaModalComponent } from './modal/textarea-modal/textarea-modal.component';
import { TransferActivityModalComponent } from './modal/transfer-activity-modal/transfer-activity-modal.component';
import { TransferClientModalComponent } from './modal/transfer-client-modal/transfer-client-modal.component';
import { TransferModalComponent } from './modal/transfer-modal/transfer-modal.component';
import { UpdateServicingTemplateModalComponent } from './modal/update-servicing-template-modal/update-servicing-template-modal.component';
import { UploadModalComponent } from './modal/upload-modal/upload-modal.component';
import { PhotoComponent } from './photo/photo.component';
import { MapToIterable } from './pipe/map-to-iterable-pipe/map-to-iterable-pipe';
import { MomentDatetimeShortPipe } from './pipe/moment-datetime-short/moment-datetime-short.pipe';
import { MomentDatetimePipe } from './pipe/moment-datetime/moment-datetime.pipe';
import { NextlinePipe } from './pipe/nextline/next-line.pipe';
import { SafeHtmlPipe } from './pipe/safe-html/safe-html.pipe';
import { SortByPipe } from './pipe/sort-by/sort-by.pipe';
import { TransformLinkPipe } from './pipe/transform-link/transform-link.pipe';
import { PopoverComponent } from './popover/popover.component';
import { EditDocumentComponent } from './profile-tabs/edit-document/edit-document.component';
import { EditHistoryComponent } from './profile-tabs/edit-history/edit-history.component';
import { ProgressPopupComponent } from './progress-popup/progress-popup.component';
import { ClientSearchControlComponent } from './search-controls/client-search-control/client-search-control.component';
import { SearchControlComponent } from './search-controls/search-control/search-control.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { AssetsServiceFormComponent } from './services/assets-service-form/assets-service-form.component';
import { FgInsuranceServiceFormComponent } from './services/fg-insurance-service-form/fg-insurance-service-form.component';
import { FgInsuranceServiceComponent } from './services/fg-insurance-service/fg-insurance-service.component';
import { InvestmentServiceFormComponent } from './services/investment-service-form/investment-service-form.component';
import { KiwisaverServiceFormComponent } from './services/kiwisaver-service-form/kiwisaver-service-form.component';
import { KiwisaverServiceComponent } from './services/kiwisaver-service/kiwisaver-service.component';
import { LiabilityServiceFormComponent } from './services/liability-service-form/liability-service-form.component';
import { LiabilityServiceComponent } from './services/liability-service/liability-service.component';
import { LinkDocumentSingleTabComponent } from './services/link-document-single-tab/link-document-single-tab.component';
import { LinkDocumentComponent } from './services/link-document/link-document.component';
import { LrInsuranceServiceFormComponent } from './services/lr-insurance-service-form/lr-insurance-service-form.component';
import { LrInsuranceServiceComponent } from './services/lr-insurance-service/lr-insurance-service.component';
import { MortgageServiceFormComponent } from './services/mortgage-service-form/mortgage-service-form.component';
import { MortgageServiceComponent } from './services/mortgage-service/mortgage-service.component';
import { PropertyAssetsFormComponent } from './services/property-assets-form/property-assets-form.component';
import { PropertyAssetsServiceComponent } from './services/property-assets-service/property-assets-service.component';
import { TransferServiceComponent } from './services/transfer-service/transfer-service.component';
import { SignatureFieldComponent } from './signature-field/signature-field.component';
import { TabComponent } from './tab/tab.component';
import { TableCriteriaComponent } from './table-criteria/table-criteria.component';
import { TableInputComponent } from './table-input/table-input.component';
import { TablePaginateClaimsComponent } from './table-paginate-claims/table-paginate-claims.component';
import { TablePaginateNotesComponent } from './table-paginate-notes/table-paginate-notes.component';
import { TapCollapseComponent } from './tap-collapse/tap-collapse.component';
import { TapThemeComponent } from './tap-theme/tap-theme.component';
import { ToastComponent } from './toast/toast.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { VerticalBarChartComponent } from './vertical-bar-chart/vertical-bar-chart.component';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
		RouterModule,
		NgxDatatableModule,
		AvatarModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatMomentDateModule,
		MatStepperModule,
		MatProgressBarModule,
		MatExpansionModule,
		DragDropModule,
		TabsModule.forRoot(),
		AccordionModule.forRoot(),
		ChartistModule,
		CollapseModule.forRoot(),
		SortableModule.forRoot(),
		PopoverModule.forRoot(),
		BsDropdownModule.forRoot(),
		ModalModule.forRoot(),
		ProgressbarModule.forRoot(),
		PaginationModule.forRoot(),
		// Froala Editor Wysiwyg
		FroalaEditorModule.forRoot(),
		FroalaViewModule.forRoot(),
		SignaturePadModule,
		PdfViewerModule,
		TooltipModule.forRoot(),
		// NGX Currency Mask
		NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
		ManualLoaderMiniModule,
		InputChipsModule,
		ConfirmationCallSendIssueModalComponent,
		ConfirmationCallCompleteModalComponent,
		InputRadioGroupComponent,

		// standalone
		ProgressPopupComponent,

		ReferClientPopupModule,
		ClientReferralLinkModalModule,
		ButtonGroupModule,
		QuickwinsModule,
		AddDocumentModalModule,

		ResponsiveDirective
	],
	declarations: [
		// Directives
		RestrictToDirective,
		AddressFieldDirective,
		OneDigitDecimalNumberDirective,
		TwoDigitDecimalNumberDirective,
		OneDigitDecimalPointFiveNumberDirective,
		TooltipDirective,
		HoverableDirective,
		ThemeDirective,
		NumericalDirective,
		NoDecimalNumericDirective,
		ResizeColumnDirective,
		FeatureToggleDirective,
		AotFeatureDirective,

		// Components
		DisplayComponent,
		ThemeComponent,
		ActivityFormDumbComponent,
		DateInputComponent,
		AddressInputComponent,
		CheckboxComponent,
		ManualLoaderComponent,
		SimpleLoaderComponent,
		RouteLoaderComponent,
		TabComponent,
		NoteComponent,
		ActivityTimelineComponent,
		CrtNotesComponent,
		AssetsServiceFormComponent,
		FgInsuranceServiceComponent,
		MortgageServiceComponent,
		LrInsuranceServiceComponent,
		KiwisaverServiceComponent,
		InvestmentServiceFormComponent,
		FgInsuranceServiceFormComponent,
		MortgageServiceFormComponent,
		LrInsuranceServiceFormComponent,
		KiwisaverServiceFormComponent,
		PropertyAssetsFormComponent,
		PropertyAssetsServiceComponent,
		EditDocumentComponent,
		EditHistoryComponent,
		TablePaginateNotesComponent,
		PhotoComponent,
		TableCriteriaComponent,
		LinkDocumentComponent,
		TransferServiceComponent,
		// Modals
		ActivityModalComponent,
		ActivityCancelModalComponent,
		DeleteModalComponent,
		ArchiveModalComponent,
		ArchiveBusinessModalComponent,
		ClientAddModalComponent,
		UploadModalComponent,
		DeleteDropdownModalComponent,
		TextareaModalComponent,
		NoteAddModalComponent,
		ConvertModalComponent,
		TransferModalComponent,
		TransferActivityModalComponent,
		AssetsLiabilitiesModalComponent,
		AtpEmailModalComponent,
		PropertyFormComponent,
		KiwiSaverFormComponent,
		LiabilitiesFormComponent,
		MortgagesFormComponent,
		PropertyAssetsTypeModalComponent,
		MOATAssetsLiabilitiesModalComponent,
		PropertyPurchaseFormComponent,
		MOATMortgagesFormComponent,
		MOATKiwiSaverFormComponent,
		MOATLiabilitiesFormComponent,
		MOATPropertyFormComponent,
		CrmEmailModalComponent,
		ServiceCalculatorModalComponent,
		MOATIncomeModalComponent,
		CpSettingsAdditionalInfoComponent,
		UpdateServicingTemplateModalComponent,
		ClientSearchControlComponent,
		SearchControlComponent,
		PopoverComponent,
		LogoComponent,
		TableInputComponent,
		LongDisplayComponent,
		ChipsComponent,
		CollapseComponent,
		MultiSelectModalComponent,
		DateRangeComponent,
		BipolarBarChartComponent,
		BarChartComponent,
		DonutChartComponent,
		GaugeChartComponent,
		HorizontalBarChartComponent,
		ActivityBulkModalComponent,
		// Controls
		TextboxComponent,
		TextareaComponent,
		DateComponent,
		DropdownComponent,
		ConfirmModalComponent,
		PeopleModalComponent,
		CurrentInsuranceModalComponent,
		FGInsuranceModalComponent,
		IncomeBudgetModalComponent,
		TrustsFormComponent,
		PeopleFormComponent,
		ProductFormComponent,
		DependentsFormComponent,
		CompanyFormComponent,
		CarFormComponent,
		HouseFormComponent,
		ContentsFormComponent,
		EmploymentFormComponent,
		ErrorLogComponent,
		AddressComponent,
		ActivityDetailComponent,
		HostComponent,
		MoneyComponent,
		MultiselectComponent,
		DatatableCheckboxComponent,
		ServiceCalculatorFormComponent,
		IncomeFormComponent,
		ToastComponent,
		// pipes
		SortByPipe,
		MapToIterable,
		MomentDatetimePipe,
		MomentDatetimeShortPipe,
		SafeHtmlPipe,
		NextlinePipe,
		TransformLinkPipe,

		ExportProgressComponent,
		TableCriteriaComponent,
		TablePaginateNotesComponent,
		LoaderComponent,
		LoaderMiniComponent,
		TapThemeComponent,
		ProvideAccessModalComponent,
		SwitchComponent,
		AdviceProcessComponent,
		AdviceProcessFormComponent,
		AdviceProcessDocumentsComponent,
		AdviceProcessDocumentBaseComponent,
		AdviceProcessLoatDocumentsComponent,
		AdviceProcessMoatDocumentsComponent,
		AdviceProcessAddModalComponent,
		AdviceProcessEndModalComponent,
		AdviceProcessStepWizardComponent,
		OfflineDocumentsComponent,
		GpModalComponent,
		TwoDigitDecimalNumberV2Directive,
		FeetInchesNumberDirective,
		SignatureFieldComponent,
		TransferClientModalComponent,
		WysiwygComponent,
		SendEmailComponent,
		KiwiSaverTypeModalComponent,
		EmailSettingsComponent,
		EmailModalComponent,
		EmailFormComponent,
		SignatureModalComponent,
		ViewPdfComponent,
		CurrencyFormatterDirective,
		CurrencyMaskExtendDirective,
		PeopleTabsComponent,
		CrtEmailModalComponent,
		LoanModalComponent,
		AdviceProcessCancelModalComponent,
		SelectInputComponent,
		TapCollapseComponent,
		HorizontalBarChartComponent,
		CopyClientModalComponent,
		PhoneCallAddModalComponent,
		InfoModalComponent,
		AdviceProcessKoatDocumentsComponent,
		CalculatorChartComponent,
		CalculatorResultsComponent,
		VerticalBarChartComponent,
		LiabilityServiceComponent,
		LiabilityServiceFormComponent,
		PhoneFieldDirective,
		LinkDocumentSingleTabComponent,
		SelectPlaceholderDirective,
		UploadDocumentComponent,
		EmailClientModalComponent,
		EmailBusinessModalComponent,
		ClipboardNoFormatDirective,
		CheckDuplicateEmailModalComponent,
		TablePaginateClaimsComponent,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		TabsModule,
		AccordionModule,
		SortableModule,
		NgxDatatableModule,
		AvatarModule,
		PopoverModule,
		PaginationModule,
		ProgressbarModule,
		BsDropdownModule,
		ModalModule,
		TooltipModule,
		MatDatepickerModule,
		MatStepperModule,
		MatProgressBarModule,
		MatExpansionModule,
		FroalaEditorModule,
		FroalaViewModule,
		NgxCurrencyModule,
		DragDropModule,
		TooltipModule,
		ManualLoaderMiniModule,
		ProgressPopupComponent,
		DisplayComponent,
		PopoverComponent,
		TabComponent,
		UploadModalComponent,
		ManualLoaderComponent,
		SimpleLoaderComponent,
		RouteLoaderComponent,
		LogoComponent,
		DateInputComponent,
		TableInputComponent,
		CpSettingsAdditionalInfoComponent,
		// Directives
		RestrictToDirective,
		ThemeDirective,
		AddressFieldDirective,
		OneDigitDecimalNumberDirective,
		TwoDigitDecimalNumberDirective,
		TwoDigitDecimalNumberV2Directive,
		OneDigitDecimalPointFiveNumberDirective,
		FeetInchesNumberDirective,
		CurrencyFormatterDirective,
		CurrencyMaskExtendDirective,
		NumericalDirective,
		NoDecimalNumericDirective,
		PhoneFieldDirective,
		ResizeColumnDirective,
		FeatureToggleDirective,
		AotFeatureDirective,
    SelectPlaceholderDirective,
		ResponsiveDirective,
		// Components
		TextareaModalComponent,
		MapToIterable,
		SafeHtmlPipe,
		NextlinePipe,
		TransformLinkPipe,
		LongDisplayComponent,
		ChipsComponent,
		CollapseComponent,
		MultiSelectModalComponent,
		ManualLoaderComponent,
		SimpleLoaderComponent,
		DateRangeComponent,
		BipolarBarChartComponent,
		BarChartComponent,
		DonutChartComponent,
		GaugeChartComponent,
		HorizontalBarChartComponent,
		ActivityTimelineComponent,
		CrtNotesComponent,
		AssetsServiceFormComponent,
		FgInsuranceServiceComponent,
		MortgageServiceComponent,
		LrInsuranceServiceComponent,
		KiwisaverServiceComponent,
		InvestmentServiceFormComponent,
		FgInsuranceServiceFormComponent,
		MortgageServiceFormComponent,
		LrInsuranceServiceFormComponent,
		KiwisaverServiceFormComponent,
		PropertyAssetsFormComponent,
		PropertyAssetsServiceComponent,
		EditDocumentComponent,
		EditHistoryComponent,
		AddressInputComponent,
		TablePaginateNotesComponent,
		PhotoComponent,
		TableCriteriaComponent,
		ClientSearchControlComponent,
		LinkDocumentComponent,
		TransferServiceComponent,
		TextboxComponent,
		TextareaComponent,
		DateComponent,
		DropdownComponent,
		ErrorLogComponent,
		AddressComponent,
		ActivityDetailComponent,
		HostComponent,
		MoneyComponent,
		MultiselectComponent,
		DatatableCheckboxComponent,
		ExportProgressComponent,
		EmailSettingsComponent,
		ToastComponent,
		// Pipes
		SortByPipe,
		MomentDatetimePipe,
		MomentDatetimeShortPipe,
		CheckboxComponent,
		NoteComponent,
		TableCriteriaComponent,
		AddressInputComponent,
		TablePaginateNotesComponent,
		LoaderComponent,
		LoaderMiniComponent,
		TapThemeComponent,
		TransferModalComponent,
		ActivityCancelModalComponent,
		ProvideAccessModalComponent,
		SwitchComponent,
		AdviceProcessComponent,
		TransferActivityModalComponent,
		AdviceProcessAddModalComponent,
		AdviceProcessEndModalComponent,
		AdviceProcessStepWizardComponent,
		TransferClientModalComponent,
		WysiwygComponent,
		SignatureFieldComponent,
		KiwiSaverTypeModalComponent,
		SignatureModalComponent,
		ViewPdfComponent,
		PeopleTabsComponent,
		LoanModalComponent,
		AdviceProcessCancelModalComponent,
		SelectInputComponent,
		TapCollapseComponent,
		CopyClientModalComponent,
		UpdateServicingTemplateModalComponent,
		CalculatorChartComponent,
		VerticalBarChartComponent,
		LiabilityServiceComponent,
		LinkDocumentSingleTabComponent,
		UploadDocumentComponent,
		ClipboardNoFormatDirective,
		TabsetModule,
		CheckDuplicateEmailModalComponent,
	],
})
export class SharedModule {}
