import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { OtherStat } from './os.model';
import { OtherStatsStore } from './os.store';


@Injectable()
export class OtherStatsService {

  private readonly endpoint = 'widgets?type=OS';

  constructor(
    private api: ApiService,
    private store: OtherStatsStore
  ) { }

  /**
   * Fetch and store Other Statistics data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetOtherStats(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post<OtherStat>(this.endpoint, {
      Advisers: adviserIdList
    })
      .subscribe(
        res => this.store.update(produce(draft => {
          draft.OtherStats = res;
        })),
        err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
        () => this.store.setLoading(false)
      );
  }
}
