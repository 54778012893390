(function (root, factory) {
	if (typeof define === "function" && define.amd) {
		// AMD. Register as an anonymous module.
		define(["chartist"], function (chartist) {
			return (root.returnExportsGlobal = factory(chartist));
		});
	} else if (typeof exports === "object") {
		// Node. Does not work with strict CommonJS, but
		// only CommonJS-like enviroments that support module.exports,
		// like Node.
		module.exports = factory(require("chartist"));
	} else {
		root["Chartist.plugins.ctTargetLine"] = factory(root.Chartist);
	}
})(this, function (Chartist) {
	"use strict";
	var defaultOptions = {
		// The class name so you can style the text
		className: "ct-target-line",
		// The axis to draw the line. y == vertical bars, x == horizontal
		axis: "y",
		// What value the target line should be drawn at
		value: null,
		classNameText: "ct-target-line-text",
		textValue: null,
	};

	Chartist.plugins = Chartist.plugins || {};

	Chartist.plugins.ctTargetLine = function (options) {
		options = Chartist.extend({}, defaultOptions, options);
		return function ctTargetLine(chart) {
			chart.on("created", function (context) {
				// For Target Line
				var projectTarget = {
					y: function (chartRect, bounds, value) {
						var targetLineY =
							chartRect.y1 - (chartRect.height() / bounds.max) * value;

						return {
							x1: chartRect.x1,
							x2: chartRect.x2,
							y1: targetLineY,
							y2: targetLineY,
						};
					},
					x: function (chartRect, bounds, value) {
						var targetLineX =
							chartRect.x1 + (chartRect.width() / bounds.max) * value;

						return {
							x1: targetLineX,
							x2: targetLineX,
							y1: chartRect.y1 - 15, // -15: to make the line shorter
							y2: chartRect.y2 + 15,
						};
					},
				};

				var targetLine = projectTarget[options.axis](
					context.chartRect,
					context.bounds,
					options.value
				);

				// For Target Line Text
				var projectTargetText = {
					y: function (chartRect, bounds, value) {
						var targetLineY =
							chartRect.y1 - (chartRect.height() / bounds.max) * value;

						return {
							x: chartRect.x1,
							y: targetLineY + 2,
						};
					},
					x: function (chartRect, bounds, value) {
						var targetLineX =
							chartRect.x1 + (chartRect.width() / bounds.max) * value;

						return {
							x:
								+options.value === 100 // If the value is 100
									? targetLineX - 30 // -30: To right align the target line value text
									: targetLineX - 10, // -10: To center the target line value text
							y: chartRect.y1,
						};
					},
				};

				var targetLineText = projectTargetText[options.axis](
					context.chartRect,
					context.bounds,
					options.value
				);

				context.svg.elem("line", targetLine, options.className);
				if (options.textValue) {
					context.svg
						.elem("text", targetLineText, options.classNameText)
						.text(options.textValue);
				}
			});
		};
	};

	return Chartist.plugins.ctTargetLine;
});
