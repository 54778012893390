import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStore } from './user/user.store';
import { UserQuery } from './user/user.query';
import { UserService } from './user/user.service';

import { BusinessConfigQuery } from './business-config/business-config.query';
import { BusinessConfigService } from './business-config/business-config.service';
import { BusinessConfigStore } from './business-config/business-config.store';
import { BLStaffsStore } from './bl-staff/bl-staffs.store';
import { BLStaffsQuery } from './bl-staff/bl-staffs.query';
import { BLStaffsService } from './bl-staff/bl-staffs.service';

import { ServicesStore } from './service/services.store';
import { ServicesQuery } from './service/services.query';
import { ServicesService } from './service/services.service';

import { BLStaffSecGroupsStore } from './bl-staff-security-group/bl-staff-security-groups.store';
import { BLStaffSecGroupsQuery } from './bl-staff-security-group/bl-staff-security-groups.query';
import { BLStaffSecGroupsService } from './bl-staff-security-group/bl-staff-security-groups.service';

import { TLStaffsStore } from './tl-staff/tl-staffs.store';
import { TLStaffsQuery } from './tl-staff/tl-staffs.query';
import { TLStaffsService } from './tl-staff/tl-staffs.service';

import { TLStaffSecGroupsStore } from './tl-staff-security-group/tl-staff-security-groups.store';
import { TLStaffSecGroupsQuery } from './tl-staff-security-group/tl-staff-security-groups.query';
import { TLStaffSecGroupsService } from './tl-staff-security-group/tl-staff-security-groups.service';
import { DropdownValueStore } from './dropdown-value/dropdown-value.store';
import { DropdownValueQuery } from './dropdown-value/dropdown-value.query';
import { DropdownValueService } from './dropdown-value/dropdown-value.service';
import { RouteLoaderStore } from './route-loader/route-loader.store';
import { RouteLoaderQuery } from './route-loader/route-loader.query';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    UserStore,
    UserQuery,
    UserService,

    DropdownValueStore,
    DropdownValueQuery,
    DropdownValueService,

    BusinessConfigStore,
    BusinessConfigQuery,
    BusinessConfigService,

    BLStaffsStore,
    BLStaffsQuery,
    BLStaffsService,

    BLStaffSecGroupsStore,
    BLStaffSecGroupsQuery,
    BLStaffSecGroupsService,

    ServicesStore,
    ServicesQuery,
    ServicesService,

    TLStaffsStore,
    TLStaffsQuery,
    TLStaffsService,

    TLStaffSecGroupsStore,
    TLStaffSecGroupsQuery,
    TLStaffSecGroupsService,

		RouteLoaderQuery,
		RouteLoaderStore
  ]
})
export class DomainModule { }
