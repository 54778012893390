import { SoaSettingsState } from './soa-settings.model';
import * as R from 'ramda';
import {
	DefaultFileNames,
	DocumentTypes,
} from '../../../../shared/models/documents/document.model';
import { SettingsTypes } from '../../state/crt-settings.model';
import { soaWordingsMetaKey } from '../../../../shared/models/client-review-template/merge-tags/soa-wordings/soa.merge-tags';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import MomentUtil from 'src/app/util/moment.util';

export class SoaSettingsMapper {
	public static mapMergeTags(data: MergeTagState[]) {
		return data?.map((item) =>
			item?.metaKey === 'DATE_TODAY'
				? {
						...item,
						value: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
						secondaryValue: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
				  }
				: item
		);
	}

	public static mapMergeTagCodes(data = []) {
		const newList = R.sortBy(R.compose(R.toLower, R.prop('description')))(data);
		return newList?.reduce((acc, cur: any) => {
			return { ...acc, [cur.metaKey]: cur.description };
		}, {});
	}

	public static mapDocumentUpload(document, template) {
		if (template === 0 || isNaN(template) || R.isNil(template)) {
			return {
				document,
				referenceId: 0,
				fileName: DefaultFileNames.SOA,
				type: DocumentTypes.SOAUpload,
			};
		}
		return {
			document,
			documentId: +template,
		};
	}

	public static mapSoaMtWordingPreviewValues(data = []) {
		return data?.map((item) =>
			item?.metaKey?.includes('SOA_')
				? {
						...item,
						value: item?.value?.replace(/\n|\t/g, '<br>'),
						secondaryValue: item?.value?.replace(/\n|\t/g, '<br>'),
				  }
				: item
		);
	}

	public static mapSoaMergeTagWording(
		mergeTags = [],
		isPreview: boolean = false
	) {
		const getContent = (metaKey) => {
			const data = mergeTags?.find((x) => x?.metaKey === metaKey);
			return isPreview ? data?.secondaryValue : data?.value;
		};

		return {
			soaLifeCoverCalcIntro:
				getContent(soaWordingsMetaKey.lifeCoverIntro) || '',
			soaTraumaCoverCalcIntro:
				getContent(soaWordingsMetaKey.traumaCoverIntro) || '',
			soaDisabilityCoverCalcIntro:
				getContent(soaWordingsMetaKey.disabilityCoverIntro) || '',
			soaTpdCoverCalcIntro: getContent(soaWordingsMetaKey.tpdCoverIntro) || '',
			soaMedicalIntro: getContent(soaWordingsMetaKey.medicalCoverIntro) || '',
			soaRaiseComplaint: getContent(soaWordingsMetaKey.raiseComplaint) || '',
			soaThingsToConsider:
				getContent(soaWordingsMetaKey.thingsToConsider) || '',
			soaTheNextStep: getContent(soaWordingsMetaKey.nextStep) || '',
		};
	}

	public static mapToUpsert(data: SoaSettingsState) {
		return {
			...data,
			referenceId: data.referenceId || 0,
			mergeTag: data.mergeTag || [],
			type: SettingsTypes.SOA,
		};
	}

	public static mapToUpsertMtWording(mergeTags = [], data) {
		return mergeTags?.map((item) =>
			item?.metaKey === soaWordingsMetaKey.lifeCoverIntro
				? {
						...item,
						value: data?.soaLifeCoverCalcIntro || '',
				  }
				: item?.metaKey === soaWordingsMetaKey.traumaCoverIntro
				? {
						...item,
						value: data?.soaTraumaCoverCalcIntro || '',
				  }
				: item?.metaKey === soaWordingsMetaKey.disabilityCoverIntro
				? {
						...item,
						value: data?.soaDisabilityCoverCalcIntro || '',
				  }
				: item?.metaKey === soaWordingsMetaKey.tpdCoverIntro
				? {
						...item,
						value: data?.soaTpdCoverCalcIntro || '',
				  }
				: item?.metaKey === soaWordingsMetaKey.medicalCoverIntro
				? {
						...item,
						value: data?.soaMedicalIntro || '',
				  }
				: item?.metaKey === soaWordingsMetaKey.raiseComplaint
				? {
						...item,
						value: data?.soaRaiseComplaint || '',
				  }
				: item?.metaKey === soaWordingsMetaKey.thingsToConsider
				? {
						...item,
						value: data?.soaThingsToConsider || '',
				  }
				: item?.metaKey === soaWordingsMetaKey.nextStep
				? {
						...item,
						value: data?.soaTheNextStep || '',
				  }
				: item
		);
	}
}
