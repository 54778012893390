import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mapTo,
	tap,
} from 'rxjs/operators';
import { MortgageCustomerServiceState } from 'src/app/shared/models/services/mortgage/mortgage.model';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { ApiService } from './../../../../../../../../core/base/api.service';
import { CustomerService } from './../../../../../../../../core/customer/customer.service';
import { objectUtil } from './../../../../../../../../util/util';
import { Mortgage } from './mortgage.model';
import { MortgageQuery } from './mortgage.query';
import { MortgageStore } from './mortgage.store';

@Injectable()
export class MortgageService {
	mortgages$ = this.query.mortgages$;
	isLoading$ = this.query.selectLoading();
	crmMortgages$ = this.query.select((x) => x.crmMortgages);

	constructor(
		private api: ApiService,
		protected store: MortgageStore,
		protected query: MortgageQuery,
		private customerService: CustomerService,
		protected crtMortgageQuery: CrtMortgageQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(adviceProcessId: number) {
		this.store.setLoading(true);
		return this.api.get<Mortgage[]>(`crt/${adviceProcessId}/FAM`).pipe(
			map((x) => (x || [])?.map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => this.store.set(x || [])),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAM',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.add(x)),
			concatMap((x) =>
				this.getPrimaryClientMortgages(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			catchError(() => of(undefined))
		);
	}

	update(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAM',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap(() => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.upsert(x?.cRTId, x)),
			concatMap((x) =>
				this.getPrimaryClientMortgages(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			catchError(() => of(undefined))
		);
	}

	delete(id) {
		return this.api.delete(`crt/${id}`).pipe(
			tap(() => this.store.remove(+id)),
			concatMap((x) =>
				this.getPrimaryClientMortgages(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			catchError(() => of(undefined))
		);
	}

	getPrimaryClientMortgages(primaryClientId: number) {
		return this.customerService
			.GetMortgagesByPrimaryClientId(primaryClientId, false)
			.pipe(
				map((x) =>
					(x as MortgageCustomerServiceState[])?.map(
						objectUtil.mapPascalCaseToCamelCase
					)
				),
				tap((x) => this.store.update({ crmMortgages: x })),
				catchError(() => of(undefined))
			);
	}
}
