import { Component, NgZone, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	filterNonEmailMergeTags,
	getContentWithMergeTags,
} from '@shared/converter/content-merge-tags';
import {
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	KiwiSaverAdviceProcessPageIds,
	KiwiSaverAdviceProcessPageNames,
	KOATDocumentField,
	ServiceAdviceProcessState,
} from '@shared/models/advice-process/advice-process.model';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import {
	DocumentModelState,
	DefaultFileNames,
	AdviceProcessDocumentTypesKOAT,
	DocumentTypesKOAT,
} from '@shared/models/documents/document.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { complement, isNil, isEmpty } from 'ramda';
import {
	BehaviorSubject,
	combineLatest,
	iif,
	Observable,
	Observer,
	of,
	Subject,
} from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { CrtKiwiSaverQuery } from '../state/crt-kiwisaver.query';
import { MergeTagsService } from '../state/merge-tags/crt-kiwisaver-mergetags.service';
import {
	DisclosureDocumentState,
	DisclosureFileExt,
	DisclosureState,
	DisclosureTemplateType,
} from './state/disclosure.model';
import { DisclosureService } from './state/disclosure.service';
import { DisclosureMapper } from './state/disclosure.mapper';
import { CrtDocumentService } from '../../_shared/service/crt-document.service';
import { UserQuery } from '../../../../../domain/user/user.query';
import { convertUtil, fileUtil, objectUtil } from '@util/util';
import { ConfirmModalComponent } from '@shared/modal/confirm-modal/confirm-modal.component';
import { ServicesCodes } from '@shared/models/services/services.model';
import { CrtKiwiSaverService } from '../state/crt-kiwisaver.service';
import { LoggerService } from '@core/logger/logger.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { EmailModalComponent } from '@shared/modal/crt/email/email-modal.component';
import { EmailService } from '@modules/crm/client-review-template/states/email/email.service';
import { KOATEmailTypes } from '@shared/models/emails/crt/email.model';
import { PeopleService } from '../people-entities/people/state/people.service';
import { DependantsService } from '../people-entities/dependants/state/dependants.service';
import { PeopleQuery } from '../people-entities/people/state/people.query';
import { CrtKsFactFindService } from '../fact-find/states/crt-ks-fact-find.service';

@Component({
	selector: 'app-disclosure',
	templateUrl: './disclosure.component.html',
	styleUrls: ['./disclosure.component.scss'],
})
export class DisclosureComponent implements OnInit {
	onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	pageHeaders = KiwiSaverAdviceProcessPageNames;
	pageIds = KiwiSaverAdviceProcessPageIds;
	defaultFileName = DefaultFileNames.Disclosure;
	mergeTags: MergeTagState[];
	defaultSettings: DisclosureDocumentState;
	crtData: DisclosureState;
	isLoading: boolean;
	contentLoading: boolean;
	isPdfLoaded: boolean;
	documentData: DocumentModelState | any;
	document: DocumentModelState;
	fileUrl: string;
	crtFileType: string;

	generatePdf$ = new BehaviorSubject<string>(null);

	sidebar = this.koatQuery.getValue().sidebars;
	adviceProcess = this.koatQuery.getValue().adviceProcess;
	adviceProcessId = this.koatQuery.getValue().adviceProcessId;
	customerID = this.koatQuery.getValue().primaryClient?.customerID;
	staffId = +this.userQuery.getValue().StaffID;
	groupName = this.koatQuery
		.getValue()
		.primaryClient?.groupName?.toString()
		?.toUpperCase();
	clientId = this.route.snapshot.paramMap.get('clientId');

	adviceProcess$ = this.koatQuery.adviceProcess$;
	mergeTags$ = this.mtService.mergeTags$;
	disclosureDocument$ = this.service.disclosure$;
	disclosureSettings$ = this.service.disclosureSettings$;
	isTabLoading$ = this.koatService.isTabLoading$;

	fileName = `${this.groupName} DISCLOSURE DOCUMENT`;
	content = '<p></p>';
	hasExistingDoc = false;
	DdTemplateType = DisclosureTemplateType;
	DdTemplateFileExt = DisclosureFileExt;
	pdfExt = DisclosureFileExt.pdf;
	peopleAndDependents$ = this.peopleQuery.peopleAndDependentsFromCRTOnly$.pipe(
		withLatestFrom(this.ffService.peopleButtonState$),
		map(([x, y]) =>
			x
				.map((obj) => ({
					...obj,
					name: y.find((obj2) => obj2.cRTId === obj.cRTId)?.name || '',
				}))
				?.filter((p) => p?.name !== '')
		)
	);
	skipSaving = false;

	businessFapName = this.businessConfigQuery.getValue().config.FAP;

	constructor(
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private renderer: Renderer2,
		private service: DisclosureService,
		private koatQuery: CrtKiwiSaverQuery,
		private koatService: CrtKiwiSaverService,
		private mtService: MergeTagsService,
		private crtDocService: CrtDocumentService,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery,
		private loggerService: LoggerService,
		private emailService: EmailService,
		private peopleService: PeopleService,
		private dependantsService: DependantsService,
		private peopleQuery: PeopleQuery,
		private ffService: CrtKsFactFindService,
		private zone: NgZone
	) {}

	ngOnInit(): void {
		this.koatService
			.updatePageStarted(AdviceProcessPageCodes.Disclosure)
			.pipe(take(1))
			.subscribe();

		this.isLoading = true;
		this.service.crtDisclosureLoading$
			.pipe(
				filter((loading) => !loading),
				concatMap(() => this.service.getDisclosureSettings()),
				withLatestFrom(this.service.disclosure$),
				tap(() => this.prepData()),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	prepData() {
		let dataCrt;
		combineLatest([
			this.disclosureDocument$,
			this.disclosureSettings$,
			this.mergeTags$,
		])
			.pipe(
				tap(() => (this.contentLoading = true)),
				filter(([, settings, mt]) => !!settings && !!mt),
				take(1),
				tap(([crt, settings, mt]) => {
					this.mergeTags = mt;
					this.defaultSettings = settings;
					this.crtData = dataCrt = crt as DisclosureState;
				}),
				mergeMap(([crt, settings]) => this.getContentFromDoc(crt, settings)),
				mergeMap((content) => this.updateContent(content)),
				concatMap((content) =>
					iif(
						() =>
							isNil(this.crtData?.cRTId) &&
							complement(isEmpty)(this.defaultSettings),
						this.addDisclosure(this.crtData, content),
						of(null)
					)
				),
				finalize(() => (this.contentLoading = false)),
				take(1)
			)
			.subscribe();
	}

	// Pull Disclosure Document Template from CRT or Settings (If no CRT yet)
	getContentFromDoc(crt, settings, forceRefresh = false) {
		return of(crt).pipe(
			map((x) => {
				if (forceRefresh) {
					return settings.templateType === DisclosureTemplateType.UploadDocument
						? settings?.uploadTemplate
						: settings?.template;
				}

				return x?.document?.referenceId
					? x?.document?.referenceId
					: settings.templateType === DisclosureTemplateType.UploadDocument
					? settings?.uploadTemplate
					: settings?.template;
			}),
			concatMap((x) =>
				iif(
					() => !!x,
					this.service.getDisclosureDocumentFile(+x).pipe(
						map((y) => (y ? objectUtil.mapPascalCaseToCamelCase(y) : null)),
						take(1)
					),
					of(null)
				)
			),
			tap((data) => {
				if (data) {
					this.crtFileType = data.fileExtension;
					this.fileUrl = data.documentLink;
					this.documentData = data;
				}
			}),
			concatMap((data) =>
				iif(
					() => isNil(data),
					of(this.content),
					this.service.getDocumentFromURL(data?.documentLink)
				)
			),
			take(1)
		);
	}

	// Update content with Merge Tags
	updateContent(content) {
		return of(content).pipe(
			map((x) => getContentWithMergeTags(x, this.mergeTags)),
			tap((x) => (this.content = x)),
			take(1)
		);
	}

	addDisclosure(crtData: DisclosureState, content: string) {
		return of(crtData).pipe(
			concatMap(() =>
				iif(
					() =>
						this.defaultSettings.templateType ===
						DisclosureTemplateType.UploadDocument,
					this.getUploadDocument(crtData),
					this.getTemplateDocument(crtData, content)
				)
			),
			mergeMap((x) => this.service.addNewDisclosure(x)),
			withLatestFrom(this.disclosureDocument$),
			tap(([, data]) => (this.crtData = data as DisclosureState)),
			take(1)
		);
	}

	getUploadDocument(crtData: DisclosureState) {
		let docFile;
		return this.service
			.getDisclosureDocumentFile(+this.defaultSettings.uploadTemplate)
			.pipe(
				map((doc) => objectUtil.mapPascalCaseToCamelCase(doc)),
				tap((doc) => (docFile = doc as DocumentModelState)),
				mergeMap((doc) => this.getFileFormat(doc.documentLink, doc.documentID)),
				map((file) =>
					DisclosureMapper.mapApDocumentUpload({
						fileName: file.fileName,
						document: file.content,
						referenceId: +this.adviceProcessId,
					})
				),
				concatMap((file) => this.crtDocService.newFileUpload(file)),
				concatMap((id) => this.service.getDisclosureDocumentFile(+id)),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				tap((res) => {
					if (res) {
						this.document = res;
						if (this.crtData) {
							this.crtData.document = {
								referenceId: +res.documentID,
								value: docFile.fileName,
							};
						}
					}
				}),
				map((res) =>
					DisclosureMapper.mapCrtToUpsert({
						...crtData,
						adviceProcessId: +this.adviceProcessId,
						document: {
							referenceId: +res.documentID,
							value: docFile.fileName,
						},
					})
				),
				take(1)
			);
	}

	getTemplateDocument(crtData: DisclosureState, content: string) {
		return of(content).pipe(
			concatMap((x) => convertUtil.convertToBase64(x)),
			map((x) =>
				DisclosureMapper.mapApDocumentUpload({
					fileName: this.defaultFileName,
					document: x,
					referenceId: +this.adviceProcessId,
				})
			),
			concatMap((file) => this.crtDocService.newFileUpload(file)),
			concatMap((id) => this.service.getDisclosureDocumentFile(+id)),
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			tap((res) => {
				if (res) {
					this.document = res;
					if (this.crtData) {
						this.crtData.document = {
							referenceId: +res.documentID,
							value: this.defaultFileName,
						};
					}
				}
			}),
			map((res) =>
				DisclosureMapper.mapCrtToUpsert({
					...crtData,
					adviceProcessId: +this.adviceProcessId,
					document: {
						referenceId: +res.documentID,
						value: this.defaultFileName,
					},
				})
			),
			take(1)
		);
	}

	getFileFormat(url, documentId) {
		return this.crtDocService
			.getDocumentLink(url, {
				responseType: 'blob',
			})
			.pipe(
				mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
				map((xx) => {
					const fileStr = `${((xx as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					return {
						documentId: +documentId,
						fileName: `${this.fileName}${this.pdfExt}`,
						generateContentCallback$: undefined,
						content: fileStr,
						pdfOptions: {
							orientation: '',
							format: '',
						},
						fileSize: +fileUtil.getFileSizeKb(fileStr),
						type: 'linked',
						fileUrl: this.fileUrl,
						allowDeleting: false
					};
				}),
				take(1)
			);
	}

	saveFn = () => {
		if (this.skipSaving) {
			this.isLoading = false;
			return of(undefined);
		}
		return of(this.crtData).pipe(
			mergeMap((crtData) =>
				iif(
					() => crtData && this.crtFileType === DisclosureFileExt.pdf,
					this.saveUploadDocument(),
					this.saveTemplateDocument()
				)
			)
		);
	};

	saveUploadDocument() {
		return this.getFileFormat(this.fileUrl, this.documentData.documentID).pipe(
			tap(() => (this.contentLoading = true)),
			map((doc) => {
				return {
					fileName: this.documentData.documentName,
					document: doc.content,
					documentId: this.crtData?.document?.referenceId,
				};
			}),
			concatMap((x) =>
				iif(
					() => !!x?.documentId && x?.documentId > 0,
					this.crtDocService.updateFileUpload(x),
					of(x)
				)
			),
			finalize(() => (this.contentLoading = true))
		);
	}

	saveTemplateDocument() {
		return of(this.crtData).pipe(
			map((data) => DisclosureMapper.mapCrtToUpsert(data)),
			withLatestFrom(of(this.content)),
			tap(() => (this.contentLoading = true)),
			map(([crt, content]) => ({ ...crt, content })),
			mergeMap((x) => convertUtil.convertToBase64(x?.content)),
			map((content) => ({
				fileName: DefaultFileNames.Disclosure,
				document: content,
				documentId: this.crtData?.document?.referenceId,
			})),
			concatMap((x) =>
				iif(
					() => !!x?.documentId && x?.documentId > 0,
					this.crtDocService.updateFileUpload(x),
					of(x)
				)
			),
			finalize(() => (this.contentLoading = true))
		);
	}

	// Save Document
	saveDocument() {
		const updatedData = `<div class="disclosure-pdf-file">${this.content}</div>`;
		this.adviceProcess$.pipe(take(1)).subscribe((x) => {
			const dDocument = x?.documents?.find(
				(d) => d.field === KOATDocumentField.Disclosure
			)?.value;

			if (isNil(dDocument) || isEmpty(dDocument)) {
				if (
					this.defaultSettings &&
					this.defaultSettings.templateType ===
						DisclosureTemplateType.UploadDocument
				) {
					this.saveNewDocumentPdf().pipe(take(1)).subscribe();
				} else {
					this.saveNewDocument(updatedData).pipe(take(1)).subscribe();
				}
			} else {
				this.saveExistingDocument(x, updatedData);
			}
		});
	}

	// Saving of Existing Document
	// If Advice Process already has Disclosure Document uploaded
	saveExistingDocument(adviceProcess: ServiceAdviceProcessState, data) {
		const document = adviceProcess?.documents?.find(
			(d) => d.field === KOATDocumentField.Disclosure
		)?.value;

		const confirm = new Observable((obs) => {
			if (this.crtData && this.crtFileType === DisclosureFileExt.pdf) {
				this.saveNewDocumentPdf().pipe(take(1)).subscribe();
			} else {
				this.saveNewDocument(data).pipe(take(1)).subscribe();
			}

			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: 'Save Document',
			message: `A document is already uploaded for Disclosure Document,`,
			subMessage: document?.fileName,
			secondaryMessage: `Do you want to replace this?`,
			confirm$: confirm,
			decline$: decline,
			isAcceptBtn: true,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Saving of New Document
	// If Advice Process has no Disclosure Document yet
	saveNewDocument(data) {
		return of(data).pipe(
			tap(() => (this.contentLoading = true)),
			mergeMap((content) =>
				this.crtDocService.downloadDocumentPDF(content, this.fileName)
			),
			mergeMap((content) => convertUtil.convertToBase64(content)),
			map((template) => ({
				ReferenceId: this.adviceProcessId,
				CustomerId: this.customerID,
				Document: template,
				FileName: `${this.fileName}${this.pdfExt}`,
				DocumentType: ServicesCodes.KiwiSaver,
				Type: AdviceProcessDocumentTypesKOAT.Disclosure,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap((x) =>
				this.koatService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesKOAT.Disclosure
				)
			),
			tap(() =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						'Successfully saved Disclosure Document.'
					)
				)
			),
			finalize(() => (this.contentLoading = false)),
			take(1)
		);
	}

	saveNewDocumentPdf() {
		return of(this.defaultSettings).pipe(
			tap(() => (this.contentLoading = true)),
			mergeMap(() => {
				return this.getFileFormat(
					this.documentData.documentLink,
					this.documentData.documentID
				);
			}),
			map((file) => ({
				ReferenceId: this.adviceProcessId,
				CustomerId: this.customerID,
				Document: file.content,
				FileName: `${this.fileName}${this.pdfExt}`,
				DocumentType: ServicesCodes.KiwiSaver,
				Type: AdviceProcessDocumentTypesKOAT.Disclosure,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap((x) =>
				this.koatService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesKOAT.Disclosure
				)
			),
			tap(() =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						'Successfully saved Disclosure Document.'
					)
				)
			),
			finalize(() => (this.contentLoading = false)),
			take(1)
		);
	}

	// Reload Template
	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.loadFromSettings();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Load template from settings
	loadFromSettings() {
		this.contentLoading = true;
		let settings;

		of(true)
			.pipe(
				concatMap(() => this.refetchMergeTags()),
				concatMap(() => this.service.getDisclosureSettings(true)),
				map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
				tap((x) => (settings = x)),
				concatMap((x) => {
					if (!x) {
						return of(null);
					}
					if (x.templateType === DisclosureTemplateType.FroalaTemplate) {
						if (x.template) {
							return this.getContentFromDoc(null, x, true);
						}
						return this.setUrlToNone();
					} else {
						if (x.uploadTemplate) {
							return this.getContentFromDoc(null, x, true);
						}
						return of('<p></p>');
					}
				}),
				mergeMap((x) =>
					iif(
						() =>
							!!settings &&
							settings.templateType === DisclosureTemplateType.FroalaTemplate,
						this.updateContent(x),
						of(null)
					)
				),
				finalize(() => (this.contentLoading = false)),
				take(1)
			)
			.subscribe();
	}

	// Download Document as PDF
	downloadDocument() {
		this.contentLoading = true;
		of(this.crtData)
			.pipe(
				mergeMap((crtData) =>
					iif(
						() => crtData && this.crtFileType === DisclosureFileExt.pdf,
						this.downloadUploadDocument(),
						this.downloadTemplateDocument()
					)
				),
				take(1)
			)
			.subscribe();
	}

	downloadUploadDocument() {
		return this.adviceProcess$.pipe(
			map((adviceProcess) => {
				const ap = adviceProcess?.documents?.find(
					(d) => d.field === KOATDocumentField.Disclosure
				)?.value;

				return ap;
			}),
			concatMap(() =>
				this.getFileFormat(
					this.documentData.documentLink,
					this.documentData.documentID
				)
			),
			tap((file: any) => {
				if (typeof file === 'object') {
					this.downloadFileUpload(file?.fileUrl, file?.fileName);
				} else {
					this.downloadFileUpload(file, `${this.fileName}${this.pdfExt}`);
				}
				this.contentLoading = false;
			}),
			take(1)
		);
	}

	downloadTemplateDocument() {
		return of(this.content).pipe(
			map((content) => `<div class="disclosure-pdf-file">${content}</div>`),
			mergeMap((content) =>
				this.renderPdfDownload(content, {
					...this.service.getDdPdfOptions(),
					FileName: this.fileName,
				})
			),
			tap(() => (this.contentLoading = false)),
			take(1)
		);
	}

	sendEmail() {
		this.service
			.getDisclosureSettings(true)
			.pipe(
				mergeMap(() =>
					iif(
						() =>
							(this.crtData && this.crtFileType === DisclosureFileExt.pdf) ||
							(!this.crtData &&
								this.defaultSettings &&
								this.defaultSettings.templateType ===
									DisclosureTemplateType.UploadDocument),
						this.getFileFormat(
							this.fileUrl,
							this.defaultSettings.uploadTemplate
						),
						of({
							fileName: `${this.fileName}${this.pdfExt}`,
							generateContentCallback$: this.generatePdf$,
							content: this.crtDocService.contentForPdf(
								`<div class="disclosure-pdf-file">${this.content}</div>`
							),
							newPdfOptions: {
								...this.service.getDdPdfOptions(),
								FileName: this.fileName,
							},
						})
					)
				),
				tap((attachments) => {
					const initState = {
						header: 'Email Client',
						enableDefaultBcc: true,
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.disclosureSettings$,
						peopleDropdown: this.peopleAndDependents$,
						attachments: [attachments],
						successMessage: 'Disclosure document has been emailed to',
						adviceProcess: this.adviceProcess,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.KiwiSaver,
						documentInfo: {
							documentType: ServicesCodes.KiwiSaver,
							type: DocumentTypesKOAT.Disclosure,
							referenceId: +this.adviceProcessId,
							customerId: +this.customerID,
						},
					};

					this.modalService.show(EmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				}),
				take(1)
			)
			.subscribe();
	}

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.dependantsService.update(data),
					this.peopleService.update(data)
				)
			)
		);
	};

	sendToRecipients = (data) => {
		return this.emailService.sendToRecipients(
			data,
			KOATEmailTypes.Disclosure,
			this.crtData?.cRTId,
			this.mergeTags$
		);
	};

	refetchMergeTags = () =>
		this.mtService.getDefaultMergeTags(true).pipe(
			concatMap(() => this.mtService.getAdviserProviderMt()),
			withLatestFrom(this.mtService.mergeTags$),
			tap(([, x]) => (this.mergeTags = x))
		);

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, this.fileName, pdfOptions)
			.pipe(
				tap((x: any) => {
					const name = `${pdfOptions?.FileName}${this.pdfExt}`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	downloadFileUpload(fileUrl: any, fileName: string) {
		this.crtDocService
			.getDocumentLink(fileUrl, {
				responseType: 'blob',
			})
			.pipe(
				tap((file) => {
					const newFile = new File([file], fileName);
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(newFile);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', fileName);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			)
			.subscribe();
	}

	downloadFile(fileUrl: any, fileName: string) {
		const a = document.createElement('a');
		a.href = fileUrl;
		a.setAttribute('download', fileName);
		a.click();
	}

	generatedPdf() {
		const content = this.crtDocService.contentForPdf(
			`<div class="disclosure-pdf-file">${this.content}</div>`
		);
		this.generatePdf$.next(content);
	}

	setUrlToNone() {
		return of(this.fileUrl).pipe(
			tap(() => (this.fileUrl = '')),
			take(1)
		);
	}

	onPageRendered(event: Event) {
		this.isPdfLoaded = true;
	}

	pauseExitFn = () =>
		of(false).pipe(
			tap(() => (this.skipSaving = true)),
			take(1)
		);

	loadingComponentFn = (value: boolean) => this.koatService.isTabLoading(value);

	saveComponentFn = () => this.saveFn();
}
