<app-manual-loader
	*ngIf="isLoading$ | async as isLoading"
	[isLoading]="isLoading"
></app-manual-loader>

<form [formGroup]="form" class="form" novalidate>
	<div class="row mb-3">
		<div class="col-12 col-lg-9">
			<div class="form-row">
				<div class="col-12 col-lg-6">
					<div class="form-row align-items-center mb-2">
						<div class="col-12 col-lg-4">
							<label for="expectedSettlementDate" class="position-relative">
								Expected Settlement Date
							</label>
						</div>
						<div class="col-12 col-lg-8">
							<app-date-input
								#expectedSettlementDate
								formControlName="expectedSettlementDate"
								id="expectedSettlementDate"
								ngProjectAs="input"
								class="crt-form-control-field"
								textboxClass="theme-crm-field-disabled font-weight-bold"
								placeholder="dd/mm/yyyy"
							></app-date-input>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col-12 col-lg-9">
			<div class="form-row">
				<!-- SOLICITOR DETAILS -->
				<div class="col-12 col-lg-6">
					<div class="font-weight-bold mb-3">Solicitor Details:</div>

					<div class="form-row align-items-center mb-2">
						<div class="col-12 col-lg-12">
							<span class="w-100" [class.crt-custom-dropdown]="form.enabled">
								<select
									id="solicitorSelect"
									formControlName="customerID"
									class="form-control theme-crm-field-disabled crt-form-control"
									(change)="onSelectSolicitor($event.target.value)"
								>
									<option [value]="null"></option>
									<option
										*ngFor="let p of secondaryProfessionals$ | async"
										[value]="p.customerID"
									>
										{{ p.name }}
									</option>
									<option value="new">+ Add New Solicitor</option>
								</select>
							</span>
						</div>
					</div>

					<div class="form-row align-items-center mb-2">
						<div class="col-12 col-lg-4">
							<label for="" class="position-relative"> Name </label>
						</div>
						<div class="col-12 col-lg-8">
							<input
								id="name"
								class="form-control crt-form-control"
								type="text"
								formControlName="name"
							/>
						</div>
					</div>

					<div class="form-row align-items-center mb-2">
						<div class="col-12 col-lg-4">
							<label for="" class="position-relative"> Email </label>
						</div>
						<div class="col-12 col-lg-8">
							<input
								id="email"
								class="form-control crt-form-control"
								type="email"
								formControlName="email"
							/>
						</div>
					</div>

					<div class="form-row align-items-center mb-2">
						<div class="col-12 col-lg-4">
							<label for="" class="position-relative"> Company </label>
						</div>
						<div class="col-12 col-lg-8">
							<input
								id="company"
								class="form-control crt-form-control"
								type="text"
								formControlName="company"
							/>
						</div>
					</div>
				</div>

				<!-- BANKING DETAILS -->
				<div class="col-12 col-lg-6 px-lg-5">
					<div class="font-weight-bold mb-3">Banking Details:</div>

					<div class="form-row align-items-center mb-2">
						<div class="col-12 col-lg-4">
							<label for="" class="position-relative font-weight-bold">
								Preferred Branch
							</label>
						</div>
						<div class="col-12 col-lg-8">
							<input
								id="preferredBranch"
								class="form-control crt-form-control"
								type="text"
								formControlName="preferredBranch"
							/>
						</div>
					</div>

					<div class="form-row align-items-center mb-2">
						<div class="col-12 col-lg-4">
							<label for="" class="position-relative font-weight-bold">
								Preferred Contact
							</label>
						</div>
						<div class="col-12 col-lg-8">
							<input
								id="preferredContact"
								class="form-control crt-form-control"
								type="text"
								formControlName="preferredContact"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="main-content-section__footer mb-4">
		<div class="border-bottom"></div>
	</div>

	<div class="row">
		<div class="col-12">
			<span class="font-weight-bolder">Insurance</span>
		</div>
	</div>

	<div class="row">
		<div class="col-12 col-lg-9">
			<div class="form-row d-lg-flex align-items-center">
				<div class="col-12 col-lg-6">
					<div class="form-row d-lg-flex align-items-center">
						<div class="col-auto">
							<label
								class="font-weight-bold pt-2 mr-5"
								for="newInsurancePolicyRequired"
								>New Insurance Policy is required</label
							>
						</div>
						<div class="col-1">
							<div class="custom-control custom-checkbox mt-1">
								<input
									id="newInsurancePolicyRequired"
									type="checkbox"
									class="checkbox custom-control-input"
									formControlName="newInsurancePolicyRequired"
								/>
								<label
									class="custom-control-label"
									for="newInsurancePolicyRequired"
								></label>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6">
					<div class="form-row d-lg-flex">
						<ng-container
							*ngIf="blanketReferralOptions$ | async as blanketOptions"
						>
							<ng-container *ngFor="let option of blanketOptions">
								<button
									*ngIf="option.referralValues?.isChecked"
									type="button"
									class="tap-btn tap-btn-outline tap-btn--shadow mr-lg-2 mb-2 mb-lg-0 mt-2 pl-4 pr-4"
									(click)="blanketLink(option.referralValues?.referralLink)"
								>
									{{ option.referralService }}
								</button>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
