import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
	DocumentModelState,
	DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from '../../core/base/api.service';

@Injectable({
	providedIn: 'root',
})
export class CpSettingsService {
	emailInvitationCode = 'CPIES';
	additionalInfoCode = 'CPRAIES';
	submitApplicationCode = 'CPASES';

	constructor(private apiService: ApiService) {}

	getEmailSettings(CRTSettingsType: string): Observable<any> {
		const url = `crt/settings/0/${CRTSettingsType}`;
		return this.apiService
			.get(url)
			.pipe(map((result) => objectUtil.mapPascalCaseToCamelCase(result)));
	}

	saveEmailSettings(req) {
		return of(req).pipe(
			mergeMap((x) => this.apiService.put('crt/settings/0', req))
		);
	}

	// Additional Info
	getAdditionalSetting(): Observable<any> {
		const url = `crt/settings/0/${this.additionalInfoCode}`;
		return this.apiService
			.get(url)
			.pipe(map((result) => objectUtil.mapPascalCaseToCamelCase(result)));
	}

	updateAdditionalSetting(settings: any): Observable<boolean> {
		const url = `crt/settings/${settings.referenceId ?? 0}`;
		return this.apiService
			.put(url, objectUtil.mapCamelCaseToPascalCase(settings))
			.pipe(map((result) => Boolean(result)));
	}

	/** Merge Tags
	 * General:
	 * http://tapnzdevapi.azurewebsites.net/api/v2/crt/merge-tag/general/reference/0
	 *
	 * Business:
	 * http://tapnzdevapi.azurewebsites.net/api/v2/crt/merge-tag/business/reference/0
	 *
	 * Adviser:
	 * http://tapnzdevapi.azurewebsites.net/api/v2/crt/merge-tag/staff/reference/0
	 *
	 * FF:
	 * crt/merge-tag/<table>/reference/<AdviceProcessId or 0>/<section code>
	 * SAMPLE:
	 * crt/merge-tag/fact-find/reference/0/FPP
	 */
	getMergeTags(
		type: 'general' | 'business' | 'staff' | 'section' | 'client',
		table?: string,
		referenceId?: number,
		sectionCode?: string,
		isPost?: boolean
	) {
		const endpoint = 'crt/merge-tag';
		if (type !== 'section') {
			return this.apiService
				.get(`${endpoint}/${type}/reference/${referenceId || 0}`)
				.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
		} else {
			// If type is section, should fill up required params
			// table, referenceId, sectionCode
			// reference: https://bbtdigital.atlassian.net/wiki/spaces/TKB/pages/1390018672/Merge+Tag
			if (!table && !referenceId && !sectionCode) {
				return of(null);
			}
			if (!!isPost) {
				return this.apiService
					.post(`${endpoint}`, {
						ReferenceId: referenceId,
						DataType: [sectionCode],
						Table: [table],
					})
					.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
			} else {
				return this.apiService
					.get(
						`${endpoint}/${table}/reference/${
							referenceId ?? 0 // default is 0
						}/${sectionCode}`
					)
					.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
			}
		}
	}

	getCPPersonMergeTag(crtId: number, isPrimary = true) {
		const endpoint = 'crt/single-merge-tag';
		return this.apiService.post(endpoint, {
			CRTId: crtId,
			DataType: [isPrimary ? 'CP-FPP' : 'CP-FPD'],
			Table: 'client',
		});
	}

	// Document Services
	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.apiService.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.apiService.put<DocumentUploadState>(endpoint, body);
	}

	getDocumentFile(id: number) {
		return this.apiService.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.apiService.getExternalResource(url, { responseType: 'text' });
	}

	updateCPStatus(adviceProcessId: number, status: number): Observable<boolean> {
		return this.apiService
			.put(`adviceprocesses/customer-portal/${adviceProcessId}/${status}`, {
				AdviceProcessId: adviceProcessId,
				AdviceProcessCode: 'APM',
				CPStatus: status,
			})
			.pipe(map((result) => Boolean(result)));
	}
}
