<div class="container-fluid">
	<div class="row">
		<div class="col plus-icon">
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
			>
				<div class="col px-0 form-inline">
					<span class="font-weight-bold px-0 sidebar__title"
						>Total API:
						{{ lrInsuranceState.totalInforceAPI | currency }}</span
					>

					<!-- Add New Group -->
					<ng-container *appRestrictTo="['FAS']">
						<button
							type="button"
							[disabled]="isAddNewPolicy || isLoading"
							(click)="addNewLRGroup()"
							class="add-button-sidenav border-0 font-weight-bold md-20 tap-text-color-h6"
							id="lrAddNewGroup"
						>
							<i class="material-icons add-button-sidenav-plus">add</i>
						</button>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="col-auto float-right px-5 pb-2">
			<div class="row buttons-container d-flex align-items-center">
				<!-- <ng-container *ngIf="isLoading">
          <div
            class="d-flex flex-row bd-highlight justify-content-end pt-1"
          >
            <app-manual-loader-mini
              *ngIf="isLoading"
              [isLoading]="isLoading"
            ></app-manual-loader-mini>
          </div>
        </ng-container> -->

				<div *ngIf="!isLoading && (isSavingDocument || documentIsLoading)">
					<div class="d-flex flex-row bd-highlight justify-content-end pt-1">
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isSavingDocument || documentIsLoading"
						>
						</app-manual-loader-mini>
					</div>
				</div>

				<!-- Link Document -->
				<div
					*ngIf="
						!isAddNewGroup &&
						currentLinkedDocument &&
						(!isSavingDocument || !documentIsLoading)
					"
				>
					<div class="btn btn-sm document-button-container px-2 mr-1 mb-1">
						<i class="material-icons md-20 document-font-color">description</i>

						<span
							*ngIf="currentLinkedDocument.id"
							class="wrap-document document-font-color cursor-pointer"
							data-toggle="tooltip"
							title="{{
								'Policy Schedule - ' +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}"
							(click)="downloadLink(currentLinkedDocument.id)"
							target="_blank"
						>
							<span>{{
								"Policy Schedule - " +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}</span>
						</span>

						<div
							class="wrap-document document-font-color"
							*ngIf="!currentLinkedDocument.documentLink"
						>
							<span>{{
								"Policy Schedule - " +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}</span>
						</div>

						<ng-container *appRestrictTo="['FES']">
							<button
								type="button"
								class="border-0 document-button p-0 pl-1"
								(click)="unlinkedDocument()"
								[disabled]="isSaving"
								id="lrUnlinkDocument"
							>
								<span class="material-icons pt-1"> close </span>
							</button>
						</ng-container>
					</div>
					<ng-container *appRestrictTo="['FES']">
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm mr-2 mb-1"
							[disabled]="isSaving"
							(click)="linkDocument()"
							id="lrReplaceDocument"
						>
							Replace Policy Document
						</button>
					</ng-container>
				</div>



				<ng-container *appRestrictTo="['FES']">
					<div
						*ngIf="
							!isAddNewGroup &&
							!currentLinkedDocument &&
							lrInsuranceState &&
							lrInsuranceState.lRs.length > 0
						"
					>
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm"
							[disabled]="isAddNewPolicy && isSaving"
							(click)="linkDocument()"
							id="lrLinkDocument"
						>
							Link Document
						</button>
					</div>
				</ng-container>

				<ng-container *ngIf="claimsFeature">
					<div
						*ngIf="
							!isAddNewGroup &&
							lrInsuranceState &&
							lrInsuranceState.lRs.length > 0 &&
							activeLR?.color==='green'
						"
					>
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm"
							[disabled]="isSaving"
							(click)="openClaimsAdvice()"
							id="lrCreateClaim"
						>
							Create Claim
						</button>
					</div>
				</ng-container>

				<!-- Add New Split -->
				<ng-container *appRestrictTo="['FAS']">
					<button
						*ngIf="!isAddNewGroup && lrInsuranceState.lRs.length > 0"
						type="button"
						class="tap-btn tap-btn--default btn-sm h-28px"
						[disabled]="isAddNewPolicy && isSaving"
						(click)="addPolicyAlteration()"
						id="lrPolicyAlteration"
					>
						Policy Alteration
					</button>
				</ng-container>

				<!-- Show Archive -->
				<ng-container *appRestrictTo="['FVAS']">
					<div *ngIf="!isAddNewGroup && lrInsuranceState.lRs.length > 0">
						<label for="showArchive" class="mr-2">Show Archived</label>
						<app-checkbox
							class="clickable d-inline-block"
							style="transform: translate(0px, 4px)"
						>
							<input
								type="checkbox"
								[checked]="!!isShowArchived"
								name="showArchive"
								id="lrShowArchive"
								class="checkbox"
								(change)="showArchived($event.target.checked)"
							/>
						</app-checkbox>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="row next-review-date p-0 m-0 mb-3" [formGroup]="form">
		<div class="col-5 p-0 d-flex align-items-center">
			<label>Next Review:</label>
		</div>
		<div class="col-7 p-0">
			<app-date-input
				textboxId="lRNextReview"
				formControlName="lRNextReview"
				textboxClass="theme-crm-field-disabled"
				placeholder="dd/mm/yyyy"
				style="flex: 1"
				useActionButtons="true"
				[isSavingDate]="isSavingNRD"
				(onSaveDateEvent)="updateReviewDate()"
			>
			</app-date-input>
		</div>
	</div>

	<div class="row main-content">
		<div class="col">
			<ng-container *ngIf="isLoading">
				<app-manual-loader
					[isLoading]="isLoading"
					[opts]="{
						lines: 9,
						length: 27,
						width: 20,
						radius: 45,
						scale: 0.5
					}"
				></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!isLoading">
				<tabset #sidebarNav class="tap-tabset-sidebar lr-insurance-service-tabs row mx-0" [vertical]="true">
					<ng-template
						ngFor
						let-lr
						[ngForOf]="lrInsuranceState.lRs"
						let-i="index"
						[ngForTrackBy]="trackByFn"
					>
						<ng-container
							*ngIf="
								lr?.isActive !== 2 || (lr?.isActive === 2 && isShowArchived)
							"
						>
							<tab
								(selectTab)="selectGroupConfirm(lr)"
								id="{{ lr.provider + '-' + lr.policyNumber }}"
								[disabled]="documentIsLoading"
								[customClass]="
									lr.color === 'green'
										? 'nav-item--success'
										: lr.color === 'yellow'
										? 'nav-item--warning'
										: lr.color === 'red'
										? 'nav-item--danger'
										: lr.color === 'dark'
										? 'nav-item--dark'
										: ''
								"
							>
								<ng-template tabHeading>
									<ng-container *ngIf="lr.provider; else tabHeadingAdd">
										<strong>{{ lr.provider + " - " + lr.policyNumber }}</strong
										><br />
										<span class="font-italic"
											>Inforce API: {{ lr.aPI | currency }}</span
										><br />
										<span>Start Date: {{ lr.startDate | momentDatetime }}</span>
										<i
											class="material-icons arrow-right md-20 d-flex align-items-center"
											>chevron_right</i
										>
									</ng-container>
								</ng-template>

								<div class="col px-0 px-0 px-md-3 pr-lg-0">
									<div class="container-fluid px-0 px-md-3 pr-lg-0">
										<ng-container
											*ngIf="
												isAddNewPolicy &&
												((lr.provider === this.activeProvider &&
													lr.policyNumber === this.activePolicyNumber) ||
													(!lr.provider && !lr.policyNumber))
											"
										>
											<app-lr-insurance-service-form
												#addForm
												id="lr_{{ i }}"
												[isLead]="isLead"
												[isCompany]="isCompany"
												[addMode]="true"
												[isNewGroup]="isAddNewGroup"
												[isSaving]="isSaving"
												[lr]="lrFormState"
												[providers]="providers"
												[policyStatuses]="policyStatuses"
												[products]="products"
												[policyTypes]="policyTypes"
												[claimStatus]="claimStatus"
												[discounts]="discounts"
												[payments]="payments"
												[cancellationCodes]="cancellationCodes"
												[retentionStatus]="retentionStatus"
												[policyOwners]="policyOwners"
												[groupName]="
													lr.provider
														? lr.provider + ': ' + lr.policyNumber
														: 'New Group'
												"
												[formId]="lr.provider + '-' + lr.policyNumber"
												[activeTabId]="activeTabId"
												(saveEvent)="confirmCreate($event)"
												(cancelAddEvent)="
													lr.provider && lr.policyNumber
														? cancelAddPolicyAlteration()
														: cancelNewGroup()
												"
												[deceasedClients]="deceasedClients"
											>
											</app-lr-insurance-service-form>
										</ng-container>

										<ng-container>
											<ng-template
												ngFor
												let-data
												[ngForOf]="lr.customerServices"
												let-i="index"
												[ngForTrackBy]="trackById"
											>
												<ng-container
													*ngIf="
														data.isActive !== 2 ||
														(data?.isActive === 2 && isShowArchived)
													"
												>
													<app-lr-insurance-service-form
														id="lr_{{ i }}"
														#lrForms
														[isLead]="isLead"
														[isCompany]="isCompany"
														[class.d-none]="data.isActive === 2 && !isShowArchived"
														[addMode]="false"
														[isSaving]="isSaving"
														[lr]="data"
														[providers]="providers"
														[policyStatuses]="policyStatuses"
														[products]="products"
														[policyTypes]="policyTypes"
														[claimStatus]="claimStatus"
														[discounts]="discounts"
														[payments]="payments"
														[cancellationCodes]="cancellationCodes"
														[retentionStatus]="retentionStatus"
														[policyOwners]="policyOwners"
														[groupName]="lr.provider + ': ' + lr.policyNumber"
														[formId]="lr.provider + '-' + lr.policyNumber + i"
														[lrList]="lrList"
														(saveEvent)="confirmSaveLR($event)"
														(deleteEvent)="confirmDeleteLr($event)"
														(archiveEvent)="archiveLr($event)"
														(splitEvent)="split($event)"
														(deleteNoteEvent)="deleteNote($event)"
														(addNoteEvent)="addNote($event)"
														[deceasedClients]="deceasedClients"
														[lrClaims]="lrClaimsList?.[data.customerServiceID]??[]"
														[redirectToAP$]="redirectToAP$"
														[claimsFeature]="claimsFeature"
														[activeTabId]="activeTabId"
													>
													</app-lr-insurance-service-form>
												</ng-container>
											</ng-template>
										</ng-container>
									</div>
								</div>
							</tab>
						</ng-container>
					</ng-template>
				</tabset>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #tabHeadingAdd>
	<div class="mt-2">
		<strong>Add New Policy</strong>
	</div>
	<i class="material-icons arrow-right md-20 d-flex align-items-center"
		>chevron_right</i
	>
</ng-template>
