import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MortgageSettingsModule } from 'src/app/modules/mortgage-settings/mortgage-settings.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CrtSharedModule } from '../_shared/crt-shared.module';
import { ApplicationListComponent } from './application/application-list/application-list.component';
import { ApplicationStepsComponent } from './application/application-steps/application-steps.component';
import { DocumentsComponent } from './application/application-steps/documents/documents.component';
import { ApplicationDocumentQuery } from './application/application-steps/documents/state/documents.query';
import { ApplicationDocumentService } from './application/application-steps/documents/state/documents.service';
import { ApplicationDocumentStore } from './application/application-steps/documents/state/documents.store';
import { CashDepositFormComponent } from './application/application-steps/funding-required/cash-deposit/cash-deposit-form/cash-deposit-form.component';
import { CashDepositComponent } from './application/application-steps/funding-required/cash-deposit/cash-deposit.component';
import { CashDepositQuery } from './application/application-steps/funding-required/cash-deposit/state/cash-deposit.query';
import { CashDepositService } from './application/application-steps/funding-required/cash-deposit/state/cash-deposit.service';
import { CashDepositStore } from './application/application-steps/funding-required/cash-deposit/state/cash-deposit.store';
import { PropertyPurchaseFormComponent } from './application/application-steps/funding-required/property-purchase/property-purchase-form/property-purchase-form.component';
import { PropertyPurchaseComponent } from './application/application-steps/funding-required/property-purchase/property-purchase.component';
import { PropertyPurchaseQuery } from './application/application-steps/funding-required/property-purchase/state/property-purchase.query';
import { PropertyPurchaseService } from './application/application-steps/funding-required/property-purchase/state/property-purchase.service';
import { PropertyPurchaseStore } from './application/application-steps/funding-required/property-purchase/state/property-purchase.store';
import { TopupRequirementsQuery } from './application/application-steps/funding-required/topup-requirements/state/topup-requirements.query';
import { TopupRequirementsService } from './application/application-steps/funding-required/topup-requirements/state/topup-requirements.service';
import { TopupRequirementsStore } from './application/application-steps/funding-required/topup-requirements/state/topup-requirements.store';
import { LoanRepaidFormComponent } from './application/application-steps/funding-required/selling-section/loan-repaid/loan-repaid-form/loan-repaid-form.component';
import { LoanRepaidComponent } from './application/application-steps/funding-required/selling-section/loan-repaid/loan-repaid.component';
import { PropertySoldFormComponent } from './application/application-steps/funding-required/selling-section/property-sold/property-sold-form/property-sold-form.component';
import { PropertySoldComponent } from './application/application-steps/funding-required/selling-section/property-sold/property-sold.component';
import { PropertySoldQuery } from './application/application-steps/funding-required/selling-section/property-sold/state/property-sold.query';
import { PropertySoldService } from './application/application-steps/funding-required/selling-section/property-sold/state/property-sold.service';
import { PropertySoldStore } from './application/application-steps/funding-required/selling-section/property-sold/state/property-sold.store';
import { FundingRequiredLoanQuery } from './application/application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.query';
import { FundingRequiredLoanService } from './application/application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.service';
import { FundingRequiredLoanStore } from './application/application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.store';
import { TopupRequirementsComponent } from './application/application-steps/funding-required/topup-requirements/topup-requirements.component';
import { TopupRequirementsFormComponent } from './application/application-steps/funding-required/topup-requirements/topup-requirements-form/topup-requirements-form.component';
import { SellingSectionComponent } from './application/application-steps/funding-required/selling-section/selling-section.component';
import { NotesComponent } from './application/application-steps/notes/notes.component';
import { NoteQuery } from './application/application-steps/notes/state/note.query';
import { NoteService } from './application/application-steps/notes/state/note.service';
import { NoteStore } from './application/application-steps/notes/state/note.store';
import { PurposeComponent } from './application/application-steps/purpose/purpose.component';
import { PurposeQuery } from './application/application-steps/purpose/state/purpose.query';
import { PurposeService } from './application/application-steps/purpose/state/purpose.service';
import { PurposeStore } from './application/application-steps/purpose/state/purpose.store';
import { ReviewApplicationComponent } from './application/application-steps/review-application/review-application.component';
import { ReviewApplicationQuery } from './application/application-steps/review-application/state/review-application.query';
import { ReviewApplicationService } from './application/application-steps/review-application/state/review-application.service';
import { ReviewApplicationStore } from './application/application-steps/review-application/state/review-application.store';
import { SecurityComponent } from './application/application-steps/security/security.component';
import { SecurityQuery } from './application/application-steps/security/state/security.query';
import { SecurityService } from './application/application-steps/security/state/security.service';
import { SecurityStore } from './application/application-steps/security/state/security.store';
import { ApplicationStepsService } from './application/application-steps/state/application-steps.service';
import { ApplicationComponent } from './application/application.component';
import { ApplicationQuery } from './application/state/application.query';
import { ApplicationService } from './application/state/application.service';
import { ApplicationStore } from './application/state/application.store';
import { AssetsAndLiabilitiesComponent } from './client-sop/assets-and-liabilities/assets-and-liabilities.component';
import { AssetQuery } from './client-sop/assets-and-liabilities/state/asset/asset.query';
import { AssetService } from './client-sop/assets-and-liabilities/state/asset/asset.service';
import { AssetStore } from './client-sop/assets-and-liabilities/state/asset/asset.store';
import { KiwiSaverQuery } from './client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.query';
import { KiwiSaverService } from './client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.service';
import { KiwiSaverStore } from './client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.store';
import { LiabilityQuery } from './client-sop/assets-and-liabilities/state/liability/liability.query';
import { LiabilityService } from './client-sop/assets-and-liabilities/state/liability/liability.service';
import { LiabilityStore } from './client-sop/assets-and-liabilities/state/liability/liability.store';
import { MortgageQuery } from './client-sop/assets-and-liabilities/state/mortgage/mortgage.query';
import { MortgageService } from './client-sop/assets-and-liabilities/state/mortgage/mortgage.service';
import { MortgageStore } from './client-sop/assets-and-liabilities/state/mortgage/mortgage.store';
import { OtherQuery } from './client-sop/assets-and-liabilities/state/other/other.query';
import { OtherService } from './client-sop/assets-and-liabilities/state/other/other.service';
import { OtherStore } from './client-sop/assets-and-liabilities/state/other/other.store';
import { PropertyQuery } from './client-sop/assets-and-liabilities/state/property/property.query';
import { PropertyService } from './client-sop/assets-and-liabilities/state/property/property.service';
import { PropertyStore } from './client-sop/assets-and-liabilities/state/property/property.store';
import { ExpensesComponent } from './client-sop/expenses/expenses.component';
import { ExpenseQuery } from './client-sop/expenses/state/expenses.query';
import { ExpenseService } from './client-sop/expenses/state/expenses.service';
import { ExpenseStore } from './client-sop/expenses/state/expenses.store';
import { IncomeComponent } from './client-sop/income/income.component';
import { IncomeQuery } from './client-sop/income/state/income.query';
import { IncomeService } from './client-sop/income/state/income.service';
import { CompanyComponent } from './client-sop/people-entities/company/company.component';
import { DependantComponent } from './client-sop/people-entities/dependant/dependant.component';
import { PeopleEntitiesComponent } from './client-sop/people-entities/people-entities.component';
import { PeopleComponent } from './client-sop/people-entities/people/people.component';
import { PeopleEntitiesQuery } from './client-sop/people-entities/state/people-entities.query';
import { PeopleEntitiesService } from './client-sop/people-entities/state/people-entities.service';
import { TrustComponent } from './client-sop/people-entities/trust/trust.component';
import { CrtMortgageResolver } from './crt-mortgage-resolver';
import { CrtMortgageRoutingModule } from './crt-mortgage-routing.module';
import { CrtMortgageComponent } from './crt-mortgage.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { DeclarationQuery } from './declaration/state/declaration.query';
import { DeclarationService } from './declaration/state/declaration.service';
import { DeclarationStore } from './declaration/state/declaration.store';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { DisclosureQuery } from './disclosure/state/disclosure.query';
import { DisclosureService } from './disclosure/state/disclosure.service';
import { IntroComponent } from './intro/intro.component';
import { MoatIntroService } from './intro/state/moat-intro.service';
import { ScopeOfServicesComponent } from './scope-of-services/scope-of-services.component';
import { ScopeOfServiceService } from './scope-of-services/state/scope-of-service.service';
import { CrtMortgageQuery } from './state/crt-mortgage.query';
import { CrtMortgageService } from './state/crt-mortgage.service';
import { CrtMortgageStore } from './state/crt-mortgage.store';
import { MergeTagsQuery } from './state/merge-tags/merge-tags.query';
import { MergeTagsService } from './state/merge-tags/merge-tags.service';
import { MortgageAdviceProcessService } from './state/mortgage-adviceprocess/mortgage-advice-process.service';
import { LoanComponent } from './structure-soa/loan/loan.component';
import { LoanQuery } from './structure-soa/loan/state/loan.query';
import { LoanService } from './structure-soa/loan/state/loan.service';
import { LoanStore } from './structure-soa/loan/state/loan.store';
import { StructureSoaQuery } from './structure-soa/state/structure-soa.query';
import { StructureSoaService } from './structure-soa/state/structure-soa.service';
import { StructureSoaStore } from './structure-soa/state/structure-soa.store';
import { StructureSoaComponent } from './structure-soa/structure-soa.component';
import { OtherDetailsComponent } from './structure-soa/other-details/other-details.component';
import { OtherDetailsQuery } from './structure-soa/other-details/state/other-details.query';
import { OtherDetailsService } from './structure-soa/other-details/state/other-details.service';
import { OtherDetailsStore } from './structure-soa/other-details/state/other-details.store';
import { StatementOfAdviceComponent } from './structure-soa/statement-of-advice/statement-of-advice.component';
import { SoaService } from './structure-soa/statement-of-advice/state/soa.service';
import { LoanRefinanceComponent } from './application/application-steps/funding-required/loan-refinance/loan-refinance.component';
import { LoanRefinanceFormComponent } from './application/application-steps/funding-required/loan-refinance/loan-refinance-form/loan-refinance-form.component';
import { LoanRefinanceService } from './application/application-steps/funding-required/loan-refinance/state/loan-refinance.service';
import { LoanRefinanceQuery } from './application/application-steps/funding-required/loan-refinance/state/loan-refinance.query';
import { LoanRefinanceStore } from './application/application-steps/funding-required/loan-refinance/state/loan-refinance.store';
import { CrtMortgageNoteQuery } from './state/note/crt-mortgage-note.query';
import { CrtMortgageNoteService } from './state/note/crt-mortgage-note.service';
import { CrtMortgageNoteStore } from './state/note/crt-mortgage-note.store';
import { PeopleModalComponent } from '../../../../shared/modal/crt/moat/people/people-modal.component';
import { CompanyFormComponent } from '../../../../shared/modal/crt/moat/people/company-form/company-form';
import { PeopleFormComponent } from '../../../../shared/modal/crt/moat/people/people-form/people-form';
import { AssetsAndLiabilitiesService } from '../crt-mortgage/client-sop/assets-and-liabilities/assets-and-liabilities.service';
import { StructureSoaSecurityComponent } from './structure-soa/security/structure-soa-security.component';
import { LoanStructureComponent } from './application/application-steps/loan-structure/loan-structure.component';
import { LoanStructureService } from './application/application-steps/loan-structure/state/loan-structure.service';
import { LoanStructureStore } from './application/application-steps/loan-structure/state/loan-structure.store';
import { LoanStructureQuery } from './application/application-steps/loan-structure/state/loan-structure.query';
import { MortgageProviderCommissionQuery } from './provider-comission/state/provider-commission.query';
import { MortgageProviderCommissionService } from './provider-comission/state/provider-commission.service';
import { MortgageProviderCommissionStore } from './provider-comission/state/provider-commission.store';

import { CrtMortgageCanDeactivateGuard } from './crt-mortgage-can-deactivate.guard';
import { CrtMortgageApplicationStepsCanDeactivateGuard } from './application/application-steps/crt-mortgage-application-steps-can-deactivate.guard';
import { CrtMortgageApplicationStepsCanActivateGuard } from './application/application-steps/crt-mortgage-application-steps-can-activate.guard';
import { StructureSOACanDeactivateGuard } from './structure-soa/structure-soa-can-deactivate.guard';

@NgModule({
	declarations: [
		CrtMortgageComponent,
		IntroComponent,
		AssetsAndLiabilitiesComponent,
		DisclosureComponent,
		ExpensesComponent,
		IncomeComponent,
		DeclarationComponent,
		ApplicationComponent,
		ScopeOfServicesComponent,
		PeopleEntitiesComponent,
		PeopleComponent,
		DependantComponent,
		TrustComponent,
		CompanyComponent,
		PeopleFormComponent,
		ApplicationListComponent,
		ApplicationStepsComponent,
		DocumentsComponent,
		PurposeComponent,
		SecurityComponent,
		PropertyPurchaseComponent,
		PropertyPurchaseFormComponent,
		TopupRequirementsComponent,
		TopupRequirementsFormComponent,
		NotesComponent,
		CashDepositComponent,
		CashDepositFormComponent,
		SellingSectionComponent,
		PropertySoldComponent,
		PropertySoldFormComponent,
		LoanRepaidComponent,
		LoanRepaidFormComponent,
		StructureSoaComponent,
		LoanComponent,
		OtherDetailsComponent,
		ReviewApplicationComponent,
		StatementOfAdviceComponent,
		LoanRefinanceComponent,
		LoanRefinanceFormComponent,
		PeopleModalComponent,
		CompanyFormComponent,
		StructureSoaSecurityComponent,
		LoanStructureComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		CrtMortgageRoutingModule,
		CrtSharedModule,
		MortgageSettingsModule,
	],
	providers: [
		CrtMortgageResolver,
		CrtMortgageService,
		CrtMortgageStore,
		CrtMortgageQuery,
		CrtMortgageCanDeactivateGuard,
		CrtMortgageApplicationStepsCanActivateGuard,
		CrtMortgageApplicationStepsCanDeactivateGuard,
		StructureSOACanDeactivateGuard,
		MortgageAdviceProcessService,
		MoatIntroService,
		PurposeService,
		PurposeStore,
		PurposeQuery,
		NoteService,
		NoteStore,
		NoteQuery,
		DeclarationStore,
		DeclarationService,
		DeclarationQuery,
		DisclosureService,
		DisclosureQuery,
		MergeTagsService,
		MergeTagsQuery,
		ScopeOfServiceService,
		PeopleEntitiesService,
		PeopleEntitiesQuery,
		PropertyService,
		PropertyStore,
		PropertyQuery,
		KiwiSaverService,
		KiwiSaverStore,
		KiwiSaverQuery,
		MortgageService,
		MortgageStore,
		MortgageQuery,
		AssetService,
		AssetStore,
		AssetQuery,
		LiabilityService,
		LiabilityStore,
		LiabilityQuery,
		OtherService,
		OtherStore,
		OtherQuery,
		AssetsAndLiabilitiesService,
		IncomeService,
		IncomeQuery,
		ApplicationStepsService,
		ApplicationStore,
		ApplicationQuery,
		ApplicationService,
		ExpenseQuery,
		ExpenseService,
		ExpenseStore,
		ApplicationDocumentStore,
		ApplicationDocumentQuery,
		ApplicationDocumentService,
		PropertyPurchaseService,
		PropertyPurchaseQuery,
		PropertyPurchaseStore,
		PropertySoldService,
		PropertySoldQuery,
		PropertySoldStore,
		FundingRequiredLoanService,
		FundingRequiredLoanQuery,
		FundingRequiredLoanStore,
		TopupRequirementsQuery,
		TopupRequirementsService,
		TopupRequirementsStore,
		SecurityService,
		SecurityStore,
		SecurityQuery,
		CashDepositService,
		CashDepositQuery,
		CashDepositStore,
		ReviewApplicationService,
		ReviewApplicationQuery,
		ReviewApplicationStore,
		StructureSoaService,
		StructureSoaStore,
		StructureSoaQuery,
		LoanService,
		LoanStore,
		LoanQuery,
		OtherDetailsService,
		OtherDetailsStore,
		OtherDetailsQuery,
		SoaService,
		LoanRefinanceService,
		LoanRefinanceQuery,
		LoanRefinanceStore,
		CrtMortgageNoteService,
		CrtMortgageNoteStore,
		CrtMortgageNoteQuery,
		LoanStructureQuery,
		LoanStructureStore,
		LoanStructureService,
		MortgageProviderCommissionService,
		MortgageProviderCommissionQuery,
		MortgageProviderCommissionStore,
	],
})
export class CrtMortgageModule {}
