import { DownloadableDocumentModel } from './templates-utils/templates.model';

const signature = (people) => {
	let result = [];
	for (let v of people) {
		result.push(`
    <p><span style="font-size: 12px;"><span
      style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Name:
      ____________________________ Signed: _____________________________</span></span></p>
    <p><span style="font-size: 12px;"><br></span></p>
    `);
	}
	return result.join('');
};

const signatureDefault = () => {
	return `
  <p><span style="font-size: 12px;"><span
  style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Name:
  ____________________________ Signed: _____________________________</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  `;
};

export const createDeedOfAcknowledgementTemplate = (
	data: DownloadableDocumentModel
) => {
	const peopleName =
		data.peopleName && data.peopleName.length > 0
			? data.peopleName.join(', ')
			: '';
	const propertyAddresses =
		data.propertyAddresses && data.propertyAddresses.length > 0
			? data.propertyAddresses.join(', ')
			: '';

	return `
  <p style="text-align: center;">
    <span style="font-size: 12px; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
      <img src="${
				data.businessLogo
			}" alt="Business Logo" style="width: 250px; height: 70px;"/>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p>
    <span style="font-size: 12px;">
      <span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
        Date: ${data.dateToday}
      </span>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 24px;"><span
        style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Deed
        of Acknowledgement of Debt</span></span></p>
  <p><span style="font-size: 12px;"><span
        style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">To
        whom it may concern:</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span
        style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">I
        _______________________ of _______________________ hereby declare that I am lending to ${peopleName} the sum of
        $${
					data.depositGift && data.depositGift > 0 ? data.depositGift : 0
				} for the sole purpose of purchasing the property at ${propertyAddresses}.</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span
        style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">I
        declare that no repayments of principal or interest are required, that no subsequent charges will be registered
        against the property and that the principal balance of $${
					data.depositGift && data.depositGift > 0 ? data.depositGift : 0
				} will only be repayable upon the sale of the
        property. I also understand that ${
					data.applicationBank ? data.applicationBank : ''
				} first mortgage interests as a mortgagee rank before mine as an
        unsecured lender.&nbsp;</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  ${
		data.peopleName && data.peopleName.length > 0
			? signature(data.peopleName)
			: signatureDefault()
	}
  `;
};
