import { SettingsTypes } from '../../state/crt-settings.model';
import { IntroSettingsState } from './intro-settings.model';

export class IntroSettingsMapper {
  public static mapToView(data: IntroSettingsState) {
    return {
      referenceId: 0,
      type: SettingsTypes.Introduction,
      intro: data?.intro ?? '',
      introType: data?.introType,
      introTypeBD: data?.introType === 'BD',
      introTypeUD: data?.introType === 'UD',
      documentID: data?.documentID,
    };
  }

  public static mapDocumentUpload(document, base64) {
    return {
      type: 'CRTI',
      referenceId: 0,
      document: base64,
      fileName: document?.name,
    };
  }

  public static mapToUpsert(data: IntroSettingsState, newDoc) {
    return {
      referenceId: 0,
      type: SettingsTypes.Introduction,
      introType: !!data?.introTypeBD ? 'BD' : 'UD',
      intro: data?.intro || '',
      documentID: data?.documentID || '',
    };
  }
}
