import { Component, OnInit } from '@angular/core';
import { SendTestEmailQuery } from './state/send-test-email.query';

@Component({
	selector: 'app-send-test-email',
	templateUrl: './send-test-email.component.html'
})
export class SendTestEmailComponent implements OnInit {

	public emailType$ = this.sendTestEmailQuery.emailType$;
	public contentMergeTages$ = this.sendTestEmailQuery.contentMergeTages$;

	constructor(private sendTestEmailQuery: SendTestEmailQuery) { }

	ngOnInit(): void {
	}

}
