import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	ALLOWED_DOCUMENT_FILE_TYPES,
	UploadModalComponent,
} from '../upload-modal/upload-modal.component';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { convertUtil } from '@util/util';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlStaffSettingsModel } from '@shared/models/_general/bl-staff.model';
import { util } from '@core/util/util.service';
import MomentUtil from '@util/moment.util';
import { RouteService } from '@core/config/route.service';
import { Router } from '@angular/router';
import { validMomentDate } from '@shared/validator/valid-moment-date/valid-moment-date';
import { LoggerService } from '@core/logger/logger.service';
import * as moment from 'moment';

@Component({
	selector: 'app-user-qualification-modal',
	templateUrl: './user-qualification-modal.component.html',
	styleUrls: ['./user-qualification-modal.component.scss'],
})
export class UserQualificationModalComponent implements OnInit {
	title: string = 'User Qualification';
	data: BlStaffSettingsModel;
	qualificationName: string;
	qualificationId: string;
	documentTypeCode: string;
	type: string;
	btnText: string;
	limitSingleDoc: boolean;
	isLoading: boolean;
	isOtherQualification: boolean;
	staffId: number;
	form: FormGroup;
	institute: ViewDisplayValue[] = [];
	documents = [];
	polytechDocuments = [];
	submitted = false;
	maxDate = util.MomentNow();

	upsertStaffQualificationFn$: (
		ac: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel>;
	downloadDocumentFn$: (ac: {
		documentId: number;
		fileName: string;
	}) => Observable<any>;

	public bsModalRefUpload: BsModalRef;

	get QualificationName() {
		return this.form.get('qualificationName');
	}
	get Institute() {
		return this.form.get('institute');
	}
	get CompletedDate() {
		return this.form.get('completedDate');
	}
	get PolytechCompletedDate() {
		return this.form.get('polytechCompletedDate');
	}

	constructor(
		private fb: FormBuilder,
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private crtDocService: CrtDocumentService,
		private routeService: RouteService,
		private loggerService: LoggerService,
		private router: Router
	) {
		this.form = this.fb.group({
			qualificationName: [''],
			institute: [''],
			completedDate: ['', [Validators.required, validMomentDate()]],
			polytechCompletedDate: [''],
		});
	}

	ngOnInit(): void {
		if (!!this.data) {
			this.form.patchValue({
				qualificationName: this.data?.QualificationName,
				institute: this.data?.Institute,
				completedDate: this.formatValidDate(this.data?.CompletedDate),
				polytechCompletedDate: this.formatValidDate(
					this.data?.PolytechCompletedDate
				),
			});
			if (this.data?.Documents?.length > 0) {
				this.documents = this.data?.Documents;
			}
			if (this.data?.PolytechDocuments?.length > 0) {
				this.polytechDocuments = this.data?.PolytechDocuments;
			}
		} else {
			this.form.patchValue({
				qualificationName: this.isOtherQualification
					? ''
					: this.qualificationName,
			});
		}
		this.setValidators();
	}

	setValidators() {
		if (this.qualificationId === 'coreKnowledge') {
			this.Institute.setValidators(Validators.required);
			if (this.Institute.value === 'Open Polytech') {
				this.PolytechCompletedDate.setValidators([
					Validators.required,
					validMomentDate(),
				]);
			} else {
				this.PolytechCompletedDate.clearValidators();
			}
		} else {
			this.Institute.clearValidators();
			this.PolytechCompletedDate.clearValidators();
		}
		if (this.isOtherQualification) {
			this.QualificationName.setValidators(Validators.required);
		} else {
			this.QualificationName.clearValidators();
		}
		this.Institute.updateValueAndValidity();
		this.PolytechCompletedDate.updateValueAndValidity();
		this.QualificationName.updateValueAndValidity();
	}

	formatValidDate(value) {
		return util.isValidMoment(value)
			? value
			: MomentUtil.formatDateToMoment(value);
	}

	uploadDocument(isPolyDocument?: boolean) {
		const formValue = this.form.getRawValue();
		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					convertUtil.simpleConvertToBase64(x[0]).pipe(
						map((file) => ({
							content: (file as string)?.replace(/^data:(.*,)?/, ''),
							filename: x[0]?.name || '',
						}))
					)
				),
				map((file: any) => ({
					Document: file.content,
					FileName: file.filename,
					DocumentName: formValue?.qualificationName || '',
					DocumentType: this.documentTypeCode,
					Type: this.type,
					ReferenceId: this.staffId,
					CustomerId: 0,
				})),
				concatMap((file) =>
					this.crtDocService.uploadDocument(file).pipe(
						map((x) => ({
							...file,
							ReferenceId: +x,
							Value: file?.FileName,
						}))
					)
				),
				tap((x) => {
					if (isPolyDocument) {
						this.polytechDocuments = [...this.polytechDocuments, x];
					} else {
						this.documents = [...this.documents, x];
					}
				}),
				take(1)
			);

		const initialState = {
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Add New Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
			customUpload: upload,
		};
		this.bsModalRefUpload = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	removeDocument(index: number, data) {
		let docs = [...this.documents];
		docs = docs?.filter((x) => +x?.ReferenceId !== +data?.ReferenceId);
		this.documents = docs;
	}

	removePolyDocument(index: number, data) {
		let docs = [...this.polytechDocuments];
		docs = docs?.filter((x) => +x?.ReferenceId !== +data?.ReferenceId);
		this.polytechDocuments = docs;
	}

	saveQualification() {
		const docList = this.documents || [];
		const polyDocList = this.polytechDocuments || [];
		const formValue = this.form.getRawValue();
		const isPolyDocumentValid =
			formValue.institute === 'Open Polytech' ? polyDocList?.length > 0 : true;
		this.submitted = true;

		if (
			(!!this.CompletedDate.value &&
				moment(this.CompletedDate.value).isAfter(this.maxDate)) ||
			(formValue.institute === 'Open Polytech' &&
				!!this.PolytechCompletedDate.value &&
				moment(this.PolytechCompletedDate.value).isAfter(this.maxDate))
		) {
			this.loggerService.Warning(
				{},
				`Invalid Completed Date. Please select from past dates.`
			);
			return;
		}
		if (!this.form.valid || docList?.length === 0 || !isPolyDocumentValid) {
			return;
		}

		of(this.form.getRawValue())
			.pipe(
				map((formValue) => {
					return {
						QualificationId: this.qualificationId,
						QualificationName: formValue?.qualificationName || '',
						Institute: !!this.institute ? formValue.institute : null,
						CompletedDate: this.formatValidDate(formValue?.completedDate),
						Documents: docList?.filter((d) => !!d?.ReferenceId) || [],
						StaffId: this.staffId,
						StaffSettingsId: this.data?.StaffSettingsId,
						DocumentType: this.documentTypeCode,
						Type: this.type,
						PolytechCompletedDate: this.formatValidDate(
							formValue?.polytechCompletedDate
						),
						PolytechDocuments:
							polyDocList?.filter((d) => !!d?.ReferenceId) || [],
						SettingsCode: this.type,
					} as BlStaffSettingsModel;
				}),
				tap(() => (this.isLoading = true)),
				concatMap(this.upsertStaffQualificationFn$),
				catchError((error) => {
					if (error) {
						this.isLoading = false;
					}
					throw error;
				}),
				tap(() => {
					this.isLoading = false;
					this.close();
				}),
				take(1)
			)
			.subscribe();
	}

	viewDocument(data) {
		return of(data)
			.pipe(
				map((res) => ({
					documentId: res.ReferenceId,
					name: res?.Value || '',
				})),
				mergeMap((res) => {
					const ext = res?.name?.split('.')?.reverse()?.[0];
					if (!!ext && !!res?.documentId) {
						if (ext?.toLowerCase() === 'pdf') {
							return of(res).pipe(
								tap(() => {
									const pdfUrl = this.router.serializeUrl(
										this.router.createUrlTree(this.routeService.viewPdf(res))
									);
									window.open(pdfUrl, '_blank');
								}),
								take(1)
							);
						} else {
							return this.downloadDocumentFn$({
								documentId: res?.documentId,
								fileName: res?.name,
							});
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}

	close() {
		this.bsModalRef.hide();
	}
}
