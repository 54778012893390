import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import {
	DangerousPastimesParsedState,
	DropdownGoalsState,
	RetirementState,
} from '../../../../../shared/models/client-review-template/risk-analysis/goals/goals.model';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';
import { GoalsService } from '../../states/risk-analysis/goals/goals.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';

@Component({
	selector: 'app-goals',
	templateUrl: './goals.component.html',
	styleUrls: ['./goals.component.scss'],
})
export class GoalsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	shortTermGoals$ = new BehaviorSubject<DropdownGoalsState[]>(null);
	longTermGoals$ = new BehaviorSubject<DropdownGoalsState[]>(null);
	retireGoals$ = new BehaviorSubject<RetirementState[]>(null);
	clientGoals$ = new BehaviorSubject<DangerousPastimesParsedState[]>(null);
	cRTId$ = new BehaviorSubject<number>(null);

	APCRTGL$ = this.service.APCRTGL$;
	APCRTDP$ = this.service.APCRTDP$;
	people$ = this.service.people$.pipe(map((x) => x?.filter((p) => !!p.cRTId)));
	dependents$ = this.service.dependents$;
	goals$ = this.goalsService.goals$;

	isNew: boolean;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$

	constructor(
		private service: ClientReviewTemplateService,
		private goalsService: GoalsService,
		private query: ClientReviewTemplateQuery
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
    this.goals$
    .pipe(
      filter((x) => !!x),
        tap((x) => {
        this.cRTId$.next(x?.cRTId);
        this.shortTermGoals$.next(x?.shortTermGoals);
        this.longTermGoals$.next(x?.longTermGoals);
        this.retireGoals$.next(x?.retirements);
        this.clientGoals$.next(x?.dangerousPastimes);
      }),
      take(1)
    )
    .subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
