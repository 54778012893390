export const declarationMetaKey = {
	// People
	peopleIncome: 'PEOPLE_NAME',
	peopleReference: 'PEOPLE_NAME_WITH_REFERENCE',
	peopleResidency: 'PEOPLE_RESIDENCY_STATUS',
	peopleIncomePrimary: 'PEOPLE_INCOME_PRIMARY',
	peopleIncomeSecondary: 'PEOPLE_INCOME_SECONDARY',
	peoplePrimaryTotalGross: 'PEOPLE_PRIMARY_INCOME_TOTAL_GROSS',
	peopleSecondaryTotalGross: 'PEOPLE_SECONDARY_INCOME_TOTAL_GROSS',
	// Income
	netRentalIncome: 'NET_RENTAL_INCOME',
	otherIncomeType: 'OTHER_INCOME_TYPE',
	incomePeople: 'INCOME_PEOPLE_NAME',
	incomePrevious: 'INCOME_PREVIOUS',
	incomePrimaryPeople: 'INCOME_PRIMARY_PEOPLE',
	incomeCurrentPeople: 'INCOME_CURRENT_PEOPLE_NAME',
	incomePreviousPeople: 'INCOME_PREVIOUS_PEOPLE_NAME',
	incomePreviousEmployment: 'INCOME_PREVIOUS_EMPLOYMENT',
	incomePreviousRole: 'INCOME_PREVIOUS_ROLE',
	incomePreviousEmployer: 'INCOME_PREVIOUS_EMPLOYER',
	// Expenses
	expensesKiwiSaverNotContinuing:
		'EXPENSES_KIWISAVER_CONTRIBUTION_NOT_CONTINUING',
	expensesListNotContinuing: 'EXPENSES_LIST_NOT_CONTINUING',
	// Others
	notContinuing: 'NOT_CONTINUING',
};

// This are the merge tags that are inside repeat table row
export const repeatListNotContinuing = [
	declarationMetaKey.expensesKiwiSaverNotContinuing,
	declarationMetaKey.expensesListNotContinuing,
];
