import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AssetState, AssetStore } from './asset.store';

@Injectable()
export class AssetQuery extends QueryEntity<AssetState> {
	assets$ = this.selectAll({ filterBy: (x) => !!x.status });

	constructor(protected store: AssetStore) {
		super(store);
	}
}
