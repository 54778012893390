import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from '@angular/core';
import {
	IChartistData,
	IChartistAnimationOptions,
	IBarChartOptions,
} from 'chartist';
import { ChartType, ChartEvent } from 'ng-chartist';
import * as R from 'ramda';
import * as Chartist from 'chartist';
import 'chartist-plugin-legend';
import 'chartist-plugin-tooltip';
import { numUtil } from '../../util/util';
import * as numeral from 'numeral';
import '../../../assets/js/chartist-plugin-bar-overlap';

declare var $: any;

@Component({
	selector: 'app-bipolar-bar-chart',
	templateUrl: './bipolar-bar-chart.component.html',
	styleUrls: ['./bipolar-bar-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BipolarBarChartComponent implements OnInit {
	@Input() chartId = 'bar-chart';
	/**
	 * chartist data
	 */
	@Input() data: IChartistData = {
		series: [],
		labels: [],
	};

	@Input() showCurrencySignAxisY = false;

	@Input() displayAdditionalData = false;
	@Input() enableBarOverlap = false;

	/**
	 * Type of chartist to generate.
	 * Could be readonly?
	 */
	type: ChartType = 'Bar';
	/**
	 * Chartist bar chart options
	 */
	options: IBarChartOptions = {
		axisX: {
			showGrid: true,
			showLabel: true,
		},
		axisY: {
			showGrid: true,
			showLabel: true,
			offset: 80,
			labelInterpolationFnc: (value: number) => {
				return this.formatValue(value);
			},
			labelOffset:{ // TAP1-3790 Number alignment
				y:6.5
			},
		},
		height: '300px',
		width: '100%',
		distributeSeries: false,
		plugins: [
			Chartist.plugins.legend({
				clickable: false,
			}),

			Chartist.plugins.tooltip({
				valueTransform: (value: number) => {
					return this.formatValue(value);
				},
				seriesName: false,
			}),
		],
	};
	/**
	 * Sort of lifecycle hook for chartist.
	 */
	events: ChartEvent;

	/**
	 * Stroke colors
	 */
	strokes: string[] = ['#5b9bd5', '#70ad47', '#e66c6c'];

	constructor(private cd: ChangeDetectorRef) {}

	/**
	 * For Manual refresh. Chartist doesn't expose its renderChart method
	 * so we'll refresh the data instead
	 */
	public refresh() {
		this.data = R.clone(this.data);
		this.cd.detectChanges();
	}

	/**
	 * Setup chartist event
	 */
	ngOnInit() {
		this.options = {
			...this.options,
			plugins: [
				...this.options.plugins,
				this.enableBarOverlap
					? Chartist.plugins.ctBarOverlap({
							chartId: this.chartId,
					  })
					: null,
			]?.filter(Boolean),
		};

		this.events = {
			draw: (data) => {
				if (data.type === 'bar') {
					data.element.animate({
						y2: {
							dur: '0.5s',
							from: data.y1,
							to: data.y2,
							easing: 'easeOutQuad',
						} as IChartistAnimationOptions,
					});

					data.element.attr({
						style: `stroke-width:  ${data.axisX.stepLength / 3}px;`,
					});
					if (!this.enableBarOverlap) {
						$(`#${this.chartId} .ct-series-a .ct-bar`).css(
							'transform',
							`translate(-${data.axisX.stepLength / 3 - 15}px, 0px)`
						);
						$(`#${this.chartId} .ct-series-c .ct-bar`).css(
							'transform',
							`translate(${data.axisX.stepLength / 3 - 15}px, 0px)`
						);
					}

					if (this.displayAdditionalData) {
						data.element._node.addEventListener('mouseenter', (e) => {
							const tooltip = document.getElementsByClassName('ct-tooltip');

							if (!tooltip[0]) {
								return;
							}

							const index = data.index;

							const series = data.series;

							const domestic = series.meta[0].data[index];
							const commercial = series.meta[1].data[index];

							tooltip[0].innerHTML = `
                ${this.formatValue(data.value.y)} <br>
                Domestic: ${this.formatValue(domestic)} <br>
                Commercial: ${this.formatValue(commercial)}
              `;
						});
					}

					// For showing data on top of bars
					// const dollar = (x: any) => {
					//   if (x < 0) {
					//     return '-$' + (Math.abs(x)).toString();
					//   }
					//   return '$' + x.toString();
					// };
					// let barHorizontalCenter: any, barVerticalCenter: number, label: Chartist.IChartistSvg, value: string;
					// barHorizontalCenter = data.x1 + (data.element.width() * .5);
					// barVerticalCenter = data.y1 + (data.element.height() * -1) - 10;
					// const ytop = data.y2 ? data.y2 : data.y1;
					// value = data.element.attr('ct:value');
					// if (value !== '0') {
					//   label = new Chartist.Svg('text');
					//   label.text(dollar(value));
					//   label.addClass('ct-barlabel');
					//   label.attr({
					//     x: barHorizontalCenter,
					//     y: ytop,
					//     'text-anchor': 'middle',
					//     'color': data.series.name === 'Submitted' ? 'blue' : (data.series.name === 'Completed' ? 'green' : 'red'),
					//     'font-weight': 'bold'
					//   });
					//   return data.group.append(label);
					// }
				}
			},
		};
	}

	formatValue(value) {
		return this.showCurrencySignAxisY
			? numeral(value).format('$0,0')
			: numUtil.formatToCount(value);
	}
}
