import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { join, split, slice, either, isNil, isEmpty } from 'ramda';
import { combineLatest, iif, of, Subject } from 'rxjs';
import {
	concatMap,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { getAlreadyExists, logMessage } from 'src/app/shared/error-message/error-message';
import { SOASubSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import {
	getContentWithMergeTags,
	removeMtWrappers,
} from '../../../../../../shared/converter/content-merge-tags';
import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ScopeOfServiceMapper } from '../../../../../../shared/models/client-review-template/scope-of-service/scope-of-service.mapper';
import { ScopeOfServiceState } from '../../../../../../shared/models/client-review-template/scope-of-service/scope-of-service.model';
import { StatementOfAdviceState } from '../../../../../../shared/models/client-review-template/statement-of-advice/statement-of-advice.model';
import { ClientReviewTemplateQuery } from '../../../states/client-review-template.query';
import { CrtMergeTagsService } from '../../../states/merge-tags/crt-merge-tags.service';
import { ScopeOfServiceService } from '../../../states/scope-of-service/scope-of-service.service';
import { StatementOfAdviceQuery } from '../../../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../../../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../../../states/statement-of-advice/statement-of-advice.store';

@Component({
	selector: 'app-soa-scope-of-service',
	templateUrl: './scope-of-service.component.html',
	styleUrls: ['./scope-of-service.component.scss'],
})
export class SoaScopeOfServiceComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() parentCRTId: number;
	@Input() viewMode: string;
	@Output() nextPage = new EventEmitter<void>();

	name = new UntypedFormControl('', [Validators.required]);
	form: UntypedFormGroup;
	submitted: boolean;
	isNameExist: boolean;

	crtData: ScopeOfServiceState;
	scopeOfService$ = this.query.scopeOfService$;
	sosDefault$ = this.query.sosDefault$;
	scopeOfServiceValues: ScopeOfServiceState;
	soa: StatementOfAdviceState;
	defaultSetting: ScopeOfServiceState;
	mergeTags: MergeTagState[];
	isLoading = true;
	showLifeInsurance = false;
	showCriticalIllness = false;
	showTPDInsurance = false;
	showDisabilityInsurance = false;
	showMedicalInsurance = false;
	showBusinessRiskInsurance = false;
	showKiwiSaver = false;
	showHomeCarAndContentsInsurance = false;
	showResidentialMortgageOrLending = false;
	showOther = false;

	constructor(
		private fb: UntypedFormBuilder,
		private query: ClientReviewTemplateQuery,
		private statementOfAdviceService: StatementOfAdviceService,
		private soaStore: StatementOfAdviceStore,
		protected soaQuery: StatementOfAdviceQuery,
		private loggerService: LoggerService,
		private mtService: CrtMergeTagsService,
		private scopeOfService: ScopeOfServiceService
	) {
		this.form = this.fb.group({
			lifeInsurance: [0],
			criticalIllness: [0],
			tPDInsurance: [0],
			disabilityInsurance: [0],
			medicalInsurance: [0],
			businessRiskInsurance: [0],
			kiwiSaver: [0],
			homeCarAndContentsInsurance: [0],
			residentialMortgageOrLending: [0],
			personalLifeAndRiskInsurance: [0],
			incomeOrMortgageInsurance: [0],
			other: [0],
			lifeInsuranceDetails: [''],
			criticalIllnessDetails: [''],
			tPDInsuranceDetails: [''],
			disabilityInsuranceDetails: [''],
			medicalInsuranceDetails: [''],
			instructionOrLimitation: [''],
			otherNotes: [''],
		});
	}

	get LifeInsurance() {
		return this.form.get('lifeInsurance');
	}
	get CriticalIllness() {
		return this.form.get('criticalIllness');
	}
	get TPDInsurance() {
		return this.form.get('tPDInsurance');
	}
	get DisabilityInsurance() {
		return this.form.get('disabilityInsurance');
	}
	get MedicalInsurance() {
		return this.form.get('medicalInsurance');
	}
	get BusinessRiskInsurance() {
		return this.form.get('businessRiskInsurance');
	}
	get KiwiSaver() {
		return this.form.get('kiwiSaver');
	}
	get HomeCarAndContentsInsurance() {
		return this.form.get('homeCarAndContentsInsurance');
	}
	get ResidentialMortgageOrLending() {
		return this.form.get('residentialMortgageOrLending');
	}
	get Other() {
		return this.form.get('other');
	}

	ngOnInit(): void {
		this.scopeOfService$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
			this.scopeOfServiceValues = ScopeOfServiceMapper.mapToView(data);
		});

		this.soaQuery.soa$
			.pipe(
				map((x) => x?.find((soa) => +soa.cRTId === +this.parentCRTId)),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => {
				this.soa = x;
				const name = this.getSoaFileName(x?.name);
				this.name.reset(name);
			});

		this.statementOfAdviceService
			.getScopeOfService(this.parentCRTId)
			.pipe(
				withLatestFrom(this.sosDefault$, this.mtService.mergeTags$),
				tap(([, settings, mt]) => {
					this.mergeTags = mt;
					this.defaultSetting = settings;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe((data) => {
				if (this.viewMode === 'view') {
					const soaSos = data[0]?.[0] || null;
					this.loadSos(soaSos);
				}
			});
	}

	loadSos(crt?: ScopeOfServiceState) {
		combineLatest([
			of(crt),
			of(this.defaultSetting),
			this.scopeOfService$,
			of(this.mergeTags),
		])
			.pipe(
				tap(() => (this.isLoading = true)),
				concatMap(([soaSos, sosSettings, sosCrt, mt]) =>
					iif(
						() => isNil(this.soa?.document),
						this.getSettings(sosCrt, sosSettings, mt),
						this.getSettings(soaSos, sosSettings, mt)
					)
				),
				tap(([crtValue1, settings]) => this.setupServices(crtValue1, settings)),
				map(([crtValue2, settings]) =>
					ScopeOfServiceMapper.mapToViewCrt(crtValue2, settings)
				),
				map((x) => this.updateMergeTagsOnContent(x)),
				tap((x) => (this.crtData = x)),
				tap((x) => this.form.reset(x)),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	getSettings = (crt, settings, mt) =>
		of(crt).pipe(
			concatMap((val) =>
				iif(
					() => isNil(val),
					this.scopeOfService.getSosDefault(),
					of(settings)
				)
			),
			map((data) => [crt, data, mt]),
			take(1)
		);

	convertMergeTags(content, mergeTags) {
		const data = getContentWithMergeTags(content, mergeTags);
		return removeMtWrappers(data);
	}

	updateMergeTagsOnContent(data) {
		return {
			...data,
			intro: this.convertMergeTags(data?.intro, this.mergeTags) ?? '',
			instructionOrLimitation:
				this.convertMergeTags(data?.instructionOrLimitation, this.mergeTags) ??
				'',
			residentialMortgageOrLendingNote:
				this.convertMergeTags(
					data?.residentialMortgageOrLendingNote,
					this.mergeTags
				) ?? '',
			homeCarAndContentsInsuranceNote:
				this.convertMergeTags(
					data?.homeCarAndContentsInsuranceNote,
					this.mergeTags
				) ?? '',
			businessRiskInsuranceNote:
				this.convertMergeTags(
					data?.businessRiskInsuranceNote,
					this.mergeTags
				) ?? '',
			kiwiSaverNote:
				this.convertMergeTags(data?.kiwiSaverNote, this.mergeTags) ?? '',
			otherInformation:
				this.convertMergeTags(data?.otherInformation, this.mergeTags) ?? '',
		};
	}

	setupServices(crtData, settings) {
		// if crtData is null, then reset (for reload)
		const reset = isNil(crtData);

		// services
		this.showLifeInsurance =
			!reset && crtData?.cRTId
				? crtData?.lifeInsurance !== 2
				: settings?.lifeInsurance;
		this.showCriticalIllness =
			!reset && crtData?.cRTId
				? crtData?.criticalIllness !== 2
				: settings?.criticalIllness;
		this.showTPDInsurance =
			!reset && crtData?.cRTId
				? crtData?.tPDInsurance !== 2
				: settings?.tPDInsurance;
		this.showDisabilityInsurance =
			!reset && crtData?.cRTId
				? crtData?.disabilityInsurance !== 2
				: settings?.disabilityInsurance;
		this.showMedicalInsurance =
			!reset && crtData?.cRTId
				? crtData?.medicalInsurance !== 2
				: settings?.medicalInsurance;

		// other advice
		this.showBusinessRiskInsurance =
			!reset && crtData?.cRTId
				? crtData?.businessRiskInsurance !== 2
				: settings?.businessRiskInsurance;
		this.showKiwiSaver =
			!reset && crtData?.cRTId ? crtData?.kiwiSaver !== 2 : settings?.kiwiSaver;
		this.showHomeCarAndContentsInsurance =
			!reset && crtData?.cRTId
				? crtData?.homeCarAndContentsInsurance !== 2
				: settings?.homeCarAndContentsInsurance;
		this.showResidentialMortgageOrLending =
			!reset && crtData?.cRTId
				? crtData?.residentialMortgageOrLending !== 2
				: settings?.residentialMortgageOrLending;
		this.showOther =
			!reset && crtData?.cRTId ? crtData?.other !== 2 : settings?.other;
	}

	submitForm() {
		const formValue = this.form.getRawValue();
		const data = {
			...formValue,
			sectionCode: SOASubSectionCodes.SOS,
			parentCRTId: this.parentCRTId,
			cRTId: this.soaQuery.getValue().scopeOfService[0]?.cRTId,
			adviceProcessId: this.query.getValue().adviceProcessId,
		};

		return of(data).pipe(
			withLatestFrom(this.soaQuery.soaSOS$),
			mergeMap(([x, sos]) =>
				iif(
					() => !!sos && sos.length > 0,
					this.statementOfAdviceService.updateScopeOfService(x),
					this.statementOfAdviceService.addScopeOfService(x)
				)
			),
			mergeMap(() =>
				iif(() => isNil(this.soa?.document), this.saveCrtSos(), of(null))
			),
			take(1)
		);
	}

	next() {
		this.isNameExist = false;
		this.submitted = true;
		if (this.name.invalid) {
			this.loggerService.Warning({}, logMessage.shared.general.warning.required);
			return;
		}
		if (
			this.soaQuery
				.getAll()
				?.some(
					(x) =>
						x?.name?.toLowerCase() === this.name?.value?.toLowerCase() &&
						this.soaQuery.getActiveId() !== x.cRTId
				)
		) {
			this.isNameExist = true;
			this.loggerService.Warning({}, getAlreadyExists(`"${this.name.value}"`));
			return;
		}

		this.submitForm()
			.pipe(
				mergeMap(() =>
					this.statementOfAdviceService.updateSoa(this.parentCRTId, SOASubSectionCodes.ProposedInsurance, {
						...this.soa,
						name: this.name.value,
					})
				),
				take(1),
				finalize(() => {
					this.submitted = false;
					this.isNameExist = false;
					this.soaStore.updateActive({ currentPage: SOASubSectionCodes.ProposedInsurance });
				})
			)
			.subscribe();
	}

	saveCrtSos() {
		return of(this.form.getRawValue()).pipe(
			withLatestFrom(this.scopeOfService.scopeOfService$),
			map(([data, crtSos]) => ({
				...crtSos,
				...data,
				clickedNext: true,
			})),
			tap((data) => this.scopeOfService.updateScopeOfServiceTemp(data, true)),
			take(1)
		);
	}

	tickChange(event) {
		const controllerName = event?.target?.id;
		const val = event?.target?.checked ? 1 : 0;
		this.form.get(controllerName).setValue(val);
	}

	getSoaFileName(name = '') {
		if (either(isNil, isEmpty)(name)) {
			return (this.soaQuery.getCount() + 1).toString();
		}

		// Split name based on dash
		const splitName = split('-', name)?.map((split: string) => split.trim());
		if (splitName.length > 0) {
			// Return name excluding first part which is always the prefix '<Client> Statement of Advice'
			const prefixIndex = splitName.findIndex((str: string) => str.toUpperCase().includes('STATEMENT OF ADVICE'));
			if (prefixIndex > -1) {
				return `${join(' - ', slice(prefixIndex + 1, Infinity, splitName))}`
			}
		}

		return name;
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
