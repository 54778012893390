import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	OnDestroy,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PQuery } from '../state/p.query';
import { MortgagePipeline } from '../state/p.model';
import { PService } from '../state/p.service';
import { DashboardQuery } from '../../../state/dashboard.query';
import { RouteService } from '../../../../../../core/config/route.service';
import {
	BodyRow,
	HeaderRow,
} from '../../../shared/simple-table/simple-table.model';
import { numUtil } from '../../../../../../util/util';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';

/**
 * Mortgage pipeline table. Uses simple table.
 */
const mortgageTotalStatusEquivalent = [
	'Mortgage Enquiry',
	'Awaiting Application',
	'Application Received',
	'Loan Application Submitted',
	'Information Required',
	'Pre-Approved LOO',
	'Conditional LOO',
	'Unconditional LOO',
	'Loan Instructions Sent',
];

/**
 * Mortgage pipeline table. Uses simple table
 */
@Component({
	selector: 'app-mp',
	templateUrl: './mp.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MpComponent implements OnInit, OnDestroy {
	/**
	 * key pair value where the key is keys in mortgage pipeline response
	 * and value is the real status value.
	 *
	 * The real status value is used for values to search when user
	 * is routed to pipeline search due to clicking a status.
	 */
	// private readonly MortgagePropertyRealStatus = (() => {
	//   const statuses = {
	//     Total: '',
	//     MortgageEnquiry: 'Mortgage Enquiry',
	//     AwaitingApplication: 'Awaiting Application',
	//     ApplicationReceived: 'Application Received',
	//     ApplicationSubmitted: 'Loan Application Submitted',
	//     InformationRequired: 'Information Required',
	//     PreApprovedLOO: 'Pre-Approved LOO',
	//     ConditionalLOO: 'Conditional LOO',
	//     UnconditionalLOO: 'Unconditional LOO',
	//     LoanInstructions: 'Loan Instructions Sent'

	//   };
	//   statuses.Total = mortgageTotalStatusEquivalent.join(',');
	//   return statuses;
	// })();
	/**
	 * Display name for Statuses.
	 * keys are the keys in mortgage pipeline response.
	 */
	// private readonly MortgagePropertyName = {
	//   Total: 'Total',
	//   MortgageEnquiry: 'Mortgage Enquiry',
	//   AwaitingApplication: 'Awaiting Application',
	//   ApplicationReceived: 'Application Received',
	//   ApplicationSubmitted: 'Loan Application Submitted',
	//   InformationRequired: 'Information Required',
	//   PreApprovedLOO: 'PreApproved LOO',
	//   ConditionalLOO: 'Conditional LOO',
	//   UnconditionalLOO: 'Unconditional LOO',
	//   LoanInstructions: 'Loan Instructions'
	// };

	/**
	 * destroy event observable. Is watched to know when to
	 * unsubscribe subscribers. emits on ngOnDestroy.
	 */
	private onDestroy$ = new Subject<void>();

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.mortgageIsLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.mortgageError$;
	/**
	 * Mortgage pipeline data
	 */
	data$: Observable<MortgagePipeline> = this.query.mortgageData$;

	isServiceLoading$: Observable<boolean> = this.query.isServiceLoading$;
	/**
	 * transform Mortgage data to simple-table object.
	 *
	 * For status label, show text from MortgagePropertyName
	 * For route get from routeService with status from MortgagePropertyRealStatus
	 */
	viewData$ = this.data$.pipe(
		withLatestFrom(
			this.dashboardQuery.hasSelectedAllAdvisers$,
			this.dashboardQuery.allAdvisersId$
		),
		map(([data, hasSelectedAllAdvisers, allAdviserIds]): BodyRow[] => {
			if (data === null) {
				return [];
			} else {
				const selectedAdvisers = this.dashboardQuery.getValue();
				const totalStatus = Object.keys(data)
					?.filter((key) => key !== 'Total')
					?.map((key) => data[key].Status);

				return Object.keys(data)?.map<BodyRow>((key) => {
					return [
						{
							text: data[key].Status,
							route: this.routeService.mPipelineWithParams({
								advisers: !hasSelectedAllAdvisers
									? selectedAdvisers.adviserFilter?.join(',')
									: allAdviserIds?.join(','),
								statuses:
									data[key].Status !== 'Total'
										? data[key].Status
										: totalStatus?.join(','),
							}),
						},
						{
							text: numUtil.formatToCurrency(data[key].LoanValue),
							class: 'col-4',
						},
						{ text: numUtil.formatToCount(data[key].Count), class: 'col-2' },
					];
				});
			}
		})
	);

	/** header for simple-table header object */
	header: HeaderRow = [
		{ text: 'Status' },
		{ text: 'Loan Value', class: 'col-4' },
		{ text: 'Count', class: 'col-2' },
	];

	constructor(
		private routeService: RouteService,
		private dashboardQuery: DashboardQuery,
		private query: PQuery,
		private service: PService,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	/** trigger Mortgage pipeline data fetch when adviser changes */
	ngOnInit() {
		this.dashboardQuery.adviserFilter$
			.pipe(
				filter((x) => x.length > 0),
				withLatestFrom(
					this.isServiceLoading$,
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([advisers, isServiceLoading]) => {
				if (!isServiceLoading) {
					this.service.GetAllPipelines(advisers);
				}
			});
	}

	/**
	 * Gets onDestroy$ to emit and unsubscribe.
	 */
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
