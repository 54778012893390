<div class="review-application">
	<div
		class="review-application__editor mb-2"
		[class.wysiwyg-loading]="isLoading || isResetting"
	>
		<app-wysiwyg
			#contentEditor
			[content]="currentTemplate"
			[hasSidebarSections]="false"
			[isTapLevel]="isTapLevel"
			[hideMergeTagButtons]="true"
			[id]="'moatReviewAppEditor'"
			designClass="pdf-design-v2-froala"
		></app-wysiwyg>
	</div>
</div>
