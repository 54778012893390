<form [formGroup]="form" class="pl-3 pr-lg-0 pr-md-0">
	<div
		class="container-fluid pr-0"
		[class.cancelled]="adviceProcess?.status === 6"
	>
		<div class="form-row clearfix align-items-center mb-1">
			<div class="col-lg col-6">
				<strong class="position-relative"> {{ adviceProccessName }} </strong>
			</div>
			<div class="col-lg-auto col-6 d-flex action-btns justify-content-end">
				<span class="position-relative" *ngIf="isSaving$ | async">
					<app-manual-loader-mini
						[isLoading]="isSaving$ | async"
						class="p-0"
					></app-manual-loader-mini>
				</span>
				<ng-container *ngIf="!(isSaving$ | async)">
					<!-- Restriction for lead -->
					<ng-container *ngIf="!(isEdit$ | async)">
						<ng-container *ngIf="isEditable">
							<ng-container *appRestrictTo="['FEAP']">
								<span class="position-relative">
									<button
										*ngIf="adviceProcess.status < 6"
										type="button"
										class="icon-btn"
										id="edit_{{ formId }}"
										(click)="edit()"
									>
										<i class="material-icons md-20 tap-text-color-h6"> edit </i>
									</button>
								</span>
							</ng-container>
						</ng-container>
						<ng-container *appRestrictTo="['FDAP']">
							<span class="position-relative">
								<button
									type="button"
									class="icon-btn"
									id="delete_{{ formId }}"
									(click)="confirmDelete()"
								>
									<i class="material-icons md-20 tap-text-color-h6"> delete </i>
								</button>
							</span>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="isEdit$ | async">
						<ng-container *appRestrictTo="['FAAP', 'FEAP']">
							<span
								class="position-relative"
								*ngIf="(isEdit$ | async) && !isSaving"
							>
								<button
									type="button"
									class="icon-btn"
									id="save_{{ formId }}"
									(click)="save()"
									[disabled]="
										clientsInvolvedFC.hasError('loatMaxClients') ||
										clientsInvolvedFC.hasError('moatMaxClients') ||
										clientsInvolvedFC.hasError('koatMaxClients')
									"
								>
									<i class="material-icons md-20 tap-text-color-h6"> save </i>
								</button>
							</span>
							<span class="position-relative">
								<button
									type="button"
									class="icon-btn"
									id="cancel_{{ formId }}"
									[disabled]="isSaving"
									(click)="cancel()"
								>
									<i class="material-icons md-20 tap-text-color-h6"> cancel</i>
								</button>
							</span>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<div class="form-row mb-3 align-items-center">
			<div class="col-lg-1 col-12 pr-lg-0">
				<strong class="mb-1 d-inline-block">{{ stageCaption }}</strong>
			</div>
			<div class="col-lg col-12 pr-0 ml-lg-3 position-relative">
				<app-advice-process-step-wizard
					#stages
					[steps]="steps"
					[isEditable]="isEdit"
					[startDate]="adviceProcess?.startDate"
					[crtLink]="stepWizardLink()"
				>
				</app-advice-process-step-wizard>
			</div>
		</div>

		<!-- Claims Feature -->
		<ng-container
			*ngIf="
				adviceProcess?.processCode === processCodes?.FGClaim ||
					adviceProcess?.processCode === processCodes?.LRClaim;
				else defaultFormFields
			"
		>
			<div class="row">
				<div class="col-lg-7">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label class="font-weight-bold" for="adviser_{{ formId }}">
										<span
											class="tap-required-field ml-2"
											*ngIf="adviserFC?.invalid"
											>*</span
										>
										Adviser
									</label>
								</div>
								<div class="col-lg-8 col-12">
									<span class="custom-dropdown w-100">
										<select
											formControlName="adviser"
											class="form-control theme-crm-field-disabled"
											id="adviser_{{ formId }}"
										>
											<option value=""></option>
											<option
												*ngFor="let d of adviserList; trackBy: trackByValue;"
												[value]="d.value"
											>
												{{ d.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-12">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label class="font-weight-bold lh-1" for="reviewed_by_{{ formId }}"
										>Claim Manager</label
									>
								</div>
								<div class="col-lg-8 col-12">
									<span class="custom-dropdown w-100">
										<select
											formControlName="claimManager"
											class="form-control theme-crm-field-disabled"
											id="reviewed_by_{{ formId }}"
										>
											<option value=""></option>
											<option *ngFor="let d of reviewedBy" [value]="d.value">
												{{ d.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
					</div>


					<div class="form-row mb-3 align-items-center">
						<div class="col-lg-6">
							<div class="form-row align-items-center">
								<div class="col-lg-4 col-12 d-inherit">
									<label
										class="font-weight-bold lh-1"
										for="referenceNumber_{{ formId }}"
										>Reference Number</label
									>
								</div>
								<div class="col-lg-8 col-12 d-flex align-items-center">
									<input
										type="text"
										class="form-control theme-crm-field-disabled"
										formControlName="referenceNumber"
										id="referenceNumber_{{ formId }}"
									/>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="form-row align-items-center">
								<div class="col-lg-4 col-12">
									<label
										class="font-weight-bold lh-1"
										for="policyNumber_{{ formId }}"
										>Policy Number</label
									>
								</div>
								<div
									class="col-lg-8 col-12 d-flex align-items-center claim-policies"
								>
									<ul class="m-0 p-0">
										<li *ngFor="let p of claimPolicyNumbers">
											<span
												(click)="redirectPolicy(p?.customerServiceId)"
												class="claim-policies__number font-weight-bold clickable"
												>{{ p?.name }}</span
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div class="form-row align-items-center">
						<div class="col-lg-6">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label
										class="font-weight-bold lh-1"
										for="claimType_{{ formId }}"
										>Claim Type</label
									>
								</div>
								<div class="col-lg-8 col-12 d-flex align-items-center">
									<app-chips
										formControlName="claimType"
										[choices]="
											adviceProcess?.processCode === processCodes?.FGClaim
												? fgPolicyLines
												: lrProducts
										"
										textboxClass="theme-crm-field"
										id="claimType_{{ formId }}"
									>
									</app-chips>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label
										class="font-weight-bold lh-1"
										for="provider_{{ formId }}"
										>Provider</label
									>
								</div>
								<div class="col-lg-8 col-12 d-flex align-items-center">
									<input
										type="text"
										class="form-control theme-crm-field-disabled"
										formControlName="provider"
										id="provider_{{ formId }}"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="form-row align-items-center">
						<div class="col-lg-6">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label
										class="font-weight-bold lh-1"
										for="totalPaid_{{ formId }}"
										>Total Paid</label
									>
								</div>
								<div class="col-lg-8 col-12 d-flex align-items-center">
									<div class="dollar-icon">
										<input
											type="text"
											class="form-control theme-crm-field-disabled"
											formControlName="totalPaid"
											id="totalPaid_{{ formId }}"
											currencyMask
											appCurrencyMaskExt
										/>
										<i>$</i>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label
										class="font-weight-bold lh-1"
										for="claimOutcome_{{ formId }}"
										>Claim Outcome</label
									>
								</div>
								<div class="col-lg-8 col-12 d-flex">
									<span class="custom-dropdown w-100">
										<select
											formControlName="claimOutcome"
											class="form-control theme-crm-field-disabled"
											id="claimOutcome_{{ formId }}"
										>
											<option value=""></option>
											<option
												*ngFor="let d of claimOutcome"
												[value]="d.value"
											>
												{{ d.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class="form-row" *ngIf="adviceProcess?.processCode === processCodes?.FGClaim">
						<div class="col-lg-6">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label
										class="font-weight-bold lh-1"
										for="policyPaymentMethod_{{ formId }}"
										>Payment Method</label
									>
								</div>
								<div class="col-lg-8 col-12 d-flex align-items-center">
									<app-chips
										formControlName="policyPaymentMethod"
										id="policyPaymentMethod_{{ formId }}"
										textboxClass="theme-crm-field"
										[choices]="fGpaymentMethods"
										[class.input-highlight]="highlightPaymentMethod"
									>
									</app-chips>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
						</div>
					</div>

					<div class="form-row mb-3 align-items-center">
						<div class="col-lg-2 col-12 pr-lg-0">
							<label class="font-weight-bold" for="clientsInvoled_{{ formId }}">
								<span
									class="tap-required-field ml-2"
									*ngIf="clientsInvolvedFC?.invalid"
									>*</span
								>
								Claimants
							</label>
						</div>
						<div class="col-lg-10 col-12 d-flex align-items-center">
							<app-chips
								formControlName="clientsInvolved"
								[choices]="currentClientsInvolved | sortBy: 'display'"
								textboxClass="theme-crm-field"
							>
							</app-chips>
							<ng-container *ngIf="hasQuickAdd && showQuickAdd">
								<button
									(click)="quickAddModal()"
									[disabled]="!(isEdit$ | async) || isSaving"
									class="icon-btn pr-0 pl-1 w-auto h-auto tap-text-color-h6"
								>
									<i class="material-icons md-16">person_add</i>
								</button>
							</ng-container>
						</div>
					</div>

					<div class="form-row mb-3 align-items-start">
						<div class="col-lg-2 col-12">
							<label class="font-weight-bold" for="review_goals_{{ formId }}"
								>Claim Details</label
							>
						</div>
						<div class="col-lg-10 col-12">
							<textarea
								formControlName="claimDetails"
								class="form-control theme-crm-field-disabled"
								cols="30"
								rows="6"
								placeholder="- -"
								id="claimDetails_{{ formId }}"
							></textarea>
						</div>
					</div>

					<div class="form-row mb-3 align-items-start">
						<div class="col-lg-2 col-12">
							<label class="font-weight-bold" for="review_goals_{{ formId }}"
								>Outcome Details</label
							>
						</div>
						<div class="col-lg-10 col-12">
							<textarea
								formControlName="outcomeDetails"
								class="form-control theme-crm-field-disabled"
								cols="30"
								rows="6"
								placeholder="- -"
								id="outcomeDetails_{{ formId }}"
							></textarea>
						</div>
					</div>
				</div>

				<div *ngIf="hasOfflineCRT" class="col-lg-5">
					<div class="form-row mb-2">
						<div
							*ngIf="!hasCRTorMOAT ? !adviceProcess?.isOnline : true"
							class="col-12 col-md-4 d-flex"
							[ngClass]="{ 'align-items-center': isAlterationProcess }"
						>
							<label class="font-weight-bold">
								{{ adviceProcessDocumentsCaption }}
							</label>
						</div>
						<div
							 class="col-12 col-md d-flex"
							 [ngClass]="{ 'align-items-center': isAlterationProcess }"
							>
							<ng-container *appRestrictTo="['FCRT']">
								<div
									*ngIf="adviceProcess?.isOnline && isEditable && hasCRTorMOAT"
									class="form-row"
								>
									<div
										class="col-12 d-flex flex-row"
										[ngClass]="{
										 'align-items-center': isAlterationProcess,
										 'mb-2': !isAlterationProcess
										}"
									>
										<a
											[ngClass]="{'anchor-disable' : !!isPrepopulating}"
											class="tap-btn tap-btn--default"
											(click)="
												prepopulate(adviceProcess?.isStarted ? true : false)
											"
										>
											{{ prepopulateBtnCaption }}
											<!-- {{ adviceProcess?.isStarted ? "Open" : "Start" }} Advice Process -->
										</a>
										<span *ngIf="isPrepopulating" class="pt-1">
											<app-manual-loader-mini
												class="d-flex"
												[isLoading]="isPrepopulating"
											></app-manual-loader-mini>
										</span>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<ng-container>
						<app-advice-process-documents
							[crtPage]="crtPage"
							[hasCRT]="hasCRT"
							[hasMOAT]="hasMOAT"
							[hasKOAT]="hasKOAT"
							[hasLOATV2]="hasLOATV2"
							[isEditable]="isEditable"
							[adviceProcess]="adviceProcess"
							[hasFinalized]="hasFinalized"
							[finalStructure]="finalStructure"
							(doDownload)="downloadFile($event)"
							(doUpload)="openUploadModal($event)"
							(doLinkDocument)="linkDocument($event)"
							(doCliamAddDocument)="cliamAddDocument($event)"
							(doRemove)="removeFile($event)"
							(doView)="viewFile($event)"
						>
						</app-advice-process-documents>
					</ng-container>
				</div>
			</div>
		</ng-container>

		<ng-template #defaultFormFields>
			<div class="row">
				<div class="col-lg-7">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label class="font-weight-bold" for="adviser_{{ formId }}">
										<span
											class="tap-required-field ml-2"
											*ngIf="adviserFC?.invalid"
											>*</span
										>
										Adviser
									</label>
								</div>
								<div class="col-lg-8 col-12">
									<span class="custom-dropdown w-100">
										<select
											formControlName="adviser"
											class="form-control theme-crm-field-disabled"
											id="adviser_{{ formId }}"
										>
											<option value=""></option>
											<option
												*ngFor="let d of adviserList; trackBy: trackByValue;"
												[value]="d.value"
											>
												{{ d.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-12">
							<div class="form-row mb-3 align-items-center">
								<div class="col-lg-4 col-12">
									<label class="font-weight-bold" for="reviewed_by_{{ formId }}"
										>Reviewed By</label
									>
								</div>
								<div class="col-lg-8 col-12">
									<span class="custom-dropdown w-100">
										<select
											formControlName="reviewedBy"
											class="form-control theme-crm-field-disabled"
											id="reviewed_by_{{ formId }}"
										>
											<option value=""></option>
											<option *ngFor="let d of reviewedBy" [value]="d.value">
												{{ d.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
					</div>

					<ng-container *ngIf="adviceProcess.processCode === 'APC'">
						<div class="form-row mb-3">
							<div class="col-lg-6">
								<div class="form-row align-items-center">
									<div class="col-lg-4 col-12">
										<label
											class="font-weight-bold"
											for="clientsInvoled_{{ formId }}"
											>Advice Service</label
										>
									</div>
									<div class="col-lg-8 col-12 d-flex align-items-center">
										<span class="custom-dropdown w-100">
											<select
												formControlName="adviceService"
												class="form-control theme-crm-field-disabled"
												id="reviewed_by_{{ formId }}"
												(change)="
													selectAdviceService(
														form.get('adviceService').value,
														true
													)
												"
											>
												<option value=""></option>
												<option
													*ngFor="let d of apcrtasChoices$"
													[value]="d.value"
												>
													{{ d.display }}
												</option>
											</select>
										</span>
									</div>
								</div>
							</div>

							<div
								class="col-lg-6"
								*ngIf="
									providers?.length > 0 && form.get('adviceService').value !== ''
								"
							>
								<div class="form-row align-items-center">
									<div class="col-lg-4 col-12">
										<label
											class="font-weight-bold"
											for="clientsInvoled_{{ formId }}"
											>Provider</label
										>
									</div>
									<div class="col-lg-8 col-12 d-flex align-items-center">
										<span class="custom-dropdown w-100">
											<select
												formControlName="provider"
												class="form-control theme-crm-field-disabled"
												id="reviewed_by_{{ formId }}"
											>
												<option value=""></option>
												<option *ngFor="let p of providers" [value]="p.value">
													{{ p.display }}
												</option>
											</select>
										</span>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="adviceProcess.processCode === 'APC'">
						<div class="form-row">
							<div class="col-lg-6">
								<div class="form-row mb-3 align-items-center">
									<div class="col-lg-4 col-12">
										<label
											class="font-weight-bold"
											for="clientsInvoled_{{ formId }}"
											>Complaint Type</label
										>
									</div>
									<div class="col-lg-8 col-12 d-flex align-items-center">
										<span class="custom-dropdown w-100">
											<select
												formControlName="complaintType"
												class="form-control theme-crm-field-disabled"
												id="reviewed_by_{{ formId }}"
											>
												<option value=""></option>
												<option
													*ngFor="let d of apcrtctChoices$"
													[value]="d.value"
												>
													{{ d.display }}
												</option>
											</select>
										</span>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="form-row mb-3 align-items-center">
									<div class="col-lg-4 col-12">
										<label
											class="font-weight-bold"
											for="clientsInvoled_{{ formId }}"
											>Complaint Outcome</label
										>
									</div>
									<div class="col-lg-8 col-12 d-flex align-items-center">
										<span class="custom-dropdown w-100">
											<select
												formControlName="complaintOutcome"
												class="form-control theme-crm-field-disabled"
												id="reviewed_by_{{ formId }}"
											>
												<option value=""></option>
												<option
													*ngFor="let d of apcrtcoChoices$"
													[value]="d.value"
												>
													{{ d.display }}
												</option>
											</select>
										</span>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<div class="form-row mb-3 align-items-center">
						<div class="col-lg-2 col-12 pr-lg-0">
							<label class="font-weight-bold" for="clientsInvoled_{{ formId }}">
								<span
									class="tap-required-field ml-2"
									*ngIf="clientsInvolvedFC?.invalid"
									>*</span
								>
								Clients Involved
							</label>
						</div>
						<div class="col-lg-10 col-12 d-flex align-items-center">
							<app-chips
								formControlName="clientsInvolved"
								[choices]="currentClientsInvolved | sortBy: 'display'"
								textboxClass="theme-crm-field"
							>
							</app-chips>
							<ng-container *ngIf="hasQuickAdd && showQuickAdd">
								<button
									(click)="quickAddModal()"
									[disabled]="!(isEdit$ | async) || isSaving"
									class="icon-btn pr-0 pl-1 w-auto h-auto tap-text-color-h6"
								>
									<i class="material-icons md-16">person_add</i>
								</button>
							</ng-container>
						</div>
						<ng-container *ngIf="clientsInvolvedFC.hasError('loatMaxClients')">
							<div class="col-lg-10 offset-lg-2 col-12">
								<p class="error">{{errorMessages.loatMaxClients}}</p>
							</div>
						</ng-container>
						<ng-container *ngIf="clientsInvolvedFC.hasError('moatMaxClients')">
							<div class="col-lg-10 offset-lg-2 col-12">
								<p class="error">{{errorMessages.moatMaxClients}}</p>
							</div>
						</ng-container>
						<ng-container *ngIf="clientsInvolvedFC.hasError('koatMaxClients')">
							<div class="col-lg-10 offset-lg-2 col-12">
								<p class="error">{{errorMessages.koatMaxClients}}</p>
							</div>
						</ng-container>
					</div>

					<ng-container
						*ngIf="adviceProcess.processCode === 'APM' && !adviceProcess.isOnline"
					>
						<div class="form-row align-items-center mb-3">
							<div class="col-lg-2 col-12 pr-lg-0">
								<label class="font-weight-bold" for="clientsInvoled_{{ formId }}"
									>Advice Type</label
								>
							</div>
							<div class="col-lg-10 col-12">
								<app-chips
									formControlName="adviceType"
									[choices]="apcrtatChoices$ | async | sortBy: 'display'"
									textboxClass="theme-crm-field"
								>
								</app-chips>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="adviceProcess.processCode === 'API'">
						<div class="form-row align-items-center mb-3">
							<div class="col-lg-2 col-12 pr-lg-0">
								<label class="font-weight-bold" for="riskLevel_{{ formId }}"
									>Risk Level</label
								>
							</div>
							<div class="col-lg-4 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="riskLevel"
										class="form-control theme-crm-field-disabled"
										id="riskLevel_{{ formId }}"
									>
										<option value=""></option>
										<option
											*ngFor="let d of apcrtrlChoices$ | async"
											[value]="d.value"
										>
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
						<div class="form-row align-items-center mb-3">
							<div class="col-lg-2 col-12 pr-lg-0">
								<label class="font-weight-bold" for="sourceOfFunds_{{ formId }}"
									>Source of Funds</label
								>
							</div>
							<div class="col-lg-4 col-12">
								<input
									type="text"
									class="form-control theme-crm-field-disabled"
									formControlName="sourceOfFunds"
									id="sourceOfFunds_{{ formId }}"
								/>
							</div>
							<div class="col-lg-2 col-12 pr-lg-0">
								<label class="font-weight-bold" for="sourceOfWealth_{{ formId }}"
									>Source of Wealth</label
								>
							</div>
							<div class="col-lg-4 col-12">
								<input
									type="text"
									class="form-control theme-crm-field-disabled"
									formControlName="sourceOfWealth"
									id="sourceOfWealth_{{ formId }}"
								/>
							</div>
						</div>
						<div class="form-row mb-3 align-items-start">
							<div class="col-lg-2 col-12">
								<label class="font-weight-bold" for="riskProfile_{{ formId }}"
									>Risk Profile</label
								>
							</div>
							<div class="col-lg-10 col-12">
								<textarea
									*ngIf="form.enabled; else riskProfileReadonly"
									formControlName="riskProfile"
									class="form-control theme-crm-field-disabled"
									cols="30"
									rows="6"
									placeholder="- -"
									id="riskProfile_{{ formId }}"
								></textarea>

								<ng-template #riskProfileReadonly>
									<textarea
										class="form-control theme-crm-field-disabled"
										cols="30"
										rows="6"
										placeholder="- -"
										id="riskProfile_{{ formId }}"
										[value]="form.get('riskProfile').value"
										readonly
									></textarea>
								</ng-template>
							</div>
						</div>
						<div class="form-row mb-3 align-items-start">
							<div class="col-lg-2 col-12">
								<label class="font-weight-bold" for="investmentGoals_{{ formId }}"
									>Investment Goals</label
								>
							</div>
							<div class="col-lg-10 col-12">
								<textarea
									*ngIf="form.enabled; else investmentGoalsReadonly"
									formControlName="investmentGoals"
									class="form-control theme-crm-field-disabled"
									cols="30"
									rows="6"
									placeholder="- -"
									id="investmentGoals_{{ formId }}"
								></textarea>

								<ng-template #investmentGoalsReadonly>
									<textarea
										class="form-control theme-crm-field-disabled"
										cols="30"
										rows="6"
										placeholder="- -"
										id="investmentGoals_{{ formId }}"
										[value]="form.get('investmentGoals').value"
										readonly
									></textarea>
								</ng-template>
								<ng-container *appRestrictTo="['FEAP']">
									<button
										*ngIf="hasViewSummary"
										(click)="endProcess(true)"
										class="tap-btn tap-btn--default mt-3"
										type="button"
									>
										View Summary
									</button>
								</ng-container>
							</div>
						</div>
					</ng-container>

					<ng-container
						*ngIf="!['APC', 'API']?.includes(adviceProcess.processCode)"
					>
						<div class="form-row mb-3 align-items-start">
							<div class="col-lg-2 col-12">
								<label class="font-weight-bold" for="review_goals_{{ formId }}"
									>{{ goalsCaption }}</label
								>
							</div>
							<div class="col-lg-10 col-12">
								<textarea
									*ngIf="form.enabled; else reviewGoalsReadonly"
									formControlName="reviewGoals"
									class="form-control theme-crm-field-disabled"
									cols="30"
									rows="6"
									placeholder="- -"
									id="review_goals_{{ formId }}"
								></textarea>

								<ng-template #reviewGoalsReadonly>
									<textarea
										class="form-control theme-crm-field-disabled"
										cols="30"
										rows="6"
										placeholder="- -"
										id="review_goals_{{ formId }}"
										[value]="form.get('reviewGoals').value"
										readonly
									></textarea>
								</ng-template>
							</div>
						</div>
					</ng-container>

					<ng-container
						*ngIf="!['APC', 'API']?.includes(adviceProcess.processCode)"
					>
						<div class="form-row mb-3 align-items-start">
							<div class="col-lg-2 col-12 pr-lg-0">
								<label
									class="font-weight-bold"
									for="important_factors_{{ formId }}"
									>Important Factors</label
								>
							</div>
							<div class="col-lg-10 col-12">
								<textarea
									*ngIf="form.enabled; else importantFactorsReadonly"
									formControlName="importantFactors"
									class="form-control theme-crm-field-disabled"
									cols="30"
									rows="6"
									placeholder="- -"
									id="important_factors_{{ formId }}"
								></textarea>

								<ng-template #importantFactorsReadonly>
									<textarea
										class="form-control theme-crm-field-disabled"
										cols="30"
										rows="6"
										placeholder="- -"
										id="important_factors_{{ formId }}"
										[value]="form.get('importantFactors').value"
										readonly
									></textarea>
								</ng-template>

								<ng-container *appRestrictTo="['FEAP']">
									<button
										*ngIf="hasViewSummary"
										(click)="endProcess(true)"
										class="tap-btn tap-btn--default mt-3"
										type="button"
									>
										View Summary
									</button>
								</ng-container>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="adviceProcess.processCode === 'APC'">
						<div class="form-row mb-3 align-items-start">
							<div class="col-lg-2 col-12">
								<label class="font-weight-bold" for="review_goals_{{ formId }}"
									>Complaint Details</label
								>
							</div>
							<div class="col-lg-10 col-12">
								<textarea
									*ngIf="form.enabled; else complaintDetailsReadonly"
									formControlName="complaintDetails"
									class="form-control theme-crm-field-disabled"
									cols="30"
									rows="6"
									placeholder="- -"
									id="complaint_details_{{ formId }}"
								></textarea>

								<ng-template #complaintDetailsReadonly>
									<textarea
										class="form-control theme-crm-field-disabled"
										cols="30"
										rows="6"
										placeholder="- -"
										id="complaint_details_{{ formId }}"
										[value]="form.get('complaintDetails').value"
										readonly
									></textarea>
								</ng-template>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="adviceProcess.processCode === 'APC'">
						<div class="form-row mb-3 align-items-start">
							<div class="col-lg-2 col-12 pr-lg-0">
								<label
									class="font-weight-bold"
									for="important_factors_{{ formId }}"
									>Outcome Details</label
								>
							</div>
							<div class="col-lg-10 col-12">
								<textarea
									*ngIf="form.enabled; else complaintOutcomeDetailsReadonly"
									formControlName="complaintOutcomeDetails"
									class="form-control theme-crm-field-disabled"
									cols="30"
									rows="6"
									placeholder="- -"
									id="complaint_outcome_details_{{ formId }}"
								></textarea>

								<ng-template #complaintOutcomeDetailsReadonly>
									<textarea
										class="form-control theme-crm-field-disabled"
										cols="30"
										rows="6"
										placeholder="- -"
										id="complaint_outcome_details_{{ formId }}"
										[value]="form.get('complaintOutcomeDetails').value"
										readonly
									></textarea>
								</ng-template>

								<ng-container *appRestrictTo="['FEAP']">
									<button
										*ngIf="hasViewSummary"
										(click)="endProcess(true)"
										class="tap-btn tap-btn--default mt-3"
										type="button"
									>
										View Summary
									</button>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</div>

				<div *ngIf="hasOfflineCRT" class="col-lg-5">
					<div class="form-row mb-2">
						<div
							*ngIf="!hasCRTorMOAT ? !adviceProcess?.isOnline : true"
							class="col-12 col-md-4 d-flex"
							[ngClass]="{ 'align-items-center': isAlterationProcess }"
						>
							<label class="font-weight-bold">
								{{ adviceProcessDocumentsCaption }}
							</label>
						</div>
						<div
							 class="col-12 col-md d-flex"
							 [ngClass]="{ 'align-items-center': isAlterationProcess }"
							>
							<ng-container *appRestrictTo="['FCRT']">
								<div
									*ngIf="adviceProcess?.isOnline && isEditable && hasCRTorMOAT"
									class="form-row"
								>
									<div
										class="col-12 d-flex flex-row"
										[ngClass]="{
										 'align-items-center': isAlterationProcess,
										 'mb-2': !isAlterationProcess
										}"
									>
										<a
											[ngClass]="{'anchor-disable' : !!isPrepopulating}"
											class="tap-btn tap-btn--default"
											(click)="
												prepopulate(adviceProcess?.isStarted ? true : false)
											"
										>
											{{ prepopulateBtnCaption }}
											<!-- {{ adviceProcess?.isStarted ? "Open" : "Start" }} Advice Process -->
										</a>
										<span *ngIf="isPrepopulating" class="pt-1">
											<app-manual-loader-mini
												class="d-flex"
												[isLoading]="isPrepopulating"
											></app-manual-loader-mini>
										</span>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<ng-container>
						<app-advice-process-documents
							[crtPage]="crtPage"
							[hasCRT]="hasCRT"
							[hasMOAT]="hasMOAT"
							[hasKOAT]="hasKOAT"
							[hasLOATV2]="hasLOATV2"
							[isEditable]="isEditable"
							[adviceProcess]="adviceProcess"
							[hasFinalized]="hasFinalized"
							[finalStructure]="finalStructure"
							(doDownload)="downloadFile($event)"
							(doUpload)="openUploadModal($event)"
							(doLinkDocument)="linkDocument($event)"
							(doRemove)="removeFile($event)"
							(doView)="viewFile($event)"
						>
						</app-advice-process-documents>
					</ng-container>
				</div>
			</div>
		</ng-template>

		<ng-container *appRestrictTo="['FVN', 'FVLN']">
			<ng-container *ngIf="!addMode">
				<div class="clearfix d-block w-100">
					<div class="clearfix align-items-center mb-2">
						<app-table-paginate-notes
							[isLead]="isLead"
							[hasAddNote]="adviceProcess?.status < 6"
							[customerId]="customerID"
							[adviceProcessId]="adviceProcess.adviceProcessID"
							[notes]="notes"
							[count]="notes?.length"
							[hasPaging]="true"
							[getFn$]="getNotes$"
							[addFn$]="addNotes$"
							[deleteFn$]="deleteNotes$"
							[itemsPerPage]="5"
							[hideEmail]="false"
							[documentType]="documentType"
							[isCompany]="isCompany"
						>
						</app-table-paginate-notes>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
</form>
