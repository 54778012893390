export class ViewDisplayValue<T = any> {
  display: string;
  value: string;
  isDefault?: boolean;
  isActive?: boolean;
	data?: T;

  static MapFromTuple(model: [string | number, string]): ViewDisplayValue {
    return Object.assign(new ViewDisplayValue(), {
      value: model[0]?.toString(),
      display: model[1]?.toString()
    } as ViewDisplayValue);
  }
  static MapFromTupleList(
    choices: [string | number, string][],
    value: string | number
  ): ViewDisplayValue {
    const parsedChoices: [string, string][] = choices?.map(x => [
      x[0]?.toString(),
      x[1]
    ]) as [string, string][];
    const parsedValue: string = value?.toString();
    if (parsedChoices?.findIndex(x => x[0] === parsedValue) > -1) {
      const toViewModel = parsedChoices?.find(x => x[0] === parsedValue);
      const viewModel = this.MapFromTuple(toViewModel);
      return viewModel;
    } else { return this.MapFromTuple([parsedValue, parsedValue]); }
  }
  static MapFromViewDisplayValueList(
    list: ViewDisplayValue[],
    value: string
  ): ViewDisplayValue {
    return list?.find(x => x.value === value) || this.Map(value, value);
  }
  static Map<T>(v: string | number, d: string): ViewDisplayValue<T>;
  // tslint:disable-next-line: unified-signatures
  static Map(v: string, d: string, isDefault: boolean, isActive?: boolean): ViewDisplayValue;
  // tslint:disable-next-line: unified-signatures
  static Map(v: string, d: string, isDefault: boolean): ViewDisplayValue;
  static Map(v: string, d: string, isDefault?: boolean, isActive?: boolean): ViewDisplayValue {
    return Object.assign(new ViewDisplayValue(), {
      value: v,
      display: d,
      isDefault: isDefault || false,
      isActive: isActive || false
    } as ViewDisplayValue);
  }
  static MapWhere(
    objectParam: any,
    v: (o) => string,
    d: (o) => string
  ): ViewDisplayValue {
    return Object.assign(new ViewDisplayValue(), {
      value: v(objectParam),
      display: d(objectParam)
    } as ViewDisplayValue);
  }
}
