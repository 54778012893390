<div class="clearfix pl-1 h-29" *ngFor="let item of criterias; let i = index;">
  <ul class="d-flex clickable m-0 p-0 list-unstyled" (click)="criteria(item)" [id]="formID ? formID+'_selectCriteria_'+i : 'selectCriteria_'+i">
    <li class="col-auto p-0 m-0 pr-1">
      {{ item.sectionCode }}
      <ng-container [ngSwitch]="item.sectionName">
        <span class="align-middle">
          <i
            *ngSwitchCase="'LR Application'"
            class="fas fa-clipboard tap-text-primary"
          ></i>
          <i
            *ngSwitchCase="'KiwiSaver Application'"
            class="fas fa-kiwi-bird tap-text-primary"
          >
          </i>
          <i
            *ngSwitchCase="'Investment Application'"
            class="fas fa-chart-line tap-text-primary"
          >
          </i>
          <i
            *ngSwitchCase="'Mortgage Application'"
            class="material-icons tap-text-primary icon-font-25px"
          >
            account_balance
          </i>
          <i
            *ngIf="
              item.serviceCode === 'M' && item.sectionName === 'Mortgage Refix'
            "
            class="material-icons tap-text-primary icon-font-25px"
          >
            account_balance
          </i>
          <svg
            *ngSwitchCase="'FG Application'"
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            viewBox="0 0 24 24"
            height="28px"
            class="pb-1"
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g />
              <g>
                <path
                  class="tap-text-primary-fill"
                  d="M20.57,10.66C20.43,10.26,20.05,10,19.6,10h-7.19c-0.46,0-0.83,0.26-0.98,0.66L10,14.77l0.01,5.51 c0,0.38,0.31,0.72,0.69,0.72h0.62C11.7,21,12,20.62,12,20.24V19h8v1.24c0,0.38,0.31,0.76,0.69,0.76h0.61 c0.38,0,0.69-0.34,0.69-0.72L22,18.91v-4.14L20.57,10.66z M12.41,11h7.19l1.03,3h-9.25L12.41,11z M12,17c-0.55,0-1-0.45-1-1 s0.45-1,1-1s1,0.45,1,1S12.55,17,12,17z M20,17c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S20.55,17,20,17z"
                />
                <polygon
                  class="tap-text-primary-fill"
                  points="14,9 15,9 15,3 7,3 7,8 2,8 2,21 3,21 3,9 8,9 8,4 14,4"
                />
                <rect
                  class="tap-text-primary-fill"
                  height="2"
                  width="2"
                  x="5"
                  y="11"
                />
                <rect
                  class="tap-text-primary-fill"
                  height="2"
                  width="2"
                  x="10"
                  y="5"
                />
                <rect
                  class="tap-text-primary-fill"
                  height="2"
                  width="2"
                  x="5"
                  y="15"
                />
                <rect
                  class="tap-text-primary-fill"
                  height="2"
                  width="2"
                  x="5"
                  y="19"
                />
              </g>
            </g>
          </svg>
          <i
            *ngSwitchCase="'LR Claim'"
            class="material-icons tap-text-primary icon-font-25px"
          >
            local_hospital
          </i>
          <i
            *ngSwitchCase="'LR Retention'"
            class="material-icons tap-text-primary icon-font-25px"
          >
            error_outline
          </i>
          <i
            *ngIf="
              item.serviceCode === apCodes.LRAdviceNew ||
              item.serviceCode === apCodes.LRAdviceReview ||
              item.serviceCode === apCodes.MortgageAdvice ||
              item.serviceCode === apCodes.MortgageRefix ||
              item.serviceCode === apCodes.KiwiSaverAdvice ||
              item.serviceCode === apCodes.BlanketAdvice ||
              item.serviceCode === apCodes.Complaint ||
              item.serviceCode === apCodes.FGDomesticNewBusinessAdvice ||
              item.serviceCode === apCodes.FGCommercialNewBusinessAdvice ||
              item.serviceCode === apCodes.FGDomesticMTAAdvice ||
              item.serviceCode === apCodes.FGCommercialMTAAdvice ||
              item.serviceCode === apCodes.FGDomesticRenewalAdvice ||
              item.serviceCode === apCodes.FGCommercialRenewalAdvice ||
              item.serviceCode === apCodes.Investment ||
							item.serviceCode === apCodes.ClientAlterationRequest ||
              item.serviceCode === apCodes.FGClaim ||
              item.serviceCode === apCodes.LRClaim
            "
            class="fas fa-book-open tap-text-primary advice-process-icon"
          >
          </i>
        </span>
      </ng-container>
    </li>
    <li class="col p-0 m-0 mt-1">
      <ul class="d-flex flex-wrap clickable m-0 p-0 list-unstyled">
        <li class="col-auto p-0 m-0 pr-1 txt-font-13px">
          <span>
            {{
              item.sectionName === "LR Application"
                ? "L&R App"
                : item.sectionName === "LR Retention"
                ? "L&R Retention"
                : item.sectionName === "LR Claim"
                ? "L&R Claim"
                : item.sectionName === "FG Application"
                ? "F&G App"
                : item.sectionName === "KiwiSaver Application"
                ? "KiwiSaver App"
                : item.sectionName === "Mortgage Application"
                ? "Mortgage App"
                : item.sectionName
            }}
          </span>
        </li>
        <li *ngIf="!!item.status" class="col-auto p-0 m-0 pr-1 txt-font-13px">
          -
        </li>
        <li *ngIf="!!item.status" class="col-auto p-0 m-0 pr-1 txt-font-13px">
          {{ item.status }}
        </li>
        <li
          *ngIf="item.statusClock !== null"
          class="col-auto p-0 m-0 pr-1 txt-font-13px"
        >
          -
        </li>
        <li class="col-auto p-0 m-0 pr-1 txt-font-13px">
          {{
            item.sectionName === "Mortgage Refix" &&
            !isAdviceProcess(item.serviceCode)
              ? item.date
              : item.statusClock > 1 || item.statusClock === 0
              ? item.statusClock + " days"
              : item.statusClock + " day"
          }}
        </li>
      </ul>
    </li>
  </ul>
</div>
