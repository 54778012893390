<carousel
	[(activeSlide)]="activeSlide"
	[interval]="false"
	[singleSlideOffset]="true"
	[class.disablePrev]="isFirst"
	[class.disableNext]="isLast"
	(activeSlideChange)="onActiveSlideChange($event)"
>
	<slide *ngFor="let slide of slides$ | async; let index = index">
		<ng-container *ngIf="activeSlide === index">
			<ng-container *ngTemplateOutlet="slide.slideContent"></ng-container>
		</ng-container>
	</slide>
</carousel>
