import { Component, ElementRef, Renderer2 } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { TabsetComponent } from '../tabset.component';

// TODO: implement - defer tab rendering to improve performance
@Component({
	selector: 'app-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.scss'],
	exportAs: 'appTab'
})
export class TabComponent extends TabDirective {
	constructor(
		tabset: TabsetComponent,
		public elementRef: ElementRef,
		public renderer: Renderer2
	) {
		super(tabset, elementRef, renderer);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
