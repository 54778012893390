import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { ReferralOptionsModel, ReferralState } from './referral-options.model';
import { Injectable } from "@angular/core";

export interface ReferralOptionsState extends EntityState<ReferralOptionsModel> {
	referrals: ReferralState[];
}

@Injectable()
@StoreConfig({ name: 'emailHistory', idKey: 'AdviceProcessId' })
export class ReferralOptionsStore extends EntityStore<ReferralOptionsState, ReferralOptionsModel> {

	constructor() {
		super({
      referrals: []
		});
	}

	setReferrals(e) {
		this.update({ referrals: e });
	}

	setBlanketReferrals(e) {
		this.update({ blanketReferrals: e });
	}

}
