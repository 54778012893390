<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center px-3"
>
	<app-header
		[pageHeader]="businessFapName + ' - KiwiSaver Advice'"
		pageHeaderCustomClass="tap-fs-20 tap-fw-normal"
		[manualLoading]="isTabLoading$ | async"
		[callbackFn$]="pauseExitFn"
	></app-header>
</div>

<!-- Conttent -->
<div role="main-content" #sosDocument class="px-80 py-20">
	<h1 class="kiwisaver-header mb-20">{{ title }}</h1>
	<div class="scope-of-service clearfix sos-document">
		<div class="scope-of-service__content">
			<form [formGroup]="form" class="form" class="mb-20">
				<div class="kiwisaver-sos clearfix">
					<!-- <div class="kiwisaver-sos__question text-center">
						<h1>Scope of Service</h1>
					</div> -->
					<div class="kiwisaver-sos__container">
						<!-- Check -->
						<div class="kiwisaver-sos__container__item text-center">
							<svg
								class="mb-20"
								width="60"
								height="60"
								viewBox="0 0 60 60"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx="30"
									cy="30"
									r="27.5"
									stroke="#6DBE45"
									stroke-width="5"
								/>
								<path
									d="M18.5 30L27 38.5L43.5 22"
									stroke="#6DBE45"
									stroke-width="5"
								/>
							</svg>

							<div class="kiwisaver-sos__container__item__title">
								<div class="caption font-weight-bold mb-20 px-3">
									What we will talk about today
								</div>
								<div
									class="info mb-20"
									[innerHTML]="WhatWeWillTalkAboutToday?.value || ''"
								></div>
							</div>
						</div>
						<!-- End: Check -->

						<!-- Or -->
						<div
							class="kiwisaver-sos__container__or tap-fs-20 font-weight-bolder"
						>
							<span>Vs</span>
						</div>
						<!-- End: Or -->

						<!-- X -->
						<div class="kiwisaver-sos__container__item text-center">
							<svg
								class="mb-20"
								width="60"
								height="60"
								viewBox="0 0 60 60"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M20 20L41 41" stroke="#FF0000" stroke-width="5" />
								<path d="M20 41L41 20" stroke="#FF0000" stroke-width="5" />
								<circle
									cx="30"
									cy="30"
									r="27.5"
									stroke="#FF0000"
									stroke-width="5"
								/>
							</svg>

							<div class="kiwisaver-sos__container__item__title">
								<div class="caption font-weight-bold mb-20 px-3">
									What we won't talk about today
								</div>
								<div
									class="info mb-20"
									[innerHTML]="WhatWeWontTalkAboutToday?.value || ''"
								></div>
							</div>
						</div>
						<!-- End: X -->
					</div>
				</div>
			</form>
		</div>
	</div>

	<!-- Action Buttons -->
	<div class="scope-of-service__buttons clearfix">
		<button
			type="button"
			class="tap-btn tap-btn-outline tap-btn--shadow mr-2"
			[disabled]="isSaving || isLoading || !defaultSetting"
			(click)="saveDocument()"
		>
			Save to File
		</button>
		<button
			type="button"
			class="tap-btn tap-btn-outline tap-btn--shadow mr-2"
			[disabled]="isSaving || isLoading || !defaultSetting"
			(click)="reloadTemplate()"
		>
			Reload Template
		</button>
		<div *ngIf="isSaving" class="d-inline-block">
			<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
		</div>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end px-3"
>
	<app-footer
		[currentPageId]="pageIds.SOS"
		[manualLoading]="isTabLoading$ | async"
		[sidebar]="sidebar"
	></app-footer>
</div>
