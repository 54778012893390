import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import {
	ActivitySettingProps,
	CRActivityFromDataSetting,
	ClientReferralSettingReferral,
	ReferalOptionType,
} from '@modules/special-features/client-referral-setting/state/client-referral-setting.model';
import { convertUtil, objectUtil } from '@util/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	Observable,
	Subject,
	defer,
	map,
	mergeMap,
	of,
	take,
	tap,
	zip,
} from 'rxjs';
import { ReferClientPopupComponent } from './refer-client-popup.component';
import { isEmpty, isNil } from 'ramda';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { ActivityViewModel } from '@shared/models/_general/activity.viewmodel';
import { ActivityModalComponent } from '@shared/modal/activity/activity-modal/activity-modal.component';
import { LocalService } from '@core/services/local.service';
import { ClientReferralEmailModalComponent } from '@shared/client-referral-email-modal/client-referral-email-modal.component';
import { ClientReferralSettingService } from '@modules/special-features/client-referral-setting/state/client-referral-setting.service';
import { UserQuery } from '@domain/user/user.query';
import { ActivityService } from '@modules/crm/activity/states/activity.service';
import { EmailSignatureService } from '@modules/emails/email-signature/state/email-signature.service';
import { ClientReferralLinkModalService } from '@shared/client-referral-link-modal/client-referral-link-modal.service';
import { MergeTagDataTypes, MergeTagState, MergeTagTable } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { CrtMergeTagsService } from '@modules/crm/client-review-template/states/merge-tags/crt-merge-tags.service';

export enum ClientReferralPopupType {
	CRM = 'crm',
	KOAT = 'koat',
	MOAT = 'moat',
	LOAT = 'loat',
}

export enum ClientReferralOATs {
	MOAT = 'Mortgage',
	KiwiSaver = 'Investment',
	LOAT = 'Life & Risk',
}

@Injectable()
export class ReferClientPopupService {
	AT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AT');
	AM$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AM');
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	referralOT = ReferalOptionType;

	constructor(
		private modalService: BsModalService,
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private localService: LocalService,
		private api: ApiService,
		private clientReferralSettingService: ClientReferralSettingService,
		private userQuery: UserQuery,
		private activityService: ActivityService,
		private emailSignature: EmailSignatureService,
		private clientReferralLinkModalService: ClientReferralLinkModalService,
		private crtMergeTagsService: CrtMergeTagsService,
	) {
		// const staffs = this.bLStaffsQuery.getAll();
		// const blindCarbonCopy = [];
		// data.blindCarbonCopy.split(',').forEach((email) => {
		// 	const user = staffs.find((s) => s.EmailAddress === email);
	}

	getReferrals(): Observable<ClientReferralSettingReferral[]> {
		const endpoint = `staff/0/settings/RO`;
		return this.api.get(endpoint).pipe(
			map((referrals: ClientReferralSettingReferral[]) =>
				referrals.map((r) => objectUtil.mapPascalCaseToCamelCase(r))
			),
			map((referrals: ClientReferralSettingReferral[]) =>
				referrals.filter((r) => Boolean(r.isChecked))
			),
			map((referrals) => referrals.sort((a, b) => a.priority - b.priority))
		);
	}

	// Some referral options wont be present in some OATs, these are to be hardcoded as exclusions
	// - Mortgage referral wont be present in a MOAT referral
	// - KiwiSaver referral wont be present in a KOAT referral
	// - Life and Risk referral wont be present in a LOAT referral
	private filterReferralByOATs(
		referrals: ClientReferralSettingReferral[],
		popupType: ClientReferralPopupType
	): ClientReferralSettingReferral[] {
		return referrals.filter((referral) => {
			switch (popupType) {
				// dont show KiwiSaver referral in KOAT
				case ClientReferralPopupType.KOAT:
					return referral.referralService !== ClientReferralOATs.KiwiSaver;
				// dont show Mortgage referral in MOATJ
				case ClientReferralPopupType.MOAT:
					return referral.referralService !== ClientReferralOATs.MOAT;
				// dont show Mortgage referral in MOATJ
				case ClientReferralPopupType.LOAT:
					return referral.referralService !== ClientReferralOATs.LOAT;
				// don't filter any when popup is open in CRM
				default:
				case ClientReferralPopupType.CRM:
					return true;
			}
		});
	}

	show(
		popupType: ClientReferralPopupType,
		adviserId: any,
		customerId: any,
		customerName: string,
		adviceProcessId?: any,
		isCompany?: boolean
	): Observable<ClientReferralSettingReferral> {
		return defer(() => {
			const subject = new Subject<ClientReferralSettingReferral>();
			const modalRef = this.modalService.show(ReferClientPopupComponent, {
				class: 'refer-client-popup modal-dialog-centered modal-md',
				ignoreBackdropClick: true,
				show: true,
				initialState: {
					subject,
					getReferrals: () =>
						this.getReferrals().pipe(
							map((referrals) =>
								this.filterReferralByOATs(referrals, popupType)
							)
						),
				},
			});
			return subject.asObservable().pipe(
				mergeMap((referralService) => {
					if (!referralService) {
						return of(true);
					}
					return this.referByReferralOption(
						referralService,
						adviserId,
						popupType,
						customerId,
						customerName,
						adviceProcessId,
						isCompany
					);
				}),
				tap(() => modalRef.hide())
			);
		});
	}

	referByReferralOption(
		rs: ClientReferralSettingReferral,
		adviserId: any,
		popupType: ClientReferralPopupType,
		customerId: any,
		customerName: string,
		adviceProcessId: any,
		isCompany: boolean
	): Observable<any> {
		if (isNil(rs)) {
			return;
		}

		switch (rs.referralOptionType) {
			case this.referralOT.link:
			case this.referralOT.email:
				rs.adviser = adviserId;
				rs.customerName = customerName;
				rs.customerId = customerId;
				rs.adviceProcessId = adviceProcessId;
				if (popupType === ClientReferralPopupType.CRM) {
					rs.referenceId = customerId;
				} else {
					rs.referenceId = adviceProcessId;
				}
				break;
		}

		switch (rs.referralOptionType) {
			case this.referralOT.link:
				return this.openLinkPopup(rs);
			case this.referralOT.email:
				return this.openEmailPopup(rs, popupType, isCompany);
			case this.referralOT.activity:
				return this.openActivityPopup(rs, customerId, customerName);
		}
	}

	openLinkPopup(referral: ClientReferralSettingReferral): Observable<any> {
		return this.clientReferralLinkModalService.show(referral).pipe(take(1));
	}

	openActivityPopup(
		activityData: ClientReferralSettingReferral,
		customerID: any,
		customerName: any
	): Observable<any> {
		let activityFormData = {};

		if (!isEmpty(activityData) && !isNil(activityData)) {
			activityFormData = {
				...this.filterReferralActivityData(
					objectUtil.mapCamelCaseToPascalCase(activityData)
				),
				// this is just a placeholder so default meeting wont replace the saved meeting value
				ActivityId: 1,
				Customer: {
					CustomerId: customerID,
					Name: customerName,
				}
			};
		}

		const initState: any = {
			formItem: activityFormData,
			AT$: this.AT$,
			AM$: this.AM$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: false,
			savefn: this.saveActivityFn,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: ['FCOA'],
			isActivityReferral: true,
		};
		const modalRef = this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft'
					? 'modal-dialog-outlook-xl'
					: 'modal-xl'
			}`,
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
		return modalRef.onHidden.asObservable();
	}

	sendEmail(data: any, popupType: ClientReferralPopupType): Observable<any> {
		const d = objectUtil.mapCamelCaseToPascalCase(data);
		const payload: any = {
			Attachment: d?.Attachment,
			EmailFrom: this.userQuery.getValue().EmailAddress,
			EmailDestination: d.EmailDestination,
			EmailCC: d?.CarbonCopy,
			EmailBCC: d?.BlindCarbonCopy,
			EmailSubject: d?.Subject,
			Type: d?.Type,
			HTMLBody: d.HTMLBody,
			StringBody: convertUtil.htmlToText(d?.BodyWithoutSignature || ''),
		}
		if (popupType === ClientReferralPopupType.CRM) {
			payload.ReferenceId = d.ReferenceId;
		} else {
			payload.AdviceProcessId = d.AdviceProcessId;
		}
		return this.api.post(`emails/single`, payload);
	}

	referralEmailPopup(
		referral: ClientReferralSettingReferral,
		popupType: ClientReferralPopupType,
		mergeTags: MergeTagState[]
	): Observable<boolean> {
		const subject = new Subject<ClientReferralSettingReferral | null>();
		const modalRef = this.modalService.show(ClientReferralEmailModalComponent, {
			class: `modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft'
					? 'modal-dialog-outlook-xl'
					: 'modal-xl'
			}`,
			initialState: {
				subject,
				mergeTags,
				showOnInit: true,
				adviser: referral.adviser,
				referral,
				allowDocumentLinking: true,
				sendFn$: (r) => {
					r.type = popupType === ClientReferralPopupType.CRM ? 'CRE' : 'APRE';
					return convertUtil.convertToBase64(r.bodyContent).pipe(
						mergeMap((htmlBody) => {
							r.hTMLBody = htmlBody;
							return this.sendEmail(r, popupType);
						}),
						mergeMap(() => this.sendEmailReferralActivity(r))
					);
					// return this.sendEmail(r);
				},
				documentInfo: {
					documentType: 'RO',
					type: 'RO',
					referenceId: 0,
					customerId: 0,
				},
				// @ts-ignore-next
				documnetBodyLoaderFn$: (data: ClientReferralSettingReferral) =>
					this.clientReferralSettingService.getBody(data.body).pipe(
						take(1),
						mergeMap((body) => {
							return this.emailSignature.getTemplate().pipe(
								take(1),
								map((emailSignature) => [body, emailSignature])
							);
						})
					),
			},
			ignoreBackdropClick: true,
			keyboard: false,
		});
		// since ClientReferralEmailModalComponent, has its own modal
		// we will hide this current modal instance
		modalRef.hide();
		return subject.asObservable().pipe(
			take(1),
			map((result) => Boolean(result))
		);
	}

	openEmailPopup(
		referral: ClientReferralSettingReferral,
		popupType: ClientReferralPopupType,
		isCompany: boolean
	): Observable<boolean> {
		return this.getMergeTags(
			+referral?.adviser,
			+referral?.customerId,
			isCompany
		).pipe(
			mergeMap((mt) => this.referralEmailPopup(referral, popupType, mt)),
			map((result) => Boolean(result))
		);
	}
	filterReferralActivityData(
		activityData: ClientReferralSettingReferral
	): CRActivityFromDataSetting | null {
		if (isEmpty(activityData)) {
			return null;
		}

		const activitySettingProps = Object.values(ActivitySettingProps);

		const result: any = {};
		for (const prop of activitySettingProps) {
			if (activityData.hasOwnProperty(prop)) {
				result[prop] = activityData[prop];
			}
		}
		return result;
	}

	saveActivityFn = (ac: ActivityViewModel) => {
		// @ts-ignore-next
		if (ac.ActivityId) {
			delete ac.ActivityId;
		}
		return this.activityService.addActivityReferral(
			ActivityViewModel.MapToAdd(ac)
		);
	};

	sendEmailReferralActivity(
		referral: ClientReferralSettingReferral
	): Observable<any> {
		const payload = {
			ActivityName: referral.referralService,
			ActivityType: 'Referral',
			Adviser: referral.adviser,
			AdviceProcessId: referral.adviceProcessId,
			Location: '',
			Appointment: true,
			CustomerId: referral.customerId,
			CustomerName: referral.customerName,
			Details: `
To: ${referral.emailDestinationEmailAddress}
Subject: ${referral.subject}
Body: ${convertUtil.htmlToText(referral.bodyWithoutSignature)}
`,
			DueDate: '',
			DueTime: '',
			Duration: 30,
			IsCompleted: true,
			IsActive: true,
			Meeting: 'Phone Meeting',
		};
		return this.api.post(`activities/referral`, payload).pipe(
			take(1),
			// mergeMap((result) => this.addNotes(referral))
		);
	}

	getMergeTags(adviser: number, id: number, isCompany: boolean) {
		let requests$ = [
			this.crtMergeTagsService.getMergeTags(MergeTagTable.Business),
			this.crtMergeTagsService.getMergeTags(MergeTagTable.Staff, adviser || 0),
		];
		if (isCompany) {
			requests$.push(
				this.crtMergeTagsService.postMergeTag({
					referenceId: id || 0,
					dataType: [MergeTagDataTypes.PrimaryCompany],
					table: [MergeTagTable.CustomerDetails],
				})
			);
		} else {
			requests$.push(
				this.crtMergeTagsService.postMergeTag({
					referenceId: id || 0,
					dataType: [
						MergeTagDataTypes.PrimaryClient,
						MergeTagDataTypes.SecondaryClient,
					],
					table: [MergeTagTable.CustomerDetails],
				})
			);
		}
		return zip(...requests$).pipe(
			map((mergeTags) =>
				mergeTags?.reduce((prev, cur) => {
					prev.push(...(cur ?? []));
					return prev;
				}, [])
			)
		);
	}
}
