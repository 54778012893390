<div class="card-header font-weight-bold text-light tap-bg-primary">
  {{ header }}
</div>
<div class="card-body p-4">
  <app-people-form
    [relationship]="scr$ | async"
    [employment]="pce$ | async"
    [visaDetails]="apcrtvd$ | async"
    [apcrtg]="apcrtg$ | async"
    [personInfo]="personInfo"
    [timeInResidence]="apcrttir$ | async"
    [maritalStatus]="apcrtms$ | async"
    [title]="pct$ | async"
    [hasCountry]="hasCountry"
    (saveEvent)="save($event)"
    (cancelEvent)="cancelChange($event)"
    [loadingState]="loadingState"
  >
  </app-people-form>
</div>
