import { KiwisaverRequest } from './kiwisaver-request.model';

// This returns an object
export const comparable = o =>
	typeof o !== 'object' || !o
		? o
		: Object.keys(o)
			?.sort()
			?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (req: KiwisaverRequest, saveReq: KiwisaverRequest) => {
	let r = true;

	if (!req || !saveReq) {
		return (r = false);
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedAdvisers)) !== JSON.stringify(comparable(saveReq.SelectedAdvisers))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedAltAdvisers)) !== JSON.stringify(comparable(saveReq.SelectedAltAdvisers))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedProviders)) !== JSON.stringify(comparable(saveReq.SelectedProviders))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedStatus)) !== JSON.stringify(comparable(saveReq.SelectedStatus))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedLeadOrigins)) !== JSON.stringify(comparable(saveReq.SelectedLeadOrigins))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.NextActivityDateMin)) !== JSON.stringify(comparable(saveReq.NextActivityDateMin))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.NextActivityDateMax)) !== JSON.stringify(comparable(saveReq.NextActivityDateMax))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.StartDateMin)) !== JSON.stringify(comparable(saveReq.StartDateMin))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.StartDateMax)) !== JSON.stringify(comparable(saveReq.StartDateMax))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedTypes)) !== JSON.stringify(comparable(saveReq.SelectedTypes))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.NextReviewDateMin)) !== JSON.stringify(comparable(saveReq.NextReviewDateMin))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.NextReviewDateMax)) !== JSON.stringify(comparable(saveReq.NextReviewDateMax))) {
		r = false;
	}

	return r;
};

export const request = (filter: any, form: KiwisaverRequest, index: number) => {
	const req = {
		SelectedAdvisers: form.SelectedAdvisers,
		SelectedAltAdvisers: form.SelectedAltAdvisers,
		SelectedProviders: form.SelectedProviders,

		SelectedStatus: form.SelectedStatus,
		SelectedLeadOrigins: form.SelectedLeadOrigins,
		SelectedAdviserStatuses: form.SelectedAdviserStatuses,
		SelectedTypes: form.SelectedTypes,
    SelectedOrigins: form.SelectedOrigins,

		NextActivityDateMin: form.NextActivityDateMin,
		NextActivityDateMax: form.NextActivityDateMax,
		StartDateMin: form.StartDateMin,
		StartDateMax: form.StartDateMax,

		NextReviewDateMin: form.NextReviewDateMin,
		NextReviewDateMax: form.NextReviewDateMax,

		Paging: {
			Index: index,
			Column: filter ? filter.Column : null,
			Direction: filter ? filter.Direction : null,
		},
	};
	return req;
};
