import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'tooltip',
  template: `
    <div class="tooltip"
        [style.left]="left + 'px'" 
        [style.top]="top + 'px'"
        [innerHtml]="tooltip"
    >
    </div>
  `,
  styleUrls: ['./tooltip-v2.component.scss'],
  standalone: true
})
export class TooltipV2Component implements OnInit {
  tooltip: string = '';
  visible: boolean;
  left: number = 0;
  top: number = 0;
  color: string;

  constructor() {}

  ngOnInit(): void {}

}