<div class="form-row mb-2 company-label-background">
	<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
		<label for="" class="font-weight-bold label-font tap-text-primary">
			Company
		</label>
	</div>
	<div class="col-5 col-lg-9 text-right px-0 iconShow">
		<button
			id="companyCollapseButton"
			type="button"
			class="btn-toggle bg-white collapse-btn border-0 company-collapse-background"
		>
			<span *ngIf="elseMinusCompany; else elsePlusCompany">
				<i (click)="collapseMoreCompany()" class="material-icons md-20 pt-1"
					>expand_more</i
				>
			</span>
			<ng-template #elsePlusCompany>
				<span>
					<i (click)="collapseLessCompany()" class="material-icons md-20 pt-1"
						>expand_less</i
					>
				</span>
			</ng-template>
		</button>
	</div>
</div>

<div
	class="form-row d-none d-lg-flex mb-2"
	[class.tap-opacity-25]="(company$ | async)?.length === 0"
>
	<div class="col-lg-3">
		<span class="font-weight-bold">Company</span>
	</div>
	<div class="col-lg-2">
		<span class="font-weight-bold">Trading Name</span>
	</div>
	<div class="col-lg-2">
		<span class="font-weight-bold">Industry</span>
	</div>
</div>

<div class="collapse expandable" id="collapseCompany">
	<div
		class="form-row align-items-center"
		*ngFor="let d of company$ | async; let i = index; let last = last"
	>
		<div class="d-md-none col-lg-2 text-right">
			<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
				<button
					id="companyEditButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="editCompany(d.cRTId)"
				>
					<i class="material-icons md-16"> edit </i>
				</button>
				<button
					id="companyDeleteButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="deleteCompany(d.cRTId)"
				>
					<i class="material-icons md-16"> delete </i>
				</button>
			</ng-container>
			<ng-template #viewOnlySmall>
				<button
					id="companyViewButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="viewCompany(d.cRTId)"
				>
					<i class="material-icons md-16"> edit </i>
				</button>
			</ng-template>
		</div>
		<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="d.name && !last">
			<ng-container *ngIf="d.name">
				<input
					id="companyName_{{ i }}"
					class="form-control crt-form-control"
					type="text"
					value="{{ d.name }}"
					[disabled]="d.name"
				/>
			</ng-container>
			<ng-container *ngIf="!d.name">
				<span class="custom-dropdown w-100">
					<select
						[id]="'addNewCompanyDropdown_' + i"
						name="addNewCompanyDropdown"
						class="form-control crt-form-control theme-crm-field-disabled"
						(change)="change($event, i)"
					>
						<option value=""></option>
						<option *ngFor="let d of companyList$ | async" [value]="d.value">
							{{ d.display }}
						</option>
						<option value="new">+ Add New Company</option>
					</select>
				</span>
			</ng-container>
		</div>
		<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="d.name && !last">
			<input
				id="companyTradingName_{{ i }}"
				class="form-control crt-form-control"
				type="text"
				value="{{ d.tradingName }}"
				disabled
			/>
		</div>

		<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="d.name && !last">
			<input
				id="companyIndustry_{{ i }}"
				class="form-control crt-form-control"
				type="text"
				value="{{ d.industry }}"
				disabled
			/>
		</div>
		<div
			class="col-auto d-none d-md-block"
			[class.border-bottom]="d.name && !last"
		>
			<ng-container *ngIf="d.cRTId && !isCrtLoading(d?.cRTId)">
				<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
					<button
						id="companyEditCompanyButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="editCompany(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> edit </i>
					</button>
					<button
						id="companyDeleteCompanyButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="deleteCompany(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> delete </i>
					</button>
				</ng-container>
				<ng-template #viewOnly>
					<button
						id="companyViewCompanyButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1 p-0"
						(click)="viewCompany(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="isCrtLoading(d?.cRTId)">
				<app-manual-loader-mini
					[isLoading]="true"
				></app-manual-loader-mini>
			</ng-container>
			<ng-container *ngIf="!d.cRTId && !isCrtLoading(d?.cRTId)">
				<button
					id="companyAddExistingCompanyButton_{{ i }}"
					color="primary"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="addExistingCompany(d)"
					[disabled]="!d.name"
				>
					<i class="material-icons md-16"> save </i>
				</button>
				<button
					id="companyRemoveCompanyButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="removeCompany(i)"
				>
					<i class="material-icons md-16"> close </i>
				</button>
			</ng-container>
		</div>
	</div>
	<div class="form-row my-4">
		<div class="col-5 col-lg-2 pl-2">
			<ng-container *ngIf="!isAdviceProcessEnded">
				<button
					id="companyAddCompanyButton"
					type="button"
					(click)="addCompany()"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
					[disabled]="
						isAddNew || (isCompanyLoading$ | async) || (isLoading$ | async)
					"
				>
					Add Company +
				</button>
			</ng-container>
			<ng-container *ngIf="isCompanyLoading$ | async">
				<app-manual-loader-mini
					[isLoading]="isCompanyLoading$ | async"
				></app-manual-loader-mini>
			</ng-container>
		</div>
	</div>
</div>
