import MomentUtil from '../../../../util/moment.util';
import { Moment } from 'moment';
import { util } from '../../../../core/util/util.service';
import { KiwisaverState, KiwiSaverTypes } from './kiwisaver.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';

export class KiwisaverMapper {
  /**
   * Map to view
   */
  public static mapToView(data: KiwisaverState): KiwisaverState {
    if (!data) {
      return { ...data };
    }

    return {
      ...data,
      fundOwner: typeof data.fundOwner === 'string'
      ? data.fundOwner
        ? JSON.parse(data.fundOwner)
        : null
      : data.fundOwner,
      fundType:
        typeof data.fundType === 'string'
          ? data.fundType
            ? JSON.parse(data.fundType)
            : null
          : data.fundType,
      startDate: util.isValidMoment(data.startDate)
        ? data.startDate
        : this.formatDateToMoment(data.startDate),
      submittedDate: util.isValidMoment(data.submittedDate)
        ? data.submittedDate
        : this.formatDateToMoment(data.submittedDate),
      // tslint:disable-next-line: max-line-length
      notes:
        data.notes && data.notes.length > 0
          ? data.notes?.filter((x) =>
              util.isValidMoment(data.createDateTime)
                ? data.createDateTime
                : this.formatDateToMoment(x.createDateTime)
            )
          : [],
			startTrans: util.isValidMoment(data.startTrans)
				? data.startTrans
				: this.formatDateToMoment(data.startTrans),
      cancellationDate: util.isValidMoment(data.cancellationDate)
				? data.cancellationDate
				: this.formatDateToMoment(data.cancellationDate),
    };
  }

  /**
   * Map to update
   */
  public static mapToUpsert(data: KiwisaverState): KiwisaverState {
    return {
      ...data,
      fUM: data.fUM ? +data.fUM : null,
      startDate: MomentUtil.formatDateToServerDate(data.startDate),
      startTrans: MomentUtil.formatDateToServerDate(data.startTrans),
      submittedDate: MomentUtil.formatDateToServerDate(data.submittedDate),
      modifiedDateTime: MomentUtil.formatDateToServerDate(
        data.modifiedDateTime
      ),
			serviceCode: ServicesCodes.KiwiSaver,
      cancellationDate: MomentUtil.formatDateToServerDate(data.cancellationDate),
    };
  }

  /**
   * Format date to Moment
   * @param date string or Moment date
   */
  static formatDateToMoment(date: string | Moment): Moment {
    return typeof date === 'string'
      ? util.DateStringToMomentLoose(date)
      : util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
  }
}
