<div class="modal-header p-0">
	<div
		class="title py-3 px-4 d-flex align-items-center w-100 justify-content-between"
	>
		<h3 class="pull-left text-white m-0">{{ title }}</h3>
		<button
			class="close-btn text-white border bg-transparent d-flex align-items-center justify-content-center"
			(click)="close()"
			aria-label="Close"
		>
			<span class="crm-close-btn"></span>
		</button>
	</div>
</div>
<div class="modal-body py-3 px-2">
	<form [formGroup]="formGroup" class="">
		<div class="row m-0 p-0 mb-2">
			<div class="col-2 d-flex align-items-center">
				<label for="templateName">Template Name:</label>
			</div>
			<div class="col-10">
				<input
					class="crt-form-control form-control"
					id="templateName"
					formControlName="templateName"
				/>
			</div>
		</div>
		<div class="row m-0 p-0 mb-2">
			<div class="col-2 d-flex align-items-center">
				<label for="emailTemplateSettingsType">Type:</label>
			</div>
			<div class="col-10">
				<app-chips
            id="emailTemplateSettingsType"
            class="crt-form-control-field"
            formControlName="emailTemplateSettingsType"
            [choices]="claimTypeChoices"
            [remainOpenOnSelect]="remainOpenETSTypeChips"
          ></app-chips>
			</div>
		</div>
		<div class="row m-0 p-0 mb-2">
			<div class="col-2 d-flex align-items-center">
				<label for="carbonCopy">CC:</label>
			</div>
			<div class="col-10">
				<input
					class="crt-form-control form-control"
					id="carbonCopy"
					formControlName="carbonCopy"
				/>
			</div>
		</div>
		<div class="row m-0 p-0 mb-2">
			<div class="col-2 d-flex align-items-center">
				<label for="blindCarbonCopy">BCC:</label>
			</div>
			<div class="col-10">
				<div class="d-flex align-items-end align-items-center">
					<div class="mr-2 d-flex align-items-center">
						<span class="default-bcc">User</span>
						,
					</div>
					<input
						class="crt-form-control form-control"
						id="blindCarbonCopy"
						formControlName="blindCarbonCopy"
					/>
				</div>
			</div>
		</div>
		<div class="row m-0 p-0 mb-2">
			<div class="col-2 d-flex align-items-center">
				<label for="subject">Subject:</label>
			</div>
			<div class="col-10">
				<input
					class="crt-form-control form-control"
					id="subject"
					formControlName="subject"
					appClipboardNoFormat
				/>
			</div>
		</div>
		<div class="row m-0 p-0 mb-2">
			<div class="col-12 d-flex align-items-center">
				<label>Body</label>
			</div>
		</div>
		<div class="row m-0 p-0">
			<div class="col-12">
				<app-wysiwyg
					#editor
					(onChangeEvent)="onEditorValueChanged($event)"
					[ngClass]="{ 'invalid': !(editor?.isValid()) ?? true }"
					[content]="initContent"
					[simpleEditor]="true"
					[shortcodes]="shortCodes"
					[extendOptions]="optionsWysiswyg"
				></app-wysiwyg>
			</div>
		</div>
	</form>

	<!-- <div class="attachments" *ngIf="getAttachmentsFn"> -->
	<!-- 	Email Attachments -->
	<!-- 	<hr> -->
	<!-- 	<div class="row"> -->
	<!-- 	</div> -->
	<!-- </div> -->
</div>

<div class="buttons d-flex flex-column">
	<button
		class="btn btn-primary tap-bg-primary text-light w-100"
		[disabled]="isSaving"
		(click)="save()"
	>
		Save
	</button>

	<button
		class="btn btn-primary tap-bg-primary text-light w-100"
		(click)="testEmail()"
		[disabled]="isSaving"
	>
		Test Email
	</button>

	<button class="btn btn-light w-100" (click)="close()" [disabled]="isSaving">
		Cancel
	</button>
</div>

<ng-template #testEmailTemplateRef>
	<div class="modal-header p-0">
		<div
			class="title py-3 px-4 d-flex align-items-center w-100 justify-content-between"
		>
			<h3 class="pull-left text-white m-0">Test Email</h3>
			<button
				class="close-btn text-white border bg-transparent d-flex align-items-center justify-content-center"
				(click)="closeTestEmail()"
				aria-label="Close"
			>
				<span class="crm-close-btn"></span>
			</button>
		</div>
	</div>
	<div class="modal-body py-3 px-2">
		<form [formGroup]="testEmailForm" class="">
			<div class="row m-0 p-0 mb-2">
				<div class="col-12 d-flex align-items-center mb-2">
					<label for="emailAddress">
						Email address to send the test email
					</label>
				</div>
				<div class="col-12">
					<input
						class="crt-form-control form-control"
						id="templateName"
						formControlName="email"
					/>
				</div>
			</div>
		</form>
	</div>

	<div class="buttons d-flex flex-column">
		<button
			class="btn btn-primary tap-bg-primary text-light w-100"
			[ngClass]="{ 'disabled': testEmailForm.invalid }"
			[disabled]="isSendingTestEmail"
			(click)="sendTestEmail()"
		>
			Send
		</button>

		<button class="btn btn-light w-100" (click)="closeTestEmail()">
			Cancel
		</button>
	</div>
</ng-template>
