import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FundingRequiredLoanStore, FundingRequiredLoanState } from './loan-repaid.store';

@Injectable()
export class FundingRequiredLoanQuery extends QueryEntity<FundingRequiredLoanState> {
	loans$ = this.selectAll();
	isLoading$ = this.selectLoading();
	isLoadedLoans$ = this.select((state) => state.isLoadedLoans);

	constructor(protected store: FundingRequiredLoanStore) {
		super(store);
	}
}
