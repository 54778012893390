import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AdviceSummary {
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	currentPage: string;
	documentId?: number;
	status?: number;
}

export const CrtKiwisaverFactFindStoreName = 'CrtKiwisaver:Advice Summary';
@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: CrtKiwisaverFactFindStoreName,
	idKey: 'cRTId',
})
export class AdviceSummaryStore extends Store<AdviceSummary> {
	constructor() {
		super({});
	}

	set(summary: AdviceSummary): void {
		this.update((state) => summary);
		this.setHasCache(true);
	}
}
