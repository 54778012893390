import { Injectable } from '@angular/core';

import { GlobalSearch } from '../../../shared/models/_general/global-search.model';
import { ApiService } from '../../base/api.service';

@Injectable()
export class GlobalSearchService {
	constructor(private api: ApiService) { }

	globalSearch(keyword: string, code: string) {
		const endpoint = `search?keyword=${keyword}&type=global`;
		return this.api.get<GlobalSearch[]>(endpoint);
	}
}
