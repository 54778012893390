import {
	Component,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	SimpleChanges,
	OnChanges,
	OnDestroy,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { FieldMetadata } from '../../dynamic-field/field-metadata.model';
import { TextareaModalComponent } from '../../modal/textarea-modal/textarea-modal.component';
import { of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { util } from '../../../util/util';
import { BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
	selector: 'app-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent implements OnChanges, OnDestroy {
	/** element to attach the tooltip */
	@ViewChild('longText', { static: true }) longText: ElementRef;
	/** value of dropdown */
	@Input() value: string;
	/** index number for use in ID */
  @Input() index: any;
  /** field name for use in ID */
  @Input() fieldId: any;
	/** restrictions */
	@Input() restrict: string[];
	/** if currently saving */
	@Input() isLoading: boolean;
	/** if currently in edit mode */
	@Input() isEditing: boolean;
	/** to use on save event for now. maybe get rid later */
	@Input() metadata: FieldMetadata<any>;
	/** input whether field is required */
	@Input() isRequired: boolean;
	/** input whether field is editable */
	@Input() isEditable: boolean;

	/** String name to show which column field is being edited in modal */
	@Input() fieldName: string;
	/** event for saving */
	@Output() saveEvent = new EventEmitter<FieldMetadata<any>>();

	constructor(private modalService: BsModalService) {}

	/** run all changes updater */
	ngOnChanges(changes: SimpleChanges): void {
		this.valueChanges(changes);
	}
	/** remove tooltip */
	ngOnDestroy(): void {
		this.removeTooltip();
	}

	editTextareaModal() {
		const initState: Partial<TextareaModalComponent> = {
			header: !util.isNullOrEmpty(this.fieldName) ? `Update ${this.fieldName}` : `Update record`,
			value: this.value,
			savefn: this.saveTextarea,
		};
		this.modalService.show(TextareaModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveTextarea = (value: string) => {
		return of(value).pipe(
			map(v => (util.isNullOrEmpty(v) ? '' : v?.trim())),
			tap(trimmedValue =>
				this.saveEvent.emit({
					...this.metadata,
					value: trimmedValue,
				})
			)
		);
	};

	/** If value changes, tooltip should change value again.
	 * Destroy and add tooltip with new value
	 */
	valueChanges(changes: SimpleChanges) {
		const notChanged = changes.value === undefined || changes.value.currentValue === changes.value.previousValue;
		if (notChanged) {
			return;
		}

		this.removeTooltip();
		this.addTooltip(changes.value.currentValue);
	}

	/** add tooltip. */
	private addTooltip(title: string) {
		if (!this.longText) {
			return;
		}
		$(this.longText.nativeElement).tooltip({
			placement: 'right',
			title: () => title,
		});
	}
	/** remove tooltip */
	private removeTooltip() {
		if (!this.longText) {
			return;
		}
		$(this.longText.nativeElement).tooltip('dispose');
	}
}
