<form novalidate [formGroup]="form" class="filter-form">
	<div class="clearfix">
		<div class="row d-flex justify-content-between">
			<div class="col-auto align-self-center">
				<h1 class="d-inline tap-text-secondary tap-text-h2">Activities</h1>
				<h6
					class="
						mb-0
						px-0 px-md-2
						d-inline
						tap-text-secondary
						d-block d-md-inline-block
					"
				>
					{{ count$ | async | number }} Results
				</h6>
			</div>

			<div class="col-auto align-self-center ml-auto">
				<div class="form-row align-items-center justify-content-end">
					<div class="col-12 col-sm-auto mb-2 mt-2 mb-md-0 mt-md-0">
						<ng-container *appRestrictTo="['FAA']">
							<button
								id="addActivityBtn"
								type="button"
								class="tap-btn tap-btn--default	font-weight-bold tap-btn--sm--block btn-add-activity"
								(click)="createActivityModal()"
							>
								Add New Activity
							</button>
						</ng-container>
					</div>
					<div class="col-auto">
						<button
							id="toggleFilterBtn"
							type="button"
							title="Filter"
							class="icon-btn font-weight-bold"
							(click)="collapse.Toggle(); toggle()"
						>
							<i class="material-icons md-20 tap-text-color-h6 pt-1">
								filter_list
							</i>
						</button>
					</div>
					<div class="col-auto">
						<button
							id="resetFilterBtn"
							type="button"
							title="Reset"
							class="icon-btn"
							(click)="reset()"
						>
							<i class="fas fa-undo fa-14 tap-text-color-h6 pt-1"></i>
						</button>
					</div>
					<div class="col-auto">
						<button
							id="searchBtn"
							type="submit"
							class="tap-btn tap-btn--default font-weight-bold px-lg-4"
							(click)="search()"
							[disabled]="(isSearching$ | async) || (isExporting$ | async)"
						>
							Search
						</button>
					</div>
					<!-- TODO: To add export button -->
					<ng-container *appRestrictTo="['FEXA']">
						<div
							class="col-auto"
							(mouseenter)="showPopover()"
							[placement]="'bottom'"
							[popover]="(exportStatus$ | async) === 3 ? popTemplate : null"
							containerClass="popover-info-container"
							triggers=""
						>
							<button
								id="exportBtn"
								type="button"
								title="Export"
								class="icon-btn"
								(click)="export();"
								[disabled]="(isSearching$ | async) || (isExporting$ | async) || (exportStatus$ | async) === 3"
							>
								<i class="fas fa-download fa-14 tap-text-color-h6 pt-1"></i>
							</button>
						</div>
					</ng-container>
					<div class="col-auto">
						<app-manual-loader-mini
							[isLoading]="(isSearching$ | async) || (isExporting$ | async)"
						>
						</app-manual-loader-mini>
					</div>
					<div class="col-auto">
						<span class="position-relative d-inline-block">
							<app-activity-columns-form
								*ngIf="isOpenColumnsSettings$ | async"
								class="search-settings"
							>
							</app-activity-columns-form>
							<button
								id="settingsBtn"
								type="button"
								title="Settings"
								class="icon-btn"
								(click)="openColumnPopUp()"
							>
								<i
									class="
										material-icons
										md-20
										column-settings-icon
										pt-1
										font-weight-bold
									"
								>
									settings
								</i>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		app-collapse
		#collapse
		[initialExpandedState]="false"
		class="search-collapse"
	>
		<div class="container-fluid ml-0 px-0 pt-3">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Assigned To</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="assignedToId"
								formControlName="AssignedTo"
								[choices]="availableStaffChoices$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="adviserId"
								formControlName="Advisers"
								[choices]="staffChoices$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Created By</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="createdById"
								formControlName="CreatedBy"
								[choices]="availableStaffChoices$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">First Name</label>
						</div>
						<div class="col-12">
							<input
								id="clientFirstNameId"
								type="text"
								formControlName="ClientFirstName"
								class="form-control"
							/>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Activity Type</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="activityTypeId"
								formControlName="ActivityType"
								[choices]="AT$ | async"
							></app-chips>
						</div>
					</div>
					<!-- <div class="row align-items-center mb-1">
            <div class="col-12">
              <label for="">Assigned To</label>
            </div>
            <div class="col-12">
              <app-chips
                textboxId="assignedToId"
                formControlName="AssignedTo"
                [choices]="availableStaffChoices$ | async"
              ></app-chips>
            </div>
          </div> -->

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Activity Status</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="activityStatusId"
								formControlName="ActivityStatus"
								[choices]="activityStatus$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Last Name</label>
						</div>
						<div class="col-12">
							<input
								id="clientLastNameId"
								type="text"
								formControlName="ClientSurname"
								class="form-control"
							/>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser Status</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="adviserStatusId"
								formControlName="AdviserStatus"
								[choices]="adviserStatus$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1"
						*featureToggle="{
							id: 'AdviserRework',
							show: false
						}">
						<div class="col-12">
							<label for="">Alt Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="AltAdvisersId"
								formControlName="AltAdvisers"
								[choices]="staffChoices$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Activity Created Date</label>
						</div>
						<div class="col-12 d-flex">
							<app-date-input
								textboxId="activityCreateDateMinId"
								formControlName="ActivityCreateDateMin"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
							<div class="col-auto align-self-center p-1">to</div>
							<app-date-input
								textboxId="activityCreateDateMaxId"
								formControlName="ActivityCreateDateMax"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Activity Due Date</label>
						</div>
						<div class="col-12 d-flex">
							<app-date-input
								textboxId="activityDueDateMinId"
								formControlName="ActivityDueDateMin"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
							<div class="col-auto align-self-center p-1">to</div>
							<app-date-input
								textboxId="activityDueDateMaxId"
								formControlName="ActivityDueDateMax"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Activity Completed Date</label>
						</div>
						<div class="col-12 d-flex">
							<app-date-input
								textboxId="activityCompleteDateMinId"
								formControlName="ActivityCompleteDateMin"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
							<div class="col-auto align-self-center p-1">to</div>
							<app-date-input
								textboxId="activityCompleteDateMax"
								formControlName="ActivityCompleteDateMax"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!-- Modal -->
<ng-template #exportTemplate>
	<app-export-progress
		[progress]="progress"
		[percent]="percent"
		[msg]="msg"
	></app-export-progress>
</ng-template>
<!-- End Modal -->

<ng-template #popTemplate>
	<div class="popover-info">
		<p>
			Export currently in progress, please wait until its completion
		</p>
	</div>
</ng-template>
