import {
	Component,
  ElementRef,
  HostBinding,
  HostListener,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocalService } from '@core/services/local.service';
import { RouteService } from '@core/config/route.service';
import { CrmRoutingModule } from '@modules/crm/crm-routing.module';
import { ActivityService } from '@modules/crm/activity/states/activity.service';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { ActivityViewModel } from '@shared/models/_general/activity.viewmodel';
import { ActivityModalComponent } from '@shared/modal/activity/activity-modal/activity-modal.component';
import { TooltipV2Directive } from '@shared/directive/tooltip/tooltip-v2.directive';

@Component({
	selector: 'app-quick-add',
	templateUrl: './quick-add.component.html',
	styleUrls: ['./quick-add.component.scss'],
  imports: [
    CommonModule, 
    CrmRoutingModule, 
    MatIconModule,
	],
  standalone: true,
  hostDirectives: [
    {
      directive: TooltipV2Directive,
      inputs: ['appTooltipV2: tapTooltip'],
    }
  ]
})
export class QuickAddComponent {
  private popoverIsShownSubj = new BehaviorSubject(false);
  public popoverIsShown$ = this.popoverIsShownSubj.asObservable();
  private routeChanges$ = this.routeService.RouteChange$;
  private isOpen = false;

  AT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AT');
  AM$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AM');
  adviserChoices$: Observable<ViewDisplayValue[]> = this.blStaffsQuery.allActiveStaffs$;
  adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;
  public leadClientAddLink$ = this.routeChanges$.pipe(
    map((x) => x.leadClientAdd)
  );
  public leadBusinessAddLink$ = this.routeChanges$.pipe(
    map((x) => x.leadBusinessAdd)
  );

  @HostBinding('class') 
  get quickAddClasses() {
    return {
      'menu-item': true,
      'menu-item-circle': true,
      'activity-button': true,
    }
  }
  
  @HostListener('click')
  onClick() {
    this.togglePopover();
  }

  @ViewChild('dropdown') dropdown: ElementRef<HTMLUListElement>;

  @HostListener('document:click', ['$event'])
	onOutsideClick(event) {
		if (!this.el.nativeElement?.contains(event.target)) {
			this.popoverIsShown$
				.pipe(
					take(1),
					map((x) => {
            this.el?.nativeElement.classList.remove('menu-item-selected');
            return false;
          })
				)
				.subscribe((x) => this.popoverIsShownSubj.next(false));
		}
	}

  @HostListener('document:scroll')
  onWindowScroll() {
    this.popoverIsShown$
				.pipe(
					take(1),
					map((x) => {
            if(x) {
              this.positionPopup();
            }
            return x;
          })
				)
				.subscribe((x) => this.popoverIsShownSubj.next(x));
  }

  constructor(
    private el: ElementRef,
    private dropdownValueQuery: DropdownValueQuery,
    private blStaffsQuery: BLStaffsQuery,
    private activityService: ActivityService,
    private modalService: BsModalService,
    private localService: LocalService,
    private routeService: RouteService,
  ) {}

  private togglePopover = () => {
		this.popoverIsShown$
			.pipe(
				take(1),
				map((x) => !x)
			)
			.subscribe((x) => {
        if (x) {
          this.el?.nativeElement.classList.add('menu-item-selected');
        } else {
          this.el?.nativeElement.classList.remove('menu-item-selected')
        }
        this.popoverIsShownSubj.next(x)
      });
  }

  saveActivityFn = (ac: ActivityViewModel) => {
		return this.activityService.addActivityFromOtherPage(
			ActivityViewModel.MapToAdd(ac)
		);
  }

  createActivityModal() {
    const initState: any = {
      formItem: {},
      AT$: this.AT$,
      AM$: this.AM$,
      adviserChoices$: this.adviserChoices$,
      adviserCalendarChoices$: this.adviserCalendarChoices$,
      header: 'Schedule Activity',
      hideClient: false,
      savefn: this.saveActivityFn,
      isModal: true,
      isEditForm: false,
      permissionsToComplete: ['FCOA'],
    };
    this.modalService.show(ActivityModalComponent, {
      class: `modal-dialog-centered ${
        this.localService.getValue('loginType') === 'microsoft'
          ? 'modal-dialog-outlook-xl'
          : 'modal-xl'
      }`,
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  positionPopup () {
    const viewPortWidth = window.innerWidth;
    const parent = this.el?.nativeElement.getBoundingClientRect();
    const menu = this.dropdown?.nativeElement?.getBoundingClientRect();
    const topAdjustments = 9;
    const menuPosX = parent.x;
    const menuPosY = parent.y + parent.height + topAdjustments;
    const menuOutboundWidth = menu?.width - (viewPortWidth - menuPosX);
    const menuPosXAdj = Math.sign(menuOutboundWidth) > 0 ? (menuPosX - menuOutboundWidth) : menuPosX;

    return {
      x: menuPosXAdj,
      y: menuPosY
    }
 }
}

