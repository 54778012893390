import { EmailSettingsModel } from '../../_general/email-settings.model'

export interface CrtEmailModel extends EmailSettingsModel {
	emailTemplate: string;
	// tslint:disable-next-line: ban-types
	attachment: Object;
}

export enum EmailTypes {
	AuthorityToProceed = 'ATP',
	Disclosure = 'DD',
	FinalStructure = 'FS',
	Declaration = 'LOATDES',
	EndProcess = 'APEP', // L&R End Process
	MortgageEndProcess = 'MOATAPEP', // Mortgage Advice End Process
	MOATDeclaration = 'MOATDES',
	MOATReviewApplication = 'MOATRAE',
	MOATApplicationLoanStructure = 'MOATALS',
	MOATROAEmailSettings = 'MOATROAES',
	SOA = 'LOATSOAE',
	SOASettings = 'LOATSOAES',
	ConfirmationCall = 'LOATCCES',
	EmailTemplate = 'ET',
	ClientReferral = 'RO',
	// CAR = Customer Alteration Request
	CARChangeRequest = 'CARCRES',
	CARUpdateStructure = 'CARUSES',
	CAREndProcess = 'CAREPES',
}

export enum EmailBodyTypes {
	AuthorityToProceed = 'CRTATPE',
	Disclosure = 'CRTDDE',
	FinalStructure = 'CRTFSE',
	ChangeRequest = 'CRTCR',
	UpdateStructure = 'CRTUS',
	EndProcess = 'CRTEP',
	// CAR = Customer Alteration Request
	CARChangeRequest = 'CARCRES',
	CARUpdateStructure = 'CARUSES',
	CAREndProcess = 'CAREPES',
}

export enum KOATEmailTypes {
	Disclosure = 'DD'
}
