<button
	id="closeModalBtn"
	class="tap-btn tap-btn-outline modal-btn-close"
	(click)="detachCloseIcon ? close() : decline()"
>
	<span class="material-icons"> close </span>
</button>
<div class="modal-header">
	<h1 class="modal-title">
		{{ header }}
	</h1>
</div>
<div
	*ngIf="canDelete"
	class="align-text modal-body text-center modal-msg"
>
	<p [innerHTML]="message"></p>
</div>
<div *ngIf="!canDelete" class="modal-body align-text modal-msg">
	<p [innerHTML]="message"></p>
</div>
<div class="modal-footer">
	<ng-container *ngIf="!isLoading">
		<button
			id="deleteBtn"
			type="button"
			class="tap-btn tap-text-h4 tap-btn--default mx-2"
			[disabled]="!canDelete"
			(click)="delete()"
		>
			{{ confirmButton || "Delete" }}
		</button>
		<button type="button" class="tap-btn tap-text-h4 tap-btn--default mx-2" (click)="decline()" id="cancelBtn">
			{{ !!confirmButton2 ? confirmButton2 : "Cancel" }}
		</button>
	</ng-container>
	<ng-container *ngIf="isLoading">
		<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
	</ng-container>
</div>
