import {
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { util } from '@core/util/util.service';
import { PDGoalsSettingsQuery } from '@modules/pd-goals/state/pd-goals.query';
import { UserSettingsQuery } from '@modules/user/state/user-settings/user-settings.query';
import MomentUtil from '@util/moment.util';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'app-user-pd-tracking-widgets',
	templateUrl: './user-pd-tracking-widgets.component.html',
	styleUrls: ['./user-pd-tracking-widgets.component.scss'],
})
export class UserPdTrackingWidgetsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() scheduledPd: any[];
	@Input() completedPd: any[];

	pdHoursCompleted: number = 0;
  pdHoursScheduled: number = 0;

	annualPersonalDevelopmentHours: number = 0;
	annualPersonalDevelopmentHoursQuarter: number = 0;

	totalCountPDTracking: number = 0;
  totalCountCompletedPDTracking: number = 0;

	totalCountOverduePDTracking: number = 0;

	constructor(private userSettingsQuery: UserSettingsQuery) {}

	ngOnInit(): void {
		this.userSettingsQuery.pdtWidget$.pipe(
			tap((data) => {
				if (data) {
					this.pdHoursCompleted = data.UserPDTrackingCompleted?.PDHoursCompleted || 0;
					this.annualPersonalDevelopmentHours = data.UserPDTrackingCompleted?.AnnualPersonalDevelopmentHours || 0;

					this.pdHoursScheduled = data.UserPDTrackingScheduled?.PDHoursScheduled || 0;
					this.annualPersonalDevelopmentHoursQuarter = data.UserPDTrackingScheduled?.AnnualPersonalDevelopmentHours || 0;

					this.totalCountPDTracking = data.UserPDTrackingProviderTest?.TotalCountPDTracking || 0;
					this.totalCountCompletedPDTracking = data.UserPDTrackingProviderTest?.TotalCountCompletedPDTracking || 0;

					this.totalCountOverduePDTracking = data.UserPDTrackingOverdue?.TotalCountOverduePDTracking || 0;
				}
			}),
			takeUntil(this.onDestroy$),
		).subscribe();
	}

	dueDateStatus(data) {
		const due = this.formatValidDate(data?.DueDate);
		const nzToday = util.MomentNow();
		const isOverdue = due.diff(nzToday, 'days');
		return isOverdue < 0;
	}

	isWithinThreeMonths(data) {
		const due = this.formatValidDate(data?.DueDate);
		return util.isWithin(due, 3, 'months');
	}

	formatValidDate(value) {
		return util.isValidMoment(value)
			? value
			: MomentUtil.formatDateToMoment(value);
	}


	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
