import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ReviewApplicationState } from './review-application.model';
import { ReviewApplicationStore } from './review-application.store';

@Injectable({ providedIn: 'root' })
export class ReviewApplicationQuery extends QueryEntity<ReviewApplicationState> {
	reviewApplications$ = this.selectAll();
	activeReviewApplication$ = this.selectActive();

	constructor(protected store: ReviewApplicationStore) {
		super(store);
	}
}
