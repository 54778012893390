const headerTxt = `<thead>
<tr>
	<th style="width: 12.7102%;">Loan Type</th>
	<th style="width: 11.6932%;">Loan Term</th>
	<th style="width: 12.8791%;">Fixed Period</th>
	<th style="width: 10.3695%;">Interest Rate</th>
	<th style="width: 15.851%;">Repayments</th>
	<th style="width: 23.384%;">Borrowers</th>
	<th style="width: 13.0079%">Amount</th>
</tr>
</thead>`;

export const loanStructureTbl = {
	header: headerTxt,
};
