import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { StructureSoaComponent } from './structure-soa.component';
import { MoatStructurePages } from 'src/app/shared/models/advice-process/advice-process.model';

@Injectable()
export class StructureSOACanDeactivateGuard
	implements CanDeactivate<StructureSoaComponent>
{
	canDeactivate(
		component: StructureSoaComponent
	): Observable<boolean> | boolean {

    return component.structureSOA$.pipe(
      switchMap((x) => {
        switch (x?.currentPage) {
          case MoatStructurePages.loanStructure: {
            component.loanStructure?.save(false, false);
            break;
          }
          case MoatStructurePages.security: {
            component.roaSecurity?.save(false, false);
            break;
          }
          case MoatStructurePages.otherDetails: {
            component.otherDetails?.save(false);
            break;
          }
        }
        return of(true);
      })
    );
	}
}
