<header 
	class="tap-header bg-white h-fill"
	[ngClass]="{'tap-admin': currentSelectedMenuItem === 'tap'}"
	[appTheme]="{ 'border-top-color': 'primarycolor' }" 
	appResponsive
>
	<div class="container-fluid h-fill header-content">
		<!-- Menu on Mobile -->
		<ng-container *ngIf="currentSelectedMenuItem != 'tap'">
			<div
					*appViewportSize="'small'"
					class="menu-item menu-item-circle header-nav-toggle"
					(click)="handleToggleNav(!toggleNav)"
				>
				<mat-icon class="tap-icon" svgIcon="menu"></mat-icon>
			</div>
		</ng-container>
		<!-- Logo -->
		<div class="header-logo-container">
			<app-logo
				id="logoLink"
				[src]="logoUrl$ | async"
				[routerLink]="dashboardLink$ | async"
			></app-logo>
		</div>
		<div
			class="header-logo bg-white justify-content-center align-items-center d-flex"
		>
		</div>
		<ng-container *ngIf="currentSelectedMenuItem != 'tap'">
			<app-header-nav *appViewportSize="'large'" [navIsShown]="true" [currentSelectedMenuItem]="currentSelectedMenuItem"></app-header-nav>

			<!-- Add new Activity -->
			<app-quick-add *appRestrictTo="['FAA']" tapTooltip="Quick Add"></app-quick-add>

			<!--BL - General Search-->
			<div *appRestrictTo="['FGS']">
				<div 
					*ngIf="(searchReworkFeature$ | async); else v1"
					class="menu-item menu-item-circle global-search-button" 
					(click)="createGlobalSearchModal()"
					appTooltipV2="Search"
				>
					<mat-icon class="tap-icon" svgIcon="search"></mat-icon>
				</div>
				<ng-template #v1>
					<app-global-search-v2
						[isExpanded]="true"
						class="align-self-center"
					></app-global-search-v2>
				</ng-template>
			</div>
			
		</ng-container>
		<div class="align-self-center justify-content-end d-flex" [ngClass]="{'push-to-right': currentSelectedMenuItem === 'tap'}">
			<div class="position-relative align-self-center">
				<h1
					*ngIf="showAnnouncementHeader$ | async"
					class="announcement-header font-weight-normal position-absolute pl-4 d-none d-lg-block"
				>
					Announcement
				</h1>
				<app-user-account tapTooltip="Accounts"></app-user-account>
			</div>
		</div>
	</div>
	<ng-container *ngIf="currentSelectedMenuItem != 'tap'">
		<app-header-nav *appViewportSize="'medium'" [navIsShown]="toggleNav" [currentSelectedMenuItem]="currentSelectedMenuItem" [isSideNav]="false"/>
		<app-header-nav *appViewportSize="'small'" [navIsShown]="toggleNav" [currentSelectedMenuItem]="currentSelectedMenuItem" [isSideNav]="true"/>
	</ng-container>
</header>