import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, Observable, of, throwError, zip } from 'rxjs';
import {
	catchError,
	delay,
	finalize,
	map,
	mapTo,
	mergeMap,
	tap,
} from 'rxjs/operators';
import { objectUtil } from '../../../../../util/util';
import { ApiService } from 'src/app/core/base/api.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { BodyMeasureState } from '../../../../../shared/models/client-review-template/history/measures.model';
import { MedicalHistoryState } from 'src/app/shared/models/client-review-template/history/medical.model';
import { FamilyHistoryState } from 'src/app/shared/models/client-review-template/history/family.model';
import { GpHistoryState } from 'src/app/shared/models/client-review-template/history/gp.model';
import { BusinessService } from 'src/app/core/business/business.service';
import { PeopleService } from '../people/people.service';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { filter, flatten } from 'ramda';

@Injectable()
export class HistoryService extends ClientReviewTemplateService {
	constructor(
		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected route: ActivatedRoute,
		protected businessService: BusinessService,
		private peopleService: PeopleService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	/**
	 * Get Medical History
	 * @param adviceProcessId number
	 */
	getMedicalHistory(adviceProcessId: number) {
		const endpoint = `crt/fact-find/${adviceProcessId}/group/MH`;
		return this.api.get(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			tap((x) => {
				const bodyMeasures = x?.bodyMeasures || [];
				const familyHistory = x?.familyHistory || [];
				const medicalHistoryHealthIssues = x?.medicalHistoryHealthIssues || [];
				const gPDetails = x?.gPDetails || [];

				this.store.setBodyMeasures(bodyMeasures);
				this.store.setMedicalHistoryOptions({
					heightOptions:
						bodyMeasures?.length > 0 ? bodyMeasures[0]?.heightOptions : '',
					weightOptions:
						bodyMeasures?.length > 0 ? bodyMeasures[0]?.weightOptions : '',
				});
				this.store.setFamilyHistory(familyHistory);
				this.store.setMedicalHistory(medicalHistoryHealthIssues);
				this.store.setGpHistory(gPDetails);
			})
		);
	}

	getBodyMeasures(adviceProcessId: number, refetch: boolean = false) {
		const endpoint = `crt/fact-find/${adviceProcessId}/FMS`;
		return !!this.query.getValue().bodyMeasures && !refetch
			? of(undefined)
			: this.api.get<BodyMeasureState[]>(endpoint).pipe(
					tap((x) => {
						applyTransaction(() => {
							const state = x
								? (x?.map(
										objectUtil.mapPascalCaseToCamelCase
								  ) as BodyMeasureState[])
								: [];
							this.store.setBodyMeasures(state);
							this.store.setMedicalHistoryOptions({
								heightOptions: state[0].heightOptions,
								weightOptions: state[0].weightOptions,
							});
						});
					}),
					catchError(() => of([]))
			  );
	}

	addBodyMeasures(measures) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(measures);
		delete body.CRTId;
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					const data = [
						...this.query.getValue().bodyMeasures?.filter((y) => {
							if (y.client !== measures.client) {
								return y;
							}
						}),
						{
							client: measures.client,
							heightOptions: measures.heightOptions,
							heightValue: measures.heightValue,
							weightOptions: measures.weightOptions,
							weightValue: measures.weightValue,
							smokingStatus: measures.smokingStatus,
							sectionCode: AdviceProcessSectionCodes.Statistics,
							cRTId: x,
							bMI: measures.bMI,
						},
					];
					this.store.setBodyMeasures(data);
					this.store.setMedicalHistoryOptions({
						heightOptions: data[0].heightOptions,
						weightOptions: data[0].weightOptions,
					});
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateBodyMeasures(measures, medicalHistoryOption?) {
		const endpoint = `crt/${measures.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(measures);
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().bodyMeasures?.map((y) =>
						y.cRTId === measures.cRTId
							? {
									client: measures.client,
									heightOptions: medicalHistoryOption
										? medicalHistoryOption.heightOptions
										: body.HeightOptions,
									heightValue: measures.heightValue,
									weightOptions: medicalHistoryOption
										? medicalHistoryOption.weightOptions
										: body.WeightOptions,
									weightValue: +measures.weightValue,
									smokingStatus: measures.smokingStatus,
									sectionCode: AdviceProcessSectionCodes.Statistics,
									cRTId: measures.cRTId,
									bMI: measures.bMI,
							  }
							: y
					) as any[];
					this.store.setBodyMeasures(data);
				})
			),
			catchError((err) => {
				if (err.CRTId) {
					const data = this.query
						.getValue()
						.bodyMeasures?.filter(
							(bodyMeasure) => bodyMeasure.cRTId !== measures.cRTId
						);
					this.store.setBodyMeasures(data);
				}
				return throwError(err);
			})
		);
	}

	updateMedicalHistoryOptions(measures, history?) {
		this.store.setMedicalHistoryOptions({
			heightOptions: measures.heightOptions,
			weightOptions: measures.weightOptions,
		});
		const data = this.query.getValue().bodyMeasures?.map((y) => {
			return history?.map((x) => {
				if (x.cRTId === y.cRTId) {
					return {
						btnSavePrimary: x.btnSavePrimary,
						btnEditPrimary: x.btnEditPrimary,
						client: y.client,
						heightOptions: measures.heightOptions,
						heightValue: x.heightValue,
						weightOptions: measures.weightOptions,
						weightValue: +x.weightValue,
						smokingStatus: y.smokingStatus,
						sectionCode: AdviceProcessSectionCodes.Statistics,
						cRTId: y.cRTId,
						bMI: +x.bMI,
					};
				}
			});
		});

		const formatData = filter((n) => n !== undefined, flatten(data));

		this.store.setBodyMeasures(formatData);
	}

	getProfessionalDoctorContacts(customerId) {
		this.store.setGpClientsIsLoading(true);
		return !!this.query.getValue().gpClients
			? of(undefined)
			: zip(
					this.customerService.GetSecondaryProfessionalsByPrimaryClient(
						customerId
					)
			  ).pipe(
					map(([scp]) => {
						const combine = [
							...(scp ?? [])
								?.map(objectUtil.mapPascalCaseToCamelCase)
								?.filter((e) => e.professionalType === 'Doctor'),
						];
						this.store.setGpClients(combine);

						return combine?.map((x) => {
							return {
								customerID: x.customerID,
								gPName: x.name,
								businessName: x.businessName || null,
								address: x.physicalAddress || x.address || null,
								phoneNumber: x.phone || x.mobile || null,
								emailAddress: x.email || null,
								professionalType: x.professionalType,
								sectionCode: AdviceProcessSectionCodes.GPDetails,
							};
						});
					}),
					finalize(() => this.store.setGpClientsIsLoading(false))
			  );
	}

	getMedical(adviceProcessId, isForced?: boolean) {
		this.store.setGpMedicalHistoryIsLoading(true);
		const endpoint = `crt/fact-find/${adviceProcessId}/FMM`;

		return !!this.query.getValue().medicalHistory && !isForced
			? of(undefined)
			: this.api.get<MedicalHistoryState[]>(endpoint).pipe(
					tap((x) => {
						applyTransaction(() => {
							const state = x
								? (x?.map(
										objectUtil.mapPascalCaseToCamelCase
								  ) as MedicalHistoryState[])
								: [];
							this.store.setMedicalHistory(state);
						});
					}),
					catchError(() => of([])),
					finalize(() => this.store.setGpMedicalHistoryIsLoading(false))
			  );
	}

	addMedical(medical, adviceProcessId) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(medical);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().medicalHistory,
						{
							sectionCode: AdviceProcessSectionCodes.MedicalHistory,
							client: medical.client,
							healthIssue: medical.healthIssue,
							dateRangeMin: medical.dateRangeMin,
							dateRangeMax: medical.dateRangeMax,
							detailsNotes: medical.detailsNotes,
							currentlyExcludedLoaded: medical.currentlyExcludedLoaded,
							cRTId: x,
						},
					]?.filter((y) => y.cRTId && y.cRTId !== 0);
					this.store.setMedicalHistory(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateMedical(medical: MedicalHistoryState) {
		const endpoint = `crt/${medical.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(medical);
		body.AdviceProcessId = medical.adviceProcessId;
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().medicalHistory?.map((y) =>
						y.cRTId === medical.cRTId
							? {
									client: medical.client,
									healthIssue: medical.healthIssue,
									dateRangeMin: medical.dateRangeMin,
									dateRangeMax: medical.dateRangeMax,
									detailsNotes: medical.detailsNotes,
									currentlyExcludedLoaded: medical.currentlyExcludedLoaded,
									cRTId: medical.cRTId,
							  }
							: y
					) as any[];
					this.store.setMedicalHistory(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	getFamily(adviceProcessId, isForce?: boolean) {
		this.store.setFamilyHistoryIsLoading(true);
		const endpoint = `crt/fact-find/${adviceProcessId}/FMF`;
		return !!this.query.getValue().familyHistory && !isForce
			? of(undefined)
			: this.api.get<FamilyHistoryState[]>(endpoint).pipe(
					tap((x) => {
						applyTransaction(() => {
							const state = x
								? (x?.map(
										objectUtil.mapPascalCaseToCamelCase
								  ) as FamilyHistoryState[])
								: [];
							this.store.setFamilyHistory(state);
						});
					}),
					catchError(() => of([])),
					finalize(() => this.store.setFamilyHistoryIsLoading(false))
			  );
	}

	addFamily(family, adviceProcessId) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(family);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().familyHistory,
						{
							sectionCode: AdviceProcessSectionCodes.FamilyHistory,
							client: family.client,
							healthIssue: family.healthIssue,
							familyMember: family.familyMember,
							ageAtDiagnosis: family.ageAtDiagnosis,
							details: family.details,
							currentlyExcludedLoaded: family.currentlyExcludedLoaded,
							cRTId: x,
						},
					]?.filter((y) => y.cRTId && y.cRTId !== 0);
					this.store.setFamilyHistory(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	addSecondaryProfessional() {
		return this.customerService.AddSecondaryProfessional;
	}
	updateFamily(family) {
		const endpoint = `crt/${family.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(family);
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().familyHistory?.map((y) =>
						y.cRTId === family.cRTId
							? {
									client: family.client,
									healthIssue: family.healthIssue,
									familyMember: family.familyMember,
									ageAtDiagnosis: family.ageAtDiagnosis,
									details: family.details,
									currentlyExcludedLoaded: family.currentlyExcludedLoaded,
									sectionCode: family.sectionCode,
									cRTId: family.cRTId,
							  }
							: y
					) as any[];
					this.store.setFamilyHistory(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	getGp(adviceProcessId, refetch?) {
		const endpoint = `crt/fact-find/${adviceProcessId}/FMG`;
		return !!this.query.getValue().gpHistory && !refetch
			? of(undefined)
			: this.api.get<GpHistoryState[]>(endpoint).pipe(
					tap((x) => {
						applyTransaction(() => {
							const state = x
								? (x?.map(
										objectUtil.mapPascalCaseToCamelCase
								  ) as GpHistoryState[])
								: [];
							this.store.setGpHistory(state);
						});
					}),
					catchError(() => of([]))
			  );
	}

	addGp(gpClient, adviceProcessId, isNew) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(gpClient);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post<any>(endpoint, body).pipe(
			delay(300),
			mergeMap(() => this.getGp(adviceProcessId, true)),
			catchError(() => EMPTY)
		);
	}

	// addSecondaryProfessional() {
	// 	this.customerService.AddSecondaryProfessional;
	// }

	updateGp(gp) {
		const endpoint = `crt/${gp.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(gp);
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().gpHistory?.map((y) =>
						y.cRTId === gp.cRTId
							? {
									adviceProcessId: gp?.adviceProcessId,
									customerId: gp?.customerId,
									client: gp.client,
									gPName: gp.gPName,
									businessName: gp.businessName,
									phoneNumber: gp.phoneNumber,
									emailAddress: gp.emailAddress,
									sectionCode: gp.sectionCode,
									professionalType: gp.professionalType,
									physicalAddress: gp.physicalAddress,
									cRTId: gp.cRTId,
							  }
							: y
					) as any[];
					this.store.setGpHistory(data);
					const doctors = this.query.getValue().gpClients?.map((gpClient) => {
						return gpClient.customerID === gp?.customerId
							? {
									customerID: gp.customerId,
									name: gp.gPName,
									businessName: gp.businessName,
									address: gp.physicalAddress,
									phoneNumber: gp.phoneNumber,
									emailAddress: gp.emailAddress,
									professionalType: gp.professionalType,
									sectionCode: AdviceProcessSectionCodes.GPDetails,
							  }
							: gpClient;
					});
					this.store.setGpClients(doctors);
				})
			),
			catchError(() => EMPTY)
		);
	}

	removeCrt(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.delete<any>(endpoint).pipe(catchError(() => EMPTY));
	}

	removeGpHistory(crtId, customerId) {
		const endpoint = `crt/${crtId}`;
		return this.api.delete<any>(endpoint).pipe(
			tap((x) => {
				applyTransaction(() => {
					const gpHistory = this.query
						.getValue()
						.gpHistory?.filter((client) => client.cRTId !== crtId);
					this.store.setGpHistory(gpHistory);
				});
				this.updateGpClient(customerId);
			}),
			catchError(() => EMPTY)
		);
	}

	updateGpClient(customerId) {
		applyTransaction(() => {
			const gpClients = this.query
				.getValue()
				.gpClients.filter((client) => client.customerID !== customerId);
			this.store.setGpClients(gpClients);
		});
	}

	resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
		const adviceProcessId = +route.paramMap.get('adviceProcessId');

		const bodyMeasures$ = this.getBodyMeasures(adviceProcessId);
		const medicalHistory$ = this.getMedical(adviceProcessId);
		const familyHistory$ = this.getFamily(adviceProcessId);
		const gpHistory$ = this.getGp(adviceProcessId);
		// const people$ = this.peopleService.getPeople(
		// 	adviceProcessId,
		// 	AdviceProcessSectionCodes.People
		// );

		return forkJoin([
			bodyMeasures$,
			medicalHistory$,
			familyHistory$,
			gpHistory$,
		]).pipe(mapTo(true));
	}
}
