import { Injectable } from '@angular/core';
import { ApiService } from '../base/api.service';
import { Observable, Observer, map, of, take, tap } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailDuplicateState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { objectUtil } from '@util/util';
import { CheckDuplicateEmailModalComponent } from '@shared/modal/check-duplicate-email-modal/check-duplicate-email-modal.component';

@Injectable()
export class EmailDuplicateService {
	constructor(private api: ApiService, private modalService: BsModalService) {}

	checkEmailDuplicate(email: string) {
		if (!email) {
			return of([]);
		}
		const endpoint = `contacts/check-duplicate?email=${email}`;
		return this.api
			.get<EmailDuplicateState[]>(endpoint)
			.pipe(
				map((x) =>
					(x || [])?.map((p) => objectUtil.mapPascalCaseToCamelCase(p))
				)
			);
	}

	emailDuplicateDialog(
		customerId: number,
		email: string,
		confirmCb: Function,
		declineCb?: Function
	) {
		const confirm = new Observable((obs) => {
			if (typeof confirmCb === 'function') {
				confirmCb();
			}
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<JSON>) => {
			if (typeof declineCb === 'function') {
				declineCb();
			}
			obs.complete();
		});
		return this.checkEmailDuplicate(email).pipe(
			tap((emails) => {
				const otherEmails = emails?.filter(
					(x) => +x?.customerID !== customerId
				);
				if (otherEmails?.length > 0) {
					const initState = {
						emails: otherEmails,
						header: 'Email Address Already Exists',
						message: `The email address you are trying to save already belongs to another client in the system. To avoid duplication and ensure data accuracy, please update the existing file accordingly. If you do not have access to view the existing record and need to be assigned to this file, please contact your Support Team.`,
						confirmTxt: 'Save Anyway',
						confirm$: confirm,
						cancelTxt: 'Cancel',
						decline$: decline,
					};
					this.modalService.show(CheckDuplicateEmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog',
						initialState: initState,
						ignoreBackdropClick: true,
					});
				} else {
					confirmCb();
				}
			}),
			take(1)
		);
	}

	displayDuplicateDialog(
		errMessage:string,
		confirmCb: Function,
		declineCb?: Function
	) {
		const confirm = new Observable((obs) => {
			if (typeof confirmCb === 'function') {
				confirmCb().pipe(take(1)).subscribe();
			}
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<JSON>) => {
			if (typeof declineCb === 'function') {
				declineCb();
			}
			obs.complete();
		});
		
		const initState = {
			duplicates:errMessage,
			header: 'Email Address Already Exists',
			message: `The email address you are trying to save already belongs to another client in the system. To avoid duplication and ensure data accuracy, please update the existing file accordingly. If you do not have access to view the existing record and need to be assigned to this file, please contact your Support Team.`,
			confirmTxt: 'Save Anyway',
			confirm$: confirm,
			cancelTxt: 'Cancel',
			decline$: decline,
		};
		this.modalService.show(CheckDuplicateEmailModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}
}
