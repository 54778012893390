import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CpSettingsService } from '../cp-settings.service';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { finalize, take, map } from 'rxjs/operators';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { Observable, zip } from 'rxjs';
import * as R from 'ramda';

@Component({
	selector: 'app-cp-settings-additional-info',
	templateUrl: './cp-settings-additional-info.component.html',
})
export class CpSettingsAdditionalInfoComponent implements OnInit {
	@ViewChild('contentEditor') editor: WysiwygComponent;

	bodyContent: string;

	isSaving = false;

	isLoading = false;

	formGroup = new UntypedFormGroup({
		carbonCopy: new UntypedFormControl(''),
		blindCarbonCopy: new UntypedFormControl(''),
		subject: new UntypedFormControl(''),
		templateID: new UntypedFormControl(''),
		type: new UntypedFormControl('CPRAIES'),
		referenceId: new UntypedFormControl(0),
		cRTSettingsId: new UntypedFormControl(0),
    unsubscribeGroupID: new UntypedFormControl(''),
	});

	optionsWysiswyg = {
		heightMax: null,
		heightMin: 250,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};

	mergeTags$: Observable<MergeTagState[]> = zip(
    this.cpSettingsService.getMergeTags('client', 'client', 0, 'FFP').pipe(take(1)),
		this.cpSettingsService.getMergeTags('general').pipe(take(1)),
		this.cpSettingsService.getMergeTags('business').pipe(take(1)),
		this.cpSettingsService.getMergeTags('staff').pipe(take(1)),
		this.cpSettingsService.getMergeTags('section', 'fact-find', 0, 'FPP').pipe(take(1))
	).pipe(
		map((x) => x.map((y) => R.values(y))),
		map((x) => R.flatten(x)),
	);

	constructor(private cpSettingsService: CpSettingsService) {}

	ngOnInit(): void {
		this.isLoading = true;
		this.cpSettingsService
			.getAdditionalSetting()
			.pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
			.subscribe((res) => {
				this.formGroup.patchValue(res ?? {});
				this.bodyContent = res?.body ?? '';
				this.isLoading = false;
			});
	}

	update(): void {
		const data = this.formGroup.getRawValue();
		data.body = this.editor.content;
		data.referenceId = 0;
		this.isSaving = true;
		this.cpSettingsService
			.updateAdditionalSetting(data)
			.pipe(
				finalize(() => (this.isSaving = false)),
				take(1)
			)
			.subscribe();
	}

  submit(e: Event): void {
    e.preventDefault();
  }
}
