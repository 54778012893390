import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MortgageCustomerServiceState } from './../../../../../../../../shared/models/services/mortgage/mortgage.model';
import { Mortgage } from './mortgage.model';

export interface MortgageState extends EntityState<Mortgage> {
	crmMortgages: MortgageCustomerServiceState[];
}

@Injectable()
@StoreConfig({ name: 'mortgage', idKey: 'cRTId' })
export class MortgageStore extends EntityStore<MortgageState, Mortgage> {
	constructor() {
		super({
			crmMortgages: [],
		});
	}
}
