<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
      close
  </span>
</button>
<div class="modal-header">
  <h1 class="modal-title">
    {{ header }}
  </h1>
</div>
<div class="align-text modal-body text-center" style="word-break: break-all;">
  <p [innerHTML]="message"></p>
</div>
<div class="modal-footer">
  <button id="copyButton" type="button" class="tap-btn tap-btn--default mr-2"
      (click)="copy()">Copy</button>
  <button id="declineButton" type="button" class="tap-btn tap-btn--default" (click)="decline()">Cancel</button>
</div>
