import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { HeaderRow, BodyRow } from './simple-table.model';

/**
 * Simple reusable table.
 * To be used only for pipelines for now.
 */
@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableComponent implements OnInit {

  @Input() headerRow: HeaderRow;
  @Input() hasSubHeader: boolean;
  @Input() rows: BodyRow[];

  constructor() { }

  ngOnInit() {
  }

}
