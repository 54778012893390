<span #oatHeader></span>
<app-main-page
	[family]="familyName$ | async"
	[isToggleSidebar]="isToggleSidebar"
	appearance="clean"
	(toggleSidebar)="toggleSidebar()"
	oatType="KOAT"
	[adviserId]="staffId$ | async"
>
	<accordion
		role="sidebar"
		[isAnimated]="true"
		[closeOthers]="true"
		#sidebarNav
	>
		<ng-template
			ngFor
			let-sb
			[ngForOf]="sidebars"
			let-i="index"
			[ngForTrackBy]="trackByFn"
		>
			<accordion-group
				*ngIf="!sb?.isSubMenu"
				class="crt-sidebar"
				[isDisabled]="sb?.isDisabled || (isTabLoading$ | async)"
				[class.disabled]="sb?.isDisabled || (isTabLoading$ | async)"
				[class.active-panel]="
					sb?.link === currentRoute ||
					(!!sb?.pageCode &&
						!!currentRouteData &&
						sb?.pageCode === currentRouteData?.type)
				"
				routerLinkActive="active-panel"
				(click)="triggerNavigate(sb?.link, false, sb?.pageCode)"
				[class.no-sub]="!sb?.subMenu?.length"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font d-flex align-items-center"
					[class.menu-item--grey]="sb?.status === 0"
					[class.menu-item--orange]="sb?.status === 1"
					[class.menu-item--success]="sb?.status === 2"
					accordion-heading
					type="button"
				>
					<div class="flex-fill text-truncate">{{ sb?.name }}</div>
					<i
						*ngIf="sb?.status === 1"
						class="material-icons warning float-right md-16 pt-1"
						[tooltip]="sb?.warning"
						container="body"
						placement="left"
						>warning</i
					>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{ sb?.status <= 1 ? "panorama_fish_eye" : "check_circle" }}
					</i>
				</button>
			</accordion-group>

			<!-- 6436 -->
			<accordion-group
				*ngIf="sb?.isSubMenu"
				[isOpen]="rla?.isActive"
				class="sub-menu crt-sidebar"
				routerLinkActive="active-panel"
				[class.d-none]="sb?.isHidden"
				#rla="routerLinkActive"
				[routerLinkActiveOptions]="{ exact: true }"
				[class.active-panel]="
					sb?.link === currentRoute ||
					(!!sb?.pageCode &&
						!!currentRouteData &&
						sb?.pageCode === currentRouteData?.type)
				"
				[isDisabled]="sb?.isDisabled"
				[class.disabled]="sb?.isDisabled"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font d-flex align-items-center"
					[class.menu-item--grey]="sb?.status === 0"
					[class.menu-item--orange]="sb?.status === 1"
					[class.menu-item--success]="sb?.status === 2"
					accordion-heading
					type="button"
				>
					<div class="flex-fill text-truncate">{{ sb?.name }}</div>
					<i class="material-icons icon-color float-right md-16">
						{{ sb?.status <= 1 ? "panorama_fish_eye" : "check_circle" }}
					</i>
				</button>
				<accordion [id]="sb?.id" [isAnimated]="true">
					<accordion-group
						*ngFor="let subMenu of sb?.subMenu"
						class="crt-sidebar"
						[class.d-none]="subMenu?.isHidden"
						[isDisabled]="true"
						panelClass="sub-menu-content"
						[class.active-panel]="
							subMenu?.link === currentRoute ||
							(!!sb?.pageCode &&
								!!currentRouteData &&
								subMenu?.pageCode === currentRouteData?.type)
						"
						routerLinkActive="active-panel"
						(click)="
							triggerNavigate(sb?.link, true, subMenu?.pageCode, subMenu?.link)
						"
					>
						<button
							class="btn btn-link btn-block clearfix btn-font d-flex align-items-center"
							[class.menu-item--grey]="subMenu?.status === 0"
							[class.menu-item--orange]="subMenu?.status === 1"
							[class.menu-item--success]="subMenu?.status === 2"
							accordion-heading
							type="button"
						>
							<div class="flex-fill text-truncate">{{ subMenu?.name }}</div>
							<i
								*ngIf="subMenu?.status === 1"
								class="material-icons warning float-right md-16 pt-1"
								[tooltip]="subMenu?.warning"
								container="body"
								placement="left"
								>warning</i
							>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									subMenu?.status <= 1 ? "panorama_fish_eye" : "check_circle"
								}}
							</i>
						</button>
					</accordion-group>
				</accordion>
			</accordion-group>
		</ng-template>
	</accordion>

	<router-outlet
		(activate)="onActivate()"
		(hideSidebar)="(hideSidebar)"
	></router-outlet>
</app-main-page>
