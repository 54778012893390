import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';

@Component({
	selector: 'app-multi-select-modal',
	templateUrl: './multiselect-modal.component.html',
	styleUrls: ['./multiselect-modal.component.scss']
})
export class MultiSelectModalComponent implements OnInit, OnDestroy {
	private subs: Subscription[] = [];
	header: string;
	valuesJson: string | null | undefined | any[];
	isSaving = false;
	choices: ViewDisplayValue[];
	isRequired: boolean;
	savefn: (model: any) => Observable<any>;

	form: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder, public bsModalRef: BsModalRef) { }

	ngOnInit() {
		this.form = this.fb.group({
			multiselect: this.fb.control([], (this.isRequired ? Validators.required : []))
		});

		const value =
			this.valuesJson && typeof this.valuesJson === 'string' ? JSON.parse(this.valuesJson)
				: (this.valuesJson instanceof Array && this.valuesJson.length > 0 ? this.valuesJson : []);

		this.form.get('multiselect').setValue(value);
	}

	save() {
		this.isSaving = true;

		const value = this.form.value.multiselect && this.form.value.multiselect.length > 0 ? this.form.value.multiselect : null;

		this.subs.push(
			this.savefn(value).subscribe(
				res => {
					if (res || (!res && !this.isRequired)) {
						this.isSaving = false;
						this.decline();
					}
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			)
		);
	}

	decline = () => this.bsModalRef.hide();

	ngOnDestroy() {
		this.subs.forEach(x => x.unsubscribe());
		this.subs = [];
	}
}
