import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ConfirmationCallFinalSectionSetting } from './confirmation-call-question.model';
import { ConfirmationCallFinalSectionStore } from './confirmation-call-final-section.store';

@Injectable({ providedIn: 'root' })
export class ConfirmationCallFinalSectionQuery extends Query<ConfirmationCallFinalSectionSetting> {
	constructor(protected store: ConfirmationCallFinalSectionStore) {
		super(store);
	}
}
