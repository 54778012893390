import { ContentInsuranceState, SpecifiedItemState } from './content-insurance.model';
import { util } from '../../../../core/util/util.service';
import MomentUtil from '../../../../util/moment.util';

export class ContentInsuranceMapper {
	/** Mapping for view */
	public static mapToView(data: any){
		return {
            ...data,
			lastReviewed: util.isValidMoment(data.lastReviewed) ? data.lastReviewed : MomentUtil.formatDateToMoment(data.lastReviewed),
			date: util.isValidMoment(data.date) ? data.lastReviewed : MomentUtil.formatDateToMoment(data.date)
		};
  }

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: ContentInsuranceState, cid?: number, adviceProcessId?: number): ContentInsuranceState {
    const specificItems = data.specificItemList?.map(item => <SpecifiedItemState> {
      specifiedItem : item.specifiedItem,
      description: item.description,
      value: +item.value
    });
    return {
      ...data,
      lastReviewed: MomentUtil.formatDateToServerDate(data.lastReviewed),
      date: MomentUtil.formatDateToServerDate(data.date),
      cRTId: cid,
      cost: +data.cost,
      sumInsured: +data.sumInsured,
      currentExcess: +data.currentExcess,
      premium: +data.premium,
      specificItems: JSON.stringify(specificItems),
      specificItemList: specificItems,
      adviceProcessId: +adviceProcessId,
      sectionCode: 'FFGCOI'
    };
	}
}
