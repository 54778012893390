import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ExportsStore } from './exports.store';
import { ExportsState } from './exports.model';


@Injectable({ providedIn: 'root' })
export class ExportsQuery extends Query<ExportsState> {
	staffId$ = this.select((x) => x?.staffId || 0);
	exportId$ = this.select((x) => x?.exportId || 0);
	reportCode$ = this.select((x) => x?.reportCode || '');
	reportLabel$ = this.select((x) => x?.reportLabel || '');
  timeElapsed$ = this.select((x) => x?.timeElapsed || 0);
  timeRemaining$ = this.select((x) => x?.timeRemaining || 0);
	progress$ = this.select((x) => x?.progress || 0);
  status$ = this.select((x) => x?.status);
	attempts$ = this.select((x) => x?.attempts || 0);
	error$ = this.select((x) => x?.error || null);

	constructor(protected store: ExportsStore) {
		super(store);
	}
}
