import { Injectable } from "@angular/core";
import { ConfirmationCallSettings } from "./confirmation-call-email-settings.model";
import { ConfirmationCallStore } from "./confirmation-call.store";
import { Query } from "@datorama/akita";

@Injectable({ providedIn: 'root' })
export class ConfirmationCallQuery extends Query<ConfirmationCallSettings> {

	emailSettings$ = this.select((state) => state.email);

	constructor(protected confirmationCallStore: ConfirmationCallStore) {
		super(confirmationCallStore);
	}
}
