<div class="field">
  <div [class]="cellClass">
    <a *ngIf="route; else textTemplate" class="m-auto" [ngClass]="{'clickable' : route}" [routerLink]="route"
      (click)="onClick()" id="{{fieldId}}Link_{{index}}">
      {{ value }}
    </a>
  </div>
</div>
<ng-template #textTemplate>
  <span class="m-auto">
    {{ value }}
  </span>
</ng-template>