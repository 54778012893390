import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, take } from 'rxjs';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';

@Component({
	selector: 'app-activity-bulk-modal',
	templateUrl: './activity-bulk-modal.component.html',
	styleUrls: ['./activity-bulk-modal.component.scss'],
})
export class ActivityBulkModalComponent implements EditActivityModalModel {
	edit$: Observable<ActivityViewModel>;
	savefn: (ac: ActivityViewModel) => Observable<unknown>;
	header: string;
	message: string;
	formItem: ActivityViewModel;
	isLoading = false;
	isModal = false;
	hideClient = false;
	isRequiredDate = false;
	permissionsToComplete: string[];
	isEditForm = true;
	isActivitySettings = false;
	isActivityReferral = false;
	clearIds: () => void;

	public readonly AT$: Observable<ViewDisplayValue[]>;
	public readonly AM$: Observable<ViewDisplayValue[]>;
	public readonly adviserChoices$: Observable<ViewDisplayValue[]>;
	public readonly adviserCalendarChoices$: Observable<ViewDisplayValue[]>;

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
	}

	setupProps() {
		this.header = 'Schedule Activity';
		this.message = '';
	}

	transfer(activity: ActivityViewModel) {
		this.isLoading = false;
		this.closeModal();
		this.savefn(activity).pipe(take(1)).subscribe();
	}

	closeModal = () => this.bsModalRef.hide();

	close() {
		this.bsModalRef.hide();
		if (this.clearIds) {
			this.clearIds();
		}
	}
}

export interface EditActivityModalModel {
	edit$: Observable<unknown>;
	header: string;
	message: string;
	permissionsToComplete: string[];
}
