import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	ChangeDetectorRef,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
	UntypedFormArray,
} from '@angular/forms';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { TrustMapper } from '../../../../../../shared/models/client-review-template/trust/trust.mapper';
import { TrustDetailsState } from '../../../../../../shared/models/client-review-template/trust/trust-details';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { OATFeatures } from '@shared/directive/feature-toggle/oat-feature.directive';
import { DependantState } from '@modules/crm/crt-page/crt-kiwisaver/people-entities/dependants/state/dependants.model';
import { PeopleState } from '@shared/models/client-review-template/people/people.model';
import { uniq } from 'ramda';

@Component({
	selector: 'app-trusts-form',
	templateUrl: './trusts-form.html',
	styleUrls: ['./trusts-form.scss'],
})
export class TrustsFormComponent implements OnInit {
	@Input() trustInfo: TrustDetailsState;
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() trustTypes: ViewDisplayValue[];
	@Input() viewMode: boolean;
	@Input() loatv2Enabled: boolean;
	public bsModalRef: BsModalRef;

	@Input() peopleAndDependents: (PeopleState | DependantState)[];

	@Input() people: PeopleState[];

	beneficiariesNewRowIndexes = new Set<number>();

	@Input() loading;
	@Input() cancelLoading;

	form: UntypedFormGroup;
	submitted = false;

	oATFeatures = OATFeatures;

	@Input() location: string;

	get addLOATv2ClassName(): boolean {
		return this.location === 'LOAT' && this.loatv2Enabled ? true : false;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService,
		private cdr: ChangeDetectorRef
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.form.reset(TrustMapper.mapToView(this.trustInfo));

		const beneficiary =
			typeof this.trustInfo?.beneficiary === 'string'
				? JSON.parse(this.trustInfo.beneficiary)
				: null;
		const trustees =
			typeof this.trustInfo?.trustee === 'string'
				? JSON.parse(this.trustInfo.trustee)
				: null;
		const independentTrustNames =
			typeof this.trustInfo?.independentTrustName === 'string'
				? JSON.parse(this.trustInfo.independentTrustName)
				: null;

		const mapDataToFB = (items: string[]) => {
			return (
				items?.map((item) => {
					return this.fb.group({
						name: this.fb.control(item),
						isCustomValue: this.fb.control(true),
					});
				}) ?? []
			);
		};

		if (trustees) {
			this.form.setControl('trustee', this.fb.array(mapDataToFB(trustees)));
		}
		if (independentTrustNames) {
			this.form.setControl(
				'independentTrustName',
				this.fb.array(mapDataToFB(independentTrustNames))
			);
		}
		if (beneficiary) {
			this.form.setControl('beneficiary', this.fb.array(mapDataToFB(beneficiary)));
		}
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	}

	get TrustName() {
		return this.form.get('trustName');
	}

	get trustee(): UntypedFormArray {
		return this.form.get('trustee') as UntypedFormArray;
	}

	get independentTrustName(): UntypedFormArray {
		return this.form.get('independentTrustName') as UntypedFormArray;
	}

	get beneficiary(): UntypedFormArray {
		return this.form.get('beneficiary') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			trustName: ['', [Validators.required, NoWhitespaceValidator]],
			tradingName: [''],
			trustType: ['', [Validators.required, NoWhitespaceValidator]],
			trustee: this.fb.array([]),
			independentTrustName: this.fb.array([]),
			beneficiary: this.fb.array([]),
		});
	}

	addTrustee() {
		this.trustee.push(
			this.fb.group({
				name: this.fb.control(''),
				isCustomValue: this.fb.control(false),
			})
		);
	}

	removeTrustee(i) {
		this.trustee.removeAt(i);
	}

	addIndependentTrustee() {
		this.independentTrustName.push(
			this.fb.group({
				name: this.fb.control(''),
				isCustomValue: this.fb.control(false),
			})
		);
	}

	removeIndependentTrustee(i) {
		this.independentTrustName.removeAt(i);
	}

	addBeneficiary() {
		this.beneficiary.push(
			this.fb.group({
				name: this.fb.control(''),
				isCustomValue: this.fb.control(false),
			})
		);
	}

	removeBeneficiary(indexndex: number) {
		this.beneficiary.removeAt(indexndex);
	}

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (!this.form.valid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		const mapNameToArr = (items: { name: string }[]) => {
			return (
				items?.map((items) => items.name).filter((name) => Boolean(name)) ?? []
			);
		};

		const formValue = this.form.getRawValue();
		formValue.beneficiary = mapNameToArr(formValue.beneficiary);
		formValue.trustee = mapNameToArr(formValue.trustee);
		formValue.independentTrustName = mapNameToArr(
			formValue.independentTrustName
		);
		const data = TrustMapper.mapToUpsert(formValue, this.trustInfo);
		this.saveEvent.emit(data);
	}

	cancelClick() {
		this.cancelEvent.emit(true);
	}

	beneficiaryChange(name: string, index: number): void {
		if (name === 'new') {
			// this will convert selectbox to text box
			this.beneficiary.controls[index].setValue({
				name: '',
				isCustomValue: true,
			});
		}
	}

	trusteeChange(name: string, index: number): void {
		if (name === 'new') {
			// this will convert selectbox to text box
			this.trustee.controls[index].setValue({
				name: '',
				isCustomValue: true,
			});
		}
	}

	independentTrustChange(name: string, index: number): void {
		if (name === 'new') {
			// this will convert selectbox to text box
			this.independentTrustName.controls[index].setValue({
				name: '',
				isCustomValue: true,
			});
		}
	}

	getBeneficiaryDd(index: number) {
		const selection = this.peopleAndDependents || [];
		const currentValue = this.beneficiary.controls[index]?.value?.name;
		const formValue = this.form.getRawValue();
		const selected = (formValue?.beneficiary || [])
			?.filter((x) => !!x.name && x.name !== currentValue)
			?.map((x) => x?.name);
		return uniq(selection?.filter((x) => !selected?.includes(x?.name)) || []);
	}

	getTrusteeDd(index: number) {
		const selection = this.people || [];
		const currentValue = this.trustee.controls[index]?.value?.name;
		const formValue = this.form.getRawValue();
		const selected = (formValue?.trustee || [])
			?.filter((x) => !!x.name && x.name !== currentValue)
			?.map((x) => x?.name);
		return uniq(selection?.filter((x) => !selected?.includes(x?.name)) || []);
	}

	getIndependentTrusteeDd(index: number) {
		const selection = this.people || [];
		const currentValue = this.independentTrustName.controls[index]?.value?.name;
		const formValue = this.form.getRawValue();
		const selected = (formValue?.independentTrustName || [])
			?.filter((x) => !!x.name && x.name !== currentValue)
			?.map((x) => x?.name);
		return uniq(selection?.filter((x) => !selected?.includes(x?.name)) || []);
	}
}
