<div class="disclosure-document clearfix">
	<div class="disclosure-document__content position-relative w-100">

		<div role="main-content">
			<ng-container *ngIf="crtData && crtFileType === '.txt' || (!crtData && defaultSettings && defaultSettings?.templateType === 'FT')">
				<app-wysiwyg
					#contentEditor
					[content]="content"
					[readOnly]="true"
					[generatePdf$]="generatePdf$"
				></app-wysiwyg>
			</ng-container>

			<ng-container *ngIf="crtData && crtFileType === '.pdf' || (!crtData && defaultSettings && defaultSettings?.templateType === 'UD')">
				<div class="wrapper">
					<app-view-pdf
						*ngIf="fileUrl"
						[fileSource]="fileUrl"
						(onPageRendered)="onPageRendered($event)"
					></app-view-pdf>
				</div>
			</ng-container>

		<div class="clearfix py-4">
			<button
				id="saveDisclosureBtn"
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-2"
				(click)="saveDocument(); generatedPdf()"
				[disabled]="!!isCompLoading"
			>
				Save to File
			</button>
			<button
				id="downloadDisclosureBtn"
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-2"
				(click)="downloadDocument()"
				[disabled]="!!isCompLoading"
			>
				Download Document
			</button>
			<button
				id="reloadDisclosureBtn"
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-2"
				(click)="reloadTemplate()"
				[disabled]="!!isCompLoading"
			>
				Reload Template
			</button>
			<button
				id="emailDisclosureBtn"
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mb-2"
				(click)="sendEmail(); generatedPdf()"
				[disabled]="!!isCompLoading || !(disclosureSettings$ | async)"
				*ngIf="isEnableEmailOption"
			>
				Email Client
			</button>

			<ng-container *ngIf="!!isCompLoading">
				<app-manual-loader-mini
					[isLoading]="!!isCompLoading"
					class="pl-1"
				></app-manual-loader-mini>
			</ng-container>
		</div>
	</div>
</div>
