import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '@core/base/api.service';
import { CustomerService } from '@core/customer/customer.service';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { CrtKiwiSaverQuery } from '../crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../crt-kiwisaver.service';
import { CrtKiwiSaverStore } from '../crt-kiwisaver.store';
import { MergeTagsQuery } from './crt-kiwisaver-mergetags.query';
import { BusinessService } from '@core/business/business.service';
import { UserQuery } from '@domain/user/user.query';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { FactFindSubSectionCodes } from '@shared/models/advice-process/advice-process.model';
import { objectUtil } from '@util/util';
import { isNil } from 'ramda';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ProviderCommissionService } from '@modules/user/service/provider-commission.service';

const factFindCodes: string[] = [...FactFindSubSectionCodes].map(
	(code: string) => code.toLowerCase()
);

@Injectable()
export class MergeTagsService extends CrtKiwiSaverService {
	mergeTags$ = this.mtQuery.mergeTags$;

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtKiwiSaverStore,
		protected query: CrtKiwiSaverQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected userQuery: UserQuery,
		protected mtQuery: MergeTagsQuery,
		protected clientProfileService: ClientProfileService,
		protected providerService: ProviderCommissionService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService,
			clientProfileService
		);
	}

	getDefaultMergeTags(refetch = false) {
		let requests$ = [];
		const refId = 0;

		const getMtGeneral$ = this.getMergeTags('general', refId);
		const getMtBusiness$ = this.getMergeTags('business', refId);
		if (isNil(this.query.getValue().mergeTags) || refetch) {
			requests$ = [getMtGeneral$, getMtBusiness$];
		}
		return forkJoin([...requests$]);
	}

	getMergeTags(tableCode: string, referenceId: number, dataType?: string) {
		let endpoint = `crt/merge-tag/${tableCode}/reference/${referenceId}`;
		if (dataType) {
			endpoint = `${endpoint}/${dataType}`;
		}
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getFactFindMt(adviceProcessId: number) {
		const body = {
			ReferenceId: +adviceProcessId,
			DataType: factFindCodes,
			Table: ['fact-find'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getAdviserProviderMt(adviserId?: number) {
		const id = adviserId || +this.query.getValue().adviceProcess?.adviser;
		return forkJoin([
			this.getMergeTags('staff', id),
			this.getMtSettings(id, 'PCT'),
			this.providerService.getProviderCommissionK(id),
		]);
	}

	getMtSettings(referenceId: number, dataType?: string) {
		const endpoint = `crt/merge-tag/settings/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getMtSettingDetails(referenceId: number, dataType?: string) {
		const endpoint = `crt/merge-tag/setting-details/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getSosMt(adviceProcessId: number) {
		const body = {
			ReferenceId: +adviceProcessId,
			DataType: ['sos'],
			Table: ['scope-of-service'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getAdviceSummaryMt(adviceProcessId: number) {
		const body = {
			ReferenceId: +adviceProcessId,
			DataType: ['KOATFFIG', 'KOATRPQ', 'KOATASPP', 'KOATASPD'],
			Table: ['fact-find', 'advice-summary'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	updateMtState(data) {
		applyTransaction(() => {
			const state = this.query.getValue().mergeTags || [];
			const updatedData = data?.reduce((acc, curr) => {
				const index = acc?.findIndex((item) => item.metaKey === curr.metaKey);
				if (index === -1) {
					return [...acc, curr];
				}
				acc[index] = curr;
				return acc;
			}, state);
			this.store.setMergeTags(updatedData);
		});
	}
}
