import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import { strUtil } from '../../../util/util';

@Component({
	selector: 'app-note-add-modal',
	templateUrl: './note-modal.component.html',
})
export class NoteAddModalComponent implements OnInit, OnDestroy {
	private subs: Subscription[] = [];
	header: string;
	isSaving = false;
	notes: string;
	savefn: (model: string) => Observable<any>;
	form: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder, public bsModalRef: BsModalRef) {
		this.buildForm();
	}

	ngOnInit() {
		this.form.patchValue({ notes: this.notes ?? '' });
	}

	buildForm() {
		this.form = this.fb.group({
			notes: ['', [Validators.required, NoWhitespaceValidator]],
		});
	}

	save(vm) {
		if (!this.form.valid) {
			return;
		}
		this.isSaving = true;

		this.subs.push(
			this.savefn(strUtil.safeTrim(vm.notes)).subscribe(
				(res) => {
					if (res) {
						this.isSaving = false;
					}
					this.decline();
				},
				() => {
					this.isSaving = false;
				}
			)
		);
	}

	decline = () => this.bsModalRef.hide();

	ngOnDestroy() {
		this.subs.forEach((x) => x.unsubscribe());
		this.subs = [];
	}
}
