import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';

@Component({
	selector: 'app-login-automation',
	templateUrl: './login-automation.component.html',
	styleUrls: ['./login-automation.component.scss'],
})
export class LoginAutomationComponent {
	loginForm: FormGroup;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthenticationService,
	) {
		this.loginForm = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.email]),
			password: new FormControl('', [Validators.required]),
		});
	}

	onSubmit() {
		this.authService
			.loginAutomation({
				code: '',
				redirectUrl: window.location.origin + '/login-automation',
				authType: 'email-password',
				body: { email: this.loginForm.value.email, password: this.loginForm.value.password },
			})
			.subscribe(
				(next: string) => {
					if (typeof next !== 'string') {
						this.router.navigate(['/']);
					} else {
						this.router.navigateByUrl('/login/unauthorized');
					}
				},
				(_error) => {
					// this.isSigningIn = false;
				},
			);
		if (this.loginForm.valid) {
		}
	}
	_v() {
		return this.loginForm.value;
	}
}
