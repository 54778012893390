<form
	[formGroup]="form"
	[class.submitted]="submitted"
	[class.ng-invalid]="
		(!addMode && !mortgage?.mortgageFrequency) ||
		(!mortgage?.loanType && !addMode) ||
		(!addMode && mortgage?.pendingAdviserApproval)
	"
	[ngClass]="isMoatV2 ? 'moat-v2' : 'moat-v1'"
	class="clearfix"
	novalidate
>
	<div class="form-row align-items-center">
		
		
		<div class="col-12 col-lg-3" *ngIf="!isMoatV2; else moatV2Lender">
			<ng-container *ngIf="!!addMode">
				<span class="w-100" [class.crt-customer-dropdown]="form.enable">
					<select
						formControlName="lenderLoanValue"
						id="mortgageMortgageLender_{{ formId }}"
						class="form-control crt-form-control theme-crm-field-disabled mortgageMortgageLenderAdd"
						(change)="onSelectMortgage($event.target.value)"
					>
						<option *ngFor="let d of crmMortgagesChoices" [value]="d.value">
							{{ d.display }}
						</option>
						<option value="new">+ Add New Mortgage</option>
					</select>
				</span>
			</ng-container>
			<ng-container *ngIf="!addMode">
				<span class="w-100" [class.crt-customer-dropdown]="form.enable">
					<select
						formControlName="lender"
						id="mortgageMortgageLender_{{ formId }}"
						class="form-control crt-form-control theme-crm-field-disabled"
					>
						<option
							value=""
							selected
							disabled
							hidden
							class="text-muted"
						></option>
						<option *ngFor="let d of MP$ | async" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
			</ng-container>
		</div>
		
		<ng-template #moatV2Lender>
			<div class="col-12 col-lg-3">
				<ng-container *ngIf="!!addMode">
					<span class="w-100" [class.crt-customer-dropdown]="form.enable">
						<select
							formControlName="lenderLoanValue"
							id="mortgageMortgageLender_{{ formId }}"
							class="form-control crt-form-control theme-crm-field-disabled moatv2"
							(change)="onSelectMortgage($event.target.value)"
						>
							<option *ngFor="let d of crmMortgagesChoices" [value]="d.value">
								{{ d.display }}
							</option>
							<option value="new">+ Add New Mortgage</option>
						</select>
					</span>	
				</ng-container>

				<ng-container *ngIf="!addMode">
					<span class="w-100" [class.crt-customer-dropdown]="form.enable">
						<select
							formControlName="lender"
							id="mortgageMortgageLender_{{ formId }}"
							class="form-control crt-form-control theme-crm-field-disabled moatv2"
						>
							<option
								value=""
								selected
								disabled
								hidden
								class="text-muted"
							></option>
							<option *ngFor="let d of MP$ | async" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</ng-container>
			</div>
		</ng-template>


		<div class="col-12 col-lg-3" *ngIf="!isMoatV2">
			<ng-container *ngIf="!addMode">
				<input
					formControlName="interestRate"
					type="text"
					id="mortgageMortgageInterestRate_{{ formId }}"
					class="form-control crt-form-control"
					placeholder="Interest Rate"
				/>
			</ng-container>
		</div>
		
		<div class="col-12 col-lg-3" *ngIf="isMoatV2">
			<ng-container>
				<input
					formControlName="interestRate"
					type="text"
					id="mortgageMortgageInterestRate_{{ formId }}"
					class="form-control crt-form-control"
					placeholder="Interest Rate"
					disabled
				/>
			</ng-container>
		</div>


		<div class="col-12 col-lg-3" *ngIf="!isMoatV2">
			<ng-container *ngIf="!addMode">
				<app-date-input
					formControlName="fixedPeriodEnd"
					placeholder="Fixed Until"
					textboxId="mortgageFixedPeriodEnd_{{ formId }}"
					textboxClass="mortgage-fixed-period-end"
					class="crt-form-control-field"
					[attr.disabled]="form.disabled"
				></app-date-input>
			</ng-container>
		</div>
		<div class="col-12 col-lg-3" *ngIf="isMoatV2">
			<app-date-input
				formControlName="fixedPeriodEnd"
				placeholder="Fixed Until"
				textboxId="mortgageFixedPeriodEnd_{{ formId }}"
				class="crt-form-control-field"
				[attr.disabled]="true"
				[disabled]="true"
			></app-date-input>
		</div>

		<div class="col-12 col-lg-2" *ngIf="!isMoatV2">
			<ng-container *ngIf="!addMode">
				<div class="dollar-icon">
					<input
						formControlName="loanValue"
						type="text"
						id="mortgageMortgageValue_{{ formId }}"
						class="form-control crt-form-control"
						placeholder="Loan Value"
						currencyMask
					/>
					<i>$</i>
				</div>
			</ng-container>
		</div>

		<div class="col-12 col-lg-2" *ngIf="isMoatV2">
			<ng-container>
				<div class="dollar-icon">
					<input
						formControlName="loanValue"
						type="text"
						id="mortgageMortgageValue_{{ formId }}"
						class="form-control crt-form-control"
						placeholder="Loan Value"
						currencyMask
						disabled
					/>
					<i>$</i>
				</div>
			</ng-container>
		</div>


		<div class="col-lg-1 order-first text-right order-lg-last">
			<ng-container *ngIf="isSaving$ | async as isSaving; else buttons">
				<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
			</ng-container>
			<ng-template #buttons>
				<ng-container *ngIf="(!(isEdit$ | async) && !addMode)">
					<ng-container
						*ngIf="
							!mortgage?.pendingAdviserApproval;
							else pendingAdviserApproval
						"
					>
						<button
							id="mortgageEditMortgageButton_{{ formId }}"
							(click)="editMortgage()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">edit</i>
						</button>
						<button
							id="mortgageDeleteMortgageButton_{{ formId }}"
							(click)="deleteMortgage()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
					<ng-template #pendingAdviserApproval>
						<button
							id="pendingAdviserApprovalButton"
							(click)="approveConfirmation()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="fas fa-exclamation-triangle fa-16"></i>
						</button>
					</ng-template>
				</ng-container>
				<ng-container *ngIf="(isEdit$ | async) || addMode">
					<button
						id="mortgageSaveButton_{{ formId }}"
						(click)="save()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="!form.get('lenderLoanValue').value"
					>
						<i class="material-icons md-16 tap-text-primary">save</i>
					</button>
					<button
						id="mortgageCancelButton_{{ formId }}"
						(click)="cancel()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
					>
						<i class="material-icons md-16">close</i>
					</button>
				</ng-container>
			</ng-template>
		</div>
	</div>
</form>
