<div class="container-fluid px-0">
  <div class="row tap-container">
    <div class="col pt-2">
      <h6>Partial View</h6>
    </div>
  </div>
  <div class="row tap-container">
    <div class="col scc-border mb-2"></div>
  </div>
  <div class="row tap-container">
    <div class="col pt-1">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody #tbodyTarget>
            <tr *ngFor="let row of excelData; first as isFirst">
              <td *ngFor="let value of row; let valueIndex = index" style="min-width: 250px;">

                <div *ngIf="isFirst" class="dd">
                  <span class="custom-dropdown w-100">
                    <select class="form-control ddOption" (change)="changeColumnOrder($event, valueIndex)">
                      <option value=""></option>
                      <option *ngFor="let d of dropdownValues; let ocIndex = index" [value]="d.display"
                        [selected]="d.display === value">
                        {{d.display}}
                      </option>
                    </select>
                  </span>
                </div>

                <span>{{value}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row tap-container">
    <div class="col scc-border mb-2"></div>
  </div>
</div>