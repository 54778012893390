import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { complement, either, isNil, isEmpty } from 'ramda';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { SOASubSectionCodes } from '../../../../shared/models/advice-process/advice-process.model';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { ConfirmModalComponent } from '../../../../shared/modal/confirm-modal/confirm-modal.component';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ProviderCommissionSettingState } from '../../../../shared/models/provider-commission/provider-commission.model';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { CrtMergeTagsService } from '../states/merge-tags/crt-merge-tags.service';
import { DisabilityQuery } from '../states/risk-analysis/disability/disability.query';
import { LifeQuery } from '../states/risk-analysis/life/life.query';
import { MedicalQuery } from '../states/risk-analysis/medical/medical.query';
import { TpdQuery } from '../states/risk-analysis/tpd/tpd.query';
import { StatementOfAdviceQuery } from '../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../states/statement-of-advice/statement-of-advice.store';
import { StatementOfAdviceState } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';

@Component({
	selector: 'app-statement-of-advice',
	templateUrl: './statement-of-advice.component.html',
	styleUrls: ['./statement-of-advice.component.scss'],
})
export class StatementOfAdviceComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	isIndex = true;
	parentCrt = 0;
	viewMode = 'reset';
	soaId: number;

	bsModalRef: BsModalRef;

	otherMergeTags$: BehaviorSubject<MergeTagState[]> = new BehaviorSubject<MergeTagState[]>(null);
	lrProviders$: BehaviorSubject<ProviderCommissionSettingState[]> = new BehaviorSubject<ProviderCommissionSettingState[]>(null);
	adviceProcessId: number = this.query.getValue().adviceProcessId;
	hasActiveId$: Observable<boolean> = this.soaQuery.selectActiveId().pipe(map((x) => !!x));
	active$: Observable<StatementOfAdviceState> = this.soaQuery.selectActive();
	paymentFrequency$: Observable<ViewDisplayValue[]> = this.service.APCRTF$;
	adviserId: number = +this.query.getValue().adviceProcess?.adviser;

	defaultFrequency = this.dropdownValueQuery
		.getAll({ filterBy: (x) => x.DropdownCode === 'APCRTF' })
		?.find((x) => !!x.IsDefault)?.DropdownValue;

	hasLife$: Observable<boolean> = this.query.hasLife$;
	hasDisability$: Observable<boolean> = this.query.hasDisability$;
	hasCriticalIllness$: Observable<boolean> = this.query.hasCriticalIllness$;
	hasTPD$: Observable<boolean> = this.query.hasTPD$;
	hasMedical$: Observable<boolean> = this.query.hasMedical$;
	hasHomeCarContents$: Observable<boolean> = this.query.hasHomeCarContentsInsurance$;
	isCompany$ = this.query.isCompany$;
	clientId: number;
	isLoadingSOA: boolean;

	constructor(
		private router: Router,
		private query: ClientReviewTemplateQuery,
		private mtService: CrtMergeTagsService,
		private soaStore: StatementOfAdviceStore,
		private soaQuery: StatementOfAdviceQuery,
		private service: StatementOfAdviceService,
		private dropdownValueQuery: DropdownValueQuery,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private lifeQuery: LifeQuery,
		private disabilityQuery: DisabilityQuery,
		private tpdQuery: TpdQuery,
		private medicalQuery: MedicalQuery
	) {}

	ngOnInit(): void {
		const code = this.route.snapshot.paramMap.get('companyCode');
		const customerType = this.route.snapshot.paramMap.get('customerType');
		const clientId = this.route.snapshot.paramMap.get('clientId');
		const adviceProcessId = this.route.snapshot.paramMap.get('adviceProcessId');
		const returnUrl = `${code}/crt/${customerType}/${clientId}/advice-process/${adviceProcessId}/declaration`;
		this.clientId = +clientId;

		this.query
			.select()
			.pipe(
				tap(() => (this.isLoadingSOA = true)),
				withLatestFrom(
					this.lifeQuery.select(),
					this.disabilityQuery.select(),
					this.tpdQuery.select(),
					this.medicalQuery.select()
				),
				withLatestFrom(
					this.hasCriticalIllness$,
					this.hasDisability$,
					this.hasLife$,
					this.hasMedical$,
					this.hasTPD$,
					this.hasHomeCarContents$
				),
				filter(([[x, life, disability, tpd, medical]]) => {
					const result =
						!!x?.people &&
						!!x?.others &&
						!!x?.mortgages &&
						!!x?.incomeSource &&
						!!x?.rentalDetails &&
						!!x?.otherIncomeDetails &&
						!!x?.monthlyExpense &&
						!!x?.bodyMeasures &&
						!!x?.currentInsurances &&
						!!x?.lrApPageCompleted &&
						!!x?.houseInsurance &&
						!!x?.carInsurance &&
						!!x?.contentInsurance &&
						!!x?.goals &&
						!!x?.criticalIllness &&
						!!x?.riskProfile &&
						!!life &&
						!!disability &&
						!!tpd &&
						!!medical;

					if (result) {
						this.isLoadingSOA = false;
					}

					return result;
				}),
				map((x: any) => x),
				map(
					([
						[x, life, disability, tpd, medical],
						hasCriticalIllness,
						hasDisability,
						hasLife,
						hasMedical,
						hasTPD,
						hasHomeCarContents
					]) => {
						const people = x.people?.length > 0;
						const assetsAndLiabilities =
							x?.others?.length > 0 &&
							!!x?.others[0]?.hasPersonalGuarantees &&
							!!x?.others[0]?.hasFinancialResponsibilities &&
							!!x?.others[0]?.hasCurrentWill &&
							((x.mortgages?.length &&
								!x.mortgages?.some(
									(mortgage) => mortgage.mortgageFrequency === null
								)) ||
								x.mortgages?.length === 0);
						const incomeAndbudget =
							(x?.incomeSource?.length > 0 ||
								x?.rentalDetails?.netRentalIncomeList?.length > 0 ||
								x?.otherIncomeDetails?.otherIncome?.length > 0) &&
							!!x?.monthlyExpense?.completed;
						const smokingStatus = x?.bodyMeasures?.filter(
							(s) => !!s.smokingStatus
						);
						const medicalHistory =
							x?.bodyMeasures?.length === smokingStatus?.length;
						const insurance =
							x?.currentInsurances?.some((c) => c) ||
							x?.lrApPageCompleted?.some((lr) => lr === 'FCLNO');
						const fg =
							x?.houseInsurance?.length > 0 ||
							x?.carInsurance?.length > 0 ||
							x?.contentInsurance?.length > 0 ||
							x?.lrApPageCompleted?.some((lr) => lr === 'APFG');

						const shortTermGoals = [...x?.goals?.shortTermGoals]?.filter(
							(i) =>
								!!i.dropdown &&
								((i?.dropdown === 'Other' && !!i?.value) ||
									i?.dropdown !== 'Other')
						);
						const longTermGoals = [...x?.goals?.longTermGoals]?.filter(
							(i) =>
								!!i?.dropdown &&
								((i?.dropdown === 'Other' && !!i?.value) ||
									i?.dropdown !== 'Other')
						);
						const riskAnalysisGoals =
							shortTermGoals?.length > 0 || longTermGoals?.length > 0;
						const riskAnalysisCriticalIllness =
							!!x?.criticalIllness &&
							x?.criticalIllness.length > 0 &&
							x?.criticalIllness?.every(
								(c) =>
									!!c?.periodOfCoverList &&
									c?.periodOfCoverList?.length > 0 &&
									c?.periodOfCoverList?.every((p) => !!p?.dropdown)
							);
						const riskProfile =
							x?.riskProfile?.insurancePreferences &&
							x?.riskProfile?.insurancePreferences.length > 0 &&
							x?.riskProfile?.insurancePreferences?.some(
								(i) =>
									!!i.dropdown &&
									((i?.dropdown === 'Other' && !!i?.value) ||
										i?.dropdown !== 'Other')
							) &&
							x?.riskProfile?.event?.some((rp) => {
								if (rp.isDependents) {
									return (
										!!rp?.prematureDeath &&
										!!rp?.sufferFromCriticalIllnessOrTrauma &&
										!!rp?.medicalConditionRequiringHospitalTreatment
									);
								} else {
									return (
										!!rp?.prematureDeath &&
										!!rp?.sufferFromCriticalIllnessOrTrauma &&
										!!rp?.unableToWorkDueToTemporarySickness &&
										!!rp?.medicalConditionRequiringHospitalTreatment &&
										!!rp?.unableToWorkPermanently
									);
								}
							});
						const riskAnalysisLife = life?.ids?.every(
							(id) =>
								!!life?.entities[id]?.loans &&
								life?.entities[id]?.loans?.every((l) => !!l?.repay) &&
								complement(either(isNil, isEmpty))(
									life?.entities[id]?.yearsShortfallCovered
								) &&
								+life?.entities[id]?.yearsShortfallCovered > -1
						);
						const riskAnalysisDisability = disability?.ids?.every(
							(id) =>
								(+disability?.entities[id]
									?.whatPercentageOfPreTaxIncomeLikeCovered > 0 &&
									!!disability?.entities[id]
										?.howLongWouldYouWantYourIncomeCoveredFor) ||
								(!either(
									isNil,
									isEmpty
								)(
									disability?.entities[id]
										?.whatPercentageOfPreTaxIncomeLikeCovered
								) &&
									+disability?.entities[id]
										?.whatPercentageOfPreTaxIncomeLikeCovered === 0)
						);
						const riskAnalysisTPD = tpd.ids?.every(
							(id) =>
								!!tpd?.entities[id]?.loansList &&
								tpd?.entities[id]?.loansList.every((l) => !!l?.repay) &&
								complement(either(isNil, isEmpty))(
									tpd?.entities[id]?.yearsShortfallCovered
								) &&
								+tpd?.entities[id]?.yearsShortfallCovered > -1
						);
						const riskAnalysisMedical = medical?.ids?.every(
							(id) =>
								!!medical?.entities[id]?.relyOnNewZealandPublicHealthSystem &&
								!!medical?.entities[id]
									?.newZealandPrivateMedicalPolicyBasePlan &&
								!!medical?.entities[id]
									?.medicalDrugTreatmentsApprovedforUseInNZ &&
								!!medical?.entities[id]
									?.policyProvidingAccessToSpecialistsAndTests &&
								!!medical?.entities[id]
									?.policyProvidingAccessToOpticalAndDentalTreatment &&
								!!medical?.entities[id]
									?.policyProvidingAccessToGPVisitsAndPrescriptions &&
								((medical?.entities[id]?.relyOnNewZealandPublicHealthSystem ===
									'No' &&
									!!medical?.entities[id]
										?.whatLevelOfExcessAreYouComfortableSelfInsuring) ||
									medical?.entities[id]?.relyOnNewZealandPublicHealthSystem ===
										'Yes')
						);

						const factFind =
							people &&
							assetsAndLiabilities &&
							incomeAndbudget &&
							medicalHistory &&
							insurance &&
							(fg || !hasHomeCarContents);

						const riskAnalysis =
							riskAnalysisGoals &&
							(riskAnalysisLife || !hasLife) &&
							(riskAnalysisDisability || !hasDisability) &&
							(riskAnalysisTPD || !hasTPD) &&
							(riskAnalysisCriticalIllness || !hasCriticalIllness) &&
							(riskAnalysisMedical || !hasMedical) &&
							riskProfile;

						if (!(factFind && riskAnalysis)) {
							const paths = document.location.pathname.split('/');
							if (paths[paths.length - 1] === 'statement-of-advice') {
								this.router.navigateByUrl(returnUrl);
							}
							return false;
						}
						return true;
					}
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.mtService.mergeTags$
			.pipe(
				filter((data) => !!data),
				take(1),
				concatMap(() => this.service.getLrProviders(+this.adviserId)),
				tap((data) => this.lrProviders$.next(data)),
				mergeMap(async () => {
					let results = [];
					const requests = [
						this.mtService
							.getFactFindMt(+this.adviceProcessId)
							.pipe(tap((x) => (results = [...results, ...x]))),
						this.mtService
							.getRiskAnalysisMt(+this.adviceProcessId)
							.pipe(tap((x) => (results = [...results, ...x]))),
						this.mtService
							.getSosMt(+this.adviceProcessId)
							.pipe(tap((x) => (results = [...results, ...x]))),
					];
					for (const request of requests) {
						await request.toPromise();
					}
					return results;
				}),
				tap((x) => this.otherMergeTags$.next(x))
			)
			.subscribe();
	}

	viewSOA() {
		const baseUrl = this.router.url.split('/')?.slice(1, 8);
		this.router.navigate([...baseUrl, this.soaId]);
	}

	changeView(viewMode: string, event = 0) {
		this.isIndex = !this.isIndex;
		this.parentCrt = event;
		this.viewMode = viewMode;
	}

	onChangePaymentFrequency(value: string, cRTId: number, soa: StatementOfAdviceState) {
		if (!value && !cRTId && !soa) {
			return;
		}
		this.soaStore.updateActive({ paymentFrequency: value });
		this.service
			.updateFrequency(cRTId, SOASubSectionCodes.ProposedInsurance, {
				...soa,
				paymentFrequency: value,
			})
			.pipe(take(1))
			.subscribe((x) => {
				const pi = this.soaQuery.getValue()?.proposedInsurance;
				if (pi?.length > 0) {
					const confirm$ = new Observable((obs) => {
						obs.complete();
					});

					const decline$ = new Observable((obs) => {
						obs.complete();
					});

					const initialState = {
						header: 'Payment Frequency Updated',
						message: 'Payment Frequency Updated. Check the premium amounts again.',
						isOkBtn: true,
						confirm$,
						decline$,
						designV2: true,
					};

					this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
						class: 'modal-dialog-centered modal-dialog oat-frequency-modal',
						initialState,
						ignoreBackdropClick: true,
					});
				}
			});
	}

	ngOnDestroy(): void {
		applyTransaction(() => {
			if (this.soaQuery.hasActive()) {
				this.soaStore.updateActive({
					currentPage: !!this.soaQuery.getActive()?.document?.referenceId
						? SOASubSectionCodes.Completed
						: this.soaQuery.getActive()?.currentPage,
				});
				this.soaStore.setActive(null);
			}
			this.soaStore.resetSubpages();
		});
	}
}
