import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DisabilityStore, DisabilityState } from './disability.store';

@Injectable({ providedIn: 'root' })
export class DisabilityQuery extends QueryEntity<DisabilityState> {

  constructor(protected store: DisabilityStore) {
    super(store);
  }

}
