import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { QuarterlyApiStat } from './state/qas.model';
import { BodyRow } from '../../shared/simple-table/simple-table.model';
import { map, filter, takeUntil } from 'rxjs/operators';
import { DashboardQuery } from '../../state/dashboard.query';
import { QuarterlyApiStatsQuery } from './state/qas.query';
import { QuarterlyApiStatsService } from './state/qas.service';
import { numUtil } from '../../../../../util/util';

@Component({
  selector: 'app-qas',
  templateUrl: './qas.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QasComponent implements OnInit, OnDestroy {
  public static widgetCode = 'qas';
  public static widgetName = 'Quarterly API Stats';
  public static sizeX = 350;
  public static sizeY = 180;
  public static minSizeX = 350;
  public static minSizeY = 180;

  widgetCode = QasComponent.widgetCode;

  /**
   * Display name for Statuses.
   */
  private readonly propertyName = {
    Submitted: 'Submitted API',
    New: 'Issued API',
    Lost: 'Cancelled API',
    NetNew: 'Net API'
  };

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * Quarterly API Stats Data
   */
  data$: Observable<QuarterlyApiStat> = this.query.quarterApiStatData$;

  /**
   * transform qas data to simple-table object.
   *
   * For status label, show text from propertyName
   */
  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return Object.keys(data)?.map<BodyRow>(key => {
          const label = this.propertyName[key];
          const value = numUtil.formatToCurrency(data[key]);
          return [{ text: label }, { text: value, class: 'col-4' }];
        });
      }
    })
  );

  constructor(
    private dashboardQuery: DashboardQuery,
    private query: QuarterlyApiStatsQuery,
    private service: QuarterlyApiStatsService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of quarter API stats.
   *
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetQuarterlyApiStats(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
