import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnDestroy } from '@angular/core';
import { TransferQuery } from './state/transfer.query';
import { Observable, Observer, Subject, takeUntil } from 'rxjs';
import { DeleteModalComponent } from '@shared/modal/delete-modal/delete-modal.component';
import { ProgressStatus } from '@shared/progress-popup/progress';
import { TransferService } from './state/transfer.service';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnDestroy {
  private onDestroy$ = new Subject<void>();

  reportLabel$ = this.transferQuery.reportLabel$;
	error$ = this.transferQuery.error$;
  hasError = false;
  percentage = 0;

  constructor(
    private tranferService: TransferService,
    private transferQuery: TransferQuery,
    private bsModalService: BsModalService
  ) {

    this.error$
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((status) => {
      if (!status) {
        this.hasError = false;
      }
      if (status) {
        this.hasError = true;
      }
    });

    this.transferQuery.progress$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((progress) => {
				this.percentage = progress;

			});
  }

  onCancel(event) {
    const status = this.transferQuery.getValue().status;

		if (status && status === ProgressStatus.COMPLETE) {
      this.tranferService.stopPolling();
			this.tranferService.resetExport();
			return;
		}

		const confirm = new Observable((obs: Observer<any>) => {
      this.tranferService.stopPolling();
			this.tranferService.resetExport();
			obs.complete();
		});

		this.bsModalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: {
				header: 'Cancel Transfer',
				message: `Are you sure you want to cancel this data Transfer? <br> All progress will be lost.`,
				confirmButton: 'Yes',
				confirmButton2: 'No',
				delete$: confirm,
			},
			ignoreBackdropClick: true,
			keyboard: false,
		});
  }

  ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
  
}
