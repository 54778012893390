<accordion
	[isAnimated]="true"
	class="crt-side-navigation d-lg-block"
	[ngClass]="{ 'd-none': !isToggleSidebar, 'd-block': isToggleSidebar }"
>
	<accordion-group
		[isOpen]="!isMobileMode"
		[isDisabled]="!isMobileMode"
		[class.disabled]="isLoading$ | async"
	>
		<button
			*ngIf="isMobileMode"
			class="btn btn-link btn-block clearfix btn-workflow"
			accordion-heading
			type="button"
		>
			<i
				*ngIf="isMobileMode"
				class="material-icons md-24 tap-text-color-h1 float-left pt-1 pr-3"
				>&#xE8EE;</i
			>
		</button>

		<accordion [isAnimated]="true">
			<accordion-group
				class="crt-sidebar"
				[class.disabled]="isAdviceProcessEnded$ | async"
				[isDisabled]="true"
				routerLinkActive="active-panel"
				(click)="
					toggleSidebar('intro');
					triggerNavigate(adviceProcessRoutes.Introduction)
				"
				[class.active-panel]="isActive(adviceProcessRoutes.Introduction)"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font {{
						colorCode?.intro
					}}"
					accordion-heading
					type="button"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Introduction</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.intro, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
				</button>
			</accordion-group>

			<accordion-group
				class="crt-sidebar"
				[class.disabled]="isAdviceProcessEnded$ | async"
				[isDisabled]="true"
				routerLinkActive="active-panel"
				(click)="
					toggleSidebar(adviceProcessRoutes.Disclosure);
					triggerNavigate(adviceProcessRoutes.Disclosure)
				"
				[class.active-panel]="isActive(adviceProcessRoutes.Disclosure)"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font {{
						colorCode?.disclosure
					}}"
					accordion-heading
					type="button"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Disclosure Document</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.disclosure, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
					<i
						*ngIf="colorCodeIncludes(colorCode?.disclosure, 'orange')"
						class="material-icons warning float-right md-16 pt-1"
						tooltip="Document must be saved to file"
						container="body"
						>warning</i
					>
				</button>
			</accordion-group>

			<accordion-group
				class="crt-sidebar"
				[class.disabled]="isAdviceProcessEnded$ | async"
				[isDisabled]="true"
				routerLinkActive="active-panel"
				[class.active-panel]="isActive(adviceProcessRoutes.SOS)"
				(click)="
					toggleSidebar(adviceProcessRoutes.SOS);
					triggerNavigate(adviceProcessRoutes.SOS)
				"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font {{
						colorCode?.scopeOfService
					}}"
					accordion-heading
					type="button"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Scope of Service</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.scopeOfService, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
					<i
						*ngIf="colorCodeIncludes(colorCode?.scopeOfService, 'orange')"
						class="material-icons warning float-right md-16 pt-1"
						tooltip="Document must be saved to file"
						container="body"
						>warning</i
					>
				</button>
			</accordion-group>

			<!----------------------------------- FACT FIND ----------------------------------->
			<accordion-group
				class="sub-menu crt-sidebar"
				[isDisabled]="true"
				[isOpen]="isFactFindOpen || (!!IsFactFindTab && !IsRiskAnalysisTab)"
				[panelClass]="'customChildClass'"
				routerLinkActive="active-panel"
				[routerLinkActiveOptions]="{ exact: true }"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font {{
						colorCode.factFind
					}}"
					accordion-heading
					type="button"
					(click)="openFactFind()"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Fact Find</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.factFind, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
				</button>

				<accordion id="factFindTab" [isAnimated]="true">
					<accordion-group
						[isDisabled]="true"
						routerLinkActive="active-panel"
						(click)="
							toggleSidebar(); triggerNavigate(adviceProcessRoutes.People)
						"
						[class.active-panel]="isActive(adviceProcessRouteEnd.People)"
					>
						<button
							class="btn btn-link btn-block clearfix btn-font {{
								colorCode?.people
							}}"
							accordion-heading
							type="button"
							[disabled]="isCRTLoading || (isLoading$ | async)"
						>
							<div class="pull-left float-left">People & Entities</div>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									colorCodeIncludes(colorCode?.people, "success")
										? "check_circle"
										: "panorama_fish_eye"
								}}
							</i>
							<i
								*ngIf="colorCodeIncludes(colorCode?.people, 'orange')"
								class="material-icons warning float-right md-16 pt-1"
								tooltip="At least one person is required"
								container="body"
								>warning</i
							>
						</button>
					</accordion-group>
					<accordion-group
						[isDisabled]="true"
						routerLinkActive="active-panel"
						[class.active-panel]="
							isActive(adviceProcessRouteEnd.AssetsLiabilities)
						"
						(click)="
							toggleSidebar();
							triggerNavigate(adviceProcessRoutes.AssetsLiabilities)
						"
					>
						<button
							[ngClass]="[
								'btn',
								'btn-link',
								'btn-block',
								'clearfix',
								'btn-font',
								!!colorCode.assetsLiabilities
									? colorCode.assetsLiabilities
									: 'menu-item--grey'
							]"
							accordion-heading
							type="button"
							[disabled]="isCRTLoading || (isLoading$ | async)"
						>
							<div class="pull-left float-left">Assets and Liabilities</div>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									colorCodeIncludes(colorCode?.assetsLiabilities, "success")
										? "check_circle"
										: "panorama_fish_eye"
								}}
							</i>
							<i
								*ngIf="
									colorCodeIncludes(colorCode?.assetsLiabilities, 'orange')
								"
								class="material-icons warning float-right md-16 pt-1"
								tooltip="Review required fields"
								container="body"
								placement="left"
								>warning</i
							>
						</button>
					</accordion-group>
					<accordion-group
						[isDisabled]="true"
						routerLinkActive="active-panel"
						[class.active-panel]="
							isActive(adviceProcessRouteEnd.IncomeExpenses)
						"
						(click)="
							toggleSidebar();
							triggerNavigate(adviceProcessRoutes.IncomeExpenses)
						"
					>
						<button
							class="btn btn-link btn-block clearfix btn-font {{
								colorCode?.incomeBudget
							}}"
							accordion-heading
							type="button"
							[disabled]="isCRTLoading || (isLoading$ | async)"
						>
							<div class="pull-left float-left">Income and Expenses</div>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									colorCodeIncludes(colorCode?.incomeBudget, "success")
										? "check_circle"
										: "panorama_fish_eye"
								}}
							</i>
							<i
								*ngIf="colorCodeIncludes(colorCode?.incomeBudget, 'orange')"
								class="material-icons warning float-right md-16 pt-1"
								style="white-space: pre-line"
								[tooltip]="incomeExpensesTooltip"
								container="body"
								placement="left"
								>warning</i
							>
						</button>
					</accordion-group>
					<accordion-group
						[isDisabled]="true"
						routerLinkActive="active-panel"
						[class.active-panel]="
							isActive(adviceProcessRouteEnd.MedicalHistory)
						"
						(click)="
							toggleSidebar();
							triggerNavigate(adviceProcessRoutes.MedicalHistory)
						"
					>
						<button
							class="btn btn-link btn-block clearfix btn-font {{
								colorCode?.medicalHistory
							}}"
							accordion-heading
							type="button"
							[disabled]="isCRTLoading || (isLoading$ | async)"
						>
							<div class="pull-left float-left">Medical History</div>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									colorCodeIncludes(colorCode?.medicalHistory, "success")
										? "check_circle"
										: "panorama_fish_eye"
								}}
							</i>
							<i
								*ngIf="colorCodeIncludes(colorCode?.medicalHistory, 'orange')"
								class="material-icons warning float-right md-16 pt-1"
								tooltip="Smoker status is required"
								container="body"
								>warning</i
							>
						</button>
					</accordion-group>
					<accordion-group
						[isDisabled]="true"
						routerLinkActive="active-panel"
						[class.active-panel]="
							isActive(adviceProcessRouteEnd.CurrentInsurance)
						"
						(click)="
							toggleSidebar();
							triggerNavigate(adviceProcessRoutes.CurrentInsurance)
						"
					>
						<button
							class="btn btn-link btn-block clearfix btn-font {{
								colorCode?.currentInsurance
							}}"
							accordion-heading
							type="button"
							[disabled]="isCRTLoading || (isLoading$ | async)"
						>
							<div class="pull-left float-left">Current Insurance</div>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									colorCodeIncludes(colorCode?.currentInsurance, "success")
										? "check_circle"
										: "panorama_fish_eye"
								}}
							</i>
						</button>
					</accordion-group>
					<ng-container *ngIf="hasHomeCarContentsInsurance$ | async">
						<accordion-group
							[isDisabled]="true"
							routerLinkActive="active-panel"
							[class.active-panel]="isActive(adviceProcessRouteEnd.FG)"
							(click)="toggleSidebar(); triggerNavigate(adviceProcessRoutes.FG)"
						>
							<button
								class="btn btn-link btn-block clearfix btn-font {{
									colorCode?.fg
								}}"
								accordion-heading
								type="button"
								[disabled]="isCRTLoading || (isLoading$ | async)"
							>
								<div class="pull-left float-left">F&G</div>
								<i class="material-icons icon-color float-right md-16 pt-1">
									{{
										colorCodeIncludes(colorCode?.fg, "success")
											? "check_circle"
											: "panorama_fish_eye"
									}}
								</i>
								<i
									*ngIf="colorCodeIncludes(colorCode?.fg, 'orange')"
									class="material-icons warning float-right md-16 pt-1"
									>warning</i
								>
							</button>
						</accordion-group>
					</ng-container>
				</accordion>
			</accordion-group>

			<!----------------------------------- RISK ANALYSIS ----------------------------------->
			<accordion-group
				class="sub-menu crt-sidebar"
				[isDisabled]="true"
				[isOpen]="isRiskAnalysisOpen || (!!IsRiskAnalysisTab && !IsFactFindTab)"
				[panelClass]="'customChildClass'"
				routerLinkActive="active-panel"
				[routerLinkActiveOptions]="{ exact: true }"
			>
				<button
					class="
            btn btn-link btn-block
            clearfix
            btn-font
            menu-item--header {{ colorCode?.riskAnalysis }}
          "
					accordion-heading
					type="button"
					(click)="openRiskAnalysis()"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Risk Analysis</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.riskAnalysis, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
				</button>

				<accordion id="riskAnalysisTab" [isAnimated]="true">
					<accordion-group
						[isDisabled]="true"
						(click)="
							toggleSidebar(); triggerNavigate(adviceProcessRoutes.Goals)
						"
						[class.active-panel]="isActive(adviceProcessRouteEnd.Goals)"
					>
						<button
							class="btn btn-link btn-block clearfix btn-font {{
								colorCode?.goals
							}}"
							accordion-heading
							type="button"
							[disabled]="isCRTLoading || (isLoading$ | async)"
						>
							<div class="pull-left float-left">Goals</div>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									colorCodeIncludes(colorCode?.goals, "success")
										? "check_circle"
										: "panorama_fish_eye"
								}}
							</i>
							<i
								*ngIf="colorCodeIncludes(colorCode?.goals, 'orange')"
								class="material-icons warning float-right md-16 pt-1"
								[tooltip]="goalsToolTip"
								container="body"
								>warning</i
							>
						</button>
					</accordion-group>
					<ng-container *ngIf="hasLife$ | async">
						<accordion-group
							[isDisabled]="true"
							routerLinkActive="active-panel"
							(click)="
								toggleSidebar(); triggerNavigate(adviceProcessRoutes.Life)
							"
							[class.active-panel]="isActive(adviceProcessRouteEnd.Life)"
						>
							<button
								class="btn btn-link btn-block clearfix btn-font {{
									colorCode?.life
								}}"
								accordion-heading
								type="button"
								[disabled]="isCRTLoading || (isLoading$ | async)"
							>
								<div class="pull-left float-left">Life</div>
								<i class="material-icons icon-color float-right md-16 pt-1">
									{{
										colorCodeIncludes(colorCode?.life, "success")
											? "check_circle"
											: "panorama_fish_eye"
									}}
								</i>
								<i
									*ngIf="colorCodeIncludes(colorCode?.life, 'orange')"
									class="material-icons warning float-right md-16 pt-1"
									tooltip="Review required fields"
									container="body"
									>warning</i
								>
							</button>
						</accordion-group>
					</ng-container>
					<ng-container *ngIf="hasDisability$ | async">
						<accordion-group
							[isDisabled]="true"
							routerLinkActive="active-panel"
							(click)="
								toggleSidebar(); triggerNavigate(adviceProcessRoutes.Disability)
							"
							[class.active-panel]="isActive(adviceProcessRouteEnd.Disability)"
						>
							<button
								class="btn btn-link btn-block clearfix btn-font {{
									colorCode?.disability
								}}"
								accordion-heading
								type="button"
								[disabled]="isCRTLoading || (isLoading$ | async)"
							>
								<div class="pull-left float-left">Disability</div>
								<i class="material-icons icon-color float-right md-16 pt-1">
									{{
										colorCodeIncludes(colorCode?.disability, "success")
											? "check_circle"
											: "panorama_fish_eye"
									}}
								</i>
								<i
									*ngIf="colorCodeIncludes(colorCode?.disability, 'orange')"
									class="material-icons warning float-right md-16 pt-1"
									tooltip="Review required fields"
									container="body"
									>warning</i
								>
							</button>
						</accordion-group>
					</ng-container>
					<ng-container *ngIf="hasTPD$ | async">
						<accordion-group
							[isDisabled]="true"
							routerLinkActive="active-panel"
							(click)="
								toggleSidebar(); triggerNavigate(adviceProcessRoutes.TPD)
							"
							[class.active-panel]="isActive(adviceProcessRouteEnd.TPD)"
						>
							<button
								class="btn btn-link btn-block clearfix btn-font {{
									colorCode.tpd
								}}"
								accordion-heading
								type="button"
								[disabled]="isCRTLoading || (isLoading$ | async)"
							>
								<div class="pull-left float-left">TPD</div>
								<i class="material-icons icon-color float-right md-16 pt-1">
									{{
										colorCodeIncludes(colorCode?.tpd, "success")
											? "check_circle"
											: "panorama_fish_eye"
									}}
								</i>
								<i
									*ngIf="colorCodeIncludes(colorCode?.tpd, 'orange')"
									class="material-icons warning float-right md-16 pt-1"
									tooltip="Review required fields"
									container="body"
									>warning</i
								>
							</button>
						</accordion-group>
					</ng-container>
					<ng-container *ngIf="hasCriticalIllness$ | async">
						<accordion-group
							[isDisabled]="true"
							routerLinkActive="active-panel"
							[class.active-panel]="
								isActive(adviceProcessRouteEnd.CriticalIllnness)
							"
							(click)="
								toggleSidebar('critical-illness');
								triggerNavigate(adviceProcessRoutes.CriticalIllnness)
							"
						>
							<button
								class="btn btn-link btn-block clearfix btn-font {{
									colorCode.criticalIllness
								}}"
								accordion-heading
								type="button"
								[disabled]="isCRTLoading || (isLoading$ | async)"
							>
								<div class="pull-left float-left">Critical Illness</div>
								<i class="material-icons icon-color float-right md-16 pt-1">
									{{
										colorCodeIncludes(colorCode?.criticalIllness, "success")
											? "check_circle"
											: "panorama_fish_eye"
									}}
								</i>
								<i
									*ngIf="
										colorCodeIncludes(colorCode?.criticalIllness, 'orange')
									"
									class="material-icons warning float-right md-16 pt-1"
									tooltip="Review required fields"
									container="body"
									>warning</i
								>
							</button>
						</accordion-group>
					</ng-container>
					<ng-container *ngIf="hasMedical$ | async">
						<accordion-group
							[isDisabled]="true"
							routerLinkActive="active-panel"
							(click)="
								toggleSidebar(); triggerNavigate(adviceProcessRoutes.Medical)
							"
							[class.active-panel]="isActive(adviceProcessRouteEnd.Medical)"
						>
							<button
								class="btn btn-link btn-block clearfix btn-font {{
									colorCode?.medical
								}}"
								accordion-heading
								type="button"
								[disabled]="isCRTLoading || (isLoading$ | async)"
							>
								<div class="pull-left float-left">Medical</div>
								<i class="material-icons icon-color float-right md-16 pt-1">
									{{
										colorCodeIncludes(colorCode?.medical, "success")
											? "check_circle"
											: "panorama_fish_eye"
									}}
								</i>
								<i
									*ngIf="colorCodeIncludes(colorCode?.medical, 'orange')"
									class="material-icons warning float-right md-16 pt-1"
									tooltip="Review required fields"
									container="body"
									>warning</i
								>
							</button>
						</accordion-group>
					</ng-container>
					<accordion-group
						[isDisabled]="true"
						routerLinkActive="active-panel"
						(click)="
							toggleSidebar(); triggerNavigate(adviceProcessRoutes.RiskProfile)
						"
						[class.active-panel]="isActive(adviceProcessRouteEnd.RiskProfile)"
					>
						<button
							class="btn btn-link btn-block clearfix btn-font {{
								colorCode?.riskProfile
							}}"
							accordion-heading
							type="button"
							[disabled]="isCRTLoading || (isLoading$ | async)"
						>
							<div class="pull-left float-left">Risk Profile</div>
							<i class="material-icons icon-color float-right md-16 pt-1">
								{{
									colorCodeIncludes(colorCode?.riskProfile, "success")
										? "check_circle"
										: "panorama_fish_eye"
								}}
							</i>
							<i
								*ngIf="colorCodeIncludes(colorCode?.riskProfile, 'orange')"
								class="material-icons warning float-right md-16 pt-1"
								tooltip="Review required fields"
								container="body"
								>warning</i
							>
						</button>
					</accordion-group>
				</accordion>
			</accordion-group>

			<accordion-group
				class="crt-sidebar"
				[class.disabled]="isAdviceProcessEnded$ | async"
				[isDisabled]="true"
				routerLinkActive="active-panel"
				[class.active-panel]="isActive(adviceProcessRoutes.Declaration)"
				(click)="
					toggleSidebar(); triggerNavigate(adviceProcessRoutes.Declaration)
				"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font menu-item--header {{
						colorCode?.declaration
					}}"
					accordion-heading
					type="button"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Declaration</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.declaration, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
				</button>
			</accordion-group>

			<accordion-group
				class="crt-sidebar"
				[class.disabled]="disableSoaTab() || (isAdviceProcessEnded$ | async)"
				[isDisabled]="true"
				routerLinkActive="active-panel"
				(click)="toggleSidebar('statement-of-advice')"
				[class.active-panel]="isActive(adviceProcessRoutes.SOA)"
			>
				<button
					class="btn btn-link btn-block clearfix btn-font {{
						colorCode?.statementOfAdvice
					}}"
					accordion-heading
					type="button"
					(click)="triggerNavigate(adviceProcessRoutes.SOA)"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Statement of Advice</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.statementOfAdvice, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
				</button>
			</accordion-group>

			<accordion-group
				class="crt-sidebar"
				[class.disabled]="
					disableAuthToProceedTab || (isAdviceProcessEnded$ | async)
				"
				[isDisabled]="true"
				routerLinkActive="active-panel"
				[class.active-panel]="isActive(adviceProcessRoutes.AuthorityToProceed)"
				(click)="toggleSidebar('client-acceptance')"
			>
				<button
					[class.disabled]="disableAuthToProceedTab"
					class="btn btn-link btn-block clearfix btn-font {{
						colorCode?.authorityToProceed
					}}"
					accordion-heading
					type="button"
					(click)="triggerNavigate(adviceProcessRoutes.AuthorityToProceed)"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Authority to Proceed</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.authorityToProceed, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
				</button>
			</accordion-group>

			<accordion-group
				class="crt-sidebar"
				[class.disabled]="
					!(isCompleted$ | async) || (isAdviceProcessEnded$ | async)
				"
				[isDisabled]="true"
				routerLinkActive="active-panel"
				(click)="toggleSidebar('final-structure')"
				[class.active-panel]="isActive(adviceProcessRoutes.FinalStructure)"
			>
				<button
					[class.disabled]="!(isCompleted$ | async)"
					class="btn btn-link btn-block clearfix btn-font {{
						colorCode?.finalStructure
					}}"
					accordion-heading
					type="button"
					(click)="triggerNavigate(adviceProcessRoutes.FinalStructure)"
					[disabled]="isCRTLoading || (isLoading$ | async)"
				>
					<div class="pull-left float-left">Final Structure</div>
					<i class="material-icons icon-color float-right md-16 pt-1">
						{{
							colorCodeIncludes(colorCode?.finalStructure, "success")
								? "check_circle"
								: "panorama_fish_eye"
						}}
					</i>
				</button>
			</accordion-group>
		</accordion>
	</accordion-group>
</accordion>

<div
	class="crt-side-navigation-toggle d-flex justify-content-center align-items-center d-lg-none clickable"
	(click)="toggleSidebar()"
>
	<i class="material-icons md-20 tap-text-color-h6"
		>chevron_{{ isToggleSidebar ? "left" : "right" }}</i
	>
</div>
