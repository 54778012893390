import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'cp-input-chips-readonly-item',
  templateUrl: './input-chips-readonly-item.component.html',
  styleUrls: ['./input-chips-readonly-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class InputChipsReadonlyItemComponent {
  @ViewChild('textPlaceholder', { static: true })
  textPlaceholder?: ElementRef<HTMLSpanElement>;

  // "nth more" element width
  MORE_ITEM_WIDTH_PX = 50;

  // items that are hidden
  moreItems$ = new BehaviorSubject<string[]>([]);

  @Input()
  set items$(items: Observable<any[]> | undefined) {
    if (!items) {
      return;
    }
    const windowResize$ = fromEvent(window, 'resize').pipe(
      debounceTime(200),
      startWith(null),
    );
    this.itemsSubject = combineLatest(items, windowResize$).pipe(
      map(([items, _resize]) => {
        const containerWidth =
          this.el.nativeElement.offsetWidth - this.MORE_ITEM_WIDTH_PX;
        const moreItems: string[] = [];
        let currentItemTotalWidth = 0;
        items = items.map((item, index) => {
          const itemWidth = this.getTextWidth(item + ',');
          const itemObj: any = {
            label: item,
          };
          if (index === 0 && itemWidth > containerWidth) {
            itemObj.width = containerWidth + 'px';
            itemObj.hidden = false;
            currentItemTotalWidth += containerWidth;
            return itemObj;
          }
          // if computed total width including the current item width
          // exceed the container width
          if (currentItemTotalWidth + itemWidth < containerWidth) {
            currentItemTotalWidth += itemWidth;
            itemObj.hidden = false;
          } else {
            moreItems.push(itemObj.label as string);
            itemObj.hidden = true;
          }
          return itemObj;
        });
        this.moreItems$.next(moreItems);
        setTimeout(() => this.cdr.detectChanges());
        return items;
      }),
    );
  }

  itemsSubject?: Observable<any[]>;

  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
  ) {}

  getTextWidth(text: string): number {
    if (this.textPlaceholder) {
      this.textPlaceholder.nativeElement.innerText = text;
      return this.textPlaceholder.nativeElement.offsetWidth;
    }
    return 0;
  }
}
