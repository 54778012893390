import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MonthlyApiStatsState, MonthlyApiStatsStore } from './mas.store';

/**
 * Query for Monthly API Stats widget data
 */
@Injectable()
export class MonthlyApiStatsQuery extends Query<MonthlyApiStatsState> {

  /** Monthly API Stats data. */
  monthlyApiStatData$ = this.select(state =>
    state.MAStats
  );

  /** Indicator if MonthlyApiStatus is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for MonthlyApiStatus pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: MonthlyApiStatsStore) {
    super(store);
  }
}
