import { DocumentDeclarationState } from './declaration.model';
import MomentUtil from '../../../../../util/moment.util';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { SignatureTypes } from 'src/app/shared/models/documents/document.model';
import { DeclarationState } from 'src/app/shared/models/client-review-template/declaration/declaration.model';

export class DeclarationDocumentMapper {
	public static mapToView(data): DeclarationState {
		const signatures = data?.signatures?.filter(
			(x) => !!x?.referenceID && +x?.referenceID > 0
		);
		return {
			...data,
			signatures,
			sectionCode: AdviceProcessSectionCodes.Declaration,
		};
	}

	public static mapDocumentUpload(reference, signature) {
		return {
			document: signature,
			referenceId: reference,
			fileName: 'sig' + reference + '.png',
			type: SignatureTypes.Declaration,
		} as DocumentDeclarationState;
	}

	public static mapDeclarationToUpsert(
		data,
		adviceProcessId,
		signatureList
	): DeclarationState {
		const signatures = signatureList
			?.filter((x) => !!x?.referenceID && +x?.referenceID > 0)
			?.map((x) => ({
				referenceID: x?.referenceID,
				secondaryReferenceID: x?.secondaryReferenceID,
				value: x?.value,
				dateValue: x?.dateValue,
			}));
		return {
			cRTId: data?.cRTId || null,
			adviceProcessId: adviceProcessId || data?.adviceProcessId,
			document: {
				referenceId: data?.document?.referenceId || 0,
				value: data?.document?.value || null,
			},
			signatures,
			sectionCode: AdviceProcessSectionCodes.Declaration,
			status: 1,
		};
	}
}
