import { PurposeDetailsState, PurposeTypes } from './purpose.model'
import { util } from '../../../../../../../../util/util';

export class PurposeMapper{
  public static mapToView(data: PurposeDetailsState) {
    const selectedTypes = data.types ? this.parseData(data.types) as string[] : [];
    return {
      ...data,
			newPurchase: selectedTypes?.includes(PurposeTypes.NewPurchase),
			lendingTopup: selectedTypes?.includes(PurposeTypes.LendingTopup),
			refinance: selectedTypes?.includes(PurposeTypes.Refinance),
			businessLending: selectedTypes?.includes(PurposeTypes.BusinessLending),
      types: selectedTypes
    }
  }

  public static mapToUpsert(data: PurposeDetailsState, types: string[]): PurposeDetailsState {
    return {
      ...data,
      cRTId: data.cRTId ? data.cRTId : null,
      bank: data.bank,
      currentPrimaryBank: data.currentPrimaryBank,
      types: types ?? [],
      isPreApproval: data.isPreApproval,
      isSellingProperties: data.isSellingProperties,
      reason: data.reason
    }
  }

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }
}
