import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Liability } from './liability.model';

export interface LiabilityState extends EntityState<Liability> {}

@Injectable()
@StoreConfig({ name: 'liability', idKey: 'cRTId' })
export class LiabilityStore extends EntityStore<LiabilityState, Liability> {
	constructor() {
		super();
	}
}
