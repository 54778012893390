import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, defer, iif, Observable, of, Subject, Subscription } from 'rxjs';
import {
	concatMap,
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	tap,
	withLatestFrom,
	catchError,
	takeUntil,
	distinctUntilChanged,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { BusinessConfig } from 'src/app/domain/business-config/business-config.model';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ClientProfileQuery } from 'src/app/modules/crm/client-profile/states/client-profile.query';
import { PeopleService } from 'src/app/modules/crm/client-review-template/states/people/people.service';
import { ServiceAdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { ClientProfilePrimaryMapper } from 'src/app/shared/models/client-profile/primary-client/primary-client.mapper';
import { PrimaryClientState } from 'src/app/shared/models/client-profile/primary-client/primary-client.model';
import { ClientProfileSecondaryMapper } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.mapper';
import { DependentDetailsState } from 'src/app/shared/models/client-review-template/dependent/dependent-details';
import { DependentMapper } from 'src/app/shared/models/client-review-template/dependent/dependent.mapper';
import {
	EmailLinkList,
	EmailLinksMT,
} from 'src/app/shared/models/client-review-template/merge-tags/email-settings/email-links';
import { PeopleMapper } from 'src/app/shared/models/client-review-template/people/people.mapper';
import {
	PeopleDetailsState,
	LOATPeopleDetailsState,
	MOATPeopleDetailsState,
} from 'src/app/shared/models/client-review-template/people/people-details.model';
import { CrtEmailModel } from 'src/app/shared/models/emails/crt/email.model';
import { Attachment, CombinedAttachment } from 'src/app/shared/models/_general/attachment.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { convertUtil, fileUtil, objectUtil, strUtil } from 'src/app/util/util';
import { validatorUtil } from 'src/app/util/validator.util';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { SecondaryClientState } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.model';
import {
	getContentWithMergeTags,
	normalizeHTMLSymbols,
	removeMtWrappers,
	resetMergeTags,
} from 'src/app/shared/converter/content-merge-tags';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { BLStaff } from 'src/app/domain/bl-staff/bl-staff.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadModalComponent } from '../../../upload-modal/upload-modal.component';
import { DocumentGroupState } from 'src/app/shared/models/documents/document-group.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { LinkDocumentComponent } from 'src/app/shared/services/link-document/link-document.component';
import { Fields, getInvalidWarning, logMessage } from 'src/app/shared/error-message/error-message';
import { ClientProfileStore } from '../../../../../modules/crm/client-profile/states/client-profile.store';
import { EmailTemplateModel } from '@modules/emails/email-settings/state/email-template.store';
import { EmailTemplateService } from '@modules/emails/email-settings/state/email-template.service';
import { EmailSignatureDetails, EmailSignatureService } from '@modules/emails/email-signature/state/email-signature.service';
import { clone, equals, find, isEmpty, isNil, omit, propEq, map as Rmap } from 'ramda';
import { BusinessProfileQuery } from '@modules/crm/business-profile/states/business-profile.query';
import { BusinessProfileStore } from '@modules/crm/business-profile/states/business-profile.store';
import { BusinessService } from '@core/business/business.service';
import { PrimaryCustomerCompanyState } from '@shared/models/business-profile/business/business.model';
import { CustomerService } from '@core/customer/customer.service';
import { User } from '@domain/user/user.model';

@Component({
	selector: 'app-email-form',
	templateUrl: './email-form.component.html',
	styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit, OnChanges, OnDestroy {
	@Input() saveEmailFn$: (model: any) => Observable<any>;
	@Input() saveCRMEmailFn$: (model: any) => Observable<any>;
	@Input() sendEmailFn$: (model: any) => Observable<any>;
	@Input() downloadOfferTermsFn$: (model: any) => Observable<any>;
  @Input() beforeSendFn$: (model?: any) => Observable<any>;
	@Input() updateMTOnRecepientChange$: (provider: string) => Observable<MergeTagState[]>;
	@Input() emailSettings: CrtEmailModel;
	@Input() emailDocumentTemplate: string;
	@Input() defaultFromSelf: boolean = true;
	@Input() ccAdvisers: boolean;
	@Input() isCompany: boolean = false;
	@Input() bodyContent = '<p></p>';
	@Input() shortCodes: object;
	@Input() isLoading: boolean;
	@Input() showEmailTemplate: boolean;
	@Input() disableEmailUpdate: boolean;
	@Input() peopleList: ViewDisplayValue[];
	@Input() isSingleUpload: boolean;
	@Input() attachments: Attachment[];
	@Input() combinedAttachments: Observable<CombinedAttachment[]>;
	@Input() successMessage: string;
	@Input() attachFiles: boolean;
	@Input() showAdviserOnRecipient: boolean;
	@Input() hideEmailOnSuccessMsg: boolean;
	@Input() adviceProcess: ServiceAdviceProcessState;
	@Input() businessConfig: BusinessConfig;
	@Input() defaultLinkDocumentTab: string;
	@Input() showNextBtn = false;
	@Input() isSending: boolean;
	@Input() sendToLabel = 'To';
	@Input() getCrtInfoFn: (value: any) => Observable<any>;
	@Input() initFormValue: string;
	@Input() clientDocumentsLoader: () => Observable<any>;
	@Input() onModalClosed: () => {};
	@Input() attachloaderIsLoading = false;
	@Input() aotAttachmentGenerationState$: BehaviorSubject<'idle' | 'inprogress' | 'done'>;
	@Input() adviserId: number;
	@Input() enableDefaultBcc: boolean;
	@Output() sent = new EventEmitter();
	@Output() cancel = new EventEmitter();
	@Output() next = new EventEmitter();
	/**
	 * For PEOPLE_NAME & PEOPLE_FIRST_NAME Merge tags
	 * If isCrtOnPeopleMergeTag is True = Merge tags will reference the Fact Find > People
	 * If false = Merge tags will reference the person selected from the "TO" select option
	 */
	@Input() isCrtOnPeopleMergeTag: boolean;

	@Input() primaryClient: any;

	@Input() nextLabel = 'Next';

	form: UntypedFormGroup;
	optionsWysiswyg = {
		heightMax: 400,
		heightMin: 500,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkList: EmailLinkList,
		linkAutoPrefix: '',
	};
	submitted: boolean;

	clientDocumentsLoaderIsLoading = false;

	personInfo: PeopleDetailsState;
	dependentInfo: DependentDetailsState;
	isFPP = true;
	isTapLevel$ = this.userQuery.isTapLevel$;
	userInfo$ = this.userQuery.userInfo$;
	user: User;
	fromOption: ViewDisplayValue[] = [];
	toOption: ViewDisplayValue[] = [];
	selectedClientIsPrimary = false;

	emailSignatureTemplate: string;

	private subscription = new Subscription();

	@ViewChild('contentEditor') editor: WysiwygComponent;
	@Input() mergeTags$: Observable<MergeTagState[]>;
	prevBodyContent = '';
	prevSubject = '';
	adviserDetails: BLStaff;

	cancelLoading: boolean;

	clientDocuments: DocumentGroupState;

	bsModalRef: BsModalRef;
	linkModalRef: BsModalRef;
	optionModalRef: BsModalRef;

	emailClientTemplateIsLoading = false;

	@ViewChild('uploadOptionModal', { read: TemplateRef })
	uploadOptionModal: TemplateRef<any>;
	@Output() addAttachment = new EventEmitter<any>();
	@Input() documentInfo: any;
	@Input() queueAttachments: boolean;

	// Enable Email Client Template Fields
	@Input() isECTemplate: boolean = false;
	@Input() ecTemplateList: EmailTemplateModel[] = [];
	ecTemplateOptions: ViewDisplayValue[] = [];
	isECTSLoading: boolean = false;
	// this is just a observable when file upload is successfull
	@Input() onUploadedDocument: () => Observable<any>;
	@Input() primaryCompany: PrimaryCustomerCompanyState;
	@Input() showBusinessRecipient: boolean;

	// Enable Email CAR Options
	@Input() isECAR: boolean = false;
	@Input() onCAREmailSavedFn$: (data: any) => Observable<any>;

	peopleDetailList: any[] = [];
	currentSelectedRecipient: number;
	clientTo: MergeTagState[] = [];
	secondaryPerson: any; // company selected secondary

	otherSearchList$ = new BehaviorSubject<ViewDisplayValue[]>(null);
	allUnfilteredStaffs: BLStaff[];
	userInfo: User;
	loadingSignature: boolean;
	isTL: boolean;

	senderOptions = {
		self: 'Me',
		adviser: 'Adviser',
		business: 'Business',
		other: 'Other'
	}

	constructor(
		private fb: UntypedFormBuilder,
		private peopleService: PeopleService,
		private loggerService: LoggerService,
		private ngZone: NgZone,
		private userQuery: UserQuery,
		private blStaffsQuery: BLStaffsQuery,
		private crtDocService: CrtDocumentService,
		private clientProfileQuery: ClientProfileQuery,
		private clientProfileStore: ClientProfileStore,
		private businessProfileQuery: BusinessProfileQuery,
		private businessProfileStore: BusinessProfileStore,
		private modalService: BsModalService,
		private emailTemplateService: EmailTemplateService,
		private emailSignatureService: EmailSignatureService,
		private cd: ChangeDetectorRef,
		private businessService: BusinessService,
		private customerService: CustomerService,

	) {
		this.getPeopleRecipientDetails();
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
		this.generateFromOption();

		this.userInfo$
			.pipe(
				filter((x) => !!x),
				withLatestFrom(this.isTapLevel$),
				tap(([user, isTL]) => {
					this.userInfo = user;
					this.isTL = isTL;
				}),
				take(1)
			)
			.subscribe();

		this.clientDocuments = clone(
			this.isCompany ? this.businessProfileQuery.getValue().documents : this.clientProfileQuery.getValue().documents
		);

		if (this.initFormValue) {
			this.form.patchValue(this.initFormValue as any);
		}

		if (!!this.updateMTOnRecepientChange$) {
			const valueChanges$ = this.form.get('emailDropdown').valueChanges
				.pipe(distinctUntilChanged())
				.subscribe((x) => {
					this.updateMTOnRecepientChange$(x).pipe(
						tap((data) => {
							this.parseContent(true, data);
						}),
						take(1)
					).subscribe();
				});
			this.subscription.add(valueChanges$);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.bodyContent) {
			this.prevBodyContent = changes.bodyContent.currentValue;
			this.parseContent();
		}

		if (changes.emailSettings) {
			this.prevSubject = changes.emailSettings.currentValue.subject;
			this.parseContent();
		}

		if (changes.isECTemplate) {
			if (this.isECTemplate) {
				setTimeout(() => {
					this.blindCarbonCopy.setValue('');
				}, 10);
			}
		}

		if (changes.ecTemplateList) {
			if(this.isECTemplate) {
				this.generateEmailClientTemplateOption();
			}
		}
		this.cd.detectChanges();
	}

	getPeopleRecipientDetails(): void {
		if(this.isCompany) {
			combineLatest([
				this.businessProfileQuery.primaryCompany$,
				this.businessProfileQuery.directors$,
				this.businessProfileQuery.shareholders$,
				this.businessProfileQuery.others$,
			]).pipe(
				take(1),
				tap(([primaryCompany, directors, shareholders, others]) => {
					this.peopleDetailList = [
						primaryCompany,
						...(directors || []),
						...(shareholders || []),
						...(others || []),
					]?.filter(Boolean);
				})
			).subscribe();
		} else {
			combineLatest([this.clientProfileQuery.primaryClient$, this.clientProfileQuery.secondaryClients$]).pipe(
				take(1),
				tap(([primaryClient, secondaryClients]) => {
					this.peopleDetailList = [
						primaryClient,
						...(secondaryClients || []),
					]?.filter(Boolean);
				})
			).subscribe();
		}
	}

	prepData() {
		if (!this.emailSettings) {
			return;
		}

		this.form.reset(this.emailSettings);
		this.bodyContent = this.bodyContent;

		if(this.isECTemplate) {
			this.emailClientTemplate.setValue('');
		}
	}

	getCustomTags() {
		let peopleMergeTags = [];

		if (!this.isCrtOnPeopleMergeTag) {
			let fullName = '';
			let firstName = '';
			if (!!this.personInfo) {
				fullName = `${this.personInfo?.firstName} ${this.personInfo?.lastName}`;
				firstName = this.personInfo?.firstName;
			} else if (!!this.dependentInfo) {
				fullName = `${this.dependentInfo?.firstName} ${this.dependentInfo?.lastName}`;
				firstName = this.dependentInfo?.firstName;
			}
			peopleMergeTags = [
				{
					metaKey: 'PEOPLE_NAME',
					description: 'People Name',
					value: fullName ?? '',
					type: 'T',
					secondaryValue: fullName ?? '',
				},
				{
					metaKey: 'PEOPLE_FIRST_NAME',
					description: 'People First Name',
					value: firstName ?? '',
					type: 'T',
					secondaryValue: firstName ?? '',
				},
			];
		}

		return [...peopleMergeTags];
	}

	parseContent(reparseFromBody = false, newMergeTags?: MergeTagState[]) {
		const customMTags = this.getCustomTags();
		const person = find(propEq('customerID', this.currentSelectedRecipient), this.peopleDetailList);

		this.mergeTags$
			?.pipe(
				withLatestFrom(this.userInfo$, this.isTapLevel$),
				map(([mergeTags, userInfo, isTapLevel]) => {
						this.userInfo = userInfo;
						this.isTL = isTapLevel;
						const emailMT = [];
						emailMT?.push(
							{
								metaKey: 'FIRST_NAME',
								description: 'Client First Name',
								value: person ? person.firstName : '',
								type: 'T',
								secondaryValue: 'N/A First Name'
							},
							{
								metaKey: 'CLIENT_SECOND_NAME',
								description: 'Client Client Last Name',
								value: person ? person.lastName : '',
								type: 'T',
								secondaryValue: 'N/A Client Last Name'
							},
							{
								metaKey: 'FULL_NAME',
								description: 'Client Full Name',
								value:  person ? `${person.firstName} ${person.lastName}` : '',
								type: 'T',
								secondaryValue: 'N/A Full Name'
							},
						);
						if (this.defaultFromSelf) {
							emailMT?.push({
								metaKey: 'FROM_USER',
								description: 'Email Sender Name',
								value: userInfo?.FullName || '',
								type: 'T',
								secondaryValue: '',
							})
						}

						// Update or Insert Email MT;
						// Update existing merge tag if already exists to avoid duplicate
						const updatedMT = emailMT?.reduce((acc, curr) => {
							const index = acc?.findIndex((item) => item.metaKey === curr.metaKey);
							if (index === -1) {
								return [...acc, curr];
							}
							acc[index] = curr;
							return acc;
						}, newMergeTags || mergeTags);

						return MergeTagsMapper.updateEmailMergeTags(updatedMT, [
							...customMTags,
							...EmailLinksMT,
						])
					}
				),
				mergeMap((data: MergeTagState[]) => {
					return this.getEmailSignature()
						.pipe(
							take(1),
							map((emailSignature) => [data, emailSignature])
						);
				}),
				tap(([data, emailSignature]: [MergeTagState[], string]) => {
					let unparsedContent = '';
					if (reparseFromBody) {
						unparsedContent = resetMergeTags(
							this.editor ? this.editor.content : ''
						);
					}

					const bodyTags = getContentWithMergeTags(
						reparseFromBody ? unparsedContent : this.prevBodyContent,
						data
					);
					this.bodyContent = bodyTags + (bodyTags.includes('email-signature-template') ? '' : emailSignature);

					const rewritableSubject = !isNil(this.prevSubject) && this.prevSubject.includes('%CLIENT_SECOND_NAME%');
					const hasNewMergetags = !!newMergeTags;
					// this formats the subject merge tags
					if (!reparseFromBody || hasNewMergetags || (rewritableSubject && this.isECTemplate)) {
						const subjectMergeTags =
							MergeTagsMapper.convertImageLinkToTextMT(data);
						const subjectContent = getContentWithMergeTags(
							this.prevSubject,
							subjectMergeTags
						);
						const parsedSubject = removeMtWrappers(subjectContent);
						// @ts-ignore-next
						const subjectWithoutNonBreakingSpace = parsedSubject?.replaceAll('&nbsp;', ' ');
						this.form
							.get('subject')
							.setValue(normalizeHTMLSymbols(subjectWithoutNonBreakingSpace));
					}
					this.cd.detectChanges();
				}),
				take(1)
			)
			.subscribe();
	}

	private getEmailSignature(): Observable<string> {
		const signatureData = this.getSignatureDetails();

		return this.emailSignatureService.getTemplate(signatureData).pipe(
			take(1),
			map((emailSignature) => {
				this.emailSignatureTemplate = emailSignature;
				return emailSignature;
			})
		);
	}

	buildForm() {
		this.prevSubject = this.emailSettings?.subject;

		this.form = this.fb.group(
			{
				emailClientTemplate: [this.emailSettings?.emailTemplateId ?
					`${this.emailSettings?.emailTemplateId}` : ''], // Email Client Template
				senderDropdown: [''],
				sender: ['', [validatorUtil.emailValidator, Validators.required]],
				carbonCopy: [
					this.emailSettings?.carbonCopy || '',
					validatorUtil.emailValidator,
				],
				blindCarbonCopy: [
					this.emailSettings?.blindCarbonCopy || '',
					[validatorUtil.emailValidator, validatorUtil.uniqueEmailsInSameInputValidator]
				],
				subject: [this.emailSettings?.subject || '', Validators.required],
				templateID: [null],
				emailDropdown: [null, Validators.required],
				emailRecipient: [
					null,
					[validatorUtil.requiredString(), validatorUtil.emailValidator],
				],
				otherSearch: ['']
			},
			{
				validator: validatorUtil.uniqueFieldsValidator([
					// 'sender',
					'carbonCopy',
					// 'defaultBcc',
					// 'blindCarbonCopy',
					'emailRecipient',
				],[
					{field:'senderDropdown',valueToCheckExempt:'',exemptedField:'sender'},
				]),
			}
		);
	}

	get emailClientTemplate() {
		return this.form.get('emailClientTemplate');
	}

	get EmailDropdown() {
		return this.form.get('emailDropdown');
	}

	get EmailRecipient() {
		return this.form.get('emailRecipient');
	}

	get OtherSearch() {
		return this.form.get('otherSearch');
	}

	get sender() {
		return this.form.get('sender');
	}

	get senderDropdown() {
		return this.form.get('senderDropdown');
	}

	get carbonCopy() {
		return this.form.get('carbonCopy');
	}

	get blindCarbonCopy() {
		return this.form.get('blindCarbonCopy');
	}

	get subject() {
		return this.form.get('subject');
	}

	get isBodyContentInvalid(): boolean {
		return isEmpty(this.editor?.content.replace(/<p>(&nbsp;|\s)+<\/p>/g, '').trim()) ||
			equals(this.editor?.content.replace(/<p>(&nbsp;|\s)+<\/p>/g, '').trim(), '<p></p>') ||
			!this.editor?.isValid();
	}

	onNext() {
		if (!this.aotAttachmentGenerationState$) {
			this.next.next(true);
			return;
		}
		if (this.aotAttachmentGenerationState$.getValue() === 'inprogress') {
			this.isSending = true;
		}
		this.aotAttachmentGenerationState$.pipe(
			filter((s) => s !== 'inprogress'),
			take(1),
			tap(() => this.next.next(true)),
			finalize(() => this.isSending = false)
		).subscribe();
	}

	getCombineAttachments() {
		return this.combinedAttachments?.pipe(
			switchMap((data) => {
				return combineLatest(
					data?.map((x) => {
						if (!!x?.toGeneratePdf) {
							return of(x?.content).pipe(
								take(1),
								mergeMap((content) =>
									this.crtDocService.generatePDFbase64(
										content,
										x.pdfOptions
									)
								),
								map((base64) => {
									return {
										Document: base64,
										OrderNo: x?.orderNo,
										Type: x?.type,
									}
								}),
							)
						}
						return of({
							Document: '',
							OrderNo: x?.orderNo,
							Type: x?.type,
						})
					})
				)
			}),
			take(1)
		);
	}

	onSend() {
		if (this.cancelLoading) {
			return;
		}
		const warningMessage = [];
		const errorMessage = [];
		this.submitted = true;
		if (this.isSending) {
			return;
		}
		if (
			this.carbonCopy?.errors?.duplicate ||
			this.sender?.errors?.duplicate ||
			this.blindCarbonCopy?.errors?.duplicate ||
			this.blindCarbonCopy?.errors?.nonUniqueEmails ||
			this.EmailRecipient?.errors?.duplicate
		) {
			// errorMessage.push('Duplicate Email Address');
			return this.loggerService.Log(null, 'Duplicate Email Address');
		}

		if (this.isBodyContentInvalid) {
			errorMessage.push('Body is empty');
		}

		if (
			this.carbonCopy.errors?.emailCommaSeparated ||
			this.blindCarbonCopy.errors?.emailCommaSeparated ||
			this.sender?.errors?.emailCommaSeparated ||
			this.EmailRecipient?.errors?.emailCommaSeparated
		) {
			warningMessage.push(getInvalidWarning(Fields.EmailAddress));
		}

		if (
			(this.form.invalid && !this.isECTemplate) ||
			(this.EmailRecipient?.errors?.required && this.isECTemplate) ||
			(this.EmailDropdown?.errors?.required && this.isECTemplate) ||
			this.subject?.errors?.required
		) {
			warningMessage.push(logMessage.shared.general.warning.required);
		}

		if (warningMessage.length > 0) {
			this.loggerService.Warning(null, warningMessage?.join(', '));
			return;
		}

		if (errorMessage.length > 0) {
			this.loggerService.Log(null, errorMessage?.join(', '));
			return;
		}

		const form = this.form.getRawValue();
		this.isSending = true;

    (this.beforeSendFn$ ? this.beforeSendFn$(undefined) : of(true))
			.pipe(
				switchMap(() => {
					if (!this.aotAttachmentGenerationState$) {
						return of([]);
					}
					return this.aotAttachmentGenerationState$.pipe(
						filter((s) => s !== 'inprogress'),
						take(1),
					)
				}),
				map(() => (this?.attachments || [])),
        switchMap((x) => {
					const attachments = x?.filter((i) => !i?.queue);

					if (!!!attachments?.length || !this.attachFiles) {
						return of([]);
					}

					if (this.queueAttachments) {
						const filteredAttachments = attachments.filter(item=>item.documentId!=null);
						if(filteredAttachments.length>0){
							return combineLatest(
								filteredAttachments.map((attachment) => {
									return of(attachment.documentId);
								})
							);
						}
						return of([]);
					}

					if (!!!attachments?.length || !this.attachFiles) {
						return of([]);
					}

					return combineLatest(
						attachments?.map((attachment) => {
							if (!attachment.generateContentCallback$) {
								return of({
									content: attachment.content,
									filename: attachment.fileName,
									disposition: 'attachment',
								});
							}
							// if (attachment.aheadOfTimeContentGeneration$) {
							// 	return attachment.aheadOfTimeContentGeneration$.pipe(
							// 		take(1),
							// 		delay(300),
							// 		map((content) => {
							// 			return {
							// 				content,
							// 				filename: attachment.fileName,
							// 				disposition: 'attachment'
							// 			}
							// 		})
							// 	);
							// }
							return attachment.generateContentCallback$.pipe(
								take(1),
								delay(300),
								mergeMap((content) =>
									this.crtDocService.generatePDFbase64(
										content,
										attachment.newPdfOptions
									)
								),
								map((content) => {
									return {
										content,
										filename: attachment.fileName,
										disposition: 'attachment',
									};
								})
							);
						})
					);
				}),
				switchMap((attachments) => {
					if (!!this.combinedAttachments) {
						return this.getCombineAttachments().pipe(
							map((combined) => {
								return {
									attachments,
									combined
								}
							})
						);
					}
					return of({attachments});
				}),
				map((files: { attachments: any[], combined?: any[] }, string) => {
					let payload = {
						emailFrom: form?.sender,
						emailDestination: this.processEmailDestination(
							'emailRecipient',
							'emailDropdown'
						),
						emailSubject: form.subject,
						emailCC: this.processEmailDestination('carbonCopy'),
						emailBCC:
							this.enableDefaultBcc
								? [
										...this.processEmailDestination('blindCarbonCopy'),
										...this.processEmailDestination('sender')
									]?.filter((x) => !!x.Email)
								: this.processEmailDestination('blindCarbonCopy'),
						bodyContent: this.editor?.content,
						attachments: files?.attachments || [],
						combinedAttachments: files?.combined || [],
						bodyContentString: this.editor.getContentWithoutSignature(),
					};
					if (!this.combinedAttachments) {
						return omit(['combinedAttachments'], payload);
					}
					return payload;
				}),
				delay(300),
				switchMap((payload) => {
					return this.sendEmailFn$(payload);
				}),
				finalize(() => (this.isSending = false))

      )
      .subscribe(
				(data) => {
					this.ngZone.run(() => {
						this.sent.emit(true);
						this.loggerService.Success(
							{},
							this.successMessage
								? `${this.successMessage} ${
										this.hideEmailOnSuccessMsg ? '' : data
								  }`
								: (this.isECTemplate ? `Email has been sent to ${this.EmailRecipient.value}` : `Document has been emailed to ${data}`)
						);
					});
				},
				(err) => {
					this.isSending = false;
					return of(err);
				}
			);
	}

	processEmailDestination(
		recipientFieldName: string,
		nameFieldName: string = ''
	) {
		const recipientField = this.form.get(recipientFieldName);
		const nameField = this.form.get(nameFieldName);
		let emailDestination = [];

		if (
			!nameField?.value ||
			nameField?.value
				?.toLowerCase()
				?.includes(this.senderOptions.other?.toLocaleLowerCase())
		) {
			const email = strUtil
				.removeSpace(recipientField.value)
				?.split(',')
				?.filter((e) => !!e && strUtil.isEmail(e));
			emailDestination = email?.map((e) => ({
				Email: e,
				Name: '',
				FirstName: '',
			}));
		} else {
			const email = strUtil
				.removeSpace(recipientField.value)
				?.split(',')
				?.filter((e) => !!e && strUtil.isEmail(e));

			let fullName = '';
			let firstName = '';
			if (!!this.personInfo) {
				if(this.isCompany) {
					fullName = this.personInfo?.name;
					firstName = this.personInfo?.name;
				} else {
					fullName = `${this.personInfo?.firstName} ${this.personInfo?.lastName}`;
					firstName = this.personInfo?.firstName;
				}
			} else if (!!this.dependentInfo) {
				if(this.isCompany) {
					firstName = this.secondaryPerson?.FirstName;
					fullName = `${firstName} ${this.secondaryPerson?.LastName}`
				} else {
					fullName = `${this.dependentInfo?.firstName} ${this.dependentInfo?.lastName}`;
					firstName = this.dependentInfo?.firstName;
				}
			}

			emailDestination = email?.map((e) => ({
				Email: e,
				Name: fullName ?? '',
				FirstName: firstName ?? '',
			}));
		}

		if(recipientFieldName === 'carbonCopy' && this.ccAdvisers && this.adviserDetails) {
			if (!emailDestination.some(e => e.Email === this.adviserDetails.EmailAddress)) {
				emailDestination.push({
					Email: this.adviserDetails.EmailAddress,
					Name: this.adviserDetails.FullName ?? '',
					FirstName: this.adviserDetails.FirstName ?? '',
				});
			}
		}
		return emailDestination;
	}

	onCancel() {
		this.cancelLoading = true;
		this.cancel.emit(true);
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	getCrtInfo(event: Event) {
		const select = (event.target as HTMLSelectElement);
		const val = select.value;
		this.EmailRecipient.setValue('');
		this.OtherSearch.setValue('');
		const name = select.options[select.selectedIndex].text;

		this.currentSelectedRecipient = +val;

		if (this.getCrtInfoFn) {
			this.loading(true);
			this.getCrtInfoFn(val)
				.pipe(
					take(1),
					finalize(() => this.loading(false)),
					tap((result) => {
						Object.keys(result).forEach((key) => {
							if (key === 'personInfo') {
								return;
							}
							this.form.controls[key]?.patchValue(result[key]);
						});
						this.setPersonInfo(result?.personInfo);
						this.parseContent(true);
					})
				).subscribe()
			return;
		}

		if (
			!!val &&
			![
				this.senderOptions.other,
				this.senderOptions.adviser,
				this.senderOptions.business,
			].includes(val)
		) {
			this.loading(true);

			of(+val)
				.pipe(
					concatMap((id) => {
						// if (this.adviceProcess.isStarted && this.adviceProcess.isOnline) {
						if (this.adviceProcess && this.adviceProcess.isOnline && !this.isECAR) {
							return this.peopleService.getPersonInfo(id);
						} else {
							if(this.isCompany) {
								const primaryData = this.businessProfileQuery.getValue().primaryCompany.primaryContact;
								this.selectedClientIsPrimary = primaryData.customerId === id;
								if (this.selectedClientIsPrimary) {
									return this.businessProfileQuery.primaryCompany$.pipe(
										map((x) => ({
											...x.primaryContact,
											isPCI: true,
											email: x.email,
										})),
									);
								} else {
									return this.businessService.GetPrimaryCompany(id).pipe(
										map((x) => {
											this.secondaryPerson = x;
											return {
												contactMethod: "Email",
												customerId: id,
												customerType: x?.CustomerType,
												email: x.Email,
												isAccessible: true,
												isActive: !!x.IsActive,
												isCompany: false,
												isPCI: false,
												name,
												primaryCustomerId: x?.PrimaryCustomer
											}}),
									);
								}
							} else {
								const primaryData =
								this.primaryClient ?? this.clientProfileQuery.getValue().primaryClient;
								this.selectedClientIsPrimary = primaryData.customerID === id;
								if (this.selectedClientIsPrimary) {
									// TO-DO: Handle PCI
									return this.clientProfileQuery.primaryClient$.pipe(
										map((x) => ({
											...x,
											isPCI: true,
											email: x.email,
										})),
									);
								} else {
									// TO-DO: Handle SCI
									return this.clientProfileQuery.secondaryClients$.pipe(
										map((sciList) => {
											const sciDetails = sciList.find(
												(sci) => +sci.customerID === id
											);
											if (sciDetails) {
												return {
													...sciDetails,
													isPCI: false,
													email: sciDetails.email,
												};
											} else {
												return {
													email: null,
												};
											}
										}),
									);
								}
							}

						}
					}),
					tap((res) => this.setPersonInfo(res)),
					finalize(() => {
						this.loading(false);
						this.parseContent(true);
					}),
					take(1)
				)
				.subscribe();
		} else {
			this.loading(false);
			if (val === this.senderOptions.other) {
				this.personInfo = null;
				this.dependentInfo = null;
				this.parseContent(true);
			} else if (val === this.senderOptions.adviser) {
				this.setPersonInfo(
					{
						firstName: this.adviserDetails?.FirstName,
						lastName: this.adviserDetails?.LastName,
						email: this.adviserDetails?.EmailAddress,
					},
					true
				);
				this.parseContent(true);
			} else if (val === this.senderOptions.business) {
				this.EmailRecipient.setValue(this.primaryCompany?.email || '');
			}
		}
	}

	setSender(event: Event) {
		this.sender.setValue((event?.target as HTMLSelectElement)?.value);
		this.sender.updateValueAndValidity();
		this.updateSignature();
	}

	setPersonInfo(data, isAdviser = false) {
		this.EmailRecipient.setValue(data?.email);
		this.personInfo = null;
		this.dependentInfo = null;

		if (isAdviser) {
			this.personInfo = data;
		} else {
			// if (this.adviceProcess.isOnline && this.adviceProcess.isStarted) {
			if (this.adviceProcess && this.adviceProcess.isOnline) {
				if (data?.sectionCode === 'FPP') {
					this.isFPP = true;
					this.personInfo = data;
				} else {
					this.isFPP = false;
					this.dependentInfo = data;
				}
			} else {
				if (data.isPCI) {
					this.personInfo = data;
				} else {
					this.dependentInfo = data;
				}
			}
		}
	}

	saveCRTEmail() {
		if (this.isECAR) {
			this.loading(true);
			this.customerService.UpdateCustomerDetail(this.EmailDropdown.value, {
				MetaKey: 'Email',
				MetaValue: this.EmailRecipient.value
			}).pipe(
				take(1),
				finalize(() => this.loading(false)),
				catchError((err) => {
					this.loading(false);
					return of(err);
				}),
				mergeMap(() => {
					this.peopleList = this.peopleList.map((p) => {
						if (p.value == this.EmailDropdown.value && p.data) {
							p.data.email = this.EmailRecipient.value;
						}
						return p;
					})
					return this.onCAREmailSavedFn$?.({
						customerId: this.EmailDropdown.value,
						email: this.EmailRecipient.value
					});
				})
			).subscribe();
		} else {
			const getPeopleData = (
				data: LOATPeopleDetailsState | MOATPeopleDetailsState
			) => PeopleMapper.mapToUpsert(data, this.personInfo);
			const getDependentData = (data: DependentDetailsState) =>
				DependentMapper.mapToUpsert(data, this.dependentInfo);
			const getPrimaryData = (data: PrimaryClientState) =>
				ClientProfilePrimaryMapper.mapToUpsert(
					ClientProfilePrimaryMapper.mapToView(data)
				);

			const getSecondaryData = (data: SecondaryClientState) =>
				ClientProfileSecondaryMapper.mapToUpsert(
					ClientProfileSecondaryMapper.mapToView(data)
				);

			of(this.isFPP)
				.pipe(
					map((isFPP) => {
						// if (this.adviceProcess.isOnline && this.adviceProcess.isStarted) {
						if (this.adviceProcess && this.adviceProcess.isOnline) {
							if (isFPP) {
								return getPeopleData({
									...this.personInfo,
									email: this.EmailRecipient.value,
								});
							} else {
								return getDependentData({
									...this.dependentInfo,
									email: this.EmailRecipient.value,
								});
							}
						} else {
							if (this.selectedClientIsPrimary) {
								if(this.isCompany) {
									return getPrimaryData({
										...this.businessProfileQuery.getValue()?.primaryCompany?.primaryContact,
										email: this.EmailRecipient.value,
									}) as PrimaryClientState;
								}
								return getPrimaryData({
									...this.clientProfileQuery.getValue().primaryClient,
									email: this.EmailRecipient.value,
								}) as PrimaryClientState;
							} else {
								if(this.isCompany) {
									return getSecondaryData({
										email: this.EmailRecipient.value,
										customerID: this.secondaryPerson?.id,
										firstName: this.secondaryPerson?.FirstName,
										middleName: this.secondaryPerson?.MiddleName,
										lastName: this.secondaryPerson?.LastName,
										gender: this.secondaryPerson?.Gender,
										title: this.secondaryPerson?.Title,
										employment: this.secondaryPerson?.Employment,
										relationship: null,
										mobile: this.secondaryPerson?.Mobile,
										dateOfBirth: this.secondaryPerson?.DateOfBirth,
										occupation: this.secondaryPerson?.Occupation,
										income: this.secondaryPerson?.Income,
										work: this.secondaryPerson?.Work,
										knownAs: this.secondaryPerson?.KnownAs,
										physicalAddress: this.secondaryPerson?.PhysicalAddress,
										contactStatus: this.secondaryPerson?.ContactStatus,
										importID: this.secondaryPerson?.ImportID,
										createdByStaffID: this.secondaryPerson?.CreatedByStaffId,
										isActive: 0,
										createdByStaffLevel: 0,
										createDateTime: this.secondaryPerson?.CreateDateTime,
										modifiedDateTime: this.secondaryPerson?.ModifiedDateTime,
										primaryCustomer: this.secondaryPerson?.PrimaryCustomer,
										photoURL: this.secondaryPerson?.PhotoURL,
										fileName: null,
										professionalContacts: this.secondaryPerson?.ProfessionalContacts,
										customerType: this.secondaryPerson?.CustomerType
									});
								}
								return getSecondaryData({
									...this.clientProfileQuery
										.getValue()
										.secondaryClients.find(
											(client) => client.customerID === +this.EmailDropdown.value
										),
									email: this.EmailRecipient.value,
								});
							}
						}
					}),
					filter((x) => strUtil.isEmail(x?.email)),
					tap(() => this.loading(true)),
					mergeMap((data) => {
						return this.saveEmailFn$(data).pipe(
							finalize(() => this.loading(false))
						);
					}),
					finalize(() => this.loading(false)),
					take(1),
					catchError((err) => {
						return of(err);
					})
				)
				.subscribe();
		}
	}

	loading(status: boolean) {
		this.isLoading = status;
		if (status) {
			this.EmailDropdown.disable();
			this.EmailRecipient.disable();
		} else {
			this.EmailDropdown.enable();
			this.EmailRecipient.enable();
		}
	}

	generateEmailClientTemplateOption(): void {
		if(!isEmpty(this.ecTemplateList)) {
			this.ecTemplateOptions = <any>Rmap(ecTemplate => ({
				display: ecTemplate.templateName,
				value: ecTemplate.emailTemplateId
			}), this.ecTemplateList);
		}
	}

	generateFromOption() {
		this.blStaffsQuery.allUnfilteredStaffs$
			.pipe(
				take(1),
				withLatestFrom(this.userInfo$),
				tap(([staffs, userInfo]) => {
					this.allUnfilteredStaffs = staffs;
					const list = staffs
						?.filter((x) => x?.IsActive === 1)
						?.map((x) =>
							ViewDisplayValue.Map(
								x.StaffID?.toString(),
								`${x.FirstName} ${x.LastName}`
							)
						);
					this.otherSearchList$.next(list);
					const adviser  = this.adviserId || this.adviceProcess?.adviser;
					const staff = staffs?.find(
						(x) => x.StaffID === adviser
					);
					if(this.defaultFromSelf) {
						this.fromOption.push({
							display: this.senderOptions.self,
							value: userInfo?.EmailAddress,
						});
					}
					if (staff) {
						this.fromOption.push({
							display: this.senderOptions.adviser,
							value: staff.EmailAddress,
						});
						this.senderDropdown.setValue(staff.EmailAddress);
						this.sender.setValue(staff.EmailAddress);
						this.adviserDetails = staff;
					}
					if(this.defaultFromSelf) {
						this.senderDropdown.setValue(userInfo?.EmailAddress);
						this.sender.setValue(userInfo?.EmailAddress);
					}
					if (this.businessConfig?.BusinessEmailAddress) {
						this.fromOption.push({
							display: this.senderOptions.business,
							value: this.businessConfig.BusinessEmailAddress,
						});
					}
					if (this.showBusinessRecipient && this.primaryCompany) {
						this.toOption.push({
							display: this.senderOptions.business,
							value: this.senderOptions.business,
						});
					}
					this.fromOption.push({ display: this.senderOptions.other, value: '' });
				})
			)
			.subscribe();
	}

	getTotalAttachmentsSize() {
		return this.attachments.reduce((a, b) => a + (+b.fileSize || 0), 0);
	}

	uploadDocuments() {
		const stop$ = new Subject<void>();

		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

		// convert Image File -> Base64 -> HTML img -> PDF
		const convertImageToBase64 = (file: File): Observable<string> => {
			return new Observable((obs) => {
				obs.next(file);
				obs.complete();
			}).pipe(
				// convert file to base64
				mergeMap(() => convertUtil.simpleConvertToBase64(file)),
				// create a html img string and add the converted base64 image in src
				map((base64) => `<img src="${base64}" />`),
				// convert html img with base64 src to pdf
				mergeMap((img) => {
					return this.crtDocService.generatePDFbase64(img, {
						FileName: convertFilename(file),
					});
				})
			);
		};

		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() =>
							// check if the file is image
							req[0].type.startsWith('image/'),
						// If the file is image. We need to convert it to pdf
						convertImageToBase64(req[0]),
						convertUtil.simpleConvertToBase64(x[0]).pipe(take(1))
					)
				),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileUploadSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				map((file) => {
					const fileStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					return {
						Document: fileStr,
						OriginalDocument: file,
						FileName: convertFilename(req[0]),
						DocumentType: this.documentInfo.documentType,
						Type: this.documentInfo.type,
						ReferenceId: this.documentInfo.referenceId,
						CustomerId: this.documentInfo.customerId,
						Size: +fileUtil.getFileSizeKb(fileStr),
					};
				}),
				mergeMap((file) =>
					this.crtDocService.uploadDocument(file).pipe(
						tap((x) => {
							const newFile = objectUtil.mapPascalCaseToCamelCase(file);
							this.attachments.push({
								documentId: +x,
								fileName: newFile.fileName,
								generateContentCallback$: undefined,
								content: newFile.document,
								pdfOptions: {
									orientation: '',
									format: '',
								},
								fileSize: newFile.size,
								type: 'uploaded',
							});
						}),
						mergeMap((documentId: number) =>
							this.pushUploadedDocumentToClientDocs(documentId)
						)
					)
				),
				takeUntil(stop$),
				catchError((err) => {
					this.loggerService.Warning(
						{},
						logMessage.shared.fileUploadSize.single.error
					);
					return err;
				})
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Save Document',
			restrict: '.pdf,image/jpeg,image/jpg,image/png',
			maxFileSize: 10000, // kb
			maxFileSizeText: '10MB',
			currentFileSize: +this.getTotalAttachmentsSize(),
		};
		this.bsModalRef = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	openUploadOptionModal() {
		this.optionModalRef = this.modalService.show(this.uploadOptionModal, {
			class: 'modal-dialog-centered',
			ignoreBackdropClick: true,
		});;
	}

	onLinkDocumentsClicked(): void {
		if (this.clientDocumentsLoader) {
			this.clientDocumentsLoaderIsLoading = true;
			this.clientDocumentsLoader()
				.pipe(
					take(1),
					tap((result) => this.clientDocuments = result),
					finalize(() => this.clientDocumentsLoaderIsLoading = false)
				).subscribe(() => {
					this.linkDocument();
					this.optionModalRef.hide();
				});
		} else {
			this.linkDocument();
			this.optionModalRef.hide();
		}
	}

	linkDocument() {
		const stop$ = new Subject<void>();
		const initialSelectedTab =
			this.defaultLinkDocumentTab?.toLowerCase() ??
			ServicesCodes.AdviceProcess?.toLowerCase();

		const initState = {
			initialSelectedTab,
			selectedDetail: 'Link Document',
			document: this.clientDocuments,
			maxFileSize: 10000,
			currentFileSize: +this.getTotalAttachmentsSize(),
		};
		this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		let fileName = '';
		let documentId = '';
		let documentLink = '';
		this.linkModalRef.content.getSelectedDocumentValue$
			.pipe(
				filter((x) => !!x),
				mergeMap((x: any) => {
					documentId = x.id;
					fileName = x.fileName;
					documentLink = x.documentLink;
					return this.crtDocService.getDocumentLink(x.documentLink, {
						responseType: 'blob',
					});
				}),
				mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileLinkSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				tap((x) => {
					const fileStr = `${((x as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					this.attachments.push({
						documentId: +documentId,
						fileName,
						generateContentCallback$: undefined,
						content: fileStr,
						pdfOptions: {
							orientation: '',
							format: '',
						},
						fileSize: +fileUtil.getFileSizeKb(fileStr),
						type: 'linked',
						disableDownload: false,
						fileUrl: documentLink,
						allowDeleting: true,
						forceShowDeleteBtn: true,
					});
				}),
				takeUntil(stop$),
				catchError((err) => {
					return err;
				})
			)
			.subscribe();
	}

	private pushUploadedDocumentToClientDocs(
		documentId: number
	): Observable<number> {
		return this.crtDocService.getDocument(documentId).pipe(
			map((doc) => objectUtil.mapPascalCaseToCamelCase(doc)),
			map((doc) => {
				const clientDocs = Object.assign({}, this.clientDocuments);
				const documentType = this.documentInfo.documentType;
				// lower case first letter of the document type just like the properties in clientDocuments
				const documentTypeFormatted =
					(documentType &&
						documentType[0].toLowerCase() + documentType.slice(1)) ||
					documentType;
				// get the reference of the client document by type array
				const clientDocumentsByType = clientDocs[documentTypeFormatted];
				if (clientDocumentsByType) {
					const appendNewDocs = [doc, ...clientDocumentsByType];
					if(this.isCompany) {
						this.businessProfileStore.setDocuments({
							...clientDocs,
							[documentTypeFormatted]: appendNewDocs,
						});
					} else {
						this.clientProfileStore.setDocuments({
							...clientDocs,
							[documentTypeFormatted]: appendNewDocs,
						});
					}
				}
					this.onUploadedDocument?.()
							.pipe(take(1))
							.subscribe();
				return documentId;
			})
		);
	}

	setECTemplateContents(event: Event): void {
		const ecTemplatedSettingsID = (event?.target as HTMLSelectElement)?.value;
		const formValue = this.form.getRawValue();

		if(!isEmpty(ecTemplatedSettingsID)) {
			this.isECTSLoading = true;

			const selectedECTS = this.ecTemplateList.find(ecTemplateSetting => ecTemplateSetting.emailTemplateId === +ecTemplatedSettingsID);
			this.getEmailBodyContent(selectedECTS).pipe(
				mergeMap((emailBodyContent) => {
					return this.getEmailSignature()
						.pipe(
							take(1),
							map((emailSignature) => [emailBodyContent, emailSignature])
						);
				}),
				tap(([emailBodyContent, emailSignature]) => {
					this.bodyContent = `${emailBodyContent}${emailSignature}`;
					this.prevBodyContent = this.bodyContent;

					const blindCarbonCopyArray = selectedECTS.blindCarbonCopy;
					this.emailSettings.emailTemplateId = selectedECTS.emailTemplateId;
					this.emailSettings.blindCarbonCopy = blindCarbonCopyArray;
					this.emailSettings.carbonCopy = selectedECTS.carbonCopy;
					this.emailSettings.body = selectedECTS.body;
					this.emailSettings.mergeTag = selectedECTS.mergeTag;
					this.emailSettings.subject = selectedECTS.subject;
					this.emailSettings.type = selectedECTS.type;
					if (selectedECTS.templateName) {
						this.emailSettings.emailTemplate = selectedECTS.templateName;
					}
					this.buildForm();
					this.form.patchValue({
						senderDropdown: formValue?.senderDropdown,
						sender: formValue?.sender,
						otherSearch: formValue?.otherSearch
					})
				}),
				finalize(() => {
					this.isECTSLoading = false
					this.currentSelectedRecipient = 0;
					this.parseContent();
					this.cd.detectChanges();
				})
			).subscribe();
		} else {
			this.bodyContent = `<br>${this.emailSignatureTemplate}`;
			this.emailSettings.emailTemplateId = 0;
			this.emailSettings.blindCarbonCopy = '';
			this.emailSettings.carbonCopy = '';
			this.emailSettings.body = '';
			this.emailSettings.mergeTag = [];
			this.emailSettings.subject = '';
			this.emailSettings.type = '';
			this.buildForm();
			this.form.patchValue({
				senderDropdown: formValue?.senderDropdown,
				sender: formValue?.sender,
				otherSearch: formValue?.otherSearch
			});
			this.isECTSLoading = false;
			this.parseContent(true);
			this.cd.detectChanges();
		}
	}

	getEmailBodyContent(data: EmailTemplateModel): Observable<EmailTemplateModel> {
		return defer(() => {
			const isPredefinedTemplate = data.body == '0';
			const getBody$ = isPredefinedTemplate
				? this.emailTemplateService.getContentByDocumentLink(data.documentLink)
				: this.emailTemplateService.getBody(data.body);
			return getBody$;
		});
	}

	selectFromOtherSearch(event) {
		const id = event?.[0];
		const data = this.allUnfilteredStaffs?.find((x) => +x?.StaffID === +id);
		this.sender.setValue(data ? data?.EmailAddress : '');
		this.updateSignature();
	}

	updateSignature() {
		this.loadingSignature = true;
		this.getEmailSignature()
			.pipe(
				take(1),
				map((emailSignature) => {
					const newBody = this.stripSignature(this.editor?.content || '');
					this.bodyContent = `${newBody}${emailSignature}`;
					this.loadingSignature = false;
					this.cd.detectChanges();
				})
			)
			.subscribe();
	}

	stripSignature(content = '') {
		const id = 'stripSignature';
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${id}">${content}</div>`);

		newHtml
			.querySelectorAll(`#email-signature-template`)
			.forEach((e: HTMLElement) => {
				e.remove();
			});

		return newHtml.querySelector(`#${id}`).innerHTML || '';
	}

	getSignatureDetails(): EmailSignatureDetails {
		let data: EmailSignatureDetails;
		const senderDropdown = this.senderDropdown.value;
		const fromType = this.fromOption?.find(
			(x) => x?.value === senderDropdown
		)?.display;

		if (!this.isTL && (fromType === this.senderOptions.self || !fromType)) {
			if (this.userInfo) {
				data = {
					FirstName: this.userInfo?.FirstName,
					LastName: this.userInfo?.LastName,
					BusinessPhone: this.userInfo?.BusinessPhone,
					MobilePhone: this.userInfo?.MobilePhone,
				};
			}
		} else if (fromType === this.senderOptions.adviser) {
			const adviser = this.adviserId || this.adviceProcess?.adviser;
			const adviserData = this.allUnfilteredStaffs?.find(
				(x) => +x.StaffID === +adviser
			);
			data = {
				FirstName: adviserData?.FirstName,
				LastName: adviserData?.LastName,
				BusinessPhone: adviserData?.BusinessPhone,
				MobilePhone: adviserData?.MobilePhone,
			};
		} else if (fromType === this.senderOptions.business) {
			data = {
				FirstName: '',
				LastName: '',
				BusinessPhone: this.businessConfig?.BusinessPhoneNumber,
				MobilePhone: this.businessConfig?.PhoneNumber,
			};
		} else if (fromType === this.senderOptions.other) {
			const id = this.OtherSearch?.value?.[0];
			const otherData = this.allUnfilteredStaffs?.find(
				(x) => +x?.StaffID === +id
			);
			data = {
				FirstName: otherData?.FirstName,
				LastName: otherData?.LastName,
				BusinessPhone: otherData?.BusinessPhone,
				MobilePhone: otherData?.MobilePhone,
			};
		} else {
			// Keep it null to apply default/existing behavior
			data = null;
		}

		return data;
	}
}
