<ng-container [formGroup]="othersForm">
    <div class="row tap-container">
      <div class="col pt-1">
        <h6>Other Type</h6>
      </div>
    </div>
  
    <div class="row tap-container">
      <div class="col-md-12 pr-md-0 my-2">
        <div class="form-group form-inline row mb-1">
          <div class="col-2">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="otNotes" value="NDI" formControlName="othersType">
              <label class="custom-control-label pt-1" for="otNotes">Notes</label>
            </div>
          </div>
          <div class="col-2">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="otActivities" value="ACTDI" formControlName="othersType">
              <label class="custom-control-label pt-1" for="otActivities">Activities</label>
            </div>
          </div>
          <div class="col-2">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="otDocuments" value="DDI" formControlName="othersType">
              <label class="custom-control-label pt-1" for="otDocuments">Documents</label>
            </div>
          </div>
          <div class="col-2">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="otAdviserAM" value="ADI" formControlName="othersType">
              <label class="custom-control-label pt-1" for="otAdviserAM">Adviser-AM</label>
            </div>
          </div>
          <div class="col-2">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="otKiwisaverMemmberNumber" value="KSOADI" formControlName="othersType">
              <label class="custom-control-label pt-1" for="otKiwisaverMemmberNumber">KiwiSaver Member Number</label>
            </div>
          </div>
        </div>
      </div>
    </div>
</ng-container>