import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private domSanitizer: DomSanitizer) {}

	transform(html: string, convertTags?: string[]): string {
		if (html.length > 0) {
			// If html was detected containing tags on convertTags
			// html will not be escaped and must be converted on innerHTML
			const tags = convertTags
				?.map((x) => {
					const regEx = new RegExp(`<${x}[^>]*>`, 'g');
					const hasMatch = html?.match(regEx);
					return hasMatch?.length > 0;
				})
				?.filter(Boolean);
			const sanitizedContent = tags?.length > 0 ? html : this.escapeHtml(html);

			return this.domSanitizer.bypassSecurityTrustHtml(
				sanitizedContent
			) as string;
		}

		return html;
	}

	private escapeHtml(str: string): string {
		return str
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;');
	}
}
