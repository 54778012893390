import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AdviceSummaryPerson } from './advice-summary-report-builder.service';

export interface AdviceSummaryReportBuilderPeopleState
	extends EntityState<AdviceSummaryPerson, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'CrtKiwisaver:Advice Summary Report Builder People',
	idKey: 'cRTId',
})
export class AdviceSummaryReportBuilderPeopleStore extends EntityStore<AdviceSummaryReportBuilderPeopleState> {
	constructor() {
		super();
	}
}
