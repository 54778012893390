<div class="container-fluid pd-tracking-scheduled">
	<div
		class="row m-0 p-0 pd-tracking-scheduled__header justify-content-between"
	>
		<div class="col-auto d-flex align-items-center p-0">
			<span class="title">Scheduled:</span>
		</div>
		<div class="col-auto pr-1" *ngIf="CanAdd">
			<button
				type="button"
				class="btn ui-btn-gray add-btn"
				id="addUpcoming"
				(click)="addUpcoming()"
			>
				+ Add Upcoming
			</button>
		</div>
	</div>
	<div class="row p-0 pd-tracking-scheduled__table mt-3 mb-3">
		<div class="col-12 p-0">
			<app-simple-data-table
				#simpleDataTable
				[dataSource]="dataSource"
				[displayedColumns]="displayedColumns"
				[tableColumns]="tableColumns"
				[enablePagination]="false"
			></app-simple-data-table>
		</div>
	</div>
</div>
