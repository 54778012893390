import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { Observable } from 'rxjs';
import {
  BlStaffSettingTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { objectUtil, util } from '@util/util';
import * as R from 'ramda';
import { delay, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ManagePdTrackingService {
	constructor(private api: ApiService) {}

  getSecurityGroups() {
    const code = `ABLUG`;
		return this.api.get<string>(`announcements/settings/${code}`).pipe(
			map((x) => util.tryParseJson(x)),
			map((x) => R.keysIn(x).map((d) => ({ value: x[d], display: d }))),
			map((x) => x?.map((d) => ViewDisplayValue.Map(d.value, d.display))),
		);
  }

	getPDTM(): Observable<any> {
    const url = `staff/${0}/staff-settings/${BlStaffSettingTypes.PersonalDevelopmentManagement}`;
		return this.api.get<BlStaffSettingsModel>(url);
	}

  findPDTM(id: number): Observable<any> {
    const url = `staff/${0}/staff-settings/${id}`;
		return this.api.get<BlStaffSettingsModel>(url);
	}

  addPDTM(data: BlStaffSettingsModel) {
    const url = `staff/${0}/staff-settings/${BlStaffSettingTypes.PersonalDevelopmentManagement}`;
		return this.api.post<BlStaffSettingsModel>(url, data);
  }

  updatePDTM(settingsId: number, data: BlStaffSettingsModel) {
    const url = `staff/${0}/staff-settings/${settingsId}/${BlStaffSettingTypes.PersonalDevelopmentManagement}`;
		return this.api.put<BlStaffSettingsModel>(url, data);
  }
}
