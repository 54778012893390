import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	OnDestroy,
	Renderer2,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ViewDisplayValue } from '@models/_general/display-value.viewmodel';
import { LoggerService } from '@core/logger/logger.service';
import { Observable, Subject, throwError } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ServicingCalculatorState } from '@modules/tl-moat-settings/servicing-calculators/state/servicing-calculators-settings.model';
import { CalcTemplateType } from '@modules/tl-moat-settings/state/tl-moat-settings.model';

@Component({
	selector: 'app-service-calculator-form',
	templateUrl: './service-calculator-form.html',
	styleUrls: ['./service-calculator-form.scss'],
})
export class ServiceCalculatorFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() adviceProcessId: number;
	@Input() clientFileName: string;
	@Input() downloadEvent$: (req) => Observable<any>;
	@Output() closeModalEvent = new EventEmitter<any>();
	@Input() bankList: ViewDisplayValue[];
	@Input() bankDetails: ServicingCalculatorState[];
	public bsModalRef: BsModalRef;

	isLoading = false;

	form: UntypedFormGroup;
	submitted = false;

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService,
		private renderer: Renderer2
	) {
		this.buildForm();
	}

	ngOnInit(): void {}

	buildForm() {
		this.form = this.fb.group({
			banks: ['', [Validators.required]],
		});
	}

	download() {
		this.submitted = true;
		this.isLoading = true;
		if (!this.form.valid) {
			this.loggerService.Warning({}, 'Please see required fields');
			this.isLoading = false;
			return;
		}
		const value = this.form.value;
		const { banks } = value;
		if (!banks.length) {
			return;
		}
		const fileName = this.getFileNames(banks);
		const fileExt =
			banks.length === 1
				? this.getFileExtension(+banks[0])
					? this.getFileExtension(+banks[0])
					: 'xlsm'
				: 'zip';
		this.downloadEvent$({ banks, adviceProcessId: this.adviceProcessId })
			.pipe(
				finalize(() => {
					this.isLoading = false;
					this.closeModalEvent.emit();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				(file) => {
					const name = `${this.clientFileName}_${fileName?.join(
						'_'
					)}.${fileExt}`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(file);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
					this.isLoading = false;
				},
				(err) => {
					this.isLoading = false;
					return throwError(err);
				}
			);
	}

	closeModal() {
		this.closeModalEvent.emit();
	}

	getFileNames(ids: number[]) {
		return (
			ids?.map((i) => {
				const bankDetails = this.bankDetails?.find(
					(x) => +x?.settingsId === +i
				);
				const name = bankDetails?.documentName || '';

				if (bankDetails.templateType === CalcTemplateType.Integrated) {
					return name?.replace(/\([^()]*\)/g, '')?.trim();
				} else {
					return name?.trim();
				}
			}) || []
		);
	}

	getFileExtension(id: number) {
		return (
			this.bankDetails?.find((x) => +x?.settingsId === +id)?.fileOutputFormat ||
			'xlsm'
		);
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
