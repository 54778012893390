import { User, isTapLevel } from '../../../domain/user/user.model';

export class BlStaffModel {
	AdviserManager: number;
	BirthDate: string;
	BusinessPhone: string;
	EmailAddress: string;
	FirstName: string;
	HomeAddress: string;
	IsActive: number;
	LastName: string;
	MobilePhone: string;
	SecurityGroup: string;
	Services: string[];
	StaffID?: number;
	StartDate: string;
	Stats: any[];
	StaffSettings: any[];
	AdviserIdsAssigned: number[];
	CalendarUrl: string;

	BLSecurityGroups?: string[];
	WidgetOrder?: { code: string }[];
	UpdatedByStaffId?: number;
	StaffDetailValue?: any;
	SecurityRoles?: string[];
	RouteTo?: string;
	CreatedByStaffId?: number;
	CreatedByStaffLevel?: number;
	CreationDate?: string;

	static isActive = (staff: BlStaffModel) => staff.IsActive;
	static getStaffName = (staff: BlStaffModel) =>
		staff ? `${staff.FirstName} ${staff.LastName}` : '';
}
export class BlStaffListModel extends Array<BlStaffModel> {
	static getAvailableAdvisers = (user: User, staffList: BlStaffListModel) => {
		if (isTapLevel(user)) {
			return staffList;
		} else {
			return staffList?.filter((x) =>
				user.AdviserIdsAssigned?.some((y) => y === x.StaffID)
			);
		}
	};
	static getActiveStaffs = (staffList: BlStaffListModel) =>
		staffList?.filter(BlStaffModel.isActive);
	static getStaffById = (staffId: number, staffList: BlStaffListModel) =>
		staffList.find((x) => x.StaffID === staffId);
	static getLeadGens = (staffList: BlStaffListModel) =>
		staffList?.filter(
			(blStaff) => blStaff.SecurityGroup?.toUpperCase() === 'LG'
		);
}

export interface BlStaffRequestModel {
	AdviserManager: number;
	BirthDate: string;
	BusinessPhone: string;
	EmailAddress: string;
	FirstName: string;
	HomeAddress: string;
	IsActive: number;
	LastName: string;
	MobilePhone: string;
	SecurityGroup: string;
	Services: string;
	StaffID?: number;
	StartDate: string;
	Stats?: string;
	StaffSettings: any;
	AdviserIdsAssigned?: number[];
	BLSecurityGroups?: string[];
	WidgetOrder?: { code: string }[];
	UpdatedByStaffId?: number;
	StaffDetailValue?: any;
	SecurityRoles?: string[];
	RouteTo?: string;
	CalendarUrl?: string;
	CreatedByStaffId?: number;
	CreatedByStaffLevel?: number;
	CreationDate?: string;
}
// tslint:disable-next-line: no-empty-interface
export interface BlStaffModelAdd extends BlStaffRequestModel {}
export interface BlStaffModelEdit extends BlStaffRequestModel {
	StaffID: number;
}

export interface ConvertUserModel {
	StaffID: number;
	ContactStatus: string;
	Note: string;
	LostType: string;
}

export interface ConvertUserProfileModel {
	staffID: number;
	contactStatus: string;
	note: string;
	lostType: string;
}

export interface AddUserPhotoRequest {
	StaffID?: number;
	Photo?: any;
	FileName?: string;
	PhotoURL?: string;
}

export interface BlStaffSettingsModel {
	// Common
	Documents: BlStaffDocumentModel[];
	StaffSettingsId?: number;
	StaffId?: number;
	Type?: string;
	IsActive?: number;
	SettingsCode?: string;
	CompletedDate?: any;
	CreatedByStaffId?: any;
	ModifiedDateTime?: any;

	// Qualifications
	QualificationName?: string;
	Institute?: string;
	PolytechCompletedDate?: any;
	PolytechDocuments?: BlStaffDocumentModel[];
	QualificationId?: string; // FE only

	// Documents
	DocumentName?: string;
	DocumentId?: string; // FE only

	// PD / Personal Development
	RequirementName?: string;
	DueDate?: any;
	Provider?: string;
	OtherProvider?: string;
	Category?: string;
	Status?: string;
	StructuredHours?: number;
	Details?: string;
	ProviderBusiness?: string; // FE Only

	// PD Management
	SecurityGroups?: string[],
	Users?: number[],
	Completed?: number,
	PDMId?: number,
}

export interface BlStaffDocumentModel {
	ReferenceId: number;
	Value?: string;
}

export enum BlStaffSettingTypes {
	Qualifications = 'UTQ',
	OtherQualifications = 'UTOQ',
	OnBoardingDocuments = 'UTOD',
	OtherOnBoardingDocuments = 'UTOOD',
	UserSpecificDocuments = 'UTUSD',
	PersonalDevelopment = 'UTPD',
	PersonalDevelopmentManagement = 'UTPDM',
	PersonalDevelopmentManagementIndividual = 'UTPDMI',
}

export enum BlStaffPdTypes {
	Upcoming = 'Upcoming',
	Completed = 'Completed',
	DidntAttend = "Didnt Attend",
}

export enum StaffQualificationInstitute {
	Polytech = 'Open Polytech',
}

export const BlStaffQualificationsCoreKnowledge = {
	id: 'coreKnowledge',
	name: 'Core Knowledge (Industry & Regulation)',
	type: BlStaffSettingTypes.Qualifications,
};

export const BlStaffQualificationsOther = {
	id: 'otherQualifications',
	name: 'Other Qualifications',
	type: BlStaffSettingTypes.OtherQualifications,
};

export const BlStaffQualifications = [
	BlStaffQualificationsCoreKnowledge,
	{
		id: 'lifeDisabilityHealth',
		name: 'Life, Disability and Health Insurance',
		type: BlStaffSettingTypes.Qualifications,
	},
	{
		id: 'residentialProperty',
		name: 'Residential Property Lending',
		type: BlStaffSettingTypes.Qualifications,
	},
	{
		id: 'investment',
		name: 'Investment',
		type: BlStaffSettingTypes.Qualifications,
	},
	{
		id: 'generalInsurance',
		name: 'General Insurance',
		type: BlStaffSettingTypes.Qualifications,
	},
	BlStaffQualificationsOther,
];

export const BlStaffStandardQualifications = BlStaffQualifications.filter(
	(x) => x.type === BlStaffSettingTypes.Qualifications
);

export const BlStaffOnboardingDocumentsOther = {
	id: 'otherOnboardingDocuments',
	name: 'Other Onboarding Documents',
	type: BlStaffSettingTypes.OtherOnBoardingDocuments,
};

export const BlStaffOnboardingDocuments = [
	{
		id: 'contract',
		name: 'Contract',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	{
		id: 'id',
		name: 'ID',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	{
		id: 'proofOfAddress',
		name: 'Proof of Address',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	{
		id: 'creditCheck',
		name: 'Credit Check',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	{
		id: 'authorityRequestLetter',
		name: 'Authority Request Letter',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	{
		id: 'disclosureDocument',
		name: 'Disclosure Document',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	{
		id: 'drsApplication',
		name: 'DRS Application',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	{
		id: 'piCertificateOfInsurance',
		name: 'PI Certificate of Insurance',
		type: BlStaffSettingTypes.OnBoardingDocuments,
	},
	BlStaffOnboardingDocumentsOther,
];

export const BlStaffStandardOnboardingDocuments =
	BlStaffOnboardingDocuments.filter(
		(x) => x.type === BlStaffSettingTypes.OnBoardingDocuments
	);

export const DefaultStaffStats = {
	// L&R
	self_gen_quarter: '',
	api_quarter: '',
	self_gen_annual: '',
	api_annual: '',
	lr_first_meeting_goal_quarter: '',
	lr_first_meeting_goals_annual: '',
	lr_soa_meeting_goals_quarter: '',
	lr_soa_meeting_goals_annual: '',
	submitted_lr_api_goals_quarter: '',
	submitted_lr_api_goals_annual: '',

	// KiwiSaver
	kiwisaver_quarter: '',
	kiwisaver_annual: '',
	submitted_kiwisaver_goals_quarter: '',
	submitted_kiwisaver_goals_annual: '',

	// Mortgage
	mortgage_quarter: '',
	mortgage_annual: '',
	mortgage_first_meeting_goals_quarter:'',
	mortgage_first_meeting_goals_annual: '',
	mortgage_roa_meeting_goals_quarter: '',
	mortgage_roa_meeting_goals_annual: '',
	submitted_mortgage_loan_value_goals_quarter: '',
	submitted_mortgage_loan_value_goals_annual: '',

	// F&G
	blanketnewbusiness_quarter: '',
	blanketnewbusiness_annual: '',
	domestic_fg_quarter: '',
	domestic_fg_annual: '',
	commercial_fg_quarter: '',
	commercial_fg_annual: '',

	// Conversion
	lr_conversion: '',
	kiwisaver_conversion: '',
	blanket_conversion: '',
	mortgage_conversion: '',
};

export const IsActiveOptionsDd = [
	{ name: 'Active', option: 1 },
	{ name: 'Paused', option: 2 },
	{ name: 'Deactivated', option: 0 },
]