import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CRMMatTableDataSourceItemQuery } from '@shared/mat-table/mat-table-datasource';
import { ServicesCodes } from '@shared/models/services/services.model';

export interface EmailTemplateModel {
	documentLink?: string;
	emailTemplateId?: number;
	referenceId?: number;
	type?: string;
	status?: number;
	templateName?: string;
	createdDateTime?: string;
	modifiedDateTime?: string;
	carbonCopy?: string;
	blindCarbonCopy?: string;
	subject?: string;
	bodyContent?: string;
	user?: string;
	userEmail?: string;
	userFirstname?: string;
	body?: any;
	mergeTag?: any;
	isLoading?: boolean;
	emailTemplateSettingsType?: string[];
}

export enum EmailTemplateTypes {
	ETAP = 'Advice Process',
	ETLR = 'L&R Insurance',
	ETM = 'Mortgages',
	ETFG = 'F&G Insurance',
	ETI = 'KiwiSaver & Investment',
	ETC = 'Claims',
}

export const ClaimTypeChoices = [
	{
		display: EmailTemplateTypes.ETAP,
		value: ServicesCodes.CRTNotesAdviceProcess,
		code: ServicesCodes.AdviceProcess,
	},
	{
		display: EmailTemplateTypes.ETLR,
		value: ServicesCodes.NotesLR,
		code: ServicesCodes.LR,
	},
	{
		display: EmailTemplateTypes.ETM,
		value: ServicesCodes.NotesMortgage,
		code: ServicesCodes.Mortgage,
	},
	{
		display: EmailTemplateTypes.ETFG,
		value: ServicesCodes.NotesFG,
		code: ServicesCodes.FG,
	},
	{
		display: EmailTemplateTypes.ETI,
		value: ServicesCodes.NotesInvestment,
		code: [ServicesCodes.KiwiSaver, ServicesCodes.Investment],
	},
	{
		display: EmailTemplateTypes.ETC,
		value: ServicesCodes.NotesClaims,
		code: [ServicesCodes.LR, ServicesCodes.FG],
	},
];

export interface EmailTemplateModelState
	extends CRMMatTableDataSourceItemQuery<EmailTemplateModel> {}

export interface EmailTemplateState
	extends EntityState<EmailTemplateModelState> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'EmailTemplateSettings', idKey: 'emailTemplateId' })
export class EmailTemplateStore extends EntityStore<CRMMatTableDataSourceItemQuery<EmailTemplateModel>> {
	constructor() {
		super({
			rangeIDs: {},
		});
	}

	setRangeData(data: any[], startIndex: number, endIndex: number): void {
		this.update((state) => {
			const newRangeIDs = {};
			newRangeIDs[`${startIndex}-${startIndex + endIndex}`] = data.map(
				(d) => d[this.idKey]
			);
			return {
				...state,
				rangeIDs: {
					...state.rangeIDs,
					...newRangeIDs,
				},
			};
		});
		this.add(data);
	}

	resetRangeData(): void {
		this.update((state) => {
			return {
				...state,
				...{
					rangeIDs: {},
				},
			};
		});
		this.reset();
	}
}

export interface EmailDocTypeModel {
	documentType: string,
	type: string,
	referenceId?: number,
	data?: any
}
