import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	PageChangedEvent,
	PaginationComponent,
} from 'ngx-bootstrap/pagination';

@Component({
	selector: 'app-simple-paginator',
	templateUrl: './simple-paginator.component.html',
	styleUrls: ['./simple-paginator.component.scss'],
})
export class SimplePaginatorComponent
	implements OnInit, OnChanges, AfterViewInit
{
	@ViewChild('pagination') ngxPagination!: PaginationComponent;

	@Output() pageChangedEvent = new EventEmitter<PageChangedEvent>();

	@Input() pageIndex: number;

	@Input() recordCount: number;

	@Input() pageSize = 10;

	showFirstPageNumber = false;

	showLastPageNumber = false;

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.cd.detectChanges();
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (!this.ngxPagination) {
			return;
		}
		// if AppMatTableDataSourceState pageIndex doesn't match the ngx pagination current page
		// we will update the ngx pagination UI
		if (
			simpleChanges.pageIndex &&
			simpleChanges.pageIndex.currentValue !==
				simpleChanges.pageIndex.previousValue &&
			this.ngxPagination.page - 1 !== simpleChanges.pageIndex.currentValue
		) {
			this.ngxPagination.selectPage(simpleChanges.pageIndex.currentValue);
			this._setFirstAndLastPageState();
		}
	}

	ngAfterViewInit(): void {
		this._setFirstAndLastPageState();
	}

	maxSize = 4;

	navigateByPage(page: number, event?: Event): void {
		if (page === null) {
			return;
		}
		event?.preventDefault();
		this.ngxPagination.selectPage(page, event);
	}

	next(event: Event): void {
		if (this.ngxPagination.page < this.ngxPagination.totalPages) {
			this.ngxPagination.selectPage(this.ngxPagination.page + 1, event);
		}
	}

	previous(event: Event): void {
		if (this.ngxPagination.page > 1) {
			this.ngxPagination.selectPage(this.ngxPagination.page - 1, event);
		}
	}

	pageChanged(e: { page: number; itemsPerPage: number }): void {
		this._setFirstAndLastPageState();
		this.pageChangedEvent.next(e);
	}

	private _setFirstAndLastPageState(): void {
		// wait for the ngx paginator to update pages property
		setTimeout(() => {
			this.showFirstPageNumber =
				this.ngxPagination?.pages.findIndex((p) => p.number === 1) === -1;
			this.showLastPageNumber =
				this.ngxPagination?.pages.findIndex(
					(p) => p.number === this.ngxPagination?.totalPages
				) === -1;
			this.cd.detectChanges();
		}, 100);
	}
}
