import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	TemplateRef,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { clone, either, isNil, isEmpty } from 'ramda';
import {
	combineLatest,
	EMPTY,
	forkJoin,
	iif,
	Observable,
	Observer,
	of,
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	withLatestFrom,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ClientProfileQuery } from 'src/app/modules/crm/client-profile/states/client-profile.query';
import { ClientProfileStore } from 'src/app/modules/crm/client-profile/states/client-profile.store';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { createMOATDocumentTemplate } from 'src/app/modules/crm/crt-page/_shared/templates/templates-utils/templates.mapper';
import { CrtSettingsQuery } from 'src/app/modules/crt-settings/state/crt-settings.query';
import { getUploadSuccess } from 'src/app/shared/error-message/error-message';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { UploadModalComponent } from 'src/app/shared/modal/upload-modal/upload-modal.component';
import { SOASubSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { DocumentGroupState } from 'src/app/shared/models/documents/document-group.model';
import {
	DocumentModelState,
	DocumentTypes,
} from 'src/app/shared/models/documents/document.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { LinkDocumentComponent } from 'src/app/shared/services/link-document/link-document.component';
import { convertUtil } from 'src/app/util/util';
import { ClientReviewTemplateQuery } from '../../../states/client-review-template.query';
import { StatementOfAdviceService } from '../../../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../../../states/statement-of-advice/statement-of-advice.store';
import { SoaDocument } from './state/soa-documents.model';
import { SoaDocumentQuery } from './state/soa-documents.query';
import { SoaDocumentService } from './state/soa-documents.service';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';

@Component({
	selector: 'app-soa-documents',
	templateUrl: './soa-documents.component.html',
	styleUrls: ['./soa-documents.component.scss'],
})
export class SoaDocumentsComponent implements OnInit, OnChanges, OnDestroy {
	private onDestroy$ = new Subject<void>();
	optionModalRef: BsModalRef;
	bsModalRef: BsModalRef;
	linkModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;
	isEdit = false;
	isAdd = false;
	showAddDropdown = false;
	uploadingIndex: number = null;
	isLoading$ = this.query.selectLoading();
	isSorting = false;
	documentsFromSettings = [];
	clientDocuments: DocumentGroupState;
	shouldUpdateSort = false;
	tempData: object;

	@Input() soaDocuments: SoaDocument[];
	@Input() parentCRTId: number;
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}>();

	downloadableDocs = [
		'Deed of Acknowledgement of Debt',
		'Financial Declaration',
		'Gifting Letter',
		'Boarder Confirmation',
	];

	peopleName: Array<string>;
	peopleFirstName: Array<string>;
	propertyAddresses: Array<string>;
	businessLogo: string;
	applicationBank: string;
	depositGift: number;
	prevDocumentList: SoaDocument[];

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private service: SoaDocumentService,
		private loggerService: LoggerService,
		private zone: NgZone,
		private renderer: Renderer2,
		private query: SoaDocumentQuery,
		private clientProfileQuery: ClientProfileQuery,
		private clientProfileStore: ClientProfileStore,
		private crtDocService: CrtDocumentService,
		private statementOfAdviceService: StatementOfAdviceService,
		private soaStore: StatementOfAdviceStore,
		private crtQuery: ClientReviewTemplateQuery,
		private settingsQuery: CrtSettingsQuery,
		private clientProfileService: ClientProfileService
	) {}

	ngOnChanges(changes) {
		if (changes.soaDocuments) {
			this.clientDocuments = clone(
				this.clientProfileQuery.getValue().documents
			);
			this.buildForm();
			this.prepDocumentList();
		}
	}

	ngOnInit(): void {
		// make clientDocuments mutable
		combineLatest([this.clientProfileQuery.documents$])
			.pipe(
				tap(([documents]) => {
					this.clientDocuments = clone(documents);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.buildForm();
		this.prepDocumentList();

		this.service.shouldUpdateDocumentSort
			.pipe(
				concatMap(() => this.updateSort()),
				finalize(() => (this.isSorting = false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	get DocumentList() {
		return this.form?.get('applicationDocuments') as UntypedFormArray;
	}

	get LastOrderValue() {
		const ordering = this.DocumentList.value?.map((x) => +x?.order) || [];
		if (ordering?.length > 0) {
			const lastOrder = Math.max(...ordering) || 0;
			return lastOrder + 1;
		} else {
			return 0;
		}
	}

	buildForm() {
		this.form = this.fb.group({
			applicationDocuments: this.fb.array([]),
		});
	}

	checkboxIsTick(doc: UntypedFormGroup, index: number): void {
		if (this.prevDocumentList?.length) {
			this.prevDocumentList[index].combine = !doc.value.combine as any;
		}
		this.shouldUpdateSort = true;
	}

	prepDocumentList() {
		while (this.DocumentList?.length > 0) {
			this.DocumentList.removeAt(0);
		}

		let appDocuments = this.soaDocuments?.sort((a, b) => a.order - b.order);

		const prevDocs = this.prevDocumentList as SoaDocument[];
		if (!!this.shouldUpdateSort) {
			appDocuments = prevDocs?.map((x) => ({
				...appDocuments.find((d) => d?.cRTId === x?.cRTId),
				order: x?.order,
				combine: x?.combine,
			}));
		}

		const docSettings = this.settingsQuery.getValue().documentList;

		if (appDocuments) {
			appDocuments = appDocuments
				?.map((doc) => {
					const setting = docSettings?.find(
						(setting) =>
							setting.documentName === doc.document && setting.isActive
					);
					return {
						...doc,
						isMultiple: setting ? setting.isMultiple : true,
						isEnable: setting ? setting.isEnable : true,
					};
				})
				?.filter((doc) => doc.isEnable);
		}

		// appDocuments = this.updatePriority(appDocuments);

		appDocuments?.forEach((document: SoaDocument, index: number) => {
			this.DocumentList?.push(this.patchValue(document, index));
		});

		this.prevDocumentList = clone(this.DocumentList.getRawValue());
	}

	rearrangeDocumentList() {
		let appDocuments = this.updatePriority(
			this.DocumentList.getRawValue()
		)?.sort((a, b) => a.order - b.order);

		const docSettings = this.settingsQuery.getValue().documentList;

		if (appDocuments) {
			appDocuments = appDocuments
				?.map((doc) => {
					const setting = docSettings?.find(
						(setting) =>
							setting.documentName === doc.document && setting.isActive
					);
					return {
						...doc,
						isMultiple: setting ? setting.isMultiple : true,
						isEnable: setting ? setting.isEnable : true,
					};
				})
				?.filter((doc) => doc.isEnable);
		}

		while (this.DocumentList?.length > 0) {
			this.DocumentList.removeAt(0);
		}

		appDocuments?.forEach((document: SoaDocument, index: number) => {
			this.DocumentList?.push(this.patchValue(document, index));
		});

		this.prevDocumentList = clone(this.DocumentList.getRawValue());
	}

	onSelectAddOption(selectedCRTId: string) {
		if (selectedCRTId === 'Other') {
			this.DocumentList?.push(
				this.patchValue({
					order: this.LastOrderValue + 1,
					combine: true,
					fromDocumentListSettings: false,
					notRequired: false,
					isEdit: true,
					cRTId: null,
				})
			);

			this.prevDocumentList = clone(this.DocumentList.getRawValue());
		} else {
			const existingDocument = this.documentsFromSettings?.find(
				(doc) => doc.settingsId?.toString() === selectedCRTId
			);
			this.DocumentList?.push(
				this.patchValue({
					document: existingDocument.title,
					fromDocumentListSettings: true,
					order: this.LastOrderValue + 1,
					cRTId: null,
					isEdit: true,
				})
			);

			this.prevDocumentList = clone(this.DocumentList.getRawValue());
		}
		this.showAddDropdown = false;
	}

	patchValue(doc, index?: number): UntypedFormGroup {
		const previousValue = this.prevDocumentList[index]?.combine;
		const fileName = this.getDocumentFileName(doc?.documentId);
		return this.fb.group({
			...doc,
			cRTId: doc?.cRTId,
			parentCRTId: this.parentCRTId,
			document: [doc?.document, [Validators.required]],
			documentId: fileName?.length
				? [doc?.documentId, [Validators.required, Validators.min(1)]]
				: null,
			fileName,
			notRequired: doc?.notRequired,
			order: doc?.order,
			fromDocumentListSettings: doc?.fromDocumentListSettings,
			combine: doc?.combine,
			isEdit:
				this.isEdit &&
				this.uploadingIndex &&
				+this?.prevDocumentList[this.uploadingIndex]?.cRTId === doc?.cRTId
					? this.isEdit
					: doc?.isEdit,
			isLoading: [false],
		});
	}

	getDocumentFileName(documentId: number) {
		const documents = [].concat(
			this.clientDocuments?.aP || [],
			this.clientDocuments?.fG || [],
			this.clientDocuments?.k || [],
			this.clientDocuments?.lR || [],
			this.clientDocuments?.m || [],
			this.clientDocuments?.o || []
		);
		return documentId > 0
			? documents
					?.filter((doc) => doc.id === documentId)
					?.map((found) => found.fileName)
			: null;
	}

	deleteConfirm(index: number) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteDocument(index);
			obs.complete();
		});
		const initState = {
			header: 'Delete Application Document',
			message: `Are you sure you want to delete this document?`,
			delete$: confirm,
			canDelete: true,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	toggleEdit(index: number) {
		const data = this.form.getRawValue()?.applicationDocuments[index];
		const toggleNewValue = !data?.isEdit;
		this.isEdit = toggleNewValue;

		if (toggleNewValue) {
			this.setTempData(data);
		} else {
			this.DocumentList.controls[index].patchValue(this.tempData);
			this.prevDocumentList = clone(this.DocumentList.getRawValue());
			this.setTempData(null);
		}

		setTimeout(() => {
			// Add delay before enabling the buttons
			this.DocumentList.controls[index].get('isEdit').setValue(toggleNewValue);
		}, 300);
	}

	toggleAddDropdown() {
		if (this.isAdd) {
			this.showAddDropdown = true;
		}
	}

	setTempData(data?) {
		this.tempData = data || null;
	}

	deleteDocument(index: number) {
		const cRTId = +this.DocumentList.controls[index].get('cRTId').value;
		this.shouldUpdateSort = true;

		this.updateSort()
			.pipe(
				concatMap(() => this.service.delete(cRTId)),
				tap(() => {
					this.DocumentList.removeAt(index);
					this.setTempData();
					this.isSorting = false;
				}),
				take(1)
			)
			.subscribe();
	}

	saveDocument(index: number) {
		const formValue = this.DocumentList.controls[index].value;
		const cRTId = this.DocumentList.controls[index].get('cRTId').value;

		if (cRTId || cRTId !== null) {
			this.DocumentList.controls[index].get('isLoading').setValue(true);
			this.updateFn$(
				{
					...formValue,
					isEnable: true,
					isMultiple: true,
				},
				false
			)
				.pipe(
					finalize(() => {
						this.resetAddEdit();
						this.DocumentList.controls[index].get('isLoading').setValue(false);
						this.DocumentList.controls[index].get('isEdit').setValue(false);
					}),
					take(1)
				)
				.subscribe();
		} else {
			this.updateSort()
				.pipe(
					concatMap(() => this.service.add({ ...formValue, combine: true })),
					finalize(() => {
						this.resetAddEdit();
						this.isSorting = false;
					}),
					take(1)
				)
				.subscribe();
		}
	}

	resetAddEdit() {
		this.isAdd = false;
		this.isEdit = false;
		this.setTempData();
	}

	updateFn$ = (data, refetch) => {
		return this.service.update(data, refetch);
	};

	removeDocument(index: number) {
		const cRTId = this.DocumentList.controls[index].get('cRTId')?.value;

		if (cRTId) {
			this.toggleEdit(index);
		} else {
			this.DocumentList.removeAt(index);
			this.toggleAddDropdown();
		}
	}

	trackByFn = (i, data) => {
		return data.cRTId;
	};

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			if (this.prevDocumentList?.length) {
				moveItemInArray(
					this.prevDocumentList,
					event.previousIndex,
					event.currentIndex
				);
			}
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			if (this.prevDocumentList?.length) {
				transferArrayItem(
					this.prevDocumentList,
					event.container.data,
					event.previousIndex,
					event.currentIndex
				);
			}
			// if transfer, recalculate the order of previous (the list from drag)
			event.previousContainer.data?.forEach((x, index) => {
				x.order = index;
			});
		}
		// always, recalculate the order of the container (the list to drag)
		event.container.data?.forEach((x, index) => {
			x.order = index;
		});

		if (event.currentIndex !== event.previousIndex) {
			this.shouldUpdateSort = true;
			this.rearrangeDocumentList();
		}
	}

	updatePriority(list) {
		return list?.map(({ order, ...item }, i) => ({
			...item,
			order: i,
		}));
	}

	openUploadOptionModal(template: TemplateRef<any>, i: number) {
		this.uploadingIndex = i;
		this.optionModalRef = this.modalService.show(template, {
			class: 'modal-dialog-centered',
			ignoreBackdropClick: true,
		});
	}

	resetLoading() {
		this.DocumentList.controls[this.uploadingIndex]
			.get('isLoading')
			.setValue(false);
	}

	uploadDocuments() {
		const isNew =
			!!!this.DocumentList.controls[this.uploadingIndex].get('documentId')
				.value;

		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

		// convert Image File -> Base64 -> HTML img -> PDF
		const convertImageToBase64 = (file: File): Observable<string> => {
			return new Observable((obs) => {
				obs.next(file);
				obs.complete();
			}).pipe(
				// convert file to base64
				mergeMap(() => convertUtil.simpleConvertToBase64(file)),
				// create a html img string and add the converted base64 image in src
				map((base64) => `<img src="${base64}" />`),
				// convert html img with base64 src to pdf
				mergeMap((img) => {
					return this.crtDocService.generatePDFbase64(img, {
						FileName: convertFilename(file),
					});
				})
			);
		};

		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() =>
							// check if the file is image
							req[0].type.startsWith('image/'),
						// If the file is image. We need to convert it to pdf
						convertImageToBase64(req[0]),
						convertUtil.simpleConvertToBase64(x[0]).pipe(take(1))
					)
				),
				map((file) => ({
					Document: `${((file as string) ?? '')?.replace(/^data:(.*,)?/, '')}`,
					FileName: convertFilename(req[0]),
					DocumentType: ServicesCodes.LR,
					Type: DocumentTypes.soaDocument,
					ReferenceId: this.crtQuery.getValue().adviceProcessId,
					CustomerId: this.crtQuery.getValue().primaryClient?.customerID,
				})),
				concatMap((x) => this.service.uploadDocument(x)),
				concatMap((documentId) =>
					this.updateDocument(documentId, false).pipe(map(() => documentId))
				),
				tap((doc) => {
					// this.shouldUpdateSort = true;
					// const document = this.DocumentList.controls[this.uploadingIndex]
					// 	.value as SoaDocument;
					this.DocumentList.controls[this.uploadingIndex]
						.get('documentId')
						.setValue(doc);
					this.DocumentList.controls[this.uploadingIndex]
						.get('fileName')
						.setValue(convertFilename(req[0]));
					this.zone.run(() =>
						this.loggerService.Success(
							{},
							getUploadSuccess(convertFilename(req[0]))
						)
					);
				}),
				take(1)
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Save Document',
			restrict: '.pdf,image/jpeg,image/jpg,image/png',
		};
		this.bsModalRef = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	linkDocument() {
		const customerId = this.crtQuery.getValue().primaryClient?.customerID;
		this.clientProfileService
			.getClientDocuments(+customerId)
			.pipe(
				withLatestFrom(this.clientProfileQuery.documents$),
				tap(([x, docs]) => (this.clientDocuments = docs)),
				finalize(() => this.linkDocumentModal()),
				take(1)
			)
			.subscribe();
	}

	linkDocumentModal() {
		const isNew =
			!!!this.DocumentList.controls[this.uploadingIndex].get('cRTId').value;
		const initState = {
			selectedDetail: 'Link Document',
			document: this.clientDocuments,
			initialSelectedTab: ServicesCodes.LR?.toLowerCase(),
			// tabs: [ServicesCodes.AdviceProcess?.toString()],
		};
		this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		this.linkModalRef.content.getSelectedDocumentValue$
			.pipe(
				filter((x) => !!x),
				tap((x: DocumentModelState) => {
					if (this.clientDocuments.cP?.some((d) => d.id === x.id)) {
						this.clientDocuments.cP = this.clientDocuments.cP.filter(
							(cd) => cd.id !== x.id
						);
						this.clientDocuments.m?.unshift({
							...x,
							fileName: x.fileName.replace(x.fileExtension, ''),
						});
						this.clientProfileStore.setDocuments(this.clientDocuments);
					}
				}),
				concatMap((doc: DocumentModelState) => {
					const isFromCP = this.clientDocuments.cP?.some(
						(d) => d.id === doc.id
					);
					if (isNew) {
						this.DocumentList.controls[this.uploadingIndex]
							.get('documentId')
							.setValue(doc.id);
						this.DocumentList.controls[this.uploadingIndex]
							.get('fileName')
							.setValue(
								!isFromCP
									? doc.fileName
									: doc.fileName.replace(doc.fileExtension, '')
							);
						return EMPTY;
					} else {
						return this.updateDocument(doc.id, false).pipe(
							map((res: any) => {
								const NEWLY_CREATED_DOCUMENTS = Boolean(0);
								if (NEWLY_CREATED_DOCUMENTS) {
									// this.applicationDocuments[this.uploadingIndex].documentId = res.documentID;
									res.id = res.documentID;
									this.clientDocuments?.aP?.push(res);
									this.clientProfileStore.setDocuments(this.clientDocuments);
									this.prepDocumentList();
								}
								return res;
							})
						);
					}
				}),
				tap(() => this.linkModalRef.hide()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	updateDocument(documentId: number, refetch: boolean) {
		this.DocumentList.controls[this.uploadingIndex]
			.get('documentId')
			.setValue(documentId);
		const formValue = this.DocumentList.controls[this.uploadingIndex].value;
		return this.updateFn$(formValue, refetch);
	}

	downloadLink(id: number) {
		this.service
			.downloadLink(id)
			.pipe(
				tap((x: any) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
				}),
				take(1)
			)
			.subscribe();
	}

	updateSort() {
		const requests$ = [];
		const data = this.updatePriority(this.DocumentList.getRawValue());
		data
			?.filter((value) => !!value.cRTId)
			?.forEach((value) => requests$?.push(this.updateFn$(value, false)));
		this.isSorting = true;

		return this.shouldUpdateSort
			? forkJoin(requests$).pipe(
					concatMap(() =>
						this.service.getApplicationDocuments(this.parentCRTId)
					),
					finalize(() => {
						// this.isSorting = false;
						this.shouldUpdateSort = false;
					}),
					take(1),
					catchError(() => EMPTY)
			  )
			: of(null).pipe(
					finalize(() => {
						// this.isSorting = false;
						this.shouldUpdateSort = false;
					})
			  );
	}

	addNew() {
		this.isAdd = true;
		this.showAddDropdown = true;
	}

	hasEmptyFilesCombined() {
		// Check if a Document is ticked to 'include in the application' but has no documents linked
		const unlinkedDocs = this.updatePriority(
			this.DocumentList.getRawValue()
		)?.filter((x) => !!x?.combine && either(isNil, isEmpty)(x?.documentId));
		const newUnlinkedDocs = unlinkedDocs?.filter(
			(doc) => doc.document !== 'Statement of Advice'
		);
		return newUnlinkedDocs?.length > 0;
	}

	emptyDocConfirm(isNext = false) {
		const confirm = new Observable((obs) => {
			this.continue(isNext);
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: 'Statement of Advice Documents',
			message: `You haven't linked all Documents that will be included in the SOA. Are you sure you want to proceed?`,
			confirm$: confirm,
			decline$: decline,
			isOkBtn: false,
			isAcceptBtn: false,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveSorting(isNext = false) {
		this.updateSort()
			.pipe(take(1))
			.subscribe(() => {
				this.setCurrentPage(
					isNext
						? SOASubSectionCodes.Review
						: SOASubSectionCodes.ProposedInsurance
				);
				this.isSorting = false;
			});
	}

	next() {
		if (this.hasEmptyFilesCombined()) {
			this.emptyDocConfirm(true);
			return;
		}
		if (this.shouldUpdateSort) {
			this.saveSorting(true);
		} else {
			this.setCurrentPage(SOASubSectionCodes.Review);
		}
	}

	previous() {
		if (this.hasEmptyFilesCombined()) {
			this.emptyDocConfirm();
			return;
		}
		this.setCurrentPage(SOASubSectionCodes.ProposedInsurance);
	}

	continue(isNext = false) {
		if (this.shouldUpdateSort) {
			this.saveSorting(isNext);
		} else {
			if (isNext) {
				this.setCurrentPage(SOASubSectionCodes.Review);
			} else {
				this.setCurrentPage(SOASubSectionCodes.ProposedInsurance);
			}
		}
	}

	setCurrentPage(page) {
		this.statementOfAdviceService
			.updateSoa(this.parentCRTId, page)
			.pipe(
				take(1),
				finalize(() => this.soaStore.updateActive({ currentPage: page }))
			)
			.subscribe();
	}

	downloadDocument(documentName: string, index: number) {
		this.DocumentList.controls[index].get('isLoading').setValue(true);

		const data = {
			businessLogo: this.businessLogo,
			dateToday: new Date().toLocaleString('default', {
				month: 'long',
				day: 'numeric',
				year: 'numeric',
			}),
			peopleName: this.peopleName,
			peopleFirstName: this.peopleFirstName,
			depositGift: this.depositGift,
			propertyAddresses: this.propertyAddresses,
			applicationBank: this.applicationBank,
			fileName: `${documentName}.pdf`,
		};

		return this.crtDocService
			.downloadDocumentPDF(
				createMOATDocumentTemplate(documentName, data),
				`${documentName}.pdf`
			)
			.pipe(
				tap((x) => {
					const name = `${documentName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				finalize(() =>
					this.DocumentList.controls[index].get('isLoading').setValue(false)
				),
				catchError((err) => {
					this.DocumentList.controls[index].get('isLoading').setValue(false);
					return throwError(new Error(err));
				}),
				take(1)
			)
			.subscribe();
	}

	duplicateDocument(document: UntypedFormGroup, index: number) {
		this.isSorting = true;
		const value = document.value;
		let order = document.value.order;
		const data = {
			...value,
			order: this.shouldUpdateSort ? index : order,
			documentId: null,
			fileName: null,
		};

		this.updateSort()
			.pipe(
				concatMap(() => {
					this.isSorting = true;
					return this.service.add(data);
				}),
				finalize(() => {
					this.isSorting = false;
					this.resetAddEdit();
				}),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
