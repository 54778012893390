import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo, tap, take } from 'rxjs/operators';
import { ClientSearchService } from './state/client-search.service';
import { DropdownValueService } from '../../../domain/dropdown-value/dropdown-value.service';
import { ServicesService } from '../../../domain/service/services.service';

const dropdownCodes: string[] = ['PCLE', 'AT', 'PCLT', 'CI'];

@Injectable()
export class ClientSearchResolver implements Resolve<boolean> {
	constructor(
		private dropdownValueService: DropdownValueService,
		private serviceService: ServicesService,
		private clientSearchService: ClientSearchService
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);

		// this.clientSearchService.clear();
		return forkJoin([dropdown$]).pipe(
			tap(() => this.clientSearchService.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.clientSearchService.getColumnWidths().pipe(take(1)).subscribe()),
			tap(() => this.serviceService.addList().pipe(take(1)).subscribe()),
			mapTo(true)
		);
	}
}
