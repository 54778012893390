import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { StatementOfAdviceState } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.model';
import { SOAScopeOfServiceState } from 'src/app/shared/models/client-review-template/statement-of-advice/scope-of-service/soa-scope-of-service.model';
import { CurrentInsuranceState } from 'src/app/shared/models/client-review-template/current-insurance/current-insurance.model';
import { ProposedInsuranceState } from 'src/app/shared/models/client-review-template/statement-of-advice/insurance/soa-insurance.model';
import { SOASubSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

export interface SOAState
  extends EntityState<StatementOfAdviceState>,
    ActiveState {
  scopeOfService: SOAScopeOfServiceState[]; // SOA Scope of Service
  currentInsurance: CurrentInsuranceState[]; // L&R Current Insurance
  proposedInsurance: ProposedInsuranceState[]; // SOA Proposed Insurance

  isLoadingSOS: boolean;
  isLoadingCI: boolean;
  isLoadingPI: boolean;
  isLoadingSOAR: boolean;

  isSavingSOA: boolean;

  isSavingSOS: boolean;
  isSavingCI: boolean;
  isSavingPI: boolean;

  isSavingSOAReview: boolean;
  soaCurrentTab: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'StatementOfAdvice', idKey: 'cRTId' })
export class StatementOfAdviceStore extends EntityStore<SOAState> {
  constructor() {
    super({
      scopeOfService: null,
      currentInsurance: null,
      proposedInsurance: null,

      isLoadingSOS: false,
      isLoadingCI: false,
      isLoadingPI: false,
      isLoadingSOAR: false,

      isSavingSOA: false,

      isSavingSOS: false,
      isSavingCI: false,
      isSavingPI: false,

      isSavingSOAReview: false,
      soaCurrentTab: null,
    });
  }

  resetSubpages() {
    this.update(() => ({
      scopeOfService: null,
      currentInsurance: null,
      proposedInsurance: null,
    }));
  }

  setSOALoading(isLoading: boolean, sectionCode: string) {
    switch (sectionCode) {
      case 'SOASOS':
        this.update({ isLoadingSOS: isLoading });
        break;
      case 'SOACI':
        this.update({ isLoadingCI: isLoading });
        break;
      case 'SOAPI':
        this.update({ isLoadingPI: isLoading });
        break;
      case SOASubSectionCodes.Review:
        this.update({ isLoadingSOAR: isLoading });
        break;
      default:
        break;
    }
  }

  setSOASaving(isSaving: boolean, sectionCode: string) {
    switch (sectionCode) {
      case 'SOA':
        this.update({ isSavingSOA: isSaving });
        break;
      case 'SOASOS':
        this.update({ isSavingSOS: isSaving });
        break;
      case 'SOACI':
        this.update({ isSavingCI: isSaving });
        break;
      case 'SOAPI':
        this.update({ isSavingPI: isSaving });
        break;
      case 'SOAR':
        this.update({ isSavingSOAReview: isSaving });
        break;
      default:
        break;
    }
  }

  setSoaCurrentTab(code: string) {
		this.update({ soaCurrentTab: code });
	}
}
