import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { util } from '@core/util/util.service';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import {
	BlStaffPdTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import MomentUtil from '@util/moment.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	ALLOWED_DOCUMENT_FILE_TYPES,
	UploadModalComponent,
} from '../upload-modal/upload-modal.component';
import {
	catchError,
	concatMap,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { convertUtil } from '@util/util';
import { Observable, of } from 'rxjs';
import { validMomentDate } from '@shared/validator/valid-moment-date/valid-moment-date';

@Component({
	selector: 'app-user-pd-tracking-modal',
	templateUrl: './user-pd-tracking-modal.component.html',
	styleUrls: ['./user-pd-tracking-modal.component.scss'],
})
export class UserPdTrackingModalComponent implements OnInit {
	title: string = 'PD Tracking';
	data: BlStaffSettingsModel;
	documentTypeCode: string;
	type: string;
	btnText: string;
	limitSingleDoc: boolean;
	isLoading: boolean;
	staffId: number;
	UPDTP: ViewDisplayValue[] = []; // Provider Dropdown
	UPDTC: ViewDisplayValue[] = []; // Category Dropdown
	UPDTS: ViewDisplayValue[] = []; // Status Dropdown
	defaultStatus: string;
	initializeEdit = true;
	toggleCompletedDate = false;
	enableAllFields = true;

	form: FormGroup;
	documents = [];
	submitted = false;
	viewMode = false;
	isEditMode = true;
	showOtherProvider = false;

	public bsModalRefUpload: BsModalRef;

	upsertFn$: (ac: BlStaffSettingsModel) => Observable<BlStaffSettingsModel>;
	downloadDocumentFn$: (ac: {
		documentId: number;
		fileName: string;
	}) => Observable<any>;

	get RequirementName() {
		return this.form.get('requirementName');
	}
	get DueDate() {
		return this.form.get('dueDate');
	}
	get CompletedDate() {
		return this.form.get('completedDate');
	}
	get Provider() {
		return this.form.get('provider');
	}
	get Category() {
		return this.form.get('category');
	}
	get Status() {
		return this.form.get('status');
	}
	get StructuredHours() {
		return this.form.get('structuredHours');
	}
	get Details() {
		return this.form.get('details');
	}
	get OtherProvider() {
		return this.form.get('otherProvider');
	}

	constructor(
		private fb: FormBuilder,
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private crtDocService: CrtDocumentService,
		private routeService: RouteService,
		private router: Router
	) {
		this.form = this.fb.group({
			requirementName: ['', [Validators.required]],
			dueDate: ['', [Validators.required, validMomentDate()]],
			completedDate: [''],
			provider: ['', [Validators.required]],
			otherProvider: [''],
			category: ['', [Validators.required]],
			status: ['', [Validators.required]],
			structuredHours: ['', [Validators.required]],
			details: [''],
		});
	}

	ngOnInit(): void {
		if (!!this.data) {
			const isOtherProvider = this.isOtherProvider(this.data?.Provider);
			this.showOtherProvider = isOtherProvider;
			this.form.patchValue({
				requirementName: this.data?.RequirementName,
				dueDate: this.formatValidDate(this.data?.DueDate),
				completedDate: this.formatValidDate(this.data?.CompletedDate),
				provider: this.data?.Provider || '',
				otherProvider: this.data?.OtherProvider || '',
				category: this.data?.Category,
				status: this.data?.Status,
				structuredHours: this.data?.StructuredHours,
				details: this.data?.Details,
			});
			if (this.data?.Documents?.length > 0) {
				this.documents = this.data?.Documents;
			}
		} else {
			this.preselectStatus();
		}

		this.setValidators();
		this.onChangeStatus();
		this.setEnabledFields();
	}

	preselectStatus() {
		if (!!this.defaultStatus) {
			this.Status.setValue(this.defaultStatus);
		}

		const defaultCategory = this.UPDTC?.find((item) => item.isDefault);
		if (!!defaultCategory) {
			this.Category.setValue(defaultCategory?.value);
		}

		const defaultProvider = this.UPDTP?.find((item) => item.isDefault);
		if (!!defaultProvider) {
			this.Provider.setValue(defaultProvider?.value);
		}
	}

	setEnabledFields() {
		if (!!this.initializeEdit) {
			this.enableFields();
		} else {
			this.isEditMode = false;
			this.form.disable();
		}
	}

	setValidators() {
		if (!!this.toggleCompletedDate) {
			this.CompletedDate.clearValidators();
		} else {
			this.CompletedDate.setValidators([
				Validators.required,
				validMomentDate(),
			]);
		}
		this.CompletedDate.updateValueAndValidity();
	}

	formatValidDate(value) {
		return util.isValidMoment(value)
			? value
			: MomentUtil.formatDateToMoment(value);
	}

	isOtherProvider(provider: string) {
		if (!provider) {
			return false;
		}
		if (provider === 'Other') {
			return true;
		}
		return !this.UPDTP?.find((x) => x?.value === provider);
	}

	enableFields() {
		if (!this.data || this.enableAllFields) {
			this.form.enable();
		} else {
			this.RequirementName.disable();
			this.DueDate.disable();
			this.Provider.disable();
			this.OtherProvider.disable();
			this.Category.disable();
			this.Details.disable();
			this.Status.enable();
			this.StructuredHours.enable();
			this.CompletedDate.enable();
		}
	}

	uploadDocument() {
		const formValue = this.form.getRawValue();
		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					convertUtil.simpleConvertToBase64(x[0]).pipe(
						map((file) => ({
							content: (file as string)?.replace(/^data:(.*,)?/, ''),
							filename: x[0]?.name || '',
						}))
					)
				),
				map((file: any) => ({
					Document: file.content,
					FileName: file.filename,
					DocumentName: formValue?.requirementName || '',
					DocumentType: this.documentTypeCode,
					Type: this.type,
					ReferenceId: this.staffId,
					CustomerId: 0,
				})),
				concatMap((file) =>
					this.crtDocService.uploadDocument(file).pipe(
						map((x) => ({
							...file,
							ReferenceId: +x,
							Value: file?.FileName,
						}))
					)
				),
				tap((x) => {
					this.documents = [...this.documents, x];
				}),
				take(1)
			);

		const initialState = {
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Add New Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
			customUpload: upload,
		};
		this.bsModalRefUpload = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	removeDocument(index: number, data) {
		let docs = [...this.documents];
		docs = docs?.filter((x) => +x?.ReferenceId !== +data?.ReferenceId);
		this.documents = docs;
	}

	viewDocument(data) {
		return of(data)
			.pipe(
				map((res) => ({
					documentId: res.ReferenceId,
					name: res?.Value || '',
				})),
				mergeMap((res) => {
					const ext = res?.name?.split('.')?.reverse()?.[0];
					if (!!ext && !!res?.documentId) {
						if (ext?.toLowerCase() === 'pdf') {
							return of(res).pipe(
								tap(() => {
									const pdfUrl = this.router.serializeUrl(
										this.router.createUrlTree(this.routeService.viewPdf(res))
									);
									window.open(pdfUrl, '_blank');
								}),
								take(1)
							);
						} else {
							return this.downloadDocumentFn$({
								documentId: res?.documentId,
								fileName: res?.name,
							});
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save() {
		this.submitted = true;
		if (
			this.form.invalid ||
			(this.Provider.value === 'Other' && !this.OtherProvider.value?.trim())
		) {
			return;
		}
		this.isLoading = true;

		of(this.form.getRawValue())
			.pipe(
				map((formValue) => {
					return {
						RequirementName: formValue?.requirementName || '',
						DueDate: this.formatValidDate(formValue?.dueDate),
						CompletedDate: this.formatValidDate(formValue?.completedDate),
						Documents: this.documents?.filter((d) => !!d?.ReferenceId) || [],
						Provider: formValue?.provider || '',
						OtherProvider: formValue?.otherProvider || '',
						Category: formValue?.category || '',
						Status: formValue?.status || '',
						StructuredHours: !!formValue?.structuredHours
							? +formValue?.structuredHours
							: null,
						Details: formValue?.details || '',
						StaffId: this.staffId,
						StaffSettingsId: this.data?.StaffSettingsId,
						DocumentType: this.documentTypeCode,
						Type: this.type,
						SettingsCode: this.type,
						PDMId: this.data?.PDMId || null,
					} as BlStaffSettingsModel;
				}),
				tap(() => (this.isLoading = true)),
				concatMap(this.upsertFn$),
				catchError((error) => {
					if (error) {
						this.isLoading = false;
					}
					throw error;
				}),
				tap(() => {
					this.isLoading = false;
					this.close();
				}),
				take(1)
			)
			.subscribe();
	}

	allowEdit() {
		this.isEditMode = true;
		this.enableFields();
	}

	onChangeOther(event: Event) {
		this.OtherProvider.setValue('');
		this.showOtherProvider = this.isOtherProvider(this.Provider.value);
	}

	onChangeOtherTextbox(event: Event | any) {
		if (event?.code === 'CapsLock') {
			event?.preventDefault();
			return;
		}

		if (!(event.target as HTMLSelectElement).value) {
			this.Provider.setValue('');
		}

		this.showOtherProvider = this.isOtherProvider(this.Provider.value);
	}

	onChangeStatus() {
		if (this.Status.value === BlStaffPdTypes.Completed) {
			this.CompletedDate.setValidators([
				Validators.required,
				validMomentDate(),
			]);
		} else {
			this.CompletedDate.clearValidators();
		}
		if (!!this.toggleCompletedDate) {
			this.CompletedDate.setValue('');
		}
		this.CompletedDate.updateValueAndValidity();
	}

	close() {
		this.bsModalRef.hide();
	}
}
