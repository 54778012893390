import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentRoutingModule } from './document-routing.module';
import { DocumentComponent } from './document.component';
import { SharedModule } from '../../../shared/shared.module';

import {
  DocumentService
} from './states/document.service';

import { DocumentStore } from './states/document.store';
import { DocumentQuery } from './states/document.query';
import { DocumentPageResolver } from './document.resolver';

@NgModule({
  imports: [CommonModule, DocumentRoutingModule, SharedModule],
  declarations: [DocumentComponent],
  providers: [
    DocumentPageResolver,
    DocumentQuery,
    DocumentStore,
    DocumentService,
  ]
})
export class DocumentModule { }
