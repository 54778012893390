import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { MonthlyApiStat } from './mas.model';
import { Injectable } from "@angular/core";

export interface MonthlyApiStatsState {

  /**
   * Monthly API Stats fields
   */
  MAStats?: MonthlyApiStat;
  /**
   * error message. will show up when not empty
   */
  error: string;
  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): MonthlyApiStatsState {
  return {
    MAStats: null,
    error: '',
    isLoading: false
  };
}


/**
 * Monthly Api Stats widget data store
 */
@Injectable()
@StoreConfig({
  name: 'MonthlyApiStats'
})
export class MonthlyApiStatsStore extends Store<MonthlyApiStatsState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Set if MAS is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
