import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { util } from 'src/app/core/util/util.service';
import { ClientAcceptance } from 'src/app/shared/models/client-review-template/client-acceptance/client-acceptance.model';
@Injectable()
export class FinalStructureGuard implements CanActivate {
	constructor(private router: Router, private api: ApiService) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const code = next.paramMap.get('companyCode');
		const customerType = next.paramMap.get('customerType');
		const clientId = next.paramMap.get('clientId');
		const adviceProcessId = next.paramMap.get('adviceProcessId');
		const returnUrl = `${code}/crt/${customerType}/${clientId}/advice-process/${adviceProcessId}/client-acceptance`;

		const isSync = next.paramMap.get('sync');

		return zip(
			this.api.get<ClientAcceptance[]>(`crt/${adviceProcessId}/CA`),
			this.api.get<any>(`crt/${adviceProcessId}/FS`),
			this.api.get<any>(`adviceprocesses/${adviceProcessId}`)
		).pipe(
			map(
				([ca, fs, ap]) =>
					!!ca &&
					ca?.length > 0 &&
					ap?.Documents?.some(
						(x) => x?.Field === 'Authority to Proceed' && !!x?.Value?.DocumentID
					) &&
					((!!fs && fs?.length > 0) || (!!isSync && !!util.toBool(isSync))) &&
					!(ap?.status > 2 && ap?.status < 5)
			),
			tap((x) => {
				if (!x) {
					this.router.navigateByUrl(returnUrl);
				}
			})
		);
	}
}
