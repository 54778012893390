import { Component, OnDestroy } from '@angular/core';
import * as R from 'ramda';
import { Subject, Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { ThemeConfig } from '../../models/_general/config';
import { strUtil } from '../../../util/util';
import { map } from 'rxjs/operators';

/** prefix for replaced substrings in the styleString */
const prefixToReplace = '##';
/**
 * code to replace in the stylesheets.
 * has derived codes too.
 * prefixed with `prefixToReplace`.
 */
const fieldTextColor = `${prefixToReplace}fieldTextColor`;
const fieldBackgroundColor = `${prefixToReplace}fieldBackgroundColor`;
const primarycolor = `${prefixToReplace}primarycolor`;
const secondarycolor = `${prefixToReplace}secondarycolor`;
const tertiarycolor = `${prefixToReplace}tertiarycolor`;
const h1color = `${prefixToReplace}h1color`;
const h2color = `${prefixToReplace}h2color`;
const h3color = `${prefixToReplace}h3color`;
const h4color = `${prefixToReplace}h4color`;
const h5color = `${prefixToReplace}h5color`;
const h6color = `${prefixToReplace}h6color`;
const pcolorcolor = `${prefixToReplace}pcolorcolor`;
const lighterprimarycolor = `${prefixToReplace}lighterprimarycolor`;
const lightersecondarycolor = `${prefixToReplace}lightersecondarycolor`;

const key = 'MSStream'
const os = /Windows/.test(navigator.userAgent) && !window[key];
/**
 * Naming convention (Not final, give suggestions)
 * - always start with `theme` to indicate it is a theme class
 * - don't define same class name elsewhere
 * - separate words with hyphen `-`
 *
 * Use
 * - apply on element by adding classname to element class
 * - for complex controls, customize control to expect input classnames to apply to different internal elements
 */
const styleString = `
  .theme-crm-field, .theme-crm-field-disabled:disabled {
    color: ${fieldTextColor};
    background-color: ${fieldBackgroundColor};
  }
  .secondary-background {
    background-color: ${secondarycolor};
  }

  .ngx-datatable .datatable-header {
    max-width: calc(100% - ${os ? '26' : '18'}px);
  }

  .h4-font-color,
  .ngx-datatable .datatable-header .datatable-header-cell {
    color: ${h4color};
  }

  .tap--bg-primary {
    background-color: ${primarycolor};
  }
  .tap--bg-secondary {
    background-color: ${secondarycolor};
  }
  .tap--bg-tertiary {
    background-color: ${tertiarycolor};
  }
  tap--bg-h1color {
    background-color: ${h1color}
  }
  tap--bg-h2color {
    background-color: ${h2color}
  }
  tap--bg-h3color {
    background-color: ${h3color}
  }
  tap--bg-h4color {
    background-color: ${h4color}
  }
  tap--bg-h5color {
    background-color: ${h5color}
  }
  tap--bg-h6color {
    background-color: ${h6color}
  }

  .tap--bg-primary--lighten {
    background-color: ${lighterprimarycolor};
  }
  .tap--bg-secondary--lighten {
    background-color: ${lightersecondarycolor};
  }

  .tap--text-primary {
    color: ${primarycolor};
  }
  .tap--text-secondary {
    color: ${secondarycolor};
  }
  .tap--text-tertiarycolor {
    color: ${tertiarycolor}
  }
  .tap--text-h1color {
    color: ${h1color}
  }
  .tap--text-h2color {
    color: ${h2color}
  }
  .tap--text-h3color {
    color: ${h3color}
  }
  .tap--text-h4color {
    color: ${h4color}
  }
  .tap--text-h5color {
    color: ${h5color}
  }
  .tap--text-h6color {
    color: ${h6color}
  }
  .tap--border-primary {
    border-color: ${primarycolor}!important;
  }
  .tap--border-secondary {
    border-color: ${secondarycolor}!important;
  }
  .tap--border-tertiarycolor {
    border-color: ${tertiarycolor}!important;
  }
` +
  /** for special cases like speech bubble arrow */
  `
  .lrct__info::after {
    border-color: transparent ${lightersecondarycolor}
  }
`;
/**
 * Adds style element that changes color according business config.
 */
@Component({
  selector: 'app-theme-style',
  template: '<span [innerHTML]="style$ | async"></span>',
})
export class ThemeComponent implements OnDestroy {
  /** observable that should trigger unsubscription to others */
  onDestroy$ = new Subject<void>();
  /** theme config object */
  themeConfig$ = this.businessConfigQuery.themeConfig$;
  /** actual HTML style. uses createStyles */
  style$: Observable<SafeHtml> = this.themeConfig$.pipe(
    map(x => x ? this.createStyles(x) : '')
  );

  constructor(
    private businessConfigQuery: BusinessConfigQuery,
    private sanitizer: DomSanitizer
  ) { }

  /** trigger destroying of observables. */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
  /** append `#` when hex code doesn't have it. */
  private appendPoundSign(hex: string): string {
    return hex?.trim()?.indexOf('#') === 0 ? hex : `#${hex?.trim()}`;
  }
  /** replace theme codes with hex color codes */
  private replaceColors(style: string, theme: ThemeConfig): string {
    return Object.keys(theme)?.reduce((acc, tkey) =>
      strUtil.replaceAll(acc, `${prefixToReplace}${tkey}`, this.appendPoundSign(theme[tkey])), style
    );
  }
  /** surround with `style` tag. */
  private mapToStyles(styles: any): string {
    return `<style>${styles}</style>`;
  }
  /**
   * pipe theme config object to produce themed styles.
   * use angular sanitizer to bypass angular blocking of dangerous code.
   */
  // tslint:disable-next-line: member-ordering
  private createStyles: (theme: ThemeConfig) => SafeHtml = R.pipe(
    R.curry(this.replaceColors)(styleString),
    this.mapToStyles,
    this.sanitizer.bypassSecurityTrustHtml
  );
}

