import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cc-kpi-table',
  templateUrl: './cc-kpi-table.component.html'
})
export class CcKpiTableComponent implements OnInit {

  @Input() data$: any;

  constructor() { }

  ngOnInit() {
  }

}
