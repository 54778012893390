import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ExportsState, ExportsStatus } from './exports.model';

export function createInitialState(): ExportsState {
	return {
		staffId: null,
		exportId: null,
    reportCode: null,
		reportLabel: null,
    timeRemaining: null,
    timeElapsed: null,
		progress: null,
    status: null,
		attempts: null,
		error: null
	};
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'exports' })
export class ExportsStore extends Store<ExportsState> {
	constructor() {
		super(createInitialState());
	}
}