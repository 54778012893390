import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
	AdviceProcessPageCodes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from '../../../../../../shared/models/advice-process/advice-process.model';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { MortgageAdviceProcessService } from '../../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { PropertyService } from '../assets-and-liabilities/state/property/property.service';
import { PeopleEntitiesQuery } from '../people-entities/state/people-entities.query';
import { PeopleEntitiesService } from '../people-entities/state/people-entities.service';
import { IncomeService } from './state/income.service';

declare var $: any;
@Component({
	selector: 'app-income',
	templateUrl: './income.component.html',
	styleUrls: ['./income.component.scss'],
})
export class IncomeComponent implements OnInit {
	onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;
	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;

	// dropdowns
	APCRTF$ = this.service.APCRTF$;
	APCRTKC$ = this.service.APCRTKC$;
	APCRTTC$ = this.service.APCRTTC$;
	APCRTI$ = this.service.APCRTI$;
	APCRTIST$ = this.service.APCRTIST$;
	APCRTIT$ = this.service.APCRTIT$;
	APCRTTM$ = this.service.APCRTTM$;
	APCRTYN$ = this.service.APCRTYN$;
	APCRTYNNA$ = this.service.APCRTYNNA$;
	PCE$ = this.service.PCE$;

	primaryClient$ = this.service.primaryClient$;
	secondaryClients$ = this.query.secondaryClients$;
	incomeSource$ = this.incomeService.incomeSource$;
	previousIncomeSource$ = this.incomeService.previousIncomeSource$;
	totalPreviousIncome$ = this.incomeService.totalPreviousIncome$;
	netRentalIncome$ = this.incomeService.netRentalIncome$;
	otherIncome$ = this.incomeService.otherIncome$;
	monthlyExpense$ = this.incomeService.monthlyExpense$;
	people$ = this.peopleService.people$;
	properties$ = this.propertyService.properties$;
	factFindComputation$ = this.incomeService.factFindComputation$;
	peopleFromCRMAndCRTExceptChild$ =
		this.peopleQuery.peopleFromCRMAndCRTExceptChild$;
	transferedSCIList$ = this.peopleQuery.transferedSCIList$;
	adviceProcessId = this.query.getValue().adviceProcessId;
	sidebar = this.query.getValue().sidebars;

	peopleFromCRMAndCRTExceptChildNotDeceased$ = this.peopleQuery.peopleFromCRMAndCRTExceptChildNotDeceased$;

	allIncomeList$ = new BehaviorSubject(null);
	annualTaxableJointIncome = 0;
	monthlyTaxableJointIncome = 0;
	monthlyAfterTaxIncome = 0;

	deceasedSciList$ = this.peopleService.deceasedSciList$;
	getOwnerChoices = this.service.getOwnerChoices;

	constructor(
		private service: CrtMortgageService,
		private query: CrtMortgageQuery,
		private incomeService: IncomeService,
		private propertyService: PropertyService,
		private peopleQuery: PeopleEntitiesQuery,
		private peopleService: PeopleEntitiesService,
		private moatAdviceProcessService: MortgageAdviceProcessService
	) {}

	ngOnInit(): void {
		this.moatAdviceProcessService
			.updateMortApPageStarted(AdviceProcessPageCodes.Income)
			.pipe(take(1))
			.subscribe();

		this.factFindComputation$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.annualTaxableJointIncome = +data?.annualTaxableJointIncome;
				this.monthlyTaxableJointIncome = +data?.monthlyTaxableJointIncome;
				this.monthlyAfterTaxIncome = +data?.monthlyAfterTaxIncome;
			});

		combineLatest([
			this.incomeService.incomeSource$,
			this.incomeService.netRentalIncome$,
			this.incomeService.otherIncome$,
		])
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() =>
				this.allIncomeList$.next(this.incomeService.getIncomeLists())
			);
	}

	/*
	 * Income Source
	 */
	addIncomeSource$ = (model) =>
		this.incomeService.addIncomeSource(model, this.adviceProcessId);
	updateIncomeSource$ = (model) => this.incomeService.updateIncomeSource(model);
	deleteEmployment$ = (id) => this.incomeService.deleteIncomeSource(id);

	/*
	 * Previous Income Source
	 */
	addPreviousIncomeSource$ = (model) =>
		this.incomeService.addPreviousIncomeSource(model, this.adviceProcessId);
	updatePreviousIncomeSource$ = (model) =>
		this.incomeService.updatePreviousIncomeSource(model);
	deletePreviousEmployment$ = (id) =>
		this.incomeService.deletePreviousIncomeSource(id);

	/*
	 * Net Rental Income
	 */
	addNewRental$ = (model) => this.incomeService.addRentalIncome(model);
	updateRental$ = (model) => this.incomeService.updateRentalIncome(model);
	deleteRental$ = (id) => this.incomeService.deleteRentalIncome(+id);

	/*
	 * Other Income
	 */
	addNewOther$ = (model) => this.incomeService.addOtherIncome(model);
	updateOther$ = (model) => this.incomeService.updateOtherIncome(model);
	deleteOther$ = (id) => this.incomeService.deleteOtherIncome(+id);
}
