import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { WeeklyApiStatsState, WeeklyApiStatsStore } from './was.store';

/**
 * Query for Weekly API Stats widget data
 */
@Injectable()
export class WeeklyApiStatsQuery extends Query<WeeklyApiStatsState> {

  /** Weekly API Stats data. */
  weeklyApiStatData$ = this.select(state =>
    state.WAStats
  );

  /** Indicator if WeeklyApiStatus is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for WeeklyApiStatus pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: WeeklyApiStatsStore) {
    super(store);
  }
}
