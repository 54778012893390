<form [formGroup]="form" novalidate>
	<div class="form-row mb-2 kiwisaver-label-background">
		<div class="col-7 col-sm-3 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary"
				>KiwiSaver</label
			>
		</div>
		<div class="col-5 col-sm-9 text-right px-0 iconShow">
			<button
				id="kiwisaver_collapseButton"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 kiwisaver-collapse-background"
			>
				<span *ngIf="elseMinusKiwiSaver; else elsePlusKiwiSaver">
					<i (click)="collapseMoreKiwiSaver()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusKiwiSaver>
					<span>
						<i
							(click)="collapseLessKiwiSaver()"
							class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div class="collapse expandable" id="collapseKiwiSaver">
		<div
			class="form-row d-none d-lg-flex mb-2"
			[class.tap-opacity-25]="KiwiSavers?.controls?.length === 0"
		>
			<div class="col-12 col-lg-4 font-weight-bold">Owner</div>
			<div class="col-12 col-lg-3 font-weight-bold">Fund Type</div>
			<div class="col-12 col-lg-2 font-weight-bold">Provider</div>
			<div class="col-12 col-lg-2 font-weight-bold">Current Balance</div>
		</div>
		<div formArrayName="kiwiSavers">
			<div
				class="form-row align-items-center"
				*ngFor="let k of KiwiSavers.controls; let i = index; let last = last"
				[formGroupName]="i"
			>
				<div class="d-md-none col-lg-1 text-right">
					<ng-container *ngIf="!k.value.isLoading">
						<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
							<ng-container *ngIf="!k.get('isEdit')?.value">
								<button
									id="kiwisaver_editKiwiSaverButton_new_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing || !!deletingId"
									(click)="editKiwiSaver(k.get('cRTId').value, i)"
								>
									<i class="material-icons md-16">edit</i>
								</button>
								<button
									id="kiwisaver_deleteModalButton_new_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing || !!deletingId"
									(click)="deleteModal(i)"
								>
									<i class="material-icons md-16">delete</i>
								</button>
							</ng-container>
							<ng-container *ngIf="!!k.get('isEdit')?.value">
								<button
									id="kiwisaver_saveKiwiSaverButton_new_{{ i }}"
									color="primary"
									*ngIf="isAddNew"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="saveKiwiSaver(k)"
									[disabled]="!k.get('fundOwner')?.value"
								>
									<i class="material-icons md-16">save</i>
								</button>
								<button
									id="kiwisaver_removePropertyButton_new_{{ i }}"
									*ngIf="isAddNew"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="removeProperty(i)"
								>
									<i class="material-icons md-16">close</i>
								</button>
							</ng-container>
						</ng-container>
						<ng-template #viewOnlySmall>
							<button
								id="kiwisaver_viewKiwiSaverButton_new_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="isEditing || !!deletingId"
								(click)="viewKiwiSaver(k.get('cRTId').value, i)"
							>
								<i class="material-icons md-16">info</i>
							</button>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="!!k.value.isLoading">
						<app-manual-loader-mini
							[isLoading]="k.value.isLoading"
						></app-manual-loader-mini>
					</ng-container>
				</div>
				<div
					class="col-lg-4 mb-2"
					[class.border-bottom]="!k.value.isNew && !last"
				>
					<ng-container *ngIf="!k.value.isNew">
						<app-chips
							[textboxId]="'kiwisaveFormOwner' + i"
							[attr.disabled]="k.get('fundOwner')?.disabled"
							class="crt-form-control-field"
							[formControl]="k.get('fundOwner')"
							textboxClass="theme-crm-field"
							[isRemoveChipsPadding]="k.get('fundOwner')?.disabled"
							[choices]="policyOwnerChoices(k.get('fundOwner').value)"
						></app-chips>
					</ng-container>
					<ng-container *ngIf="k.value.isNew">
						<span
							class="w-100"
							[class.crt-custom-dropdown]="!selectedFundOwner"
						>
							<select
								id="kiwisaveformOwnerAdd"
								placeholder="Owner"
								class="form-control crt-form-control theme-crm-field-disabled"
								[disabled]="!!selectedFundOwner"
								(change)="onSelectFundOwner($event.target.value, i)"
								[class.select-text-muted]="!selectedFundOwner"
							>
								<option
									value=""
									selected
									[attr.disabled]="true"
									hidden
									class="select-text-muted"
								>
									Select Owner
								</option>
								<option
									*ngFor="let d of crmKiwisaverChoices$ | async"
									[value]="d.value"
									[selected]="+d.value === selectedFundOwner"
									class="select-text-option"
								>
									{{ d.display }}
								</option>
								<option value="new" class="select-text-option">
									+ Add New KiwiSaver
								</option>
							</select>
						</span>
					</ng-container>
				</div>
				<div
					class="col-lg-3 mb-2"
					[class.border-bottom]="!k.value.isNew && !last"
				>
					<ng-container *ngIf="!k.value.isNew">
						<app-chips
							[attr.disabled]="true"
							class="crt-form-control-field"
							[formControl]="k.get('fundType')"
							textboxClass="theme-crm-field"
							[textboxId]="'fundType' + i"
							textboxPlaceholder="Fund Type"
							[choices]="kft$ | async"
							[isRemoveChipsPadding]="true"
						></app-chips>
					</ng-container>
				</div>
				<div
					class="col-lg-2 mb-2"
					[class.border-bottom]="!k.value.isNew && !last"
				>
					<ng-container *ngIf="!k.value.isNew">
						<span class="w-100">
							<select
								formControlName="provider"
								[id]="'kiwisaverProvider' + i"
								placeholder="Provider"
								class="form-control crt-form-control theme-crm-field-disabled"
							>
								<option
									value=""
									selected
									[attr.disabled]="true"
									hidden
								></option>
								<option *ngFor="let d of kp$ | async" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</ng-container>
				</div>
				<div
					class="col-lg-2 mb-2"
					[class.border-bottom]="!k.value.isNew && !last"
				>
					<ng-container *ngIf="!k.value.isNew">
						<div class="dollar-icon">
							<input
								formControlName="currentBalance"
								type="text"
								[id]="'kiwisaverCurrentBalance' + i"
								class="form-control crt-form-control"
								placeholder="Current Balance"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</ng-container>
				</div>
				<div
					class="d-none d-md-block col-lg-1 text-right"
					[class.border-bottom]="!k.value.isNew && !last"
				>
					<ng-container *ngIf="!k.value.isLoading">
						<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
							<ng-container *ngIf="!k.get('isEdit')?.value">
								<button
									id="kiwisaver_editKiwiSaverButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing || !!deletingId"
									(click)="editKiwiSaver(k.get('cRTId')?.value, i)"
								>
									<i class="material-icons md-16">edit</i>
								</button>
								<button
									id="kiwisaver_deleteModalButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing || !!deletingId"
									(click)="deleteModal(i)"
								>
									<i class="material-icons md-16">delete</i>
								</button>
							</ng-container>
							<ng-container *ngIf="!!k.get('isEdit')?.value">
								<button
									id="kiwisaver_saveKiwiSaverButton_{{ i }}"
									color="primary"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="saveKiwiSaver(i)"
									[disabled]="!selectedFundOwner"
								>
									<i class="material-icons md-16">save</i>
								</button>
								<button
									id="kiwisaver_removePropertyButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="removeProperty(i)"
								>
									<i class="material-icons md-16">close</i>
								</button>
							</ng-container>
						</ng-container>
						<ng-template #viewOnly>
							<button
								id="kiwisaver_viewKiwiSaverButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="isEditing || !!deletingId"
								(click)="viewKiwiSaver(k.get('cRTId')?.value, i)"
							>
								<i class="material-icons md-16">info</i>
							</button>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="k.value.isLoading">
						<app-manual-loader-mini
							[isLoading]="k.value.isLoading"
						></app-manual-loader-mini>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="form-row my-4">
			<div class="col-5 col-lg-3">
				<ng-container *ngIf="isLoading$ | async as loading">
					<app-manual-loader-mini
						[isLoading]="loading"
					></app-manual-loader-mini>
				</ng-container>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						*ngIf="!(isLoading$ | async)"
						id="kiwisaver_addKiwiSaverButton"
						type="button"
						(click)="addKiwiSaver()"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
						[disabled]="isAddNew"
					>
						Add KiwiSaver +
					</button>
				</ng-container>
			</div>
			<div class="offset-lg-1 col-sm-6 col-lg-5"></div>
		</div>
		<div class="border-bottom mb-3"></div>
	</div>
</form>
