import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { SettingsTypes } from '@modules/kiwisaver-settings/state/kiwisaver-settings.model';
import { objectUtil } from '@util/util';
import { find, isNil, propEq } from 'ramda';
import { Observable, iif, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import {
	CrtKiwiSaverRiskProfileQuestionOutcome,
	RiskProfileOutcomesStore,
} from '../states/risk-profile/risk-profile-outcomes.store';
import { CrtKiwiSaverRiskProfileOutcomeQuery } from './risk-profile/risk-profile-outcomes.query';
import {
	CrtKiwiSaverRiskProfile,
	RiskProfileStore,
} from './risk-profile/risk-profile.store';
import { CrtKiwiSaverRiskProfileQuery } from './risk-profile/risk-profile.query';

export interface CrtKiwiSaverRiskProfileQA {
	question: string;
	answers: CrtKiwiSaverRiskProfileAnswer[];
}

export interface CrtKiwiSaverRiskProfileAnswer {
	id: number;
	answer: string;
	isDefault: boolean;
	orderNo: number;
	score: number;
	status: number;
}

@Injectable({
	providedIn: 'root',
})
export class CrtKiwiSaverRiskProfileService {
	outcomes$ = this.crtKiwiSaverRiskProfileOutcomeQuery.select();

	constructor(
		private api: ApiService,
		private riskProfileOutcomesStore: RiskProfileOutcomesStore,
		private crtKiwiSaverRiskProfileOutcomeQuery: CrtKiwiSaverRiskProfileOutcomeQuery,
		private riskProfileStore: RiskProfileStore,
		private riskProfileQuery: CrtKiwiSaverRiskProfileQuery
	) {}

	getAll(adviceProcessId: number): Observable<CrtKiwiSaverRiskProfile[]> {
		const endpoint = `crt/${adviceProcessId}/KOATRPQ`;
		return this.api.get(endpoint).pipe(
			map((result) => result as CrtKiwiSaverRiskProfile[]),
			map((result) =>
				result.map((r) => objectUtil.mapPascalCaseToCamelCase(r))
			),
			tap((result) => this.riskProfileStore.set(result)),
			catchError(() => of([]))
		);
	}

	add(riskProfile: CrtKiwiSaverRiskProfile): Observable<boolean> {
		return this.api
			.post(`crt`, objectUtil.mapCamelCaseToPascalCase(riskProfile))
			.pipe(
				tap((result) => {
					riskProfile.cRTId = +result;
					const list = this.riskProfileQuery.getAll();
					const isAlreadyOnState = find(propEq('cRTId', 0))(list);
					if (isAlreadyOnState) {
						this.riskProfileStore.update(0, riskProfile);
					} else {
						this.riskProfileStore.add(riskProfile);
					}
				}),
				map((result) => Boolean(result))
			);
	}

	update(riskProfile: CrtKiwiSaverRiskProfile): Observable<boolean> {
		return this.api
			.put(
				`crt/${riskProfile.cRTId}`,
				objectUtil.mapCamelCaseToPascalCase(riskProfile)
			)
			.pipe(
				tap(() => {
					this.riskProfileStore.update(riskProfile);
					this.riskProfileStore.update(riskProfile.cRTId, (state) => {
						return {
							...state,
							...riskProfile,
						};
					});
				}),
				map((result) => Boolean(result))
			);
	}

	upsert(riskProfile: CrtKiwiSaverRiskProfile): Observable<boolean> {
		return this.calculateOutcome(riskProfile.outcomeScore).pipe(
			map((outcome) => ({ ...riskProfile, outcome })),
			mergeMap((data: CrtKiwiSaverRiskProfile) =>
				Boolean(data?.cRTId) ? this.update(data) : this.add(data)
			),
			take(1)
		);
	}

	getQuestions(): Observable<any> {
		const endpoint = `crt/settings/0/${SettingsTypes.KOATFactFindRiskProfileQuestion}`;
		return this.api.get(endpoint).pipe(
			map((settings: any[]) => {
				return objectUtil.mapPascalCaseToCamelCase(settings);
			})
		);
	}

	saveOutcome(
		outcome: CrtKiwiSaverRiskProfileQuestionOutcome
	): Observable<boolean> {
		const endpoint = `crt/settings/${outcome.referenceId ?? 0}`;
		return this.api
			.post(endpoint, objectUtil.mapCamelCaseToPascalCase(outcome))
			.pipe(map((result) => Boolean(result)));
	}

	getOutComes(): Observable<CrtKiwiSaverRiskProfileQuestionOutcome> {
		const endpoint = `crt/settings/0/${SettingsTypes.KOATFactFindRiskProfileOutCome}`;
		return this.api.get(endpoint).pipe(
			map(
				(result) =>
					objectUtil.mapPascalCaseToCamelCase(
						result
					) as CrtKiwiSaverRiskProfileQuestionOutcome
			),
			map((data) => ({
				...data,
				outcomes: data?.outcomes?.sort((i1, i2) => +i1.score - +i2.score) || [],
			})),
			tap((state) => this.riskProfileOutcomesStore.set(state)),
			catchError(() => of(undefined))
		);
	}

	private calculateOutcome(score: number): Observable<string> {
		return this.crtKiwiSaverRiskProfileOutcomeQuery.select().pipe(
			map((result) => result.outcomes),
			map((outcomes) => {
				const DEFENSIVE_INDEX = 0;
				const AGGRESSIVE_INDEX = outcomes.length - 1;
				const outcomeIndex = outcomes.findIndex((outcome, index) => {
					if (index === DEFENSIVE_INDEX && score <= outcome.score) {
						return true;
					}
					if (index === AGGRESSIVE_INDEX && score >= outcome.score) {
						return true;
					}

					if (score === outcome.score) {
						return true;
					}

					return false;
				});
				return outcomes[outcomeIndex]?.outcome;
			})
		);
	}

	setFormValuesOnState(riskProfile: CrtKiwiSaverRiskProfile) {
		const list = this.riskProfileQuery.getAll();
		const isExisting = find(propEq('cRTId', riskProfile.cRTId))(list);
		if (isExisting) {
			this.riskProfileStore.update(riskProfile.cRTId, riskProfile);
		} else {
			this.riskProfileStore.add(riskProfile);
		}
	}
}
