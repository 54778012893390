import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
	ConfirmationCallFinalSectionSetting
} from './confirmation-call-question.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ConfirmationCallFinalSection', idKey: 'qId' })
export class ConfirmationCallFinalSectionStore extends Store<ConfirmationCallFinalSectionSetting> {
	constructor() {
		super({});
	}
}
