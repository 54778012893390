import { LRCommissionTracker } from './lrct.model';
import { StoreConfig, Store, } from '@datorama/akita';
import produce from 'immer';
import { Injectable } from "@angular/core";

/**
 * L&R Commission Tracker widget state model
 */
export interface LRCTState {
  /**
   * list of items in L&R Anniversary list
   */
  LRCommissionTracker: LRCommissionTracker;
  /**
   * error message. will show up when not empty
   */
  error: string;
  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): LRCTState {
  return {
    LRCommissionTracker: {
      CurrentFinancialYTD: 0,
      CurrentQuarter: 0,
      CurrentMonth: 0,
      CurrentPipeline: 0,
      LRLeadCount: 0,
      MortgageLeadCount: 0
    },
    error: '',
    isLoading: false
  };
}

/**
 * L&R Commission Tracker widget data store
 */
@Injectable()
@StoreConfig({
  name: 'LRCommissionTracker'
})
export class LRCTStore extends Store<LRCTState> {
  /**
   * L&R Commission Tracker store
   */
  constructor() {
    super(createInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
