<div class="d-flex justify-content-end m-2">
  <form [formGroup]="form">
    <select id="importTypeOptions" formControlName="importType" class="selectpicker" multiple
      data-selected-text-format="count > 5">
      <optgroup label="Staff">
        <option value="SDI" title="SDI">Staff</option>
        <option value="ADI" title="ADI">Adviser-AM</option>
      </optgroup>
      <optgroup label="Client">
        <option value="PCIDI" title="PCIDI">Primary Customer Client</option>
        <option value="PCCDI" title="PCCDI">Primary Customer Company</option>
        <option value="SCIDI" title="SCIDI">Secondary Customer Client</option>
        <option value="SCTDI" title="SCTDI">Secondary Customer Trust</option>
      </optgroup>
      <optgroup label="Services">
        <option value="SLDI" title="SLDI">L&R Insurance</option>
        <option value="SSDI" title="SSDI">Security</option>
        <option value="SMDI" title="SMDI">Mortgage</option>
        <option value="SFDI" title="SFDI">F&G Insurance</option>
        <option value="SKDI" title="SKDI">KiwiSaver</option>
        <option value="SIDI" title="SIDI">Investment</option>
      </optgroup>
      <optgroup label="Others">
        <option value="NDI" title="NDI">Notes</option>
        <option value="ACTDI" title="ACTDI">Activities</option>
        <option value="DDI" title="DDI">Documents</option>
        <option value="KSOADI" title="KSOADI">KiwiSaver Member Number</option>
      </optgroup>
    </select>
  </form>
  <button type="button" class="btn btn-primary mx-2" [appTheme]="{'background-color':'primarycolor'}"
    (click)="refresh()">Refresh</button>
  <app-manual-loader-mini [isLoading]="isLoading" class="d-flex align-items-center mx-0"></app-manual-loader-mini>
</div>

<div class="table-responsive my-2">
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th scope="col">Imported Date</th>
        <th scope="col">Imported By</th>
        <th scope="col">Total Row Count</th>
        <th scope="col">Success Count</th>
        <th scope="col">Failed Count</th>
        <th scope="col">Initial Upload</th>
        <th scope="col">Summary Link</th>
        <th scope="col">STATUS</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let h of (histories$ | async)">
        <td> {{h.ImportedStartDateFormatted}}</td>
        <td>
          {{h.ImportedByName}}
        </td>
        <td>
          {{h.DataRowCount}}
        </td>
        <td>
          <ng-container *ngIf="h.SuccessCount; else sc">
            {{h.SuccessCount}}
          </ng-container>
          <ng-template #sc>
            0
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="h.FailedCount; else fc">
            {{h.FailedCount}}
          </ng-container>
          <ng-template #fc>
            0
          </ng-template>
        </td>
        <td>
          <a class="tap-text-primary" [href]="h.InitialDocumentLink">DOWNLOAD</a>
        </td>
        <td>
          <a class="tap-text-primary" [href]="h.FailedSummaryLink">DOWNLOAD</a>
        </td>
        <td>
          <span *ngIf="h.Status === 'C'">COMPLETE</span>
          <span *ngIf="h.Status === 'X'">CANCELLED</span>
          <span *ngIf="h.Status === 'F' && !h.FailedDocumentLink">FAILED</span>

          <a *ngIf="h.Status === 'F' && h.FailedDocumentLink" [href]="h.FailedDocumentLink">FAILED - DOWNLOAD DOCUMENT
          </a>
        </td>
      </tr>
    </tbody>
  </table>

</div>