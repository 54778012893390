<div [ngClass]="{ 'input-group': prependText || appendText }" class="w-100">
	<div
		class="prepend-container d-flex align-items-center px-2"
		#prependContainer
	>
		<span
			class="material-icons input-group-text bg-transparent"
			*ngIf="prependText"
			>{{ prependText }}</span
		>
		<span class="material-icons" *ngIf="prependIcon">{{ prependIcon }}</span>
	</div>
	<ng-container *ngIf="type === 'numeric'; else otherTypes">
		<input
			#input
			numeric
			type="number"
			inputmode="numeric"
			[style.padding-left.px]="prependContainer.offsetWidth"
			[value]="value"
			[class]="inputClass"
			[attr.disabled]="disabled ? '' : null"
			[attr.readonly]="readonly ? '' : null"
			[attr.tabindex]="tabindex || null"
			[id]="id"
			placeholder=" "
		/>
	</ng-container>
	<ng-template #otherTypes>
		<ng-container *ngIf="type === 'decimal'; else showDefault">
			<input
				#input
				appOneDigitDecimalPointFiveNumber
				decimals="1"
				[style.padding-left.px]="prependContainer.offsetWidth"
				[value]="value"
				[type]="type"
				[class]="inputClass"
				[attr.disabled]="disabled ? '' : null"
				[attr.readonly]="readonly ? '' : null"
				[attr.tabindex]="tabindex || null"
				[id]="id"
				placeholder=" "
			/>
		</ng-container>

	</ng-template>
	<ng-template #showDefault>
		<input
			#input
			[style.padding-left.px]="prependContainer.offsetWidth || null"
			[value]="value"
			[type]="type"
			[class]="inputClass"
			[attr.disabled]="disabled ? '' : null"
			[attr.readonly]="readonly ? '' : null"
			[attr.tabindex]="tabindex || null"
			[id]="id"
			placeholder=" "
			autocomplete="off"
		/>
	</ng-template>
	<div
		class="app-input-placeholder text-muted pe-none"
		[style.padding-left.px]="prependContainer.offsetWidth"
	>
		{{ placeholder || "" }}
		<span class="text-danger fw-bold" *ngIf="required">*</span>
	</div>
	<span
		class="material-icons input-group-text bg-transparent"
		*ngIf="appendText"
		>{{ appendText }}</span
	>
</div>
