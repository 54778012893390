import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { util } from '../../../util/util';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import { FieldMetadata } from '../../dynamic-field/field-metadata.model';

@Component({
	selector: 'app-datatable-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatatableCheckboxComponent implements OnChanges, OnDestroy {
	/** destroy subject that will trigger unsubscription */
	private onDestroy$ = new Subject<void>();
	/** value of textbox */
	@Input() value: boolean | string;
	/** index number for use in ID */
	@Input() index: number;
	/** field name for use in ID */
	@Input() fieldId: string;
	/** restrictions */
	@Input() restrict: string[];
	/** if currently saving */
	@Input() isLoading: boolean;
	/** if show side control buttons */
	@Input() hasControls = true;
	/** if currently in edit mode */
	@Input() isEditing: boolean;
	/** to use on save event for now. maybe get rid later */
	@Input() metadata: FieldMetadata<unknown>;

	@Input() isEditable = true;
	/** temporary value.
	 *  To show when its not empty and in edit mode
	 */
	@Input() tempValue: string | boolean;
	/** input whether field is required */
	@Input() isRequired: boolean;
	/** input whether field is disabled */
	@Input() disabled: boolean;

	/** event for saving */
	@Output() saveEvent = new EventEmitter<FieldMetadata<unknown>>();
	/** event for cancel */
	@Output() cancelEvent = new EventEmitter<void>();
	/** event for edit */
	@Output() editEvent = new EventEmitter<void>();
	/** event for pending field changes. Used to store unsaved data. */
	@Output() fieldUpdateEvent = new EventEmitter<string>();
	/** event for pending field changes. Used to store unsaved data. */
	@Output() onChangeValue = new EventEmitter<boolean>();

	/** form control for textbox */
	field = new UntypedFormControl();
	/** subscription reference to easily unsubscribe */
	fieldSubscription: Subscription;

	constructor(private cd: ChangeDetectorRef) {}

	/** on changes trigger all change check */
	ngOnChanges(changes: SimpleChanges): void {
		this.editChanges(changes);
		this.valueChanges(changes);
		this.requiredChanges(changes);
	}
	/** unsubscribe to field subscription on destroy */
	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	/** sets up `field`.
	 * * if `tempValue` has value, set `tempValue` to `field`
	 * * else set `value` to `field`
	 * * start listening to field on edit to store unsaved data
	 * * stop listening to field on NOT edit for performance
	 */
	editChanges(changes: SimpleChanges) {
		const notChanged =
			changes.isEditing === undefined || changes.isEditing.currentValue === changes.isEditing.previousValue;
		if (notChanged) {
			return;
		}

		if (changes.isEditing.currentValue) {
			const hasTempValue = this.tempValue !== undefined;
			const resetValue = hasTempValue ? this.getBooleanValue(this.tempValue) : this.getBooleanValue(this.value);
			this.field.reset(resetValue);
			this.fieldSubscription = this.field.valueChanges
				.pipe(debounceTime(400), takeUntil(this.onDestroy$))
				.subscribe((v: string) => this.fieldUpdateEvent.emit(v));
		}
		if (!changes.isEditing.currentValue && this.fieldSubscription) {
			this.fieldSubscription.unsubscribe();
			this.fieldSubscription = undefined;
		}
	}

	/** if value changes, reset field value */
	valueChanges(changes: SimpleChanges) {
		const notChanged = changes.value === undefined || changes.value.currentValue === changes.value.previousValue;
		if (notChanged) {
			return;
		}

		const hasTempValue = this.tempValue !== undefined;
		const resetValue = hasTempValue
			? this.getBooleanValue(this.tempValue)
			: this.getBooleanValue(changes.value.currentValue);
		this.field.reset(resetValue);

		if (this.disabled) {
			this.field.disable();
		} else {
			this.field.enable();
		}
	}

	/** of required, add validators. else clear validators */
	requiredChanges(changes: SimpleChanges) {
		const notChanged =
			changes.isRequired === undefined || changes.isRequired.currentValue === changes.isRequired.previousValue;
		if (notChanged) {
			return;
		}

		if (changes.isRequired.currentValue) {
			this.field.setValidators([Validators.required, NoWhitespaceValidator]);
		} else {
			this.field.clearValidators();
		}
	}

	/** trigger edit event */
	edit() {
		this.editEvent.emit();
	}
	/** trigger cancel event */
	cancel() {
		this.cancelEvent.emit();
	}
	/** trigger save event */
	save() {
		if (this.field.valid) {
			this.saveEvent.emit(this.prapareData());
		}
	}
	/** prepare data for saving */
	private prapareData(): FieldMetadata<boolean> {
		const value = this.field.value;
		return {
			...this.metadata,
			value: value === true || `${value}`?.toLowerCase() === 'true',
		};
	}

	getBooleanValue(value: boolean | string | undefined | null): boolean {
		const valueIsEmpty = util.isNullOrEmpty(value);
		const valueIsString = typeof value === 'string';

		if (valueIsEmpty) {
			return false;
		} else if (valueIsString) {
			return (value as string)?.toLowerCase() === 'true';
		} else {
			return value === true;
		}
	}

	onChangeModel(value: boolean) {
		this.onChangeValue.emit(value);
	}
}
