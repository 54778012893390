import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	SpecialFeaturesSettingsStore,
	SpecialFeaturesSettingsState,
} from './special-features-settings.store';
import { SpecialFeaturesSettingsCode } from './special-features-settings.model';

@Injectable({ providedIn: 'root' })
export class SpecialFeaturesSettingsQuery extends Query<SpecialFeaturesSettingsState> {
	isConfirmationCallEnabled$ = this.select(
		(x) =>
			x?.specialFeaturesSettings?.includes(SpecialFeaturesSettingsCode.CC) ||
			false
	);

	isCustomerAlterationEnabled$ = this.select(
		(x) => {
			return x?.specialFeaturesSettings?.includes(SpecialFeaturesSettingsCode.CAR) ?? false
		}
	);

	constructor(protected store: SpecialFeaturesSettingsStore) {
		super(store);
	}
}
