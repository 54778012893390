<form
	[formGroup]="form"
	class="form short-term-goals-form"
	[class.submitted]="submitted"
>
	<div class="form-row mb-3">
		<div class="col-12">
			<label
				class="label-font tap-text-primary font-weight-bold"
				*ngIf="personInfo"
			>
				{{ personInfo.name }}, If you suffer from a critical illness, what would
				you want in place?
			</label>
		</div>
	</div>

	<div class="form-row">
		<div class="col-12">
			<div formArrayName="periodOfCoverList">
				<div
					class="form-row mb-1"
					*ngFor="
						let arrayItem of PeriodOfCoverList.controls;
						let pIndex = index
					"
					[formGroupName]="pIndex"
				>
					<div class="col-12 col-lg-3 mb-1 text-lg-right text-left">
						<span class="crt-custom-dropdown col-md-12 p-0">
							<select
								id="periodCoverId_{{ pIndex }}"
								class="form-control crt-form-control d-inline"
								formControlName="dropdown"
								(change)="selectPeriodCover(+pIndex)"
							>
								<option value=""></option>
								<option *ngFor="let f of APCRTTRC$ | async" [value]="f.value">
									{{ f.display }}
								</option>
							</select>
						</span>
					</div>
					<div class="col-12 col-lg-2 mb-1">
						<div class="dollar-icon">
							<input
								id="periodCoverAmountId_{{ pIndex }}"
								class="form-control crt-form-control font-weight-bold"
								type="text"
								formControlName="value"
								placeholder="Amount"
								(focusout)="onChanged()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-row">
		<div class="col-12">
			<div formArrayName="periodOfPartnerOffList">
				<div
					class="form-row mb-1"
					*ngFor="
						let arrayItem of PeriodOfPartnerOffList.controls;
						let pIndex = index
					"
					[formGroupName]="pIndex"
				>
					<div class="col-12 col-lg-3 mb-1 text-lg-right text-left">
						<input
							id="periodPartnerId_{{ pIndex }}"
							class="form-control crt-form-control"
							type="text"
							formControlName="dropdown"
							(change)="onChanged()"
						/>
					</div>
					<div class="col-12 col-lg-2 mb-1">
						<div class="dollar-icon">
							<input
								id="periodPartnerAmountId_{{ pIndex }}"
								class="form-control crt-form-control font-weight-bold"
								type="text"
								formControlName="value"
								placeholder="Amount"
								(focusout)="onChanged()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-row">
		<div class="col-12">
			<div formArrayName="extraCostsList">
				<div
					class="form-row mb-1"
					*ngFor="let arrayItem of ExtraCostsList.controls; let pIndex = index"
					[formGroupName]="pIndex"
				>
					<div class="d-lg-none text-right col-12 col-lg-2">
						<ng-container *ngIf="!isAdviceProcessEnded">
							<button
								id="deleteExtraItemBtn_{{ pIndex }}"
								type="button"
								class="icon-btn h-auto"
								*ngIf="ExtraCostsList.controls.length > 1"
								(click)="deleteExtraItem(+pIndex)"
							>
								<i class="material-icons md-20 tap-text-color-h6"> close </i>
							</button>
						</ng-container>
					</div>

					<div class="col-12 col-lg-3 mb-1 text-lg-right text-left">
						<app-select-input
							[items]="APCRTEC$ | async"
							formControlName="dropdown"
							[dropdownValue]="arrayItem.get('dropdownValue').value"
							[disabled]="isAdviceProcessEnded"
							(dropdownValueChanged)="
								arrayItem.get('dropdownValue').setValue($event)
							"
							(valueChanged)="onChanged()"
						>
						</app-select-input>
					</div>

					<div class="col-12 col-lg-2 mb-1">
						<div class="dollar-icon">
							<input
								id="extraAmountId_{{ pIndex }}"
								class="form-control crt-form-control font-weight-bold"
								type="text"
								formControlName="value"
								placeholder="Amount"
								(focusout)="onChanged()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
					<div
						class="col-12 col-lg-3 mb-1 d-none d-lg-flex d-md-none d-xs-none d-sm-none"
					>
						<ng-container *ngIf="!isAdviceProcessEnded">
							<button
								id="deleteExtraItem_{{ pIndex }}"
								type="button"
								class="icon-btn h-auto d-flex align-items"
								*ngIf="ExtraCostsList.controls.length > 1"
								(click)="deleteExtraItem(+pIndex)"
							>
								<i class="material-icons md-20 tap-text-color-h6"> close </i>
							</button>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="form-row my-4">
				<div class="col-12">
					<div class="form-row">
						<div class="col-auto px-2">
							<ng-container *ngIf="!isAdviceProcessEnded">
								<button
									id="addExtraItemBtn"
									type="button"
									class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
									(click)="AddExpense()"
								>
									Add Expense +
								</button>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<div class="form-row px-2">
				<div class="col-12 col-lg-5 border-bottom my-2"></div>
			</div>

			<div class="form-row px-3 px-lg-0">
				<div class="col-12">
					<div class="form-row mb-1">
						<div
							class="col-2 col-lg-3 mb-1 text-left text-lg-right d-flex align-items-center justify-content-end"
						>
							<label class="font-weight-bold tap-text-primary pt-1"
								>Total Required</label
							>
						</div>
						<div class="col-2 mb-1">
							<div class="dollar-icon">
								<input
									id="totalRequiredId"
									class="form-control crt-form-control font-weight-bold"
									type="text"
									formControlName="totalRequired"
									placeholder="Amount"
									(focusout)="onChanged()"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
