import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html'
})
export class ClientComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  // Client
  PCIF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('PCIF');
  // Company
  PCCF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('PCCF');
  // People
  SCIF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('SCIF');
  // Trust
  SCTF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('SCTF');

  dropdownValues = null;
  @Output() clientEvent = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, private dropdownValueQuery: DropdownValueQuery) { }

  public clientForm = this.fb.group({
    contactStatus: ['', Validators.required],
    primaryType: ['', Validators.required]
  });

  ngOnInit() {
    this.initData();

    this.clientForm
      .get('primaryType')
      .valueChanges.pipe(
        mergeMap(type => {
          switch (type) {
            case 'PCIDI':
              return this.PCIF$;
            case 'PCCDI':
              return this.PCCF$;
            case 'SCIDI':
              return this.SCIF$;
            case 'SCTDI':
              return this.SCTF$;
            default:
              return of<ViewDisplayValue[]>([]);
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(x => {
        this.dropdownValues = x;
      });

    /**
     * Listens to form value changes
     * validates if contactstatus and primaryType has been selected
     */
    this.clientForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      if (x.contactStatus && x.primaryType) {
        // Emit contact status and primary type
        this.clientEvent.emit({
          contactStatus: x.contactStatus,
          primaryType: x.primaryType,
          dropdownValues: this.dropdownValues
        });
      }
    });
  }

  initData() {
    this.dropdownValues = null;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
