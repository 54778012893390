import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { LRGTStore } from './lrgt.store';
import { GoalTrack } from './lrgt.model';
import { applyTransaction } from '@datorama/akita';

/**
 * LR Goal track service for fetching
 */
@Injectable()
export class LRGTService {
  /**
   * partial url endpoint
   */
  private readonly endpoint = 'widgets?type=LRGT';

  constructor(
    private api: ApiService,
    private store: LRGTStore
  ) { }


  /**
   * Fetch and store Refix mortgage data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetLRGoalTrack(adviserIdList: string[]): void {
    this.store.setLoading(true);
    this.api.post<GoalTrack>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update({GoalTrack: res}),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
