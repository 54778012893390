import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageUsersComponent } from './manage-users.component';
import { ManageUsersListComponent } from './manage-users-list/manage-users-list.component';
import { ManagePdTrackingComponent } from './manage-pd-tracking/manage-pd-tracking.component';
import { SharedModule } from '@shared/shared.module';
import { MatDataTableScrollModule } from '@shared/components/mat-data-table-scroll/mat-data-table-scroll.module';
import { ManageUsersListSelectionComponent } from './manage-users-list/manage-users-list-selection/manage-users-list-selection.component';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { InputModule } from '@shared/components/input/input.module';
import { InputChipsModule } from '@shared/components/input-chips/input-chips.module';
import { SimpleDataTableModule } from '@shared/components/simple-data-table/simple-data-table.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		MatDataTableScrollModule,
		InputModule,
		InputChipsModule,
		SortableModule.forRoot(),
		SimpleDataTableModule,
	],
	declarations: [
		ManageUsersComponent,
		ManageUsersListComponent,
		ManagePdTrackingComponent,
		ManageUsersListSelectionComponent,
	],
	exports: [ManageUsersComponent],
})
export class ManageUsersModule {}
