import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { AdviceProcessPageCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { SidebarStatus } from '../../../_shared/models/sidebar.model';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { CrtMortgageStore } from '../../state/crt-mortgage.store';
import { AssetService } from './state/asset/asset.service';
import { KiwiSaverService } from './state/kiwiSaver/kiwiSaver.service';
import { LiabilityService } from './state/liability/liability.service';
import { Mortgage } from './state/mortgage/mortgage.model';
import { MortgageService } from './state/mortgage/mortgage.service';
import { OtherService } from './state/other/other.service';
import { PropertyService } from './state/property/property.service';

@Injectable({
	providedIn: 'root',
})
export class AssetsAndLiabilitiesService extends CrtMortgageService {
	properties$ = this.propertyService.properties$;
	kiwiSavers$ = this.kiwiSaverService.kiwiSavers$;
	mortgages$ = this.mortgageService.mortgages$;
	assets$ = this.assetService.assets$;
	liabilities$ = this.liabilityService.liabilities$;
	others$ = this.otherService.others$;

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		private propertyService: PropertyService,
		private kiwiSaverService: KiwiSaverService,
		private mortgageService: MortgageService,
		private liabilityService: LiabilityService,
		private assetService: AssetService,
		private otherService: OtherService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	setSidebarColor() {
		return combineLatest([
			this.properties$,
			this.kiwiSavers$,
			this.mortgages$,
			this.assets$,
			this.liabilities$,
			this.others$,
			this.query.mortApPageStarted$,
		]).pipe(
			map(
				([
					properties,
					kiwiSavers,
					mortgages,
					assets,
					liabilities,
					others,
					pageStarted,
				]) => {
					// Unopened
					if (
						!pageStarted?.includes(AdviceProcessPageCodes.AssetsLiabilities)
					) {
						return SidebarStatus.Unopened;
					}

					// Incomplete
					if (
						pageStarted?.includes(AdviceProcessPageCodes.AssetsLiabilities) &&
						((mortgages?.length > 0 &&
							mortgages?.some(
								(mortgage: Mortgage) =>
									mortgage.mortgageFrequency === null ||
									mortgage.mortgageFrequency === ''
							)) ||
							(properties?.length > 0 &&
								properties?.some(
									(property) =>
										(!!property?.rentalIncome &&
											(!property?.rentalIncomeFrequency ||
												property?.rentalIncomeFrequency === '')) ||
										(!!property?.boarderIncome &&
											(!property?.boarderIncomeFrequency ||
												property?.boarderIncomeFrequency === '')) ||
										!property?.propertyOwner ||
										property?.propertyOwner === ''
								)) ||
							(others?.length > 0 &&
								!others[0]?.hasPersonalGuarantees &&
								!others[0]?.hasFinancialResponsibilities))
					) {
						return SidebarStatus.Incomplete;
					}

					// Completed
					if (
						pageStarted?.includes(AdviceProcessPageCodes.AssetsLiabilities)
						// others[0]?.hasPersonalGuarantees &&
						// others[0]?.hasFinancialResponsibilities
					) {
						return SidebarStatus.Completed;
					}
				}
			),
			tap((status) => {
				this.setSideSidebarStatus('assetsAndLiabilities', false, status);
			})
		);
	}
}
