import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { sum, multiply } from 'ramda';
import { iif, of } from 'rxjs';
import {
	filter,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { numUtil, objectUtil } from 'src/app/util/util';
import { Application } from './../../state/application.model';
import { Security } from './state/security.model';
import { SecurityQuery } from './state/security.query';
import { SecurityService } from './state/security.service';
import { ComponentBase } from 'src/app/core/base/component-base';
import { computeUtil } from '../../../../_shared/calculations/funding-required';
import { computeMoatAppUtil } from '../../../../_shared/calculations/moat-application';
import { PropertyPurchaseQuery } from '../funding-required/property-purchase/state/property-purchase.query';
import { SecurityMapper } from './state/security.mapper';
import { PropertyPurchaseDetailsState } from '../funding-required/property-purchase/state/property-purchase.model';
import { PropertyState } from '../../../client-sop/assets-and-liabilities/state/property/property.model';
import { PropertyService } from '../../../client-sop/assets-and-liabilities/state/property/property.service';
import { CashDepositMapper } from '../funding-required/cash-deposit/state/cash-deposit.mapper';
import { CashDepositQuery } from '../funding-required/cash-deposit/state/cash-deposit.query';
import { LoggerService } from 'src/app/core/logger/logger.service';

@Component({
	selector: 'app-security',
	templateUrl: './security.component.html',
	styleUrls: ['./security.component.scss'],
})
export class SecurityComponent
	extends ComponentBase
	implements OnInit, OnChanges, OnDestroy
{
	@Input() parentCRTId: number;
	@Input() application: Application;
	@Input() adviceProcessId: number;
	@Input() isComplete: boolean;
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}>();
	@Input() fundingRequiredTotals: any;

	securities$ = this.service.securities$;
	ffProperties$ = this.propertyService.properties$;
	propertyPurchase$ = this.propertyPurchaseQuery.properties$;
	propertyPurchase: PropertyPurchaseDetailsState[];
	ffProperties: PropertyState[];
	isLoading$ = this.query.selectLoading();

	form: UntypedFormGroup;
	isLoading: boolean;
	submitted = false;

	constructor(
		private service: SecurityService,
		private query: SecurityQuery,
		private fb: UntypedFormBuilder,
		private propertyPurchaseQuery: PropertyPurchaseQuery,
		private propertyService: PropertyService,
		private cashDepositQuery: CashDepositQuery,
		private loggerService: LoggerService
	) {
		super();
		this.buildForm();
	}

	get BankWillTake() {
		return this.form.get('bankWillTake') as UntypedFormArray;
	}

	get TotalSecurityValue() {
		return this.form.get('totalSecurityValue');
	}

	get ProposedTotalDebt() {
		return this.form.get('proposedTotalDebt');
	}

	get ProposedLVR() {
		return this.form.get('proposedLVR');
	}

	get ScaledLVR() {
		return this.form.get('scaledLVR');
	}

	get isNoSecurity() {
		const securities = this.BankWillTake?.value?.filter((a) => !!a?.isTick);
		return securities?.length === 0;
	}

	ngOnInit(): void {
		this.prepareData();
	}

	buildForm() {
		this.form = this.fb.group({
			bankWillTake: this.fb.array([]),
			totalSecurityValue: this.fb.control(''),
			proposedTotalDebt: this.fb.control(''),
			proposedLVR: this.fb.control(''),
			scaledLVR: this.fb.control(''),
		});
	}

	securityAdd() {
		this.service
			.get(this.parentCRTId, this.adviceProcessId)
			.pipe(
				withLatestFrom(this.service.securities$),
				mergeMap(([x, securities]) =>
					iif(
						() => !!securities && securities?.length > 0,
						of(x),
						this.service.add({
							adviceProcessId: this.adviceProcessId,
							parentCrtId: this.application?.cRTId,
						})
					)
				),
				take(1)
			)
			.subscribe();
	}

	prepareData() {
		this.securities$
			.pipe(
				tap(() => (this.isLoading = true)),
				filter((x) => !!x && x?.length > 0),
				map((x) => x[0]),
				tap((x) => {
					this.clearFormArray(this.BankWillTake);
					x?.bankWillTake?.forEach((b) =>
						this.BankWillTake.push(
							this.fb.group({
								aLPropertiesCrtId: [b?.aLPropertiesCrtId],
								propertyAddress: [b?.propertyAddress],
								amount: [b?.amount],
								isTick: [b?.isTick],
								fromFactFind: [b?.fromFactFind],
							})
						)
					);
				}),
				withLatestFrom(this.ffProperties$, this.propertyPurchase$),
				tap(([x, ffProperties, propertyPurchase]) => {
					this.ffProperties = ffProperties;
					this.propertyPurchase = propertyPurchase;
					this.recomputeTotals(x);
				}),
				tap(() => (this.isLoading = false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	getTotalSecurityValue() {
		const bwt = this.BankWillTake.getRawValue();
		const result = sum(
			bwt?.filter((a) => !!a?.isTick)?.map((b) => +b?.amount)
		);
		return +numUtil.formatToNumCurrency(result);
	}

	getProposedTotalDebt() {
		const cashDepositData = CashDepositMapper.mapToView(
			objectUtil.mapPascalCaseToCamelCase(this.cashDepositQuery.getActive())
		);
		const lendingContinuing =
			this.fundingRequiredTotals?.lendingContinuingTotal || 0;
		const newLendingRequired = cashDepositData?.newLendingRequired || 0;

		return sum([lendingContinuing, newLendingRequired]);
	}

	getProposedLVR() {
		// Proposed LVR = (Proposed Total Debt / Total Security Value) * 100
		const totalSecurityValue = this.getTotalSecurityValue();
		const proposedTotalDebt = this.getProposedTotalDebt();
		if (totalSecurityValue > 0) {
			let result = multiply(proposedTotalDebt / totalSecurityValue, 100);
			result = Math.ceil(result);
			return +result?.toFixed(0);
		} else {
			return 0;
		}
	}

	getScaledLVR() {
		const securities = SecurityMapper.getSecurityDetails(
			this.BankWillTake.getRawValue(),
			this.propertyPurchase,
			this.ffProperties
		);
		const totalDebt = this.getProposedTotalDebt() || 0;
		const totalMaxLoanAmount =
			computeMoatAppUtil.totalMaxLoanAmount(securities) || 0;

		return computeUtil.scaledLVR(totalDebt, totalMaxLoanAmount) || 0;
	}

	prepareFormValue() {
		const data = this.form.getRawValue();
		return {
			...data,
			adviceProcessId: this.adviceProcessId,
			sectionCode: AdviceProcessSectionCodes.Security,
		} as Security;
	}

	recomputeTotals(data?) {
		const form = data ?? this.form.getRawValue();
		const totals = {
			totalSecurityValue: sum(
				form?.bankWillTake?.filter((a) => !!a?.isTick)?.map((b) => +b?.amount)
			),
			proposedTotalDebt: this.getProposedTotalDebt(),
			proposedLVR: this.getProposedLVR(),
			scaledLVR: this.getScaledLVR(),
		};
		this.form.patchValue(totals);
	}

	formErrors(data) {
		return of(data).pipe(
			map((x) => {
				const securities = x?.bankWillTake?.filter((a) => !!a?.isTick);
				const errors = [];
				if (securities?.length === 0) {
					errors.push('At least one Security is required');
				}
				return errors;
			}),
			take(1)
		);
	}

	save(
		isNext: boolean,
		checkValidations: boolean = true,
		redirect: boolean = true
	) {
		if (this.form.invalid) {
			return;
		}
		const data = this.prepareFormValue();
		this.formErrors(data)
			.pipe(
				filter((errors) => {
					if (checkValidations && errors?.length > 0) {
						if (this.submitted) {
							// If already submitted, allow to proceed regardless of requirements
							this.submitted = false;
							return true;
						} else {
							// If first time submission and incomplete form requirements
							this.submitted = true;
							this.saveCompleted.emit({ isSuccess: false, isNext, redirect });
							this.loggerService.MultipleWarnings(errors);
							return false;
						}
					} else {
						this.submitted = false;
						return true;
					}
				}),
				mergeMap(() => of(data)),
				withLatestFrom(this.securities$),
				mergeMap(([req, sec]) =>
					this.service.update({
						...req,
						cRTId: sec[0]?.cRTId,
						parentCrtId: this.parentCRTId,
					})
				),
				// mergeMap(([req, sec]) =>
				// 	iif(
				// 		() => !!sec && sec?.length > 0,
				// 		this.service.update({
				// 			...req,
				// 			cRTId: sec[0]?.cRTId,
				// 			parentCrtId: this.parentCRTId,
				// 		}),
				// 		this.service.add({
				// 			...req,
				// 			cRTId: null,
				// 			parentCrtId: this.parentCRTId,
				// 		})
				// 	)
				// ),
				tap(
					(next) => {
						this.saveCompleted.emit({ isSuccess: true, isNext, redirect });
					},
					(err) =>
						this.saveCompleted.emit({
							isSuccess: false,
							isNext: false,
							redirect,
						})
				),
				take(1)
			)
			.subscribe();
	}

	private clearFormArray(formArray: UntypedFormArray) {
		while (formArray.length > 0) {
			formArray.removeAt(0);
		}
		return formArray;
	}

	ngOnChanges() {
		this.recomputeTotals();
	}

	ngOnDestroy(): void {
		this.service.clear();
		super.dispose();
	}
}
