import * as R from 'ramda';
import MomentUtil from 'src/app/util/moment.util';
import { MortgageDetails, MortgageInfo, MortgageState } from './mortgage.model';
export class MortgageMapper {
	static mapToView(v: MortgageInfo) {
		return !!v
			? {
					lender: v.Lender,
					borrowingEntities: v.Borrower ? JSON.parse(v.Borrower) : [],
					security: v.Security ? JSON.parse(v.Security) : [],
					loanValue: v.LoanValue,
					actualRepayment: v.LoanRepayment,
					mortgageFrequency: v.MortgageFrequency,
					interestRate: v.InterestRate,
					loanType: v.LoanType,
					fixedPeriodEnd: MomentUtil.formatDateToMoment(v.FixedPeriodEnd),
					loanTerm: v.LoanTerm,
					purpose: v.Purpose,
					customerServiceID: v?.CustomerServiceID,
					loanLimit: v.LoanLimit,
					loanRepaymentDate: MomentUtil.formatDateToMoment(v.LoanRepaymentDate),
			  }
			: {};
	}

	static mapToMortgageDetails(m, adviceProcessId) {
		return {
			AdviceProcessId: adviceProcessId,
			SectionCode: 'FAM',
			Lender: m.lender,
			LoanValue: Math.abs(+m.loanValue),
			InterestRate: +m?.interestRate,
			FixedPeriodEnd: MomentUtil.formatDateToServerDate(m.fixedPeriodEnd),
			Borrower: !!m?.borrowingEntities
				? typeof m?.borrowingEntities === 'string'
					? m?.borrowingEntities
					: JSON.stringify(m?.borrowingEntities)
				: !!m?.borrower
				? typeof m?.borrower === 'string'
					? m?.borrower
					: JSON.stringify(m?.borrower)
				: null,
			LoanRepayment: !R.isNil(m?.actualRepayment)
				? +m?.actualRepayment
				: !R.isNil(m?.loanRepayment)
				? +m?.loanRepayment
				: null,
			MortgageFrequency: m.mortgageFrequency,
			LoanType: m.loanType,
			LoanTerm: m.loanTerm,
			Security: !!m.security
				? typeof m.security === 'string'
					? m?.security
					: JSON.stringify(m.security)
				: null,
			Purpose: m.purpose,
			CRTId: m?.cRTId ?? null,
			CustomerServiceID: m?.customerServiceID ?? null,
			Status: 1,
			LoanLimit: m.loanLimit,
			LoanRepaymentDate: MomentUtil.formatDateToServerDate(m.loanRepaymentDate),
			PendingAdviserApproval: m?.pendingAdviserApproval ?? false,
		};
	}

	static mapToUpsert(m: MortgageDetails, cRTId): MortgageState {
		return {
			adviceProcessId: m?.AdviceProcessId,
			cRTId,
			fixedUntil: m.FixedPeriodEnd, // Date
			interestRate: m.InterestRate,
			lender: m.Lender,
			loanValue: m.LoanValue,
			loanRepayment: m.LoanRepayment,
			mortgageFrequency: m.MortgageFrequency,
			customerServiceID: m?.CustomerServiceID ?? null,
			loanLimit: m.LoanLimit,
			loanRepaymentDate: m.LoanRepaymentDate,
		};
	}
}
