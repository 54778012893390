<div
	class="container-fluid manage-users-search px-4 mb-4"
	[class.is-expanded]="isSearchFormExpanded"
>
	<div class="row d-flex justify-content-between mt-3">
		<div class="col-auto align-self-center manage-users-search__header">
			<h1 class="d-inline">Users</h1>
			<h6 class="mb-0 px-0 px-md-2 d-inline d-block d-md-inline-block">
				({{ resultCount }} Results)
			</h6>
		</div>

		<div class="col-auto align-self-center ml-auto manage-users-search__cta">
			<div class="form-row align-items-center">
				<div class="col-auto" *appRestrictTo="['BAU']">
					<button
						id="addUserBtn"
						type="button"
						class="btn ui-btn-white px-4"
						[routerLink]="UserAddLink"
					>
						+ Create New User
					</button>
				</div>
				<div class="col-auto">
					<button
						id="toggleFilterBtn"
						type="button"
						title="Filter"
						class="icon-btn font-weight-bold"
						(click)="collapse.Toggle()"
					>
						<i class="material-icons md-20 tap-text-color-h6 pt-1">
							filter_list
						</i>
					</button>
				</div>
				<div class="col-auto">
					<button
						id="resetFilterBtn"
						type="button"
						title="Reset"
						class="icon-btn"
						(click)="resetSearchFilter()"
					>
						<i class="fas fa-undo fa-14 tap-text-color-h6 pt-1"></i>
					</button>
				</div>
				<div class="col-auto">
					<button
						id="searchBtn"
						type="button"
						class="btn ui-btn-white px-4"
						(click)="filterSearch()"
						[class.disabled]="!!(isSearching$ | async)"
					>
						Search
					</button>
				</div>
				<div
					class="col-auto"
					(mouseenter)="showPopover()"
					[placement]="'bottom'"
					[popover]="(exportStatus$ | async) === 3 ? popTemplate : null"
					containerClass="popover-info-container"
					triggers=""
				>
					<button
						id="exportBtn"
						type="button"
						title="Export"
						class="icon-btn"
						(click)="export()"
						[disabled]="(exportStatus$ | async) === 3"
						data-toggle="modal"
						data-target="#lrExportModal"
					>
						<i class="fas fa-download fa-14 tap-text-color-h6 pt-1"></i>
					</button>
				</div>
				<div class="col-auto">
					<app-manual-loader-mini [isLoading]="isSearching$ | async">
					</app-manual-loader-mini>
				</div>
				<div class="col-auto float-right">
					<span class="position-relative d-inline-block">
						<div *ngIf="openColumnSelection" class="mr-3 pop-column-selection">
							<app-manage-users-list-selection
								[columns]="tableColumnConfig"
								(toggleColumns)="onToggleColumns($event)"
								(closeColumnSelection)="closeSelection()"
								class="ui-search-settings"
							></app-manage-users-list-selection>
						</div>
						<button
							id="settingsBtn"
							type="button"
							title="Settings"
							class="icon-btn"
							(click)="openColumnPopup()"
						>
							<i class="material-icons md-20 column-settings-icon pt-1">
								settings
							</i>
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div
		app-collapse
		#collapse
		[initialExpandedState]="false"
		class="search-collapse manage-users-search__form my-3"
		[formGroup]="formGroup"
		(isExpandState)="searchFormToggle($event)"
	>
		<div class="row mb-2">
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="firstName">First Name</label>
					</div>
					<div class="col-12">
						<app-input
							inputClassname="ui-form-control"
							formControlName="FirstName"
							id="firstName"
						></app-input>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="lastName">Last Name</label>
					</div>
					<div class="col-12">
						<app-input
							inputClassname="ui-form-control"
							formControlName="LastName"
							id="lastName"
						></app-input>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="securityGroups">Security Group</label>
					</div>
					<div class="col-12">
						<app-input-chips
							id="securityGroups"
							formControlName="SecurityGroups"
							class="ui-form-control"
							valueProperty="SecurityGroupCode"
							displayProperty="SecurityGroupName"
							dropdownPosition="bottom"
							[items$]="secGroups$"
						></app-input-chips>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="userStatuses">User Status</label>
					</div>
					<div class="col-12">
						<app-input-chips
							id="userStatuses"
							formControlName="UserStatuses"
							class="ui-form-control"
							valueProperty="option"
							displayProperty="name"
							dropdownPosition="bottom"
							[items$]="userStatus$"
						></app-input-chips>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="emailAddress">Email Address</label>
					</div>
					<div class="col-12">
						<app-input
							inputClassname="ui-form-control"
							id="emailAddress"
							formControlName="EmailAddress"
						></app-input>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="correspondenceEmail">Correspondence Address</label>
					</div>
					<div class="col-12">
						<app-input
							inputClassname="ui-form-control"
							id="correspondenceEmail"
							formControlName="CorrespondenceEmail"
						></app-input>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="startDateMin">Start Date</label>
					</div>
					<div class="col-12">
						<app-date-input
							class="ui-form-control"
							formControlName="StartDateMin"
							textboxId="startDateMin"
						></app-date-input>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">&nbsp;</div>
					<div class="col-12">
						<app-date-input
							class="ui-form-control"
							formControlName="StartDateMax"
							textboxId="startDateMax"
						></app-date-input>
					</div>
				</div>
			</div>
		</div>
		<div class="row pb-4">
			<div class="col-12 col-md-3"></div>
			<div class="col-12 col-md-3"></div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">
						<label for="lastLoginMin">Last Login</label>
					</div>
					<div class="col-12">
						<app-date-input
							class="ui-form-control"
							formControlName="LastLoginMin"
							textboxId="lastLoginMin"
						></app-date-input>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="row align-items-center mb-1">
					<div class="col-12">&nbsp;</div>
					<div class="col-12">
						<app-date-input
							class="ui-form-control"
							formControlName="LastLoginMax"
							textboxId="lastLoginMax"
						></app-date-input>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="row manage-users-search__table px-3"
		[class.is-expanded]="openColumnSelection"
	>
		<div class="col-12 px-0">
			<app-mat-data-table-scroll
				[tableColumnConfig]="tableColumnConfig"
				(dragColumn)="onDragColumn($event)"
				(sortColumn)="onSortColumn($event)"
				(resizeColumn)="onResizeColumn($event)"
				[CustomDataSource]="CustomDataSource"
				[isLoading]="isSearching$ | async"
				[loadDataFn$]="fetchUsersFn$"
				[searchFilter$]="searchFilter$"
				[itemSize]="VIEWPORT_TABLE_ROW_HEIGHT"
			></app-mat-data-table-scroll>
		</div>
	</div>
</div>

<ng-template #popTemplate>
	<div class="popover-info">
		<p>Export currently in progress, please wait until its completion</p>
	</div>
</ng-template>
