<div class="field">
  <div [class]="cellClass" #longText>
    <a
      *ngIf="route; else textTemplate"
      class="m-auto"
      [ngClass]="{ clickable: route }"
      [routerLink]="route"
      (click)="onClick()"
      id="{{fieldId}}Link_{{index}}"
    >
      {{ value }}
    </a>
  </div>
</div>
<ng-template #textTemplate>
  <a class="m-auto">
    {{ value }}
  </a>
</ng-template>
