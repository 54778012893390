<div class="d-flex align-items-center my-2">
	<span
		class="material-icons nav-icon"
		(click)="previous($event)"
		[ngClass]="{ 'disabled': ngxPagination?.page === 1 }"
		>arrow_left</span
	>
	<ng-container *ngIf="showFirstPageNumber">
		<a class="page-link page-first" href (click)="navigateByPage(1, $event)">1</a>
	</ng-container>
	<pagination
		#pagination
		(pageChanged)="pageChanged($event)"
		[totalItems]="recordCount"
		[itemsPerPage]="pageSize"
		[maxSize]="maxSize"
		[boundaryLinks]="true"
		[customPreviousTemplate]="prevTemplate"
		[customNextTemplate]="nextTemplate"
		[customPageTemplate]="pageTemplate"
	>
	</pagination>
	<ng-container
		*ngIf="showLastPageNumber"
	>
		<a
			class="page-link page-last"
			href
			(click)="navigateByPage(ngxPagination.totalPages, $event)"
			>{{ ngxPagination.totalPages }}</a
		>
	</ng-container>
	<span
		class="material-icons nav-icon"
		(click)="next($event)"
		[ngClass]="{ 'disabled': ngxPagination?.page ===  ngxPagination?.totalPages }"
		>arrow_right</span
	>
</div>

<ng-template
	#pageTemplate
	let-page
	let-disabled="disabled"
	let-currentPage="currentPage"
>
	<span [ngClass]="{ 'disabled': page.text === '...'}">{{ page.text }}</span>
</ng-template>

<ng-template
	#nextTemplate
	let-disabled="disabled"
	let-currentPage="currentPage"
>
	<ng-container *ngIf="!!showLastPageNumber"> ... </ng-container>
</ng-template>

<ng-template
	#prevTemplate
	let-disabled="disabled"
	let-currentPage="currentPage"
>
	<ng-container *ngIf="!!showFirstPageNumber"> ... </ng-container>
</ng-template>
