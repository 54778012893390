import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Resolve } from '@angular/router';
import { BLStaffsService } from '../../../../domain/bl-staff/bl-staffs.service';

@Injectable()
export class BlStaffsResolver implements Resolve<boolean> {

  resolve(): Observable<boolean> {
    const staffs$ = this.blStaffService.addList();
    return forkJoin([staffs$]).pipe(mapTo(true));
  }

  constructor(private blStaffService: BLStaffsService) { }
}
