import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailSettingsService } from './email-settings/state/email-settings.service';
import { EmailStatusService } from './email-status/state/email-status.service';
import { EmailHistoryService } from './email-history/state/email-history.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
	selector: 'app-emails',
	templateUrl: './emails.component.html',
	styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {

	@ViewChild('tabset') tabset: TabsetComponent;
	public activeRow: [] = null;

	constructor(private eSettingService: EmailSettingsService,
		           private eStatusService: EmailStatusService,
		           private eHistoryService: EmailHistoryService) { }

	ngOnInit(): void {
	}

	transferToStatus = (row) => {
		this.activeRow = row;
		this.tabset.tabs[1].active = true;
	}

	onSelect(event) {
		this.eSettingService.reloadData();
		this.eStatusService.reloadData();
		this.eHistoryService.reloadData();
	}

}
