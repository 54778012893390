import { RiskAnalysisMedical, RiskAnalysisMedicalState } from './medical.model';

export class RiskAnalysisMedicalMapper {
    /** Mapping for view */
    static mapToView(data: RiskAnalysisMedical) {
        if (!data) {
            return { ...data };
        }
        const val = {
            ...data,
        };
        return val;
    }

    /**
     * Map to update
     */
    public static mapToUpsert(data: RiskAnalysisMedicalState): RiskAnalysisMedicalState {
        return {
            ...data,
            sectionCode: 'RAM',
        };
    }
}
