import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../../../../core/base/api.service';
import { FundingRequiredLoanStore } from './loan-repaid.store';
import { FundingRequiredLoanQuery } from './loan-repaid.query';
import { FundingRequiredLoan } from './loan-repaid.model';
import { DropdownValueQuery } from '../../../../../../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../../../../../../util/util';
import { of } from 'rxjs';
import { catchError, finalize, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { AdviceProcessSectionCodes } from '../../../../../../../../../../shared/models/advice-process/advice-process.model';
import { CrtMortgageQuery } from '../../../../../../state/crt-mortgage.query';
import { applyTransaction } from '@datorama/akita';

import { LiabilityService } from 'src/app/modules/crm/crt-page/crt-mortgage/client-sop/assets-and-liabilities/state/liability/liability.service';
import { ApplicationService } from '../../../../../state/application.service';
import { LoanRefinanceQuery } from '../../../loan-refinance/state/loan-refinance.query';

@Injectable()
export class FundingRequiredLoanService {
	SU$ = this.ddQuery.orderedChoices$('SU');
	SV1$ = this.ddQuery.orderedChoices$('SV1');
	ST$ = this.ddQuery.orderedChoices$('ST');
	STI$ = this.ddQuery.orderedChoices$('STI');
	APCRTF$ = this.ddQuery.orderedChoices$('APCRTF');
	isLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		protected store: FundingRequiredLoanStore,
		protected query: FundingRequiredLoanQuery,
		private ddQuery: DropdownValueQuery,
		protected crtMortgageQuery: CrtMortgageQuery,
		private liabilityService: LiabilityService,
		private appService: ApplicationService,
		private refinanceQuery: LoanRefinanceQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(applicationId: number, exclude?: [number]) {	
		this.store.setLoading(true);
		this.store.update({ isLoadedLoans: false });
		return this.api
			.get<FundingRequiredLoan[]>(
				`crt/${AdviceProcessSectionCodes.Application}/${applicationId}/sub-section/${AdviceProcessSectionCodes.FundingRequiredLoan}`
			)
			.pipe(
				map((x) => (x || []).map(objectUtil.mapPascalCaseToCamelCase)),
				tap((x) => {
					this.store.set(x || []);
				}),
				finalize(() => {
					this.store.update({ isLoadedLoans: true });
					this.store.setLoading(false)
				}),
				catchError((error) => of(undefined))
			);
	}

	add(data) {
		this.store.setLoading(true);
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.FundingRequiredLoan,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					const loans: FundingRequiredLoan[] = [
						...this.query.getValue().loans,
						{
							parentCrtId: body.ParentCrtId,
							linkedCRTId: body.LinkedCRTId,
							isTicked: body.IsTicked,
							liability: body.Liability,
							loanLimit: body.LoanLimit,
							loanBalance: body.LoanBalance,
							interestRate: body.InterestRate,
							borrower: body.Borrower,
							lender: body.Lender,
							loanRepayment: body.LoanRepayment,
							mortgageFrequency: body.MortgageFrequency,
							paidInFull: body.PaidInFull,
							loanType: body.LoanType,
							security: body.Security,
							adviceProcessId: body.AdviceProcessId,
							sectionCode: body.SectionCode,
							cRTId: +x,
              sourceType: body.SourceType
						},
					].filter((p) => p.cRTId && p.cRTId !== 0);
					this.store.add(loans);
				});
			}),
			tap(() =>
				this.liabilityService
					.get(body.AdviceProcessId)
					.pipe(take(1))
					.subscribe()
			),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	update(data) {
		this.store.setLoading(true);
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.FundingRequiredLoan,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.upsert(body.CRTId, {
						parentCrtId: data.ParentCrtId,
						linkedCRTId: data.LinkedCRTId,
						isTicked: data.IsTicked,
						liability: data.Liability,
						loanLimit: data.LoanLimit,
						loanBalance: data.LoanBalance,
						interestRate: data.InterestRate,
						borrower: data.Borrower,
						lender: data.Lender,
						loanRepayment: data.LoanRepayment,
						mortgageFrequency: data.MortgageFrequency,
						paidInFull: data.PaidInFull,
						loanType: data.LoanType,
						security: data.Security,
						cRTId: data.CRTId,
						adviceProcessId: data.AdviceProcessId,
						sectionCode: data.SectionCode,
					});
				});
			}),
			tap(() => this.appService.setIsUpdatedLoans(true)),
			finalize(() => {
				this.store.setLoading(false)
				this.appService.setIsUpdatedLoans(false);
			}),
			catchError(() => of(undefined))
		);
	}

	reUpdateStore() {
		const list: any = this.query.getAll();
		of(list).pipe(
			withLatestFrom(
				this.refinanceQuery.loanRefinance$
			),
			tap(([fundingRequired, refinance]) => {
				let list = fundingRequired;
				if (refinance && refinance.length) {
					const ids = refinance
						?.filter((loan) => loan.isRefinance)
						?.map((loan) => loan.linkedCRTId)
						?.filter(Boolean); // Remove 0 values
					list = list?.map((loan) => {
						return {
							...loan,
							hidden: ids?.includes(loan.linkedCRTId)
						}
					});
				}
				applyTransaction(() => this.store.set(list))
			}),
			take(1)
		).subscribe();
	}

	updateStore(ids: number[]) {
		const newIds = ids.filter((a) => a);
		const list: any = this.query.getAll();
		const idsToUpdate = list.filter(loan => newIds.includes(loan.linkedCRTId)).map(loan => loan.cRTId);
		this.store.upsert(idsToUpdate, { hidden: true });
		const idsToUnhide = list.filter(loan => !newIds.includes(loan.linkedCRTId)).map(loan => loan.cRTId);
		this.store.upsert(idsToUnhide, { hidden: false });
	}
}
