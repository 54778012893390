import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { LRGTFYStore } from './lrgtfy.store';
import { GoalTrack } from './lrgtfy.model';
import { applyTransaction } from '@datorama/akita';

/**
 * LR Goal track service for fetching
 */
@Injectable()
export class LRGTFYService {
  /**
   * partial url endpoint
   */
  private readonly endpoint = 'widgets?type=LRGTFY';

  constructor(private api: ApiService, private store: LRGTFYStore) {}

  /**
   * Fetch and store L&R Goal Track Financial Year data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetLRGoalTrackFY(adviserIdList: string[]): void {
    this.store.setLoading(true);
    this.api
      .post<GoalTrack>(this.endpoint, {
        Advisers: adviserIdList
      })
      .subscribe(
        res => this.store.update({ GoalTrack: res }),
        err =>
          applyTransaction(() => {
            this.store.setError('Error fetching data!');
            this.store.setLoading(false);
          }),
        () => this.store.setLoading(false)
      );
  }
}
