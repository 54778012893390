<div class="row tap-container service-tabs crt-settings pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<ng-container *ngIf="showTabs$ | async">
				<tab heading="Confirmation Questions">
					<app-confirmation-call-question>
					</app-confirmation-call-question>
				</tab>
			</ng-container>

			<ng-container *ngIf="showTabs$ | async">
				<tab heading="Send to Issue">
					<app-confirmation-call-email-setting>
					</app-confirmation-call-email-setting>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
