<ng-container *ngIf="appearance === 'v3'">
	<!-- To use this design, need to add "info-modal-v3" on modalService.show class -->
	<div
		class="modal-header text-light tap-bg-primary d-flex justify-content-between"
	>
		<h4 class="modal-title font-weight-normal">
			{{ header }}
		</h4>
		<div class="clrearfix d-flex">
			<button
				type="button"
				class="close"
				aria-label="Close"
				(click)="close()"
				id="closeBtn"
			>
				<span class="material-symbols-outlined">close</span>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="modal-body__inner clearfix">
			<p [innerHTML]="message"></p>
		</div>
	</div>
	<div class="modal-footer pt-0 pb-4">
		<ng-container *ngIf="hasOkay">
			<button
				type="button"
				class="btn ui-btn-gray mr-3 px-4"
				(click)="close()"
				id="okayBtn"
			>
				Okay
			</button>
		</ng-container>
	</div>
</ng-container>

<ng-container *ngIf="!appearance || appearance === 'default'">
	<button
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="close()"
		id="closeBtn"
	>
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h1 class="modal-title">
			{{ header }}
		</h1>
	</div>
	<div class="align-text modal-body text-center" style="word-break: break-word">
		<p [innerHTML]="message"></p>
	</div>
	<div class="modal-footer">
		<ng-container *ngIf="hasOkay">
			<button
				type="button"
				class="tap-btn tap-text-h4 tap-btn--default mx-2"
				(click)="close()"
				id="okayBtn"
			>
				Okay
			</button>
		</ng-container>
	</div>
</ng-container>
