import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocalService } from '../services/local.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private localService: LocalService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const isToken = this.authenticationService.isLoggedIn;
		const user = this.authenticationService.user;

		const code = route.paramMap.get('companyCode');

		if (isToken && user.IsLoggedIn && user.IsAuthorized) {
			// logged in so return true
			return true;
		} else {
			// not logged in so redirect to login page with the return url
			// this.router.navigate(['/login'], {
			// 	queryParams: { returnUrl: state.url },
			// });

			// save return url to local
			if (state.url !== '/') {
				localStorage.setItem('returnUrl', state.url);
			}

			if (!!code) {
				this.router.navigate([`/${code}`]);
				this.localService.setValue('code', code);
			} else {
				this.router.navigate(['/login']);
				this.localService.remove('code');
			}

			return false;
		}
	}
}
