import { AbstractControl } from '@angular/forms';

export function NoWhitespaceValidator(control: AbstractControl) {
    if (control.value) {
        const isWhitespace = control.value.length > 0 && (control.value?.toString() || '')?.trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }
    return null;
}
