import * as moment from 'moment';
import * as numeral from 'numeral';
import * as R from 'ramda';
import { Observable } from 'rxjs';

const arrAppend =
	<T>(item: T) =>
	(arr: T[]) =>
		[...arr, item];
const arrRemove =
	<T>(fn: (item: T) => boolean) =>
	(arr: T[]) =>
		arr?.filter((x) => !fn(x));
const concat =
	<T>(arr2: T[]) =>
	(arr: T[]) =>
		[...arr, ...arr2];
const flatMap = <T>(array: T[]) =>
	array?.reduce(
		(acc: T[], curr: T | T[]) =>
			Array.isArray(curr) ? concat(curr)(acc) : arrAppend(curr)(acc),
		[]
	);

export const arrUtil = {
	arrAppend,
	arrRemove,
	concat,
	flatMap,
};

// export arrUtil;
const isString = (str: string | any): str is string => typeof str === typeof '';
const stringIsNotEmpty = (str: string) =>
	isString(str) && str?.trim().length > 0;
const escapeRegExp = (str) => {
	return str?.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
};
const replaceAll = (str: string, find: string, replace: string) =>
	str?.replace(new RegExp(escapeRegExp(find), 'g'), replace);
const safeReplaceAll = R.curry(
	(str: string | RegExp, replaceWith: string, replace: string) =>
		R.pipe(R.defaultTo(''), R.replace(str, replaceWith))(replace)
);
const safeTrim = (str: string) => R.pipe(R.defaultTo(''), R.trim)(str);
const removeSpace = safeReplaceAll(/\s/g, '');
const stringSortFn = (a: string, b: string) => {
	const strA = R.trim(R.toLower(R.defaultTo('', a)));
	const strB = R.trim(R.toLower(R.defaultTo('', b)));
	if (strA < strB) {
		return -1;
	} else if (strA > strB) {
		return 1;
	} else {
		return 0;
	}
};
const firstLetterToLower = (str: string) => {
	return str.charAt(0).toLowerCase().concat(str.substring(1));
};

/**
 * Removes extra space in the middle, making it 1 space between
 * @param str string
 * @returns string
 */
const safeTrimExtraSpace = (str: string) =>
	R.pipe(R.defaultTo(''), R.replace(/\s+/g, ' '), R.trim)(str);

const isEmail = (email: string) => {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email)?.toLowerCase());
};

export const strUtil = {
	stringIsNotEmpty,
	isString,
	replaceAll,
	safeReplaceAll,
	removeSpace,
	safeTrim,
	safeTrimExtraSpace,
	stringSortFn,
	isEmail,
	firstLetterToLower,
};

const isNumber = (num: any): num is number => typeof num === typeof 1;
const isPositive = (num: number): boolean => num > 0;
const isFinite = (num: number): boolean =>
	num !== Number.POSITIVE_INFINITY && num !== Number.NEGATIVE_INFINITY;
const isValid = (num: number | any): boolean => !isNaN(num) && isFinite(num); // && isPositive(num);
const isValidPositive = (num: number | any): boolean =>
	isValid(num) && isPositive(num);
const toNumber = (num: any): number => +num;
const toValidNumber = (num: any): number => (isValid(num) ? toNumber(num) : 0);
const numberSortFn = (a: number, b: number) => {
	if (a < b) {
		return -1;
	} else if (a > b) {
		return 1;
	} else {
		return 0;
	}
};
const formatToPercent = (num: number) => numeral(num).format('0[.]00%');
const formatToPercentNoDecimal = (num: number) => numeral(num).format('0,0%');
const formatToCurrency = (num: number) => numeral(num).format('$0,0.00');
const formatToCurrency2 = (num: number) => numeral(num).format('$ 0,0.00');
const formatWholeNumNoDecimal = (num: number) =>
	numeral(Math.round(num)).format('$0,0');
const formatWholeNumNoDecimalNoSymbol = (num: number) =>
	numeral(Math.round(num)).format('0,0');
const formatToNumCurrency = (num: number) => numeral(num).format('00.00');
const formatToNumCurrency4Decimal = (num: number) =>
	numeral(num).format('00.0000');
const formatToCount = (num: number) => numeral(num).format('0,0');
const formatToCountDecimal = (num: number) => numeral(num).format('0,0.0');
const formatToNum2Decimal = (num: number) => numeral(num).format('0.00');

/**
 * native js formatter
 * we used this since numeraljs return NaN when number is decimal exponential notation
 */
const intlCurrencyFormatterFactory = (options?: Intl.NumberFormatOptions) =>
	new Intl.NumberFormat('en-NZ', {
		...options,
		...{
			style: 'currency',
			currency: 'NZD',
		},
	});

const intlCurrencyFormatterFactoryCompact = (
	options?: Intl.NumberFormatOptions
) =>
	new Intl.NumberFormat('en-NZ', {
		...options,
		...{
			style: 'currency',
			currency: 'NZD',
			notation: 'compact',
		},
	});

const currencyToNumber = (cur: string) => {
	if (typeof cur === 'number') {
		return cur;
	}
	if (!cur) {
		return null;
	}
	// @ts-ignore-next
	return +cur.replaceAll(',', '');
}

export const numUtil = {
	isNumber,
	isPositive,
	isFinite,
	isValid,
	isValidPositive,
	currencyToNumber,
	toNumber,
	toValidNumber,
	numberSortFn,
	formatToPercent,
	formatToPercentNoDecimal,
	formatToCurrency,
	formatToCurrency2,
	formatWholeNumNoDecimal,
	formatWholeNumNoDecimalNoSymbol,
	formatToCount,
	formatToCountDecimal,
	formatToNumCurrency,
	formatToNumCurrency4Decimal,
	intlCurrencyFormatterFactory,
	intlCurrencyFormatterFactoryCompact,
	formatToNum2Decimal
};

const isNullOrEmpty = (value) => R.isEmpty(value) || R.isNil(value);
const debounce = (fn, time) => {
	let timeout;
	return function (...args) {
		const functionCall = () => fn.apply(this, args);

		clearTimeout(timeout);
		timeout = setTimeout(functionCall, time);
	};
};
const createLookupFromList = <T>(
	keyFinder: (obj: T) => any,
	valueFinder: (obj: T) => any,
	list: T[]
): { [key: string]: any } => {
	return list?.reduce(
		(prev, curr) => ({ ...prev, [keyFinder(curr)]: valueFinder(curr) }),
		{}
	);
};

const tryCatchParse = (data) => {
	try {
		JSON.parse(data);
	} catch (e) {
		return false;
	}
	return true;
};

const tryCatchParseJoin = (data) => {
	try {
		JSON.parse(data)?.join(', ');
	} catch (e) {
		return false;
	}
	return true;
};

const tryParseJson = (data: string) => {
	try {
		return JSON.parse(data);
	} catch (e) {
		return null;
	}
};

const tryStringifyJson = (json: object): string | null => {
	try {
		return JSON.stringify(json);
	} catch (e) {
		return null;
	}
};

const removeEmptyObjsFromArr = (arr) => {
	// Remove Objects from Array if fields are Empty or Null or 0
	let data = [...arr];
	data =
		data?.reduce((acc, cur) => {
			const result = !Object.values(cur)?.every(
				(o) => o === null || o === '' || o === 0
			);
			if (result) {
				return [...acc, cur];
			}
			return acc;
		}, []) || [];
	return data;
};

export const util = {
	debounce,
	isNullOrEmpty,
	createLookupFromList,
	tryCatchParse,
	tryCatchParseJoin,
	tryParseJson,
	tryStringifyJson,
	removeEmptyObjsFromArr,
};

/**
 * Map PascalCase object into camelCase format
 * In reality, it only lower case the first letter
 * @param o Object with PascalCase format
 * @returns Object with camelCase format
 */
const mapPascalCaseToCamelCase = (o) => {
	if (!o) {
		return null;
	}

	const checkPascalArray = (data) => {
		return Array.from(
			data?.map((y) => {
				if (typeof y !== 'string' && typeof y !== 'number') {
					if (checkAray(y)) {
						return checkPascalArray(y);
					}
					return mapPascalCaseToCamelCase(y);
				}
				return y;
			})
		);
	};

	return Object.keys(o)?.reduce(
		(c, k) => (
			(c[k?.replace(/^\w/, (x) => x?.toLowerCase())] =
				o[k] && checkObject(o[k])
					? checkAray(o[k])
						? checkPascalArray(o[k])
						: mapPascalCaseToCamelCase(o[k])
					: o[k]),
			c
		),
		{}
	);
};

/**
 * Map camelCase object into PascalCase format
 * In reality, it only uppercase case the first letter
 * @param o Object with camelCase format
 * @returns Object with PascalCase format
 */
const mapCamelCaseToPascalCase = (o) => {
	if (!o) {
		return null;
	}
	return Object.keys(o)?.reduce(
		(c, k) => (
			(c[k?.replace(/^\w/, (x) => x?.toUpperCase())] =
				o[k] && checkObject(o[k])
					? checkAray(o[k])
						? Array.from(
								o[k]?.map((y) =>
									Array.isArray(y) ? mapCamelCaseToPascalCase(y) : y
								)
						  )
						: mapCamelCaseToPascalCase(o[k])
					: o[k]),
			c
		),
		{}
	);
};

function checkObject(p) {
	return p && typeof p === 'object';
}

function checkAray(p) {
	return p && typeof p === 'object' && Array.isArray(p);
}

export const objectUtil = {
	mapPascalCaseToCamelCase,
	mapCamelCaseToPascalCase,
};

const convertToBase64 = (html, reader = new FileReader()): Observable<string> =>
	new Observable((obs) => {
		const data = convertToBlob(html);
		reader.onload = () =>
			obs.next((reader.result as string)?.replace(/^data:(.*,)?/, ''));
		reader.onloadend = () => obs.complete();

		return reader.readAsDataURL(data);
	});

const simpleConvertToBase64 = (file) =>
	new Observable((obs) => {
		const reader = new FileReader();
		reader.onload = () => obs.next(reader.result);
		reader.onloadend = () => obs.complete();
		return reader.readAsDataURL(file);
	});

/**
 * Convert string to blob
 * @param content stringified html
 * @returns blob
 */
const convertToBlob = (content: string = '') => {
	const blob = new Blob([content], {
		type: 'text/plain',
	});
	return blob;
};

const ConvertBlobToText = (blob) => {
	let file = new Blob([blob], {type: 'application/json'});
	return file.text();
}

const imageFilenameToPDF = (file: File): string => {
	if (file.type.startsWith('image/')) {
		const filenameArr = file.name.split('.');
		// if image doesn't have a file extension
		if (filenameArr.length === 1) {
			return file.name + '.pdf';
		}
		const fileExt = filenameArr[filenameArr.length - 1];
		return file.name.replace(fileExt, 'pdf');
	}
	return file.name;
};

const toBase64 = (
	file: File
): Observable<{ content: string; filename: string }> => {
	return new Observable((obs) => {
		const reader = new FileReader();
		reader.onload = () =>
			obs.next({
				content: (reader.result as string).split(',')[1],
				filename: file.name,
			});
		reader.onloadend = () => obs.complete();
		return reader.readAsDataURL(file);
	});
};

/**
 * Convert blob to html/text
 * @param blob text file that contains the html
 * @param reader optional new FileReader()
 * @returns html/text
 */
const convertToText = (
	blob: Blob,
	reader = new FileReader()
): Observable<string> =>
	new Observable((obs) => {
		const data = blob;
		reader.onload = () => obs.next(reader.result as string);
		reader.onloadend = () => obs.complete();

		return reader.readAsText(data);
	});

const base64toBlobPdf = (base64Data: string) => {
		const sliceSize = 1024;
		const byteCharacters = atob(base64Data);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);

		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);

			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: "application/pdf" });
	}

const blobToBase64 = (blob: Blob): Observable<string> => {
	const reader = new FileReader();
	return new Observable((obs) => {
		reader.onload = () => obs.next(reader.result as string);
		reader.onloadend = () => obs.complete();
		return reader.readAsDataURL(blob);
	});
}

const htmlToText = (html: string): string => {
	const container = document.createElement('div') as HTMLDivElement;
	container.style.cssText = `
		position: fixed;
		z-index: -22;
	`;
	document.body.appendChild(container);
	container.innerHTML = html;
	const innerText = container.innerText;
	container.remove();
	return innerText;
}

export const convertUtil = {
	convertToBase64,
	convertToBlob,
	convertToText,
	ConvertBlobToText,
	simpleConvertToBase64,
	imageFilenameToPDF,
	base64toBlobPdf,
	toBase64,
	blobToBase64,
	htmlToText,
};

/**
 * if (0 or 1 day)
 * 0 day
 * 1 day
 * if (more than 1 day but less than 365 days)
 * n days
 * if (equal to 365 days)
 * 1 year
 * if (more than 365 days, get the decimal point)
 * ex if 720 days, that’s 720/365 = 1.97 years
 */
const calcStatusClock = (clock) => {
	if (!clock && R.isNil(clock)) {
		return '';
	}

	if (!clock || clock === 0) {
		return `${+clock} days`;
	}

	if (clock === 1) {
		return `${+clock} day`;
	}

	if (clock > 1 && clock < 365) {
		return `${clock} days`;
	}

	if (clock === 365) {
		return `1 year`;
	}

	if (clock > 365) {
		const a = (+clock / 365)?.toString();
		const b = (+a)?.toFixed(2);

		return +b > 1 ? `${+b} years` : `${+b} year`;
	}
};

export const computeAgeBasedOnBirthdate = (birthDate) => {
	const age = moment().diff(birthDate, 'years');

	return age > 0 ? age : 0;
};

export const computeUtil = {
	calcStatusClock,
};

const getFileSizeKb = (file) => {
	const size = atob(file)?.length || 0;
	return formatBytes(size, 'KB');
};

export type fileSizeUnit = 'KB' | 'MB' | 'GB';

const sizePerUnit = {
	KB: '1',
	MB: '2',
	GB: '3',
};

const formatBytes = (
	bytes,
	unitToConvert?: fileSizeUnit,
	decimals = 2,
	suffix = false
) => {
	if (bytes === 0) {
		return suffix ? '0 B' : '0';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const unit = unitToConvert || 'KB';
	const u = sizePerUnit[unit] || 0;
	const result = parseFloat((bytes / Math.pow(+k, +u)).toFixed(dm));

	return suffix ? `${result} ${unit}` : result;
};
/**
 * Formats filename to remove space and other special characters except . - _
 * @param string
 * @returns string
 */
const formatFileName = (file:string)=>{
	return file.replace(/[^\w\.\-\_]/gi,'');
}

export const fileUtil = {
	getFileSizeKb,
	formatBytes,
	formatFileName,
};

export const addMonthsToDateWithFormat = (date: Date, months: number) => {
	const formatter = new Intl.DateTimeFormat('en-CA'); // formats yyyy/mm/dd

	const dt = date.setMonth(date.getMonth() + months);

	return formatter.format(dt);
}
