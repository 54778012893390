import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { omit, either, isNil, isEmpty } from 'ramda';
import { concat, forkJoin, from, Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	map,
	mergeMap,
	switchMap,
	tap,
	reduce,
	mapTo,
	finalize,
} from 'rxjs/operators';
import {
	CurrentInsurance,
	CurrentInsuranceState,
	PolicyDocumentsListState,
} from '../../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { objectUtil } from '../../../../../util/util';
import { ApiService } from '../../../../../core/base/api.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { BusinessService } from 'src/app/core/business/business.service';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { DocumentTypes } from 'src/app/shared/models/documents/document.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';

@Injectable()
export class CurrentInsuranceService
	extends ClientReviewTemplateService
	implements Resolve<boolean>
{
	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	setIsCompany(isCompany) {
		this.store.setIsCompany(isCompany);
	}

	getCurrentInsurance(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		this.store.setCurrentInsuranceIsLoading(true);
		return this.api.get<CurrentInsurance[]>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? // tslint:disable-next-line: no-angle-bracket-type-assertion
						  <CurrentInsuranceState[]>(
								x.map(objectUtil.mapPascalCaseToCamelCase)
						  )
						: [];
					this.store.setCurrentInsurance(state);
				})
			),
			finalize(() => this.store.setCurrentInsuranceIsLoading(false)),
			catchError(() => of(undefined))
		);
	}

	addCurrentInsurance(
		currentInsurance: CurrentInsuranceState,
		adviceProcessId?: number
	) {
		const newCurrentInsurance = Object.assign(
			{},
			omit(
				['policyDocumentFormData', 'policyDocumentsName', 'lifeAssured'],
				currentInsurance
			)
		);
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(newCurrentInsurance);
		body.SectionCode = 'FCL';
		body.AdviceProcessId = adviceProcessId;

		return this.api.post3<number>(endpoint, omit(['LifeAssured'], body)).pipe(
			tap((id) =>
				applyTransaction(() => {
					const ci = this.query.getValue().currentInsurances
						? this.query.getValue().currentInsurances
						: [];

					const data = [
						...ci,
						{
							...currentInsurance,
							cRTId: id,
						},
					]?.filter((x) => x.cRTId && x.cRTId !== 0);
					this.store.setCurrentInsurance(data);
				})
			)
		);
	}

	updateCurrentInsurance(currentInsurance: CurrentInsuranceState) {
		const endpoint = `crt/${currentInsurance.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(currentInsurance);
		body.SectionCode = AdviceProcessSectionCodes.LR;
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<string>(endpoint, omit(['LifeAssured'], body)).pipe(
			tap((id) =>
				applyTransaction(() => {
					const data = this.query.getValue().currentInsurances?.map((y) =>
						y.cRTId === currentInsurance.cRTId
							? {
									...currentInsurance,
							  }
							: y
					);
					this.store.setCurrentInsurance(data);
				})
			)
		);
	}

	updatePaymentFrequency(frequency: string) {
		const apID = this.query.getValue().adviceProcessId;
		const endpoint = `crt/sync/fact-find/${apID}/current-insurance/payment-frequency/${frequency}`;
		return this.api
			.post<string | any>(endpoint)
			.pipe(
				mergeMap(() =>
					this.getCurrentInsurance(apID, AdviceProcessSectionCodes.LR)
				)
			);
	}

	uploadDocument(currentInsurance: CurrentInsuranceState, crtId?: number, customerId?: number) {
		const uploadDocs: any[] = [];
		for (const data of currentInsurance.policyDocumentFormData.entries()) {
			uploadDocs.push(data[1]);
		}

		const first$ = of({
			ReferenceId: crtId,
			Document: '',
			FileName: uploadDocs ? uploadDocs[0].name : '',
			Type: DocumentTypes.Upload,
		});

		const documentIds = [];

		return first$.pipe(
			switchMap(() =>
				concat(
					first$,
					from(uploadDocs).pipe(
						mergeMap(
							(x) => this.convertToBase64(x),
							(o, i) => [o, i]
						),
						map(([o, i]) => {
							return {
                ReferenceId: crtId,
                Document: i ? i.split(',')[1] : '',
                FileName: o.name,
                Type: DocumentTypes.Upload,
                DocumentType: ServicesCodes.LR,
                DocumentTypeCode: ServicesCodes.LR,
                // DocumentTypeCode: DocumentTypes.AdviceProcess,
                CustomerId: customerId,
							};
						}),
						concatMap((req2) => this.api.post('documents', req2))
					)
				)
			),
			reduce((acc, v) => documentIds.push(v)),
			map((ids) =>
				applyTransaction(() => {
					const data: CurrentInsuranceState[] = [
						...this.query.getValue().currentInsurances,
					];
					data?.forEach((i: CurrentInsuranceState) => {
						if (i.cRTId === crtId) {
							const docs = [];
							documentIds?.forEach((a, index) => {
								docs?.push({
									referenceId: a,
									value: uploadDocs[index].name,
								});
							});
							i.policyDocumentsName = currentInsurance.policyDocumentsName;
							// tslint:disable-next-line: max-line-length
							const filteredList: PolicyDocumentsListState[] =
								i.policyDocumentsList?.filter(
									(value) =>
										currentInsurance.policyDocumentsList.findIndex(
											(doc) => doc.referenceId === value.referenceId
										) >= 0
								);
							const list =
								i.policyDocumentsList && i.policyDocumentsList.length > 0
									? [...filteredList, ...docs]
									: docs;
							i.policyDocuments = JSON.stringify(list);
							i.policyDocumentsList = list;
						}
					});
					this.store.setCurrentInsurance(data);
					return ids;
				})
			)
		);
	}

	deleteCurrentInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.currentInsurances.filter((y) => y.cRTId !== id);
					this.store.setCurrentInsurance(data);

					if (either(isNil, isEmpty)(data)) {
						this.setCurrentInsuranceNext(false);
					}
				});
			})
		);
	}

	convertToBase64 = (file, reader = new FileReader()) =>
		new Observable((obs) => {
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(file);
		});

	downloadLink(referenceId) {
		return this.api.get<string>(`documents/download/${referenceId}`);
	}

	setCurrentInsuranceNext(b: boolean) {
		applyTransaction(() => {
			this.store.setCurrentInsuranceNext(b);
		});
	}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		const currentInsurance$ = this.getCurrentInsurance(
			this.query.getValue().adviceProcessId,
			AdviceProcessSectionCodes.LR
		);
		return forkJoin([currentInsurance$]).pipe(mapTo(true));
	}
}
