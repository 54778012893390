import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { LoanStructure } from './loan-structure.model';

export interface LoanStructuretState extends EntityState<LoanStructure> {
	loanStructures: LoanStructure[];
}

@Injectable()
@StoreConfig({ name: 'applicationLoanStructure', idKey: 'cRTId' })
export class LoanStructureStore extends EntityStore<LoanStructuretState> {
	constructor() {
		super({});
	}
}
