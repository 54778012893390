import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TlMoatSettingsComponent } from './tl-moat-settings.component';
import { ServicingCalculatorsComponent } from './servicing-calculators/servicing-calculators.component';
import { ServicingBlankTemplatesComponent } from './servicing-calculators/servicing-blank-templates/servicing-blank-templates.component';
import { ServicingIntegratedTemplatesComponent } from './servicing-calculators/servicing-integrated-templates/servicing-integrated-templates.component';
import { TlMoatSettingsRoutingModule } from './tl-moat-settings-routing.module';

@NgModule({
	imports: [CommonModule, TlMoatSettingsRoutingModule, SharedModule],
	declarations: [
		TlMoatSettingsComponent,
		ServicingCalculatorsComponent,
		ServicingBlankTemplatesComponent,
		ServicingIntegratedTemplatesComponent,
	],
})
export class TlMoatSettingsModule {}
