import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { Fields, getRequiredWarning } from 'src/app/shared/error-message/error-message';
import { RouteService } from '../../../../core/config/route.service';
import { BusinessMapper } from '../../../../shared/models/business-profile/business/business.mapper';
import { PrimaryCustomerCompanyState } from '../../../../shared/models/business-profile/business/business.model';
import { BusinessProfileGroupComponent } from '../business-profile-group/business-profile-group.component';
import { FormCompanyComponent } from '../forms/form-company/form-company.component';
import { BusinessProfileService } from '../states/business-profile.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { Location } from '@angular/common';
import { EmailDuplicateService } from '@core/services/email-duplicate.service';

@Component({
  selector: 'app-business-profile-add',
  templateUrl: './business-profile-add.component.html',
  styleUrls: ['./business-profile-add.component.scss']
})
export class BusinessProfileAddComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  advisers$ = this.service.adviserChoices$
	allActiveStaffs$ = this.service.allActiveStaffs$;
	altAdviserChoices$ = this.service.altAdviserChoices$;
  leadGenChoices$ = this.service.leadGenChoices$;
  leadTypeChoices$ = this.service.PCLT$;
  leadOriginChoices$ = this.service.PCLE$;
  adviserGenChoices$ = this.service.adviserChoices$;
  allStaffChoices$ = this.service.allStaffChoices$;
	allStaff$ = this.blStaffQuery.activeStaffs$;
	businessConfig$ = this.businessConfigQuery.businessConfig$;

  PCT$ = this.service.PCT$;
  PCE$ = this.service.PCE$;
  PCPC$ = this.service.PCPC$;
  PCLE$ = this.service.PCLE$;
  PCLT$ = this.service.PCLT$;
  PCR$ = this.service.PCR$;
  LS$ = this.service.LS$;
  SAS$ = this.service.SAS$;

  isLead$ = this.route.data.pipe(map(x => x.isLead));

  @ViewChild(FormCompanyComponent) formCompany: FormCompanyComponent;
  @ViewChild(BusinessProfileGroupComponent) bpGroupComponent: BusinessProfileGroupComponent;

  constructor(
    private service: BusinessProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private routeService: RouteService,
    private loggerService: LoggerService,
		private businessConfigQuery: BusinessConfigQuery,
		private blStaffQuery: BLStaffsQuery,
    private emailDupService: EmailDuplicateService,
    private location:Location,
  ) { }

  ngOnInit() { }

  addNewCompany(formGroup) {
    if (!this.formCompany.form.valid) {
      if (!this.formCompany.prepareFormValue().primarContact) {
        this.loggerService.Warning({}, getRequiredWarning(Fields.PrimaryContact));
        return;
      }
    } else {
      this.bpGroupComponent.toggleSaving(true);
      const company = {
        ...formGroup,
        ...this.formCompany.prepareFormValue()
      };
      const primaryCompany = BusinessMapper.mapToUpsert(company) as PrimaryCustomerCompanyState;
      const declineCb  = ()=>{
        this.location.back();
      }
      const addCompany$ = this.service.addCompany(primaryCompany)
        .pipe(
          catchError((err)=>of(err)),
          switchMap((res)=>{
            if(res.hasOwnProperty('DuplicateEmail')){
              const ulMessage = res['DuplicateEmail'];
              const confirmCb = () => {
                primaryCompany['saveAnyway'] = true;
                return addCompany$;
              }
              this.emailDupService.displayDuplicateDialog(ulMessage, confirmCb, declineCb);
            }
            return of(res) 
          }),
          tap((id)=>{
            if(!id.hasOwnProperty('DuplicateEmail')){
              const route = this.routeService.businessView(+id);
              this.router.navigate(route);
            }
          }),
        );
      addCompany$.pipe(takeUntil(this.onDestroy$)).subscribe();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
