import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isNil, isEmpty } from 'ramda';
import { combineLatest, iif, Observable, Observer, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { ConfirmModalComponent } from '../../../../shared/modal/confirm-modal/confirm-modal.component';
import {
	getContentWithMergeTags,
	normalizeHTMLSymbols,
	removeMtWrappers,
} from '../../../../shared/converter/content-merge-tags';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ScopeOfServiceMapper } from '../../../../shared/models/client-review-template/scope-of-service/scope-of-service.mapper';
import { ScopeOfServiceState } from '../../../../shared/models/client-review-template/scope-of-service/scope-of-service.model';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { CrtMergeTagsService } from '../states/merge-tags/crt-merge-tags.service';
import { ScopeOfServiceService } from '../states/scope-of-service/scope-of-service.service';
import { LoatDocumentService } from '../states/document/loat-document.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import {
	AdviceProcessDocumentField,
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	ServiceAdviceProcessState,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { DocumentTypes } from 'src/app/shared/models/documents/document.model';
import { convertUtil } from 'src/app/util/util';
import { CrtDocumentService } from '../../crt-page/_shared/service/crt-document.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
declare var $: any;

@Component({
	selector: 'app-scope-of-services',
	templateUrl: './scope-of-services.component.html',
	styleUrls: ['./scope-of-services.component.scss'],
})
export class ScopeOfServicesComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	@ViewChild('sosDocument') sosDocument: ElementRef;

	scopeOfService$ = this.query.scopeOfService$;
	sosDefault$ = this.query.sosDefault$;
	adviceProcessId = this.query.getValue().adviceProcessId;
	adviceProcess$ = this.query.adviceProcess$;

	crtData: ScopeOfServiceState;
	defaultSetting: ScopeOfServiceState;
	mergeTags: MergeTagState[];
	cRTId: number;

	form: UntypedFormGroup;
	isLoading = true;
	isSaving = false;
	showLifeInsurance: boolean | number;
	showCriticalIllness: boolean | number;
	showTPDInsurance: boolean | number;
	showDisabilityInsurance: boolean | number;
	showMedicalInsurance: boolean | number;
	showBusinessRiskInsurance: boolean | number;
	showKiwiSaver: boolean | number;
	showHomeCarAndContentsInsurance: boolean | number;
	showResidentialMortgageOrLending: boolean | number;
	showOther: boolean | number;

	constructor(
		private query: ClientReviewTemplateQuery,
		private fb: UntypedFormBuilder,
		private service: ScopeOfServiceService,
		private mtService: CrtMergeTagsService,
		private modalService: BsModalService,
		private loatDocumentService: LoatDocumentService,
		private loggerService: LoggerService,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private crtDocService: CrtDocumentService
	) {
		this.buildForm();
	}

	get LifeInsurance() {
		return this.form.get('lifeInsurance');
	}
	get CriticalIllness() {
		return this.form.get('criticalIllness');
	}
	get TPDInsurance() {
		return this.form.get('tPDInsurance');
	}
	get DisabilityInsurance() {
		return this.form.get('disabilityInsurance');
	}
	get MedicalInsurance() {
		return this.form.get('medicalInsurance');
	}
	get BusinessRiskInsurance() {
		return this.form.get('businessRiskInsurance');
	}
	get KiwiSaver() {
		return this.form.get('kiwiSaver');
	}
	get HomeCarAndContentsInsurance() {
		return this.form.get('homeCarAndContentsInsurance');
	}
	get ResidentialMortgageOrLending() {
		return this.form.get('residentialMortgageOrLending');
	}
	get Other() {
		return this.form.get('other');
	}

	ngOnInit(): void {
		combineLatest([
			this.scopeOfService$,
			this.sosDefault$,
			this.mtService.mergeTags$,
		])
			.pipe(
				takeUntil(this.onDestroy$),
				tap(([, , mt]) => {
					this.mergeTags = mt;
				}),
				filter(([crt, settings, mt]) => {
					isEmpty(settings) || isNil(settings)
						? (this.isLoading = false)
						: (this.isLoading = true);
					return !isNil(crt) && !isNil(settings) && !isNil(mt);
				}),
				tap(([crt, settings]) => {
					this.defaultSetting = settings;
					this.cRTId = crt?.cRTId;
				}),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe(([crt]) => this.loadSos(crt));
	}

	loadSos(crtData?: ScopeOfServiceState) {
		combineLatest([of(crtData), of(this.defaultSetting), of(this.mergeTags)])
			.pipe(
				takeUntil(this.onDestroy$),
				tap(() => (this.isLoading = true)),
				concatMap(([crt, settings, mt]) => this.getSettings(crt, settings, mt)),
				tap(([crt, settings]) => this.setupServices(crt, settings)),
				map(([crt, settings]) =>
					ScopeOfServiceMapper.mapToViewCrt(crt, settings)
				),
				map((x) => ({ ...x, cRTId: this.cRTId })),
				map((x) => this.updateMergeTagsOnContent(x)),
				tap((x) => (this.crtData = x)),
				tap((x) => this.form.reset(x)),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe((x: ScopeOfServiceState) => {
				if (!this.cRTId || isNil(crtData)) {
					// trigger if
					// !this.cRTId : first visit of page
					// R.isNil(crt) : null crt means reload template
					this.isLoading = false;
					this.onChange();
				}
			});
	}

	getSettings = (crt: ScopeOfServiceState, settings: ScopeOfServiceState, mt: Array<MergeTagState>) =>
		of(crt).pipe(
			concatMap((val) =>
				iif(() => isNil(val), this.service.getSosDefault(), of(settings))
			),
			map((data) => [crt, data, mt]),
			take(1)
		);

	updateMergeTagsOnContent(data: ScopeOfServiceState) {
		const obj = {
			...data,
			intro: this.convertMergeTags(data?.intro || ''),
			lifeInsuranceDetails: normalizeHTMLSymbols(this.convertMergeTags(
				data?.lifeInsuranceDetails || '',
				true
			)),
			criticalIllnessDetails: normalizeHTMLSymbols(this.convertMergeTags(
				data?.criticalIllnessDetails || '',
				true
			)),
			tPDInsuranceDetails: normalizeHTMLSymbols(this.convertMergeTags(
				data?.tPDInsuranceDetails || '',
				true
			)),
			disabilityInsuranceDetails: normalizeHTMLSymbols(this.convertMergeTags(
				data?.disabilityInsuranceDetails || '',
				true
			)),
			medicalInsuranceDetails: normalizeHTMLSymbols(this.convertMergeTags(
				data?.medicalInsuranceDetails || '',
				true
			)),
			instructionOrLimitation: normalizeHTMLSymbols(this.convertMergeTags(
				data?.instructionOrLimitation || '',
				true
			)),
			residentialMortgageOrLendingNote: this.convertMergeTags(
				data?.residentialMortgageOrLendingNote || ''
			),
			homeCarAndContentsInsuranceNote: this.convertMergeTags(
				data?.homeCarAndContentsInsuranceNote || ''
			),
			businessRiskInsuranceNote: this.convertMergeTags(
				data?.businessRiskInsuranceNote || ''
			),
			kiwiSaverNote: this.convertMergeTags(data?.kiwiSaverNote || ''),
			otherInformation: this.convertMergeTags(data?.otherInformation || ''),
		};

		return obj;
	}

	convertMergeTags(content: string, skipNewLineUpdate = false) {
		const data = getContentWithMergeTags(
			content,
			this.mergeTags,
			skipNewLineUpdate
		);
		return removeMtWrappers(data) || '';
	}

	setupServices(crtData: ScopeOfServiceState, settings: ScopeOfServiceState) {
		// if crtData is null, then reset (for reload)
		const reset = isNil(crtData);

		// services
		this.showLifeInsurance =
			!reset && crtData?.cRTId
				? crtData?.lifeInsurance !== 2
				: settings?.lifeInsurance;
		this.showCriticalIllness =
			!reset && crtData?.cRTId
				? crtData?.criticalIllness !== 2
				: settings?.criticalIllness;
		this.showTPDInsurance =
			!reset && crtData?.cRTId
				? crtData?.tPDInsurance !== 2
				: settings?.tPDInsurance;
		this.showDisabilityInsurance =
			!reset && crtData?.cRTId
				? crtData?.disabilityInsurance !== 2
				: settings?.disabilityInsurance;
		this.showMedicalInsurance =
			!reset && crtData?.cRTId
				? crtData?.medicalInsurance !== 2
				: settings?.medicalInsurance;

		// other advice
		this.showBusinessRiskInsurance =
			!reset && crtData?.cRTId
				? crtData?.businessRiskInsurance !== 2
				: settings?.businessRiskInsurance;
		this.showKiwiSaver =
			!reset && crtData?.cRTId ? crtData?.kiwiSaver !== 2 : settings?.kiwiSaver;
		this.showHomeCarAndContentsInsurance =
			!reset && crtData?.cRTId
				? crtData?.homeCarAndContentsInsurance !== 2
				: settings?.homeCarAndContentsInsurance;
		this.showResidentialMortgageOrLending =
			!reset && crtData?.cRTId
				? crtData?.residentialMortgageOrLending !== 2
				: settings?.residentialMortgageOrLending;
		this.showOther =
			!reset && crtData?.cRTId ? crtData?.other !== 2 : settings?.other;
	}

	buildForm() {
		this.form = this.fb.group({
			cRTId: [null],
			intro: [null],
			lifeInsurance: [0],
			lifeInsuranceDetails: [''],
			criticalIllness: [0],
			criticalIllnessDetails: [''],
			tPDInsurance: [0],
			tPDInsuranceDetails: [''],
			disabilityInsurance: [0],
			disabilityInsuranceDetails: [''],
			medicalInsurance: [0],
			medicalInsuranceDetails: [''],
			businessRiskInsurance: [0],
			kiwiSaver: [0],
			homeCarAndContentsInsurance: [0],
			residentialMortgageOrLending: [0],
			instructionOrLimitation: [null],
			otherInformation: [null],
			residentialMortgageOrLendingNote: [null],
			homeCarAndContentsInsuranceNote: [null],
			kiwiSaverNote: [null],
			businessRiskInsuranceNote: [null],
			other: [0],
			otherNotes: [''],
			sectionCode: [AdviceProcessSectionCodes.SOS],
		});
	}

	onChange() {
		const formValue = this.form.getRawValue();
		this.service.updateScopeOfServiceTemp({
			...formValue,
			adviceProcessId: +this.adviceProcessId,
		});
	}

	tickChange(event) {
		const controllerName = event?.target?.id;
		const val = event?.target?.checked ? 1 : 0;
		this.form.get(controllerName).setValue(val);
		this.onChange();
	}

	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.loadSos();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	getSosHtml() {
		const getForm = this.form;
		const header = `<h1>Scope of Service</h1><br />`;
		const html = this.sosDocument?.nativeElement.innerHTML;
		const newHtml = document.createRange().createContextualFragment(html);
		const getAllTextArea: HTMLElement[] = Array.from(
			newHtml.querySelectorAll('textarea')
		);
		const getAllCheckBox: HTMLElement[] = Array.from(
			newHtml.querySelectorAll('.chckbox')
		);
		// Update all textarea
		getAllTextArea?.forEach((item) => {
			const getValue =
				getForm.get(item?.id).value?.replace(/\n|\t/g, '<br />') || '';
			$(item)?.replaceWith('<p>' + getValue + '</p>');
		});
		// Update all checkboxes
		getAllCheckBox?.forEach((item) => {
			const cBoxVal = getForm.get(item?.id).value === 1;
			$(item).attr('checked', cBoxVal);
		});
		const sosDoc = newHtml.querySelector('.sos-document');
		return `<div class="sos-pdf-file">${header}${sosDoc?.innerHTML}</div>`;
	}

	saveDocument() {
		this.changeDetector.detectChanges();
		const updatedData = this.getSosHtml();

		this.adviceProcess$.pipe(take(1)).subscribe((x) => {
			const sosDocument = x?.documents?.find(
				(d) => d.field === AdviceProcessDocumentField.SOS
			)?.value;

			if (!sosDocument?.documentID) {
				this.saveNewDocument(updatedData)
				.pipe(
					takeUntil(this.onDestroy$),
					take(1)
					)
					.subscribe();
			} else {
				this.saveExistingDocument(x, updatedData);
			}
		});
	}

	saveNewDocument(data: string) {
		const groupName = this.query
			.getValue()
			.primaryClient?.groupName?.toString()
			?.toUpperCase();
		const fileName = `${groupName} SCOPE OF SERVICE`;
		return of(data).pipe(
			tap(() => (this.isSaving = true)),
			mergeMap((content) =>
				this.crtDocService.downloadDocumentPDF(content, fileName)
			),
			mergeMap((content) => convertUtil.convertToBase64(content)),
			map((template) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: template,
				FileName: `${fileName}.pdf`,
				DocumentType: ServicesCodes.LR,
				Type: AdviceProcessPageCodes.SOS,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap((x) =>
				this.loatDocumentService.updateAdviceProcess(x, AdviceProcessPageCodes.SOS)
			),
			tap((_) =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						logMessage.oat.shared.scopeOfService.success.save
					)
				)
			),
			finalize(() => (this.isSaving = false)),
			take(1)
		);
	}

	saveExistingDocument(adviceProcess: ServiceAdviceProcessState, data: string) {
		const document = adviceProcess?.documents?.find(
			(d) => d.field === AdviceProcessDocumentField.SOS
		)?.value;

		const confirm = new Observable((obs) => {
			this.saveNewDocument(data)
			.pipe(
				takeUntil(this.onDestroy$),
				take(1)
				)
				.subscribe();
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});

		const initState = {
			header: 'Save Document',
			message: `A document is already uploaded for Scope of Service,`,
			subMessage: document?.fileName,
			secondaryMessage: `Do you want to replace this?`,
			confirm$: confirm,
			decline$: decline,
			isAcceptBtn: true,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
