import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { KiwisaverState } from './../../../../../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { KiwiSaver } from './kiwiSaver.model';

export interface KiwiSaverState extends EntityState<KiwiSaver> {
	crmKiwiSavers: KiwisaverState[];
}

@Injectable()
@StoreConfig({ name: 'kiwiSaver', idKey: 'cRTId' })
export class KiwiSaverStore extends EntityStore<KiwiSaverState, KiwiSaver> {
	constructor() {
		super({
			crmKiwiSavers: [],
		});
	}
}
