import { trigger, transition, style, animate } from '@angular/animations'

const TAP_COLLAPSE_ANIMATION_DURATION = 300;
export const tapCollapseAnimations = trigger('tapCollapseAnimations', [
  transition(':enter', [
    style({ height: '0px', overflow: 'hidden' }),
    animate(`${TAP_COLLAPSE_ANIMATION_DURATION}ms ease-in`, style({ height: '*', overflow: 'hidden' }))
  ]),
  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    animate(`${TAP_COLLAPSE_ANIMATION_DURATION}ms ease-out`, style({ height: '0px', overflow: 'hidden' }))
  ]),
]);
