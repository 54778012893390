<div class="container-fluid px-0 servicing-sections pull-left float-left">
	<!-- Integrated Templates -->
	<div class="row tap-container pt-3">
		<div class="col-12 pt-2">
			<h5>Integrated Templates</h5>
		</div>
	</div>
	<div class="row tap-container">
		<div class="col-12 scc-border mb-2"></div>
	</div>
	<div class="row tap-container">
		<div class="col-12">
			<app-servicing-integrated-templates
				[data$]="integratedTemplates$"
			></app-servicing-integrated-templates>
		</div>
	</div>

	<!-- Blank Templates -->
	<div class="row tap-container pt-4">
		<div class="col-12 pt-2">
			<h5>Blank Templates</h5>
		</div>
	</div>
	<div class="row tap-container">
		<div class="col-12 scc-border mb-2"></div>
	</div>
	<div class="row tap-container">
		<div class="col-12">
			<app-servicing-blank-templates
				[data$]="blankTemplates$"
			></app-servicing-blank-templates>
		</div>
	</div>
</div>
