import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, from, iif, Observable, of } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	skipWhile,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { RouteService } from 'src/app/core/config/route.service';
import {
	AdviceProcessOnlineRoutes,
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	MOATProcessRoutes,
	MoatStructurePages,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MortgageAdviceProcessService } from '../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { LoanComponent } from './loan/loan.component';
import { OtherDetailsComponent } from './other-details/other-details.component';
import { OtherDetailsQuery } from './other-details/state/other-details.query';
import { StructureSoa } from './state/structure-soa.model';
import { StructureSoaService } from './state/structure-soa.service';
import { StatementOfAdviceComponent } from './statement-of-advice/statement-of-advice.component';
import { StructureSoaSecurityComponent } from './security/structure-soa-security.component';
import { BusinessConfigService } from 'src/app/domain/business-config/business-config.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { LoanService } from './loan/state/loan.service';
import { MortgageProviderCommissionService } from '../provider-comission/state/provider-commission.service';
import { CrtMortgageService } from '../state/crt-mortgage.service';
import { ComponentBase } from 'src/app/core/base/component-base';
import { StructureSoaStore } from './state/structure-soa.store';
import { ApplicationService } from '../application/state/application.service';
import { PeopleEntitiesQuery } from '../client-sop/people-entities/state/people-entities.query';
import { CrtMortgageQuery } from '../state/crt-mortgage.query';

@Component({
	selector: 'app-structure-soa',
	templateUrl: './structure-soa.component.html',
	styleUrls: ['./structure-soa.component.scss'],
})
export class StructureSoaComponent extends ComponentBase implements OnInit, OnDestroy {
	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;

	adviceProcessId = +this.route.snapshot.paramMap.get('adviceProcessId');
	isLoading$: Observable<boolean> = this.service.isLoading$;
	structureSOA$: Observable<StructureSoa | null> =
		this.service.structureSOA$.pipe(
			map((x) => (!!x && x.length > 0 ? x[0] : null))
		);
	hasStructureSOA$: Observable<boolean> = this.service.hasStructureSOA$;

	isLoading: boolean;
	sectionCodes = AdviceProcessSectionCodes;
	moatStructurePages = MoatStructurePages;
	parentCRTId: number;
	reviewAppLastSaved: string;
	isAutoSaving: boolean;

	showFooter = false;
	exit = false;

	isOtherDetailsLoading$ = this.otherDetailsQuery.selectLoading();
	loanIsUpdating$ = this.loanService.isUpdating$;
	deceasedSciList$ = this.peopleQuery.deceasedSciList$;
	getOwnerChoices = this.crtMortgageService.getOwnerChoices;
	sidebar = this.moatQuery.getValue().sidebars;

	@ViewChild('stepper') stepper: MatStepper;
	@ViewChild('loanStructure') loanStructure: LoanComponent;
	@ViewChild('roaSecurity') roaSecurity: StructureSoaSecurityComponent;
	@ViewChild('otherDetails') otherDetails: OtherDetailsComponent;
	@ViewChild('soa') soa: StatementOfAdviceComponent;

	constructor(
		private route: ActivatedRoute,
		private service: StructureSoaService,
		private otherDetailsQuery: OtherDetailsQuery,
		private router: Router,
		private routeService: RouteService,
		private moatService: MortgageAdviceProcessService,
		private businessService: BusinessConfigService,
		private loanService: LoanService,
		private providerCommission: MortgageProviderCommissionService,
		private crtMortgageService: CrtMortgageService,
		private soaStore: StructureSoaStore,
		private applicationService: ApplicationService,
		private moatQuery: CrtMortgageQuery,
		private peopleQuery: PeopleEntitiesQuery,
	) {
		super();
	}

	ngOnInit(): void {
		this.markSoaAsStarted();
		this.service.get(this.adviceProcessId).subscribe();

		combineLatest([
			this.service.isLoading$,
			this.applicationService.isLoading$,
			this.crtMortgageService.sectionsLoading$,
		])
			.pipe(
				filter(([x, y, z]) => !x && !y && !z),
				withLatestFrom(this.crtMortgageService.sidebars$),
				tap(([, sidebars]) => {
					const isDisabledRoa = this.service.disableROA(sidebars);
					const isDisabledApplication =
						this.applicationService.disableApplication(sidebars);

					if (isDisabledRoa || isDisabledApplication) {
						// Base route
						const route = this.routeService.crtPage(
							this.route.snapshot.paramMap.get('customerType') !== 'client',
							+this.route.snapshot.paramMap.get('clientId'),
							+this.route.snapshot.paramMap.get('adviceProcessId'),
							AdviceProcessOnlineRoutes.Mortgage
						);

						// Navigate to route with application
						this.moatService.setRoaCurrentTab(null);
						this.router.navigate([...route, MOATProcessRoutes.Application]);
					} else {
						this.prepData();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	prepData() {
		this.isLoading = true;
		combineLatest([
			this.service.isLoading$,
			this.applicationService.isLoading$
		])
			.pipe(
				filter(([x, y]) => !x && !y),
				withLatestFrom(this.service.structureSOA$),
				map(([, soa]) => soa),
				switchMap((x) =>
					iif(
						() => !!x && x?.length > 0,
						of(x),
						this.service.add({
							adviceProcessId: +this.adviceProcessId,
							sectionCode: AdviceProcessSectionCodes.ROA,
							cRTId: null,
							name: 'Record of Advice',
							currentPage: MoatStructurePages.loanStructure,
							documentId: 0,
						})
					)
				),
				tap(() => this.moatService.setRoaCurrentTab(AdviceProcessSectionCodes.LoanStructure)),
				tap((x: any) => {
					if (x?.length > 0) {
						this.parentCRTId = x[0].cRTId;
						this.soaStore.updateCurrentPage(x[0].cRTId, 'Loan Structure');
					}
				}),
				concatMap(() => this.loanService.get(this.parentCRTId)),
				tap(() => this.isLoading = false),
				take(1)
			)
			.subscribe();

		// Pre-load on background for SOA
		of(true).pipe(
			mergeMap(() => this.businessService.get(true)),
			mergeMap(() => this.providerCommission.get()),
			take(1)
		).subscribe();

		this.crtMortgageService.triggerLeaveROA$
			.pipe(
				withLatestFrom(this.crtMortgageService.roaCurrentTab$),
				skipWhile(([x , y]) => !y),
				filter(([x, y]) => !!x && !!y),
				tap(([x, y]) => this.handleSidebarNavigate(x, y)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	handleSaveComplete(saveResult: { isSuccess: boolean; isNext: boolean }) {
		if (saveResult?.isSuccess) {
			this.isLoading = false;
			this.stepper.selected.completed = true;

			if (saveResult?.isNext !== undefined && !!saveResult?.isNext) {
				this.stepper.next();
			}

			if (saveResult?.isNext !== undefined && !saveResult?.isNext) {
				this.stepper.previous();
			}

			this.saveCurrentPage(this.stepper.selected.label);
		} else {
			this.isLoading = false;
		}
	}

	saveCurrentPage(page = '') {
		of(true)
			.pipe(
				withLatestFrom(this.structureSOA$),
				map(([, x]) => x),
				filter((x) => !!x),
				switchMap((x) =>
					this.service.update({
						...x,
						currentPage: page,
					} as StructureSoa)
				),
				take(1),
				finalize(() => {
					if (page === MortgageAdviceProcessPageNames.ROA && this.exit) {
						this.saveAndExit();
					}
				})
			)
			.subscribe();
	}

	saveAndExit() {
		let redirectRoute = [];
		const clientId = +this.route.snapshot.paramMap.get('clientId');
		const apId = +this.route.snapshot.paramMap.get('adviceProcessId');

		const goToCrm = () => {
			redirectRoute = this.routeService.customerAdviceProcess(clientId, apId);
			this.router.navigate(redirectRoute);
		};

		goToCrm();
	}

	handleSidebarNavigate(path: string, sectionCode: string) {
		switch (sectionCode) {
			case AdviceProcessSectionCodes.StatementOfAdvice: {
				this.soa?.leaveROA(true, path);
				break;
			}
			default:
				this.soa?.navigateToUrl(path);
				break;
		}
	}

	handleNext(sectionCode: string) {
		this.isLoading = true;
		this.exit = false;
		switch (sectionCode) {
			case AdviceProcessSectionCodes.LoanStructure: {
				this.loanStructure.save(true);
				this.moatService.setRoaCurrentTab(AdviceProcessSectionCodes.Security);
				break;
			}
			case AdviceProcessSectionCodes.Security: {
				this.roaSecurity.save(true);
				this.moatService.setRoaCurrentTab(AdviceProcessSectionCodes.OtherDetails);
				break;
			}
			case AdviceProcessSectionCodes.OtherDetails: {
				this.otherDetails.save(true);
				this.soa.loadComponent();
				this.moatService.setRoaCurrentTab(AdviceProcessSectionCodes.StatementOfAdvice);
				break;
			}
			case AdviceProcessSectionCodes.StatementOfAdvice: {
				this.soa.save(true);
				this.exit = true;
				break;
			}
			default:
				break;
		}
	}

	handleBack(sectionCode: string) {
		this.isLoading = true;
		switch (sectionCode) {
			case AdviceProcessSectionCodes.LoanStructure: {
				break;
			}
			case AdviceProcessSectionCodes.Security: {
				this.roaSecurity.save(false);
				this.moatService.setRoaCurrentTab(AdviceProcessSectionCodes.LoanStructure);
				break;
			}
			case AdviceProcessSectionCodes.OtherDetails: {
				this.otherDetails.save(false);
				this.moatService.setRoaCurrentTab(AdviceProcessSectionCodes.Security);
				break;
			}
			case AdviceProcessSectionCodes.StatementOfAdvice: {
				this.soa.back();
				this.isLoading = false;
				break;
			}
			default:
				break;
		}
	}

	markSoaAsStarted() {
		this.moatService.updateMortApPageStarted(AdviceProcessPageCodes.ROA).pipe(
			take(1)
		).subscribe();
	}

	resetStores() {
		this.otherDetails?.clearData();
	}

	stepperSelectionChange(e: StepperSelectionEvent): void {
		switch (e.selectedStep.label) {
			case this.moatStructurePages.security:
				this.roaSecurity.load();
				break;
		}
	}

	lastAutoSavedTimeApp(data: string) {
		this.reviewAppLastSaved = data;
	}

	isAutoSaveLoading(status: boolean) {
		this.isAutoSaving = status;
	}

	ngOnDestroy(): void {
		this.resetStores();
		this.moatService.setRoaCurrentTab(null);
		this.moatService.setTriggerLeaveROA(null);
		super.dispose();
	}
}
