<div class="p-0">
	<div class="clearfix">
		<h4 class="px-3 pt-3 pb-3 mb-0 font-weight-bold tap-text-secondary">
			Column Selection
		</h4>
		<div class="wrapper clearfix">
			<div class="column-settings px-3 pt-2 pb-1">
				<h5 class="m-0 font-weight-bold">Visible</h5>
			</div>
			<div class="visible-columns px-3">
				<bs-sortable
					[(ngModel)]="visibleColumns"
					fieldName="columnName"
					[itemTemplate]="clientVisibleItem"
					itemClass="sortable-item py-1"
					itemActiveClass="sortable-item-active"
					placeholderItem="Drag here"
					placeholderClass="placeholder-drag text-muted"
					wrapperClass="visible-sortable-wrapper py-2"
				>
				</bs-sortable>
			</div>
			<div class="column-settings px-3 pt-3 pb-1 not-visible-columns-title">
				<h5 class="m-0 font-weight-bold">Not Visible</h5>
			</div>
			<div class="not-visible-columns px-3">
				<bs-sortable
					[(ngModel)]="hiddenColumns"
					fieldName="columnName"
					[itemTemplate]="clientHiddenItem"
					itemClass="sortable-item py-1"
					itemActiveClass="sortable-item-active"
					placeholderItem="Drag here"
					placeholderClass="placeholder-drag text-muted"
					wrapperClass="hidden-sortable-wrapper py-2"
				>
				</bs-sortable>
			</div>
		</div>
		<div class="d-flex px-3 py-3 flex-row-reverse align-items-center">
			<button
				type="button"
				class="btn ui-btn-gray px-4 ml-2"
				(click)="cancel()"
				id="columnCancelBtn"
			>
				Cancel
			</button>
			<button
				id="columnSaveBtn"
				type="button"
				class="btn ui-btn-gray px-4 ml-2"
				(click)="save()"
				[disabled]="isLoading || visibleColumns?.length === 0"
			>
				Save
			</button>
			<app-manual-loader-mini *ngIf="isLoading" [isLoading]="true">
			</app-manual-loader-mini>
		</div>
	</div>
</div>

<ng-template #clientVisibleItem let-item="item" let-index="index">
	<span class="sortable-icon mr-2"
		><i class="material-icons md-18 tap-text-color-h6">drag_indicator</i></span
	>
	<span
		class="sortable-name"
		[ngClass]="{ 'disabled-sortable': item.value === 'Name' }"
		>{{ item.value }}</span
	>
</ng-template>

<ng-template #clientHiddenItem let-item="item" let-index="index">
	<span class="sortable-icon mr-2"
		><i class="material-icons md-18 tap-text-color-h6">drag_indicator</i></span
	>
	<span
		class="sortable-name"
		[ngClass]="{ 'disabled-sortable': item.value === 'Name' }"
		>{{ item.value }}</span
	>
</ng-template>
