import {
	Directive,
	EmbeddedViewRef,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { ComponentBase } from '@core/base/component-base';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

export enum OATFeatures {
	All = 'ALL',
	LOATV2 = 'LOAT',
}

@Directive({
	selector: '[oatFeature]',
})
export class AotFeatureDirective
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@Input() oatFeature: OATFeatures = OATFeatures.LOATV2;

	@Input() oatFeatureElse: TemplateRef<any>;

	private viewRef: EmbeddedViewRef<any>;

	constructor(
		private businessQuery: BusinessConfigQuery,
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {
		super();
	}

	ngOnInit(): void {
		super.subscribe(this.businessQuery.select(), ({ config }) => {
			this.viewContainerRef.clear();
			if (config?.OAT?.includes(this.oatFeature) || this.oatFeature === OATFeatures.All) {
				this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
			} else if (this.oatFeatureElse) {
				this.viewRef = this.viewContainerRef.createEmbeddedView(this.oatFeatureElse);
			}
		})
	}

	ngOnDestroy(): void {
		super.dispose();
		this.viewRef?.destroy();
	}

}
