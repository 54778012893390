import { getContentWithMergeTags } from '@shared/converter/content-merge-tags';
import { sosMetaKey } from '@shared/models/client-review-template/merge-tags/crt-kiwisaver/scope-of-service/sos.merge-tag';
import { util } from '@util/util';
import { either, isEmpty, isNil } from 'ramda';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { DefaultFileNames } from 'src/app/shared/models/documents/document.model';
import { DocumentSettingsTypes, SettingsTypes } from '../../../state/kiwisaver-settings.model';
import { KiwiScopeOfServiceState } from '../../state/kiwi-scope-of-service.model';
import { KiwiSosTemplateSettingsState } from './kiwi-sos-template-settings.model';

// biome-ignore lint/complexity/noStaticOnlyClass: Mapper
export class KiwiScopeOfServiceTemplateSettingsMapper {
	public static mapToUpsert(data: KiwiSosTemplateSettingsState) {
		return {
			...data,
			referenceId: data.referenceId || 0,
			type: SettingsTypes.KOATSosTemplate,
		};
	}

	public static filterMergeTagsToShow(data: MergeTagState[]) {
		// Merge Tags to Show that includes this metaKey prefixes
		return (
			data?.filter(
				(x) =>
					x?.metaKey?.includes('DATE_TODAY') ||
					x?.metaKey?.includes('BUSINESS_') ||
					x?.metaKey?.includes('ADVISER_') ||
					x?.metaKey?.includes('KOAT_SOS_'),
			) || []
		);
	}

	public static mapDocumentUpload(document, template) {
		if (template === 0 || Number.isNaN(template) || either(isNil, isEmpty)(template)) {
			return {
				document,
				referenceId: 0,
				fileName: DefaultFileNames.KoatSosTemplateSettings,
				type: DocumentSettingsTypes.SosTemplate,
			};
		}
		return {
			document,
			documentId: +template,
		};
	}

	public static mapSosPreviewSettings(mergeTags: MergeTagState[], sosSettings: KiwiScopeOfServiceState) {
		return (
			mergeTags?.map((x) => {
				if (x?.metaKey === sosMetaKey?.sosWill) {
					const will = util.tryParseJson(sosSettings?.whatWeWillTalkAboutToday)?.replace(/\n|\t/gm, '<br />');
					return {
						...x,
						secondaryValue: will ? getContentWithMergeTags(will, mergeTags, false, true) : '',
					};
				}
				if (x?.metaKey === sosMetaKey?.sosWont) {
					const wont = util.tryParseJson(sosSettings?.whatWeWontTalkAboutToday)?.replace(/\n|\t/gm, '<br />');
					return {
						...x,
						secondaryValue: wont ? getContentWithMergeTags(wont, mergeTags, false, true) : '',
					};
				}
				return x;
			}) || []
		);
	}
}
