import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityRoutingModule } from './activity-routing.module';
import { ActivityComponent } from './activity.component';
import { SharedModule } from '../../../shared/shared.module';
import { ActivityService } from './states/activity.service';
import { ActivityFormComponent } from './activity-form/activity-form.component';
import { ActivityColumnsFormComponent } from './activity-columns-form/activity-columns-form.component';
import { ActivityStore } from './states/activity.store';
import { ActivityQuery } from './states/activity.query';
import { ActivityResolver } from './activity.resolver';
import { ActivityUiStore } from './states/activity-ui.store';
import { ActivityUiQuery } from './states/activity-ui.query';
import { CustomerService } from 'src/app/core/customer/customer.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ActivityRoutingModule
  ],
  declarations: [ActivityComponent, ActivityFormComponent, ActivityColumnsFormComponent],
  providers: [
    ActivityUiStore,
    ActivityUiQuery,

    ActivityResolver,
    ActivityStore,
    ActivityQuery,
    ActivityService,

    CustomerService
  ]
})
export class ActivityModule { }
