import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RiskProfileOutcomeState } from '../state/fact-find-risk-profile-settings.model';
import { LoggerService } from '@core/logger/logger.service';

@Component({
	selector: 'app-outcome-form',
	templateUrl: './outcome-form.component.html',
	styleUrls: ['./outcome-form.component.scss'],
})
export class OutcomeFormComponent implements OnInit, OnChanges {
	@Input() outcomes: RiskProfileOutcomeState[];
	@Output() saveEvent = new EventEmitter<any>();
	@Input() loading: boolean;
	@Output() disableEvent = new EventEmitter<any>();
	@Input() disabled: boolean;

	@Input() shouldUpdate: number;

	currentValue: any;

	form: UntypedFormGroup;

	isAdding: boolean;
	isEditing: boolean;

	get OutcomeList() {
		return this.form.get('outcomes') as UntypedFormArray;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService,
		private cd: ChangeDetectorRef
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.buildForm();
		this.prepDocumentList();
	}

	ngOnChanges(changes) {
		if (changes?.shouldUpdate) {
			this.prepDocumentList();

			this.outcomes?.forEach((_, i) => {
				const item = this.OutcomeList?.controls[i];
				item.get('rendering')?.setValue(true);
			});

			setTimeout(() => {
				this.outcomes?.forEach((_, i) => {
					const item = this.OutcomeList?.controls[i];
					item.get('rendering')?.setValue(false);
				});
			}, 0)
		}
	}

	buildForm() {
		this.form = this.fb.group({
			outcomes: this.fb.array([]),
		});
	}

	prepDocumentList() {
		while (this.OutcomeList?.length > 0) {
			this.OutcomeList?.removeAt(0);
		}
		this.outcomes?.forEach((outcome: RiskProfileOutcomeState) => {
			this.OutcomeList?.push(this.patchValue(outcome));
		});
	}

	patchValue(outcome): UntypedFormGroup {
		return this.fb.group({
			...outcome,
			id: outcome.id,
			outcome: outcome.outcome || '',
			score: outcome.score || 0,
			origScore: outcome.score || 0,
			status: outcome.status,
			origStatus: outcome.status,
			isDefault: outcome.isDefault,
			isEditing: false,
			isAdding: false,
			rendering: false
		});
	}

	trackByFn = (index: number, item: any) => {
		return index;
	};

	updateStatus(index: number) {
		const item = this.OutcomeList?.controls[index];
		const currentValue = item.get('status')?.value;
		item.get('status')?.setValue(currentValue === 1 ? 2 : 1);
		
		if (!item.get('isAdding')?.value && !item.get('isEditing')?.value) {
			this.save();	
		}
	}

	saveScore(i: number) {
		const item = this.OutcomeList?.controls[i];

		if (!item.get('isAdding')?.value && !item.get('isEditing')?.value) {
			this.save();
		}
	}

	save() {
		const data = this.OutcomeList?.getRawValue();

		const outcomes = data?.map((outcome) => {
			return {
				id: outcome.id,
				outcome: outcome.outcome,
				score: outcome.score || 0,
				origScore: outcome.origScore,
				status: outcome.status,
				origStatus: outcome.origStatus,
				isDefault: outcome.isDefault,
			}
		});

		this.saveEvent.emit(outcomes);
	}

	addNew() {
		this.isAdding = true;

		const ids = this.OutcomeList?.controls?.map((control) => {
			return control.get('id')?.value;
		})

		const highest = ids && ids.length ? Math.max(...ids) + 1 : 1;

		this.OutcomeList.push(
			this.fb.group({
				id: highest,
				outcome: null,
				score: 0,
				status: 1,
				isDefault: false,
				isEditing: false,
				isAdding: true,
				rendering: false,
			})
		);

		this.disableEvent.emit(true);
	}

	saveAddEdit(i: number) {
		const item = this.OutcomeList?.controls[i];

		const newOutcome = item.get('outcome')?.value;

		if (newOutcome == null || newOutcome.trim() === '') {
			this.loggerService.Warning({}, 'Please provide a valid outcome.');
			return;
		}

		const outcomes = this.outcomes?.map(({ outcome }) => outcome);

		const existing = outcomes?.find((outcome) => {
			return outcome === newOutcome;
		});
		
		if (this.currentValue?.outcome !== newOutcome && existing) {
			this.loggerService.Warning({}, 'Outcome already exist.');
			return;
		}

		this.save();

		item.get('isAdding')?.setValue(false);
		item.get('isEditing')?.setValue(false);
		this.isAdding = false;
		this.isEditing = false;
		this.currentValue = null;
		this.disableEvent.emit(false);
	}

	edit(i: number) {
		const item = this.OutcomeList?.controls[i];
		this.isEditing = true;
		item.get('isEditing')?.setValue(true);

		this.currentValue = item.value;

		this.disableEvent.emit(true);
	}

	cancelAddEdit(i: number) {
		const item = this.OutcomeList?.controls[i];

		if (this.isAdding) {
			this.OutcomeList?.controls?.pop();
			this.isAdding = false;
		}

		if (this.isEditing) {
			item.patchValue(this.currentValue);
			item.get('isEditing')?.setValue(false);
			// item.get('outcome')?.setValue(this.currentValue);
			this.isEditing = false;

			item.get('rendering')?.setValue(true);

			setTimeout(() => {
				item.get('rendering')?.setValue(false);
			}, 0)
		}

		this.currentValue = null;
		this.disableEvent.emit(false);
	}

	cancelEdit(i: number) {
		const item = this.OutcomeList?.controls[i];
		item.get('isEditing')?.setValue(false);
		this.currentValue = null;
	}

	delete(index: number) {
		this.OutcomeList?.removeAt(index);
		this.save();
	}
}
