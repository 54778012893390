export interface MergeTagDetails {
	MetaKey: string;
	Value: any;
	Type?: string;
	Description: string;
	SecondaryValue: string;
	Order?: number;
}

export interface MergeTagState {
	metaKey: string;
	value: any;
	description?: string;
	secondaryValue?: any;
	type?: string;
	order?: number;
}

export enum MergeTagTypeCode {
	text = 'T',
	image = 'I',
	link = 'L',
	reference = 'R',
	referenceValue = 'RV',
	referenceMultiValue = 'RMV',
	object = 'O',
	listOfObject = 'LO',
	dropdown = 'D',
	dropdownValue = 'DV',
	dropdownOther = 'DO',
	otherDropdownValue = 'ODV',
	textWithComputation = 'TC'
}

export enum MergeTagDataTypes {
	PrimaryCompany = 'PCC',
	PrimaryClient = 'PCI',
	SecondaryClient = 'SCI',
	AdviceProcess = 'AP',
	LR = 'LR',
	Mortgage = 'M',
	FG = 'FG',
	Investment = 'I',
	KiwiSaver = 'KS',
	Claims = 'APC'
}

export enum MergeTagTable {
	General = 'general',
	Business = 'business',
	Staff = 'staff',
	CustomerDetails = 'customer-details',
	AdviceProcess = 'advice-process',
	LR = 'lrinsurance',
	Mortgage = 'mortgages',
	FG = 'fginsurance',
	Investment = 'investment',
	KiwiSaver = 'kiwisaver'
}