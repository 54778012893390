import { StoreConfig, Store } from '@datorama/akita';
import { MonthlyMortgageGoalTrack } from './mmgt.model';
import produce from 'immer';
import { Injectable } from "@angular/core";

export interface MMGTState {
  /**
   * Monthly Mortgage Goal Track data
   */
  GoalTrack: MonthlyMortgageGoalTrack;
  /**
   * Error message. will show up when not empty
   */
  error: string;
  /**
   * Indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): MMGTState {
  return {
    GoalTrack: {
      PreviousMonth: 0,
      CurrentMonth: 0,
      NextMonth: 0,
      QuarterExpected: 0
    },
    error: '',
    isLoading: false
  };
}

/**
 * Monthly Mortgage Goal Tracker data store
 */
@Injectable()
@StoreConfig({
  name: 'MortgageMonthlyGoalTrack'
})
export class MMGTStore extends Store<MMGTState> {
  constructor() {
    super(createInitialState());
  }

  /**
   * Set if widget is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(
      produce(draft => {
        draft.isLoading = isLoading;
      })
    );
  }
}
