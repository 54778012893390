import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, OnDestroy } from '@angular/core';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { combineLatest, map, take, tap, startWith, auditTime, delay, withLatestFrom, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DashboardService } from '../state/dashboard.service';
import { DashboardQuery } from '../state/dashboard.query';
import * as R from 'ramda';
import { UserQuery } from '../../../../domain/user/user.query';
import { ReferralResponse } from '../../../../shared/models/_general/referenceResponse';
import produce from 'immer';
import { DashboardStore } from '../state/dashboard.store';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

/**
 * component to filter dashboard widget data
 */
@Component({
	selector: 'app-dashboard-control',
	templateUrl: './dashboard-control.component.html',
	styleUrls: ['./dashboard-control.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardControlComponent implements OnInit, AfterViewInit, OnDestroy {
	private onDestroy$: Subject<void> = new Subject<void>();

	hasLR$ = this.service.hasLR$;
	hasM$ = this.service.hasM$;
	hasFG$ = this.service.hasFG$;
	hasK$ = this.service.hasK$;
	hasI$ = this.service.hasI$;
	isConversionEnabled$ = this.businessConfigQuery.conversionFeature$;

	/**
	 * angular form control for choosing adviser
	 */
	adviserField = new UntypedFormControl([]);
	/**
	 * observable adviserField value
	 */
	adviserValue$: Observable<string[]> = this.adviserField.valueChanges.pipe(startWith(this.adviserField.value));
	/**
	 * adviser choices for adviser field
	 */
	adviserChoices$ = this.staffQuery.adviserChoices$
	/**
	 * indicator if the all advisers has been selected
	 */
	allAdvisersSelected$ = this.adviserChoices$.pipe(
		combineLatest(this.adviserValue$),
		auditTime(0),
		tap(([choices, field]) => (choices.length !== field.length ?
				this.dashboardStore.setSelectedAllAdvisers(false) :
				this.dashboardStore.setSelectedAllAdvisers(true))
		),
		map(([choices, field]) => choices?.map(choice => choice.value)?.every(x => field?.includes(x)))
	);

	/**
	 * Indicator if the widgets are saving
	 */
	isSavingWidgets$: Observable<boolean> = this.dashboardQuery.isSavingWidgets$;

	form = new UntypedFormGroup({});

	widgets$: Observable<{ widgetCode: string; widgetName: string }[]> = this.dashboardQuery.availableWidgets$.pipe(
		withLatestFrom(this.hasLR$, this.hasM$, this.hasK$, this.hasFG$, this.hasI$, this.isConversionEnabled$),
		map(([w, lr, m, k, fg, i, isConversionEnabled]) => {
			const wState = produce(w, draft => {
				if (!lr) {
					draft = R.filter(
						x =>
							x.widgetCode !== 'lrgt' &&
							x.widgetCode !== 'lrgtsg' &&
							x.widgetCode !== 'lrgtfy' &&
							x.widgetCode !== 'lra' &&
							x.widgetCode !== 'lrct' &&
							x.widgetCode !== 'aas' &&
							x.widgetCode !== 'aasf' &&
							x.widgetCode !== 'qas' &&
							x.widgetCode !== 'mas' &&
							x.widgetCode !== 'was' &&
							x.widgetCode !== 'bb' &&
							x.widgetCode !== 'adv' &&
							x.widgetCode !== 'avs' &&
							x.widgetCode !== 'asry' &&
							x.widgetCode !== 'msw' &&
							x.widgetCode !== 'ebc' &&
							x.widgetCode !== 'lc' &&
							x.widgetCode !== 'eblr' &&
							x.widgetCode !== 'nblr' ,
						draft
					);
				}

				if (!m) {
					draft = R.filter(
						x => 
							x.widgetCode !== 'rm' && 
							x.widgetCode !== 'mgt' && 
							x.widgetCode !== 'mmgt' && 
							x.widgetCode !== 'mo',
						draft
					);
				}

				if (!k) {
					draft = R.filter(x => x.widgetCode !== 'kgt', draft);
				}

				if (!fg) {
					draft = R.filter(x => x.widgetCode !== 'bnb', draft);
					draft = R.filter(x => x.widgetCode !== 'fggtd', draft);
					draft = R.filter(x => x.widgetCode !== 'fggtc', draft);
					draft = R.filter(x => x.widgetCode !== 'fgbb', draft);
					draft = R.filter(x => x.widgetCode !== 'fgpsc', draft);
					draft = R.filter(x => x.widgetCode !== 'fgpsd', draft);
					draft = R.filter(x => x.widgetCode !== 'fgpslt', draft);
				}

				if (!lr && !m && !k && !fg && !i) {
					draft = R.filter(x => x.widgetCode !== 'p', draft);
				}
				
				if (!isConversionEnabled) {
					draft = R.filter(x => x.widgetCode !== 'nblr', draft);
					draft = R.filter(x => x.widgetCode !== 'mo', draft);
					draft = R.filter(x => x.widgetCode !== 'eblr', draft);
				}

				return draft;
			});

			return wState;
		})
	);

	isSelectedAllWidgets$: Observable<boolean> = this.form.valueChanges.pipe(
		startWith(this.form.controls),
		auditTime(0),
		map(
			widgets =>
				!Object.values(widgets)
					?.map(x => (x && typeof x === 'object' ? x['value'] : x))
					?.some(w => !w)
		)
	);

	referrals$: Observable<ReferralResponse[]> = this.dashboardQuery.referrals$;

	widgetsFormOpen = false;

	constructor(
		private staffQuery: BLStaffsQuery,
		private dashboardService: DashboardService,
		private dashboardQuery: DashboardQuery,
		private userQuery: UserQuery,
		private dashboardStore: DashboardStore,
		private service: ClientProfileService,
		private businessConfigQuery: BusinessConfigQuery,
	) {}

	ngOnInit() {}

	ngAfterViewInit() {
		const user$ = this.userQuery.userInfo$.pipe(take(1));
		const isAutoSelectUser$ = this.userQuery.isAutoSelectUser$.pipe(take(1));

		user$
			.pipe(
				combineLatest(isAutoSelectUser$),
				delay(0),
				tap(([user, isAutoSelect]) => {
					if (isAutoSelect) {
						this.adviserField.setValue([user.StaffID?.toString()]);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	/**
	 * method to set adviser field value to select or clear all adviser
	 */
	selectAll(checked: boolean): void {
		this.dashboardStore.setSelectedAllAdvisers(checked);

		if (!checked) {
			this.adviserField.setValue([]);
		} else {
			this.adviserChoices$
				.pipe(
					take(1),
					map(choices => choices?.map(choice => choice.value))
				)
				.subscribe(x => this.setAdviserField(x));
		}
	}

	/**
	 * @description
	 * send adviser to store
	 */
	filter(): void {
		this.dashboardService.setFilter([...this.adviserField.value]);
	}

	/**
	 * Set adviser field value
	 *
	 * @param ids - list of adviser ids to insert into adviser field
	 */
	private setAdviserField(ids: string[]): void {
		this.adviserField.setValue(ids);
	}

	/**
	 * show form window and setup active widget form.
	 */
	onToggleWidgets() {
		if (this.widgetsFormOpen) {
			this.widgetsFormOpen = false;
		} else {
			const activewidgets = this.dashboardQuery.activeWidgetCodes();
			this.dashboardQuery.availableWidgets$
				.pipe(
					tap(widgets => {
						for (const key of Object.keys(this.form.controls)) {
							this.form.removeControl(key);
						}
						widgets?.forEach((w, i) =>
							activewidgets?.some(x => x === w.widgetCode)
								? this.form.addControl(w.widgetCode, new UntypedFormControl(true))
								: this.form.addControl(w.widgetCode, new UntypedFormControl(false))
						);

						this.widgetsFormOpen = true;
					}),
					takeUntil(this.onDestroy$)
				)
				.subscribe();
		}
	}

	selectAllWidgets(isSelectedAll: boolean) {
		for (const key of Object.keys(this.form.controls)) {
			this.form.removeControl(key);
		}

		this.dashboardQuery.availableWidgets$
			.pipe(
				takeUntil(this.onDestroy$),
				tap(widgets => {
					widgets?.forEach(w => this.form.addControl(w.widgetCode, new UntypedFormControl(isSelectedAll)));
				})
			)
			.subscribe();
	}

	/** Get ticked widgets */
	// tslint:disable-next-line: member-ordering
	private getWidgetKeys = R.pipe(
		R.defaultTo({}),
		R.filter<{ [key: string]: boolean }>((x): boolean => !!x),
		R.keys
	);

	/** Save list of ticket widgets that will show in the dashboard page */
	save() {
		//  Save service method
		this.dashboardService
			.updateVisibleWidgets(this.getWidgetKeys(this.form.value))
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				() => {},
				() => {},
				() => (this.widgetsFormOpen = false)
			);
	}

	/** Close the the dropdown */
	cancel() {
		this.widgetsFormOpen = false;
	}

	/**
	 * Open referral link
	 * @param link referral link
	 */
	goToLink(link: string) {
		if (link) {
			window.open(this.parseLink(link), '_blank');
		}
	}

	parseLink(link: string): string {
		if (link && !link?.match(/^[a-zA-Z]+:\/\//)) {
			link = 'http://' + link;
		}
		return link;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
