import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AnnualApiStatFinancialYear } from './state/aasf.model';
import { BodyRow } from '../../shared/simple-table/simple-table.model';
import { map, filter, takeUntil } from 'rxjs/operators';
import { DashboardQuery } from '../../state/dashboard.query';
import { AnnualApiStatsFinancialYearQuery } from './state/aasf.query';
import { AnnualApiStatsFinancialYearService } from './state/aasf.service';
import { numUtil } from '../../../../../util/util';

/** Annual API Stats but financial year date range */
@Component({
  selector: 'app-aasf',
  templateUrl: './aasf.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AasfComponent implements OnInit, OnDestroy {
  public static widgetCode = 'aasf';
  public static widgetName = 'Annual API Stats - FY';
  public static sizeX = 350;
  public static sizeY = 250;
  public static minSizeX = 350;
  public static minSizeY = 250;

  widgetCode = AasfComponent.widgetCode;

  /**
   * Display name for Statuses.
   */
  private readonly propertyName = {
    APICompletionRate: 'API Completion Rate',
    APPCompletionRate: 'App Completion Rate',
    Submitted: 'Submitted API',
    New: 'Issued API',
    Lost: 'Cancelled API',
    NetNew: 'Net API',
    CompletionRate: 'Completion Rate'
  };

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * AAS (Finacial Year) Data
   */
  data$: Observable<AnnualApiStatFinancialYear> = this.query.AnnualApiStatData$;

  /**
   * data useable by view.
   * creates data that can be used by simple-table.
   * appends % or prepends $ signs on values.
   */
  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return Object.keys(data)?.map<BodyRow>(key => {
          const isPercent = ['APICompletionRate', 'APPCompletionRate']?.indexOf(key) !== -1;
          const label = this.propertyName[key];
          const value = isPercent ? numUtil.formatToPercent(data[key]) : numUtil.formatToCurrency(data[key]);

          return [
            {
              text: label
            },
            { text: value, class: 'col-4' }
          ];
        });
      }
    })
  );
  constructor(
    private dashboardQuery: DashboardQuery,
    private query: AnnualApiStatsFinancialYearQuery,
    private service: AnnualApiStatsFinancialYearService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of annual API stats.
   *
   * Chart does not automatically resize on div resize(gridstack)
   *   so listen to resize event to trigger manual resize via refreshing
   *   of data.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetAnnualApiStats(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
