import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { DropdownValueService } from '../../../domain/dropdown-value/dropdown-value.service';

const dropdownCodes: string[] = ['RT'];

@Injectable()
export class ReportPageResolver implements Resolve<boolean> {
	constructor(private dropdownValueService: DropdownValueService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdowns$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		return forkJoin([dropdowns$]).pipe(mapTo(true));
	}
}
