import { LoatPdfFooterCodes, LoatPdfHeaderCodes } from "@shared/models/advice-process/advice-process.model";

export interface PdfDesignV2State {
	genericCover: string;
	body: string;
}

export const PdfDesignV2Options = {
	DPI: '120',
	MarginTop: '2.5cm',
	MarginBottom: '1.6cm',
	MarginLeft: '0cm',
	MarginRight: '0cm',
	FooterHtmlType: LoatPdfFooterCodes.Default,
	HeaderHtmlType: LoatPdfHeaderCodes.Default
};

export const PdfGenericDivId = 'PDF_GENERIC_COVER';
export const PdfClasses = {
	LoatSoa: 'soa-pdf-file-v2 pdf-design-v2'
}
