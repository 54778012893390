import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DocumentService } from '@modules/crm/document/states/document.service';
import { UploadModalComponent } from '@shared/modal/upload-modal/upload-modal.component';
import {
	DocumentModelState,
	TenmplateDocumentUploadState,
} from '@shared/models/documents/document.model';
import { convertUtil, objectUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { iif, Observable, of } from 'rxjs';
import {
	concatMap,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ServicingCalculatorSettingsMapper } from '@modules/tl-moat-settings/servicing-calculators/state/servicing-calculators-settings.mapper';
import { ServicingCalculatorState } from '@modules/tl-moat-settings/servicing-calculators/state/servicing-calculators-settings.model';

@Component({
	selector: 'app-update-servicing-template-modal',
	templateUrl: './update-servicing-template-modal.component.html',
	styleUrls: ['./update-servicing-template-modal.component.scss'],
})
export class UpdateServicingTemplateModalComponent implements OnInit {
	savefn$: (model: any) => Observable<any>;
	close$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	formItem: ServicingCalculatorState;
	isTapLevel: boolean;

	form: UntypedFormGroup;
	documentId: number;
	document: DocumentModelState;
	tempDoc: TenmplateDocumentUploadState;
	tempDocBase64: string;
	sumbitted: boolean;
	isLoading: boolean;

	constructor(
		@Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private docService: DocumentService
	) {
		this.buildForm();
	}

	get DocumentID() {
		return this.form.get('documentId');
	}

	ngOnInit(): void {
		this.clearTempDoc();
		of(this.formItem)
			.pipe(
				tap((data) => (this.documentId = +data?.documentId)),
				tap((data) => (data ? this.form.patchValue(data) : null)),
				mergeMap((data) =>
					iif(
						() => R.complement(R.isNil)(data?.documentId),
						this.getFile(+data?.documentId),
						of(null)
					)
				),
				take(1)
			)
			.subscribe();
	}

	buildForm() {
		this.form = this.fb.group({
			documentName: ['', [Validators.required]],
			documentId: [null, [Validators.required]],
			fileOutputFormat: [''],
		});
	}

	patchValue(data?: ServicingCalculatorState) {
		this.form.get('documentName').setValue(data?.documentName || '');
		this.form.get('documentId').setValue(data?.documentId || null);
		this.form.get('fileOutputFormat').setValue(data?.fileOutputFormat || '');
	}

	save() {
		this.sumbitted = true;
		const formValue = this.form.getRawValue();

		if (!formValue?.name && !(!!this.tempDoc || !!this.document)) {
			return;
		}
		of(formValue)
			.pipe(
				tap(() => (this.isLoading = true)),
				concatMap((x) =>
					iif(
						() => !formValue.documentId || !R.isNil(this.tempDocBase64),
						this.uploadDocument(formValue),
						of(x).pipe(
							tap(() => this.clearTempDoc()),
							take(1)
						)
					)
				),
				concatMap((data) => this.savefn$({ ...this.formItem, ...data })),
				finalize(() => {
					this.isLoading = false;
					this.bsModalRef.hide();
				}),
				take(1)
			)
			.subscribe();
	}

	close() {
		this.close$.pipe(take(1)).subscribe();
		this.bsModalRef.hide();
	}

	openUploadModal() {
		const upload = (req) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				tap((x) => this.addTempDocument(x)),
				mergeMap((x) => convertUtil.convertToBase64(x[0])),
				tap((x) => (this.tempDocBase64 = x))
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Upload Template',
			restrict: '.xls, .xlsm, .xlsx',
			additionalInfo: 'Upload File',
			allowedFileExtensions: ['xls', 'xlsm', 'xlsx']
		};

		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	addTempDocument(data) {
		if (R.complement(R.either(R.isNil, R.isEmpty))(data)) {
			this.tempDoc = data[0];
		}
	}

	uploadDocument = (formValues) => {
		let extension = '';
		return of(this.documentId).pipe(
			withLatestFrom([this.tempDoc], [this.tempDocBase64]),
			tap(([id, doc]) => {
				extension = doc?.name?.split('.')?.reverse()?.[0];
			}),
			map(([id, doc, base64]) =>
				ServicingCalculatorSettingsMapper.mapDocumentUpload(doc, base64)
			),
			concatMap((data) =>
				iif(
					() => !!this.isTapLevel,
					this.docService.tlNewFileUpload(data),
					this.docService.newFileUpload(data)
				)
			),
			tap((res) => (this.documentId = +res)),
			tap((res) => this.DocumentID.setValue(+res)),
			tap((res) => this.DocumentID.setValue(+res)),
			mergeMap((res) =>
				of({ ...formValues, documentId: +res, fileOutputFormat: extension })
			),
			take(1)
		);
	};

	clearTempDoc() {
		this.tempDoc = null;
		this.tempDocBase64 = null;
	}

	getFile = (documentId) =>
		of(documentId).pipe(
			mergeMap((x) =>
				iif(
					() => !!this.isTapLevel,
					this.docService.tlGetFile(x),
					this.docService.getFile(x)
				)
			),
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			tap((data) => (this.document = data)),
			take(1)
		);
}

export interface UpdateServicingTemplateModel {
	header: string;
	message: string;
}
