import { mapToLookupObject } from 'src/app/domain/dropdown-value/dropdown-value.model';
import {
	AfterViewInit,
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { DomSanitizer, Meta, SafeStyle } from '@angular/platform-browser';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import {
	debounceTime,
	map,
	mergeMap,
	shareReplay,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { CommandRoute, RouteService } from 'src/app/core/config/route.service';
import { staticConf } from 'src/app/core/config/static-config.service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { LocalService } from 'src/app/core/services/local.service';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { FieldMetadata } from 'src/app/shared/dynamic-field/field-metadata.model';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { ActivityModalComponent } from 'src/app/shared/modal/activity/activity-modal/activity-modal.component';
import {
	DeleteModalComponent,
	DeleteModalModel,
} from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { ActivityViewModel } from 'src/app/shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import MomentUtil from 'src/app/util/moment.util';
import { util } from '../../../../util/util';
import {
	columns,
	linkColumn,
	Metakey,
} from './advice-process-datatable.config';
import { AdviceProcessFormComponent } from './advice-process-form/advice-process-form.component';
import { comparer, request } from './advice-process.util';
import { AdviceProcessUiQuery } from './states/advice-process-ui.query';
import { Row } from './states/advice-process.model';
import { AdviceProcessQuery } from './states/advice-process.query';
import { AdviceProcessService } from './states/advice-process.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

declare var $: any;

@Component({
	selector: 'app-advice-process',
	templateUrl: './advice-process.component.html',
	styleUrls: ['./advice-process.component.scss'],
})
export class AdviceProcessComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	claimsMetakeys = [
		'Reference Number', 
		'Claim Type',
		'Claim Outcome',
		'Total Paid'];
	claimsFeature:boolean = this.businessConfigQuery.getValue()?.config?.Claims;

	// columns
	// advice process datatable config.
	columns$ = this.query.tableColumns$.pipe(
		map((columns)=>{ return columns.filter(
				(x)=> (this.claimsMetakeys.includes(x.metakey) && !this.claimsFeature)? false: true
			)}
		),
	);
	columnsWithLink$ = this.columns$.pipe(map((x) => [...x, linkColumn]));
	linkColumn = linkColumn;

	cellsLoading$ = this.query.cellsLoading$;
	cellsEditing$ = this.query.cellsEditing$;
	cellsTempvalue$ = this.query.cellsTempvalue$;
	rowsLoading$ = this.query.rowsLoading$;

	rows$ =
		this.query.getValue().count <= 500
			? this.query.sortedRows$
			: this.query.rows$;
	hasRow$ = this.query.hasRows$;
	propSort$ = this.query.uiQuery.propSort$;
	sorts$ = this.query.sorts$;
	isSearching$ = this.query.isSearching$;

	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');
	LRProducts$ = this.dropdownValueQuery.orderedChoices$('LRPR');
	FGPL$ = this.dropdownValueQuery.orderedChoices$('FGPL');
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;
	allAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$;
	allAdviserChoicesAsObject$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);

	edit = this.service.edit;
	cancel = this.service.cancel;
	setTempValue = this.service.setTempValue;

	properties = columns;

	showSearchFields = false;
	tblHeight: SafeStyle;

	resizeEvent$ = fromEvent(window, 'resize');

	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild(AdviceProcessFormComponent)
	lrInsuranceFormComponent: AdviceProcessFormComponent;


	trackByFn(index, item: TableColumn) {
		return item.prop;
	}
	rowIdentity = (row: Row) => row.AdviceProcessID;

	route = (metakey: Metakey, row: Row): CommandRoute | string => {
		return row.link.IsCompany
			? this.routeService.businessAdviceProcess(
					row.CustomerID,
					row.AdviceProcessID
			  )
			: this.routeService.customerAdviceProcess(
					row.CustomerID,
					row.AdviceProcessID
			  );
	};

	constructor(
		private query: AdviceProcessQuery,
		private service: AdviceProcessService,
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private routeService: RouteService,
		private modalService: BsModalService,
		private ngZone: NgZone,
		private sanitizer: DomSanitizer,
		private uiQuery: AdviceProcessUiQuery,
		private loggerService: LoggerService,
		private localService: LocalService,
		private businessConfigQuery: BusinessConfigQuery,
	) {}

	ngOnInit() {
		this.tblHeight =
			this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - 156px)`);

		this.resizeEvent$
			.pipe(debounceTime(500), takeUntil(this.onDestroy$))
			.subscribe((e) => {
				this.service.reloadData();
			});
	}

	ngAfterViewInit() {
		this.dragHeader();
	}

	businessRoute(id: number, customerSvcId: number) {
		return this.ngZone.run(() =>
			this.routeService.businessAdviceProcess(id, customerSvcId)
		);
	}
	clientRoute(id: number, customerSvcId: number) {
		return this.ngZone.run(() =>
			this.routeService.customerAdviceProcess(id, customerSvcId)
		);
	}

	reorder = (reorderEvent: {
		column: TableColumn;
		newValue: number;
		prevValue: number;
	}) => {
		this.service
			.reorderColumn(reorderEvent.prevValue, reorderEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	resize = (event: { column: TableColumn; newValue: number }) => {
		if (event && (!event.column || !event.newValue)) {
			return;
		}
		this.service
			.resizeColumn(`${event.column.prop}`, event.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	sort(a: { sorts; column; prevValue; newValue }) {
		if (this.query.getValue().count <= 500) {
			return this.service?.sort(a.sorts[0].prop, a.sorts[0].dir);
		}

		this.service
			.search(
				request(
					{
						Column: a.sorts[0].prop,
						Direction: a.sorts[0].dir,
					},
					this.lrInsuranceFormComponent.prepareFormValue(),
					1
				)
			)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.service?.sort(a.sorts[0].prop, a.sorts[0].dir);
			});
	}

	saveField = (
		row: Row,
		AdviceProcessID: number,
		MetaKey: Metakey,
		Key: string,
		metadata: FieldMetadata<any>
	) => {
		const claimsRow = this.claimsMetakeys.includes(MetaKey);
		const serviceCode = row.AdviceProcess.value.includes('F&G')?'APFGC':'APLRC';
		const saveFieldReq = {
			CustomerID: row.CustomerID,
			AdviceProcessID,
			MetaKey,
			MetaValue: metadata.value,
			CustomerServiceType: claimsRow?serviceCode:staticConf.lrServiceCode,
		};

		this.service
			.saveField(saveFieldReq,claimsRow)
			.pipe(
				withLatestFrom(this.propSort$),
				mergeMap(([res, prop]) => {
					return this.rows$.pipe(
						tap((rows) => {
							if (prop === Key) {
								const i = rows?.findIndex(
									(x) => x.AdviceProcessID === row.AdviceProcessID
								);
								this.table.bodyComponent.updateOffsetY(
									i / this.table.bodyComponent.pageSize
								);
								this.table.bodyComponent.selected.push(row);
								setTimeout(() => {
									this.table.bodyComponent.selected = [];
									$('.datatable-body-row').removeClass('active');
								}, 1000);
							}
						}),
						take(1)
					);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	updateClientNextActivityField = (customerId: number) => {
		this.service
			.updateClientAndUserNextActivity(customerId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	updateNoteField = (AdviceProcessID: number, metadata: any) =>
		this.service.updateNote(AdviceProcessID, metadata.value);

	delete(customerId: number, adviceProcessId: number, adviceProcess: string) {
		const confirm$ = this.service.delete(adviceProcessId);

		const initState: Partial<DeleteModalModel> = {
			delete$: confirm$,
			header: 'Delete Advice Process',
			message: `Are you sure you want to delete ${adviceProcess}?`,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveClientNextActivity = (ac: ActivityViewModel) =>
		this.service.createClientNextActivity(ac).pipe(
			tap(() => {
				this.loggerService.Success(
					{},
					logMessage.crm.activity.success.create,
					[]
				);
			})
		);

	// Activity modal
	createClientNextActivity(customerId: number) {
		new Observable((obs) => {
			const formItem = {
				Customer: { CustomerId: customerId },
			};
			const initState: any = {
				formItem,
				AT$: this.AT$,
				AM$: this.AM$,
				adviserChoices$: this.adviserChoices$,
				adviserCalendarChoices$: this.adviserCalendarChoices$,
				header: 'Schedule Activity',
				hideClient: true,
				savefn: this.saveClientNextActivity,
				isModal: true,
				isEditForm: false,
				permissionsToComplete: ['FCCA', 'FCLA'],
			};
			this.modalService.show(ActivityModalComponent, {
				class: `modal-dialog-centered ${
					this.localService.getValue('loginType') === 'microsoft'
						? 'modal-dialog-outlook-xl'
						: 'modal-xl'
				}`,
				initialState: initState,
				ignoreBackdropClick: true,
			});
			obs.complete();
		})
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	convertDateToNZ(d: string) {
		const nzToday = MomentUtil.createMomentNz().format('LL');
		const splicedDate = d.slice(0, 10);
		const date = moment(splicedDate, 'DD/MM/YYYY');
		return date.diff(nzToday, 'days');
	}

	getRowClass = (row: Row) => {
		const nd = this.convertDateToNZ(
			row.NextActivity.value ? row.NextActivity.value : ''
		);
		// If 0 meaning its today
		if (nd === 0) {
			return 'isDueDate';
		}
		// if negative meaning overdue
		if (nd < 0) {
			return 'isOverdue';
		}
		if (nd < 6) {
			return 'isDueNextFiveDays';
		}
	};

	getCellClass(metadata: FieldMetadata<any>, row: Row): string {
		if (metadata.metakey === 'Next Activity' && row.NextActivity.value) {
			const rowValue =
				metadata.metakey === 'Next Activity'
					? row.NextActivity.value
					: row.NextActivity.value;
			const nd = this.convertDateToNZ(rowValue);
			// If 0 meaning its today
			if (nd === 0) {
				return 'isDueDate';
			}
			// if negative meaning overdue
			if (nd < 0) {
				return 'isOverdue';
			}
			if (nd < 6) {
				return 'isDueNextFiveDays';
			}
		}
	}

	onToggleSearch(data) {
		this.showSearchFields = data.showSearch;
		if (this.showSearchFields && data.width > 1199) {
			this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
				`calc(100vh - ${104 + data.height}px)`
			);
		} else {
			this.tblHeight =
				this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - 141px)`);
		}
		setTimeout(() => {
			this.service.reloadData();
			document.body.style.overflowY = 'auto';
		}, 500);
	}

	// Dragging column header
	dragHeader() {
		const _tbl = 'ngx-datatable';
		const _header = 'datatable-header';
		const _headerInner = 'datatable-header-inner';
		const _headerCell = 'datatable-header-cell';
		const _body = 'datatable-body';
		const _draggable = 'draggable';
		const _draggingState = 'dragging-state';

		const tblHeaderCell = `${_tbl} ${_header} ${_headerCell}`;
		const tblHeaderCellDrag = `${_tbl} ${_header} ${_headerCell} .${_draggable}`;
		const header = $(`${_tbl} ${_header}`);
		const tblBody = $(`${_tbl} ${_body}`);

		let isDragging = 0;
		let timer;
		let scrollTimer;
		let _self;
		let tranlateLeft;

		const scrollLeftHeader = (pageX, headerWidth) => {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			const innerHeader = header?.find(`.${_headerInner}`);
			const key = 'MSStream';
			const os = /Windows/.test(navigator.userAgent) && !window[key];
			const maxScroll =
				innerHeader[0].scrollWidth - tblBody.width() + (os ? 16 : 0);
			const isDraggingState = $(tblHeaderCell)
				.parent()
				.hasClass(`${_draggingState}`);

			// Check if the mouse is in the left edge of header while dragging
			if (pageX <= 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() > 0) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() - 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft -= 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the left edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
			// Check if the mouse is in the right edge of header while dragging
			if (pageX >= headerWidth - 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() <= maxScroll) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() + 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft += 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the right edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
		};

		$(document)
			.on('mousedown', tblHeaderCellDrag, function (e) {
				if (e.which !== 1) {
					return;
				}
				_self = $(this);
				tranlateLeft = 0;

				isDragging = 1;

				timer = setTimeout(() => {
					_self.closest(tblHeaderCell).parent().addClass(`${_draggingState}`);
				}, 500);
			})

			.on('mouseup', function (e) {
				if (timer) {
					clearTimeout(timer);
				}
				if (scrollTimer) {
					clearTimeout(scrollTimer);
					_self
						.closest(`${_headerCell}`)
						.css('transform', `translate3d(0px, 0px, 0px)`);
				}
				if (isDragging) {
					$(tblHeaderCell)
						.removeClass(`${_draggable}`)
						.parent()
						.removeClass(`${_draggingState}`);
					isDragging = 0;
				}
			})

			// Scroll header when dragging column into the edge of header
			.on('mousemove', function (e) {
				const headerWidth = header.width();
				const pageX = e.pageX - header.offset().left;
				scrollLeftHeader(pageX, headerWidth);
			});
	}

	ngOnDestroy() {
		$(document).off('mousedown mouseup mousemove');

		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	createChoicesFromEntityList(
		list: {
			Id: number;
			Name: string;
		}[]
	): ViewDisplayValue[] {
		return list?.map((x) => ViewDisplayValue.Map(x.Id?.toString(), x.Name));
	}
	createLookupFromEntityList(
		list: {
			Id: number;
			Name: string;
		}[]
	): { [key: string]: string } {
		return util.createLookupFromList(
			(x) => x.Id,
			(x) => x.Name,
			list
		);
	}

	mapToLookupObject = mapToLookupObject;

	onPage(event: any, indexes: any) {
		if (this.query.getValue().count <= 500) {
			return;
		}
		// pre-fetch the next page
		const calc = this.query.getCount() - 2;
		if (calc <= indexes.last) {
			this.loadMore();
		}
	}

	loadMore() {
		const sl = this.table.element.querySelector('.datatable-body').scrollLeft;
		const sh = this.table.bodyComponent.scrollHeight;
		this.query.searchForm$
			.pipe(
				withLatestFrom(this.query.isComplete$),
				withLatestFrom(this.uiQuery.isSearching$),
				map(([[x, isComplete], isSearching]) => {
					const paging = {
						Column: x ? x.Paging.Column : '',
						Direction: x ? x.Paging.Direction : '',
					};

					// Checking if filter has changed
					const compare = comparer(
						request(
							paging,
							this.lrInsuranceFormComponent.prepareFormValue(),
							0
						),
						x
					);
					const currentIndex = x && x.Paging ? x.Paging.Index + 1 : 0;

					return {
						compare,
						isSearching,
						isComplete,
						paging,
						currentIndex,
					};
				}),
				mergeMap((x) => {
					if (x.compare && !x.isSearching && !x.isComplete) {
						return this.service.search(
							request(
								x.paging,
								this.lrInsuranceFormComponent.prepareFormValue(),
								x.currentIndex
							)
						);
					}
					return of(null);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.table.element.querySelector('.datatable-body').scrollTop = sh;
				this.table.element.querySelector('.datatable-body').scrollLeft = sl;
			});
	}
}
