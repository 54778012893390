export interface KiwiSaverDetails {
  AdviceProcessId: number;
  SectionCode: 'FAK';
  FundOwner: (string | number)[];
  Provider: string;
  CurrentBalance: number;
  FundType: string;
  ContributionRate: number;
  RiskProfile: string;
  FromCRM?: boolean;
}

export interface KiwiSaver {
	AdviceProcessId: number;
	CRTId: number;
	CurrentBalance: number;
	ContributionRate?: number;
	FundOwner: [];
	FundType: string;
	Provider: string;
	PendingAdviserApprovap?: boolean;
}

export interface KiwiSaverState {
	adviceProcessId?: number;
	cRTId?: number;
	currentBalance: number;
	contributionRate?: number;
	fundOwner: [];
	fundType: string[];
	provider: string;
	customerServiceID?: number;
	fromCRM?: boolean;
	owner?: (string | number)[];
	pendingAdviserApprovap?: boolean;
}

export interface KiwiSaverInfo {
  AdviceProcessId: number;
  CRTId: number;
  ContributionRate: string;
  CreateDateTime: string;
  CreatedByStaffId: number;
  CreatedByStaffLevel: number;
  CurrentBalance: number;
  CustomerServiceID: number;
  FundType: string;
  ModifiedByStaffId: number;
  ModifiedDateTime: string;
  Owner: [];
  FundOwner: [];
  Provider: string;
  RiskProfile: string;
  SectionCode: string;
  Status: number;
}

export enum KiwiSaverStatus {
	Active = 'Active',
}
