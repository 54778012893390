import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
	CrtKiwisaverFactFind,
	CrtKiwisaverFactFindGatheredInformationStore,
} from './crt-ks-fact-find-gathered-information.store';

@Injectable({ providedIn: 'root' })
export class CrtKiwisaverFactFindGatheredInformationQuery extends QueryEntity<CrtKiwisaverFactFind> {
	allFfInfoGathered$ = this.selectAll();
	
	constructor(protected store: CrtKiwisaverFactFindGatheredInformationStore) {
		super(store);
	}
}
