import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MortgageSettingsComponent } from './mortgage-settings.component';
import { MortgageSettingsResolver } from './mortgage-settings.resolver';

const routes: Routes = [
  {
    path: '',
    component: MortgageSettingsComponent,
    resolve: { data: MortgageSettingsResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MortgageSettingsRoutingModule {}
