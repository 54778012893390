import { PropertySoldDetails, PropertySoldDetailsState } from './property-sold.model';
import { util } from '../../../../../../../../../../util/util'
import MomentUtil from '../../../../../../../../../../util/moment.util';
import { AdviceProcessSectionCodes } from '../../../../../../../../../../shared/models/advice-process/advice-process.model'

import { isNil, isEmpty } from 'ramda';

export class PropertySoldMapper {

  public static mapToView(data: PropertySoldDetailsState): PropertySoldDetailsState {
    return {
      ...data,
      propertyOwner: data?.propertyOwner ? this.parseData(data.propertyOwner) : []
    }
  }

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }

  public static mapToPropertyDetails = (model: PropertySoldDetailsState): PropertySoldDetails => {
		return {
			CRTId: model?.cRTId ?? null,
			AdviceProcessId: model.adviceProcessId,
      ParentCRTId: model.parentCRTId,
			SectionCode: AdviceProcessSectionCodes.PropertySold,
			PropertyAddress: model.propertyAddress,
			PropertyUse: model.propertyUse,
			PropertyValue: isNil(model.propertyValue) || isEmpty(model.propertyValue) ? 0 : model.propertyValue,
			PropertyOwner:
				typeof model.propertyOwner === 'string'
					? model.propertyOwner
					: !!model.propertyOwner
					? JSON.stringify(model.propertyOwner)
					: null,
			Total: null,
			PurchaseDate: !!model.purchaseDate
				? MomentUtil.formatDateToServerDate(model.purchaseDate)
				: null,
			ValuationType: !!model.valuationType
				? model.valuationType
				: !!model.valuationType
				? model.valuationType
				: null,
			ValuationTypeDate: !!model.valuationTypeDate
				? MomentUtil.formatDateToServerDate(model.valuationTypeDate)
				: !!model.valuationTypeDate
				? model.valuationTypeDate
				: null,
			RentalIncome: !!model?.rentalIncome ? model.rentalIncome : null,
			RentalIncomeFrequency: model.rentalIncomeFrequency,
			BoarderIncome: !!model?.boarderIncome ? model.boarderIncome : null,
			BoarderIncomeFrequency: model.boarderIncomeFrequency,
			Title: model.title,
			Rates: +model.rates,
			RatesFrequency: model.ratesFrequency,
			Type: model.type,
			Insurance: model.insurance,
			InsuranceFrequency: model.insuranceFrequency,
			OtherExpense: model.otherExpense,
			OtherExpenseFrequency: model.otherExpenseFrequency,
			SalesPrice: model.salesPrice,
			Status: model.status
		};
	};
}
