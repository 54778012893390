import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	TemplateRef,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
	combineLatest,
	Observable,
	of,
	pipe,
	Subject,
	BehaviorSubject,
} from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import {
	concatMap,
	finalize,
	mergeMap,
	startWith,
	take,
	takeUntil,
	withLatestFrom,
	catchError,
	first,
	tap,
	delay,
	filter,
} from 'rxjs/operators';
import { ViewDisplayValue } from '@models/_general/display-value.viewmodel';
import { LiabilityQuery } from '../../client-sop/assets-and-liabilities/state/liability/liability.query';
import { MortgageQuery } from '../../client-sop/assets-and-liabilities/state/mortgage/mortgage.query';
import { MortgageProviderCommissionService } from '../../provider-comission/state/provider-commission.service';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { RouteService } from '@core/config/route.service';
import { ServiceCalculatorModalComponent } from '@shared/modal/crt/moat/application/service-calculator/service-calculator-modal.component';
import {
	AdviceProcessOnlineRoutes,
	AdviceProcessSectionCodes,
	MoatApplicationStepHeader,
	MoatApplicationStepOrder,
	ReviewAppSubSectionCodes,
} from '@models/advice-process/advice-process.model';
import { Application } from './../state/application.model';
import { ApplicationQuery } from './../state/application.query';
import { ApplicationService } from './../state/application.service';
import { DocumentsComponent } from './documents/documents.component';
import { ApplicationDocumentQuery } from './documents/state/documents.query';
import { ApplicationDocumentService } from './documents/state/documents.service';
import { CashDepositComponent } from './funding-required/cash-deposit/cash-deposit.component';
import { LoanRefinanceComponent } from './funding-required/loan-refinance/loan-refinance.component';
import { LoanRefinanceQuery } from './funding-required/loan-refinance/state/loan-refinance.query';
import { PropertyPurchaseQuery } from './funding-required/property-purchase/state/property-purchase.query';
import { LoanRepaidComponent } from './funding-required/selling-section/loan-repaid/loan-repaid.component';
import { FundingRequiredLoanQuery } from './funding-required/selling-section/loan-repaid/state/loan-repaid.query';
import { SellingSectionComponent } from './funding-required/selling-section/selling-section.component';
import { TopupRequirementsQuery } from './funding-required/topup-requirements/state/topup-requirements.query';
import { TopupRequirementsComponent } from './funding-required/topup-requirements/topup-requirements.component';
import { NotesComponent } from './notes/notes.component';
import { PurposeComponent } from './purpose/purpose.component';
import {
	PurposeDetailsState,
	PurposeTypes,
} from './purpose/state/purpose.model';
import { PurposeQuery } from './purpose/state/purpose.query';
import { ReviewApplicationComponent } from './review-application/review-application.component';
import { ReviewApplicationService } from './review-application/state/review-application.service';
import { SecurityComponent } from './security/security.component';
import { ApplicationStepsService } from './state/application-steps.service';
import { ReviewApplicationState } from './review-application/state/review-application.model';

import { subtract, sum } from 'ramda';
import { DocumentListService } from '@modules/mortgage-settings/document-list/state/document-list.service';
import { SettingsTypes } from '@modules/mortgage-settings/state/mortgage-settings.model';
import { ApplicationMapper } from '../state/application.mapper';
import { FundingRequiredLoan } from './funding-required/selling-section/loan-repaid/state/loan-repaid.model';
import { LoanRefinanceState } from './funding-required/loan-refinance/state/loan-refinance.model';
import { PeopleEntitiesQuery } from '../../client-sop/people-entities/state/people-entities.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { DropdownValuesService } from '@core/dropdown/dropdown-values.service';
import { ServicingCalculatorState } from '@modules/tl-moat-settings/servicing-calculators/state/servicing-calculators-settings.model';
import { ServicingCalculatorSettingsService } from '@modules/tl-moat-settings/servicing-calculators/state/servicing-calculators-settings.service';
import { ApplicationDocument } from './documents/state/documents.model';

@Component({
	selector: 'app-application-steps',
	templateUrl: './application-steps.component.html',
	styleUrls: ['./application-steps.component.scss'],
})
export class ApplicationStepsComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	applicationBanks$ = this.stepsService.applicationBanks$;
	primaryBanks$ = this.stepsService.primarybanks$;
	isPurposeCompleted = false;
	isLoading = false;
	sectionCodes = AdviceProcessSectionCodes;
	stepHeaders = MoatApplicationStepHeader;
	stepSectionCodes = ReviewAppSubSectionCodes;
	stepHeaderOrder = MoatApplicationStepOrder;
	next = false;
	back = false;
	adviceProcessId = this.moatQuery.getValue().adviceProcessId;
	primaryClient = this.moatQuery.getValue().primaryClient;
	isLoadedLoans$ = this.fundingRequiredLoanQuery.isLoadedLoans$;
	getOwnerChoices = this.moatService.getOwnerChoices;
	isInit = true;

	loadSellingSection = false;
	loadRefinanceSection = false;
	checkingReviewApplication = true;
	tabLoading = false;

	progressModalRef: BsModalRef;
	reviewAppLastSaved: string;
	isAutoSaving: boolean;

	@ViewChild('purpose') purpose: PurposeComponent;
	@ViewChild('topups') topups: TopupRequirementsComponent;
	@ViewChild('sellingSection') sellingSection: SellingSectionComponent;
	@ViewChild('loanRefinance') loanRefinance: LoanRefinanceComponent;
	@ViewChild('loanRepaid') loanRepaid: LoanRepaidComponent;
	@ViewChild('security') security: SecurityComponent;
	@ViewChild('notes') notes: NotesComponent;
	@ViewChild('stepper') stepper: MatStepper;
	@ViewChild('cashDeposit') cashDeposit: CashDepositComponent;
	@ViewChild('documents') documents: DocumentsComponent;
	@ViewChild('reviewApplication') reviewApplication: ReviewApplicationComponent;
	@ViewChild('progressTemplate') progressTemplate: TemplateRef<any>;

	sectionTotals$ = new BehaviorSubject<string>(null);

	// ParentCRTId
	applicationId = +this.route.snapshot.paramMap.get('applicationId');
	activeApplication$: Observable<Application> = this.query
		.selectEntity(this.applicationId)
		.pipe(tap((x) => (this.activeApplication = x)));
	applicationDocuments$: Observable<ApplicationDocument[]> = of(null);
	documentsFromSettings$ = of(null);
	clientDocuments$ = of(null);
	isApplicationDocumentLoading$ = this.applicationDocumentQuery.selectLoading();

	hasNewPurchase$ = this.purposeQuery.purpose$.pipe(
		concatMap((purposeList: PurposeDetailsState[]) => {
			const purpose = purposeList?.find(
				(p) =>
					p?.parentCRTId === this.applicationId &&
					p?.types?.includes(PurposeTypes.NewPurchase)
			);
			return purpose ? of(true) : of(false);
		})
	);

	isLendingTopup$ = this.purposeQuery.purpose$.pipe(
		concatMap((purposeList: PurposeDetailsState[]) => {
			const purpose = purposeList.find(
				(p) =>
					p?.parentCRTId === this.applicationId &&
					p?.types?.includes(PurposeTypes.LendingTopup)
			);
			return purpose ? of(true) : of(false);
		})
	);

	isSellingProperty$ = this.purposeQuery
		.selectEntity(this.applicationId)
		.pipe(map((purpose) => purpose?.isSellingProperties));

	isRefinance$ = this.purposeQuery
		.selectEntity(this.applicationId)
		.pipe(map((purpose) => purpose?.types?.includes(PurposeTypes.Refinance)));

	isPurposeLoading$ = combineLatest([
		this.applicationBanks$.pipe(startWith(null as any), pipe(map((x) => !!!x))),
		this.primaryBanks$.pipe(startWith(null as any), pipe(map((x) => !!!x))),
		this.purposeQuery.selectLoading(),
		this.isApplicationDocumentLoading$,
	]).pipe(
		map(([appBanks, primaryBanks, isLoading, applicationDocumentLoading]) => {
			return (
				!!appBanks || !!primaryBanks || isLoading || applicationDocumentLoading
			);
		})
	);

	isFinalizing = false;

	serviceCalculatorBankList: ViewDisplayValue[];

	sectionTotals: any;
	activeBank: string;
	currentPurpose: any;

	downloadProgress: { width: string };
	downloadPercentage: number;
	fundingReqSubmitted = false;
	activeApplication: Application;
	reviewApplicationData: ReviewApplicationState;
	serviceCalculators: ServicingCalculatorState[];

	get DefaultTabIndex() {
		return this.getStepIndex(this.activeApplication?.currentPage) || 0;
	}

	documentSettings = null;

	deceasedSciList$ = this.peopleQuery.deceasedSciList$;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private routeService: RouteService,
		private query: ApplicationQuery,
		private applicationDocumentService: ApplicationDocumentService,
		private moatQuery: CrtMortgageQuery,
		private applicationDocumentQuery: ApplicationDocumentQuery,
		private service: ApplicationService,
		private stepsService: ApplicationStepsService,
		private purposeQuery: PurposeQuery,
		private modalService: BsModalService,
		private reviewService: ReviewApplicationService,
		private fundingRequiredLoanQuery: FundingRequiredLoanQuery,
		private providerCommission: MortgageProviderCommissionService,
		private mortgageQuery: MortgageQuery,
		private liabilityQuery: LiabilityQuery,
		private loanRefinanceQuery: LoanRefinanceQuery,
		private propertyPurchaseQuery: PropertyPurchaseQuery,
		private topupRequirementsQuery: TopupRequirementsQuery,
		private documentSettingsService: DocumentListService,
		private peopleQuery: PeopleEntitiesQuery,
		private moatService: CrtMortgageService,
		private servicingCalcSettingsService: ServicingCalculatorSettingsService,
		private dropdownService: DropdownValuesService
	) {}

	ngOnInit(): void {
		this.service.setAsActive(this.applicationId);
		this.service
			.setCurrentPage(+this.applicationId, MoatApplicationStepHeader.Purpose)
			.pipe(take(1))
			.subscribe();
		this.applicationDocumentService
			.getApplicationDocuments(this.applicationId)
			.pipe(
				mergeMap(() => this.applicationDocumentService.refetchClientDocument()),
				finalize(
					() =>
						(this.applicationDocuments$ =
							this.applicationDocumentQuery.applicationDocuments$)
				),
				take(1)
			)
			.subscribe();

		this.documentSettingsService
			.getDocumentList(0, SettingsTypes.DocumentListSettings)
			.pipe(
				tap((x) => {
					this.documentSettings = x;
				}),
				take(1)
			)
			.subscribe();

		this.servicingCalcSettingsService.servicingCalculators$
			.pipe(
				filter((data) => !!data),
				map((data) => data?.filter((i) => !!i?.isActive)),
				tap((data) => (this.serviceCalculators = data || [])),
				map((data) =>
					data.map((val) => ({
						value: val?.settingsId?.toString(),
						display: val?.documentName || '',
					}))
				),
				take(1)
			)
			.subscribe((data) => {
				this.serviceCalculatorBankList = data;
			});

		combineLatest([
			this.isSellingProperty$,
			this.isLoadedLoans$,
			this.isRefinance$,
		])
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(([isSellingProperty, isLoadedLoans, isRefinance]) => {
				this.loadSellingSection = isSellingProperty;

				if (isSellingProperty && isRefinance) {
					this.loadRefinanceSection =
						isSellingProperty && isLoadedLoans && isRefinance;
				} else {
					this.loadRefinanceSection = isRefinance;
				}
			});

		this.providerCommission.get().pipe(take(1)).subscribe();

		combineLatest([
			this.purposeQuery.purpose$,
			this.mortgageQuery.mortgages$,
			this.liabilityQuery.liabilities$,
			this.fundingRequiredLoanQuery.loans$,
			this.loanRefinanceQuery.loanRefinance$,
			this.propertyPurchaseQuery.properties$,
			this.topupRequirementsQuery.topupRequirementList$,
		])
			.pipe(
				withLatestFrom(this.hasNewPurchase$, this.isLendingTopup$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				([
					[
						purposes,
						mortgages,
						liabilities,
						loanRepaids,
						loanRefinances,
						propertyPurchases,
						topupRequirements,
					],
					hasNewPurchase,
					hasLendingTopup,
				]) => {
					if (
						purposes &&
						mortgages &&
						liabilities &&
						loanRepaids &&
						loanRefinances &&
						propertyPurchases &&
						topupRequirements
					) {
						const currentPurpose = (purposes as any[]).find(
							(purpose) => purpose.parentCRTId === this.applicationId
						);
						this.activeBank = currentPurpose ? currentPurpose.bank : null;

						const sumOfMortgageLiabilities =
							ApplicationMapper.getAllMortgageLiabilities(
								this.activeBank,
								loanRefinances as LoanRefinanceState[],
								mortgages,
								liabilities
							);

						const checkedLoansRepaidTotalWithBank =
							ApplicationMapper.getListWithBankTotal(
								this.activeBank,
								loanRepaids,
								{
									bankKey: 'lender',
									valueKey: 'loanBalance',
									filterKey: 'isTicked',
									filterKeyValue: true,
									activeBank: this.activeBank,
								}
							);

						const checkedLoansRefinanceTotalWithBank =
							ApplicationMapper.getListWithBankTotal(
								this.activeBank,
								loanRefinances,
								{
									bankKey: 'lender',
									valueKey: 'loanValue',
									filterKey: 'isRefinance',
									filterKeyValue: true,
									activeBank: this.activeBank,
								}
							);

						const checkedLoansRefinanceTotal =
							ApplicationMapper.getListWithBankTotal(
								this.activeBank,
								loanRefinances,
								{
									valueKey: 'loanValue',
									filterKey: 'isRefinance',
									filterKeyValue: true,
								}
							);

						const lendingContinuingTotal = subtract(
							sumOfMortgageLiabilities,
							sum([
								checkedLoansRepaidTotalWithBank,
								checkedLoansRefinanceTotalWithBank,
							])
						);

						const propertyPurchasesTotal = ApplicationMapper.getListTotal(
							propertyPurchases,
							{
								valueKey: 'propertyValue',
							}
						);
						const topupRequirementsTotal = ApplicationMapper.getListTotal(
							topupRequirements,
							{
								valueKey: 'topupRequirementValue',
							}
						);

						const newFundingRequiredTotal = sum([
							hasNewPurchase ? propertyPurchasesTotal : 0,
							this.loadRefinanceSection ? checkedLoansRefinanceTotal : 0,
							hasLendingTopup ? topupRequirementsTotal : 0,
						]);

						const sectionTotal = sum([
							lendingContinuingTotal,
							newFundingRequiredTotal,
						]);

						this.sectionTotals = {
							lendingContinuingTotal,
							newFundingRequiredTotal,
							total: sectionTotal,
						};

						this.sectionTotals$.next(this.sectionTotals);
					}
				}
			);
	}

	ngAfterViewInit(): void {
		this.reviewService
			.get(+this.applicationId)
			.pipe(
				withLatestFrom(this.reviewService.reviewApplication$),
				map(([, data]) => data),
				tap((data) => {
					this.reviewApplicationData = data;
					this.checkingReviewApplication = false;
				}),
				delay(10),
				tap(() => {
					this.moatService.setAppCurrentTab(AdviceProcessSectionCodes.Purpose);
					this.setDefaultIndex();
					this.isInit = false;
				}),
				take(1)
			)
			.subscribe();
	}

	fetchClientDocuments(isFetch: boolean) {
		if (isFetch) {
			this.applicationDocumentService
				.refetchClientDocument()
				.pipe(take(1))
				.subscribe();
		}
	}

	setDefaultIndex() {
		// TAPNZ-8062:
		// We’ll revert the current Edit behavior to open back all applications to Purpose for now.
		// if (this.activeApplication) {
		// 	const index = this.DefaultTabIndex;
		// 	this.stepper.selectedIndex = index;
		// }
		if (this.stepper) {
			this.stepper.selectedIndex = 0; // Set to Purpose
		}
	}

	getStepIndex(value: string) {
		if (
			value === 'Finalized' ||
			!!this.reviewApplicationData?.document?.referenceId
		) {
			// If Review Application Document has already been saved
			// Redirect to the Last Tab/Review Application Summary
			return this.stepHeaderOrder?.length - 1;
		}
		return this.stepHeaderOrder?.findIndex((x) => x === value) || 0;
	}

	backToApplications(sectionCode?: string) {
		this.moatService.setAppCurrentTab(null);
		// Base route
		const route = this.routeService.crtPage(
			this.route.snapshot.paramMap.get('customerType') !== 'client',
			+this.route.snapshot.paramMap.get('clientId'),
			+this.route.snapshot.paramMap.get('adviceProcessId'),
			AdviceProcessOnlineRoutes.Mortgage
		);

		if (
			sectionCode &&
			sectionCode === AdviceProcessSectionCodes.ApplicationDocument
		) {
			this.documents.updateSort().subscribe((x) => {
				// Navigate to route with application ID
				this.router.navigate([...route, 'application']);
				this.resetStores();
			});
		} else if (
			sectionCode &&
			sectionCode === AdviceProcessSectionCodes.ReviewApplication
		) {
			this.reviewApplication.leaveSOA('application');
		} else {
			// Navigate to route with application ID
			this.router.navigate([...route, 'application']);
			this.resetStores();
		}
	}

	resetMergeTags() {
		this.reviewApplication.resetAllMergeTags();
	}

	handleSaveComplete(saveResult: {
		isSuccess: boolean;
		isNext: SVGAnimatedBoolean;
		redirect: boolean;
	}) {
		if (saveResult?.isSuccess) {
			this.isLoading = false;
			this.stepper.selected.completed = true;

			if (saveResult?.redirect) {
				if (saveResult?.isNext !== undefined && !!saveResult?.isNext) {
					this.stepper.next();
				}

				if (saveResult?.isNext !== undefined && !saveResult?.isNext) {
					this.stepper.previous();
				}
			}

			this.saveCurrentPage(this.stepper.selected.label);
		} else {
			this.isLoading = false;
		}
	}

	handleToggleLoading(loading: boolean) {
		this.isLoading = loading;
	}

	saveCurrentPage(page = '') {
		const currentPurpose = this.purposeQuery.getEntity(this.applicationId);
		const application = this.service.application(this.applicationId);
		this.service
			.update({
				...application,
				currentPage:
					application?.currentPage === 'Finalized'
						? application?.currentPage
						: page,
				bank: currentPurpose?.bank ? currentPurpose.bank : application.bank,
			})
			.pipe(take(1))
			.subscribe();
	}

	handleNext(sectionCode: string) {
		if (this.isLoading) {
			return;
		}
		this.isLoading = true;
		this.nextSubSection(sectionCode);

		switch (sectionCode) {
			case AdviceProcessSectionCodes.Purpose: {
				this.purpose.save(true);
				break;
			}
			case AdviceProcessSectionCodes.FundingRequired: {
				this.cashDeposit.next();
				this.sellingSection?.clear();
				this.topups?.clear();
				break;
			}
			case AdviceProcessSectionCodes.Security: {
				this.security.save(true);
				break;
			}
			case AdviceProcessSectionCodes.ApplicationLoanStructure: {
				this.saveCurrentPage(this.stepHeaders.Notes);
				this.isLoading = false;
				this.stepper.next();
				break;
			}
			case AdviceProcessSectionCodes.Notes: {
				this.notes.next();
				this.reviewApplication.load();
				this.reviewService.setHasSoaChanges(true);
				this.reviewService.setHasReviewApplicationChanges(true);
				break;
			}
			case AdviceProcessSectionCodes.ApplicationDocument: {
				this.documents.next();
				this.reviewApplication.load();
				this.reviewService.setHasSoaChanges(true);
				this.reviewService.setHasReviewApplicationChanges(true);
				break;
			}
			case AdviceProcessSectionCodes.ReviewApplication: {
				this.reviewApplication.next();
				break;
			}
			default:
				break;
		}
	}

	setFundingReqSubmitted(value: boolean) {
		this.fundingReqSubmitted = value;
	}

	handleBack(sectionCode: string) {
		this.isLoading = true;
		this.backSubSection(sectionCode);

		switch (sectionCode) {
			case AdviceProcessSectionCodes.Purpose: {
				this.purpose.save(false);
				break;
			}
			case AdviceProcessSectionCodes.FundingRequired: {
				this.sellingSection?.clear();
				this.topups?.clear();
				this.cashDeposit.previous();
				break;
			}
			case AdviceProcessSectionCodes.Security: {
				this.security.save(false);
				break;
			}
			case AdviceProcessSectionCodes.Notes: {
				this.notes.previous();
				break;
			}
			case AdviceProcessSectionCodes.ApplicationDocument: {
				this.documents.previous();
				this.saveCurrentPage(this.stepHeaders.Notes);
				break;
			}
			case AdviceProcessSectionCodes.ReviewApplication: {
				this.reviewApplication.leaveSummary();
				break;
			}
			default:
				break;
		}
	}

	nextSubSection(code: string) {
		const index = +(this.stepSectionCodes.findIndex((x) => x === code) || 0);
		const sectionCode = this.stepSectionCodes[index + 1];
		this.moatService.setAppCurrentTab(sectionCode);
	}

	backSubSection(code: string) {
		const index = +(this.stepSectionCodes.findIndex((x) => x === code) || 0);
		const sectionCode = this.stepSectionCodes[index - 1];
		this.moatService.setAppCurrentTab(sectionCode);
	}

	onSelectionChange(stepper: StepperSelectionEvent) {
		if (!!stepper && !!stepper.selectedStep.label) {
			const label = stepper.selectedStep.label;
			switch (label) {
				// Add Secuirty
				case 'Security': {
					this.security.securityAdd();
					break;
				}
				case this.stepHeaders.ReviewApplication: {
					if (this.isInit) {
						this.notes.loadNotes();
					}
					// this.reviewApplication.load();
					break;
				}
				default:
					break;
			}
		}
	}

	resetStores() {
		// Reset data
		this.cashDeposit?.clearData();
		this.reviewApplication?.clearData();

		// this.loanRepaid.clearData();
		// this.loanRefinance.clearData();
	}

	/**
	 * Finalized And Download the Review Application Document
	 */
	finalizedAndDownload() {
		this.isFinalizing = true;
		this.reviewApplication
			.emailReviewApplication()
			.pipe(
				finalize(() => (this.isFinalizing = false)),
				take(1)
			)
			.subscribe();
	}

	downloadServicingCalculator() {
		const clientFileName = this.primaryClient.groupName;
		const downloadServiceCalculatorFn = (req: any) => {
			if (!req.banks.length) {
				return;
			}
			req = {
				SettingsId: req.banks?.map((x) => +x),
				AdviceProcessId: req.adviceProcessId,
				ApplicationId: +this.route.snapshot.paramMap.get('applicationId'),
				FileOutputFormat: 'xlsm',
			};

			const totalBanks = req.SettingsId.length;
			const updateProgressBar = (currentBankIndex: number) => {
				const percentage = Math.floor(
					((currentBankIndex + 1) * 100) / totalBanks
				);
				this.downloadProgress = {
					width: percentage + '%',
				};
				this.downloadPercentage = percentage;
			};

			const endDownload = () => {
				if (this.progressModalRef) {
					this.progressModalRef.hide();
					this.progressModalRef = null;
				}
				this.modalService.onHidden.pipe(first()).subscribe(() => {
					this.downloadProgress = { width: '0%' };
					this.downloadPercentage = 0;
				});
			};

			this.progressModalRef = this.modalService.show(this.progressTemplate, {
				class: 'modal-dialog-centered gray modal-xl w-50',
				ignoreBackdropClick: true,
			});

			if (req.SettingsId.length === 1) {
				updateProgressBar(0);
				req = {
					...req,
					FileOutputFormat: this.getFileExtension(+req.SettingsId[0]),
				};

				return this.service
					.downloadServiceCalculator(req)
					.pipe(finalize(() => endDownload()));
			} else {
				const prepareCalcDownload = (data) => {
					if (data.SettingsId?.length) {
						data = {
							...data,
							FileOutputFormat: this.getFileExtension(+data.SettingsId[0]),
						};
						return this.service.prepareServiceCalcDownload(data).pipe(
							mergeMap((x) => {
								updateProgressBar(totalBanks - data.SettingsId.length);
								return prepareCalcDownload(x);
							}),
							catchError((err) => {
								return of(err);
							})
						);
					}
					data.IsProc;
					return this.service
						.downloadServiceCalculator(data)
						.pipe(finalize(() => endDownload()));
				};

				return of(req).pipe(mergeMap((x) => prepareCalcDownload(x)));
			}
		};

		const initState = {
			header: 'Service Calculator',
			message: `Service Calculator`,
			downloadFn$: downloadServiceCalculatorFn,
			adviceProcessId: this.adviceProcessId,
			clientFileName,
			bankList: this.serviceCalculatorBankList,
			bankDetails: this.serviceCalculators,
		};

		this.modalService.show(ServiceCalculatorModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	getFileExtension(id: number) {
		return (
			this.serviceCalculators?.find((x) => +x?.settingsId === +id)
				?.fileOutputFormat || 'xlsm'
		);
	}

	isTabLoading(event) {
		this.tabLoading = event;
	}

	lastAutoSavedTimeApp(data: string) {
		this.reviewAppLastSaved = data;
	}

	isAutoSaveLoading(status: boolean) {
		this.isAutoSaving = status;
	}

	ngOnDestroy(): void {
		this.resetStores();
		this.moatService.setAppCurrentTab(null);
		this.service.setAsActive(null);
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
