<button
	id="phoneCallAdds_hideModalButton"
	class="tap-btn tap-btn-outline modal-btn-close"
	(click)="close()"
>
	<span class="material-icons"> close </span>
</button>
<div class="modal-body p-2">
	<form [formGroup]="form" [class.submitted]="submitted">
		<div class="row mb-2">
			<div class="col-12">
				<h1 class="modal-title">
					{{ header }}
				</h1>
			</div>
		</div>
		<div class="row mb-2" *ngIf="!!message">
			<div class="col-12">
				<p>{{ message }}</p>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-4">
				<label for="addPhoneCall_ActivityName" class="position-relative">
					Name:
					<span class="tap-required" *ngIf="ActivityName?.invalid"
						>*</span
					></label
				>
			</div>
			<div class="col-8">
				<input
					type="text"
					id="addPhoneCall_ActivityName"
					formControlName="ActivityName"
					class="form-control theme-crm-field-disabled"
				/>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-4">
				<label for="addPhoneCall_date" class="position-relative"
					>Date:<span class="tap-required" *ngIf="DueDate?.invalid"
						>*</span
					></label
				>
			</div>
			<div class="col-8">
				<app-date-input
					textboxId="addPhoneCall_date"
					formControlName="DueDate"
					textboxClass="theme-crm-field-disabled"
					placeholder="dd/mm/yyyy"
					style="flex: 1"
				>
				</app-date-input>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-4">
				<label for="addPhoneCall_time" class="position-relative">
					Time:
					<span class="tap-required" *ngIf="DueTime?.invalid">*</span></label
				>
			</div>
			<div class="col-8">
				<input
					type="text"
					id="addPhoneCall_time"
					formControlName="DueTime"
					class="form-control theme-crm-field-disabled"
					placeholder="13:30"
				/>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-4">
				<label for="addPhoneCall_assignedTo" class="position-relative">
					Assigned To:
					<span class="tap-required" *ngIf="Adviser?.invalid">*</span></label
				>
			</div>
			<div class="col-8">
				<span class="custom-dropdown w-100">
					<select
						id="addPhoneCall_assignedTo"
						formControlName="Adviser"
						class="form-control theme-crm-field-disabled"
					>
						<option value=""></option>
						<option *ngFor="let d of advisers$ | async" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label for="addPhoneCall_details" class="position-relative"
					>Details:</label
				>
			</div>
			<div class="col-8">
				<textarea
					class="form-control theme-crm-field-disabled"
					rows="4"
					id="addPhoneCall_details"
					formControlName="Details"
				>
				</textarea>
			</div>
		</div>
	</form>
	<div class="d-flex justify-content-lg-between flex-row-reverse modal-footer">
		<ng-container *ngIf="!isLoading; else loader">
			<button
				type="button"
				class="tap-btn tap-btn--default"
				(click)="save()"
				id="addPhoneCall_saveBtn"
			>
				Save
			</button>
		</ng-container>
		<ng-template #loader>
			<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
		</ng-template>
	</div>
</div>
