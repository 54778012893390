<span class="tap-required" *ngIf="isEditing && !field.valid">*</span>
<div class="field">
	<div *ngIf="!isEditing; else fieldTemplate" [class]="cellClass">
		<a
			*ngIf="route; else textTemplate"
			class="m-auto"
			[ngClass]="{ clickable: route }"
			[routerLink]="route"
			(click)="onClick()"
			id="{{ fieldId }}Link_{{ index }}"
		>
			{{ display }}
		</a>
	</div>
</div>
<ng-template #textTemplate>
	<span class="m-auto">
		{{ display }}
	</span>
</ng-template>

<ng-container *ngIf="!restrict; else hasRestriction">
	<div *ngIf="isEditable" class="control">
		<button
			*ngIf="!isEditing"
			type="button"
			class="icon-btn-bg btn-edit"
			(click)="edit()"
			id="{{ fieldId }}EditBtn_{{ index }}"
		>
			<i class="material-icons md-16 dark">&#xE3C9;</i>
		</button>
	</div>
</ng-container>

<ng-template #hasRestriction>
	<ng-container *appRestrictTo="restrict">
		<div *ngIf="isEditable" class="control">
			<button
				*ngIf="!isEditing"
				type="button"
				class="icon-btn-bg btn-edit"
				(click)="edit()"
				id="{{ fieldId }}EditBtn_{{ index }}"
			>
				<i class="material-icons md-16 dark">&#xE3C9;</i>
			</button>
		</div>
	</ng-container>
</ng-template>

<div>
	<app-manual-loader-mini
		*ngIf="isLoading"
		[isLoading]="isLoading"
		class="loader-mini"
	></app-manual-loader-mini>
</div>

<div class="edit-controls d-flex flex-column" *ngIf="isEditing && !isLoading">
	<button
		type="submit"
		class="icon-btn-bg btn-save"
		(click)="save()"
		id="{{ fieldId }}SaveBtn_{{ index }}"
	>
		<i class="material-icons md-16 dark">&#xE161;</i>
	</button>
	<button
		type="button"
		class="icon-btn-bg btn-cancel"
		(click)="cancel()"
		id="{{ fieldId }}CancelBtn_{{ index }}"
	>
		<i class="material-icons md-16 dark">&#xE5C9;</i>
	</button>
</div>

<!-- edit mode field -->
<ng-template #fieldTemplate>
	<ng-container *ngIf="!hasSort">
		<select
			[formControl]="field"
			class="form-control"
			id="{{ fieldId }}RowId_{{ index }}"
		>
			<option value=""></option>
			<option
				*ngFor="let d of completeChoices | mapToIterable"
				[ngValue]="d.key"
				[selected]="field.value == d.key"
			>
				{{ d.val }}
			</option>
		</select>
	</ng-container>
	<ng-container *ngIf="hasSort">
		<select
			[formControl]="field"
			class="form-control"
			id="{{ fieldId }}RowId_{{ index }}"
		>
			<option value=""></option>
			<option
				*ngFor="let d of completeChoices | mapToIterable | sortBy: ['val']"
				[value]="d.key"
				[selected]="field.value == d.key"
			>
				{{ d.val }}
			</option>
		</select>
	</ng-container>
</ng-template>
