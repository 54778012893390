import { Injectable } from '@angular/core';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { ApiService } from '../../../../../../core/base/api.service';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../../client-review-template.query';
import { ClientReviewTemplateService } from '../../client-review-template.service';
import { ClientReviewTemplateStore } from '../../client-review-template.store';
import { RiskProfileState } from '../../../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.model';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { mapTo, tap, catchError } from 'rxjs/operators';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { applyTransaction } from '@datorama/akita';
import { objectUtil } from 'src/app/util/util';
import { complement, either, isNil, isEmpty } from 'ramda';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

@Injectable()
export class RiskProfileService
  extends ClientReviewTemplateService
  implements Resolve<boolean>
{
  riskProfile$ = this.query.riskProfile$;
  people$ = this.query.people$;
  peopleWithDependants$ = this.query.peopleAndDependantsFromCRTOnly$;
  endpoint = 'crt/risk-analysis/advice-process/';

  constructor(
    private api: ApiService,
    protected dropdownValueQuery: DropdownValueQuery,
    protected store: ClientReviewTemplateStore,
    protected query: ClientReviewTemplateQuery,
    protected customerService: CustomerService,
    protected businessService: BusinessService
  ) {
    super(dropdownValueQuery, store, query, customerService, businessService);
  }

  getRiskProfile(adviceProcessId: number, sectionCode: string) {
    const endpoint = this.endpoint + `${adviceProcessId}/${sectionCode}`;
    return this.api.get<any>(endpoint).pipe(
      tap((x) =>
        applyTransaction(() => {
          const data = x
            .map((item) => ({
              ...item,
              adviceProcessId,
            }))
            .map(objectUtil.mapPascalCaseToCamelCase) as RiskProfileState;

          const state = complement(either(isNil, isEmpty))(data)
            ? data[0]
            : {
                adviceProcessId,
                insurancePreferences: [],
                event: [],
              } as RiskProfileState;

          this.store.setRiskProfile(state);
        })
      ),
      catchError(() => of([]))
    );
  }

  addRiskProfile(riskProfile: RiskProfileState) {
    const endpoint = `crt`;
    const body = objectUtil.mapCamelCaseToPascalCase(riskProfile);
    return this.api.post3<number>(endpoint, body).pipe(
      tap((x) =>
        applyTransaction(() => {
          const state = {
            ...riskProfile,
            cRTId: +x,
          } as RiskProfileState;
          this.store.setRiskProfile(state);
        })
      ),
      catchError(() => EMPTY)
    );
  }

  updateRiskProfile(riskProfile: RiskProfileState) {
    const endpoint = `crt/${riskProfile.cRTId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(riskProfile);
    return this.api.put<RiskProfileState>(endpoint, body).pipe(
      tap((x) =>
        applyTransaction(() => {
          this.store.setRiskProfile(riskProfile);
        })
      ),
      catchError(() => EMPTY)
    );
  }

  updateRiskProfileState(formValue) {
    applyTransaction(() => {
      const state = this.query.getValue().riskProfile;
      const data = {
        ...state,
        ...formValue,
      };
      this.setHasFormChanges(true);
      this.store.setRiskProfile(data);
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const adviceProcessId = +route.paramMap.get('adviceProcessId');

    const getRiskProfile$ = isNil(this.query.getValue().riskProfile)
      ? this.getRiskProfile(adviceProcessId, AdviceProcessSectionCodes.RiskProfile)
      : of(undefined);

    return forkJoin([getRiskProfile$]).pipe(mapTo(true));
  }
}
