<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
    close
  </span>
</button>
<div>
  <div class="modal-header" >
    <h5 class="modal-title">{{header}}
    </h5>
  </div>
  <div class="modal-body">
    <form novalidate [formGroup]="form">
      <div class="form-group form-inline" *ngIf="contactStatus === 'X'">
        <div class="col-2 px-0">
          <span class="px-0">Lost Reason</span>
        </div>
        <div class="col-10 col-lg-4 px-0">
          <span class="custom-dropdown w-100">
            <select id="lostType" class='form-control theme-crm-field-disabled' formControlName="lostType">
              <option value=""></option>
              <option *ngFor="let d of LT" [value]="d.value">{{d.display}}</option>
            </select>
          </span>
        </div>
      </div>
      <div class="form-group m-0">
        <span class="px-0" for="note">Comment (Optional)</span>
        <div class="clearfix">
          <textarea maxlength="250" class="form-control" name="note" id="note" cols="30" rows="6" formControlName="note"></textarea>
        </div>
      </div>
      <div class="justify-content-end float-right modal-footer">
        <ng-container *ngIf="!(isSaving); else loader">
          <button id="convertButton" type="button" class="tap-btn tap-btn--default" (click)="convert()">{{buttonLabel}}</button>
          <button id="declineButton" type="button" class="tap-btn tap-btn--default " data-dismiss="modal" (click)="decline()">Cancel</button>
        </ng-container>
        <ng-template #loader>
          <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
        </ng-template>
      </div>
    </form>
  </div>
</div>
