export const sosMetaKey = {
	willText: 'KOAT_SOS_WILL_TEXT',
	wontText: 'KOAT_SOS_WONT_TEXT',
	otherAdviceNotes: 'KOAT_SOS_OTHER_ADVICE_NOTES',
	sosOtherAdviceCheckbox: 'KOAT_SOS_OTHER_ADVICE_CHECKBOXES',
	sosWill: 'KOAT_SOS_WILL',
	sosWont: 'KOAT_SOS_WONT',
};

export const sosMergeTags = [
	{
		metaKey: sosMetaKey.willText,
		description: 'KOAT > Scope of Service > What We Will Talk About Today Text',
		value: [''],
		type: 'T',
		secondaryValue: 'Scope of Service what we will talk about today text goes here',
		noWrap: true,
	},
	{
		metaKey: sosMetaKey.wontText,
		description: "KOAT > Scope of Service > What We Won't Talk About Today Text",
		value: [''],
		type: 'T',
		secondaryValue: "Scope of Service what we won't talk about today text goes here",
		noWrap: true,
	},
];

export const koatSosMergeTags = [
	{
		metaKey: sosMetaKey.sosOtherAdviceCheckbox,
		description: 'KOAT > Scope of Service > Other Advices checkbox',
		value: [],
		type: 'T',
		secondaryValue: 'Scope of Service text goes here',
		noWrap: true,
	},
];

export const sosOtherAdvice = {
	lifeAndRiskReview: 'Life & Risk Review',
	houseCarAndContentsInsurance: 'House, Car & Contents Insurance',
	residentialMortgageOrLending: 'Residential Mortgage / Lending',
	otherAdvice: 'Other',
};

export const sosOtherAdviceList = [
	sosOtherAdvice.lifeAndRiskReview,
	sosOtherAdvice.houseCarAndContentsInsurance,
	sosOtherAdvice.residentialMortgageOrLending,
	sosOtherAdvice.otherAdvice,
];
