import { SimpleDataTableModel } from '@shared/models/_general/simple-data-table.model';

export const displayedColumns = [
	'RequirementName',
	'CompletedDate',
	'ProviderBusiness',
	'Category',
	'StructuredHours',
	'ViewAction',
];

export const tableColumns: SimpleDataTableModel[] = [
	{
		columnId: 'RequirementName',
		columnName: 'Requirement',
		headerCellClass: '',
		bodyCellClass: '',
		width: '15%',
		type: 'text',
	},
	{
		columnId: 'CompletedDate',
		columnName: 'Completed Date',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'conditional',
	},
	{
		columnId: 'ProviderBusiness',
		columnName: 'Provider/Business',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
	{
		columnId: 'Category',
		columnName: 'Category',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
	{
		columnId: 'StructuredHours',
		columnName: 'Structured Hours',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
	{
		columnId: 'ViewAction',
		columnName: '',
		headerCellClass: '',
		bodyCellClass: '',
		actionCallback: null,
		actionCallbackText: 'View',
		width: '10%',
		type: 'action',
	},
];
