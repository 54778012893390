// NEW HTML to PDF Config
export const htmlPdfConfig = {
	PageSize: 'A4',
	Orientation: 'Portrait',
	MarginTop: '1cm',
	MarginBottom: '1cm',
	MarginLeft: '2cm',
	MarginRight: '2cm',
	ImageQuality: '92',
	FileName: 'File',
	DPI: '120',
	EnableIntelligentShrinking: 'false',
	MinimumFontSize: '9',
	LoadZoomFactor: '1.22',

	// Others, uncomment if needed to change
	// DefaultEncoding: 'utf-8',
	// LoadJSDelay: '1200',
};

export interface HtmlPdfConfigState {
	FileName?: string;
	PageSize?: string;
	DPI?: string;
	Orientation?: string;
	ImageQuality?: string;
	MarginTop?: string;
	MarginBottom?: string;
	MarginLeft?: string;
	MarginRight?: string;
	EnableIntelligentShrinking?: string;
	MinimumFontSize?: string;
	LoadZoomFactor?: string;
	FoooterHtmlUrl?: string;
	FooterHtmlType?: string;
	FooterHtmlUrlParam?: string;
	HeaderHtmlUrl?: string;
	HeaderHtmlType?: string;
	HeaderHtmlUrlParam?: string;
	filename?: string; // FE only
}

// OLD HTML to PDF Config
export const oldDefaultConfig = {
	margin: [1, 2],
	image: { type: 'jpeg', quality: 0.98 },
	html2canvas: {
		scale: 2,
		letterRendering: true,
		useCORS: true,
		// dpi: 192,
		// ignoreElements: (el: HTMLElement) => el.tagName.toLowerCase() === 'hr',
	},
	jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' },
	pagebreak: {
		mode: 'css',
		avoid: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'tr', 'li', 'p', 'span'],
	},
};
