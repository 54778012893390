<!-- For Top Controls -->
<div
	class="progress-buttons btn-pause-exit d-flex justify-content-end justify-content-lg-between align-items-center"
	*ngIf="isTopControls"
>
	<div class="current-tab pr-md-4 pr-sm-2">
		{{ currentTabTitle }}
	</div>
	<ng-container *ngIf="currentTab | async as tab">
		<ng-container
			*ngIf="!isPause && tab != adviceProcessPageCodes.AuthorityToProceed"
		>
			<button
				id="pauseAndExitBtn"
				type="button"
				class="tap-btn tap-btn--primary btn-progress text-white"
				[disabled]="
					isCRTLoading ||
					(isSavingPI$ | async) ||
					(isSavingSOAReview$ | async) ||
					(isSavingSOA$ | async)
				"
				[class.disabled]="
					isCRTLoading ||
					(isSavingPI$ | async) ||
					(isSavingSOAReview$ | async) ||
					(isSavingSOA$ | async)
				"
				(click)="pauseExit()"
			>
				{{ (isAdviceProcessEnded$ | async) || !!isExit ? "Exit" : "Pause & Exit" }}
			</button>
		</ng-container>
	</ng-container>

	<app-manual-loader-mini
		*ngIf="isPause"
		[isLoading]="true"
	></app-manual-loader-mini>
</div>

<!-- For Bottom Controls -->
<div
	class="btn-next-prev progress-buttons clearfix pt-1 d-flex justify-content-end align-items-center"
	*ngIf="!isTopControls"
	[class.invisible]="hasActiveSOA$ | async"
>
	<div
		class="align-self-end py-1 pr-1 mr-auto documentActionBtnsWrap"
		*ngIf="currentTab | async as tab"
	>
		<ng-container
			*ngIf="!!tab && tab === adviceProcessPageCodes.AuthorityToProceed"
		>
			<button
				id="saveAuthorityToProceedBtn"
				type="button"
				class="tap-btn tap-btn--primary text-white btn-progress mr-1"
				[disabled]="
					!(hasATP$ | async) || !(hasATPSOASelected$ | async) || !!isLoading
				"
				(click)="confirmSaveATP()"
			>
				Save to File
			</button>
			<button
				id="emailAuthorityToProceedBtn"
				type="button"
				class="tap-btn tap-btn--primary text-white btn-progress mr-1"
				(click)="onClickEmailAP()"
				[disabled]="
					!(hasATP$ | async) ||
					!(authorityToProceedSettings$ | async) ||
					!(hasATPSOASelected$ | async) ||
					!!isLoading
				"
			>
				Email Document
				<app-manual-loader-mini
					*ngIf="!(authorityToProceedSettings$ | async)"
					[isLoading]="true"
				></app-manual-loader-mini>
			</button>
			<button
				id="downloadAuthorityToProceedBtn"
				type="button"
				class="tap-btn tap-btn--primary text-white btn-progress mr-1"
				[disabled]="
					!(hasATP$ | async) || !(hasATPSOASelected$ | async) || !!isLoading
				"
				(click)="downloadCADDocument()"
			>
				Download Document
			</button>

			<button
				id="uploadAuthorityToProceedBtn"
				type="button"
				class="tap-btn tap-btn--primary text-white btn-progress"
				(click)="uploadDocument(tab)"
				[disabled]="
					!(hasATP$ | async) || !(hasATPSOASelected$ | async) || !!isLoading
				"
			>
				Upload Document
			</button>
		</ng-container>

		<ng-container *ngIf="tab === adviceProcessPageCodes.FinalStructure">
			<!-- <button
      type="button"
      class="tap-btn tap-btn-primary tap-btn--default font-weight-bold btn-progress"
      (click)="downloadFSDocument()"
    >
      Download
    </button> -->
			<button
				id="emailFinalStructureBtn"
				type="button"
				class="tap-btn tap-btn--primary text-white btn-progress"
				(click)="onClickEmailFS()"
				[disabled]="
					(isLoadingFinalStructure$ | async) ||
					!(finalStructureSettings$ | async) ||
					(isUpdatingFinalStructure$ | async) ||
					!!isLoading ||
					isCRTLoading
				"
			>
				Email Document
				<app-manual-loader-mini
					*ngIf="!(finalStructureSettings$ | async)"
					[isLoading]="true"
				></app-manual-loader-mini>
			</button>
			<button
				id="previewFsPdf"
				type="button"
				class="ml-2 tap-btn tap-btn--primary text-white btn-progress"
				(click)="onClickPreviewFsDocument()"
				[disabled]="
					(isLoadingFinalStructure$ | async) ||
					!(finalStructureSettings$ | async) ||
					(isUpdatingFinalStructure$ | async) ||
					!!isLoading ||
					isCRTLoading
				"
			>
				Preview
			</button>
			<!-- <button
      type="button"
      class="tap-btn tap-btn-primary tap-btn--default font-weight-bold btn-progress"
      (click)="uploadDocument(tab)"
    >
      Upload Document
    </button> -->
		</ng-container>

		<ng-container *ngIf="isLoading">
			<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
		</ng-container>
	</div>

	<ng-container *ngIf="!isCRTLoading && !(isLoading$ | async)">
		<div
			*ngIf="
				(currentTab | async) !== adviceProcessPageCodes.Introduction &&
				!this.isFirstTab()
			"
			class="py-1 mr-1"
		>
			<button
				id="previousBtn"
				type="button"
				class="tap-btn tap-btn--default btn-progress"
				(click)="previous()"
				[disabled]="isLrApUpdateLoading"
			>
				Previous
			</button>
		</div>

		<ng-container
			*ngIf="
				(currentTab | async) !== adviceProcessPageCodes.FinalStructure &&
				(currentTab | async) !== adviceProcessPageCodes.AuthorityToProceed &&
				!this.isLastTab()
			"
		>
			<div class="py-1 mr-1 mr-lg-0">
				<button
					id="nextBtn"
					type="button"
					class="tap-btn text-white tap-btn--primary btn-progress"
					(click)="next()"
					[disabled]="isLrApUpdateLoading"
				>
					Next
				</button>
			</div>
		</ng-container>
		<ng-container
			*ngIf="(currentTab | async) === adviceProcessPageCodes.AuthorityToProceed"
		>
			<div
				class="position-relative py-1"
				[tooltip]="
					'Either save, upload or email the document to complete the application process'
				"
				[isDisabled]="hasFileSavedATP$ | async"
			>
				<button
					id="completeApplicationStageBtn"
					type="button"
					class="tap-btn tap-btn--primary text-white btn-progress"
					(click)="completeApplicationStage()"
					[class.disabled]="
						!(hasATP$ | async) ||
						!(hasFileSavedATP$ | async) ||
						!(hasATPSOASelected$ | async)
					"
					[disabled]="(isUpdatingFinalStructure$ | async)"
				>
					Complete Application Stage
				</button>
			</div>
		</ng-container>

		<ng-container
			*ngIf="(currentTab | async) === adviceProcessPageCodes.FinalStructure"
		>
			<div class="py-1">
				<button
					id="completeBtn"
					type="button"
					class="tap-btn tap-btn--primary text-white btn-progress"
					[disabled]="fsExist(fs$ | async) || (isUpdatingFinalStructure$ | async)"
					(click)="complete()"
				>
					Complete
				</button>
			</div>
		</ng-container>
	</ng-container>

	<!-- <div class="d-block d-lg-none py-1">
		<button
			id="pauseAndExitBtn"
			*ngIf="!isCRTLoading && !isPause"
			type="button"
			class="tap-btn tap-btn--primary text-white btn-progress"
			(click)="pauseExit()"
		>
			{{ (isAdviceProcessEnded$ | async) ? "Exit" : "Pause & Exit" }}
		</button>
		<app-manual-loader-mini
			*ngIf="isPause"
			[isLoading]="true"
		></app-manual-loader-mini>
	</div> -->
	<ng-container
		#loading
		*ngIf="
			isCRTLoading ||
			((currentTab | async) === adviceProcessPageCodes.People &&
				(isLoading$ | async))
		"
	>
		<div class="p-1">
			<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
		</div>
	</ng-container>
</div>
