import { Component, OnInit } from '@angular/core';
import { ReportService } from './state/report.service';
import { combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { SpecialFeaturesSettingsCode } from '@modules/special-features/state/special-features-settings.model';
import { SpecialFeaturesSettingsQuery } from '@modules/special-features/state/special-features-settings.query';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
	isConfirmationCallEnabled$ = combineLatest([
		this.specialFeaturesQuery.isConfirmationCallEnabled$,
		this.businessConfigQuery.specialFeatures$,
	]).pipe(
		map(([hasConCallOn, bsp]) => {
			const hasBusinessConCallOn = bsp?.includes(
				SpecialFeaturesSettingsCode.CC
			);
			return hasConCallOn || hasBusinessConCallOn;
		})
	);

	reportTypes$ = this.reportService.reportTypes$.pipe(
		withLatestFrom(this.isConfirmationCallEnabled$),
		map(([rt, hasConCall]) =>
			rt?.filter((x) =>
				!hasConCall ? x.display !== 'Confirmation Call Report' : true
			)
		),
		withLatestFrom(this.businessConfigQuery.conversionFeature$),
		map(([rt, isConversionEnabled]) =>
			rt?.filter((x) =>
				!isConversionEnabled
					? ![
							'L&R Lead Outcome',
							'Mortgage Lead Outcome',
					  ].includes(x?.display)
					: true
			)
		),
		withLatestFrom(this.businessConfigQuery.claimsFeature$),
		map(([rt, isClaimsenabled]) =>
			rt?.filter((x) =>
				!isClaimsenabled ? x.display !== 'Advice Process Claims Report' : true
			)
		)
	);

	constructor(
		private reportService: ReportService,
		private specialFeaturesQuery: SpecialFeaturesSettingsQuery,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit() {}
}
