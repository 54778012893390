<form novalidate [formGroup]="form">
  <div class="container-fluid px-2">
    <div class="form row d-flex">
      <div class="col-lg-6 py-2">
        <div class="form-row align-items-center">
          <div class="col-lg-6">
            <div class="form-row align-items-center mb-1 mb-lg-0">
              <div class="col-auto">
                <label for=""><strong>Report Dates</strong></label>
              </div>
              <div class="col d-flex">
                <app-date-input
                  formControlName="DateMin"
                  textboxId="dateMinId"
                  textboxClass="form-control theme-crm-field-disabled"
                  placeholder="Min"
                  style="flex:1;"
                ></app-date-input>
                <div class="col-auto align-self-center px-2">to</div>
                <app-date-input
                  formControlName="DateMax"
                  textboxId="dateMaxId"
                  textboxClass="form-control theme-crm-field-disabled"
                  placeholder="Max"
                  style="flex:1;"
                ></app-date-input>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-row align-items-center mb-1 mb-lg-0">
              <div class="col-auto">
                <label for=""><strong>Report Type</strong></label>
              </div>
              <div class="col">
                <span class="custom-dropdown w-100">
                  <select formControlName="ReportType" class="form-control" id="reportTypeId">
                    <option value=""></option>
                    <option *ngFor="let reportType of reportTypes" [value]="reportType.value"
                      >{{ reportType.display }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto py-2 d-flex align-items-center">
        <div class="form-row align-items-center">
          <!-- TODO: Low prio for now -->
          <!-- <div class="col-auto">
            <button type="submit" class="btn btn-primary" [appTheme]="{ 'background-color': 'primarycolor' }"
              (click)="run()" [disabled]="(isRunning$ | async)">
              Run
            </button>
          </div> -->

          <!-- TODO: To add restriction access -->
          <ng-container *appRestrictTo="['FRA']">
            <div
              class="col-auto"
							(mouseenter)="showPopover()"
							[placement]="'bottom'"
							[popover]="(exportStatus$ | async) === 3 ? popTemplate : null"
							containerClass="popover-info-container"
							triggers=""
            >
              <button
                id="exportBtn"
                type="button"
                class="tap-btn tap-btn--default font-weight-bold"
                (click)="export()"
                [disabled]="(isExporting$ | async) || (exportStatus$ | async) === 3 || !form.valid"
              >
                Export
              </button>
            </div>
          </ng-container>
          <div class="col-auto">
            <app-manual-loader-mini [isLoading]="isExporting$ | async"> </app-manual-loader-mini>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #popTemplate>
	<div class="popover-info">
		<p>
			Export currently in progress, please wait until its completion
		</p>
	</div>
</ng-template>
