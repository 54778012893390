import { ProgressStatus } from '@shared/progress-popup/progress';
import { Moment } from 'moment';

export enum Transfer {
	LIMIT = 1000,
}

export enum TransferType {
	CLIENT = 'CTE',
	LEAD = 'LTE',
	LR = 'LRTE',
	UDE = 'UDTE',
	KSE = 'KSTE',
}
export interface TransferAdviceProcess {
	ProcessCode:string;
	CustomerServiceId: string;
	ClientsInvolved:string;
	IsOnline:boolean
}

export type TransferExportPayload = {
	CustomerIds: number[];
	Field: string;
	NewValue: string;
	AdditionalValue?: string;
	IsBulkAdviceProcess?:boolean;
	AdviceProcess?: TransferAdviceProcess;
	IsBulkActivity?: boolean;
	Activity?: TransferBulkActivity;
};

export interface TransferBulkActivity {
	ActivityName: string;
	ActivityType: string;
	Adviser: number;
	Location: string;
	Appointment: boolean;
	Details: string;
	DueDate: string;
	DueTime: string;
	Duration: number;
	Meeting: string;
	isCompleted?: boolean;
	IsActive?: boolean;
}

export interface ExportsState {
	staffId: number | null;
	exportId: string | null;
	reportCode: string | null;
	reportLabel: string | null;
	timeRemaining: number | null;
	timeElapsed: number | null;
	progress: number | null;
	status: ProgressStatus | null;
	attempts: number | null;
	error: unknown;
}
