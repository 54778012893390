import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Resolve
} from '@angular/router';
import { StaffService } from '../../service/staff.service';
import { take } from 'rxjs/operators';

@Injectable()
export class TlStaffBusinessResolver implements Resolve<any> {
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.ss
			.getBusinesses()
			.pipe(take(1));
	}

	constructor(private ss: StaffService) { }
}
