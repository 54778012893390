import { Injectable } from "@angular/core";
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { TapIcons } from './icons.enum';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  public registerIcons(): void {
    this.loadIcons(Object.values(TapIcons), '/assets/icons/svg');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      const keyLower = key.toLocaleLowerCase();
      this.matIconRegistry.addSvgIcon(keyLower, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${keyLower}.svg`));
    });
  }
}