<div class="card-header font-weight-bold text-light tap-bg-primary">
	{{ header }}
</div>
<div class="card-body p-4">
	<form [formGroup]="form" [class.submitted]="submitted" novalidate>
		<!-- 1st row -->
		<div class="form-row mb-3 align-items-center">
			<div class="col-md-3 mb-2">
				<label for="" class="position-relative font-weight-bold">
					Borrowers
				</label>
			</div>
			<div class="col-md-9 mb-2">
				<app-chips
					class="crt-form-control-field"
					textboxClass="theme-crm-field"
					formControlName="borrowers"
					[choices]="policyOwnerChoices(form.get('borrowers').value)"
				>
				</app-chips>
			</div>
		</div>

		<!-- 2nd row -->
		<div class="form-row mb-3">
			<div class="col-md-6">
				<div class="form-row align-items-center">
					<div class="col-md-6 mb-2">
						<label for="loanAmount" class="position-relative font-weight-bold">
							Loan Amount
						</label>
					</div>
					<div class="col-md-6 mb-2">
						<div class="dollar-icon">
							<input
								id="loanAmount"
								formControlName="loanAmount"
								type="text"
								class="form-control crt-form-control"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>

					<div class="col-md-6 mb-2">
						<label for="loanType" class="position-relative font-weight-bold">
							Loan Type
						</label>
					</div>
					<div class="col-md-6 mb-2">
						<span class="custom-dropdown w-100">
							<select
								formControlName="loanType"
								id="loanType"
								class="form-control crt-form-control theme-crm-field-disabled"
							>
								<option value=""></option>
								<option *ngFor="let d of loanTypes$ | async" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>

					<ng-container *ngIf="form.get('loanType').value === 'Interest Only'">
						<div class="col-md-6 mb-2">
							<label
								for="interestOnlyPeriod"
								class="position-relative font-weight-bold"
							>
								Interest Only Period
							</label>
						</div>
						<div class="col-md-6 mb-2">
							<span class="custom-dropdown w-100">
								<select
									id="interestOnlyPeriod"
									formControlName="interestOnlyPeriod"
									class="form-control crt-form-control theme-crm-field-disabled"
								>
									<option [value]="" disabled="">Please Select</option>
									<option *ngFor="let option of interestOnlyPeriodOptions" [value]="option.value">
										{{ option.label }}
									</option>
								</select>
							</span>
						</div>
					</ng-container>

					<div class="col-md-6 mb-2">
						<label
							for="interestRate"
							class="position-relative font-weight-bold"
						>
							Interest Rate
						</label>
					</div>
					<div class="col-md-6 mb-2">
						<div class="dollar-icon dollar-icon-right">
							<input
								id="interestRate"
								formControlName="interestRate"
								class="form-control crt-form-control"
								type="text"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>%</i>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-6">
				<div class="form-row align-items-center">
					<div class="col-md-6 mb-2">
						<label for="fixedPeriod" class="position-relative font-weight-bold">
							Fixed Period
						</label>
					</div>
					<div class="col-md-6 mb-2">
						<span class="crt-custom-dropdown w-100">
							<select
								formControlName="fixedPeriod"
								class="form-control crt-form-control theme-crm-field-disabled"
								id="fixedPeriod"
							>
								<option value=""></option>
								<option
									*ngFor="let fp of fixedPeriods$ | async"
									[value]="fp.value"
								>
									{{ fp.display }}
								</option>
							</select>
						</span>
						<!-- <app-date-input
							textboxId="fixedPeriod"
							class="crt-form-control-field"
							formControlName="fixedPeriod"
							placeholder="dd/mm/yyyy"
						></app-date-input> -->
					</div>

					<div class="col-md-6 mb-2">
						<label for="loanTerm" class="position-relative font-weight-bold">
							Original Loan Term
						</label>
					</div>
					<div class="col-md-6 mb-2">
						<div class="years dollar-icon dollar-icon-right">
							<input
								id="loanTerm"
								formControlName="loanTerm"
								class="form-control crt-form-control pr-5"
								type="text"
								numeric
							/>
							<i>years</i>
						</div>
					</div>

					<div class="col-md-6 mb-2">
						<label
							for="repaymentFrequency"
							class="position-relative font-weight-bold"
						>
							Repayment Frequency
						</label>
					</div>
					<div class="col-md-6 mb-2">
						<span class="crt-custom-dropdown w-100">
							<select
								id="repaymentFrequency"
								formControlName="repaymentFrequency"
								class="form-control crt-form-control theme-crm-field-disabled"
							>
								<option value=""></option>
								<option
									*ngFor="let d of frequencies$ | async"
									[value]="d.value"
								>
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>

					<div class="col-md-6 mb-2">
						<label
							for="haveRequestedRateLock"
							class="position-relative font-weight-bold"
						>
							Have requested ratelock?
						</label>
					</div>
					<div class="col-md-6 mb-2 ">
						<div class="form-check pl-0">
							<input
								formControlName="haveRequestedRateLock"
								class="form-control chckbox"
								id="haveRequestedRateLock"
								type="checkbox"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 3rd row -->
		<div class="form-row mb-3">
			<div class="col-md-3 mb-2 repayment">
				<label for="repaymentAmount" class="position-relative font-weight-bold">
					Repayment Amount
				</label>
			</div>
			<div class="col-md-3 mb-2">
				<div class="form-row align-items-center">
					<div class="col">
						<div class="dollar-icon">
							<input
								[attr.disabled]="true"
								formControlName="repaymentAmount"
								id="repaymentAmount"
								type="text"
								class="form-control crt-form-control font-weight-bold"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
					<div class="col-lg-auto">
						{{ form?.get("repaymentPerFrequency")?.value }}
					</div>
				</div>
			</div>

			<div class="col-md-6 mb-2">
				<textarea
					formControlName="notes"
					placeholder="Notes:"
					class="form-control crt-form-control"
					name="notes"
					id="notes"
					rows="3"
				></textarea>
			</div>
		</div>

		<div class="form-buttons">
			<button
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
				[disabled]="isSaving"
				(click)="save()"
			>
				Save
				<i *ngIf="isSaving" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				type="button"
				[disabled]="isSaving"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancel()"
			>
				Cancel
			</button>
		</div>
	</form>
</div>
