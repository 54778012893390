<div class="main-assets p-4">
	<div class="form-row">
		<div class="column col-lg-12">
			<div class="form-row mb-2 people-label-background">
				<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
					<label for="" class="font-weight-bold label-font tap-text-primary"
						>People</label
					>
				</div>
				<div class="col-5 col-lg-9 text-right iconShow">
					<button
						id="peopleCollapseButton"
						type="button"
						class="btn-toggle bg-white collapse-btn border-0 people-collapse-background"
					>
						<span *ngIf="elseMinusPeople; else elsePlusPeople">
							<i
								(click)="collapseMorePeople()"
								class="material-icons md-20 pt-1"
								>expand_more</i
							>
						</span>
						<ng-template #elsePlusPeople>
							<span>
								<i
									(click)="collapseLessPeople()"
									class="material-icons md-20 pt-1"
									>expand_less</i
								>
							</span>
						</ng-template>
					</button>
				</div>
			</div>

			<div
				class="form-row d-none d-lg-flex mb-2"
				[class.tap-opacity-25]="(people$ | async)?.length === 0"
			>
				<div class="col-lg-3">
					<span class="font-weight-bold">Name</span>
				</div>
				<div class="col-lg-2">
					<span class="font-weight-bold">Date of Birth</span>
				</div>
				<div class="col-lg-2">
					<span class="font-weight-bold">Age</span>
				</div>
			</div>
			<div class="collapse expandable" id="collapsePeople">
				<div
					class="form-row align-items-center"
					*ngFor="
						let p of (people$ | async)?.slice(0, 3);
						let i = index;
						let last = last
					"
				>
					<!-- for small screen -->
						<div class="d-md-none col-lg-2 text-right">
							<ng-container *ngIf="p.cRTId && !isCrtLoading(p?.cRTId)">
								<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnlySmall">
									<button
										id="peopleEditPersonButton_{{ i }}"
										type="button"
										class="icon-btn w-auto h-auto px-1"
										(click)="editPerson(p.cRTId)"
										[disabled]="!!crtLoading"
									>
										<i class="material-icons md-16"> edit </i>
									</button>
									<button
										id="peopleDeletePersonButton_{{ i }}"
										type="button"
										class="icon-btn w-auto h-auto px-1"
										(click)="deletePerson(p.cRTId)"
										[disabled]="!!crtLoading"
									>
										<i class="material-icons md-16">delete</i>
									</button>
								</ng-container>

								<ng-template #viewOnlySmall>
									<button
										id="peopleViewPersonButton_{{ i }}"
										type="button"
										class="icon-btn w-auto h-auto px-1"
										(click)="viewPerson(p.cRTId)"
										[disabled]="!!crtLoading"
									>
										<i class="material-icons md-16"> info </i>
									</button>
								</ng-template>

								<ng-container *ngIf="p.cRTId == -1">
									<button
										id="peopleAddExistingPersonButton_{{ i }}"
										type="button"
										color="primary"
										class="icon-btn w-auto h-auto px-1"
										(click)="addExistingPerson(p)"
										[disabled]="!p.name"
									>
										<i class="material-icons md-16">save</i>
									</button>
									<button
										id="peopleRemovePersonButton_{{ i }}"
										type="button"
										class="icon-btn w-auto h-auto px-1"
										(click)="removePerson(i)"
									>
										<i class="material-icons md-16">close</i>
									</button>
								</ng-container>
							</ng-container>

							<ng-container *ngIf="p.cRTId && isCrtLoading(p?.cRTId)">
								<app-manual-loader-mini
									[isLoading]="true"
								></app-manual-loader-mini>
							</ng-container>
						</div>

					<ng-container *ngIf="p.name">
						<div
							class="col-12 col-lg-3 mb-2"
							[class.border-bottom]="p.name && !last"
							[tooltip]="p.name"
						>
							<input
								id="peopleName_{{ i }}"
								class="form-control crt-form-control name-input pl-0"
								type="text"
								value="{{ p.name }}"
								readonly
								(keydown)="onKeyDown($event)"
							/>
						</div>
					</ng-container>
					<ng-container *ngIf="!p.name">
						<div
							class="col-12 col-lg-3 mb-2"
							[class.border-bottom]="p.name && !last"
						>
							<span class="crt-custom-dropdown w-100">
								<select
									[id]="'addNewPersonDropdown-' + i"
									name="addNewPersonDropdown"
									class="form-control crt-form-control"
									(change)="change($event, i)"
								>
									<option value=""></option>
									<option
										*ngFor="let d of peopleList$ | async"
										[value]="d.value"
									>
										{{ d.display }}
									</option>
									<option value="new">+ Add New Person</option>
								</select>
							</span>
						</div>
					</ng-container>
					<div
						class="col-12 col-lg-2 mb-2"
						[class.border-bottom]="p.name && !last"
					>
						<input
							*ngIf="p.name && p.name !== 'new'"
							id="peopleBirthDate_{{ i }}"
							class="form-control crt-form-control"
							type="text"
							value="{{ p.birthDate | momentDatetime }}"
							[disabled]="p.name"
						/>
					</div>
					<div
						class="col-12 col-lg-2 mb-2"
						[class.border-bottom]="p.name && !last"
					>
						<input
							*ngIf="p.name && p.name !== 'new'"
							id="peopleAge_{{ i }}"
							class="form-control crt-form-control"
							type="text"
							value="{{ p.age }}"
							[disabled]="p.name"
						/>
					</div>
					<!-- for big screen -->
					<div
						class="d-none d-md-block col-auto border-bottom"
						[class.border-bottom]="p.name && !last"
					>
						<ng-container *ngIf="!(isAdviceProcessEnded$ | async); else viewOnly">
							<ng-container *ngIf="p.cRTId != -1 && !isCrtLoading(p?.cRTId)">
								<button
									id="peopleEditButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="editPerson(p.cRTId)"
									[disabled]="!!crtLoading"
								>
									<i class="material-icons md-16">edit</i>
								</button>
								<button
									id="peopleDeleteButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="deletePerson(p.cRTId)"
									[disabled]="!!crtLoading"
								>
									<i class="material-icons md-16">delete</i>
								</button>
							</ng-container>

							<ng-container *ngIf="p.cRTId == -1 && !isCrtLoading(p?.cRTId)">
								<button
									id="peopleAddExistingPersonButton_{{ i }}"
									color="primary"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="addExistingPerson(p)"
									[disabled]="!p.name"
								>
									<i class="material-icons md-16">save</i>
								</button>
								<button
									id="peopleRemovePersonButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="removePerson(i)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</ng-container>

						<ng-template #viewOnly>
							<ng-container *ngIf="p.cRTId != -1 && !isCrtLoading(p?.cRTId)">
								<button
									id="peopleView_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="viewPerson(p.cRTId)"
								>
									<i class="material-icons md-16"> info </i>
								</button>
							</ng-container>
						</ng-template>

						<ng-container *ngIf="isCrtLoading(p?.cRTId)">
							<app-manual-loader-mini
								[isLoading]="true"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</div>
				<div class="form-row my-4">
					<div class="col-auto">
						<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
							<ng-container *ngIf="(people$ | async)?.length < 3">
								<button
									id="peopleAddPersonButton"
									type="button"
									(click)="addPerson()"
									class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
									[disabled]="
										isAddNew ||
										(isPeopleLoading$ | async) ||
										isDeleting ||
										peopleIsLoading
									"
								>
                  Add Person +
								</button>
							</ng-container>
							<ng-container *ngIf="(people$ | async)?.length >= 3">
								<label> Maximum of 3 people reached </label>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="(isPeopleLoading$ | async) || isDeleting">
							<app-manual-loader-mini
								[isLoading]="(isPeopleLoading$ | async) || isDeleting"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</div>
				<div class="border-bottom mb-3"></div>
			</div>

			<app-dependent
				[dependents$]="dependents$"
				[SCR$]="SCR$"
				[APCRTG$]="APCRTG$"
				[isDependantsLoading$]="isDependantsLoading$"
				[addFn$]="addExistingDependentFn$"
				[addNewFn$]="addNewDependentFn$"
				[updateFn$]="updateDepentdentFn$"
				[deleteFn$]="deleteDepentdentFn$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
			></app-dependent>
			<app-trust
				[SCTT$]="SCTT$"
				[trusts$]="trusts$"
				[isTrustsLoading$]="isTrustsLoading$"
				[addFn$]="addExistingTrustFn$"
				[addNewFn$]="addNewTrustFn$"
				[updateFn$]="updateTrustFn$"
				[deleteFn$]="deleteTrustFn$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
			></app-trust>
			<app-company
				[APCRTBP$]="APCRTBP$"
				[APCRTYNNA$]="APCRTYNNA$"
				[company$]="company$"
				[isCompanyLoading$]="isCompanyLoading$"
				[addFn$]="addExistingCompanyFn$"
				[addNewFn$]="addNewCompanyFn$"
				[updateFn$]="updateCompanyFn$"
				[deleteFn$]="deleteCompanyFn$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
			></app-company>
		</div>
	</div>
</div>
