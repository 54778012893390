<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Mortgage Online Advice Tool</h1>
	</div>

	<div class="row tap-container service-tabs crt-settings">
		<div class="col">
			<tabset>
				<ng-container>
					<tab heading="Servicing Calculator">
						<app-servicing-calculators></app-servicing-calculators>
					</tab>
				</ng-container>
			</tabset>
		</div>
	</div>
</div>
