import { Component } from '@angular/core';
import { UserQuery } from 'src/app/domain/user/user.query';

@Component({
	selector: 'app-fact-find-settings',
	templateUrl: './fact-find-settings.component.html',
})
export class KiwiFactFindSettingsComponent{
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;

	constructor(
		private userQuery: UserQuery
	) {}

}
