import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { ComponentBase } from 'src/app/core/base/component-base';
import { DashboardQuery } from '../../state/dashboard.query';
import { APTService } from './state/apt.service';
import { APTQuery } from './state/apt.query';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-apt',
	templateUrl: './apt.component.html',
	styleUrls: ['./apt.component.scss'],
})
export class AptComponent extends ComponentBase implements OnInit, OnDestroy {
	public static widgetCode = 'apt';
	public static widgetName = 'Advice Tracking Process';
	public static sizeX = 500;
	public static sizeY = 480;
	public static minSizeX = 500;
	public static minSizeY = 480;

	isLoading$: Observable<boolean> = this.query.isLoading$;

	widgetCode = AptComponent.widgetCode;

	link: string;

	customers$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => [...x.Customers].sort((a, b) => b.StatusClock - a.StatusClock)),
		takeUntil(this.onDestroy$)
	);

	target$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x.Target),
		takeUntil(this.onDestroy$)
	);

	customerCount$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x.Customers.length),
		takeUntil(this.onDestroy$)
	);

	constructor(
		private service: APTService,
		private query: APTQuery,
		private dashboardQuery: DashboardQuery,
		private route: ActivatedRoute
	) {
		super();
		const companyCode = this.route.snapshot.paramMap.get('companyCode');
		this.link = `/${companyCode}/pipeline/adviceprocess`;
	}

	ngOnInit() {
		this.dashboardQuery.adviserFilter$
			.pipe(
				filter((x) => x.length > 0),
				takeUntil(this.onDestroy$)
			)
			.subscribe((advisers) => {
				this.service.GetAPTracking({
					advisers
				});
			});
	}

	ngAfterViewInit() {
		this.initializeTableDomConfig();
	}

		/**
	 * HTML Dom manipulations
	 */
	private initializeTableDomConfig() {
		$(() => {
			const tblHeader = 'datatable-header';
			const tblHeaderCell = `${tblHeader}-cell`;

			const dragState = 'dragging-state';

			let timer;

			$(document)
				// On mouse down on header cell
				.on('mousedown', '.draggable', function() {
					const _ = $(this);
					timer = setTimeout(() => {
						_.closest(`.${tblHeaderCell}`).parent().addClass(`${dragState}`);
					}, 500);
				})
				// On mouseup
				.on('mouseup', () => {
					if (timer) {
						clearTimeout(timer);
					}

					const isDragging = $(`.${dragState}`).length;
					if (isDragging) {
						$(`.${tblHeaderCell}`).parent().removeClass(`${dragState}`);
					}
				});
		});
	}

	getStatusColor(items, max) {
		if (isNaN(items / max)) {
			return 'green';
		}

		const value = ((items / max) * 100).toFixed(1);

		if (+value < 80) {
			return 'green';
		}

		if (+value >= 80.1 && +value <= 95) {
			return 'orange';
		}
		
		if (+value >= 95.1) {
			return 'red';
		}

		return 'green';
	}

	ngOnDestroy() {
		super.dispose();
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
