import { ProvidersSettingsState, ProviderLogoState } from './providers-settings.model';
import { SettingsTypes } from '../../../state/kiwisaver-settings.model';

export class ProviderSettingsMapper {
	public static mapToView(data: ProvidersSettingsState) {
		const {
			providers,
			cRTSettingsId
		} = data;

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFindProviders,
			cRTSettingsId, 
			providers
		};
	}

	public static mapToUpsert(data: ProvidersSettingsState) {
		const {
			providers,
			cRTSettingsId
		} = data;

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFindProviders,
			cRTSettingsId, 
			providers
		};
	}

	public static mapToUpsertLogoUpload(data: any) {
		return {
			...data,
			referenceId: 0,
			type: SettingsTypes.KOATFactFindProviders,
		};
	}
}
