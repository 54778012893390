import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { AdviceSummaryEmailSettingsState } from './state/advice-summary-email-settings.model';
import { AdviceSummaryEmailSettingsMapper } from './state/advice-summary-email-settings.mapper';
import { ComponentBase } from 'src/app/core/base/component-base';
import { AdviceSummaryEmailSettingsService } from './state/advice-summary-email-settings.service';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import {
	filter,
	tap,
	map,
	mergeMap,
	take,
	withLatestFrom,
	concatMap,
	finalize,
	catchError,
} from 'rxjs/operators';
import { Observable, combineLatest, iif, of } from 'rxjs';
import { objectUtil, convertUtil } from 'src/app/util/util';
import * as R from 'ramda';

@Component({
	selector: 'app-advice-summary-email-settings',
	templateUrl: './advice-summary-email-settings.component.html'
})
export class AdviceSummaryEmailSettingsComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@Input() emailSettings: AdviceSummaryEmailSettingsState;
	@Input() mergeTags$: Observable<MergeTagState[]>;
	@Input() adviceSummaryData: any;

	adviceSummaryEmailSettings$ =
		this.adviceSummaryEmailSettingsService.adviceSummaryEmailSettings$;

	isLoading = false;
	bodyContent = '<p></p>';
	bodyId: number;
	shortCodes: object;

	constructor(
		private adviceSummaryEmailSettingsService: AdviceSummaryEmailSettingsService
	) {
		super();
	}

	ngOnInit(): void {
		this.prepData();
	}

	ngOnDestroy(): void {
		super.dispose();
	}

	prepData() {
		combineLatest([this.adviceSummaryEmailSettings$, this.mergeTags$])
			.pipe(
				filter(([data, mt]) => !!data && !!mt),
				tap(
					([_data, mt]) =>
						(this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
				),
				tap(([data, _mt]) => (this.bodyId = data?.body ? +data?.body : 0)),
				map(([data, _mt]) =>
					AdviceSummaryEmailSettingsMapper.mapEmailSettingsData(data || {})
				),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.adviceSummaryEmailSettingsService.getFile(data?.body ? +data?.body : 0)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.adviceSummaryEmailSettingsService.getDocumentFromURL(
							res?.documentLink
						),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save(emailSettings: any): void {
		this.isLoading = true;
		of(emailSettings)
			.pipe(
				mergeMap((currentTemplate) => {
					return convertUtil.convertToBase64(currentTemplate?.bodyContent);
				}),
				withLatestFrom(of(this.bodyId)),
				map(([currentTemplate, bodyId]) =>
					AdviceSummaryEmailSettingsMapper.mapStaffEmailBody(
						currentTemplate,
						bodyId,
						0
					)
				),
				concatMap((data) =>
					iif(
						() => R.isNil(data.documentId),
						this.adviceSummaryEmailSettingsService
							.newFileUpload(data)
							.pipe(catchError((err) => of(err))),
						this.adviceSummaryEmailSettingsService.updateFileUpload(data)
					)
				),
				withLatestFrom(of(this.bodyId)),
				concatMap(([data, body]) => {
					const isNewBody = body === 0 || !body;
					const newBodyId = isNewBody ? data : body;
					const formValue = AdviceSummaryEmailSettingsMapper.mapEmailSettingsToUpsert(
						{
							...emailSettings,
							referenceId: 0,
							body: newBodyId,
						}
					);
					this.bodyId = newBodyId;
					return this.adviceSummaryEmailSettingsService.updateSettings(formValue);
				}),
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe();
	}
}
