<form [formGroup]="form" [class.submitted]="submitted">
	<div class="form-row collapse-label-background">
		<div class="col-8 mb-3">
			<label for="" class="font-weight-bold label-font tap-text-primary">
				Family History
			</label>
		</div>
	</div>

	<div class="">
		<div formArrayName="familyArray">
			<div
				class="form-row mb-2 font-weight-bold"
				[class.tap-opacity-25]="FamilyArray.controls.length === 0"
			>
				<div class="col-12 col-lg-3"></div>
				<div class="col-12 col-lg-2">Health Issue</div>
				<div class="col-12 col-lg-2">Relationship</div>
				<div class="col-12 col-lg-2">Age of Diagnosis</div>
				<div class="col-12 col-lg-2">Current Terms</div>
			</div>
			<div
				class="form-row align-items-center"
				*ngFor="
					let item of FamilyArray.controls;
					let pointIndex = index;
					let last = last
				"
				[formGroupName]="pointIndex"
			>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<div class="d-lg-none col-lg-2 text-right">
						<div *ngIf="!item.get('isSaving').value">
							<ng-container *ngIf="item.get('btnSaveFamily').value">
								<button
									id="family_saveFamilyButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="saveFamily(pointIndex)"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnEditFamily').value">
								<button
									id="family_saveFamilyButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editFamily(pointIndex)"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnEditFamily').value &&
									pointIndex < familyArray.length
								"
							>
								<button
									id="family_deleteFamilyButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="deleteFamily(pointIndex)"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnSaveFamily').value">
								<button
									id="family_removeFamilyButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removeFamily(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</div>
						<ng-container *ngIf="item.get('isSaving').value">
							<app-manual-loader-mini
								[isLoading]="item.get('isSaving').value"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</ng-container>
				<div class="col-12 col-lg-3 mb-2">
					<span
						class="w-100"
						[class.crt-custom-dropdown]="item.get('client').enabled"
					>
						<select
							id="family_clientFamily_{{ pointIndex }}"
							class="crt-form-control form-control theme-crm-field-disabled"
							name="clientFamily"
							formControlName="client"
							placeholder="Client"
							(change)="onSelect(pointIndex)"
							[class.select-text-muted]="!item.get('client').value"
						>
							<option class="select-text-muted" selected hidden value="">
								Client
							</option>
							<option
								*ngFor="let gpClient of clientList"
								[value]="gpClient.value"
								class="select-text-option"
							>
								{{ gpClient.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<input
						id="family_healthIssue_{{ pointIndex }}"
						class="crt-form-control form-control"
						type="text"
						formControlName="healthIssue"
						placeholder="Health Issue"
					/>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<span
						class="w-100"
						[class.crt-custom-dropdown]="item.get('familyMember').enabled"
					>
						<select
							id="family_familyMember_{{ pointIndex }}"
							class="crt-form-control form-control"
							formControlName="familyMember"
							[class.select-text-muted]="!item.get('familyMember').value"
						>
							<option
								class="select-text-muted"
								[attr.disabled]="true"
								selected
								hidden
								value
							>
								Relationship
							</option>
							<option value=""></option>
							<option
								*ngFor="let dropdown of apcrtfm | async"
								[value]="dropdown.value"
								class="select-text-option"
							>
								{{ dropdown.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<input
						id="family_ageAtDiagnosis_{{ pointIndex }}"
						class="crt-form-control form-control"
						type="number"
						formControlName="ageAtDiagnosis"
						placeholder="Age at Diagnosis"
						onkeydown="javascript: return event.keyCode === 8 ||
            event.keyCode === 46 ? true : !isNaN(Number(event.key))"
					/>
				</div>
				<div class="col-12 col-lg-2 mb-1">
					<span
						class="w-100"
						[class.crt-custom-dropdown]="
							item.get('currentlyExcludedLoaded').enabled
						"
					>
						<select
							id="family_currentlyExcludedLoaded_{{ pointIndex }}"
							class="crt-form-control form-control"
							name="exclude"
							formControlName="currentlyExcludedLoaded"
							[class.select-text-muted]="
								!item.get('currentlyExcludedLoaded').value
							"
						>
							<option
								class="select-text-muted"
								[attr.disabled]="true"
								selected
								hidden
								value
							>
								Current Terms
							</option>
							<option value=""></option>
							<option
								*ngFor="let d of currentTerms$ | async"
								class="select-text-option"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<div class="d-none d-lg-block col-lg-1 text-right">
						<div *ngIf="!item.get('isSaving').value">
							<ng-container *ngIf="item.get('btnSaveFamily').value">
								<button
									id="family_saveFamilyButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="saveFamily(pointIndex)"
									[disabled]="item.get('btnDisable').value"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnEditFamily').value">
								<button
									id="family_editFamilyButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editFamily(pointIndex)"
									[disabled]="isEditMode || isAddMode"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnEditFamily').value &&
									pointIndex < familyArray.length
								"
							>
								<button
									id="family_deleteFamilyButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="deleteFamily(pointIndex)"
									[disabled]="isEditMode || isAddMode"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnSaveFamily').value">
								<button
									id="family_removeFamilyButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removeFamily(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</div>
						<ng-container *ngIf="item.get('isSaving').value">
							<app-manual-loader-mini
								[isLoading]="item.get('isSaving').value"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</ng-container>
				<div class="col-12 col-lg-12 mb-2" [class.border-bottom]="!last">
					<div class="col-12 col-lg-11 p-0">
						<textarea
							#familyDetailsTextArea
							id="family_details_{{ pointIndex }}"
							class="crt-form-control form-control mb-3"
							formControlName="details"
							placeholder="Details"
							rows="3"
							[class.test]="isAdviceProcessEnded"
							[style.height]="'100px'"
						></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="form-row my-4">
			<div class="col-5 col-lg-6">
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="family_addFamilyButton"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 border-0 col-auto mb-2"
						(click)="addFamily()"
						[disabled]="(isFamilyLoading$ | async) || isAddMode || isEditMode"
					>
						Add Family History +
					</button>
				</ng-container>
				<ng-container *ngIf="isFamilyLoading$ | async">
					<app-manual-loader-mini
						[isLoading]="isFamilyLoading$ | async"
					></app-manual-loader-mini>
				</ng-container>
			</div>
			<div class="col-7 pt-1 col-lg-6 text-right"></div>
		</div>
		<div class="form-row line mb-3"></div>
	</div>
</form>
