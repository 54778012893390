<form [formGroup]="form" class="form email-form" [class.submitted]="submitted">
  <div class="row">
    <div class="col-12">
      <ng-container>
        <div *ngIf="isECTemplate" class="row pb-2 align-items-center">
          <div class="col-12 col-lg-2 email-form__label">
            <label class="form-check-label font-weight-bold">Template Name:</label>
          </div>
          <div class="col-12 email-form__dropdown" [ngClass]="isECTSLoading ? 'col-lg-9' : 'col-lg-10'">
            <span class="crt-custom-dropdown w-100">
              <select
                id="emailClientTemplate"
                formControlName="emailClientTemplate"
                (change)="setECTemplateContents($event)"
                class="form-control theme-crm-field-disabled crt-form-control"
								[attr.disabled]="isECTSLoading ? '' : null"
              >
                <option value="" selected>Blank Template</option>
                <option *ngFor="let item of ecTemplateOptions" [value]="item.value">
                  {{ item.display }}
                </option>
              </select>
            </span>
          </div>
          <div *ngIf="isECTSLoading" class="card-body__loader float-lg-left float-right">
            <app-manual-loader-mini [isLoading]="isECTSLoading"></app-manual-loader-mini>
          </div>
        </div>
				<div class="row pb-2 align-items-center">
          <div class="col-12 col-lg-2 email-form__label">
            <label class="form-check-label font-weight-bold">From:</label>
          </div>
          <div class="col-12 col-lg-5 email-form__dropdown">
            <span class="crt-custom-dropdown w-100">
              <select
								#senderDropdown
                id="senderDropdown"
                formControlName="senderDropdown"
                (change)="setSender($event)"
                class="form-control theme-crm-field-disabled crt-form-control"
              >
                <option *ngFor="let item of fromOption" [value]="item.value">
                  {{ item.display }}
                </option>
              </select>
            </span>
          </div>
					<ng-container
						*ngIf="senderDropdown.value === ''; else showDefaultSenderDropdown"
					>
						<div class="col-12 col-lg-4 email-form__textbox">
							<app-chips
								[formControl]="OtherSearch"
								valueId="otherSearch"
								textboxId="otherSearch"
								class="crt-form-control"
								[collapsible]="false"
								[showRemoveIcon]="true"
								[limit]="1"
								[choices]="otherSearchList$ | async"
								[class.ng-invalid]="!this.OtherSearch.value?.length"
								[class.pointer-events-none]="this.loadingSignature"
								(onChangeEvent)="selectFromOtherSearch($event)"
							>
							</app-chips>
						</div>
						<div
							class="col-1"
							*ngIf="
								(otherSearchList$ | async) === null || this.loadingSignature
							"
						>
							<app-manual-loader-mini
								[isLoading]="true"
							></app-manual-loader-mini>
						</div>
					</ng-container>
					<ng-template #showDefaultSenderDropdown>
						<div class="col-12 col-lg-4 email-form__textbox">
							<!-- USE READONLY SO EMAIL VALIDATION WILL STILL BE CALLED -->
							<label> {{senderDropdown.value}}</label>
							<!-- <input
								type="hidden"
								[readonly]="senderDropdown.value"
								formControlName="sender"
							/> -->
						</div>
					</ng-template>
        </div>
        <div class="row pb-2 align-items-center">
          <div class="col-12 col-lg-2 email-form__label">
						<label class="form-check-label font-weight-bold">{{ sendToLabel }}:</label>
          </div>
          <div class="col-12 col-lg-5 email-form__dropdown">
            <span class="crt-custom-dropdown w-100">
              <select
                id="emailDropdown"
                formControlName="emailDropdown"
                (change)="getCrtInfo($event)"
                class="form-control theme-crm-field-disabled crt-form-control"
              >
								<option *ngFor="let i of toOption" [value]="i.value">
									{{ i.display }}
								</option>
								<option *ngIf="!!!peopleList?.length" value=""></option>
								<option *ngIf="showAdviserOnRecipient" [value]="senderOptions.adviser">Adviser</option>
								<option *ngIf="!!!peopleList?.length" [value]="senderOptions.other">Other</option>
                <option *ngFor="let item of peopleList" [value]="item.value">
                  {{ item.display }}
                </option>
              </select>
            </span>
          </div>
					<div class="col-12 email-form__textbox" [ngClass]="disableEmailUpdate ? 'col-lg-5' : 'col-lg-4'">
						<ng-container *ngIf="EmailDropdown?.value !== senderOptions.adviser; else showStaticEmail">
							<input
								type="text"
								id="email"
								class="form-control crt-form-control"
								placeholder="Enter email address"
								formControlName="emailRecipient"
							/>
						</ng-container>
						<ng-template #showStaticEmail>
							<label>{{ EmailRecipient?.value || '' }}</label>
						</ng-template>
					</div>
					<div class="col-12 col-lg-1 pl-lg-0" *ngIf="!disableEmailUpdate">
						<div
							class="card-body__save-button float-lg-left float-right"
							*ngIf="!isLoading"
						>
							<button
								id="saveEmailBtn"
								type="button"
								class="icon-btn pr-0 d-inline-block"
								*ngIf="
									!!EmailDropdown?.value &&
									EmailDropdown?.value !== senderOptions.other &&
									EmailDropdown?.value !== senderOptions.adviser &&
									EmailDropdown?.value !== senderOptions.business
								"
								(click)="saveCRTEmail()"
							>
								<i class="material-icons md-16"> save </i>
							</button>
						</div>

						<div
							class="card-body__loader float-lg-left float-right"
							*ngIf="isLoading"
						>
							<app-manual-loader-mini
								[isLoading]="isLoading"
							></app-manual-loader-mini>
						</div>
					</div>
        </div>
        <div class="row pb-2 align-items-center">
          <div class="col-12 col-lg-2 email-form__label">
            <label class="form-check-label font-weight-bold">CC:</label>
          </div>
          <div class="col-12 col-lg-10 email-form__textbox">
            <span *ngIf="ccAdvisers && adviserDetails">{{adviserDetails?.FirstName}} {{adviserDetails?.LastName}} ,</span>
            <input
              id="carbonCopy"
              type="text"
              class="form-control theme-crm-field-disabled crt-form-control"
              formControlName="carbonCopy"
            />
          </div>
        </div>
        <div class="row pb-2" [ngClass]="enableDefaultBcc ? 'pt-2' : 'align-items-center'">
          <div class="col-12 col-lg-2 email-form__label">
              <label class="form-check-label font-weight-bold">BCC:</label>
          </div>
          <div class="col-12 col-lg-10 email-form__textbox">
            <div class="pb-2" *ngIf="enableDefaultBcc">
              <label>{{ form.get('sender')?.value }}</label>
              <br/>
            </div>
            <input
              id="blindCarbonCopy"
              type="text"
              class="form-control theme-crm-field-disabled crt-form-control"
              formControlName="blindCarbonCopy"
            />
          </div>
        </div>
        <div class="row pb-2 align-items-center">
          <div class="col-12 col-lg-2 email-form__label">
            <label class="form-check-label font-weight-bold">Subject:</label>
          </div>
          <div class="col-12 col-lg-10 email-form__textbox">
            <input
              id="subject"
              type="text"
              class="form-control theme-crm-field-disabled crt-form-control"
              formControlName="subject"
							appClipboardNoFormat
            />
          </div>
        </div>
        <div class="row align-items-center pb-3 email-form__wysiwyg">
          <div class="col-12 col-lg-12 email-form__label">
            <label class="form-check-label font-weight-bold">Body:</label>
          </div>
          <div class="col-12 col-lg-12 mt-2 email-form__wysiwyg" [ngClass]="{'content-loading' : isECTSLoading}">
           <div [ngClass]="{'body-content-invalid' : isBodyContentInvalid && submitted}">
            <app-wysiwyg
              #contentEditor
              [content]="bodyContent"
              [simpleEditor]="true"
              [extendOptions]="optionsWysiswyg"
              [shortcodes]="shortCodes"
            ></app-wysiwyg>
           </div>
          </div>
        </div>
        <div class="row pb-2" *ngIf="showEmailTemplate">
          <div class="col-12 col-lg-2 email-form__label">
            <label class="form-check-label font-weight-bold"
              >Email Template:</label
            >
          </div>
          <div class="col-12 col-lg-10 email-form__textbox">
            <input
              id="emailTemplate"
              type="text"
              class="form-control theme-crm-field-disabled crt-form-control"
              formControlName="templateID"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-content></ng-content>
  <div class="form-buttons pb-1">
    <button
      id="sendBtn"
      type="button"
      class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
      (click)="onSend()"
      [disabled]="isSending || attachloaderIsLoading"
    >
      Send
			<i *ngIf="isSending" class="fas fa-spinner fa-pulse"></i>
    </button>
    <button
			*ngIf="showNextBtn"
      id="nextBtn"
      type="button"
      class="btn-light btn-block mb-2 mb-lg-0"
      [disabled]="isSending || attachloaderIsLoading"
			(click)="onNext()"
    >
		{{ nextLabel }}
    </button>
    <button
      id="cancelBtn"
      type="button"
      class="btn-light btn-block mb-2 mb-lg-0"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>

<ng-template #uploadOptionModal>
	<button
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="optionModalRef.hide()"
	>
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Attach Document</h4>
	</div>
	<div class="modal-body">
		<div class="clearfix py-3">
			<div class="d-flex justify-content-center">
				<button
					type="button"
					[disabled]="clientDocumentsLoaderIsLoading"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-2"
					(click)="uploadDocuments(); optionModalRef.hide()"
				>
					Upload New
				</button>
				<button
					type="button"
					[disabled]="clientDocumentsLoaderIsLoading"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4"
					(click)="onLinkDocumentsClicked()"
				>
					Link Document
				</button>
			</div>
		</div>
	</div>
</ng-template>

