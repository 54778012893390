<div class="field">
  <div #longText [class]="cellClass">
    <a
      *ngIf="route; else textTemplate"
      class="m-auto"
      [class]="cellClass"
      [ngClass]="{ clickable: route }"
      [routerLink]="route"
      (click)="onClick()"
      id="{{fieldId}}Link_{{index}}"
    >
      {{ value }}
    </a>
  </div>
</div>
<ng-template #textTemplate>
  <span class="m-auto">
    {{ value }}
  </span>
</ng-template>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="!restrict; else hasRestriction">
    <div class="control">
      <button
        *ngIf="activityId"
        type="button"
        class="icon-btn-bg btn-edit"
        (click)="editActivityModal()"
        id="{{fieldId}}EditBtn_{{index}}"
      >
        <i class="material-icons md-16 dark">&#xE3C9;</i>
      </button>
    </div>
  </ng-container>

  <ng-template #hasRestriction>
    <ng-container *appRestrictTo="restrict">
      <div class="control">
        <button
          *ngIf="activityId"
          type="button"
          class="icon-btn-bg btn-edit"
          (click)="editActivityModal()"
          id="{{fieldId}}EditBtn_{{index}}"
        >
          <i class="material-icons md-16 dark">&#xE3C9;</i>
        </button>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<div>
  <app-manual-loader-mini
    *ngIf="isLoading"
    [isLoading]="isLoading"
    class="loader-mini"
  ></app-manual-loader-mini>
</div>
