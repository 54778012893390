import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subject, iif, of } from 'rxjs';
import { concatMap, filter, map, take, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { fileUtil } from 'src/app/util/util';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { logMessage } from '../../error-message/error-message';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import { DocumentModelState } from '../../models/documents/document.model';
import { ServicesCodes } from '../../models/services/services.model';

@Component({
	selector: 'app-link-document',
	templateUrl: './link-document.component.html',
	styleUrls: ['./link-document.component.scss'],
})
export class LinkDocumentComponent implements OnInit {
	public getSelectedDocumentValue$: Subject<string>;
	selectedDetail: string;
	maxFileSize: number; // File Size in KB
	initialSelectedTab: string;
	activeDocType: string;
	currentpage = 1;
	page: number;
	tabs: string[] = [
		ServicesCodes.LR,
		ServicesCodes.Mortgage,
		ServicesCodes.FG,
		ServicesCodes.KiwiSaver,
		ServicesCodes.Others,
		ServicesCodes.AdviceProcess,
		ServicesCodes.CustomerPortalMOATDocuments,
	];

	filteredListLR: DocumentModelState[];
	filteredListM: DocumentModelState[];
	filteredListFG: DocumentModelState[];
	filteredListK: DocumentModelState[];
	filteredListO: DocumentModelState[];
	filteredListOD: DocumentModelState[];
	filteredListAXD: DocumentModelState[];
	filteredListXD: DocumentModelState[];
	filteredListAP: DocumentModelState[];

	listPerPageLR: any[];
	listPerPageM: any[];
	listPerPageFG: any[];
	listPerPageK: any[];
	listPerPageO: any[];
	listPerPageOD: any[];
	listPerPageAXD: any[];
	listPerPageXD: any[];
	listPerPageAP: any[];
	listPerPageCP: any[];

	allowedFileExtensions: string[];

	itemPerPage = 10;
	document: DocumentGroupState;

	hasLR$ = this.businessConfigQuery.hasLR$;
	hasM$ = this.businessConfigQuery.hasM$;
	hasFG$ = this.businessConfigQuery.hasFG$;
	hasK$ = this.businessConfigQuery.hasK$;
	hasI$ = this.businessConfigQuery.hasI$;
	hasCustomerPortalDocument$ =
		this.businessConfigQuery.hasCustomerPortalDocument$;
	loading = false;

	@ViewChild('sidebarNav') sidebarNav: TabsetComponent;

	currentFileSize: number;

	constructor(
		public bsModalRef: BsModalRef,
		public businessConfigQuery: BusinessConfigQuery,
		public crtDocumentService: CrtDocumentService,
		public loggerService: LoggerService
	) {}

	get getDocument() {
		return !!this.document ? this.document : null;
	}

	ngOnInit() {
		this.getSelectedDocumentValue$ = new Subject();

		this.listPerPageLR =
			this.document.lR && this.document.lR.length > 0
				? this.document.lR?.slice(0, this.itemPerPage)
				: null;
		this.listPerPageM =
			this.document.m && this.document.m.length > 0
				? this.document.m?.slice(0, this.itemPerPage)
				: null;
		this.listPerPageFG =
			this.document.fG && this.document.fG.length > 0
				? this.document.fG?.slice(0, this.itemPerPage)
				: null;
		this.listPerPageK =
			this.document.k && this.document.k.length > 0
				? this.document.k?.slice(0, this.itemPerPage)
				: null;
		this.listPerPageO =
			this.document.o && this.document.o.length > 0
				? this.document.o?.slice(0, this.itemPerPage)
				: null;
		this.listPerPageAP =
			this.document.aP && this.document.aP.length > 0
				? this.document.aP?.slice(0, this.itemPerPage)
				: null;
		this.listPerPageCP =
			this.document.cP && this.document.cP.length > 0
				? this.document.cP?.slice(0, this.itemPerPage)
				: null;
		if (this.initialSelectedTab) {
			this.activeDocType = this.initialSelectedTab?.toUpperCase();
			setTimeout(() => {
				const i =
					this.sidebarNav?.tabs?.findIndex(
						(x) => x?.id === this.initialSelectedTab
					) ?? -1;
				const tab = this.sidebarNav?.tabs[i];
				if (!!tab) {
					tab.active = true;
				} else {
					const defaultTab = this.sidebarNav?.tabs?.[0]?.id;
					this.activeDocType = defaultTab?.toUpperCase();
					this.setPage({ page: 1, itemsPerPage: this.itemPerPage }, defaultTab);
				}
			}, 0);
		}
	}
	// side pagination
	pageChanged(event: any): void {
		this.page = event.page;
	}

	setPage(event: PageChangedEvent, type: string): void {
		this.currentpage = event.page;
		const startItem = (event.page - 1) * event.itemsPerPage;
		const endItem = event.page * event.itemsPerPage;

		if (type === ServicesCodes.LR?.toLowerCase()) {
			this.listPerPageLR = this.getDocument.lR?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.Mortgage?.toLowerCase()) {
			this.listPerPageM = this.getDocument.m.slice(startItem, endItem);
		}
		if (type === ServicesCodes.FG?.toLowerCase()) {
			this.listPerPageFG = this.getDocument.fG?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.KiwiSaver?.toLowerCase()) {
			this.listPerPageK = this.getDocument.k?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.Others?.toLowerCase()) {
			this.listPerPageO = this.getDocument.o?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.AdviceProcess?.toLowerCase()) {
			this.listPerPageAP = this.getDocument.aP?.slice(startItem, endItem);
		}
		if (type === ServicesCodes.CustomerPortal?.toLowerCase()) {
			this.listPerPageCP = this.getDocument.cP?.slice(startItem, endItem);
		}
	}

	selectType(type: string) {
		this.activeDocType = type;
		this.setPage(
			{ page: 1, itemsPerPage: this.itemPerPage },
			type.toLowerCase()
		);
	}

	isTabAvailable(tab: string) {
		return this.tabs?.some((x) => x === tab);
	}

	close() {
		this.bsModalRef.hide();
	}

	selectLinkDocument(dataselected: any) {
		if (this.loading) {
			return;
		}
		if (this.allowedFileExtensions?.length > 0) {
			const ext = dataselected.fileExtension?.replace('.', '');
			const allowedExtension = this.allowedFileExtensions?.map((x) => x?.toLowerCase());
			const extension = ext?.toLowerCase();
			if (!allowedExtension?.includes(extension)) {
				this.loggerService.Warning(
					{},
					logMessage.shared.fileInvalidExtension.error
				);
				return;
			}
		}
		this.loading = true;
		of(dataselected)
			.pipe(
				map((file) => {
					if (!file?.fileName?.includes('.')) {
						return {
							...file,
							fileName: `${file.fileName}${file.fileExtension}`,
						};
					}
					return file;
				}),
				concatMap((file) =>
					iif(
						() => !!this.maxFileSize && !!file?.documentLink,
						of(file).pipe(
							concatMap((x) =>
								this.crtDocumentService.getDocumentLink(x?.documentLink, {
									responseType: 'blob',
								})
							),
							map((x) => {
								const size = +fileUtil.formatBytes(x?.size, 'KB');
								dataselected = {
									...dataselected,
									fileSize: size
								};

								if (size > +this.maxFileSize) {
									this.loggerService.Warning(
										{},
										logMessage.shared.fileLinkSize.single.error
									);

									setTimeout(() => (this.loading = false), 500);
									return null;
								}

								const totalSize = +size + (this.currentFileSize || 0);
								if (this.currentFileSize && totalSize > +this.maxFileSize) {
									this.loggerService.Warning(
										{},
										logMessage.shared.fileLinkSize.total.error
									);
									setTimeout(() => (this.loading = false), 500);
									return null;
								}
								return file;
							}),
							take(1)
						),
						of(file)
					)
				),
				filter((x) => !!x),
				tap(() => {
					setTimeout(() => (this.loading = false), 500);
					this.getSelectedDocumentValue$.next(dataselected);
					this.close();
				}),
				take(1)
			)
			.subscribe();
	}
}
