import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Application } from './application.model';

export interface ApplicationState extends EntityState<Application> {
	isUpdatedLoans: boolean
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application', idKey: 'cRTId' })
export class ApplicationStore extends EntityStore<ApplicationState> {
	constructor() {
		super({
			isUpdatedLoans: false
		});
	}
}
