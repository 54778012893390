<form [formGroup]="form" class="form">
	<div formArrayName="existingPolicies">
		<div
			class="existing-policy-structure__content"
			*ngFor="
				let item of ExistingPolicies.controls;
				let i = index;
				trackBy: track
			"
			[formGroupName]="i"
		>
			<div class="form-row mb-3">
				<div class="col-lg-11">
					<label
						class="provider-name font-weight-bold"
						*ngIf="!!item?.get('provider').value"
						>{{ item?.get("provider").value }}:</label
					>
				</div>
				<div class="col-lg-1 align-self-end">
					<ng-container
						*ngIf="
							isEdit && !!item.get('isEdit').value;
							else showDefaultButtons
						"
					>
						<button
							id="saveInsuranceBtn_{{ i }}"
							type="button"
							class="icon-btn h-auto w-auto px-1"
							(click)="save(i)"
							[disabled]="!!item.get('isLoading').value"
						>
							<i class="material-icons md-16">save</i>
						</button>
						<button
							id="cancelEditInsuranceBtn_{{ i }}"
							type="button"
							class="icon-btn h-auto w-auto px-1"
							class="icon-btn w-auto h-auto px-1"
							(click)="cancelEdit(i, item.get('cRTId').value)"
							[disabled]="!!item.get('isLoading').value"
						>
							<i class="material-icons md-16">close</i>
						</button>
					</ng-container>
					<ng-template #showDefaultButtons>
						<button
							id="editInsuranceBtn_{{ i }}"
							type="button"
							class="icon-btn h-auto w-auto px-1"
							(click)="edit(i)"
							[disabled]="
								(isEdit && !item.get('isEdit').value) ||
								!!item.get('isLoading').value
							"
						>
							<i class="material-icons md-16"> edit </i>
						</button>
						<button
							id="deleteInsuranceBtn_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							(click)="remove(i)"
							[disabled]="
								(isEdit && !item.get('isEdit').value) ||
								!!item.get('isLoading').value
							"
						>
							<i class="material-icons md-16"> delete </i>
						</button>
					</ng-template>
					<i
						*ngIf="!!item.get('isLoading').value"
						class="fas fa-spinner fa-pulse px-1"
					></i>
				</div>
			</div>
			<div class="form-row mb-3 font-weight-bold">
				<div class="col-lg-11">
					<div class="form-row">
						<div class="col-lg-3">Life Assured</div>
						<div class="col-lg-3">Product</div>
						<div class="col-lg-3">Benefit</div>
						<div class="col-lg-3">Outcome</div>
					</div>
				</div>
				<div class="col-lg-1"></div>
			</div>
			<div formArrayName="lifeAssured">
				<div
					class="form-row mb-3"
					*ngFor="
						let la of item.get('lifeAssured').controls;
						let j = index;
						trackBy: track
					"
					[formGroupName]="j"
				>
					<div class="col-lg-11">
						<div class="form-row">
							<div class="col-lg-3">
								<span
									class="w-100"
									[class.crt-custom-dropdown]="
										isEdit && !!item.get('isEdit')?.value
									"
								>
									<select
										id="lifeAssured_{{ j }}"
										formControlName="lifeAssured"
										class="form-control theme-crm-field-disabled form-item__element--select crt-form-control"
									>
										<option
											*ngFor="let d of lifeAssuredDropdownList"
											[value]="d.value"
										>
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
							<div class="col-lg-3">
								<span
									class="w-100"
									[class.crt-custom-dropdown]="
										isEdit && !!item.get('isEdit')?.value
									"
								>
									<select
										id="product_{{ j }}"
										formControlName="product"
										class="form-control theme-crm-field-disabled form-item__element--select crt-form-control"
										(change)="changeDropdown(i, j)"
									>
										<option *ngFor="let d of product" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
							<div class="col-lg-3">
								<ng-container *ngIf="la.get('showDropdown').value">
									<span
										class="w-100"
										[class.crt-custom-dropdown]="
											isEdit && !!item.get('isEdit')?.value
										"
									>
										<select
											id="benefit_{{ j }}"
											formControlName="benefit"
											class="form-control theme-crm-field-disabled crt-form-control"
											placeholder="Benefit"
										>
											<option *ngFor="let d of benefit" [value]="d.value">
												{{ d.display }}
											</option>
										</select>
									</span>
								</ng-container>
								<ng-container *ngIf="!la.get('showDropdown').value">
									<div
										[ngClass]="[
											'dollar-icon',
											showMonth(la.get('product')?.value)
												? 'benefit label-right'
												: ''
										]"
									>
										<i [class.ml-0]="!item.get('isEdit')?.value">$</i>
										<input
											class="form-control crt-form-control"
											id="benefit_{{ j }}"
											type="text"
											placeholder="Benefit"
											formControlName="benefit"
											currencyMask
											appCurrencyMaskExt
										/>
										<i
											*ngIf="showMonth(la.get('product')?.value)"
											class="right"
										>
											/month</i
										>
									</div>
								</ng-container>
							</div>
							<div class="col-lg-3">
								<span
									class="w-100"
									[class.crt-custom-dropdown]="
										isEdit && !!item.get('isEdit')?.value
									"
								>
									<select
										id="outcome_{{ j }}"
										formControlName="outcome"
										class="form-control theme-crm-field-disabled crt-form-control"
										placeholder="Outcome"
									>
										<option *ngFor="let d of outcomes" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg-1"></div>
				</div>
			</div>
			<div class="form-row mb-3">
				<div class="col-lg-11">
					<div class="form-row">
						<div class="col-lg-9">
							<textarea
								class="form-control crt-form-control note-text-area"
								id="additionalNotes_{{ i }}"
								type="text"
								placeholder="Notes"
								formControlName="additionalNotes"
								rows="4"
							>
							</textarea>
						</div>
						<div class="col-lg-3">
							<div class="form-row mb-2">
								<div
									class="col-lg-8 text-right d-lg-flex justify-content-end align-items-center"
								>
									<label
										for="policyFee_{{ i }}"
										class="font-weight-bold tap-text-primary"
										>Policy Fee</label
									>
								</div>
								<div class="col-lg-4">
									<div class="dollar-icon">
										<input
											class="form-control crt-form-control"
											id="policyFee_{{ i }}"
											type="text"
											placeholder="Policy Fee"
											formControlName="policyFee"
											currencyMask
											appCurrencyMaskExt
										/>
										<i>$</i>
									</div>
								</div>
							</div>
							<div class="form-row">
								<div
									class="col-lg-8 text-right d-lg-flex justify-content-end align-items-center"
								>
									<label
										for="totalPremium_{{ i }}"
										class="font-weight-bold tap-text-primary"
										>Total Premium:</label
									>
								</div>
								<div class="col-lg-4">
									<div class="dollar-icon">
										<input
											class="form-control crt-form-control"
											id="totalPremium_{{ i }}"
											type="text"
											placeholder="Total Premium"
											formControlName="totalPremium"
											currencyMask
											appCurrencyMaskExt
										/>
										<i>$</i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-1"></div>
			</div>
		</div>
	</div>
</form>
