import { RmComponent } from './widgets/rm/rm.component';
import { LrgtComponent } from './widgets/lrgt/lrgt.component';
import { LrgtsgComponent } from './widgets/lrgtsg/lrgtsg.component';
import { LrgtfyComponent } from './widgets/lrgtfy/lrgtfy.component';
import { KgtComponent } from './widgets/kgt/kgt.component';
import { PComponent } from './widgets/p/p.component';
import { MgtComponent } from './widgets/mgt/mgt.component';
import { LraComponent } from './widgets/lra/lra.component';
import { AComponent } from './widgets/a/a.component';
import { LrctComponent } from './widgets/lrct/lrct.component';
import { AasComponent } from './widgets/aas/aas.component';
import { AdvComponent } from './widgets/adv/adv.component';
// import { AvsComponent } from './widgets/avs/avs.component';
import { AsryComponent } from './widgets/asry/asry.component';
import { AasfComponent } from './widgets/aasf/aasf.component';
import { BbComponent } from './widgets/bb/bb.component';
import { WasComponent } from './widgets/was/was.component';
import { MasComponent } from './widgets/mas/mas.component';
import { QasComponent } from './widgets/qas/qas.component';
import { CBComponent } from './widgets/cb/cb.component';
import { MmgtComponent } from './widgets/mmgt/mmgt.component';
import { LcComponent } from './widgets/lc/lc.component';
import { NblrComponent } from './widgets/nblr';
import { MoComponent } from './widgets/mo';
import { Type } from '@angular/core';
import { BnbComponent } from './widgets/bnb/bnb.component';
import { MswComponent } from './widgets/msw/msw.component';
import { EbcComponent } from './widgets/ebc/ebc.component';
import { AptComponent } from './widgets/apt/apt.component';
import { FggtdComponent } from './widgets/fggtd/fggtd.component';
import { FggtcComponent } from './widgets/fggtc/fggtc.component';
import { FgbbComponent } from './widgets/fgbb/fgbb.component';
import { FgpscComponent } from './widgets/fgpsc/fgpsc.component';
import { FgpsdComponent } from './widgets/fgpsd/fgpsd.component';
import { FgpsltComponent } from './widgets/fgpslt/fgpslt.component';
import { EblrComponent } from './widgets/eblr/eblr.component';

type WidgetComponent =
	| Type<RmComponent>
	| Type<LrgtComponent>
	| Type<LrgtsgComponent>
	| Type<KgtComponent>
	| Type<PComponent>
	| Type<LraComponent>
	| Type<AasComponent>
	| Type<LrctComponent>
	| Type<MgtComponent>
	| Type<AComponent>
	| Type<AdvComponent>
	// | Type<AvsComponent>
	| Type<AsryComponent>
	// | Type<OsComponent>
	| Type<AasfComponent>
	| Type<BbComponent>
	| Type<WasComponent>
	| Type<MasComponent>
	| Type<QasComponent>
	| Type<CBComponent>
	| Type<LrgtfyComponent>
	| Type<MmgtComponent>
	| Type<LcComponent>
	| Type<NblrComponent>
	| Type<BnbComponent>
	| Type<MswComponent>
	| Type<EbcComponent>
	| Type<EblrComponent>
	| Type<AptComponent>
	| Type<FggtdComponent>
	| Type<FggtcComponent>
	| Type<FgbbComponent>
	| Type<FgpscComponent>
	| Type<FgpsdComponent>
	| Type<FgpsltComponent>
	| Type<MoComponent>;

/**
 * @description
 * Object to find the component equivalent to a code.
 * Consists of widget code as key and component as value.
 *
 * @example
 * {
 *   p: PComponent, // pipeline widget component
 *   a: AComponent, // activity widget component
 *   rm: RmComponent // Refix mortgage widget component
 * }
 */
export const codeComponentPair: {
	[key: string]: WidgetComponent & {
		widgetCode: string;
		widgetName: string;
		sizeX: number;
		sizeY: number;
		minSizeX: number;
		minSizeY: number;
		maxSizeX?: number;
		maxSizeY?: number;
	};
} = {
	rm: RmComponent,
	lrgt: LrgtComponent,
	lrgtsg: LrgtsgComponent,
	lrgtfy: LrgtfyComponent,
	kgt: KgtComponent,
	p: PComponent,
	lra: LraComponent,
	a: AComponent,
	lrct: LrctComponent,
	mgt: MgtComponent,
	aas: AasComponent,
	adv: AdvComponent,
	// avs: AvsComponent, // TAP1-3811: API Stats - Rolling 6 Months (Hide widget)
	asry: AsryComponent,
	// os: OsComponent,
	aasf: AasfComponent,
	bb: BbComponent,
	was: WasComponent,
	mas: MasComponent,
	qas: QasComponent,
	cb: CBComponent,
	mmgt: MmgtComponent,
	lc: LcComponent,
	bnb: BnbComponent,
	msw: MswComponent,
	ebc: EbcComponent,
	eblr: EblrComponent,
	apt: AptComponent,
	fggtd: FggtdComponent,
	fggtc: FggtcComponent,
	fgbb: FgbbComponent,
	fgpsc: FgpscComponent,
	fgpsd: FgpsdComponent,
	fgpslt: FgpsltComponent,
	nblr: NblrComponent,
	mo: MoComponent,
};
