import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	AfterViewInit,
	ViewChildren,
	QueryList,
	OnDestroy
} from '@angular/core';
import { ThemeComponentService } from '../../service/theme-component.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil, take } from 'rxjs/operators';
import { ThemeConfig } from '../../../../../shared/models/_general/config';

declare var jscolor: any;
declare var $: any;

@Component({
	selector: 'app-theme',
	templateUrl: './theme.component.html',
	styleUrls: ['./theme.component.scss'],
	providers: [ThemeComponentService]
})
export class ThemeComponent implements OnInit, AfterViewInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@ViewChildren('input') inputs: QueryList<ElementRef>;
	@ViewChild('save') saveBtn: ElementRef;
	@ViewChild('cancel') cancelBtn: ElementRef;
	jsColors: jsColor[];
	dataForm: UntypedFormGroup;
	isLoading$: Observable<boolean>;
	constructor(
		private compService: ThemeComponentService,
		private fb: UntypedFormBuilder
	) {
		this.jsColors = [];
		this.isLoading$ = this.compService.isLoading$;
		this.initForm();
	}

	ngOnInit() {}
	initForm(): void {
		this.dataForm = this.fb.group({
			primarycolor: '',
			secondarycolor: '',
			tertiarycolor: '',
			h1color: '',
			h2color: '',
			h3color: '',
			h4color: '',
			h5color: '',
			h6color: '',
			pcolor: '',
			fieldBackgroundColor: '',
			fieldTextColor: '',
			widgetcolor1: '',
			widgetcolor2: '',
			soaHeadingColor: '',
		} as ThemeConfig);
	}
	ngAfterViewInit(): void {
    fromEvent(this.saveBtn.nativeElement, 'click')
      .pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe(x => this.save());
    fromEvent(this.cancelBtn.nativeElement, 'click')
      .pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe(x => this.cancel());

    this.inputs.forEach(x => {
      this.jsColors.push(new jscolor(x.nativeElement,  { previewSize: '100%'} ));
      fromEvent(x.nativeElement, 'change')
        .pipe(debounceTime(200), takeUntil(this.onDestroy$))
        .subscribe($event => {
          this.setValue($event['target'].id, $event['target'].value);
        });
    });
    this.cancel();
  }
	setValue(key: string, value: string) {
		this.dataForm.get(key).setValue(value);
	}
	save() {
    this.compService.save(this.dataForm.value).pipe(takeUntil(this.onDestroy$)).subscribe();
  }
  cancel() {
    this.compService.theme$.pipe(take(1)).subscribe(x => {
      this.dataForm.reset(x);
    });
    this.inputs?.map(x => $(x.nativeElement).attr('id'))?.forEach(x => this.ManuallyChangeColor(x));
  }

  private ManuallyChangeColor(name: string) {
    const value = this.dataForm.get(name).value;
    const el = this.jsColors?.find(x => x.targetElement.id === name);
    if (![null, undefined]?.some(z => z === value)) { el.fromString(value); }
  }

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

type jsColor = { targetElement: Element; fromString: (x: string) => void };
