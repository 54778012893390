<div class="container-fluid tap-search-page">
	<div class="clearfix py-3">
		<app-investment-form
			(toggleSearch)="onToggleSearch($event)"
		></app-investment-form>

		<div class="clearfix">
			<div class="row">
				<div class="col p-0">
					<!-- Start of ngx-datatable -->
					<ngx-datatable
						#mydatatable
						class="bootstrap"
						[style.height]="tblHeight"
						[ngClass]="{
							showSearchFields: showSearchFields,
							'disabled-table': isSearching$ | async,
							'disable-sort': !(hasRow$ | async)
						}"
						[columns]="columnsWithLink$ | async"
						[rows]="rows$ | async"
						[columnMode]="'standard'"
						[rowIdentity]="rowIdentity"
						[scrollbarH]="true"
						[trackByProp]="'CustomerServiceID'"
						[scrollbarV]="true"
						[headerHeight]="32"
						[rowHeight]="40"
						[footerHeight]="false"
						(reorder)="reorder($event)"
						(resize)="resize($event)"
						[externalSorting]="true"
						sortType="single"
						(sort)="sort($event)"
						[rowClass]="getRowClass"
						[sorts]="sorts$ | async"
						(page)="onPage($event, mydatatable.bodyComponent.indexes)"
					>
						<ngx-datatable-column
							*ngFor="let c of columns$ | async"
							[name]="c.name"
							[prop]="c.prop"
							[width]="c.width"
							[resizeable]="true"
							[cellClass]="c.cellClass"
							[draggable]="!(c.metakey === 'Investor')"
							[frozenLeft]="c.metakey === 'Investor'"
						>
							<ng-template
								ngx-datatable-header-template
								let-column="column"
								let-sort="sortFn"
							>
								<i
									class="material-icons draggable"
									[id]="c.columnId"
									[class.d-none]="c.metakey === 'Investor'"
									>drag_indicator</i
								>
								<span class="datatable-header-cell-wrapper" (click)="sort()">
									<span class="datatable-header-cell-label">{{
										column.name
									}}</span>
								</span>
							</ng-template>

							<ng-template
								ngx-datatable-cell-template
								let-row="row"
								let-value="value"
								let-rowIndex="rowIndex"
							>
								<!-- Tried to provide template for each column but [Object, object] bug happens so made it this way -->
								<ng-container [ngSwitch]="c.controlType">
									<app-display
										*ngSwitchCase="'display'"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>
									<app-long-display
										*ngSwitchCase="'long-display'"
										[value]="value.value"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									></app-long-display>
									<ng-container
										*ngSwitchCase="'dropdown'"
										[ngSwitch]="c.metakey"
									>
										<app-dropdown
											*ngSwitchDefault
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[value]="value.value"
											[restrict]="value.restrict"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerServiceID + '-' + c.metakey
												]
											"
											[isEditing]="
												(cellsEditing$ | async)[
													row.CustomerServiceID + '-' + c.metakey
												]
											"
											[tempValue]="
												(cellsTempvalue$ | async)[
													row.CustomerServiceID + '-' + c.metakey
												]
											"
											[isRequired]="c.isRequired"
											[isEditable]="
												c.metakey === 'Original Adviser'
													? hasPermissions$(['FEO'])
													: value.editable
											"
											[hasSort]="
												c.metakey === 'Adviser' ||
												c.metakey === 'Investment Adviser'||
												c.metakey === 'Original Adviser'
													? true
													: false
											"
											[choices]="c.choices"
											[choicesObject]="c.choicesAsObject"
											[allChoices]="
												c.metakey === 'Adviser' ||
												c.metakey === 'Investment Adviser'||
												c.metakey === 'Original Adviser'
													? (allAdviserChoices$ | async)
													: undefined
											"
											[allChoicesObject]="
												c.metakey === 'Adviser' ||
												c.metakey === 'Investment Adviser'||
												c.metakey === 'Original Adviser'
													? (allAdviserChoicesAsObject$ | async)
													: undefined
											"
											(editEvent)="edit(row.CustomerServiceID, c.metakey)"
											(cancelEvent)="cancel(row.CustomerServiceID, c.metakey)"
											(saveEvent)="
												saveField(
													row,
													row.CustomerServiceID,
													c.metakey,
													value.key,
													$event
												)
											"
											(fieldUpdateEvent)="
												setTempValue(row.CustomerServiceID, c.metakey, $event)
											"
										></app-dropdown>
									</ng-container>

									<app-textbox
										*ngSwitchCase="'textbox'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										(editEvent)="edit(row.CustomerServiceID, c.metakey)"
										(cancelEvent)="cancel(row.CustomerServiceID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.CustomerServiceID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerServiceID, c.metakey, $event)
										"
									></app-textbox>
									<app-address
										*ngSwitchCase="'address'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[isLoading]="
											(cellsLoading$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										(editEvent)="edit(row.CustomerServiceID, c.metakey)"
										(cancelEvent)="cancel(row.CustomerServiceID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.CustomerServiceID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerServiceID, c.metakey, $event)
										"
									></app-address>
									<app-date
										*ngSwitchCase="'date'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[isLoading]="
											(cellsLoading$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										(editEvent)="edit(row.CustomerServiceID, c.metakey)"
										(cancelEvent)="cancel(row.CustomerServiceID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.CustomerServiceID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerServiceID, c.metakey, $event)
										"
									></app-date>
									<ng-container
										*ngSwitchCase="'activity'"
										[ngSwitch]="c.metakey"
									>
										<app-activity-detail
											*ngSwitchCase="'Client Next Activity'"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[cellClass]="getCellClass(value, row)"
											[value]="value.value"
											[restrict]="value.restrict"
											[activityId]="row.ClientNextActivityId"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerID + '-' + c.metakey
												]
											"
											(editActivityEvent)="
												updateClientNextActivityField(row.CustomerID)
											"
											[permissionsToComplete]="['FCCA', 'FCLA']"
										></app-activity-detail>
										<app-activity-detail
											*ngSwitchCase="'User Next Activity'"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[cellClass]="getCellClass(value, row)"
											[value]="value.value"
											[activityId]="row.UserNextActivityId"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerID + '-' + c.metakey
												]
											"
											(editActivityEvent)="
												updateUserNextActivityField(row.CustomerID)
											"
											[permissionsToComplete]="['FCCA', 'FCLA']"
										></app-activity-detail>
									</ng-container>
									<app-note
										*ngSwitchCase="'note'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[activityType]="'KS'"
										[restrict]="value.restrict"
										[isEditable]="value.editable"
										(updateNoteEvent)="
											updateNoteField(row.CustomerServiceID, $event)
										"
										[customerId]="row.CustomerID"
										[customerServiceId]="row.CustomerServiceID"
									></app-note>
									<ng-container
										*ngSwitchCase="'multiselect'"
										[ngSwitch]="c.metakey"
									>
										<ng-container *ngSwitchCase="'Investor'">
											<app-multiselect
												[route]="route(c.metakey, row)"
												[value]="value.value"
												[restrict]="value.restrict"
												[isLoading]="
													(cellsLoading$ | async)[
														row.CustomerServiceID + '-' + c.metakey
													]
												"
												[isEditing]="
													(cellsEditing$ | async)[
														row.CustomerServiceID + '-' + c.metakey
													]
												"
												[isEditable]="value.editable"
												[isRequired]="c.isRequired"
												[choices]="
													createChoicesFromEntityList(row.InvestorList)
												"
												[choicesObject]="
													createLookupFromEntityList(row.InvestorList)
												"
												[fieldName]="c.name"
												[metakey]="c.metakey"
												[validatorFn$]="ownerTypeHasErrorFn$"
												[row]="row"
												(editEvent)="edit(row.CustomerServiceID, c.metakey)"
												(cancelEvent)="cancel(row.CustomerServiceID, c.metakey)"
												(saveEvent)="
													saveField(
														row,
														row.CustomerServiceID,
														c.metakey,
														value.key,
														$event
													)
												"
												(fieldUpdateEvent)="
													setTempValue(row.CustomerServiceID, c.metakey, $event)
												"
											></app-multiselect>
										</ng-container>

										<ng-container *ngSwitchDefault>
											<app-multiselect
												[route]="route(c.metakey, row)"
												[value]="value.value"
												[isLoading]="
													(cellsLoading$ | async)[
														row.CustomerServiceID + '-' + c.metakey
													]
												"
												[isEditing]="
													(cellsEditing$ | async)[
														row.CustomerServiceID + '-' + c.metakey
													]
												"
												[isRequired]="c.isRequired"
												[isEditable]="value.editable"
												[choices]="c.choices"
												[choicesObject]="c.choicesAsObject"
												[fieldName]="c.name"
												(saveEvent)="
													saveField(
														row,
														row.CustomerServiceID,
														c.metakey,
														value.key,
														$event
													)
												"
											></app-multiselect>
										</ng-container>
									</ng-container>

									<!-- createLookupFromList -->
									<app-textarea
										*ngSwitchCase="'textarea'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										[fieldName]="c.name"
										(saveEvent)="
											saveField(
												row,
												row.CustomerServiceID,
												c.metakey,
												value.key,
												$event
											)
										"
									></app-textarea>
									<app-money
										*ngSwitchCase="'money'"
										[apiRoundOffOnUI]="c.fieldId === 'fUM' ? true : false"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										(editEvent)="edit(row.CustomerServiceID, c.metakey)"
										[isEditable]="value.editable"
										(cancelEvent)="cancel(row.CustomerServiceID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.CustomerServiceID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerServiceID, c.metakey, $event)
										"
									></app-money>
									<app-datatable-checkbox
										*ngSwitchCase="'checkbox'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[isLoading]="
											(cellsLoading$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerServiceID + '-' + c.metakey
											]
										"
										(editEvent)="edit(row.CustomerServiceID, c.metakey)"
										(cancelEvent)="cancel(row.CustomerServiceID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.CustomerServiceID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerServiceID, c.metakey, $event)
										"
									>
									</app-datatable-checkbox>
								</ng-container>
							</ng-template>
						</ngx-datatable-column>

						<ngx-datatable-column
							[prop]="linkColumn.prop"
							[name]="linkColumn.name"
							[width]="linkColumn.width"
							[resizeable]="false"
							headerClass="link-header"
							[cellClass]="'link-ellipses'"
						>
							<ng-template
								ngx-datatable-cell-template
								let-value="value"
								let-row="row"
								let-rowIndex="rowIndex"
							>
								<div
									class="dropdown d-flex"
									dropdown
									placement="bottom right"
									container="body"
								>
									<button
										id="toggleMoreBtn_{{ rowIndex }}"
										class="tap-btn tap-btn-outline tap-text-primary px-0 py-0"
										dropdownToggle
									>
										<i
											class="material-icons icon-btn md-18 d-flex align-items-center px-0 py-0 w-auto"
										>
											more_vert
										</i>
									</button>
									<ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
										<a
											id="addActivityBtn_{{ rowIndex }}"
											*appRestrictTo="['FACA']"
											class="dropdown-item clickable tap-tex-primary"
											(click)="createClientNextActivity(row.CustomerID)"
										>
											Add Activity
										</a>
										<a
											id="editBtn_{{ rowIndex }}"
											*ngIf="row.link.IsCompany"
											[routerLink]="
												businessRoute(row.CustomerID, row.CustomerServiceID)
											"
											class="dropdown-item clickable tap-tex-primary"
										>
											Edit
										</a>
										<a
											id="editBtn_{{ rowIndex }}"
											*ngIf="!row.link.IsCompany"
											[routerLink]="
												clientRoute(row.CustomerID, row.CustomerServiceID)
											"
											class="dropdown-item clickable tap-tex-primary"
										>
											Edit
										</a>
									</ul>
								</div>
							</ng-template>
						</ngx-datatable-column>
					</ngx-datatable>
					<!-- End of ngx-datatable -->
				</div>
				<!-- End of .col -->
			</div>
			<!-- End of .row -->
		</div>
		<!-- End of .clearfix -->
	</div>
</div>
