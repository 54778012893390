import { DefaultFileNames } from '../../../../../shared/models/documents/document.model';
import { EmailTypes } from '../../../../../shared/models/emails/crt/email.model';
import { DeclarationEmailSettingsState } from './declaration-email-settings.model';

export class DeclarationEmailSettingsMapper {
  public static mapEmailSettingsToUpsert(data: DeclarationEmailSettingsState) {

    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: EmailTypes.Declaration
    };
  }

  public static mapStaffEmailBody(document, docId, staffId) {
    if (docId === 0 || !docId) {
      return {
        document,
        referenceId: +staffId,
        fileName: DefaultFileNames.SettingsEmailBody,
        type: EmailTypes.Declaration
      };
    }
    return {
      document,
      documentId: +docId,
    };
  }

  public static mapEmailSettingsData(data: DeclarationEmailSettingsState) {
    if (data) {
      const { template, mergeTag, ...others } = data;
      return others;
    }
    return null;
  }
}

