import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DropdownValueState, DropdownValueStore } from './dropdown-value.store';
import { DropdownValue, mapDropdownToChoices } from './dropdown-value.model';
import { map, shareReplay, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

/** Query service to get dropdown values  */
@Injectable()
export class DropdownValueQuery extends QueryEntity<
DropdownValueState,
DropdownValue
> {

  offlineOnlineChoice$ = of([{
    display: 'Online',
    value: 'Online',
  }, {
    display: 'Offline',
    value: 'Offline',
  }]);

  /** Get sorted dropdown in its original format */
  orderedDropdowns$ = (dropdownCode: string | string[]) =>
    of(dropdownCode).pipe(
      map(code => {
        let value: string[];
        if (!Array.isArray(code)) { value = [code]; } else { value = code; }
        return value;
      }),
      mergeMap(code =>
        this.selectAll({
          filterBy: x => code?.includes(x.DropdownCode),
          sortBy: (x, y) => x.DropdownOrder - y.DropdownOrder
        })
      )
    )
  /** Get sorted dropdown in `ViewDisplayValue` format */
  orderedChoices$ = (code: string | string[]) =>
    this.orderedDropdowns$(code).pipe(
      map(mapDropdownToChoices),
      shareReplay(1)
    );

  constructor(dropdownValueStore: DropdownValueStore) {
    super(dropdownValueStore);
  }
}
