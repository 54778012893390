<app-secondary-base-widget headerText="Client Birthdays" [widgetCode]="widgetCode">
  <app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
  <app-date-range ngProjectAs="input" [formControl]="dateControl"></app-date-range>
  <div class="position-relative h-100">
    <div class="container-fluid h-100 px-0">
      <div class="row pb-2 client_birthdays__row font-weight-bold">
        <div class="col-5 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          Client Name
        </div>
        <div class="col-4 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          Birthday
        </div>
        <div class="col-3 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          Age
        </div>
      </div>
      <div class="row bg-white client_birthdays__table-body position-relative">
        <div class="col px-3 position-relative h-100">
          <div class="client_birthdays__table-body__wrapper">
            <div class="row client_birthdays__row" *ngFor="let row of data$ | async">
              <div class="col-5 py-2 body-col tap-text-color-h4">
                <a class="clickable tap-text-color-h4" [routerLink]="goToClientProfile(row.CustomerID)">{{
                  row.ClientName
                }}</a>
              </div>
              <div class="col-4 py-2 font-weight-bold body-col tap-text-color-h4">
                {{ row.Birthday | momentDatetime }}
              </div>
              <div class="col-3 py-2 font-weight-bold body-col tap-text-color-h4">
                {{ row.Age }}
              </div>
              <div class="col-12">
                <div class="border-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-secondary-base-widget>
