import { DefaultFileNames } from 'src/app/shared/models/documents/document.model';
import { EmailBodyTypes, EmailTypes } from 'src/app/shared/models/emails/crt/email.model';
import { AuthorityToProceedState } from './authority-to-proceed-settings.model';

export class AuthorityToProceedMapper {
  public static mapEmailSettingsToUpsert(data: AuthorityToProceedState) {

    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: EmailTypes.AuthorityToProceed
    };
  }

  public static mapStaffEmailBody(document, docId, staffId) {
    if (docId === 0 || !docId) {
      return {
        document,
        referenceId: +staffId,
        fileName: DefaultFileNames.SettingsEmailBody,
        type: EmailBodyTypes.AuthorityToProceed
      };
    }
    return {
      document,
      documentId: +docId,
    };
  }

  public static mapEmailSettingsData(data: AuthorityToProceedState) {
    if (data) {
      const { template, mergeTag, ...others } = data;
      return others;
    }
    return null;
  }
}

