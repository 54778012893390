import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { PropertyMapper } from 'src/app/modules/crm/crt-page/_shared/mapper/property.mapper';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { logMessage } from '../../../../../error-message/error-message';

@Component({
	selector: 'app-moat-property-form',
	templateUrl: './property-form.html',
	styleUrls: ['./property-form.scss'],
})
export class MOATPropertyFormComponent implements OnInit {
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() propertyUse: ViewDisplayValue[];
	@Input() securityValuation: ViewDisplayValue[];
	@Input() type: ViewDisplayValue[];
	@Input() title: ViewDisplayValue[];
	@Input() rentalFrequencyChoices: ViewDisplayValue[];
	@Input() rateFrequencyChoices: ViewDisplayValue[];
	@Input() insuranceFrequencyChoices: ViewDisplayValue[];
	@Input() otherExpenseFrequencyChoices: ViewDisplayValue[];
	@Input() policyOwners: ViewDisplayValue[];

	@Input() propertyInfo: any;
	@Input() isLoading: boolean;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;
	isLoadingBtn = false;

	cancelLoading: boolean;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

  owners$: any;

	constructor(private fb: UntypedFormBuilder, private loggerService: LoggerService) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();

    this.owners$ = of(this.getOwnerChoices([], this.policyOwners));
	}

	prepareData() {
		if (!this.propertyInfo) {
			return;
		}

		this.form.reset(PropertyMapper.mapToView(this.propertyInfo));
	}

	get PropertyAddress() {
		return this.form.get('propertyAddress');
	}
	get PropertyValue() {
		return this.form.get('propertyValue');
	}
	get Owners() {
		return this.form.get('propertyOwner');
	}

	get RentalIncome() {
		return this.form.get('rentalIncome');
	}

	get BoarderIncome() {
		return this.form.get('boarderIncome');
	}

	get PropertyUse() {
		return this.form.get('propertyUse');
	}

	buildForm() {
		this.form = this.fb.group({
			propertyAddress: ['', Validators.required],
			propertyUse: [''],
			purchaseDate: [null],
			propertyValue: ['', Validators.required],
			valuationType: [null],
			valuationTypeDate: [null],
			propertyOwner: ['', Validators.required],
			rentalIncome: [null],
			rentalIncomeFrequency: ['Fortnightly'],
			boarderIncome: [null],
			boarderIncomeFrequency: ['Fortnightly'],
			title: [null],
			rates: [null],
			ratesFrequency: ['Quarterly'],
			type: [null],
			insurance: [null],
			insuranceFrequency: ['Monthly'],
			otherExpense: [null],
			otherExpenseFrequency: ['Monthly'],
		});
	}

	// policyOwnerChoices(owners: (string | number)[]) {
	// 	return this.getOwnerChoices(owners, this.policyOwners);
	// }

	saveBtnClick() {
		if (this.isLoading || this.cancelLoading) {
			return;
		}
		this.submitted = true;
		this.isLoadingBtn = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			this.isLoadingBtn = false;
			return;
		}
		const value = this.form.getRawValue();
		this.saveEvent.emit(
			!!this.propertyInfo
				? {
						...value,
						cRTId: this.propertyInfo.CRTId,
						customerServiceID: this.propertyInfo.CustomerServiceID,
				  }
				: value
		);
		this.isLoadingBtn = false;
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => this.cancelLoading = false, 500);
	}

	onChangePropertyUse(propertyUse) {
		if (propertyUse === 'Owner Occupied') {
			this.RentalIncome.setValue(null);
		}
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.policyOwners);
	}

}
