import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AnnualApiStatsFinancialYearStore, AnnualApiStatsFinancialYearState } from './aasf.store';

/**
 * Query for Annual API Stats Financial Year widget data
 */
@Injectable()
export class AnnualApiStatsFinancialYearQuery extends Query<AnnualApiStatsFinancialYearState> {

  /** Annual API Stats data. */
  AnnualApiStatData$ = this.select(state =>
    state.AAStats
  );

  /** Indicator if AnnualApiStatus is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for AnnualApiStatus pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: AnnualApiStatsFinancialYearStore) {
    super(store);
  }
}
