<span class="p-2" *ngIf="isLoading$ | async">
	<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
</span>
<div class="d-md-flex" *ngIf="!(isLoading$ | async)">
	<div class="px-3 custom-sidebar-nav custom-sidebar-nav--vertical mb-3 pr-0">
		<div
			class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
		>
			<div class="col px-0 mb-2">Property Portfolio</div>
			<ng-container *appRestrictTo="['FAS']">
				<div class="col-auto px-0">
					<i
						class="add-button-sidenav clickable material-icons add-button-sidenav-plus border-0 font-weight-bold md-20 tap-text-color-h6"
						id="createPropertyAsset"
						[class.disabled]="
							isAddNew ||
							(isLoading$ | async) ||
							addNewLiability ||
							isEditingLiability
						"
						(click)="addNewProperty()"
						>add</i
					>
				</div>
			</ng-container>
		</div>
		<div
			class="custom-sidebar-nav__divider-header d-flex align-items-center pb-2"
		>
			<div class="col font-weight-bold px-0">
				Total Property Portfolio: <br />{{ PortfolioValue | currency }}
			</div>
		</div>

		<!-- Properties -->
		<div
			class="custom-sidebar-nav__divider-header d-flex flex-column pb-2 properties-tabset"
		>
			<ng-template
				ngFor
				let-pa
				let-i="index"
				[ngForOf]="propertiesAndAssets"
				[ngForTrackBy]="trackById"
			>
				<div
					*ngIf="
						(!pa.assetType && pa?.isActive !== 2) ||
						(pa?.isActive === 2 && isShowArchived)
					"
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2"
					[ngClass]="{
						'list--success': pa.color === 'green',
						'list--warning': pa.color === 'yellow',
						'list--danger': pa.color === 'red',
						'list--dark': pa.color === 'dark',
						'list--blue': pa.color === 'blue'
					}"
					id="{{ pa.customerServiceID || 0 }}"
					[class.active]="
						this.activeGroupNavId?.toString() ===
						pa?.customerServiceID?.toString()
					"
					(click)="selectGroupConfirm(pa?.customerServiceID, pa)"
				>
					<ng-container
						*ngIf="
							pa.customerServiceID && pa.customerServiceID > 0;
							else showAddNewProperty
						"
					>
						<strong>{{ pa.propertyAddress }}</strong>
						<span class="font-italic">Value: ${{ pa.gRM1Value }}</span>
						<i class="material-icons md-20 arrow-right">chevron_right</i>
					</ng-container>
					<ng-template #showAddNewProperty>
						<strong>Add Property</strong><br />
						<i
							class="material-icons arrow-right md-20 d-flex align-items-center"
							>chevron_right</i
						>
					</ng-template>
				</div>
			</ng-template>
		</div>

		<!-- Non-Property Assets & Liabilities -->
		<div
			class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
		>
			<div class="col px-0">
				Non-Property Assets &<br />
				Liabilities
			</div>
			<ng-container *appRestrictTo="['FAS']">
				<div class="col-auto px-0">
					<i
						class="add-button-sidenav clickable material-icons add-button-sidenav-plus border-0 font-weight-bold md-20 tap-text-color-h6"
						id="createPropertyAsset"
						[class.disabled]="
							isAddNew ||
							(isLoading$ | async) ||
							addNewLiability ||
							isEditingLiability
						"
						(click)="createModal()"
						>add</i
					>
				</div>
			</ng-container>
		</div>

		<div
			class="custom-sidebar-nav__divider-header d-flex flex-column pb-2 properties-tabset"
		>
			<!-- Add New Current Asset -->
			<ng-container
				*ngIf="addNewAsset && !currentAssets?.customerServices?.length"
			>
				<div
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2"
					[class.active]="this.activeGroupNavId === currentAssetsTabId"
					id="{{ currentAssetsTabId }}_add"
					(click)="selectAssetTab(currentAssetsTabId)"
				>
					<strong>Assets - Current</strong>
					<span class="font-italic">Value: $0</span>
					<i class="material-icons md-20 arrow-right">chevron_right</i>
				</div>
			</ng-container>

			<!-- Current Assets -->
			<ng-container *ngIf="!!currentAssets?.customerServices?.length">
				<div
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--success"
					[class.active]="this.activeGroupNavId === currentAssetsTabId"
					id="{{ currentAssetsTabId }}"
					(click)="selectAssetTab(currentAssetsTabId)"
				>
					<strong>Assets - Current</strong>
					<span class="font-italic"
						>Value: ${{ currentAssets?.totalValue || 0 }}</span
					>
					<i class="material-icons md-20 arrow-right">chevron_right</i>
				</div>
			</ng-container>

			<!-- Add New Current Liability -->
			<ng-container
				*ngIf="
					showNewCurrentLiabilityTab &&
					!liabilityCurrent?.customerServices?.length
				"
			>
				<div
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2"
					[class.active]="this.activeGroupNavId === liabilityCurrentId"
					id="{{ liabilityCurrentId }}"
					(click)="selectAssetTab(liabilityCurrentId)"
				>
					<strong>Liability - Current</strong>
					<span class="font-italic">Value: $0</span>
					<i class="material-icons md-20 arrow-right">chevron_right</i>
				</div>
			</ng-container>

			<!-- Current Liabilities -->
			<ng-container *ngIf="!!liabilityCurrent?.customerServices?.length">
				<div
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--success"
					[class.active]="this.activeGroupNavId === liabilityCurrentId"
					id="{{ liabilityCurrentId }}"
					(click)="selectAssetTab(liabilityCurrentId)"
				>
					<strong>Liability - Current</strong>
					<span class="font-italic"
						>Value: ${{ liabilityCurrent?.totalValue || 0 }}</span
					>
					<i class="material-icons md-20 arrow-right">chevron_right</i>
				</div>
			</ng-container>

			<!-- Sold Assets -->
			<ng-container *ngIf="!!soldAssets?.customerServices?.length">
				<div
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--dark"
					[class.active]="this.activeGroupNavId === soldAssetsTabId"
					id="{{ soldAssetsTabId }}"
					(click)="selectAssetTab(soldAssetsTabId)"
				>
					<strong>Assets - Sold</strong>
					<span class="font-italic"
						>Value: ${{ soldAssets?.totalValue || 0 }}</span
					>
					<i class="material-icons md-20 arrow-right">chevron_right</i>
				</div>
			</ng-container>

			<!-- Add New Discharged Liability -->
			<ng-container
				*ngIf="
					showNewDischargedLiabilityTab &&
					!liabilityDischarged?.customerServices?.length
				"
			>
				<div
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2"
					[class.active]="this.activeGroupNavId === liabilityDischargedId"
					id="{{ liabilityDischargedId }}"
					(click)="selectAssetTab(liabilityDischargedId)"
				>
					<strong>Liability - Discharged</strong>
					<span class="font-italic">Value: $0</span>
					<i class="material-icons md-20 arrow-right">chevron_right</i>
				</div>
			</ng-container>

			<!-- Discharged Liabilities -->
			<ng-container *ngIf="!!liabilityDischarged?.customerServices?.length">
				<div
					class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--dark"
					[class.active]="this.activeGroupNavId === liabilityDischargedId"
					id="{{ liabilityDischargedId }}"
					(click)="selectAssetTab(liabilityDischargedId)"
				>
					<strong>Liability - Discharged</strong>
					<span class="font-italic"
						>Value: ${{ liabilityDischarged?.totalValue || 0 }}</span
					>
					<i class="material-icons md-20 arrow-right">chevron_right</i>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="row" *ngIf="false">
		<div class="col plus-icon">
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
			>
				<div class="col px-0 form-inline">
					<span class="font-weight-bold px-0 sidebar__title"
						>Porfolio Value: {{ PortfolioValue | currency }}
					</span>
					<!-- Add New Group -->
					<ng-container *appRestrictTo="['FAS']">
						<button
							type="button"
							[disabled]="
								isAddNew ||
								(isLoading$ | async) ||
								addNewLiability ||
								isEditingLiability
							"
							(click)="createModal()"
							id="createPropertyAsset"
							class="add-button-sidenav border-0 font-weight-bold md-20 tap-text-color-h6"
						>
							<i class="material-icons add-button-sidenav-plus">add</i>
						</button>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="col-auto d-flex">
			<!-- <ng-container *ngIf="isLoading">
        <div class="d-flex flex-row bd-highlight justify-content-end px-2 pt-2">
          <app-manual-loader-mini
            *ngIf="isLoading"
            [isLoading]="isLoading"
          ></app-manual-loader-mini>
        </div>
      </ng-container> -->

			<!-- Show Archive -->
			<ng-container *appRestrictTo="['FAS']">
				<button
					*ngIf="
						(activeGroupNavId === liabilityCurrentId ||
							activeGroupNavId === liabilityDischargedId) &&
						!isAddNewGroup
					"
					[disabled]="addNewLiability || isEditingLiability"
					type="button"
					(click)="addNewLiabilityData()"
					class="tap-btn tap-btn--default mr-2"
					id="addLiability"
				>
					Add Liability +
				</button>
			</ng-container>
			<ng-container *appRestrictTo="['FVAS']">
				<div
					class="mr-2"
					*ngIf="!isAddNewGroup && propertiesAndAssets.length > 0"
				>
					<label for="showArchive" class="mr-2">Show Archived</label>
					<app-checkbox
						class="clickable d-inline-block"
						style="transform: translate(0px, 4px)"
					>
						<input
							type="checkbox"
							[checked]="!!isShowArchived"
							name="showArchive"
							id="showArchive"
							class="checkbox"
							(change)="showArchived($event.target.checked)"
						/>
					</app-checkbox>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="pr-3 main-content w-100">
		<div class="px-0 col-12 col-md">
			<ng-container *ngIf="isLoading$ | async">
				<app-manual-loader
					[isLoading]="isLoading$ | async"
					[opts]="{
						lines: 9,
						length: 27,
						width: 20,
						radius: 45,
						scale: 0.5
					}"
				></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!(isLoading$ | async)">
				<div class="col-auto d-flex justify-content-end mb-4 pr-1 mr-1">
					<!-- Show Archive -->
					<ng-container *appRestrictTo="['FAS']">
						<button
							*ngIf="
								(activeGroupNavId === liabilityCurrentId ||
									activeGroupNavId === liabilityDischargedId) &&
								!isAddNewGroup
							"
							[disabled]="addNewLiability || isEditingLiability"
							type="button"
							(click)="addNewLiabilityData()"
							class="tap-btn tap-btn--default mr-2"
							id="addLiability"
						>
							Add Liability +
						</button>
					</ng-container>
					<ng-container *appRestrictTo="['FVAS']">
						<div
							class="mr-2"
							*ngIf="!isAddNewGroup && propertiesAndAssets.length > 0"
						>
							<label for="showArchive" class="mr-2">Show Archived</label>
							<app-checkbox
								class="clickable d-inline-block"
								style="transform: translate(0px, 4px)"
							>
								<input
									type="checkbox"
									[checked]="!!isShowArchived"
									name="showArchive"
									id="showArchive"
									class="checkbox"
									(change)="showArchived($event.target.checked)"
								/>
							</app-checkbox>
						</div>
					</ng-container>
				</div>
				<ng-container #outlet [ngTemplateOutlet]="content"> </ng-container>

				<ng-template #content>
					<tabset
						#sidebarNav
						class="tap-tabset-sidebar"
						[vertical]="true"
						id="pal-tabset"
					>
						<ng-template
							ngFor
							let-pa
							let-i="index"
							[ngForOf]="propertiesAndAssets"
							[ngForTrackBy]="trackById"
						>
							<!-- Properties Assets Tab -->
							<ng-container
								*ngIf="
									(!pa.assetType && pa?.isActive !== 2) ||
									(pa?.isActive === 2 && isShowArchived)
								"
							>
								<tab id="{{ pa.customerServiceID }}">
									<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
										<div class="container-fluid px-0 px-md-3 pr-lg-0">
											<ng-container *ngIf="isAddNew">
												<app-property-assets-form
													id="property_{{ i }}"
													[formId]="
														pa?.customerServiceID || pa?.customerServiceID !== 0
															? i
															: 'add'
													"
													[isLead]="isLead"
													[isCompany]="isCompany"
													[addMode]="true"
													[propertyOwners]="propertyOwners"
													[valuations1]="valuations1"
													[valuations2]="valuations2"
													[types]="types"
													[uses]="uses"
													[titles]="titles"
													[status]="status"
													(saveEvent)="createSecurity($event)"
													(cancelAddEvent)="cancelAddNewGroup($event)"
												>
												</app-property-assets-form>
											</ng-container>
											<ng-container *ngIf="!isAddNew">
												<app-property-assets-form
													#paForms
													id="property_{{ i }}"
													[isLead]="isLead"
													[isCompany]="isCompany"
													[addMode]="false"
													[isSaving]="isSaving"
													[propertyAsset]="pa"
													[propertyOwners]="propertyOwners"
													[valuations1]="valuations1"
													[valuations2]="valuations2"
													[types]="types"
													[status]="status"
													[uses]="uses"
													[titles]="titles"
													[formId]="i"
													(saveEvent)="saveSecurity($event)"
													(deleteEvent)="deleteSecurity($event)"
													(archiveEvent)="archiveSecurity($event)"
													(deleteNoteEvent)="deleteNote($event)"
													(addNoteEvent)="addNote($event)"
												>
												</app-property-assets-form>
											</ng-container>
										</div>
									</div>
								</tab>
							</ng-container>

							<!-- Current Assets Tab -->
							<ng-container
								*ngIf="
									pa.assetType === assetTypes.current &&
									pa.customerServices.length > 0
								"
							>
								<tab id="{{ currentAssetsTabId }}">
									<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
										<div class="container-fluid px-0 px-md-3 pr-lg-0">
											<app-assets-service-form
												#assets
												id="assetsUnsold"
												[owners]="assetOwners"
												[isLead]="isLead"
												[isCompany]="isCompany"
												[assetStatus]="assetCurrent"
												[assetState]="assetState"
												[assets]="currentAssets"
												[customerID]="customerId"
												[isUpdatingOrAdding]="isSaving"
												(addAssetEvent)="addAsset($event)"
												(saveAssetEvent)="saveAsset($event)"
												(deleteAssetEvent)="deleteAsset($event)"
												(soldAssetEvent)="soldAsset($event)"
												(addNoteEvent)="addNoteAsset($event)"
												(deleteNoteEvent)="deleteNoteAsset($event)"
											>
											</app-assets-service-form>
										</div>
									</div>
								</tab>
							</ng-container>

							<!-- Sold Assets Tab -->
							<ng-container
								*ngIf="
									pa.assetType === assetTypes.sold &&
									pa.customerServices.length > 0
								"
							>
								<tab id="{{ soldAssetsTabId }}">
									<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
										<div class="container-fluid px-0 px-md-3 pr-lg-0">
											<app-assets-service-form
												#soldAssetsList
												id="assetsSold"
												[owners]="assetOwners"
												[isLead]="isLead"
												[isCompany]="isCompany"
												[assetStatus]="assetSold"
												[assetState]="assetState"
												[assets]="soldAssets"
												[customerID]="customerId"
												(deleteAssetEvent)="deleteAsset($event)"
											>
											</app-assets-service-form>
										</div>
									</div>
								</tab>
							</ng-container>

							<!-- Current Liability -->
							<ng-container
								*ngIf="
									pa.assetType === liabilityTypes.current &&
									liabilityCurrent?.customerServices?.length
								"
							>
								<tab
									id="{{ liabilityCurrentId }}"
									(removed)="removedLiabilityTab()"
								>
									<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
										<div class="container-fluid px-0 px-md-3 pr-lg-0">
											<app-liability-service
												*ngIf="
													isActive && activeGroupNavId === liabilityCurrentId
												"
												#currentLiabilityService
												[addNewLiability]="addNewLiability"
												type="current"
												[liabilities]="liabilityCurrent?.customerServices"
												[notes]="liabilityCurrent?.notes"
												[liabilityStatus]="liabilityStatus"
												[borrowers]="propertyOwners"
												[liabilityChoices]="liabilities"
												[lenders]="lenders"
												[loanTypes]="loanTypes"
												[repaymentFrequency]="repaymentFrequency"
												[saveFn$]="saveLiabilityFn$"
												[updateFn$]="updateLiabilityFn$"
												[deleteFn$]="deleteLiabilityFn$"
												[isLead]="isLead"
												[customerID]="customerId"
												(addNoteEvent)="addNoteLiability($event)"
												(deleteNoteEvent)="deleteNoteLiability($event)"
												(onAddLiability)="onAddedLiability($event)"
												(onCancelAddOrEdit)="onCancelAddOrEdit($event)"
												(onEditLiability)="onEditLiability($event)"
											>
											</app-liability-service>
										</div>
									</div>
								</tab>
							</ng-container>

							<!-- Discharged Liability -->
							<ng-container
								*ngIf="
									pa.assetType === liabilityTypes.discharged &&
									liabilityDischarged?.customerServices?.length
								"
							>
								<tab
									id="{{ liabilityDischargedId }}"
									(removed)="removedLiabilityTab()"
								>
									<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
										<div class="container-fluid px-0 px-md-3 pr-lg-0">
											<app-liability-service
												[addNewLiability]="addNewLiability"
												*ngIf="
													isActive && activeGroupNavId === liabilityDischargedId
												"
												#dischargedLiabilityService
												type="discharged"
												[liabilities]="liabilityDischarged?.customerServices"
												[liabilityStatus]="liabilityStatus"
												[borrowers]="propertyOwners"
												[liabilityChoices]="liabilities"
												[loanTypes]="loanTypes"
												[lenders]="lenders"
												[repaymentFrequency]="repaymentFrequency"
												[saveFn$]="saveLiabilityFn$"
												[updateFn$]="updateLiabilityFn$"
												[deleteFn$]="deleteLiabilityFn$"
												[isLead]="isLead"
												[customerID]="customerId"
												(addNoteEvent)="addNoteLiability($event)"
												(deleteNoteEvent)="deleteNoteLiability($event)"
												(onAddLiability)="onAddedLiability($event)"
												(onCancelAddOrEdit)="onCancelAddOrEdit($event)"
												(onEditLiability)="onEditLiability($event)"
											>
											</app-liability-service>
										</div>
									</div>
								</tab>
							</ng-container>
						</ng-template>

						<ng-container *ngIf="addNewAsset && !hasCurrentAssets">
							<tab id="{{ currentAssetsTabId }}">
								<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
									<div class="container-fluid px-0 px-md-3 pr-lg-0">
										<app-assets-service-form
											#assets
											id="assetsUnsold"
											[owners]="assetOwners"
											[isLead]="isLead"
											[isCompany]="isCompany"
											[assetStatus]="assetCurrent"
											[assetState]="assetState"
											[assets]="currentAssets"
											[customerID]="customerId"
											[isUpdatingOrAdding]="isSaving"
											(addAssetEvent)="addAsset($event)"
											(saveAssetEvent)="saveAsset($event)"
											(deleteAssetEvent)="deleteAsset($event)"
											(soldAssetEvent)="soldAsset($event)"
											(addNoteEvent)="addNoteAsset($event)"
											(deleteNoteEvent)="deleteNoteAsset($event)"
										>
										</app-assets-service-form>
									</div>
								</div>
							</tab>
						</ng-container>

						<!-- ADD NEW CURRENT LIABILITY -->
						<ng-container *ngIf="showNewCurrentLiabilityTab">
							<tab id="{{ liabilityCurrentId }}">
								<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
									<div class="container-fluid px-0 px-md-3 pr-lg-0">
										<app-liability-service
											#currentLiabilityService
											type="current"
											[addNewLiability]="addNewLiability"
											[liabilities]="liabilityCurrent?.customerServices"
											[notes]="liabilityCurrent?.notes"
											[liabilityStatus]="liabilityStatus"
											[liabilityChoices]="liabilities"
											[borrowers]="propertyOwners"
											[loanTypes]="loanTypes"
											[lenders]="lenders"
											[repaymentFrequency]="repaymentFrequency"
											[saveFn$]="saveLiabilityFn$"
											[updateFn$]="updateLiabilityFn$"
											[deleteFn$]="deleteLiabilityFn$"
											[isLead]="isLead"
											[customerID]="customerId"
											(addNoteEvent)="addNoteLiability($event)"
											(deleteNoteEvent)="deleteNoteLiability($event)"
											(onAddLiability)="onAddedLiability($event)"
											(onCancelAddOrEdit)="onCancelAddOrEdit($event)"
											(onEditLiability)="onEditLiability($event)"
										>
										</app-liability-service>
									</div>
								</div>
							</tab>
						</ng-container>

						<!-- ADD NEW DISCHARGED LIABILITY -->
						<ng-container *ngIf="showNewDischargedLiabilityTab">
							<tab id="{{ liabilityDischargedId }}">
								<div class="col px-0 px-md-3 pl-lg-0 pr-lg-0">
									<div class="container-fluid px-0 px-md-3 pr-lg-0">
										<app-liability-service
											#dischargedLiabilityService
											type="discharged"
											[addNewLiability]="addNewLiability"
											[liabilities]="liabilityDischarged?.customerServices"
											[notes]="liabilityDischarged?.notes"
											[liabilityStatus]="liabilityStatus"
											[liabilityChoices]="liabilities"
											[borrowers]="propertyOwners"
											[loanTypes]="loanTypes"
											[lenders]="lenders"
											[repaymentFrequency]="repaymentFrequency"
											[saveFn$]="saveLiabilityFn$"
											[updateFn$]="updateLiabilityFn$"
											[deleteFn$]="deleteLiabilityFn$"
											[isLead]="isLead"
											[customerID]="customerId"
											(addNoteEvent)="addNoteLiability($event)"
											(deleteNoteEvent)="deleteNoteLiability($event)"
											(onAddLiability)="onAddedLiability($event)"
											(onCancelAddOrEdit)="onCancelAddOrEdit($event)"
											(onEditLiability)="onEditLiability($event)"
										>
										</app-liability-service>
									</div>
								</div>
							</tab>
						</ng-container>
					</tabset>
				</ng-template>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #tabHeadingAdd>
	<strong>Add Property</strong><br />
	<i class="material-icons arrow-right md-20 d-flex align-items-center"
		>chevron_right</i
	>
</ng-template>
