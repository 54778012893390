<app-secondary-base-widget headerText="Activities" [widgetCode]="widgetCode">
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<button
		*appRestrictTo="['FAA']"
		class="icon-btn clickable py-1 px-3 mb-2 h-auto"
		ngProjectAs="input"
		(click)="createActivityModal()"
	>
		<i class="material-icons tap-text-color-h6"> calendar_today </i>
	</button>
	<app-date-range
		#dateRange
		ngProjectAs="input"
		[formControl]="dateControl"
	></app-date-range>
	<div class="position-relative clearfix h-100 px-0">
		<app-a-table></app-a-table>
	</div>
</app-secondary-base-widget>
