<form
	[formGroup]="form"
	[class.submitted]="formSubmitted"
	class="position-relative"
>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<span class="crt-custom-dropdown w-100" *ngIf="title && title.length">
				<select
					id="title"
					formControlName="title"
					class="crt-form-control form-control"
					placeholder="Title"
					[class.select-text-muted]="!form.get('title').value"
				>
					<option class="select-text-muted" value="" disabled selected hidden>
						Title
					</option>
					<option
						class="select-text-option"
						*ngFor="let d of title; trackBy: trackByValue"
						[value]="d.value"
					>
						{{ d.display }}
					</option>
				</select>
			</span>
			<label
				for=""
				class="position-relative font-weight-bold"
				*ngIf="!(title && title.length)"
			>
				Name:
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<input
				id="firstName"
				formControlName="firstName"
				class="form-control crt-form-control"
				type="text"
				placeholder="First Name"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-3 mb-2">
			<input
				id="middleName"
				formControlName="middleName"
				class="form-control crt-form-control"
				type="text"
				placeholder="Middle Name"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-4 mb-2">
			<input
				id="lastName"
				formControlName="lastName"
				class="form-control crt-form-control"
				type="text"
				placeholder="Last Name"
			/>
		</div>
	</div>
	<hr />
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Known As:
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<input
				formControlName="knownAs"
				class="form-control crt-form-control"
				type="text"
				placeholder="--"
			/>
		</div>
		<div class="col-4 col-md-3 text-sm-left text-md-right mb-2">
			<label for="" class="position-relative font-weight-bold"> Gender: </label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<span class="crt-custom-dropdown w-100">
				<select
					[selectPlaceholder]="{defaultValue: ''}"
					id="gender"
					formControlName="gender"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value="" selected>Select</option>
					<option *ngFor="let d of gender; trackBy: trackByValue" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> D.O.B: </label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<app-date-input
				id="dateOfBirth"
				textboxId="dateOfBirth"
				class="crt-form-control-field"
				formControlName="dateOfBirth"
				placeholder="dd/mm/yyyy"
				(onChangeEvent)="onDateChange($event)"
			></app-date-input>
		</div>
		<div class="col-4 col-md-3 text-sm-left text-md-right mb-2">
			<label for="" class="position-relative font-weight-bold">
				Residency Status:
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<span class="crt-custom-dropdown w-100">
				<select
					formControlName="residencyStatus"
					id="residencyStatus"
					class="form-control theme-crm-field-disabled crt-form-control"
					[selectPlaceholder]="{defaultValue: ''}"
				>
					<option value="" selected>Select</option>
					<option *ngFor="let d of visaDetails; trackBy: trackByValue" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div
		class="form-row align-items-center"
		*ngIf="hasCountry && this.Residency.value && this.Residency.value !== nzCitizenStatus"
	>
		<div class="col-4 col-md-3 text-sm-left text-md-right mb-2 offset-md-5">
			<label for="countryOfOrigin" class="position-relative font-weight-bold">
				Country of Origin:
			</label>
		</div>
		<div class="col-8 col-md-4 mb-2">
			<input
				formControlName="countryOfOrigin"
				id="countryOfOrigin"
				class="form-control crt-form-control"
				type="text"
				placeholder="Country"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> Phone: </label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<input
				formControlName="mobilePhone"
				id="mobilePhone"
				class="form-control crt-form-control"
				type="text"
				placeholder="Mobile"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-3 mb-2">
			<input
				formControlName="homePhone"
				id="homePhone"
				class="form-control crt-form-control"
				type="text"
				placeholder="Home"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-4 mb-2">
			<input
				formControlName="workPhone"
				id="workPhone"
				class="form-control crt-form-control"
				type="text"
				placeholder="Work"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> Email: </label>
		</div>
		<div
			[ngClass]="timeInResidence && timeInResidence.length ? 'col-8 col-md-6 mb-2' : 'col-8 col-md-10 mb-2'"
		>
			<input
				formControlName="email"
				id="email"
				class="form-control crt-form-control"
				type="text"
				placeholder="--"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Physical Address:
			</label>
		</div>
		<div
			[ngClass]="timeInResidence && timeInResidence.length ? 'col-8 col-md-6 mb-2' : 'col-8 col-md-10 mb-2'"
		>
			<app-address-input
				inputId="physicalAddress"
				id="physicalAddress"
				formControlName="physicalAddress"
				textboxClass="theme-crm-field-disabled"
				class="crt-form-control-field"
				placeholder="--"
			></app-address-input>
		</div>
		<div
			class="col-4 col-md-4 mb-2"
			*ngIf="timeInResidence && timeInResidence.length"
		>
			<span class="crt-custom-dropdown w-100">
				<select
					id="timeInResidencePhysicalAddress"
					formControlName="timeInResidencePhysicalAddress"
					class="form-control theme-crm-field-disabled crt-form-control"
					[class.select-text-muted]="!form.get('timeInResidencePhysicalAddress').value"
				>
					<option class="select-text-muted" value="" disabled selected hidden>
						Time in Residence
					</option>
					<option
						class="select-text-option"
						*ngFor="let d of timeInResidence; trackBy: trackByValue"
						[value]="d.value"
					>
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div
		class="form-row align-items-center"
		*ngIf="timeInResidence && timeInResidence.length"
	>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Previous Address
			</label>
		</div>
		<div class="col-8 col-md-6 mb-2">
			<app-address-input
				inputId="previousAddress"
				id="previousAddress"
				formControlName="previousAddress"
				textboxClass="theme-crm-field-disabled"
				class="crt-form-control-field"
				tabindex="24"
			></app-address-input>
		</div>
		<div class="col-4 col-md-4 mb-2">
			<span class="crt-custom-dropdown w-100">
				<select
					id="timeInResidencePreviousAddress"
					formControlName="timeInResidencePreviousAddress"
					class="form-control theme-crm-field-disabled crt-form-control"
					[class.select-text-muted]="!form.get('timeInResidencePreviousAddress').value"
				>
					<option class="select-text-muted" value="" disabled selected hidden>
						Time in Residence
					</option>
					<option
						class="select-text-option"
						*ngFor="let d of timeInResidence; trackBy: trackByValue"
						[value]="d.value"
					>
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label
				for=""
				class="position-relative font-weight-bold"
				*ngIf="!(title && title.length)"
			>
				Employment:
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
      <span class="crt-custom-dropdown w-100">
				<select
					id="employment"
					formControlName="employment"
					class="form-control theme-crm-field-disabled crt-form-control"
					[selectPlaceholder]="{defaultValue: ''}"
				>
					<option value="" selected>Select</option>
					<option *ngFor="let d of employment; trackBy: trackByValue" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-3 mb-2">
			<input
				id="occupation"
				formControlName="occupation"
				class="form-control crt-form-control"
				type="text"
				placeholder="Occupation"
			/>
		</div>
		<div class="offset-4 offset-md-0 offset-lg-0 col-8 col-md-4 mb-2">
      <div class="dollar-icon">
        <input
          id="income"
          class="form-control crt-form-control"
          type="text"
          formControlName="income"
          placeholder="Income"
          currencyMask
          appCurrencyMaskExt
        />
        <i>$</i>
      </div>
		</div>
	</div>
	<div class="form-row align-items-center">
    <ng-container *ngIf="showRelationShipField">
      <div class="col-4 col-md-2 text-sm-left text-md-left mb-2">
        <label for="" class="position-relative font-weight-bold">
          Relationship:
        </label>
      </div>
      <div class="col-8 col-md-3 mb-2">
        <span class="crt-custom-dropdown w-100">
          <select
						formControlName="relationship"
						id="relationship"
						class="form-control theme-crm-field-disabled crt-form-control"
						[selectPlaceholder]="{defaultValue: ''}"
					>
            <option value="" selected>Select</option>
            <option *ngFor="let d of relationshipDd; trackBy: trackByValue" [value]="d.value">
              {{ d.display }}
            </option>
          </select>
        </span>
      </div>
    </ng-container>
  </div>

	<div class="form-buttons">
		<button
			id="saveButton"
			type="button"
			class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
			(click)="save()"
			[disabled]="loadingState"
		>
			Save
			<i *ngIf="loadingState" class="fas fa-spinner fa-pulse"></i>
		</button>
		<button
			id="cancelButton"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancelClick()"
			[disabled]="loadingState"
		>
			Cancel
		</button>
	</div>
</form>
