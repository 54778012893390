import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RouteLoaderState, RouteLoaderStore } from './route-loader.store';

@Injectable()
export class RouteLoaderQuery extends Query<RouteLoaderState> {
	skipLoading$ = this.select((x) => x.skipLoading);

	constructor(protected store: RouteLoaderStore) {
		super(store);
	}
}