import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, Observable, Subject, iif, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { DeclarationSettingState } from 'src/app/modules/crt-settings/declaration-settings/declaration-template-settings/state/declaration-settings.model';
import {
	AdviceProcessPageCodes,
	LoatPdfFooterCodes,
	LoatPdfHeaderCodes,
	ServiceAdviceProcessState,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { DeclarationState } from 'src/app/shared/models/client-review-template/declaration/declaration.model';
import {
	DocumentModel,
	DocumentModelState,
	DocumentTypes,
	SignatureTypes,
} from 'src/app/shared/models/documents/document.model';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { convertUtil, objectUtil } from '../../../../../util/util';
import { LoatQuery } from '../loat.query';
import { LoatService } from '../loat.service';
import { LoatStore } from '../loat.store';
import { LoatCffSettingState } from '@modules/crt-settings/loat-cff-template-settings/state/loat-cff-template-settings.model';
import { SettingsTypes } from '@modules/crt-settings/state/crt-settings.model';
import { Attachment, CombinedAttachment } from '@shared/models/_general/attachment.model';

export const declarationPdfOptions = {
	FileName: 'DECLARATION',
	DPI: '120',
};
export const LoatCffPdfOptions = {
	MarginTop: '2.5cm',
	MarginBottom: '1.6cm',
	MarginLeft: '0cm',
	MarginRight: '0cm',
	FooterHtmlType: LoatPdfFooterCodes.Default,
	HeaderHtmlType: LoatPdfHeaderCodes.Default
};

// OrderNo = 0 is reserved for Aesthetic cover page (combined by BE)
// OrderNo = 6 is reserved for Declaration cover page (combined by BE)
export const LoatCffPdfOrder: CombinedAttachment[] = [
	{
		orderNo: 1,
		document: '',
		type: DocumentTypes.LOATFFGenericCoverPage,
		toGeneratePdf: true,
	},
	{
		orderNo: 2,
		document: '',
		type: DocumentTypes.LOATFFProcessDocument,
		toGeneratePdf: false,
	},
	{
		orderNo: 3,
		document: '',
		type: DocumentTypes.LOATClientFactFindDocument,
		toGeneratePdf: true,
	},
	{
		orderNo: 4,
		document: '',
		type: DocumentTypes.LOATFFExistingPolicyDocument,
		toGeneratePdf: false,
	},
]

export interface EmailModalData {
	state?: 'next' | 'sent' | 'cancel';
}

export interface EmailModalCallback {
	(data: EmailModalData): void;
}

@Injectable()
export class DeclarationService extends LoatService {
	declaration$ = this.query.declaration$;
	declarationFormValue$ = this.query.declarationFormValue$;
	declarationDocument$ = this.query.declarationDocument$;
	declarationEmailSettings$ = this.query.declarationEmailSettings$;
	clientFactFindSettings$ = this.query.clientFactFindTemplateSettings$;
	clientFactFindFormValue$ = this.query.declarationClientFactFindFormValue$;

	showEmailModal$ = new Subject<EmailModalCallback>();

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: LoatStore,
		protected query: LoatQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	showEmailModal(): Observable<EmailModalData | boolean> {
		const subject = new Subject<EmailModalData | boolean>();

		const callback: EmailModalCallback = (data: EmailModalData) => {
			subject.next(data);
		};

		this.showEmailModal$.next(callback);

		return this.declarationEmailSettings$.pipe(
			mergeMap((setting) => {
				if (setting?.isEnableEmailOption) {
					return subject.asObservable();
				}
				return of(true);
			})
		);
	}

	getDeclarationPdfOptions() {
		return declarationPdfOptions;
	}

	getDeclarationDocument(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setDeclarationDocument(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getDeclarationEmailSettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setDeclarationEmailSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getDeclarationDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getDeclaration(adviceProcessId) {
		const endpoint = `crt/${adviceProcessId}/D`;
		return this.api.get<DeclarationState[]>(endpoint).pipe(
			tap((x) => {
				applyTransaction(() => {
					const state =
						!!x && x?.length > 0
							? objectUtil.mapPascalCaseToCamelCase(x[0])
							: {
									cRTId: 0,
									adviceProcessId: this.query.getValue().adviceProcessId,
									document: { referenceId: null, value: null },
									signatures: [],
								};
					this.store.setDeclaration(state);
				});
			}),
			catchError(() => of([]))
		);
	}

	addDeclaration(declaration) {
		const endpoint = `crt`;
		const adviceProcessId = this.query.getValue().adviceProcessId;
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId,
			sectionCode: 'D',
			signatures: declaration?.signatures?.map((x) =>
				objectUtil.mapCamelCaseToPascalCase(x)
			),
		});
		if (!adviceProcessId) {
			return of(null)
		}
		delete body.CRTId;
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDeclaration({ ...declaration, cRTId: +x });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateDeclaration(declaration) {
		const endpoint = `crt/${declaration?.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId: this.query.getValue().adviceProcessId,
			sectionCode: 'D',
			signatures: declaration?.signatures?.map((x) =>
				objectUtil.mapCamelCaseToPascalCase(x)
			),
		});
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDeclaration(objectUtil.mapPascalCaseToCamelCase(body));
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateClientFindFactAndDeclaration(clientFindFactAndDeclaration) {
		const adviceProcessId = clientFindFactAndDeclaration.adviceProcessId;
		const documentId =
			clientFindFactAndDeclaration?.clientFactFindDeclarationId;
		const sectionCode = AdviceProcessPageCodes.ClientFactFind;
		const endpoint = `adviceprocesses/${adviceProcessId}/document/${documentId}/${sectionCode}`;
		return this.api.put<any>(endpoint).pipe(
			tap((x) => {}),
			catchError(() => EMPTY)
		);
	}

	getSignature(documentId) {
		const endpoint = `documents/${documentId}`;
		return this.api.get<DocumentModel>(endpoint).pipe(catchError(() => EMPTY));
	}

	addSignature(signature) {
		const endpoint = `documents`;
		const body = {
			ReferenceId: this.query.getValue().adviceProcessId,
			Document: signature?.split(',')[1],
			FileName: 'sig' + this.query.getValue().adviceProcessId + '.png',
			Type: SignatureTypes.Declaration,
		};
		return this.api.post<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	updateSignature(documentId, signature) {
		const endpoint = `documents/${documentId}/document-link`;
		const body = {
			Document: signature?.split(',')[1],
			DocumentID: documentId,
		};
		return this.api.put<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	updateDeclarationDocument(documentId, document) {
		const endpoint = `documents/${documentId}/document-link`;
		const body = {
			Document: document,
			DocumentID: documentId,
		};
		return this.api.put<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	getAdviceProcess(adviceProcessId) {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setAdviceProcess(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	documentMapper(data) {
		const docs = data.Documents;
		docs?.forEach((doc) => {
			if (typeof doc.Value !== 'number') {
				doc.Value = doc.Value?.DocumentID;
			}
		});
		return docs;
	}

	sendEmail(data) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);
		const adviceProcessId = this.query.getValue().adviceProcessId;
		let dd: DeclarationDocument;
		return this.api
			.get<DeclarationDocument[]>(`crt/${adviceProcessId}/DD`)
			.pipe(
				map((x) => {
					const res = x?.map(
						(y) => objectUtil.mapPascalCaseToCamelCase(y) as DeclarationDocument
					);
					dd = res.length > 0 ? res[0] : null;
					return dd;
				}),
				// Update or Add Document for Disclosure Document
				mergeMap((x) =>
					iif(
						() => !!x && !!x?.documentID,
						// Update the current document
						this.api.put(`documents/${x?.documentID}/document-link`, {
							Document: d.Document,
							DocumentID: x?.documentID,
						}),
						// Upload new document
						this.api.post(`documents`, {
							ReferenceId: adviceProcessId,
							Document: d.Document,
							FileName: 'Declaration Document.pdf',
							Type: DocumentTypes.Declaration,
						})
					)
				),
				// Create New or update Declaration document
				mergeMap((x) =>
					iif(
						() => !!dd,
						// Update the current Declaration Document
						this.api.put(`crt/${dd?.cRTId}`, {
							AdviceProcessId: adviceProcessId,
							SectionCode: 'D',
							DocumentID: dd?.documentID,
							ParentCRTId: 0,
							CRTId: dd?.cRTId,
						}),
						// Create new Declaration Document
						this.api.post(`crt`, {
							AdviceProcessId: adviceProcessId,
							SectionCode: 'D',
							DocumentID: x,
							ParentCRTId: 0,
						})
					)
				),
				catchError(() => EMPTY)
			);
	}

	setDeclarationFormValue(d: DeclarationState) {
		const data = this.query.getValue().declaration;
		applyTransaction(() => {
			this.store.setDeclarationFromValue({
				...data,
				...d,
			});
		});
	}

	getClientFactFindSettings() {
		// referenceId is always 0 because it comes from the settings
		const referenceId = 0;
		const endpoint = `crt/settings/${referenceId}/${SettingsTypes.LOATClientFactFind}`;
		return this.api.get<LoatCffSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setClientFactFindSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}
	getDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}
	getUploadDoc(document) {
		if (!document) {
			return of(null);
		}
		return this.api.getExternalResourceAsBlob(document?.DocumentLink).pipe(
			map((content) => ({
				content: content,
				documentName: document?.DocumentName,
			})),
			mergeMap((content: any) => {
				return convertUtil.simpleConvertToBase64(content.content).pipe(
					map((result) => {
						content.content = result;
						return content;
					}),
					map(
						(file) =>
							({
								fileName: document?.DocumentName,
								content: file?.content
									?.replace('data:', '')
									.replace(/^.+,/, ''),
							}) as Attachment
					)
				);
			}),
			take(1)
		);
	}

	generateAttachment(
		documentField: 'Scope of Service' | 'Disclosure Document'
	): Observable<Attachment> {
		const doc = this.query
			.getValue()
			.adviceProcess.documents.find((d) => d.field === documentField);
		if (!doc?.value) {
			return of(null);
		}
		return this.getDocumentFile(doc.value.documentID).pipe(
			mergeMap((data: any) => {
				return this.getUploadDoc(data).pipe(
					map((doc) => {
						doc.fileUrl = data.DocumentLink;
						return doc;
					})
				);
			})
		);
	}
}

export interface DeclarationDocument {
	documentID: number;
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	status: number;
	createDateTime: string;
	createdByStaffId: number;
	createdByStaffLevel: number;
	modifiedDateTime: string;
	modifiedByStaffId?: any;
}
