import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'transformLink'
})
export class TransformLinkPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(value: string | unknown[], args?: any) {
    /**
     * Escape greater than and lessthan symbols
     * replace <email@email.com> to &#60;email@email.com&#62; 
     */
    const emailRegex = /\<\b([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\b\>/g;
    const emailEscape = '&#60;$1&#62;';
    /**
     * replace links to html anchor links;
     */
    const regex = /!\[([^\][]*)]\(((?:(?:https?|ftp):\/\/)?(?:\S+(?::\S+)*)?\.(?:[A-Za-z]{2,6})(?:[/?\w\.\-~_ +%:@&;=#]*)(?!\w))\)/g;
    const replaceWith = '<a href="$2" alt="$1" target="_blank" class="tap-text-link">$1</a>';
    if(typeof value === 'string') {
      const escdEmail = value.replace(emailRegex, emailEscape);
      const newValue = escdEmail.replace(regex, replaceWith);
      return this.domSanitizer.bypassSecurityTrustHtml(newValue);
    }
    if(typeof value === 'object') {
      const rawValue = this.domSanitizer.sanitize(SecurityContext.HTML, value);
      const escdEmail = rawValue.replace(emailRegex, emailEscape);
      const newValue = escdEmail.replace(regex, replaceWith);
      if(args === 'string') {
        return newValue;
      }
      return this.domSanitizer.bypassSecurityTrustHtml(newValue);
    }
    return value;
  }
}

