import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TpdStore, TpdState } from './tpd.store';

@Injectable({ providedIn: 'root' })
export class TpdQuery extends QueryEntity<TpdState> {

  constructor(protected store: TpdStore) {
    super(store);
  }

}
