import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { DropdownValue } from '../../../../../../modules/dropdown/model/dropdownValue';
import { DropdownValuesService } from '../../../../../../core/dropdown/dropdown-values.service';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { GpHistoryState } from '../../../../../../shared/models/client-review-template/history/gp.model';
import { util } from '../../../../../../util/util';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { BusinessService } from '@core/business/business.service';
import { ComponentBase } from '@core/base/component-base';

@Component({
  selector: 'app-gp-modal',
  templateUrl: './gp-modal.component.html',
  styleUrls: ['./gp-modal.component.scss'],
})
export class GpModalComponent extends ComponentBase implements OnInit, OnDestroy {
  @Output() saveNew: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  savefn: (model: any) => Observable<any>;
  decline$: Observable<any>;
  header: string;
  message: string;
  secondaryMessage: string;
  professionalTypes: any;
  dropdownCodes: DropdownValue[];
  clients: any;
  submitted: boolean;
  peoples$: Observable<any>;
  gpDetails: GpHistoryState;
  isNew: boolean;
  viewMode: boolean;
	loadingState: boolean;
	cRTId: number;

  public form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private loggerService: LoggerService,
    public bsModalRef: BsModalRef,
    private dropdownService: DropdownValuesService,
		private businessConfig: BusinessService
  ) {
		super();
    this.setupProps();
    this.buildForm();
  }

  ngOnInit() {
    this.prepData();

    this.dropdownService.getDropdownValues('PT').subscribe((data) => {
      this.professionalTypes = data;
      if (this.isNew) {
        this.ProfessionalType.setValue('Doctor');
      }
    });

		super.subscribe(this.businessConfig.loatv2Enabled$, (enabled) => {
			if (enabled) {
				this.submitted = true;
			}
		});
  }

	ngOnDestroy(): void {
		super.dispose();
	}

  get GpName() {
    return this.form.get('gPName');
  }

  get ProfessionalType() {
    return this.form.get('professionalType');
  }

  prepData() {
    if (!this.gpDetails) { return; }

    const tryArr = [];
    const client = util.tryCatchParse(this.gpDetails?.client)
      ? JSON.parse(this.gpDetails?.client)
      : [];
    (client ? client : [])?.forEach((x) => {
      tryArr.push(Number(x));
    });

    const gp = {
      ...this.gpDetails,
      client,
    };
    this.form.reset(gp);
    setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
  }

  buildForm() {
    this.form = this.fb.group({
      customerId: [''],
      gPName: ['', Validators.required],
      businessName: [''],
      professionalType: ['', Validators.required],
      phoneNumber: [''],
      emailAddress: [''],
      physicalAddress: [''],
      client: [''],
    });
  }

  setupProps() {
    this.header = 'Confirm';
    this.message = 'Confirm?';
  }

  save() {
    this.submitted = true;
    if (this.form.invalid) {
      this.loggerService.Warning({}, logMessage.shared.general.warning.required);
      return;
    }
		this.loadingState = true;
    const gpClient = this.form.getRawValue() as GpHistoryState;
    gpClient.sectionCode = 'FMG';

    this.savefn({...gpClient, cRTId: this.cRTId}).subscribe({
      next: () => {
        this.bsModalRef.hide();
        setTimeout(() => this.loadingState = false, 500)
      },
      error: () => {
        this.loadingState = false
      }
    });
  }

  decline() {
    if (!!this.decline$) {
      this.decline$.subscribe();
    }
    this.bsModalRef.hide();
  }

  cancelClick() {
    this.bsModalRef.hide();
    this.cancel.emit(true);
  }
}

export interface ConfirmModalModel {
  decline$?: Observable<any>;
  header: string;
  message: string;
  secondaryMessage: string;
}
