<div class="tap-froala tap-froala--container" [id]="'wysiwyg_'+ editorId">
	<div
		*ngIf="hasSidebarSections && sections?.length > 0"
		class="tap-froala__nav"
	>
		<ul class="list-group list-group-flush">
			<li *ngFor="let s of sections" class="list-group-item">
				<a
					class="clickable"
					href="javascript:void(0);"
					(click)="scrollToHeader('#' + s.id)"
					>{{ s.name }}</a
				>
			</li>
		</ul>
	</div>
	<ng-container *ngIf="!readOnly">
		<div
			[class]="'tap-froala__editor ' + designClass"
			[froalaEditor]="options"
			[(froalaModel)]="content"
		></div>
	</ng-container>
	<ng-container *ngIf="readOnly">
		<div [class]="'tap-froala__editor--view-mode ' + designClass" [class.has-template]="content && content !== '' && content !== '<p></p>'" [froalaView]="content"></div>
		<div
			class="zoom-control d-flex flex-column"
			*ngIf="zoom && content && content !== '' && content !== '<p></p>'"
		>	
			<app-manual-loader-mini
				class="mb-2"
				[isLoading]="isZoomLoading"
			></app-manual-loader-mini>
			<button
				(click)="zoomIn()"
				class="tap-btn tap-btn-outline tap-btn--shadow mb-2 zoom-btn w-auto p-1"
				[disabled]="isZoomLoading || +displayZoomValue === 200"
			>
				<i class="material-icons md-18">add</i>
			</button>
			<button
				(click)="zoomOut()"
				class="tap-btn tap-btn-outline tap-btn--shadow zoom-btn w-auto p-1"
				[disabled]="isZoomLoading || +displayZoomValue === 70"
			>
				<i class="material-icons md-18">remove</i>
			</button>
			<div class="zoom-control__percent">{{ displayZoomValue }}%</div>
		</div>
	</ng-container>
</div>
