import { environment } from "@environment";

export const mergeTagButtons = ['repeatTableRow', 'repeatSection'];

export const button = [
	// uncomment the html button on prod; for dev use only
	// 'html',
	'fullscreen',
	'undo',
	'redo',
	'paragraphFormat',
	'fontFamily',
	'fontSize',
	'bold',
	'italic',
	'underline',
	'textColor',
	'backgroundColor',
	'paragraphStyle',
	'insertLink',
	'insertImage',
	'insertTable',
	'alignLeft',
	'alignCenter',
	'alignRight',
	'alignJustify',
	'lineHeight',
	'formatOL',
	'formatUL',
	'indent',
	'outdent',
	'pageBreak',
	'addFooter',
	'clearFormatting',
	'addBottomLine',
	...mergeTagButtons,
];

// add html button in development mode
if (!environment.production) {
	button.unshift('html');
}

export const buttonMD = [
	'fullscreen',
	'undo',
	'redo',
	'paragraphFormat',
	'fontFamily',
	'fontSize',
	'bold',
	'italic',
	'underline',
	'textColor',
	'backgroundColor',
	'paragraphStyle',
	'insertLink',
	'lineHeight',
	'formatOL',
	'formatUL',
	'addFooter',
	'clearFormatting',
	...mergeTagButtons,
	,
];

export const buttonSM = [
	'fullscreen',
	'undo',
	'redo',
	'paragraphFormat',
	'fontFamily',
	'fontSize',
	'bold',
	'italic',
	'underline',
	'paragraphStyle',
	'formatOL',
	'formatUL',
	'clearFormatting',
	...mergeTagButtons,
];

export const buttonXS = [
	'fullscreen',
	'undo',
	'redo',
	'paragraphFormat',
	'fontFamily',
	'fontSize',
	'bold',
	'italic',
	'underline',
	'paragraphStyle',
	'clearFormatting',
	...mergeTagButtons,
];

export const simpleEditorButtons = [
	'fontFamily',
	'fontSize',
	'bold',
	'italic',
	'underline',
	'textColor',
	'backgroundColor',
	'formatOL',
	'formatUL',
	'insertLink',
	'clearFormatting',
	...mergeTagButtons,
];
