import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { UserQuery } from '@domain/user/user.query';
import { AppFormGroup } from '@util/form-group';
import {
	EmailSignature,
	EmailSignatureService,
} from './state/email-signature.service';
import { finalize, map, mergeMap, of, take } from 'rxjs';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { TLStaffsQuery } from '@domain/tl-staff/tl-staffs.query';

@Component({
	selector: 'app-email-signature',
	templateUrl: './email-signature.component.html',
	styleUrls: ['./email-signature.component.scss'],
})
export class EmailSignatureComponent implements OnInit {
	constructor(
		private userQuery: UserQuery,
		private businessQuery: BusinessConfigQuery,
		private cdr: ChangeDetectorRef,
		private emailSignatureService: EmailSignatureService
	) {}

	formGroup = new AppFormGroup({
		isLogoChecked: new FormControl(true),
		isCellphoneNumberChecked: new FormControl(true),
		isOfficePhoneNumberChecked: new FormControl(true),
		isAddressChecked: new FormControl(true),
		isMailAddressChecked: new FormControl(true),
		mailAddress: new FormControl(''),
		isWebsiteURLChecked: new FormControl(true),
		type: new FormControl('ES'),
		referenceId: new FormControl(0),
		isActive: new FormControl(true),
		settingsId: new FormControl(null),

		previewLogo: new FormControl(null),
		previewPhoneNumber: new FormControl('Cell phone number'),
		previewMobileNumber: new FormControl('Office phone number'),
		previewAddress: new FormControl('address'),
		previewMailAddress: new FormControl('mail address'),
		previewWebsiteURL: new FormControl(null),
		previewFullName: new FormControl('First Last Name'),
	});

	isSaving = false;

	get disableFormControls(): boolean {
		return !this.formGroup.controls.isActive.value;
	}

	get showLogo(): boolean {
		return this.formGroup.controls.isLogoChecked.value;
	}

	get showPhoneNumber(): boolean {
		return this.formGroup.controls.isCellphoneNumberChecked.value;
	}

	get showMobileNumber(): boolean {
		return this.formGroup.controls.isOfficePhoneNumberChecked.value;
	}

	get showAddress(): boolean {
		return this.formGroup.controls.isAddressChecked.value;
	}

	get showMailAddress(): boolean {
		return this.formGroup.controls.isMailAddressChecked.value;
	}

	get showWebsiteURL(): boolean {
		return this.formGroup.controls.isWebsiteURLChecked.value;
	}

	get showPhone(): boolean {
		return (
			!!this.formGroup.controls.previewPhoneNumber.value ||
			!!this.formGroup.controls.previewMobileNumber.value
		);
	}

	get showPhoneSeparator(): boolean {
		return this.showPhoneNumber && this.showMobileNumber && this.hasPhoneAndMobile;
	}

	get mailAddress(): string {
		return this.formGroup.controls.isMailAddressChecked.value;
	}

	get hasPhoneAndMobile(): boolean {
		return (
			Boolean(this.formGroup.value.previewPhoneNumber) &&
			Boolean(this.formGroup.value.previewPhoneNumber)
		);
	}

	get hasPhoneOrMobile(): boolean {
		return (
			Boolean(this.formGroup.value.previewPhoneNumber) ||
			Boolean(this.formGroup.value.previewPhoneNumber)
		);
	}

	ngOnInit(): void {
		this.emailSignatureService
			.getSettings()
			.pipe(
				take(1),
				mergeMap((setting) => {
					return this.emailSignatureService.getStaff().pipe(
						take(1),
						map((staff) => {
							const { Logo, Website, Address1, Address2, City, Postcode, Country } =
								this.businessQuery.getValue()?.config;
							return {
								...setting,
								...{
									previewLogo: Logo,
									previewAddress: [Address1, Address2, City, Country, Postcode]
										.filter((a) => Boolean(a?.trim()))
										.join(', '),
									previewMailAddress: setting.mailAddress,
									previewWebsiteURL: Website,
									previewPhoneNumber: staff.BusinessPhone,
									previewMobileNumber: staff.MobilePhone,
								},
							};
						})
					);
				})
			)
			.subscribe((res) => {
				this.formGroup.patchValue(res);
				this.cdr.detectChanges();
			});
	}

	private getData(): EmailSignature {
		const data = this.formGroup.getRawValue();
		Object.keys(data).forEach((key) => {
			if (key.startsWith('preview')) {
				delete data[key];
			}
		});
		return data;
	}

	save(): void {
		if (this.isSaving) {
			return;
		}
		this.isSaving = true;
		this.emailSignatureService
			.upsert(this.getData())
			.pipe(
				take(1),
				finalize(() => {
					this.isSaving = false;
				})
			)
			.subscribe();
	}
}
