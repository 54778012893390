<header class="d-flex justify-content-center align-items-center">
	<div>
		<ng-container *ngIf="logo">
			<img [src]="logo" alt="Logo" />
		</ng-container>
	</div>
</header>
<div
	class="d-flex justify-content-center align-items-center app-main-container"
>
	<div
		class="d-flex flex-column justify-content-center align-items-center app-center-box w-auto text-center"
	>
		<h1 class="pb-2">Oops, something went wrong</h1>
		<p class="mb-0">
			Sorry, please try refreshing the page or opening it in a another tab.
		</p>
		<p class="mb-4">If issue still persist, please contact admin/support.</p>
		<button
			class="app-button app-refresh-button w-auto px-3 py-1"
			(click)="reload()"
		>
			<span>Refresh</span>
		</button>
	</div>
</div>
