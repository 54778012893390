import {
	Component,
	OnInit,
	EventEmitter,
	Output,
	Input,
	OnDestroy,
	TemplateRef,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	UntypedFormArray,
	Validators,
	AbstractControl,
} from '@angular/forms';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { JsonResultStatus } from '../../../../../../core/base/api.service';
import {
	CurrentInsuranceState,
	LifeAssuredListState,
	AllowDropdowns,
	DefaultNewExistingOption,
	AppendMonths,
	OtherProducts,
} from '../../../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { CurrentInsuranceMapper } from '../../../../../../shared/models/client-review-template/current-insurance/current-insurance.mapper';
import MomentUtil from '../../../../../../util/moment.util';
import { catchError, concatMap,mergeMap, pairwise, take, takeUntil, tap } from 'rxjs/operators';
import { UploadModalComponent } from '../../../../../../shared/modal/upload-modal/upload-modal.component';
import { convertUtil, numUtil, objectUtil } from '../../../../../../util/util';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { LinkDocumentComponent } from '../../../../../../shared/services/link-document/link-document.component';
import { DocumentGroupState } from '../../../../../../shared/models/documents/document-group.model';
import { ClientReviewTemplateQuery } from '../../../../../../modules/crm/client-review-template/states/client-review-template.query';
import {
	DocumentModelState,
	DocumentTypes,
} from '../../../../../../shared/models/documents/document.model';
import { CurrentInsuranceService } from '../../../../../../modules/crm/client-review-template/states/current-insurance/current-insurance.service';
import * as R from 'ramda';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { SecondaryClientState } from '../../../../../models/client-profile/secondary-client/secondary-client.model';
import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import { logMessage, unallowedFileChars } from 'src/app/shared/error-message/error-message';
import { CustomerTypes } from '@shared/models/_general/client.model';
import { BusinessService } from '@core/business/business.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { AiQuoteService } from '@crm/crt-page/_shared/service/ai-quote/ai-quote.service'
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { AiQouteCompany, AiQouteType, AiQuotePayloadDataState, AiQuoteResultState } from '@modules/crm/crt-page/_shared/service/ai-quote/ai-quote.model';
import { ToastService } from '@core/services/toast.service';
import { BusinessConfigService } from '@domain/business-config/business-config.service';
import { datadogRum } from '@datadog/browser-rum';

export interface LifeAssuredViewDisplayValue extends ViewDisplayValue {
	hidden?: boolean;
}

@Component({
	selector: 'app-product-form',
	templateUrl: './product-form.html',
	styleUrls: ['./product-form.scss'],
})
export class ProductFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	private subs = new Subscription();
	@Input() currentInsuranceInfo: CurrentInsuranceState;
	@Input() currentInsuranceLifeAssured: LifeAssuredListState[];
	@Input() uploadDocFn$: ({ doc, type }) => Observable<JsonResultStatus>;
	@Input() linkDocFn$;
	@Input() policyOwners: ViewDisplayValue[];

	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() provider: ViewDisplayValue[];
	@Input() product: ViewDisplayValue[];
	@Input() premiumFrequency: ViewDisplayValue[];
	@Input() benefit: ViewDisplayValue[];
	@Input() lifeAssuredDropdownRaw: ViewDisplayValue[];
	@Input() isSingleUpload: boolean;
	@Input() isProposedInsurance: boolean;
	@Input() isLoading: boolean;
	@Input() newExistingChoices: ViewDisplayValue[];
	@Input() defaultPaymentFrequency: string;
	@Input() defaultNewExistingValue: string;
	@Input() enableLinkDocument: boolean;
	@Input() hideNotes: boolean;
	@Input() restrictFileTypes = '*';
	@Input() allowedFileExtensions: string[];
	@Input() viewMode: boolean;
	@Input() enableAiQuote: boolean;
	@Input() adviceProcessId: number;
	@Input() clientId: number;
	@Input() isAPCAR: boolean;

	@Input() primaryClient: any;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	@Input() isAdviceProcessEnded$: Observable<boolean> | null;

	public bsModalRef: BsModalRef;
	public linkModalRef: BsModalRef;
	public optionModalRef: BsModalRef;
	productValue: string;

	form: UntypedFormGroup;
	submitted: boolean;

	productForm: UntypedFormGroup;
	myFiles: any[] = [];
	documentList = [];

	fileform: FormData = new FormData();
	filelist: any;
	filenames: any[];

	isAddProduct: boolean;
	totalPremium = 0;

	allowDropdownList = AllowDropdowns;
	DEFAULT_NEW_EXISTING_OPTION = DefaultNewExistingOption;
	appendMonthTo = AppendMonths;
	otherProducts = OtherProducts;

	isAddNewPolicy = false;
	isSaving = false;
	isSavingDocument = false;
	hasSelectedDocument = false;
	currentLinkedDocument: any;
	currentLinkedDocumentId: string;
	document: DocumentGroupState;
	documentIsLoading = false;
	transferedSCI: SecondaryClientState[];
	deceasedSCI: ViewDisplayValue[];
	lifeAssuredDropdownList: LifeAssuredViewDisplayValue[];
	insuranceIdsSelectedForPrefill: number[] = [];
	dragIndex: number;

	cancelLoading: boolean;
	enableLoatV2: boolean;
	aiQuoteFeature: boolean;
	
	isAiQuoteProcessing = null;
	toastClass = 'toast-product-container';
	aiQuoteStatus = {
		DEFAULT: 0,
		LOADING: 1,
		SUCCESS: 2,
		FAILED: 3,
		ERROR_PROVIDER: 4,
		ERROR_POLICY: 5
	}
	aiQuoteMessages = {
		failed: {
			status: this.aiQuoteStatus.FAILED,
			buttonTooltip: 'Something went wrong, for now update your details manually',
			buttonLabel: 'AI Failed',
			notifHeader: 'AI Failed',
			notifMessage: 'Opps! Something went wrong. Our AI is still new and in Beta. We are training it every day to help improve accuracy, for now <b>update your details manually</b> while we look at what has gone wrong.'
		},
		success: {
			status: this.aiQuoteStatus.SUCCESS,
			buttonTooltip: null,
			buttonLabel: 'Complete',
			notifHeader: 'Complete',
			notifMessage: 'Your document %DOCNAME% has finished processing. Your quote has been reviewed and the products are detailed above. <b>Please review and ensure all the data is accurate. Our AI is in Beta and learning every day.</b>'
		},
		errorProvider: {
			status: this.aiQuoteStatus.ERROR_PROVIDER,
			buttonTooltip: 'Only one provider per insurance policy',
			buttonLabel: 'Second Provider Error',
			notifHeader: 'Second Provider Error',
			notifMessage: 'We see you are trying to upload information for <b>2 different providers</b>. This cannot be done. To input the second provider please save and close this popup. Then click on "Add a new policy" again.'
		},
		errorPolicy: {
			status: this.aiQuoteStatus.ERROR_POLICY,
			buttonTooltip: 'Only one policy number per insurance policy',
			buttonLabel: 'Second Policy Error',
			notifHeader: 'Second Policy Error',
			notifMessage: 'We see you are trying to upload information for <b>2 different policies</b>. This cannot be done. To input the second policy please save and close this popup. Then click on "Add a new policy" again.'
		}
	};
	aiQuotesStatusArr = Object.entries(this.aiQuoteMessages)?.map(([k, v]) => ({
		...v,
		id: k
	}));
	
	toggleChanges = false;

	get isAiQuoteEnabled(): boolean {
		return this.aiQuoteFeature && this.enableAiQuote;
	}
	get companyCode(): string {
		return this.busConfigService.companyCode();
	}

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private service: CurrentInsuranceService,
		private query: ClientReviewTemplateQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private aiQuoteService: AiQuoteService,
		private crtDocService: CrtDocumentService,
		private toastService: ToastService,
		private businessService: BusinessService,
		private busConfigService: BusinessConfigService
	) {
		this.filenames = [];
		this.fileform = null;
		this.filelist = [];
		this.buildForm();

		this.businessService.loatv2Enabled$.pipe(
			takeUntil(this.onDestroy$)
		).subscribe((isEnabled) => {
			this.enableLoatV2 = isEnabled;
			if (this.enableLoatV2) {
				this.setDefaultFormDetails();
			}
		});
	}

	parse(docs) {
		const mappedDocument = typeof docs === 'string' ? JSON.parse(docs) : docs;
		return !!mappedDocument
			? (mappedDocument?.map(objectUtil.mapPascalCaseToCamelCase) as any[])
			: [];
	}

	ngOnInit() {
		this.businessConfigQuery.aiQuoteFeature$.pipe(
			tap((x) => this.aiQuoteFeature = x),
			take(1)
		).subscribe();

		if (this.isAPCAR) {
			this.setCARRequiredFields();
		}

		combineLatest([
			this.query.allSecondaryClients$,
			this.query.deceasedSciList$,
		])
			.pipe(
				tap(([sci, deceased]) => {
					this.transferedSCI = sci?.filter((s) => s.isActive === 3);
					this.deceasedSCI = deceased;
					this.setLifeAssuredList();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		if (this.defaultPaymentFrequency) {
			this.form.patchValue({ premiumFrequency: this.defaultPaymentFrequency });
		}

		if (this.currentInsuranceInfo) {
			this.prepData();

			const mappedDocument =
				typeof this.currentInsuranceInfo.policyDocuments === 'string'
					? JSON.parse(this.currentInsuranceInfo.policyDocuments)
					: this.currentInsuranceInfo.policyDocuments;

			this.currentInsuranceInfo = {
				...this.currentInsuranceInfo,
				policyDocuments:
					!!mappedDocument && mappedDocument?.length > 0
						? (mappedDocument?.map(
								objectUtil.mapPascalCaseToCamelCase
						  ) as any[])
						: null,
			};
		}

		if (this.benefit && this.benefit.length > 0) {
			const mapBenefit = this.benefit?.map((x) =>
				x.value
					? {
							display: `$ ${x.display?.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								','
							)} excess`,
							value: x.value,
					  }
					: null
			);
			this.benefit = Object.assign([], mapBenefit);
		}

		// TAP1-3821
		// if (this.isAiQuoteEnabled && !this.viewMode) {
			// this.form.get('policyNumber')?.setValidators([Validators.required]);
			// this.form.get('policyNumber')?.updateValueAndValidity();
		// }

		this.datadogObserveChanges();
		this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this.setLifeAssuredList();
			this.toggleChanges = true;
		});
	}

	get incomeArray() {
		return this.form.get('incomeArray') as UntypedFormArray;
	}
	get lifeAssuredList() {
		return this.form.get('lifeAssuredList') as UntypedFormArray;
	}
	get policyOwnerFC() {
		return this.form.get('policyOwner') as UntypedFormArray;
	}
	get providerFC() {
		return this.form.get('provider') as UntypedFormArray;
	}

	datadogObserveChanges() {
		this.lifeAssuredList.valueChanges.pipe(
			pairwise(),
			takeUntil(this.onDestroy$),
		).subscribe(([prev, next]) => {
			if(!this.toggleChanges) {
				return;
			}
			if (R.equals(prev, next) || R.isNil(prev) || R.isEmpty(prev)) {
				return;
			}
			const observeKeys = ['lifeAssured', 'product', 'benefit', 'premium', 'startDate' ];
			next.forEach((item, index) => {
				const prevItem = prev[index];
				if(R.isNil(prevItem)) {
					return;
				}
				const changedKeys = observeKeys.filter(key => {
					const currVal = R.isEmpty(item[key]) || R.isNil(item[key]) ? null : item[key];
					const prevVal = R.isEmpty(prevItem[key]) || R.isNil(prevItem[key]) ? null : prevItem[key];
					return currVal !== prevVal;
				});

				changedKeys.forEach(key => {
					const value = `${key}`;
					datadogRum.addAction('adlib user change', { 
						value, 
						curr: item[key],
						prev: prevItem[key]
					});
				});
			});
		});
	}

	setCARRequiredFields(): void {
		this.policyOwnerFC.setValidators([Validators.required]);
		this.policyOwnerFC.updateValueAndValidity();
		this.providerFC.setValidators([Validators.required]);
		this.providerFC.updateValueAndValidity();
	}

	buildForm() {
		this.form = this.fb.group({
			policyOwner: [''],
			policyNumber: [''],
			provider: [''],
			premiumFrequency: [''],
			additionalNotes: [''],
			policyFee: [''],
			lifeAssuredList: this.fb.array([], [Validators.required]),
			manualReview: [false],
		});
	}

	setDefaultFormDetails(): void {
		combineLatest([
			this.query.people$,
			this.query.peopleEntities$
		]).pipe(
			takeUntil(this.onDestroy$)
		).subscribe(([people, peopleEntities]) => {
			let defaultCustomerID = null;

			let pciCustomer = null;
			if (!R.isNil(people)) {
				pciCustomer = people.find(item => item.customerType === CustomerTypes.PrimaryCustomerIndividual);
			}

			// Finds the primary Customer if any

			let peopleChoices = peopleEntities;

			// if in case there is no primary person on people array to verify the CustomerTypes to be primary
			// and on peopleEntities the primary person exist, it should be filtered out
			if(!R.isNil(pciCustomer)) {
				peopleChoices = peopleChoices.filter(item => +item.value !== pciCustomer.customerId);
			}

			if (pciCustomer) {
				defaultCustomerID = pciCustomer.customerId;
			} else {

				// If there is no "PCI" customer, return the customerId of the first item sorted alphabetically
				const sortedData = [...peopleChoices].sort((a, b) => a.display.localeCompare(b.display));
				defaultCustomerID = sortedData.length > 0 ? sortedData[0].value : null;
			}

			if (!R.isNil(defaultCustomerID)) {
				this.form.patchValue({
					policyOwner: [defaultCustomerID]
				});
			}

			setTimeout(() => {
				if(this.lifeAssuredList.length === 0) {
					if(!!this.currentInsuranceLifeAssured) {
						this.addNew();
					} else {
						this.addProduct();
					}
				}
			}, 100);
		});
	}

	prepData() {
		if (!!this.currentInsuranceInfo) {
			this.form.reset({
				...CurrentInsuranceMapper.mapToView(this.currentInsuranceInfo),
				manualReview: false
			});
		}
		this.setStringControlArray(
			!!this.currentInsuranceInfo.lifeAssuredList
				? this.currentInsuranceInfo.lifeAssuredList
				: !!this.currentInsuranceInfo.lifeAssured
				? typeof this.currentInsuranceInfo.lifeAssured === 'string'
					? ((
							JSON.parse(
								this.currentInsuranceInfo.lifeAssured
							) as LifeAssuredListState[]
					  )?.map(
							objectUtil.mapPascalCaseToCamelCase
					  ) as LifeAssuredListState[])
					: this.currentInsuranceInfo.lifeAssured
				: [],
			'lifeAssuredList'
		);
		this.lifeAssuredList.controls?.forEach((control: AbstractControl) => {
			control
				.get('lifeAssuredDisplay')
				.setValue(
					this.getLifeAssuredDisplay(
						control.get('lifeAssured').value?.toString()
					)
				);
		});
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	}

	setLifeAssuredList() {
		const transferred = this.transferedSCI
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							`${y.firstName} ${y.lastName}`
						),
					this.transferedSCI
			  )?.map((x) => ({ ...x, hidden: true }))
			: [];
		const deceased = this.deceasedSCI?.map((x) => ({ ...x, hidden: true }));
		const lifeAssured = this.lifeAssuredDropdownRaw?.filter(
			(l) => !this.deceasedSCI?.find((x) => +x?.value === +l?.value)
		);

		this.lifeAssuredDropdownList = R.uniqBy(R.prop('value'), [
			...transferred,
			...deceased,
			...lifeAssured,
		])?.sort((a, b) => a.display?.localeCompare(b.display));
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.policyOwners).map((x) => ({
			...x,
			value: +x.value,
		}));
	}

	lifeAssuredChoices(owners: string | number) {
		return this.getOwnerChoices([owners], this.lifeAssuredDropdownRaw)?.map(
			(x) => ({ ...x, value: +x.value })
		);
	}

	setStringControlArray(array, controlArrayName: string = '') {
		const controls =
			array === null ? null : array?.map((x) => this.fb.control(x));
		const formArr = this.form.controls[controlArrayName] as UntypedFormArray;

		while (formArr.length !== 0) {
			formArr.removeAt(0);
		}

		if (controls !== null) {
			controls?.forEach((x, i) => {
				(this.form.controls[controlArrayName] as UntypedFormArray).push(
					this.fb.group({
						name: x.value.name,
						lifeAssured: +x.value.lifeAssured,
						lifeAssuredDisplay: this.getLifeAssuredDisplay(
							x.value.lifeAssured?.toString()
						),
						product: x.value.product,
						benefit: +x.value.benefit,
						premium: +x.value.premium,
						startDate: x.value.startDate
							? MomentUtil.formatDateToMoment(x.value.startDate)
							: null,
						newExisting: x.value.newExisting,
						notes: x.value.notes,
						btnAdd: false,
						showDropdown: this.allowDropdown(x.value.product),
						id: i,
						priority: x.priority,
					})
				);
			});
		}
	}

	allowDropdown = (product) =>
		product ? this.allowDropdownList?.includes(product) : false;

	addNew() {
		this.isAddProduct = this.totalUnselectedInsurance > 0 ? true : false;
		if (!this.isAddProduct) {
			this.addProduct();
		}
	}

	get totalUnselectedInsurance() {
		let unselectedLifeAssured = [];
		if (
			!!this.currentInsuranceLifeAssured &&
			this.currentInsuranceLifeAssured.length > 0
		) {
			unselectedLifeAssured = this.viewUnselectedLifeAssured(
				this.currentInsuranceLifeAssured
			);
		}

		return unselectedLifeAssured.length;
	}

	addProduct(data?: LifeAssuredListState) {
		const latestPerson =
			this.lifeAssuredList.length > 0
				? this.lifeAssuredList
						.at(this.lifeAssuredList.length - 1)
						?.get('lifeAssured').value
				: this.lifeAssuredDropdownRaw[0]?.value || '';
		const lifeAssured = this.totalUnselectedInsurance < 1 ? latestPerson : '';
		this.lifeAssuredList.push(
			this.fb.group({
				...(!!data
					? {
							lifeAssured: [data?.lifeAssured, Validators.required],
							lifeAssuredDisplay: [
								this.getLifeAssuredDisplay(data?.lifeAssured?.toString()),
							],
							product: [data?.product],
							benefit: [data?.benefit],
							premium: [null],
							startDate: [
								data?.startDate
									? MomentUtil.formatDateToMoment(data?.startDate)
									: '',
							],
							notes: [data?.notes],
							newExisting: [data?.newExisting],
							id: [data?.id],
							priority: [
								R.isNil(data?.priority)
									? this.lifeAssuredList.length
									: data?.priority,
							],
					  }
					: {
							lifeAssured: [lifeAssured, Validators.required],
							lifeAssuredDisplay: [''],
							product: [this.getProductType()],
							benefit: [''],
							premium: [''],
							startDate: [this.getProductStartDate()],
							notes: [''],
							newExisting: [
								this.defaultNewExistingValue ||
									this.DEFAULT_NEW_EXISTING_OPTION,
							],
							id: [this.lifeAssuredList.length],
							priority: [this.lifeAssuredList.length],
					  }),
				btnAdd: false,
				showDropdown: !!data ? this.allowDropdown(data.product) : false,
			})
		);
	}

	getProductType(): string {
		let product = '';

		if (this.enableLoatV2) {
			if (this.lifeAssuredList.length === 0) {
				product = 'Life'
			}

			if (this.lifeAssuredList.length !== 0 &&
				!R.isEmpty(this.lifeAssuredList.value) &&
				!R.isNil(this.lifeAssuredList.value[this.lifeAssuredList.length - 1])) {
				product = this.lifeAssuredList.value[this.lifeAssuredList.length - 1].product;
			}
		}

		return product;
	}

	getProductStartDate(): string {
		let startDate = '';

		if (this.enableLoatV2) {
			if (this.lifeAssuredList.length !== 0 &&
				!R.isEmpty(this.lifeAssuredList.value) &&
				!R.isNil(this.lifeAssuredList.value[this.lifeAssuredList.length - 1])) {
				startDate = this.lifeAssuredList.value[this.lifeAssuredList.length - 1].startDate;
			}
		}

		return startDate;
	}

	canelAddProduct() {
		this.isAddProduct = false;
	}

	deleteProduct(index) {
		this.lifeAssuredList.removeAt(index);
	}

	hasDeleteBtn() {
		return this.form.getRawValue()?.lifeAssuredList?.length > 1;
	}

	upload(req) {
		return this.uploadDocFn$({ doc: req, type: DocumentTypes.Upload });
	}

	openUploadModal() {
		const type = DocumentTypes.Upload;
		const upload = (req) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(mergeMap((x) => this.upload(req)));
		const initState = {
			upload,
			data: { type },
			allowedFileExtensions: this.allowedFileExtensions
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	prepareFormvalue() {
		const data = this.form.getRawValue() as CurrentInsuranceState;
		return {
			...data,
			lifeAssuredList: data.lifeAssuredList
				?.filter((x) => !!x.lifeAssured)
				?.sort((a, b) => a.priority - b.priority),
		} as CurrentInsuranceState;
	}

	saveClick() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		this.updatePriorityOrder();
		const formValue = this.form.getRawValue();
		if (
			this.form.invalid ||
			(this.isAiQuoteEnabled &&
				this.isAiQuoteProcessing !== null &&
				!formValue?.manualReview)
		) {
			if (formValue?.lifeAssuredList?.length === 0) {
				this.loggerService.Warning(
					null,
					logMessage.oat.shared.insurance.warning.minimum
				);
				return;
			}
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}

		const data = CurrentInsuranceMapper.mapToUpsert(
			{
				...this.form.getRawValue(),
				totalPremium: +this.getSumOfLifeAssuredList(),
				additionalNotes: this.hideNotes
					? ''
					: this.form.getRawValue()?.additionalNotes || '',
			},
			this.currentInsuranceInfo,
			this.isAiQuoteEnabled
		);
		data.policyDocumentFormData = this.fileform;
		data.policyDocumentsName = this.filenames?.map((x) => x?.value);

		this.saveEvent.emit(data);
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	chooseFile(event) {
		const limitSize = 25 * 1024;
		if (
			(Array.from(event.target.files) as File[])?.some(
				(x) => Math.round(x.size / 1024) > limitSize
			)
		) {
			this?.optionModalRef?.hide();
			return;
		}

		const fileform: FormData = new FormData();
		const fileList: FileList = event.target.files;

		if (this.allowedFileExtensions?.length > 0) {
			const hasInvalidFile = (Array.from(fileList) as File[])?.some((x) => {
				const ext = x?.name?.split('.')?.reverse()?.[0];
				const allowedExtension = this.allowedFileExtensions?.map((x) => x?.toLowerCase());
				const extension = ext?.toLowerCase();
				return !allowedExtension?.includes(extension);
			});
			if (hasInvalidFile) {
				this.loggerService.Warning(
					{},
					logMessage.shared.fileInvalidExtension.error
				);
				this?.optionModalRef?.hide();
				return;
			}
		}

		if (
			Array.from(fileList)?.some((f: any) =>
				unallowedFileChars.some((x) => f.name?.includes(x))
			)
		) {
			this.loggerService.Warning({}, logMessage.shared.fileName.error);
			return;
		}

		const files = [];

		if (this.fileform) {
			this.fileform.forEach((file: any) => {
				files.push(file.name);
			});
		}

		if (fileList.length > 0) {
			Array.from(fileList)?.forEach((f) => {
				if (this.isSingleUpload) {
					fileform.append(f.name, f, f.name);
				} else {
					if (this.fileform && !files.includes(f.name)) {
						this.fileform.append(f.name, f, f.name);
					} else {
						if (!files.includes(f.name)) {
							this.fileform = new FormData();
							this.fileform.append(f.name, f, f.name);
						}
					}
				}
			});

			if (this.isSingleUpload) {
				this.fileform = fileform;
			}

			if (fileList.length > 1) {
				Array.from(fileList).forEach((f) => {
					this.filelist.push([f]);
				});
			} else {
				this.filelist.push(fileList);
			}
			Array.from(fileList).map((x, i) => this.filenames.push({
				referenceId: fileList?.length + 1,
				value: x.name || ''
			}));
		}
		this?.optionModalRef?.hide();
	}

	removeExistingDocument(index: number) {
		this.currentInsuranceInfo.policyDocuments?.splice(index, 1);
	}

	removeDocument(index: number) {
		const file = this.filelist[index][0];
		const files = [];

		this.fileform.delete(file.name);
		this.filenames.splice(index, 1);
		this.filelist.splice(index, 1);

		if (this.fileform) {
			this.fileform.forEach((f: any) => {
				files.push(f.name);
			});
		}

		if (this.isSingleUpload) {
			this.fileform = new FormData();
		} else {
			if (this.filelist.length) {
				const fileList: FileList = this.filelist;
				Array.from(fileList).forEach((f) => {
					if (this.fileform && !files.includes(f[0].name)) {
						this.fileform.append(f[0].name, f[0], f[0].name);
					} else {
						if (!files.includes(f[0].name)) {
							this.fileform = new FormData();
							this.fileform.append(f[0].name, f[0], f[0].name);
						}
					}
				});
			} else {
				this.fileform = new FormData();
			}
		}
	}

	changeDropdown(i) {
		const list = this.form.controls.lifeAssuredList as UntypedFormArray;
		if (this.otherProducts?.includes(list.controls[i].value.product)) {
			const a = {
				...list.controls[i].value,
				premium: 'N/A',
			};
			list.controls[i].patchValue(a);
		} else {
			const a = {
				...list.controls[i].value,
				premium: null,
			};
			list.controls[i].patchValue(a);
		}
		if (this.allowDropdownList?.includes(list.controls[i].value.product)) {
			const a = {
				...list.controls[i].value,
				showDropdown: true,
			};
			list.controls[i].patchValue(a);
		} else {
			const a = {
				...list.controls[i].value,
				showDropdown: false,
			};
			list.controls[i].patchValue(a);
		}
	}

	isUploadDocumentButton(isSingleUpload, docs, files) {
		if (!isSingleUpload) {
			return true;
		} else {
			return R.sum([(docs ?? []).length, (files ?? []).length]) < 1;
		}
	}

	selectCurrentIsurance(value) {
		if (!value || value === '') {
			return;
		}
		if (!!value && value !== 'AddNew') {
			const data =
				this.currentInsuranceLifeAssured?.find(
					(x) =>
						`${x.id}-${x.lifeAssured}-${x.product}-${x.benefit}` === `${value}`
				) ?? null;
			this.addProduct({
				...data,
				newExisting: this.DEFAULT_NEW_EXISTING_OPTION,
				notes: '',
			});
			this.insuranceIdsSelectedForPrefill.push(data.id);
		} else if (value === 'AddNew') {
			this.addProduct();
		}
		this.isAddProduct = false;
	}

	viewLifeAssured(id) {
		const transferedSCI =
			this.transferedSCI?.map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName.concat(' ', x.lastName)}`
				)
			) || [];
		const list = [...transferedSCI, ...(this.lifeAssuredDropdownRaw || [])];
		return list?.find((x) => +x.value === +id)?.display ?? '';
	}

	viewUnselectedLifeAssured(lifeAssuredlist: LifeAssuredListState[]) {
		const selectedLifeAssured = R.filter(
			(x) => !!x?.lifeAssured,
			(this.lifeAssuredList.getRawValue() ?? []) as LifeAssuredListState[]
		);
		return lifeAssuredlist?.filter(
			(x) =>
				!selectedLifeAssured?.find((s) => {
					if (x.id === s.id) {
						if (
							x.lifeAssured === s.lifeAssured &&
							x.product === s.product &&
							+x.benefit === +s.benefit
						) {
							return true;
						} else {
							if (this.insuranceIdsSelectedForPrefill?.includes(s.id)) {
								return true;
							} else {
								return false;
							}
						}
					} else {
						return false;
					}
				})
		);
	}

	getSumOfLifeAssuredList() {
		const lifeAssured = this.form.getRawValue();
		const lifeAssuredTotal = (
			lifeAssured?.lifeAssuredList?.reduce(
				(a, b) => Number(a) + Number(b.premium),
				0
			) + +lifeAssured.policyFee
		);
		return  numUtil.formatToNum2Decimal(lifeAssuredTotal);
	}

	openUploadOptionModal(template: TemplateRef<any>) {
		this.optionModalRef = this.modalService.show(template, {
			class: 'modal-dialog-centered',
			initialState: {
				insurance: this.currentInsuranceInfo,
			},
			ignoreBackdropClick: true,
		});
	}

	linkDocument() {
		const primaryClient = this.primaryClient ?? this.query.getValue().primaryClient;
		this.service
			.getClientDocuments(primaryClient.customerID)
			.pipe(take(1))
			.subscribe((docs) => {
				const initState = {
					selectedDetail: 'Link Document',
					document: docs,
					initialSelectedTab: ServicesCodes.LR.toLowerCase(),
					tabs: [ServicesCodes.LR.toString()],
					allowedFileExtensions: this.allowedFileExtensions
				};
				this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
					class: 'modal-dialog-centered modal-lg',
					initialState: initState,
					ignoreBackdropClick: true,
				});

				this.linkModalRef.content.getSelectedDocumentValue$
					.pipe(
						tap((doc: DocumentModelState) => {
							const policyDocs = this.currentInsuranceInfo?.policyDocuments
								? [...this.currentInsuranceInfo.policyDocuments]
								: [];
							policyDocs.push({ referenceId: doc?.id, value: doc?.fileName });
							if (this.currentInsuranceInfo) {
								this.currentInsuranceInfo.policyDocuments = policyDocs;
								this.currentInsuranceInfo.policyDocumentsList = policyDocs;
							} else {
								this.currentInsuranceInfo = {
									...this.currentInsuranceInfo,
									policyDocuments: policyDocs,
									policyDocumentsList: policyDocs,
								};
							}
						}),
						takeUntil(this.onDestroy$)
					)
					.subscribe();
			});
	}

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			// if transfer, recalculate the order of previous (the list from drag)
			event.previousContainer.data?.forEach((x, index) => {
				x.order = index;
			});
		}
		// always, recalculate the order of the container (the list to drag)
		event.container.data?.forEach((x, index) => {
			this.lifeAssuredList.controls[index].get('priority').setValue(index);
			x.order = index;
		});
	}

	getLifeAssuredDisplay(id: string) {
		return this.lifeAssuredDropdownList?.find(
			(lifeAssured: LifeAssuredViewDisplayValue) => +lifeAssured.value === +id
		)?.display;
	}

	handleLifeAssuredChange(index: number) {
		const selectedLifeAssured =
			this.lifeAssuredList.controls[index].get('lifeAssured').value;
		this.lifeAssuredList.controls[index]
			.get('lifeAssuredDisplay')
			.setValue(this.getLifeAssuredDisplay(selectedLifeAssured));
	}

	updatePriorityOrder() {
		this.lifeAssuredList.controls?.forEach(
			(control: AbstractControl, index: number) => {
				control.get('priority').setValue(index);
			}
		);
	}

	dragStarted(item: AbstractControl) {
		this.dragIndex = +item.get('id').value;
	}

	dragEnd() {
		this.dragIndex = null;
	}

	showMonth(value: string) {
		return this.appendMonthTo?.includes(value);
	}

	processAiQuote(doc: any, index: number, isExisting?: boolean) {
		if (!!doc?.aiQuoteStatus) {
			return;
		}
		this.isAiProcessing(true);
		const lifeAssuredList = this.lifeAssuredDropdownList
			?.map((x) => x?.display)
			?.join(', ');
		const providersList = this.provider?.map((x) => x?.display)?.join(', ');
		const productsList = this.product?.map((x) => x?.display)?.join(', ');
		const filename = doc?.value || '';
		const data = {
			type: AiQouteType,
			companyData: {
				name: AiQouteCompany,
				data: {
					adviceProcessId: this.adviceProcessId,
					fileName: filename,
					companyCode: this.companyCode,
					clientId: this.clientId,
					lifeAssuredList,
					providersList,
					productsList,
				},
			},
		} as AiQuotePayloadDataState;

		if (isExisting) {
			this.updateAiQuoteStatus(this.aiQuoteStatus.LOADING, index, true);
			this.subs.add(
				this.crtDocService
					.getDocument(doc?.referenceId)
					.pipe(
						concatMap((x) =>
							this.crtDocService
								.getDocumentLink(x?.DocumentLink, {
									responseType: 'blob',
								})
								.pipe(
									catchError((x) => {
										this.updateAiQuoteStatus(
											this.aiQuoteStatus.FAILED,
											index,
											true
										);
										this.errorToast();
										return of(x);
									})
								)
						),
						mergeMap((x) => convertUtil.simpleConvertToBase64(x)),
						concatMap((file: string) => {
							const fileStr = file
								? `${((file as string) ?? '')?.replace(/^data:(.*,)?/, '')}`
								: '';
							return this.aiQuoteService.processAiQuotePdf({
								payload: data,
								file: fileStr,
							});
						}),
						take(1)
					)
					.subscribe(
						(res) => {
							if (res?.success) {
								this.applyAiQuoteResult(res, filename, index, true);
							} else {
								this.isAiProcessing(false);
								this.updateAiQuoteStatus(
									this.aiQuoteStatus.FAILED,
									index,
									true
								);
								this.errorToast();
							}
						},
						(err) => {
							this.isAiProcessing(false);
							this.updateAiQuoteStatus(this.aiQuoteStatus.FAILED, index, true);
							this.errorToast();
						}
					)
			);
		} else {
			this.updateAiQuoteStatus(this.aiQuoteStatus.LOADING, index);
			this.subs.add(
				of(this.filelist?.[index]?.[0])
					.pipe(
						mergeMap((x) => convertUtil.simpleConvertToBase64(x)),
						concatMap((file: string) => {
							const fileStr = file
								? `${((file as string) ?? '')?.replace(/^data:(.*,)?/, '')}`
								: '';
							return this.aiQuoteService.processAiQuotePdf({
								payload: data,
								file: fileStr,
							});
						}),
						take(1)
					)
					.subscribe(
						(res) => {
							if (res?.success) {
								this.applyAiQuoteResult(res, filename, index);
							} else {
								this.isAiProcessing(false);
								this.updateAiQuoteStatus(this.aiQuoteStatus.FAILED, index);
								this.errorToast();
							}
						},
						(err) => {
							this.isAiProcessing(false);
							this.updateAiQuoteStatus(this.aiQuoteStatus.FAILED, index);
							this.errorToast();
						}
					)
			);
		}
	}

	applyAiQuoteResult(
		result: AiQuoteResultState,
		filename: string,
		index: number,
		isExist?: boolean
	) {
		if (result?.success) {
			const formValue = this.form.getRawValue();
			const resValue = CurrentInsuranceMapper.mapProcessAiToView(
				result,
				this.lifeAssuredDropdownList,
				this.product,
				formValue
			);
			const applyLA = (data) => {
				data?.lifeAssuredList?.forEach((x) => {
					(this.form.controls['lifeAssuredList'] as UntypedFormArray)?.push(
						this.fb.group({
							...x,
							lifeAssured: [x?.lifeAssured, [Validators.required]],
							product: [x?.product, [Validators.required]],
							showDropdown: this.allowDropdown(x?.product),
						})
					);
				});
				this.successToast(filename);
				this.updateAiQuoteStatus(this.aiQuoteStatus.SUCCESS, index, isExist);
			};

			if (!formValue?.provider && !formValue?.policyNumber) {
				// No Provider and Policy Number inputted
				const resProvider = this.provider.find(x => x.display.toLowerCase() === resValue?.provider.toLowerCase());
				this.form.patchValue({
					provider: resProvider?.display,
					policyNumber: resValue?.policyNumber
				});
				applyLA(resValue);
			} else {
				if (formValue?.provider !== resValue?.provider) {
					this.errorToast(this.aiQuoteStatus.ERROR_PROVIDER);
					this.updateAiQuoteStatus(
						this.aiQuoteStatus.ERROR_PROVIDER,
						index,
						isExist
					);
				} else if (formValue?.policyNumber !== resValue?.policyNumber) {
					this.errorToast(this.aiQuoteStatus.ERROR_POLICY);
					this.updateAiQuoteStatus(
						this.aiQuoteStatus.ERROR_POLICY,
						index,
						isExist
					);
				} else {
					// If Policy Number and Provider are equal
					applyLA(resValue);
				}
			}

			datadogRum.addAction('adlib response status', {
				value: 'ok',
				items: result?.data['Quoted Products']?.length
			});
			
		} else {
			// Generic Failed Error
			this.errorToast();
			this.updateAiQuoteStatus(this.aiQuoteStatus.FAILED, index, isExist);
		}
		this.isAiProcessing(false);
	}

	successToast(fileName: string) {
		const message = this.aiQuoteMessages.success.notifMessage?.replace(
			'%DOCNAME%',
			fileName
		);
		this.toastService.Success({
			message,
			header: this.aiQuoteMessages.success.notifHeader,
			allowHtmlMessage: true,
			containerClass: this.toastClass,
		});
	}

	errorToast(status?: number) {
		let err: any = this.aiQuotesStatusArr?.find((x) => +x?.status === +status);
		if (!status || !err) {
			err = this.aiQuoteMessages?.failed;
		}
		this.toastService.Error({
			header: err.notifHeader,
			message: err.notifMessage,
			allowHtmlMessage: true,
			containerClass: this.toastClass,
		});
	}

	updateAiQuoteStatus(status: number, index: number, isExisting?: boolean) {
		if([
				this.aiQuoteStatus.FAILED, 
				this.aiQuoteStatus.ERROR_POLICY, 
				this.aiQuoteStatus.ERROR_PROVIDER
			].includes(status)) {
			datadogRum.addAction('adlib response status', {
				value: 'fail',
				error: Object.keys(this.aiQuoteStatus).find(key => this.aiQuoteStatus[key] === status)
			});
		}
		if (isExisting) {
			this.currentInsuranceInfo.policyDocuments =
				this.currentInsuranceInfo?.policyDocuments?.map((x, i) => {
					if (i === index) {
						return {
							...x,
							aiQuoteStatus: status,
						};
					}
					return x;
				});
		} else {
			this.filenames = this.filenames?.map((x, i) => {
				if (i === index) {
					return {
						...x,
						aiQuoteStatus: status,
					};
				}
				return x;
			});
		}
	}

	getTooltip(status: number) {
		switch (status) {
			case this.aiQuoteStatus.SUCCESS:
				return this.aiQuoteMessages.success.buttonTooltip;
				break;
			case this.aiQuoteStatus.FAILED:
				return this.aiQuoteMessages.failed.buttonTooltip;
				break;
			case this.aiQuoteStatus.ERROR_POLICY:
				return this.aiQuoteMessages.errorPolicy.buttonTooltip;
				break;
			case this.aiQuoteStatus.ERROR_PROVIDER:
				return this.aiQuoteMessages.errorProvider.buttonTooltip;
				break;
			default:
				return null;
				break;
		}
	}

	getButtonLabel(status: number) {
		switch (status) {
			case this.aiQuoteStatus.LOADING:
				return 'Processing';
				break;
			case this.aiQuoteStatus.SUCCESS:
				return this.aiQuoteMessages.success.buttonLabel;
				break;
			case this.aiQuoteStatus.FAILED:
				return this.aiQuoteMessages.failed.buttonLabel;
				break;
			case this.aiQuoteStatus.ERROR_POLICY:
				return this.aiQuoteMessages.errorPolicy.buttonLabel;
				break;
			case this.aiQuoteStatus.ERROR_PROVIDER:
				return this.aiQuoteMessages.errorProvider.buttonLabel;
				break;
			default:
				return 'Process with AI';
				break;
		}
	}

	isAiProcessing(enable: boolean) {
		this.isAiQuoteProcessing = enable;
		if (enable) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	cancelAiQuoteProcess() {
		this.subs.unsubscribe();
		if (!!this.currentInsuranceInfo?.policyDocuments) {
			this.currentInsuranceInfo.policyDocuments =
				this.currentInsuranceInfo?.policyDocuments?.map((x) => {
					if (x?.aiQuoteStatus === this.aiQuoteStatus.LOADING) {
						return {
							...x,
							aiQuoteStatus: this.aiQuoteStatus.DEFAULT,
						};
					}
					return x;
				});
		}
		this.filenames = this.filenames?.map((x) => {
			if (x?.aiQuoteStatus === this.aiQuoteStatus.LOADING) {
				return {
					...x,
					aiQuoteStatus: this.aiQuoteStatus.DEFAULT,
				};
			}
			return x;
		});
		setTimeout(() => {
			this.subs = new Subscription();
			this.isAiProcessing(false);
		}, 100);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
		this.toggleChanges = false;
	}
}
