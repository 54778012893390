import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import * as R from 'ramda';
import { computeUtil, util } from '../../../../util/util';
import { TableColumn } from '@swimlane/ngx-datatable';
import produce from 'immer';
import { Mortgage } from './mortgage-request.model';
import { Row } from './states/mortgage.model';
import { datatableUtil } from 'src/app/util/datatable.util';

export type Metakey =
	| 'Name'
	| 'Adviser'
	| 'Mortgage Adviser'
	| 'Contact Status'
	| 'Borrowing Entities'
	| 'Provider'
	| 'Status'
	| 'Interest Rate'
	| 'Fixed Period End Date'
	| 'Security'
	| 'Loan Number'
	| 'Loan Value'
	| 'Loan Drawdown'
	| 'Mortgage Type'
	| 'Business Mortgage'
	| 'LVR'
	| 'Fixed Period End'
	| 'Loan Term'
	| 'Loan Repayment'
	| 'Repayment Frequency'
	| 'Business Refix'
	| 'Submitted'
	| 'Approved'
	| 'Expected Settlement'
	| 'Loan Type'
	| 'Expiry Date'
	| 'Required'
	| 'Client Next Activity'
	| 'User Next Activity'
	| 'Mortgage Status Clock'
	| 'Last Note'
	| 'Additional Contacts'
	| 'Lead Origin'
	| 'Original Adviser'
  | 'Double Sub';

/** possible control types. Move this somewhere more appropriate. */
export type controlType =
	| 'display'
	| 'long-display'
	| 'textbox'
	| 'dropdown'
	| 'note'
	| 'date'
	| 'address'
	| 'activity'
	| 'multiselect'
	| 'textarea'
	| 'money'
	| 'checkbox';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
export type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof Row;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (
		field: FieldMetadata<any>,
		choices?: ViewDisplayValue[],
		row?: Row
	) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
	isTwoDecimal?: boolean;
	columnId?: string;
	fieldId?: string;
};

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return R.propOr<string, ViewDisplayValue, string>(
		'',
		'display' as keyof FieldMetadata<any>,
		choiceItem
	);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceIndex = choices?.findIndex((x) => x.value === field.value);
	return choiceIndex > -1 ? choiceIndex : undefined;
};
/** Get multi value display */
const getMultiselectValues = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value || field.value === '[]' || field.value.length === 0) {
		return '';
	}
	const values: string[] =
		typeof field.value === 'string'
			? Array.isArray(JSON.parse(field.value))
				? JSON.parse(field.value)
				: []
			: [];
	return values
		?.map((v) =>
			R.propOr(
				'',
				'display',
				choices?.find((c) => `${c.value}` === `${v}`)
			)
		)
		?.filter((x) => x)
		?.join(', ');
};

const statusClock = (field: FieldMetadata<any>) => {
	const arr = field.value?.split(' ');
	const timePeriod = arr?.pop();
	return timePeriod === 'year' || timePeriod === 'years'
		? +(arr[0] * 365)
		: R.complement(R.isEmpty)(field.value)
		? +arr[0]
		: '';
};

const contactStatus = (value: string) => {
	switch (value) {
		case 'L':
			return 'Lead';
		case 'C':
			return 'Client';
		case 'X':
			return 'Ex-Client';
	}
};
/** get sort value for checkboxes */
const getCheckboxSortValue = (field: FieldMetadata<any>) => {
	const isTrue: boolean =
		field.value === true ||
		(typeof field.value === typeof '' && field.value?.toLowerCase() === 'true');
	return R.defaultTo('', isTrue ? '1' : '2');
};
/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
	const b = R.defaultTo('', date).split(/\D/);
	return b?.reverse().join('/');
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
	const splicedDate = R.defaultTo('', note)?.slice(0, 10);
	return reformatDateString(splicedDate);
};

// Header
export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'Name',
		prop: 'Name',
		name: 'Client Name',
		width: 200,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold fixed-column',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Name'),
		fieldId: datatableUtil.formatFieldId('Name')
	},
	{
		metakey: 'Adviser',
		prop: 'Adviser',
		name: 'Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser')
	},
	// Adviser Rework	
	{
		metakey: 'Mortgage Adviser',
		prop: 'MortgageAdviser',
		name: 'Mortgage Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Mortgage Adviser'),
		fieldId: datatableUtil.formatFieldId('Mortgage Adviser'),
	},
	{
		metakey: 'Contact Status',
		prop: 'ContactStatus',
		name: 'Contact Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Contact Status'),
		fieldId: datatableUtil.formatFieldId('Contact Status')
	},
	{
		metakey: 'Borrowing Entities',
		prop: 'BorrowingEntities',
		name: 'Borrowing Entities',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c, r) => {
			const isEmpty = util.isNullOrEmpty(f.value);
			const noChoices = util.isNullOrEmpty(r.BorrowingEntitiesList);
			if (isEmpty || noChoices) {
				return undefined;
			}

			const customChoices = R.uniq(r.BorrowingEntitiesList?.map((po) =>
				ViewDisplayValue.Map(po.Id?.toString(), po.Name)
			));
			return getMultiselectValues(customChoices, f);
		},
		controlType: 'multiselect',
		columnId: datatableUtil.formatColumnId('Borrowing Entities'),
		fieldId: datatableUtil.formatFieldId('Borrowing Entities')
	},
	{
		metakey: 'Provider',
		prop: 'Provider',
		name: 'Provider',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Provider'),
		fieldId: datatableUtil.formatFieldId('Provider')
	},
	{
		metakey: 'Status',
		prop: 'Status',
		name: 'Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Status'),
		fieldId: datatableUtil.formatFieldId('Status')
	},
	{
		metakey: 'Interest Rate',
		prop: 'InterestRate',
		name: 'Interest Rate',
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isTwoDecimal: true,
		columnId: datatableUtil.formatColumnId('Interest Rate'),
		fieldId: datatableUtil.formatFieldId('Interest Rate')
	},
	{
		metakey: 'Fixed Period End Date',
		prop: 'FixedPeriodEndDate',
		name: 'Fixed Period End',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Fixed Period End Date'),
		fieldId: datatableUtil.formatFieldId('Fixed Period End Date')
	},
	{
		metakey: 'Security',
		prop: 'Security',
		name: 'Security',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c, r) => {
			const isEmpty = util.isNullOrEmpty(f.value);
			const noChoices = util.isNullOrEmpty(r.SecuritiesList);
			if (isEmpty || noChoices) {
				return undefined;
			}

			const customChoices = r.SecuritiesList?.map((po) =>
				ViewDisplayValue.Map(po.Id?.toString(), po.Name)
			);
			return getMultiselectValues(customChoices, f);
		},
		controlType: 'multiselect',
		columnId: datatableUtil.formatColumnId('Security'),
		fieldId: datatableUtil.formatFieldId('Security')
	},
	{
		metakey: 'Loan Number',
		prop: 'LoanNumber',
		name: 'Loan Number',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Loan Number'),
		fieldId: datatableUtil.formatFieldId('Loan Number')
	},
	{
		metakey: 'Loan Value',
		prop: 'LoanValue',
		name: 'Loan Value',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		isTwoDecimal: true,
		columnId: datatableUtil.formatColumnId('Loan Value'),
		fieldId: datatableUtil.formatFieldId('Loan Value')
	},
	{
		metakey: 'Loan Drawdown',
		prop: 'LoanDrawdown',
		name: 'Loan Drawdown',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Loan Drawdown'),
		fieldId: datatableUtil.formatFieldId('Loan Drawdown')
	},
	{
		metakey: 'Mortgage Type',
		prop: 'MortgageType',
		name: 'Mortgage Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Mortgage Type'),
		fieldId: datatableUtil.formatFieldId('Mortgage Type')
	},
	{
		metakey: 'Business Mortgage',
		prop: 'BusinessMortgage',
		name: 'Business Mortgage',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getCheckboxSortValue(f),
		controlType: 'checkbox',
		columnId: datatableUtil.formatColumnId('Business Mortgage'),
		fieldId: datatableUtil.formatFieldId('Business Mortgage')
	},
	{
		metakey: 'LVR',
		prop: 'LVR',
		name: 'LVR',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('LVR'),
		fieldId: datatableUtil.formatFieldId('LVR')
	},
	{
		metakey: 'Fixed Period End',
		prop: 'FixedPeriodEnd',
		name: 'Fixed Period',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Fixed Period End'),
		fieldId: datatableUtil.formatFieldId('Fixed Period End')
	},
	{
		metakey: 'Loan Term',
		prop: 'LoanTerm',
		name: 'Original Loan Term',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isTwoDecimal: true,
		columnId: datatableUtil.formatColumnId('Loan Term'),
		fieldId: datatableUtil.formatFieldId('Loan Term')
	},
	{
		metakey: 'Loan Repayment',
		prop: 'LoanRepayment',
		name: 'Loan Repayment',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => (f.value ? +f.value : undefined),
		controlType: 'money',
		isTwoDecimal: true,
		columnId: datatableUtil.formatColumnId('Loan Repayment'),
		fieldId: datatableUtil.formatFieldId('Loan Repayment')
	},
	{
		metakey: 'Repayment Frequency',
		prop: 'RepaymentFrequency',
		name: 'Repayment Frequency',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Repayment Frequency'),
		fieldId: datatableUtil.formatFieldId('Repayment Frequency')
	},
	{
		metakey: 'Business Refix',
		prop: 'BusinessRefix',
		name: 'Refix Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Business Refix'),
		fieldId: datatableUtil.formatFieldId('Business Refix')
	},
	{
		metakey: 'Submitted',
		prop: 'Submitted',
		name: 'Submitted',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Submitted'),
		fieldId: datatableUtil.formatFieldId('Submitted')
	},
	{
		metakey: 'Approved',
		prop: 'Approved',
		name: 'Approved',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Approved'),
		fieldId: datatableUtil.formatFieldId('Approved')
	},
	{
		metakey: 'Expected Settlement',
		prop: 'ExpectedSettlement',
		name: 'Expected Settlement',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Expected Settlement'),
		fieldId: datatableUtil.formatFieldId('Expected Settlement')
	},
	{
		metakey: 'Loan Type',
		prop: 'LoanType',
		name: 'Loan Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Loan Type'),
		fieldId: datatableUtil.formatFieldId('Loan Type')
	},
	{
		metakey: 'Expiry Date',
		prop: 'ExpiryDate',
		name: 'Expiry Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Expiry Date'),
		fieldId: datatableUtil.formatFieldId('Expiry Date')
	},
	{
		metakey: 'Required',
		prop: 'Required',
		name: 'Required',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textarea',
		columnId: datatableUtil.formatColumnId('Required'),
		fieldId: datatableUtil.formatFieldId('Required')
	},
	{
		metakey: 'Client Next Activity',
		prop: 'ClientNextActivity',
		name: 'Client Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('Client Next Activity'),
		fieldId: datatableUtil.formatFieldId('Client Next Activity')
	},
	{
		metakey: 'User Next Activity',
		prop: 'UserNextActivity',
		name: 'Your Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('User Next Activity'),
		fieldId: datatableUtil.formatFieldId('User Next Activity')
	},
	{
		metakey: 'Mortgage Status Clock',
		prop: 'StatusClock',
		name: 'Status Clock',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => statusClock(f),
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Mortgage Status Clock'),
		fieldId: datatableUtil.formatFieldId('Mortgage Status Clock')
	},
	{
		metakey: 'Last Note',
		prop: 'LastNote',
		name: 'Last Note',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'note',
		columnId: datatableUtil.formatColumnId('Last Note'),
		fieldId: datatableUtil.formatFieldId('Last Note')
	},
	{
		metakey: 'Additional Contacts',
		prop: 'AdditionalContact',
		name: 'Additional Contacts',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'long-display',
		columnId: datatableUtil.formatColumnId('Additional Contacts'),
		fieldId: datatableUtil.formatFieldId('Additional Contacts')
	},
	{
		metakey: 'Lead Origin',
		prop: 'LeadOrigin',
		name: 'Lead Origin',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Lead Origin'),
		fieldId: datatableUtil.formatFieldId('Lead Origin')
	},
	{
		metakey: 'Original Adviser',
		prop: 'OriginalAdviser',
		name: 'Original Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Original Adviser'),
		fieldId: datatableUtil.formatFieldId('Original Adviser')
	},
	{
		metakey: 'Double Sub',
		prop: 'DoubleSub',
		name: 'Double Sub',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, _c) => getCheckboxSortValue(f),
		controlType: 'checkbox',
		columnId: datatableUtil.formatColumnId('Double Sub'),
		fieldId: datatableUtil.formatFieldId('Double Sub')
	},
];

/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 18,
};

/**
 * template for Row
 * Dynamic fields
 */
export const templateRow: Row = {
	CustomerID: null,
	CustomerServiceID: null,
	BorrowingEntitiesList: null,
	SecuritiesList: null,
	ClientNextActivityId: null,
	UserNextActivityId: null,
	Name: {
		metakey: 'Name',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Name').prop,
		required: false,
		editable: false,
		isCustomerDetail: true,
	},
	Adviser: {
		metakey: 'Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Adviser').prop,
		required: true,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	// Adviser Rework
	MortgageAdviser: {
		metakey: 'Mortgage Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Mortgage Adviser').prop,
		required: true,
		editable: false,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	ContactStatus: {
		metakey: 'Contact Status',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Contact Status').prop,
		required: false,
		editable: false,
		isCustomerDetail: true,
	},
	BorrowingEntities: {
		metakey: 'Borrowing Entities',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Borrowing Entities').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	Provider: {
		metakey: 'Provider',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Provider').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Status: {
		metakey: 'Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Status').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	InterestRate: {
		metakey: 'Interest Rate',
		value: '0.00',
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Interest Rate').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	FixedPeriodEndDate: {
		metakey: 'Fixed Period End Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Fixed Period End Date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Security: {
		metakey: 'Security',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Security').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	LoanNumber: {
		metakey: 'Loan Number',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Loan Number').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	LoanValue: {
		metakey: 'Loan Value',
		value: '0.00',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Loan Value').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	LoanDrawdown: {
		metakey: 'Loan Drawdown',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Loan Drawdown').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	MortgageType: {
		metakey: 'Mortgage Type',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Mortgage Type').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	BusinessMortgage: {
		metakey: 'Business Mortgage',
		value: null,
		choices: [],
		controlType: 'checkbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Business Mortgage').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	LVR: {
		metakey: 'LVR',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'LVR').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	FixedPeriodEnd: {
		metakey: 'Fixed Period End',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Fixed Period End').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	LoanTerm: {
		metakey: 'Loan Term',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Loan Term').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	LoanRepayment: {
		metakey: 'Loan Repayment',
		value: '0.00',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Loan Repayment').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	RepaymentFrequency: {
		metakey: 'Repayment Frequency',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Repayment Frequency').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	BusinessRefix: {
		metakey: 'Business Refix',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Business Refix').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Submitted: {
		metakey: 'Submitted',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Submitted').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Approved: {
		metakey: 'Approved',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Approved').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	ExpectedSettlement: {
		metakey: 'Expected Settlement',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Expected Settlement').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
	},
	LoanType: {
		metakey: 'Loan Type',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Loan Type').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	ExpiryDate: {
		metakey: 'Expiry Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Expiry Date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Required: {
		metakey: 'Required',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Required').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	ClientNextActivity: {
		metakey: 'Client Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Client Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	UserNextActivity: {
		metakey: 'User Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'User Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	StatusClock: {
		metakey: 'Mortgage Status Clock',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Mortgage Status Clock').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
	},
	LastNote: {
		metakey: 'Last Note',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Last Note').prop,
		required: false,
		editable: true,
		hasToolTip: true,
		restrict: ['FAN'],
	},
	AdditionalContact: {
		metakey: 'Additional Contacts',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Additional Contacts').prop,
		required: false,
		editable: false,
		hasToolTip: true,
	},
	LeadOrigin: {
		metakey: 'Lead Origin',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns.find((x) => x.metakey === 'Lead Origin').prop,
		required: false,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	OriginalAdviser: {
		metakey: 'Original Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Original Adviser').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FEO'],
	},
	link: null,
	DoubleSub: {
		metakey: 'Double Sub',
		value: null,
		choices: [],
		controlType: 'checkbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Double Sub').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		// restrict: ['FES'],
	},
};

export const createRowPrototypeWithChoices = (
	advisers: ViewDisplayValue[],
	providers: ViewDisplayValue[],
	status: ViewDisplayValue[],
	nextActivityType: ViewDisplayValue[],
	refixStatus: ViewDisplayValue[],
	mortgageType: ViewDisplayValue[],
	fixedPeriod: ViewDisplayValue[],
	repaymentFrequency: ViewDisplayValue[],
	loanType: ViewDisplayValue[]
) => {
	return produce(templateRow, (draft) => {
		draft.Adviser.choices = advisers;
		draft.OriginalAdviser.choices = advisers;
		draft.Provider.choices = providers;
		draft.Status.choices = status;
		draft.ClientNextActivity.choices = nextActivityType;
		draft.UserNextActivity.choices = nextActivityType;
		draft.BusinessRefix.choices = refixStatus;
		draft.MortgageType.choices = mortgageType;
		draft.FixedPeriodEnd.choices = fixedPeriod;
		draft.RepaymentFrequency.choices = repaymentFrequency;
		draft.LoanType.choices = loanType;
	});
};

/**
 * Table body
 * Fill value on rows
 */
export const createRowFromPrototype = R.curry((template: Row, row: Mortgage) =>
	produce<Row>(template, (draft) => {
		draft.CustomerID = row.CustomerID;
		draft.CustomerServiceID = row.CustomerServiceID;
		draft.BorrowingEntitiesList = row.BorrowingEntitiesList;
		draft.SecuritiesList = row.SecuritiesList;
		draft.ClientNextActivityId = row.ClientNextActivityId;
		draft.UserNextActivityId = row.UserNextActivityId;

		draft.Name.metakey = 'Name';
		draft.Name.value = row.Name;

		draft.Adviser.metakey = 'Adviser';
		draft.Adviser.value = row.Adviser;

		// Adviser Rework
		draft.MortgageAdviser.metakey = row.MortgageAdviser?.MetaKey || 'Mortgage Adviser';
		draft.MortgageAdviser.value = row.MortgageAdviser;

		draft.ContactStatus.metakey = 'Contact Status';
		draft.ContactStatus.value = contactStatus(row.ContactStatus);

		draft.BorrowingEntities.metakey = 'Borrowing Entities';
		draft.BorrowingEntities.value = row.BorrowingEntities;
		draft.BorrowingEntities.choices = row.BorrowingEntitiesList
			? row.BorrowingEntitiesList?.map(
					(x: { Id: number; Name: string }) => ({
						display: x.Name,
						value: x.Id?.toString(),
					})
			  ) as ViewDisplayValue[]
			: null;

		draft.Provider.metakey = 'Provider';
		draft.Provider.value = row.Provider;

		draft.Status.metakey = 'Status';
		draft.Status.value = row.Status;

		draft.InterestRate.metakey = 'Interest Rate';
		draft.InterestRate.value = row.InterestRate
			? row.InterestRate === '0'
				? draft.InterestRate.value
				: row.InterestRate
			: draft.InterestRate.value;

		draft.FixedPeriodEndDate.metakey = 'Fixed Period End Date';
		draft.FixedPeriodEndDate.value = row.FixedPeriodEndDate;

		draft.Security.metakey = 'Security';
		draft.Security.value = row.Security;
		draft.Security.choices = row.SecuritiesList
			? row.SecuritiesList?.map(
					(x: { Id: number; Name: string }) => ({
						display: x.Name,
						value: x.Id?.toString(),
					})
			  ) as ViewDisplayValue[]
			: null;

		draft.LoanNumber.metakey = 'Loan Number';
		draft.LoanNumber.value = row.LoanNumber;

		draft.LoanValue.metakey = 'Loan Value';
		draft.LoanValue.value = row.LoanValue
			? row.LoanValue === '0' || row.LoanValue === null
				? draft.LoanValue.value
				: row.LoanValue
			: draft.LoanValue.value;

		draft.LoanDrawdown.metakey = 'Loan Drawdown';
		draft.LoanDrawdown.value = row.LoanDrawdown;

		draft.MortgageType.metakey = 'Mortgage Type';
		draft.MortgageType.value = row.MortgageType;

		draft.BusinessMortgage.metakey = 'Business Mortgage';
		draft.BusinessMortgage.value = row.BusinessMortgage;

		draft.LVR.metakey = 'LVR';
		draft.LVR.value = row.LVR;

		draft.FixedPeriodEnd.metakey = 'Fixed Period End';
		draft.FixedPeriodEnd.value = row.FixedPeriodEnd;

		draft.LoanTerm.metakey = 'Loan Term';
		draft.LoanTerm.value = row.LoanTerm;

		draft.LoanRepayment.metakey = 'Loan Repayment';
		draft.LoanRepayment.value = row.LoanRepayment
			? row.LoanRepayment === '0' || row.LoanRepayment === null
				? draft.LoanRepayment.value
				: row.LoanRepayment
			: draft.LoanRepayment.value;

		draft.RepaymentFrequency.metakey = 'Repayment Frequency';
		draft.RepaymentFrequency.value = row.RepaymentFrequency;

		draft.BusinessRefix.metakey = 'Business Refix';
		draft.BusinessRefix.value = row.BusinessRefix;

		draft.Submitted.metakey = 'Submitted';
		draft.Submitted.value = row.Submitted;

		draft.Approved.metakey = 'Approved';
		draft.Approved.value = row.Approved;

		draft.ExpectedSettlement.metakey = 'Expected Settlement';
		draft.ExpectedSettlement.value = row.ExpectedSettlement;

		draft.LoanType.metakey = 'Loan Type';
		draft.LoanType.value = row.LoanType;

		draft.ExpiryDate.metakey = 'Expiry Date';
		draft.ExpiryDate.value = row.ExpiryDate;

		draft.Required.metakey = 'Required';
		draft.Required.value = row.Required;

		draft.ClientNextActivity.metakey = 'Client Next Activity';
		draft.ClientNextActivity.value = row.ClientNextActivity;

		draft.UserNextActivity.metakey = 'User Next Activity';
		draft.UserNextActivity.value = row.UserNextActivity;

		draft.StatusClock.metakey = 'Mortgage Status Clock';
		draft.StatusClock.value = computeUtil.calcStatusClock(row.StatusClock);

		draft.LastNote.metakey = 'Last Note';
		draft.LastNote.value = row.LastNote;

		draft.AdditionalContact.metakey = 'Additional Contacts';
		draft.AdditionalContact.value = row.AdditionalContact;

		draft.LeadOrigin.metakey = 'Lead Origin';
		draft.LeadOrigin.value = row.LeadOrigin;

		draft.OriginalAdviser.metakey = 'Original Adviser';
		draft.OriginalAdviser.value = row.OriginalAdviser;
		draft.OriginalAdviser.choices = draft.OriginalAdviser.choices?.filter(
			(x) =>
				x.value === row.OriginalAdviser ||
				draft.Adviser.choices?.find((y) => y.value === x.value)
		);

		draft.link = {
			CustomerServiceId: row.CustomerServiceID,
			CustomerId: row.CustomerID,
			IsCompany: row.IsCompany,
		};

		draft.DoubleSub.metakey = 'Double Sub';
		draft.DoubleSub.value = row.DoubleSub;
	})
);
