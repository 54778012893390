import { Injectable } from '@angular/core';
import { ApiService, JsonResultStatus } from '../../../core/base/api.service';
import { TlEditStaffRequest, TlAddStaffRequest } from '../../../shared/models/_general/staff';
import { mergeMap, map } from 'rxjs/operators';
import sort from 'fast-sort';

@Injectable()
export class StaffService {
  domain: string = window.location.host;

  constructor(private api: ApiService) { }
  // TL ----------------------------
  deactivateTlStaff(id: number) {
    const endpoint = 'Staff/DeactivateTLStaff';
    const body = { staff_id: id };
    return this.api.post3<JsonResultStatus>(endpoint, body);
  }
  getTlStaffs() {
    const endpoint = 'staff/tl';
    const body = {
      staff_level: 'TL',
      show_disabed: true,
    };
    return this.api.get<any>(endpoint, body);
  }
  getTlStaff(id: number | string) {
    const endpoint = `staff/${id}/tl`;
    const body = {
      staff_level: 'TL',
      id,
    };
    return this.api.get<JsonResultStatus>(endpoint, body);
  }
  editTlStaff(staff: TlEditStaffRequest) {
    const endpoint = 'Staff/UpdateTLStaff';
    const body = staff;
    return this.api.post3<JsonResultStatus>(endpoint, body);
  }
  addTlStaff(staff: TlAddStaffRequest) {
    const endpoint = 'Staff/AddTLStaff';
    const body = staff;
    return this.api.post3<JsonResultStatus>(endpoint, body);
  }

  // BL ----------------------------
  getBusinesses() {
    const endpoint = `Businesses`;
    return this.api
      .get<{ [k: string]: any }[]>(endpoint, { type: 'active' })
      .pipe(
        mergeMap(a =>
          this.api
            .get<{ [k: string]: any }[]>(endpoint, { type: 'archived' })
            .pipe(
              map(b => {
                const businesses = [...(a ? a : []), ...(b ? b : [])];
                return sort(businesses).asc(x => x.BusinessName);
              })
            )
        )
      );
  }
}
