import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { ScopeOfServiceSettingsService } from './state/scope-of-service-settings.service';

@Component({
  selector: 'app-scope-of-service-settings',
  templateUrl: './scope-of-service-settings.component.html',
  styleUrls: ['./scope-of-service-settings.component.scss']
})
export class ScopeOfServiceSettingsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
  emailSettings$ = this.sosSettingsService.emailSettings$;

  constructor(private sosSettingsService: ScopeOfServiceSettingsService) { }

  ngOnInit(): void {
  }

	prepData() {
    combineLatest([
      this.emailSettings$
    ]).pipe(
      map(([emailSettings]) => MergeTagsMapper.mapMergeTags(emailSettings?.mergeTag || [])),
      tap((mt) => this.mergeTags$.next(mt)),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

	ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
