import { Tag } from './global-search.types';

export const TAGS: Array<Tag> = [
  {
    type: 'person',
    value: 'People',
    icon: 'people'
  },
  {
    type: 'files',
    value: 'Client Files',
    icon: 'client'
  },
  {
    type: 'business',
    value: 'Business Files',
    icon: 'business'
  },
  {
    type: 'service',
    value: 'Services',
    icon: 'policies'
  },
  {
    type: 'activity',
    value: 'Activities',
    icon: 'activities',
  },
];

export const TYPING_TIMER_TIMEOUT = 1000;