<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center px-3"
>
	<app-header
		[pageHeader]="businessFapName + ' - KiwiSaver Advice'"
		pageHeaderCustomClass="tap-fs-20 tap-fw-normal"
		[manualLoading]="isTabLoading$ | async"
		[callbackFn$]="pauseExitFn"
	></app-header>
</div>

<!-- Content -->
<div role="main-content" class="px-80 py-20">
	<h1 class="kiwisaver-header mb-20">Disclosure</h1>
	<ng-container
		*ngIf="
			(crtData && crtFileType === DdTemplateFileExt.text) ||
			(!crtData &&
				defaultSettings?.templateType === DdTemplateType.FroalaTemplate)
		"
	>
		<app-wysiwyg
			#contentEditor
			[content]="content"
			[readOnly]="true"
			[generatePdf$]="generatePdf$"
		></app-wysiwyg>
	</ng-container>

	<ng-container
		*ngIf="
			(crtData && crtFileType === DdTemplateFileExt.pdf) ||
			(!crtData &&
				defaultSettings?.templateType === DdTemplateType.UploadDocument)
		"
	>
		<div class="wrapper">
			<app-view-pdf
				*ngIf="fileUrl"
				[fileSource]="fileUrl"
				(onPageRendered)="onPageRendered($event)"
			></app-view-pdf>
		</div>
	</ng-container>

	<!-- Buttons -->
	<div class="clearfix p-lg-0 mt-20">
		<ng-container
			*ngIf="
				defaultSettings?.isEnableEmailOption != null &&
				(disclosureSettings$ | async)
			"
		>
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-20"
				(click)="saveDocument(); generatedPdf()"
				[disabled]="!!contentLoading"
			>
				Save to File
			</button>
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-20"
				(click)="downloadDocument()"
				[disabled]="!!contentLoading"
			>
				Download Document
			</button>
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-20"
				(click)="reloadTemplate()"
				[disabled]="!!contentLoading"
			>
				Reload Template
			</button>
			<button
				type="button"
				class="tap-btn tap-btn-outline tap-btn--shadow mb-20"
				(click)="sendEmail(); generatedPdf()"
				[disabled]="!!contentLoading"
				*ngIf="defaultSettings?.isEnableEmailOption"
			>
				Email Client
			</button>
			<ng-container *ngIf="!!contentLoading && !(isTabLoading$ | async)">
				<app-manual-loader-mini
					[isLoading]="!!contentLoading"
					class="pl-1"
				></app-manual-loader-mini>
			</ng-container>
		</ng-container>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end px-3"
>
	<app-footer
		[currentPageId]="pageIds.Disclosure"
		[manualLoading]="isTabLoading$ | async"
		[sidebar]="sidebar"
	></app-footer>
</div>
