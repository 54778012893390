<div class="container-fluid hideScroll tap-document-table position-relative">
	<ng-container *appRestrictTo="['FADO']">
		<button
			class="tap-btn tap-btn--default tap-document-table__add"
			(click)="openUploadModal()"
			id="addNewDocumentBtn"
		>
			Add New Document
		</button>
	</ng-container>
	<div class="row tap-document-table__header align-items-center">
		<div class="col-3 p-2">Name</div>
		<div class="col-3 p-2">Date Uploaded</div>
		<div class="col-3 col-md-4 col-xl-5 p-2">Document</div>
		<div class="col-3 col-md-2 col-xl-1 p-2"></div>
	</div>
	<div
		class="row tap-document-table__item-list align-items-center"
		*ngFor="let d of dataList; let i = index"
	>
		<div class="col-3 word-break font-weight-bold p-2">
			<span
				class="cursor-pointer"
				(click)="downloadLink(d)"
				id="documentNameDownloadLink_{{ i }}"
				>{{ d.DocumentName }}</span
			>
		</div>
		<div class="col-3 p-2">
			<span
				class="cursor-pointer"
				(click)="downloadLink(d)"
				id="documentCreateDateLink_{{ i }}"
				>{{ d.CreateDateTime | momentDatetime: true }}</span
			>
		</div>
		<div class="col-3 col-md-4 col-xl-5 word-break font-weight-bold p-2">
			<span
				class="cursor-pointer"
				(click)="downloadLink(d)"
				id="documentFileNameDownloadLink_{{ i }}"
				>{{ d.DocumentName }}</span
			>
		</div>
		<div class="col-3 col-md-2 col-xl-1 align-self-center text-right p-2">
			<ng-container *appRestrictTo="['FTDO']">
				<button
					type="button"
					class="btn btn-link p-0"
					(click)="transferConfirm(d)"
					id="transferDocumentBtn_{{ i }}"
				>
					<i class="material-icons md-20 tap-text-color-h6">arrow_forward</i>
				</button>
			</ng-container>

			<ng-container *appRestrictTo="['FDDO']">
				<button
					type="button"
					class="btn btn-link p-0"
					(click)="deleteConfirm(d)"
					id="deleteDocumentBtn_{{ i }}"
				>
					<i class="material-icons md-20 tap-text-color-h6">&#xE872;</i>
				</button>
			</ng-container>
		</div>
	</div>
</div>
