<form [formGroup]="form" class="user-goals-form">
	<div class="row my-2 m-0 pl-0 pt-4 pb-4 border-bottom">
		<div class="col-12">
			<button
				type="button"
				class="btn btn-primary tap-bg-primary mr-3"
				id="userGoalsEdit"
				[disabled]="isLoading"
				(click)="enableEdit()"
				*ngIf="!isEdit"
			>
				Edit
			</button>
			<button
				type="button"
				class="btn btn-primary tap-bg-primary mr-3"
				id="userGoalsSaveEdit"
				[disabled]="isLoading"
				(click)="save()"
				*ngIf="!!isEdit"
			>
				Save
				<i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				type="button"
				class="btn btn-primary tap-bg-primary mr-3"
				id="userGoalsCancelEdit"
				[disabled]="isLoading"
				(click)="cancelEdit()"
				*ngIf="!!isEdit"
			>
				Cancel
			</button>
		</div>
	</div>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 col-md-12 p-0">
				<div class="container-fluid">
					<!-- L&R -->
					<div class="row mt-3">
						<div class="col-12">
							<h3 class="m-0">L&R</h3>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="lrFirstMeetingQuarter">First Meeting Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="lrFirstMeetingQuarter"
								formControlName="lr_first_meeting_goal_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="lrFirstMeetingAnnual">First Meeting Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="lrFirstMeetingAnnual"
								formControlName="lr_first_meeting_goals_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="lrSoaMeetingQuarter">SOA Meeting Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="lrSoaMeetingQuarter"
								formControlName="lr_soa_meeting_goals_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="lrSoaMeetingAnnual">SOA Meeting Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="lrSoaMeetingAnnual"
								formControlName="lr_soa_meeting_goals_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="lrSubmittedApiQuarter">Submitted API Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="lrSubmittedApiQuarter"
								formControlName="submitted_lr_api_goals_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="lrSubmittedApiAnnual">Submitted API Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="lrSubmittedApiAnnual"
								formControlName="submitted_lr_api_goals_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="selfGenQuarter">Issued Self Gen Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="selfGenQuarter"
								formControlName="self_gen_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="selfGenAnnual">Issued Self Gen Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="selfGenAnnual"
								formControlName="self_gen_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="apiQuarter">Issued API Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="apiQuarter"
								formControlName="api_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="apiAnnual">Issued API Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="apiAnnual"
								formControlName="api_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-6"></div>
					</div>

					<!-- KiwiSaver -->
					<div class="row mt-5">
						<div class="col-12">
							<h3 class="m-0">KiwiSaver</h3>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="ksSubmittedQuarter">Submitted KS Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="ksSubmittedQuarter"
								formControlName="submitted_kiwisaver_goals_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="ksSubmittedAnnual">Submitted KS Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="ksSubmittedAnnual"
								formControlName="submitted_kiwisaver_goals_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="kiwiSaverQuarter">Completed KS Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="kiwiSaverQuarter"
								formControlName="kiwisaver_quarter"
								noDecimalNumeric
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="kiwiSaverAnnual">Completed KS Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="kiwiSaverAnnual"
								formControlName="kiwisaver_annual"
								noDecimalNumeric
							/>
						</div>
					</div>

					<!-- Mortgage -->
					<div class="row mt-5">
						<div class="col-12">
							<h3 class="m-0">Mortgage</h3>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="mLoanQuarter">First Meeting Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="mLoanQuarter"
								formControlName="mortgage_first_meeting_goals_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="mLoanAnnual">First Meeting Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="mLoanAnnual"
								formControlName="mortgage_first_meeting_goals_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="mRoaMeetingQuarter">ROA Meeting Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="mRoaMeetingQuarter"
								formControlName="mortgage_roa_meeting_goals_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="mRoaMeetingAnnual">ROA Meeting Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="mRoaMeetingAnnual"
								formControlName="mortgage_roa_meeting_goals_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="mSubmittedLoanQuarter">Submitted Loan Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="mSubmittedLoanQuarter"
								formControlName="submitted_mortgage_loan_value_goals_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="mSubmittedLoanAnnual">Submitted Loan Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="mSubmittedLoanAnnual"
								formControlName="submitted_mortgage_loan_value_goals_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="mortgageQuarter">Settled Mortgage Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="mortgageQuarter"
								formControlName="mortgage_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="mortgageAnnual">Settled Mortgage Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="mortgageAnnual"
								formControlName="mortgage_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
					</div>

					<!-- F&G -->
					<div class="row mt-5">
						<div class="col-12">
							<h3 class="m-0">F&G</h3>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="blanketNewBusinessQuarter"
								>Blanket New Business Quarter</label
							><br />
							<input
								class="form-control"
								type="text"
								id="blanketNewBusinessQuarter"
								formControlName="blanketnewbusiness_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="blanketNewBusinessAnnual"
								>Blanket New Business Annual</label
							><br />
							<input
								class="form-control"
								type="text"
								id="blanketNewBusinessAnnual"
								formControlName="blanketnewbusiness_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="domesticFgQuarter">Domestic F&G Quarter</label><br />
							<input
								class="form-control"
								type="text"
								id="domesticFgQuarter"
								formControlName="domestic_fg_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="domesticFgAnnual">Domestic F&G Annual</label><br />
							<input
								class="form-control"
								type="text"
								id="domesticFgAnnual"
								formControlName="domestic_fg_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-12 col-md-3">
							<label for="commercialFgQuarter">Commercial F&G Quarter</label
							><br />
							<input
								class="form-control"
								type="text"
								id="commercialFgQuarter"
								formControlName="commercial_fg_quarter"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-3">
							<label for="commercialFgAnnual">Commercial F&G Annual</label
							><br />
							<input
								class="form-control"
								type="text"
								id="commercialFgAnnual"
								formControlName="commercial_fg_annual"
								appTwoDigitDecimalNumberV2
							/>
						</div>
						<div class="col-12 col-md-6"></div>
					</div>

					<!-- Conversion -->
					<ng-container *ngIf="isConversionEnabled">
						<div class="row mt-5">
							<div class="col-12">
								<h3 class="m-0">Conversion</h3>
							</div>
						</div>
						<div class="row mt-3 mb-4">
							<div class="col-12 col-md-3">
								<label for="lRConversion">L&R Conversion</label><br />
								<div class="col-lg-12 percent-icon p-0">
									<input
										class="form-control"
										type="text"
										id="lRConversion"
										formControlName="lr_conversion"
										appOneDigitDecimalNumber
									/>
									<i>%</i>
								</div>
							</div>
							<div class="col-12 col-md-3">
								<label for="kiwisaverConversion">KiwiSaver Conversion</label
								><br />
								<div class="col-lg-12 percent-icon p-0">
									<input
										class="form-control"
										type="text"
										id="kiwisaverConversion"
										formControlName="kiwisaver_conversion"
										appOneDigitDecimalNumber
									/>
									<i>%</i>
								</div>
							</div>
							<div class="col-12 col-md-3">
								<label for="blanketConversion">Blanket Conversion</label><br />
								<div class="col-lg-12 percent-icon p-0">
									<input
										class="form-control"
										type="text"
										id="blanketConversion"
										formControlName="blanket_conversion"
										appOneDigitDecimalNumber
									/>
									<i>%</i>
								</div>
							</div>
							<div class="col-12 col-md-3">
								<label for="mortgageConversion">Mortgage Conversion</label><br />
								<div class="col-lg-12 percent-icon p-0">
									<input
										class="form-control"
										type="text"
										id="mortgageConversion"
										formControlName="mortgage_conversion"
										appOneDigitDecimalNumber
									/>
									<i>%</i>
								</div>
							</div>
							<div class="col-12 col-md-3"></div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</form>
