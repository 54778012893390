import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { SettingsTypes } from './state/kiwisaver-settings.model';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { KiwiMergeTagsCrtSettingsService } from './merge-tags/state/kiwi-merge-tags-crt-settings.service';
import { KiwiScopeOfServiceService } from './scope-of-service-settings/state/kiwi-scope-of-service.service';
import { KiwiIntroSettingsService } from './intro-settings/state/kiwi-intro-settings.service';
import { KiwiSosTemplateSettingsService } from './scope-of-service-settings/sos-template-settings/state/kiwi-sos-template-settings.service';
import { FactFindSubpagesSettingsService } from './fact-find-settings/subpages/state/fact-find-subpages-settings.service';
import { FactFindRiskProfileSettingsService } from './fact-find-settings/risk-profile/state/fact-find-risk-profile-settings.service';
import { AdviceSummarySettingsService } from './advice-summary-settings/state/advice-summary-settings.service';
import { AdviceSummaryEmailSettingsService } from './advice-summary-settings/advice-summary-email-settings/state/advice-summary-email-settings.service';
import { DocumentListService } from '@modules/kiwisaver-settings/advice-summary-settings/document-list/state/document-list.service';
import { EndProcessEmailSettingsService } from './end-process/state/end-process.service';
import { ProviderSettingsService } from './fact-find-settings/providers/state/providers-settings.service';
import { DropdownValuesService } from '@core/dropdown/dropdown-values.service';
import { KiwiSaverSettingsStore } from './state/kiwisaver-settings.store';
import { applyTransaction } from '@datorama/akita';
import { DeclarationEmailSettingsService } from './declaration-settings/declaration-email-settings/state/declaration-email-settings.service';
import { DeclarationTemplateSettingsService } from './declaration-settings/declaration-template-settings/state/declaration-template-settings.service';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { RouteService } from '@core/config/route.service';
import { BusinessConfigService } from '@domain/business-config/business-config.service';

@Injectable()
export class KiwiSaverSettingsResolver implements Resolve<boolean> {
	constructor(
		private sService: KiwiScopeOfServiceService,
		private introSettings: KiwiIntroSettingsService,
		private mtService: KiwiMergeTagsCrtSettingsService,
		private sosTemplateSettingsService: KiwiSosTemplateSettingsService,
		private adviceSummarySettingsService: AdviceSummarySettingsService,
		private factFindSubpagesSettings: FactFindSubpagesSettingsService,
		private factFindRiskProfileSettings: FactFindRiskProfileSettingsService,
		private adviceSummaryEmailSettingsService: AdviceSummaryEmailSettingsService,
		private documentListService: DocumentListService,
		private endProcessEmailSettingsService: EndProcessEmailSettingsService,
		private providersSettings: ProviderSettingsService,
		private decService: DeclarationTemplateSettingsService,
		private decEmailSettingsService: DeclarationEmailSettingsService,
		private dropdownService: DropdownValuesService,
		private store: KiwiSaverSettingsStore,
    private router: Router,
    private routeService: RouteService,
		private bConfigService: BusinessConfigService,
		private bProfileService: BusinessProfileService
	) {}

	resolve(): Observable<boolean> {
		const companyCode = this.bConfigService.companyCode();
		const refId = 0;

		const getIntroSettings$ = this.introSettings.getIntroSettings(refId);
		const getScopeOfService$ = this.sService.getScopeOfService(
			refId,
			SettingsTypes.SOS
		);

		const getSosTemplateSettings$ =
			this.sosTemplateSettingsService.getSettings();

		const getDeclaration$ = this.decService.getDeclarationDocument(
			refId,
			SettingsTypes.Declaration
		);

		const getDeclarationEmailSettings$ =
			this.decEmailSettingsService.getDeclarationEmailSettings(
				refId,
				SettingsTypes.KOATDeclarationEmail
			);

		const getAdviceSummarySettings$ =
			this.adviceSummarySettingsService.getAdviceSummarySettings(
				refId,
				SettingsTypes.KOATAdviceSummary
			);

		const factFindSubpagesSettings$ =
			this.factFindSubpagesSettings.getFactFindSubpagesSettings(
				refId,
				SettingsTypes.KOATFactFind
			);

		const factFindRiskProfileQASettings$ =
			this.factFindRiskProfileSettings.getFactFindRiskProfileQASettings(
				refId,
				SettingsTypes.KOATFactFindRiskProfileQA
			);

		const factFindRiskProfileOutcomesSettings$ =
			this.factFindRiskProfileSettings.getFactFindRiskProfileOutcomesSettings(
				refId,
				SettingsTypes.KOATFactFindRiskProfileOutcomes
			);

		const getAdviceSummaryEmailSettings$ =
			this.adviceSummaryEmailSettingsService.getEmailSettings(
				refId,
				SettingsTypes.KOATAdviceSummaryEmail
			);

		const getDocumentList$ = this.documentListService.getDocumentList(
			0,
			SettingsTypes.AdviceSummaryDocumentListSettings
		);

		const getProviderSettings$ = this.providersSettings.getProvidersSettings(
			0,
			SettingsTypes.KOATFactFindProviders
		);

		const getProviderDropdowns$ = this.dropdownService
			.getDropdownValues('KP')
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						this.store.setProviderDropdowns(x);
					})
				)
			);

		const getEndProcessEmail$ = this.endProcessEmailSettingsService.getSettings(
			refId,
			AdviceProcessSectionCodes.KiwiSaverEndProcess
		);

		// Merge Tags
		let i = 0;
		const getMtGeneral$ = this.mtService.getMergeTags(i++, 'general', refId);
		const getMtBusiness$ = this.mtService.getMergeTags(i++, 'business', refId);
		const getMTStaff$ = this.mtService.getMergeTags(i++, 'staff', +refId);
		// Settings
		const getMTProviders$ = this.mtService.getMtSettings(i++, 'PCT');
		const getMTLrProvider$ = this.mtService.getMtSettings(i++, 'PCLR');
		const getMTLrDetails$ = this.mtService.getMtSettingDetails(i++, 'PCLR');

		// Fact Find > People
		const getPeople$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.People
		);
		const getDependants$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Dependants
		);
		const getTrusts$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Trust
		);
		const getCompany$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Company
		);
		// Fact Find > A&L
		const getProperties$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Property
		);
		const getKiwiSaver$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.KiwiSaver
		);
		const getAssets$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.AssetsInvestments
		);
		const getMortages$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Mortgages
		);
		const getLiabilities$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.Liabilities
		);
		const getIncomeSources$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.IncomeSource
		);
		const getPreviousIncome$ = this.mtService.getMtFactFind(
			i++,
			AdviceProcessSectionCodes.PreviousIncome
		);
		const getTBA$ = this.mtService.getMtFactFind(i++, 'TBA');
		const getLsSoaMergeTags$ = this.mtService.getLsSoaMt();
		const getSosMergeTags$ = this.mtService.getSosMt();

		return forkJoin([
			getIntroSettings$,
			getScopeOfService$,
			getSosTemplateSettings$,
			getDeclaration$,
			getDeclarationEmailSettings$,
			getAdviceSummarySettings$,
			factFindSubpagesSettings$,
			factFindRiskProfileQASettings$,
			factFindRiskProfileOutcomesSettings$,
			getAdviceSummaryEmailSettings$,
			getDocumentList$,
			getEndProcessEmail$,
			getProviderSettings$,
			getProviderDropdowns$,

			// Merge Tags
			getMtGeneral$,
			getMtBusiness$,
			getMTProviders$,
			getMTLrProvider$,
			getMTLrDetails$,
			getMTStaff$,
			getSosMergeTags$,
			getPeople$,
			getDependants$,
			getTrusts$,
			getCompany$,
			getProperties$,
			getKiwiSaver$,
			getAssets$,
			getMortages$,
			getLiabilities$,
			getIncomeSources$,
			getPreviousIncome$,
			getLsSoaMergeTags$,
			getTBA$,
		]).pipe(
			withLatestFrom(
				this.bProfileService.hasK$,
				this.bProfileService.hasKOAT$
			),
			tap(([, hasK, hasKOAT]) => {
				if (!hasK || !hasKOAT) {
					const redirect = this.routeService.customBusiness(companyCode);
					this.router.navigate(this.routeService.splitToRoutePath(redirect));
				}
			}),
			mapTo(true),
		);
	}
}
