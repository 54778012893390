import { StoreConfig, Store, } from '@datorama/akita';
import produce from 'immer';
import { LrPipeline, KiwisaverPipeline, MortgagePipeline, InvestmentPipeline, FGPipeline } from './p.model';
import { Injectable } from "@angular/core";

/**
 * Pipeline widget state model
 */
export interface PState {
  isServiceLoading: boolean;
  /**
   * LR Pipeline related state
   *
   * @property LRPipelineStats -
   *  LR pipeline stats data
   * @property isLoading -
   *  Indicator whether LR pipeline is currently fetching
   * @property error -
   *  Error message if any. always clear on request.
   *  Show if not empty.
   */
  LRState: {
    LRPipelineStats?: LrPipeline
    error: string;
    isLoading: boolean;
  };

  /**
   * Mortgage Pipeline related state
   *
   * @property MortgagePipelineStats -
   *  Mortgage pipeline stats data
   * @property isLoading -
   *  Indicator whether Mortgage pipeline is currently fetching
   * @property error -
   *  Error message if any. always clear on request.
   *  Show if not empty.
   */
  MortgageState: {
    MortgagePipelineStats?: MortgagePipeline
    error: string;
    isLoading: boolean;
  };

  /**
   * Kiwisaver Pipeline related state
   *
   * @property KiwisaverPipelineStats -
   *  Kiwisaver pipeline stats data
   * @property isLoading -
   *  Indicator whether Kiwisaver pipeline is currently fetching
   * @property error -
   *  Error message if any. always clear on request.
   *  Show if not empty.
   */
  KiwisaverState: {
    KiwisaverPipelineStats?: KiwisaverPipeline
    error: string;
    isLoading: boolean;
  };


  /**
   * Investment Pipeline related state
   *
   * @property InvestmentPipelineStats -
   *  Investment pipeline stats data
   * @property isLoading -
   *  Indicator whether Investment pipeline is currently fetching
   * @property error -
   *  Error message if any. always clear on request.
   *  Show if not empty.
   */
	InvestmentState: {
    InvestmentPipelineStats?: InvestmentPipeline
    error: string;
    isLoading: boolean;
  };

  /**
   * F&G Pipeline related state
   *
   * @property F&GPipelineStats -
   *  F&G pipeline stats data
   * @property isLoading -
   *  Indicator whether F&G pipeline is currently fetching
   * @property error -
   *  Error message if any. always clear on request.
   *  Show if not empty.
   */
  FGState: {
    FGPipelineStats?: FGPipeline
    error: string;
    isLoading: boolean;
  };
}

/**
 * Create initial state store
 */
export function createInitialState(): PState {
  return {
    isServiceLoading: false,
    LRState: {
      LRPipelineStats: null,
      error: '',
      isLoading: false
    },
    MortgageState: {
      MortgagePipelineStats: null,
      error: '',
      isLoading: false
    },
    KiwisaverState: {
      KiwisaverPipelineStats: null,
      error: '',
      isLoading: false
    },
    InvestmentState: {
      InvestmentPipelineStats: null,
      error: '',
      isLoading: false
    },
    FGState: {
      FGPipelineStats: null,
      error: '',
      isLoading: false
    },
  };
}

/**
 * Pipeline widget data store
 */
@Injectable()
@StoreConfig({
  name: 'PGoalTrack'
})
export class PStore extends Store<PState> {

  constructor() {
    super(createInitialState());
  }

  setIsServiceLoading(isServiceLoading: boolean): void {
    this.update(produce(draft => {
      draft.isServiceLoading = isServiceLoading;
    }))
  }

  /**
   * Set if LR pipeline is loading or not
   * @param isLoading if widget is loading
   */
  setLRLoading(isLoading: boolean): void {
    this.update(produce(draft => {
      draft.LRState.isLoading = isLoading;
    }));
  }
  /**
   * Set if Mortgage pipeline is loading or not
   * @param isLoading if widget is loading
   */
  setMortgageLoading(isLoading: boolean): void {
    this.update(produce(draft => {
      draft.MortgageState.isLoading = isLoading;
    }));
  }
  /**
   * Set if Kiwisaver pipeline is loading or not
   * @param isLoading if widget is loading
   */
  setKiwisaverLoading(isLoading: boolean): void {
    this.update(produce(draft => {
      draft.KiwisaverState.isLoading = isLoading;
    }));
  }
  /**
   * Set if Investment pipeline is loading or not
   * @param isLoading if widget is loading
   */
  setInvestmentLoading(isLoading: boolean): void {
    this.update(produce(draft => {
      draft.InvestmentState.isLoading = isLoading;
    }));
  }
  /**
   * Set if F&G pipeline is loading or not
   * @param isLoading if widget is loading
   */
  setFGLoading(isLoading: boolean): void {
    this.update(produce(draft => {
      draft.FGState.isLoading = isLoading;
    }));
  }

  /** ***WARNING***
   * Don't use. There are separate setLoading for each pipeline.
   */
  setLoading() {
    return;
  }
  /** ***WARNING***
   * Don't use. Use update to set error for each pipeline.
   */
  setError() {
    return;
  }
}
