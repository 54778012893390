export enum WidgetSettingType {
  NRDRO = 'NRDRO',
  APT = 'APT'
}

export interface NRDROSettings {
  SettingsId: number,
  ReferenceId: number,
  Type: string,
  NextReviewDateRollOver : number
}

export interface APTSettings {
  SettingsId: number,
  ReferenceId: number,
  Type: string,
  AdviceProcessTrackingTarget : number
}

export interface NRDROSettingsState {
  createdByStaffId: number,
  createdByStaffLevel: number
  createdDateTime: string,
  modifiedByStaffId: number,
  modifiedByStaffLevel: number,
  modifiedDateTime: string,
  nextReviewDateRollOver: number,
  referenceId: number,
  settingsId: number
  status: number,
  type: string,
}

export interface APTSettingsState {
  createdByStaffId: number,
  createdByStaffLevel: number
  createdDateTime: string,
  modifiedByStaffId: number,
  modifiedByStaffLevel: number,
  modifiedDateTime: string,
  nextReviewDateRollOver: number,
  referenceId: number,
  settingsId: number
  status: number,
  type: string,
}
