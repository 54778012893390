import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnDestroy,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import {
	CommandRoute,
	RouteService,
} from '../../../../../../core/config/route.service';
import { UserQuery } from '../../../../../../domain/user/user.query';
import { ActivityCancelModalComponent } from '../../../../../../shared/modal/activity/activity-cancel-modal/activity-cancel-modal.component';
import {
	ActivityModel,
	PutActivityParam,
} from '../../../../../../shared/models/_general/activity.model';
import MomentUtil from '../../../../../../util/moment.util';
import { objectUtil } from '../../../../../../util/util';
import { AQuery } from '../state/a.query';
import { AService } from '../state/a.service';

/** table for activities in Activities widget */
@Component({
	selector: 'app-a-table',
	templateUrl: './a-table.component.html',
	styleUrls: ['./a-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ATableComponent implements OnDestroy {
	onDestroy$: Subject<void> = new Subject<void>();
	/** activity data from response */
	data$: Observable<ActivityModel[]> = this.query.data$;
	/**
	 * date made for view.
	 * gets various data needed by UI
	 */
	viewData$ = this.data$.pipe(
		map((activities) =>
			activities?.map((act) => {
				const due = this.getDue(act);
				return {
					...act,
					activityId: act.ActivityId,
					dueDate: due,
					activityName: act.ActivityName,
					clientName: this.getClientName(act),
					isCompleted: act.IsCompleted,
					className: this.getOverdueState(act),
					route: this.getRoute(act),
				};
			})
		)
	);

	isDisabledComplete$ = this.userQuery.userInfo$.pipe(
		map((x) => !x.SecurityRoles?.some((y) => y === 'FCOA'))
	);

	@ViewChildren('checkBoxes') checkboxes: QueryList<ElementRef>;

	constructor(
		private query: AQuery,
		private routeService: RouteService,
		private aService: AService,
		private userQuery: UserQuery,
		private modalService: BsModalService
	) {}

	/** complete activity in state and api */
	public completeActivity(activity: any): void {
		const updateActivity: PutActivityParam = {
			ActivityId: activity.ActivityId,
			ActivityType: activity.ActivityType,
			ActivityName: activity.ActivityName,
			DueDate: activity.DueDate,
			DueTime: activity.DueTime,
			Duration: activity.Duration,
			Adviser: activity.Adviser,
			Location: activity.Location,
			Details: activity.Details,
			Appointment: activity.Appointment,
			CustomerId: activity.Client ? activity.Client.CustomerId : null,
			CustomerName: activity.Client ? activity.Client.CustomerName : null,
			IsCompleted: true,
			IsCancelled: activity.IsCancelled,
			IsActive: activity.IsActive,
			Meeting: activity.Meeting,
		};
		this.aService.complete(updateActivity).subscribe(() => {
			this.checkboxes?.forEach((element) => {
				element.nativeElement.checked = false;
			});
		});
	}

	public cancelActivity = (data: any) =>
		new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) => {
				let date = x.activity?.DueDate?.split('/')?.reverse()?.join('-');
				const activity: PutActivityParam = {
					...x.activity,
					DueDate: MomentUtil.formatDateToServerDate(date),
					CustomerId: x.activity.Client ? x.activity.Client.CustomerId : null,
					CustomerName: x.activity.Client
						? x.activity.Client.CustomerName
						: null,
					IsCancelled: true,
					Reason: x.reason,
				};
				return this.aService.cancelActivity(activity);
			})
		);

	public cancel = (activity: any) => {
		const mapActivity = objectUtil.mapCamelCaseToPascalCase(activity);
		new Observable((obs) => {
			const initState: any = {
				header: 'Cancel Activity',
				message: `Are you sure you want to cancel this activity?`,
				activity: mapActivity,
				savefn: this.cancelActivity,
			};
			this.modalService.show(ActivityCancelModalComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
			});

			obs.complete();
		})
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	/** ngFor optimization */
	public trackByFn(index: number, item: ActivityModel) {
		return item.ActivityId;
	}

	/** Get route depending on activity data
	 * * client Individual page if customer is individual
	 * * client Business page if customer is business
	 * * Main Activity page if activity has no customer or inaccessible
	 */
	private getRoute(activity: ActivityModel): CommandRoute {
		const customerId = activity.Client
			? activity.Client.PrimaryCustomerId || activity.Client.CustomerId
			: 0;
		const activityId = activity.ActivityId;

		if (
			R.isNil(activity.Client) ||
			!activity.Client.IsAccessible ||
			customerId === 0
		) {
			return this.routeService.activityEdit(activityId);
		} else if (activity.Client.IsCompany) {
			return this.routeService.businessActivity(customerId, activityId);
		} else {
			return this.routeService.customerActivity(customerId, activityId);
		}
	}

	/** getDueDate in string */
	private getDue(activity: ActivityModel): string {
		const datetime = MomentUtil.DateStringToMoment(activity.DueDate);
		return MomentUtil.formatToDisplayDate(datetime);
	}

	/** get overdue state whether green, orange, or red.
	 * @return equivalent class name
	 * * text-danger - for overdue
	 * * text-warning - if due in the next five days
	 * * text-success - if due today
	 */
	private getOverdueState(activity: ActivityModel): string {
		if (activity.DueDate) {
			const nzToday = MomentUtil.createMomentNz();
			const nzTodayPlus5Days = nzToday.clone().add(5, 'day');
			const date = MomentUtil.DateStringToMoment(activity.DueDate);

			const isSameDay = date.isSame(nzToday, 'day');
			const isPast = date.isBefore(nzToday, 'day');
			const isIn5Days = date.isBetween(nzToday, nzTodayPlus5Days, 'day');

			if (isSameDay) {
				return 'text-success';
			} else if (isPast) {
				return 'text-danger';
			} else if (isIn5Days) {
				return 'text-warning';
			}
		}
		return '';
	}

	/** Get client name in the activity */
	private getClientName(activity: ActivityModel): string {
		if (!activity.Client) {
			return '';
		} else {
			return activity.Client.Name;
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
