import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LRCommissionTracker } from './state/lrct.model';
import { LRCTService } from './state/lrct.service';
import { LRCTQuery } from './state/lrct.query';
import { DashboardQuery } from '../../state/dashboard.query';
import { filter, takeUntil, map } from 'rxjs/operators';

declare var require: any;

@Component({
  selector: 'app-lrct',
  templateUrl: './lrct.component.html',
  styleUrls: ['./lrct.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LrctComponent implements OnInit, OnDestroy {
  public static widgetCode = 'lrct';
  public static widgetName = 'L&R Commission Tracker';
  public static sizeX = 400;
  public static sizeY = 300;
  public static minSizeX = 400;
  public static minSizeY = 300;

  widgetCode = LrctComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();
  /**
   * LR Goal Track data
   */
  data$: Observable<LRCommissionTracker> = this.query.data$;

  currentFinancialYTD$ = this.data$.pipe(map(data => data.CurrentFinancialYTD));
  currentQuarter$ = this.data$.pipe(map(data => data.CurrentQuarter));
  currentMonth$ = this.data$.pipe(map(data => data.CurrentMonth));
  currentPipeline$ = this.data$.pipe(map(data => data.CurrentPipeline));
  lrLeadCount$ = this.data$.pipe(map(data => data.LRLeadCount));
  mortgageLeadCount$ = this.data$.pipe(map(data => data.MortgageLeadCount));

  /**
   * Indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;
  /**
   * Error message string. Show error if not empty.
   */
  error$ = this.query.error$;

  // fix
  // imageIcon = defaultIcon;

  constructor(private service: LRCTService, private query: LRCTQuery, private dashboardQuery: DashboardQuery) {}

  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetLRCommissionTracker(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
