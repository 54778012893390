import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { ApiService } from 'src/app/core/base/api.service';
import { BlanketNewBusinessGoalTrack } from './bnb.model';
import { BNBStore } from './bnb.store';

@Injectable()
export class BNBService {
	/**
	 * partial url endpoint
	 */
	private readonly endpoint = 'widgets?type=BNB';

	constructor(
		private api: ApiService,
		private store: BNBStore
	) { }


	/**
	 * Fetch and store
	 *
	 * @param adviserIdList
	 * Search widget data related to theses advisers
	 */
	public GetLRGoalTrack(adviserIdList: string[]): void {
		this.store.setLoading(true);
		this.api.post<BlanketNewBusinessGoalTrack>(this.endpoint, {
			Advisers: adviserIdList
		}).subscribe(
			res => this.store.update({ GoalTrack: res }),
			err => applyTransaction(() => {
				this.store.setError('Error fetching data!');
				this.store.setLoading(false);
			}),
			() => this.store.setLoading(false)
		);
	}
}
