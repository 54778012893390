import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { BusinessPageModel } from './business-page.model';
import produce from 'immer';
import { Injectable } from "@angular/core";

interface ColumnWidth {
	metakey: string;
	width: number;
}

export interface BusinessPageState extends EntityState<BusinessPageModel> {
	columns: string[];
	columnWidths: ColumnWidth[];
	count: number;

	isSearching: boolean;
	isLoading: boolean;
	isExporting: boolean;
	isColumnSaving: boolean;
	showAll: boolean;
	propSort: string;
	sort: string;
	toggleColumnsSetting: boolean;

	plQuarter: number;
	aiaQuarter: number;
	fidelityLifeQuarter: number;
	asteronQuarter: number;
	cignaLifeQuarter: number;
	blanketQuarter: number;
	totalMonth: number;
	totalQuarter: number;
	totalYear: number;
	noOfClients: number;
	noOfAdvisers: number;
	hasArchived: boolean;
}

const storeName = 'businessPage';
@Injectable()
@StoreConfig({ name: storeName, idKey: 'BusinessID' })
export class BusinessPageStore extends EntityStore<BusinessPageState, BusinessPageModel> {
	public static storeName = storeName;
	constructor() {
		super({
			columns: [],
			columnWidths: [],
			count: 0,

			isSearching: false,
			isLoading: false,
			isExporting: false,
			isColumnSaving: false,
			showAll: false,
			propSort: '',
			sort: '',
			toggleColumnsSetting: false,

			plMonth: 0,
			aiaMonth: 0,
			fidelityLifeMonth: 0,
			asteronMonth: 0,
			cignaLifeMonth: 0,
			blanketMonth: 0,
			totalMonth: 0,
			totalYear: 0,
			noOfClients: 0,
			noOfAdvisers: 0,

			hasArchived: false,
		});
	}

	setColumns(columns: string[]) {
		this.update(
			produce(x => {
				x.columns = columns;
			})
		);
	}

	setColumnWidths(columnWidths: ColumnWidth[]) {
		this.update(
			produce(x => {
				x.columnWidths = columnWidths;
			})
		);
	}

	setIsSearching(isSearching: boolean) {
		this.update(
			produce(x => {
				x.isSearching = isSearching;
			})
		);
	}

	setIsLoading(isLoading: boolean) {
		this.update(
			produce(x => {
				x.isLoading = isLoading;
			})
		);
	}

	setIsExporting(isExporting: boolean) {
		this.update(
			produce(x => {
				x.isExporting = isExporting;
			})
		);
	}

	setShowAll(showAll: boolean) {
		this.update(
			produce(x => {
				x.showAll = showAll;
			})
		);
	}

	setSort(propSort: string, sort: 'asc' | 'desc') {
		this.update(
			produce(draft => {
				draft.propSort = propSort;
				draft.sort = sort;
			})
		);
	}

	toggleColumnsSetting(toggleComunsSetting: boolean) {
		this.update(
			produce(x => {
				x.toggleColumnsSetting = toggleComunsSetting;
			})
		);
	}

	setTotal(
		plMonth: number,
		aiaMonth: number,
		fidelityLifeMonth: number,
		asteronMonth: number,
		cignaLifeMonth: number,
		blanketMonth: number,
		totalMonth: number,
		totalYear: number,
		noOfClients: number,
		noOfAdvisers: number
	) {
		this.update(
			produce(x => {
				x.plMonth = plMonth;
				x.aiaMonth = aiaMonth;
				(x.fidelityLifeMonth = fidelityLifeMonth),
					(x.asteronMonth = asteronMonth),
					(x.cignaLifeMonth = cignaLifeMonth),
					(x.blanketMonth = blanketMonth),
					(x.totalMonth = totalMonth);
				x.totalYear = totalYear;
				x.noOfClients = noOfClients;
				x.noOfAdvisers = noOfAdvisers;
			})
		);
	}
}
