import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { CRTNoteState } from 'src/app/shared/models/client-review-template/note/crt-note.model';
import { ActivityViewModel } from 'src/app/shared/models/_general/activity.viewmodel';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { CrtMortgageService } from '../../../crt-mortgage/state/crt-mortgage.service';
import { CrtMortgageNoteService } from '../../../crt-mortgage/state/note/crt-mortgage-note.service';
import { OnDestroy } from '@angular/core';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsStatus } from '@modules/exports/state/exports.model';

declare var $: any;

@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, OnDestroy {
	@Input() family = '';
	@Input() isToggleSidebar: boolean;
	@Input() appearance: 'default' | 'clean' = 'default';
	@Output() toggleSidebar = new EventEmitter<boolean>();
	@Input() oatType: 'MOAT' | 'KOAT' | 'LOAT';
	@Input() adviserId: any;

	private onDestroy$ = new Subject<void>();

	tabInit: string;

	isCollapsed = false;
	isNotes = false;

	crtTypes$ = this.crtNoteService.crtTypes$;
	crtNotesFactAndFind$ = this.crtNoteService.crtNotesFactFind$;
	activeType$ = this.crtNoteService.activeType$;

	isLead$: Observable<boolean> = this.crtService.primaryClient$.pipe(
		map((x: any) => x?.contactStatus === 'L')
	);

	customerId$: Observable<number> = this.crtService.primaryClient$.pipe(
		map((x: any) => x.customerID)
	);

	customerName$: Observable<string> = this.crtService.primaryClient$.pipe(
		map((x: any) =>
			x.customerType === CustomerTypes.PrimaryCustomerIndividual
				? `${x.firstName} ${x.lastName}`
				: `${x.companyName ?? ''}`
		)
	);

	AT$ = this.crtService.AT$;
	AM$ = this.crtService.AM$;

	activityAdviserChoices$ = this.crtNoteService.activityAdviserChoices$;
	adviserCalendarChoices$ = this.crtNoteService.adviserCalendarChoices$;
	allActiveStaffs$ = this.blStaffsQuery.allActiveStaffs$;

	customerLocation$: Observable<string> = this.crtService.primaryClient$.pipe(
		map((x: any) => x?.physicalAddress ?? null)
	);

	staffId$ = this.crtService.adviceProcess$.pipe(map((x) => x.adviser));

	isExporting = false;
	adviceProcessId = +this.route.snapshot.paramMap.get('adviceProcessId');

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private crtNoteService: CrtMortgageNoteService,
		private crtService: CrtMortgageService,
		private activityService: ActivityService,
		private blStaffsQuery: BLStaffsQuery,
		private exportsQuery: ExportsQuery
	) {}

	addCrtNote$ = (notes: string, type: string) =>
		this.crtNoteService.addNote(notes, type, this.adviceProcessId);
	updateCrtNote$ = (note: CRTNoteState) => this.crtNoteService.updateNote(note);
	deleteCrtNote$ = (id: number) => this.crtNoteService.deleteNote(id);

	addActivity$ = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToAdd(a)),
			mergeMap((x) => this.activityService.Post(x))
		);
	};

	exportCrtNotes$ = (req) => this.crtNoteService.export(req);

	ngOnInit(): void {
		this.tabInit = this.getType();
		this.crtNoteService.setActiveType(this.tabInit);
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.getType()),
				takeUntil(this.onDestroy$)
			)
			.subscribe((type: string | null) => {
				this.setCrtNotesType(type);
			});

		this.exportsQuery.status$.pipe(
			takeUntil(this.onDestroy$)
		).subscribe((status) => {
			if (status === ExportsStatus.STARTED || status === ExportsStatus.COMPLETE ) {
				this.isExporting = true;
			}
		});
	}

	setCrtNotesType(type?: string) {
		this.crtNoteService.setActiveType(type);
	}

	getType() {
		let child = this.route.firstChild;
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else if (child.snapshot.data && child.snapshot.data.type) {
				return child.snapshot.data.type;
			} else {
				return '';
			}
		}
	}

	toggle() {
		this.isToggleSidebar = !this.isToggleSidebar;
		this.toggleSidebar.emit(this.isToggleSidebar);
	}

	toggleCollapsePanel() {
		this.isCollapsed = !this.isCollapsed;
		if (this.isCollapsed) {
			this.isNotes = true;
		} else {
			this.isNotes = false;
		}

		this.crtNoteService.setNoteIsCollapsed(this.isNotes);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
