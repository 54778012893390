import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateRange } from '../../../../../shared/date-range/date-range.component';
import { HorizontalBarChartComponent } from '@shared/horizontal-bar-chart/horizontal-bar-chart.component';
import {
	Observable,
	Subscription,
	filter,
	map,
	startWith,
	takeUntil,
} from 'rxjs';
import { combineLatest, delay } from 'rxjs/operators';
import { EBLRService } from './state/eblr.service';
import { EBLRQuery } from './state/eblr.query';
import { DashboardQuery } from '../../state/dashboard.query';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { ComponentBase } from '@core/base/component-base';
import MomentUtil from '@util/moment.util';
import { numUtil } from '@util/util';
import { ServicesCodes } from '@shared/models/services/services.model';

@Component({
	selector: 'app-eblr',
	templateUrl: './eblr.component.html',
	styleUrls: ['./eblr.component.scss'],
})
export class EblrComponent
	extends ComponentBase
	implements OnInit, AfterContentChecked, OnDestroy
{
	public static widgetCode = 'eblr';
	public static widgetName = 'E.B. L&R Opportunity';
	public static sizeX = 480;
	public static sizeY = 480;
	public static minSizeX = 480;
	public static minSizeY = 480;
	public static maxSizeX = 480;
	public static maxSizeY = 480;

	private subs: Subscription[] = [];

	widgetCode = EblrComponent.widgetCode;
	widgetName = EblrComponent.widgetName;

	lrLegend = ['Policy Issued', 'In Progress', 'NCR', 'NTU'];
	ksLegend = ['Completed', 'In Progress', 'No Advice', 'NTU'];

	lrOpportunity$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x?.find((i) => i?.Type === ServicesCodes.LR)),
		map((x) => ({
			Target: x?.Target,
			Data: [
				Math.round(x?.PolicyIssued) || 0,
				Math.round(x?.InProgress) || 0,
				Math.round(x?.NCR) || 0,
				Math.round(x?.NTU) || 0,
			],
			Tooltip: [
				`Policy Issued (${x?.PolicyIssuedCount || 0})`,
				`In Progress (${x?.InProgressCount || 0})`,
				`NCR (${x?.NCRCount || 0})`,
				`NTU (${x?.NTUCount || 0})`,
			],
			Total: Math.round(x?.Total),
		}))
	);
	ksOpportunity$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x?.find((i) => i?.Type === ServicesCodes.KiwiSaver)),
		map((x) => ({
			Target: x?.Target,
			Data: [
				Math.round(x?.Completed) || 0,
				Math.round(x?.InProgress) || 0,
				Math.round(x?.NoAdvice) || 0,
				Math.round(x?.NTU) || 0,
			],
			Tooltip: [
				`Completed (${x?.CompletedCount || 0})`,
				`In Progress (${x?.InProgressCount || 0})`,
				`No Advice (${x?.NoAdviceCount || 0})`,
				`NTU (${x?.NTUCount || 0})`,
			],
			Total: Math.round(x?.Total),
		}))
	);

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.isLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.error$;

	/** form control for date range.
	 * Should default to 1 week behind up to now.
	 */
	dateControl: UntypedFormControl = new UntypedFormControl({
		min: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().subtract(7, 'months')
		),
		max: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().subtract(1, 'months')
		),
	} as DateRange);

	@ViewChild(HorizontalBarChartComponent)
	hbarChart: HorizontalBarChartComponent;

	constructor(
		private service: EBLRService,
		private query: EBLRQuery,
		private dashboardQuery: DashboardQuery,
		private dashboard: DashboardComponent,
		private cd: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit() {
		this.subs.push(
			this.dashboardQuery.adviserFilter$
				.pipe(
					filter((x) => x.length > 0),
					combineLatest(
						this.dateControl.valueChanges.pipe(
							startWith(this.dateControl.value)
						)
					),
					takeUntil(this.onDestroy$)
				)
				.subscribe(([advisers, dateRange]) => {
					this.service.GetEBLROpportunity({
						advisers,
						dateRangeMin: dateRange.min,
						dateRangeMax: dateRange.max,
					});
				})
		);

		this.subs.push(
			this.dashboard.gridChangeEvent.pipe(delay(200)).subscribe((res) => {
				if (res === EblrComponent.widgetCode) {
					this.hbarChart.refresh();
				}
			})
		);
	}

	ngAfterContentChecked() {
		this.cd.detectChanges();
	}

	getLegendColor = (i: number) => this.hbarChart?.barColorMap?.get(i) || '#fff';

	ngOnDestroy() {
		this.subs?.forEach((x) => x.unsubscribe);
		this.subs = [];
		super.dispose();
	}
}
