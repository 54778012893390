<form [formGroup]="form" novalidate>
	<div class="tap-advice-process-step-wizard d-flex flex-wrap align-items-center clearfix">
		<div
			*ngFor="let s of steps; let i = index; trackBy: trackByFn"
			class="tap-advice-process-step-wizard__step d-flex align-items-center justify-content-center py-0"
			[class.active]="!!s.value || (!s.value && isLessThanLasIndexWithValue(i))"
			[class.complete-btn]="isEditable && isCompleteBtn(i)"
			[class.inprogress-btn]="!isEditable && isInProgressBtn(i)"
		>
			<span class="tap-advice-process-step-wizard__step__header">{{ s.stage }}</span>
			<span class="tap-advice-process-step-wizard__step__info d-flex align-items-center justify-content-center">
				<div
					class="d-flex align-items-center justify-content-center w-100 h-100"
					[class.clickable]="(isEditable && !!s.value) || (isEditable && isCompleteBtn(i))"
					(click)="complete(s.formControl, isCompleteBtn(i))"
				>
					<ng-container *ngIf="!!s?.value || (!s.value && isLessThanLasIndexWithValue(i))">
						<app-date-input
							[formControl]="form.controls[s.formControl]"
							textboxId="ad_step_date_{{ i }}"
							ngProjectAs="input"
							textboxClass="theme-crm-field-disabled"
							[placeholder]="isEditable ? 'dd/mm/yyyy' : ''"
							[showButton]="false"
							(onChangeEvent)="updateStepDate(i, form.controls[s.formControl])"
						>
						</app-date-input>
					</ng-container>
          <ng-container *ngIf="isEditable && !s.value && isCompleteBtn(i)"> Complete? </ng-container>
					<ng-container *ngIf="!isEditable && isInProgressBtn(i)"> In Progress </ng-container>
				</div>
				<button
          *ngIf="isEditable && !!s?.value"
					(click)="clearValue(s.formControl)"
					class="clear-button icon-btn text-white d-flex align-items-center p-0 w-auto h-auto"
				>
					<i class="material-icons md-16">close</i>
				</button>
			</span>
			<span class="tap-advice-process-step-wizard__step__footer">{{
				daysDiff(i) !== null ? daysDiff(i) + ' ' + (daysDiff(i) > 1 || daysDiff(i) < -1 ? 'days' : 'day') : ''
			}}</span>
		</div>
	</div>
</form>
