import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLinebreak',
  standalone: true
})
export class LineBreakPipe implements PipeTransform {

  transform(value: string): string {
    if(value.includes('\n')) {
      return value.replace(/\n/g, '<br/>');
    }
    if(value.includes('&amp;')) {
      return value.replace(/&amp;/g, '<br/>');
    }
    return value;
  }

}
