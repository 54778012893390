import { Store, StoreConfig } from '@datorama/akita';
import { IntroSettingsState } from '../intro-settings/state/kiwi-intro-settings.model';
import { KiwiScopeOfServiceState } from '../scope-of-service-settings/state/kiwi-scope-of-service.model'
import { KiwiSosTemplateSettingsState } from '../scope-of-service-settings/sos-template-settings/state/kiwi-sos-template-settings.model';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { FactFindSubpagesSettingsState } from '../fact-find-settings/subpages/state/fact-find-subpages-settings.model';
import { FactFindRiskProfileQASettingsState, FactFindRiskProfileOutcomesSettingsState } from '../fact-find-settings/risk-profile/state/fact-find-risk-profile-settings.model';
import { AdviceSummarySettingsState } from '../advice-summary-settings/state/advice-summary-settings.model';
import { AdviceSummaryEmailSettingsState } from '../advice-summary-settings/advice-summary-email-settings/state/advice-summary-email-settings.model'
import { DocumentListState } from '../advice-summary-settings/document-list/state/document-list.model';
import { EndProcessEmailSettingsState } from '../end-process/state/end-process.model';
import { ProvidersSettingsState } from '../fact-find-settings/providers/state/providers-settings.model';
import { Injectable } from '@angular/core';
import { DropdownValue } from '@modules/dropdown/model/dropdownValue';
import { DeclarationEmailSettingsState } from '../declaration-settings/declaration-email-settings/state/declaration-email-settings.model';
import { DeclarationSettingState } from '../declaration-settings/declaration-template-settings/state/declaration-template-settings.model';

export interface KiwiSaverSettingsState {
	mergeTags: MergeTagState[] | null,
	introSettings: IntroSettingsState | null;
	scopeOfService: KiwiScopeOfServiceState | null;
	declaration: DeclarationSettingState;
	declarationEmailSettings: DeclarationEmailSettingsState;
	scopeOfServiceTemplateSettings: KiwiSosTemplateSettingsState | null,
	factFindSubpagesSettings: FactFindSubpagesSettingsState | null,
	factFindRiskProfileQASettings: FactFindRiskProfileQASettingsState | null,
	factFindRiskProfileOutcomesSettings: FactFindRiskProfileOutcomesSettingsState | null,
	adviceSummarySettings: AdviceSummarySettingsState | null;
	adviceSummaryEmailSettings: AdviceSummaryEmailSettingsState | null;
	documentList: DocumentListState[];
	endProcessEmailSettings: EndProcessEmailSettingsState;
	providersSettings: ProvidersSettingsState | null;
	providerDropdowns: DropdownValue[],
}

export function createInitialState(): KiwiSaverSettingsState {
	return {
		mergeTags: [],
		introSettings: null,
		scopeOfService: null,
		declaration: null,
		declarationEmailSettings: null,
		scopeOfServiceTemplateSettings: null,
		factFindSubpagesSettings: null,
		factFindRiskProfileQASettings: null,
		factFindRiskProfileOutcomesSettings: null,
		adviceSummarySettings: null,
		adviceSummaryEmailSettings: null,
		documentList: [],
		endProcessEmailSettings: null,
		providersSettings: null,
		providerDropdowns: []
	};
}

@Injectable()
@StoreConfig({ name: 'koatSettings' })
export class KiwiSaverSettingsStore extends Store<KiwiSaverSettingsState> {
	constructor() {
		super(createInitialState());
	}

	setMergeTags(data: MergeTagState[]) {
		this.update({ mergeTags: data });
	}

	setIntroSettings(data: IntroSettingsState) {
		this.update({ introSettings: data });
	}

	setScopeOfService(data: KiwiScopeOfServiceState) {
		this.update({ scopeOfService: data });
	}

	setDeclarationSettings(data: DeclarationSettingState) {
		this.update({ declaration: data });
	}

	setDeclarationEmailSettings(data: DeclarationEmailSettingsState) {
		this.update({ declarationEmailSettings: data });
	}

	setScopeOfServiceTemplateSettings(data: KiwiSosTemplateSettingsState) {
		this.update({ scopeOfServiceTemplateSettings: data });
	}

	setFactFindSubpagesSettings(data: FactFindSubpagesSettingsState) {
		this.update({ factFindSubpagesSettings: data });
	}

	setFactFindRiskProfileQASettings(data: FactFindRiskProfileQASettingsState) {
		this.update({ factFindRiskProfileQASettings: data });
	}

	setFactFindRiskProfileOutcomesSettings(data: FactFindRiskProfileOutcomesSettingsState) {
		this.update({ factFindRiskProfileOutcomesSettings: data });
	}

	setAdviceSummarySettings(data: AdviceSummarySettingsState) {
		this.update({ adviceSummarySettings: data });
	}

	setAdviceSummaryEmailSettings(data: AdviceSummaryEmailSettingsState) {
		this.update({ adviceSummaryEmailSettings: data });
	}

	setDocumentList(data: DocumentListState[]) {
		this.update({ documentList: data });
	}

	setEndProcessEmailSettings(data: EndProcessEmailSettingsState) {
		this.update({ endProcessEmailSettings: data });
	}

	setProvidersSettings(data: ProvidersSettingsState) {
		this.update({ providersSettings: data });
	}

	setProviderDropdowns(data: DropdownValue[]) {
		this.update({ providerDropdowns: data });
	}
}
