import { TableColumn } from '@swimlane/ngx-datatable';
import produce from 'immer';
import * as R from 'ramda';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { Row } from './states/lr-insurance.model';
import { LrInsurance } from './lr-insurance-request.model';
import { computeUtil, util } from '../../../../util/util';
import { datatableUtil } from 'src/app/util/datatable.util';

/**
 * possible metakeys
 */
export type Metakey =
	| 'Policy Owners'
	| 'Policy Status'
	| 'Premium'
	| 'Adviser'
	| 'LR Adviser'
	| 'GI Adviser'
	| 'Policy Number'
	| 'Submitted Date'
	| 'LR Status Clock'
	| 'Client Next Activity'
	| 'User Next Activity'
	| 'Required'
	| 'Policy Claim Status'
	| 'Name'
	| 'Anniversary Date'
	| 'Cancellation Code'
	| 'Cancellation Date'
	| 'Commission'
	| 'Contact Status'
	| 'Policy Discount'
	| 'Double Sub'
	| 'Mobile'
	| 'Email'
	| 'End Trans'
	| 'Exclusions'
	| 'Original Adviser'
	| 'Payment Frequency'
	| 'Policy Type'
	| 'Products'
	| 'Provider'
	| 'ReplacementText'
	| 'Policy Retention Status'
	| 'Start Date'
	| 'Start Trans'
	| 'Last Note'
	| 'Additional Contacts'
	| 'Lead Origin'
	| 'Retention Clock'
	| 'Submitted API';

/** possible control types. Move this somewhere more appropriate. */
export type controlType =
	| 'display'
	| 'long-display'
	| 'textbox'
	| 'dropdown'
	| 'note'
	| 'date'
	| 'address'
	| 'activity'
	| 'multiselect'
	| 'textarea'
	| 'money'
	| 'checkbox';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
export type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof Row;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (
		field: FieldMetadata<any>,
		choices?: ViewDisplayValue[],
		row?: Row
	) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
	isTwoDecimal?: boolean;
	columnId?: string;
	fieldId?: string;
};

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return R.propOr<string, ViewDisplayValue, string>(
		'',
		'display' as keyof FieldMetadata<any>,
		choiceItem
	);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceIndex = choices?.findIndex((x) => x.value === field.value);
	return choiceIndex > -1 ? choiceIndex : undefined;
};
/** Get multi value display */
const getMultiselectValues = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value || field.value === '[]' || field.value.length === 0) {
		return '';
	}
	const values: string[] =
		typeof field.value === 'string'
			? Array.isArray(JSON.parse(field.value))
				? JSON.parse(field.value)
				: []
			: [];
	return values
		?.map((v) =>
			R.propOr(
				'',
				'display',
				choices?.find((c) => `${c.value}` === `${v}`)
			)
		)
		?.filter((x) => x)
		?.join(', ');
};

const contactStatus = (value: string) => {
	switch (value) {
		case 'L':
			return 'Lead';
		case 'C':
			return 'Client';
		case 'X':
			return 'Ex-Client';
	}
};

const statusClock = (field: FieldMetadata<any>) => {
	const arr = field.value?.split(' ');
	const timePeriod = arr?.pop();
	return timePeriod === 'year' || timePeriod === 'years'
		? +(arr[0] * 365)
		: R.complement(R.isEmpty)(field.value)
		? +arr[0]
		: '';
};

/** get sort value for checkboxes */
const getCheckboxSortValue = (field: FieldMetadata<any>) => {
	const isTrue: boolean =
		field.value === true ||
		(typeof field.value === typeof '' && field.value?.toLowerCase() === 'true');
	return R.defaultTo('', isTrue ? '1' : '2');
};
/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
	const b = R.defaultTo('', date)?.split(/\D/);
	return b?.reverse().join('/');
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
	const splicedDate = R.defaultTo('', note).slice(0, 10);
	return reformatDateString(splicedDate);
};
/**
 * possible columns with their own configurations
 */
export const columns: EnhancedTableColumn[] = [
	// Default columns to show
	{
		metakey: 'Policy Owners',
		prop: 'PolicyOwners',
		name: 'Policy Owners',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c, r) => {
			const isEmpty = util.isNullOrEmpty(f.value);
			const noChoices = util.isNullOrEmpty(r.PolicyOwnersList);
			if (isEmpty || noChoices) {
				return undefined;
			}

			const customChoices = R.uniq(
				r.PolicyOwnersList?.map((po) =>
					ViewDisplayValue.Map(po.Id?.toString(), po.Name)
				)
			);
			return getMultiselectValues(customChoices, f);
		},
		controlType: 'multiselect',
		columnId: datatableUtil.formatColumnId('Policy Owners'),
		fieldId: datatableUtil.formatFieldId('Policy Owners'),
	},
	{
		metakey: 'Policy Status',
		prop: 'PolicyStatus',
		name: 'Policy Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Policy Status'),
		fieldId: datatableUtil.formatFieldId('Policy Status'),
	},
	{
		metakey: 'Premium',
		prop: 'Premium',
		name: 'API',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		isTwoDecimal: false,
		columnId: datatableUtil.formatColumnId('Premium'),
		fieldId: datatableUtil.formatFieldId('Premium'),
	},
	{
		metakey: 'Adviser',
		prop: 'Adviser',
		name: 'Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser'),
	},
	// Adviser Rework
	{
		metakey: 'LR Adviser',
		prop: 'LRAdviser',
		name: 'L&R Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('L&R Adviser'),
		fieldId: datatableUtil.formatFieldId('L&R Adviser'),
	},
	// Adviser Rework
	{
		metakey: 'GI Adviser',
		prop: 'GroupInsuranceAdviser',
		name: 'Group Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Group Adviser'),
		fieldId: datatableUtil.formatFieldId('Group Adviser'),
	},
	{
		metakey: 'Policy Number',
		prop: 'PolicyNumber',
		name: 'Policy Number',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			!!f?.value ? f?.value?.split('-').join('.') : undefined,
		controlType: 'textbox',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Policy Number'),
		fieldId: datatableUtil.formatFieldId('Policy Number'),
	},
	{
		metakey: 'Submitted Date',
		prop: 'SubmittedDate',
		name: 'Submitted Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Submitted Date'),
		fieldId: datatableUtil.formatFieldId('Submitted Date'),
	},
	{
		metakey: 'LR Status Clock',
		prop: 'StatusClock',
		name: 'Status Clock',
		width: 100,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => statusClock(f),
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('LR Status Clock'),
		fieldId: datatableUtil.formatFieldId('LR Status Clock'),
	},
	{
		metakey: 'Client Next Activity',
		prop: 'ClientNextActivity',
		name: 'Client Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('Client Next Activity'),
		fieldId: datatableUtil.formatFieldId('Client Next Activity'),
	},
	{
		metakey: 'User Next Activity',
		prop: 'UserNextActivity',
		name: 'Your Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('User Next Activity'),
		fieldId: datatableUtil.formatFieldId('User Next Activity'),
	},
	{
		metakey: 'Required',
		prop: 'Required',
		name: 'Required',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textarea',
		columnId: datatableUtil.formatColumnId('Required'),
		fieldId: datatableUtil.formatFieldId('Required'),
	},
	{
		metakey: 'Policy Claim Status',
		prop: 'PolicyClaimStatus',
		name: 'Claim Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Policy Claim Status'),
		fieldId: datatableUtil.formatFieldId('Policy Claim Status'),
	},
	// Other columns
	{
		metakey: 'Name',
		prop: 'Name',
		name: 'Client Name',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		cellClass: 'font-weight-bold fixed-column',
		columnId: datatableUtil.formatColumnId('Name'),
		fieldId: datatableUtil.formatFieldId('Name'),
	},
	{
		metakey: 'Anniversary Date',
		prop: 'AnniversaryDate',
		name: 'Anniversary Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Anniversary Date'),
		fieldId: datatableUtil.formatFieldId('Anniversary Date'),
	},
	{
		metakey: 'Cancellation Code',
		prop: 'CancellationCode',
		name: 'Cancellation Code',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Cancellation Code'),
		fieldId: datatableUtil.formatFieldId('Cancellation Code'),
	},
	{
		metakey: 'Cancellation Date',
		prop: 'CancellationDate',
		name: 'Cancellation Date',
		width: 200,
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Cancellation Date'),
		fieldId: datatableUtil.formatFieldId('Cancellation Date'),
	},
	{
		metakey: 'Commission',
		prop: 'Commission',
		name: 'Commission',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isTwoDecimal: true,
		columnId: datatableUtil.formatColumnId('Commission'),
		fieldId: datatableUtil.formatFieldId('Commission'),
	},
	{
		metakey: 'Contact Status',
		prop: 'ContactStatus',
		name: 'Contact Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Contact Status'),
		fieldId: datatableUtil.formatFieldId('Contact Status'),
	},
	{
		metakey: 'Policy Discount',
		prop: 'PolicyDiscount',
		name: 'Discount',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Policy Discount'),
		fieldId: datatableUtil.formatFieldId('Policy Discount'),
	},
	{
		metakey: 'Double Sub',
		prop: 'DoubleSub',
		name: 'Double Sub',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getCheckboxSortValue(f),
		controlType: 'checkbox',
		columnId: datatableUtil.formatColumnId('Double Sub'),
		fieldId: datatableUtil.formatFieldId('Double Sub'),
	},
	{
		metakey: 'Email',
		prop: 'Email',
		name: 'Email',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('Email'),
		fieldId: datatableUtil.formatFieldId('Email'),
	},
	{
		metakey: 'Mobile',
		prop: 'Mobile',
		name: 'Mobile',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('Mobile'),
		fieldId: datatableUtil.formatFieldId('Mobile'),
	},
	{
		metakey: 'End Trans',
		prop: 'EndTrans',
		name: 'End Trans',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('End Trans'),
		fieldId: datatableUtil.formatFieldId('End Trans'),
	},
	{
		metakey: 'Exclusions',
		prop: 'Exclusions',
		name: 'Exclusions',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textarea',
		columnId: datatableUtil.formatColumnId('Exclusions'),
		fieldId: datatableUtil.formatFieldId('Exclusions'),
	},
	{
		metakey: 'Original Adviser',
		prop: 'OriginalAdviser',
		name: 'Original Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Original Adviser'),
		fieldId: datatableUtil.formatFieldId('Original Adviser'),
	},
	{
		metakey: 'Payment Frequency',
		prop: 'PaymentFrequency',
		name: 'Payment',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Payment Frequency'),
		fieldId: datatableUtil.formatFieldId('Payment Frequency'),
	},
	{
		metakey: 'Policy Type',
		prop: 'PolicyType',
		name: 'Policy Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Policy Type'),
		fieldId: datatableUtil.formatFieldId('Policy Type'),
	},
	{
		metakey: 'Products',
		prop: 'Products',
		name: 'Products',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getMultiselectValues(c, f),
		controlType: 'multiselect',
		columnId: datatableUtil.formatColumnId('Products'),
		fieldId: datatableUtil.formatFieldId('Products'),
	},
	{
		metakey: 'Provider',
		prop: 'Provider',
		name: 'Provider',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Provider'),
		fieldId: datatableUtil.formatFieldId('Provider'),
	},
	{
		metakey: 'ReplacementText',
		prop: 'ReplacementText',
		name: 'Replacement',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('ReplacementText'),
		fieldId: datatableUtil.formatFieldId('ReplacementText'),
	},
	{
		metakey: 'Policy Retention Status',
		prop: 'PolicyRetentionStatus',
		name: 'Retention Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Policy Retention Status'),
		fieldId: datatableUtil.formatFieldId('Policy Retention Status'),
	},
	{
		metakey: 'Start Date',
		prop: 'StartDate',
		name: 'Start Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Start Date'),
		fieldId: datatableUtil.formatFieldId('Start Date'),
	},
	{
		metakey: 'Start Trans',
		prop: 'StartTrans',
		name: 'Start Trans',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Start Trans'),
		fieldId: datatableUtil.formatFieldId('Start Trans'),
	},
	{
		metakey: 'Last Note',
		prop: 'LastNote',
		name: 'Last Note',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'note',
		columnId: datatableUtil.formatColumnId('Last Note'),
		fieldId: datatableUtil.formatFieldId('Last Note'),
	},
	{
		metakey: 'Additional Contacts',
		prop: 'AdditionalContact',
		name: 'Additional Contacts',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'long-display',
		columnId: datatableUtil.formatColumnId('Additional Contacts'),
		fieldId: datatableUtil.formatFieldId('Additional Contacts'),
	},
	{
		metakey: 'Lead Origin',
		prop: 'LeadOrigin',
		name: 'Lead Origin',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Lead Origin'),
		fieldId: datatableUtil.formatFieldId('Lead Origin'),
	},
	{
		metakey: 'Retention Clock',
		prop: 'RetentionClock',
		name: 'Retention Clock',
		width: 100,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => statusClock(f),
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Retention Clock'),
		fieldId: datatableUtil.formatFieldId('Retention Clock'),
	},
	{
		metakey: 'Submitted API',
		prop: 'SubmittedAPI',
		name: 'Submitted API',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		isTwoDecimal: false,
		columnId: datatableUtil.formatColumnId('Submitted API'),
		fieldId: datatableUtil.formatFieldId('Submitted API'),
	},
];
/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 18,
};
/**
 * template for Row
 */
export const templateRow: Row = {
	CustomerID: null,
	CustomerServiceID: null,
	PolicyOwnersList: null,
	ClientNextActivityId: null,
	UserNextActivityId: null,
	PolicyOwners: {
		metakey: 'Policy Owners',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Owners').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FEC'],
	},
	PolicyStatus: {
		metakey: 'Policy Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Status').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Premium: {
		metakey: 'Premium',
		value: '0.0000',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Premium').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Adviser: {
		metakey: 'Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Adviser').prop,
		required: true,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	// Adviser Rework
	LRAdviser: {
		metakey: 'LR Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'LR Adviser').prop,
		required: true,
		editable: false,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	GroupInsuranceAdviser: {
		metakey: 'GI Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'GI Adviser').prop,
		required: true,
		editable: false,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	PolicyNumber: {
		metakey: 'Policy Number',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Number').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	SubmittedDate: {
		metakey: 'Submitted Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Submitted Date').prop,
		required: false,
		editable: true,
		restrict: ['FES'],
	},
	StatusClock: {
		metakey: 'LRStatus Clock',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'LR Status Clock').prop,
		required: false,
		editable: false,
	},
	Required: {
		metakey: 'Required',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Required').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	PolicyClaimStatus: {
		metakey: 'Claim Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Claim Status').prop,
		required: false,
		editable: true,
		restrict: ['FES'],
	},
	// Other columns
	Name: {
		metakey: 'Name',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Name').prop,
		required: false,
		editable: false,
	},
	AnniversaryDate: {
		metakey: 'AnniversaryDate',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Anniversary Date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	CancellationCode: {
		metakey: 'Cancellation Code',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Cancellation Code').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	CancellationDate: {
		metakey: 'Cancellation Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Cancellation Date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Commission: {
		metakey: 'Commission',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Commission').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	ContactStatus: {
		metakey: 'Contact Status',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Contact Status').prop,
		required: false,
		editable: false,
	},
	PolicyDiscount: {
		metakey: 'Policy Discount',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Discount').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	DoubleSub: {
		metakey: 'Double Sub',
		value: null,
		choices: [],
		controlType: 'checkbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Double Sub').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Mobile: {
		metakey: 'Mobile',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Mobile').prop,
		required: false,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	Email: {
		metakey: 'Email',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Email').prop,
		required: false,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	EndTrans: {
		metakey: 'End Trans',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'End Trans').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Exclusions: {
		metakey: 'Exclusions',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Exclusions').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	OriginalAdviser: {
		metakey: 'Original Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Original Adviser').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FEO'],
	},
	PaymentFrequency: {
		metakey: 'Payment Frequency',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Payment Frequency').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	PolicyType: {
		metakey: 'Policy Type',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Type').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Products: {
		metakey: 'Products',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Products').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	Provider: {
		metakey: 'Provider',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Provider').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	ReplacementText: {
		metakey: 'ReplacementText',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'ReplacementText').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		// restrict: ['FES'],
	},
	PolicyRetentionStatus: {
		metakey: 'Policy Retention Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Retention Status').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	StartTrans: {
		metakey: 'Start Trans',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Start Trans').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	StartDate: {
		metakey: 'Start Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Start Date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
	},
	LastNote: {
		metakey: 'Last Note',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Last Note').prop,
		required: false,
		editable: true,
		hasToolTip: true,
		restrict: ['FAN'],
	},
	ClientNextActivity: {
		metakey: 'Client Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Client Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	UserNextActivity: {
		metakey: 'User Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'User Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	AdditionalContact: {
		metakey: 'Additional Contacts',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Additional Contacts').prop,
		required: false,
		editable: false,
		hasToolTip: true,
	},
	LeadOrigin: {
		metakey: 'Lead Origin',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Lead Origin').prop,
		required: false,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	RetentionClock: {
		metakey: 'Retention Clock',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Retention Clock').prop,
		required: false,
		editable: false,
	},
	SubmittedAPI: {
		metakey: 'Submitted API',
		value: '0.0000',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Submitted API').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	// Floating action buttons
	link: null,
};

export const createRowPrototypeWithChoices = (
	advisers: ViewDisplayValue[],
	nextActivtyType: ViewDisplayValue[],
	cancellationCode: ViewDisplayValue[],
	discount: ViewDisplayValue[],
	payment: ViewDisplayValue[],
	policyStatus: ViewDisplayValue[],
	policyType: ViewDisplayValue[],
	retentionStatus: ViewDisplayValue[],
	provider: ViewDisplayValue[],
	products: ViewDisplayValue[],
	claimStatus: ViewDisplayValue[],
	allAdvisers: ViewDisplayValue[]
) => {
	return produce(templateRow, (draft) => {
		draft.Adviser.choices = advisers;
		draft.ClientNextActivity.choices = nextActivtyType;
		draft.UserNextActivity.choices = nextActivtyType;
		draft.CancellationCode.choices = cancellationCode;
		draft.PolicyDiscount.choices = discount;
		draft.PaymentFrequency.choices = payment;
		draft.PolicyStatus.choices = policyStatus;
		draft.PolicyType.choices = policyType;
		draft.PolicyRetentionStatus.choices = retentionStatus;
		draft.Provider.choices = provider;
		draft.Products.choices = products;
		draft.PolicyClaimStatus.choices = claimStatus;
		draft.OriginalAdviser.choices = allAdvisers;
	});
};

export const createRowFromPrototype = R.curry(
	(template: Row, row: LrInsurance) =>
		produce<Row>(template, (draft) => {
			draft.CustomerID = row.CustomerID;
			draft.CustomerServiceID = row.CustomerServiceID;
			draft.PolicyOwnersList = row.PolicyOwnersList;
			draft.ClientNextActivityId = row.ClientNextActivityId;
			draft.UserNextActivityId = row.UserNextActivityId;

			draft.PolicyOwners.metakey = 'Policy Owners';
			draft.PolicyOwners.value = row.PolicyOwners;
			draft.PolicyOwners.choices = row.PolicyOwnersList?.map(
				(x: { Id: number; Name: string }) => ({
					display: x.Name,
					value: x.Id?.toString(),
				})
			) as ViewDisplayValue[];

			draft.PolicyStatus.metakey = 'Policy Status';
			draft.PolicyStatus.value = row.PolicyStatus;

			draft.Premium.metakey = 'Premium';
			draft.Premium.value = row.Premium
				? row.Premium === '0' || row.Premium === null
					? draft.Premium.value
					: row.Premium
				: draft.Premium.value;

			draft.Adviser.metakey = 'Adviser';
			draft.Adviser.value = row.Adviser;

			// Adviser Rework
			draft.LRAdviser.metakey = row.LRAdviser?.MetaKey || 'LR Adviser';
			draft.LRAdviser.value = row.LRAdviser;

			// Adviser Rework
			draft.GroupInsuranceAdviser.metakey =
				row.GroupInsuranceAdviser?.MetaKey || 'GI Adviser';
			draft.GroupInsuranceAdviser.value = row.GroupInsuranceAdviser;

			draft.PolicyNumber.metakey = 'Policy Number';
			draft.PolicyNumber.value = row.PolicyNumber;

			draft.SubmittedDate.metakey = 'Submitted Date';
			draft.SubmittedDate.value = row.SubmittedDate;

			draft.StatusClock.metakey = 'LR Status Clock';
			draft.StatusClock.value = computeUtil.calcStatusClock(row.StatusClock);

			draft.Required.metakey = 'Required';
			draft.Required.value = row.Required;

			draft.PolicyClaimStatus.metakey = 'Policy Claim Status';
			draft.PolicyClaimStatus.value = row.PolicyClaimStatus;

			// Other properties
			draft.Name.metakey = 'Name';
			draft.Name.value = row.Name;

			draft.AnniversaryDate.metakey = 'Anniversary Date';
			draft.AnniversaryDate.value = row.AnniversaryDate;

			draft.CancellationCode.metakey = 'Cancellation Code';
			draft.CancellationCode.value = row.CancellationCode;

			draft.CancellationDate.metakey = 'Cancellation Date';
			draft.CancellationDate.value = row.CancellationDate;

			draft.Commission.metakey = 'Commission';
			draft.Commission.value = row.Commission;

			draft.ContactStatus.metakey = 'Contact Status';
			draft.ContactStatus.value = contactStatus(row.ContactStatus);

			draft.PolicyDiscount.metakey = 'Policy Discount';
			draft.PolicyDiscount.value = row.PolicyDiscount;

			draft.DoubleSub.metakey = 'Double Sub';
			draft.DoubleSub.value = row.DoubleSub;

			draft.Mobile.metakey = 'Mobile';
			draft.Mobile.value = row.Mobile;

			draft.Email.metakey = 'Email';
			draft.Email.value = row.Email;

			draft.EndTrans.metakey = 'End Trans';
			draft.EndTrans.value = row.EndTrans;

			draft.Exclusions.metakey = 'Exclusions';
			draft.Exclusions.value = row.Exclusions;

			draft.ClientNextActivity.metakey = 'Client Next Activity';
			draft.ClientNextActivity.value = row.ClientNextActivity;

			draft.UserNextActivity.metakey = 'User Next Activity';
			draft.UserNextActivity.value = row.UserNextActivity;

			draft.OriginalAdviser.metakey = 'Original Adviser';
			draft.OriginalAdviser.value = row.OriginalAdviser;
			draft.OriginalAdviser.choices = draft.OriginalAdviser.choices?.filter(
				(x) =>
					x.value === row.OriginalAdviser ||
					draft.Adviser.choices?.find((y) => y.value === x.value)
			);

			draft.PaymentFrequency.metakey = 'Payment Frequency';
			draft.PaymentFrequency.value = row.PaymentFrequency;

			draft.PolicyType.metakey = 'Policy Type';
			draft.PolicyType.value = row.PolicyType;

			draft.Products.metakey = 'Products';
			draft.Products.value = row.Products;

			draft.Provider.metakey = 'Provider';
			draft.Provider.value = row.Provider;

			draft.ReplacementText.metakey = 'ReplacementText';
			draft.ReplacementText.value = row.ReplacementText;

			draft.PolicyRetentionStatus.metakey = 'Policy Retention Status';
			draft.PolicyRetentionStatus.value = row.PolicyRetentionStatus;

			draft.StartDate.metakey = 'Start Date';
			draft.StartDate.value = row.StartDate;

			draft.StartTrans.metakey = 'Start Trans';
			draft.StartTrans.value = row.StartTrans;

			draft.LastNote.metakey = 'Last Note';
			draft.LastNote.value = row.LastNote;

			draft.AdditionalContact.metakey = 'Additional Contacts';
			draft.AdditionalContact.value = row.AdditionalContact;

			draft.LeadOrigin.metakey = 'Lead Origin';
			draft.LeadOrigin.value = row.LeadOrigin;

			draft.RetentionClock.metakey = 'Retention Clock';
			draft.RetentionClock.value = computeUtil.calcStatusClock(
				row.RetentionClock
			);

			// Submitted API
			draft.SubmittedAPI.metakey = 'Submitted API';
			draft.SubmittedAPI.value = row.SubmittedAPI
				? row.SubmittedAPI === '0' || row.SubmittedAPI === null
					? draft.SubmittedAPI.value
					: row.SubmittedAPI
				: draft.SubmittedAPI.value;

			draft.link = {
				CustomerID: row.CustomerID,
				CustomerServiceID: row.CustomerServiceID,
				IsCompany: row.IsCompany,
			};
		})
);
