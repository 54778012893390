import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  map,
  withLatestFrom,
  distinctUntilChanged,
  combineLatest,
  auditTime,
  shareReplay,
  delay,
} from 'rxjs/operators';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import * as R from 'ramda';
import { Observable, of } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { mapToLookupObject } from '../../../../../domain/dropdown-value/dropdown-value.model';
import sort from 'fast-sort';
import { util } from '../../../../../util/util';
import { UserQuery } from '../../../../../domain/user/user.query';
import { AdviceProcessState, AdviceProcessStore } from './advice-process.store';
import { AdviceStatuses, Row } from './advice-process.model';
import { AdviceProcessUiQuery } from './advice-process-ui.query';
import {
  columns,
  EnhancedTableColumn,
  templateRow,
} from '../advice-process-datatable.config';

@Injectable()
export class AdviceProcessQuery extends QueryEntity<AdviceProcessState, Row> {
  private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
    map((x) =>
      R.map(
        (y) =>
          ViewDisplayValue.Map(
            y.StaffID?.toString(),
            `${y.FirstName} ${y.LastName}`
          ),
        x
      )
    ),
    map((x) => (x ? x.sort((a, b) => a.display.localeCompare(b.display)) : x))
  ); // Advisers
  private PCLE$ = this.dropdownQuery.orderedChoices$('PCLE'); // Lead Origin

  private APCRTAS$ = this.dropdownQuery.orderedChoices$('APCRTAS');
  private APCRTCT$ = this.dropdownQuery.orderedChoices$('APCRTCT');
  private APCRTCO$ = this.dropdownQuery.orderedChoices$('APCRTCO');
  private LS$ = this.dropdownQuery.orderedChoices$('LS');
  private CCO$ = this.dropdownQuery.orderedChoices$('CCO');

  private adviceStatuses$ = of([
    { display: AdviceStatuses.InProgress, value: AdviceStatuses.InProgress },
    { display: AdviceStatuses.EndedCompleted, value: AdviceStatuses.EndedCompleted },
    { display: AdviceStatuses.EndedIncomplete, value: AdviceStatuses.EndedIncomplete },
    { display: AdviceStatuses.Cancelled, value: AdviceStatuses.Cancelled },
  ]);

  columns$ = this.select((x) => x.columns);
  tableColumns$ = this.columns$
    .pipe(
      withLatestFrom(this.userQuery.isUserWithOptFields$),
      map(([tableColumns]) => {
        return tableColumns
          ?.map((col) => columns?.find((y) => y.metakey === col))
          ?.filter((x) => x !== undefined);
      }),
      withLatestFrom(this.select((x) => x.columnWidths)),
      map(([tableColumns, widths]) =>
        tableColumns?.map((column) => {
          const widthConf = widths
            ?.filter((x) => x)
            ?.find((width) => width.metakey === column.metakey);
          const newColumn = { ...column, $$id: column.name };
          if (widthConf) {
            return { ...newColumn, width: widthConf.width };
          } else {
            return newColumn;
          }
        })
      ),
      combineLatest(this.advisers$),
      auditTime(0),
      combineLatest(this.adviceStatuses$),
      combineLatest(this.APCRTAS$, this.APCRTCT$, this.APCRTCO$, this.LS$,this.CCO$),
    )
    .pipe(
      map(([[[tableColumns, advisers], as], adviceService, complaintOutcome, complaintType, leadStatuses, claimOutcomes]) => {
        return tableColumns?.map((c) => {
          switch (c.metakey) {
            case 'Adviser':
            case 'Reviewed By':
              return this.fillUpChoices(advisers, c);
            case 'Advice Status':
              return this.fillUpChoices(as, c);
            case 'Advice Service':
              return this.fillUpChoices(adviceService, c);
            case 'Complaint Outcome':
              return this.fillUpChoices(complaintOutcome, c);
            case 'Complaint Type':
              return this.fillUpChoices(complaintType, c);
            case 'Lead Status':
              return this.fillUpChoices(leadStatuses, c);
            case 'Claim Outcome':
              return this.fillUpChoices(claimOutcomes, c);
            default:
              return c;
          }
        });
      }),
      distinctUntilChanged((x, y) => R.equals(x, y)),
      shareReplay(1)
    );

  hiddenTableColumns$ = this.columns$.pipe(
    withLatestFrom(this.userQuery.isUserWithOptFields$),
    map(([tableColumns, isUserWithOptFields]) => {
      return columns?.filter((x) =>
        !isUserWithOptFields
          ? !tableColumns?.includes(x.metakey)
          : !tableColumns?.includes(x.metakey)
      );
    })
  );

  sorts$ = this.uiQuery.select((x) => [{ dir: x.sort, prop: x.propSort }]);
  cellsLoading$ = this.uiQuery.select((state) => state.cellLoadingStatus);
  cellsEditing$ = this.uiQuery.select((state) => state.cellEditStatus);
  cellsTempvalue$ = this.uiQuery.select((state) => state.cellTempValue);
  rowsLoading$ = this.uiQuery.select((state) => state.rowLoadingStatus);
  isSearching$ = this.uiQuery.select((state) => state.isSearching);
  allAdvisers$ = this.blstaffQuery.allStaffsChoices$;

  templateRow$: Observable<Row> = of(templateRow);

  rows$: Observable<Row[]> = this.selectAll();
  hasRows$: Observable<boolean> = this.selectCount().pipe(map((x) => x > 0));

  count$ = this.select((x) => x.count);
  searchForm$ = this.select((x) => x.searchForm);
  isComplete$ = this.select((x) => x.isComplete);

  sortedRows$ = this.uiQuery
    .select((x) => [x.propSort, x.sort])
    .pipe(
      distinctUntilChanged((p, q) => R.equals(p, q)),
      combineLatest(this.rows$),
      withLatestFrom(this.tableColumns$),
      withLatestFrom(this.isSearching$),
      withLatestFrom(this.allAdvisers$),
      delay(0),
      map(
        ([
          [[[[prop, sortDirection], rows], tableColumns], isSearching],
          allAdvisers,
        ]) => {
          if (sortDirection === '' || prop === '' || isSearching) {
            return rows;
          } else {
            const column = tableColumns?.find((x) => x.prop === prop);
            if (util.isNullOrEmpty(column)) {
              return rows;
            }

            const decorated = rows?.map<[number, any, number, Row]>((r) => {
              // tslint:disable-next-line: max-line-length
              const actualValue = column.sortValueGetter(
                r[prop],
                prop === 'Adviser' || prop === 'Reviewed By'
                  ? allAdvisers
                  : column.choices,
                r
              );
              return [
                this.spaceSortValueGetter(actualValue),
                R.defaultTo('', actualValue),
                r.AdviceProcessID,
                r,
              ];
            });
            return sort(decorated)
              .by([
                { asc: (u) => u[0] },
                {
                  [sortDirection]: (u) =>
                    u[1] && isNaN(u[1]) ? u[1]?.toLowerCase() : u[1],
                } as any,
                { asc: (u) => u[2] },
              ])
              ?.map((x) => x[3]);
          }
        }
      )
    );

  constructor(
    protected dropdownQuery: DropdownValueQuery,
    protected blstaffQuery: BLStaffsQuery,
    protected adviceProcessStore: AdviceProcessStore,
    public uiQuery: AdviceProcessUiQuery,
    protected userQuery: UserQuery
  ) {
    super(adviceProcessStore);
  }

  /** fill up choices and choices as object. */
  private fillUpChoices(
    choices: ViewDisplayValue[],
    column: EnhancedTableColumn
  ) {
    return {
      ...column,
      choices,
      choicesAsObject: mapToLookupObject(choices),
    };
  }

  /** Create a value usable as index for sorting.
   * Its only necessary to know if value is empty or not.
   * So if it is not empty, return 1 which is first in sort index.
   * And 2 if empty.
   */
  private spaceSortValueGetter(
    fieldValue: string | number | null | undefined
  ): 1 | 2 {
    let stringValue: string;
    if (util.isNullOrEmpty(fieldValue)) {
      stringValue = '';
    } else if (typeof fieldValue === 'string') {
      stringValue = fieldValue?.trim();
    } else {
      stringValue = fieldValue?.toString();
    }
    if (stringValue === '') {
      return 2;
    } else {
      return 1;
    }
  }
}
