import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SoaDocument } from './soa-documents.model';

export interface SoaDocumentState extends EntityState<SoaDocument> {
	applicationDocuments: SoaDocument[];
}

@Injectable()
@StoreConfig({ name: 'soaDocument', idKey: 'cRTId' })
export class SoaDocumentStore extends EntityStore<SoaDocumentState, SoaDocument> {
	constructor() {
		super({
			applicationDocuments: [],
		});
	}
}
