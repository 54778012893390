import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { CRMDocumentTypeCodes } from 'src/app/shared/models/documents/document.model';
import { DocumentService } from './states/document.service';
@Injectable()
export class DocumentPageResolver implements Resolve<boolean> {
  constructor(
    private documentService: DocumentService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const adDocuments$ = this.documentService.search(CRMDocumentTypeCodes.Adviser);
    const odDocuments$ = this.documentService.search(CRMDocumentTypeCodes.Office);
    const fapDocuments$ = this.documentService.search(CRMDocumentTypeCodes.FAP);
    const xdDocuments$ = this.documentService.search(CRMDocumentTypeCodes.Other);
    this.documentService.clear();

    return forkJoin(adDocuments$, odDocuments$, fapDocuments$, xdDocuments$).pipe(mapTo(true));
  }
}
