import { TLStaff } from './tl-staff.model';
import { QueryEntity } from '@datorama/akita';
import { TLStaffsState, TLStaffsStore } from './tl-staffs.store';
import { Injectable } from '@angular/core';

@Injectable()
export class TLStaffsQuery extends QueryEntity<TLStaffsState, TLStaff> {
    constructor(protected store: TLStaffsStore) {
        super(store);
    }
}
