<app-manual-loader
	[isLoading]="isSorting || (isLoading$ | async)"
></app-manual-loader>
<div class="clearfix document-list mb-4">
	<div [formGroup]="form" [class.submitted]="submitted">
		<div class="form-row font-weight-bold d-none d-lg-flex mb-2">
			<div class="col-auto">
				<span class="document-list__icons__drag sortable-item">
					<i class="material-icons md-18 tap-text-color-h6 invisible"
						>drag_indicator</i
					>
				</span>
			</div>
			<div class="col-12 col-lg-2">SOA Documents</div>
			<div class="col-12 col-lg-3">Link</div>
			<div class="col-12 col-lg-4">File Name</div>
			<div
				class="col-12 col-lg-2 d-flex justify-content-center align-self-center"
			>
				Include in SOA
			</div>
		</div>
		<ng-container
			*ngIf="DocumentList?.controls.length; else showNoItemsMessage"
		>
			<div
				class="form-row mb-3 pt-2 document-list__form d-block"
				formArrayName="applicationDocuments"
			>
				<div
					cdkDropList
					class="document-list__list"
					(cdkDropListDropped)="drop($event)"
					[cdkDropListData]="DocumentList?.controls"
				>
					<div
						[formGroup]="document"
						class="py-2 d-block d-lg-flex align-items-center"
						*ngFor="
							let document of DocumentList?.controls;
							let i = index;
							let last = last;
							trackBy: trackByFn
						"
						cdkDrag
						[cdkDragData]="document"
						[cdkDragDisabled]="isAdd || isEdit"
					>
						<ng-container>
							<div class="col-auto p-0">
								<span
									class="document-list__icons__drag document-list__list-box-drag sortable-item"
									cdkDragHandle
								>
									<i class="material-icons md-18 tap-text-color-h6 d-block"
										>drag_indicator</i
									>
								</span>
							</div>
							<div class="col-12 col-lg-2">
								<ng-container
									*ngIf="document.get('isEdit').value; else showDocumentName"
								>
									<ng-container
										*ngIf="
											!document.get('fromDocumentListSettings').value;
											else showDocumentName
										"
									>
										<input
											formControlName="document"
											type="text"
											id="document"
											class="form-control crt-form-control"
											placeholder="Document name"
											[class.disabled]="!document.get('isEdit').value"
										/>
									</ng-container>
								</ng-container>
								<ng-template #showDocumentName>
									{{ document?.get("document").value }}
								</ng-template>
								<ng-template #showInput>
									<input
										*ngIf="!document.get('fromDocumentListSettings').value"
										formControlName="document"
										type="text"
										id="document"
										class="form-control crt-form-control"
										placeholder="Document name"
										[disabled]="!document.get('isEdit').value"
									/>
								</ng-template>
							</div>
							<div class="col-12 col-lg-3 d-flex align-items-center">
								<button
									*ngIf="
										document?.get('document').value !== 'Statement of Advice'
									"
									type="button"
									class="tap-btn tap-btn-outline tap-btn--shadow px-4 w-50 mr-1"
									[class.disabled]="
										(document.get('cRTId').value &&
											isEdit &&
											!!!document.get('isEdit').value) ||
										isAdd
									"
									[disabled]="
										(document.get('cRTId').value &&
											isEdit &&
											!!!document.get('isEdit').value) ||
										isAdd
									"
									(click)="openUploadOptionModal(uploadOptionModal, i)"
								>
									{{ document.get("documentId").value ? "Relink" : "Link" }}
								</button>
								<span
									class="mr-0 mr-lg-2"
									*ngIf="
										!isAdd && document.get('isMultiple').value && document.get('document').value !== 'Statement of Advice' && !isEdit
									"
								>
									<button
										id="duplicateDocumentBtn_{{ i }}"
										class="icon-btn"
										title="Duplicate"
										(click)="duplicateDocument(document, i)"
									>
										<i class="fas fa-clone fa-16"></i>
									</button>
								</span>
								<ng-container
									*ngIf="document.get('isLoading').value; else showButtons"
								>
									<app-manual-loader-mini
										[isLoading]="true"
									></app-manual-loader-mini>
								</ng-container>
								<ng-template #showButtons>
									<ng-container
										*ngIf="document.get('isEdit').value; else showEdit"
									>
										<button
											color="primary"
											type="button"
											class="icon-btn w-auto h-auto px-1"
											[disabled]="document.get('document').invalid"
											(click)="saveDocument(i)"
										>
											<i class="material-icons md-16"> save </i>
										</button>
										<ng-container
											*ngIf="
												document.get('cRTId').value && document.valid;
												else showRemove
											"
										>
											<button
												type="button"
												class="icon-btn w-auto h-auto px-1"
												(click)="toggleEdit(i)"
											>
												<i class="material-icons md-16"> close </i>
											</button>
										</ng-container>
										<ng-template #showRemove>
											<button
												type="button"
												class="icon-btn w-auto h-auto px-1"
												(click)="removeDocument(i)"
											>
												<i class="material-icons md-16"> close </i>
											</button>
										</ng-template>
									</ng-container>
									<ng-template #showEdit>
										<button
											*ngIf="!document.get('fromDocumentListSettings').value"
											type="button"
											class="icon-btn w-auto h-auto px-1"
											[disabled]="
												isAdd || (isEdit && !!!document.get('isEdit').value)
											"
											(click)="toggleEdit(i)"
										>
											<i class="material-icons md-16">edit</i>
										</button>
	
										<button
											*ngIf="
												downloadableDocs.includes(document?.get('document').value)
											"
											type="button"
											class="icon-btn w-auto h-auto px-1"
											[disabled]="
												isAdd || (isEdit && !!!document.get('isEdit').value)
											"
											(click)="
												downloadDocument(document?.get('document').value, i)
											"
										>
											<i class="fas fa-download fa-14 tap-text-color-h6 pt-1"></i>
										</button>
									</ng-template>
								</ng-template>
							</div>
							<div class="col-12 col-lg-4">
								<span
									class="word-break font-weight-bold cursor-pointer"
									(click)="downloadLink(document.get('documentId').value)"
								>
									{{ document.get("fileName").value }}
								</span>
							</div>
							<div
								class="col-12 col-lg-2 d-flex justify-content-center align-self-center"
							>
								<div class="custom-control custom-checkbox">
									<input
										type="checkbox"
										class="checkbox custom-control-input"
										[class.disabled]="
											(isEdit && !!!document.get('isEdit').value) ||
											(document.get('cRTId').value && isAdd) ||
											isAdd
										"
										(click)="checkboxIsTick(document, i)"
										id="combine{{ i }}"
										formControlName="combine"
									/>
									<label
										class="custom-control-label"
										for="combine{{ i }}"
										[class.disabled]="
											(isEdit && !!!document.get('isEdit').value) ||
											(document.get('cRTId').value && isAdd) ||
											isAdd
										"
									>
									</label>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #showNoItemsMessage>
			<p>No Application Documents yet.</p>
		</ng-template>
		<!-- </ng-template> -->

		<div class="clearfix mb-3"></div>
		<div class="form-row align-items-center">
			<ng-container *ngIf="showAddDropdown">
				<div class="col-12 col-lg-2">
					<span class="crt-custom-dropdown w-100">
						<select
							#addDocument
							id="addNewDocument"
							class="form-control theme-crm-field-disabled crt-form-control"
							(change)="onSelectAddOption($event.target.value)"
						>
							<option value=""></option>
							<option
								*ngFor="let d of documentsFromSettings"
								[value]="d.settingsId"
							>
								{{ d.title }}
							</option>
							<option value="Other">Other</option>
						</select>
					</span>
				</div>
				<div class="col-auto">
					<button
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="isAdd = false; showAddDropdown = false"
					>
						<i class="material-icons md-16">close</i>
					</button>
				</div>
			</ng-container>
			<ng-container *ngIf="!isAdd && !isEdit">
				<div class="col-12 col-lg-2">
					<button
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow ml-1 px-4"
						(click)="addNew()"
					>
						Add Document +
					</button>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #uploadOptionModal>
	<button
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="optionModalRef.hide()"
	>
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Link Document</h4>
	</div>
	<div class="modal-body">
		<div class="clearfix py-3">
			<div class="d-flex justify-content-center">
				<button
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-2"
					(click)="uploadDocuments(); optionModalRef.hide()"
				>
					Upload New
				</button>
				<button
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4"
					(click)="linkDocument(); optionModalRef.hide()"
				>
					Link Document
				</button>
			</div>
		</div>
	</div>
</ng-template>
