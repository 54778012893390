import { Injectable } from '@angular/core';
import { FeatureToggleModel } from '@modules/special-features/state/special-features-settings.model';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService, JsonResultStatus } from '../../core/base/api.service';
import { DocumentUploadResponse } from './../../shared/models/documents/document.model';
import { BusinessConfig, createBusinessConfig, getGenericKey } from './business-config.model';
import { BusinessConfigQuery } from './business-config.query';
import { BusinessConfigStore } from './business-config.store';
import { ThemeConfigUpdate } from './theme-config.model';

export const getCompanyCode = () => {
	let cc = '';
	if (document.location.pathname && document.location.pathname?.split('/').length > 0) {
		cc = document.location.pathname?.split('/')[1];
		cc = cc === 'admin' || cc === 'login' || cc === 'login-automation' ? '' : cc;
	} else {
		return '';
	}
	return cc;
};

@Injectable()
export class BusinessConfigService {
	constructor(
		private businessConfigStore: BusinessConfigStore,
		private businessConfigQuery: BusinessConfigQuery,
		private api: ApiService,
	) {}

	getOrCached(companyCode: string): Observable<BusinessConfig> {
		const config = this.businessConfigQuery.getValue()?.config;
		if (config) {
			return of(config);
		}
		return this.get(false, companyCode);
	}

	get(force = false, companyCode = ''): Observable<BusinessConfig> {
		const code = companyCode || getCompanyCode();
		const endpoint = `businesses/${code}`;
		if (!this.businessConfigQuery.getHasCache() || force) {
			return this.api.get<BusinessConfig>(endpoint).pipe(
				tap((res) =>
					this.businessConfigStore.update(() => ({
						config: createBusinessConfig(res),
					})),
				),
				catchError((err) => of(err)),
			);
		} else {
			this.clear();
			return of(null);
		}
	}
	clear() {
		this.businessConfigStore.reset();
	}

	updateBusinessConfig(req: BusinessConfig, companyCode: string) {
		if (!companyCode) {
			return of(req);
		}
		const endpoint = `businesses/${companyCode}`;
		return this.api.put<JsonResultStatus>(endpoint, { ...req, BusinessCode: companyCode }).pipe(
			mergeMap(() => this.api.get<BusinessConfig>(endpoint)),
			tap((res) => {
				this.businessConfigStore.update(() => ({
					config: createBusinessConfig(res),
				}));
			}),
		);
	}

	updateThemeConfig(req: ThemeConfigUpdate) {
		const endpoint = `businesses/${req.company_code}`;
		const body = {
			FieldBackgroundColor: req.fieldBackgroundColor,
			FieldTextColor: req.fieldTextColor,
			H1Color: req.h1color,
			H2Color: req.h2color,
			H3Color: req.h3color,
			H4Color: req.h4color,
			H5Color: req.h5color,
			H6Color: req.h6color,
			PColor: req.pcolor,
			PrimaryColor: req.primarycolor,
			SecondaryColor: req.secondarycolor,
			TertiaryColor: req.tertiarycolor,
			WidgetColor1: req.widgetcolor1,
			WidgetColor2: req.widgetcolor2,
			BusinessCode: req.company_code,
			SOAHeadingColor: req.soaHeadingColor,
		};
		return of(body).pipe(
			withLatestFrom(this.businessConfigQuery.businessConfig$),
			map(([x, y]) => ({ ...y, ...x })),
			mergeMap((val) => this.api.put<JsonResultStatus>(endpoint, val)),
			mergeMap(() => this.api.get<BusinessConfig>(endpoint)),
			tap((res) =>
				this.businessConfigStore.update(() => ({
					config: createBusinessConfig(res),
				})),
			),
		);
	}

	updateBusinessOATLogo(req) {
		const endpoint = 'documents/upload-photo';
		return this.api.post(endpoint, { ...req, Type: 'B' }).pipe(concatMap(() => this.get(true, getCompanyCode())));
	}

	updateBusinessAestheticCover(req, oatAestheticCover) {
		const endpoint = 'documents/upload-business-document';
		return this.api.post(endpoint, { ...req }).pipe(
			tap((response: DocumentUploadResponse) => {
				this.businessConfigStore.update((prevState) => ({
					...prevState,
					config: {
						...prevState.config,
						[oatAestheticCover]: response?.DocumentURL || '',
					},
				}));
			}),
		);
	}
	updateBusinessLOATFactFinderCover(req) {
		const endpoint = 'documents/upload-business-document';

		return this.api.post(endpoint, { ...req }).pipe(
			tap((response: DocumentUploadResponse) => {
				this.businessConfigStore.update((prevState) => ({
					...prevState,
					config: {
						...prevState.config,
						BusinessLOATFactFindCover: response?.DocumentURL || '',
					},
				}));
			}),
		);
	}
	updateBusinessGenericCover(req) {
		const endpoint = 'documents/upload-photo';
		return this.api.post(endpoint, { ...req }).pipe(
			tap((response: DocumentUploadResponse) => {
				const key = getGenericKey(req?.Type);
				if (key) {
					this.businessConfigStore.update((prevState) => ({
						...prevState,
						config: {
							...prevState.config,
							[key]: response?.PhotoURL || '',
						},
					}));
				}
			}),
		);
	}

	patchUpdateFeature(data: FeatureToggleModel, company: string) {
		const endpoint = `businesses/${company}/patch`;
		return this.api.patch<JsonResultStatus>(endpoint, [data]).pipe(
			tap(() => {
				this.businessConfigStore.update((prevState) => ({
					...prevState,
					config: {
						...prevState.config,
						[data?.MetaKey]: data?.Value,
					},
				}));
			}),
		);
	}

	multiMetaKeyPatch(data: FeatureToggleModel[], company: string) {
		const endpoint = `businesses/${company}/patch`;
		return this.api.patch<JsonResultStatus>(endpoint, data).pipe(
			tap(() => {
				if (data?.length) {
					const updatedFields = data?.reduce((a, c) => {
						return { ...a, [c?.MetaKey]: c?.Value };
					}, {});
					this.businessConfigStore.update((prevState) => ({
						...prevState,
						config: {
							...prevState.config,
							...updatedFields,
						},
					}));
				}
			}),
		);
	}

	companyCode() {
		return getCompanyCode()?.toLowerCase();
	}
}
