<div class="container">
	<pdf-viewer
		*ngIf="showViewPDF"
		[src]="fileSource"
		[show-all]="true"
		[autoresize]="true"
		[original-size]="false"
		[fit-to-page]="true"
		[render-text]="true"
		[zoom]="1"
		[zoom-scale]="'page-width'"
		(page-rendered)="pageRendered($event)"
	></pdf-viewer>
</div>
