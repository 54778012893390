import MomentUtil from 'src/app/util/moment.util';
import {
	FgCustomerServiceGroupState,
	FgCustomerServiceState,
} from '../../models/services/fg-insurance/fg-insurance.model';

/** Policy match
 * @param a
 * @param b
 * @returns {number}
 */
export const statusMatch = (regExp: RegExp, str: string): boolean => {
	const match = str?.match(regExp);
	return match && str === match[0];
};

/** Policy status sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const statusSorter = (a: string, b: string, type: string): number => {
	const matchA = statusMatch(new RegExp(type), a ? a?.toLowerCase() : '');
	const matchB = statusMatch(new RegExp(type), b ? b?.toLowerCase() : '');

	if (matchA < matchB) {
		return 1;
	} else if (matchA > matchB) {
		return -1;
	} else {
		return 0;
	}
};

/** Policy status group sorter (default)
 * @param a
 * @param b
 * @returns {number}
 */
export const statusGroupSorter = (a: string, b: string): number => {
	return (
		statusSorter(a, b, 'inforce') ||
		statusSorter(a, b, 'lead') ||
		statusSorter(a, b, 'contact made') ||
		statusSorter(a, b, 'info requested') ||
		statusSorter(a, b, 'info received') ||
		statusSorter(a, b, 'quoted') ||
		statusSorter(a, b, 'underwriting') ||
		statusSorter(a, b, 'offer of terms') ||
		statusSorter(a, b, 'policy accepted') ||
		statusSorter(a, b, 'closed/renewed') ||
		statusSorter(a, b, 'cancelled') ||
		statusSorter(a, b, 'ntu')
	);
};

/** Custom policy status group sorter for businesses that have custom statuses
 * @param a
 * @param b
 * @returns {number}
 */
export const customStatusGroupSorter = (
	a: string,
	b: string,
	list: string[]
): number => {
	const sortedList = list?.map((i) => statusSorter(a, b, i)) || [];
	const sort = sortedList?.reduce((acc, curr, index) => {
		if (index === 0) {
			return curr;
		}
		return acc || curr;
	}, 0);
	return sort;
};

export const totalPremiumSorter = (a: number, b: number) => {
	return +(a === null) - +(b === null) || -(a > b) || +(a < b);
};

export const tracking = (a: string, b: string): number => {
	return statusSorter(a, b, 'original') || statusSorter(a, b, 'renewal') || statusSorter(a, b, 'mta');
};

/********************* Date sorter *********************/
export const dateGroupSorter = (a, b) => {
	const convertA = a ? MomentUtil.formatDateToMoment(a).unix() : 0;
	const convertB = b ? MomentUtil.formatDateToMoment(b).unix() : 0;

	return (
		+(convertA === null) - +(convertB === null) ||
		-(convertA > convertB) ||
		+(convertA < convertB)
	);
};
/********************* End of Date sorter *********************/

/** Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const subGroupSorter = (
	a: FgCustomerServiceState,
	b: FgCustomerServiceState,
	customSortingList?: string[]
): number => {
	const statusSorting = !!customSortingList
		? customStatusGroupSorter(a.status, b.status, customSortingList)
		: statusGroupSorter(a.status, b.status);

	return (
		fgIsActiveSorter(a.isActive,b.isActive) ||
		tracking(a.tracking, b.tracking) ||
		statusSorting ||
		totalPremiumSorter(!!a.totalPremium ? a.totalPremium : null, !!b.totalPremium ? b.totalPremium : null)
	);
};

/********************* End of status sorter *********************/

export const apiSorter = (a, b) => {
	return +(a === null) - +(b === null) || -(a > b) || +(a < b);
};

/** Main Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mainGroupSorter = (
	a: FgCustomerServiceGroupState,
	b: FgCustomerServiceGroupState,
	customSortingList?: string[]
): number => {
	const statusSorting = !!customSortingList
		? customStatusGroupSorter(a.status, b.status, customSortingList)
		: statusGroupSorter(a.status, b.status);

	return (fgIsActiveSorter(a.isActive,b.isActive)|| statusSorting || dateGroupSorter(a.firstPolicyDate, b.firstPolicyDate));
};

/********************* FG IsActive sorter *********************/
/**isActive Sorter 
 * * @param a
 * @param b
 * @returns {number}
*/
export const fgIsActiveSorter = (a:number,b:number):number => {
	if(a===2&&b!==2)	return 1;
	if(a!==2&&b==2)	return -1;
	return 0;
}
