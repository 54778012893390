<div class="container-fluid">
	<div class="row">
		<div class="col plus-icon">
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
			>
				<div class="col px-0 form-inline">
					<span class="font-weight-bold px-0 sidebar__title"
						>Total Lending: {{ mortgageState.totaLending | currency }}</span
					>

					<!-- Add New Group -->
					<ng-container *appRestrictTo="['FAS']">
						<button
							type="button"
							[disabled]="isAddNew || isLoading"
							(click)="addNewGroup()"
							class="add-button-sidenav border-0 font-weight-bold md-20 tap-text-color-h6"
							id="mortgageAddNewGroup"
						>
							<i class="material-icons add-button-sidenav-plus">add</i>
						</button>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="col-auto float-right px-5 pb-3">
			<div class="row">
				<!-- <ng-container *ngIf="isLoading">
          <div class="d-flex flex-row bd-highlight justify-content-end px-2 pt-2">
            <app-manual-loader-mini *ngIf="isLoading" [isLoading]="isLoading"></app-manual-loader-mini>
          </div>
        </ng-container> -->

				<div *ngIf="isSavingDocument">
					<div
						class="d-flex flex-row bd-highlight justify-content-end px-2 pt-2"
					>
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isSavingDocument"
						></app-manual-loader-mini>
					</div>
				</div>

				<!-- Add New Split -->
				<ng-container *appRestrictTo="['FAS']">
					<button
						*ngIf="!isAddNewGroup && mortgageState.mortgages.length > 0"
						type="button"
						class="tap-btn tap-btn--default btn-sm mr-2"
						[disabled]="isAddNew && isSaving"
						(click)="addNewSplit()"
						id="mortgageAddNewSplit"
					>
						Add New Split
					</button>
				</ng-container>

				<!-- Show Archive -->
				<ng-container *appRestrictTo="['FVAS']">
					<div *ngIf="!isAddNewGroup && mortgageState.mortgages.length > 0">
						<label for="showArchive" class="mr-2">Show Archived</label>
						<app-checkbox
							class="clickable d-inline-block"
							style="transform: translate(0px, 4px)"
						>
							<input
								type="checkbox"
								[checked]="!!isShowArchived"
								name="showArchive"
								id="mortgageShowArchive"
								class="checkbox"
								(change)="showArchived($event.target.checked)"
							/>
						</app-checkbox>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="row next-review-date p-0 m-0 mb-3" [formGroup]="form">
		<div class="col-5 p-0 d-flex align-items-center">
			<label>Next Review:</label>
		</div>
		<div class="col-7 p-0">
			<app-date-input
				textboxId="mortgageNextReview"
				formControlName="mortgageNextReview"
				textboxClass="theme-crm-field-disabled"
				placeholder="dd/mm/yyyy"
				style="flex: 1"
				useActionButtons="true"
				[isSavingDate]="isSavingNRD"
				(onSaveDateEvent)="updateReviewDate()"
			>
			</app-date-input>
		</div>
	</div>
	<div class="row main-content">
		<div class="col">
			<ng-container *ngIf="isLoading">
				<app-manual-loader
					[isLoading]="isLoading"
					[opts]="{
						lines: 9,
						length: 27,
						width: 20,
						radius: 45,
						scale: 0.5
					}"
				></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!isLoading">
				<tabset #sidebarNav class="tap-tabset-sidebar" [vertical]="true">
					<ng-template
						ngFor
						let-m
						[ngForOf]="mortgageState.mortgages"
						let-i="index"
						[ngForTrackBy]="trackByFn"
					>
						<ng-container
							*ngIf="
								m?.isActive !== 2 || (m?.isActive === 2 && isShowArchived)
							"
						>
							<tab
								(selectTab)="selectGroupConfirm(m)"
								id="{{ m.provider + '-' + m.loanNumber }}"
								[customClass]="
									m.color === 'green'
										? 'nav-item--success'
										: m.color === 'yellow'
										? 'nav-item--warning'
										: m.color === 'red'
										? 'nav-item--danger'
										: m.color === 'dark'
										? 'nav-item--dark'
										: ''
								"
							>
								<ng-template tabHeading>
									<ng-container *ngIf="m.provider; else tabHeadingAdd">
										<strong>{{ m.provider + " - " + m.loanNumber }}</strong
										><br />
										<span class="font-italic"
											>Total Lending: {{ m.totalLending | currency }}</span
										><br />
										<span>Fixed Until: {{ m.fixedUntil | momentDatetime }}</span>
										<i
											class="material-icons arrow-right md-20 d-flex align-items-center"
											>chevron_right</i
										>
									</ng-container>
								</ng-template>
		
								<div class="col px-0 px-md-3 pr-lg-0">
									<div class="container-fluid px-0 px-md-3 pr-lg-0">
										<ng-container
											*ngIf="
												isAddNew &&
												((m.provider === this.activeProvider &&
													m.loanNumber === this.activeLoanNumber) ||
													(!m.provider && !m.loanNumber))
											"
										>
											<app-mortgage-service-form
												id="mortgage_{{ i }}"
												#addForm
												[isLead]="isLead"
												[isCompany]="isCompany"
												[addMode]="true"
												[isNewGroup]="isAddNewGroup"
												[isSaving]="isSaving"
												[mortgage]="mFormState"
												[borrowingEntities]="borrowingEntities"
												[securities]="securities"
												[statuses]="status"
												[mortgageTypes]="mortgageTypes"
												[loanTypes]="loanTypes"
												[fixedPeriods]="fixedPeriods"
												[providers]="providers"
												[repaymentFrequency]="repaymentFrequency"
												[refixStatus]="refixStatus"
												[groupName]="
													m.provider
														? m.provider + ': ' + m.loanNumber
														: 'New Group'
												"
												(saveEvent)="confirmCreate($event)"
												(cancelAddEvent)="
													m.provider && m.loanNumber
														? cancelAddNewSplit()
														: cancelNewGroup($event)
												"
											></app-mortgage-service-form>
										</ng-container>
		
										<ng-container>
											<ng-template
												ngFor
												let-data
												[ngForOf]="m.customerServices"
												let-i="index"
												[ngForTrackBy]="trackById"
											>
												<ng-container
													*ngIf="
														data.isActive !== 2 ||
														(data?.isActive === 2 && isShowArchived)
													"
												>
													<app-mortgage-service-form
														#mortgageForms
														id="mortgage_{{ i }}"
														[isLead]="isLead"
														[isCompany]="isCompany"
														[addMode]="false"
														[isSaving]="isSaving"
														[mortgage]="data"
														[borrowingEntities]="borrowingEntities"
														[securities]="securities"
														[statuses]="status"
														[mortgageTypes]="mortgageTypes"
														[loanTypes]="loanTypes"
														[fixedPeriods]="fixedPeriods"
														[providers]="providers"
														[refixStatus]="refixStatus"
														[repaymentFrequency]="repaymentFrequency"
														[groupName]="m.provider + ': ' + m.loanNumber"
														[formId]="m.provider + '-' + i"
														[mList]="mList"
														(saveEvent)="confirmSaveMortgage($event)"
														(deleteEvent)="confirmDeleteMortgage($event)"
														(archiveEvent)="archiveMortgage($event)"
														(splitEvent)="split($event)"
														(deleteNoteEvent)="deleteNote($event)"
														(addNoteEvent)="addNote($event)"
													></app-mortgage-service-form>
												</ng-container>
											</ng-template>
										</ng-container>
									</div>
								</div>
							</tab>
						</ng-container>
					</ng-template>
				</tabset>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #tabHeadingAdd>
	<div class="mt-2">
		<strong>Add New Split</strong>
	</div>
	<i class="material-icons arrow-right md-20 d-flex align-items-center"
		>chevron_right</i
	>
</ng-template>
