import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { Observable, Observer, of } from 'rxjs';
import { concatMap, take, map, tap } from 'rxjs/operators';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { MOATAssetsLiabilitiesModalComponent } from '../../../../../../../../../../shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { FundingRequiredLoanMapper } from '../state/loan-repaid.mapper';
import { FundingRequiredLoan } from '../state/loan-repaid.model';
import { LoanRefinanceQuery } from '../../../loan-refinance/state/loan-refinance.query';
import { LoanRefinanceService } from '../../../loan-refinance/state/loan-refinance.service';

@Component({
  selector: 'app-loan-repaid-form',
  templateUrl: './loan-repaid-form.component.html',
})
export class LoanRepaidFormComponent implements OnInit, OnChanges {

  @Input() isLoading: boolean;
  @Input() parentCRTId: number;
  @Input() loans: FundingRequiredLoan[];
  @Input() updateFn$: (req: any) => Observable<any>;

  @Input() adviceProcessId: number;
  @Input() netProceeds: number;

  @Input() addFn$: (req: any) => Observable<any>;

  form: UntypedFormGroup;
  bsModalRef: BsModalRef;

  @Input() borrowers$: Observable<ViewDisplayValue[]>;
	@Input() securities$: Observable<ViewDisplayValue[]>;
	@Input() MP$: Observable<ViewDisplayValue[]>;
	@Input() MLT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() APCRTL$: Observable<ViewDisplayValue[]>;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

  sourceType$ = this.query.sourceType$;
	isUpdating = false;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private query: LoanRefinanceQuery,
    private service: LoanRefinanceService
  ) { }

  ngOnChanges(changes) {
    if (changes.loans) {
      this.buildForm();
      this.prepareData();
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.prepareData();
  }


  buildForm(): void {
    this.form = this.fb.group({
      loans: this.fb.array([])
    });
  }

  prepareData() {
    const processData = (loan: FundingRequiredLoan) => {
      const caseChange = objectUtil.mapPascalCaseToCamelCase(loan);
      const processed = FundingRequiredLoanMapper.mapToView(caseChange);
      const group = this.fb.group({
        lender: this.fb.control(processed.lender),
        loanAmount: this.fb.control(processed.loanBalance),
        isTicked: this.fb.control(processed.isTicked),
        linkedCRTId: this.fb.control(processed.linkedCRTId),
        hidden: this.fb.control(processed.hidden || false),
				sourceType: this.fb.control(processed?.sourceType),
				isLoading: false
      })
      this.LoanList.push(group);
    }

    if (this.loans?.length) {
      R.forEach(processData, this.loans);
    }
  }

  get LoanList() {
    return this.form.get('loans') as UntypedFormArray;
  }

  tickLoan(index: number) {
    const loan = this.loans[index]
    const isTicked = this.LoanList.controls[index].get('isTicked').value
    const data = {
      ...loan,
      isTicked
    }
		this.updateLoading(index, true);
    this.updateFn$(data).pipe(
			tap(() => this.updateLoading(index, false)),
			take(1)
		).subscribe();
  }

	updateLoading(index: number, status: boolean) {
		this.LoanList.controls[index].get('isLoading').setValue(status);
		this.isUpdating = status;
	}

  addNewLoan() {
    const decline$ = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

    const saveFn$ = (data) => of(data).pipe(
			map((x) => ({...x, isTicked: false})),
			map((x) => FundingRequiredLoanMapper.mapToUpsert(x)),
			concatMap((x) => this.addFn$(x)),
			concatMap(() => this.service.get(this.parentCRTId)),
			take(1)
		);

    const initState = {
			header: 'Loan Details',
			message: `Liabilities`,
			borrowers: this.borrowers$,
			securities: this.securities$,
			mp$: this.MP$,
			mlt$: this.MLT$,
			apcrtf$: this.APCRTF$,
			apcrtyn$: this.APCRTYN$,
			apcrtynna$: this.APCRTYNNA$,
			apcrtl$: this.APCRTL$,
			getOwnerChoices: this.getOwnerChoices,
			saveFn$,
			decline$,
		};
    this.bsModalRef = this.modalService.show(
			MOATAssetsLiabilitiesModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
  }
}
