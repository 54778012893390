<app-secondary-base-widget
	headerText="Meeting Stats"
	[widgetCode]="widgetCode"
	class="meeting-stats"
>
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<app-date-range
		ngProjectAs="input"
		[formControl]="dateControl"
	></app-date-range>
	<div *ngIf="!(isLoading$ | async)" class="meeting-stats-widget p-3 pt-0">
		<div class="row container-header border-bottom-light-thick">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2"></div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				<span class="tbl-header complete-header">Completed</span>
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				<span class="tbl-header upcoming-header">Upcoming</span>
			</div>
		</div>
		<div class="row border-bottom">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2">
				{{ (ccFirstMeeting$ | async)?.ActivityType }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ccFirstMeeting$ | async)?.Completed }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ccFirstMeeting$ | async)?.Upcoming }}
			</div>
		</div>
		<div class="row border-bottom">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2">
				{{ (selfGenFirstMeeting$ | async)?.ActivityType }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (selfGenFirstMeeting$ | async)?.Completed }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (selfGenFirstMeeting$ | async)?.Upcoming }}
			</div>
		</div>
		<div class="row border-bottom-light-thick">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2">
				{{ (ebReviewMeeting$ | async)?.ActivityType }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ebReviewMeeting$ | async)?.Completed }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ebReviewMeeting$ | async)?.Upcoming }}
			</div>
		</div>
		<div class="row">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2"></div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				<span class="tbl-total complete-total font-weight-bolder">{{
					(total1$ | async)?.Completed
				}}</span>
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				<span class="tbl-total upcoming-total font-weight-bolder">{{
					(total1$ | async)?.Upcoming
				}}</span>
			</div>
		</div>

		<div class="row mt-2 border-bottom">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2">
				{{ (ccSoaMeeting$ | async)?.ActivityType }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ccSoaMeeting$ | async)?.Completed }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ccSoaMeeting$ | async)?.Upcoming }}
			</div>
		</div>
		<div class="row border-bottom">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2">
				{{ (ccSelfGenSoaMeeting$ | async)?.ActivityType }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ccSelfGenSoaMeeting$ | async)?.Completed }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ccSelfGenSoaMeeting$ | async)?.Upcoming }}
			</div>
		</div>
		<div class="row border-bottom-light-thick">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2">
				{{ (ebSoaMeeting$ | async)?.ActivityType }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ebSoaMeeting$ | async)?.Completed }}
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				{{ (ebSoaMeeting$ | async)?.Upcoming }}
			</div>
		</div>
		<div class="row">
			<div class="col-8 pl-0 pr-3 pt-2 pb-2"></div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				<span class="tbl-total complete-total font-weight-bolder">{{
					(total2$ | async)?.Completed
				}}</span>
			</div>
			<div class="col-2 pl-3 pr-3 pt-2 pb-2 text-center">
				<span class="tbl-total upcoming-total font-weight-bolder">{{
					(total2$ | async)?.Upcoming
				}}</span>
			</div>
		</div>
	</div>
</app-secondary-base-widget>
