import { TLStaffSecGroup } from './tl-staff-security-group.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface SecGroupState extends EntityState<TLStaffSecGroup> { }

@Injectable()
@StoreConfig({
    name: 'tlStaffSecGroups',
    idKey: 'SecurityGroupID'
})
export class TLStaffSecGroupsStore extends EntityStore<SecGroupState, TLStaffSecGroup> {
    constructor() {
        super();
    }
}
