export interface AiQuotePayloadState {
	payload: AiQuotePayloadDataState;
	file: string;
}

export interface AiQuoteResultState {
	success: boolean;
	status: string;
	message: string;
	data: any;
}
export const AiQouteCompany = 'tap';
export const AiQouteType = 'insurance';

export interface AiQuotePayloadDataState {
	type: string; // AiQouteType
	companyData: AiQuoteCompanyState;
}

export interface AiQuoteCompanyState {
	name: string; // AiQouteCompany
	data: AiQuoteCompanyDataState;
}

export interface AiQuoteCompanyDataState {
	adviceProcessId: number;
	fileName: string;
	companyCode: string;
	clientId: number;
	lifeAssuredList: string;
	providersList: string;
	productsList: string;
}