import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import produce from 'immer';
import * as R from 'ramda';
import { LeadSearchRequest } from '../lead-search-request.model';
import { LeadSearchUiStore } from './lead-search-ui.store';
import { Row } from './lead-search.model';

interface ColumnWidth {
  metakey: string;
  width: number;
}

export interface LeadSearchState extends EntityState<Row> {
  columns: string[];
  columnWidths: ColumnWidth[];
  count: number;
  searchForm: LeadSearchRequest;
  totalAPI: number;
  isComplete: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'leadSearch', idKey: 'CustomerId' })
export class LeadSearchStore extends EntityStore<LeadSearchState, Row> {

  constructor(public uiStore: LeadSearchUiStore) {
    super({ columns: [], count: 0, totalAPI: 0, isComplete: false, columnWidths: [], searchForm: null });
  }

  setColumns(columns: string[]) {
    this.update(produce(x => { x.columns = columns; }));
  }

  setColumnWidths(columnWidths: ColumnWidth[]) {
    this.update(produce(x => {
      x.columnWidths = columnWidths;
    }));
  }

  setSearchForm(form: LeadSearchRequest) {
    this.update(produce(x => {
      x.searchForm = form;
    }));
  }

  updateField(req: { CustomerId: number, MetaKey: string, MetaValue: string }) {
    this.update(req.CustomerId, produce(draft => {
      const fields = R.omit(['CustomerId', 'link', 'ClientNextActivityId', 'UserNextActivityId'], draft);
      Object.values(fields).find(x => x.metakey === req.MetaKey).value = req.MetaValue;
    }));
  }
}
