import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { FormUserComponent } from '../../comps/form-user/form-user.component';
import { ListUserComponent } from '../../comps/list-user/list-user.component';
import { ViewUserComponent } from '../../comps/view-user/view-user.component';
import { ServicesListComponent } from '../../comps/view-user/services-list/services-list.component';
import { UserGoalsComponent } from '../../comps/view-user/user-goals/user-goals.component';
import { DocumentsComponent } from '../../comps/view-user/documents/documents.component';
import { DisclosureSettingsComponent } from '../../comps/view-user/disclosure-settings/disclosure-settings.component';
import { DisclosureTemplateComponent } from '../../comps/view-user/disclosure-settings/disclosure-template/disclosure-template.component';
import { DisclosureEmailComponent } from '../../comps/view-user/disclosure-settings/disclosure-email/disclosure-email.component';
import { DisclosureMergeTagComponent } from '../../comps/view-user/disclosure-settings/disclosure-merge-tag/disclosure-merge-tag.component';
import { UserSettingsQuery } from '../../state/user-settings/user-settings.query';
import { UserSettingsStore } from '../../state/user-settings/user-settings.store';
import { DisclosureSettingsService } from '../../service/disclosure-settings.service';
import { ProviderCommissionComponent } from '../../comps/view-user/provider-commission/provider-commission.component';
import { LrProviderComponent } from '../../comps/view-user/provider-commission/lr-provider/lr-provider.component';
import { MortgageProviderComponent } from '../../comps/view-user/provider-commission/mortgage-provider/mortgage-provider.component';
import { FgProviderComponent } from '../../comps/view-user/provider-commission/fg-provider/fg-provider.component';
import { KiwisaverProviderComponent } from '../../comps/view-user/provider-commission/kiwisaver-provider/kiwisaver-provider.component';
import { ProviderCommissionService } from '../../service/provider-commission.service';
import { MergeTagsService } from '../../service/merge-tags.service';
import { CustomerPortalComponent } from '../../comps/view-user/customer-portal/customer-portal.component';
import { CustomerPortalService } from '../../service/customer-portal.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccessibilitySettingsComponent } from '../../comps/view-user/accessibility-settings/accessibility-settings.component';
import { StaffSettingsService } from '../../service/staff-settings.service'

@NgModule({
	imports: [CommonModule, SharedModule, CollapseModule],
	declarations: [
		FormUserComponent,
		ListUserComponent,
		ViewUserComponent,
		ServicesListComponent,
		UserGoalsComponent,
		DocumentsComponent,
		DisclosureSettingsComponent,
		DisclosureTemplateComponent,
		DisclosureEmailComponent,
		DisclosureMergeTagComponent,
		ProviderCommissionComponent,
		LrProviderComponent,
		MortgageProviderComponent,
		FgProviderComponent,
		KiwisaverProviderComponent,
		CustomerPortalComponent,
		AccessibilitySettingsComponent,
	],
	exports: [
		FormUserComponent,
		ListUserComponent,
		ViewUserComponent,
		ServicesListComponent,
		UserGoalsComponent,
		DocumentsComponent,
		DisclosureSettingsComponent,
		DisclosureTemplateComponent,
		DisclosureEmailComponent,
		DisclosureMergeTagComponent,
		ProviderCommissionComponent,
		LrProviderComponent,
		MortgageProviderComponent,
		FgProviderComponent,
		KiwisaverProviderComponent,
		CustomerPortalComponent,
		AccessibilitySettingsComponent,
	],
	providers: [
		UserSettingsQuery,
		UserSettingsStore,
		DisclosureSettingsService,
		ProviderCommissionService,
		MergeTagsService,
		CustomerPortalService,
		StaffSettingsService
	],
})
export class UserComponentsModule {}
