import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, mergeMap, take } from 'rxjs/operators';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { ScopeOfServiceService } from '../states/scope-of-service/scope-of-service.service';

@Injectable()
export class CriticalIllnessGuard implements CanActivate {
  constructor(
    private router: Router,
    private query: ClientReviewTemplateQuery,
		private sosService: ScopeOfServiceService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
		return this.query.sosDefault$.pipe(
			take(1),
			mergeMap((sosDefault) => {
				// if SOS data is not loaded yet
				if (sosDefault === null) {
					// load sos default first before continue redirection logic
					return this.sosService.getSosDefault().pipe(
						mergeMap(() => this.query.hasCriticalIllness$)
					);
				}
				return this.query.hasCriticalIllness$;
			}),
			map((hasLife) => {
				if (!hasLife) {
					const { companyCode, customerType, clientId, adviceProcessId } =
						next.params;
					const returnUrl = `${companyCode}/crt/${customerType}/${clientId}/advice-process/${adviceProcessId}/scope-of-service`;
					return this.router.parseUrl(returnUrl);
				}
				return true;
			})
		);
  }
}
