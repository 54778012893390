import { ScopeOfServiceState } from './scope-of-service.model';
import { util } from '../../../../../../util/util';
import { SettingsTypes } from '../../../../../mortgage-settings/state/mortgage-settings.model';
import { AdviceProcessSectionCodes } from '../../../../../../shared/models/advice-process/advice-process.model';

export class ScopeOfServiceMapper {
  public static mapToView(data: ScopeOfServiceState) {
    return {
      referenceId: data?.referenceId || 0,
      intro: this.parseData(data?.intro) || '',
      newPurchase: data?.newPurchase || false,
      lendingTopup: data?.lendingTopup || false,
      businessLending: data?.businessLending || false,
      refinance: data?.refinance || false,
      generalStructureReview: data?.generalStructureReview || false,
      otherService: data?.otherService || false,
      otherServiceNotes: this.parseData(data?.otherServiceNotes) || '',
      otherSpecificClientGoals: this.parseData(data?.otherSpecificClientGoals) || '',

      kiwiSaverAdvice: data?.kiwiSaverAdvice || false,
      kiwiSaverAdviceDetails:
        this.parseData(data?.kiwiSaverAdviceDetails) || '',
      lifeAndRiskReview: data?.lifeAndRiskReview || false,
      lifeAndRiskReviewDetails:
        this.parseData(data?.lifeAndRiskReviewDetails) || '',
      houseCarContentsQuote: data?.houseCarContentsQuote || false,
      houseCarContentsQuoteDetails:
        this.parseData(data?.houseCarContentsQuoteDetails) || '',
      otherAdvice: data?.otherAdvice || false,
      otherAdviceNotes: this.parseData(data?.otherAdviceNotes) || '',
      type: SettingsTypes.SOS,
    };
  }

  public static mapCheckboxes(
    data: ScopeOfServiceState,
    settings: ScopeOfServiceState
  ) {
    if (!data?.cRTId) {
      // CRT Page > SOS checkbox status:
      // 0 - (Ticked on CRT Settings) Unticked on CRT Page
      // 1 - (Ticked on CRT Settings) Ticked on CRT Page
      // 2 - (Unticked on CRT Settings) Don't show on CRT Page
      return {
        // services
        newPurchase: settings?.newPurchase ? 0 : 2,
        lendingTopup: settings?.lendingTopup ? 0 : 2,
        businessLending: settings?.businessLending ? 0 : 2,
        refinance: settings?.refinance ? 0 : 2,
        generalStructureReview: settings?.generalStructureReview ? 0 : 2,
        otherService: settings?.otherService ? 0 : 2,

				// Other Advice
        kiwiSaverAdvice: settings?.kiwiSaverAdvice ? 0 : 2,
        lifeAndRiskReview: settings?.lifeAndRiskReview ? 0 : 2,
        houseCarContentsQuote: settings?.houseCarContentsQuote ? 0 : 2,
				otherAdvice: settings?.otherAdvice ? 0 : 2
      };
    }
    return data;
  }

  public static mapToViewCrt(
    data: ScopeOfServiceState,
    settings: ScopeOfServiceState
  ) {
    return {
      cRTId: data?.cRTId,
      adviceProcessId: data?.adviceProcessId,
      sectionCode: AdviceProcessSectionCodes.SOS,
      ...this.mapCheckboxes(data, settings),
      intro: this.parseData(data?.intro) ?? this.parseData(settings?.intro),
      otherServiceNotes: this.parseData(data?.otherServiceNotes) ?? this.parseData(settings?.otherServiceNotes),
      otherSpecificClientGoals: this.parseData(data?.otherSpecificClientGoals) ?? this.parseData(settings?.otherSpecificClientGoals),
      kiwiSaverAdviceDetails: this.parseData(data?.kiwiSaverAdviceDetails) ?? this.parseData(settings?.kiwiSaverAdviceDetails),
      lifeAndRiskReviewDetails: this.parseData(data?.lifeAndRiskReviewDetails) ?? this.parseData(settings?.lifeAndRiskReviewDetails),

      houseCarContentsQuoteDetails: this.parseData(data?.houseCarContentsQuoteDetails) ??
      this.parseData(settings?.houseCarContentsQuoteDetails),

      otherAdviceNotes: this.parseData(data?.otherAdviceNotes) ?? this.parseData(settings?.otherAdviceNotes),
    };
  }

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }

  public static mapToUpsertSosSettings(data: ScopeOfServiceState) {
    return {
      ...data,
      intro: !!data.intro ? JSON.stringify(data.intro) : '',
      otherServiceNotes: !!data.otherServiceNotes
        ? JSON.stringify(data.otherServiceNotes)
        : '',
      otherSpecificClientGoals: !!data.otherSpecificClientGoals
        ? JSON.stringify(data.otherSpecificClientGoals)
        : '',
      kiwiSaverAdviceDetails: !!data.kiwiSaverAdviceDetails
        ? JSON.stringify(data.kiwiSaverAdviceDetails)
        : '',
      lifeAndRiskReviewDetails: !!data.lifeAndRiskReviewDetails
        ? JSON.stringify(data.lifeAndRiskReviewDetails)
        : '',
      houseCarContentsQuoteDetails: !!data.houseCarContentsQuoteDetails
        ? JSON.stringify(data.houseCarContentsQuoteDetails)
        : '',
      otherAdviceNotes: !!data.otherAdviceNotes
        ? JSON.stringify(data.otherAdviceNotes)
        : '',
      type: SettingsTypes.SOS,
    };
  }
}
