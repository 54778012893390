import { MortgageCustomerServiceState } from '../../models/services/mortgage/mortgage.model';
import MomentUtil from '../../../util/moment.util';
import { MortgageProviderGroupState } from '../../models/services/mortgage/mortgage-group.model';

/********************* Mortgage type sorter *********************
/** Policy match
  * @param a
  * @param b
  * @returns {number}
  */
export const policyMatch = (regExp: RegExp, str: string): boolean => {
	const match = str?.match(regExp);
	return match && str === match[0];
};

/** Mortgge type sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mortgageTypeSorter = (
	a: string,
	b: string,
	type: string
): number => {
	const matchA = policyMatch(new RegExp(type), a ? a?.toLowerCase() : '');
	const matchB = policyMatch(new RegExp(type), b ? b?.toLowerCase() : '');

	if (matchA < matchB) {
		return 1;
	} else if (matchA > matchB) {
		return -1;
	} else {
		return 0;
	}
};

/** Mortgage type group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mortgageTypeGroupSorter = (a: string, b: string): number => {
	return (
		mortgageTypeSorter(a, b, 'primary') || mortgageTypeSorter(a, b, 'split')
	);
};
/********************* End of Mortgage type sorter *********************


/********************* Id status sorter *********************
/** Id sorter
 * @param a
 * @param b
 * @returns {number}
 */
// tslint:disable-next-line: max-line-length
export const idSorter = (
	a: MortgageCustomerServiceState,
	b: MortgageCustomerServiceState
): number => a.customerServiceID - b.customerServiceID;
/********************* End of Id status sorter *********************

/********************* Mortgage status sorter *********************
 * @param a
 * @param b
 * @returns {number}
 */
export const mortgageStatusSorter = (
	a,
	b,
	type: string,
	exact?: boolean
): number => {
	let indexOfA = 0;
	let indexOfB = 0;
	if (exact) {
		indexOfA = a?.toLowerCase() === type ? 1 : -1;
		indexOfB = b?.toLowerCase() === type ? 1 : -1;
	} else {
		indexOfA = a ? a?.toLowerCase()?.indexOf(type) : '';
		indexOfB = b ? b?.toLowerCase()?.indexOf(type) : '';
	}

	if ((indexOfA !== -1) < (indexOfB !== -1)) {
		return 1;
	} else if ((indexOfA !== -1) > (indexOfB !== -1)) {
		return -1;
	} else {
		return 0;
	}
};

/** Mortgage status group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mortgageStatusGroupSorter = (a, b): number => {
	return (
		mortgageStatusSorter(a, b, 'current mortgage') ||
		mortgageStatusSorter(a, b, 'drawn down/settled') ||
		mortgageStatusSorter(a, b, 'awaiting application') ||
		mortgageStatusSorter(a, b, 'application received') ||
		mortgageStatusSorter(a, b, 'loan application submitted') ||
		mortgageStatusSorter(a, b, 'information required') ||
		mortgageStatusSorter(a, b, 'pre-approved loo') ||
		mortgageStatusSorter(a, b, 'conditional loo', true) ||
		mortgageStatusSorter(a, b, 'unconditional loo', true) ||
		mortgageStatusSorter(a, b, 'offer accepted') ||
		mortgageStatusSorter(a, b, 'loan instructions sent') ||
		mortgageStatusSorter(a, b, 'ready for settlement') ||
		mortgageStatusSorter(a, b, 'discharged') ||
		mortgageStatusSorter(a, b, 'not proceeding')
	);
};
/********************* End of Mortgage status sorter *********************/

/********************* Business mortgage sorter *********************/
export const businessMortgageTickedSorter = (a, b) => {
	return a.businessMortgage === b.businessMortgage
		? 0
		: a.businessMortgage
		? -1
		: 1;
};

export const businessMortgageUntickedWithRefixSorter = (a, b) => {
	if (
		(!a.businessMortgage && a.businessRefix === 'Complete') <
		(!b.businessMortgage && b.businessRefix === 'Complete')
	) {
		return 1;
	} else if (
		(!a.businessMortgage && a.businessRefix === 'Complete') >
		(!b.businessMortgage && b.businessRefix === 'Complete')
	) {
		return -1;
	} else {
		return 0;
	}
};
/********************* End of Business mortgage sorter *********************/

/********************* Mortgage IsActive sorter *********************/
/**isActive Sorter 
 * * @param a
 * @param b
 * @returns {number}
*/
export const mortgageIsActiveSorter = (a:number,b:number):number => {
	if(a===2&&b!==2)	return 1;
	if(a!==2&&b==2)	return -1;
	return 0;
}
/********************* End of isActive sorter *********************/

/** Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const subGroupSorter = (
	a: MortgageCustomerServiceState,
	b: MortgageCustomerServiceState
): number => {
	return (
		mortgageIsActiveSorter(a.isActive,b.isActive)||
		mortgageStatusGroupSorter(a.status, b.status) ||
		mortgageTypeGroupSorter(a.mortgageType, b.mortgageType) ||
		businessMortgageTickedSorter(a, b) ||
		businessMortgageUntickedWithRefixSorter(a, b) ||
		idSorter(a, b)
	);
};

/********************* Date sorter *********************/
export const convertDateToNZ = (d: any) => {
	const nzToday = MomentUtil.createMomentNz().format('LL');
	const date = d ? MomentUtil.formatDateToMoment(d) : null;
	const diffDate = date ? date.diff(nzToday, 'days') : null;
	if (diffDate < 0) {
		return null;
	}
	return diffDate;
};
/********************* End of date sorter *********************/
export const DescendingNumberSorter = (a, b) => {
	return +(a === null) - +(b === null) || +(a > b) || -(a < b);
};

// tslint:disable-next-line: max-line-length
export const mortgageDateSort = (
	a: MortgageCustomerServiceState,
	b: MortgageCustomerServiceState
) => {
	const convertA = convertDateToNZ(a.fixedPeriodEndDate);
	const convertB = convertDateToNZ(b.fixedPeriodEndDate);

	return DescendingNumberSorter(convertA, convertB);
};

export const totalLendingSorter = (a, b) => {
	return +(a === null) - +(b === null) || -(a > b) || +(a < b);
};

/** Main Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mainGroupSorter = (
	a: MortgageProviderGroupState,
	b: MortgageProviderGroupState
): number => {
	return (
		mortgageIsActiveSorter(a.isActive,b.isActive)||
		mortgageStatusGroupSorter(a.status, b.status) ||
		totalLendingSorter(a.totalLending, b.totalLending)
	);
};
