export enum ExportsType {
	CLIENT = 'CE',
	LEAD = 'LE',
	LR = 'LRE',
	UDE = 'UDE',
	KSE = 'KSE',
	APE = 'APE',
	ME = 'ME',
	FGE = 'FGE',
	AE = 'AE',
	IE = 'IE'
}

export enum ExportsTypeName {
	CLIENT = 'Clients Export',
	LEAD = 'Leads Export',
	LR = 'L&R Insurance Pipeline Export',
	UDE = 'User Details Export',
	KSE = 'KiwiSaver Pipeline Export',
	APE = 'Advice Process Pipeline Export',
	ME = 'Mortgage Pipeline Export',
	FGE = 'F&G Pipeline Export',
	AE = 'Activities Export',
	IE = 'Investment Export'
}

export enum ExportsStatus {
	CANCELLED = 4,
	STARTED = 3,
	PROCESSING = 2,
	COMPLETE = 1,
	ERROR = 0,
}

export interface ExportsState {
	staffId: number | null;
	exportId: string | null;
	reportCode: string  | null;
	reportLabel: string  | null;
	timeRemaining: number  | null;
  timeElapsed: number  | null;
	progress: number | null;
	status: ExportsStatus  | null;
	attempts: number | null;
	error: any;
}