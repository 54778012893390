import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable, delay, map, of, tap } from 'rxjs';
import { PropertyPurchaseService } from './funding-required/property-purchase/state/property-purchase.service';
import { LoanRefinanceService } from './funding-required/loan-refinance/state/loan-refinance.service';
import { FundingRequiredLoanService } from './funding-required/selling-section/loan-repaid/state/loan-repaid.service';
import { PropertySoldService } from './funding-required/selling-section/property-sold/state/property-sold.service';
import { TopupRequirementsService } from './funding-required/topup-requirements/state/topup-requirements.service';
import { LoanStructureService } from './loan-structure/state/loan-structure.service';
import { PurposeService } from './purpose/state/purpose.service';
import { CashDepositService } from './funding-required/cash-deposit/state/cash-deposit.service';
import { NoteService } from './notes/state/note.service';
import { SecurityService } from './security/state/security.service';

@Injectable()
export class CrtMortgageApplicationStepsCanActivateGuard
	implements CanActivate
{
	constructor(
		private propertyPurchaseService: PropertyPurchaseService,
		private loanRefinanceService: LoanRefinanceService,
		private fundingRequiredLoanService: FundingRequiredLoanService,
		private propertySoldService: PropertySoldService,
		private topupRequirementsService: TopupRequirementsService,
		private loanStructureService: LoanStructureService,
		private purposeService: PurposeService,
		private cashDepositService: CashDepositService,
		private noteService: NoteService,
		private security: SecurityService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return of(route).pipe(
			tap(() => {
				this.propertyPurchaseService.clearData();
				this.loanRefinanceService.clearData();
				this.fundingRequiredLoanService.clearData();
				this.propertySoldService.clearData();
				this.topupRequirementsService.clearData();
				this.loanStructureService.clearData();
				this.purposeService.clearData();
				this.cashDepositService.clearData();
				this.noteService.clearData();
				this.security.clear();
			}),
			delay(300),
			map(() => true)
		);
	}
}
