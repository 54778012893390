import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { BusinessConfigService } from '@domain/business-config/business-config.service';
import { FeatureToggleModel } from '@modules/special-features/state/special-features-settings.model';
import { sort } from 'ramda';
import { iif, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../core/base/api.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { objectUtil } from '../../../util/util';
import { ReferralOptionsModel, ReferralTypes } from './referral-options.model';
import { ReferralOptionsQuery } from './referral-options.query';
import { ReferralOptionsStore } from './referral-options.store';

interface SyncSettingsState {
	// Compare
	SyncToCompare?: boolean;
	SyncToCompareAdminEmail?: string;

	// Focus Media
	SyncToFocusMedia?: boolean;
	SyncToFocusMediaAdminEmail?: string;

	// External Adviser Leads
	SyncToEAL?: boolean;
	SyncToEALAdminEmail?: string;
	SendEmailToEAL?: boolean;
}

@Injectable()
export class ReferralOptionsService {
	referrals$ = this.referralOptionsQuery.referrals$;
	enabledReferrals = this.referralOptionsQuery.enabledReferrals$;
	activeReferrals = this.referralOptionsQuery.activeReferrals$;
	constructor(
		private api: ApiService,
		private referralOptionsStore: ReferralOptionsStore,
		protected loggerService: LoggerService,
		private referralOptionsQuery: ReferralOptionsQuery,
		private businessConfigService: BusinessConfigService,
	) {}

	clear(): void {
		applyTransaction(() => {
			this.referralOptionsStore.reset();
		});
	}

	getReferrals(staffId = 0, type: string) {
		const endpoint = `staff/${staffId}/settings/${type}`;
		return this.api.get<ReferralOptionsModel[]>(endpoint).pipe(
			map((data) => {
				const defaultPrio = data.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							Order: item?.ReferralValues?.Priority ?? defaultPrio,
						}))
					: [];
			}),
			map((data) => sort((a, b) => a?.Order - b?.Order, data)),
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : [];
					if (type === ReferralTypes.Referral) {
						this.referralOptionsStore.setReferrals(Object.values(state));
					} else if (type === ReferralTypes.Blanket) {
						this.referralOptionsStore.setBlanketReferrals(Object.values(state));
					}
				}),
			),
			catchError(() => of(undefined)),
		);
	}

	deleteReferral(settingsid, _referral) {
		const endpoint = `staff/settings/${settingsid}`;
		return this.api.delete<unknown>(endpoint).pipe(catchError((err) => of(err)));
	}

	addReferral(referral, referrals = null) {
		const ref = referrals ? referrals : [...this.referralOptionsQuery.referrals];
		ref.push(referral);
		this.referralOptionsStore.setReferrals(ref);
	}

	save(payload, type: string, refetchQuery = false) {
		const endpoint = 'staff/settings/referral-options';
		return this.api.put<ReferralOptionsModel[]>(endpoint, payload).pipe(
			tap(() =>
				applyTransaction(() => {
					if (type === ReferralTypes.Referral) {
						this.referralOptionsStore.setReferrals(Object.values(objectUtil.mapPascalCaseToCamelCase(payload)));
					} else if (type === ReferralTypes.Blanket) {
						this.referralOptionsStore.setBlanketReferrals(Object.values(objectUtil.mapPascalCaseToCamelCase(payload)));
					}
				}),
			),
			concatMap(() => {
				return iif(() => refetchQuery, this.getReferrals(0, type), of(payload));
			}),
			catchError((err) => of(err)),
		);
	}

	saveCompareIP(data: { isSync: boolean; adminEmail: string }) {
		return this.updateSyncSettings(
			{
				SyncToCompare: data.isSync,
				SyncToCompareAdminEmail: data.adminEmail,
			},
			'compare',
		);
	}

	saveFocusMedia(data: { isSync: boolean; adminEmail: string }) {
		return this.updateSyncSettings(
			{
				SyncToFocusMedia: data.isSync,
				SyncToFocusMediaAdminEmail: data.adminEmail,
			},
			'focusmedia',
		);
	}

	saveEAL(data: {
		isSync: boolean;
		adminEmail: string;
		emailAllLeads: boolean;
	}) {
		const newData = {
			SyncToEAL: data.isSync,
			SyncToEALAdminEmail: data.adminEmail,
			SendEmailToEAL: data.emailAllLeads ?? false,
		};
		const payload = Object.keys(newData)?.map((x) => ({
			MetaKey: x,
			Value: newData?.[x],
		})) as FeatureToggleModel[];
		return this.patchSyncSettings(payload);
	}

	updateSyncSettings(data: SyncSettingsState, code: string) {
		const endpoint = `businesses/settings/sync/${code}`;
		return this.api.put(endpoint, data).pipe(
			concatMap(() => this.businessConfigService.get(true)),
			catchError((err) => of(err)),
		);
	}

	patchSyncSettings(data: FeatureToggleModel[]) {
		const company = this.businessConfigService.companyCode();
		return this.businessConfigService.multiMetaKeyPatch(data, company);
	}
}
