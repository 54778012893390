import { util } from '../../../../util/util';
import { SettingsTypes } from '../../state/mortgage-settings.model';
import { ScopeOfServiceState } from './scope-of-service.model';

export class ScopeOfServiceMapper {
  public static mapToView(data: ScopeOfServiceState) {
    return {
      intro: this.parseData(data?.intro) || '',
      newPurchase: data?.newPurchase || false,
      lendingTopup: data?.lendingTopup || false,
      businessLending: data?.businessLending || false,
      refinance: data?.refinance || false,
      generalStructureReview: data?.generalStructureReview || false,
      otherService: data?.otherService || false,
      otherSpecificClientGoals:
        this.parseData(data?.otherSpecificClientGoals) || '',
      kiwiSaverAdvice: data?.kiwiSaverAdvice || false,
      kiwiSaverAdviceDetails: this.parseData(data?.kiwiSaverAdviceDetails) || '',
      lifeAndRiskReview: data?.lifeAndRiskReview || false,
      lifeAndRiskReviewDetails:
        this.parseData(data?.lifeAndRiskReviewDetails) || '',
      houseCarContentsQuote: data?.houseCarContentsQuote || false,
      houseCarContentsQuoteDetails:
        this.parseData(data?.houseCarContentsQuoteDetails) || '',
      otherAdvice: data?.otherAdvice || false,
      type: data?.type ?? SettingsTypes.SOS,
      referenceId: data?.referenceId ?? 0,
    };
  }

  public static mapSosSettingsToView(data: ScopeOfServiceState) {
    // For SOS Settings Only
    return {
      referenceId: 0,
      intro: this.parseData(data?.intro) || '',
      newPurchase: data?.newPurchase || 0,
      lendingTopup: data?.lendingTopup || 0,
      businessLending: data?.businessLending || 0,
      refinance: data?.refinance || 0,
      generalStructureReview: data?.generalStructureReview || 0,
      otherService: data?.otherService || 0,
      otherSpecificClientGoals: this.parseData(data?.otherSpecificClientGoals) || '',
      kiwiSaverAdvice: data?.kiwiSaverAdvice || 0,
      kiwiSaverAdviceDetails:
        this.parseData(data?.kiwiSaverAdviceDetails) || '',
      lifeAndRiskReview: data?.lifeAndRiskReview || 0,
      lifeAndRiskReviewDetails:
        this.parseData(data?.lifeAndRiskReviewDetails) || '',
      houseCarContentsQuote: data?.houseCarContentsQuote || 0,
      houseCarContentsQuoteDetails:
        this.parseData(data?.houseCarContentsQuoteDetails) || '',
        otherAdvice: data?.otherAdvice || 0,
      type: SettingsTypes.SOS,
    };
  }

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }

  public static mapToUpsertSosSettings(data: ScopeOfServiceState) {
    return {
      ...data,
      referenceId: 0,
      intro: !!data.intro ? JSON.stringify(data.intro) : '',
      otherSpecificClientGoals: !!data.otherSpecificClientGoals
        ? JSON.stringify(data.otherSpecificClientGoals)
        : '',
      kiwiSaverAdviceDetails: !!data.kiwiSaverAdviceDetails
        ? JSON.stringify(data.kiwiSaverAdviceDetails)
        : '',
      lifeAndRiskReviewDetails: !!data.lifeAndRiskReviewDetails
        ? JSON.stringify(data.lifeAndRiskReviewDetails)
        : '',
      houseCarContentsQuoteDetails: !!data.houseCarContentsQuoteDetails
        ? JSON.stringify(data.houseCarContentsQuoteDetails)
        : '',
      type: SettingsTypes.SOS,
    };
  }
}
