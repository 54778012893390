import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subscription, zip } from 'rxjs';
import { delay, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { BusinessConfig } from 'src/app/domain/business-config/business-config.model';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { ClientReviewTemplateQuery } from 'src/app/modules/crm/client-review-template/states/client-review-template.query';
import { PeopleService } from 'src/app/modules/crm/client-review-template/states/people/people.service';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { HtmlToPdfService } from 'src/app/modules/crm/crt-page/_shared/service/html-pdf/html-to-pdf.service';
import { AuthorityToProceedMapper } from 'src/app/modules/crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.mapper';
import { AuthorityToProceedSettingsService } from 'src/app/modules/crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.service';
import { ServiceAdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { DocumentState } from 'src/app/shared/models/client-review-template/declaration/declaration.model';
import { DisclosureDocumentMapper } from 'src/app/shared/models/client-review-template/disclosure-document/disclosure-document.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { CrtEmailModel } from 'src/app/shared/models/emails/crt/email.model';
import { Attachment } from 'src/app/shared/models/_general/attachment.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { EmailFormComponent } from '../email-form/email-form.component';

@Component({
	selector: 'app-atp-email-modal',
	templateUrl: './crt-email-modal.component.html',
	styleUrls: ['./crt-email-modal.component.scss'],
})
export class CrtEmailModalComponent implements ConfirmModalModel, OnInit {
	saveEmailFn$: (model: any) => Observable<any>;
	sendEmailFn$: (model: any) => Observable<any>;
	downloadOfferTermsFn$: (model: any) => Observable<any>;
	generateFinalisedEmailTemplateFn$: Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	emailSettings$: Observable<any>;
	emailDocumentTemplateBase64$: Observable<string>;
	emailDocumentTemplate: string;
	bodyContent = '<p></p>';
	shortCodes: object = null;
	peopleDropdown$ = this.crtQuery.peopleAndDependentsFromCRTOnly$.pipe(
		withLatestFrom(this.crtQuery.transferedSCIList$),
		map(([x, y]) => x?.filter((x) => !y?.find((i) => +i?.cRTId === +x?.cRTId)) || [])
	);
	peopleList: ViewDisplayValue[];
	showEmailTemplate: boolean;
	bodyId: number;
	attachments: Attachment[] = [];
	successMessage: string;
	hideEmailOnSuccessMsg: boolean;
	hideManualAttachment: boolean;
	isCrtOnPeopleMergeTag: boolean;
	attachFiles = true;
	offerTerms: DocumentState[];
	adviceProcess: ServiceAdviceProcessState;
	businessConfig$: Observable<BusinessConfig> = this.businessConfigQuery.businessConfig$;
	showAdviserOnRecipient: boolean;
	mergeTags$: Observable<MergeTagState[]>;
	defaultLinkDocumentTab: string;
	showNextBtn = false;
	next$: Observable<any>;
	getCrtInfoFn: (value: any) => Observable<any>;
	disableEmailUpdate = false;

	@ViewChild(EmailFormComponent) emailFormComponent: EmailFormComponent;
	documentInfo: any;
	queueAttachments: boolean;
	enableDefaultBcc: boolean;

	aotAttachmentGenerationState$ = new BehaviorSubject<'idle' | 'inprogress' | 'done'>('idle');

	attachmentsLoader$: () => Observable<Attachment[]>;

	attachloaderIsLoading: boolean;

	aotAttachmentGenerationSub = new Subscription();

	constructor(
		public bsModalRef: BsModalRef,
		private apService: AuthorityToProceedSettingsService,
		private peopleService: PeopleService,
		private crtQuery: ClientReviewTemplateQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private crtDocService: CrtDocumentService,
		private renderer: Renderer2,
		private htmlPdfService: HtmlToPdfService,
		private cdr: ChangeDetectorRef,
	) {
	}

	ngOnInit(): void {
		this.prepData();
		this.peopleDropdown$
			.pipe(
				map((data) => DisclosureDocumentMapper.mapCRTPeopleToEmailDd(data)),
				take(1)
			)
			.subscribe((data) => {
				this.peopleList = data;
			});

			if (!this.saveEmailFn$) {
				this.saveEmailFn$ = this.saveCrtEmail;
			}

		if (this.attachmentsLoader$) {
			this.attachloaderIsLoading = true;
			this.cdr.detectChanges();
			this.attachmentsLoader$()
				.pipe(finalize(() => (this.attachloaderIsLoading = false)))
				.subscribe((attachments) => {
					this.cdr.detectChanges();
					if (!attachments?.length) {
						return;
					}
					this.attachments.push(...attachments);

					const attachmentWithAOTGeneration = this.attachments.filter((a) => a.aheadOfTimeContentGeneration$);
					if (attachmentWithAOTGeneration?.length) {
						this.initializeAOTContentGeneration(attachmentWithAOTGeneration);
					}
				});
		}

	}

	ngOnDestroy(): void {
		this.aotAttachmentGenerationSub.unsubscribe();
	}

	private initializeAOTContentGeneration(attachments: Attachment[]): void {
		this.aotAttachmentGenerationState$.next('inprogress');
		const attachmentObs = attachments.map((a) => a.aheadOfTimeContentGeneration$.pipe(
			tap((content) => a.content = content)
		));
		const sub$ = zip(...attachmentObs)
			.pipe(
				finalize(() => {
					this.aotAttachmentGenerationState$.next('done');
				})
			).subscribe();
		this.aotAttachmentGenerationSub.add(sub$);
	}


	prepData() {
		combineLatest([this.emailSettings$])
			.pipe(
				filter(([data]) => !!data),
				tap(([data]) => (this.bodyId = +data?.body || 0)),
				map(([data]) => AuthorityToProceedMapper.mapEmailSettingsData(data)),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.apService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.apService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	sent() {
		this.bsModalRef.hide();
	}

	decline() {
		if (!!this.decline$) {
			this.decline$.pipe(take(1)).subscribe();
		}
		this.bsModalRef.hide();
	}

	downloadPdfAttachment(attachment: Attachment) {
		if (attachment.generateContentCallback$) {
			return attachment.generateContentCallback$
				.pipe(
					delay(100),
					mergeMap((content) => (this.renderPdfDownload(content, attachment.newPdfOptions))),
					take(1)
				)
				.subscribe();
		}

		// Prevents download if file is just uploaded by user
		if (attachment.type && attachment.type !== 'uploaded') {
			this.crtDocService.getDocument(attachment.documentId).pipe(
				tap((doc) => this.pdfDownloadFromUrl(doc.DocumentLink)),
				take(1)
			).subscribe();
		}

		if (attachment.fileUrl) {
			this.downloadFile(attachment.fileUrl, attachment.fileName);
		}
	}

	downloadFile(fileUrl: any, fileName: string) {
		this.crtDocService
			.getDocumentLink(fileUrl, {
				responseType: 'blob',
			})
			.pipe(
				tap((file) => {
					const newFile = new File([file], fileName);
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(newFile);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', fileName);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			)
			.subscribe();
	}

	saveCrtEmail(data) {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === 'FPD',
					this.peopleService.updateDependent(data),
					this.peopleService.updatePeople(data)
				)
			)
		);
	}

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, pdfOptions?.FileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	pdfDownloadFromUrl = (fileUrl, pdfOptions?) => {
		const name = `${pdfOptions?.FileName}.pdf`;
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', fileUrl);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	openAttachmentModal() {
		this.emailFormComponent.openUploadOptionModal();
	}

	addAttachment(attachment: Attachment) {
		this.attachments.push(attachment);
	}

	removeAttachment(index: number) {
		this.attachments.splice(index, 1);
	}

	next(): void {
		this.bsModalRef.hide();
		this.next$?.pipe(take(1)).subscribe();
	}


}

export interface ConfirmModalModel {
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
