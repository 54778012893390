import * as R from 'ramda';
import { LoanRefinanceState } from '../application-steps/funding-required/loan-refinance/state/loan-refinance.model';
import { FundingRequiredLoan } from '../application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.model';

export class ApplicationMapper {
	public static getAllMortgageLiabilities(
		activeBank: string,
		loanRefinance: LoanRefinanceState[],
		ffMortgages,
		ffLiabilities
	) {
		const sumMortgages = this.getListWithBankTotal(
			activeBank,
			ffMortgages || [],
			{
				bankKey: 'lender',
				valueKey: 'loanValue',
				activeBank,
			}
		);

		const sumLiabilities = this.getListWithBankTotal(
			activeBank,
			ffLiabilities || [],
			{
				bankKey: 'lender',
				valueKey: 'loanBalance',
				activeBank,
			}
		);

		// Filter loans to refinances that are created only from Funding Required
		// If linkedCRTID is null or 0, it is created from Funding Required
		const loanRefinanceList =
			loanRefinance?.filter((x) => !x?.linkedCRTId) || [];

		const loanRefinanceTotal = this.getListWithBankTotal(
			activeBank,
			loanRefinanceList,
			{
				bankKey: 'lender',
				valueKey: 'loanValue',
				activeBank,
			}
		);

		// TAPNZ-9292 Sum of all Mortgages (from Fact Find) + Liabilites (from Fact Find) + Loans to Refinance (added from Funding Required)
		// Loans to be repaid is not added on the computation because it already syncs back to Fact Find
		return R.sum([sumMortgages || 0, loanRefinanceTotal || 0]);
	}

	public static getListWithBankTotal(activeBank: string, list, options) {
		return list
			.filter((item) => {
				if (options.activeBank) {
					if (options.filterKey) {
						return (
							item[options.bankKey] === activeBank &&
							item[options.filterKey] === options.filterKeyValue
						);
					}
					return item[options.bankKey] === activeBank;
				} else {
					if (options.filterKey) {
						return item[options.filterKey] === options.filterKeyValue;
					}
					return true;
				}
			})
			.map((item) => item[options.valueKey])
			.reduce((a, b) => a + b, 0);
	}

	public static getListTotal(list, options) {
		return list
			.map((item) => item[options.valueKey])
			.reduce((a, b) => a + b, 0);
	}
}
