<ng-template #uploadOptionModal>
	<button
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="optionModalRef.hide()"
	>
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Attach Document</h4>
	</div>
	<div class="modal-body">
		<div class="clearfix py-3">
			<div class="d-flex justify-content-center">
				<button
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-2"
					(click)="uploadDocuments(); optionModalRef.hide()"
				>
					Upload New
				</button>
				<button
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4"
					(click)="onLinkDocumentsClicked()"
				>
					Link Document
				</button>
			</div>
		</div>
	</div>
</ng-template>
