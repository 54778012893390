import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrtPageRoutingModule } from './crt-page-routing.module';
import { CrtMortgageModule } from './crt-mortgage/crt-mortgage.module';
import { CrtKiwiSaverModule } from './crt-kiwisaver/crt-kiwisaver.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CrtPageGuard } from './crt-page.guard';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		SharedModule,
		CrtPageRoutingModule,
		CrtMortgageModule,
		CrtKiwiSaverModule,
	],
	providers: [
		CrtPageGuard,
	],
})
export class CrtPageModule {}
