import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { LRCTStore } from './lrct.store';
import { LRCommissionTracker } from './lrct.model';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class LRCTService {
  private readonly endpoint = 'widgets?type=LRCT';
  /**
   *
   */
  constructor(
    private api: ApiService,
    private store: LRCTStore
  ) { }


  /**
   * Fetch and store Refix mortgage data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetLRCommissionTracker(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);
    this.api.post<LRCommissionTracker>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update({LRCommissionTracker: res}),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
