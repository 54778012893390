<div class="row pb-0 pl-3 pb-2 pt-4 disclosure-merge-tags">
  <div class="col-12">
    <div class="row mb-4 disclosure-merge-tags__header">
      <div class="col-12 col-lg-3 d-none d-lg-block">
        <label class="disclosure-merge-tags__header__tags font-weight-bold"
          >Merge Tags</label
        >
      </div>
      <div class="col-12 col-lg-4 d-none d-lg-block">
        <label class="disclosure-merge-tags__header__tags font-weight-bold"
          >Description</label
        >
      </div>
      <div class="col-12 col-lg-4 d-none d-lg-block">
        <label class="disclosure-merge-tags__header__tags font-weight-bold"
          >Sample</label
        >
      </div>
    </div>

    <div *ngFor="let item of mergeTagsList">
      <div class="row mb-4 disclosure-merge-tags__values">
        <div
          class="col-12 col-lg-3 disclosure-merge-tags__values__tags text-truncate"
        >
          %{{ item.metaKey }}%
        </div>
        <div class="col-12 col-lg-4 disclosure-merge-tags__values__description">
          {{ item.description }}
        </div>
        <div class="col-12 col-lg-4 disclosure-merge-tags__values__sample" *ngIf="item.value">
          {{ item.value }}
        </div>
				<div class="col-12 col-lg-4 disclosure-merge-tags__values__sample font-italic" *ngIf="!item.value && item.secondaryValue">
          ex. {{ item.secondaryValue }}
        </div>
      </div>
    </div>
  </div>
</div>
