<div class="container-fluid px-0 servicing-blank-templates">
	<form [formGroup]="form">
		<div
			class="d-none d-lg-flex servicing-blank-templates__header form-row pt-1 pb-2"
		>
			<div class="col-lg-10 servicing-blank-templates__header__template-name">
				<span class="font-weight-bold">Template Name</span>
			</div>
			<div
				class="col-lg-2 text-center servicing-blank-templates__header__actions"
			>
				<span class="font-weight-bold">Actions</span>
			</div>
		</div>
		<div formArrayName="templateList">
			<div
				class="d-flex servicing-blank-templates__content form-row pt-1"
				*ngFor="let template of TemplateList.controls; let i = index"
				[formGroupName]="i"
			>
				<div
					class="col-12 col-lg-9 servicing-blank-templates__content__template-name"
				>
					<label for="template_{{ i }}">{{
						template.get("documentName").value
					}}</label>
				</div>
				<div
					class="col-1 d-lg-flex d-inline-block text-right servicing-blank-templates__content__loading"
				>
					<app-manual-loader-mini
						[isLoading]="template.get('isLoading').value"
						class="mt-1"
					></app-manual-loader-mini>
				</div>
				<div
					class="col-auto col-lg-2 d-lg-flex d-inline-block text-center align-items-center servicing-blank-templates__content__actions"
				>
					<button
						id="download"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="downloadTemplate(i)"
						[disabled]="template.get('isLoading').value"
					>
						<i class="material-icons md-20"> visibility </i>
					</button>
					<button
						id="update"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="template.get('isLoading').value"
						(click)="updateTemplate(i)"
					>
						<i class="material-icons md-20"> edit </i>
					</button>
					<button
						id="delete"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="template.get('isLoading').value"
						(click)="removeTemplate(i)"
					>
						<i class="material-icons md-20"> clear </i>
					</button>
				</div>
			</div>
		</div>
		<!-- Upload Template -->
		<div class="d-flex servicing-blank-templates__content form-row pt-1">
			<div class="col-12 col-lg-12 servicing-blank-templates__content__upload">
				<span
					id="uploadTemplate"
					class="upload-template"
					(click)="addNewTemplate()"
					>+ Upload Template</span
				>
			</div>
		</div>
	</form>
</div>
