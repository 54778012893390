import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleDataTableComponent } from './simple-data-table.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { SimplePaginatorModule } from '@shared/components/simple-paginator/simple-paginator.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
	declarations: [SimpleDataTableComponent],
	exports: [SimpleDataTableComponent],
	imports: [CommonModule, SharedModule, MatTableModule, SimplePaginatorModule],
})
export class SimpleDataTableModule {}
