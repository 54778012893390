import { Injectable } from '@angular/core';
import { EmailStatusModel } from './email-status.model';
import { EmailStatusStore } from './email-status.store';
import { EmailStatusQuery } from './email-status.query';
import { ApiService } from '../../../../core/base/api.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { tap, map, retry, catchError } from 'rxjs/operators';
import { EmailStatusResponse } from '../../../../shared/models/emails/email-status/email-status.model';
import { applyTransaction } from '@datorama/akita';
import { throwError, of } from 'rxjs';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Injectable()
export class EmailStatusService {
	status = [];

	constructor(
		private api: ApiService,
		private store: EmailStatusStore,
		private query: EmailStatusQuery,
		protected loggerService: LoggerService
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	reloadData() {
		const data = this.query.getAll();
		this.store.set([]);
		this.store.set(data);
	}

	getSettings() {
		this.status = [
			{ name: 'Accepted', value: 'Accepted' },
			{ name: 'BadRequest', value: 'BadRequest' },
			{ name: 'Bounce', value: 'bounce' },
			{ name: 'Blocked', value: 'blocked' },
			{ name: 'Click', value: 'click' },
			{ name: 'Deferred', value: 'deferred' },
			{ name: 'Delivered', value: 'delivered' },
			{ name: 'Dropped', value: 'dropped' },
			{ name: 'Forbidden', value: 'Forbidden' },
			{ name: 'Group_Resubscribe', value: 'group_resubscribe' },
			{ name: 'Group_Unsubscribe', value: 'group_unsubscribe' },
			{ name: 'Open', value: 'open' },
			{ name: 'Processed', value: 'processed' },
			{ name: 'Spamreport', value: 'spamreport' },
			{ name: 'Unsubscribe', value: 'unsubscribe' },
			{ name: 'Invalid Sender Email', value: 'Invalid Sender Email' },
			{ name: 'Invalid Recipient Email', value: 'Invalid Recipient Email' },
			{ name: 'Sending In Progress', value: 'Sending In Progress' },
		];
		return of(this.status).pipe(
			map((x) => {
				this.store.setStatus(x);
			})
		);
	}

	getReport(values) {
		const endpoint = `emails/report`;
		const body = values;
		return this.api.post3<EmailStatusResponse>(endpoint, body).pipe(
			tap((result) => {
				const topResult = {
					total: result.Total,
					success: result.TotalSuccess,
					failed: result.TotalFailed,
				};
				this.store.set(result.EmailStatuses);
				if (result.EmailStatuses[0]) {
					const columnsData = Object.keys(result.EmailStatuses[0]);
					this.store.setColumns(columnsData);
				}
				this.store.setTableResult(topResult);
			})
		);
	}

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.store.setSort(propSort, sort);
	}

	resend(row) {
		const endpoint = `emails/resend/${row}`;
		return this.api.post3<string>(endpoint);
	}

	exportStatus(req: EmailStatusModel) {
		return this.api.post4('export/email-status', req).pipe(
			map((x) => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			retry(1),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.shared.export.error);
				return throwError(err);
			})
		);
	}

	tryParseJSON(jsonString: string): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}
}
