export interface PurposeDetailsState {
  adviceProcessId: number;
  cRTId: number;
  types: string[];
  bank: string;
  currentPrimaryBank: string;
  isPreApproval: boolean;
  isSellingProperties: boolean;
  reason: string;
  parentCRTId: number;
}

export interface PurposeDetails {
  AdviceProcessId: number;
  CRTId: number;
  Types: string[];
  Bank: string;
  CurrentPrimaryBank: string;
  IsPreApproval: boolean;
  IsSellingProperties: boolean;
  Reason: string;
  ParentCRTId: number;
}


export enum PurposeTypes {
  NewPurchase = 'New Purchase',
  LendingTopup = 'Lending Topup',
  Refinance = 'Refinance',
  Other = 'Other',
  BusinessLending = 'Business Lending'
}
