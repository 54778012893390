<ng-content select="app-manual-loader"></ng-content>
<div class="p-3 pr-5 position-relative">
  <h1 class="widget__title text-capitalize">
    {{ headerText }}
    <ng-container *ngIf="subHeaderText">
      <span class="widget__title__subheader">{{ subHeaderText }}</span>
    </ng-container>

    <ng-container *ngIf="subHeaderInlineText">
      <span class="widget__title__inline-subheader"> {{ subHeaderInlineText }} </span>
    </ng-container>
  </h1>
  <div class="widget__title-divider"></div>
  <div class="widget__drag-handle">
    <i>
      <svg id="SVGDoc" width="15" height="15" viewBox="0 0 15 15">
        <g>
          <g>
            <path
              d="M11.99829,10.29994v0l2.99957,-2.80011v0l-2.99957,-2.8001v0v1.80013v0h-3.5165v0v-3.49993v0h1.79991v0l-2.78277,-2.99993v0l-2.79977,2.99993v0h1.79991v0v3.51693v0h-3.4995v0v-1.81696v0l-2.99957,2.8001v0l2.99957,2.8001v0v-1.81696v0h3.5165v0v3.51693v0h-1.81674v0l2.79977,2.99993v0l2.79976,-2.99993v0h-1.81674v0v-3.51693v0h3.5165v0z"
              fill-opacity="1"
              id="drag_handle_{{ widgetCode }}"
            ></path>
          </g>
        </g>
      </svg>
    </i>
  </div>
</div>
<div #widgetControl class="px-3 widget__control" [class.widget__control--with-control]="hasControl">
  <ng-content select="input"></ng-content>
</div>
<div class="widget__content pb-3 px-3" [class.widget__content--with-control]="hasControl">
  <ng-content></ng-content>
</div>
