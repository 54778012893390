import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { Fields, getInvalidWarning } from '@shared/error-message/error-message';
import { finalize, take, tap } from 'rxjs';
import { TlMobileSettingsQuery } from './state/tl-mobile-settings.query';
import { TlMobileSettingsService } from './state/tl-mobile-settings.service';

@Component({
	selector: 'app-tl-mobile-settings',
	templateUrl: './tl-mobile-settings.component.html',
})
export class TlMobileSettingsComponent implements OnInit {
	iosVersion$ = this.query.iosVersion$;
	isSaving = false;
	isEdit = false;
	form: UntypedFormGroup;
	initialIosVersion = '';
	constructor(
		private query: TlMobileSettingsQuery,
		private fb: UntypedFormBuilder,
		private service: TlMobileSettingsService,
		private loggerService: LoggerService,
	) {
		this.buildForm();
	}

	get iosVersionForm() {
		return this.form.get('iosVersion');
	}

	ngOnInit(): void {
		this.iosVersion$
			.pipe(
				take(1),
				tap((x) => {
					this.initialIosVersion = x;
					this.iosVersionForm.setValue(x);
				}),
			)
			.subscribe();
		this.form.disable();
	}

	buildForm() {
		this.form = this.fb.group({
			iosVersion: ['', Validators.required],
		});
	}

	cancel() {
		if (this.isEdit) {
			this.iosVersionForm.setValue(this.initialIosVersion);
			this.form.disable();
			this.isEdit = false;
		}
	}

	submitBtnClick(button: HTMLElement) {
		if (!this.isEdit) {
			this.form.enable();
			this.isEdit = !this.isEdit;
		} else {
			if (this.form.valid) {
				this.isSaving = true;
				this.service
					.updateIosAppVersion(this.iosVersionForm.value)
					.pipe(
						take(1),
						finalize(() => {
							this.initialIosVersion = this.iosVersionForm.value;
							this.isSaving = false;
						}),
					)
					.subscribe();
				this.form.disable();
				this.isEdit = !this.isEdit;
			} else {
				if (this.iosVersionForm.invalid) {
					this.loggerService.Warning({}, getInvalidWarning(Fields.IosVersion));
				}
			}
		}

		button.blur();
	}
}
