import { Component, OnInit, Renderer2, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReportService } from '../state/report.service';
import { ReportRequest } from '../state/report.model';
import { ReportQuery } from '../state/report.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { strUtil } from '../../../../util/util';
import MomentUtil from '../../../../util/moment.util';
import { throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { take, tap } from 'rxjs/operators';
import { ExportsStatus } from '@modules/exports/state/exports.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { isEmpty, isNil } from 'ramda';

@Component({
	selector: 'app-report-form',
	templateUrl: './report-form.component.html',
	styleUrls: ['./report-form.component.scss'],
})
export class ReportFormComponent implements OnInit {
	@ViewChild(PopoverDirective) popover: PopoverDirective;

  exportStatus$ = this.exportsQuery.status$;

	@Input() reportTypes: ViewDisplayValue[];

	isLoading$ = this.reportQuery.isLoading$;
	isExporting$ = this.reportQuery.isExporting$;
	reportType$ = this.reportQuery.reportType$;

	form: UntypedFormGroup = this.fb.group({
		ReportType: this.fb.control(null, [Validators.required]),
		DateMin: this.fb.control(null),
		DateMax: this.fb.control(null),
	});

	constructor(
		private fb: UntypedFormBuilder,
		private reportService: ReportService,
		private reportQuery: ReportQuery,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private exportsQuery: ExportsQuery,
		private businessQuery: BusinessConfigQuery,
	) {}

	ngOnInit() {
		this.filterReportTypeByBC();
	}

	// Filters Report Type dropdown based on enabled business config
	filterReportTypeByBC(): void {
		this.businessQuery.businessConfig$.pipe(
      take(1),
			tap((businessConfig) => {
				if (isNil(businessConfig?.ReferralOption) || (!businessConfig?.ReferralOption && !isEmpty(this.reportTypes))) {
					this.reportTypes.filter(rt => rt.value !== 'Referral Report');
				}
			})
    ).subscribe();
	}

	export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		let reportCode = '';

		switch (this.form.value.ReportType) {
			case 'Adviser Activity Report':
				reportCode = 'aar';
				break;
			case 'Adviser Summary Report':
				reportCode = 'asr';
				break;
			case 'Call Centre KPI Report':
				reportCode = 'cckr';
				break;
			case 'Anniversary Report':
				reportCode = 'ar';
				break;
			case 'Client Review Report':
				reportCode = 'crr';
				break;
			case 'Birthday Report':
				reportCode = 'br';
				break;
			case 'Refix Report':
				reportCode = 'rr';
				break;
			case 'Internal - Start Date':
				reportCode = 'isdr';
				break;
			case 'Internal - Anniversary Date':
				reportCode = 'iadr';
				break;
			case 'Internal - Cancellation Date':
				reportCode = 'icdr';
				break;
			case 'F&G Renewal Date':
				reportCode = 'fgrdr';
				break;
			case 'Completed KiwiSaver':
				reportCode = 'ckr';
				break;
			case 'Retention Report':
				reportCode = 'rtr';
				break;
			case 'Claims Report':
				reportCode = 'cr';
				break;
			case 'Internal - Contact Status':
				reportCode = 'csr';
				break;
			case 'Advice Process Complaints Report':
				reportCode = 'apc';
				break;
			case 'Client Services Report':
				reportCode = 'ccsr';
				break;
			case 'Vulnerable Client Report':
				reportCode = 'vcr';
				break;
			case 'Compliance - Open Advice Process Pivot Table':
				reportCode = 'croappt';
				break;
			case 'Compliance - Closed KiwiSaver Advice Processes':
				reportCode = 'crckap';
				break;
			case 'Compliance - Advice Processes':
				reportCode = 'crcap';
				break;
			case 'Referral Status Report (External Adviser)':
				reportCode = 'rsr';
				break;
			case 'New Business Stats':
				reportCode = 'nbs';
				break;
			case 'Renewal Stats':
				reportCode = 'rs';
				break;
			case 'Cancellation Report':
				reportCode = 'cxr';
				break;
			case 'Lead Summary Report':
				reportCode = 'lsr';
				break;
			case 'Confirmation Call Report':
				reportCode = 'ccr';
				break;
			case 'Mortgage - Expected Settlement':
				reportCode = 'mesr';
				break;
			case 'Mortgage - Loan Drawdown':
				reportCode = 'mldr';
				break;
			case 'Referral Report':
				reportCode = 'refr';
				break;
			case 'L&R Opportunity Outcome Report':
				reportCode = 'lroor';
				break;
			case 'Mortgage Opportunity Outcome Report':
				reportCode = 'moor';
				break;
			case 'Advice Process Claims Report':
				reportCode = 'APCR';
				break;
		}

		const req = {
			ReportType: reportCode,
			DateMin: MomentUtil.formatToServerDate(this.form.value.DateMin),
			DateMax: MomentUtil.formatToServerDate(this.form.value.DateMax),
		} as ReportRequest;
		this.reportService.export(req).subscribe(
			(file) => {
				const name = `${this.route.snapshot.paramMap.get(
					'companyCode'
				)}-${strUtil.removeSpace(this.form.value.ReportType)}.csv`;
				const a = this.renderer.createElement('a');
				this.renderer.setStyle(a, 'display', 'none');
				const url = window.URL.createObjectURL(file);
				this.renderer.setAttribute(a, 'href', url);
				this.renderer.setAttribute(a, 'download', name);
				a.click();
				window.URL.revokeObjectURL(url);
			},
			(err) => throwError(err)
		);
	}

	showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

	// TODO: Low prio for now
	// run(f) {
	//   this.reportService.runReports({
	//     ReportType: f.value.ReportType,
	//     DateMin: f.value.DateMin,
	//     DateMax: f.value.DateMax,
	//   } as ReportRequest);
	// }
}
