import { TableColumn } from '@swimlane/ngx-datatable';
import produce from 'immer';
import * as R from 'ramda';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { Row } from '../state/lead-search.model';
import { LeadSearch } from '../lead-search-request.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { datatableUtil } from 'src/app/util/datatable.util';

/**
 * possible metakeys
 */
export type Metakey =
	'bulk' |
  'Name' |
  'Additional Contacts' |
  'Create Date' |
  'Alt. Adviser' |
  'Email' |
  'Last Note' |
  'Lead Status' |
  'Lead Type' |
  'Lead Origin' |
  'Mobile' |
  'Client Next Activity' |
  'User Next Activity' |
  'Physical Address' |
	'Linked Contacts' |
	'Lead Gen' |
  'Adviser' |
  'LR Adviser' |
  'GI Adviser' |
  'Mortgage Adviser' |
  'FG Adviser' |
  'KS Adviser' |
  'Investment Adviser' |
  'Combined Income' |
  'Rank' |
	'Adviser Gen';


/** possible control types. Move this somewhere more appropriate. */
export type controlType = 'display' | 'long-display' | 'textbox'
  | 'dropdown' | 'note' | 'date' | 'address' | 'activity' | 'display-date'
  | 'multiselect' | 'checkbox' | 'money';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
type EnhancedTableColumn = (
  TableColumn &
  {
    /** Metakey of the column. Used for indexing ui state and requesting changes */
    metakey: Metakey,
    /** Property key in rows. */
    prop: keyof Row,
    /** Replaces `ascFn` and `descFn`. Provides sortable values. */
    // sortValueGetter: ((field: FieldMetadata<any>, choices?: ViewDisplayValue[]) => any),
    sortValueGetter: (
      field: FieldMetadata<any>,
      choices?: ViewDisplayValue[],
      row?: Row
    ) => any;
    /** Dropdown choices used for fields in this column.
     * To be filled up in Query file.
     */
    choices?: ViewDisplayValue[],
    /** Dropdown choices used for fields in this column.
     * To be filled up in Query file.
     */
    choicesAsObject?: { [key: string]: string },
    /** Type of control.
     * Determines which datatable field control to use.
     * Carefully check that the type used is same here and in the template.
     */
    controlType: controlType;
    /** indicator if column is required */
    isRequired?: boolean;
    columnId?: string;
	  fieldId?: string;
  });

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (choices: ViewDisplayValue[], field: FieldMetadata<any>) => {
  if (!field.value) {
    return '';
	}

  const choiceItem = choices?.find(x => x.value === field.value);
  return R.propOr<string, ViewDisplayValue, string>('', 'display' as keyof FieldMetadata<any>, choiceItem);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (choices: ViewDisplayValue[], field: FieldMetadata<any>) => {
  if (!field.value) {
    return '';
  }

  const choiceIndex = choices?.findIndex(x => x.value === field.value);
  return choiceIndex > -1 ? choiceIndex : undefined;
};

const getMultiselectValues = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value || field.value === '[]' || field.value.length === 0) {
		return '';
	}
	const values: string[] =
		typeof field.value === 'string'
			? Array.isArray(JSON.parse(field.value))
				? JSON.parse(field.value)
				: []
			: [];
	return values
		?.map((v) =>
			R.propOr(
				'',
				'display',
				choices?.find((c) => `${c.value}` === `${v}`)
			)
		)
		?.filter((x) => x)
		?.join(', ');
};

/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
  const b = R.defaultTo('', date)?.split(/\D/);
  return b?.reverse()?.join('/');
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
  const splicedDate = R.defaultTo('', note)?.slice(0, 10);
  return reformatDateString(splicedDate);
};

/**
 * possible columns with their own configurations
 */
export const columns: EnhancedTableColumn[] = [
  {
		metakey: 'bulk',
		prop: 'bulk',
		name: 'bulk',
		width: 60,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold fixed-column',
		controlType: 'checkbox',
    sortValueGetter: (f, c) => f.value,
    columnId: datatableUtil.formatColumnId('bulk'),
		fieldId: datatableUtil.formatFieldId('bulk')
  },
  {
    metakey: 'Name',
    prop: 'Name',
    name: 'Client Name',
    width: 200,
    headerClass: 'secondary-background',
    cellClass: 'font-weight-bold fixed-column',
    sortValueGetter: (f, c) => f.value,
    controlType: 'display',
    columnId: datatableUtil.formatColumnId('Name'),
		fieldId: datatableUtil.formatFieldId('Name')
  },
  {
    metakey: 'Additional Contacts',
    prop: 'AdditionalContacts',
    name: 'Additional Contacts',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('Additional Contacts'),
		fieldId: datatableUtil.formatFieldId('Additional Contacts')
  },
  {
    metakey: 'Adviser', // TODO: remove when we have the go signal to remove
    prop: 'Adviser',
    name: 'Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser')
  },
  {
    metakey: 'Create Date',
    prop: 'CreateDate',
    name: 'Created Date',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'display-date',
    columnId: datatableUtil.formatColumnId('Create Date'),
		fieldId: datatableUtil.formatFieldId('Create Date')
  },
  { // TODO: remove this once confirmed
    metakey: 'Alt. Adviser',
    prop: 'AltAdviser',
    name: 'Alt. Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'multiselect',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('Alt. Adviser'),
		fieldId: datatableUtil.formatFieldId('Alt. Adviser')
  },
  {
    metakey: 'Email',
    prop: 'Email',
    name: 'Email',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'textbox',
    columnId: datatableUtil.formatColumnId('Email'),
		fieldId: datatableUtil.formatFieldId('Email')
  },
  {
    metakey: 'Last Note',
    prop: 'LastNote',
    name: 'Last Note',
    width: 300,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
    controlType: 'note',
    columnId: datatableUtil.formatColumnId('Last Note'),
		fieldId: datatableUtil.formatFieldId('Last Note')
  },
  {
    metakey: 'Lead Status',
    prop: 'LeadStatus',
    name: 'Lead Status',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownOrder(c, f),
    controlType: 'dropdown',
    columnId: datatableUtil.formatColumnId('Lead Status'),
		fieldId: datatableUtil.formatFieldId('Lead Status')
  },
  {
    metakey: 'Lead Type',
    prop: 'LeadType',
    name: 'Lead Type',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownOrder(c, f),
    controlType: 'dropdown',
    columnId: datatableUtil.formatColumnId('Lead Type'),
		fieldId: datatableUtil.formatFieldId('Lead Type')
  },
  {
    metakey: 'Lead Origin',
    prop: 'LeadOrigin',
    name: 'Lead Origin',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    columnId: datatableUtil.formatColumnId('Lead Origin'),
		fieldId: datatableUtil.formatFieldId('Lead Origin')
  },
  {
    metakey: 'Mobile',
    prop: 'Mobile',
    name: 'Mobile',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'textbox',
    columnId: datatableUtil.formatColumnId('Mobile'),
		fieldId: datatableUtil.formatFieldId('Mobile')
  },
  {
    metakey: 'Client Next Activity',
    prop: 'ClientNextActivity',
    name: 'Client Next Activity',
    width: 200,
    headerClass: 'secondary-background',
    cellClass: 'activity',
    sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
    controlType: 'activity',
    columnId: datatableUtil.formatColumnId('Client Next Activity'),
		fieldId: datatableUtil.formatFieldId('Client Next Activity')
  },
  {
    metakey: 'User Next Activity',
    prop: 'UserNextActivity',
    name: 'Your Next Activity',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
    controlType: 'activity',
    cellClass: 'activity',
    columnId: datatableUtil.formatColumnId('User Next Activity'),
		fieldId: datatableUtil.formatFieldId('User Next Activity')
  },
  {
    metakey: 'Physical Address',
    prop: 'PhysicalAddress',
    name: 'Physical Address',
    width: 300,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'address',
    columnId: datatableUtil.formatColumnId('Physical Address'),
		fieldId: datatableUtil.formatFieldId('Physical Address')
  },
  {
    metakey: 'Linked Contacts',
    prop: 'LinkedContacts',
    name: 'Linked Contacts',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('Linked Contacts'),
		fieldId: datatableUtil.formatFieldId('Linked Contacts')
  },
  {
    metakey: 'Lead Gen',
    prop: 'LeadGen',
    name: 'Lead Gen',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: false,
    columnId: datatableUtil.formatColumnId('Lead Gen'),
		fieldId: datatableUtil.formatFieldId('Lead Gen')
  },
  {
    metakey: 'Combined Income',
    prop: 'CombinedIncome',
    name: 'Combined Income',
    width: 200,
    headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
    controlType: 'money',
    columnId: datatableUtil.formatColumnId('CombinedIncome'),
		fieldId: datatableUtil.formatFieldId('CombinedIncome')
  },
  {
    metakey: 'Rank',
    prop: 'Rank',
    name: 'Rank',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'display',
    columnId: datatableUtil.formatColumnId('Rank'),
		fieldId: datatableUtil.formatFieldId('Rank')
  },
  {
    metakey: 'Name',
    prop: 'Name',
    name: 'Client Name',
    width: 200,
    headerClass: 'secondary-background',
    cellClass: 'font-weight-bold fixed-column',
    sortValueGetter: (f, c) => f.value,
    controlType: 'display',
    columnId: datatableUtil.formatColumnId('Name'),
		fieldId: datatableUtil.formatFieldId('Name')
  },
];

// Adviser Rework Columns
export const adviserReworkColumns: EnhancedTableColumn[] = [
  {
    metakey: 'LR Adviser',
    prop: 'LRAdviser',
    name: 'L&R Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('LRAdviser'),
		fieldId: datatableUtil.formatFieldId('LRAdviser')
  },
  {
    metakey: 'GI Adviser',
    prop: 'GroupInsuranceAdviser',
    name: 'Group Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('GroupInsuranceAdviser'),
		fieldId: datatableUtil.formatFieldId('GroupInsuranceAdviser')
  },
  {
    metakey: 'Mortgage Adviser',
    prop: 'MortgageAdviser',
    name: 'Mortgage Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('MortgageAdviser'),
		fieldId: datatableUtil.formatFieldId('MortgageAdviser')
  },
  {
    metakey: 'FG Adviser',
    prop: 'FGAdviser',
    name: 'F&G Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('FGAdviser'),
		fieldId: datatableUtil.formatFieldId('FGAdviser')
  },
  {
    metakey: 'KS Adviser',
    prop: 'KiwiSaverAdviser',
    name: 'KiwiSaver Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('KiwiSaverAdviser'),
		fieldId: datatableUtil.formatFieldId('KiwiSaverAdviser')
  },
  {
    metakey: 'Investment Adviser',
    prop: 'InvestmentAdviser',
    name: 'Investment Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('InvestmentAdviser'),
		fieldId: datatableUtil.formatFieldId('InvestmentAdviser')
  },
];
export const adviserReworkColumnNames = adviserReworkColumns?.map((x) => x?.metakey as string);

// FGUpdateV1 - Lead Pipeline Adjustments Columns
export const nzfaLPACol: EnhancedTableColumn[] = [
  {
    metakey: 'Adviser Gen',
    prop: 'AdviserGen',
    name: 'Adviser Gen',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: false,
    columnId: datatableUtil.formatColumnId('Adviser Gen'),
		fieldId: datatableUtil.formatFieldId('Adviser Gen')
  },
]
export const nzfaLPAColNames = nzfaLPACol?.map((x) => x?.metakey as string);

/**
 * column configuration for link
 */
export const linkColumn: (TableColumn & { prop: 'link' }) = {
  prop: 'link',
  name: '',
  width: 18
};
/**
 * template for Row
 */
export const templateRow: Row = {
  CustomerId: null,
  ClientNextActivityId: null,
  UserNextActivityId: null,
  bulk: {
    metakey: 'bulk',
    value: null,
    choices: [],
    controlType: 'checkbox',
    id: null,
    key: columns?.find(x => x.metakey === 'bulk').prop,
    required: false,
    editable: false,
  },
  Name: {
    metakey: 'Name',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Name').prop,
    required: false,
    editable: false,
  },
  AdditionalContacts: {
    metakey: 'Additional Contacts',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Additional Contacts').prop,
    required: false,
    editable: false,
    hasToolTip: true
  },
  Adviser: {
    metakey: 'LR Adviser', // TODO: remove
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Adviser').prop,
    required: true,
    editable: true,
    restrict: ['FEL']
  },
  // future work
  LRAdviser: {
    metakey: 'LR Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'LR Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEL']
  },
  GroupInsuranceAdviser: {
    metakey: 'GI Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'GI Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEL']
  },
  MortgageAdviser: {
    metakey: 'Mortgage Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Mortgage Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEL']
  },
  FGAdviser: {
    metakey: 'FG Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'FG Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEL']
  },
  KiwiSaverAdviser: {
    metakey: 'KS Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'KS Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEL']
  },
  InvestmentAdviser: {
    metakey: 'Investment Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Investment Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEL']
  },
  // END future work
  CreateDate: {
    metakey: 'Create Date',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Create Date').prop,
    required: false,
    editable: false
  },
  AltAdviser: {
    metakey: 'Alt. Adviser',
    value: null,
    choices: [],
    controlType: 'multiselect',
    id: null,
    key: columns?.find(x => x.metakey === 'Alt. Adviser').prop,
    required: true,
    editable: true,
    // restrict: ['FEL']
  },
  Email: {
    metakey: 'Email',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Email').prop,
    required: false,
    editable: true,
    restrict: ['FEL']
  },
  LastNote: {
    metakey: 'Last Note',
    value: null,
    choices: [],
    controlType: 'textarea',
    id: null,
    key: columns?.find(x => x.metakey === 'Last Note').prop,
    required: false,
    editable: true,
    hasToolTip: true,
    restrict: ['FALN']
  },
  LeadStatus: {
    metakey: 'Lead Status',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Lead Status').prop,
    required: false,
    editable: true,
    restrict: ['FEL']
  },
  LeadType: {
    metakey: 'Lead Type',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Lead Type').prop,
    required: false,
    editable: true,
    restrict: ['FEL']
  },
  LeadOrigin: {
    metakey: 'Lead Origin',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Lead Origin').prop,
    required: false,
    editable: true,
    restrict: ['FEL']
  },
  Mobile: {
    metakey: 'Mobile',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Mobile').prop,
    required: false,
    editable: true,
    restrict: ['FEL']
  },
  ClientNextActivity: {
    metakey: 'Client Next Activity',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Client Next Activity').prop,
    required: false,
    editable: false,
    hasToolTip: true,
    restrict: ['FELA']
  },
  UserNextActivity: {
    metakey: 'User Next Activity',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'User Next Activity').prop,
    required: false,
    editable: false,
    hasToolTip: true,
    restrict: ['FELA']
  },
  PhysicalAddress: {
    metakey: 'Physical Address',
    value: null,
    choices: [],
    controlType: 'address',
    id: null,
    key: columns?.find(x => x.metakey === 'Physical Address').prop,
    required: false,
    editable: true,
    restrict: ['FEL']
  },
  LinkedContacts: {
    metakey: 'Linked Contacts',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Linked Contacts').prop,
    required: false,
    editable: false,
    hasToolTip: true
  },
  LeadGen: {
    metakey: 'Lead Gen',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Lead Gen').prop,
    required: false,
    editable: true
  },
  CombinedIncome: {
    metakey: 'Combined Income',
    value: '0',
    choices: [],
		controlType: 'money',
    id: null,
    key: columns?.find(x => x.metakey === 'Combined Income').prop,
    required: false,
    editable: false,
  },
  Rank: {
    metakey: 'Rank',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Rank').prop,
    required: false,
    editable: false,
  },
  link: null,

	// FGUpdateV1 - Lead Pipeline Adjustments
  AdviserGen: {
    metakey: 'Adviser Gen',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: nzfaLPACol?.find(x => x.metakey === 'Adviser Gen').prop,
    required: false,
    editable: true
  },
};

export const createRowFromPrototype = R.curry((template: Row, row: LeadSearch) => produce<Row>(template, draft => {
	draft.bulk.value = false;

  draft.CustomerId = row.CustomerId;
  draft.ClientNextActivityId = row.ClientNextActivityId;
  draft.UserNextActivityId = row.UserNextActivityId;

  draft.Name.metakey = row.Name.MetaKey;
  draft.Name.value = row.Name.Value;

  draft.CombinedIncome.metakey = row.CombinedIncome.MetaKey;
  draft.CombinedIncome.value = row.CombinedIncome.Value || '0';

  draft.Rank.metakey = row.Rank.MetaKey;
  draft.Rank.value = row.Rank.Value;

  draft.AdditionalContacts.metakey = row.AdditionalContacts.MetaKey;
  draft.AdditionalContacts.value = row.AdditionalContacts.Value;

  draft.Adviser.metakey = row.Adviser.MetaKey;
  draft.Adviser.value = row.Adviser.Value;

  // ADVISER REWORK START
  draft.LRAdviser.metakey = row.LRAdviser?.MetaKey ||  'LR Adviser';
  draft.LRAdviser.value = row.LRAdviser?.Value;

  draft.GroupInsuranceAdviser.metakey = row.GroupInsuranceAdviser?.MetaKey || 'GI Adviser';
  draft.GroupInsuranceAdviser.value = row.GroupInsuranceAdviser?.Value;

  draft.MortgageAdviser.metakey = row.MortgageAdviser?.MetaKey || 'Mortgage Adviser';
  draft.MortgageAdviser.value = row.MortgageAdviser?.Value;

  draft.FGAdviser.metakey = row.FGAdviser?.MetaKey || 'FG Adviser';
  draft.FGAdviser.value = row.FGAdviser?.Value;

  draft.KiwiSaverAdviser.metakey = row.KiwiSaverAdviser?.MetaKey || 'KS Adviser';
  draft.KiwiSaverAdviser.value = row.KiwiSaverAdviser?.Value;

  draft.InvestmentAdviser.metakey = row.InvestmentAdviser?.MetaKey || 'Investment Adviser';
  draft.InvestmentAdviser.value = row.InvestmentAdviser?.Value;
  // ADVISER REWORK END

  draft.CreateDate.metakey = row.CreateDate.MetaKey;
  draft.CreateDate.value = row.CreateDate.Value;

  // TODO: remove this if confirmed
  draft.AltAdviser.metakey = row.AltAdviser.MetaKey;
  draft.AltAdviser.value = JSON.stringify(
    row.AltAdviser.Value && typeof JSON.parse(row.AltAdviser.Value) === 'object' ? JSON.parse(row.AltAdviser.Value).map((va) => va.toString()) :
    row.AltAdviser.Value.split(',').map((va) => va.toString())
  );

  draft.Email.metakey = row.Email.MetaKey;
  draft.Email.value = row.Email.Value;

  draft.LastNote.metakey = row.LastNote.MetaKey;
  draft.LastNote.value = row.LastNote.Value;

  draft.LeadStatus.metakey = row.LeadStatus.MetaKey;
  draft.LeadStatus.value = row.LeadStatus.Value;

  draft.LeadType.metakey = row.LeadType.MetaKey;
  draft.LeadType.value = row.LeadType.Value;

  draft.LeadOrigin.metakey = row.LeadOrigin.MetaKey;
  draft.LeadOrigin.value = row.LeadOrigin.Value;

  draft.Mobile.metakey = row.Mobile.MetaKey;
  draft.Mobile.value = row.Mobile.Value;

  draft.ClientNextActivity.metakey = row.ClientNextActivity.MetaKey;
  draft.ClientNextActivity.value = row.ClientNextActivity.Value;

  draft.UserNextActivity.metakey = row.UserNextActivity.MetaKey;
  draft.UserNextActivity.value = row.UserNextActivity.Value;

  draft.PhysicalAddress.metakey = row.PhysicalAddress.MetaKey;
  draft.PhysicalAddress.value = row.PhysicalAddress.Value;

  draft.LinkedContacts.metakey = row.LinkedContacts.MetaKey;
  draft.LinkedContacts.value = row.LinkedContacts.Value;

  draft.LeadGen.metakey = row.LeadGen.MetaKey;
  draft.LeadGen.value = row.LeadGen.Value === '0' ? '' : row.LeadGen.Value;

  draft.link = {
    CustomerId: row.CustomerId,
    IsCompany: row.IsCompany
  };

	// FGUpdateV1 - Lead Pipeline Adjustments
  draft.AdviserGen.metakey = row.AdviserGen?.MetaKey;
  draft.AdviserGen.value = row.AdviserGen?.Value === '0' ? '' : row.AdviserGen?.Value;
}));
