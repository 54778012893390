import {
  Disability,
  ExpensesCouldStartList,
  ExpensesCouldStopList,
  IncomeCouldStartList,
} from './disability.model';

export class DisabilityMapper {
  private static filterList(
    list:
      | ExpensesCouldStopList[]
      | IncomeCouldStartList[]
      | ExpensesCouldStartList[]
  ) {
    return list?.filter((x) => !!x.dropdown || x.value);
  }

  public static mapToUpsert(v: Disability): Disability {
		const changeOfExpenseList = v.changeOfExpenseList?.filter((c) => c.dropdown && c.value);
    return {
      ...v,
      availableSickLeave: +v.availableSickLeave || 0,
      availableAnnualLeave: +v.availableAnnualLeave || 0,
      sectionCode: 'RAD',
			changeOfExpenseList,
      livingOffSavings: v.livingOffSavings === 'N/A' ? 0 : v.livingOffSavings,
      expensesCouldStopList: this.filterList(v.expensesCouldStopList),
      incomeCouldStartList: this.filterList(v.incomeCouldStartList),
      expensesCouldStartList: this.filterList(v.expensesCouldStartList),
    };
  }
}
