import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  Optional,
} from '@angular/core';
import { Spinner } from 'spin.js';
import {
  LoaderService,
  ActionLoaderService,
  ResultLoaderService,
} from 'src/app/core/loader/loader.service';
import { ControlLoaderService } from 'src/app/core/services/control-loader.service';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  template: `<div
    class="loader-container"
    [ngClass]="{ 'd-none': !isLoading }"
    #target
  ></div>`,
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('target') spinnerTarget: ElementRef;
  spinner: Spinner;
  private _opts: any;
  defaultColor: string;
  service: LoaderService;
  @Input()
  set opts(value) {
    Object.assign(this._opts, value);
  }
  // tslint:disable-next-line:no-input-rename
  @Input('service') serviceName: string;
  get isLoading() {
    if (this.service) { return this.service.isLoading; }
    else { return false; }
  }
  subs: Subscription[];
  constructor(
    @Optional() private ls: LoaderService,
    @Optional() private cls: ControlLoaderService,
    @Optional() private als: ActionLoaderService,
    @Optional() private rls: ResultLoaderService,
    private businessConfigQuery: BusinessConfigQuery
  ) {
    this.setupProps();
    this.setupSubs();
  }

  setupProps(): void {
    this.serviceName = '';

    this.defaultColor = '#00263e';
    this._opts = {
      lines: 9, // The number of lines to draw
      length: 27, // The length of each line
      width: 20, // The line thickness
      radius: 45, // The radius of the inner circle
      scale: 0.5, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      color: this.defaultColor, // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      opacity: 0.25, // Opacity of the lines
      rotate: 0, // The rotation offset
      animation: 'spinner-line-fade-quick',
      direction: 1, // 1: clockwise, -1: counterclockwise
      speed: 1, // Rounds per second
      trail: 60, // Afterglow percentage
      fps: 20, // Frames per second when using setTimeout() as a fallback in IE 9
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      className: 'spinner', // The CSS class to assign to the spinner
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
      position: 'fixed', // Element positioning
    };
    this.spinner = new Spinner(this._opts);
    this.subs = [];
  }
  setupSubs(): void {}
  ngOnInit() {}
  ngOnChanges(): void {
    this.spinner.stop();
    this.spinner = new Spinner(this._opts);
  }
  ngAfterViewInit(): void {
    this.service =
      [this.ls, this.cls, this.als, this.rls]?.find((x) =>
        x ? x.serviceName === this.serviceName : false
      ) || this.service;
    const sub1 = this.businessConfigQuery.themeConfig$.subscribe((x) => {
      this.opts = { color: x ? x.primarycolor : this.defaultColor };
      this.ngOnChanges();
      if (this.isLoading) { this.spinner.spin(this.spinnerTarget.nativeElement); }
    });
    const sub2 = this.service.isLoading$.subscribe((x) => {
      if (x) { this.spinner.spin(this.spinnerTarget.nativeElement); }
      else { this.spinner.stop(); }
    });
    this.subs.push(sub1, sub2);
  }
  ngOnDestroy(): void {
    this.subs?.forEach((x) => x.unsubscribe());
    this.subs = [];
  }
}
