import { Component, OnInit, ChangeDetectorRef, OnChanges, Input, SimpleChanges } from '@angular/core';
import { ChartType, ChartEvent } from 'ng-chartist';
import { IChartistData, IPieChartOptions } from 'chartist';
import * as R from 'ramda';
import { BusinessConfigQuery } from '../../domain/business-config/business-config.query';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { numUtil } from '../../util/util';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit, OnChanges {
  /**
   * Donut width
   */
  private readonly width = 50;

  @Input() parts: { series: number[]; labels: string[] };
  @Input() actual: number;
  @Input() target: number;
  @Input() title: string;
  @Input() sub: string;

  /**
   * Chartist data. Used by ng-chartist
   */
  data: IChartistData = {
    series: []
  };

  /**
   * Type of chartist to generate.
   * Could be readonly?
   */
  type: ChartType = 'Pie';
  /**
   * Chartist pie chart options
   */
  options: IPieChartOptions = {
    donut: true,
    donutWidth: this.width,
    startAngle: 270,
    total: 200,
    showLabel: false,
    ignoreEmptyValues: true,
    height: 300
  };
  /**
   * Sort of lifecycle hook for chartist.
   */
  events: ChartEvent;

  /**
   * Stroke colors
   */
  strokes: string[] = [];

  widgetColour1: string;
  widgetColour2: string;
  tertiaryColor: string;
  percentageColor: string;

  /** Observable that should trigger unsubscription to others */
  onDestroy$ = new Subject<void>();

  /** Theme config object */
  themeConfig$ = this.businessConfigQuery.themeConfig$.pipe(
    filter(x => !!x),
    takeUntil(this.onDestroy$)
  );

  constructor(private cd: ChangeDetectorRef, private businessConfigQuery: BusinessConfigQuery) {}

  /**
   * For Manual refresh. Chartist doesn't expose its renderChart method
   * so we'll refresh the data instead
   */
  refresh(): void {
    this.data = R.clone(this.data);
    this.cd.detectChanges();
  }

  /**
   * Compute variables needed by chartist whenever component input changes.
   * @param changes combined changed component input properties in an object
   *
   * * compute graph necessary data
   * * compute display data
   */
  ngOnChanges(changes: SimpleChanges) {
    this.strokes = ['#d3d3d3'];

    if ((!!this.target || !!this.actual) && this.target > this.actual) {
      this.strokes?.unshift(this.tertiaryColor, '#d3d3d3');
      this.percentageColor = 'tap-text-tertiary';
    } else {
      this.percentageColor = 'tap-text-widget-colour-1';
      if (
        !!this.target &&
        this.target > 0 &&
        !!this.actual &&
        this.actual > this.target &&
        this.actual !== this.target &&
        this.actual < 100
      ) {
        this.strokes?.unshift(this.widgetColour1, this.widgetColour1);
      } else {
        this.strokes?.unshift(this.widgetColour1);
      }
    }

    this.data = {
      series: this.parts ? this.parts.series : []
    };
  }

  ngOnInit() {
    this.themeConfig$.subscribe(x => {
      this.widgetColour1 = x.widgetcolor1 && x.widgetcolor1 !== '' ? x.widgetcolor1 : x.primarycolor;
      this.widgetColour2 = x.widgetcolor2 && x.widgetcolor2 !== '' ? x.widgetcolor2 : x.secondarycolor;
      this.tertiaryColor = x.tertiarycolor;
    });

    this.events = {
      draw: data => {
        if (data.type === 'slice') {

          const pathLength = data.element._node.getTotalLength();

          data.element.attr({
            'stroke-dasharray':
              data.index > 0 ? pathLength - 2 + 'px ' + (pathLength - 2) + 'px' : pathLength + 'px ' + pathLength + 'px'
          });

          if (data.index <= this.strokes.length - 1) {
            data.element.attr({
              style: `stroke-width: ${this.width}px; stroke: ${this.strokes[data.index]};`
            });
          }

        }
      }
    };
  }

  formatToPercent(num: number = 0) {
    return numUtil.formatToPercent(num / 100);
  }
}
