export interface CurrentInsurance {
	PolicyDocuments?: any;
	PolicyOwner: string;
	Provider: string;
	PremiumFrequency: string;
	AdditionalNotes: string;
	LifeAssured?: LifeAssuredList[] | any;
	PolicyDocumentsList?: PolicyDocumentsList[];
	LifeAssuredList?: LifeAssuredList[];
	CRTId: number;
	AdviceProcessId: number;
	SectionCode: string;
	Status: number;
	ParentCRTId?: number;
	LinkedCRTId?: number;
	Priority?: number;
	// Only for Final Structure
	ParentCustomerId?: number;
	// AI Quote Feature
	PolicyNumber?: string;
	ManualReview?: boolean;
}

export interface CurrentInsuranceState {
	policyDocuments?: any;
	policyDocumentFormData?: any;
	policyDocumentsName?: any;
	policyOwner: any;
	policyFee?: any;
	provider: string;
	premiumFrequency: string;
	additionalNotes: string;
	lifeAssured?: LifeAssuredListState[] | any;
	policyDocumentsList?: PolicyDocumentsListState[] | any;
	lifeAssuredList?: LifeAssuredListState[];
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	status: number;
	parentCRTId?: number;
	totalPremium?: number;
	linkedCRTId?: number;
	priority?: number;
	id?: number;
	// Only for Final Structure
	parentCustomerId?: number;
	DocumentTypeCode?: string;
	// AI Quote Feature
	policyNumber?: string;
	manualReview?: boolean;
}

export interface LifeAssuredList {
	LifeAssured: number;
	Product: string;
	Benefit: number;
	Notes: string;
	Premium: string;
	PremiumValue: number;
	StartDate: string;
	NewExisting?: string;
}

export interface LifeAssuredListState {
	id?: number;
	lifeAssured?: number;
	product?: string;
	benefit?: number;
	notes?: string;
	premium?: number;
	premiumValue?: number;
	startDate?: string;
	newExisting?: string;
	priority?: number;
}

export interface PolicyDocumentsList {
	ReferenceId: number;
	Value: string;
}

export interface PolicyDocumentsListState {
	referenceId: number;
	value: string;
	// AI Quote
	aiQuoteStatus?: boolean; // FE only
}

export enum NewExistingStatus {
	New = 'New',
	Existing = 'Existing',
	Replacement = 'Replacement',
}

export const AllowDropdowns = [
	'Medical Base Plan',
	'Specialists & Tests',
	'Medical',
	'Medical + S&T',
	'Dental & Optical',
];

export const DefaultNewExistingOption = 'Replacement';

export const AppendMonths = [
	'Income',
	'Income - Level',
	'Mortgage',
	'Mortgage - Level',
	'Household Expenses',
	'Income & Expenses',
	'Redundancy',
	'Business Overheads',
];

export const OtherProducts = [
	// 'Waiver of Premium',
	'GP Cover',
	'Other',
	'Vitality',
];

// AI Quote Response Fields
export enum aiFields {
	Provider = 'Insurance Provider',
	PolicyNumber = 'Policy Number',
	PolicyFee = 'Total Policy Fee',
	LifeAssureList = 'Quoted Products',
	LifeAssured = 'Quoted For',
	StartDate = 'Start Date',
	Benefit = 'Benefit',
	Notes = 'Notes',
	Premium = 'Premium',
	Product = 'Product Name',
};
