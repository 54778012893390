<form 
	[formGroup]="form" 
	[class.submitted]="submitted"
	[class.loatv2]="enableLoatV2$ | async" 
	novalidate>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">Lender</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="lender"
					formControlName="lender"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of lender" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Borrowing Entities
			</label>
		</div>
		<div class="col-8 col-md-5 mb-2">
			<app-chips
				id="borrowingEntities"
				textboxId="borrowingEntities"
				class="crt-form-control-field"
				textboxClass="theme-crm-field"
				formControlName="borrowingEntities"
				[choices]="policyOwnerChoices(form.get('borrowingEntities').value)"
				[attr.disabled]="viewMode"
			>
			</app-chips>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Security
			</label>
		</div>
		<div class="col-8 col-md-10 mb-2">
			<app-chips
				id="security"
				textboxId="security"
				class="crt-form-control-field"
				textboxClass="theme-crm-field"
				formControlName="security"
				[choices]="securities"
				[attr.disabled]="viewMode"
			>
			</app-chips>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Loan Value
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<div class="dollar-icon">
				<input
					id="loanValue"
					formControlName="loanValue"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Loan Repayment
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<div class="dollar-icon">
				<input
					id="actualRepayment"
					formControlName="actualRepayment"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-8 offset-md-0 offset-4 col-md-2 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="mortgageFrequency"
					formControlName="mortgageFrequency"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of mortgageFrequency" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center" *ngIf="hasLoanLimit">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Loan Limit
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<div class="dollar-icon">
				<input
					id="loanLimit"
					formControlName="loanLimit"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Interest Rate
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<div class="dollar-icon dollar-icon-right">
				<input
					id="interestRate"
					formControlName="interestRate"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>%</i>
			</div>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Loan Type
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="loanType"
					formControlName="loanType"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of loanType" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Fixed Period End
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<app-date-input
				textboxId="fixedPeriodEnd"
				class="crt-form-control-field"
				formControlName="fixedPeriodEnd"
				placeholder="dd/mm/yyyy"
				[attr.disabled]="viewMode"
			></app-date-input>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Original Loan Term
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<div class="years dollar-icon dollar-icon-right">
				<input
					id="loanTerm"
					formControlName="loanTerm"
					class="form-control crt-form-control pr-5"
					type="text"
				/>
				<i>years</i>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center" *ngIf="hasLoanRepaymentDate">
		<div class="col-4 col-md-2 offset-md-5 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Loan Repayment Date
			</label>
		</div>
		<div class="col-8 col-md-3">
			<app-date-input
				textboxId="loanRepaymentDate"
				class="crt-form-control-field"
				formControlName="loanRepaymentDate"
				placeholder="dd/mm/yyyy"
				[attr.disabled]="viewMode"
			></app-date-input>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-md-2 text-left mb-2"></div>
		<div class="col-8 col-md-3 mb-2"></div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> Purpose </label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="purpose"
					formControlName="purpose"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option value="Investment Loan">Investment Loan</option>
					<option value="Home Loan">Home Loan</option>
				</select>
			</span>
		</div>
	</div>

	<div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button
				id="closeButton"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
			>
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
				id="saveButton"
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
				[disabled]="isLoading || isLoadingBtn"
				(click)="save()"
			>
				Save
				<i *ngIf="isLoading || isLoadingBtn" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				id="cancelButton"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
			>
				Cancel
			</button>
		</ng-template>
	</div>
</form>
