import { SecondaryProfessionalState } from './secondary-professional.model';

export class ClientProfileSecondaryProfessionalMapper {
  static mapToView(data: SecondaryProfessionalState) {
    return {
      ...data
    };
  }

  static mapToUpsert(data: SecondaryProfessionalState) {
    return {
			...data,
			isActive: 1,
		};
  }
}
