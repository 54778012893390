import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { UserPdTrackingModalComponent } from '@shared/modal/user-pd-tracking-modal/user-pd-tracking-modal.component';
import { DocumentTypes } from '@shared/models/documents/document.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	displayedColumns,
	tableColumns,
} from './user-pd-tracking-completed-table-config';
import { SimpleDataTableModel } from '@shared/models/_general/simple-data-table.model';
import { Observable, iif, of } from 'rxjs';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import {
	BlStaffPdTypes,
	BlStaffSettingTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { util } from '@core/util/util.service';
import { mergeMap, take } from 'rxjs/operators';
import { ManagePdTrackingService } from '@modules/user/comps/manage-users/manage-pd-tracking/states/manage-pd-tracking.service';

@Component({
	selector: 'app-user-pd-tracking-completed',
	templateUrl: './user-pd-tracking-completed.component.html',
	styleUrls: ['./user-pd-tracking-completed.component.scss'],
})
export class UserPdTrackingCompletedComponent implements OnInit {
	@Input() staff: BlStaffViewmodel;
	@Input() data: BlStaffSettingsModel[];
	@Input() isTapLevel: boolean;
	@Input() userSecurityGroup: string;
	@Input() loggedUserId: number;
	@Input() UPDTP: ViewDisplayValue[] = []; // Provider Dropdown
	@Input() UPDTC: ViewDisplayValue[] = []; // Category Dropdown
	@Input() UPDTS: ViewDisplayValue[] = []; // Status Dropdown
	@Input() upsertPdTrackingFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() downloadDocumentFn$: (data: {
		documentId: number;
		fileName: string;
	}) => Observable<any> = (data) => of(data);

	displayedColumns: string[] = [];
	tableColumns: SimpleDataTableModel[] = [];

	dataSource = [];
	modalTitle = 'Personal Development Tracking';
	isShowMissed = false;

	get CanAdd() {
		return ['SO', 'SA', 'SM', 'BO', 'BAI', 'A', 'AM', 'BAV', 'BM']?.includes(
			this.userSecurityGroup
		);
	}
	get CanEdit() {
		return this.isTapLevel || ['BO']?.includes(this.userSecurityGroup);
	}

	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private pdtService: ManagePdTrackingService,
	) {
		this.displayedColumns = displayedColumns;
	}

	ngOnInit(): void {
		this.setTableData(this.data);
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges?.data) {
			this.setTableData(this.data);
		}
	}

	setTableData(data: BlStaffSettingsModel[]) {
		this.tableColumns = tableColumns.map((x) => ({
			...x,
			actionCallback: x?.columnId === 'ViewAction' ? this.viewCompleted : null,
		}));

		this.dataSource = data?.map((x) => ({
			...x,
			ProviderBusiness:
				x?.Provider === 'Other' ? x?.OtherProvider : x?.Provider,
			Conditional:
				x?.Status === BlStaffPdTypes.DidntAttend
					? "Didn't Attend"
					: util.DateStringToDateStringDisplay(x?.CompletedDate),
			sortDate:
				x?.Status === BlStaffPdTypes.DidntAttend
					? x?.ModifiedDateTime
					: x?.CompletedDate,
		}));

		if (!this.isShowMissed) {
			this.dataSource = this.dataSource.filter(
				(ds) => ds?.Status !== BlStaffPdTypes.DidntAttend
			);
		} else {
			this.dataSource = this.dataSource.sort(
				(a, b) =>
					new Date(b.sortDate).valueOf() - new Date(a.sortDate).valueOf()
			);
		}

		this.cd.detectChanges();
	}

	sorter(a: any, b: any) {
		const sortBy = (a: any, b: any, key: string) =>
			new Date(a[key]).valueOf() - new Date(b[key]).valueOf();

		return sortBy(a, b, 'CompletedDate') || sortBy(a, b, 'ModifiedDateTime');
	}

	addCompleted() {
		const statusList = this.UPDTS?.filter(
			(x) => x?.value !== BlStaffPdTypes.Upcoming
		);
		const initialState = {
			title: this.modalTitle,
			limitSingleDoc: true,
			staffId: this.staff?.StaffID,
			UPDTP: this.UPDTP,
			UPDTC: this.UPDTC,
			UPDTS: statusList,
			enableAllFields: true,
			initializeEdit: true,
			defaultStatus: BlStaffPdTypes.Completed,
			type: BlStaffSettingTypes.PersonalDevelopment,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Save',
			upsertFn$: this.upsertPdTrackingFn$,
			downloadDocumentFn$: this.downloadDocumentFn$,
		};
		this.modalService.show(UserPdTrackingModalComponent, {
			class:
				'modal-dialog-centered modal-dialog modal-lg user-pd-tracking-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	}

	viewCompleted = (event) => {
		const statusList = this.UPDTS?.filter(
			(x) => x?.value !== BlStaffPdTypes.Upcoming
		);
		const initialState = {
			title: this.modalTitle,
			data: event,
			limitSingleDoc: true,
			initializeEdit: false,
			viewMode: !this.CanEdit,
			staffId: this.staff?.StaffID,
			UPDTP: this.UPDTP,
			UPDTC: this.UPDTC,
			UPDTS: statusList,
			defaultStatus: BlStaffPdTypes.Completed,
			type: event?.SettingsCode || BlStaffSettingTypes.PersonalDevelopment,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Save',
			upsertFn$: this.upsertPdTrackingFn$,
			downloadDocumentFn$: this.downloadDocumentFn$,
		};
		this.modalService.show(UserPdTrackingModalComponent, {
			class:
				'modal-dialog-centered modal-dialog modal-lg user-pd-tracking-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	};

	showMissed() {
		this.isShowMissed = !this.isShowMissed;
		this.setTableData(this.data);
	}
}
