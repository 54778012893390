import { User, isTapLevel } from '../user/user.model';

export const DEFAULT_ADVISER_FILER = ['AM', 'A', 'BO'];

export enum StaffSettingTypes {
	UserTrackerGoals = 'UTG',
	UserTrackerAccessibility = 'UTA'
}

export type BLStaff = {
    StaffID?: number;
    FirstName: string;
    LastName: string;
    FullName: string;
    IsActive: number;
    EmailAddress: string;
    SecurityGroup: string;
    StartDate: string;
    BirthDate: string;
    MobilePhone: string;
    BusinessPhone: string;
    HomeAddress: string;
    CalendarUrl: string;
    AdviserIdsAssigned: number[];
    AdviserManager?: number;
    Services: string[];
    Stats: any[];
		StaffSettings: {
			ShowInAdviserList: boolean;
			AdviserServices?: string;
		};
};

export function createBLStaff({
    StaffID,
    FirstName,
    LastName,
    IsActive,
    EmailAddress,
    SecurityGroup,
    StartDate,
    BirthDate,
    MobilePhone,
    BusinessPhone,
    HomeAddress,
    CalendarUrl,
    AdviserIdsAssigned,
    AdviserManager,
    Services,
    Stats
}: Partial<BLStaff>) {
    return {
        StaffID,
        FirstName,
        LastName,
        IsActive,
        EmailAddress,
        SecurityGroup,
        StartDate,
        BirthDate,
        MobilePhone,
        BusinessPhone,
        HomeAddress,
        CalendarUrl,
        AdviserIdsAssigned,
        AdviserManager,
        Services,
        Stats
    }
}


export class BLStaffs extends Array<BLStaff> {
	static getAvailableAdvisers = (user: User, staffList: BLStaffs) => {
		if (isTapLevel(user)) {
			return staffList;
		} else {
			return staffList?.filter((x) =>
				user.AdviserIdsAssigned?.some((y) => y === x.StaffID)
			);
		}
	};
	static getActiveStaffs = (staffList: BLStaffs) =>
		staffList?.filter(BLModel.isActive);
	static getStaffById = (staffId: number, staffList: BLStaffs) =>
		staffList?.find((x) => x.StaffID === staffId);
	static getLeadGens = (staffList: BLStaffs) =>
		staffList?.filter(
			(blStaff) => blStaff.SecurityGroup?.toUpperCase() === 'LG'
		);
	// tslint:disable-next-line: max-line-length
	static getLeadGensAndLeadGenInternal = (staffList: BLStaffs) =>
		staffList?.filter(
			(blStaff) =>
				blStaff.SecurityGroup?.toUpperCase() === 'LG' ||
				blStaff.SecurityGroup?.toUpperCase() === 'LGI' ||
				blStaff.SecurityGroup?.toUpperCase() === 'CCA' ||
				blStaff.SecurityGroup?.toUpperCase() === 'BAV'
		);


  // tslint:disable-next-line: max-line-length
	static getAllLeadGensAndLeadGenInternal = (staffList: BLStaffs) =>
    staffList?.filter(
      (blStaff) =>
        blStaff.SecurityGroup?.toUpperCase() === 'LG' ||
        blStaff.SecurityGroup?.toUpperCase() === 'LGI' ||
        blStaff.SecurityGroup?.toUpperCase() === 'CCA' ||
        blStaff.SecurityGroup?.toUpperCase() === 'BAV'
    );

	/**
	 * Active Adviser(all types of advisers) and Paused/Inactive Adviser(A) and Adviser Manager(AM)
	 */
	static getActiveAdvisersAndAdviserManager = (
		user: User,
		staffList: BLStaffs
	) => {
		if (isTapLevel(user)) {
			return staffList?.filter(
				(x) =>
					x.IsActive === 1 ||
					x.SecurityGroup === 'A' ||
					x.SecurityGroup === 'AM'
			);
		} else {
			return staffList?.filter((x) =>
				user.AdviserIdsAssigned?.some((y) => y === x.StaffID)
			);
		}
	};
}

export const IsActive = (staff: BLStaff) => staff.IsActive;

export class BLModel {
    static isActive = (staff: BLStaff) => staff.IsActive;
    static getStaffName = (staff: BLStaff) => staff ? `${staff.FirstName} ${staff.LastName}` : '';
}
