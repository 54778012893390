import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FGPremiumStatsLeadTypeState, FGPremiumStatsLeadTypeStore } from './fgpslt.store';

/**
 * Query for FG Premium Stats Lead Type widget data for Rollling 12 Months (Year)
 */
@Injectable()
export class FGPremiumStatsLeadTypeQuery extends Query<FGPremiumStatsLeadTypeState> {

  /** FG Premium Stats LeadType data. */
  FGPremiumStatsLeadTypeData$ = this.select(state =>
    state.FGPremiumStatsLeadType
  );

  /** Indicator if FGPremiumStatsLeadType is loading */
  isLoading$ = this.select(state => state.isLoading);

  isLoading = this.getValue().isLoading;

  /** error message for FGPremiumStatsLeadType pipeline */
  error$ = this.select(state => state.error);

  selectedTypes$ = this.select(state => state.selectedTypes);

  constructor(protected store: FGPremiumStatsLeadTypeStore) {
    super(store);
  }
}
