import {
	Component,
	OnInit,
	Input,
	ViewChild,
	OnChanges,
	Output,
	EventEmitter,
	OnDestroy,
	ChangeDetectionStrategy,
	HostListener,
	ChangeDetectorRef,
	SimpleChanges,
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { ClientSearchControlComponent } from '../../../../shared/search-controls/client-search-control/client-search-control.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConvertModalComponent } from './../../../../shared/modal/convert-modal/convert-modal.component';
import {
	map,
	mergeMap,
	filter,
	takeUntil,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ClientProfileService } from '../states/client-profile.service';
import {
	Observable,
	Subject,
	ReplaySubject,
	zip,
	of,
	combineLatest,
} from 'rxjs';
import { FormPersonComponent } from '../forms/form-person/form-person.component';
import { strUtil, util } from '../../../../util/util';
import {
	AdviserServiceState,
	PrimaryClientState,
	SasReference,
} from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { ClientProfilePrimaryMapper } from '../../../../shared/models/client-profile/primary-client/primary-client.mapper';
import { CurrentActivityCriteriaState } from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { patchValue } from '../../../../shared/services/service-utils/service.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from 'src/app/shared/date-input/date-input.component';
import {
	ContactStatusCode,
	ContactStatusLabel,
	GetStatusLabelByCode,
} from 'src/app/shared/models/business-profile/business/business.model';
import {
	Fields,
	getRequiredWarning,
} from 'src/app/shared/error-message/error-message';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { AdviceProcessCode, AdviceProcessTypesList } from 'src/app/shared/models/advice-process/advice-process.model';
import * as moment from 'moment';
import MomentUtil from '@util/moment.util';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { omit, pluck, uniq } from 'ramda';
import { ServicesCodes } from '@shared/models/services/services.model';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-client-profile-group',
	templateUrl: './client-profile-group.component.html',
	styleUrls: ['./client-profile-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProfileGroupComponent
	implements OnInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	@Input() addMode: boolean;
	@Input() isLead: boolean;
	@Input() showPendingFields: boolean;
	@Input() allStaff: BLStaff[];

	private _isSaving = false;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	isSaving$ = new ReplaySubject<boolean>(1);
	isSavingLS$ = new ReplaySubject<boolean>(1);

	private _isEdit = false;
	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	isEdit$ = new ReplaySubject<boolean>(1);

	@Input() data: PrimaryClientState;
	@Input() criterias: CurrentActivityCriteriaState[];

	@Input() advisers: ViewDisplayValue[];
	@Input() allStaffChoices: ViewDisplayValue[];
	@Input() altAdvisers: ViewDisplayValue[];
	@Input() leadGens: ViewDisplayValue[];
	@Input() PCT: ViewDisplayValue[];
	@Input() PCE: ViewDisplayValue[];
	@Input() PCPC: ViewDisplayValue[];
	@Input() PCLE: ViewDisplayValue[];
	@Input() PCLT: ViewDisplayValue[];
	@Input() PCR: ViewDisplayValue[];
	@Input() LS: ViewDisplayValue[];
	@Input() LT: ViewDisplayValue[];
	@Input() SAS: ViewDisplayValue[];
	@Input() preferredEmailContacts: ViewDisplayValue[];

	@Output() saveEvent = new EventEmitter<any>();
	@Output() selectServiceTab = new EventEmitter<any>();

	criteriasState = [];
	hasCriteria: boolean;
	

	contactStatusLabel$: Observable<string> =
		this.service.clientContactStatus$.pipe(
			filter((x) => x !== null),
			map((s) => {
				if (s) {
					return GetStatusLabelByCode(s);
				} else {
					return ContactStatusLabel.CurrentClient;
				}
			})
		);

	contactDropdown$ = this.contactStatusLabel$.pipe(
		map((x) => {
			if (x === ContactStatusLabel.CurrentClient) {
				return [
					{
						value: ContactStatusCode.Lead,
						display: `Move to ${ContactStatusLabel.Lead}`,
					},
					{
						value: ContactStatusCode.ExClient,
						display: `Move to ${ContactStatusLabel.ExClient}`,
					},
				];
			} else {
				return [
					{ value: ContactStatusCode.CurrentClient, display: `Move to Client` },
				];
			}
		})
	);

	form: UntypedFormGroup;
	convertHeaderTitle: string;
	adviserReworkFeature: boolean;
	altAdviserDropdown: ViewDisplayValue[];

	@ViewChild(ClientSearchControlComponent)
	referredInput: ClientSearchControlComponent;
	@ViewChild(FormPersonComponent) FormPersonComponent: FormPersonComponent;
	@ViewChild('lastReviewDate') lastReviewDate: DateInputComponent;
	@ViewChild('nextReviewDate') nextReviewDate: DateInputComponent;

	innerWidth: number;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	hasPermissions$ = this.userQuery.hasPermission$;
	canUpdateLeadStatus$ = this.userQuery.canUpdateLeadStatus$;
	claimsFeature = this.businessConfigQuery.getValue().config?.Claims;

	companyCode: string;
	hasBusinessService: string[] = [];

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private service: ClientProfileService,
		private loggerService: LoggerService,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery,
		private cd: ChangeDetectorRef,
		private router: ActivatedRoute

	) {
		this.createForm();
	}

	get groupName() {
		return this.form.get('groupName');
	}
	get adviser() {
		return this.form.get('adviser');
	}
	get AdviserList() {
		// Adviser Rework Field
		return this.form.get('advisers') as UntypedFormArray;
	}

	isAM$ = this.userQuery.userInfo$.pipe(
		map((x) => x.SecurityGroup === 'AM')
	);

	ngOnInit() {
		this.getBusinessProfileServices();
		this.router.params.subscribe(() => this.init());
	}

	private init() {
		this.companyCode = this.businessConfigQuery.getValue().config?.BusinessCode;

		this.isEdit$
			.pipe(
				withLatestFrom(this.canUpdateLeadStatus$, this.isAM$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([x, canUpdateLeadStatus, isAM]) => {
				this._isEdit = x;
				if (x || this.addMode) {
					this.form.enable();
					if(isAM) {
						if(!this.addMode) {
							this.form.get('groupName').disable();
						}
						this.form.get('rank').disable();
						this.form.get('isRecycled').disable();
					}
				} else {
					this.form.disable();
					if (canUpdateLeadStatus && !x) {
						this.form.get('leadStatus').enable();
					}
				}
			});

		this.isSaving$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isSaving = x;
		});

		zip(of(this.PCR), of(this.LS))
			.pipe(
				take(1),
				map((ddListList) => {
					const defaultValueList: string[] = ddListList
						?.map((ddList) => ddList?.find((dd) => dd.isDefault))
						?.map((def) => def && def.value);
					return defaultValueList;
				})
			)
			.subscribe(this.setDropdownDefaults);
		this.getAltAdvisers();
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([pcr, ls]) => {
		patchValue<any>(this.form, {
			rank: this.data ? this.data.rank : pcr,
			leadStatus: this.data ? this.data.leadStatus : this.isLead ? ls : '',
		});
	};

	ngOnChanges(changes: SimpleChanges) {
		if (!this.addMode) {
			this.isEdit$.next(true);
			const data = this.data
				? Object.assign(
						new ClientProfilePrimaryMapper(),
						ClientProfilePrimaryMapper.mapToView(this.data)
				  )
				: Object.assign(new ClientProfilePrimaryMapper(), this.form.value);
			const adviser = this.advisers?.find((x) => x.value === data.adviser);
			data.adviser = adviser === undefined ? null : adviser.value;

			// Prepare
			if (!!changes?.data?.currentValue) {
				// Trigger only if data is updated
				this.prepData(data);
			}
			this.prepCriterias();
			this.isEdit$.next(false);
		} else {
			if (!!changes?.addMode?.currentValue) {
				// Trigger only initially on load for Add Mode
				this.prepData();
			}
		}
		this.getAltAdvisers();
	}

	prepCriterias() {
		this.criteriasState = [];
		this.hasCriteria = this.criterias && this.criterias.length > 0;
		const filteredCriterias = this.filterCriterias();

		const newCriterias = filteredCriterias?.map((x) => ({
			...x,
			status: this.convertStatusName(x?.status),
		}));

		// Array starts 0 index
		const row1 =
			newCriterias.length > 0
				? newCriterias?.filter((x, i, array) => i % 2 === 0)
				: [];
		const row2 =
			newCriterias.length > 0
				? newCriterias?.filter((x, i, array) => i % 2 !== 0)
				: [];
		this.criteriasState.push(row1, row2);
	}

	convertStatusName(status: string) {
		switch (status) {
			case 'Final Structure Stage':
				return 'Final Structure';
				break;
			default:
				return status || '';
				break;
		}
	}

	createForm() {
		this.form = this.fb.group({
			groupName: ['', [Validators.required]],
			adviser: ['', [Validators.required]],
			rank: [''],
			preferredEmailContact: [''],
			lastReview: [''],
			nextReview: [''],

			altAdviser: [null],
			leadStatus: [''],
			reviewPending: false,
			leadPending: false,

			isRecycled: false,
		});
	}

	filterCriterias() {
		const criterias = Object.assign([], this.criterias);
		const businessServices =
			this.businessConfigQuery.getValue().config?.Services || [];
		const userServices =
			JSON.parse(this.userQuery.getValue().Services)?.filter((us) =>
				businessServices.some((bs) => bs === us)
			) || [];
		const allowedServices = this.userQuery.isTapLevel()
			? businessServices
			: businessServices.filter((x) => userServices.includes(x));
			const filteredTypes = AdviceProcessTypesList.filter((type) => {
				if (!type.code) {
					return true;
				}
				if (
					(type.value === AdviceProcessCode.FGClaim ||
						type.value === AdviceProcessCode.LRClaim) &&
					!this.claimsFeature
				) {
					return false;
				}
				if (type.value === AdviceProcessCode.ClientAlterationRequest) {
					return allowedServices?.includes(ServicesCodes.ClientAlterationRequest);
				}
				return allowedServices?.includes(type.code);
				// return !type.code ? true : allowedServices.includes(type.code)
			})?.map((x) => x?.value);
		const newCriterias =
			criterias?.filter(
				(x) =>
					filteredTypes?.includes(x?.serviceCode) || // for advice processes
					allowedServices?.includes(x?.serviceCode) // for services
			) || [];

		return newCriterias?.length > 10
			? newCriterias?.slice(0, 10)
			: newCriterias;
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	edit() {
		this.toggleEdit(true);
	}

	cancel() {
		if (!this.addMode) {
			const data = this.data
				? Object.assign(
						new ClientProfilePrimaryMapper(),
						ClientProfilePrimaryMapper.mapToView(this.data)
				  )
				: Object.assign(new ClientProfilePrimaryMapper(), this.form.value);
			const adviser = this.advisers?.find((x) => x.value === data.adviser);
			data.adviser = adviser === undefined ? null : adviser.value;

			if (!data?.lastReview?._i) {
				this.lastReviewDate?.reset();
			}
			if (!data?.nextReview?._i) {
				this.nextReviewDate?.reset();
			}

			this.prepData(data);
			this.cd.detectChanges();
		}
		this.toggleEdit(false);
	}

	prepareFormValue() {
		const form = this.form.value;
		const groupName = form.groupName || this.groupName.getRawValue();

		const newData = {
			...form,
			groupName: strUtil.safeTrimExtraSpace(groupName),
			altAdviser:
				form.altAdviser && form.altAdviser.length > 0
					? form.altAdviser?.filter((x) => x)?.map((x) => +x)
					: [],
		};
		if (!!this.adviserReworkFeature) {
			const adviserServices =
				ClientProfilePrimaryMapper.mapAdviserReworkServicesUpsert(
					form?.advisers || []
				);
			return {
				...newData,
				...adviserServices,
			};
		}
		return newData;
	}

	prepData(data?: PrimaryClientState) {
		this.businessConfigQuery.adviserReworkFeature$
			.pipe(
				tap((isEnabled) => {
					this.adviserReworkFeature = isEnabled;
					if (isEnabled) {
						const adviserServices =
							ClientProfilePrimaryMapper.mapAdviserReworkServicesView(data);
						if (!this.AdviserList) {
							this.form.addControl('advisers', this.fb.array([]));
						}
						if (data) {
							this.form.reset(data);
						}
						if (this.addMode) {
							this.clearFormArray(this.AdviserList);
							this.addAdviser(true);
						} else {
							if (!!adviserServices?.length) {
								this.prepAdviserList(false, adviserServices);
							} else {
								this.clearFormArray(this.AdviserList);
								this.addAdviser(true);
							}
						}
					} else {
						setTimeout(() => {
							if (data) {
								this.form.reset(data);
							}
						}, 1);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Check form if valid
	 * @returns boolean
	 */
	isFormValid() {
		return this.form.valid;
	}

	save(isAddMode?: boolean) {
		// Check validation
		if (
			!this.isFormValid() ||
			this.prepareFormValue().groupName?.trim() === ''
		) {
			if (
				this.prepareFormValue().groupName?.trim() === '' ||
				!this.prepareFormValue().groupName
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.FileName));
				return;
			}
			if (!!this.adviserReworkFeature) {
				const advisers = this.prepareFormValue().advisers;
				if (!advisers?.length) {
					this.loggerService.Warning({}, getRequiredWarning(Fields.Adviser));
					return;
				} else if (advisers?.some((z) => !z.adviserId || !z.services?.length)) {
					this.loggerService.Warning({}, 'Adviser & Service are required');
					return;
				}
			} else {
				if (!this.prepareFormValue().adviser) {
					this.loggerService.Warning({}, getRequiredWarning(Fields.Adviser));
					return;
				}
			}
			// if (this.lastReviewDate.isInvalid()) {
			// 	this.loggerService.Warning({}, getInvalidWarning(Fields.LastReview));
			// 	return;
			// }
			// if (this.nextReviewDate.isInvalid()) {
			// 	this.loggerService.Warning({}, getInvalidWarning(Fields.NextReview));
			// 	return;
			// }
		}

		if (isAddMode) {
			/////////////// Add new primary client ///////////////
			// tslint:disable-next-line: max-line-length
			const newData = this.adviserReworkFeature
				? omit(['advisers'], this.prepareFormValue())
				: this.prepareFormValue();

			this.saveEvent.emit({
				...newData,
				contactStatus: this.isLead
					? ContactStatusCode.Lead
					: ContactStatusCode.CurrentClient,
			}); // Emit formatted form
		} else {
			/////////////// Edit existing primary client ///////////////
			this.isSaving$.next(true);
			this.form.disable();
			// Get the primary client additional info saved in states
			const primaryClient = ClientProfilePrimaryMapper.mapToView(this.data);
			// Combine the existing client with the new changes in group section
			const { clientSince } = primaryClient;
			const newClientSince = clientSince
				? clientSince
				: primaryClient.contactStatus === 'C'
				? MomentUtil.formatToServerDate(moment())
				: null;
			const data: PrimaryClientState = {
				...primaryClient,
				...this.prepareFormValue(),
				clientSince: newClientSince,
			};
			const newData = this.adviserReworkFeature
				? omit(['advisers'], data)
				: data;
			// Send to API
			this.service
				.updatePrimaryClient(
					ClientProfilePrimaryMapper.mapToUpsert(newData) as PrimaryClientState
				)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					() => {
						this.isEdit$.next(false);
					},
					() => {
						this.isEdit$.next(true);
					},
					() => {
						this.isSaving$.next(false);
					}
				);
		}
	}

	convertStatus(selectedContactStatus: string) {
		this.convertHeaderTitle = '';
		if (selectedContactStatus === ContactStatusCode.Lead) {
			this.convertHeaderTitle = ` to ${ContactStatusLabel.Lead}`;
		} else if (selectedContactStatus === ContactStatusCode.CurrentClient) {
			this.convertHeaderTitle = ' to Client';
		} else if (selectedContactStatus === ContactStatusCode.ExClient) {
			this.convertHeaderTitle = ` to ${ContactStatusLabel.ExClient}`;
		}
		const initState: any = {
			header:
				'Convert ' +
				this.data.firstName +
				' ' +
				(this.data.middleName ? this.data.middleName : '') +
				' ' +
				this.data.lastName +
				' ' +
				this.convertHeaderTitle,
			customerId: this.data.customerID,
			name:
				this.data.firstName +
				' ' +
				this.data.middleName +
				' ' +
				this.data.lastName,
			contactStatus: selectedContactStatus,
			LT: this.LT,
			savefn: this.saveConvertClient,
		};
		if (this.convertHeaderTitle !== '') {
			this.modalService.show(ConvertModalComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		}
		this.convertHeaderTitle = '';
	}

	saveConvertClient = (data: any) =>
		new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(mergeMap((x) => this.service.convert(x)));

	selectedCriteria(item) {
		this.selectServiceTab.emit(item);
	}

	/**
	 * Advice Reworker Methods
	 */

	addAdviser(isNew: boolean, data?: any) {
		const id = !!isNew ? null : data?.adviserId.toString();
		const servicesList = !!isNew ? [] : data?.services;
		this.AdviserList.push(
			this.fb.group({
				adviserId: [id, [Validators.required]],
				services: [servicesList, [Validators.required]],
			})
		);
	}

	removeAdviser(index) {
		this.AdviserList.removeAt(index);
	}

	prepAdviserList(isNew: boolean, data: AdviserServiceState[]) {
		while (this.AdviserList.length > 0) {
			this.AdviserList.removeAt(0);
		}
		data?.forEach((x) => {
			this.AdviserList.push(this.patchValue(isNew, x));
		});
	}

	patchValue(isNew: boolean, data?: AdviserServiceState) {
		const id = !!isNew ? null : data?.adviserId.toString();
		const servicesList = !!isNew ? [] : data?.services;
		return this.fb.group({
			adviserId: [id, [Validators.required]],
			services: [servicesList, [Validators.required]],
		});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		if (!formArray) {
			return;
		}
		while (formArray.length > 0) {
			formArray?.removeAt(0);
		}
	};

	onChangeAdviser(index: number, adviserId: string) {
		this.AdviserList.controls[index].get('services').setValue([]);
		const id = +adviserId;
		// Get all the services from other advisers
		const selectedChoices = this.getAllCurrentSelectedServices();
		const services = this.getStaffChoices(id);
		// Filter services from other advisers
		const choices = services?.filter(
			(x) => {
				const code = SasReference?.find(
						(r) => r?.display === x?.value
					)?.service;//Get service code for checking
				return !selectedChoices?.includes(x?.value) && this.hasBusinessService.includes(code) //add business filter
			}
		);
		const defaultSelection = pluck('value', choices);
		// Prepopulate services from User Details
		this.AdviserList.controls[index].get('services').setValue(defaultSelection);
	}

	onChangeLeadStatus(value: string) {
		if (!this._isEdit) {
			this.isSavingLS$.next(true);
			const primaryClient = ClientProfilePrimaryMapper.mapToView(this.data);

			const data: PrimaryClientState = {
				...primaryClient,
				leadStatus: value,
			};

			this.service
				.updatePrimaryClient(
					ClientProfilePrimaryMapper.mapToUpsert(data) as PrimaryClientState
				)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					() => {
						this.isSavingLS$.next(false);
					},
					() => {
						this.isSavingLS$.next(false);
					},
					() => {
						this.isSavingLS$.next(false);
					}
				);
		}
	}

	getAllCurrentSelectedServices(idToExclude?: number) {
		const formValue = this.form.getRawValue()?.advisers || [];
		if (!idToExclude) {
			return formValue?.reduce(
				(acc, cur) => uniq([...acc, ...(cur?.services || [])]),
				[]
			);
		}
		return formValue?.reduce((acc, cur) => {
			if (+cur.adviserId !== +idToExclude) {
				return uniq([...acc, ...(cur?.services || [])]);
			}
			return acc;
		}, []);
	}

	getAltAdvisers() {
		const value = util.tryParseJson(this.data?.altAdviser as any);
		if (this.addMode || !value) {
			this.altAdviserDropdown = this.altAdvisers;
		}
		const list = this.altAdvisers || [];
		const otherAltAdvisers = value
			?.reduce((a, c) => {
				const adviserDataActive = list?.find((x) => +x?.value === +c);
				if (!!adviserDataActive) {
					return a;
				}
				const data = this.allStaff
					?.filter((x) => +x?.StaffID === +c)
					?.map((x) =>
						ViewDisplayValue.Map(
							x.StaffID?.toString(),
							`${x.FirstName} ${x.LastName}`
						)
					);
				return [...data, ...a];
			}, [])
			?.filter(Boolean);
		this.altAdviserDropdown = [...(otherAltAdvisers || []), ...list]?.sort(
			(a, b) => a.display?.localeCompare(b.display)
		);
	}

	getStaffChoices = (id: number) =>
		ClientProfilePrimaryMapper.getAdviserReworkChoices(id, this.allStaff);

	adviserChoices(index: number) {
		const adviserId = this.AdviserList.controls[index]?.value?.adviserId;
		const adviser = this.advisers?.find((x) => +x?.value === +adviserId);
		const selectedAdvisers = this.AdviserList.controls
			?.reduce((a, c) => {
				const val = c?.value?.adviserId;
				if (+val !== +adviserId) {
					return uniq([...a, +c?.value?.adviserId]);
				}
				return a;
			}, [])
			?.filter(Boolean);
		const adviserList = this.advisers?.filter(
			(x) => !selectedAdvisers?.includes(+x?.value)
		);

		if (!!adviser) {
			// If selected adviser is in the adviser list (allowed in accessibility settings) of the logged in user
			return adviserList;
		}
		// Should still show the adviser if selected regardless of accessibility settings
		const otherAdviser = this.allStaffChoices?.find(
			(x) => +x?.value === +adviserId
		);
		return !!otherAdviser
			? [otherAdviser, ...adviserList]?.sort((a, b) =>
					a.display?.localeCompare(b.display)
			  )
			: adviserList;
	}

	adviserServicesChoices(index: number) {
		const id = +this.AdviserList.controls[index]?.value?.adviserId;
		// Saved service of Adviser from CRM Profile
		const services = this.AdviserList.controls[index]?.value?.services;
		// Get Adviser Service from User Details Settings
		const availableChoices = this.getStaffChoices(id);
		// Get all the services from other advisers
		const otherSelectedChoices = this.getAllCurrentSelectedServices(id);
		// Get Currently selected services
		const details = this.AdviserList.controls[index].get('services').value;

		return (this.SAS || [])
			?.filter(
				(x) =>{
					const code = SasReference?.find(
						(r) => r?.display === x?.value
					)?.service; //Get Code for business checking
					return(
						(this.isEdit?
							(this.hasBusinessService?.includes(code)||details?.includes(x.value)) // Business Config services checker  and existing selected services checker
							:true) &&
						// Show Services from User Details
						(!!availableChoices?.find((c) => c?.value === x?.value) ||
						// Include chosen service from CRM Profile
						// So that even if the service was remove from the User Details, it will still appear
						!!services?.includes(x?.value))
					);
				}
			)
			?.filter(
				// Prevent user from choosing service for diff advisers
				(x) => !otherSelectedChoices?.includes(x?.value)
			);
	}

	private getBusinessProfileServices() {
		combineLatest([
			this.businessConfigQuery.hasLR$,
			this.businessConfigQuery.hasM$,
			this.businessConfigQuery.hasFG$,
			this.businessConfigQuery.hasK$,
			this.businessConfigQuery.hasI$,
			this.businessConfigQuery.hasAP$,
			this.businessConfigQuery.hasCRT$,
			this.businessConfigQuery.hasMOAT$,
			this.businessConfigQuery.hasKOAT$,
			this.businessConfigQuery.hasCPMOAT$,
			this.businessConfigQuery.hasCPMOATD$,
			this.businessConfigQuery.hasCAR$
		]).pipe(
			map(([LR, M, FG, K, I, AP, CRT, MOAT, KOAT, CPMOAT, CPMOATD, CAR]) => {
				return { LR, M, FG, K, I, AP, CRT, MOAT, KOAT, CPMOAT, CPMOATD, CAR };
			}),
			take(1)
		).subscribe((services) => {
			this.hasBusinessService = Object.keys(services).filter(
				(k) => services[k]
			);
		});
		
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
