import {
	IncomeSourceState,
	incomeDetailsList,
	FactFindComputationState,
	RentalIncomeObjState,
	RentalIncomeState,
	OtherIncomeObjState,
	OtherIncomeState,
	PreviousIncomeSourceState,
} from '../models/income.model';
import { convertToAnnual } from '../../../../../modules/crm/client-review-template/income-budget/calculations/annual-conversion';
import { numUtil } from '../../../../../util/util';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

/*
 * Income Source Mappers
 */
export class IncomeSourceMapper {
	public static mapIncomeToView(data: IncomeSourceState) {
		return {
			...data,
			incomeEarner: +data.incomeEarner,
			occupation: data.occupation,
			employment: data.employment,
			totalGrossIncome: +numUtil.formatToNumCurrency(data.totalGrossIncome),
		};
	}

	/** Map people to dropdown CRM */
	public static mapCRMPeopleToDd(list) {
		if (!list) {
			return [];
		}
		return list?.map((x) => {
			return {
				display: x.firstName + ' ' + x.lastName,
				value: x.customerID,
				isCRT: false,
				customerType: x?.customerType,
			};
		});
	}

	/** Map people to dropdown CRT */
	public static mapCRTPeopleToDd(list) {
		if (!list) {
			return [];
		}
		return list?.map((x) => {
			return {
				display: x.name,
				value: x.customerId,
				isCRT: true,
				customerType: x?.customerType,
			};
		});
	}

	/** Mapping for view */
	public static mapToView(data: any) {
		return {
			...data,
			incomeEarner: +data.incomeEarner,
			incomeType: data.incomeType,
			employer: data.employer,
			industry: data.industry,
			occupation: data.occupation,
			employment: data.employment,
			kiwiSaverContribution: data.kiwiSaverContribution,
			details: data.details,
			detailsList: [],
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: IncomeSourceState,
		cid?: number,
		adviceProcessId?: number
	): IncomeSourceState {
		let totalGross = 0;
		data.detailsList?.map(
			(dl) =>
				(totalGross += +convertToAnnual(
					+dl.incomeSourceValue,
					dl.incomeSourceFrequency
				))
		);
		return {
			...data,
			cRTId: cid,
			adviceProcessId,
			incomeEarner: +data.incomeEarner,
			incomeType: data.incomeType,
			employer: data.employer,
			industry: data.industry,
			occupation: data.occupation,
			employment: data.employment,
			kiwiSaverContribution: data.kiwiSaverContribution,
			totalGrossIncome: +numUtil.formatToNumCurrency(totalGross),
			details: JSON.stringify(data.detailsList),
			detailsList: data.detailsList?.map(
				(dl) =>
					// tslint:disable-next-line: no-angle-bracket-type-assertion
					<incomeDetailsList> {
						incomeSourceFrequency: dl.incomeSourceFrequency,
						incomeSourceType: dl.incomeSourceType,
						incomeSourceValue: +dl.incomeSourceValue,
					}
			),
			factFindComputation: {
				// adviceProcessId,
				sectionCode: 'FFC',
				totalPropertyValue: 0,
				totalAssetValue: 0,
				overallTotalGrossIncome: 0,
				totalNetRentalIncome: 0,
				annualTaxableJointIncome: 0,
				monthlyTaxableJointIncome: 0,
				monthlyAfterTaxIncome: 0,
			},
			sectionCode: 'FII',
			status: 1,
		};
	}

	/**
	 * Map to tempFactFind
	 */
	public static mapTempFactFind(
		data: IncomeSourceState,
		tempFactFind?: FactFindComputationState
	): IncomeSourceState {
		return {
			...data,
			factFindComputation: {
				...tempFactFind,
				// adviceProcessId: data.adviceProcessId,
				sectionCode: 'FFC',
			},
		};
	}
}

/*
 * Previous Income Source Mappers
 */

export class PreviousIncomeSourceMapper {
	public static mapIncomeToView(data: PreviousIncomeSourceState) {
		return {
			...data,
			incomeEarner: +data.incomeEarner,
		};
	}

	/** Map people to dropdown CRM */
	public static mapCRMPeopleToDd(list) {
		if (!list) {
			return [];
		}
		return list?.map((x) => {
			return {
				display: x.firstName + ' ' + x.lastName,
				value: x.customerID,
				isCRT: false,
				customerType: x?.customerType,
			};
		});
	}

	/** Map people to dropdown CRT */
	public static mapCRTPeopleToDd(list) {
		if (!list) {
			return [];
		}
		return list?.map((x) => {
			return {
				display: x.name,
				value: x.customerId,
				isCRT: true,
				customerType: x?.customerType,
			};
		});
	}

	/** Mapping for view */
	public static mapToView(data: any) {
		return {
			...data,
			incomeEarner: +data.incomeEarner,
			incomeType: data.incomeType,
			employer: data.employer,
			industry: data.industry,
			occupation: data.occupation,
			employment: data.employment,
			periodOfEmployment: data.periodOfEmployment,
			kiwiSaverContribution: data.kiwiSaverContribution,
			details: data.details,
			detailsList: [],
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: PreviousIncomeSourceState,
		cid?: number,
		apId?: number
	): PreviousIncomeSourceState {

		return {
			...data,
			cRTId: cid || null,
			adviceProcessId: apId,
			incomeEarner: +data.incomeEarner,
			employer: data.employer,
			previousOccupation: data.previousOccupation,
			periodOfEmployment: data.periodOfEmployment,
			sectionCode: AdviceProcessSectionCodes.PreviousIncome,
		};
	}
}

/*
 * Net Rental Income Mappers
 */
export class RentalIncomeMapper {
	/** Mapping for view */
	public static mapRentalIncome(data: RentalIncomeObjState, properties) {
		const getAddress = (id: number) => {
			return properties?.find((item) => +item.cRTId === +id)?.propertyAddress;
		};

		return data?.netRentalIncomeList?.map(
			({ propertyAsset, netRentalIncome, cRTId, adviceProcessId, incomeType }) => ({
				cRTId,
				adviceProcessId,
				propertyAsset,
				netRentalIncome,
				incomeType,
				btnSaveRental: false,
				btnEditRental: true,
				propertyAddress: getAddress(propertyAsset) || '',
			})
		);
	}

	public static mapGross(data: RentalIncomeObjState) {
		return +data.totalNetRentalIncome;
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: RentalIncomeState,
		cid: number,
		adviceProcessId: number,
		tempFactFind?: FactFindComputationState
	): RentalIncomeState {
		return {
			cRTId: cid,
			propertyAsset: +data.propertyAsset,
			netRentalIncome: +(data.netRentalIncome || 0),
			adviceProcessId,
			status: 1,
			sectionCode: 'FIR',
			incomeType: data?.incomeType || 'Rental',
			factFindComputation: {
				...tempFactFind,
				// adviceProcessId,
				sectionCode: 'FFC',
			},
		};
	}
}

/*
 * Other Income Mappers
 */
export class OtherIncomeMapper {
	/** Mapping for view */
	public static mapOtherIncome(data: OtherIncomeObjState) {
		return data?.otherIncome?.map(
			({ incomeType, annualIncome, cRTId, adviceProcessId, isNonTaxable }) => ({
				cRTId,
				adviceProcessId,
				incomeType,
				annualIncome,
				isNonTaxable,
				btnSaveOther: false,
				btnEditOther: true,
			})
		);
	}

	public static mapGross(data: OtherIncomeObjState) {
		return +data.totalAnnualIncome;
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: OtherIncomeState,
		cid: number,
		adviceProcessId: number,
		tempFactFind?: FactFindComputationState
	): OtherIncomeState {
		return {
			...data,
			cRTId: cid,
			annualIncome: +data.annualIncome,
			adviceProcessId,
			sectionCode: 'FIO',
			factFindComputation: {
				...tempFactFind,
				// adviceProcessId,
				sectionCode: 'FFC',
			},
			status: 1,
		};
	}
}
