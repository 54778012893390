import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	MatDateFormats,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
	MatDatepicker,
	MatDatepickerInput,
	MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { Moment } from 'moment';
import { Observable, Subscription } from 'rxjs';
import { staticConf } from '../../../core/config/static-config.service';
import MomentUtil from '../../../util/moment.util';
import { InputDateService } from './input-date.service';

export const MY_FORMATS = {
	parse: {
		dateInput: 'l',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
@Component({
	selector: 'app-input-date',
	templateUrl: './input-date.component.html',
	styleUrls: ['./input-date.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: InputDateComponent,
			multi: true,
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MY_FORMATS,
		},
	],
})
export class InputDateComponent implements OnInit {
	@Input() noYear: boolean;
	@Input() disabled = false;
	@Input() min: Date;
	@Input() max: Date;
	@Input() textboxClass = '';
	@Input() dateboxClass = '';
	@Input() tabindex: number;
	@Input() placeholder = 'dd/mm/yyyy';
	@Input() textboxId = '';
	@Input() showButton = true;
	@Output() onChangeEvent = new EventEmitter<any>();
	date$: Observable<Moment>;
	datafacade: string;

	@Input() appearance: 'bootstrap' | 'crt' = 'bootstrap';

	// value parser from model to view
	@Input() outputValueParser: (date: Moment) => string;

	@ViewChild('date') dateInput: ElementRef;
	@ViewChild('date') matInput: MatDatepickerInput<any>;
	@ViewChild('picker') picker: MatDatepicker<any>;

	/**
	 * remove all attribute from host element. so we dont have a
	 * duplicate attribute when we supplied tabindex, type, size, and id
	 */
	@HostBinding('attr.tabindex') attrTabindex = null;
	@HostBinding('attr.type') attrType = null;
	@HostBinding('attr.size') attrSize = null;
	@HostBinding('attr.id') attrId = null;
	@HostBinding('attr.readonly') attrReadonly = null;
	@HostBinding('attr.appearance') attrAppearance = null;

	@HostBinding('attr.class') get attrClass() {
		return this.appearance === 'bootstrap' ? '' : 'appearance-crt';
	}

	get inputClass(): string {
		return `${this.textboxClass ?? ''}`;
	}

	constructor(
		private service: InputDateService,
		@Inject(MAT_DATE_FORMATS) private format,
		private cdr: ChangeDetectorRef
	) {}

	ngAfterViewInit() {
		if (this.noYear) {
			this.format.parse.dateInput = 'DD/MM';
			this.format.display.dateInput = 'DD/MM';
			this.format.display.monthYearLabel = 'MMM';
			this.format.display.monthYearA11yLabel = 'MMM';
		}
	}

	get dateBoxClass() {
		return !!this.dateboxClass ? this.dateboxClass : '';
	}

	writeValue(value: any): void {
		if (value) {
			if (!MomentUtil.isValidMoment(value)) {
				value = MomentUtil.formatDateToMoment(value);
			}
			this.datafacade = value;
		} else {
			this.datafacade = '';
		}
		this.cdr.detectChanges();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnInit() {
		if (this.picker) {
			this.picker.panelClass = this.dateBoxClass;
		}
	}

	ngOnDestroy(): void {}

	changeDate(event: MatDatepickerInputEvent<Moment>) {
		// @ts-ignore-next
		this.onChange(this.modelToViewParser(event.value) as any);
		// this.change(event.value);
		// this.onChange?.(MomentUtil.formatToServerDate(event.value) as any);
		this.onChangeEvent.emit(event);
	}

	private modelToViewParser(value: any): string {
		if (this.outputValueParser) {
			// @ts-ignore-next
			return this.outputValueParser(MomentUtil.convertToMoment(value));
		}
		return MomentUtil.formatToServerDate(value) as any;
	}

	change(mom?: Moment) {
		// this.service.set(mom as Moment);
	}

	onChange = (value: Moment) => {};
	onTouched = () => {};

	isInvalid() {
		return (
			!!this.dateInput?.nativeElement?.value &&
			this.dateInput?.nativeElement?.value.trim() !== '' &&
			!MomentUtil.IsValidDateFormat(
				this.dateInput.nativeElement.value,
				staticConf.displayDateFormat
			)
		);
	}

	reset() {
		this.dateInput.nativeElement.value = '';
	}
}
