import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataImportService } from '../data-import.component.service';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-import',
  templateUrl: './new-import.component.html',
  styleUrls: ['./new-import.component.scss']
})
export class NewImportComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  startTime: any = null;
  endTime: any = null;

  obj64: any = null;

  importType: any = null;
  columnOrder: any = null;
  fileByte: any = null;
  contactStatus: any = null;

  progress = {
    width: '0%'
  };

  rowCount: number;
  count: number;
  percent: number;
  uploadCount: number;

  successCount: number;
  failCount: number;

  showProgress: boolean;
  showSummary: boolean;
  summaryLink: string;

  showInitSummary: boolean;
  strictError: string;

  isCancelled: boolean;
  dataImportID: number;

  constructor(private dataImportService: DataImportService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.progress = {
      width: 0 + '%'
    };

    this.count = 0;
    this.successCount = 0;
    this.failCount = 0;
    this.percent = 0;
    this.uploadCount = 0;

    this.strictError = '';
    this.showProgress = false;
    this.showSummary = false;
    this.showInitSummary = false;
    this.summaryLink = '';

    this.startTime = null;
    this.endTime = null;

    this.isCancelled = false;
    this.rowCount = 0;
  }

  acceptImport(file) { this.importType = file; }
  acceptColumns(file) { this.columnOrder = file; }
  acceptFile(file) { this.fileByte = file; }
  acceptContactStatus(file) { this.contactStatus = file; }

  importData = (data: any) => {
    if (this.count === 0) {
      this.obj64 = {
        CompanyCode: this.route.snapshot.paramMap.get('companyCode'),
        ImportType: this.importType,
        ColumnOrders: this.columnOrder,
        File: this.fileByte,
        ContactStatus: this.contactStatus,
        FileExtension: ''
      }
      this.successCount = 0;
      this.failCount = 0;
    }
    if (this.isCancelled) {
      this.strictError = 'Import Cancelled';
      this.showInitSummary = false;
      this.showSummary = true;
      return;
    }
    this.showProgress = true;
    this.count++;

    this.dataImportService
      .importData(this.obj64)
      .pipe(
        tap(x => {
          const isSuccess = x?.Success;
          this.successCount += isSuccess ? 1 : 0;
          this.failCount += !isSuccess ? 1 : 0;

          this.uploadCount++;
          if (this.count === 1) {
            this.rowCount = x.RowCount;
            this.startTime = x.StartTime;
            this.dataImportID = x.DataImportID;
            Object.assign(this.obj64, {
              CompanyCode: x.CompanyCode,
              SourcePath: x.SourcePath,
              SummarySourcePath: x.SummarySourcePath,
              DataImportID: x.DataImportID
            });
          }
          // Progress bar
          this.computeProgess();

          if (x.StrictError) {
            this.showInitSummary = this.count < 1 ? true : false;
            this.showSummary = true;
            this.showProgress = false;
            this.strictError = x.StrictError;
            return;
          }
          // Loops import data if isComplete = false
          if (!x.IsComplete) {
            this.importData(this.obj64);
          } else {
            this.showSummary = true;
            this.showProgress = false;
            this.endTime = x.EndTime;
            this.summaryLink = x.SumarryLink;
            this.successCount = x?.SuccessCount || 0;
            this.failCount = x?.FailedCount || 0;
          }
        }),
        catchError((err: { status_code: number; StrictError: string[] }) => {
          this.strictError = err.StrictError[0];
          if (this.uploadCount < 1) {
            this.showInitSummary = true;
          } else {
            this.showInitSummary = false;
          }
          this.showSummary = true;
          return throwError(`Process error: ${err.StrictError}`);
        }),
        takeUntil(this.onDestroy$)
      ).subscribe();

  }

  cancelImport = () => {
    this.isCancelled = true;
    this.dataImportService.cancelImport(this.dataImportID).subscribe();
  }

  computeProgess() {
    this.percent = +((this.count * 100) / this.rowCount)?.toFixed(2);
    this.progress = {
      width: this.percent + '%'
    };
  }

  importNew = () => {
    this.initData();
  }

  cancel = () => {
    this.initData();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

}
