import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CrtKiwiSaverRiskProfile {
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	parentCRTId: number;
	customerId: number;
	currentPage: string;
	status: number;
	mostImportantWhileSaving: string;
	mostImportantWhileSavingScore: number;
	levelOfGainOrLoss: string;
	levelOfGainOrLossScore: number;
	howLongToSpendKiwiSaver: string;
	howLongToSpendKiwiSaverScore: number;
	//not required on POST
	outcome: string;
	outcomeScore: number;
}

export interface CrtKiwiSaverRiskProfileState
	extends EntityState<CrtKiwiSaverRiskProfile> {}

export const CrtKiwisaverRiskProfileStoreName =
	'CrtKiwisaver:FactFind - Risk Profile';
@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: CrtKiwisaverRiskProfileStoreName,
	idKey: 'cRTId',
})
export class RiskProfileStore extends EntityStore<CrtKiwiSaverRiskProfileState, CrtKiwiSaverRiskProfile> {
	constructor() {
		super([]);
	}
}
