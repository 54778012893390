import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { MMGTStore } from './mmgt.store';
import { MonthlyMortgageGoalTrack } from './mmgt.model';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class MMGTService {

 /**
  * partial url endpoint
  */
  private readonly endpoint = 'widgets?type=MMGT';

  constructor(
    private api: ApiService,
    private store: MMGTStore
  ) { }

  /**
   * Fetch and store Monthly Mortgage goal track data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetMonthlyMortgageGoalTrack(adviserIdList: string[]): void {
    this.store.setLoading(true);
    this.api.post<MonthlyMortgageGoalTrack>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update({GoalTrack: res}),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
