import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OtherDetailsStore, OtherDetailsState } from './other-details.store';

@Injectable({ providedIn: 'root' })
export class OtherDetailsQuery extends QueryEntity<OtherDetailsState> {
	otherDetails$ = this.selectAll();
	activeOtherDetails$ = this.selectActive();

	constructor(protected store: OtherDetailsStore) {
		super(store);
	}
}
