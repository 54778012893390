<app-primary-base-widget headerText="L&R Commission Tracker" [widgetCode]="widgetCode">
  <app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
  <div class="lrct lrct--container position-relative px-3 clearfix">
    <div class="clearfix lrct__content">
      <div class="d-flex row">
        <div class="col-7 lrct__content__pills pt-0">
          <div class=" py-1 px-4 text-center tap-bg-primary tap-text-color-h1">
            ${{ currentFinancialYTD$ | async | number }}
          </div>
        </div>
        <div class="col-5 lrct__content__info align-self-center">
          <div class="">
            Financial YTD
          </div>
        </div>

        <div class="col-7 lrct__content__pills">
          <div class=" py-1 px-4 text-center tap-bg-primary tap-text-color-h1">
            ${{ currentQuarter$ | async | number }}
          </div>
        </div>
        <div class="col-5 lrct__content__info align-self-center">
          <div class="">
            Current Quarter
          </div>
        </div>

        <div class="col-7 lrct__content__pills">
          <div class=" py-1 px-4 text-center tap-bg-primary tap-text-color-h1">
            ${{ currentMonth$ | async | number }}
          </div>
        </div>
        <div class="col-5 lrct__content__info align-self-center">
          <div class="">
            Current Month
          </div>
        </div>

        <div class="col-7 lrct__content__pills pb-0">
          <div class=" py-1 px-4 text-center tap-bg-primary tap-text-color-h1">
            ${{ currentPipeline$ | async | number }}
          </div>
        </div>
        <div class="col-5 lrct__content__info align-self-center">
          <div class="">
            Current Pipeline
          </div>
        </div>
      </div>
    </div>
  </div>
</app-primary-base-widget>