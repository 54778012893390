<div class="row tap-container soa-settings pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Presentation Pages">
					<app-fact-find-subpages-settings></app-fact-find-subpages-settings>
				</tab>

				<tab heading="Risk Profile">
					<app-fact-find-risk-profile-settings></app-fact-find-risk-profile-settings>
				</tab>

				<tab heading="Providers">
					<app-providers-settings></app-providers-settings>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
