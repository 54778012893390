export interface AdviceProcessModel {
	AdviceProcessSettingsId: number;
	ProcessCode: string;
	Type: string;
	Value: string;
	IsEnabled: boolean;
	IsActive: boolean;
	SettingOrder: number;
	Status: number;
}

export enum AdviceProcessTypes {
	Steps = 'S',
	OfflineSteps = 'OS',
	Documents = 'D',
	OfflineDocuments = 'OD'
}
