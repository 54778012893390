import {
	Directive,
	HostListener,
	Self,
	ElementRef,
	Optional,
} from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * This extends the ngx-currency event handling
 * Use this directive along with CurrencyMask from ngx-currency only
 * This directive fixes the bug for auto-fill on input textboxes
 * Until ngx-currency patches a fix
 * Ref: https://github.com/nbfontana/ngx-currency/issues/94
 */

@Directive({
	selector: '[appCurrencyMaskExt]',
})
export class CurrencyMaskExtendDirective {
	private el: HTMLInputElement;

	constructor(
		@Self() @Optional() private ngControl: NgControl,
		private elementRef: ElementRef
	) {
		this.el = this.elementRef.nativeElement;
	}

	get isFocused() {
		return this.el.dataset.isFocused;
	}

	updateValue(value) {
		const val = value?.replace(',', '');
		if (this.el.value !== '') {
			this.el.value = val;
		}
		if (this.ngControl) {
			this.ngControl.control.setValue(val);
		}
	}

	@HostListener('input', ['$event'])
	onInput(event) {
		this.updateValue(event.target.value);
	}
}
