import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { KiwiSaverSettingsQuery } from '../../../state/kiwisaver-settings.query'
import { KiwiSaverSettingsStore } from '../../../state/kiwisaver-settings.store';
import { DocumentList, DocumentListState } from './document-list.model';
import { iif, of } from 'rxjs';
import * as R from 'ramda';
import { objectUtil } from 'src/app/util/util';
import { SettingsTypes } from '../../../state/kiwisaver-settings.model';

@Injectable()
export class DocumentListService {
  documentList$ = this.query.documentList$;

  constructor(
    private api: ApiService,
    private store: KiwiSaverSettingsStore,
    private query: KiwiSaverSettingsQuery
  ){}

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  getDocumentList(staffId: number = 0, type: string) {
    const endpoint = `crt/settings/${staffId}/${type}`;
		  return this.api.get<DocumentList[]>(endpoint).pipe(
			map((data) => {
				const defaultPrio = data.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							SortNo: item?.SortNo ?? defaultPrio,
					  }))
					: [];
			}),
			map((data) => R.sort((a, b) => a?.SortNo - b?.SortNo, data)),
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : [];
          this.store.setDocumentList(Object.values(state));
				})
			),
			catchError(() => of(undefined))
		);
  }

  deleteDocument(settingsId: number) {
    const endpoint = `staff/settings/${settingsId}`;
		  return this.api.delete<any>(endpoint).pipe(catchError((err) => of(err)));
  }

  addDocument(addedDocument, documents = null) {
    const docs = documents
      ? documents
      : [...this.query.getValue().documentList];
    docs.push(addedDocument);
    this.store.setDocumentList(docs);
  }

  save(payload, refetchQuery: boolean = false) {
    const endpoint = `crt/settings/multiple`;
    return this.api.put<DocumentListState[]>(endpoint, payload).pipe(
			tap((data) => {
				applyTransaction(() => {
					this.store.setDocumentList(
            Object.values(objectUtil.mapPascalCaseToCamelCase(payload))
          );
				})
      }),
			concatMap(() => {
				return iif(() => refetchQuery, this.getDocumentList(0, SettingsTypes.AdviceSummaryDocumentListSettings), of(payload));
			}),
			catchError((err) => of(err))
		);
  }

  update(payload, refetchQuery: boolean = false) {
    const endpoint = `crt/settings/0`;
    return this.api.put<DocumentListState>(endpoint, payload)
    .pipe(
      catchError((err) => of(err))
    );
  }
}
