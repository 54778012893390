import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlBusinessComponentService } from '../bl-business-component.service';
import { ViewBusiness } from '../../viewmodels/business.viewmodel';
import { tap, map, takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserQuery } from '../../../../domain/user/user.query';

@Component({
	selector: 'app-bl-business-view',
	templateUrl: './bl-business-view.component.html',
	styleUrls: ['./bl-business-view.component.scss'],
})
export class BlBusinessViewComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	isLoading = false;
	isEdit = false;
	businessName$ = this.compService.businessName$;
	viewBusiness$ = this.compService.viewBusiness$;
	coverPhoto$ = this.viewBusiness$.pipe(
		map((bus) => (bus ? bus.coverphoto : null)),
		takeUntil(this.onDestroy$)
	);
	hasPermission$ = this.userQuery.hasPermission$;
	clientAlterationRequestEnabled$ = this.compService.cARFeatureEnabled$;

	constructor(
		private compService: BlBusinessComponentService,
		private userQuery: UserQuery
	) {
		this.viewBusiness = new ViewBusiness();
	}
	viewBusiness: ViewBusiness;
	ngOnInit(): void {
		this.compService.viewBusiness$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.viewBusiness = x));
	}

	edit() {
		this.isEdit = true;
	}
	cancel() {
		this.isEdit = false;
	}
	save(data: ViewBusiness) {
		this.isLoading = true;

		this.compService
			.save(data)
			.pipe(
				finalize(() => {
					this.isEdit = false;
					this.isLoading = false;
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
