import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LrInsuranceState, LrInsuranceStore } from './lr-insurance.store';
import { Row } from './lr-insurance.model';
import {
	map,
	withLatestFrom,
	distinctUntilChanged,
	combineLatest,
	auditTime,
	shareReplay,
	delay,
} from 'rxjs/operators';
import { columns, templateRow, EnhancedTableColumn } from '../lr-insurance-datatable.config';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { LrInsuranceUiQuery } from './lr-insurance-ui.query';
import * as R from 'ramda';
import { Observable, of } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { mapToLookupObject } from '../../../../../domain/dropdown-value/dropdown-value.model';
import sort from 'fast-sort';
import { util } from '../../../../../util/util';
import { UserQuery } from '../../../../../domain/user/user.query';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Injectable()
export class LrInsuranceQuery extends QueryEntity<LrInsuranceState, Row> {
	private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
		map(x => R.map(y => ViewDisplayValue.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
		map(x => (x ? x.sort((a, b) => a.display.localeCompare(b.display)) : x))
	); // Advisers
	private AT$ = this.dropdownQuery.orderedChoices$('AT'); // Activity Type
	private LRCC$ = this.dropdownQuery.orderedChoices$('LRCC'); // Cancellation Code
	private LRPD$ = this.dropdownQuery.orderedChoices$('LRPD'); // Policy Discount
	private LRPF$ = this.dropdownQuery.orderedChoices$('LRPF'); // Payment Frequency
	private LRPS$ = this.dropdownQuery.orderedChoices$('LRPS'); // Policy Status
	private LRPT$ = this.dropdownQuery.orderedChoices$('LRPT'); // Policy Type
	private LRRS$ = this.dropdownQuery.orderedChoices$('LRRS'); // Policy Retention Status
	private LRP$ = this.dropdownQuery.orderedChoices$('LRP'); // Provider
	private LRPR$ = this.dropdownQuery.orderedChoices$('LRPR'); // Products
	private LRCS$ = this.dropdownQuery.orderedChoices$('LRCS'); // Claim Status
	private PCLE$ = this.dropdownQuery.orderedChoices$('PCLE'); // Lead Origin

	columns$ = this.select(x => x.columns);
	tableColumns$ = this.columns$
		.pipe(
			withLatestFrom(this.userQuery.isUserWithOptFields$, this.businessConfigQuery.adviserReworkFeature$),
			map(([tableColumns, isUserWithOptFields, adviserReworkFeature]) => {
				return tableColumns
					.filter(x => adviserReworkFeature ? (x != 'Adviser') : (x != 'LR Adviser' && x != 'GI Adviser'))
					?.map(col =>
						columns?.find(y => (!isUserWithOptFields ? y.metakey === col : y.metakey === col && y.metakey !== 'Email'))
					)
					?.filter(x => x !== undefined);
			}),
			withLatestFrom(this.select(x => x.columnWidths)),
			map(([tableColumns, widths]) =>
				tableColumns?.map(column => {
					const widthConf = widths?.filter(x => x)?.find(width => width.metakey === column.metakey);
					const newColumn = { ...column, $$id: column.name };
					if (widthConf) {
						return { ...newColumn, width: widthConf.width };
					} else {
						return newColumn;
					}
				})
			),
			combineLatest(this.advisers$, this.LRCC$, this.LRPD$, this.LRPF$),
			auditTime(0),
			combineLatest(this.LRPS$, this.LRPT$, this.LRRS$, this.LRP$, this.LRPR$),
			auditTime(0),
			combineLatest(this.LRCS$, this.PCLE$)
		)
		.pipe(
			map(([[[tableColumns, advisers, lrcc, lrpd, lrpf], lrps, lrpt, lrrs, lrp, lrpr], lrcs, pcle]) => {
				return tableColumns?.map(c => {
					switch (c.metakey) {
						case 'Adviser':
						case 'LR Adviser':
						case 'GI Adviser':
						case 'Original Adviser':
							return this.fillUpChoices(advisers, c);
						case 'Cancellation Code':
							return this.fillUpChoices(lrcc, c);
						case 'Policy Discount':
							return this.fillUpChoices(lrpd, c);
						case 'Payment Frequency':
							return this.fillUpChoices(lrpf, c);
						case 'Policy Status':
							return this.fillUpChoices(lrps, c);
						case 'Policy Type':
							return this.fillUpChoices(lrpt, c);
						case 'Policy Retention Status':
							return this.fillUpChoices(lrrs, c);
						case 'Provider':
							return this.fillUpChoices(lrp, c);
						case 'Products':
							return this.fillUpChoices(lrpr, c);
						case 'Policy Claim Status':
							return this.fillUpChoices(lrcs, c);
						case 'Lead Origin':
							return this.fillUpChoices(pcle, c);
						default:
							return c;
					}
				});
			}),
			distinctUntilChanged((x, y) => R.equals(x, y)),
			shareReplay(1)
		);

	hiddenTableColumns$ = this.columns$.pipe(
		withLatestFrom(this.userQuery.isUserWithOptFields$),
		map(([tableColumns, isUserWithOptFields]) => {
			return columns?.filter(x =>
				!isUserWithOptFields
					? !tableColumns?.includes(x.metakey)
					: !tableColumns?.includes(x.metakey) && x.metakey !== 'Email'
			);
		})
	);

	sorts$ = this.uiQuery.select(x => [{ dir: x.sort, prop: x.propSort }]);
	cellsLoading$ = this.uiQuery.select(state => state.cellLoadingStatus);
	cellsEditing$ = this.uiQuery.select(state => state.cellEditStatus);
	cellsTempvalue$ = this.uiQuery.select(state => state.cellTempValue);
	rowsLoading$ = this.uiQuery.select(state => state.rowLoadingStatus);
	isSearching$ = this.uiQuery.select(state => state.isSearching);
	allAdvisers$ = this.blstaffQuery.allStaffsChoices$;

	templateRow$: Observable<Row> = of(templateRow);

	rows$: Observable<Row[]> = this.selectAll();
	hasRows$: Observable<boolean> = this.selectCount().pipe(map(x => x > 0));

	count$ = this.select(x => x.count);
	totalApi$ = this.select(x => x.totalAPI);
	searchForm$ = this.select(x => x.searchForm);
	isComplete$ = this.select(x => x.isComplete);

	sortedRows$ = this.uiQuery
		.select(x => [x.propSort, x.sort])
		.pipe(
			distinctUntilChanged((p, q) => R.equals(p, q)),
			combineLatest(this.rows$),
			withLatestFrom(this.tableColumns$),
			withLatestFrom(this.isSearching$),
			withLatestFrom(this.allAdvisers$),
			delay(0),
			map(([[[[[prop, sortDirection], rows], tableColumns], isSearching], allAdvisers]) => {
				if (sortDirection === '' || prop === '' || isSearching) {
					return rows;
				} else {
					const column = tableColumns?.find(x => x.prop === prop);
					if (util.isNullOrEmpty(column)) {
						return rows;
					}

					const decorated = rows?.map<[number, any, number, Row]>(r => {
						const adviserColumn = [
							'Adviser',
							'OriginalAdviser',
							'LRAdviser',
							'GroupInsuranceAdviser'
						].includes(prop) ? allAdvisers : column.choices;

						const actualValue = column.sortValueGetter(
							r[prop],
							adviserColumn,
							r
						);
						const isZeroAtTheBottom = prop === 'Commission';
						return [
							this.spaceSortValueGetter(actualValue, isZeroAtTheBottom),
							R.defaultTo('', actualValue),
							r.CustomerID,
							r,
						];
					});
					return sort(decorated)
						.by([
							{ asc: u => u[0] },
							{ [sortDirection]: u => (u[1] && isNaN(u[1]) ? u[1]?.toLowerCase() : u[1]) } as any,
							{ asc: u => u[2] },
						])
						.map(x => x[3]);
				}
			})
		);

	getCustomerName = (customerServiceId: number) => this.getEntity(customerServiceId).Name.value;

	getSum(total: number, num: Row) {
		return total + +num.Premium.value;
	}

	constructor(
		protected dropdownQuery: DropdownValueQuery,
		protected blstaffQuery: BLStaffsQuery,
		protected lrInsuranceStore: LrInsuranceStore,
		public uiQuery: LrInsuranceUiQuery,
		protected userQuery: UserQuery,
    protected businessConfigQuery: BusinessConfigQuery
	) {
		super(lrInsuranceStore);
	}

	/** fill up choices and choices as object. */
	private fillUpChoices(choices: ViewDisplayValue[], column: EnhancedTableColumn) {
		return {
			...column,
			choices,
			choicesAsObject: mapToLookupObject(choices),
		};
	}

	/** Create a value usable as index for sorting.
	 * Its only necessary to know if value is empty or not.
	 * So if it is not empty, return 1 which is first in sort index.
	 * And 3 if empty.
	 * 
	 * If isZeroAtTheBottom is true; Zero values will always show at the bottom
	 * before the empty values
	 */
	private spaceSortValueGetter(
		fieldValue: string | number | null | undefined,
		isZeroAtTheBottom: boolean
	): 1 | 2 | 3 {
		let stringValue: string;
		if (util.isNullOrEmpty(fieldValue)) {
			stringValue = '';
		} else if (typeof fieldValue === 'string') {
			stringValue = fieldValue?.trim();
		} else {
			stringValue = fieldValue?.toString();
		}
		if (stringValue === '') {
			return !!isZeroAtTheBottom ? 3 : 2;
		} else {
			if (!!isZeroAtTheBottom && +stringValue === 0) {
				return 2;
			}
			return 1;
		}
	}
}
