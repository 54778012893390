import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ControlsService {
  private closeEvent: Subject<any>;
  public readonly closeEvent$: Observable<any>;

  constructor() {
    this.closeEvent = new Subject();
    this.closeEvent$ = this.closeEvent.asObservable();
  }

  closeAll = (triggerer: any) => {
    this.closeEvent.next(triggerer);
  };
}
