import { AdviceProcessSectionCodes } from '../../../advice-process/advice-process.model';
import { KiwiSaverInfo } from './kiwiSaver.model';

export class KiwiSaverMapper {
	static mapToView(v: KiwiSaverInfo) {
		return !!v
			? {
					owner: (v.Owner as number[])?.map((owner) => owner?.toString()),
					currentBalance: v.CurrentBalance,
					provider: v.Provider,
					fundType: v.FundType,
					contributionRate: v.ContributionRate,
					riskProfile: v.RiskProfile,
			  }
			: {};
	}

	static mapToUpsert(m: KiwiSaverInfo, cRTId) {
		return {
			cRTId,
			adviceProcessId: m.AdviceProcessId,
			currentBalance: m.CurrentBalance,
			fundOwner: m.FundOwner,
			fundType: m.FundType,
			provider: m.Provider,
			customerServiceID: m?.CustomerServiceID,
		};
	}

	static mapToKiwiSaverDetails(m, adviceProcessId) {
		return {
			CRTId: m?.cRTId ?? null,
			AdviceProcessId: adviceProcessId,
			SectionCode: AdviceProcessSectionCodes.KiwiSaver,
			Owner: m?.owner,
			Provider: m.provider,
			CurrentBalance: +m.currentBalance,
			FundType: m?.fundType ?? [],
			ContributionRate: m?.contributionRate ?? null,
			RiskProfile: m?.riskProfile ?? null,
			CustomerServiceID: m?.customerServiceID ?? null,
			Status: 1,
			PendingAdviserApproval: m?.pendingAdviserApproval ?? false,
		};
	}
}
