import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { TransferActivityModel } from '../../../domain/bl-staff/bl-staffs.service';

@Component({
	selector: 'app-transfer-activity-modal',
	templateUrl: './transfer-activity-modal.component.html',
})
export class TransferActivityModalComponent implements OnInit {
	transferFn: (model: TransferActivityModel) => Observable<any>;

	header: string;
	advisers: any[];
	staff: any;
	customMessage: string;
	message: string;
	isSaving = false;

	constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {}

	form: UntypedFormGroup = this.fb.group({
		adviser: null,
	});

	ngOnInit() {
		this.message =
			this.customMessage ||
			`Choose a user to replace '${this.staff.FirstName} ${this.staff.LastName}'`;
	}

	continue() {
		if (!this.form.get('adviser').value) {
			return;
		}
		this.isSaving = true;

		of({
			CurrentAdviser: this.staff.StaffID,
			NewAdviser: this.form.get('adviser').value
				? +this.form.get('adviser').value
				: 0,
		})
			.pipe(
				switchMap((x) => this.transferFn(x)),
				tap(() => {
					this.decline();
					setTimeout(() => {
						this.isSaving = false;
					}, 1000);
				}),
				take(1),
				catchError((error) => {
					if (error) {
						this.isSaving = false;
					}
					throw error;
				}),
			)
			.subscribe();
	}

	decline() {
		this.bsModalRef.hide();
	}
}
