import { ProgressStatus } from '@shared/progress-popup/progress';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import {
	MonoTypeOperatorFunction,
	Observable,
	Subject,
	throwError,
	timer,
} from 'rxjs';
import {
	catchError,
	delay,
	finalize,
	map,
	retry,
	retryWhen,
	scan,
	switchMapTo,
	takeUntil,
	takeWhile,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { TransferType } from './transfer.model';
import { TransferStore } from './transfer.store';
import { TransferQuery } from './transfer.query';
import { UserQuery } from '@domain/user/user.query';
import { LoggerService } from '@core/logger/logger.service';
import { logMessage } from '@shared/error-message/error-message';
import { ExportsTypeName } from '@modules/exports/state/exports.model';

export const DEFAULT_INTERVAL = 5000; // polling interval in ms
export const DEFAULT_INTERVAL_BEFORE_POLLING = 10000; // initial delay before the actual polling in ms
export const MAX_ATTEMPTS = 500; // should have max attempts so it would not run infinitely
export const RETRIES = 500; // same as MAX_ATTEMPTS but for error retries

const MAX_RETRIES_ERROR = 'Exceeded max retries';
const MAX_ATTEMPTS_ERROR = 'Exceeded max attempts';
const FORCE_CANCEL = 'Polling was cancelled';
const GENERIC_ERROR = 'Something went wrong, please try again later';

@Injectable()
export class TransferService {
	cancelObservable$ = new Subject<void>();

	timeElapsed = 0;
	timer: ReturnType<typeof setInterval>;
	attempts = 0;
	progress = 0;
	averageResponse: number[] = [];
	timeRemaining = 0; // assumption / estimate
	status: ProgressStatus;

	constructor(
		private store: TransferStore,
		private query: TransferQuery,
		private apiService: ApiService,
		private userQuery: UserQuery,
		protected loggerService: LoggerService
	) {}

	getFileFromUrl(url: string) {
		return this.apiService.getExternalResourceAsBlob(url);
	}

	getExportLabel(type: string) {
		const key = Object.entries(TransferType).find(([_, val]) => val === type)?.[0];
		return ExportsTypeName[key as keyof ExportsTypeName] || '';
	}

	queue(payload, type: TransferType) {
		return this.apiService.post(`export/queue/${type}`, payload).pipe(
			tap((id: string) => {
				this.store.update({
					exportId: id,
				  });
			  }, 
      ),
			retry(1),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.shared.transfer.error);
				return throwError(err);
			})
		);
	}

  downloadExport(payload, type: TransferType) {
    this.resetExport();

		let timeToComplete = 0;

		if (payload?.CustomerIds?.length > 0) {
			this.timeRemaining = 60000;
			timeToComplete = 60000;
		} else {
			this.timeRemaining = 120000;
			timeToComplete = 120000;
		}

		this.store.update({
			staffId: this.userQuery.getValue().StaffID,
			reportCode: type,
			reportLabel: this.getExportLabel(type),
			status: ProgressStatus.STARTED,
			timeRemaining: this.timeRemaining,
		});

		this.status = ProgressStatus.STARTED;

		this.timer = setInterval(() => {
			this.timeElapsed = this.timeElapsed + 1000;
			this.timeRemaining = this.timeRemaining - 1000;
			this.progress = Math.round(
				100 - (this.timeRemaining * 100) / timeToComplete
			);

			if (this.progress >= 95 && this.status === ProgressStatus.STARTED) {
				this.progress = 95;
			}

			this.store.update({
				timeElapsed: this.timeElapsed,
				timeRemaining: this.timeRemaining,
				progress: Math.round(this.progress),
			});

			if (this.timeRemaining <= 0) {
				clearInterval(this.timer);
			}
		}, 1000);
  }

	getTranferStatus(id: string) {
		return this.apiService.get2(`export/polling/${id}`);
	}

	retriesCheck(maxRetries: number) {
		return (retryCount: number) => {
			if (retryCount > maxRetries) {
				throw new Error(MAX_RETRIES_ERROR);
			}
		};
	}

	attempsCheck(maxAttempts: number) {
		return (attemptsCount: number) => {
			if (attemptsCount > maxAttempts) {
				throw new Error(MAX_ATTEMPTS_ERROR);
			}
		};
	}

	pollWhile<T>(
		pollInterval: number,
		isPollingActive: (res: T) => boolean,
		maxAttempts = Infinity,
		cancelPoll$: Observable<T>,
		maxRetries: number,
		status$: Observable<any>
	): MonoTypeOperatorFunction<T> {
		return (source$) => {
			const poll$ = timer(0, pollInterval).pipe(
				withLatestFrom(status$),
				tap(([count, status]) => {
					if (status === null || status === ProgressStatus.CANCELLED) {
						throw new Error(FORCE_CANCEL);
					}
				}),
				scan((attempts) => ++attempts, 0),
				tap(this.attempsCheck(maxAttempts)),
				switchMapTo(source$),
				takeWhile(isPollingActive, true),
				takeUntil(cancelPoll$),
				retryWhen((errors) => {
					return errors.pipe(
						delay(pollInterval),
						tap((error) => {
							if (error && error.message.includes(FORCE_CANCEL)) {
								throw new Error(FORCE_CANCEL);
							}

							if (error && error.message.includes(MAX_ATTEMPTS_ERROR)) {
								throw new Error(MAX_ATTEMPTS_ERROR);
							}
						}),
						scan((retry) => ++retry, 0),
						tap(this.retriesCheck(maxRetries))
					);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);

			return poll$;
		};
	}

	startPolling<T>(source$: Observable<T>) {
		let hasServerError = false;

		return source$.pipe(
			this.pollWhile(
				DEFAULT_INTERVAL,
				({ Status }: any) => {
					if (Status === ProgressStatus.ERROR) {
						hasServerError = true;
					}

					return (
						Status !== ProgressStatus.COMPLETE &&
						Status !== ProgressStatus.ERROR &&
						this.status !== ProgressStatus.CANCELLED
					);
				},
				MAX_ATTEMPTS,
				this.cancelObservable$,
				RETRIES,
				this.query.status$
			),
			tap((data: any) => {
				this.status = data.Status;

				const currentProgress =
					data.TotalNoOfData > 0
						? (data.CompletedNoOfData * 100) / data.TotalNoOfData
						: 0;

				let update = {
					attempts: this.attempts++,
				};

				if (currentProgress > this.progress) {
					update = {
						...update,
						...{
							progress: Math.round(currentProgress),
						},
					};
				}

				this.store.update(update);
			}),
			finalize(() => {
				clearInterval(this.timer);
				const status = this.query.getValue().status;

				if (hasServerError) {
					this.store.update({
						status: ProgressStatus.ERROR,
						reportLabel: GENERIC_ERROR,
						progress: 0,
						attempts: 0,
						error: true,
					});
				} else {
					this.store.update({
						status:
							status === ProgressStatus.STARTED ? ProgressStatus.COMPLETE : null,
						timeRemaining: 0,
					});
				}
			}),
			catchError((err) => {
				this.store.update({
					status: ProgressStatus.ERROR,
					reportLabel: GENERIC_ERROR,
					progress: 0,
					attempts: 0,
					error: true,
				});
				return throwError(err);
			})
		);
	}

	resetExport() {
		this.store.reset();
		this.timeElapsed = 0;
		this.timeRemaining = 0;
		this.attempts = 0;
		this.progress = 0;
		this.averageResponse = [];
		this.status = null;
		clearInterval(this.timer);
	}

	stopPolling() {
		this.status = ProgressStatus.CANCELLED;
		this.cancelObservable$.next();
		this.resetExport();
		this.store.update({
			status: ProgressStatus.CANCELLED,
		});
	}
}
