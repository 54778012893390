<form [formGroup]="form" class="form disclosure-email-settings">
  <div class="row pb-0 pl-3 pb-2 pt-4">
    <div class="col-12 col-lg-6">
      <div class="row mb-4">
        <div class="col-1 disclosure-email-settings__checkbox m-0 pr-0 mw-50">
          <input
            type="checkbox"
            class="chckbox form-control theme-crm-field-disabled"
            id="isEnableEmailOption"
            formControlName="isEnableEmailOption"
          />
        </div>
        <div class="col-10 pt-1 pl-0 disclosure-email-settings__label">
          <label class="disclosure-email-settings__label__text"
            >Enable Email option</label
          >
        </div>
      </div>

			<div [hidden]="!IsEnableEmailOption.value">
        <div class="row pb-2">
          <div class="col-12 col-lg-5 disclosure-email-settings__label">
            <label class="form-check-label">CC:</label>
          </div>
          <div class="col-12 col-lg-7 disclosure-email-settings__textbox">
            <input
              type="text"
              class="form-control theme-crm-field-disabled"
              formControlName="carbonCopy"
            />
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-12 col-lg-5 disclosure-email-settings__label">
            <label class="form-check-label">BCC:</label>
          </div>
					<div
						class="col-12 col-lg-1 disclosure-email-settings__bcc d-flex align-items-center"
					>
						<span class="tap-text-color bcc-user">User, </span>
					</div>
          <div class="col-12 col-lg-6 disclosure-email-settings__textbox">
            <input
              type="text"
              class="form-control theme-crm-field-disabled"
              formControlName="blindCarbonCopy"
            />
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-12 col-lg-5 disclosure-email-settings__label">
            <label class="form-check-label">Subject:</label>
          </div>
          <div class="col-12 col-lg-7 disclosure-email-settings__textbox">
            <input
              type="text"
              class="form-control theme-crm-field-disabled"
              formControlName="subject"
            />
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-12 col-lg-5 disclosure-email-settings__label">
            <label class="form-check-label">Template ID:</label>
          </div>
          <div class="col-12 col-lg-7 disclosure-email-settings__textbox">
            <input
              type="text"
              class="form-control theme-crm-field-disabled"
              formControlName="templateID"
            />
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-12 col-lg-5 disclosure-email-settings__label">
            <label class="form-check-label">Unsubscribe Group ID</label>
          </div>
          <div class="col-12 col-lg-7 disclosure-email-settings__textbox">
            <input
              type="text"
              class="form-control theme-crm-field-disabled"
              formControlName="unsubscribeGroupID"
            />
          </div>
        </div>

        <div class="row disclosure-email-settings__wysiwyg">
          <div class="col-12 col-lg-12 disclosure-email-settings__label">
            <label class="form-check-label">Body:</label>
          </div>
          <div class="col-12 col-lg-12 mt-2 disclosure-email-settings__wysiwyg">
            <app-wysiwyg
              #contentEditor
              [content]="bodyContent"
              [simpleEditor]="true"
              [extendOptions]="optionsWysiswyg"
              [shortcodes]="shortcodes"
            ></app-wysiwyg>
          </div>
        </div>
      </div>

      <div class="row pb-0 pt-2 mb-4">
        <div class="col-12 text-right">
          <app-manual-loader-mini
            [isLoading]="isLoading"
          ></app-manual-loader-mini>
          <button
            type="button"
            class="btn btn-primary my-2 mx-1 tap-bg-primary"
            (click)="save()"
            *ngIf="!isLoading"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
