import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ExistingPolicyStructureStore } from './existing-policy-structure.store';
import { ExistingPolicyStructureState } from '@models/client-review-template/existing-policy-structure/existing-policy-structure.model';

@Injectable()
export class ExistingPolicyStructureQuery extends QueryEntity<ExistingPolicyStructureState> {
	data$ = this.selectAll();

	constructor(protected store: ExistingPolicyStructureStore) {
		super(store);
	}
}
