export interface BusinessConfig {
	BusinessCode: string;
	BusinessName: string;
	Description: string;
	Website: string;
	Domain: string;
	Address1: string;
	Address2: string;
	City: string;
	Postcode: string;
	Country: string;
	FirstName: string;
	LastName: string;
	Position: string;
	EmailAddress: string;
	PhoneNumber: string;
	Services: string[];
	IsActive: string;
	Logo: string;
	PrimaryColor: string;
	SecondaryColor: string;
	TertiaryColor: string;
	H1Color: string;
	H2Color: string;
	H3Color: string;
	H4Color: string;
	H5Color: string;
	H6Color: string;
	PColor: string;
	FieldBackgroundColor: string;
	FieldTextColor: string;
	WidgetColor1: string;
	WidgetColor2: string;
	SOAHeadingColor?: string;
	CoverPhoto: string;
	LoginPhoto: string;
	Widgets: string[];

	TradingName: string;
	BusinessPhoneNumber: string;
	BusinessEmailAddress: string;
	FacebookLink: string;
	LinkedInLink: string;
	UnsubscribeGroupingID: string;
	BusinessFooterLogo: string;
	BusinessLOATFactFindCover: string;
	BusinessLOATSOAAestheticCover: string;
	BusinessMOATFactFindAestheticCover: string;
	BusinessMOATROAAestheticCover: string;
	BusinessKOATAdviceSummaryAestheticCover: string;
	LOATGenericCover: string;
	MOATGenericCover: string;
	KOATGenericCover: string;
	CARGenericCover: string;
	BusinessHeaderLogo: string;
	DisclosureDocument: string;
	DeclarationDocument: string;
	ScopeOfService: string;
	LetterOfAuthority: string;
	BusinessOverview: string;
	DocumentLink: string;
	FAP: string;
	FSPNumber: string;
	BusinessOATLogo: string;
	SyncToCompare: boolean;
	SyncToCompareAdminEmail: string;
	SyncToFocusMedia: boolean;
	SyncToFocusMediaAdminEmail: string;
	SyncToEAL: boolean;
	SyncToEALAdminEmail: string;
	SendEmailToEAL: boolean;
	OAT?: string[];

	// Feature Toggle
	AdviserRework?: boolean;
	BulkTransfer?: string[];
	SpecialFeature?: string[];
	EmailClientTemplate?: boolean;
	Conversion?: boolean;
	ReferralOption?: boolean;
	MenuRework?: boolean;
	AIQuote?: boolean;
	Elasticsearch?: boolean;
	ElasticsearchMonitoring?: boolean;
	SearchRework?: boolean;
	MOAT2Quickwins?: boolean;
	LOATQuickWinsV2_3?: boolean;
	FGUpdateV1?: boolean;
	Claims?: boolean;
	KLOAT?: boolean;
}

export function createBusinessConfig({
	BusinessCode = '',
	BusinessName = '',
	Description = '',
	Website = '',
	Domain = '',
	Address1 = '',
	Address2 = '',
	City = '',
	Postcode = '',
	Country = '',
	FirstName = '',
	LastName = '',
	Position = '',
	EmailAddress = '',
	PhoneNumber = '',
	Services = [],
	IsActive = '',
	Logo = '',
	PrimaryColor = '',
	SecondaryColor = '',
	TertiaryColor = '',
	H1Color = '',
	H2Color = '',
	H3Color = '',
	H4Color = '',
	H5Color = '',
	H6Color = '',
	PColor = '',
	FieldBackgroundColor = '',
	FieldTextColor = '',
	WidgetColor1 = '',
	WidgetColor2 = '',
	SOAHeadingColor = '',
	CoverPhoto = '',
	LoginPhoto = '',
	Widgets = [],

	TradingName = '',
	BusinessPhoneNumber = '',
	BusinessEmailAddress = '',
	FacebookLink = '',
	LinkedInLink = '',
	UnsubscribeGroupingID = '',
	BusinessFooterLogo = '',
	BusinessLOATFactFindCover = '',
	BusinessLOATSOAAestheticCover = '',
	BusinessMOATFactFindAestheticCover = '',
	BusinessMOATROAAestheticCover = '',
	BusinessKOATAdviceSummaryAestheticCover = '',
	LOATGenericCover = '',
	MOATGenericCover = '',
	KOATGenericCover = '',
	CARGenericCover = '',
	BusinessHeaderLogo = '',
	DisclosureDocument = '',
	DeclarationDocument = '',
	ScopeOfService = '',
	LetterOfAuthority = '',
	BusinessOverview = '',
	DocumentLink = '',

	FAP = '',
	FSPNumber = '',

	BusinessOATLogo = '',
	SyncToCompare = false,
	SyncToCompareAdminEmail = '',
	SyncToFocusMedia = false,
	SyncToFocusMediaAdminEmail = '',
	SyncToEAL = false,
	SyncToEALAdminEmail = '',
	SendEmailToEAL = false,
	OAT = [],

	AdviserRework = false,
	BulkTransfer = [],
	SpecialFeature = [],
	EmailClientTemplate = false,
	Conversion = false,
	ReferralOption = false,
	MenuRework = false,
	SearchRework = false,
	AIQuote = false,
	Elasticsearch = false,
	ElasticsearchMonitoring = false,
	MOAT2Quickwins = false,
	LOATQuickWinsV2_3 = false,
	FGUpdateV1 = false,
	Claims = false,
	KLOAT = false,
}: Partial<BusinessConfig>): BusinessConfig {
	return {
		BusinessCode,
		BusinessName,
		Description,
		Website,
		Domain,
		Address1,
		Address2,
		City,
		Postcode,
		Country,
		FirstName,
		LastName,
		Position,
		EmailAddress,
		PhoneNumber,
		Services,
		IsActive,
		Logo,
		PrimaryColor,
		SecondaryColor,
		TertiaryColor,
		H1Color,
		H2Color,
		H3Color,
		H4Color,
		H5Color,
		H6Color,
		PColor,
		FieldBackgroundColor,
		FieldTextColor,
		WidgetColor1,
		WidgetColor2,
		SOAHeadingColor,
		CoverPhoto,
		LoginPhoto,
		Widgets,

		TradingName,
		BusinessPhoneNumber,
		BusinessEmailAddress,
		FacebookLink,
		LinkedInLink,
		UnsubscribeGroupingID,
		BusinessFooterLogo,
		BusinessLOATFactFindCover,
		BusinessLOATSOAAestheticCover,
		BusinessMOATFactFindAestheticCover,
		BusinessMOATROAAestheticCover,
		BusinessKOATAdviceSummaryAestheticCover,
		LOATGenericCover,
		MOATGenericCover,
		KOATGenericCover,
		CARGenericCover,
		BusinessHeaderLogo,
		DisclosureDocument,
		DeclarationDocument,
		ScopeOfService,
		LetterOfAuthority,
		BusinessOverview,
		DocumentLink,
		FAP,
		FSPNumber,
		BusinessOATLogo,
		SyncToCompare,
		SyncToCompareAdminEmail,
		SyncToFocusMedia,
		SyncToFocusMediaAdminEmail,
		SyncToEAL,
		SyncToEALAdminEmail,
		SendEmailToEAL,
		OAT,

		// Feature Toggle
		AdviserRework,
		BulkTransfer,
		SpecialFeature,
		EmailClientTemplate,
		Conversion,
		ReferralOption,
		MenuRework,
		SearchRework,
		AIQuote,
		Elasticsearch,
		ElasticsearchMonitoring,
		MOAT2Quickwins,
		LOATQuickWinsV2_3,
		FGUpdateV1,
		Claims,
		KLOAT,
	};
}

export enum BusinessInternalServices {
	LoatV2 = 'LOAT',
	MoatV2 = 'MOAT',
}

export enum SecurityGroup {
	TAPAdmin = 'BAE',
	TAPUserManager = 'SM',
	BusinessOwner = 'BO',
	// previously Business Admin - Internal
	BusinessAdmin = 'BAI',
	AdviserManager = 'AM',
	Adviser = 'A',
	// previously Super Owner
	TAPSuperOwner = 'SO',
	// previously Super Admin
	TAPUserAdmin = 'SA',
	// Previously Lead Generator
	LeadGenerator = 'LG',
	// Applied to Haven business only.
	LeadGeneratorInternal = 'LGI',
	// Applied to Haven business only.
	BusinessAdminView = 'BAV',
	CallCenterAdmin = 'CCA',
}

export enum AestheticCoverCodes {
	LOATFactFindCover = 'LOATFFC',
	LOATSOAAestheticCover = 'LOATSAC',
	MOATFactFindAestheticCover = 'MOATFFAC',
	MOATROAAestheticCover = 'MOATRAC',
	KOATAdviceSummaryAestheticCover = 'KOATASAC',
}

export enum AestheticCoverType {
	BusinessLOATFactFindCover = 'BusinessLOATFactFindCover',
	BusinessLOATSOAAestheticCover = 'BusinessLOATSOAAestheticCover',
	BusinessMOATFactFindAestheticCover = 'BusinessMOATFactFindAestheticCover',
	BusinessMOATROAAestheticCover = 'BusinessMOATROAAestheticCover',
	BusinessKOATAdviceSummaryAestheticCover = 'BusinessKOATAdviceSummaryAestheticCover',
}

export enum GenericCoverCodes {
	LOATGenericCover = 'LOATG',
	MOATGenericCover = 'MOATG',
	KOATGenericCover = 'KOATG',
	CARGenericCover = 'CARG',
}

export const getGenericKey = (value: string) => {
	return Object.entries(GenericCoverCodes).reduce((a, [k, v]) => {
		return v === value ? k : a;
	}, '');
};
