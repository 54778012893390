import {
	button,
	buttonMD,
	buttonSM,
	buttonXS,
} from './defaults/toolbar-buttons';
import { fonts } from './defaults/toolbar-fonts';
import {
	allowPasteTags,
	colorsAvail,
	doNotPasteTags,
	doNotWrapTags,
} from './defaults/toolbar-html-tags';
import FroalaEditor from 'froala-editor';

export const options = {
	key: 'Ne2C1sA3I4C3B12A10B8jcyitaA5B-21qE4nF-10C-8C2H2C4D4B6B2F2C4B1C1==',
	charCounterCount: true,
	documentReady: true,
	attribution: false,
	toolbarSticky: true,
	lineBreakerTags: ['hr'],
	zIndex: 10,
	pastePlain: false,
	tabSpaces: 4,

	enter: FroalaEditor.ENTER_P,
	htmlRemoveTags: ['script'],
	htmlDoNotWrapTags: doNotWrapTags,

	// Note: Using ['.*'] on htmlAllowedTags & htmlAllowedAttrs overrides all options on
	// 			 htmlAllowedEmptyTags, pasteAllowedStyleProps, pasteDeniedAttrs, pasteDeniedTags
	// 			 This option will be deleted on the wysiwyg component if allowAllHtmlTags = true, to allow other elements like SVG
	// Ref of same issue: https://github.com/froala/wysiwyg-editor/issues/4112 (not yet resolved by froala)
	htmlAllowedTags: ['.*'],
	htmlAllowedAttrs: ['.*'],

	pasteAllowedStyleProps: allowPasteTags,
	pasteDeniedAttrs: ['dir', 'class'],
	pasteDeniedTags: doNotPasteTags,
	htmlAllowedEmptyTags: ['.custom-notes', 'var', 'svg', 'path', 'line', 'circle'],

	heightMin: window.innerHeight,

	fontFamily: fonts,
	fontFamilySelection: true,
	fontSizeSelection: true,

	paragraphFormatSelection: true,
	paragraphStyles: {
		'froala-border-box': 'Border Box',
		'froala-text-border-bottom': 'Text Border Bottom'
	},

	tableStyles: {
		dynamicDependants: 'Dynamic Dependants',
		tblNoBorder: 'No Border',
		fullWidth: 'Full Width',
		tblTopBorderOnly: 'Top Border Only',
		'hr-line-only': 'Horizontal Lines',
		'no-border-hr-lines': 'Horizontal No Border',
		'no-outer-border': 'No Outer Border',
		'no-border-left-right': 'No Border Left & Right'
	},

	tableCellStyles: {
		cellNoBorder: 'No Border',
		cellNoBackground: 'No Background',
		cellLeftIndent: 'Left Indent',
	},

	toolbarButtons: button,
	toolbarButtonsMD: buttonMD,
	toolbarButtonsSM: buttonSM,
	toolbarButtonsXS: buttonXS,

	imageDefaultAlign: 'left',
	imageDefaultDisplay: 'block',
	imageAllowedTypes: ['jpeg', 'jpg', 'png'],
	imageMaxSize: 5 * 1024 * 1024,

	angularIgnoreAttrs: [
		'style',
		'ng-reflect-froala-editor',
		'ng-reflect-froala-model',
	],
	immediateAngularModelUpdate: true,
	colorsText: colorsAvail,
	colorsStep: 6,
};
