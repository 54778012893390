import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
	PropertyPurchaseStore,
	PropertyPurchaseState,
} from './property-purchase.store';

@Injectable()
export class PropertyPurchaseQuery extends QueryEntity<PropertyPurchaseState> {
	properties$ = this.selectAll();

	constructor(protected store: PropertyPurchaseStore) {
		super(store);
	}
}
