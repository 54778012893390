import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientReferralLinkModalComponent } from './client-referral-link-modal.component';
import { ClientReferralLinkModalService } from './client-referral-link-modal.service';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
	declarations: [ClientReferralLinkModalComponent],
	exports: [ClientReferralLinkModalComponent],
	imports: [CommonModule, ModalModule],
	providers: [ClientReferralLinkModalService],
})
export class ClientReferralLinkModalModule {}
