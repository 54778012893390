<form
	[formGroup]="form"
	[class.submitted]="submitted"
	[ngClass]="isMoatV2 ? 'moat-v2' : 'moat-v1'"
	class="clearfix"
	[class.ng-invalid]="!addMode && asset?.pendingAdviserApproval"
	novalidate
>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-3">
			<span class="w-100" [class.crt-custom-dropdown]="form.enabled">
				<select
					formControlName="asset"
					id="assetsOtherAsset_{{ formId }}"
					class="form-control crt-form-control theme-crm-field-disabled"
					[class.select-text-muted]="!form?.get('asset')?.value"
				>
					<option
						value=""
						selected
						[attr.disabled]="true"
						hidden
						class="select-text-muted"
					>
						Select Asset
					</option>
					<option
						*ngFor="let d of APCRTA$ | async"
						[value]="d.value"
						class="select-text-option"
					>
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-3">
			<app-chips
				id="assetsOwner_{{ formId }}"
				textboxId="assetsOwner_{{ formId }}"
				formControlName="owner"
				class="crt-form-control-field"
				textboxClass="theme-crm-field"
				[attr.disabled]="form?.disabled"
				[isRemoveChipsPadding]="form.disabled"
				[choices]="policyOwnerChoices(form.get('owner').value)"
			></app-chips>
		</div>
		<div class="col-12 col-lg-3" [tooltip]="this.form?.get('description')?.value">
			<input
				formControlName="description"
				type="text"
				id="assetsOtherDescription_{{ formId }}"
				class="form-control crt-form-control"
				placeholder="Description"
			/>
		</div>
		<div class="col-12 col-lg-2">
			<div class="dollar-icon">
				<input
					formControlName="value"
					type="text"
					id="assetsOtherValue_{{ formId }}"
					class="form-control crt-form-control"
					placeholder="Value"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-lg-1 order-first text-right order-lg-last">
			<ng-container *ngIf="isSaving$ | async as isSaving; else buttons">
				<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
			</ng-container>
			<ng-template #buttons>
				<ng-container *ngIf="!(isEdit$ | async)">
					<ng-container
						*ngIf="
							!asset?.pendingAdviserApproval;
							else pendingAdviserApproval
						"
					>
						<button
							id="assetsEditButton_{{ formId }}"
							(click)="edit()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							[disabled]="disableUpdate"
						>
							<i class="material-icons md-16">edit</i>
						</button>
						<button
							id="assetsDeleteButton_{{ formId }}"
							(click)="deleteAsset()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							[disabled]="disableUpdate"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
					<ng-template #pendingAdviserApproval>
						<button
							id="pendingAdviserApprovalButton"
							(click)="approveConfirmation()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="fas fa-exclamation-triangle fa-16"></i>
						</button>
					</ng-template>
				</ng-container>
				<ng-container *ngIf="isEdit$ | async">
					<button
						id="assetsSaveButton_{{ form }}"
						(click)="save()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="form.invalid"
					>
						<i class="material-icons md-16 tap-text-primary">save</i>
					</button>
					<button
						id="assetsCancelButton_{{ form }}"
						(click)="cancel()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
					>
						<i class="material-icons md-16">close</i>
					</button>
				</ng-container>
			</ng-template>
		</div>
	</div>
</form>
