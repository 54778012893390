import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { IsWorkingAccumulator } from '../util/IsWorkingAccumulator';

@Injectable()
export class LoaderService extends IsWorkingAccumulator {
	get serviceName(): string {
		return 'LoaderService';
	}
	constructor() {
		super();
		this._isLoading = false;
		this._isLoading$ = new ReplaySubject<boolean>(1);

		this.isLoading$.subscribe(x => (this._isLoading = x));
		this._isLoading$.next(false);

		this.isWorking$.subscribe(x => (x ? this.start() : this.stop()));
	}
	private _isLoading: boolean;
	get isLoading(): boolean {
		return this._isLoading;
	}
	private _isLoading$: ReplaySubject<boolean>;
	get isLoading$(): Observable<boolean> {
		// return this._isLoading$.distinctUntilChanged();
		return this._isLoading$;
	}

	private start() {
		this._isLoading$.next(true);
	}
	private stop() {
		this._isLoading$.next(false);
	}
}
@Injectable()
export class ResultLoaderService extends LoaderService {
	get serviceName(): string {
		return 'ResultLoaderService';
	}
}
@Injectable()
export class ActionLoaderService extends LoaderService {
	get serviceName(): string {
		return 'ActionLoaderService';
	}
}
