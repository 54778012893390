import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { applyTransaction } from '@datorama/akita';
import { AdviceProcessSectionCodes } from '@shared/models/advice-process/advice-process.model';
import { objectUtil } from '@util/util';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CrtKiwiSaverQuery } from '../../../state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../../../state/crt-kiwisaver.service';
import { CrtKiwiSaverStore } from '../../../state/crt-kiwisaver.store';
import {
	People,
	PeopleDetails,
	PeopleDetailsState,
	PeopleState,
} from './people.model';
import { PeopleQuery } from './people.query';
import { PeopleStore } from './people.store';

@Injectable()
export class PeopleService {
	people$ = this.query.people$;
	isLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		protected store: PeopleStore,
		protected query: PeopleQuery,
		protected koatService: CrtKiwiSaverService,
		protected koatQuery: CrtKiwiSaverQuery,
		protected koatStore: CrtKiwiSaverStore
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getPersonInfo(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api
			.get<PeopleDetails>(endpoint)
			.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
	}

	get(adviceProcessId: number) {
		const sectionCode = AdviceProcessSectionCodes.People;
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<People[]>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? (x?.map(objectUtil.mapPascalCaseToCamelCase) as PeopleState[])
						: [];
					this.store.set(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	update(people: PeopleDetailsState) {
		const endpoint = `crt/${people.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(people);

		const {
			customerID,
			cRTId,
			customerType,
			relationship,
			title,
			firstName,
			middleName,
			lastName,
			knownAs,
			gender,
			dateOfBirth,
			residencyStatus,
			mobilePhone,
			homePhone,
			workPhone,
			email,
			physicalAddress,
		} = people;

		const peopleDetails = {
			customerID,
			cRTId,
			customerType,
			relationship,
			title,
			firstName,
			middleName,
			lastName,
			fullLegalName: `${firstName} ${middleName} ${lastName}`,
			knownAs,
			gender,
			dateOfBirth,
			birthDate: dateOfBirth,
			residencyStatus,
			mobilePhone,
			mobile: mobilePhone,
			homePhone,
			workPhone,
			work: workPhone,
			email,
			physicalAddress,
		};

		return this.api.put<PeopleDetailsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					// PEOPLE
					const age = moment().diff(dateOfBirth, 'years');
					const data = this.query.getValue()?.map((y) =>
						y.cRTId === cRTId
							? {
									customerId: customerID,
									age: dateOfBirth ? (age < 0 ? 0 : age) : null,
									birthDate: dateOfBirth,
									dateOfBirth,
									cRTId,
									customerType,
									name:
										firstName && lastName
											? firstName?.concat(' ', lastName)
											: null,
									relationship,
									email: people.email,
							  }
							: y
					);
					this.store.set(data);

					// PRIMARY CLIENT
					const primaryClient = this.koatQuery.getValue().primaryClient;
					if (people.customerID === primaryClient.customerID) {
						const clientData = {
							...primaryClient,
							...peopleDetails,
							age: dateOfBirth ? (age < 0 ? 0 : age) : null,
						};

						this.koatStore.setPrimaryClient(clientData);
					}

					// SECONDARY CONTACTS
					const secondaryClients = this.koatQuery.getValue().secondaryClients;
					const secondaryClientsData = secondaryClients.map((client) => {
						if (people.customerID === client.customerID) {
							return {
								...client,
								...peopleDetails,
								age: dateOfBirth ? (age < 0 ? 0 : age) : null,
							};
						}
						return client;
					});

					this.koatStore.setSecondaryClients(secondaryClientsData);

					// LINKED CONTACTS
					const linkedContacts = this.koatQuery.getValue().linkedContacts;
					const linkedContactsData = linkedContacts.map((contact) => {
						if (people.customerID === contact.relatedCustomerId) {
							return {
								...contact,
								...peopleDetails,
								age: dateOfBirth ? (age < 0 ? 0 : age) : null,
								name: `${firstName} ${lastName}`,
							};
						}
						return contact;
					});

					this.koatStore.setLinkedContacts(linkedContactsData);
				})
			)
		);
	}
}
