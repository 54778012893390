// Static Variables
const staticVariables = {
	// General
	WAGE_GROWTH: 2.7,
	CPI_AGAINST_RETURNS: 2,
	AGE_LIMIT: 65,
	VOLUNTARY_CONTRIBUTION: 0,
	// Defensive Related
	DEFENSIVE_GROSS_RETURNS: 2.39,
	DEFENSIVE_NET_RETURN: 1.5,
	DEFENSIVE_FEES: 0.9,
	// Conservative Related
	CONSERVATIVE_GROSS_RETURNS: 5,
	CONSERVATIVE_NET_RETURN: 2.5,
	CONSERVATIVE_FEES: 1.04,
	// Balanced Related
	BALANCED_GROSS_RETURNS: 7.4,
	BALANCED_NET_RETURN: 3.5,
	BALANCED_FEES: 1.33,
	// Growth Related
	GROWTH_GROSS_RETURNS: 9.58,
	GROWTH_NET_RETURN: 4.5,
	GROWTH_FEES: 1.48,
	// Aggressive Related
	AGGRESSIVE_GROSS_RETURNS: 9.86,
	AGGRESSIVE_NET_RETURN: 5.5,
	AGGRESSIVE_FEES: 1.2,
	// PIR Related
	PIR_DEFAULT: 0.105,
	PIR_MIN_SALARY: 14000,
	PIR_MAX_SALARY: 48000,
	// TAX CREDIT Related
	TAX_CREDIT_LIMIT: 1042.86,
	// Get Tax Related
	TAX_RATE: 0.105,
	TAX_RATE_SALARY_LIMIT1: 16800,
	TAX_RATE_SALARY_LIMIT2: 57600,
	TAX_RATE_SALARY_LIMIT3: 84000,
};

const getPercentage = (n) => {
	return parseFloat(n) / 100;
};

const getNetReturnAfterPIR = (gross: number, fees: number, pir: number) => {
	let num;
	num = gross * (1 - pir) * (1 - fees);
	return parseFloat(num);
};

const getAgeDiff = (
	ageLimit: number,
	birthMonth: string,
	birthYear: string
) => {
	return ageLimit - getBirthDate(birthYear, birthMonth);
};

const getBirthDate = (year: string, month: string) => {
	let dob;
	let today;

	dob = year + '-' + month + '-01';
	dob = new Date(dob);
	today = new Date();

	return Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
};

const getTax = (salary: number, employerContribution: number) => {
	let taxRate;
	taxRate = staticVariables.TAX_RATE;
	if (salary + employerContribution > staticVariables.TAX_RATE_SALARY_LIMIT1) {
		taxRate = 0.175;
	}
	if (salary + employerContribution > staticVariables.TAX_RATE_SALARY_LIMIT2) {
		taxRate = 0.3;
	}
	if (salary + employerContribution > staticVariables.TAX_RATE_SALARY_LIMIT3) {
		taxRate = 0.33;
	}
	return taxRate * employerContribution;
};

const getTaxCredit = (
	employeeContribution: number,
	voluntaryContribution: number
) => {
	let taxCredit;
	taxCredit = (employeeContribution + voluntaryContribution) / 2;
	if (
		employeeContribution + voluntaryContribution >
		staticVariables.TAX_CREDIT_LIMIT
	) {
		taxCredit = 521.43; // Default tax credit
	}
	return taxCredit;
};

const getContribution = (
	employeeContribution: number,
	A10: number,
	taxCredit: number,
	voluntaryContributions: number
) => {
	return employeeContribution + A10 + taxCredit + voluntaryContributions;
};

const getPIR = (salary: number) => {
	let pir = staticVariables.PIR_DEFAULT;
	if (salary > staticVariables.PIR_MIN_SALARY) {
		pir = 0.175;
	}
	if (salary > staticVariables.PIR_MAX_SALARY) {
		pir = 0.28;
	}
	return pir;
};

const getTotalResultNoInflation = (
	balance: number,
	netReturn: number,
	contribution: number,
	wageGrowth: number,
	ageDiff: number
) => {
	/*Variable init*/
	let set1Part1;
	let set1Part2;
	let set1Part3;
	let set1Part4;
	let set1;
	let set2;

	set1Part1 = Math.pow(1 + wageGrowth, ageDiff);
	set1Part2 = Math.pow(1 + netReturn, ageDiff);
	set1Part3 = wageGrowth - netReturn;
	set1Part4 = (set1Part1 - set1Part2) / set1Part3;

	set1 = set1Part4 * contribution;
	set2 = balance * Math.pow(1 + netReturn, ageDiff);

	return Math.round((set1 + set2) / 1000) * 1000; // Round to nearest thousand
};

const getTotalResultWithInflation = (
	nonInflationValue: number,
	cpiAgainstReturns: number,
	ageDiff: number
) => {
	let set3;
	set3 = nonInflationValue / Math.pow(1 + cpiAgainstReturns, ageDiff);
	return Math.round(set3 / 1000) * 1000; // Round to nearest thousand
};

export const havenCalc = {
	var: staticVariables,
	getPercentage,
	getNetReturnAfterPIR,
	getAgeDiff,
	getBirthDate,
	getTax,
	getTaxCredit,
	getContribution,
	getPIR,
	getTotalResultNoInflation,
	getTotalResultWithInflation,
};

export interface InvestmentReturnData {
	defensive: number;
	conservative: number;
	balanced: number;
	growth: number;
	aggressive: number;
}
