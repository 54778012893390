import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { InfoModalComponent } from '@shared/modal/info-modal/info-modal.component';
import { UserDocumentModalComponent } from '@shared/modal/user-document-modal/user-document-modal.component';
import {
	BlStaffSettingTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { SimpleDataTableModel } from '@shared/models/_general/simple-data-table.model';
import {
	BlDocumentModel,
	DocumentTypes,
} from '@shared/models/documents/document.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import {
	displayedColumns,
	tableColumns,
} from './user-specific-document.table-config';

@Component({
	selector: 'app-user-specific-documents',
	templateUrl: './user-specific-documents.component.html',
	styleUrls: ['./user-specific-documents.component.scss'],
})
export class UserSpecificDocumentsComponent implements OnInit, OnChanges {
	@Input() staff: BlStaffViewmodel;
	@Input() documents: BlStaffSettingsModel[];
	@Input() isTapLevel: boolean;
	@Input() userSecurityGroup: string;
	@Input() removeStaffDocumentFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() upsertStaffDocumentFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() downloadDocumentFn$: (ac: {
		documentId: number;
		fileName: string;
	}) => Observable<any> = (ac) => of(ac);
	@Input() getUserDocumentsFn$: (data: number) => Observable<any> = (data) =>
		of(data);
	@Input() deleteUserDocumentsFn$: (data: number) => Observable<any> = (data) =>
		of(data);

	isLoading: boolean;

	displayedColumns: string[] = [];
	tableColumns: SimpleDataTableModel[] = [];
	dataSource: any[];

	constructor(
		private modalService: BsModalService,
		private routeService: RouteService,
		private router: Router
	) {
		this.displayedColumns = displayedColumns;
	}

	get CanDeleteDocument() {
		return this.isTapLevel || ['BO']?.includes(this.userSecurityGroup);
	}

	ngOnInit(): void {
		this.fetchDocuments();
	}

	fetchDocuments() {
		this.isLoading = true;
		this.getUserDocumentsFn$(this.staff.StaffID)
			.pipe(
				tap((data) => {
					this.setTableData(data);
					this.isLoading = false;
				}),
				take(1)
			)
			.subscribe();
	}

	setTableData(data: BlStaffSettingsModel[]) {
		this.dataSource = data;
		this.tableColumns = tableColumns.map((x) => ({
			...x,
			actionCallback:
				x?.columnId === 'DeleteAction' ? this.removeDocument : null,
		}));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.documents) {
			this.fetchDocuments();
		}
	}

	addDocument() {
		const data = {
			DocumentName: '',
			Documents: [],
		};
		const initialState = {
			title: 'Add Document',
			data,
			staffId: this.staff?.StaffID,
			type: BlStaffSettingTypes.UserSpecificDocuments,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Upload',
			isSingleUpload: true,
			showDocumentName: true,
			documentNameText: 'Document Name',
			uploadOnly: true,
			upsertStaffDocumentFn$: this.upsertStaffDocumentFn$,
			getUserDocumentsFn$: this.getUserDocumentsFn$,
		};

		this.modalService.show(UserDocumentModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-md user-documents-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	}

	removeDocument = (event) => {
		this.isLoading = true;
		const id = event.Id;
		const popupError = (e) => this.popup(e);

		return this.deleteUserDocumentsFn$(id)
			.pipe(take(1))
			.subscribe(
				() => {
					this.isLoading = false;
				},
				(err) => {
					this.isLoading = false;
					popupError(err);
				}
			);
	}

	popup = (err) => {
		if (!err) {
			return;
		}
		const errors = err.map((error) => {
			return ` - ${error.MetaKey} on ${error.Value}  <br />`;
		});
		const message = `This document is an <br /> ${errors
			?.toString()
			?.replace(/,/g, ' ')} Kindly unlink ${
			err && err.length > 1 ? 'these' : 'this'
		} before deleting.`;

		const initState = {
			header: 'Delete Document',
			message: message,
			confirmation: true,
			hasOkay: true,
			appearance: 'v3',
		};

		this.modalService.show(InfoModalComponent, {
			class: 'modal-dialog-centered modal-dialog info-modal-v3',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState: initState,
		});
	}

	viewDocument = (data) => {
		return of({
			documentId: data?.Id,
			name: data?.FileName || '',
		})
			.pipe(
				mergeMap((res) => {
					const ext = res?.name?.split('.')?.reverse()?.[0];
					if (!!ext && !!res?.documentId) {
						if (ext?.toLowerCase() === 'pdf') {
							return of(res).pipe(
								tap(() => {
									const pdfUrl = this.router.serializeUrl(
										this.router.createUrlTree(this.routeService.viewPdf(res))
									);
									window.open(pdfUrl, '_blank');
								}),
								take(1)
							);
						} else {
							return this.downloadDocumentFn$({
								documentId: res?.documentId,
								fileName: res?.name,
							});
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}
}
