import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TlReportComponent } from './tl-report.component';
import { TlReportRoutingModule } from './tl-report.routing.module';
import { SharedModule } from '../../shared/shared.module';
import { BusinessPageModule } from '../business-page/business-page.module';
import { TlReportService } from './tl-report.service';

@NgModule({
    imports: [
        CommonModule,
        TlReportRoutingModule,
        SharedModule,
        BusinessPageModule,
    ],
    declarations: [TlReportComponent],
    providers: [
        TlReportService
    ]
})
export class TlReportModule { }
