import { TlMobileSettingsResolver } from './tl-mobile-settings.resolver';
import { TlMobileSettingsService } from './state/tl-mobile-settings.service';
import { TlMobileSettingsQuery } from './state/tl-mobile-settings.query';
import { TlMobileSettingsStore } from './state/tl-mobile-settings.store';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TlMobileSettingsComponent } from './tl-mobile-settings.component';

const routes: Routes = [{
  path: '',
  component: TlMobileSettingsComponent,
	resolve:{data:TlMobileSettingsResolver}
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
	providers:[
		TlMobileSettingsStore,
		TlMobileSettingsQuery,
		TlMobileSettingsService,
		TlMobileSettingsResolver
	]
})
export class TlMobileSettingsRoutingModule { }
