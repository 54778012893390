<form
	[formGroup]="form"
	class="clearfix"
	[class.ng-invalid]="!addMode && kiwiSaver?.pendingAdviserApproval"
	novalidate
>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-4">
			<ng-container *ngIf="addMode; else displayMode">
				<span class="w-100" [class.crt-custom-dropdown]="form.enabled">
					<select
						[tooltip]="ownerTooltipDisplay?.length > 0 ? ownerTooltipDisplay : ''"
						data-toggle="tooltip"
						container="body"
						formControlName="kiwiSaverfundOwner"
						id="kiwisaveFormOwner_{{ formId }}"
						placeholder="Owner"
						class="form-control crt-form-control theme-crm-field-disabled"
						(change)="onSelectKiwiSaver($event.target.value)"
					>
						<option *ngFor="let d of crmKiwiSaversChoinces" [value]="d.value">
							{{ d.display }}
						</option>
						<option value="new">+ Add New KiwiSaver</option>
					</select>
				</span>
			</ng-container>
			<ng-template #displayMode>
				<app-chips
					[tooltip]="ownerTooltipDisplay?.length > 0 ? ownerTooltipDisplay : ''"
					[textboxId]="'kiwisaveFormOwner' + formId"
					[attr.disabled]="form.get('owner')?.disabled"
					class="crt-form-control-field"
					[formControl]="form.get('owner')"
					textboxClass="theme-crm-field"
					[isRemoveChipsPadding]="form.get('owner')?.disabled"
					[choices]="policyOwnerChoices(form.get('owner').value)"
				></app-chips>
			</ng-template>
		</div>
		<div class="col-12 col-lg-3">
			<ng-container *ngIf="!isMoatV2 && !addMode">
				<app-chips
					class="crt-form-control-field fund-enabled"
					[formControl]="form.get('fundType')"
					textboxClass="theme-crm-field"
					[textboxId]="'fundType' + formId"
					textboxPlaceholder="Fund Type"
					[choices]="KFT$ | async"
					[attr.disabled]="true"
					[isRemoveChipsPadding]="true"
				></app-chips>
			</ng-container>
			<ng-container *ngIf="isMoatV2">
				<app-chips *ngIf="!addMode; else addModeDisplay"
					class="crt-form-control-field fund-disabled"
					[formControl]="form.get('fundType')"
					textboxClass="theme-crm-field"
					[textboxId]="'fundType' + formId"
					textboxPlaceholder="Fund Type"
					[choices]="KFT$ | async"
					[attr.disabled]="true"
					[disabled]="true"
					[isRemoveChipsPadding]="true"
					[showRemoveIcon]="false"
				></app-chips>
				<ng-template #addModeDisplay>
					<div [tooltip]="form.get('fundType') ? form.get('fundType').value.join(', ') : ''">
						<input class="fund-disabled" type="text"
							[value]="form.get('fundType') ? form.get('fundType').value.join(', '): ''"
							disabled
						>
					</div>
				</ng-template>
				
			</ng-container>
		</div>
		<div class="col-12 col-lg-2">
			<ng-container>
				<span class="w-100" [class.crt-custom-dropdown]="form.enabled" *ngIf="!isMoatV2 && !addMode">
					<select
						formControlName="provider"
						id="kiwisaverProvider_{{ formId }}"
						placeholder="Provider"
						class="form-control crt-form-control theme-crm-field-disabled"
					>
						<option value="" selected [attr.disabled]="true" hidden></option>
						<option *ngFor="let d of KP$ | async" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
				<span class="w-100" [class.crt-custom-dropdown]="false" *ngIf="isMoatV2">
					<select
						formControlName="provider"
						id="kiwisaverProvider_{{ formId }}"
						placeholder="Provider"
						class="form-control crt-form-control theme-crm-field-disabled"
						disabled
					>
						<option value="" selected [attr.disabled]="true" hidden></option>
						<option *ngFor="let d of KP$ | async" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
			</ng-container>
		</div>
		<div class="col-12 col-lg-2">
			<ng-container *ngIf="!isMoatV2 && !addMode">
				<div class="dollar-icon">
					<input
						formControlName="currentBalance"
						type="text"
						id="kiwisaverCurrentBalance_{{ formId }}"
						class="form-control crt-form-control"
						placeholder="Current Balance"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</ng-container>
			<ng-container *ngIf="isMoatV2">
				<div class="dollar-icon">
					<input
						formControlName="currentBalance"
						type="text"
						id="kiwisaverCurrentBalance_{{ formId }}"
						class="form-control crt-form-control"
						placeholder="Current Balance"
						currencyMask
						appCurrencyMaskExt
						disabled
					/>
					<i>$</i>
				</div>
			</ng-container>
			
		</div>
		<div class="col-lg-1 order-first text-right order-lg-last">
			<ng-container *ngIf="isSaving$ | async as isSaving; else buttons">
				<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
			</ng-container>
			<ng-template #buttons>
				<ng-container *ngIf="!(isEdit$ | async)">
					<ng-container
						*ngIf="
							!kiwiSaver?.pendingAdviserApproval;
							else pendingAdviserApproval
						"
					>
						<button
							id="kiwisaverEditKiwiSaverButton_{{ formId }}"
							(click)="editKiwiSaver()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">edit</i>
						</button>
						<button
							id="kiwisaverDeleteKiwiSaverButton_{{ formId }}"
							(click)="deleteKiwiSaver()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
					<ng-template #pendingAdviserApproval>
						<button
							id="pendingAdviserApprovalButton"
							(click)="approveConfirmation()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="fas fa-exclamation-triangle fa-16"></i>
						</button>
					</ng-template>
				</ng-container>
				<ng-container *ngIf="isEdit$ | async">
					<button
						id="kiwisaverSaveButton_{{ formId }}"
						(click)="save()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="!form.get('kiwiSaverfundOwner').value"
					>
						<i class="material-icons md-16 tap-text-primary">save</i>
					</button>
					<button
						id="kiwisaverCancelButton_{{ formId }}"
						(click)="cancel()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
					>
						<i class="material-icons md-16">close</i>
					</button>
				</ng-container>
			</ng-template>
		</div>
	</div>
</form>
