import { TableColumn } from '@swimlane/ngx-datatable';
import produce from 'immer';
import * as R from 'ramda';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { Row } from '../state/client-search.model';
import { ClientSearch } from '../client-search-request.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { datatableUtil } from 'src/app/util/datatable.util';

/**
 * possible metakeys
 */
export type Metakey =
  'Name' |
  'Primary Contact' |
  'Additional Contacts' |
  'Adviser' |

  'Email' |
  'Industry' |
  'Website' |
  'Mobile' |
  'Physical Address' |

  'LR Note' |
  'M Note' |
  'FG Note' |
  'K Note' |
  'I Note' |

  'Client Next Activity' |
  'User Next Activity' |

  'Last Note' |
  'Lead Type' |
  'Lead Origin' |
  'Create Date' |
  'Client Since' |
  'Work' |
  'Last Review' |
	'Linked Contacts' |

  'LR Next Review' |
  'Investment KS Next Review' |
  'Mortgage Next Review' |
  'FG Next Review' |

  'LR Adviser' |
  'GI Adviser' |
  'Mortgage Adviser' |
  'FG Adviser' | 
  'KS Adviser' | 
  'Investment Adviser';
  ;

/** possible control types. Move this somewhere more appropriate. */
export type controlType = 'display' | 'long-display' | 'textbox'
  | 'dropdown' | 'note' | 'date' | 'address' | 'activity' | 'display-date' | 'industry';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
type EnhancedTableColumn = (
  TableColumn &
  {
    /** Metakey of the column. Used for indexing ui state and requesting changes */
    metakey: Metakey,
    /** Property key in rows. */
    prop: keyof Row,
    /** Replaces `ascFn` and `descFn`. Provides sortable values. */
    sortValueGetter: ((field: FieldMetadata<any>, choices?: ViewDisplayValue[]) => any),
    /** Dropdown choices used for fields in this column.
     * To be filled up in Query file.
     */
    choices?: ViewDisplayValue[],
    /** Dropdown choices used for fields in this column.
     * To be filled up in Query file.
     */
    choicesAsObject?: { [key: string]: string },
    /** Type of control.
     * Determines which datatable field control to use.
     * Carefully check that the type used is same here and in the template.
     */
    controlType: controlType;
    /** indicator if column is required */
    isRequired?: boolean;
    columnId?: string;
	  fieldId?: string;
  });

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (choices: ViewDisplayValue[], field: FieldMetadata<any>) => {
  if (!field.value) {
    return '';
  }

  const choiceItem = choices?.find(x => x.value === field.value);
  return R.propOr<string, ViewDisplayValue, string>('', 'display' as keyof FieldMetadata<any>, choiceItem);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (choices: ViewDisplayValue[], field: FieldMetadata<any>) =>
  field.value ? choices?.findIndex(x => x.value === field.value) : null;
/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
  const b = R.defaultTo('', date)?.split(/\D/);
  return b?.reverse()?.join('/');
};
/** Get value to be used for sorting.
 * replace YES with 1 and NO with 2 to sort
 */
const getServiceSortValue = (value: string): string => {
  const actualValue = R.defaultTo('NO', value);
  if (actualValue?.indexOf('YES') === 0) {
    return actualValue?.replace('YES', '1');
  } else {
    return actualValue?.replace('NO', '2');
  }
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
  const splicedDate = R.defaultTo('', note)?.slice(0, 10);
  return reformatDateString(splicedDate);
};

export const columns: EnhancedTableColumn[] = [
  {
    metakey: 'Name',
    prop: 'Name',
    name: 'Name',
    width: 200,
    headerClass: 'secondary-background',
    cellClass: 'font-weight-bold fixed-column',
    sortValueGetter: (f, c) => f.value,
    controlType: 'display',
    columnId: datatableUtil.formatColumnId('Name'),
		fieldId: datatableUtil.formatFieldId('Name')
  },
  {
    metakey: 'Primary Contact',
    prop: 'PrimaryContact',
    name: 'Primary Contact',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'display',
    columnId: datatableUtil.formatColumnId('Primary Contact'),
		fieldId: datatableUtil.formatFieldId('Primary Contact')
  },
  {
    metakey: 'Additional Contacts',
    prop: 'AdditionalContacts',
    name: 'Additional Contacts',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('Additional Contacts'),
		fieldId: datatableUtil.formatFieldId('Additional Contacts')
  },
  { // TODO: remove this when confirmed
    metakey: 'Adviser',
    prop: 'Adviser',
    name: 'Adviser',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    isRequired: true,
    columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser'),
  },
  {
    metakey: 'Email',
    prop: 'Email',
    name: 'Email',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'textbox',
    columnId: datatableUtil.formatColumnId('Email'),
		fieldId: datatableUtil.formatFieldId('Email')
  },
  {
    metakey: 'Industry',
    prop: 'Industry',
    name: 'Industry',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'industry',
    columnId: datatableUtil.formatColumnId('Industry'),
		fieldId: datatableUtil.formatFieldId('Industry')
  },
  {
    metakey: 'Website',
    prop: 'Website',
    name: 'Website',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'textbox',
    columnId: datatableUtil.formatColumnId('Website'),
		fieldId: datatableUtil.formatFieldId('Website')
  },
  {
    metakey: 'Mobile',
    prop: 'Mobile',
    name: 'Mobile',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'textbox',
    columnId: datatableUtil.formatColumnId('Mobile'),
		fieldId: datatableUtil.formatFieldId('Mobile')
  },
  {
    metakey: 'Physical Address',
    prop: 'PhysicalAddress',
    name: 'Physical Address',
    width: 300,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'address',
    columnId: datatableUtil.formatColumnId('Physical Address'),
		fieldId: datatableUtil.formatFieldId('Physical Address')
  },

  {
    metakey: 'LR Note',
    prop: 'LRNote',
    name: 'L&R',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getServiceSortValue(f.value),
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('LR Note'),
		fieldId: datatableUtil.formatFieldId('LR Note')
  },
  {
    metakey: 'M Note',
    prop: 'MNote',
    name: 'Mortgage',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getServiceSortValue(f.value),
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('M Note'),
		fieldId: datatableUtil.formatFieldId('M Note')
  },
  {
    metakey: 'FG Note',
    prop: 'FGNote',
    name: 'F&G',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getServiceSortValue(f.value),
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('FG Note'),
		fieldId: datatableUtil.formatFieldId('FG Note')
  },
  {
    metakey: 'K Note',
    prop: 'KNote',
    name: 'KiwiSaver',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getServiceSortValue(f.value),
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('K Note'),
		fieldId: datatableUtil.formatFieldId('K Note')
  },
  {
    metakey: 'I Note',
    prop: 'INote',
    name: 'Investment',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getServiceSortValue(f.value),
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('K Note'),
		fieldId: datatableUtil.formatFieldId('K Note')
  },
  {
    metakey: 'Client Next Activity',
    prop: 'ClientNextActivity',
    name: 'Client Next Activity',
    width: 200,
    headerClass: 'secondary-background',
    cellClass: 'activity',
    sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
    controlType: 'activity',
    columnId: datatableUtil.formatColumnId('Client Next Activity'),
		fieldId: datatableUtil.formatFieldId('Client Next Activity')
  },
  {
    metakey: 'User Next Activity',
    prop: 'UserNextActivity',
    name: 'Your Next Activity',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
    controlType: 'activity',
    cellClass: 'activity',
    columnId: datatableUtil.formatColumnId('User Next Activity'),
		fieldId: datatableUtil.formatFieldId('User Next Activity')
  },
  {
    metakey: 'Last Note',
    prop: 'LastNote',
    name: 'Last Note',
    width: 300,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
    controlType: 'note',
    columnId: datatableUtil.formatColumnId('Last Note'),
		fieldId: datatableUtil.formatFieldId('Last Note')
  },
  {
    metakey: 'Lead Type',
    prop: 'LeadType',
    name: 'Lead Type',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    columnId: datatableUtil.formatColumnId('Lead Type'),
		fieldId: datatableUtil.formatFieldId('Lead Type')
  },
  {
    metakey: 'Lead Origin',
    prop: 'LeadOrigin',
    name: 'Lead Origin',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
    controlType: 'dropdown',
    columnId: datatableUtil.formatColumnId('Lead Origin'),
		fieldId: datatableUtil.formatFieldId('Lead Origin')
  },
  {
    metakey: 'Create Date',
    prop: 'CreateDate',
    name: 'Created Date',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'display-date',
    columnId: datatableUtil.formatColumnId('Create Date'),
		fieldId: datatableUtil.formatFieldId('Create Date')
  },
  {
    metakey: 'Client Since',
    prop: 'ClientSince',
    name: 'Client Since',
    width: 200,
    sortValueGetter: (f, c) => f.value,
    controlType: 'display-date',
    columnId: datatableUtil.formatColumnId('Client Since'),
		fieldId: datatableUtil.formatFieldId('Client Since')
  },
  {
    metakey: 'Work',
    prop: 'Work',
    name: 'Work',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'textbox',
    columnId: datatableUtil.formatColumnId('Work'),
		fieldId: datatableUtil.formatFieldId('Work')
  },
  {
    metakey: 'Last Review',
    prop: 'LastReview',
    name: 'Last Review Date',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'date',
    columnId: datatableUtil.formatColumnId('Last Review'),
		fieldId: datatableUtil.formatFieldId('Last Review')
  },
  {
    metakey: 'Linked Contacts',
    prop: 'LinkedContacts',
    name: 'Linked Contacts',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'long-display',
    columnId: datatableUtil.formatColumnId('Linked Contacts'),
		fieldId: datatableUtil.formatFieldId('Linked Contacts')
  },
  {
    metakey: 'LR Next Review',
    prop: 'LRNextReview',
    name: 'Next Review - LR',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'date',
    columnId: datatableUtil.formatColumnId('LR Next Review'),
		fieldId: datatableUtil.formatFieldId('LR Next Review')
  },
  {
    metakey: 'Investment KS Next Review',
    prop: 'InvestmentKSNextReview',
    name: 'Next Review - Investment',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'date',
    columnId: datatableUtil.formatColumnId('Investment KS Next Review'),
		fieldId: datatableUtil.formatFieldId('Investment KS Next Review')
  },
  {
    metakey: 'Mortgage Next Review',
    prop: 'MortgageNextReview',
    name: 'Next Review - Mortgage',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'date',
    columnId: datatableUtil.formatColumnId('Mortgage Next Review'),
		fieldId: datatableUtil.formatFieldId('Mortgage Next Review')
  },
  {
    metakey: 'FG Next Review',
    prop: 'FGNextReview',
    name: 'Next Review - F&G',
    width: 200,
    headerClass: 'secondary-background',
    sortValueGetter: (f, c) => f.value,
    controlType: 'date',
    columnId: datatableUtil.formatColumnId('FG Next Review'),
		fieldId: datatableUtil.formatFieldId('FG Next Review')
  },
];

export const futureWorkColumns: EnhancedTableColumn[] = [
{
  metakey: 'LR Adviser',
  prop: 'LRAdviser',
  name: 'L&R Adviser',
  width: 200,
  headerClass: 'secondary-background',
  sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
  controlType: 'dropdown',
  isRequired: true,
  columnId: datatableUtil.formatColumnId('LRAdviser'),
  fieldId: datatableUtil.formatFieldId('LRAdviser')
}, 
{
  metakey: 'GI Adviser',
  prop: 'GroupInsuranceAdviser',
  name: 'Group Adviser',
  width: 200,
  headerClass: 'secondary-background',
  sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
  controlType: 'dropdown',
  isRequired: true,
  columnId: datatableUtil.formatColumnId('GIAdviser'),
  fieldId: datatableUtil.formatFieldId('GIAdviser')
},
{
  metakey: 'Mortgage Adviser',
  prop: 'MortgageAdviser',
  name: 'Mortgage Adviser',
  width: 200,
  headerClass: 'secondary-background',
  sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
  controlType: 'dropdown',
  isRequired: true,
  columnId: datatableUtil.formatColumnId('MortgageAdviser'),
  fieldId: datatableUtil.formatFieldId('MortgageAdviser')
},
{
  metakey: 'FG Adviser',
  prop: 'FGAdviser',
  name: 'F&G Adviser',
  width: 200,
  headerClass: 'secondary-background',
  sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
  controlType: 'dropdown',
  isRequired: true,
  columnId: datatableUtil.formatColumnId('FGAdviser'),
  fieldId: datatableUtil.formatFieldId('FGAdviser')
},
{
  metakey: 'KS Adviser',
  prop: 'KiwiSaverAdviser',
  name: 'KiwiSaver Adviser',
  width: 200,
  headerClass: 'secondary-background',
  sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
  controlType: 'dropdown',
  isRequired: true,
  columnId: datatableUtil.formatColumnId('KSAdviser'),
  fieldId: datatableUtil.formatFieldId('KSAdviser')
},
{
  metakey: 'Investment Adviser',
  prop: 'InvestmentAdviser',
  name: 'Investment Adviser',
  width: 200,
  headerClass: 'secondary-background',
  sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
  controlType: 'dropdown',
  isRequired: true,
  columnId: datatableUtil.formatColumnId('InvestmentAdviser'),
  fieldId: datatableUtil.formatFieldId('InvestmentAdviser')
},
];

/**
 * column configuration for link
 */
export const linkColumn: (TableColumn & { prop: 'link' }) = {
  prop: 'link',
  name: '',
  width: 18
};
/**
 * template for Row
 */
export const templateRow: Row = {
  CustomerId: null,
  ClientNextActivityId: null,
  UserNextActivityId: null,
  IsCompany: null,

  Name: {
    metakey: 'Name',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Name').prop,
    required: false,
    editable: false,
  },
  PrimaryContact: {
    metakey: 'Primary Contact',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Primary Contact').prop,
    required: false,
    editable: false,
  },
  AdditionalContacts: {
    metakey: 'Additional Contacts',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Additional Contacts').prop,
    required: false,
    editable: false,
    hasToolTip: true
  },
  Adviser: {
    metakey: 'Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Adviser').prop,
    required: true,
    editable: true,
    restrict: ['FEC']
  },
  LRAdviser: {
    metakey: 'LR Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'LR Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEC']
  },
  GroupInsuranceAdviser: {
    metakey: 'GI Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'GI Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEC']
  },
  MortgageAdviser: {
    metakey: 'Mortgage Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Mortgage Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEC']
  },
  FGAdviser: {
    metakey: 'FG Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'FG Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEC']
  },
  KiwiSaverAdviser: {
    metakey: 'KS Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'KS Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEC']
  },
  InvestmentAdviser: {
    metakey: 'Investment Adviser',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Investment Adviser')?.prop,
    required: true,
    editable: false,
    restrict: ['FEC']
  },
  Email: {
    metakey: 'Email',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Email').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  Industry: {
    metakey: 'Industry',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Industry').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  Website: {
    metakey: 'Website',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Website').prop,
    required: false,
    editable: false,
    restrict: ['FEC']
  },
  Mobile: {
    metakey: 'Mobile',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Mobile').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  PhysicalAddress: {
    metakey: 'Physical Address',
    value: null,
    choices: [],
    controlType: 'address',
    id: null,
    key: columns?.find(x => x.metakey === 'Physical Address').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },

  LRNote: {
    metakey: 'LR Note',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'LR Note').prop,
    required: false,
    editable: false
  },
  MNote: {
    metakey: 'M Note',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'M Note').prop,
    required: false,
    editable: false
  },
  FGNote: {
    metakey: 'FG Note',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'FG Note').prop,
    required: false,
    editable: false
  },
  KNote: {
    metakey: 'K Note',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'K Note').prop,
    required: false,
    editable: false
  },
  INote: {
    metakey: 'I Note',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'I Note').prop,
    required: false,
    editable: false
  },
  ClientNextActivity: {
    metakey: 'Client Next Activity',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Client Next Activity').prop,
    required: false,
    editable: false,
    hasToolTip: true,
    restrict: ['FECA']
  },
  UserNextActivity: {
    metakey: 'User Next Activity',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'User Next Activity').prop,
    required: false,
    editable: false,
    hasToolTip: true,
    restrict: ['FECA']
  },
  LastNote: {
    metakey: 'Last Note',
    value: null,
    choices: [],
    controlType: 'textarea',
    id: null,
    key: columns?.find(x => x.metakey === 'Last Note').prop,
    required: false,
    editable: true,
    hasToolTip: true,
    restrict: ['FAN']
  },
  LeadType: {
    metakey: 'Lead Type',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Lead Type').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  LeadOrigin: {
    metakey: 'Lead Origin',
    value: null,
    choices: [],
    controlType: 'dropdown',
    id: null,
    key: columns?.find(x => x.metakey === 'Lead Origin').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  CreateDate: {
    metakey: 'Create Date',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Create Date').prop,
    required: false,
    editable: false,
  },
  ClientSince: {
    metakey: 'Client Since',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Client Since').prop,
    required: false,
    editable: false,
  },
  Work: {
    metakey: 'Work',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Work').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  LastReview: {
    metakey: 'Last Review',
    value: null,
    choices: [],
    controlType: 'date',
    id: null,
    key: columns.find(x => x.metakey === 'Last Review').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  LinkedContacts: {
    metakey: 'Linked Contacts',
    value: null,
    choices: [],
    controlType: 'textbox',
    id: null,
    key: columns?.find(x => x.metakey === 'Linked Contacts').prop,
    required: false,
    editable: false,
    hasToolTip: true
  },
  link: null,
  LRNextReview: {
    metakey: 'LR Next Review',
    value: null,
    choices: [],
    controlType: 'date',
    id: null,
    key: columns?.find(x => x.metakey === 'LR Next Review').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  InvestmentKSNextReview: {
    metakey: 'Investment Next Review',
    value: null,
    choices: [],
    controlType: 'date',
    id: null,
    key: columns?.find(x => x.metakey === 'Investment KS Next Review').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  MortgageNextReview: {
    metakey: 'Mortgage Next Review',
    value: null,
    choices: [],
    controlType: 'date',
    id: null,
    key: columns?.find(x => x.metakey === 'Mortgage Next Review').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
  FGNextReview: {
    metakey: 'Mortgage Next Review',
    value: null,
    choices: [],
    controlType: 'date',
    id: null,
    key: columns?.find(x => x.metakey === 'FG Next Review').prop,
    required: false,
    editable: true,
    restrict: ['FEC']
  },
};

export const createRowFromPrototype = R.curry((template: Row, row: ClientSearch) => produce<Row>(template, draft => {
  draft.CustomerId = row.CustomerId;
  draft.ClientNextActivityId = row.ClientNextActivityId;
  draft.UserNextActivityId = row.UserNextActivityId;
  draft.IsCompany = row.IsCompany;

  draft.Name.metakey = row.Name.MetaKey;
  draft.Name.value = row.Name.Value;

  draft.PrimaryContact.metakey = row.PrimaryContact.MetaKey;
  draft.PrimaryContact.value = row.PrimaryContact.Value;

  draft.AdditionalContacts.metakey = row.AdditionalContacts.MetaKey;
  draft.AdditionalContacts.value = row.AdditionalContacts.Value;

  draft.Adviser.metakey = row?.Adviser.MetaKey;
  draft.Adviser.value = row?.Adviser.Value;

  // FUTUREWORK
  draft.LRAdviser.metakey = row.LRAdviser?.MetaKey ||  'LR Adviser';
  draft.LRAdviser.value = row.LRAdviser?.Value;

  draft.GroupInsuranceAdviser.metakey = row.GroupInsuranceAdviser?.MetaKey || 'GI Adviser';
  draft.GroupInsuranceAdviser.value = row.GroupInsuranceAdviser?.Value;

  draft.MortgageAdviser.metakey = row.MortgageAdviser?.MetaKey || 'Mortgage Adviser';
  draft.MortgageAdviser.value = row.MortgageAdviser?.Value;

  draft.FGAdviser.metakey = row.FGAdviser?.MetaKey || 'FG Adviser';
  draft.FGAdviser.value = row.FGAdviser?.Value;

  draft.KiwiSaverAdviser.metakey = row.KiwiSaverAdviser?.MetaKey || 'KS Adviser';
  draft.KiwiSaverAdviser.value = row.KiwiSaverAdviser?.Value;

  draft.InvestmentAdviser.metakey = row.InvestmentAdviser?.MetaKey || 'Investment Adviser';
  draft.InvestmentAdviser.value = row.InvestmentAdviser?.Value;

  // FUTUREWORK END

  draft.CreateDate.metakey = row.CreateDate.MetaKey;
  draft.CreateDate.value = row.CreateDate.Value;

  draft.Email.metakey = row.Email.MetaKey;
  draft.Email.value = row.Email.Value;

  draft.Industry.metakey = row.Industry.MetaKey;
  draft.Industry.value = row.Industry.Value;

  draft.Website.metakey = row.Website.MetaKey;
  draft.Website.value = row.Website.Value;
  draft.Website.editable = row.IsCompany;

  draft.LastNote.metakey = row.LastNote.MetaKey;
  draft.LastNote.value = row.LastNote.Value;

  draft.LRNote.metakey = row.LRNote.MetaKey;
  draft.LRNote.value = row.LRNote.Value;

  draft.MNote.metakey = row.MNote.MetaKey;
  draft.MNote.value = row.MNote.Value;

  draft.FGNote.metakey = row.FGNote.MetaKey;
  draft.FGNote.value = row.FGNote.Value;

  draft.KNote.metakey = row.KNote.MetaKey;
  draft.KNote.value = row.KNote.Value;

  draft.INote.metakey = row.INote.MetaKey;
  draft.INote.value = row.INote.Value;

  draft.LeadType.metakey = row.LeadType.MetaKey;
  draft.LeadType.value = row.LeadType.Value;

  draft.LeadOrigin.metakey = row.LeadOrigin.MetaKey;
  draft.LeadOrigin.value = row.LeadOrigin.Value;

  draft.Mobile.metakey = row.Mobile.MetaKey;
  draft.Mobile.value = row.Mobile.Value;

  draft.LastReview.metakey = row.LastReview.MetaKey;
  draft.LastReview.value = row.LastReview.Value;

  draft.ClientNextActivity.metakey = row.ClientNextActivity.MetaKey;
  draft.ClientNextActivity.value = row.ClientNextActivity.Value;

  draft.UserNextActivity.metakey = row.UserNextActivity.MetaKey;
  draft.UserNextActivity.value = row.UserNextActivity.Value;

  draft.PhysicalAddress.metakey = row.PhysicalAddress.MetaKey;
  draft.PhysicalAddress.value = row.PhysicalAddress.Value;

  draft.ClientSince.metakey = row.ClientSince.MetaKey;
  draft.ClientSince.value = row.ClientSince.Value;

  draft.Work.metakey = row.Work.MetaKey;
  draft.Work.value = row.Work.Value;

  draft.LinkedContacts.metakey = row.LinkedContacts.MetaKey;
  draft.LinkedContacts.value = row.LinkedContacts.Value;

  draft.LRNextReview.metakey = row.LRNextReview.MetaKey;
  draft.LRNextReview.value = row.LRNextReview.Value;

  draft.InvestmentKSNextReview.metakey = row.InvestmentKSNextReview.MetaKey;
  draft.InvestmentKSNextReview.value = row.InvestmentKSNextReview.Value;

  draft.MortgageNextReview.metakey = row.MortgageNextReview.MetaKey;
  draft.MortgageNextReview.value = row.MortgageNextReview.Value;

  draft.FGNextReview.metakey = row.FGNextReview.MetaKey;
  draft.FGNextReview.value = row.FGNextReview.Value;

  draft.link = {
    CustomerId: row.CustomerId,
    IsCompany: row.IsCompany
  };
}));
