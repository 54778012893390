import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BusinessPageRoutingModule } from './business-page-routing.module';
import { SharedModule } from '../../shared/shared.module';

import { BusinessPageComponent } from './business-page.component';
import { BusinessPageResolver } from './business-page.resolver';

import { BusinessPageService } from './state/business-page.service';
import { BusinessPageQuery } from './state/business-page.query';
import { BusinessPageStore } from './state/business-page.store';
import { BusinessPageColumnsFormComponent } from './business-page-columns-form/business-page-columns-form.component';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
  imports: [
    CommonModule,
    BusinessPageRoutingModule,
    SharedModule,
    SortableModule.forRoot(),
    TabsModule.forRoot()
  ],
  declarations: [BusinessPageComponent, BusinessPageColumnsFormComponent],
  providers: [
    BusinessPageResolver,

    BusinessPageQuery,
    BusinessPageStore,
    BusinessPageService
  ],
  exports: [
    // BusinessPageReportFormComponent
  ],
  schemas: [
    // NO_ERRORS_SCHEMA
  ]
})
export class BusinessPageModule { }
