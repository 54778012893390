import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo, tap, take } from 'rxjs/operators';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { LrInsuranceService } from './states/lr-insurance.service';

const dropdownCodes: string[] = [
	'LRP',
	'LRPT',
	'LRCS',
	'LRPS',
	'LRRS',
	'PLRS',
	'PCLE',
	'PCLT',
	'AT',
	'LRCC',
	'LRPD',
	'LRPF',
	'LRPR',
];

@Injectable()
export class LrInsuranceResolver implements Resolve<boolean> {
	constructor(private dropdownValueService: DropdownValueService, private lrInsuranceService: LrInsuranceService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		return forkJoin([dropdown$]).pipe(
			tap(() => this.lrInsuranceService.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.lrInsuranceService.getColumnWidths().pipe(take(1)).subscribe()),
			mapTo(true)
		);
	}
}
