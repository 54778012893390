<div class="card-header font-weight-bold text-light tap-bg-primary">
	{{ header }}
</div>
<div class="card-body p-4">
	<ng-container [ngSwitch]="message">
		<ng-container *ngSwitchCase="'Property'">
			<app-property-form
				[propertyUse]="su$ | async"
				[securityValuation]="sv1$ | async"
				[type]="st$ | async"
				[title]="sti$ | async"
				[rentalFrequencyChoices]="apcrtf$ | async"
				[rateFrequencyChoices]="apcrtf$ | async"
				[insuranceFrequencyChoices]="apcrtf$ | async"
				[otherExpenseFrequencyChoices]="apcrtf$ | async"
				[policyOwners]="policyOwners"
				[getOwnerChoices]="getOwnerChoices"
				[propertyInfo]="crtInfo"
				[isLoading]="isLoading"
				[viewMode]="viewMode"
				(saveEvent)="save($event)"
				(cancelEvent)="cancel($event)"
			>
			</app-property-form>
		</ng-container>
		<ng-container *ngSwitchCase="'KiwiSaver'">
			<app-kiwisaver-form
				[provider]="kp$ | async"
				[riskProfile]="apcrtrp$ | async"
				[kiwiSaverInfo]="crtInfo"
				[fromCRM]="crtInfo?.FromCRM"
				[lifeAssuredList]="lifeAssuredList$ | async"
				[getOwnerChoices]="getOwnerChoices"
				[fundType]="kft$ | async"
				[isLoading]="isLoading"
				[viewMode]="viewMode"
				(saveEvent)="save($event)"
				(cancelEvent)="cancel($event)"
			>
			</app-kiwisaver-form>
		</ng-container>
		<ng-container *ngSwitchCase="'Other'"> </ng-container>
		<ng-container *ngSwitchCase="'Mortgages'">
			<app-mortgages-form
				[lender]="mp$ | async"
				[loanType]="mlt$ | async"
				[mortgageFrequency]="apcrtf$ | async"
				[borrowers]="borrowers | async"
				[getOwnerChoices]="getOwnerChoices"
				[securities]="securities | async"
				[mortgageInfo]="crtInfo"
				[isLoading]="isLoading"
				[hasLoanLimit]="hasLoanLimit"
				[hasLoanRepaymentDate]="hasLoanRepaymentDate"
				[viewMode]="viewMode"
				(saveEvent)="save($event)"
				(cancelEvent)="cancel($event)"
			></app-mortgages-form>
		</ng-container>
		<ng-container *ngSwitchCase="'Liabilities'">
			<app-liabilities-form
				[lender]="mp$ | async"
				[loanTypes]="mlt$ | async"
				[mortgageFrequency]="apcrtf$ | async"
				[agree]="apcrtynna$ | async"
				[liabilities]="apcrtl$ | async"
				[borrowers]="borrowers | async"
				[getOwnerChoices]="getOwnerChoices"
				[securities]="securities | async"
				[liabilityInfo]="crtInfo"
				[isLoading]="isLoading"
				[viewMode]="viewMode"
				(saveEvent)="save($event)"
				(cancelEvent)="cancel($event)"
			>
			</app-liabilities-form>
		</ng-container>
	</ng-container>
</div>
