import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
	TopupRequirementsStore,
	TopupRequirementsState,
} from './topup-requirements.store';

@Injectable()
export class TopupRequirementsQuery extends QueryEntity<TopupRequirementsState> {
	topupRequirementList$ = this.selectAll();
	isLoading$ = this.selectLoading();

	constructor(protected store: TopupRequirementsStore) {
		super(store);
	}
}
