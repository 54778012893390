import {
  Directive,
  OnDestroy,
  Input,
  ViewContainerRef,
  TemplateRef
} from "@angular/core";
import {
  BreakpointObserver,
  BreakpointState
} from "@angular/cdk/layout";
import { Subscription } from "rxjs";
import {ScreenSize, breakpointConfig} from './breakpoints';

@Directive({ 
  selector: "[appViewportSize]",
  standalone: true
})
export class ViewportSizeDirective implements OnDestroy {
  private subscription = new Subscription();

  @Input("appViewportSize") set size(value: ScreenSize) {
    this.subscription.unsubscribe();
    this.subscription = this.observer
      .observe(breakpointConfig[value])
      .subscribe(this.updateView);
  }

  constructor(
    private observer: BreakpointObserver,
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  updateView = ({ breakpoints }: BreakpointState) => {
    const matches = Object.values(breakpoints).every(x => x)
    if (matches && !this.vcRef.length) {
      this.vcRef.createEmbeddedView(this.templateRef);
    } else if (!matches && this.vcRef.length) {
      this.vcRef.clear();
    }
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
