import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, NgZone } from '@angular/core';
import { CommandRoute } from '../../../core/config/route.service';

/** Displays text and is clickable if has route. */
@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayComponent {
  /** route to go to */
  @Input() route: CommandRoute;
  /** value to show */
  @Input() value: string;
  /** class for overdue color code */
  @Input() cellClass: any;
  /** index number for use in ID */
  @Input() index: any;
  /** field name for use in ID */
  @Input() fieldId: any;

  constructor(private cd: ChangeDetectorRef, private ngZone: NgZone) { }

  /**
   * routeLink click, gets stuck when using ngx-datatable.
   * Somehow solves it.
   */
  onClick() {
    this.ngZone.run(() => this.cd.detectChanges());
  }
}
