import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SoaDocumentState, SoaDocumentStore } from './soa-documents.store';

@Injectable()
export class SoaDocumentQuery extends QueryEntity<SoaDocumentState> {
	applicationDocuments$ = this.selectAll();

	constructor(protected store: SoaDocumentStore) {
		super(store);
	}
}
