import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Report } from './report.model';
import { produce } from 'immer';
import { Injectable } from "@angular/core";

export interface ReportState extends EntityState<Report> {
  reportType: string;
  isExporting: boolean;
}
@Injectable()
@StoreConfig({ name: 'reports' })
export class ReportStore extends EntityStore<ReportState, Report> {
  constructor() {
    super({ reportType: null });
  }

  setReportType(reportType: string | null | undefined) {
    this.update(produce(x => { x.reportType = reportType; }));
  }

  setIsExporting(isExporting: boolean) {
    this.update(produce(x => { x.isExporting = isExporting; }));
  }
}
