import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { delay, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { DocumentState } from 'src/app/shared/models/client-review-template/declaration/declaration.model';
import { DisclosureDocumentMapper } from 'src/app/shared/models/client-review-template/disclosure-document/disclosure-document.mapper';
import { CrtEmailModel } from 'src/app/shared/models/emails/crt/email.model';
import { Attachment } from 'src/app/shared/models/_general/attachment.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { AuthorityToProceedSettingsService } from 'src/app/modules/crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.service';
import { AuthorityToProceedMapper } from 'src/app/modules/crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.mapper';
import { ServiceAdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { BusinessConfig } from 'src/app/domain/business-config/business-config.model';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { EmailFormComponent } from '../email-form/email-form.component';

@Component({
	selector: 'app-atp-email-modal',
	templateUrl: './atp-email-modal.component.html',
	styleUrls: ['./atp-email-modal.component.scss'],
})
export class AtpEmailModalComponent implements ConfirmModalModel, OnInit {
	saveEmailFn$: (model: any) => Observable<any>;
	sendEmailFn$: (model: any) => Observable<any>;
	downloadOfferTermsFn$: (model: any) => Observable<any>;
	generateFinalisedEmailTemplateFn$: Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	emailSettings$: Observable<any>;
	emailDocumentTemplateBase64$: Observable<string>;
	emailDocumentTemplate: string;
	bodyContent = '<p></p>';
	shortCodes: object = null;
	peopleDropdown: Observable<any>;
	peopleList: ViewDisplayValue[];
	showEmailTemplate: boolean;
	bodyId: number;
	attachments: Attachment[];
	successMessage: string;
	attachFiles = true;
	offerTerms: DocumentState[];
	adviceProcess: ServiceAdviceProcessState;
	businessConfig$: Observable<BusinessConfig>;
	enableDefaultBcc: boolean;
	mergeTags$: Observable<MergeTagState[]>;
	showNextBtn = false;
	nextLabel = '';

	next$: Observable<any>;

	@ViewChild(EmailFormComponent) emailFormComponent: EmailFormComponent;
	documentInfo: any;

	constructor(
		public bsModalRef: BsModalRef,
		private apService: AuthorityToProceedSettingsService,
		private crtDocService: CrtDocumentService,
		private renderer: Renderer2
	) {}

	ngOnInit(): void {
		this.prepData();
		this.peopleDropdown
			.pipe(
				map((data) => DisclosureDocumentMapper.mapCRTPeopleToEmailDd(data)),
				take(1)
			)
			.subscribe((data) => {
				this.peopleList = data;
			});
	}

	prepData() {
		combineLatest([this.emailSettings$])
			.pipe(
				filter(([data]) => !!data),
				tap(([data]) => (this.bodyId = +data?.body || 0)),
				map(([data]) => AuthorityToProceedMapper.mapEmailSettingsData(data)),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.apService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.apService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	sent() {
		this.bsModalRef.hide();
	}

	next(): void {
		this.bsModalRef.hide();
		this.next$?.pipe(take(1)).subscribe();
	}

	decline() {
		if (!!this.decline$) {
			this.decline$.pipe(take(1)).subscribe();
		}
		this.bsModalRef.hide();
	}

	downloadPdfAttachment(attachment: Attachment) {
		if (attachment.generateContentCallback$) {
			attachment.generateContentCallback$
			.pipe(
				delay(100),
				mergeMap((content) => this.renderPdfDownload(content, attachment.newPdfOptions)),
				take(1)
			)
			.subscribe();
		}

		if (attachment.fileUrl) {
			this.downloadFile(attachment.fileUrl, attachment.fileName);
		}
	}

	downloadFile(fileUrl: any, fileName: string) {
		this.crtDocService
			.getDocumentLink(fileUrl, {
				responseType: 'blob',
			})
			.pipe(
				tap((file) => {
					const newFile = new File([file], fileName);
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(newFile);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', fileName);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			)
			.subscribe();
	}

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, pdfOptions?.FileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	openAttachmentModal() {
		this.emailFormComponent.openUploadOptionModal();
	}

	removeAttachment(index: number) {
		this.attachments.splice(index, 1);
	}
}

export interface ConfirmModalModel {
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
