import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, Subject, throwError, iif } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mergeMap,
	tap,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { ClientProfileService } from 'src/app/modules/crm/client-profile/states/client-profile.service';
import { DocumentGroup } from 'src/app/shared/models/documents/document-group.model';
import { DocumentTypes } from 'src/app/shared/models/documents/document.model';
import { objectUtil } from 'src/app/util/util';
import { ClientReviewTemplateQuery } from '../../../../states/client-review-template.query';
import { SoaDocumentStore } from '../state/soa-documents.store';
import { SoaDocument } from './soa-documents.model';
import { SoaDocumentQuery } from './soa-documents.query';


@Injectable({
	providedIn: 'root',
})
export class SoaDocumentService {
	shouldUpdateDocumentSort: Subject<boolean> = new Subject();

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected soaDocumentStore: SoaDocumentStore,
		private query: ClientReviewTemplateQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected clientProfileService: ClientProfileService,
		protected businessService: BusinessService,
		protected soaDocumentQuery: SoaDocumentQuery
	) {
	}

	getApplicationDocuments(
		applicationCRTId: number
	): Observable<SoaDocument[]> {
		this.soaDocumentStore.setLoading(true);
		const endpoint = `crt/soa/${applicationCRTId}/sub-section/LOATSOAD `;
		return this.api.get<SoaDocument[]>(endpoint).pipe(
			map((x) =>
				(x || [])
					?.map(objectUtil.mapPascalCaseToCamelCase)
					?.sort((a, b) => a.order - b.order)
			),
			tap((x) => this.soaDocumentStore.set(x || [])),
			finalize(() => this.soaDocumentStore.setLoading(false)),
			catchError(() => EMPTY)
		);
	}

	add(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: DocumentTypes.soaDocument,
			AdviceProcessId: this.query.getValue().adviceProcessId,
		};
		this.soaDocumentStore.setLoading(true);
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.soaDocumentStore.add(x)),
			finalize(() => this.soaDocumentStore.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	update(data, refetch?: boolean) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			// SectionCode: AdviceProcessSectionCodes.SoaDocument,
			SectionCode: DocumentTypes.soaDocument,
			AdviceProcessId: this.query.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap((x) => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.soaDocumentStore.upsert(x?.cRTId, x)),
			concatMap((x) => {
				if (refetch) {
					return this.getApplicationDocuments(x?.parentCRTId);
				} else {
					return of(x);
				}
			}),
      mergeMap((x) => iif(() =>
        // Since linking a image convert it to pdf.
        // This means that a new document will be created and the response from the server has
        // different documentid. We need to fetch and add the newly created document in the client documents lists
        // so we can map the client documentid in document component to show the linked document file name.
        !!x.documentId && +x.documentId > 0 && x.documentId !== data.documentId,
        this.api.get(`documents/${x.documentId}`)
          .pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x))),
        of(x)
      )),
			catchError(() => of(undefined))
		);
	}

	delete(cRTId: number) {
		const endpoint = `crt/${cRTId}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				this.soaDocumentStore.remove(cRTId);
			}),
			catchError(() => of(undefined))
		);
	}

	uploadDocument(req) {
		const endpoint = 'documents';
		return this.api
			.post3<any>(endpoint, req)
			.pipe(catchError(() => EMPTY))
			.pipe(
				concatMap((x) => this.refetchClientDocument().pipe(map(() => x))),
				catchError(() => of(undefined))
			);
	}

	downloadLink(referenceId) {
		return this.api.get<string>(`documents/download/${referenceId}`);
	}

	refetchClientDocument() {
		return this.clientProfileService
			.getClientDocuments(this.query.getValue().primaryClient?.customerID,)
			.pipe(catchError(() => of(undefined)));
	}

	/**
	 * Get Client Documents
	 * @param primaryClientId primaryClientId
	 */
	getClientDocuments(primaryClientId: number): Observable<DocumentGroup> {
		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetDocumentsClientId(x)),
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => EMPTY)
		);
	}

  imageToPDF(data: {
    document: string;
    fileName: string;
  }): Observable<string> {
    const endpoint = 'documents/render/image-pdf';
    return this.api.post(endpoint, objectUtil.mapCamelCaseToPascalCase(data));
  }

	combineDocuments(documentIds: number[]) {
		const endpoint = `documents/render/merge-pdf`;
		return this.api
			.postFileDownload(endpoint, documentIds)
			.pipe(catchError((x) => throwError(x)));
	}
}
