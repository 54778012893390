import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, EMPTY, iif, Observable, of } from 'rxjs';
import { concatMap, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import {
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { MortgageAdviceProcessService } from '../../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { PeopleEntitiesQuery } from './state/people-entities.query';
import { PeopleEntitiesService } from './state/people-entities.service';

@Component({
	selector: 'app-people-entities',
	templateUrl: './people-entities.component.html',
	styleUrls: ['./people-entities.component.scss'],
})
export class PeopleEntitiesComponent implements OnInit {
	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;

	isPeopleLoading$ = this.peopleEntitiesQuery.isPeopleLoading$;
	isDependantsLoading$ = this.peopleEntitiesQuery.isDependantLoading$;
	isTrustsLoading$ = this.peopleEntitiesQuery.isTrustLoading$;
	isCompanyLoading$ = this.peopleEntitiesQuery.isCompanyLoading$;

	SCR$ = this.crtMortgageService.SCR$;
	PCE$ = this.crtMortgageService.PCE$;
	SCTT$ = this.crtMortgageService.SCTT$;
	APCRTVD$ = this.crtMortgageService.APCRTVD$;
	APCRTG$ = this.crtMortgageService.APCRTG$;

	APCRTBP$ = this.crtMortgageService.APCRTBP$;
	APCRTYNNA$ = this.crtMortgageService.APCRTYNNA$;

	APCRTTIR$ = this.crtMortgageService.APCRTTIR$;
	APCRTMS$ = this.crtMortgageService.APCRTMS$;

	PCT$ = this.crtMortgageService.PCT$;

	people$ = this.peopleEntitiesQuery.people$;
	dependents$ = this.peopleEntitiesService.dependants$;
	trusts$ = this.peopleEntitiesService.trusts$;
	company$ = this.peopleEntitiesService.company$;
	sidebar = this.moatQuery.getValue().sidebars;

	clientId = this.route.snapshot.paramMap.get('clientId');
	constructor(
		private peopleEntitiesQuery: PeopleEntitiesQuery,
		private peopleEntitiesService: PeopleEntitiesService,
		private crtMortgageService: CrtMortgageService,
		private moatAdviceProcessService: MortgageAdviceProcessService,
		private moatQuery: CrtMortgageQuery,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.moatAdviceProcessService
			.updateMortApPageStarted(AdviceProcessPageCodes.People)
			.pipe(take(1))
			.subscribe();
	}

	// People
	addNewPeopleFn$ = (req: { people; adviceProcessId }) =>
		this.peopleEntitiesService.addPeople(req.people, req.adviceProcessId).pipe(
			mergeMap((data) => this.peopleEntitiesService.getPersonInfo(+data)),
			tap((data) => this.peopleEntitiesService.updatePeopleInfo(data)),
			mergeMap(() =>
				this.crtMortgageService.getSecondaryClients(+this.clientId)
			)
		);
	addExistingPeopleFn$ = (req: { people; adviceProcessId }) =>
		this.peopleEntitiesService.addPeople(req.people, req.adviceProcessId);
	updatePeopleFn$ = (model) =>
		this.peopleEntitiesService.updatePeople(model).pipe(
			mergeMap((y) => {
        // refresh people entities for ShareCP Dropdown
				return this.crtMortgageService
					.getSecondaryClients(+this.clientId)
					.pipe(map(() => y))
      }),
			concatMap(() =>
				iif(
					() => model.relationship === 'Child',
					this.peopleEntitiesService.getPeople(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						'FPP',
						true
					),
					EMPTY
				)
			),
			concatMap(() =>
				iif(
					() => model.relationship === 'Child',
					this.peopleEntitiesService.getDependents(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						'FPD',
						true,
						true
					),
					EMPTY
				)
			),
		);
	deletePeopleFn$ = (id) =>
		this.peopleEntitiesService
			.deletePeople(id)
			.pipe(
				concatMap(() =>
					this.crtMortgageService.getSecondaryClients(+this.clientId)
				)
			);

	// Dependent
	addNewDependentFn$ = (req: { dependent; adviceProcessId }) =>
		this.peopleEntitiesService
			.addDependent(req.dependent, req.adviceProcessId)
			.pipe(
				concatMap((x) =>
					this.peopleEntitiesService
						.getDependents(
							req.adviceProcessId,
							AdviceProcessSectionCodes.Dependants,
							true
						)
						.pipe(map(() => x))
				),
				concatMap((x) =>
					this.crtMortgageService.getSecondaryClients(this.clientId)
				)
			);
	addExistingDependentFn$ = (req: { dependent; adviceProcessId }) =>
		this.peopleEntitiesService.addDependent(req.dependent, req.adviceProcessId);

	updateDepentdentFn$ = (model) =>
		this.peopleEntitiesService.updateDependent(model).pipe(
			// tslint:disable-next-line: max-line-length
			concatMap(() =>
				iif(
					() => model.relationship !== 'Child',
					this.peopleEntitiesService.getPeople(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						'FPP',
						true
					),
					of(model)
				)
			),
			concatMap(() =>
				iif(
					() => model.relationship !== 'Child',
					this.peopleEntitiesService.getDependents(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						'FPD',
						true
					),
					of(model)
				)
			),
			concatMap((y) =>
				iif(
					() => model.relationship !== 'Child',
					this.peopleEntitiesService.getSecondaryClients(this.clientId),
					of(model)
				)
			),
			finalize(() => {
				this.peopleEntitiesService.setDependentsIsLoading(false);
			})
		);

	deleteDepentdentFn$ = (id) =>
		this.peopleEntitiesService
			.deleteDependent(id)
			.pipe(
				concatMap(() =>
					this.crtMortgageService.getSecondaryClients(+this.clientId)
				)
			);

	// Trust
	addNewTrustFn$ = (req: { trust; adviceProcessId }) =>
		this.peopleEntitiesService.addTrust(req.trust, req.adviceProcessId).pipe(
			concatMap((x) =>
				this.peopleEntitiesService
					.getTrusts(req.adviceProcessId, AdviceProcessSectionCodes.Trust, true)
					.pipe(map(() => x))
			),
			concatMap((x) =>
				this.crtMortgageService.getSecondaryTrusts(this.clientId)
			)
		);
	addExistingTrustFn$ = (req: { trust; adviceProcessId }) =>
		this.peopleEntitiesService.addTrust(req.trust, req.adviceProcessId);
	updateTrustFn$ = (model) =>
		this.peopleEntitiesService
			.updateTrust(model)
			.pipe(
				concatMap((x) =>
					this.crtMortgageService.getSecondaryTrusts(this.clientId)
				)
			);
	deleteTrustFn$ = (id) => this.peopleEntitiesService.deleteTrust(id);

	// Company
	addNewCompanyFn$ = (req: { company; adviceProcessId }) =>
		new Observable<any>((obs) => {
			obs.next(req);
			obs.complete();
		}).pipe(
			concatMap(() =>
				this.peopleEntitiesService.addCompany(req.company, req.adviceProcessId)
			),
			concatMap(() =>
				this.peopleEntitiesService.getCompany(
					req.adviceProcessId,
					AdviceProcessSectionCodes.Company,
					true
				)
			)
		);
	addExistingCompanyFn$ = (req: { company; adviceProcessId }) =>
		this.peopleEntitiesService.addCompany(req.company, req.adviceProcessId);
	updateCompanyFn$ = (model) => this.peopleEntitiesService.updateCompany(model);
	deleteCompanyFn$ = (id) =>
		this.peopleEntitiesService
			.deleteCompany(id)
			.pipe(
				concatMap(() =>
					this.crtMortgageService.getSecondaryCompanies(+this.clientId)
				)
			);
}
