import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, iif, Observable, of, Subject } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../../util/moment.util';
import { BodyMeasureMapper } from '../../../../../shared/models/client-review-template/history/measure.mapper';
import { MedicalHistoryMapper } from '../../../../../shared/models/client-review-template/history/medical.mapper';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { HistoryService } from '../../states/history/history.service';
import { BodyMeasureState } from 'src/app/shared/models/client-review-template/history/measures.model';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';
import { validatorUtil } from 'src/app/util/validator.util';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';

import { divide, sum, multiply, either, isNil, isEmpty } from 'ramda';
import { DependentState } from 'src/app/shared/models/client-review-template/dependent/dependent.model';
import { FamilyHistoryClient } from 'src/app/shared/models/client-review-template/history/family.model';

@Component({
	selector: 'app-history-medical',
	templateUrl: './medical.component.html',
	styleUrls: ['./medical.component.scss'],
})
export class MedicalComponent implements OnInit, OnDestroy {

	@Input() isAdviceProcessEnded: boolean;

	@Input() isMedicalLoading$: Observable<boolean>;
	@Input() clients: Observable<DependentState[]>;
	@Input() currentTerms$: Observable<ViewDisplayValue[]>;
	@Input() addMeasures: ({ measures }) => Observable<any>;
	@Input() editMeasures: ({ measures }) => Observable<any>;
	@Input() editMedicalHistoryOptions: ({
		measures,
		history,
	}) => Observable<any>;
	@Input() addFn: ({ medical, adviceProcessId }) => Observable<any>;
	@Input() editFn: ({ medical }) => Observable<any>;
	form: UntypedFormGroup;
	elseMinusPeople = true;
	measurement = [];
	medicalHistory = [];
	adviceProcessId: string;
	clientList: FamilyHistoryClient[] = [];
	submitted: boolean;
	currentPrimaryEdit: number;
	currentPeopleEdit: number;
	isBodyMeasureLoading: boolean;
	isAddMode = false;
	isEditMode = false;
	heightOptionsDd$ = this.service.APCRTHO$;
	weightOptionsDd$ = this.service.APCRTWO$;
	default = {
		height: 'Height (cm)',
		weight: 'Weight (kg)',
	};

	onDestroy$ = new Subject<void>();
	WEIGHT_MULTIPLIER = 2.2046;
	HEIGHT_MULTIPLIER = 30.48;
	M_MULTIPLIER = 100;

	isInit = false;
	primaryArrayStatus: boolean[] = [];

	get PeopleArray() {
		return this.form.get('peopleArray') as UntypedFormArray;
	}

	get PrimaryArray() {
		return this.form.get('primaryArray') as UntypedFormArray;
	}

	get Height() {
		return this.form.get('height');
	}

	constructor(
		private fb: UntypedFormBuilder,
		private query: ClientReviewTemplateQuery,
		private route: ActivatedRoute,
		private historyService: HistoryService,
		private service: ClientReviewTemplateService
	) {
		this.form = this.fb.group({
			height: [''],
			weight: [''],
			primaryArray: this.fb.array([]),
			peopleArray: this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.isInit = true;
		this.adviceProcessId = this.route.snapshot.paramMap.get('adviceProcessId');
		this.form.get('height').setValue(this.default.height);
		this.form.get('weight').setValue(this.default.weight);

		this.clients.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
			if (data) {
				this.clientList = data?.map((x) => {
					return {
						display: x.name,
						value: x.customerId,
						isDefault: false,
					};
				});
			}
		});

		this.query.bodyMeasures$
			.pipe(
				withLatestFrom(this.query.medicalHistoryOptions$),
				tap(([bodyMeasures, options]) => {
					this.PrimaryArray.reset();
					this.measurement = bodyMeasures;
					if (options) {
						const heightOptions = options.heightOptions;
						const weightOptions = options.weightOptions;
						this.form.get('height').setValue(heightOptions);
						this.form.get('weight').setValue(weightOptions);
					}
					this.prepBodyMeasures(true);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.isInit = false;
			});

		this.query.medicalHistory$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.medicalHistory = data;
				this.prepMedicalHistory();
			});

		if (this.isAdviceProcessEnded) {
			this.form.disable()
		}
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	prepBodyMeasures(isInit?: boolean) {
		while (this.PrimaryArray.length !== 0) {
			this.PrimaryArray.removeAt(0);
		}
		(this.measurement ?? [])?.forEach((x, i) => {
			if (
				!isInit &&
				x.heightOptions &&
				x.heightOptions.toLowerCase()?.includes('ft') &&
				!!x.heightValue
			) {
				x.heightValue = Number(x.heightValue * this.HEIGHT_MULTIPLIER).toFixed(
					2
				);
			}
			if (
				!isInit &&
				x.weightOptions &&
				x.weightOptions.toLowerCase()?.includes('lbs') &&
				!!x.weightValue
			) {
				x.weightValue = Number(x.weightValue / this.WEIGHT_MULTIPLIER).toFixed(
					2
				);
			}
			const fg = this.fb.group({
				client: x.client,
				heightValue: x.heightValue,
				weightValue: +x.weightValue,
				smokingStatus: [
					x.smokingStatus,
					[Validators.required, NoWhitespaceValidator],
				],
				bMI: x.bMI ? Number(x.bMI).toFixed(2) : null,

				btnSavePrimary: x.btnSavePrimary != null ? x.btnSavePrimary : false,
				btnEditPrimary: x.btnEditPrimary != null ? x.btnEditPrimary : true,
			});

			if (isNil(x.btnSavePrimary) && isNil(x.btnEditPrimary)) {
				if (this.primaryArrayStatus.length > 1 && this.primaryArrayStatus[i]) {
					fg.patchValue({
						btnSavePrimary: true,
						btnEditPrimary: false,
					});
					fg.enable();
				} else {
					fg.patchValue({
						btnSavePrimary: false,
						btnEditPrimary: true,
					});
					fg.disable();
				}
			}

			if (x.btnEditPrimary) {
				fg.disable();
			}

			// tslint:disable-next-line: no-string-literal
			fg.controls['client'].disable();

			this.PrimaryArray?.push(fg);
		});
		this.primaryArrayStatus = [];
	}

	prepMedicalHistory() {
		while (this.PeopleArray.length !== 0) {
			this.PeopleArray.removeAt(0);
		}
		(this.medicalHistory ?? [])?.forEach((x) => {
			const fg = this.fb.group({
				client: x.client || 0,
				healthIssue: x.healthIssue,
				dateRangeMin: MomentUtil.formatDateToMoment(x.dateRangeMin),
				dateRangeMax: MomentUtil.formatDateToMoment(x.dateRangeMax),
				detailsNotes: x.detailsNotes,
				currentlyExcludedLoaded: x.currentlyExcludedLoaded,

				btnDisable: true,
				btnSavePeople: false,
				btnEditPeople: true,
				isSaving: false,
			});
			fg.controls.client.setValue(x.client, { onlySelf: true });
			fg.disable();
			this.PeopleArray?.push(fg);
		});
	}

	setValidation() {
		this.PrimaryArray.controls.forEach((c, i) => {
			if (this.Height.value?.includes('ft')) {
				this.PrimaryArray.controls[i]
					.get('heightValue')
					?.setValidators([validatorUtil.ftInchesValidator]);
			} else {
				this.PrimaryArray.controls[i].get('heightValue')?.clearValidators();
			}
			this.PrimaryArray.controls[i]
				.get('heightValue')
				?.updateValueAndValidity();
		});
		this.PrimaryArray?.updateValueAndValidity();
	}

	selectChangeHeight() {
		this.isBodyMeasureLoading = true;
		const hasValueHeight = this.form.get('height').value;
		this.primaryArrayStatus = [];

		this.PrimaryArray.controls?.forEach((control, i) => {
			if (control instanceof UntypedFormGroup) {
				this.primaryArrayStatus?.push(control.enabled);
				let result = 0 as any;
				if (
					!!control.get('heightValue').value &&
					hasValueHeight?.includes('ft')
				) {
					result = this.computeFeetAndInches(control.get('heightValue').value);
				} else if (
					!!control.get('heightValue').value &&
					hasValueHeight?.includes('cm')
				) {
					result = this.computeFeetToCm(control.get('heightValue').value);
				}
				control.get('heightValue').setValue(result);
				this.inputChangeHeight(i);
			}
		});
		setTimeout(() => {
			this.editMeasurementOptions();
			this.setValidation();
		}, 100);
	}

	selectChangeWeight() {
		this.isBodyMeasureLoading = true;
		const hasValueWeight = this.form.get('weight').value;
		this.primaryArrayStatus = [];

		this.PrimaryArray.controls?.forEach((control, i) => {
			if (control instanceof UntypedFormGroup) {
				this.primaryArrayStatus?.push(control.enabled);
				let result = 0 as any;
				if (
					!!control.get('weightValue').value &&
					control.get('weightValue').value &&
					hasValueWeight?.includes('kg')
				) {
					result = Number(
						+control.get('weightValue').value / this.WEIGHT_MULTIPLIER
					).toFixed(2);
				} else if (
					!!control.get('weightValue').value &&
					hasValueWeight?.includes('lbs')
				) {
					result = Number(
						+control.get('weightValue').value * this.WEIGHT_MULTIPLIER
					).toFixed(2);
				}
				control.get('weightValue').setValue(result);
				this.inputChangeWeight(i);
			}
		});
		setTimeout(() => {
			this.editMeasurementOptions();
		}, 100);
	}

	editMeasurementOptions() {
		const height = this.form.get('height').value;
		const weight = this.form.get('weight').value;
		const bodyMeasures = this.PrimaryArray.getRawValue() as BodyMeasureState[];

		const measurements = this.measurement?.map((measure, i) => {
			let heightValue = bodyMeasures[i]?.heightValue?.toString();
			if (this.Height.value?.includes('ft')) {
				if (!either(isNil, isEmpty)(heightValue) && !heightValue?.includes('\'')) {
					// Append single quote for height ft value if numbers only
					heightValue = heightValue + '\'';
				}
			}

			const data = {
				...bodyMeasures[i],
				heightValue
			};

			return BodyMeasureMapper.mapToUpsert(
				data,
				measure || null,
				height,
				weight
			);
		});

		this.editMedicalHistoryOptions({
			measures: {
				heightOptions: height,
				weightOptions: weight,
			},
			history: measurements,
		});

		of(true)
			.pipe(
				concatMap(() => {
					const requests = measurements
						?.filter((i) => !either(isNil, isEmpty)(i?.smokingStatus))
						?.reduce((a, c) => {
							if (c?.cRTId) {
								// Catch error so that forkjoin will still continue
								const updateMeasures$ = this.editMeasures({ measures: c }).pipe(
									catchError((err) => of(null))
								);
								return [...a, updateMeasures$];
							}
							return a;
						}, []);

					return iif(
						() => requests?.length > 0,
						forkJoin(requests),
						of(null)
					)
				}),
				tap(() => this.isBodyMeasureLoading = false),
				take(1)
			)
			.subscribe();
	}

	inputChangeHeight = (index) => {
		this.setValidation();
		const hasValueHeight = this.form.get('height').value;
		const hasValueWeight = this.form.get('weight').value;

		const inputValueHeight =
			this.PrimaryArray.controls[index].get('heightValue').value;
		let bmi = 0;
		const inputValueWeight =
			this.PrimaryArray.controls[index].get('weightValue').value;
		if (inputValueWeight) {
			bmi = this.calculateBMI(
				inputValueHeight,
				hasValueHeight,
				+inputValueWeight,
				hasValueWeight
			);
		}
		this.PrimaryArray.controls[index].get('bMI').setValue(bmi);
	};

	inputChangeWeight = (index: number) => {
		const hasValueHeight = this.form.get('height').value;
		const hasValueWeight = this.form.get('weight').value;

		const inputValueWeight =
			this.PrimaryArray.controls[index].get('weightValue').value;
		let bmi = 0;
		const inputValueHeight =
			this.PrimaryArray.controls[index].get('heightValue').value;
		if (inputValueHeight) {
			bmi = this.calculateBMI(
				inputValueHeight,
				hasValueHeight,
				+inputValueWeight,
				hasValueWeight
			);
		}
		this.PrimaryArray.controls[index].get('bMI').setValue(bmi);
	};

	calculateBMI(height, heightMeasurement, weight, weightMeasurement) {
		weight = parseFloat(weight);

		if (heightMeasurement?.includes('ft')) {
			const inputValueHeight = this.computeInchesToFeet(height);
			height = (inputValueHeight * this.HEIGHT_MULTIPLIER) / this.M_MULTIPLIER;
		} else {
			height = parseFloat(height);
			height = height / this.M_MULTIPLIER;
		}
		if (isNaN(weight) || isNaN(height) || height <= 0 || weight <= 0) {
			return 0;
		}

		if (weightMeasurement?.includes('lbs')) {
			weight = weight / this.WEIGHT_MULTIPLIER;
		}
		return Number((weight / Math.pow(height, 2)).toFixed(2));
	}

	computeFeetAndInches(value) {
		const conversionResult = Number(+value / this.HEIGHT_MULTIPLIER).toFixed(2);
		const data = conversionResult?.split('.') || [];
		const feet = data?.length > 0 ? data[0] : 0;
		const inches = data?.length > 1 ? +`0.${data[1] || 0}` : 0;
		const inchesToFeet = Number(multiply(inches, 12)).toFixed(0);
		const result = `${feet}'${inchesToFeet}"`;

		return result;
	}

	computeInchesToFeet(data) {
		const value = (data?.toString()?.replace('"', '') || '')?.split('\'') || [];
		const feet = value?.length > 0 ? value[0] : 0;
		const inches = value?.length > 1 ? value[1] : 0;
		const inchesToFeet = +divide(inches, 12);
		const result = sum([+feet, inchesToFeet]);

		return result;
	}

	computeFeetToCm(value) {
		const getFeetValue = this.computeInchesToFeet(value);
		const result = Number(+getFeetValue * this.HEIGHT_MULTIPLIER).toFixed(2);

		return result;
	}

	savePrimary(index) {
		let updateFtHeightValue = false;
		this.submitted = true;
		if (this.PrimaryArray.controls[index].invalid) {
			return;
		}

		this.PrimaryArray.controls[index].get('btnSavePrimary').setValue(false);
		this.PrimaryArray.controls[index].get('btnEditPrimary').setValue(true);

		const height = this.form.get('height').value;
		const weight = this.form.get('weight').value;
		const formArray = this.form.controls.primaryArray as UntypedFormArray;
		formArray.controls[index].disable();
		let heightValue =	this.PrimaryArray.controls[index].get('heightValue').value?.toString();
		if (this.Height.value?.includes('ft')) {
			if (!either(isNil, isEmpty)(heightValue) && !heightValue?.includes('\'')) {
				// Append single quote for height ft value if numbers only
				heightValue = heightValue + '\'';
				updateFtHeightValue = true;
			}
		}
		heightValue = either(isNil, isEmpty)(heightValue) ? null : heightValue;
		const formData = {
			...this.form.controls.primaryArray.value[index],
			heightValue,
		};
		const measurement = BodyMeasureMapper.mapToUpsert(
			formData,
			this.measurement[index] || null,
			height,
			weight
		);
		if (measurement.cRTId > 0) {
			this.editMeasures({ measures: measurement })
				.pipe(
					finalize(() => {
						if (updateFtHeightValue) {
							this.PrimaryArray.controls[index]
								.get('heightValue')
								.setValue(heightValue);
						}
						this.isBodyMeasureLoading = false;
					}),
					take(1)
				)
				.subscribe(
					(_) => {
						if (this.currentPrimaryEdit === index) {
							this.setCurrentEditPrimary();
						} else {
							this.setCurrentEditPrimary(this.currentPrimaryEdit);
						}
					},
					(error) => {
						this.setCurrentEditPrimary();
						if (error.CRTId) {
							this.addMeasures({ measures: measurement }).subscribe();
						}
					}
				);
		} else {
			if (this.currentPrimaryEdit === index) {
				this.setCurrentEditPrimary();
			} else {
				this.setCurrentEditPrimary(this.currentPrimaryEdit);
			}
		}
	}

	editPrimary(index: number) {
		this.setCurrentEditPrimary(index);
	}

	removePrimary(index: number) {
		this.PrimaryArray.controls[index].get('btnSavePrimary').setValue(false);
		this.PrimaryArray.controls[index].get('btnEditPrimary').setValue(true);
		this.restoreOriginalData(index);
		const formArray = this.form.controls.primaryArray as UntypedFormArray;
		formArray.controls[index].disable();
		// this.prepBodyMeasures();
		this.setCurrentEditPrimary();
	}

	restoreOriginalData(index: number) {
		if (this.PrimaryArray.controls[index].dirty) {
			const latestSavedData = this.measurement[index] as BodyMeasureState;
			this.PrimaryArray.controls[index]
				.get('heightValue')
				.setValue(latestSavedData.heightValue);
			this.PrimaryArray.controls[index]
				.get('weightValue')
				.setValue(+latestSavedData.weightValue);
			this.PrimaryArray.controls[index]
				.get('smokingStatus')
				.setValue(latestSavedData.smokingStatus);
			this.inputChangeHeight(index);
		}
	}

	deletePrimary(index: number) {
		this.PrimaryArray.removeAt(index);
		this.historyService.removeCrt(this.measurement[index].cRTId).subscribe();
		this.measurement?.splice(index, 1);
		this.setCurrentEditPrimary();
	}

	addPeople() {
		this.PrimaryArray?.push(
			this.fb.group({
				clientPrimary: [''],
				heightPrimary: [''],
				weightPrimary: [''],
				isSmokerPrimary: [''],
				bMI: [''],

				btnDisable: true,
				btnSavePrimary: false,
				btnEditPrimary: true,
				isSaving: false,
			})
		);
	}

	addMedicalHistory() {
		this.isAddMode = true;
		this.PeopleArray?.push(
			this.fb.group({
				client: [''],
				healthIssue: [''],
				dateRangeMin: [''],
				dateRangeMax: [''],
				detailsNotes: [''],
				currentlyExcludedLoaded: [''],

				btnDisable: true,
				btnSavePeople: true,
				btnEditPeople: false,
				isSaving: false,
			})
		);
		this.setCurrentEditPeople(this.PeopleArray.getRawValue().length - 1);
	}

	savePeople(index: number) {
		this.PeopleArray.controls[index].get('btnSavePeople').setValue(false);
		this.PeopleArray.controls[index].get('isSaving').setValue(true);

		const formArray = this.form.controls.peopleArray as UntypedFormArray;
		formArray.controls[index].disable();

		const medical = MedicalHistoryMapper.mapToUpsert(
			this.form.controls.peopleArray.value[index],
			this.medicalHistory[index] || null
		);
		medical.adviceProcessId =
			+this.route.snapshot.paramMap.get('adviceProcessId');
		if (medical.cRTId > 0) {
			this.editFn({ medical })
				.pipe(
					finalize(() => {
						this.PeopleArray.controls[index]
							.get('btnEditPeople')
							.setValue(true);
						this.isEditMode = false;
						this.PeopleArray.controls[index].get('isSaving').setValue(false);
					})
				)
				.subscribe();
		} else {
			this.addFn({
				medical,
				adviceProcessId: Number(
					this.route.snapshot.paramMap.get('adviceProcessId')
				),
			})
				.pipe(
					finalize(() => {
						this.PeopleArray.controls[index]
							.get('btnEditPeople')
							.setValue(true);
						this.PeopleArray.controls[index].get('isSaving').setValue(false);
						this.isAddMode = false;
						this.setCurrentEditPeople();
					})
				)
				.subscribe();
		}
	}

	editPeople(index: number) {
		this.isEditMode = true;
		this.setCurrentEditPeople(index);
		this.PeopleArray.controls[index].get('btnSavePeople').setValue(true);
		this.PeopleArray.controls[index].get('btnEditPeople').setValue(false);
		this.PeopleArray.controls[index].get('btnDisable').setValue(false);

		const formArray = this.form.controls.peopleArray as UntypedFormArray;
		formArray.controls[index].enable();
	}

	deletePeople(index: number) {
		this.PeopleArray.removeAt(index);
		this.historyService.removeCrt(this.medicalHistory[index].cRTId).subscribe();
		this.medicalHistory?.splice(index, 1);
		this.setCurrentEditPeople();
		this.isEditMode = false;
	}

	removePeople(index: number) {
		if (index < this.medicalHistory.length) {
			this.PeopleArray.controls[index].get('btnSavePeople').setValue(false);
			this.PeopleArray.controls[index].get('btnEditPeople').setValue(true);
			const formArray = this.form.controls.peopleArray as UntypedFormArray;
			formArray.controls[index].disable();
			this.prepMedicalHistory();
		} else {
			this.PeopleArray.removeAt(index);
		}
		this.isAddMode = false;
		this.isEditMode = false;
		this.setCurrentEditPeople();
	}

	setCurrentEditPeople(index?: number) {
		this.currentPeopleEdit = index;
	}

	isPeopleEdit(index: number) {
		const isEdit = this.PeopleArray.getRawValue().some((x) => x?.btnSavePeople);
		return isEdit ? +index !== +this.currentPeopleEdit : false;
	}

	setCurrentEditPrimary(index?: number) {
		this.currentPrimaryEdit = index;
		this.PrimaryArray.controls[index]?.get('btnSavePrimary').setValue(true);
		this.PrimaryArray.controls[index]?.get('btnEditPrimary').setValue(false);

		const formArray = this.form.controls.primaryArray as UntypedFormArray;
		formArray.controls[index]?.enable();
		formArray.controls[index]?.get('client').disable();

		this.submitted = false;
	}

	isPrimaryEdit(index: number) {
		const isEdit = this.PrimaryArray.getRawValue()?.some(
			(x) => x?.btnSavePrimary
		);
		return isEdit ? +index !== +this.currentPrimaryEdit : false;
	}

	onSelect(index: number, value: string) {
		if (!!value) {
			this.PeopleArray.controls[index].get('btnDisable').setValue(false);
		} else {
			this.PeopleArray.controls[index].get('btnDisable').setValue(true);
		}
	}

	findPeople(id: number) {
		return this.clientList.find((x) => x.value === id);
	}
}
