import MomentUtil from '../../../../../util/moment.util';
import { AdviceProcessSectionCodes } from '../../../../../shared/models/advice-process/advice-process.model';
import { ExistingProperty } from '../../../../../shared/models/client-review-template/assets-liabilities/property/property.model';
import {
	Property,
	PropertyState,
} from '../../crt-mortgage/client-sop/assets-and-liabilities/state/property/property.model';
export class PropertyMapper {
	static mapToPropertyDetailsExisting = (
		model: ExistingProperty,
		adviceProcessId: number
	): Property => {
		return {
			AdviceProcessId: adviceProcessId,
			SectionCode: AdviceProcessSectionCodes.Property,
			PropertyAddress: model.PropertyAddress,
			PropertyUse: model.PropertyUse,
			PropertyValue: model.PropertyValue ?? null,
			PropertyOwner: model.PropertyOwner,
			Total: null,
			PurchaseDate: null,
			ValuationType: model.Valuation1,
			ValuationTypeDate: model.GRM1Date,
			RentalIncome: model.RentalIncome,
			RentalIncomeFrequency: null,
			BoarderIncome: null,
			BoarderIncomeFrequency: null,
			IncomeType: null,
			Title: model.Title,
			Rates: null,
			RatesFrequency: null,
			Type: model.Type,
			Insurance: null,
			InsuranceFrequency: null,
			OtherExpense: null,
			OtherExpenseFrequency: null,
			CustomerServiceID: model?.CustomerServiceID ?? null,
			FactFindComputation: {
				SectionCode: AdviceProcessSectionCodes.FFComputation,
				TotalAnnualIncome: 0,
				TotalPropertyValue: 0,
				TotalAssetValue: 0,
				OverallTotalGrossIncome: 0,
				TotalNetRentalIncome: 0,
				AnnualTaxableJointIncome: 0,
				MonthlyTaxableJointIncome: 0,
				MonthlyAfterTaxIncome: 0,
			},
			Status: 1,
		};
	};

	static mapToPropertyDetails = (
		model: PropertyState,
		adviceProcessId
	): Property => {
		return {
			CRTId: model?.cRTId ?? null,
			AdviceProcessId: adviceProcessId,
			SectionCode: AdviceProcessSectionCodes.Property,
			PropertyAddress: model.propertyAddress,
			PropertyUse: model.propertyUse,
			PropertyValue: model.propertyValue,
			PropertyOwner:
				typeof model.propertyOwner === 'string'
					? model.propertyOwner
					: !!model.propertyOwner
					? JSON.stringify(model.propertyOwner)
					: null,
			Total: null,
			PurchaseDate: !!model.purchaseDate
				? MomentUtil.formatDateToServerDate(model.purchaseDate)
				: null,
			ValuationType: !!model.valuationType ? model.valuationType : null,
			ValuationTypeDate: !!model.valuationTypeDate
				? MomentUtil.formatDateToServerDate(model.valuationTypeDate)
				: null,
			RentalIncome: !!model?.rentalIncome ? model.rentalIncome : null,
			RentalIncomeFrequency: model.rentalIncomeFrequency,
			BoarderIncome: !!model?.boarderIncome ? model.boarderIncome : null,
			BoarderIncomeFrequency: model.boarderIncomeFrequency,
			IncomeType: model.incomeType ? model.incomeType : null,
			Title: model.title,
			Rates: +model.rates,
			RatesFrequency: model.ratesFrequency,
			Type: model.type,
			Insurance: model.insurance,
			InsuranceFrequency: model.insuranceFrequency,
			OtherExpense: model.otherExpense,
			OtherExpenseFrequency: model.otherExpenseFrequency,
			CustomerServiceID: model?.customerServiceID ?? null,
			FactFindComputation: {
				SectionCode: AdviceProcessSectionCodes.FFComputation,
				TotalPropertyValue: model.factFindComputation?.totalPropertyValue ?? 0,
				TotalAssetValue: model.factFindComputation?.totalAssetValue ?? 0,
				TotalAnnualIncome: model.factFindComputation?.totalAnnualIncome ?? 0,
				OverallTotalGrossIncome:
					model.factFindComputation?.overallTotalGrossIncome ?? 0,
				TotalNetRentalIncome:
					model.factFindComputation?.totalNetRentalIncome ?? 0,
				AnnualTaxableJointIncome:
					model.factFindComputation?.annualTaxableJointIncome ?? 0,
				MonthlyTaxableJointIncome:
					model.factFindComputation?.monthlyTaxableJointIncome ?? 0,
				MonthlyAfterTaxIncome:
					model.factFindComputation?.monthlyAfterTaxIncome ?? 0,
			},
			Status: 1,
			PendingAdviserApproval: model?.pendingAdviserApproval ?? false,
		};
	};

	static mapToView(v) {
		return !!v
			? {
					propertyAddress: v.PropertyAddress,
					propertyUse: v.PropertyUse,
					purchaseDate: MomentUtil.formatDateToMoment(v.PurchaseDate),
					propertyValue: v.PropertyValue,
					valuationType: v.ValuationType,
					valuationTypeDate: MomentUtil.formatDateToMoment(v.ValuationTypeDate),
					propertyOwner:
						!!v.PropertyOwner && typeof v.PropertyOwner === 'string'
							? JSON.parse(v.PropertyOwner)
							: [],
					rentalIncome: v.RentalIncome,
					rentalIncomeFrequency: v.RentalIncomeFrequency,
					boarderIncome: v.BoarderIncome,
					boarderIncomeFrequency: v.BoarderIncomeFrequency,
					incomeType: v.IncomeType,
					title: v.Title,
					rates: v.Rates,
					ratesFrequency: v.RatesFrequency,
					type: v.Type,
					insurance: v.Insurance,
					insuranceFrequency: v.InsuranceFrequency,
					otherExpense: v.OtherExpense,
					otherExpenseFrequency: v.OtherExpenseFrequency,
			  }
			: {};
	}
}
