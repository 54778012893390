<div class="container-fluid h-100 px-0">
  <div class="form-row a-table__header flex-nowrap">
    <div class="col-auto a-table__header-cell">Due By</div>
    <div class="col a-table__header-cell">Activity Name</div>
    <div class="col a-table__header-cell">Client</div>
    <div class="col a-table__header-cell text-truncate text-center" title="Complete">Complete</div>
    <div class="a-table__header-cell text-truncate text-center" title="Cancel">Cancel</div>
  </div>
  <div class="row h-100">
    <div class="col a-table__body bg-white">
      <div class="a-table__body__wrapper">
        <div
          class="form-row a-table__row align-items-center"
          [ngClass]="act.className"
          *ngFor="let act of viewData$ | async; trackBy: trackByFn"
        >
          <div class="col-auto a-table__cell py-2">
            <a [ngClass]="act.className" [routerLink]="act.route">{{ act.dueDate }}</a>
          </div>
          <div class="col a-table__cell py-2">
            <a [ngClass]="act.className" [routerLink]="act.route">{{ act.activityName }}</a>
          </div>
          <div class="col a-table__cell py-2">
            <a [ngClass]="act.className" [routerLink]="act.route">{{ act.clientName }}</a>
          </div>
          <div class="col a-table__cell py-2 text-center mt-1">
            <app-checkbox>
              <input
                [disabled]="isDisabledComplete$ | async"
                [class.disabled]="isDisabledComplete$ | async"
                #checkBoxes
                type="checkbox"
                [checked]="act.isCompleted"
                (change)="completeActivity(act)"
              />
            </app-checkbox>
          </div>
          <div class="a-table__cell py-0 text-center">
            <ng-container *appRestrictTo="['FXDA']">
              <button type="button" class="btn btn-link p-0 text-center mt-1" (click)="cancel(act)">
                <i class="material-icons md-20 tap-text-color-h6" data-toggle="tooltip" title="cancel">cancel</i>
              </button>
            </ng-container>
          </div>
          <div class="col-12">
            <div class="a-table__divider"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
