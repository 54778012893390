import {
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { complement, isNil, isEmpty } from 'ramda';
import {
	BehaviorSubject,
	combineLatest,
	iif,
	Observable,
	Observer,
	of,
	Subject,
} from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { BusinessConfigQuery } from '../../../../../domain/business-config/business-config.query';
import { UserQuery } from '../../../../../domain/user/user.query';
import { filterNonEmailMergeTags, getContentWithMergeTags } from '../../../../../shared/converter/content-merge-tags';
import { ConfirmModalComponent } from '../../../../../shared/modal/confirm-modal/confirm-modal.component';
import { EmailModalComponent } from '../../../../../shared/modal/crt/email/email-modal.component';
import {
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	MOATDocumentField,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from '../../../../../shared/models/advice-process/advice-process.model';
import { MergeTagState } from '../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import {
	AdviceProcessDocumentTypesMOAT,
	DefaultFileNames,
	DocumentTypesMOAT
} from '../../../../../shared/models/documents/document.model';
import { EmailTypes } from '../../../../../shared/models/emails/mortgage/email.model';
import { WysiwygComponent } from '../../../../../shared/wysiwyg/wysiwyg.component';
import { convertUtil, fileUtil, objectUtil } from '../../../../../util/util';
import { EmailService } from '../../../client-review-template/states/email/email.service';
import { CrtDocumentService } from '../../_shared/service/crt-document.service';
import { PeopleEntitiesQuery } from '../client-sop/people-entities/state/people-entities.query';
import { CrtMortgageQuery } from '../state/crt-mortgage.query';
import { MergeTagsService } from '../state/merge-tags/merge-tags.service';
import { MortgageAdviceProcessService } from '../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { DisclosureMapper } from './state/disclosure.mapper';
import {
	DisclosureDocumentState,
	DisclosureState,
} from './state/disclosure.model';
import { DisclosureService } from './state/disclosure.service';
import { PeopleEntitiesService } from '../client-sop/people-entities/state/people-entities.service';
import { CrtMortgageNoteService } from '../state/note/crt-mortgage-note.service';

@Component({
	selector: 'app-disclosure',
	templateUrl: './disclosure.component.html',
	styleUrls: ['./disclosure.component.scss'],
})
export class DisclosureComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;
	content = '<p></p>';
	defaultSettings: DisclosureDocumentState;
	crtData: DisclosureState | any;
	isEnableEmailOption = null;
	hasExistingDoc = false;
	defaultFileName = DefaultFileNames.Disclosure;
	pdf: string;
	templateId: number;
	document;
	isCompLoading = false;

	generatePdf$ = new BehaviorSubject<string>(null);
	updateAP$ = new BehaviorSubject<boolean>(false);

	disclosureDocument$ = this.dService.disclosureDocument$;
	disclosureSettings$ = this.dService.disclosureSettings$;
	disclosureCrtSettings = this.dService.disclosureDocument$;
	mergeTags$ = this.mtService.mergeTags$;
	peopleAndDependents$ = this.peopleQuery.peopleAndDependentsFromCRTOnly$;
	adviceProcessId = this.query.getValue().adviceProcessId;
	adviceProcess$ = this.query.adviceProcess$;
	staffId = +this.userQuery.getValue().StaffID;
	clientId = this.route.snapshot.paramMap.get('clientId');
	groupName = this.query
		.getValue()
		.primaryClient?.groupName?.toString()
		?.toUpperCase();
	fileName = `${this.groupName} DISCLOSURE DOCUMENT`;
	adviceProcess = this.query.getValue().adviceProcess;
	sidebar = this.query.getValue().sidebars;

	@ViewChild('contentEditor') editor: WysiwygComponent;
	pdfUrlString: string;
	mergeTags: MergeTagState[] = [];

	fileUrl: string;
	isPdfLoaded: boolean;
	crtFileType: string;
	documentData: any;

	zoom$ = this.query.mortApPageCompleted$.pipe(
		map((x) =>
			x?.some((y) => y.includes('ZOOM'))
				? +x?.find((y) => y.includes('ZOOM'))?.substr(4)
				: 120
		)
	);
	
	isNotesCollapseSubject$ = this.crtNoteService.getIsNotesCollapse();

	constructor(
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private query: CrtMortgageQuery,
		private dService: DisclosureService,
		private mtService: MergeTagsService,
		private emailService: EmailService,
		private userQuery: UserQuery,
		private crtDocService: CrtDocumentService,
		private loggerService: LoggerService,
		private zone: NgZone,
		private businessConfigQuery: BusinessConfigQuery,
		private peopleQuery: PeopleEntitiesQuery,
		private peopleService: PeopleEntitiesService,
		private mApService: MortgageAdviceProcessService,
		private renderer: Renderer2,
		private crtNoteService: CrtMortgageNoteService,
	) {}

	ngOnInit(): void {
		this.mApService
			.updateMortApPageStarted(AdviceProcessPageCodes.Disclosure)
			.pipe(take(1))
			.subscribe();

		this.isCompLoading = true;
		this.dService
			.getDisclosureSettings()
			.pipe(
				finalize(() => {
					this.isCompLoading = false;
					this.prepData();
				})
			)
			.subscribe();
	}

	prepData() {
		let dataCrt;
		combineLatest([
			this.disclosureDocument$,
			this.disclosureSettings$,
			this.mergeTags$,
		])
			.pipe(
				tap(() => (this.isCompLoading = true)),
				filter(([crt, settings, mt]) => !!crt && !!settings && !!mt),
				take(1),
				tap(([crt, settings, mt]) => {
					this.mergeTags = mt;
					this.defaultSettings = settings;
					this.crtData = dataCrt = crt;
					this.isEnableEmailOption = settings?.isEnableEmailOption || false;
				}),
				mergeMap(([crt, settings]) => {
					return this.getContentFromDoc(crt, settings)
				}),
				mergeMap((content) => {
					return this.updateContent(content)
				}),
				concatMap((content) =>
					iif(
						() =>
							isNil(dataCrt?.cRTId) &&
							complement(isEmpty)(this.defaultSettings),
						this.addDisclosure(dataCrt, content),
						of(null)
					)
				),
				finalize(() => (this.isCompLoading = false)),
				take(1)
			)
			.subscribe();
	}

	// Create new Disclosure CRT
	addDisclosure(crtData, content) {
		let docFile;
		if (this.defaultSettings.templateType === 'UD') {
			return this.dService.getDisclosureDocumentFile(+this.defaultSettings.uploadTemplate)
				.pipe(
					map((doc) => {
						return objectUtil.mapPascalCaseToCamelCase(doc);
					}),
					tap((doc) => {
						docFile = doc;
					}),
					mergeMap((doc) => {
						return this.getFileFormat(doc.documentLink, doc.documentID)
					}),
					map((file) =>
						DisclosureMapper.mapApDocumentUpload({
							fileName: file.fileName,
							document: file.content,
							referenceId: +this.adviceProcessId,
						})
					),
					concatMap((file) => {
						return this.dService.newFileUploadDD(file);
					}),
					concatMap((id) => this.dService.getDisclosureDocumentFile(+id)),
					map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
					tap((res) => {
						if (res) {
							this.document = res;
							this.crtData.document = {
								referenceId: +res.documentID,
								value: docFile.fileName,
							};
						}
					}),
					map((res) => {
						const upsert = DisclosureMapper.mapCrtToUpsert({
							...crtData,
							document: {
								referenceId: +res.documentID,
								value: docFile.fileName,
							},
						})
						return upsert;
					}),
					concatMap((x) => {
						return this.dService.addNewDisclosure(x)
					}),
					tap((x) => (this.crtData.cRTId = x)),
					take(1)
				);
		} else {
			return of(content).pipe(
				concatMap((x) => convertUtil.convertToBase64(x)),
				map((x) =>
					DisclosureMapper.mapApDocumentUpload({
						fileName: this.defaultFileName,
						document: x,
						referenceId: +this.adviceProcessId,
					})
				),
				concatMap((file) => {
					return this.dService.newFileUploadDD(file);
				}),
				concatMap((id) => this.dService.getDisclosureDocumentFile(+id)),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				tap((res) => {
					if (res) {
						this.document = res;
						this.crtData.document = {
							referenceId: +res.documentID,
							value: this.defaultFileName,
						};
					}
				}),
				map((res) => {
					return DisclosureMapper.mapCrtToUpsert({
						...crtData,
						document: {
							referenceId: +res.documentID,
							value: this.defaultFileName,
						},
					})
				}),
				concatMap((x) => {
					return this.dService.addNewDisclosure(x);
				}),
				tap((x) => (this.crtData.cRTId = x)),
				take(1)
			);
		}
	}

	// Pull Disclosure Document Template from CRT or Settings (If no CRT yet)
	getContentFromDoc(crt, settings, forceRefresh = false) {
		return of(crt).pipe(
			map((x) => {
				if (forceRefresh) {
					return settings.templateType === 'UD' ? settings?.uploadTemplate : settings?.template;
				}

				return x?.document?.referenceId ? x?.document?.referenceId : settings.templateType === 'UD' ? settings?.uploadTemplate : settings?.template
			}),
			concatMap((x) => {
				return x
					? this.dService.getDisclosureDocumentFile(+x).pipe(
							map((y) => (y ? objectUtil.mapPascalCaseToCamelCase(y) : null)),
							take(1)
						)
					: of(null)
			}),
			tap((data) => {
				if (data) {
					this.crtFileType = data.fileExtension;
					this.fileUrl = data.documentLink;
					this.documentData = data;
				}
			}),
			concatMap((data) => {
				return iif(
					() => isNil(data),
					of(this.content),
					this.dService.getDocumentFromURL(data?.documentLink)
				)
			}),
			take(1)
		);
	}

	// Update content with Merge Tags
	updateContent(content) {
		return of(content).pipe(
			map((x) => getContentWithMergeTags(x, this.mergeTags)),
			tap((x) => (this.content = x)),
			take(1)
		);
	}

	getFileFormat(url, documentId) {
		return this.crtDocService.getDocumentLink(url, {
			responseType: 'blob'
		}).pipe(
			mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
			map((xx) => {
				const fileStr = `${((xx as string) ?? '')?.replace(/^data:(.*,)?/, '')}`;
		
				return {
					documentId: +documentId,
					fileName: `${this.fileName}.pdf`,
					generateContentCallback$: undefined,
					content: fileStr,
					pdfOptions: {
						orientation: '',
						format: ''
					},
					fileSize: +fileUtil.getFileSizeKb(fileStr),
					type: 'linked',
					fileUrl: this.fileUrl,
					allowDeleting: false
				};
			}),
			take(1)
		);
	}

	sendEmail() {
		this.dService
			.getDisclosureSettings(true)
			.pipe(
				mergeMap(() => iif(
					() => this.crtData && this.crtFileType === '.pdf' || (!this.crtData && this.defaultSettings && this.defaultSettings.templateType === 'UD'),
					this.getFileFormat(this.fileUrl, this.defaultSettings.uploadTemplate),
					of({
						fileName: `${this.fileName}.pdf`,
						generateContentCallback$: this.generatePdf$,
						content: this.crtDocService.contentForPdf(
							`<div class="disclosure-pdf-file">${this.content}</div>`
						),
						newPdfOptions: {
							...this.dService.getDdPdfOptions(),
							FileName: this.fileName,
						},
					})
				)),
				tap((attachments) => {
					const initState = {
						header: 'Email Client',
						enableDefaultBcc: true,
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.disclosureSettings$,
						peopleDropdown: this.peopleAndDependents$,
						attachments: [attachments],
						successMessage: 'Disclosure document has been emailed to',
						adviceProcess: this.adviceProcess,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.Mortgage,
						documentInfo: {
							documentType: ServicesCodes.Mortgage,
							type: DocumentTypesMOAT.Application,
							referenceId: this.query.getValue().adviceProcessId,
							customerId: this.query.getValue().primaryClient?.customerID,
						}
					};

					this.modalService.show(EmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				}),
				take(1)
			)
			.subscribe();
	}

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(data),
					this.peopleService.updatePeople(data)
				)
			)
		);
	};

	sendToRecipients = (data) => {
		return this.emailService.sendToRecipients(
			data,
			EmailTypes.DISCLOSURE,
			this.query.getValue()?.disclosureDocument?.cRTId,
			this.mergeTags$
		);
	};

	generatedPdf() {
		const content = this.crtDocService.contentForPdf(
			`<div class="disclosure-pdf-file">${this.content}</div>`
		);
		this.generatePdf$.next(content);
	}

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, this.fileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	// Download Document as PDF
	downloadDocument() {
		this.isCompLoading = true;
		if (this.crtData && this.crtFileType === '.pdf') {
				this.adviceProcess$.pipe(
					map((adviceProcess) => {
						const ap = adviceProcess?.documents?.find(
							(d) => d.field === MOATDocumentField.Disclosure
						)?.value;

						return ap;
					}),
					concatMap((document) => {
						// return iif(
						// 	() => !!document,
						// 	this.crtDocService.downloadLink(document ? document.documentID : this.documentData.documentID),
						// 	this.getFileFormat(this.documentData.documentLink, this.documentData.documentID)
						// )
						return this.getFileFormat(this.documentData.documentLink, this.documentData.documentID);
					}),
					tap((file: any) => {
						if (typeof file === 'object') {
							this.downloadFileUpload(file?.fileUrl, file?.fileName);
						} else {
							this.downloadFileUpload(file, `${this.fileName}.pdf`);
						}
					}),
					tap(() => {
						this.isCompLoading = false;
					}),
					take(1)
				).subscribe();
		} else {
			of(this.content)
				.pipe(
					map((content) => `<div class="disclosure-pdf-file">${content}</div>`),
					mergeMap((content) =>
						this.renderPdfDownload(content, {
							...this.dService.getDdPdfOptions(),
							FileName: this.fileName,
						})
					),
					tap(() => (this.isCompLoading = false)),
					take(1)
				)
				.subscribe();
		}
	}

	downloadFileUpload(fileUrl: any, fileName: string) {
		this.crtDocService.getDocumentLink(fileUrl, {
			responseType: 'blob'
		}).pipe(
			tap((file) => {
				const newFile = new File([file], fileName);
				const a = this.renderer.createElement('a');
				this.renderer.setStyle(a, 'display', 'none');
				const url = window.URL.createObjectURL(newFile);
				this.renderer.setAttribute(a, 'href', url);
				this.renderer.setAttribute(a, 'download', fileName);
				a.click();
				window.URL.revokeObjectURL(url);
			}),
			take(1)
		)
		.subscribe();
	}

	downloadFile(fileUrl: any, fileName: string) {
		const a = document.createElement('a');
		a.href = fileUrl;
		a.setAttribute('download', fileName);
		a.click();
	}

	// Save Document
	saveDocument() {
		const updatedData = `<div class="disclosure-pdf-file">${this.content}</div>`;
		this.adviceProcess$.pipe(take(1)).subscribe((x) => {
			const dDocument = x?.documents?.find(
				(d) => d.field === MOATDocumentField.Disclosure
			)?.value;

			if (isNil(dDocument) || isEmpty(dDocument)) {
				if (this.defaultSettings && this.defaultSettings.templateType === 'UD') {
					this.saveNewDocumentPdf().pipe(take(1)).subscribe();
				} else {
					this.saveNewDocument(updatedData).pipe(take(1)).subscribe();
				}
			} else {
				this.saveExistingDocument(x, updatedData);
			}
		});
	}

	// Saving of New Document
	// If Advice Process has no Disclosure Document yet
	saveNewDocument(data) {
		return of(data).pipe(
			tap(() => (this.isCompLoading = true)),
			mergeMap((content) =>
				this.crtDocService.downloadDocumentPDF(content, this.fileName)
			),
			mergeMap((content) => convertUtil.convertToBase64(content)),
			map((template) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: template,
				FileName: `${this.fileName}.pdf`,
				DocumentType: ServicesCodes.Mortgage,
				Type: AdviceProcessDocumentTypesMOAT.Disclosure,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap((x) =>
				this.mApService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesMOAT.Disclosure
				)
			),
			tap(() =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						'Successfully saved Disclosure Document.'
					)
				)
			),
			finalize(() => (this.isCompLoading = false)),
			take(1)
		);
	}

	saveNewDocumentPdf() {
		return of(this.defaultSettings).pipe(
			tap(() => (this.isCompLoading = true)),
			mergeMap(() => {
				return this.getFileFormat(this.documentData.documentLink, this.documentData.documentID)
			}),
			map((file) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: file.content,
				FileName: `${this.fileName}.pdf`,
				DocumentType: ServicesCodes.Mortgage,
				Type: AdviceProcessDocumentTypesMOAT.Disclosure,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap((x) => {
				return this.mApService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesMOAT.Disclosure
				);
			}),
			tap(() =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						'Successfully saved Disclosure Document.'
					)
				)
			),
			finalize(() => (this.isCompLoading = false)),
			take(1)
		)
	}

	// Saving of Existing Document
	// If Advice Process already has Disclosure Document uploaded
	saveExistingDocument(adviceProcess, data) {
		const document = adviceProcess?.documents?.find(
			(d) => d.field === MOATDocumentField.Disclosure
		)?.value;

		const confirm = new Observable((obs) => {
			if (this.crtData && this.crtFileType === '.pdf') {
				this.saveNewDocumentPdf().pipe(take(1)).subscribe();
			} else {
				this.saveNewDocument(data).pipe(take(1)).subscribe();
			}

			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: 'Save Document',
			message: `A document is already uploaded for Disclosure Document,`,
			subMessage: document?.fileName,
			secondaryMessage: `Do you want to replace this?`,
			confirm$: confirm,
			decline$: decline,
			isAcceptBtn: true,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Reload Template
	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.loadFromSettings();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Load template from settings
	loadFromSettings() {
		this.isCompLoading = true;
		let settings;

		of(true).pipe(
			concatMap(() => this.refetchMergeTags()),
			concatMap(() => this.dService.getDisclosureSettings(true)),
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			tap((x) => {
				settings = x;
			}),
			concatMap((x) => {
				if (!x) return of(null);

				if (x.templateType === 'FT') {
					if (x.template) {
						return this.getContentFromDoc(null, x, true)
					}
					return this.setUrlToNone()
				} else {
					if (x.uploadTemplate) {
						return this.getContentFromDoc(null, x, true)
					}
					return of('<p></p>')
				}
			}),
			mergeMap((x) => {
				return iif(() => settings && settings.templateType === 'FT', this.updateContent(x), of(null));
			}),
			finalize(() => (this.isCompLoading = false)),
			take(1)
		).subscribe();
	}

	setUrlToNone() {
		return of(this.fileUrl).pipe(
			tap(() => {
				this.fileUrl = '';
			}),
			take(1)
		)
	}

	refetchMergeTags = () =>
		this.mtService.getDefaultMergeTags(true).pipe(
			concatMap(() => this.mtService.getAdviserProviderMt()),
			withLatestFrom(this.mtService.mergeTags$),
			tap(([, x]) => (this.mergeTags = x))
		);

	saveFn = () => {
		const data = DisclosureMapper.mapCrtToUpsert(this.crtData);

		if (this.crtData && this.crtFileType === '.pdf') {
			return this.getFileFormat(this.fileUrl, this.documentData.documentID).pipe(
				tap(() => (this.isCompLoading = true)),
				map((doc) => {
					return {
						fileName: this.documentData.documentName,
						document: doc.content,
						documentId: this.crtData?.document?.referenceId
					}
				}),
				concatMap((x) =>
					iif(
						() => !!x?.documentId && x?.documentId > 0,
						this.dService.updateFileUploadDD(x),
						of(x)
					)
				),
				// concatMap(() => {
				// 	return this.dService.updateDisclosure({
				// 		...this.crtData,
				// 		document: {
				// 			referenceId: +this.documentData.documentID,
				// 			value: this.documentData.documentName
				// 		}
				// 	});
				// }),
				finalize(() => (this.isCompLoading = true))
			);
		} else {
			return of(true).pipe(
				withLatestFrom(of(data), of(this.content)),
				tap(() => (this.isCompLoading = true)),
				map(([, crt, content]) => ({ ...crt, content })),
				mergeMap((x) => convertUtil.convertToBase64(x?.content)),
				map((content) => ({
					fileName: DefaultFileNames.Disclosure,
					document: content,
					documentId: this.crtData?.document?.referenceId
				})),
				concatMap((x) =>
					iif(
						() => !!x?.documentId && x?.documentId > 0,
						this.dService.updateFileUploadDD(x),
						of(x)
					)
				),
				// concatMap(() => {
				// 	return this.dService.updateDisclosure({
				// 		...this.crtData,
				// 		document: {
				// 			referenceId: +this.documentData.documentID,
				// 			value: DefaultFileNames.Disclosure
				// 		}
				// 	});
				// }),
				finalize(() => (this.isCompLoading = true))
			);
		}
	};

	onPageRendered(event: Event) {
		this.isPdfLoaded = true;
	}

	zoomFn(zoom) {
		const update = (d: string[]) =>
			this.mApService.updateMortApPageCompleted(d).pipe(
				tap(() => this.mApService.setMortApPageCompletedState(d)),
				take(1)
			);

		of(zoom)
			.pipe(
				withLatestFrom(this.query.mortApPageCompleted$),
				concatMap(([z, list]) =>
					update([
						...(list ?? [])?.filter((l) => !l.includes('ZOOM')),
						`ZOOM${z}`,
					])
				),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
