<app-secondary-base-widget headerText="L&R Anniversaries" [widgetCode]="widgetCode">
  <app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
  <app-date-range ngProjectAs="input" [formControl]="dateControl"></app-date-range>
  <div class="position-relative h-100">
    <div class="container-fluid h-100 px-0">
      <div class="row pb-2 lr_anniversaries__row font-weight-bold">
        <div class="col-3 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          Start Date
        </div>
        <div class="col-5 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          Policy Owners
        </div>
        <div class="col text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          API
        </div>
      </div>
      <div class="row bg-white lr_anniversaries__table-body position-relative">
        <div class="col px-3 position-relative h-100">
          <div class="lr_anniversaries__table-body__wrapper">
            <div class="row lr_anniversaries__row" *ngFor="let row of data$ | async">
              <div class="col-3 py-2 body-col tap-text-color-h4">
                {{ row.PolicyStartDate | momentDatetime }}
              </div>
              <div class="col-5 py-2 font-weight-bold body-col tap-text-color-h4">
                <a [routerLink]="customerRoute(row.CustomerId, row.IsCompany)" class="tap-text-color-h4">
                  {{ row.Customer }}
                </a>
              </div>
              <div class="col py-2 font-weight-bold body-col tap-text-color-h4">
                {{ row.Premium }}
              </div>
              <div class="col-12">
                <div class="border-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-secondary-base-widget>
