import { TableColumn } from '@swimlane/ngx-datatable';
import { EmailStatusModel } from './state/email-status.model';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';

export type Metakey =
	'EmailType' |
	'CustomerName' |
	'DateSent' |
	'RecipientName' |
	'RecipientEmail' |
	'RecipientType' |
	'Status' |
	'OpenRate' |
	'ClickRate' |
	'BounceRate' |
	'EmailMode' |
	'EmailSettingsId';

export type controlType = 'display' | 'money' | 'number' | 'display-datetime';
/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */

type EnhancedTableColumn = (
	TableColumn &
	{
		/** Metakey of the column. Used for indexing ui state and requesting changes */
		metakey: Metakey,
		/** Property key in rows. */
		prop: keyof EmailStatusModel,
		/** Replaces `ascFn` and `descFn`. Provides sortable values. */
		sortValueGetter: ((field: any, choices?: ViewDisplayValue[]) => any),
		/** Dropdown choices used for fields in this column.
		 * To be filled up in Query file.
		 */
		choices?: ViewDisplayValue[],
		/** Dropdown choices used for fields in this column.
		 * To be filled up in Query file.
		 */
		choicesAsObject?: { [key: string]: string },
		/** Type of control.
		 * Determines which datatable field control to use.
		 * Carefully check that the type used is same here and in the template.
		 */
		controlType: controlType;
		/** indicator if column is required */
		isRequired?: boolean;
	});

export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'EmailType',
		prop: 'EmailType',
		name: 'Email Type',
		width: 209,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'CustomerName',
		prop: 'CustomerName',
		name: 'Customer Name',
		width: 159,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'DateSent',
		prop: 'DateSent',
		name: 'Date Sent',
		width: 155,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display-datetime'
	},
	{
		metakey: 'RecipientName',
		prop: 'RecipientName',
		name: 'Recipient Name',
		width: 159,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'RecipientEmail',
		prop: 'RecipientEmail',
		name: 'Recipient Email',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'RecipientType',
		prop: 'RecipientType',
		name: 'Type',
		width: 66,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'Status',
		prop: 'Status',
		name: 'Status',
		width: 109,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'OpenRate',
		prop: 'OpenRate',
		name: 'Open',
		width: 66,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'ClickRate',
		prop: 'ClickRate',
		name: 'Click',
		width: 66,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'BounceRate',
		prop: 'BounceRate',
		name: 'Bounce',
		width: 74,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
	{
		metakey: 'EmailMode',
		prop: 'EmailMode',
		name: 'Email Mode',
		width: 104,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f,
		controlType: 'display'
	},
];



/**
 * column configuration for link
 */
export const linkColumn: (TableColumn & { prop: 'link' }) = {
	prop: 'link',
	name: '',
	width: 119
};
