export class RoaOtherDetailsMapper {
	public static mapToUpsert(data) {
		return {
			...data,
			name: data?.name || '',
			email: data?.email || '',
			company: data?.company || '',
			customerID: !!data?.customerID ? +data?.customerID : 0,
		};
	}
}
