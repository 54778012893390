import { Injectable } from '@angular/core';

import { of, concat, from } from 'rxjs';
import {
  mergeMap,
  tap,
  switchMap,
  withLatestFrom,
  take,
  map,
  concatMap,
  catchError,
} from 'rxjs/operators';

import { ApiService } from '../../../../../core/base/api.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { ClientProfileUtilsService } from '../client-profie-utils.service';

import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { UserQuery } from '../../../../../domain/user/user.query';
import { ClientProfileQuery } from '../client-profile.query';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientProfileStore } from '../client-profile.store';
import { applyTransaction } from '@datorama/akita';
import { LrCustomerServiceState } from '../../../../../shared/models/services/lr-insurance/lr-insurance.model';
import { util } from '../../../../../core/util/util.service';
import produce from 'immer';
import { lrSortServiceUtil } from '../../../../../shared/services/service-utils/lr-insurance.util';
import { ClientProfileService } from '../client-profile.service';
import { BusinessConfigQuery } from '../../../../../domain/business-config/business-config.query';
import MomentUtil from '../../../../../util/moment.util';
import { NoteState } from '../../../../../shared/models/activity-timeline/activity-timeline.model';
import * as R from 'ramda';
import { NoteTypes } from 'src/app/shared/models/notes/note.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';

@Injectable()
export class LrInsuranceService extends ClientProfileUtilsService {
  constructor(
    protected dropdownValueQuery: DropdownValueQuery,
    protected blStaffsQuery: BLStaffsQuery,
    protected store: ClientProfileStore,
    protected query: ClientProfileQuery,
    protected api: ApiService,
    protected customerService: CustomerService,
    protected userQuery: UserQuery,
    protected profileService: ClientProfileService,
    protected businessConfigQuery: BusinessConfigQuery
  ) {
    super(
      store,
      query,
      dropdownValueQuery,
      blStaffsQuery,
      businessConfigQuery,
      userQuery
    );
  }

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  // Add LRInsurance
  createLrInsurance(req: LrCustomerServiceState, linkDocumentId: string) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      mergeMap((x) =>
        this.customerService.AddLrInsurance({
          ...x,
          serviceCode: ServicesCodes.LR,
          customerServiceID: 0,
        })
      ),
      tap((id) => (req.customerServiceID = +id)),
      switchMap((id) =>
        linkDocumentId
          ? this.customerService.UpsertDocument({
              CustomerServiceId: id,
              CustomerServiceType: ServicesCodes.LR,
              MetaKey: 'Document Link',
              MetaValue: linkDocumentId,
            })
          : of(id)
      ),
      switchMap((status) =>
        req.note
          ? this.customerService.AddNote({
              CustomerID: req.customerID,
              CustomerServiceID: req.customerServiceID,
              Notes: req.note,
              ActivityType: NoteTypes.LR,
              StaffName: `${this.userQuery.getValue().FirstName} ${
                this.userQuery.getValue().LastName
              }`,
            })
          : of(status)
      ),
      withLatestFrom(this.query.lrInsurance$),
      tap(([id, lrInsurance]) =>
        applyTransaction(() => {
          if (id) {
            if (req.note) {
              this.profileService.addToActivityTimelineNotes(
                +id,
                req.note,
                NoteTypes.LR,
                req.customerID,
                req.customerServiceID
              );
            }
            const lrState = produce(lrInsurance, (draft) => {
              const group = draft.lRs?.find(
                (lr) =>
                  lr.provider === req.provider &&
                  lr.policyNumber === req.policyNumber
              );

              if (!group) {
                // New Group with mortgage and note
                draft.lRs.push({
                  provider: req.provider,
                  policyNumber: req.policyNumber,
                  customerServices: [
                    {
                      ...req,
                      note: '',
                      notes: req.note
                        ? [
                            {
                              notesID: +id,
                              customerServiceID: req.customerServiceID,
                              notes: req.note,
                              isActive: true,
                              createDateTime: util.MomentNowNz(),
                              createdByStaffId:
                                this.userQuery.getValue().StaffID,
                              customerID: req.customerID,
                              staffName: `${
                                this.userQuery.getValue().FirstName
                              } ${this.userQuery.getValue().LastName}`,
                            },
                          ]
                        : [],
                    },
                  ],
                  status: req.policyStatus,
                });

                if (
                  req.policyStatus &&
                  (req.policyStatus?.toLowerCase() === 'inforce' ||
                    req.policyStatus?.toLowerCase() === 'inforce-pending')
                ) {
                  draft.totalInforceAPI = +draft.totalInforceAPI + +req.premium;
                }
              } else {
                // New LR
                draft.lRs?.forEach((y) => {
                  if (
                    y.provider === req.provider &&
                    y.policyNumber === req.policyNumber
                  ) {
                    y.customerServices.push({
                      ...req,
                      note: '',
                      notes: req.note
                        ? [
                            {
                              notesID: +id,
                              customerServiceID: req.customerServiceID,
                              notes: req.note,
                              isActive: true,
                              createDateTime: util.MomentNowNz(),
                              createdByStaffId:
                                this.userQuery.getValue().StaffID,
                              customerID: req.customerID,
                              staffName: `${
                                this.userQuery.getValue().FirstName
                              } ${this.userQuery.getValue().LastName}`,
                            },
                          ]
                        : null,
                    });
                  }
                });
              }
            });

            // Removes empty lrs
            lrState.lRs?.filter((m) => m.customerServices.length !== 0);
            this.store.setLrInsurance(lrSortServiceUtil(lrState));
          }
        })
      ),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      )
    );
  }

  /**
   * Upsert LrInsurance
   * @param req LrCustomerServiceState
   * @param provider provider
   * @param policynumber policynumber
   * return status
   */
  updateLrInsurance(req: LrCustomerServiceState) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      withLatestFrom(this.LRPolicyOwners$),
      map(([x, ac]) => {
        return {
          ...x,
          policyOwners: !!x.policyOwners
            ? JSON.stringify(
                JSON.parse(x.policyOwners)?.filter((y) =>
                  ac?.find((c) => c.value === y)
                )
              )
            : x.policyOwners,
        };
      }),
      mergeMap((x) =>
        this.customerService.UpdateLrInsurance({ ...x, serviceCode: ServicesCodes.LR })
      ),
      switchMap((status) =>
        req.note
          ? this.customerService.AddNote({
              CustomerID: req.customerID,
              CustomerServiceID: req.customerServiceID,
              Notes: req.note,
              ActivityType: NoteTypes.LR,
              StaffName: `${this.userQuery.getValue().FirstName} ${
                this.userQuery.getValue().LastName
              }`,
            })
          : of(status)
      ),
      withLatestFrom(this.query.lrInsurance$),
      tap(([x, lrInsurance]) =>
        applyTransaction(() => {
          if (x && req.note) {
            this.profileService.addToActivityTimelineNotes(
              +x,
              req.note,
              NoteTypes.LR,
              req.customerID,
              req.customerServiceID
            );
          }
          const lrState = produce(lrInsurance, (draft) => {
            const group = draft.lRs?.find(
              (lr) =>
                lr.provider === req.provider &&
                lr.policyNumber === req.policyNumber
            );

            if (!group) {
              draft.lRs.push({
                provider: req.provider,
                policyNumber: req.policyNumber,
                customerServices: [
                  {
                    ...req,
                    note: '',
                    notes: req.note
                      ? [
                          {
                            notesID: +x,
                            customerServiceID: req.customerServiceID,
                            notes: req.note,
                            isActive: true,
                            createDateTime: MomentUtil.formatToServerDatetime(
                              MomentUtil.createMomentNz()
                            ),
                            createdByStaffId: this.userQuery.getValue().StaffID,
                            customerID: req.customerID,
                            staffName: `${
                              this.userQuery.getValue().FirstName
                            } ${this.userQuery.getValue().LastName}`,
                          },
                        ]
                      : req.notes && req.notes.length > 0
                      ? req.notes
                      : [],
                  },
                ],
                status: req.policyStatus,
              });
            } else {
              draft.lRs?.forEach((lr) => {
                if (
                  lr.provider === req.provider &&
                  lr.policyNumber === req.policyNumber
                ) {
                  const service = lr.customerServices?.find(
                    (cs) => cs.customerServiceID === req.customerServiceID
                  );

                  if (!service) {
                    // push on current service
                    lr.customerServices.push(req);
                  } else {
                    // Update and add note
                    lr.customerServices?.forEach((cs2, i) => {
                      if (cs2.customerServiceID === req.customerServiceID) {
                        lr.customerServices[i] = req;

                        if (req.note) {
                          lr.customerServices[i].notes?.unshift({
                            notesID: +x,
                            customerServiceID: req.customerServiceID,
                            notes: req.note,
                            isActive: true,
                            createDateTime: MomentUtil.formatToServerDatetime(
                              MomentUtil.createMomentNz()
                            ),
                            createdByStaffId: this.userQuery.getValue().StaffID,
                            customerID: req.customerID,
                            staffName: `${
                              this.userQuery.getValue().FirstName
                            } ${this.userQuery.getValue().LastName}`,
                          });
                        } else {
                          lr.customerServices[i].notes = cs2.notes;
                        }

                        lr.customerServices[i].note = '';
                      }
                    });
                  }
                }
              });
            }
            // Removes services that dont belong to the group
            draft.lRs = draft.lRs?.map((lr) => {
              return lr.provider !== req.provider ||
                lr.policyNumber !== req.policyNumber
                ? {
                    ...lr,
                    customerServices: lr.customerServices?.filter(
                      (cs) => cs.customerServiceID !== req.customerServiceID
                    ),
                  }
                : lr;
            });

            // Removes empty Group
            draft.lRs = draft.lRs?.filter(
              (lr) => lr.customerServices.length > 0
            );
          });

          this.store.setLrInsurance(lrSortServiceUtil(lrState));
        })
      ),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      )
    );
  }

  deleteLrInsurance(
    req: LrCustomerServiceState,
    provider: string,
    policyNumber: string
  ) {
    return of(req).pipe(
      mergeMap((x) =>
        this.customerService.DeactivateService(+x.customerServiceID)
      ),
      withLatestFrom(this.query.lrInsurance$),
      tap(([x, lrState]) => {
        applyTransaction(() => {
          if (x) {
            const state = produce(lrState, (draft) => {
              draft.lRs = draft.lRs?.map((lr) =>
                lr.provider === provider && lr.policyNumber === policyNumber
                  ? {
                      ...lr,
                      customerServices: lr.customerServices?.filter(
                        (cs) => cs.customerServiceID !== req.customerServiceID
                      ),
                    }
                  : lr
              );

              // Removes empty lrs
              draft.lRs = draft.lRs?.filter(
                (lr) => lr.customerServices.length !== 0
              );
            });

            this.store.setLrInsurance(lrSortServiceUtil(state));
          }
        });
      }),
      tap(() =>
        applyTransaction(() => {
          const activityTimeline = this.query.getValue().activityTimeline;
          const notesState = activityTimeline?.notes?.filter(
            (n) => n.customerServiceID !== req.customerServiceID
          );
          this.store.setActivityTimeline({
            activities: activityTimeline?.activities,
            notes: notesState,
          });
        })
      ),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  archiveLrInsurance(
    data: LrCustomerServiceState,
    provider: string,
    policyNumber: string,
    isArchive: boolean
  ) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], data));
    return of(newReq).pipe(
      withLatestFrom(this.LRPolicyOwners$),
      map(([x, ac]) => {
        return {
          ...x,
          policyOwners: !!x.policyOwners
            ? JSON.stringify(
                JSON.parse(x.policyOwners)?.filter((y) =>
                  ac?.find((c) => c.value === y)
                )
              )
            : x.policyOwners,
        };
      }),
      mergeMap((x) =>
        this.customerService.UpdateLrInsurance({
          ...x,
          isActive: isArchive ? 2 : 1,
          serviceCode: ServicesCodes.LR,
        })
      ),
      withLatestFrom(this.query.lrInsurance$),
      tap(([x, lrState]) =>
        applyTransaction(() => {
          if (x) {
            const state = produce(lrState, (draft) => {
              draft.lRs = draft.lRs?.map((lr) =>
                lr.provider === provider && lr.policyNumber === policyNumber
                  ? // Check if LR exist in this group, if yes then update
                    lr.customerServices?.find(
                      (cs) => cs.customerServiceID === newReq.customerServiceID
                    )
                    ? // If existing
                      {
                        // Update LR
                        ...lr,
                        customerServices: lr.customerServices?.map((cs) =>
                          cs.customerServiceID === newReq.customerServiceID
                            ? {
                                ...newReq,
                                isActive: isArchive ? 2 : 1,
                                notes: data.notes,
                              }
                            : cs
                        ),
                      }
                    : lr
                  : lr
              );
            });

            this.store.setLrInsurance(lrSortServiceUtil(state));
          }
        })
      ),
      tap(() =>
        this.profileService
          .getCriterias(newReq.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  deleteLrInsuranceNote(id: number, data, provider, policyNumber) {
    return of(data).pipe(
      mergeMap(() => this.profileService.deleteActivityNote(id)),
      withLatestFrom(this.query.lrInsurance$),
      tap(([x, lrState]) =>
        applyTransaction(() => {
          if (x) {
            const state = produce(lrState, (draft) => {
              draft.lRs = draft.lRs?.map((lr) =>
                lr.provider === provider && lr.policyNumber === policyNumber
                  ? // Check if LR exist in this group, if yes then update
                    lr.customerServices?.find(
                      (cs) => cs.customerServiceID === data.customerServiceID
                    )
                    ? // If existing
                      {
                        // Update LR
                        ...lr,
                        customerServices: lr.customerServices?.map((cs) =>
                          cs.customerServiceID === data.customerServiceID
                            ? {
                                ...data,
                                notes: cs.notes?.filter((n) => n.notesID !== id),
                              }
                            : cs
                        ),
                      }
                    : lr
                  : lr
              );
            });

            this.store.setLrInsurance(state);
          }
        })
      ),
      catchError(() => of(''))
    );
  }

  syncNote(notes: NoteState) {
    return of(notes).pipe(
      withLatestFrom(this.query.lrInsurance$),
      tap(([note, lrState]) =>
        applyTransaction(() => {
          const state = produce(lrState, (draft) => {
            draft.lRs = draft.lRs?.map((lr) =>
              lr.customerServices?.find(
                (cs) => cs.customerServiceID === note.customerServiceID
              )
                ? {
                    ...lr,
                    customerServices: lr.customerServices?.map((cs) =>
                      cs.customerServiceID === note.customerServiceID
                        ? {
                            ...cs,
                            notes: cs.notes?.filter(
                              (n) => note.notesID !== n.notesID
                            ),
                          }
                        : cs
                    ),
                  }
                : lr
            );
          });
          this.store.setLrInsurance(lrSortServiceUtil(state));
        })
      )
    );
  }

  upsertLRLinkDocument(id, provider, policyNumber) {
    const lrState = this.query.getValue().lrInsurance;

    const group = lrState.lRs?.find(
      (lr) => lr.provider === provider && lr.policyNumber === policyNumber
    );

    const ids = group.customerServices?.map(
      ({ customerServiceID }) => customerServiceID
    );

    const first$ = of({
      CustomerServiceId: ids[0],
      CustomerServiceType: ServicesCodes.LR,
      MetaKey: 'Document Link',
      MetaValue: id,
    });

    return first$.pipe(
      mergeMap(() =>
        concat(
          first$,
          from(ids).pipe(
            map((i) => {
              return {
                CustomerServiceId: i,
                CustomerServiceType: ServicesCodes.LR,
                MetaKey: 'Document Link',
                MetaValue: id,
              };
            }),
            concatMap((req2) =>
              this.customerService.UpsertDocument(req2).pipe(
                tap((x) => {
                  applyTransaction(() => {
                    if (x) {
                      const state = produce(lrState, (draft) => {
                        draft.lRs = draft.lRs?.map((lr) =>
                          lr.provider === provider &&
                          lr.policyNumber === policyNumber
                            ? { ...lr, linkDocument: id }
                            : lr
                        );
                      });

                      this.store.setLrInsurance(lrSortServiceUtil(state));
                    }
                  });
                })
              )
            )
          )
        )
      ),
      catchError(() => of(''))
    );
  }

  addNote(req: LrCustomerServiceState) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      switchMap(() =>
        this.customerService.AddNote({
          CustomerID: req.customerID,
          CustomerServiceID: req.customerServiceID,
          Notes: req.note,
          ActivityType: NoteTypes.LR,
          StaffName: `${this.userQuery.getValue().FirstName} ${
            this.userQuery.getValue().LastName
          }`,
        })
      ),
      withLatestFrom(this.query.lrInsurance$),
      tap(([x, lrInsurance]) =>
        applyTransaction(() => {
          if (x && req.note) {
            this.profileService.addToActivityTimelineNotes(
              +x,
              req.note,
              NoteTypes.LR,
              req.customerID,
              req.customerServiceID
            );
          }
          const lrState = produce(lrInsurance, (draft) => {
            const group = draft.lRs?.find(
              (lr) =>
                lr.provider === req.provider &&
                lr.policyNumber === req.policyNumber
            );

            if (!group) {
              draft.lRs.push({
                provider: req.provider,
                policyNumber: req.policyNumber,
                customerServices: [
                  {
                    ...req,
                    note: '',
                    notes: req.note
                      ? [
                          {
                            notesID: +x,
                            customerServiceID: req.customerServiceID,
                            notes: req.note,
                            isActive: true,
                            createDateTime: MomentUtil.formatToServerDatetime(
                              MomentUtil.createMomentNz()
                            ),
                            createdByStaffId: this.userQuery.getValue().StaffID,
                            customerID: req.customerID,
                            staffName: `${
                              this.userQuery.getValue().FirstName
                            } ${this.userQuery.getValue().LastName}`,
                          },
                        ]
                      : req.notes && req.notes.length > 0
                      ? req.notes
                      : [],
                  },
                ],
                status: req.policyStatus,
              });
            } else {
              draft.lRs?.forEach((lr) => {
                if (
                  lr.provider === req.provider &&
                  lr.policyNumber === req.policyNumber
                ) {
                  const service = lr.customerServices?.find(
                    (cs) => cs.customerServiceID === req.customerServiceID
                  );

                  if (!service) {
                    // push on current service
                    lr.customerServices.push(req);
                  } else {
                    // Update and add note
                    lr.customerServices?.forEach((cs2, i) => {
                      if (cs2.customerServiceID === req.customerServiceID) {
                        lr.customerServices[i] = req;

                        if (req.note) {
                          lr.customerServices[i].notes?.unshift({
                            notesID: +x,
                            customerServiceID: req.customerServiceID,
                            notes: req.note,
                            isActive: true,
                            createDateTime: MomentUtil.formatToServerDatetime(
                              MomentUtil.createMomentNz()
                            ),
                            createdByStaffId: this.userQuery.getValue().StaffID,
                            customerID: req.customerID,
                            staffName: `${
                              this.userQuery.getValue().FirstName
                            } ${this.userQuery.getValue().LastName}`,
                          });
                        } else {
                          lr.customerServices[i].notes = cs2.notes;
                        }

                        lr.customerServices[i].note = '';
                      }
                    });
                  }
                }
              });
            }
            // Removes services that dont belong to the group
            draft.lRs = draft.lRs?.map((lr) => {
              return lr.provider !== req.provider ||
                lr.policyNumber !== req.policyNumber
                ? {
                    ...lr,
                    customerServices: lr.customerServices?.filter(
                      (cs) => cs.customerServiceID !== req.customerServiceID
                    ),
                  }
                : lr;
            });

            // Removes empty Group
            draft.lRs = draft.lRs?.filter(
              (lr) => lr.customerServices.length > 0
            );
          });
          this.store.setLrInsurance(lrState);
        })
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      )
    );
  }
}
