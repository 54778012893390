import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	AdviceProcessPageCodes,
	AdviceProcessPageNamesByCode,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { AssetsLiabilitiesComponent } from './assets-liabilities/assets-liabilities.component';
import { ClientAcceptanceComponent } from './client-acceptance/client-acceptance.component';
import { ClientReviewTemplateComponent } from './client-review-template.component';
import { ClientReviewTemplateResolver } from './client-review-template.resolver';
import { CurrentInsuranceComponent } from './current-insurance/current-insurance.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { FgInsuranceComponent } from './fg-insurance/fg-insurance.component';
import { FinalStructureComponent } from './final-structure/final-structure.component';
import { AdviceProcessEndedGuard } from './guards/advice-process-ended.guard';
import { AuthToProceedGuard } from './guards/auth-to-proceed.guard';
import { CriticalIllnessGuard } from './guards/critical-illness.guard';
import { DisabilityGuard } from './guards/disability.guard';
import { FinalStructureGuard } from './guards/final-structure.guard';
import { LifeGuard } from './guards/life.guard';
import { MedicalGuard } from './guards/medical.guard';
import { TpdGuard } from './guards/tpd.guard';
import { HistoryComponent } from './history/history.component';
import { IncomeBudgetComponent } from './income-budget/income-budget.component';
import { IncomeBudgetGuard } from './income-budget/income-budget.guard';
import { IntroComponent } from './intro/intro.component';
import { PeopleComponent } from './people/people.component';
import { CriticalIllnessComponent } from './risk-analysis/critical-illness/critical-illness.component';
import { DisabilityComponent } from './risk-analysis/disability/disability.component';
import { GoalsComponent } from './risk-analysis/goals/goals.component';
import { LifeComponent } from './risk-analysis/life/life.component';
import { RiskMedicalComponent } from './risk-analysis/medical/medical.component';
import { RiskProfileComponent } from './risk-analysis/risk-profile/risk-profile.component';
import { TpdComponent } from './risk-analysis/tpd/tpd.component';
import { ScopeOfServicesComponent } from './scope-of-services/scope-of-services.component';
import { StatementOfAdviceComponent } from './statement-of-advice/statement-of-advice.component';

const routes: Routes = [
	{
		path: '',
		component: ClientReviewTemplateComponent,
		resolve: {
			data: ClientReviewTemplateResolver,
		},
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'intro',
			},
			{
				path: 'intro',
				component: IntroComponent,
				canActivate: [AdviceProcessEndedGuard],
				data: {
					type: AdviceProcessPageCodes.Introduction,
					title:
						AdviceProcessPageNamesByCode[AdviceProcessPageCodes.Introduction],
				},
			},
			{
				path: 'disclosure',
				component: DisclosureComponent,
				canActivate: [AdviceProcessEndedGuard],
				data: {
					type: AdviceProcessPageCodes.Disclosure,
					title:
						AdviceProcessPageNamesByCode[AdviceProcessPageCodes.Disclosure],
				},
			},
			{
				path: 'scope-of-service',
				component: ScopeOfServicesComponent,
				canActivate: [AdviceProcessEndedGuard],
				data: {
					type: AdviceProcessPageCodes.SOS,
					title: AdviceProcessPageNamesByCode[AdviceProcessPageCodes.SOS],
				},
			},
			{
				path: 'fact-find',
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'people',
					},
					{
						path: 'people',
						component: PeopleComponent,
						data: {
							type: AdviceProcessPageCodes.People,
							title:
								AdviceProcessPageNamesByCode[AdviceProcessPageCodes.People],
						},
					},
					{
						path: 'assests-and-liabilities',
						component: AssetsLiabilitiesComponent,
						data: {
							type: AdviceProcessPageCodes.AssetsLiabilities,
							title:
								AdviceProcessPageNamesByCode[
									AdviceProcessPageCodes.AssetsLiabilities
								],
						},
					},
					{
						path: 'income-and-budget',
						component: IncomeBudgetComponent,
						canDeactivate: [IncomeBudgetGuard],
						data: {
							type: AdviceProcessPageCodes.IncomeExpenses,
							title:
								AdviceProcessPageNamesByCode[
									AdviceProcessPageCodes.IncomeExpenses
								],
						},
					},
					{
						path: 'medical-history',
						component: HistoryComponent,
						data: {
							type: AdviceProcessPageCodes.MedicalHistory,
							title:
								AdviceProcessPageNamesByCode[
									AdviceProcessPageCodes.MedicalHistory
								],
						},
					},
					{
						path: 'fg-insurance',
						component: FgInsuranceComponent,
						data: {
							type: AdviceProcessPageCodes.FG,
							title: 'F&G Insurance',
						},
					},
					{
						path: 'current-insurance',
						component: CurrentInsuranceComponent,
						data: {
							type: AdviceProcessPageCodes.CurrentInsurance,
							title: 'Current Insurance Cover',
						},
					},
				],
			},
			{
				path: 'risk-analysis',
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'goals',
					},
					{
						path: 'goals',
						component: GoalsComponent,
						data: {
							type: AdviceProcessPageCodes.Goals,
							title: 'Lifestyle & Family Goals',
						},
					},
					{
						path: 'life',
						component: LifeComponent,
						canActivate: [LifeGuard],
						data: {
							type: AdviceProcessPageCodes.Life,
							title: 'Event: Premature death',
						},
					},
					{
						path: 'disability',
						component: DisabilityComponent,
						canActivate: [DisabilityGuard],
						data: {
							type: AdviceProcessPageCodes.Disability,
							title: 'Event - Temporary disability due to sickness or injury',
						},
					},
					{
						path: 'tpd',
						component: TpdComponent,
						canActivate: [TpdGuard],
						data: {
							type: AdviceProcessPageCodes.TPD,
							title: 'Event: Total and Permanent Disability',
						},
					},
					{
						path: 'critical-illness',
						component: CriticalIllnessComponent,
						canActivate: [CriticalIllnessGuard],
						data: {
							type: AdviceProcessPageCodes.CriticalIllnness,
							title:
								'Event: Critical Illness - (Incl Heart Attack / Cancer / Stroke)',
						},
					},
					{
						path: 'medical',
						component: RiskMedicalComponent,
						canActivate: [MedicalGuard],
						data: {
							type: AdviceProcessPageCodes.Medical,
							title: 'Medical Insurance / Health Care',
						},
					},
					{
						path: 'risk-profile',
						component: RiskProfileComponent,
						data: {
							type: AdviceProcessPageCodes.RiskProfile,
							title: 'Personal Risk Profile',
						},
					},
				],
			},
			{
				path: 'declaration',
				component: DeclarationComponent,
				data: {
					type: AdviceProcessPageCodes.Declaration,
					title: 'Declaration Document',
				},
			},
			{
				path: 'statement-of-advice',
				component: StatementOfAdviceComponent,
				// canActivate: [StatementOfAdviceGuard],
				data: {
					type: AdviceProcessPageCodes.SOA,
					title: AdviceProcessPageNamesByCode[AdviceProcessPageCodes.SOA],
				},
			},
			{
				path: 'client-acceptance',
				component: ClientAcceptanceComponent,
				canActivate: [AuthToProceedGuard],
				data: {
					type: AdviceProcessPageCodes.AuthorityToProceed,
					title:
						AdviceProcessPageNamesByCode[
							AdviceProcessPageCodes.AuthorityToProceed
						],
				},
			},
			{
				path: 'final-structure',
				component: FinalStructureComponent,
				canActivate: [FinalStructureGuard],
				data: {
					type: AdviceProcessPageCodes.FinalStructure,
					title:
						AdviceProcessPageNamesByCode[AdviceProcessPageCodes.FinalStructure],
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ClientReviewTemplateRoutingModule {}
