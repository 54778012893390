<div
	class="align-self-center position-fixed w-100"
	style="
		bottom: 0;
		z-index: 2000;
		max-width: 1140px;
		margin: 0 auto;
		left: 0%;
		right: 0%;
	"
>
	<div class="container">
		<div
			class="row alert"
			[ngClass]="{
				'alert-danger': err.state == -1,
				'alert-warning': err.state == 0,
				'alert-success': err.state == 1
			}"
			*ngFor="let err of logs"
		>
			<div class="col-11 d-flex align-items-center">
				<div>
					{{ err.message }}
				</div>
			</div>
			<div class="col-1 d-flex align-items-center justify-content-end">
				<button
					class="btn btn-link font font-weight-bold p-0"
					style="font-size: 20px"
					(click)="remove(err)"
				>
					&times;
				</button>
			</div>
		</div>
	</div>
</div>
