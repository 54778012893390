import { Component, OnInit } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Router,
} from '@angular/router';
import { UserQuery } from '@domain/user/user.query';
import { AnnouncementPopupService } from '@modules/annoucements/shared/announcement-popup/announcement-popup.service';
import { ConfigService } from '../../core/config/config.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	menuReworkFeature$ = this.businessConfigQuery.menuReworkFeature$;

	constructor(
		private userQuery: UserQuery,
		private route: ActivatedRoute,
		private router: Router,
		private cs: ConfigService,
		private businessConfigQuery: BusinessConfigQuery,
		private announcementPopupService: AnnouncementPopupService
	) {}
	hasSidenav = true;
	isOnTapLevelView = false;
	ngOnInit() {
		// TODO: REFACTOR TO SERVICE
		// this.router.events.filter(x => x instanceof NavigationEnd).forEach(x => {
		this.router.events?.forEach((x) => {
			// let data = this.router.routerState.snapshot.root.children[0].data;
			// let data2 = this.router.routerState.snapshot.root.firstChild.firstChild.data;
			const data = getChildTail(this.router.routerState.snapshot.root).data;
			// this.isOnTapLevelView = data.isTapLevelView;
			this.hasSidenav = !data.hasNoSideNav;
		});
		this.cs.IsViewTapLevel.subscribe((x) => (this.isOnTapLevelView = x));
	}
}
// TODO: REFACTOR TO SERVICE
function getChildTail(route: ActivatedRouteSnapshot) {
	if (route.firstChild) {
		return getChildTail(route.firstChild);
	} else {
		return route;
	}
}
