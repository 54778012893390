import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { UserDocumentModalComponent } from '@shared/modal/user-document-modal/user-document-modal.component';
import {
	BlStaffOnboardingDocuments,
	BlStaffOnboardingDocumentsOther,
	BlStaffSettingTypes,
	BlStaffSettingsModel,
	BlStaffStandardOnboardingDocuments,
} from '@shared/models/_general/bl-staff.model';
import { DocumentTypes } from '@shared/models/documents/document.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';

@Component({
	selector: 'app-user-onboarding-documents',
	templateUrl: './user-onboarding-documents.component.html',
	styleUrls: ['./user-onboarding-documents.component.scss'],
})
export class UserOnboardingDocumentsComponent implements OnInit, OnChanges {
	@Input() staff: BlStaffViewmodel;
	@Input() documents: BlStaffSettingsModel[];
	@Input() upsertStaffDocumentFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() removeStaffDocumentFn$: (
		staffId: number,
		settingsId: number
	) => Observable<any> = (data) => of(data);
	@Input() downloadDocumentFn$: (ac: {
		documentId: number;
		fileName: string;
	}) => Observable<any> = (ac) => of(ac);
	@Input() getUserDocumentsFn$: (data: number) => Observable<any> = (data) =>
		of(data);

	standardDocuments: BlStaffSettingsModel[];
	otherDocuments: BlStaffSettingsModel[];
	otherD = BlStaffOnboardingDocumentsOther;
	isLoading = false;

	constructor(
		private modalService: BsModalService,
		private routeService: RouteService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.setDocuments(this.documents);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.documents) {
			this.setDocuments(this.documents);
		}
	}

	setDocuments(data: BlStaffSettingsModel[]) {
		this.standardDocuments = BlStaffStandardOnboardingDocuments?.map((x) => {
			const q = data?.find(
				(d) =>
					d?.DocumentName === x?.name &&
					d?.SettingsCode === BlStaffSettingTypes.OnBoardingDocuments
			);
			return {
				...q,
				DocumentId: x?.id,
				DocumentName: q?.DocumentName || x?.name,
				SettingsCode: q?.SettingsCode || x?.type,
			};
		});

		this.otherDocuments = data
			?.filter(
				(x) => x?.SettingsCode === BlStaffSettingTypes.OtherOnBoardingDocuments
			)
			?.map((x) => ({
				...x,
				DocumentId: BlStaffSettingTypes.OtherOnBoardingDocuments,
			}));
	}

	addDocument(data, isOther?: boolean) {
		const id = isOther ? this.otherD?.id : data?.DocumentId;
		const docType = BlStaffOnboardingDocuments?.find((x) => x.id === id)?.type;
		const initialState = {
			title: !!isOther ? '+ Add Onboarding Document' : 'Link Document',
			data,
			staffId: this.staff?.StaffID,
			type: docType,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Upload',
			isSingleUpload: true,
			showDocumentName: !!isOther,
			documentNameText: 'Onboarding Document Name',
			uploadOnly: false,
			upsertStaffDocumentFn$: this.upsertStaffDocumentFn$,
			getUserDocumentsFn$: this.getUserDocumentsFn$,
		};

		this.modalService.show(UserDocumentModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-md user-documents-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	}

	replaceDocument(data: BlStaffSettingsModel, isOther?: boolean) {
		const id = isOther ? this.otherD?.id : data?.DocumentId;
		const docType = BlStaffOnboardingDocuments?.find((x) => x.id === id)?.type;
		const initialState = {
			title: 'Replace Document',
			data,
			staffId: this.staff?.StaffID,
			type: docType,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Upload',
			isSingleUpload: true,
			showDocumentName: !!isOther,
			documentNameText: 'Onboarding Document Name',
			uploadOnly: false,
			upsertStaffDocumentFn$: this.upsertStaffDocumentFn$,
			getUserDocumentsFn$: this.getUserDocumentsFn$,
		};

		this.modalService.show(UserDocumentModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-md user-documents-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	}

	viewDocument(data) {
		return of(data)
			.pipe(
				map((res) => ({
					documentId: res?.Documents?.[0].ReferenceId,
					name: res?.Documents?.[0]?.Value || '',
				})),
				mergeMap((res) => {
					const ext = res?.name?.split('.')?.reverse()?.[0];
					if (!!ext && !!res?.documentId) {
						if (ext?.toLowerCase() === 'pdf') {
							return of(res).pipe(
								tap(() => {
									const pdfUrl = this.router.serializeUrl(
										this.router.createUrlTree(this.routeService.viewPdf(res))
									);
									window.open(pdfUrl, '_blank');
								}),
								take(1)
							);
						} else {
							return this.downloadDocumentFn$({
								documentId: res?.documentId,
								fileName: res?.name,
							});
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}

	removeDocument(data) {
		if (!!this.isLoading) {
			return;
		}
		this.isLoading = true;
		return this.removeStaffDocumentFn$(
			this.staff?.StaffID,
			data?.StaffSettingsId
		)
			.pipe(
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	isEllipsisActive(e: HTMLElement): boolean {
    return e ? (e.offsetWidth < e.scrollWidth) : false;
  }
}
