<div class="row tap-container soa-settings pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Record of Advice - Template">
					<app-soa-document-template
						[soaData]="data"
						[template]="template"
						[mergeTags$]="mergeTags$"
					>
					</app-soa-document-template>
				</tab>
			</ng-container>

			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Email Settings">
					<app-moat-soa-email-settings
						[soaData]="data"
						[mergeTags$]="mergeTags$"
						[emailSettings]="statementOfAdviceEmailSettings$ | async"
					></app-moat-soa-email-settings>
				</tab>
			</ng-container>

			<ng-container
				*ngIf="['SO', 'BO', 'SM']?.includes(userSecurityGroup$ | async)"
			>
				<tab heading="Merge Tags Wordings">
					<app-merge-tags-wording [soaData]="data"></app-merge-tags-wording>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
