<div class="row tap-container pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<tab heading="Email Settings">
				<app-scope-of-service-email-settings
					[emailSettings]="emailSettings$ | async"
					[mergeTags$]="mergeTags$"
				></app-scope-of-service-email-settings>
			</tab>
			<tab heading="Services">
				<app-sos-service-settings></app-sos-service-settings>
			</tab>
			<tab heading="Scope of Service PDF">
				<app-sos-template-settings></app-sos-template-settings>
			</tab>
		</tabset>
	</div>
</div>
