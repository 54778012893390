import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { UserQuery } from '@domain/user/user.query';
import { CustomerTypes } from '@shared/models/_general/client.model';
import { util } from '@util/util';
import { either, isEmpty, isNil } from 'ramda';
import { Observable, forkJoin, of } from 'rxjs';
import { finalize, mapTo, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
	AdviceProcessState,
	GetAllAdviserReworkCodesPascal,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { Investment } from 'src/app/shared/models/services/investments/investments.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { ActivityTimeline } from '../../../../shared/models/activity-timeline/activity-timeline.model';
import { PrimaryClient } from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { CurrentActivityCriteria } from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { FgInsurance } from '../../../../shared/models/services/fg-insurance/fg-provider-group.model';
import { Kiwisaver } from '../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { LrInsurance } from '../../../../shared/models/services/lr-insurance/lr-provider-group.model';
import { Mortgage } from '../../../../shared/models/services/mortgage/mortgage-group.model';
import { PropertyAsset } from '../../../../shared/models/services/property-asset/property-asset-group.model';
import { CrtMergeTagsService } from '../../client-review-template/states/merge-tags/crt-merge-tags.service';
import { ClientProfileService } from '../states/client-profile.service';

const dropdownCodes: string[] = [
	// primary form
	'PCT',
	'PCE',
	'PCPC',
	'PCLE',
	'PCLT',
	'PCR',
	'LS',
	'LT',
	'CI',
	'SAS',
	// secondary individual
	'SCR',
	// secondary company
	// secondary trust
	'SCTT',
	// professional contact
	'CT',
	'PT',
	// l&r
	'LRPS',
	'LRPF',
	'LRCS',
	'LRRS',
	'LRP',
	'LRPT',
	'LRPD',
	'LRCC',
	'LRPR',
	// mortgage
	'MP',
	'MS',
	'MT',
	'MFPE',
	'MRF',
	'MLT',
	'MRS',
	// security
	'SV1',
	'SV2',
	'ST',
	'SU',
	'STI',
	'SS',
	'PAT',
	'CLS',
	'APCRTL',
	'APCRTF',
	// f&g
	'FGS',
	'FGCS',
	'FGAS',
	'FGI',
	'FGPT',
	'FGPW',
	'FGPL',
	'FGPM',
	'FGF',
	'FGPCR',
	// kiwisaver
	'KP',
	'KS',
	'KRS',
	// Investment
	'IP',
	'IS',
	'KFT',
	'KO',
	'KT',
	'IT',
	'KPIRR',
	'IPIRR',
	'IRS',
	// activity
	'AT',
	'AM',
	// Advice Process
	'AP',
	'APCRTAS',
	'APCRTAT',
	'APCRTCT',
	'APCRTCO',
	'APCRTQI1',
	'APCRTQI6',
	'APCRTQCLR7',
	'APCRTA',
	'APCRTTIR',
	'APCRTMS',
	'APCRTRL',
	'APCRTFCC',
	'APCRTYNNA',
	'APCRTQIK6',
	'CARQICAR1',
	'APCRTQIK15',
	'CCO',
];

@Injectable()
export class ClientProfileEditResolver implements Resolve<boolean> {
	constructor(
		private dropdownValueService: DropdownValueService,
		private clientProfileService: ClientProfileService,
		private crtMergeTagsService: CrtMergeTagsService,
		private router: Router,
		private routeService: RouteService,
		private userQuery: UserQuery,
		private businessConfigQuery: BusinessConfigQuery,
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
		const dropdowns$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		const customerId = Number.parseInt(route.paramMap.get('customerId'), 10);
		const service = route.paramMap.get('tab');

		const mergeTags$ = this.crtMergeTagsService.getDefaultMergeTags();

		let primaryClient$: Observable<PrimaryClient>;
		let criteria$: Observable<CurrentActivityCriteria[]>;
		let activityTimeline$: Observable<ActivityTimeline>;

		let lrInsurance$: Observable<LrInsurance> = of(
			Object.assign(
				{},
				{
					TotalInforceAPI: 0,
					LRs: [],
				},
			),
		);
		let mortgage$: Observable<Mortgage> = of(
			Object.assign(
				{},
				{
					TotaLending: 0,
					Mortgages: [],
				},
			),
		);
		let security$: Observable<PropertyAsset> = of(
			Object.assign(
				{},
				{
					TotaLending: 0,
					CustomerServices: [],
				},
			),
		);
		// let asset$: Observable<Asset> = of(
		//   Object.assign(
		//     {},
		//     {
		//       Notes: [],
		//       CustomerServices: [],
		//     }
		//   )
		// );
		let fgInsurance$: Observable<FgInsurance> = of(
			Object.assign(
				{},
				{
					TotalValue: 0,
					CustomerServices: [],
				},
			),
		);
		let kiwisavers$: Observable<Kiwisaver[]> = of(Object.assign([], []));
		let investments$: Observable<Investment[]> = of(Object.assign([], []));
		let adviceProcess$: Observable<AdviceProcessState> = of(
			Object.assign(
				{},
				{
					complaintAdviceProcesses: [],
					blanketAdviceProcesses: [],
					kiwiSaverAdviceProcesses: [],
					lRAdviceProcesses: [],
					mortgageAdviceProcesses: [],
					fGAdviceProcesses: [],
					investmentAdviceProcesses: [],
					clientAlterationRequests: [],
					fGClaimsAdviceProcesses: [],
					lRClaimsAdviceProcesses: [],
				},
			),
		);

		if (customerId) {
			primaryClient$ = this.clientProfileService.getPrimaryClient(customerId, service).pipe(take(1));
			criteria$ = this.clientProfileService.getCriterias(customerId).pipe(take(1));
			activityTimeline$ = this.clientProfileService.getActivityTimeline(customerId).pipe(take(1));

			if (service) {
				if (service === ServicesCodes.LR?.toLowerCase()) {
					lrInsurance$ = this.clientProfileService.getLRInsurance(customerId).pipe(take(1));
				}
				if (service === ServicesCodes.Mortgage?.toLowerCase()) {
					security$ = this.clientProfileService.getSecurity(customerId).pipe(
						mergeMap(
							() => this.clientProfileService.getAsset(customerId),
							(o) => o,
						),
						take(1),
					);
					// asset$ = this.clientProfileService
					//   .getAsset(customerId)
					//   .pipe(take(1));
					mortgage$ = this.clientProfileService.getMortage(customerId).pipe(take(1));
				}
				if (service === ServicesCodes.FG?.toLowerCase()) {
					fgInsurance$ = this.clientProfileService.getFGInsurance(customerId).pipe(take(1));
				}
				if (service === ServicesCodes.KiwiSaver?.toLowerCase()) {
					kiwisavers$ = this.clientProfileService.getKiwisavers(customerId).pipe(take(1));
				}
				if (service === ServicesCodes.Investment?.toLowerCase()) {
					investments$ = this.clientProfileService.getInvestments(customerId).pipe(take(1));
				}
				if (service === ServicesCodes.AdviceProcess?.toLowerCase()) {
					adviceProcess$ = this.clientProfileService.getAdviceProcessesByPrimaryId(customerId).pipe(take(1));
				}
			}
		}

		return customerId
			? forkJoin([
					dropdowns$,
					primaryClient$,
					criteria$,
					activityTimeline$,
					lrInsurance$,
					mortgage$,
					security$,
					fgInsurance$,
					kiwisavers$,
					investments$,
					adviceProcess$,
				]).pipe(
					withLatestFrom(
						this.businessConfigQuery.adviserReworkFeature$,
						this.userQuery.isTapLevel$,
						this.clientProfileService.allowedInAccessibility$,
					),
					tap(([[, pci], adviserReworkFeature, isTapLevel, advisers]) => {
						const primary = pci as PrimaryClient;
						if (!isTapLevel) {
							this.canViewClient(adviserReworkFeature, primary, advisers);
						}
						if (
							either(isNil, isEmpty)(primary) ||
							primary?.CustomerType === CustomerTypes.SecondaryCustomerIndividual
						) {
							this.navigateNotFound();
						}
					}),
					mapTo(true),
					finalize(() => {
						mergeTags$.subscribe();
					}),
				)
			: forkJoin([dropdowns$]).pipe(mapTo(true));
	}

	canViewClient(adviserReworkFeature: boolean, pci: PrimaryClient, advisers) {
		// TAPNZ-14474
		if (adviserReworkFeature) {
			const canView = GetAllAdviserReworkCodesPascal?.reduce((a, c) => {
				const hasAccessibility = !!advisers?.find((x) => +x?.value === +pci?.[c]);
				return !!a || hasAccessibility;
			}, false);
			if (!canView) {
				this.navigateNotFound();
			}
		} else {
			const altAdvisers = util.tryParseJson(pci?.AltAdviser?.toString());
			const hasAccessibility = !!advisers?.find((x) => +x?.value === +pci?.Adviser || altAdvisers?.includes(+x?.value));
			if (!hasAccessibility) {
				this.navigateNotFound();
			}
		}
	}

	navigateNotFound() {
		this.router.navigate(this.routeService.splitToRoutePath(this.routeService.notFoundPage));
	}
}
