import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementPopupComponent } from './announcement-popup.component';
import { AnnouncementPopupService } from './announcement-popup.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonModule } from '@shared/components/button/button.module';
import { LoaderMiniModule } from '@shared/components/loader-mini/loader-mini.module';
import { FroalaViewModule } from 'angular-froala-wysiwyg';
import { AnnouncementPopupGuard } from './announcement-popup.guard';

@NgModule({
	declarations: [AnnouncementPopupComponent],
	exports: [AnnouncementPopupComponent],
	imports: [
		CommonModule,
		ModalModule,
		ButtonModule,
		LoaderMiniModule,
		FroalaViewModule,
	],
	providers: [AnnouncementPopupService, AnnouncementPopupGuard],
})
export class AnnouncementPopupModule {}
