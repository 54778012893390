<!-- Import Type -->
<app-import-type *ngIf='!showProgress && !showSummary'
  (importEvent)="importData($event)"
  (importEventType)="acceptImport($event)"
  (importColumnOrder)="acceptColumns($event)"
  (importFile)="acceptFile($event)"
  (importContactStatus)="acceptContactStatus($event)"
  (cancelEvent)="cancel()">
</app-import-type>

<!-- Import progress -->
<app-import-progress *ngIf='showProgress && !showSummary'
  [percent]="percent"
  [uploadCount]="uploadCount"
  [rowCount]="rowCount"
  [successCount]="successCount"
  [failCount]="failCount"
  [startTime]="startTime"
  [progress]="progress"
  (cancelImportEvent)="cancelImport()">
</app-import-progress>

<!-- Export Summary -->
<app-import-summary *ngIf='showSummary' class="container-fluid px-0"
  [count]="count"
  [rowCount]="rowCount"
  [successCount]="successCount"
  [failCount]="failCount"
  [startTime]="startTime"
  [endTime]="endTime"
  [strictError]="strictError"
  [summaryLink]="summaryLink"
  [showInitSummary]="showInitSummary"
  (importNewEvent)="importNew()">
</app-import-summary>