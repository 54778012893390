import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, Subject } from 'rxjs';
import { finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { Announcement } from '../../state/announcement.model';

@Component({
	selector: 'app-announcement-popup',
	templateUrl: './announcement-popup.component.html',
	styleUrls: ['./announcement-popup.component.scss'],
})
export class AnnouncementPopupComponent implements OnInit {
	@ViewChild('modalBody') modalBody: ElementRef<HTMLDivElement>;

	readonly VISIBLE_ANNOUNCEMENT_COUNT = 4;

	isFullscreen = false;

	isLoading = false;

	isSaving = false;

	staffId: number;

	announcements: Announcement[];

	visibleAnnouncements: Announcement[];

	currentIndex = 0;

	acknowledged: Subject<boolean>;

	announcemenstUrlBodyToHtml: (
		announcements: Announcement[]
	) => Observable<Announcement[]>;

	acknowledge: (ids: number[]) => Observable<boolean>;

	constructor(public bsModalRef: BsModalRef) {}

	ngOnInit(): void {
		this.showNextAnnouncements().pipe(take(1)).subscribe();
	}

	private getNextAnnouncements(startIndex: number): Announcement[] {
		const visibleIndexes = startIndex + this.VISIBLE_ANNOUNCEMENT_COUNT;
		this.currentIndex = visibleIndexes;
		return this.announcements.slice(startIndex, visibleIndexes);
	}

	showNextAnnouncements(): Observable<Announcement[] | null> {
		const nextAnnouncements = this.getNextAnnouncements(
			this.currentIndex
		);
		if (!nextAnnouncements?.length) {
			this.hidePopup();
			return of(null);
		}
		this.isLoading = true;
		return this.announcemenstUrlBodyToHtml(nextAnnouncements).pipe(
			finalize(() => (this.isLoading = false)),
			tap((announcements) => {
				this.visibleAnnouncements = announcements;
				this.modalBody?.nativeElement?.scrollTo({ behavior: 'smooth', top: 0 });
			})
		);
	}

	toggleFullscreen() {
		this.isFullscreen = !this.isFullscreen;
		if (this.isFullscreen) {
			this.bsModalRef.setClass(
				'modal-dialog modal-dialog-centered modal-full announcement-popup'
			);
		} else {
			this.bsModalRef.setClass(
				'modal-dialog modal-dialog-centered modal-lg announcement-popup'
			);
		}
	}

	private hidePopup(): void {
		this.acknowledged.next(true);
	}

	private noNextAnnouncement(): boolean {
		return Boolean(this.announcements?.[this.currentIndex]);
	}

	acknowledge$ = (): Observable<boolean> => {
		if (this.isSaving) {
			return of(true);
		}
		this.isSaving = true;
		const ids = this.visibleAnnouncements.map((a) => a.announcementId);
		return this.acknowledge(ids).pipe(
			take(1),
			finalize(() => (this.isSaving = false)),
			mergeMap(() => {
				if (!this.noNextAnnouncement()) {
					this.hidePopup();
					return of(true);
				}
				return this.showNextAnnouncements().pipe(
					take(1),
					map(() => true)
				);
			})
		);
	};
}
