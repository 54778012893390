import { Injectable } from '@angular/core';
import { Dropdown } from '../model/dropdown';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DropdownService } from '../../../core/dropdown/dropdown.service';

@Injectable()
export class DropdownResolver implements Resolve<Dropdown[]> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Dropdown[] | Observable<Dropdown[]> | Promise<Dropdown[]> {
    return this.ds.getDropdowns();
  }

  constructor(private ds: DropdownService) { }
}
