<!-- HEADER -->
<app-header-v2 *ngIf="(menuReworkFeature$ | async); else v1"></app-header-v2>
<ng-template #v1>

	<header class="crm-header shadow-sm bg-white">
		<!-- TODO: Change the background color of -->
		<div class="container-fluid">
			<div class="row align-items-stretch tap-bg-primary">
				<div
					class="col-4 col-md-3 col-xl-1 order-0 d-flex justify-content-xl-center align-items-center header-logo bg-white"
				>
					<div class="header-logo-container pl-0 pr-0 py-2">
						<app-logo
							id="logoLink"
							[src]="logoUrl$ | async"
							[routerLink]="dashboardLink$ | async"
						></app-logo>
					</div>
				</div>
				<!-- Navigation Mobile -->
				<div
					class="col-auto d-flex order-6 order-md-6 justify-content-between d-md-none d-none px-0 w-100"
				>
					<button
						id="menuBtn"
						class="btn border-0 rounded-0 text-right"
						(click)="navIsShown = !navIsShown"
					>
						<i class="material-icons md-24 tap-text-color-h1">&#xE8EE;</i>
					</button>
				</div>
				<!-- Navigation links -->
				<ul
					class="col-xl order-7 order-md-7 order-xl-2 nav px-0 py-1 flex-column flex-md-row d-md-flex justify-content-md-start align-items-md-center text-white d-md-none d-none"
					[appTheme]="{ 'background-color': 'primarycolor' }"
					[ngClass]="{ 'd-none': !navIsShown }"
				>
					<!--BL - Dashboard Access-->
					<ng-container *appRestrictTo="['FDSA']">
						<li
							class="nav-item"
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
						>
							<a
								id="dashboardLink"
								class="nav-link"
								[routerLink]="dashboardLink$ | async"
								(click)="navIsShown = false"
								>Dashboard</a
							>
						</li>
					</ng-container>
					<!-- Lead Customers -->
					<ng-container *appRestrictTo="['FLA']">
						<li
							class="nav-item dropdown"
							dropdown
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
						>
							<a
								href
								dropdownToggle
								(click)="(false)"
								class="dropdown-toggle nav-link"
								role="button"
								aria-haspopup="true"
								aria-expanded="false"
								id="leadLink"
							>
								Lead
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu py-0 rounded-0" *dropdownMenu>
								<!--BL - Search Lead-->
								<ng-container *appRestrictTo="['FSL']">
									<li>
										<a
											id="searchLeadLink"
											[routerLink]="leadSearchLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Search</a
										>
									</li>
								</ng-container>
								<!--BL - Add Lead CLient-->
								<ng-container *appRestrictTo="['FAL']">
									<li>
										<a
											id="addNewPersonLink"
											[routerLink]="leadClientAddLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Add New Personal File</a
										>
									</li>
								</ng-container>
								<!--BL - Add Lead Business-->
								<ng-container *appRestrictTo="['FAL']">
									<li>
										<a
											id="addNewBusinessLeadLink"
											[routerLink]="leadBusinessAddLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Add New Business File</a
										>
									</li>
								</ng-container>
							</ul>
						</li>
					</ng-container>
					<!--BL - Customers Access-->
					<ng-container *appRestrictTo="['FCA']">
						<li
							class="nav-item dropdown"
							dropdown
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
						>
							<a
								id="clientLink"
								href
								dropdownToggle
								(click)="(false)"
								class="dropdown-toggle nav-link"
								role="button"
								aria-haspopup="true"
								aria-expanded="false"
							>
								Client
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu py-0 rounded-0" *dropdownMenu>
								<!--BL - Search Customers-->
								<ng-container *appRestrictTo="['FSC']">
									<li>
										<a
											id="searchCustomerLink"
											[routerLink]="customerSearchLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Search</a
										>
									</li>
								</ng-container>
								<!--BL - Add Customers-->
								<ng-container *appRestrictTo="['FAC']">
									<li>
										<a
											id="addNewPersonCustomerLink"
											[routerLink]="customerAddLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Add New Personal File</a
										>
									</li>
									<li>
										<a
											id="addNewBusinessCustomerLink"
											[routerLink]="businessAddLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Add New Business File</a
										>
									</li>
								</ng-container>
							</ul>
						</li>
					</ng-container>
					<!--BL - Pipeline Access-->
					<ng-container *appRestrictTo="['FPA']">
						<li
							class="nav-item dropdown"
							dropdown
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
						>
							<a
								id="pipelinesLink"
								href
								dropdownToggle
								(click)="(false)"
								class="dropdown-toggle nav-link"
								role="button"
								aria-haspopup="true"
								aria-expanded="false"
							>
								Pipelines
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu py-0 rounded-0" *dropdownMenu>
								<ng-template
									ngFor
									let-item
									[ngForOf]="orderedServiceLabelLinkModel$ | async"
									let-index
								>
									<li *ngIf="!!item?.label">
										<a
											id="pipelinesLink_{{ index }}"
											[routerLink]="navigateRoute(item?.link)"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>{{ item?.label }}</a
										>
									</li>
								</ng-template>
							</ul>
						</li>
					</ng-container>
					<!--BL - Activities Access-->
					<ng-container *appRestrictTo="['FAAC']">
						<li
							class="nav-item"
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
						>
							<a
								id="activitiesLink"
								class="nav-link"
								[routerLink]="activityLink$ | async"
								(click)="navIsShown = false"
								>Activities</a
							>
						</li>
					</ng-container>
					<!--BL - Document Access-->
					<ng-container *appRestrictTo="['FDAC', 'FRA']">
						<li
							class="nav-item dropdown"
							dropdown
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
						>
							<a
								id="documentsLink"
								href
								dropdownToggle
								(click)="(false)"
								class="dropdown-toggle nav-link"
								role="button"
								aria-haspopup="true"
								aria-expanded="false"
							>
								Documents
								<span class="caret"></span
							></a>
							<ul class="dropdown-menu py-0 rounded-0" *dropdownMenu>
								<ng-container *appRestrictTo="['FDAC']">
									<li>
										<a
											id="documentsSubLink"
											[routerLink]="documentLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Documents</a
										>
									</li>
								</ng-container>
								<ng-container *appRestrictTo="['FRA']">
									<li>
										<a
											id="reportsLink"
											[routerLink]="reportLink$ | async"
											class="dropdown-item py-1 px-3"
											(click)="navIsShown = false"
											>Reports</a
										>
									</li>
								</ng-container>
							</ul>
						</li>
					</ng-container>
				</ul>
	
				<!-- Add new Activity -->
				<div
					class="col-12 col-md-3 col-xl-auto order-4 order-md-2 order-xl-3 align-self-center justify-content-end py-3 d-flex add-activity"
				>
					<ng-container *appRestrictTo="['FAA']">
						<button
							id="newActivityBtn"
							type="button"
							class="tap-btn tap-btn--default font-weight-bold tap-btn--block new-activity-btn"
							(click)="createActivityModal()"
						>
							New Activity
						</button>
					</ng-container>
				</div>
	
				<!--BL - General Search-->
				<div
					class="col-12 col-md-3 col-xl-2 order-5 order-md-3 order-xl-4 align-self-center py-3 px-lg-4 d-flex global-search"
				>
					<ng-container *appRestrictTo="['FGS']">
						<app-global-search
							class="align-self-center w-100"
						></app-global-search>
					</ng-container>
				</div>
				<div
					class="col-8 col-md-3 col-xl-auto order-2 order-md-3 order-xl-4 align-self-center justify-content-end py-3 d-flex user-setting-dropdown"
				>
					<div class="position-relative align-self-center">
						<div
							id="toggleUserMenuBtn"
							(click)="togglePopover()"
							[ngClass]="{ clickable: hasPopover$ | async }"
							class="app-popover-user align-items-center d-flex clickable justify-content-end"
						>
							<ngx-avatars
								class="mr-3"
								[src]="googleImageUrl$ | async"
								[name]="name$ | async"
								[round]="true"
								size="30"
							>
							</ngx-avatars>
							<div
								class="align-self-center app-user-name clickable tap-text-color-h1"
							>
								{{ fullName$ | async }}
							</div>
							<div
								class="align-self-center app-popover-btn border-left profile-button px-2"
							>
								<div class="carret-icon">
									<i class="material-icons clickable">expand_more</i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="align-self-center order-3 order-md-3 order-xl-5">
					<div class="position-absolute pt-30px">
						<app-popover *ngIf="popoverIsShown$ | async"></app-popover>
					</div>
				</div>
			</div>
		</div>
	</header>
</ng-template>

<main class="position-relative">
	<app-loader service="ControlLoaderService"></app-loader>
	<router-outlet></router-outlet>
</main>
