<div class="row tap-container disclosure-document pt-3">
  <div class="col">
    <tabset>
      <tab heading="Template">
        <form [formGroup]="form">
          <div class="row px-4 pt-4">
            <div class="col-12 col-lg-3 intro-settings__option">
              <div class="row">
                <div class="col-1 intro-settings__option__checkbox">
                  <input
                    type="checkbox"
                    class="chckbox form-control theme-crm-field-disabled"
                    id="disclosureTypeUD"
                    formControlName="disclosureTypeUD"
                    (change)="toggleDisclosureTypeTemplate()"
                  />
                </div>
                <div class="col-10 pt-1 pl-2 intro-settings__option__label">
                  <label for="disclosureTypeUD" class="form-check-label">Upload Document</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3 intro-settings__option">
              <div class="row">
                <div class="col-1 intro-settings__option__checkbox">
                  <input
                    type="checkbox"
                    class="chckbox form-control theme-crm-field-disabled"
                    id="disclosureTypeFT"
                    formControlName="disclosureTypeFT"
                    (change)="toggleDisclosureTypeUD()"
                  />
                </div>
                <div class="col-10 pt-1 pl-2 intro-settings__option__label">
                  <label for="disclosureTypeFT" class="form-check-label">Template</label>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!!DisclosureTypeUD.value">
            <div class="row px-4">
              <div class="col-12 mt-3">
                <button
                  type="button"
                  class="tap-btn tap-btn--default"
                  (click)="openUploadModal()"
                >
                  Upload
                </button>
                <span class="ml-2" *ngIf="!!tempDoc">{{ tempDoc?.name }}</span>
                <span class="ml-2" *ngIf="!!!tempDoc && !!document">
                  <a href="{{ document.documentLink }}" target="_blank">
                    {{ document?.name || document?.documentName }}
                  </a>
                </span>
              </div>
            </div>
            <div class="text-right">
              <ng-container *ngIf="!isLoading">
                <button
                  type="button"
                  class="btn btn-primary my-2 pull-right tap-bg-primary"
                  style="background-color: rgb(52, 158, 237)"
                  [disabled]="!DisclosureTypeUD.value && !DisclosureTypeFT.value"
                  (click)="submitForm()"
                >
                  SAVE
                </button>
              </ng-container>
        
              <ng-container *ngIf="isLoading">
                <div class="mt-3 mb-3">
                  <app-manual-loader-mini
                    [isLoading]="isLoading"
                  ></app-manual-loader-mini>
                </div>
              </ng-container>
            </div>
          </ng-container>
      
          <ng-container *ngIf="!!DisclosureTypeFT.value">
            <app-disclosure-template
              [settingsId$]="settingsId$"
              [staffId]="staffId"
            ></app-disclosure-template>
          </ng-container>      
        </form>
      </tab>

      <tab heading="Email Settings">
        <app-disclosure-email
          [staffId]="staffId"
          [settingsId$]="settingsId$"
        ></app-disclosure-email>
      </tab>

      <tab heading="Merge Tags">
        <app-disclosure-merge-tag
          [staffId]="staffId"
        ></app-disclosure-merge-tag>
      </tab>
    </tabset>
  </div>
</div>
