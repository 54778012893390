import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { QuarterlyApiStatsState, QuarterlyApiStatsStore } from './qas.store';

/**
 * Query for Quarter API Stats widget data
 */
@Injectable()
export class QuarterlyApiStatsQuery extends Query<QuarterlyApiStatsState> {

  /** Quarter API Stats data. */
  quarterApiStatData$ = this.select(state =>
    state.QAStats
  );

  /** Indicator if QuarterApiStatus is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for QuarterApiStatus pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: QuarterlyApiStatsStore) {
    super(store);
  }
}
