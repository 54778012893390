<div class="risk-analysis clearfix">
  <div class="risk-analysis__header d-flex flex-wrap mb-3 align-items-center">
    <ng-container *ngIf="isLoading">
      <app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
    </ng-container>
  </div>

  <ng-container *ngIf="!!activePerson && !isLoading">
    <div class="risk-analysis__content">
      <ng-container *ngIf="!isLoading && !!activePerson">
        <app-insurance-priority
          *ngIf="people$ | async"
          [APCRTP$]="APCRTP$ | async"
          [cRTId]="cRTId"
          [event]="event"
          [peopleList]="peopleEvent"
          [sosCRT$]="sosCRT$"
          [activePerson]="activePerson"
					[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
        ></app-insurance-priority>

        <app-insurance-preference
          *ngIf="people$ | async"
          [APCRTIP$]="APCRTIP$ | async"
          [cRTId]="cRTId"
          [insurancePreferences]="insurancePreferences"
          [activePerson]="activePerson"
					[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
        ></app-insurance-preference>
      </ng-container>
    </div>
  </ng-container>
</div>
