import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CustomerPortalService } from '../../../service/customer-portal.service';
import { BlStaffViewmodel } from '../../../viewmodels/bl-staff.viewmodel';
import { iif, of, Subject, Observable } from 'rxjs';
import {
	filter,
	mergeMap,
	take,
	takeUntil,
	finalize,
	tap,
	concatMap,
	map,
} from 'rxjs/operators';
import { WysiwygComponent } from '../../../../../shared/wysiwyg/wysiwyg.component';
import { convertUtil, objectUtil } from '../../../../../util/util';
import { DocumentUploadState } from 'src/app/shared/models/documents/document.model';
import { EmailAccessPerAdviceProcess } from '../../../state/user-settings/user-settings.store';
import { UserSettingsQuery } from '../../../state/user-settings/user-settings.query';
import { EmailSettingsModel } from '../../../../emails/email-settings/state/email-settings.model';

@Component({
	selector: 'app-customer-portal',
	templateUrl: './customer-portal.component.html',
	styleUrls: ['./customer-portal.component.scss'],
})
export class CustomerPortalComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() data: BlStaffViewmodel;
	@ViewChild('contentEditor') editor: WysiwygComponent;

	currentSettings: EmailSettingsModel = null;
	// emailSettings: EmailSettingsModel;
	documentId: number;
	content = '';
	isLoading = false;
	isCollapsed = true;

	emailAccessPerAdviceProcess$: Observable<EmailAccessPerAdviceProcess[]> =
		this.query.emailAccessPerAdviceProcess$;

	constructor(
		private service: CustomerPortalService,
		private query: UserSettingsQuery
	) {}

	ngOnInit() {
		// this.prepareData();
		this.service
			.getCustomerPortalAccess(this?.data?.StaffID)
			.pipe(take(1))
			.subscribe();
	}

	prepareData() {
		this.service
			.getInvitationEmail(this?.data?.StaffID)
			.pipe(
				tap((x) => (this.currentSettings = x)),
				mergeMap((x) => {
					if (!!x && !!x?.body) {
						this.documentId = +x?.body;
						return this.service.getDocumentFile(this.documentId);
					}
					return of(x);
				}),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.service.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.content = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	saveInvitationEmail = (emailData) => {
		this.isLoading = true;
		const data = {
			// documentId: null,
			...this.currentSettings,
			referenceId: this.data.StaffID,
			type: this.service.sectionCode,
			...emailData,

		};

    data.unsubscribeGroupID = 0;
    data.body = data.bodyContent ?? '';
    data.isEnableEmailOption = true;
		of(data)
			.pipe(
				filter((x) => {
					if (!x?.referenceId) {
						this.isLoading = false;
					}
					return !!x && !!x?.referenceId;
				}),
				concatMap((settings) =>
					convertUtil.convertToBase64(settings.bodyContent)
				),
				concatMap((document) => {
					if (!!document) {
						if (!!this.currentSettings && !!+this.documentId) {
							return this.service
								.updateFileUpload({
									referenceId: this.data.StaffID,
									type: this.service.sectionCode,
									documentId: +this.documentId,
									fileName: 'CPInvitationEmail.txt',
									document,
								} as DocumentUploadState)
								.pipe(map(() => +this?.documentId));
						}
						return this.service
							.newFileUpload({
								referenceId: this.data.StaffID,
								type: this.service.sectionCode,
								fileName: 'CPInvitationEmail.txt',
								document,
							} as DocumentUploadState)
							.pipe(map((documentId) => +documentId));
					} else {
						return of(0);
					}
				}),
				map((documentId) =>
					objectUtil.mapCamelCaseToPascalCase({
						...this.currentSettings,
						...data,
						referenceId: +this.data?.StaffID,
						type: this.service.sectionCode,
						body: !!documentId ? documentId : null,
					})
				),
				concatMap((req) => this.service.saveInvitationEmail(req)),
				finalize(() => {
					this.isLoading = false;
					this.prepareData();
				}),
				take(1)
			)
			.subscribe();
	};

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

