import { LrCustomerServiceState } from './lr-insurance.model';
import MomentUtil from '../../../../util/moment.util';
import { util } from '../../../../core/util/util.service';
import { Moment } from 'moment';

export class LrInsuranceMapper {
	/**
	 * Map to view
	 */
	public static mapToView(data: LrCustomerServiceState): LrCustomerServiceState {
		if (!data) {
			return { ...data };
		}
		const filterPolicyOwners = (po: string) => {
			const a = JSON.parse(po);
			return a?.filter(x => x);
		};
		return {
			...data,
			products: typeof data.products === 'string' ? (data.products ? JSON.parse(data.products) : []) : data.products??[],
			policyOwners: typeof data.policyOwners === 'string' && data.policyOwners ? filterPolicyOwners(data.policyOwners) :
				(data.policyOwners && Array.isArray(data.policyOwners) ? data.policyOwners : []),
			submittedDate: util.isValidMoment(data.submittedDate) ? data.submittedDate : this.formatDateToMoment(data.submittedDate),
			startDate: util.isValidMoment(data.startDate) ? data.startDate : this.formatDateToMoment(data.startDate),
			anniversaryDate: util.isValidMoment(data.anniversaryDate) ? data.anniversaryDate : this.formatDateToMoment(data.anniversaryDate),
			startTrans: util.isValidMoment(data.startTrans) ? data.startTrans : this.formatDateToMoment(data.startTrans),
			endTrans: util.isValidMoment(data.endTrans) ? data.endTrans : this.formatDateToMoment(data.endTrans),
			cancellationDate: util.isValidMoment(data.cancellationDate) ? data.cancellationDate : this.formatDateToMoment(data.cancellationDate),
			notes: (data.notes && data.notes.length > 0) ? data.notes?.filter(x => MomentUtil.formatDateToMoment(x.createDateTime)) : []
		};
	}

	public static mapLrNextReviewView(value) {
		if (!value) {
			return '';
		}
		return util.isValidMoment(value) ? value : this.formatDateToMoment(value);
	}

	/**
	 * Map to add view
	 */
	public static mapToAddView(data: LrCustomerServiceState): LrCustomerServiceState {
		if (!data) {
			return { ...data };
		}

		return {
			...data,
			policyOwners: typeof data.policyOwners === 'string' ? (data.policyOwners ? JSON.parse(data.policyOwners) : null) : data.policyOwners,
			anniversaryDate: util.isValidMoment(data.anniversaryDate) ? data.anniversaryDate : this.formatDateToMoment(data.anniversaryDate),
			products: '',
			policyNumberSuffix: '',
			policyStatus: '',
			premium: null,
			policyClaimStatus: '',
			policyRetentionStatus: '',
			startDate: '',
			policyType: '',
			originalAdviser: '',
			submittedDate: '',
			startTrans: '',
			endTrans: '',
			policyDiscount: '',
			submittedAPI: null,
			cancellationDate: '',
			trailGenerating: false,
			adviserSP: false,
			clawbackRate: null,
			cancellationCode: '',
			replacement: false,
			required: '',
			doubleSub: false,
			commission: null,
			exclusions: '',
			lRStatusClock: '',
			isActive: 1,
			createDateTime: '',
			aPI: null
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: LrCustomerServiceState): LrCustomerServiceState {
		return {
			...data,
			premium: data.premium ? +data.premium : null,
			submittedAPI: data.submittedAPI ? +data.submittedAPI : null,
			commission: data.commission ? +data.commission : null,
			products: data.products && data.products.length > 0 ? JSON.stringify(data.products) : null,
			policyOwners: data.policyOwners && data.policyOwners.length > 0 ? JSON.stringify(data.policyOwners) : null,
			submittedDate: MomentUtil.formatDateToServerDate(data.submittedDate),
			startTrans: MomentUtil.formatDateToServerDate(data.startTrans),
			endTrans: MomentUtil.formatDateToServerDate(data.endTrans),
			startDate: MomentUtil.formatDateToServerDate(data.startDate),
			anniversaryDate: MomentUtil.formatDateToServerDate(data.anniversaryDate),
			cancellationDate: MomentUtil.formatDateToServerDate(data.cancellationDate),
		};
	}

	public static mapLrNextReviewUpsert(value) {
		return MomentUtil.formatDateToServerDate(value);
	}

	/**
	 * Format date to Moment
	 * @param date string or Moment date
	 */
	static formatDateToMoment(date: string | Moment): Moment {
		return typeof date === 'string'
			? util.DateStringToMomentLoose(date)
			: util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
	}
}
