<form [formGroup]="dataForm" novalidate (ngSubmit)="save()">
	<div class="app-content-container container">
		<div class="justify-content-between row app-form-control">
			<div class="col-auto">
				<div class="app-logo rounded-circle" style="padding: 19px">
					<app-logo *ngIf="data && data.logo" [src]="data.logo"></app-logo>
				</div>
			</div>
			<!--BL - Edit Business Account - Primary details-->
			<ng-container *appRestrictTo="['BEBP']">
				<div class="col-auto app-edit-button">
					<ng-container *ngIf="!isLoading; else spinner">
						<button
							id="blBusinessViewSave"
							class="btn btn-primary tap-bg-primary mr-2"
							type="submit"
							*ngIf="isEdit"
						>
							Save
						</button>
						<button
							id="blBusinessViewEdit"
							class="btn btn-primary tap-bg-primary"
							type="button"
							*ngIf="!isEdit"
							(click)="edit()"
						>
							Edit
						</button>
						<button
							id="blBusinessViewCancel"
							class="btn btn-light"
							type="button"
							*ngIf="isEdit"
							(click)="cancel()"
						>
							Cancel
						</button>
					</ng-container>
					<ng-template #spinner>
						<app-manual-loader-mini
							[isLoading]="isLoading"
						></app-manual-loader-mini>
					</ng-template>
				</div>
			</ng-container>
		</div>

		<div class="row app-form-name">
			<div class="col-7">
				<span class="tap-required-field" *ngIf="businessName.invalid">*</span>
				<label for="businessName" *ngIf="isEdit && !isLoading">
					<span>Business Name</span>
				</label>
				<span class="title">
					<input
						class="form-control h2"
						type="text"
						name="businessName"
						id="businessName"
						formControlName="businessName"
					/>
				</span>
			</div>
		</div>
		<div class="row app-form-description">
			<div class="col-lg-7">
				<label for="businessDescription" *ngIf="isEdit && !isLoading">
					<span>Business Description</span>
				</label>
				<textarea
					class="form-control"
					name="businessDescription"
					id="businessDescription"
					cols="30"
					rows="4"
					formControlName="description"
				></textarea>
			</div>
		</div>

		<div class="row app-form-section">
			<div class="col-lg-4 col-12 mb-3">
				<h6>Address</h6>
				<div class="d-flex flex-column">
					<div>
						<label for="address1" *ngIf="isEdit && !isLoading">
							<span>Address 1</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="address1"
							id="address1"
							formControlName="address1"
						/>
					</div>
					<div>
						<label for="address2" *ngIf="isEdit && !isLoading">
							<span>Address 2</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="address2"
							id="address2"
							formControlName="address2"
						/>
					</div>
					<div class="d-flex">
						<div>
							<label for="city" *ngIf="isEdit && !isLoading">
								<span>City</span>
							</label>
							<input
								class="form-control"
								type="text"
								name="city"
								id="city"
								formControlName="city"
							/>
						</div>
						<div class="form-postcode">
							<label for="Postcode" *ngIf="isEdit && !isLoading">
								<span>Postcode</span>
							</label>
							<input
								class="form-control"
								type="text"
								name="Postcode"
								id="Postcode"
								formControlName="postcode"
							/>
						</div>
					</div>
					<div>
						<label for="country" *ngIf="isEdit && !isLoading">
							<span>Country</span>
						</label>
						<select
							class="form-control"
							name="country"
							id="country"
							formControlName="country"
						>
							<option value=""></option>
							<option *ngFor="let country of countries" [value]="country">
								{{ country }}
							</option>
						</select>
					</div>
				</div>
				<hr />
				<div class="d-flex flex-column">
					<div>
						<label for="fAP">
							<span>FAP Name </span>
						</label>
						<input
							class="form-control"
							type="text"
							name="fAP"
							id="fAP"
							formControlName="fAP"
						/>
					</div>
					<div>
						<label for="fSPNumber">
							<span>FSP Number </span>
						</label>
						<input
							class="form-control"
							type="text"
							name="fSPNumber"
							id="fSPNumber"
							formControlName="fSPNumber"
						/>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-12 mb-3">
				<h6>Contact Person</h6>
				<div>
					<label for="firstName" *ngIf="isEdit && !isLoading">
						<span>First Name</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="firstName"
						id="firstName"
						formControlName="firstName"
					/>
				</div>
				<div>
					<label for="lastName" *ngIf="isEdit && !isLoading">
						<span>Last Name</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="lastName"
						id="lastName"
						formControlName="lastName"
					/>
				</div>
				<div>
					<label for="position" *ngIf="isEdit && !isLoading">
						<span>Position</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="position"
						id="position"
						formControlName="position"
					/>
				</div>
				<div>
					<label for="email" *ngIf="isEdit && !isLoading">
						<span>Email Address</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="email"
						id="email"
						formControlName="emailAddress"
					/>
				</div>
				<div>
					<label for="phone" *ngIf="isEdit && !isLoading">
						<span>Phone Number</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="phone"
						id="phone"
						formControlName="phoneNumber"
					/>
				</div>
			</div>
			<div class="col-lg-4 col-12 mb-3" *ngIf="isEdit && !isLoading">
				<h6>Services</h6>
				<div class="app-services" formArrayName="services">
					<label
						[for]="i"
						class="app-checkbox col-12"
						*ngFor="let service of servicesForm.controls; let i = index"
						[formGroupName]="i"
					>
						<div [ngClass]="{ 'd-none': !showService(service.value.code) }">
							<!--'d-none': service.value.code === 'CRT' && !hasPermission-->
							<input
								type="checkbox"
								id="service_{{ service?.value?.code }}_{{ i }}"
								formControlName="selected"
							/>
							<span>&nbsp;</span>
							<span>{{ service.value.name }}</span>
						</div>
					</label>
				</div>
			</div>
			<div class="col-lg-4 col-12 mb-3" *ngIf="!(isEdit && !isLoading)">
				<h6>Services</h6>
				<div class="app-services" formArrayName="services">
					<ul>
						<li
							[class.app-services-has]="service.value.selected"
							*ngFor="let service of servicesForm.controls; let i = index"
							[formGroupName]="i"
							class="pb-2"
							[class.d-none]="!showService(service.value.code)"
						>
							<ng-container *ngIf="showService(service.value.code)"
								>{{ service.value.name }}
							</ng-container>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</form>
