import {
	Component,
	OnDestroy,
	OnInit,
	Renderer2,
	TemplateRef,
} from '@angular/core';
import {
	FormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	map as Rmap,
	flatten,
	addIndex,
	forEach,
	complement,
	either,
	isNil,
	isEmpty,
	pick,
	uniqBy,
	prop,
} from 'ramda';
import { combineLatest, iif, Observable, Observer, of, Subject } from 'rxjs';
import {
	concatMap,
	exhaustMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { FSSubSectionCodes } from '../../../../shared/models/advice-process/advice-process.model';
import {
	LifeAssuredListState,
	CurrentInsuranceState,
} from '../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { ServicesCodes } from '../../../../shared/models/services/services.model';
import { util } from '../../../../core/util/util.service';
import { CurrentInsuranceModalComponent } from '../../../../shared/modal/crt/fact-find/current-insurance/current-insurance-modal.component';
import {
	UploadModalComponent,
	ALLOWED_DOCUMENT_FILE_TYPES,
} from '../../../../shared/modal/upload-modal/upload-modal.component';
import { CurrentInsuranceMapper } from '../../../../shared/models/client-review-template/current-insurance/current-insurance.mapper';
import { PeopleState } from '../../../../shared/models/client-review-template/people/people.model';
import { ProposedInsuranceState } from '../../../../shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { DocumentGroupState } from '../../../../shared/models/documents/document-group.model';
import {
	DocumentModelState,
	DocumentTypes,
} from '../../../../shared/models/documents/document.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { LinkDocumentComponent } from '../../../../shared/services/link-document/link-document.component';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { FinalStructureService } from '../states/final-structure/final-structure.service';
import { ConfirmModalComponent } from '../../../../shared/modal/confirm-modal/confirm-modal.component';
import { ProductsWithPerMonth } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';
import { ToPdfService } from 'src/app/shared/services/to-pdf/to-pdf.service';
import { convertUtil, objectUtil } from 'src/app/util/util';
import { ExistingPolicyStructureQuery } from '../states/existing-policy-structure/existing-policy-structure.query';
import {
	FinalStructureState,
	UnderwritingOutcomeModel,
} from '@shared/models/client-review-template/final-structure/final-structure.model';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';

@Component({
	selector: 'app-final-structure',
	templateUrl: './final-structure.component.html',
	styleUrls: ['./final-structure.component.scss'],
})
export class FinalStructureComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	proposedInsurance$: Observable<ProposedInsuranceState[]> =
		this.query.fsProposedInsurance$.pipe(
			map((x) =>
				(x ?? [])
					.map((pi) => ({ ...pi, lifeAssuredList: pi.lifeAssured }))
					.sort((a, b) => a.priority - b.priority)
			)
		);
	crtPeople$: Observable<PeopleState[]> = this.query.people$;
	currentInsurance$ = this.query.currentInsurances$;
	getAndSyncFinalStructure$: Observable<any>;
	document: DocumentGroupState;
	hasFinalStructure$ = this.query.finalStructure$.pipe(map((x) => !!x));

	pciAndSciFromCRM$ = this.query.pciAndSciFromCRM$;
	APCRTF$ = this.service.APCRTF$;
	APCRTB$ = this.service.APCRTB$;
	APCRTNE$ = this.service.APCRTNE$;
	APCRTFSCCO$ = this.service.APCRTFSCCO$;
	LRP$ = this.service.LRP$;
	LRPR$ = this.service.LRPR$;
	people$ = this.service.peopleEntities$.pipe(
		map((people) => people?.filter((person) => +person.value))
	);

	deceasedSCI$ = this.query.deceasedSciList$;
	lifeAssured$ = this.query.peopleFromCrmAndCrtExceptChildChoices$;
	currentInsuranceLifeAssured$ = this.query.currentInsurances$.pipe(
		map((x) => Rmap((l) => l?.lifeAssuredList ?? [], x)),
		map((x) => flatten(x)),
		map((x) => {
			const indexedForEach = addIndex(forEach);
			return indexedForEach(
				(lifeAssured: LifeAssuredListState, index) => (lifeAssured.id = index),
				x
			);
		})
	);

	lifeAssuredList: ViewDisplayValue[] = [];
	bsModalRef: BsModalRef;
	optionModalRef: BsModalRef;
	linkModalRef: BsModalRef;
	transferedSCI: PeopleState[];

	form: UntypedFormGroup = this.fb.group({
		cRTId: [''],
		sectionCode: [''],
		adviceProcessId: [''],
		underwritingOutcome: this.fb.array([]),
	});

	isSync = false;
	isLoading$ = this.query.isLoadingFinalStructure$;
	paymentFrequency$ = this.service.APCRTF$;
	// set default frequency
	paymentFrequency: string = this.dropdownValueQuery
		.getAll({ filterBy: (x) => x.DropdownCode === 'APCRTF' })
		?.find((x) => !!x.IsDefault)?.DropdownValue;

	isExistingPolicyLoading$ = this.existingPolicyQuery.selectLoading();
	existingPolicies$ = this.existingPolicyQuery.data$;

	underWritingOutcomeLAList: UnderwritingOutcomeModel[] = [];
	isAttachTermsUpdate: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private service: FinalStructureService,
		private query: ClientReviewTemplateQuery,
		private existingPolicyQuery: ExistingPolicyStructureQuery,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private renderer: Renderer2,
		private toPdfService: ToPdfService,
		private dropdownValueQuery: DropdownValueQuery
	) {}

	get underWritingOutcomeFormArray(): FormArray {
		return this.form.get('underwritingOutcome') as FormArray;
	}

	get underWritingOutcomeIfHasOOT(): boolean {
		if (this.underWritingOutcomeFormArray.length === 0) {
			return false;
		}

		return this.underWritingOutcomeFormArray.value.some(
			(item) => item.Value === 'Offer of Terms'
		);
	}

	ngOnInit(): void {
		this.query.transferedSCIList$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.transferedSCI = x));

		this.proposedInsurance$
			.pipe(
				tap((pi) => {
					if (!isEmpty(pi) && !this.isAttachTermsUpdate) {
						this.underWritingOutcomeLAList = [];
						const arrayUnderwritingOutcome = Rmap(
							pick(['lifeAssured']),
							flatten(pi.map((piLAL) => piLAL.lifeAssuredList))
						);

						const uniqueLifeAssuredList = uniqBy(
							prop('lifeAssured'),
							arrayUnderwritingOutcome
						);

						this.underWritingOutcomeLAList =
							uniqueLifeAssuredList.map((uLAL) => {
								return {
									LifeAssured: isEmpty(uLAL.lifeAssured)
										? null
										: uLAL.lifeAssured,
									Value: null,
								};
							}) || [];
					}
				}),
				tap((x) => setTimeout(() => this.checkPaymentFrequency(x), 300)),
				finalize(() => (this.isAttachTermsUpdate = false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.route.paramMap
			.pipe(
				map((x) => x.get('sync')),
				filter((x) => !!x),
				mergeMap((x) =>
					iif(
						() => !!x && util.toBool(x),
						this.service.getAndGenerateFinalStructure(
							this.query.getValue().adviceProcessId
						),
						of(x)
					)
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.service.lifeAssured$
			.pipe(take(1))
			.subscribe((x) => (this.lifeAssuredList = x));

		this.prepareData();
		// this.checkPaymentFrequency();

		// Set form value on every changes
		this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this.service.underWritingOutcomeSubj.next(x.underwritingOutcome);
			// this.service.setFormValue(this.prepareFormValue());
		});
	}

	checkPaymentFrequency(data: ProposedInsuranceState[]) {
		of(data)
			.pipe(
				withLatestFrom(this.query.finalStructure$, this.paymentFrequency$),
				filter(([insurance, fs]) => !!insurance && !!fs),
				tap(([insurance, fs, frequencyDropdown]) => {
					if (complement(either(isNil, isEmpty))(fs?.paymentFrequency)) {
						this.paymentFrequency = fs?.paymentFrequency;
					} else {
						// if (insurance?.length > 0) {
						// 	if (!isEmpty(fs?.underwritingOutcome)) {
						// 		// Handle Old FS Entries
						// 		// Data with no Global Payment Frequency but already has FS and FS insurance entries
						// 		this.paymentFrequency = null;
						// 		this.needToUpdateFrequencyPopUp();
						// 	} else {
						// 		this.setDefaultPaymentFrequency(
						// 			fs,
						// 			insurance,
						// 			frequencyDropdown
						// 		);
						// 	}
						// } else {
							this.setDefaultPaymentFrequency(fs, insurance, frequencyDropdown);
						// }
					}

					const cpfUnderwritingOutcome = fs?.underwritingOutcome;

					if (
						!isNil(cpfUnderwritingOutcome) &&
						!isEmpty(cpfUnderwritingOutcome) &&
						!isEmpty(this.underWritingOutcomeLAList)
					) {
						this.underWritingOutcomeLAList.forEach((itemTwo) => {
							const correspondingItem = cpfUnderwritingOutcome.find(
								(itemOne) =>
									itemOne.lifeAssured === itemTwo.LifeAssured ||
									itemOne.LifeAssured === itemTwo.LifeAssured
							);
							if (correspondingItem) {
								itemTwo.Value =
									correspondingItem.value || correspondingItem.Value;
							}
						});
					}
					if (!this.isAttachTermsUpdate) {
						this.prepUnderwritingOutcome();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	setDefaultPaymentFrequency(fs, fsInsurance, frequencyDropdown) {
		this.paymentFrequency = !!this.paymentFrequency
			? this.paymentFrequency
			: !!fsInsurance && fsInsurance?.length > 0
			? fsInsurance[0]?.premiumFrequency
			: frequencyDropdown?.some((x) => x.isDefault)
			? frequencyDropdown?.find((x) => x.isDefault).value
			: 'Monthly';
	}

	needToUpdateFrequencyPopUp() {
		const confirm$ = new Observable((obs) => {
			obs.complete();
		});

		const decline$ = new Observable((obs) => {
			obs.complete();
		});

		const initialState = {
			header: 'Payment Frequency',
			message: `Payment frequency needs to be updated.`,
			isOkBtn: true,
			confirm$,
			decline$,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	prepareData() {
		this.query.finalStructure$
			.pipe(withLatestFrom(this.service.formValue$), takeUntil(this.onDestroy$))
			.subscribe(([x, formValue]) => {
				if (!!x) {
					this.service.underWritingOutcomeSubj.next(x.underwritingOutcome);
					if (!!formValue) {
						this.form.reset({
							...formValue,
							underwritingOutcome: objectUtil.mapCamelCaseToPascalCase(
								formValue.underwritingOutcome
							),
						});
					} else {
						this.form.reset({
							...x,
							underwritingOutcome: objectUtil.mapCamelCaseToPascalCase(
								x.underwritingOutcome
							),
						});
					}
				}
			});
	}

	prepUnderwritingOutcome(toReset: boolean = false): void {
		this.underWritingOutcomeFormArray.clear();

		if (!isEmpty(this.underWritingOutcomeLAList)) {
			this.underWritingOutcomeLAList.forEach((lifeAL) => {
				const uoFormValue =
					this.query.getValue()?.finalStructureFormValue?.underwritingOutcome;
				const lifeAssuredValues =
					!!uoFormValue &&
					uoFormValue?.some(
						(uafv) => +uafv?.lifeAssured === +lifeAL?.LifeAssured
					)
						? uoFormValue?.find(
								(uafv) => +uafv?.lifeAssured === +lifeAL?.LifeAssured
						  )?.value
						: isNil(lifeAL.Value)
						? ''
						: lifeAL.Value;

				this.underWritingOutcomeFormArray.push(
					this.fb.group({
						LifeAssured: isEmpty(lifeAL.LifeAssured)
							? null
							: lifeAL.LifeAssured,
						Value: toReset ? '' : lifeAssuredValues,
					})
				);
			});
		}
	}

	prepareFormValue() {
		const data = this.form.getRawValue();
		return objectUtil.mapPascalCaseToCamelCase({
			...data,
		});
	}

	onChange(event: Event) {
		if (!(event?.target as HTMLSelectElement)?.value) {
			return;
		}

		this.service.setFormValue(this.prepareFormValue());
		// this.updateFinalStructure();
	}

	convertLifeAssuredToName(id: number) {
		const name = CurrentInsuranceMapper.getLifeAssuredName(
			id,
			this.lifeAssuredList,
			this.transferedSCI
		);

		if (!name) {
			return (
				this.query
					.getValue()
					.allSecondaryClients?.filter((x) => +x.customerID === +id)
					?.map((sci) => sci.firstName.concat(' ', sci.lastName)) ?? ''
			);
		}

		return name || '';
	}

	findPeopleName$ = (id: number) => {
		return this.lifeAssured$.pipe(
			map((x) => x?.find((p) => +p.value === id)?.display)
		);
	};

	findCurrentInsurance$ = (id: number) => {
		return combineLatest([this.currentInsurance$, this.service.people$]).pipe(
			map(([currentInsurance, people]) => {
				const ppl = (people ?? [])?.find((x) => x.cRTId === id);
				const curIns = (currentInsurance ?? [])
					?.filter((x) =>
						x.lifeAssuredList?.some((l) => l.lifeAssured === ppl?.customerId)
					)
					?.map((c) => ({
						...c,
						lifeAssuredList: c.lifeAssuredList?.filter(
							(la) => la.lifeAssured === ppl?.customerId
						),
					}));

				return curIns ?? [];
			})
		);
	};

	sync() {
		this.isSync = true;
		this.service
			.getAndGenerateFinalStructure(this.query.getValue().adviceProcessId)
			.pipe(
				tap(() => this.underWritingOutcomeFormArray.clear()),
				take(1)
			)
			.subscribe(
				() => {},
				() => {},
				() => {
					this.isSync = false;
					this.prepUnderwritingOutcome(true);
				}
			);
	}

	addCurrentInsurance = (model: ProposedInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next({
				...model,
				sectionCode: FSSubSectionCodes.ProposedInsurance,
				parentCRTId: this.form.value?.cRTId,
			});
			obs.complete();
		}).pipe(
			switchMap((x) =>
				this.service.addProposedInsurance(
					x,
					+this.route.snapshot.paramMap.get('adviceProcessId')
				)
			),
			switchMap((crtId) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.service
							.uploadProposeInsurance(
								model,
								crtId,
								+this.route.snapshot.paramMap.get('clientId')
							)
							.pipe(map(() => crtId))
					: of(crtId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const proposedInsurances = this.query.getValue().fsProposedInsurance;
					const m = proposedInsurances?.find((ci) => ci.cRTId === crtId);
					return this.service.updateProposedInsurance(m);
				}
				return of(crtId);
			}),
			map((x) => ({ ...model, cRTId: +x }))
		);

	updateCurrentInsurance = (model: ProposedInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next({
				...model,
				sectionCode: FSSubSectionCodes.ProposedInsurance,
			});
			obs.complete();
		}).pipe(
			map((res) => {
				let index = 0;
				if (res.policyDocumentFormData) {
					for (const [, file] of res.policyDocumentFormData.entries()) {
						res.policyDocumentsName[index] =
							convertUtil.imageFilenameToPDF(file);
						index++;
					}
				}
				return res;
			}),
			mergeMap((x) => this.service.updateProposedInsurance(x)),
			switchMap((crtId) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.service
							.uploadProposeInsurance(
								model,
								crtId,
								+this.route.snapshot.paramMap.get('clientId')
							)
							.pipe(map(() => crtId))
					: of(crtId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const proposedInsurances = this.query.getValue().fsProposedInsurance;
					const m = proposedInsurances?.find((ci) => ci.cRTId === model.cRTId);
					return this.service.updateProposedInsurance(m);
				}
				return of(crtId);
			}),
			map(() => model)
		);

	addProduct() {
		// const confirm = new Observable((obs: Observer<any>) => {
		// 	obs.complete();
		// });
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Proposed Insurance',
			message: `Proposed Insurance`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			getOwnerChoices: this.service.getOwnerChoices,
			currentInsuranceLifeAssured$: this.currentInsuranceLifeAssured$,
			isProposedInsurance: true,
			isSingleUpload: true,
			savefn: this.addCurrentInsurance,
			decline$: decline,
			defaultPaymentFrequency: this.paymentFrequency || 'Monthly',
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	editProduct(insurance: CurrentInsuranceState) {
		// const confirm = new Observable((obs: Observer<any>) => {
		// 	obs.complete();
		// });
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Proposed Insurance',
			message: `Proposed Insurance`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			apcrtne$: this.APCRTNE$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			getOwnerChoices: this.service.getOwnerChoices,
			currentInsuranceInfo: insurance,
			currentInsuranceLifeAssured$: this.currentInsuranceLifeAssured$,
			isProposedInsurance: true,
			isSingleUpload: true,
			savefn: this.updateCurrentInsurance,
			decline$: decline,
			restrictFileTypes: ALLOWED_DOCUMENT_FILE_TYPES,
			defaultPaymentFrequency:
				this.paymentFrequency || insurance?.premiumFrequency,
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	deleteCurrentInsurance(id: number) {
		this.service.deleteProposedInsurance(id).pipe(take(1)).subscribe();
	}

	updateFinalStructure() {
		// const data = {
		// 	...this.prepareFormValue(),
		// 	paymentFrequency: this.paymentFrequency,
		// };
		// const oldData = this.query.getValue().finalStructure;
		// const body: FinalStructureState = {
		// 	...oldData,
		// 	isCompleted: data?.isCompleted ?? oldData.isCompleted,
		// 	document: data?.document ?? oldData?.document,
		// 	underwritingOutcome:
		// 		data?.underwritingOutcome ?? oldData?.underwritingOutcome,
		// 	isEmailSent: data?.isEmailSent ?? oldData.isEmailSent,
		// 	paymentFrequency: data?.paymentFrequency ?? oldData.paymentFrequency,
		// };

		this.service
			.updateFinalStructure({
				...this.prepareFormValue(),
				paymentFrequency: this.paymentFrequency,
			})
			.pipe(
				take(1)
				// finalize(() => (this.isUpdatingUWO = false))
			)
			.subscribe();
	}

	allowDropdown = (product: string) =>
		product
			? [
					'Medical Base Plan',
					'Specialists & Tests',
					'Medical',
					'Medical + S&T',
					'Dental & Optical',
			  ].includes(product)
			: false;

	showPerMonth = (product: string) => ProductsWithPerMonth?.includes(product);

	getSumOfLifeAssuredList(lifeAssured: CurrentInsuranceState) {
		return (
			lifeAssured?.lifeAssured?.reduce(
				(a, b) => Number(a) + Number(b.premium),
				0
			) +
			(!lifeAssured?.policyFee || lifeAssured?.policyFee === 'N/A'
				? 0
				: +lifeAssured?.policyFee)
		);
	}

	downloadLink(id: number) {
		this.service
			.downloadLink(id)
			.pipe(
				tap((x) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
				}),
				take(1)
			)
			.subscribe();
	}

	convertToBase64 = (
		file: File
	): Observable<{ content: string; filename: string }> =>
		new Observable((obs) => {
			const reader = new FileReader();
			reader.onload = () =>
				obs.next({
					content: reader.result as string,
					filename: file.name,
				});
			reader.onloadend = () => obs.complete();
			return reader.readAsDataURL(file);
		});

	uploadOfferOfTerms(insurance?: ProposedInsuranceState) {
		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap(() =>
					iif(
						() => req[0].type.startsWith('image/'),
						this.toPdfService.fromImage(req[0]),
						this.convertToBase64(req[0]).pipe(take(1))
					)
				),
				map((file: any) => ({
					Document: `${(file.content ?? '')?.replace(/^data:(.*,)?/, '')}`,
					FileName: `${file.filename}`,
					DocumentType: ServicesCodes.LR,
					DocumentTypeCode: ServicesCodes.LR,
					// DocumentTypeCode: DocumentTypes.AdviceProcess,
				})),
				tap(() => (this.isAttachTermsUpdate = true)),
				concatMap((x) => this.service.uploadOfferOfTerms(x, insurance))
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Upload Offer of Terms Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
		};
		this.bsModalRef = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	linkDocument(insurance?: ProposedInsuranceState) {
		this.service
			.getClientDocuments(this.query.getValue().primaryClient.customerID)
			.pipe(take(1))
			.subscribe((docs) => {
				const initState = {
					selectedDetail: 'Link Document',
					document: docs,
					initialSelectedTab: ServicesCodes.LR.toLowerCase(),
					tabs: [ServicesCodes.LR.toString()],
				};
				this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
					class: 'modal-dialog-centered modal-lg',
					initialState: initState,
					ignoreBackdropClick: true,
				});

				this.linkModalRef.content.getSelectedDocumentValue$
					.pipe(
						tap(() => (this.isAttachTermsUpdate = true)),
						concatMap((doc: DocumentModelState) =>
							this.service.updateProposedInsurance({
								...insurance,
								documentTerm: { referenceId: doc?.id, value: doc?.fileName },
							})
						),
						takeUntil(this.onDestroy$)
					)
					.subscribe();
			});
	}

	openUploadOptionModal(
		template: TemplateRef<any>,
		insurance?: ProposedInsuranceState
	) {
		this.optionModalRef = this.modalService.show(template, {
			class: 'modal-dialog-centered',
			initialState: {
				insurance,
			},
			ignoreBackdropClick: true,
		});
	}

	updatePaymentFrequency() {
		of(this.paymentFrequency)
			.pipe(
				withLatestFrom(this.proposedInsurance$),
				filter(([x, insurance]) => !!x && !!insurance && insurance?.length > 0),
				withLatestFrom(this.query.finalStructure$),
				concatMap(([[x], fs]) =>
					this.service.updateFinalStructure({ ...fs, paymentFrequency: x })
				),
				concatMap(() =>
					this.service.getFinalStructure(this.query.getValue().adviceProcessId)
				),
				withLatestFrom(this.proposedInsurance$),
				tap(([, insurance]) => {
					if (insurance?.length > 0) {
						const confirm$ = new Observable((obs) => {
							obs.complete();
						});

						const decline$ = new Observable((obs) => {
							obs.complete();
						});

						const initialState = {
							header: 'Payment Frequency Updated',
							message: 'Payment Frequency Updated. Check the premium amounts again.',
							isOkBtn: true,
							confirm$,
							decline$,
							designV2: true,
						};

						this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
							class: 'modal-dialog-centered modal-dialog oat-frequency-modal',
							initialState,
							ignoreBackdropClick: true,
							keyboard: false,
						});
					}
				}),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
