import * as R from 'ramda';
import { numUtil } from '../../../../util/util';
import { DefaultFileNames } from '../../../../shared/models/documents/document.model';
import {
	EmailBodyTypes,
	EmailTypes,
} from '../../../../shared/models/emails/crt/email.model';
import { FinalStructureState } from './final-structure-settings.model';
import { fsCalc } from '../../../crm/crt-page/_shared/calculations/final-structure';

export class FinalStructureDocumentMapper {
	public static mapEmailSettingsToUpsert(data: FinalStructureState) {
		return {
			...data,
			referenceId: data.referenceId || 0,
			mergeTag: data.mergeTag || [],
			type: EmailTypes.FinalStructure,
		};
	}

	public static mapStaffEmailBody(document, docId, staffId) {
		if (docId === 0 || !docId) {
			return {
				document,
				referenceId: +staffId,
				fileName: DefaultFileNames.SettingsEmailBody,
				type: EmailBodyTypes.FinalStructure,
			};
		}
		return {
			document,
			documentId: +docId,
		};
	}

	public static mapEmailSettingsData(data: FinalStructureState) {
		if (data) {
			const { template, mergeTag, ...others } = data;
			return others;
		}
		return null;
	}

	public static groupByLifeAssured(insuranceList, lifeAssuredList) {
		if (!insuranceList || !lifeAssuredList) {
			return [];
		}
		const setName = (id) =>
			lifeAssuredList?.find((i) => +i?.value === +id)?.display;

		const insurances =
			(insuranceList?.reduce((a, c) => {
				const list = [];
				c?.lifeAssured?.forEach((item) => {
					list.push({
						...item,
						provider: c?.provider,
					});
				});
				return [...a, ...list];
			}, []) as any[]) || [];

		const lifeAssuredId = R.uniq(R.pluck('lifeAssured', insurances) || []);

		const newList = lifeAssuredId?.reduce((a, c) => {
			const data = {
				lifeAssured: c,
				lifeAssuredName: setName(c) || '',
				lifeAssuredList:
					insurances?.filter((x) => +x?.lifeAssured === +c) || [],
			};
			return [...a, data];
		}, []);

		return newList || [];
	}

	public static getCiPolicyFeeAndPremium(insuranceList = []) {
		// Get Current Insurance Total Premium
		const getCiTotalPremium = (data = []) => {
			const total = data?.reduce((a, c) => {
				const val = +c?.totalPremium || 0;
				return a + val;
			}, 0);
			return numUtil.formatToCurrency(total);
		};

		// Get Payment Frequency
		const getFrequency = (data = []) => {
			const list = R.uniq(R.pluck('premiumFrequency', data) || []);
			return list ? list[0] : '';
		};

		return {
			totalPremium: getCiTotalPremium(insuranceList),
			policyFee: this.getTotalPolicyFee(insuranceList),
			premiumFrequency: getFrequency(insuranceList),
		};
	}

	public static getFsPolicyFeeAndPremium(insuranceList = [], paymentFrequency) {
		// Get Final Structure Total Premium
		return {
			totalPremium: fsCalc.getFsTotalPremium(insuranceList),
			policyFee: this.getTotalPolicyFee(insuranceList),
			premiumFrequency: paymentFrequency || '',
		};
	}

	public static getTotalPolicyFee = (data = []) => {
		return fsCalc.getTotalPolicyFee(data);
	};
}
