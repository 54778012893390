import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { OtherStat } from './state/os.model';
import { BodyRow } from '../../shared/simple-table/simple-table.model';
import { map, filter, takeUntil } from 'rxjs/operators';
import { DashboardQuery } from '../../state/dashboard.query';
import { OtherStatsQuery } from './state/os.query';
import { OtherStatsService } from './state/os.service';
import * as R from 'ramda';
import { numUtil } from '../../../../../util/util';

@Component({
  selector: 'app-os',
  templateUrl: './os.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsComponent implements OnInit, OnDestroy {
  public static widgetCode = 'os';
  public static widgetName = 'Other Statistics';
  public static sizeX = 350;
  public static sizeY = 320;
  public static minSizeX = 350;
  public static minSizeY = 320;

  widgetCode = OsComponent.widgetCode;

  /**
   * Display name for Statuses.
   */
  private readonly propertyName = {
    NoOfClients: 'Number Of Clients',
    TotalInforce: 'Total Inforce API',
    Average: 'Average API',
    AveragePolicyTerms: 'Average Policy Terms',
    Persistency: 'Persistency',
    ClaimsPaid: 'Claims Paid',
    SettledMortgages: 'Settled Mortgages',
    SettledMortgagesThisYear: 'Settled Morgages this Year',
    TotalRefixes: 'Total Refixes',
    TotalKiwiSaver: 'Total Kiwisaver'
  };

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * Other Statistics Data
   */
  data$: Observable<OtherStat> = this.query.OtherStatData$;

  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return Object.keys(data)?.map<BodyRow>(key => {
          const propsWithDollars = ['TotalInforce', 'Average', 'SettledMortgagesThisYear'];
          const propsWithPercents = ['Persistency'];
          const propsWithYears = ['AveragePolicyTerms'];

          const hasDollar = R.contains(key, propsWithDollars);
          const hasPercent = R.contains(key, propsWithPercents);
          const hasYear = R.contains(key, propsWithYears);

          const label = this.propertyName[key];
          let value = '';
          if (hasDollar) {
            value = numUtil.formatToCurrency(data[key]);
          } else if (hasPercent) {
            value = numUtil.formatToPercent(data[key]);
          } else if (hasYear) {
            value = `${data[key]} Years`;
          } else {
            value = numUtil.formatToCount(data[key]);
          }

          return [
            {
              text: this.propertyName[key]
            },
            { text: value, class: 'col-4' }
          ];
        });
      }
    })
  );
  constructor(
    private dashboardQuery: DashboardQuery,
    private query: OtherStatsQuery,
    private service: OtherStatsService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of other stats.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetOtherStats(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
