<button
  id="hideModalButton"
  class="tap-btn tap-btn-outline modal-btn-close"
  (click)="bsModalRef.hide()"
>
  <span class="material-icons">
    close
  </span>
</button>
<div class="modal-header">
  <h1 class="modal-title">
    {{ header }}
  </h1>
  >
</div>
<div class="modal-body text-center">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button
    id="confirmModalButton"
    type="button"
    class="tap-btn tap-btn--default mr-2"
    (click)="confirm()"
  >
    {{ isArchive ? "Archive" : "Unarchive" }}
  </button>
  <button id="declineModalButton" type="button" class="tap-btn tap-btn--default" (click)="decline()">
    Cancel
  </button>
</div>
