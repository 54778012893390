import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfComponent implements OnInit, OnDestroy {
  @Input() fileSource: string;
  @Output() onPageRendered = new EventEmitter();

  @ViewChild(PdfViewerComponent, { static: false })
  private pdfComponent: PdfViewerComponent;

  @Input() renderOnNotesCollapse: boolean = false;
  @Input() isNotesCollapse$: Observable<boolean>;
  onDestroy$ = new Subject<void>();
  showViewPDF: boolean = true;

  hidePdf = true;
  timer: any;

  constructor() {}

  ngOnInit(): void {
    this.renderViewPDFOnNotesCollapse();
  }

  // Re-render the pdf-viewer using structural directive
  renderViewPDFOnNotesCollapse(): void {
    if (this.renderOnNotesCollapse) {
      this.isNotesCollapse$
        .pipe(
          takeUntil(this.onDestroy$),
          tap(() => {
            this.showViewPDF = false;
            setTimeout(() => {
              this.showViewPDF = true;
            }, 0);
          })
        )
        .subscribe();
    }
	}

  pageRendered(event) {
    $('pdf-viewer').parent().height($('.pdfViewer').height());
    this.onPageRendered.emit(event);
  }

  ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
