<form
  [formGroup]="form"
  class="form retire-term-goals-form retire-goals"
  [class.submitted]="submitted"
>
  <div class="form-row mb-2 label-background">
    <div class="col-7 mt-1 mt-lg-0 mb-lg-3">
      <label for="" class="font-weight-bold label-font tap-text-primary"
        >When would you like to retire?</label
      >
    </div>
    <div class="col-5 text-right iconShow">
      <button
        id="collapseRetireGoalBtn"
        type="button"
        class="btn-toggle bg-white collapse-btn border-0 collapse-background"
      >
        <span *ngIf="elseMinus; else elsePlus">
          <i (click)="collapseMore()" class="material-icons md-20 pt-1"
            >expand_more</i
          >
        </span>
        <ng-template #elsePlus>
          <span>
            <i (click)="collapseLess()" class="material-icons md-20 pt-1"
              >expand_less</i
            >
          </span>
        </ng-template>
      </button>
    </div>
  </div>

  <div class="form-row d-none d-lg-flex mb-2 font-weight-bold">
    <div class="col-lg-3">
      <span>Name</span>
    </div>
    <div class="col-lg-3 text-truncate">
      <span>Age</span>
    </div>
  </div>

  <ng-template #showLoader>
    <span class="mb-4 d-block">
      <app-manual-loader-mini
        [isLoading]="isListLoading"
      ></app-manual-loader-mini>
    </span>
  </ng-template>

  <div formArrayName="detailsList" *ngIf="!isListLoading; else showLoader">
    <div class="collapse expandable" id="collapse">
      <div
        class="form-row align-items-center"
        *ngFor="
          let item of DetailsList.controls;
          let i = index;
          let last = last
        "
        [formGroupName]="i"
      >
        <div
          class="col-12 col-lg-3 mb-2 pb-2 retire-goals__client-name"
          [class.border-bottom]="!last"
        >
          <input
            id="retireClientNameId_{{ i }}"
            class="form-control crt-form-control"
            placeholder="Name"
            formControlName="clientName"
            type="text"
          />
        </div>
        <div
          class="col-12 col-lg-3 mb-2 pb-2 retire-goals__age"
          [class.border-bottom]="!last"
        >
          <input
            id="retireAgeId_{{ i }}"
            class="form-control crt-form-control"
            placeholder="Age"
            formControlName="age"
            type="number"
            onkeydown="javascript: return event.keyCode === 8 ||
            event.keyCode === 46 ? true : !isNaN(Number(event.key))"
            (change)="onChange()"
          />
        </div>
      </div>
    </div>
  </div>
</form>
