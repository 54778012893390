import { Moment } from 'moment';
import { omit } from 'ramda';
import { util } from '../../../../core/util/util.service';
import MomentUtil from '../../../../util/moment.util';
import { FgCustomerServiceState } from './fg-insurance.model';

// biome-ignore lint/complexity/noStaticOnlyClass: Static mapper
export class FgInsuranceMapper {
	/**
	 * Map to view
	 */
	public static mapToView(data: FgCustomerServiceState): FgCustomerServiceState {
		if (!data) {
			return { ...data };
		}

		return {
			...data,
			policyOwners:
				typeof data.policyOwners === 'string'
					? data.policyOwners
						? JSON.parse(data.policyOwners)
						: null
					: data.policyOwners,
			policyLines:
				typeof data.policyLines === 'string'
					? data.policyLines
						? JSON.parse(data.policyLines)
						: null
					: data.policyLines,
			renewalDate: util.isValidMoment(data.renewalDate) ? data.renewalDate : this.formatDateToMoment(data.renewalDate),
			inceptionDate: util.isValidMoment(data.inceptionDate)
				? data.inceptionDate
				: this.formatDateToMoment(data.inceptionDate),
			policyEndDate: util.isValidMoment(data.policyEndDate)
				? data.policyEndDate
				: this.formatDateToMoment(data.policyEndDate),
			firstPolicyDate: util.isValidMoment(data.firstPolicyDate)
				? data.firstPolicyDate
				: this.formatDateToMoment(data.firstPolicyDate),
			fixedPeriodEnd: util.isValidMoment(data.fixedPeriodEnd)
				? data.fixedPeriodEnd
				: this.formatDateToMoment(data.fixedPeriodEnd),
			fGStatusClock: util.isValidMoment(data.fGStatusClock)
				? data.fGStatusClock
				: this.formatDateToMoment(data.fGStatusClock),
			createDateTime: util.isValidMoment(data.createDateTime)
				? data.createDateTime
				: this.formatDateToMoment(data.createDateTime),
			modifiedDateTime: util.isValidMoment(data.modifiedDateTime)
				? data.modifiedDateTime
				: this.formatDateToMoment(data.modifiedDateTime),
			issueDate: util.isValidMoment(data.issueDate) ? data.issueDate : this.formatDateToMoment(data.issueDate),
			notes:
				data.notes && data.notes.length > 0
					? data.notes?.filter((x) =>
							util.isValidMoment(data.createDateTime)
								? data.createDateTime
								: // @ts-ignore-next
									MomentUtil.formatDateToMoment(x.createDateTime),
						)
					: [],
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(fg: FgCustomerServiceState, fGUpdateV1Feature?: boolean): FgCustomerServiceState {
		const data = fGUpdateV1Feature ? fg : omit(['cancellationReason', 'autoRenewal'], fg);
		return {
			...data,
			premium: data.premium ? +data.premium : null,
			brokerFee: data.brokerFee ? +data.brokerFee : null,
			adminFee: data.adminFee ? +data.adminFee : null,
			policyOwners: data.policyOwners && data.policyOwners.length > 0 ? JSON.stringify(data.policyOwners) : null,
			policyLines: data.policyLines && data.policyLines.length > 0 ? JSON.stringify(data.policyLines) : null,
			renewalDate: MomentUtil.formatDateToServerDate(data.renewalDate),
			inceptionDate: MomentUtil.formatDateToServerDate(data.inceptionDate),
			policyEndDate: MomentUtil.formatDateToServerDate(data.policyEndDate),
			firstPolicyDate: MomentUtil.formatDateToServerDate(data.firstPolicyDate),
			fixedPeriodEnd: MomentUtil.formatDateToServerDate(data.fixedPeriodEnd),
			fGStatusClock: MomentUtil.formatDateToServerDate(data.fGStatusClock),
			createDateTime: MomentUtil.formatDateToServerDate(data.createDateTime),
			modifiedDateTime: MomentUtil.formatDateToServerDate(data.modifiedDateTime),
			issueDate: MomentUtil.formatDateToServerDate(data.issueDate),
		};
	}

	/**
	 * Format date to Moment
	 * @param date string or Moment date
	 */
	static formatDateToMoment(date: string | Moment): Moment {
		return typeof date === 'string'
			? util.DateStringToMomentLoose(date)
			: util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
	}

	/**
	 * Map to add view
	 */
	public static mapToAddView(data: FgCustomerServiceState): FgCustomerServiceState {
		if (!data) {
			return { ...data };
		}

		return {
			...data,
			policyOwners:
				typeof data.policyOwners === 'string'
					? data.policyOwners
						? JSON.parse(data.policyOwners)
						: null
					: data.policyOwners,
			premium: null,
			brokerFee: null,
			adminFee: null,
			policyLines: null,
			renewalDate: '',
			inceptionDate: '',
			policyEndDate: '',
			firstPolicyDate: '',
			fixedPeriodEnd: '',
			fGStatusClock: '',
			createDateTime: '',
			modifiedDateTime: '',
		};
	}

	public static mapFgNextReviewView(value) {
		if (!value) {
			return '';
		}
		return util.isValidMoment(value) ? value : this.formatDateToMoment(value);
	}

	public static mapFgNextReviewUpsert(value) {
		return MomentUtil.formatDateToServerDate(value);
	}
}
