import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';
import { FactFindSubpagesSettingsService } from './state/fact-find-subpages-settings.service';
import { FactFindSettingsMapper } from './state/fact-find-subpages-settings.mapper';

@Component({
	selector: 'app-fact-find-subpages-settings',
	templateUrl: './fact-find-subpages-settings.component.html',
})
export class FactFindSubpagesSettingsComponent implements OnInit {
	form: UntypedFormGroup;
	isLoading = false;
  factFindSubpagesSettings$ = this.factFindSubpagesService.factFindSubpagesSettings$;

	constructor(
		private fb: UntypedFormBuilder,
		private factFindSubpagesService: FactFindSubpagesSettingsService,
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
	}

	buildForm() {
		this.form = this.fb.group({
			whyKiwiSaver: [false],
			howKiwiSaverWorks: [false],
			investmentStrategy: [false],
			// passiveVsActive: [false],
			// riskVsVolatility: [false],
		});
	}

	prepData() {
		this.factFindSubpagesSettings$.pipe(take(1)).subscribe((data) => {
      if (data) {
        this.form.reset(FactFindSettingsMapper.mapToView(data));
      }
    });
	}

	save() {
    this.isLoading = true;

    const formValue = FactFindSettingsMapper.mapToUpsert(
      this.form.getRawValue()
    );

    this.factFindSubpagesService
      .updateFactFindSubpagesSettings(formValue)
      .pipe(
        finalize(() => (this.isLoading = false)),
        take(1)
      ).subscribe();
	}
}
