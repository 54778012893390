import {
	ChangeDetectorRef,
	Component,
	OnChanges,
	OnDestroy,
	OnInit,
	Renderer2,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, of, Subject } from 'rxjs';
import {
	exhaustMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ProductsWithPerMonth } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { ThemeConfig } from '../../../../domain/business-config/theme-config.model';
import { CurrentInsuranceModalComponent } from '../../../../shared/modal/crt/fact-find/current-insurance/current-insurance-modal.component';
import { CurrentInsuranceMapper } from '../../../../shared/models/client-review-template/current-insurance/current-insurance.mapper';
import {
	CurrentInsuranceState,
	PolicyDocumentsListState,
} from '../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { PeopleState } from '../../../../shared/models/client-review-template/people/people.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { ClientReviewTemplateService } from '../states/client-review-template.service';
import { CurrentInsuranceService } from '../states/current-insurance/current-insurance.service';
import { ConfirmModalComponent } from './../../../../shared/modal/confirm-modal/confirm-modal.component';

declare var $: any;

@Component({
	selector: 'app-current-insurance',
	templateUrl: './current-insurance.component.html',
	styleUrls: ['./current-insurance.component.scss'],
})
export class CurrentInsuranceComponent implements OnInit, OnChanges, OnDestroy {
	documents: any[];

	providerHeader: string;

	onDestroy$ = new Subject<void>();
	themeConfig$ = this.businessConfigQuery.themeConfig$;
	primaryColor: string;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	transferedSCI: PeopleState[];

	currentInsurances$ = this.service.currentInsurances$.pipe(
		map((x) =>
			(x ?? []).map((pi) => {
				const lifeAssured = pi?.lifeAssuredList?.map((la) => ({
					...la,
					allowDropdown: this.allowDropdown(la?.product),
					lifeAssuredName: this.convertLifeAssuredToName(la?.lifeAssured),
					showPerMonth: this.showPerMonth(la?.product),
				}));
				return {
					...pi,
					lifeAssured,
					lifeAssuredList: lifeAssured,
					sumOfLifeAssured: this.getSumOfLifeAssuredList(pi),
				};
			})
		)
	);
	isLoadingCurrentInsurance$ = this.service.isLoadingCurrentInsurance$;
	lifeAssured$ = this.service.lifeAssured$;

	APCRTF$ = this.service.APCRTF$;
	APCRTME$ = this.service.APCRTME$;
	APCRTNE$ = this.service.APCRTNE$;
	APCRTB$ = this.service.APCRTB$;
	LRP$ = this.service.LRP$;
	LRPR$ = this.service.LRPR$;
	people$ = this.service.peopleEntities$.pipe(
		map((people) => people?.filter((person) => +person.value))
	);

	lifeAssuredList: ViewDisplayValue[] = [];
	elseMinusProvider = true;

	isAddNew = false;

	paymentFrequency$ = this.service.APCRTF$;
	paymentFrequency;

	isLoading = false;
	isLoadingId = 0;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$;

	constructor(
		private businessConfigQuery: BusinessConfigQuery,
		private modalService: BsModalService,
		private service: ClientReviewTemplateService,
		private route: ActivatedRoute,
		private currentInsuranceService: CurrentInsuranceService,
		protected query: ClientReviewTemplateQuery,
		private cd: ChangeDetectorRef,
		private renderer: Renderer2
	) {}

	ngOnChanges() {}

	addCurrentInsurance = (model: CurrentInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			switchMap((x) =>
				this.currentInsuranceService.addCurrentInsurance(
					x,
					parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10)
				)
			),
			switchMap((crtId) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.currentInsuranceService
							.uploadDocument(model, crtId, +this.route.snapshot.params.clientId)
							.pipe(map(() => crtId))
					: of(crtId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const currentInsurances = this.query.getValue().currentInsurances;
					const m = currentInsurances?.find((ci) => ci.cRTId === crtId);
					return this.currentInsuranceService.updateCurrentInsurance(m);
				}
				return of(crtId);
			}),
			map((x) => ({...model, cRTId: +x})),
			finalize(() => {
				this.isAddNew = false;
			})
		);

	updateCurrentInsurance = (model: CurrentInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			switchMap((result) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.currentInsuranceService
							.uploadDocument(model, model.cRTId, +this.route.snapshot.params.clientId)
							.pipe(map(() => result))
					: of(result)
			),
			exhaustMap(() => {
				model.adviceProcessId = parseInt(
					this.route.snapshot.paramMap.get('adviceProcessId'),
					10
				);
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const currentInsuranceList: CurrentInsuranceState[] =
						this.query.getValue().currentInsurances;
					const oldInsurance: CurrentInsuranceState =
						currentInsuranceList?.find((ci) => ci.cRTId === model.cRTId);

					const oldInsuranceDocumentList: PolicyDocumentsListState[] =
						oldInsurance.policyDocumentsList;
					const newInsuranceDocumentList: PolicyDocumentsListState[] =
						model.policyDocumentsList as PolicyDocumentsListState[];
					const oldDocumentIds: number[] = oldInsuranceDocumentList?.map(
						(value) => value.referenceId
					);

					const removeExistingFromNew: PolicyDocumentsListState[] =
						newInsuranceDocumentList
							? newInsuranceDocumentList?.filter(
									(value) =>
										oldDocumentIds.findIndex(
											(id) => id === value.referenceId
										) === -1
							  )
							: [];

					const combinedPolicyDocumentList: PolicyDocumentsListState[] = [
						...new Set([...oldInsuranceDocumentList, ...removeExistingFromNew]),
					];
					const combinedModel: CurrentInsuranceState = {
						...model,
						policyDocuments: JSON.stringify(combinedPolicyDocumentList),
						policyDocumentsList: combinedPolicyDocumentList,
					};
					return this.currentInsuranceService.updateCurrentInsurance(
						combinedModel
					);
				} else {
					return this.currentInsuranceService.updateCurrentInsurance(model);
				}
			}),
			map(() => model),
		);

	addProduct() {
		this.isAddNew = true;
		const decline = new Observable((obs: Observer<any>) => {
			this.isAddNew = false;
			this.cd.detectChanges();
			obs.complete();
		});
		const initState = {
			header: 'Current L&R Details',
			message: `CurrentL&RDetails`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			getOwnerChoices: this.service.getOwnerChoices,
			savefn: this.addCurrentInsurance,
			decline$: decline,
			enableLinkDocument: true,
			hideNotes: true,
			defaultPaymentFrequency: this.paymentFrequency,
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	editProduct(insurance) {
		const decline = new Observable((obs: Observer<any>) => obs.complete());
		const initState = {
			header: 'Current L&R Details',
			message: `CurrentL&RDetails`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			getOwnerChoices: this.service.getOwnerChoices,
			currentInsuranceInfo: insurance,
			savefn: this.updateCurrentInsurance,
			decline$: decline,
			enableLinkDocument: true,
			hideNotes: true,
			defaultPaymentFrequency: this.paymentFrequency,
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	viewProduct(insurance) {
		const decline = new Observable((obs: Observer<any>) => obs.complete());
		const initState = {
			header: 'Current L&R Details',
			message: `CurrentL&RDetails`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			getOwnerChoices: this.service.getOwnerChoices,
			currentInsuranceInfo: insurance,
			decline$: decline,
			enableLinkDocument: true,
			hideNotes: true,
			defaultPaymentFrequency: this.paymentFrequency,
			viewMode: true
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteCurrentInsurance(id: number) {
		this.isLoading = true;
		this.isLoadingId = id;
		this.currentInsuranceService
			.deleteCurrentInsurance(id)
			.pipe(
				tap(() => {
					this.isLoading = false;
					this.isLoadingId = 0;
				})
			)
			.subscribe();
	}

	colorStyleLess() {
		$('.insurance-label-background').css('background-color', '');
		$('.insurance-label-background').css('color', '');
		$('.insurance-collapse-background').attr('style', 'background-color: none');
	}

	colorStyleMore() {
		// $('.insurance-label-background').css(
		// 	'background-color',
		// 	this.primaryColor + ' !important'
		// );
		// $('.insurance-label-background').css('color', '#FFFFFF' + ' !important');
		// $('.insurance-collapse-background').attr(
		// 	'style',
		// 	'background-color: ' + this.primaryColor + ' !important; color: white'
		// );
	}

	collapseFalse() {
		this.elseMinusProvider = false;
	}

	defaultShow() {
		$('#collapseInsurance').css('display', 'block');
	}

	ngOnInit(): void {
		this.query.transferedSCIList$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.transferedSCI = x));

		const widthOnLoad = $(window).width();
		if (widthOnLoad > 600) {
			$('.iconShow').hide();
			this.defaultShow();
		}
		if (widthOnLoad <= 600) {
			$('.iconShow').show();
		}

		$(window).resize(() => {
			const widthReSize = $(window).width();
			if (widthReSize > 600) {
				$('.iconShow').hide();
				this.colorStyleLess();
				this.defaultShow();
			}
			if (widthReSize <= 600) {
				$('.iconShow').show();
				this.colorStyleMore();
				this.collapseFalse();
			}
		});

		this.themeConfig$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => this.setThemeConfig(x));

		this.service.lifeAssured$
			.pipe(take(1))
			.subscribe((x) => (this.lifeAssuredList = x));

		this.service.showCurrentInsuranceModal$
			.pipe(
				filter((x) => x),
				tap(() => {
					this.addProduct();
					this.service.showCurrentInsuranceModal$.next(false);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.currentInsuranceService.currentInsurances$
			.pipe(
				withLatestFrom(this.paymentFrequency$),
				tap(
					([ci, f]) => {
						if (!!ci && ci.length > 0) {
							this.paymentFrequency = ci[0]?.premiumFrequency;
						} else {
							if (f?.some((x) => x.isDefault)) {
								this.paymentFrequency = f?.find((x) => x.isDefault).value;
							} else {
								this.paymentFrequency = 'Monthly';
							}
						}
					}
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	convertLifeAssuredToName(id: number) {
		const name = CurrentInsuranceMapper.getLifeAssuredName(
			id,
			this.lifeAssuredList,
			this.transferedSCI
		);

		if (!name) {
			return (
				this.query
					.getValue()
					.allSecondaryClients?.filter((x) => +x.customerID === +id)
					?.map((sci) => sci.firstName.concat(' ', sci.lastName)) ?? ''
			);
		}

		return name || '';
	}

	collapseMoreInsurance() {
		// const widthSize = $(window).width();
		// if (widthSize <= 600) {
		// 	$('.insurance-label-background').css(
		// 		'background-color',
		// 		this.primaryColor + ' !important'
		// 	);
		// 	$('.insurance-label-background').css('color', '#FFFFFF' + ' !important');
		// 	$('.insurance-collapse-background').attr(
		// 		'style',
		// 		'background-color: ' + this.primaryColor + ' !important; color: white'
		// 	);
		// }
		$('#collapseInsurance').toggle();
		this.elseMinusProvider = false;
	}
	collapseLessInsurance() {
		// const widthSize = $(window).width();
		$('.insurance-label-background').css('background-color', '');
		$('.insurance-label-background').css('color', '');
		$('.insurance-collapse-background').attr('style', 'background-color: none');
		$('#collapseInsurance').toggle();
		this.elseMinusProvider = true;
	}

	getSumOfLifeAssuredList(lifeAssured) {
		return (
			lifeAssured?.lifeAssuredList?.reduce(
				(a, b) => Number(a) + Number(b.premium),
				0
			) +
			(!lifeAssured?.policyFee || lifeAssured?.policyFee === 'N/A'
				? 0
				: +lifeAssured?.policyFee)
		);
	}

	setThemeConfig(theme: ThemeConfig) {
		this.primaryColor = theme?.primarycolor || '#00263e';
	}

	downloadLink(id: number) {
		this.currentInsuranceService
			.downloadLink(id)
			.pipe(
				tap((x) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
				}),
				take(1)
			)
			.subscribe();
	}

	allowDropdown = (product) =>
		product
			? [
					'Medical Base Plan',
					'Specialists & Tests',
					'Medical',
					'Medical + S&T',
					'Dental & Optical',
			  ].includes(product)
			: false;

	updatePaymentFrequency() {
		const paymentFrequency = this.paymentFrequency;
		of(paymentFrequency)
			.pipe(
				withLatestFrom(this.currentInsurances$),
				filter(([x, ci]) => !!x && !!ci && ci?.length > 0),
				mergeMap(([x]) =>
					this.currentInsuranceService.updatePaymentFrequency(x)
				),
				withLatestFrom(this.currentInsurances$),
				tap(([, ci]) => {
					if (ci?.length > 0) {
						const confirm$ = new Observable((obs) => obs.complete());
						const decline$ = new Observable((obs) => obs.complete());
						const initialState = {
							header: 'Payment Frequency Updated',
							message: 'Payment Frequency Updated. Check the premium amounts again.',
							isOkBtn: true,
							confirm$,
							decline$,
							designV2: true,
						};

						this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
							class: 'modal-dialog-centered modal-dialog oat-frequency-modal',
							initialState,
							ignoreBackdropClick: true,
							keyboard: false,
						});
					}
				}),
				take(1)
			)
			.subscribe();
	}

	showPerMonth = (product) => ProductsWithPerMonth.includes(product);

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
