import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { uniq, complement, either, isNil, isEmpty } from 'ramda';
import { iif, of, throwError } from 'rxjs';
import { concatMap, filter, finalize, catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { objectUtil } from 'src/app/util/util';
import {
	DocumentUploadState,
	IntroductionState,
} from '../../../../shared/models/client-review-template/intro/intro.model';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { IntroService } from '../states/intro/intro.service';
import { LrAdviceprocessService } from '../states/lr-adviceprocess/lr-adviceprocess.service';

@Component({
	selector: 'app-intro',
	templateUrl: './intro.component.html',
	styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {
	form: UntypedFormGroup;
	intro$ = this.query.intro$;
	introduction: IntroductionState;
	isLoading = false;
	isImg = false;
	document: DocumentUploadState;
	imgExt = ['.jpg', '.png'];
	pdfExt = ['.pdf'];
	fileUrl: any;
	isPdfLoaded = false;
	showHeader: boolean;

	constructor(
		private query: ClientReviewTemplateQuery,
		private introService: IntroService,
		private sanitizer: DomSanitizer,
		private lrApService: LrAdviceprocessService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.intro$
			.pipe(
				tap(() => (this.isLoading = true)),
				filter((data) => !!data),
				tap((data) => (this.introduction = data)),
				mergeMap((data) =>
					iif(
						() =>
							data?.introType === 'UD' &&
							complement(either(isNil, isEmpty))(+data?.documentID),
						this.getIntroFile(+data?.documentID),
						this.returnLink('')
					)
				),
				finalize(() => {
					this.isLoading = false;
					this.cdr.detectChanges();
				}),
				take(1)
			)
			.subscribe();

		this.query.lrApPageCompleted$
			.pipe(
				concatMap((data) => {
					const code = 'IP';
					const list = uniq([...data, code]) || [];
					return of(list).pipe(
						tap((x) => this.lrApService.setLrApPageCompletedState(x)),
						concatMap(() => iif(
							() => !complement(either(isNil, isEmpty))(data) ||
							!data?.some((x) => x === code),
							this.lrApService.updateLrApPageCompleted(list),
							of(null)
						))
					)
				}),
				take(1)
			)
			.subscribe();
	}

	getIntroFile = (documentID: number) =>
		of(documentID).pipe(
			mergeMap((x) => this.introService.getFile(x)),
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			tap((data) => (this.document = data)),
			mergeMap((data) => {
				this.isImg = this.imgExt?.includes(data?.fileExtension);
				return iif(
					() => this.isImg,
					this.returnLink(data?.documentLink),
					this.returnPdf(data?.documentLink)
				);
			}),
			take(1)
		);

	returnLink = (docLink: string) =>
		of(docLink).pipe(
			map((x) => this.sanitizer.bypassSecurityTrustResourceUrl(x)),
			tap((x) => (this.fileUrl = x)),
			take(1)
		);

	returnPdf = (docLink: string) =>
		of(docLink).pipe(
			tap((x) => (this.fileUrl = x)),
			take(1)
		);

	onPageRendered(event: Event) {
		this.isPdfLoaded = true;
	}
}
