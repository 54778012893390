import { Moment } from 'moment';

export interface PeopleDetails {
    AdviceProcessId: number,
    Age: number,
    CRTId: number,
    CreateDateTime: string,
    CreatedByStaffId: number,
    CreatedByStaffLevel: number,
    CustomerID: number,
    CustomerType: string,
    DateOfBirth: string,
    Email: string,
    Employer: string,
    Employment: string,
    FirstName: string,
    Gender: string,
    HomePhone: string,
    KnownAs: string,
    LastName: string,
    Manual: string,
    MiddleName: string,
    MobilePhone: string,
    ModifiedByStaffId: null
    ModifiedDateTime: string,
    Occupation: string,
    PhysicalAddress: string,
    Relationship: string,
    ResidencyStatus: string,
    SectionCode: string,
    Status: number,
    WorkPhone: string,
}

export interface LOATPeopleDetails {

}

export interface MOATPeopleDetails extends PeopleDetails {
    Title: string,
    CountryOfOrigin: string,
    TimeInResidencePhysicalAddress: string,
    PreviousAddress: string,
    TimeInResidencePreviousAddress: string,
    MaritalStatus: string,
    VisaDetails: string,
}

export interface PeopleDetailsState {
    adviceProcessId: number,
    age?: number,
    cRTId: number,
    createDateTime?: string,
    createdByStaffId?: number,
    createdByStaffLevel?: number,
    customerID: number,
    customerType: string,
    dateOfBirth: string,
    email: string,
    employer: null
    employment: string,
    firstName: string,
    gender: string,
    homePhone: string,
    knownAs: string,
    lastName: string,
    manual: number,
    middleName: string,
    mobilePhone: string,
    modifiedByStaffId?: null
    modifiedDateTime?: string,
    occupation: string,
    physicalAddress: string,
    relationship: string,
    residencyStatus: string,
    sectionCode: string,
    status: number,
    workPhone: string,
    customerId?: number,
    name?: string,
    title?: string;
}

export interface LOATPeopleDetailsState extends PeopleDetailsState {
	countryOfOrigin?: string,
}

export interface MOATPeopleDetailsState extends PeopleDetailsState {
    title: string,
    countryOfOrigin: string,
    timeInResidencePhysicalAddress: string,
    previousAddress: string,
    timeInResidencePreviousAddress: string,
    maritalStatus: string,
    visaDetails: string
}

export interface KOATPeopleDetailsState extends PeopleDetailsState {
    title: string,
    countryOfOrigin: string,
    timeInResidencePhysicalAddress: string,
    previousAddress: string,
    timeInResidencePreviousAddress: string,
    maritalStatus: string,
    visaDetails: string,
    employmentStatus: string;
    occupation: string,
    income: number,
}

export interface LOATPeopleDetailsMapped extends Omit<LOATPeopleDetailsState, 'dateOfBirth'> {
    dateOfBirth: Moment | string;
}
export interface MOATPeopleDetailsMapped extends Omit<MOATPeopleDetailsState, 'dateOfBirth'> {
    dateOfBirth: Moment | string;
}

export interface KOATPeopleDetailsMapped extends Omit<KOATPeopleDetailsState, 'dateOfBirth'> {
    dateOfBirth: Moment | string;
}

export const ResidencyStatus = {
	nzCitizen: 'NZ Citizen'
}

