<div class="col-auto p-3 d-flex flex-column widget">
  <div class="description pb-2 mb-3 d-flex align-items-center">
    <span [innerHTML]="label"></span>
  </div>
  <div class="d-flex flex-column align-items-center justify-content-center content" [ngClass]="color">
    <span class="material-icons md-24">{{ icon }}</span>
    <span>
      <b>{{ total }}</b> {{ suffix }}
    </span>
  </div>
</div>