<ng-container *ngIf="isLR">
  <app-advice-process-loat-documents
    [adviceProcess]="adviceProcess"
    [isEditable]="isEditable"
    [hasCRT]="hasCRT"
    [hasLOATV2]="hasLOATV2"
    [hasFinalized]="hasFinalized"
    [finalStructure]="finalStructure"
    [crtPage]="crtPage"
    (doDownload)="downloadFile($event)"
    (doUpload)="openUploadModal($event)"
    (doReplace)="replaceFile($event)"
    (doLinkDocument)="linkDocument($event, 'lr')"
  >
  </app-advice-process-loat-documents>
</ng-container>

<ng-container *ngIf="isMortgage">
  <app-advice-process-moat-documents
  [adviceProcess]="adviceProcess"
  [isEditable]="isEditable"
  [hasMOAT]="hasMOAT"
  [crtPage]="crtPage"
  (doDownload)="downloadFile($event)"
  (doUpload)="openUploadModal($event)"
  (doReplace)="replaceFile($event)"
  (doLinkDocument)="linkDocument($event, 'm')"
  >
  </app-advice-process-moat-documents>
</ng-container>

<ng-container *ngIf="isKiwiSaver">
  <app-advice-process-koat-documents
  [adviceProcess]="adviceProcess"
  [isEditable]="isEditable"
  [hasKOAT]="hasKOAT"
  [crtPage]="crtPage"
  (doDownload)="downloadFile($event)"
  (doUpload)="openUploadModal($event)"
  (doReplace)="replaceFile($event)"
  (doLinkDocument)="linkDocument($event, 'k')"
  >
  </app-advice-process-koat-documents>
</ng-container>

<!-- DISPLAY OFFLINE FOR OTHERS -->
<ng-container *ngIf="!isLR && !isMortgage && !isKiwiSaver">
  <app-advice-process-document-base
  [adviceProcess]="adviceProcess"
  [isEditable]="isEditable"
  [hasCRT]="hasCRT"
  [crtPage]="crtPage"
  (doDownload)="downloadFile($event)"
  (doUpload)="openUploadModal($event)"
  (doReplace)="replaceFile($event)"
  (doLinkDocument)="linkDocument($event)"
	(doRemove)="removeFile($event)"
	(doView)="viewFile($event)"
	(doCliamAddDocument)="cliamAddDocument($event)"
  ></app-advice-process-document-base>
</ng-container>
