<div
	class="spinner-border"
	role="status"
	*ngIf="isLoading; else loadedContent"
	[style.height.px]="height"
	[style.width.px]="width"
>
	<span class="visually-hidden">
		<span *ngIf="text">{{ text }}</span>
	</span>
</div>

<ng-template #loadedContent>
	<ng-content></ng-content>
</ng-template>
