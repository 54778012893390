import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CrtKiwiSaverQuery } from '../../state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../../state/crt-kiwisaver.service';
import { Sidebar, SidebarStatus } from '../../../_shared/models/sidebar.model';
import {
	AdviceProcessPageCodes,
	KiwiSaverAdviceProcessPageIds,
} from '@models/advice-process/advice-process.model';
import { CrtKiwisaverFactFindGatheredInformationQuery } from './crt-ks-fact-find-gathered-information.query';
import { InformationGathered } from './crt-ks-fact-find-gathered-information.store';
import { either, isEmpty, isNil } from 'ramda';
import { PeopleQuery } from '../../people-entities/people/state/people.query';
import { CrtKiwiSaverRiskProfileQuery } from './risk-profile/risk-profile.query';
import { CrtKiwiSaverRiskProfile } from './risk-profile/risk-profile.store';
import { PeopleState } from '../../people-entities/people/state/people.model';

const ksFfPageCodes = [
	{
		id: KiwiSaverAdviceProcessPageIds.YourKiwiSaver,
		pageCode: AdviceProcessPageCodes.YourKiwiSaver,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.WhyKiwiSaver,
		pageCode: AdviceProcessPageCodes.WhyKiwiSaver,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.HowKiwiSaverWorks,
		pageCode: AdviceProcessPageCodes.HowKiwiSaverWorks,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.InvestmentStrategy,
		pageCode: AdviceProcessPageCodes.InvestmentStrategy,
	},
	// {
	// 	id: KiwiSaverAdviceProcessPageIds.PassiveVsActive,
	// 	pageCode: AdviceProcessPageCodes.PassiveVsActive,
	// },
	// {
	// 	id: KiwiSaverAdviceProcessPageIds.RiskVsVolatility,
	// 	pageCode: AdviceProcessPageCodes.RiskVsVolatility,
	// },
	{
		id: KiwiSaverAdviceProcessPageIds.KiwiSaverProjection,
		pageCode: AdviceProcessPageCodes.KiwiSaverProjection,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.RiskProfile,
		pageCode: AdviceProcessPageCodes.RiskProfile,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.Providers,
		pageCode: AdviceProcessPageCodes.Providers,
	},
];

@Injectable()
export class CrtKsFactFindTabColorsService {
	constructor(
		private koatQuery: CrtKiwiSaverQuery,
		private koatService: CrtKiwiSaverService,
		private peopleQuery: PeopleQuery,
		private ksFfInfoGatherQuery: CrtKiwisaverFactFindGatheredInformationQuery,
		private ksFfRiskProfileQuery: CrtKiwiSaverRiskProfileQuery
	) {}

	/**
	 * Disable sidebar based from Your KiwiSaver Tab
	 * @param sidebar Array of sidebar objects
	 * @returns boolean
	 */
	disableFactFindTab(sidebar: Sidebar[]): boolean {
		const appIndex =
			sidebar?.findIndex(
				(x) => x?.id === KiwiSaverAdviceProcessPageIds.FactFind
			) || 0;

		if (appIndex <= 0) {
			return false;
		}

		const disabledFF = [...sidebar]
			?.splice(0, appIndex)
			?.map((x) => x?.status === 2)
			?.includes(false);

		return disabledFF;
	}

	riskProfileIsCompleted(): boolean {
		return this.koatQuery
			.getValue()
			?.pageCompleted?.includes(AdviceProcessPageCodes.RiskProfile) ?? false;
	}

	/**
	 * Disable sidebar based from the sidebar's previous status
	 * @param sidebar Array of sidebar objects
	 * @returns boolean
	 */
	disableTabs(sidebar: Sidebar[], code: string): boolean {
		const pageCompleted = this.koatQuery.getValue().pageCompleted;
		const appIndex = sidebar?.findIndex((x) => x?.id === code) || 0;

		if (appIndex <= 0) {
			return false;
		}

		const previousTab = sidebar?.[appIndex - 1]?.pageCode;
		const isPrevComplete = previousTab
			? pageCompleted?.includes(previousTab)
			: false;

		return !isPrevComplete;
	}

	/**
	 * Disable sidebar based from Your KiwiSaver tab
	 * @param sidebar Array of sidebar objects
	 * @returns boolean
	 */
	disableTabsBasedFromYourKiwi(): boolean {
		const pageCompleted = this.koatQuery.getValue().pageCompleted;
		return !pageCompleted?.includes(AdviceProcessPageCodes.FactFind);
	}

	setTabColorYourKiwiSaver() {
		return combineLatest([
			this.koatQuery.adviceProcess$,
			this.koatQuery.pageStarted$,
			this.peopleQuery.people$,
			this.ksFfInfoGatherQuery.allFfInfoGathered$,
		]).pipe(
			tap(([ap, pageStarted, people, allFfInfoGathered]) => {
				let status = SidebarStatus.Unopened;
				let warning = '';

				const areQuestionsComplete = (data: InformationGathered) => {
					if (either(isNil, isEmpty)(data?.inKiwiSaver)) {
						// If Q1 not answered yet
						return false;
					}

					if (data?.inKiwiSaver) {
						// If Q1 is True
						if (
							!!data?.currentProvider && // Q2
							!!data?.fundType && // Q3
							!!data?.pIR && // Q4
							+data?.levelOfKiwiSaverKnowledge >= 0 && // Q5
							!!data?.mostImportantInProvider && // Q6
							!!data?.aboutCurrentProvider // Q7
						) {
							return true;
						}
						return false;
					} else {
						// If Q1 is False
						if (
							+data?.levelOfKiwiSaverKnowledge >= 0 && // Q5
							!!data?.mostImportantInProvider // Q6
						) {
							return true;
						}
						return false;
					}
				};

				if (pageStarted?.includes(AdviceProcessPageCodes.FactFind)) {
					status = SidebarStatus.Incomplete;
					warning = 'Review required fields';
				}

				const allPeopleGathered = people
					.map(
						(p: PeopleState) =>
							!!allFfInfoGathered?.find(
								(g) => +g?.customerId === +p?.customerId
							)
					)
					?.filter(Boolean)?.length;

				if (
					!!allFfInfoGathered &&
					allFfInfoGathered?.length > 0 &&
					people?.length === allPeopleGathered &&
					!allFfInfoGathered?.some((x) => !areQuestionsComplete(x))
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.koatService.setSideSidebarStatus(
					KiwiSaverAdviceProcessPageIds.FactFind,
					false,
					status,
					warning
				);
			})
		);
	}

	setTabColorForOpenOnly() {
		// For tabs that are marked as completed when opened
		return combineLatest([
			this.koatQuery.adviceProcess$,
			this.koatQuery.pageStarted$,
		]).pipe(
			tap(([ap, pageStarted]) => {
				const pageCodes = ksFfPageCodes?.filter(
					(x) =>
						![
							AdviceProcessPageCodes.YourKiwiSaver,
							AdviceProcessPageCodes.RiskProfile,
							// AdviceProcessPageCodes.InvestmentStrategy,
						]?.includes(x?.pageCode)
				);
				pageCodes?.forEach((page) => {
					if (pageStarted?.includes(page.pageCode)) {
						const status = SidebarStatus.Completed;
						this.koatService.setSideSidebarStatus(page.id, false, status, '');
					}
				});
			})
		);
	}

	setTabColorRiskProfile() {
		return combineLatest([
			this.koatQuery.adviceProcess$,
			this.koatQuery.pageStarted$,
			this.peopleQuery.people$,
			this.ksFfRiskProfileQuery.allRiskProfile$,
		]).pipe(
			tap(([ap, pageStarted, people, allRiskProfile]) => {
				let status = SidebarStatus.Unopened;
				let warning = '';

				if (pageStarted?.includes(AdviceProcessPageCodes.RiskProfile)) {
					status = SidebarStatus.Incomplete;
					warning = 'Review required fields';
				}

				const areQuestionsComplete = (data: CrtKiwiSaverRiskProfile) => {
					if (
						!!data?.mostImportantWhileSaving && // Q1
						!!data?.levelOfGainOrLoss && // Q2
						!!data?.howLongToSpendKiwiSaver // Q3
					) {
						return true;
					}
					return false;
				};

				if (
					!!allRiskProfile &&
					allRiskProfile?.length > 0 &&
					allRiskProfile?.length === people?.length &&
					!allRiskProfile?.some((x) => !areQuestionsComplete(x))
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.koatService.setSideSidebarStatus(
					KiwiSaverAdviceProcessPageIds.RiskProfile,
					false,
					status,
					warning
				);
			})
		);
	}
}
