<div class="col py-2" [class.loading]="loading">
	<div class="provider-list-body">
		<div class="d-flex flex-row my-3 py-3 px-0 list-header">
			<div class="col">
				<span>List of Providers</span>
			</div>
			<div class="col">
				<span>Logo</span>
			</div>
			<div class="col">
				<span>Description</span>
			</div>
			<div class="col">
				<span>View Link</span>
			</div>
			<div class="col">
				<span>Apply Link</span>
			</div>
			<div class="col-1 text-center">
				<span>Active</span>
			</div>
			<div class="col-1">
			</div>
		</div>
		<div class="d-flex list-items">
			<div
				class="list w-100"
				cdkDropList
				(cdkDropListDropped)="drop($event)"
				[cdkDropListData]="ProvidersList?.controls || []"
			>
				<div
					[formGroup]="item"
					class="d-flex mb-3 py-3 item drag"
					*ngFor="let item of (ProvidersList?.controls || []); let i = index; trackBy: trackByFn"
					cdkDrag
					[cdkDragData]="item"
					[cdkDragDisabled]="loading"
					[class.no-drag]="loading"
				>
					<ng-container *ngIf="!item.get('isAdding').value && !item.get('isEditing').value; else addEditTemplate">
						<div class="col-2 w-100 word-break">
							<div class="d-flex">
									<div class="pr-1">
										<span class="sortable-item">
											<i class="material-icons md-18 tap-text-color-h6"
												>drag_indicator</i
											>
										</span>
									</div>
									<div class="pl-1 w-100">
										<span class="field-text">
											{{ item.get('provider').value }}
										</span>
									</div>
							</div>
						</div>

						<div class="col-2 word-break">
							<img *ngIf="!!item.get('logoUrl').value || !!item.get('logoBase64').value" class="img-fluid photo-thumbnail" [src]="item.get('logoUrl').value || !!item.get('logoBase64').value"/>

							<div *ngIf="!(!!item.get('logoUrl').value) && !(!!item.get('logoBase64').value)" class="d-flex image-placeholder">
								<i class="fas fa-image"></i>
							</div>
						</div>

						<div class="col-2 word-break">
							<span class="field-text note-details text-break">
								{{ item.get('description').value }}
							</span>
						</div>

						<div class="col-2 word-break">
							<span class="field-text">
								{{ item.get('logoLink').value }}
							</span>
						</div>

						<div class="col-2 word-break">
							<span class="field-text">
								{{ item.get('applyLink').value }}
							</span>
						</div>

						<div class="col-1 text-center">
							<div class="switch pl-2">
								<span>
									<app-switch
										[value]="item.get('status').value === 1"
										[isEditing]="false"
										[isEditable]="true"
										[hideControl]="true"
										[isRequired]="false"
										[restrict]="[]"
										[noRestriction]="true"
									></app-switch>
								</span>
							</div>
						</div>

						<div class="col-1 text-center">
							<button class="icon-btn" (click)="edit(i)" [disabled]="loading">
								<i class="material-icons">edit</i>
							</button>
							<button class="icon-btn" (click)="delete(i)" [disabled]="loading">
								<i class="material-icons">delete</i>
							</button>
						</div>
					</ng-container>

					<ng-template #addEditTemplate>
						<div class="col-2 w-100">
							<div class="d-flex">
								<div class="pr-1">
									<span class="sortable-item">
										<i class="material-icons md-18 tap-text-color-h6"
											>drag_indicator</i
										>
									</span>
								</div>
								<span class="custom-dropdown w-100">
									<select
										formControlName="provider"
										class="form-control theme-crm-field-disabled"
										id="provider-{{ item.get('id').value }}"
									>
										<option value=""></option>
										<option *ngFor="let p of providerList" [value]="p.value">
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>

						<div class="col-2 d-flex w-100">
							<div *ngIf="!(!!item.get('logoBase64').value) && !(!!item.get('logoUrl').value)" class="d-flex image-placeholder" (click)="openUploadModal(i)">
								<i class="fas fa-image"></i>
							</div>

							<div>
								<img
									*ngIf="!!item.get('logoBase64').value || !!item.get('logoUrl').value"
									[src]="item.get('logoBase64').value || item.get('logoUrl').value"
									class="img-fluid photo-thumbnail-edit"
									(click)="openUploadModal(i)"
								/>
							</div>

							<div class="d-flex" *ngIf="!item.get('isLoading').value">
								<button
									class="d-flex btn btn-link px-1 pt-0"
									(click)="deleteCurrentImage(i)"
								>
									<i class="material-icons md-20 dark">&#xE5C9;</i>
								</button>
							</div>
						</div>

						<div class="col-2 w-100">
							<textarea
								class="form-control"
								name="description"
								id="description-{{item.get('id').value}}"
								formControlName="description"
								rows="1"
								placeholder="Description"
								[attr.disabled]="item.get('isLoading').value || null"
								(mousedown)="$event.stopPropagation()"
							></textarea>
						</div>

						<div class="col-2 w-100">
							<input
								class="form-control"
								name="logoLink"
								id="logoLink-{{item.get('id').value}}"
								formControlName="logoLink"
								placeholder="View Link"
								[attr.disabled]="item.get('isLoading').value || null"
								(mousedown)="$event.stopPropagation()"
							/>
						</div>

						<div class="col-2 w-100">
							<input
								class="form-control"
								name="applyLink"
								id="applyLink-{{item.get('id').value}}"
								formControlName="applyLink"
								placeholder="Apply Link"
								[attr.disabled]="item.get('isLoading').value || null"
								(mousedown)="$event.stopPropagation()"
							/>
						</div>

						<div class="col-1 text-center">
							<div class="switch pl-2">
								<span>
									<app-switch
										[tempValue]="item.get('status').value === 1"
										fieldId="switch-{{ item.get('id').value  }}"
										[isEditing]="true"
										[isEditable]="true"
										[hideControl]="true"
										[isRequired]="false"
										[restrict]="[]"
										[noRestriction]="true"
										(fieldUpdateEvent)="updateStatus(i)"
									></app-switch>
								</span>
							</div>
						</div>

						<div class="col-1 text-center">
							<div class="d-flex justify-content-center">
								<ng-container *ngIf="item.get('isLoading').value">
									<app-manual-loader-mini
										[isLoading]="true"
									></app-manual-loader-mini>
								</ng-container>

								<ng-container *ngIf="!item.get('isLoading').value">
									<!-- Save button -->
									<button
										class="btn btn-link px-1 py-0"
										(click)="saveAddEdit(i)"
									>
										<i class="material-icons md-20 dark">save</i>
									</button>

									<!-- Close button -->
									<button
										class="btn btn-link px-1 py-0"
										(click)="cancelAddEdit(i)"
									>
										<i class="material-icons md-20 dark">&#xE5C9;</i>
									</button>
								</ng-container>
							</div>
						</div>


					</ng-template>
				</div>
			</div>
		</div>
		<div class="d-flex btn-wrapper-add">
			<div class="col-1">
				<button
					type="button"
					class="btn btn-primary tap-bg-primary"
					(click)="addNew()"
					[disabled]="loading"
				>
					+ Add
				</button>
			</div>
		</div>
		<div class="d-flex btn-wrapper-save">
			<div class="col" *ngIf="loading">
				<app-manual-loader-mini
					[isLoading]="true"
				></app-manual-loader-mini>
			</div>

			<div class="col" *ngIf="!loading">
				<button
					type="button"
					class="btn btn-primary tap-bg-primary btn-save mr-1"
					(click)="saveChanges()"
					[disabled]="!didReorder"
				>
					Save
				</button>
				<button
					type="button"
					class="btn btn-light ml-1"
					(click)="cancelChanges()"
				>
					Cancel
				</button>
			</div>
		</div>
	</div>
</div>
