import { FactFindSubpagesSettingsState } from './fact-find-subpages-settings.model';
import { SettingsTypes } from '../../../state/kiwisaver-settings.model';

export class FactFindSettingsMapper {
	public static mapToView(data: FactFindSubpagesSettingsState) {
		const {
			whyKiwiSaver,
			howKiwiSaverWorks,
			passiveVsActive,
			riskVsVolatility,
			investmentStrategy
		} = data;

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFind,
			whyKiwiSaver,
			howKiwiSaverWorks,
			passiveVsActive,
			riskVsVolatility,
			investmentStrategy,
		};
	}

	public static mapToUpsert(data: FactFindSubpagesSettingsState) {
		const {
			whyKiwiSaver,
			howKiwiSaverWorks,
			passiveVsActive,
			riskVsVolatility,
			investmentStrategy
		} = data;

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFind,
			whyKiwiSaver,
			howKiwiSaverWorks,
			passiveVsActive,
			riskVsVolatility,
			investmentStrategy,
		};
	}
}
