<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="title"></app-header>
</div>

<!-- Conttent -->
<div role="main-content" #sosDocument>
	<div class="scope-of-service clearfix sos-document">
		<div class="scope-of-service__content">
			<form [formGroup]="form" class="form" class="mb-3">
				<div class="row pb-2">
					<div class="col-12">
						<div class="row">
							<div class="col-12 scope-of-service__labels">
								<label
									class="label-font font-weight-bold tap-text-primary hide-from-pdf"
								>
									What does the client need help with?
								</label>
								<label
									class="label-font font-weight-bold tap-text-primary show-on-pdf-only"
								>
									Services
								</label>
							</div>
						</div>
					</div>
				</div>

				<div class="row pb-0 pb-2 scope-of-service__client-services">
					<div class="col-12">
						<div class="row">
							<div class="col-12 col-lg-auto pr-lg-5">
								<ng-container *ngFor="let input of inputChecboxes.slice(0,3);trackBy: trackByFn;">
									<div class="row scope-of-service__services">
										<div class="col">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													class="chckbox custom-control-input"
													[id]="input.id"
													[formControlName]="input.id"
													[checked]="+input.checkboxes?.value === 1"
													(change)="tickChange($event)"
												/>
												<label class="custom-control-label" [for]="input.id"
													>{{input.label}}</label
												>
											</div>
										</div>
									</div>
								</ng-container>
							</div> 

							<div class="col-12 col-lg-auto" *ngIf="inputChecboxes.length>3" >
								<ng-container *ngFor="let input of inputChecboxes.slice(3,6);trackBy: trackByFn;">
									<div class="row scope-of-service__services">
										<div class="col">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													class="chckbox custom-control-input"
													[id]="input.id"
													[formControlName]="input.id"
													[checked]="+input.checkboxes?.value === 1"
													(change)="tickChange($event)"
												/>
												<label class="custom-control-label" [for]="input.id"
													>{{input.label}}</label
												>
											</div>
										</div>
									</div>
								</ng-container>

							</div>
						</div>
					</div>
				</div>

				<div
					class="row pb-0 pb-2"
					*ngIf="showOtherService && OtherService.value"
				>
					<div class="col-12">
						<div class="row">
							<div class="col-12 scope-of-service__textbox">
								<textarea
									class="form-control"
									name="otherServiceNotes"
									id="otherServiceNotes"
									cols="30"
									rows="4"
									formControlName="otherServiceNotes"
									(change)="onChange()"
								></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row pb-0">
					<div class="col-12">
						<div class="row">
							<div class="col-12">
								<label class="font-weight-bold"
									>Other specific client goals:</label
								>
							</div>
						</div>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-12">
						<div class="row">
							<div class="col-12">
								<textarea
									#contentEditor
									class="form-control theme-crm-field-disabled"
									formControlName="otherSpecificClientGoals"
									rows="18"
									id="otherSpecificClientGoals"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row pb-2">
					<div class="col-12">
						<div class="row">
							<div class="col-12 scope-of-service__textbox">
								<p [innerHTML]="Intro.value"></p>
							</div>
						</div>
					</div>
				</div>

				<div class="row pb-0 pb-2">
					<div class="col-12">
						<div class="row">
							<div class="col-12">
								<label class="font-weight-bold tap-text-primary"
									>Other Advice:</label
								>
							</div>
						</div>
					</div>
				</div>

				<div class="row pb-0 b-2 pt-2">
					<div class="col-12">
						<ng-container *ngIf="showKiwiSaverAdvice">
							<div class="row">
								<div class="col-lg-12 col-12">
									<div class="row scope-of-service__services">
										<div class="col">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													class="chckbox custom-control-input"
													id="kiwiSaverAdvice"
													formControlName="kiwiSaverAdvice"
													[checked]="+KiwiSaverAdvice?.value === 1"
													(change)="tickChange($event)"
												/>
												<label
													class="custom-control-label tap-text-primary-pdf"
													for="kiwiSaverAdvice"
													>KiwiSaver Advice</label
												>
											</div>
										</div>
									</div>

									<div
										class="row pb-3 scope-of-service__services"
										*ngIf="KiwiSaverAdvice.value"
									>
										<div class="col-12 scope-of-service__textbox">
											<p [innerHTML]="KiwiSaveAdviceDetails.value"></p>
										</div>
									</div>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="showLifeAndRiskReview">
							<div class="row">
								<div class="col-lg-12 col-12">
									<div class="row scope-of-service__services">
										<div class="col">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													class="chckbox custom-control-input"
													id="lifeAndRiskReview"
													formControlName="lifeAndRiskReview"
													[checked]="+LifeAndRiskReview?.value === 1"
													(change)="tickChange($event)"
												/>
												<label
													class="custom-control-label tap-text-primary-pdf"
													for="lifeAndRiskReview"
													>Life & Risk Review</label
												>
											</div>
										</div>
									</div>

									<div
										class="row pb-3 scope-of-service__services"
										*ngIf="LifeAndRiskReview.value"
									>
										<div class="col-12 scope-of-service__textbox">
											<p [innerHTML]="LifeAndRiskReviewDetails.value"></p>
										</div>
									</div>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="showHouseCarContentsQuote">
							<div class="row">
								<div class="col-lg-12 col-12">
									<div class="row scope-of-service__services">
										<div class="col">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													class="chckbox custom-control-input"
													id="houseCarContentsQuote"
													formControlName="houseCarContentsQuote"
													[checked]="+HouseCarContentsQuote?.value === 1"
													(change)="tickChange($event)"
												/>
												<label
													class="custom-control-label tap-text-primary-pdf"
													for="houseCarContentsQuote"
													>House, Car & Contents Insurance</label
												>
											</div>
										</div>
									</div>

									<div
										class="row pb-3 scope-of-service__services"
										*ngIf="HouseCarContentsQuote.value"
									>
										<div class="col-12 scope-of-service__textbox">
											<p [innerHTML]="HouseCarContentsQuoteDetails.value"></p>
										</div>
									</div>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="showOtherAdvice">
							<div class="row">
								<div class="col-lg-12 col-12">
									<div class="row scope-of-service__services">
										<div class="col">
											<div class="custom-control custom-checkbox">
												<input
													type="checkbox"
													class="chckbox custom-control-input"
													id="otherAdvice"
													formControlName="otherAdvice"
													[checked]="+OtherAdvice?.value === 1"
													(change)="tickChange($event)"
												/>
												<label
													class="custom-control-label tap-text-primary-pdf"
													for="otherAdvice"
													>Other</label
												>
											</div>
										</div>
									</div>
	
									<div
										class="row scope-of-service__services"
										*ngIf="OtherAdvice.value"
									>
										<div class="col-12 scope-of-service__textbox">
											<textarea
												class="form-control"
												name="otherAdviceNotes"
												id="otherAdviceNotes"
												cols="30"
												rows="4"
												formControlName="otherAdviceNotes"
												(change)="onChange()"
											></textarea>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</form>
		</div>
	</div>

	<!-- Action Buttons -->
	<div class="py-4 scope-of-service__buttons clearfix">
		<button
			type="button"
			class="tap-btn tap-btn-outline tap-btn--shadow mr-2"
			[disabled]="isSaving || isLoading || !defaultSetting"
			(click)="onClickEmail()"
			*ngIf="(emailSettings$ | async)?.isEnableEmailOption"
		>
			Email Client
			<app-manual-loader-mini
				*ngIf="!(emailSettings$ | async)"
				[isLoading]="true"
			></app-manual-loader-mini>
		</button>
		<button
			type="button"
			class="tap-btn tap-btn-outline tap-btn--shadow mr-2"
			[disabled]="isSaving || isLoading || !defaultSetting"
			(click)="saveDocument()"
		>
			Save to File
		</button>
		<button
			type="button"
			class="tap-btn tap-btn-outline tap-btn--shadow mr-2"
			[disabled]="isSaving || isLoading || !defaultSetting"
			(click)="reloadTemplate()"
		>
			Reload Template
		</button>
		<div *ngIf="isSaving" class="d-inline-block">
			<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
		</div>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer [currentPageId]="pageIds.SOS" [sidebar]="sidebar"></app-footer>
</div>
