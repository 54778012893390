import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Renderer2,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, map, withLatestFrom, first, finalize, take, tap, mergeMap } from 'rxjs/operators';
import { InvestmentService } from '../states/investment.service';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../../util/moment.util';
import { InvestmentRequest, InvestmentRequestModel } from '../investment-request.model';
import { InvestmentQuery } from '../states/investment.query';
import { UserQuery } from '../../../../../domain/user/user.query';
import { RecursivePartial } from '../../../../../core/util/util.service';
import { ActivatedRoute } from '@angular/router';
import { strUtil } from '../../../../../util/util';
import { InvestmentUiQuery } from '../states/investment-ui.query';
import { exportPayload } from '../investment.util';
import { InvestmentUiStore } from '../states/investment-ui.store';
import { ChipsComponent } from '../../../../../shared/chips/chips.component';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { AdviserStatusViewDisplay } from '../../pipeline.model';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsStatus, ExportsType } from '@modules/exports/state/exports.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ExportsService } from '@modules/exports/state/exports.service';
import { omit } from 'ramda';
declare var $: any;

const patchValue: <T>(form: AbstractControl, value: RecursivePartial<T>) => void = (form, value) => {
  form.patchValue(value);
};

@Component({
  selector: 'app-investment-form',
  templateUrl: './investment-form.component.html',
  styleUrls: ['./investment-form.component.scss'],
})
export class InvestmentFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild(PopoverDirective) popover: PopoverDirective;

  exportStatus$ = this.exportsQuery.status$;

  isSearching$ = this.investmentQuery.uiQuery.isSearching$;
  isExporting$ = this.investmentQuery.uiQuery.isExporting$;
  columnFromOpen$ = this.investmentQuery.uiQuery.columnFormPopupOpen$;
	adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;
  openColumnPopup = this.investmentService.togglePopup;

  advisers$: Observable<ViewDisplayValue[]> = this.bLStaffsQuery.InvestmentAdviserChoicesOption$
  AdviserStatus$: Observable<ViewDisplayValue[]> = of(AdviserStatusViewDisplay);

  IP$ = this.investmentService.IP$;
  IT$ = this.investmentService.IT$;
  IS$ = this.investmentService.IS$;
  PCLE$ = this.investmentService.PCLE$;

  isTapLevel$ = this.userQuery.isTapLevel$;
  userInfo$ = this.userQuery.userInfo$;

  // tslint:disable-next-line: no-angle-bracket-type-assertion
  form: UntypedFormGroup = this.fb.group(<{ [key in keyof InvestmentRequestModel]: UntypedFormControl }> {
    SelectedAdvisers: this.fb.control([]),
    SelectedAltAdvisers: this.fb.control([]),
    SelectedAdviserStatuses: this.fb.control([]),
    SelectedProviders: this.fb.control([]),

    SelectedStatus: this.fb.control([]),
    SelectedLeadOrigins: this.fb.control([]),
		SelectedInvestmentType: this.fb.control([]),

    // SelectedOrigins: this.fb.control([]),

    NextActivityDateMin: this.fb.control(null),
    NextActivityDateMax: this.fb.control(null),
    StartDateMin: this.fb.control(null),
    StartDateMax: this.fb.control(null),

    NextReviewDateMin: this.fb.control(null),
    NextReviewDateMax: this.fb.control(null),
  });

  count$ = this.investmentQuery.count$;
  totalApi$ = this.investmentQuery.totalApi$;
  @Output() toggleSearch = new EventEmitter<{
    showSearch: boolean;
    height: number;
    width: number;
  }>();
  showSearch = false;
  isTapLevel: boolean;
  params$ = this.route.paramMap.pipe(takeUntil(this.onDestroy$));

  ////////// Progress Bar //////////////
  progress = {
    width: '0%',
  };
  index = 0;
  percent = 0;
  count = 0;
  totalCount = 0;
  msg = 'Export Progress';
  ///////// END Progress Bar ////////

  @ViewChild('selectedStatus') selectedStatus: ChipsComponent;
  @ViewChild('SelectedAdvisers') SelectedAdvisers: ChipsComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private investmentService: InvestmentService,
    private investmentQuery: InvestmentQuery,
    private userQuery: UserQuery,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private investmentUiQuery: InvestmentUiQuery,
    private investmentUiStore: InvestmentUiStore,
    private activitedRoute: ActivatedRoute,
    private bLStaffsQuery: BLStaffsQuery,
    private exportsQuery: ExportsQuery,
    private businessConfigQuery: BusinessConfigQuery,
		private exportsService: ExportsService
  ) {
    this.onDestroy$ = new Subject<void>();
  }

  ngOnInit() {

    this.params$
      .pipe(
        withLatestFrom(this.isTapLevel$, this.userInfo$, this.IS$),
        map(([params, isTapLevel, , is]) => {
          this.isTapLevel = isTapLevel;
          const adv = params.get('advisers');
          const stat = params.get('statuses');
          if (params && (adv || stat)) {
            const req = JSON.parse(JSON.stringify(this.prepareFormValue()));
            this.investmentService.setSearchForm({
              ...req,
              SelectedAdvisers: !!adv ? adv?.split(',') : [],
              SelectedStatuses: strUtil.stringIsNotEmpty(stat)
                ? stat?.split(',').filter(x => is?.find(y => y.value === x))
                : [],
            });
            return {
              hasStatus: stat ? true : false,
              SelectedAdvisers: strUtil.stringIsNotEmpty(adv) ? adv?.split(',') : [],
              SelectedStatus: strUtil.stringIsNotEmpty(stat)
                ? stat?.split(',').filter(x => is?.find(y => y.value === x))
                : [],
            };
          } else if (!isTapLevel) {
            const policyStatusList = ['Lead', 'Submitted', 'Info Required'];
            return {
              SelectedStatus: policyStatusList?.filter(x => is?.find(y => y.value === x)),
            };
          } else {
            const policyStatusList = ['Lead', 'Submitted', 'Info Required'];
            return {
              SelectedStatus: policyStatusList?.filter(x => is?.find(y => y.value === x)),
            };
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fValue => {
        this.reset();
        if (fValue) {
          patchValue(this.form, fValue);
          if (this.isTapLevel) {
            this.investmentService?.sort('Status', 'desc');
          } else {
            this.investmentService?.sort('ClientNextActivity', 'desc');
          }
          if (fValue && fValue.hasStatus) {
            this.search();
          }
        }
      });
  }

  ngAfterViewInit() {
    this.SelectedAdvisers.onFocus();
    this.selectedStatus.onFocus();

    if (this.investmentQuery.getValue().count > 0 || this.investmentQuery.getValue().searchForm) {
      const advisers = this.investmentQuery.getValue().searchForm.SelectedAdvisers;
      const altAdvisers = this.investmentQuery.getValue().searchForm.SelectedAltAdvisers;
      const p = this.investmentQuery.getValue().searchForm.SelectedProviders;
      const lo = this.investmentQuery.getValue().searchForm.SelectedLeadOrigins;
      const as = this.investmentQuery.getValue().searchForm.SelectedAdviserStatuses;
      const st = this.investmentQuery.getValue().searchForm.SelectedInvestmentType;

      const naMin = this.investmentQuery.getValue().searchForm.NextActivityDateMin;
      const naMax = this.investmentQuery.getValue().searchForm.NextActivityDateMax;
      const sMin = this.investmentQuery.getValue().searchForm.StartDateMin;
      const sMax = this.investmentQuery.getValue().searchForm.StartDateMax;
      const nrMin = this.investmentQuery.getValue().searchForm.NextReviewDateMin;
      const nrMax = this.investmentQuery.getValue().searchForm.NextReviewDateMax;

      this.form.get('SelectedAdvisers').setValue(advisers?.map(x => x?.toString()));
      this.form.get('SelectedAltAdvisers').setValue(altAdvisers?.map(x => x?.toString()));
      this.form.get('SelectedProviders').setValue(p?.map(x => x?.toString()));
      this.form.get('SelectedLeadOrigins').setValue(lo?.map(x => x?.toString()));
      this.form.get('SelectedAdviserStatuses').setValue(as?.map(x => x?.toString()));
      this.form.get('SelectedInvestmentType').setValue(st?.map(x => x?.toString()));
      this.form.get('NextActivityDateMin').setValue(MomentUtil.formatDateToMoment(naMin));
      this.form.get('NextActivityDateMax').setValue(MomentUtil.formatDateToMoment(naMax));
      this.form.get('StartDateMin').setValue(MomentUtil.formatDateToMoment(sMin));
      this.form.get('StartDateMax').setValue(MomentUtil.formatDateToMoment(sMax));
      this.form.get('NextReviewDateMin').setValue(MomentUtil.formatDateToMoment(nrMin));
      this.form.get('NextReviewDateMax').setValue(MomentUtil.formatDateToMoment(nrMax));
    }
  }

  public prepareFormValue(): InvestmentRequest {
    const formValue: formType = this.form.value;

    const req: InvestmentRequest = {
      ...formValue,
      SelectedAdvisers: formValue.SelectedAdvisers?.map(x => +x),
      SelectedAltAdvisers: formValue.SelectedAltAdvisers?.map(x => +x),
      SelectedProviders: formValue.SelectedProviders?.map(x => x),
      SelectedStatus: formValue.SelectedStatus?.map(x => x),
      SelectedLeadOrigins: formValue.SelectedLeadOrigins?.map(x => x),
      SelectedInvestmentType: formValue.SelectedInvestmentType?.map(x => x),

      NextActivityDateMin: MomentUtil.formatToServerDate(formValue.NextActivityDateMin),
      NextActivityDateMax: MomentUtil.formatToServerDate(formValue.NextActivityDateMax),
      StartDateMin: MomentUtil.formatToServerDate(formValue.StartDateMin),
      StartDateMax: MomentUtil.formatToServerDate(formValue.StartDateMax),
      NextReviewDateMin: MomentUtil.formatToServerDate(formValue.NextReviewDateMin),
      NextReviewDateMax: MomentUtil.formatToServerDate(formValue.NextReviewDateMax),
    };
    return req;
  }

  search() {
    const req = JSON.parse(JSON.stringify(this.prepareFormValue()));

    this.investmentUiQuery.currentSort$
      .pipe(
        first(),
        map(x => {
          req.Paging = {
            Index: 1,
            Column: x.propSort,
            Direction: x.sort,
          };
        }),
        finalize(() => {
          this.investmentService
            .search(req)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              setTimeout(() => $('datatable-body').scrollTop(1).scrollLeft(1), 1);
              setTimeout(() => $('datatable-body').scrollTop(0).scrollLeft(0), 1);
            });
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  /**
   * Api calls (Endpoint)
   * @returns void
   * Downloads file in .csv format
   */
  //////////////////////////////////// EXPORT /////////////////////////////////
  export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		this.index++;
		const req = exportPayload(this.prepareFormValue());

		this.exportsService
			.queueExport(req, ExportsType.IE)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(
						this.exportsService.getExportStatus(id)
					)
				)
			)
			.subscribe({
				next: (data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport(data.DocumentLink);
						this.hidePopover();
					}
				},
				error: () => {
					this.hidePopover();
				},
			});

		setTimeout(() => {
			this.popover.hide();
		}, 2500);
	}

	downloadExport(url: string) {
		const name =
			this.activitedRoute.snapshot.paramMap.get('companyCode') +
			'-Investment.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

  clearExportProgress() {
    this.index = 0;
    this.percent = 0;
    this.progress = {
      width: '0%',
    };
    this.totalCount = 0;
  }
  //////////////////////////////////// END EXPORT /////////////////////////////////

  toggle() {
    this.showSearch = !this.showSearch;
    document.body.style.overflowY = 'hidden';
    let x = 0;
    let data;
    const setIntervalHeight = setInterval(() => {
      if (x < 1 && !data) {
        x += 1;
        data = {
          showSearch: this.showSearch,
          height: $('form').height(),
          width: $('form').width(),
        };
      } else if (x > 0) {
        x += 1;
        if (data.height !== $('form').height()) {
          data.height = $('form').height();
        } else {
          this.toggleSearch.emit(data);
          clearInterval(setIntervalHeight);
        }
      }
    }, 100);
  }

  reset() {
    this.form.get('SelectedAdvisers').reset([]);
    this.form.get('SelectedAltAdvisers').reset([]);
    this.form.get('SelectedAdviserStatuses').reset([]);
    this.form.get('SelectedProviders').reset([]);
    this.form.get('SelectedStatus').reset([]);
    this.form.get('SelectedLeadOrigins').reset([]);
    this.form.get('SelectedInvestmentType').reset([]);
    // this.form.get('SelectedOrigins').reset([]);

    this.form.get('NextActivityDateMin').reset(null);
    this.form.get('NextActivityDateMax').reset(null);
    this.form.get('StartDateMin').reset(null);
    this.form.get('StartDateMax').reset(null);

    this.form.get('NextReviewDateMin').reset(null);
    this.form.get('NextReviewDateMax').reset(null);
  }

  showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}

type formType = { [key in keyof InvestmentRequestModel]: any };
