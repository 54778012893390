import { Injectable } from '@angular/core';
import { NBLRState, NBLRStore } from './nblr.store';
import { Query } from '@datorama/akita';

@Injectable()
export class NBLRQuery extends Query<NBLRState> {

  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create lead Conversions query
   */
  data$ = this.select(state => state.NblrOpportunity);

  constructor(protected store: NBLRStore) {
    super(store);
  }
}
