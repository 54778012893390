<div class="clearfix mb-2">
	<ng-container *ngIf="isLoading$ | async as isLoading">
		<app-manual-loader-mini [isLoading]="isLoading"> </app-manual-loader-mini>
	</ng-container>
</div>

<ng-container *ngIf="!(isLoading$ | async)">
	<ng-container *ngIf="!(hasIncomplete$ | async)">
		<div class="clearfix mb-4">
			<ng-container
				*ngIf="isMergeTagLoading || (isSavingSOA$ | async) as isLoading"
			>
				<app-manual-loader-mini [isLoading]="isLoading">
				</app-manual-loader-mini>
			</ng-container>
			<ng-container *ngIf="!isMergeTagLoading && !(isSavingSOA$ | async)">
				<button
					id="createNewSoaBtn"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
					(click)="createNewSoa()"
				>
					Create New SOA
				</button>
			</ng-container>
		</div>
	</ng-container>

	<ng-container *ngIf="hasIncomplete$ | async">
		<div class="clearfix mb-2">
			<label class="label-font tap-text-primary font-weight-bold mb-2"
				>Incomplete SOA</label
			>
		</div>

		<ng-container
			*ngIf="isMergeTagLoading || (isSavingSOA$ | async) as isLoading"
		>
			<div class="clearfix mb-3">
				<app-manual-loader-mini [isLoading]="isLoading">
				</app-manual-loader-mini>
			</div>
		</ng-container>
		<ng-container *ngIf="!isMergeTagLoading && !(isSavingSOA$ | async)">
			<div
				class="clearfix mb-4"
				*ngFor="let soa of incompleteSoa$ | async | slice: 0:1"
			>
				<button
					id="viewSoaBtn"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto mr-2"
					(click)="viewSoa(soa.cRTId)"
				>
					View SOA
				</button>
				<button
					id="resetSoaBtn"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto mx-2"
					(click)="resetSoa(soa.cRTId)"
				>
					Reset SOA
				</button>
			</div>
		</ng-container>
	</ng-container>

	<div class="form-row">
		<div class="col-12 col-lg-12 border-bottom mt-2 mb-3"></div>
	</div>
	<!-- if soa has been completed -->
	<div class="clearfix mb-2">
		<label class="label-font tap-text-primary font-weight-bold mb-0"
			>Previous SOA</label
		>
	</div>
	<ng-container *ngIf="soaCompletedAndFinalized$ | async as soaList">
		<ng-container *ngIf="soaList?.length > 0; else noSOA">
			<div
				class="form-row mb-2 align-items-center"
				*ngFor="let soa of soaList; trackBy: trackBySOA; let i = index; let last = last"
			>
				<div class="col-12 col-lg-12 mb-2" [class.border-bottom]="!last">
					<div class="form-row mb-2">
						<div
							class="col-12 col-md-6 col-lg-6 d-lg-flex align-items-center mb-1 rename-soa"
						>
							<ng-template #showName>
								<span class="soa-name">
									<i class="material-icons md-16 mr-2">description</i>
									{{ soa?.name || "Statement Of Advice" }}
								</span>
								<button
									id="renameBtn_{{ i }}"
									title="Rename"
									class="icon-btn ml-1"
									[disabled]="showLoader(soa) || renamingSOA"
									(click)="renameSOA(soa.cRTId, soa?.name)"
								>
									<i class="fas fa-edit"></i>
								</button>
							</ng-template>

							<ng-container *ngIf="isRenamingSOA(+soa.cRTId); else showName">
								<div class="col-md-12 col-lg-12 p-0 m-0">
									<form [formGroup]="form" class="form d-flex">
										<div class="col-12 p-0 m-0">
											<span class="d-block d-xl-inline-block rename-soa__name">
												<i class="material-icons md-16 mr-2">description</i>
												{{ placeholderName(soa?.name) }} -&nbsp;
											</span>
											<input
												id="soaNameId_{{ i }}"
												class="form-control crt-form-control rename-soa__input text-uppercase d-inline-block"
												formControlName="soaName"
											/>
											<span
												class="d-block d-xl-inline-block rename-soa__action-btns p-0"
											>
												<button
													id="saveRenameBtn_{{ i }}"
													title="Save"
													class="icon-btn ml-0 ml-lg-2"
													[disabled]="SoaName.value === '' || showLoader(soa)"
													(click)="saveRename(soa.cRTId, soa.name)"
												>
													<i class="material-icons md-16"> save </i>
												</button>
												<button
													id="cancelRenameBtn_{{ i }}"
													title="Cancel"
													class="icon-btn"
													[disabled]="showLoader(soa)"
													(click)="cancelRename()"
												>
													<i class="material-icons md-16"> close </i>
												</button>
											</span>
										</div>
									</form>
								</div>
							</ng-container>
						</div>
						<div
							class="col-12 col-md-2 col-lg-2 d-lg-flex align-items-center mb-1 pl-2"
						>
							{{ soa.createDateTime | momentDatetime: true }}
						</div>

						<div
							class="col-12 col-md-4 col-lg-4 justify-content-md-end justify-content-lg-end align-items-center d-lg-flex mb-1"
						>
							<ng-container *ngIf="showLoader(soa)">
								<app-manual-loader-mini [isLoading]="true">
								</app-manual-loader-mini>
							</ng-container>

							<ng-container *ngIf="!showLoader(soa)">
								<span class="mr-0 mr-lg-2">
									<button
										id="cloneSoaBtn_{{ i }}"
										[disabled]="!soa.document || renamingSOA"
										[class.disabled]="!soa.document || renamingSOA"
										class="icon-btn"
										title="Duplicate"
										(click)="cloneSOA(soa); soa.isSaving = true"
									>
										<i class="fas fa-clone fa-16"></i>
									</button>
								</span>
								<span class="mr-0 mr-lg-2">
									<button
										id="downloadPdf_{{ i }}"
										[disabled]="!soa.document || renamingSOA"
										[class.disabled]="!soa.document || renamingSOA"
										class="icon-btn"
										title="Download"
										(click)="downloadPDF(soa); soa.isDownloading = true"
									>
										<i class="fas fa-download fa-16"></i>
									</button>
								</span>
								<ng-container
									*ngIf="soa.currentPage == 'Finalized'; else soaNotFinalized"
								>
									<span class="mr-0 mr-lg-2">
										<button
											id="saveToFileBtn_{{ i }}"
											class="tap-btn tap-btn-outline tap-btn--shadow px-4"
											[disabled]="renamingSOA"
											(click)="saveDocument(soa)"
										>
											Save to File
										</button>
									</span>
								</ng-container>
								<ng-template #soaNotFinalized>
									<span class="mr-0 mr-lg-2">
										<button
											id="editSoaBtn_{{ i }}"
											class="icon-btn"
											[disabled]="renamingSOA"
											(click)="editSoa(soa.cRTId)"
											title="Edit"
										>
											<i class="material-icons md-16">edit</i>
										</button>
									</span>
									<span class="mr-0 mr-lg-2">
										<button
											id="deleteSoaBtn_{{ i }}"
											title="Delete"
											class="icon-btn"
											[disabled]="renamingSOA"
											(click)="removeSoa(soa); soa.isDeleting = true"
										>
											<i class="material-icons md-16">delete</i>
										</button>
									</span>
									<span class="mr-0 mr-lg-2">
										<button
											id="finalizeSoaBtn_{{ i }}"
											class="tap-btn tap-btn-outline tap-btn--shadow px-4"
											[disabled]="renamingSOA"
											(click)="finalize(soa); soa.isFinalizing = true"
										>
											Finalize
										</button>
									</span>
								</ng-template>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #noSOA>
			<p class="py-2">No Statement of Advice yet.</p>
		</ng-template>
	</ng-container>
</ng-container>
