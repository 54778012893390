import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MergeTagsService } from '@modules/user/service/merge-tags.service';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, map, tap } from 'rxjs/operators';
import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';

@Component({
  selector: 'app-disclosure-merge-tag',
  templateUrl: './disclosure-merge-tag.component.html',
  styleUrls: ['./disclosure-merge-tag.component.scss'],
})
export class DisclosureMergeTagComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  @Input() staffId: number;

  mergeTagsList: MergeTagState[] = [];

  constructor(
    private mtService: MergeTagsService
  ) {}

  ngOnInit(): void {
    this.mtService.mergeTags$
      .pipe(
        map((mt) => MergeTagsMapper.mapMergeTags(mt)),
        filter((data) => !!data),
        tap((data) => (this.mergeTagsList = data)),
        takeUntil(this.onDestroy$)
      )
    .subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
