import { FamilyHistoryState } from './family.model';

export class FamilyHistoryMapper {
	/** Mapping for view */
	static mapToView(data: FamilyHistoryState) {
		if (!data) {
			return { ...data };
		}
		const val = {
			...data
		};
		return val;
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: FamilyHistoryState, personInfo: FamilyHistoryState): FamilyHistoryState {
		return {
			...data,
			adviceProcessId: personInfo?.adviceProcessId,
			cRTId: personInfo?.cRTId | 0,
			sectionCode: 'FMF',
			status: 1
		};
	}
}
