import { util } from 'src/app/core/util/util.service';
import MomentUtil from 'src/app/util/moment.util';
import { DependentDetailsState } from './dependent-details';

export class DependentMapper {
    /** Mapping for view */
    static mapToView(data: DependentDetailsState) {
        if (!data) {
            return { ...data };
        }
				    const { age, ...others } = data;
        const val = {
            ...others,
            dateOfBirth: util.isValidMoment(data.dateOfBirth) ? data.dateOfBirth : MomentUtil.formatDateToMoment(data.dateOfBirth),
        };
        return val;
    }

    /**
     * Map to update
     */
    public static mapToUpsert(data: DependentDetailsState, personInfo: DependentDetailsState): DependentDetailsState {
			const { age, ...others } = data;
   return {
            ...others,
            dateOfBirth: MomentUtil.formatDateToServerDate(data.dateOfBirth),
            customerID: personInfo?.customerID,
            adviceProcessId: personInfo?.adviceProcessId,
            cRTId: personInfo?.cRTId,
            sectionCode: 'FPD',
            status: personInfo?.status
        };
    }
}
