import { MergeTagTypeCode } from '../../../merge-tags.model';

export const appFundingRequiredMetaKey = {
	// Property Purchase
	propertyPurchaseAddress: 'APPLICATION_NEW_PROPERTY_PURCHASE_ADDRESS',
	propertyPurchasePrice: 'APPLICATION_NEW_PROPERTY_PURCHASE_PRICE',
	propertyPurchaseTotalPrice: 'APPLICATION_NEW_PROPERTY_PURCHASE_TOTAL_PRICE',
	propertyPurchaseLvr: 'APPLICATION_NEW_PROPERTY_PURCHASE_LVR',

	// Loans to Refinance
	refinancedLoanAmount: 'APPLICATION_REFINANCED_LOAN_AMOUNT',
	refinancedLoanAddress: 'APPLICATION_REFINANCED_LOAN_ADDRESS',

	// Top up Requirement
	topupRequirementAmount: 'APPLICATION_TOPUP_REQUIREMENT_AMOUNT',
	topupRequirementAddress: 'APPLICATION_TOPUP_REQUIREMENT_ADDRESS',

	// Property to be Sold
	propertyToBeSoldPrice: 'APPLICATION_SOLD_PROPERTY_PRICE',
	propertyToBeSoldAddress: 'APPLICATION_SOLD_PROPERTY_ADDRESS',

	// Loans to be repaid by sale
	loansToBeRepaidAmount: 'APPLICATION_LOANS_REPAID_AMOUNT',
	loansToBeRepaidSecurity: 'APPLICATION_LOANS_REPAID_SECURITY',

	// Totals
	totalSecurityLvr: 'APPLICATION_SECURITY_TOTAL_LVR',
	totalSecurityScaledLvr: 'APPLICATION_SECURITY_SCALED_LVR',
	totalPropertyLoanAmount: 'TOTAL_PROPERTY_LOAN_AMOUNT',
	totalPropertyValue: 'TOTAL_PROPERTY_VALUE',
};

export const appFundingRequiredMergeTag = [
	{
		metaKey: appFundingRequiredMetaKey.propertyPurchaseAddress,
		description: 'Application > Funding Required > Property Purchase > Address',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Auckland, New Zealand',
	},
	{
		metaKey: appFundingRequiredMetaKey.propertyPurchasePrice,
		description: 'Application > Funding Required > Property Purchase > Price',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appFundingRequiredMetaKey.propertyPurchaseLvr,
		description: 'Application > Funding Required > Property Purchase > LVR',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appFundingRequiredMetaKey.propertyPurchaseTotalPrice,
		description: 'Application > Funding Required > Property Purchase > Total',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appFundingRequiredMetaKey.refinancedLoanAmount,
		description: 'Application > Funding Required > Loans to Refinance > Amount',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appFundingRequiredMetaKey.refinancedLoanAddress,
		description:
			'Application > Funding Required > Loans to Refinance > Address',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Auckland, New Zealand',
	},
	{
		metaKey: appFundingRequiredMetaKey.topupRequirementAmount,
		description: 'Application > Funding Required > Top Up Requirement > Amount',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appFundingRequiredMetaKey.topupRequirementAddress,
		description:
			'Application > Funding Required > Top Up Requirement > Address',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Auckland, New Zealand',
	},
	{
		metaKey: appFundingRequiredMetaKey.propertyToBeSoldPrice,
		description: 'Application > Funding Required > Property to be Sold > Price',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appFundingRequiredMetaKey.propertyToBeSoldAddress,
		description:
			'Application > Funding Required > Property to be Sold > Address',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Auckland, New Zealand',
	},
	{
		metaKey: appFundingRequiredMetaKey.loansToBeRepaidAmount,
		description:
			'Application > Funding Required > Loans to be repaid by sale > Amount',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appFundingRequiredMetaKey.loansToBeRepaidSecurity,
		description:
			'Application > Funding Required > Loans to be repaid by sale > Security',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Savings',
	},
	{
		metaKey: appFundingRequiredMetaKey.totalSecurityLvr,
		description: 'Application > Security > Total LVR',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appFundingRequiredMetaKey.totalSecurityScaledLvr,
		description: 'Application > Security > Total Scaled LVR',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
];
