import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { AnnualApiStatFinancialYear } from './aasf.model';
import { AnnualApiStatsFinancialYearStore } from './aasf.store';


@Injectable()
export class AnnualApiStatsFinancialYearService {

  private readonly endpoint = 'widgets?type=AASF';

  constructor(
    private api: ApiService,
    private store: AnnualApiStatsFinancialYearStore
  ) { }

  /**
   * Fetch and store Annual API Stats Financial Year data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetAnnualApiStats(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post3<AnnualApiStatFinancialYear>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.AAStats = {
          Submitted: res.Submitted,
          New: res.New,
          Lost: res.Lost,
          NetNew: res.NetNew,
          APICompletionRate: res.APICompletionRate,
          APPCompletionRate: res.APPCompletionRate
        };
      })),
      err => applyTransaction(() => {
        this.store.setError('Error fetching data!');
        this.store.setLoading(false);
      }),
      () => this.store.setLoading(false)
    );
  }
}
