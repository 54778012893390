import { SimpleDataTableModel } from '@shared/models/_general/simple-data-table.model';

export const displayedColumns = [
	'RequirementName',
	'DueDate',
	'Category',
  'ProviderBusiness',
	'StructuredHours',
  'SecurityGroups',
  'Users',
  'CompletedPercentage',
	'Action',
];

export const tableColumns: SimpleDataTableModel[] = [
	{
		columnId: 'RequirementName',
		columnName: 'Requirement Name',
		headerCellClass: '',
		bodyCellClass: '',
		width: '15%',
		type: 'text',
	},
	{
		columnId: 'DueDate',
		columnName: 'Due Date',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'moment',
	},
	{
		columnId: 'Category',
		columnName: 'Category',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
  {
		columnId: 'ProviderBusiness',
		columnName: 'Provider',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'text',
	},
	{
		columnId: 'StructuredHours',
		columnName: 'Hours',
		headerCellClass: '',
		bodyCellClass: '',
		width: '5%',
		type: 'text',
	},
  {
		columnId: 'SecurityGroups',
		columnName: 'Security Group',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'tooltip',
	},
  {
		columnId: 'Users',
		columnName: 'Users',
		headerCellClass: '',
		bodyCellClass: '',
		width: '10%',
		type: 'popover',
	},
  {
		columnId: 'CompletedPercentage',
		columnName: 'Completed',
		headerCellClass: '',
		bodyCellClass: '',
		width: '5%',
		type: 'text',
	},
	{
		columnId: 'Action',
		columnName: '',
		headerCellClass: '',
		bodyCellClass: '',
		actionCallback: null,
		actionCallbackText: 'Edit',
		actionCallbackTwo: null,
		actionCallbackTextTwo: 'Retract',
		actionCallbackTwoCondition: (param: any) => true,
		width: '10%',
		type: 'action',
	},
];
