import {
	Component,
	OnInit,
	Input,
	ViewChild,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	ElementRef,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, zip, of, Observer } from 'rxjs';
import { strUtil } from '../../../util/util';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { InvestmentMapper } from '../../models/services/investments/investments.mapper';
import { InvestmentState } from '../../models/services/investments/investments.model';
import { ArchiveModalComponent } from '../../modal/archive-modal/archive-modal.component';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import {
	take,
	map,
	withLatestFrom,
	filter,
	tap,
	takeUntil,
} from 'rxjs/operators';
import { patchValue } from '../service-utils/service.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from '../../date-input/date-input.component';
import { UserQuery } from 'src/app/domain/user/user.query';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import * as R from 'ramda';
import {
	Fields,
	getInvalidWarning,
	getRequiredWarning,
} from '../../error-message/error-message';
import { KiwiSaverTypes } from '../../models/services/kiwisaver/kiwisaver.model';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ComponentBase } from '@core/base/component-base';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { ServicesCodes } from '@shared/models/services/services.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-investment-service-form',
	templateUrl: './investment-service-form.component.html',
	styleUrls: ['./investment-service-form.component.scss'],
})
export class InvestmentServiceFormComponent
	extends ComponentBase
	implements OnInit, OnChanges, OnDestroy
{
	public bsModalRef: BsModalRef;

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() archiveEvent = new EventEmitter<any>();
	@Output() deleteNoteEvent = new EventEmitter<any>();
	@Output() cancelAddEvent = new EventEmitter<boolean>();
	@Output() addNoteEvent = new EventEmitter<any>();

	@Input() isLead: boolean;
	@Input() isCompany: boolean;
	@Input() addMode: boolean;

	@Input() investment: InvestmentState;

	@Input() providers: ViewDisplayValue[];
	@Input() statuses: ViewDisplayValue[];
	@Input() types: ViewDisplayValue[];
	@Input() policyOwners: ViewDisplayValue[];
	@Input() investmentTypes: ViewDisplayValue[];
	@Input() pirRates: ViewDisplayValue[];

	@Input() groupName: string;
	@Input() formId: string;
	@Input() isSaving: boolean;

	@Input() clientsInvolved: any[];
	@Input() propertyOwners: ViewDisplayValue[];
	@Input() retentionStatuses: ViewDisplayValue[];

	@ViewChild('submittedDateInput') submittedDateInput: DateInputComponent;
	@ViewChild('startDateInput') startDateInput: DateInputComponent;
	@ViewChild('startTransInput') startTransInput: DateInputComponent;
	@ViewChild('cancellationDateInput') cancellationDateInput: DateInputComponent;

	isEditForm = false;
	notes: any[];
	ksTypes = KiwiSaverTypes;
	investorDropdown: ViewDisplayValue[];

	form: UntypedFormGroup = this.fb.group({
		customerServiceID: '',
		investor: ['', [Validators.required]],
		provider: ['', [Validators.required]],
		status: ['', [Validators.required]],
		investorNumber: ['', [Validators.required, NoWhitespaceValidator]],
		investmentType: '',
		startDate: '',
		submittedDate: '',
		pIRRate: '',
		totalWithdrawals: '',
		product: ['', [Validators.required]],
		iRDNumber: ['', [Validators.required, NoWhitespaceValidator]],
		contribution: ['', [Validators.required, NoWhitespaceValidator]],
		fUM: null,
		originalAdviser: ['', [Validators.required]],
		note: '',

		notes: [],
		color: '',
		isActive: '',
		documentLink: '',
		commission: '',
		startTrans: '',
		cancellationDate:'',
		retentionStatus:'',
	});

	get originalAdviser() {
		return this.form.get('originalAdviser');
	}

	get investor() {
		return this.form?.get('investor');
	}

	get provider() {
		return this.form.get('provider');
	}

	get status() {
		return this.form.get('status');
	}

	get investorNumber() {
		return this.form.get('investorNumber');
	}

	get iRDNumber() {
		return this.form.get('iRDNumber');
	}

	get contribution() {
		return this.form.get('contribution');
	}

	get investmentType() {
		return this.form.get('investmentType');
	}

	get product() {
		return this.form.get('product');
	}

	get customerServiceID() {
		return this.form.get('customerServiceID');
	}
	get cancellationDate() {
		return this.form.get('cancellationDate');
	}

	notesHeader = [
		{ title: 'Created By' },
		{ title: 'Created date & Time' },
		{ title: 'Details' },
		{ title: ' ' },
	];

	@ViewChild('formProp') formProp: ElementRef;

	hasPermission = this.userQuery.hasPermission$;

	advisers$ = this.blStaffsQuery.adviserChoicesOption$;

	originalAdvisers$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.advisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								+x.value ===
									(this.investment ? this.investment.originalAdviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all
		)
	);

	emailClientTemplateFeature$ =
		this.businessConfigQuery.emailClientTemplateFeature$;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private loggerService: LoggerService,
		private userQuery: UserQuery,
		private blStaffsQuery: BLStaffsQuery,
		private clientService: ClientProfileService,
		private businessService: BusinessProfileService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.isSaving && !this.isSaving) {
				this.prepData();
			}

			if (changes.investment) {
				if (
					changes.investment.previousValue?.notes.length ===
					this.investment.notes?.length
				) {
					if (
						changes.investment.previousValue &&
						changes.investment.currentValue &&
						changes.investment.previousValue.notes !==
							changes.investment.currentValue.notes
					) {
						this.prepData();
					} else {
						setTimeout(() => {
							this.prepData();
						}, 700);
					}
				} else {
					this.notes = this.investment?.notes || [];
					// this.form.patchValue({ notes: this.investment.notes });
					this.prepData();
				}

				this.isSaving = false;
			}
		}
		this.setInvestors();
	}

	ngOnInit() {
		this.addMode ? this.form.enable() : this.form.disable();

		if (this.addMode) {
			zip(of(this.providers), of(this.statuses), of(this.retentionStatuses))
				.pipe(
					take(1),
					map((ddListList) => {
						const defaultValueList =
							ddListList
								?.map((ddList) => ddList?.find((dd) => dd.isDefault))
								?.map((def) => def && def.value) || [];
						return defaultValueList as any[];
					})
				)
				.subscribe(this.setDropdownDefaults);
		}

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap(() => this.setInvestors()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	setInvestors() {
		this.investorDropdown = this.getOwnerChoices(this.investor.value);
	}

	getOwnerChoices(owners: (string | number)[]) {
		if (this.isCompany) {
			return this.businessService.getOwnerChoices(
				owners || [],
				this.propertyOwners
			);
		}
		return this.clientService.getOwnerChoices(
			owners || [],
			this.propertyOwners
		);
	}

	compareFn(v1, v2): boolean {
		return +v1 === +v2;
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([p, st, rs]) => {
		patchValue<any>(this.form, {
			provider: p,
			status: st,
			retentionStatus:rs,
		});
	};

	refresh() {
		this.cd.detectChanges();
	}

	prepData() {
		this.notes =
			this.investment &&
			this.investment.notes &&
			this.investment.notes.length > 0
				? this.investment.notes
				: [];
		this.form.reset(InvestmentMapper.mapToView(this.investment));
	}

	formSaving(isSaving: boolean) {
		this.isSaving = isSaving;
		this.refresh();
	}

	save() {
		if (
			!this.form.valid ||
			this.submittedDateInput?.isInvalid() ||
			this.startDateInput?.isInvalid() || 
			this.startTransInput?.isInvalid() ||
			this.cancellationDateInput?.isInvalid()
		) {
			if (
				R.isNil(this.form.value.investor) ||
				R.isEmpty(this.form.value.investor)
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Investor));
				return;
			}
			if (!this.form.value.provider) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Provider));
				return;
			}
			if (!this.form.value.status) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Status));
				return;
			}
			if (
				this.form.value.contribution?.trim() === '' ||
				!this.form.value.contribution
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Contribution));
				return;
			}
			if (
				this.form.value.investorNumber?.trim() === '' ||
				!this.form.value.investorNumber
			) {
				this.loggerService.Warning(
					{},
					getRequiredWarning(Fields.investorNumber)
				);
				return;
			}
			if (this.form.value.product?.trim() === '' || !this.form.value.product) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.product));
				return;
			}
			if (
				this.form.value.iRDNumber?.trim() === '' ||
				!this.form.value.iRDNumber
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.IRDNumber));
				return;
			}
			if (this.submittedDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.SubmittedDate));
				return;
			}
			if (this.startDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.StartDate));
				return;
			}
			if (this.startTransInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.CommissionDate));
				return;
			}
			if (this.cancellationDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.CancellationDate));
				return;
			}
			if (!this.form.value?.originalAdviser) {
				this.loggerService.Warning(
					{},
					getRequiredWarning(Fields.OriginalAdviser)
				);
				return;
			}
		}
		if (isNaN(this.form.value.fUM)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.FUM));
			return;
		}

		if (this.addMode) {
			this.isSaving = true;
		}
		const form = this.form.value;
		form.customerServiceID = this.investment
			? this.investment.customerServiceID
			: '';
		form.isActive = this.addMode ? 1 : this.investment.isActive;
		form.note = form.note;

		const data = InvestmentMapper.mapToUpsert(form);
		this.saveEvent.emit(data);
	}

	archive(isArchive) {
		const msg = this.investment.isActive === 1 ? 'archive' : 'unarchive';
		const initState: any = {
			confirm$: new Observable((obs) => {
				const form = this.form.value;
				form.note = strUtil.safeTrim(form.note);
				const data = InvestmentMapper.mapToUpsert(form);
				this.archiveEvent.emit({
					investment: data,
					type: 'investment',
					isArchive,
				});
				obs.complete();
			}),
			header: 'Archive service',
			message: `Are you sure you want to ${msg} this item?`,
			isArchive: isArchive ? true : false,
		};
		this.modalService.show(ArchiveModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	cancel() {
		if (this.addMode) {
			const confirm = new Observable((obs: Observer<any>) => {
				if (this.addMode) {
					this.cancelAddEvent.emit(false);
				}
				this.isEditForm = false;
				this.prepData();
				this.form.disable();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: 'Discard Confirmation',
				message: `Current information will be discarded?`,
				confirm$: confirm,
				decline$: decline,
			};

			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.isEditForm = false;
			this.prepData();
			this.form.disable();

			const data = InvestmentMapper.mapToView(this.form.value);

			if (!data.submittedDate['_i']) {
				this.submittedDateInput.reset();
			}
			if (!data.startDate['_i']) {
				this.startDateInput.reset();
			}
		}
	}

	editForm(edit: boolean) {
		this.isEditForm = edit;
		edit ? this.form.enable() : this.form.disable();
	}

	deleteConfirm() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const initState = {
			header: 'Delete',
			message: `Are you sure you want to delete ?`,
			delete$: confirm,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	delete() {
		this.isSaving = true;
		const form = this.form.value;
		const data = InvestmentMapper.mapToUpsert(form);

		this.deleteEvent.emit(data);
	}

	deleteNote$ = (data) => {
		return new Observable((obs) => {
			this.isSaving = true;
			const form = this.form.value;
			const mappedForm = InvestmentMapper.mapToUpsert(form);

			this.deleteNoteEvent.emit({ noteId: data.notesID, data: mappedForm });
			obs.next();
			obs.complete();
		});
	};

	openModalAddNote() {
		const saveNote = (notes: string) =>
			new Observable((obs) => {
				const form = InvestmentMapper.mapToView(this.investment);
				form.note = notes;
				this.addNoteEvent.emit(form);
				obs.next();
				obs.complete();
			});
		const initState: any = {
			header: 'Add Note',
			savefn: saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	trackByValue(index, item: ViewDisplayValue) {
		return item.value;
	}

	resetCancellationDate(event){
		if(event !== 'Cancelled' &&  this.cancellationDate.value !==''){
			this.form.patchValue({cancellationDate:''});
		}
	}

	openEmailPopup() {
		if (this.isCompany) {
			this.openEmailBusinessPopup();
		} else {
			this.openEmailClientPopup();
		}
	}

	private openEmailBusinessPopup() {
		this.businessService.openECModalSubject$.next({
			data: this.form.value,
			documentType: ServicesCodes.KiwiSaver,
			type: ServicesCodes.NotesInvestment,
			referenceId: this.customerServiceID.value || 0,
		});
	}

	private openEmailClientPopup(): void {
		this.clientService.openECModalSubject$.next({
			data: this.form.value,
			documentType: ServicesCodes.KiwiSaver,
			type: ServicesCodes.NotesInvestment,
			referenceId: this.customerServiceID.value || 0,
		});
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
