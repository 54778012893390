<div
	class="not-found-wrapper text-center w-100 h-100 d-flex position-absolute flex-row justify-content-center align-items-center"
>
	<div
		class="not-found-box d-flex align-items-center flex-column border tap--border-secondary rounded w-50 p-5"
	>
		<div class="not-found-icon mb-2">
			<i class="material-symbols-outlined tap-text-color-h6 md-24">lock</i>
		</div>
		<div class="not-found-message">
			You do not have access to view this file. Please contact your Support Team
			if you need access or if you require additional information.
		</div>
	</div>
</div>
