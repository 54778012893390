import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { concatMap, delay, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { BusinessConfig } from 'src/app/domain/business-config/business-config.model';
import { ClientProfileQuery } from 'src/app/modules/crm/client-profile/states/client-profile.query';
import { ClientProfileService } from 'src/app/modules/crm/client-profile/states/client-profile.service';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { AuthorityToProceedMapper } from 'src/app/modules/crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.mapper';
import { AuthorityToProceedSettingsService } from 'src/app/modules/crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.service';
import { ServiceAdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { DocumentState } from 'src/app/shared/models/client-review-template/declaration/declaration.model';
import { CrtEmailModel } from 'src/app/shared/models/emails/crt/email.model';
import { Attachment } from 'src/app/shared/models/_general/attachment.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { PrimaryClientState } from '../../models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from '../../models/client-profile/secondary-client/secondary-client.model';
import { MergeTagState } from '../../models/client-review-template/merge-tags/merge-tags.model';

@Component({
	selector: 'app-crm-email-modal',
	templateUrl: './crm-email-modal.component.html',
	styleUrls: ['./crm-email-modal.component.scss'],
})
export class CrmEmailModalComponent implements ConfirmModalModel, OnInit {
	saveEmailFn$: (model: any) => Observable<any>;
	sendEmailFn$: (model: any) => Observable<any>;
	downloadOfferTermsFn$: (model: any) => Observable<any>;
	generateFinalisedEmailTemplateFn$: Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	emailSettings$: Observable<any>;
	emailDocumentTemplateBase64$: Observable<string>;
	emailDocumentTemplate: string;
	bodyContent = '<p></p>';
	shortCodes: object = null;
	peopleDropdown$: Observable<ViewDisplayValue[]>;
	peopleList: ViewDisplayValue[];
	showEmailTemplate: boolean;
	bodyId: number;
	attachments: Attachment[];
	successMessage: string;
	attachFiles = true;
	offerTerms: DocumentState[];
	adviceProcess: ServiceAdviceProcessState;
	businessConfig: BusinessConfig;
	enableDefaultBcc: boolean;

	@Input() mergeTags$: Observable<MergeTagState[]>;

	constructor(
		public bsModalRef: BsModalRef,
		private apService: AuthorityToProceedSettingsService,
		private customerService: CustomerService,
		private clientProfileQuery: ClientProfileQuery,
		private clientProfileService: ClientProfileService,
		private crtDocService: CrtDocumentService,
		private renderer: Renderer2
	) {}

	ngOnInit(): void {
		this.prepData();
		this.peopleDropdown$
			.subscribe((data) => {
				this.peopleList = data;
			});
		// this.saveEmailFn$ = this.saveCrmEmail
	}

	prepData() {
		combineLatest([this.emailSettings$])
			.pipe(
				filter(([data]) => !!data),
				tap(([data]) => (this.bodyId = +data?.body || 0)),
				map(([data]) => AuthorityToProceedMapper.mapEmailSettingsData(data)),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.apService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.apService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	sent() {
		this.bsModalRef.hide();
	}

	decline() {
		if (!!this.decline$) {
			this.decline$.pipe(take(1)).subscribe();
		}
		this.bsModalRef.hide();
	}

	downloadPdfAttachment(attachment: Attachment) {
		if (attachment.generateContentCallback$) {
			attachment.generateContentCallback$
				.pipe(
					delay(100),
					mergeMap((content) => this.renderPdfDownload(content, attachment.newPdfOptions)),
					take(1)
				)
				.subscribe();
		}

		if (attachment.fileUrl) {
			this.downloadFile(attachment.fileUrl, attachment.fileName);
		}
	}

	downloadFile(fileUrl: any, fileName: string) {
		this.crtDocService
			.getDocumentLink(fileUrl, {
				responseType: 'blob',
			})
			.pipe(
				tap((file) => {
					const newFile = new File([file], fileName);
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(newFile);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', fileName);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			)
			.subscribe();
	}

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, pdfOptions?.FileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	saveCrmEmail(data) {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap(() => {
				const isPrimary = this.clientProfileQuery.getValue().primaryClient.customerID === data.customerID;
				if(isPrimary) {
					return this.customerService.SavePrimaryClient(data as PrimaryClientState).pipe(
						concatMap(() => this.clientProfileService.getPrimaryClient(this.clientProfileQuery.getValue().primaryClient.customerID))
					);
				}
				else {
					return this.customerService?.UpdateSecondaryClient(data as SecondaryClientState).pipe(
						concatMap(() => this.clientProfileService.getSecondaryClients(this.clientProfileQuery.getValue().primaryClient.customerID))
					);
				}
			})
		);
	}
}

export interface ConfirmModalModel {
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
