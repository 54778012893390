import { UserService } from './../user/user.service';
import { UserQuery } from './../user/user.query';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, timer } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { LocalService } from 'src/app/core/services/local.service';
import { ApiService, JsonResultStatus } from '../../core/base/api.service';
import { BLStaff, StaffSettingTypes } from './bl-staff.model';
import { BLStaffsQuery } from './bl-staffs.query';
import { BLStaffsStore } from './bl-staffs.store';
import {
	AddUserPhotoRequest,
	BlStaffModel,
	DefaultStaffStats,
} from '@shared/models/_general/bl-staff.model';
import { defaultConversions } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { BlDocumentModel } from '@shared/models/documents/document.model';

@Injectable()
export class BLStaffsService {
	private UpdatedStaffListSubj = new Subject<void>();
	public UpdatedStaffList$ = this.UpdatedStaffListSubj.asObservable();

	constructor(
		private api: ApiService,
		private blStaffsStore: BLStaffsStore,
		private blStaffsQuery: BLStaffsQuery,
		private localService: LocalService,
		private userQuery: UserQuery,
		private userService: UserService
	) {}

	getBLStaff() {
		const endpoint = 'staff/bl';
		return this.api.get<BLStaff[]>(endpoint).pipe(
			tap((x) => {
				// const code =
				// 	!!getCompanyCode() && getCompanyCode() !== 'login'
				// 		? getCompanyCode()
				// 		: this.localService.getValue('code') ?? '';

				// this.localService.setValue(code.concat('_blStaffs'), x);

				// this.localService.setValue(code.concat('_blStaffs_exp'), Date.now());

				this.blStaffsStore.add(x);
			}),
			catchError((err) => of(err))
		);
	}

	getBlStaffById(id: number | string) {
		const endpoint = `staff/${id}/bl`;
		const body = {
			staff_level: 'bl',
			id,
		};
		return this.api.get<BlStaffModel>(endpoint, body).pipe(
			map((x) => {
				const parse2: any = x;
				parse2.Services =
					parse2 && parse2.Services
						? JSON.parse(parse2.Services)
						: parse2.Services;
				parse2.Stats =
					parse2 && parse2.Stats ? JSON.parse(parse2.Stats) : parse2.Stats;
				return parse2;
			})
		);
	}

	getBLStaffShorten() {
		const endpoint = 'staff/shorten/bl';
		return this.api.get<BLStaff[]>(endpoint).pipe(
			tap((x) => {
				// const code =
				// 	!!getCompanyCode() && getCompanyCode() !== 'login'
				// 		? getCompanyCode()
				// 		: this.localService.getValue('code') ?? '';

				// this.localService.setValue(code.concat('_blStaffs'), x);

				// this.localService.setValue(code.concat('_blStaffs_exp'), Date.now());

				this.blStaffsStore.add(x);
			}),
			catchError((err) => of(err))
		);
	}

	addList(): Observable<BLStaff[]> | Promise<BLStaff[]> {
		const staffs = this.blStaffsQuery.getAll();
		// const code =
		// 	!!getCompanyCode() && getCompanyCode() !== 'login'
		// 		? getCompanyCode()
		// 		: this.localService.getValue('code') ?? '';
		// const localStaffs =
		// 	this.localService.getValue(code.concat('_blStaffs')) || [];
		// const exp = this.localService.getValue(
		// 	getCompanyCode().concat('_blStaffs_exp')
		// );
		// const dateNow = Date.now();
		// if (
		// 	!!localStaffs &&
		// 	localStaffs?.length > 0 &&
		// 	exp &&
		// 	dateNow < 7200 * 1000 + exp
		// ) {
		// return of(localStaffs).pipe(
		if (staffs?.length > 0) {
			return of(staffs).pipe(
				tap((blStaffs) => this.blStaffsStore.add(blStaffs))
				// finalize(() => this.getBLStaff().pipe(take(1)).subscribe())
			);
		} else {
			return this.getBLStaffShorten();
		}
	}

	add(blStaff: any) {
		const endpoint = 'staff/bl';
		const body = blStaff;
		let newId = null;
		return this.api.post3<JsonResultStatus>(endpoint, body).pipe(
			tap((id) => {
				if (id) {
					blStaff.StaffID = Number(id);
					this.blStaffsStore.add(blStaff);
				}
				newId = id;

				// const code =
				// 	!!getCompanyCode() && getCompanyCode() !== 'login'
				// 		? getCompanyCode()
				// 		: this.localService.getValue('code') ?? '';

				// this.localService.setValue(
				// 	code.concat('_blStaffs'),
				// 	this.blStaffsQuery.getAll()
				// );

				// Update list on crm
				this.UpdatedStaffListSubj.next();
			}),
			mergeMap((id) =>
				this.createStaffSettings(
					+id,
					{
						AdviserIdsAssigned: [],
					},
					StaffSettingTypes.UserTrackerAccessibility
				)
			),
			mergeMap(() => this.createStaffGoalSettings(newId, blStaff)),
			map(() => newId)
		);
	}

	update(blStaff: any, noLoading?: boolean) {
		const endpoint = `staff/${blStaff.StaffID}/bl`;
		const body = blStaff;
		return this.api.put(endpoint, body).pipe(
			tap((res) => {
				if (res) {
					this.blStaffsStore.update(blStaff.StaffID, blStaff);

					if (+blStaff.IsActive === 0) {
						this.blStaffsStore.update(blStaff.StaffID, {
							IsActive: 0,
						});
					}
				}

				// const code =
				// 	!!getCompanyCode() && getCompanyCode() !== 'login'
				// 		? getCompanyCode()
				// 		: this.localService.getValue('code') ?? '';

				// this.localService.setValue(
				// 	code.concat('_blStaffs'),
				// 	this.blStaffsQuery.getAll()
				// );

				this.UpdatedStaffListSubj.next();
			}),
			map(() => blStaff),
			finalize(() => {
				// Logged in User / Current user
				const user = this.userQuery.getValue().StaffID;
				if (!!user && !!blStaff && +user === +blStaff?.StaffID) {
					// Get the latest update
					if (noLoading) {
						this.userService.getStaffNoLoading().pipe(take(1)).subscribe();
					} else {
						this.userService.get().pipe(take(1)).subscribe();
					}
				}
			})
		);
	}

	delete(StaffID: any) {
		const endpoint = `staff/${StaffID?.toString()}?type=bl`;
		return this.api.delete(endpoint).pipe(
			tap((id) => {
				if (id) {
					this.blStaffsStore.update(StaffID, {
						IsActive: 0,
					});

					// const code =
					// 	!!getCompanyCode() && getCompanyCode() !== 'login'
					// 		? getCompanyCode()
					// 		: this.localService.getValue('code') ?? '';

					// this.localService.setValue(
					// 	code.concat('_blStaffs'),
					// 	this.blStaffsQuery.getAll()
					// );
				}
			})
		);
	}

	TransferActivity = (request: TransferActivityModel) =>
		this.api.putText('activities/transfer', request).pipe(
			catchError(() =>
				timer(30000).pipe(mergeMap(() => this.api.post4('export/auditlogs/TA')))
			),
			map((x) => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			})
		);

	TransferClient = (request: TransferActivityModel) =>
		this.api.putText('contacts/transfer', request).pipe(
			map((x) => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			catchError((e) => {
				timer(30000).pipe(mergeMap(() => this.api.post4('export/auditlogs/TA'))).subscribe();
				throw e;
			}),
		);

	tryParseJSON(jsonString: any): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}

	addPhoto(req: AddUserPhotoRequest) {
		const body = {
			FileName: req.FileName,
			Photo: !!req.Photo
				? req.Photo.replace(/^data:image.+;base64,/, '')
				: null,
		};
		const endpoint = `staff/${req.StaffID}/upload-photo`;
		return this.api.post<AddUserPhotoRequest>(endpoint, body).pipe(
			concatMap(() => this.getBlStaffById(+req.StaffID)),
			catchError(() => of(null))
		);
	}

	UploadDocument(req) {
		return this.api.post('documents', req);
	}
	updateProvideAccess(blStaff: any) {
		const endpoint = `staff/${blStaff.StaffID}?type=bl&provideAccess=true`;
		const body = blStaff;
		return this.api.put<JsonResultStatus>(endpoint, body);
	}

	createStaffSettings(staffID: number, payload: any, type: string) {
		const endpoint = `staff/${staffID}/staff-settings/${type}`;

		return this.api.post(endpoint, payload);
	}

	updateStaffSettings(staffID: number, payload: any, type: string) {
		const endpoint = `staff/${staffID}/staff-settings/0/${type}`;

		return this.api.put(endpoint, payload);
	}

	createStaffGoalSettings(staffID: number, data: any) {
		const code = this.localService.getValue('code')?.toLowerCase() ?? '';
		const isGetDefaultValue =
			['AM', 'A', 'BO']?.includes(data?.SecurityGroup) && code === 'haven';

		return of(staffID).pipe(
			mergeMap((id) => {
				const statsValue = {
					...DefaultStaffStats,

					lr_conversion: isGetDefaultValue
						? defaultConversions.lr_conversion
						: '',
					kiwisaver_conversion: isGetDefaultValue
						? defaultConversions.kiwisaver_conversion
						: '',
					blanket_conversion: isGetDefaultValue
						? defaultConversions.blanket_conversion
						: '',

					mortgage_conversion: isGetDefaultValue
						? defaultConversions.mortgage_conversion
						: '',
				};

				return this.createStaffSettings(
					id,
					{
						Stats: JSON.stringify(
							Object.entries(statsValue).map(([key, value]) => ({
								[key]: value,
							})) || []
						),
					},
					StaffSettingTypes.UserTrackerGoals
				);
			}),
			take(1)
		);
	}

	getStaffDocuments(id: number): Observable<BlDocumentModel[]> {
		return this.api.get<any>(`staff/documents/${id}`);
	}

	deleteStaffDocuments(
		documentId: number,
		staffId: number
	): Observable<BlDocumentModel[]> {
		const endpoint = `staff/${staffId}/documents/${documentId}`;
		return this.api.delete<any>(endpoint);
	}
}

export interface TransferActivityModel {
	CurrentAdviser: number;
	NewAdviser: number;
}
