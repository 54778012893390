import { Injectable } from '@angular/core';
import { EMPTY, iif, of } from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	map,
	mergeMap,
	take,
} from 'rxjs/operators';
import { removeMtWrappers } from 'src/app/shared/converter/content-merge-tags';
import { convertUtil } from 'src/app/util/util';
import { ApiService } from 'src/app/core/base/api.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { HtmlPdfConfigState, htmlPdfConfig } from './defaults-config';
import { fontFaceOpenSans } from './fonts';
import * as R from 'ramda';

@Injectable({
	providedIn: 'root',
})
export class HtmlToPdfService {
	/*-------------------------------------------------------------*\
  		This is for the NEW HTML-to-PDF integration from Endpoint
	\*-------------------------------------------------------------*/

	endpoint = `documents/render/html-pdf`;
	defaultOptions = htmlPdfConfig;

	constructor(
		private api: ApiService,
		protected customerService: CustomerService
	) {}

	/**
	 * Parse HTML String for PDF
	 * @param template HTML content as string
	 * @param orientation document orientation (default: portrait)
	 * @returns string
	 */
	contentForPdf = (template: string, orientation: string = 'portrait') => {
		let result = '';
		const content = removeMtWrappers(template);
		const newHtml = document
			.createRange()
			.createContextualFragment(
				`<div id="templateFile"><div class="froala-template-file pdf-${orientation}">${content}</div></div>`
			);

		result = newHtml
			.querySelector('#templateFile')
			.innerHTML?.replace(/‐/g, '-');

		return result;
	};

	/**
	 * Convert HTML String content to the new downloadable PDF
	 * @param content HTML content as string
	 * @param documentName Document name on PDF
	 * @param options Optional wkhtmltopdf config options
	 * @returns Observable<string> of new html
	 */
	downloadDocumentPDF(content: string, documentName: string = 'Document', options?) {
		return of(content || '').pipe(
			delay(300),
			mergeMap((x) => this.createHtmlForPdf(x, documentName)),
			concatMap((x) => this.getDocumentHtmlToPdf(x, options)),
			take(1)
		);
	}

	/**
	 * Generate Base64 PDF
	 * @param content HTML body content as string
	 * @param pdfOptions Optional wkhtmltopdf config options
	 * @returns Observable<string> base64 file
	 */
	generatePDFbase64(content, pdfOptions?: HtmlPdfConfigState) {
		return of(content).pipe(
			mergeMap((x) => this.createHtmlForPdf(x, pdfOptions?.FileName)),
			concatMap((x) => this.getDocumentHtmlToPdf(x, pdfOptions)),
			mergeMap((x) => convertUtil.convertToBase64(x)),
			take(1)
		);
	}

	/**
	 * Create a whole html with css styles containing the content provided as body
	 * @param bodyContent HTML body content as string
	 * @param documentName Document name on PDF
	 * @returns Observable<string> of new html
	 */
	createHtmlForPdf(bodyContent: string, documentName: string) {
		return this.getStyleSheets().pipe(
			map((cssStyles) => {
				const template = this.contentForPdf(bodyContent || '');
				return `
					<!DOCTYPE html>
					<html lang="en">
						<head>
							<meta http-equiv="X-UA-Compatible" content="IE=edge" />
							<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
							<meta name="viewport" content="width=device-width, initial-scale=1.0" />
							<title>${documentName}</title>
							<style type="text/css">
							${fontFaceOpenSans}
							${cssStyles}
							</style>
						</head>
						<body>
							${template}
						</body>
					</html>`;
			}),
			map((html: any) =>
				html?.replaceAll('&quot;Open Sans&quot;', `'Open Sans'`)
			),
			map((html) => {
				const newHtml = html?.replaceAll(
					'var(--tap-font, "Open Sans", sans-serif)',
					`'Open Sans', sans-serif`
				);
				const cssVariables = this.getAllCSSVariables() || [];

				const result = cssVariables?.reduce((a, c) => {
					return a?.replaceAll(`var(${c?.className})`, c?.value);
				}, newHtml);
				return result;
			}),
			take(1)
		);
	}

	/**
	 * Get stylesheets from application head
	 * @returns Observable<string> of cleaned CSS stylesheet
	 */
	getStyleSheets() {
		const cssLink =
			document.head
				?.querySelector('link[rel="stylesheet"]')
				?.getAttribute('href') ?? null;

		const getHeaderCss = () => {
			return (
				Array.from(document.head.getElementsByTagName('style'))
					?.filter((style) => style.innerHTML.includes('froala-template-file'))
					?.map((style) => style.innerHTML)
					?.join('')
					?.replace(/\n|\t/g, '') || ''
			);
		};

		return of(cssLink).pipe(
			concatMap((link) =>
				iif(
					() => !!link,
					this.getFileFromURL(`${window.location.origin}/${cssLink}`).pipe(
						map((x) => x?.replace(/\n|\t/g, '') || '')
					),
					of(getHeaderCss())
				)
			),
			map((css) => {
				// Remove all styles before Bootstrap imported on styles.css
				let cssStyles = css
					?.split('/*! * Bootstrap')
					?.filter((x, i) => i !== 0)
					?.map((x) => `/*! * Bootstrap${x}`)
					?.join(' ');

				cssStyles = cssStyles.replace(/--tap-font/g, '');

				// Remove existing font-face from css
				const regExFontface = new RegExp(
					`(?:@font-face[^}]*)(.*?)(?:})`,
					'gms'
				);
				const getMatches = cssStyles.match(regExFontface);
				const newStyle = getMatches?.reduce(
					(a, c) => a?.replace(c, ''),
					cssStyles
				);
				return newStyle || '';
			}),
			take(1)
		);
	}

	getThemeConfigCssVariables() {
		const styleSheets = document.documentElement.style;
		const notEmpty = R.compose(R.not, R.isEmpty);
		const cssVars = styleSheets
			? R.filter(notEmpty, R.values(styleSheets))
			: [];

		return cssVars?.reduce((a, c: any) => {
			const value = document.documentElement.style.getPropertyValue(c);
			return value ? [...a, { className: c, value }] : a;
		}, []);
	}

	getAllCSSVariables() {
		const themeConfig = this.getThemeConfigCssVariables();
		const styleSheets: any = document.styleSheets;
		const otherCssVars = [];
		// loop each stylesheet
		for (let i = 0; i < styleSheets.length; i++) {
			// loop stylesheet's cssRules
			try {
				for (let j = 0; j < styleSheets[i].cssRules.length; j++) {
					const rules: any = styleSheets[i].cssRules[j];
					try {
						// loop stylesheet's cssRules' style (property names)
						for (let k = 0; k < rules.style.length; k++) {
							const name = rules.style[k];
							// check if name is variable
							if (
								name?.startsWith('--') &&
								!otherCssVars?.find((x) => x?.className === name)
							) {
								const value = rules.style.getPropertyValue(name)?.trim() || '';
								if (R.contains('var(', value)) {
									// replace all css vars with actual values from theme config
									// skip the --tap-dark* css
									if (!R.contains('--tap-dark', value)) {
										// get css value of the variable
										const varName = value?.match(/\((.*)\)/)?.pop();
										const themeValue =
											[...themeConfig, ...otherCssVars]?.find(
												(x) => x?.className === varName
											)?.value || '';

										otherCssVars.push({
											className: name,
											value: themeValue?.trim(),
										});
									}
								} else {
									otherCssVars.push({ className: name, value });
								}
							}
						}
					} catch (error) {}
				}
			} catch (error) {}
		}
		return [...themeConfig, ...otherCssVars];
	}

	/*-----------------------------*\
          	  REQUESTS
	\*-----------------------------*/

	getPdfByDocumentId(documentId: number) {
		return this.api
			.get(
				`${this.endpoint}/${documentId}`,
				null,
				{
					'Content-type': 'application/pdf',
				},
				{ responseType: 'arraybuffer' }
			)
			.pipe(map((x) => new Blob([x as BlobPart], { type: 'application/pdf' })));
	}

	convertHtmlToPdf(document: string, extendedOptions?) {
		const options = {
			...this.defaultOptions,
			...extendedOptions,
		};
		return this.api
			.post(
				this.endpoint,
				{ Document: document, ...options },
				{
					responseType: 'arraybuffer',
				}
			)
			.pipe(map((x) => new Blob([x as BlobPart], { type: 'application/pdf' })));
	}

	getDocumentHtmlToPdf(document: string, options?) {
		return convertUtil
			.convertToBase64(document)
			.pipe(concatMap((x) => this.convertHtmlToPdf(x, options)));
	}

	getFileFromURL(url: string) {
		return this.api
			.getExternalResource(url, { responseType: 'text' })
			.pipe(catchError(() => EMPTY));
	}
}
