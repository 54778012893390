import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { util } from '../util/util.service';
import { map, retry } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
import { environment as env } from '@environment';
import { LocalService } from '../services/local.service';
import { UserLoggedIn } from '../authentication/authentication.service';

export const apiUrl = `${env.apiUrl}/api/v${env.apiVersion}`;

const CheckForError = (response: any) => {
  const parsedResponse = util.fromJson(response);
  if (isJsonResultStatus(parsedResponse)) {
    // if (parsedResponse.status_code === 401) {
    //   const unauthorizedLink = 'http://' + env.apiUrl + '/login/Unauthorized';
    //   window.location.replace(unauthorizedLink);
    // }
    if (parsedResponse.status_code >= 300 || parsedResponse.status_code < 200) {
      throw parsedResponse.status_description ? parsedResponse.status_description : parsedResponse;
    }
  }
};

@Injectable({ providedIn: 'root' })
export class ApiService {
  /**
   *
   */
  constructor(private http: HttpClient, private loggerService: LoggerService, private localService: LocalService) { }

  get user(): UserLoggedIn {
    return this.localService.getValue('token');
  }

  get isAuthorized(): boolean {
    const expiresIn = this.localService.getValue('expires_in');
    return !!this.user && expiresIn && Date.now() < expiresIn;
  }

  /**
   * Sends a post request. Not the method to call normally as this is for some special old endpoints which needs special json parsing
   *
   * @param endpoint endpoint to call
   * @param body body to send
   *
   */
  post2<T>(endpoint: string, body: object = {}): Observable<T & JsonResultStatus> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http.post<T & JsonResultStatus>(url, body).pipe(
      map(x => {
        CheckForError(x);
        return x;
      })
    );
  }
  /**
   * Sends a post request.
   * @param endpoint endpoint to call
   * @param body body to send
   */
  post<T>(endpoint: string, body: object = {}, opt = {}): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http.post<any>(url, body, opt).pipe(
      map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
      map<T | JsonResultStatus, T>(x => {
        CheckForError(x); // tslint:disable-line: no-use-before-declare
        return x as T;
      })
    );
  }

  /**
   * Sends post request but does not retry on failure
   * @param endpoint endpoint to call
   * @param body body to send
   */
  postNoRetry<T>(endpoint: string, body?: any, opt?: any): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);

    // const optionalCompanyCode =
    //   opt && opt.headers && opt.headers.CompanyCode
    //     ? opt.headers.CompanyCode
    //     : '';

    return this.http.post<any>(url, body, {}).pipe(
      map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
      map<T | JsonResultStatus, T>(x => {
        CheckForError(x); // tslint:disable-line: no-use-before-declare
        return x as T;
      })
    );
  }

  /**
   * Sends a post request with better types. Retries 1 time on failure.
   * @param endpoint endpoint to call
   * @param body body to send
   */
  post3<T>(endpoint: string, body?: any, opt: any = {}): Observable<T> {
    return this.postNoRetry<T>(endpoint, body, opt).pipe(retry(1));
  }

  postFileDownload: (endpoint: string, body?: any) => Observable<Blob> = (endpoint, body) =>
    this.http.post(util.toUrl(apiUrl, endpoint), body, {
      responseType: 'blob',
    });

  post4: (endpoint: string, body?: any) => Observable<any> = (endpoint, body) =>
    this.http.post(util.toUrl(apiUrl, endpoint), body, {
      responseType: 'text',
    } as any);

  postFileArrayBuffer: (endpoint: string, body?: any) => Observable<any> = (endpoint, body) =>
    this.http.post(util.toUrl(apiUrl, endpoint), body, {
      responseType: 'arraybuffer',
    } as any);

  get<T>(endpoint: string, params?: any, headers: any = {}, otherOptions = {}): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http
      .get<T>(url, { params: { ...params }, headers: { ...headers }, ...otherOptions })
      .pipe(
        map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
        map<T | JsonResultStatus, T>(x => {
          CheckForError(x); // tslint:disable-line: no-use-before-declare
          return x as T;
        })
      );
  }    

  // Get method without error checking
  get2<T>(endpoint: string, params?: any, headers: any = {}, otherOptions = {}): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http
      .get<T>(url, { params: { ...params }, headers: { ...headers }, ...otherOptions })
      .pipe(
        map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
        map<T | JsonResultStatus, T>(x => {
          return x as T;
        })
      );
  }    

	getResponseBlob(endpoint: string, params?: any): Observable<any> {
    return this.http.get(util.toUrl(apiUrl, endpoint), { ...params, responseType: 'blob' }) ;
  }

  put<T>(endpoint: string, body: any = {}, headers: any = {}): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http
      .put<T>(url, body, { headers: { ...headers } })
      .pipe(
        map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
        map<T | JsonResultStatus, T>(x => {
          CheckForError(x); // tslint:disable-line: no-use-before-declare
          return x as T;
        })
      );
  }

  put2<T>(endpoint: string, body: any = {}, headers = {}): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http
      .put<T>(url, body, {
        headers: {
          ...headers,
        },
      })
      .pipe(
        map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
        map<T | JsonResultStatus, T>(x => {
          CheckForError(x); // tslint:disable-line: no-use-before-declare
          return x as T;
        })
      );
  }

	patch<T>(endpoint: string, body: any = {}, headers: any = {}): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http
      .patch<T>(url, body, { headers: { ...headers } })
      .pipe(
        map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
        map<T | JsonResultStatus, T>(x => {
          CheckForError(x); // tslint:disable-line: no-use-before-declare
          return x as T;
        })
      );
  }

  /** Returns text */
  putText: (endpoint: string, body?: any) => Observable<any> = (endpoint, body) =>
    this.http.put(util.toUrl(apiUrl, endpoint), body, {
      responseType: 'text',
    } as any);

  delete<T>(endpoint: string): Observable<T> {
    const url = util.toUrl(apiUrl, endpoint);
    return this.http.delete<T>(url).pipe(
      map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
      map<T | JsonResultStatus, T>(x => {
        CheckForError(x); // tslint:disable-line: no-use-before-declare
        return x as T;
      })
    );
  }

  request<T>(method: string, endpoint: string, body: any = {}): Observable<T> {
    return this.http
      .request(method, endpoint, {
        body: { ...body },
      })
      .pipe(
        map<string | T | JsonResultStatus, T | JsonResultStatus>(x => util.fromJson(x)),
        map<T | JsonResultStatus, T>(x => {
          CheckForError(x); // tslint:disable-line: no-use-before-declare
          return x as T;
        })
      );
  }

	/** For External APIs - Outside CRM */
	getExternalResource(url: string, params?: any): Observable<any> {
    return this.http.get(url, { ...params }) ;
  }

	getExternalResourceAsBlob(url: string, params?: any): Observable<any> {
    return this.http.get(url, { ...params, responseType: 'blob' }) ;
  }

	/**
   * Sends post request but does not retry on failure
   * @param endpoint endpoint to call
   * @param body body to send
   */
  externalPost<T>(url: string, body?: any, options?: any): Observable<T> {
    return this.http.post<any>(url, body, options).pipe(
      map(x => util.fromJson(x)),
      map<T | JsonResultStatus, T>(x => {
        CheckForError(x); // tslint:disable-line: no-use-before-declare
        return x as T;
      })
    );
  }
}

export interface JsonResultStatus {
  status_code?: number;
  status_description?: string;
  id?: string;
}

export function isJsonResultStatus(o: any): o is JsonResultStatus {
  return o && (o.status_code || o.status_description);
}
