import { DOCUMENT } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit,
	Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { SecurityGroup } from '@domain/business-config/business-config.model';
import { UserQuery } from '@domain/user/user.query';
import {
	CARSectionCodes,
	ClientAlterationRequestRouteName,
} from '@modules/crm/client-alteration-request/states/client-alteration-request.model';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ComfirmationCallDocumentStatus } from '@modules/crm/loat/util/loat-enums';
import { ConfirmationCallCompleteModalService } from '@shared/confirmation-call-complete-modal/confirmation-call-complete-modal.service';
import { ConfirmationCallSendIssueModalService } from '@shared/confirmation-call-send-issue-modal/confirmation-call-send-issue-modal.service';
import { finalize, map, take } from 'rxjs/operators';
import {
	AdviceProcessDocumentField,
	AdviceProcessDocumentState,
	AdviceProcessPageCodes,
	AdviceProcessStatusState,
	FactFindCodes,
	GetPageCodeForDocumentField,
	RiskAnalysisCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { AdviceProcessDocumentBaseComponent } from '../advice-process-document-base/advice-process-document-base.component';

@Component({
	selector: 'app-advice-process-loat-documents',
	templateUrl: './advice-process-loat-documents.component.html',
})
export class AdviceProcessLoatDocumentsComponent
	extends AdviceProcessDocumentBaseComponent
	implements OnInit
{
	comfirmationCallDocumentStatus = ComfirmationCallDocumentStatus;

	documentFields = AdviceProcessDocumentField;

	completeModalIsLoading = false;

	hasFinalStructure = false;

	showConfirmationCallButtons$ = this.userQuery.userSecurityGroup$.pipe(
		map((securityGroup) => {
			return [
				SecurityGroup.TAPUserManager,
				SecurityGroup.BusinessOwner,
				SecurityGroup.BusinessAdmin,
				SecurityGroup.AdviserManager,
				SecurityGroup.Adviser,
				SecurityGroup.AdviserManager,
				SecurityGroup.TAPSuperOwner,
			].includes(securityGroup as SecurityGroup);
		})
	);

	constructor(
		private confirmationCallSendIssueModalService: ConfirmationCallSendIssueModalService,
		private confirmationCallCompleteModalService: ConfirmationCallCompleteModalService,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private userQuery: UserQuery,
		private clientProfileService: ClientProfileService,
		@Inject(DOCUMENT) private doc: HTMLDocument,
		private renderer: Renderer2,
		private routeService: RouteService,
		private router: Router
	) {
		super();
	}

	ngOnInit(): void {}

	prepDocuments() {
		const docs = this.adviceProcess.documents?.filter((x) => {
			if (x.field === AdviceProcessDocumentField.ConfirmationCall) {
				const finalStructure = this.adviceProcess.documents.find(
					(doc) => doc.field === AdviceProcessDocumentField.FinalStructure
				);
				return Boolean(finalStructure?.value?.documentID);
			}
			return true;
		});
		this.documents = docs?.map((x: AdviceProcessDocumentState) => {
			if (x.field === AdviceProcessDocumentField.FactFind) {
				return {
					...x,
					show:
						!!this.adviceProcess.pageStarted?.some((page) =>
							FactFindCodes.includes(page)
						) ||
						!!this.adviceProcess.pageStarted?.some((page) =>
							RiskAnalysisCodes.includes(page)
						),
					isUploading: false,
					isDeleting: false,
					isDownloading: false,
				};
			} else if (x.field === AdviceProcessDocumentField.FinalStructure) {
				this.hasFinalStructure = Boolean(x?.value?.documentID);
				return {
					...x,
					show:
						!!this.adviceProcess.documents?.find(
							(doc) =>
								doc.field === AdviceProcessDocumentField.AuthorityToProceed &&
								!!doc.value?.documentID
						) &&
						!!this.adviceProcess.pageCompleted?.some(
							(page) => page === AdviceProcessPageCodes.AuthorityToProceed
						),
					isUploading: false,
					isDeleting: false,
					isDownloading: false,
				};
			} else if (x.field === AdviceProcessDocumentField.CARFinalisedStructure) {
				const isNotEnded =
					this.adviceProcess?.status !== AdviceProcessStatusState.incomplete &&
					this.adviceProcess?.status !== AdviceProcessStatusState.complete;
				return {
					...x,
					showCreateButton:
						this.adviceProcess?.pageCompleted?.includes(
							CARSectionCodes.ChangeRequest
						) && isNotEnded,
				};
			} else {
				const field = GetPageCodeForDocumentField(
					x.field,
					this.adviceProcess.processCode
				);
				return this.getDocumentState(field, x);
			}
		});
	}

	private reloadAdviceProcesses(): void {
		const primaryClientId = this.activatedRoute.snapshot.params.customerId;
		this.clientProfileService
			.getAdviceProcessesByPrimaryId(primaryClientId)
			.pipe(take(1))
			.subscribe();
		this.reloadHistoriesAndActivities();
	}

	private reloadHistoriesAndActivities(): void {
		this.clientProfileService
			.getClientHistories(+this.adviceProcess.customerID)
			.pipe(take(1))
			.subscribe();
		this.clientProfileService
			.getActivityTimeline(+this.adviceProcess.customerID)
			.pipe(take(1))
			.subscribe();
		this.clientProfileService
			.getCriterias(+this.adviceProcess.customerID)
			.pipe(take(1))
			.subscribe();
	}

	openConfirmationCallModal(): void {
		this.confirmationCallSendIssueModalService
			.show(this.adviceProcess)
			.subscribe((successRequest) => {
				if (successRequest) {
					this.reloadAdviceProcesses();
				}
			});
	}

	openCompletionCallModal(conCall: any): void {
		this.completeModalIsLoading = true;
		this.confirmationCallCompleteModalService
			.show(this.adviceProcess, conCall.callId)
			.pipe(
				take(1),
				finalize(() => {
					this.completeModalIsLoading = false;
					this.cdr.detectChanges();
					this.renderer.removeClass(this.doc.body, 'modal-open');
				})
			)
			.subscribe((successRequest) => {
				if (successRequest) {
					this.reloadAdviceProcesses();
				}
			});
	}

	openCallAgainModal(conCall: any): void {
		this.completeModalIsLoading = true;
		this.confirmationCallCompleteModalService
			.show(this.adviceProcess, conCall.callId, true)
			.pipe(
				take(1),
				finalize(() => {
					this.completeModalIsLoading = false;
					this.cdr.detectChanges();
					this.renderer.removeClass(this.doc.body, 'modal-open');
				})
			)
			.subscribe((successRequest) => {
				if (successRequest) {
					this.reloadAdviceProcesses();
				}
			});
	}

	openCallViewModal(conCall: any): void {
		this.completeModalIsLoading = true;
		this.confirmationCallCompleteModalService
			.show(this.adviceProcess, conCall.callId, false,true)
			.pipe(
				take(1),
				finalize(() => {
					this.completeModalIsLoading = false;
					this.cdr.detectChanges();
					this.renderer.removeClass(this.doc.body, 'modal-open');
				})
			)
			.subscribe();
	}

	navigateToCreateUpdatedStructure(): void {
		const carPage = this.routeService.clientAlterationRequest(
			this.adviceProcess.customerID,
			this.adviceProcess.adviceProcessID
		);
		carPage.push(ClientAlterationRequestRouteName.UpdatedStructure);
		this.router.navigate(carPage, {
			queryParams: {
				// this will tell car resolver to create Updated Structure data
				populate: 'true',
			},
		});
	}
}
