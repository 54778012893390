import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PurposeState, PurposeStore } from './purpose.store'

@Injectable()
export class PurposeQuery extends QueryEntity<PurposeState> {
  purpose$ = this.selectAll();

  constructor(protected store: PurposeStore) {
    super(store);
  }
}
