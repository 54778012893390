import { Component, OnInit } from '@angular/core';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { BusinessEmailSettingsService } from './business-email-settings.service';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-business-email-settings',
	templateUrl: './business-email-settings.component.html'
})
export class BusinessEmailSettingsComponent implements OnInit {
	companyCode = this.route.snapshot.paramMap.get('companyCode');
	businessConfig = null;

	constructor(
		private businessEmailSettingsService: BusinessEmailSettingsService,
		private businessConfigQuery: BusinessConfigQuery,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.businessConfigQuery.businessConfig$.subscribe(y => (this.businessConfig = y));
	}

	removeFileFn$ = ({ file, type }) => {
		return this.businessConfigQuery.businessConfig$.pipe(
			mergeMap((config) => {
				const body = {
					...config,
					BusinessCode: this.companyCode,
					BusinessFooterLogo:
						type === 'FooterLogo' && !file.FileName ? null : config.BusinessFooterLogo,
					BusinessHeaderLogo:
						type === 'HeaderLogo' && !file.FileName ? null : config.BusinessHeaderLogo,
					DisclosureDocument:
						type === 'Disclosure' && !file.FileName ? null : config.DisclosureDocument,
					DeclarationDocument:
						type === 'Declaration' && !file.FileName ? null : config.DeclarationDocument,
					ScopeOfService:
						type === 'Scope of Service' && !file.FileName ? null : config.ScopeOfService,
					LetterOfAuthority:
						type === 'Letter of Authority' && !file.FileName ? null : config.LetterOfAuthority,
					BusinessOverview:
						type === 'Business Overview' && !file.FileName ? null : config.BusinessOverview,
				};
				return this.businessEmailSettingsService.save(body, this.companyCode);
			}),
			catchError(() => of('Error'))
		);
	};

	uploadFileFn$ = ({ file, type }) => {
		return this.businessEmailSettingsService.uploadDocument(file).pipe(
			mergeMap(documentId => this.businessEmailSettingsService.getDocument(documentId)),
			withLatestFrom(this.businessConfigQuery.businessConfig$),
			mergeMap(([documentLink, config]) => {
				const { DocumentID } = documentLink;
				const body = {
					...config,
					BusinessCode: this.companyCode,
					BusinessFooterLogo:
						type === 'FooterLogo' ? (documentLink ? DocumentID : config.BusinessFooterLogo) : config.BusinessFooterLogo,
					BusinessHeaderLogo:
						type === 'HeaderLogo' ? (documentLink ? DocumentID : config.BusinessHeaderLogo) : config.BusinessHeaderLogo,
					DisclosureDocument:
						type === 'Disclosure' ? (documentLink ? DocumentID : config.DisclosureDocument) : config.DisclosureDocument,
					DeclarationDocument:
						type === 'Declaration'
							? documentLink
								? DocumentID
								: config.DeclarationDocument
							: config.DeclarationDocument,
					ScopeOfService:
						type === 'Scope of Service' ? (documentLink ? DocumentID : config.ScopeOfService) : config.ScopeOfService,
					LetterOfAuthority:
						type === 'Letter of Authority'
							? documentLink
								? DocumentID
								: config.LetterOfAuthority
							: config.LetterOfAuthority,
					BusinessOverview:
						type === 'Business Overview'
							? documentLink
								? DocumentID
								: config.BusinessOverview
							: config.BusinessOverview,
				};

				this.businessConfig = body;
				return this.businessEmailSettingsService.save(body, this.companyCode);
			}),
			catchError(() => of('Error'))
		);
	};

	sendBtnClick$ = ({ values }) => {
		const body = {
			BusinessCode: this.companyCode,
			TradingName: values.TradingName ? values.TradingName : '',
			BusinessPhoneNumber: values.BusinessPhoneNumber ? values.BusinessPhoneNumber : '',
			BusinessEmailAddress: values.BusinessEmailAddress ? values.BusinessEmailAddress : '',
			FacebookLink: values.FacebookLink ? values.FacebookLink : '',
			LinkedInLink: values.LinkedInLink ? values.LinkedInLink : '',
			UnsubscribeGroupingID: values.UnsubscribeGroupingID ? values.UnsubscribeGroupingID : '',
		};
		return this.businessEmailSettingsService.save(body, this.companyCode).pipe(catchError(() => of('Error')));
	};

	getDocument$ = ({ value }) => {
		return this.businessEmailSettingsService.getDocument(value).pipe(catchError(() => of('Error')));
	};

	downloadLink$ = ({ documentID }) => {
		return this.businessEmailSettingsService.downloadLink(documentID);
	};
}
