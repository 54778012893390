import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from "@angular/core";
import { ViewGlobalSearch } from './global-search.service';

export interface GlobalSearchState {
  recentSearch: ViewGlobalSearch[]
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'globalSearch' })
export class GlobalSearchStore extends Store<GlobalSearchState> {
  constructor() {
		super({
      recentSearch: []
    })
  }
	setRecentSearches(recentSearch: ViewGlobalSearch[]) {
    this.update({ recentSearch });
  }
}
		