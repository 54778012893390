import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { objectUtil } from '../../../../util/util';
import { CrtSettingsStore } from '../../state/crt-settings.store';
import { CrtSettingsQuery } from '../../state/crt-settings.query';
import { annualTaxableMT } from '@shared/models/client-review-template/merge-tags/crt-lr-insurance/declaration/declaration.merge-tags';

@Injectable()
export class MergeTagsCrtSettingsService {
	mergeTags$ = this.query.mergeTags$;

	constructor(
		private api: ApiService,
		private store: CrtSettingsStore,
		private query: CrtSettingsQuery
	) {}

	getMergeTags(
		order: number,
		tableCode: string,
		referenceId?: number,
		dataType?: string
	) {
		let endpoint = `crt/merge-tag/${tableCode}/reference/${referenceId || 0}`;
		if (dataType) {
			endpoint = `${endpoint}/${dataType}`;
		}
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags, ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getMtFactFind(order: number, dataType?: string) {
		const referenceId = 0;
		const endpoint = `crt/merge-tag/fact-find/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [
						...this.query.getValue().mergeTags,
						...x,
						annualTaxableMT,
					];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getMtRiskAnalysis(order: number, dataType?: string) {
		const referenceId = 0;
		const endpoint = `crt/merge-tag/risk-analysis/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags, ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getMtSettings(order: number, dataType?: string) {
		const referenceId = 0;
		const endpoint = `crt/merge-tag/settings/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags, ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getMtSettingDetails(order: number, dataType?: string) {
		const referenceId = 0;
		const endpoint = `crt/merge-tag/setting-details/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags, ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	postMergeTag(data: {
		referenceId: number;
		dataType: string[];
		table: string[];
	}) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			catchError(() => of([]))
		);
	}

	updateMtState(data) {
		applyTransaction(() => {
			const state = this.query.getValue().mergeTags || [];
			const updatedData =
				data?.reduce((acc, curr) => {
					const index = acc?.findIndex((item) => item.metaKey === curr.metaKey);
					if (index === -1) {
						return [...acc, curr];
					}
					acc[index] = curr;
					return acc;
				}, state) || [];
			this.store.setMergeTags([...updatedData]);
		});
	}

	getOtherMergeTags(referenceId: number, tableCode: string, dataType?: string) {
		const body = {
			ReferenceId: +referenceId,
			DataType: [dataType],
			Table: [tableCode],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : [])),
			catchError(() => of([]))
		);
	}
}
