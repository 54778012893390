<div class="position-relative h-100">
  <div class="container-fluid h-100 px-0">
    <div class="row pb-2 customers__row font-weight-bold">
      <div class="col-4 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate text-left">
        Client
      </div>
      <div class="col-4 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate text-center">
        Status Clock
      </div>
      <div class="col-4 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate text-right">
        Advice status
      </div>
    </div>
    <div class="row bg-white customers__table-body position-relative">
      <div class="col px-3 position-relative h-100">
        <div class="customers__table-body__wrapper">
          <div class="row customers__row" *ngFor="let data of customers; let first = first; trackBy: trackByFn">

            <div *ngIf="first" class="col-12">
              <div class="border-bottom"></div>
            </div>

            <div class="col-4 py-2 body-col tap-text-color-h4 text-left">
              <span>{{ data.Name }}</span>
            </div>
            <div class="col-4 py-2 body-col tap-text-color-h4 text-center">
              <span>{{ formatStatusClock(data.StatusClock) }}</span>
            </div>
            <div class="col-4 py-2 pr-4 body-col tap-text-color-h4 text-right">
              <span>In progress</span>
              <div class="indicator float-right ml-1 mt-1"></div>
            </div>
            <div class="col-12">
              <div class="border-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
