import { Directive, HostListener, ElementRef, Renderer2, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appHoverable]'
})
export class HoverableDirective implements OnInit {

  @Input() hoverClass = 'hover';

  ngOnInit(): void {
    this.render.addClass(this.el.nativeElement, 'clickable');
  }

  constructor(private el: ElementRef, private render: Renderer2) { }

  @HostListener('mouseenter', ['$event'])
  onHover($event) {
    this.render.addClass(this.el.nativeElement, this.hoverClass);
  }
  @HostListener('mouseleave', ['$event'])
  onExit($event) {
    this.render.removeClass(this.el.nativeElement, this.hoverClass);
  }
}
