<form [formGroup]="form" class="form client-acceptance-settings">
  <div class="row pb-0 pl-3 pb-2 pt-4">

    <div *ngIf="loatV2_3QuickWins$ | async" class="col-12 row">
      <div class="col-4">
        <label for="">{{ enableSignedDocumentLabel }}</label>
      </div>
      <div class="col-8 mb-2 form-inline">
        <div class="custom-control check custom-radio mr-3">
          <input
            type="radio"
            class="custom-control-input"
            [id]="'enableSignedDocument_yes'"
            [value]="true"
            formControlName="enableClientSignedDocument"
          />
          <label
            class="custom-control-label pt-1"
            [for]="'enableSignedDocument_yes'"
          >Yes
          </label>
        </div>
        <div class="custom-control check custom-radio mr-2">
          <input
            type="radio"
            class="custom-control-input"
            [id]="'enableSignedDocument_no'"
            [value]="false"
            formControlName="enableClientSignedDocument"
          />
          <label
            class="custom-control-label pt-1"
            [for]="'enableSignedDocument_no'"
          >No
          </label>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <label for="">{{ clientAcceptanceWordingLabel }}</label>
        </div>
        <div class="col-12">
          <textarea
          class="form-control client-acceptance-settings__desc mt-3 mb-2"
          rows="12"
          formControlName="clientAcceptanceWording"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="row pb-0 mb-4">
    <div class="col-12 text-right">
      <app-manual-loader-mini
        [isLoading]="isLoading"
      ></app-manual-loader-mini>
      <button
        type="button"
        class="btn btn-primary my-2"
        [appTheme]="{ 'background-color': 'primarycolor' }"
        (click)="onSave()"
        *ngIf="!isLoading"
      >
        SAVE
      </button>
    </div>
  </div>
</form>
