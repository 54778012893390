import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Directive for Input validation
 * Accept only Whole Number (Positive / Negative)
 */
@Directive({
	selector: '[noDecimalNumeric]',
})
export class NoDecimalNumericDirective {
	private el: HTMLInputElement;

	constructor(private elementRef: ElementRef) {
		this.el = this.elementRef.nativeElement;
	}

	private specialKeys: Array<string> = [
		'Backspace',
		'Tab',
		'End',
		'Home',
		'ArrowLeft',
		'ArrowRight',
		'Delete',
		'Control',
	];

	private check(value: string) {
		const countNegative = (String(value)?.match(/-/g) || [])?.length;
		if (countNegative <= 1) {
			if (value?.toString().trim() === '-' || String(value)?.includes('-')) {
				return String(value);
			} else {
				const regExpString = '^-?\\d*\\.{0}\\d+$';
				return String(value)?.match(new RegExp(regExpString));
			}
		}
		return false;
	}

	@HostListener('keydown', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		const ckey = (event.ctrlKey || event.metaKey) && event.key === 'c';
		const vkey = (event.ctrlKey || event.metaKey) && event.key === 'v';

		// Allow Backspace, tab, end, delete and home keys
		if (this.specialKeys?.indexOf(event.key) !== -1) {
			return;
		}
		const current: string = this.el.value;
		const next: string = current?.concat(event.key);

		// Checks if user hits copy/paste
		if (
			(ckey || vkey) &&
			next?.match(/[A-Za-z]/g).length === 1 &&
			(event.key === 'v' || event.key === 'c')
		) {
			return;
		}

		if (next && !this.check(next)) {
			event.preventDefault();
		}
	}

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const prevValue = +this.el.value?.match(/\./g)?.length;
		const currValue = +pastedText?.match(/\./g)?.length;

		if (prevValue + currValue > 1 || !this.check(pastedText)) {
			event.preventDefault();
		}
	}
}
