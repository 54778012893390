import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mapTo,
	tap,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { objectUtil } from 'src/app/util/util';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { IncomeService } from '../../../income/state/income.service';
import { Property, PropertyState } from './property.model';
import { PropertyQuery } from './property.query';
import { PropertyStore } from './property.store';

@Injectable()
export class PropertyService {
	properties$ = this.query.properties$;
	SU$ = this.ddQuery.orderedChoices$('SU');
	SV1$ = this.ddQuery.orderedChoices$('SV1');
	ST$ = this.ddQuery.orderedChoices$('ST');
	STI$ = this.ddQuery.orderedChoices$('STI');
	APCRTF$ = this.ddQuery.orderedChoices$('APCRTF');
	isLoading$ = this.query.selectLoading();
	propertyAddresses$ = this.query.select((x) => x.propertyAddresses);

	constructor(
		private api: ApiService,
		protected store: PropertyStore,
		protected query: PropertyQuery,
		private ddQuery: DropdownValueQuery,
		private customerService: CustomerService,
		protected crtMortgageQuery: CrtMortgageQuery,
		private incomeService: IncomeService
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(adviceProcessId: number) {
		this.store.setLoading(true);
		return this.api.get<Property[]>(`crt/${adviceProcessId}/FAP`).pipe(
			map((x) => (x || [])?.map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => this.store.set(x || [])),
			tap(() => this.incomeService.computeTaxable()),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data: PropertyState) {
		const body = {
			...data,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			concatMap((x) =>
				this.getPrimaryClientProperties(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			tap((x) => this.store.add(x)),
			catchError(() => of(undefined))
		);
	}

	update(data: Property) {
		const body = {
			...data,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap((x) => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			concatMap((x) =>
				this.getPrimaryClientProperties(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			tap((x) => this.store.upsert(x?.cRTId, x)),
			catchError(() => of(undefined))
		);
	}

	delete(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAP',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap((x) =>
				this.getPrimaryClientProperties(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			tap((x) => this.store.remove(body.CRTId)),
			catchError(() => of(undefined))
		);
	}

	getPrimaryClientProperties(primaryClientId: number) {
		return this.customerService
			.GetSecuritiesByPrimaryClientId(primaryClientId)
			.pipe(
				map(objectUtil.mapPascalCaseToCamelCase),
				map((x) => x.customerServices?.filter((p) => p.status !== 'Sold')),
				tap((x) => this.store.update({ propertyAddresses: x })),
				catchError(() => of(undefined))
			);
	}
}
