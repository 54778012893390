import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoggerService, errorLog } from '../../core/logger/logger.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html',
})
export class ErrorLogComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  constructor(private loggerService: LoggerService) {}
  logs: errorLog[] = [];
  ngOnInit() {
    this.loggerService.log$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
      // find toast with duplicate message
      const duplicateIndex = this.logs.findIndex(a => a.message === x.message);
      // if duplicate message is found
      if (duplicateIndex !== -1) {
        // remove it
        this.logs.splice(duplicateIndex, 1);
      }
      if (this.logs.length > 2) { this.logs?.shift(); }
      this.logs.push(x);

      window.setTimeout(() => {
        const i = this.logs?.indexOf(x);
        if (i > -1) { this.logs?.splice(i, 1); }
        this.remove(x);
      }, 5000);
    });
  }
  remove(item: errorLog) {
    const i = this.logs?.indexOf(item);
    if (i > -1) { this.logs?.splice(i, 1); }
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
