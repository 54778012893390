import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { EmailSettingsModel } from './email-settings.model';
import produce from 'immer';
import { Metakey, columns } from '../email-settings-datatable.config';
import { Injectable } from "@angular/core";

interface ColumnWidth {
  metakey: string;
  width: number;
}

export interface EmailStatusState extends EntityState<EmailSettingsModel> {
  columns: string[];
  /** edit status of cell. key is id and propname */
  cellEditStatus: Record<string, boolean>;
  /** loading status of cell. key is id and propname */
  cellLoadingStatus: Record<string, boolean>;
  /** temp value of cell */
  cellTempValue: Record<string, any>;
}

@Injectable()
@StoreConfig({ name: 'EmailSettings', idKey: 'EmailSettingsId' })
export class EmailSettingsStore extends EntityStore<EmailStatusState, EmailSettingsModel> {
  constructor() {
    super({
			columns: [
				'Email Type',
				'Is Active',
				'Email Subject',
				'Interval Date Start',
				'Development Triggers',
				'From Email',
				'From Name',
				'CC',
				'BCC',
				'Template Id',
				'Attachment',
        'Unsubscribe Group Id'
			],
			cellEditStatus: {},
			cellLoadingStatus: {},
			cellTempValue: {},
		});
  }

  setColumns(columnsVal: string[]) {
    this.update(
      produce(x => {
        x.columns = columnsVal;
      })
    );
  }

  setEdit(id: number, metakey: Metakey, edit: boolean) {
    const key = `${id}-${metakey}`;

    this.update(state => ({
      cellEditStatus: { ...state.cellEditStatus, [key]: edit },
    }));
  }

  setLoad(id: number, metakey: Metakey, loading: boolean) {
    const key = `${id}-${metakey}`;
    this.update(state => ({
      cellLoadingStatus: { ...state.cellLoadingStatus, [key]: loading },
    }));
  }

  setTempValue(id: number, metakey: string, value: any) {
    const key = `${id}-${metakey}`;
    this.update(state => ({
      cellTempValue: { ...state.cellTempValue, [key]: value },
    }));
  }

  updateField(id, metakey: string, metavalue: string) {
    this.update(
      id,
      produce(draft => {
        draft[Object.values(columns)?.find(x => x.metakey === metakey).prop] = metavalue;
      })
    );
  }
}
