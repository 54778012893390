import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { TlMoatSettingsQuery } from '../../state/tl-moat-settings.query';
import { TlMoatSettingsStore } from '../../state/tl-moat-settings.store';
import { ApiService } from '@core/base/api.service';
import { iif, of } from 'rxjs';
import * as R from 'ramda';
import {
	ServicingCalculator,
	ServicingCalculatorState,
} from './servicing-calculators-settings.model';
import { objectUtil } from '@util/util';
import { TlMoatSettingsTypes } from '../../state/tl-moat-settings.model';

@Injectable()
export class ServicingCalculatorSettingsService {
	servicingCalculators$ = this.query.servicingCalculators$;

	constructor(
		private api: ApiService,
		private store: TlMoatSettingsStore,
		private query: TlMoatSettingsQuery
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getServicingCalculatorList() {
		const type = TlMoatSettingsTypes.ServicingCalc;
		const endpoint = `businesses/settings/0/${type}`;

		return this.api.get<ServicingCalculator[]>(endpoint).pipe(
			map((data) => {
				const defaultPrio = data.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							SortNo: item?.SortNo ?? defaultPrio,
					  }))
					: [];
			}),
			map((data) => R.sort((a, b) => a?.SortNo - b?.SortNo, data)),
			map((data) => objectUtil.mapPascalCaseToCamelCase(data || [])),
			tap((data) =>
				applyTransaction(() => {
					this.store.setServicingCalculators(Object.values(data));
				})
			),
			catchError(() => of(undefined))
		);
	}

	addTemplate(payload: ServicingCalculatorState) {
		const sortList = this.query
			.getValue()
			.servicingCalculators?.map((x) => +x?.sortNo);
		const data = {
			...payload,
			sortNo: sortList ? Math.max(...sortList) + 1 : 0,
		};
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		const endpoint = `businesses/settings/0`;

		return this.api.put<ServicingCalculatorState[]>(endpoint, body).pipe(
			tap(() => {
				applyTransaction(() => {
					const state = [
						...(this.query.getValue().servicingCalculators || []),
						data,
					];
					this.store.setServicingCalculators(state);
				});
			}),
			catchError((err) => of(err))
		);
	}

	updateTemplate(
		payload: ServicingCalculatorState,
		refetchQuery: boolean = false
	) {
		const body = objectUtil.mapCamelCaseToPascalCase(payload);
		const endpoint = `businesses/settings/0`;
		return this.api.put<ServicingCalculatorState[]>(endpoint, body).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.servicingCalculators?.map((x) =>
							+x?.settingsId === +payload?.settingsId ? payload : x
						);
					this.store.setServicingCalculators(data);
				});
			}),
			concatMap(() => {
				return iif(
					() => refetchQuery,
					this.getServicingCalculatorList(),
					of(payload)
				);
			}),
			catchError((err) => of(err))
		);
	}
}
