import { Component, OnInit } from '@angular/core';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { ProviderSettingsService } from './state/providers-settings.service';
import { ProviderSettingsMapper } from './state/providers-settings.mapper';
import { ProvidersSettingsState } from './state/providers-settings.model';
import { Subject } from 'rxjs';
import { KiwiSaverSettingsQuery } from '@modules/kiwisaver-settings/state/kiwisaver-settings.query';

@Component({
	selector: 'app-providers-settings',
	templateUrl: './providers-settings.component.html',
	styleUrls: ['./providers-settings.component.scss'],
})
export class ProvidersSettingsComponent implements OnInit {
	isLoading = false;
	isDisabled = false;

	isLoadingOutcomes = false;
	isDisabledOutcomes = false;

  providersSettings$ = this.service.providersSettings$;

	providersData: ProvidersSettingsState;

	providers$ = this.ksQuery.orderedChoices$;

	private onDestroy$ = new Subject<void>();

	constructor(
		private service: ProviderSettingsService,
		private ksQuery: KiwiSaverSettingsQuery
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.providersSettings$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      if (data) {
				this.providersData = data;
      }
    });
	}

	disableForms(data) {
		this.isDisabled = data;
	}

	disableFormsOutcomes(data) {
		this.isDisabledOutcomes= data;
	}

	save(data) {
		this.isLoading = true;

		const newData = {
			...this.providersData,
			providers: data
		};

    const formValue = ProviderSettingsMapper.mapToUpsert(newData);

    this.service
      .updateProvidersSettings(formValue)
      .pipe(
        finalize(() => (this.isLoading = false)),
        take(1)
      ).subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
