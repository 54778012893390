import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TopupRequirementsDetailsState } from './topup-requirements.model';
export interface TopupRequirementsState extends EntityState<TopupRequirementsDetailsState> {
	topupRequirements: TopupRequirementsDetailsState[];
	totalTopupRequired: number;
}

@Injectable()
@StoreConfig({ name: 'topupRequirements', idKey: 'cRTId' })
export class TopupRequirementsStore extends EntityStore<TopupRequirementsState, TopupRequirementsDetailsState> {
	constructor() {
		super({
			topupRequirements: [],
			totalTopupRequired: 0
		});
	}

	setTotalTopupRequired(total: number) {
		this.update({totalTopupRequired: total});
	}
}
