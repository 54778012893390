<div class="container-fluid tap-client-profile clearfix">
  <div class="row">
    <div class="col tap-client-profile__main-content py-3 px-3">
      <div class="row">
        <div class="col">
          <app-client-profile-group
            class="d-block"
            [addMode]="true"
            [isSaving]="isSaving"
            [isEdit]="true"
            [isLead]="isLead$ | async"
            [advisers]="advisers$ | async"
						[altAdvisers]="altAdviserChoices$ | async"
            [leadGens]="leadGens$ | async"
            [PCT]="PCT$ | async"
            [PCE]="PCE$ | async"
            [PCPC]="PCPC$ | async"
            [PCLE]="PCLE$ | async"
            [PCLT]="PCLT$ | async"
            [PCR]="PCR$ | async"
            [LS]="LS$ | async"
            [LT]="LT$ | async"
            [SAS]="SAS$ | async"
            [allStaff]="allStaff$ | async"
            (saveEvent)="addNewPrimaryClient($event)"
          >
          </app-client-profile-group>
        </div>
      </div>
      <div class="row">
        <div class="col px-0">
          <tabset class="tap-tabset tap-tabset--border-radius-left-right tap-client-profile__tabset">
            <tab heading="People / Entities" id="peopleEntities" [customClass]="'m-0'">
              <div class="row clearfix mx-0">
                <div class="col">
                  <app-form-person
                    [isLead]="isLead$ | async"
                    [addMode]="true"
                    [isEdit]="true"
                    [disableDelete]="true"
                    [title]="title$ | async"
                    [employment]="employment$ | async"
                    [relationship]="relationship$ | async"
                    [preferredContact]="preferredContact$ | async"
                    [leadTypeChoices]="leadTypeChoices$ | async"
                    [leadOriginChoices]="leadOriginChoices$ | async"
                    [leadGenChoices]="leadGenChoices$ | async"
										[adviserGenChoices]="adviserGenChoices$ | async"
										[allStaffChoices]="allStaffChoices$ | async"
                    [industry]="Industry$ | async"
										[businessConfig$]="businessConfig$"
                    [addPhoto$]="uploadPhoto$"
                  ></app-form-person>
                </div>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
