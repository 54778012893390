import { Moment } from 'moment';

export interface Note {
	NotesID: number;
	CustomerServiceID: number;
	Notes: string;
	IsActive: boolean;
	CreateDateTime: string;
	CreatedByStaffId: number;
	CustomerID: number;
	StaffName: string;
	StaffLevel: number;
	DueDateTime: string;
	ActivityType: string;
	ActivityName: string;
	AssignedToAdviser: number;
	IsHistory: boolean;
	IsActivity: boolean;
	IsPinned?: boolean;
}

export interface NoteState {
	notesID: number;
	customerServiceID: number;
	notes: string;
	isActive: boolean;
	createDateTime: string | Moment;
	createdByStaffId: number;
	customerID: number;
	staffName: string;
	staffLevel: number;
	dueDateTime: string;
	activityType: string;
	activityName: string;
	assignedToAdviser: number;
	isHistory?: boolean;
	isActivity?: boolean;
	isPinned?: boolean;
}

export enum NoteTypes {
	LR = 'LR',
	Mortgage = 'M',
	Property = 'S',
	Asset = 'A',
	FG = 'FG',
	KiwiSaver = 'KS',
	AdviceProcess = 'AP',
	Note = 'Note',
	MOATCompany = 'AMFC',
	Investment = 'I',
	Liability = 'L',
}

export enum NoteFilters {
	Activities = 'activities',
	Notes = 'notes',
	All = 'all'
}
