import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimplePaginatorComponent } from './simple-paginator.component';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
	declarations: [SimplePaginatorComponent],
	exports: [SimplePaginatorComponent],
	imports: [CommonModule, PaginationModule.forRoot()],
	providers: [
		{
			provide: PaginationConfig,
			useValue: {
				main: {},
			},
		},
	],
})
export class SimplePaginatorModule {}
