import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { MortgageProviderCommissionState } from '../state/provider-commission.model';

@Injectable()
@StoreConfig({ name: 'mortgageProviderCommission', idKey: 'settingsId' })
export class MortgageProviderCommissionStore extends EntityStore<MortgageProviderCommissionState[]> {
	constructor() {
		super();
	}
}
