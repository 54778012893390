import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { mapTo, take, tap } from 'rxjs/operators';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { InvestmentService } from './states/investment.service';

const dropdownCodes: string[] = ['IP', 'IS', 'PCLE', 'AT', 'IT', 'IPIRR'];

@Injectable()
export class InvestmentResolver implements Resolve<boolean> {
  constructor(
    private dropdownValueService: DropdownValueService,
    private investmentService: InvestmentService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const dropdown$ =
      this.dropdownValueService.getDropdownValueList$(dropdownCodes);
    return forkJoin([dropdown$]).pipe(
      tap(() => this.investmentService.getColumns().pipe(take(1)).subscribe()),
      tap(() =>
        this.investmentService.getColumnWidths().pipe(take(1)).subscribe()
      ),
      mapTo(true)
    );
  }
}
