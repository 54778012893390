import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { BusinessConfig } from './business-config.model';

export interface BusinessConfigState {
	config: BusinessConfig;
}

export function createInitialState(): BusinessConfigState {
	return {
		config: null,
	};
}

@Injectable()
@StoreConfig({ name: 'business config' })
export class BusinessConfigStore extends Store<BusinessConfigState> {
	constructor() {
		super(createInitialState());
	}
}
