<form class="main-medical-history p-4">
  <div class="form-row mb-3">
    <div class="col-12">
      <app-history-medical
        [isMedicalLoading$]="medicalLoading$"
        [clients]="clients"
        [addFn]="addMedicalFn$"
        [addMeasures]="addMeasurementFn$"
        [currentTerms$]="apcrtcc$"
        [editFn]="updateMedicalFn$"
        [editMeasures]="updateMeasurementFn$"
        [editMedicalHistoryOptions]="updateMedicalHistoryFn$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
      >
      </app-history-medical>
    </div>
  </div>
  <div class="form-row mb-3">
    <div class="col-12">
      <app-history-family
        [clients]="clients"
        [currentTerms$]="apcrtcc$"
        [isFamilyLoading$]="familyLoading$"
        [addFn]="addFamilyFn$"
        [editFn]="updateFamilyFn$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
      >
      </app-history-family>
    </div>
  </div>
  <div class="form-row mb-3">
    <div class="col-12">
      <app-history-gp
        [peoples$]="peoples$"
        [isGpClientsLoading$]="isGpClientsLoading$"
        [addFn]="addGpFn$"
        [editFn]="updateGpFn$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
      >
      </app-history-gp>
    </div>
  </div>
</form>
