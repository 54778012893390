<div
	class="crt-main-page position-relative clearfix d-flex"
	[ngClass]="{ 'export-margin': isExporting }"
	[class.clean-theme]="appearance === 'clean'"
>
	<!-- CRT : Sidebar  -->
	<!-- <div class="crt-main-page__sidebar-mobile" [class.d-block]="isToggleSidebar"></div> -->
	<div class="crt-main-page__sidebar">
		<div class="crt-main-page__sidebar__header d-flex align-items-center">
			<div class="d-block">
				<span
					class="text-truncate crt-title"
					[tooltip]="family.length > 21 ? family : null"
					placement="right"
					>{{ family }}</span
				>
				<span>Family</span>
			</div>
		</div>
		<ng-content select="accordion[role='sidebar']"></ng-content>
		<div
			class="crt-main-page__sidebar__toggle d-flex justify-content-center align-items-center d-lg-none clickable"
			(click)="toggle()"
		>
			<i class="material-icons md-20 tap-text-color-h6">
				chevron_{{ isToggleSidebar ? "left" : "right" }}
			</i>
		</div>
	</div>

	<!-- CRT : Main Content  -->
	<div
		class="crt-main-page__content"
		[class.crt-main-page__content-panel--open]="isCollapsed"
		[class.px-3]="appearance !== 'clean'"
	>
		<ng-content select="router-outlet"></ng-content>
	</div>

	<!-- CRT : Notes -->
	<div
		class="crt-collapse-panel"
		[class.crt-collapse-panel--open]="isCollapsed"
	>
		<div class="toggle-wrapper">
			<span
				class="crt-collapse-panel__toggle material-icons md-20 tap-text-color-h6"
				(click)="toggleCollapsePanel()"
			>
				{{ isCollapsed ? "keyboard_arrow_right" : "keyboard_arrow_left" }}
			</span>
		</div>
		<ng-container *ngIf="isNotes">
			<div
				id="crtNotes"
				class="col tap-client-profile__activity-timeline notes"
			>
				<app-crt-notes-timeline
					[adviserId]="adviserId"
					[oatType]="oatType"
					[crtTypes]="crtTypes$ | async"
					[crtNotes$]="crtNotesFactAndFind$"
					[activeType$]="activeType$"
					[addNoteFn$]="addCrtNote$"
					[editNoteFn$]="updateCrtNote$"
					[deleteNoteFn$]="deleteCrtNote$"
					[isLead]="isLead$ | async"
					[customerId]="customerId$ | async"
					[customerName]="customerName$ | async"
					[location]="customerLocation$ | async"
					[activityType$]="AT$"
					[activityMeeting$]="AM$"
					[adviserChoices$]="allActiveStaffs$"
					[adviserCalendarChoices$]="adviserCalendarChoices$"
					[addActivityFn$]="addActivity$"
					[exportFn$]="exportCrtNotes$"
					(setTypeEvent)="setCrtNotesType($event)"
				>
				</app-crt-notes-timeline>
			</div>
		</ng-container>
	</div>
</div>
