import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	Renderer2,
} from '@angular/core';
import { ViewCustomerDocument } from '../../shared/models/_general/customer-document.viewmodel';
import { Subject, Observable, Observer } from 'rxjs';
import { DeleteModalComponent } from '../modal/delete-modal/delete-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TransferModalComponent } from '../modal/transfer-modal/transfer-modal.component';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';
import { mergeMap } from 'rxjs/operators';
import { JsonResultStatus } from '../../core/base/api.service';
import { UploadModalComponent } from '../../shared/modal/upload-modal/upload-modal.component';
import { RouteService } from '../../core/config/route.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-tab-form',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit {
	companyCode: string;

	private _dataList: ViewCustomerDocument[] = [];
	request: any;
	@Input() set dataList(value: ViewCustomerDocument[]) {
		this._dataList = value;
	}
	get dataList(): ViewCustomerDocument[] {
		return this._dataList;
	}
	@Input() types: ViewDisplayValue[];
	@Input() activeDocType: string;
	@Output() deleteEvent: EventEmitter<ViewCustomerDocument>;
	@Output() transferEvent: EventEmitter<{
		doc: ViewCustomerDocument;
		code: string;
	}>;
	@Input() uploadDocFn$: ({
		doc,
		doctype,
	}) => Observable<JsonResultStatus>;
	@Input() downloadLink$: ({ documentID }) => Observable<string>;

	constructor(
		private modalService: BsModalService,
		private routeService: RouteService,
		private router: Router,
		private renderer: Renderer2,
	) {
		this.dataList = [];
		this.types = [];
		this.deleteEvent = new EventEmitter<ViewCustomerDocument>();
		this.transferEvent = new EventEmitter();
	}

	ngOnInit() {}

	delete(data: ViewCustomerDocument) {
		this.deleteEvent.emit(data);
	}

	deleteConfirm(doc: ViewCustomerDocument) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete(doc);
			obs.complete();
		});
		const initState = {
			header: 'Delete Document',
			message: `Are you sure you want to delete '${doc.DocumentName}'?`,
			delete$: confirm,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}
	transfer(doc: ViewCustomerDocument, code: string) {
		this.transferEvent.emit({ doc, code });
	}
	transferConfirm(doc: ViewCustomerDocument) {
		const confirm: Subject<string> = new Subject();
		confirm.asObservable().subscribe((x) => this.transfer(doc, x));
		const initState = {
			message: `'${doc.DocumentName}' will be transferred, are you sure?`,
			transferSubject: confirm,
			types: this.types?.filter((x) => x.value !== doc.DocumentTypeCode),
		};
		this.modalService.show(TransferModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	downloadLink = (data) => {
		// Old implementation
		// this.downloadLink$({ documentID: data }).pipe(
		// 	tap((res) => {
		// 		window.location.href = res;
		// 	}),
		// ).subscribe();

		// New Implementation
		if (data) {
			if (data?.FileExtension?.toLowerCase() === '.pdf') {
				const pdfUrl = this.router.serializeUrl(
					this.router.createUrlTree(
						this.routeService.viewPdf({
							documentId: data?.DocumentID,
							name: data?.FileName,
						})
					)
				);

				window.open(pdfUrl, '_blank');
			} else {
				const a = this.renderer.createElement('a');
				this.renderer.setStyle(a, 'display', 'none');
				this.renderer.setAttribute(a, 'href', data?.DocumentLink);
				a.click();
			}
		}
	};

	upload(req): Observable<JsonResultStatus> {
		return this.uploadDocFn$({ doc: req, doctype: this.activeDocType });
	}

	openUploadModal() {
		const doctype = this.activeDocType;
		const upload = (req) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(mergeMap((x) => this.upload(req)));
		const initState = {
			upload,
			data: {
				document_type: doctype,
				customer_id: 0,
			},
			headerTitle: 'Add New Document',
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}
	isNullValue(strval) {
		return strval === undefined || strval == null || strval.length <= 0
			? ''
			: strval;
	}
}
