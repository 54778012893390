<div class="declaration-document clearfix">
	<div class="declaration-document__content">
		<ng-container *ngIf="isLoadingDocument || isLoadingDocument === undefined">
			<app-manual-loader-mini
				[isLoading]="isLoadingDocument || isLoadingDocument === undefined"
			></app-manual-loader-mini>
		</ng-container>

		<ng-container
			*ngIf="!isLoadingDocument && isLoadingDocument !== undefined && data"
		>
			<app-wysiwyg
				#contentEditor
				class="d-block"
				[content]="content"
				[readOnly]="true"
				[generatePdf$]="generatePdf$"
				(getPDF)="getPdf($event)"
				[zoom]="zoom$ | async"
				(zoomEvent)="zoomFn($event)"
			></app-wysiwyg>

			<div class="clearfix">
				<form class="form" [formGroup]="form">
					<div class="clearfix mb-5 crt-signature-comp" formArrayName="signatures">
						<div
							class="
								form-row
								flex-column flex-lg-row
								align-items-end
								signature-image-container
							"
							*ngFor="let s of Signatures.controls; let i = index"
							[formGroupName]="i"
						>
							<div class="col col-lg-3 mb-2 name-field-padding"
								[tooltip]="s.get('fullName')?.value">
								<input
									type="text"
									class="form-control crt-form-control"
									id="fullNameId_{{i}}"
									formControlName="fullName"
									placeholder="Enter Full Name"
								/>
								<div class="font-weight-bold">Full Name</div>
							</div>
							<div class="col mb-2 date-field-padding">
								<app-date-input
									class="crt-form-control-field"
									id="dateId_{{i}}"
									#date
									ngProjectAs="input"
									textboxClass="theme-crm-field-disabled"
									placeholder="Enter date  dd/mm/yyyy"
									formControlName="date"
									textboxId="date"
									(onChangeEvent)="onChange()"
								>
								</app-date-input>
								<div class="font-weight-bold">Date</div>
							</div>
							<div class="col mb-2">
								<div
									class="
										clearfix
										border-bottom
										d-flex
										justify-content-end
										align-items-end
										w-100
									"
								>
									<div class="flex-fill align-self-start text-center">
										<img
											*ngIf="!!s.get('signature')?.value"
											[src]="s.get('signature')?.value"
											alt="Signature"
											class="img-fluid"
										/>
									</div>
									<button
										id="signId_{{i}}"
										class="icon-btn h-auto"
										title="Sign"
										(click)="sign(i)"
									>
										<i
											class="fas fa-file-signature fa-14 tap-text-color-h6"
										></i>
									</button>
								</div>
								<div class="font-weight-bold">Signature</div>
							</div>
						</div>
					</div>

					<div class="form-row">
						<div class="col-12">
							<button
								id="saveDeclarationBtn"
								class="
									tap-btn tap-btn-outline
									tap-btn--shadow
									px-4
									mr-2
									mb-2
									col-auto
								"
								(click)="saveDocument()"
								[disabled]="!!isLoading"
							>
								Save to File
							</button>
							<button
								id="downloadDeclarationBtn"
								class="
									tap-btn tap-btn-outline
									tap-btn--shadow
									px-4
									mx-2
									mb-2
									col-auto
								"
								(click)="downloadDocumentPDF()"
								[disabled]="!!isLoading"
							>
								Download Document
							</button>
							<button
								id="reloadDeclarationBtn"
								class="
									tap-btn tap-btn-outline
									tap-btn--shadow
									px-4
									mx-2
									mb-2
									col-auto
								"
								type="button"
								(click)="reloadTemplate()"
								[disabled]="!!isLoading"
							>
								Reload Document
							</button>
							<button
								id="uploadDeclarationBtn"
								class="
									tap-btn tap-btn-outline
									tap-btn--shadow
									px-4
									mx-2
									mb-2
									col-auto
								"
								(click)="uploadDocument()"
								[disabled]="!!isLoading"
							>
								Upload Document
							</button>
							<button
								id="emailDeclarationBtn"
								type="button"
								class="tap-btn tap-btn-outline tap-btn--shadow px-4 mx-2 mb-2"
								(click)="generatedPdf(); sendEmail();"
								[disabled]="!!isLoading"
								*ngIf="isEnableEmailOption"
							>
								Email Client
							</button>

							<ng-container *ngIf="!!isLoading || isDownloading">
								<app-manual-loader-mini
									[isLoading]="!!isLoading || isDownloading"
									class="pl-1"
								></app-manual-loader-mini>
							</ng-container>
						</div>
					</div>
				</form>
			</div>
		</ng-container>
	</div>
</div>
