// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export interface Environment {
	production: boolean;
	live?: boolean;
	staging?: boolean;
	log?: boolean;
	flags?: {
		useNewHeader: boolean;
	};
	apiUrl?: string;
	defaultLoginLogo?: string;
	defaultLoginBackground?: string;
	apiProtocol?: string;
	apiVersion?: 2;
	secretKey?: string;
	idleTime?: number;
	swUpdateTime?: number;
	lastDeploymentDate?: string;
	cpUrl?: string;
	cpSiteUrl?: string;
	cpEmailSender?: string;
	datadogRum?: {
		applicationId: string;
		clientToken: string;
		site: string;
		service: string;
		env?: string;
		version?: string;
		sessionSampleRate?: number;
		sessionReplaySampleRate?: number;
		trackResources?: boolean;
		trackLongTasks?: boolean;
		trackUserInteractions?: boolean;
		defaultPrivacyLevel?: string;
	};
	appVersion?: string;
	intercom?: {
		appId: string;
	};
	aiQuoteApi?: string;
	aiQuoteAuth?: string;
	defaultImgUrl?: string;
}

export const environment: Environment = {
	production: false,
	staging: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
