<form [formGroup]="form" class="d-flex w-100">
  <ng-container [ngSwitch]="metakey">
    <ng-container *ngSwitchCase="'Interval Date Start'">
      <span class="tap-required" *ngIf="isEditing && !form.valid">*</span>
      <div class="d-flex align-items-stretch field-wrapper">
        <ng-container *ngIf="!isEditing; else fieldIntervalTemplate;">
          
          <ng-container *ngIf="row.IsCustom === 0">
            <div class="field col">
              <div class="d-flex">
                <div *ngIf="!!row.IntervalDateStart" class="pr-2">
                  {{ row.IntervalDateStart > 0 ? row.IntervalDateStart : row.IntervalDateStart < 0 ? displayPositive(row.IntervalDateStart) : '' }}
                </div>
                <div>
                  {{row.IntervalDateStart > 0 ? 'days after' : (row.IntervalDateStart < 0 ? 'days before' : 'same day')}}
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="row.IsCustom === 1">
            <div class="field col">
              <div class="d-flex">
                <div *ngIf="!!row.IntervalDateStart" class="flex-fill">
                  {{ row.IntervalDateStart > 0 ? row.IntervalDateStart : row.IntervalDateStart < 0 ? displayPositive(row.IntervalDateStart) : '' }}
                </div>
                <div class="flex-fill">
                  {{row.IntervalDateStart > 0 ? 'days after' : (row.IntervalDateStart < 0 ? 'days before' : 'same day')}}
                </div>
              </div>
              <div class="field px-0">
                <div>Start Time: {{ row.IntervalTimeStart }}</div>
              </div>
            </div>

            <div class="py-2 px-1 col-auto">
              to
            </div>

            <div class="field col" >
              <div class="d-flex">
                <div class="flex-fill" *ngIf="!!row.IntervalDateEnd">
                  {{ row.IntervalDateEnd > 0 ? row.IntervalDateEnd : row.IntervalDateEnd < 0 ? displayPositive(row.IntervalDateEnd) : '' }}
                </div>
                <div class="flex-fill">
                  {{row.IntervalDateEnd > 0 ? 'days after' : (row.IntervalDateEnd < 0 ? 'days before' : 'same day')}}
                </div>
              </div>
              <div class="field px-0" *ngIf="row.IsCustom">
                <div>End Time: {{ row.IntervalTimeEnd }}</div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="row.IsCustom === 2">
            <div class="field col">
              <div class="d-flex">
                <div *ngIf="!!row.IntervalDateStart || row.IntervalFixDate" class="pr-2">
                  {{ row.IntervalFixDate ?
                      format(row.IntervalFixDate) :
                        row.IntervalDateStart > 0 ? row.IntervalDateStart :
                        row.IntervalDateStart < 0 ? displayPositive(row.IntervalDateStart)
                      : ''}}
                </div>
                <div>
                  {{ row.IntervalFixDate ? 'fixed date' :
                    row.IntervalDateStart > 0 ? 'days after' : (row.IntervalDateStart < 0 ? 'days before' : 'same day') }}
                </div>
              </div>
    
            </div>
          </ng-container>

					<ng-container *ngIf="row.IsCustom === 3">
            <div class="field col">
              <div class="d-flex">
                <div>
									<!-- TAPNZ-11634 -->
                  Fixed Friday
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="!restrict; else hasRestriction;">
        <div class="control">
          <button *ngIf="!isEditing && row.IsCustom !== 3" type="button" class="icon-btn-bg btn-edit" (click)="edit()">
            <i class="material-icons md-16 dark">&#xE3C9;</i>
          </button>
        </div>
      </ng-container>

      <ng-template #hasRestriction>
        <ng-container *appRestrictTo="restrict">
          <div class="control">
            <button *ngIf="!isEditing && row.IsCustom !== 3" type="button" class="icon-btn-bg btn-edit" (click)="edit()">
              <i class="material-icons md-16 dark">&#xE3C9;</i>
            </button>
          </div>
        </ng-container>
      </ng-template>

      <div>
        <app-manual-loader-mini *ngIf="isLoading" [isLoading]="isLoading" class="loader-mini"></app-manual-loader-mini>
      </div>

      <div class="edit-controls d-flex flex-column" *ngIf="isEditing && !isLoading">
        <button type="submit" class="icon-btn-bg btn-save" (click)="save()">
          <i class="material-icons md-16 dark">&#xE161;</i>
        </button>
        <button type="button" class="icon-btn-bg btn-cancel" (click)="cancel()">
          <i class="material-icons md-16 dark">&#xE5C9;</i>
        </button>
      </div>

      <ng-template #fieldIntervalTemplate>
        <ng-container *ngIf="row.IsCustom === 0">
          <div class="field col">
            <div class="flex-fill pr-1 w-60" class="d-flex">
              <div class="flex-fill pr-1 w-40">
                <input formControlName="IntervalDateStart" type="text" class="form-control" placeholder="- -"
                  (keyup)="intervalDateStartChange($event)" />
              </div>
              <div class="flex-fill pl-1 w-60">
                <select formControlName="IntervalDayStart" class="form-control" (change)="intervalDayStartChange($event)">
                  <option value=""></option>
                  <option *ngFor="let d of (intervalDays$ | async) | sortBy: ['display']" [value]="d.value">
                    {{d.display}}</option>
                </select>
              </div>
            </div>
            <div class="field px-0">
              <input formControlName="IntervalTimeStart" type="text" class="form-control" placeholder="13:30" />
            </div>
          </div>
          <div class="field col"></div>
        </ng-container>

        <ng-container *ngIf="row.IsCustom === 1">
          <div class="field col">
            <div class="flex-fill pr-1 w-60" class="d-flex">
              <div class="flex-fill pr-1 w-40">
                <input formControlName="IntervalDateStart" type="text" class="form-control" placeholder="- -"
                  (keyup)="intervalDateStartChange($event)" />
              </div>
              <div class="flex-fill pl-1 w-60">
                <select formControlName="IntervalDayStart" class="form-control" (change)="intervalDayStartChange($event)">
                  <option value=""></option>
                  <option *ngFor="let d of (intervalDays$ | async) | sortBy: ['display']" [value]="d.value">
                    {{d.display}}</option>
                </select>
              </div>
            </div>
            <div class="field px-0">
              <input formControlName="IntervalTimeStart" type="text" class="form-control" placeholder="13:30" />
            </div>
          </div>
  
          <div class="py-2 px-1 col-auto">
            to
          </div>
  
          <div class="field col">
            <div class="d-flex">
              <div class="flex-fill pr-1 w-40">
                <input formControlName="IntervalDateEnd" type="text" class="form-control" placeholder="- -"
                  (keyup)="intervalDateEndChange($event)" />
              </div>
              <div class="flex-fill pl-1 w-60">
                <select formControlName="IntervalDayEnd" class="form-control" (change)="intervalDayEndChange($event)">
                  <option value=""></option>
                  <option *ngFor="let d of (intervalDays$ | async) | sortBy: ['display']" [value]="d.value">
                    {{d.display}}</option>
                </select>
              </div>
            </div>
            <div class="field px-0">
              <input formControlName="IntervalTimeEnd" type="text" class="form-control" placeholder="13:30" />
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="row.IsCustom === 2">
          <div class="field col">
            <div [ngClass]="checkIsNaN(form.get('IntervalDayStart').value) ? 'd-flex flex-column' : 'd-flex'">
              <div [ngClass]="checkIsNaN(form.get('IntervalDayStart').value) ? 'w-100' : 'w-40'">
                <ng-container *ngIf="checkIsNaN(form.get('IntervalDayStart').value)">
                  <app-date-input
                    textboxId="IntervalFixDate"
                    formControlName="IntervalFixDate"
                    placeholder="dd/mm"
                    noYear="true"
                  >
                  </app-date-input>
                </ng-container>

                <ng-container *ngIf="!checkIsNaN(form.get('IntervalDayStart').value)">
                  <input formControlName="IntervalDateStart" type="text" class="form-control" placeholder="- -"
                    (keyup)="intervalDateStartChange($event)" />
                </ng-container>

              </div>
              <div [ngClass]="checkIsNaN(form.get('IntervalDayStart').value) ? 'w-60 pt-1' : 'w-60 pl-2'">
                <select formControlName="IntervalDayStart" class="form-control" (change)="intervalDayStartChange($event)">
                  <option value=""></option>
                  <option *ngFor="let d of (intervalDaysWithFixedDay$ | async) | sortBy: ['display']" [value]="d.value">
                    {{d.display}}</option>
                </select>
              </div>
            </div>
            <div class="field px-0" *ngIf="!checkIsNaN(form.get('IntervalDayStart').value)">
              <input formControlName="IntervalTimeStart" type="text" class="form-control" placeholder="13:30" />
            </div>
          </div>
          <div class="field col"></div>
        </ng-container>

      </ng-template>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span class="tap-required" *ngIf="isEditing && !form.valid">*</span>
      <div class="d-flex align-items-stretch flex-column field-wrapper">
        <ng-container *ngIf="!isEditing; else fieldTemplate;">
					<div class="field">
						<div>
							{{
								row[prop] === "A"
									? "Adviser"
									: row[prop] === "T"
									? "Trading Name"
									: row[prop] === "B"
									? "Business Email"
									: !row[prop]
									? ""
									: "Other"
							}}
						</div>
					</div>
					<div class="field" *ngIf="row[prop] !== 'A' && row[prop] !== 'T' && row[prop] !== 'B'">
						<div>
							{{ row[prop] }}
						</div>
					</div>
        </ng-container>
      </div>

      <ng-container *ngIf="!restrict; else hasRestriction;">
        <div class="control">
          <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()">
            <i class="material-icons md-16 dark">&#xE3C9;</i>
          </button>
        </div>
      </ng-container>

      <ng-template #hasRestriction>
        <ng-container *appRestrictTo="restrict">
          <div class="control">
            <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()">
              <i class="material-icons md-16 dark">&#xE3C9;</i>
            </button>
          </div>
        </ng-container>
      </ng-template>

      <div>
        <app-manual-loader-mini *ngIf="isLoading" [isLoading]="isLoading" class="loader-mini"></app-manual-loader-mini>
      </div>

      <div class="edit-controls d-flex flex-column" *ngIf="isEditing && !isLoading">
        <button type="submit" class="icon-btn-bg btn-save" (click)="save()">
          <i class="material-icons md-16 dark">&#xE161;</i>
        </button>
        <button type="button" class="icon-btn-bg btn-cancel" (click)="cancel()">
          <i class="material-icons md-16 dark">&#xE5C9;</i>
        </button>
      </div>

      <ng-template #fieldTemplate>
        <ng-container *ngIf="metakey === 'From Email'">
          <div class="field">
            <select formControlName="SenderDD" class="form-control">
              <!-- <option value=""></option> -->
              <option *ngFor="let d of (emailFieldsDropdown$ | async) | sortBy: ['value']" [value]="d.value">
                {{d.display}}</option>
            </select>
          </div>
          <div class="field" *ngIf="form.get('SenderDD').value === 'O'">
            <input formControlName="Sender" type="email" class="form-control" placeholder="- -" />
          </div>
        </ng-container>

        <ng-container *ngIf="metakey === 'From Name'">
          <div class="field">
            <select formControlName="FromNameDD" class="form-control">
              <!-- <option value=""></option> -->
              <option *ngFor="let d of (fromNameDropdown$ | async) | sortBy: ['value']" [value]="d.value">
                {{d.display}}</option>
            </select>
          </div>
          <div class="field" *ngIf="form.get('FromNameDD').value !== 'A' && form.get('FromNameDD').value !== 'T'">
            <input formControlName="FromName" type="text" class="form-control" placeholder="- -" />
          </div>
        </ng-container>

        <ng-container *ngIf="metakey === 'CC'">
          <div class="field">
            <select formControlName="CarbonCopyDD" class="form-control">
              <option value=""></option>
              <option *ngFor="let d of (emailFieldsDropdown$ | async) | sortBy: ['value']" [value]="d.value">
                {{d.display}}</option>
            </select>
          </div>
          <div class="field" *ngIf="form.get('CarbonCopyDD').value === 'O'">
            <input formControlName="CarbonCopy" type="text" class="form-control" placeholder="- -" />
          </div>
        </ng-container>

        <ng-container *ngIf="metakey === 'BCC'">
          <div class="field">
            <select formControlName="BlindCarbonCopyDD" class="form-control">
              <option value=""></option>
              <option *ngFor="let d of (emailFieldsDropdown$ | async) | sortBy: ['value']" [value]="d.value">
                {{d.display}}</option>
            </select>
          </div>
          <div class="field" *ngIf="form.get('BlindCarbonCopyDD').value === 'O'">
            <input formControlName="BlindCarbonCopy" type="text" class="form-control" placeholder="- -" />
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</form>
