<!-- <div class="app-popover-overlay"></div> -->
<div class="app-popover-container d-flex flex-column text-dark">
	<div class="app-popover-details-container d-flex flex-row align-items-center">
		<div class="app-popover-pic-container">
			<ngx-avatars
				[src]="googleImageUrl$ | async"
				[name]="name$ | async"
				[round]="true"
				size="50"
			></ngx-avatars>
		</div>
		<div class="app-popover-details d-flex-column">
			<div class="app-popover-name">{{ name$ | async }}</div>
			<div class="app-popover-email">{{ email$ | async }}</div>
		</div>
	</div>
	<div class="pop-up-container">
		<!--GA - Switch Business-->
		<ng-container *appRestrictTo="['GSB']">
			<ng-container *ngIf="isTapLevelUser$ | async">
				<div
					class="app-popover-link d-flex flex-no-wrap align-items-center clickable"
				>
					<a
						class="w-100 h-100 d-flex align-items-center"
						[routerLink]="adminBusinessList"
						id="viewMyBusinessesLink"
					>
						<i class="material-icons mr-2">&#xE0AF;</i> View My Businesses
					</a>
				</div>
			</ng-container>
		</ng-container>

		<!--GA - Access CRM Backend-->
		<ng-container *appRestrictTo="['GACB']">
			<ng-container *ngIf="!isOnTapLevelView && isOnCrmLevelView">
				<div
					class="app-popover-link d-flex flex-no-wrap align-items-center clickable"
				>
					<a
						class="w-100 h-100 d-flex align-items-center"
						[routerLink]="businessBusiness"
						id="viewBusinessLink"
					>
						<i class="material-icons mr-2">&#xE7FD;</i> View Business Account
					</a>
				</div>
			</ng-container>
			<ng-container *ngIf="canViewUserDetails && isOnCrmLevelView">
				<div
					class="app-popover-link d-flex flex-no-wrap align-items-center clickable"
				>
					<a
						class="w-100 h-100 d-flex align-items-center"
						[routerLink]="userDetails"
						id="viewUserDetailsLink"
					>
						<i class="far fa-address-book fa-flip-horizontal mr-2"></i> User
						Details
					</a>
				</div>
			</ng-container>
			<ng-container *ngIf="canManageUsers && isOnCrmLevelView">
				<div
					class="app-popover-link d-flex flex-no-wrap align-items-center clickable"
				>
					<a
						class="w-100 h-100 d-flex align-items-center"
						[routerLink]="manageUsers"
						id="viewManageUsersLink"
					>
						<i class="fas fa-pen mr-2 fa-sm"></i> Manage Users
					</a>
				</div>
			</ng-container>
			<ng-container *ngIf="!isOnTapLevelView && !isOnCrmLevelView">
				<div
					class="app-popover-link d-flex flex-no-wrap align-items-center clickable"
				>
					<a
						class="w-100 h-100 d-flex align-items-center"
						[routerLink]="dashboard"
						id="viewDashboardLink"
					>
						<i class="material-icons mr-2">&#xE7FD;</i> View Dashboard
					</a>
				</div>
			</ng-container>
		</ng-container>

		<div
			class="app-popover-link d-flex flex-no-wrap align-items-center clickable"
		>
			<a
				class="w-100 h-100 d-flex align-items-center clickable"
				(click)="logout()"
				id="logoutLink"
			>
				<i class="material-icons mr-2">&#xE8AC;</i> Logout
			</a>
		</div>
	</div>
</div>
