import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PropertySoldStore, PropertySoldState } from './property-sold.store';

@Injectable()
export class PropertySoldQuery extends QueryEntity<PropertySoldState> {
	properties$ = this.selectAll();
	isLoading$ = this.selectLoading();

	constructor(protected store: PropertySoldStore) {
		super(store);
	}
}
