export class MeetingStats {
	public ActivityType: string;
	public Completed: number;
	public Upcoming: number;
}

export enum MswActivityTypes {
	CcFirstMeeting = 'CC 1st Meeting',
	SelfGenFirstMeeting = 'Self-Gen 1st Meeting',
	EbReviewMeeting = 'EB Review Meeting',
	Total1 = 'Total 1',
	CcSoaMeeting = 'CC SOA Meeting',
	CcSelfGenSoaMeeting = 'Self-Gen SOA Meeting',
	EbSoaMeeting = 'EB SOA Meeting',
	Total2 = 'Total 2',
}
