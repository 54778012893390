import { Component, OnInit } from '@angular/core';
import { SpecialFeaturesSettingsQuery } from '@modules/special-features/state/special-features-settings.query';
import { map } from 'rxjs/operators';
import { UserQuery } from 'src/app/domain/user/user.query';
import { BusinessConfigQuery } from '../../domain/business-config/business-config.query';
import { combineLatest } from 'rxjs';
import { SpecialFeaturesSettingsCode } from '@modules/special-features/state/special-features-settings.model';

@Component({
	selector: 'app-crt-settings',
	templateUrl: './crt-settings.component.html',
	styleUrls: ['./crt-settings.component.scss'],
})
export class CrtSettingsComponent implements OnInit {
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;

	showConfirmationCall$ = this.userSecurityGroup$.pipe(
		map((userGroup) => ['SO', 'SM'].includes(userGroup))
	);

	isConfirmationCallEnabled$ = combineLatest([
		this.specialFeaturesQuery.isConfirmationCallEnabled$,
		this.businessConfigQuery.specialFeatures$,
	]).pipe(
		map(([hasConCallOn, bsp]) => {
			const hasBusinessConCallOn = bsp?.includes(
				SpecialFeaturesSettingsCode.CC
			);
			return hasConCallOn || hasBusinessConCallOn;
		})
	);

	constructor(
		private userQuery: UserQuery,
		private specialFeaturesQuery: SpecialFeaturesSettingsQuery,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit(): void {}
}
