<div class="topup-requirements-header font-weight-bold mb-3">
	<span
		[class.highlight-label-invalid]="
			(topupRequirementList$ | async)?.length === 0
		"
		>Topup Requirements</span
	>
</div>
<ng-container *ngIf="(isLoading$ | async); else showForm">
  <div class="pb-3">
    <app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
  </div>
</ng-container>
<ng-template #showForm>
  <app-topup-requirements-form
    #topupForm
    [topupRequirements]="topupRequirementList$ | async"
    [options$]="options$"
    [addFn$]="add"
    [updateFn$]="update"
    [deleteFn$]="delete"
  ></app-topup-requirements-form>
</ng-template>
<div class="mb-4">
</div>