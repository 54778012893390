import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlBusinessRoutingModule } from './bl-business-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { BlBusinessViewComponent } from './bl-business-view/bl-business-view.component';
import { FormComponent } from './bl-business-view/form/form.component';
import { BlBusinessComponentService } from './bl-business-component.service';

@NgModule({
  imports: [CommonModule, BlBusinessRoutingModule, SharedModule],
  declarations: [BlBusinessViewComponent, FormComponent],
  providers: [BlBusinessComponentService]
})
export class BlBusinessModule {}
