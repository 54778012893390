import { Component, OnInit } from '@angular/core';
import { MortgageSettingsQuery } from '../state/mortgage-settings.query';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html'
})
export class DocumentListComponent implements OnInit {

  documentList$ = this.query.documentList$;

  constructor(
    private query: MortgageSettingsQuery
  ) { }

  ngOnInit(): void {
  }

}
