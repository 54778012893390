<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
    close
  </span>
</button>
<div class="modal-header ">
  <h1 class="modal-title">
    {{ header }}
  </h1>
</div>
<div class="modal-body text-center">
  <label class="pr-2" for="transferModalSelect">Select Document Type</label>
  <span class="custom-dropdown">
    <select id="transferModalSelect" class="modal-select form-control" [(ngModel)]="typeCode">
      <option value="" disabled></option>
      <option *ngFor="let type of types" [value]="type.value">{{ type.display }}</option>
    </select>
  </span>
  <p class="mt-3">{{ message }}</p>
</div>
<div class="modal-footer text-center">
  <button id="uploadButton" type="button" class="tap-btn tap-btn--default mx-2" (click)="upload()" [disabled]="!typeCode">
    Yes
  </button>
  <button id="cancelButton" type="button" class="tap-btn tap-btn--default" (click)="cancel()">Cancel</button>
</div>
