import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {
	sum,
	subtract,
	divide,
	complement,
	either,
	isNil,
	isEmpty,
} from 'ramda';
import { combineLatest, from, Observable, Subject } from 'rxjs';
import {
	concatMap,
	delay,
	distinctUntilChanged,
	filter,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	toArray,
	withLatestFrom,
} from 'rxjs/operators';
import { AdviceProcessSectionCodes } from '../../../../../shared/models/advice-process/advice-process.model';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { PeopleState } from '../../../../../shared/models/client-review-template/people/people.model';
import { numUtil, util } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { RiskAnalysisService } from '../../states/risk-analysis/risk-analysis.service';
import { TpdQuery } from '../../states/risk-analysis/tpd/tpd.query';
import { TpdService } from '../../states/risk-analysis/tpd/tpd.service';
import { calcUtil } from '../calculations/disability-calculations';
import { AssetsLiabilitiesService } from '../../states/assets-liabilities/assets-liabilities.service';
import { convertToAnnual } from '../../income-budget/calculations/annual-conversion';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { RiskAnalysisTPDState } from 'src/app/shared/models/client-review-template/risk-analysis/tpd/tpd.model';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';

@Component({
	selector: 'app-risk-tpd',
	templateUrl: './tpd.component.html',
	styleUrls: ['./tpd.component.scss'],
})
export class TpdComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	form: UntypedFormGroup;
	activePerson: PeopleState;

	people$: Observable<PeopleState[]>;
	activePerson$ = this.rService.activePerson$;
	riskTpd$ = this.tpdQuery.selectLoading();
	isLoading = true;
	isInit = true;
	kiwisaverValue = 0;
	propertyList = null;

	APCRTIID$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTIID');
	APCRTESI$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTESI');
	APCRTE$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTE');
	APCRTR$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTR');
	APCRTLTCR$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTLTCR');
	APCRTYN$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTYN');
	APCRTYNNA$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTYNNA');

	isAdviceProcessEnded$ = this.crtQuery.isAdviceProcessEnded$;

	@ViewChild('tpdTabset') tpdTabset: TabsetComponent;

	get ExpensesCouldStop() {
		return this.form.get('expensesCouldStop') as UntypedFormArray;
	}

	get ExpensesCouldStart() {
		return this.form.get('expensesCouldStart') as UntypedFormArray;
	}

	get IncomeCouldStart() {
		return this.form.get('incomeCouldStart') as UntypedFormArray;
	}

	get PropertiesList() {
		return this.form.get('propertiesList') as UntypedFormArray;
	}

	get AssetsList() {
		return this.form.get('assetsList') as UntypedFormArray;
	}

	get LoansList() {
		return this.form.get('loansList') as UntypedFormArray;
	}

	get RequiredList() {
		return this.form.get('requiredList') as UntypedFormArray;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private crtQuery: ClientReviewTemplateQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private rService: RiskAnalysisService,
		private tpdService: TpdService,
		private tpdQuery: TpdQuery,
		private aLService: AssetsLiabilitiesService,
		private clientReviewTemplateService: ClientReviewTemplateService,
		private cdr: ChangeDetectorRef
	) {
		this.form = this.fb.group({
			sectionCode: [AdviceProcessSectionCodes.TPD],
			adviceProcessId: [null],

			lostAfterTaxIncomePerMonth: [null],
			monthlySurplusShortfallLost: [null],

			expensesCouldStop: this.fb.array([]),
			expensesCouldStart: this.fb.array([]),
			incomeCouldStart: this.fb.array([]),

			monthlySurplusShortfallStopStart: [null],
			propertiesList: this.fb.array([]),
			assetsList: this.fb.array([]),

			loansList: this.fb.array([]),
			monthlySurplusShortfallLoans: [null],
			annualSurplusShortfallStopStart: [null],
			yearsShortfallCovered: [null, Validators.required],

			debtRepayment: [null],
			familyIncome: [null],

			requiredList: this.fb.array([]),
			totalRequired: [null],
			lessKiwiSaverPaidOut: [null],
			lessNetPropertySales: [null],
			lessAssetSales: [null],
			lTPDCoveredRequired: [null],

			parentCRTId: [null],
			cRTId: [null],
		});

		this.people$ = this.rService.getPeopleTabs(false);
		this.activePerson$
			.pipe(
				distinctUntilChanged(),
				withLatestFrom(this.people$),
				tap(([person, people]) => {
					const index = people.findIndex((x) => x?.cRTId === person?.cRTId);
					if (person && this.tpdTabset?.tabs) {
						this.tpdTabset.tabs[index].active = true;
					}
				}),
				tap(([person]) => {
					if (!!person) {
						this.setActivePerson(person);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		combineLatest([this.crtQuery.properties$, this.people$])
			.pipe(
				filter(([properties, people]) => Boolean(properties) && Boolean(people)),
				take(1),
			)
			.subscribe(([, people]) => {
				if (people?.[0]) {
					this.setActivePerson(people?.[0]);
					// this.cdr.detectChanges();
				}
			});
	}

	ngOnInit(): void {
		this.disabledFields();
		this.crtQuery.people$
			.pipe(
				filter((people) => !!people),
				map((people) => people?.filter((p) => !!p.cRTId)),
				tap((people) => {
					if (people && people.length > 0) {
						this.rService.setCurrentPersonIndex(0, true);
					}
				}),
				take(1)
			)
			.subscribe();

		this.tpdQuery
			.selectEntityAction()
			.pipe(
				filter((data) => !!data),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	disabledFields() {
		this.form.get('lostAfterTaxIncomePerMonth').disable();
		this.form.get('monthlySurplusShortfallLost').disable();
		this.form.get('monthlySurplusShortfallStopStart').disable();
		this.form.get('monthlySurplusShortfallLoans').disable();
		this.form.get('annualSurplusShortfallStopStart').disable();
		this.form.get('debtRepayment').disable();
		this.form.get('familyIncome').disable();
		this.form.get('totalRequired').disable();
		this.form.get('lessKiwiSaverPaidOut').disable();
		this.form.get('lessNetPropertySales').disable();
		this.form.get('lessAssetSales').disable();
		this.form.get('lTPDCoveredRequired').disable();
	}

	getProperties(formData?: RiskAnalysisTPDState) {
		return this.crtQuery.properties$.pipe(
			filter((data) => !!data),
			concatMap((data) =>
				from(data).pipe(
					mergeMap((item) =>
						this.aLService
							.getInfoByCRT(item.cRTId)
							.pipe(map((detail) => ({ ...item, ...detail })))
					),
					toArray()
				)
			),
			tap((data) => (this.propertyList = data)),
			map(() => formData),
			take(1)
		);
	}

	prepareData() {
		this.kiwisaverValue = 0;
		this.form.reset();
		this.clearFormArray(this.ExpensesCouldStop);
		this.clearFormArray(this.ExpensesCouldStart);
		this.clearFormArray(this.IncomeCouldStart);
		this.clearFormArray(this.RequiredList);
		this.clearFormArray(this.PropertiesList);
		this.clearFormArray(this.AssetsList);
		this.clearFormArray(this.LoansList);

		combineLatest([
			this.tpdQuery.selectEntity(this.activePerson?.cRTId),
			this.riskTpd$,
		])
			.pipe(
				filter(([, tpd]) => !tpd),
				map(([data]) => data),
				map((x) => {
					const expensesCouldStop = x?.expensesCouldStop ?? [];
					const expensesCouldStart = x?.expensesCouldStart ?? [];
					const incomeCouldStart = x?.incomeCouldStart ?? [];
					const requiredList = x?.requiredList ?? [];

					if (x) {
						return {
							...x,
							expensesCouldStop,
							expensesCouldStart,
							incomeCouldStart,
							requiredList,
						};
					} else {
						return x;
					}
				}),
				tap(() => (this.isLoading = true)),
				concatMap((data) => this.getProperties(data)),
				tap((x) => {
					this.populateExpenseIncome(x);
					this.populatePropertyAsset(x);
					this.kiwisaverValue = +this.rService.getKiwisaverPaidOut(
						+this.activePerson?.cRTId
					);
					const { loansList, propertiesList, assetsList, ...others } = x || {};

					const newData: Partial<RiskAnalysisTPDState> = {
						...others,
						propertiesList: this.PropertiesList.getRawValue(),
						assetsList: this.AssetsList.getRawValue(),
						loansList: this.LoansList.getRawValue(),
					};

					this.form.patchValue({
						...others,
						...this.getComputations(newData),
						sectionCode: AdviceProcessSectionCodes.TPD,
						parentCRTId: this.activePerson?.cRTId ?? null,
						adviceProcessId: this.crtQuery.getValue().adviceProcessId ?? null,
					});

					this.form.get('lessKiwiSaverPaidOut').setValue(this.kiwisaverValue);

					if (this.form.get('cRTId').value) {
						this.onChange();
					}
				}),
				tap(() => (this.isLoading = false)),
				tap(() => this.form.markAllAsTouched()),
				withLatestFrom(this.crtQuery.isAdviceProcessEnded$),
				tap(([, apEnded]) => {
					if (apEnded) {
						this.form.disable();
					}
				}),
				map(([x]) => x),
				take(1)
			)
			.subscribe();
	}

	populateExpenseIncome(riskAnalysisTPD: RiskAnalysisTPDState) {
		this.clearFormArray(this.ExpensesCouldStop);
		this.clearFormArray(this.ExpensesCouldStart);
		this.clearFormArray(this.IncomeCouldStart);
		this.clearFormArray(this.RequiredList);
		if (riskAnalysisTPD) {
			(riskAnalysisTPD.expensesCouldStop.length > 0
				? riskAnalysisTPD.expensesCouldStop
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) => this.ExpensesCouldStop?.push(this.pushArrayList(l)));
			(riskAnalysisTPD.expensesCouldStart.length > 0
				? riskAnalysisTPD.expensesCouldStart
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) => this.ExpensesCouldStart?.push(this.pushArrayList(l)));
			(riskAnalysisTPD.incomeCouldStart.length > 0
				? riskAnalysisTPD.incomeCouldStart
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) => this.IncomeCouldStart?.push(this.pushArrayList(l)));
			(riskAnalysisTPD.requiredList.length > 0
				? riskAnalysisTPD.requiredList
				: [{ dropdownValue: '', dropdown: '', value: '' }]
			)?.forEach((l) => this.RequiredList?.push(this.pushArrayList(l)));
		} else {
			this.ExpensesCouldStop?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.ExpensesCouldStart?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.IncomeCouldStart?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.RequiredList?.push(
				this.pushArrayList({
					dropdownValue: null,
					dropdown: null,
					value: 0,
				})
			);
		}
	}

	populatePropertyAsset(data: RiskAnalysisTPDState) {
		const getProperty = (val) => {
			if (data) {
				const res = (data.propertiesList ?? [])?.find((x) => x.cRTId === val);
				return res?.sell || 'No';
			}
			return '';
		};

		const getAsset = (val) => {
			if (data) {
				const res = (data.assetsList ?? [])?.find((x) => x.cRTId === val);
				return res?.sell || 'No';
			}
			return '';
		};

		// Both Mortgage and Liabilities are saved under Loans list

		const getMortgage = (val) => {
			if (data) {
				const res = (data.loansList ?? [])?.find((x) => x.cRTId === val);
				return res?.repay || '';
			}
			return '';
		};

		const getLiability = (val) => {
			if (data) {
				const res = (data.loansList ?? [])?.find((x) => x.cRTId === val);
				return res?.repay || '';
			}
			return '';
		};

		this.clearFormArray(this.PropertiesList);
		const query = this.crtQuery.getValue();
		(query?.properties ?? [])?.forEach((property) => {
			const propertyForm = this.fb.group({
				property: [
					{
						value: property.propertyAddress || 0,
						disabled: true,
					},
				],
				value: [
					{
						value: property.propertyValue || 0,
						disabled: true,
					},
				],
				sell: getProperty(property.cRTId),
				cRTId: property.cRTId,
				customerServiceId: property.customerServiceID,
			});
			this.PropertiesList?.push(propertyForm);
		});

		this.clearFormArray(this.AssetsList);
		(query?.assets ?? []).forEach((asset) => {
			const assetForm = this.fb.group({
				asset: [
					{
						value: asset.asset || 0,
						disabled: true,
					},
				],
				value: [
					{
						value: asset.value || 0,
						disabled: true,
					},
				],
				sell: getAsset(asset.cRTId),
				cRTId: asset.cRTId,
			});
			this.AssetsList?.push(assetForm);
		});

		this.clearFormArray(this.LoansList);
		(query?.mortgages ?? [])?.forEach((mortgage) => {
			if (mortgage.cRTId) {
				const loanForm = this.fb.group({
					lender: [
						{
							value: mortgage.lender || 0,
							disabled: true,
						},
					],
					value: [
						{
							value: mortgage.loanValue || 0,
							disabled: true,
						},
					],
					loanRepayment: mortgage?.loanRepayment,
					repay: [getMortgage(mortgage.cRTId), Validators.required],
					cRTId: mortgage.cRTId,
				});
				this.LoansList?.push(loanForm);
			}
		});

		(query?.liabilities ?? [])?.forEach((liability) => {
			const liabilityForm = this.fb.group({
				lender: [
					{
						value: liability?.liability || 0,
						disabled: true,
					},
				],
				value: [
					{
						value: liability?.loanBalance || 0,
						disabled: true,
					},
				],
				loanRepayment: liability?.loanRepayment,
				repay: [getLiability(liability.cRTId), Validators.required],
				cRTId: liability.cRTId,
			});
			this.LoansList?.push(liabilityForm);
		});
	}

	computeNetRental(data) {
		const rentalIncome = convertToAnnual(
			+data.rentalIncome || 0,
			data.rentalIncomeFrequency
		);
		const rate = convertToAnnual(+data.rates || 0, data.ratesFrequency);
		const other = convertToAnnual(
			+data.otherExpense || 0,
			data.otherExpenseFrequency
		);
		const insurance = convertToAnnual(
			+data.insurance || 0,
			data.insuranceFrequency
		);
		const sumROI = sum([rate, other, insurance]);
		const totalNetRental = +numUtil.formatToNumCurrency(
			subtract(rentalIncome, subtract(sumROI, 0))
		);
		return divide(totalNetRental, 12);
	}

	getComputations(data: Partial<RiskAnalysisTPDState>) {
		const id = +this.activePerson?.cRTId;
		const lostAfterTaxIncomePerMonth =
			+this.rService.getLostAfterTaxIncomePerMonth(id);
		const monthlySurplusShortfallLost =
			+this.rService.getMonthlySurplusOrShortfallLost(id);

		let monthlySurplusShortfallStopStart = calcUtil.incomeAddArray(
			monthlySurplusShortfallLost,
			this.ExpensesCouldStop.getRawValue(),
			this.IncomeCouldStart.getRawValue()
		);

		monthlySurplusShortfallStopStart = calcUtil.incomeLessArray(
			monthlySurplusShortfallStopStart,
			this.ExpensesCouldStart.getRawValue()
		);

		const assetsValue = +this.rService.formatCurrency(
			data?.assetsList
				?.filter(({ sell }) => sell === 'Yes')
				?.reduce((sum, asset) => sum + +asset.value, 0)
		);

		const propertiesValue = +this.rService.formatCurrency(
			data?.propertiesList
				?.filter(({ sell }) => sell === 'Yes')
				?.reduce((sum, property) => sum + +property.value, 0)
		);

		const isPropertyInvestment = (prop) => {
			const property = (this.propertyList ?? []).find(
				(x) =>
					x?.propertyAddress === prop?.property &&
					x?.propertyUse === 'Investment'
			);

			if (complement(either(isNil, isEmpty))(property)) {
				return this.computeNetRental(property) || 0;
			}
			return 0;
		};

		const monthlyNetRental = +this.rService.formatCurrency(
			data?.propertiesList
				?.filter((p) => p?.sell === 'Yes')
				?.reduce((sum, p) => sum + +(isPropertyInvestment(p) || 0), 0)
		);
		const loans = this.LoansList.getRawValue() || [];

		const loansValue = this.rService.getTotalLoanValue(
			loans,
			this.crtQuery.getValue().mortgages,
			this.crtQuery.getValue().liabilities
		);

		const debtRepayment = +this.rService.formatCurrency(
			loans
				?.filter(({ repay }) => repay)
				?.reduce(
					(sum, loan) => sum + (+loan.value * parseFloat(loan.repay)) / 100,
					0
				)
		);

		const monthlySurplusShortfallLoans = +this.rService.formatCurrency(
			+monthlySurplusShortfallStopStart + loansValue - +monthlyNetRental
		);
		const annualSurplusShortfallStopStart = +this.rService.formatCurrency(
			monthlySurplusShortfallLoans * 12
		);

		const lessKiwiSaverPaidOut = this.kiwisaverValue;
		const lessNetPropertySales = propertiesValue || 0;
		const lessAssetSales = assetsValue || 0;
		const yearsShortfallCovered = data?.yearsShortfallCovered || 0;

		let totalExpense = 0;
		if (data?.requiredList) {
			totalExpense += calcUtil.incomeLessArray(0, data.requiredList) * -1;
		}

		const familyIncome = +this.rService.getFamilyIncome(
			+yearsShortfallCovered,
			+annualSurplusShortfallStopStart
		);

		const totalRequired = +this.rService.formatCurrency(
			debtRepayment + familyIncome + totalExpense
		);
		const lTPDCoveredRequired = +this.rService.getCoverRequired(
			+totalRequired,
			+propertiesValue,
			+assetsValue,
			+this.kiwisaverValue
		);

		return {
			lostAfterTaxIncomePerMonth,
			monthlySurplusShortfallLost,
			monthlySurplusShortfallStopStart,
			lessKiwiSaverPaidOut,
			monthlySurplusShortfallLoans,
			annualSurplusShortfallStopStart,
			lessNetPropertySales,
			lessAssetSales,
			familyIncome,
			totalRequired,
			debtRepayment,
			lTPDCoveredRequired,
		};
	}

	onChange() {
		this.setFormValueWithExpense();
	}

	onChangeOther(event: KeyboardEvent, item: UntypedFormGroup) {
		const value = (event.target as HTMLInputElement).value;
		if (!value) {
			item.get('dropdown').setValue('');
		}
		this.setFormValueWithExpense();
	}

	setFormValueWithExpense() {
		const formValue = this.form.getRawValue();
		if (formValue.expensesCouldStart?.some((z) => !z.dropdown && !z.value)) {
			const expensesCouldStartList = util.removeEmptyObjsFromArr(
				formValue.expensesCouldStart
			);
			formValue.expensesCouldStart = isEmpty(expensesCouldStartList)
				? null
				: expensesCouldStartList;
		}
		if (formValue.expensesCouldStop?.some((z) => !z.dropdown && !z.value)) {
			const expensesCouldStopList = util.removeEmptyObjsFromArr(
				formValue.expensesCouldStop
			);
			formValue.expensesCouldStop = isEmpty(expensesCouldStopList)
				? null
				: expensesCouldStopList;
		}
		if (formValue.incomeCouldStart?.some((z) => !z.dropdown && !z.value)) {
			const incomeCouldStartList = util.removeEmptyObjsFromArr(
				formValue.incomeCouldStart
			);
			formValue.incomeCouldStart = isEmpty(incomeCouldStartList)
				? null
				: incomeCouldStartList;
		}
		if (formValue.requiredList?.some((z) => !z.dropdown && !z.value)) {
			const requiredsList = util.removeEmptyObjsFromArr(formValue.requiredList);
			formValue.requiredList = isEmpty(requiredsList) ? null : requiredsList;
		}

		const computations = this.getComputations(formValue);
		this.form.patchValue(computations);

		this.tpdService.setFormValue({
			...formValue,
			...computations,
			sectionCode: AdviceProcessSectionCodes.TPD,
			parentCRTId: this.activePerson?.cRTId ?? null,
			adviceProcessId: this.crtQuery.getValue().adviceProcessId ?? null,
			requireds: formValue.requireds?.map((x) => ({
				...x,
				dropdownValue: x.dropdown === 'Other' ? x.dropdownValue : '',
				value: +x.value,
			})),
		});
	}

	setActive(index: number) {
		this.rService.setCurrentPersonIndex(index, true).pipe(take(1)).subscribe();
	}

	setActivePerson(data: PeopleState) {
		this.activePerson = data;
		this.tpdService.setActiveParentCRTId(data.cRTId);

		this.prepareData();
	}

	pushArrayList(data: {
		dropdownValue?: string;
		dropdown: string;
		value: string | number;
	}) {
		return this.fb.group({
			dropdownValue: [data.dropdownValue],
			dropdown: [data.dropdown],
			value: [data.value],
		});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
	};

	addExpenseStop() {
		this.ExpensesCouldStop?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addIncome() {
		this.IncomeCouldStart?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addExpenseStart() {
		this.ExpensesCouldStart?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addRequired() {
		this.RequiredList?.push(
			this.fb.group({
				dropdownValue: [''],
				dropdown: [''],
				value: [''],
			})
		);
	}

	removeList(formArray: UntypedFormArray, index: number) {
		formArray.removeAt(index);
		this.onChange();
	}

	clear(formArray: UntypedFormArray, index: number) {
		formArray.at(index).get('dropdown').setValue('');
		formArray.at(index).get('value').setValue('');
		this.onChange();
	}

	getFormArrayByIndex(
		formControlName: string,
		index: number,
		property: string
	): AbstractControl {
		return this.form.get(`${formControlName}.${index}.${property}`);
	}

	onClickCheckBoxSell(formControlName: string, index: number, val: string) {
		const prop: AbstractControl = this.getFormArrayByIndex(
			formControlName,
			index,
			'sell'
		);
		const value = prop.value === val ? 'No' : val;

		prop.setValue(value);
		this.onChange();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
