<form [formGroup]="form" [class.submitted]="submitted">
	<div class="form-row align-items-center">
		<div class="col-4 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Company Name
			</label>
		</div>
		<div class="col-8 col-lg-4 mb-2 mb-lg-0 mb-2">
			<input
				id="businessName"
				class="form-control crt-form-control"
				formControlName="businessName"
				type="text"
			/>
		</div>
		<div class="col-4 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Trading Name
			</label>
		</div>
		<div class="col-8 col-lg-4 mb-2">
			<input
				id="tradingName"
				class="form-control crt-form-control"
				formControlName="tradingName"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"> Purpose </label>
		</div>
		<div class="col-8 col-lg-4 mb-2">
			<span class="crt-custom-dropdown w-100">
				<select
					id="purpose"
					formControlName="purpose"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of businessPurpose" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-4 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Primary Contact
			</label>
		</div>
		<div class="col-8 col-lg-4 mb-2">
			<app-client-search-control
				textboxId="primaryContact"
				id="primaryContact"
				class="crt-form-control-field"
				textboxClass="theme-crm-field-disabled"
				formControlName="primaryContact"
				tabindex="12"
			></app-client-search-control>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">Industry</label>
		</div>
		<div class="col-8 col-lg-4 mb-2">
			<input
				id="industry"
				class="form-control crt-form-control"
				formControlName="industry"
				type="text"
			/>
		</div>
		<div class="col-8 col-lg-4 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"
				>Number Of Employees</label
			>
		</div>
		<div class="col-4 col-lg-2 mb-2">
			<input
				id="noOfEmployees"
				class="form-control crt-form-control"
				formControlName="noOfEmployees"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">Company Number</label>
		</div>
		<div class="col-8 col-lg-4 mb-2">
			<input
				id="companyNumber"
				class="form-control crt-form-control"
				formControlName="companyNumber"
				type="text"
			/>
		</div>
	</div>
	<ng-container *ngIf="shareholdersList.length > 0">
		<div class="form-row align-items-center">
			<div class="col-lg-2 text-left mb-2">
				<label for="" class="position-relative font-weight-bold"
					>Shareholders</label
				>
			</div>
			<div class="col-3 col-lg-3 text-left mb-2">
				<label for="" class="position-relative font-weight-bold">Name</label>
			</div>
			<div class="col-3 col-lg-2 text-left mb-2">
				<label for="" class="position-relative font-weight-bold"
					>Shareholding</label
				>
			</div>
			<div class="col-3 col-lg-2 text-left mb-2">
				<label for="" class="position-relative font-weight-bold"
					>Position in Business</label
				>
			</div>
			<div class="col col-lg-2 text-left mb-2">
				<label for="" class="position-relative font-weight-bold"
					>Key Person</label
				>
			</div>
			<div class="col"></div>
		</div>
	</ng-container>

	<div formArrayName="shareholdersList">
		<div
			class="form-row align-items-center"
			*ngFor="let arrayItem of shareholdersList.controls; let i = index"
			[formGroupName]="i"
		>
			<div class="col-lg-2 text-left"></div>
			<div class="col-3 col-lg-3 text-left mb-2">
				<input
					id="shareHolderName_{{ i }}"
					type="text"
					class="form-control crt-form-control"
					formControlName="name"
				/>
			</div>
			<div class="col-3 col-lg-2 text-left percent-icon--right mb-2">
				<input
					id="shareHolding_{{ i }}"
					type="text"
					class="form-control crt-form-control"
					formControlName="shareholding"
				/>
				<i>%</i>
			</div>
			<div class="col-3 col-lg-2 text-left mb-2">
				<input
					id="positionInBusiness_{{ i }}"
					type="text"
					class="form-control crt-form-control"
					formControlName="positionInBusiness"
				/>
			</div>
			<div class="col-2 col-lg-2 text-left mb-2">
				<span class="crt-custom-dropdown w-100">
					<select
						id="keyToBusiness_{{ i }}"
						class="form-control crt-form-control"
						formControlName="keyToBusiness"
					>
						<option *ngFor="let f of agree" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-1 mb-2">
				<button
					id="removeShareholderButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto pt-2"
					(click)="removeShareholder(i)"
				>
					<i class="material-icons md-16"> delete </i>
				</button>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-lg-2 text-left"></div>
		<div class="col-8 col-lg-3 text-left mb-3 mt-1">
			<button
				id="addShareholderItemButton"
				for=""
				class="
					tap-btn tap-btn-outline
					tap-btn--shadow
					px-4
					col-auto
					font-weight-bold
				"
				(click)="addShareholderItem()"
			>
				Add Shareholder +
			</button>
		</div>
		<div class="col-4 col-lg-2 px-0 text-left"></div>
		<div class="col-4 col-lg-3 text-left"></div>
		<div class="col-4 col-lg-2 px-0 text-left"></div>
	</div>
	<ng-container *ngIf="directorsList.controls.length > 0">
		<div class="form-row align-items-center">
			<div class="col-4 col-lg-2 text-left mb-2">
				<label for="" class="position-relative font-weight-bold"
					>Directors</label
				>
			</div>
			<div class="col col-lg-3 text-left mb-2">
				<label for="" class="position-relative font-weight-bold">Name</label>
			</div>
		</div>
	</ng-container>

	<div formArrayName="directorsList">
		<div
			class="form-row align-items-center"
			*ngFor="let arrayItem of directorsList.controls; let i = index"
			[formGroupName]="i"
		>
			<div class="col-4 col-lg-2 text-left"></div>
			<div class="col col-lg-4 text-left mb-2">
				<input
					id="directorName_{{ i }}"
					type="text"
					class="form-control crt-form-control"
					formControlName="name"
				/>
			</div>
			<div class="col-auto col-lg-2 text-left mb-2">
				<button
					id="removeDirectorButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto pt-2"
					(click)="removeDirector(i)"
				>
					<i class="material-icons md-16">delete</i>
				</button>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-4 col-lg-2 text-left"></div>
		<div class="col-8 col-lg-3 text-left mb-3 mt-1">
			<button
				id="addDirectorItemButton"
				for=""
				class="
					tap-btn tap-btn-outline
					tap-btn--shadow
					px-4
					col-auto
					font-weight-bold
				"
				(click)="addDirectorItem()"
			>
				Add Director +
			</button>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Do you have an accountant?
			</label>
		</div>
		<div class="col-12 col-lg-2 mb-2">
			<span class="crt-custom-dropdown w-100">
				<select
					id="hasAccountant"
					class="form-control crt-form-control"
					formControlName="hasAccountant"
				>
					<option *ngFor="let f of agree" [value]="f.value">
						{{ f.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"
				>Accountant Name</label
			>
		</div>
		<div class="col-12 col-lg-6 mb-2">
			<input
				id="accountantName"
				class="form-control crt-form-control"
				formControlName="accountantName"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-4"></div>
		<div class="col-12 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold"
				>Business Name</label
			>
		</div>
		<div class="col-12 col-lg-6 mb-2">
			<input
				id="businessAccountName"
				class="form-control crt-form-control"
				formControlName="businessAccountName"
				type="text"
			/>
		</div>
	</div>

	<div class="form-row align-items-center">
		<div class="col-12">
			<label for="" class="position-relative font-weight-bold"
				>Notes</label
			>
			<div formGroupName="notes">
				<textarea
					class="crt-form-control form-control"
					formControlName="notes"
					rows="4"
					id="notes"
				></textarea>
			</div>
		</div>
	</div>

	<div class="form-buttons">
		<button
			id="saveButton"
			type="button"
			class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
			(click)="save()"
			[disabled]="loading"
		>
			Save
			<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
		</button>
		<button
			id="cancelButtont"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancelClick()"
			[disabled]="loading"
		>
			Cancel
		</button>
	</div>
</form>
