import { MortgageState } from '../../models/services/mortgage/mortgage-group.model';
import produce from 'immer';
import {
	subGroupSorter,
	mortgageDateSort,
	mainGroupSorter,
} from './mortgage-sorter.util';

export const isArrayEqual = (a: string, b: string) => {
	const splitA = a ? a?.split(/[-]/) : [];
	const splitb = b ? b?.split(/[-]/) : [];
	if (splitA.length !== splitb.length) {
		return false;
	} else {
		for (let i = 0; i < splitA.length; i++) {
			return splitb?.includes(splitA[i]);
		}
	}
	return false;
};

export const isEqualBorrowingEntities = (a: string, b: string): boolean => {
	const aParsed = a ? JSON.parse(a) : [];
	const bParsed = b ? JSON.parse(b) : [];
	if (aParsed.length !== bParsed.length) {
		return false;
	} else {
		for (let i = 0; i < aParsed.length; i++) {
			return bParsed?.includes(aParsed[i]);
		}
	}

	return false;
};

export const mortgageColorStatus = (status: string) => {
	const green = ['current mortgage', 'drawn down/settled'];
	const yellow = [
		'mortgage enquiry',
		'awaiting application',
		'application received',
		'loan application submitted',
		'information required',
		'pre-approved loo',
		'conditional loo',
		'unconditional loo',
		'offer accepted',
		'loan instructions sent',
		'ready for settlement',
	];
	const red = ['discharged'];
	const grey = ['not proceeding'];

	const stat = status ? status?.toLowerCase() : '';

	if (green?.includes(stat)) {
		return 'green';
	}

	if (yellow?.includes(stat)) {
		return 'yellow';
	}

	if (red?.includes(stat)) {
		return 'red';
	}

	if (grey?.includes(stat)) {
		return 'grey';
	}

	return 'dark';
};

export const mortgageSortServiceUtil = (state: MortgageState) => {
	return produce(state, (draft) => {
		let totalCount = 0;
		draft.mortgages?.forEach((mortgage) => {
			mortgage.totalLending = 0;
			const services = mortgage.customerServices?.sort(subGroupSorter);
			const dates = [...mortgage.customerServices]?.sort(mortgageDateSort);
			mortgage.customerServices = services;
			mortgage.color = mortgageColorStatus(services[0].status);
			mortgage.status = services[0].status;

			for (let i = 0; i < services.length; i++) {
				// tslint:disable-next-line: max-line-length
				if (
					services[i].isActive === 1 &&
					(services[i].status === 'Current Mortgage' ||
						services[i].status === 'Drawn Down/Settled')
				) {
					totalCount = totalCount + +services[i].loanValue;
					mortgage.totalLending =
						mortgage.totalLending + +services[i].loanValue;
				}
			}

			for (let i = 0; i < dates.length; i++) {
				if (dates[i].fixedPeriodEndDate) {
					return (mortgage.fixedUntil = dates[i].fixedPeriodEndDate);
				} else {
					mortgage.fixedUntil = '';
				}
			}
		});

		draft.totaLending = totalCount;
		draft.mortgages?.sort(mainGroupSorter);
	});
};
