<div class="user-detail-documents">
	<ng-container>
		<app-user-onboarding-documents
			[staff]="staff"
			[documents]="onBoardingDocuments"
			[upsertStaffDocumentFn$]="upsertStaffDocumentFn$"
			[removeStaffDocumentFn$]="removeStaffDocumentFn$"
			[downloadDocumentFn$]="downloadDocumentFn$"
			[getUserDocumentsFn$]="getUserDocumentsFn$"
		></app-user-onboarding-documents>
	</ng-container>
	<ng-container>
		<app-user-specific-documents
			[staff]="staff"
			[isTapLevel]="isTapLevel"
			[userSecurityGroup]="userSecurityGroup"
			[documents]="userSpecificDocuments"
			[upsertStaffDocumentFn$]="upsertStaffDocumentFn$"
			[downloadDocumentFn$]="downloadDocumentFn$"
			[getUserDocumentsFn$]="getUserDocumentsFn$"
			[deleteUserDocumentsFn$]="deleteUserDocumentsFn$"
		></app-user-specific-documents>
	</ng-container>
</div>
