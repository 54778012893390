import { StoreConfig, Store,  } from '@datorama/akita';
import produce from 'immer';
import { ClientBirthday } from './cb.model';
import { Injectable } from "@angular/core";

/**
 * L&R Anniversary widget state model
 */
export interface CBState {
  /**
   * list of items in L&R Anniversary list
   */
  ClientBirthdayList: ClientBirthday[];
  /**
   * error message. will show up when not empty
   */
  error: string;
  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): CBState {
  return {
    ClientBirthdayList: [],
    error: '',
    isLoading: false
  };
}

/**
 * L&R Anniversaries widget data store
 */
@Injectable()
@StoreConfig({
  name: 'ClientBirthdays'
})
export class CBStore extends Store<CBState> {
  /**
   * L&R Anniversaries store
   */
  constructor() {
    super(createInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
