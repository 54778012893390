import { Directive, HostListener, ElementRef, Renderer2, Input, OnInit, AfterViewInit} from '@angular/core';

@Directive({
  selector: '[selectPlaceholder]'
})

export class SelectPlaceholderDirective implements OnInit, AfterViewInit {
  defaultValue: any;
	@Input()
	set selectPlaceholder(selectValue: any) {
		this.defaultValue = selectValue?.defaultValue;
	}
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.onChange();
  }

  @HostListener('ngModelChange', ['$event'])
  @HostListener('input', ['$event'])
  @HostListener('change') onChange() {
    const selectElement = this.el.nativeElement;

    // Check if the value is empty
    if(selectElement) {
      if (!selectElement.value || selectElement.value === this.defaultValue) {
        this.renderer.addClass(selectElement, 'select-placeholder');
       
      } else {
        if (selectElement.classList && selectElement.classList.contains('select-placeholder')) {
          // Remove the class when the value is not empty and different from the default value
          this.renderer?.removeClass(selectElement, 'select-placeholder');
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.onChange();
  }
}