<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Emails</h1>
	</div>

	<div class="row tap-container service-tabs data-import">
		<div class="col">
			<tabset class="email-tabset" #tabset>
				<tab
					*appRestrictTo="['BES']"
					heading="Email Template"
					(selectTab)="onSelect($event)"
				>
					<app-email-template></app-email-template>
				</tab>
				<tab
					*appRestrictTo="['BES']"
					heading="Email Settings"
					(selectTab)="onSelect($event)"
				>
					<app-email-settings></app-email-settings>
				</tab>
				<tab
					*appRestrictTo="['BET']"
					heading="Email Status"
					(selectTab)="onSelect($event)"
				>
					<app-email-status [activeRow]="activeRow"></app-email-status>
				</tab>
				<tab
					*appRestrictTo="['BEH']"
					heading="Email History"
					(selectTab)="onSelect($event)"
				>
					<app-email-history
						[transferToStatus]="transferToStatus"
					></app-email-history>
				</tab>
				<tab *appRestrictTo="['BSTE']" heading="Send Test Email">
					<app-send-test-email></app-send-test-email>
				</tab>
				<tab *appRestrictTo="['BSME']" heading="Send Manual Email">
					<app-send-manual-email></app-send-manual-email>
				</tab>
				<tab *appRestrictTo="['BBES']" heading="Business Email Settings">
					<app-business-email-settings></app-business-email-settings>
				</tab>
				<tab *appRestrictTo="['BES']" heading="Email Signature">
					<app-email-signature></app-email-signature>
				</tab>
			</tabset>
		</div>
	</div>
</div>
