import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { combineLatest, EMPTY, of } from 'rxjs';
import { ApiService } from '@core/base/api.service';
import { DisclosureStore } from './disclosure.store';
import { DisclosureQuery } from './disclosure.query';
import { CrtKiwiSaverQuery } from '../../state/crt-kiwisaver.query';
import { DisclosureDocumentState, DisclosureState } from './disclosure.model';
import { catchError, map, take, tap } from 'rxjs/operators';
import { objectUtil } from '@util/util';
import { DocumentModelState } from '@shared/models/documents/document.model';
import { Sidebar, SidebarStatus } from '@modules/crm/crt-page/_shared/models/sidebar.model';
import {
	AdviceProcessPageCodes,
	KiwiSaverAdviceProcessPageIds,
} from '@shared/models/advice-process/advice-process.model';
import { CrtKiwiSaverService } from '../../state/crt-kiwisaver.service';
import { complement, either, isEmpty, isNil } from 'ramda';

export const disclosurePdfOptions = {
	FileName: 'DISCLOSURE-DOCUMENT',
	DPI: '120',
};
@Injectable()
export class DisclosureService {
	// Disclosure Settings
	disclosureSettings$ = this.query.disclosureSettings$;
	// KOAT > Disclosure
	disclosure$ = this.query.activeDisclosure$;
	crtDisclosureLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		protected store: DisclosureStore,
		protected query: DisclosureQuery,
		protected koatQuery: CrtKiwiSaverQuery,
		protected koatService: CrtKiwiSaverService
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getDdPdfOptions() {
		return disclosurePdfOptions;
	}

	getDisclosureSettings(forceRefetch: boolean = false) {
		const adviserId = +this.koatQuery.getValue().adviceProcess?.adviser || 0;
		const endpoint = `staff/${adviserId}/settings/DD`;

		return forceRefetch || isNil(this.query.getValue().settings)
			? this.api.get<DisclosureDocumentState>(endpoint).pipe(
					map((data) =>
						complement(either(isNil, isEmpty))(data)
							? objectUtil.mapPascalCaseToCamelCase(data)
							: null
					),
					tap((data) =>
						applyTransaction(() => {
							this.store.setDisclosureSettings(data);
						})
					),
					catchError(() => of(undefined))
			  )
			: of(this.query.getValue().settings);
	}

	getDisclosureDocument(adviceProcessId: number) {
		const endpoint = `crt/${adviceProcessId}/DD`;
		return this.api.get<DisclosureState[]>(endpoint).pipe(
			map((data) =>
				!!data
					? (data.map(objectUtil.mapPascalCaseToCamelCase) as DisclosureState[])
					: []
			),
			tap((data) =>
				applyTransaction(() => {
					this.store.set(data);
					if (data?.length > 0) {
						this.store.setActive(data[0]?.cRTId);
					}
				})
			),
			catchError(() => of(undefined))
		);
	}

	addNewDisclosure(data) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.add({ ...data, cRTId: +x });
					this.store.setActive(+x);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateDisclosure(data) {
		const endpoint = `crt/${data.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<any>(endpoint, body).pipe(
			tap(() => {
				applyTransaction(() => {
					this.store.upsert(+data?.cRTId, data);
					this.store.setActive(+data?.cRTId);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getDisclosureDocumentFile(id: number) {
		return this.api
			.get<DocumentModelState>(`documents/${id}`)
			.pipe(catchError(() => EMPTY));
	}

	getDocumentFromURL(url: string) {
		const dateTime = new Date().getTime();
		url = `${url}?${dateTime}`;
		return this.api.getExternalResource(url, { responseType: 'text' }).pipe(
			catchError(() => EMPTY),
			take(1)
		);
	}

	setTabColor() {
		return combineLatest([
			this.koatQuery.adviceProcess$,
			this.koatQuery.pageStarted$,
			this.koatQuery.pageCompleted$,
		]).pipe(
			tap(([ap, pageStarted, pageCompleted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (pageStarted?.includes(AdviceProcessPageCodes.Disclosure)) {
					status = SidebarStatus.Incomplete;
					warning = 'Document must be saved to file';
				}

				if (
					!!ap?.documents &&
					ap?.documents?.length > 0 &&
					ap?.documents?.some(
						(x) => x?.field === 'Disclosure Document' && !!x?.value
					)
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.koatService.setSideSidebarStatus(
					KiwiSaverAdviceProcessPageIds.Disclosure,
					false,
					status,
					warning
				);
			})
		);
	}

	disabledTab(sidebar: Sidebar[]): boolean {
		const appIndex =
			sidebar?.findIndex(
				(x) => x?.id === KiwiSaverAdviceProcessPageIds.Disclosure
			) || 0;

		if (appIndex <= 0) {
			return false;
		}

		const disabledTab = [...sidebar]
			?.splice(0, appIndex)
			?.map((x) => x?.status === 2)
			?.includes(false);

		return disabledTab;
	}
}
