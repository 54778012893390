import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { AnnualApiStatsStore } from './aas.store';
import { AnnualApiStat } from './aas.model';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';


@Injectable()
export class AnnualApiStatsService {

  private readonly endpoint = 'widgets?type=AAS';

  constructor(
    private api: ApiService,
    private store: AnnualApiStatsStore
  ) { }

  /**
   * Fetch and store Annual Api Stats data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetAnnualApiStats(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post<AnnualApiStat>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.AAStats = {
          Submitted: res.Submitted,
          New: res.New,
          Lost: res.Lost,
          NetNew: res.NetNew,
          APICompletionRate: res.APICompletionRate,
          APPCompletionRate: res.APPCompletionRate
        };
      })),
      err => applyTransaction(() => {
        this.store.setError('Error fetching data!');
        this.store.setLoading(false);
      }),
      () => this.store.setLoading(false)
    );
  }
}
