import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
} from '@angular/router';
import { resetStores } from '@datorama/akita';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { BusinessPageStore } from 'src/app/modules/business-page/state/business-page.store';
import { UserStore } from '../../domain/user/user.store';
import { ConfigService } from '../config/config.service';
import { UserService } from '@domain/user/user.service';
import { UserQuery } from '@domain/user/user.query';
import { environment } from '@environment';
import { datadogRum } from '@datadog/browser-rum';

@Injectable()
export class BusinessSetterGuard implements CanActivate {
	constructor(
		private configService: ConfigService,
		private userQuery: UserQuery
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		resetStores({
			exclude: [UserStore.storeName, BusinessPageStore.storeName],
		});
		const bCode = route.paramMap.get('companyCode');
		if (environment.production) {
			const user = this.userQuery?.getValue();
			datadogRum.setUser({
				id: user?.StaffID?.toString() || '',
				name: user?.FirstName + ' ' + user?.LastName || '',
				email: user?.EmailAddress || '',
				role: user?.SecurityGroup || '',
				business_name: bCode ?? '',
			});
		}
		return this.configService.SetCompanyCode(bCode).pipe(mapTo(true));
	}
}
