<span class="tap-required" *ngIf="isEditing && !field.valid">*</span>
<div #longText class="field" [class.has-controls]="hasControls">
  <div *ngIf="!isEditing && hasControls; else fieldTemplate;">
    {{ getBooleanValue(value) ? 'YES' : 'NO' }}
  </div>
</div>

<ng-container *ngIf="!restrict && hasControls; else hasRestriction;">
  <div class="control" *ngIf="isEditable">
    <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()" id="{{fieldId}}EditBtn_{{index}}">
      <i class="material-icons md-16 dark">&#xE3C9;</i>
    </button>
  </div>
</ng-container>

<ng-template #hasRestriction>
  <ng-container *appRestrictTo="restrict">
    <div class="control">
      <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()" id="{{fieldId}}EditBtn_{{index}}">
        <i class="material-icons md-16 dark">&#xE3C9;</i>
      </button>
    </div>
  </ng-container>
</ng-template>

<div>
  <app-manual-loader-mini *ngIf="isLoading" [isLoading]="isLoading" class="loader-mini"></app-manual-loader-mini>
</div>

<div class="edit-controls d-flex flex-column" *ngIf="isEditing && !isLoading">
  <button type="submit" class="icon-btn-bg btn-save" (click)="save()" id="{{fieldId}}SaveBtn_{{index}}">
    <i class="material-icons md-16 dark">&#xE161;</i>
  </button>
  <button type="button" class="icon-btn-bg btn-cancel" (click)="cancel()" id="{{fieldId}}CancelBtn_{{index}}">
    <i class="material-icons md-16 dark">&#xE5C9;</i>
  </button>
</div>

<ng-template #fieldTemplate>
  <input type="checkbox" class="chckbox form-control theme-crm-field-disabled"
    [formControl]="field"
    [attr.disabled]="disabled ? true : null"
    id="{{fieldId}}RowId_{{index}}"
    (ngModelChange)="onChangeModel($event)">
</ng-template>
