import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { OtherStat } from './os.model';
import { Injectable } from "@angular/core";

export interface OtherStatsState {

  /**
   * Other Stats fields
   */
  OtherStats?: OtherStat;

  /**
   * error message. will show up when not empty
   */
  error: string;

  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): OtherStatsState {
  return {
    OtherStats: null,
    error: '',
    isLoading: false
  };
}


/**
 * Other Stats widget data store
 */
@Injectable()
@StoreConfig({
  name: 'OtherStats'
})
export class OtherStatsStore extends Store<OtherStatsState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Set if Other Statistic is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
