import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { NoteTypes } from '../../models/notes/note.model';

@Component({
	selector: 'app-edit-history',
	templateUrl: './edit-history.component.html',
	styleUrls: ['./edit-history.component.scss']
})
export class EditHistoryComponent implements OnInit, OnChanges {
	@Input() isLead: boolean;
	@Input() notes: any[];
	@Input() deleteFn$: (id: number, isAp?: boolean) => Observable<any>;
	@Input() isLoading: boolean;

	constructor() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.notes) {
			this.notes = changes.notes.currentValue && changes.notes.currentValue.length > 0 ? changes.notes.currentValue : null;
		}
	}

	ngOnInit() { }

	deleteNote$ = note => {
		const isAp = note.activityType === NoteTypes.AdviceProcess ? true : false;
		return this.deleteFn$(note.notesID, isAp);
	};
}
