import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FGGTCState, FGGTCStore } from './fggtc.store';
import { shareReplay } from 'rxjs/operators';

/**
 * Query F&G Goal Track Commercial widget data
 */
@Injectable()
export class FGGTCQuery extends Query<FGGTCState> {
	/**
	 * Goal track data.
	 */
	data$ = this.select((state) => {
		return state.GoalTrack;
	}).pipe(shareReplay(1));
	/**
	 * indicator if widget is loading.
	 */
	isLoading$ = this.select((state) => state.isLoading);
	/**
	 * error message to show.
	 */
	error$ = this.select((state) => state.error);
	/**
	 * Create F&G Goal Track Commercial query
	 */
	constructor(protected store: FGGTCStore) {
		super(store);
	}
}
