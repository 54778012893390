import { Component, OnInit, OnDestroy, Output, EventEmitter, Renderer2, TemplateRef, AfterViewInit, ViewChild } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { MortgageQuery } from '../states/mortgage.query';
import { MortgageService } from '../states/mortgage.service';
import { takeUntil, map, withLatestFrom, first, finalize, take, tap, mergeMap } from 'rxjs/operators';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { UserQuery } from '../../../../../domain/user/user.query';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { MortgageRequest, MortgageRequestModel } from '../mortgage-request.model';
import MomentUtil from '../../../../../util/moment.util';
import { ActivatedRoute } from '@angular/router';
import { strUtil } from '../../../../../util/util';
import { RecursivePartial } from '../../../../../core/util/util.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MortgageUiQuery } from '../states/mortgage-ui.query';
import { request } from '../mortgage.util';
import { MortgageUiStore } from '../states/mortgage-ui.store';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsStatus, ExportsType } from '@modules/exports/state/exports.model';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsService } from '@modules/exports/state/exports.service';
import { omit } from 'ramda';
declare var $: any;

const patchValue: <T>(form: AbstractControl, value: RecursivePartial<T>) => void = (form, value) => {
  form.patchValue(value);
};

@Component({
  selector: 'app-mortgage-form',
  templateUrl: './mortgage-form.component.html',
  styleUrls: ['./mortgage-form.component.scss'],
})
export class MortgageFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() toggleSearch = new EventEmitter<{
    showSearch: boolean;
    height: number;
    width: number;
  }>();

  onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild(PopoverDirective) popover: PopoverDirective;

  exportStatus$ = this.exportsQuery.status$;

  isSearching$ = this.mortgageQuery.uiQuery.isSearching$;
  isExporting$ = this.mortgageQuery.uiQuery.isExporting$;
  columnFromOpen$ = this.mortgageQuery.uiQuery.columnFormPopupOpen$;
  adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

  openColumnPopup = this.mortgageService.togglePopup;

  advisers$: Observable<ViewDisplayValue[]> = this.bLStaffsQuery.MAdviserChoicesOption$
  adviserStatus$: Observable<ViewDisplayValue[]> = of([
    {
      display: 'Inactive',
      value: '0',
    },
    {
      display: 'Active',
      value: '1',
    },
    {
      display: 'Paused',
      value: '2',
    },
  ]);

  MP$: Observable<ViewDisplayValue[]> = this.mortgageService.MP$;
  MS$: Observable<ViewDisplayValue[]> = this.mortgageService.MS$;
  PCLE$: Observable<ViewDisplayValue[]> = this.mortgageService.PCLE$;
  MRS$: Observable<ViewDisplayValue[]> = this.mortgageService.MRS$;
  PCLT$: Observable<ViewDisplayValue[]> = this.mortgageService.PCLT$;

  businessName$: Observable<string> = this.businessConfigQuery.businessName$;
  businessMortgage$ = of([
    { display: 'Yes', value: true },
    { display: 'No', value: false },
  ]);

  isTapLevel$ = this.userQuery.isTapLevel$;
  userInfo$ = this.userQuery.userInfo$;

  // tslint:disable-next-line: no-angle-bracket-type-assertion
  form: UntypedFormGroup = this.fb.group(<{ [key in keyof MortgageRequestModel]: UntypedFormControl }> {
    SelectedAdvisers: this.fb.control([]),
    SelectedAltAdvisers: this.fb.control([]),
    SelectedAdviserStatuses: this.fb.control([]),
    SelectedProviders: this.fb.control([]),

    SelectedStatuses: this.fb.control([]),
    SelectedRefixSatuses: this.fb.control([]),
    SelectedLeadTypes: this.fb.control([]),
    SelectedLeadOrigins: this.fb.control([]),

    NextActivityDateMin: this.fb.control(null),
    NextActivityDateMax: this.fb.control(null),
    LoanDrawdownDateMin: this.fb.control(null),
    LoanDrawdownDateMax: this.fb.control(null),
    FixedPeriodEndDateMin: this.fb.control(null),
    FixedPeriodEndDateMax: this.fb.control(null),
    StartDateMin: this.fb.control(null),
    StartDateMax: this.fb.control(null),

    BusinessMortgage: this.fb.control(null),
  });

  count$ = this.mortgageQuery.count$;
  totalApi$ = this.mortgageQuery.totalApi$;
  showSearch = false;
  isTapLevel: boolean;

  params$ = this.route.paramMap;

  /************** Progress Bar ****************/
  progress = {
    width: '0%',
  };
  index = 0;
  percent = 0;
  count = 0;
  totalCount = 0;
  modalRef: BsModalRef;
  msg = 'Export Progress';
  /************** END Progress Bar *************/

  constructor(
    private fb: UntypedFormBuilder,
    private mortgageQuery: MortgageQuery,
    private mortgageService: MortgageService,
    private userQuery: UserQuery,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private mortgageUiStore: MortgageUiStore,
    private mortgageUiQuery: MortgageUiQuery,
    private businessConfigQuery: BusinessConfigQuery,
    private bLStaffsQuery: BLStaffsQuery,
    private exportsQuery: ExportsQuery,
		private exportsService: ExportsService,
  ) { }

  ngOnInit() {
    this.params$
      .pipe(
        withLatestFrom(this.isTapLevel$, this.userInfo$, this.MS$),
        map(([params, isTapLevel, userinfo, ms]) => {
          this.isTapLevel = isTapLevel;
          const adv = params.get('advisers');
          const stat = params.get('statuses');
          const fixedPeriodStart = params.get('fixedPeriodStart') || '';
          const fixedPeriodEnd = params.get('fixedPeriodEnd') || '';
          if (params && (!!adv || stat)) {
            const req = JSON.parse(JSON.stringify(this.prepareFormValue()));
            this.mortgageService.setSearchForm({
              ...req,
              SelectedAdvisers: !!adv ? adv?.split(',') : [],
              SelectedStatuses: strUtil.stringIsNotEmpty(stat)
                ? stat?.split(',')?.filter(x => ms?.find(y => y.value === x))
                : [],
              FixedPeriodEndDateMin: fixedPeriodStart ? MomentUtil.DateStringToMoment(fixedPeriodStart) : null,
              FixedPeriodEndDateMax: fixedPeriodEnd ? MomentUtil.DateStringToMoment(fixedPeriodEnd) : null,
            });
            return {
              hasStatus: stat ? true : false,
              SelectedAdvisers: strUtil.stringIsNotEmpty(adv) ? adv?.split(',') : [],
              SelectedStatuses: strUtil.stringIsNotEmpty(stat)
                ? stat?.split(',')?.filter(x => ms?.find(y => y.value === x))
                : [],
              FixedPeriodEndDateMin: MomentUtil.DateStringToMoment(fixedPeriodStart),
              FixedPeriodEndDateMax: MomentUtil.DateStringToMoment(fixedPeriodEnd),
            };
          } else if (!isTapLevel) {
            const policyStatusList = [
              'Mortgage Enquiry',
              'Awaiting Application',
              'Application Received',
              'Loan Application Submitted',
              'Information Required',
              'Conditional LOO',
              'Unconditional LOO',
              'Pre-Approved LOO',
              'Offer Accepted',
              'Loan Instructions Sent',
              'Ready for Settlement',
            ];
            return {
              SelectedStatuses: policyStatusList?.filter(x => ms?.find(y => y.value === x)),
            };
          } else {
            const policyStatusList = [
              'Mortgage Enquiry',
              'Awaiting Application',
              'Application Received',
              'Loan Application Submitted',
              'Information Required',
              'Conditional LOO',
              'Unconditional LOO',
              'Pre-Approved LOO',
              'Offer Accepted',
              'Loan Instructions Sent',
              'Ready for Settlement',
            ];
            return {
              SelectedStatuses: policyStatusList?.filter(x => ms?.find(y => y.value === x)),
            };
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fValue => {
        this.reset();
        if (fValue) {
          patchValue(this.form, fValue);
          if (this.isTapLevel) {
            this.mortgageService?.sort('Status', 'desc');
          } else {
            this.mortgageService?.sort('ClientNextActivity', 'desc');
          }
          if (fValue && fValue.hasStatus) {
            this.search();
          }
        }
      });
  }

  ngAfterViewInit() {
    if (this.mortgageQuery.getValue().count > 0 || this.mortgageQuery.getValue().searchForm) {
      const advisers = this.mortgageQuery.getValue().searchForm.SelectedAdvisers;
      const altAdvisers = this.mortgageQuery.getValue().searchForm.SelectedAltAdvisers;
      const p = this.mortgageQuery.getValue().searchForm.SelectedProviders;
      const lt = this.mortgageQuery.getValue().searchForm.SelectedLeadTypes;
      const ms = this.mortgageQuery.getValue().searchForm.SelectedStatuses;
      const rs = this.mortgageQuery.getValue().searchForm.SelectedRefixSatuses;
      const lo = this.mortgageQuery.getValue().searchForm.SelectedLeadOrigins;
      const as = this.mortgageQuery.getValue().searchForm.SelectedAdviserStatuses;
      const bm = this.mortgageQuery.getValue().searchForm.BusinessMortgage;

      const naMin = this.mortgageQuery.getValue().searchForm.NextActivityDateMin;
      const naMax = this.mortgageQuery.getValue().searchForm.NextActivityDateMax;
      const fpeMin = this.mortgageQuery.getValue().searchForm.FixedPeriodEndDateMin;
      const fpeMax = this.mortgageQuery.getValue().searchForm.FixedPeriodEndDateMax;
      const ldMin = this.mortgageQuery.getValue().searchForm.LoanDrawdownDateMin;
      const ldMax = this.mortgageQuery.getValue().searchForm.LoanDrawdownDateMax;

      this.form.get('SelectedAdvisers').setValue(advisers?.map(x => x?.toString()));
      this.form.get('SelectedAltAdvisers').setValue(altAdvisers?.map(x => x?.toString()));
      this.form.get('SelectedProviders').setValue(p?.map(x => x?.toString()));
      this.form.get('SelectedLeadTypes').setValue(lt?.map(x => x?.toString()));
      this.form.get('SelectedStatuses').setValue(ms?.map(x => x?.toString()));
      this.form.get('SelectedRefixSatuses').setValue(rs?.map(x => x?.toString()));
      this.form.get('SelectedLeadOrigins').setValue(lo?.map(x => x?.toString()));
      this.form.get('SelectedAdviserStatuses').setValue(as?.map(x => x?.toString()));
      this.form.get('BusinessMortgage').setValue(bm);

      this.form.get('NextActivityDateMin').setValue(MomentUtil.formatDateToMoment(naMin));
      this.form.get('NextActivityDateMax').setValue(MomentUtil.formatDateToMoment(naMax));
      this.form.get('FixedPeriodEndDateMin').setValue(MomentUtil.formatDateToMoment(fpeMin, 'YYYY-MM-DD'));
      this.form.get('FixedPeriodEndDateMax').setValue(MomentUtil.formatDateToMoment(fpeMax, 'YYYY-MM-DD'));
      this.form.get('LoanDrawdownDateMin').setValue(MomentUtil.formatDateToMoment(ldMin));
      this.form.get('LoanDrawdownDateMax').setValue(MomentUtil.formatDateToMoment(ldMax));
    }
  }

  public prepareFormValue(): MortgageRequest {
    const formValue: formType = this.form.value;

    const req: MortgageRequest = {
      ...formValue,
      SelectedAdvisers: formValue.SelectedAdvisers.map(x => +x),
      SelectedAltAdvisers: formValue.SelectedAltAdvisers.map(x => +x),
      SelectedProviders: formValue.SelectedProviders.map(x => x),
      SelectedStatuses: formValue.SelectedStatuses.map(x => x),
      SelectedLeadTypes: formValue.SelectedLeadTypes.map(x => x),
      SelectedLeadOrigins: formValue.SelectedLeadOrigins.map(x => x),

      NextActivityDateMin: MomentUtil.formatToServerDate(formValue.NextActivityDateMin),
      NextActivityDateMax: MomentUtil.formatToServerDate(formValue.NextActivityDateMax),
      LoanDrawdownDateMin: MomentUtil.formatToServerDate(formValue.LoanDrawdownDateMin),
      LoanDrawdownDateMax: MomentUtil.formatToServerDate(formValue.LoanDrawdownDateMax),
      FixedPeriodEndDateMin: MomentUtil.formatToServerDate(formValue.FixedPeriodEndDateMin),
      FixedPeriodEndDateMax: MomentUtil.formatToServerDate(formValue.FixedPeriodEndDateMax),
    };
    return req;
  }

  search() {
    const req = JSON.parse(JSON.stringify(this.prepareFormValue()));

    this.mortgageUiQuery.currentSort$
      .pipe(
        first(),
        map(x => {
          req.Paging = {
            Index: 1,
            Column: x.propSort,
            Direction: x.sort,
          };
        }),
        finalize(() => {
          this.mortgageService
            .search(req)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              setTimeout(() => $('datatable-body').scrollTop(1).scrollLeft(1), 1);
              setTimeout(() => $('datatable-body').scrollTop(0).scrollLeft(0), 1);
            });
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  /**
   * Api calls (Endpoint)
   * @returns void
   * Downloads file in .csv format
   */
  //////////////////////////////////// EXPORT /////////////////////////////////
  export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		this.index++;
		const req = request(
			this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
			this.prepareFormValue(),
			this.index
		);
		const newReq = omit(['Paging'], req);

		this.exportsService
			.queueExport(newReq, ExportsType.ME)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(
						this.exportsService.getExportStatus(id)
					)
				)
			)
			.subscribe({
				next: (data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport(data.DocumentLink);
						this.hidePopover();
					}
				},
				error: () => {
					this.hidePopover();
				},
			});

		setTimeout(() => {
			this.popover.hide();
		}, 2500);
  }

	downloadExport(url: string) {
		const name =
			this.route.snapshot.paramMap.get('companyCode') + '-Mortgage.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

  clearExportProgress() {
    this.index = 0;
    this.percent = 0;
    this.progress = {
      width: '0%',
    };
    this.totalCount = 0;
  }
  //////////////////////////////////// END EXPORT /////////////////////////////////

  toggle() {
    this.showSearch = !this.showSearch;
    document.body.style.overflowY = 'hidden';
    let x = 0;
    let data;
    const setIntervalHeight = setInterval(() => {
      if (x < 1 && !data) {
        x += 1;
        data = {
          showSearch: this.showSearch,
          height: $('form').height(),
          width: $('form').width(),
        };
      } else if (x > 0) {
        x += 1;
        if (data.height !== $('form').height()) {
          data.height = $('form').height();
        } else {
          this.toggleSearch.emit(data);
          clearInterval(setIntervalHeight);
        }
      }
    }, 100);
  }

  reset() {
    this.form.get('SelectedAdvisers').reset([]);
    this.form.get('SelectedAltAdvisers').reset([]);
    this.form.get('SelectedAdviserStatuses').reset([]);
    this.form.get('SelectedProviders').reset([]);
    this.form.get('SelectedStatuses').reset([]);
    this.form.get('SelectedRefixSatuses').reset([]);
    this.form.get('SelectedLeadTypes').reset([]);
    this.form.get('SelectedLeadOrigins').reset([]);
    this.form.get('BusinessMortgage').reset([]);

    this.form.get('NextActivityDateMin').reset(null);
    this.form.get('NextActivityDateMax').reset(null);
    this.form.get('LoanDrawdownDateMin').reset(null);
    this.form.get('LoanDrawdownDateMax').reset(null);
    this.form.get('FixedPeriodEndDateMin').reset(null);
    this.form.get('FixedPeriodEndDateMax').reset(null);
  }

  showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}

type formType = { [key in keyof MortgageRequestModel]: any };
