export enum PDGoalsSettingsType {
  PDGoals = 'PDG',
}

export interface PDGoalsSettings {
  SettingsId: number,
  ReferenceId: number,
  Type: string,
  AnnualPersonalDevelopmentHours: number,
}

export interface PDGoalsSettingsState {
  createdByStaffId: number,
  createdByStaffLevel: number
  createdDateTime: string,
  modifiedByStaffId: number,
  modifiedByStaffLevel: number,
  modifiedDateTime: string,
  annualPersonalDevelopmentHours: number,
  referenceId: number,
  settingsId: number
  status: number,
  type: string,
}