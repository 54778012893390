import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../../../core/base/api.service';
import { CrtMortgageQuery } from '../../../../../state/crt-mortgage.query';
import { TopupRequirementsStore } from './topup-requirements.store';
import { TopupRequirementsQuery } from './topup-requirements.query';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	tap,
} from 'rxjs/operators';
import { TopupRequirementDetails, TopupRequirementsDetailsState } from './topup-requirements.model';
import { AdviceProcessSectionCodes } from '../../../../../../../../../shared/models/advice-process/advice-process.model';
import { computeUtil } from '../../../../../../_shared/calculations/funding-required';
import { objectUtil } from '../../../../../../../../../util/util';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { applyTransaction } from '@datorama/akita';


@Injectable()
export class TopupRequirementsService {
	isLoading$ = this.query.selectLoading();
	// TO-DO Add code for dropdown
	topupOptions$ = this.dropdownQuery.orderedChoices$('APCRTTP');
	topupRequirementList$ = this.query.topupRequirementList$;

	constructor(
		private api: ApiService,
		protected store: TopupRequirementsStore,
		protected query: TopupRequirementsQuery,
		protected crtMortgageQuery: CrtMortgageQuery,
		private dropdownQuery: DropdownValueQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

  get(applicationId: number) {
		this.store.setLoading(true);
		return this.api.get<TopupRequirementsDetailsState[]>(`crt/${AdviceProcessSectionCodes.Application}/${applicationId}/sub-section/${AdviceProcessSectionCodes.TopupRequirements}`).pipe(
			map((x) => (x || []).map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => {
				this.store.set(x || []);
				this.calculateTotalTopupRequired();
			}),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data: TopupRequirementDetails) {
		const body = {
			...data,
			SectionCode: AdviceProcessSectionCodes.TopupRequirements,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => {
				this.store.add(x);
				this.calculateTotalTopupRequired();
			}),
			catchError(() => of(undefined))
		);
	}

	update(data: TopupRequirementDetails) {
		const body = {
			...data,
			SectionCode: AdviceProcessSectionCodes.TopupRequirements,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap((x) => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => {
				this.store.upsert(x?.cRTId, x);
				this.calculateTotalTopupRequired();
			}),
			catchError(() => of(undefined))
		);
	}

	delete(data: number) {
		return this.api.delete(`crt/${data}`).pipe(
			tap((x) => {
				this.store.remove(data);
				this.calculateTotalTopupRequired();
			}),
			catchError(() => of(undefined))
		);
	}

	calculateTotalTopupRequired() {
		const total = computeUtil.totalTopupRequired(this.query.getAll());
		this.store.update({ totalTopupRequired: total });
	}
}
