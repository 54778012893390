<form [formGroup]="form" [class.submitted]="submitted">
  <div class="form-row">
    <div class="col-8 col-md-9 m-auto mb-2">
      <app-chips
        id="banks"
        textboxId="banks"
        class="crt-form-control-field"
        textboxClass="theme-crm-field"
        formControlName="banks"
        [choices]="bankList">
      </app-chips>
    </div>
    <div class="col-8 col-md-9 m-auto mb-2 pt-3">
      <div class="form-row">
        <div class="col-6">
          <button
            id="downloadButton"
            type="button"
            class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
            [disabled]="isLoading"
            (click)="download()"
          >
            Download
            <i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
          </button>
        </div>
        <div class="col-6">
          <button
            id="cancelButton"
            type="button"
            class="btn-light btn-block mb-2 mb-lg-0"
            (click)="closeModal()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
