import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { viewSecGroup } from '@modules/user/viewmodels/viewSecGroup';

@Component({
	selector: 'app-provide-access-modal',
	templateUrl: './provide-access-modal.component.html',
	styleUrls: ['./provide-access-modal.component.scss'],
})
export class ProvideAccessModalComponent implements OnInit, OnDestroy {
	private subs: Subscription[] = [];
	header: string;
	securityGroups: viewSecGroup[];
	savefn: (model: Array<string>) => Observable<any>;

	isSaving = false;
	form: UntypedFormGroup = this.fb.group({
		securityGroups: this.fb.array([]),
	});

	constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {}

	ngOnInit(): void {}

	onChange(securityGroupCode: string, isChecked: boolean) {
		const securityGroups = this.form.controls.securityGroups as UntypedFormArray;

		if (isChecked) {
			securityGroups.push(new UntypedFormControl(securityGroupCode));
		} else {
			const index = securityGroups.controls?.findIndex(x => x.value === securityGroupCode);
			securityGroups?.removeAt(index);
		}
	}

	save(vm) {
		this.isSaving = true;

		this.subs.push(
			this.savefn(vm.securityGroups).subscribe(
				res => {
					if (res) {
						this.isSaving = false;
					}
					this.decline();
				},
				() => {
					this.isSaving = false;
				}
			)
		);
	}

	decline = () => this.bsModalRef.hide();

	ngOnDestroy() {
		this.subs?.forEach(x => x.unsubscribe());
		this.subs = [];
	}
}
