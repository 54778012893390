import { Moment } from 'moment';
import { Staff, TlAddStaffRequest, TlEditStaffRequest } from '../../../shared/models/_general/staff';
import { util } from '../../../core/util/util.service';

// tslint:disable-next-line: class-name
export class viewStaff {
  StaffID: number;
  FirstName: string;
  LastName: string;
  IsActive: boolean;
  EmailAddress: string;
  SecurityGroup: string;
  StartDate: Moment;
  BirthDate: Moment;
  MobilePhone: string;
  BusinessPhone: string;
  HomeAddress: string;
  Businesses: string[] = [];
  AdviserManager?: number;
  Stats?: string[] = [];
  Services?: string[] = [];

  static mapStaffs(staffs): viewStaff[] {
    return staffs
      ?.map(s => this.mapToViewModel(s))
      ?.sort((a, b) => {
        const mapA = a.FirstName?.concat(' ', a.LastName);
        const mapB = b.FirstName?.concat(' ', b.LastName);
        return mapA?.localeCompare(mapB)
      });
  }

  static mapToViewModel(m: Staff): viewStaff {
    return {
      StaffID: m.StaffID,
      BirthDate: util.DateStringToMomentLoose(m.BirthDate),
      StartDate: util.DateStringToMomentLoose(m.StartDate),
      Businesses: m.Businesses ? JSON.parse(m.Businesses) : null,
      FirstName: m.FirstName,
      LastName: m.LastName,
      BusinessPhone: m.BusinessPhone,
      EmailAddress: m.EmailAddress,
      HomeAddress: m.HomeAddress,
      IsActive: m.IsActive,
      MobilePhone: m.MobilePhone,
      SecurityGroup: m.SecurityGroup
    };
  }
  static mapToTlAddRequest(v: viewStaff): TlAddStaffRequest {
    return {
      BirthDate: util.MomentToDateString(v.BirthDate),
      StartDate: util.MomentToDateString(v.StartDate),
      BusinessPhone: v.BusinessPhone,
      Businesses: v.Businesses ? JSON.stringify(v.Businesses) : null,
      EmailAddress: v.EmailAddress,
      FirstName: v.FirstName,
      LastName: v.LastName,
      HomeAddress: v.HomeAddress,
      IsActive: v.IsActive,
      MobilePhone: v.MobilePhone,
      SecurityGroup: v.SecurityGroup,
      StaffID: v.StaffID
    };
  }
  static mapToTlEditRequest(v: viewStaff): TlEditStaffRequest {
    return {
      BirthDate: util.MomentToDateString(v.BirthDate),
      StartDate: util.MomentToDateString(v.StartDate),
      BusinessPhone: v.BusinessPhone,
      Businesses: v.Businesses ? JSON.stringify(v.Businesses) : null,
      EmailAddress: v.EmailAddress,
      FirstName: v.FirstName,
      LastName: v.LastName,
      HomeAddress: v.HomeAddress,
      IsActive: v.IsActive,
      MobilePhone: v.MobilePhone,
      SecurityGroup: v.SecurityGroup,
      StaffID: v.StaffID
    };
  }
}
