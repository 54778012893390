<div class="row pl-3 pt-4 disclosure-template">
  <div class="col-12 disclosure-template__froala position-relative">
    <app-wysiwyg
      #contentEditor
      [shortcodes]="shortcodes"
      [content]="currentTemplate"
    ></app-wysiwyg>

    <div class="row pl-3">
      <div class="col-12 text-right pr-2">
        <ng-container *ngIf="!isLoading">
          <button
            type="button"
            class="btn btn-primary my-2 mx-1 tap-bg-primary"
            (click)="backToEdit()"
            *ngIf="isPreview"
          >
            BACK TO EDIT
          </button>
          <app-manual-loader-mini [isLoading]="true" *ngIf="!(pCTmergeTagsLoaded$ | async)" class="p-0">
          </app-manual-loader-mini>
          <button
            type="button"
            class="btn btn-primary my-2 mx-1 tap-bg-primary"
            (click)="showPreview()"
            *ngIf="!isPreview"
            [disabled]="!(pCTmergeTagsLoaded$ | async)"
          >
            PREVIEW
          </button>
          <button
            type="button"
            class="btn btn-primary my-2 mx-1 tap-bg-primary"
            (click)="save()"
            *ngIf="!isPreview"
          >
            SAVE
          </button>
        </ng-container>

        <ng-container *ngIf="isLoading">
          <div class="mt-3 mb-3">
            <app-manual-loader-mini
              [isLoading]="isLoading"
            ></app-manual-loader-mini>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
