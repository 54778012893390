import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { EmailStatusService } from './state/email-status.service';

@Injectable()
export class EmailStatusResolver implements Resolve<boolean> {
  constructor(
    private emailStatusService: EmailStatusService) { }

  resolve(
  ): Observable<boolean> {
    const getSettings$ = this.emailStatusService.getSettings();
    this.emailStatusService.clear();
    return forkJoin([getSettings$]).pipe(
      mapTo(true));
  }
}

