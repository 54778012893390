import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { applyTransaction } from '@datorama/akita';

import { complement, either, isNil, isEmpty } from 'ramda';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { ScopeOfServiceState } from '../../../../../shared/models/client-review-template/scope-of-service/scope-of-service.model';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

@Injectable()
export class ScopeOfServiceService
  extends ClientReviewTemplateService
  implements Resolve<boolean>
{
  goals$ = this.query.goals$;
  endpoint = 'crt/risk-analysis/advice-process/';

  scopeOfService$ = this.query.scopeOfService$;
  sosDefault$ = this.query.sosDefault$;

  constructor(
    private api: ApiService,
    protected dropdownValueQuery: DropdownValueQuery,
    protected store: ClientReviewTemplateStore,
    protected query: ClientReviewTemplateQuery,
    protected customerService: CustomerService,
    protected businessService: BusinessService
  ) {
    super(dropdownValueQuery, store, query, customerService, businessService);
  }

  getSosDefault() {
    const endpoint = `crt/settings/0/SOS`;
    return this.api.get<ScopeOfServiceState>(endpoint).pipe(
      map((x) =>
        complement(either(isNil, isEmpty))(x)
          ? objectUtil.mapPascalCaseToCamelCase(x)
          : null
      ),
      tap((data) =>
        applyTransaction(() => {
          this.store.setSosDefault(data);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  getScopeOfService(adviceProcessId: number, settingsCode: string) {
    const endpoint = `crt/${adviceProcessId}/${settingsCode}`;
    return this.api.get<ScopeOfServiceState>(endpoint).pipe(
      tap((data) =>
        applyTransaction(() => {
          const state = complement(either(isNil, isEmpty))(data)
            ? objectUtil.mapPascalCaseToCamelCase(data)[0]
            : {};
          this.store.setScopeOfService(state);
        })
      ),
      catchError(() => of({}))
    );
  }

  addScopeOfService(scopeOfService: ScopeOfServiceState) {
    const endpoint = `crt`;
    const body = objectUtil.mapCamelCaseToPascalCase(scopeOfService);
    return this.api.post3<ScopeOfServiceState>(endpoint, body).pipe(
      tap((x) =>
        applyTransaction(() => {
          this.store.setScopeOfService({ ...scopeOfService, cRTId: +x });
        })
      ),
      catchError(() => EMPTY)
    );
  }

  updateScopeOfService(scopeOfService: ScopeOfServiceState) {
    const endpoint = `crt/${scopeOfService.cRTId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(scopeOfService);
    return this.api.put<ScopeOfServiceState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setScopeOfService(scopeOfService);
        })
      ),
      catchError(() => EMPTY)
    );
  }

  updateScopeOfServiceTemp(
    scopeOfService: ScopeOfServiceState,
    skipHasFormChanges?: boolean
  ) {
    applyTransaction(() => {
      if (!skipHasFormChanges) {
        this.setHasFormChanges(true);
      }
      this.setHasFormChanges(true);
      this.store.setScopeOfService(scopeOfService);
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const adviceProcessId = +route.paramMap.get('adviceProcessId');

    const getScopeOfService$ = isNil(this.query.getValue().scopeOfService)
      ? this.getScopeOfService(adviceProcessId, AdviceProcessSectionCodes.Goals)
      : of(undefined);

    return forkJoin([getScopeOfService$]).pipe(mapTo(true));
  }
}
