import * as R from 'ramda';
import { MonthlyExpenseDetailsState } from './monthly-expense.model';

export class MonthlyExpenseMapper {
	/** Mapping for view */
	public static mapToView(data: MonthlyExpenseDetailsState) {
		const defaultFrequencyValue = 'Monthly';
		return {
			...data,
			rentFrequency: data?.rentFrequency || defaultFrequencyValue,
			pGWValueFrequency: data?.pGWValueFrequency || defaultFrequencyValue,
			pIPValueFrequency: data?.pIPValueFrequency || defaultFrequencyValue,
			hCCValueFrequency: data?.hCCValueFrequency || defaultFrequencyValue,
			lRMValueFrequency: data?.lRMValueFrequency || defaultFrequencyValue,
			carExpensesFrequency: data?.carExpensesFrequency || defaultFrequencyValue,
			homeRatesFrequency: data?.homeRatesFrequency || defaultFrequencyValue,
			petsFrequency: data?.petsFrequency || defaultFrequencyValue,
			schoolFeesFrequency: data?.schoolFeesFrequency || defaultFrequencyValue,
			childCareFrequency: data?.childCareFrequency || defaultFrequencyValue,
			foodClothesFrequency: data?.foodClothesFrequency || defaultFrequencyValue,
			discretionarySpendingValueFrequency:
				data?.discretionarySpendingValueFrequency || defaultFrequencyValue,
			mortgagePayments: +data.mortgagePayments,
			pHCValue: +data.pHCValue,
			totalExpenses: +data.totalExpenses,
			expenseList: data.expenseList || [],
			unallocatedMonthlyIncome: data.unallocatedMonthlyIncome ?? null
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: MonthlyExpenseDetailsState,
		adviceProcessId?: number,
		cid?: number
	): MonthlyExpenseDetailsState {
		return {
			...data,
			rentValue: R.isEmpty(data?.rentValue) ? null : data?.rentValue,
			carExpenses: R.isEmpty(data?.carExpenses) ? null : data?.carExpenses,
			childCare: R.isEmpty(data?.childCare) ? null : data?.childCare,
			foodClothes: R.isEmpty(data?.foodClothes) ? null : data?.foodClothes,
			hCCValue: R.isEmpty(data?.hCCValue) ? null : data?.hCCValue,
			homeRates: R.isEmpty(data?.homeRates) ? null : data?.homeRates,
			lRMValue: R.isEmpty(data?.lRMValue) ? null : data?.lRMValue,
			pGWValue: R.isEmpty(data?.pGWValue) ? null : data?.pGWValue,
			pIPValue: R.isEmpty(data?.pIPValue) ? null : data?.pIPValue,
			pets: R.isEmpty(data?.pets) ? null : data?.pets,
			schoolFees: R.isEmpty(data?.schoolFees) ? null : data?.schoolFees,
			discretionarySpendingValue: R.isEmpty(data?.discretionarySpendingValue)
				? null
				: data?.discretionarySpendingValue,
			expense: data.expenseList ? JSON.stringify(data.expenseList) : null,
			cRTId: cid || null,
			adviceProcessId: adviceProcessId || null,
			sectionCode: 'FIM',
			status: 1,
		};
	}
}
