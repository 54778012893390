import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { SendTestEmailModel } from './send-test-email.model';
import produce from 'immer';
import { Injectable } from "@angular/core";

export interface SendTestEmailState extends EntityState<SendTestEmailModel> {
	columns: string[];
	contentMergeTages: [];
}

@Injectable()
@StoreConfig({ name: 'sendEmail', idKey: 'EmailSettingsId' })
export class SendTestEmailStore extends EntityStore<SendTestEmailState, SendTestEmailModel> {

	constructor() {
		super({
			columns: [],
			contentMergeTages: null
		});
	}

	setColumns(columns: string[]) {
		this.update(produce(x => { x.columns = columns; }));
	}

	setContentMergeTages(e: []) {
		this.update({ contentMergeTages: e });
	}


}
