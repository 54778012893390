import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnInit,
	Output,
	OnDestroy,
	ViewChild,
	HostListener,
} from '@angular/core';
import FroalaEditor from 'froala-editor';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import { Observable, of, Subject } from 'rxjs';
import {
	delay,
	filter,
	map,
	mergeMap,
	takeUntil,
	tap,
	take,
} from 'rxjs/operators';
import MomentUtil from 'src/app/util/moment.util';
import { contentEditable } from '../converter/content-merge-tags';
import {
	button,
	buttonMD,
	buttonSM,
	buttonXS,
	mergeTagButtons,
	simpleEditorButtons,
} from './defaults/toolbar-buttons';
import { options } from './editor.config';
import { FroalaEditorDirective } from 'angular-froala-wysiwyg';
import { loatRiskAnalysisIds } from '@shared/models/client-review-template/merge-tags/crt-lr-insurance/risk-analysis/risk-analysis.merge-tags';
import { KEYCODES, WysiwygMapper } from './wysiwyg.mapper';
import { loatSosPagesIds } from '@shared/models/client-review-template/merge-tags/crt-lr-insurance/scope-of-service/sos.merge-tag';
export const froalaCopyClass = 'fr-copy-froala';
export const isPasted = 'isPasted';
declare var $: any;

@Component({
	selector: 'app-wysiwyg',
	templateUrl: './wysiwyg.component.html',
	styleUrls: ['./wysiwyg.component.scss', './wysiwyg-design-v2.component.scss'],
})
export class WysiwygComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(FroalaEditorDirective) froala: FroalaEditorDirective;

	onDestroy$ = new Subject<void>();
	onClickZoom$ = new Subject();
	@Input() id: string;
	@Input() content: string;
	@Input() readOnly = false;
	@Input() hideMergeTagButtons = false;
	@Input() triggerPrint$: Observable<object>;
	@Input() generatePdf$: Observable<object>;
	@Input() fileName = `File-${MomentUtil.formatToServerDatetime(
		MomentUtil.createMomentNz()
	)}`;
	@Input() shortcodes: object;
	@Input() hasSidebarSections = false;
	@Input() extendOptions: object;
	@Input() simpleEditor = false;
	@Input() isTapLevel = true;
	@Input() allowAllHtmlTagsAttrs = false; // Set to TRUE to enable copy-pasting of ALL HTML elements with no restriction
	@Input() showToolbar = true;
	@Input() designClass = '';
	@Input() undoReset$: Observable<any[]>;

	@Output() onChangeEvent = new EventEmitter<any>();
	@Output() getPDF = new EventEmitter();

	@Input() zoom = 120;
	@Output() zoomEvent = new EventEmitter();
	@Output() undoStack = new EventEmitter<any>();

	/*
	 * this will give us option weather to allow user to press enter (create new line)
	 * if the caret position is inside of the merge tag element (<var class="mtag mtag-orig></var>)
	 * for more info, please see: https://bbtdigital.atlassian.net/browse/TAP1-705
	 */
	@Input() allowNewlineOnMT = true;

	displayZoomValue = 0;

	private optPDF = {
		filename: `${this.fileName}.pdf`,

		margin: [1, 2],
		image: { type: 'jpeg', quality: 0.98 },
		html2canvas: {
			scale: 2,
			// dpi: 192,
			letterRendering: true,
			useCORS: true,
			// ignoreElements: (el: HTMLElement) => el.tagName.toLowerCase() === 'hr',
		},
		jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' },
		pagebreak: {
			mode: 'css',
			avoid: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'tr', 'li', 'p'],
			// before: ['hr', '.generated-pagebreak'],
		},
	};

	// tslint:disable-next-line: ban-types
	options: any = {
		...options,
		events: {
			initialized: this.onInitialized(),
			contentChanged: this.onContentChange(),
			'image.beforeUpload': this.onImageBeforeUpload(),
			'paste.after': this.onContentChange(),
			'paste.afterCleanup': this.cleanPaste,
			'commands.after': this.commands,
			mousedown: this.onMouseDown,
		},
	};

	sections: { id: number; name: string }[];
	editorId: string | number;
	shortcodeButtonId: string;
	isZoomLoading = false;
	isCodeView = false;
	// zoom: number;

	constructor(private ngZone: NgZone) {}

	@HostListener('copy', ['$event']) onCopyEvent(event: ClipboardEvent) {
		const editor = this;
		if (editor?.options?.toolbarButtons.includes('html')) {
			// If in dev mode
			return;
		}
		const range = window.getSelection().getRangeAt(0);
		const rangeContents = range.cloneContents();
		const helper = document.createElement('div');
		const froalaTag = `<span class="${froalaCopyClass}"></span>`;
		helper.appendChild(rangeContents);
		event.clipboardData?.setData(
			'text/plain',
			`${froalaTag} ${helper.innerText}`
		);
		event.clipboardData?.setData(
			'text/html',
			`${froalaTag} ${helper.innerHTML}`
		);
		event.preventDefault();
	}

	ngOnInit(): void {
		this.editorId = this.id ?? Math.floor(Math.random() * 999999);
		this.shortcodeButtonId = `customShortcodes_${this.editorId}`;

		this.setDynamicOptions();
		this.displayZoomValue = this.zoom;
	}

	zoomIn() {
		if (this.zoom === 200) {
			return;
		}
		of(this.zoom)
			.pipe(
				map((x) => x + 10),
				tap((x) => {
					// this.onClickZoom$.next(true);
					this.isZoomLoading = true;
					this.zoom = x;
					$('.fr-view').css('zoom', `calc((${x}/100)*100%)`);
					this.displayZoomValue = x;
					this.zoomEvent.emit(x);
				}),
				delay(200),
				tap(() => (this.isZoomLoading = false)),
				take(1)
			)
			.subscribe();
	}
	zoomOut() {
		if (this.zoom === 70) {
			return;
		}
		of(this.zoom)
			.pipe(
				map((x) => x - 10),
				tap((x) => {
					// this.onClickZoom$.next(true);
					this.isZoomLoading = true;
					this.zoom = x;
					$('.fr-view').css('zoom', `calc((${x}/100)*100%)`);
					this.displayZoomValue = x;
					this.zoomEvent.emit(x);
				}),
				delay(200),
				tap(() => (this.isZoomLoading = false)),
				take(1)
			)
			.subscribe();
	}

	ngAfterViewInit() {
		const self = this;
		this.ngZone.run(() => {
			FroalaEditor.DefineIconTemplate(
				'fontawesome',
				'<i class="fas fa-[NAME]" aria-hidden="true"></i>'
			);

			FroalaEditor.DefineIcon('alert', { SVG_KEY: 'help' });
			FroalaEditor.RegisterCommand('alert', {
				title: 'Giphy',
				icon: 'giphyIcon',
				undo: true,
				popup: true,
				plugin: 'giphyPlugin',
				showOnMobile: true,
				refreshAfterCallback: true,
				callback: function () {
					/* Toggle the giphy button */
					if (!this.popups.isVisible('giphyPlugin.popup')) {
						this.giphyPlugin.showPopup();
						this.$tb.find('input.giphy_search_field').focus();
					} else {
						if (this.$el.find('.fr-marker')) {
							this.events.disableBlur();
							this.selection.restore();
						}
						this.popups.hide('giphyPlugin.popup');
					}
				},
			});

			FroalaEditor.DefineIcon('pageBreak', {
				NAME: 'pageBreak',
				SVG_KEY: 'pageBreaker',
			});
			FroalaEditor.RegisterCommand('pageBreak', {
				title: 'Insert Page Break',
				focus: false,
				undo: true,
				refreshAfterCallback: false,
				callback: function () {
					const item = this.selection.element();
					const parent = $(item).parent();
					if (parent != null) {
						const tagName = $(parent).prop('tagName').toLowerCase();
						if (
							tagName === 'td' ||
							tagName === 'th' ||
							tagName === 'tr' ||
							tagName === 'table'
						) {
							return;
						}
					}
					const breakSection = `<hr
            name='pagebreak'
            class='span.fr-deletable'
            contenteditable='false'
          />`;
					this.html.insert(breakSection);
				},
			});

			// Custom Button - List of Short codes
			if (this.shortcodes) {
				FroalaEditor.DefineIcon(this.shortcodeButtonId, {
					NAME: 'tags',
					SVG_KEY: 'tags',
				});
				FroalaEditor.RegisterCommand(this.shortcodeButtonId, {
					title: 'Meta Tag Shortcodes',
					type: 'dropdown',
					focus: true,
					undo: true,
					icon: this.shortcodeButtonId,
					refreshAfterCallback: true,
					options: this.shortcodes,
					callback: function (cmd, val) {
						this.html.insert(`%${val}%`);
					},
				});
			}

			FroalaEditor.DefineIcon('addFooter', {
				NAME: 'F',
				template: 'text',
			});
			FroalaEditor.RegisterCommand('addFooter', {
				title: 'Add/Remove Footer',
				focus: true,
				undo: false,
				icon: 'addFooter',
				refreshAfterCallback: true,
				callback: function () {
					const content = this.html.get();
					const footerVal = `<p class="tap-froala__footer" ${contentEditable.false} style="position:fixed; bottom:0; width:93%;"><span style="float:left;">%BUSINESS_WEBSITE%</span><span style="float: right; text-align:right;">%BUSINESS_PHONE_NUMBER%</span></p><p><br></p>`;

					if (content?.includes('tap-froala__footer')) {
						const newContent = content.replace(footerVal, '');
						this.html.set(newContent);
					} else {
						this.html.insert(footerVal);
					}
				},
			});

			FroalaEditor.DefineIcon('repeatTableRow', {
				NAME: 'table',
				template: 'fontawesome',
			});
			FroalaEditor.RegisterCommand('repeatTableRow', {
				title: 'Repeat Table Row with Merge Tags',
				focus: false,
				undo: true,
				icon: 'repeatTableRow',
				refreshAfterCallback: true,
				callback: function () {
					let getSelected = this.html.getSelected() || '';
					getSelected = getSelected.replace(/(<p[\s\S]+?>|<\/p>)/g, '');
					this.html.insert(
						`[REPEAT_TABLE_ROW]${getSelected}[/REPEAT_TABLE_ROW]`
					);
				},
			});

			FroalaEditor.DefineIcon('repeatSection', {
				NAME: 'codeView',
				SVG_KEY: 'codeView',
			});
			FroalaEditor.RegisterCommand('repeatSection', {
				title: 'Repeat Section with Merge Tags',
				focus: false,
				undo: true,
				icon: 'repeatSection',
				refreshAfterCallback: true,
				callback: function () {
					let getSelected = this.html.getSelected() || '';
					getSelected = getSelected.replace(/(<p><\/p>)/g, '');
					this.html.insert(`[REPEAT_SECTION]${getSelected}[/REPEAT_SECTION]`);
				},
			});
		});

		this.setZoom();
	}

	private onInitialized() {
		const self = this;
		return function (e) {
			const editor = this;

			if (self.readOnly) {
				editor.edit.off();
			}

			self.setTriggerPrint(editor);
			self.setUndoReset(editor);
			self.initEvents(e._editor);
			// 	self.generateHeaders(editor.el);

			if (self.hasSidebarSections) {
				const sectionHeight = $('.fr-wrapper').height();
				const toolbarHeight = $('.fr-toolbar').height();
				$('.tap-froala__nav')
					.css({ 'margin-top': toolbarHeight })
					.height(sectionHeight);
			}
		};
	}

	private onMouseDown(event) {
		const target = event?.currentTarget;
		const parent = target?.parentElement;
		if (
			parent &&
			(Array.from(target?.classList)?.includes('allow-edit') ||
				target?.contentEditable === 'true' ||
				Array.from(parent?.classList)?.includes('allow-edit') ||
				parent?.contentEditable === 'true')
		) {
			setTimeout(() => {
				Array.from($('.fr-toolbar button'))?.forEach((i) =>
					$(i).removeClass('fr-disabled')
				);
			}, 100);
		}
	}

	private initEvents(editor) {
		editor.events.on(
			'keydown',
			(event) => {
				const selection = window.getSelection();
				const range = selection?.getRangeAt(0);
				const key = event?.which;
				const elem = range?.commonAncestorContainer;
				const parentElement = elem?.parentElement;
				const prevElement = parentElement?.previousSibling as any;
				const parentClasses = Array.from(parentElement?.classList);
				const ancestorElement =
					selection.anchorNode?.parentElement?.parentElement;
				const preventKeyDown = () => {
					event.preventDefault();
					event.stopPropagation();
					event.stopImmediatePropagation();
				};

				// KEYBOARD EVENTS
				if (key == KEYCODES.enter) {
					if (parentClasses?.includes('mtag')) {
						if (!this.allowNewlineOnMT) {
							// For regular merge tags
							preventKeyDown();
							return false;
						}
						const mtUID = parentElement?.dataset.uid;
						// wait for the DOM to update when enter is pressed
						setTimeout(() => {
							// find and remove mt element that are duplicate
							editor.el
								.querySelectorAll(`var[data-uid="${mtUID}"]`)
								?.forEach((el: HTMLElement, index: number) => {
									if (index === 0) {
										return;
									}
									delete el.dataset.mergetag;
									delete el.dataset.uid;
									el.className = '';
								});
						});
						return true;
					}
				}

				if (key == KEYCODES.backspace) {
					if (loatSosPagesIds?.includes(ancestorElement?.id)) {
						// For LOAT Risk Analysis Input Notes
						const preventAction = WysiwygMapper.preventOnLoatRAInputNotes(
							elem,
							ancestorElement,
							prevElement,
							key
						);
						if (preventAction) {
							preventKeyDown();
							return false;
						}
					}
				}

				if (key == KEYCODES.delete) {
					if (loatSosPagesIds?.includes(ancestorElement?.id)) {
						// For LOAT Risk Analysis Input Notes
						const preventAction = WysiwygMapper.preventOnLoatRAInputNotes(
							elem,
							ancestorElement,
							prevElement,
							key
						);
						if (preventAction) {
							preventKeyDown();
							return false;
						}
					}
				}

				// Other Specific Conditions
				if (loatRiskAnalysisIds?.includes(ancestorElement?.id)) {
					// ANY KEY
					// For LOAT Risk Analysis Merge Tag Notes
					const preventAction = WysiwygMapper.preventOnLoatRAMTnotes(
						ancestorElement,
						parentElement
					);
					if (preventAction) {
						preventKeyDown();
						return false;
					}
				}
			},
			true
		);
		editor.events.on(
			'drop',
			(event) => {
				const target = event?.target;
				if (
					Array.from(target?.classList)?.includes('fr-element') ||
					target?.tagName === 'H1'
				) {
					event.preventDefault();
					event.stopPropagation();
					event.stopImmediatePropagation();
					return false;
				}
			},
			true
		);
	}

	private commands(cmd) {
		if (cmd === 'html') {
			this.isCodeView = !this.isCodeView;
		}

		if (cmd === 'applybackgroundColor' || cmd === 'applytextColor') {
			const scrollPosition = $(window).scrollTop();
			$(window).scrollTop(scrollPosition);
		}
	}

	private cleanPaste(clipboard_html) {
		const id = 'cleanPaste';
		const html = clipboard_html?.replace(/<\!--.*?-->/g, '') || '';
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${id}">${html}</div>`);

		if (html?.toString()?.includes(froalaCopyClass)) {
			// Remove copy tag from froala
			Array.from(newHtml.querySelectorAll(`.${froalaCopyClass}`)).forEach(
				(val) => val.remove()
			);
		} else {
			// Remove inline styles
			newHtml
				.querySelectorAll('[style]')
				.forEach((el) => el.removeAttribute('style'));
			newHtml.querySelectorAll(`strong, b`).forEach((e) => {
				if (e?.innerHTML !== '') {
					e.after(
						document.createRange().createContextualFragment(e?.innerHTML)
					);
				}
				e.remove();
			});
			// Remove html headers
			newHtml.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach((e) => {
				if (e?.innerHTML !== '') {
					e.after(
						document.createRange().createContextualFragment(e?.innerHTML)
					);
				}
				e.remove();
			});

			newHtml.querySelectorAll(`#${id} div, #${id} section`).forEach((e) => {
				// Do not allow pasting of DIV & SECTION tags
				// This is reserved for our Merge Tag Wrappers and other custom templates
				if (e?.innerHTML !== '') {
					e.after(
						document.createRange().createContextualFragment(e?.textContent)
					);
				}
				e.remove();
			});

			newHtml.querySelectorAll(`#${id} header, #${id} footer`).forEach((e) => {
				// Don't allow pasting of HEADER & FOOTER tags
				// This is reserved for HTML to PDF
				e.remove();
			});
		}

		// Remove all IDs to avoid duplicate
		newHtml
			.querySelectorAll(`#${id} [id]`)
			.forEach((el) => el.removeAttribute('id'));

		// Remove merge tag related classes
		newHtml
			.querySelectorAll(
				`#${id} [class*="mtag"], #${id} [class*="dynamicDependants"], #${id} [class*="no-data"]`
			)
			.forEach((el) => {
				const omitClasses = [];
				el.classList?.forEach((c) => {
					if (
						c?.includes('mtag') ||
						c?.includes('dynamicDependants') ||
						c?.includes('no-data')
					) {
						omitClasses.push(c);
					}
				});
				el.classList.remove(...omitClasses);

				if (el.classList?.length === 0) {
					el.removeAttribute('class');
				}
			});

		// Remove all data* attributes
		newHtml.querySelectorAll(`#${id} *`).forEach((el: any) => {
			const attrs = [...el?.attributes]?.filter(Boolean)?.map((e) => e?.name);
			attrs?.forEach((t) => {
				if (t?.startsWith('data')) {
					el?.removeAttribute(t);
				}
			});
		});

		return newHtml.querySelector(`#${id}`)?.innerHTML?.trim() || '';
	}

	private onContentChange() {
		const self = this;
		return function () {
			const editor = this;
			const content = this.html.get();

			self.generateHeaders(editor.el);
			self.undoStack.emit(editor.undo_stack);
			self.onChangeEvent.emit({ ...self, content });

			if (self.hasSidebarSections) {
				const sectionHeight = $('.fr-wrapper').outerHeight();
				const toolbarHeight = $('.fr-toolbar').height();
				$('.tap-froala__nav')
					.css({ 'margin-top': toolbarHeight + 'px' })
					.height(sectionHeight);
			}
		};
	}

	private onImageBeforeUpload() {
		const self = this;
		return function (images) {
			const editor = this;
			const image = images.length ? images[0] : null;

			of(image)
				.pipe(
					filter((x) => !!x),
					mergeMap((x) => self.convertToBase64(x)),
					tap((x) => editor.image.insert(x, null, null, editor.image.get()))
				)
				.subscribe();
			return false;
		};
	}

	private setDynamicOptions() {
		let toolbarOptions = {
			toolbarButtons: this.readOnly
				? []
				: this.filterMergeTagsBtn(button, this.hideMergeTagButtons),
			toolbarButtonsMD: this.readOnly
				? []
				: this.filterMergeTagsBtn(buttonMD, this.hideMergeTagButtons),
			toolbarButtonsSM: this.readOnly
				? []
				: this.filterMergeTagsBtn(buttonSM, this.hideMergeTagButtons),
			toolbarButtonsXS: this.readOnly
				? []
				: this.filterMergeTagsBtn(buttonXS, this.hideMergeTagButtons),
		};

		let pastePlainSettings = {
			pastePlain: options.pastePlain,
			pasteAllowedStyleProps: options.pasteAllowedStyleProps,
			pasteDeniedAttrs: options.pasteDeniedAttrs,
			pasteDeniedTags: options.pasteDeniedTags,
		};

		if (this.simpleEditor) {
			const simpleBtns = this.filterMergeTagsBtn(
				simpleEditorButtons,
				this.hideMergeTagButtons
			);
			toolbarOptions = {
				toolbarButtons: simpleBtns,
				toolbarButtonsMD: simpleBtns,
				toolbarButtonsSM: simpleBtns,
				toolbarButtonsXS: simpleBtns,
			};
		}

		// Uncomment if need to enable paste plain only for all users except TAP Level
		// if (!this.isTapLevel) {
		// 	pastePlainSettings = {
		// 		pastePlain: true,
		// 		pasteAllowedStyleProps: [],
		// 		pasteDeniedAttrs: ['class', 'id', 'style', 'dir'],
		// 		pasteDeniedTags: ['*'],
		// 	};
		// }

		this.options = {
			...this.options,
			...this.extendOptions,
			...pastePlainSettings,
			documentReady: this.simpleEditor ? false : true,
			charCounterCount: !this.readOnly,
			...toolbarOptions,
		};

		if (!this.allowAllHtmlTagsAttrs) {
			delete this.options.htmlAllowedTags;
			delete this.options.htmlAllowedAttrs;
		}

		if (!this.showToolbar) {
			this.options.toolbarButtons = [];
			this.options.toolbarButtonsMD = [];
			this.options.toolbarButtonsSM = [];
			this.options.toolbarButtonsXS = [];
		}
	}

	private filterMergeTagsBtn(buttons, isHidden) {
		if (isHidden) {
			return buttons?.filter((x) => !mergeTagButtons?.includes(x));
		}
		return [...buttons, this.shortcodeButtonId];
	}

	private setTriggerPrint(editor) {
		this.triggerPrint$
			?.pipe(
				tap((data) => {
					if (data) {
						editor.print.run();
						// this.printPDF(editor.el);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	private setUndoReset(editor) {
		this.undoReset$
			?.pipe(
				filter((x) => !!x),
				tap(() => {
					editor.undo.reset();
					editor.undo.saveStep();
					this.undoStack.emit([]);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	private setZoom() {
		if (this.readOnly) {
			const setZoomTimer = setTimeout(() => {
				$('.fr-view').css('zoom', `calc((${this.zoom ?? 120}/100)*100%)`);
				if (setZoomTimer) {
					clearTimeout(setZoomTimer);
				}
			}, 100);
		}
	}

	private generateHeaders(element: HTMLElement) {
		this.sections = [];
		const h1 = Array.from($(element)?.find('h1')) as HTMLElement[];

		// Remove all generated pagebreak
		$(element).find('.generated-pagebreak').remove();

		let id = 100;
		h1?.forEach((el) => {
			const index = id++;

			if (
				!!el.innerText?.trim() &&
				// dont prepend pagebreak inside email signature
				el?.id !== 'email-signature-name'
			) {
				// Add pagebreak
				const hr = document.createElement('hr');
				$(hr)
					.addClass('span.fr-deletable')
					.addClass('generated-pagebreak')
					.attr('name', 'pagebreak')
					.attr('contenteditable', 'false');

				$(el).attr('id', `${index}`);
				$(hr).insertBefore(el);

				this.sections.push({ id: index, name: el.innerText?.trim() });
			}
		});
	}

	scrollToHeader(id) {
		const stop = $(id).offset().top;
		$('.fr-wrapper').animate({ scrollTop: stop }, 300);
		return true;
	}

	isEmpty(): boolean {
		return (
			Boolean(
				// @ts-ignore-next
				this.froala?.getEditor()?.$el?.text?.()?.replace('\n', '')?.trim() ??
					true
			) ||
			// @ts-ignore-next
			Boolean(this.froala.getEditor().el.querySelector('img'))
		);
	}

	isValid(): boolean {
		return (
			// @ts-ignore-next
			Boolean(this.froala?.getEditor()?.el?.innerText?.trim()) ||
			// @ts-ignore-next
			Boolean(this.froala?.getEditor()?.el?.querySelector('img'))
		);
	}

	getContentWithoutSignature(): string {
		// @ts-ignore-next
		const content = this.froala.getEditor().el.cloneNode(true);
		// remove email signature parent from the clone DOM
		content.querySelector('#email-signature-template')?.remove();
		return content.innerHTML;
	}

	private convertToBase64 = (file) =>
		new Observable((obs) => {
			const reader = new FileReader();
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();
			return reader.readAsDataURL(file);
		});

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
