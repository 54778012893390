import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap, switchMap, map, finalize } from 'rxjs/operators';

export interface CrtKiwisaverCanDeactivateGuardComponent {
	saveComponentFn: () => Observable<any>;
	loadingComponentFn: (isLoading: boolean) => Observable<any> | void;
}

@Injectable()
export class CrtKiwisaverCanDeactivateGuard
	implements CanDeactivate<CrtKiwisaverCanDeactivateGuardComponent>
{

  constructor() {}

	canDeactivate(component: CrtKiwisaverCanDeactivateGuardComponent): Observable<boolean> | boolean {
    if (!component.saveComponentFn) {
      return true;
    }
		// Set Loader
		component.loadingComponentFn(true);

		return component.saveComponentFn().pipe(
			switchMap(() => of(true)),
			finalize(() => component.loadingComponentFn(false)),
			catchError((err) => of(true))
		);
	}
}
