import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { objectUtil } from '../../../../../util/util';
import { KiwiSaverSettingsQuery } from '../../../state/kiwisaver-settings.query';
import { KiwiSaverSettingsStore } from '../../../state/kiwisaver-settings.store';
import {
	FactFindRiskProfileQASettingsState,
	FactFindRiskProfileOutcomesSettingsState,
} from './fact-find-risk-profile-settings.model';

@Injectable()
export class FactFindRiskProfileSettingsService {
	factFindRiskProfileQASettings$ = this.query.factFindRiskProfileQASettings$;
	factFindRiskProfileOutcomesSettings$ = this.query.factFindRiskProfileOutcomesSettings$;

	constructor(
		private api: ApiService,
		private store: KiwiSaverSettingsStore,
		private query: KiwiSaverSettingsQuery
	) {}

	clearFactFindSettings(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getFactFindRiskProfileQASettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<any>(endpoint).pipe(
			tap((data) => {
				const newQA = data?.QA?.map((qa) => {
					const newAnswers = qa?.Answers?.map((answer) => {
						return {
							...answer,
							OrigScore: answer.Score,
							OrigStatus: answer.Status,
							OrigOrderNo: answer.OrderNo
						}
					});

					return {
						...qa,
						Answers: newAnswers
					}
				});

				const newData = {
					...data,
					QA: newQA
				};

				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(newData) : null;
					this.store.setFactFindRiskProfileQASettings(state);
				})
			}),
			catchError(() => of(undefined))
		);
	}

	updateFactFindRiskProfileQASettingsStateOnly(data: FactFindRiskProfileQASettingsState) {
		return of(data).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setFactFindRiskProfileQASettings(state);
				})
			)
		);
	}

	updateFactFindRiskProfileQASettings(data: FactFindRiskProfileQASettingsState) {
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.put<FactFindRiskProfileQASettingsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setFactFindRiskProfileQASettings(state);
				})
			)
		);
	}

	getFactFindRiskProfileOutcomesSettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<FactFindRiskProfileOutcomesSettingsState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setFactFindRiskProfileOutcomesSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateFactFindRiskProfileOutcomesSettingsStateOnly(data: FactFindRiskProfileOutcomesSettingsState) {
		return of(data).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setFactFindRiskProfileOutcomesSettings(state);
				})
			)
		);
	}

	updateFactFindRiskProfileOutcomesSettings(data: FactFindRiskProfileOutcomesSettingsState) {
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.put<FactFindRiskProfileOutcomesSettingsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setFactFindRiskProfileOutcomesSettings(state);
				})
			)
		);
	}
}
