<form [formGroup]="form">
  <div class="container-fluid px-0">
    <div class="row tap-container">
      <div class="col mb-4"></div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0">
            <label for="" class="position-relative pt-0">
              Email Type
              <span class="tap-required" *ngIf="EmailType.invalid">*</span>
            </label>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0">
            <select
              formControlName="emailType"
              class="form-control theme-crm-field-disabled"
              id="label_r4_preferredEmailContact"
              (change)="selectOption($event)"
            >
              <option value=""></option>
              <option *ngFor="let d of emailTypes" [value]="d.value">
                {{ d.display }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0">
            <label for="" class="position-relative pt-0">
              Date Target
              <span class="tap-required" *ngIf="DateTarget.invalid">*</span>
            </label>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0">
            <app-date-input
              formControlName="dateTarget"
              placeholder="dd/mm/yyyy"
            ></app-date-input>
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0 text-right">
        <div class="form-group form-inline row mb-1">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0"></div>
          <div
            class="col-lg-3 col-md-6 col-sm-6 px-0 text-right"
            *ngIf="!isSaving"
          >
            <span class="text-danger mr-3" *ngIf="isRestart"
              >Sending Email Failed</span
            >
            <span class="text-success mr-3" *ngIf="isSent"
              >Manual Email Sent</span
            >
            <button class="btn btn-primary" (click)="sendBtnClick()">
              {{ isRestart ? "Restart" : "Send" }}
            </button>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0 text-right">
            <app-manual-loader-mini
              [isLoading]="isSaving"
            ></app-manual-loader-mini>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
