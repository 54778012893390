<div
	class="modal-header text-light tap-bg-primary d-flex justify-content-between"
>
	<span class="modal-title font-weight-normal">{{ title }}</span>
	<div class="clrearfix d-flex">
		<button type="button" aria-label="Close" class="close" (click)="hide()">
			<span _ngcontent-njr-c542="" class="material-symbols-outlined"
				>close</span
			>
		</button>
	</div>
</div>

<div
	class="modal-body content bg-white d-flex align-items-center justify-content-center flex-column"
>
	<form
		[ngClass]="{ 'disabled': isUploading }"
		[formGroup]="formGroup"
		class="modal-body__inner clearfix py-0 px-0 w-100"
	>
		<div class="row mx-0">
			<div class="col-12 mx-0 px-0 mb-1">
				<label for=""><b>Claim Document Name</b></label>
			</div>
			<div class="col-12 mx-0 px-0 mb-2">
				<ng-container *ngIf="!isOthersDocumentSelected">
					<app-input-select
						displayProperty="display"
						formControlName="documentName"
						valueProperty="value"
						[items$]="documentNames$"
						(valueChange) = "checkDropdownValue($event)"
					>
					</app-input-select>
				</ng-container>
				<ng-container *ngIf="isOthersDocumentSelected">
				<input
						type="text"
						formControlName="documentName"
						class="form-control"
						placeholder="Other"
						id="documentName"
						(focusout)="checkInputValue()"
					/>
				</ng-container>
			</div>
		</div>

		<div class="w-100 d-flex mb-2 btn-container">
			<button
				class="upload-document-btn ui-btn-gray w-100"
				(click)="uploadDocument()"
				[ngClass]="{ 'disabled': clientDocumentIsLoading }"
			>
				Upload New
			</button>
			<button
				id="linkDocumentButton"
				type="button"
				class="link-document-btn ui-btn-gray w-100"
				(click)="linkDocument()"
				[ngClass]="{ 'disabled': clientDocumentIsLoading }"
			>
				Link Document
			</button>
		</div>

		<div class="row mx-0 mb-2">
			<div class="col-12 px-0">
				<button
					id="uploadBtn"
					class="btn btn-primary ui-btn-primary w-100"
					(click)="upload()"
					[ngClass]="{ 'disabled': !documentToUpload || formGroup.invalid }"
				>
					Upload
				</button>
			</div>
		</div>
	</form>
</div>
