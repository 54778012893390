import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class SetViewTapLevelGuard implements CanActivate, CanActivateChild {

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    this.cs.SetIsViewTapLevel(true);
    return true;
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    this.cs.SetIsViewTapLevel(true);
    return true;
  }

  constructor(private cs: ConfigService) { }

}
