import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	AfterViewInit,
	OnChanges,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { NoWhitespaceValidator } from '../../../../../shared/directive/no-whitespace/no-whitespace.directive';
import { ReplaySubject, Observable, of, Observer, Subject } from 'rxjs';
import { SecondaryTrustState } from '../../../../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { strUtil } from '../../../../../util/util';
import { ClientProfileSecondaryTrustMapper as SecondaryTrustMapper } from '../../../../../shared/models/client-profile/secondary-trust/secondary-trust.mapper';
import { mergeMap, tap, withLatestFrom, map, takeUntil, take } from 'rxjs/operators';
import { ObservableUtil } from '../../../../../util/observable.util';
import { DeleteModalComponent } from '../../../../../shared/modal/delete-modal/delete-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { NoteState } from '../../../../../shared/models/notes/note.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { TablePaginateNotesComponent } from '../../../../../shared/table-paginate-notes/table-paginate-notes.component';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ClientProfileService } from '../../states/client-profile.service';
import { Fields, getRequiredWarning } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-form-trust',
	templateUrl: './form-trust.component.html',
	styleUrls: ['./form-trust.component.scss'],
})
export class FormTrustComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Input() formID: any;
	@Input() isLead: boolean;
	@Input() secondaryTrust: SecondaryTrustState;
	@Input() addMode: boolean;
	@Input() disableDelete: boolean;
	@Input() addFn$: (t: SecondaryTrustState) => Observable<any>;
	@Input() updateFn$: (t: SecondaryTrustState) => Observable<any>;
	@Input() deleteFn$: (t: SecondaryTrustState) => Observable<any>;
	@Input() trustTypes: ViewDisplayValue[];
	// Notes
	@Input() getNotesFn$: (req: any) => Observable<any>;
	@Input() addNotesFn$: (req: any) => Observable<any>;
	@Input() deleteNotesFn$: (req: any) => Observable<any>;

	private _isSaving = false;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	isSaving$ = new ReplaySubject<boolean>(1);
	private _isEdit = false;
	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() cancelEvent = new EventEmitter<boolean>();
	@Output() saveEvent = new EventEmitter<any>();

	form: UntypedFormGroup = this.fb.group({
		trustName: this.fb.control('', [Validators.required, NoWhitespaceValidator]),
		tradingName: this.fb.control(''),
		trustType: this.fb.control('', [Validators.required]),
		fullTrustName: this.fb.array([]),
		independentTrustName: this.fb.array([]),
		note: this.fb.control(''),
	});

	notes: { notes: NoteState[]; count: number };

	formData$ = ObservableUtil.connectBehavior(this.form.valueChanges, this.form.value);

	@ViewChild(TablePaginateNotesComponent) noteTable: TablePaginateNotesComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private profile: ClientProfileService,
		private loggerService: LoggerService
	) { }

	ngOnInit() {
		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$.pipe(takeUntil(this.onDestroy$)).subscribe(x => (this._isSaving = x));
		this.removeEmptyFormControl();
	}

	ngOnChanges() {
		if (this.addMode) {
			this.isEdit$.next(true);
			this.secondaryTrust = undefined;
			this.form.reset();
			this.patchDefaultValues();
		} else {
			const data = this.secondaryTrust
				? Object.assign({}, SecondaryTrustMapper.mapToView(this.secondaryTrust))
				: Object.assign({}, this.form.value);

			this.form.reset(data);

			this.setStringControlArray(data.fullTrustName as string[], 'fullTrustName');
			this.setStringControlArray(data.independentTrustName as string[], 'independentTrustName');
		}
	}

	addNotes$ = note => {
		return this.addNotesFn$({
			customerID: note.customerID,
			customerServiceID: note.customerServiceID,
			notes: note.notes,
		});
	};

	deleteNotes$ = (note: NoteState) => {
		return this.deleteNotesFn$(note);
	};

	getNotes$ = (req: GetNotes) => {
		return this.getNotesFn$(req).pipe(
			tap(x => {
				this.notes = x;
			})
		);
	};

	ngAfterViewInit() { }

	patchDefaultValues() {
		const findDefault = R.find<ViewDisplayValue>(x => x.isDefault);
		const getValue = (v: ViewDisplayValue): string => R.propOr('', 'value', v);
		const mapToDefaultValues = R.pipe(R.map(findDefault), R.map(getValue));
		const defaults = mapToDefaultValues([this.trustTypes]);
		this.setDropdownDefaults(defaults);
	}

	private setDropdownDefaults = ([trustTypes]: string[]): void => {
		// tslint:disable-next-line: no-use-before-declare
		patchValue<SecondaryTrustState>(this.form, {
			trustType: trustTypes,
		});
	};

	get trustNames(): UntypedFormArray {
		return this.form.get('fullTrustName') as UntypedFormArray;
	}

	get independentTrustNames(): UntypedFormArray {
		return this.form.get('independentTrustName') as UntypedFormArray;
	}

	get trustName() {
		return this.form.get('trustName');
	}

	get trustType() {
		return this.form.get('trustType');
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	addTrustName() {
		this.trustNames.push(this.fb.control(''));
	}

	addIndependentTrustName() {
		this.independentTrustNames.push(this.fb.control(''));
	}

	setStringControlArray(stringArray: string[] = [], controlArrayName: string = '') {
		const controls = stringArray === null ? null : stringArray?.map(x => this.fb.control(x));
		const formArr = this.form.controls[controlArrayName] as UntypedFormArray;

		while (formArr.length !== 0) {
			formArr.removeAt(0);
		}

		if (controls !== null) {
			controls?.forEach(x => (this.isEdit ? null : x.disable()));
			controls?.forEach(x => (this.form.controls[controlArrayName] as UntypedFormArray).push(x));
		}
	}

	prepareValue(data?: any) {
		return {
			...data,
			trustName: strUtil.safeTrimExtraSpace(data.trustName),
			tradingName: strUtil.safeTrimExtraSpace(data.tradingName),
			fullTrustName: data.fullTrustName?.map(strUtil.safeTrimExtraSpace),
			independentTrustName: data.independentTrustName?.map(strUtil.safeTrimExtraSpace),
		} as SecondaryTrustState;
	}

	edit() {
		this.isEdit$.next(true);
	}

	save() {
		if (!this.form.valid || this.prepareValue(this.form.value).trustName?.trim() === '') {
			if (this.prepareValue(this.form.value).trustName?.trim() === '' || !this.prepareValue(this.form.value).trustName) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.TrustName));
				return;
			}
			if (!this.prepareValue(this.form.value).trustType) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.TrustType));
				return;
			}
		}
		this.isSaving$.next(true);
		this.isEdit$.next(false);

		this.removeEmptyFormControl();

		if (!this.addMode) {
			of(this.secondaryTrust)
				.pipe(
					withLatestFrom(this.formData$),
					map(([, x]) => this.prepareValue(x)),
					map(x => SecondaryTrustMapper.mapToUpsert({ ...this.secondaryTrust, ...x })),
					mergeMap(x => this.updateFn$(x)),
					takeUntil(this.onDestroy$)
				)
				.subscribe(
					() => {
						this.isEdit$.next(false);
					},
					() => {
						this.isEdit$.next(true);
					},
					() => {
						this.isSaving$.next(false);
					}
				);
		} else {
			of(true)
				.pipe(
					withLatestFrom(this.formData$),
					map(([, x]) => this.prepareValue(x)),
					map(x => SecondaryTrustMapper.mapToUpsert(x)),
					mergeMap(x => this.addFn$(x)),
					tap(
						res => {
							this.isEdit$.next(true);
							this.isSaving$.next(false);
						},
						err => {
							this.isEdit$.next(true);
							this.isSaving$.next(false);
						}
					),
					takeUntil(this.onDestroy$)
				)
				.subscribe();
		}
	}

	delete(data: SecondaryTrustState) {
		this.isSaving$.next(true);
		this.deleteFn$(data)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				res => { },
				err => { },
				() => {
					this.isSaving$.next(false);
				}
			);
	}

	confirmDelete() {
		const data = SecondaryTrustMapper.mapToUpsert({
			...this.secondaryTrust,
			customerID: +this.secondaryTrust.customerID,
		});

		this.profile
			.listOfServices(+data.customerID)
			.pipe(
				tap(x => {
					const message = [];
					if (x) {
						x.lrs?.forEach(lr => {
							lr.customerServices?.forEach(cs => {
								const provider =
									cs.provider + ': ' + cs.policyNumber + (cs.policyNumberSuffix ? ' - ' + cs.policyNumberSuffix : '');
								message.push(` - L&R Policy Owner of ${provider} <br />`);
							});
						});

						x.mortgages?.forEach(m => {
							m.customerServices?.forEach(cs => {
								const provider =
									cs.provider + ': ' + cs.loanNumber + (cs.loanNumberSuffix ? ' - ' + cs.loanNumberSuffix : '');
								message.push(` - Borrowing Entity of ${provider} <br />`);
							});
						});

						x.propertyAssets?.forEach(pa => {
							message.push(` - Property Owner of ${pa.propertyAddress} <br />`);
						});

						x.assets?.forEach(({ asset }) => {
							message.push(` - Owner of ${asset} <br />`);
						});

						x.fgs?.forEach((fg: any) => {
							const provider =
								fg.provider +
								': ' +
								fg.policyNumber +
								(fg.policyNumberSuffix ? ' - ' + fg.policyNumberSuffix : '');
							message.push(` - F&G Policy Owner of ${provider} <br />`);
						});

						x.kiwiSavers?.forEach(ks => {
							const provider = ks.provider + ': ' + ks.memberNumber;
							message.push(` - Fund Owner of ${provider} <br />`);
						});

						x.investments?.forEach((investment) => {
							const provider = `${investment.provider}: ${investment.investorNumber}`;
							message.push(` - Investment Owner of ${provider} <br />`);
						});
					}
					
					const confirm = new Observable((obs: Observer<any>) => {
						this.delete(data);
						obs.complete();
					});

					const initState = {
						header: 'Delete customer',
						message: x
							? `'${data.trustName}' is a <br /> ${message?.toString()?.replace(/,/g, ' ')} Kindly remove ${message && message.length > 1 ? 'these' : 'this'
							} before deleting.`
							: `Are you sure you want to delete '${data.trustName}'?`,
						delete$: confirm,
						canDelete: x ? false : true,
					};

					this.modalService.show(DeleteModalComponent, {
						class: 'modal-dialog-centered',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false
					});
				}),
				take(1)
			)
			.subscribe();
	}

	cancel() {
		if (!this.addMode) {
			this.isEdit$.next(false);
			const data = this.secondaryTrust
				? Object.assign({}, SecondaryTrustMapper.mapToView(this.secondaryTrust))
				: Object.assign({}, this.form.value);
			this.form.reset(data);
			this.setStringControlArray(data.fullTrustName as string[], 'fullTrustName');
			this.setStringControlArray(data.independentTrustName as string[], 'independentTrustName');
		}
		this.cancelEvent.emit(true);
	}

	removeEmptyFormControl() {
		// Remove empty values
		this.trustNames.value?.forEach((item, i) => {
			if (!item) {
				this.trustNames?.removeAt(i);
			}
		});
		// Remove empty values
		this.independentTrustNames.value?.forEach((item, i) => {
			if (!item) {
				this.independentTrustNames?.removeAt(i);
			}
		});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

const patchValue: <T>(form: UntypedFormGroup, partialViewModel: Partial<T>) => void = (form, partialViewModel) => {
	const FormValueToPatch = Object.keys(partialViewModel)?.reduce((acc, v) => {
		const notEmpty = !['', null, undefined]?.some(x => x === v);
		if (notEmpty) {
			const prop = { [v]: partialViewModel[v] };
			Object.assign(acc, prop);
		}
		return acc;
	}, {});
	form.patchValue(FormValueToPatch);
};
