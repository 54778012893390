<app-primary-base-widget
	headerText="API Stats - Rolling 12 Months"
	[widgetCode]="widgetCode"
>
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<div
		class="position-relative clearfix h-100 px-0 scrollable-auto-x scrollable-hidden-y"
	>
		<app-bipolar-bar-chart
			#asryBipolarBarChart
			[data]="viewData$ | async"
			[showCurrencySignAxisY]="true"
			[chartId]="chartId"
			[enableBarOverlap]="true"
		></app-bipolar-bar-chart>
	</div>
</app-primary-base-widget>
