import { Injectable, EventEmitter } from '@angular/core';
import { EmailHistoryModel } from './email-history.model';
import { EmailHistoryStore } from './email-history.store';
import { EmailHistoryQuery } from './email-history.query';
import { EmailHistoryResponse } from '../../../../shared/models/emails/email-history/email-history.model';
import { ApiService } from '../../../../core/base/api.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import {
	tap,
	map,
	retry,
	catchError,
} from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { throwError } from 'rxjs';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Injectable()
export class EmailHistoryService {

	public onResize$ = new EventEmitter<{ width: number; height: number; }>();

	constructor(
		private api: ApiService,
		private emailHistoryStore: EmailHistoryStore,
		private emailHistoryQuery: EmailHistoryQuery,
  protected loggerService: LoggerService
	) {
	}

	clear(): void {
		applyTransaction(() => {
			this.emailHistoryStore.reset();
		});
	}

	reloadData() {
		const data = this.emailHistoryQuery.getAll();
		this.emailHistoryStore.set([]);
		this.emailHistoryStore.set(data);
	}

	getReport(values) {
		const endpoint = `emails/email-history`;
		const body = values;
		return this.api.post3<EmailHistoryResponse>(endpoint, body).pipe(
			tap(result => {
				const topResult = { total: result.Total, success: result.TotalSuccess, failed: result.TotalFailed };
				this.emailHistoryStore.set(result.EmailHistoryStatuses);
				if (result.EmailHistoryStatuses[0]) {
					let comumnFilter: string[] = [];
					const columnsData = Object.keys(result.EmailHistoryStatuses[0]);
					comumnFilter = columnsData?.filter(x => x !== 'EmailHistoryId');
					comumnFilter.push('EmailHistoryId');
					this.emailHistoryStore.setColumns(comumnFilter);
				}
				this.emailHistoryStore.setTableResult(topResult);
			})
		);
	}

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.emailHistoryStore.setSort(propSort, sort);
	}

	exportStatus(req: EmailHistoryModel) {
		return this.api.post4('export/email-history', req).pipe(
			map(x => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			retry(1),
			catchError(err => {
				this.loggerService.Log(err, logMessage.shared.export.error);
				return throwError(err);
			})
		);
	}

	tryParseJSON(jsonString: string): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}


}
