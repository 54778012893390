<form
	[formGroup]="formGroup"
	#form
>
	<div class="row">
		<div class="col-lg-8 col-12">
			<div class="row">
				<div class="col-2 d-flex align-items-center">
					<label for="">Borrower</label>
				</div>
				<div class="col-10">
					<app-input-chips
						#borrowers
						[items$]="borrowers$"
						[compareWith]="compareWith"
						dropdownPosition="bottom"
						valueProperty="value"
						displayProperty="display"
						formControlName="borrower"
					></app-input-chips>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-2 d-flex align-items-center">
					<label for="status">
						Status
						<span
							*ngIf="status.errors?.required"
							class="ml-1 text-danger position-absolute"
							>*</span
						>
					</label>
				</div>

				<div class="col-4">
					<span class="custom-dropdown w-100">
						<select
							class="form-control theme-crm-field"
							id="status"
							formControlName="status"
							[ngClass]="{ 'border-danger': isSubmitted && status.errors }"
						>
							<option value=""></option>
							<option *ngFor="let s of liabilityStatus" [value]="s.value">
								{{ s.display }}
							</option>
						</select>
					</span>
				</div>

				<div class="col-2 d-flex align-items-center">
					<label for="lender">
						Lender
						<span
							*ngIf="lender.errors?.required"
							class="ml-1 text-danger position-absolute"
							>*</span
						>
					</label>
				</div>

				<div class="col-4">
					<span class="custom-dropdown w-100">
						<select
							class="form-control theme-crm-field"
							id="lender"
							formControlName="lender"
							[ngClass]="{ 'border-danger': isSubmitted && lender.errors }"
						>
							<option value=""></option>
							<option *ngFor="let d of lenders" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-2 d-flex align-items-center">
					<label for="loanType">Loan Type</label>
				</div>

				<div class="col-4">
					<span class="custom-dropdown w-100">
						<select
							class="form-control theme-crm-field"
							id="loanType"
							formControlName="loanType"
						>
							<option value=""></option>
							<option *ngFor="let d of loanTypes" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>

				<div class="col-2 d-flex align-items-center">
					<label for="interestRate">Interest Rate</label>
				</div>

				<div class="col-4">
					<div class="dollar-icon dollar-icon-right">
						<input
							type="text"
							formControlName="interestRate"
							appTwoDigitDecimalNumber
							class="form-control theme-crm-field"
							id="interestRate"
						/><i>%</i>
					</div>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-2 d-flex align-items-center">
					<label for="loanRepayment">
						Loan Repayment
						<span
							*ngIf="loanRepayment.errors?.required"
							class="ml-1 text-danger position-absolute"
							>*</span
						>
					</label>
				</div>

				<div class="col-4">
					<div class="dollar-icon">
						<input
							type="text"
							formControlName="loanRepayment"
							appTwoDigitDecimalNumber
							class="form-control theme-crm-field"
							id="loanRepayment"
							[ngClass]="{ 'border-danger': isSubmitted && loanRepayment.errors }"
						/><i>$</i>
					</div>
				</div>

				<div class="col-2 d-flex align-items-center">
					<label for="repaymentFrequency">
						Repayment Frequency
						<span
							*ngIf="repaymentFrequencyControl.errors?.required"
							class="ml-1 text-danger position-absolute"
							>*</span
						>
					</label>
				</div>

				<div class="col-4">
					<span class="custom-dropdown w-100">
						<select
								class="form-control theme-crm-field"
								id="repaymentFrequency"
								formControlName="repaymentFrequency"
								[ngClass]="{ 'border-danger': isSubmitted && repaymentFrequencyControl.errors }"
								>
								<option value=""></option>
								<option *ngFor="let d of repaymentFrequency" [value]="d.value">
								{{ d.display }}
								</option>
						</select>
					</span>
				</div>
			</div>
		</div>

		<div class="col-lg-4 col-12">
			<div class="row">
				<div class="col-4">
					<label for="liability">
						Liability
						<span
							*ngIf="liabilityControl.errors?.required"
							class="ml-1 text-danger position-absolute"
							>*</span
						>
					</label>
				</div>
				<div class="col-8">
					<span class="custom-dropdown w-100">
						<select
								class="form-control theme-crm-field"
								id="liability"
								formControlName="liability"
								[ngClass]="{ 'border-danger': isSubmitted && liabilityControl.errors }"
								>
								<option value=""></option>
								<option *ngFor="let d of liabilityChoices" [value]="d.value">
								{{ d.display }}
								</option>
						</select>
					</span>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-4">
					<label for="loanLimit">
						Loan/Limit
						<span
							*ngIf="loanLimit.errors?.required"
							class="ml-1 text-danger position-absolute"
							>*</span
						>
					</label>
				</div>
				<div class="col-8">
					<div class="dollar-icon">
						<input
							autocomplete="off"
							type="text"
							formControlName="loanLimit"
							appTwoDigitDecimalNumber
							class="form-control theme-crm-field"
							[ngClass]="{ 'border-danger': isSubmitted && loanLimit.errors }"
							id="loanLimit"
						/><i>$</i>
					</div>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-4">
					<label for="loanBalance">
						Loan Balance
						<span
							*ngIf="loanBalance?.errors?.required"
							class="text-danger position-absolute"
							>*</span
						>
					</label>
				</div>
				<div class="col-8">
					<div class="dollar-icon">
						<input
							type="text"
							formControlName="loanBalance"
							appTwoDigitDecimalNumber
							class="form-control theme-crm-field"
							[ngClass]="{ 'border-danger': isSubmitted && loanBalance.errors }"
							id="loanBalance"
						/><i>$</i>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
