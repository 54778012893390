// Data for MOAT Settings SOA preview only
export const previewData = [
	{
		lifeAssured: 0,
		lifeAssuredName: 'John Smith',
		lifeAssuredList: [
			{
				newExisting: 'Replacement',
				lifeAssured: 0,
				product: 'Life',
				benefit: '123.00',
				notes: 'Any notes here',
				premium: '123.00',
				premiumValue: null,
				startDate: '2021-07-01T00:00:00',
				priority: 0,
				provider: 'Asteron',
			},
			{
				newExisting: 'New',
				lifeAssured: 0,
				product: 'Income',
				benefit: '123.00',
				notes: '',
				premium: '123.00',
				premiumValue: null,
				startDate: '2021-07-01T00:00:00',
				priority: 1,
				provider: 'Asteron',
			},
		],
	},
	{
		lifeAssured: 1,
		lifeAssuredName: 'Jane Smith',
		lifeAssuredList: [
			{
				newExisting: 'Replacement',
				lifeAssured: 1,
				product: 'Mortgage',
				benefit: '123.00',
				notes: 'Any notes here',
				premium: '123.00',
				premiumValue: null,
				startDate: null,
				priority: 1,
				provider: 'Asteron',
			},
		],
	},
];
