import { Component, OnDestroy, OnInit } from '@angular/core';
import { ObservableUtil } from '@util/observable.util';
import { Observable, Subject, throwError } from 'rxjs';
import { finalize, take, catchError, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-prompt-unsaved',
	templateUrl: './prompt-unsaved.component.html',
	styleUrls: ['./prompt-unsaved.component.scss'],
})
export class PromptUnsavedComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	saveFn: () => Observable<boolean>;

	closeFn: Subject<any>;

	cancelFn: () => Observable<boolean>;

	isSaving = false;

	constructor() {}

	ngOnInit(): void {}

	close(resolveValue = false): void {
		if(this.cancelFn && !this.isSaving && resolveValue){
			this.cancelFn().pipe(
				take(1),
				finalize(()=>this.closeFn.next(resolveValue))
			).subscribe()
		}else if (this.closeFn) {
			this.closeFn.next(resolveValue);
		}
	}

	save(): void {
		if (this.isSaving) {
			return;
		}

		if (this.saveFn) {
			this.isSaving = true;
			this.saveFn()
				.pipe(
					ObservableUtil.finalizeSuccess((result) => this.close(result)),
					catchError((err) => {
						this.close(false);
						return throwError(err);
					}),
					takeUntil(this.onDestroy$)
				)
				.subscribe();
		} else {
			this.closeFn.next(true);
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
