import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputChipsModule } from '@shared/components/input-chips/input-chips.module';
import { SharedModule } from '../../../shared/shared.module';
import { DashboardControlComponent } from './dashboard-control/dashboard-control.component';
import { DashboardResolverService } from './dashboard-resolver.service';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SimpleTableComponent } from './shared/simple-table/simple-table.component';
import { DashboardQuery } from './state/dashboard.query';
import { DashboardService } from './state/dashboard.service';
import { DashboardStore } from './state/dashboard.store';
import { WidgetHostDirective } from './widget-host/widget-host.directive';
import { ARowComponent } from './widgets/a/a-table/a-row/a-row.component';
import { ATableComponent } from './widgets/a/a-table/a-table.component';
import { AComponent } from './widgets/a/a.component';
import { AQuery } from './widgets/a/state/a.query';
import { AService } from './widgets/a/state/a.service';
import { AStore } from './widgets/a/state/a.store';
import { AasComponent } from './widgets/aas/aas.component';
import { AnnualApiStatsQuery } from './widgets/aas/state/aas.query';
import { AnnualApiStatsService } from './widgets/aas/state/aas.service';
import { AnnualApiStatsStore } from './widgets/aas/state/aas.store';
import { AasfComponent } from './widgets/aasf/aasf.component';
import { AnnualApiStatsFinancialYearQuery } from './widgets/aasf/state/aasf.query';
import { AnnualApiStatsFinancialYearService } from './widgets/aasf/state/aasf.service';
import { AnnualApiStatsFinancialYearStore } from './widgets/aasf/state/aasf.store';
import { AdvComponent } from './widgets/adv/adv.component';
import { AdviserStatsQuery } from './widgets/adv/state/adv.query';
import { AdviserStatsService } from './widgets/adv/state/adv.service';
import { AdviserStatsStore } from './widgets/adv/state/adv.store';
import { AptTableComponent } from './widgets/apt/apt-table/apt-table.component';
import { AptComponent } from './widgets/apt/apt.component';
import { APTQuery } from './widgets/apt/state/apt.query';
import { APTService } from './widgets/apt/state/apt.service';
import { APTStore } from './widgets/apt/state/apt.store';
import { AsryComponent } from './widgets/asry/asry.component';
import { ApiVsSubmittedYearStatsQuery } from './widgets/asry/state/asry.query';
import { ApiVsSubmittedYearStatsService } from './widgets/asry/state/asry.service';
import { ApiVsSubmittedYearStatsStore } from './widgets/asry/state/asry.store';
// import { AvsComponent } from './widgets/avs/avs.component';
// import { ApiVsSubmittedStatsQuery } from './widgets/avs/state/avs.query';
// import { ApiVsSubmittedStatsService } from './widgets/avs/state/avs.service';
// import { ApiVsSubmittedStatsStore } from './widgets/avs/state/avs.store';
import { BbComponent } from './widgets/bb/bb.component';
import { BookBreakdownQuery } from './widgets/bb/state/bb.query';
import { BookBreakdownService } from './widgets/bb/state/bb.service';
import { BookBreakdownStore } from './widgets/bb/state/bb.store';
import { BnbComponent } from './widgets/bnb/bnb.component';
import { BNBQuery } from './widgets/bnb/state/bnb.query';
import { BNBService } from './widgets/bnb/state/bnb.service';
import { BNBStore } from './widgets/bnb/state/bnb.store';
import { CBComponent } from './widgets/cb/cb.component';
import { CBQuery } from './widgets/cb/state/cb.query';
import { CBService } from './widgets/cb/state/cb.service';
import { CBStore } from './widgets/cb/state/cb.store';
import { EbcComponent } from './widgets/ebc/ebc.component';
import { EBCQuery } from './widgets/ebc/state/ebc.query';
import { EBCService } from './widgets/ebc/state/ebc.service';
import { EBCStore } from './widgets/ebc/state/ebc.store';
import { FgbbComponent } from './widgets/fgbb/fgbb.component';
import { FGBookBreakdownQuery } from './widgets/fgbb/state/fgbb.query';
import { FGBookBreakdownService } from './widgets/fgbb/state/fgbb.service';
import { FGBookBreakdownStore } from './widgets/fgbb/state/fgbb.store';
import { FggtcComponent } from './widgets/fggtc/fggtc.component';
import { FGGTCQuery } from './widgets/fggtc/state/fggtc.query';
import { FGGTCService } from './widgets/fggtc/state/fggtc.service';
import { FGGTCStore } from './widgets/fggtc/state/fggtc.store';
import { FggtdComponent } from './widgets/fggtd/fggtd.component';
import { FGGTDQuery } from './widgets/fggtd/state/fggtd.query';
import { FGGTDService } from './widgets/fggtd/state/fggtd.service';
import { FGGTDStore } from './widgets/fggtd/state/fggtd.store';
import { FgpscComponent } from './widgets/fgpsc/fgpsc.component';
import { FGPremiumStatsCommercialQuery } from './widgets/fgpsc/state/fgpsc.query';
import { FGPremiumStatsCommercialService } from './widgets/fgpsc/state/fgpsc.service';
import { FGPremiumStatsCommercialStore } from './widgets/fgpsc/state/fgpsc.store';
import { FgpsdComponent } from './widgets/fgpsd/fgpsd.component';
import { FGPremiumStatsDomesticQuery } from './widgets/fgpsd/state/fgpsd.query';
import { FGPremiumStatsDomesticService } from './widgets/fgpsd/state/fgpsd.service';
import { FGPremiumStatsDomesticStore } from './widgets/fgpsd/state/fgpsd.store';
import { FgpsltComponent } from './widgets/fgpslt/fgpslt.component';
import { FGPremiumStatsLeadTypeQuery } from './widgets/fgpslt/state/fgpslt.query';
import { FGPremiumStatsLeadTypeService } from './widgets/fgpslt/state/fgpslt.service';
import { FGPremiumStatsLeadTypeStore } from './widgets/fgpslt/state/fgpslt.store';
import { KgtComponent } from './widgets/kgt/kgt.component';
import { KGTQuery } from './widgets/kgt/state/kgt.query';
import { KGTService } from './widgets/kgt/state/kgt.service';
import { KGTStore } from './widgets/kgt/state/kgt.store';
import { LcComponent } from './widgets/lc/lc.component';
import { LCQuery } from './widgets/lc/state/lc.query';
import { LCService } from './widgets/lc/state/lc.service';
import { LCStore } from './widgets/lc/state/lc.store';
import { NblrComponent, NBLRQuery, NBLRService, NBLRStore } from './widgets/nblr';
import { MoComponent, MOQuery, MOService, MOStore } from './widgets/mo';
import { LraComponent } from './widgets/lra/lra.component';
import { LRAQuery } from './widgets/lra/state/lra.query';
import { LRAService } from './widgets/lra/state/lra.service';
import { LRAStore } from './widgets/lra/state/lra.store';
import { LrctComponent } from './widgets/lrct/lrct.component';
import { LRCTQuery } from './widgets/lrct/state/lrct.query';
import { LRCTService } from './widgets/lrct/state/lrct.service';
import { LRCTStore } from './widgets/lrct/state/lrct.store';
import { LrgtComponent } from './widgets/lrgt/lrgt.component';
import { LRGTQuery } from './widgets/lrgt/state/lrgt.query';
import { LRGTService } from './widgets/lrgt/state/lrgt.service';
import { LRGTStore } from './widgets/lrgt/state/lrgt.store';
import { LrgtfyComponent } from './widgets/lrgtfy/lrgtfy.component';
import { LRGTFYQuery } from './widgets/lrgtfy/state/lrgtfy.query';
import { LRGTFYService } from './widgets/lrgtfy/state/lrgtfy.service';
import { LRGTFYStore } from './widgets/lrgtfy/state/lrgtfy.store';
import { LrgtsgComponent } from './widgets/lrgtsg/lrgtsg.component';
import { LRGTSGQuery } from './widgets/lrgtsg/state/lrgtsg.query';
import { LRGTSGService } from './widgets/lrgtsg/state/lrgtsg.service';
import { LRGTSGStore } from './widgets/lrgtsg/state/lrgtsg.store';
import { MasComponent } from './widgets/mas/mas.component';
import { MonthlyApiStatsQuery } from './widgets/mas/state/mas.query';
import { MonthlyApiStatsService } from './widgets/mas/state/mas.service';
import { MonthlyApiStatsStore } from './widgets/mas/state/mas.store';
import { MgtComponent } from './widgets/mgt/mgt.component';
import { MGTQuery } from './widgets/mgt/state/mgt.query';
import { MGTService } from './widgets/mgt/state/mgt.service';
import { MGTStore } from './widgets/mgt/state/mgt.store';
import { MmgtComponent } from './widgets/mmgt/mmgt.component';
import { MMGTQuery } from './widgets/mmgt/state/mmgt.query';
import { MMGTService } from './widgets/mmgt/state/mmgt.service';
import { MMGTStore } from './widgets/mmgt/state/mmgt.store';
import { MswComponent } from './widgets/msw/msw.component';
import { MeetingStatsQuery } from './widgets/msw/state/msw.query';
import { MeetingStatsService } from './widgets/msw/state/msw.service';
import { MeetingStatsStore } from './widgets/msw/state/msw.store';
import { OsComponent } from './widgets/os/os.component';
import { OtherStatsQuery } from './widgets/os/state/os.query';
import { OtherStatsService } from './widgets/os/state/os.service';
import { OtherStatsStore } from './widgets/os/state/os.store';
import { FgpComponent } from './widgets/p/fgp/fgp.component';
import { IpComponent } from './widgets/p/ip/ip.component';
import { KpComponent } from './widgets/p/kp/kp.component';
import { LrpComponent } from './widgets/p/lrp/lrp.component';
import { MpComponent } from './widgets/p/mp/mp.component';
import { PComponent } from './widgets/p/p.component';
import { PQuery } from './widgets/p/state/p.query';
import { PService } from './widgets/p/state/p.service';
import { PStore } from './widgets/p/state/p.store';
import { PrimaryBaseWidgetComponent } from './widgets/primary-base-widget/primary-base-widget.component';
import { QasComponent } from './widgets/qas/qas.component';
import { QuarterlyApiStatsQuery } from './widgets/qas/state/qas.query';
import { QuarterlyApiStatsService } from './widgets/qas/state/qas.service';
import { QuarterlyApiStatsStore } from './widgets/qas/state/qas.store';
import { RmComponent } from './widgets/rm/rm.component';
import { RMQuery } from './widgets/rm/state/rm.query';
import { RMService } from './widgets/rm/state/rm.service';
import { RMStore } from './widgets/rm/state/rm.store';
import { SecondaryBaseWidgetComponent } from './widgets/secondary-base-widget/secondary-base-widget.component';
import { WeeklyApiStatsQuery } from './widgets/was/state/was.query';
import { WeeklyApiStatsService } from './widgets/was/state/was.service';
import { WeeklyApiStatsStore } from './widgets/was/state/was.store';
import { WasComponent } from './widgets/was/was.component';
import { EblrComponent } from './widgets/eblr/eblr.component';
import { EBLRQuery } from './widgets/eblr/state/eblr.query';
import { EBLRService } from './widgets/eblr/state/eblr.service';
import { EBLRStore } from './widgets/eblr/state/eblr.store';

@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        SharedModule,
        InputChipsModule,
    ],
    declarations: [
        DashboardComponent,
        DashboardControlComponent,
        WidgetHostDirective,
        RmComponent,
        PrimaryBaseWidgetComponent,
        SecondaryBaseWidgetComponent,
        LrgtComponent,
        LrgtsgComponent,
        LrgtfyComponent,
        PComponent,
        SimpleTableComponent,
        MpComponent,
        KgtComponent,
        LrpComponent,
        KpComponent,
        MgtComponent,
        LraComponent,
        AComponent,
        LrctComponent,
        AasComponent,
        AdvComponent,
        // AvsComponent, TAP1-3811: API Stats - Rolling 6 Months (Hide widget)
        AsryComponent,
        OsComponent,
        AasfComponent,
        BbComponent,
        WasComponent,
        MasComponent,
        QasComponent,
        ATableComponent,
        ARowComponent,
        CBComponent,
        MmgtComponent,
        LcComponent,
        NblrComponent,
        MoComponent,
        BnbComponent,
        MswComponent,
        EbcComponent,
        AptComponent,
        AptTableComponent,
        IpComponent,
        FggtdComponent,
        FggtcComponent,
        FgpComponent,
        FgbbComponent,
        FgpscComponent,
        FgpsdComponent,
        FgpsltComponent,
        EblrComponent,
    ],
    providers: [
        DashboardResolverService,
        DashboardStore,
        DashboardQuery,
        DashboardService,
        RMStore,
        RMQuery,
        RMService,
        LRGTStore,
        LRGTQuery,
        LRGTService,
        LRGTSGStore,
        LRGTSGQuery,
        LRGTSGService,
        LRGTFYStore,
        LRGTFYQuery,
        LRGTFYService,
        KGTStore,
        KGTQuery,
        KGTService,
        PStore,
        PQuery,
        PService,
        MGTStore,
        MGTQuery,
        MGTService,
        LRAStore,
        LRAQuery,
        LRAService,
        AStore,
        AQuery,
        AService,
        LRCTStore,
        LRCTQuery,
        LRCTService,
        AnnualApiStatsStore,
        AnnualApiStatsQuery,
        AnnualApiStatsService,
        AdviserStatsStore,
        AdviserStatsQuery,
        AdviserStatsService,
        // ApiVsSubmittedStatsStore,
        // ApiVsSubmittedStatsQuery,
        // ApiVsSubmittedStatsService,
        ApiVsSubmittedYearStatsStore,
        ApiVsSubmittedYearStatsQuery,
        ApiVsSubmittedYearStatsService,
        OtherStatsStore,
        OtherStatsQuery,
        OtherStatsService,
        AnnualApiStatsFinancialYearStore,
        AnnualApiStatsFinancialYearQuery,
        AnnualApiStatsFinancialYearService,
        BookBreakdownStore,
        BookBreakdownQuery,
        BookBreakdownService,
        WeeklyApiStatsStore,
        WeeklyApiStatsQuery,
        WeeklyApiStatsService,
        MonthlyApiStatsStore,
        MonthlyApiStatsQuery,
        MonthlyApiStatsService,
        QuarterlyApiStatsStore,
        QuarterlyApiStatsQuery,
        QuarterlyApiStatsService,
        CBStore,
        CBQuery,
        CBService,
        MMGTStore,
        MMGTQuery,
        MMGTService,
        LCStore,
        LCQuery,
        LCService,
        NBLRStore,
        NBLRQuery,
        NBLRService,
        MOStore,
        MOQuery,
        MOService,
        BNBStore,
        BNBQuery,
        BNBService,
        MeetingStatsStore,
        MeetingStatsQuery,
        MeetingStatsService,
        EBCStore,
        EBCQuery,
        EBCService,
        APTStore,
        APTQuery,
        APTService,
        FGGTDStore,
        FGGTDQuery,
        FGGTDService,
        FGGTCStore,
        FGGTCQuery,
        FGGTCService,
        FGBookBreakdownStore,
        FGBookBreakdownQuery,
        FGBookBreakdownService,
        FGPremiumStatsCommercialStore,
        FGPremiumStatsCommercialQuery,
        FGPremiumStatsCommercialService,
        FGPremiumStatsDomesticStore,
        FGPremiumStatsDomesticQuery,
        FGPremiumStatsDomesticService,
        FGPremiumStatsLeadTypeStore,
        FGPremiumStatsLeadTypeQuery,
        FGPremiumStatsLeadTypeService,
        EBLRStore,
        EBLRQuery,
        EBLRService,
    ]
})
export class DashboardModule {}
