import { appNotesMergeTag, appNotesMetaKey } from './app-notes.merge-tags';
import { Note, NoteContentState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/notes/state/note.model';

export class AppNoteMergeTagMapper {
	public static getMergeTagValues(data: Note | any) {
		const notes: NoteContentState = data?.noteContents;

		return appNotesMergeTag?.map((x) => {
			let value = '';
			if (x?.metaKey === appNotesMetaKey.notesPurpose) {
				value = this.wrapParagraph(notes?.purpose);
			}
			if (x.metaKey === appNotesMetaKey.notesBackground) {
				value = this.wrapParagraph(notes?.clientBackgroundCharacter);
			}
			if (x.metaKey === appNotesMetaKey.notesRecommendation) {
				value = this.wrapParagraph(notes?.recommendation);
			}
			if (x.metaKey === appNotesMetaKey.notesOtherInfo) {
				value = this.wrapParagraph(notes?.otherInformation);
			}
			return {
				...x,
				value,
			};
		});
	}

	public static wrapParagraph(content: string) {
		return content || '';
	}
}
