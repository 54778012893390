import { ProviderCommissionSettingState } from './provider-commission.model';

export class ProviderCommissionMapper {
	public static mapToView(data: ProviderCommissionSettingState[]) {
		return data;
	}

	public static sortToView(
		data: ProviderCommissionSettingState[],
		type: string
	) {
		return data;
	}

	public static updateOrder(list) {
		return list?.map(({ order, ...item }, i) => ({
			...item,
			providerCommissionOrder: i,
		}));
	}

	public static mapToUpsert(
		data: ProviderCommissionSettingState,
		staffId: number,
		typeCode: string
	) {
		return {
			referenceId: data?.referenceId || staffId,
			settingsId: data?.settingsId || null,
			provider: data?.provider || '',
			isTicked: data?.isTicked || false,
			isActive: data?.isActive || true,
			minUpfront: data?.minUpfront,
			maxUpfront: data?.maxUpfront,
			minRenewal: data?.minRenewal,
			maxRenewal: data?.maxRenewal,
			fee: data?.fee || 0,
			type: typeCode,
			providerCommissionOrder: data?.providerCommissionOrder || 0,
			fromGlobalSettings: data?.fromGlobalSettings,
		};
	}
}
