<form [formGroup]="form" novalidate>
  <div class="container-fluid clearfix px-0">
    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg col-12"></div>
      <div class="col-lg-auto col-12 d-flex action-btns justify-content-end">
        <span class="position-relative" *ngIf="isSaving$ | async">
          <app-manual-loader-mini [isLoading]="isSaving$ | async" class="p-0"></app-manual-loader-mini>
        </span>
        <ng-container *ngIf="!(isSaving$ | async)">
          <!-- Restriction for lead -->
          <ng-container *ngIf="isLead">
            <ng-container *ngIf="!(isEdit$ | async)">
              <ng-container *appRestrictTo="['FEL']">
                <span class="position-relative">
                  <button type="button" class="icon-btn" id="edit_{{ formID }}" (click)="edit()">
                    <i class="material-icons md-20 tap-text-color-h6">
                      edit
                    </i>
                  </button>
                </span>
              </ng-container>
              <ng-container *appRestrictTo="['FDL']">
                <span class="position-relative" *ngIf="!disableDelete">
                  <button type="button" class="icon-btn" id="delete_{{ formID }}" (click)="confirmDelete()">
                    <i class="material-icons md-20 tap-text-color-h6">
                      delete
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isEdit$ | async">
              <ng-container *appRestrictTo="['FAL', 'FEL']">
                <span class="position-relative" *ngIf="(isEdit$ | async) && !isSaving">
                  <button type="button" class="icon-btn" id="save_{{ formID }}" (click)="save()">
                    <i class="material-icons md-20 tap-text-color-h6">
                      save
                    </i>
                  </button>
                </span>
                <span class="position-relative">
                  <button
                    type="button"
                    class="icon-btn"
                    id="cancel_{{ formID }}"
                    [disabled]="isSaving$ | async"
                    (click)="cancel()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6">
                      cancel
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- Restriction for current client -->
          <ng-container *ngIf="!isLead">
            <ng-container *ngIf="!(isEdit$ | async)">
              <ng-container *appRestrictTo="['FEC']">
                <span class="position-relative">
                  <button type="button" class="icon-btn" id="edit_{{ formID }}" (click)="edit()">
                    <i class="material-icons md-20 tap-text-color-h6">
                      edit
                    </i>
                  </button>
                </span>
              </ng-container>
              <ng-container *appRestrictTo="['FDC']">
                <span class="position-relative" *ngIf="!disableDelete">
                  <button type="button" class="icon-btn" id="delete_{{ formID }}" (click)="confirmDelete()">
                    <i class="material-icons md-20 tap-text-color-h6">
                      delete
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isEdit$ | async">
              <ng-container *appRestrictTo="['FAC', 'FEC']">
                <span class="position-relative" *ngIf="(isEdit$ | async) && !isSaving">
                  <button type="button" class="icon-btn" id="save_{{ formID }}" (click)="save()">
                    <i class="material-icons md-20 tap-text-color-h6">
                      save
                    </i>
                  </button>
                </span>
                <span class="position-relative" *ngIf="isEdit$ | async">
                  <button
                    type="button"
                    class="icon-btn"
                    id="cancel_{{ formID }}"
                    [disabled]="isSaving$ | async"
                    (click)="cancel()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6">
                      cancel
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg-4 col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="professionalName_{{ formID }}">Name</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="name"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="professionalName_{{ formID }}"
              tabindex="7"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="professionalBusinessName_{{ formID }}">Business Name</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="businessName"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="professionalBusinessName_{{ formID }}"
              tabindex="8"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <span class="tap-required-field" *ngIf="professionalType.invalid">*</span>
            <label for="professionalType_{{ formID }}">Professional Type</label>
          </div>
          <div class="col-lg-8 col-12">
            <span class="custom-dropdown w-100">
              <select
                formControlName="professionalType"
                class="form-control theme-crm-field-disabled"
                placeholder="- -"
                id="professionalType_{{ formID }}"
                tabindex="9"
              >
                <option value=""></option>
                <option *ngFor="let d of professionalTypes" [value]="d.value">{{ d.display }}</option>
              </select>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg-4 col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="professionalPhone_{{ formID }}">Phone</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="phone"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="professionalPhone_{{ formID }}"
              tabindex="10"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="professionalEmail_{{ formID }}">Email</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="email"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="professionalEmail_{{ formID }}"
              tabindex="11"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="professionalPhysicalAddress_{{ formID }}">Physical Address</label>
          </div>
          <div class="col-lg-8 col-12">
            <app-address-input
              formControlName="physicalAddress"
              textboxClass="theme-crm-field-disabled"
              inputId="professionalPhysicalAddress_{{ formID }}"
              tabindex="12"
            ></app-address-input>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="addMode">
      <div class="form-row clearfix align-items-center mb-2">
        <div class="col-lg-12">
          Add a note
        </div>
        <div class="col-lg-12">
          <textarea
            class="form-control theme-crm-field-disabled"
            formControlName="note"
            rows="4"
            id="note_{{ formID }}"
          ></textarea>
        </div>
      </div>
    </ng-container>
  </div>
</form>

<ng-container *ngIf="!!secondaryProfessional && !addMode">
  <app-table-paginate-notes
    [isLead]="isLead"
    [hasAddNote]="true"
    [customerId]="secondaryProfessional?.customerID"
    [notes]="notes?.notes"
    [count]="notes?.count"
    [hasPaging]="true"
    [getFn$]="getNotes$"
    [addFn$]="addNotes$"
    [deleteFn$]="deleteNotes$"
  ></app-table-paginate-notes>
</ng-container>
