import { QueryEntity } from '@datorama/akita';
import { Row } from './investment.model';
import { InvestmentState, InvestmentStore } from './investment.store';
import { Injectable } from '@angular/core';
import {
  map,
  withLatestFrom,
  distinctUntilChanged,
  combineLatest,
  auditTime,
  shareReplay,
} from 'rxjs/operators';
import {
  columns,
  templateRow,
  EnhancedTableColumn,
} from '../investment-datatable.config';
import * as R from 'ramda';
import { Observable, of } from 'rxjs';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { InvestmentUiQuery } from './investment-ui.query';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { mapToLookupObject } from '../../../../../domain/dropdown-value/dropdown-value.model';
import sort from 'fast-sort';
import { util } from '../../../../../util/util';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Injectable()
export class InvestmentQuery extends QueryEntity<InvestmentState, Row> {
  private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
    map((x) =>
      R.map(
        (y) =>
          ViewDisplayValue.Map(
            y.StaffID.toString(),
            `${y.FirstName} ${y.LastName}`
          ),
        x
      )
    ),
    map((x) => (x ? x.sort((a, b) => a.display.localeCompare(b.display)) : x))
  ); // Advisers
  private IP$ = this.dropdownQuery.orderedChoices$('IP'); // Provider
  private IS$ = this.dropdownQuery.orderedChoices$('IS'); // Status
  private AT$ = this.dropdownQuery.orderedChoices$('AT'); // Activity Type
  private PCLE$ = this.dropdownQuery.orderedChoices$('PCLE'); // Lead Origin
  private IT$ = this.dropdownQuery.orderedChoices$('IT'); // Investment Type
  private IPIRR$ = this.dropdownQuery.orderedChoices$('IPIRR'); // PIR Rate

  columns$ = this.select((x) => x.columns);
  tableColumns$ = this.columns$
    .pipe(
			withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
      map(([tableColumns, adviserReworkFeature]) => {
        return tableColumns
					?.filter((x: any) => adviserReworkFeature ? (x != 'Adviser') : (x != 'Investment Adviser'))
          ?.map((col) => columns?.find((y) => y.metakey === col))
          ?.filter((x) => x !== undefined);
      }),
      withLatestFrom(this.select((x) => x.columnWidths)),
      map(([tableColumns, widths]) =>
        tableColumns?.map((column) => {
          const widthConf = widths
            ?.filter((x) => x)
            ?.find((width) => width.metakey === column.metakey);
          const newColumn = { ...column, $$id: column.name };
          if (widthConf) {
            return { ...newColumn, width: widthConf.width };
          } else {
            return newColumn;
          }
        })
      ),
      combineLatest(this.advisers$, this.IP$, this.IS$, this.IPIRR$),
      auditTime(0),
      combineLatest(this.AT$, this.PCLE$, this.IT$),
      auditTime(0),
      map(([[tableColumns, advisers, ip, is, ipirr], at, pcle, it]) => {
        return tableColumns?.map((c) => {
          switch (c.metakey) {
            case 'Adviser':
						case 'Investment Adviser':
            case 'Original Adviser':
              return this.fillUpChoices(advisers, c);
            case 'Provider':
              return this.fillUpChoices(ip, c);
            case 'Status':
              return this.fillUpChoices(is, c);
            case 'PIR Rate':
              return this.fillUpChoices(ipirr, c);
            case 'Lead Origin':
              return this.fillUpChoices(pcle, c);
            case 'Investment Type':
							return this.fillUpChoices(it, c);
            default:
              return c;
          }
        });
      })
    )
    .pipe(
      shareReplay(1),
      distinctUntilChanged((x, y) => R.equals(x, y))
    );
  hiddenTableColumns$ = this.columns$.pipe(
    map((tableColumns) => {
      return columns?.filter((x) => !tableColumns?.includes(x.metakey));
    })
  );

  sorts$ = this.uiQuery.select((x) => [{ dir: x.sort, prop: x.propSort }]);
  cellsLoading$ = this.uiQuery.select((state) => state.cellLoadingStatus);
  cellsEditing$ = this.uiQuery.select((state) => state.cellEditStatus);
  cellsTempvalue$ = this.uiQuery.select((state) => state.cellTempValue);
  rowsLoading$ = this.uiQuery.select((state) => state.rowLoadingStatus);
  isSearching$ = this.uiQuery.select((state) => state.isSearching);

  allAdvisers$ = this.blstaffQuery.allStaffsChoices$;
  templateRow$: Observable<Row> = of(templateRow);

  rows$: Observable<Row[]> = this.selectAll();
  hasRows$: Observable<boolean> = this.selectCount().pipe(map((x) => x > 0));

  count$ = this.select((x) => x.count);
  totalApi$ = this.select(x => x.totalAPI);
  searchForm$ = this.select((x) => x.searchForm).pipe(map((x) => x));
  isComplete$ = this.select((x) => x.isComplete);

  sortedRows$ = this.uiQuery
    .select((x) => [x.propSort, x.sort])
    .pipe(
      distinctUntilChanged((p, q) => R.equals(p, q)),
      combineLatest(this.rows$, this.tableColumns$),
      withLatestFrom(this.isSearching$),
      withLatestFrom(this.allAdvisers$),
      auditTime(0),
      map(
        ([
          [[[prop, sortDirection], rows, tableColumns], isSearching],
          allAdvisers,
        ]) => {
          if (sortDirection === '' || prop === '' || isSearching) {
            return rows;
          } else {
            const column = tableColumns?.find((x) => x.prop === prop);
            if (util.isNullOrEmpty(column)) {
              return rows;
            }
            const decorated = rows?.map<[number, any, number, Row]>((r) => {
							const adviserColumn = [
								'Adviser',
								'OriginalAdviser',
								'InvestmentAdviser',
							].includes(prop) ? allAdvisers : column.choices;
              const actualValue = column.sortValueGetter(
                r[prop],
                adviserColumn,
                r
              );
              return [
                this.spaceSortValueGetter(actualValue),
                R.defaultTo('', actualValue),
                r.CustomerID,
                r,
              ];
            });
            return sort(decorated)
              .by([
                { asc: (u) => u[0] },
                {
                  [sortDirection]: (u) =>
                    u[1] && isNaN(u[1]) ? u[1]?.toLowerCase() : u[1],
                } as any,
                { asc: (u) => u[2] },
              ])
              ?.map((x) => x[3]);
          }
        }
      )
    );

  getCustomerName = (customerServiceId: number) =>
    this.getEntity(customerServiceId).Name.value;

  constructor(
    protected dropdownQuery: DropdownValueQuery,
    protected blstaffQuery: BLStaffsQuery,
    protected investmentStore: InvestmentStore,
		protected businessConfigQuery: BusinessConfigQuery,
    public uiQuery: InvestmentUiQuery
  ) {
    super(investmentStore);
  }
  /** fill up choices and choices as object. */
  private fillUpChoices(
    choices: ViewDisplayValue[],
    column: EnhancedTableColumn
  ) {
    return {
      ...column,
      choices,
      choicesAsObject: mapToLookupObject(choices),
    };
  }

  /** Create a value usable as index for sorting.
   * Its only necessary to know if value is empty or not.
   * So if it is not empty, return 1 which is first in sort index.
   * And 2 if empty.
   */
  private spaceSortValueGetter(
    fieldValue: string | number | null | undefined
  ): 1 | 2 {
    let stringValue: string;
    if (util.isNullOrEmpty(fieldValue)) {
      stringValue = '';
    } else if (typeof fieldValue === 'string') {
      stringValue = fieldValue?.trim();
    } else {
      stringValue = fieldValue?.toString();
    }
    if (stringValue === '') {
      return 2;
    } else {
      return 1;
    }
  }
}
