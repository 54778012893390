import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StructureSoaStore, StructureSoaState } from './structure-soa.store';

@Injectable({ providedIn: 'root' })
export class StructureSoaQuery extends QueryEntity<StructureSoaState> {

  emailSettings$ = this.select((state) => state.emailSettings);

  statementOfAdviceEmailEnabled$ = this.select((state) => state.emailSettings?.isEnableEmailOption);

  constructor(protected store: StructureSoaStore) {
    super(store);
  }

}
