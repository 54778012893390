import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	Input,
	OnDestroy,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
	],
})
export class InputComponent<T>
	implements AfterViewInit, OnDestroy, ControlValueAccessor
{
	/**
	 * remove all attribute from host element. so we dont have a
	 * duplicate attribute when we supplied tabindex, type, size, and id
	 */
	@HostBinding('attr.tabindex') attrTabindex = null;
	@HostBinding('attr.type') attrType = null;
	@HostBinding('attr.size') attrSize = null;
	@HostBinding('attr.id') attrId = null;
	@HostBinding('attr.readonly') attrReadonly = null;
	@HostBinding('attr.appearance') attrAppearance = null;

	@ViewChild('input') input: ElementRef<HTMLInputElement>;

	@Input() value?: T;

	@Input() disabled = false;

	@Input() type = 'text';

	@Input() tabindex?: number | string;

	@Input() size = 'md';

	@Input() id?: string = '';

	@Input() placeholder? = '';

	@Input() required = false;

	@Input() readonly = false;

	@Input() appendText?: string;

	@Input() valueChangeEvent = 'change';

	@Input() prependText?: string;

	// angular material icons
	@Input() prependIcon?: string;

	@Input() appearance: 'bootstrap' | 'crt' = 'bootstrap';

	@Input() debounceTime = 0;

	@Input() inputClassname: string;

	@Input() isInvalid = false;

	change?: (value: T) => void;

	valueChangeUnlistener: () => void;

	constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2) {}

	ngAfterViewInit(): void {
		this.cdr.detectChanges();
		const sub = fromEvent(this.input.nativeElement, this.valueChangeEvent)
			.pipe(debounceTime(this.debounceTime))
			.subscribe((event) => this.onChange(event));
		this.valueChangeUnlistener = () => sub.unsubscribe();
	}

	ngOnDestroy(): void {
		this.valueChangeUnlistener?.();
	}

	get inputClass(): string {
		return `form-control form-control-${this.size} ${
			this.appearance === 'crt' ? 'crt-form-control' : ''
		} ${this.inputClassname ?? ''} ${
			!!this.isInvalid ? 'invalid-control' : ''
		}`;
	}

	private parseValueByType(value: string): string | number | null {
		switch (this.type) {
			case 'number':
				return isNaN(+value) ? null : +value;
			default:
				return value?.trim();
		}
	}

	writeValue(value: T): void {
		this.value = value;
		this.cdr.detectChanges();
	}

	registerOnChange(fn: (value: T) => void): void {
		this.change = fn;
	}

	registerOnTouched(): void {
		// throw new Error('Method not implemented.');
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.cdr.detectChanges();
	}

	onChange(e: Event): void {
		if (this.change) {
			// @ts-ignore-next
			this.value = this.parseValueByType(e.target.value);
			this.change(this.value);
			this.cdr.detectChanges();
		}
	}
}
