import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { environment as env } from '@environment';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
	logo = env.defaultLoginLogo;
	url: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private authService: AuthenticationService
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.pipe(
			take(1)
		).subscribe((param) => {
			if (param.url) {
				const [url, _] = param.url.split('?');
				this.url = url;
			}
		})
	}

	reload() {
		let redirectTo = '/admin/business';

		if (this.authService.isLoggedIn) {
			const loggedInIsTL = this.authService.user.IsTL;
			if(loggedInIsTL) {
				redirectTo = '/admin/business';
			} else {
				const companyCode = this.authService.user.CompanyCode;
				redirectTo = this.url.includes(companyCode) ? this.url : `/${companyCode}/dashboard`;
			}
		} else {
			redirectTo = '/login'
		}

		// const url = `${this.url || ''}?dt=${new Date().getTime()}`
		location.href = redirectTo;
	}
}
