import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { mapTo, take, tap } from 'rxjs/operators';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { KiwisaverService } from './states/kiwisaver.service';

const dropdownCodes: string[] = ['KP', 'KS', 'KFT', 'KO', 'PCLE', 'AT', 'KT'];

@Injectable()
export class KiwisaverResolver implements Resolve<boolean> {
  constructor(
    private dropdownValueService: DropdownValueService,
    private kiwisaverService: KiwisaverService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const dropdown$ =
      this.dropdownValueService.getDropdownValueList$(dropdownCodes);
    return forkJoin([dropdown$]).pipe(
      tap(() => this.kiwisaverService.getColumns().pipe(take(1)).subscribe()),
      tap(() =>
        this.kiwisaverService.getColumnWidths().pipe(take(1)).subscribe()
      ),
      mapTo(true)
    );
  }
}
