import {
	Component,
	OnInit,
	AfterContentInit,
	ContentChildren,
	QueryList,
	Input,
} from '@angular/core';
import { Observable } from 'rxjs';
import { SlideComponent } from './slide/slide.component';
import { delay, map, startWith } from 'rxjs/operators';
import { Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-slides',
	templateUrl: './slides.component.html',
	styleUrls: ['./slides.component.scss'],
})
export class SlidesComponent implements OnInit, AfterContentInit {
	@ContentChildren(SlideComponent) slides: QueryList<SlideComponent>;
	@Output() slideChange = new EventEmitter<number>();
	activeSlide: number = 0;
	isFirst = true;
	isLast = false;

	slides$: Observable<SlideComponent[]>;

	constructor() {}

	ngOnInit(): void {}

	ngAfterContentInit(): void {
		this.slides$ = this.slides.changes
			.pipe(startWith(''))
			.pipe(delay(0))
			.pipe(map(() => this.slides.toArray()));
	}

	onActiveSlideChange(active: number) {
		this.activeSlide = active;
		this.isFirst = active === 0;
		this.isLast = this.slides.length - 1 === active;
		this.slideChange.emit(active);
	}
}
