<div class="final-structure clearfix">
	<div class="final-structure__content">
		<ng-template #loading>
			<div class="w-100 h-100"></div>
		</ng-template>
		<ng-container *ngIf="isLoading$ | async as isLoading">
			<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
		</ng-container>
		<ng-container *ngIf="!(isLoading$ | async)">
			<ng-container *ngIf="hasFinalStructure$ | async">
				<form [formGroup]="form">
					<div class="form-row mb-3">
						<div class="col-lg-12">
							<div class="form-row">
								<div class="col-lg-3 col-12 font-weight-bold underwriting-question">
									What is the underwriting outcome?
								</div>
								<div *ngIf="underWritingOutcomeFormArray.length !== 0" class="col-lg-9 col-12" formArrayName="underwritingOutcome">
									<div *ngFor="let lifeAL of underWritingOutcomeFormArray.controls; let laIndex = index; let first = first" [formGroupName]="laIndex" class="row underwriting-items">
										<div class="col-lg-4 col-12 names">
											{{ convertLifeAssuredToName(lifeAL.controls['LifeAssured'].value) }}
										</div>
										<div class="col-lg-8 col-12">
											<label>
												<span class="crt-custom-dropdown underwriting-select">
													<select
														class="d-inline-block form-control crt-form-control"
														formControlName="Value"
														(change)="onChange($event)"
													>
														<option value="Standard Rates" hidden>Standard Rates</option>
														<option value="Standard Rates - With Medical Disclosure">Standard Rates - With Medical Disclosure</option>
														<option value="Standard Rates - No Medical Disclosure">Standard Rates - No Medical Disclosure</option>
														<option value="Offer of Terms">Offer of Terms</option>
													</select>
												</span>
											</label>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</form>
				<div
					class="final-structure__content__frequency mb-3 mb-lg-2 pr-0 pr-lg-3"
				>
					<label class="mr-3" for="SOA_PaymentFrequency"
						>Payment Frequency</label
					>
					<label>
						<span class="crt-custom-dropdown">
							<select
								id="SOA_PaymentFrequencyId"
								name="SOA_PaymentFrequency"
								class="d-inline-block form-control crt-form-control"
								(change)="updatePaymentFrequency()"
								[(ngModel)]="paymentFrequency"
								[class.invalid-value]="
									paymentFrequency === null || paymentFrequency === ''
								"
							>
								<option
									*ngFor="let f of paymentFrequency$ | async"
									[value]="f.value"
								>
									{{ f.display }}
								</option>
							</select>
						</span>
					</label>
				</div>

				<div class="clearfix mb-3">
					<button
						id="resetFinalStructureBtn"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 border-0 col-auto"
						(click)="sync()"
					>
						Reset
					</button>
				</div>
				<div class="clearfix">
					<div class="form-row mb-3">
						<div class="col-lg-12">
							<div class="tap-text-primary font-weight-bold label-font mb-3">
								Final Insurance Structure
							</div>
							<div class="cleafix">
								<div
									*ngFor="
										let insurance of proposedInsurance$ | async;
										let i = index
									"
								>
									<div class="form-row align-items-center">
										<div class="col-lg-12">
											<div
												class="form-row align-items-center justify-content-between mb-2"
											>
												<div class="col-lg-3">
													<label
														class="provider-name font-weight-bold"
														*ngIf="insurance?.provider"
													>
														{{ insurance?.provider }}:</label
													>
												</div>

												<div
													*ngIf="underWritingOutcomeIfHasOOT"
													class="col-lg d-flex align-items-center"
												>
													<div class="form-row">
														<div class="col-lg-auto">
															<ng-template #uploadOptionModal>
																<button
																	id="closeUploadModalBtn"
																	class="tap-btn tap-btn-outline modal-btn-close"
																	(click)="optionModalRef.hide()"
																>
																	<span class="material-icons"> close </span>
																</button>
																<div class="modal-header">
																	<h4 class="modal-title pull-left">
																		Attach Terms
																	</h4>
																</div>
																<div class="modal-body">
																	<div class="clearfix py-3">
																		<div class="d-flex justify-content-center">
																			<button
																				id="uploadOfferOfTermsBtn"
																				type="button"
																				class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-2"
																				(click)="
																					uploadOfferOfTerms(insurance);
																					optionModalRef.hide()
																				"
																			>
																				Upload New
																			</button>
																			<button
																				id="linkOfferOfTermsBtn"
																				type="button"
																				class="tap-btn tap-btn-outline tap-btn--shadow px-4"
																				(click)="
																					linkDocument(insurance);
																					optionModalRef.hide()
																				"
																			>
																				Link Document
																			</button>
																		</div>
																	</div>
																</div>
															</ng-template>

															<button
																id="attachTermsBtn"
																class="tap-btn tap-btn-outline tap-btn--shadow px-4 border-0 col-auto position-relative"
																(click)="
																	openUploadOptionModal(
																		uploadOptionModal,
																		insurance
																	)
																"
															>
																Attach terms
															</button>
														</div>
														<div
															class="col-lg font-weight-bold text-truncate pt-1"
														>
															{{ insurance?.documentTerm?.value }}
														</div>
													</div>
												</div>
												<div class="col-12 col-lg-1 align-self-end mb-2">
													<button
														id="editInsuranceBtn_{{ i }}"
														type="button"
														class="icon-btn h-auto w-auto px-1"
														(click)="editProduct(insurance)"
													>
														<i class="material-icons md-16"> edit </i>
													</button>
													<button
														id="deleteInsuranceBtn_{{ i }}"
														type="button"
														class="icon-btn w-auto h-auto px-1"
														(click)="deleteCurrentInsurance(insurance.cRTId)"
													>
														<i class="material-icons md-16"> delete </i>
													</button>
												</div>
											</div>
										</div>
									</div>
									<div class="form-row mb-2">
										<div class="col-12 col-lg-12">
											<div class="form-row mb-2">
												<div class="col-12 col-lg-3 mb-2">
													<label class="font-weight-bold">Life Assured</label>
												</div>
												<div class="col-12 col-lg-3 mb-2">
													<label class="font-weight-bold">Product</label>
												</div>
												<div class="col-12 col-lg-3 mb-2">
													<label class="font-weight-bold">Benefit</label>
												</div>
												<div class="col-12 col-lg-3 mb-2">
													<label class="font-weight-bold">Premium</label>
												</div>
											</div>
											<div
												class="form-row"
												*ngFor="
													let p of insurance?.lifeAssured;
													let ip = index;
													let l = last
												"
											>
												<div
													class="col-12 col-lg-3 mb-2"
													[class.border-bottom]="!l"
												>
													<input
														class="crt-form-control form-control"
														placeholder="Life Assured"
														type="text"
														[value]="convertLifeAssuredToName(p?.lifeAssured)"
														disabled
													/>
												</div>
												<div
													class="col-12 col-lg-3 mb-2"
													[class.border-bottom]="!l"
												>
													<input
														class="crt-form-control form-control"
														placeholder="Product"
														type="text"
														value="{{ p?.product }}"
														disabled
													/>
												</div>
												<div
													class="col-12 col-lg-3 mb-2"
													[class.border-bottom]="!l"
												>
													<ng-container
														*ngIf="allowDropdown(p.product); else showInput"
													>
														<input
															class="crt-form-control form-control"
															placeholder="Benefit"
															type="text"
															value="{{ p.benefit | currency }}{{
																allowDropdown(p.product) ? ' excess' : ''
															}}"
															disabled
														/>
													</ng-container>
													<ng-template #showInput>
														<input
															class="crt-form-control form-control"
															placeholder="Benefit"
															type="text"
															value="{{ p.benefit | currency }}{{
																showPerMonth(p.product) ? '/month' : ''
															}}"
															currencyMask
															disabled
														/>
													</ng-template>
												</div>
												<div
													class="col-12 col-lg-3 mb-2"
													[class.border-bottom]="!l"
												>
													<div class="dollar-icon">
														<input
															class="crt-form-control form-control"
															placeholder="Premium"
															type="text"
															value="{{ p?.premium }}"
															appCurrencyFormatter
															disabled
														/>
														<i>$</i>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="col-12">
											<div class="form-row mb-2">
												<div class="col-0 col-lg-6"></div>
												<div
													class="col-12 col-lg-3 text-lg-right d-lg-flex justify-content-end align-items-center"
												>
													<label
														for=""
														class="font-weight-bold tap-text-primary"
														>Policy Fee</label
													>
												</div>
												<div class="col-12 col-lg-3">
													<div class="dollar-icon">
														<input
															class="crt-form-control form-control"
															placeholder="Policy Fee"
															type="text"
															[(ngModel)]="insurance.policyFee"
															[ngModelOptions]="{ standalone: true }"
															currencyMask
															disabled
														/>
														<i>$</i>
													</div>
												</div>
											</div>
											<div class="form-row mb-2">
												<div class="col-0 col-lg-6"></div>
												<div
													class="col-12 col-lg-3 text-lg-right d-lg-flex justify-content-end align-items-center"
												>
													<label
														for=""
														class="font-weight-bold tap-text-primary"
													>
														Total Premium
													</label>
												</div>
												<div class="col-12 col-lg-3">
													<div class="dollar-icon">
														<input
															appCurrencyFormatter
															class="crt-form-control form-control"
															placeholder="Premium"
															type="text"
															value="{{
																getSumOfLifeAssuredList(insurance) || 0
															}}"
															disabled
														/>
														<i>$</i>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										class="row mb-2"
										*ngFor="
											let d of insurance?.policyDocuments;
											let docIndex = index
										"
									>
										<div class="col-12 col-lg-auto word-break font-weight-bold">
											<div
												id="downloadPolicyDocumentBtn_{{ i }}_{{ docIndex }}"
												class="d-flex cursor-pointer"
												(click)="downloadLink(d.referenceId)"
											>
												<i
													class="material-icons md-16 tap-text-color-h6 mr-2 mb-2"
													>description</i
												>
												<span>{{ d.value }}</span>
											</div>
										</div>
									</div>

									<div class="form-row line mb-3"></div>
								</div>

								<div class="form-row my-4">
									<div class="col-12">
										<button
											id="addInsuranceBtn"
											type="button"
											class="tap-btn tap-btn-outline tap-btn--shadow px-4 border-0 col-auto"
											(click)="addProduct()"
										>
											Add New +
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>

	<ng-container *ngIf="!(isLoading$ | async)">
		<hr />

		<div class="existing-policy-structure">
			<div class="form-row mb-3">
				<div class="col-lg-12">
					<div class="tap-text-primary font-weight-bold label-font mb-3">
						Existing Policy Structure
					</div>
				</div>
			</div>
			<ng-container *ngIf="isExistingPolicyLoading$ | async">
				<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
			</ng-container>
			<ng-container *ngIf="!(isExistingPolicyLoading$ | async)">
				<ng-container
					*ngIf="
						(existingPolicies$ | async)?.length > 0;
						else showNoDataExistingPolicy
					"
				>
					<app-existing-policy-structure
						[existingPolicies$]="existingPolicies$"
						[lifeAssuredDropdownRaw]="lifeAssured$ | async"
						[deceasedSCI]="deceasedSCI$ | async"
						[transferedSCI]="transferedSCI"
						[product]="LRPR$ | async"
						[benefit]="APCRTB$ | async"
						[outcomes]="APCRTFSCCO$ | async"
					></app-existing-policy-structure>
				</ng-container>
				<ng-template #showNoDataExistingPolicy>
					No data to display.
				</ng-template>
			</ng-container>
		</div>
	</ng-container>
</div>
