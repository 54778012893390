import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, iif, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { DeclarationSettingState } from 'src/app/modules/crt-settings/declaration-settings/declaration-template-settings/state/declaration-settings.model';
import { ServiceAdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { DeclarationState } from 'src/app/shared/models/client-review-template/declaration/declaration.model';
import {
	DocumentModel,
	DocumentModelState,
	DocumentTypes,
	SignatureTypes,
} from 'src/app/shared/models/documents/document.model';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../util/util';
import { DeclarationDocumentMapper } from '../../states/declaration/declaration.mapper';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';

export const declarationPdfOptions = {
	FileName: 'DECLARATION',
	DPI: '120',
};
@Injectable()
export class DeclarationService extends ClientReviewTemplateService {
	declaration$ = this.query.declaration$;
	declarationFormValue$ = this.query.declarationFormValue$;
	declarationDocument$ = this.query.declarationDocument$;
	declarationEmailSettings$ = this.query.declarationEmailSettings$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getDeclarationPdfOptions() {
		return declarationPdfOptions;
	}

	getDeclarationDocument(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setDeclarationDocument(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getDeclarationEmailSettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setDeclarationEmailSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getDeclarationDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getDeclaration(adviceProcessId) {
		const endpoint = `crt/${adviceProcessId}/D`;
		return this.api.get<DeclarationState[]>(endpoint).pipe(
			tap((x) => {
				applyTransaction(() => {
					const state =
						!!x && x?.length > 0
							? objectUtil.mapPascalCaseToCamelCase(x[0])
							: {
									cRTId: 0,
									adviceProcessId: this.query.getValue().adviceProcessId,
									document: { referenceId: null, value: null },
									signatures: [],
							  };
					this.store.setDeclaration(state);
				});
			}),
			catchError(() => of([]))
		);
	}

	addDeclaration(declaration) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId: this.query.getValue().adviceProcessId,
			sectionCode: 'D',
			signatures: declaration?.signatures?.map((x) =>
				objectUtil.mapCamelCaseToPascalCase(x)
			),
		});
		delete body.CRTId;
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDeclaration({ ...declaration, cRTId: +x });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateDeclaration(declaration) {
		const endpoint = `crt/${declaration?.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId: this.query.getValue().adviceProcessId,
			sectionCode: 'D',
			signatures: declaration?.signatures?.map((x) =>
				objectUtil.mapCamelCaseToPascalCase(x)
			),
		});
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDeclaration(objectUtil.mapPascalCaseToCamelCase(body));
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getSignature(documentId) {
		const endpoint = `documents/${documentId}`;
		return this.api.get<DocumentModel>(endpoint).pipe(catchError(() => EMPTY));
	}

	addSignature(signature) {
		const endpoint = `documents`;
		const body = {
			ReferenceId: this.query.getValue().adviceProcessId,
			Document: signature?.split(',')[1],
			FileName: 'sig' + this.query.getValue().adviceProcessId + '.png',
			Type: SignatureTypes.Declaration,
		};
		return this.api.post<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	updateSignature(documentId, signature) {
		const endpoint = `documents/${documentId}/document-link`;
		const body = {
			Document: signature?.split(',')[1],
			DocumentID: documentId,
		};
		return this.api.put<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	updateDeclarationDocument(documentId, document) {
		const endpoint = `documents/${documentId}/document-link`;
		const body = {
			Document: document,
			DocumentID: documentId,
		};
		return this.api.put<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	getAdviceProcess(adviceProcessId) {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setAdviceProcess(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	documentMapper(data) {
		const docs = data.Documents;
		docs?.forEach((doc) => {
			if (typeof doc.Value !== 'number') {
				doc.Value = doc.Value?.DocumentID;
			}
		});
		return docs;
	}

	sendEmail(data) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);
		const adviceProcessId = this.query.getValue().adviceProcessId;
		let dd: DeclarationDocument;
		return this.api
			.get<DeclarationDocument[]>(`crt/${adviceProcessId}/DD`)
			.pipe(
				map((x) => {
					const res = x?.map(
						(y) => objectUtil.mapPascalCaseToCamelCase(y) as DeclarationDocument
					);
					dd = res.length > 0 ? res[0] : null;
					return dd;
				}),
				// Update or Add Document for Disclosure Document
				mergeMap((x) =>
					iif(
						() => !!x && !!x?.documentID,
						// Update the current document
						this.api.put(`documents/${x?.documentID}/document-link`, {
							Document: d.Document,
							DocumentID: x?.documentID,
						}),
						// Upload new document
						this.api.post(`documents`, {
							ReferenceId: adviceProcessId,
							Document: d.Document,
							FileName: 'Declaration Document.pdf',
							Type: DocumentTypes.Declaration,
						})
					)
				),
				// Create New or update Declaration document
				mergeMap((x) =>
					iif(
						() => !!dd,
						// Update the current Declaration Document
						this.api.put(`crt/${dd?.cRTId}`, {
							AdviceProcessId: adviceProcessId,
							SectionCode: 'D',
							DocumentID: dd?.documentID,
							ParentCRTId: 0,
							CRTId: dd?.cRTId,
						}),
						// Create new Declaration Document
						this.api.post(`crt`, {
							AdviceProcessId: adviceProcessId,
							SectionCode: 'D',
							DocumentID: x,
							ParentCRTId: 0,
						})
					)
				),
				catchError(() => EMPTY)
			);
	}

	setDeclarationFormValue(d: DeclarationState) {
		const data = this.query.getValue().declaration;
		applyTransaction(() => {
			this.store.setDeclarationFromValue({
				...data,
				...d,
			});
		});
	}
}

export interface DeclarationDocument {
	documentID: number;
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	status: number;
	createDateTime: string;
	createdByStaffId: number;
	createdByStaffLevel: number;
	modifiedDateTime: string;
	modifiedByStaffId?: any;
}
