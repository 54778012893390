import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { KiwiScopeOfServiceSettingsService } from './state/kiwi-scope-of-service-settings.service';

@Component({
  selector: 'app-kiwi-scope-of-service-settings',
  templateUrl: './kiwi-scope-of-service-settings.component.html',
  styleUrls: ['./kiwi-scope-of-service-settings.component.scss']
})
export class KiwiScopeOfServiceSettingsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);

  constructor(private sosSettingsService: KiwiScopeOfServiceSettingsService) { }

  ngOnInit(): void {}

	prepData() {}

	ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
