import { Query } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { GlobalSearchState, GlobalSearchStore } from "./global-search.store";

@Injectable()
export class GlobalSearchQuery extends Query<GlobalSearchState> {
	constructor(protected store: GlobalSearchStore) {
		super(store);
	}

  recentSearch$ = this.select(s => s.recentSearch);
}