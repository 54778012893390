import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { objectUtil } from '../../../../util/util';
import { MortgageSettingsQuery } from '../../state/mortgage-settings.query';
import { MortgageSettingsStore } from '../../state/mortgage-settings.store';
import { ScopeOfServiceState } from './scope-of-service.model';

@Injectable({
	providedIn: 'root',
})
export class ScopeOfServiceService {
  scopeOfService$ = this.query.scopeOfService$;

  constructor(
    private api: ApiService,
    private store: MortgageSettingsStore,
    private query: MortgageSettingsQuery
  ) {}

  getScopeOfService(referenceId: number, settingsCode: string) {
    const endpoint = `crt/settings/${referenceId}/${settingsCode}`;
    return this.api.get<ScopeOfServiceState>(endpoint).pipe(
      tap((data) =>
        applyTransaction(() => {
          const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
          this.store.setScopeOfService(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateScopeOfService(scopeOfService: ScopeOfServiceState) {
    const endpoint = `crt/settings/${scopeOfService.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(scopeOfService);
    return this.api.put<ScopeOfServiceState>(endpoint, body).pipe(
      tap((data) =>
        applyTransaction(() => {
          const state = data ? objectUtil.mapPascalCaseToCamelCase(scopeOfService) : null;
          this.store.setScopeOfService(state);
        })
      )
    );
  }
}
