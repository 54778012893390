<app-dashboard-control></app-dashboard-control>

<div class="dashboard-wrapper px-4">
  <!-- widgets -->
  <div class="grid widget-grid">
    <ng-container *ngIf="hasAdviserFilter$ | async">
      <ng-template appWidgetHost *ngFor="let c of widgets$ | async; trackBy: trackWidget;" [widget]="c"></ng-template>
    </ng-container>
  </div>
</div>
