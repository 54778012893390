<div
	class="form-row align-items-center mb-2"
	*ngFor="let d of documents; trackBy: trackByDocument"
>
	<div class="col-md-4">{{ d?.field }}</div>
	<div class="col-md d-flex align-items-center">
		<!-- Online Advice Process Buttons -->
		<ng-container *ngIf="hasMOAT && adviceProcess?.isOnline; else offlineMode">
			<ng-container *ngIf="!!d.value; else onlineProcessNoDocument">
				<button
					type="button"
					class="tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0"
					(click)="
						downloadFile(d?.value?.documentID, d?.field, d.value.documentName)
					"
				>
					View
				</button>
				<button
					*ngIf="
						d?.field !== moatDocumentFields.FactFindDeclaration &&
						d?.field !== moatDocumentFields.ROA &&
						isEditable
					"
					type="button"
					class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
					(click)="openUploadModal('Replace', d?.field)"
				>
					Replace
				</button>
				<button
					*ngIf="
						(d?.field === moatDocumentFields.FactFindDeclaration ||
							d?.field === moatDocumentFields.ROA) &&
						isEditable
					"
					type="button"
					class="tap-btn tap-btn--default mr-1 w-40 px-sm-0"
					[routerLink]="goToCrtPage(d?.field)"
				>
					{{ d?.field === moatDocumentFields.ROA ? "Open" : "Edit" }}
				</button>
			</ng-container>

			<ng-template #onlineProcessNoDocument>
				<ng-container *appRestrictTo="['FEAP']">
					<ng-container>
						<ng-container *ngIf="adviceProcess.isStarted && !!d?.show">
							<ng-container *appRestrictTo="['FCRT']">
								<ng-container
									*ngIf="
										(d?.field === moatDocumentFields.SignedLoanStructure ||
											d?.field === moatDocumentFields.SettlementStatement ||
											d?.field === moatDocumentFields.LetterOfOffer) &&
											isEditable;
										else defaultNoDocumentButtons
									"
								>
									<ng-container *ngTemplateOutlet="offlineMode"></ng-container>
								</ng-container>
								<ng-template #defaultNoDocumentButtons>
									<button
										*ngIf="isEditable"
										type="button"
										class="tap-btn tap-btn--default mr-1 w-40 px-sm-0"
										[routerLink]="goToCrtPage(d?.field)"
									>
										Edit
									</button>
								</ng-template>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-template>
		</ng-container>

		<!-- Offline Advice Process Buttons -->
		<ng-template #offlineMode>
			<div class="w-100">
				<app-offline-documents
					[d]="d"
					[isEditable]="isEditable"
					(doDownload)="
						downloadFile(d?.value?.documentID, d?.field, d.value.documentName)
					"
					(doUpload)="openUploadModal('Upload New', d?.field)"
					(doLinkDocument)="linkDocument(d?.field)"
				>
				</app-offline-documents>
			</div>
		</ng-template>
		<!--<ng-template #offlineNoDocument>
        <button
          type="button"
          class="tap-btn tap-btn--default w-40 mr-1"
          (click)="openUploadModal('Upload New', d?.field)"
        >
          Upload New
        </button>
        <ng-container *ngIf="isEditable">
          <button
            [disabled]="d.isUploading"
            type="button"
            class="tap-btn tap-btn--default w-40"
            title="Link Document"
            (click)="linkDocument(d?.field)"
          >
            Link Document
          </button>
        </ng-container>
      </ng-template>-->
	</div>
</div>
