import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, iif, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { EmailSettingsModel } from 'src/app/shared/models/_general/email-settings.model';
import { ScopeOfServiceSettingsService } from '../state/scope-of-service-settings.service';
import { ScopeOfServiceSettingsMapper } from '../state/scope-of-service-settings.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { convertUtil, objectUtil } from 'src/app/util/util';

@Component({
  selector: 'app-scope-of-service-email-settings',
  templateUrl: './scope-of-service-email-settings.component.html'
})
export class ScopeOfServiceEmailSettingsComponent implements OnInit {

  @Input() staffId: number;
  @Input() emailSettings: EmailSettingsModel
  @Input() mergeTags$: Observable<MergeTagState[]>;

  emailSettings$ = this.scopeOfSettingsService.emailSettings$;
  isLoading = false;
  bodyContent = '<p></p>';
  bodyId: number;
  shortCodes: object;

  constructor(
    private scopeOfSettingsService: ScopeOfServiceSettingsService
  ) { }

  ngOnInit(): void {
    this.prepData();
  }

  prepData() {
    combineLatest([this.emailSettings$, this.mergeTags$])
      .pipe(
        filter(([data, mt]) => !!data),
        tap(
          ([data, mt]) => {
            (this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
					}
        ),
        tap(([data, mt]) => (this.bodyId = +data?.body || 0)),

        map(([data, mt]) =>
          ScopeOfServiceSettingsMapper.mapEmailSettingsData(data)
        ),
        mergeMap((data) => {
					return iif(
            () => R.isNil(data?.body),
            of(null),
            this.scopeOfSettingsService.getFile(+data.body)
          )
				}),
        map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
        mergeMap((res) =>
          iif(
            () => res?.documentLink,
            this.scopeOfSettingsService.getDocumentFromURL(res?.documentLink),
            of(null)
          )
        ),
        tap((data) => {
          if (data) {
            this.bodyContent = data;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  save(emailSettings) {
    this.isLoading = true;
    of(emailSettings)
      .pipe(
        mergeMap((currentTemplate) => {
          return convertUtil.convertToBase64(currentTemplate?.bodyContent)
        }),
        withLatestFrom(of(this.bodyId)),
        map(([currentTemplate, bodyId]) =>
          ScopeOfServiceSettingsMapper.mapStaffEmailBody(
            currentTemplate,
            bodyId,
            0
          )
        ),
        concatMap((data) =>
          iif(
            () => R.isNil(data.documentId),
            this.scopeOfSettingsService.newFileUpload(data).pipe(catchError((err) => of(err))),
            this.scopeOfSettingsService.updateFileUpload(data)
          )
        ),
        withLatestFrom(of(this.bodyId)),
        concatMap(([data, body]) => {
          const isNewBody = body === 0 || !body;
          const newBodyId = isNewBody ? data : body;
          const formValue = ScopeOfServiceSettingsMapper.mapEmailSettingsToUpsert({
            ...emailSettings,
            referenceId: 0,
            body: newBodyId,
          });
          this.bodyId = newBodyId;
          return this.scopeOfSettingsService.updateEmailSettings(formValue);
        }),
        take(1),
        finalize(() => (this.isLoading = false)),

      )
      .subscribe();
  }

}
