import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	CanDeactivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'ramda';

import { BaseCrtMortgageComponent } from './base-crt-mortgage.component';

@Injectable()
export class CrtMortgageCanDeactivateGuard
	implements CanDeactivate<BaseCrtMortgageComponent>
{
	canDeactivate(
		component: BaseCrtMortgageComponent
	): Observable<boolean> | boolean {
		return component.saveComponentFunction().pipe(
			switchMap(() => of(true)),
			catchError(() => of(true))
		);
	}
}
