import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { WeeklyApiStatsStore } from './was.store';
import { WeeklyApiStat } from './was.model';


@Injectable()
export class WeeklyApiStatsService {

  private readonly endpoint = 'widgets?type=WAS';

  constructor(
    private api: ApiService,
    private store: WeeklyApiStatsStore
  ) { }

  /**
   * Fetch and store Weekly Api Stats data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetWeeklyApiStats(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post<WeeklyApiStat>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.WAStats = res;
      })),
      err => applyTransaction(() => {
        this.store.setError('Error fetching data!');
        this.store.setLoading(false);
      }),
      () => this.store.setLoading(false)
    );
  }
}
