<div class="manage-users fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Manage Users</h1>
	</div>

	<div class="row service-tabs data-import px-0 mx-0">
		<div class="create-pd-container" *ngIf="CanViewPDManagement && showPDManagement">
				<button
					id="createPDBtn"
					type="button"
					class="btn ui-btn-white px-5"
					(click)="createPD()"
				>
					+ Create PD
				</button>
		</div>
		<tabset class="tab-container w-100">
			<tab heading="Manage Users" (selectTab)="onSelectTab($event)">
				<app-manage-users-list
					[isTapLevel]="isTapLevel"
					[secGroups$]="secGroups$"
					[userInfo$]="userInfo$"
					[saveColumnSettingsFn$]="saveColumnSettingsFn$"
					[saveColumnWidthSettingsFn$]="saveColumnWidthSettingsFn$"
				>
				</app-manage-users-list>
			</tab>
			<ng-container *ngIf="CanViewPDManagement">
				<tab heading="PD Management" (selectTab)="onSelectTab($event)">
					<app-manage-pd-tracking
						#managePdTrackingComponent
					></app-manage-pd-tracking>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
