import { BusinessConfig } from '@domain/business-config/business-config.model';
import {
	MergeTagState,
	MergeTagTypeCode,
} from '@models/client-review-template/merge-tags/merge-tags.model';
import {
	declarationMetaKey,
	repeatListNotContinuing,
} from '@models/client-review-template/merge-tags/crt-mortgage/declaration/declaration.merge-tags';
import { removeEmptyParagraphs } from '@shared/converter/content-merge-tags';
import { MergeTagsMapper } from '@models/client-review-template/merge-tags/merge-tags.mapper';
import { ResidencyStatus } from '@models/client-review-template/people/people-details.model';
import { PeopleState } from '@shared/models/client-review-template/people/people.model';
import * as R from 'ramda';

export const cffClasses = {
	personalDetails: 'cff-personal-details',
	employmentDetails: 'cff-employment-details',
	incomeDetails: 'cff-income-details',
	firstDuplicate: 'cff-dup-first-table',
	secondDuplicate: 'cff-dup-second-table',
	secondaryIncome: 'secondary-income',
	allowEmptyTableRows: 'allow-empty-table-rows',
};

export class ClientFactFindMapper {
	/**
	 *
	 * @param mergeTags merge tags available
	 * @param content html template content
	 * @returns string with Personal Details logic applied
	 */
	public static applyPersonalDetailsRules(
		content: string,
		mergeTags: MergeTagState[]
	) {
		let newStr = content;
		// Country of Origin
		const residencyValues = (
			mergeTags?.find(
				(tag) => tag.metaKey === declarationMetaKey.peopleResidency?.toString()
			).value as Array<string>
		)?.filter((value) => !R.isEmpty(value));
		const noOfCitizens = residencyValues?.filter(
			(val) => val === ResidencyStatus.nzCitizen
		)?.length;

		if (noOfCitizens === residencyValues.length) {
			newStr = content?.replace(/countryOfOrigin/g, 'countryOfOrigin-hide');
		}
		return newStr;
	}

	public static applyIncomeRules(content: string, mergeTags: MergeTagState[]) {
		let newStr = content;

		// Rental Income
		const rentalIncomeList = (
			mergeTags?.find(
				(tag) => tag.metaKey === declarationMetaKey.netRentalIncome?.toString()
			).value as Array<string>
		)?.filter((value) => !R.isEmpty(value));

		if (rentalIncomeList?.length === 0) {
			newStr = content?.replace(/rental-income/g, 'rental-income pdf-hidden');
		}

		// Other Income
		const otherIncomeList = (
			mergeTags?.find(
				(tag) => tag.metaKey === declarationMetaKey.otherIncomeType?.toString()
			).value as Array<string>
		)?.filter((value) => !R.isEmpty(value));

		if (otherIncomeList?.length === 0) {
			newStr = newStr?.replace(/other-income/g, 'other-income pdf-hidden');
		}

		return newStr;
	}

	public static replaceColorClasses(content: string, config: BusinessConfig) {
		return content?.replace(
			/color=""/g,
			`style="color: ${config.PrimaryColor}"`
		);
	}

	public static replaceCheckboxes(content: string, mergeTags: MergeTagState[]) {
		const notContinuingMergeTags = mergeTags?.filter((tag) =>
			tag.metaKey.includes(declarationMetaKey.notContinuing?.toString())
		);
		let newStr = content;

		const processMergeTags = (tag: MergeTagState) => {
			if (![...repeatListNotContinuing].includes(tag.metaKey)) {
				// update checkboxes excluding merge tags inside repeat table row
				const newValue =
					tag.value[0]?.toString() === 'True' ? 'checked="true"' : '';
				const elemId = `id="${tag.metaKey}"`;
				// remove checked status
				newStr = newStr?.replace(`${elemId} checked="true"`, elemId);
				// re-apply checked status
				newStr = newStr?.replace(elemId, `${elemId} ${newValue}`);
			}
		};
		if (
			!R.isNil(notContinuingMergeTags) &&
			!R.isEmpty(notContinuingMergeTags)
		) {
			R.forEach(processMergeTags, notContinuingMergeTags);
		}

		// Check for other checkboxes inside repeat table row
		newStr = this.tableRowCheckbox(newStr, notContinuingMergeTags);

		return newStr;
	}

	public static capEmploymentTables(
		content: string,
		mergeTags: MergeTagState[],
		people: PeopleState[]
	) {
		if (people?.length > 2) {
			const wrapId = 'capEmploymentTables';
			// Create fragment from HTML String
			const newHtml = document
				.createRange()
				.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

			// Personal Details Table
			const personalDetails = newHtml.querySelector(
				`.${cffClasses.personalDetails}`
			);
			if (personalDetails) {
				const personalDetailsDup = personalDetails.cloneNode(
					true
				) as HTMLElement;
				personalDetails.after(personalDetailsDup);
				personalDetails.classList.add(`${cffClasses.firstDuplicate}`);
				personalDetailsDup.classList.add(`${cffClasses.secondDuplicate}`);
			}

			// Employment Details Table
			const employmentDetails = newHtml.querySelector(
				`.${cffClasses.employmentDetails}`
			);
			if (employmentDetails) {
				const employmentDetailsDup = employmentDetails.cloneNode(
					true
				) as HTMLElement;
				employmentDetails.after(employmentDetailsDup);
				employmentDetails.classList.add(`${cffClasses.firstDuplicate}`);
				employmentDetailsDup.classList.add(`${cffClasses.secondDuplicate}`);
			}

			// Income Details Table
			const incomeDetails = newHtml.querySelector(
				`.${cffClasses.incomeDetails}`
			);
			if (incomeDetails) {
				const incomeDetailsDup = incomeDetails.cloneNode(true) as HTMLElement;
				incomeDetails.after(incomeDetailsDup);
				incomeDetails.classList.add(`${cffClasses.firstDuplicate}`);
				incomeDetailsDup.classList.add(`${cffClasses.secondDuplicate}`);
			}

			const newContent = this.cleanDuplicateTables(
				newHtml.querySelector(`#${wrapId}`).innerHTML
			);

			return newContent;
		}
		return content;
	}

	public static cleanDuplicateTables(content: string) {
		const wrapId = 'cleanDuplicateTables';
		// Create fragment from HTML String
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

		newHtml
			.querySelectorAll(`.${cffClasses.firstDuplicate} table`)
			.forEach((el: HTMLElement) => {
				el?.querySelectorAll(
					'th:nth-child(4), th:nth-child(5), td:nth-child(4), td:nth-child(5)'
				).forEach((e: HTMLElement) => e.remove());
			});

		newHtml
			.querySelectorAll(`.${cffClasses.secondDuplicate} table`)
			.forEach((el: HTMLElement) => {
				el?.querySelectorAll(
					'th:nth-child(2), th:nth-child(3), td:nth-child(2), td:nth-child(3)'
				).forEach((e: HTMLElement) => e.remove());
			});

		return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
	}

	public static hideEmptyEmploymentIncomeTable(content: string) {
		const wrapId = 'hideEmptyEmploymentIncomeTable';

		// Create fragment from HTML String
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

		// Hide empty rows per table
		newHtml
			.querySelectorAll(
				`.${cffClasses.personalDetails} table, .${cffClasses.employmentDetails} table, .${cffClasses.incomeDetails} table`
			)
			.forEach((i: HTMLElement) => {
				// skip hiding of empty table rows if table has the class allow-empty-table-rows
				if (!i.classList?.contains(`${cffClasses.allowEmptyTableRows}`)) {
					const rowIsEmpty = (tr) =>
						Array.from(
							tr.querySelectorAll('td:not(:first-child), th:not(:first-child)')
						).every((td: HTMLElement) => td.innerText.trim() === '');

					// Remove tr that has empty td
					i?.querySelectorAll('tr').forEach((tr) => {
						if (rowIsEmpty(tr)) {
							tr.remove();
						}
					});

					// get the real value of the tds of the table
					const tdValues = Array.from(
						i?.querySelectorAll('td:not(:first-child)')
					).map((td: HTMLElement) => td.innerText.trim());

					// check if they're all empty aside from the title td
					if (!tdValues?.some((e) => e !== '')) {
						i.style.display = 'none';
					}
				}
			});

		// Hide H4 header for each income section
		newHtml
			.querySelectorAll(`.${cffClasses.incomeDetails} section`)
			.forEach((i: HTMLElement) => {
				// get all the the real value of the tds from all the tables under each section
				const tableValues = Array.from(
					i?.querySelectorAll('td:not(:first-child)')
				).map((td: HTMLElement) => td.innerText.trim());

				// hide the header if all tables have no value or does not show
				if (!tableValues?.some((e) => e !== '')) {
					i.querySelector('h4').style.display = 'none';
				}
			});

		return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
	}

	/**
	 *
	 * @param content - HTML template string
	 * @param mergeTags - Merge tags
	 * @returns - Updated HTML template string, with hidden (conditional) Rental & Other income are Empty
	 */
	public static hideEmploymentIncomeTables(
		content: string,
		mergeTags: MergeTagState[]
	) {
		const wrapId = 'hideEmploymentIncomeTables';
		const rentalIncome = this.getMergeTagValue(
			declarationMetaKey?.netRentalIncome,
			mergeTags
		);
		const otherIncome = this.getMergeTagValue(
			declarationMetaKey?.otherIncomeType,
			mergeTags
		);
		const rentalClass = 'rental-income';
		const otherClass = 'other-income';

		// Create fragment from HTML String
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

		newHtml
			.querySelectorAll(`.${rentalClass}, .${otherClass}`)
			.forEach((i: HTMLElement) => {
				if (
					(rentalIncome?.length === 0 && i.classList?.contains(rentalClass)) ||
					(otherIncome?.length === 0 && i.classList?.contains(otherClass))
				) {
					i?.remove();
				}
			});

		return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
	}

	public static getMergeTagValue(key: string, mergeTags: MergeTagState[]) {
		return mergeTags?.find((x) => x?.metaKey === key?.toString())?.value || [];
	}

	public static processCFF(
		content: string,
		mergeTags: MergeTagState[],
		config: BusinessConfig,
		people: PeopleState[]
	) {
		let newStr = content || '';
		newStr = this.applyPersonalDetailsRules(newStr, mergeTags);
		newStr = this.applyIncomeRules(newStr, mergeTags);
		newStr = this.replaceColorClasses(newStr, config);
		newStr = this.replaceCheckboxes(newStr, mergeTags);
		newStr = this.capEmploymentTables(newStr, mergeTags, people);
		newStr = this.hideEmptyEmploymentIncomeTable(newStr);
		newStr = this.hideEmploymentIncomeTables(newStr, mergeTags);
		newStr = this.hideEmptyOtherExpensesRow(newStr);

		return removeEmptyParagraphs(newStr);
	}

	public static hideEmptyOtherExpensesRow(content: string) {
		const wrapId = 'hideEmptyOtherExpensesRow';

		// Create fragment from HTML String
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

		// Hide empty rows per table
		newHtml
			.querySelectorAll(`.cff-other-expenses-table table`)
			.forEach((i: HTMLElement) => {
				const rowIsEmpty = (tr) =>
					Array.from(tr.querySelectorAll('td, th')).every(
						(td: HTMLElement) => td.innerText.trim() === ''
					);

				// Remove tr that has empty td
				i?.querySelectorAll('tr').forEach((tr) => {
					if (rowIsEmpty(tr)) {
						tr.remove();
					}
				});
			});

		return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
	}

	public static tableRowCheckbox(content: string, mergeTags: MergeTagState[]) {
		const notContinuingMergeTags = mergeTags?.filter((tag) =>
			[...repeatListNotContinuing].includes(tag.metaKey)
		);
		const wrapId = 'tableRowCheckbox';

		// Create fragment from HTML String
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

		notContinuingMergeTags?.forEach((mergeTag) => {
			newHtml.querySelectorAll(`#${mergeTag?.metaKey}`).forEach((elem, i) => {
				const value =
					mergeTag?.value?.length > 0 ? mergeTag?.value[i] || 'False' : 'False';
				if (value === 'False') {
					elem?.removeAttribute('checked');
				}
			});
		});

		return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
	}

	/**
	 * Update the Declaration CFF merge tag values
	 * @param mergeTags MergeTagState[]
	 * @returns MergeTagState[] - with updated values
	 */
	public static updateCFFMergeTags(mergeTags: MergeTagState[]) {
		const people =
			mergeTags?.find(
				(x) => x?.metaKey === declarationMetaKey.peopleReference?.toString()
			)?.value || [];
		// Filter secondary merge tags to show only values that are on FF > PEOPLE
		const secondaryTags = mergeTags
			?.filter((i) =>
				i?.metaKey?.includes(
					declarationMetaKey.peopleIncomeSecondary?.toString()
				)
			)
			?.map((i) => {
				return {
					...i,
					value: i.value?.filter(
						(x) => !!people?.find((y) => +y?.referenceId === +x?.referenceId)
					),
				};
			});
		const secondaryRepeatCount = MergeTagsMapper.repeatCount(
			secondaryTags,
			true
		);

		return (
			mergeTags?.map((mt) => {
				if (
					mt?.metaKey?.includes(
						declarationMetaKey.incomePrimaryPeople?.toString()
					) ||
					mt?.metaKey?.includes(
						declarationMetaKey.peoplePrimaryTotalGross?.toString()
					)
				) {
					// For Employment Details
					return {
						...mt,
						type: MergeTagTypeCode.text,
						value: MergeTagsMapper.getReferenceValueAsText(mt, people),
					};
				} else if (
					mt?.metaKey?.includes(
						declarationMetaKey.incomePrevious?.toString()
					) &&
					mt?.type === MergeTagTypeCode.referenceValue
				) {
					// For Employment Details > Previous Employment
					return {
						...mt,
						type: MergeTagTypeCode.text,
						value: MergeTagsMapper.getReferenceValueForTable(mt, people),
					};
				} else if (
					mt?.metaKey?.includes(
						declarationMetaKey.peopleIncomePrimary?.toString()
					)
				) {
					// For Primary Income Table
					return {
						...mt,
						type: MergeTagTypeCode.referenceValue,
						value: MergeTagsMapper.getReferenceValueForTable(mt, people, true),
					};
				} else if (
					mt?.metaKey?.includes(
						declarationMetaKey.peopleIncomeSecondary?.toString()
					) &&
					mt?.type === MergeTagTypeCode.referenceMultiValue
				) {
					// For Secondary Income Table
					return {
						...mt,
						type: MergeTagTypeCode.text,
						value: MergeTagsMapper.getReferenceMultiValueForTable(
							mt,
							people,
							secondaryRepeatCount,
							true
						),
					};
				} else if (
					mt?.metaKey?.includes(
						declarationMetaKey.peopleSecondaryTotalGross?.toString()
					)
				) {
					// For Secondary Income Table > Total Gross Income
					return {
						...mt,
						type: MergeTagTypeCode.text,
						value: MergeTagsMapper.getReferenceMultiValueAsText(
							mt,
							people,
							secondaryRepeatCount
						),
					};
				}
				return mt;
			}) || []
		);
	}
}
