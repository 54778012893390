import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from '../../../../core/base/api.service';
import { EmailBodyTypes } from '../../../../shared/models/emails/mortgage/email.model';
import {
  DocumentModelState,
  DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';

// @TODO USE THE QUERY AND STORE
import { KiwiSaverSettingsQuery } from '../../state/kiwisaver-settings.query';
import { KiwiSaverSettingsStore } from '../../state/kiwisaver-settings.store';
import { KiwiScopeOfServiceEmailSettingsState } from './kiwi-scope-of-service-settings.model';

@Injectable()
export class KiwiScopeOfServiceSettingsService {
  constructor(
    private api: ApiService,
		// @TODO USE THE QUERY AND STORE
		private store: KiwiSaverSettingsStore,
    private query: KiwiSaverSettingsQuery
  ) {}

	newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<DocumentUploadState>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req?.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<any>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
