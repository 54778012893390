import { Directive, Input, AfterViewInit, ElementRef, OnDestroy, OnChanges } from '@angular/core';
declare var $: any;

/** tooltip directive to show text in tooltip
 * to add capability to show content of template
 */
@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy, OnChanges {
  /** anput to accept tooltip content */
  @Input() appTooltip: string;

  constructor(private el: ElementRef) { }

  /** after view is ready, add tooltip */
  ngAfterViewInit(): void {
    this.addTooltip();
  }
  /** when tooltip content changes, readd the tooltip */
  ngOnChanges(): void {
    this.removeTooltip();
    this.addTooltip();
  }
  /** when tooltip element is destroyed, dispose the tooltip */
  ngOnDestroy(): void {
    this.removeTooltip();
  }

  /** add tooltip to element this directive is attached to */
  addTooltip() {
    const content = this.appTooltip;
    $(this.el.nativeElement).tooltip({
      title: content,
      placement: 'top'

    });
  }
  /** remove and dispose tooltip from the element */
  removeTooltip() {
    $(this.el.nativeElement).tooltip('dispose');
  }
}
