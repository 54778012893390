import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Observer, iif, of } from 'rxjs';
import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { mergeMap, map, tap, take, concatMap } from 'rxjs/operators';
import { ProviderState } from '../state/providers-settings.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UploadModalComponent } from '@shared/modal/upload-modal/upload-modal.component';
import { ProviderSettingsMapper } from '../state/providers-settings.mapper';
import { ProviderSettingsService } from '../state/providers-settings.service';
import { LoggerService } from '@core/logger/logger.service';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';

@Component({
	selector: 'app-provider-form',
	templateUrl: './provider-form.component.html',
	styleUrls: ['./provider-form.component.scss'],
})
export class ProviderFormComponent implements OnInit {

	@Input() providers: ProviderState[];
	@Output() saveEvent = new EventEmitter<any>();
	@Input() loading: boolean;

	@Input() providerList: ViewDisplayValue[];

	form: UntypedFormGroup;

	originalValues: any;
	formDisabled = false;

	tempValues: any;

	didReorder = false;

	get ProvidersList() {
		return this.form.get('providers') as UntypedFormArray;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private service: ProviderSettingsService,
		private loggerService: LoggerService,
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.originalValues = this.providers;
		this.tempValues = this.providers;

		this.buildForm();
		this.prepDocumentList();
	}

	buildForm() {
		this.form = this.fb.group({
			providers: this.fb.array([]),
		});
	}

	prepDocumentList() {
		while (this.ProvidersList?.length > 0) {
			this.ProvidersList?.removeAt(0);
		}
		this.providers?.filter((provider) => provider.status !== 0)?.forEach((provider: ProviderState) => {
			this.ProvidersList?.push(this.patchValue(provider));
		});
	}

	patchValue(provider): UntypedFormGroup {
		return this.fb.group({
			...provider,
			id: provider.id,
			provider: provider.provider || '',
			description: provider.description || '',
			logoUrl: provider.logoUrl || '',
			logoLink: provider.logoLink || '',
			applyLink: provider.applyLink || '',
			status: provider.status,
			orderNo: provider.orderNo || 0,
			isEditing: false,
			isAdding: false,
			hasUploadPhoto: false,
			logoBase64: null,
			logoFileName: null,
			isLoading: false
		});
	}

	trackByFn = (index: number, item: any) => {
		return index;
	};

	updateStatus(index: number) {
		const item = this.ProvidersList?.controls[index];
		const currentValue = item.get('status')?.value;
		item.get('status')?.setValue(currentValue === 1 ? 2 : 1);
	}

	save() {
		const data = this.ProvidersList?.getRawValue();

		const newData = data?.filter((provider) => !provider.isAdding)?.map((provider) => {
			if (provider.isEditing) {
				return this.providers?.find((eProvider) => {
					return eProvider.id === provider.id;
				});
			}

			return provider;
		});

		const providers = newData.map((provider) => {
			return {
				id: provider.id,
				provider: provider.provider,
				description: provider.description,
				logoUrl: provider.logoUrl,
				logoLink: provider.logoLink,
				applyLink: provider.applyLink,
				status: provider.status,
				orderNo: provider.orderNo,
			}
		});

		this.providers = providers;
		this.tempValues = providers;
		this.originalValues = providers;

		this.didReorder = false;

		this.saveEvent.emit(providers);
	}

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			// if transfer, recalculate the order of previous (the list from drag)
			event.previousContainer.data?.forEach((x, index) => {
				x.orderNo = index;
			});
		}
		// always, recalculate the order of the container (the list to drag)
		event.container.data?.forEach((x, index) => {
			x.orderNo = index;
		});

		this.didReorder = true;
	}

	addNew() {
		const ids = this.ProvidersList?.controls?.map((control) => {
			return control.get('id')?.value;
		});

		const highest = ids && ids.length ? Math.max(...ids) + 1 : 1;

		this.ProvidersList?.push(
			this.fb.group({
				id:  highest,
				provider: null,
				description: null,
				logoUrl: null,
				logoLink: null,
				applyLink: null,
				orderNo: this.ProvidersList?.length,
				status: 1,
				isEditing: false,
				isAdding: true,
				logoBase64: null,
				logoFileName: null,
				isLoading: false
			})
		);
	}

	uploadLogo(data) {
		const uploadData = ProviderSettingsMapper.mapToUpsertLogoUpload(data);

		return this.service.uploadProviderLogo(uploadData);
	}

	saveAddEdit(i: number) {
		const item = this.ProvidersList?.controls[i];

		const newProvider = item.get('provider')?.value;

		if(newProvider === null || newProvider.trim() === '') {
			this.loggerService.Warning({}, 'Provider is required.');
			return;
		}

		item.get('isLoading').setValue(true);

		const base64Data = item.get('logoBase64')?.value;
		const fileName = item.get('logoFileName')?.value;

		of(item).pipe(
			concatMap(() =>
				iif(
					() => !!base64Data,
					this.uploadLogo({
						photo: base64Data ? base64Data?.replace(/^data:(.*,)?/, '') : null,
						fileName: fileName || null
					}),
					of({
						photoURL: null,
					})
				)
			),
			take(1)
		).subscribe((data) => {
			item.get('isLoading').setValue(false);

			if (data?.photoURL) {
				item.get('logoUrl')?.setValue(data?.photoURL);
			}

			item.get('isAdding')?.setValue(false);
			item.get('isEditing')?.setValue(false);

			this.save();
		});
	}

	edit(i: number) {
		const item = this.ProvidersList?.controls[i];

		item.get('isEditing')?.setValue(true);
	}

	cancelAddEdit(i: number) {
		const item = this.ProvidersList?.controls[i];

		if (item.get('isAdding').value) {
			// this.ProvidersList?.controls?.pop();
			this.ProvidersList.removeAt(i);
		}

		if (item.get('isEditing').value) {
			item.get('isEditing')?.setValue(false);

			const providerItem = this.tempValues?.find((provider) => {
				return provider?.id === item.get('id').value;
			});

			if (providerItem) {
				item.patchValue(providerItem);
			}
		}
	}

	cancelEdit(i: number) {
		const item = this.ProvidersList?.controls[i];
		item.get('isEditing')?.setValue(false);
	}

	delete(index: number) {
		this.ProvidersList?.removeAt(index);

		this.save();
	}

	updatePriority(list) {
		return list?.map(({ order, ...item }, i) => ({
			...item,
			orderNo: i,
		}));
	}

	convertFileToBase64 = (blob, reader = new FileReader()) => {
		return 	new Observable((obs) => {
			if (!(blob instanceof Blob)) {
				return;
			}
			reader.onabort = () => obs.error(reader.error);
			reader.onerror = () => obs.error(reader.error);
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();
	
			return reader.readAsDataURL(blob);
		});
	}

	openUploadModal(i: number) {
		const item = this.ProvidersList.controls[i];

		if (item.get('isLoading').value) {
			return;
		}

		const upload = (req) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				mergeMap((x) => this.convertFileToBase64(x[0]).pipe(take(1))),
				map((base64Image) => {
					return {
						FileName: `${req[0].name}`,
						Photo: `${base64Image}`
					}
				}),
				tap(() => {
					this.convertFileToBase64(req[0])
						.pipe(take(1))
						.subscribe((res) => {
							item.get('logoBase64')?.setValue(res);
							item.get('logoFileName')?.setValue(req[0].name);
						});
				})
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			restrict: 'image/x-png,image/jpeg,image/png',
			headerTitle: 'Update Provider Photo',
			additionalInfo: '',
		};

		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	deleteCurrentImage(i: number) {
		const item = this.ProvidersList?.controls[i];

		item.get('logoBase64')?.setValue(null);
		item.get('logoUrl')?.setValue(null);
		item.get('logoFileName')?.setValue(null);
	}

	cancelChanges() {
		this.providers = this.originalValues;
		this.prepDocumentList();

		this.didReorder = false;
	}

	saveChanges() {
		this.save();
	}
}
