<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">L&R Online Advice Tool</h1>
	</div>

	<div class="row tap-container service-tabs crt-settings">
		<div class="col">
			<tabset [appTheme]="{ color: 'primarycolor' }">
				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Intro Page">
						<app-intro-page-settings></app-intro-page-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Scope of Service">
						<app-scope-of-service-settings></app-scope-of-service-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Declaration">
						<app-declaration-settings></app-declaration-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Authority to Proceed">
						<app-authority-to-proceed-settings></app-authority-to-proceed-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Final Structure">
						<app-final-structure-settings></app-final-structure-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'BO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Statement of Advice">
						<app-soa-settings></app-soa-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Merge Tags">
						<app-merge-tags></app-merge-tags>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="End Process">
						<app-end-process></app-end-process>
					</tab>
				</ng-container>

				<tab
					heading="Confirmation Call"
					*ngIf="(isConfirmationCallEnabled$ | async) && (showConfirmationCall$ | async)"
				>
					<app-confirmation-call></app-confirmation-call>
				</tab>
			</tabset>
		</div>
	</div>
</div>
