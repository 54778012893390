import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FGBookBreakdownStore, FGBookBreakdownState } from './fgbb.store';

/**
 * Query for F&G Book breakdown widget data
 */
@Injectable()
export class FGBookBreakdownQuery extends Query<FGBookBreakdownState> {

  /** F&G Book Breakdown data. */
  FGBookBreakdownData$ = this.select(state =>
    state.fGBookBreakdowns
  );

  /** Indicator if F&G Book Breakdown is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for F&G Book Breakdown */
  error$ = this.select(state => state.error);

  constructor(protected store: FGBookBreakdownStore) {
    super(store);
  }
}
