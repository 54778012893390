export class Dropdown {
	DropdownID: number;
	DropdownName: string;
	DropdownCode: string;
	/**
	 *
	 */
	constructor(id = null, name, code) {
		this.DropdownID = id;
		this.DropdownName = name;
		this.DropdownCode = code;
	}
}

export const CustomDropdowns = {
	LRP: [
		{
			dropdownValueId: null,
			key: 'email',
			type: 'email',
			validationType: 'email',
			isRequired: false,
			value: '',
		},
	],
	MP: [
		{
			dropdownValueId: null,
			key: 'email',
			type: 'email',
			validationType: 'email',
			isRequired: false,
			value: '',
		},
	],
};
