<form [formGroup]="form" novalidate>
  <div class="container-fluid clearfix px-0">
    <div class="form-row clearfix align-items-center mb-1">
      <div class="col-lg col-12"></div>
      <div class="col-lg-auto col-12 d-flex action-btns justify-content-end">
        <!-- Restriction for lead -->
        <ng-container *ngIf="isLead">
          <ng-container *appRestrictTo="['FEL']">
            <span class="position-relative">
              <a class="btn btn-link icon-btn p-0" [routerLink]="businessRoute" target="_blank">
                <i class="material-icons md-20 tap-text-color-h6">
                  edit
                </i>
              </a>
            </span>
          </ng-container>
        </ng-container>
        <!-- Restriction for current client -->
        <ng-container *ngIf="!isLead">
          <ng-container *appRestrictTo="['FEC']">
            <span class="position-relative">
              <a class="btn btn-link icon-btn p-0" [routerLink]="businessRoute" target="_blank">
                <i class="material-icons md-20 tap-text-color-h6">
                  edit
                </i>
              </a>
            </span>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="companyName_{{ formID }}">Company Name</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="companyName"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="companyName_{{ formID }}"
            />
          </div>
        </div>
      </div>
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="tradingName_{{ formID }}">Trading Name</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="tradingName"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="tradingName_{{ formID }}"
            />
          </div>
        </div>
      </div>
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="industry_{{ formID }}">Industry</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="industry"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="industry_{{ formID }}"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="primaryContact_{{ formID }}">Primary Contact</label>
          </div>
          <div class="col-lg-8 col-12">
            <app-client-search-control
              id="primaryContact_{{ formID }}"
              ngProjectAs="input"
              textboxClass="theme-crm-field-disabled"
              formControlName="primaryContact"
            ></app-client-search-control>
          </div>
        </div>
      </div>
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="phone_{{ formID }}">Phone</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="work"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="phone_{{ formID }}"
            />
          </div>
        </div>
      </div>
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="email_{{ formID }}">Email</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="email"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="email_{{ formID }}"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg-8 col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="physicalAddress_{{ formID }}">Physical Address</label>
          </div>
          <div class="col-lg-10 col-12">
            <app-address-input
              inputId="physicalAddress_{{ formID }}"
              formControlName="physicalAddress"
              textboxClass="theme-crm-field-disabled"
            ></app-address-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="!!business">
  <app-table-paginate-notes
    [isLead]="isLead"
    [hasAddNote]="true"
    [customerId]="business?.customerID"
    [notes]="notes?.notes"
    [count]="notes?.count"
    [hasPaging]="true"
    [getFn$]="getNotes$"
    [addFn$]="addNotes$"
    [deleteFn$]="deleteNotes$"
  ></app-table-paginate-notes>
</ng-container>
