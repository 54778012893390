import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface IncomeBudgetGuardCanDeactive {
	canDeactivate: () => Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class IncomeBudgetGuard implements CanDeactivate<IncomeBudgetGuardCanDeactive> {
  canDeactivate(component: IncomeBudgetGuardCanDeactive): Observable<boolean> {
		return component.canDeactivate();
  }

}
