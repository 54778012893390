import { Injectable } from '@angular/core';
import { share, publish, debounceTime } from 'rxjs/operators';
import { fromEvent, ConnectableObservable } from 'rxjs';


@Injectable()
export class DomEventService {
  public readonly DocumentClickEvent$ = (() => {
    const docClick$ = fromEvent<MouseEvent>(document, 'click').pipe(publish<MouseEvent>());
    (docClick$ as ConnectableObservable<MouseEvent>).connect();
    return docClick$;
  })();
  public readonly windowResize$ = fromEvent<Event>(window, 'resize').pipe(
    debounceTime(100),
    share()
  );
  constructor() {
  }
}
