import { Component, OnInit } from '@angular/core';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { ActivityModel } from '../../../../shared/models/_general/activity.model';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-activity-cancel-modal',
	templateUrl: './activity-cancel-modal.component.html'
})
export class ActivityCancelModalComponent implements OnInit {
	header: string;
	message: string;
	reason = '';
	isSaving = false;
	activity: ActivityViewModel | ActivityModel;

	savefn: (ac: any) => Observable<any>;
	constructor(public bsModalRef: BsModalRef) {
	}

	ngOnInit() {
	}

	confirm(reason: string) {
		this.isSaving = true;
		this.savefn({ activity: this.activity, reason }).subscribe(() => {
			this.isSaving = false;
			this.decline();
		}, () => {
			this.isSaving = false;
		});
	}
	decline = () => this.bsModalRef.hide();

}
