import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { ScopeOfServiceMapper } from '../state/scope-of-service.mapper';
import { ScopeOfServiceService } from '../state/scope-of-service.service';

@Component({
  selector: 'app-sos-service-settings',
  templateUrl: './sos-service-settings.component.html'
})
export class SosServiceSettingsComponent implements OnInit {

  form: UntypedFormGroup;
  isLoading = false;
  scopeOfService$ = this.sService.scopeOfService$;

  defaultDetails = 'is not included in the advice process because';

  optionsWysiswyg = {
		heightMax: null,
		heightMin: 250,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};

  @ViewChild('contentEditor') contentEditor: WysiwygComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private sService: ScopeOfServiceService
  ) {
    this.buildForm();
  }

  get OtherSpecificClientGoals() {
    return this.form.get('otherSpecificClientGoals');
  }

  ngOnInit(): void {
    this.prepData();
  }

  buildForm() {
    this.form = this.fb.group({
      referenceId: [null],
      type: [null],
      newPurchase: [0],
      lendingTopup: [0],
      businessLending: [0],
      refinance: [0],
      generalStructureReview: [0],
      otherService: [0],
      otherSpecificClientGoals: [null],
      intro: [null],
      kiwiSaverAdvice: [false],
      kiwiSaverAdviceDetails: [null],
      lifeAndRiskReview: [false],
      lifeAndRiskReviewDetails: [null],
      houseCarContentsQuote: [false],
      houseCarContentsQuoteDetails: [null],
      otherAdvice: [false]
    });
  }

  prepData() {
    this.scopeOfService$.pipe(take(1)).subscribe((data) => {
      if (data) {
        this.form.reset(ScopeOfServiceMapper.mapSosSettingsToView(data));
      }
    });
  }

  save() {
    this.isLoading = true;
    const formValue = ScopeOfServiceMapper.mapToUpsertSosSettings(
      this.form.getRawValue()
    );
    this.sService
      .updateScopeOfService(formValue)
      .pipe(
        finalize(() => (this.isLoading = false)),
        take(1)
      )
      .subscribe();
  }

}
