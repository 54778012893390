import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { DefaultStaffStats } from '@shared/models/_general/bl-staff.model';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';

@Component({
	selector: 'app-user-goals',
	templateUrl: './user-goals.component.html',
	styleUrls: ['./user-goals.component.scss'],
})
export class UserGoalsComponent implements OnInit {
	@Input() data: BlStaffViewmodel;
	@Input() isConversionEnabled: Boolean;
	@Input() updateStaffGoalsFn$: (data) => Observable<any>;

	form: FormGroup;
	isEdit = false;
	isLoading = false;

	constructor(private fb: FormBuilder) {
		this.form = this.fb.group(DefaultStaffStats);
	}

	ngOnInit(): void {
		this.form.patchValue(this.data.Stats);
		this.form.disable();
	}

	enableEdit() {
		this.isEdit = true;
		this.form.enable();
	}

	disableEdit() {
		this.isEdit = false;
		this.isLoading = false;
		this.form.disable();
	}

	cancelEdit() {
		this.form.reset(this.data.Stats);
		this.disableEdit();
	}

	save() {
		if (this.form.invalid) {
			return;
		}
		of(this.form.getRawValue())
			.pipe(
				tap(() => (this.isLoading = true)),
				map((formValue) => ({
					...this.data,
					Stats: Object.entries(formValue).map(([key, value]) => ({
						[key]: value,
					})),
				})),
				concatMap(this.updateStaffGoalsFn$),
				catchError((error) => {
					if (error) {
						this.isLoading = false;
					}
					throw error;
				}),
				tap(() => this.disableEdit()),
				take(1)
			)
			.subscribe();
	}
}
