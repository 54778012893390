<app-secondary-base-widget
	[headerText]="widgetName"
	[widgetCode]="widgetCode"
	class="eblr-opportunity"
>
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<app-date-range
		ngProjectAs="input"
		[formControl]="dateControl"
	></app-date-range>
	<div *ngIf="!(isLoading$ | async)" class="eblr-opportunity-widget">
		<!-- L&R -->
		<div class="eblr-lr pb-3">
			<div class="form-row eblr-lr-chart">
				<div class="col-8">
					<label class="mt-2 opportunity-name">Life & Risk</label>
				</div>
				<div class="col-4 text-right pr-3">
					<label class="mt-2 opportunity-name">Opportunities</label>
				</div>
			</div>
			<div class="form-row bar-chart pb-2 pt-1">
				<div class="col-11">
					<app-horizontal-bar-chart
						[barDataArr]="(lrOpportunity$ | async)?.Data || []"
						[barDataArrTooltip]="(lrOpportunity$ | async)?.Tooltip || []"
						[targetValue]="(lrOpportunity$ | async)?.Target || 0"
						[isPercentBar]="true"
					></app-horizontal-bar-chart>
				</div>
				<div class="col-1 p-0">
					<span class="opportunity-percent">{{
						(lrOpportunity$ | async)?.Total || 0
					}}</span>
				</div>
			</div>
			<div class="form-row eblr-lr-legend bar-legend px-1 pt-3 pb-2">
				<div
					class="bar-legend-container pr-2"
					*ngFor="let l of lrLegend; let i = index"
				>
					<span
						class="bar-legend-box"
						[style.background-color]="getLegendColor(i)"
					></span>
					<span class="bar-legend-name">{{ l }}</span>
				</div>
			</div>
		</div>

		<!-- KiwiSaver -->
		<div class="eblr-ks mt-4">
			<div class="form-row eblr-ks-chart">
				<div class="col-12">
					<label class="mt-2 opportunity-name">KiwiSaver</label>
				</div>
			</div>
			<div class="form-row bar-chart pb-2 pt-1">
				<div class="col-11">
					<app-horizontal-bar-chart
						[barDataArr]="(ksOpportunity$ | async)?.Data || []"
						[barDataArrTooltip]="(ksOpportunity$ | async)?.Tooltip || []"
						[targetValue]="(ksOpportunity$ | async)?.Target || 0"
						[isPercentBar]="true"
					></app-horizontal-bar-chart>
				</div>
				<div class="col-1 p-0">
					<span class="opportunity-percent">{{
						(ksOpportunity$ | async)?.Total || 0
					}}</span>
				</div>
			</div>
			<div class="form-row eblr-ks-legend bar-legend px-1 pt-3">
				<div
					class="bar-legend-container pr-2"
					*ngFor="let l of ksLegend; let i = index"
				>
					<span
						class="bar-legend-box"
						[style.background-color]="getLegendColor(i)"
					></span>
					<span class="bar-legend-name">{{ l }}</span>
				</div>
			</div>
			<!-- <div class="form-row legend-container px-1 pt-3 pb-4">
				<ng-container *ngFor="let l of ksLegend; let i = index">
					<div
						class="bar-legend-box"
						[style.background-color]="getLegendColor(i)"
					></div>
					<label class="mx-2 bar-legend-name">{{ l }}</label>
				</ng-container>
			</div> -->
		</div>
	</div>
</app-secondary-base-widget>
