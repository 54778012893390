import { RiskAnalysisLife, RiskAnalysisLifeState } from './life.model';

export class RiskAnalysisLifeMapper {
	/** Mapping for view */
	static mapToView(data: RiskAnalysisLife) {
		if (!data) {
			return { ...data };
		}
		const val = {
			...data,
		};
		return val;
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: RiskAnalysisLifeState
	): RiskAnalysisLifeState {
		const changeOfExpense = data.changeOfExpense?.filter((c) => c.dropdown && c.value);
		return {
			...data,
			loans: data?.loans || [],
			assets: data?.assets || [],
			properties: data?.properties || [],
			requireds: data?.requireds || [],
			changeOfExpense: changeOfExpense,
			sectionCode: 'RAL',
		};
	}
}
