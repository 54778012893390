import * as R from 'ramda';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';

export class DropdownValue {
	/**
	 *
	 */
	constructor(
		public DropdownValueID: number = null,
		public DropdownCode: string,
		public DropdownValue: string,
		public DropdownOrder: number,
		public IsDefault: boolean
	) {}

	static filterDropdownsByCode = R.curry(
		(code: string, dropdownValues: DropdownValue[]): DropdownValue[] =>
			dropdownValues?.filter((x) => x.DropdownCode === code)
	);

	static mapDropdownToChoices = (dropdownValues): ViewDisplayValue[] => {
		return dropdownValues?.map((d) =>
			ViewDisplayValue.Map(d.DropdownValue, d.DropdownValue, d.IsDefault)
		);
	};

	static filterAndMapDropdowns = (code: string) =>
		R.pipe(
			DropdownValue.filterDropdownsByCode(code),
			DropdownValue.mapDropdownToChoices
		);
}

export class DropdownValueUpdate extends DropdownValue {
	/**
	 *
	 */
	constructor(
		public dropdown_id: number, // dropdownvalue_id
		DropdownValueID: number = null,
		DropdownCode: string,
		DropdownValue: string,
		DropdownOrder: number,
		IsDefault: boolean,
		public company_code: string
	) {
		super(
			DropdownValueID,
			DropdownCode,
			DropdownValue,
			DropdownOrder,
			IsDefault
		);
	}
}

export class DropdownValueAdd extends DropdownValue {
	/**
	 *
	 */
	constructor(
		DropdownCode: string,
		DropdownValue: string,
		DropdownOrder: number,
		IsDefault: boolean,
		public company_code: string
	) {
		super(undefined, DropdownCode, DropdownValue, DropdownOrder, IsDefault);
	}
}

export class AllDropdownValues {
	/**
	 *
	 */
	constructor(
		public company_code: string,
		public dropdown_values: DropdownValuev2[]
	) {}
}
export class DropdownValuev2 {
	DropdownValueID: number;
	DropdownCode: string;
	DropdownValue: string;
	DropdownOrder: number;
	IsDefault: boolean;
}
