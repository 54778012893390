import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { viewStaff } from '../../../viewmodels/viewStaff';
import { viewSecGroup } from '../../../viewmodels/viewSecGroup';
import { TLStaffsQuery } from '../../../../../domain/tl-staff/tl-staffs.query';
import { TLStaffSecGroupsQuery } from '../../../../../domain/tl-staff-security-group/tl-staff-security-groups.query';
import { TLStaffsService } from '../../../../../domain/tl-staff/tl-staffs.service';

@Component({
  selector: 'app-tl-list',
  templateUrl: './tl-list.component.html'
})
export class TlListComponent implements OnInit, OnDestroy {
  constructor(
    private tlStaffQuery: TLStaffsQuery,
    private tlStaffSecGroupQuery: TLStaffSecGroupsQuery,
    private tlStaffsService: TLStaffsService) { }
  subs: Subscription[] = [];
  staffs: viewStaff[] = [];
  secGroups: viewSecGroup[] = [];
  ngOnInit() {
    const s = this.tlStaffQuery.getAll();
    const sg = this.tlStaffSecGroupQuery.getAll();

    this.staffs = viewStaff.mapStaffs(s);
    this.secGroups = viewSecGroup.mapSecGroups(sg);
  }

  deleteTlStaff(id: number) {
    this.tlStaffsService.delete(id).subscribe(() => {

      const i = this.tlStaffQuery.getAll();
      this.staffs = viewStaff.mapStaffs(i);
    });
  }

  ngOnDestroy(): void {
    this.subs?.forEach(s => s.unsubscribe());
  }
}
