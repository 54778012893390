<div class="intro-settings p-4">
	<form [formGroup]="form">
		<div class="row">
			<div class="col-12 col-lg-3 intro-settings__option">
				<div class="row">
					<div class="col-1 intro-settings__option__checkbox">
						<input
							type="checkbox"
							class="chckbox form-control theme-crm-field-disabled"
							id="introTypeUD"
							formControlName="introTypeUD"
							(change)="toggleIntroTypeBD()"
						/>
					</div>
					<div class="col-10 pt-1 pl-2 intro-settings__option__label">
						<label class="form-check-label">Upload Document</label>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-3 intro-settings__option">
				<div class="row">
					<div class="col-1 intro-settings__option__checkbox">
						<input
							type="checkbox"
							class="chckbox form-control theme-crm-field-disabled"
							id="introTypeBD"
							formControlName="introTypeBD"
							(change)="toggleIntroTypeUD()"
						/>
					</div>
					<div class="col-10 pt-1 pl-2 intro-settings__option__label">
						<label class="form-check-label">Block Document</label>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="!!IntroTypeUD.value">
			<div class="row">
				<div class="col-12 mt-3">
					<button
						type="button"
						class="tap-btn tap-btn--default"
						(click)="openUploadModal()"
					>
						Upload
					</button>
					<span class="ml-2" *ngIf="!!tempDoc">{{ tempDoc?.name }}</span>
					<span class="ml-2" *ngIf="!!!tempDoc && !!document"
						><a href="{{ document.documentLink }}" target="_blank">{{
							document?.fileName
						}}</a>
					</span>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="!!IntroTypeBD.value">
			<textarea
				class="form-control intro-settings__intro-desc mt-3 mb-2"
				rows="12"
				formControlName="intro"
			></textarea>
		</ng-container>

		<div class="text-right">
			<ng-container *ngIf="!isLoading">
				<button
					type="button"
					class="btn btn-primary my-2 pull-right tap-bg-primary"
					style="background-color: rgb(52, 158, 237)"
					[disabled]="!IntroTypeUD.value && !IntroTypeBD.value"
					(click)="submitForm()"
				>
					SAVE
				</button>
			</ng-container>

			<ng-container *ngIf="isLoading">
				<div class="mt-3 mb-3">
					<app-manual-loader-mini
						[isLoading]="isLoading"
					></app-manual-loader-mini>
				</div>
			</ng-container>
		</div>
	</form>
</div>
