import {
  MonthlyExpenseDetailsState,
  ExpenseListObjState,
} from '../../../../../shared/models/client-review-template/income-budget/monthly-expense.model';
import { numUtil } from '../../../../../util/util';
import { convertToMonthly, curFreqType } from './annual-conversion';
import { sum } from 'ramda';

export const computeTotalExpense = (
  monthlyExpense: MonthlyExpenseDetailsState,
  others
) => {
  let totalExpenseList = 0;
  monthlyExpense.expenseList?.map(
    (x) =>
      (totalExpenseList += convertToMonthly(
        +x.expenseValue,
        x.expenseFrequency as curFreqType
      ))
  );

  const totalExpense = sum([
    // rent
    convertToMonthly(
      +monthlyExpense.rentValue,
      monthlyExpense.rentFrequency as curFreqType
    ),
    // power, gas, water
    convertToMonthly(
      +monthlyExpense.pGWValue,
      monthlyExpense.pGWValueFrequency as curFreqType
    ),
    // phone, internet, pay tv
    convertToMonthly(
      +monthlyExpense.pIPValue,
      monthlyExpense.pIPValueFrequency as curFreqType
    ),
    // house, car, contents
    convertToMonthly(
      +monthlyExpense.hCCValue,
      monthlyExpense.hCCValueFrequency as curFreqType
    ),
    // life, risk, medical insurance
    convertToMonthly(
      +monthlyExpense.lRMValue,
      monthlyExpense.lRMValueFrequency as curFreqType
    ),
    // car expenses
    convertToMonthly(
      +monthlyExpense.carExpenses,
      monthlyExpense.carExpensesFrequency as curFreqType
    ),
    // home rates
    convertToMonthly(
      +monthlyExpense.homeRates,
      monthlyExpense.homeRatesFrequency as curFreqType
    ),
    // pets
    convertToMonthly(
      +monthlyExpense.pets,
      monthlyExpense.petsFrequency as curFreqType
    ),
    // school fees
    convertToMonthly(
      +monthlyExpense.schoolFees,
      monthlyExpense.schoolFeesFrequency as curFreqType
    ),
    // child care
    convertToMonthly(
      +monthlyExpense.childCare,
      monthlyExpense.childCareFrequency as curFreqType
    ),
    // food, clothes
    convertToMonthly(
      +monthlyExpense.foodClothes,
      monthlyExpense.foodClothesFrequency as curFreqType
    ),
    // discertionary spending
    convertToMonthly(
      +monthlyExpense.discretionarySpendingValue,
      monthlyExpense.discretionarySpendingValueFrequency as curFreqType
    ),
    // expense list
    +totalExpenseList,
    // mortgage and phc value
    monthlyExpense.mortgagePayments,
    monthlyExpense.pHCValue,
  ]);

  return +numUtil.formatToNumCurrency(totalExpense);
};
