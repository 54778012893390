import { AdviceSummarySettingsState } from './advice-summary-settings.model';
import * as R from 'ramda';
import {
	DefaultFileNames,
	DocumentTypesKOAT,
} from '../../../../shared/models/documents/document.model';
import { SettingsTypes } from '../../state/kiwisaver-settings.model';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import MomentUtil from 'src/app/util/moment.util';
import { adviceSummaryWordingsMetaKey } from 'src/app/shared/models/client-review-template/merge-tags/crt-kiwisaver/advice-summary/advice-summary.merge-tag';

export class AdviceSummarySettingsMapper {
	public static mapMergeTags(data: MergeTagState[]) {
		return data?.map((item) =>
			item?.metaKey === 'DATE_TODAY'
				? {
						...item,
						value: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
						secondaryValue: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
				  }
				: item
		);
	}

	public static mapMergeTagCodes(data = []) {
		const newList = R.sortBy(R.compose(R.toLower, R.prop('description')))(data);
		return newList?.reduce((acc, cur: any) => {
			return { ...acc, [cur.metaKey]: cur.description };
		}, {});
	}

	public static mapDocumentUpload(document, template) {
		if (template === 0 || isNaN(template) || R.isNil(template)) {
			return {
				document,
				referenceId: 0,
				fileName: DefaultFileNames.AdviceSummary,
				type: DocumentTypesKOAT.AdviceSummary,
			};
		}
		return {
			document,
			documentId: +template,
		};
	}

	public static mapAdviceSummaryMtWordingPreviewValues(data: any[] = []) {
		return data?.map((item: any) =>
			item?.metaKey?.includes('KOAT_') || item?.metaKey?.includes('INVESTMENT_ADVICE')
				? {
						...item,
						value: item?.value?.replace(/\n|\t/g, '<br>'),
						secondaryValue: item?.value?.replace(/\n|\t/g, '<br>'),
				  }
				: item
		);
	}

	public static mapAdviceSummaryMergeTagWording(
		mergeTags: any[] = [],
		isPreview: boolean = false
	) {
		const getContent = (metaKey: any) => {
			const data: any = mergeTags?.find((x: any) => x?.metaKey === metaKey);
			return isPreview ? data?.secondaryValue : data?.value;
		};

		return {
			adviceSummaryRaiseComplaint: getContent(adviceSummaryWordingsMetaKey.raiseComplaint) || '',
			adviceSummaryThingsToConsider:
				getContent(adviceSummaryWordingsMetaKey.thingsToConsider) || '',
			adviceSummaryTheNextStep: getContent(adviceSummaryWordingsMetaKey.nextStep) || '',
			investmentAdvice: getContent(adviceSummaryWordingsMetaKey.investmentAdvice) || ''
		};
	}

	public static mapToUpsert(data: AdviceSummarySettingsState) {
		return {
			...data,
			referenceId: data.referenceId || 0,
			mergeTag: data.mergeTag || [],
			type: SettingsTypes.KOATAdviceSummary,
		};
	}

	public static mapToUpsertMtWording(mergeTags: any[] = [], data) {
		return mergeTags?.map((item: any) =>
			item?.metaKey === adviceSummaryWordingsMetaKey.raiseComplaint
				? {
						...item,
						value: data?.adviceSummaryRaiseComplaint || '',
				  }
				: item?.metaKey === adviceSummaryWordingsMetaKey.thingsToConsider
				? {
						...item,
						value: data?.adviceSummaryThingsToConsider || '',
				  }
				: item?.metaKey === adviceSummaryWordingsMetaKey.nextStep
				? {
						...item,
						value: data?.adviceSummaryTheNextStep || '',
				  }
				: item?.metaKey === adviceSummaryWordingsMetaKey.investmentAdvice
				? {
						...item,
						value: data?.investmentAdvice || '',
					}
				: item
		);
	}
}
