<div class="main-fg-insurance p-4">
	<form [formGroup]="form" class="form">
		<div class="form-row">
			<div class="column col-lg-12">
				<div class="form-row mb-2 p-lg-0 house-label-background">
					<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
						<label for="" class="font-weight-bold label-font tap-text-primary">
							House Insurance
						</label>
					</div>
					<div class="col-5 col-lg-9 text-right px-0 iconShow">
						<button
							id="collapseHouseBtn"
							type="button"
							class="btn-toggle collapse-btn border-0 house-collapse-background"
						>
							<span *ngIf="elseMinusHouse; else elsePlusHouse">
								<i
									(click)="collapseMoreHouse()"
									class="material-icons md-20 pt-1"
									>expand_more</i
								>
							</span>
							<ng-template #elsePlusHouse>
								<span>
									<i
										(click)="collapseLessHouse()"
										class="material-icons md-20 pt-1"
										>expand_less</i
									>
								</span>
							</ng-template>
						</button>
					</div>
				</div>

				<div
					class="form-row d-none d-lg-flex mb-2"
					[class.tap-opacity-25]="(houseInsurance$ | async)?.length === 0"
				>
					<div class="col-lg-4 font-weight-bold">
						<span>Provider</span>
					</div>
					<div class="col-lg-4 font-weight-bold">
						<span>Address</span>
					</div>
					<div class="col-lg-3 font-weight-bold">
						<span>Premium</span>
					</div>
					<div class="col-lg-1 font-weight-bold"></div>
				</div>

				<div class="collapse expandable" id="collapseHouse">
					<div
						class="form-row"
						*ngFor="
							let item of houseInsurance$ | async;
							let i = index;
							let last = last
						"
					>
						<div class="d-md-none col-lg-2 text-right">
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnlySmallHouse">
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="editHouseBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="editHouse(item.cRTId)"
									>
										edit
									</i>
								</button>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="deleteHouseBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="deleteHouse(item.cRTId)"
									>
										delete
									</i>
								</button>
							</ng-container>
							<ng-template #viewOnlySmallHouse>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="viewHouseBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="viewHouse(item.cRTId)"
									>
										info
									</i>
								</button>
							</ng-template>
						</div>
						<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
							<input
								id="houseProvider"
								class="form-control crt-form-control"
								placeholder="Provider"
								type="text"
								value="{{ item.provider }}"
								disabled
							/>
						</div>
						<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
							<input
								id="houseAddress"
								class="form-control crt-form-control"
								placeholder="Address"
								type="text"
								value="{{ item.address }}"
								disabled
							/>
						</div>
						<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
							<div class="dollar-icon">
								<input
									id="housePremium"
									class="form-control crt-form-control"
									placeholder="Premium"
									type="text"
									[(ngModel)]="item.premium"
									[ngModelOptions]="{ standalone: true }"
									currencyMask
									disabled
								/>
								<i>$</i>
							</div>
						</div>
						<div
							class="d-none d-md-block col-lg-1 text-right mb-2"
							[class.border-bottom]="!last"
						>
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnlyHouse">
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="editHouseBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="editHouse(item.cRTId)"
									>
										edit
									</i>
								</button>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="deleteHouseBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="deleteHouse(item.cRTId)"
									>
										delete
									</i>
								</button>
							</ng-container>
							<ng-template #viewOnlyHouse>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="editHouseBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="viewHouse(item.cRTId)"
									>
										info
									</i>
								</button>
							</ng-template>
						</div>
					</div>

					<div class="form-row my-4">
						<div class="col-5 col-lg-6">
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
								<button
									type="button"
									id="addNewHousePolicyBtn"
									class="tap-btn tap-btn-outline tap-btn--shadow col-auto"
									(click)="addHouse()"
									[disabled]="isHouseLoading"
								>
									Add New Policy +
								</button>
							</ng-container>
							<span *ngIf="isHouseLoading">
								<app-manual-loader-mini
									[isLoading]="isHouseLoading"
								></app-manual-loader-mini>
							</span>
						</div>
						<div class="col-7 pt-1 col-lg-6 text-right"></div>
					</div>
					<div class="form-row line mb-3"></div>
				</div>

				<div class="form-row mt-4 mb-2 p-lg-0 car-label-background">
					<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
						<label for="" class="font-weight-bold label-font tap-text-primary">
							Car Insurance
						</label>
					</div>
					<div class="col-5 col-lg-9 text-right px-0 iconShow">
						<button
							id="collapseCarBtn"
							type="button"
							class="btn-toggle collapse-btn border-0 car-collapse-background"
						>
							<span *ngIf="elseMinusCar; else elsePlusCar">
								<i (click)="collapseMoreCar()" class="material-icons md-20 pt-1"
									>expand_more</i
								>
							</span>
							<ng-template #elsePlusCar>
								<span>
									<i
										(click)="collapseLessCar()"
										class="material-icons md-20 pt-1"
										>expand_less</i
									>
								</span>
							</ng-template>
						</button>
					</div>
				</div>

				<div
					class="form-row d-none d-lg-flex mb-2"
					[class.tap-opacity-25]="(carInsurance$ | async)?.length === 0"
				>
					<div class="col-lg-4 font-weight-bold">
						<span>Provider</span>
					</div>
					<div class="col-lg-4 font-weight-bold">
						<span>Model</span>
					</div>
					<div class="col-lg-3 font-weight-bold">
						<span>Premium</span>
					</div>
					<div class="col-lg-1 font-weight-bold"></div>
				</div>

				<div class="collapse expandable" id="collapseCar">
					<div
						class="form-row"
						*ngFor="
							let item of carInsurance$ | async;
							let i = index;
							let last = last
						"
					>
						<div class="d-md-none col-lg-2 text-right">
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnlySmallCar">
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="editCarBtn_{{ i }}"
								>
									<i class="material-icons md-16" (click)="editCar(item.cRTId)">
										edit
									</i>
								</button>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="deleteCarBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="deleteCar(item.cRTId)"
									>
										delete
									</i>
								</button>
							</ng-container>
							<ng-template #viewOnlySmallCar>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="viewCarBtn_{{ i }}"
								>
									<i class="material-icons md-16" (click)="viewCar(item.cRTId)">
										info
									</i>
								</button>
							</ng-template>
						</div>
						<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
							<input
								id="carProvider"
								class="form-control crt-form-control"
								placeholder="Provider"
								type="text"
								value="{{ item.provider }}"
								disabled
							/>
						</div>
						<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
							<input
								id="carModel"
								class="form-control crt-form-control"
								placeholder="Model"
								type="text"
								value="{{ item.model }}"
								disabled
							/>
						</div>
						<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
							<div class="dollar-icon">
								<input
									id="carPremium"
									class="form-control crt-form-control"
									placeholder="Premium"
									type="text"
									[(ngModel)]="item.premium"
									[ngModelOptions]="{ standalone: true }"
									currencyMask
									disabled
								/>
								<i>$</i>
							</div>
						</div>
						<div
							class="d-none d-md-block col-lg-1 text-right mb-2"
							[class.border-bottom]="!last"
						>
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnlyCar">
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="editCarBtn_{{ i }}"
								>
									<i class="material-icons md-16" (click)="editCar(item.cRTId)">
										edit
									</i>
								</button>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="deleteCarBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="deleteCar(item.cRTId)"
									>
										delete
									</i>
								</button>
							</ng-container>
							<ng-template #viewOnlyCar>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="viewCarBtn_{{ i }}"
								>
									<i class="material-icons md-16" (click)="viewCar(item.cRTId)">
										info
									</i>
								</button>
							</ng-template>
						</div>
					</div>
					<div class="form-row my-4">
						<div class="col-5 col-lg-6">
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
								<button
									id="addCarPolicyBtn"
									type="button"
									class="tap-btn tap-btn-outline tap-btn--shadow col-auto"
									(click)="addCar()"
									[disabled]="isCarLoading"
								>
									Add New Policy +
								</button>
							</ng-container>
							<span *ngIf="isCarLoading">
								<app-manual-loader-mini
									[isLoading]="isCarLoading"
								></app-manual-loader-mini>
							</span>
						</div>
						<div class="col-7 pt-1 col-lg-6 text-right"></div>
					</div>
					<div class="form-row line mb-3"></div>
				</div>

				<div class="form-row mt-4 mb-2 p-lg-0 contents-label-background">
					<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
						<label for="" class="font-weight-bold label-font tap-text-primary">
							Contents Insurance
						</label>
					</div>
					<div class="col-5 col-lg-9 text-right px-0 iconShow">
						<button
							id="collapseContentsBtn"
							type="button"
							class="btn-toggle collapse-btn border-0 contents-collapse-background"
						>
							<span *ngIf="elseMinusContents; else elsePlusContents">
								<i
									(click)="collapseMoreContents()"
									class="material-icons md-20 pt-1"
									>expand_more</i
								>
							</span>
							<ng-template #elsePlusContents>
								<span>
									<i
										(click)="collapseLessContents()"
										class="material-icons md-20 pt-1"
										>expand_less</i
									>
								</span>
							</ng-template>
						</button>
					</div>
				</div>

				<div
					class="form-row d-none d-lg-flex mb-2"
					[class.tap-opacity-25]="(contentInsurance$ | async)?.length === 0"
				>
					<div class="col-lg-4 font-weight-bold">
						<span>Provider</span>
					</div>
					<div class="col-lg-4 font-weight-bold">
						<span>Address</span>
					</div>
					<div class="col-lg-3 font-weight-bold">
						<span>Premium</span>
					</div>
					<div class="col-lg-1 font-weight-bold"></div>
				</div>

				<div class="collapse expandable" id="collapseContents">
					<div
						class="form-row"
						*ngFor="
							let item of contentInsurance$ | async;
							let i = index;
							let last = last
						"
					>
						<div class="d-md-none col-lg-2 text-right">
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnlySmallContent">
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="editContentsBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="editContent(item.cRTId)"
									>
										edit
									</i>
								</button>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="deleteContentsBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="deleteContent(item.cRTId)"
									>
										delete
									</i>
								</button>
							</ng-container>
							<ng-template #viewOnlySmallContent>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="viewContentsBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="viewContent(item.cRTId)"
									>
										info
									</i>
								</button>
							</ng-template>
						</div>
						<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
							<input
								id="houseProvider"
								class="form-control crt-form-control"
								placeholder="Provider"
								type="text"
								value="{{ item.provider }}"
								disabled
							/>
						</div>
						<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last">
							<input
								id="houseAddress"
								class="form-control crt-form-control"
								placeholder="Address"
								type="text"
								value="{{ item.address }}"
								disabled
							/>
						</div>
						<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
							<div class="dollar-icon">
								<input
									id="housePremium"
									class="form-control crt-form-control"
									placeholder="Premium"
									type="text"
									[(ngModel)]="item.premium"
									[ngModelOptions]="{ standalone: true }"
									currencyMask
									disabled
								/>
								<i>$</i>
							</div>
						</div>
						<div
							class="d-none d-md-block col-lg-1 text-right mb-2"
							[class.border-bottom]="!last"
						>
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnlyContent">
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="editContentsBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="editContent(item.cRTId)"
									>
										edit
									</i>
								</button>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="deleteContentsBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="deleteContent(item.cRTId)"
									>
										delete
									</i>
								</button>
							</ng-container>
							<ng-template #viewOnlyContent>
								<button
									type="button"
									class="icon-btn w-auto h-auto px-1"
									id="viewContentsBtn_{{ i }}"
								>
									<i
										class="material-icons md-16"
										(click)="viewContent(item.cRTId)"
									>
										info
									</i>
								</button>
							</ng-template>
						</div>
					</div>
					<div class="form-row my-4">
						<div class="col-5 col-lg-6">
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
								<button
									id="addContentPolicy"
									type="button"
									class="tap-btn tap-btn-outline tap-btn--shadow col-auto"
									(click)="addContent()"
									[disabled]="isContentLoading"
								>
									Add New Policy +
								</button>
							</ng-container>
							<span *ngIf="isContentLoading">
								<app-manual-loader-mini
									[isLoading]="isContentLoading"
								></app-manual-loader-mini>
							</span>
						</div>
						<div class="col-7 pt-1 col-lg-6 text-right"></div>
					</div>
					<div class="form-row line mb-3"></div>
				</div>

				<div class="form-row mt-4 mb-2 p-lg-0">
					<div
						class="offset-6 col-6 px-0 offset-lg-0 col-lg-12 text-right mt-1 mt-lg-0 mb-lg-3"
					>
						<ng-container *ngIf="blanketOptions$ | async as blanketOptions">
							<ng-container
								*ngFor="let option of blanketOptions; let i = index"
							>
								<button
									*ngIf="showButton(option)"
									type="button"
									id="blanketOption_{{ i }}"
									class="tap-btn tap-btn-outline tap-btn--shadow mr-lg-2 mb-2 mb-lg-0 mt-2 pl-4 pr-4"
									(click)="blanketLink(option.referralValues.referralLink)"
								>
									{{ option.referralService }}
								</button>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
