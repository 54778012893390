import * as R from 'ramda';
import { GpHistoryState } from './gp.model';

export class GpHistoryMapper {
  /** Mapping for view */
  static mapToView(data: GpHistoryState) {
    if (!data) {
      return { ...data };
    }
    const val = {
      ...data,
      client: JSON.stringify(data.client),
    };
    return val;
  }

  /**
   * Map to update
   */
  public static mapToUpsert(
    data: GpHistoryState,
    personInfo?: GpHistoryState
  ): GpHistoryState {
    const cid = R.complement(R.either(R.isNil, R.isEmpty))(data.customerId)
      ? data.customerId
      : 0;
    return {
      ...data,
      client: JSON.stringify(data?.client),
      adviceProcessId: data?.adviceProcessId ?? personInfo?.adviceProcessId,
      cRTId: data?.cRTId ? data?.cRTId || 0 : personInfo?.cRTId || 0,
      customerId: +cid,
      sectionCode: 'FMG',
      status: 1,
    };
  }
}
