import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
	ElementRef,
	NgZone,
	OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Observable, Observer, of, zip } from 'rxjs';
import { take, map, withLatestFrom, filter, tap, takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { MortgageCustomerServiceState } from '../../models/services/mortgage/mortgage.model';
import { MortgageProviderGroupState } from '../../models/services/mortgage/mortgage-group.model';
import { MortgageMapper } from '../../models/services/mortgage/mortgage.mapper';

import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { ArchiveModalComponent } from '../../modal/archive-modal/archive-modal.component';
import { CollapseComponent } from '../../collapse/collapse.component';
import { TransferServiceComponent } from '../transfer-service/transfer-service.component';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';

import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import { patchValue } from '../service-utils/service.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from '../../date-input/date-input.component';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import * as R from 'ramda';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { Fields, getInvalidWarning, getRequiredWarning } from '../../error-message/error-message';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ComponentBase } from '@core/base/component-base';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { ServicesCodes } from '@shared/models/services/services.model';

@Component({
	selector: 'app-mortgage-service-form',
	templateUrl: './mortgage-service-form.component.html',
	styleUrls: ['./mortgage-service-form.component.scss'],
})
export class MortgageServiceFormComponent
	extends ComponentBase
	implements OnInit, OnChanges, OnDestroy
{
	form: UntypedFormGroup;
	// Actions
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Output() archiveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() deleteNoteEvent = new EventEmitter<any>();
	@Output() splitEvent = new EventEmitter<any>();
	@Output() addNoteEvent = new EventEmitter<any>();

	@Input() isLead: boolean;
	@Input() isCompany: boolean;

	@Input() mortgage: MortgageCustomerServiceState;
	@Input() borrowingEntities: ViewDisplayValue[];
	@Input() statuses: ViewDisplayValue[];
	@Input() providers: ViewDisplayValue[];
	@Input() loanTypes: ViewDisplayValue[];
	@Input() mortgageTypes: ViewDisplayValue[];
	@Input() repaymentFrequency: ViewDisplayValue[];
	@Input() fixedPeriods: ViewDisplayValue[];
	@Input() securities: ViewDisplayValue[];
	@Input() refixStatus: ViewDisplayValue[];
	@Input() groupName: string;

	@Output() cancelAddEvent = new EventEmitter<boolean>();

	@Input() addMode: boolean;
	@Input() formId: string;
	@Input() mList: MortgageProviderGroupState[];
	@Input() isNewGroup: boolean;

	@ViewChild(CollapseComponent) collapse: CollapseComponent;
	@ViewChild('formProp') formProp: ElementRef;

	@ViewChild('submittedDateInput') submittedDateInput: DateInputComponent;
	@ViewChild('approvedDateInput') approvedDateInput: DateInputComponent;
	@ViewChild('loanDrawdownDateInput') loanDrawdownDateInput: DateInputComponent;
	@ViewChild('expectedSettlementDateInput')
	expectedSettlementDateInput: DateInputComponent;
	@ViewChild('fixedPeriodEndDateInput')
	fixedPeriodEndDateInput: DateInputComponent;
	@ViewChild('expiryDateInput') expiryDateInput: DateInputComponent;

	public bsModalRef: BsModalRef;
	isEditForm = false;
	@Input() isSaving = false;
	notes: any[];
	hasPermission = this.userQuery.hasPermission$;

	shouldShowExpiryDate = false;
	businessName$ = this.businessConfigQuery.businessName$;
	disableOnchanges = false;

	borrowingEntitiesDropdown: ViewDisplayValue[];

	emailClientTemplateFeature$ =
		this.businessConfigQuery.emailClientTemplateFeature$;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private businessConfigQuery: BusinessConfigQuery,
		private cd: ChangeDetectorRef,
		private loggerService: LoggerService,
		private blStaffsQuery: BLStaffsQuery,
		private userQuery: UserQuery,
		private clientService: ClientProfileService,
		private businessService: BusinessProfileService
	) {
		super();
		this.form = this.fb.group({
			approved: '',
			borrowingEntities: [],
			businessMortgage: '',
			businessRefix: '',
			customerServiceID: '',
			doubleSub: false,
			expectedSettlement: '',
			expiryDate: '',
			fixedPeriodEnd: '',
			fixedPeriodEndDate: '',
			interestRate: null,
			isActive: 1,
			lVR: '',
			loanDrawdown: '',
			loanNumber: ['', [Validators.required, NoWhitespaceValidator]],
			loanNumberSuffix: '',
			loanRate: '',
			loanRepayment: null,
			loanTerm: null,
			loanType: '',
			loanValue: [null, Validators.min(0)],
			mortgageType: '',
			note: '',
			notes: [],
			originalAdviser: ['', [Validators.required]],
			provider: ['', [Validators.required]],
			repaymentFrequency: '',
			required: '',
			security: [],
			status: ['', [Validators.required]],
			submitted: '',
		});
	}

	get status() {
		return this.form.get('status');
	}
	get provider() {
		return this.form.get('provider');
	}
	get loanNumber() {
		return this.form.get('loanNumber');
	}
	get originalAdviser() {
		return this.form.get('originalAdviser');
	}
	get formBorrowingEntities() {
		return this.form.get('borrowingEntities');
	}

	get customerServiceID() {
		return this.form.get('customerServiceID');
	}

	noteHeader = [
		{
			title: 'Created By',
		},
		{
			title: 'Created date & Time',
		},
		{
			title: 'Details',
		},
		{
			title: ' ',
		},
	];

	advisers$ = this.blStaffsQuery.adviserChoicesOption$;

	originalAdvisers$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.advisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								+x.value ===
									(this.mortgage ? this.mortgage.originalAdviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all
		)
	);
	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.isSaving && !this.isSaving) {
				this.prepData();
			}

			if (changes.mortgage) {
				if (
					changes.mortgage.previousValue?.notes?.length ===
					this.mortgage?.notes?.length
				) {
					this.prepData();
				} else {
					this.notes = this.mortgage.notes;
					this.form.patchValue({ notes: this.mortgage.notes });
				}
			}
		}
		this.setBorrowingEntities();
	}

	ngOnInit() {
		this.addMode ? this.form.enable() : this.form.disable();
		this.form.get('loanType').valueChanges.subscribe((x) => {
			if (x === 'Interest Only') {
				this.shouldShowExpiryDate = true;
			} else {
				this.shouldShowExpiryDate = false;
				this.form.controls.expiryDate.setValue(null);
			}
		});

		if (this.addMode) {
			zip(
				of(this.providers),
				of(this.statuses),
				of(this.refixStatus),
				of(this.fixedPeriods),
				of(this.loanTypes),
				of(this.repaymentFrequency)
			)
				.pipe(
					take(1),
					map((ddListList) => {
						const defaultValueList: string[] = ddListList
							?.map((ddList) => ddList?.find((dd) => dd.isDefault))
							?.map((def) => def && def.value);
						return defaultValueList;
					})
				)
				.subscribe(this.setDropdownDefaults);
			
				// patch Business Mortgage to true
				patchValue<any>(this.form, {
					businessMortgage: true
				})
		}

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap(() => this.setBorrowingEntities()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([
		p,
		st,
		rs,
		fp,
		lt,
		rf,
	]) => {
		patchValue<any>(this.form, {
			provider:
				this.mortgage && this.mortgage.provider ? this.mortgage.provider : p,
			repaymentFrequency:
				this.mortgage && this.mortgage.repaymentFrequency
					? this.mortgage.repaymentFrequency
					: rf,
			status:
				this.addMode && this.mortgage && this.mortgage.status
					? this.mortgage.status
					: st,
			businessRefix:
				!this.addMode && this.mortgage && this.mortgage.businessRefix
					? this.mortgage.businessRefix
					: rs,
			fixedPeriodEnd:
				this.addMode && this.mortgage && this.mortgage.fixedPeriodEnd
					? this.mortgage.fixedPeriodEnd
					: fp,
			loanType:
				this.addMode && this.mortgage && this.mortgage.loanType
					? this.mortgage.loanType
					: lt,
		});
	};

	refresh() {
		this.cd.detectChanges();
	}

	setBorrowingEntities() {
		this.borrowingEntitiesDropdown = this.getOwnerChoices(
			this.formBorrowingEntities.value
		);
	}

	getOwnerChoices(owners: (string | number)[]) {
		if (this.isCompany) {
			return this.businessService.getOwnerChoices(
				owners || [],
				this.borrowingEntities
			);
		}
		return this.clientService.getOwnerChoices(
			owners || [],
			this.borrowingEntities
		);
	}

	prepData() {
		this.notes =
			this.mortgage && this.mortgage.notes && this.mortgage.notes.length > 0
				? this.mortgage.notes
				: null;

		this.shouldShowExpiryDate =
			this.mortgage &&
			this.mortgage.loanType === 'Interest Only' &&
			!this.addMode
				? true
				: false;

		if (this.addMode) {
			this.form.reset(MortgageMapper.mapToAddView(this.mortgage));
			if (
				this.isNewGroup ||
				!this.mortgage ||
				(this.mortgage && this.mortgage.mortgageType !== 'Primary')
			) {
				this.form.controls.mortgageType.setValue('Primary');
			}
		} else {
			this.form.reset(MortgageMapper.mapToView(this.mortgage));
		}
		this.setBorrowingEntities();
	}

	transferGroup() {
		const newList = JSON.parse(JSON.stringify(this.mList));

		const mappedList = newList
			?.map((data) => {
				return {
					serviceCode: 'm',
					id: data.provider + '-' + data.loanNumber,
					name: data.provider + ' - ' + data.loanNumber,
					total: data.totalLending,
					startDate: data.fixedUntil,
					color: data.color,
				};
			})
			?.filter(
				(x) => x.id !== this.mortgage.provider + '-' + this.mortgage.loanNumber
			);

		const initState = {
			groupList: mappedList,
			pageTitle: 'Transfer Split',
		};
		this.bsModalRef = this.modalService.show(TransferServiceComponent, {
			class: 'modal-dialog-centered modal-md',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.bsModalRef.content.getTransferedValue.subscribe((data) => {
			// do the stuff here
			if (data) {
				const form = this.form.value;
				const mappedForm = MortgageMapper.mapToUpsert(form);

				const dash = data.id?.split(/[-]/);
				mappedForm.provider = dash[0];
				mappedForm.loanNumber = dash[1];

				this.splitEvent.emit({ data: mappedForm, isAddNote: false });
			}
		});
	}

	cancel() {
		if (this.addMode) {
			const confirm = new Observable((obs: Observer<any>) => {
				if (this.addMode) {
					this.cancelAddEvent.emit(true);
				}
				this.isEditForm = false;
				this.prepData();
				this.form.disable();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: 'Discard Confirmation',
				message: `Current information will be discarded?`,
				confirm$: confirm,
				decline$: decline,
			};

			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.isEditForm = false;
			this.prepData();
			this.form.disable();

			const data = MortgageMapper.mapToView(this.form.getRawValue());

			if (!data.submitted['_i']) {
				this.submittedDateInput.reset();
			}
			if (!data.approved['_i']) {
				this.approvedDateInput.reset();
			}
			if (!data.loanDrawdown['_i']) {
				this.loanDrawdownDateInput.reset();
			}
			if (!data.expectedSettlement['_i']) {
				this.expectedSettlementDateInput.reset();
			}
			if (!data.fixedPeriodEndDate['_i']) {
				this.fixedPeriodEndDateInput.reset();
			}
			if (this.shouldShowExpiryDate && !data.expiryDate['_i']) {
				this.expiryDateInput.reset();
			}
		}
	}

	collapseForm(collapse: boolean) {
		collapse ? this.collapse.Open() : this.collapse.Close();
	}

	formSaving(isSaving: boolean) {
		this.isSaving = isSaving;
		this.refresh();
	}

	editForm(isEdit: boolean, editFromParent: boolean) {
		this.isEditForm = isEdit;
		isEdit ? (this.form.enable(), this.collapse.Open()) : this.form.disable();
		if (!editFromParent) {
			this.prepData();
		}
		this.refresh();
	}

	save(isAddNote?: boolean) {
		if (
			!this.form.valid ||
			this.submittedDateInput?.isInvalid() ||
			this.approvedDateInput?.isInvalid() ||
			this.loanDrawdownDateInput?.isInvalid() ||
			this.expectedSettlementDateInput?.isInvalid() ||
			this.fixedPeriodEndDateInput?.isInvalid() ||
			(this.shouldShowExpiryDate && this.expiryDateInput?.isInvalid())
		) {
			if (this.form.value.loanNumber) {
				const isWhiteSpace =
					this.form.value.loanNumber.length > 0 &&
					(this.form.value.loanNumber?.toString() || '')?.trim().length === 0;
				if (isWhiteSpace) {
					this.loggerService.Warning({}, getRequiredWarning(Fields.LoanNumber));
					return;
				}
			}
			if (!this.form.value.loanNumber) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.LoanNumber));
				return;
			}
			if (!this.form.value.status) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Status));
				return;
			}
			if (!this.form.value.provider) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Provider));
				return;
			}
			if (!this.form.value.originalAdviser) {
				this.loggerService.Warning(
					{},
					getRequiredWarning(Fields.OriginalAdviser)
				);
				return;
			}

			if (this.submittedDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.SubmittedDate));
				return;
			}
			if (this.approvedDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.Approved));
				return;
			}
			if (this.loanDrawdownDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.LoanDrawdown));
				return;
			}
			if (this.expectedSettlementDateInput?.isInvalid()) {
				this.loggerService.Warning(
					{},
					getInvalidWarning(Fields.ExpectedSettlement)
				);
				return;
			}
			if (this.fixedPeriodEndDateInput?.isInvalid()) {
				this.loggerService.Warning(
					{},
					getInvalidWarning(Fields.FixedPeriodEndDate)
				);
				return;
			}
			if (this.shouldShowExpiryDate && this.expiryDateInput?.isInvalid()) {
				this.loggerService.Warning(
					{},
					getInvalidWarning(Fields.InterestOnlyExpiry)
				);
				return;
			}
		}

		if (isNaN(this.form.value.loanValue) || +this.form.value.loanValue < 0) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.LoanValue));
			return;
		}

		if (isNaN(this.form.value.loanTerm)) {
			this.loggerService.Warning(
				{},
				getInvalidWarning(Fields.OriginalLoanTerm)
			);
			return;
		}

		if (isNaN(this.form.value.interestRate)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.InterestRate));
			return;
		}

		const form = this.form.value;
		form.customerServiceID = this.mortgage
			? this.mortgage.customerServiceID
			: '';
		form.isActive = this.addMode ? 1 : this.mortgage.isActive;
		form.note = form.note;
		form.interestRate =
			form.interestRate === '' ||
			form.interestRate === undefined ||
			R.isNil(form.interestRate)
				? null
				: +form.interestRate;
		form.loanRepayment =
			form.loanRepayment === '' ||
			form.loanRepayment === undefined ||
			R.isNil(form.loanRepayment)
				? null
				: +form.loanRepayment;
		const data = MortgageMapper.mapToUpsert(form);
		if (this.addMode) {
			this.saveEvent.emit(data);
		} else {
			this.saveEvent.emit({ data, isAddNote });
		}
	}

	archive(isArchive) {
		const msg = this.mortgage.isActive === 1 ? 'archive' : 'unarchive';
		const initState: any = {
			confirm$: new Observable((obs) => {
				this.form.reset(MortgageMapper.mapToView(this.mortgage));
				const data = MortgageMapper.mapToUpsert(this.form.value);

				this.archiveEvent.emit({ mortgage: data, isArchive });
				obs.complete();
			}),
			header: 'Archive service',
			message: `Are you sure you want to ${msg} this item?`,
			isArchive: isArchive ? true : false,
		};
		this.modalService.show(ArchiveModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	delete() {
		const data = MortgageMapper.mapToUpsert(this.mortgage);

		this.deleteEvent.emit(data);
	}

	deleteConfirm() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const initState = {
			header: 'Delete',
			message: `Are you sure you want to delete ?`,
			delete$: confirm,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteNote$ = (data) => {
		return new Observable((obs) => {
			const form = this.form.value;
			this.deleteNoteEvent.emit({ noteId: data.notesID, data: form });
			obs.next();
			obs.complete();
		});
	};

	borrowingEntitiesName(mortgage) {
		const parseBorrowingEntities = mortgage.borrowingEntities
			? JSON.parse(mortgage.borrowingEntities)
			: '';

		if (parseBorrowingEntities) {
			const b = parseBorrowingEntities
				?.map((value) =>
					this.borrowingEntities
						?.filter((be) => be.value === value)
						?.map((x) => x.display)
				)
				?.filter((x) => x.length > 0);

			if (b.length === 0) {
				return '';
			}
			return b ? b?.reduce((acc, v) => acc?.concat(v))?.join(', ') : '';
		}

		return '';
	}

	openModalAddNote() {
		const saveNote = (notes: string) =>
			new Observable((obs) => {
				const form = MortgageMapper.mapToView(this.mortgage);
				form.note = notes;
				this.addNoteEvent.emit(form);
				obs.next();
				obs.complete();
			});
		const initState: any = {
			header: 'Add Note',
			savefn: saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	trackByValue(index, item: ViewDisplayValue) {
		return item.value;
	}

	openEmailPopup() {
		if (this.isCompany) {
			this.openEmailBusinessPopup();
		} else {
			this.openEmailClientPopup();
		}
	}

	private openEmailBusinessPopup() {
		this.businessService.openECModalSubject$.next({
			documentType: ServicesCodes.Mortgage,
			type: ServicesCodes.NotesMortgage,
			referenceId: this.customerServiceID.value || 0,
			data: this.form.value,
		});
	}

	private openEmailClientPopup(): void {
		this.clientService.openECModalSubject$.next({
			documentType: ServicesCodes.Mortgage,
			type: ServicesCodes.NotesMortgage,
			referenceId: this.customerServiceID.value || 0,
			data: this.form.value,
		});
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
