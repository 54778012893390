import { Component, Input, OnInit } from '@angular/core';
import * as R from 'ramda';
import { combineLatest, iif, Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { MergeTagsMapper } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { convertUtil, objectUtil } from '../../../../util/util';
import { EndProcessEmailSettingsState } from '../state/end-process.model';
import { EndProcessEmailSettingsMapper } from '../state/end-process.mapper';
import { EndProcessEmailSettingsService } from '../state/end-process.service';

@Component({
	selector: 'app-end-process-email-settings',
	templateUrl: './end-process-email-settings.component.html'
})
export class EndProcessEmailSettingsComponent implements OnInit {
	@Input() staffId: number;
	@Input() emailSettings$: Observable<EndProcessEmailSettingsState>;
	@Input() mergeTags$: Observable<MergeTagState[]>;

	isLoading = false;
	bodyContent = '<p></p>';
	bodyId: number;
	shortCodes: object;
	emailSettings: EndProcessEmailSettingsState;

	constructor(private endProcessService: EndProcessEmailSettingsService) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		combineLatest([this.emailSettings$, this.mergeTags$])
			.pipe(
				filter(([data, mt]) => !!data && !!mt),
				tap(([data, mt]) => {
					this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt);
					this.emailSettings = data;
				}),
				tap(([data]) => (this.bodyId = data?.body ? +data?.body : 0)),
				map(([data]) =>
					EndProcessEmailSettingsMapper.mapEmailSettingsData(data)
				),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.endProcessService.getFile(data?.body ? +data.body : 0)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.endProcessService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save(emailSettings) {
		this.isLoading = true;
		of(emailSettings)
			.pipe(
				mergeMap((data) => convertUtil.convertToBase64(data?.bodyContent)),
				map((body) =>
					EndProcessEmailSettingsMapper.mapStaffEmailBody(body, +this.bodyId, 0)
				),
				concatMap((data) =>
					iif(
						() => R.isNil(data.documentId),
						this.endProcessService
							.newFileUpload(data)
							.pipe(catchError((err) => of(err))),
						this.endProcessService.updateFileUpload(data)
					)
				),
				concatMap((data) => {
					const isNewBody = this.bodyId === 0 || !this.bodyId;
					const newBodyId = isNewBody ? data : +this.bodyId;
					const formValue =
						EndProcessEmailSettingsMapper.mapEmailSettingsToUpsert({
							...emailSettings,
							referenceId: 0,
							body: newBodyId,
						});
					this.bodyId = newBodyId;
					return this.endProcessService.update(formValue);
				}),
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe();
	}
}
