import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, Subject } from 'rxjs';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { logMessage } from '../../error-message/error-message';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import { AssetState, AssetStatus } from '../../models/services/assets/assets';
import { AssetsMapper } from '../../models/services/assets/assets.mapper';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';

@Component({
  selector: 'app-assets-service-form',
  templateUrl: './assets-service-form.component.html',
  styleUrls: ['./assets-service-form.component.scss']
})
export class AssetsServiceFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() owners: ViewDisplayValue[];
  @Input() isLead: boolean;
	@Input() isCompany: boolean;
  @Input() assetState: AssetState;
  @Input() assetStatus: string;
  @Input() customerID: string;
  @Input() assets: AssetState;
  @Output() deleteNoteEvent = new EventEmitter<any>();
  @Output() cancelAddEvent = new EventEmitter<boolean>();
  @Output() addNoteEvent = new EventEmitter<any>();
  @Output() addAssetEvent = new EventEmitter<any>();
  @Output() saveAssetEvent = new EventEmitter<any>();
  @Output() deleteAssetEvent = new EventEmitter<any>();
  @Output() soldAssetEvent = new EventEmitter<any>()
	@Input() isUpdatingOrAdding = false;

  @Output() addLiabilityNoteEvent = new EventEmitter<any>();

  onDestroy$ = new Subject<void>();
  assetOptions$: Observable<ViewDisplayValue[]> = this.dropdownQuery.orderedChoices$('APCRTA');

  form: UntypedFormGroup;
  bsModalRef: BsModalRef;
  elseMinusOthers = true;
  submitted = false;
  isLoading = false;
  addMode = false;
  editMode = false;
  isSaving = false;
  notes: any[];
  total = 0;

  constructor(
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    private loggerService: LoggerService,
    protected dropdownQuery: DropdownValueQuery,
    private cd: ChangeDetectorRef,
    private clientService: ClientProfileService,
		private businessService: BusinessProfileService
  ) {
    this.buildForm();
    if (!this.assetState) {
      this.assetState = Object.assign(
        {},
        {
          notes: [],
          customerServices: []
        }
      );
    }
  }

  get AssetsArray() {
    return this.form.get('assetsArray') as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.isSaving && !this.isSaving) {
        this.prepareData();
      }

      if (changes.asset) {
        this.isSaving = false;
        if (changes.assetState.previousValue?.notes.length === this.assetState.notes.length) {
          this.prepareData();
        } else {
          this.notes = this.assetState.notes;
          this.form.patchValue({ notes: this.assetState.notes });
        }
      }
			if (changes.assetState) {
				setTimeout(() => {
					if (!!changes?.assetState?.previousValue) {
						this.clearFormArray(this.AssetsArray);
						this.prepareData();
						this.cd.detectChanges();
					}
				}, 10);
			}
    }
  }

  ngOnInit(): void {
    this.prepareData();
  }

  buildForm() {
    this.form = this.fb.group({
      assetsArray: this.fb.array([]),
    });
  }

	clearFormArray = (formArray: UntypedFormArray) => {
		if (!formArray) {
			return;
		}
		while (formArray.length > 0) {
			formArray?.removeAt(0);
		}
	};

  prepNotes() {
    this.notes = this.assets?.notes;
    this.refresh();
  }

  prepareData() {
    this.prepNotes();
    this.assets?.customerServices?.forEach((data, index) => {
      const mappedData = AssetsMapper.mapToView(data);
      if (data.status === this.assetStatus || (this.assetStatus === AssetStatus.Current && !data.status)) {
        this.AssetsArray.push(
          this.fb.group({
            customerID: [mappedData?.customerID ?? 0],
            customerServiceID: [mappedData?.customerServiceID ?? 0],
            asset: [mappedData.asset, [Validators.required]],
            owner: [mappedData.owner, [Validators.required]],
            value: [mappedData.value, [Validators.required, Validators.min(0.01)]],
            description: [mappedData.description],
            isEdit: [false],
            isLoading: [false],
            status: mappedData.status
          })
        )
        this.total = this.total + mappedData.value;
      }
    })
    this.refresh();
    this.form.disable();
  }

  addNew() {
		if (this.addMode || this.editMode) {
			return;
		}
		this.addMode = true;
    this.isLoading = true;
    this.AssetsArray.push(
      this.fb.group({
        customerID: [this.customerID],
        customerServiceID: [0],
       asset: ['', [Validators.required]],
        owner: ['', [Validators.required]],
        value: ['', [Validators.required, Validators.min(0.01)]],
        description: [''],
        isEdit: [true],
        isLoading: [false],
      })
    )
    this.isLoading = false;
  }

	getOwnerChoices(owners: (string | number)[]) {
		if (this.isCompany) {
			return this.businessService.getOwnerChoices(owners || [], this.owners)
				?.map((x) => ({ ...x, value: +x?.value }));
		}
		return this.clientService
			.getOwnerChoices(owners || [], this.owners)
			?.map((x) => ({ ...x, value: +x?.value }));
	}

  cancelEdit(index: number) {
    this.resetFormItemValue(index);
    this.disableFormItem(index);
		this.editMode = false;
  }

  cancelAsset(index: number) {
    this.AssetsArray.removeAt(index);
		this.addMode = false;
  }

  resetFormItemValue(index: number) {
    const latestSaved = this.assets.customerServices[index];
    this.AssetsArray.controls[index].reset(AssetsMapper.mapToView(latestSaved));
  }

  disableFormItem(index) {
    this.AssetsArray.controls[index].get('isEdit')?.setValue(false);
    this.AssetsArray.controls[index].get('isLoading')?.setValue(false);
    this.AssetsArray.controls[index].disable();
  }

  enableFormItem(index) {
    this.AssetsArray.controls[index].enable();
    this.AssetsArray.controls[index].get('isEdit')?.setValue(true);
		this.editMode = true;
  }

  deleteAsset(index: number) {
    this.isLoading = true;
    const formValue = this.AssetsArray.controls[index].value;
    const data = AssetsMapper.mapToUpsert(formValue);
    this.AssetsArray.controls[index].get('isLoading').setValue(false);
    this.AssetsArray.controls[index].get('isEdit').setValue(false);
    this.deleteAssetEvent.emit(data);
    this.isLoading = false;
  }

  saveAsset(index, isAdd?: boolean) {
    this.isLoading = true;
    this.submitted = true;
    this.AssetsArray.controls[index].markAllAsTouched();

    if (this.AssetsArray.controls[index].invalid) {
      this.AssetsArray.controls[index].get('isLoading').setValue(false);
      this.isLoading = false;
      this.loggerService.Warning(null, logMessage.shared.general.warning.required);
      return;
    }
    const formValue = this.AssetsArray.controls[index].value;
    const data = AssetsMapper.mapToUpsert(formValue);
    this.AssetsArray.controls[index].get('isLoading').setValue(true);
    if (isAdd) {
      this.addAssetEvent.emit(data);
      this.isLoading = false;
      this.AssetsArray.controls[index].get('isLoading').setValue(false);
      this.AssetsArray.controls[index].get('isEdit').setValue(false);
    } else {
      this.AssetsArray.controls[index].get('isLoading').setValue(false);
      this.AssetsArray.controls[index].get('isEdit').setValue(false);
      this.saveAssetEvent.emit(data);
      this.isLoading = false;
    }
		this.addMode = false;
		this.editMode = false;
  }

  deleteModal(index: number) {
    const confirm = new Observable((obs: Observer<any>) => {
      this.deleteAsset(index);
      obs.complete();
    });
    const initState = {
      header: 'Delete Asset',
      message: `Are you sure you want to delete this?`,
      delete$: confirm,
      canDelete: true,
    };

    this.modalService.show(DeleteModalComponent, {
      class: 'modal-dialog-centered',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  markAsSold(index: number) {
    this.isLoading = true;
    this.AssetsArray.controls[index].get('status').setValue(AssetStatus.Sold);
    const formValue = this.AssetsArray.controls[index].value;
    const data = AssetsMapper.mapToUpsert(formValue);
    this.AssetsArray.controls[index].get('isLoading').setValue(false);
    this.AssetsArray.controls[index].get('isEdit').setValue(false);
    this.soldAssetEvent.emit(data);
    this.isLoading = false;
  }

  trackByFn(i, data) {
    return data?.customerServiceID;
  }

  // Notes
  openModalAddNote() {
    const saveNote = (notes: string) =>
      new Observable(obs => {
        this.addNoteEvent.emit({ note: notes, customerID: this.customerID });
        obs.next();
        obs.complete();
      });
    const initState: any = {
      header: 'Add Note',
      savefn: saveNote,
    };
    this.modalService.show(NoteAddModalComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  deleteNote$ = (data) => {
    return new Observable(obs => {
      this.isSaving = true;
      const form = this.form.value;
      const mappedForm = AssetsMapper.mapToUpsert(form);

      this.deleteNoteEvent.emit({ noteId: data.notesID, data: mappedForm });
      obs.next();
      obs.complete();
    });
  }

  refresh() {
    this.cd.detectChanges();
  }

  formSaving(isSaving: boolean) {
    this.isSaving = isSaving;
    this.refresh();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

}
