import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { DependentDetailsState } from '../../../../../../shared/models/client-review-template/dependent/dependent-details';
import { DependentMapper } from '../../../../../../shared/models/client-review-template/dependent/dependent.mapper';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { NoWhitespaceValidator } from 'src/app/shared/directive/no-whitespace/no-whitespace.directive';
import { RelationshipTypes } from 'src/app/shared/models/_general/client.model';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-dependents-form',
	templateUrl: './dependents-form.html',
	styleUrls: ['./dependents-form.scss'],
})
export class DependentsFormComponent implements OnInit {
	@Input() dependentInfo: DependentDetailsState;
	@Output() saveEvent = new EventEmitter<DependentDetailsState>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() gender: ViewDisplayValue[];
	@Input() relationship: ViewDisplayValue[];
	@Input() loadingState: boolean;
	@Input() viewMode: boolean;
	@Input() loatv2Enabled: boolean;
	public bsModalRef: BsModalRef;

	form: UntypedFormGroup;
	submitted = false;

	cancelLoading: boolean;

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();

		if (!this.dependentInfo) {
			this.form.patchValue({
				relationship: this.relationship?.find(
					(x) => x.display === RelationshipTypes.Child
				).value,
			});
		}

		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	}

	prepData() {
		this.form.reset(DependentMapper.mapToView(this.dependentInfo));
	}

	get FirstName() {
		return this.form.get('firstName');
	}
	get LastName() {
		return this.form.get('lastName');
	}

	buildForm() {
		this.form = this.fb.group({
			firstName: [null, [Validators.required, NoWhitespaceValidator]],
			lastName: [null, [Validators.required, NoWhitespaceValidator]],
			gender: [''],
			relationship: [''],
			dateOfBirth: [''],
			knownAs: [''],
			physicalAddress: [''],
			email: [''],
		});
	}

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (!this.form.valid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		const data = DependentMapper.mapToUpsert(
			this.form.value,
			this.dependentInfo
		);
		this.saveEvent.emit(data);
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => (this.cancelLoading = true), 500);
	}
}
