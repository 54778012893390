<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
    close
  </span>
</button>
<div class="modal-header">
  <h1 class="modal-title">
    {{ header }}
  </h1>
  >
</div>

<div class="modal-body">
  <form novalidate [formGroup]="form">
    <div class="form-group m-0">
      <div class="clearfix">
        <div class="checkbox-list py-2" [class.odd]="odd" *ngFor="let secGroup of securityGroups; let odd=odd">
          <input id="securityGroupName" type="checkbox" class="mb-1 mr-2"
            (change)="onChange(secGroup.securityGroupCode, $event.target.checked)" />
          <span>{{ secGroup.securityGroupName }}</span>
        </div>
      </div>
    </div>

    <div class="justify-content-end float-right modal-footer">
      <ng-container *ngIf="!isSaving; else loader">
        <button id="saveButton" type="button" class="tap-btn tap-btn--default" (click)="save(form.value)">
          Save
        </button>
      </ng-container>
      <ng-template #loader>
        <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
      </ng-template>
    </div>
  </form>
</div>
