import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AStore, ActivityState } from './a.store';
import { ActivityModel } from '../../../../../../shared/models/_general/activity.model';

@Injectable()
export class AQuery extends QueryEntity<ActivityState, ActivityModel> {
  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.selectLoading();
  /**
   * error message to show.
   */
  error$ = this.selectError();
  /**
   * Create Activities query
   * Filter all not completed activities
   */
  data$ = this.selectAll({
    filterBy: [
      entity => entity.IsCompleted === false && entity.IsCancelled === false
    ]
  });

  /**
   * Get activity columns
   */
  // columns$ = this.select(x => x.columns);

  constructor(protected store: AStore) {
    super(store);
  }
}
