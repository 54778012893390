import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FGPremiumStatsDomestic } from './state/fgpsd.model';
import { map, filter, takeUntil, delay } from 'rxjs/operators';
import { DashboardQuery } from '../../state/dashboard.query';
import { FGPremiumStatsDomesticQuery } from './state/fgpsd.query';
import { FGPremiumStatsDomesticService } from './state/fgpsd.service';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { BipolarBarChartComponent } from '../../../../../shared/bipolar-bar-chart/bipolar-bar-chart.component';

@Component({
  selector: 'app-fgpsd',
  templateUrl: './fgpsd.component.html',
})
export class FgpsdComponent implements OnInit, OnDestroy {
  public static widgetCode = 'fgpsd';
  public static widgetName = 'F&G Premium Stats - Rolling 12 Months - Domestic';
  public static sizeX = 1000;
  public static sizeY = 400;
  public static minSizeX = 1000;
  // public static maxSizeX = 1000;
  public static minSizeY = 400;
  public static maxSizeY = 400;

  widgetCode = FgpsdComponent.widgetCode;
	chartId = 'fgpsd-bar-chart';

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * Bipolar Bar chart element. referenced for manual refreshing
   * when gridstack resizes div.
   */
  @ViewChild('fgpsdBipolarBarChart') fgpsdBarChart: BipolarBarChartComponent;

  /**
   * FGPSD Data for Rolling 12 Months (Year) (x3)
   */
  data$: Observable<FGPremiumStatsDomestic[]> = this.query.FGPremiumStatsDomesticData$;

  viewData$ = this.data$.pipe(
    map((data) => {
      return {
        series: [
          { name: 'New Business', data: data?.map(x => x.NewBusiness) },
          { name: 'Renewal', data: data?.map(x => x.Renewal) },
          { name: 'MTA', data: data?.map(x => x.MTA) }
        ],
        labels: data?.map(x => x.Month)
      };
    })
  );

  constructor(
    private dashboardQuery: DashboardQuery,
    private query: FGPremiumStatsDomesticQuery,
    private service: FGPremiumStatsDomesticService,
    private dashboard: DashboardComponent) { }

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of annual API stats.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$.pipe(
      filter(x => x.length > 0),
      takeUntil(this.onDestroy$)
    ).subscribe(advisers => {
      this.service.getFGPremiumStatsDomesticStats(advisers);
    });

    this.dashboard.gridChangeEvent.pipe(
      delay(200),
      takeUntil(this.onDestroy$)
    ).subscribe((res) => {
      if (res === FgpsdComponent.widgetCode) {
        this.fgpsdBarChart.refresh();
      }
    });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
