import { Component, OnInit, OnDestroy, Output, EventEmitter, Renderer2, TemplateRef, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, AbstractControl } from '@angular/forms';
import { LrInsuranceRequestModel, LrInsuranceRequest } from '../lr-insurance-request.model';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { LrInsuranceService } from '../states/lr-insurance.service';
import { takeUntil, map, withLatestFrom, first, finalize, take, tap, mergeMap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { LrInsuranceQuery } from '../states/lr-insurance.query';
import MomentUtil from '../../../../../util/moment.util';
import { UserQuery } from '../../../../../domain/user/user.query';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { ActivatedRoute } from '@angular/router';
import { strUtil } from '../../../../../util/util';
import { RecursivePartial } from '../../../../../core/util/util.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LrInsuranceUiQuery } from '../states/lr-insurance-ui.query';
import { request } from '../lr-insurance.util';
import { LrInsuranceUiStore } from '../states/lr-insurance-ui.store';
import { ConfigService } from '../../../../../core/config/config.service';
import { AdviserStatusViewDisplay } from '../../pipeline.model';
import * as R from 'ramda';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsStatus, ExportsType } from '@modules/exports/state/exports.model';
import { ExportsService } from '@modules/exports/state/exports.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
declare let $: any;

const patchValue: <T>(form: AbstractControl, value: RecursivePartial<T>) => void = (form, value) => {
  form.patchValue(value);
};

@Component({
  selector: 'app-lr-insurance-form',
  templateUrl: './lr-insurance-form.component.html',
  styleUrls: ['./lr-insurance-form.component.scss'],
})
export class LrInsuranceFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() toggleSearch = new EventEmitter<{
    showSearch: boolean;search
    height: number;
    width: number;
  }>();

  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild(PopoverDirective) popover: PopoverDirective;

  exportStatus$ = this.exportsQuery.status$;

  isSearching$ = this.lrInsuranceQuery.uiQuery.isSearching$;
  isExporting$ = this.lrInsuranceQuery.uiQuery.isExporting$;
  columnFromOpen$ = this.lrInsuranceQuery.uiQuery.columnFormPopupOpen$;

  openColumnPopup = this.lrInsuranceService.togglePopup;
  count$ = this.lrInsuranceQuery.count$;
  totalApi$ = this.lrInsuranceQuery.totalApi$;

  isTapLevel$ = this.userQuery.isTapLevel$;
  userInfo$ = this.userQuery.userInfo$;
  adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

  // Get Active and (Paused and Inactive A and AM)
  availableStaffChoices$: Observable<ViewDisplayValue[]> = this.bLStaffsQuery.LRAdviserChoicesOption$;
  LRP$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('LRP');
  LRPT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('LRPT');
  LRCS$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('LRCS');
  LRPS$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('LRPS').pipe(
    withLatestFrom(this.configService.CompanyCode),
    map(([ps, cc]) =>
      cc.toLowerCase() === 'haven'
        ? ps.filter(x => x.display !== 'Lead').filter(y => y.display !== 'First Meeting')
        : ps
    )
  );
  LRRS$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('LRRS');
  PLRS$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('PLRS');
  PCLE$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('PCLE');
  PCLT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('PCLT');

  AdviserStatus$: Observable<ViewDisplayValue[]> = of(AdviserStatusViewDisplay);

  form: UntypedFormGroup = this.fb.group({
    SelectedAdvisers: this.fb.control([]),
    SelectedAltAdvisers: this.fb.control([]),
    SelectedAdviserStatuses: this.fb.control([]),

    SelectedInsuranceProviders: this.fb.control([]),

    SelectedPolicyStatus: this.fb.control([]),
    SelectedRetentionStatus: this.fb.control([]),
    SelectedClaimStatus: this.fb.control([]),

    SelectedInsuranceTypes: this.fb.control([]),
    SelectedLeadTypes: this.fb.control([]),
    SelectedLeadOrigins: this.fb.control([]),

    NextActivityDateMin: this.fb.control(null),
    NextActivityDateMax: this.fb.control(null),
    PolicyStartDateMin: this.fb.control(null),
    PolicyStartDateMax: this.fb.control(null),
    NextReviewDateMin: this.fb.control(null),
    NextReviewDateMax: this.fb.control(null),
    SubmittedDateMin: this.fb.control(null),
    SubmittedDateMax: this.fb.control(null),
  } as { [key in keyof LrInsuranceRequestModel]: UntypedFormControl });

  showSearch = false;
  isTapLevel: boolean;

  params$ = this.route.paramMap;

  ////////// Progress Bar //////////////
  progress = {
    width: '0%',
  };
  index = 0;
  percent = 0;
  count = 0;
  totalCount = 0;
  modalRef: BsModalRef;
  msg = 'Export Progress';
  ///////// END Progress Bar ////////
  constructor(
    private fb: UntypedFormBuilder,
    private lrInsuranceService: LrInsuranceService,
    private lrInsuranceQuery: LrInsuranceQuery,
    private bLStaffsQuery: BLStaffsQuery,
    private userQuery: UserQuery,
    private dropdownValueQuery: DropdownValueQuery,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    public lrInsuranceUiQuery: LrInsuranceUiQuery,
    public lrInsuranceUiStore: LrInsuranceUiStore,
    private configService: ConfigService,
    private exportsQuery: ExportsQuery,
    private exportsService: ExportsService,
    protected businessConfigQuery: BusinessConfigQuery
  ) { }

  ngOnInit() {
    const policyStatusList = [
      'Submitted',
      'Underwriting',
      'Notes/Tests Required',
      'Offer of terms',
      'Policy Accepted',
      'Underwriting-Info Req',
      'Inforce-Pending',
      'Incomplete APP',
    ];
    this.params$
      .pipe(
        withLatestFrom(this.isTapLevel$, this.userInfo$, this.LRPS$),
        map(([params, isTapLevel, userinfo, lrps]) => {
          this.isTapLevel = isTapLevel;
          const adv = params.get('advisers');
          const stat = params.get('statuses');
          const adviserStat = params.get('adviserStatuses');
          if (params && (!!adv || stat)) {
            const req = JSON.parse(JSON.stringify(this.prepareFormValue()));
            this.lrInsuranceService.setSearchForm({
              ...req,
              SelectedAdvisers: !!adv ? adv?.split(',') : [],
              SelectedPolicyStatus: strUtil.stringIsNotEmpty(stat)
                ? stat?.split(',').filter(x => lrps?.find(y => y.value === x))
                : [],
                SelectedAdviserStatuses: strUtil.stringIsNotEmpty(adviserStat)
                ? adviserStat?.split(',')
                : []
            });
            return {
              hasStatus: stat ? true : false,
              SelectedAdvisers: !!adv ? adv?.split(',') : [],
              SelectedPolicyStatus: strUtil.stringIsNotEmpty(stat)
                ? stat?.split(',').filter(x => lrps?.find(y => y.value === x))
                : [],
                SelectedAdviserStatuses: strUtil.stringIsNotEmpty(adviserStat)
                ? adviserStat?.split(',')
                : [],
            };
          } else if (!isTapLevel) {
            return {
              // Advisers: [userinfo.StaffID.toString()],
              SelectedPolicyStatus: policyStatusList?.filter(x => lrps?.find(y => y.value === x)),
            };
          } else {
            return {
              SelectedPolicyStatus: policyStatusList?.filter(x => lrps?.find(y => y.value === x)),
            };
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fValue => {
        this.reset();
        if (fValue) {
          patchValue(this.form, fValue);
          if (this.isTapLevel) {
            this.lrInsuranceService.sort('PolicyStatus', 'desc');
          } else {
            this.lrInsuranceService.sort('ClientNextActivity', 'desc');
          }
        }
        if (fValue && fValue.hasStatus) {
          this.search();
        }
      });
  }

  ngAfterViewInit() {
    if (this.lrInsuranceQuery.getValue().count > 0 || this.lrInsuranceQuery.getValue().searchForm) {
      const advisers = this.lrInsuranceQuery.getValue().searchForm.SelectedAdvisers;
      const altAdvisers = this.lrInsuranceQuery.getValue().searchForm.SelectedAltAdvisers;
      const p = this.lrInsuranceQuery.getValue().searchForm.SelectedInsuranceProviders;
      const cs = this.lrInsuranceQuery.getValue().searchForm.SelectedClaimStatus;
      const pt = this.lrInsuranceQuery.getValue().searchForm.SelectedInsuranceTypes;
      const ps = this.lrInsuranceQuery.getValue().searchForm.SelectedPolicyStatus;
      const rs = this.lrInsuranceQuery.getValue().searchForm.SelectedRetentionStatus;
      const lo = this.lrInsuranceQuery.getValue().searchForm.SelectedLeadOrigins;
      const lt = this.lrInsuranceQuery.getValue().searchForm.SelectedLeadTypes;
      const as = this.lrInsuranceQuery.getValue().searchForm.SelectedAdviserStatuses;

      const naMin = this.lrInsuranceQuery.getValue().searchForm.NextActivityDateMin;
      const naMax = this.lrInsuranceQuery.getValue().searchForm.NextActivityDateMax;
      const psMin = this.lrInsuranceQuery.getValue().searchForm.PolicyStartDateMin;
      const psMax = this.lrInsuranceQuery.getValue().searchForm.PolicyStartDateMax;
      const nrMin = this.lrInsuranceQuery.getValue().searchForm.NextReviewDateMin;
      const nrMax = this.lrInsuranceQuery.getValue().searchForm.NextReviewDateMax;
      const sMin = this.lrInsuranceQuery.getValue().searchForm.SubmittedDateMin;
      const sMax = this.lrInsuranceQuery.getValue().searchForm.SubmittedDateMax;

      this.form.get('SelectedAdvisers').setValue(advisers?.map(x => x?.toString()));
      this.form.get('SelectedAltAdvisers').setValue(altAdvisers?.map(x => x?.toString()));
      this.form.get('SelectedInsuranceProviders').setValue(p?.map(x => x?.toString()));
      this.form.get('SelectedClaimStatus').setValue(cs?.map(x => x?.toString()));
      this.form.get('SelectedInsuranceTypes').setValue(pt?.map(x => x?.toString()));
      this.form.get('SelectedPolicyStatus').setValue(ps?.map(x => x?.toString()));
      this.form.get('SelectedRetentionStatus').setValue(rs?.map(x => x?.toString()));
      this.form.get('SelectedLeadOrigins').setValue(lo?.map(x => x?.toString()));
      this.form.get('SelectedLeadTypes').setValue(lt?.map(x => x?.toString()));
      this.form.get('SelectedAdviserStatuses').setValue(as?.map(x => x?.toString()));

      this.form.get('NextActivityDateMin').setValue(MomentUtil.formatDateToMoment(naMin));
      this.form.get('NextActivityDateMax').setValue(MomentUtil.formatDateToMoment(naMax));
      this.form.get('PolicyStartDateMin').setValue(MomentUtil.formatDateToMoment(psMin));
      this.form.get('PolicyStartDateMax').setValue(MomentUtil.formatDateToMoment(psMax));
      this.form.get('NextReviewDateMin').setValue(MomentUtil.formatDateToMoment(nrMin));
      this.form.get('NextReviewDateMax').setValue(MomentUtil.formatDateToMoment(nrMax));
      this.form.get('SubmittedDateMin').setValue(MomentUtil.formatDateToMoment(sMin));
      this.form.get('SubmittedDateMax').setValue(MomentUtil.formatDateToMoment(sMax));
    }
  }

  public prepareFormValue(): LrInsuranceRequest {
    const formValue: formType = this.form.value;

    const req: LrInsuranceRequest = {
      ...formValue,
      SelectedAdvisers: formValue.SelectedAdvisers?.map(x => x),
      SelectedAltAdvisers: formValue.SelectedAltAdvisers?.map(x => x),
      NextActivityDateMin: MomentUtil.formatToServerDate(formValue.NextActivityDateMin),
      NextActivityDateMax: MomentUtil.formatToServerDate(formValue.NextActivityDateMax),
      PolicyStartDateMin: MomentUtil.formatToServerDate(formValue.PolicyStartDateMin),
      PolicyStartDateMax: MomentUtil.formatToServerDate(formValue.PolicyStartDateMax),
      NextReviewDateMin: MomentUtil.formatToServerDate(formValue.NextReviewDateMin),
      NextReviewDateMax: MomentUtil.formatToServerDate(formValue.NextReviewDateMax),
      SubmittedDateMin: MomentUtil.formatToServerDate(formValue.SubmittedDateMin),
      SubmittedDateMax: MomentUtil.formatToServerDate(formValue.SubmittedDateMax),
    };
    return req;
  }

  search() {
    const req = JSON.parse(JSON.stringify(this.prepareFormValue()));

    this.lrInsuranceUiQuery.currentSort$
      .pipe(
        first(),
        map(x => {
          req.Paging = {
            Index: 1,
            Column: x.propSort,
            Direction: x.sort,
          };
        }),
        finalize(() => {
          this.lrInsuranceService
            .search(req)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              setTimeout(() => $('datatable-body').scrollTop(1).scrollLeft(1), 1);
              setTimeout(() => $('datatable-body').scrollTop(0).scrollLeft(0), 1);
            });
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  /**
   * Api calls (Endpoint)
   * @returns void
   * Downloads file in .csv format
   */
  //////////////////////////////////// EXPORT /////////////////////////////////
  openModal(exportTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      exportTemplate,
      Object.assign(
        {},
        {
          class: 'modal-dialog-centered gray modal-xl w-50',
          ignoreBackdropClick: true,
        }
      )
    );
  }

  export() {
    const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

    this.index++;

    const req = request(
      this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
      this.prepareFormValue(),
      this.index
    );

    const newReq = Object.assign({}, R.omit(['ServiceCode'], req)) as any;

    const updateReq = {
      ...newReq,
      AdviserStatuses: newReq?.SelectedAdviserStatuses,
      Advisers: newReq?.SelectedAdvisers,
      AltAdvisers: newReq?.SelectedAltAdvisers,
      ClaimStatus: newReq?.SelectedClaimStatus,
      InsuranceProviders: newReq?.SelectedInsuranceProviders,
      InsuranceTypes: newReq?.SelectedInsuranceTypes,
      LeadOrigins: newReq?.SelectedLeadOrigins,
      LeadTypes: newReq?.SelectedLeadTypes,
      PolicyStatus: newReq?.SelectedPolicyStatus,
      RetentionStatus: newReq?.SelectedRetentionStatus,
    }

		this.exportsService.queueExport(updateReq, ExportsType.LR)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(this.exportsService.getExportStatus(id))
				)
			)
			.subscribe(
				(data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport2(data.DocumentLink);
						this.hidePopover();
					}
				},
				(err) => {
					this.hidePopover();
				}
			);

		
		setTimeout(() => {
			this.popover.hide();
		}, 2500);
  }

  computeProgess() {
    this.percent = +((this.index * 100) / this.totalCount).toFixed(2);
    this.progress = {
      width: `${this.percent}%`,
    };
  }

  downloadExport(file: any) {
    const name = `${this.route.snapshot.paramMap.get('companyCode')}-L&R.csv`;
    const a = this.renderer.createElement('a');
    this.renderer.setStyle(a, 'display', 'none');
    const url = window.URL.createObjectURL(file);
    this.renderer.setAttribute(a, 'href', url);
    this.renderer.setAttribute(a, 'download', name);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  downloadExport2(url: string) {
		const name = `${this.route.snapshot.paramMap.get('companyCode')}-Customer.csv`;

		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

  clearExportProgress() {
    this.index = 0;
    this.percent = 0;
    this.progress = {
      width: '0%',
    };
    this.totalCount = 0;
  }
  //////////////////////////////////// END EXPORT /////////////////////////////////

  toggle() {
    this.showSearch = !this.showSearch;
    document.body.style.overflowY = 'hidden';
    let x = 0;
    let data;
    const setIntervalHeight = setInterval(() => {
      if (x < 1 && !data) {
        x += 1;
        data = {
          showSearch: this.showSearch,
          height: $('form').height(),
          width: $('form').width(),
        };
      } else if (x > 0) {
        x += 1;
        if (data.height !== $('form').height()) {
          data.height = $('form').height();
        } else {
          this.toggleSearch.emit(data);
          clearInterval(setIntervalHeight);
        }
      }
    }, 100);
  }

  /** Reset form fields value */
  reset() {
    this.form.get('SelectedAdvisers').reset([]);
    this.form.get('SelectedAltAdvisers').reset([]);
    this.form.get('SelectedAdviserStatuses').reset([]);
    this.form.get('SelectedInsuranceProviders').reset([]);

    this.form.get('SelectedPolicyStatus').reset([]);
    this.form.get('SelectedRetentionStatus').reset([]);
    this.form.get('SelectedClaimStatus').reset([]);

    this.form.get('SelectedInsuranceTypes').reset([]);
    this.form.get('SelectedLeadTypes').reset([]);
    this.form.get('SelectedLeadOrigins').reset([]);

    this.form.get('NextActivityDateMin').reset(null);
    this.form.get('NextActivityDateMax').reset(null);
    this.form.get('PolicyStartDateMin').reset(null);
    this.form.get('PolicyStartDateMax').reset(null);
    this.form.get('NextReviewDateMin').reset(null);
    this.form.get('NextReviewDateMax').reset(null);
    this.form.get('SubmittedDateMin').reset(null);
    this.form.get('SubmittedDateMax').reset(null);
  }

  showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}

type formType = { [key in keyof LrInsuranceRequestModel]: any };
