import { AdviceProcessSectionCodes } from '../../../../shared/models/advice-process/advice-process.model';
import { DefaultFileNames, DocumentTypes } from '../../../../shared/models/documents/document.model';
import { EndProcessEmailSettingsState } from './end-process.model';

export class EndProcessEmailSettingsMapper {
	public static mapEmailSettingsData(data: EndProcessEmailSettingsState) {
		if (data) {
			const { mergeTag, ...others } = data;
			return others;
		}
		return null;
	}

	public static mapStaffEmailBody(document, docId, staffId) {
		if (docId === 0 || !docId) {
			return {
				document,
				referenceId: +staffId,
				fileName: DefaultFileNames.EndProcess,
				type: DocumentTypes.KiwiSaverEndProcess,
			};
		}
		return {
			document,
			documentId: +docId,
			type: DocumentTypes.KiwiSaverEndProcess,
		};
	}

	public static mapEmailSettingsToUpsert(data: EndProcessEmailSettingsState): EndProcessEmailSettingsState {
		return {
			settingsId: data?.settingsId,
			referenceId: data.referenceId || 0,
			type: AdviceProcessSectionCodes.KiwiSaverEndProcess,
			isEnableEmailOption: data?.isEnableEmailOption || false,
			carbonCopy: data?.carbonCopy || '',
			blindCarbonCopy: data?.blindCarbonCopy || '',
			subject: data?.subject || '',
			unsubscribeGroupID: data?.unsubscribeGroupID || '',
			templateID: data?.templateID || '',
			body: data?.body || '',
		};
	}
}
