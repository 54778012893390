import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	Renderer2,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import {
	ALLOWED_DOCUMENT_FILE_TYPES,
	UploadModalComponent,
} from '@shared/modal/upload-modal/upload-modal.component';
import { ServicesCodes } from '@shared/models/services/services.model';
import { LinkDocumentComponent } from '@shared/services/link-document/link-document.component';
import { ToPdfService } from '@shared/services/to-pdf/to-pdf.service';
import { convertUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, iif, of } from 'rxjs';
import { concatMap, map, mergeMap, take, tap } from 'rxjs/operators';

@Component({
	selector: 'app-upload-document',
	templateUrl: './upload-document.component.html',
	styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnChanges, OnDestroy {
	@ViewChild('uploadOptionModal') uploadOptionModal: TemplateRef<any>;

	@Output() documentUploaded = new EventEmitter<{
		ReferenceId: number;
		Value: string;
	}>();

	@Output() documentLinked = new EventEmitter<{
		ReferenceId: number;
		Value: string;
	}>();

	@Input() getClientDocumentsFn: () => Observable<any>;

	@Input() saveDocumentFn: (data: { ReferenceId: number, Value: string }) => Observable<any>;

	@Input() tabs: ServicesCodes[];

	@Input() trigger: HTMLElement;

	@Input() initialSelectedTab: ServicesCodes;

	@Input() uploadModalTitle: string;

	@Input() adviceProcessId: number;

	@Input() customerId: number;

	@Input() documentType: ServicesCodes;

	@Input() type: string;

	@Input() chooserTitle = 'Add Policy Document';

	public bsModalRef: BsModalRef;
	public linkModalRef: BsModalRef;
	public optionModalRef: BsModalRef;
	public bsModalRefUpload: BsModalRef;

	private _triggerUnlistener: () => void;

	constructor(
		private modalService: BsModalService,
		private renderer: Renderer2,
		private toPdfService: ToPdfService,
		private crtDocService: CrtDocumentService
	) {}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.trigger?.currentValue) {
			this._triggerUnlistener?.();
			this._triggerUnlistener = null;
			this._triggerUnlistener = this.renderer.listen(
				simpleChanges.trigger?.currentValue,
				'click',
				() => this.showUploadOption()
			);
		}
	}

	ngOnDestroy(): void {
		this._triggerUnlistener?.();
	}

	convertToBase64 = (
		file: File
	): Observable<{ content: string; filename: string }> =>
		new Observable((obs) => {
			const reader = new FileReader();
			reader.onload = () =>
				obs.next({
					content: reader.result as string,
					filename: file.name,
				});
			reader.onloadend = () => obs.complete();
			return reader.readAsDataURL(file);
		});

	uploadNew(): void {
		this.optionModalRef.hide();
		const upload = (req: FileList) => {
			const filename = convertUtil.imageFilenameToPDF(req[0]);
			return new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() => req[0].type.includes('image/'),
						this.toPdfService.fromImage(req[0]),
						this.convertToBase64(x[0])
					)
				),
				map((base64Image) => ({
					ReferenceId: this.adviceProcessId,
					CustomerId: this.customerId,
					Document: `${((base64Image.content as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`,
					FileName: filename,
					Type: this.type,
					DocumentType: this.documentType,
				})),
				concatMap((x) => this.crtDocService.saveDocument(x as any)),
				mergeMap((referenceId: number) => {
					if (this.saveDocumentFn) {
						return this.saveDocumentFn({
							ReferenceId: referenceId,
							Value: filename,
						})
					}
					return of(true);
				})
			);
		};
		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: this.uploadModalTitle,
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	showUploadOption(): void {
		if (this.optionModalRef) {
			return;
		}
		this.optionModalRef = this.modalService.show(this.uploadOptionModal, {
			class: 'modal-dialog-centered',
			initialState: {},
			ignoreBackdropClick: true,
		});

		this.optionModalRef?.onHidden
			.pipe(take(1))
			.subscribe(() => (this.optionModalRef = null));
	}

	linkDocument() {
		this.optionModalRef?.hide();
		this.getClientDocumentsFn()
			.pipe(
				take(1),
				mergeMap((docs) => {
					const initState = {
						selectedDetail: 'Link Document',
						document: docs,
						initialSelectedTab: this.initialSelectedTab.toLowerCase(),
						tabs: this.tabs,
					};
					this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
						class: 'modal-dialog-centered modal-lg',
						initialState: initState,
						ignoreBackdropClick: true,
					});

					return this.linkModalRef.content.getSelectedDocumentValue$.pipe(
						take(1)
					);
				}),
				mergeMap((docs: any) => {
					if (this.saveDocumentFn) {
						return this.saveDocumentFn({
							ReferenceId: docs.id,
							Value: docs.fileName,
						})
					}
					return of(true);
				})
			)
			.subscribe();
	}
}
