import { Injectable } from '@angular/core';
import { ExpenseStore } from './expenses.store';
import { ApiService } from '../../../../../../../core/base/api.service';
import { catchError, tap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { objectUtil } from '../../../../../../../util/util';
import { combineLatest, EMPTY, of } from 'rxjs';

import { omit, keys, complement, either, isNil, isEmpty } from 'ramda';
import { CrtMortgageStore } from '../../../state/crt-mortgage.store';
import {
	ExpenseListObjState,
	MonthlyExpenseDetailsState,
} from '../../../../_shared/models/monthly-expense.model';
import { ExpenseQuery } from './expenses.query';
import { CrtMortgageService } from '../../../state/crt-mortgage.service';
import { DropdownValueQuery } from '../../../../../../../domain/dropdown-value/dropdown-value.query';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import { CustomerService } from '../../../../../../../core/customer/customer.service';
import { BusinessService } from '../../../../../../../core/business/business.service';
import { SidebarStatus } from '../../../../_shared/models/sidebar.model';
import {
	AdviceProcessPageCodes,
	MortgageAdviceProcessPageIds,
} from '../../../../../../../shared/models/advice-process/advice-process.model';

@Injectable()
export class ExpenseService extends CrtMortgageService {
	expenses$ = this.eQuery.expenses$;
	expensesFormValue$ = this.eQuery.formValue$;
	constructor(
		private eStore: ExpenseStore,
		private eQuery: ExpenseQuery,

		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	clearData() {
		applyTransaction(() => {
			this.eStore.reset();
		});
	}

	getMonthlyExpense(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? x.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
					this.eStore.setExpenseState(state);
				})
			),
			catchError(() => of(null))
		);
	}

	updateMonthlyExpenseState(monthlyExpense: MonthlyExpenseDetailsState) {
		this.setHasFormChanges(true);
		this.eStore.setExpenseState(monthlyExpense);
	}

	updateMonthlyExpense(monthlyExpense: MonthlyExpenseDetailsState) {
		const endpoint = `crt/${monthlyExpense.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(monthlyExpense);
		return this.api.put<MonthlyExpenseDetailsState[]>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.eStore.setExpenseState(monthlyExpense);
				})
			),
			catchError(() => EMPTY)
		);
	}

	addMonthlyExpense(
		monthlyExpense: MonthlyExpenseDetailsState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(monthlyExpense);
		return this.api.post3<MonthlyExpenseDetailsState[]>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.eStore.setExpenseState({ ...monthlyExpense, cRTId: +x });
				})
			),
			catchError(() => EMPTY)
		);
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.store.setHasFormChanges(hasFormChanges);
	}

	setTabColor() {
		return combineLatest([
			this.query.adviceProcess$,
			this.expenses$,
			this.query.mortApPageStarted$,
			this.expensesFormValue$,
		]).pipe(
			tap(([ap, expenses, pageStarted, expensesFormvalue]) => {
				let status = SidebarStatus.Unopened;
				const warning = null;

				if (pageStarted?.includes(AdviceProcessPageCodes.Expenses)) {
					status = SidebarStatus.Incomplete;
				}

				const withFrequency = keys(expenses)?.filter((x) =>
					x.toString().toLowerCase().includes('frequency')
				);
				const withNotContinuing = keys(expenses)?.filter((x) =>
					x.toString().toLowerCase().includes('notcontinuing')
				);
				const others = [
					'adviceProcessId',
					'incomeSourceList',
					'totalExpenses',
					'cRTId',
					'sectionCode',
					'status',
					'createDateTime',
					'createdByStaffId',
					'createdByStaffLevel',
					'modifiedDateTime',
					'modifiedByStaffId',
					'expense',
					'expenseList',
					'isInvalid',
					'monthlyIncomeAfterExpenses',
					'unallocatedMonthlyIncome',
					'completed',
				];
				const expensesKey = keys(
					omit([...withFrequency, ...withNotContinuing, ...others], expenses)
				) as string[];
				const checkExpensesIfHasAmount = expensesKey?.filter((x) =>
					complement(either(isNil, isEmpty))(expenses[x])
				);
				const checkExpenseListIfHasAmount = (
					expenses?.expenseList as ExpenseListObjState[]
				)?.filter((x) =>
					complement(either(isNil, isEmpty))(x?.expenseValue)
				);
				if (
					!!expenses &&
					(checkExpensesIfHasAmount?.length > 0 ||
						checkExpenseListIfHasAmount?.length > 0)
				) {
					status = SidebarStatus.Completed;
				}

				this.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.Expenses,
					false,
					status,
					warning
				);
			})
		);
	}
}
