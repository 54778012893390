import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { produce } from 'immer';
import { applyTransaction } from '@datorama/akita';
import { EBLRStore } from './eblr.store';
import { EBLROpportunity } from './eblr.model';

@Injectable()
export class EBLRService {
  private readonly endpoint = 'widgets?type=EBLR';

  constructor(private api: ApiService, private store: EBLRStore) {}

  /**
   * Fetch and store data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetEBLROpportunity(req: {
    advisers: string[],
    dateRangeMin: string,
    dateRangeMax: string
  }): void {
    this.store.reset();
    this.store.setLoading(true);
    this.api.post<EBLROpportunity[]>(this.endpoint, {
      Advisers: req.advisers,
      DateRangeMin: req.dateRangeMin,
      DateRangeMax: req.dateRangeMax
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.EBLROpportunity = res;
      })),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
