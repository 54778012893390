import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo, tap, take } from 'rxjs/operators';
import { ActivityService } from './states/activity.service';
import { DropdownValueService } from '../../../domain/dropdown-value/dropdown-value.service';

const dropdownCodes: string[] = ['AT', 'AM'];

@Injectable()
export class ActivityResolver implements Resolve<boolean> {
	constructor(private dropdownValueService: DropdownValueService, private activityService: ActivityService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);

		return forkJoin([dropdown$]).pipe(
			tap(() => this.activityService.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.activityService.getColumnWidths().pipe(take(1)).subscribe()),
			mapTo(true)
		);
	}
}
