import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { KiwisaverState } from '../../models/services/kiwisaver/kiwisaver.model';
import produce from 'immer';
import { mainGroupSorter } from './kiwisaver-sorter.util';
import { InvestmentState } from '../../models/services/investments/investments.model';
import { ServicesCodes } from '@models/services/services.model'

export const convertFundOwnersIdToName = (fundOwners: ViewDisplayValue[], ks: KiwisaverState | InvestmentState | any) => {
	const parseFundowner = ks?.serviceCode === ServicesCodes.KiwiSaver ? ks.fundOwner : ks?.investor;

	if (parseFundowner) {
    const b = parseFundowner?.map(value =>
      fundOwners?.filter(be => be && +be.value === +value)?.map(x => x.display))?.filter(x => x.length > 0);

    if (b.length === 0) {
      return '';
    }
    return b ? b?.reduce((acc, v) => acc?.concat(v))?.join(', ') : '';
  }

	return '';
};

export const isArrayEqual = (a: string, b: string) => {
  const splitA = a ? a?.split(/[-]/) : [];
  const splitb = b ? b?.split(/[-]/) : [];
  if (splitA.length !== splitb.length) {
    return false;
  } else {
    for (let i = 0; i < splitA.length; i++) {
      return splitb?.includes(splitA[i]);
    }
  }
  return false;
};

export const isEqualFundOwners = (a: string, b: string): boolean => {
  const aParsed = a ? JSON.parse(a) : [];
  const bParsed = b ? JSON.parse(b) : [];
  if (aParsed.length !== bParsed.length) {
    return false;
  } else {
    for (let i = 0; i < aParsed.length; i++) {
      return bParsed?.includes(aParsed[i]);
    }
  }

  return false;
};


export const kiwisaverColorStatus = (status: string) => {
  const green = ['active', 'active-pending', 'existing ks'];
  const yellow = ['lead', 'submitted', 'info required'];
  const red = ['cancelled'];
  const grey = ['ntu'];

  const stat = status ? status?.toLowerCase() : '';

  if (green?.includes(stat)) {
    return 'green';
  }

  if (yellow?.includes(stat)) {
    return 'yellow';
  }

  if (red?.includes(stat)) {
    return 'red';
  }

  if (grey.includes(stat)) {
    return 'grey';
  }

  return 'dark';
};

export const kiwiSaverServiceUtil = (state: KiwisaverState[] | InvestmentState[] | any): KiwisaverState[] | InvestmentState[] | any => {
  return produce(state, draft => {
    draft?.forEach(k => {
      k.color = kiwisaverColorStatus(k.status);
    });

    draft.sort(mainGroupSorter);
  });
};
