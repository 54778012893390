import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { tapCollapseAnimations } from './tap-collapse.animations';

@Component({
	selector: 'app-tap-collapse',
	template: `
		<div class="tap-collapse-container" *ngIf="visible" @tapCollapseAnimations>
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./tap-collapse.component.scss'],
	animations: [tapCollapseAnimations],
	exportAs: 'appTapCollapse',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TapCollapseComponent implements OnInit {
	@Output() visibleChange = new EventEmitter<boolean>();
	@Input() visible = true;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

  private emitValue(): void {
		this.visibleChange.emit(this.visible);
		this.cdr.detectChanges();
  }

	toggle(): void {
    this.visible = !this.visible;
    this.emitValue();
	}

  show(): void {
    this.visible = true;
    this.emitValue();
  }

	open(isOpen: boolean): void {
		this.visible = isOpen;
    this.emitValue();
	}
}
