import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as R from 'ramda';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ClientAlterationRequestRouteName } from '@modules/crm/client-alteration-request/states/client-alteration-request.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { AdviceProcessOnlineRoutes } from '@shared/models/advice-process/advice-process.model';

@Injectable()
export class RouteService {
	RouteChange$: Observable<RouteService>;
	cCode: string;
	adminBusinessList: string;
	adminUsersList: string;
	adminUsersAdd: string;
	adminBusinessReport: string;
	adminCustomerPortalSettings: string;
	adminMOAT: string;
	adminAnnouncements: string;
	adminManageUsers: string;
	adminCustomerAlteration: string;
	adminMobileApp:string;
	/**
	 *
	 */
	constructor(
		private configService: ConfigService,
		private businessConfigQuery: BusinessConfigQuery,
	) {
		this.SetupProps();
		this.SetupSubscription();
	}

	private SetupProps(): void {
		this.cCode = '';
		this.adminBusinessList = '/admin/business';
		this.adminUsersList = '/admin/users';
		this.adminUsersAdd = '/admin/users/add';
		this.adminBusinessReport = '/admin/report';
		this.adminCustomerPortalSettings = '/admin/cp-settings';
		this.adminMOAT = '/admin/moat-settings';
		this.adminAnnouncements = '/admin/announcements';
		this.adminManageUsers = '/admin/users';
		this.adminCustomerAlteration = '/admin/car-settings';
		this.adminMobileApp = '/admin/mobile-app-settings'
		this.RouteChange$ = this.configService.CompanyCode.pipe(
			mergeMap(() => of(this))
		);
	}
	private SetupSubscription(): void {
		this.configService.CompanyCode.subscribe((x) => {
			this.cCode = x;
		});
	}
	adminUsersView(userId: number): string {
		return `${this.adminUsersList}/${userId}`;
	}
	adminUsersEdit(userId: number): string {
		return `${this.adminUsersView(userId)}/edit`;
	}

	get business(): string {
		return `/${this.cCode}/admin/business`;
	}
	get usersList(): string {
		return `/${this.cCode}/admin/users`;
	}
	get usersAdd(): string {
		return `/${this.cCode}/admin/users/add`;
	}

	usersView(userId: number): string {
		return `/${this.cCode}/admin/users/${userId}`;
	}

	get sfSettings(): string {
		return `/${this.cCode}/admin/special-features`;
	}

	get pdGoals(): string {
		return `/${this.cCode}/admin/special-features/pd-goals`;
	}

	get leadIntegration(): string {
		return `/${this.cCode}/admin/special-features/lead-integration`;
	}

	get dropdown(): string {
		return `/${this.cCode}/admin/special-features/dropdown`;
	}
	get widgets(): string {
		return `/${this.cCode}/admin/special-features/widgets`;
	}
	get ReferralOptions(): string {
		return `/${this.cCode}/admin/special-features/referralOptions`;
	}

	get theme(): string {
		return `/${this.cCode}/admin/theme`;
	}
	get logo(): string {
		return `/${this.cCode}/admin/images`;
	}
	get activities(): string {
		return `/${this.cCode}/admin/activities`;
	}
	get primaryCustomer(): string {
		return `/${this.cCode}/admin/primarycustomer`;
	}
	get secondaryCustomer(): string {
		return `/${this.cCode}/admin/secondarycustomer`;
	}
	get services(): string {
		return `/${this.cCode}/admin/services`;
	}
	get dataImport(): string {
		return `/${this.cCode}/admin/dataimport`;
	}
	get emails(): string {
		return `/${this.cCode}/admin/emails`;
	}
	get DownloadDocuments(): string {
		return `/${this.cCode}/admin/downloadDocuments`;
	}
	get AdviceProcess(): string {
		return `/${this.cCode}/admin/adviceProcess`;
	}
	get MortgageSettings(): string {
		return `/${this.cCode}/admin/mortgage-settings`;
	}
	get CARSettings(): string {
		return `/${this.cCode}/admin/car-settings`;
	}

	get dashboard(): string {
		return `/${this.cCode}/dashboard`;
	}
	get mainActivity(): string {
		return `/${this.cCode}/activity`;
	}
	get notFoundPage(): string {
		return `/${this.cCode}/not-found`;
	}

	// Lead routes
	get leadSearch(): string {
		return `/${this.cCode}/lead/search`;
	}
	get leadClientAdd(): string {
		return `/${this.cCode}/lead/client/add`;
	}
	get leadBusinessAdd(): string {
		return `/${this.cCode}/lead/business/add`;
	}

	leadClientView = (customerId: number): CommandRoute => [
		`/${this.cCode}`,
		'lead',
		'client',
		R.toString(customerId),
	];
	leadBusiness = (customerId: number): CommandRoute => [
		`/${this.cCode}`,
		'lead',
		'business',
		R.toString(customerId),
	];

	// client routes
	get customerSearch(): string {
		return `/${this.cCode}/client/search`;
	}
	get customerAdd(): string {
		return `/${this.cCode}/client/add`;
	}
	customerView = (customerId: number): CommandRoute => [
		`/${this.cCode}`,
		`client`,
		R.toString(customerId),
	];
	customerPipeline: (customerId: number, pipeline: string) => CommandRoute = (
		id,
		pipeline
	) => [`/${this.cCode}`, 'client', id?.toString(), { tab: pipeline }];

	customerPipelineServiceView: (
		customerId: number,
		pipeline: string,
		custServiceId: number
	) => CommandRoute = (id, pipeline, custServiceId) => [
		`/${this.cCode}`,
		'client',
		id?.toString(),
		{ tab: pipeline, customerServiceId: custServiceId?.toString() },
	];

	customerActivityNote: (
		customerId: number,
		activityId: number
	) => CommandRoute = (id, activityId) => [
		`/${this.cCode}`,
		`client`,
		id.toString(),
		{ tab: 'AN', activity: activityId?.toString() },
	];

	customerAdviceProcess = (id: number, adviceProcessId) => [
		`/${this.cCode}`,
		`client`,
		id.toString(),
		{ tab: 'ap', adviceProcessId: adviceProcessId?.toString() },
	];;

	customerLrPipeline = (id: number) =>
		this.customerPipeline(id, ServicesCodes.LR?.toLowerCase());
	customerMPipeline = (id: number) =>
		this.customerPipeline(id, ServicesCodes.Mortgage?.toLowerCase());
	customerFgPipeline = (id: number) =>
		this.customerPipeline(id, ServicesCodes.FG?.toLowerCase());
	customerKPipeline = (id: number) =>
		this.customerPipeline(id, ServicesCodes.KiwiSaver?.toLowerCase());
	customerActivity = (id: number, activityId: number) =>
		this.customerActivityNote(id, activityId);
	customerLrCustService = (id: number, custServiceId: number) =>
		this.customerPipelineServiceView(
			id,
			ServicesCodes.LR?.toLowerCase(),
			custServiceId
		);
	customerMCustService = (id: number, custServiceId: number) =>
		this.customerPipelineServiceView(
			id,
			ServicesCodes.Mortgage?.toLowerCase(),
			custServiceId
		);
	customerFgCustService = (id: number, custServiceId: number) =>
		this.customerPipelineServiceView(
			id,
			ServicesCodes.FG?.toLowerCase(),
			custServiceId
		);
	customerKCustService = (id: number, custServiceId: number) =>
		this.customerPipelineServiceView(
			id,
			ServicesCodes.KiwiSaver?.toLowerCase(),
			custServiceId
		);

	businessEdit = (customerId: number): CommandRoute => [
		`/${this.cCode}`,
		`business`,
		customerId?.toString(),
	];

	get businessSearch(): CommandRoute {
		return [`/${this.cCode}`, `business`, `search`];
	}
	get businessAdd(): CommandRoute {
		return [`/${this.cCode}`, `business`, `add`];
	}

	businessView = (customerId: number): CommandRoute => [
		`/${this.cCode}`,
		`business`,
		R.toString(customerId),
	];
	businessPipeline: (customerId: number, pipeline: string) => CommandRoute = (
		id,
		pipeline
	) => [...this.businessEdit(id), { tab: pipeline }];

	businessPipelineServiceView: (
		customerId: number,
		pipeline: string,
		custServiceId: number
	) => CommandRoute = (id, pipeline, custServiceId) => [
		...this.businessEdit(id),
		{ tab: pipeline, customerServiceId: custServiceId?.toString() },
	];

	businessActivityNote: (
		customerId: number,
		activityId: number
	) => CommandRoute = (id, activityId) => [
		...this.businessEdit(id),
		{ tab: 'AN', activity: activityId?.toString() },
	];

	businessAdviceProcess = (id: number, adviceProcessId) => [
		`/${this.cCode}`,
		`business`,
		id?.toString(),
		{ tab: 'ap', adviceProcessId: adviceProcessId?.toString() },
	];

	businessLrPipeline = (id: number): CommandRoute =>
		this.businessPipeline(id, ServicesCodes.LR?.toLowerCase());
	businessMPipeline = (id: number): CommandRoute =>
		this.businessPipeline(id, ServicesCodes.Mortgage?.toLowerCase());
	businessFgPipeline = (id: number): CommandRoute =>
		this.businessPipeline(id, ServicesCodes.FG?.toLowerCase());
	businessKPipeline = (id: number): CommandRoute =>
		this.businessPipeline(id, ServicesCodes.KiwiSaver?.toLowerCase());
	businessActivity = (id: number, activityId: number): CommandRoute =>
		this.businessActivityNote(id, activityId);
	businessLrCustService = (id: number, custServiceId: number) =>
		this.businessPipelineServiceView(
			id,
			ServicesCodes.LR?.toLowerCase(),
			custServiceId
		);
	businessMCustService = (id: number, custServiceId: number) =>
		this.businessPipelineServiceView(
			id,
			ServicesCodes.Mortgage?.toLowerCase(),
			custServiceId
		);
	businessFgCustService = (id: number, custServiceId: number) =>
		this.businessPipelineServiceView(
			id,
			ServicesCodes.FG?.toLowerCase(),
			custServiceId
		);
	businessKCustService = (id: number, custServiceId: number) =>
		this.businessPipelineServiceView(
			id,
			ServicesCodes.KiwiSaver?.toLowerCase(),
			custServiceId
		);

	get fgPipeline(): string {
		return `/${this.cCode}/pipeline/FgInsurance`.toLowerCase();
	}
	get mPipeline(): string {
		return `/${this.cCode}/pipeline/Mortgage`.toLowerCase();
	}
	mPipelineWithStatus: (status: string) => CommandRoute = (status) => [
		`/${this.cCode}`,
		'pipeline',
		'mortgage',
		{ status },
	];

	mPipelineWithParams: (
		qParams: Partial<{
			statuses: string;
			advisers: string;
			fixedPeriodStart: string;
			fixedPeriodEnd: string;
		}>
	) => CommandRoute = ({
		statuses,
		advisers,
		fixedPeriodStart,
		fixedPeriodEnd,
	}) => [
		`/${this.cCode}`,
		'pipeline',
		'mortgage',
		{
			statuses,
			advisers,
			fixedPeriodStart,
			fixedPeriodEnd,
		},
	];

	get kPipeline(): string {
		return `/${this.cCode}/pipeline/Kiwisaver`.toLowerCase();
	}
	get iPipeline(): string {
		return `/${this.cCode}/pipeline/Investment`.toLowerCase();
	}
	get lrPipeline(): string {
		return `/${this.cCode}/pipeline/LrInsurance`.toLowerCase();
	}
	lrPipelineWithParams: (
		qParams: Partial<{ advisers: string; statuses: string }>
	) => CommandRoute = (qParams) => [
		`/${this.cCode}`,
		'pipeline',
		'lrinsurance',
		qParams,
	];
	kiwisaverPipelineWithParams: (
		qParams: Partial<{ advisers: string; statuses: string }>
	) => CommandRoute = (qParams) => [
		`/${this.cCode}`,
		'pipeline',
		'kiwisaver',
		qParams,
	];

	investmentPipelineWithParams: (
		qParams: Partial<{ advisers: string; statuses: string }>
	) => CommandRoute = (qParams) => [
		`/${this.cCode}`,
		'pipeline',
		'investment',
		qParams,
	];

	fgPipelineWithParams: (
		qParams: Partial<{ advisers: string; statuses: string }>
	) => CommandRoute = (qParams) => [
		`/${this.cCode}`,
		'pipeline',
		'fginsurance',
		qParams,
	];

	get apPipeline(): string {
		return `/${this.cCode}/pipeline/adviceProcess`.toLowerCase();
	}

	activity(adviserId: number | string): string {
		return `/${this.cCode}/activity/${adviserId}`;
	}

	activityEdit = (activityId: number): CommandRoute => [
		`/${this.cCode}`,
		`activity`,
		{ activityId: activityId?.toString() },
	];

	activityAddCommand: () => CommandRoute = () => {
		return [`/${this.cCode}`, `activity`, { add: 'true' }];
	};

	get document(): string {
		return `/${this.cCode}/document`;
	}

	get report(): string {
		return `/${this.cCode}/report`;
	}

	splitToRoutePath(route: string): string[] {
		const split = route?.split('/');
		split[0] = `/${split[0]}`;
		return split;
	}

	customBusiness(newCode: string) {
		return `/${newCode}/admin/business`;
	}

	externalRoute(url: string): CommandRoute {
		return [`/externalRedirect`, { externalUrl: url }];
	}

	clientAlterationRequest = (
		customerId: number,
		adviceProcessId: number
	) => [
		this.cCode,
		'car',
		'client',
		customerId.toString(),
		'advice-process',
		adviceProcessId.toString(),
	];

	clientReviewTemplate = (
		isCompany: boolean,
		customerId: number,
		adviceProcessId: number
	): CommandRoute => [
		`/${this.cCode}`,
		`crt`,
		`${isCompany ? 'business' : 'client'}`,
		R.toString(customerId),
		`advice-process`,
		R.toString(adviceProcessId),
	];

	crtPage = (
		isCompany: boolean,
		customerId: number,
		adviceProcessId: number,
		adviceProcess: string
	): CommandRoute => {
		const moatv2Enabled = this.businessConfigQuery.getValue()?.config?.MOAT2Quickwins;
		const adviceProcessPath = adviceProcess === AdviceProcessOnlineRoutes.Mortgage && moatv2Enabled ?
			AdviceProcessOnlineRoutes.MortgageV2 :
			adviceProcess;
		return [
			`/${this.cCode}`,
			`crt-page`,
			adviceProcessPath,
			`${isCompany ? 'business' : 'client'}`,
			R.toString(customerId),
			`advice-process`,
			R.toString(adviceProcessId),
		]
	};

	viewPdf: (
		qParams: Partial<{ documentId: string; name: string }>
	) => CommandRoute = (qParams) => [`/${this.cCode}`, 'view-pdf', qParams];

	viewPdfBlob: (
		qParams: Partial<{ blobUrl: string }>
	) => CommandRoute = (qParams) => [`/${this.cCode}`, 'view-pdf', qParams];
}

export type CommandRoute = (string | { [key: string]: string })[];
