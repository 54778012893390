import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DeclarationState } from './declaration.model';
import { DeclarationStore, MoatDeclarationState } from './declaration.store';

@Injectable()
export class DeclarationQuery extends Query<MoatDeclarationState> {
  declaration$ = this.select((state) => state.declaration);

  declarationSettings$ = this.select((state) => state.settings);

  declarationFormValue$ = this.select((state) => state.formValue);

  declarationEmailSettings$ = this.select((state) => state.declarationEmailSettings);

  clientFactFindTemplateSettings$ = this.select((state) => state.clientFactFindTemplateSettings);

  constructor(protected store: DeclarationStore) {
		super(store);
	}
}
