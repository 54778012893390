import { Injectable } from '@angular/core';
import { HtmlToPdfService } from '../../../modules/crm/crt-page/_shared/service/html-pdf/html-to-pdf.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { convertUtil } from '../../../util/util';

@Injectable({
  providedIn: 'root'
})
export class ToPdfService {

  constructor(private htmlToPdfService: HtmlToPdfService) {}

  fromImage(file: File, options?: any): Observable<{
    filename: string;
    content: string;
  }> {
    return of(file)
    .pipe(
      // convert file to base64
      mergeMap(() => convertUtil.simpleConvertToBase64(file)),
      // create a html img string and add the converted base64 image in src
      map((base64) => `<img src="${base64}" />`),
      // convert html img with base64 src to pdf
      mergeMap((img) => this.htmlToPdfService.generatePDFbase64(img, options) as Observable<string>),
      map((base64) => {
        return {
          filename: convertUtil.imageFilenameToPDF(file),
          content: base64
        }
      })
    );
  }
}
