<form [formGroup]="form" class="form" class="mb-3">
  <div formArrayName="properties">
    <ng-container *ngFor="let property of PropertiesList.controls; let last=last; let i=index;" [formGroupName]="i">
      <div class="row" [ngClass]="last ? 'pb-0' : 'pb-3'">
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center">
            <div class="col-12 col-lg-4 text-left mb-2">
              <label for="" class="position-relative font-weight-bold">
                Property Address
              </label>
            </div>
            <div class="col-12 col-lg-8 mb-2 pr-md-0">
              <app-address-input
                class="crt-form-control-field disabled"
                formControlName="propertyAddress"
                textboxClass="theme-crm-field-disabled"
                tabindex="19"
              >
              </app-address-input>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-row align-items-center">
            <div class="col-4 col-lg-5 text-left mb-2">
              <label for="" class="position-relative font-weight-bold">
                Sales Price
              </label>
            </div>
            <div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
              <div class="dollar-icon">
                <input
                  id="SalesPrice"
                  formControlName="salesPrice"
                  class="form-control crt-form-control"
                  type="text"
                  currencyMask
                  appCurrencyMaskExt
                  [class.disabled]="!property.get('isEdit').value"
                />
                <i>$</i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2">
          <!-- EDIT MODE -->
          <div 
            *ngIf="(!property.get('cRTId').value || property.get('isEdit').value) && !property.get('isLoading').value"
            class="form-row align-items-center"
          >
            <div class="col mt-1">
              <button
                (click)="save(i)"
                type="button"
                [disabled]="property.get('isLoading').value"
                class="icon-btn w-auto h-auto px-1"
                [class.disabled]="property.get('isLoading').value"
              >
                <i class="material-icons md-16 tap-text-primary">save</i>
              </button>
              <button
                (click)="close(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
                [disabled]="property.get('isLoading').value"
                [class.disabled]="property.get('isLoading').value"
              >
                <i class="material-icons md-16">close</i>
              </button>
            </div>
          </div>

          <ng-container *ngIf="property.get('isLoading').value">
            <div class="form-row mt-1">
              <app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
            </div>
          </ng-container>

          <!-- DISPLAY MODE -->
          <div
            *ngIf="!property.get('isEdit').value && property.get('cRTId').value && !property.get('isLoading').value"
            class="form-row align-items-center"
          >
            <div class="col mt-1">
              <button
                (click)="edit(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
              >
                <i class="material-icons md-16">edit</i>
              </button>
              <button
                (click)="confirmDelete(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
              >
                <i class="material-icons md-16">delete</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="addMode">
    <div class="row pt-3 pb-2">
      <div class="col-12 col-lg-6">
        <div class="form-row align-items-center">
          <div class="col-12 col-lg-4 text-left mb-2">
            <label for="" class="position-relative font-weight-bold">
              Property Address
            </label>
          </div>
          <div class="col-12 col-lg-8 mb-2 pr-md-0">
            <app-address-input
              class="crt-form-control-field disabled"
              formControlName="newPropertyAddress"
              textboxClass="theme-crm-field-disabled"
              tabindex="19"
            >
            </app-address-input>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="form-row align-items-center">
          <div class="col-4 col-lg-5 text-left mb-2">
            <label for="" class="position-relative font-weight-bold">
              Sales Price
            </label>
          </div>
          <div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
            <div class="dollar-icon">
              <input
                id="newPropertySalesPrice"
                formControlName="newPropertySalesPrice"
                class="form-control crt-form-control"
                type="text"
                currencyMask
                appCurrencyMaskExt
              />
              <i>$</i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2">
        <ng-container *ngIf="isAddLoading">
          <div class="form-row mt-1">
            <app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
          </div>
        </ng-container>
        <div class="form-row align-items-center" *ngIf="!isAddLoading">
          <div class="col mt-1">
            <button
              (click)="saveNew()"
              type="button"
              [disabled]="isLoading"
              class="icon-btn w-auto h-auto px-1"
              [class.disabled]="isLoading"
            >
              <i class="material-icons md-16 tap-text-primary">save</i>
            </button>
            <button
              (click)="closeNew()"
              type="button"
              class="icon-btn w-auto h-auto px-1"
              [disabled]="isLoading"
              [class.disabled]="isLoading"
            >
              <i class="material-icons md-16">close</i>
            </button>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <div class="row">
    <div class="col-lg-4 ml-2">
      <div class="form-row align-items-center">
        <ng-container *ngIf="isAdding">
          <div class="col pl-0">
            <span class="w-100" [class.crt-custom-dropdown]="form.enabled">
              <select
                formControlName="propertyAddress"
                id="propertyAddressAdd"
                class="form-control theme-crm-field-disabled crt-form-control"
                (change)="onSelectProperty($event.target.value)"
              >
                <option
                  *ngFor="let d of propertyAddresses"
                  [value]="d.customerServiceID"
                >
                  {{ d.propertyAddress }}
                </option>
                <option value="new">+ Add New Property</option>
              </select>
            </span>
          </div>
          <div class="col-auto">
            <button
              (click)="closeNew()"
              type="button"
              class="icon-btn w-auto h-auto px-1"
              [disabled]="isLoading"
              [class.disabled]="isLoading"
            >
              <i class="material-icons md-16">close</i>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  
  <div class="row my-4">
    <div class="col-lg-4">
      <div class="form-row align-items-center">
        <div class="col">
          <button
            (click)="addNewProperty()"
            type="button"
            class="tap-btn tap-btn-outline tap-btn--shadow px-4 ml-1"
            [disabled]="isAdding || addMode"
          >
            Add New Property +
          </button>
        </div>
      </div>
		</div>
  </div>
</form>
