import { TlMoatSettingsTypes } from '../../state/tl-moat-settings.model';
import { ServicingCalculatorState } from '../state/servicing-calculators-settings.model';

export class ServicingCalculatorSettingsMapper {
	public static mapToUpsert(data: ServicingCalculatorState) {
		return {
			referenceId: 0,
			settingsId: data?.settingsId || null,
			documentId: data?.documentId || 0,
			templateType: data?.templateType,
			documentName: data?.documentName || '',
			fileOutputFormat: data?.fileOutputFormat || '',
			isActive: data?.isActive,
			status: +data?.status,
			sortNo: data?.sortNo || null,
			type: TlMoatSettingsTypes.ServicingCalc,
		};
	}

	public static mapDocumentUpload(document, base64) {
		return {
			type: TlMoatSettingsTypes.ServicingCalc,
			referenceId: 0,
			document: base64,
			fileName: document?.name,
		};
	}
}
