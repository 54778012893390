import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { FinalStructureSettingsService } from './state/final-structure-settings.service';
@Component({
  selector: 'app-final-structure-settings',
  templateUrl: './final-structure-settings.component.html'
})
export class FinalStructureSettingsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
  emailSettings$ = this.fsService.finalStructure$;
  constructor(private fsService: FinalStructureSettingsService) { }

  ngOnInit(): void {
    this.prepData();
  }

  prepData() {
    combineLatest([
      this.emailSettings$
    ]).pipe(
      map(([fs]) => MergeTagsMapper.mapMergeTags(fs?.mergeTag || [])),
      tap((mt) => this.mergeTags$.next(mt)),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
