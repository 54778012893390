import { FieldMetadata } from 'src/app/shared/dynamic-field/field-metadata.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import * as R from 'ramda';
export interface Row {
	AdviceProcessID: number;
	CustomerID: number;
	AdviceProcess: FieldMetadata<string>;
	ClientsInvolved: FieldMetadata<string>;
	AdviceStatus: FieldMetadata<string>;
	Adviser: FieldMetadata<number>;
  AdviceOnlineOffline: FieldMetadata<string>;
	AdviserName: string;
	ReviewedBy: FieldMetadata<number>;
	ReviewedByName: string;
	RequiredDocuments: FieldMetadata<string>;
	StatusClock: FieldMetadata<string>;
	StartDate: FieldMetadata<string>;
	EndDate: FieldMetadata<string>;
	LastNote: FieldMetadata<string>;
	NextActivity: FieldMetadata<string>;
	ClientNextActivityId: number;
	ClientType: FieldMetadata<string>;
	AdviceStage: FieldMetadata<string>;
	AdviceService: FieldMetadata<string>;
	ComplaintOutcome: FieldMetadata<string>;
	ComplaintType: FieldMetadata<string>;
	LeadStatus: FieldMetadata<string>;
	link?: {
		AdviceProcessID: number;
		CustomerID: number;
		IsCompany: boolean;
	};
	//Claims
	ReferenceNumber?: FieldMetadata<string>;
	ClaimType?: FieldMetadata<string>;
	ClaimOutcome?: FieldMetadata<string>;
	ClaimTotalPaid?: FieldMetadata<string>;
}

export interface AdviceProcess {
	AdviceProcessID: number;
	CustomerID: number;
	AdviceProcess: string;
	ClientsInvolved: string;
	AdviceStatus: string;
	Adviser: number;
	AdviserName: string;
	ReviewedBy: number;
	ReviewedByName: string;
	RequiredDocuments: string;
	StatusClock: number;
	StartDate: string;
	EndDate: string;
	LastNote: string;
	NextActivity: string;
	ClientNextActivityId: number;
	ClientType: string;
	AdviceStage: string;
	AdviceService: string;
	ComplaintOutcome: string;
	ComplaintType: string;
	IsCompany?: boolean;
	LeadStatus?: string;
  AdviceOnlineOffline?: string;
	//Claims
	ReferenceNumber?: string;
	ClaimType?: string;
	ClaimOutcome?: string;
	ClaimTotalPaid?: number;
	ClaimManagerID?:number;
}

export interface AdviceProcessResponse {
	SearchResults: AdviceProcess[];
	TotalCount: number;
	TotalAPI: number;
	IsComplete: boolean;
}

export interface AdviceProcessExportResponse {
	TotalCount: number;
	IsCompleted: boolean;
	Blob: Blob;
	Index: Blob;
}

export enum AdviceStatuses {
	InProgress = 'In progress',
	EndedCompleted = 'Ended - Completed',
	EndedIncomplete = 'Ended - Incomplete',
	Cancelled = 'Cancelled'
}

export enum AdviceDocumentStatuses {
	Complete = 'C',
	Incomplete = 'I'
}

export const GetAdviceDocumentStatusViewDisplay = () => {
	const keys: string[] = Object.keys(AdviceDocumentStatuses);
	const docStatuses: ViewDisplayValue[] = [];
	const makeViewDisplay = (key: string) => {
		docStatuses.push({
			display: key,
			value: AdviceDocumentStatuses[key as keyof typeof AdviceDocumentStatuses]
		})
	}
	R.forEach(makeViewDisplay, keys);
	return docStatuses;
}

export interface AdviceStagesDropdownState {
	processCode: string;
	stages: AdviceStageState[];
}

export interface AdviceStageState {
	stage: string;
	isOnline: boolean;
	status: number; // 1 is ticked/ON; 2 is unticked/OFF
}

export const NotStartedStage = {
	stage: 'Not Started',
	isOnline: false, // This is a default; can also be true
	status: 1
}