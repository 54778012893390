import { MergeTagTypeCode } from '../../../merge-tags.model';

export const appCashDepositMetaKey = {
	// Totals
	totalContribution: 'APPLICATION_TOTAL_CONTRIBUTION',
	totalFundingRequired: 'APPLICATION_TOTAL_FUNDING_REQUIRED',
	totalNetLoanProceeds: 'APPLICATION_TOTAL_NET_LOAN_PROCEEDS',

	// Cash/Deposit
	depositCash: 'APPLICATION_DEPOSIT_CASH',
	depositGift: 'APPLICATION_DEPOSIT_GIFT',
	depositKiwiSaver: 'APPLICATION_DEPOSIT_KIWISAVER',
	depositExistingLoan: 'APPLICATION_DEPOSIT_EXISTING_LOAN',
	depositOtherAmount: 'APPLICATION_DEPOSIT_OTHER_AMOUNT',
	depositOtherType: 'APPLICATION_DEPOSIT_OTHER_TYPE',
};

export const appCashDepositMergeTag = [
	{
		metaKey: appCashDepositMetaKey.totalContribution,
		description:
			'Application > Funding Required > Cash/Deposit > Total Contribution',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.totalFundingRequired,
		description:
			'Application > Funding Required > Cash/Deposit > Total Funding Required',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.totalNetLoanProceeds,
		description:
			'Application > Funding Required > Cash/Deposit > Total Net Loan Proceeds',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.depositCash,
		description: 'Application > Funding Required > Cash/Deposit > Cash',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.depositGift,
		description: 'Application > Funding Required > Cash/Deposit > Gift',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.depositKiwiSaver,
		description: 'Application > Funding Required > Cash/Deposit > KiwiSaver',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.depositExistingLoan,
		description:
			'Application > Funding Required > Cash/Deposit > Cash from Existing Loan',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.depositOtherAmount,
		description:
			'Application > Funding Required > Cash/Deposit > Other (Amount)',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '123',
	},
	{
		metaKey: appCashDepositMetaKey.depositOtherType,
		description: 'Application > Funding Required > Cash/Deposit > Other (Type)',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: 'Others',
	},
];
