import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, takeUntil, finalize, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { Fields, getInvalidWarning, getRequiredWarning } from 'src/app/shared/error-message/error-message';
import { RouteService } from '../../../../core/config/route.service';
import { AddPhotoRequest } from '../../../../core/customer/customer.service';
import { ClientProfilePrimaryMapper } from '../../../../shared/models/client-profile/primary-client/primary-client.mapper';
import { ClientProfileGroupComponent } from '../client-profile-group/client-profile-group.component';
import { FormPersonComponent } from '../forms/form-person/form-person.component';
import { ClientProfileService } from '../states/client-profile.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { EmailDuplicateService } from '@core/services/email-duplicate.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-client-profile-add',
	templateUrl: './client-profile-add.component.html',
	styleUrls: ['./client-profile-add.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProfileAddComponent implements OnInit, OnDestroy {

	private onDestroy$ = new Subject<void>();
	isSaving = false;
	public advisers$ = this.service.adviserChoices$;
	public allActiveStaffs$ = this.service.allActiveStaffs$;
	public altAdviserChoices$ = this.service.altAdviserChoices$;
	public leadGens$ = this.service.leadGenChoices$;

	PCT$ = this.service.PCT$;
	PCE$ = this.service.PCE$;
	PCPC$ = this.service.PCPC$;
	PCLE$ = this.service.PCLE$;
	PCLT$ = this.service.PCLT$;
	PCR$ = this.service.PCR$;
	LS$ = this.service.LS$;
	LT$ = this.service.LT$;
	SAS$ = this.service.SAS$;
	Industry$ = this.service.CI$;

	// Primary
	title$ = this.service.PCT$;
	employment$ = this.service.PCE$;
	relationship$ = this.service.SCR$;
	preferredContact$ = this.service.PCPC$;
	leadTypeChoices$ = this.service.PCLT$;
	leadTypeChoices2$ = this.service.PCLT$;
	leadOriginChoices$ = this.service.PCLE$;
	leadGenChoices$ = this.service.leadGenChoices$;
	adviserGenChoices$ = this.service.adviserChoices$;
	allStaffChoices$ = this.service.allStaffChoices$;
	allStaff$ = this.blStaffQuery.activeStaffs$;
	businessConfig$ = this.businessConfigQuery.businessConfig$;

	isLead$ = this.route.data.pipe(map(x => x.isLead));

	primaryClientId: number;
	addPhotoRequest: AddPhotoRequest;

	@ViewChild(FormPersonComponent) formPerson: FormPersonComponent;
	@ViewChild(ClientProfileGroupComponent) formGroup: ClientProfileGroupComponent;

	constructor(
		private service: ClientProfileService,
		private route: ActivatedRoute,
		private router: Router,
		private routeService: RouteService,
		private loggerService: LoggerService,
		private businessConfigQuery: BusinessConfigQuery,
		private blStaffQuery: BLStaffsQuery,
		private emailDupService: EmailDuplicateService,
		private location:Location,
	) { }

	ngOnInit() { }

	uploadPhoto$ = (req: AddPhotoRequest) =>
	new Observable(obs => {
		this.addPhotoRequest = req;
		obs.next();
		obs.complete();
	})

	addNewPrimaryClient(formGroup) {
		this.isSaving = false;
		if (
			!this.formPerson.form.valid ||
			this.formPerson.form.value.firstName?.trim() === '' ||
			this.formPerson.form.value.lastName?.trim() === '' ||
			this.formPerson.dateOfBirthDateInput?.isInvalid() ||
			isNaN(this.formPerson.form.value.income)
		) {
			if (!this.formPerson.form.value.firstName || this.formPerson.form.value.firstName?.trim() === '') {
				this.loggerService.Warning({}, getRequiredWarning(Fields.FirstName));
				return;
			}
			if (!this.formPerson.form.value.lastName || this.formPerson.form.value.lastName?.trim() === '') {
				this.loggerService.Warning({}, getRequiredWarning(Fields.LastName));
				return;
			}
			if (this.formPerson.dateOfBirthDateInput?.isInvalid()) {
				this.loggerService.Log({}, getInvalidWarning(Fields.DateOfBirth));
				return;
			}
			if (isNaN(this.formPerson.form.value.income)) {
				this.loggerService.Log({}, getInvalidWarning(Fields.Income));
				return;
			}
		} else {
			this.isSaving = true;
			this.formPerson.isEdit$.next(false);
			this.formGroup.isEdit$.next(false);
			const primaryClient = {
				...formGroup,
				...this.formPerson.prepareFormValue(), // Form Person
			};
			const declineCb = () =>{
				this.location.back();
			}
			const d =  ClientProfilePrimaryMapper.mapToUpsert(primaryClient);
			const addPrimary$ =  this.service.addPrimaryClient(d).pipe(
				catchError((err)=>of(err)),
				switchMap((res)=>{
					if(res.hasOwnProperty('DuplicateEmail')){
						const ulMessage = res['DuplicateEmail'];
						const confirmCb = () => {
							//Add creation service call here to proceed need customer ID
							this.isSaving = true;
							d['saveAnyway'] = true;
							return addPrimary$
						};
					this.emailDupService.displayDuplicateDialog(ulMessage, confirmCb, declineCb);
					this.formPerson.isEdit$.next(true);
					this.formGroup.isEdit$.next(true);
					this.isSaving = false;
					}
					return of(res);
				}),
				mergeMap(id =>
					!!this.addPhotoRequest && !!this.addPhotoRequest.Photo &&!id.hasOwnProperty('DuplicateEmail')
						? this.service.addPhoto$({
							...this.addPhotoRequest,
							CustomerID: +id,
						})
						: of(id)
				),
				tap((x)=>{if(!x.hasOwnProperty('DuplicateEmail')){
					this.addingSuccess(x)
				}})
			);
			addPrimary$.pipe(takeUntil(this.onDestroy$)).subscribe();
		}
	}

	addingSuccess(res){
		const route = this.routeService.customerView(
			!!this.addPhotoRequest && !!this.addPhotoRequest.Photo ? res.CustomerID : +res
		);
		this.router.navigate(route);
		this.formPerson.isEdit$.next(true);
		this.formGroup.isEdit$.next(true);
		this.isSaving = false;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
