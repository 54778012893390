<div class="crt-intro">
	<ng-template #introUD>
		<div *ngIf="!isImg" class="intro__content__wrap-embed">
			<ng-container *ngIf="!isPdfLoaded">
				<app-manual-loader-mini
					[isLoading]="!isPdfLoaded"
				></app-manual-loader-mini>
			</ng-container>
			<ng-container *ngIf="!!fileUrl">
				<app-view-pdf
					[fileSource]="fileUrl"
					(onPageRendered)="onPageRendered($event)"
				></app-view-pdf>
			</ng-container>
		</div>
		<div *ngIf="isImg">
			<ng-container *ngIf="!!fileUrl">
				<img [src]="fileUrl" class="intro__content__img img-fluid w-100" />
			</ng-container>
		</div>
	</ng-template>
	<ng-container
		*ngIf="!!introduction && !!introduction?.introType; else emptyIntro"
	>
		<div
			class="intro-desc"
			*ngIf="introduction?.introType === 'BD'; else introUD"
		>
			<p class="mb-0">{{ introduction?.intro }}</p>
		</div>
	</ng-container>

	<ng-template #emptyIntro>
		<label>Contact your business owner to add an introduction template</label>
	</ng-template>
</div>
