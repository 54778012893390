import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, of, iif } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { viewSecGroup } from '../../../viewmodels/viewSecGroup';
import { BlStaffViewmodel } from '../../../viewmodels/bl-staff.viewmodel';
import { RouteService } from '../../../../../core/config/route.service';
import { BLStaffSecGroupsQuery } from '../../../../../domain/bl-staff-security-group/bl-staff-security-groups.query';
import {
	AddUserPhotoRequest,
	BlStaffSettingsModel,
	BlStaffModel,
	BlStaffModelEdit,
} from '../../../../../shared/models/_general/bl-staff.model';
import { mergeMap, map, take, tap } from 'rxjs/operators';
import { BLStaffsService } from 'src/app/domain/bl-staff/bl-staffs.service';
import * as R from 'ramda';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { BusinessConfigService } from 'src/app/domain/business-config/business-config.service';
import { UserQuery } from '@domain/user/user.query';
import { StaffSettingTypes } from '@domain/bl-staff/bl-staff.model';
import { util } from '@core/util/util.service';
import { StaffSettingsService } from '@modules/user/service/staff-settings.service';

@Component({
	selector: 'app-bl-view',
	templateUrl: './bl-view.component.html',
})
export class BlViewComponent implements OnInit, OnDestroy {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private routeService: RouteService,
		private blStaffSecGroupQuery: BLStaffSecGroupsQuery,
		private blsStaffService: BLStaffsService,
		protected dropdownValueQuery: DropdownValueQuery,
		private businessConfigService: BusinessConfigService,
		private userQuery: UserQuery,
		private ssService: StaffSettingsService
	) {}
	subs: Subscription[] = [];
	staff: BlStaffViewmodel;
	staffSettings: BlStaffSettingsModel[] = [];
	secGroups: viewSecGroup[] = [];
	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');
	SCS$ = this.dropdownValueQuery.orderedChoices$('SCS');
	SCT$ = this.dropdownValueQuery.orderedChoices$('SCT');
	SAS$ = this.dropdownValueQuery.orderedChoices$('SAS');
	SQCKI$ = this.dropdownValueQuery.orderedChoices$('SQCKI');
	UPDTP$ = this.dropdownValueQuery.orderedChoices$('UPDTP');
	UPDTC$ = this.dropdownValueQuery.orderedChoices$('UPDTC');
	UPDTS$ = this.dropdownValueQuery.orderedChoices$('UPDTS');
	companyCode: string = this.businessConfigService.companyCode();
	isTapLevel = this.userQuery.isTapLevel();
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;

	ngOnInit() {
		const sg = this.blStaffSecGroupQuery.getAll();
		this.secGroups = viewSecGroup.mapSecGroups(sg);

		const sub = this.route.data.subscribe((data) => {
			this.staff = BlStaffViewmodel.mapToViewmodel(data.staff as BlStaffModel);
			this.setStaffSettings();
		});
		this.subs.push(sub);
	}

	ngOnDestroy(): void {
		this.subs?.forEach((s) => s.unsubscribe());
		this.subs = [];
	}

	setStaffSettings() {
		this.ssService.staffSettings$.pipe(take(1)).subscribe((data) => {
			this.staffSettings = data || [];
		});
	}

	provideAccess = (data) =>
		of(data).pipe(
			map((x) => ({
				...BlStaffViewmodel.mapToEditModel(x),
				BLSecurityGroups: data.BLSecurityGroups,
			})),
			map((x) =>
				R.omit(['AdviserIdsAssigned', 'WidgetOrder', 'CalendarUrl'], x)
			),
			mergeMap((x) => this.blsStaffService.updateProvideAccess(x))
		);

	updateStaffFn$ = (v: BlStaffViewmodel) =>
		new Observable<BlStaffModelEdit>((obs) => {
			const staff = BlStaffViewmodel.mapToEditModel(v);
			obs.next(staff);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.blsStaffService.update(x)),
			mergeMap((x) => this.blsStaffService.getBlStaffById(+x?.StaffID)),
			tap(
				(x) => (this.staff = BlStaffViewmodel.mapToViewmodel(x as BlStaffModel))
			)
		);

	addPhoto$ = (req: AddUserPhotoRequest) =>
		this.blsStaffService
			.addPhoto(req)
			.pipe(
				tap(
					(x) =>
						(this.staff = BlStaffViewmodel.mapToViewmodel(x as BlStaffModel))
				)
			);

	upsertStaffQualificationFn$ = (data: BlStaffSettingsModel) =>
		of(data).pipe(
			mergeMap((x) => {
				const payload = {
					QualificationName: x?.QualificationName,
					Institute: x?.Institute,
					CompletedDate: util.MomentToDateString(x?.CompletedDate),
					PolytechCompletedDate: util.MomentToDateString(
						x?.PolytechCompletedDate
					),
					PolytechDocuments: x?.PolytechDocuments,
					Documents: x?.Documents.map((i) => ({
						ReferenceId: i?.ReferenceId,
						Value: i?.Value,
					})),
					SettingsCode: x?.SettingsCode,
				};
				return iif(
					() => !!x?.StaffSettingsId,
					this.ssService.updateBlStaffSettings(
						payload,
						x?.StaffId,
						x?.StaffSettingsId,
						x?.Type
					),
					this.ssService.addBlStaffSettings(payload, x?.StaffId, x?.Type)
				);
			}),
			mergeMap(() => this.refetchStaffSettings(data?.StaffId)),
			take(1)
		);

	updateStaffGoalsFn$ = (data: any) =>
		of(data).pipe(
			mergeMap(() =>
				this.blsStaffService.updateStaffSettings(
					data.StaffID,
					{ Stats: JSON.stringify(data?.Stats) },
					StaffSettingTypes.UserTrackerGoals
				)
			),
			mergeMap(() => this.blsStaffService.getBlStaffById(+data.StaffID)),
			tap(
				(x) => (this.staff = BlStaffViewmodel.mapToViewmodel(x as BlStaffModel))
			)
		);

	upsertStaffDocumentFn$ = (data: BlStaffSettingsModel) =>
		of(data).pipe(
			mergeMap((x) => {
				const payload = {
					DocumentName: x?.DocumentName || '',
					Documents: x?.Documents || [],
				} as BlStaffSettingsModel;
				return iif(
					() => !!x?.StaffSettingsId,
					this.ssService.updateBlStaffSettings(
						payload,
						x?.StaffId,
						x?.StaffSettingsId,
						x?.Type
					),
					this.ssService.addBlStaffSettings(payload, x?.StaffId, x?.Type)
				);
			}),
			mergeMap(() => this.refetchStaffSettings(data?.StaffId)),
			take(1)
		);

	upsertPdTrackingFn$ = (data: BlStaffSettingsModel) => {
		return of(data).pipe(
			mergeMap((x) => {
				const payload = {
					RequirementName: x?.RequirementName,
					DueDate: util.MomentToDateString(x?.DueDate),
					Provider: x?.Provider,
					OtherProvider: x?.OtherProvider,
					Category: x?.Category,
					Status: x?.Status,
					StructuredHours: x?.StructuredHours,
					Details: x?.Details,
					CompletedDate: util.MomentToDateString(x?.CompletedDate),
					Documents: x?.Documents.map((i) => ({
						ReferenceId: i?.ReferenceId,
						Value: i?.Value,
					})),
					SettingsCode: x?.SettingsCode,
					PDMId: x?.PDMId,
				};
				return iif(
					() => !!x?.StaffSettingsId,
					this.ssService.updateBlStaffSettings(
						payload,
						x?.StaffId,
						x?.StaffSettingsId,
						x?.Type
					),
					this.ssService.addBlStaffSettings(payload, x?.StaffId, x?.Type)
				);
			}),
			mergeMap(() => this.refetchStaffSettings(data?.StaffId)),
			mergeMap(() => this.ssService.getPDTrackingWidgets(data?.StaffId)),
			take(1)
		);
	}

	removeStaffDocumentFn$ = (staffId: number, settingsId: number) => {
		return this.ssService.removeBlStaffSettings(settingsId).pipe(
			mergeMap(() => this.refetchStaffSettings(staffId)),
			take(1)
		);
	};

	getUserDocumentsFn$ = (staffId: number) => {
		return this.blsStaffService.getStaffDocuments(staffId).pipe(take(1));
	};

	deleteUserDocumentsFn$ = (documentId: number) => {
		const staffId = +this.staff?.StaffID;
		return of(documentId).pipe(
			mergeMap((id) => this.blsStaffService.deleteStaffDocuments(id, staffId)),
			mergeMap(() => this.refetchStaffSettings(staffId)),
			take(1)
		);
	};

	refetchStaffSettings(staffId: number) {
		return this.ssService.getBlStaffSettings(staffId).pipe(
			tap((x) => (this.staffSettings = x)),
			take(1)
		);
	}

	cancel() {
		this.router.navigate(
			this.routeService.splitToRoutePath(this.routeService.usersList)
		);
	}
}
