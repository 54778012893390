import { Component, OnInit, OnDestroy } from '@angular/core';
import { uniq, complement, either, isNil, isEmpty } from 'ramda';
import { iif, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	take,
	tap,
	takeUntil,
} from 'rxjs/operators';
import {
	DocumentUploadState,
	IntroductionState,
} from '../../../../../shared/models/client-review-template/intro/intro.model';

import {
	KiwiSaverAdviceProcessPageIds,
	KiwiSaverAdviceProcessPageNames,
} from '@shared/models/advice-process/advice-process.model';

import { CrtKiwiSaverQuery } from '../state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../state/crt-kiwisaver.service';
import { SidebarStatus } from '../../_shared/models/sidebar.model';
import { AdviceProcessPageCodes } from '../../../../../shared/models/advice-process/advice-process.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-intro',
	templateUrl: './introduction.component.html',
	styleUrls: ['./introduction.component.scss'],
})
export class IntroComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	intro$ = this.query.intro$;
	introduction: IntroductionState;
	document: DocumentUploadState;
	isLoading = false;
	pageHeaders = KiwiSaverAdviceProcessPageNames;
	pageIds = KiwiSaverAdviceProcessPageIds;
	sidebar = this.query.getValue().sidebars;
	businessFapName = this.businessConfigQuery.getValue().config.FAP;

	constructor(
		private query: CrtKiwiSaverQuery,
		// private mApService: MortgageAdviceProcessService,
		private service: CrtKiwiSaverService,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit(): void {
		this.service
			.updatePageStarted(AdviceProcessPageCodes.Introduction)
			.pipe(take(1))
			.subscribe();

		this.intro$
			.pipe(
				tap(() => (this.isLoading = true)),
				filter((data) => !!data),
				tap((data) => {
					this.introduction = data;
				}),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();

		this.query.adviceProcess$
			.pipe(
				tap((ap) => {
					if (!ap?.pageStarted?.includes(AdviceProcessPageCodes.Introduction)) {
						this.service.setSideSidebarStatus(
							KiwiSaverAdviceProcessPageIds.Introduction,
							false,
							SidebarStatus.Completed
						);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.query.pageCompleted$
			.pipe(
				concatMap((data) => {
					const code = AdviceProcessPageCodes.Introduction;
					const list = data ? uniq([...data, code]) : [];
					return of(list).pipe(
						tap((x) => this.service.setPageCompleted(x)),
						concatMap(() =>
							iif(
								() =>
									!complement(either(isNil, isEmpty))(data) ||
									!data?.some((x) => x === code),
								this.service.updatePageCompleted(list),
								of(null)
							)
						)
					);
				}),
				take(1)
			)
			.subscribe();
	}

	next() {}

	pauseExit() {}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
