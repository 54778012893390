<tabset
	#subpageTab
	class="
		tap-tabset tap-tabset--border-radius-left-right tap-tabset--right-nav
		tap-soa__tabset
		flex-fill
	"
>
	<!-- <tab
		heading="Scope of Service"
		[id]="soaSubSectionCodes.SOS"
		[active]="(active$ | async)?.currentPage === soaSubSectionCodes.SOS"
		[customClass]="'m-0'"
		(selectTab)="step = 0"
	>
		<ng-container *ngIf="isLoadingSOS$ | async as isLoadingSOS">
			<app-manual-loader [isLoading]="isLoadingSOS"></app-manual-loader>
		</ng-container>
		<div class="row clearfix mx-0">
			<div class="col">
				<app-soa-scope-of-service
					[parentCRTId]="parentCRTId"
					[viewMode]="viewMode"
				>
				</app-soa-scope-of-service>
			</div>
		</div>
	</tab> -->

	<!-- <tab
    heading="Current Insurance"
    [id]="soaSubSectionCodes.CurrentInsurance"
    [active]="(active$ | async)?.currentPage === soaSubSectionCodes.CurrentInsurance"
    [customClass]="'m-0'"
    (selectTab)="step = 1"
  >
    <ng-container *ngIf="isLoadingCI$ | async as isLoadingCI">
      <app-manual-loader [isLoading]="isLoadingCI"></app-manual-loader>
    </ng-container>
    <div class="row clearfix mx-0">
      <div class="col">
        <app-soa-current-insurance
          [parentCRTId]="parentCRTId"
          [viewMode]="viewMode"
        >
        </app-soa-current-insurance>
      </div>
    </div>
  </tab> -->

	<tab
		heading="Proposed Insurance"
		[id]="soaSubSectionCodes.ProposedInsurance"
		[active]="(active$ | async)?.currentPage === soaSubSectionCodes.ProposedInsurance"
		[customClass]="'m-0'"
		(selectTab)="step = 0"
	>
		<ng-container *ngIf="isLoadingPI$ | async as isLoadingPI">
			<app-manual-loader [isLoading]="isLoadingPI"></app-manual-loader>
		</ng-container>
		<div class="row clearfix mx-0">
			<div class="col">
				<app-soa-proposed-insurance
					[parentCRTId]="parentCRTId"
					[parent]="this"
					[viewMode]="viewMode"
				>
				</app-soa-proposed-insurance>
			</div>
		</div>
	</tab>

	<tab
		heading="Documents"
		[id]="soaSubSectionCodes.Documents"
		[active]="(active$ | async)?.currentPage === soaSubSectionCodes.Documents"
		[customClass]="'m-0'"
		(selectTab)="step = 1"
	>
		<app-soa-documents [parentCRTId]="parentCRTId" [soaDocuments]="soaDocuments$ | async"></app-soa-documents>
	</tab>

	<tab
		heading="SOA Review"
		[id]="soaSubSectionCodes.Review"
		[active]="
			(active$ | async)?.currentPage === soaSubSectionCodes.Review ||
			(active$ | async)?.currentPage === soaSubSectionCodes.Completed
		"
		[customClass]="'m-0'"
		(selectTab)="step = 2"
	>
		<div class="row clearfix mx-0">
			<div class="col">
				<app-soa-review
					[parentCRTId]="parentCRTId"
					[otherMergeTags$]="otherMergeTags$"
					[lrProviders$]="lrProviders$"
					[parent]="this"
					(moveToIndex)="goToIndex($event)"
				>
				</app-soa-review>
			</div>
		</div>
	</tab>
</tabset>

<div class="d-flex justify-content-end navigation-buttons">
	<button
		id="backToSoaBtn"
		class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-auto"
		(click)="backToSOA($event)"
		[disabled]="
			(isSavingPI$ | async) ||
			(isSavingSOAReview$ | async) ||
			(isSavingSOA$ | async) ||
			(isLoadingSOAR$ | async)
		"
	>
		Back to SOA
	</button>
	<ng-container
		*ngIf="
			(isSavingPI$ | async) ||
			(isSavingSOAReview$ | async) ||
			(isSavingSOA$ | async)
		"
	>
		<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
	</ng-container>
	<ng-container
		*ngIf="
			!(isSavingPI$ | async) &&
			!(isSavingSOAReview$ | async) &&
			!(isSavingSOA$ | async)
		"
	>
		<button
			id="previousSoaSubpageBtn"
			*ngIf="step > 0"
			class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-2"
			(click)="prev()"
			[disabled]="
				(isSavingPI$ | async) ||
				(isSavingSOAReview$ | async) ||
				(isSavingSOA$ | async) ||
				(isLoadingSOAR$ | async)
			"
		>
			Previous
		</button>
		<button
			id="nextSoaSubpageBtn"
			*ngIf="step < 2"
			class="tap-btn tap-btn-outline tap-btn--shadow px-4"
			(click)="next()"
			[disabled]="
				(isSavingPI$ | async) ||
				(isSavingSOAReview$ | async) ||
				(isSavingSOA$ | async)
			"
		>
			Next
		</button>
		<button
			id="saveSoaBtn"
			class="tap-btn tap-btn-outline tap-btn--shadow px-4"
			*ngIf="step === 2"
			(click)="next()"
			[disabled]="
				(isSavingPI$ | async) ||
				(isSavingSOAReview$ | async) ||
				(isSavingSOA$ | async) ||
				(isLoadingSOAR$ | async)
			"
		>
			Save
		</button>
	</ng-container>
</div>
