import { Component } from '@angular/core';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import {
	BehaviorSubject,
	Subject,
	combineLatest,
	map,
	takeUntil,
	tap,
} from 'rxjs';
import { MortgageEndProcessSettingsService } from './state/mortgage-end-process.service';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
@Component({
	selector: 'app-mortgage-end-process',
	templateUrl: './mortgage-end-process.component.html'
})
export class MortgageEndProcessComponent {
	private onDestroy$ = new Subject<void>();
	emailSettings$ = this.endProcessService.endProcessEmailSettings$;
	mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);

	constructor(private endProcessService: MortgageEndProcessSettingsService) {}

	ngOnInit(): void {
		combineLatest([this.emailSettings$])
			.pipe(
				map(([dec]) => MergeTagsMapper.mapMergeTags(dec?.mergeTag || [])),
				tap((mt) => this.mergeTags$.next(mt)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
