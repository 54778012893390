import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RiskProfileAnswersState } from '../state/fact-find-risk-profile-settings.model';
import { LoggerService } from '@core/logger/logger.service';

@Component({
	selector: 'app-question-form',
	templateUrl: './question-form.component.html',
	styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent implements OnInit {
	@Input() question: string;
	@Input() answers: RiskProfileAnswersState[];
	@Output() saveEvent = new EventEmitter<any>();
	@Input() loading: boolean;
	@Output() disableEvent = new EventEmitter<any>();
	@Input() disabled: boolean;
	@Input() letter: string;

	currentValue: any;

	form: UntypedFormGroup;

	isAdding: boolean;
	isEditing: boolean;

	get AnswersList() {
		return this.form.get('answers') as UntypedFormArray;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService,
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.buildForm();
		this.prepDocumentList();
	}

	buildForm() {
		this.form = this.fb.group({
			answers: this.fb.array([]),
		});
	}

	prepDocumentList() {
		while (this.AnswersList?.length > 0) {
			this.AnswersList?.removeAt(0);
		}
		this.answers
			?.sort((a, b) => a.orderNo - b.orderNo)
			?.forEach((answer: RiskProfileAnswersState) => {
				this.AnswersList?.push(this.patchValue(answer));
			});
	}

	patchValue(answer): UntypedFormGroup {
		return this.fb.group({
			...answer,
			id: answer.id,
			answer: answer.answer || '',
			score: answer.score || 0,
			origScore: answer.origScore || 0,
			orderNo: answer.orderNo || 0,
			origOrderNo: answer.origOrderNo || 0,
			status: answer.status,
			origStatus: answer.origStatus,
			isDefault: answer.isDefault,
			isEditing: false,
			isAdding: false,
			rendering: false,
		});
	}

	trackByFn = (index: number, item: any) => {
		return index;
	};

	updateStatus(index: number) {
		const item = this.AnswersList?.controls[index];
		const currentValue = item.get('status')?.value;
		item.get('status')?.setValue(currentValue === 1 ? 2 : 1);

		if (!item.get('isAdding')?.value && !item.get('isEditing')?.value) {
			this.save();	
		}
	}

	saveScore(i: number) {
		const item = this.AnswersList?.controls[i];

		if (!item.get('isAdding')?.value && !item.get('isEditing')?.value) {
			this.save();
		}
	}

	save(shouldSaveToAPI = false) {
		const data = this.updatePriority(this.AnswersList?.getRawValue());

		const answers = data.map((answer) => {
			return {
				id: answer.id,
				answer: answer.answer,
				isDefault: answer.isDefault,
				orderNo: answer.orderNo,
				origOrderNo: answer.origOrderNo,
				score: answer.score || 0,
				origScore: answer.origScore,
				status: answer.status,
				origStatus: answer.origStatus
			}
		});

		this.saveEvent.emit({
			question: this.question,
			answers,
			shouldSaveToAPI
		});
	}

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			// if transfer, recalculate the order of previous (the list from drag)
			event.previousContainer.data?.forEach((x, index) => {
				x.orderNo = index;
			});
		}
		// always, recalculate the order of the container (the list to drag)
		event.container.data?.forEach((x, index) => {
			x.orderNo = index;
		});

		this.save();
	}

	addNew() {
		this.isAdding = true;

		const ids = this.AnswersList?.controls?.map((control) => {
			return control.get('id')?.value;
		});

		const highest = ids && ids.length ? Math.max(...ids) + 1 : 1;

		this.AnswersList.push(
			this.fb.group({
				id:  highest,
				answer: null,
				score: 0,
				orderNo: this.AnswersList.length,
				status: 1,
				isDefault: false,
				isEditing: false,
				isAdding: true,
				isNew: true,
				rendering: false,
			})
		);

		this.disableEvent.emit(true);
	}

	saveAddEdit(i: number) {
		const item = this.AnswersList?.controls[i];

		const newAnswer = item.get('answer')?.value;

		if (newAnswer === null || newAnswer.trim() === '') {
			this.loggerService.Warning({}, 'Please provide a valid answer.');
			return;
		}

		const answers = this.answers?.map(({ answer }) => answer);

		const existing = answers?.find((answer) => {
			return answer === newAnswer;
		});
		

		if (this.currentValue?.answer !== newAnswer && existing) {
			this.loggerService.Warning({}, 'Answer already exist.');
			return;
		}

		this.save(true);

		item.get('isAdding')?.setValue(false);
		item.get('isEditing')?.setValue(false);
		this.isAdding = false;
		this.isEditing = false;
		this.currentValue = null;
		this.disableEvent.emit(false);
	}

	edit(i: number) {
		this.isEditing = true;
		const item = this.AnswersList?.controls[i];

		item.get('isEditing')?.setValue(true);
		this.currentValue = item.value;

		this.disableEvent.emit(true);
	}

	cancelAddEdit(i: number) {
		const item = this.AnswersList?.controls[i];

		if (this.isAdding) {
			this.AnswersList?.controls?.pop();
			this.isAdding = false;
		}

		if (this.isEditing) {
			item.patchValue(this.currentValue);
			item.get('isEditing')?.setValue(false);
			this.isEditing = false;

			item.get('rendering')?.setValue(true);

			setTimeout(() => {
				item.get('rendering')?.setValue(false);
			}, 0)
		}

		this.currentValue = null;
		this.disableEvent.emit(false);
	}

	cancelEdit(i: number) {
		const item = this.AnswersList?.controls[i];
		item.get('isEditing')?.setValue(false);
		this.currentValue = null;
	}

	delete(index: number) {
		this.AnswersList?.removeAt(index);
		this.save();
	}

	updatePriority(list) {
		return list?.map(({ order, ...item }, i) => ({
			...item,
			orderNo: i,
		}));
	}

}
