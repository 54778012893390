import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
	Output,
	EventEmitter,
} from '@angular/core';
import { ModelFactory, Model } from '../../core/base/model.service';
import { Subject, Observable } from 'rxjs';
import {
  takeUntil,
  filter,
  withLatestFrom,
  map,
} from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ControlsService } from '../../core/controls/controls.service';

@Component({
  selector: 'app-collapse,[app-collapse]',
  template: `<div [@expanded]="(IsExpandedState$ | async) ? 'show' : 'hide'">
    <ng-content></ng-content>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expanded', [
      state('show', style({ height: '*' })),
      state(
        'hide',
        style({ overflow: 'hidden', height: '0px' })
      ),
      transition('show <=> hide', [
        style({ overflow: 'hidden' }),
        animate('400ms ease-in-out'),
      ]),
    ]),
  ],
})
export class CollapseComponent implements OnDestroy {
  onDestroy$ = new Subject<void>();
  private IsExpanded: Model<boolean>;
  public readonly IsExpanded$: Observable<boolean>;
  public readonly IsExpandedState$: Observable<string>;

  @Input()
  set initialExpandedState(value: boolean) {
    if (typeof value === typeof true) { this.IsExpanded.set(value); }
  }
	@Output() isExpandState = new EventEmitter<any>();

  constructor(
    private booleanModelFactory: ModelFactory<boolean>,
    private controlService: ControlsService,
    private cd: ChangeDetectorRef
  ) {
    this.IsExpanded = this.booleanModelFactory.create(false);
    this.IsExpanded$ = this.IsExpanded.data$;
    this.IsExpandedState$ = this.IsExpanded$.pipe(
      map((x) => (x ? x?.toString() : ''))
    );

    this.IsExpanded$.pipe(
      takeUntil(this.onDestroy$),
      filter((x) => x)
    ).subscribe((x) => {
      this.controlService.closeAll(this);
    });
    this.controlService.closeEvent$
      .pipe(
        takeUntil(this.onDestroy$),
        withLatestFrom(this.IsExpanded$),
        filter(([component, isExpanded]) => this !== component && isExpanded)
      )
      .subscribe((x) => {
        this.Close();
      });
    this.IsExpanded$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
  Toggle = () => {
		const isExpanded = !this.IsExpanded.get();
		this.IsExpanded.set(isExpanded);
		this.isExpandState.emit(isExpanded);
	}
  Open = () => {
    this.IsExpanded.set(true);
		this.isExpandState.emit(true);
  };
  Close = () => {
		this.IsExpanded.set(false);
		this.isExpandState.emit(false);
	}
}
