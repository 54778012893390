<app-form-user 
    [staff]="" 
    [secGroups]="secGroups"
    [isView]="false"
    [header]="'Add user'" 
    [businesses] ="businesses"
    [onSubmitClick]="submitAdd"
    [btnText]="'Add'"
    (cancelEvent)="cancel()"
    >
</app-form-user>