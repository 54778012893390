import { Directive } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Directive()
export abstract class ComponentBase {
  private _subscriptions = new Subscription();

  protected onDestroy$ = new Subject<void>();

  /**
   * force the dev to implement angular life cycle hook OnDestroy
   * to dispose subscription
   */
  abstract ngOnDestroy(): void;

  subscribe<T>(obs: Observable<T>, cb?: (result: T) => void): void {
    this._subscriptions.add(obs.subscribe((e) => cb && cb(e)));
  }

  /**
   * unsubscribe to all subscription
   */
  dispose(): void {
    this._subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

}
