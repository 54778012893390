import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { DashboardService } from './state/dashboard.service';
import { tap, mergeMap, mapTo  } from 'rxjs/operators';
import { DropdownValueService } from '../../../domain/dropdown-value/dropdown-value.service';
const dropdownCodes: string[] = [
  'AT',
  'PCLT'
];
@Injectable()
export class DashboardResolverService implements Resolve<boolean> {

  constructor(
    private dashboardService: DashboardService,
    private dropdownValuesService: DropdownValueService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | Observable<boolean> | Promise<boolean> {
    return of(true).pipe(
      tap(() => this.dashboardService.reset()),
      mergeMap(() => forkJoin([
        this.dropdownValuesService.getDropdownValueList$(dropdownCodes),
        this.dashboardService.getWidgets(),
        this.dashboardService.getReferrals()
      ])),
      mapTo(true)
    );
  }

}
