import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, filter, finalize, map, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../../../../../../../core/base/api.service';
import { AdviceProcessSectionCodes } from '../../../../../../../../../shared/models/advice-process/advice-process.model';
import { objectUtil } from '../../../../../../../../../util/util';
import { CrtMortgageQuery } from '../../../../../state/crt-mortgage.query';
import { ApplicationService } from '../../../../state/application.service';
import { FundingRequiredLoanQuery } from '../../selling-section/loan-repaid/state/loan-repaid.query';
import { FundingRequiredLoanStore } from '../../selling-section/loan-repaid/state/loan-repaid.store';
import { LoanRefinanceState } from './loan-refinance.model';
import { LoanRefinanceQuery } from './loan-refinance.query';
import { LoanRefinanceStore } from './loan-refinance.store';

@Injectable()
export class LoanRefinanceService {
	loanRefinance$ = this.query.selectAll();
	isLoading$ = this.query.selectLoading();
  
  sourceType$ 

	constructor(
		private api: ApiService,
		protected crtMortgageQuery: CrtMortgageQuery,
		protected store: LoanRefinanceStore,
		protected query: LoanRefinanceQuery,
		protected repaidStore: FundingRequiredLoanStore,
		protected repaidQuery: FundingRequiredLoanQuery,
		private appService: ApplicationService
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(parentCRTId: number) {
		this.store.setLoading(true);
		this.store.update({ isLoadedLoans: false });
		const endpoint = `crt/${AdviceProcessSectionCodes.Application}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.LoanRefinance}`;

		return this.api.get<LoanRefinanceState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : [])),
			filter((x) => !!x),
			withLatestFrom(
				this.repaidQuery.loans$
			),
			tap(([refinance, fundingRequired]) => {
				let list = refinance;
				if (fundingRequired && fundingRequired.length) {
					const ids = fundingRequired
						?.filter((loan) => loan.isTicked)
						?.map((loan) => loan.linkedCRTId)
						?.filter(Boolean); // Remove 0 values
					list = list?.map((loan) => {
						return {
							...loan,
							hidden: ids?.includes(loan.linkedCRTId)
						}
					});
				}
				applyTransaction(() => this.store.set(list))
			}),
			finalize(() => {
				this.store.update({ isLoadedLoans: true });
				this.store.setLoading(false);
			}),
			catchError(() => of(null))
		);
	}

	add(data: LoanRefinanceState) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.post(`crt`, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.add({
						...data,
						cRTId: +x,
					});
				})
			),
			catchError(() => of(null))
		);
	}

	update(data: LoanRefinanceState) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.upsert(+data?.cRTId, data);
				});
			}),
			tap(() => this.appService.setIsUpdatedLoans(true)),
			tap(() => this.appService.setIsUpdatedLoans(false)),
			catchError(() => of(undefined))
		);
	}

	updateStore(ids: number[]) {
		const newIds = ids.filter((a) => a);
		const list: any = this.query.getAll();
		const idsToUpdate = list.filter(loan => newIds.includes(loan.linkedCRTId)).map(loan => loan.cRTId);
		this.store.upsert(idsToUpdate, { hidden: true });
		const idsToUnhide = list.filter(loan => !newIds.includes(loan.linkedCRTId)).map(loan => loan.cRTId);
		this.store.upsert(idsToUnhide, { hidden: false });
	}
}
