import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { MortgageCustomerServiceState } from 'src/app/shared/models/services/mortgage/mortgage.model';
import { PropertyAssetCustomerServiceState } from 'src/app/shared/models/services/property-asset/property-asset';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { Mortgage } from '../../../crt-mortgage/client-sop/assets-and-liabilities/state/mortgage/mortgage.model';

@Component({
	selector: 'app-crt-mortgage-template',
	templateUrl: './crt-mortgage-template.component.html',
	styleUrls: ['./crt-mortgage-template.component.scss'],
})
export class CrtMortgageTemplateComponent implements OnInit {
	@Input() mortgages: Mortgage[];
	@Input() borrowers$: Observable<ViewDisplayValue[]>;
	@Input() securities$: Observable<PropertyAssetCustomerServiceState[]>;
	@Input() crmMortgagesChoices: ViewDisplayValue[];
	@Input() crmMortgages: MortgageCustomerServiceState[];
	@Input() MP$: Observable<ViewDisplayValue[]>;
	@Input() MLT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	isAdd: boolean;

	constructor() {}

	ngOnInit(): void {}

	trackByFn(index: number, ks: Mortgage): number {
		return ks.cRTId;
	}

	add() {
		this.isAdd = true;
	}

	cancelEvent(event?) {
		this.isAdd = false;
	}

	saveEvent(event?) {
		this.isAdd = false;
	}
}
