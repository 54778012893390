import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { either, isNil, isEmpty } from 'ramda';
import { combineLatest, iif, interval, Observable, of, Subject } from 'rxjs';
import {
	concatMap,
	debounceTime,
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
	startWith,
	auditTime,
	skipWhile,
	distinctUntilChanged,
} from 'rxjs/operators';
import { MergeTagsMapper } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import {
	getContentWithMergeTags,
	resetMergeTags,
	removeCustomNotes,
	removeEmptyParagraphs,
	updateDependantsTbl,
	removeSoaDivs,
	removeMtWrappers,
	removeHTMLContentWhiteSpaces,
} from '../../../../../../shared/converter/content-merge-tags';
import { PrimaryClientState } from '../../../../../../shared/models/client-profile/primary-client/primary-client.model';
import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { InsuranceMergeTagsMapper as InsuranceMtMapper } from '../../../../../../shared/models/client-review-template/merge-tags/crt-lr-insurance/insurance/insurance.mapper';
import { SosMergeTagsMapper as sosMtMapper } from '../../../../../../shared/models/client-review-template/merge-tags/crt-lr-insurance/scope-of-service/sos.mapper';
import { StatementOfAdviceMapper as soaMapper } from '../../../../../../shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';
import { ScopeOfServiceMapper as sosMapper } from '../../../../../../shared/models/client-review-template/scope-of-service/scope-of-service.mapper';
import { LrProvidersMergeTagsMapper as lrMtMapper } from '../../../../../../shared/models/client-review-template/merge-tags/crt-lr-insurance/lr-providers/lr-providers.mapper';
import { StatementOfAdviceState } from '../../../../../../shared/models/client-review-template/statement-of-advice/statement-of-advice.model';
import { convertUtil, numUtil, objectUtil } from '../../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../../states/client-review-template.query';
import { ClientReviewTemplateService } from '../../../states/client-review-template.service';
import { CrtMergeTagsService } from '../../../states/merge-tags/crt-merge-tags.service';
import { StatementOfAdviceQuery } from '../../../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../../../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../../../states/statement-of-advice/statement-of-advice.store';
import { IncomeService } from '../../../states/income-budget/income.service';
import { RiskAnalysisService } from '../../../states/risk-analysis/risk-analysis.service';
import { ScopeOfServiceService } from '../../../states/scope-of-service/scope-of-service.service';
import { WysiwygComponent } from '../../../../../../shared/wysiwyg/wysiwyg.component';
import { ProviderCommissionSettingState } from '../../../../../../shared/models/provider-commission/provider-commission.model';
import {
	AdviceProcessDocumentField,
	AdviceProcessSectionCodes,
	SOASubSectionCodes,
} from '../../../../../../shared/models/advice-process/advice-process.model';
import { UserQuery } from '../../../../../../domain/user/user.query';
import { sosMtList } from '../../../../../../shared/models/client-review-template/merge-tags/crt-lr-insurance/scope-of-service/sos.merge-tag';
import { lrPoviderMtList } from '../../../../../../shared/models/client-review-template/merge-tags/crt-lr-insurance/lr-providers/lr-providers.merge-tag';
import { insuranceMtList } from '../../../../../../shared/models/client-review-template/merge-tags/crt-lr-insurance/insurance/insurance.merge-tag';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { CurrentInsuranceService } from '../../../states/current-insurance/current-insurance.service';
import { BusinessConfigService } from '../../../../../../domain/business-config/business-config.service';
import { PeopleService } from '../../../states/people/people.service';
import { SoaDocumentService } from '../documents/state/soa-documents.service';
import { SoaDocumentQuery } from '../documents/state/soa-documents.query';
import { CrtSettingsQuery } from 'src/app/modules/crt-settings/state/crt-settings.query';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { AdviceProcessDocumentTypes } from 'src/app/shared/models/documents/document.model';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import { curFreqType } from 'src/app/modules/crm/crt-page/_shared/calculations/annual-conversion';
import { applyTransaction } from '@datorama/akita';
import MomentUtil from '@util/moment.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '@shared/modal/confirm-modal/confirm-modal.component';

@Component({
	selector: 'app-soa-review',
	templateUrl: './soa-review.component.html',
	styleUrls: ['./soa-review.component.scss'],
})
export class SoaReviewComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Input() parentCRTId: number;
	@Input() otherMergeTags$: Observable<MergeTagState[]>;
	@Input() lrProviders$: Observable<ProviderCommissionSettingState[]>;
	@Output() finalizeSOA = new EventEmitter<void>();
	@Output() backPage = new EventEmitter<void>();
	@Output() moveToIndex = new EventEmitter();
	@Input() parent: any;

	hidePreview = true;
	isPreview = false;
	isNew = false;
	isCompleted = true;
	isInit = true;
	currentTemplate = '<p></p>';
	dynamicMergetagList = [];

	templateId: number;
	rawContent: string;
	data: StatementOfAdviceState;
	mergeTag: MergeTagState[];
	InsuranceMtMappers: MergeTagState[];
	insuranceMergeTags: MergeTagState[];
	@ViewChild('contentEditor') editor: WysiwygComponent;

	customerId$ = this.service.primaryClient$.pipe(
		map((x: PrimaryClientState) => x.customerID)
	);
	mergeTags$ = this.mtService.mergeTags$;
	adviceProcessId = this.query.getValue().adviceProcessId;

	// dropdowns
	APCRTLTCR$: Observable<ViewDisplayValue[]> = this.crtService.APCRTLTCR$;
	APCRTE$: Observable<ViewDisplayValue[]> = this.crtService.APCRTE$;
	APCRTESI$: Observable<ViewDisplayValue[]> = this.crtService.APCRTESI$;
	APCRTIID$: Observable<ViewDisplayValue[]> = this.crtService.APCRTIID$;

	people$ = this.service.people$;
	dependents$ = this.service.dependents$;
	sosDefault$ = this.sosService.sosDefault$;
	sosCrt$ = this.sosService.scopeOfService$;
	proposedInsurances$ = this.soaQuery.soaPI$;
	lifeAssured$ = this.service.lifeAssured$;
	transferedSCIList$ = this.query.transferedSCIList$;
	incomeSource$ = this.incomeService.incomeSource$;
	isLoading$ = this.soaQuery.isLoadingSOAR$;
	isSavingSOAReview$ = this.soaQuery.isSavingSOAReview$;
	isSavingSOA$ = this.soaQuery.isSavingSOA$;
	currentSOA$ = this.soaQuery.selectActive();
	defaultFrequency = this.dropdownValueQuery
		.getAll({ filterBy: (x) => x.DropdownCode === 'APCRTF' })
		?.find((x) => !!x.IsDefault)?.DropdownValue;

	isTapLevel = this.userQuery.isTapLevel();
	currentInsurances$ = this.service.currentInsurances$;
	documentName = 'Statement of Advice'; // Document Name on Advice Process Documents
	combineDocuments$ = this.soaDocumentQuery.applicationDocuments$.pipe(
		map((x) =>
			x?.filter(
				(i) =>
					!!i?.combine &&
					(i?.document === 'Statement of Advice' || !!i?.documentId)
			)
		)
	);
	lastAutoSaveDateTime: string;
	isAutoSaveEnabled: boolean;

	public bsModalRef: BsModalRef;

	constructor(
		protected query: ClientReviewTemplateQuery,
		private crtService: ClientReviewTemplateService,
		private soaService: StatementOfAdviceService,
		private service: ClientReviewTemplateService,
		private mtService: CrtMergeTagsService,
		private soaStore: StatementOfAdviceStore,
		private soaQuery: StatementOfAdviceQuery,
		private incomeService: IncomeService,
		private riskService: RiskAnalysisService,
		private sosService: ScopeOfServiceService,
		private userQuery: UserQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private ciService: CurrentInsuranceService,
		private businessService: BusinessConfigService,
		private peopleService: PeopleService,
		private documentService: SoaDocumentService,
		private soaDocumentQuery: SoaDocumentQuery,
		private renderer: Renderer2,
		private settingsQuery: CrtSettingsQuery,
		private crtDocService: CrtDocumentService,
		private loggerService: LoggerService,
		private modalService: BsModalService
	) {}

	ngOnInit(): void {
		this.prepData();
		this.getListOfDynamicMergeTags();
		this.updateDynamicTagsPages();
	}

	prepMergeTags(mergeTags: MergeTagState[]) {
		of(mergeTags)
			.pipe(
				tap((x) => (this.mergeTag = x)),
				take(1)
			)
			.subscribe();
	}

	prepData(allowAutoSave?: boolean) {
		this.soaStore.setSOALoading(true, SOASubSectionCodes.Review);

		combineLatest([
			this.soaQuery.selectEntity(+this.parentCRTId),
			this.mergeTags$,
			this.otherMergeTags$,
			this.currentInsurances$,
			this.proposedInsurances$,
			this.people$,
			this.incomeSource$,
			this.lrProviders$,
		])
			.pipe(
				tap(() => (this.isInit = true)),
				filter(
					([
						data,
						mergeTags,
						otherMergeTags,
						currentInsurance,
						proposedInsurance,
						people,
						incomeSource,
						lrProviders,
					]) =>
						!!data &&
						!!mergeTags &&
						!!otherMergeTags &&
						!!currentInsurance &&
						!!proposedInsurance &&
						!!people &&
						!!incomeSource &&
						!!lrProviders
				),
				tap(([, mergeTags]) =>
					this.prepMergeTags(mergeTags as MergeTagState[])
				),
				map(([data]) => {
					this.data = data as StatementOfAdviceState;
					const getData = data as StatementOfAdviceState;
					return {
						documentId: getData?.document?.referenceId,
						isCompleted: getData?.currentPage === SOASubSectionCodes.Completed,
					};
				}),
				tap((data) => (this.isCompleted = data?.isCompleted)),
				withLatestFrom(this.soaService.soaSettings$),
				switchMap(([data]) =>
					iif(
						() => isNil(data?.documentId),
						this.getSettingsTemplate(),
						this.getTemplate(+data?.documentId)
					)
				),
				delay(500),
				concatMap(() => this.previewContent()),
				tap((data) => (this.currentTemplate = data)),
				concatMap((x) => this.updateDynamicMergeTags(x)),
				take(1)
			)
			.subscribe((content: string) => {
				this.isInit = false;
				this.soaStore.setSOALoading(false, SOASubSectionCodes.Review);
				this.currentTemplate = content;

				if (
					allowAutoSave ||
					(!this.data?.document &&
						this.data?.currentPage === SOASubSectionCodes.Review)
				) {
					this.initializeAutoSave();
				}
			});
	}

	initializeAutoSave() {
		if (this.isAutoSaveEnabled) {
			// If already running
			return;
		}

		const autoSave = () =>
			interval(300000) // 5 minutes
				.pipe(
					withLatestFrom(this.currentSOA$, this.isSavingSOAReview$),
					filter(
						([, soa, isSaving]) =>
							soa?.currentPage === SOASubSectionCodes.Review && !isSaving
					),
					switchMap(() => this.save(SOASubSectionCodes.Review)),
					tap(() => {
						this.lastAutoSaveDateTime = MomentUtil.formatToServerTime(
							MomentUtil.createMomentNz()
						);
					}),
					takeUntil(this.onDestroy$)
				)
				.subscribe();

		this.currentSOA$
			.pipe(
				distinctUntilChanged(),
				skipWhile((soa) => soa?.currentPage !== SOASubSectionCodes.Review),
				tap(() => {
					this.isAutoSaveEnabled = true;
					autoSave();
				}),
				take(1)
			)
			.subscribe();
	}

	updateDynamicTagsPages() {
		// Update Dynamic Merge Tags & Pages
		combineLatest([
			this.proposedInsurances$.pipe(startWith([])),
			this.lifeAssured$.pipe(startWith([])),
			this.lrProviders$.pipe(startWith([])),
			this.people$.pipe(startWith([])),
			this.dependents$.pipe(startWith([])),
			this.transferedSCIList$.pipe(startWith([])),
			this.mergeTags$.pipe(startWith([])),
			this.sosCrt$.pipe(startWith(null as any)),
			this.sosDefault$.pipe(startWith(null as any)),
		])
			.pipe(
				skipWhile(() => this.isInit),
				distinctUntilChanged(),
				tap(() => {
					this.soaStore.setSOALoading(true, SOASubSectionCodes.Review);
				}),
				filter(
					([proposedInsurance, lifeAssured, lrProviders]) =>
						!!proposedInsurance && !!lifeAssured && !!lrProviders
				),
				debounceTime(350),
				mergeMap(() => of(this.editor?.content)),
				concatMap((x) => this.updateDynamicMergeTags(x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe((data: string) => {
				this.soaStore.setSOALoading(false, SOASubSectionCodes.Review);
				this.currentTemplate = data;
			});
	}

	updateDynamicMergeTags = (content: string, reset = false) =>
		of(content || '').pipe(
			concatMap((x) => this.updateInsuranceMergeTags(x)),
			concatMap((x) => this.updateSosMergeTags(x)),
			concatMap((x) => this.updateLrProvidersMergeTags(x)),
			concatMap((x) => this.updateDynamicDependants(x, reset)),
			concatMap((x) => this.updateSosPages(x)),
			map((x) => removeCustomNotes(x)),
			map((x) => removeEmptyParagraphs(x)),
			take(1)
		);

	updateInsuranceMergeTags = (content?: string) =>
		combineLatest([
			this.currentInsurances$.pipe(startWith([])),
			this.proposedInsurances$.pipe(startWith([])),
			this.lifeAssured$.pipe(startWith([])),
			this.transferedSCIList$.pipe(startWith([])),
		]).pipe(
			auditTime(0),
			withLatestFrom(this.currentSOA$, this.people$, this.dependents$),
			map(
				([
					[currentInsurance, proposedInsurance, lifeAssured, transferedSCIList],
					soa,
					people,
					dependants,
				]) => {
					const frequency = (soa?.paymentFrequency ||
						this.defaultFrequency) as curFreqType;
					// Add transfered SCI in life assured people list
					const lifeAssuredPeople = (transferedSCIList || [])?.reduce(
						(a, c) => {
							return [
								...a,
								{
									display: c?.name || '',
									value: c?.customerId,
								},
							];
						},
						lifeAssured || []
					);

					// Proposed Insurance Structure
					const proposedInsuranceStructure =
						soaMapper.generateProposedInsuranceStucture(
							proposedInsurance,
							lifeAssuredPeople,
							people,
							dependants
						);

					// Proposed Insurance Premium
					const proposedInsurancePremium =
						soaMapper.generateProposedInsurancePremium(
							proposedInsurance,
							lifeAssuredPeople,
							people,
							dependants,
							frequency
						);

					// Current Insurance Premium
					const currentInsurancePremium =
						soaMapper.generateCurrentInsurancePremium(
							currentInsurance,
							lifeAssuredPeople,
							people,
							dependants,
							frequency
						);

					return {
						proposedInsuranceStructure,
						proposedInsurancePremium,
						currentInsurancePremium,
					};
				}
			),
			map((data) => InsuranceMtMapper.getSoaMergeTags(data)),
			map((data) => {
				let template = content ?? this.currentTemplate;
				if (
					!this.data?.document?.referenceId ||
					(this.data?.document?.referenceId && !this.isInit)
				) {
					// This executes always except only on initialize of Edit SOA
					const updated = InsuranceMtMapper.revertInsuranceMergeTags(template);
					template = getContentWithMergeTags(updated, data);
				}
				return template;
			}),
			take(1)
		);

	updateSosMergeTags = (content?: string) =>
		combineLatest([
			this.mergeTags$.pipe(startWith([])),
			this.sosCrt$.pipe(startWith(null as any)),
			this.sosDefault$.pipe(startWith(null as any)),
		]).pipe(
			auditTime(0),
			map(([data, sosCrt, sosDefault]) =>
				sosMtMapper.getSosMergeTags(data, sosCrt, sosDefault)
			),
			map((mergeTag) => {
				let template = content ?? this.currentTemplate;
				template = sosMtMapper.revertSosMergeTags(template);
				return getContentWithMergeTags(template, mergeTag);
			}),
			take(1)
		);

	updateSosPages = (content?: string) =>
		combineLatest([
			this.sosCrt$.pipe(startWith(null as any)),
			this.sosDefault$.pipe(startWith(null as any)),
		]).pipe(
			auditTime(0),
			map(([crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
			map((data) => {
				const template = content ?? this.currentTemplate;
				return sosMtMapper.toggleSosPages(template, data);
			}),
			take(1)
		);

	updateLrProvidersMergeTags = (content?: string) =>
		combineLatest([
			this.proposedInsurances$.pipe(startWith([])),
			this.lrProviders$.pipe(startWith([])),
		]).pipe(
			auditTime(0),
			map(([insurance, providers]) =>
				lrMtMapper.getListOfProviders(insurance, providers)
			),
			map((providers: ProviderCommissionSettingState[]) =>
				lrMtMapper.getLrProviderMergeTags(providers, this.mergeTag)
			),
			map((mergeTag) => {
				let template = content ?? this.currentTemplate;
				if (
					!this.data?.document?.referenceId ||
					(this.data?.document?.referenceId && !this.isInit)
				) {
					// This executes always except only on initialize of Edit SOA
					const updated = lrMtMapper.revertProviderMergeTags(template);
					template = getContentWithMergeTags(updated, mergeTag);
				}
				return template;
			}),
			take(1)
		);

	updateDynamicDependants = (content: string, isReset = false) =>
		of(true).pipe(
			withLatestFrom(this.dependents$),
			map(([, x]) => {
				const noDependants = either(isNil, isEmpty)(x);
				const template = content ?? this.currentTemplate;
				const isNew = !this.data?.document?.referenceId && this.isInit;

				// Trigger only on create and reset
				if (isReset || isNew) {
					return updateDependantsTbl(template, noDependants);
				}
				return template;
			}),
			take(1)
		);

	updateCurrentTemplate(mergeTag: MergeTagState[]) {
		this.currentTemplate = getContentWithMergeTags(
			this.currentTemplate,
			mergeTag
		);
	}

	getSettingsTemplate = () =>
		this.soaService.soaSettings$.pipe(
			tap(() => {
				this.templateId = null;
				this.isNew = true;
			}),
			concatMap((x) => this.soaService.getDocumentFromURL(x?.templateLink)),
			tap((data) => (this.rawContent = data)),
			take(1)
		);

	getTemplate = (documentId?: number, isNew = false) =>
		of(documentId).pipe(
			tap((id) => {
				this.templateId = isNew ? null : +id;
				this.isNew = isNew;
			}),
			concatMap((id) => this.soaService.getSoaDocumentFile(+id)),
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			concatMap((res) =>
				iif(
					() => res?.documentLink,
					this.soaService.getDocumentFromURL(res?.documentLink),
					of(null)
				)
			),
			tap((data) => (this.rawContent = data)),
			take(1)
		);

	previewContent = () =>
		of(this.isNew).pipe(
			withLatestFrom([this.mergeTag], [this.rawContent], this.people$),
			map(([isNew, mergeTag, template, people]) => {
				mergeTag = soaMapper.mapSoaWordings(mergeTag);
				const updatedTags = MergeTagsMapper.mapCrtMergeTags(
					this.updateGrossIncomeMt(mergeTag, people) || []
				);
				return isNew
					? getContentWithMergeTags(template, updatedTags)
					: template;
			}),
			take(1)
		);

	updateGrossIncomeMt = (
		mergeTag: MergeTagState[] = [],
		people: PeopleState[] = []
	) =>
		mergeTag?.map((i: MergeTagState) =>
			i?.metaKey === 'PEOPLE_GROSS_INCOME'
				? { ...i, value: this.computeGrossIncome(people) || i?.value }
				: i
		) || [];

	computeGrossIncome(people: PeopleState[]) {
		const newData =
			people?.map((i) => ({
				...i,
				totalGross: numUtil.formatWholeNumNoDecimal(
					+this.riskService.getTotalGrossIncomeAnnual(+i?.cRTId) || 0
				),
			})) || ([] as PeopleState[]);
		return newData?.map((i) => `$${i?.totalGross}`) || [];
	}

	/**
	 * Advice Process update document
	 * @param id DocumentID
	 * @param field Document in Advice Process CRM
	 * @returns Observable<string>
	 */
	updateAdviceProcess(
		id: number,
		field: string = AdviceProcessDocumentField.SOA
	) {
		const adviceProcess = this.query.getValue().adviceProcess;
		const lradvice = {
			...adviceProcess,
			documents: adviceProcess.documents?.map((doc) => {
				return {
					...doc,
					value:
						doc.field.toLowerCase() === field.toLowerCase()
							? id
							: doc.value?.documentID ?? null,
				};
			}),
		};
		return this.soaService.updateLRAdviceProcess(lradvice);
	}

	showErrorTemplate() {
		const redirect$ = new Observable((obs) => {
			this.moveToIndex.emit(this.editor.content);
			obs.complete();
		});
		const initialState = {
			header: 'Error',
			message: `Page timeout, please refresh your page.`,
			isOkBtn: true,
			confirm$: redirect$,
			decline$: redirect$,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	isTemplateEmpty = () => soaMapper.isTemplateEmpty(this.editor?.content);

	// tslint:disable-next-line: ban-types
	updateSoaDocument = (callback?: Function) =>
		of(this.editor?.content).pipe(
			tap(() => {
				this.soaStore.setSOALoading(true, SOASubSectionCodes.Review);
			}),
			mergeMap((template) => convertUtil.convertToBase64(template)),
			withLatestFrom([this.templateId], [this.parentCRTId]),
			map(([template, templateId, cRTId]) =>
				soaMapper.mapDocumentUpload(template, cRTId, templateId)
			),
			concatMap((data) =>
				iif(
					() => this.isDocumentEmpty(data.documentId),
					this.soaService.newFileUploadSOA(data),
					this.soaService.updateFileUploadSOA(data)
				)
			),
			tap(
				(data) =>
					(this.templateId = this.isDocumentEmpty(this.templateId)
						? +data
						: this.templateId)
			),
			withLatestFrom([this.templateId], [this.parentCRTId]),
			concatMap(([data, templateId]) => {
				const docId = this.isDocumentEmpty(templateId) ? data : +templateId;
				return iif(
					() => typeof callback === 'function',
					of(
						callback({
							document: {
								referenceId: docId,
							},
						})
					),
					of(null)
				);
			}),
			finalize(() => {
				this.soaStore.setSOALoading(false, SOASubSectionCodes.Review);
			}),
			take(1)
		);

	isDocumentEmpty = (docId: string | number) =>
		isNil(docId) || isNaN(+docId) || +docId === 0;

	resetAllMergeTags() {
		this.soaStore.setSOALoading(true, SOASubSectionCodes.Review);
		this.parent
			.checkSoaForUpdate(this.parentCRTId)
			.pipe(
				filter((x) => {
					if (!x) {
						this.soaStore.setSOALoading(false, SOASubSectionCodes.Review);
						return false;
					}
					return !!x;
				}),
				concatMap(() => this.businessService.get(true)),
				concatMap(() => this.refetchMergeTags()),
				concatMap(() =>
					this.peopleService.getPeople(
						this.adviceProcessId,
						AdviceProcessSectionCodes.People
					)
				),
				concatMap(() =>
					this.incomeService.getIncomeSources(
						this.adviceProcessId,
						AdviceProcessSectionCodes.IncomeSource
					)
				),
				concatMap(() =>
					this.sosService.getScopeOfService(
						+this.adviceProcessId,
						AdviceProcessSectionCodes.SOS
					)
				),
				withLatestFrom(of(this.editor?.content)),
				map(([, x]) => sosMtMapper.revertSosMergeTags(x)),
				map((x: string) => InsuranceMtMapper.revertInsuranceMergeTags(x)),
				map((x: string) => lrMtMapper.revertProviderMergeTags(x)),
				map((x: string) => resetMergeTags(x, this.dynamicMergetagList)),
				withLatestFrom(this.mtService.mergeTags$, this.people$),
				map(([x, mergeTag, people]) => {
					this.mergeTag = soaMapper.mapSoaWordings(mergeTag);
					const updatedMergeTags = MergeTagsMapper.mapCrtMergeTags(
						this.updateGrossIncomeMt(this.mergeTag, people) || []
					);
					return getContentWithMergeTags(x, updatedMergeTags);
				}),
				concatMap((x: string) => this.updateDynamicMergeTags(x, true)),
				delay(100),
				finalize(() =>
					this.soaStore.setSOALoading(false, SOASubSectionCodes.Review)
				),
				take(1)
			)
			.subscribe((x) => (this.currentTemplate = x as string));
	}

	refetchMergeTags = () =>
		this.mtService.getDefaultMergeTags(true).pipe(
			concatMap(() => this.mtService.getAdviserProviderMt()),
			concatMap(() => this.mtService.getFactFindMt(+this.adviceProcessId)),
			concatMap(() => this.mtService.getRiskAnalysisMt(+this.adviceProcessId)),
			concatMap(() => this.mtService.getSosMt(+this.adviceProcessId)),
			concatMap(() => this.soaService.getDefaultSoaSettings(0, 'SOA')),
			concatMap(() =>
				this.ciService.getCurrentInsurance(+this.adviceProcessId, 'FCL')
			),
			take(1)
		);

	getListOfDynamicMergeTags() {
		// List all metakey from FE merge tags
		// This will be used on resetMergeTags
		const sosMetakey = sosMtList();
		const lrPoviderMetakey = lrPoviderMtList();
		const insuranceMetakey = insuranceMtList();

		this.dynamicMergetagList = [
			...sosMetakey,
			...lrPoviderMetakey,
			...insuranceMetakey,
		];
	}

	save(currentPage?: string) {
		this.soaStore.setSOASaving(true, SOASubSectionCodes.Review);
		const saveCallback = (data) => {
			this.soaService
				.updateSoa(this.parentCRTId, SOASubSectionCodes.Completed, { ...data })
				.pipe(
					tap(() => {
						applyTransaction(() => {
							if (currentPage) {
								// Update state only to go to previous tab
								this.soaStore.updateActive({ currentPage });
							}
						});
					}),
					take(1)
				)
				.subscribe();
		};

		return of(saveCallback).pipe(
			filter(() => {
				if (this.isTemplateEmpty()) {
					this.showErrorTemplate();
					return false;
				}
				return true;
			}),
			mergeMap(this.updateSoaDocument),
			tap(() => {
				this.soaStore.setSOASaving(false, SOASubSectionCodes.Review);
			}),
			take(1)
		);
	}

	back() {
		this.soaService
			.updateSoa(this.parentCRTId, SOASubSectionCodes.Documents)
			.pipe(
				take(1),
				finalize(() =>
					this.soaStore.updateActive({
						currentPage: SOASubSectionCodes.Documents,
					})
				)
			)
			.subscribe();
	}

	combineDocuments() {
		const docSettings = this.settingsQuery.getValue().documentList;

		this.soaDocumentQuery
			.selectLoading()
			.pipe(
				delay(300),
				skipWhile((x) => !!x),
				tap(() => this.soaStore.setSOALoading(true, SOASubSectionCodes.Review)),
				withLatestFrom(
					this.soaDocumentQuery.applicationDocuments$,
					this.soaQuery.selectEntity(+this.parentCRTId)
				),
				mergeMap(([, appDocuments, soaData]) => {
					const filename = soaData?.name;
					const soa = appDocuments?.find(
						(x) => x?.document === this.documentName
					);
					return iif(
						() => !!soa?.combine,
						of(appDocuments).pipe(
							switchMap(() =>
								this.cleanContentForDownload(this.editor.content || '')
							),
							map((x) => `<div class="soa-pdf-file">${x}</div>`),
							concatMap((x) =>
								this.soaService.convertSoaTemplateToBase64Pdf(x, filename)
							),
							map((currentTemplate) => ({
								ReferenceId: this.query.getValue().adviceProcessId,
								CustomerId: this.query.getValue().primaryClient?.customerID,
								Document: currentTemplate,
								FileName: `${filename}.pdf`,
								DocumentType: ServicesCodes.LR,
								Type: AdviceProcessDocumentTypes.soaCombinedPdf,
							})),
							concatMap((x) => this.crtDocService.saveDocument(x)),
							map((x) => {
								return appDocuments?.map((i) => {
									if (i?.document === this.documentName) {
										return {
											...i,
											documentId: +x,
										};
									}
									return i;
								});
							})
						),
						of(appDocuments)
					);
				}),
				map((x: any[]) => {
					const docs = x
						?.map((doc) => {
							const setting = docSettings?.find(
								(s) => s?.documentName === doc.document && s?.isActive
							);
							return {
								...doc,
								isEnable: setting ? setting.isEnable : true,
							};
						})
						?.filter((doc) => doc.isEnable);
					return docs
						?.filter((doc) => doc.documentId > 0 && doc.combine)
						?.map((doc) => doc.documentId);
				}),
				concatMap((x) => this.documentService.combineDocuments(x)),
				finalize(() =>
					this.soaStore.setSOALoading(false, SOASubSectionCodes.Review)
				),
				take(1)
			)
			.subscribe({
				next: (file) => {
					const name = `${this.query
						.getValue()
						.primaryClient.lastName?.toUpperCase()} COMBINED STATEMENT OF ADVICE.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(file);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				},
				error: (err) => {
					try {
						const fr = new FileReader();
						fr.onload = (e: any) => {
							this.loggerService.Log({}, JSON.parse(e.target.result)?.Document);
						};
						fr.readAsText(err);
					} catch (e) {
						this.loggerService.Log({}, 'Something went wrong');
					}
				},
			});
	}

	cleanContentForDownload = (content: string) =>
		// Use this only when converting final contents of SOA to PDF
		of(content || '').pipe(
			map((x) => removeSoaDivs(x)),
			map((x) => removeCustomNotes(x)),
			map((x) => removeMtWrappers(x)),
			map((x) => removeHTMLContentWhiteSpaces(x, true)),
			take(1)
		);

	ngOnDestroy() {
		this.isAutoSaveEnabled = false;
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
