import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { Event, Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { applyTransaction } from '@datorama/akita';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { isNil } from 'ramda';
import { Observable, Observer, of, Subject, iif } from 'rxjs';
import {
	finalize,
	take,
	takeUntil,
	tap,
	filter,
	skipWhile,
	withLatestFrom,
	map,
	concatMap
} from 'rxjs/operators';
import { DocumentListService } from 'src/app/modules/crt-settings/soa-settings/document-list/state/document-list.service';
import { SettingsTypes } from 'src/app/modules/crt-settings/state/crt-settings.model';
import { Fields } from 'src/app/shared/error-message/error-message';
import { ConfirmModalComponent } from '../../../../../shared/modal/confirm-modal/confirm-modal.component';
import { SOASubSectionCodes } from '../../../../../shared/models/advice-process/advice-process.model';
import { MergeTagState } from '../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ProviderCommissionSettingState } from '../../../../../shared/models/provider-commission/provider-commission.model';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { StatementOfAdviceQuery } from '../../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../../states/statement-of-advice/statement-of-advice.store';
import { SoaDocumentsComponent } from './documents/soa-documents.component';
import { SoaDocument } from './documents/state/soa-documents.model';
import { SoaDocumentQuery } from './documents/state/soa-documents.query';
import { SoaDocumentService } from './documents/state/soa-documents.service';
import { SoaProposedInsuranceComponent } from './proposed-insurance/proposed-insurance.component';
import { SoaReviewComponent } from './soa-review/soa-review.component';

@Component({
	selector: 'app-soa-subpage',
	templateUrl: './subpage.component.html',
	styleUrls: ['./subpage.component.scss'],
})
export class SoaSubpageComponent
	implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	@ViewChild('subpageTab', { static: false }) subpageTab: TabsetComponent;
	@Input() parentCRTId: number;
	@Input() viewMode: string;
	@Input() isCompany: boolean;
	@Input() adviceProcessId: number;
	@Input() clientId: number;
	@Input() otherMergeTags$: Observable<MergeTagState[]>;
	@Input() lrProviders$: Observable<ProviderCommissionSettingState[]>;
	@Output() changeView = new EventEmitter();
	step = 0;

	@ViewChild(SoaProposedInsuranceComponent)
	piComponent: SoaProposedInsuranceComponent;
	@ViewChild(SoaDocumentsComponent)
	soaDocumentsComponent: SoaDocumentsComponent;
	@ViewChild(SoaReviewComponent)
	reviewComponent: SoaReviewComponent;

	active$ = this.soaQuery.selectActive();

	isLoadingPI$: Observable<boolean>;
	isLoadingSOAR$: Observable<boolean>;

	isSavingSOA$: Observable<boolean>;
	isSavingPI$: Observable<boolean>;
	isSavingSOAReview$: Observable<boolean>;
	soaSubSectionCodes = SOASubSectionCodes;

	isInit = true;
	cRTId: number;

	soaDocuments$: Observable<SoaDocument[]>;

	constructor(
		private soaStore: StatementOfAdviceStore,
		protected soaQuery: StatementOfAdviceQuery,
		private cd: ChangeDetectorRef,
		private modalService: BsModalService,
		private soaService: StatementOfAdviceService,
		private crtQuery: ClientReviewTemplateQuery,
		private router: Router,
		private soaDocumentService: SoaDocumentService,
		private soaDocumentQuery: SoaDocumentQuery,
		private dlService: DocumentListService,
		private routeService: RouteService
	) {}

	ngOnInit(): void {
		this.crtQuery.triggerLeaveSoa$
			.pipe(
				skipWhile(() => this.isInit),
				filter((x) => !!x),
				tap((x) => {
					if (x !== 'statement-of-advice') {
						this.leaveSOA(null, x);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.soaDocumentService
			.getApplicationDocuments(this.parentCRTId)
			.pipe(
				finalize(
					() =>
						(this.soaDocuments$ =
							this.soaDocumentQuery.applicationDocuments$)
				),
				take(1)
			)
			.subscribe();

		this.dlService.getDocumentList(0, SettingsTypes.DocumentList).pipe(
			take(1)
		).subscribe();
	}

	ngAfterContentInit(): void {
		this.isLoadingPI$ = this.soaQuery.isLoadingPI$;
		this.isLoadingSOAR$ = this.soaQuery.isLoadingSOAR$;

		this.isSavingSOA$ = this.soaQuery.isSavingSOA$;
		this.isSavingPI$ = this.soaQuery.isSavingPI$;
		this.isSavingSOAReview$ = this.soaQuery.isSavingSOAReview$;
		this.cd.detectChanges();
	}

	ngAfterViewInit(): void {
		this.active$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this.step = x?.currentPage === SOASubSectionCodes.Review ? 2 : x?.currentPage === SOASubSectionCodes.Documents ? 1 : 0;
			this.soaService.setSoaCurrentTab(x?.currentPage);
			this.subpageTab.tabs[this.step].active = true;
			this.cd.detectChanges();
			this.setSoaChanges();
			this.isInit = false;
			this.cRTId = x?.cRTId;
		});
	}

	moveToIndex(event?: Event) {
		this.changeView.emit(event);

		applyTransaction(() => {
			if (this.soaQuery.hasActive()) {
				this.soaStore.updateActive({
					currentPage: !!this.soaQuery.getActive()?.document?.referenceId
						? SOASubSectionCodes.Completed
						: this.soaQuery.getActive()?.currentPage,
				});
				this.soaStore.setActive(null);
			}
			this.soaStore.resetSubpages();
			this.resetLeaveSoa();
		});
	}

	backToSOA(event: Event) {
		this.leaveSOA(event);
	}

	leaveSOA(event?: Event, path?: string) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.saveSOA(path);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (event) {
				this.refreshSoaList();
			} else {
				this.navigateToUrl(path);
			}
			obs.complete();
		});

		this.leaveSoaModal(confirm, decline);
	}

	leaveSoaModal(confirm: Observable<any>, decline: Observable<any>) {
		const close = new Observable((obs: Observer<any>) => {
			this.soaService.setTriggerLeaveSoa(null);
			obs.complete();
		});

		const initState = {
			header: 'Leave SOA?',
			message: `Changes that you made may not be saved.`,
			confirm$: confirm,
			decline$: decline,
			close$: close,
			confirmTxt: 'Save',
			cancelTxt: 'Leave',
			detachCloseIcon: true,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveSOA(path?) {
		this.reviewComponent
			.save()
			.pipe(
				tap(() => {
					if (path) {
						this.navigateToUrl(path);
					} else {
						this.refreshSoaList();
					}
				}),
				take(1),
			)
			.subscribe();
	}

	next() {
		this.checkSoaForUpdate(this.cRTId).pipe(
			filter((x) => !!x),
			tap(() => {
				if (this.step > 1) {
					this.saveSOA();
				} else if (this.step === 1) {
					this.soaDocumentsComponent.next();
					this.reviewComponent.prepData(true);
				} else {
					this.piComponent.next();
				}
				this.setSoaChanges();
			}),
			take(1)
		).subscribe();
	}

	prev() {
		this.checkSoaForUpdate(this.cRTId).pipe(
			filter((x) => !!x),
			tap(() => {
				if (this.step === 2) {
					this.prevFromSoa();
				} else {
					this.soaDocumentsComponent.previous();
				}
			}),
			take(1)
		).subscribe();
	}

	goToIndex(event?) {
		this.refreshSoaList();
	}

	prevFromSoa() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.backToDocuments(true);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.backToDocuments(false);
			obs.complete();
		});

		this.leaveSoaModal(confirm, decline);
	}

	backToDocuments(save: boolean) {
		const referenceId = this.soaQuery.getActive()?.document?.referenceId;
		const currentPage = !!referenceId
			? SOASubSectionCodes.Completed
			: SOASubSectionCodes.Documents;

		of(save).pipe(
			concatMap((isSave) =>
				iif (
					() => !!isSave,
					this.reviewComponent.save(SOASubSectionCodes.Documents),
					of(referenceId).pipe(
						concatMap((id) =>
							iif(
								() => !!id,
								of(id),
								this.soaService.updateSoa(this.parentCRTId, currentPage)
							)
						)
					)
				)
			),
			tap(() => {
				applyTransaction(() => {
					// Update state only to go to previous tab
					this.soaStore.updateActive({
						currentPage: SOASubSectionCodes.Documents
					});
				});
				this.soaStore.setSOALoading(false, 'SOA');
				this.soaStore.setSOASaving(false, 'SOA');
				this.soaStore.setSOASaving(false, SOASubSectionCodes.Review);
			}),
			take(1),
		).subscribe();
	}

	navigateToUrl(path: string) {
		if (isNil(path)) {
			this.resetLeaveSoa();
			return;
		}
		if (path === 'crm') {
			const redirectRoute = this.isCompany
				? this.routeService.businessAdviceProcess(this.clientId, this.adviceProcessId)
				: this.routeService.customerAdviceProcess(this.clientId, this.adviceProcessId);
			this.router.navigate(redirectRoute);
		} else {
			const baseUrl = this.router.url?.split('/')?.slice(1, 7)?.join('/');
			const navigateTo = baseUrl + '/' + path;
			this.router.navigateByUrl(navigateTo);
		}
	}

	resetLeaveSoa() {
		this.soaService.setHasSoaChanges(false);
		this.soaService.setTriggerLeaveSoa(null);
		this.soaService.setSoaCurrentTab(null);
	}

	setSoaChanges() {
		this.soaService.setHasSoaChanges(true);
	}

	// tslint:disable-next-line: ban-types
	checkSoaForUpdate(cRTId: number) {
		return this.soaService
			.getSoaByCRT(cRTId)
			.pipe(
				withLatestFrom(this.soaQuery.selectEntity(cRTId)),
				map(([refetchedData, currentData]) => {
					if (!!currentData?.document?.referenceId) {
						// Bypass checking if the SOA is not incomplete
						return refetchedData;
					}
					if (
						!!refetchedData?.document?.referenceId ||
						currentData?.currentPage !== refetchedData?.currentPage
					) {
						// If there are changes on the SOA; Refresh SOA list
						this.refreshModal();
						return false;
					} else {
						return refetchedData;
					}
				}),
				take(1)
			)
	}

	refreshModal() {
		const confirm = new Observable((obs) => {
			this.refreshSoaList();
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.refreshSoaList();
			obs.complete();
		});

		const initState = {
			header: 'SOA update',
			message: Fields.SOAupdated,
			confirm$: confirm,
			decline$: decline,
			isOkBtn: true,
			isAcceptBtn: false
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	refreshSoaList() {
		this.soaStore.setSOASaving(true, 'SOA');
		this.soaStore.setSOALoading(true, 'SOA');
		this.soaService.getSoa()
			.pipe(
				finalize(() => {
					this.moveToIndex();
					this.soaStore.setSOALoading(false, 'SOA');
					this.soaStore.setSOASaving(false, 'SOA');
					this.soaStore.setSOASaving(false, SOASubSectionCodes.Review);
					this.soaService.setHasSoaChanges(false);
					this.soaService.setTriggerLeaveSoa(null);
				}),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.resetLeaveSoa();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
