import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, iif, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { appCashDepositMergeTag } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/cash-deposit/cash-deposit.merge-tag';
import { appDocsMergeTag } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/documents/documents.merge-tags';
import { appFundingRequiredMergeTag } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/funding-required/funding-required.merge-tags';
import { appNotesMergeTag } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/notes/app-notes.merge-tags';
import { appSecurityMergeTag } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/security/security.merge-tags';
import { UserQuery } from '../../../domain/user/user.query';
import {
	contentEditable,
	getContentWithMergeTags,
	removeEmptyParagraphs,
} from '../../../shared/converter/content-merge-tags';
import { reviewApplicationMergeTag } from '../../../shared/models/client-review-template/merge-tags/crt-mortgage/review-application/review-application.merge-tag';
import { MergeTagsMapper } from '../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from '../../../shared/wysiwyg/wysiwyg.component';
import { convertUtil } from '../../../util/util';
import { MergeTagsCrtSettingsService } from '../merge-tags/state/merge-tags-crt-settings.service';
import { SettingsTypes } from '../state/mortgage-settings.model';
import { ReviewApplicationEmailSettingsService } from './review-application-email-settings/state/review-application-email-settings.service';
import { ReviewApplicationSettingsMapper as reviewAppMapper } from './state/review-application-settings.mapper';
import { ReviewApplicationSettingsService } from './state/review-application-settings.service';
import { appLoanStructureMergeTag } from '@shared/models/client-review-template/merge-tags/crt-mortgage/application/loan-structure/loan-structure.merge-tags';

declare var $: any;

@Component({
	selector: 'app-review-application-settings',
	templateUrl: './review-application-settings.component.html',
	styleUrls: ['./review-application-settings.component.scss'],
})
export class ReviewApplicationSettingsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	isLoading = false;
	isPreview = false;
	isReload = true;
	customButtons = [];
	currentTemplate = '<p></p>';
	componentName = 'app-review-application-settings';
	undoReset$ = new BehaviorSubject<any>(null);
	undoStack$ = new BehaviorSubject<any>(null);

	tempData: string;
	templateId: number;
	shortcodes: object;
	data: object;
	template: string;
	mergeTag: MergeTagState[];
	undoStack = [];

	reviewApplicationSettings$ = this.reviewAppService.reviewApplicationSettings$;
	emailSettings$ = this.emailService.reviewApplicationEmailSettings$;
	mergeTags$ = this.mtService.mergeTags$;
	isTapLevel = this.userQuery.isTapLevel();

	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(
		private reviewAppService: ReviewApplicationSettingsService,
		private emailService: ReviewApplicationEmailSettingsService,
		private mtService: MergeTagsCrtSettingsService,
		private userQuery: UserQuery
	) {}

	ngOnInit(): void {
		this.mergeTags$
			.pipe(
				map((mt) =>
					MergeTagsMapper.mapMergeTags(
						[
							...mt,
							...reviewApplicationMergeTag,
							...appCashDepositMergeTag,
							...appFundingRequiredMergeTag,
							...appSecurityMergeTag,
							...appNotesMergeTag,
							...appDocsMergeTag,
							...appLoanStructureMergeTag,
						],
						true
					)
				),
				tap((x) => (this.mergeTag = x)),
				map((mt) => MergeTagsMapper.mapTagsForWysiwyg(mt)),
				tap((mt) => (this.shortcodes = mt)),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => this.backToEdit());
		this.prepData();
	}

	prepData() {
		combineLatest([
			this.reviewAppService.reviewApplicationSettings$,
			this.mtService.mergeTags$,
		])
			.pipe(
				filter(([settings, mergeTag]) => !!mergeTag && !!settings),
				tap(([reviewApp]) => {
					this.data = reviewApp;
					this.templateId = +reviewApp?.template;
				}),
				concatMap(([reviewApp]) =>
					this.reviewAppService.getDocumentFromURL(reviewApp?.templateLink)
				),
				tap((content) => {
					if (content) {
						this.currentTemplate = content;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	hasTemplate = (template) =>
		R.complement(R.either(R.isNil, R.isEmpty))(template) &&
		!isNaN(+template) &&
		+template > 0;

	getUndoStack(event) {
		this.undoStack = event;
	}

	save(event?) {
		this.isLoading = true;
		of(this.isReload)
			.pipe(
				concatMap((x) =>
					iif(() => x, this.saveReloadTemplate(x), this.saveTemplate())
				),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	saveReloadTemplate = (isReload) =>
		of(this.undoStack).pipe(
			concatMap((undoStack) =>
				iif(
					() => isReload && R.isEmpty(undoStack),
					this.reviewAppService
						.updateReviewApplicationSettings({
							template: null,
							referenceId: 0,
							type: SettingsTypes.ReviewApplication,
						})
						.pipe(
							finalize(() => {
								this.isReload = false;
								this.templateId = null;
							})
						),
					this.saveTemplate()
				)
			)
		);

	saveTemplate = () =>
		of(this.editor?.content).pipe(
			mergeMap((curTmpl) => convertUtil.convertToBase64(curTmpl)),
			withLatestFrom(of(this.templateId)),
			map(([curTmpl, tmplId]) =>
				reviewAppMapper.mapDocumentUpload(curTmpl, tmplId)
			),
			concatMap((data) =>
				iif(
					() =>
						R.isNil(data?.documentId) ||
						isNaN(data?.documentId) ||
						data?.documentId === 0,
					this.reviewAppService.newFileUpload(data),
					this.reviewAppService.updateFileUpload(data)
				)
			),
			tap((data) => {
				this.templateId = this.hasTemplate(this.templateId)
					? this.templateId
					: +data;
			}),
			withLatestFrom(of(this.templateId)),
			concatMap(([data, tmplId]) =>
				iif(
					() => this.hasTemplate(tmplId),
					of(tmplId),
					this.reviewAppService.updateReviewApplicationSettings({
						template: data,
						referenceId: 0,
						type: SettingsTypes.ReviewApplication,
					})
				)
			),
			finalize(() => (this.isLoading = false)),
			take(1)
		);

	reloadTemplate(event) {
		this.isLoading = true;
		this.reviewAppService
			.getDefaultTemplateUrl()
			.pipe(
				concatMap((url: string) =>
					this.reviewAppService.getDocumentFromURL(url)
				),
				finalize(() => {
					this.isLoading = false;
					this.isReload = true;
					this.templateId = null;
					this.undoReset$.next(event);
				}),
				take(1)
			)
			.subscribe((data) => {
				if (data) {
					const template = this.isTapLevel
						? data
						: `<div ${contentEditable.false}>${data}</div>`;
					this.currentTemplate = template;
					this.editor.content = template;
				}
			});
	}

	backToEdit() {
		this.currentTemplate = this.tempData;
		this.isPreview = false;
		$(
			`${this.componentName} .fr-element.fr-view, ${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'unset');
		$(`${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 1,
		});
	}

	showPreview() {
		this.isPreview = true;
		this.tempData = this.editor?.content;
		const getConvertedContent = getContentWithMergeTags(
			this.editor.content,
			this.mergeTag
		);
		$(
			`${this.componentName} .fr-element.fr-view, ${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'none');
		$(`${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 0.6,
		});
		this.currentTemplate = removeEmptyParagraphs(getConvertedContent);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
