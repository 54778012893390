import { StoreConfig, Store } from '@datorama/akita';
import { produce } from 'immer';
import { Injectable } from "@angular/core";
import { EBLROpportunity } from './eblr.model';

export interface EBLRState {
  EBLROpportunity: EBLROpportunity[] | null;
  error: string;
  isLoading: boolean;
}

export function CreateInitialState(): EBLRState {
  return {
    EBLROpportunity: null,
    error: '',
    isLoading: false
  };
}

@Injectable()
@StoreConfig({
  name: 'EBLROpportunity'
})
export class EBLRStore extends Store<EBLRState> {
  constructor() {
    super(CreateInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(
      produce(draft => {
        draft.isLoading = isLoading;
      })
    );
  }
}
