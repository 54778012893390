import * as R from 'ramda';
import { ProviderCommissionSettingState } from 'src/app/shared/models/provider-commission/provider-commission.model';
import {
	contentEditable,
	getContentWithMergeTags,
} from '../../../../../../shared/converter/content-merge-tags';
import { ProposedInsuranceState } from '../../../statement-of-advice/insurance/soa-insurance.model';
import { MergeTagsMapper } from '../../merge-tags.mapper';
import { MergeTagState } from '../../merge-tags.model';
import {
	lProvidersMetaKey,
	lrProvidersMergeTags,
	lrProvidersMt,
} from './lr-providers.merge-tag';

export class LrProvidersMergeTagsMapper {
	public static getTemplate(template: string) {
		return template?.replace(/\n|\t/g, '');
	}

	/**
	 * Get List of Providers based from Insurance
	 * @param insurance : Array of Proposed Insurance from SOA Tab
	 * @param providers : Array of L&R Providers from User Settings
	 * @returns : Array of Providers with each settings
	 */
	public static getListOfProviders(
		insurance: ProposedInsuranceState[] = [],
		providers: ProviderCommissionSettingState[] = []
	) {
		// Get List of Providers from Proposed Insurance
		let getProviders = insurance?.reduce((a, c) => {
			const list = c?.lifeAssured?.filter((x) =>
				['New', 'Replacement'].includes(x?.newExisting)
			);
			if (R.complement(R.either(R.isNil, R.isEmpty))(list)) {
				// If insurance has any New or Replacement on its Life Assured List
				return [...a, c?.provider];
			}
			return a;
		}, []);
		getProviders = R.uniq(getProviders || []);

		// Get Settings of Each L&R Provider from User > Settings > Provider & Commission
		let newList: any =
			getProviders?.map((x) => {
				const obj = providers?.find((i) => i.provider === x);
				if (R.complement(R.either(R.isNil, R.isEmpty))(obj)) {
					return obj;
				}
				return {
					provider: x || '',
					maxUpfront: null,
					maxRenewal: null,
				};
			}) || [];

		// Sort alphabetical
		newList = R.sortBy(R.compose(R.toLower, R.prop('provider')))(newList);
		return newList || [];
	}

	// GET L&R Provider (FE) Merge tags and Format Values
	public static getProviderData(providers: ProviderCommissionSettingState[] = []) {
		const getValue = (x) => {
			const val = MergeTagsMapper.getMtValue(providers, x?.value) || [];
			if (
				[lProvidersMetaKey.maxUpfront, lProvidersMetaKey.maxRenewal].includes(
					x?.metaKey
				)
			) {
				// Check if Max Upfront and Max Renewal are null, then set value as N/A
				return val?.map((i) => (R.either(R.isNil, R.isEmpty)(i) ? 'N/A' : i));
			}
			return val;
		};

		return (
			lrProvidersMt?.map((i) => ({
				...i,
				value: getValue(i),
			})) || []
		);
	}

	// Map (ALL) Merge Tags + FE L&R Merge Tags to HTML String Content
	public static getLrProviderMergeTags(
		providers: ProviderCommissionSettingState[],
		mergeTags: MergeTagState[]
	) {
		return lrProvidersMergeTags?.map((i) => {
			if (i?.metaKey === lProvidersMetaKey.sectionName) {
				return {
					...i,
					value: this.generateProviderSection(providers, mergeTags),
				};
			}
			return i;
		});
	}

	public static generateProviderSection(
		providers: ProviderCommissionSettingState[],
		mergeTags: MergeTagState[] = []
	) {
		const providerDataMergeTags = this.getProviderData(providers);
		const placeholder = `<div ${contentEditable.false} id="${lProvidersMetaKey.sectionName}"><p>&nbsp;</p></div>`;

		const template = this
			.getTemplate(`
			<div ${contentEditable.false} id="${lProvidersMetaKey.sectionName}">
			<p>[REPEAT_SECTION]</p>
			<p>%${lProvidersMetaKey.providersName}%</p>
			<p ${contentEditable.true}>%${lProvidersMetaKey.businessFapName}% may receive a commission of up to %${lProvidersMetaKey.maxUpfront}% of the first year’s premium from %${lProvidersMetaKey.providersName}% if you take out this policy (this is dependent on the product mix as some benefits attract a lesser commission rate). In addition, %${lProvidersMetaKey.businessFapName}% also receives a commission of up to %${lProvidersMetaKey.maxRenewal}% of the premium for each year the policy remains in force.</p>
			<p>[/REPEAT_SECTION]</p>
			</div>`);

		return providers?.length > 0
			? getContentWithMergeTags(template, [
					...mergeTags,
					...providerDataMergeTags,
			  ])
			: placeholder;
	}

	// Revert converted L&R Provider Section Tags
	public static revertProviderMergeTags(content: string) {
		// Convert back all dynamic LR Provider sections of SOA to Merge Tag Codes
		const newContent = this.getTemplate(content || '');

		// LR Providers Section
		return MergeTagsMapper.revertMergeTag(
			newContent,
			lProvidersMetaKey.sectionName
		);
	}
}
