

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessPageComponent } from './business-page.component';
import { BusinessPageResolver } from './business-page.resolver';

const routes: Routes = [
  {
    path: '',
    resolve: { data: BusinessPageResolver },
    component: BusinessPageComponent,
    pathMatch: 'full',
    data: { isTapLevelView: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [BusinessPageResolver]
})
export class BusinessPageRoutingModule { }
