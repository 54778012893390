import { DisclosureDocumentState, DisclosureSettingsState } from './disclosure-document.model';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import MomentUtil from '../../../../util/moment.util';
import { AdviceProcessSectionCodes } from '../../advice-process/advice-process.model';
import { DefaultFileNames, DocumentTypes } from '../../documents/document.model';
import { EmailTypes, EmailBodyTypes } from '../../emails/crt/email.model';
import { ServicesCodes } from '../../services/services.model';

export class DisclosureDocumentMapper {
  public static mapMergeTags(data: MergeTagState[]) {
    return data?.map((item) =>
      item?.metaKey === 'DATE_TODAY'
        ? {
            ...item,
            value: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
            secondaryValue: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
          }
        : item
    );
  }

  public static mapEmailSettingsData(data: DisclosureDocumentState) {
    if (data) {
      const { template, mergeTag, ...others } = data;
      return others;
    }
    return null;
  }

  public static mapCRTPeopleToEmailDd(list = []) {
    if (!list) {
      return [];
    }
    const newList = list?.map((x) => {
      return {
        display: x.name,
        value: x.cRTId,
      };
    });
    newList.push({ display: 'Other', value: 'Other' });
    return newList;
  }

  public static mapDocumentUpload(document, docId, staffId?) {
    if (docId === 0 || !docId) {
      return {
        document,
        referenceId: +staffId || 0,
        fileName: DefaultFileNames.Disclosure,
        type: DocumentTypes.Disclosure,
      };
    }
    return {
      document,
      documentId: +docId,
    };
  }

  public static mapNewDocumentUpload(document, staffId?) {
    return {
      document,
      referenceId: +staffId || 0,
      fileName: DefaultFileNames.Disclosure,
      type: DocumentTypes.Disclosure,
    };
  }

  public static mapStaffEmailBody(document, docId, staffId) {
    if (docId === 0 || !docId) {
      return {
        document,
        referenceId: +staffId,
        fileName: DefaultFileNames.SettingsEmailBody,
        type: EmailBodyTypes.Disclosure,
      };
    }
    return {
      document,
      documentId: +docId,
    };
  }

  public static mapTemplateTypeToUpsert(data: DisclosureDocumentState) {
		return {
			template: data?.template || null,
			isEnableEmailOption: data?.isEnableEmailOption || false,
			sender: data?.sender || '',
			carbonCopy: data?.carbonCopy || '',
			blindCarbonCopy: data?.blindCarbonCopy || '',
			subject: data?.subject || '',
			templateID: data?.templateID || '',
			unsubscribeGroupID: data?.unsubscribeGroupID || null,
			settingsId: data?.settingsId || null,
			templateType: data?.templateType,
			uploadTemplate: data?.uploadTemplate,
			referenceId: data.referenceId || 0,
			mergeTag: data.mergeTag || [],
			type: EmailTypes.Disclosure,
		};
  }

  public static mapEmailSettingsToUpsert(data: DisclosureDocumentState) {
    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: EmailTypes.Disclosure,
    };
  }

  public static mapDocTemplateToUpsert(data: DisclosureDocumentState) {
    return {
      template: data?.template,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: EmailTypes.Disclosure,
    };
  }

  public static mapCRTDocumentUpload(data) {
    return {
      document: data?.document,
      referenceId: data?.referenceId,
      fileName: data?.fileName,
      type: DocumentTypes.Disclosure,
    };
  }

  public static mapCARDocumentUpload(data) {
    return {
      document: data?.document,
      referenceId: data?.referenceId,
      fileName: data?.fileName,
      type: DocumentTypes.CARDisclosure,
    };
  }

  public static mapApLrDocUpload(data) {
    return {
      document: data?.document,
      customerId: data?.customerId,
      fileName: data?.fileName,
      type: ServicesCodes.LR,
    };
  }

  public static mapCrtToUpsert(data) {
    return {
      ...data,
      sectionCode: AdviceProcessSectionCodes.Disclosure,
      status: 1,
    };
  }

  public static mapCARToUpsert(data) {
    return {
      ...data,
      sectionCode: AdviceProcessSectionCodes.Disclosure,
      status: 1,
    };
  }

  public static mapToUpsert(data: DisclosureSettingsState) {
		return {
			referenceId: 0,
			type: EmailTypes.Disclosure,
			disclosureType: !!data?.disclosureTypeFT ? 'FT' : 'UD',
			disclosure: data?.disclosure || '',
			documentID: data?.documentID || '',
		};
	}

  public static mapCRTDocumentUploadFile(data, id, base64) {
		return {
			type: DocumentTypes.DisclosureUpload,
			referenceId: id,
			document: base64,
			fileName: data?.name,
		};
  }
}
