import { SecondaryTrustState } from './secondary-trust.model';
import { util } from '../../../../core/util/util.service';

export class ClientProfileSecondaryTrustMapper {
	static mapToView(data: SecondaryTrustState): SecondaryTrustState {
		return {
			...data,
			fullTrustName:
				data.fullTrustName === null
					? []
					: util.toStringArray(data.fullTrustName as string),
			independentTrustName:
				data.independentTrustName === null
					? []
					: util.toStringArray(data.independentTrustName as string),
		};
	}

	static mapToUpsert(data: SecondaryTrustState): SecondaryTrustState {
		return {
			...data,
			fullTrustName:
				data.fullTrustName &&
				typeof data.fullTrustName === 'object' &&
				Array.isArray(data.fullTrustName)
					? data.fullTrustName.length > 0
						? JSON.stringify(data.fullTrustName)
						: null
					: null,
			independentTrustName:
				data.independentTrustName &&
				typeof data.independentTrustName === 'object' &&
				Array.isArray(data.independentTrustName)
					? data.independentTrustName.length > 0
						? JSON.stringify(data.independentTrustName)
						: null
					: null,
			isActive: 1,
		};
	}
}
