import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { BodyRow } from '../../shared/simple-table/simple-table.model';
import { DashboardQuery } from '../../state/dashboard.query';
import { map, filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AnnualApiStat } from './state/aas.model';
import { AnnualApiStatsQuery } from './state/aas.query';
import { AnnualApiStatsService } from './state/aas.service';
import { numUtil } from '../../../../../util/util';

/** Annual API Stats for calendar year */
@Component({
  selector: 'app-aas',
  templateUrl: './aas.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AasComponent implements OnInit, OnDestroy {
  public static widgetCode = 'aas';
  public static widgetName = 'Annual API Stats - CY';
  public static sizeX = 350;
  public static sizeY = 250;
  public static minSizeX = 350;
  public static minSizeY = 250;

  widgetCode = AasComponent.widgetCode;

  /**
   * Display name for Statuses.
   */
  private readonly propertyName = {
    Submitted: 'Submitted API',
    New: 'Issued API',
    Lost: 'Cancelled API',
    NetNew: 'Net API',
    CompletionRate: 'Completion Rate',
    APICompletionRate: 'API Completion Rate',
    APPCompletionRate: 'App Completion Rate'
  };

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * AAS Data
   */
  data$: Observable<AnnualApiStat> = this.query.annualApiStatData$;

  /**
   * data useable by view.
   * creates data that can be used by simple-table.
   * appends % or prepends $ signs on values.
   */
  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return Object.keys(data)?.map<BodyRow>(key => {
          const isPercent = ['APICompletionRate', 'APPCompletionRate']?.indexOf(key) !== -1;
          const label = this.propertyName[key];
          const value = isPercent ? numUtil.formatToPercent(data[key]) : numUtil.formatToCurrency(data[key]);

          return [{ text: label }, { text: value, class: 'col-4' }];
        });
      }
    })
  );
  constructor(
    private dashboardQuery: DashboardQuery,
    private query: AnnualApiStatsQuery,
    private service: AnnualApiStatsService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of annual api stats.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetAnnualApiStats(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
