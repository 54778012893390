<label
	class="position-relative radio-group-container d-flex align-items-center"
	*ngFor="let item of items; let index = index"
	for="{{ 'radio-group-item-' + name + index }}"
>
	<span>
		{{ item[displayProperty] }}
	</span>
	<input
		[disabled]="disabled"
		type="radio"
		name="{{'input-radio' + name}}"
		[value]="item[valueProperty]"
		[attr.checked]="item[valueProperty] == value ? true : null"
		id="{{ 'radio-group-item-' + name + index }}"
		(change)="onChange($event.target.value)"
	/>
	<span
			class="checkmark-container position-relative"
			[style.height.px]="radioHeight"
			[style.width.px]="radioWidth"
		>
		<div
				class="checkmark"
				[style.height.px]="radioHeight - 8"
				[style.width.px]="radioWidth - 8"
			></div>
	</span>
</label>
