import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import sort from 'fast-sort';

import { either, isNil, isEmpty } from 'ramda';
import { map } from 'rxjs/operators';
import { SOAState, StatementOfAdviceStore } from './statement-of-advice.store';

@Injectable({ providedIn: 'root' })
export class StatementOfAdviceQuery extends QueryEntity<SOAState> {
	soa$ = this.selectAll().pipe(
		map((x) => sort(x).desc((s) => s.createDateTime))
	);

	hasIncomplete$ = this.soa$.pipe(
		map(
			(x) =>
				x?.filter((s) => either(isNil, isEmpty)(s?.document?.referenceId))
					?.length > 0
		)
	);

	incompleteSOA$ = this.soa$.pipe(
		map(
			(x) =>
				x?.filter((s) =>
					either(isNil, isEmpty)(s?.document?.referenceId)
				) || []
		)
	);

	completedSOA$ = this.soa$.pipe(
		map((x) => x?.filter((s) => s?.currentPage === 'Completed'))
	);

	finalizedSOA$ = this.soa$.pipe(
		map((x) => x?.filter((s) => s?.currentPage === 'Finalized'))
	);

	soaCompletedAndFinalized$ = this.soa$.pipe(
		map((x) => x?.filter((i) => !!i?.document?.referenceId)),
		map((x) => (x ? sort(x)?.desc((s) => s?.createDateTime) : []))
	);

	soaCount$ = this.selectCount();
	isLoading$ = this.selectLoading();

	soaSOS$ = this.select((x) => x?.scopeOfService);
	soaCI$ = this.select((x) => x?.currentInsurance);
	soaPI$ = this.select((x) => x?.proposedInsurance);

	isLoadingSOS$ = this.select((x) => x?.isLoadingSOS);
	isLoadingCI$ = this.select((x) => x?.isLoadingCI);
	isLoadingPI$ = this.select((x) => x?.isLoadingPI);
	isLoadingSOAR$ = this.select((x) => x?.isLoadingSOAR);

	isSavingSOA$ = this.select((x) => x?.isSavingSOA);

	isSavingSOS$ = this.select((x) => x?.isSavingSOS);
	isSavingCI$ = this.select((x) => x?.isSavingCI);
	isSavingPI$ = this.select((x) => x?.isSavingPI);
	isSavingSOAReview$ = this.select((x) => x?.isSavingSOAReview);
	soaCurrentTab$ = this.select((x) => x?.soaCurrentTab);

	constructor(protected store: StatementOfAdviceStore) {
		super(store);
	}
}
