<ng-container *ngIf="(items$ | async).length">
  <div [ngClass]="{
      'btn-group': stylingMode === 'group'
    }"
    class="py-1 d-flex">
    <button
        *ngFor="let item of items$ | async"
        class="btn border w-100"
        [class.btn-light]="!selectedItems.has(item[valueProperty])"
        [class.btn-primary]="selectedItems.has(item[valueProperty])"
        [class.tap-bg-primary]="selectedItems.has(item[valueProperty])"
        [class.text-light]="selectedItems.has(item[valueProperty])"
        [class.text-dark]="!selectedItems.has(item[valueProperty])"
				[attr.style]="btnStyle"
        (click)="toggle(item)"
        [attr.disabled]="disabled ? '' : null"
      >
      {{ item[displayProperty ]}}
    </button>
  </div>
</ng-container>
