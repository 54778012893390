import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
	MOATProcessRoutes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
	ServiceAdviceProcessState,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { PrimaryCustomerCompanyState } from 'src/app/shared/models/business-profile/business/business.model';
import { PrimaryClientState } from 'src/app/shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from 'src/app/shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from 'src/app/shared/models/client-profile/seondary-business/secondary-business.model';
import { CompanyState } from 'src/app/shared/models/client-review-template/company/company.model';
import { DependentState } from 'src/app/shared/models/client-review-template/dependent/dependent.model';
import { IntroductionState } from 'src/app/shared/models/client-review-template/intro/intro.model';
import { ScopeOfServiceState } from '../scope-of-services/state/scope-of-service.model';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import { TrustState } from 'src/app/shared/models/client-review-template/trust/trust.model';
import { Sidebar } from '../../_shared/models/sidebar.model';
import {
	DisclosureDocumentState,
	DisclosureState,
} from '../disclosure/state/disclosure.model';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import {
	FactFindComputationState,
	IncomeSourceState,
	MonthlyExpenseDetailsState,
	OtherIncomeObjState,
	PreviousIncomeSourceState,
	RentalIncomeObjState,
} from '../../_shared/models/income.model';
import { ReviewApplicationSettingsState } from '../../../../../modules/mortgage-settings/review-application-settings/state/review-application-settings.model';
import { SoaSettingsState } from '../../../../../modules/mortgage-settings/soa-settings/state/soa-settings.model';
import { DeclarationState } from '../declaration/state/declaration.model';
import { SosTemplateSettingsState } from 'src/app/modules/mortgage-settings/scope-of-service-settings/sos-template-settings/state/sos-template-settings.model';
import { AdviceProcessPageCodes } from '../../../../../shared/models/advice-process/advice-process.model';
import { SecondaryProfessionalState } from 'src/app/shared/models/client-profile/secondary-professional/secondary-professional.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';

export interface CrtMortgageState {
	primaryClient: PrimaryClientState | any;
	secondaryClients: SecondaryClientState[];
	secondaryTrusts: SecondaryTrustState[];
	secondaryCompanies: SecondaryBusinessState[];
	secondaryProfessionals: SecondaryProfessionalState[];
	linkedContacts: LinkedContactState[];

	sidebars: Sidebar[];

	adviceProcess: ServiceAdviceProcessState;
	adviceProcessId: number;
	sectionsLoading: boolean;
	clientId: number;
	isCompany: boolean;
	mortApPageCompleted: any[];
	mortApPageStarted: any[];

	intro: IntroductionState;
	scopeOfService: ScopeOfServiceState;
	sosDefault: ScopeOfServiceState;
	sosTemplateSettings: SosTemplateSettingsState;
	hasFormChanges: boolean;

	// Fact/find PEOPLE
	people: PeopleState[];
	dependents: DependentState[];
	trusts: TrustState[];
	company: CompanyState[];

	// People Loaders
	peopleIsLoading: boolean;
	dependantsIsLoading: boolean;
	trustsIsLoading: boolean;
	companyIsLoading: boolean;

	// Disclosure
	disclosureDocument: DisclosureState;
	disclosureSettings: DisclosureDocumentState;

	// Declaration
	declaration: DeclarationState;

	// Merge Tags
	mergeTags: MergeTagState[];

	// Fact find Income
	incomeSource: IncomeSourceState[];
	previousIncomeSource: PreviousIncomeSourceState[];
	totalPreviousIncome: number;
	rentalDetails: RentalIncomeObjState;
	otherIncomeDetails: OtherIncomeObjState;
	factFindComputation: FactFindComputationState;
	monthlyExpense: MonthlyExpenseDetailsState;

	reviewApplicationSettings: ReviewApplicationSettingsState;
	soaSettings: SoaSettingsState;
	triggerLeaveSoa: string;
	hasSoaChanges: boolean;
	triggerLeaveROA: string;
	roaCurrentTab: string;
	appCurrentTab: string;

	hasReviewApplicationChanges: boolean;
	reviewApplicationChangeValue: string;
	reviewApplicationShowModal: boolean;

	mortApIsLoading: boolean;
}

export function createInitialState(): CrtMortgageState {
	return {
		primaryClient: null,
		secondaryClients: null,
		secondaryTrusts: null,
		secondaryCompanies: null,
		secondaryProfessionals: null,
		linkedContacts: null,

		sidebars: [
			{
				id: MortgageAdviceProcessPageIds.Introduction,
				name: MortgageAdviceProcessPageNames.Introduction,
				link: MOATProcessRoutes.Introduction,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.Introduction,
			},
			{
				id: MortgageAdviceProcessPageIds.Disclosure,
				name: MortgageAdviceProcessPageNames.Disclosure,
				link: MOATProcessRoutes.Disclosure,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.Disclosure,
			},
			{
				id: MortgageAdviceProcessPageIds.ScopeOfService,
				name: MortgageAdviceProcessPageNames.ScopeOfService,
				link: MOATProcessRoutes.ScopeOfService,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.SOS,
			},
			{
				id: MortgageAdviceProcessPageIds.ClientSOP,
				name: MortgageAdviceProcessPageNames.FactFind,
				link: MOATProcessRoutes.ClientSOP,
				isSubMenu: true,
				subMenu: [
					{
						id: MortgageAdviceProcessPageIds.People,
						name: MortgageAdviceProcessPageNames.People,
						link: MOATProcessRoutes.People,
						isSubMenu: false,
						subMenu: [],
						customClass: '',
						status: 0,
						warning: '',
						pageCode: AdviceProcessPageCodes.People,
					},
					{
						id: MortgageAdviceProcessPageIds.AssetsLiabilities,
						name: MortgageAdviceProcessPageNames.AssetsLiabilities,
						link: MOATProcessRoutes.AssetsLiabilities,
						isSubMenu: false,
						subMenu: [],
						customClass: '',
						status: 0,
						warning: '',
						pageCode: AdviceProcessPageCodes.AssetsLiabilities,
					},
					{
						id: MortgageAdviceProcessPageIds.Income,
						name: MortgageAdviceProcessPageNames.Income,
						link: MOATProcessRoutes.Income,
						isSubMenu: false,
						subMenu: [],
						customClass: '',
						status: 0,
						warning: '',
						pageCode: AdviceProcessPageCodes.Income,
					},
					{
						id: MortgageAdviceProcessPageIds.Expenses,
						name: MortgageAdviceProcessPageNames.Expenses,
						link: MOATProcessRoutes.Expenses,
						isSubMenu: false,
						subMenu: [],
						customClass: '',
						status: 0,
						warning: '',
						pageCode: AdviceProcessPageCodes.Expenses,
					},
				],
				customClass: '',
				status: 0,
				warning: '',
			},
			{
				id: MortgageAdviceProcessPageIds.Declaration,
				name: MortgageAdviceProcessPageNames.Declaration,
				link: MOATProcessRoutes.Declaration,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.Declaration,
			},
			{
				id: MortgageAdviceProcessPageIds.Application,
				name: MortgageAdviceProcessPageNames.Application,
				link: MOATProcessRoutes.Application,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.Application,
			},
			{
				id: MortgageAdviceProcessPageIds.ROA,
				name: MortgageAdviceProcessPageNames.ROA,
				link: MOATProcessRoutes.ROA,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.SOA,
			},
		],

		adviceProcess: null,
		adviceProcessId: null,
		sectionsLoading: null,
		clientId: null,
		isCompany: null,
		mortApPageCompleted: null,
		mortApPageStarted: null,
		intro: null,
		scopeOfService: null,
		sosDefault: null,
		sosTemplateSettings: null,
		hasFormChanges: false,
		people: null,
		dependents: null,
		trusts: null,
		company: null,

		disclosureDocument: null,
		disclosureSettings: null,
		mergeTags: null,

		peopleIsLoading: false,
		dependantsIsLoading: false,
		trustsIsLoading: false,
		companyIsLoading: false,

		incomeSource: null,
		previousIncomeSource: null,
		totalPreviousIncome: 0,
		rentalDetails: null,
		otherIncomeDetails: null,
		monthlyExpense: null,
		factFindComputation: {},

		reviewApplicationSettings: null,
		soaSettings: null,

		declaration: null,
		triggerLeaveSoa: null,
		hasSoaChanges: false,
		triggerLeaveROA: null,
		roaCurrentTab: null,
		appCurrentTab: null,

		hasReviewApplicationChanges: false,
		reviewApplicationChangeValue: null,
		reviewApplicationShowModal: false,

		mortApIsLoading: false
	};
}

@Injectable()
@StoreConfig({ name: 'crt-mortgage' })
export class CrtMortgageStore extends Store<CrtMortgageState> {
	constructor() {
		super(createInitialState());
	}

	setAdviceProcess(p: ServiceAdviceProcessState) {
		this.update({ adviceProcess: p });
	}

	setAdviceProcessId(id: number) {
		this.update({ adviceProcessId: id });
	}

	setSectionsLoading(value: boolean) {
		this.update({ sectionsLoading: value });
	}

	setPrimaryClient(pci: PrimaryClientState | PrimaryCustomerCompanyState) {
		this.update({ primaryClient: pci });
	}

	setClientId(id: number) {
		this.update({ clientId: id });
	}

	setIsCompany(c: boolean) {
		this.update({ isCompany: c });
	}

	setSecondaryClients(c: SecondaryClientState[]) {
		this.update({ secondaryClients: c });
	}

	setSecondaryTrusts(c: SecondaryTrustState[]) {
		this.update({ secondaryTrusts: c });
	}

	setSecondaryCompanies(c: SecondaryBusinessState[]) {
		this.update({ secondaryCompanies: c });
	}

	setSecondaryProfessionals(c: SecondaryProfessionalState[]) {
		this.update({ secondaryProfessionals: c });
	}

	setLinkedContacts(c: LinkedContactState[]) {
		this.update({ linkedContacts: c });
	}

	setMortApPageCompleted(p: any[]) {
		this.update({ mortApPageCompleted: p });
	}

	setMortApPageStarted(p: any[]) {
		this.update({ mortApPageStarted: p });
	}

	setIntro(p: IntroductionState) {
		this.update({ intro: p });
	}

	setScopeOfService(p: ScopeOfServiceState) {
		this.update({ scopeOfService: p });
	}

	setSosDefault(p: ScopeOfServiceState) {
		this.update({ sosDefault: p });
	}

	setSosTemplateSettings(p: SosTemplateSettingsState) {
		this.update({ sosTemplateSettings: p });
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.update({ hasFormChanges });
	}

	setPeople(p: PeopleState[]) {
		this.update({ people: p });
	}

	setPeopleIsLoading(isLoading: boolean) {
		this.update({ peopleIsLoading: isLoading });
	}

	setDependents(p: DependentState[]) {
		this.update({ dependents: p });
	}

	setDependentsIsLoading(isLoading: boolean) {
		this.update({ dependantsIsLoading: isLoading });
	}

	setTrust(p: TrustState[]) {
		this.update({ trusts: p });
	}

	setTrustsIsLoading(isLoading: boolean) {
		this.update({ trustsIsLoading: isLoading });
	}

	setCompany(p: CompanyState[]) {
		this.update({ company: p });
	}

	setCompanyIsLoading(isLoading: boolean) {
		this.update({ companyIsLoading: isLoading });
	}

	setDisclosureDocument(p: DisclosureState) {
		this.update({ disclosureDocument: p });
	}

	setDisclosureSettings(p: DisclosureDocumentState) {
		this.update({ disclosureSettings: p });
	}

	setMergeTags(p: MergeTagState[]) {
		this.update({ mergeTags: p });
	}

	setIncomeSource(p: IncomeSourceState[]) {
		this.update({ incomeSource: p });
	}

	setPreviousIncomeSource(p: PreviousIncomeSourceState[]) {
		this.update({ previousIncomeSource: p });
	}

	setTotalPreviousIncomeSource(p: number) {
		this.update({ totalPreviousIncome: p });
	}

	setRentalIncome(p: RentalIncomeObjState) {
		this.update({ rentalDetails: p });
	}

	setOtherIncome(p: OtherIncomeObjState) {
		this.update({ otherIncomeDetails: p });
	}

	setMonthlyExpense(p: MonthlyExpenseDetailsState) {
		this.update({ monthlyExpense: p });
	}

	setFactFindComputation(p: FactFindComputationState) {
		this.update({ factFindComputation: p });
	}

	setReviewApplicationSettings(p: ReviewApplicationSettingsState) {
		this.update({ reviewApplicationSettings: p });
	}

	setSoaSettings(p: SoaSettingsState) {
		this.update({ soaSettings: p });
	}

	setTriggerLeaveSoa(p: string) {
		this.update({ triggerLeaveSoa: p });
	}

	setHasSoaChanges(p: boolean) {
		this.update({ hasSoaChanges: p });
	}

	setAppCurrentTab(p: string) {
		this.update({ appCurrentTab: p });
	}

	setTriggerLeaveROA(p: string) {
		this.update({ triggerLeaveROA: p });
	}

	setRoaCurrentTab(p: string) {
		this.update({ roaCurrentTab: p });
	}

	setHasReviewApplicationChanges(p: boolean) {
		this.update({ hasReviewApplicationChanges: p });
	}

	setReviewApplicationChangeValue(p: string) {
		this.update({ reviewApplicationChangeValue: p });
	}

	setReviewApplicationShowModal(p: boolean) {
		this.update({ reviewApplicationShowModal: p });
	}

	setMortApIsLoading(isLoading: boolean) {
		this.update({ mortApIsLoading: isLoading });
	}
}
