<div class="container-fluid tap-search-page">
	<div class="clearfix py-3">
		<app-activity-form-page (toggleSearch)="onToggleSearch($event)">
		</app-activity-form-page>

		<div class="clearfix">
			<div class="row">
				<div class="col p-0">
					<ngx-datatable
						#ngxdatatableActivity
						class="bootstrap"
						[style.height]="tblHeight"
						[ngClass]="{
							showSearchFields: showSearchFields,
							'disabled-table': isSearching$ | async,
							'disable-sort': (hasRow$ | async) !== true
						}"
						[columns]="columnsWithLink$ | async"
						[rows]="rows$ | async"
						[columnMode]="'standard'"
						[rowIdentity]="rowIdentity"
						[scrollbarH]="true"
						[scrollbarV]="true"
						[rowHeight]="40"
						[headerHeight]="32"
						[footerHeight]="false"
						[trackByProp]="'ActivityId'"
						(reorder)="reorder($event)"
						(resize)="resize($event)"
						[externalSorting]="true"
						sortType="single"
						(sort)="sort($event)"
						[rowClass]="getRowClass"
						[sorts]="sorts$ | async"
						(page)="onPage($event, ngxdatatableActivity.bodyComponent.indexes)"
					>
						<ngx-datatable-column
							*ngFor="let c of columns$ | async"
							[prop]="c.prop"
							[width]="c.width"
							[resizeable]="true"
							[name]="c.name"
							[cellClass]="c.cellClass"
							[draggable]="!(c.metakey === 'Client Name')"
							[frozenLeft]="c.metakey === 'Client Name'"
						>
							<ng-template
								ngx-datatable-header-template
								let-column="column"
								let-sort="sortFn"
							>
								<i
									class="material-icons draggable"
									[id]="c.columnId"
									[class.d-none]="c.metakey === 'Client Name'"
									>drag_indicator</i
								>
								<span class="datatable-header-cell-wrapper" (click)="sort()">
									<span class="datatable-header-cell-label">{{
										column.name
									}}</span>
								</span>
							</ng-template>
							<ng-template
								ngx-datatable-cell-template
								let-row="row"
								let-value="value"
								let-rowIndex="rowIndex"
							>
								<ng-container [ngSwitch]="c.controlType">
									<app-display
										*ngSwitchCase="'display'"
										[route]="route(row)"
										[value]="
											c.metakey === 'Client Name' &&
											(!value.value || value.value === ' ')
												? 'User Activity'
												: value.value
										"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>
									<app-display
										*ngSwitchCase="'display-date'"
										[value]="value.value | momentDatetime"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>
									<app-long-display
										*ngSwitchCase="'long-display'"
										[value]="value.value"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									></app-long-display>
									<app-date
										*ngSwitchCase="'date'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[cellClass]="getCellClass(row)"
										[isLoading]="
											(cellsLoading$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.ActivityId + '-' + c.metakey
											]
										"
										[isEditable]="
											value.editable &&
											row.ActivityStatus.value === activityStatuses.Pending
										"
										[restrict]="value.restrict"
										(editEvent)="edit(row.ActivityId, c.metakey)"
										(cancelEvent)="cancel(row.ActivityId, c.metakey)"
										(saveEvent)="saveField(row.ActivityId, c.metakey, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.ActivityId, c.metakey, $event)
										"
									></app-date>
									<app-dropdown
										*ngSwitchCase="'dropdown'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.ActivityId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="
											value.editable &&
											row.ActivityStatus.value === activityStatuses.Pending
										"
										[choices]="c.choices"
										[choicesObject]="c.choicesAsObject"
										
										[hasSort]="
											c.metakey === 'Assigned To' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser'
										"
										[allChoices]="
											c.metakey === 'Assigned To' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser'
												? (completeAdvisers$ | async)
												: undefined
										"
										[allChoicesObject]="
											c.metakey === 'Adviser' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser'
												? (adviserChoicesAsObject$ | async)
												: undefined
										"
										(editEvent)="edit(row.ActivityId, c.metakey)"
										(cancelEvent)="cancel(row.ActivityId, c.metakey)"
										(saveEvent)="saveField(row.ActivityId, c.metakey, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.ActivityId, c.metakey, $event)
										"
									></app-dropdown>
									<app-textbox
										*ngSwitchCase="'textbox'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.ActivityId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="
											value.editable &&
											row.ActivityStatus.value === activityStatuses.Pending
										"
										(editEvent)="edit(row.ActivityId, c.metakey)"
										(cancelEvent)="cancel(row.ActivityId, c.metakey)"
										(saveEvent)="saveField(row.ActivityId, c.metakey, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.ActivityId, c.metakey, $event)
										"
									></app-textbox>
									<app-textarea
										*ngSwitchCase="'textarea'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.ActivityId + '-' + c.metakey]
										"
										[isRequired]="c.isRequired"
										[isEditable]="
											value.editable &&
											row.ActivityStatus.value === activityStatuses.Pending
										"
										[fieldName]="c.name"
										(saveEvent)="saveField(row.ActivityId, c.metakey, $event)"
									></app-textarea>
								</ng-container>
							</ng-template>
						</ngx-datatable-column>

						<ngx-datatable-column
							[prop]="linkColumn.prop"
							[name]="linkColumn.name"
							[width]="linkColumn.width"
							[resizeable]="false"
							headerClass="link-header"
							[cellClass]="'link-ellipses'"
						>
							<ng-template
								ngx-datatable-cell-template
								let-value="value"
								let-row="row"
								let-rowIndex="rowIndex"
							>
								<ng-container *ngIf="!(rowsLoading$ | async)[row.ActivityId]">
									<ng-container
										*ngIf="
											row.ActivityStatus.value === activityStatuses.Pending
										"
									>
										<ng-container
											*appRestrictTo="['FCOA', 'FXMA', 'FEA', 'FDA']"
										>
											<div
												class="dropdown d-flex"
												dropdown
												container="body"
												placement="bottom right"
											>
												<button
													id="toggleMoreBtn_{{ rowIndex }}"
													class="tap-btn tap-btn-outline tap-text-primary px-0 py-0"
													dropdownToggle
												>
													<i
														class="material-icons icon-btn md-18 d-flex align-items-center px-0 py-0 w-auto"
													>
														more_vert
													</i>
												</button>
												<ul
													class="dropdown-menu dropdown-menu-right"
													*dropdownMenu
												>
													<a
														id="completeActivityBtn_{{ rowIndex }}"
														*appRestrictTo="['FCOA']"
														class="dropdown-item clickable tap-tex-primary"
														(click)="complete(row.ActivityId)"
													>
														Complete
													</a>
													<a
														id="cancelBtn_{{ rowIndex }}"
														*appRestrictTo="['FXMA']"
														class="dropdown-item clickable tap-tex-primary"
														(click)="confirmCancelActivity(row)"
													>
														Cancel
													</a>
													<a
														id="editBtn_{{ rowIndex }}"
														*appRestrictTo="['FEA']"
														class="dropdown-item clickable tap-tex-primary"
														(click)="editActivityModal(row.ActivityId)"
													>
														Edit
													</a>
													<a
														id="deleteBtn_{{ rowIndex }}"
														*appRestrictTo="['FDA']"
														class="dropdown-item clickable tap-tex-primary"
														(click)="confirmDeleteActivity(row)"
													>
														Delete
													</a>
												</ul>
											</div>
										</ng-container>
									</ng-container>
									<ng-container
										*ngIf="
											row.ActivityStatus.value !== activityStatuses.Pending
										"
									>
										<ng-container *appRestrictTo="['FDA']">
											<div
												class="dropdown d-flex"
												dropdown
												container="body"
												placement="bottom right"
											>
												<button
													id="toggleMoreBtn_{{ rowIndex }}"
													class="tap-btn tap-btn-outline tap-text-primary px-0 py-0"
													dropdownToggle
												>
													<i
														class="material-icons icon-btn md-18 d-flex align-items-center px-0 py-0 w-auto"
													>
														more_vert
													</i>
												</button>
												<ul
													class="dropdown-menu dropdown-menu dropdown-menu-right"
													*dropdownMenu
												>
													<a
														id="deleteBtn_{{ rowIndex }}"
														class="dropdown-item clickable tap-tex-primary"
														(click)="confirmDeleteActivity(row)"
													>
														Delete
													</a>
												</ul>
											</div>
										</ng-container>
									</ng-container>
								</ng-container>
								<app-manual-loader-mini
									class="d-flex clearfix align-items-center justify-content-center mt-2"
									*ngIf="(rowsLoading$ | async)[row.ActivityId]"
									[isLoading]="(rowsLoading$ | async)[row.ActivityId]"
								>
								</app-manual-loader-mini>
							</ng-template>
						</ngx-datatable-column>
					</ngx-datatable>
				</div>
			</div>
		</div>
	</div>
</div>
