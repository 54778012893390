import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrtPageGuard } from './crt-page.guard';
import { moatCanActivateFn } from './moat/moat.guard';
import { AdviceProcessOnlineRoutes } from '@shared/models/advice-process/advice-process.model';

const routes: Routes = [
	{
		path: 'mortgage',
		canActivate: [moatCanActivateFn, CrtPageGuard],
		data: {
			breadcrumb: 'CRT - Mortgage',
			routeVersion: 'v1'
		},
		loadChildren: () =>
			import('../crt-page/crt-mortgage/crt-mortgage.module').then(
				(m) => m.CrtMortgageModule
			),
	},
	{
		path: AdviceProcessOnlineRoutes.MortgageV2,
		canActivate: [moatCanActivateFn, CrtPageGuard],
		data: {
			breadcrumb: 'CRT - Mortgage',
			routeVersion: 'v2'
		},
		loadChildren: () =>
			import('@crt-moat/moat.module').then(
				(m) => m.MoatModule
			),
	},
	{
		path: 'kiwisaver',
		canActivate: [CrtPageGuard],
		data: {
			breadcrumb: 'CRT - KiwiSaver',
		},
		loadChildren: () =>
			import('../crt-page/crt-kiwisaver/crt-kiwisaver.module').then(
				(m) => m.CrtKiwiSaverModule
			),
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CrtPageRoutingModule {}
