import { MergeTagsMapper } from '../../merge-tags.mapper';
import { numUtil } from '../../../../../../util/util';
import {
	reviewApplicationMergeTag,
	reviewApplicationMetaKey,
} from './review-application.merge-tag';
import { MergeTagState, MergeTagTypeCode } from '../../merge-tags.model';
import { PeopleState } from '../../../people/people.model';
import * as R from 'ramda';
import {
	contentEditable,
	placeholders,
} from 'src/app/shared/converter/content-merge-tags';

export class ReviewAppMergeTagsMapper {
	public static formatCurrency(value) {
		return numUtil.isNumber(+value)
			? numUtil.formatWholeNumNoDecimal(+value)
			: '$0';
	}

	public static peoplePrimaryIncome(mergeTags: MergeTagState[]) {
		const people =
			mergeTags?.find(
				(x) =>
					x?.metaKey === reviewApplicationMetaKey.peopleReference?.toString()
			)?.value || [];

		return (
			mergeTags?.map((mt) => {
				if (
					mt?.metaKey?.includes(
						reviewApplicationMetaKey.peoplePrimaryIncome?.toString()
					)
				) {
					return {
						...mt,
						type: MergeTagTypeCode.text,
						value: MergeTagsMapper.getReferenceValueAsText(mt, people),
					};
				}
				return mt;
			}) || []
		);
	}

	public static getMergeTagValues(
		mergeTags: MergeTagState[],
		rawMergeTags: MergeTagState[],
		people: PeopleState[]
	) {
		const peopleFirstNames =
			mergeTags?.find(
				(i) => i?.metaKey === reviewApplicationMetaKey.peopleFirstName
			)?.value || [];
		const peopleGender =
			mergeTags?.find(
				(i) => i?.metaKey === reviewApplicationMetaKey.peopleGender
			)?.value || [];
		const peopleList = R.sort((a, b) => a?.cRTId - b?.cRTId, people);

		// Takes the liabilities amount
		const liabilityRepaymentMergeTags = mergeTags.find(
			(mt) => mt.metaKey === reviewApplicationMetaKey.liabilityRepayment
		);
		let totalAnnualLiabilityRepayment = 0;
		if (liabilityRepaymentMergeTags) {
			const values = liabilityRepaymentMergeTags.value?.map((val) =>
				parseFloat(val.replace(/[^\d.-]/g, ''))
			);
			totalAnnualLiabilityRepayment = values?.reduce((a, b) => a + b, 0);
		}

		return reviewApplicationMergeTag?.map((x) => {
			let value = '';
			if (x?.metaKey === reviewApplicationMetaKey.peopleFirstCombined) {
				value = peopleFirstNames?.join(' & ');
			}
			if (x?.metaKey === reviewApplicationMetaKey.peoplePossessivePronoun) {
				value = this.getPossessivePronoun(peopleGender);
			}
			if (x?.metaKey === reviewApplicationMetaKey.peoplePersonalPronoun) {
				value = this.getPersonalPronoun(peopleGender);
			}
			if (x?.metaKey === reviewApplicationMetaKey.peopleLinkingVerb) {
				value = this.getLinkingVerb(peopleGender);
			}
			if (x?.metaKey === reviewApplicationMetaKey.exitStrategyPeopleAge) {
				value = this.getPeopleAge(mergeTags);
			}
			if (x?.metaKey === reviewApplicationMetaKey.exitStrategyPeopleKiwi) {
				value = this.getPeopleKiwi(mergeTags, rawMergeTags, peopleList);
			}
			if (
				x?.metaKey === reviewApplicationMetaKey.totalAnnualLiabilityRepayment
			) {
				value = numUtil.formatToCurrency2(totalAnnualLiabilityRepayment);
			}

			return {
				...x,
				value,
			};
		});
	}

	public static getPeopleAge(mergeTags: MergeTagState[]) {
		const peopleName =
			mergeTags?.find(
				(i) => i?.metaKey === reviewApplicationMetaKey.peopleFirstName
			)?.value || [];
		const peopleAge =
			mergeTags?.find((i) => i?.metaKey === reviewApplicationMetaKey.peopleAge)
				?.value || [];

		const data = peopleName?.reduce((a, c, i) => {
			const value = `${c} is ${peopleAge[i] || ''}`;
			return [...a, value];
		}, []);
		return data?.join(' & ');
	}

	public static getPeopleKiwi(
		mergeTags: MergeTagState[],
		rawMergeTags: MergeTagState[],
		people: PeopleState[]
	) {
		const peopleName =
			mergeTags?.find(
				(i) => i?.metaKey === reviewApplicationMetaKey.peopleFirstName
			)?.value || [];
		const peopleKiwi =
			rawMergeTags?.find(
				(i) => i?.metaKey === reviewApplicationMetaKey.peopleIncomePrimaryKiwi
			)?.value || [];

		const data = people?.reduce((a, c, i) => {
			const kiwi = peopleKiwi?.find(
				(x) => +x?.referenceId === +c?.customerId
			)?.value;
			if (kiwi?.length > 0 && !!kiwi[0] && kiwi[0] !== '0%') {
				const value = `${peopleName[i]} contributes to KiwiSaver at ${kiwi[0]}`;
				return [...a, value];
			}
			return a;
		}, []);

		if (data?.length === 0) {
			data.push(
				`<em ${contentEditable.false} class="${placeholders.class}">No KiwiSaver Contrbution data to display</em>`
			);
		}

		return data?.join(' & ');
	}

	public static getPossessivePronoun(data) {
		// Possible Results: their/her/his
		if (data?.length > 1) {
			return 'their';
		} else {
			if (data[0] === 'Female') {
				return 'her';
			}
			if (data[0] === 'Male') {
				return 'his';
			}
			return 'their';
		}
	}

	public static getPersonalPronoun(data) {
		// Possible Results: they/she/he
		if (data?.length > 1) {
			return 'they';
		} else {
			if (data[0] === 'Female') {
				return 'she';
			}
			if (data[0] === 'Male') {
				return 'he';
			}
			return 'they';
		}
	}

	public static getLinkingVerb(data) {
		// Possible Results: are/is
		if (data?.length > 1) {
			return 'are';
		} else {
			if (data[0] === 'Female' || data[0] === 'Male') {
				return 'is';
			}
			return 'are';
		}
	}

	public static hideDollarSign(content: string) {
		const wrapId = 'hideDollarSign';

		// Create fragment from HTML String
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

		// Remove dollar signs on merge tags
		newHtml
			.querySelectorAll(`.mt-hide-dollar var`)
			.forEach((elem: HTMLElement) => {
				const text = elem?.innerHTML?.replace('$', '');
				elem.innerHTML = text || '';
			});

		return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
	}
}
