import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { viewSecGroup } from '../../viewmodels/viewSecGroup';
import { viewBusiness } from '../../viewmodels/viewBusiness';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TlStaffViewModel } from '../../viewmodels/tl-staff.viewmodel';
import { mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ActionLoaderService } from '../../../../core/loader/loader.service';
// For TL
@Component({
	selector: 'app-form-user',
	templateUrl: './form-user.component.html',
	styleUrls: ['./form-user.component.scss'],
	providers: [ActionLoaderService]
})
export class FormUserComponent implements OnInit {
	@Input()
	set staff(value: TlStaffViewModel) {
		this._data = value;
		this.form.reset(value);
	}
	@Input()
	set isView(value: boolean) {
		if (value) {
			this.form.disable();
		} else { this.form.enable(); }
	}
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private fb: UntypedFormBuilder
	) {
		this.buildForm();
	}

	get FirstName() {
		return this.form.get('FirstName');
	}
	get LastName() {
		return this.form.get('LastName');
	}
	get EmailAddress() {
		return this.form.get('EmailAddress');
	}
	get SecurityGroup() {
		return this.form.get('SecurityGroup');
	}
	get StartDate() {
		return this.form.get('StartDate');
	}
	get activeBusiness() {
		return this.businesses.filter(x => x.isActive === 1);
	}
	get inActiveBusiness() {
		return this.businesses.filter(x => x.isActive > 1);
	}
	isActiveOptions = [
		{ name: 'YES', option: 1 },
		{ name: 'REMOVED', option: 0 }
	];

	_data: TlStaffViewModel;
	form: UntypedFormGroup;
	showAllBusiness: boolean;

	@Input() secGroups: viewSecGroup[] = [];
	@Input() businesses: viewBusiness[] = [];

	@Input() btnText = 'Save';
	@Input() header = '';
	isSaving = false;
	@Output() cancelEvent = new EventEmitter();

	@Input()
	onSubmitClick: (data: TlStaffViewModel) => Observable<any> = data => of(data);
	submitBtnClick() {
		if (this.form.invalid) { return; }
		return new Observable<TlStaffViewModel>(obs => {
			this.removeSpaces();
			this.isSaving = true;
			obs.next(this.prepareData());
			obs.complete();
		})
			.pipe(mergeMap(x => this.onSubmitClick(x)))
			.subscribe(
				() => (this.isSaving = false),
				() => (this.isSaving = false),
				() => (this.isSaving = false)
			);
	}

	removeSpaces() {
		return this.form.value.email = (this.form.value.email || '').replace(/\s/g, '');
	}

	buildForm() {
		this.form = this.fb.group({
			StaffID: null,
			FirstName: ['', Validators.required],
			LastName: ['', Validators.required],
			IsActive: ['', Validators.required],
			EmailAddress: ['', Validators.required],
			SecurityGroup: ['', Validators.required],
			StartDate: ['', Validators.required],
			BirthDate: [''],
			MobilePhone: [''],
			BusinessPhone: [''],
			HomeAddress: [''],
			Businesses: [[]]
		});
	}

	prepareData(): TlStaffViewModel {
		return Object.assign((this._data || {}), this.form.value, {
			Businesses: this.form.controls.Businesses.value.filter(x => x)
		} as Pick<TlStaffViewModel, 'Businesses'>);
	}

	ngOnInit() { }

	hasBusiness(businessId: string | number): boolean {
		const id = businessId.toString();
		const businesses = (this.form.get('Businesses').value as string[]) || [];
		return businesses.some(x => x.toString() === id);
	}
	checkToggle(businessId: string | number, checked: boolean) {
		const id = businessId.toString();
		const businesses = (this.form.get('Businesses').value as string[]) || [];
		if (this.hasBusiness(id)) {
			if (!checked) {
				const newBusinesses = businesses.filter(x => x !== id);
				this.form.patchValue({ Businesses: newBusinesses });
			}
		} else {
			if (checked) {
				const newBusinesses = businesses;
				newBusinesses.push(id);
				this.form.patchValue({ Businesses: newBusinesses });
			}
		}
	}

	goBack() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}
	cancel() {
		this.cancelEvent.emit();
	}
	showAll() {
		this.showAllBusiness = !this.showAllBusiness;
	}
}
