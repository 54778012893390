export interface DocumentList {
	SettingsID: number;
	ReferenceID: number;
	Type: string;
	SortNo: number;
	IsDefault: boolean;
	IsMultiple: boolean;
	IsActive: boolean;
	IsEnable: boolean;
}

export interface DocumentListState {
	settingsId: number;
	referenceId: number;
	type: string;
	sortNo: number;
	isDefault: boolean;
	isMultiple: boolean;
	isActive: boolean;
	isEnable: boolean;
	documentName: string;
}

export enum DocumentListStatus {
	Inactive = 0,
	Checked = 1,
	Unchecked = 2,
}

export enum DocumentListType {
	Default = 'MGDLD',
	Custom = 'MGDL',
}
