import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from '../../../../core/base/api.service';
import { EmailBodyTypes } from '../../../../shared/models/emails/mortgage/email.model';
import {
  DocumentModelState,
  DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';

// @TODO USE THE QUERY AND STORE
import { MortgageSettingsQuery } from '../../state/mortgage-settings.query';
import { MortgageSettingsStore } from '../../state/mortgage-settings.store';
import { ScopeOfServiceEmailSettingsState } from './scope-of-service-settings.model';

@Injectable()
export class ScopeOfServiceSettingsService {

	// @TODO USE THE QUERY AND STORE
  emailSettings$ = this.query.scopeOfServiceEmailSettings$;

  constructor(
    private api: ApiService,
		// @TODO USE THE QUERY AND STORE
		  private store: MortgageSettingsStore,
    private query: MortgageSettingsQuery
  ) {}

  getEmailSettings(data: number) {
    const endpoint = `crt/settings/${data}/${EmailBodyTypes.SCOPE_OF_SERVICE}`;
    return this.api.get<ScopeOfServiceEmailSettingsState>(endpoint).pipe(
      tap((res) =>
        applyTransaction(() => {
          const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
					// @TODO USE THE QUERY AND STORE
					     this.store.setScopeOfServiceEmailSettings(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateEmailSettings(data: ScopeOfServiceEmailSettingsState) {
    const newValue = {
			// @TODO USE THE QUERY AND STORE
      // ...this.query.getValue().scopeOfServiceSettings,
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<ScopeOfServiceEmailSettingsState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
					// @TODO USE THE QUERY AND STORE
          this.store.setScopeOfServiceEmailSettings(newValue);
        })
      ),
      take(1)
    );
  }

	newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<DocumentUploadState>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req?.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<any>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
