<form [formGroup]="form" [class.submitted]="submitted" novalidate>
	<div class="form-row align-items-center mb-2">
		<div class="col-4 col-md-2 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Property Address
			</label>
		</div>
		<div class="col-8 col-md-10 col-lg-7 pr-md-0">
			<app-address-input
				inputId="propertyAddress"
				class="crt-form-control-field"
				formControlName="propertyAddress"
				textboxClass="theme-crm-field-disabled"
				tabindex="19"
			>
			</app-address-input>
		</div>
	</div>
	<div class="form-row mb-2">
		<div class="col-4 col-md-2 col-lg-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Owner(s)
			</label>
		</div>
		<div class="col-8 col-md-10 col-lg-7 pr-md-0 mb-2">
			<app-chips
				id="owners"
				textboxId="owners_tb"
				class="crt-form-control-field"
				textboxClass="theme-crm-field"
				formControlName="propertyOwner"
        [choices]="policyOwnerChoices(form.get('propertyOwner').value)"
			>
			</app-chips>
		</div>
		<div class="col-12 col-md-6 col-lg-5">
			<div class="form-row align-items-center mb-2">
				<div class="col-4 col-lg-5 text-left mb-2">
					<label for="" class="position-relative font-weight-bold"
						>Property Use</label
					>
				</div>
				<div class="col-8 col-lg-7 mb-2 pl-0">
					<span class="crt-custom-dropdown w-100">
						<select
							#propertyUseField
							id="propertyUse"
							formControlName="propertyUse"
							class="form-control theme-crm-field-disabled crt-form-control"
							(change)="onChangePropertyUse(propertyUseField.value)"
						>
							<option value=""></option>
							<option *ngFor="let d of propertyUse" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-4 col-lg-5 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Property Value
					</label>
				</div>
				<div class="col-8 col-lg-7 pl-0 mb-2">
					<div class="dollar-icon">
						<input
							id="propertyValue"
							formControlName="propertyValue"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-4 col-lg-5 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">Title</label>
				</div>
				<div class="col-8 col-lg-7 pl-0 mb-2">
					<span class="crt-custom-dropdown w-100">
						<select
							id="title"
							formControlName="title"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of title" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-4 col-lg-5 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Type
					</label>
				</div>
				<div class="col-8 col-lg-7 pl-0 mb-2">
					<span class="crt-custom-dropdown w-100">
						<select
							id="type"
							formControlName="type"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of type" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-6 col-lg-7">
			<div class="form-row align-items-center mb-2">
				<div class="col-4 col-md-4 col-lg-7 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Property Settlement Date
					</label>
				</div>
				<div class="col-md-8 col-lg-5 mb-2 pl-0">
					<app-date-input
						textboxId="purchaseDate"
						class="crt-form-control-field"
						formControlName="purchaseDate"
						placeholder="dd/mm/yyyy"
					></app-date-input>
				</div>
				<div class="col-4 col-md-4 col-lg-3 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Valuation Type
					</label>
				</div>
				<div class="col-8 col-lg-4 mb-2 pl-0">
					<span class="crt-custom-dropdown w-100">
						<select
							id="securityValuation"
							formControlName="valuationType"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of securityValuation" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-8 offset-4 col-lg-5 offset-lg-0 mb-2 pl-0">
					<app-date-input
						textboxId="securityValuationDate"
						class="crt-form-control-field"
						formControlName="valuationTypeDate"
						placeholder="dd/mm/yyyy"
					></app-date-input>
				</div>
				<div class="col-4 col-md-4 col-lg-3 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Rental Income
					</label>
				</div>
				<div class="col-8 col-lg-4 mb-2 pl-0">
					<div class="dollar-icon">
						<input
							id="rentalIncome"
							formControlName="rentalIncome"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-8 offset-4 col-lg-5 offset-lg-0 mb-2 pl-0">
					<span class="crt-custom-dropdown w-100">
						<select
							id="rentalIncomeFrequency"
							formControlName="rentalIncomeFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option
								*ngFor="let d of rentalFrequencyChoices"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<ng-container *ngIf="PropertyUse.value === 'Owner Occupied'">
					<div class="col-4 col-md-4 col-lg-3 text-left mb-2">
						<label for="" class="position-relative font-weight-bold">
							Boarder Income
						</label>
					</div>
					<div class="col-8 col-lg-4 mb-2 pl-0">
						<div class="dollar-icon">
							<input
								id="boarderIncome"
								formControlName="boarderIncome"
								class="form-control crt-form-control"
								type="text"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
					<div class="col-8 offset-4 col-lg-5 offset-lg-0 mb-2 pl-0">
						<span class="crt-custom-dropdown w-100">
							<select
								id="boaderIncomeFrequency"
								formControlName="boarderIncomeFrequency"
								class="form-control theme-crm-field-disabled crt-form-control"
							>
								<option value=""></option>
								<option
									*ngFor="let d of rentalFrequencyChoices"
									[value]="d.value"
								>
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</ng-container>
				<div class="col-4 col-md-4 col-lg-3 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Rates
					</label>
				</div>
				<div class="col-8 col-lg-4 mb-2 pl-0 mb-2">
					<div class="dollar-icon">
						<input
							id="rates"
							formControlName="rates"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-8 offset-4 col-lg-5 offset-lg-0 mb-2 pl-0">
					<span class="crt-custom-dropdown w-100">
						<select
							id="ratesFrequency"
							formControlName="ratesFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of rateFrequencyChoices" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-4 col-md-4 col-lg-3 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Insurance
					</label>
				</div>
				<div class="col-8 col-lg-4 mb-2 pl-0">
					<div class="dollar-icon">
						<input
							id="insurance"
							formControlName="insurance"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-8 offset-4 col-lg-5 offset-lg-0 mb-2 pl-0">
					<span class="crt-custom-dropdown w-100">
						<select
							id="insuranceFrequency"
							formControlName="insuranceFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option
								*ngFor="let d of insuranceFrequencyChoices"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-4 col-md-4 col-lg-3 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Other Expense
					</label>
				</div>
				<div class="col-8 col-lg-4 mb-2 pl-0">
					<div class="dollar-icon">
						<input
							id="otherExpense"
							formControlName="otherExpense"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-8 offset-4 col-lg-5 offset-lg-0 mb-2 pl-0">
					<span class="crt-custom-dropdown w-100">
						<select
							id="otherExpenseFrequency"
							formControlName="otherExpenseFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option
								*ngFor="let d of otherExpenseFrequencyChoices"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center mb-2 d-md-none">
		<div class="col-12">
			<div class="form-row align-items-center">
				<div class="col-4 col-md-2 col-lg-2 text-left mb-2 mb-lg-0">
					<label for="" class="position-relative font-weight-bold"
						>Property Use</label
					>
				</div>
				<div class="col-8 col-md-4 col-lg-3 mb-2 mb-lg-0">
					<span class="crt-custom-dropdown w-100">
						<select
							#propertyUseField
							id="propertyUse"
							formControlName="propertyUse"
							class="form-control theme-crm-field-disabled crt-form-control"
							(change)="onChangePropertyUse(propertyUseField.value)"
						>
							<option value=""></option>
							<option *ngFor="let d of propertyUse" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-4 col-md-2 col-lg-2 text-left mb-2 mb-lg-0">
					<label for="" class="position-relative font-weight-bold">
						Property Settlement Date
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-2">
					<app-date-input
						textboxId="purchaseDate"
						class="crt-form-control-field"
						formControlName="purchaseDate"
						placeholder="dd/mm/yyyy"
					></app-date-input>
				</div>
			</div>
			<div class="form-row align-items-center">
				<div class="col-4 col-md-2 col-lg-2 text-left">
					<label for="" class="position-relative font-weight-bold">
						Property Value
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-3 mb-2">
					<div class="dollar-icon">
						<input
							id="propertyValue"
							formControlName="propertyValue"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-4 col-md-2 col-lg-2 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Valuation Type
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-2 mb-2">
					<span class="crt-custom-dropdown w-100">
						<select
							id="securityValuation"
							formControlName="valuationType"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of securityValuation" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-8 offset-4 offset-md-8 offset-lg-0 col-md-4 col-lg-3">
					<app-date-input
						textboxId="securityValuationDate"
						class="crt-form-control-field"
						formControlName="valuationTypeDate"
						placeholder="dd/mm/yyyy"
					></app-date-input>
				</div>
			</div>
			<div class="form-row align-items-center mb-2">
				<div class="col-4 col-md-2 col-lg-2 text-left">
					<label for="" class="position-relative font-weight-bold">Title</label>
				</div>
				<div class="col-8 col-md-4 col-lg-3 mb-2">
					<span class="crt-custom-dropdown w-100">
						<select
							id="title"
							formControlName="title"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of title" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-4 col-md-2 col-lg-2 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Rental Income
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-2 mb-2">
					<div class="dollar-icon">
						<input
							id="rentalIncome"
							formControlrName="rentalIncome"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div
					class="col-8 offset-4 offset-md-8 offset-lg-0 col-md-4 col-lg-3 mb-2"
				>
					<span class="crt-custom-dropdown w-100">
						<select
							id="rentalIncomeFrequency"
							formControlName="rentalIncomeFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option
								*ngFor="let d of rentalFrequencyChoices"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
			</div>
			<ng-container *ngIf="PropertyUse.value === 'Owner Occupied'">
				<div class="form-row align-items-center mb-2">
					<div class="col-4 col-md-2 col-lg-2 text-left mb-2">
						<label for="" class="position-relative font-weight-bold">
							Boarder Income
						</label>
					</div>
					<div class="col-8 col-md-4 col-lg-2 mb-2">
						<div class="dollar-icon">
							<input
								id="boarderIncome"
								formControlrName="boarderIncome"
								class="form-control crt-form-control"
								type="text"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
					<div
						class="col-8 offset-4 offset-md-8 offset-lg-0 col-md-4 col-lg-3 mb-2"
					>
						<span class="crt-custom-dropdown w-100">
							<select
								id="boarderIncomeFrequency"
								formControlName="boarderIncomeFrequency"
								class="form-control theme-crm-field-disabled crt-form-control"
							>
								<option value=""></option>
								<option
									*ngFor="let d of rentalFrequencyChoices"
									[value]="d.value"
								>
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</ng-container>

			<div class="form-row align-items-center mb-2">
				<div class="col-4 col-md-2 col-lg-2 text-left mb-2">
					<label
						for=""
						class="position-relative crt-form-control font-weight-bold"
					>
						Type
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-3 mb-2">
					<span class="crt-custom-dropdown w-100">
						<select
							id="type"
							formControlName="type"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of type" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-4 col-md-2 col-lg-2 text-left mb-2">
					<label for="" class="position-relative font-weight-bold">
						Rates
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-2 mb-2">
					<div class="dollar-icon">
						<input
							id="rates"
							formControlName="rates"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-8 offset-4 offset-md-8 offset-lg-0 col-md-4 col-lg-3">
					<span class="crt-custom-dropdown w-100">
						<select
							id="ratesFrequency"
							formControlName="ratesFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of rateFrequencyChoices" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
			</div>
			<div class="form-row align-items-center mb-2">
				<div
					class="offset-0 offset-md-6 offset-lg-5 col-4 col-md-2 col-lg-2 text-left mb-2"
				>
					<label for="" class="position-relative font-weight-bold">
						Insurance
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-2 mb-2">
					<div class="dollar-icon">
						<input
							id="insurance"
							formControlName="insurance"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-8 offset-4 offset-md-8 offset-lg-0 col-md-4 col-lg-3">
					<span class="crt-custom-dropdown w-100">
						<select
							id="insuranceFrequency"
							formControlName="insuranceFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option
								*ngFor="let d of insuranceFrequencyChoices"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
			</div>
			<div class="form-row align-items-center mb-2">
				<div
					class="offset-0 offset-md-6 offset-lg-5 col-4 col-md-2 col-lg-2 text-left mb-2"
				>
					<label for="" class="position-relative font-weight-bold">
						Other Expense
					</label>
				</div>
				<div class="col-8 col-md-4 col-lg-2 mb-2">
					<div class="dollar-icon">
						<input
							id="otherExpense"
							formControlName="otherExpense"
							class="form-control crt-form-control"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-8 offset-4 offset-md-8 offset-lg-0 col-md-4 col-lg-3">
					<span class="crt-custom-dropdown w-100">
						<select
							id="otherExpenseFrequency"
							formControlName="otherExpenseFrequency"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option
								*ngFor="let d of otherExpenseFrequencyChoices"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="form-buttons">
		<button
			id="saveButton"
			type="button"
			class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
			[disabled]="isLoading || isLoadingBtn"
			(click)="saveBtnClick()"
		>
			Save
			<i *ngIf="isLoading || isLoadingBtn" class="fas fa-spinner fa-pulse"></i>
		</button>
		<button
			id="cancelButton"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancelClick()"
		>
			Cancel
		</button>
	</div>
</form>
