import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { combineLatest, EMPTY, iif, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ClientFactFindSettingState } from 'src/app/modules/mortgage-settings/declaration-settings/client-fact-find-template-settings/state/client-fact-find-template-settings.model';
import { SettingsTypes } from 'src/app/modules/mortgage-settings/state/mortgage-settings.model';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { ApiService } from '../../../../../../core/base/api.service';
import {
	AdviceProcessPageCodes,
	MoatPdfFooterCodes,
	MoatPdfHeaderCodes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessSectionCodes,
} from '../../../../../../shared/models/advice-process/advice-process.model';
import {
	DocumentModel,
	DocumentModelState,
	DocumentTypesMOAT,
	SignatureTypesMOAT,
} from '../../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../../util/util';
import { DeclarationSettingState } from '../../../../../mortgage-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.model';
import { Sidebar, SidebarStatus } from '../../../_shared/models/sidebar.model';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { DeclarationDocumentMapper } from './declaration.mapper';
import { DeclarationDocument, DeclarationState } from './declaration.model';
import { DeclarationQuery } from './declaration.query';
import { DeclarationStore } from './declaration.store';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { CrtMortgageStore } from '../../state/crt-mortgage.store';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { BusinessService } from '../../../../../../core/business/business.service';

export const declarationPdfOptions = {
	FileName: 'DECLARATION',
	DPI: '120',
};
export const MoatCffPdfOptions = {
	MarginTop: '2.5cm',
	MarginBottom: '1.6cm',
	MarginLeft: '0cm',
	MarginRight: '0cm',
	FooterHtmlType: MoatPdfFooterCodes.Default,
	HeaderHtmlType: MoatPdfHeaderCodes.Default
};

@Injectable()
export class DeclarationService extends CrtMortgageService {
	snapshotValue = this.dQuery.getValue();
	declaration$ = this.dQuery.declaration$;
	declarationFormValue$ = this.dQuery.declarationFormValue$;
	declarationSettings$ = this.dQuery.declarationSettings$;

	declarationEmailSettings$ = this.dQuery.declarationEmailSettings$;
	clientFactFindSettings$ = this.dQuery.clientFactFindTemplateSettings$;

	constructor(
		protected dStore: DeclarationStore,
		protected dQuery: DeclarationQuery,
		private loggerService: LoggerService,

		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	clearData() {
		applyTransaction(() => {
			this.dStore.reset();
		});
	}

	getDeclarationPdfOptions() {
		return declarationPdfOptions;
	}

	getDeclarationDocument(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.dStore.setDeclarationSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getDeclarationDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(
			`${url}?p=${Math.floor(Date.now() / 1000)}`,
			{ responseType: 'text' }
		);
	}

	getDeclaration(adviceProcessId) {
		const endpoint = `crt/${adviceProcessId}/${MortgageAdviceProcessSectionCodes.Declaration}`;
		return this.api.get<DeclarationState[]>(endpoint).pipe(
			tap((x) => {
				applyTransaction(() => {
					const state =
						!!x && x?.length > 0
							? objectUtil.mapPascalCaseToCamelCase(x[0])
							: {
									cRTId: 0,
									adviceProcessId: this.query.getValue().adviceProcessId,
									document: { referenceId: null, value: null },
									signatures: [],
							  };
					this.dStore.setDeclaration(state);
				});
			}),
			catchError(() => of([]))
		);
	}

	addDeclaration(declaration) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId: this.query.getValue().adviceProcessId,
			sectionCode: MortgageAdviceProcessSectionCodes.Declaration,
			signatures: declaration?.signatures?.map((x) =>
				objectUtil.mapCamelCaseToPascalCase(x)
			),
		});
		delete body.CRTId;
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.dStore.setDeclaration({ ...declaration, cRTId: +x });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	createDeclaration(declaration) {
		return this.addSignature(declaration.signature).pipe(
			mergeMap((documentId) => {
				const newDeclaration = DeclarationDocumentMapper.mapDeclarationToUpsert(
					declaration,
					this.query.getValue().adviceProcessId,
					documentId
				);
				return this.addDeclaration(newDeclaration);
			})
		);
	}

	updateDeclarationWithSignature(declaration) {
		return this.updateSignature(
			declaration.documentID,
			declaration.signature
		).pipe(
			mergeMap((response) => {
				const newDeclaration = DeclarationDocumentMapper.mapDeclarationToUpsert(
					declaration,
					this.query.getValue().adviceProcessId,
					declaration.documentID
				);
				return this.addDeclaration(newDeclaration);
			})
		);
	}

	updateDeclaration(declaration) {
		const endpoint = `crt/${declaration?.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId: this.query.getValue().adviceProcessId,
			sectionCode: MortgageAdviceProcessSectionCodes.Declaration,
			signatures: declaration?.signatures?.map((x) =>
				objectUtil.mapCamelCaseToPascalCase(x)
			),
		});
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.dStore.setDeclaration(objectUtil.mapPascalCaseToCamelCase(body));
				});
			}),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.oat.mortgage.declaration.error);
				return EMPTY;
			})
		);
	}

	getSignature(documentId) {
		const endpoint = `documents/${documentId}`;
		return this.api.get<DocumentModel>(endpoint).pipe(catchError(() => EMPTY));
	}

	addSignature(signature) {
		const endpoint = `documents`;
		const body = {
			ReferenceId: this.query.getValue().adviceProcessId,
			Document: signature?.split(',')[1],
			FileName: 'sig' + this.query.getValue().adviceProcessId + '.png',
			Type: SignatureTypesMOAT.Declaration,
		};
		return this.api.post<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	updateSignature(documentId, signature) {
		const endpoint = `documents/${documentId}/document-link`;
		const body = {
			Document: signature?.split(',')[1],
			DocumentID: documentId,
		};
		return this.api.put<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	updateDeclarationDocument(documentId, document) {
		const endpoint = `documents/${documentId}/document-link`;
		const body = {
			Document: document,
			DocumentID: documentId,
		};
		return this.api.put<any>(endpoint, body).pipe(catchError(() => EMPTY));
	}

	documentMapper(data) {
		const docs = data.Documents;
		docs?.forEach((doc) => {
			if (typeof doc.Value !== 'number') {
				doc.Value = doc.Value?.DocumentID;
			}
		});
		return docs;
	}

	sendEmail(data) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);
		const adviceProcessId = this.query.getValue().adviceProcessId;
		let dd: DeclarationDocument;
		return this.api
			.get<DeclarationDocument[]>(
				`crt/${adviceProcessId}/${MortgageAdviceProcessSectionCodes.Declaration}`
			)
			.pipe(
				map((x) => {
					const res = x?.map(
						(y) => objectUtil.mapPascalCaseToCamelCase(y) as DeclarationDocument
					);
					dd = res.length > 0 ? res[0] : null;
					return dd;
				}),
				// Update or Add Document for Disclosure Document
				mergeMap((x) =>
					iif(
						() => !!x && !!x?.documentID,
						// Update the current document
						this.api.put(`documents/${x?.documentID}/document-link`, {
							Document: d.Document,
							DocumentID: x?.documentID,
						}),
						// Upload new document
						this.api.post(`documents`, {
							ReferenceId: adviceProcessId,
							Document: d.Document,
							FileName: 'Declaration Document.pdf',
							Type: DocumentTypesMOAT.Declaration,
						})
					)
				),
				// Create New or update Declaration document
				mergeMap((x) =>
					iif(
						() => !!dd,
						// Update the current Declaration Document
						this.api.put(`crt/${dd?.cRTId}`, {
							AdviceProcessId: adviceProcessId,
							SectionCode: MortgageAdviceProcessSectionCodes.Declaration,
							DocumentID: dd?.documentID,
							ParentCRTId: 0,
							CRTId: dd?.cRTId,
						}),
						// Create new Declaration Document
						this.api.post(`crt`, {
							AdviceProcessId: adviceProcessId,
							SectionCode: MortgageAdviceProcessSectionCodes.Declaration,
							DocumentID: x,
							ParentCRTId: 0,
						})
					)
				),
				catchError(() => EMPTY)
			);
	}

	setDeclarationFormValue(d: DeclarationState) {
		const data = this.dQuery.getValue().declaration;
		applyTransaction(() => {
			this.dStore.setDeclarationFromValue({
				...data,
				...d,
			});
		});
	}

	getDeclarationEmailSettings() {
		// referenceId is always 0 because it comes from the settings
		const referenceId = 0;
		const endpoint = `crt/settings/${referenceId}/${SettingsTypes.MOATDeclarationEmail}`;
		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.dStore.setDeclarationEmailSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getClientFactFindSettings() {
		// referenceId is always 0 because it comes from the settings
		const referenceId = 0;
		const endpoint = `crt/settings/${referenceId}/${SettingsTypes.MOATClientFactFind}`;

		return this.api.get<ClientFactFindSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.dStore.setClientFactFindSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	setTabColor() {
		return combineLatest([
			this.query.adviceProcess$,
			this.declaration$,
			this.query.mortApPageStarted$,
			this.query.mortApPageCompleted$,
		]).pipe(
			tap(([ap, dc, pageStarted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (pageStarted?.includes(AdviceProcessPageCodes.Declaration)) {
					status = SidebarStatus.Incomplete;
					warning = 'Document must be saved to file';
				}

				if (
					ap?.documents?.some(
						(x) => x?.field === 'Fact Find & Declaration' && !!x?.value
					) ||
					(!!dc?.signatures &&
						dc?.signatures?.length > 0 &&
						dc?.signatures?.every((x) => !!x?.secondaryReferenceID))
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.Declaration,
					false,
					status,
					warning
				);
			})
		);
	}

	disableDeclaration(sidebar: Sidebar[]): boolean {
		const appIndex =
			sidebar?.findIndex(
				(x) => x?.id === MortgageAdviceProcessPageIds.Declaration
			) || 0;

		if (appIndex <= 0) {
			return false;
		}

		const disableDeclaration = [...sidebar]
			?.splice(0, appIndex)
			?.map((x) => x?.status === 2)
			?.includes(false);

		return disableDeclaration;
	}
}
