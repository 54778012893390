import { objectUtil } from '../../../../../../util/util';
import { AdviceProcessSectionCodes, MoatPdfFooterCodes, MoatPdfHeaderCodes } from '../../../../../../shared/models/advice-process/advice-process.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	tap,
} from 'rxjs/operators';
import { StructureSoa, StructureSoaEmailSettings } from './structure-soa.model';
import { StructureSoaQuery } from './structure-soa.query';
import { StructureSoaStore } from './structure-soa.store';
import { of, Observable, forkJoin } from 'rxjs';
import { SettingsTypes } from '../../../../../../modules/mortgage-settings/state/mortgage-settings.model';
import { MergeTagsService } from '../../state/merge-tags/merge-tags.service';
import { Sidebar } from '../../../_shared/models/sidebar.model';
import { ApplicationService } from '../../application/state/application.service';
import { ApplicationQuery } from '../../application/state/application.query';
import { LoanService } from '../loan/state/loan.service';
import { PurposeService } from '../../application/application-steps/purpose/state/purpose.service';

export const StructureSoaEmailOptions = {
  FileName: 'RECORD OF ADVICE',
  DPI: '120'
}
export const RoaPdfOptions = {
	MarginTop: '2.5cm',
	MarginBottom: '1.6cm',
	MarginLeft: '0cm',
	MarginRight: '0cm',
	FooterHtmlType: MoatPdfFooterCodes.Default,
	HeaderHtmlType: MoatPdfHeaderCodes.Default
};

@Injectable({ providedIn: 'root' })
export class StructureSoaService {
	isLoading$ = this.query.selectLoading();
	structureSOA$ = this.query.selectAll();
	hasStructureSOA$ = this.query
		.selectAll()
		.pipe(map((x) => !!x && x.length > 0));

  emailSettings$ = this.query.emailSettings$;
  statementOfAdviceEmailEnabled$ = this.query.statementOfAdviceEmailEnabled$;

	constructor(
		private store: StructureSoaStore,
		private query: StructureSoaQuery,
		private api: ApiService,
		private mtService: MergeTagsService,
		private applicationService: ApplicationService,
		private applicationQuery: ApplicationQuery,
		private loanService: LoanService,
		private purposeService: PurposeService
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(adviceProcessId?: number) {
		return this.api
			.get<StructureSoa[]>(
				`crt/${adviceProcessId}/${AdviceProcessSectionCodes.ROA}`
			)
			.pipe(
				// tap(() => this.store.setLoading(true)),
				map(
					(x) => x?.map(objectUtil.mapPascalCaseToCamelCase) as StructureSoa[]
				),
				tap((entities) => {
					this.store.set(entities);
				})
				// finalize(() => this.store.setLoading(false))
			);
	}

	getById(id: number) {
		return this.api.get<StructureSoa>(`crt/${id}`).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x) as StructureSoa),
			catchError(this.catchError)
		);
	}

	add(structureSoa: StructureSoa) {
		const body = {
			...structureSoa,
			sectionCode: AdviceProcessSectionCodes.ROA,
		};
		return this.api.post(`crt`, objectUtil.mapCamelCaseToPascalCase(body)).pipe(
			tap(() => this.store.setLoading(true)),
			switchMap((x) => this.getById(+x)),
			tap((x) => this.store.add(x)),
			finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	update(structureSoa: Partial<StructureSoa>) {
		const state = this.query.getEntity(+structureSoa?.cRTId);
		const body = {
			...state,
			currentPage: structureSoa?.currentPage || state?.currentPage,
			documentId:
				+structureSoa?.documentId > 0
					? +structureSoa?.documentId
					: state?.documentId,
			sectionCode: SettingsTypes.MOATDefaultTemplate,
		};

		return this.api
			.put(`crt/${body?.cRTId}`, objectUtil.mapCamelCaseToPascalCase(body))
			.pipe(
				// tap(() => this.store.setLoading(true)),
				// switchMap((x) => this.getById(structureSoa?.cRTId)),
				// finalize(() => this.store.setLoading(false)),
				tap(() => this.store.update(body?.cRTId, body)),
				catchError(this.catchError)
			);
	}

	delete(structure: StructureSoa | number) {
		this.store.setLoading(true);
		const cRTId = typeof structure === 'number' ? structure : structure?.cRTId;
		return this.api.delete<string>(`crt/${cRTId}`).pipe(
			tap(() => this.store.setLoading(true)),
			tap(() => this.store.remove(cRTId)),
			finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	updateDocumentId(cRTId: number, newDocumentId) {
		applyTransaction(() => {
			const state = this.query.getEntity(+cRTId);
			const data = {
				...state,
				documentId: newDocumentId || state?.documentId,
			};
			this.store.update(data?.cRTId, data);
		});
	}

	getLsSoaMergeTags(adviceProcessId?: number) {
		return of(true).pipe(
			concatMap(() => this.mtService.getSosMt(adviceProcessId)),
			concatMap(() => this.mtService.getFactFindMt(adviceProcessId)),
			concatMap(() => this.mtService.getLsSoaMt(adviceProcessId)),
			concatMap(() => this.mtService.getApplicationReasonMt(adviceProcessId)),
			catchError(this.catchError)
		);
	}

	getEmailSettings(adviceProcessId: number, settingsCode: string): Observable<StructureSoaEmailSettings> {
		const endpoint = `crt/settings/${adviceProcessId}/${settingsCode}`;
		return this.api.get<StructureSoaEmailSettings>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setEmailSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	disableROA(sidebar: Sidebar[]): boolean {
		const applications = this.applicationQuery.getAll();
		const isApplicationDisabled =
			this.applicationService.disableApplication(sidebar);

		return applications?.length === 0;
	}

	catchError = (x) => of(undefined);

	getRoaApprovedApplication(adviceProcessId: number) {
		return this.get(adviceProcessId).pipe(
			mergeMap((x) => {
				const parentCRTId = x?.[0]?.cRTId;
				if (parentCRTId) {
					return this.loanService.getLoanStructure(parentCRTId);
				}
				return of(null);
			}),
			mergeMap((x) => {
				const approvedApplication = x?.[0]?.approvedApplication;
				if (approvedApplication) {
					return forkJoin([
						this.applicationService.getById(approvedApplication),
						this.purposeService.get(approvedApplication)?.pipe(
							map((x) => x?.[0] || null)
						)
					]);
				}
				return of([null, null]);
			}),
			take(1)
		)
	}
}
