import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PropertyAssetCustomerServiceState } from 'src/app/shared/models/services/property-asset/property-asset';
import { Property, PropertyState } from './property.model';

export interface PropertyAddressState extends EntityState<PropertyState> {
	propertyAddresses: PropertyAssetCustomerServiceState[];
}

@Injectable()
@StoreConfig({ name: 'property', idKey: 'cRTId' })
export class PropertyStore extends EntityStore<PropertyAddressState, PropertyState> {
	constructor() {
		super({
			propertyAddresses: [],
		});
	}
}
