<div class="container-fluid px-0 servicing-integrated-templates">
	<form [formGroup]="form">
		<div
			class="d-none d-lg-flex servicing-integrated-templates__header form-row pt-1 pb-2"
		>
			<div
				class="col-lg-10 servicing-integrated-templates__header__template-name"
			>
				<span class="font-weight-bold">Template Name</span>
			</div>
			<div
				class="col-lg-2 text-center servicing-integrated-templates__header__actions"
			>
				<span class="font-weight-bold">Is Active</span>
			</div>
		</div>
		<div formArrayName="templateList">
			<div
				class="d-flex servicing-integrated-templates__content form-row pt-1 pb-1"
				*ngFor="let template of TemplateList.controls; let i = index"
				[formGroupName]="i"
			>
				<div
					class="col-12 col-lg-9 servicing-integrated-templates__content__template-name"
				>
					<label for="toggle_{{ i }}">{{
						template.get("documentName").value
					}}</label>
				</div>
				<div
					class="col-1 d-lg-flex d-inline-block text-right servicing-integrated-templates__content__loading"
				>
					<app-manual-loader-mini
						[isLoading]="template.get('isLoading').value"
					></app-manual-loader-mini>
				</div>
				<div
					class="col-auto col-lg-2 d-lg-flex d-inline-block text-center servicing-integrated-templates__content__actions action-switch"
					[class.disabled]="template.get('isLoading').value"
				>
					<app-switch
						[tempValue]="template.get('isActive').value"
						fieldId="toggle_{{ i }}"
						[isEditing]="true"
						[isEditable]="true"
						[hideControl]="true"
						[isRequired]="false"
						[restrict]="[]"
						[noRestriction]="true"
						(fieldUpdateEvent)="updateTick(i)"
					></app-switch>
				</div>
			</div>
		</div>
	</form>
</div>
