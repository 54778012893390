import { Directive, Input, AfterViewInit, ElementRef, OnDestroy, OnChanges, AfterContentInit, ApplicationRef, ComponentFactoryResolver, Injector, ComponentRef, HostListener, EmbeddedViewRef } from '@angular/core';
import { TooltipV2Component } from './tooltip-v2.component';
import { DomSanitizer } from '@angular/platform-browser';

/** 
 * tooltip directive v2 (not bootstrap) 
 */
@Directive({
  selector: '[appTooltipV2]',
  standalone: true
})
export class TooltipV2Directive implements OnDestroy {
  /** tooltip content */
  @Input('appTooltipV2') tooltip: string;
  private componentRef: ComponentRef<TooltipV2Component> | null = null;
  private showDelay = 0;
  private hideDelay = 0;
  private showTimeout?: number;
  private hideTimeout?: number;

  constructor(
    private el: ElementRef,
    private appRef: ApplicationRef, 
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private sanitaizer: DomSanitizer
  ) { }


  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (!this.el.nativeElement.classList.contains('menu-item-selected')) {
      this.initializeTooltip();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.setHideTooltipTimeout();
  }

  private initializeTooltip() {
    if (this.componentRef === null) {
      window.clearInterval(this.hideDelay);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipV2Component);
      this.componentRef = componentFactory.create(this.injector);

      this.appRef.attachView(this.componentRef.hostView);
      const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;

      this.setTooltipComponentProperties();

      document.body.appendChild(tooltipDOMElement);
      this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;

      const {left, right, top, height} = this.el.nativeElement.getBoundingClientRect() as DOMRect;

      this.componentRef.instance.left = Math.round((right - left) / 2 + left);
      this.componentRef.instance.top = Math.round(top) + height;

      // TODO: tooltip position
    }
  }

  private showTooltip() {
    if (this.componentRef !== null) {
      this.componentRef.instance.visible = true;
    }
  }

  private setHideTooltipTimeout() {
    this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      window.clearInterval(this.showTimeout);
      window.clearInterval(this.hideDelay);
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
