import { Component, Input } from '@angular/core';
import { SoaSettingsService } from '@modules/crt-settings/soa-settings/state/soa-settings.service';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { DefaultFileNames } from '@shared/models/documents/document.model';
import { EmailTypes } from '@shared/models/emails/crt/email.model';
import { convertUtil, objectUtil } from '@util/util';
import { isNil } from 'ramda';
import { Observable, combineLatest, iif, of } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ConfirmationCallEmailSettings } from '../states/confirmation-call-email-settings.model';
import { ConfirmationCallSettingsService } from '../states/confirmation-call-settings.service';
import { SettingsTypes } from '@modules/crt-settings/state/crt-settings.model';

@Component({
	selector: 'app-confirmation-call-email-setting',
	templateUrl: './confirmation-call-email-setting.component.html',
	styleUrls: ['./confirmation-call-email-setting.component.scss'],
})
export class ConfirmationCallEmailSettingComponent {
	@Input() staffId: number;
	@Input() emailSettings: ConfirmationCallEmailSettings;
	@Input() mergeTags$: Observable<MergeTagState[]> = of([]);

	emailSettigns$ = this.confirmationCallSettingsService.emailSettings$;
	isLoading = false;
	bodyContent = '<p></p>';
	bodyId: number;
	shortCodes: object;
	autoCCAdviser: boolean = true;

	constructor(
		private confirmationCallSettingsService: ConfirmationCallSettingsService
	) {}

	ngOnInit(): void {
		this.confirmationCallSettingsService
			.getEmailSettings(0, SettingsTypes.ConfirmationCall)
			.pipe(take(1))
			.subscribe();

			this.prepData();
	}

	prepData() {
		combineLatest([this.emailSettigns$, this.mergeTags$])
			.pipe(
				filter(([data, mt]) => !!data && !!mt),
				tap(([settings]) => {
					this.emailSettings = settings;
				}),
				tap(

					([data, mt]) =>
						(this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
				),
				tap(([data, mt]) => {
					this.bodyId = +data?.body < 1 ? 0 : +data?.body;
				}),
				map(([data, mt]) => this.mapEmailSettingsData(data)),
				mergeMap((data) =>
					iif(
						() => isNil(data?.body),
						of(null),
						this.confirmationCallSettingsService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.confirmationCallSettingsService.getDocumentFromURL(
							res?.documentLink
						),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save(emailSettings: ConfirmationCallEmailSettings): void {
		this.isLoading = true;
		of(emailSettings)
			.pipe(
				mergeMap((currentTemplate) => {
					return convertUtil.convertToBase64(currentTemplate?.bodyContent);
				}),
				withLatestFrom(of(this.bodyId)),
				map(([currentTemplate, bodyId]) =>
					this.mapStaffEmailBody(currentTemplate, bodyId, 0)
				),
				concatMap((data) => {
					return isNil(data.documentId) || data.documentId < 1
						? this.confirmationCallSettingsService
								.newFileUpload(data)
								.pipe(catchError((err) => of(err)))
						: this.confirmationCallSettingsService.updateFileUpload(data);
				}),
				withLatestFrom(of(this.bodyId)),
				concatMap(([data, body]) => {
					const isNewBody = body === 0 || !body;
					const newBodyId = isNewBody ? data : body;
					const formValue = this.mapEmailSettingsToUpsert({
						...emailSettings,
						referenceId: 0,
						body: newBodyId,
					});
					this.bodyId = newBodyId;
					return this.confirmationCallSettingsService.updateEmailSettings(
						formValue
					);
				}),
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe();
	}

  public mapEmailSettingsToUpsert(data: ConfirmationCallEmailSettings) {
    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: EmailTypes.ConfirmationCall
    };
  }

	public mapStaffEmailBody(document, docId, staffId) {
		if (docId === 0 || !docId) {
			return {
				document,
				referenceId: +staffId,
				fileName: DefaultFileNames.ConfirmationCall,
				type: EmailTypes.ConfirmationCall,
			};
		}
		return {
			document,
			documentId: +docId,
		};
	}

	public mapEmailSettingsData(data: ConfirmationCallEmailSettings) {
		if (data) {
			const { template, mergeTag, ...others } = data;
			return others;
		}
		return null;
	}
}
