<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Mobile App</h1>
	</div>

	<form [formGroup]="form">
		<div class="container-fluid px-0 pt-3">
			<div class="row tap-container">
				<div class="col-md-3 pr-md-0">
					<div class="form-group form-inline row mb-1">
						<div class="col-lg-4 pr-0">
							<label for="firstName" class="position-relative">
								iOS App Version
							</label>
						</div>
						<div class="col-lg-5">
							<input
									class="form-control ng-untouched ng-pristine ng-valid"
									type="text"
									id="iosVersion"
									formControlName="iosVersion"
								/>
						</div>
					</div>
				</div>
				</div>
				<div class="row">
					<ng-container>
						<div class="col-lg-5 mb-5 text-right">
							<ng-container *ngIf="!isSaving">
								<button id="btnMobileSave" #btnMobileSave class="btn btn-primary mr-2" (click)="submitBtnClick(btnMobileSave)">
									{{isEdit?'Save':'Edit'}}
								</button>
								<button id="btnMobileCancel" *ngIf="isEdit" class="btn btn-light" (click)="cancel()">Cancel</button>
							</ng-container>
							<app-manual-loader-mini
								[isLoading]="isSaving"
							></app-manual-loader-mini>
						</div>
					</ng-container>
				</div>
		</div>
	</form>

</div>
