import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { ConfirmationCallEmailSettings, ConfirmationCallSettings } from "./confirmation-call-email-settings.model";

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'ConfirmationCall',
  idKey: 'id',
})
export class ConfirmationCallStore extends Store<ConfirmationCallSettings> {
  constructor() {
    super({});
  }
	setEmailSettings(emailSettings: ConfirmationCallEmailSettings): void {
		this.update({ email: emailSettings });
	}
}
