import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, Optional, Renderer2 } from '@angular/core';
import { FormControlDirective, NgControl } from '@angular/forms';

// remove all format when pasting from an input
@Directive({
  selector: '[appClipboardNoFormat]'
})
export class ClipboardNoFormatDirective {

	@HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
		// @ts-ignore-next
		const clipboardText = (e.clipboardData || window.clipboardData).getData("text");
		const target = (e.target as HTMLInputElement);
		if (Boolean(clipboardText)) {
			const text = this.getPlainText(clipboardText);
			const carretPositionAfterPaste = target.selectionStart + text.length;
			const updatedText = this.replaceBetween(target.value, target.selectionStart, target.selectionEnd, text);
			target.value = updatedText;
			// set the carret position at the end of the pasted text
			target.setSelectionRange(carretPositionAfterPaste, carretPositionAfterPaste);
			this.formControl?.control?.setValue(target.value);
			e.preventDefault();
		}
	}

  constructor(
		@Inject(DOCUMENT) private doc: HTMLDocument,
		@Optional() private formControl?: NgControl
	) {
	}

	private replaceBetween(text: string, start: number, end: number, what: string) {
		return text.substring(0, start) + what + text.substring(end);
	};

	/*
		* remove all html tags in a string
	 */
	private getPlainText(text: string): string {
		const container = this.doc.createElement('div') as HTMLDivElement;
		container.innerHTML = text;
		return container.innerText?.trim() ?? '';
	}


}
