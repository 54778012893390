import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import {
  DocumentModelState,
  DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from '../../../../core/base/api.service';
import { CrtSettingsQuery } from '../../state/crt-settings.query';
import { CrtSettingsStore } from '../../state/crt-settings.store';
import { AuthorityToProceedState } from './authority-to-proceed-settings.model';

@Injectable()
export class AuthorityToProceedSettingsService {

  authorityToProceed$ = this.query.authorityToProceed$;

  constructor(
    private api: ApiService,
    private store: CrtSettingsStore,
    private query: CrtSettingsQuery
  ) {}

  getAuthorityToProceed(data: number, settingsCode: string) {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<AuthorityToProceedState>(endpoint).pipe(
      tap((res) =>
        applyTransaction(() => {
          const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
          this.store.setAuthorityToProceed(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateAuthorityToProceed(data: AuthorityToProceedState) {
    const newValue = {
      ...this.query.getValue().authorityToProceed,
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<AuthorityToProceedState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setAuthorityToProceed(newValue);
        })
      ),
      take(1)
    );
  }

  newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
