import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { uniq, omit, either, isNil, isEmpty } from 'ramda';
import {
	BehaviorSubject,
	EMPTY,
	from,
	iif,
	Observable,
	Observer,
	of,
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	combineLatest,
	concatMap,
	debounceTime,
	delay,
	filter,
	finalize,
	map,
	mapTo,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config/config.service';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { AuthorityToProceedSettingsService } from 'src/app/modules/crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.service';
import {
	filterNonEmailMergeTags,
	removeCustomNotes,
	removeHTMLContentWhiteSpaces,
	removeMtWrappers,
	removeSoaDivs,
} from 'src/app/shared/converter/content-merge-tags';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { AtpEmailModalComponent } from 'src/app/shared/modal/crt/email/atp-email-modal/atp-email-modal.component';
import { EmailModalComponent } from 'src/app/shared/modal/crt/email/email-modal.component';
import {
	UploadModalComponent,
	ALLOWED_DOCUMENT_FILE_TYPES,
} from 'src/app/shared/modal/upload-modal/upload-modal.component';
import {
	AdviceProcessDocumentField,
	AdviceProcessDocumentState,
	AdviceProcessPageCodes,
	AdviceProcessPageNamesByCode,
	AdviceProcessSectionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { ClientAcceptanceMapper } from 'src/app/shared/models/client-review-template/client-acceptance/client-acceptance.mapper';
import { CurrentInsuranceState } from 'src/app/shared/models/client-review-template/current-insurance/current-insurance.model';
import { DeclarationState } from 'src/app/shared/models/client-review-template/declaration/declaration.model';
import { RiskAnalysisLifeMapper } from 'src/app/shared/models/client-review-template/risk-analysis/life/life.mapper';
import { RiskAnalysisMedicalMapper } from 'src/app/shared/models/client-review-template/risk-analysis/medical/medical.mapper';
import { RiskAnalysisTpdMapper } from 'src/app/shared/models/client-review-template/risk-analysis/tpd/tpd.mapper';
import {
	DefaultFileNames,
	DocumentTypes,
} from 'src/app/shared/models/documents/document.model';
import { EmailTypes } from 'src/app/shared/models/emails/crt/email.model';
import MomentUtil from 'src/app/util/moment.util';
import { RouteService } from '../../../../core/config/route.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { FinalStructureDocumentMapper as FsDocMapper } from '../../../../modules/crt-settings/final-structure-settings/state/final-structure-settings.mapper';
import { CriticalIllnessMapper } from '../../../../shared/models/client-review-template/risk-analysis/critical-illness/critical-illness.mapper';
import { DisabilityMapper } from '../../../../shared/models/client-review-template/risk-analysis/disability/disability.mapper';
import { GoalsMapper } from '../../../../shared/models/client-review-template/risk-analysis/goals/goals.mapper';
import { RiskProfileMapper } from '../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.mapper';
import { ScopeOfServiceMapper as sosMapper } from '../../../../shared/models/client-review-template/scope-of-service/scope-of-service.mapper';
import { convertUtil, objectUtil, util } from '../../../../util/util';
import { documentStaticConf } from '../../client-review-template/util/document-static-config.service';
import { AssetsLiabilitiesService } from '../states/assets-liabilities/assets-liabilities.service';
import { ClientAcceptanceService } from '../states/client-acceptance/client-acceptance.service';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { ClientReviewTemplateService } from '../states/client-review-template.service';
import { CurrentInsuranceService } from '../states/current-insurance/current-insurance.service';
import { DeclarationService } from '../states/declaration/declaration.service';
import { DisclosureService } from '../states/disclosure/disclosure.service';
import { LoatDocumentService } from '../states/document/loat-document.service';
import { EmailService } from '../states/email/email.service';
import { FinalStructureService } from '../states/final-structure/final-structure.service';
import { IncomeService } from '../states/income-budget/income.service';
import { LrAdviceprocessService } from '../states/lr-adviceprocess/lr-adviceprocess.service';
import { CrtMergeTagsService } from '../states/merge-tags/crt-merge-tags.service';
import { PeopleService } from '../states/people/people.service';
import { CriticalIllnessService } from '../states/risk-analysis/critical-illness/critical-illness.service';
import { DisabilityQuery } from '../states/risk-analysis/disability/disability.query';
import { DisabilityService } from '../states/risk-analysis/disability/disability.service';
import { GoalsService } from '../states/risk-analysis/goals/goals.service';
import { LifeQuery } from '../states/risk-analysis/life/life.query';
import { LifeService } from '../states/risk-analysis/life/life.service';
import { MedicalQuery } from '../states/risk-analysis/medical/medical.query';
import { RiskAnalysisMedicalService } from '../states/risk-analysis/medical/medical.service';
import { RiskAnalysisService } from '../states/risk-analysis/risk-analysis.service';
import { RiskProfileService } from '../states/risk-analysis/risk-profile/risk-profile.service';
import { TpdQuery } from '../states/risk-analysis/tpd/tpd.query';
import { TpdService } from '../states/risk-analysis/tpd/tpd.service';
import { ScopeOfServiceService } from '../states/scope-of-service/scope-of-service.service';
import { StatementOfAdviceQuery } from '../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../states/statement-of-advice/statement-of-advice.service';
import {
	tableCiPolicyFeeAndPremium,
	tableExistingFinalStructureTemplate,
	tableFinalizedStructureTemplate,
	tableFsPolicyFeeAndPremium,
	tdExistingFinalStructureTemplate,
	tdFinalizedStructureTemplate,
} from '../util/dynamic-tables/email-document-template';
import { soaTemplate } from '../util/dynamic-tables/soa-document-template';
import {
	SignatureModel,
	signatureTemplate,
} from '../util/templates/signature-template';
import { GoalsState } from './../../../../shared/models/client-review-template/risk-analysis/goals/goals.model';
import { FinalStructureSettingsService } from './../../../crt-settings/final-structure-settings/state/final-structure-settings.service';
import { HistoryService } from '../states/history/history.service';
import { CrtNoteService } from '../states/note/crt-note.service';
import { CrtDocumentService } from '../../crt-page/_shared/service/crt-document.service';
import { ToPdfService } from 'src/app/shared/services/to-pdf/to-pdf.service';
import {
	Fields,
	getInvalidWarning,
	logMessage,
} from 'src/app/shared/error-message/error-message';
import { RiskProfileState } from 'src/app/shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.model';
import { DeclarationDocumentMapper } from '../states/declaration/declaration.mapper';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ExistingPolicyStructureQuery } from '../states/existing-policy-structure/existing-policy-structure.query';
import { environment as env } from '@environment';

const crtRoutes = {
	API: 'intro',
	APDD: 'disclosure',
	APSOS: 'scope-of-service',

	// Fact Find
	APP: 'fact-find/people',
	APAL: 'fact-find/assests-and-liabilities',
	APIB: 'fact-find/income-and-budget',
	APMH: 'fact-find/medical-history',
	APCI: 'fact-find/current-insurance',
	APFG: 'fact-find/fg-insurance',

	// Riks Analysis
	APG: 'risk-analysis/goals',
	APL: 'risk-analysis/life',
	APD: 'risk-analysis/disability',
	APTPD: 'risk-analysis/tpd',
	APCRI: 'risk-analysis/critical-illness',
	APM: 'risk-analysis/medical',
	APRP: 'risk-analysis/risk-profile',

	APDC: 'declaration',
	APSOA: 'statement-of-advice',
	APCA: 'client-acceptance',
	APFS: 'final-structure',
};
const defaultLogoUrl = `${env.defaultImgUrl}/logo.png`;
@Component({
	selector: 'app-client-review-template-progress',
	templateUrl: './client-review-template-progress.component.html',
	styleUrls: ['./client-review-template-progress.component.scss'],
})
export class ClientReviewTemplateProgressComponent
	implements OnInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	@Input() isTopControls: boolean;
	@Input() isCRTLoading: boolean;
	@Input() isCompany$: Observable<boolean>;
	@Output() tabSet = new EventEmitter<string>();
	@Output() crtLoad = new EventEmitter<boolean>();

	hasFSDocumentUploaded$: Observable<boolean> =
		this.fsService.finalStructure$.pipe(
			map((x) => !!x && !!x.document?.referenceId)
		);

	currentTab = new BehaviorSubject<string>('');
	currentTabTitle: string;
	monthlyExpense$ = this.incomeService.monthlyExpense$;
	goals$ = this.goalsService.goals$;
	criticalIllness$ = this.illnessService.criticalIllness$;
	riskProfile$ = this.riskProfileService.riskProfile$;
	riskTpd$ = this.tpdService.riskTpd$;

	adviceProcess = this.query.getValue().adviceProcess;
	document;

	isLoading = false;
	isSaving = false;
	isPause = false;
	isExit = false;

	peopleDropdown$ = this.crtQuery.peopleAndDependentsFromCRTOnly$.pipe(
		withLatestFrom(this.query.transferedSCIList$),
		map(
			([x, y]) =>
				x?.filter((x) => !y?.find((i) => +i?.cRTId === +x?.cRTId)) || []
		)
	);
	finalStructureSettings$ = this.fsCrtSettingsService.finalStructure$;
	fs$ = this.fsService.finalStructure$;
	mergeTags$ = this.crtMergeTagsService.mergeTags$;

	authorityToProceedSettings$ = this.apCrtSettingsService.authorityToProceed$;

	public bsModalRef: BsModalRef;

	crtRoutes: object;

	isSavingSOA$ = this.soaQuery.isSavingSOA$;
	isSavingPI$ = this.soaQuery.isSavingPI$;
	isSavingSOAReview$ = this.soaQuery.isSavingSOAReview$;
	isLoadingFinalStructure$ = this.crtQuery.isLoadingFinalStructure$;
	isUpdatingFinalStructure$ = this.crtQuery.isUpdatingFinalStructure$;
	isLoading$ = this.crtQuery.selectLoading();

	private isTapLevelView$ = this.configService.IsViewTapLevel;
	public logoUrl$ = this.businessConfigQuery.businessLogoUrl$.pipe(
		combineLatest(this.isTapLevelView$, of(defaultLogoUrl)),
		map(([url, isTapView, defaultUrl]) => {
			if (isTapView) {
				return defaultUrl;
			} else {
				return url;
			}
		})
	);

	hasActiveSOA$ = this.soaQuery.selectActive().pipe(map((x) => !!x));

	hasATP$ = this.query.clientAcceptance$.pipe(map((x) => !!x));

	hasATPSOASelected$ = this.caService.formValue$.pipe(
		map((x) =>
			!!x
				? !!x?.sOA && (x?.presentedSOA === 0 || x?.presentedSOA > 0)
				: !!this.query.getValue()?.clientAcceptance?.sOA &&
				  (this.query.getValue()?.clientAcceptance?.presentedSOA === 0 ||
						this.query.getValue()?.clientAcceptance?.presentedSOA > 0)
		)
	);

	hasFileSavedATP$ = this.query.adviceProcess$.pipe(
		map((x) =>
			x?.documents.some(
				(d) =>
					d.field === AdviceProcessDocumentField.AuthorityToProceed &&
					!!d?.value?.documentID
			)
		)
	);

	emailDocumentTemplate: string;

	groupName = this.query
		.getValue()
		.primaryClient?.groupName.toString()
		.toUpperCase();
	atpFileName = `${this.groupName} AUTHORITY TO PROCEED`;
	fsFileName = `${this.groupName} FINAL STRUCTURE`;

	isLrApUpdateLoading: boolean;
	adviceProcessPageCodes = AdviceProcessPageCodes;

	isDownloadingATP: boolean;
	isSavingFileATP: boolean;
	// contentATP: string;

	isAdviceProcessEnded$ = this.query.adviceProcess$.pipe(
		map((x) => x?.status > 2 && x?.status < 5)
	);

	@ViewChild('top', { static: true }) top: ElementRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private incomeService: IncomeService,
		private alService: AssetsLiabilitiesService,
		private disabilityService: DisabilityService,
		private loggerService: LoggerService,
		private routeService: RouteService,
		private goalsService: GoalsService,
		private illnessService: CriticalIllnessService,
		private riskProfileService: RiskProfileService,
		private tpdService: TpdService,
		private lifeService: LifeService,
		private medicalService: RiskAnalysisMedicalService,
		private sosService: ScopeOfServiceService,
		private caService: ClientAcceptanceService,
		private fsService: FinalStructureService,
		private modalService: BsModalService,
		private crtService: ClientReviewTemplateService,
		private crtQuery: ClientReviewTemplateQuery,
		private medicalQuery: MedicalQuery,
		private tpdQuery: TpdQuery,
		private lifeQuery: LifeQuery,
		private disabilityQuery: DisabilityQuery,
		protected query: ClientReviewTemplateQuery,
		protected soaService: StatementOfAdviceService,
		protected soaQuery: StatementOfAdviceQuery,
		private fsCrtSettingsService: FinalStructureSettingsService,
		private peopleService: PeopleService,
		private emailService: EmailService,
		private crtMergeTagsService: CrtMergeTagsService,
		private configService: ConfigService,
		private businessConfigQuery: BusinessConfigQuery,
		private declarationService: DeclarationService,
		private loatDocumentService: LoatDocumentService,
		private disclosureService: DisclosureService,
		private cIService: CurrentInsuranceService,
		private ngZone: NgZone,
		private renderer: Renderer2,
		private apCrtSettingsService: AuthorityToProceedSettingsService,
		private lrApService: LrAdviceprocessService,
		private riskAnalysisService: RiskAnalysisService,
		private historyService: HistoryService,
		private crtNoteService: CrtNoteService,
		private crtDocService: CrtDocumentService,
		private existingPolicyStructure: ExistingPolicyStructureQuery,
		private toPdfService: ToPdfService
	) {}

	ngOnInit(): void {
		of(true)
			.pipe(
				combineLatest(
					this.sosService.scopeOfService$,
					this.sosService.sosDefault$
				),
				map(([_, crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
				map((x) => {
					const result = [];
					if (!x) {
						return result;
					}
					if (x.lifeInsurance !== 1) {
						result?.push(AdviceProcessPageCodes.Life);
					}
					if (x.criticalIllness !== 1) {
						result?.push(AdviceProcessPageCodes.CriticalIllnness);
					}
					if (x.tPDInsurance !== 1) {
						result?.push(AdviceProcessPageCodes.TPD);
					}
					if (x.disabilityInsurance !== 1) {
						result?.push(AdviceProcessPageCodes.Disability);
					}
					if (x.medicalInsurance !== 1) {
						result?.push(AdviceProcessPageCodes.Medical);
					}
					if (x.homeCarAndContentsInsurance !== 1) {
						result?.push(AdviceProcessPageCodes.FG);
					}

					if (this.isAdviceProcessEnded()) {
						this.crtRoutes = omit(
							[
								...result,
								AdviceProcessPageCodes.Introduction,
								AdviceProcessPageCodes.Disclosure,
								AdviceProcessPageCodes.SOS,
								AdviceProcessPageCodes.Declaration,
								AdviceProcessPageCodes.SOA,
								AdviceProcessPageCodes.AuthorityToProceed,
								AdviceProcessPageCodes.FinalStructure,
							],
							crtRoutes
						);
					} else {
						this.crtRoutes = omit(result, crtRoutes);
					}
					return result;
				})
			)
			.subscribe();

		this.setCurrentTab();
		this.setCurrentTabTitle();

		this.router.events
			.pipe(
				tap((event) => {
					if (this.isTopControls && event instanceof NavigationEnd) {
						this.setCurrentTabTitle();
						this.setCurrentTab();
					}
				}),
				filter(
					(event) =>
						(event instanceof NavigationStart ||
							event instanceof NavigationEnd) &&
						!this.isTopControls &&
						event.url.includes('crt')
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe((event) => {
				if (event instanceof NavigationStart) {
					if (!this.isSaving) {
						this.saveTab();
					}
				} else if (event instanceof NavigationEnd) {
					this.tabSet.emit(this.getRouterData());
					this.setCurrentTab();
					this.isSaving = false;
				}
			});

		this.query.lrApUpdateLoading$
			.pipe(
				tap((x) => (this.isLrApUpdateLoading = x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	isFirstTab() {
		return Object.keys(this.crtRoutes).indexOf(this.getRouterData()) === 0;
	}

	isLastTab() {
		return (
			Object.keys(this.crtRoutes).indexOf(this.getRouterData()) + 1 ===
			Object.keys(this.crtRoutes).length
		);
	}

	isAdviceProcessEnded = () =>
		this.query.getValue().adviceProcess?.status > 2 &&
		this.query.getValue().adviceProcess?.status < 5;

	next() {
		this.isSaving = true;
		const navigateNext = () => {
			if (this.isLastTab()) {
				return;
			}
			const baseUrl = this.router.url.split('/').slice(1, 7).join('/');
			const currentTabIndex = Object.keys(this.crtRoutes).indexOf(
				this.getRouterData()
			);
			const nextTab = Object.values(this.crtRoutes)[currentTabIndex + 1];
			const navigateTo = baseUrl + '/' + nextTab;
			this.router.navigateByUrl(navigateTo);
		};

		this.saveTab(navigateNext, true);
	}

	previous() {
		this.isSaving = true;
		const navigatePrev = () => {
			if (this.isFirstTab()) {
				return;
			}
			const baseUrl = this.router.url.split('/').slice(1, 7).join('/');
			const currentTabIndex = Object.keys(this.crtRoutes).indexOf(
				this.getRouterData()
			);
			const nextTab = Object.values(this.crtRoutes)[currentTabIndex - 1];
			const navigateTo = baseUrl + '/' + nextTab;
			this.router.navigateByUrl(navigateTo);
		};
		this.saveTab(navigatePrev);
	}

	goToTab(path: string) {
		this.isSaving = true;
		const navigateToTab = () => {
			const baseUrl = this.router.url.split('/').slice(1, 7).join('/');
			const navigateTo = baseUrl + '/' + path;
			this.router.navigateByUrl(navigateTo);
		};
		this.saveTab(navigateToTab, false, true);
	}

	pauseExit() {
		this.isSaving = true;
		let redirectRoute = [];
		const hasChanges = this.crtQuery.getValue().hasFormChanges;
		const clientId = +this.route.snapshot.paramMap.get('clientId');
		const apId = +this.route.snapshot.paramMap.get('adviceProcessId');
		const isSavingSOA = this.soaQuery.getValue().isSavingSOA;
		const isSavingPI = this.soaQuery.getValue().isSavingPI;
		const isSavingSOAReview = this.soaQuery.getValue().isSavingSOAReview;
		const hasSoaChanges = this.query.getValue().hasSoaChanges;

		if (hasSoaChanges) {
			this.soaService.setTriggerLeaveSoa('crm');
			return;
		}

		if (this.isCRTLoading || isSavingSOA || isSavingPI || isSavingSOAReview) {
			return;
		}

		const goToCrm = () => {
			this.clearDataFromState();

			if (this.isCompany$) {
				redirectRoute = this.routeService.businessAdviceProcess(clientId, apId);
			} else {
				redirectRoute = this.routeService.customerAdviceProcess(clientId, apId);
			}
			this.router.navigate(redirectRoute);
		};

		const confirm = new Observable((obs: Observer<any>) => {
			this.isPause = true;
			this.saveTab(goToCrm, false, true);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			goToCrm();
			obs.complete();
		});

		const initState = {
			header: 'Confirmation',
			message: `There are unsaved changes, do you wish to save them?`,
			confirm$: confirm,
			decline$: decline,
			detachCloseIcon: true,
		};

		if (hasChanges && !this.isAdviceProcessEnded()) {
			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			if (this.isAdviceProcessEnded()) {
				this.isExit = true;
			}
			goToCrm();
		}
	}

	saveTab(callback?: Function, clickedNext?: boolean, goToTab?: boolean) {
		if (this.isAdviceProcessEnded()) {
			if (typeof callback === 'function') {
				if (
					(this.getRouterData() === AdviceProcessPageCodes.Life ||
						this.getRouterData() === AdviceProcessPageCodes.Disability ||
						this.getRouterData() === AdviceProcessPageCodes.TPD ||
						this.getRouterData() === AdviceProcessPageCodes.CriticalIllnness ||
						this.getRouterData() === AdviceProcessPageCodes.Medical) &&
					!goToTab
				) {
					this.riskAnalysisService
						.switchPersonTab(clickedNext)
						.pipe(
							take(1),
							tap((x) => {
								if (!x || goToTab) {
									callback();
								}
							})
						)
						.subscribe();
				} else {
					callback();
				}
			}
			return;
		}
		switch (this.getRouterData()) {
			// Introduction
			case AdviceProcessPageCodes.Introduction:
				if (typeof callback === 'function') {
					callback();
				}
				break;
			// Disclosure Document
			case AdviceProcessPageCodes.Disclosure:
				this.saveDisclosure(callback, clickedNext);
				break;
			// Scope of Services
			case AdviceProcessPageCodes.SOS:
				this.saveScopeOfService(callback, clickedNext);
				break;

			// Fact Find
			// People
			case AdviceProcessPageCodes.People:
				if (typeof callback === 'function') {
					callback();
				}
				break;
			// Assets and Liabilities
			case AdviceProcessPageCodes.AssetsLiabilities:
				this.saveAssetsAndLiabilities(callback);
				break;
			// Income and Budget
			case AdviceProcessPageCodes.IncomeExpenses:
				this.saveIncomeBudget(callback);
				break;
			// Current Insurance
			case AdviceProcessPageCodes.CurrentInsurance:
				this.checkCurrentInsurance(callback, clickedNext);
				break;
			// F&G
			case AdviceProcessPageCodes.FG:
				if (typeof callback === 'function') {
					callback();
				}
				break;
			// Medical History
			case AdviceProcessPageCodes.MedicalHistory:
				this.saveMedicalHistory(callback);
				break;

			// Risk Analysis
			// Goals
			case AdviceProcessPageCodes.Goals:
				this.saveGoals(callback);
				break;
			// Life
			case AdviceProcessPageCodes.Life:
				this.saveRiskLife(callback, clickedNext, goToTab);
				break;

			// Disability
			case AdviceProcessPageCodes.Disability:
				this.saveDisability(callback, clickedNext, goToTab);
				break;

			// TPD
			case AdviceProcessPageCodes.TPD:
				this.saveRiskTpd(callback, clickedNext, goToTab);
				break;

			// Critical Illness
			case AdviceProcessPageCodes.CriticalIllnness:
				this.saveCriticalIllness(callback, clickedNext, goToTab);
				break;

			// Medical
			case AdviceProcessPageCodes.Medical:
				this.saveRiskMedical(callback, clickedNext, goToTab);
				break;

			// Risk Profile
			case AdviceProcessPageCodes.RiskProfile:
				this.saveRiskProfile(callback);
				break;

			// Declaration
			case AdviceProcessPageCodes.Declaration:
				this.saveDeclaration(callback);
				break;
			// Statement of Advice
			case AdviceProcessPageCodes.SOA:
				if (typeof callback === 'function' && !clickedNext) {
					callback();
				} else {
					this.checkStatementOfAdvice(callback, clickedNext);
				}
				break;
			// Client Acceptance
			case AdviceProcessPageCodes.AuthorityToProceed:
				this.saveClientAcceptance(callback);
				break;
			// Final Structure
			case AdviceProcessPageCodes.FinalStructure:
				this.saveFinalStructure(callback);
				break;
			default:
				this.crtLoad.emit(false);
				this.isPause = false;
				return null;
		}
	}

	getRouterData(prop = 'type') {
		let child = this.route.firstChild;
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else if (child.snapshot.data && child.snapshot.data[prop]) {
				return child.snapshot.data[prop];
			} else {
				return '';
			}
		}
		return '';
	}

	setCurrentTab() {
		const tabCode = this.getRouterData();
		this.currentTab.next(tabCode);
		if (
			!this.query.getValue().lrApPageStarted?.includes(tabCode) &&
			!this.isTopControls &&
			tabCode !== AdviceProcessPageCodes.Introduction
		) {
			this.updateAdviceProcessPageStarted(tabCode);
		}
	}

	setCurrentTabTitle() {
		this.currentTabTitle = this.getRouterData('title');
	}

	// tslint:disable-next-line: ban-types
	checkCurrentInsurance(callback?: Function, clickedNext?: boolean) {
		this.crtService.showCurrentInsuranceModal$.next(false);
		this.cIService.currentInsurances$
			.pipe(
				tap(() => this.crtLoad.emit(true)),
				tap((x) => {
					const proceed = (canProceed) => {
						this.crtLoad.emit(false);
						this.crtService.setHasFormChanges(false);
						if (canProceed && typeof callback === 'function') {
							callback();
						}
						if (clickedNext) {
							this.cIService.setCurrentInsuranceNext(canProceed);
						}
					};

					if (either(isNil, isEmpty)(x)) {
						const confirm = new Observable((obs) => {
							this.ngZone.run(() =>
								this.updateAdviceProcessPageCompleted('FCLYES')
							);
							proceed(false);
							this.crtService.showCurrentInsuranceModal$.next(true);
							obs.next();
							obs.complete();
						});

						const decline = new Observable((obs: Observer<any>) => {
							this.ngZone.run(() =>
								this.updateAdviceProcessPageCompleted('FCLNO')
							);
							this.crtService.showCurrentInsuranceModal$.next(false);
							proceed(true);
							obs.complete();
						});

						const initState = {
							header: '',
							message: `Is there any existing insurance?`,
							confirm$: confirm,
							decline$: decline,
							isOkBtn: false,
							isAcceptBtn: false,
						};
						this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
							class: 'modal-dialog-centered modal-dialog',
							initialState: initState,
							ignoreBackdropClick: true,
							keyboard: false,
						});
					} else {
						this.updateAdviceProcessPageCompleted('FCLYES');
						proceed(true);
					}
				}),
				finalize(() => (this.isPause = false)),
				take(1)
			)
			.subscribe();
	}

	scrollToTop() {
		const top = document.querySelector('.crt__header');
		top.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}

	saveMedicalHistory(callback?: Function) {
		const bodyMeasures = this.query.getValue().bodyMeasures;

		const medicalHistoryOption = this.query.getValue().medicalHistoryOptions;

		this.crtLoad.emit(true);
		from(bodyMeasures)
			.pipe(
				concatMap((x) =>
					iif(
						() => !!x?.smokingStatus,
						this.historyService.updateBodyMeasures(x, medicalHistoryOption),
						of(null)
					)
				),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				})
			)
			.subscribe();
	}

	updateAdviceProcessPageCompleted(code: string) {
		this.query.lrApPageCompleted$
			.pipe(
				concatMap((data) => {
					let pageCompleted = data || [];
					if (code === 'FCLYES') {
						pageCompleted = pageCompleted?.filter((x) => x !== 'FCLNO');
					} else if (code === 'FCLNO') {
						pageCompleted = pageCompleted?.filter((x) => x !== 'FCLYES');
					}

					const list = uniq([...pageCompleted, code]) || [];

					return of(list).pipe(
						tap((x) => this.lrApService.setLrApPageCompletedState(x)),
						concatMap(() =>
							iif(
								() => !data?.some((x) => x === code),
								this.lrApService.updateLrApPageCompleted(list),
								of([])
							)
						)
					);
				}),
				take(1)
			)
			.subscribe();
	}

	updateAdviceProcessPageStarted(code: string) {
		this.query.lrApPageStarted$
			.pipe(
				delay(350),
				concatMap((data) => {
					const list = uniq([...data, code]) || [];
					return of(list).pipe(
						tap((x) => this.lrApService.setLrApPageStartedState(x)),
						concatMap(() =>
							iif(
								() => !data?.some((x) => x === code),
								this.lrApService.updateLrApPageStarted(list),
								of([])
							)
						)
					);
				}),
				take(1)
			)
			.subscribe();
	}

	saveIncomeBudget(callback?: Function) {
		this.crtLoad.emit(true);
		this.monthlyExpense$
			.pipe(
				take(1),
				tap((data) => {
					if (!data && typeof callback === 'function') {
						callback();
					}
				}),
				filter((x) => !!x),
				switchMap((data) => {
					if (!isNil(data?.isInvalid) && !data.isInvalid) {
						return data?.cRTId
							? this.incomeService.updateMonthlyExpense(data)
							: this.incomeService.addMonthlyExpense(data);
					}
					return of(true).pipe(tap(() => this.crtLoad.emit(false)));
					// return iif(
					// 	() => !isNil(data?.isInvalid) && !data.isInvalid,
					// 	action,
					// 	of(true).pipe(tap(() => this.crtLoad.emit(false)))
					// );
				}),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				})
			)
			.subscribe();
	}

	saveAssetsAndLiabilities(callback?: Function) {
		this.crtLoad.emit(true);
		of(true)
			.pipe(
				withLatestFrom(this.alService.others$),
				tap(([, x]) => {
					if (
						(!x || x.length === 0 || !!x[0].invalid) &&
						typeof callback === 'function'
					) {
						callback();
					}
				}),
				filter(([, x]) => !!x && x.length > 0 && !x[0].invalid),
				map(([, x]) => x),
				mergeMap((x) =>
					!x[0]?.cRTId
						? this.alService.addAL(
								objectUtil.mapCamelCaseToPascalCase({
									...x[0],
									adviceProcessId: this.crtQuery.getValue().adviceProcessId,
									sectionCode: AdviceProcessSectionCodes.Others,
								}),
								AdviceProcessSectionCodes.Others
						  )
						: this.alService.updateAL(
								objectUtil.mapCamelCaseToPascalCase({
									...x[0],
									adviceProcessId: this.crtQuery.getValue().adviceProcessId,
									sectionCode: AdviceProcessSectionCodes.Others,
								}),
								AdviceProcessSectionCodes.Others
						  )
				),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
	}

	saveDisclosure(callback?: Function, clickedNext?: boolean) {
		let data;
		this.crtLoad.emit(true);
		this.disclosureService.disclosureDocument$
			.pipe(
				tap((x) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter((x) => !!x && !!x?.content),
				tap((x) => {
					data = x;
				}),
				mergeMap((x) => {
					return iif(
						() => x.templateType === '.pdf',
						this.crtDocService.getDocumentLink(x.fileInfo.fileUrl, {
							responseType: 'blob',
						}),
						of(x?.content)
					);
				}),
				mergeMap((x) => {
					return iif(
						() => x.templateType === '.pdf',
						convertUtil.simpleConvertToBase64(x),
						convertUtil.convertToBase64(x)
					);
				}),
				map((content) => {
					let fileStr;
					if (data.templateType === '.pdf') {
						fileStr = `${((content as string) ?? '')?.replace(
							/^data:(.*,)?/,
							''
						)}`;
					} else {
						fileStr = content;
					}
					return {
						fileName:
							data.templateType === '.pdf'
								? data?.fileInfo?.fileName
								: DefaultFileNames.Disclosure,
						document: fileStr,
						documentId: +data?.fileInfo?.documentID,
					};
				}),
				concatMap((x) =>
					iif(
						() => !!x?.documentId && x?.documentId > 0,
						this.disclosureService.updateFileUploadDD(x),
						of(x)
					)
				),
				// concatMap(() => {
				// 	return iif(() => data?.fileInfo?.documentID,  this.disclosureService.updateDisclosure({
				// 		...data,
				// 		document: {
				// 			referenceId: +data?.fileInfo?.documentID,
				// 			value: data?.fileInfo?.fileName
				// 		}
				// 	}), of(null));

				// }),
				finalize(() => {
					this.disclosureService.updateDdTemp({
						...data,
						clickedNext: data.clickedNext ? data.clickedNext : clickedNext,
					});
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
	}

	saveDeclaration(callback?: Function) {
		this.crtLoad.emit(true);
		of(true)
			.pipe(
				withLatestFrom(this.declarationService.declarationFormValue$),
				tap(([, x]) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter(([, x]) => !!x),
				map(([, x]) => x as DeclarationState & { content?: string }),
				concatMap((x) =>
					!!x?.content
						? convertUtil.convertToBase64(x.content).pipe(
								concatMap((c) => {
									const request = {
										ReferenceId: x.cRTId,
										Document: c,
										FileName: DefaultFileNames.Declaration,
										Type: DocumentTypes.Declaration,
										DocumentID: x?.document?.referenceId,
									};
									return this.declarationService.saveDocument(request);
								}),
								map((c) => ({
									...x,
									document: { ...x?.document, referenceId: c },
								}))
						  )
						: of(x)
				),
				map((x) => {
					return DeclarationDocumentMapper.mapDeclarationToUpsert(
						x,
						this.query.getValue().adviceProcessId,
						x?.signatures
					);
				}),
				mergeMap((x) => this.declarationService.updateDeclaration(x)),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	saveDisability(
		callback?: Function,
		clickedNext?: boolean,
		goToTab?: boolean
	) {
		let isInvalid = true;
		this.crtLoad.emit(true);
		this.disabilityService.setShowInvalid(true);
		of(
			this.disabilityQuery.getAll({
				filterBy: (e) => !!e.parentCRTId || +e.parentCRTId > 0,
			})
		)
			.pipe(
				tap((x) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter((x) => {
					if (!x) {
						isInvalid = false;
						return !!x;
					}
					if (
						x?.some((y) =>
							y?.expensesCouldStopList?.some((z) => !z.dropdown || !z.value)
						)
					) {
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.ExpensesStop)
						);
						isInvalid = false;
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}

					if (
						x?.some((y) =>
							y?.incomeCouldStartList?.some((z) => !z.dropdown || !z.value)
						)
					) {
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.IncomeStart)
						);
						isInvalid = false;
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}
					if (
						x?.some((y) =>
							y?.expensesCouldStartList?.some((z) => !z.dropdown || !z.value)
						)
					) {
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.ExpensesStart)
						);
						isInvalid = false;
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}

					return true;
				}),
				map((x) => x?.map((i) => DisabilityMapper.mapToUpsert(i))),
				mergeMap(async (data) => {
					const requests = data.map((x) =>
						x.cRTId
							? this.disabilityService.updateDisability(x)
							: this.disabilityService.addDisability(x)
					);
					for (const request of requests) {
						await request.toPromise();
					}
					return of(requests);
				}),
				concatMap(() => {
					return iif(
						() => goToTab,
						of(false), // THERE IS NO SWITCHING
						this.riskAnalysisService.switchPersonTab(clickedNext)
					);
				}),
				tap((changePersonTab) => {
					this.crtService.setHasFormChanges(false);

					if (!changePersonTab || goToTab) {
						if (isInvalid && typeof callback === 'function') {
							callback();
						}
					}
					this.scrollToTop();
				}),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
		this.disabilityService.setShowInvalid(false);
	}

	saveGoals(callback?: Function) {
		let isInvalid = true;
		this.crtLoad.emit(true);
		this.goals$
			.pipe(
				take(1),
				withLatestFrom(this.query.people$),
				map(
					([goals]) =>
						({
							...goals,
							longTermGoals:
								goals?.longTermGoals?.filter(
									(g) =>
										!!g.dropdown &&
										(g.dropdown !== 'Other' ||
											(g.dropdown === 'Other' && !!g.value))
								) ?? [],
							shortTermGoals:
								goals?.shortTermGoals?.filter(
									(g) =>
										!!g.dropdown &&
										(g.dropdown !== 'Other' ||
											(g.dropdown === 'Other' && !!g.value))
								) ?? [],
						} as GoalsState)
				),
				tap((goals) => this.goalsService.updateGoalsState(goals)),
				map((goals) => GoalsMapper.mapGoalsObjToUpsert(goals)),
				switchMap((newGoals) =>
					iif(
						() => isNil(newGoals?.cRTId),
						this.goalsService.addGoals(newGoals),
						this.goalsService.updateGoals(newGoals)
					)
				),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (isInvalid && typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				})
			)
			.subscribe();
	}

	saveCriticalIllness(
		callback?: Function,
		clickedNext?: boolean,
		goToTab?: boolean
	) {
		this.crtLoad.emit(true);
		let isInvalid = true;
		this.illnessService.setShowInvalid(true);
		this.criticalIllness$
			.pipe(
				take(1),
				map((data) =>
					data?.filter(
						(i) => i.touched && (!!i?.parentCRTId || +i?.parentCRTId > 0)
					)
				),
				map((data) =>
					data?.map((item) => CriticalIllnessMapper.mapToUpsert(item))
				),
				filter((x) => {
					const extraCostsList = x?.filter((y) =>
						y.extraCostsList.some((p) => !p.dropdown || isEmpty(p.value))
					);
					if (extraCostsList.length > 0) {
						this.loggerService.Warning(
							{},
							extraCostsList.length > 0
								? getInvalidWarning(Fields.OtherCostList)
								: getInvalidWarning(Fields.PartnersIncome)
						);
						isInvalid = false;
						this.crtLoad.emit(false);
					}
					return !!x && extraCostsList.length < 1;
				}),
				mergeMap(async (data) => {
					const requests = data?.map((x) =>
						x.cRTId
							? this.illnessService.updateCriticalIllness({
									...x,
									clickedNext: x.clickedNext ? x.clickedNext : clickedNext,
							  })
							: this.illnessService.addCriticalIllness({
									...x,
									clickedNext: x.clickedNext ? x.clickedNext : clickedNext,
							  })
					);
					for (const request of requests) {
						await request.toPromise();
					}
					return of(requests);
				}),
				concatMap(() => {
					return iif(
						() => goToTab,
						of(false), // THERE IS NO SWITCHING
						this.riskAnalysisService.switchPersonTab(clickedNext)
					);
				}),
				tap((changePersonTab) => {
					this.crtService.setHasFormChanges(false);

					if (!changePersonTab || goToTab) {
						if (isInvalid && typeof callback === 'function') {
							callback();
						}
					}
				}),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
				})
			)
			.subscribe();
		this.illnessService.setShowInvalid(false);
	}

	saveRiskProfile(callback?: Function) {
		this.crtLoad.emit(true);
		this.riskProfileService.setShowInvalid(true);
		this.riskProfile$
			.pipe(
				take(1),
				map(
					(data) =>
						({
							...data,
							insurancePreferences:
								data?.insurancePreferences?.filter(
									(g) =>
										!!g.dropdown &&
										(g.dropdown !== 'Other' ||
											(g.dropdown === 'Other' && !!g.value))
								) ?? [],
						} as RiskProfileState)
				),
				map((data) => RiskProfileMapper.mapToUpsert(data)),
				switchMap((data) =>
					iif(
						() => isNil(data?.cRTId),
						this.riskProfileService.addRiskProfile(data),
						this.riskProfileService.updateRiskProfile(data)
					)
				),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				})
			)
			.subscribe();
		this.riskProfileService.setShowInvalid(false);
	}

	saveRiskTpd(callback?: Function, clickedNext?: boolean, goToTab?: boolean) {
		let isInvalid = true;
		this.crtLoad.emit(true);
		this.tpdService.setShowInvalid(true);
		of(
			this.tpdQuery.getAll({
				filterBy: (e) => !!e?.parentCRTId || +e?.parentCRTId > 0,
			})
		)
			.pipe(
				tap((x) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter((x) => {
					if (!x) {
						isInvalid = false;
						return !!x;
					}
					if (
						x?.some((y) =>
							y.expensesCouldStart?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) =>
							y.expensesCouldStart?.some((z) => !z.dropdown || !z.value)
						)
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.ExpensesStart)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}
					if (
						x?.some((y) =>
							y.expensesCouldStop?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) =>
							y.expensesCouldStop?.some((z) => !z.dropdown || !z.value)
						)
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.ExpensesStop)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}
					if (
						x?.some((y) =>
							y.incomeCouldStart?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) =>
							y.incomeCouldStart?.some((z) => !z.dropdown || !z.value)
						)
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.IncomeStart)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}
					if (
						x?.some((y) =>
							y.requiredList?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) => y.requiredList?.some((z) => !z.dropdown || !z.value))
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.LifeInsuranceRequirementOther)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}

					return true;
				}),
				map((x) => x?.map((i) => RiskAnalysisTpdMapper.mapToUpsert(i))),
				mergeMap(async (data) => {
					const requests = data?.map((x) =>
						x.cRTId ? this.tpdService.updateTpd(x) : this.tpdService.addTpd(x)
					);
					for (const request of requests) {
						await request.toPromise();
					}
					return of(requests);
				}),
				concatMap(() => {
					return iif(
						() => goToTab,
						of(false), // THERE IS NO SWITCHING
						this.riskAnalysisService.switchPersonTab(clickedNext)
					);
				}),
				tap((changePersonTab) => {
					this.crtService.setHasFormChanges(false);

					if (!changePersonTab || goToTab) {
						if (isInvalid && typeof callback === 'function') {
							callback();
						}
					}
					this.scrollToTop();
				}),
				take(1),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
				})
			)
			.subscribe();
		this.tpdService.setShowInvalid(false);
	}

	saveRiskLife(callback?: Function, clickedNext?: boolean, goToTab?: boolean) {
		let isInvalid = true;
		this.crtLoad.emit(true);
		this.lifeService.setShowInvalid(true);
		of(
			this.lifeQuery.getAll({
				filterBy: (e) => !!e.parentCRTId || +e.parentCRTId > 0,
			})
		)
			.pipe(
				tap((x) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter((x) => {
					if (!x) {
						isInvalid = false;
						return !!x;
					}
					if (
						x?.some((y) =>
							y.expensesCouldStart?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) =>
							y.expensesCouldStart?.some((z) => !z.dropdown || !z.value)
						)
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.ExpensesStart)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}
					if (
						x?.some((y) =>
							y.expensesCouldStop?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) =>
							y.expensesCouldStop?.some((z) => !z.dropdown || !z.value)
						)
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.ExpensesStop)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}
					if (
						x?.some((y) =>
							y.incomeCouldStart?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) =>
							y.incomeCouldStart?.some((z) => !z.dropdown || !z.value)
						)
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.IncomeStart)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}
					if (
						x?.some((y) =>
							y.requireds?.some((z) => !!z.dropdown || !!z.value)
						) &&
						x?.some((y) => y.requireds?.some((z) => !z.dropdown || !z.value))
					) {
						isInvalid = false;
						this.loggerService.Warning(
							{},
							getInvalidWarning(Fields.LifeInsuranceRequirementOther)
						);
						this.crtLoad.emit(false);
						this.isPause = false;
						return false;
					}

					return true;
				}),
				map((x) => x?.map((i) => RiskAnalysisLifeMapper.mapToUpsert(i))),
				mergeMap(async (data) => {
					const requests = data?.map((x) =>
						x.cRTId
							? this.lifeService.updateLife(x).pipe(
									catchError(() => {
										this.crtLoad.emit(false);
										this.isPause = false;
										return EMPTY;
									})
							  )
							: this.lifeService.addLife(x).pipe(
									catchError(() => {
										this.crtLoad.emit(false);
										this.isPause = false;
										return EMPTY;
									})
							  )
					);
					for (const request of requests) {
						await request.toPromise();
					}
					return of(requests);
				}),
				concatMap(() => {
					return iif(
						() => goToTab,
						of(false), // THERE IS NO SWITCHING
						this.riskAnalysisService.switchPersonTab(clickedNext)
					);
				}),
				tap((changePersonTab) => {
					this.crtService.setHasFormChanges(false);

					if (!changePersonTab || goToTab) {
						if (isInvalid && typeof callback === 'function') {
							callback();
						}
					}
					this.scrollToTop();
				}),
				take(1),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
					this.isPause = false;
				})
			)
			.subscribe();
		this.lifeService.setShowInvalid(false);
	}

	saveRiskMedical(
		callback?: Function,
		clickedNext?: boolean,
		goToTab?: boolean
	) {
		this.medicalService.setShowInvalid(true);
		this.crtLoad.emit(true);

		of(this.medicalQuery.getAll())
			.pipe(
				tap((x) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter((x) => !!x),
				map((x) => x?.map((i) => RiskAnalysisMedicalMapper.mapToUpsert(i))),
				mergeMap(async (data) => {
					const requests = data.map((x) =>
						x.cRTId
							? this.medicalService.updateMedical(x)
							: this.medicalService.addMedical(x)
					);
					for (const request of requests) {
						await request.toPromise();
					}
					return of(requests);
				}),
				concatMap(() => {
					return iif(
						() => goToTab,
						of(false), // THERE IS NO SWITCHING
						this.riskAnalysisService.switchPersonTab(clickedNext)
					);
				}),
				tap((changePersonTab) => {
					this.crtService.setHasFormChanges(false);

					if (!changePersonTab || goToTab) {
						if (typeof callback === 'function') {
							callback();
						}
					}
				}),
				take(1),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
				})
			)
			.subscribe();
		this.medicalService.setShowInvalid(false);
	}

	saveScopeOfService(callback?: Function, clickedNext?: boolean) {
		this.crtLoad.emit(true);
		of(undefined)
			.pipe(
				withLatestFrom(this.sosService.scopeOfService$),
				map(([, x]) => x),
				tap((x) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter((x) => !either(isNil, isEmpty)(x)),
				concatMap((x) =>
					iif(
						() => isNil(x?.cRTId),
						this.sosService.addScopeOfService({
							...x,
							clickedNext: x.clickedNext ? x.clickedNext : clickedNext,
						}),
						this.sosService.updateScopeOfService({
							...x,
							clickedNext: x.clickedNext ? x.clickedNext : clickedNext,
						})
					)
				),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
	}

	saveSOADocument(soaId: number) {
		return this.soaQuery.finalizedSOA$.pipe(
			map((x) => x.find((soa) => soa.cRTId === soaId)),
			concatMap((x) =>
				this.getDocumentToPdf(
					x?.document?.referenceId,
					x?.document?.value?.replace(/(.*)\.[^.]+$/, '')
				).pipe(
					map((file) => ({
						file,
						name: x?.name.concat('.pdf'),
					}))
				)
			),
			concatMap((x) =>
				this.crtDocService.saveDocument({
					ReferenceId: this.query.getValue().adviceProcessId,
					CustomerId: this.query.getValue().primaryClient?.customerID,
					Document: x.file,
					FileName: x.name,
					DocumentType: ServicesCodes.LR,
					Type: AdviceProcessPageCodes.SOA,
				})
			),
			concatMap((x) =>
				this.loatDocumentService.updateAdviceProcess(
					x,
					AdviceProcessPageCodes.SOA
				)
			)
		);
	}

	getDocumentToPdf(documentId: number, documentName?: string) {
		return of(this.query.getValue().adviceProcessId).pipe(
			mergeMap((id) => this.crtMergeTagsService.getSosMt(+id)),
			concatMap(() => this.crtDocService.getDocument(documentId)),
			concatMap((x) =>
				this.crtDocService.getDocumentLink(x.DocumentLink, {
					responseType: 'text',
				})
			),
			switchMap((x) => this.cleanContentForDownload(x)),
			debounceTime(100),
			map((x) => `<div class="soa-pdf-file">${x}</div>`),
			concatMap((x) =>
				this.soaService.convertSoaTemplateToBase64Pdf(x, `${documentName}.pdf`)
			)
		);
	}

	cleanContentForDownload = (content = '') =>
		// Use this only when converting final contents of SOA to PDF
		of(content).pipe(
			concatMap((x) => this.updateSoaSosMt(x)),
			concatMap((x) => this.updateSoaSosPages(x)),
			map((x) => removeSoaDivs(x)),
			map((x) => removeCustomNotes(x)),
			map((x) => removeMtWrappers(x)),
			map((x) => removeHTMLContentWhiteSpaces(x, true)),
			take(1)
		);

	updateSoaSosMt = (content = '') =>
		of(true).pipe(
			withLatestFrom(
				this.crtMergeTagsService.mergeTags$,
				this.sosService.scopeOfService$,
				this.sosService.sosDefault$
			),
			switchMap(([, mergeTags, sosCrt, sosDefault]) =>
				this.soaService.updateSosMt(content, mergeTags, sosCrt, sosDefault)
			),
			take(1)
		);

	updateSoaSosPages = (content = '') =>
		of(true).pipe(
			withLatestFrom(
				this.sosService.scopeOfService$,
				this.sosService.sosDefault$
			),
			switchMap(([, sosCrt, sosDefault]) =>
				this.soaService.updateSosPages(content, sosCrt, sosDefault)
			),
			take(1)
		);

	saveClientAcceptance(callback?: Function) {
		this.crtLoad.emit(true);
		of(true)
			.pipe(
				withLatestFrom(this.caService.formValue$),
				tap(([, x]) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter(([, x]) => !!x),
				map(([, x]) => ClientAcceptanceMapper.mapToUpsert(x)),
				mergeMap((x) =>
					!x.cRTId
						? this.caService.addClientAcceptance(x).pipe(mapTo(x))
						: this.caService.updateClientAcceptance(x).pipe(mapTo(x))
				),
				concatMap((x) => this.saveSOADocument(x.sOA)),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
	}

	saveFinalStructure(callback?: Function) {
		this.crtLoad.emit(true);
		of(true)
			.pipe(
				withLatestFrom(this.fsService.formValue$),
				tap(([, x]) => {
					if (!x && typeof callback === 'function') {
						callback();
					}
				}),
				filter(([, x]) => !!x),
				map(([, x]) => x),
				mergeMap((x) =>
					!!x.cRTId ? this.fsService.updateFinalStructure(x) : of(x)
				),
				finalize(() => {
					this.crtLoad.emit(false);
					this.crtService.setHasFormChanges(false);
					if (typeof callback === 'function') {
						callback();
					}
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
	}

	updateAdviceProcess(id: number, field: string) {
		const lradvice = {
			...this.adviceProcess,
			documents: this.adviceProcess.documents?.map((doc) => {
				return {
					...doc,
					value:
						doc.field.toLowerCase() === field.toLowerCase()
							? id
							: doc.value?.documentID ?? null,
				};
			}),
		};
		return this.soaService.updateLRAdviceProcess(lradvice);
	}

	complete() {
		this.crtLoad.emit(true);
		of(true)
			.pipe(
				withLatestFrom(this.query.finalStructure$, this.fsService.formValue$),
				filter(([, fs, x]) => {
					//this.isLoading = !!fs || !!x;
					return !!fs || !!x;
				}),
				concatMap(() =>
					this.generateAttachment().pipe(
						mergeMap((content) =>
							this.crtDocService.downloadDocumentPDF(content, this.fsFileName)
						),
						mergeMap((content) => convertUtil.convertToBase64(content))
					)
				),
				concatMap((x) =>
					this.crtService.saveDocument({
						ReferenceId: this.query.getValue().adviceProcessId,
						CustomerId: this.query.getValue().primaryClient?.customerID,
						Document: x,
						FileName: `${this.fsFileName}.pdf`,
						DocumentType: ServicesCodes.LR,
						Type: AdviceProcessPageCodes.FinalStructure,
					})
				),
				withLatestFrom(this.query.finalStructure$, this.fsService.formValue$),
				concatMap(([x, fs, formValue]) =>
					this.fsService
						.updateFinalStructure({
							...(!!formValue ? formValue : fs),
							document: { referenceId: x, value: `${this.fsFileName}.pdf` },
							isCompleted: true,
						})
						.pipe(mapTo(x))
				),
				mergeMap((x) =>
					this.loatDocumentService.updateAdviceProcess(
						+x,
						AdviceProcessPageCodes.FinalStructure
					)
				),
				withLatestFrom(this.fsService.finalStructure$),
				map(([, x]) => x),
				tap((x) => {
					if (!!x.cRTId) {
						this.ngZone.run(() =>
							this.router.navigate(
								this.routeService.customerAdviceProcess(
									+this.route.snapshot.paramMap.get('clientId'),
									+this.route.snapshot.paramMap.get('adviceProcessId')
								)
							)
						);
					}
				}),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
					this.crtService.setHasFormChanges(false);
				}),
				take(1)
			)
			.subscribe();
	}

	completeApplicationStage() {
		of(true)
			.pipe(
				withLatestFrom(
					this.caService.formValue$,
					this.caService.clientAcceptance$
				),
				filter(([, x, ca]) => !!x || !!ca),
				tap(() => this.crtLoad.emit(true)),
				map(([, x, ca]) => ClientAcceptanceMapper.mapToUpsert(!!x ? x : ca)),
				mergeMap((x) =>
					!x.cRTId
						? this.caService.addClientAcceptance(x).pipe(mapTo(x))
						: this.caService.updateClientAcceptance(x).pipe(mapTo(x))
				),
				tap((x) => {
					this.updateAdviceProcessPageCompleted(
						AdviceProcessPageCodes.AuthorityToProceed
					);
					this.ngZone.run(() =>
						this.router.navigate(
							this.routeService.customerAdviceProcess(
								+this.route.snapshot.paramMap.get('clientId'),
								+this.route.snapshot.paramMap.get('adviceProcessId')
							)
						)
					);
				}),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
	}

	saveToFileATP() {
		// Save and Download the document of client acceptance
		const alternativeStructure$ = this.query.caAlternativeStructure$;
		this.isLoading = true;

		const proposedInsurance$ = iif(
			() => !!this.soaQuery.getValue().proposedInsurance,
			this.soaService.proposedInsurance$,
			this.soaService.getProposedInsurance(
				this.query.getValue().clientAcceptance.sOA
			)
		);

		proposedInsurance$
			.pipe(
				withLatestFrom(
					this.query.clientAcceptance$,
					alternativeStructure$,
					this.query.lifeAssured$
				),
				map(([pi, ca, as, lifeAssuredList]) => {
					const data = this.query.getValue().clientAcceptanceFormValue;

					const signatures = data?.signatures?.map(
						(x) =>
							({
								name: x.value,
								date: MomentUtil.formatToDisplayDate(
									MomentUtil.DateStringToMoment(x.dateValue)
								),
								image: x?.signature,
							} as SignatureModel)
					);
					const signatureContent = signatureTemplate(signatures ?? []);

					const Template = soaTemplate({
						isProposedInsurance:
							ca.presentedSOA && ca.presentedSOA > 0 ? true : false,
						proposedInsurance: ca.presentedSOA && ca.presentedSOA > 0 ? pi : as,
						peoples: lifeAssuredList,
						caSettings:
							this.query.getValue().clientAcceptanceSettings
								?.clientAcceptanceWording,
						signatures,
					});

					// Control styles in _wysiwyg.css [."atp-pdf-file"]
					return `<div class="atp-pdf-file">${Template}${signatureContent}</div>`;
				}),
				mergeMap((x) =>
					this.crtDocService.downloadDocumentPDF(x, this.fsFileName)
				),
				mergeMap((x) => convertUtil.convertToBase64(x)),
				concatMap((pdfTemplateWithSignature) =>
					this.crtDocService.saveDocument({
						ReferenceId: this.query.getValue().adviceProcessId,
						CustomerId: this.query.getValue().primaryClient?.customerID,
						Document: pdfTemplateWithSignature,
						FileName: `${this.atpFileName}.pdf`,
						DocumentType: ServicesCodes.LR,
						Type: AdviceProcessPageCodes.AuthorityToProceed,
					})
				),
				concatMap((x) =>
					this.loatDocumentService.updateAdviceProcess(
						x,
						AdviceProcessPageCodes.AuthorityToProceed
					)
				),
				tap(
					() => {
						this.ngZone.run(() =>
							this.loggerService.Success(
								{},
								logMessage.oat.lr.authorityToProceed.success.save
							)
						);
					},
					(err) => {
						this.ngZone.run(() =>
							this.loggerService.Warning(err, logMessage.shared.general.error)
						);
					}
				),
				concatMap((x) =>
					this.saveSOADocument(this.query.getValue().clientAcceptance.sOA)
				),
				take(1),
				finalize(() => {
					this.isLoading = false;
				})
			)
			.subscribe();
	}

	confirmSaveATP() {
		const adviceProcessCA = this.query
			.getValue()
			?.adviceProcess?.documents?.find(
				(x) => x.field === AdviceProcessDocumentField.AuthorityToProceed
			);
		if (adviceProcessCA?.value?.documentID > 0) {
			const confirm = new Observable((obs) => {
				this.saveToFileATP();
				obs.next();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: '',
				message: `A document is already uploaded for Authority to Proceed,`,
				subMessage: adviceProcessCA?.value?.fileName,
				secondaryMessage: `Do you want to replace this?`,
				confirm$: confirm,
				decline$: decline,
				isAcceptBtn: true,
			};
			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.saveToFileATP();
		}
	}

	getCADDocument(): Observable<string> {
		const alternativeStructure$: Observable<CurrentInsuranceState[]> =
			this.query.caAlternativeStructure$;

		const proposedInsurance$ = iif(
			() => !!this.soaQuery.getValue().proposedInsurance,
			this.soaService.proposedInsurance$,
			this.soaService.getProposedInsurance(
				this.query.getValue().clientAcceptance.sOA
			)
		);

		return proposedInsurance$.pipe(
			withLatestFrom(
				this.query.clientAcceptance$,
				alternativeStructure$,
				this.query.lifeAssured$,
				this.query.people$
			),
			take(1),
			map(([pi, ca, as, lifeAssuredList, peoples]) => {
				const Template = soaTemplate({
					isProposedInsurance:
						ca.presentedSOA && ca.presentedSOA > 0 ? true : false,
					proposedInsurance: ca.presentedSOA && ca.presentedSOA > 0 ? pi : as,
					peoples: lifeAssuredList,
					caSettings:
						this.query.getValue().clientAcceptanceSettings
							?.clientAcceptanceWording,
					signatures: peoples,
				});

				const data = this.query.getValue().clientAcceptanceFormValue;

				const signatures = data?.signatures?.map(
					(x) =>
						({
							name: x.value,
							date: MomentUtil.formatToDisplayDate(
								MomentUtil.DateStringToMoment(x.dateValue)
							),
							image: x?.signature,
						} as SignatureModel)
				);
				const signatureContent = signatureTemplate(signatures ?? []);

				// Control styles in _wysiwyg.css [."atp-pdf-file"]
				return `<div class="atp-pdf-file">${Template}${signatureContent}</div>`;
			})
		);
	}

	renderPdfDownload = (content: string, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, pdfOptions?.FileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	// Download the document of Authority to Proceed
	downloadCADDocument(): void {
		this.isLoading = true;
		this.getCADDocument()
			.pipe(
				tap(() => (this.isDownloadingATP = true)),
				concatMap((template: string) => {
					return this.renderPdfDownload(
						`<div class="froala-template-file pdf-portrait">${template}</div>`,
						{
							DPI: '120',
							FileName: this.atpFileName,
						}
					);
				}),
				tap(
					() => {
						this.ngZone.run(() =>
							this.loggerService.Success(
								{},
								logMessage.oat.lr.authorityToProceed.success.download
							)
						);
					},
					(err) => {
						this.ngZone.run(() =>
							this.loggerService.Warning(err, logMessage.shared.general.error)
						);
					}
				),
				finalize(() => {
					this.isDownloadingATP = false;
					this.isLoading = false;
				}),
				take(1)
			)
			.subscribe();
	}

	convertToBase64 = (
		file: File
	): Observable<{ content: string; filename: string }> =>
		new Observable((obs) => {
			const reader = new FileReader();
			reader.onload = () =>
				obs.next({
					content: reader.result as string,
					filename: file.name,
				});
			reader.onloadend = () => obs.complete();
			return reader.readAsDataURL(file);
		});

	uploadDocument(currentTab?: string): void {
		if (currentTab === AdviceProcessPageCodes.AuthorityToProceed) {
			const adviceProcessCA: AdviceProcessDocumentState = this.query
				.getValue()
				?.adviceProcess?.documents?.find(
					(x) => x.field === AdviceProcessDocumentField.AuthorityToProceed
				);
			if (adviceProcessCA?.value?.documentID > 0) {
				const confirm = new Observable((obs) => {
					this.upload(AdviceProcessPageCodes.AuthorityToProceed);
					obs.next();
					obs.complete();
				});

				const decline = new Observable((obs: Observer<any>) => {
					obs.complete();
				});

				const initState = {
					header: '',
					message: `A document is already uploaded for Authority to Proceed,`,
					subMessage: adviceProcessCA?.value?.fileName,
					secondaryMessage: `Do you want to replace this?`,
					confirm$: confirm,
					decline$: decline,
					isAcceptBtn: true,
				};
				this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
					class: 'modal-dialog-centered modal-dialog',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				});
			} else {
				this.upload(AdviceProcessPageCodes.AuthorityToProceed);
			}
		}
		if (currentTab === AdviceProcessPageCodes.FinalStructure) {
			if (this.query.getValue()?.finalStructure?.document?.referenceId > 0) {
				const confirm = new Observable((obs) => {
					this.upload(AdviceProcessPageCodes.FinalStructure);
					obs.next();
					obs.complete();
				});

				const decline = new Observable((obs: Observer<any>) => {
					obs.complete();
				});

				const initState = {
					header: '',
					message: `A document is already uploaded for Final Structure,`,
					subMessage: this.query.getValue()?.clientAcceptance?.document?.value,
					secondaryMessage: `Do you want to replace this?`,
					confirm$: confirm,
					decline$: decline,
					isAcceptBtn: true,
				};
				this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
					class: 'modal-dialog-centered modal-dialog',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				});
			} else {
				this.upload(AdviceProcessPageCodes.FinalStructure);
			}
		}
	}

	upload(type?: string) {
		const upload = (req: FileList) => {
			const filename = convertUtil.imageFilenameToPDF(req[0]);
			return new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() => req[0].type.includes('image/'),
						this.toPdfService.fromImage(req[0]),
						this.convertToBase64(x[0])
					)
				),
				map((base64Image) => ({
					ReferenceId: this.query.getValue().adviceProcessId,
					CustomerId: this.query.getValue().primaryClient?.customerID,
					Document: `${((base64Image.content as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`,
					FileName: filename,
					DocumentType: ServicesCodes.LR,
					Type: Object.values(AdviceProcessPageCodes).includes(
						type as AdviceProcessPageCodes
					)
						? type
						: '',
					DocumentTypeCode: ServicesCodes.LR,
				})),
				concatMap((x) => this.crtDocService.saveDocument(x)),
				concatMap((x) => {
					if (type === AdviceProcessPageCodes.AuthorityToProceed) {
						const ca = {
							...this.query.getValue()?.clientAcceptance,
							document: { referenceId: x, value: filename },
						};
						return this.caService
							.updateClientAcceptance(ca)
							.pipe(
								concatMap(() =>
									this.loatDocumentService.updateAdviceProcess(x, type)
								)
							);
					}
					const fs = {
						...this.query.getValue()?.finalStructure,
						document: { referenceId: x, value: filename },
					};
					return this.fsService.updateFinalStructure(fs);
				})
			);
		};
		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: `Upload ${
				AdviceProcessPageNamesByCode[
					type as keyof typeof AdviceProcessPageNamesByCode
				]
			} Document`,
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	onClickEmailFS() {
		const fs = this.query.getValue().finalStructure;
		const proposedInsuranceList = this.query.getValue().fsProposedInsurance;

		this.fsCrtSettingsService
			.getFinalStructure(0, AdviceProcessSectionCodes.FinalStructure)
			.pipe(
				take(1),
				tap(() => {
					const initState = {
						header: 'Email Client',
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.finalStructureSettings$,
						peopleDropdown: this.peopleDropdown$,
						attachments: [
							{
								fileName: `${this.fsFileName}.pdf`,
								generateContentCallback$: this.generateAttachment(),
								newPdfOptions: {
									DPI: '120',
									FileName: this.fsFileName,
								},
							},
						],
						offerTerms:
							!isEmpty(fs?.underwritingOutcome) &&
							fs?.underwritingOutcome?.some(
								(item) => item.Value === 'Offer of Terms'
							)
								? proposedInsuranceList.map(
										(insurance) => insurance.documentTerm
								  )
								: null,
						downloadOfferTermsFn$: this.downloadOfferTerms,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						adviceProcess: this.adviceProcess,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.LR,
						documentInfo: {
							documentType: ServicesCodes.LR,
							type: DocumentTypes.soaDocument,
							referenceId: this.query.getValue().adviceProcessId,
							customerId: this.query.getValue().primaryClient?.customerID,
						},
					};
					this.modalService.show(EmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				})
			)
			.subscribe();
	}

	onClickPreviewFsDocument() {
		this.crtLoad.emit(true);
		of(true)
			.pipe(
				withLatestFrom(this.query.finalStructure$, this.fsService.formValue$),
				filter(([, fs, x]) => {
					return !!fs || !!x;
				}),
				concatMap(() =>
					this.generateAttachment().pipe(
						mergeMap((content) =>
							this.crtDocService.downloadDocumentPDF(content, this.fsFileName)
						),
						mergeMap((content) => convertUtil.convertToBase64(content))
					)
				),
				delay(300),
				tap((res) => {
					this.ngZone.run(() => {
						const blob = convertUtil.base64toBlobPdf(res);
						const blobUrl = URL.createObjectURL(blob);
						if (res) {
							const pdfUrl = this.router.serializeUrl(
								this.router.createUrlTree(
									this.routeService.viewPdfBlob({ blobUrl: blobUrl })
								)
							);
							window.open(pdfUrl, '_blank');
						}
					});
				}),
				finalize(() => {
					this.crtLoad.emit(false);
					this.isPause = false;
				}),
				take(1)
			)
			.subscribe();
	}

	onClickEmailAP() {
		this.apCrtSettingsService
			.getAuthorityToProceed(0, 'ATP')
			.pipe(
				take(1),
				tap(() => {
					const initState = {
						header: 'Email Client',
						sendEmailFn$: this.sendToAPCARecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.authorityToProceedSettings$,
						peopleDropdown: this.peopleDropdown$,
						attachments: [
							{
								fileName: `${this.atpFileName}.pdf`,
								generateContentCallback$: this.getCADDocument(),
								newPdfOptions: {
									DPI: '120',
									FileName: this.atpFileName,
								},
							},
						],
						adviceProcess: this.adviceProcess,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.LR,
						documentInfo: {
							documentType: ServicesCodes.LR,
							type: DocumentTypes.soaDocument,
							referenceId: this.query.getValue().adviceProcessId,
							customerId: this.query.getValue().primaryClient?.customerID,
						},
					};
					this.modalService.show(AtpEmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				})
			)
			.subscribe();
	}

	generateAttachment() {
		return this.existingPolicyStructure.data$.pipe(
			withLatestFrom(
				this.query.lifeAssured$,
				this.query.fsProposedInsurance$,
				this.query.finalStructure$,
				this.logoUrl$,
				this.fsService.formValue$
			),
			map(
				([
					existingPolicyStructure,
					lifeAssuredList,
					fsInsurance,
					finalStructure,
					logo,
					fsFormValue,
				]) => {
					const fs = !!fsFormValue ? fsFormValue : finalStructure;
					const doc1 = this.generateFinalisedStructure(
						fs,
						fsInsurance,
						lifeAssuredList,
						logo
					);
					const doc2 = this.generateExistingInsurance(
						existingPolicyStructure,
						lifeAssuredList,
						logo
					);

					return `<div class="finalstructure-pdf-file">${doc1}${doc2}</div>`;
				}
			)
		);
	}

	generateExistingInsurance(ci, lifeAssuredList, logo): string {
		const HEADER = documentStaticConf.emailDocumentHeader;
		const LABEL = documentStaticConf.emailDocumentLabel1;
		const CURRENTINSURANCETABLES = [];
		const BR = documentStaticConf.brTag;
		const ciGroupByLifeAssured =
			FsDocMapper.groupByLifeAssured(ci, lifeAssuredList) || [];

		ciGroupByLifeAssured?.forEach((i) => {
			const td = tdExistingFinalStructureTemplate(i?.lifeAssuredList);
			const ciTable = tableExistingFinalStructureTemplate(
				`<tr style="border: 1px solid #DDD; text-align: left; padding: 3px;">${td}</tr>`
			);
			const lifeAssuredName = `<p class="tap-text-primary" style="font-size: 14px;">Life Assured: ${i?.lifeAssuredName}</p>`;

			CURRENTINSURANCETABLES?.push(`${lifeAssuredName}${ciTable}${BR}${BR}`);
		});

		const TOTALPOLICYPREMIUMTABLE = tableCiPolicyFeeAndPremium(
			FsDocMapper.getCiPolicyFeeAndPremium(ci)
		);

		let noteList = [];
		ci.forEach((insurance) => {
			if (insurance.additionalNotes) {
				noteList.push(`
				<p>
					<span style="font-size: 14px;"><strong>${
						insurance?.provider || ''
					} - </strong>${
					insurance.additionalNotes?.replace(/\n|\t/g, '<br />') || ''
				}</span>
				</p>
			`);
			}
		});

		const FINALNOTES = `
			<p class="tap-text-primary" style="font-size: 14px;">Notes:</p>
			${noteList?.join('').toString()}
		`;

		return (
			BR +
			HEADER +
			LABEL +
			CURRENTINSURANCETABLES?.join('').toString() +
			FINALNOTES +
			TOTALPOLICYPREMIUMTABLE
		);
	}

	generateFinalisedStructure(
		finalStructure,
		fsInsurance,
		lifeAssuredList,
		logo
	): string {
		const FINALISEDHEADER = documentStaticConf.emailFinalisedHeader;
		const FINALISEDLABEL = documentStaticConf.emailFinalisedLabel1;
		const FINALISEDLABEL2 = documentStaticConf.emailFinalisedLabel2;
		const LOGO = `<p><img src="${logo}" style="float: right; text-align: right; display: inline-block; width: 150px;"></p>`;
		const FINALIZEDINSURANCETABLES = [];
		const BR = documentStaticConf.brTag;
		const HR = documentStaticConf.hrTag;

		const fsGroupByLifeAssured =
			FsDocMapper.groupByLifeAssured(fsInsurance, lifeAssuredList) || [];

		let paragraphSRNMD = '';
		const STANDARD_RATES_NMD = 'Standard Rates - No Medical Disclosure';
		const filteredSRNMDList = finalStructure?.underwritingOutcome.filter(
			(uwoItem) =>
				uwoItem.Value === STANDARD_RATES_NMD ||
				uwoItem.value === STANDARD_RATES_NMD
		);

		if (!isEmpty(filteredSRNMDList) && !isEmpty(fsGroupByLifeAssured)) {
			const LANAMES = [];
			filteredSRNMDList.forEach((firstItem) => {
				const matchedItem = fsGroupByLifeAssured.find(
					(secondItem) =>
						secondItem.lifeAssured === firstItem.LifeAssured ||
						secondItem.lifeAssured === firstItem.lifeAssured
				);
				if (matchedItem) {
					LANAMES.push(matchedItem.lifeAssuredName);
				}
			});

			// @ts-ignore-next
			const formatter = new Intl.ListFormat('en', {
				style: 'long',
				type: 'conjunction',
			});
			paragraphSRNMD = documentStaticConf.paragraphSRNMD(
				formatter.format(LANAMES)
			);
		}

		let noteList = [];

		fsInsurance.forEach((insurance) => {
			if (insurance.additionalNotes) {
				noteList.push(`
				<p>
					<span style="font-size: 14px;"><strong>${
						insurance?.provider || ''
					} - </strong>${
					insurance?.additionalNotes?.replace(/\n|\t/g, '<br />') || ''
				}</span>
				</p>
			`);
			}
		});

		const FINALNOTES = `
			<p class="tap-text-primary" style="font-size: 14px;">Notes:</p>
			${noteList?.join('').toString()}
		`;

		fsGroupByLifeAssured?.forEach((i) => {
			const td = tdFinalizedStructureTemplate(i?.lifeAssuredList);
			const fsTable = tableFinalizedStructureTemplate(
				`<tr style="border: 1px solid #DDD; text-align: left; padding: 3px;">${td}</tr>`
			);
			const lifeAssuredName = `<p class="tap-text-primary" style="font-size: 14px;">Life Assured: ${i?.lifeAssuredName}</p>`;

			FINALIZEDINSURANCETABLES?.push(`${lifeAssuredName}${fsTable}${BR}${BR}`);
		});

		const TOTALPOLICYPREMIUMTABLE = tableFsPolicyFeeAndPremium(
			FsDocMapper.getFsPolicyFeeAndPremium(
				fsInsurance,
				finalStructure?.paymentFrequency
			)
		);

		return (
			FINALISEDHEADER +
			FINALISEDLABEL +
			paragraphSRNMD +
			FINALISEDLABEL2 +
			FINALIZEDINSURANCETABLES?.join('').toString() +
			FINALNOTES +
			TOTALPOLICYPREMIUMTABLE +
			HR
		);
	}

	saveCrtEmail(data) {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(data),
					this.peopleService.updatePeople(data)
				)
			)
		);
	}

	sendToAPCARecipients = (data) => {
		return this.emailService
			.sendToRecipients(
				data,
				EmailTypes.AuthorityToProceed,
				this.query.getValue()?.clientAcceptance?.cRTId,
				this.mergeTags$
			)
			.pipe(finalize(() => this.saveToFileATP()));
	};

	sendToRecipients = (data) => {
		return this.emailService
			.sendToRecipients(
				data,
				EmailTypes.FinalStructure,
				this.query.getValue()?.finalStructure?.cRTId,
				filterNonEmailMergeTags(this.mergeTags$)
			)
			.pipe(
				withLatestFrom(this.query.finalStructure$, this.fsService.formValue$),
				mergeMap(([x, y, z]) => {
					const fsData = !!z
						? { ...z, isEmailSent: true }
						: { ...y, isEmailSent: true };
					return !y.isEmailSent
						? this.fsService.updateFinalStructure(fsData).pipe(mapTo(x))
						: of(x);
				})
			);
	};

	downloadOfferTerms = (referenceId) => {
		const proposedInsuranceList = this.query.getValue().fsProposedInsurance;
		const term = proposedInsuranceList
			?.map((insurance) => insurance.documentTerm)
			?.find((term) => term.referenceId === referenceId);
		const fileName = term ? term.value : 'Document';
		return this.soaService.downloadFile(referenceId).pipe(
			tap((x) => {
				const name = fileName;
				const a = this.renderer.createElement('a');
				this.renderer.setStyle(a, 'display', 'none');
				const url = window.URL.createObjectURL(x);
				this.renderer.setAttribute(a, 'href', url);
				this.renderer.setAttribute(a, 'download', name);
				a.click();
				window.URL.revokeObjectURL(url);
			}),
			take(1)
		);
	};

	findCurrentInsurance = (id: number) => {
		const ppl = (this.query.getValue().people ?? [])?.find(
			(x) => x.customerId === id
		);
		const curIns = (this.query.getValue().currentInsurances ?? [])
			?.filter((x) =>
				x.lifeAssuredList.some((l) => l.lifeAssured === ppl?.customerId)
			)
			?.map((c) => ({
				...c,
				lifeAssuredList: c.lifeAssuredList?.filter(
					(la) => la.lifeAssured === ppl?.customerId
				),
			}));
		return curIns ?? [];
	};

	/**
	 * Check statement of advice if it can proceed. Same checking used in Authority to proceed guard
	 *
	 * @param callback
	 * @returns
	 */
	checkStatementOfAdvice(callback: Function, clickedNext: boolean) {
		return this.query
			.select((state) => state.adviceProcess)
			.pipe(
				take(1),
				map((x) =>
					x?.documents?.some(
						(val) => val.field === AdviceProcessDocumentField.SOA && !!val.value
					)
				),
				concatMap((hasDocument) => {
					return this.soaQuery.finalizedSOA$.pipe(
						take(1),
						tap((x) => {
							if (!hasDocument && clickedNext) {
								this.loggerService.Warning(
									{},
									logMessage.oat.lr.soa.warning.minimum
								);
							}
							if (callback && (hasDocument || (!!x && x?.length > 0))) {
								callback();
							}
						})
					);
				})
			)
			.subscribe();
	}

	fsExist(fs) {
		if (!!fs) {
			return false;
		}

		return true;
	}

	clearDataFromState() {
		// LOAT State
		this.crtService.clear();
		this.riskAnalysisService.clearData();
		this.lifeService.clearData();
		this.tpdService.clearData();
		this.disabilityService.clearData();
		this.medicalService.clearData();
		this.crtNoteService.clearData();
		this.soaService.clearData();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
