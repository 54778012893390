import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ApiVsSubmittedYearStatsState, ApiVsSubmittedYearStatsStore } from './asry.store';

/**
 * Query for API Vs Submitted Stats widget data for Rollling 12 Months (Year)
 */
@Injectable()
export class ApiVsSubmittedYearStatsQuery extends Query<ApiVsSubmittedYearStatsState> {

  /** API Vs Submitted Stats data. */
  ApiVsSubmittedYearStatData$ = this.select(state =>
    state.ApiVsSubmittedYearStats
  );

  /** Indicator if ApiVsSubmittedYearStat is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for ApiVsSubmittedYearStat pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: ApiVsSubmittedYearStatsStore) {
    super(store);
  }
}
