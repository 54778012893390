<form [formGroup]='form'>
  <div class="container-fluid px-0">

    <div class="row tap-container">
      <div class="col mb-4"></div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-4 col-md-4 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              Email Type
            </label>
          </div>
          <div class="col-lg-8 col-md-8 px-0">
            <app-chips formControlName="emailCode" [choices]="emailType" textboxClass="theme-crm-field">
            </app-chips>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-md-8 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-1 col-md-2 px-0">
            <label for="" class="position-relative">
              Date Range
            </label>
          </div>

          <div class='col-6 col-lg-3 col-md-3 pr-2 pl-0'>
            <app-date-input ngProjectAs="input" textboxClass="theme-crm-field-disabled" formControlName="dateTargetMin"
              placeholder="dd/mm/yyyy" textboxId="DateTargetMin"></app-date-input>
          </div>
          <div class='col-6 col-lg-3 col-md-3 px-0'>
            <app-date-input ngProjectAs="input" textboxClass="theme-crm-field-disabled" formControlName="dateTargetMax"
              placeholder="dd/mm/yyyy" textboxId="DateTargetMax"></app-date-input>
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-4 col-md-4 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              Email Mode
            </label>
          </div>
          <div class="col-lg-8 col-md-8 px-0">
            <app-chips formControlName="emailMode" [choices]="emailModeList" textboxClass="theme-crm-field">
            </app-chips>
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-4 col-md-4 pr-md-0">
        <div class="form-group form-inline row mb-1">
        </div>
      </div>
      <div class="col-lg-8 col-md-8 pr-md-0">
        <div class="form-group form-inline row mb-4">

          <div class="col-lg-7 col-md-0 px-0 pr-md-0">
            <div class="col-lg-12 col-md-8 px-0 text-right mb-3">

              <button class="btn btn-light mr-2" id="" (click)="clearForm()">
                Clear
              </button>

              <button class="btn btn-primary mr-2" *ngIf="!isSaving" (click)="getBtnClick()">
                Search
              </button>
              <app-manual-loader-mini class="mr-2" *ngIf="isSaving" [isLoading]="isSaving"></app-manual-loader-mini>

              <ng-container *appRestrictTo="['BEHX']">
                <button class="btn btn-primary" *ngIf="!isExport" (click)="export()">
                  Export
                </button>
              </ng-container>
              <app-manual-loader-mini *ngIf="isExport" [isLoading]="isExport"></app-manual-loader-mini>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form>
