import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
	combineLatest,
	delay,
	filter,
	map,
	startWith,
	takeUntil
} from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/base/component-base';
import { HorizontalBarChartComponent } from 'src/app/shared/horizontal-bar-chart/horizontal-bar-chart.component';
import { DateRange } from '../../../../../shared/date-range/date-range.component';
import MomentUtil from '../../../../../util/moment.util';
import { numUtil } from '../../../../../util/util';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { DashboardQuery } from '../../state/dashboard.query';
import { EBCQuery } from './state/ebc.query';
import { EBCService } from './state/ebc.service';

@Component({
	selector: 'app-ebc',
	templateUrl: './ebc.component.html',
	styleUrls: ['./ebc.component.scss'],
})
export class EbcComponent extends ComponentBase implements OnInit, OnDestroy {
	public static widgetCode = 'ebc';
	public static widgetName = 'E.B. Conversion';
	public static sizeX = 480;
	public static sizeY = 480;
	public static minSizeX = 480;
	public static minSizeY = 480;
	public static maxSizeX = 480;
	public static maxSizeY = 480;

	private subs: Subscription[] = [];

	widgetCode = EbcComponent.widgetCode;

	ebConversion$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x?.find((i) => i?.Type === 'LR')),
		map((x) => ({
			...x,
			Conversion: Math.round(x?.Conversion),
		}))
	);
	kiwisaverConversion$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x?.find((i) => i?.Type === 'K')),
		map((x) => ({
			...x,
			Conversion: Math.round(x?.Conversion),
		}))
	);
	blanketConversion$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x?.find((i) => i?.Type === 'FG')),
		map((x) => ({
			...x,
			Conversion: Math.round(x?.Conversion),
		}))
	);

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.isLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.error$;

	/** form control for date range.
	 * Should default to 1 week behind up to now.
	 */
	dateControl: UntypedFormControl = new UntypedFormControl({
		min: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().subtract(7, 'months')
		),
		max: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().subtract(1, 'months')
		),
	} as DateRange);

	@ViewChild(HorizontalBarChartComponent)
	hbarChart: HorizontalBarChartComponent;

	constructor(
		private service: EBCService,
		private query: EBCQuery,
		private dashboardQuery: DashboardQuery,
		private dashboard: DashboardComponent
	) {
		super();
	}

	ngOnInit() {
		this.subs.push(
			this.dashboardQuery.adviserFilter$
				.pipe(
					filter((x) => x.length > 0),
					combineLatest(
						this.dateControl.valueChanges.pipe(
							startWith(this.dateControl.value)
						)
					),
					takeUntil(this.onDestroy$)
				)
				.subscribe(([advisers, dateRange]) => {
					this.service.GetEBConversions({
						advisers,
						dateRangeMin: dateRange.min,
						dateRangeMax: dateRange.max,
					});
				})
		);

		this.subs.push(
			this.dashboard.gridChangeEvent.pipe(delay(200)).subscribe((res) => {
				if (res === EbcComponent.widgetCode) {
					this.hbarChart.refresh();
				}
			})
		);
	}

	fortmatToCount(num: number) {
		return numUtil.formatToCount(num);
	}

	ngOnDestroy() {
		this.subs?.forEach((x) => x.unsubscribe);
		this.subs = [];
		super.dispose();
	}
}
