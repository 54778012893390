import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../../../../../core/base/api.service';
import { AdviceProcessSectionCodes } from '../../../../../../../../../shared/models/advice-process/advice-process.model';
import { objectUtil } from '../../../../../../../../../util/util';
import { CrtMortgageQuery } from '../../../../../state/crt-mortgage.query';
import { CashDepositState } from './cash-deposit.model';
import { CashDepositQuery } from './cash-deposit.query';
import { CashDepositStore } from './cash-deposit.store';

@Injectable()
export class CashDepositService {
	cashDeposit$ = this.query.cashDeposit$;

	constructor(
		private api: ApiService,
		protected crtMortgageQuery: CrtMortgageQuery,
		protected store: CashDepositStore,
		protected query: CashDepositQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(parentCRTId: number) {
		this.store.setLoading(true);
		const endpoint = `crt/${AdviceProcessSectionCodes.Application}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.FundingRequired}`;

		return this.api.get<CashDepositState>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			tap((x) =>
				applyTransaction(() => {
					this.store.set([{ ...x, parentCRTId }]);
					this.store.setActive(parentCRTId);
				})
			),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(null))
		);
	}

	add(data) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.post(`crt`, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.update(data?.parentCRTId, { ...data, cRTId: +x });
				})
			),
			catchError(() => of(null))
		);
	}

	update(data) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.put(`crt/${data?.cRTId}`, body).pipe(
			tap(() =>
				applyTransaction(() => this.store.update(+data?.parentCRTId, data))
			),
			catchError(() => of(null))
		);
	}
}
