import { Query } from '@datorama/akita';
import { LeadSearchUiState, LeadSearchUiStore } from './lead-search-ui.store';
import { Injectable } from '@angular/core';

@Injectable()
export class LeadSearchUiQuery extends Query<LeadSearchUiState> {
	/** Indicator whether page is currently searching */
	isSearching$ = this.select(x => x.isSearching);
  /** Indicator whether table is selecting all */
	isSelectAll$ = this.select(x => x.isSelectAll);
	/** Indicator whether page is currently exporting */
	isExporting$ = this.select(x => x.isExporting);
	/** Indicator whether column settings window is open */
	columnFormPopupOpen$ = this.select(x => x.columnFormPopupOpen);
	/** Indicator whether column settings are being saved */
	isColumnSaving$ = this.select(x => x.isColumnSaving);

	/** Current column sorted */
	propSort$ = this.select(x => x.propSort);
	/** Direction of sort */
	sort$ = this.select(x => x.sort);

	currentSort$ = this.select(x => x.currentSort);

	constructor(protected leadSearchUiStore: LeadSearchUiStore) {
		super(leadSearchUiStore);
	}
}
