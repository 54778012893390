import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { complement, either, isNil, isEmpty } from 'ramda';
import {
	BehaviorSubject,
	combineLatest,
	iif,
	Observable,
	Observer,
	of,
	Subject,
} from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	first,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { CompanyDetailsState } from 'src/app/shared/models/client-review-template/company/company-details';
import { DependentDetailsState } from 'src/app/shared/models/client-review-template/dependent/dependent-details';
import { PeopleDetailsState } from 'src/app/shared/models/client-review-template/people/people-details.model';
import { TrustDetailsState } from 'src/app/shared/models/client-review-template/trust/trust-details';
import { RelationshipTypes } from 'src/app/shared/models/_general/client.model';
import { LoggerService } from '../../../../core/logger/logger.service';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { DropdownValue } from '../../../../modules/dropdown/model/dropdownValue';
import { PeopleModalComponent } from '../../../../shared/modal/crt/fact-find/people/people-modal.component';
import { DeleteModalComponent } from '../../../../shared/modal/delete-modal/delete-modal.component';
import { PeopleState } from '../../../../shared/models/client-review-template/people/people.model';
import { ThemeConfig } from '../../../../shared/models/_general/config';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { ClientReviewTemplateService } from '../states/client-review-template.service';
import { ClientReviewTemplateStore } from '../states/client-review-template.store';
import { HistoryService } from '../states/history/history.service';
import {
	BLANK_PEOPLE_ID,
	PeopleService,
} from '../states/people/people.service';
import { CriticalIllnessService } from '../states/risk-analysis/critical-illness/critical-illness.service';
import { DisabilityService } from '../states/risk-analysis/disability/disability.service';
import { GoalsService } from '../states/risk-analysis/goals/goals.service';
import { LifeService } from '../states/risk-analysis/life/life.service';
import { RiskAnalysisMedicalService } from '../states/risk-analysis/medical/medical.service';
import { RiskProfileService } from '../states/risk-analysis/risk-profile/risk-profile.service';
import { TpdService } from '../states/risk-analysis/tpd/tpd.service';
import { peopleToViewDisplayValueUtil } from '../util/mapViewDisplayValue.util';

declare var $: any;

@Component({
	selector: 'app-people',
	templateUrl: './people.component.html',
	styleUrls: ['./people.component.scss'],
})
export class PeopleComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();
	themeConfig$ = this.businessConfigQuery.themeConfig$;
	primaryColor: string;

	isPeopleLoading$ = this.query.isPeopleLoading$;
	isDependantsLoading$ = this.query.isDependantLoading$;
	isTrustsLoading$ = this.query.isTrustLoading$;
	isCompanyLoading$ = this.query.isCompanyLoading$;

	dropdownCodes: DropdownValue[];

	/**
	 * flag to determine if the people and peopleFromCRMAndCRTExceptChild$
	 * observable emits value
	 */
	peopleIsLoading = true;

	/**
	 * since deleting process takes time
	 * we will add a flag to disable add person button until the delete process finished
	 */
	isDeleting = false;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	transferedSCI: PeopleState[];
	linkedContacts: LinkedContactState[];

	elseMinusPeople = true;
	elseMinusDependents = true;
	elseMinusTrust = true;
	elseMinusCompany = true;

	SCR$ = this.service.SCR$;
	PCE$ = this.service.PCE$;
	SCTT$ = this.service.SCTT$;
	clientId = this.route.snapshot.paramMap.get('clientId');

	APCRTVD$ = this.service.APCRTVD$;
	APCRTG$ = this.service.APCRTG$;
	APCRTBP$ = this.service.APCRTBP$;
	APCRTYN$ = this.service.APCRTYN$;
	APCRTYNNA$ = this.service.APCRTYNNA$;

	isCompany$ = this.service.isCompany$;
	people$ = this.service.people$;
	dependents$ = this.service.dependents$;
	trusts$ = this.service.trusts$;
	company$ = this.service.company$;
	crtLoading: number;

	isAddNew = false;
	isLoading = false;
	isSaving = false;
	peopleList$ = new BehaviorSubject<ViewDisplayValue[]>(null);

	nameToolTip: string;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private businessConfigQuery: BusinessConfigQuery,
		private service: ClientReviewTemplateService,
		private peopleService: PeopleService,
		private query: ClientReviewTemplateQuery,
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
		private historyService: HistoryService,
		private loggerService: LoggerService,
		private crtservice: ClientReviewTemplateService,
		private lifeService: LifeService,
		private disabilityService: DisabilityService,
		private criticalService: CriticalIllnessService,
		private tpdService: TpdService,
		private medicalService: RiskAnalysisMedicalService,
		private riskProfileService: RiskProfileService,
		private goalService: GoalsService,
		protected clientReviewTemplateStore: ClientReviewTemplateStore
	) {}

	personInfo = (crtId: number) => this.peopleService.getPersonInfo(crtId);

	collapseFalse() {
		this.elseMinusPeople = false;
		this.elseMinusDependents = false;
		this.elseMinusTrust = false;
		this.elseMinusCompany = false;
	}

	ngOnInit() {
		combineLatest([
			this.query.transferedSCIList$,
			this.query.linkedContacts$
		])
			.pipe(
				tap(([transfered, linkedContacts]) => {
					this.transferedSCI = transfered;
					this.linkedContacts = linkedContacts;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.getPeopleList();
	}

	getPeopleList() {
		combineLatest(this.query.peopleFromCRMAndCRTExceptChildNotDeceased$, this.people$)
		.pipe(
				filter(([peopleFromCRMAndCRTExceptChildNotDeceased$, people]) => {
					return (
						// if the values are not null it means that they loaded
						Boolean(peopleFromCRMAndCRTExceptChildNotDeceased$) && Boolean(people)
					);
				}),
				map(([peopleFromCRMAndCRTExceptChild, people]) => {
					return [
						peopleFromCRMAndCRTExceptChild?.map((x) => {
							return {
								display: x.firstName
									? x.firstName.concat(' ', x.lastName)
									: x.name,
								value: x.customerID
									? x.customerID
									: x.customerId
									? x.customerId
									: null,
								isDefault: false,
							};
						}),
						people,
					];
				}),
				first(),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([peopleFromCRMAndCRTExceptChildDeceased]) => {
				this.peopleList$.next(
					peopleFromCRMAndCRTExceptChildDeceased as ViewDisplayValue[]
				);
				this.peopleIsLoading = false;
			});
	}

	buildForm() {
		this.form = this.fb.group({
			name: [''],
			birthDate: ['', Validators.required],
			age: [''],
		});
	}

	collapseMorePeople() {
		$('#collapsePeople').toggle();
		this.elseMinusPeople = false;
	}
	collapseLessPeople() {
		$('#collapsePeople').toggle();
		this.elseMinusPeople = true;
	}

	warnMsg = () => {
		const message = logMessage.oat.shared.factFind.people.warning.maxPeople;
		this.loggerService.Warning({}, message);
		throw new Error(message);
	};

	addPeople = (model: PeopleDetailsState) => {
		return new Observable<any>((obs) => {
			this.isSaving = true;
			obs.next(model);
			obs.complete();
		}).pipe(
			withLatestFrom(this.query.people$),
			concatMap(([peopleModel, people]) => {
				const count = people.filter((x) => x.cRTId > 0)?.length;
				return count >= 3 ? this.warnMsg() : of(peopleModel);
			}),
			catchError((err) => of(err)),
			filter((x) => !!x),
			concatMap((x) =>
				this.peopleService.addPeople(
					x,
					parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10)
				)
			),
			concatMap((data) => this.peopleService.getPersonInfo(+data)),
			tap((data) => this.peopleService.updatePeopleInfo(data)),
			concatMap(() => this.crtservice.getSecondaryClients(+this.clientId)),
			concatMap(() =>
				this.historyService.getBodyMeasures(
					parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10),
					true
				)
			),
			tap(() => {
				this.isAddNew = false;
				this.isSaving = false;
				this.cd.detectChanges();
			})
		);
	};

	addPeopleOrDependent = (model: PeopleDetailsState) =>
		new Observable<any>((obs) => {
			this.isSaving = true;
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => x?.relationship === RelationshipTypes.Child,
					this.addNewDependentFn$({
						dependent: x,
						adviceProcessId: parseInt(
							this.route.snapshot.paramMap.get('adviceProcessId'),
							10
						),
					}).pipe(
						withLatestFrom(this.people$),
						map(([, people]) => {
							this.removePerson(people.length - 1);
						})
					),
					this.addPeople(model)
				)
			)
		);

	change(e: any, i: number) {
		if (e.target.value === 'new') {
			const decline = new Observable((obs: Observer<any>) => {
				// tslint:disable-next-line: no-angle-bracket-type-assertion
				(<HTMLInputElement>(
					document.getElementById('addNewPersonDropdown-' + i)
				)).value = '';
				this.isSaving = false;
				this.cd.detectChanges();
				obs.complete();
			});

			const initState = {
				header: 'People Details',
				message: `LifeAssuredDetails`,
				scr$: this.SCR$,
				pce$: this.PCE$,
				apcrtvd$: this.APCRTVD$,
				apcrtg$: this.APCRTG$,
				hasCountry: true,
				isCompany$: this.query.isCompany$,
				savefn: this.addPeopleOrDependent,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(PeopleModalComponent, {
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.query.peopleFromCRMAndCRTExceptChild$
				.pipe(
					map((x) => x?.find((val) => val.customerID === +e.target.value)),
					mergeMap((x) =>
						this.people$.pipe(
							map((p) => {
								const age = (x.dateOfBirth && moment().diff(x.dateOfBirth || x.birthDate, 'years')) ?? 0;
								p[i] = {
									cRTId: BLANK_PEOPLE_ID,
									age: x.dateOfBirth || x.birthDate  ? (age < 0 ? 0 : age) : null,
									birthDate: x.dateOfBirth || x.birthDate,
									customerType: x.customerType,
									name:
										x.firstName && x.lastName
											? x.firstName.concat(' ', x.lastName)
											: null,
									relationship: x.relationship,
									customerId: x.customerID,
									title: x?.title
								};
							})
						)
					),
					concatMap(() => this.updatePeopleDd()),
					take(1)
				)
				.subscribe();
		}
	}

	updatePeople = (model: PeopleState) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.peopleService.updatePeople(x)),
			tap(() => {
				if (
					!this.isLinkedContact(+model?.customerID) &&
					+model?.customerID &&
					this.checkSCIByCustomerId(+model?.customerID)
				) {
					this.invalidSciWarning();
				}
				this.isAddNew = false;
				this.toggleLoader(+model?.cRTId, false);
				this.cd.detectChanges();
			}),
			concatMap(() =>
				iif(
					() => model.relationship === 'Child',
					this.peopleService.getPeople(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						'FPP',
						true
					),
					of(model)
				)
			),
			concatMap(() =>
				iif(
					() => model.relationship === 'Child',
					this.peopleService.getDependents(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						'FPD',
						true,
						true
					),
					of(model)
				)
			),
			concatMap(() =>
				iif(
					() => model.relationship === 'Child',
					this.crtservice.getSecondaryClients(this.clientId),
					of(model)
				)
			),
			mergeMap(() =>
				this.historyService.getBodyMeasures(+model?.adviceProcessId, true)
			)
		);

	addExistingPerson(p: PeopleState) {
		this.isSaving = true;
		this.query.peopleFromCRMAndCRTNoFilter$
			.pipe(
				tap(() => (this.isLoading = true)),
				map((x) => x?.find((c) => c.customerID === +p.customerId)),
				map((client) => {
					const adviceProcessId = parseInt(
						this.route.snapshot.paramMap.get('adviceProcessId'),
						10
					);
					const newClient = {
						adviceProcessId: 0,
						cRTId: 0,
						customerID: client.customerID,
						customerType: client.customerType,
						countryOfOrigin: client.countryOfOrigin,
						dateOfBirth: client.dateOfBirth || client.birthDate,
						email: client.email,
						employer: null,
						employment: client.employment,
						firstName: client.firstName,
						gender: client.gender
							? client.gender === 'F'
								? 'Female'
								: 'Male'
							: null,
						homePhone: client.homePhone,
						knownAs: client.knownAs,
						lastName: client.lastName,
						manual: 0,
						middleName: client.middleName,
						mobilePhone: client.mobile,
						occupation: client.occupation,
						putletRetails: null,
						physicalAddress: client.physicalAddress,
						relationship: client.relationship,
						sectionCode: AdviceProcessSectionCodes.People,
						workPhone: client.work,
						age: null,
						status: null,
						residencyStatus: client.residencyStatus,
						title: client?.title,
					};
					return { newClient, adviceProcessId };
				}),
				take(1),
				mergeMap((res) =>
					this.peopleService.addPeople(res.newClient, res.adviceProcessId)
				),
				tap(() => {
					this.isAddNew = false;
					this.isSaving = false;
					this.cd.detectChanges();
				}),
				concatMap(() =>
					this.historyService.getBodyMeasures(
						parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10),
						true
					)
				)
			)
			.subscribe();
	}

	editPerson(crtId: number) {
		this.toggleLoader(+crtId, true);
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
			this.toggleLoader(crtId, false);
		});
		this.personInfo(crtId)
			.pipe(take(1))
			.subscribe((x) => {
				const initState = {
					header: 'People Details',
					message: `LifeAssuredDetails`,
					scr$: this.SCR$,
					pce$: this.PCE$,
					apcrtvd$: this.APCRTVD$,
					apcrtg$: this.APCRTG$,
					hasCountry: true,
					personInfo: x,
					savefn: this.updatePeople,
					decline$: decline,
				};
				this.bsModalRef = this.modalService.show(PeopleModalComponent, {
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				});
			});
	}

	viewPerson(crtId: number) {
		this.toggleLoader(+crtId, true);
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
			this.toggleLoader(crtId, false);
		});
		this.personInfo(crtId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => {
				const initState = {
					header: 'People Details',
					message: `LifeAssuredDetails`,
					scr$: this.SCR$,
					pce$: this.PCE$,
					apcrtvd$: this.APCRTVD$,
					apcrtg$: this.APCRTG$,
					personInfo: x,
					hasCountry: true,
					// savefn: this.updatePeople,
					decline$: decline,
					viewMode: true,
				};
				this.bsModalRef = this.modalService.show(PeopleModalComponent, {
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				});
			});
	}

	addPerson() {
    this.isAddNew = true;
    combineLatest(this.query.peopleFromCRMAndCRTExceptChildNotDeceased$, this.people$)
    .pipe(
      first(([peopleFromCRMAndCRTExceptChildNotDeceased$, people]) => {
        return Boolean(peopleFromCRMAndCRTExceptChildNotDeceased$) && Boolean(people);
      })
    )
			.subscribe(([list, people]) => {
				if (!people.find((p) => p.cRTId === BLANK_PEOPLE_ID)) {
					this.peopleService.addBlankPeople();
					// if (list.length < 1) {
					//   this.change({ target: { value: 'new' } }, people.length);
					// }
					// this.cd.detectChanges();
				}
			});
	}

	checkSCIByCustomerId(id: number) {
		// Check if SCI, based on customerId
		const sci = this.transferedSCI?.filter((x) => +x?.customerId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	checkSCIByCrtId(id: number) {
		// Check if SCI, based on cRTId
		const sci = this.transferedSCI?.filter((x) => +x?.cRTId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	invalidSciWarning() {
		this.loggerService.Warning({}, logMessage.oat.shared.warning.invalidSci);
	}

	toggleLoader(cRTId: number, load: boolean) {
		this.crtLoading = cRTId && load ? +cRTId : null;
	}

	isCrtLoading(cRTId: number) {
		if (this.isAddNew && !this.isSaving) {
			return false;
		}
		if (this.isSaving && cRTId === BLANK_PEOPLE_ID) {
			return true;
		}
		return +this.crtLoading === +cRTId;
	}

	isLinkedContact(id: number) {
		return !!this.linkedContacts?.find((x) => +x?.customerID === +id);
	}

	deletePerson(id: number) {
		this.toggleLoader(id, true);

		const confirm = new Observable((obs) => {
			this.deleteItem(id);
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.toggleLoader(id, false);
			obs.complete();
		});

		const initState = {
			header: 'Delete Person',
			message: logMessage.oat.shared.factFind.delete,
			delete$: confirm,
			decline$: decline,
			confirmButton: 'OK',
			detachCloseIcon: false,
		};
		this.bsModalRef = this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteItem = (id: number) => {
		this.isDeleting = true;
		this.peopleService.setRefetchingAfterDelete(true);
		this.peopleService
			.deletePeople(id)
			.pipe(
				concatMap(() =>
					this.historyService.getMedical(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						true
					)
				),
				concatMap(() =>
					this.historyService.getFamily(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						true
					)
				),
				concatMap(() =>
					this.peopleService.getPeople(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						AdviceProcessSectionCodes.People,
						true
					)
				),
				concatMap(() => this.service.getSecondaryClients(+this.clientId)),
				concatMap(() => this.updatePeopleDd()),
				concatMap(() =>
					this.goalService.getGoals(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						AdviceProcessSectionCodes.Goals
					)
				),
				concatMap(() => this.lifeService.getLife()),
				concatMap(() => this.disabilityService.getDisabilities()),
				concatMap(() => this.tpdService.getTpd()),
				concatMap(() => this.medicalService.getMedical()),
				concatMap(() =>
					this.criticalService.getCriticalIllness(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						AdviceProcessSectionCodes.CriticalIllness
					)
				),
				concatMap(() =>
					this.riskProfileService.getRiskProfile(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						AdviceProcessSectionCodes.RiskProfile
					)
				),
				concatMap(() =>
					this.historyService.getBodyMeasures(
						parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10),
						true
					)
				),
				tap(() => {
					if (+id && this.checkSCIByCrtId(+id)) {
						this.invalidSciWarning();
					}
				}),
				finalize(() => {
					this.isAddNew = false;
					this.isLoading = false;
					this.isDeleting = false;
					this.peopleService.setRefetchingAfterDelete(false);
					this.getPeopleList();
					this.toggleLoader(id, false);
				}),
				take(1)
			)
			.subscribe();
	};

	updatePeopleDd = () =>
		this.query.peopleFromCRMAndCRTExceptChildNotDeceased$.pipe(
			map((x) => x?.filter((i) => +i?.isActive === 1)),
			mergeMap((x) => peopleToViewDisplayValueUtil(x)),
			tap((x) => this.peopleList$.next(x)),
			take(1)
		);

	removePerson(index: number) {
		this.people$
			.pipe(
				map((x) => x.splice(index, 1)),
				take(1)
			)
			.subscribe(() => {
				this.getPeopleList();
				this.isAddNew = false;
				this.cd.detectChanges();
			});
	}

	// Dependent
	addNewDependentFn$ = (req: { dependent: any; adviceProcessId: number }) =>
		of(req.dependent).pipe(
			withLatestFrom(this.query.people$),
			concatMap(([model, peeps]) => {
				const count = peeps?.filter((x) => x.cRTId > 0)?.length;
				return req.dependent?.relationship !== RelationshipTypes.Child &&
					count >= 3
					? this.warnMsg()
					: of(model);
			}),
			filter((x) => !!x),
			concatMap(() =>
				iif(
					() => req.dependent?.relationship !== RelationshipTypes.Child,
					this.addPeople({
						...req.dependent,
						SectionCode: AdviceProcessSectionCodes.People,
					}).pipe(
						mergeMap(() => this.crtservice.getSecondaryClients(+this.clientId)),
						concatMap(() =>
							this.historyService.getBodyMeasures(
								parseInt(
									this.route.snapshot.paramMap.get('adviceProcessId'),
									10
								),
								true
							)
						),
						concatMap(() =>
							this.peopleService.getDependents(
								req.adviceProcessId,
								AdviceProcessSectionCodes.Dependants,
								true
							)
						)
					),
					this.peopleService
						.addDependent(req.dependent, req.adviceProcessId)
						.pipe(
							concatMap((y) =>
								this.peopleService
									.getDependents(
										req.adviceProcessId,
										AdviceProcessSectionCodes.Dependants,
										true
									)
									.pipe(map(() => y))
							),
							concatMap(() =>
								this.crtservice.getSecondaryClients(this.clientId)
							)
						)
				)
			),
			tap(() => {
				this.isAddNew = false;
				this.isSaving = false;
				this.cd.detectChanges();
			})
		);

	addExistingDependentFn$ = (req: {
		dependent: DependentDetailsState;
		adviceProcessId: number;
	}) => this.peopleService.addDependent(req.dependent, req.adviceProcessId);

	updateDepentdentFn$ = (model: DependentDetailsState) =>
		of(model).pipe(
			withLatestFrom(this.query.people$),
			concatMap(([dependant, people]) => {
				const count = people?.filter((x) => x.cRTId > 0)?.length;
				return dependant?.relationship !== RelationshipTypes.Child && count >= 3
					? this.warnMsg()
					: of(model);
			}),
			filter((x) => !!x),
			concatMap(() => this.peopleService.updateDependent(model)),
			// tslint:disable-next-line: max-line-length
			concatMap(() =>
				iif(
					() => model.relationship !== 'Child',
					this.peopleService.getPeople(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						AdviceProcessSectionCodes.People,
						true
					),
					of(model)
				)
			),
			concatMap(() =>
				iif(
					() => model.relationship !== 'Child',
					this.peopleService.getDependents(
						+this.route.snapshot.paramMap.get('adviceProcessId'),
						AdviceProcessSectionCodes.Dependants,
						true
					),
					of(model)
				)
			),
			concatMap(() =>
				iif(
					() => model.relationship !== 'Child',
					this.crtservice.getSecondaryClients(this.clientId),
					of(model)
				)
			),
			mergeMap(() =>
				this.historyService.getBodyMeasures(+model?.adviceProcessId, true)
			),
			finalize(() => {
				this.peopleService.setDependentsIsLoading(false);
			})
		);

	deleteDepentdentFn$ = (id) => {
		this.peopleService.setRefetchingAfterDelete(true);
		return this.peopleService.deleteDependent(id).pipe(
			concatMap(() =>
				this.historyService.getMedical(
					+this.route.snapshot.paramMap.get('adviceProcessId'),
					true
				)
			),
			concatMap(() =>
				this.historyService.getFamily(
					+this.route.snapshot.paramMap.get('adviceProcessId'),
					true
				)
			),
			concatMap(() =>
				this.goalService.getGoals(
					+this.route.snapshot.paramMap.get('adviceProcessId'),
					AdviceProcessSectionCodes.Goals
				)
			),
			concatMap(() => this.medicalService.getMedical()),
			concatMap(() =>
				this.riskProfileService.getRiskProfile(
					+this.route.snapshot.paramMap.get('adviceProcessId'),
					AdviceProcessSectionCodes.RiskProfile
				)
			),
			finalize(() => this.peopleService.setRefetchingAfterDelete(false))
		);
	};

	// Trust
	addNewTrustFn$ = (req: {
		trust: TrustDetailsState;
		adviceProcessId: number;
	}) =>
		this.peopleService.addTrust(req.trust, req.adviceProcessId).pipe(
			concatMap((x) =>
				this.peopleService
					.getTrusts(req.adviceProcessId, AdviceProcessSectionCodes.Trust, true)
					.pipe(map(() => x))
			),
			concatMap(() => this.crtservice.getSecondaryTrusts(this.clientId)),
			tap(() => {
				this.isAddNew = false;
				this.isSaving = false;
				this.cd.detectChanges();
			})
		);

	addExistingTrustFn$ = (req: {
		trust: TrustDetailsState;
		adviceProcessId: number;
	}) => this.peopleService.addTrust(req.trust, req.adviceProcessId);
	updateTrustFn$ = (model: TrustDetailsState) =>
		this.peopleService
			.updateTrust(model)
			.pipe(concatMap(() => this.crtservice.getSecondaryTrusts(this.clientId)));
	deleteTrustFn$ = (id: number) => this.peopleService.deleteTrust(id);

	// Company
	addNewCompanyFn$ = (req: {
		company: CompanyDetailsState;
		adviceProcessId: number;
	}) =>
		new Observable<any>((obs) => {
			this.isSaving = true;
			obs.next(req);
			obs.complete();
		}).pipe(
			concatMap(() =>
				this.peopleService.addCompany(req.company, req.adviceProcessId)
			),
			concatMap(() =>
				this.peopleService.getCompany(
					req.adviceProcessId,
					AdviceProcessSectionCodes.Company,
					true
				)
			),
			tap(() => {
				this.isAddNew = false;
				this.isSaving = false;
				this.cd.detectChanges();
			})
		);

	addExistingCompanyFn$ = (req: {
		company: CompanyDetailsState;
		adviceProcessId: number;
	}) => this.peopleService.addCompany(req.company, req.adviceProcessId);
	updateCompanyFn$ = (model: CompanyDetailsState) =>
		this.peopleService.updateCompany(model);
	deleteCompanyFn$ = (id: number) => this.peopleService.deleteCompany(id);

	isEllipsisActive(event: MouseEvent, value: string) {
		const element = (event.target as HTMLElement).children[0] as HTMLElement;
		return (this.nameToolTip =
			element.offsetWidth < element.scrollWidth ? value : '');
	}

	onKeyDown(event: KeyboardEvent) {
		const ARROW_KEY_LEFT = 37;
		const ARROW_KEY_RIGHT = 39;
		switch (event.keyCode) {
			case ARROW_KEY_LEFT:
			case ARROW_KEY_RIGHT:
				return event.preventDefault();
		}
	}

	ngOnDestroy(): void {
		this.service.people$
			.pipe(
				map((x) => {
					if (x?.some((p) => p?.cRTId === BLANK_PEOPLE_ID || !p?.cRTId)) {
						x?.splice(
							x.findIndex((p) => p?.cRTId === BLANK_PEOPLE_ID || !p.cRTId),
							1
						);

						return x;
					}
					return x;
				}),
				first()
			)
			.subscribe();

		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
