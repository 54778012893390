import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	NgZone,
	ChangeDetectorRef,
	AfterContentInit,
	AfterViewInit,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ViewDisplayValue } from '../models/_general/display-value.viewmodel';
import { BLStaff } from '../../domain/bl-staff/bl-staff.model';
import { viewSecGroup } from '@modules/user/viewmodels/viewSecGroup';

@Component({
	selector: 'app-table-input',
	templateUrl: './table-input.component.html',
	styleUrls: ['./table-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TableInputComponent,
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableInputComponent
	implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor
{
	constructor(private zone: NgZone, private cdRef: ChangeDetectorRef) {}

	value: string[] = [];
	isDisabled = false;
	init = true;

	@Input() staff: any;

	@Input() choices: any[] = [];
	@Input() inActiveStaffChoices: ViewDisplayValue[] = [];
	@Input() staffs: BLStaff[];
	@Input() showAllStaffs: boolean;
	@Input() isEditing: boolean;
	@Input() isLoading: boolean;
	a = 0;

	list: any[] = [];

	ngOnInit() {}

	ngAfterViewInit() {
		this.renderList();
	}

	ngOnChanges() {
		this.renderList();
	}

	renderList() {
		this.list = this.choices?.filter((choice) => {
			const check =
				(!this.isEditing && choice.isActive && this.isChecked(choice)) ||
				(this.isEditing && !(!this.showAllStaffs && !choice.isActive)) ||
				(!this.isEditing && this.showAllStaffs && this.isChecked(choice));

			if (check) {
				return {
					...choice,
					isChecked: this.isChecked(choice),
				};
			}

			return null;
		});

		if (!this.isEditing) {
			this.list = [this.staff, ...this.list];
		}

		this.cdRef.detectChanges();
	}

	writeValue(obj: string[]): void {
		if (Array.isArray(obj)) {
			this.value = obj;
		} else {
			this.value = [];
		}
	}

	controlInputChangeValue(value: string[]): void {
		this.writeValue(value);
		this.onChange(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {}

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.cdRef.detectChanges();
	}

	onChange: (x: string[]) => void = () => {};

	trackByFn(item: ViewDisplayValue): any {
		return item.value;
	}

	toggleSelectAll(checked: boolean) {
		if (checked) {
			this.controlInputChangeValue(this.choices?.map((x) => x.value));
		} else {
			this.controlInputChangeValue([]);
		}
	}

	checkChange(choice: ViewDisplayValue, checked: boolean) {
		if (checked) {
			this.controlInputChangeValue([...this.value, choice.value]);
		} else {
			this.controlInputChangeValue(
				this.value?.filter((x) => x !== choice.value)
			);
		}

		this.cdRef.detectChanges();
	}

	isChecked = (choice: ViewDisplayValue) => {
		return this.value?.includes(choice.value);
	};

	isAllChecked = () =>
		!this.choices?.some((x) => this.value?.indexOf(x.value) < 0);
}
