import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Row } from './kiwisaver.model';
import { KiwisaverUiStore } from './kiwisaver-ui.store';
import produce from 'immer';
import * as R from 'ramda';
import { KiwisaverRequest } from '../kiwisaver-request.model';
import { Injectable } from '@angular/core';

interface ColumnWidth {
	metakey: string;
	width: number;
}

export interface KiwisaverState extends EntityState<Row> {
	columns: string[];
	columnWidths: ColumnWidth[];
	count: number;
	totalAPI: number;
	isComplete: boolean;
	searchForm: KiwisaverRequest;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'kiwisaverSearch', idKey: 'CustomerServiceID' })
export class KiwisaverStore extends EntityStore<KiwisaverState, Row> {
	constructor(public uiStore: KiwisaverUiStore) {
		super({ columns: [], count: 0, totalAPI: 0, isComplete: false, columnWidths: [], searchForm: null });
	}

	setColumns(columns: string[]) {
		this.update(
			produce(x => {
				x.columns = columns;
			})
		);
	}

	setColumnWidths(columnWidths: ColumnWidth[]) {
		this.update(
			produce(x => {
				x.columnWidths = columnWidths;
			})
		);
	}

	setSearchForm(req: KiwisaverRequest) {
		this.update(
			produce(x => {
				x.searchForm = req;
			})
		);
	}

	updateField(req: { CustomerServiceId: number | number[]; MetaKey: string; MetaValue: string }) {
		this.update(
			req.CustomerServiceId,
			produce(draft => {
				const fields = R.omit(
					[
						'CustomerID',
						'CustomerServiceID',
						'link',
						'ActivityId',
						'FundOwnersList',
						'ClientNextActivityId',
						'UserNextActivityId',
					],
					draft
				);
				Object.values(fields).find(x => x.metakey === req.MetaKey).value = req.MetaValue;
				if (req.MetaKey === 'Status') {
					draft.StatusClock.value = '0 days';
				}

				if (req.MetaKey === 'Contribution' && req.MetaValue === '0') {
					draft.Contribution.value = '0.00';
				}

				if(req.MetaKey === 'Fund Owner') {
					draft.FundOwner.value = JSON.parse(req.MetaValue);
				}
			})
		);
	}
}
