<form [formGroup]="form" novalidate (ngSubmit)="save()">
	<div class="container-fluid clearfix px-0">
		<div *ngIf="!addMode" class="row clearfix align-items-center mb-1">
			<div class="col-lg col-12"></div>
			<div class="col-lg-auto col-12 d-flex action-btns justify-content-end">
				<ng-container *appRestrictTo="['FEC', 'FAC', 'FAL', 'FEL']">
					<span class="position-relative" *ngIf="isSaving$ | async">
						<app-manual-loader-mini
							[isLoading]="isSaving$ | async"
							class="p-0"
						></app-manual-loader-mini>
					</span>
					<ng-container *ngIf="!(isSaving$ | async)">
						<!-- Restrictions for Lead -->
						<ng-container *ngIf="isLead">
							<ng-container *appRestrictTo="['FEL']">
								<span class="position-relative" *ngIf="!(isEdit$ | async)">
									<button
										type="button"
										class="icon-btn"
										id="edit_{{ formID }}"
										(click)="edit()"
									>
										<i class="material-icons md-20 tap-text-color-h6"> edit </i>
									</button>
								</span>
							</ng-container>
							<ng-container *appRestrictTo="['FAL', 'FEL']">
								<ng-container *ngIf="isEdit$ | async">
									<span class="position-relative">
										<button
											type="submit"
											class="icon-btn"
											id="save_{{ formID }}"
										>
											<i class="material-icons md-20 tap-text-color-h6">
												save
											</i>
										</button>
									</span>
									<span class="position-relative">
										<button
											type="cancel"
											class="icon-btn"
											id="cancel_{{ formID }}"
											(click)="cancel()"
										>
											<i class="material-icons md-20 tap-text-color-h6">
												cancel
											</i>
										</button>
									</span>
								</ng-container>
							</ng-container>
						</ng-container>
						<!-- Restriction for current client -->
						<ng-container *ngIf="!isLead">
							<ng-container *appRestrictTo="['FEC']">
								<span class="position-relative" *ngIf="!(isEdit$ | async)">
									<button
										type="button"
										class="icon-btn"
										id="edit_{{ formID }}"
										(click)="edit()"
									>
										<i class="material-icons md-20 tap-text-color-h6"> edit </i>
									</button>
								</span>
							</ng-container>
							<ng-container *appRestrictTo="['FAC', 'FEC']">
								<ng-container *ngIf="isEdit$ | async">
									<span class="position-relative">
										<button
											type="submit"
											class="icon-btn"
											id="save_{{ formID }}"
										>
											<i class="material-icons md-20 tap-text-color-h6">
												save
											</i>
										</button>
									</span>
									<span class="position-relative">
										<button
											type="cancel"
											class="icon-btn"
											id="cancel_{{ formID }}"
											(click)="cancel()"
										>
											<i class="material-icons md-20 tap-text-color-h6">
												cancel
											</i>
										</button>
									</span>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="form-row clearfix align-items-center mb-2">
			<!-- Profile Photo -->
			<div class="col-lg-3 text-center order-lg-2">
				<div class="d-flex align-items-center justify-content-center">
					<app-photo
						id="upload_photo_{{ formID }}"
						[class.disabled]="isSaving$ | async"
						[uploadType]="'modal'"
						[customer]="data"
						[imageUrl]="data?.photoURL"
						[uploadFn$]="addPhoto$"
					></app-photo>
				</div>
			</div>
			<div class="col-lg-9 order-lg-1">
				<div class="form-row align-items-center mb-2">
					<!-- Title -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="title_{{ formID }}">Title</label>
							</div>
							<div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="title"
										class="form-control theme-crm-field-disabled"
										id="title_{{ formID }}"
										tabindex="7"
									>
										<option value=""></option>
										<option *ngFor="let d of title" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<!-- First Name -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<span class="tap-required-field" *ngIf="firstName.invalid"
									>*</span
								>
								<label for="firstName_{{ formID }}">First Name</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="firstName"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="firstName_{{ formID }}"
									tabindex="8"
								/>
							</div>
						</div>
					</div>
					<!-- Last Name -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<span class="tap-required-field" *ngIf="lastName.invalid"
									>*</span
								>
								<label for="lastName_{{ formID }}">Last Name</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="lastName"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="lastName_{{ formID }}"
									tabindex="9"
								/>
							</div>
						</div>
					</div>
				</div>

        <div class="form-row align-items-center mb-2">
          <!-- Middle Name -->
          <div class="col-lg">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="middleName_{{ formID }}">Middle Name</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  type="text"
                  formControlName="middleName"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="middleName_{{ formID }}"
                  tabindex="10"
                />
              </div>
            </div>
          </div>
          <!-- Date of Birth -->
          <div class="col-lg">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="">Date of Birth</label>
              </div>
              <div class="col-lg-6 col-12">
                <app-date-input
                  #dateOfBirthDateInput
                  id="dateOfBirth_{{ formID }}"
                  ngProjectAs="input"
                  textboxClass="theme-crm-field-disabled"
                  formControlName="dateOfBirth"
                  placeholder="dd/mm/yyyy"
									(onChangeEvent)="computeAge()"
                  [tabindex]="11"
                ></app-date-input>
              </div>
							<div class="col-lg-2 col-12 mt-lg-0 mt-1">
								<input
									id="age_{{ formID }}"
									[value]="age?.value || 0"
									class="form-control theme-crm-field-disabled"
									disabled
								/>
							</div>
            </div>
          </div>
          <!-- Gender -->
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="">Gender</label>
              </div>
              <div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="gender"
										class="form-control theme-crm-field-disabled"
										id="label_r3_gender"
                    tabindex="11.1"
									>
										<option *ngFor="let d of gender" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="form-row align-items-center mb-2">
					<!-- Known As -->
					<div class="col-lg col-lg-4">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="knownAs_{{ formID }}">Known As</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="knownAs"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="knownAs_{{ formID }}"
									tabindex="12.1"
								/>
							</div>
						</div>
					</div>
					<!-- Country of Origin -->
					<div class="col-lg col-lg-4">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="countryOfOrigin_{{ formID }}">Country of Origin</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="countryOfOrigin"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="countryOfOrigin_{{ formID }}"
									tabindex="12.2"
								/>
							</div>
						</div>
					</div>
					<div
						class="col-lg col-lg-4"
						*featureToggle="{
							id: 'AdviserRework',
							show: false,
						}"
					>
						<!-- Vulnerable Status -->
						<div class="form-row align-items-center">
							<div class="col-xl-4 col-lg-5 col-12 label-padding-bottom-md">
								<label for="vulnerable_{{ formID }}">Vulnerable?</label>
							</div>
							<div class="col-xl-8 col-lg-7 col-12">
								<input
									class="chckbox form-control theme-crm-field-disabled"
									formControlName="isVulnerable"
									type="checkbox"
									id="vulnerable_{{ formID }}"
									tabindex="12.1"
								/>
							</div>
						</div>
					</div>
					<div
						class="col-lg col-lg-4"
						*featureToggle="{
							id: 'AdviserRework',
							show: true,
						}"
					>
						<!-- Key Contact -->
						<div class="form-row align-items-center">
							<div class="col-xl-4 col-lg-5 col-12 label-padding-bottom-md">
								<label for="isKeyContact_{{ formID }}">Key Contact</label>
							</div>
							<div class="col-xl-8 col-lg-7 col-12">
								<input
									class="chckbox form-control theme-crm-field-disabled"
									formControlName="isKeyContact"
									type="checkbox"
									id="isKeyContact_{{ formID }}"
									tabindex="12.1"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="form-row align-items-center mb-2">
					<!-- Employment -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="employment_{{ formID }}">Employment</label>
							</div>
							<div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="employment"
										class="form-control theme-crm-field-disabled"
										id="employment_{{ formID }}"
										tabindex="13"
									>
										<option value=""></option>
										<option *ngFor="let d of employment" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<!-- Occupation -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="occupation_{{ formID }}">Occupation</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="occupation"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="occupation_{{ formID }}"
									tabindex="13.1"
								/>
							</div>
						</div>
					</div>
					<!-- Income -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="income_{{ formID }}">Income</label>
							</div>
							<div class="col-lg-8 col-12">
								<div class="dollar-icon">
									<input
										type="text"
										formControlName="income"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										id="income_{{ formID }}"
										appTwoDigitDecimalNumber
										tabindex="13.2"
										maxlength="16"
									/>
									<i>$</i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-row align-items-center mb-2">
					<!-- Employer -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="employer_{{ formID }}">Employer</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="employer"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="employer_{{ formID }}"
									tabindex="14"
								/>
							</div>
						</div>
					</div>
					<!-- Industry -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="industry_{{ formID }}">Industry</label>
							</div>
							<div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="industry"
										class="form-control theme-crm-field-disabled"
										id="industry_{{ formID }}"
										tabindex="15"
									>
										<option value=""></option>
										<option *ngFor="let d of industry" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg"></div>
				</div>
			</div>
		</div>

		<hr />

		<div class="form-row clearfix align-items-center mb-2">
			<!-- Preferred Contact -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="preferredContact_{{ formID }}">Contact Method</label>
					</div>
					<div class="col-lg-8 col-12">
						<span class="custom-dropdown w-100">
							<select
								formControlName="preferredContact"
								id="contactMethod_{{ formID }}"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								tabindex="16"
							>
								<option value=""></option>
								<option *ngFor="let d of preferredContact" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</div>
			<!-- Mobile -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="mobile_{{ formID }}">Mobile</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="mobile"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="mobile_{{ formID }}"
							tabindex="17"
						/>
					</div>
				</div>
			</div>
			<!-- Home Phone -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="homePhone_{{ formID }}">Home Phone</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="homePhone"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="homePhone_{{ formID }}"
							tabindex="18.1"
						/>
					</div>
				</div>
			</div>
			<!-- Work -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="work_{{ formID }}">Work</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="work"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="work_{{ formID }}"
							tabindex="18.2"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row clearfix align-items-center mb-2">
			<!-- Email -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-2 col-12 label-padding-bottom-md">
						<label for="email_{{ formID }}">Email</label>
					</div>
					<div class="col-lg-10 col-12">
						<input
							type="text"
							formControlName="email"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="email_{{ formID }}"
							tabindex="18.3"
						/>
					</div>
				</div>
			</div>
			<!-- Alternate Email -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-2 col-12 label-padding-bottom-md">
						<label for="alternateEmail_{{ formID }}">Alternate Email</label>
					</div>
					<div class="col-lg-10 col-12">
						<input
							type="text"
							formControlName="alternateEmail"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="alternateEmail_{{ formID }}"
							tabindex="18.4"
						/>
					</div>
				</div>
			</div>
		</div>

    <div class="form-row clearfix align-items-center mb-2">
      <!-- Mailing Address 1 -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="mailingAddress1_{{ formID }}">Mailing Address 1</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="mailingAddress1"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="mailingAddress1_{{ formID }}"
              tabindex="18.5"
            />
          </div>
        </div>
      </div>
      <!-- Mailing Address 2 -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="mailingAddress2_{{ formID }}">Mailing Address 2</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="mailingAddress2"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="mailingAddress2_{{ formID }}"
              tabindex="19"
            />
          </div>
        </div>
      </div>
      <!-- City -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="city_{{ formID }}">City</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="city"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="city_{{ formID }}"
              tabindex="19.1"
            />
          </div>
        </div>
      </div>
      <!-- Postcode -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="postcode_{{ formID }}">Postcode</label>
          </div>
          <div class="col-lg-8 col-12">
            <input
              type="text"
              formControlName="postcode"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="postcode_{{ formID }}"
              tabindex="19.2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
      <!-- Physical Address -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="physicalAddress_{{ formID }}">Physical Address</label>
          </div>
          <div class="col-lg-10 col-12">
            <app-address-input
              id="physicalAddress_{{ formID }}"
              inputId="physicalAddress_{{ formID }}"
              formControlName="physicalAddress"
              textboxClass="theme-crm-field-disabled"
              [tabindex]="20"
            ></app-address-input>
          </div>
        </div>
      </div>
      <div class="col-lg"></div>
    </div>

		<hr />

    <div class="form-row clearfix align-items-center mb-2">
      <!-- Lead Type -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="leadType_{{ formID }}">Lead Type</label>
          </div>
          <div class="col-lg-8 col-12">
            <span class="custom-dropdown w-100">
              <select
                formControlName="leadType"
                id="leadType_{{ formID }}"
                class="form-control theme-crm-field-disabled"
                placeholder="- -"
                tabindex="21"
              >
                <option value=""></option>
                <option *ngFor="let d of leadTypeChoices" [value]="d.value">
                  {{ d.display }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <!-- Lead Origin -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="leadOrigin_{{ formID }}">Lead Origin</label>
          </div>
          <div class="col-lg-8 col-12">
            <span class="custom-dropdown w-100">
              <select
                formControlName="leadOrigin"
                id="leadOrigin_{{ formID }}"
                class="form-control theme-crm-field-disabled"
                placeholder="- -"
                tabindex="22"
              >
                <option value=""></option>
                <option *ngFor="let d of leadOriginChoices" [value]="d.value">
                  {{ d.display }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="leadGen_{{ formID }}">Lead Gen</label>
          </div>
          <div class="col-lg-8 col-12">
            <span class="custom-dropdown w-100">
              <select
                formControlName="leadGen"
                id="leadGen_{{ formID }}"
                class="form-control theme-crm-field-disabled"
                placeholder="- -"
                tabindex="23"
              >
                <option value=""></option>
                <option *ngFor="let d of leadGenChoices" [value]="d.value">
                  {{ d.display }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <!-- Referred By -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-4 col-12">
            <label for="referredBy_{{ formID }}">Referred By</label>
          </div>
          <div class="col-lg-8 col-12">
            <app-client-search-control
              textboxClass="theme-crm-field-disabled"
              formControlName="referredBy"
              id="referredBy_{{ formID }}"
              [tabindex]="24"
            ></app-client-search-control>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row clearfix align-items-center mb-2">
      <!-- Professional Contact -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="professionalContacts_{{ formID }}"
              >Professional Contacts</label
            >
          </div>
          <div class="col-lg-10 col-12">
            <app-chips
              textboxClass="theme-crm-field"
              formControlName="professionalContacts"
              [choices]="professionalContacts"
              id="professionalContacts{{ formID }}"
              [tabindex]="25"
            >
            </app-chips>
          </div>
        </div>
      </div>
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="adviserGen_{{ formID }}">Adviser Gen</label>
          </div>
          <div class="col-lg-4 col-12">
            <span class="custom-dropdown w-100">
              <select
                formControlName="adviserGen"
                id="adviserGen_{{ formID }}"
                class="form-control theme-crm-field-disabled"
                placeholder="- -"
                tabindex="26"
              >
                <option value=""></option>
                <option *ngFor="let c of allAdviserGenChoices" [value]="c.value">
                  {{ c.display }}
                </option>
              </select>
            </span>
          </div>
					<div class="col-lg-6 col-12"></div>
        </div>
      </div>
    </div>

    <div
			class="form-row clearfix align-items-center mb-2 feat-adviser-rework"
			*featureToggle="{
				id: 'AdviserRework',
				show: true,
			}"
		>
      <!-- Vulnerable -->
      <div class="col-lg">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="vulnerable_{{ formID }}">Vulnerable?</label>
          </div>
          <div class="col-lg-10 col-12 d-flex align-items-center">
            <input
							class="chckbox form-control theme-crm-field-disabled cbox-vulnerable d-inline-block"
							formControlName="isVulnerable"
							type="checkbox"
							id="vulnerable_{{ formID }}"
							tabindex="27"
						/>
						<input
							type="text"
							formControlName="vulnerableNotes"
							class="form-control theme-crm-field-disabled input-vulnerable d-inline-block ml-2"
							placeholder="- -"
							id="vulnerableNotes_{{ formID }}"
							tabindex="28"
						/>
					</div>
        </div>
      </div>
      <div class="col-lg">
      </div>
    </div>

    <ng-container *ngIf="addMode">
      <div class="form-row clearfix align-items-center mb-2">
        <div class="col-lg-12">Add a note</div>
        <div class="col-lg-12">
          <textarea
            class="form-control theme-crm-field-disabled"
            formControlName="note"
            rows="4"
            id="note_{{ formID }}"
            tabindex="29"
          ></textarea>
        </div>
      </div>
    </ng-container>
  </div>
</form>

<ng-container *ngIf="!addMode">
	<app-table-paginate-notes
		[isLead]="isLead"
		[hasAddNote]="true"
		[customerId]="data?.customerID"
		[notes]="notes?.notes"
		[count]="notes?.count"
		[hasPaging]="true"
		[getFn$]="getNotes$"
		[addFn$]="addNotes$"
		[deleteFn$]="deleteNotes$"
		[refreshNotes$]="refreshNotes$"
	>
	</app-table-paginate-notes>
</ng-container>
