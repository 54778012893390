import { OpenSansRegularTtf } from './fonts/OpenSansRegularTtf';
import { OpenSansRegularWoff } from './fonts/OpenSansRegularWoff';

export const liveUrl = `https://crm.tapnz.co.nz`;
export const windowUrl = window.location.origin;
export const assetsPath = `assets/fonts`;
export const fontFaceUrl = `
@font-face {
  font-family: "Open Sans";
  src: url("${windowUrl}/${assetsPath}/OpenSans-Light.woff") format("woff"),
    url("${windowUrl}/${assetsPath}/OpenSans-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("${windowUrl}/${assetsPath}/OpenSans-Regular.woff2") format("woff2"),
    url("${windowUrl}/${assetsPath}/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("${windowUrl}/${assetsPath}/OpenSans-SemiBold.woff2") format("woff2"),
    url("${windowUrl}/${assetsPath}/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("${windowUrl}/${assetsPath}/OpenSans-Bold.woff2") format("woff2"),
    url("${windowUrl}/${assetsPath}/OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}`;

export const importGoogle = `@import url(https://fonts.googleapis.com/css?family=Open+Sans);`;

export const googleFontStyle = `
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}`;

export const fontFaceBase64 = `
@font-face {
	font-family: 'Open Sans';
	src: url(data:font/opentype;charset=utf-8;base64,${OpenSansRegularWoff}) format("woff"),
	url(data:font/truetype;charset=utf-8;base64,${OpenSansRegularTtf}) format('truetype');
}`;

export const fontFaceOpenSans = `
@font-face {
	font-family: 'Open Sans';
	src: local('Arial') !important;
	font-style: normal !important;
	letter-spacing: normal !important;
}`;

export const googleFonts = `
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap" rel="stylesheet" />`;
