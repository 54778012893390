<div class="container-fluid">
  <div class="row">
    <div class="col-auto custom-sidebar-nav custom-sidebar-nav--vertical mb-3">
      <!-- Business -->
      <div class="custom-sidebar-nav__divider-header d-flex align-items-center py-2">
        <div class="col font-weight-bold px-0">Business</div>
      </div>
      <div
        class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--success last--index"
        [ngClass]="{ active: activeTab === 0 }"
        (click)="selectCompany()"
        id="selectCompany"
      >
        <strong>{{ primaryCompany?.companyName }}</strong>
        <span class="font-italic">"Primary"</span>
        <i class="material-icons md-20 arrow-right">chevron_right</i>
      </div>

      <!-- Directors -->
      <div class="custom-sidebar-nav__divider-header d-flex align-items-center py-2 mt-3">
        <div class="col font-weight-bold px-0">Director</div>
      </div>

      <div
        class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--purple last--index"
        [ngClass]="{ active: activeTab === 1 }"
        (click)="selectDirector()"
        id="selectDirector"
      >
        <strong>{{ (directors$ | async)?.length || 0 }} Linked</strong>
        <i class="material-icons md-20 arrow-right">chevron_right</i>
        <ng-container *ngIf="directorIsLoading$ | async">
          <app-manual-loader-mini class="pt-1" [isLoading]="directorIsLoading$ | async"></app-manual-loader-mini>
        </ng-container>
      </div>

      <!-- Shareholder -->
      <div class="custom-sidebar-nav__divider-header d-flex align-items-center py-2 mt-3">
        <div class="col font-weight-bold px-0">Shareholder</div>
      </div>

      <div
        class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--info last--index"
        [ngClass]="{ active: activeTab === 2 }"
        (click)="selectShareholder()"
        id="selectShareholder"
      >
        <strong>{{ (shareholders$ | async)?.length || 0 }} Linked</strong>
        <i class="material-icons md-20 arrow-right">chevron_right</i>
        <ng-container *ngIf="shareholderIsLoading$ | async">
          <app-manual-loader-mini class="pt-1" [isLoading]="shareholderIsLoading$ | async"></app-manual-loader-mini>
        </ng-container>
      </div>

      <!-- Other -->
      <div class="custom-sidebar-nav__divider-header d-flex align-items-center py-2 mt-3">
        <div class="col font-weight-bold px-0">Other</div>
      </div>

      <div
        class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--danger last--index"
        [ngClass]="{ active: activeTab === 3 }"
        (click)="selectOther()"
        id="selectOther"
      >
        <strong>{{ (others$ | async)?.length || 0 }} Linked</strong>
        <i class="material-icons md-20 arrow-right">chevron_right</i>
        <ng-container *ngIf="othersIsLoading$ | async">
          <app-manual-loader-mini class="pt-1" [isLoading]="othersIsLoading$ | async"></app-manual-loader-mini>
        </ng-container>
      </div>
    </div>

    <div class="col">
      <tabset #businessLinkedContacts class="tap-tabset-sidebar tap-tabset-sidebar--hidden-header" [vertical]="true">
        <tab heading="Primary Client" id="CompanyTab">
          <app-form-company
            [isLead]="isLead"
            [addMode]="false"
            [isEdit]="false"
            [isSaving]="false"
            [business]="primaryCompany"
            [leadTypeChoices]="leadTypes"
            [leadOriginChoices]="leadOrigins"
            [leadGenChoices]="leadGens"
            [updateFn$]="updateBusiness$"
            [getNotesFn$]="getNotes$"
            [addNotesFn$]="addNotes$"
            [deleteNotesFn$]="deleteNotes$"
            [adviserGenChoices]="adviserGenChoices"
            [allStaffChoices]="allStaffChoices"
          ></app-form-company>
        </tab>
        <!-- Director -->
        <tab heading="Director" id="DirectorTab">
          <app-form-director
            [isLead]="isLead"
            [isEdit]="false"
            [isSaving]="false"
            [members]="directors$ | async"
            [advisers]="advisers$ | async"
            [leadOriginChoice]="leadOriginChoices$ | async"
            [leadTypeChoice]="leadTypeChoices$  | async"
            [trustTypes]="trustTypes$ | async"
            [customerStatus]="customerStatus$ | async"
            [customerStatusChange]="customerStatusChange"
            [updateFn$]="updateLinkedMembers$"
          ></app-form-director>
        </tab>
        <!-- Shareholder -->
        <tab heading="Shareholder" id="ShareholderTab">
          <app-form-shareholder
            [isLead]="isLead"
            [isEdit]="false"
            [isSaving]="false"
            [members]="shareholders$ | async"
            [advisers]="advisers$ | async"
            [leadOriginChoice]="leadOriginChoices$ | async"
            [leadTypeChoice]="leadTypeChoices$  | async"
            [trustTypes]="trustTypes$ | async"
            [customerStatus]="customerStatus$ | async"
            [customerStatusChange]="customerStatusChange"
            [updateFn$]="updateLinkedMembers$"
          ></app-form-shareholder>
        </tab>
        <!-- Other -->
        <tab heading="Other" id="OtherTab">
          <app-form-other
            [isLead]="isLead"
            [isEdit]="false"
            [isSaving]="false"
            [members]="others$ | async"
            [advisers]="advisers$ | async"
            [leadOriginChoice]="leadOriginChoices$ | async"
            [leadTypeChoice]="leadTypeChoices$  | async"
            [trustTypes]="trustTypes$ | async"
            [customerStatus]="customerStatus$ | async"
            [customerStatusChange]="customerStatusChange"
            [updateFn$]="updateLinkedMembers$"
          ></app-form-other>
        </tab>
      </tabset>
    </div>
  </div>
</div>
