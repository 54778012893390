import { MergeTagTypeCode } from '../../../merge-tags.model';

export const appDocsMetaKey = {
	documents: 'APPLICATION_DOCUMENTS',
};

export const appDocsMergeTag = [
	{
		metaKey: appDocsMetaKey.documents,
		description: 'Application > Documents > Ticked Documents',
		value: [],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Financial Declaration',
	},
];
