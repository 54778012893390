import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { DefaultFileNames, DocumentModelState } from '../../../../shared/models/documents/document.model';
import { ApiService } from '../../../../core/base/api.service';
import { objectUtil } from '../../../../util/util';
import { CrtSettingsQuery } from '../../state/crt-settings.query';
import { CrtSettingsStore } from '../../state/crt-settings.store';
import {
	DocumentUploadState,
	SOAEmailSettings,
	SoaSettingsState,
} from './soa-settings.model';
import { MergeTagsCrtSettingsService } from '../../merge-tags/state/merge-tags-crt-settings.service';
import { SettingsTypes } from '../../state/crt-settings.model';
import { EmailTypes } from '@shared/models/emails/crt/email.model';

@Injectable()
export class SoaSettingsService {
	statementOfAdvice$ = this.query.statementOfAdvice$;

	emailSettings$ = this.query.soaEmailSettings$;

	constructor(
		private api: ApiService,
		private store: CrtSettingsStore,
		private query: CrtSettingsQuery,
		private mtService: MergeTagsCrtSettingsService
	) {}

	getSoaSettings(refId: number, settingsCode: string) {
		const endpoint = `crt/settings/${refId}/${settingsCode}`;
		return this.api.get<SoaSettingsState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setSoaSettings(state);
					const mergeTag = state?.mergeTag?.map((i) => ({
						...i,
						secondaryValue: i?.value?.replace(/\n|\t/g, '<br>') || '',
						value: i?.value?.replace(/\n|\t/g, '<br>') || '',
					}));
					this.mtService.updateMtState(mergeTag || []);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getEmailSettings(refId: number, settingsCode: string) {
		const endpoint = `crt/settings/${refId}/${settingsCode}`;
		return this.api.get<SOAEmailSettings>(endpoint).pipe(
			tap((setting) => {
				const state = setting
					? objectUtil.mapPascalCaseToCamelCase(setting)
					: null;
				this.store.setSoaEmailSettings(state);
			}),
			catchError(() => of(undefined))
		);
	}

	updateEmailSettings(settings: SOAEmailSettings) {
    const endpoint = `crt/settings/${settings.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(settings);
    return this.api.put<SOAEmailSettings>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setDeclarationEmailSettings(settings);
        })
      ),
      take(1)
    );
	}

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

	updateSoaSettings(data: SoaSettingsState) {
		const newValue = {
			...this.query.getValue().statementOfAdvice,
			...data,
		};
		const { templateLink, ...payload } = newValue;
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(payload);
		return this.api.put<SoaSettingsState>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.setSoaSettings(newValue);
				})
			)
		);
	}

	newFileUploadSOA(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUploadSOA(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getSoaDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getDefaultSoaTemplateUrl() {
		const endpoint = `crt/oat-template/${SettingsTypes.SOA}`;
		return this.api.get<string>(endpoint);
	}

  public static mapEmailSettingsToUpsert(data: SOAEmailSettings) {
    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: EmailTypes.SOASettings
    };
  }

  public static mapStaffEmailBody(document, docId, staffId) {
    if (docId === 0 || !docId) {
      return {
        document,
        referenceId: +staffId,
        fileName: DefaultFileNames.SOA,
        type: EmailTypes.SOA
      };
    }
    return {
      document,
      documentId: +docId,
    };
  }

}
