<div class="soa-mergetag-wording pr-2">
	<form [formGroup]="form" class="form">
		<div class="row pb-0 pb-2 pt-4">
			<div class="col-12 col-lg-10">
				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>Life Cover Introduction on Life Cover Calculation Page</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaLifeCoverCalcIntro"
							id="soaLifeCoverCalcIntro"
							formControlName="soaLifeCoverCalcIntro"
							cols="30"
							rows="6"
						></textarea>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>Trauma Cover Introduction on Trauma Cover Calculation Page</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaTraumaCoverCalcIntro"
							id="soaTraumaCoverCalcIntro"
							formControlName="soaTraumaCoverCalcIntro"
							cols="30"
							rows="6"
						></textarea>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>Disability Cover Introduction on Disability Benefit Calculation
							Page</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaDisabilityCoverCalcIntro"
							id="soaDisabilityCoverCalcIntro"
							formControlName="soaDisabilityCoverCalcIntro"
							cols="30"
							rows="6"
						></textarea>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>TPD Cover Introduction on TPD Cover Calculation Page</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaTpdCoverCalcIntro"
							id="soaTpdCoverCalcIntro"
							formControlName="soaTpdCoverCalcIntro"
							cols="30"
							rows="6"
						></textarea>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>Medical Introduction on Medical Insurance Page</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaMedicalIntro"
							id="soaMedicalIntro"
							formControlName="soaMedicalIntro"
							cols="30"
							rows="6"
						></textarea>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>‘How to raise a concern or complaint about my advice’ Section on
							the Things you should know Page</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaRaiseComplaint"
							id="soaRaiseComplaint"
							formControlName="soaRaiseComplaint"
							cols="30"
							rows="6"
						></textarea>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>Things to Consider</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaThingsToConsider"
							id="soaThingsToConsider"
							formControlName="soaThingsToConsider"
							cols="30"
							rows="15"
						></textarea>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold">The Next Step</label>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control large-text-"
							name="soaTheNextStep"
							id="soaTheNextStep"
							formControlName="soaTheNextStep"
							cols="30"
							rows="15"
						></textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 mb-4">
			<div class="col-12 col-lg-10 text-right">
				<app-manual-loader-mini
					[isLoading]="isLoading"
				></app-manual-loader-mini>
				<button
					type="button"
					class="btn btn-primary my-2 ml-1 tap-bg-primary"
					(click)="save()"
					*ngIf="!isLoading"
				>
					SAVE
				</button>
			</div>
		</div>
	</form>
</div>
