import { Component, Input, OnChanges, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SendTestEmailService } from '../state/send-test-email.service';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { util } from '../../../../core/util/util.service';
import { SendTestEmail } from '../../../../shared/models/emails/send-test-email/send-test-email.model';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ClientSearchControlComponent } from 'src/app/shared/search-controls/client-search-control/client-search-control.component';
import * as R from 'ramda';

@Component({
	selector: 'app-send-test-email-form',
	templateUrl: './send-test-email-form.component.html'
})
export class SendTestEmailFormComponent implements OnChanges, AfterViewChecked {
	private onDestroy$ = new Subject<void>();
	@Input() emailType: ViewDisplayValue[];
	@Input() contentMergeTages: string;
	@ViewChild('sendTestEmail', { static: true }) sendTestEmail: ElementRef;
	@ViewChild(ClientSearchControlComponent) customer: ClientSearchControlComponent;
	form: UntypedFormGroup;
	prepData: SendTestEmail;
	isSaving = false;
	isSent = false;
	emailTypeValue: string;

	formTemplate: string[] = [];

	constructor(private fb: UntypedFormBuilder,
             private sendTestEmailService: SendTestEmailService,
             private changeDetector: ChangeDetectorRef) {
		  this.buildForm();
	}

	ngOnChanges() {
    const NotRequiredFields = [
      'KStartDate'
    ];
		  if (!this.contentMergeTages) {
			return;
		} else {
			this.formTemplate = JSON.parse(this.contentMergeTages);
			this.clearAllValidators(this.formTemplate);
			this.formTemplate?.forEach(element => {
        const isElementNotRequired = R.contains(element, NotRequiredFields);
        if (!isElementNotRequired) {
          const value = element?.replace(/^[a-z]/i, str => str?.toLowerCase()) as string;
          this.form.get(value).setValidators([Validators.required]);
        }
			});
		}
	}

	buildForm() {
		this.form = this.fb.group({
			emailType: ['', Validators.required],
			name: ['', Validators.required],
			email: ['', Validators.required],
			customerID: ['', Validators.required],

			clientLastName: [''],
			adviserFirstName: [''],
			lRProvider: [''],
			lRPolicyNumber: [''],
			clientLink: [''],
			allClientIndividuals: [''],
			lRStatusClock: [''],
			clientFileName: [''],
			kProvider: [''],
			clientFirstName: [''],
			signOffName: [''],
			mProvider: [''],
			fixedPeriodMonth: [''],
			fundOwnerFirstName: [''],
			investorFirstName: [''],
			activityDate: [''],
			lRSubmittedDate: [''],
			dateTime: [''],
			activityTime: [''],
      kStartDate: [''],
			userFirstName: [''],
			clientCreateDate: [''],
			leadStatus: [''],
			adviserName: [''],
      leadOrigin: ['']
		});
	}

	get ClientLastName() {
		return this.form.get('clientLastName');
	}
	get AdviserFirstName() {
		return this.form.get('adviserFirstName');
	}
	get LRProvider() {
		return this.form.get('lRProvider');
	}
	get LRPolicyNumber() {
		return this.form.get('lRPolicyNumber');
	}
	get ClientLink() {
		return this.form.get('clientLink');
	}
	get AllClientIndividuals() {
		return this.form.get('allClientIndividuals');
	}
	get LRStatusClock() {
		return this.form.get('lRStatusClock');
	}
	get ClientFileName() {
		return this.form.get('clientFileName');
	}
	get KProvider() {
		return this.form.get('kProvider');
	}
	get ClientFirstName() {
		return this.form.get('clientFirstName');
	}
	get SignOffName() {
		return this.form.get('signOffName');
	}
	get MProvider() {
		return this.form.get('mProvider');
	}
	get FixedPeriodMonth() {
		return this.form.get('fixedPeriodMonth');
	}
	get FundOwnerFirstName() {
		return this.form.get('fundOwnerFirstName');
	}
	get InvestorFirstName() {
		return this.form.get('investorFirstName');
	}
	get ActivityDate() {
		return this.form.get('activityDate');
	}
	get LRSubmittedDate() {
		return this.form.get('lRSubmittedDate');
	}
	get DateTime() {
		return this.form.get('dateTime');
	}
	get ActivityTime() {
		return this.form.get('activityTime');
	}

	get EmailType() {
		return this.form.get('emailType');
	}
	get Name() {
		return this.form.get('name');
	}
	get Email() {
		return this.form.get('email');
	}
	get CustomerID() {
		return this.form.get('customerID');
	}
  get KStartDate() {
		return this.form.get('kStartDate');
	}
	// New fields for Lead - Referral Update
  get UserFirstName() {
		return this.form.get('userFirstName');
	}
  get ClientCreateDate() {
		return this.form.get('clientCreateDate');
	}
  get LeadStatus() {
		return this.form.get('leadStatus');
	}
  get AdviserName() {
		return this.form.get('adviserName');
	}
  get LeadOrigin() {
		return this.form.get('leadOrigin');
	}


	selectOption(event) {
		const value = event.target.value ? event.target.value : '';
		this.sendTestEmailService.getContentMegeTags(value);
		this.resetMergeTags();
	}

	resetMergeTags() {
		this.ClientLastName.reset();
		this.AdviserFirstName.reset();
		this.LRProvider.reset();
		this.LRPolicyNumber.reset();
		this.ClientLink.reset();
		this.AllClientIndividuals.reset();
		this.LRStatusClock.reset();
		this.ClientFileName.reset();
		this.KProvider.reset();
		this.ClientFirstName.reset();
		this.SignOffName.reset();
		this.MProvider.reset();
		this.FixedPeriodMonth.reset();
		this.FundOwnerFirstName.reset();
		this.InvestorFirstName.reset();
		this.ActivityDate.reset();
		this.LRSubmittedDate.reset();
		this.DateTime.reset();
		this.ActivityTime.reset();
		this.KStartDate.reset();
		this.UserFirstName.reset();
		this.ClientCreateDate.reset();
		this.LeadStatus.reset();
		this.AdviserName.reset();
		this.LeadOrigin.reset();
	}

	clearForm() {
		this.sendTestEmail.nativeElement.reset();
		this.customer.searchBox.clear();
		this.buildForm();
		this.sendTestEmailService.resetMergeTags();
		this.formTemplate = [];
		this.isSent = false;
	}

	prepareData(values): SendTestEmail {
		return (this.prepData = {
			CustomerID: !values.customerID ? '' : values.customerID.CustomerId,
			Tos: [
				{
					Name: !values.name ? '' : values.name,
					Email: !values.email ? '' : values.email,
				},
			],
			TemplateData: {
				ClientFirstName: values.clientFirstName ? values.clientFirstName : '',
				ClientLastName: values.clientLastName ? values.clientLastName : '',
				ActivityDate: values.activityDate ? util.DateStringToDateStringDisplay(values.activityDate) : '',
				ActivityTime: values.activityTime ? values.activityTime : '',
				AdviserFirstName: values.adviserFirstName ? values.adviserFirstName : '',
				LRProvider: values.lRProvider ? values.lRProvider : '',
				LRPolicyNumber: values.lRPolicyNumber ? values.lRPolicyNumber : '',
				LRStatusClock: values.lRStatusClock ? values.lRStatusClock : '',
				LRSubmittedDate: values.lRSubmittedDate ? util.DateStringToDateStringDisplay(values.lRSubmittedDate) : '',
				ClientLink: values.clientLink ? values.clientLink : '',
				AllClientIndividuals: values.allClientIndividuals ? values.allClientIndividuals : '',
				ClientFileName: values.clientFileName ? values.clientFileName : '',
				KProvider: values.kProvider ? values.kProvider : '',
				FundOwnerFirstName: values.fundOwnerFirstName ? values.fundOwnerFirstName : '',
				InvestorFirstName: values.investorFirstName ? values.investorFirstName : '',
				FixedPeriodMonth: values.fixedPeriodMonth ? values.fixedPeriodMonth : '',
				SignOffName: values.signOffName ? values.signOffName : '',
				MProvider: values.mProvider ? values.mProvider : '',
				DateTime: values.dateTime ? util.DateStringToDateStringDisplay(values.dateTime) : '',
        KStartDate: values.kStartDate ? util.DateStringToDateStringDisplay(values.kStartDate) : '',
        UserFirstName: values?.UserFirstName || '',
        ClientCreateDate: values.ClientCreateDate ? util.DateStringToDateStringDisplay(values.ClientCreateDate) : '',
        LeadStatus: values?.LeadStatus || '',
        AdviserName: values.AdviserName || '',
        LeadOrigin: values.LeadOrigin || '',
			},
		});
	}

	sendBtnClick() {
		if (!this.form.valid) {
			return;
		}
		this.isSaving = true;
		const values = this.prepareData(this.form.getRawValue()) as SendTestEmail;
		const emailType = this.form.value.emailType ? this.form.value.emailType : '';
		this.sendTestEmailService
			.send(values, emailType)
			.pipe(
				tap(() => (this.isSaving = false)),
				tap(() => (this.isSent = true)),
				finalize(() => setTimeout(() => (this.isSent = false), 3000)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	clearAllValidators(content) {
		const contentValue = [
			'ActivityTime',
			'DateTime',
			'LRSubmittedDate',
			'ActivityDate',
			'FundOwnerFirstName',
			'InvestorFirstName',
			'FixedPeriodMonth',
			'MProvider',
			'SignOffName',
			'ClientFirstName',
			'KProvider',
			'ClientFileName',
			'LRStatusClock',
			'AllClientIndividuals',
			'ClientLink',
			'LRPolicyNumber',
			'LRProvider',
			'AdviserFirstName',
			'ClientLastName',
      'KStartDate',
      'UserFirstName',
      'ClientCreateDate',
      'LeadStatus',
      'AdviserName',
      'LeadOrigin'
		] as string[];

		const difference = contentValue?.filter(x => !content?.includes(x));

		difference?.forEach(element => {
			const value = element?.replace(/^[a-z]/i, str => str?.toLowerCase()) as string;
			this.form.get(value).clearValidators();
			this.form.get(value).updateValueAndValidity();
		});
	}

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

}
