import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { mergeMap, withLatestFrom, tap, map } from 'rxjs/operators';
import * as R from 'ramda';
import { ConfigService } from '@core/config/config.service';
import { RouteService, CommandRoute } from '@core/config/route.service';
import { staticConf } from '@core/config/static-config.service';
import { ApiService } from '@core/base/api.service';
import { GlobalSearchResult, GlobalSearchResultService, GlobalSearch } from '@shared/models/_general/global-search.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { GlobalSearchQuery } from './global-search.query';
import { GlobalSearchStore } from './global-search.store';
import { TAGS } from './global-search.constants';

@Injectable()
export class GlobalSearchService {
  public readonly companyCode$: Observable<string>
  searchResult$: Observable<ViewGlobalSearch[]>;
  rawSearchResult$: Observable<ViewGlobalSearch[]>;
  noResultMessage$: Observable<string>;
  private searchResultSubj: BehaviorSubject<ViewGlobalSearch[]>;
  private rawSearchResultSubj: BehaviorSubject<ViewGlobalSearch[]>;
  private noResultMessageSubj: BehaviorSubject<string>;
  
  constructor(
    private api: ApiService,
    private configService: ConfigService,
    private routeService: RouteService,
    private query: GlobalSearchQuery,
    private store: GlobalSearchStore
  ) {
    this.companyCode$ = this.configService.CompanyCode;

    this.searchResultSubj = new BehaviorSubject<ViewGlobalSearch[]>([]);
    this.searchResult$ = this.searchResultSubj.asObservable();
        
    // for filtering
    this.rawSearchResultSubj = new BehaviorSubject<ViewGlobalSearch[]>([]);
    this.rawSearchResult$ = this.rawSearchResultSubj.asObservable();

    // for message if no result found
    this.noResultMessageSubj = new BehaviorSubject<string>('');
    this.noResultMessage$ = this.noResultMessageSubj.asObservable();
  }

  fetchMostRecent(count: number) {
    return this.query.recentSearch$.pipe(
      tap(x => {
        const items = x.slice(0, count);
        this.searchResultSubj.next(items);
        this.rawSearchResultSubj.next(items);
      })
    )
  }

  storeMostRecent() {
    return this.searchResult$.pipe(
      tap(x => {
        if(x.length > 0) {
          this.store.setRecentSearches(x)
        }
      })
    )
  }

  private globalSearch(keyword: string, code: string) {
		const endpoint = `search?keyword=${keyword}&type=global`;
		return this.api.get<GlobalSearch[]>(endpoint);
	}
  
  search(keyword: string, filter?: string): Observable<ViewGlobalSearch[]> {
    return of(keyword).pipe(
      withLatestFrom(this.companyCode$),
      mergeMap(([key, ccode]) => this.globalSearch(key, ccode)),
      map(x => {
        const vm = x?.map(y =>
          ViewGlobalSearch.MapFromModel(
            y,
            this.routeService.customerView,
            this.routeService.businessEdit,
            // LR
            this.routeService.customerLrCustService,
            this.routeService.businessLrCustService,
            // M
            this.routeService.customerMCustService,
            this.routeService.businessMCustService,
            // FG
            this.routeService.customerFgCustService,
            this.routeService.businessFgCustService,
            // K
            this.routeService.customerKCustService,
            this.routeService.businessKCustService
          )
        );
        return vm;
      }),
      tap(x => {
        const filtered = filter ? x.filter(y => {
          switch(filter) {
            case 'person':
              return !y.isCompany && !y.serviceType;
            case 'business':
              return y.isCompany;
            case 'policy':
            case 'service':
              return !!y.serviceType
            case 'files':
            case 'activity':
              return false;
            default:
              return true;
          }
        }) : x;
        this.searchResultSubj.next(filtered);
        this.rawSearchResultSubj.next(filtered);
        
        if(filtered.length === 0) {
          let message = '';
          const tagName = TAGS.find(tag => tag.type === filter)?.value || filter;
          
          if (keyword && filter) {
            message = `No results for “${keyword}” under ${tagName}. Try removing the filter or changing the searched text.`;
          } else if (keyword && !filter) {
            message = `No results for “${keyword}”, try changing the searched text.`;
          } else if (!keyword && filter) {
            message = `No results for ${tagName}, try removing the filter.`;
          }
          
          this.noResultMessageSubj.next(message);
        } else {
          this.noResultMessageSubj.next('');
        }
      })
    );
  }
}
type serviceList = { name: string; policies: string[]; policiesConcat: string };
const createServiceList = R.curry((code: string, policies: string[]): serviceList => ({
  name: code,
  policies,
  policiesConcat: R.join(', ', policies)
}));

const hasPolicies = R.propSatisfies(R.complement(R.isEmpty), 'policies' as keyof serviceList)
export class ViewGlobalSearchResult {
  name: string;
  services: serviceList[];
  link: CommandRoute;

  static MapFromModel(
    model: GlobalSearchResult,
    clientLinkFn: (id) => CommandRoute, businessLinkFn: (id) => CommandRoute): ViewGlobalSearchResult {
    const codes = [
      staticConf.lrServiceCode,
      staticConf.mortgageServiceCode,
      staticConf.fgServiceCode,
      staticConf.kiwisaverServiceCode
    ];

    const filterServiceListByCode = createServiceListFilter(model.Services) as any;
    const groupedServices = R.filter(hasPolicies, R.map(filterServiceListByCode, codes));
    const primaryId = model.PrimaryCustomerId ? model.PrimaryCustomerId : model.CustomerId;

    return {
      name: model.Name,
      services: [],
      link: model.IsCompany ? businessLinkFn(primaryId) : clientLinkFn(primaryId)
    };
  }
}

const isServiceOfType = (code: string) => R.whereEq({ ServiceCode: code } as Pick<GlobalSearchResultService, 'ServiceCode'>);
const filterServicesOfType = (code: string) => R.filter<GlobalSearchResultService>(isServiceOfType(code));

const getLrNumber = R.pair(isServiceOfType(staticConf.lrServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));
const getMNumber = R.pair(isServiceOfType(staticConf.mortgageServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));
const getFgNumber = R.pair(isServiceOfType(staticConf.fgServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));
const getKNumber = R.pair(isServiceOfType(staticConf.kiwisaverServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));

const getNumberAccordingToCode = R.cond([
  getLrNumber,
  getMNumber,
  getFgNumber,
  getKNumber
]) as any;

const createServiceListFilter = R.curry((list: GlobalSearchResultService[], code: string) =>
  R.pipe(
		filterServicesOfType(code),
		R.map(getNumberAccordingToCode),
		createServiceList(code)
	)(list));


export class ViewGlobalSearch {
  name: string;
  detail: string;
  detail2: string;
  isCompany: boolean;
  isService: boolean;
  link: CommandRoute;
  email: string;
  address: string;
  contanct: string;
  tradingName: string;
  industry: string;
  serviceType: string;
  contanctName: string;
  photo: string;
  date: string;
  groupName: string;
  details?: { value: string; }[];

  static MapFromModel(
    model: GlobalSearch,
    customerLink: (id) => CommandRoute,
    businessLink: (id) => CommandRoute,
    customerLrLink: (id, sId) => CommandRoute,
    businessLrLink: (id, sId) => CommandRoute,
    customerMLink: (id, sId) => CommandRoute,
    businessMLink: (id, sId) => CommandRoute,
    customerFgLink: (id, sId) => CommandRoute,
    businessFgLink: (id, sId) => CommandRoute,
    customerKLink: (id, sId) => CommandRoute,
    businessKLink: (id, sId) => CommandRoute
  ): ViewGlobalSearch {

    let redirectTo: CommandRoute;
    if (model.IsCompany) {
      if (model.CustomerServiceId && model.ServiceType === staticConf.lrServiceCode) {
        redirectTo = businessLrLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.mortgageServiceCode) {
        redirectTo = businessMLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.fgServiceCode) {
        redirectTo = businessFgLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.kiwisaverServiceCode) {
        redirectTo = businessKLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.investmentServiceCode) {
        redirectTo = businessKLink(model.CustomerId, model.CustomerServiceId);
      } else {
        redirectTo = businessLink(model.CustomerId);
      }
    } else {
      if (model.CustomerServiceId && model.ServiceType === staticConf.lrServiceCode) {
        redirectTo = customerLrLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.mortgageServiceCode) {
        redirectTo = customerMLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.fgServiceCode) {
        redirectTo = customerFgLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.kiwisaverServiceCode) {
        redirectTo = customerKLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.investmentServiceCode) {
        redirectTo = customerKLink(model.CustomerId, model.CustomerServiceId);
      } else {
        redirectTo = customerLink(model.CustomerId);
      }
    }
    const getServiceName = (type) => {
      switch(type) {
        case ServicesCodes.LR:
          return staticConf.lrServiceHeader;
        case ServicesCodes.Mortgage:
          return staticConf.mortgageServiceHeader;
        case ServicesCodes.FG:
          return staticConf.fgServiceHeader;
        case ServicesCodes.KiwiSaver:
          return staticConf.kiwisaverServiceHeader;
        case ServicesCodes.Investment:
          return 'Investment';
        default:
          return '';
      }
    };

    const getServiceDate = (type, date) => {
      if(!date) {
        return null;
      }
      switch(type) {
        case ServicesCodes.LR:
          return `Start Date: ${date}`;
        case ServicesCodes.Mortgage:
          return `Fixed Until: ${date}`;
        case ServicesCodes.FG:
          return `First Policy Date: ${date}`;
        default:
          return '';
      }
    };

    const getDetails = (search: GlobalSearch) => {
      const isService = !!model.CustomerServiceId && !!model.ServiceType;
      
      if(isService) {
        return [
          {value: search.GroupName},
          {value: getServiceName(search.ServiceType)},
        ]
      } else {
        if(search.IsCompany) {
          return [
            {value: search.TradingName},
            {value: search.Industry},
            {value: search.PrimaryContactName},
          ]
        } else {
          return [
            {value: search.PhysicalAddress},
            {value: search.Mobile},
            {value: search.Email},
          ]
        }
      }
    };

    return {
      name: model.Name,
      details: getDetails(model),
      detail: model.Detail,
      detail2: model.Detail2,
      isCompany: model.IsCompany,
      isService: (!!model.CustomerServiceId && !!model.ServiceType),
      link: redirectTo,
      email: model.Email,
      address: model.PhysicalAddress,
      contanct: model.Mobile,
      contanctName: model.PrimaryContactName,
      photo: model.PhotoURL,
      tradingName: model.TradingName,
      industry: model.Industry,
      serviceType: getServiceName(model.ServiceType) ,
      date: getServiceDate(model.ServiceType, model.PolicyDate),
      groupName: model.GroupName
    };
  }
}
