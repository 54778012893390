<div class="col py-2" [class.loading]="loading">
	<div class="d-flex flex-row align-items-center mb-4 question-list-header">
		<div class="mr-4">
			<div>
				<span class="font-weight-normal">Question</span>
			</div>
			<div>
				<span class="font-weight-bold">{{ letter }}: {{ question }}</span>
			</div>
		</div>
		<div>
			<app-manual-loader-mini
			[isLoading]="loading"
		></app-manual-loader-mini>
		</div>
	</div>

	<div class="question-list-body">
		<div class="d-flex flex-row my-3 py-3 px-0 list-header">
			<div class="col-6">
				<span>Answer</span>
			</div>
			<div class="col text-center">
				<span>Edit Answer</span>
			</div>
			<div class="col">
				<span>Score</span>
			</div>
			<div class="col text-center">
				<span>Active</span>
			</div>
		</div>
		<div class="d-flex list-items">
			<div
				class="list w-100"
				cdkDropList
				(cdkDropListDropped)="drop($event)"
				[cdkDropListData]="AnswersList.controls"
			>
				<div
					[formGroup]="item"
					class="d-flex mb-3 py-3 item drag"
					*ngFor="let item of AnswersList.controls; let i = index; trackBy: trackByFn"
					cdkDrag
					[cdkDragData]="item"
					[cdkDragDisabled]="loading || disabled"
					[class.no-drag]="loading || disabled"
				>
					<div class="col-6 w-100">
						<div class="d-flex">
							<ng-container *ngIf="!item.get('isAdding').value && !item.get('isEditing').value; else notAddEditText">
								<div class="pr-1">
									<span class="sortable-item">
										<i class="material-icons md-18 tap-text-color-h6"
											>drag_indicator</i
										>
									</span>
								</div>
								<div class="pl-1">
									<span class="answer-text">
										{{ item.get('answer').value }}
									</span>
								</div>
							</ng-container>

							<ng-template #notAddEditText>
								<textarea
									class="form-control"
									name="answer"
									id="answer-{{item.get('id').value}}"
									formControlName="answer"
									rows="8"
								></textarea>
							</ng-template>
						</div>
					</div>
					<div class="col text-center">
						<ng-container *ngIf="!item.get('isAdding').value && !item.get('isEditing').value; else notAddEditButtons">
							<button class="icon-btn" (click)="edit(i)" [disabled]="loading || disabled">
								<i class="material-icons">edit</i>
							</button>
							<button class="icon-btn" (click)="delete(i)" [disabled]="loading || disabled">
								<i class="material-icons">delete</i>
							</button>
						</ng-container>

						<ng-template #notAddEditButtons>
							<div class="mb-2">
								<button
									type="button"
									class="btn btn-primary tap-bg-primary btn-save"
									(click)="saveAddEdit(i)"
								>
									Save
								</button>
							</div>
							<div>
								<button
									type="button"
									class="btn btn-light"
									(click)="cancelAddEdit(i)"
								>
									Cancel
								</button>
							</div>
						</ng-template>
					</div>
					<div class="col">
						<input
							class="form-control w-100"
							formControlName="score"
							type="number"
							inputmode="numeric"
							step="1"
							noDecimalNumeric
							(mousedown)="$event.stopPropagation()"
							(change)="saveScore(i)"
							[attr.disabled]="((loading || disabled) && (!item.get('isAdding').value && !item.get('isEditing').value)) || null"
						/>
					</div>
					<div class="col text-center" *ngIf="!item.get('rendering').value">
						<div class="switch pl-2">
							<span *ngIf="(!loading && !disabled) || (item.get('isAdding').value || item.get('isEditing').value); else notAddEditSwitch">
								<app-switch
									[tempValue]="item.get('status').value === 1"
									fieldId="{{item.get('answer').value}}-{{item.get('id').value}}"
									[isEditing]="true"
									[isEditable]="!loading"
									[hideControl]="true"
									[isRequired]="false"
									[restrict]="[]"
									[noRestriction]="true"
									(fieldUpdateEvent)="updateStatus(i)"
								></app-switch>
							</span>
							<ng-template #notAddEditSwitch>
								<span>
									<app-switch
										[value]="item.get('status').value === 1"
										[isEditing]="false"
										[isEditable]="true"
										[hideControl]="true"
										[isRequired]="false"
										[restrict]="[]"
										[noRestriction]="true"
									></app-switch>
								</span>
							</ng-template>
						</div>
					</div>
					<div class="col text-center" *ngIf="item.get('rendering').value">
						<app-manual-loader-mini
							[isLoading]="true"
						></app-manual-loader-mini>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex">
			<div class="col-12 text-right">
				<button
					type="button"
					class="btn btn-primary"
					[appTheme]="{ 'background-color': 'primarycolor' }"
					(click)="addNew()"
					[disabled]="loading || disabled"
				>
					+ Add
				</button>
			</div>
		</div>
	</div>
</div>
