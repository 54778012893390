import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RiskAnalysisLifeState } from 'src/app/shared/models/client-review-template/risk-analysis/life/life.model';

export interface LifeState extends EntityState<RiskAnalysisLifeState> {
  activeParentCRTId: number;
  formValue: RiskAnalysisLifeState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'life', idKey: 'parentCRTId' })
export class LifeStore extends EntityStore<LifeState> {
  constructor() {
    super({
      activeParentCRTId: null,
      formValue: null,
    });
  }

  setActiveParentCRTId(parenCRTId) {
    this.update((state) => ({ activeParentCRTId: parenCRTId }));
  }

  setFormValue(formValue) {
    this.update((state) => ({ formValue }));
  }
}
