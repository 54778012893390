import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { ConfirmationCallEmailSettings } from './confirmation-call-email-settings.model';
import { catchError, map, take, tap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { objectUtil } from '@util/util';
import { Observable, of } from 'rxjs';
import { SettingsTypes } from '@modules/crt-settings/state/crt-settings.model';
import { ConfirmationCallStore } from './confirmation-call.store';
import { ConfirmationCallQuery } from './confirmation-call.query';
import {
	DocumentModelState,
	DocumentUploadState,
} from '@shared/models/documents/document.model';
import { ConfirmationCallFinalSectionSetting, ConfirmationCallQuestionSetting } from './confirmation-call-question.model';
import { ConfirmationCallQuestionStore } from './confirmation-call-question.store';
import { ConfirmationCallFinalSectionStore } from './confirmation-call-final-section.store';
import { mtMergeContent } from '@shared/converter/content-merge-tags';
import { ConfirmationCallQuestionQuery } from './confirmation-call-question.query';
import { ConfirmationCallFinalSectionQuery } from './confirmation-call-final-section.query';
import { clone } from 'ramda';

@Injectable({
	providedIn: 'root',
})
export class ConfirmationCallSettingsService {
	emailSettings$ = this.query.emailSettings$;

	staticMergeTags = [
		{
			metaKey: 'ADVISER_NAME',
			value: 'Adviser Name',
		},
		{
			metaKey: 'ADVISER_FIRST_NAME',
			value: 'Adviser First Name',
		},
		{
			metaKey: 'LIFE_ASSURED',
			value: "Life Assured's",
		},
		{
			metaKey: 'COMMENCEMENT_DETAILS',
			value: 'Commencement Details',
		},
	];

	finalSection$ = this.confirmationCallFinalSectionQuery.select((state) => {
		if(!state?.finalSection) {
			return null;
		}

		return state.finalSection;
	})
	// questions$ = this.confirmationCallQuestionQuery.
	questions$ = this.confirmationCallQuestionQuery.select((state) => {
		if (!state?.questions) {
			return [];
		}
		return state.questions.map((q) => {
			const question = clone(q);
			question.question = this.mergeTags(question.question, this.staticMergeTags as any);
			return question;
		});
	});

	activityAssignedTo$ = this.confirmationCallQuestionQuery.select((state) => {
		if(!state?.activityAssignedUser) {
			return null;
		}

		return state.activityAssignedUser;
	});
	automaticActivity$ = this.confirmationCallQuestionQuery.select((state) => {
		return !!state.automaticActivity;
	});

	constructor(
		private api: ApiService,
		private store: ConfirmationCallStore,
		private query: ConfirmationCallQuery,
		private confirmationCallQuestionStore: ConfirmationCallQuestionStore,
		private confirmationCallQuestionQuery: ConfirmationCallQuestionQuery,
		private confirmationCallFinalSectionStore: ConfirmationCallFinalSectionStore,
		private confirmationCallFinalSectionQuery: ConfirmationCallFinalSectionQuery,

	) {}

	getEmailSettings(
		data: number,
		settingsCode: SettingsTypes
	): Observable<ConfirmationCallEmailSettings> {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<ConfirmationCallEmailSettings>(endpoint).pipe(
			map((x) => {
				const state = x ? objectUtil.mapPascalCaseToCamelCase(x) : {};
				this.store.setEmailSettings(state);
				return state;
			}),
			catchError(() => of({}))
		);
	}

	getFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	updateEmailSettings(settings: ConfirmationCallEmailSettings) {
		const endpoint = `crt/settings/${settings.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(settings);
		return this.api.put<ConfirmationCallEmailSettings>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.setEmailSettings(settings);
				})
			),
			take(1)
		);
	}

	private mapQuestionToSave(state: ConfirmationCallQuestionSetting): ConfirmationCallQuestionSetting {
		state.questions = state.questions.map((q) => {
			q.status = q.status ? 1 : 0;
			return q;
		})
		return state;
	}

	private mapQuestionToView(state: ConfirmationCallQuestionSetting): ConfirmationCallQuestionSetting {
		state.questions = state.questions.map((q) => {
			q.status = q.status === 1 ? true : false;
			return q;
		})
		return state;
	}

	getQuestions(): Observable<ConfirmationCallQuestionSetting> {
		const endpoint = `crt/settings/0/LOATCCQ`;
		return this.api.get<ConfirmationCallQuestionSetting>(endpoint).pipe(
			map((result) => objectUtil.mapPascalCaseToCamelCase(result)),
			tap((result) => {
				this.confirmationCallQuestionStore.update(this.mapQuestionToView(result));
			})
		);
	}
	
	getFinalSection(): Observable<ConfirmationCallFinalSectionSetting> {
		const endpoint = `crt/settings/0/LOATCCF`;
		return this.api.get<ConfirmationCallFinalSectionSetting>(endpoint).pipe(
			map((result) => objectUtil.mapPascalCaseToCamelCase(result)),
			tap((result) => {
				this.confirmationCallFinalSectionStore.update(result);
			})
		);
	}

	updateFinalSection(
		finalSection: ConfirmationCallFinalSectionSetting
	) {
		const endpoint = `crt/settings/0`;
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(finalSection))
			.pipe(
				tap(() => this.confirmationCallFinalSectionStore.update(finalSection)),
				map(() => true),
				catchError((err) => of(false))
			);
	}
	updateQuestions(
		questions: ConfirmationCallQuestionSetting
	): Observable<boolean> {
		const endpoint = `crt/settings/0`;
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(this.mapQuestionToSave(questions)))
			.pipe(
				tap(() => this.confirmationCallQuestionStore.update(this.mapQuestionToView(questions))),
				map(() => true),
				catchError((err) => of(false))
			);
	}

	mergeTags(question: string, mergeTags: any[]): string {
		return mtMergeContent(question, mergeTags as any, false);
	}
}
