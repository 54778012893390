import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ClientSearchState, ClientSearchStore } from './client-search.store';
import {
  combineLatest,
  distinctUntilChanged,
  withLatestFrom,
  shareReplay,
  auditTime,
  delay,
} from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Row } from './client-search.model';
import { ClientSearchUiQuery } from './client-search-ui.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { UserQuery } from '../../../../domain/user/user.query';
import * as R from 'ramda';
import sort from 'fast-sort';
import {
  columns,
  futureWorkColumns,
  templateRow,
} from '../client-search-page/client-search-datatable.config';
import { mapToLookupObject } from '../../../../domain/dropdown-value/dropdown-value.model';
import { util } from '../../../../util/util';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Injectable()
export class ClientSearchQuery extends QueryEntity<ClientSearchState, Row> {
  private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
    map((x) =>
      R.map(
        (y) =>
          ViewDisplayValue.Map(
            y.StaffID?.toString(),
            `${y.FirstName} ${y.LastName}`
          ),
        x
      )
    ),
    map((x) => (x ? x?.sort((a, b) => a.display?.localeCompare(b.display)) : x))
  );
  private AT$ = this.dropdownQuery.orderedChoices$('AT');
  private PCLT$ = this.dropdownQuery.orderedChoices$('PCLT');
  private PCLE$ = this.dropdownQuery.orderedChoices$('PCLE');
  private CI$ = this.dropdownQuery.orderedChoices$('CI');

  columns$ = this.select((x) => x.columns);

  tableColumns$ = this.columns$.pipe(
    withLatestFrom(this.userQuery.isUserWithOptFields$, this.businessConfigQuery.adviserReworkFeature$),
    map(([tableColumns, isUserWithOptFields, adviserReworkFeature]) => {
      return tableColumns
      .filter(column => {
        return adviserReworkFeature ? 
          !(column.includes("Alt. Adviser") || column === "Adviser") || futureWorkColumns.find(f => f.metakey === column) :
            !futureWorkColumns.find(f => f.metakey === column);
      })
        ?.map((col) => {
          let futureCol = null;

          if (adviserReworkFeature) {
            futureCol = futureWorkColumns.find(f => f.metakey === col);
          }

          return futureCol ? futureCol : columns?.find(y => 
            !isUserWithOptFields
              ? y.metakey === col
              : y.metakey === col &&
                y.metakey !== 'Mobile' &&
                y.metakey !== 'Email' &&
                y.metakey !== 'Physical Address'
          );
        })
        ?.filter((x) => x);
    }),
    withLatestFrom(this.select((x) => x.columnWidths)),
    map(([tableColumns, widths]) =>
      tableColumns?.map((column) => {
        const widthConf = widths?.filter(x => x)?.find(
          (width) => width.metakey === column.metakey
        );
        const newColumn = { ...column, $$id: column.name };
        if (widthConf) {
          return { ...newColumn, width: widthConf.width };
        } else {
          return newColumn;
        }
      })
    ),
    combineLatest(this.advisers$, this.AT$, this.PCLT$, this.PCLE$, this.CI$),
    auditTime(0),
    map(([cols, adv, , pclt, pcle, ci]) => {
      return cols?.map((x) => {
        if (
          x.metakey === 'Adviser' ||
          x.metakey === 'LR Adviser' || 
          x.metakey === 'GI Adviser' ||
          x.metakey === 'Mortgage Adviser' ||
          x.metakey === 'FG Adviser' ||
          x.metakey === 'KS Adviser' ||
          x.metakey === 'Investment Adviser' ) {
          return {
            ...x,
            choices: adv,
            choicesAsObject: mapToLookupObject(adv),
          };
        } else if (x.metakey === 'Lead Type') {
          return {
            ...x,
            choices: pclt,
            choicesAsObject: mapToLookupObject(pclt),
          };
        } else if (x.metakey === 'Lead Origin') {
          return {
            ...x,
            choices: pcle,
            choicesAsObject: mapToLookupObject(pcle),
          };
        } else if (x.metakey === 'Industry') {
          return {
            ...x,
            choices: ci,
            choicesAsObject: mapToLookupObject(ci),
          };
        } else {
          return x;
        }
      });
    }),
    distinctUntilChanged((x, y) => R.equals(x, y)),
    shareReplay(1)
  );
  hiddenTableColumns$ = this.columns$.pipe(
    withLatestFrom(this.userQuery.isUserWithOptFields$, this.businessConfigQuery.adviserReworkFeature$),
    map(([tableColumns, isUserWithOptFields, adviserReworkFeature]) => {
      const allColumns = [...columns, ...(adviserReworkFeature ? futureWorkColumns : [])];

      return allColumns?.filter((x) => {
        return !isUserWithOptFields
        ? !tableColumns?.includes(x.metakey)
        : !tableColumns?.includes(x.metakey) &&
          x.metakey !== 'Mobile' &&
          x.metakey !== 'Email' &&
          x.metakey !== 'Physical Address' &&
          x.metakey !== 'Work'
          })
          ?.filter((x) => adviserReworkFeature ? x.metakey !== 'Adviser' : x )
    }),
    shareReplay(1)
  );
  sorts$ = this.uiStore.select((x) => [{ dir: x.sort, prop: x.propSort }]);
  cellsLoading$ = this.uiStore.select((state) => state.cellLoadingStatus);
  cellsEditing$ = this.uiStore.select((state) => state.cellEditStatus);
  cellsTempvalue$ = this.uiStore.select((state) => state.cellTempValue);
  rowsLoading$ = this.uiStore.select((state) => state.rowLoadingStatus);
  isSearching$ = this.uiStore.select((state) => state.isSearching);
  searchForm$ = this.select((x) => x.searchForm);
  isComplete$ = this.select((x) => x.isComplete);
  allAdvisers$ = this.blstaffQuery.allStaffsChoices$;

  templateRow$: Observable<Row> = of(templateRow);
  rows$: Observable<Row[]> = this.selectAll();
  hasRows$: Observable<boolean> = this.selectCount().pipe(map((x) => x > 0));

  count$ = this.select((x) => x.count);

  /** Rows sorted using fast-sort.
   *  Create a list of values(`decorated`) with different sortValues (Schwatzian)
   *  use fast-sort to sort rows
   *  get value and leave sort values behind.
   */
  sortedRows$: Observable<Row[]> = this.uiStore
    .select((x) => [x.propSort, x.sort])
    .pipe(
      distinctUntilChanged((p, q) => R.equals(p, q)),
      combineLatest(this.rows$),
      withLatestFrom(this.tableColumns$),
      withLatestFrom(this.isSearching$),
      withLatestFrom(this.allAdvisers$),
      delay(0),
      map(
        ([
          [[[[prop, sortDirection], rows], tableColumns], isSearching],
          allAdvisers,
        ]) => {
          if (sortDirection === '' || prop === '' || isSearching) {
            return rows;
          } else {
            const column = tableColumns?.find((x) => x.prop === prop);
            if (util.isNullOrEmpty(column)) {
              return rows;
            }

            const decorated = rows?.map<[number, any, number, Row]>((r) => {
              const adviserColumns = [
                'Adviser',
                'LRAdviser',
                'GroupInsuranceAdviser',
                'MortgageAdviser',
                'FGAdviser',
                'KiwiSaverAdviser',
                'InvestmentAdviser'
              ]
              const actualValue = column.sortValueGetter(
                r[prop],
                adviserColumns.includes(prop) ? allAdvisers : column.choices
              );
              return [
                this.spaceSortValueGetter(actualValue),
                R.defaultTo('', actualValue),
                r.CustomerId,
                r,
              ];
            });
            return sort(decorated)
              .by([
                { asc: (u) => u[0] },
                {
                  [sortDirection]: (u) =>
                    u[1] && isNaN(u[1]) ? u[1]?.toLowerCase() : u[1],
                } as any,
                { asc: (u) => u[2] },
              ])
              ?.map((x) => x[3]);
          }
        }
      )
    );

  getCustomerName = (customerId: number) =>
    this.getEntity(customerId).Name.value;

  constructor(
    protected dropdownQuery: DropdownValueQuery,
    protected blstaffQuery: BLStaffsQuery,
    protected clientSearchStore: ClientSearchStore,
    public uiStore: ClientSearchUiQuery,
    protected userQuery: UserQuery,
    protected businessConfigQuery: BusinessConfigQuery
  ) {
    super(clientSearchStore);
  }
  /** Create a value usable as index for sorting.
   * Its only necessary to know if value is empty or not.
   * So if it is not empty, return 1 which is first in sort index.
   * And 2 if empty.
   */
  private spaceSortValueGetter(
    fieldValue: string | number | null | undefined
  ): 1 | 2 {
    let stringValue: string;
    if (util.isNullOrEmpty(fieldValue)) {
      stringValue = '';
    } else if (typeof fieldValue === 'string') {
      stringValue = fieldValue?.trim();
    } else {
      stringValue = fieldValue?.toString();
    }
    if (stringValue === '') {
      return 2;
    } else {
      return 1;
    }
  }
}
