import { CommandRoute } from 'src/app/core/config/route.service';

export interface Sidebar {
	id?: string;
	name?: string;
	link?: string | CommandRoute;
	isSubMenu?: boolean;
	subMenu?: Sidebar[];
	customClass?: string;
	borderColor?: string;
	isComplete?: boolean;
	CompleteClass?: string;
	isActive?: boolean;
	isHidden?: boolean;
	status?: SidebarStatus;
	warning?: string;
	pageCode?: string;
	isDisabled?: boolean;
	parentRouterLink?: string;
}

export enum SidebarStatus {
	Unopened = 0, // Gray - Not visited
	Incomplete = 1, // Orange - Invalid
	Completed = 2, // Green - Passed Validation
}
