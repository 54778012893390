import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { MortgageMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/mortgage/mortgage.mapper';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { MortgageInfo } from './../../../../../models/client-review-template/assets-liabilities/mortgage/mortgage.model';
import { BusinessService } from '@core/business/business.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-mortgages-form',
	templateUrl: './mortgages-form.html',
	styleUrls: ['./mortgages-form.scss'],
})
export class MortgagesFormComponent implements OnInit {
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() loanType: ViewDisplayValue[];
	@Input() lender: ViewDisplayValue[];
	@Input() mortgageFrequency: ViewDisplayValue[];
	@Input() borrowers: ViewDisplayValue[];
	@Input() securities: ViewDisplayValue[];
	@Input() mortgageInfo: MortgageInfo;
	@Input() isLoading: boolean;
	@Input() viewMode: boolean;
	@Input() hasLoanLimit: boolean;
	@Input() hasLoanRepaymentDate: boolean;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;
	isLoadingBtn = false;

	cancelLoading: boolean;
	enableLoatV2$: Observable<boolean>;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private businessService: BusinessService
	) {
		this.enableLoatV2$ = this.businessService.loatv2Enabled$;
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();
	}

	prepareData() {
		this.form.reset(MortgageMapper.mapToView(this.mortgageInfo));
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	}

	get LoanValue() {
		return this.form.get('loanValue');
	}
	get ActualRepayment() {
		return this.form.get('actualRepayment');
	}
	get MortgageFrequency() {
		return this.form.get('mortgageFrequency');
	}
	get LoanType() {
		return this.form.get('loanType');
	}
	get Purpose() {
		return this.form.get('purpose');
	}
	get Lender() {
		return this.form.get('lender');
	}

	buildForm() {
		this.form = this.fb.group({
			lender: ['', Validators.required],
			borrowingEntities: [''],
			security: [''],
			loanValue: ['', [Validators.required, Validators.min(0)]],
			actualRepayment: ['', Validators.required],
			mortgageFrequency: ['Fortnightly', Validators.required],
			interestRate: [''],
			loanType: ['', Validators.required],
			fixedPeriodEnd: [''],
			loanTerm: [''],
			purpose: ['', Validators.required],
			loanLimit: [''],
			loanRepaymentDate: [''],
		});
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.borrowers);
	}

	save() {
    if (this.isLoading || this.cancelLoading) {
      return;
    }
    this.submitted = true;
    this.isLoadingBtn = true;
    let warningMessage = '';
    if (this.form.invalid) {
      if(this.LoanValue.hasError('min')) {
        warningMessage = `${logMessage.oat.shared.factFind.assetsLiabilities.mortgages.warning.minimum} `;
      }
      if (
				this.Lender.hasError('required') ||
				this.MortgageFrequency.hasError('required') ||
				this.LoanValue.hasError('required') ||
				this.LoanType.hasError('required') ||
				this.Purpose.hasError('required') ||
				this.ActualRepayment.hasError('required')
			) {
        warningMessage = warningMessage.concat(
					logMessage.shared.general.warning.required
				);
      }
      this.loggerService.Warning(null, warningMessage);
      this.isLoadingBtn = false;
      return;
    }
    const value = this.form.value;
    this.saveEvent.emit(
      !!this.mortgageInfo
        ? {
            ...value,
            cRTId: this.mortgageInfo.CRTId,
            customerServiceID: this.mortgageInfo.CustomerServiceID,
          }
        : value
    );
    this.isLoadingBtn = false;
	}
	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => this.cancelLoading = false, 500);
	}
}
