<form [formGroup]="form" [class.loatv2]="loatv2Enabled" [class.submitted]="submitted">
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Address </label>
		</div>
		<div class="col-12 col-lg-10">
			<app-address-input
				class="crt-form-control-field"
				inputId="houseAddressId"
				formControlName="address"
				textboxClass="theme-crm-field-disabled"
				tabindex="19"
				[attr.disabled]="viewMode"
			>
			</app-address-input>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Sum Insured
			</label>
		</div>
		<div class="col-12 col-lg-4 mb-2 mb-lg-0">
			<div class="dollar-icon">
				<input
					id="sumInsuredId"
					class="form-control crt-form-control"
					formControlName="sumInsured"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Premium </label>
		</div>
		<div class="col-12 col-lg-4">
			<div class="dollar-icon">
				<input
					id="premiumId"
					class="form-control crt-form-control"
					formControlName="premium"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Provider
			</label>
		</div>
		<div class="col-12 col-lg-4 mb-2 mb-lg-0">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectCurrentProviderId"
					formControlName="provider"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of currentProvider$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Current Excess
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<div class="dollar-icon">
				<input
					id="currentExcessId"
					class="form-control crt-form-control"
					formControlName="currentExcess"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Last Reviewed
			</label>
		</div>
		<div class="col-12 col-lg-3 not-required">
			<app-date-input
				class="crt-form-control-field"
				textboxId="lastReviewedId"
				formControlName="lastReviewed"
				placeholder="dd/mm/yyyy"
				[attr.disabled]="viewMode"
			></app-date-input>
		</div>
	</div>

	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				Claimed In Last 12 months?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="col-md-12 p-0" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectClaimIn12MonthsId"
					class="form-control crt-form-control"
					formControlName="hasClaimedInLast12Months"
					(change)="selectChange()"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>

	<ng-container *ngIf="showClaimInLast12Months">
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold">
					Claimant
				</label>
			</div>
			<div class="col-12 col-lg-4">
				<input
					id="claimDriverId"
					class="form-control crt-form-control"
					formControlName="driverName"
					type="text"
				/>
			</div>
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold">
					What happened
				</label>
			</div>
			<div class="col-12 col-lg-4">
				<ng-container *ngIf="WhatHappened?.value === 'Other'; else whatHappendDropdown">
					<input
						id="whatHappenedOtherId"
						class="form-control crt-form-control"
						formControlName="whatHappenedOther"
						type="text"
						placeholder="Other"
					/>
				</ng-container>
				<ng-template #whatHappendDropdown>
					<span class="col-md-12 p-0" [class.crt-custom-dropdown]="!viewMode">
						<select
							id="selectWhatHappenedId"
							formControlName="whatHappened"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of APCRTCLC$ | async" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</ng-template>
			</div>
		</div>
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold"> Date </label>
			</div>
			<div class="col-12 col-lg-4 not-required">
				<app-date-input
					class="crt-form-control-field"
					id="claimDateId"
					formControlName="date"
					placeholder="dd/mm/yyyy"
					[attr.disabled]="viewMode"
				></app-date-input>
			</div>

			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold"> Cost </label>
			</div>
			<div class="col-12 col-lg-4">
				<div class="dollar-icon">
					<input
						id="claimCostId"
						class="form-control crt-form-control"
						formControlName="cost"
						type="text"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				House Alarmed
			</label>
		</div>
		<div class="col-12 col-lg-3">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectHouseAlarmedId"
					class="form-control crt-form-control"
					formControlName="houseAlarmed"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Specificied Items
			</label>
		</div>
		<ng-container class="item-list" formArrayName="specificItemList">
			<ng-container
				*ngFor="let item of SpecificItemList.controls; let pointIndex=index"
				[formGroupName]="pointIndex"
			>
				<div class="col-12 col-lg-3" [class.offset-lg-2]="pointIndex > 0">
					<span class="col-md-12 p-0" [class.crt-custom-dropdown]="!viewMode">
						<select
							id="selectSpecifiedItemId_{{pointIndex}}"
							formControlName="specifiedItem"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of APCRTSI$ | async" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-12 col-lg-3">
					<input
						id="itemDescriptionId_{{pointIndex}}"
						class="form-control crt-form-control"
						type="text"
						formControlName="description"
						placeholder="Description"
					/>
				</div>
				<div class="col-12 col-lg-3">
					<div class="dollar-icon">
						<input
							id="itemValueId_{{pointIndex}}"
							class="form-control crt-form-control"
							type="text"
							formControlName="value"
							placeholder="Value"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-12 col-lg-1">
					<ng-container
						*ngIf="item.get('btnDelete').value && SpecificItemList.length > 1 && !viewMode"
					>
						<button
							id="deleteSpecificItemId_{{pointIndex}}"
							type="button"
							class="icon-btn"
							(click)="deleteItem(pointIndex)"
						>
							<i class="material-icons md-16"> delete </i>
						</button>
					</ng-container>
					<ng-container *ngIf="item.get('btnAdd').value && !viewMode">
						<button id="addSpecificItemId_{{pointIndex}}" type="button" class="icon-btn" (click)="addItem()">
							<i class="material-icons md-16">add</i>
						</button>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>
	</div>

	<div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button
				id="closeContentsPolicyBtn"
				type="button"
				class="btn-light btn-block mb-lg-0"
				(click)="cancelClick()"
			>
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
				id="saveContentsBtn"
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
				(click)="save()"
				[disabled]="loading"
			>
				Save
				<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				id="cancelContentsBtn"
				type="button"
				class="btn-light btn-block mb-2 mb-lg-0"
				(click)="cancelClick()"
				[disabled]="loading"
			>
				Cancel
			</button>
		</ng-template>
	</div>
</form>
