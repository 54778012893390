<form [formGroup]="form" novalidate (ngSubmit)="save()">
	<div class="container-fluid clearfix px-0">
		<div *ngIf="!addMode" class="row clearfix align-items-center mb-1">
			<div class="col-lg col-12"></div>
			<div class="col-lg-auto col-12 d-flex">
				<span class="position-relative" *ngIf="isSaving$ | async">
					<app-manual-loader-mini
						[isLoading]="isSaving$ | async"
						class="p-0"
					></app-manual-loader-mini>
				</span>
				<ng-container *ngIf="!(isSaving$ | async)">
					<ng-container *ngIf="isLead">
						<ng-container *appRestrictTo="['FEL']">
							<span class="position-relative" *ngIf="!(isEdit$ | async)">
								<button
									type="button"
									class="icon-btn"
									(click)="edit()"
									id="businessProfileEdit"
								>
									<i class="material-icons md-20 tap-text-color-h6"> edit </i>
								</button>
							</span>
						</ng-container>
						<ng-container *appRestrictTo="['FAL', 'FEL']">
							<ng-container *ngIf="isEdit$ | async">
								<span class="position-relative">
									<button
										type="submit"
										class="icon-btn"
										id="businessProfileSave"
									>
										<i class="material-icons md-20 tap-text-color-h6"> save </i>
									</button>
								</span>
								<span class="position-relative">
									<button
										type="cancel"
										class="icon-btn"
										(click)="cancel()"
										id="businessProfileCancel"
									>
										<i class="material-icons md-20 tap-text-color-h6">
											cancel
										</i>
									</button>
								</span>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!isLead">
						<ng-container *appRestrictTo="['FEC']">
							<span class="position-relative" *ngIf="!(isEdit$ | async)">
								<button
									type="button"
									class="icon-btn"
									(click)="edit()"
									id="businessProfileEdit"
								>
									<i class="material-icons md-20 tap-text-color-h6"> edit </i>
								</button>
							</span>
						</ng-container>
						<ng-container *appRestrictTo="['FEC', 'FAC']">
							<ng-container *ngIf="isEdit$ | async">
								<span class="position-relative">
									<button
										type="submit"
										class="icon-btn"
										id="businessProfileSave"
									>
										<i class="material-icons md-20 tap-text-color-h6"> save </i>
									</button>
								</span>
								<span class="position-relative">
									<button
										type="cancel"
										class="icon-btn"
										(click)="cancel()"
										id="businessProfileCancel"
									>
										<i class="material-icons md-20 tap-text-color-h6">
											cancel
										</i>
									</button>
								</span>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg-9 order-lg-1">
				<div class="form-row align-items-center mb-2">
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="tradingName">Trading As</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="tradingName"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="tradingName"
									tabindex="7"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="industry">Industry</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="industry"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="industry"
									tabindex="8"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="website">Website</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="website"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="website"
									tabindex="9"
								/>
							</div>
						</div>
					</div>
				</div>

				<!-- 2nd Row -->
				<div class="form-row align-items-center mb-2">
					<div class="col-lg-4">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="annualTurnover">Annual Turnover</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="annualTurnover"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="annualTurnover"
									appTwoDigitDecimalNumber
									tabindex="10"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="iRDNumber">IRD Number</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="iRDNumber"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="iRDNumber"
									tabindex="11"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<hr />

		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg-9 order-lg-1">
				<div class="form-row align-items-center">
					<!-- Primary Contact -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<span
									class="tap-required-field"
									*ngIf="primaryContactInvalid$ | async"
									>*</span
								>
								<label for="primaryContact">Primary Contact</label>
							</div>
							<div class="col-lg-8 col-12">
								<app-client-search-control
									textboxId="primaryContact"
									textboxClass="theme-crm-field-disabled"
									formControlName="primaryContact"
									[tabindex]="12"
									[businessInfo]="business"
								></app-client-search-control>
							</div>
						</div>
					</div>
					<!-- Mobile -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="mobile">Mobile</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="mobile"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="mobile"
									tabindex="13"
								/>
							</div>
						</div>
					</div>
					<!-- Work Phone -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="work">Work Phone</label>
							</div>
							<div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="work"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="work"
									tabindex="14"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row clearfix align-items-center mb-2">
			<!-- Email -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-2 col-12 label-padding-bottom-md">
						<label for="email">Email</label>
					</div>
					<div class="col-lg-10 col-12">
						<input
							type="text"
							formControlName="email"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="email"
							tabindex="15"
						/>
					</div>
				</div>
			</div>
			<!-- Alternate Email -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-2 col-12 label-padding-bottom-md">
						<label for="alternateEmail">Alternate Email</label>
					</div>
					<div class="col-lg-10 col-12">
						<input
							type="text"
							formControlName="alternateEmail"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="alternateEmail"
							tabindex="15"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row clearfix align-items-center mb-2">
			<!-- Mailing Address 1 -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="mailingAddress1">Mailing Address 1</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="mailingAddress1"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="mailingAddress1"
							tabindex="16"
						/>
					</div>
				</div>
			</div>
			<!-- Mailing Address 2 -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="mailingAddress2">Mailing Address 2</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="mailingAddress2"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="mailingAddress2"
							tabindex="17"
						/>
					</div>
				</div>
			</div>
			<!-- City -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="city">City</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="city"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="city"
							tabindex="18"
						/>
					</div>
				</div>
			</div>
			<!-- Postcode -->
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12 label-padding-bottom-md">
						<label for="postcode">Postcode</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="postcode"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="postcode"
							tabindex="19"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg">
				<div class="form-row align-items-center">
					<div class="col-lg-2 col-12 label-padding-bottom-md">
						<label for="physicalAddress">Physical Address</label>
					</div>
					<div class="col-lg-10 col-12">
						<app-address-input
							inputId="physicalAddress"
							formControlName="physicalAddress"
							textboxClass="theme-crm-field-disabled"
							[tabindex]="20"
						>
						</app-address-input>
					</div>
				</div>
			</div>
			<div class="col-lg"></div>
		</div>

		<hr />

		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg-9 order-lg-1">
				<div class="form-row align-items-center">
					<!-- Lead Type -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="leadType">Lead Type</label>
							</div>
							<div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="leadType"
										id="leadType"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										tabindex="21"
									>
										<option value=""></option>
										<option *ngFor="let d of leadTypeChoices" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<!-- Lead Origin -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="leadOrigin">Lead Origin</label>
							</div>
							<div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="leadOrigin"
										id="leadOrigin"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										tabindex="22"
									>
										<option value=""></option>
										<option
											*ngFor="let d of leadOriginChoices"
											[value]="d.value"
										>
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<!-- Lead Gen -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="leadGen">Lead Gen</label>
							</div>
							<div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="leadGen"
										id="leadGen"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										tabindex="23"
									>
										<option value=""></option>
										<option *ngFor="let d of leadGenChoices" [value]="d.value">
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<!-- Adviser Gen -->
					<div class="col-lg">
						<div class="form-row align-items-center">
							<div class="col-lg-4 col-12 label-padding-bottom-md">
								<label for="adviserGen">Adviser Gen</label>
							</div>
							<div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="adviserGen"
										id="adviserGen"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										tabindex="26"
									>
										<option value=""></option>
										<option *ngFor="let c of adviserGenChoices" [value]="c.value">
											{{ c.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="addMode">
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-12">Add a note</div>
				<div class="col-lg-12">
					<textarea
						class="form-control theme-crm-field-disabled"
						formControlName="note"
						rows="4"
						id="note_business"
						tabindex="24"
					></textarea>
				</div>
			</div>
		</ng-container>
	</div>
</form>

<ng-container *ngIf="!!business?.customerID && !addMode">
	<app-table-paginate-notes
		[isLead]="isLead"
		[hasAddNote]="true"
		[customerId]="business?.customerID"
		[notes]="notes?.notes"
		[count]="notes?.count"
		[hasPaging]="true"
		[getFn$]="getNotes$"
		[addFn$]="addNotes$"
		[deleteFn$]="deleteNotes$"
	></app-table-paginate-notes>
</ng-container>
