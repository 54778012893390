import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsetComponent } from './tabset.component';
import { TabComponent } from './tab/tab.component';
import { TabsModule } from 'ngx-bootstrap/tabs';

const PUBLIC = [
	TabsetComponent,
	TabComponent
]

@NgModule({
  declarations: PUBLIC,
	exports: PUBLIC,
  imports: [
    CommonModule,
		TabsModule,
  ]
})
export class TabsetModule { }
