import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FundingRequiredLoanService } from './state/loan-repaid.service';
import { FundingRequiredLoanMapper } from './state/loan-repaid.mapper';
import { combineLatest, Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FundingRequiredLoan } from './state/loan-repaid.model';

// import { PropertyService } from './state/property/property.service';
import { PropertyService  } from '../../../../../client-sop/assets-and-liabilities/state/property/property.service';
import { LiabilityService } from '../../../../../client-sop/assets-and-liabilities/state/liability/liability.service';
import { PeopleEntitiesService } from '../../../../../client-sop/people-entities/state/people-entities.service';
import { AssetService } from '../../../../../client-sop/assets-and-liabilities/state/asset/asset.service';

import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { LoanRefinanceStore } from '../../loan-refinance/state/loan-refinance.store';
import { LoanRefinanceQuery } from '../../loan-refinance/state/loan-refinance.query';
import { CrtMortgageService } from '@modules/crm/crt-page/crt-mortgage/state/crt-mortgage.service';

@Component({
  selector: 'app-loan-repaid',
  templateUrl: './loan-repaid.component.html',
})
export class LoanRepaidComponent implements OnInit, OnDestroy {

  @Input() isRefinance$: Observable<any>;

  @Input() loading: boolean;
  @Input() parentCRTId: number;
  
  isLoading = false;
  private onDestroy$ = new Subject<void>();

  @Input() loans: FundingRequiredLoan[];
  @Input() netProceeds: number;

  SU$ = this.propertyService.SU$;
	SV1$ = this.propertyService.SV1$;
	ST$ = this.propertyService.ST$;
	STI$ = this.propertyService.STI$;
	APCRTF$ = this.propertyService.APCRTF$;

  MP$ = this.liabilityService.MP$;
	MLT$ = this.liabilityService.MLT$;
	APCRTYN$ = this.liabilityService.APCRTYN$;
	APCRTYNNA$ = this.liabilityService.APCRTYNNA$;
	APCRTL$ = this.liabilityService.APCRTL$;

  policyOwners$ = this.peopleEntitiesService.policyOwnersWithCRT$;
  borrowers$ = this.policyOwners$;

  assets$ = this.assetService.assets$;
  securities$ = this.assets$.pipe(
		map((x) => x?.map((a) => ViewDisplayValue.Map(`${a.cRTId}`, `${a.asset}`)))
	);

  isLoadedRefinanceLoans$ = this.refinanceQuery.isLoadedLoans$;
	getOwnerChoices = this.moatService.getOwnerChoices;

  @Input() deceasedSciList: any[];

  constructor(
    private service: FundingRequiredLoanService,
    private propertyService: PropertyService,
    private liabilityService: LiabilityService,
    private peopleEntitiesService: PeopleEntitiesService,
    private assetService: AssetService,
    private refinanceQuery: LoanRefinanceQuery,
		private moatService: CrtMortgageService
  ) {}
  
  ngOnInit(): void {
    this.service.get(this.parentCRTId).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe();

    this.isLoadedRefinanceLoans$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((loaded) => {
      if (loaded) {
        this.service.reUpdateStore();
      }
    });

    combineLatest([
      this.isRefinance$,
      this.isLoadedRefinanceLoans$
    ])
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(([isRefinance, isLoadedRefinanceLoans]) => {
      this.isLoading = (isRefinance && !isLoadedRefinanceLoans);
    });
  }

  update = (data) => {
    data.parentCRTId = this.parentCRTId;
    const processedData = FundingRequiredLoanMapper.mapToDetails(data);
    return this.service.update(processedData)
  }

  add = (data) => {
    data.parentCRTId = this.parentCRTId;
    data.sourceType = 'L';
    const processedData = FundingRequiredLoanMapper.mapToDetails(data);
    return this.service.add(processedData);
  }

  clearData() {
    this.service.clearData();
  }

  ngOnDestroy(): void {
    this.clearData();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
