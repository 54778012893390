import { SoaSettingsState } from './soa-settings.model';
import * as R from 'ramda';
import {
	DefaultFileNames,
	DocumentTypesMOAT,
} from '../../../../shared/models/documents/document.model';
import { SettingsTypes } from '../../state/mortgage-settings.model';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import MomentUtil from 'src/app/util/moment.util';
import { roaWordingsMetaKey } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/structure-soa/structure-soa.merge-tag';

export class SoaSettingsMapper {
	public static mapMergeTags(data: MergeTagState[]) {
		return data?.map((item) =>
			item?.metaKey === 'DATE_TODAY'
				? {
						...item,
						value: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
						secondaryValue: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
				  }
				: item
		);
	}

	public static mapMergeTagCodes(data = []) {
		const newList = R.sortBy(R.compose(R.toLower, R.prop('description')))(data);
		return newList?.reduce((acc, cur: any) => {
			return { ...acc, [cur.metaKey]: cur.description };
		}, {});
	}

	public static mapDocumentUpload(document, template) {
		if (template === 0 || isNaN(template) || R.isNil(template)) {
			return {
				document,
				referenceId: 0,
				fileName: DefaultFileNames.ROA,
				type: DocumentTypesMOAT.ROAUpload,
			};
		}
		return {
			document,
			documentId: +template,
		};
	}

	public static mapSoaMtWordingPreviewValues(data = []) {
		return data?.map((item) =>
			item?.metaKey?.includes('SOA_')
				? {
						...item,
						value: item?.value?.replace(/\n|\t/g, '<br>'),
						secondaryValue: item?.value?.replace(/\n|\t/g, '<br>'),
				  }
				: item
		);
	}

	public static mapSoaMergeTagWording(
		mergeTags = [],
		isPreview: boolean = false
	) {
		const getContent = (metaKey) => {
			const data = mergeTags?.find((x) => x?.metaKey === metaKey);
			return isPreview ? data?.secondaryValue : data?.value;
		};

		return {
			soaRaiseComplaint: getContent(roaWordingsMetaKey.raiseComplaint) || '',
			soaThingsToConsider:
				getContent(roaWordingsMetaKey.thingsToConsider) || '',
			soaTheNextStep: getContent(roaWordingsMetaKey.nextStep) || '',
		};
	}

	public static mapToUpsert(data: SoaSettingsState) {
		return {
			...data,
			referenceId: data.referenceId || 0,
			mergeTag: data.mergeTag || [],
			type: SettingsTypes.MOATROA,
		};
	}

	public static mapToUpsertMtWording(mergeTags = [], data) {
		return mergeTags?.map((item) =>
			item?.metaKey === roaWordingsMetaKey.raiseComplaint
				? {
						...item,
						value: data?.soaRaiseComplaint || '',
				  }
				: item?.metaKey === roaWordingsMetaKey.thingsToConsider
				? {
						...item,
						value: data?.soaThingsToConsider || '',
				  }
				: item?.metaKey === roaWordingsMetaKey.nextStep
				? {
						...item,
						value: data?.soaTheNextStep || '',
				  }
				: item
		);
	}
}
