import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { DashboardQuery } from '../../state/dashboard.query';
import { filter, takeUntil, map, tap, combineLatest, withLatestFrom, startWith } from 'rxjs/operators';
import { AService } from './state/a.service';
import { Subject, Observable, of } from 'rxjs';
import { AQuery } from './state/a.query';
import { ActivityModel } from '../../../../../shared/models/_general/activity.model';
import { Column } from './state/a.store';
import { TableColumn } from '@swimlane/ngx-datatable';
import * as R from 'ramda';
import MomentUtil from '../../../../../util/moment.util';
import { util } from '../../../../../core/util/util.service';
import { RouteService, CommandRoute } from '../../../../../core/config/route.service';
import { UntypedFormControl } from '@angular/forms';
import { DateRange, DateRangeComponent } from '../../../../../shared/date-range/date-range.component';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivityModalComponent } from '../../../../../shared/modal/activity/activity-modal/activity-modal.component';
import { ActivityViewModel } from '../../../../../shared/models/_general/activity.viewmodel';
import { LocalService } from 'src/app/core/services/local.service';
declare var $: any;

@Component({
	selector: 'app-a',
	templateUrl: './a.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AComponent implements OnInit, OnDestroy, AfterViewInit {
	public static widgetCode = 'a';
	public static widgetName = 'Activities';
	public static sizeX = 500;
	public static sizeY = 480;
	public static minSizeX = 500;
	public static minSizeY = 480;

	widgetCode = AComponent.widgetCode;

	/** Element binding to daterange component */
	@ViewChild(DateRangeComponent) dateRange: DateRangeComponent;

	/**
	 * Destroy event observable. Is watched to know when to
	 * unsubscribe subscribers. emits on ngOnDestroy.
	 */
	private onDestroy$ = new Subject<void>();

	/**
	 * Activities data
	 */
	data$: Observable<ActivityModel[]> = this.query.data$;
	/**
	 * Indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.isLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.error$;

	sorts = [{ prop: 'DueDateTime', dir: 'asc' }];

	columns$: Observable<Column[]> = of([
		{
			name: 'Due Date',
			prop: 'DueDateTime',
		},
		{
			name: 'Activity Name',
			prop: 'ActivityName',
		},
		{
			name: 'Client',
			prop: 'Client',
		},
		{
			name: 'Completed',
			prop: 'IsCompleted',
		},
		{
			name: 'Cancel',
			prop: 'Cancel'
		}
	]).pipe(map(c => R.clone(c)));
	rows$: Observable<any[]> = this.data$.pipe(
		withLatestFrom(util.nowTz$),
		map(([x, now]) =>
			x?.map(c => {
				const nowTz = now;
				const dueDateTime = util.DateAndTimeStringToMomentLoose(c.DueDate, c.DueTime);
				const dueTz = util.toTz(dueDateTime, false);
				const item = {
					...c,
					DueDateTime: MomentUtil.formatToServerDate(dueDateTime),
					IsOverdue: nowTz.isAfter(dueTz),
				};
				return item;
			})
		)
	);

	/**
	 * Form control for date range.
	 * Should default to 1 week behind up to now.
	 */
	dateControl: UntypedFormControl = new UntypedFormControl({
		min: MomentUtil.formatToServerDate(MomentUtil.createMomentNz().subtract(7, 'day')),
		max: MomentUtil.formatToServerDate(MomentUtil.createMomentNz().add(7, 'day')),
	} as DateRange);

	// For adding activity
	AT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AT');
	AM$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AM');
	adviserChoices$: Observable<ViewDisplayValue[]> = this.blStaffsQuery.allActiveStaffs$;
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	constructor(
		private dashboardQuery: DashboardQuery,
		private service: AService,
		private query: AQuery,
		private routeService: RouteService,
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private modalService: BsModalService,
		private localService: LocalService
	) { }

	ngOnInit() {
		this.dashboardQuery.adviserFilter$
			.pipe(
				filter(x => x.length > 0),
				combineLatest(this.dateControl.valueChanges.pipe(startWith(this.dateControl.value))),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([advs, dateRange]) => {
				this.service.GetActivities({
					Advisers: advs?.map(x => +x),
					DateRangeMin: dateRange.min !== null ? dateRange.min : '',
					DateRangeMax: dateRange.max !== null ? dateRange.max : '',
				});
			});
	}

	/**
	 * HTML Dom manipulations
	 */
	ngAfterViewInit() {
		this.initializeTableDomConfig();
	}

	tick(id: number, checked: boolean) {
		if (checked) {
			this.service
				.complete(id)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					() => { },
					() => { },
					() => {
						return false;
					}
				);
		}
	}

	route(activity: ActivityModel & { CustomerId: number; IsAccessible: boolean; IsCompany: boolean }): CommandRoute {
		activity = {
			...activity,
			CustomerId: activity.Client
				? activity.Client.PrimaryCustomerId
					? activity.Client.PrimaryCustomerId
					: activity.Client.CustomerId
				: null,
			IsAccessible: activity.Client ? activity.Client.IsAccessible : false,
			IsCompany: activity.Client ? activity.Client.IsCompany : null,
		};
		if (activity.CustomerId === null || activity.CustomerId === 0 || !activity.IsAccessible) {
			return this.routeService.activityEdit(activity.ActivityId);
		} else if (activity.IsCompany) {
			return this.routeService.businessActivity(activity.CustomerId, activity.ActivityId);
		} else {
			return this.routeService.customerActivity(activity.CustomerId, activity.ActivityId);
		}
	}

	reorder = (reorderEvent: { column: TableColumn; newValue: number; prevValue: number }) => {
		return this.service
			.reorderColumns(reorderEvent.prevValue, reorderEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}
	resize = (resizeEvent: { column: TableColumn; newValue }) => {
		return this.service
			.resizeColumns(`${resizeEvent.column.prop}`, resizeEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	private initializeTableDomConfig() {
		$(() => {
			const tblHeader = 'datatable-header';
			const tblHeaderCell = `${tblHeader}-cell`;

			const dragState = 'dragging-state';

			let timer;

			$(document)
				// On mouse down on header cell
				.on('mousedown', '.draggable', function() {
					const _ = $(this);
					timer = setTimeout(() => {
						_.closest(`.${tblHeaderCell}`).parent().addClass(`${dragState}`);
					}, 500);
				})
				// On mouseup
				.on('mouseup', () => {
					if (timer) {
						clearTimeout(timer);
					}

					const isDragging = $(`.${dragState}`).length;
					if (isDragging) {
						$(`.${tblHeaderCell}`).parent().removeClass(`${dragState}`);
					}
				});
		});
	}

	/**
	 * setup Activity modal and show.
	 * closes date range control.
	 */
	createActivityModal() {
		this.dateRange.close();
		const initState: any = {
			formItem: {},
			AT$: this.AT$,
			AM$: this.AM$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: false,
			savefn: this.saveActivityFn,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: ['FCOA'],
		};
		this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${this.localService.getValue('loginType') === 'microsoft' ? 'modal-dialog-outlook-xl' : 'modal-xl'}`,
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	/**
	 * Save activity
	 */
	saveActivityFn = (ac: ActivityViewModel) =>
		this.service.saveActivity(ac).pipe(
			withLatestFrom(this.dashboardQuery.adviserFilter$, of(this.dateControl.value)),
			tap(([id, advs, dateRange]) => {
				return this.service.GetActivities({
					Advisers: advs?.map(x => +x),
					DateRangeMin: dateRange.min !== null ? dateRange.min : '',
					DateRangeMax: dateRange.max !== null ? dateRange.max : '',
				});
			})
		)

	/** dispose of observables and event handlers. */
	ngOnDestroy() {
		$(document).off('mousedown mouseup');
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
