import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Model, ModelFactory } from '../../../../../core/base/model.service';

@Injectable()
export class ServiceListService {
  private model: Model<string[]>;
  public get model$(): Observable<string[]> {
    return this.model.data$;
  }
  /**
   *
   */
  constructor(modelFactory: ModelFactory<string[]>) {
    this.model = modelFactory.create([]);
  }

	has(code: string): boolean {
    const codeList = this.model.get();
		return codeList.includes(code);
	}

  add(code: string) {
    const codeList = this.model.get();
    if (!codeList?.includes(code)) {
      codeList.push(code);
    }
    this.model.set(codeList);
  }
  remove(code: string) {
    const codeList = this.model.get();
    if (codeList?.includes(code)) {
      codeList?.splice(codeList?.indexOf(code), 1);
    }
    this.model.set(codeList);
  }
  set(codes: string[]) {
    this.model.set((codes || []));
  }
}
