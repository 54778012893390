import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class LocalService {
	constructor(private storageService: StorageService) {}
	// Set the json data to local storage
	setValue(key: string, value: any) {
		this.storageService.secureStorage.setItem(key, value);
	}
	// Get the json value from local storage
	getValue(key: string) {
		return this.storageService.secureStorage.getItem(key);
	}

	// Remove the item from the local storage
	remove(key: string) {
		return this.storageService.secureStorage.removeItem(key);
	}
	// Clear the local storage
	clear() {
		return this.storageService.secureStorage.clear();
	}
}
