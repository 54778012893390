import { contentEditable } from '../../../../../converter/content-merge-tags';
import { sosMetaKey } from './sos.merge-tag';

/**
 * For CRT LOAT Advice Process
 */
const sosIntroText = `<div ${contentEditable.false} id="${sosMetaKey.introText}"><p>%${sosMetaKey.intro}%</p></div>`;
const sosIncludedList = `<div ${contentEditable.false} id="${sosMetaKey.servicesIncludedList}">
	<ul>
		<li>%${sosMetaKey.servicesIncluded}%</li>
	</ul>
</div>`;

const sosExcludedList = `<div ${contentEditable.false} id="${sosMetaKey.servicesExcludedList}">
	<p>[REPEAT_SECTION]</p>
	<ul>
		<li>%${sosMetaKey.servicesExcluded}%</li>
	</ul>
	<p>%${sosMetaKey.servicesExcludedNotes}%</p>
	<p>[/REPEAT_SECTION]</p>
</div>`;

const sosNotesText = `<div ${contentEditable.false} id="${sosMetaKey.notesText}"><p>%${sosMetaKey.notes}%</p></div>`;

const sosOtherAdvList = `<div ${contentEditable.false} id="${sosMetaKey.otherAdviseList}">
	<p>[REPEAT_SECTION]</p>
	<ul>
		<li>%${sosMetaKey.otherAdvice}%</li>
	</ul>
	<p>%${sosMetaKey.otherAdviceNotes}%</p>
	<p>[/REPEAT_SECTION]</p>
</div>`;

export const sosMtTemplate = {
	sosIntro: sosIntroText,
	sosIncluded: sosIncludedList,
	sosExcluded: sosExcludedList,
	sosNotes: sosNotesText,
	sosOtherAdvice: sosOtherAdvList,
};

/**
 * For CRT LOAT Settings Preview
 */
const sosIntroTextSettings = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur gravida laoreet risus, non luctus lorem eleifend id.<br>`;

const sosIncludedSettings = `<ul>
		<li>Life Insurance</li>
		<li>Critical Illness</li>
	</ul>`;

const sosExcludedSettings = `<ul>
		<li>TPD Insurance</li>
	</ul>
	<p>Sample TPD Insurance Notes here</p>
	<ul>
		<li>Medical Insurance</li>
	</ul>
	<p>Sample Medical Insurance Notes here</p>`;

const sosNotesTextSettings = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur gravida laoreet risus, non luctus lorem eleifend id.<br>`;

const sosOtherAdvSettings = `<ul>
		<li>Business Risk Insurance</li>
		<li>KiwiSaver</li>
	</ul>`;

export const settingsPreview = {
	sosIntro: sosIntroTextSettings,
	sosIncluded: sosIncludedSettings,
	sosExcluded: sosExcludedSettings,
	sosNotes: sosNotesTextSettings,
	sosOtherAdvice: sosOtherAdvSettings,
};
