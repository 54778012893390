import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessProfileAddComponent } from './business-profile-add/business-profile-add.component';
import { BusinessProfileAddResolver } from './business-profile-add/business-profile-add.resolver';
import { BusinessProfileEditComponent } from './business-profile-edit/business-profile-edit.component';
import { BusinessProfileEditResolver } from './business-profile-edit/business-profile-edit.resolver';


const routes: Routes = [
	{
		path: 'add',
		data: {
			breadcrumb: 'Client: Add New Business',
		},
		component: BusinessProfileAddComponent,
		resolve: {
			data: BusinessProfileAddResolver,
		},
	},
	{
		path: ':customerId',
		data: {
			breadcrumb: 'Business',
		},
		component: BusinessProfileEditComponent,
		resolve: {
			data: BusinessProfileEditResolver,
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class BusinessProfileRoutingModule {}
