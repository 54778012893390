import { numUtil } from '../../../../../util/util';

const getPolicyFee = (data) => {
	return !data?.policyFee || data?.policyFee === 'N/A' ? 0 : +data?.policyFee;
};

const getTotalPolicyFee = (data) => {
	const total = data?.reduce((a, c) => {
		const val = getPolicyFee(c);
		return a + val;
	}, 0);
	return numUtil.formatToCurrency(total);
};

const getFsTotalPremium = (insuranceList = []) => {
	// Total Premium + Total Policy Fee
	const totalPremium = insuranceList?.reduce((a, c) => {
		const total = c?.lifeAssured?.reduce((acc, cur) => {
			const premium = +cur?.premium || 0;
			return acc + premium;
		}, 0);

		const val = +total || 0;
		return a + val;
	}, 0);
	const totalPolicy = insuranceList?.reduce((a, c) => {
		const val = getPolicyFee(c);
		return a + val;
	}, 0);
	const overallTotal = +totalPremium + +totalPolicy;
	return numUtil.formatToCurrency(overallTotal);
};

export const fsCalc = {
	getPolicyFee,
	getTotalPolicyFee,
	getFsTotalPremium,
};
