import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { OtherStatsState, OtherStatsStore } from './os.store';

/**
 * Query for Other Stats widget data
 */
@Injectable()
export class OtherStatsQuery extends Query<OtherStatsState> {

  /** Other Stats data. */
  OtherStatData$ = this.select(state =>
    state.OtherStats
  );

  /** Indicator if Other Stat is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for Other Stat pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: OtherStatsStore) {
    super(store);
  }
}
