import { BLStaffSecGroup } from './bl-staff-security-group.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { BLStaffSecGroupsStore, SecGroupState } from './bl-staff-security-groups.store';


// Query list of tl staff security group
@Injectable()
export class BLStaffSecGroupsQuery extends QueryEntity<SecGroupState, BLStaffSecGroup> {
    constructor(protected store: BLStaffSecGroupsStore) {
        super(store);
    }
}
