<app-primary-base-widget headerText="Monthly Mortgage Goal Track" [widgetCode]="widgetCode">
  <app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
  <div class="position-relative clearfix h-100 px-0 scrollable-auto-y scrollable-hidden-x">
    <div class="row mb-4">
      <app-donut-chart
        class="col-12 col-sm-4"
        headerTitle="Previous Month"
        [part]="previousMonth$ | async"
        [total]="quarterExpected$ | async"
        strokeColor="secondarycolor"
        [showCurrencySign]="true"
      >
      </app-donut-chart>

      <app-donut-chart
        class="col-12 col-sm-4"
        headerTitle="Current Month"
        [part]="currentMonth$ | async"
        [total]="quarterExpected$ | async"
        strokeColor="primarycolor"
        [showCurrencySign]="true"
      >
      </app-donut-chart>

      <app-donut-chart
        class="col-12 col-sm-4"
        headerTitle="Next Month"
        [part]="nextMonth$ | async"
        [total]="quarterExpected$ | async"
        strokeColor="tertiarycolor"
        [showCurrencySign]="true"
      >
      </app-donut-chart>
    </div>
  </div>
</app-primary-base-widget>
