import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { isNil } from 'ramda';
import { forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
	FactFindSubSectionCodes,
	RiskAnalysisSubSectionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { MergeTagState } from '../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { objectUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';

const factFindCodes: string[] = FactFindSubSectionCodes.map((code: string) =>
	code.toLowerCase()
);

const riskAnalysisCodes: string[] = RiskAnalysisSubSectionCodes.map(
	(code: string) => code.toLowerCase()
);

@Injectable()
export class CrtMergeTagsService extends ClientReviewTemplateService {
	mergeTags$ = this.query.mergeTags$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getMergeTags(tableCode: string, referenceId?: number, dataType?: string) {
		let endpoint = `crt/merge-tag/${tableCode}/reference/${referenceId || 0}`;
		if (dataType) {
			endpoint = `${endpoint}/${dataType}`;
		}
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getAdviserProviderMt(adviserId?: number) {
		const id = adviserId || +this.query.getValue().adviceProcess?.adviser;
		return forkJoin([
			this.getMergeTags('staff', id),
			this.getMtSettings(id, 'PCT'),
			this.getMtSettings(id, 'PCLR'),
			// this.getMtSettingDetails(id, 'PCLR'),
		]);
	}

	getMtSettings(referenceId: number, dataType?: string) {
		const endpoint = `crt/merge-tag/settings/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getMtSettingDetails(referenceId: number, dataType?: string) {
		const endpoint = `crt/merge-tag/setting-details/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getFactFindMt(adviceProcessId: number) {
		const body = {
			referenceId: +adviceProcessId,
			dataType: factFindCodes,
			table: ['fact-find'],
		};
		return this.postMergeTag(body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getRiskAnalysisMt(adviceProcessId: number) {
		const body = {
			referenceId: +adviceProcessId,
			dataType: riskAnalysisCodes,
			table: ['risk-analysis'],
		};
		return this.postMergeTag(body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getSosMt(adviceProcessId: number) {
		const body = {
			referenceId: +adviceProcessId,
			dataType: ['sos'],
			table: ['scope-of-service'],
		};
		return this.postMergeTag(body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	postMergeTag(data: {
		referenceId: number,
		dataType: string[],
		table: string[],
	}) {
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			catchError(() => of([]))
		);
	}

	updateMtState(data) {
		applyTransaction(() => {
			const state = this.query.getValue().mergeTags || [];
			const updatedData = data?.reduce((acc, curr) => {
				const index = acc.findIndex((item) => item.metaKey === curr.metaKey);
				if (index === -1) {
					return [...acc, curr];
				}
				acc[index] = curr;
				return acc;
			}, state);
			this.store.setMergeTags(updatedData);
		});
	}

	getDefaultMergeTags(refetch = false) {
		let requests$ = [];
		const refId = 0;

		const getMtGeneral$ = this.getMergeTags('general', refId);
		const getMtBusiness$ = this.getMergeTags('business', refId);
		if (isNil(this.query.getValue().mergeTags) || refetch) {
			requests$ = [getMtGeneral$, getMtBusiness$];
		}
		return forkJoin([...requests$]);
	}
}
