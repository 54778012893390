<div class="card-header font-weight-bold text-light" [appTheme]="{ 'background-color': 'primarycolor' }">{{header}}
</div>
<div class="card-body p-4">
    <ng-container [ngSwitch]="message">
        <ng-container *ngSwitchCase="'CarDetails'">
            <app-car-form
              (cancelEvent)="cancelChange()"
              (saveEvent)="save($event)"
              [cRTInfo]="cRTInfo"
              [cRTId]="cRTId"
              [isAdd]="isAdd"
              [currentProvider$]="fgi$"
              [APCRTCLC$]="APCRTCLC$"
              [APCRTVP$]="APCRTVP$"
              [APCRTKPY$]="APCRTKPY$"
              [APCRTYNNA$]="APCRTYNNA$"
              [loading]="isLoading"
							[viewMode]="viewMode"
							[loatv2Enabled]="loatv2Enabled$ | async"
            >
            </app-car-form>
        </ng-container>

        <ng-container *ngSwitchCase="'HouseDetails'">
            <app-house-form
              (cancelEvent)="cancelChange()"
              (saveEvent)="save($event)"
              [cRTInfo]="cRTInfo"
              [cRTId]="cRTId"
              [isAdd]="isAdd"
              [currentProvider$]="fgi$"
              [APCRTCLC$]="APCRTCLC$"
              [APCRTYNNA$]="APCRTYNNA$"
              [loading]="isLoading"
							[viewMode]="viewMode"
							[loatv2Enabled]="loatv2Enabled$ | async"
            ></app-house-form>
        </ng-container>

        <ng-container *ngSwitchCase="'ContentsDetails'">
            <app-contents-form
              (cancelEvent)="cancelChange()"
              (saveEvent)="save($event)"
              [cRTId]="cRTId"
              [cRTInfo]="cRTInfo"
              [isAdd]="isAdd"
              [currentProvider$]="fgi$"
              [APCRTCLC$]="APCRTCLC$"
              [APCRTSI$]="APCRTSI$"
              [APCRTYNNA$]="APCRTYNNA$"
              [loading]="isLoading"
							[viewMode]="viewMode"
							[loatv2Enabled]="loatv2Enabled$ | async"
            ></app-contents-form>
        </ng-container>

    </ng-container>
</div>

<ng-container *ngIf="false">
    <div class="card-footer bg-transparent footer-height">
        <label class="pt-3 pl-3">
            + Add Additional [Information]
        </label>
    </div>
</ng-container>
