import {
	Component,
	ChangeDetectionStrategy,
	Input,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	NgZone,
} from '@angular/core';
import { CommandRoute } from 'src/app/core/config/route.service';
declare var $: any;

/** For long texts that should have tooltip */
@Component({
  selector: 'app-long-display',
  templateUrl: './long-display.component.html',
  styleUrls: ['./long-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LongDisplayComponent implements OnChanges, OnDestroy {
  /** element to attach the tooltip */
  @ViewChild('longText', { static: true }) longText: ElementRef;
  /** route to go to */
  @Input() route: CommandRoute;
  /** value to show */
  @Input() value: string;
  /** class for overdue color code */
  @Input() cellClass: any;
  /** index number for use in ID */
  @Input() index: any;
  /** field name for use in ID */
  @Input() fieldId: any;
  
  constructor(private cd: ChangeDetectorRef, private ngZone: NgZone) {}

  /** run all changes updater */
  ngOnChanges(changes: SimpleChanges): void {
    this.valueChanges(changes);
  }
  /** remove tooltip */
  ngOnDestroy(): void {
    this.removeTooltip();
  }

  /**
   * routeLink click, gets stuck when using ngx-datatable.
   * Somehow solves it.
   */
  onClick() {
    this.ngZone.run(() => this.cd.detectChanges());
  }
  /** If value changes, tooltip should change value again.
   * Destroy and add tooltip with new value
   */
  valueChanges(changes: SimpleChanges) {
    const notChanged =
      changes.value === undefined ||
      changes.value.currentValue === changes.value.previousValue;
    if (notChanged) {
      return;
    }

    this.removeTooltip();
    this.addTooltip(changes.value.currentValue);
  }

  /** add tooltip. */
  private addTooltip(title: string) {
    if (!this.longText) {
      return;
    }
    $(this.longText.nativeElement).tooltip({
      placement: 'right',
      title: () => title,
    });
  }
  /** remove tooltip */
  private removeTooltip() {
    if (!this.longText) {
      return;
    }
    $(this.longText.nativeElement).tooltip('dispose');
  }
}
