import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import {
	DocumentModelState,
	DocumentUploadState,
} from '@shared/models/documents/document.model';
import { ApiService } from '@core/base/api.service';
import { MortgageSettingsQuery } from '../../state/mortgage-settings.query';
import { MortgageSettingsStore } from '../../state/mortgage-settings.store';
import { MortgageEndProcessEmailSettingsState } from './mortgage-end-process.model';
import { objectUtil } from '@util/util';

@Injectable()
export class MortgageEndProcessSettingsService {
	endProcessEmailSettings$ = this.query.endProcessEmailSettings$;

	constructor(
		private api: ApiService,
		private store: MortgageSettingsStore,
		private query: MortgageSettingsQuery
	) {}

	getSettings(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<MortgageEndProcessEmailSettingsState>(endpoint).pipe(
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			tap((res) =>
				applyTransaction(() => this.store.setEndProcessEmailSettings(res))
			),
			catchError(() => of(undefined))
		);
	}

	update(data: MortgageEndProcessEmailSettingsState) {
		const endpoint = `crt/settings/${data?.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api
			.put<MortgageEndProcessEmailSettingsState>(endpoint, body)
			.pipe(
				tap(() =>
					applyTransaction(() => {
						this.store.setEndProcessEmailSettings(data);
					})
				),
				take(1)
			);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}
}
