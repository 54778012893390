<div class="property-purchase mb-4">
  <div class="property-purchase-header font-weight-bold mb-3">
		<span [class.highlight-label-invalid]="(properties$ | async)?.length === 0"
			>Property to be purchased</span
		>
  </div>
  <ng-container *ngIf="(isLoading$ | async); else showForm">
		<div class="pb-3">
			<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
		</div>
  </ng-container>
  <ng-template #showForm>
    <app-property-purchase-form
      [SU$]="SU$"
      [SV1$]="SV1$"
      [ST$]="ST$"
      [STI$]="STI$"
      [APCRTF$]="APCRTF$"
      [propertyOwners]="propertyOwners$ | async"
      [adviceProcessId]="adviceProcessId"
      [properties]="properties$ | async"
      [addFn$]="add"
      [updateFn$]="update"
      [deleteFn$]="delete"
      [potentialPurchases]="potentialPurchases"
      [getOwnerChoices]="getOwnerChoices"
    ></app-property-purchase-form>
  </ng-template>
</div>