import * as moment from 'moment';
import { Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// DateAdapter<Moment>
export class MomentDateAdapter2 extends MomentDateAdapter {
  deserialize(value: any): Moment | null {
    let /** @type {?} */ date;
    if (value instanceof Date) {
      date = moment(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = moment(value, moment.ISO_8601, true).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return date;
    }
    return null;
  }

  /**
   * @param {?} value
   * @param {?} parseFormat
   * @return {?}
   */
  parse(value: any, parseFormat: any): any {
    if (value && typeof value === 'string') {
      return moment(value, parseFormat, this.locale, true);
    }
    return value ? moment(value).locale(this.locale) : null;
  }
}


import { staticConf } from '../../core/config/static-config.service';
import { MatDateFormats } from '@angular/material/core';


export const CUSTOM_MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: staticConf.displayDateFormat,
  },
  display: {
    dateInput: staticConf.displayDateFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
