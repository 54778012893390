import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { CrtDocumentService } from '../crm/crt-page/_shared/service/crt-document.service';
import { PageRenderedEvent } from 'ngx-extended-pdf-viewer';

@Component({
	selector: 'app-view-pdf',
	templateUrl: './view-pdf.component.html',
	styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfComponent implements OnInit {
	documentId: string;
	url: string;
	fileName = 'document.pdf';
	blobUrl: string;
	constructor(
		private route: ActivatedRoute,
		private crtDocService: CrtDocumentService
	) {}

	ngOnInit(): void {
		this.fileName = this.route.snapshot.paramMap.get('name');
		this.documentId = this.route.snapshot.paramMap.get('documentId');
		const blobUrl = this.route.snapshot.paramMap.get('blobUrl');

		const documentId = this.documentId;
		if (documentId) {
			this.crtDocService
				.getDocument(+documentId)
				.pipe(
					tap((x) => (this.url = x.DocumentLink)),
					take(1)
				)
				.subscribe();
		}
		if (blobUrl) {
			this.url = blobUrl;
		}
	}

	adjustCanvas(detail:PageRenderedEvent){
		detail.source.canvas.style.height = (detail.source.canvas.clientHeight+1.5)+'px';
		detail.source.canvas.style.width =  (detail.source.canvas.clientWidth+1.5)+'px';
	}
}
