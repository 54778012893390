export interface SecondaryProfessional {
	CustomerID: string | number;

	Phone: string;
	Name: string;
	BusinessName: string;
	ContactType: string;
	ProfessionalType: string;
	PhysicalAddress: string;
	Email: string;

	PrimaryCustomer: string;
	CustomerType: string;
	IsActive: boolean;
}

export interface SecondaryProfessionalState {
	customerID?: string | number;

	phone: string;
	name: string;
	businessName: string;
	contactType?: string;
	professionalType: string;
	physicalAddress: string;
	email: string;

	primaryCustomer?: number | string;
	customerType?: string;
	isActive?: boolean | number;

	note?: string;
}

export enum SecondaryProfessionalType {
	Solicitor = 'Solicitor',
}
