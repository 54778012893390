import { Component, Input, ViewChild } from '@angular/core';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { viewSecGroup } from '@modules/user/viewmodels/viewSecGroup';
import { Observable } from 'rxjs';
import { ManagePdTrackingComponent } from './manage-pd-tracking/manage-pd-tracking.component';

@Component({
	selector: 'app-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent {
	@Input() secGroups$: Observable<viewSecGroup[]>;
	@Input() userInfo$: Observable<BlStaffViewmodel>;
	@Input() isTapLevel: boolean;
	@Input() saveColumnSettingsFn$: (data: any) => Observable<any>;
	@Input() saveColumnWidthSettingsFn$: (data: any) => Observable<any>;
	@Input() userSecurityGroups: string;

	@ViewChild('managePdTrackingComponent') managePdTrackingComponent: ManagePdTrackingComponent;

	showPDManagement = false;

	get CanViewPDManagement() {
		return ['BO', 'BM'].includes(this.userSecurityGroups) || this.isTapLevel;
	}

	createPD() {
		this.managePdTrackingComponent.createPD();
	}

	onSelectTab(tab: any) {
		if (tab.heading === 'PD Management') {
			this.showPDManagement = true;
		} else {
			this.showPDManagement = false;
		}
	}

}
