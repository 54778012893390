import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TlAddComponent } from '../../views/tl/tl-add/tl-add.component';
import { TlEditComponent } from '../../views/tl/tl-edit/tl-edit.component';
import { TlListComponent } from '../../views/tl/tl-list/tl-list.component';
import { TlViewComponent } from '../../views/tl/tl-view/tl-view.component';
import { TlStaffsResolver } from '../../resolver/tl/tl-staffs-resolver.service';
import { TlStaffBusinessResolver } from '../../resolver/tl/tl-business-resolver.service';
import { TlSecGroupsResolver } from '../../resolver/tl/tl-sec-groups-resolver.service';
import { TlStaffResolver } from '../../resolver/tl/tl-staff-resolver.service';

const routes: Routes = [
  {
    // TL add
    path: 'add',
    data: {
      breadcrumb: 'Add User'
    },
    component: TlAddComponent,
    resolve: {
      staffs: TlStaffsResolver,
      secGroups: TlSecGroupsResolver,
      businesses: TlStaffBusinessResolver
    }
  },
  {
    path: ':id',
    resolve: {
      staffs: TlStaffsResolver,
      secGroups: TlSecGroupsResolver,
      businesses: TlStaffBusinessResolver
    },
    children: [
      {
        // TL edit
        path: 'edit',
        data: {
          breadcrumb: 'Edit User'
        },
        component: TlEditComponent,
        resolve: {
          staff: TlStaffResolver
        }
      },
      {
        // TL view
        path: '',
        data: {
          breadcrumb: 'User Info'
        },
        component: TlViewComponent,
        resolve: {
          staff: TlStaffResolver
        }
        // resolve: { staff: TlStaffResolver, secGroups: TlSecGroupsResolver }
      }
    ]
  },
  {
    // TL list
    path: '',
    component: TlListComponent,
    resolve: { staffs: TlStaffsResolver, secGroups: TlSecGroupsResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    TlSecGroupsResolver,
    TlStaffResolver,
    TlStaffBusinessResolver,
    TlStaffsResolver
  ]
})
export class TlUserRoutingModule { }
