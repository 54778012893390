import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import produce from 'immer';
import * as R from 'ramda';
import { ClientSearchUiStore } from './client-search-ui.store';
import { Row } from './client-search.model';
import { ClientSearchRequest } from '../client-search-request.model';
import { Injectable } from '@angular/core';

interface ColumnWidth {
  metakey: string;
  width: number;
}

export interface ClientSearchState extends EntityState<Row> {
  columns: string[];
  columnWidths: ColumnWidth[];
  count: number;
  totalAPI: number;
  isComplete: boolean;
  searchForm: ClientSearchRequest;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clientSearch', idKey: 'CustomerId' })
export class ClientSearchStore extends EntityStore<ClientSearchState, Row> {

  constructor(public uiStore: ClientSearchUiStore) {
    super({ columns: [], count: 0, totalAPI: 0, isComplete: false, columnWidths: [], searchForm: null });
  }

  setColumns(columns: string[]) {
    this.update(produce(x => { x.columns = columns; }));
  }

  setColumnWidths(columnWidths: ColumnWidth[]) {
    this.update(produce(x => {
      x.columnWidths = columnWidths;
    }));
  }

  setSearchForm(form: ClientSearchRequest) {
    this.update(produce(x => {
      x.searchForm = form;
    }));
  }

  updateField(req: { CustomerId: number, MetaKey: string, MetaValue: string }) {
    this.update(req.CustomerId, produce(draft => {
      const fields = R.omit(['CustomerId', 'link', 'ClientNextActivityId', 'UserNextActivityId', 'IsCompany'], draft);
      Object.values(fields).find(x => x.metakey === req.MetaKey).value = req.MetaValue;
    }));
  }
}
