<div class="clearfix">
	<div
		class="form-row font-weight-bold d-none d-lg-flex mb-2"
		[class.tap-opacity-25]="!liabilities || liabilities?.length === 0"
	>
		<div class="col-12 col-lg-3">Liability</div>
		<div class="col-12 col-lg-3">Interest Rate</div>
		<div class="col-12 col-lg-3">Loan / Limit</div>
		<div class="col-12 col-lg-3">Loan Balance</div>
	</div>

	<ng-content select="app-manual-loader-mini"></ng-content>

	<div class="mb-4">
		<app-crt-liability-form
			*ngFor="
				let l of liabilities;
				let i = index;
				let last = last;
				trackBy: trackByFn
			"
			[formId]="i"
			[liability]="l"
			[borrowers$]="borrowers$"
			[securities$]="securities$"
			[MP$]="MP$"
			[MLT$]="MLT$"
			[APCRTF$]="APCRTF$"
			[APCRTYN$]="APCRTYN$"
			[APCRTYNNA$]="APCRTYNNA$"
			[APCRTL$]="APCRTL$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="d-block mb-2"
			[class.border-bottom]="!last"
			[getOwnerChoices]="getOwnerChoices"
		></app-crt-liability-form>
	</div>

	<div class="clearfix mb-4">
		<app-crt-liability-form
			*ngIf="isAdd"
			[addMode]="true"
			[liability]="{}"
			[borrowers$]="borrowers$"
			[securities$]="securities$"
			[MP$]="MP$"
			[MLT$]="MLT$"
			[APCRTF$]="APCRTF$"
			[APCRTYN$]="APCRTYN$"
			[APCRTYNNA$]="APCRTYNNA$"
			[APCRTL$]="APCRTL$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="mb-2"
			[getOwnerChoices]="getOwnerChoices"
		></app-crt-liability-form>
	</div>

	<button
		id="liabiltyAddButton"
		(click)="add()"
		type="button"
		[disabled]="isAdd"
		class="tap-btn tap-btn-outline tap-btn--shadow px-4"
	>
		Add Liability +
	</button>
</div>
