import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';

export const AdviserStatusViewDisplay: ViewDisplayValue[] = [
  {
    display: 'Inactive',
    value: '0',
  },
  {
    display: 'Active',
    value: '1',
  },
  {
    display: 'Paused',
    value: '2',
  },
]
