import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { iif, Observable, Observer, of, Subject } from 'rxjs';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { DocumentListState, DocumentListType } from '../state/document-list.model';
import {
	concatMap,
	finalize,
	take,
	tap,
} from 'rxjs/operators';
import { DocumentListService } from '../state/document-list.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { objectUtil, strUtil } from 'src/app/util/util';
import { LoggerService } from '@core/logger/logger.service';

@Component({
  selector: 'app-soa-document-list-form',
  templateUrl: './soa-document-list-form.component.html',
  styleUrls: ['./soa-document-list-form.component.scss']
})
export class SoaDocumentListFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() documentList: DocumentListState[];

  private onDestroy$ = new Subject<void>();
	form: UntypedFormGroup;
	submitted: boolean;
	saving: boolean;
	documentTypes = DocumentListType;

  get DocumentList() {
    return this.form.get('documentList') as UntypedFormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
		private modalService: BsModalService,
    private service: DocumentListService,
		private loggerService: LoggerService
  ) { }

  ngOnChanges(changes) {
		if (changes.documentList) {
			this.buildForm();
			this.prepDocumentList();
		}
	}

	ngOnInit(): void {
    this.buildForm();
		this.prepDocumentList();
	}

	buildForm() {
		this.form = this.fb.group({
			documentList: this.fb.array([]),
		});
	}

	prepDocumentList() {
		while (this.DocumentList.length > 0) {
			this.DocumentList.removeAt(0);
		}
		this.documentList?.forEach((document: DocumentListState) => {
			this.DocumentList.push(this.patchValue(document));
		});
	}

	patchValue(document): UntypedFormGroup {
		return this.fb.group({
			...document,
			documentName: [document?.documentName, [Validators.required]],
			type: document?.type,
			sortNo: document?.sortNo || 0,
			isMultiple: document?.isMultiple,
			isDefault: document?.isDefault,
			isEnable: document?.isEnable,
			isActive: document.isActive
		});
	}

	deleteModal(index: number, document) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteDocument(index);
			obs.complete();
		});
		const initState = {
			header: 'Delete Document',
			message: `Are you sure you want to delete document, ${document?.value?.title}?`,
			delete$: confirm,
			canDelete: true,
			confirmButton: 'OK',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered text-break',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	deleteDocument(index) {
		const data = this.DocumentList.getRawValue()[index];
		of(data?.settingsId)
			.pipe(
				tap(() => (this.saving = true)),
				concatMap((x) =>
					iif(
						() => x,
						this.service.deleteDocument(x),
						of(null)
					)
				),
				finalize(() => {
					this.DocumentList.removeAt(index);
					this.saving = false;
				}),
				take(1)
			)
			.subscribe();
	}

	delete(index, document = null) {
		if (document?.value.settingsId) {
			this.deleteModal(index, document);
		} else {
			this.deleteDocument(index);
		}
	}

	updateDocument(index) {
		this.saving = true;

		const data = this.DocumentList.getRawValue()[index];
		const newData = {
			...data,
			isActive: false
		}

		this.DocumentList.controls[index]
			.get('isActive')
			.setValue(false);


		if (!newData.cRTSettingsId) {
			this.saving = false;
			return;
		}

		this.service.update(objectUtil.mapCamelCaseToPascalCase(newData), false)
			.pipe(
				finalize(() => this.saving = false),
				take(1))
			.subscribe();
	}

	save() {
		if (this.form.invalid) {
			return;
		}
		const data = this.updatePriority(this.DocumentList.getRawValue());

		let seen = new Set();
		const activeData = this.DocumentList.getRawValue().filter(
			(x) => x.isActive
		);
		let hasDuplicates = activeData?.some((currentObject) => {
			return (
				seen.size ===
				seen.add(strUtil?.safeTrim(currentObject.documentName)).size
			);
		});
		if (hasDuplicates) {
			this.loggerService.Warning({}, 'Duplicate Title');
			return;
		}

		this.submitted = true;
		this.saving = true;

		this.service
			.save(Object.values(objectUtil.mapCamelCaseToPascalCase(data)), true)
			.pipe(take(1))
			.subscribe(
				() => {},
				(err) => {
					throw err;
				},
				() => {
					this.saving = false;
				}
			);
	}

	addNew() {
		this.service.addDocument(
			{
				referenceId: 0,
				type: DocumentListType.Default,
				sortNo: this.documentList.length + 1,
				isDefault: false,
				isMultiple: false,
				isActive: true,
				isEnable: true,
				documentName: null,
			} as DocumentListState,
			this.DocumentList.getRawValue()
		);
	}

	trackByFn = (index: number, item: any) => {
		return index
	}

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			// if transfer, recalculate the order of previous (the list from drag)
			event.previousContainer.data?.forEach((x, index) => {
				x.sortNo = index;
			});
		}
		// always, recalculate the order of the container (the list to drag)
		event.container.data?.forEach((x, index) => {
			x.sortNo = index;
		});
	}

	updatePriority(list) {
		return list?.map(({ order, ...item }, i) => ({
			...item,
      sortNo: i,
		}));
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

}
