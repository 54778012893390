<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Mortgage Online Advice Tool</h1>
	</div>

	<div class="row tap-container service-tabs crt-settings">
		<div class="col">
			<tabset [appTheme]="{ color: 'primarycolor' }">
				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Intro Page">
						<app-moat-intro-settings></app-moat-intro-settings>
					</tab>
				</ng-container>
				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Declaration">
						<app-declaration-settings></app-declaration-settings>
					</tab>
				</ng-container>
				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Scope of Service">
						<app-scope-of-service-settings></app-scope-of-service-settings>
					</tab>
				</ng-container>
				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Application">
						<app-review-application-settings></app-review-application-settings
					></tab>
				</ng-container>
				<ng-container
					*ngIf="['SO', 'BO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Record of Advice">
						<app-soa-settings></app-soa-settings>
					</tab>
				</ng-container>
				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="End Process">
						<app-mortgage-end-process></app-mortgage-end-process>
					</tab>
				</ng-container>
			</tabset>
		</div>
	</div>
</div>
