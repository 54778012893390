import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDetailsComponent } from './user-details.component';
import { SharedModule } from '@shared/shared.module';
import { UserComponentsModule } from '@modules/user/modules/user-components/user-components.module';
import { UserQualificationsComponent } from './user-qualifications/user-qualifications.component';
import { InputModule } from '@shared/components/input/input.module';
import { InputSelectModule } from '@shared/components/input-select/input-select.module';
import { UserQualificationModalModule } from '@shared/modal/user-qualification-modal/user-qualification-modal.module';
import { UserDocumentsComponent } from './user-documents/user-documents.component';
import { UserOnboardingDocumentsComponent } from './user-documents/user-onboarding-documents/user-onboarding-documents.component';
import { UserSpecificDocumentsComponent } from './user-documents/user-specific-documents/user-specific-documents.component';
import { UserDocumentModalModule } from '@shared/modal/user-document-modal/user-document-modal.module';
import { UserPdTrackingComponent } from './user-pd-tracking/user-pd-tracking.component';
import { UserPdTrackingWidgetsComponent } from './user-pd-tracking/user-pd-tracking-widgets/user-pd-tracking-widgets.component';
import { PDTDonutWidgetComponent  } from './user-pd-tracking/user-pd-tracking-widgets/pdt-donut-widget/pdt-donut-widget.component';
import { PDTInfoWidgetComponent  } from './user-pd-tracking/user-pd-tracking-widgets/pdt-info-widget/pdt-info-widget.component';
import { UserPdTrackingScheduledComponent } from './user-pd-tracking/user-pd-tracking-scheduled/user-pd-tracking-scheduled.component';
import { UserPdTrackingCompletedComponent } from './user-pd-tracking/user-pd-tracking-completed/user-pd-tracking-completed.component';
import { UserPdTrackingModalModule } from '@shared/modal/user-pd-tracking-modal/user-pd-tracking-modal.module';
import { SimpleDataTableModule } from '@shared/components/simple-data-table/simple-data-table.module';
import { UserPdTaskModalModule } from '@shared/modal/user-pd-task-modal/user-pd-task-modal.module';
import { InputChipsModule } from '@shared/components/input-chips/input-chips.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		InputModule,
		InputSelectModule,
		SimpleDataTableModule,
		UserComponentsModule,
		UserQualificationModalModule,
		UserDocumentModalModule,
		UserPdTrackingModalModule,
		UserPdTaskModalModule,
		InputChipsModule,
	],
	declarations: [
		UserDetailsComponent,
		UserQualificationsComponent,
		UserDocumentsComponent,
		UserOnboardingDocumentsComponent,
		UserSpecificDocumentsComponent,
		UserPdTrackingComponent,
		UserPdTrackingWidgetsComponent,
		PDTDonutWidgetComponent,
		PDTInfoWidgetComponent,
		UserPdTrackingScheduledComponent,
		UserPdTrackingCompletedComponent,
	],
	exports: [UserDetailsComponent],
})
export class UserDetailsModule {}
