import { Injectable } from '@angular/core';
import { SendTestEmail } from '../../../../shared/models/emails/send-test-email/send-test-email.model';
import { SendTestEmailStore } from './send-test-email.store';
import { ApiService } from '../../../../core/base/api.service';
import { map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { EmailHistoryStore } from '../../email-history/state/email-history.store';
import { EmailSettingsQuery } from '../../email-settings/state/email-settings.query';

@Injectable()
export class SendTestEmailService {
	constructor(
		private api: ApiService,
		private sendTestEmailStore: SendTestEmailStore,
		private emailHistoryStore: EmailHistoryStore,
		private emailSettingsQuery: EmailSettingsQuery
	) { }

	clear(): void {
		applyTransaction(() => {
			this.sendTestEmailStore.reset();
		});
	}

	getContentMegeTags(emailCode: string) {
		this.emailSettingsQuery
			.selectAll()
			.pipe(
				map(result => {
					const email = result?.filter(x => x.EmailCode === emailCode);
					this.emailHistoryStore.setContentMergeTages(email[0].ContentMergeTag);
				})
			)
			.subscribe();
	}

	resetMergeTags() {
		this.emailHistoryStore.setContentMergeTages(null);
	}

	send(sendTest: SendTestEmail, emailType: string) {
		const endpoint = `emails/test/${emailType}`;
		const body = sendTest;
		return this.api.post3<string>(endpoint, body);
	}
}
