import { DefaultFileNames, DocumentTypesMOAT } from '../../../../../shared/models/documents/document.model';
import { SettingsTypes } from '../../../state/mortgage-settings.model';
import { MoatSoaEmailSettingsState } from './moat-soa-email-settings.model';

export class MoatSoaEmailSettingsMapper {
  public static mapEmailSettingsToUpsert(data: MoatSoaEmailSettingsState) {
    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      type: SettingsTypes.MOATRoaEmail
    };
  }

  public static mapStaffEmailBody(document, docId, staffId) {
    // TO-DO Update type and fileName value
    if (docId === 0 || !docId) {
      return {
        document,
        referenceId: +staffId,
        fileName: DefaultFileNames.SOA,
        type: DocumentTypesMOAT.MOATROAEmailSettings
      };
    }
    return {
      document,
      documentId: +docId,
      type: DocumentTypesMOAT.MOATROAEmailSettings
    };
  }

  public static mapEmailSettingsData(data: MoatSoaEmailSettingsState) {
    if (data) {
      const { template, mergeTag, ...others } = data;
      return others;
    }
    return null;
  }
}

