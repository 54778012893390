import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '../base/api.service';
import { LocalService } from '../services/local.service';
import { getCompanyCode } from './../../domain/business-config/business-config.service';

export interface UserLoggedIn {
	access_token: string;
	refresh_token: string;
	expires_in: number;
	token_type: string;
	email: string;
	aud: string;
	hd: string;
	picture: string;
	AuthUrl: string;
	CompanyCode: string;
	IsAuthorized: boolean;
	IsLoggedIn: string;
	IsTL: boolean;
	AuthType?: string;
}

@Injectable()
export class AuthenticationService {
	constructor(
		private api: ApiService,
		private localService: LocalService,
	) {}

	get isLoggedIn(): boolean {
		return !!this.localService.getValue('token');
	}

	get isAuthorized(): boolean {
		const token: UserLoggedIn = this.localService.getValue('token');
		const expiresIn = this.localService.getValue('expires_in');
		return !!token && !!expiresIn && Date.now() < expiresIn;
	}

	get user(): UserLoggedIn {
		return this.localService.getValue('token');
	}

	/**
	 * Login
	 */
	login(req?: { code?: string; redirectUrl?: string; authType?: string }) {
		if (req.authType) {
			this.localService.setValue('loginType', req.authType);
		}

		const body = {
			code: req && req.code ? req.code : '',
			redirectUrl: req && req.redirectUrl ? req.redirectUrl : window.location.origin + '/login',
		};

		return this.api.post3<string | UserLoggedIn>('login', body).pipe(
			map((x) => {
				if (!!x && typeof x === 'object') {
					this.localService.setValue('token', x);
					this.localService.setValue('isTL', x.IsTL);
					this.localService.setValue('expires_in', +x.expires_in * 1000 + Date.now());

					if (x.CompanyCode) {
						this.localService.setValue('code', x.CompanyCode);
					}
				}

				return x;
			}),
		);
	}

	/**
	 * Login
	 */
	loginAutomation(req?: {
		code?: string;
		redirectUrl?: string;
		authType?: string;
		body?: { email: string; password: string };
	}) {
		if (req.authType) {
			this.localService.setValue('loginType', req.authType);
		}

		const body = {
			Email: req?.body.email,
			Password: req?.body.password,
		};

		return this.api.post3<string | UserLoggedIn>('login/email-password', body).pipe(
			map((x) => {
				if (!!x && typeof x === 'object') {
					this.localService.setValue('token', {
						...x,
						token_type: x.token_type ?? 'Bearer',
						CompanyCode: x.CompanyCode ?? '',
					});
					this.localService.setValue('isTL', x.IsTL);
					this.localService.setValue('expires_in', +x.expires_in * 1000 + Date.now());

					if (x.CompanyCode) {
						this.localService.setValue('code', x.CompanyCode);
					}
				}

				return x;
			}),
		);
	}

	/**
	 * Logout
	 */
	logout() {
		if (!this.isLoggedIn || !this.isAuthorized) {
			return of(null).pipe(
				tap(
					() => {},
					() => {},
					() => {
						const isTl = this.localService.getValue('isTL');
						const code = getCompanyCode() ? getCompanyCode() : this.localService.getValue('code');
						if (typeof isTl === 'boolean' && !isTl) {
							this.localService.remove('token');
							this.localService.remove('expires_in');
							this.localService.remove('idleTime');
							this.localService.remove('loginType');
							this.localService.remove(code.concat('_blStaffs'));
							this.localService.remove(code.concat('_blStaffs_exp'));
							window.location.href = '/' + code;
						} else {
							this.localService.clear();
							window.location.href = '/';
						}
					},
				),
			);
		}

		const user = this.user;

		return this.api.post3('login/logout').pipe(
			tap(
				() => {
					if (user && !user.IsTL && user.CompanyCode) {
						this.localService.remove('token');
						this.localService.remove('expires_in');
						window.location.href = '/' + user.CompanyCode;
					} else {
						this.localService.clear();
						window.location.href = '/';
					}
				},
				() => {
					if (user && !user.IsTL && user.CompanyCode) {
						this.localService.remove('token');
						this.localService.remove('expires_in');
						this.localService.remove('idleTime');
						window.location.href = '/' + user.CompanyCode;
					} else {
						this.localService.clear();
						window.location.href = '/';
					}
				},
			),
		);
	}

	clearToken() {
		const user = this.user;

		if (user && !user.IsTL && user.CompanyCode) {
			this.localService.remove('token');
			this.localService.remove('expires_in');
			this.localService.remove('idleTime');
		} else {
			this.localService.clear();
		}
	}

	refreshToken() {
		if (!this.isLoggedIn) {
			this.clearToken();
			return of(null);
		}

		return this.api
			.post3<UserLoggedIn>('login/refreshtoken', {
				RedirectUrl: window.location.origin + '/login',
				Code: '',
				RefreshToken: this.user.refresh_token,
			})
			.pipe(
				tap(
					(x) => {
						if (!x.access_token) {
							const isTl = this.localService.getValue('isTL');
							const code = getCompanyCode() ? getCompanyCode() : this.localService.getValue('code');
							if (!isTl) {
								this.localService.remove('token');
								this.localService.remove('expires_in');
								this.localService.remove('idleTime');
								window.location.href = '/' + code;
							} else {
								this.localService.clear();
								window.location.href = '/';
							}
						} else {
							this.localService.setValue('token', {
								...this.user,
								token_type: x.token_type,
								access_token: x.access_token,
								refresh_token: x.refresh_token,
							});
							this.localService.setValue('expires_in', +this.user.expires_in * 1000 + Date.now());
						}
					},
					(_err) => {},
				),
			);
	}
}
