<div class="form-row mb-2 others-label-background">
	<div class="col-7 col-sm-3 mt-1 mt-lg-0 mb-lg-3">
		<label for="" class="main-content-section__header">
			Previous Income Source
		</label>
	</div>
	<div class="col-5 col-sm-9 text-right iconShow">
		<button
			id="previousIncomeSource_collapseButton"
			type="button"
			class="
				btn-toggle
				bg-white
				collapse-btn
				border-0
				others-collapse-background
			"
		>
			<span *ngIf="elseMinusIncome; else elsePlusOthers">
				<i (click)="collapseMoreIncome()" class="material-icons md-20 pt-1"
					>expand_more</i
				>
			</span>
			<ng-template #elsePlusOthers>
				<span>
					<i (click)="collapseLessIncome()" class="material-icons md-20 pt-1"
						>expand_less</i
					>
				</span>
			</ng-template>
		</button>
	</div>
</div>

<div class="clearfix">
	<div
		class="form-row font-weight-bold d-none d-lg-flex mb-2"
		[class.tap-opacity-25]="(!incomeList || incomeList?.length === 0) && !isAdd"
	>
		<div class="col-12 col-lg-3">Name</div>
		<div class="col-12 col-lg-3">Previous Occupation</div>
		<div class="col-12 col-lg-3">Employer</div>
		<div class="col-12 col-lg-3">Period of Employment</div>
	</div>

	<ng-content select="app-manual-loader-mini"></ng-content>

	<div class="mb-4">
		<app-crt-previous-income-form
			*ngFor="
				let income of incomeList;
				let i = index;
				let last = last;
				trackBy: trackByFn
			"
			[formId]="i"
			[addMode]="false"
			[incomeSource]="income"
			[people]="people$ | async"
			[addFn$]="addNewFn$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[peopleFromCRMAndCRTExceptChild$]="peopleFromCRMAndCRTExceptChild$"
			[transferedSCIList]="transferedSCIList$ | async"
			[disableUpdate]="isAdd || (!!(editMode$ | async) && +(editMode$ | async) !== +income?.cRTId)"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent()"
			(saveEvent)="saveEvent()"
			(editModeEvent)="isEditMode($event)"
			class="d-block mb-2"
			[class.border-bottom]="!last"
			[deceasedSciList]="deceasedSciList$ | async"
			[getOwnerChoices]="getOwnerChoices"
		></app-crt-previous-income-form>

		<ng-container *ngIf="isAdd">
			<div class="clearfix">
				<app-crt-previous-income-form
					[addMode]="true"
					[incomeSource]="{}"
					[addFn$]="addNewFn$"
					[people]="people$ | async"
					[peopleFromCRMAndCRTExceptChild$]="peopleFromCRMAndCRTExceptChild$"
					[adviceProcessId]="adviceProcessId"
					[transferedSCIList]="transferedSCIList$ | async"
					(cancelEvent)="cancelEvent()"
					(saveEvent)="saveEvent()"
					class="mb-2"
					[deceasedSciList]="deceasedSciList$ | async"
					[getOwnerChoices]="getOwnerChoices"
				></app-crt-previous-income-form>
			</div>
		</ng-container>
	</div>

	<div class="form-row align-items-center mb-4">
		<div class="col-lg-3">
			<button
				id="assetAddButton"
				(click)="add()"
				type="button"
				[disabled]="isAdd || !!(editMode$ | async)"
				class="tap-btn tap-btn-outline tap-btn--shadow px-4"
			>
				Add Previous Income Source +
			</button>
		</div>
	</div>
	<div class="form-row line mb-3"></div>
</div>
