<div class="container-fluid pd-tracking-completed">
	<div
		class="row m-0 p-0 pd-tracking-completed__header justify-content-between"
	>
		<div class="col-auto d-flex align-items-center p-0">
			<span class="title">Completed:</span>
		</div>
		<div class="col-auto d-flex pr-0">
			<div class="col-auto pr-0">
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="checkbox"
						id="showMissed"
						(click)="showMissed()"
					/>
					<label class="form-check-label" for="showMissed">
						Show Missed
					</label>
				</div>
			</div>
			<div class="col-auto pr-1" *ngIf="CanAdd">
				<button
					type="button"
					class="btn ui-btn-gray add-btn"
					id="addCompleted"
					(click)="addCompleted()"
				>
					+ Add Completed
				</button>
			</div>
		</div>
	</div>
	<div class="row p-0 pd-tracking-completed__table mt-3 mb-3">
		<div class="col-12 p-0">
			<app-simple-data-table
				[dataSource]="dataSource"
				[displayedColumns]="displayedColumns"
				[tableColumns]="tableColumns"
				[itemPerPage]="10"
				[enablePagination]="true"
			></app-simple-data-table>
		</div>
	</div>
</div>
