import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { objectUtil } from 'src/app/util/util';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { Asset } from './asset.model';
import { AssetQuery } from './asset.query';
import { AssetStore } from './asset.store';

@Injectable()
export class AssetService {
	assets$ = this.query.assets$;
	APCRTA$ = this.ddQuery.orderedChoices$('APCRTA');
	isLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		protected store: AssetStore,
		protected query: AssetQuery,
		private ddQuery: DropdownValueQuery,
		private customerService: CustomerService,
		protected crtMortgageQuery: CrtMortgageQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset()
		})
	}

	get(adviceProcessId: number) {
		this.store.setLoading(true);
		return this.api.get<Asset[]>(`crt/${adviceProcessId}/FAA`).pipe(
			map((x) => (x || [])?.map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => this.store.set(x || [])),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAA',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.add(x)),
			catchError(() => of(undefined))
		);
	}

	update(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAA',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap(() => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.upsert(x?.cRTId, x)),
			catchError(() => of(undefined))
		);
	}

	delete(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAA',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			tap((x) => this.store.remove(body.CRTId)),
			catchError(() => of(undefined))
		);
	}
}
