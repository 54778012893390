import { Moment } from 'moment';
import { Note, NoteState } from '../../notes/note.model';

export interface PropertyAssetCustomerService {
	PropertyAddress: string;
	PropertyOwner: string;
	Valuation1: string;
	Valuation2: string;
	GRM1Value: number;
	GRM2Value: number;
	GRM1Date: string;
	GRM2Date: string;
	Type: string;
	RentalIncome: number;
	RentalFrequency?: string;
	Use: string;
	Title: string;
	CustomerServiceID: number;
	CustomerID: number;
	ServiceCode: string;
	IsActive: number;
	CreateDateTime?: string | Moment;
	CreatedByStaffId?: number;
	ModifiedDateTime?: string | Moment;
	ModifiedByStaffId?: number;
	CreatedByStaffLevel?: number;
	ImportID?: string;
	Note: string;
	Notes: Note[];
	DocumentLink: string;
	Color?: string;
	PropertyValue?: number;
	Status: string;
	PendingAdviserApproval?: boolean;
}

export interface PropertyAssetCustomerServiceState {
	propertyAddress: string;
	propertyOwner: string;
	valuation1: string;
	valuation2: string;
	gRM1Value: number;
	gRM2Value: number;
	gRM1Date: string | Moment;
	gRM2Date: string | Moment;
	type: string;
	rentalIncome: number;
	rentalFrequency?: string;
	use: string;
	title: string;
	customerServiceID: number;
	customerID: number;
	purchaseDate: string | Moment;
	serviceCode?: string;
	isActive?: number;
	createDateTime?: string | Moment;
	createdByStaffId?: number;
	modifiedDateTime?: string | Moment;
	modifiedByStaffId?: number;
	createdByStaffLevel?: number;
	importID?: string;
	note?: string;
	notes?: NoteState[];
	documentLink: string;
	color?: string;
	propertyValue?: number;
	status: string;
}

export enum PropertyStatus {
	Current = 'Current'
}
