<div
	class="modal-header text-light tap-bg-primary d-flex justify-content-between"
>
	<h4 class="modal-title font-weight-normal">
		{{ title }}
	</h4>
	<div class="clrearfix d-flex">
		<button type="button" class="close" aria-label="Close" (click)="close()">
			<span class="material-symbols-outlined">close</span>
		</button>
	</div>
</div>
<div class="modal-body" [formGroup]="form" [class.submitted]="submitted">
	<div class="modal-body__inner clearfix py-3">
		<div class="container">
			<div class="row mb-2" *ngIf="showDocumentName">
				<div class="col-12">
					<label for="documentName" class="mb-1"
						>{{ documentNameText || ""
						}}<span class="tap-required" *ngIf="DocumentName.invalid"
							>*</span
						></label
					><br />
					<app-input
						inputClassname="ui-form-control"
						id="documentName"
						formControlName="documentName"
						[isInvalid]="DocumentName.invalid"
						[disabled]="isLoading"
					></app-input>
				</div>
			</div>
			<div
				class="row mb-2"
				*ngIf="documents?.length > 0 || linkedDocs?.length > 0"
			>
				<ng-container *ngFor="let l of linkedDocs; let i = index">
					<div class="col-12 mt-2">
						<span class="float-left text-truncate document-name">{{
							l.Value || ""
						}}</span
						><button
							id="removeLinkedDocument_{{ i }}"
							type="button"
							class="icon-btn float-left"
							(click)="removeLinkedDocument(l)"
							[disabled]="isLoading"
						>
							<i class="material-icons md-16 pr-0"> delete </i>
						</button>
					</div>
				</ng-container>
				<ng-container *ngFor="let d of documents; let i = index">
					<div class="col-12 mt-2">
						<span class="float-left text-truncate document-name">{{
							d?.FileName || ""
						}}</span
						><button
							id="removeUploadedDocument_{{ i }}"
							type="button"
							class="icon-btn float-left"
							(click)="removeUploadedDocument(i, d)"
							[disabled]="isLoading"
						>
							<i class="material-icons md-16 pr-0"> delete </i>
						</button>
					</div>
				</ng-container>
			</div>
			<div
				class="row pt-1 mb-1"
				*ngIf="
					(!!isSingleUpload &&
						documents?.length === 0 &&
						linkedDocs?.length === 0) ||
					!isSingleUpload
				"
			>
				<div [ngClass]="{ 'col-6 pr-1': !uploadOnly, 'col-12': !!uploadOnly }">
					<button
						class="upload-document-btn ui-btn-gray w-100"
						(click)="uploadDocument()"
						[disabled]="isLoading"
						[class.invalid-control]="
							submitted && documents?.length === 0 && linkedDocs?.length === 0
						"
					>
						Upload New
					</button>
				</div>
				<div class="col-6 pl-1" *ngIf="!uploadOnly">
					<button
						id="linkDocumentButton"
						type="button"
						class="link-document-btn ui-btn-gray w-100"
						(click)="linkDocument(); optionModalRef?.hide()"
						[disabled]="isLoading"
						[class.invalid-control]="
							submitted && documents?.length === 0 && linkedDocs?.length === 0
						"
					>
						Link Document
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 mt-2">
					<button
						class="btn btn-primary ui-btn-primary w-100"
						(click)="saveDocument()"
						[disabled]="isLoading"
					>
						{{ btnText || "Save" }}
						<i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
