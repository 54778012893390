import { MortgageRequest } from './mortgage-request.model';

// This returns an object
export const comparable = o =>
	typeof o !== 'object' || !o
		? o
		: Object.keys(o)
				?.sort()
				?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (req: MortgageRequest, saveReq: MortgageRequest) => {
	let r = true;

	if (!req || !saveReq) {
		return (r = false);
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedAdvisers)) !== JSON.stringify(comparable(saveReq.SelectedAdvisers))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedAltAdvisers)) !== JSON.stringify(comparable(saveReq.SelectedAltAdvisers))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedProviders)) !== JSON.stringify(comparable(saveReq.SelectedProviders))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedStatuses)) !== JSON.stringify(comparable(saveReq.SelectedStatuses))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedRefixSatuses)) !== JSON.stringify(comparable(saveReq.SelectedRefixSatuses))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedLeadTypes)) !== JSON.stringify(comparable(saveReq.SelectedLeadTypes))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.SelectedLeadOrigins)) !== JSON.stringify(comparable(saveReq.SelectedLeadOrigins))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.NextActivityDateMin)) !== JSON.stringify(comparable(saveReq.NextActivityDateMin))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.NextActivityDateMax)) !== JSON.stringify(comparable(saveReq.NextActivityDateMax))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.LoanDrawdownDateMin)) !== JSON.stringify(comparable(saveReq.LoanDrawdownDateMin))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (JSON.stringify(comparable(req.LoanDrawdownDateMax)) !== JSON.stringify(comparable(saveReq.LoanDrawdownDateMax))) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.FixedPeriodEndDateMin)) !== JSON.stringify(comparable(saveReq.FixedPeriodEndDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.FixedPeriodEndDateMax)) !== JSON.stringify(comparable(saveReq.FixedPeriodEndDateMax))
	) {
		r = false;
	}

	if (JSON.stringify(comparable(req.BusinessMortgage)) !== JSON.stringify(comparable(saveReq.BusinessMortgage))) {
		r = false;
	}

	return r;
};

export const request = (filter: any, form: MortgageRequest, index: number) => {
	const req = {
		SelectedAdvisers: form.SelectedAdvisers,
		SelectedAltAdvisers: form.SelectedAltAdvisers,
		SelectedProviders: form.SelectedProviders,

		SelectedStatuses: form.SelectedStatuses,
		SelectedRefixSatuses: form.SelectedRefixSatuses,
		SelectedLeadTypes: form.SelectedLeadTypes,
		SelectedLeadOrigins: form.SelectedLeadOrigins,
		SelectedAdviserStatuses: form.SelectedAdviserStatuses,

		NextActivityDateMin: form.NextActivityDateMin,
		NextActivityDateMax: form.NextActivityDateMax,
		LoanDrawdownDateMin: form.LoanDrawdownDateMin,
		LoanDrawdownDateMax: form.LoanDrawdownDateMax,
		FixedPeriodEndDateMin: form.FixedPeriodEndDateMin,
		FixedPeriodEndDateMax: form.FixedPeriodEndDateMax,

		BusinessMortgage: form.BusinessMortgage,

    DoubleSub: form.DoubleSub,

		Paging: {
			Index: index,
			Column: filter ? filter.Column : null,
			Direction: filter ? filter.Direction : null,
		},
	};

	return req;
};
