import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	NgZone,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ScopeOfServiceSettingsService } from 'src/app/modules/mortgage-settings/scope-of-service-settings/state/scope-of-service-settings.service';
import { CrtEmailModalComponent } from 'src/app/shared/modal/crt/email/crt-email-modal/crt-email-modal.component';
import { EmailService } from '../../../client-review-template/states/email/email.service';
import { EmailTypes } from '../../../../../shared/models/emails/mortgage/email.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, iif, Observable, Observer, of, throwError } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
	catchError,
} from 'rxjs/operators';
import {
	MOATDocumentField,
	AdviceProcessPageCodes,
	AdviceProcessPageNamesByCode,
	AdviceProcessRoutes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { MergeTagsService } from '../state/merge-tags/crt-kiwisaver-mergetags.service';
import { CrtKiwiSaverQuery } from '../state/crt-kiwisaver.query';
import { ScopeOfServiceState } from './state/scope-of-service.model';
import { ScopeOfServiceService } from './state/scope-of-service.service';
import { either, isNil, isEmpty } from 'ramda';
import {
	filterNonEmailMergeTags,
	getContentWithMergeTags,
	removeMtWrappersOnTextarea,
} from 'src/app/shared/converter/content-merge-tags';
import { ScopeOfServiceMapper } from './state/scope-of-service.mapper';
import { CrtKiwiSaverService } from '../state/crt-kiwisaver.service';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import {
	AdviceProcessDocumentTypesMOAT,
	DocumentTypesMOAT,
} from 'src/app/shared/models/documents/document.model';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { CrtDocumentService } from '../../_shared/service/crt-document.service';
import { convertUtil } from 'src/app/util/util';
import { SosMTMapper } from 'src/app/shared/models/client-review-template/merge-tags/crt-kiwisaver/scope-of-service/sos.mapper';

import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { PdfDesignV2Options } from '@shared/models/client-review-template/pdf-design-v2/pdf-design-v2.model';
import { HtmlPdfConfigState } from '../../_shared/service/html-pdf/defaults-config';

@Component({
	selector: 'app-scope-of-services',
	templateUrl: './scope-of-services.component.html',
	styleUrls: ['./scope-of-services.component.scss'],
})
export class ScopeOfServicesComponent implements OnInit {
	emailSettings$ = this.sosSettingsService.emailSettings$.pipe(
		map((data) =>
			either(isNil, isEmpty)(data) ? { isEnableEmailOption: false } : data
		)
	);
	adviceProcess = this.query.getValue().adviceProcess;
	public bsModalRef: BsModalRef;

	@ViewChild('sosDocument') sosDocument: ElementRef;

	title = AdviceProcessPageNamesByCode[AdviceProcessPageCodes.SOS];
	form: UntypedFormGroup;
	scopeOfService$ = this.query.scopeOfService$;
	sosDefault$ = this.query.sosDefault$;
	sosTemplateSettings$ = this.query.sosTemplateSettings$;
	crtData: ScopeOfServiceState;
	defaultSetting: ScopeOfServiceState;
	mergeTags: MergeTagState[];
	cRTId: number | undefined;
	adviceProcessId = this.query.getValue().adviceProcessId;
	adviceProcess$ = this.query.adviceProcess$;
	sidebar = this.query.getValue().sidebars;
	defaultDetails = 'is not included in the advice process because';
	isLoading = false;
	isSaving = false;
	isTabLoading$ = this.kiwisaverService.isTabLoading$;

	showLifeAndRiskReview = false;
	showHouseCarAndContentsInsurance = false;
	showResidentialMortgageOrLending = false;
	showOtherAdvice = false;
	skipSaving = false;

	pageIds = AdviceProcessRoutes;

	optionsWysiswyg = {
		heightMax: null,
		heightMin: 250,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};

	@ViewChild('contentEditor') contentEditor: WysiwygComponent;

	businessFapName = this.businessConfigQuery.getValue().config.FAP;

	themeConfig$ = this.businessConfigQuery.themeConfig$;
	pdfClasses = ' pdf-design-v2 pdf-design-v2-body';

	constructor(
		private fb: UntypedFormBuilder,
		private sService: ScopeOfServiceService,
		private mtService: MergeTagsService,
		private query: CrtKiwiSaverQuery,
		private kiwisaverService: CrtKiwiSaverService,
		private modalService: BsModalService,
		private sosSettingsService: ScopeOfServiceSettingsService,
		private emailService: EmailService,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private loggerService: LoggerService,
		private documentService: CrtDocumentService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		this.buildForm();
	}

	get WhatWeWillTalkAboutToday() {
		return this.form.get('whatWeWillTalkAboutToday');
	}

	get WhatWeWontTalkAboutToday() {
		return this.form.get('whatWeWontTalkAboutToday');
	}

	get LifeAndRiskReview() {
		return this.form.get('lifeAndRiskReview');
	}

	get LifeAndRiskReviewDetails() {
		return this.form.get('lifeAndRiskReviewDetails');
	}

	get HouseCarAndContentsInsurance() {
		return this.form.get('houseCarAndContentsInsurance');
	}

	get HouseCarAndContentsInsuranceDetails() {
		return this.form.get('houseCarAndContentsInsuranceDetails');
	}

	get ResidentialMortgageOrLending() {
		return this.form.get('residentialMortgageOrLending');
	}

	get ResidentialMortgageOrLendingDetails() {
		return this.form.get('residentialMortgageOrLendingDetails');
	}

	get OtherAdvice() {
		return this.form.get('otherAdvice');
	}

	get OtherAdviceNotes() {
		return this.form.get('otherAdviceNotes');
	}

	ngOnInit(): void {
		this.kiwisaverService
			.updatePageStarted(AdviceProcessPageCodes.SOS)
			.pipe(take(1))
			.subscribe();

		combineLatest([
			this.scopeOfService$,
			this.sosDefault$,
			this.query.mergeTags$,
		])
			.pipe(
				tap(([, , mt]) => {
					this.mergeTags = mt;
				}),
				filter(([crt, settings, mt]) => {
					isEmpty(settings) || isNil(settings)
						? (this.isLoading = false)
						: (this.isLoading = true);
					return !isNil(crt) && !isNil(settings) && !isNil(mt);
				}),
				tap(([crt, settings]) => {
					this.defaultSetting = settings;
					this.cRTId = crt?.cRTId;
				}),
				tap(() => (this.isLoading = false)),
				catchError((err) => {
					return throwError(err);
				}),
				take(1)
			)
			.subscribe(([crt]) => this.loadSos(crt));
	}

	private getPdfHeaderFooterOptions() {
		return this.themeConfig$.pipe(
			withLatestFrom(this.businessConfigQuery.businessOATLogo$),
			map(([theme, oatLogo]) => {
				// Header config For PDF
				const HeaderHtmlUrlParam = new URLSearchParams({
					headerText: 'Scope of Service',
					headerLogo: oatLogo?.toString()?.trim() || '',
				})?.toString();
				// Footer config for PDF
				const FooterHtmlUrlParam = new URLSearchParams({
					footerColor: theme?.primarycolor || '#00263e',
				})?.toString();

				return {
					...PdfDesignV2Options,
					HeaderHtmlUrlParam,
					FooterHtmlUrlParam,
				} as HtmlPdfConfigState;
			}),
			map((options) => {
				const otherHeaderOptions = new URLSearchParams({
					startHeaderOnPage: '1',
				})?.toString();
				const otherFooterOptions = new URLSearchParams({
					startPageNumberOn: '1',
				})?.toString();
				return {
					...options,
					FooterHtmlUrlParam: `${options?.FooterHtmlUrlParam}&${otherFooterOptions}`,
					HeaderHtmlUrlParam: `${options?.HeaderHtmlUrlParam}&${otherHeaderOptions}`,
				};
			}),
			take(1)
		);
	}

	loadSos(crt?: ScopeOfServiceState) {
		combineLatest([of(crt), of(this.defaultSetting), of(this.mergeTags)])
			.pipe(
				tap(() => (this.isLoading = true)),
				concatMap(([getSettingsVal, settings, mt]) =>
					this.getSettings(getSettingsVal, settings, mt)
				),
				tap(([setServiceVal, settings]) =>
					this.setupServices(setServiceVal, settings)
				),
				map(([viewCartVal, settings]) =>
					ScopeOfServiceMapper.mapToViewCrt(viewCartVal, settings)
				),
				map((x) => ({ ...x, cRTId: this.cRTId })),
				map((x) =>
					either(isNil, isEmpty)(crt) ? this.updateMergeTagsOnContent(x) : x
				),
				tap((x) => (this.crtData = x)),
				tap((x) => {
					this.form.reset(x);
				}),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe((x) => {
				if (!this.cRTId || isNil(crt)) {
					// trigger if
					// !this.cRTId : first visit of page
					// isNil(crt) : null crt means reload template
					this.isLoading = false;
					this.onChange();
				}
			});
	}

	onChange() {
		const formValue = this.form.getRawValue();

		this.sService.updateScopeOfServiceTemp({
			...formValue,
			cRTId: this.cRTId,
			sectionCode: 'SOS',
			adviceProcessId: +this.adviceProcessId,
		});
	}

	getSettings = (crt, settings, mt) =>
		of(crt).pipe(
			concatMap((val) =>
				iif(() => isNil(val), this.sService.getSosDefault(), of(settings))
			),
			map((data) => [crt, data, mt]),
			take(1)
		);

	setupServices(crtData, settings: ScopeOfServiceState) {
		const reset = isNil(crtData);

		this.showLifeAndRiskReview =
			!reset && crtData?.cRTId
				? crtData?.lifeAndRiskReview !== 2
				: !!settings?.lifeAndRiskReview;

		this.showHouseCarAndContentsInsurance =
			!reset && crtData?.cRTId
				? crtData?.houseCarAndContentsInsurance !== 2
				: !!settings?.houseCarAndContentsInsurance;

		this.showResidentialMortgageOrLending =
			!reset && crtData?.cRTId
				? crtData?.residentialMortgageOrLending !== 2
				: !!settings?.residentialMortgageOrLending;

		this.showOtherAdvice =
			!reset && crtData?.cRTId ? crtData?.otherAdvice !== 2 : !!settings?.other;
	}

	updateMergeTagsOnContent(data) {
		const newData = {
			...data,
			whatWeWillTalkAboutToday:
				this.convertMergeTags(data?.whatWeWillTalkAboutToday, true) ?? '',
			whatWeWontTalkAboutToday:
				this.convertMergeTags(data?.whatWeWontTalkAboutToday, true) ?? '',
			lifeAndRiskReviewDetails:
				this.convertMergeTags(data?.lifeAndRiskReviewDetails, true) ?? '',
			houseCarAndContentsInsuranceDetails:
				this.convertMergeTags(
					data?.houseCarAndContentsInsuranceDetails,
					true
				) ?? '',
			residentialMortgageOrLendingDetails:
				this.convertMergeTags(
					data?.residentialMortgageOrLendingDetails,
					true
				) ?? '',
		};

		return newData;
	}

	convertMergeTags(content: string, convertToNonTextarea: boolean = false) {
		if (!convertToNonTextarea) {
			content = content?.trim()?.replace(/(?:\r\n|\r|\n)/gm, '<br />');
		}

		const data = getContentWithMergeTags(
			content,
			this.mergeTags,
			convertToNonTextarea
		);

		return removeMtWrappersOnTextarea(data, convertToNonTextarea);
	}

	buildForm() {
		this.form = this.fb.group({
			cRTId: [null],
			referenceId: [null],
			type: [null],
			whatWeWillTalkAboutToday: [null],
			whatWeWontTalkAboutToday: [null],
			lifeAndRiskReview: [0],
			lifeAndRiskReviewDetails: [null],
			houseCarAndContentsInsurance: [0],
			houseCarAndContentsInsuranceDetails: [null],
			residentialMortgageOrLending: [0],
			residentialMortgageOrLendingDetails: [null],
			otherAdvice: [0],
			otherAdviceNotes: [null],
		});
	}

	saveFn = () => {
		if (this.skipSaving) {
			return of(undefined);
		}
		this.isLoading = true;
		this.onChange();
		return of(undefined).pipe(
			withLatestFrom(this.scopeOfService$),
			map(([, x]) => x),
			filter((x) => !either(isNil, isEmpty)(x)),
			tap(() => (this.isLoading = true)),
			concatMap((x: any) =>
				iif(
					() => either(isNil, isEmpty)(this.cRTId),
					this.sService
						.addScopeOfService({
							...x,
							clickedNext: true,
						})
						.pipe(tap((res) => (this.cRTId = +res))),
					this.sService.updateScopeOfService({
						...x,
						clickedNext: true,
						status: 1,
					})
				)
			),
			finalize(() => {
				this.isLoading = false;
				this.kiwisaverService.setHasFormChanges(false);
			})
		);
	};

	tickChange(event) {
		const controllerName = event?.target?.id;
		const val = event?.target?.checked ? 1 : 0;
		this.form.get(controllerName)?.setValue(val);
		this.onChange();
	}

	saveDocument() {
		this.changeDetector.detectChanges();

		this.adviceProcess$.pipe(take(1)).subscribe((x) => {
			const sosDocument = x?.documents?.find(
				(d) => d.field === MOATDocumentField.SOS
			)?.value;

			if (isNil(sosDocument) || isEmpty(sosDocument)) {
				this.saveNewDocument().pipe(take(1)).subscribe();
			} else {
				this.saveExistingDocument(x);
			}
		});
	}

	renderSosTemplate() {
		return this.mtService.getSosMt(+this.adviceProcessId).pipe(
			concatMap(() => this.getSettingsTemplate()),
			withLatestFrom(
				this.query.mergeTags$,
				this.scopeOfService$,
				this.sosDefault$
			),
			map(([settingsTemplate, mergeTags, sosData, settings]) => {
				const updatedMT = SosMTMapper.koatSosMergeTags(
					mergeTags,
					sosData,
					settings
				);
				return getContentWithMergeTags(settingsTemplate, updatedMT);
			}),
			map((x) => {
				return `<div class="sos-pdf-file ${this.pdfClasses}">${x}</div>`;
			}),
			take(1)
		);
	}

	getSettingsTemplate() {
		return this.sosTemplateSettings$.pipe(
			concatMap((x) =>
				iif(
					() => !!x?.templateLink,
					this.sService.getDocumentFromURL(x?.templateLink || ''),
					of('<p></p>')
				)
			),
			take(1)
		);
	}

	saveNewDocument() {
		const groupName = this.query
			.getValue()
			.primaryClient?.groupName?.toString()
			?.toUpperCase();
		const fileName = `${groupName} SCOPE OF SERVICE`;
		return this.saveFn().pipe(
			concatMap(() => this.renderSosTemplate()),
			tap(() => (this.isSaving = true)),
			mergeMap((content: any) => {
				return this.getPdfHeaderFooterOptions().pipe(
					mergeMap((options) =>
						this.documentService.downloadDocumentPDF(content, fileName, {
							...options,
							FileName: fileName,
						})
					)
				)
			}
			),
			mergeMap((content) => convertUtil.convertToBase64(content)),
			map((template) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: template,
				FileName: `${fileName}.pdf`,
				DocumentType: ServicesCodes.KiwiSaver,
				Type: AdviceProcessDocumentTypesMOAT.SOS,
			})),
			concatMap((x: any) => this.kiwisaverService.saveDocument(x)),
			mergeMap((x) =>
				this.kiwisaverService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesMOAT.SOS
				)
			),
			tap((x) =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						'Successfully saved Scope of Service Document.'
					)
				)
			),
			finalize(() => (this.isSaving = false)),
			take(1)
		);
	}

	saveExistingDocument(adviceProcess) {
		const document = adviceProcess?.documents?.find(
			(d) => d.field === MOATDocumentField.SOS
		)?.value;

		const confirm = new Observable((obs) => {
			this.saveNewDocument().pipe(take(1)).subscribe();
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: 'Save Document',
			message: `A document is already uploaded for Scope of Service,`,
			subMessage: document?.fileName,
			secondaryMessage: `Do you want to replace this?`,
			confirm$: confirm,
			decline$: decline,
			isAcceptBtn: true,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.loadSos();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	pauseExitFn = () =>
		of(false).pipe(
			tap(() => (this.skipSaving = true)),
			take(1)
		);

	loadingComponentFn = (value: boolean) =>
		this.kiwisaverService.isTabLoading(value);

	saveComponentFn = () => this.saveFn();
}
