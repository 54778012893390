import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
	delay,
	filter,
	skipWhile,
	startWith,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { Asset } from '../../../../../client-sop/assets-and-liabilities/state/asset/asset.model';
import { KiwiSaver } from '../../../../../client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.model';
import { PropertyPurchaseState } from '../../property-purchase/state/property-purchase.store';
import { computeUtil } from '../../../../../../../../../modules/crm/crt-page/_shared/calculations/funding-required';
import { LoanRefinanceState } from '../../loan-refinance/state/loan-refinance.model';
import { TopupRequirementsState } from '../../topup-requirements/state/topup-requirements.store';
import {
	PurposeDetails,
	PurposeDetailsState,
} from '../../../purpose/state/purpose.model';
import { FundingRequiredLoan } from '../../selling-section/loan-repaid/state/loan-repaid.model';
import { CashDepositDetails, OtherDetails } from '../state/cash-deposit.model';
import { PropertySoldDetailsState } from '../../selling-section/property-sold/state/property-sold.model';

@Component({
	selector: 'app-cash-deposit-form',
	templateUrl: './cash-deposit-form.component.html',
	styleUrls: ['./cash-deposit-form.component.scss'],
})
export class CashDepositFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() data$: Observable<CashDepositDetails>;
	@Input() kiwiSavers$: Observable<KiwiSaver[]>;
	@Input() assets$: Observable<Asset[]>;
	@Input() propertyPurchases$: Observable<PropertyPurchaseState[]>;
	@Input() loanRefinance$: Observable<LoanRefinanceState[]>;
	@Input() purpose$: Observable<PurposeDetails>;
	@Input() isRefinance$: Observable<boolean>;
	@Input() isSellingProperty$: Observable<boolean>;
	@Input() netProceeds$: Observable<number>;
	@Input() propertyToBeSold$: Observable<PropertySoldDetailsState[]>;
	@Input() loansRepaid$: Observable<FundingRequiredLoan[]>;
	@Input() isLendingTopup$: Observable<boolean>;
	@Input() topUpRequirement$: Observable<TopupRequirementsState[]>;
	@Input() cRTId$: Observable<number>;
	@Input() parentCRTId: number;
	@Input() adviceProcessId: number;
	@Input() saveFn$: (req: any) => Observable<any>;

	form: UntypedFormGroup;
	isInit = true;
	kiwiSaverList: KiwiSaver[];
	assetList: Asset[];
	propertyPurchaseList: PropertyPurchaseState[];
	purpose: PurposeDetailsState;
	loanRefinance: LoanRefinanceState[];
	isRefinance: boolean;
	netProceeds: number;
	isLendingTopup: boolean;
	topUpRequirementList: TopupRequirementsState[];

	idOther = 0;
	@Input() activeBank: string;

	constructor(private fb: UntypedFormBuilder) {
		this.form = this.fb.group({
			adviceProcessId: [null],
			cRTId: [null],
			parentCRTId: [null],
			cash: [null],
			gift: [null],
			kiwiSaver: [null],
			cashFromExistingLoan: [null],
			other: this.fb.array([]),
			saleProceeds: [null],
			cashTick: [false],
			kiwiSaverTick: [false],
			saleProceedsTick: [null],

			totalContribution: [{ value: 0, disabled: true }],
			newLendingRequired: [{ value: 0, disabled: true }],
			availableCash: [{ value: 0, disabled: true }],
			availableKiwiSaver: [{ value: 0, disabled: true }],
			availableSaleProceeds: [{ value: 0, disabled: true }],
		});
	}

	get Cash() {
		return this.form.get('cash');
	}
	get KiwiSaver() {
		return this.form.get('kiwiSaver');
	}
	get SaleProceeds() {
		return this.form.get('saleProceeds');
	}
	get SaleProceedsTick() {
		return this.form.get('saleProceedsTick');
	}
	get AvailableSaleProceeds() {
		return this.form.get('availableSaleProceeds');
	}
	get other() {
		return this.form.get('other') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.prepData();

		this.cRTId$
			.pipe(
				skipWhile(() => this.isInit),
				tap((cRTId) => this.form.patchValue({ cRTId })),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	prepData() {
		combineLatest([
			this.data$.pipe(startWith(null as any)),
			this.kiwiSavers$.pipe(startWith(null as any)),
			this.assets$.pipe(startWith(null as any)),
			this.propertyPurchases$.pipe(startWith(null as any)),
			this.purpose$.pipe(startWith(null as any)),
			this.loanRefinance$,
			this.isRefinance$,
			this.isSellingProperty$,
			this.propertyToBeSold$,
			this.loansRepaid$,
			this.topUpRequirement$,
			this.isLendingTopup$,
		])
			.pipe(
				filter(
					([
						data,
						kiwiSavers,
						assets,
						propertyPurchases,
						purpose,
						loanRefinance,
					]) =>
						!!data &&
						!!kiwiSavers &&
						!!assets &&
						!!propertyPurchases &&
						!!purpose &&
						!!loanRefinance
				),
				tap(
					([
						data,
						kiwiSavers,
						assets,
						propertyPurchases,
						purpose,
						loanRefinance,
						isRefinance,
						isSellingProperty,
						propertyToBeSold,
						loansRepaid,
						topUpRequirement,
						isLendingTopup,
					]) => {
						if (this.isInit) {
							this.form.reset({
								...data,
								parentCRTId: this.parentCRTId,
								adviceProcessId: this.adviceProcessId,
							});
						}
						this.kiwiSaverList = kiwiSavers;
						this.assetList = assets;
						this.propertyPurchaseList = propertyPurchases;
						this.loanRefinance = loanRefinance;
						this.isRefinance = isRefinance;
						this.netProceeds = isSellingProperty
							? +computeUtil.totalNetProceeds(
									propertyToBeSold as PropertySoldDetailsState[],
									loansRepaid as FundingRequiredLoan[]
							  )
							: 0;
						this.isLendingTopup = isLendingTopup;
						this.topUpRequirementList = topUpRequirement;
						this.resetSaleProceeds(purpose as PurposeDetailsState);
						this.other.clear();
						(data as CashDepositDetails).other?.map((other) =>
							this.addOtherItem(other)
						);
					}
				),
				delay(100),
				tap(() => {
					this.runComputations();

					const formData = this.form.getRawValue();

					if (!!formData?.saleProceedsTick) {
						if (formData?.saleProceedsTick) {
							this.SaleProceeds.setValue(+this.netProceeds);
						}
					}

					if (!!formData?.cashTick) {
						if (formData?.cashTick) {
							this.Cash.setValue(formData?.availableCash);
						}
					}

					if (!!formData?.kiwiSaverTick) {
						if (formData?.kiwiSaverTick) {
							this.KiwiSaver.setValue(formData?.availableKiwiSaver);
						}
					}

					this.isInit = false;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	availableKiwiSaverTick() {
		const formData = this.form.getRawValue();
		if (formData?.kiwiSaverTick) {
			this.KiwiSaver.setValue(formData?.availableKiwiSaver);
		} else {
			this.KiwiSaver.setValue(0);
		}
		this.runComputations();
	}

	availableCashTick() {
		const formData = this.form.getRawValue();
		if (formData?.cashTick) {
			this.Cash.setValue(formData?.availableCash);
		} else {
			this.Cash.setValue(0);
		}
		this.runComputations();
	}

	availableSaleProceeds() {
		const formData = this.form.getRawValue();
		if (formData?.saleProceedsTick) {
			this.SaleProceeds.setValue(+this.netProceeds);
		} else {
			this.SaleProceeds.setValue(0);
		}
		this.runComputations();
	}

	resetSaleProceeds(newPurpose: PurposeDetailsState) {
		if (
			this.purpose &&
			this.purpose?.isSellingProperties !== newPurpose?.isSellingProperties
		) {
			this.SaleProceeds.setValue(0);
			this.SaleProceedsTick.setValue(false);
		}
		this.purpose = newPurpose;
	}

	runComputations() {
		// If Refinance is Uncheck, it should not be added on the computation
		const refinanceList = this.isRefinance ? this.loanRefinance : [];
		const topupList = this.isLendingTopup ? this.topUpRequirementList : [];

		const formData = this.form.getRawValue();

		const availableCash = computeUtil.totalAvailableCash(this.assetList);
		const availableKiwiSaver = computeUtil.totalAvailableKiwiSaver(
			this.kiwiSaverList
		);
		const availableSaleProceeds = +this.netProceeds;

		const totalContribution = computeUtil.totalContribution(formData);

		const newLendingRequired = computeUtil.newLendingRequired(
			totalContribution,
			this.propertyPurchaseList,
			refinanceList,
			topupList
		);

		// Patch new computations to form
		this.form.patchValue({
			totalContribution,
			newLendingRequired,
			availableKiwiSaver,
			availableCash,
			availableSaleProceeds,
		});
	}

	getFormData = () => of(this.form.getRawValue());

	addOtherItem(data?: OtherDetails) {
		this.idOther++;
		this.other.push(
			this.fb.group({
				otherField: [(data && data.otherField) || ''],
				otherValue: [(data && data.otherValue) || ''],
			})
		);
		if (!data) {
			this.form.markAllAsTouched();
		}
	}

	deleteOtherItem(index: number) {
		this.other?.removeAt(index);
		this.runComputations();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
