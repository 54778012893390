import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Observer, Subject } from 'rxjs';
import { filter, finalize, mergeMap, take } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { ThemeConfig } from '../../../../shared/models/_general/config';
import { FGInsuranceModalComponent } from '../../../../shared/modal/crt/fact-find/fg-insurance/fg-insurance-modal.component';
import { DropdownValue } from '../../../..//modules/dropdown/model/dropdownValue';
import { HouseInsuranceState } from '../../../../shared/models/client-review-template/fg-insurance/house-insurance.model';
import { CarInsuranceState } from '../../../../shared/models/client-review-template/fg-insurance/car-insurance.model';
import { ContentInsuranceState } from '../../../../shared/models/client-review-template/fg-insurance/content-insurance.model';
import { ClientReviewTemplateService } from '../states/client-review-template.service';
import { FgService } from '../states/fg-insurance/fg.service';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { DOCUMENT } from '@angular/common';
import { complement, either, isNil, isEmpty } from 'ramda';

declare var $: any;

@Component({
  selector: 'app-fg-insurance',
  templateUrl: './fg-insurance.component.html',
  styleUrls: ['./fg-insurance.component.scss'],
})
export class FgInsuranceComponent implements OnInit {
  dropdownCodes: DropdownValue[];

  onDestroy$ = new Subject<void>();
  themeConfig$ = this.businessConfigQuery.themeConfig$;
  primaryColor: string;
  isHouseLoading = true;
  isCarLoading = true;
  isContentLoading = true;

  houseInsurance$ = this.fgService.houseInsurance$;
  carInsurance$ = this.fgService.carInsurance$;
  contentInsurance$ = this.fgService.contentInsurance$;
  blanketQuote$ = this.fgService.blanketQuote$;
  blanketOptions$ = this.fgService.blanketOptions$;
  APCRTCLC$ = this.fgService.APCRTCLC$;
  APCRTVP$ = this.fgService.APCRTVP$;
  APCRTKPY$ = this.fgService.APCRTKPY$;
  APCRTSI$ = this.fgService.APCRTSI$;
  APCRTYNNA$ = this.fgService.APCRTYNNA$;
  fgi$ = this.service.FGI$;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$

  elseMinusHouse = true;
  elseMinusCar = true;
  elseMinusContents = true;
  isLoading = false;
  isColorStyleLessCar = true;
  isColorStyleLessHouse = true;
  isColorStyleLessContents = true;

  public bsModalRef: BsModalRef;
  form: UntypedFormGroup;

  get window(): Window {
    return this.document.defaultView;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private businessConfigQuery: BusinessConfigQuery,
    private fgService: FgService,
    private service: ClientReviewTemplateService,
    private route: ActivatedRoute,
    protected query: ClientReviewTemplateQuery,
    @Inject(DOCUMENT) readonly document: Document
  ) {
    this.buildForm();
  }

  colorStyleLess() {
    this.isColorStyleLessCar = true;
    this.isColorStyleLessHouse = true;
    this.isColorStyleLessContents = true;
  }

  colorStyleMore() {
    this.isColorStyleLessCar = false;
    this.isColorStyleLessHouse = false;
    this.isColorStyleLessContents = false;
  }

  collapseFalse() {
    this.elseMinusHouse = false;
    this.elseMinusCar = false;
    this.elseMinusContents = false;
    this.isColorStyleLessCar = false;
    this.isColorStyleLessHouse = false;
    this.isColorStyleLessContents = false;
  }

  defaultShow() {
    $('#collapseHouse').show();
    $('#collapseCar').show();
    $('#collapseContents').show();
  }

  ngOnInit(): void {
    this.houseInsurance$
      .pipe(
        filter((x) => !!x),
        finalize(() => (this.isHouseLoading = false)),
        take(1)
      )
      .subscribe();
    this.carInsurance$
      .pipe(
        filter((x) => !!x),
        finalize(() => (this.isCarLoading = false)),
        take(1)
      )
      .subscribe();
    this.contentInsurance$
      .pipe(
        filter((x) => !!x),
        finalize(() => (this.isContentLoading = false)),
        take(1)
      )
      .subscribe();
  }

  buildForm() {
    this.form = this.fb.group({});
  }

  collapseMoreHouse() {
    const widthSize = $(window).width();
    this.isColorStyleLessHouse = widthSize <= 600 ? false : true;
    $('#collapseHouse').toggle();
    this.elseMinusHouse = false;
  }
  collapseLessHouse() {
    $('#collapseHouse').toggle();
    this.isColorStyleLessHouse = true;
    this.elseMinusHouse = true;
  }

  collapseMoreCar() {
    const widthSize = $(window).width();
    this.isColorStyleLessCar = widthSize <= 600 ? false : true;
    $('#collapseCar').toggle();
    this.elseMinusCar = false;
  }
  collapseLessCar() {
    this.isColorStyleLessCar = true;
    $('#collapseCar').toggle();
    this.elseMinusCar = true;
  }

  collapseMoreContents() {
    const widthSize = $(window).width();
    this.isColorStyleLessContents = widthSize <= 600 ? false : true;
    $('#collapseContents').toggle();
    this.elseMinusContents = false;
  }
  collapseLessContents() {
    this.isColorStyleLessContents = false;
    $('#collapseContents').toggle();
    this.elseMinusContents = true;
  }

  addHouse() {
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    const initState = {
      header: 'House Details',
      message: `HouseDetails`,
      dropdownCodes: this.dropdownCodes,
      confirm$: confirm,
      decline$: decline,
      fgi$: this.fgi$,
      APCRTCLC$: this.APCRTCLC$,
      APCRTYNNA$: this.APCRTYNNA$,
      savefn: this.addHouseInsurance,
      isAdd: true,
    };
    this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
      class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  editHouse(cRTId?: number) {
    if (this.isLoading) {
      return;
    }
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    this.isLoading = true;
    this.fgService
      .getPolicyInfo(+cRTId)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.isLoading = false;
          const initState = {
            header: 'House Details',
            message: `HouseDetails`,
            dropdownCodes: this.dropdownCodes,
            confirm$: confirm,
            decline$: decline,
            fgi$: this.fgi$,
            APCRTCLC$: this.APCRTCLC$,
            APCRTYNNA$: this.APCRTYNNA$,
            savefn: this.updateHouseInsurance,
            cRTId,
            cRTInfo: data,
            isAdd: false,
          };
          this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
            class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
            initialState: initState,
            ignoreBackdropClick: true,
            keyboard: false
          });
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  viewHouse(cRTId?: number) {
    if (this.isLoading) {
      return;
    }
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    this.isLoading = true;
    this.fgService
      .getPolicyInfo(+cRTId)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.isLoading = false;
          const initState = {
            header: 'House Details',
            message: `HouseDetails`,
            dropdownCodes: this.dropdownCodes,
            confirm$: confirm,
            decline$: decline,
            fgi$: this.fgi$,
            APCRTCLC$: this.APCRTCLC$,
            APCRTYNNA$: this.APCRTYNNA$,
            cRTId,
            cRTInfo: data,
            isAdd: false,
						viewMode: true
          };
          this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
            class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
            initialState: initState,
            ignoreBackdropClick: true,
            keyboard: false
          });
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  addHouseInsurance = (model: HouseInsuranceState) =>
    new Observable<HouseInsuranceState>((obs) => {
      obs.next(model);
      obs.complete();
    }).pipe(
      mergeMap((x) =>
        this.fgService.addHouseInsurance(
          x,
          parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10)
        )
      )
    );

  updateHouseInsurance = (model: HouseInsuranceState) =>
    new Observable<HouseInsuranceState>((obs) => {
      obs.next(model);
      obs.complete();
    }).pipe(mergeMap((x) => this.fgService.updateHouseInsurance(x)));

  deleteHouse(id: number) {
    this.fgService.deleteHouseInsurance(+id).subscribe();
  }

  addCar() {
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    const initialState = {
      header: 'Car Details',
      message: `CarDetails`,
      dropdownCodes: this.dropdownCodes,
      savefn: this.addCarInsurance,
      fgi$: this.fgi$,
      APCRTCLC$: this.APCRTCLC$,
      APCRTVP$: this.APCRTVP$,
      APCRTKPY$: this.APCRTKPY$,
      APCRTYNNA$: this.APCRTYNNA$,
      confirm$: confirm,
      decline$: decline,
      isAdd: true,
    };
    this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
      class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
      initialState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  editCar(cRTId?: number) {
    if (this.isLoading) {
      return;
    }
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    this.isLoading = true;
    this.fgService
      .getPolicyInfo(+cRTId)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.isLoading = false;
          const initialState = {
            header: 'Car Details',
            message: `CarDetails`,
            dropdownCodes: this.dropdownCodes,
            confirm$: confirm,
            decline$: decline,
            fgi$: this.fgi$,
            APCRTCLC$: this.APCRTCLC$,
            APCRTVP$: this.APCRTVP$,
            APCRTKPY$: this.APCRTKPY$,
            APCRTYNNA$: this.APCRTYNNA$,
            savefn: this.updateCarInsurance,
            cRTId,
            cRTInfo: data,
            isAdd: false,
          };
          this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
            class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
            initialState,
            ignoreBackdropClick: true,
            keyboard: false
          });
        },
        () => this.isLoading = false
      );
  }

  viewCar(cRTId?: number) {
    if (this.isLoading) {
      return;
    }
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    this.isLoading = true;
    this.fgService
      .getPolicyInfo(+cRTId)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.isLoading = false;
          const initialState = {
            header: 'Car Details',
            message: `CarDetails`,
            dropdownCodes: this.dropdownCodes,
            confirm$: confirm,
            decline$: decline,
            fgi$: this.fgi$,
            APCRTCLC$: this.APCRTCLC$,
            APCRTVP$: this.APCRTVP$,
            APCRTKPY$: this.APCRTKPY$,
            APCRTYNNA$: this.APCRTYNNA$,
            cRTId,
            cRTInfo: data,
            isAdd: false,
						viewMode: true
          };
          this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
            class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
            initialState,
            ignoreBackdropClick: true,
            keyboard: false
          });
        },
        () => this.isLoading = false
      );
  }

  addCarInsurance = (model: CarInsuranceState) =>
    new Observable<CarInsuranceState>((obs) => {
      obs.next(model);
      obs.complete();
    }).pipe(
      mergeMap((x) =>
        this.fgService.addCarInsurance(
          x,
          parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10)
        )
      )
    );

  updateCarInsurance = (model: CarInsuranceState) =>
    new Observable<CarInsuranceState>((obs) => {
      obs.next(model);
      obs.complete();
    }).pipe(mergeMap((x) => this.fgService.updateCarInsurance(x)));

  deleteCar(id: number) {
    this.fgService.deleteCarInsurance(+id).subscribe();
  }

  addContent() {
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    const initState = {
      header: 'Contents Details',
      message: `ContentsDetails`,
      dropdownCodes: this.dropdownCodes,
      confirm$: confirm,
      decline$: decline,
      savefn: this.addContentInsurance,
      fgi$: this.fgi$,
      APCRTCLC$: this.APCRTCLC$,
      APCRTSI$: this.APCRTSI$,
      APCRTYNNA$: this.APCRTYNNA$,
      isAdd: true,
    };
    this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
      class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  editContent(cRTId?: number) {
    if (this.isLoading) {
      return;
    }
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    this.isLoading = true;
    this.fgService
      .getPolicyInfo(+cRTId)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.isLoading = false;
          const initialState = {
            header: 'Contents Details',
            message: `ContentsDetails`,
            dropdownCodes: this.dropdownCodes,
            confirm$: confirm,
            decline$: decline,
            savefn: this.updateContentInsurance,
            fgi$: this.fgi$,
            APCRTCLC$: this.APCRTCLC$,
            APCRTSI$: this.APCRTSI$,
            APCRTYNNA$: this.APCRTYNNA$,
            cRTId,
            cRTInfo: data,
            isAdd: false,
          };
          this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
            class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
            initialState,
            ignoreBackdropClick: true,
            keyboard: false
          });
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  viewContent(cRTId?: number) {
    if (this.isLoading) {
      return;
    }
    const confirm = new Observable((obs: Observer<any>) => obs.complete());
    const decline = new Observable((obs: Observer<any>) => obs.complete());
    this.isLoading = true;
    this.fgService
      .getPolicyInfo(+cRTId)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.isLoading = false;
          const initialState = {
            header: 'Contents Details',
            message: `ContentsDetails`,
            dropdownCodes: this.dropdownCodes,
            confirm$: confirm,
            decline$: decline,
            fgi$: this.fgi$,
            APCRTCLC$: this.APCRTCLC$,
            APCRTSI$: this.APCRTSI$,
            APCRTYNNA$: this.APCRTYNNA$,
            cRTId,
            cRTInfo: data,
            isAdd: false,
						viewMode: true
          };
          this.bsModalRef = this.modalService.show(FGInsuranceModalComponent, {
            class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
            initialState,
            ignoreBackdropClick: true,
            keyboard: false
          });
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  addContentInsurance = (model: ContentInsuranceState) =>
    new Observable<ContentInsuranceState>((obs) => {
      obs.next(model);
      obs.complete();
    }).pipe(
      mergeMap((x) =>
        this.fgService.addContentInsurance(
          x,
          parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10)
        )
      )
    );

  updateContentInsurance = (model: ContentInsuranceState) =>
    new Observable<ContentInsuranceState>((obs) => {
      obs.next(model);
      obs.complete();
    }).pipe(mergeMap((x) => this.fgService.updateContentInsurance(x)));

  deleteContent(id: number) {
    this.fgService.deleteContentInsurance(+id).subscribe();
  }

  blanketLink(path: string) {
    if (path.trim() && !!path.includes('http')) {
      window.open(path, '_blank');
    }

  }

	showButton(option) {
		return complement(either(isNil, isEmpty))(option.referralValues?.referralLink);
	}
}
