<form [formGroup]="form" class="form">
	<div class="form-row mb-2 income-label-background">
		<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary">
				Income Source
			</label>
		</div>
		<div class="col-5 col-lg-9 text-right iconShow">
			<button
				id="incomeSource_collapseButton"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 income-collapse-background"
			>
				<span *ngIf="elseMinusIncome; else elsePlusIncome">
					<i (click)="collapseMoreIncome()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusIncome>
					<span>
						<i (click)="collapseLessIncome()" class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div
		class="form-row d-none d-lg-flex mb-2 font-weight-bold"
		[class.tap-opacity-25]="IncomeArray?.controls?.length === 0"
	>
		<div class="col-lg-3">
			<span>Income Earner</span>
		</div>
		<div class="col-lg-3">
			<span>Employment</span>
		</div>
		<div class="col-lg-3">
			<span>Occupation</span>
		</div>
		<div class="col-lg-2">
			<span>Total Gross Income</span>
		</div>
	</div>

	<div class="collapse expandable" id="collapseIncome">
		<div formArrayName="incomeArray">
			<div
				class="form-row align-items-center"
				*ngFor="
					let item of IncomeArray.controls;
					let i = index;
					let last = last
				"
				[formGroupName]="i"
			>
				<div class="d-md-none col-lg-2 text-right">
					<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
						<ng-container *ngIf="!item.get('isLoading').value">
							<button
								id="incomeSource_editEmploymentButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="editEmployment(+item.get('cRTId').value, i)"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
							<button
								id="incomeSource_deleteEmploymentButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="deleteEmployment(+item.get('cRTId').value, i)"
							>
								<i class="material-icons md-16"> delete </i>
							</button>
						</ng-container>
					</ng-container>
					<ng-template #viewOnlySmall>
						<button
							id="incomeSource_viewEmploymentButton_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							(click)="viewEmployment(+item.get('cRTId').value, i)"
						>
							<i class="material-icons md-16"> info </i>
						</button>
					</ng-template>
					<ng-container *ngIf="item.get('isLoading').value">
						<app-manual-loader-mini
							[isLoading]="item.get('isLoading').value"
						></app-manual-loader-mini>
					</ng-container>
				</div>
				<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
					<input
						id="incomeSource_incomeEarner_{{ i }}"
						class="form-control crt-form-control"
						placeholder="Income Earner"
						formControlName="incomeEarner"
						type="text"
					/>
				</div>
				<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
					<input
						id="incomeSource_employment_{{ i }}"
						class="form-control crt-form-control"
						placeholder="Employment"
						formControlName="employment"
						type="text"
					/>
				</div>
				<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
					<input
						id="incomeSource_occupation_{{ i }}"
						class="form-control crt-form-control"
						placeholder="Occupation"
						formControlName="occupation"
						type="text"
					/>
				</div>
				<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="!last">
					<div class="dollar-icon">
						<input
							id="incomeSource_totalGrossIncome_{{ i }}"
							class="form-control crt-form-control"
							placeholder="Total Gross Income"
							formControlName="totalGrossIncome"
							type="text"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div
					class="d-none d-md-block col-lg-1 text-right"
					[class.border-bottom]="!last"
				>
					<ng-container *ngIf="!item.get('isLoading').value">
						<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
							<button
								id="incomeSource_editEmploymentButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="editEmployment(+item.get('cRTId').value, i)"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
							<button type="button" class="icon-btn w-auto h-auto px-1">
								<i
									id="incomeSource_deleteEmploymentButton_{{ i }}"
									class="material-icons md-16"
									(click)="deleteEmployment(+item.get('cRTId').value, i)"
								>
									delete
								</i>
							</button>
						</ng-container>
						<ng-template #viewOnly>
							<button
								id="incomeSource_viewEmploymentButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="viewEmployment(+item.get('cRTId').value, i)"
							>
								<i class="material-icons md-16"> info </i>
							</button>
						</ng-template>
					</ng-container>

					<ng-container *ngIf="item.get('isLoading').value">
						<app-manual-loader-mini
							[isLoading]="item.get('isLoading').value"
						></app-manual-loader-mini>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="form-row my-4">
			<div class="col-6 col-lg-6">
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="incomeSource_addEmploymentButton"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
						(click)="addEmployment()"
						[disabled]="isListLoading"
					>
						Add Income Source +
					</button>
				</ng-container>

				<span *ngIf="isListLoading">
					<app-manual-loader-mini
						[isLoading]="isListLoading"
					></app-manual-loader-mini>
				</span>
			</div>
			<div class="col-3 pt-1 col-lg-3 text-lg-left text-right">
				<label class="w-100 text-right font-weight-bold tap-text-primary"
					>Total Gross Income</label
				>
			</div>
			<div class="col-3 pt-1 col-lg-3 text-left">
				<span>{{ overallGross | currency }}</span>
			</div>
		</div>
		<div class="form-row line mb-3"></div>
	</div>
</form>
