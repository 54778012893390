<app-secondary-base-widget headerText="Pipelines" [widgetCode]="widgetCode">
  <div class="btn-group d-block" ngProjectAs="input">
    <button
      class="tap-btn tap-btn--default d-block dropdown-toggle btn-sm tap-dynamic-text-color px-3"
      type="button"
      id="pipelineDD"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ isTabToLr() ? 'L&R Insurance' : isTabToM() ? 'Mortgage' : isTabToK() ? 'KiwiSaver' : isTabToI() ? 'Investment' : isTabToFG() ? 'F&G Insurance' : 'L&R Insurance' }}
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <a class="btn dropdown-item py-1 clickable" *ngIf="hasLR$ | async" (click)="setTabToLr()">L&R Insurance</a>
      <a class="btn dropdown-item py-1 clickable" *ngIf="hasM$ | async" (click)="setTabToM()">Mortgage</a>
      <a class="btn dropdown-item py-1 clickable" *ngIf="hasFG$ | async" (click)="setTabToFG()">F&G Insurance</a>
      <a class="btn dropdown-item py-1 clickable" *ngIf="hasK$ | async" (click)="setTabToK()">KiwiSaver</a>
      <a class="btn dropdown-item py-1 clickable" *ngIf="hasI$ | async" (click)="setTabToI()">Investment</a>
    </div>
  </div>
  <div class="p-window">
    <app-lrp
      *ngIf="hasLR$ | async"
      class="tap-tab-content bg-white py-3"
      [ngClass]="{ 'd-none': !isTabToLr(), 'px-3': !(shrinkMode$ | async) }"
    ></app-lrp>
    <app-mp
      *ngIf="hasM$ | async"
      class="tap-tab-content bg-white py-3"
      [ngClass]="{ 'd-none': !isTabToM(), 'px-3': !(shrinkMode$ | async) }"
    ></app-mp>
    <app-kp
      *ngIf="hasK$ | async"
      class="tap-tab-content bg-white py-3"
      [ngClass]="{ 'd-none': !isTabToK(), 'px-3': !(shrinkMode$ | async) }"
    ></app-kp>
    <app-ip
      *ngIf="hasI$ | async"
      class="tap-tab-content bg-white py-3"
      [ngClass]="{ 'd-none': !isTabToI(), 'px-3': !(shrinkMode$ | async) }"
    ></app-ip>
    <app-fgp
      *ngIf="hasFG$ | async"
      class="tap-tab-content bg-white py-3"
      [ngClass]="{ 'd-none': !isTabToFG(), 'px-3': !(shrinkMode$ | async) }"
    ></app-fgp>
  </div>
</app-secondary-base-widget>
