import { BLStaff } from './bl-staff.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from "@angular/core";

export interface BLStaffsState extends EntityState<BLStaff> {}

@Injectable()
@StoreConfig({ name: 'blStaffs', idKey: 'StaffID' })
export class BLStaffsStore extends EntityStore<BLStaffsState, BLStaff> {
	constructor() {
		super();
	}
}
