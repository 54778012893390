import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ClientSearchQuery } from '../state/client-search.query';
import { ClientSearchService } from '../state/client-search.service';
import { Metakey } from '../client-search-page/client-search-datatable.config';
import { Subject } from 'rxjs';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Row } from '../state/client-search.model';
declare var $: any;

@Component({
  selector: 'app-client-search-columns-form',
  templateUrl: './client-search-columns-form.component.html',
  styleUrls: ['./client-search-columns-form.component.scss'],
})
export class ClientSearchColumnsFormComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy$: Subject<void> = new Subject<void>();

  visibleColumns$ = this.clientQuery.tableColumns$;
  hiddenColumns$ = this.clientQuery.hiddenTableColumns$;

  visibleColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];
  hiddenColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];

  isColumnSaving$ = this.clientQuery.uiStore.isColumnSaving$;

  form = new UntypedFormGroup({});
  formInitialized = false;

  constructor(
    private clientQuery: ClientSearchQuery,
    private clientService: ClientSearchService
  ) {}

  ngOnInit() {
    this.visibleColumns$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) =>
          (this.visibleColumns = res?.filter(
            (x) => x.metakey !== 'Name'
          ))
      );
    this.hiddenColumns$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => (this.hiddenColumns = res));
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      $('.disabled-sortable').parent().addClass('disabled d-none');
    });
  }

  prepareFormValue() {
    return [
      'Name',
      ...this.visibleColumns?.map((x) => x.metakey),
    ] as Metakey[];
  }

  save() {
    if (this.visibleColumns.length === 0) {
      return;
    }
    this.clientService
      .saveVisibleColumns(this.prepareFormValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => {},
        () => {},
        () => {
          this.clientService.closePopup();
        }
      );
  }
  cancel() {
    this.clientService.closePopup();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
