import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as R from 'ramda';
import { BehaviorSubject, iif, Observable, of, Subject } from 'rxjs';
import {
	concatMap,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { UserQuery } from '../../../../domain/user/user.query';
import {
	contentEditable,
	getContentWithMergeTags,
	removeEmptyParagraphs,
} from '../../../../shared/converter/content-merge-tags';
import { MergeTagsMapper } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from '../../../../shared/wysiwyg/wysiwyg.component';
import { convertUtil } from '../../../../util/util';
import { SettingsTypes } from '../../state/kiwisaver-settings.model';
import { AdviceSummarySettingsMapper } from '../state/advice-summary-settings.mapper';
import { AdviceSummarySettingsState } from '../state/advice-summary-settings.model';
import { AdviceSummarySettingsService } from '../state/advice-summary-settings.service';
declare var $: any;

@Component({
	selector: 'app-advice-summary-template',
	templateUrl: './advice-summary-template.component.html',
	styleUrls: ['./advice-summary-template.component.scss'],
})
export class AdviceSummaryTemplateComponent implements OnInit, OnDestroy {
	@Input() template: string;
	@Input() mergeTags$: Observable<MergeTagState[]>;
	@Input() adviceSummaryData: AdviceSummarySettingsState;

	private onDestroy$ = new Subject<void>();
	undoReset$ = new BehaviorSubject<any>(null);
	undoStack$ = new BehaviorSubject<any>(null);

	isLoading = false;
	isPreview = false;
	isReload = true;
	customButtons: [];
	mergeTags = [];
	undoStack = [];
	currentTemplate = '<p></p>';
	tempData: string;
	templateId: number | null;
	shortcodes: object;

	@ViewChild('contentEditor') editor: WysiwygComponent;

	isTapLevel = this.userQuery.isTapLevel();

	constructor(
		private adviceSummarySettingsService: AdviceSummarySettingsService,
		private userQuery: UserQuery
	) {}

	ngOnInit(): void {
		this.mergeTags$
			.pipe(
				map((mt) => MergeTagsMapper.mapMergeTags(mt, true)),
				tap((x: any) => (this.mergeTags = x)),
				map((mt) => MergeTagsMapper.mapTagsForWysiwyg(mt)),
				tap((data) => (this.shortcodes = data)),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => this.backToEdit());

		this.prepData();
	}

	prepData() {
		of(this.adviceSummaryData)
			.pipe(
				map((data: any) => ({
					...data,
					template: R.either(R.isNil, R.isEmpty)(data?.template)
						? null
						: +data?.template,
				})),
				tap((x) => (this.templateId = x?.template)),
				concatMap((x) =>
					this.adviceSummarySettingsService.getDocumentFromURL(x?.templateLink)
				),
				take(1)
			)
			.subscribe((data) => {
				if (data) {
					const template = this.isTapLevel
						? data
						: `<div ${contentEditable.false}>${data}</div>`;
					this.currentTemplate = template;
				}
			});
	}

	getUndoStack(event) {
		this.undoStack = event;
	}

	save(event) {
		this.isLoading = true;
		of(this.isReload)
			.pipe(
				concatMap((x) =>
					iif(() => x, this.saveReloadTemplate(x), this.saveTemplate())
				),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	saveReloadTemplate = (isReload) =>
		of(this.undoStack).pipe(
			concatMap((undoStack) =>
				iif(
					() => isReload && R.isEmpty(undoStack),
					this.adviceSummarySettingsService
						.updateAdviceSummarySettings({
							template: null,
							referenceId: 0,
							type: SettingsTypes.KOATAdviceSummary,
						})
						.pipe(
							finalize(() => {
								this.isReload = false;
								this.templateId = null;
							})
						),
					this.saveTemplate()
				)
			)
		);

	saveTemplate = () =>
		of(this.editor?.content).pipe(
			mergeMap((curTmpl) => convertUtil.convertToBase64(curTmpl)),
			withLatestFrom(of(this.templateId)),
			map(([curTmpl, tmplId]) =>
				AdviceSummarySettingsMapper.mapDocumentUpload(curTmpl, tmplId)
			),
			concatMap((data) =>
				iif(
					() =>
						R.isNil(data?.documentId) ||
						isNaN(data?.documentId) ||
						data?.documentId === 0,
					this.adviceSummarySettingsService.newFileUploadAdviceSummary(data),
					this.adviceSummarySettingsService.updateFileUploadAdviceSummary(data)
				)
			),
			tap((data) => {
				this.templateId =
					this.templateId === 0 ||
					R.isNil(this.templateId) ||
					isNaN(this.templateId)
						? +data
						: this.templateId;
			}),
			withLatestFrom(of(this.templateId)),
			concatMap(([data, tmplId]) => {
				return iif(
					() => tmplId === 0 || R.isNil(tmplId) || isNaN(tmplId),
					this.adviceSummarySettingsService.updateAdviceSummarySettings({
						template: data,
						referenceId: 0,
						type: SettingsTypes.KOATAdviceSummary,
					}),
					of(tmplId)
				)
			}),
			finalize(() => (this.isLoading = false)),
			take(1)
		);

	reloadTemplate(event) {
		this.isLoading = true;
		this.adviceSummarySettingsService
			.getDefaultSoaTemplateUrl()
			.pipe(
				concatMap((url: string) =>
					this.adviceSummarySettingsService.getDocumentFromURL(url)
				),
				finalize(() => {
					this.isLoading = false;
					this.isReload = true;
					this.templateId = null;
					this.undoReset$.next(event);
				}),
				take(1)
			)
			.subscribe((data) => {
				if (data) {
					const template = this.isTapLevel
						? data
						: `<div ${contentEditable.false}>${data}</div>`;
					this.currentTemplate = template;
					this.editor.content = template;
				}
			});
	}

	backToEdit() {
		if (!this.isPreview) {
			return;
		}
		this.currentTemplate = this.tempData;
		this.isPreview = false;
		$(
			'app-soa-document-template .fr-element.fr-view, app-soa-document-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
		).css('pointer-events', 'unset');
		$(
			'app-soa-document-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
		).css({ opacity: 1 });
	}

	showPreview() {
		this.isPreview = true;
		this.tempData = this.editor?.content;
		const getConvertedContent = getContentWithMergeTags(
			this.editor?.content,
			this.mergeTags
		);
		$(
			'app-soa-document-template .fr-element.fr-view, app-soa-document-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
		).css('pointer-events', 'none');
		$(
			'app-soa-document-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
		).css({ opacity: 0.6 });
		this.currentTemplate = removeEmptyParagraphs(getConvertedContent);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
