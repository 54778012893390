import { CRTNoteState } from './../../../shared/models/client-review-template/note/crt-note.model';
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil, delay, take, switchMap } from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { AdviceProcessPageCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { ActivityViewModel } from 'src/app/shared/models/_general/activity.viewmodel';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { ClientReviewTemplateProgressComponent } from './client-review-template-progress/client-review-template-progress.component';
import { ClientReviewTemplateService } from './states/client-review-template.service';
import { CrtNoteService } from './states/note/crt-note.service';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsStatus } from '@modules/exports/state/exports.model';
import { CrtNoteQuery } from './states/note/crt-note.query';

declare var $: any;
@Component({
	selector: 'app-client-review-template',
	templateUrl: './client-review-template.component.html',
	styleUrls: ['./client-review-template.component.scss'],
})
export class ClientReviewTemplateComponent implements OnInit, OnDestroy {
	isExporting = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private service: ClientReviewTemplateService,
		private crtNoteService: CrtNoteService,
		private activityService: ActivityService,
		private cd: ChangeDetectorRef,
		private blStaffsQuery: BLStaffsQuery,
		private exportsQuery: ExportsQuery,
		private crtNoteQuery: CrtNoteQuery,
	) {}

	private onDestroy$ = new Subject<void>();

	isCollapsed = false;
	isNotes = false;
	primaryClient$ = this.service.primaryClient$;
	adviceProcessId$ = this.service.adviceProcessId$;
	isCompany$ = this.service.isCompany$;
	AT$ = this.service.AT$;
	AM$ = this.service.AM$;
	crtTypes$ = this.crtNoteService.crtTypes$;
	currentTab$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	tabInit: string;
	isCRTLoading: boolean;

	customerId$: Observable<number> = this.service.primaryClient$.pipe(
		map((x: any) => x?.customerID || null)
	);
	customerName$: Observable<string> = this.service.primaryClient$.pipe(
		map((x: any) =>
			x?.customerType === CustomerTypes.PrimaryCustomerIndividual
				? `${x?.firstName || ''} ${x?.lastName || ''}`
				: `${x?.companyName ?? ''}`
		)
	);
	customerLocation$: Observable<string> = this.service.primaryClient$.pipe(
		map((x: any) => x?.physicalAddress ?? null)
	);
	isLead$: Observable<boolean> = this.service.primaryClient$.pipe(
		map((x: any) => x?.contactStatus === 'L')
	);

	activityAdviserChoices$ = this.crtNoteService.activityAdviserChoices$;
	adviserCalendarChoices$ = this.crtNoteService.adviserCalendarChoices$;
	allActiveStaffs$ = this.blStaffsQuery.allActiveStaffs$;

	crtNotesFactAndFind$ = this.crtNoteService.crtNotesFactFind$;
	activeType$ = this.crtNoteService.activeType$;
	adviceProcessPageCodes = AdviceProcessPageCodes;

	@ViewChild('progress') progress: ClientReviewTemplateProgressComponent;

	isNoteCollapse$ = new Subject<boolean>();

	addActivity$ = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToAdd(a)),
			mergeMap((x) => this.activityService.Post(x))
		);
	};
	addCrtNote$ = (notes: string, type: string) =>
		this.crtNoteService.addNote(notes, type);
	updateCrtNote$ = (note: CRTNoteState) => this.crtNoteService.updateNote(note);
	deleteCrtNote$ = (id: number) => this.crtNoteService.deleteNote(id);

	exportCrtNotes$ = (req) => this.crtNoteService.export(req);

	staffId$ = this.service.staffId$;

	ngOnInit() {
		this.tabInit = this.getType();
		this.crtNoteService.setActiveType(this.tabInit);
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.getType()),
				takeUntil(this.onDestroy$)
			)
			.subscribe((type: string | null) => {
				this.setCrtNotesType(type);
			});

		this.exportsQuery.status$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((status) => {
				if (
					status === ExportsStatus.STARTED ||
					status === ExportsStatus.COMPLETE
				) {
					this.isExporting = true;
				}
			});

		this.isNoteCollapse$
			.pipe(
				takeUntil(this.onDestroy$),
				filter((isCollapse) => isCollapse && !this.crtNoteQuery.getHasCache()),
				mergeMap(() => this.adviceProcessId$.pipe(take(1))),
				filter((adviceProcessId) => Boolean(adviceProcessId)),
				switchMap((adviceProcessId) => {
					return this.crtNoteService.getNotes(
						adviceProcessId,
						this.crtNoteQuery.getValue().crtNoteTypes.map((x) => x.value)
					);
				})
			)
			.subscribe();
	}

	/**
	 * Get type from route data
	 * @return string | null
	 */
	getType() {
		let child = this.route.firstChild;
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else if (child.snapshot.data && child.snapshot.data.type) {
				return child.snapshot.data.type;
			} else {
				return '';
			}
		}
	}

	toggleCollapsePanel() {
		this.isCollapsed = !this.isCollapsed;
		if (this.isCollapsed) {
			this.isNotes = true;
		} else {
			this.isNotes = false;
		}
		this.isNoteCollapse$.next(this.isNotes);
	}

	setCrtNotesType(type?: string) {
		this.crtNoteService.setActiveType(type);
	}

	setCurrentTab(currentTab) {
		this.tabInit = null;
		this.currentTab$.next(currentTab);
		this.cd.detectChanges();
	}

	handleTabClick(path: string) {
		this.progress.goToTab(path);
	}

	handleLoad(loading: boolean) {
		this.isCRTLoading = loading;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
