<ng-container *ngIf="!success; else successMessage">
	<button class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()" id="closeModalBtn" [disabled]="uploading">
		<span class="material-icons"> close </span>
	</button>

	<div class="modal-header">
		<h1 class="modal-title" id="myModalLabel">
			{{ headerTitle ? headerTitle : 'Add New Document' }}
		</h1>
	</div>

	<div class="modal-body">
		<div class="fallback clearfix">
			<input
				*ngIf="isSingleUpload; else isMultiple"
				name="file"
				type="file"
				id="file"
				class="p-0"
				[accept]="restrict"
				(click)="selectFile($event); $event.target.value = ''"
				(change)="chooseFile($event)"
				[disabled]="uploading"
			/>
			<ng-template #isMultiple>
				<input
					name="file"
					type="file"
					id="file"
					class="p-0"
					multiple=""
					[accept]="restrict"
					(click)= "$event.target.value = ''"
					(change)="chooseFile($event)"
					[disabled]="uploading"
				/>
			</ng-template>
			<div id="btnChooseFileUpload">Choose File</div>
			<div id="fileUploadLabel" class="upload-modal-fonts">
				Or drag file{{ !isSingleUpload ? 's' : '' }} here

				<p></p>
				<p class="upload-modal-fonts">Maximum upload size is {{ maxFileSizeText }}. {{ additionalInfo }}</p>
			</div>

			<div id="fileslist">
				<div class="f-list upload-bg-color" *ngFor="let f of filenames">
					{{ f }}
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer border-0">
		<ng-container *ngIf="uploading; else showUpload">
			<app-manual-loader-mini [isLoading]="uploading"></app-manual-loader-mini>
		</ng-container>
		<ng-template #showUpload>
			<input
				[disabled]="!fileform"
				type="button"
				value="Upload"
				class="tap-btn tap-btn--default"
				id="filesUploadBtn"
				(click)="uploadClick()"
			/>
		</ng-template>
	</div>
</ng-container>
<ng-template #successMessage>
	<button class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()" id="closeModalBtn">
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-body">
		<div *ngIf="responseMessage">
			<p *ngIf="responseMessage.success > 0">{{ responseMessage.success }} document(s) sucessfully uploaded</p>
			<p *ngIf="responseMessage.failed > 0">{{ responseMessage.failed }} document(s) failed - document is empty</p>
		</div>
		<div *ngIf="!responseMessage">{{ message }}</div>
	</div>
</ng-template>
