import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Resolve, Router } from '@angular/router';
import { UserService } from '../../domain/user/user.service';
import { catchError, mapTo } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class TlAdminDataService implements Resolve<boolean>{

  constructor(
    private userService: UserService,
    private router: Router,
    private location: Location,
  ) {}

  resolve(): Observable<boolean> {
    return this.userService.get().pipe(
      mapTo(true),
      catchError((err) => {
        this.router.navigate(['/error'], { queryParams: { url: this.location.path() }});
        return of(err);
      })
    );
  }
}
