<form [formGroup]="form" [class.submitted]="submitted" novalidate>
	<div class="form-row align-items-center mb-2">
		<div class="col-4 col-md-2 text-left">
			<label for="" class="position-relative font-weight-bold">Borrower</label>
		</div>
		<div class="col-8 col-md-7">
			<app-chips
				id="borrower"
				textboxId="borrower"
				class="crt-form-control-field"
				[attr.disabled]="form.get('borrower')?.disabled"
				textboxClass="theme-crm-field"
				formControlName="borrower"
				[choices]="policyOwnerChoices(form.get('borrower').value)"
			>
			</app-chips>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-4 col-md-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Security
			</label>
		</div>
		<div class="col-8 col-md-7">
			<span
				class="w-100"
				[class.crt-custom-dropdown]="form.get('security')?.enabled"
			>
				<select
					id="security"
					formControlName="security"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of securities" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-4 col-md-2 text-left mb-2 mb-lg-0">
			<label for="" class="position-relative font-weight-bold">
				Liability
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2 mb-lg-0">
			<span
				class="w-100"
				[class.crt-custom-dropdown]="form.get('liability')?.enabled"
			>
				<select
					id="liability"
					formControlName="liability"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of liabilities" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-4 col-md-2 text-left">
			<label for="" class="position-relative font-weight-bold">Lender</label>
		</div>
		<div class="col-8 col-md-2">
			<span
				class="w-100"
				[class.crt-custom-dropdown]="form.get('lender')?.enabled"
			>
				<select
					id="lender"
					formControlName="lender"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of lender" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-4 col-md-2 text-left mb-2 mb-lg-0">
			<label for="" class="position-relative font-weight-bold">
				Loan/Limit
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2 mb-lg-0">
			<div class="dollar-icon">
				<input
					id="loanLimit"
					formControlName="loanLimit"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-4 col-md-2 text-left mb-2 mb-lg-0">
			<label for="" class="position-relative font-weight-bold">
				Loan Repayment
			</label>
		</div>
		<div class="col-8 col-md-2 mb-2 mb-lg-0">
			<div class="dollar-icon">
				<input
					id="loanRepayment"
					formControlName="loanRepayment"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-8 offset-4 offset-md-0 col-md-3 mb-2 mb-lg-0">
			<span
				class="w-100"
				[class.crt-custom-dropdown]="form.get('mortgageFrequency').enabled"
			>
				<select
					id="mortgageFrequency"
					formControlName="mortgageFrequency"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of mortgageFrequency" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-4 col-md-2 text-left mb-2 mb-lg-0">
			<label for="" class="position-relative font-weight-bold">
				Loan Balance
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2 mb-lg-0">
			<div class="dollar-icon">
				<input
					id="loanBalance"
					formControlName="loanBalance"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
		<div class="col-4 col-md-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Paid in Full
			</label>
		</div>
		<div class="col-8 col-md-2">
			<span
				class="w-100"
				[class.crt-custom-dropdown]="form.get('paidInFull').enabled"
			>
				<select
					id="paidInFull"
					formControlName="paidInFull"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of agree" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Interest Rate
			</label>
		</div>
		<div class="col-8 col-md-3 mb-2">
			<div class="dollar-icon dollar-icon-right">
				<input
					id="interestRate"
					formControlName="interestRate"
					class="form-control crt-form-control"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>%</i>
			</div>
		</div>
		<div class="col-4 col-md-2 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Loan Type
			</label>
		</div>
		<div class="col-8 col-md-2 mb-2">
			<span
				class="w-100"
				[class.crt-custom-dropdown]="form.get('loanType').enabled"
			>
				<select
					id="loanType"
					formControlName="loanType"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of loanTypes" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>

	<div class="form-buttons">
		<button
			id="saveButton"
			type="button"
			class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
			[disabled]="isLoading || isLoadingBtn"
			(click)="save()"
		>
			Save
			<i *ngIf="isLoading || isLoadingBtn" class="fas fa-spinner fa-pulse"></i>
		</button>
		<button
			id="cancelButton"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancelClick()"
		>
			Cancel
		</button>
	</div>
</form>
