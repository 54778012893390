import { Row } from './states/kiwisaver.model';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import * as R from 'ramda';
import { TableColumn } from '@swimlane/ngx-datatable';
import { util, computeUtil } from '../../../../util/util';
import produce from 'immer';
import { Kiwisaver } from './kiwisaver-request.model';
import { datatableUtil } from 'src/app/util/datatable.util';

export type Metakey =
	| 'Name'
	| 'Adviser'
	| 'KS Adviser'
	| 'Contact Status'
	| 'Fund Owner'
	| 'Provider'
	| 'Status'
	| 'Member Number'
	| 'Start Date'
	| 'Fund Type'
	| 'IRD Number'
	| 'Contribution'
	| 'Origin'
	| 'Kiwisaver Status Clock'
	| 'Client Next Activity'
	| 'User Next Activity'
	| 'Last Note'
	| 'Additional Contacts'
	| 'Lead Origin'
	| 'FUM'
	| 'Original Adviser'
	| 'Type';

/** possible control types. Move this somewhere more appropriate. */
export type controlType =
	| 'display'
	| 'long-display'
	| 'textbox'
	| 'dropdown'
	| 'note'
	| 'date'
	| 'address'
	| 'activity'
	| 'multiselect'
	| 'textarea'
	| 'money'
	| 'checkbox';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
export type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof Row;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (
		field: FieldMetadata<any>,
		choices?: ViewDisplayValue[],
		row?: Row
	) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
	columnId?: string;
	fieldId?: string;
};

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return R.propOr<string, ViewDisplayValue, string>(
		'',
		'display' as keyof FieldMetadata<any>,
		choiceItem
	);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceIndex = choices?.findIndex((x) => x.value === field.value);
	return choiceIndex > -1 ? choiceIndex : undefined;
};
/** Get multi value display */
const getMultiselectValues = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value || field.value === '[]' || field.value.length === 0) {
		return '';
	}
	const values: string[] =
		typeof field.value === 'string'
			? Array.isArray(JSON.parse(field.value))
				? JSON.parse(field.value)
				: []
			: Array.isArray(field.value)
			? field.value
			: [];
	return values
		.map((v) =>
			R.propOr(
				'',
				'display',
				choices?.find((c) => `${c.value}` === `${v}`)
			)
		)
		?.filter((x) => x)
		.join(', ');
};

const statusClock = (field: FieldMetadata<any>) => {
	const arr = field.value?.split(' ');
	const timePeriod = arr.pop();
	return timePeriod === 'year' || timePeriod === 'years'
		? +(arr[0] * 365)
		: R.complement(R.isEmpty)(field.value)
		? +arr[0]
		: '';
};

const contactStatus = (value: string) => {
	switch (value) {
		case 'L':
			return 'Lead';
		case 'C':
			return 'Client';
		case 'X':
			return 'Ex-Client';
	}
};
/** get sort value for checkboxes */
const getCheckboxSortValue = (field: FieldMetadata<any>) => {
	const isTrue: boolean =
		field.value === true ||
		(typeof field.value === typeof '' && field.value?.toLowerCase() === 'true');
	return R.defaultTo('', isTrue ? '1' : '2');
};
/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
	const b = R.defaultTo('', date)?.split(/\D/);
	return b?.reverse().join('/');
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
	const splicedDate = R.defaultTo('', note)?.slice(0, 10);
	return reformatDateString(splicedDate);
};
// Header
export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'Name',
		prop: 'Name',
		name: 'Client Name',
		width: 200,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Name'),
		fieldId: datatableUtil.formatFieldId('Name')
	},
	{
		metakey: 'Adviser',
		prop: 'Adviser',
		name: 'Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser')
	},
	// Adviser Rework	
	{
		metakey: 'KS Adviser',
		prop: 'KSAdviser',
		name: 'KiwiSaver Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('KiwiSaver Adviser'),
		fieldId: datatableUtil.formatFieldId('KiwiSaver Adviser'),
	},
	{
		metakey: 'Contact Status',
		prop: 'ContactStatus',
		name: 'Contact Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Contact Status'),
		fieldId: datatableUtil.formatFieldId('Contact Status')
	},
	{
		metakey: 'Fund Owner',
		prop: 'FundOwner',
		name: 'Fund Owner',
		width: 200,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold fixed-column',
		sortValueGetter: (f, c, r) => {
			const isEmpty = util.isNullOrEmpty(f.value);
			const noChoices = util.isNullOrEmpty(r.FundOwnersList);
			if (isEmpty || noChoices) {
				return undefined;
			}

			const customChoices = r.FundOwnersList?.map((po) =>
				ViewDisplayValue.Map(po.Id ? po.Id?.toString() : null, po.Name)
			);
			return getMultiselectValues(customChoices, f);
		},
		controlType: 'multiselect',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Fund Owner'),
		fieldId: datatableUtil.formatFieldId('Fund Owner')
	},
	{
		metakey: 'Provider',
		prop: 'Provider',
		name: 'Provider',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Provider'),
		fieldId: datatableUtil.formatFieldId('Provider')
	},
	{
		metakey: 'Status',
		prop: 'Status',
		name: 'Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Status'),
		fieldId: datatableUtil.formatFieldId('Status')
	},
	{
		metakey: 'Member Number',
		prop: 'MemberNumber',
		name: 'Member Number',
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Member Number'),
		fieldId: datatableUtil.formatFieldId('Member Number')
	},
	{
		metakey: 'Start Date',
		prop: 'StartDate',
		name: 'Start Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Start Date'),
		fieldId: datatableUtil.formatFieldId('Start Date')
	},
	{
		metakey: 'Fund Type',
		prop: 'FundType',
		name: 'Fund Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getMultiselectValues(c, f),
		controlType: 'multiselect',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Fund Type'),
		fieldId: datatableUtil.formatFieldId('Fund Type')
	},
	{
		metakey: 'IRD Number',
		prop: 'IRDNumber',
		name: 'IRD Number',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('IRD Number'),
		fieldId: datatableUtil.formatFieldId('IRD Number')
	},
	{
		metakey: 'FUM',
		prop: 'FUM',
		name: 'FUM',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('FUM'),
		fieldId: datatableUtil.formatFieldId('FUM')
	},
	{
		metakey: 'Contribution',
		prop: 'Contribution',
		name: 'Contribution',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser')
	},
	{
		metakey: 'Origin',
		prop: 'Origin',
		name: 'Origin',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Origin'),
		fieldId: datatableUtil.formatFieldId('Origin')
	},
	{
		metakey: 'Kiwisaver Status Clock',
		prop: 'StatusClock',
		name: 'Status Clock',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => statusClock(f),
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('Kiwisaver Status Clock'),
		fieldId: datatableUtil.formatFieldId('Kiwisaver Status Clock')
	},
	{
		metakey: 'Client Next Activity',
		prop: 'ClientNextActivity',
		name: 'Client Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('Client Next Activity'),
		fieldId: datatableUtil.formatFieldId('Client Next Activity')
	},
	{
		metakey: 'User Next Activity',
		prop: 'UserNextActivity',
		name: 'Your Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('User Next Activity'),
		fieldId: datatableUtil.formatFieldId('User Next Activity')
	},
	{
		metakey: 'Last Note',
		prop: 'LastNote',
		name: 'Last Note',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'note',
		columnId: datatableUtil.formatColumnId('Last Note'),
		fieldId: datatableUtil.formatFieldId('Last Note')
	},
	{
		metakey: 'Additional Contacts',
		prop: 'AdditionalContact',
		name: 'Additional Contacts',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'long-display',
		columnId: datatableUtil.formatColumnId('Additional Contacts'),
		fieldId: datatableUtil.formatFieldId('Additional Contacts')
	},
	{
		metakey: 'Lead Origin',
		prop: 'LeadOrigin',
		name: 'Lead Origin',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Lead Origin'),
		fieldId: datatableUtil.formatFieldId('Lead Origin')
	},
	{
		metakey: 'Original Adviser',
		prop: 'OriginalAdviser',
		name: 'Original Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Original Adviser'),
		fieldId: datatableUtil.formatFieldId('Original Adviser')
	},
];
/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 18,
};
/**
 * template for Row
 * Dynamic fields
 */
export const templateRow: Row = {
	CustomerID: null,
	CustomerServiceID: null,
	FundOwnersList: null,
	ClientNextActivityId: null,
	UserNextActivityId: null,
	Name: {
		metakey: 'Name',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Name').prop,
		required: false,
		editable: false,
		isCustomerDetail: true,
	},
	Adviser: {
		metakey: 'Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Adviser').prop,
		required: true,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	// Adviser Rework
	KSAdviser: {
		metakey: 'KS Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'KS Adviser').prop,
		required: true,
		editable: false,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	ContactStatus: {
		metakey: 'Contact Status',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Contact Status').prop,
		required: false,
		editable: false,
		isCustomerDetail: true,
	},
	FundOwner: {
		metakey: 'Fund Owner',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Fund Owner').prop,
		required: true,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Provider: {
		metakey: 'Provider',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Provider').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Status: {
		metakey: 'Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Status').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	MemberNumber: {
		metakey: 'Member Number',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Member Number').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	StartDate: {
		metakey: 'Start Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Start Date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	FundType: {
		metakey: 'Fund Type',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Fund Type').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	IRDNumber: {
		metakey: 'IRD Number',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'IRD Number').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	FUM: {
		metakey: 'FUM',
		value: '0.0000',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'FUM').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Contribution: {
		metakey: 'Contribution',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Contribution').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Origin: {
		metakey: 'Origin',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Origin').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	StatusClock: {
		metakey: 'Kiwisaver Status Clock',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Kiwisaver Status Clock').prop,
		required: false,
		editable: false,
	},
	ClientNextActivity: {
		metakey: 'Client Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Client Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	UserNextActivity: {
		metakey: 'User Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'User Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	LastNote: {
		metakey: 'Last Note',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Last Note').prop,
		required: false,
		editable: true,
		hasToolTip: true,
		restrict: ['FAN'],
	},
	AdditionalContact: {
		metakey: 'Additional Contacts',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Additional Contacts').prop,
		required: false,
		editable: false,
		hasToolTip: true,
	},
	LeadOrigin: {
		metakey: 'Lead Origin',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Lead Origin').prop,
		required: false,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	OriginalAdviser: {
		metakey: 'Original Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Original Adviser').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FEO'],
	},
	link: null,
};

/**
 * Table body
 * Fill value on rows
 */
export const createRowFromPrototype = R.curry((template: Row, row: Kiwisaver) =>
	produce<Row>(template, (draft) => {
		draft.CustomerID = row.CustomerID;
		draft.CustomerServiceID = row.CustomerServiceID;
		draft.ClientNextActivityId = row.ClientNextActivityId;
		draft.UserNextActivityId = row.UserNextActivityId;
		draft.FundOwnersList = row.FundOwnersList;

		draft.Name.metakey = 'Name';
		draft.Name.value = row.Name;

		draft.Adviser.metakey = 'Adviser';
		draft.Adviser.value = row.Adviser;

		// Adviser Rework
		draft.KSAdviser.metakey = row.KSAdviser?.MetaKey || 'KS Adviser';
		draft.KSAdviser.value = row.KSAdviser;

		draft.ContactStatus.metakey = 'Contact Status';
		draft.ContactStatus.value = contactStatus(row.ContactStatus);

		draft.FundOwner.metakey = 'Fund Owner';
		draft.FundOwner.value = row.FundOwner
			? (row.FundOwner as number[])?.map((owner) => owner?.toString())
			: [];
		draft.FundOwner.choices = row.FundOwnersList?.map(
			(x: { Id: number; Name: string }) => ({
				display: x.Name,
				value: x.Id ? x.Id?.toString() : null,
			})
		) as ViewDisplayValue[];

		draft.Provider.metakey = 'Provider';
		draft.Provider.value = row.Provider;

		draft.Status.metakey = 'Status';
		draft.Status.value = row.Status;

		draft.MemberNumber.metakey = 'Member Number';
		draft.MemberNumber.value = row.MemberNumber;

		draft.StartDate.metakey = 'Start Date';
		draft.StartDate.value = row.StartDate;

		draft.FundType.metakey = 'Fund Type';
		draft.FundType.value = Array.isArray(row.FundType)
			? JSON.stringify(row.FundType)
			: row.FundType;

		draft.IRDNumber.metakey = 'IRD Number';
		draft.IRDNumber.value = row.IRDNumber;

		draft.FUM.metakey = 'FUM';
		draft.FUM.value = row.FUM
			? row.FUM === '0' || row.FUM === null
				? draft.FUM.value
				: row.FUM
			: draft.FUM.value;

		draft.Contribution.metakey = 'Contribution';
		draft.Contribution.value = row.Contribution
			? row.Contribution === '0'
				? draft.Contribution.value
				: row.Contribution
			: draft.Contribution.value;

		draft.Origin.metakey = 'Origin';
		draft.Origin.value = row.Origin;

		draft.StatusClock.metakey = 'Kiwisaver Status Clock';
		draft.StatusClock.value = computeUtil.calcStatusClock(row.StatusClock);

		draft.ClientNextActivity.metakey = 'Client Next Activity';
		draft.ClientNextActivity.value = row.ClientNextActivity;

		draft.UserNextActivity.metakey = 'User Next Activity';
		draft.UserNextActivity.value = row.UserNextActivity;

		draft.LastNote.metakey = 'Last Note';
		draft.LastNote.value = row.LastNote;

		draft.AdditionalContact.metakey = 'Additional Contacts';
		draft.AdditionalContact.value = row.AdditionalContact;

		draft.LeadOrigin.metakey = 'Lead Origin';
		draft.LeadOrigin.value = row.LeadOrigin;

		draft.OriginalAdviser.metakey = 'Original Adviser';
		draft.OriginalAdviser.value = row.OriginalAdviser;
		draft.OriginalAdviser.choices = draft.OriginalAdviser.choices?.filter(
			(x) =>
				x.value === row.OriginalAdviser ||
				draft.Adviser.choices?.find((y) => y.value === x.value)
		);

		draft.link = {
			CustomerServiceID: row.CustomerServiceID,
			CustomerID: row.CustomerID,
			IsCompany: row.IsCompany,
		};
	})
);
