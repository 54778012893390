<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.Expenses"></app-header>
</div>

<!-- Content -->
<div role="main-content">
	<div class="main-income-budgets">
		<app-crt-budget-form
			#expenses
			[monthlyExpense$]="monthlyExpense$"
			[addNewFn$]="addNewMonthlyExpense$"
			[updateFn$]="updateMonthlyExpense$"
			[APCRTF$]="APCRTF$"
			[APCRTYN$]="APCRTYN$"
			[mortgagePayments]="mortgagePayments$ | async"
			[personalDebtValue]="personalDebtValue$ | async"
			[factFindComputation]="factFindComputation$ | async"
			[updateStateFn]="updateExpensesState$"
			[peopleList]="peopleList$ | async"
			[investmentRates]="investmentRates$ | async"
			[ownerOccupiedRates]="ownerOccupiedRates$ | async"
		></app-crt-budget-form>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer
		[currentPageId]="pageIds.Expenses"
		[sidebar]="sidebar"
	></app-footer>
</div>