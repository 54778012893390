import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { FGPremiumStatsDomestic } from './fgpsd.model';
import { Injectable } from "@angular/core";

export interface FGPremiumStatsDomesticState {

  /**
   * FG Premium Stats Domestic fields
   */
  FGPremiumStatsDomestic: FGPremiumStatsDomestic[];

  /**
   * error message. will show up when not empty
   */
  error: string;

  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): FGPremiumStatsDomesticState {
  return {
    FGPremiumStatsDomestic: [],
    error: '',
    isLoading: false
  };
}


/**
 * FG Premium Stats Domestic widget data store for Rolling 12 Months (Year)
 */
@Injectable()
@StoreConfig({
  name: 'FGPremiumStatsDomestic'
})
export class FGPremiumStatsDomesticStore extends Store<FGPremiumStatsDomesticState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Set if FGP remium Stats Domestic is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
