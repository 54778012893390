import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownValue } from '../../../../../modules/dropdown/model/dropdownValue';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { LOATPeopleDetailsState } from '../../../../../shared/models/client-review-template/people/people-details.model';
import { DependentDetailsState } from '../../../../../shared/models/client-review-template/dependent/dependent-details';
import { TrustDetailsState } from '../../../../../shared/models/client-review-template/trust/trust-details';
import { CompanyDetailsState } from '../../../../../shared/models/client-review-template/company/company-details';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { BusinessInternalServices } from '@domain/business-config/business-config.model';
import { BusinessService } from '@core/business/business.service';
import { PeopleState } from '@shared/models/client-review-template/people/people.model';
import { DependantState } from '@modules/crm/crt-page/crt-kiwisaver/people-entities/dependants/state/dependants.model';

@Component({
	selector: 'app-people-modal',
	templateUrl: './people-modal.component.html',
	styleUrls: ['./people-modal.component.scss'],
})
export class PeopleModalComponent implements ConfirmModalModel, OnInit {
	savefn: (model: any) => Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	dropdownCodes: DropdownValue[];
	viewMode: boolean;

	hasCountry: boolean;

	loadingState: boolean;
	cancelLoadingState: boolean;

	peopleAndDependents: (PeopleState | DependantState)[];
	people: PeopleState[];

	location: 'MOAT' | 'LOAT' | string = 'LOAT';

	public scr$: Observable<ViewDisplayValue[]>;
	public pce$: Observable<ViewDisplayValue[]>;
	public sctt$: Observable<ViewDisplayValue[]>;
	public apcrtvd$: Observable<ViewDisplayValue[]>;
	public apcrtg$: Observable<ViewDisplayValue[]>;
	public apcrtbp$: Observable<ViewDisplayValue[]>;
	public apcrtyn$: Observable<ViewDisplayValue[]>;
	public apcrtynna$: Observable<ViewDisplayValue[]>;

	public apcrttir$: Observable<ViewDisplayValue[]>;
	public apcrtms$: Observable<ViewDisplayValue[]>;
	public pct$: Observable<ViewDisplayValue[]>;

	personInfo: LOATPeopleDetailsState;
	dependentInfo: DependentDetailsState;
	trustInfo: TrustDetailsState;
	companyInfo: CompanyDetailsState;

	// loatv2Enabled$ = this.businessConfigQuery.select((x) =>
	// 	x.config.AOT.includes(BusinessInternalServices.LoatV2)
	// );

	loatv2Enabled$ = this.businessService.loatv2Enabled$;

	constructor(
		public bsModalRef: BsModalRef,
		// private businessConfigQuery: BusinessConfigQuery,
		private businessService: BusinessService
	) {
		this.setupProps();
		this.setupSubs();
	}

	ngOnInit() {}

	setupProps() {
		this.header = 'Confirm';
		this.message = 'Confirm?';
	}
	setupSubs() {}

	save(model) {
		this.loadingState = true;
		this.savefn(model).subscribe({
			next: () => {
				this.bsModalRef.hide();
				setTimeout(() => (this.loadingState = false), 500);
			},
			error: () => {
				this.loadingState = false;
			},
		});
		// this.bsModalRef.hide();
	}

	decline() {
		if (!!this.decline$) {
			this.decline$.subscribe();
		}
		this.bsModalRef.hide();
	}

	cancelChange(value) {
		this.cancelLoadingState = true;
		this.bsModalRef.hide();
		this.decline$.subscribe();
		setTimeout(() => (this.cancelLoadingState = false), 500);
	}
}
export interface ConfirmModalModel {
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
