import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UserQuery } from '../../domain/user/user.query';
import { ConfigService } from '../../core/config/config.service';
import { RouteService } from '../../core/config/route.service';
import { Subscription, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { map, withLatestFrom } from 'rxjs/operators';
import { BusinessConfigQuery } from '../../domain/business-config/business-config.query';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { User } from '@domain/user/user.model';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit, AfterViewInit, OnDestroy {
  userInfo$ = this.userQuery.userInfo$;
  isTapLevelUser$ = this.userQuery.isTapLevel$;
  profilePic$ = this.userQuery.profilePic$;

  name$ = this.userInfo$.pipe(map((x) => x.FirstName + ' ' + x.LastName));
  email$ = this.userInfo$.pipe(map((x) => x.EmailAddress));
  googleImageUrl$ = of(
    this.authService.user ? this.authService.user.picture : ''
  );
  constructor(
    private userQuery: UserQuery,
    private cs: ConfigService,
    private rs: RouteService,
    private ds: DomSanitizer,
    private businessConQuery: BusinessConfigQuery,
    private authService: AuthenticationService
  ) {}
  isOnTapLevelView = false;
  isOnCrmLevelView = true;
	canManageUsers = false;
	canViewUserDetails = false;
  profilePic = '';

  adminBusinessList = '';
  businessBusiness = '';
  dashboard = '';
	userDetails = '';
	manageUsers = '';
	userInfo: User;

  hasBusinessConfig: boolean;

  subs: Subscription[] = [];

  logoutlink = this.ds.bypassSecurityTrustUrl(
    window.location.origin + '/login/logout'
  );
  ngOnInit() {
    const sub4 = this.cs.IsViewTapLevel.subscribe(
      (x) => (this.isOnTapLevelView = x)
    );
    const sub5 = this.cs.IsViewCrmLevel.subscribe(
      (x) => (this.isOnCrmLevelView = x)
    );
		const sub6 = this.rs.RouteChange$.pipe(
			withLatestFrom(this.isTapLevelUser$, this.userQuery.userInfo$)
		).subscribe(([x, isTapLevel, userInfo]) => {
			this.adminBusinessList = x.adminBusinessList;
			this.businessBusiness = x.business;
			this.dashboard = x.dashboard;
			this.userInfo = userInfo;
			this.manageUsers = isTapLevel ? x.adminManageUsers : this.rs.usersList;
			this.userDetails = x.usersView(userInfo?.StaffID);
			this.canViewUserDetails =
				!isTapLevel && !['LG', 'LGI', 'CCA']?.includes(userInfo?.SecurityGroup); // TAPNZ-13088
			this.canManageUsers =
				!isTapLevel && ['AM', 'BM', 'BO']?.includes(userInfo?.SecurityGroup); // TAPNZ-13088
		});
    const sub7 = this.businessConQuery.businessConfig$.subscribe(
      (x) => (this.hasBusinessConfig = x !== null)
    );
    this.subs.push(sub4, sub5, sub6, sub7);
  }
  ngAfterViewInit() {
    const toggleSwitch: HTMLInputElement = document.querySelector(
      '.theme-switch input[type="checkbox"]'
    );
    const currentTheme = localStorage.getItem('theme')
      ? localStorage.getItem('theme')
      : null;
    if (currentTheme && this.hasBusinessConfig !== null) {
      document.documentElement.setAttribute('data-theme', currentTheme);

      if (toggleSwitch && currentTheme === 'dark') {
        toggleSwitch.checked = true;
      }
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }

  /**
   * Toggle switch for dark and light theme
   */
  switchTheme(e) {
    if (e.target.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
  }

  ngOnDestroy(): void {
    this.subs?.forEach((x) => x.unsubscribe());
    this.subs = [];
  }
  logout() {
    // TODO:: clear cookies
    // and redirect to login page
    // window.location.href = window.location.host + '/login/logout';
    this.authService.logout().subscribe();
  }
}
