import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FundingRequiredLoan } from './loan-repaid.model';
export interface FundingRequiredLoanState extends EntityState<FundingRequiredLoan> {
	loans: FundingRequiredLoan[];
	isLoadedLoans: boolean,
	isUpdatedLoans: boolean
}

@Injectable()
@StoreConfig({ name: 'fundingRequiredLoan', idKey: 'cRTId' })
export class FundingRequiredLoanStore extends EntityStore<FundingRequiredLoanState, FundingRequiredLoan> {
	constructor() {
		super({
			loans: [],
			isLoadedLoans: false,
			isUpdatedLoans: false
		});
	}
}
