<div
	class="select-container crt-custom-dropdown"
	*ngIf="value !== otherValue; else inputTemplate"
>
	<select
		#select
		class="form-control crt-form-control {{ selectClassName }}"
		[attr.dir]="direction"
		[tabindex]="tabindex"
		[disabled]="disabled"
		(change)="onSelectboxChanged($event)"
		[(ngModel)]="value"
		ngModel
	>
		<option value=""></option>
		<option *ngFor="let item of items" [value]="item[valueProperty]">
			<span> {{ item[this.displayProperty] }} </span>
		</option>
	</select>
	<span
		*ngIf="placeholder && (!hasValue || !toBoolean(select?.value))"
		class="placeholder form-control position-absolute"
		>{{ placeholder }}</span
	>
</div>
<!-- .select-container -->
<ng-template #inputTemplate>
	<div class="input-container" *ngIf="value === otherValue">
		<input
			class="form-control {{ textBoxClassName }}"
			type="text"
			#textInput
			[placeholder]="value === otherValue ? textBoxPlaceholder : ''"
			[value]="dropdownValue"
			[tabindex]="tabindex"
			[disabled]="disabled"
			(change)="onTextboxChanged($event)"
			(keydown)="onTextboxKeyDown.emit($event)"
			(blur)="onTextboxBlur($event)"
		/>
	</div>
	<!-- .input-container -->
</ng-template>
