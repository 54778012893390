import { Component, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Metakey } from '../advice-process-datatable.config';
import { Row } from '../states/advice-process.model';
import { AdviceProcessQuery } from '../states/advice-process.query';
import { AdviceProcessService } from '../states/advice-process.service';
declare var $: any;

@Component({
  selector: 'app-advice-process-columns-form',
  templateUrl: './advice-process-columns-form.component.html',
  styleUrls: ['./advice-process-columns-form.component.scss'],
})
export class AdviceProcessColumnsFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  visibleColumns$ = this.lrInsuranceQuery.tableColumns$;
  hiddenColumns$ = this.lrInsuranceQuery.hiddenTableColumns$;

  visibleColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];
  hiddenColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];

  isColumnSaving$ = this.lrInsuranceQuery.uiQuery.isColumnSaving$;

  form = new UntypedFormGroup({});
  formInitialized = false;
  @Input() claimsFeature:boolean = false;
  claimsMetaKeys = [
    'Reference Number',
    'Claim Type',
    'Claim Outcome',
    'Total Paid',
  ]

  constructor(
    private lrInsuranceQuery: AdviceProcessQuery,
    private service: AdviceProcessService
  ) {}

  ngOnInit() {
    this.visibleColumns$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) =>
          (this.visibleColumns = res?.filter(
            (x) =>
              { 
                if(this.claimsMetaKeys.includes(x.metakey) && !this.claimsFeature)
                  return false;
                return x.metakey !== 'Advice Process' && x.metakey !== 'Clients Involved'
              }
          ))
      );
    this.hiddenColumns$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => (this.hiddenColumns = res.filter(
        (x)=>{
          if(this.claimsMetaKeys.includes(x.metakey) && !this.claimsFeature)
            return false;
          return true;
      })));
  }
  ngAfterViewInit() {
    $(document).ready(() => {
      $('.disabled-sortable').parent().addClass('disabled d-none');
    });
  }
  prepareFormValue() {
    return [
      'Advice Process',
      'Clients Involved',
      ...this.visibleColumns?.map((x) => x.metakey),
    ] as Metakey[];
  }

  save() {
    if (this.visibleColumns.length === 0) {
      return;
    }
    this.service
      .saveVisibleColumns(this.prepareFormValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => {},
        () => {},
        () => {
          this.service.closePopup();
        }
      );
  }
  cancel() {
    this.service.closePopup();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
