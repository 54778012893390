import { Component, ViewChild } from '@angular/core';
import { getContentWithMergeTags } from '@shared/converter/content-merge-tags';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from '@shared/wysiwyg/wysiwyg.component';
import { convertUtil, objectUtil } from '@util/util';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import {
  concatMap,
  filter,
  finalize,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';
import { MergeTagsCrtSettingsService } from '../merge-tags/state/merge-tags-crt-settings.service';
import { SettingsTypes } from '../state/crt-settings.model';
import { LoatCffTemplateSettingsMapper } from './state/loat-cff-template-settings.mapper';
import { LoatCffTemplateSettingsService } from './state/loat-cff-template-settings.service';

export const componentName = 'app-loat-cff-template-settings';
@Component({
  selector: 'app-loat-cff-template-settings',
  templateUrl: './loat-cff-template-settings.component.html',
  styleUrls: ['./loat-cff-template-settings.component.scss']
})
export class LoatCffTemplateSettingsComponent {
	template: string;
	mergeTag: MergeTagState[];
	data;
	isLoading = false;
	isPreview = false;
	isReload = true;
	customButtons: [];
	currentTemplate = '<p></p>';
	undoReset$ = new BehaviorSubject<any>(null);
	undoStack$ = new BehaviorSubject<any>(null);

	tempData: string;
	templateId: number;
	shortcodes: object;
	undoStack = [];

	@ViewChild('contentEditor') editor: WysiwygComponent;

  constructor(
    private mtService: MergeTagsCrtSettingsService,
		private loatCffService: LoatCffTemplateSettingsService
  ) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		combineLatest([
			this.loatCffService.loatCffSettings$,
			this.mtService.mergeTags$,
		])
			.pipe(
				filter(([data, mt]) => !!mt),
				tap(([data, mt]) => (this.data = data)),
				tap(([data, mt]) => (this.templateId = +data?.template)),
				tap(([data, mt]) => this.setMergeTags(mt)),
				concatMap(([data, mt]) =>
					iif(
						() =>
							(R.complement(R.isNil)(data?.template) ||
								!isNaN(+data?.template)) &&
							+data?.template !== 0,
						this.loatCffService
							.getClientFactFindDocumentFile(+data?.template)
							.pipe(
								map((res) =>
									res ? objectUtil.mapPascalCaseToCamelCase(res) : null
								),
								concatMap((res) =>
									iif(
										() => res?.documentLink,
										this.loatCffService.getDocumentFromURL(res?.documentLink),
										of(null)
									)
								)
							),
						this.loatCffService.getDocumentFromURL(data?.templateLink)
					)
				),
				tap((data) => {
					this.currentTemplate = data
						? data?.toString().replace(/><\/label>/g, '> </label>')
						: '<p></p>';
				}),
				take(1)
			)
			.subscribe();
	}

	setMergeTags(mergeTags) {
		of(mergeTags)
			.pipe(
				map((mt) => MergeTagsMapper.mapMergeTags(mt, true)),
				tap((mt) => (this.mergeTag = mt)),
				map((mt) => MergeTagsMapper.mapTagsForWysiwyg(mt)),
				tap((mt) => (this.shortcodes = mt)),
				take(1)
			)
			.subscribe();
	}

	getUndoStack(event) {
		this.undoStack = event;
	}

	save() {
		this.isLoading = true;
		of(this.isReload)
			.pipe(
				concatMap((x) =>
					iif(() => x, this.saveReloadTemplate(x), this.saveTemplate())
				),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	saveReloadTemplate = (isReload) =>
		of(this.undoStack).pipe(
			concatMap((undoStack) =>
				iif(
					() => isReload && R.isEmpty(undoStack),
					this.loatCffService
						.updateClientFactFind({
							template: null,
							referenceId: 0,
							type: SettingsTypes.LOATClientFactFind,
						})
						.pipe(
							finalize(() => {
								this.isReload = false;
								this.templateId = null;
							})
						),
					this.saveTemplate()
				)
			)
		);

	saveTemplate = () =>
		of(this.editor?.content).pipe(
			mergeMap((template) => convertUtil.convertToBase64(template)),
			map((template) =>
				LoatCffTemplateSettingsMapper.mapDocumentUpload(
					template,
					this.templateId
				)
			),
			concatMap((data) =>
				iif(
					() =>
						R.isNil(data?.documentId) ||
						isNaN(data?.documentId) ||
						data?.documentId === 0,
					this.loatCffService.newFileUpload(data),
					this.loatCffService.updateFileUpload(data)
				)
			),
			tap((data) => {
				this.templateId = this.hasTemplate(this.templateId)
					? this.templateId
					: +data;
			}),
			concatMap(() =>
				this.loatCffService.updateClientFactFind({
					template: this.templateId?.toString(),
					referenceId: 0,
					type: SettingsTypes.LOATClientFactFind,
					mergeTag: [],
				})
			),
			finalize(() => (this.isLoading = false)),
			take(1)
		);

	hasTemplate = (template) =>
		R.complement(R.either(R.isNil, R.isEmpty))(template) &&
		!isNaN(+template) &&
		+template > 0;

	reloadTemplate(event) {
		this.isLoading = true;
		this.loatCffService
			.getDefaultTemplateUrl()
			.pipe(
				concatMap((url: string) => this.loatCffService.getDocumentFromURL(url)),
				finalize(() => {
					this.isLoading = false;
					this.isReload = true;
					this.templateId = null;
					this.undoReset$.next(event);
				}),
				take(1)
			)
			.subscribe((data) => {
				if (data) {
					this.currentTemplate = data;
					this.editor.content = data;
				}
			});
	}

	backToEdit() {
		this.currentTemplate = this.tempData;
		this.isPreview = false;
		$(
			`${componentName} .fr-element.fr-view, ${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'unset');
		$(`${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 1,
		});
	}

	showPreview() {
		this.isPreview = true;
		this.tempData = this.editor?.content;
		const getConvertedContent = getContentWithMergeTags(
			this.editor.content,
			this.mergeTag
		);
		$(
			`${componentName} .fr-element.fr-view, ${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'none');
		$(`${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 0.6,
		});
		this.currentTemplate = getConvertedContent;
	}

}
