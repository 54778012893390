import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MortgageStore, MortgageState } from './mortgage.store';

@Injectable()
export class MortgageQuery extends QueryEntity<MortgageState> {
	mortgages$ = this.selectAll({ filterBy: (x) => !!x.status });
	isLoading$ = this.selectLoading();

	constructor(protected store: MortgageStore) {
		super(store);
	}
}
