<div class="container-fluid tap-search-page">
	<div class="clearfix py-3">
		<app-advice-process-form
			(toggleSearch)="onToggleSearch($event)"
		></app-advice-process-form>
		<div class="clearfix">
			<div class="row">
				<div class="col p-0">
					<ngx-datatable
						#mydatatable
						class="bootstrap"
						[style.height]="tblHeight"
						[ngClass]="{
							showSearchFields: showSearchFields,
							'disabled-table': isSearching$ | async,
							'disable-sort': !(hasRow$ | async)
						}"
						[columns]="columnsWithLink$ | async"
						[rows]="rows$ | async"
						[columnMode]="'standard'"
						[rowIdentity]="rowIdentity"
						[scrollbarH]="true"
						[trackByProp]="'AdviceProcessID'"
						[scrollbarV]="true"
						[headerHeight]="32"
						[rowHeight]="40"
						[footerHeight]="false"
						(reorder)="reorder($event)"
						(resize)="resize($event)"
						[externalSorting]="true"
						sortType="single"
						(sort)="sort($event)"
						[rowClass]="getRowClass"
						[sorts]="sorts$ | async"
						(page)="onPage($event, mydatatable.bodyComponent.indexes)"
					>
						<ngx-datatable-column
							*ngFor="let c of columns$ | async"
							[name]="c.name"
							[prop]="c.prop"
							[width]="c.width"
							[resizeable]="true"
							[cellClass]="c.cellClass"
							[draggable]="
								!(
									c.metakey === 'Advice Process' ||
									c.metakey === 'Clients Involved'
								)
							"
							[frozenLeft]="
								c.metakey === 'Advice Process' ||
								c.metakey === 'Clients Involved'
							"
						>
							<ng-template
								ngx-datatable-header-template
								let-column="column"
								let-sort="sortFn"
							>
								<i
									class="material-icons draggable"
									[id]="c.columnId"
									[class.d-none]="
										c.metakey === 'Advice Process' ||
										c.metakey === 'Clients Involved'
									"
									>drag_indicator</i
								>
								<span class="datatable-header-cell-wrapper" (click)="sort()">
									<span class="datatable-header-cell-label">{{
										column.name
									}}</span>
								</span>
							</ng-template>

							<ng-template
								ngx-datatable-cell-template
								let-row="row"
								let-value="value"
								let-rowIndex="rowIndex"
							>
								<!-- Tried to provide template for each column but [Object, object] bug happens so made it this way -->
								<ng-container [ngSwitch]="c.controlType">
									<app-display
										*ngSwitchCase="'display'"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>
									<app-long-display
										*ngSwitchCase="'long-display'"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									></app-long-display>
									<app-dropdown
										*ngSwitchCase="'dropdown'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										[choices]="c.choices"
										[choicesObject]="c.choicesAsObject"
										[hasSort]="c.metakey === 'Adviser' ? true : false"
										[allChoices]="
											c.metakey === 'Adviser' || c.metakey === 'Reviewed By'
												? (allAdviserChoices$ | async)
												: undefined
										"
										[allChoicesObject]="
											c.metakey === 'Adviser' || c.metakey === 'Reviewed By'
												? (allAdviserChoicesAsObject$ | async)
												: undefined
										"
										(editEvent)="edit(row.AdviceProcessID, c.metakey)"
										(cancelEvent)="cancel(row.AdviceProcessID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.AdviceProcessID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.AdviceProcessID, c.metakey, $event)
										"
									></app-dropdown>
									<app-textbox
										*ngSwitchCase="'textbox'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isTwoDecimal]="c.isTwoDecimal"
										(editEvent)="edit(row.AdviceProcessID, c.metakey)"
										[isEditable]="value.editable"
										(cancelEvent)="cancel(row.AdviceProcessID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.AdviceProcessID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.AdviceProcessID, c.metakey, $event)
										"
									></app-textbox>
									<app-date
										*ngSwitchCase="'date'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										(editEvent)="edit(row.AdviceProcessID, c.metakey)"
										(cancelEvent)="cancel(row.AdviceProcessID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.AdviceProcessID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.AdviceProcessID, c.metakey, $event)
										"
									></app-date>
									<app-activity-detail
										*ngSwitchCase="'activity'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[cellClass]="getCellClass(value, row)"
										[value]="value.value"
										[restrict]="value.restrict"
										[activityId]="row.ClientNextActivityId"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerID + '-' + c.metakey]
										"
										(editActivityEvent)="
											updateClientNextActivityField(row.CustomerID)
										"
										[permissionsToComplete]="['FCCA', 'FCLA']"
									></app-activity-detail>
									<app-note
										*ngSwitchCase="'note'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[activityType]="'AP'"
										[adviceProcessID]="row.AdviceProcessID"
										[restrict]="value.restrict"
										[isEditable]="value.editable"
										(updateNoteEvent)="
											updateNoteField(row.AdviceProcessID, $event)
										"
										[customerId]="row.CustomerID"
										[customerServiceId]="row.AdviceProcessID"
									></app-note>
									<ng-container *ngSwitchCase="'multiselect'">
										<app-multiselect
											*ngIf="
												c.metakey === 'Policy Owners';
												else claimTypeMulti
											"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[value]="value.value"
											[restrict]="value.restrict"
											[isLoading]="
												(cellsLoading$ | async)[
													row.AdviceProcessID + '-' + c.metakey
												]
											"
											[isEditing]="
												(cellsEditing$ | async)[
													row.AdviceProcessID + '-' + c.metakey
												]
											"
											[isRequired]="c.isRequired"
											[choices]="
												createChoicesFromEntityList(row.PolicyOwnersList)
											"
											[choicesObject]="
												createLookupFromEntityList(row.PolicyOwnersList)
											"
											[fieldName]="c.name"
											[isEditable]="value.editable"
											(saveEvent)="
												saveField(
													row,
													row.AdviceProcessID,
													c.metakey,
													value.key,
													$event
												)
											"
										>
										</app-multiselect>
										<ng-template #claimTypeMulti>
											<app-multiselect
												*ngIf="
													c.metakey === 'Claim Type';
													else defaultMultiselect
												"
												[index]="rowIndex"
												[fieldId]="c.fieldId"
												[value]="value.value"
												[restrict]="value.restrict"
												[isLoading]="
													(cellsLoading$ | async)[
														row.AdviceProcessID + '-' + c.metakey
													]
												"
												[isEditing]="
													(cellsEditing$ | async)[
														row.AdviceProcessID + '-' + c.metakey
													]
												"
												[isRequired]="c.isRequired"
												[choices]="
												row.AdviceProcess.value.includes('F&G') ?
												 (FGPL$ | async):
												 (LRProducts$ | async)"
												[choicesObject]="
												mapToLookupObject(
													row.AdviceProcess.value.includes('F&G') ?
													 (FGPL$ | async):
													 (LRProducts$ | async)
												)"
												[fieldName]="c.name"
												[isEditable]="value.editable"
												(saveEvent)="
													saveField(
														row,
														row.AdviceProcessID,
														c.metakey,
														value.key,
														$event
													)
												"
											>
											</app-multiselect>
										</ng-template>
										<ng-template #defaultMultiselect>
											<app-multiselect
												[index]="rowIndex"
												[fieldId]="c.fieldId"
												[value]="value.value"
												[restrict]="value.restrict"
												[isLoading]="
													(cellsLoading$ | async)[
														row.AdviceProcessID + '-' + c.metakey
													]
												"
												[isEditing]="
													(cellsEditing$ | async)[
														row.AdviceProcessID + '-' + c.metakey
													]
												"
												[isRequired]="c.isRequired"
												[choices]="c.choices"
												[choicesObject]="c.choicesAsObject"
												[fieldName]="c.name"
												(saveEvent)="
													saveField(
														row,
														row.AdviceProcessID,
														c.metakey,
														value.key,
														$event
													)
												"
											>
											</app-multiselect>
										</ng-template>
									</ng-container>
									<!-- createLookupFromList -->
									<app-textarea
										*ngSwitchCase="'textarea'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										[fieldName]="c.name"
										(saveEvent)="
											saveField(
												row,
												row.AdviceProcessID,
												c.metakey,
												value.key,
												$event
											)
										"
									></app-textarea>
									<app-money
										*ngSwitchCase="'money'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										(editEvent)="edit(row.AdviceProcessID, c.metakey)"
										[isEditable]="value.editable"
										(cancelEvent)="cancel(row.AdviceProcessID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.AdviceProcessID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.AdviceProcessID, c.metakey, $event)
										"
									></app-money>
									<app-datatable-checkbox
										*ngSwitchCase="'checkbox'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[isEditing]="
											(cellsEditing$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.AdviceProcessID + '-' + c.metakey
											]
										"
										(editEvent)="edit(row.AdviceProcessID, c.metakey)"
										(cancelEvent)="cancel(row.AdviceProcessID, c.metakey)"
										(saveEvent)="
											saveField(
												row,
												row.AdviceProcessID,
												c.metakey,
												value.key,
												$event
											)
										"
										(fieldUpdateEvent)="
											setTempValue(row.AdviceProcessID, c.metakey, $event)
										"
									>
									</app-datatable-checkbox>
								</ng-container>
							</ng-template>
						</ngx-datatable-column>

						<ngx-datatable-column
							[prop]="linkColumn.prop"
							[name]="linkColumn.name"
							[width]="linkColumn.width"
							[resizeable]="false"
							headerClass="link-header"
							[cellClass]="'link-ellipses'"
						>
							<ng-template
								ngx-datatable-cell-template
								let-value="value"
								let-row="row"
								let-rowIndex="rowIndex"
							>
								<ng-container
									*ngIf="!(rowsLoading$ | async)[row.AdviceProcessID]"
								>
									<div
										class="dropdown d-flex"
										dropdown
										placement="bottom right"
										container="body"
									>
										<button
											id="toggleMoreBtn_{{rowIndex}}"
											class="tap-btn tap-btn-outline tap-text-primary px-0 py-0"
											dropdownToggle
										>
											<i
												class="
													material-icons
													icon-btn
													md-18
													d-flex
													align-items-center
													px-0
													py-0
													w-auto
												"
											>
												more_vert
											</i>
										</button>
										<ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
											<a
												id="addActivityBtn_{{rowIndex}}"
												*appRestrictTo="['FACA']"
												class="dropdown-item clickable tap-tex-primary"
												(click)="createClientNextActivity(row.CustomerID)"
											>
												Add Activity
											</a>
											<ng-container *appRestrictTo="['FEAP']">
												<a
													id="editBtn_{{rowIndex}}"
													*ngIf="row.link.IsCompany"
													[routerLink]="
														businessRoute(row.CustomerID, row.AdviceProcessID)
													"
													class="dropdown-item clickable tap-tex-primary"
												>
													Edit
												</a>
												<a
													id="editBtn_{{rowIndex}}"
													*ngIf="!row.link.IsCompany"
													[routerLink]="
														clientRoute(row.CustomerID, row.AdviceProcessID)
													"
													class="dropdown-item clickable tap-tex-primary"
												>
													Edit
												</a>
											</ng-container>
											<a
												id="deleteBtn_{{rowIndex}}"
												*appRestrictTo="['FDAP']"
												(click)="
													delete(
														row.CustomerID,
														row.AdviceProcessID,
														row.AdviceProcess.value
													)
												"
												class="dropdown-item clickable tap-tex-primary"
											>
												Delete
											</a>
										</ul>
									</div>
								</ng-container>
								<app-manual-loader-mini
									*ngIf="(rowsLoading$ | async)[row.AdviceProcessID]"
									[isLoading]="(rowsLoading$ | async)[row.AdviceProcessID]"
								></app-manual-loader-mini>
							</ng-template>
						</ngx-datatable-column>
					</ngx-datatable>
				</div>
			</div>
		</div>
	</div>
</div>
