import { RefixMortgage } from './rm.model';
import { StoreConfig, Store,  } from '@datorama/akita';
import produce from 'immer';
import { Injectable } from "@angular/core";

/**
 * Refix mortgage widget state model
 */
export interface RMState {
  /**
   * list of items in refix mortgage list
   */
  RefixMortgageList: RefixMortgage[];
  /**
   * error message. will show up when not empty
   */
  error: string;
  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): RMState {
  return {
    RefixMortgageList: [],
    error: '',
    isLoading: false
  };
}

/**
 * Refix mortgage widget data store
 */
@Injectable()
@StoreConfig({
  name: 'refixMortgage'
})
export class RMStore extends Store<RMState> {
  /**
   * Refix mortgage store
   */
  constructor() {
    super(createInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }))
  }
}
