import { Component, Input } from '@angular/core';
import { APTrackingCustomers } from '../state/apt.model';

@Component({
	selector: 'app-apt-table',
	templateUrl: './apt-table.component.html',
  styleUrls: ['./apt-table.component.scss']
})
export class AptTableComponent {
  @Input() customers: APTrackingCustomers[];
	@Input() link: string;

	constructor() {}

  /** ngFor optimization */
	public trackByFn(index: number, item: any) {
		return item.CustomerId;
	}

	formatStatusClock(days) {
		if (!days) {
			return '0 days';
		}

		if (days < 365) {
			return `${days} days`;
		}

		const years = (days / 365).toFixed(2);
		return `${years} ${years === '1.00' ? 'year' : 'years' }`;
	}
}
