import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CrtMortgageService } from '@modules/crm/crt-page/crt-mortgage/state/crt-mortgage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropertyService } from '../../../../../client-sop/assets-and-liabilities/state/property/property.service';
import { PeopleEntitiesService } from '../../../../../client-sop/people-entities/state/people-entities.service';
import { CrtMortgageQuery } from '../../../../../state/crt-mortgage.query';
import { PropertySoldFormComponent } from './property-sold-form/property-sold-form.component';
import { PropertySoldMapper } from './state/property-sold.mapper';
import { PropertySoldDetailsState } from './state/property-sold.model';
import { PropertySoldQuery } from './state/property-sold.query';
import { PropertySoldService } from './state/property-sold.service';

@Component({
  selector: 'app-property-sold',
  templateUrl: './property-sold.component.html',
})
export class PropertySoldComponent implements OnInit {

  @Input() parentCRTId: number;

  propertyOwners$ = this.peopleEntitiesService.policyOwnersWithCRT$;

  SU$ = this.service.SU$;
	SV1$ = this.service.SV1$;
	ST$ = this.service.ST$;
  STI$ = this.service.STI$;
	APCRTF$ = this.service.APCRTF$;
  adviceProcessId = this.moatQuery.getValue().adviceProcessId;
  isLoading$ = this.query.selectLoading();
	getOwnerChoices = this.moatService.getOwnerChoices;

  private onDestroy$ = new Subject<void>();

  @Input() properties: PropertySoldDetailsState[];
  propertyAddresses$ =  this.propertyService.properties$

  @ViewChild ('propertySoldForm') propertySoldForm: PropertySoldFormComponent;

  @Input() deceasedSciList: any[];

  constructor(
    private query: PropertySoldQuery,
    private service: PropertySoldService,
    private peopleEntitiesService: PeopleEntitiesService,
    private moatQuery: CrtMortgageQuery,
    private propertyService: PropertyService,
		private moatService: CrtMortgageService
  ) { }

  ngOnInit(): void {
    this.service.get(this.parentCRTId).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  add = (data: PropertySoldDetailsState) => {
    data.parentCRTId = this.parentCRTId;
    const processedData = PropertySoldMapper.mapToPropertyDetails(data);
    return this.service.add(processedData);
  }

  update = (data: PropertySoldDetailsState) => {
    data.parentCRTId = this.parentCRTId;
    const processedData = PropertySoldMapper.mapToPropertyDetails(data);
    return this.service.update(processedData);
  }

  delete = (data: number) => {
    return this.service.delete(data);
  }

  clear() {
    this.propertySoldForm?.clearAdd();
  }

}
