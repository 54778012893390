export const roaWordingsMetaKey = {
	// ROA Wordings
	raiseComplaint: 'MOAT_ROA_RAISE_COMPLAINT',
	thingsToConsider: 'MOAT_ROA_THINGS_TO_CONSIDER',
	nextStep: 'MOAT_ROA_NEXT_STEP',
};

export const lsSoaMetaKeys = {
	// Loan Structure
	lsRepaymentAmtFreq: 'LOAN_STRUCTURE_REPAYMENT_AMOUNT_FREQUENCY',
	mortgageProviderSection: 'SOA_MORTGAGE_PROVIDERS_SECTION',

	// Loan Structure > Loan Splits
	loanRepaymentAmount: 'LOAN_STRUCTURE_REPAYMENT_AMOUNT',
	loanRepaymentFrequency: 'LOAN_STRUCTURE_REPAYMENT_FREQUENCY',
	loanSplitIndex: 'LOAN_SPLIT_INDEX',

	// Mortgage Provider
	mortgageMaxUpfront: 'APPROVED_LENDER_MAX_UPFRONT',
	mortgageMaxRenewal: 'APPROVED_LENDER_MAX_RENEWAL',
	mortgageFee: 'APPROVED_LENDER_FEE',
};

export const appLoanSplitMetaKey = {
	loanType: 'LOAN_STRUCTURE_LOAN_TYPE',
	interestOnlyPeriod: 'LOAN_STRUCTURE_INTEREST_ONLY_PERIOD',
};

export const lsSoaMergeTag = [
	{
		metaKey: lsSoaMetaKeys.lsRepaymentAmtFreq,
		description:
			'Structure Statement of Advice > Loan Split Details > Repayment Amount, including frequency',
		value: [''],
		type: 'T',
		secondaryValue: `$123 per Monthly`,
	},
	{
		metaKey: lsSoaMetaKeys.loanSplitIndex,
		description:
			'Structure Statement of Advice > Loan Split Details > Loan Split Index',
		value: [''],
		type: 'T',
		secondaryValue: `Loan 1`,
	},
];

export const mortgageLenderMT = [
	{
		metaKey: lsSoaMetaKeys.mortgageMaxUpfront,
		description:
			'Provider & Commission > Mortgage > (Approved Lender) Max Upfront',
		value: [''],
		type: 'T',
		secondaryValue: `$123.00`,
	},
	{
		metaKey: lsSoaMetaKeys.mortgageMaxRenewal,
		description:
			'Provider & Commission > Mortgage > (Approved Lender) Max Renewal',
		value: [''],
		type: 'T',
		secondaryValue: `$123.00`,
	},
	{
		metaKey: lsSoaMetaKeys.mortgageFee,
		description: 'Provider & Commission > Mortgage > (Approved Lender) Fee',
		value: [''],
		type: 'T',
		secondaryValue: `$123.00`,
	},
];

export const mortgageLoanSplitMT = [
	{
		metaKey: appLoanSplitMetaKey.interestOnlyPeriod,
		description:
			'Record of Advice > Loan Split Details > Interest Only Period',
		value: [''],
		type: 'T',
		secondaryValue: `0`,
	},
];
