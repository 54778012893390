<form
	[formGroup]="form"
	class="form product-form"
	[class.submitted]="submitted"
	[class.design-v2]="isAiQuoteEnabled"
	[class.ai-quote-processing]="isAiQuoteProcessing"
>
	<ng-container *ngIf="isAiQuoteEnabled">
		<div class="d-flex form-top-row mb-2">
			<div class="drag-col invisible"></div>
			<div class="flex-fill">
				<div class="form-row">
					<div class="col-12 col-lg-4 d-flex">
						<label class="d-none d-lg-block pt-1 font-weight-bold lbl-policy-owner">
							Policy Owner
						</label>
						<app-chips
							textboxId="policyOwner"
							class="crt-form-control-field"
							textboxClass="theme-crm-field"
							formControlName="policyOwner"
							[choices]="policyOwnerChoices(form.get('policyOwner').value)"
							id="policyOwner"
							tabindex="8"
							[attr.disabled]="viewMode"
						>
						</app-chips>
					</div>
					<div class="col-12 col-lg-4 d-flex">
						<label class="d-none d-lg-block pt-1 font-weight-bold lbl-provider">
							Provider
						</label>
						<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
							<select
								id="provider"
								formControlName="provider"
								class="form-control theme-crm-field-disabled crt-form-control"
							>
								<option *ngFor="let d of provider" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
					<div class="col-12 col-lg-4 d-flex">
						<label class="d-none d-lg-block pt-1 font-weight-bold lbl-policy-number">
							Policy Number
						</label>
						<input
							class="form-control crt-form-control"
							id="policyNumber"
							type="text"
							placeholder="Policy Number"
							formControlName="policyNumber"
						/>
					</div>
				</div>
			</div>
			<div class="delete-col invisible"></div>
		</div>
	</ng-container>

	<ng-container *ngIf="isAPCAR">
		<div class="form-row mb-20">
			<div class="col-12 col-lg-6">
				<div class="form-row">
					<div class="col-12 text-left mb-10">
						<label class="d-none d-md-block pt-1 font-weight-bold">
							Policy Owner
						</label>
					</div>
					<div class="col-12">
						<app-chips
							textboxId="policyOwner"
							class="crt-form-control-field"
							textboxClass="theme-crm-field"
							formControlName="policyOwner"
							[choices]="policyOwnerChoices(form.get('policyOwner').value)"
							id="policyOwner"
							tabindex="8"
							[attr.disabled]="viewMode"
						>
						</app-chips>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="form-row">
					<div class="col-12 text-left mb-10">
						<label class="d-none d-md-block pt-1 font-weight-bold">
							Provider
						</label>
					</div>
					<div class="col-12">
						<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
							<select
								id="provider"
								formControlName="provider"
								class="form-control theme-crm-field-disabled crt-form-control"
							>
								<option *ngFor="let d of provider" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!isAiQuoteEnabled && !isAPCAR">
		<div class="d-flex">
			<div class="drag-col invisible"></div>
			<div class="flex-fill">
				<div class="form-row">
					<div class="col-12 col-lg-2 text-left mb-2">
						<label class="d-none d-md-block pt-1 font-weight-bold">
							Policy Owner
						</label>
					</div>
					<div class="col-12 col-lg-7">
						<div class="form-row">
							<div class="col-12 col-lg-6">
								<app-chips
									textboxId="policyOwner"
									class="crt-form-control-field"
									textboxClass="theme-crm-field"
									formControlName="policyOwner"
									[choices]="policyOwnerChoices(form.get('policyOwner').value)"
									id="policyOwner"
									tabindex="8"
									[attr.disabled]="viewMode"
								>
								</app-chips>
							</div>
							<div class="col-12 col-lg-3 offset-lg-3">
								<label class="d-none d-md-block pt-1 font-weight-bold">
									Provider
								</label>
							</div>
						</div>

					</div>
					<!--<div class="col-12 col-lg-2 mb-2">
						<label class="d-none d-md-block pt-1 font-weight-bold">
							Provider
						</label>
					</div>-->
					<div class="col-12 col-lg-3 mb-2">
						<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
							<select
								id="provider"
								formControlName="provider"
								class="form-control theme-crm-field-disabled crt-form-control"
							>
								<option *ngFor="let d of provider" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</div>
			<div class="delete-col invisible"></div>
		</div>
	</ng-container>

	<div class="d-flex">
		<div class="drag-col invisible"></div>
		<div class="flex-fill">
			<div class="form-row life-assured-list-container">
				<div class="d-none d-lg-block col-12 col-lg-2 text-left mb-2">
					<label class="pt-1 font-weight-bold"> Life Assured </label>
				</div>
				<div class="d-none d-md-block col-12 col-lg-7 text-left">
					<div class="form-row">
						<div class="d-none d-lg-block col-12 col-lg-3 mb-2">
							<label class="pt-1 font-weight-bold">
								Product
							</label>
						</div>
						<div class="d-none d-lg-block col-12 col-lg-3 mb-2">
							<label class="pt-1 font-weight-bold"
								>Benefit</label
							>
						</div>
						<div class="d-none d-lg-block col-12 col-lg-3 mb-2">
							<label class="pt-1 font-weight-bold"
								>Premium</label
							>
						</div>
						<div
							*ngIf="isProposedInsurance"
							class="d-none d-lg-block col-12 col-lg-3 mb-2"
						>
							<label class="pt-1 font-weight-bold"
								>New/Existing</label
							>
						</div>
						<div
							*ngIf="!isProposedInsurance"
							class="d-none d-lg-block col-12 col-lg-3 mb-2"
						>
							<label class="pt-1 font-weight-bold"
								>Start Date</label
							>
						</div>
					</div>
				</div>
				<div class="d-none d-lg-block col-12 col-lg-3 mb-2">
					<label class="pt-1 font-weight-bold"
						>Explanation/Notes</label
					>
				</div>
			</div>
		</div>
		<div class="delete-col invisible"></div>
	</div>

	<ng-template #deleteBlankslate>
		<button type="button" class="icon-btn p-0 cursor-normal" [disabled]="isAiQuoteProcessing"></button>
	</ng-template>
	<div formArrayName="lifeAssuredList">
		<div
			cdkDropList
			(cdkDropListDropped)="drop($event)"
			[cdkDropListData]="lifeAssuredList.controls"
		>
			<div
				class="d-flex"
				*ngFor="let item of lifeAssuredList.controls; let i=index"
				[formGroupName]="i"
				cdkDrag
				cdkDragLockAxis="y"
				[cdkDragData]="i"
				(cdkDragStarted)="dragStarted(item)"
				(cdkDragEnded)="dragEnd()"
				[cdkDragDisabled]="viewMode"
				#element="cdkDrag"
			>
				<div class="drag-col">
					<ng-container *ngIf="!viewMode">
						<button type="button" [disabled]="isAiQuoteProcessing" class="d-lg-block icon-btn p-0" cdkDragHandle>
							<i class="material-icons md-16 pr-0">drag_handle</i>
						</button>
					</ng-container>
				</div>
				<div class="flex-fill pl-2 pl-sm-0">
					<div class="form-row">
						<div class="col-12 col-lg-2 text-left mb-2">
							<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
								<select
									id="lifeAssured_{{ i }}"
									formControlName="lifeAssured"
									class="
										form-control
										theme-crm-field-disabled
										form-item__element--select
										crt-form-control
									"
									(change)="handleLifeAssuredChange(i)"
								>
									<option [hidden]="element.moved">
										{{ item.get('lifeAssuredDisplay')?.value }}
									</option>
									<option
										*ngFor="let d of lifeAssuredDropdownList"
										[value]="d.value"
										[hidden]="!!d.hidden && item.get('lifeAssured')?.value != d.value"
									>
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
						<div class="col-lg-7">
							<div class="form-row">
								<div class="col-12 col-lg-3 mb-2">
									<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
										<select
											id="product_{{ i }}"
											formControlName="product"
											class="
												form-control
												theme-crm-field-disabled
												form-item__element--select
												crt-form-control
											"
											(change)="changeDropdown(i)"
										>
											<option [hidden]="element.moved">
												{{ item.get('product')?.value }}
											</option>
											<option *ngFor="let d of product" [value]="d.value">
												{{ d.display }}
											</option>
										</select>
									</span>
								</div>
								<ng-container *ngIf="item.get('showDropdown').value">
									<div class="col-12 col-lg-3 mb-2">
										<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
											<select
												id="benefit_{{ i }}"
												formControlName="benefit"
												class="
													form-control
													theme-crm-field-disabled
													crt-form-control
												"
												placeholder="Benefit"
											>
												<option *ngFor="let d of benefit" [value]="d.value">
													{{ d.display }}
												</option>
											</select>
										</span>
									</div>
								</ng-container>
								<ng-container *ngIf="!(item.get('showDropdown').value)">
									<div class="col-12 col-lg-3 mb-2">
										<div [ngClass]="['dollar-icon', showMonth(item.get('product')?.value) ? 'benefit label-right' : '']">
											<i [class.ml-0]="viewMode">$</i>
											<input
												class="form-control crt-form-control"
												id="benefit_{{ i }}"
												type="text"
												placeholder="Benefit"
												formControlName="benefit"
												currencyMask
												appCurrencyMaskExt
											/>
											<i *ngIf="showMonth(item.get('product')?.value)" class="right month-suffix"> /month</i>
										</div>
									</div>
								</ng-container>
								<div class="col-12 col-lg-3 mb-2">
									<div class="dollar-icon">
										<input
											class="form-control crt-form-control"
											id="premium_{{ i }}"
											type="text"
											placeholder="Premium"
											formControlName="premium"
											autocomplete="off"
											currencyMask
											appCurrencyMaskExt
										/>
										<i *ngIf="item.get('premium')?.value !== 'N/A'">$</i>
									</div>
								</div>

								<ng-container *ngIf="isProposedInsurance">
									<div class="col-12 col-lg-3 mb-2">
										<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
											<select
												id="NewExisting_{{ i }}"
												formControlName="newExisting"
												class="
													form-control
													theme-crm-field-disabled
													crt-form-control
												"
												placeholder="New/Existing"
											>
												<option [hidden]="element.moved">
													{{ item.get('newExisting')?.value }}
												</option>
												<option value=""></option>
												<option
													*ngFor="let d of newExistingChoices"
													[value]="d.value"
												>
													{{d.display}}
												</option>
											</select>
										</span>
									</div>
								</ng-container>
								<ng-container *ngIf="!isProposedInsurance">
									<div class="col-12 col-lg-3 mb-2">
										<app-date-input
											class="crt-form-control-field"
											textboxId="startDate_{{ i }}"
											[placeholder]="isAPCAR ? 'DD/MM/YYYY' : 'dd/mm/yyyy'"
											formControlName="startDate"
											[attr.disabled]="viewMode"
										></app-date-input>
									</div>
								</ng-container>
							</div>
						</div>

						<div class="col-12 col-lg-3 mb-2 d-flex">
							<ng-container *ngIf="!!viewMode else editableNotes">
								<div class="form-row">
									<span [innerHTML]="item.get('notes').value || ''"></span>
								</div>
							</ng-container>
							<ng-template #editableNotes>
								<textarea
									class="form-control crt-form-control note-text-area"
									id="notes_{{ i }}"
									type="text"
									placeholder="Notes"
									formControlName="notes"
								>
								</textarea>
							</ng-template>
						</div>
					</div>
				</div>

				<div class="delete-col">
					<ng-container *ngIf="!viewMode">
						<button
							id="deleteProductButton_{{ i }}"
							type="button"
							class="d-lg-block icon-btn p-0"
							(click)="deleteProduct(i)"
							[disabled]="isAiQuoteProcessing"
						>
							<i class="material-icons pr-0"
								[ngClass]="isAPCAR ? 'md-20' : 'md-16'">
								{{ isAPCAR ? 'cancel' : 'delete' }}</i>
						</button>
					</ng-container>
				</div>
				<div *cdkDragPreview class="d-flex dragging">
					<div class="drag-col">
						<button type="button" [disabled]="isAiQuoteProcessing" class="d-lg-block icon-btn p-0" cdkDragHandle>
							<i class="material-icons md-16 pr-0">drag_handle</i>
						</button>
					</div>
					<div class="flex-fill pl-2 pl-sm-0">
						<div class="form-row">
							<div class="col-12 col-lg-2 text-left">
								<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
									<select
										id="lifeAssured_{{ i }}"
										formControlName="lifeAssured"
										class="
											form-control
											theme-crm-field-disabled
											form-item__element--select
											crt-form-control
										"
										(change)="handleLifeAssuredChange(i)"
									>
										<option [hidden]="element.moved">
											{{ item.get('lifeAssuredDisplay')?.value }}
										</option>
										<option
											*ngFor="let d of lifeAssuredDropdownList"
											[value]="d.value"
											[hidden]="!!d.hidden && item.get('lifeAssured')?.value != d.value"
										>
											{{ d.display }}
										</option>
									</select>
								</span>
							</div>
							<div class="col-lg-4">
								<div class="form-row">
									<div class="col-12 col-lg-4">
										<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
											<select
												id="product_{{ i }}"
												formControlName="product"
												class="
													form-control
													theme-crm-field-disabled
													form-item__element--select
													crt-form-control
												"
												(change)="changeDropdown(i)"
											>
												<option [hidden]="element.moved">
													{{ item.get('product')?.value }}
												</option>
												<option *ngFor="let d of product" [value]="d.value">
													{{ d.display }}
												</option>
											</select>
										</span>
									</div>
									<ng-container *ngIf="item.get('showDropdown').value">
										<div class="col-12 col-lg-4">
											<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
												<select
													id="benefit_{{ i }}"
													formControlName="benefit"
													class="
														form-control
														theme-crm-field-disabled
														crt-form-control
													"
													placeholder="Benefit"
												>
													<option [hidden]="element.moved">
														{{ item.get('benefit')?.value }}
													</option>
													<option *ngFor="let d of benefit" [value]="d.value">
														{{ d.display }}
													</option>
												</select>
											</span>
										</div>
									</ng-container>
									<ng-container *ngIf="!(item.get('showDropdown').value)">
										<div class="col-12 col-lg-4">
											<div class="dollar-icon">
												<input
													class="form-control crt-form-control"
													id="benefit_{{ i }}"
													type="text"
													placeholder="Benefit"
													formControlName="benefit"
													currencyMask
													appCurrencyMaskExt
												/>
												<i>$</i>
											</div>
										</div>
									</ng-container>
									<div class="col-12 col-lg-4">
										<div class="dollar-icon">
											<input
												class="form-control crt-form-control"
												id="premium_{{ i }}"
												type="text"
												placeholder="Premium"
												formControlName="premium"
												autocomplete="off"
												currencyMask
												appCurrencyMaskExt
											/>
											<i *ngIf="item.get('premium')?.value !== 'N/A'">$</i>
										</div>
									</div>
								</div>
							</div>

							<ng-container *ngIf="isProposedInsurance">
								<div class="col-12 col-lg-2">
									<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
										<select
											id="NewExisting_{{ i }}"
											formControlName="newExisting"
											class="
												form-control
												theme-crm-field-disabled
												crt-form-control
											"
											placeholder="New/Existing"
										>
											<option [hidden]="element.moved">
												{{ item.get('newExisting')?.value }}
											</option>
											<option value=""></option>
											<option
												*ngFor="let d of newExistingChoices"
												[value]="d.value"
											>
												{{d.display}}
											</option>
										</select>
									</span>
								</div>
							</ng-container>
							<ng-container *ngIf="!isProposedInsurance">
								<div class="col-12 col-lg-2">
									<app-date-input
										class="crt-form-control-field"
										texboxId="startDate"
										placeholder="dd/mm/yyyy"
										formControlName="startDate"
									></app-date-input>
								</div>
							</ng-container>
							<div class="col-12 col-lg-4 d-flex">
								<textarea
									class="form-control crt-form-control note-text-area"
									id="notes_{{ i }}"
									type="text"
									placeholder="Notes"
									formControlName="notes"
								>
								</textarea>
							</div>
						</div>
					</div>
					<div class="delete-col">
						<ng-container *ngIf="!viewMode">
							<button
								id="deleteProductButton_{{ i }}"
								type="button"
								class="d-lg-block icon-btn p-0"
								(click)="deleteProduct(i)"
								[disabled]="isAiQuoteProcessing"
							>
								<i class="material-icons pr-0"
									[ngClass]="isAPCAR ? 'md-20' : 'md-16'"
									>{{ isAPCAR ? 'cancel' : 'delete' }}</i>
							</button>
						</ng-container>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row" *ngIf="!!currentInsuranceLifeAssured && isAddProduct">
			<div class="drag-col invisible"></div>
			<div class="flex-fill">
				<div class="form-row">
					<div class="col-10 col-lg-3 mb-2">
						<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
							<select
								id="ddLifeAssured"
								placeholder="Current Insurance"
								class="form-control theme-crm-field-disabled crt-form-control"
								(change)="selectCurrentIsurance($event.target.value)"
							>
								<option value=""></option>
								<!-- Use index as unique identifier here -->
								<option
									*ngFor="let d of viewUnselectedLifeAssured(currentInsuranceLifeAssured); let i = index"
									[value]="d?.id+'-'+d?.lifeAssured+'-'+d?.product+'-'+d?.benefit"
								>
									{{ viewLifeAssured(d?.lifeAssured) }}-{{ d?.product }}-{{
									d?.benefit | currency }}
								</option>
								<option value="AddNew">Add new +</option>
							</select>
						</span>
					</div>
					<div class="col-auto mb-2">
						<button type="button" [disabled]="isAiQuoteProcessing" class="icon-btn" (click)="canelAddProduct()" id="cancelAddBtn">
							<i class="material-icons md-16 pr-0">close</i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" *ngIf="!isAPCAR">
			<div class="drag-col invisible"></div>
			<div class="form-row">
				<div class="col-12 text-left mb-2 pl-xs--2 pl-lg--0">
					<button
						id="addProductButton"
						*ngIf="((!!currentInsuranceLifeAssured && !isAddProduct) || !currentInsuranceLifeAssured) && !viewMode"
						type="button"
						class="
							tap-btn tap-btn-outline
							tap-btn--shadow
							px-4
							border-0
							col-auto
							mb-3
						"
						(click)="!!currentInsuranceLifeAssured ? addNew() : addProduct()"
						[disabled]="isAiQuoteProcessing"
					>
						Add Product +
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="d-flex">
		<div class="drag-col invisible"></div>
		<div class="flex-fill">
			<div class="form-row">
				<div class="col-12" [ngClass]="isAPCAR ? 'col-lg-2' : 'col-lg-2 mb-2'">
					<ng-container *ngIf="isAPCAR">
						<div class="drag-col invisible"></div>
						<div class="form-row">
							<div class="col-12 text-left mb-2 pl-xs--2 pl-lg--0">
								<button
									id="addProductButton"
									*ngIf="((!!currentInsuranceLifeAssured && !isAddProduct) || !currentInsuranceLifeAssured) && !viewMode"
									type="button"
									class="
										tap-btn tap-btn-outline
										tap-btn--shadow
										px-4
										border-0
										col-auto
									"

									(click)="!!currentInsuranceLifeAssured ? addNew() : addProduct()"
									[disabled]="isAiQuoteProcessing"
								>
									Add Product +
								</button>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="col-12 col-lg-7">
					<div class="form-row align-items-center">
						<div *ngIf="isAPCAR" class="col-lg-3"></div>
						<div class="text-lg-right mb-2" [ngClass]="isAPCAR ? 'col-12 col-lg-3' : 'col-6 col-lg-4 '">
							<label class="font-weight-bold">Policy Fee</label>
						</div>
						<div class="col-6 mb-2 col-lg-3">
							<div class="dollar-icon">
								<input
									class="form-control crt-form-control"
									id="policyFee"
									type="text"
									placeholder="Policy Fee"
									formControlName="policyFee"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
						<div class="col-lg-2"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="delete-col invisible"></div>
	</div>

	<div class="d-flex mb-3">
		<div class="drag-col invisible"></div>
		<div class="flex-fill">
			<div class="form-row">
				<div class="col-12 col-lg-2 mb-2"></div>
				<div class="col-12 text-right col-lg-7">
					<div class="form-row flex-row align-items-center">
						<div class="col-auto col-lg-6">
							<div class="form-row align-items-center justify-content-end">
								<div class="col-auto mb-2 text-right">
									<label class="font-weight-bold">{{ isAPCAR ? 'Total' : 'TOTAL'}}</label>
								</div>
								<ng-container *ngIf="!!defaultPaymentFrequency">
									<div class="col-auto col-lg-auto mb-2">
										<label class="font-weight-bold">
											{{ defaultPaymentFrequency }}
										</label>
									</div>
								</ng-container>
								<ng-container *ngIf="!defaultPaymentFrequency">
									<div class="col-auto col-lg-4 mb-2">
										<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
											<select
												id="premiumFrequency"
												formControlName="premiumFrequency"
												class="
													form-control
													theme-crm-field-disabled
													crt-form-control
												"
												placeholder="Premium Frequency"
											>
												<option
													*ngFor="let d of premiumFrequency"
													[value]="d.value"
												>
													{{ d.display }}
												</option>
											</select>
										</span>
									</div>
								</ng-container>
								<div class="col-auto mb-2">
									<label class="font-weight-bold">Premium:</label>
								</div>
							</div>

						</div>
						<div class="col-auto col-lg-3">
							<div class="form-row">
								<div class="col-auto mb-2">
									<div class="dollar-icon car-total-premium-container">
										<span *ngIf="isAPCAR" class="car-total-premium ml-1">{{ getSumOfLifeAssuredList() | currency: ' ' }}</span>
										<input
											*ngIf="!isAPCAR"
											readonly
											[value]="(getSumOfLifeAssuredList() || 0)"
											class="form-control crt-form-control car-total-premium"
											type="text"
											placeholder=""
											currencyMask
											appCurrencyMaskExt
										/>
										<i>$</i>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="delete-col invisible"></div>
	</div>

	<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
		<ng-container *ngIf="isAiQuoteEnabled; else defaultDocLayout">
			<div class="d-flex">
				<div class="drag-col invisible"></div>
				<div class="flex-fill">
					<div class="form-row pr-3">
						<div class="col-12 col-lg-6">
							<div
								class="d-flex add-file-wrapper p-2 justify-content-between flex-wrap"
							>
								<div class="d-flex pl-3 add-file-wrapper-label">
									<i class="fas fa-folder fa-18 tap-text-primary mr-2"></i> Add
									File
								</div>
								<div class="upload-btn d-flex justify-content-end">
									<div class="d-flex position-relative">
										<label
											for="file-upload"
											class="tap-btn custom-file-upload mr-1"
											[class.disabled]="isAiQuoteProcessing"
										>
											<i
												class="fas fa-upload fa-14 tap-text-color-h6 pt-1 mr-1"
											></i>
											{{!!isSingleUpload ? 'Upload Quote' : 'Upload File'}}
										</label>
										<input
											id="file-upload"
											type="file"
											[multiple]="!isSingleUpload"
											(change)="chooseFile($event)"
											[accept]="restrictFileTypes"
											[disabled]="isAiQuoteProcessing"
										/>
									</div>
									<div class="d-flex">
										<button
											id="linkDocumentButon"
											*ngIf="enableLinkDocument"
											type="button"
											class="tap-btn"
											(click)="linkDocument()"
											[disabled]="isAiQuoteProcessing"
										>
											<i
												class="fas fa-link fa-14 tap-text-color-h6 pt-1 mr-1"
											></i>
											Link File
										</button>
									</div>
								</div>
							</div>
							<div class="doc-list mt-1 existing-docs">
								<div
									class="d-flex mt-2 doc-list-row px-3 py-2"
									*ngFor="let d of currentInsuranceInfo?.policyDocuments; let i = index;"
								>
									<div
										class="d-flex align-items-center doc-list-filename w-50 word-break"
									>
										<span class="doc-list-filename-icon">
											<i class="fas fa-folder fa-18 mr-2"></i>
										</span>
										<span class="doc-list-filename-text"> {{ d.value }} </span>
									</div>
									<div
										class="doc-list-action-btns w-50 d-flex justify-content-end align-items-center"
									>
									<ng-container *ngIf="!viewMode && isAiQuoteEnabled">
										<app-simple-loader
											[isLoading]="d?.aiQuoteStatus === aiQuoteStatus.LOADING"
										></app-simple-loader>
										<button
											id="processAiQuoteExistingBtn_{{ i }}"
											type="button"
											class="tap-btn tap-bg-primary process-ai-btn mx-1 d-flex align-items-center"
											(click)="processAiQuote(d, i, true)"
											[class.py-2]="!d?.aiQuoteStatus || d?.aiQuoteStatus === aiQuoteStatus.LOADING"
											[class.ai-loading]="d?.aiQuoteStatus === aiQuoteStatus.LOADING"
											[class.ai-error]="[aiQuoteStatus.FAILED, aiQuoteStatus.ERROR_PROVIDER, aiQuoteStatus.ERROR_POLICY]?.includes(d?.aiQuoteStatus)"
											[class.ai-success]="d?.aiQuoteStatus === aiQuoteStatus.SUCCESS"
											[tooltip]="getTooltip(d?.aiQuoteStatus)"
											[disabled]="!!d?.aiQuoteStatus || (isAiQuoteProcessing && d?.aiQuoteStatus !== aiQuoteStatus.LOADING)"
											containerClass="dark-tooltip"
											placement="bottom"
											data-dd-action-name="adlib document process"
										>
											<i
												class="fas fa-check fa-14 mr-2"
												*ngIf="d?.aiQuoteStatus === aiQuoteStatus.SUCCESS"
											></i
											><i
												class="fas fa-bolt fa-14 mr-2"
												*ngIf="!d?.aiQuoteStatus"
											></i>
											{{getButtonLabel(d?.aiQuoteStatus)}}
										</button>
									</ng-container>
									<ng-container *ngIf="!viewMode">
										<ng-container
											*ngIf="d?.aiQuoteStatus === aiQuoteStatus.LOADING; else defaultButton"
										>
											<button
												id="cancelProcessExistingDocumentButton_{{ i }}"
												type="button"
												class="cancel-ai-process w-auto icon-btn mx-1"
												tooltip="Cancel AI Processing"
												containerClass="dark-tooltip"
												placement="bottom"
												(click)="cancelAiQuoteProcess()"
											>
												<i class="material-icons md-20">close</i>
											</button>
										</ng-container>
										<ng-template #defaultButton>
											<button
												id="removeExistingDocumentButton_{{ i }}"
												type="button"
												class="icon-btn mx-1"
												(click)="removeExistingDocument(i)"
												[disabled]="isAiQuoteProcessing"
											>
												<i class="fas fa-trash-alt fa-16"></i>
											</button>
										</ng-template>
									</ng-container>
									</div>
								</div>
								<div
									class="d-flex mt-2 doc-list-row px-3 py-2"
									*ngFor="let d of filenames; let i = index;"
								>
								<div
								class="d-flex align-items-center doc-list-filename w-50 word-break"
							>
								<span class="doc-list-filename-icon">
									<i class="fas fa-folder fa-18 mr-2"></i>
								</span>
								<span class="doc-list-filename-text"> {{ d?.value }} </span>
							</div>
							<div
								class="doc-list-action-btns w-50 d-flex justify-content-end align-items-center"
							>
							<ng-container *ngIf="!viewMode && isAiQuoteEnabled">
								<app-simple-loader
									[isLoading]="d?.aiQuoteStatus === aiQuoteStatus.LOADING"
								></app-simple-loader>
								<button
									id="processAiQuoteNewBtn_{{ i }}"
									type="button"
									class="tap-btn tap-bg-primary process-ai-btn mx-1 d-flex align-items-center"
									(click)="processAiQuote(d, i)"
									[class.py-2]="!d?.aiQuoteStatus || d?.aiQuoteStatus === aiQuoteStatus.LOADING"
									[class.ai-loading]="d?.aiQuoteStatus === aiQuoteStatus.LOADING"
									[class.ai-error]="[aiQuoteStatus.FAILED, aiQuoteStatus.ERROR_PROVIDER, aiQuoteStatus.ERROR_POLICY]?.includes(d?.aiQuoteStatus)"
									[class.ai-success]="d?.aiQuoteStatus === aiQuoteStatus.SUCCESS"
									[tooltip]="getTooltip(d?.aiQuoteStatus)"
									[disabled]="!!d?.aiQuoteStatus || (isAiQuoteProcessing && d?.aiQuoteStatus !== aiQuoteStatus.LOADING)"
									containerClass="dark-tooltip"
									placement="bottom"
									data-dd-action-name="adlib document process"
								>
									<i
										class="fas fa-check fa-14 mr-2"
										*ngIf="d?.aiQuoteStatus === aiQuoteStatus.SUCCESS"
									></i
									><i
										class="fas fa-bolt fa-14 mr-2"
										*ngIf="!d?.aiQuoteStatus"
									></i>
									{{getButtonLabel(d?.aiQuoteStatus)}}
								</button>
							</ng-container>
							<ng-container *ngIf="!viewMode">
								<ng-container
									*ngIf="d?.aiQuoteStatus === aiQuoteStatus.LOADING; else defaultButton"
								>
									<button
										id="cancelProcessNewDocumentButton_{{ i }}"
										type="button"
										class="cancel-ai-process w-auto icon-btn mx-1"
										tooltip="Cancel AI Processing"
										containerClass="dark-tooltip"
										placement="bottom"
										(click)="cancelAiQuoteProcess()"
									>
										<i class="material-icons md-20">close</i>
									</button>
								</ng-container>
								<ng-template #defaultButton>
									<button
										id="removeNewDocumentButton_{{ i }}"
										type="button"
										class="icon-btn mx-1"
										(click)="removeDocument(i)"
										[disabled]="isAiQuoteProcessing"
									>
										<i class="fas fa-trash-alt fa-16"></i>
									</button>
								</ng-template>
							</ng-container>
							</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6 pl-2 pl-lg-3">
							<div class="ai-tool-banner mt-2">
								<img src="./assets/images/new-ai-tool.jpg" class="w-100">
							</div>
							<div class="other-notes mt-2">
								<textarea
									class="form-control crt-form-control w-100"
									name="additionalNotes"
									id="additionalNotes"
									formControlName="additionalNotes"
									placeholder="Additional Notes"
									cols="30"
									rows="8"
									*ngIf="!hideNotes"
								></textarea>
							</div>
							<div
								class="acceptance-wrapper mt-2 p-3 d-flex align-items-center"
								[class.invalid]="submitted && !form.get('manualReview')?.value"
								*ngIf="isAiQuoteProcessing !== null"
							>
								<input
									type="checkbox"
									id="manualReview"
									class="ui-form-control mr-2"
									formControlName="manualReview"
									[class.disabled]="isAiQuoteProcessing"
									[class.invalid]="submitted && !form.get('manualReview')?.value && !isAiQuoteProcessing"
								/>
								<label class="font-weight-normal" for="manualReview"
									>I have manually reviewed the content & it is all correct.</label
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	
		<!-- Old Design -->
		<ng-template #defaultDocLayout>
			<div class="d-flex">
				<div class="drag-col invisible"></div>
				<div class="flex-fill">
					<div class="form-row">
						<div class="col-12 col-lg-6">
							<div
								*ngIf="isUploadDocumentButton(isSingleUpload, currentInsuranceInfo?.policyDocuments, filenames) && !viewMode"
								class="clearfix pb-3"
							>
								<div class="d-inline-block h-auto position-relative w-auto">
									<ng-container *ngIf="enableLinkDocument; else showDirectUpload">
										<button
											id="openUploadOptionModalButton"
											class="tap-btn tap-btn-outline tap-btn--shadow"
											(click)="openUploadOptionModal(uploadOptionModal)"
											[disabled]="isAiQuoteProcessing"
											[ngClass]="{'openUploadOptionModalButton-car' : isAPCAR}"
										>
											Upload Documents
										</button>
									</ng-container>
									<ng-template #showDirectUpload>
										<input
											name="file"
											type="file"
											id="file"
											class="p-0"
											[accept]="restrictFileTypes"
											[multiple]="!isSingleUpload"
											(change)="chooseFile($event)"
										/>
										<div
											id="btnChooseFileUpload"
											class="tap-btn tap-btn-outline tap-btn--shadow"
											[ngClass]="{'btnChooseFileUpload-car' : isAPCAR}"
										>
											{{!!isSingleUpload ? 'Upload Quote' : 'Upload Documents'}}
										</div>
									</ng-template>
								</div>
							</div>
							<div
								class="form-row flex-row align-items-center mb-2"
								*ngFor="let d of currentInsuranceInfo?.policyDocuments; let i = index;"
							>
								<div class="col-11">
									<div class="form-row flex-row">
										<div *ngIf="!isAPCAR" class="col-1">
											<i class="material-icons md-20 tap-text-color-h6"
												>description</i
											>
										</div>
										<div class="col-11" [ngClass]="{'pl-0' : !isAPCAR}">
											<div [ngClass]="{'d-flex align-items-center' : isAPCAR}">
												<div class="text-left word-break">
													<span [class.cursor-pointer]="!viewMode" target="_blank"
														>{{ d.value }}
													</span>
												</div>
												<i
													*ngIf="isAPCAR"
													id="removeExistingDocumentButton_{{ i }}"
													(click)="removeExistingDocument(i)"
													class="material-icons pr-0 md-20 ml-1">
													{{ isAPCAR ? 'cancel' : 'delete' }}
												</i>
											</div>
										</div>
									</div>
								</div>
								<div class="col-1 text-right word-break pr-0">
									<ng-container *ngIf="!viewMode && !isAPCAR">
										<button
											id="removeExistingDocumentButton_{{ i }}"
											type="button"
											class="icon-btn"
											(click)="removeExistingDocument(i)"
											[disabled]="isAiQuoteProcessing"
										>
											<i class="material-icons md-16 pr-0">
											{{ isAPCAR ? 'cancel' : 'delete' }} </i>
										</button>
									</ng-container>
								</div>
							</div>
							<div
								class="form-row flex-row align-items-center mb-2"
								*ngFor="let d of filenames; let i = index;"
							>
								<div class="col-11">
									<div class="form-row flex-row">
										<div *ngIf="!isAPCAR" class="col-1">
											<i class="material-icons md-20 tap-text-color-h6"
												>description</i
											>
										</div>
										<div class="col-11" [ngClass]="{'pl-0' : !isAPCAR}">
											<div class="text-left word-break">
												<div [ngClass]="{'d-flex align-items-center' : isAPCAR}">
													<span class="cursor-pointer" target="_blank">{{ d?.value }} </span>
														<i
															*ngIf="isAPCAR"
															id="removeDocumentButton_{{ i }}"
															(click)="removeDocument(i)"
															class="material-icons pr-0 md-20 ml-1">
															{{ isAPCAR ? 'cancel' : 'delete' }}
														</i>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-1 text-right word-break pr-0">
									<ng-container *ngIf="!viewMode && !isAPCAR">
										<button
											id="removeDocumentButton_{{ i }}"
											type="button"
											class="icon-btn"
											(click)="removeDocument(i)"
											[disabled]="isAiQuoteProcessing"
										>
											<i class="material-icons pr-0"
												[ngClass]="isAPCAR ? 'md-20' : 'md-16'">
												{{ isAPCAR ? 'cancel' : 'delete' }} </i>
										</button>
									</ng-container>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-2"></div>
						<div *ngIf="!isAPCAR" class="col-12 col-lg-4 mb-2 text-justify">
							<textarea
								class="form-control crt-form-control"
								name="additionalNotes"
								id="additionalNotes"
								formControlName="additionalNotes"
								placeholder="Additional Notes"
								cols="30"
								rows="8"
								*ngIf="!hideNotes"
							></textarea>
						</div>
					</div>
				</div>
				<div class="delete-col"></div>
			</div>
		</ng-template>
	</ng-container>
</form>
<div [ngClass]="isAPCAR ? 'form-buttons-car' : 'form-buttons'">
	<ng-container *ngIf="viewMode else upsertMode">
		<button
			id="closeButton"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancelClick()"
			[disabled]="isAiQuoteProcessing"
		>
			Close
		</button>
	</ng-container>
	<ng-template #upsertMode>
		<button
			id="saveButton"
			type="button"
			class="btn btn-primary tap-bg-primary text-light btn-block"
			[ngClass]="isAPCAR ? '' : 'mb-2'"
			[ngClass]="isAPCAR ? '' : 'mb-2'"
			(click)="saveClick()"
			[disabled]="isLoading || isAiQuoteProcessing"
		>
			Save
			<i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
		</button>
		<button
			id="cancelButton"
			type="button"
			class="btn-light mb-lg-0 btn-block"
			[ngClass]="isAPCAR ? 'mr-13' : 'mb-2'"
			(click)="cancelClick()"
			[disabled]="isAiQuoteProcessing"
		>
			Cancel
		</button>
	</ng-template>
</div>

<ng-template #uploadOptionModal>
	<button
		id="modalHideButton"
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="optionModalRef.hide()"
		[disabled]="isAiQuoteProcessing"
	>
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Attach Document</h4>
	</div>
	<div class="modal-body">
		<div class="clearfix py-3">
			<div class="d-flex justify-content-center">
				<div class="d-inline-block h-auto position-relative w-auto">
					<input
						name="file"
						type="file"
						id="file"
						class="p-0"
						[multiple]="!isSingleUpload"
						(change)="chooseFile($event)"
            [accept]="restrictFileTypes"
					/>
					<div class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-2">
						{{!!isSingleUpload ? 'Upload Quote' : 'Upload New'}}
					</div>
				</div>

				<button
					id="linkDocumentButon"
					*ngIf="enableLinkDocument"
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4"
					(click)="
            linkDocument();
            optionModalRef.hide()
          "
					[disabled]="isAiQuoteProcessing"
				>
					Link Document
				</button>
			</div>
		</div>
	</div>
</ng-template>
