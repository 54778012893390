import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/base/api.service';
import { Observable, delay, map, mergeMap, of, take, tap, zip } from 'rxjs';
import { ClientReferralSettingReferral } from './client-referral-setting.model';
import { ClientReferralSettingStore } from './client-referral-setting.store';
import { ClientReferralSettingQuery } from './client-referral-setting.query';
import { convertUtil, objectUtil, util } from '@util/util';
import { EmailTypes } from '@shared/models/emails/crt/email.model';
import { compose, groupBy, prop, sortBy } from 'ramda';
import { ClientReferralAttachments } from '@shared/client-referral-email-modal/client-referral-email-modal-form/client-referral-email-modal-form.component';

@Injectable({ providedIn: 'root' })
export class ClientReferralSettingService {
	settings$ = this.clientReferralSettingQuery.selectAll();

	constructor(
		private api: ApiService,
		private clientReferralSettingStore: ClientReferralSettingStore,
		private clientReferralSettingQuery: ClientReferralSettingQuery
	) {}

	getSetting(): Observable<ClientReferralSettingReferral[]> {
		const endpoint = `staff/0/settings/RO`;
		const get$ = this.api.get(endpoint).pipe(
			map((referrals: ClientReferralSettingReferral[]) =>
				referrals.map((r) => objectUtil.mapPascalCaseToCamelCase(r))
			),
			map((referrals) => referrals.sort((a, b) => a.priority - b.priority)),
			tap((referrals) => {
				this.clientReferralSettingStore.reset();
				this.clientReferralSettingStore.upsertMany(referrals);
				this.clientReferralSettingStore.setHasCache(true);
			})
		);
		return get$;
	}

	getBody(documentId: number): Observable<string> {
		const endpoint = `documents/${documentId}`;
		return this.api.get(endpoint).pipe(
			map((data) => objectUtil.mapPascalCaseToCamelCase(data)),
			mergeMap((data) => this.getContentByDocumentLink(data?.documentLink))
		);
	}

	private getContentByDocumentLink(documentLink: string): Observable<string> {
		return this.api.getExternalResource(documentLink, {
			responseType: 'text',
		});
	}

	private saveDocumentBody(name: string, content: string): Observable<number> {
		return convertUtil.convertToBase64(content).pipe(
			map((template) => {
				return {
					document: template,
					referenceId: 0,
					fileName: `${name}.txt`,
					type: EmailTypes.ClientReferral,
				};
			}),
			mergeMap((doc) => this.api.post3('documents', doc) as Observable<number>)
		);
	}

	private saveEmailBodys(
		referrals: ClientReferralSettingReferral[]
	): Observable<ClientReferralSettingReferral[]> {
		const obs = referrals.map((referral) => {
			// if referral has a bodyContent meaning body is modified
			// we need to save it first
			if (referral.bodyContent) {
				return this.saveDocumentBody(
					referral.referralService,
					referral.bodyContent
				).pipe(
					map((documentId) => {
						referral.body = documentId;
						return referral;
					})
				);
			}
			return of(referral);
		});
		return zip(obs);
	}

	saveAttachments(
		referrals: ClientReferralSettingReferral[]
	): Observable<ClientReferralSettingReferral[]> {
		const obs = referrals.map((referral) => {
			const attachmentsIds = new Set(util.tryParseJson(referral.attachment));
			if (!referral.attachmentFiles?.length) {
				return of(referral);
			}

			// group temporary uploaded document to
			// toAdd and toDelete
			const groupedDocuments = referral.attachmentFiles.reduce(
				(prev, cur) => {
					// @ts-ignore-next
					const documentId = cur.DocumentID ?? cur.documentId;
					if (documentId && cur.IsDeleted) {
						prev.toDelete.push(cur);
					} else if (!documentId) {
						prev.toAdd.push(cur);
					}
					return prev;
				},
				{
					toAdd: [] as ClientReferralAttachments[],
					toDelete: [] as ClientReferralAttachments[],
				}
			);

			const actionObservable: Observable<any>[] = [];

			groupedDocuments?.toAdd?.forEach((attachment: any) => {
				// if attachment is already saved
				if (attachment.documentId) {
					attachmentsIds.add(attachment.documentId.toString());
					return;
				}
				const addReq = this.api.post('documents', {
					Document: attachment.content,
					FileName: attachment.filename
				}).pipe(
					take(1),
					map((documentId) => {
						attachmentsIds.add(documentId.toString());
					})
				);
				actionObservable.push(addReq);
			});

			groupedDocuments?.toDelete?.forEach((attachment) => {
				const documentId = attachment.DocumentID ?? attachment.documentId;
				// @ts-ignore-next
				const endpoint = `documents/${documentId}`;
				const addReq = this.api.delete(endpoint).pipe(
					take(1),
					map(() => {
						// @ts-ignore-next
						attachmentsIds.delete(documentId.toString());
					})
				);
				actionObservable.push(addReq);
			});

			return of(0).pipe(
				mergeMap(() =>
					actionObservable?.length ? zip(actionObservable) : of([])
				),
				map(() => {
					referral.attachmentFiles = null;
					// referral.attachment = Array.from(attachmentsIds) as any;
					const documentIds = Array.from(attachmentsIds)
						?.filter((id) => +id > 0)
						?.map((id) => id.toString())
					referral.attachment = util.tryStringifyJson(documentIds);
					return referral;
				})
			);
		});
		return zip(obs);
	}

	bulkUpsert(
		referrals: ClientReferralSettingReferral[]
	): Observable<ClientReferralSettingReferral[]> {
		const endpoint = `staff/settings/referral-options-multiple`;
		const body = referrals.map((r) => objectUtil.mapCamelCaseToPascalCase(r));
		return this.api.put(endpoint, body).pipe(map(() => referrals));
	}

	upsert(
		referrals: ClientReferralSettingReferral[]
	): Observable<ClientReferralSettingReferral[]> {
		return this.saveEmailBodys(referrals).pipe(
			mergeMap((referrals) => this.saveAttachments(referrals)),
			mergeMap((referrals) => this.bulkUpsert(referrals)),
			mergeMap(() => this.getSetting())
		);
	}

	getAttachments(
		referral: ClientReferralSettingReferral
	): Observable<ClientReferralAttachments[]> {
		const ids = util.tryParseJson(referral.attachment) as number[];
		return zip(ids.map((id) => this.api.get(`documents/${id}`)));
	}
}
