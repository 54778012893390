import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
	AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import * as R from 'ramda';
import MomentUtil from 'src/app/util/moment.util';

@Component({
  selector: 'app-advice-process-step-wizard',
  templateUrl: './advice-process-step-wizard.component.html',
  styleUrls: ['./advice-process-step-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdviceProcessStepWizardComponent implements OnInit, OnChanges {
  @Input() steps: { stage: string; value: string; formControl: string }[];
  @Input() isEditable: boolean;
  @Input() startDate: string;
  @Input() crtLink: any;

  form: UntypedFormGroup = this.fb.group({});

  constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.steps && changes.steps.currentValue !== undefined) {
      this.form = this.fb.group(
        R.mergeAll(
          this.steps?.map((x) => ({
            [x.formControl]: MomentUtil.formatDateToMoment(x.value),
          }))
        )
      );
    }
    if (changes.isEditable && changes.isEditable.currentValue !== undefined) {
      changes.isEditable.currentValue
        ? this.form.enable()
        : this.form.disable();
    }
		this.disableStages();
  }

	disableStages() {
		(R.keys(this.form.controls) || [])?.forEach((x: string) => {
			if (!this.isEditable) {
				this.form?.get(x)?.disable();
			} else {
				this.form?.get(x)?.enable();
			}
		});
	}

  trackByFn(index: number, item: any) {
    return index;
  }

  isLessThanLasIndexWithValue(index: number) {
    const steps = R.map(
      MomentUtil.formatDateToServerDate,
      R.values(this.form.value)
    );
    const lastIndexWithValue = R.findLastIndex((x) => !!x, steps);
    return index < lastIndexWithValue;
  }

  isCompleteBtn(index: number) {
    const steps = R.map(
      MomentUtil.formatDateToServerDate,
      R.values(this.form.value)
    );
    const lastIndexWithValue = R.findLastIndex((x) => !!x, steps);
    return lastIndexWithValue + 1 > 0
      ? index > lastIndexWithValue &&
      lastIndexWithValue + 1 === index &&
      !steps[index]
      : 0 === index && !steps[index];
  }

  isInProgressBtn(i: number) {
    const lastIndexWithValue = R.findLastIndex((x) => x.value!=null, this.steps);
    return (lastIndexWithValue+1)===i;
	}

  complete(control: string, isCompleteBtn: boolean) {
    if (!isCompleteBtn || !this.isEditable) {
      return;
    }
    this.form.get(control).reset(MomentUtil.momentNowNz());
    this.steps = this.steps?.map((x) =>
      x.formControl === control
        ? {
          ...x,
          value: MomentUtil.formatDateToServerDate(MomentUtil.momentNowNz()),
        }
        : x
    );
  }

  daysDiff(index: number) {
    const steps = R.map(
      MomentUtil.formatDateToServerDate,
      R.values(this.form.value)
    );
    if (index === 0) {
      return R.isNil(steps[index])
        ? null
        : !!this.startDate
          ? MomentUtil.DateStringToMoment(steps[index]).diff(
            MomentUtil.DateStringToMoment(this.startDate),
            'days'
          )
          : 0;
    }
    if (R.isNil(steps[index])) {
      return null;
    }
    for (let i = index - 1; i >= 0; --i) {
      if (!R.isNil(steps[i])) {
        return MomentUtil.DateStringToMoment(steps[index]).diff(
          MomentUtil.DateStringToMoment(steps[i]),
          'days'
        );
      }
    }
    return 0;
  }

  getValue() {
    return this.form.value;
  }

  isValid() {
    const steps = R.map(
      MomentUtil.formatDateToServerDate,
      R.values(this.form.value)
    );
    const index = R.findLastIndex((x) => !!x, steps);
    if (index === -1) {
      return true;
    }
    return !steps.some((v, i) => i < index && R.isNil(v));
  }

  requiredStages() {
    const steps = R.map(
      MomentUtil.formatDateToServerDate,
      R.values(this.form.value)
    );
    const index = R.findLastIndex((x) => !!x, steps);
    if (index === -1) {
      return '';
    }
    const stages = [];
    steps?.forEach((v, i) => {
      if (i < index && R.isNil(v)) {
        stages.push(this.steps[i].stage);
      }
    });
    return `${stages?.join(', ')} ${stages.length > 1 ? 'dates are' : 'date is'
      } required.`;
  }

  clearValue(formControl) {
    const steps = R.map(
      MomentUtil.formatDateToServerDate,
      R.values(this.form.value)
    );
    const lastIndexWithValue = R.findLastIndex((x) => !!x, steps);
    const indexToBeCleard = R.findIndex(R.propEq('formControl', formControl))(
      this.steps
    );

    if (indexToBeCleard === lastIndexWithValue) {
      this.steps = this.steps?.map((x) =>
        x.formControl === formControl ? { ...x, value: null } : x
      );
    }
    this.form.get(formControl).setValue(MomentUtil.formatDateToMoment(''));
    this.cd.detectChanges();
  }

	updateStepDate(index: number, formControl: AbstractControl) {
		this.steps = [...this.steps]?.reduce((a, c, i) => {
			if (i === index) {
				const value = !!formControl?.value
					? MomentUtil.formatDateToServerDate(formControl?.value)
					: null;
				return [...a, { ...c, value }];
			}
			return [...a, c];
		}, []);
	}

  routeTo(field, value) {
    if (!value) { return; }

    switch (field) {
      case 'Fact Find':
        return this.crtLink;
      default:
        break;
    }
  }
}
