import { Pipe, PipeTransform } from '@angular/core';
import { util } from '../../../core/util/util.service';
import * as moment from 'moment';

@Pipe({
  name: 'momentDatetime',
})
export class MomentDatetimePipe implements PipeTransform {
  transform(value: string);
  transform(value: string, isDateTime: boolean);
  transform(value: string, isDateTime: true, time: string);
  transform(value: string, isDateTime?: boolean, time?: string): any {
    try {
      if (isDateTime) {
        if (time) {
          return moment(value).isAfter(moment('0001-01-01'))
            ? util.DatetimeStringToDatetimeStringDisplay(value, time)
            : '';
        }
        return moment(value).isAfter(moment('0001-01-01')) ? util.DatetimeStringToDatetimeStringDisplay(value) : '';
      } else {
        return moment(value).isAfter(moment('0001-01-01')) ? util.DateStringToDateStringDisplay(value) : '';
      }
    } catch (error) {
      return '';
    }
  }
}
