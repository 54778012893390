<form [formGroup]="form" class="form" class="mb-3">
  <div formArrayName="properties">
    <ng-container *ngFor="let property of PropertiesList.controls; let i=index;" [formGroupName]="i">
      <div class="row pb-0 pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center">
            <div class="col-12 col-lg-4 text-left mb-2">
              <label for="" class="position-relative font-weight-bold">
                Property Address
              </label>
            </div>
            <div class="col-12 col-lg-8 mb-2 pr-md-0">
              <app-address-input
                class="crt-form-control-field disabled"
                formControlName="propertyAddress"
                textboxClass="theme-crm-field-disabled"
                tabindex="19"
              >
              </app-address-input>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-4">
          <div class="form-row align-items-center">
            <div class="col-4 col-lg-5 text-left mb-2">
              <label for="" class="position-relative font-weight-bold">
                Purchase Price
              </label>
            </div>
            <div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
              <div class="dollar-icon">
                <input
                  id="propertyValue"
                  formControlName="propertyValue"
                  class="form-control crt-form-control"
                  type="text"
                  currencyMask
                  appCurrencyMaskExt
                  [class.disabled]="property.get('cRTId').value"
                />
                <i>$</i>
              </div>
            </div>
          </div>
          
        </div>
        <div class="col-12 col-lg-2">
          <!-- EDIT MODE -->
          <div 
            *ngIf="(!property.get('cRTId').value || property.get('isEdit').value) && !property.get('isLoading').value"
            class="form-row align-items-center"
          >
            <div class="col mt-1">
              <button
                (click)="save(i)"
                type="button"
                [disabled]="property.get('isLoading').value"
                class="icon-btn w-auto h-auto px-1"
                [class.disabled]="property.get('isLoading').value"
              >
                <i class="material-icons md-16 tap-text-primary">save</i>
              </button>
              <button
                (click)="remove(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
                [disabled]="property.get('isLoading').value"
                [class.disabled]="property.get('isLoading').value"
              >
                <i class="material-icons md-16">close</i>
              </button>
            </div>
          </div>

          <ng-container *ngIf="property.get('isLoading').value">
            <div class="form-row mt-1">
              <app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
            </div>
          </ng-container>

          <!-- DISPLAY MODE -->
          <div
            *ngIf="!property.get('isEdit').value && property.get('cRTId').value && !property.get('isLoading').value"
            class="form-row align-items-center"
          >
            <div class="col mt-1">
              <button
                (click)="edit(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
              >
                <i class="material-icons md-16">edit</i>
              </button>
              <button
                (click)="confirmDelete(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
              >
                <i class="material-icons md-16">delete</i>
              </button>
            </div>
            
          </div>
          
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="form-row align-items-center">
        <div class="col" *ngIf="!isAdd">
          <button
            (click)="add()"
            type="button"
            class="tap-btn tap-btn-outline tap-btn--shadow px-4 ml-1"
          >
            Add Property +
          </button>
        </div>

        <ng-container *ngIf="!!isAdd">
          <div class="col col-lg-6">
            <span class="w-100" [class.crt-customer-dropdown]="form.enable">
              <select
                formControlName="propertyPurchaseValue"
                id="propertyPurchaseValue"
                class="form-control crt-form-control theme-crm-field-disabled mortgageMortgageLenderAdd"
                (change)="handleAddNew($event.target.value)"
              >
                <option *ngFor="let pp of newPotentialPurchases; let i = index" [value]="i">
                  {{ pp.PropertyAddress }}
                </option>
                <option value="new">+ Add New Property</option>
              </select>
            </span>
          </div>
          <div class="col col-lg-1">
            <button
              id="properyPurchaseCancelButton"
              (click)="cancel()"
              type="button"
              class="icon-btn w-auto h-auto px-1"
            >
              <i class="material-icons md-16">close</i>
            </button>
          </div>
        </ng-container>
      </div>
		</div>
    <div class="col-12 col-lg-4">
      <div class="form-row align-items-center">
        <div class="col-4 col-lg-5 text-left mb-2">
          <label for="" class="position-relative font-weight-bold tap-text-primary">
            Total Purchase Price
          </label>
        </div>
        <div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
          <div class="dollar-icon">
            {{ totalPropertyValue | currency: "USD":"$":"1.2-2" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  
</form>