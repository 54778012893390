export interface BusinessEmailSettingsModel {
    EmailSettingsId: number;
    EmailType: string;
    EmailCode: string;
    Recipient: string;
    FirstName: string;
    LastName: string;
    AdviserName: string;
    SignOffName: number;
    ContentMergeTag: string;
}

export class DocumentFileClass {
    FileName: string;
    Document: string;
    CustomerId: 0;
    DocumentType: null;
  }
