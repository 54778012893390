<div class="people-tabs d-flex flex-wrap align-items-center">
  <div
    class="illness__header__list__nav mb-0 px-3 py-2 btn-tabs mb-1 mr-1"
    *ngFor="let item of people$"
    (click)="clickPeople(item.cRTId)"
    [class.btn-tabs--isactive]="+selectedCrt === +item.cRTId"
  >
    {{ item.name }}
  </div>
</div>
