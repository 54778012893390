import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AdviceSummary, AdviceSummaryStore } from './advice-summary.store';

@Injectable({ providedIn: 'root' })
export class AdviceSummaryQuery extends Query<AdviceSummary> {
	constructor(protected store: AdviceSummaryStore) {
		super(store);
	}

	adviceSummary$ = this.select((state) => state);

}


