<div class="d-flex align-items-center">
	<input
		#date
		readonly
		[matDatepicker]="picker"
		[min]="min"
		[max]="max"
		type="text"
		[class]="'form-control ' + inputClass"
		[placeholder]="placeholder"
		[disabled]="disabled"
		id="{{ textboxId }}"
		[(ngModel)]="datafacade"
		(dateInput)="changeDate($event)"
		(dateChange)="changeDate($event)"
		[tabindex]="tabindex"
		(click)="picker.open()"
	/>
	<button
		*ngIf="showButton"
		(click)="picker.open()"
		class="btn btn-outline-secondary border-0 p-0 pl-1 mr-1"
		type="button"
		[disabled]="disabled"
	>
		<i class="material-icons align-middle py-1 px-0">date_range</i>
	</button>
	<mat-datepicker #picker></mat-datepicker>
</div>
