<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.Income"></app-header>
</div>

<!-- Content -->
<div role="main-content" class="main-income-budgets">
	<!-- Income Source -->
	<app-crt-income-source
		[APCRTF$]="APCRTF$"
		[APCRTKC$]="APCRTKC$"
		[APCRTTC$]="APCRTTC$"
		[APCRTI$]="APCRTI$"
		[APCRTIST$]="APCRTIST$"
		[APCRTIT$]="APCRTIT$"
		[APCRTTM$]="APCRTTM$"
		[APCRTYNNA$]="APCRTYNNA$"
		[PCE$]="PCE$"
		[incomeSource$]="incomeSource$"
		[primaryClient$]="primaryClient$"
		[secondaryClients$]="secondaryClients$"
		[people$]="people$"
		[addNewFn$]="addIncomeSource$"
		[updateFn$]="updateIncomeSource$"
		[deleteFn$]="deleteEmployment$"
		[factFindComputation$]="factFindComputation$"
		[peopleFromCRMAndCRTExceptChild$]="peopleFromCRMAndCRTExceptChild$"
		[transferedSCIList$]="transferedSCIList$"
		[allIncomeList$]="allIncomeList$"
		[showTimeInJob]="true"
		[deceasedSciList$]="deceasedSciList$"
		[getOwnerChoices]="getOwnerChoices"
	></app-crt-income-source>

	<!-- Previous Income Source -->
	<app-crt-previous-income-source
		[incomeSource$]="previousIncomeSource$"
		[people$]="people$"
		[addNewFn$]="addPreviousIncomeSource$"
		[updateFn$]="updatePreviousIncomeSource$"
		[deleteFn$]="deletePreviousEmployment$"
		[peopleFromCRMAndCRTExceptChild$]="peopleFromCRMAndCRTExceptChild$"
		[transferedSCIList$]="transferedSCIList$"
		[adviceProcessId]="adviceProcessId"
		[deceasedSciList$]="deceasedSciList$"
		[getOwnerChoices]="getOwnerChoices"
	></app-crt-previous-income-source>

	<!-- Net Rental Income -->
	<app-crt-rental-income
		[netRentalIncome$]="netRentalIncome$"
		[properties$]="properties$"
		[addNewFn$]="addNewRental$"
		[updateFn$]="updateRental$"
		[deleteFn$]="deleteRental$"
		[factFindComputation$]="factFindComputation$"
		[incomeSource$]="incomeSource$"
		[allIncomeList$]="allIncomeList$"
	></app-crt-rental-income>

	<!-- Other Income -->
	<app-crt-other-income
		[otherIncome$]="otherIncome$"
		[addNewFn$]="addNewOther$"
		[updateFn$]="updateOther$"
		[deleteFn$]="deleteOther$"
		[factFindComputation$]="factFindComputation$"
		[incomeSource$]="incomeSource$"
		[allIncomeList$]="allIncomeList$"
	></app-crt-other-income>

	<div class="form-row mb-2 mt-3">
		<div class="col-9 col-lg-9 text-right pt-1">
			<label class="font-weight-bold tap-text-primary">
				Annual Taxable Joint Income
			</label>
		</div>
		<div class="col-3 col-lg-3 pt-1">
			<label>
				{{ annualTaxableJointIncome | currency }}
			</label>
		</div>
	</div>
	<div class="form-row mb-2">
		<div class="col-9 col-lg-9 text-right pt-1">
			<label class="font-weight-bold tap-text-primary">
				Monthly Taxable Joint Income
			</label>
		</div>
		<div class="col-3 col-lg-3 pt-1">
			<label>
				{{ monthlyTaxableJointIncome | currency }}
			</label>
		</div>
	</div>
	<div class="form-row mb-3">
		<div class="col-9 col-lg-9 text-right pt-1">
			<label class="font-weight-bold tap-text-primary">
				Monthly After Tax Income
			</label>
		</div>
		<div class="col-3 col-lg-3 pt-1">
			<label>
				{{ monthlyAfterTaxIncome | currency }}
			</label>
		</div>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer [currentPageId]="pageIds.Income" [sidebar]="sidebar"></app-footer>
</div>
