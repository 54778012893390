import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ComponentBase } from '@core/base/component-base';

@Component({
  selector: 'app-loader-mini2',
  templateUrl: './loader-mini.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderMiniComponent
  extends ComponentBase
  implements OnInit, OnDestroy
{
  // get styleHeight() {
  //   return typeof this.height === 'string' ? this.height : `${this.height}px`;
  // }

  // get styleWidth() {
  //   return typeof this.width === 'string' ? this.width : `${this.width}px`;
  // }

  @Input() text?: string;

  @Input() height?: number | string = 25;

  @Input() width?: number | string = 25;

  @Input() isLoading = false;

  @Input() load$?: Observable<any>;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.load$) {
      this.isLoading = true;
      const obs = this.load$.pipe(finalize(() => {
				this.isLoading = false;
				this.cdr.detectChanges();
			}));
      super.subscribe(obs);
    }
  }

  ngOnDestroy(): void {
    super.dispose();
  }
}
