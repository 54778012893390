<div class="notes" *ngIf="!isLoading; else showLoader">
	<form action="" class="form">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-12 pb-2">
						<span class="title">Purpose</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
						#purposeEditor
						[simpleEditor]="true"
						[content]="Purpose.value"
						[extendOptions]="optionsWysiswyg"
            [showToolbar]="false"
					></app-wysiwyg>
					</div>
				</div>
				<div class="row">
					<div class="col-12 py-2">
						<span class="title">Client Background & Character</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
						#clientBackgroundEditor
						[simpleEditor]="true"
						[content]="ClientBackgroundCharacter.value"
						[extendOptions]="optionsWysiswyg"
            [showToolbar]="false"
					></app-wysiwyg>
					</div>
				</div>
				<div class="row">
					<div class="col-12 py-2">
						<span class="title">Recommendation</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
						#recommendationEditor
						[simpleEditor]="true"
						[content]="Recommendation.value"
						[extendOptions]="optionsWysiswyg"
            [showToolbar]="false"
					></app-wysiwyg>
					</div>
				</div>
				<div class="row">
					<div class="col-12 py-2">
						<span class="title">Other Information</span>
					</div>
					<div class="col-12 notes__textbox">
						<app-wysiwyg
						#otherInformationEditor
						[simpleEditor]="true"
						[content]="OtherInformation.value"
						[extendOptions]="optionsWysiswyg"
            [showToolbar]="false"
					></app-wysiwyg>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<ng-template #showLoader>
	<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
</ng-template>