import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { find, propEq, complement, either, isNil, isEmpty } from 'ramda';
import { combineLatest, Observable, Observer, of, Subject, Subscription } from 'rxjs';
import {
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { LoggerService } from '../../../../../core/logger/logger.service';
import {
	computeAnnualTaxable,
	computeMonthlyAfterTaxIncome,
	computeMonthlyTaxable,
} from '../../../../../modules/crm/client-review-template/income-budget/calculations/compute-taxable';
import { IncomeService } from '../../../../../modules/crm/client-review-template/states/income-budget/income.service';
import { IncomeBudgetModalComponent } from '../../../../../shared/modal/crt/fact-find/income-budget/income-budget-modal.component';
import { PrimaryClientState } from '../../../../../shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from '../../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { FactFindComputationState } from '../../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import { IncomeSourceState } from '../../../../../shared/models/client-review-template/income-budget/income-source-details.model';
import { IncomeSourceMapper } from '../../../../../shared/models/client-review-template/income-budget/income-source.mapper';
import { PeopleDetailsState } from '../../../../../shared/models/client-review-template/people/people-details.model';
import { PeopleState } from '../../../../../shared/models/client-review-template/people/people.model';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { numUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';

declare var $: any;

@Component({
	selector: 'app-income-source',
	templateUrl: './income-source.component.html',
	styleUrls: ['./income-source.component.scss'],
})
export class IncomeSourceComponent implements OnInit, OnDestroy {
	@Input() deleteFn$: (id: number) => Observable<any>;

	@Input() isAdviceProcessEnded: boolean;

	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() APCRTKC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTTC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTI$: Observable<ViewDisplayValue[]>;
	@Input() APCRTIST$: Observable<ViewDisplayValue[]>;
	@Input() APCRTIT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTTM$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() PCE$: Observable<ViewDisplayValue[]>;
	@Input() primaryClient$: Observable<PrimaryClientState>;
	@Input() secondaryClients$: Observable<SecondaryClientState[]>;
	@Input() incomeSource$: Observable<IncomeSourceState[]>;
	@Input() people$: Observable<PeopleDetailsState[]>;
	@Input() factFindComputation$: Observable<FactFindComputationState>;
	@Input() peopleFromCRMAndCRTExceptChild$: Observable<any[]>;

	onDestroy$ = new Subject<void>();

	elseMinusIncome = true;
	overallGross = 0;
	incomeList: IncomeSourceState[];
	factFind: FactFindComputationState = {};
	peopleList: ViewDisplayValue[];

	isListLoading = true;
	transferedSCI: PeopleState[];
	incomeSources$ = this.query.incomeSource$;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private incomeService: IncomeService,
		private route: ActivatedRoute,
		private query: ClientReviewTemplateQuery,
		private service: ClientReviewTemplateService,
		private loggerService: LoggerService,
		private cdr: ChangeDetectorRef,
	) {
		this.buildForm();
	}

	get IncomeArray() {
		return this.form.get('incomeArray') as UntypedFormArray;
	}

	ngOnInit(): void {
		let sub$: Subscription;
		this.getPeopleList()
			.subscribe(() => {
				if (sub$) {
					sub$.unsubscribe();
					sub$ = null;
				}
				this.buildForm();
				sub$ = this.prepData();
			});

		this.factFindComputation$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.overallGross = +data?.overallTotalGrossIncome;
				this.factFind = data;
			});

		this.incomeSource$
			.pipe(
				filter((data) => !!data),
				takeUntil(this.onDestroy$)
			)
			.subscribe((data) => (this.incomeList = data));

			this.query.transferedSCIList$
			.pipe(
				filter((data) => !!data),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => (this.transferedSCI = x));
	}

	prepData() {
		return this.incomeSource$
			.pipe(
				filter((data) => !!data && !!this.peopleList),
				map((data) =>
					data?.map((item) => IncomeSourceMapper.mapIncomeToView(item))
				),
				map((data) =>
					data?.map((item) => ({
						...item,
						incomeEarner: this.getIncomeEarnerName(+item.incomeEarner),
					}))
				),
				finalize(() => {
					this.isListLoading = false;
					this.cdr.detectChanges();
				}),
				take(1)
			)
			.subscribe((data) => {
				this.form.reset();
				data?.map((x) => this.addIncome(x));
				this.disableAll();
			});
	}

	buildForm() {
		this.form = this.fb.group({
			incomeArray: this.fb.array([]),
		});
	}

	addIncome(data?: IncomeSourceState) {
		this.IncomeArray?.push(
			this.fb.group({
				cRTId: [data?.cRTId || null],
				incomeEarner: [data?.incomeEarner || ''],
				employment: [data?.employment || ''],
				occupation: [data?.occupation || ''],
				totalGrossIncome: [data?.totalGrossIncome || ''],
				isLoading: [false],
			})
		);
	}

	getIncomeEarnerName(id: string | number) {
		let getName = '';
		if (this.peopleList) {
			const nameDisplay = find(propEq('value', id))(
				this.peopleList
			) as ViewDisplayValue;
			getName = nameDisplay?.display;
		}
		return getName;
	}

	getPeopleList() {
		return combineLatest([
			this.peopleFromCRMAndCRTExceptChild$,
			this.query.allSecondaryClients$,
			this.people$,
		])
			.pipe(
				map(([crmPeople, crmALlSCI, crtPeople]) => [
					...IncomeSourceMapper.mapCRMPeopleToDd([
						...(crmPeople || []),
						...(crmALlSCI || [])?.filter((x) => x.isActive === 3),
					]),
					...IncomeSourceMapper.mapCRTPeopleToDd(crtPeople),
				]),
				tap((res) => {
					this.peopleList = res;
				}),
				takeUntil(this.onDestroy$)
			)
	}

	addEmployment() {
		this.isListLoading = true;
		const confirm = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const decline = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const initState = {
			header: 'Employment Details',
			message: `EmploymentDetails`,
			confirm$: confirm,
			decline$: decline,
			apcrtf$: this.APCRTF$,
			apcrtkc$: this.APCRTKC$,
			apcrttc$: this.APCRTTC$,
			apcrti$: this.APCRTI$,
			apcrtist$: this.APCRTIST$,
			apcrtit$: this.APCRTIT$,
			apcrttm$: this.APCRTTM$,
			apcrtyn$: this.APCRTYN$,
			apcrtynna$: this.APCRTYNNA$,
			pce$: this.PCE$,
			people$: this.people$,
			primaryClient$: this.primaryClient$,
			secondaryClients$: this.secondaryClients$,
			factFindComputation$: this.factFindComputation$,
			transferedSCI: this.transferedSCI,
			incomeSources$: this.incomeSources$,
			peopleFromCRMAndCRTExceptChild$: this.peopleFromCRMAndCRTExceptChild$,
			getOwnerChoices: this.service.getOwnerChoices,
			savefn: this.addIncomeSource,
			cancelAddUpdate: this.doneLoading,
			isAdd: true,
			adviceProcessId: +this.route.snapshot.paramMap.get('adviceProcessId'),
		};
		this.bsModalRef = this.modalService.show(IncomeBudgetModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	editEmployment(id: number, index: number) {
		this.IncomeArray.controls[index].get('isLoading').setValue(true);
		const confirm = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const decline = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const initState = {
			header: 'Employment Details',
			message: `EmploymentDetails`,
			confirm$: confirm,
			decline$: decline,
			apcrtf$: this.APCRTF$,
			apcrtkc$: this.APCRTKC$,
			apcrttc$: this.APCRTTC$,
			apcrti$: this.APCRTI$,
			apcrtist$: this.APCRTIST$,
			apcrtit$: this.APCRTIT$,
			apcrttm$: this.APCRTTM$,
			apcrtynna$: this.APCRTYNNA$,
			pce$: this.PCE$,
			people$: this.people$,
			primaryClient$: this.primaryClient$,
			secondaryClients$: this.secondaryClients$,
			factFindComputation$: this.factFindComputation$,
			transferedSCI: this.transferedSCI,
			incomeSources$: this.incomeSources$,
			peopleFromCRMAndCRTExceptChild$: this.peopleFromCRMAndCRTExceptChild$,
			getOwnerChoices: this.service.getOwnerChoices,
			savefn: this.updateIncomeSource,
			isAdd: false,
			cRTId: +id,
			index: +index,
			cancelAddUpdate: this.doneLoading,
			adviceProcessId: +this.route.snapshot.paramMap.get('adviceProcessId'),
		};
		this.bsModalRef = this.modalService.show(IncomeBudgetModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	viewEmployment(id: number, index: number) {
		this.IncomeArray.controls[index].get('isLoading').setValue(true);
		const confirm = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const decline = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const initState = {
			header: 'Employment Details',
			message: `EmploymentDetails`,
			confirm$: confirm,
			decline$: decline,
			apcrtf$: this.APCRTF$,
			apcrtkc$: this.APCRTKC$,
			apcrttc$: this.APCRTTC$,
			apcrti$: this.APCRTI$,
			apcrtist$: this.APCRTIST$,
			apcrtit$: this.APCRTIT$,
			apcrttm$: this.APCRTTM$,
			apcrtynna$: this.APCRTYNNA$,
			pce$: this.PCE$,
			people$: this.people$,
			primaryClient$: this.primaryClient$,
			secondaryClients$: this.secondaryClients$,
			factFindComputation$: this.factFindComputation$,
			transferedSCI: this.transferedSCI,
			incomeSources$: this.incomeSources$,
			peopleFromCRMAndCRTExceptChild$: this.peopleFromCRMAndCRTExceptChild$,
			getOwnerChoices: this.service.getOwnerChoices,
			isAdd: false,
			cRTId: +id,
			index: +index,
			viewMode: true,
			cancelAddUpdate: this.doneLoading,
			adviceProcessId: +this.route.snapshot.paramMap.get('adviceProcessId'),
		};
		this.bsModalRef = this.modalService.show(IncomeBudgetModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteEmployment(id: number, index: number) {
		const data = this.incomeList?.find((x) => +x?.cRTId === +id);

		this.IncomeArray.controls[index].get('isLoading').setValue(true);
		this.deleteFn$(id)
			.pipe(
				finalize(() => {
					if (data?.incomeEarner && this.isSciInvalid(+data?.incomeEarner)) {
						this.invalidSciWarning();
					}
					this.IncomeArray.removeAt(index);
				})
			)
			.subscribe();
	}

	isSciInvalid(id: number) {
		const sci = this.transferedSCI?.filter((x) => +x?.customerId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	invalidSciWarning() {
		this.loggerService.Warning({}, logMessage.oat.shared.warning.invalidSci);
	}

	addIncomeSource = (model: IncomeSourceState) =>
		new Observable<IncomeSourceState>((obs) => {
			const tempFactFind = {
				...this.factFind,
				...this.recompute(model, this.factFind),
			};
			obs.next(IncomeSourceMapper.mapTempFactFind(model, tempFactFind));
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				this.incomeService
					.addIncomeSource(
						x,
						parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10)
					)
					.pipe(mergeMap((res) => this.addNewIncome(+res, x)))
			),
			finalize(() => {
				if (model?.incomeEarner && this.isSciInvalid(+model?.incomeEarner)) {
					this.invalidSciWarning();
				}
				this.isListLoading = false;
			})
		);

	addNewIncome = (cRTId: number, data: IncomeSourceState) =>
		of(data).pipe(
			map((mapFirstData) => IncomeSourceMapper.mapIncomeToView(mapFirstData)),
			map((mapSecondData) => ({
				...mapSecondData,
				cRTId: +cRTId,
				incomeEarner: this.getIncomeEarnerName(+mapSecondData.incomeEarner),
			})),
			tap((val) => {
				this.addIncome(val);
				this.disableAll();
			}),
			take(1)
		);

	updateIncomeSource = (model: IncomeSourceState, index: number) =>
		new Observable<IncomeSourceState>((obs) => {
			const tempFactFind = {
				...this.factFind,
				...this.recompute(model, this.factFind),
			};
			obs.next(IncomeSourceMapper.mapTempFactFind(model, tempFactFind));
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				this.incomeService
					.updateIncomeSource(x)
					.pipe(mergeMap(() => this.updateIncome(index, x)))
			),
			finalize(() => {
				if (model?.incomeEarner && this.isSciInvalid(+model?.incomeEarner)) {
					this.invalidSciWarning();
				}
				this.IncomeArray.controls[index].get('isLoading').setValue(false);
			})
		);

	doneLoading = (index?: number) =>
		new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		}).pipe(
			finalize(() => {
				if (isNaN(index)) {
					this.isListLoading = false;
				} else {
					this.IncomeArray.controls[index].get('isLoading').setValue(false);
				}
			})
		);

	collapseFalse() {
		this.elseMinusIncome = false;
	}

	updateIncome = (index: number, data: IncomeSourceState) =>
		of(data).pipe(
			map((mapFirstData) => IncomeSourceMapper.mapIncomeToView(mapFirstData)),
			map((mapSecondData) => ({
				...mapSecondData,
				incomeEarner: this.getIncomeEarnerName(+mapSecondData.incomeEarner),
			})),
			tap((val) => {
				this.IncomeArray.controls[index]
					.get('incomeEarner')
					.setValue(val.incomeEarner);
				this.IncomeArray.controls[index]
					.get('employment')
					.setValue(val.employment);
				this.IncomeArray.controls[index]
					.get('occupation')
					.setValue(val.occupation);
				this.IncomeArray.controls[index]
					.get('totalGrossIncome')
					.setValue(val.totalGrossIncome);
				this.disableAll();
			}),
			take(1)
		);

	recompute(model: IncomeSourceState, factFind: FactFindComputationState) {
		const cRTId = +model.cRTId;
		const newValue = +model.totalGrossIncome;
		let tempTotalGrossIncome = 0;
		const tempList = [...this.incomeList] || [];

		if (!cRTId) {
			tempList?.push(model);
		}

		tempList?.map((income: IncomeSourceState) => {
			if (cRTId === income.cRTId) {
				income.totalGrossIncome = newValue;
			}
			tempTotalGrossIncome += +income.totalGrossIncome || 0;
		});
		const annualTaxable = computeAnnualTaxable({
			...factFind,
			overallTotalGrossIncome: +tempTotalGrossIncome,
		});
		const monthlyTaxable = computeMonthlyTaxable(+annualTaxable);
		const monthlyAfterTax = computeMonthlyAfterTaxIncome(
			tempList,
			{
				...factFind,
				overallTotalGrossIncome: +tempTotalGrossIncome,
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
			},
			this.incomeService.getIncomeLists()
		);

		return {
			overallTotalGrossIncome:
				+numUtil.formatToNumCurrency(tempTotalGrossIncome),
			annualTaxableJointIncome: +annualTaxable,
			monthlyTaxableJointIncome: +monthlyTaxable,
			monthlyAfterTaxIncome: +monthlyAfterTax,
		};
	}

	collapseMoreIncome() {
		$('#collapseIncome').toggle();
		this.elseMinusIncome = false;
	}

	collapseLessIncome() {
		$('#collapseIncome').toggle();
		this.elseMinusIncome = true;
	}

	disableAll() {
		this.form.disable();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
