import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { EmailSettingsService } from './state/email-settings.service';

@Injectable()
export class EmailSettingsResolver implements Resolve<boolean> {
	constructor(private service: EmailSettingsService) {}

	resolve(): Observable<boolean> {
		this.service.clear();
		return forkJoin([this.service.getEmailSettings()]).pipe(mapTo(true));
	}
}
