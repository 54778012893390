import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
	selector: 'app-slide',
	template: `<ng-template><ng-content></ng-content></ng-template>`,
})
export class SlideComponent {
	@ViewChild(TemplateRef)
	slideContent: TemplateRef<any>;
}
