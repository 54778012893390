import { ClientAcceptanceSettingsState } from './client-acceptance-settings.model';
import { SettingsTypes } from '../../state/crt-settings.model';
export class ClientAcceptanceSettingsMapper {
  public static mapToUpsert(data: ClientAcceptanceSettingsState) {
    return {
      ...data,
      referenceId: 0,
      type: SettingsTypes.AuthorityToProceed,
    };
  }
}
