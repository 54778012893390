<div #formProp>
	<div class="row align-items-center mb-3">
		<div class="col-auto">
			<strong>{{ groupName }}: {{ ksTypes.Investment }}</strong>
		</div>
		<div class="col">
			<div class="d-flex flex-row bd-highlight justify-content-end">
				<span *ngIf="isSaving">
					<div class="d-flex flex-row bd-highlight justify-content-end">
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isSaving"
						></app-manual-loader-mini>
					</div>
				</span>

				<!-- Save Service -->
				<ng-container *appRestrictTo="['FES']">
					<div *ngIf="(isEditForm || addMode) && !isSaving">
						<span class="position-relative">
							<button
								type="submit"
								class="icon-btn"
								(click)="save()"
								id="saveKiwisaver_{{ formId }}"
							>
								<i class="material-icons md-20 tap-text-color-h6"> save </i>
							</button>
						</span>
						<span class="position-relative">
							<button
								type="cancel"
								class="icon-btn"
								(click)="cancel()"
								id="cancelKiwisaver_{{ formId }}"
							>
								<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
							</button>
						</span>
					</div>
				</ng-container>

				<ng-container *ngIf="!addMode && !isEditForm && !isSaving">
					<div class="d-flex flex-row bd-highlight justify-content-end">
						<!-- Archive Service -->
						<ng-container *appRestrictTo="['FARS']">
							<span>
								<button
									*ngIf="investment?.isActive === 1"
									class="icon-btn"
									type="button"
									(click)="archive(true)"
									id="archiveKiwisaver"
								>
									<i
										data-toggle="tooltip"
										title="archive"
										class="material-icons md-20 tap-text-color-h6 pt-1"
									>
										archive
									</i>
								</button>
								<button
									*ngIf="investment?.isActive !== 1"
									class="icon-btn"
									type="button"
									(click)="archive(false)"
									id="unarchiveKiwisaver"
								>
									<i
										data-toggle="tooltip"
										title="unarchive"
										class="material-icons md-20 tap-text-color-h6 pt-1"
									>
										unarchive
									</i>
								</button>
							</span>
						</ng-container>

						<!-- Edit Service -->
						<ng-container *appRestrictTo="['FES']">
							<span>
								<button
									data-toggle="tooltip"
									title="edit"
									class="icon-btn"
									type="button"
									(click)="editForm(true)"
									id="editKiwisaver"
								>
									<i class="material-icons md-20 tap-text-color-h6 pt-1">
										edit
									</i>
								</button>
							</span>
						</ng-container>

						<!-- Delete Service -->
						<ng-container *appRestrictTo="['FDS']">
							<span>
								<button
									data-toggle="tooltip"
									title="delete"
									class="btn btn-link p-1 ng-star-inserted"
									type="button"
									(click)="deleteConfirm()"
									id="deleteKiwisaver"
								>
									<i class="material-icons md-20 tap-text-color-h6">  </i>
								</button>
							</span>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<form novalidate [formGroup]="form" class="pb-3">
		<div
			class="container-fluid clearfix px-0"
			[class.archived]="
				investment && investment?.isActive ? investment?.isActive === 2 : false
			"
		>
			<!-- First Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12">
							<span class="tap-required-field" *ngIf="investor.invalid">*</span>
							<label for="investor_{{ formId }}">Investor</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="investor"
								[choices]="investorDropdown"
								id="investor_{{ formId }}"
								tabindex="8"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="provider.invalid"
								>*</span
							>
							<label for="provider_{{ formId }}"> Provider </label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="provider"
									class="form-control theme-crm-field-disabled"
									id="provider_{{ formId }}"
									tabindex="13"
								>
									<option value=""></option>
									<option
										*ngFor="let p of providers; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="status.invalid">*</span>
							<label for="kstatus_{{ formId }}"> Status </label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="status"
									class="form-control theme-crm-field-disabled"
									id="kstatus_{{ formId }}"
									tabindex="16"
									(change)="resetCancellationDate($event.target.value)"
								>
									<option value=""></option>
									<option
										*ngFor="let s of statuses; trackBy: trackByValue"
										[value]="s.value"
									>
										{{ s.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Second Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="investorNumber.invalid"
								>*</span
							>
							<label for="investorNumber_{{ formId }}">Investor Number</label>
						</div>
						<div class="col-lg-6 col-12">
							<input
								type="text"
								formControlName="investorNumber"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="investorNumber_{{ formId }}"
								tabindex="8"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span
								class="tap-required-field"
								*ngIf="investmentType.invalid"
								>*</span
							>
							<label for="investmentType_{{ formId }}"> Investment Type</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="investmentType"
									class="form-control theme-crm-field-disabled"
									id="investmentType_{{ formId }}"
									tabindex="10"
								>
									<option value=""></option>
									<option
										*ngFor="let p of investmentTypes; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="irstatus_{{ formId }}"> Retention Status </label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="retentionStatus"
									class="form-control theme-crm-field-disabled"
									id="irstatus_{{ formId }}"
									tabindex="16"
								>
									<option value=""></option>
									<option
										*ngFor="let s of retentionStatuses; trackBy: trackByValue"
										[value]="s.value"
									>
										{{ s.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="submittedDate_{{ formId }}">Submitted Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#submittedDateInput
								formControlName="submittedDate"
								textboxClass="theme-crm-field-disabled"
								id="submittedDate_{{ formId }}"
								tabindex="17"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>

			<!-- Third Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="iRDNumber.invalid"
								>*</span
							>
							<label for="iRDNumber_{{ formId }}">IRD Number</label>
						</div>
						<div class="col-lg-6 col-12">
							<input
								type="text"
								formControlName="iRDNumber"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="iRDNumber_{{ formId }}"
								tabindex="9"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="contribution.invalid"
								>*</span
							>
							<label for="contribution_{{ formId }}">Contribution</label>
						</div>
						<div class="col-lg-6 col-12">
							<input
								type="text"
								formControlName="contribution"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="contribution_{{ formId }}"
								tabindex="14"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="totalWithdrawals_{{ formId }}"
								>Total Withdrawals</label
							>
						</div>
						<div class="col-6">
							<input
								type="text"
								formControlName="totalWithdrawals"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="totalWithdrawals_{{ formId }}"
								tabindex="13"
							/>
						</div>
					</div>
				</div>
				
				<div class="col-lg col-6">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="startDate_{{ formId }}">Start Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#startDateInput
								formControlName="startDate"
								textboxClass="theme-crm-field-disabled"
								id="startDate_{{ formId }}"
								tabindex="18"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>

			<!-- Fourth Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="pIRRate_{{ formId }}"> PIR Rate </label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="pIRRate"
									class="form-control theme-crm-field-disabled"
									id="pIRRate_{{ formId }}"
									tabindex="11"
								>
									<option value=""></option>
									<option
										*ngFor="let o of pirRates; trackBy: trackByValue"
										[value]="o.value"
									>
										{{ o.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-6">
								<label for="fUM_{{ formId }}">FUM</label>
							</div>
							<div class="col-6">
								<div class="dollar-icon">
									<input
										type="text"
										formControlName="fUM"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										id="fUM_{{ formId }}"
										tabindex="15"
										appTwoDigitDecimalNumber
									/>
									<i>$</i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="commission_{{ formId }}"
									>Commission</label
								>
							</div>
							<div class="col-6">
								<input
									type="text"
									formControlName="commission"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="commission_{{ formId }}"
									tabindex="14"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="startTrans_{{ formId }}">Commission Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#startTransInput
									formControlName="startTrans"
									textboxClass="theme-crm-field-disabled"
									id="startTrans_{{ formId }}"
									tabindex="16"
								>
								</app-date-input>
							</div>
						</div>
					</div>
				
			</div>

			<!-- Fifth Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12">
							<span class="tap-required-field" *ngIf="product.invalid">*</span>
							<label for="product_{{ formId }}">Product</label>
						</div>
						<div class="col-lg-9 col-12">
							<input
								type="text"
								formControlName="product"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="product_{{ formId }}"
								tabindex="12"
							/>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="originalAdviser.invalid"
								>*</span
							>
							<label for="originalAdvisers_{{ formId }}"
								>Original Adviser</label
							>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									[class.disabled]="
										addMode
											? false
											: (hasPermission(['FEO']) | async)
											? false
											: true
									"
									formControlName="originalAdviser"
									class="form-control theme-crm-field-disabled"
									id="originalAdvisers_{{ formId }}"
									tabindex="15"
								>
									<option value=""></option>
									<option
										*ngFor="let c of originalAdvisers$ | async"
										[value]="c.value"
									>
										{{ c.display }}
									</option>
								</select>
							</span>
						</div>
					</div>	
				</div>
				<div class="col-lg-3 col-12" *ngIf="status.value==='Cancelled'">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="cancellationDate_{{ formId }}">Cancellation Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#cancellationDateInput
								formControlName="cancellationDate"
								textboxClass="theme-crm-field-disabled"
								id="cancellationDate_{{ formId }}"
								tabindex="16"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>


			<!-- Sixth Row -->
			<ng-container *ngIf="addMode">
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col">
						<label for="note_{{ formId }}">Add a note</label>
						<textarea
							class="form-control theme-crm-field-disabled"
							formControlName="note"
							rows="4"
							id="note_{{ formId }}"
							tabindex="20"
						></textarea>
					</div>
				</div>
			</ng-container>

			<!-- Seventh Row -->
			<ng-container *ngIf="!addMode">
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg"></div>
				</div>
				<br />
				<div class="form-row clearfix align-items-center py-2">
					<div class="col-lg d-flex align-items-center">
						<strong class="mr-3">Notes</strong>
						<ng-container *ngIf="isLead">
							<button
								*appRestrictTo="['FALN']"
								type="button"
								class="icon-btn h-auto"
								[disabled]="isSaving"
								(click)="openModalAddNote()"
								id="addKiwisaverNote"
							>
								<i class="material-icons md-20 tap-text-color-h6">note_add</i>
							</button>
							<ng-container *ngIf="emailClientTemplateFeature$ | async">
								<button
									*appRestrictTo="['FALN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openEmailPopup()"
									id="openEmailClient"
								>
									<i class="material-icons md-20 tap-text-color-h6">email</i>
								</button>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="!isLead">
							<button
								*appRestrictTo="['FAN']"
								type="button"
								class="icon-btn h-auto"
								[disabled]="isSaving"
								(click)="openModalAddNote()"
								id="addKiwisaverNote"
							>
								<i class="material-icons md-20 tap-text-color-h6">note_add</i>
							</button>
							<ng-container *ngIf="emailClientTemplateFeature$ | async">
								<button
									*appRestrictTo="['FAN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openEmailPopup()"
									id="openEmailClient"
								>
									<i class="material-icons md-20 tap-text-color-h6">email</i>
								</button>
							</ng-container>
						</ng-container>
					</div>
				</div>
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<app-table-paginate-notes
							[isService]="true"
							[isLead]="isLead"
							[notes]="notes"
							[itemsPerPage]="5"
							[deleteFn$]="deleteNote$"
							[showHeader]="false"
						>
						</app-table-paginate-notes>
					</div>
				</div>
			</ng-container>
		</div>
	</form>
</div>
