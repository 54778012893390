import { CompanyDetailsState, CompanyMappedDetails } from './company-details';
import MomentUtil from '../../../../util/moment.util';
import { util } from '../../../../core/util/util.service';
import { AdviceProcessSectionCodes } from '../../advice-process/advice-process.model';

export class CompanyMapper {
    /** Mapping for view */
    static mapToView(data: CompanyDetailsState): CompanyMappedDetails {
        if (!data) {
            //return { ...data };
            return null;
        }
        return {
            ...data,
            shareholdersList: !!data.shareholdersList ? data.shareholdersList : [],
            directorsList: !!data.directorsList ? data.directorsList : [],
            otherKeyPeopleList: !!data.otherKeyPeopleList ? data.otherKeyPeopleList : [],
            businessValued: util.isValidMoment(data.businessValued) ? data.businessValued : MomentUtil.formatDateToMoment(data.businessValued),
        };
    }

    /**
     * Map to update
     */
    public static mapToUpsert(data: CompanyMappedDetails, personInfo: CompanyDetailsState, accountantList: any): CompanyDetailsState {
        return {
            ...data,
            customerID: personInfo?.customerID,
            adviceProcessId: personInfo?.adviceProcessId,
            cRTId: personInfo?.cRTId,
            sectionCode: AdviceProcessSectionCodes.Company,
            businessValued: MomentUtil.formatDateToServerDate(data.businessValued),
            businessValue: +data.businessValue,
            accountantList: !!accountantList && !!(accountantList[0].accountantName) ? accountantList : null,
            directorsList: data.directorsList && (data?.directorsList?.length > 0 && !!(data?.directorsList[0].name)) ? data?.directorsList : null,
            shareholdersList: data.shareholdersList && (data.shareholdersList?.length > 0 && !!(data.shareholdersList[0].name)) ? data.shareholdersList : null,
            otherKeyPeopleList: data.otherKeyPeopleList && (data.otherKeyPeopleList?.length > 0 && !!(data.otherKeyPeopleList[0].name)) ? data.otherKeyPeopleList : null
        };
    }
}
