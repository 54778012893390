import { Injectable } from '@angular/core';
import { NoteAdd, NoteParam, GetNote, NoteResponse } from '../../shared/models/_general/note';
import { JsonResultStatus, ApiService } from '../base/api.service';
import { Observable } from 'rxjs';
import { NoteRequest } from '../../shared/models/activity-timeline/activity-timeline.model';
import { GetNotes } from '../../shared/models/notes/note-params.model';

@Injectable()
export class NoteService {
	constructor(private api: ApiService) { }

	SaveNote = (request: NoteAdd | NoteParam | NoteRequest) => {
		const endpoint = 'notes';
		const body = request;
		return this.api.post3<JsonResultStatus>(endpoint, body);
	}

	GetNotes = (request: GetNote | GetNotes): Observable<NoteResponse> => {
		const endpoint = 'Note/GetNotes';
		return this.api.get<NoteResponse>(endpoint, request);
	}

	AddActivityNote = (request: NoteRequest) => {
		const endpoint = 'Note/AddActivityNote';
		return this.api.post3<JsonResultStatus>(endpoint, request);
	}

	AddCRTNote = (request: { AdviceProcessID: number, CustomerID: number, Notes: string }) => {
		const endpoint = 'notes/crt';
		return this.api.post3<JsonResultStatus>(endpoint, request);
	}

	AddAdviceProcessNote = (request: { ReferenceId: number, Type: string, Notes: string }) => {
		const endpoint = 'notes/crt';
		return this.api.post3<JsonResultStatus>(endpoint, request);
	}

	DeactivateNote = (noteId: number, isAp?: boolean) => {
		const endpoint = !isAp ? `notes/${noteId}` : `/notes/${noteId}/crt`;
		return this.api.delete<JsonResultStatus>(endpoint);
	}

	PinNote = (noteId: number, pin: boolean) => {
		const endpoint = `notes/pin/${noteId}/${pin}`;
		const body = null;
		return this.api.put(endpoint, body);
	}

	DeactiveNotesByType = (type: string, customerId: number) => {
		const endpoint = `notes/customer/${customerId}/${type}`;
		return this.api.delete<JsonResultStatus>(endpoint);
	}
}
