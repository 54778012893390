<form [formGroup]="form" novalidate>
	<div class="form-row mb-2 liabilities-label-background">
		<div class="col-7 col-sm-3 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary"
				>Liabilities</label
			>
		</div>
		<div class="col-5 col-sm-9 text-right iconShow">
			<button
				id="liabilities_collapseButton"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 liabilities-collapse-background"
			>
				<span *ngIf="elseMinusLiabilities; else elsePlusLiabilities">
					<i
						(click)="collapseMoreLiabilities()"
						class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusLiabilities>
					<span>
						<i
							(click)="collapseLessLiabilities()"
							class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div class="collapse expandable" id="collapseLiabilities">
		<div
			class="form-row d-none d-lg-flex mb-2 font-weight-bold"
			[class.tap-opacity-25]="(liabilities$ | async)?.length === 0"
		>
			<div class="col-12 col-lg-3 align-items-center">Liability</div>
			<div class="col-12 col-lg-3 align-items-center">Interest Rate</div>
			<div class="col-12 col-lg-3 align-items-center">Loan / Limit</div>
			<div class="col-12 col-lg-3 align-items-center">Loan Balance</div>
		</div>
		<div
			class="form-row align-items-center"
			*ngFor="let l of liabilities$ | async; let i = index; let last = last"
		>
			<div class="d-md-none col-lg-1 text-right">
				<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
					<button
						id="liabilities_editLiabilitiesButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="editLiabilities(l.cRTId, i)"
					>
						<i class="material-icons md-16"> edit </i>
					</button>
					<button
						id="liabilities_deleteModalButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="deleteModal(l.cRTId)"
					>
						<i class="material-icons md-16"> delete </i>
					</button>
				</ng-container>
				<ng-template #viewOnlySmall>
					<button
						id="liabilities_viewLiabilitiesButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="viewLiabilities(l.cRTId, i)"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-template>
			</div>
			<div class="col-lg-3 mb-2" [class.border-bottom]="!last">
				<input
					type="text"
					for=""
					id="liabilitiesLender"
					class="form-control crt-form-control"
					placeholder="Liability"
					[value]="l.liability"
					[disabled]="true"
				/>
			</div>
			<div class="col-lg-3 mb-2" [class.border-bottom]="!last">
				<input
					type="text"
					for=""
					id="liabilitiesInterestRate"
					class="form-control crt-form-control"
					placeholder="Interest Rate"
					[value]="+l.interestRate + '%'"
					[disabled]="true"
				/>
			</div>
			<div class="col-lg-3 mb-2" [class.border-bottom]="!last">
				<div class="dollar-icon">
					<input
						type="text"
						id="liabilitiesLoanValue"
						class="form-control crt-form-control"
						placeholder="Loan / Limit"
						[disabled]="true"
						[(ngModel)]="l.loanLimit"
						[ngModelOptions]="{ standalone: true }"
						currencyMask
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-lg-2 mb-2" [class.border-bottom]="!last">
				<div class="dollar-icon">
					<input
						type="text"
						id="liabilitiesLoanBalance"
						class="form-control crt-form-control"
						placeholder="Loan Balance crt-form-control"
						[disabled]="true"
						[(ngModel)]="l.loanBalance"
						[ngModelOptions]="{ standalone: true }"
						currencyMask
					/>
					<i>$</i>
				</div>
			</div>
			<div
				class="d-none d-md-block col-lg-1 text-right"
				[class.border-bottom]="!last"
			>
				<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
					<ng-container *ngIf="editIndex !== i">
						<button
							id="liabilities_editLiabilitiesButton_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							[disabled]="isEditing"
							(click)="editLiabilities(l.cRTId, i)"
						>
							<i class="material-icons md-16"> edit </i>
						</button>
						<button
							id="liabilities_deleteModalButton_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							[disabled]="isEditing"
							(click)="deleteModal(l.cRTId)"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
					<ng-container *ngIf="editIndex === i">
						<app-manual-loader-mini
							[isLoading]="isEditing"
						></app-manual-loader-mini>
					</ng-container>
				</ng-container>
				<ng-template #viewOnly>
					<button
						id="liabilities_viewLiabilitiesButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="isEditing"
						(click)="viewLiabilities(l.cRTId, i)"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-template>
			</div>
		</div>

		<div class="form-row align-items-center" *ngIf="crmLiabilitiesChoices.length !== 0 && isAddNew">
			<div class="col-lg-3">
				<span class="w-100" [class.crt-custom-dropdown]="selectedLiability">
					<select
						id="liabilitySelectFromCrm"
						class="form-control crt-form-control theme-crm-field-disabled"
						(change)="onSelectLiability($event.target.value)"
					>
						<option
							value=""
							selected
							disabled
							hidden
							class="select-text-muted"
						></option>
						<option
							*ngFor="let d of crmLiabilitiesChoices"
							[value]="d.value"
							class="select-text-option"
						>
							{{ d.display }}
						</option>
						<option value="new" class="select-text-option">
							+ Add new Liability
						</option>
					</select>
				</span>
			</div>
			<div class="col-lg-3">
				<span *ngIf="selectedLiability && selectedLiability.InterestRate">
					{{ selectedLiability.InterestRate + '%' }}
				</span>
			</div>
			<div class="col-lg-3">
				<span *ngIf="selectedLiability && selectedLiability.LoanLimit">
					{{ '$ ' + selectedLiability.LoanLimit}}
				</span>
			</div>
			<div class="col-lg-2">
				<span *ngIf="selectedLiability && selectedLiability.LoanBalance">
					{{ '$ ' + selectedLiability.LoanBalance }}
				</span>
			</div>

			<div class="d-none d-md-block col-lg-1 text-right pt-2">
				<ng-container *ngIf="!isAdding">
					<button
						color="primary"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="saveLiability()"
					>
						<i class="material-icons md-16"> save </i>
					</button>
					<button
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="cancelAdd()"
					>
						<i class="material-icons md-16"> close </i>
					</button>
				</ng-container>
				<ng-container *ngIf="isAdding">
					<app-manual-loader-mini
						[isLoading]="isAdding"
					></app-manual-loader-mini>
				</ng-container>
			</div>
		</div>
		
		<div class="form-row my-4">
			<div class="col-5 col-lg-3">
				<ng-container *ngIf="isLoading$ | async as loading">
					<app-manual-loader-mini
						[isLoading]="loading"
					></app-manual-loader-mini>
				</ng-container>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="liabilities_addLiabilities"
						*ngIf="!(isLoading$ | async)"
						(click)="addLiabilities()"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
						[disabled]="isAddNew"
					>
						Add Liability +
					</button>
				</ng-container>
			</div>
			<div class="offset-lg-1 col-sm-6 col-lg-5"></div>
		</div>
		<div class="border-bottom mb-3"></div>
	</div>
</form>
