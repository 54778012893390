import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import produce from 'immer';
import * as R from 'ramda';
import { LrInsuranceUiStore } from './lr-insurance-ui.store';
import { Row } from './lr-insurance.model';
import { LrInsuranceRequest } from '../lr-insurance-request.model';
import { Injectable } from '@angular/core';

interface ColumnWidth {
	metakey: string;
	width: number;
}

export interface LrInsuranceState extends EntityState<Row> {
	columns: string[];
	columnWidths: ColumnWidth[];
	count: number;
	totalAPI: number;
	isComplete: boolean;
	searchForm: LrInsuranceRequest;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lrInsurance', idKey: 'CustomerServiceID' })
export class LrInsuranceStore extends EntityStore<LrInsuranceState, Row> {
	constructor(public uiStore: LrInsuranceUiStore) {
		super({ columns: [], count: 0, totalAPI: 0, isComplete: false, columnWidths: [], searchForm: null });
	}

	setColumns(columns: string[]) {
		this.update(
			produce(x => {
				x.columns = columns;
			})
		);
	}

	setColumnWidths(columnWidths: ColumnWidth[]) {
		this.update(
			produce(x => {
				x.columnWidths = columnWidths;
			})
		);
	}

	setSearchForm(req: LrInsuranceRequest) {
		this.update(
			produce(x => {
				x.searchForm = req;
			})
		);
	}

	updateField(req: { CustomerServiceId: number | number[]; MetaKey: string; MetaValue: string }) {
		this.update(
			req.CustomerServiceId,
			produce(draft => {
				const fields = R.omit(
					[
						'CustomerID',
						'CustomerServiceID',
						'link',
						'ActivityId',
						'PolicyOwnersList',
						'ClientNextActivityId',
						'UserNextActivityId',
					],
					draft
				);
				Object.values(fields).find(x => x.metakey === req.MetaKey).value = req.MetaValue;

				if (req.MetaKey === 'Policy Status') {
					draft.StatusClock.value = '0 days';
				}

				if (req.MetaKey === 'Policy Retention Status') {
					draft.RetentionClock.value = '0 days';
				}


				if (req.MetaKey === 'Premium' && req.MetaValue === '0') {
					draft.API.value = '0.00';
				}

				if (req.MetaKey === 'Commission' && req.MetaValue === '0') {
					draft.Commission.value = '0.00';
				}
			})
		);
	}
}
