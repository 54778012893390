import { ClientSearchRequest } from './client-search-request.model';

// This returns an object
export const comparable = o =>
	typeof o !== 'object' || !o
		? o
		: Object.keys(o)
			?.sort()
			?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (req: ClientSearchRequest, saveReq: ClientSearchRequest) => {
	let r = true;

	if (!req || !saveReq) {
		return (r = false);
	}

	if (JSON.stringify(comparable(req.AdviserStatuses)) !== JSON.stringify(comparable(saveReq.AdviserStatuses))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.Advisers)) !== JSON.stringify(comparable(saveReq.Advisers))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.AltAdvisers)) !== JSON.stringify(comparable(saveReq.AltAdvisers))) {
		r = false;
	}

	if (JSON.stringify(comparable(req.FirstName)) !== JSON.stringify(comparable(saveReq.FirstName))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.LastName)) !== JSON.stringify(comparable(saveReq.LastName))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.LeadOrigins)) !== JSON.stringify(comparable(saveReq.LeadOrigins))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.Services)) !== JSON.stringify(comparable(saveReq.Services))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.ContactStatus)) !== JSON.stringify(comparable(saveReq.ContactStatus))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.ContactTypes)) !== JSON.stringify(comparable(saveReq.ContactTypes))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.NextActivityTypes)) !== JSON.stringify(comparable(saveReq.NextActivityTypes))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.NextActivityDateMin)) !== JSON.stringify(comparable(saveReq.NextActivityDateMin))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.NextActivityDateMax)) !== JSON.stringify(comparable(saveReq.NextActivityDateMax))) {
		r = false;
	}
	// if (JSON.stringify(comparable(req.LastReviewDateMin)) !== JSON.stringify(comparable(saveReq.LastReviewDateMin))) {
	// 	r = false;
	// }
	// if (JSON.stringify(comparable(req.LastReviewDateMax)) !== JSON.stringify(comparable(saveReq.LastReviewDateMax))) {
	// 	r = false;
	// }
	if (JSON.stringify(comparable(req.NextReviewDateMin)) !== JSON.stringify(comparable(saveReq.NextReviewDateMin))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.NextReviewDateMax)) !== JSON.stringify(comparable(saveReq.NextReviewDateMax))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.ClientSinceDateMin)) !== JSON.stringify(comparable(saveReq.ClientSinceDateMin))) {
		r = false;
	}
	if (JSON.stringify(comparable(req.ClientSinceDateMax)) !== JSON.stringify(comparable(saveReq.ClientSinceDateMax))) {
		r = false;
	}
	if (
		JSON.stringify(comparable(req.NextActivityAssignedTo)) !==
		JSON.stringify(comparable(saveReq.NextActivityAssignedTo))
	) {
		r = false;
	}

	return r;
};

export const request = (filter: any, form: ClientSearchRequest, index: number) => {
	const req = {
		AdviserStatuses: form.AdviserStatuses,
		Advisers: form.Advisers,
		AltAdvisers: form.AltAdvisers,
		FirstName: form.FirstName,
		LastName: form.LastName,
		LeadOrigins: form.LeadOrigins,
		Services: form.Services,
		ContactStatus: form.ContactStatus,
		ContactTypes: form.ContactTypes,
		NextActivityTypes: form.NextActivityTypes,
		NextActivityDateMin: form.NextActivityDateMin,
		NextActivityDateMax: form.NextActivityDateMax,
		NextActivityAssignedTo: form.NextActivityAssignedTo,
		// LastReviewDateMin: form.LastReviewDateMin,
		// LastReviewDateMax: form.LastReviewDateMax,
		NextReviewDateMin: form.NextReviewDateMin,
		NextReviewDateMax: form.NextReviewDateMax,
		ClientSinceDateMin: form.ClientSinceDateMin,
		ClientSinceDateMax: form.ClientSinceDateMax,
		NextReviewServiceCode: form.NextReviewServiceCode,

		Paging: {
			Index: index,
			Column: filter ? filter.Column : null,
			Direction: filter ? filter.Direction : null,
		},
	};
	return req;
};
