import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, map, combineLatest, startWith } from 'rxjs/operators';
import { LRAService } from './state/lra.service';
import { LRAQuery } from './state/lra.query';
import { DashboardQuery } from '../../state/dashboard.query';
import { RouteService, CommandRoute } from '../../../../../core/config/route.service';
import { LRAnniversary } from './state/lra.model';
import { UntypedFormControl } from '@angular/forms';
import MomentUtil from '../../../../../util/moment.util';
import { DateRange } from '../../../../../shared/date-range/date-range.component';
import { numUtil } from '../../../../../util/util';

/**
 * L&R Anniversaries widget component
 */
@Component({
  selector: 'app-lra',
  templateUrl: './lra.component.html',
  styleUrls: ['./lra.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LraComponent implements OnInit, OnDestroy {
  public static widgetCode = 'lra';
  public static widgetName = 'L&R Anniversaries';
  public static sizeX = 400;
  public static sizeY = 480;
  public static minSizeX = 400;
  public static minSizeY = 480;

  widgetCode = LraComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * L&R Anniversaries data
   */
  data$: Observable<LRAnniversary[]> = this.query.data$.pipe(
    map(x => x?.map(lra => ({ ...lra, Premium: numUtil.formatToCurrency(lra.Premium ? +lra.Premium : 0) })))
  );
  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;
  /**
   * Error message string. Show error if not empty.
   */
  error$ = this.query.error$;
  /** form control for date range.
   * Should default to 1 week behind up to now.
   */
  dateControl: UntypedFormControl = new UntypedFormControl({
    min: MomentUtil.formatToServerDate(MomentUtil.createMomentNz()),
    max: MomentUtil.formatToServerDate(MomentUtil.createMomentNz().add(13, 'd'))
  } as DateRange);

  constructor(
    private routeService: RouteService,
    private dashboardQuery: DashboardQuery,
    private service: LRAService,
    private query: LRAQuery
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of goal track.
   *
   * Chart does not automatically resize on div resize(gridstack)
   *   so listen to resize event to trigger manual resize via refreshing
   *   of data.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        combineLatest(this.dateControl.valueChanges.pipe(startWith(this.dateControl.value))),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([advisers, dateRange]) => {
        this.service.GetLRAnniversaries({
          advisers,
          dateRangeMin: dateRange.min,
          dateRangeMax: dateRange.max
        });
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  /**
   * Get a route for routerLink directive.
   *
   * @param customerId
   * customer id of the customer edit page to route to
   * @param isCompany
   * if it is a company, it will route to business edit page,
   * else, client edit page
   *
   * @returns route to either business or client edit apge
   */
  public customerRoute(customerId: number, isCompany: boolean): CommandRoute {
    if (isCompany) {
      return this.routeService.businessLrPipeline(customerId);
    } else {
      return this.routeService.customerLrPipeline(customerId);
    }
  }
}
