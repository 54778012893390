import { MergeTagsMapper } from '../merge-tags/merge-tags.mapper';
import { PdfDesignV2State, PdfGenericDivId } from './pdf-design-v2.model';

export class PdfDesignV2Mapper {
	public static splitTemplate(
		content: string,
		classes: string,
		genericCoverBg?: string
	): PdfDesignV2State {
		const genericCoverContent = MergeTagsMapper.getContentByDivId(
			content,
			PdfGenericDivId
		);
		const bodyContent = this.getBody(content);
		const genericCoverClass = genericCoverBg
			? 'pdf-design-v2-custom-generic-cover full-width-pdf'
			: 'pdf-design-v2-default-generic-cover';
		const genericBg = genericCoverBg
			? `<div class="pdf-design-v2-generic-cover-bg full-width-pdf" style="background-image: url(${genericCoverBg});"></div>`
			: '';

		return {
			genericCover: !!genericCoverContent
				? `<div class="${classes} pdf-design-v2-generic ${genericCoverClass}">${genericCoverContent}${genericBg}</div>`
				: '',
			body: `<div class="${classes} pdf-design-v2-body">${bodyContent}</div>`,
		};
	}

	public static getBody(content: string) {
		const wrapId = 'getBody';
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

		newHtml
			.querySelectorAll(`#${PdfGenericDivId}`)
			.forEach((i: HTMLElement) => {
				i?.remove();
			});

		return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
	}
}
