import { Component, OnInit, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EmailHistoryModel } from '../state/email-history.model';
import { EmailHistoryService } from '../state/email-history.service';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { util } from '../../../../core/util/util.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'app-email-history-form',
	templateUrl: './email-history-form.component.html'
})
export class EmailHistoryFormComponent implements OnInit {
	private onDestroy$ = new Subject<void>();
	@Input() emailModeList: ViewDisplayValue[];
	@Output() valueChange = new EventEmitter<boolean>();
	@Input() emailType: ViewDisplayValue[];
	@Input() getReport: (values) => Observable<any>;

	form: UntypedFormGroup;
	prepData = {};
	isSaving = false;
	isExport = false;

	constructor(private fb: UntypedFormBuilder,
		           private emailHistoryService: EmailHistoryService,
		           private activitedRoute: ActivatedRoute,
		           private renderer: Renderer2) {
		this.buildForm();
	}

	ngOnInit() {
	}

	buildForm() {
		this.form = this.fb.group({
			emailCode: [],
			dateTargetMin: [],
			dateTargetMax: [],
			emailMode: []
		});
	}

	prepareData(values) {
		return this.prepData = {
			EmailCode: (values.emailCode && values.emailCode.length !== 0) ? values.emailCode : null,
			EmailMode: (values.emailMode && values.emailMode.length !== 0) ? values.emailMode : null,
			DateTargetMin: values.dateTargetMin ? util.MomentToDateString(values.dateTargetMin) : null,
			DateTargetMax: values.dateTargetMax ? util.MomentToDateString(values.dateTargetMax) : null,
		};
	}

	clearForm() {
		this.form.reset();
		this.form.controls.emailCode.setValue([]);
		this.form.controls.emailMode.setValue([]);
	}

	getBtnClick() {
		this.isSaving = true;
		const values = this.prepareData(this.form.value);
		this.getReport(values).pipe(
			tap(x => {
				this.isSaving = false;
				this.valueChange.emit(true);
			}),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

	export() {
		this.isExport = true;
		const values = this.prepareData(this.form.value) as EmailHistoryModel;
		this.emailHistoryService.exportStatus(values).pipe(
			takeUntil(this.onDestroy$)
		)
			.subscribe(x => {
				this.downloadExport(x);
				this.isExport = false;
			});
	}

	downloadExport(file: any) {
		const name = this.activitedRoute.snapshot.paramMap.get('companyCode') + '-Email History.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		const url = window.URL.createObjectURL(file);
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
		window.URL.revokeObjectURL(url);
	}

}
