import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { BlStaffModel } from '../../../../shared/models/_general/bl-staff.model';
import { BlStaffService } from '../../../../core/staff/bl-staff.service';

@Injectable()
export class BlActiveStaffResolver implements Resolve<BlStaffModel[]> {
  /**
   *
   */
  constructor(private bss: BlStaffService) { }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): BlStaffModel[] | Observable<BlStaffModel[]> | Promise<BlStaffModel[]> {
    return this.bss.getBlActiveStaffs();
  }
}
