<div class="form-row mb-2 trust-label-background">
  <div class="col-7 col-lg-4 mt-1 mt-lg-0 mb-lg-3">
    <label for="" class="font-weight-bold label-font tap-text-primary">Trusts</label>
  </div>
  <div class="col-5 col-lg-9 text-right iconShow">
    <button
      id="trustCollapseButton"
      type="button"
      class="
        btn-toggle
        bg-white
        collapse-btn
        border-0
        trust-collapse-background
      "
    >
      <span *ngIf="elseMinusTrust; else elsePlusTrust">
        <i (click)="collapseMoreTrust()" class="material-icons md-20 pt-1"
          >expand_more</i
        >
      </span>
      <ng-template #elsePlusTrust>
        <span>
          <i (click)="collapseLessTrust()" class="material-icons md-20 pt-1"
            >expand_less</i
          >
        </span>
      </ng-template>
    </button>
  </div>
</div>

<div class="form-row d-none d-lg-flex mb-2">
  <div
    class="col-lg-3"
    [class.tap-opacity-25]="(trusts$ | async)?.length === 0"
  >
    <span class="font-weight-bold">Trust Name</span>
  </div>
</div>

<div class="collapse expandable" id="collapseTrust">
  <div
    class="form-row align-items-center"
    *ngFor="let d of trusts$ | async; let i = index; let last = last"
  >
		<div class="d-md-none col-lg-2 text-right">
			<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
				<button id="trustEditButton_{{ i }}" type="button" class="icon-btn w-auto h-auto px-1" (click)="editTrust(d.cRTId)">
					<i class="material-icons md-16"> edit </i>
				</button>
				<button id="trustDeleteButton_{{ i }}" type="button" class="icon-btn w-auto h-auto px-1" (click)="deleteTrust(d.cRTId)">
					<i class="material-icons md-16"> delete </i>
				</button>
			</ng-container>
			<ng-template #viewOnlySmall>
				<button id="trustViewButton_{{ i }}" type="button" class="icon-btn w-auto h-auto px-1" (click)="viewTrust(d.cRTId)">
					<i class="material-icons md-16"> info </i>
				</button>
			</ng-template>
		</div>
    <div class="col-12 col-lg-3 mb-2" [class.border-bottom]="d.name && !last">
      <ng-container *ngIf="d.name">
        <input
          id="trustName_{{ i }}"
          class="form-control crt-form-control"
          type="text"
          value="{{ d.name }}"
          [disabled]="d.name"
        />
      </ng-container>
      <ng-container *ngIf="!d.name">
        <div class="col-12 col-lg px-lg-0">
          <span class="custom-dropdown w-100">
            <select
              [id]="'addNewTrustDropdown_' + i"
              name="addNewTrustDropdown"
              class="form-control crt-form-control"
              (change)="change($event, i)"
            >
              <option value=""></option>
              <option *ngFor="let d of trustList$ | async" [value]="d.value">
                {{ d.display }}
              </option>
              <option value="new">+ Add New Trust</option>
            </select>
          </span>
        </div>
      </ng-container>
    </div>
		<div class="d-none d-md-block col-auto" [class.border-bottom]="d.name && !last">
			<ng-container *ngIf="d.cRTId && !isCrtLoading(d?.cRTId)">
				<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
					<button
						id="trustEditTrustButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1 p-0"
						(click)="editTrust(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> edit </i>
					</button>
					<button
						id="trustDeleteTrustButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1 p-0"
						(click)="deleteTrust(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> delete </i>
					</button>
				</ng-container>
				<ng-template #viewOnly>
					<button
						id="trustViewTrustButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="viewTrust(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="isCrtLoading(d?.cRTId)">
				<app-manual-loader-mini
					[isLoading]="true"
				></app-manual-loader-mini>
			</ng-container>
			<ng-container *ngIf="!d.cRTId && !isCrtLoading(d?.cRTId)">
				<button
					id="trustAddExistingTrustButton_{{ i }}"
					color="primary"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="addExistingTrust(d)"
					[disabled]="!d.name"
				>
					<i class="material-icons md-16"> save </i>
				</button>
				<button
					id="trustRemoveTrustButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="removeTrust(i)"
				>
					<i class="material-icons md-16"> close </i>
				</button>
			</ng-container>
		</div>
  </div>
  <div class="form-row my-4">
    <div class="col-5 col-lg-2 pl-2">
			<ng-container *ngIf="!isAdviceProcessEnded">
				<button
					id="trustAddTrustButton"
					type="button"
					(click)="addTrust()"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
					[disabled]="isAddNew || (isTrustsLoading$ | async) || (isLoading$ | async)"
				>
					Add Trust +
				</button>
			</ng-container>
      <ng-container *ngIf="isTrustsLoading$ | async">
        <app-manual-loader-mini
          [isLoading]="isTrustsLoading$ | async"
        ></app-manual-loader-mini>
      </ng-container>
    </div>
  </div>
  <div class="form-row line mb-3"></div>
</div>
