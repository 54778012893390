import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { MOATAssetsLiabilitiesModalComponent } from 'src/app/shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { KiwiSaver } from '../../../crt-mortgage/client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.model';
import { LiabilityMapper } from './../../../../../../shared/models/client-review-template/assets-liabilities/liability/liability.mapper';
import { Liability } from './../../../crt-mortgage/client-sop/assets-and-liabilities/state/liability/liability.model';

@Component({
	selector: 'app-crt-liability',
	templateUrl: './crt-liability.component.html',
})
export class CrtLiabilityComponent implements OnInit {
	@Input() liabilities: Liability[];
	@Input() borrowers$: Observable<ViewDisplayValue[]>;
	@Input() securities$: Observable<ViewDisplayValue[]>;
	@Input() MP$: Observable<ViewDisplayValue[]>;
	@Input() MLT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() APCRTL$: Observable<ViewDisplayValue[]>;
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	isAdd: boolean;
	bsModalRef: BsModalRef;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor(private modalService: BsModalService) { }

	ngOnInit(): void { }

	trackByFn(index: number, ks: KiwiSaver): number {
		return ks.cRTId;
	}

	add() {
		const decline$ = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const saveFn$ = (data) =>
			this.addFn$(LiabilityMapper.mapToDetails(data, this.adviceProcessId));

		const initState = {
			header: 'Liabilities Details',
			message: `Liabilities`,
			borrowers: this.borrowers$,
			securities: this.securities$,
			mp$: this.MP$,
			mlt$: this.MLT$,
			apcrtf$: this.APCRTF$,
			apcrtyn$: this.APCRTYN$,
			apcrtynna$: this.APCRTYNNA$,
			apcrtl$: this.APCRTL$,
			saveFn$,
			decline$,
			getOwnerChoices: this.getOwnerChoices
		};
		this.bsModalRef = this.modalService.show(MOATAssetsLiabilitiesModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
	}

	cancelEvent(event?) {
		this.isAdd = false;
	}

	saveEvent(event?) {
		this.isAdd = false;
	}
}
