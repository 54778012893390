import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DocumentModelState } from '../../../../shared/models/documents/document.model';
import { ApiService } from '../../../../core/base/api.service';
import { objectUtil } from '../../../../util/util';
import { MortgageSettingsQuery } from '../../state/mortgage-settings.query';
import { MortgageSettingsStore } from '../../state/mortgage-settings.store';
import {
	IntroSettingsState,
	DocumentUploadState,
} from './intro-settings.model';

@Injectable()
export class MoatIntroSettingsService {
	introSettings$ = this.query.introSettings$;

	constructor(
		private api: ApiService,
		private store: MortgageSettingsStore,
		private query: MortgageSettingsQuery
	) {}

	clearIntroSettings(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getIntroSettings(referenceId: number) {
		const endpoint = `crt/settings/${referenceId}/MOATIP`;
		return this.api.get<IntroSettingsState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setIntroSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateIntroSettings(data: IntroSettingsState) {
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<IntroSettingsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setIntroSettings(state);
				})
			)
		);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	getFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}
}
