import { CompanyDetailsState, CompanyMappedDetails } from './company-details';
import { AdviceProcessSectionCodes } from '../../advice-process/advice-process.model';
import { NoteTypes } from '../../notes/note.model';

export class CompanyMapper {
    /** Mapping for view */
    static mapToView(data: CompanyDetailsState): CompanyMappedDetails {
        if (!data) {
            //return { ...data };
            return null;
        }
        return {
            ...data,
            shareholdersList: !!data.shareholdersList ? data.shareholdersList : [],
            directorsList: !!data.directorsList ? data.directorsList : [],
            notes: data.notes
        };
    }

    /**
     * Map to update
     */
    public static mapToUpsert(data: CompanyMappedDetails, personInfo: CompanyDetailsState, accountantList: any): CompanyDetailsState {
        const noteData = data.notes.cRTNotesId ? {
            ...data.notes,
            type: NoteTypes.MOATCompany,
            referenceId: personInfo?.cRTId,
            isActive: 1
        } :
        {
            type: NoteTypes.MOATCompany,
            notes: data.notes.notes
        }

        return {
            ...data,
            customerID: personInfo?.customerID,
            adviceProcessId: personInfo?.adviceProcessId,
            cRTId: personInfo?.cRTId,
            sectionCode: AdviceProcessSectionCodes.Company,
            accountantList: !!accountantList && !!(accountantList[0].accountantName) ? accountantList : [],
            directorsList: data.directorsList && (data?.directorsList?.length > 0 && !!(data?.directorsList[0].name)) ? data?.directorsList : [],
            shareholdersList: data.shareholdersList && (data.shareholdersList?.length > 0 && !!(data.shareholdersList[0].name)) ? data.shareholdersList : [],
            notes: noteData
        };
    }
}
