import { Component, OnInit } from '@angular/core';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import {
	BlStaffDocumentModel,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { convertUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import {
	ALLOWED_DOCUMENT_FILE_TYPES,
	UploadModalComponent,
} from '../upload-modal/upload-modal.component';
import { BlDocumentModel } from '@shared/models/documents/document.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesCodes } from '@shared/models/services/services.model';
import { LinkDocumentSingleTabComponent } from '@shared/services/link-document-single-tab/link-document-single-tab.component';

@Component({
	selector: 'app-user-document-modal',
	templateUrl: './user-document-modal.component.html',
	styleUrls: ['./user-document-modal.component.scss'],
})
export class UserDocumentModalComponent implements OnInit {
	title: string = 'Link Document';
	documentNameText: string = 'Document Name';
	data: BlStaffSettingsModel;
	staffId: number;
	documentName: string;
	documentTypeCode: string;
	type: string;
	btnText: string;
	isSingleUpload: boolean;
	showDocumentName: boolean;
	restrictFileTypes = ALLOWED_DOCUMENT_FILE_TYPES;
	uploadOnly: boolean;
	form: FormGroup;
	document: BlStaffSettingsModel;
	isLoading: boolean;
	linkedDocs: BlStaffDocumentModel[] = [];
	submitted = false;
	documents = [];

	upsertStaffDocumentFn$: (
		ac: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel>;
	downloadDocumentFn$: (ac: {
		documentId: number;
		fileName: string;
	}) => Observable<any>;
	getUserDocumentsFn$: (ac: number) => Observable<BlDocumentModel[]>;

	public bsModalRefUpload: BsModalRef;
	public optionModalRef: BsModalRef;
	public linkModalRef: BsModalRef;

	get DocumentName() {
		return this.form.get('documentName');
	}

	constructor(
		private fb: FormBuilder,
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private crtDocService: CrtDocumentService
	) {
		this.form = this.fb.group({
			documentName: [''],
		});
	}

	ngOnInit(): void {
		if (!!this.data?.StaffSettingsId && this.data?.Documents?.length > 0) {
			this.linkedDocs = this.data?.Documents;
			this.form.patchValue({
				documentName: !!this.showDocumentName
					? this.data?.DocumentName
					: this.documentName,
			});
		}
		this.setValidators();
	}

	setValidators() {
		if (this.showDocumentName) {
			this.DocumentName.setValidators(Validators.required);
		} else {
			this.DocumentName.clearValidators();
		}
		this.DocumentName.updateValueAndValidity();
	}

	removeLinkedDocument(data) {
		let docs = [...this.linkedDocs];
		docs = docs?.filter((x) => +x?.ReferenceId !== +data?.ReferenceId);
		this.linkedDocs = docs;
	}

	removeUploadedDocument(index: number, data) {
		let docs = [...this.documents];
		docs = docs?.filter((x) => +x?.ReferenceId !== +data?.ReferenceId);
		this.documents = docs;
	}

	linkDocument() {
		const code = ServicesCodes.UserTracker;
		this.getUserDocumentsFn$(+this.staffId)
			.pipe(take(1))
			.subscribe((docs) => {
				const initState = {
					selectedDetail: 'Link Document',
					message: 'Select Document to Link',
					documents: docs,
					singleTab: true,
					tab: code,
				};
				this.linkModalRef = this.modalService.show(
					LinkDocumentSingleTabComponent,
					{
						class: 'modal-dialog-centered modal-lg link-document-single-tab',
						initialState: initState,
						ignoreBackdropClick: true,
					}
				);

				this.linkModalRef.content.getSelectedDocumentValue$
					.pipe(
						tap((doc: any) => {
							this.linkedDocs = [
								...(this.linkedDocs || []),
								{ ReferenceId: doc?.Id, Value: doc?.FileName },
							];
						}),
						take(1)
					)
					.subscribe();
			});
	}

	uploadDocument() {
		const documentName = !!this.showDocumentName
			? this.form.getRawValue()?.documentName || ''
			: this.data?.DocumentName || '';
		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					convertUtil.simpleConvertToBase64(x[0]).pipe(
						map((file) => ({
							content: (file as string)?.replace(/^data:(.*,)?/, ''),
							filename: x[0]?.name || '',
						}))
					)
				),
				map((file: any) => ({
					Document: file.content,
					FileName: file.filename,
					DocumentName: documentName,
					DocumentType: this.documentTypeCode,
					Type: this.type,
					ReferenceId: this.staffId,
					CustomerId: 0,
				})),
				concatMap((file) =>
					this.crtDocService.uploadDocument(file).pipe(
						map((x) => ({
							...file,
							ReferenceId: +x,
							Value: file?.FileName,
						}))
					)
				),
				tap((x) => {
					this.documents = [...this.documents, x];
				}),
				take(1)
			);

		const initialState = {
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Add New Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
			customUpload: upload,
		};
		this.bsModalRefUpload = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveDocument() {
		this.submitted = true;
		if (
			this.form.invalid ||
			(this.linkedDocs?.length === 0 && this.documents?.length === 0)
		) {
			return;
		}
		this.isLoading = true;

		of(this.form.getRawValue())
			.pipe(
				map((formvalue) => {
					return {
						...this.data,
						StaffId: this.staffId,
						Type: this.type,
						DocumentName: !!this.showDocumentName
							? formvalue?.documentName || ''
							: this.data?.DocumentName || '',
						Documents: [...(this.linkedDocs || []), ...(this.documents || [])],
					} as BlStaffSettingsModel;
				}),
				concatMap(this.upsertStaffDocumentFn$),
				catchError((error) => {
					if (error) {
						this.isLoading = false;
					}
					throw error;
				}),
				tap(() => {
					this.isLoading = false;
					this.close();
				}),
				take(1)
			)
			.subscribe();
	}

	close() {
		this.bsModalRef.hide();
	}
}
