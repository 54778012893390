import * as R from 'ramda';
import { AdviceProcessSectionCodes } from '../../../advice-process/advice-process.model';
import { ScopeOfServiceState } from '../../scope-of-service/scope-of-service.model';
import {
  RiskProfileState,
  EventState,
  InsurancePreferenceState,
} from './risk-profile.model';

export class RiskProfileMapper {
  /** Mapping for view */
  public static mapToView(data: RiskProfileState) {
    return data;
  }

	public static mapToSosDefault(sosCrt: ScopeOfServiceState) {
		let prematureDeath = 'N/A';
  let unableToWorkPermanently = 'N/A';
		let sufferFromCriticalIllnessOrTrauma = 'N/A';
		let unableToWorkDueToTemporarySickness = 'N/A';
		let medicalConditionRequiringHospitalTreatment = 'N/A'

		if (R.complement(R.either(R.isNil, R.isEmpty))(sosCrt)) {
			prematureDeath = +sosCrt?.lifeInsurance === 1 ? '' : 'N/A';
   unableToWorkPermanently = +sosCrt?.lifeInsurance === 1 ? '' : 'N/A';
			sufferFromCriticalIllnessOrTrauma = +sosCrt?.criticalIllness === 1 ? '' : 'N/A';
			unableToWorkDueToTemporarySickness = +sosCrt?.disabilityInsurance === 1 ? '' : 'N/A';
			medicalConditionRequiringHospitalTreatment = +sosCrt?.medicalInsurance === 1 ? '' : 'N/A';
			unableToWorkPermanently = +sosCrt?.tPDInsurance === 1 ? '' : 'N/A';
		}

		return {
			peopleCRTId: null,
			isDependents: null,
			prematureDeath,
      unableToWorkPermanently,
			sufferFromCriticalIllnessOrTrauma,
			unableToWorkDueToTemporarySickness,
			medicalConditionRequiringHospitalTreatment
		} as EventState;
  }


  public static mapEventToView(data: EventState[]) {
    return data?.map((item) => {
      return {
        peopleCRTId: item.peopleCRTId,
        prematureDeath:
          item.prematureDeath,
        unableToWorkPermanently:
          item.unableToWorkPermanently,
        sufferFromCriticalIllnessOrTrauma:
          item.sufferFromCriticalIllnessOrTrauma,
        unableToWorkDueToTemporarySickness:
          item.unableToWorkDueToTemporarySickness,
        medicalConditionRequiringHospitalTreatment:
          item.medicalConditionRequiringHospitalTreatment,
        isDependents: item.isDependents,
      };
    });
  }

  /** Mapping for update */
  public static mapPreferenceToUpsert(list: InsurancePreferenceState[]) {
    if (!list) {
      return [];
    }
    return list?.map((item) => {
      return {
        dropdown: item.dropdown,
        value: item.value,
      };
    });
  }

  public static mapToUpsert(data: RiskProfileState) {
    return {
      ...data,
      event: data?.event || [],
      insurancePreferences: data?.insurancePreferences || [],
      status: 1,
      sectionCode: AdviceProcessSectionCodes.RiskProfile,
    };
  }
}
