import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderMiniComponent } from './loader-mini.component';

@NgModule({
  declarations: [LoaderMiniComponent],
  exports: [LoaderMiniComponent],
  imports: [CommonModule],
})
export class LoaderMiniModule {}
