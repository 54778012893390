import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { RouteService } from '../../../../../core/config/route.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-section',
  templateUrl: './admin-section.component.html',
  styleUrls: ['./admin-section.component.scss']
})
export class AdminSectionComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  adminBList = '';
  adminUList = '';
  adminUAdd = '';

  isAdminCollapsed = true;
  constructor(private routeService: RouteService) {}

  ngOnInit() {
    this.routeService.RouteChange$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        x => {
          (this.adminBList = x.adminBusinessList),
            (this.adminUList = x.adminUsersList),
            (this.adminUAdd = x.adminUsersAdd);
        }
      );
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}

