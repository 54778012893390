import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ServiceAdviceProcessState } from '../../../../../shared/models/advice-process/advice-process.model';
import { objectUtil } from '../../../../../util/util';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';

@Injectable()
export class LrAdviceprocessService extends ClientReviewTemplateService {
	adviceProcess$ = this.query.adviceProcess$;
	adviceProcessId$ = this.query.adviceProcessId$;
	lrApPageCompleted$ = this.query.lrApPageCompleted$;
	lrApPageStarted$ = this.query.lrApPageStarted$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getAdviceProcess(adviceProcessId): Observable<ServiceAdviceProcessState> {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			map((data) => (data ? objectUtil.mapPascalCaseToCamelCase(data) : null)),
			tap((data) =>
				applyTransaction(() => {
					this.store.setAdviceProcess(data);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateLrApPageStarted(data) {
		const body = objectUtil.mapCamelCaseToPascalCase({
			referenceID: +this.query.getValue().adviceProcessId,
			secondaryReferenceID: 0,
			key: 'PageStarted',
			value: JSON.stringify(data || []),
		});
		const endpoint = `adviceprocesses`;

		return this.api.patch<any>(endpoint, [body]).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setLrApPageStarted(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateLrApPageCompleted(data) {
		const body = objectUtil.mapCamelCaseToPascalCase({
			referenceID: +this.query.getValue().adviceProcessId,
			secondaryReferenceID: 0,
			key: 'PageCompleted',
			value: JSON.stringify(data || []),
		});
		const endpoint = `adviceprocesses`;

		return this.api.patch<any>(endpoint, [body]).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setLrApPageCompleted(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	/*
	 * State Update
	 */
	setLrApPageCompletedState(b: any[]) {
		applyTransaction(() => {
			this.store.setLrApPageCompleted(b);
		});
	}

	setLrApPageStartedState(b: any[]) {
		applyTransaction(() => {
			this.store.setLrApPageStarted(b);
		});
	}
}
