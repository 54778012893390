import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { StructureSoaSecurity, BankWillTake } from './structure-soa-security.model';

export interface StructureSoaSecurityState extends EntityState<StructureSoaSecurity> {
  bankWillTake: BankWillTake[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'structureSoaSecurity', idKey: 'cRTId' })
export class StructureSoaSecurityStore extends EntityStore<StructureSoaSecurityState, StructureSoaSecurity> {
	constructor() {
		super();
	}
}
