import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableScrollDataSource } from './mat-data-table-scroll.datasource';
import { distinctUntilChanged, map, mergeMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MatDataTableModel } from '@shared/models/_general/mat-data-table.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RouteService } from '@core/config/route.service';
import { Router } from '@angular/router';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { ComponentBase } from '@core/base/component-base';
declare var $: any;

/**
 * Material Table with Virtual Scroll
 */
@Component({
	selector: 'app-mat-data-table-scroll',
	templateUrl: './mat-data-table-scroll.component.html',
	styleUrls: ['./mat-data-table-scroll.component.scss'],
})
export class MatDataTableScrollComponent
	extends ComponentBase
	implements OnInit
{
	@Input()
	set tableColumnConfig(data: MatDataTableModel[]) {
		if (!this.isDragging) {
			this.setTableColumnConfig(data);
		} else {
			this.isDragging = false;
		}
	}
	@Input() itemSize: number = 33.5;
	@Input() isLoading: boolean = false;
	@Input() loadDataFn$: (filter: any) => Observable<any>;
	@Input() searchFilter$: Observable<any>;
	@Input() CustomDataSource: any;
	@Output() sortColumn = new EventEmitter<any>();
	@Output() dragColumn = new EventEmitter<any>();
	@Output() resizeColumn = new EventEmitter<any>();

	displayedColumns: string[];
	tableColumns: MatDataTableModel[];
	dataSource: MatTableScrollDataSource;
	isDragging = false;
	containerHeight = null;
	lastTop = 0;

	@ViewChild(CdkVirtualScrollViewport, { static: true })
	viewPort: CdkVirtualScrollViewport;
	@ViewChild(MatSort, { static: true }) matSort: MatSort;

	offset: number;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calculateContainerHeight();
	}

	constructor(
		private routeService: RouteService,
		private router: Router,
		private docService: CrtDocumentService,
		private cd: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit() {
		this.dataSource = new this.CustomDataSource({
			searchFilter$: this.searchFilter$,
			loadFn$: this.loadFn$,
		});

		this.setDisplayedColumns();
		this.dataSource.attach(this.viewPort);
		this.initViewPortListeners();
		// this.initSorting();
	}

	loadFn$ = (filter: any) => {
		return this.loadDataFn$(filter).pipe(
			tap((x) => this.calculateContainerHeight(x)),
			take(1)
		);
	};

	initViewPortListeners() {
		super.subscribe(this.viewPort.elementScrolled(), () => {
			if (this.isDragging) {
				this.preventScrolling();
			}
		});
		super.subscribe(
			this.viewPort.scrolledIndexChange.pipe(
				map(() => this.viewPort.getOffsetToRenderedContentStart() * -1),
				distinctUntilChanged()
			),
			(offset) => (this.offset = offset)
		);
		super.subscribe(
			this.viewPort.renderedRangeStream,
			(range) => (this.offset = range.start * -this.itemSize)
		);
	}

	initSorting() {
		// this.dataSource.matTableDataSource.sort = this.matSort;

		const originalSortingDataAccessor =
			this.dataSource.matTableDataSource.sortingDataAccessor;

		this.dataSource.matTableDataSource.sortingDataAccessor = (
			data: any,
			sortHeaderId: string
		) => {
			return originalSortingDataAccessor(data, sortHeaderId);
		};
	}

	setTableColumnConfig(data: MatDataTableModel[]) {
		this.tableColumns = data;
		this.setDisplayedColumns();
	}

	setDisplayedColumns() {
		this.displayedColumns = (this.tableColumns || [])
			?.filter((x) => !!x?.visible)
			?.map((x) => x.columnId);
		this.stickyHeader();
	}

	getUpdatedColumns() {
		const visible =
			this.displayedColumns
				?.map((x) => this.tableColumns?.find((c) => c?.columnId === x))
				?.filter(Boolean) || [];
		const hidden = this.tableColumns.filter((x) => !x?.visible) || [];
		return [...visible, ...hidden];
	}

	drop(event: CdkDragDrop<string[]>) {
		const i = event.currentIndex;
		const col = this.tableColumns[i];
		if (!!col?.sticky) {
			return;
		}
		moveItemInArray(
			this.displayedColumns,
			event.previousIndex,
			event.currentIndex
		);

		this.tableColumns = this.getUpdatedColumns();
		this.dragColumn.emit(this.getUpdatedColumns());
		this.stickyHeader();
	}

	viewFile(data) {
		return of({
			documentId: data?.Id,
			name: data?.FileName || '',
		})
			.pipe(
				mergeMap((res) => {
					const ext = res?.name?.split('.')?.reverse()?.[0];
					if (!!ext && !!res?.documentId) {
						if (ext?.toLowerCase() === 'pdf') {
							return of(res).pipe(
								tap(() => {
									const pdfUrl = this.router.serializeUrl(
										this.router.createUrlTree(this.routeService.viewPdf(res))
									);
									window.open(pdfUrl, '_blank');
								}),
								take(1)
							);
						} else {
							return this.downloadDocumentFn$({
								documentId: res?.documentId,
								fileName: res?.name,
							});
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}

	downloadDocumentFn$ = (req: { documentId: number; fileName: string }) => {
		return this.docService.downloadLink(+req?.documentId).pipe(
			tap((fileUrl) => {
				if (!fileUrl) {
					return;
				}
				const a = document.createElement('a');
				a.href = fileUrl;
				a.setAttribute('download', req.fileName || '');
				a.click();
			}),
			take(1)
		);
	};

	stickyHeader() {
		setTimeout(() => {
			$('table thead th').css('top', `${this.offset}px`);
			$('table thead th').css('z-index', `100`);
			$('table thead tr').css('top', `${this.offset}px`);
			$('table thead tr').css('z-index', `100`);
		}, 10);
	}

	enter(event) {
		this.lastTop = $('.cdk-virtual-scroll-viewport').scrollTop();
		this.isDragging = true;
		this.stickyHeader();
	}

	trackByFn = (i: number) => i;

	sortData(event) {
		this.sortColumn.emit(event);
	}

	onResizeColumn(event) {
		this.resizeColumn.emit(event);
	}

	onTooltipChange(event) {
		this.cd.detectChanges();
	}

	showToolTip(cellText, col, element) {
		return !!col.showToolTip && !!cellText && this.isEllipsisActive(cellText)
			? element?.[col.columnId] || ''
			: '';
	}

	isEllipsisActive(e: HTMLElement): boolean {
		return !!e ? e.offsetWidth < e.scrollWidth : false;
	}

	preventScrolling() {
		$('.cdk-virtual-scroll-viewport').scrollTop(this.lastTop);
	}

	calculateContainerHeight(results?) {
		const datasource = this.dataSource as any;
		const data = results || this.dataSource?.matTableDataSource?.filteredData;
		const numberOfItems = data?.length || 0; // Total number of results
		const itemHeight = +this.itemSize; // This should be the height of your item in pixels
		const visibleItems = +datasource?._pageOffset; // The final number of items you want to keep visible
		const bufferHeight = 2;
		const headerHeight = itemHeight + bufferHeight;

		setTimeout(() => {
			this.viewPort.checkViewportSize();
		}, 300);

		let result;
		
		result = 'calc(100vh - 292px)';
		this.containerHeight = result;
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
