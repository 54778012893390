import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { Observable, forkJoin, of, EMPTY } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { RiskAnalysisMedicalState } from '../../../../../../shared/models/client-review-template/risk-analysis/medical/medical.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../client-review-template.query';
import { ClientReviewTemplateService } from '../../client-review-template.service';
import { ClientReviewTemplateStore } from '../../client-review-template.store';
import { MedicalQuery } from './medical.query';
import { MedicalStore } from './medical.store';
import { find, propEq } from 'ramda';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

@Injectable()
export class RiskAnalysisMedicalService
  extends ClientReviewTemplateService
  implements Resolve<boolean>
{
  formValue$ = this.medicalQuery.select((x) => x.formValue);
  riskMedical$ = this.query.riskAnalysisMedical$;
  riskMedicalList$ = this.medicalQuery.getAll();

  constructor(
    private api: ApiService,
    protected dropdownValueQuery: DropdownValueQuery,
    protected store: ClientReviewTemplateStore,
    protected query: ClientReviewTemplateQuery,
    protected customerService: CustomerService,
    protected businessService: BusinessService,
    protected medicalStore: MedicalStore,
    protected medicalQuery: MedicalQuery
  ) {
    super(dropdownValueQuery, store, query, customerService, businessService);
  }

  clearData() {
    applyTransaction(() => {
      this.medicalStore.reset();
    });
  }

  getMedical() {
    return this.api
      .get<[]>(
        `crt/risk-analysis/advice-process/${
          this.query.getValue().adviceProcessId
        }/${AdviceProcessSectionCodes.Medical}`
      )
      .pipe(
        map(
          (x) =>
            x.map(
              objectUtil.mapPascalCaseToCamelCase
            ) as RiskAnalysisMedicalState[]
        ),
        tap((x) =>
          applyTransaction(() => {
            this.medicalStore.set(x);
          })
        ),
        catchError(() => of(undefined))
      );
  }

  addMedical(medical: RiskAnalysisMedicalState) {
    const data = objectUtil.mapCamelCaseToPascalCase(medical);
    return this.api.post3<number>(`crt`, data).pipe(
      tap((x) =>
        applyTransaction(() => {
          this.medicalStore.update(medical.parentCRTId, {
            ...medical,
            cRTId: x,
          });
        })
      ),
      catchError(() => EMPTY)
    );
  }

  updateMedical(medical: RiskAnalysisMedicalState) {
    const data = objectUtil.mapCamelCaseToPascalCase(medical);
    return this.api.put<number>(`crt/${medical.cRTId}`, data).pipe(
      tap(() =>
        applyTransaction(() => {
          this.medicalStore.update(medical.parentCRTId, medical);
        })
      ),
      catchError(() => EMPTY)
    );
  }

  setActiveParentCRTId(parentCRTId: number) {
    this.medicalStore.setActiveParentCRTId(parentCRTId);
  }

  setFormValue(data: RiskAnalysisMedicalState) {
    this.setHasFormChanges(true);
    const list = this.medicalQuery.getAll();
    const isExisting = find(propEq('parentCRTId', data?.parentCRTId))(list);
    if (isExisting) {
      this.medicalStore.update(data?.parentCRTId, data);
    } else {
      this.medicalStore.add(data);
    }
  }

  resolve(): Observable<boolean> {
    const getMedical$ = !!this.medicalQuery.getHasCache()
      ? of(null)
      : this.getMedical();

    return forkJoin([getMedical$]).pipe(mapTo(true));
  }
}
