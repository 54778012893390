import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {
  @Input() routerLink: any[] | string;
  @Input() src: string;

	imgUrl: SafeUrl;

	constructor(private ds: DomSanitizer) {}

  ngOnInit() { 
		this.imgUrl = this.ds.bypassSecurityTrustUrl(this.src);
	}
}
