import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ServicingCalculatorSettingsService } from './servicing-calculators/state/servicing-calculators-settings.service';

@Injectable()
export class TlMoatSettingsResolver implements Resolve<boolean> {
	constructor(
		private servicingCalcService: ServicingCalculatorSettingsService
	) {}

	resolve(): Observable<boolean> {
		const getServicingCalculatorList$ =
			this.servicingCalcService.getServicingCalculatorList();

		return forkJoin([getServicingCalculatorList$]).pipe(mapTo(true));
	}
}
