import { map, mapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { TlMobileSettingsService } from './state/tl-mobile-settings.service';


@Injectable()
export class TlMobileSettingsResolver implements Resolve<boolean> {
	constructor(
		private tlmobileSettingsService: TlMobileSettingsService
	) {}

	resolve(): Observable<boolean> {
		const getIosVersion$ =
			this.tlmobileSettingsService.getIosAppVersion();

		return forkJoin([getIosVersion$]).pipe(map(()=>true));
	}
}
