import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	CrtFactFindPeople,
	CrtKiwiSaverFactFindPeopleState,
	CrtKiwisaverFactFindPeopleStore,
} from './crt-ks-fact-find-people.store';
import sort from 'fast-sort';

@Injectable({ providedIn: 'root' })
export class CrtKiwisaverFactFindPeopleQuery extends QueryEntity<CrtKiwiSaverFactFindPeopleState> {
	activePerson$ = this.selectActive() as Observable<CrtFactFindPeople>;

	activePersonIndex$ = this.activePerson$.pipe(
		map((person) =>
			this.getAll().findIndex((p) => p.customerID === person.customerID)
		)
	);

	people$ = this.selectAll().pipe(
		map((x) => x?.filter((p) => p?.relationship !== 'Child')),
		map((x) => sort(x)?.asc((s) => s.cRTId))
	);

	personCount$ = this.selectCount();

	constructor(protected store: CrtKiwisaverFactFindPeopleStore) {
		super(store);
	}
}
