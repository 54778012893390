import * as R from 'ramda';
import * as numeral from 'numeral';
import { CashDepositDetails } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/cash-deposit/state/cash-deposit.model';
import { ApplicationComputationsState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/review-application/state/review-application.model';
import { numUtil } from 'src/app/util/util';
import {
	appCashDepositMergeTag,
	appCashDepositMetaKey,
} from './cash-deposit.merge-tag';

export class AppCashDepositMergeTagMapper {
	public static formatCurrency(value) {
		return numUtil.isNumber(+value)
			? numUtil.formatWholeNumNoDecimalNoSymbol(+value)
			: '0';
	}

	public static getMergeTagValues(
		depositData: CashDepositDetails,
		computations: ApplicationComputationsState
	) {
		return appCashDepositMergeTag?.map((x) => {
			let value = '';
			if (x?.metaKey === appCashDepositMetaKey.totalContribution) {
				value = `${this.formatCurrency(
					R.negate(computations?.totalDeposit || 0)
				)}`;
			}
			if (x.metaKey === appCashDepositMetaKey.totalNetLoanProceeds) {
				value = this.formatCurrency(computations?.totalNetLoanProceeds);
			}
			if (x.metaKey === appCashDepositMetaKey.totalFundingRequired) {
				value = this.formatCurrency(computations?.totalFundingRequired);
			}
			if (x.metaKey === appCashDepositMetaKey.depositCash) {
				value = this.formatCurrency(depositData?.cash);
			}
			if (x.metaKey === appCashDepositMetaKey.depositGift) {
				value = this.formatCurrency(depositData?.gift);
			}
			if (x.metaKey === appCashDepositMetaKey.depositKiwiSaver) {
				value = this.formatCurrency(depositData?.kiwiSaver);
			}
			if (x.metaKey === appCashDepositMetaKey.depositExistingLoan) {
				value = this.formatCurrency(depositData?.cashFromExistingLoan);
			}
			if (x.metaKey === appCashDepositMetaKey.depositOtherAmount) {
				value = this.getDepositOtherAmount(depositData?.other || []);
			}
			if (x.metaKey === appCashDepositMetaKey.depositOtherType) {
				value = this.getDepositOtherField(depositData?.other || []);
			}
			return {
				...x,
				value,
			};
		});
	}

	public static hideDepositFields(content: string, data: CashDepositDetails) {
		const id = 'hideDepositFields';
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${id}">${content}</div>`);
		newHtml
			.querySelectorAll('tr[class^="APPLICATION_DEPOSIT"]')
			.forEach((e: HTMLElement) => {
				const classes = Array.from(e?.classList);
				let display = '';

				if (
					(classes?.includes(appCashDepositMetaKey?.depositCash) &&
						+data?.cash <= 0) ||
					(classes?.includes(appCashDepositMetaKey?.depositGift) &&
						+data?.gift <= 0) ||
					(classes?.includes(appCashDepositMetaKey?.depositKiwiSaver) &&
						+data?.kiwiSaver <= 0) ||
					(classes?.includes(appCashDepositMetaKey?.depositExistingLoan) &&
						+data?.cashFromExistingLoan <= 0)
				) {
					display = 'none';
				}
				e.style.display = display;
			});
		return newHtml.querySelector(`#${id}`).innerHTML || '';
	}

	public static getDepositOtherAmount(data) {
		return data?.map((x) => this.formatCurrency(x?.otherValue)) ?? [];
	}

	public static getDepositOtherField(data) {
		return data?.map((x) => x?.otherField || '') ?? [];
	}
}
