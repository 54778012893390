import { Component } from '@angular/core';
import { UserQuery } from 'src/app/domain/user/user.query';

@Component({
	selector: 'app-kiwisaver-settings',
	templateUrl: './kiwisaver-settings.component.html',
})
export class KiwiSaverSettingsComponent {
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;

	constructor(private userQuery: UserQuery) {}
}
