import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { UserQuery } from 'src/app/domain/user/user.query';
import { AdviceSummarySettingsState } from './state/advice-summary-settings.model';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { AdviceSummarySettingsService } from './state/advice-summary-settings.service';
import { KiwiMergeTagsCrtSettingsService } from '../merge-tags/state/kiwi-merge-tags-crt-settings.service';
import { MergeTagsMapper } from '../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import {
	kiwisaverAdviseSummaryMT,
} from '../../../shared/models/client-review-template/merge-tags/crt-kiwisaver/advice-summary/advice-summary.merge-tag';
import { koatSosMergeTags } from 'src/app/shared/models/client-review-template/merge-tags/crt-kiwisaver/scope-of-service/sos.merge-tag';
import { AdviceSummaryEmailSettingsService } from './advice-summary-email-settings/state/advice-summary-email-settings.service';


@Component({
	selector: 'app-advice-summary-settings',
	templateUrl: './advice-summary-settings.component.html',
	styleUrls: ['./advice-summary-settings.component.scss'],
})
export class AdviceSummarySettingsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;
	template: string;
	data: AdviceSummarySettingsState;
	mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
	adviceSummaryEmailSettings$ = this.adviceSummaryEmailSettingsService.adviceSummaryEmailSettings$;

	constructor(
		private adviceSummarySettingsService: AdviceSummarySettingsService,
		private mtService: KiwiMergeTagsCrtSettingsService,
		private adviceSummaryEmailSettingsService: AdviceSummaryEmailSettingsService,
		private userQuery: UserQuery
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		combineLatest([
			this.adviceSummarySettingsService.adviceSummarySettings$,
			this.mtService.mergeTags$,
		])
			.pipe(
				filter(([adviceSummary, mt]) => !!adviceSummary && !!mt),
				tap(([adviceSummary]) => {
					this.data = adviceSummary;
					this.template = adviceSummary?.template;
				}),
				map(([, mt]) =>
					MergeTagsMapper.mapMergeTags(
						[...mt, ...koatSosMergeTags, ...kiwisaverAdviseSummaryMT],
						true
					)
				),
				tap((mt) => this.mergeTags$.next(mt)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
