import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailTemplateModalComponent } from './email-template-modal.component';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailTemplateModalFormComponent } from './email-template-modal-form/email-template-modal-form.component';

@NgModule({
	declarations: [EmailTemplateModalComponent, EmailTemplateModalFormComponent],
	exports: [EmailTemplateModalComponent],
	imports: [
		CommonModule,
		SharedModule,
		// TODO: remove this import when all components are modularized
		ReactiveFormsModule,
		FormsModule,
	],
})
export class EmailTemplateModalModule {}
