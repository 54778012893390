<ng-template #popTemplate>
	<form [formGroup]="formExport" class="dateRange">
		<div class="form-group mb-2">
			<app-date-input
				#minDateInput
				formControlName="DateMin"
				textboxId="dateMinId"
				textboxClass="theme-crm-field-disabled"
				databoxClass="tap-date-range-datepicker"
				placeholder="Min"
				style="flex: 1"
				class="popover-width"
			>
			</app-date-input>
		</div>
		<div class="form-group mb-3">
			<app-date-input
				#maxDateInput
				textboxId="dateMaxId"
				formControlName="DateMax"
				textboxClass="theme-crm-field-disabled"
				databoxClass="tap-date-range-datepicker"
				placeholder="Max"
				style="flex: 1"
			>
			</app-date-input>
		</div>
		<div class="form-group mb-3">
			<div class="form-row">
				<div class="col-auto">
					<label for="crtNotesSelectFileType" class="mr-2">Type</label>
				</div>
				<div class="col">
					<span class="custom-dropdown w-100">
						<select
							formControlName="ReportType"
							class="form-control"
							id="crtNotesSelectFileType"
						>
							<option value="csv">.csv</option>
							<option value="docx">.docx</option>
							<option value="txt">.txt</option>
						</select>
					</span>
				</div>
			</div>
		</div>
		<div class="form-group d-flex justify-content-center">
			<div class="pr-2">
				<button
					id="exportNotesBtn"
					type="button"
					class="tap-btn tap-btn--default"
					[disabled]="formExport.invalid"
					(click)="export()"
				>
					Export
				</button>
			</div>
			<div>
				<button
					id="cancelNotesBtn"
					type="button"
					class="tap-btn tap-btn--default"
					(click)="close()"
				>
					Cancel
				</button>
			</div>
		</div>
	</form>
</ng-template>

<div
	#crtNotesElement
	class="crt-notes-timeline"
	[class.disabled]="isSaving$ | async"
>
	<div class="-timeline__header clearfix mb-3">
		<div class="clearfix row align-items-center mb-2">
			<div class="col crt-notes-timeline__header__title">Notes</div>
			<span class="custom-dropdown crt-type-dropdown">
				<select
					class="form-control"
					id="crtNotesSelectType"
					(change)="selectType($event.target.value)"
				>
					<option value="" [selected]="(activeType$ | async) === ''"></option>
					<option
						*ngFor="let d of crtTypes"
						[value]="d.value"
						[selected]="(activeType$ | async) === d.value"
					>
						{{ d.display }}
					</option>
				</select>
			</span>

			<span
				class="col-auto crt-notes-timeline__header__icon md-20 tap-text-color-h6 p-1 pl-2 client-referral-icon"
				id="crtOpenReferralPopUp"
				(click)="openClientReferralPopUp()"
			>
				<svg
					version="1.1"
					width="20"
					height="18"
					viewBox="0 0 173 191"
					xml:space="preserve"
					id="svg4"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:svg="http://www.w3.org/2000/svg"
				>
					<defs id="defs1" />
					<g
						style="
							opacity: 1;
							fill: none;
							fill-rule: nonzero;
							stroke: none;
							stroke-width: 0;
							stroke-linecap: butt;
							stroke-linejoin: miter;
							stroke-miterlimit: 10;
							stroke-dasharray: none;
						"
						transform="matrix(2.81,0,0,2.81,1.4065934,1.4065934)"
						id="g4"
					>
						<path
							d="m 28.478591,34.933056 c -9.589,0 -17.39,-7.801 -17.39,-17.39 0,-9.5890008 7.801,-17.39000095 17.39,-17.39000095 9.589,0 17.39,7.80100015 17.39,17.39000095 0,9.589 -7.801,17.39 -17.39,17.39 z m 9.263251,-9.467704 c 0,5.729 -14.992251,2.467704 -9.263251,2.467704 5.729,0 4.663629,-4.708877 10.39,-10.39 4.917281,-4.878427 -0.459152,1.027876 -1.126749,7.922296 z"
							style="
								opacity: 1;
								fill: currentColor;
								fill-rule: nonzero;
								stroke: none;
								stroke-width: 1;
								stroke-linecap: butt;
								stroke-linejoin: miter;
								stroke-miterlimit: 10;
								stroke-dasharray: none;
							"
							stroke-linecap="round"
							id="path1"
						/>
						<path
							id="path2"
							style="
								opacity: 1;
								fill: currentColor;
								fill-rule: nonzero;
								stroke: none;
								stroke-width: 1;
								stroke-linecap: butt;
								stroke-linejoin: miter;
								stroke-miterlimit: 10;
								stroke-dasharray: none;
							"
							d="M 36.406332,39.57061 H 20.765346 c -11.1399885,0 -20.20267981,9.063386 -20.20267981,20.203375 v 3.391209 c 0,1.932998 1.56664051,3.500334 3.49963861,3.500334 1.932998,0 3.500334,-1.567336 3.500334,-3.500334 V 59.77468 c 0,-7.279993 5.9227142,-13.202708 13.2027072,-13.202708 h 15.710492 z"
						/>

						<path
							d="m 58.487502,52.155636 -8.515,-8.515 c -1.367,-1.367 -3.582,-1.367 -4.949,0 -1.367,1.366 -1.367,3.583 0,4.949 l 2.54,2.54 -9.058674,-0.149662 c -1.932736,-0.03193 -3.5,1.567 -3.5,3.5 0,1.933 1.567264,3.468068 3.5,3.5 l 9.058674,0.149662 -2.541,2.541 c -1.367,1.367 -1.367,3.583 0,4.949 0.684,0.684 1.579,1.025 2.475,1.025 0.896,0 1.792,-0.342 2.475,-1.025 l 8.515,-8.516 c 1.367,-1.366 1.367,-3.582 0,-4.948 z"
							style="
								opacity: 1;
								fill: currentColor;
								fill-rule: nonzero;
								stroke: none;
								stroke-width: 1;
								stroke-linecap: butt;
								stroke-linejoin: miter;
								stroke-miterlimit: 10;
								stroke-dasharray: none;
							"
							stroke-linecap="round"
							id="path4"
						/>
					</g>
				</svg>
			</span>

			<ng-container *appRestrictTo="['FAN', 'FALN']">
				<span
					class="col-auto crt-notes-timeline__header__icon material-icons md-20 tap-text-color-h6 p-1"
					id="crtNotesTimelineAddNoteBtn"
					(click)="addNote()"
					>note_add</span
				>
			</ng-container>
			<ng-container *appRestrictTo="['FACA', 'FALA']">
				<span
					class="col-auto crt-notes-timeline__header__icon material-icons md-20 tap-text-color-h6 p-1"
					id="crtNotesTimelineAddActivityBtn"
					(click)="addActivity()"
					>calendar_today</span
				>
			</ng-container>
			<button
				id="selectDateRangeBtn"
				type="button"
				title="Export"
				class="icon-btn h-auto w-auto p-1"
				[placement]="'bottom left'"
				[popover]="popTemplate"
				popoverTitle="Select Date Range"
			>
				<i class="fas fa-download fa-14 tap-text-color-h6"></i>
			</button>
		</div>
	</div>

	<div class="crt-notes-timeline__add-note clearfix mb-3">
		<form novalidate [formGroup]="formNote" (ngSubmit)="saveNote()">
			<ng-container *ngIf="isAddNote">
				<div class="form-group mb-2">
					<textarea
						id="noteId"
						class="form-control theme-crm-field-disabled"
						formControlName="note"
						rows="5"
					></textarea>
				</div>

				<div class="form-group mb-2 d-flex justify-content-end">
					<app-manual-loader-mini
						[isLoading]="isAddingNote$ | async"
						class="p-0"
					></app-manual-loader-mini>
					<ng-container *ngIf="!(isAddingNote$ | async)">
						<button
							id="saveNoteBtn"
							class="tap-btn tap-btn--default mr-2"
							type="submit"
							[disabled]="!formNote.valid || !formNote?.get('note')?.value?.trim()"
						>
							Save
						</button>
						<button
							id="cancelAddNoteBtn"
							class="tap-btn tap-btn--default"
							type="reset"
							(click)="cancelAddNote()"
						>
							Cancel
						</button>
					</ng-container>
				</div>
			</ng-container>

			<div class="form-group mb-2 text-right" [class.d-none]="!isAddNote">
				<label>Posting as {{ currentUserName$ | async }}</label>
			</div>
		</form>
	</div>

	<div class="crt-notes-timeline__container-flow clearfix">
		<div class="crt-notes-timeline__container-flow__list">
			<app-manual-loader
				[isLoading]="isloadingNotes$ | async"
			></app-manual-loader>
			<div class="crt-notes-timeline__vert-line"></div>
			<div
				class="crt-notes-timeline__container-flow__list__item d-flex"
				*ngFor="
          let n of filteredCRTNotes$ | async;
          trackBy: trackByNotesFn;
          let i = index
        "
				#noteH
			>
				<div class="crt-notes-timeline__container-flow__list__item__header">
					<div class="clearfix">Added to {{ getSection(n.type) }}</div>
				</div>
				<div class="crt-notes-timeline__container-flow__list__item__icon">
					<p class="transparent-circle"></p>
				</div>
				<div
					class="crt-notes-timeline__container-flow__list__item__info col"
					[class.has-overlay]="
            (n?.notes && n.notes.length > 190) || hasShowMoreBtn(noteH, 72)
          "
					[class.h-auto]="existingActiveShowMoreIds(n.cRTNotesId)"
				>
					<app-manual-loader
						[isLoading]="
              (isSaving$ | async) && completedActiveId === n.cRTNotesId
            "
						class="p-0"
					>
					</app-manual-loader>
					<!-- Restriction for current client -->
					<div class="btn-group dropdown" dropdown>
						<button
							id="noteDropdownBtn"
							class="tap-btn tap-btn-outline p-0"
							dropdownToggle
						>
							<i class="material-icons">more_horiz</i>
						</button>
						<ul class="dropdown-menu" *dropdownMenu>
							<a
								id="editNoteBtn"
								class="dropdown-item px-2 py-1"
								(click)="
									editNote(n); completedActiveId = n.cRTNotesId
								"
								>EDIT</a
							>
							<ng-container *appRestrictTo="['FDN']">
								<a
									id="deleteNoteBtn"
									class="dropdown-item px-2 py-1"
									(click)="
                    confirmDeleteNote(n); completedActiveId = n.cRTNotesId
                  "
									>DELETE</a
								>
							</ng-container>
						</ul>
					</div>
					<div class="item-wrapper">
						<!-- Please do not format this. It will print any new line, spaces, and tabs -->
						<div class="note-details mt-1 text-break">
							{{ n.notes | slice : 0 : (existingActiveShowMoreIds(n.cRTNotesId)
							? n.notes.length : 190) }}
						</div>
					</div>
				</div>
				<ng-container
					*ngIf="
            (n?.notes && n.notes.length > 190) || hasShowMoreBtn(noteH, 72)
          "
				>
					<div class="crt-notes-timeline__container-flow__list__item__more">
						<span
							class="font-weight-bold clickable float-right"
							(click)="setActiveShowMoreIds(n.cRTNotesId)"
						>
							<span class="material-icons"
								>{{ existingActiveShowMoreIds(n.cRTNotesId) ? "expand_less" :
								"expand_more" }}</span
							>
						</span>
					</div>
				</ng-container>
				<div class="crt-notes-timeline__container-flow__list__item__footer">
					<div class="d-flex justify-content-between">
						<div class="text-muted text-truncate">
							{{ formatToDisplayDatetime(n.createDateTime) }}
						</div>
						<div class="text-muted text-truncate">{{ n.staffName }}</div>
					</div>
				</div>
			</div>
			<div
				class="crt-notes-timeline__container-flow__list__item d-flex mb-3"
			></div>
		</div>
	</div>

	<ng-container *ngIf="isLastPage && count > 10">
		<button
			id="backToRecentNotesBtn"
			(click)="backToRecentNotes()"
			class="tap-btn tap-btn--default"
			type="button"
		>
			Back to recent notes
		</button>
	</ng-container>
</div>
