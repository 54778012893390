import { FormControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { clone, equals } from 'ramda';

/**
 * coerce value treat undefined, null and empty string as equal when comparing
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const coerceValue = (value: any) => {
  if (value === undefined || value === '' || value === null) {
    return null;
  }
  return value;
};

// compare two object
// NOTE: this function only compare value of sourceObj property to targetObj
export function isDirty<T extends object>(sourceObj: T, targetObj: T, excludedProperties?: string[]): boolean {
  if (!sourceObj && !targetObj) {
    return false;
  }
  const rawDataValueKeys = Object.keys(sourceObj);
  for (let i = 0, len = rawDataValueKeys.length; i < len; i++) {
    const key = rawDataValueKeys[i];
		if (excludedProperties?.includes(key)) {
			continue;
		}
    // @ts-ignore-next
    const isArray = Array.isArray(sourceObj[key]);

    // @ts-ignore-next
    if (isArray && !equals(sourceObj[key], targetObj?.[key])) {
      return true;
    }

    if (
      !isArray &&
      // @ts-ignore-next
      coerceValue(sourceObj[key]) !== coerceValue(targetObj![key])
    ) {
      return true;
    }
  }
  return false;
}

export class AppFormGroup<T extends Object> extends UntypedFormGroup {

  originalData?: T;

	exlucdedPropertiesInDirtyChecking: string[];

  patchValue(
    data: T,
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    super.patchValue(data, options);
    this.originalData = { ...this.getRawValue(), ...data };
  }

	setOriginalData(data: T): void {
		this.originalData = data;
	}

  // check weather the form has modified properties
  isDirty(): boolean {
    return isDirty(this.getRawValue(), this.originalData as any, this.exlucdedPropertiesInDirtyChecking);
  }

	setExcludedPropertyDirtyChecking(properties: string[]): void {
		this.exlucdedPropertiesInDirtyChecking = properties;
	}

	resetToOriginalData(): void {
		this.patchValue(this.originalData);
	}

  /**
   * update original data to the current FormGroup rawValue
   */
  updateOriginalData(): void {
    this.originalData = clone(this.getRawValue());
  }

  resetData(): void {
    this.originalData = {} as T;
    this.patchValue({} as T);
  }

  /**
   * @returns - get merged data of originalData and FormGroup rawValue
   */
  getUpdatedValue(): T {
    return { ...this.originalData, ...this.getRawValue() };
  }

	static clearFormArray (formArray: UntypedFormArray): void {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
	};

	static dataToFormGroup<T>(data: T): AppFormGroup<T> {
		const object: { [key: string]: FormControl } = {};
		Object.keys(data).forEach((key) => {
			object[key] = new FormControl(data?.[key] ?? null);
		})
		return new AppFormGroup(object);
	}

}

