import { Component, OnInit, ViewChild } from '@angular/core';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { DeclarationTemplateSettingsService } from './state/declaration-template-settings.service';
import { KiwiMergeTagsCrtSettingsService } from '@modules/kiwisaver-settings/merge-tags/state/kiwi-merge-tags-crt-settings.service';
import { WysiwygComponent } from '@shared/wysiwyg/wysiwyg.component';
import { getContentWithMergeTags } from '@shared/converter/content-merge-tags';
import { combineLatest, iif, of } from 'rxjs';
import { concatMap, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { convertUtil, objectUtil } from '@util/util';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { DeclarationTemplateDocumentMapper } from '@modules/kiwisaver-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.mapper';
import { SettingsTypes } from '@modules/kiwisaver-settings/state/kiwisaver-settings.model';
import * as R from 'ramda';

@Component({
  selector: 'app-declaration-template-settings',
  templateUrl: './declaration-template-settings.component.html',
  styleUrls: ['./declaration-template-settings.component.scss']
})
export class DeclarationTemplateSettingsComponent implements OnInit {
  template: string;
  mergeTag: MergeTagState[];
  data;
  declaration$ = this.dService.declarationDocuments$;
  isLoading = false;
  isPreview = false;
  customButtons: [];
  currentTemplate = '<p></p>';
  tempData: string;
  templateId: number;
  shortcodes: object;

  @ViewChild('contentEditor') editor: WysiwygComponent;

   constructor(
    private dService: DeclarationTemplateSettingsService,
    private ktService: KiwiMergeTagsCrtSettingsService
  ) {}


  ngOnInit(): void {
    this.prepData();
  }


  prepData() {
    combineLatest([
      this.dService.declarationDocuments$,
      this.ktService.mergeTags$,
    ])
      .pipe(
        filter(([data, mt]) => !!mt),
        tap(([data, mt]) => (this.data = data)),
        tap(([data, mt]) => (this.templateId = +data?.template)),
        tap(([data, mt]) => this.setMergeTags(mt)),
        concatMap(([data, mt]) =>
          iif(
            () =>	this.hasTemplate(data?.template),
            this.dService.getDeclarationDocumentFile(+data?.template),
            of(null)
          )
        ),
        map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
        concatMap((res) =>
          iif(
            () => res?.documentLink,
            this.dService.getDocumentFromURL(res?.documentLink),
            of(null)
          )
        ),
        tap((data) => {
          if (data) {
            this.currentTemplate = data;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  setMergeTags(mergeTags) {
    of(mergeTags)
      .pipe(
        map((mt) => MergeTagsMapper.mapMergeTags(mt, true)),
        tap((mt) => (this.mergeTag = mt)),
        map((mt) => MergeTagsMapper.mapTagsForWysiwyg(mt)),
        tap((mt) => (this.shortcodes = mt)),
        take(1)
      )
      .subscribe();
  }

  save() {
    this.isLoading = true;
    of(this.editor?.content)
      .pipe(
				map((content) => content || '<p></p>'),
        mergeMap((currentTemplate) =>
          convertUtil.convertToBase64(currentTemplate)
        ),
        withLatestFrom(of(this.templateId)),
        map(([currentTemplate, templateId]) =>
				DeclarationTemplateDocumentMapper.mapDocumentUpload(
            currentTemplate,
            templateId
          )
        ),
        concatMap((data) =>
          iif(
            () => this.hasTemplate(data?.documentId),
            this.dService.updateFileUploadDD(data),
            this.dService.newFileUploadDD(data),
          )
        ),
        tap((data) => {
          this.templateId =	this.hasTemplate(this.templateId)
              ? +this.templateId
              : +data;
        }),
        withLatestFrom(of(this.templateId)),
        concatMap(([data, tmplId]) =>
          iif(
            () => this.hasTemplate(tmplId),
            of(tmplId),
            this.dService.updateDeclaration({
              template: data,
              referenceId: 0,
              type: SettingsTypes.Declaration,
              mergeTag: [],
              templateID: '',
              unsubscribeGroupID: '',
            }),
          )
        ),
        finalize(() => (this.isLoading = false)),
        take(1)
      )
      .subscribe();
  }

	hasTemplate = (template) =>
		R.complement(R.either(R.isNil, R.isEmpty))(template) &&
		!isNaN(+template) &&
		+template > 0;

  backToEdit() {
    this.currentTemplate = this.tempData;
    this.isPreview = false;
    $(
      'app-declaration-settings .fr-element.fr-view, app-declaration-settings .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css('pointer-events', 'unset');
    $(
      'app-declaration-settings .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css({ opacity: 1 });
  }

  showPreview() {
    this.isPreview = true;
    this.tempData = this.editor?.content;
    const getConvertedContent = getContentWithMergeTags(
      this.editor.content,
      this.mergeTag
    );
    $(
      'app-declaration-settings .fr-element.fr-view, app-declaration-settings .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css('pointer-events', 'none');
    $(
      'app-declaration-settings .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css({ opacity: 0.6 });
    this.currentTemplate = getConvertedContent;
  }
}
