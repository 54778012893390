import {
	Component,
	OnInit,
	AfterViewInit,
	OnChanges,
	OnDestroy,
	Input,
	ViewChild,
	ElementRef,
	Optional,
} from '@angular/core';
import { Subject, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Spinner } from 'spin.js';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { LoaderService, ResultLoaderService, ActionLoaderService } from 'src/app/core/loader/loader.service';
import { ControlLoaderService } from 'src/app/core/services/control-loader.service';

@Component({
	selector: 'app-manual-loader-mini',
	template: `<div class="loader-container" #target>&nbsp;</div>`,
	styleUrls: ['./manual-loader-mini.component.scss'],
})
export class ManualLoaderMiniComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	onDestroy$ = new Subject<void>();
	_isLoading: ReplaySubject<boolean> = new ReplaySubject(1);
	isLoading$ = this._isLoading.pipe(distinctUntilChanged());
	@Input()
	set isLoading(value: boolean) {
		this._isLoading.next(value);
	}
	spinner: Spinner;
	private _opts: any;
	@Input()
	set opts(value) {
		Object.assign(this._opts, value);
	}
	@ViewChild('target', { static: true }) spinnerTarget: ElementRef;

	defaultColor: string;
	subs: Subscription[];

	constructor(
		@Optional() private ls: LoaderService,
		@Optional() private cls: ControlLoaderService,
		@Optional() private rls: ResultLoaderService,
		@Optional() private als: ActionLoaderService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		this.setupProps();
	}
	setupProps(): void {
		this.defaultColor = '#00263e';
		this._opts = {
			lines: 8, // The number of lines to draw
			length: 46, // The length of each line
			width: 31, // The line thickness
			radius: 41, // The radius of the inner circle
			scale: 0.1, // Scales overall size of the spinner
			corners: 1, // Corner roundness (0..1)
			color: this.defaultColor, // CSS color or array of colors
			fadeColor: 'transparent', // CSS color or array of colors
			opacity: 0, // Opacity of the lines
			rotate: 0, // The rotation offset
			animation: 'spinner-line-fade-quick',
			direction: 1, // 1: clockwise, -1: counterclockwise
			speed: 1.3, // Rounds per second
			trail: 100, // Afterglow percentage
			fps: 20, // Frames per second when using setTimeout() as a fallback in IE 9
			zIndex: 1, // The z-index (defaults to 2000000000)
			className: 'spinner', // The CSS class to assign to the spinner
			// top: '50%', // Top position relative to parent
			// left: "12.5%" // Left position relative to parent
			// position: 'absolute' // Element positioning
		};
		this.spinner = new Spinner(this._opts);
		this.subs = [];
		this.isLoading = false;
	}
	setupSubs(): void {
		this.businessConfigQuery.themeConfig$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
			this.opts = { color: x ? x.primarycolor : this.defaultColor };
		});

		this.isLoading$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
			if (![undefined, null]?.some(y => y === this.spinner)) {
				this.spinner.stop();
			}
			if (x) {
				this.spinner = new Spinner(this._opts);
				this.spinner.spin(this.spinnerTarget.nativeElement);
			}
		});
	}

	ngOnInit() {}
	ngOnChanges(): void {}
	ngAfterViewInit(): void {
		this.setupSubs();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
		this.onDestroy$ = undefined;
	}
}
