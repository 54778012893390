import { RentalIncomeState, RentalIncomeObjState } from './rental-income.model';
import { FactFindComputationState } from './factfind-computation.model';
import { AdviceProcessSectionCodes } from '../../advice-process/advice-process.model';
import { PropertyState } from '../assets-liabilities/property.model';

export class RentalIncomeMapper {
  /** Mapping for view */
  public static mapRentalIncome(
		data: RentalIncomeObjState,
		properties: PropertyState[]
	): RentalIncomeState[] {
		const getAddress = (id: number) => {
			return properties?.find((item) => +item.cRTId === +id )?.propertyAddress;
		}
  return data?.netRentalIncomeList?.map(
      ({ propertyAsset, netRentalIncome, cRTId, adviceProcessId}) => ({
        cRTId,
        adviceProcessId,
        propertyAsset,
        netRentalIncome,
        btnSaveRental: false,
        btnEditRental: true,
				propertyAddress: getAddress(propertyAsset) || ''
      })
    );
  }

  public static mapGross(data: RentalIncomeObjState) {
    return +data.totalNetRentalIncome;
  }

  /**
   * Map to update
   */
  public static mapToUpsert(
    data: RentalIncomeState,
    cid: number,
    adviceProcessId: number,
    tempFactFind?: FactFindComputationState
  ): RentalIncomeState {
    return {
      cRTId: cid,
      propertyAsset: +data.propertyAsset,
      netRentalIncome: +data.netRentalIncome,
      adviceProcessId,
      status: 1,
      sectionCode: AdviceProcessSectionCodes.RentalIncome,
      factFindComputation: {
        ...tempFactFind,
        sectionCode: AdviceProcessSectionCodes.FFComputation,
      },
    };
  }
}
