import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KiwiSaverSettingsComponent } from './kiwisaver-settings.component';
import { KiwiSaverSettingsResolver } from './kiwisaver-settings.resolver';
import { KiwiSaverSettingsRoutingModule } from './kiwisaver-settings-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { KiwiIntroSettingsComponent } from './intro-settings/kiwi-intro-settings.component';
import { KiwiIntroSettingsService } from './intro-settings/state/kiwi-intro-settings.service';
import { KiwiSaverSettingsQuery } from './state/kiwisaver-settings.query';
import { KiwiSaverSettingsStore } from './state/kiwisaver-settings.store';
import { KiwiSosServiceSettingsComponent } from './scope-of-service-settings/sos-service-settings/kiwi-sos-service-settings.component';
import { KiwiSosTemplateSettingsComponent } from './scope-of-service-settings/sos-template-settings/kiwi-sos-template-settings.component';
import { KiwiScopeOfServiceSettingsComponent } from './scope-of-service-settings/kiwi-scope-of-service-settings.component';
import { KiwiMergeTagsComponent } from './merge-tags/kiwi-merge-tags.component';
import { KiwiScopeOfServiceService } from './scope-of-service-settings/state/kiwi-scope-of-service.service';
import { KiwiMergeTagsCrtSettingsService } from './merge-tags/state/kiwi-merge-tags-crt-settings.service';
import { KiwiScopeOfServiceSettingsService } from './scope-of-service-settings/state/kiwi-scope-of-service-settings.service';
import { KiwiSosTemplateSettingsService } from './scope-of-service-settings/sos-template-settings/state/kiwi-sos-template-settings.service';
import { KiwiFactFindSettingsComponent } from './fact-find-settings/fact-find-settings.component';
import { FactFindSubpagesSettingsComponent } from './fact-find-settings/subpages/fact-find-subpages-settings.component';
import { FactFindSubpagesSettingsService } from './fact-find-settings/subpages/state/fact-find-subpages-settings.service';
import { FactFindRiskProfileSettingsComponent } from './fact-find-settings/risk-profile/fact-find-risk-profile-settings.component';
import { QuestionFormComponent } from './fact-find-settings/risk-profile/question-form/question-form.component';
import { OutcomeFormComponent } from './fact-find-settings/risk-profile/outcome-form/outcome-form.component';
import { FactFindRiskProfileSettingsService } from './fact-find-settings/risk-profile/state/fact-find-risk-profile-settings.service';
import { AdviceSummarySettingsComponent } from './advice-summary-settings/advice-summary-settings.component';
import { AdviceSummaryTemplateComponent } from './advice-summary-settings/advice-summary-template/advice-summary-template.component';
import { AdviceSummarySettingsService } from './advice-summary-settings/state/advice-summary-settings.service';
import { AdviceSummaryEmailSettingsComponent } from './advice-summary-settings/advice-summary-email-settings/advice-summary-email-settings.component';
import { AdviceSummaryEmailSettingsService } from './advice-summary-settings/advice-summary-email-settings/state/advice-summary-email-settings.service';
import { AdviceSummaryMergeTagsWordingComponent } from './advice-summary-settings/merge-tags-wording/advice-summary-merge-tags-wording.component';
import { DocumentListComponent } from './advice-summary-settings/document-list/document-list.component';
import { DocumentListFormComponent } from './advice-summary-settings/document-list/document-list-form/document-list-form.component';
import { DocumentListService } from './advice-summary-settings/document-list/state/document-list.service';
import { EndProcessComponent } from './end-process/end-process.component';
import { EndProcessEmailSettingsComponent } from './end-process/end-process-email-settings/end-process-email-settings.component';
import { EndProcessEmailSettingsService } from './end-process/state/end-process.service';
import { ProvidersSettingsComponent } from './fact-find-settings/providers/providers-settings.component';
import { ProviderFormComponent } from './fact-find-settings/providers/provider-form/provider-form.component';
import { ProviderSettingsService } from './fact-find-settings/providers/state/providers-settings.service';
import { DeclarationSettingsComponent } from './declaration-settings/declaration-settings.component';
import { DeclarationEmailSettingsComponent } from './declaration-settings/declaration-email-settings/declaration-email-settings.component';
import { DeclarationEmailSettingsService } from './declaration-settings/declaration-email-settings/state/declaration-email-settings.service';
import { DeclarationTemplateSettingsComponent } from './declaration-settings/declaration-template-settings/declaration-template-settings.component';
import { DeclarationTemplateSettingsService } from '@modules/kiwisaver-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.service';



@NgModule({
	declarations: [
		KiwiSaverSettingsComponent,
		KiwiIntroSettingsComponent,
		KiwiSosServiceSettingsComponent,
		KiwiSosTemplateSettingsComponent,
		KiwiScopeOfServiceSettingsComponent,
		KiwiMergeTagsComponent,
		KiwiFactFindSettingsComponent,
		FactFindSubpagesSettingsComponent,
		FactFindRiskProfileSettingsComponent,
		QuestionFormComponent,
		OutcomeFormComponent,
		AdviceSummarySettingsComponent,
		AdviceSummaryTemplateComponent,
		AdviceSummaryEmailSettingsComponent,
		AdviceSummaryMergeTagsWordingComponent,
		DocumentListComponent,
		DocumentListFormComponent,
		EndProcessComponent,
		EndProcessEmailSettingsComponent,
		ProvidersSettingsComponent,
		ProviderFormComponent,
		DeclarationSettingsComponent,
		DeclarationEmailSettingsComponent,
		DeclarationTemplateSettingsComponent,
	],
	imports: [CommonModule, KiwiSaverSettingsRoutingModule, SharedModule],
	providers: [
		KiwiScopeOfServiceSettingsService,
		KiwiSosTemplateSettingsService,
		KiwiSaverSettingsQuery,
		KiwiSaverSettingsStore,
		KiwiSaverSettingsResolver,
		KiwiIntroSettingsService,
		KiwiScopeOfServiceService,
		KiwiMergeTagsCrtSettingsService,
		FactFindSubpagesSettingsService,
		FactFindRiskProfileSettingsService,
		AdviceSummarySettingsService,
		AdviceSummaryEmailSettingsService,
		DocumentListService,
		EndProcessEmailSettingsService,
		ProviderSettingsService,
    DeclarationEmailSettingsService,
		DeclarationTemplateSettingsService
	],
})
export class KiwiSaverSettingsModule {}
