import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { environment } from '@environment';
import { getEmailContentNoHtml } from '@shared/converter/content-email';
import { logMessage } from '@shared/error-message/error-message';
import { UploadModalComponent } from '@shared/modal/upload-modal/upload-modal.component';
import { DocumentGroupState } from '@shared/models/documents/document-group.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { Attachment } from '@shared/models/_general/attachment.model';
import { LinkDocumentComponent } from '@shared/services/link-document/link-document.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { iif, Observable, of, Subject, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	mtMergeContent,
	normalizeHTMLSymbols,
	removeMtWrappers,
} from '../../../../../../../shared/converter/content-merge-tags';
import { EmailLinksMT } from '../../../../../../../shared/models/client-review-template/merge-tags/email-settings/email-links';
import { MergeTagsMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from '../../../../../../../shared/wysiwyg/wysiwyg.component';
import {
	convertUtil,
	fileUtil,
	objectUtil,
	strUtil,
} from '../../../../../../../util/util';
import { CrtDocumentService } from '../../../service/crt-document.service';
import { CrtInviteCpModel } from '../state/crt-invite-cp.model';
import { inviteFooter, inviteHeader } from '../templates/invite.template';
import { ClientProfileQuery } from '@modules/crm/client-profile/states/client-profile.query';
import * as R from 'ramda';
import { CrtInviteCpService } from '../state/crt-invite-cp.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-crt-invite-cp-modal',
	templateUrl: './crt-invite-cp-modal.component.html',
	styleUrls: ['./crt-invite-cp-modal.component.scss'],
})
export class CrtInviteCpModalComponent implements OnInit {
	@ViewChild('uploadOptionModal', { read: TemplateRef })
	uploadOptionModal: TemplateRef<any>;

	@Input() attachments: Attachment[] = [];

	adviceProcessId: number;
	adviserId: number;

	uploadModal: BsModalRef;
	optionModalRef: BsModalRef;
	clientDocuments: DocumentGroupState;
	linkModalRef: BsModalRef;

	loadContent$: () => Observable<any>;
	sendFn$: (
		data: Partial<CrtInviteCpModel>,
		attachments: Attachment[]
	) => Observable<any>;
	mergeTags$: Observable<MergeTagState[]> = of([]);

	data: Partial<CrtInviteCpModel>;
	company: string;

	form = new UntypedFormGroup({
		from: new UntypedFormControl({ value: null, disabled: true }),
		to: new UntypedFormControl({ value: null, disabled: true }),
		carbonCopy: new UntypedFormControl(null),
		blindCarbonCopy: new UntypedFormControl(null),
		subject: new UntypedFormControl(null),
	});

	bodyContent: string;

	// content that is merged with mergeTags
	parsedBodyContent: string;

	froalaExtendOptions = {
		heightMax: 200,
		heightMin: 300,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};

	isSending = false;
	isLoading = false;
	isLoadingAttachments = false;

	headerTemplate = '';
	parsedHeaderTemplate = '';
	footerTemplate = '';
	parsedFooterTemplate = '';

	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(
		public bsModalRef: BsModalRef,
		private crtDocService: CrtDocumentService,
		private loggerService: LoggerService,
		private modalService: BsModalService,
		private clientProfileQuery: ClientProfileQuery,
		private crtInviteService: CrtInviteCpService,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit(): void {
		if (!!this.loadContent$) {
			this.isLoading = true;
			this.loadContent$()
				.pipe(
					withLatestFrom(this.businessConfigQuery.businessConfig$),
					tap(([emailSettings, businessConfig]) => {
						const content = !!emailSettings?.bodyContent
							? emailSettings?.bodyContent
							: '';
						// TODO: Replace default template (Static template)
						const cpSiteUrl = `${environment.cpSiteUrl}/${this.company}/login`;
						const business =
							businessConfig.BusinessCode?.toLowerCase()?.includes('haven')
								? '%BUSINESS_MORTGAGE_FAP_NAME%'
								: '%BUSINESS_FAP_NAME%';
						this.headerTemplate = inviteHeader(business);
						this.footerTemplate = inviteFooter(cpSiteUrl);
						this.bodyContent = content;

						this.form.patchValue({
							from: this.data.emailFrom,
							to: this.data?.emailDestination[0]?.email,
							carbonCopy: emailSettings.carbonCopy,
							blindCarbonCopy: emailSettings.blindCarbonCopy,
							subject: emailSettings.subject,
						});
					}),
					mergeMap(() => this.parseContent()),
					finalize(() => (this.isLoading = false)),
					take(1)
				)
				.subscribe();
		}
		this.clientDocuments = R.clone(
			this.clientProfileQuery.getValue().documents
		);
		this.prepareAttachments();
	}

	onValueChanged(value: any): void {
		this.bodyContent = value.content;
		this.parsedBodyContent = value.content;
	}

	prepareFormValue() {
		const data = this.form.getRawValue();
		return {
			...data,
			carbonCopy: strUtil
				.removeSpace(data.carbonCopy)
				.split(',')
				.filter((x) => !!x)
				.map((cc) => ({
					Email: cc,
					Name: cc,
				})),
			blindCarbonCopy: strUtil
				.removeSpace(data.blindCarbonCopy)
				.split(',')
				.filter((x) => !!x)
				.map((bcc) => ({
					Email: bcc,
					Name: bcc,
				})),
			subject: strUtil.safeTrim(data.subject),
		};
	}

	prepareAttachments() {
		of(true)
			.pipe(
				tap((x) => (this.isLoadingAttachments = x)),
				mergeMap(() =>
					this.crtInviteService.getIntroductionAndDisclosureAttachments(
						this.adviceProcessId,
						this.adviserId
					)
				),
				map((attachments) => {
					this.attachments = attachments?.filter(Boolean) || [];
					return attachments;
				}),
				finalize(() => (this.isLoadingAttachments = false)),
				take(1)
			)
			.subscribe();
	}

	send(): void {
		this.isSending = true;
		const formValue = this.prepareFormValue();
		this.data.emailCC = formValue?.carbonCopy;
		this.data.emailBCC = formValue?.blindCarbonCopy;
		this.data.emailSubject = formValue?.subject;
		const cpSiteUrl = `${environment.cpSiteUrl}/${this.company}/login`;
		const emailBody = `${this.headerTemplate}${this.parsedBodyContent}${this.footerTemplate}`;
		convertUtil
			.convertToBase64(emailBody)
			.pipe(
				mergeMap((htmlContent) => {
					this.data.htmlBody = htmlContent;
					this.data.stringBody = getEmailContentNoHtml(emailBody);
					return this.sendFn$(this.data, this.attachments).pipe(
						finalize(() => (this.isSending = false)),
						catchError((err) => {
							this.isSending = false;
							return throwError(err);
						}),
						take(1)
					);
				}),
				take(1)
			)
			.subscribe(() => this.bsModalRef.hide());
	}

	cancel(): void {
		this.bsModalRef.hide();
	}

	parseContent() {
		// const customMTags = this.getCustomTags();

		return this.mergeTags$?.pipe(
			map((mergeTags: MergeTagState[]) =>
				MergeTagsMapper.updateEmailMergeTags(mergeTags, [
					// ...customMTags,
					...EmailLinksMT,
				])
			),
			tap((data: MergeTagState[]) => {
				const headerTags = mtMergeContent(this.headerTemplate || '', data);
				const footerTags = mtMergeContent(this.footerTemplate || '', data);
				const bodyTags = mtMergeContent(this.bodyContent || '', data);

				this.headerTemplate = headerTags;
				this.footerTemplate = footerTags;
				this.bodyContent = bodyTags;

				this.parsedBodyContent = bodyTags;
				this.parsedHeaderTemplate = headerTags;
				this.parsedFooterTemplate = footerTags;

				// this formats the subject merge tags
				const subjectMergeTags = MergeTagsMapper.convertImageLinkToTextMT(data);
				const subjectContent = mtMergeContent(
					this.form.controls.subject.value || '',
					subjectMergeTags,
					false
				);
				const parsedSubject = removeMtWrappers(subjectContent);
				this.form.get('subject').setValue(normalizeHTMLSymbols(parsedSubject));
			}),
			take(1)
		);
	}

	uploadDocuments() {
		const stop$ = new Subject<void>();

		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

		// convert Image File -> Base64 -> HTML img -> PDF
		const convertImageToBase64 = (file: File): Observable<string> => {
			return new Observable((obs) => {
				obs.next(file);
				obs.complete();
			}).pipe(
				// convert file to base64
				mergeMap(() => convertUtil.simpleConvertToBase64(file)),
				// create a html img string and add the converted base64 image in src
				map((base64) => `<img src="${base64}" />`),
				// convert html img with base64 src to pdf
				mergeMap((img) => {
					return this.crtDocService.generatePDFbase64(img, {
						FileName: convertFilename(file),
					});
				})
			);
		};

		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() =>
							// check if the file is image
							req[0].type.startsWith('image/'),
						// If the file is image. We need to convert it to pdf
						convertImageToBase64(req[0]),
						convertUtil.simpleConvertToBase64(x[0]).pipe(take(1))
					)
				),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileUploadSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				map((file) => {
					const fileStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					return {
						Document: fileStr,
						OriginalDocument: file,
						FileName: convertFilename(req[0]),
						DocumentType: null, // this.documentInfo.documentType,
						Type: null, // this.documentInfo.type,
						ReferenceId: null, // this.documentInfo.referenceId,
						CustomerId: null, // this.documentInfo.customerId,
						Size: +fileUtil.getFileSizeKb(fileStr),
					};
				}),
				mergeMap((file) =>
					this.crtDocService.uploadDocument(file).pipe(
						tap((x) => {
							const newFile = objectUtil.mapPascalCaseToCamelCase(file);
							this.attachments.push({
								documentId: +x,
								fileName: newFile.fileName,
								generateContentCallback$: undefined,
								content: newFile.document,
								pdfOptions: {
									orientation: '',
									format: '',
								},
								fileSize: newFile.size,
								type: 'uploaded',
							});
						})
					)
				),
				takeUntil(stop$),
				catchError((err) => {
					this.loggerService.Warning(
						{},
						logMessage.shared.fileUploadSize.single.error
					);
					return err;
				})
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Save Document',
			restrict: '.pdf,image/jpeg,image/jpg,image/png',
			maxFileSize: 10000, // kb
			maxFileSizeText: '10MB',
			currentFileSize: +this.getTotalAttachmentsSize(),
		};
		this.uploadModal = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	openUploadOptionModal() {
		this.optionModalRef = this.modalService.show(this.uploadOptionModal, {
			class: 'modal-dialog-centered',
			ignoreBackdropClick: true,
		});
	}

	getTotalAttachmentsSize() {
		return this.attachments.reduce((a, b) => a + (+b.fileSize || 0), 0);
	}

	removeAttachment(index: number): void {
		this.attachments.splice(index, 1);
	}

	linkDocument() {
		const stop$ = new Subject<void>();
		const initState = {
			selectedDetail: 'Link Document',
			document: this.clientDocuments,
			initialSelectedTab: ServicesCodes.AdviceProcess?.toLowerCase(),
			maxFileSize: 10000,
			currentFileSize: +this.getTotalAttachmentsSize(),
		};
		this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		let fileName = '';
		let documentId = '';
		this.linkModalRef.content.getSelectedDocumentValue$
			.pipe(
				filter((x) => !!x),
				mergeMap((x: any) => {
					documentId = x.id;
					fileName = x.fileName;
					return this.crtDocService.getDocumentLink(x.documentLink, {
						responseType: 'blob',
					});
				}),
				mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileLinkSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				tap((x) => {
					const fileStr = `${((x as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					this.attachments.push({
						documentId: +documentId,
						fileName,
						generateContentCallback$: undefined,
						content: fileStr,
						pdfOptions: {
							orientation: '',
							format: '',
						},
						fileSize: +fileUtil.getFileSizeKb(fileStr),
						type: 'linked',
					});
				}),
				takeUntil(stop$),
				catchError((err) => {
					return err;
				})
			)
			.subscribe();
	}
}
