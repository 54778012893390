import { Injectable } from '@angular/core';
import { ReportStore } from './report.store';
import { ReportRequest } from './report.model';
import { of, throwError } from 'rxjs';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';

@Injectable()
export class ReportService {
	constructor(private store: ReportStore, private api: ApiService, protected dropdownValueQuery: DropdownValueQuery) {}

	reportTypes$ = this.dropdownValueQuery.orderedChoices$('RT');

	setReportType(rt: string | null | undefined) {
		return this.store.setReportType(rt);
	}

	export(req: ReportRequest) {
		this.store.setIsExporting(true);
		return of(req).pipe(
			mergeMap(r =>
				this.api.postFileDownload(`export/reports?type=${r.ReportType}`, {
					DateMax: r.DateMax,
					DateMin: r.DateMin,
				})
			),
			tap(() => this.store.setIsExporting(false)),
			catchError(err => {
				this.store.setIsExporting(false);
				return throwError(err);
			})
		);
	}

	// TODO: Low prio for now
	// runReports(req: ReportRequest) {
	//   if (!req || req.ReportType === '') { return; }
	//   this.store.setLoading(true);
	//   const batchLength = 500;
	//   const getIndexesToFetch: ((res: ReportResponse) => number[]) = R.pipe(
	//     (res: ReportResponse) => Math.ceil(res.TotalCount / batchLength),
	//     (totalPages: number) => Array(totalPages).fill(1).map((x, i) => i + 1).slice(1)
	//   );

	//   const searchRequest = (request: ReportRequest) => this.api.post3<ReportResponse>(`Search/${req.ReportType}`, request);

	//   const firstPage$ = of(req).pipe(
	//     map(produce(draft => {
	//       draft.PageIndex = req.PageIndex;
	//     })),
	//     flatMap(searchRequest),
	//     shareReplay()
	//   );
	// }
}
