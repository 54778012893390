import { inject } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Router,
	UrlSegment,
	UrlTree,
} from '@angular/router';
import { BusinessInternalServices } from '@domain/business-config/business-config.model';
import { BusinessConfigService } from '@domain/business-config/business-config.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export function loatCanActivate(
	route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> {
	const router = inject(Router);
	const businessService = inject(BusinessConfigService);
	const activatedRoute = inject(ActivatedRoute);

	// @ts-ignore-next
	const segments = route._urlSegment.segments as UrlSegment as UrlSegment[];
	const buildRouteFromSegment = (baseRoute: string): any[] => {
		let params: { [key: string]: any };
		const path: any[] = segments.map((segment, index) => {
			if (segment.parameters) {
				params = { ...params, ...segment.parameters };
			}
			return index === 1 ? baseRoute : segment.path;
		});
		path.push(params);
		return path;
	};

	const companyCode = segments[0].path;
	return businessService.getOrCached(companyCode).pipe(
		take(1),
		map((config) => config.OAT?.includes(BusinessInternalServices.LoatV2)),
		map((loatv2Enabled) => {
			// if user is redirecting to version 1 but version 2 is enabled
			if (loatv2Enabled && route.data.routeVersion === 'v1') {
				// redirect to loat version 2
				return router.createUrlTree(buildRouteFromSegment('loat-v2'), {
					relativeTo: activatedRoute,
				});
			}

			// if user is redirecting to version 2 but version 2 is disabled
			if (!loatv2Enabled && route.data.routeVersion === 'v2') {
				// redirect to loat version 1
				return router.createUrlTree(buildRouteFromSegment('crt'), {
					relativeTo: activatedRoute,
				});
			}
			return true;
		})
	);
}
