<div class="main-assets p-4">
	<div class="form-row">
		<div class="column col-lg-12">
			<app-property
				[policyOwners]="policyOwners$ | async"
				[totalPropertyValues$]="totalPropertyValues$"
				[propertyAddresses]="propertyAddresses$ | async"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
			></app-property>

			<app-kiwisaver
				[lifeAssuredList]="lifeAssuredList$ | async"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
				[deceasedSciList]="deceasedSciList$ | async"
			></app-kiwisaver>

			<app-other-assets-investments
				[policyOwners]="alPolicyOwnersWithBusinessTrusts$ | async"
				[apcrta$]="apcrta$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
				[deceasedSciList]="deceasedSciList$ | async"
			></app-other-assets-investments>

			<app-mortgage
				[mp$]="mp$"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
			></app-mortgage>

			<app-liabilities
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
			></app-liabilities>

			<br />
			<ng-container *ngIf="isLoading$ | async as loading">
				<app-manual-loader-mini [isLoading]="loading"></app-manual-loader-mini>
			</ng-container>
			<ng-container *ngIf="!(isLoading$ | async)">
				<form [formGroup]="form" [class.submitted]="submitted" class="form">
					<div class="form-row align-items-center">
						<div class="col-3">
							<label for="" class="font-weight-bold"
								>Do you have any personal guarantees?</label
							>
						</div>
						<div class="col-9 mb-2">
							<span
								class="w-auto"
								[class.crt-custom-dropdown]="!(isAdviceProcessEnded$ | async)"
							>
								<select
									[attr.disabled]="!(isAdviceProcessEnded$ | async) ? null : ''"
									class="form-control crt-form-control"
									formControlName="HasPersonalGuarantees"
									id="personalGuarantees"
									(change)="onValueChanges()"
								>
									<option value="" selected hidden></option>
									<option
										*ngFor="let dropdown of APCRTYNNA$ | async"
										[value]="dropdown.value"
									>
										{{ dropdown.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
					<div class="form-row mb-3">
						<div class="col-3"></div>
						<div class="col-9 mb-2">
							<ng-container
								*ngIf="form.get('HasPersonalGuarantees').value === 'Yes'"
							>
								<div class="pt-2">
									<textarea
                    [attr.disabled]="!(isAdviceProcessEnded$ | async) ? null : ''"
										class="form-control crt-form-control"
										formControlName="PersonalGuaranteesDetails"
										rows="3"
										id="PersonalGuaranteesDetails"
										(change)="onValueChanges()"
									></textarea>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="form-row align-items-center">
						<div class="col-3">
							<label for="" class="font-weight-bold">
								Do you have any other potential financial responsibilities?
							</label>
						</div>
						<div class="col-9 mb-2">
							<span
								class="w-auto"
								[class.crt-custom-dropdown]="!(isAdviceProcessEnded$ | async)"
							>
								<select
                  [attr.disabled]="!(isAdviceProcessEnded$ | async) ? null : ''"
									class="form-control crt-form-control"
									formControlName="HasFinancialResponsibilities"
									id="potentialFinancialResponsibilities"
									(change)="onValueChanges()"
								>
									<option value="" selected hidden></option>
									<option
										*ngFor="let dropdown of APCRTYNNA$ | async"
										[value]="dropdown.value"
									>
										{{ dropdown.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
					<div class="form-row mb-3">
						<div class="col-3"></div>
						<div class="col-9 mb-2">
							<ng-container
								*ngIf="form.get('HasFinancialResponsibilities').value === 'Yes'"
							>
								<div class="pt-2">
									<textarea
                    [attr.disabled]="!(isAdviceProcessEnded$ | async) ? null : ''"
										class="form-control crt-form-control"
										formControlName="FinancialResponsibilitiesDetails"
										id="FinancialResponsibilitiesDetails"
										rows="3"
										(change)="onValueChanges()"
									></textarea>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="form-row align-items-center">
						<div class="col-3 mb-2">
							<label for="" class="font-weight-bold"
								>Do you have a current Will in place?</label
							>
						</div>
						<div class="col-9 mb-2">
							<span
								class="w-auto"
								[class.crt-custom-dropdown]="!(isAdviceProcessEnded$ | async)"
							>
								<select
                  [attr.disabled]="!(isAdviceProcessEnded$ | async) ? null : ''"
									class="form-control crt-form-control"
									formControlName="HasCurrentWill"
									id="currentWillInPlace"
									(change)="onValueChanges()"
								>
									<option value="" selected hidden></option>
									<option
										*ngFor="let dropdown of APCRTYNNA$ | async"
										[value]="dropdown.value"
									>
										{{ dropdown.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
					<div class="form-row mb-3">
						<div class="col-3 mb-2"></div>
						<div class="col-9 mb-2">
							<ng-container *ngIf="form.get('HasCurrentWill').value === 'No'">
								<div class="pt-2">
									<textarea
                    [attr.disabled]="!(isAdviceProcessEnded$ | async) ? null : ''"
										class="form-control crt-form-control"
										formControlName="CurrentWillDetails"
										id="CurrentWillDetails"
										rows="3"
										(change)="onValueChanges()"
									></textarea>
								</div>
							</ng-container>
						</div>
					</div>
				</form>
			</ng-container>
		</div>
	</div>
</div>
