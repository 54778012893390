import { QueryEntity } from '@datorama/akita';
import { EmailStatusState, EmailStatusStore } from './email-status.store';
import { Injectable } from '@angular/core';
import { map, distinctUntilChanged, withLatestFrom, combineLatest } from 'rxjs/operators';
import { columns } from '../email-status-datatable.config';
import * as R from 'ramda';
import { EmailStatusModel } from './email-status.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { util } from '../../../../util/util';
import sort from 'fast-sort';
import { EmailSettingsQuery } from '../../email-settings/state/email-settings.query';

@Injectable()
export class EmailStatusQuery extends QueryEntity<EmailStatusState, EmailStatusModel> {
	constructor(protected store: EmailStatusStore, private emailSettingsQuery: EmailSettingsQuery) {
		super(store);
	}

	rowsLoading$ = this.select(state => state.rowLoadingStatus);
	cellsLoading$ = this.select(state => state.cellLoadingStatus);
	status$ = this.select(x => x.status);
	emailStatus$ = this.status$.pipe(
		map(emailType => {
			const sc = emailType
				? R.map(y => ViewDisplayValue.Map(!y.value ? '0' : y.value?.toString(), `${y.name}`), emailType)
				: [];
			return [...sc];
		})
	);

	tableResult$ = this.select(x => x.tableResult);
	settings$ = this.emailSettingsQuery.selectAll({ filterBy: x => x.IsActive > 0 });
	emailType$ = this.settings$.pipe(
		map(emailType => {
			const sc = emailType
				? R.map(
						y => ViewDisplayValue.Map(!y.EmailCode ? '' : y.EmailCode?.toString(), `${y.EmailType}`),
						emailType
				  )?.sort((a, b) => a.display.localeCompare(b.display))
				: [];
			return [...sc];
		})
	);

	columns$ = this.select(x => x.columns);
	rows$ = this.selectAll();
	tableColumns$ = this.columns$.pipe(
		map(tableColumns => {
			return tableColumns?.map(col => columns?.find(y => y.metakey === col))?.filter(x => x);
		}),
		map(tableColumns =>
			tableColumns?.map(column => {
				return column;
			})
		),
		distinctUntilChanged((x, y) => R.equals(x, y))
	);

	sortedRows$ = this.select(x => [x.propSort, x.sort]).pipe(
		distinctUntilChanged((p, q) => R.equals(p, q)),
		// tslint:disable-next-line: deprecation
		combineLatest(this.rows$),
		withLatestFrom(this.tableColumns$),
		map(([[[prop, sortDirection], rows], tableColumns]) => {
			if (sortDirection === '' || prop === '') {
				return rows;
			} else {
				const column = tableColumns?.find(x => x.prop === prop);
				if (util.isNullOrEmpty(column)) {
					return rows;
				}

				const decorated = rows?.map(r => {
					const actualValue = column.sortValueGetter(r[prop], column.choices);
					return [this.spaceSortValueGetter(actualValue), R.defaultTo('', actualValue), r.EmailSettingsId, r];
				});
				return sortDirection === 'asc'
					? sort(decorated)
							.by([{ asc: u => u[0] }, { asc: u => u[1] }, { asc: u => u[2] }])
							?.map(x => x[3])
					: sort(decorated)
							.by([{ asc: u => u[0] }, { desc: u => u[1] }, { asc: u => u[2] }])
							?.map(x => x[3]);
			}
		})
	);

	private spaceSortValueGetter(fieldValue: string | number | null | undefined): 1 | 2 {
		let stringValue: string;
		if (util.isNullOrEmpty(fieldValue)) {
			stringValue = '';
		} else if (typeof fieldValue === 'string') {
			stringValue = fieldValue?.trim();
		} else {
			stringValue = fieldValue?.toString();
		}
		if (stringValue === '') {
			return 2;
		} else {
			return 1;
		}
	}
}
