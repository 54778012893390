import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, zip } from 'rxjs';
import { Router } from '@angular/router';
import { RouteService } from '../config/route.service';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../../domain/user/user.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class BusinessAdminGuard implements CanActivate {
	constructor(
		private router: Router,
		private routeService: RouteService,
		private userService: UserService,
		private authService: AuthenticationService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		let r;
		if (this.authService.isLoggedIn) {
			zip(this.routeService.RouteChange$, this.userService.get())
				.pipe(
					map(([route, user]) => {
						if (user && ['LG', 'LGI']?.includes(user.SecurityGroup)) {
							r = this.router.navigate(route.splitToRoutePath(route.dashboard));
						}
					}),
					catchError((err) => {
						r = this.router.navigate(
							this.routeService.splitToRoutePath('/error')
						);
						return of(err);
					})
				)
				.subscribe();
		} else {
			r = this.router.navigate(this.routeService.splitToRoutePath('/login'));
		}
		return r;
	}
}
