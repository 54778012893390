<div
	class="form-row align-items-center mb-2"
	*ngFor="let d of documents; trackBy: trackByDocument"
>
	<div class="col-md-4">{{ d?.field }}</div>
	<div class="col-md d-flex align-items-center">
		<!-- Online Advice Process Buttons -->
		<ng-container *ngIf="hasKOAT && adviceProcess?.isOnline; else offlineMode">
			<ng-container *ngIf="!!d.value; else onlineProcessNoDocument">
				<button
					type="button"
					class="tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0"
					(click)="
						downloadFile(d?.value?.documentID, d?.field, d.value.documentName)
					"
				>
					View
				</button>
				<button
					*ngIf="d?.field !== koatDocumentFields.AdviceSummary && isEditable"
					type="button"
					class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
					(click)="openUploadModal('Replace', d?.field)"
				>
					Replace
				</button>
				<button
					*ngIf="d?.field === koatDocumentFields.AdviceSummary && isEditable"
					type="button"
					class="tap-btn tap-btn--default mr-1 w-40 px-sm-0"
					[routerLink]="goToCrtPage(d?.field)"
				>
					{{ d?.field === koatDocumentFields.AdviceSummary ? "Open" : "Edit" }}
				</button>
			</ng-container>

			<ng-template #onlineProcessNoDocument>
				<ng-container *appRestrictTo="['FEAP']">
					<ng-container>
						<ng-container *ngIf="adviceProcess.isStarted && !!d?.show">
							<ng-container *appRestrictTo="['FCRT']">
								<ng-container
									*ngIf="(
											d?.field === koatDocumentFields.KiwiSaverInformation ||
											d?.field === koatDocumentFields.RiskProfileReview ||
											d?.field === koatDocumentFields.AdviceSummary
										); else defaultNoDocumentButtons
									"
								>
									<ng-container [ngSwitch]="d?.field">
										<ng-container
											*ngSwitchCase="koatDocumentFields.KiwiSaverInformation"
										>
											<button
												*ngIf="
													!!adviceProcess?.pageStarted?.includes('APFF') ||
													!!adviceProcess?.pageStarted?.includes('APYK')
												"
												type="button"
												class="{{
													!!adviceProcess?.pageCompleted?.includes('APYK') || !isEditable ?
													'tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0' :
													'tap-btn tap-btn--default text-truncate mr-1 w-40 px-xs-0'
												}}"
												[routerLink]="goToCrtPage(d?.field)"
											>
												{{ !!adviceProcess?.pageCompleted?.includes('APYK') ||
												!isEditable ? "View" : "Edit" }}
											</button>
										</ng-container>
										<ng-container
											*ngSwitchCase="koatDocumentFields.RiskProfileReview"
										>
											<button
												*ngIf="!!adviceProcess?.pageStarted?.includes('APRP')"
												type="button"
												class="{{
													!!adviceProcess?.pageCompleted?.includes('APRP') || !isEditable ?
													'tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0' :
													'tap-btn tap-btn--default text-truncate mr-1 w-40 px-xs-0'
												}}"
												[routerLink]="goToCrtPage(d?.field)"
											>
												{{ !!adviceProcess?.pageCompleted?.includes('APRP') ||
												!isEditable ? "View" : "Edit" }}
											</button>
										</ng-container>
										<ng-container
											*ngSwitchCase="koatDocumentFields.AdviceSummary"
										>
											<button
												*ngIf="pageIsCompleted(adviceProcessPageCodes.Declaration) && isEditable"
												type="button"
												title="Create Advice Summary"
												class="{{
													!!adviceProcess?.pageStarted?.includes('APAS') ?
													'tap-btn tap-btn--default text-truncate mr-1 w-40 px-xs-0' :
													'tap-btn tap-btn--default text-truncate mr-1 px-xs-0'
												}}"
												[routerLink]="goToCrtPage(d?.field)"
											>
												{{ adviceProcess?.pageStarted?.includes('APAS') ? "Edit"
												: "Create Advice Summary" }}
											</button>
											<!-- <ng-template #adviceSummaryClosed>
												<button
													*ngIf="!!adviceProcess?.pageStarted?.includes('APAS')"
													type="button"
													title="Create Advice Summary"
													class="tap-btn tap-btn--primary tap-text-color-h1 mr-1 w-40 px-sm-0"
													[routerLink]="goToCrtPage(d?.field)"
												>
													View
												</button>
											</ng-template> -->
										</ng-container>
										<ng-container *ngSwitchDefault>
											<ng-container
												*ngTemplateOutlet="offlineMode"
											></ng-container>
										</ng-container>
									</ng-container>
								</ng-container>
								<ng-template #defaultNoDocumentButtons>
									<button
										*ngIf="isEditable"
										type="button"
										class="tap-btn tap-btn--default mr-1 w-40 px-sm-0"
										[routerLink]="goToCrtPage(d?.field)"
									>
										Edit
									</button>
								</ng-template>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-template>
		</ng-container>

		<!-- Offline Advice Process Buttons -->
		<ng-template #offlineMode>
			<div class="w-100">
				<app-offline-documents
					[d]="d"
					[isEditable]="isEditable"
					(doDownload)="
						downloadFile(d?.value?.documentID, d?.field, d.value.documentName)
					"
					(doUpload)="openUploadModal('Upload New', d?.field)"
					(doLinkDocument)="linkDocument(d?.field)"
				>
				</app-offline-documents>
			</div>
		</ng-template>
	</div>
</div>
