import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PropertySoldDetailsState } from './property-sold.model';
export interface PropertySoldState extends EntityState<PropertySoldDetailsState> {
	properties: PropertySoldDetailsState[];
}

@Injectable()
@StoreConfig({ name: 'propertySold', idKey: 'cRTId' })
export class PropertySoldStore extends EntityStore<PropertySoldState, PropertySoldDetailsState> {
	constructor() {
		super({
			properties: []
		});
	}
}
