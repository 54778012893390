import { AdviceProcessSectionCodes } from '@shared/models/advice-process/advice-process.model';
import {
	DefaultFileNames,
	DocumentTypes,
} from '@shared/models/documents/document.model';
import { MortgageEndProcessEmailSettingsState } from './mortgage-end-process.model';

export class MortgageEndProcessEmailSettingsMapper {
	public static mapEmailSettingsData(
		data: MortgageEndProcessEmailSettingsState
	) {
		if (data) {
			const { mergeTag, ...others } = data;
			return others;
		}
		return null;
	}

	public static mapStaffEmailBody(document, docId, staffId) {
		if (docId === 0 || !docId) {
			return {
				document,
				referenceId: +staffId,
				fileName: DefaultFileNames.MortgageEndProcess,
				type: DocumentTypes.MortgageEndProcess,
			};
		}
		return {
			document,
			documentId: +docId,
		};
	}

	public static mapEmailSettingsToUpsert(
		data: MortgageEndProcessEmailSettingsState
	) {
		return {
			settingsId: data?.settingsId || null,
			referenceId: data.referenceId || 0,
			type: AdviceProcessSectionCodes.MortgageEndProcess,
			isEnableEmailOption: data?.isEnableEmailOption || false,
			carbonCopy: data?.carbonCopy || '',
			blindCarbonCopy: data?.blindCarbonCopy || '',
			subject: data?.subject || '',
			unsubscribeGroupID: data?.unsubscribeGroupID || '',
			templateID: data?.templateID || '',
			body: data?.body || '',
		};
	}
}
