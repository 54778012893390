import { SettingsTypes } from '../../state/mortgage-settings.model';
import { IntroSettingsState } from './intro-settings.model';

export class IntroSettingsMapper {
	public static mapToView(data: IntroSettingsState) {
		return {
			referenceId: 0,
			type: 'MOATIP',
			intro: data?.intro ?? '',
			introType: data?.introType,
			introTypeBD: data?.introType === 'BD',
			introTypeUD: data?.introType === 'UD',
			documentID: data?.documentID,
		};
	}

	public static mapDocumentUpload(document, base64) {
		return {
			type: 'MOATI',
			referenceId: 0,
			document: base64,
			fileName: document?.name,
		};
	}

	public static mapToUpsert(data: IntroSettingsState) {
		return {
			referenceId: 0,
			type: 'MOATIP',
			introType: !!data?.introTypeBD ? 'BD' : 'UD',
			intro: data?.intro || '',
			documentID: data?.documentID || '',
		};
	}
}
