import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
import { strUtil } from '../../../util/util';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';

@Component({
	selector: 'app-advice-process-cancel-modal',
	templateUrl: './advice-process-cancel-modal.component.html'
})
export class AdviceProcessCancelModalComponent implements OnInit {
	header: string;
	message: string;
	reasonOptions: string[];
	form: UntypedFormGroup;

	isSaving: boolean;

	saveFn$: (reason: string, notes: string) => Observable<any>;
	cancelFn$: () => Observable<any>;

	constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {
		this.createForm();
	}

	ngOnInit(): void {}

	createForm() {
		this.form = this.fb.group({
			cancellationReason: this.fb.control('', [Validators.required]),
			notes: this.fb.control('', [NoWhitespaceValidator]),
		});
	}

	save() {
		const formValue = this.form.getRawValue();
		const reason = strUtil.safeTrim(formValue.cancellationReason) as string;
		const notes = strUtil.safeTrim(formValue.notes) as string;
		if (this.saveFn$) {
			this.isSaving = true;
			this.saveFn$(reason, notes)
				.pipe(
					take(1),
					finalize(() => {
						this.isSaving = false;
						this.bsModalRef.hide();
					})
				)
				.subscribe();
		}
	}

	cancel() {
		if (!!this.cancelFn$) {
			this.cancelFn$().pipe(take(1)).subscribe();
		}
		this.bsModalRef.hide();
	}
}
