import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PromptUnsavedComponent } from './prompt-unsaved.component';

export interface PromptUnsavedServiceShowOption {
	saveFn: () => Observable<boolean>;
	cancelFn?: () => Observable<boolean>;
}

@Injectable()
export class PromptUnsavedService {
	constructor(private bsModalService: BsModalService) {}

	show(option?: PromptUnsavedServiceShowOption): Observable<boolean> {
		const closeFn = new Subject<any>();
		const modalRef = this.bsModalService.show(PromptUnsavedComponent, {
			show: true,
			ignoreBackdropClick: true,
			ariaDescribedby: 'unsaved-prompt-modal',
			animated: false,
			class: 'modal-dialog-centered',
			initialState: {
				closeFn,
				saveFn: option?.saveFn,
				cancelFn:option?.cancelFn??null,
			},
		});
		return closeFn.asObservable().pipe(
			filter((value) => typeof value === 'boolean'),
			tap(() => modalRef.hide())
		);
	}

}
