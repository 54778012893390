<div class="position-relative">
	<select
		#input
		[ngModel]="value"
		[class]="inputClass"
		[id]="id"
		[tabindex]="tabindex"
		[attr.disabled]="disabled ? '' : null"
		[attr.readonly]="readonly ? '' : null"
		[style.height]="height ? height : null"
		(change)="change($event)"
	>
		<ng-container *ngIf="allowEmptyInitial; else emptyDefault">
			<option value="" selected hidden>{{ emptyText }}</option>
		</ng-container>
		<ng-template #emptyDefault>
			<option *ngIf="allowEmpty">{{ emptyText }}</option>
		</ng-template>
		<ng-container *ngIf="items$ | async; else flatOptions">
			<option *ngFor="let item of items$ | async" [value]="item[valueProperty]">
				{{ item[displayProperty] }}
			</option>
		</ng-container>
		<ng-template #flatOptions>
			<option *ngFor="let item of items" [value]="item[valueProperty]">
				{{ item[displayProperty] }}
			</option>
		</ng-template>
	</select>
	<div
		class="crm-select-placeholder position-absolute top-50 form-select form-select-{{
			size
		}} pe-none text-muted border-0 bg-transparent"
		*ngIf="
			placeholder && !hasValue
		"
	>
		{{ placeholder }}
		<span class="text-danger fw-bold" *ngIf="required">*</span>
	</div>
	<div class="crm-arrow-down crm-text-primary position-absolute fs-4"></div>
</div>
