import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { ApiService } from '../../../../../../core/base/api.service';
import { objectUtil } from '../../../../../../util/util';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { CrtMortgageStore } from '../../state/crt-mortgage.store';
import { ScopeOfServiceState } from './scope-of-service.model';

import { complement, either, isNil, isEmpty } from 'ramda';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	MoatPdfFooterCodes,
	MoatPdfHeaderCodes,
	MortgageAdviceProcessPageIds,
} from '../../../../../../shared/models/advice-process/advice-process.model';
import { SettingsTypes } from '../../../../../mortgage-settings/state/mortgage-settings.model';
import { SosTemplateSettingsState } from 'src/app/modules/mortgage-settings/scope-of-service-settings/sos-template-settings/state/sos-template-settings.model';
import { SidebarStatus } from '../../../_shared/models/sidebar.model';

export const sosPdfOptions = {
	FileName: 'SCOPE-OF-SERVICE',
	DPI: '120',
};

export const SosCfffOptions = {
	MarginTop: '2.5cm',
	MarginBottom: '1.6cm',
	MarginLeft: '0cm',
	MarginRight: '0cm',
	FooterHtmlType: MoatPdfFooterCodes.Default,
	HeaderHtmlType: MoatPdfHeaderCodes.Default
}

@Injectable()
export class ScopeOfServiceService
	extends CrtMortgageService
	implements Resolve<boolean>
{
	constructor(
		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	reset() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getSosPdfOptions() {
		return sosPdfOptions;
	}

	getSosDefault() {
		const endpoint = `crt/settings/0/${SettingsTypes.SOS}`;
		return this.api.get<ScopeOfServiceState>(endpoint).pipe(
			map((x) =>
				complement(either(isNil, isEmpty))(x)
					? objectUtil.mapPascalCaseToCamelCase(x)
					: null
			),
			tap((data) =>
				applyTransaction(() => {
					this.store.setSosDefault(data);
				})
			),
			catchError(() => of(null))
		);
	}

	getSosTemplateSettings() {
		const endpoint = `crt/settings/0/${SettingsTypes.MOATSosTemplate}`;
		return this.api.get<SosTemplateSettingsState>(endpoint).pipe(
			map((x) =>
				complement(either(isNil, isEmpty))(x)
					? objectUtil.mapPascalCaseToCamelCase(x)
					: null
			),
			tap((data) =>
				applyTransaction(() => {
					this.store.setSosTemplateSettings(data);
				})
			),
			catchError(() => of(null))
		);
	}

	getScopeOfService(adviceProcessId: number, settingsCode: string) {
		const endpoint = `crt/${adviceProcessId}/${settingsCode}`;
		return this.api.get<ScopeOfServiceState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(data)
						? objectUtil.mapPascalCaseToCamelCase(data)[0]
						: {};
					this.store.setScopeOfService(state);
				})
			),
			catchError(() => of({}))
		);
	}

	addScopeOfService(scopeOfService: ScopeOfServiceState) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(scopeOfService);
		return this.api.post3<ScopeOfServiceState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setScopeOfService({ ...scopeOfService, cRTId: +x });
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateScopeOfService(scopeOfService: ScopeOfServiceState) {
		const endpoint = `crt/${scopeOfService.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(scopeOfService);
		return this.api.put<ScopeOfServiceState>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.setScopeOfService(scopeOfService);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateScopeOfServiceTemp(
		scopeOfService: ScopeOfServiceState,
		skipHasFormChanges?: boolean
	) {
		applyTransaction(() => {
			if (!skipHasFormChanges) {
				this.setHasFormChanges(true);
			}
			this.setHasFormChanges(true);
			this.store.setScopeOfService(scopeOfService);
		});
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
		const adviceProcessId = +route.paramMap.get('adviceProcessId');

		const getScopeOfService$ = isNil(this.query.getValue().scopeOfService)
			? this.getScopeOfService(adviceProcessId, AdviceProcessSectionCodes.Goals)
			: of(undefined);

		return forkJoin([getScopeOfService$]).pipe(mapTo(true));
	}

	setTabColor() {
		return combineLatest([
			this.query.adviceProcess$,
			this.query.mortApPageStarted$,
			this.query.mortApPageCompleted$,
		]).pipe(
			tap(([ap, pageStarted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (pageStarted?.includes(AdviceProcessPageCodes.SOS)) {
					status = SidebarStatus.Incomplete;
					warning = 'Document must be saved to file';
				}

				if (
					ap?.documents?.some(
						(x) => x?.field === 'Scope of Service' && !!x?.value
					)
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.ScopeOfService,
					false,
					status,
					warning
				);
			})
		);
	}
}
