import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LRGTState, LRGTStore } from './lrgt.store';
import { shareReplay } from 'rxjs/operators';

/**
 * Query LR Goal track widget data
 */
@Injectable()
export class LRGTQuery extends Query<LRGTState> {

  /**
   * Goal track data.
   */
  data$ = this.select(state => {
    return state.GoalTrack;
  }).pipe(
    shareReplay(1)
  );
  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create LR Goal Track query
   */
  constructor(protected store: LRGTStore) {
    super(store);
  }
}
