import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ClientReferralSettingReferralState } from './client-referral-setting.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clientReferralSetting', idKey: 'settingsId' })
export class ClientReferralSettingStore extends EntityStore<ClientReferralSettingReferralState> {
	constructor() {
		super([]);
	}
}
