<div
	class="header d-flex align-items-center justify-content-between tap--bg-primary"
>
	<span class="text-white text">Refer the Client</span>
	<button
		class="close-btn text-white border bg-transparent d-flex align-items-center justify-content-center"
		aria-label="Close"
		(click)="close()"
	>
		<span class="crm-close-btn"></span>
	</button>
</div>

<div class="content bg-white">
	<form
		[formGroup]="formGroup"
		class="py-4 px-5"
		[ngClass]="{ 'is-loading': isLoading }"
	>
		<div class="row m-0 p-0 w-100 mb-2">
			<label for="service">What Service would you like to refer?</label>
		</div>
		<div class="row m-0 p-0 w-100">
			<app-input-select
				[items$]="referrals$"
				formControlName="service"
				appearance="crt"
				height="40px"
				id="service"
				class="w-100"
				valueProperty="settingsId"
				displayProperty="referralService"
			>
			</app-input-select>
		</div>

		<div class="d-flex justify-content-end buttons pt-3">
			<button class="tap-btn p-0 rounded-0 border mr-2" (click)="close()">
				Cancel
			</button>
			<button
				class="tap-btn tap-btn--primary p-0 rounded-0 text-white"
				[disabled]="!service.value"
				(click)="refer()"
			>
				Refer
			</button>
		</div>
	</form>
</div>
