<form [formGroup]="form" novalidate (ngSubmit)="save(addMode)">
	<div class="tap-client-profile__group clearfix">
		<div class="form-row align-items-center tap-client-profile__group__header mb-2">
			<div class="col-lg-4">
				<span class="tap-required-field" *ngIf="groupName.invalid">*</span>
				<h1
					class="w-auto border-0"
					[tooltip]="form?.get('groupName')?.value?.length > 35 ? form?.get('groupName')?.value : null"
					placement="right"
				>
					<input
						type="text"
						formControlName="groupName"
						class="form-control form-control-group theme-crm-field-disabled text-truncate"
						placeholder="File Name"
						id="groupName"
						tabindex="1"
					/>
				</h1>
			</div>
			<div class="col"></div>
			<ng-container *ngIf="(companyCode === 'aurora' || companyCode === 'haven') && isLead">
				<div class="col-lg-auto pr-2">
					<div  class="form-row align-items-center">
						<div class="col-auto d-flex align-items-center">
							<label for="isRecycled">Recycled</label>
						</div>
						<div class="col-auto d-flex">
							<app-checkbox class="d-flex align-items-center clickable">
								<input
									formControlName="isRecycled"
									type="checkbox"
									name="isRecycled"
									id="isRecycled"
									class="checkbox"
									tabindex="17"
								/>
							</app-checkbox>
						</div>
					</div>
				</div>
			
			</ng-container>

			<ng-container *ngIf="isLead" >
				<div class="col-lg-auto">
					<div class="form-row align-items-center">
						<div class="col-lg-4 col-12">
							<label id="leadStatusLabel" for="leadStatus">Lead Status</label>
						</div>
						<div class="col-lg-8 col-12 lead-status-value">
							<span class="custom-dropdown w-100">
								<select
								  #leadStatus
									formControlName="leadStatus"
									id="leadStatus"
									class="form-control"
									placeholder="- -"
									(change)="onChangeLeadStatus($event.target.value)"
								>
									<option value=""></option>
									<option *ngFor="let d of LS" [value]="d.value">{{ d.display }}</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<ng-container *ngIf="isSavingLS$ | async">
					<div class="col-lg-auto mx-0 p-0">
						<i class="tap-text-primary fas fa-spinner fa-pulse"></i>
					</div>
				</ng-container>
			</ng-container>

			<div class="col-lg-auto">
				<div class="form-row align-items-center">
					<div class="col-lg-auto col-12">
						<label for="rank" class="font-weight-bold tap-text-h3-color">Rank</label>
					</div>
					<div class="col-lg col-12 rank">
						<span class="custom-dropdown w-100">
							<select
								formControlName="rank"
								class="form-control theme-crm-field-disabled"
								id="rank"
								tabindex="3"
							>
								<option value=""></option>
								<option *ngFor="let d of PCR" [value]="d.value">{{ d.display }}</option>
							</select>
						</span>
					</div>
				</div>
			</div>
			<div class="col-6 col-lg-auto">
				<div *ngIf="!addMode" class="form-row align-items-center">
					<div class="col-lg-auto col-12">
						<label for="label_r1_status" class="font-weight-bold tap-text-h3-color">Status</label>
					</div>
					<div class="col-lg col-12">
						<div class="btn-group d-block">
							<button
								class="tap-btn tap-btn--default d-block dropdown-toggle btn-sm tap-dynamic-text-color status-btn px-3"
								type="button"
								id="statusDropdown"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[disabled]="!(hasPermissions$(['FCCS']) | async)"
							>
								{{ contactStatusLabel$ | async }}
							</button>
							<ng-container *appRestrictTo="['FCCS']">
								<div class="dropdown-menu dropdown-menu-right" aria-labelledby="statusDropdown">
									<a
										class="btn dropdown-item py-1 clickable"
										*ngFor="let dd of contactDropdown$ | async; let i = index;"
										(click)="convertStatus(dd.value)"
										id="statusDropdown_{{i}}"
										>{{ dd.display }}</a
									>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6 col-lg-auto">
				<div class="form-row align-items-center justify-content-end">
					<div class="col-auto">
						<ng-container *appRestrictTo="['FEC', 'FAC', 'FAL', 'FEL']">
							<span class="position-relative d-inline-block" *ngIf="isSaving$ | async">
								<app-manual-loader-mini [isLoading]="isSaving$ | async" class="p-0"></app-manual-loader-mini>
							</span>
							<ng-container *ngIf="!(isSaving$ | async)">
								<!-- Restriction for edit/add lead customer -->
								<ng-container *ngIf="isLead">
									<ng-container *ngIf="isAM$ | async">
										<ng-component *ngTemplateOutlet="editButtonTemplate" />
									</ng-container>
									<ng-container *appRestrictTo="['FEL']" >
										<ng-component *ngTemplateOutlet="editButtonTemplate" />
									</ng-container>
									<ng-template #editButtonTemplate>
										<ng-container *ngIf="!addMode">
											<span *ngIf="!(isEdit$ | async)" class="position-relative d-inline-block">
												<button type="button" class="icon-btn" (click)="edit()" id="editLeadCustomer">
													<i class="material-icons md-20 tap-text-color-h6"> edit </i>
												</button>
											</span>
										</ng-container>
									</ng-template>
									<ng-container *appRestrictTo="['FAL', 'FEL']">
										<span *ngIf="isEdit$ | async" class="position-relative d-inline-block">
											<button type="submit" class="icon-btn" id="saveLeadCustomer">
												<i class="material-icons md-20 tap-text-color-h6"> save </i>
											</button>
										</span>
										<ng-container *ngIf="!addMode">
											<span *ngIf="isEdit$ | async" class="position-relative d-inline-block">
												<button type="reset" class="icon-btn" (click)="cancel()" id="cancelLeadCustomer">
													<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
												</button>
											</span>
										</ng-container>
									</ng-container>
								</ng-container>
								<!-- Restriction for current client -->
								<ng-container *ngIf="!isLead">
									<ng-container *appRestrictTo="['FEC']">
										<ng-container *ngIf="!addMode">
											<span *ngIf="!(isEdit$ | async)" class="position-relative d-inline-block">
												<button type="button" class="icon-btn" (click)="edit()" id="editCurrentClient">
													<i class="material-icons md-20 tap-text-color-h6"> edit </i>
												</button>
											</span>
										</ng-container>
									</ng-container>
									<ng-container *appRestrictTo="['FEC', 'FAC']">
										<span *ngIf="isEdit$ | async" class="position-relative d-inline-block">
											<button type="submit" class="icon-btn" id="saveCurrentClient">
												<i class="material-icons md-20 tap-text-color-h6"> save </i>
											</button>
										</span>
										<ng-container *ngIf="!addMode">
											<span *ngIf="isEdit$ | async" class="position-relative d-inline-block">
												<button type="reset" class="icon-btn" (click)="cancel()" id="cancelCurrentClient">
													<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
												</button>
											</span>
										</ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row pb-3">
			<div class="col-lg-7">
				<!-- Service Step wizard for status clock services -->
				<ng-container *ngIf="hasCriteria">
					<div class="form-row px-0 mb-2">
						<div class="col-lg-6 px-0">
							<app-table-criteria formID="clientCriteriaColumn1" (selectedCriteriaEvent)="selectedCriteria($event)" [criterias]="criteriasState[0]">
							</app-table-criteria>
						</div>
						<div class="col-lg-6 px-0">
							<app-table-criteria formID="clientCriteriaColumn2" (selectedCriteriaEvent)="selectedCriteria($event)" [criterias]="criteriasState[1]">
							</app-table-criteria>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="!hasCriteria">
					<span class="no-criteria mb-2">No current file activity</span>
				</ng-container>
			</div>

			<div class="col-lg-5">
				<!-- <div class="row align-items-right mb-1">
					<div class="col-lg-3 col-3 pt-1">
						<label for="">Last Review</label>
					</div>
					<div class="col-lg-3 col-9 mb-1 pl-0">
						<app-date-input
							#lastReviewDate
							ngProjectAs="input"
							textboxClass="theme-crm-field-disabled"
							formControlName="lastReview"
							placeholder="dd/mm/yyyy"
							textboxId="lastReview"
							[tabindex]="4"
						></app-date-input>
					</div>
					<div class="col-lg-3 col-3 pt-1">
						<label for="">Next Review</label>
					</div>
					<div class="col-lg-3 col-9 pl-0">
						<app-date-input
							#nextReviewDate
							ngProjectAs="input"
							textboxClass="theme-crm-field-disabled"
							formControlName="nextReview"
							placeholder="dd/mm/yyyy"
							textboxId="nextReview"
							[tabindex]="5"
						></app-date-input>
					</div>
				</div> -->
				<div class="py-3 px-2 bg-group-3rd-col">
					<div class="row align-items-center mb-1" *ngIf="showPendingFields">
						<div class="col-lg-3 col-3">
							<label for="">Review Pending</label>
						</div>
						<div class="col-lg-3 col-1 pl-0">
							<input
								type="checkbox"
								class="chckbox form-control theme-crm-field-disabled"
								id="reviewPending"
								formControlName="reviewPending"
							/>
						</div>
						<div class="col-lg-3 col-3">
							<label for="">Lead Pending</label>
						</div>
						<div class="col-lg-3 col-1 pl-0">
							<input
								type="checkbox"
								class="chckbox form-control theme-crm-field-disabled"
								id="leadPending"
								formControlName="leadPending"
							/>
						</div>
					</div>

					<div
						class="row align-items-center mb-2"
						*featureToggle="{
							id: 'AdviserRework',
							show: false,
						}"
					>
						<div class="col-lg-2 col-12">
							<label class="font-weight-bold" for="adviserName">Adviser</label>
						</div>
						<div class="col-lg-10 col-12 pl-2">
							<span class="tap-required-field" *ngIf="adviser.invalid">*</span>
							<span class="custom-dropdown w-100">
								<select
									formControlName="adviser"
									class="form-control theme-crm-field-disabled"
									id="adviserName"
									tabindex="2"
								>
									<option value=""></option>
									<option *ngFor="let d of advisers" [value]="d.value">{{ d.display }}</option>
								</select>
							</span>
						</div>
				</div>

					<ng-container
						*featureToggle="{
							id: 'AdviserRework',
							show: true,
						}"
						formArrayName="advisers"
						class="adviser-services"
					>
						<div class="row adviser-services__header">
							<div class="col-5 pl-4 d-lg-inline-block d-none">
								Adviser
							</div>
							<div class="col-7 d-lg-inline-block d-none">
								Services
							</div>
						</div>
						<ng-container
							*ngFor="let item of AdviserList?.controls; let i = index"
							[formGroupName]="i"
						>
							<div class="row mb-1">
								<div class="col-lg-5 col-12 pr-lg-0 pl-lg-4 pb-1 adviser-services__adviser">
									<span
										class="tap-required-field"
										*ngIf="item.get('adviserId').invalid"
										>*</span
									>
									<span class="custom-dropdown w-100">
										<select
											formControlName="adviserId"
											id="adviserId_{{ i }}"
											class="form-control theme-crm-field-disabled"
											placeholder="- -"
											tabindex="6.6"
											(change)="onChangeAdviser(i, $event.target.value)"
										>
											<option value=""></option>
											<option
												*ngFor="let d of adviserChoices(i)"
												[value]="d.value"
											>
												{{ d.display }}
											</option>
										</select>
									</span>
								</div>
								<div class="col-lg-7 col-12">
									<div class="d-inline-block adviser-services__services">
										<span
											class="tap-required-field"
											*ngIf="item.get('services').invalid"
										>*</span
										>
										<app-chips
											formControlName="services"
											[choices]="adviserServicesChoices(i)"
											[collapsible]="false"
											[showRemoveIcon]="true"
											valueId="adviserService_{{ i }}"
											textboxId="adviserService_{{ i }}"
											textboxClass="theme-crm-field"
											tabindex="6.7"
										>
										</app-chips>
									</div>

									<div
										class="d-inline-block adviser-services___action position-absolute" 
										*ngIf="!!(isEdit$ | async)"
									>
										<ng-container *ngIf="i === 0; else showRemove">
											<button
												type="button"
												class="icon-btn pt-1 h-auto"
												id="adviserAdd_{{ i }}"
												(click)="addAdviser(true)"
												[disabled]="AdviserList.controls?.length > 5"
											>
												<i class="material-icons md-16 tap-text-primary"> add </i>
											</button>
										</ng-container>
										<ng-template #showRemove>
											<button
												type="button"
												class="icon-btn pt-1 h-auto"
												id="adviserRemove_{{ i }}"
												(click)="removeAdviser(i)"
											>
												<i class="material-icons md-16 tap-text-primary">
													remove
												</i>
											</button>
										</ng-template>
									</div>
								</div>
							</div>
						</ng-container>
					</ng-container>

					<div
					class="row align-items-center mb-2"
					*featureToggle="{
						id: 'AdviserRework',
						show: false,
					}"
				>
					<div class="col-lg-2">
						<label for="">Alt. Adviser</label>
					</div>
					<div class="col-lg-10 pl-2">
						<app-chips
							formControlName="altAdviser"
							[choices]="altAdviserDropdown"
							textboxId="altAdvisers"
							textboxClass="theme-crm-field"
							tabindex="6"
						>
						</app-chips>
					</div>
					</div>
					
					<div
						class="row align-items-center mb-1"
						*featureToggle="{
							id: 'AdviserRework',
							show: false,
						}"
					>
						<div class="col-lg-2 pr-lg-0">
							<label for="preferredEmailContact">Key Contact</label>
						</div>
						<div class="col-lg-10 pl-2">
							<select
								formControlName="preferredEmailContact"
								class="form-control theme-crm-field-disabled"
								id="preferredEmailContact"
								tabindex="6.5"
							>
								<option value=""></option>
								<option *ngFor="let d of preferredEmailContacts" [value]="d.value">{{ d.display }}</option>
							</select>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</form>
