export interface Mortgage {
	adviceProcessId?: number;
	cRTId?: number;
	customerServiceID?: number;

	fixedUntil?: string;
	interestRate?: number;
	lender?: string;
	loanValue?: number;
	loanRepayment?: number;
	mortgageFrequency?: string;
	fixedPeriodEnd?: string;

	status?: number;
	sectionCode?: string;

	loanType?: string;
	pendingAdviserApproval?: boolean;
}

export enum MortgageStatus {
	CurrentMortgage = 'Current Mortgage',
	DrawDown = 'Drawn Down/Settled',
}
