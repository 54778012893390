import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as env } from '@environment';
import * as R from 'ramda';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ApiService } from 'src/app/core/base/api.service';
import { LocalService } from 'src/app/core/services/local.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	logo: string;
	bg: string;
	isLoggedIn = false;
	returnUrl: string;
	companyCode: string;
	isBranded: boolean;
	isPageLoad: boolean;
	isSigningIn = false;
	isTL: boolean;
	loginType: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthenticationService,
		private apiService: ApiService,
		private localService: LocalService
	) {
		this.returnUrl = localStorage.getItem('returnUrl') || '/';
		if (this.authService.isLoggedIn) {
			if (!!this.authService.user && !!this.authService.user.CompanyCode) {
				this.localService.setValue('code', this.authService.user.CompanyCode);
			}
			this.router.navigate([this.returnUrl]);
			localStorage.removeItem('returnUrl');
		} else {
			if (
				!this.route.snapshot.queryParamMap.get('code') &&
				!this.route.snapshot.paramMap.get('companyCode')
			) {
				this.localService.remove('code');
			}
		}
	}

	ngOnInit() {
		this.companyCode = this.route.snapshot.paramMap.get('companyCode');

		if (!!this.companyCode) {
			this.isTL = false;
			this.apiService.get('businesses/theme/' + this.companyCode).subscribe(
				(next: {
					Logo: string;
					LoginPhoto: string;
					PrimaryColor: string;
					LoginType: string;
				}) => {
					if (!!next && !!next.LoginPhoto && !!next.Logo) {
						this.logo = next.Logo;
						this.bg = next.LoginPhoto;
						this.isBranded = true;
						this.loginType = next.LoginType;
					} else {
						this.router.navigate(['/login']);
					}
				},
				(error) => {
					if (this.companyCode) {
						this.router.navigate([`/${this.companyCode}`]);
					} else {
						this.router.navigate(['/login']);
					}
					this.logo = env.defaultLoginLogo;
					this.bg = env.defaultLoginBackground;
				}
			);
		} else {
			this.isTL = true;
			this.isBranded = false;
			this.logo = env.defaultLoginLogo;
			this.bg = env.defaultLoginBackground;
		}

		this.route.queryParams.subscribe((x: { code: string }) => {
			this.isLoggedIn = (!!x && !!x.code) || this.authService.isLoggedIn;
			if (!!x && !!x.code) {
				this.authService
					.login({
						code: x.code,
						redirectUrl: window.location.origin + '/login',
					})
					.subscribe(
						(next) => {
							if (next) {
								this.router.navigate([this.returnUrl]);
								localStorage.removeItem('returnUrl');
							} else {
								this.router.navigateByUrl('/login/unauthorized');
							}
						},
						(error) => {
							if (
								R.isNil(error) ||
								(typeof error === 'string' &&
									error?.toLowerCase().includes('user is not authorized'))
							) {
								this.router.navigateByUrl('/login/unauthorized');
							} else {
								if (this.companyCode) {
									this.router.navigate([`/${this.companyCode}`]);
								} else {
									this.router.navigate(['/login']);
								}
							}
						}
					);
			}
		});
	}

	login(authType?: string) {
		this.isSigningIn = true;
		this.authService
			.login({
				code: '',
				redirectUrl: window.location.origin + '/login',
				authType: authType || null,
			})
			.subscribe(
				(next: string) => {
					this.isSigningIn = false;
					if (typeof next === 'string') {
						window.location.href = next;
					} else {
						console.log(next);
					}
				},
				(error) => {
					this.isSigningIn = false;
				}
			);
	}
}

export function getParameterByName(param, url) {
	if (!url) {
		url = window.location.href;
	}
	param = param?.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2]?.replace(/\+/g, ' '));
}
