<form [formGroup]="form" class="form" class="mb-3">
  <div formArrayName="topups">
    <ng-container *ngFor="let topup of TopupList.controls; let last=last; let i=index;" [formGroupName]="i">
      <div class="row" [ngClass]="last ? 'pb-0' : 'pb-3'">
        <div class="col-12 col-lg-6 mb-2">
          <ng-container *ngIf="topup.get('topupRequirement').value === 'Other'; else showDropdown">
            <input
              id="other_{{i}}"
              formControlName="other"
              class="form-control crt-form-control"
              type="text"
              placeholder="Other"
              [disabled]="!topup.get('isEdit').value"
              [class.disabled]="!topup.get('isEdit').value"
            />
          </ng-container>
          <ng-template #showDropdown>
            <span class="w-100" [class.crt-custom-dropdown]="topup.get('isEdit').value">
              <select
                id="topupRequirement_{{i}}"
                class="crt-form-control form-control"
                formControlName="topupRequirement"
                [class.disabled]="!topup.get('isEdit').value"
                (change)="requirementChanged(i)"
              >
                <option value=""></option>
                <option
                  *ngFor="let dropdown of options$ | async"
                  [value]="dropdown.value"
                >
                  {{ dropdown.display }}
                </option>
              </select>
            </span>
          </ng-template>
          
        </div>

        <div class="col-12 col-lg-4">
          <div class="form-row align-items-center">
            <div class="col-4 col-lg-5 text-left mb-2">
              <label for="" class="position-relative font-weight-bold">
                Lending
              </label>
            </div>
            <div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
              <div class="dollar-icon">
                <input
                  id="topupRequirementValue_{{i}}"
                  formControlName="topupRequirementValue"
                  class="form-control crt-form-control"
                  type="text"
                  currencyMask
                  appCurrencyMaskExt
                  [class.disabled]="!topup.get('isEdit').value"
                />
                <i>$</i>
              </div>
            </div>
          </div>
          
        </div>
        <div class="col-12 col-lg-2">
          <!-- EDIT MODE -->
          <div 
            *ngIf="(!topup.get('cRTId').value || topup.get('isEdit').value) && !topup.get('isLoading').value"
            class="form-row align-items-center"
          >
            <div class="col mt-1">
              <button
                id="saveBtn_{{i}}"
                (click)="save(i)"
                type="button"
                [disabled]="topup.get('isLoading').value || topup.invalid"
                class="icon-btn w-auto h-auto px-1"
                [class.disabled]="topup.get('isLoading').value || topup.invalid"
              >
                <i class="material-icons md-16 tap-text-primary">save</i>
              </button>
              <button
                id="cancelEdit_{{i}}"
                (click)="cancelEdit(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
                [disabled]="topup.get('isLoading').value"
                [class.disabled]="topup.get('isLoading').value"
              >
                <i class="material-icons md-16">close</i>
              </button>
            </div>
          </div>

          <ng-container *ngIf="topup.get('isLoading').value">
            <div class="form-row mt-1">
              <app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
            </div>
          </ng-container>

          <!-- DISPLAY MODE -->
          <div
            *ngIf="!topup.get('isEdit').value && topup.get('cRTId').value && !topup.get('isLoading').value"
            class="form-row align-items-center"
          >
            <div class="col mt-1">
              <button
                id="editBtn_{{i}}"
                (click)="edit(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
              >
                <i class="material-icons md-16">edit</i>
              </button>
              <button
                id="deleteBtn_{{i}}"
                (click)="confirmDelete(i)"
                type="button"
                class="icon-btn w-auto h-auto px-1"
              >
                <i class="material-icons md-16">delete</i>
              </button>
            </div>
            
          </div>
          
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row my-4">
    <div class="col-12 col-lg-6">
      <div class="form-row align-items-center">
        <div class="col">
          <button
            id="addBtn"
            (click)="handleAddNew()"
            type="button"
            [disabled]="isAdd"
            [class.disabled]="isAdd"
            class="tap-btn tap-btn-outline tap-btn--shadow px-4 ml-1"
          >
            Add Requirements +
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="form-row align-items-center">
        <div class="col-4 col-lg-5 text-left mb-2">
          <label for="" class="position-relative font-weight-bold tap-text-primary">
            Total Topup Required
          </label>
        </div>
        <div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
          <div class="dollar-icon">
            {{ totalTopupRequired | currency: "USD":"$":"1.2-2" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>