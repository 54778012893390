<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.Declaration"></app-header>
</div>

<!-- Content -->
<div role="main-content">
	<div class="declaration-document clearfix">
		<div class="declaration-document__content">
			<ng-container *ngIf="isLoadingDocument || isLoadingDocument === undefined">
				<app-manual-loader-mini
					[isLoading]="isLoadingDocument || isLoadingDocument === undefined"
				></app-manual-loader-mini>
			</ng-container>
	
			<ng-container
				*ngIf="!isLoadingDocument && isLoadingDocument !== undefined && data"
			>
				<app-wysiwyg
					#contentEditor
					class="d-block"
					[content]="content"
					[readOnly]="true"
					[generatePdf$]="generatePdf$"
					(getPDF)="getPdf($event)"
					[zoom]="zoom$ | async"
					(zoomEvent)="zoomFn($event)"
					designClass="pdf-design-v2-froala"
				></app-wysiwyg>
	
				<div class="clearfix" #signatureSection>
					<form class="form" [formGroup]="form">
						<div class="clearfix mb-5 crt-signature-comp" formArrayName="signatures">
							<div
								class="
									form-row
									flex-column flex-lg-row
									align-items-end
									signature-image-container
								"
								*ngFor="let s of Signatures.controls; let i = index"
								[formGroupName]="i"
							>
							<div class="col col-lg-3 mb-2 name-field-padding"
								[tooltip]="s.get('fullName')?.value">
									<input
										type="text"
										class="form-control crt-form-control"
										id="fullName"
										formControlName="fullName"
										placeholder="Enter Full Name"
									/>
									<div class="font-weight-bold">Full Name</div>
								</div>
								<div class="col mb-2 date-field-padding">
									<app-date-input
										class="crt-form-control-field"
										#date
										ngProjectAs="input"
										textboxClass="theme-crm-field-disabled"
										placeholder="Enter date  dd/mm/yyyy"
										formControlName="date"
										textboxId="date"
										(onChangeEvent)="onChange()"
									>
									</app-date-input>
									<div class="font-weight-bold">Date</div>
								</div>
								<div class="col mb-2">
									<div
										class="
											clearfix
											border-bottom
											d-flex
											justify-content-end
											align-items-end
											w-100
										"
									>
										<div class="flex-fill align-self-start text-center">
											<img
												*ngIf="!!s.get('signature')?.value"
												[src]="s.get('signature')?.value"
												alt="Signature"
												class="img-fluid"
											/>
										</div>
										<button
											class="icon-btn h-auto"
											title="Sign"
											(click)="sign(i)"
										>
											<i
												class="fas fa-file-signature fa-14 tap-text-color-h6"
											></i>
										</button>
									</div>
									<div class="font-weight-bold">Signature</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</ng-container>

			<ng-container *ngIf="!isLoadingDocument && isLoadingDocument !== undefined && !data">
				<label>
					Contact your business owner to add an declaration template
				</label>
			</ng-container>
		</div>
	</div>
	<ng-container
		*ngIf="!isLoadingDocument && isLoadingDocument !== undefined && data"
	>
		<div class="form-row declaration-document-buttons">
			<div class="col-12">
				<button
					class="
						tap-btn tap-btn-outline
						tap-btn--shadow
						mr-2
						mb-2
						col-auto
					"
					(click)="saveDocument()"
					[disabled]="(!!isLoading || !(otherMergeTags$ | async))"
				>
					Save to File
				</button>
				<button
					class="
						tap-btn tap-btn-outline
						tap-btn--shadow
						mr-2
						mb-2
						col-auto
					"
					(click)="downloadDocumentPDF()"
					[disabled]="(!!isLoading || !(otherMergeTags$ | async))"
				>
					Download Document
				</button>
				<button
					class="
						tap-btn tap-btn-outline
						tap-btn--shadow
						mr-2
						mb-2
						col-auto
					"
					type="button"
					(click)="reloadTemplate()"
					[disabled]="(!!isLoading || !(otherMergeTags$ | async))"
				>
					Reload Document
				</button>
				<button
					class="
						tap-btn tap-btn-outline
						tap-btn--shadow
						mr-2
						mb-2
						col-auto
					"
					(click)="uploadDocument()"
					[disabled]="(!!isLoading || !(otherMergeTags$ | async))"
				>
					Upload Document
				</button>
				<button
					*ngIf="isEnableEmailOption"
					id="emailDeclarationBtn"
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow mr-2 mb-2"
					(click)="sendEmail();"
					[disabled]="(!!isLoading || !(otherMergeTags$ | async) || !(hasDeclarationSaved$ | async))"
					[tooltip]="
						(hasDeclarationSaved$ | async)
							? null
							: 'Please save to file to enable email to client button'
					"
					containerClass="email-tooltip"
					placement="top"
				>
					Email Client
				</button>
				<ng-container *ngIf="isLoading || !(otherMergeTags$ | async)">
					<app-manual-loader-mini
						[isLoading]="true"
						class="pl-1"
					></app-manual-loader-mini>
				</ng-container>
			</div>
		</div>
	</ng-container>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer
		[currentPageId]="pageIds.Declaration"
		[sidebar]="sidebar"
	></app-footer>
</div>

