import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	iif,
	Observable,
	of,
	Subject,
} from 'rxjs';
import {
	finalize,
	map,
	mergeMap,
	take,
	tap,
	concatMap,
	startWith,
	filter,
	withLatestFrom,
} from 'rxjs/operators';
import { Asset } from '../../../../client-sop/assets-and-liabilities/state/asset/asset.model';
import { AssetService } from '../../../../client-sop/assets-and-liabilities/state/asset/asset.service';
import { KiwiSaver } from '../../../../client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.model';
import { KiwiSaverService } from '../../../../client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.service';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { PropertyPurchaseQuery } from '../property-purchase/state/property-purchase.query';
import { PropertyPurchaseService } from '../property-purchase/state/property-purchase.service';
import { CashDepositFormComponent } from './cash-deposit-form/cash-deposit-form.component';
import { CashDepositMapper } from './state/cash-deposit.mapper';
import { CashDepositService } from './state/cash-deposit.service';
import { SecurityService } from '../../security/state/security.service';
import { LoggerService } from '../../../../../../../../core/logger/logger.service';
import { PurposeService } from '../../purpose/state/purpose.service';
import { LoanRefinanceService } from '../loan-refinance/state/loan-refinance.service';
import { LoanRefinanceQuery } from '../loan-refinance/state/loan-refinance.query';
import { PropertySoldQuery } from '../selling-section/property-sold/state/property-sold.query';
import { FundingRequiredLoanQuery } from '../selling-section/loan-repaid/state/loan-repaid.query';
import { TopupRequirementsQuery } from '../topup-requirements/state/topup-requirements.query';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { CashDepositDetails } from './state/cash-deposit.model';

@Component({
	selector: 'app-cash-deposit',
	templateUrl: './cash-deposit.component.html',
})
export class CashDepositComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Input() parentCRTId: number;
	@Input() hasNewPurchase: boolean;
	@Input() isRefinance$: Observable<boolean>;
	@Input() isSellingProperty$: Observable<boolean>;
	@Input() isLendingTopup$: Observable<boolean>;
	@Output() saveCompleted: EventEmitter<object> = new EventEmitter<object>();
	@Output() setFundingReqSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	isLoading: boolean;
	submitted = false;
	cashDeposit$ = new BehaviorSubject<CashDepositDetails>(null);
	kiwiSavers$ = new BehaviorSubject<KiwiSaver[]>(null);
	assets$ = new BehaviorSubject<Asset[]>(null);
	cRTId$ = new BehaviorSubject<number>(null);

	propertyPurchases$ = this.propertyPurchaseQuery.selectAll();
	loanRefinance$ = this.loanRefinanceQuery.loanRefinance$;
	adviceProcessId = this.moatQuery.getValue().adviceProcessId;
	purpose$ = this.purposeService.purposes$.pipe(
		map((x) => (x?.length > 0 ? x[0] : null))
	);
	propertyToBeSold$ = this.propertySoldQuery.properties$;
	loansRepaid$ = this.fundingRequiredLoanQuery.loans$;
	topUpRequirement$ = this.topUpQuery.topupRequirementList$;

	@ViewChild('cashDepositForm') cashDepositForm: CashDepositFormComponent;

	@Input() activeBank: string;

	constructor(
		private moatQuery: CrtMortgageQuery,
		private service: CashDepositService,
		private kiwiSaverService: KiwiSaverService,
		private assetsService: AssetService,
		private propertyPurchaseService: PropertyPurchaseService,
		private propertyPurchaseQuery: PropertyPurchaseQuery,
		private securityService: SecurityService,
		private loggerService: LoggerService,
		private purposeService: PurposeService,
		private loanRefinanceService: LoanRefinanceService,
		private loanRefinanceQuery: LoanRefinanceQuery,
		private propertySoldQuery: PropertySoldQuery,
		private fundingRequiredLoanQuery: FundingRequiredLoanQuery,
		private topUpQuery: TopupRequirementsQuery
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.isLoading = true;
		combineLatest([
			this.kiwiSaverService.isLoading$.pipe(startWith(true)),
			this.assetsService.isLoading$.pipe(startWith(true)),
			this.propertyPurchaseService.isLoading$.pipe(startWith(true)),
			this.purposeService.isLoading$.pipe(startWith(true)),
			this.loanRefinanceService.isLoading$,
			this.isRefinance$,
			this.isLendingTopup$,
			this.topUpQuery.isLoading$,
			this.loanRefinanceService.loanRefinance$.pipe(startWith(null as any)),
			this.isSellingProperty$,
		])
			.pipe(
				filter(
					([
						kiwiLoading,
						assetsLoading,
						propertyLoading,
						purposeLoading,
						loanRefinanceLoading,
						isRefinance,
						isLendingTopup,
						topupLoading,
					]) => {
						return (
							!purposeLoading &&
							!kiwiLoading &&
							!assetsLoading &&
							((isLendingTopup && !topupLoading) || !isLendingTopup) &&
							((isRefinance && !loanRefinanceLoading) || !isRefinance) &&
							((this.hasNewPurchase && !propertyLoading) ||
								!this.hasNewPurchase)
						);
					}
				),
				withLatestFrom(
					this.kiwiSaverService.kiwiSavers$,
					this.assetsService.assets$
				),
				tap(([, kiwiSavers, assets]) => {
					this.kiwiSavers$.next(kiwiSavers);
					this.assets$.next(assets);
				}),
				concatMap(() => this.service.get(+this.parentCRTId)),
				map((x) => CashDepositMapper.mapToView(x)),
				tap((x) => this.cashDeposit$.next(x)),
				tap((x) => this.cRTId$.next(x?.cRTId)),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	checkSecurityIsModified() {
		this.securityService.securities$
			.pipe(
				tap((x) => {
					if (!!x && x[0] && !!x[0]?.isModified) {
						this.loggerService.Warning(
							{},
							logMessage.oat.mortgage.cashDeposit.warning.securityModified
						);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	next() {
		this.save(true);
	}

	previous() {
		this.save();
	}

	formErrors() {
		return combineLatest([
			this.isSellingProperty$,
			this.propertyToBeSold$,
			this.propertyPurchases$,
			this.isLendingTopup$,
			this.topUpRequirement$,
		]).pipe(
			map(
				([
					isSellingProperty,
					propertySold,
					propertyPurchase,
					isLendingTopup,
					topup,
				]) => {
					const errors = [];
					if (isSellingProperty && propertySold?.length === 0) {
						errors.push(
							'At least one property to be sold needs to be selected'
						);
					}
					if (this.hasNewPurchase && propertyPurchase?.length === 0) {
						errors.push(
							'At least one property to be purchased needs to be entered'
						);
					}
					if (isLendingTopup && topup?.length === 0) {
						errors.push('At least one Topup Requirement needs to be added');
					}
					return errors;
				}
			),
			take(1)
		);
	}

	save(isNext = false, checkValidations: boolean = true, redirect: boolean = true) {
		this.formErrors()
			.pipe(
				filter((errors) => {
					if (checkValidations && errors?.length > 0) {
						if (this.submitted) {
							// If already submitted, allow to proceed regardless of requirements
							this.setFundingReqSubmitted.emit(false);
							this.submitted = false;
							return true;
						} else {
							// If first time submission and incomplete form requirements
							this.setFundingReqSubmitted.emit(true);
							this.submitted = true;
							this.saveCompleted.emit({ isSuccess: false, isNext, redirect });
							this.loggerService.MultipleWarnings(errors);
							return false;
						}
					} else {
						this.setFundingReqSubmitted.emit(false);
						this.submitted = false;
						return true;
					}
				}),
				mergeMap(() => this.cashDepositForm.getFormData()),
				map((x) => CashDepositMapper.mapToUpsert({
						...x,
						adviceProcessId: this.adviceProcessId,
						parentCRTId: this.parentCRTId
					})
				),
				concatMap((x) => {
					const formatOther = x.other.map((o) => {
						return {
							otherField: o.name,
							otherValue: o.cashDepositValue,
							isUse: o.isUse
						}
					});

					const cashDeposit: CashDepositDetails = {
						cRTId: x.cRTId,
						cash: x.cash.cashDepositValue,
						cashFromExistingLoan: x.cashFromExistingLoan.cashDepositValue,
						cashTick: false,
						gift: x.gift.cashDepositValue,
						kiwiSaver: x.kiwiSaver.cashDepositValue,
						kiwiSaverTick: false,
						newLendingRequired: x.newLendingRequired,
						other: formatOther,
						saleProceeds: x.saleProceeds.cashDepositValue,
						saleProceedsTick: false
					}

					this.cashDeposit$.next(cashDeposit);
					return iif(
						() => x?.cRTId === 0 || !x?.cRTId,
						this.service.add(x).pipe(tap((id: any) => this.cRTId$.next(id))),
						this.service.update(x)
					)
				}),
				concatMap(() =>
					this.securityService.get(this.parentCRTId, this.adviceProcessId).pipe(
						finalize(() => this.checkSecurityIsModified()),
						take(1)
					)
				),
				take(1)
			)
			.subscribe(
				() => this.saveCompleted.emit({ isSuccess: true, isNext, redirect }),
				() => this.saveCompleted.emit({ isSuccess: false, isNext, redirect })
			);
	}

	clearData() {
		this.service.clearData();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
