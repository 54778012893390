<div class="qualifications-section container-fluid mt-5 mb-5">
	<div class="container-fluid m-0">
		<div class="row qualifications-section__box pt-2 pb-2 pr-3 pl-3">
			<div class="container-fluid m-0">
				<div class="row mt-2 mb-2">
					<div class="col-12 p-0">
						<span class="section-header tap-text-primary">Qualifications</span>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-12 border-bottom"></div>
				</div>
				<div class="row mb-3">
					<div class="col-12 p-0">
						<span class="qualifications-section__header"
							>New Zealand Certificate in Financial Services (Level 5)</span
						>
					</div>
				</div>

				<!-- Standard Qualifications -->
				<div class="row mb-5">
					<div
						class="col-12 col-md-4 col-lg-4 qualifications-section__details pr-4"
						*ngFor="let sq of standardQualifications; let sqi = index"
						[class.mt-4]="sqi > 2"
					>
						<div class="container p-0">
							<div class="row">
								<div class="col-12 p-0">
									<span class="font-weight-bold qualification-name float-left">
										{{ sq.QualificationName }}
									</span>
									<ng-container *ngIf="!!sq?.StaffSettingsId && !disable">
										<button
											*appRestrictTo="['BEU']"
											[id]="sq.QualificationId + 'QualificationEdit'"
											type="button"
											class="icon-btn w-auto h-auto px-1 float-left"
											(click)="editQualification(sq)"
										>
											<i class="material-icons md-16"> edit </i>
										</button>
									</ng-container>
								</div>
							</div>
							<div class="row" *ngIf="!!sq?.StaffSettingsId">
								<div class="col-12 p-0">
									<span class="complete-date"
										>Completed Date:
										{{ sq?.CompletedDate | momentDatetime }}</span
									><br />
									<ng-container *ngFor="let sqd of sq?.Documents"
										>Document :
										<span
											class="doc-name view-document"
											(click)="viewDocument(sqd)"
										>
											{{ sqd.Value || "" }}</span
										><br />
									</ng-container>
								</div>
							</div>
							<ng-container
								*ngIf="
									sq.QualificationName === coreKnowledge.name &&
									sq.Institute === OpenPolytech &&
									!!sq.StaffSettingsId
								"
							>
								<div class="row">
									<div class="col-12 p-0">
										<span class="complete-date"
											>Completed Date:
											{{ sq?.PolytechCompletedDate | momentDatetime }}</span
										><br />
										<ng-container *ngFor="let sqd of sq?.PolytechDocuments"
											>Document :
											<span
												class="doc-name view-document"
												(click)="viewDocument(sqd)"
											>
												{{ sqd.Value || "" }}</span
											><br />
										</ng-container>
									</div>
								</div>
							</ng-container>
							<div class="row mt-2" *ngIf="!sq?.StaffSettingsId">
								<div class="col-12 p-0 qualifications-section__cta">
									<ng-container *ngIf="!disable">
										<button
											*appRestrictTo="['BEU']"
											type="button"
											class="btn ui-btn-gray mr-3"
											(click)="addQualification(sq)"
											id="qualificationAdd"
											[disabled]="disable"
										>
											+ Add Qualification
										</button>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Other Qualifications -->
				<div class="row pb-1">
					<div class="col-12 qualifications-section__title p-0 pb-2">
						<span class="font-weight-bold">Other Qualifications</span>
					</div>
				</div>
				<div class="row">
					<div
						class="col-12 col-md-4 col-lg-4 qualifications-section__details pr-4"
						*ngFor="let oq of otherQualifications; let oqi = index"
						[class.mt-4]="oqi > 2"
					>
						<div class="container p-0">
							<div class="row">
								<div class="col-12 p-0">
									<span class="qualification-name float-left">
										Name: {{ oq.QualificationName || "" }}
									</span>
									<ng-container *ngIf="!disable">
										<button
											*appRestrictTo="['BEU']"
											id="otherQualificationsEdit"
											type="button"
											class="icon-btn w-auto h-auto px-1 float-left"
											[disabled]="disable"
											(click)="editQualification(oq, true)"
										>
											<i class="material-icons md-16"> edit </i>
										</button>
									</ng-container>
								</div>
							</div>
							<div class="row">
								<div class="col-12 p-0">
									<span class="complete-date"
										>Completed Date:
										{{ oq?.CompletedDate | momentDatetime }}</span
									><br />
									<ng-container *ngFor="let c of oq?.Documents"
										>Document :
										<span
											class="doc-name view-document"
											(click)="viewDocument(c)"
										>
											{{ c.Value || "" }}</span
										><br />
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-4">
					<div
						class="col-12 qualifications-section__cta mb-2 p-0 pt-1"
						[class.mt-4]="
							!!standardQualifications?.length && !!otherQualifications?.length
						"
					>
						<ng-container *ngIf="!disable">
							<button
								*appRestrictTo="['BEU']"
								type="button"
								class="btn ui-btn-gray mr-3"
								(click)="addQualification(null, true)"
								id="otherQualificationsAdd"
								[disabled]="disable"
							>
								+ Add Other Qualification
							</button>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
