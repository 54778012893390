import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { either, isNil, isEmpty, sum } from 'ramda';
import { combineLatest, Observable, Observer, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	first,
	map,
	mergeMap,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { getPerFrequency } from '../../../_shared/calculations/monthly-conversion';
import { ApplicationService } from '../../application/state/application.service';
import { PeopleEntitiesService } from '../../client-sop/people-entities/state/people-entities.service';
import { LoanModalComponent } from './../../../../../../shared/modal/crt/moat/loan-modal/loan-modal.component';
import { numUtil } from './../../../../../../util/util';
import { CrtMortgageService } from './../../state/crt-mortgage.service';
import { calculateLoanRepayment } from './state/loan.calculations';
import { Loan, LoanStructure } from './state/loan.model';
import { LoanService } from './state/loan.service';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';

@Component({
	selector: 'app-loan',
	templateUrl: './loan.component.html',
	styleUrls: ['./loan.component.scss'],
})
export class LoanComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() parentCRTId: number;
	@Input() adviceProcessId: number;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
	}>();

	form: UntypedFormGroup;
	isLoading$: Observable<boolean> = this.service.isLoading$;
	loanSplits$: Observable<Loan[]> = this.service.loanSplits$;
	loanStructure$: Observable<LoanStructure> = this.service.loanStructure$;
	remainingToAllocate$: Observable<number | string>;
	/**
	 * flag when a approved application http call is on going
	 */
	approvedApplicationIsLoading = false;

	submitted = false;

	applications$ = this.applicationService.applications$.pipe(
		filter((x) => !!x),
		map((x) =>
			x.map((a) =>
				ViewDisplayValue.Map(
					a?.cRTId,
					`${a?.name}` + (a?.bank ? ` (${a.bank})` : '')
				)
			)
		)
	);

	modalRef: BsModalRef;
	peopleList: ViewDisplayValue[];
	borrowers: ViewDisplayValue[];
	loanStructure: LoanStructure;

	constructor(
		private fb: UntypedFormBuilder,
		private service: LoanService,
		private modalService: BsModalService,
		private crtMortgageService: CrtMortgageService,
		private peopleEntitiesService: PeopleEntitiesService,
		private applicationService: ApplicationService,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.approvedApplicationIsLoading = true;
		this.service.isLoading$.pipe(
			filter((x) => !x),
			concatMap(() => this.service.getLoanStructure(this.parentCRTId)),
			tap(() => {
				this.prepareData();
				this.approvedApplicationIsLoading = false;
			}),
			take(1)
		).subscribe();

		this.peopleEntitiesService.policyOwnersWithCRT$
			.pipe(
				tap((x) => (this.peopleList = x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.remainingToAllocate$ = combineLatest([
			this.loanSplits$,
			this.form.valueChanges.pipe(startWith(null)),
		]).pipe(
			map(
				([x, y]) =>
					(y?.approvedMortgageAmount || 0) -
					sum(x?.map((l) => l?.loanAmount || 0))
			),
			map((x) => Math.round((x + Number.EPSILON) * 100) / 100),
			map((x) => numUtil.formatToCurrency2(x))
		);

		combineLatest([
			this.peopleEntitiesService.people$,
			this.peopleEntitiesService.dependants$,
			this.peopleEntitiesService.trusts$,
			this.peopleEntitiesService.company$,
		]).pipe(
			map(([p, d, t, c]) => {
				const pp = p?.map((x) => ViewDisplayValue.Map(x.customerId, x.name)) || [];
				const dd = d?.map((x) => ViewDisplayValue.Map(x.customerId, x.name)) || [];
				const tt = t?.map((x) => ViewDisplayValue.Map(x.customerId, x.name)) || [];
				const cc = c?.map((x) => ViewDisplayValue.Map(x.customerId, x.name)) || [];
				return [...pp, ...dd, ...tt, ...cc]?.filter((option) => +option.value);
			}),
			tap((x) => this.borrowers = x),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

	buildForm() {
		this.form = this.fb.group({
			approvedApplication: [
				'',
				[Validators.required, NoWhitespaceValidator, Validators.min(0.01)],
			],
			// expectedSettlementDate: [''],
			approvedMortgageAmount: ['', [Validators.required]],
			cashIncentiveOffered: [''],
		});
	}

	prepareData() {
		this.loanStructure$
			.pipe(
				tap((x) => (this.loanStructure = x)),
				tap((x) => {
					this.form.patchValue({
						approvedApplication: x?.approvedApplication,
						// expectedSettlementDate: MomentUtil.formatDateToMoment(
						// 	x?.expectedSettlementDate
						// ),
						approvedMortgageAmount: x?.approvedMortgageAmount,
						cashIncentiveOffered: x?.cashIncentiveOffered,
					});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	prepareFormValue() {
		const data = this.form.getRawValue();
		return {
			...data,
			// expectedSettlementDate: MomentUtil.formatToServerDate(
			// 	data.expectedSettlementDate
			// ),
			approvedMortgageAmount: numUtil.toValidNumber(
				data?.approvedMortgageAmount
			),
			cashIncentiveOffered: numUtil.toValidNumber(data?.cashIncentiveOffered),
			cRTId: this?.loanStructure?.cRTId || null,
			parentCRTId: +this.parentCRTId,
			adviceProcessId: +this.adviceProcessId,
			sectionCode: AdviceProcessSectionCodes.LoanStructure,
		} as LoanStructure;
	}

	formErrors() {
		return of(this.form.getRawValue()).pipe(
			withLatestFrom(this.loanSplits$),
			map(([data, loanSplit]) => {
				const errors = [];
				if (
					either(isNil, isEmpty)(data?.approvedApplication) ||
					+data?.approvedApplication === 0
				) {
					errors.push('Please select an Approved Application');
				}
				if (either(isNil, isEmpty)(data?.approvedMortgageAmount)) {
					errors.push('Please enter the Total Approved Lending');
				}
				if (loanSplit?.length === 0) {
					errors.push('Please add at least one loan split');
				}
				return errors;
			}),
			take(1)
		);
	}

	// Save Loan Structure
	save(isNext: boolean, checkValidations: boolean = true) {
		this.formErrors()
			.pipe(
				filter((errors) => {
					if (checkValidations && errors?.length > 0) {
						if (this.submitted) {
							// If already submitted, allow to proceed regardless of requirements
							this.submitted = false;
							return true;
						} else {
							// If first time submission and incomplete form requirements
							this.submitted = true;
							this.saveCompleted.emit({ isSuccess: false, isNext });
							this.loggerService.MultipleWarnings(errors);
							return false;
						}
					} else {
						this.submitted = false;
						return true;
					}
				}),
				mergeMap(() => of(this.prepareFormValue())),
				withLatestFrom(this.service.loanStructure$),
				tap(() => !isNext && this.service.isUpdating$.next(true)),
				tap(() => (this.approvedApplicationIsLoading = isNext ? false : true)),
				switchMap(([x, loanStructure]) => {
					const data = {
						...x,
						approvedApplication: +x?.approvedApplication
					}
					if (!!loanStructure?.cRTId) {
						return this.service
							.updateLoanStructure(data)
							.pipe(switchMap((ls) => this.service.get(+this?.parentCRTId)));
					} else {
						return this.service
							.addLoanStructure(data)
							.pipe(switchMap((ls) => this.service.get(+this?.parentCRTId)));
					}
				}),
				switchMap(() => this.service.loanStructure$.pipe(first())),
				tap(() => {
					if (!isNext) {
						this.approvedApplicationIsLoading = false;
					}
				}),
				tap(() => !isNext && this.service.isUpdating$.next(false)),
				take(1)
			)
			.subscribe(
				() => this.saveCompleted.emit({ isSuccess: true, isNext }),
				() => this.saveCompleted.emit({ isSuccess: false, isNext })
			);
	}

	perFrequncy(f) {
		return getPerFrequency(f);
	}

	checkPeopleList(people: []): string {
		const peopleList = this.peopleList;
		return peopleList
			?.filter((x) => people?.some((y) => +y === +x.value))
			?.map((x) => x.display)
			?.join(', ');
	}

	addLoanSplit() {
		const saveFn$ = (data) =>
			this.service.add({
				...data,
				cRTId: null,
				adviceProcessId: this.adviceProcessId,
				parentCRTId: this.parentCRTId,
				sectionCode: AdviceProcessSectionCodes.LoanSplit,
			});

		const cancelFn$ = () =>
			new Observable((obs: Observer<any>) => {
				obs.complete();
			});

		const initState = {
			header: 'Loan Split Details',
			message: 'Add new loan split',
			// Loan Type
			loanTypes$: this.crtMortgageService.MLT$,
			// Frequency
			frequencies$: this.crtMortgageService.APCRTF$,
			// Fixed Periods = CRM > Profile > Mortgage
			fixedPeriods$: this.crtMortgageService.MFPE$,
			// Borrowers = Fact Find > People
			borrowers: this.borrowers,
			getOwnerChoices: this.getOwnerChoices,

			calculateLoanRepayment,

			saveFn$,
			cancelFn$,
		};

		this.modalRef = this.modalService.show(LoanModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	editLoanSplit(l: Loan) {
		const saveFn$ = (data) =>
			this.service.update({
				...data,
				cRTId: l.cRTId,
				adviceProcessId: this.adviceProcessId,
				parentCRTId: this.parentCRTId,
			});

		const cancelFn$ = () =>
			new Observable((obs: Observer<any>) => {
				obs.complete();
			});

		const initState = {
			header: 'Loan Split Details',
			message: 'Edit loan split',
			// Loan Type
			loanTypes$: this.crtMortgageService.MLT$,
			// Frequency
			frequencies$: this.crtMortgageService.APCRTF$,
			// Fixed Periods = CRM > Profile > Mortgage
			fixedPeriods$: this.crtMortgageService.MFPE$,
			// Borrowers = Fact Find > People
			borrowers: this.borrowers,
			getOwnerChoices: this.getOwnerChoices,
			loan: l,
			calculateLoanRepayment,

			saveFn$,
			cancelFn$,
		};

		this.modalRef = this.modalService.show(LoanModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	confirmDelete(loan: Loan) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteLoanSplit(loan.cRTId);
			obs.complete();
		});
		const initState = {
			header: 'Delete Loan Split',
			message: `Are you sure you want to delete this?`,
			delete$: confirm,
			canDelete: true,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteLoanSplit(id: number) {
		this.service.delete(id).pipe(take(1)).subscribe();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
