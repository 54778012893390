import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { BookBreakdown } from './bb.model';
import { BookBreakdownStore } from './bb.store';


@Injectable()
export class BookBreakdownService {

  private readonly endpoint = 'widgets?type=BB';

  constructor(
    private api: ApiService,
    private store: BookBreakdownStore
  ) { }

  /**
   * Fetch and store Book Breakdown data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetBookBreakdown(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post3<BookBreakdown[]>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.bookBreakdowns = res;
      })),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
