import { numUtil } from '../../../../../util/util';
import * as R from 'ramda';

export type curFreqType =
	| 'Annually'
	| '6-Monthly'
	| 'Quarterly'
	| 'Monthly'
	| 'Fortnightly'
	| 'Weekly'
	| 'Daily'
	| 'Hourly';

export const convertToAnnual = (amount: number, curFreq: curFreqType) => {
	return amount && numUtil.isNumber(amount) && curFreq
		? amount * +freqToAnnual[curFreq]
		: 0;
};

export const convertToMonthly = (
	amount: number,
	curFreq: curFreqType = 'Annually'
) => {
	const getAnnual = convertToAnnual(+amount, curFreq);
	return +numUtil.formatToNumCurrency(R.divide(+getAnnual, 12));
};

export const freqToAnnual = {
	Annually: 1,
	'6-Monthly': 2,
	Quarterly: 4,
	Monthly: 12,
	Fortnightly: 26,
	Weekly: 52,
	Daily: 365,
	Hourly: 8760,
};

export const convertAnnualToCustomFreq = (
	amount: number,
	frequency: curFreqType = 'Monthly'
) => {
	// Convert annual to any frequency specified
	const freq = freqToAnnual[frequency];
	return +R.divide(+amount, freq);
};
