import { LrCustomerServiceState } from '../../models/services/lr-insurance/lr-insurance.model';
import { LRProviderGroupState } from '../../models/services/lr-insurance/lr-provider-group.model';
import MomentUtil from '../../../util/moment.util';

/********************* Policy status sorter *********************
/** Policy match
  * @param a
  * @param b
  * @returns {number}
  */
export const policyMatch = (regExp: RegExp, str: string): boolean => {
  const match = str?.match(regExp);
  return match && str === match[0];
};

/** Policy type sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const policyTypeSorter = (a: string, b: string, type: string): number => {
  const matchA = policyMatch(new RegExp(type), a ? a?.toLowerCase() : '');
  const matchB = policyMatch(new RegExp(type), b ? b?.toLowerCase() : '');

  if ((matchA)
    < (matchB)) {
    return 1;
  } else if ((matchA)
    > (matchB)) {
    return -1;
  } else {
    return 0;
  }
};

/** Policy type group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const policyTypeGroupSorter = (a: string, b: string): number => {
  return policyTypeSorter(a, b, 'primary') ||
    policyTypeSorter(a, b, 'increase') ||
    policyTypeSorter(a, b, 'servicing adviser') ||
    policyTypeSorter(a, b, 'not servicing');
};
/********************* End of Policy status sorter *********************

/********************* Policy status sorter *********************
/** Policy status sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const policyStatusSorter = (a: string, b: string, type: string): number => {
  const matchA = policyMatch(new RegExp(type), a ? a?.toLowerCase() : '');
  const matchB = policyMatch(new RegExp(type), b ? b?.toLowerCase() : '');

  if ((matchA)
    < (matchB)) {
    return 1;
  } else if ((matchA)
    > (matchB)) {
    return -1;
  } else {
    return 0;
  }
};

/** Policy status group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const policyStatusGroupSorter = (a: string, b: string): number => {
  return policyStatusSorter(a, b, 'inforce') ||
    policyStatusSorter(a, b, 'lead') ||
    policyStatusSorter(a, b, 'first meeting') ||
    policyStatusSorter(a, b, 'submitted') ||
    policyStatusSorter(a, b, 'underwriting') ||
    policyStatusSorter(a, b, 'notes/tests required') ||
    policyStatusSorter(a, b, 'offer of terms') ||
    policyStatusSorter(a, b, 'policy accepted') ||
    policyStatusSorter(a, b, 'inforce-pending') ||
    policyStatusSorter(a, b, 'incomplete app') ||
    policyStatusSorter(a, b, 'cancelled') ||
    policyStatusSorter(a, b, 'ntu') ||
    policyStatusSorter(a, b, 'lead ntu') ||
    policyStatusSorter(a, b, 'open ntu') ||
    policyStatusSorter(a, b, 'delayed app');
};
/********************* End of Policy status sorter *********************/

/********************* Id sorter *********************/
/** Id sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const idSorter = (a: LrCustomerServiceState, b: LrCustomerServiceState): number => a.customerServiceID - b.customerServiceID;
/********************* End of Id sorter *********************/

/********************* Mortgage IsActive sorter *********************/
/**isActive Sorter 
 * * @param a
 * @param b
 * @returns {number}
*/
export const lrInsuranceIsActiveSorter = (a:number,b:number):number => {
	if(a===2&&b!==2)  return 1;
	if(a!==2&&b==2) return -1;
	return 0;
}
/********************* End of isActive sorter *********************/

/** Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const subGroupSorter = (a: LrCustomerServiceState, b: LrCustomerServiceState): number => {
  return lrInsuranceIsActiveSorter(a.isActive, b.isActive)
    || policyStatusGroupSorter(a.policyStatus, b.policyStatus)
    || policyTypeGroupSorter(a.policyType, b.policyType)
    || idSorter(a, b);
};

/********************* Date sorter *********************/
export const dateGroupSorter = (a, b) => {
  const convertA = a ? MomentUtil.formatDateToMoment(a).unix() : 0;
  const convertB = b ? MomentUtil.formatDateToMoment(b).unix() : 0;

  return +(convertA === null) - +(convertB === null) || -(convertA > convertB) || +(convertA < convertB);
};
/********************* End of Date sorter *********************/

export const premiumSorter = (a: number, b: number) => {
  return b - a;
};

/** Main Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mainGroupSorter = (a: LRProviderGroupState, b: LRProviderGroupState): number => {
  // Sort by Status
  // Sort by start date
  return lrInsuranceIsActiveSorter(a.isActive, b.isActive)
    ||policyStatusGroupSorter(a.status, b.status)
    || dateGroupSorter(a.startDate, b.startDate)
    || premiumSorter(+a.aPI, +b.aPI);
};
