import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-copy-client-modal',
  templateUrl: './copy-client-modal.component.html',
  styleUrls: ['./copy-client-modal.component.scss']
})
export class CopyClientModalComponent implements OnInit {
	copy$: Observable<any>;
	header: string;
	message: string | any;

	constructor(public bsModalRef: BsModalRef) {
	}

	setupProps() {
	}

	setupSubs() {}

	copy() {
		this.copy$.subscribe();
		this.decline();
	}

	decline() {
		this.bsModalRef.hide();
	}

  ngOnInit(): void {
  }
}
