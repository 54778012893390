import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { sum, isNil } from 'ramda';
import { Observable, Observer, Subject } from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { AssetsLiabilitiesService } from '../../states/assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { AssetMapper } from './../../../../../shared/models/client-review-template/assets-liabilities/asset/asset.mapper';
import { KiwiSaverTypes } from '@models/services/kiwisaver/kiwisaver.model';
import { logMessage } from '@errorMsgs';

declare var $: any;

@Component({
	selector: 'app-other-assets-investments',
	templateUrl: './other-assets-investments.component.html',
	styleUrls: ['./other-assets-investments.component.scss'],
})
export class OtherAssetsInvestmentsComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Input() isAdviceProcessEnded: boolean;

	@Input() apcrta$: Observable<ViewDisplayValue[]>;
	@Input() policyOwners: ViewDisplayValue[];

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;
	elseMinusOthers = true;
	submitted = false;

	totalAssets$ = this.query.assets$.pipe(
		map((x) => sum((!isNil(x) ? x : []).map((a) => a?.value ?? 0)))
	);
	policyOwners$ = this.query.pciAndSciFromCrmAndCrtChoices$.pipe(
		map((people) => people?.filter((person) => +person.value))
	);

	totalAssets = this.service.totalAssets;
	adviceProcessId = this.query.getValue().adviceProcessId;

	isLoading$ = this.query.isAssetsLoading$;
	isEdit = false;
	isAdd = false;

	@Input() deceasedSciList: ViewDisplayValue[] = [];

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private service: AssetsLiabilitiesService,
		private query: ClientReviewTemplateQuery,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	get OtherArray() {
		return this.form.get('otherArray') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.prepareData();
	}

	buildForm() {
		this.form = this.fb.group({
			otherArray: this.fb.array([]),
		});
	}

	prepareData() {
		this.query.assets$
			.pipe(
				tap((x) => {
					(!isNil(x) ? x : []).forEach((a) => {
						if (a.assetStatus !== 'Sold') {
							const owners =
								!!a?.owner && typeof a.owner === 'string'
									? Array.isArray(JSON.parse(a.owner))
										? JSON.parse(a.owner)?.map((x) => x?.toString())
										: []
									: [];

							this.OtherArray?.push(
								this.fb.group({
									cRTId: [a.cRTId],
									asset: [a.asset, [Validators.required]],
									owner: [owners, [Validators.required]],
									value: [a.value, [Validators.required, Validators.min(0.01)]],
									description: [a.description],
									isEdit: [false],
									isLoading: [false],
									policyOwners: this.policyOwnerChoices(owners),
								})
							);
						}
					});
					this.form.disable();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.service.getOwnerChoices(owners, this.policyOwners);
	}

	disableFormItem(index: number) {
		this.OtherArray.controls[index].get('isEdit')?.setValue(false);
		this.OtherArray.controls[index].disable();
	}

	enableFormItem(index: number) {
		this.OtherArray.controls[index].enable();
		this.OtherArray.controls[index].get('isEdit')?.setValue(true);
	}

	editItem(index: number) {
		this.isEdit = true;
		this.isAdd = false;
		for (let i = 0; i < this.OtherArray?.length; i++) {
			if (index === i) {
				this.enableFormItem(index);
			} else {
				this.disableFormItem(i);
			}
		}
	}

	addOther() {
		this.isEdit = false;
		this.isAdd = true;

		let newPolicyOwners = this.policyOwners;

		if (this.deceasedSciList && this.deceasedSciList.length) {
			const deceasedIds = this.deceasedSciList.map((item) => `${item.value}`);
			newPolicyOwners = this.policyOwners.filter(
				(po) => !deceasedIds.includes(po.value)
			);
		}

		this.OtherArray.push(
			this.fb.group({
				cRTId: [0],
				asset: ['', [Validators.required]],
				owner: ['', [Validators.required]],
				value: ['', [Validators.required, Validators.min(0.01)]],
				description: [''],
				isEdit: [true],
				isLoading: [false],
				policyOwners: [newPolicyOwners],
			})
		);
	}

	deleteOther(index: number, remove?: boolean) {
		this.isEdit = true;
		this.setLoading(index, true);
		const cRTId = +this.OtherArray.controls[index].get('cRTId').value;
		const adviceProcessId = +this.query.getValue().adviceProcessId;
		this.service
			.getCrtById(cRTId)
			.pipe(
				map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
				map((x) => AssetMapper.mapToAssetDetails(x, adviceProcessId)),
				map((x) => ({
					...x,
					assetStatus: !remove ? 'Sold' : '',
					CRTId: cRTId,
					status: 0,
				})),
				mergeMap((x) =>
					this.service.updateAL(x, AdviceProcessSectionCodes.AssetsInvestments)
				),
				tap(() =>
					this.service.deleteState(
						cRTId,
						AdviceProcessSectionCodes.AssetsInvestments
					)
				),
				take(1)
			)
			.subscribe(
				() => {
					this.setLoading(index, false);
					this.buildForm();
					this.prepareData();
					this.isEdit = false;
				},
				() => {
					this.setLoading(index, false);
					this.buildForm();
					this.prepareData();
					this.isEdit = false;
				}
			);
	}

	deleteModal(index: number) {
		this.setLoading(index, true);
		const data = this.form.getRawValue()?.otherArray?.[index];
		const isInvestment = data?.asset === KiwiSaverTypes.Investment;
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteOther(index);
			obs.complete();
		});
		const message = isInvestment
			? logMessage.oat.shared.factFind.assetsLiabilities.investments.remove
			: logMessage.oat.shared.factFind.assetsLiabilities.otherAssets.sold;

		const initState = {
			message,
			header: `Delete ${isInvestment ? 'Investment' : 'Other Asset'}`,
			delete$: confirm,
			canDelete: true,
			confirmButton: 'Confirm'
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.modalService.onHide.pipe(take(1)).subscribe(() => {
			this.setLoading(index, false);
		});
	}

	cancelEditOther(index: number) {
		this.resetEditAddMode();
		this.disableFormItem(index);
		this.revertToOriginalData(index);
	}

	revertToOriginalData(index: number) {
		const cRTId = this.OtherArray.controls[index].get('cRTId').value;
		const originalData = this.query
			.getValue()
			.assets.find((asset) => asset.cRTId === +cRTId);
		if (originalData) {
			this.OtherArray.controls[index].patchValue({
				...originalData,
				owner: [...JSON.parse(originalData.owner)]?.map((id) => id.toString()),
			});
		}
	}

	cancelOther(index: number) {
		this.resetEditAddMode();
		this.OtherArray.removeAt(index);
	}

	resetEditAddMode() {
		this.isEdit = false;
		this.isAdd = false;
	}

	collapseMoreOthers() {
		$('#collapseOthers').toggle();
		this.elseMinusOthers = false;
	}
	collapseLessOthers() {
		$('#collapseOthers').toggle();
		this.elseMinusOthers = true;
	}

	saveOther(index: number, isAdd: boolean) {
		this.submitted = true;
		if (this.OtherArray.controls[index].invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		const formValue = this.OtherArray.controls[index].value;
		const data = AssetMapper.mapToAssetDetails(formValue, this.adviceProcessId);
		this.OtherArray.controls[index].get('isLoading').setValue(true);

		if (isAdd) {
			this.service
				.addAL(data, AdviceProcessSectionCodes.AssetsInvestments)
				.subscribe(
					() => {
						this.OtherArray.controls[index].get('isLoading').setValue(false);
						this.submitted = false;
						this.buildForm();
						this.prepareData();
						this.resetEditAddMode();
					},
					() => {
						this.OtherArray.controls[index].get('isLoading').setValue(false);
						this.submitted = false;
						this.resetEditAddMode();
					}
				);
		} else {
			this.service
				.updateAL(data, AdviceProcessSectionCodes.AssetsInvestments)
				.subscribe(
					() => {
						this.OtherArray.controls[index].get('isLoading').setValue(false);
						this.submitted = false;
						this.buildForm();
						this.prepareData();
						this.resetEditAddMode();
					},
					() => {
						this.OtherArray.controls[index].get('isLoading').setValue(false);
						this.submitted = false;
						this.resetEditAddMode();
					}
				);
		}
	}

	setLoading(index: number, isLoading: boolean) {
		this.OtherArray.controls[index].get('isLoading').setValue(isLoading);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
