import { Injectable } from '@angular/core';

import { concat, from, of, zip } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../../../../core/base/api.service';

import { applyTransaction } from '@datorama/akita';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '../../../../../domain/user/user.query';

import { BusinessProfileQuery } from '../business-profile.query';
import { BusinessProfileStore } from '../business-profile.store';

import {
	FgCustomerServiceGroupState,
	FgCustomerServiceState,
} from '../../../../../shared/models/services/fg-insurance/fg-insurance.model';

import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import * as R from 'ramda';
import { BusinessConfigService } from 'src/app/domain/business-config/business-config.service';
import { NoteTypes } from 'src/app/shared/models/notes/note.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { objectUtil } from 'src/app/util/util';
import { BusinessService } from '../../../../../core/business/business.service';
import { util } from '../../../../../core/util/util.service';
import { BusinessConfigQuery } from '../../../../../domain/business-config/business-config.query';
import { NoteState } from '../../../../../shared/models/activity-timeline/activity-timeline.model';
import { fgServiceUtil } from '../../../../../shared/services/service-utils/fg-insurance.util';
import MomentUtil from '../../../../../util/moment.util';
import { BusinessProfileService } from '../business-profile.service';
import { BusinessProfileUtilsService } from '../business-profile.utils.service';

@Injectable()
export class FgInsuranceService extends BusinessProfileUtilsService {
	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected blStaffsQuery: BLStaffsQuery,
		protected store: BusinessProfileStore,
		protected query: BusinessProfileQuery,
		protected api: ApiService,
		protected businessService: BusinessService,
		protected userQuery: UserQuery,
		protected profileService: BusinessProfileService,
		protected businessConfigQuery: BusinessConfigQuery,
		protected businessConfigService: BusinessConfigService,
	) {
		super(store, query, dropdownValueQuery, blStaffsQuery, businessConfigQuery, userQuery);
	}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	private updateOriginalAndAddRenewal(req: FgCustomerServiceState, reference: string, referenceNo: string) {
		return this.businessService
			.AddFgInsurance({
				...req,
				serviceCode: ServicesCodes.FG,
				customerServiceID: 0,
			})
			.pipe(
				withLatestFrom(this.query.fgInsurance$),
				mergeMap(([newFGId, fgInsurance]) => {
					return this.businessService
						.UpdateRenewal(req.customerID, {
							reference,
							referenceNo,
							sourceId: req.sourceId,
						})
						.pipe(map((renewal) => ({ renewal, newFGId, fgInsurance })));
				}),
			);
	}

	createFGInsurance(
		req: FgCustomerServiceState,
		_linkDocumentId: string,
		isAddNewRenewal?: boolean,
		reference?: string,
		referenceNo?: string,
		_customerServiceId?: number,
	) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			mergeMap((x) => {
				if (isAddNewRenewal) {
					return this.updateOriginalAndAddRenewal(newReq, reference, referenceNo);
				}
				return this.businessService
					.AddFgInsurance({
						...x,
						serviceCode: ServicesCodes.FG,
						customerServiceID: 0,
					})
					.pipe(
						map((newFGId) => {
							return { newFGId };
						}),
					);
			}),
			tap((result) => (req.customerServiceID = +result.newFGId)),
			// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
			switchMap((result: any) =>
				req.note
					? this.businessService
							.AddNote({
								CustomerID: req.customerID,
								CustomerServiceID: req.customerServiceID,
								Notes: req.note,
								ActivityType: NoteTypes.FG,
								StaffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
							})
							.pipe(
								map((x) => {
									result.noteId = +x;
									return result;
								}),
							)
					: of(result),
			),
			withLatestFrom(this.query.fgInsurance$),
			tap(([result, fgInsurance]) =>
				applyTransaction(() => {
					if (result?.newFGId) {
						if (req.note) {
							this.profileService.addToActivityTimelineNotes(
								+result?.newFGId,
								req.note,
								NoteTypes.FG,
								req.customerID,
								req.customerServiceID,
							);
						}
						const fgState = produce(fgInsurance, (draft) => {
							if (isAddNewRenewal) {
								const renewedFG = objectUtil.mapPascalCaseToCamelCase(result.renewal);
								const renewedFGIndex = draft.fGs?.findIndex(
									(fg) =>
										fg.provider === renewedFG.provider &&
										fg.policyNumber === renewedFG.policyNumber &&
										fg.customerServices.find((cs) => cs.customerServiceID === req.sourceId),
								);
								if (renewedFGIndex !== -1) {
									draft.fGs.splice(renewedFGIndex, 1, {
										...draft.fGs[renewedFGIndex],
										...renewedFG,
									});
								}
							}

							let group: WritableDraft<FgCustomerServiceGroupState>;

							if (req.tracking === 'Original' || req.tracking === 'Renewal') {
								group = draft.fGs?.find(
									(fg) =>
										fg.provider === req.insurer &&
										fg.policyNumber === req.fGPolicyNumber &&
										fg.tracking !== 'Original' &&
										fg.tracking !== 'Renewal',
								);
							} else {
								group = draft.fGs?.find((fg) => fg.provider === req.insurer && fg.policyNumber === req.fGPolicyNumber);
							}

							if (!group) {
								// New Group with mortgage and note
								draft.fGs.push({
									provider: req.insurer,
									policyNumber: req.fGPolicyNumber,
									customerServices: [
										{
											...req,
											note: '',
											notes: req.note
												? [
														{
															notesID: +result?.newFGId,
															customerServiceID: req.customerServiceID,
															notes: req.note,
															isActive: true,
															createDateTime: util.MomentNowNz(),
															createdByStaffId: this.userQuery.getValue().StaffID,
															customerID: req.customerID,
															staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
														},
													]
												: [],
										},
									],
									status: req.status,
									tracking: req.tracking,
									sourceId:
										req.tracking === 'Renewal'
											? req.customerServiceID
											: req.sourceId
												? req.sourceId
												: req.customerServiceID,
								});

								if (
									req.status &&
									(req.status?.toLowerCase() === 'inforce' || req.status?.toLowerCase() === 'inforce-pending')
								) {
									draft.totalInforceApi = +draft.totalInforceApi + +req.premium;
								}
							} else {
								// New FG
								draft.fGs?.forEach((y) => {
									if (
										y.provider === req.insurer &&
										y.policyNumber === req.fGPolicyNumber &&
										y.customerServices.find((cs) => cs.customerServiceID === req.sourceId)
									) {
										y.customerServices.push({
											...req,
											note: '',
											notes: req.note
												? [
														{
															notesID: +result?.newFGId,
															customerServiceID: req.customerServiceID,
															notes: req.note,
															isActive: true,
															createDateTime: util.MomentNowNz(),
															createdByStaffId: this.userQuery.getValue().StaffID,
															customerID: req.customerID,
															staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
														},
													]
												: null,
										});
									}
								});
							}
						});

						// Removes empty fgs
						fgState.fGs?.filter((m) => m.customerServices.length !== 0);
						this.store.setFgInsurance(fgServiceUtil(fgState, this.businessConfigService.companyCode()));
					}
				}),
			),
			tap(() => this.profileService.getCriterias(req.customerID).pipe(take(1)).subscribe()),
			tap(() => this.profileService.getClientHistories(req.customerID).pipe(take(1)).subscribe()),
		);
	}

	updateRenewedOriginalWithNote(req: {
		customerID;
		customerServiceID;
		reference;
		referenceNo;
		sourceId;
	}) {
		const note = 'Policy renewed and ended';
		return zip(
			this.businessService.UpdateRenewal(req.customerID, {
				reference: req.reference,
				referenceNo: req.referenceNo,
				sourceId: req.sourceId,
			}),
			this.businessService.AddNote({
				CustomerID: req.customerID,
				CustomerServiceID: req.customerServiceID,
				Notes: note,
				ActivityType: NoteTypes.FG,
				StaffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
			}),
			this.query.fgInsurance$,
		).pipe(
			map(([renewal, noteId, fgInsurance]) => {
				if (noteId) {
					this.profileService.addToActivityTimelineNotes(
						+noteId,
						note,
						NoteTypes.FG,
						req.customerID,
						req.customerServiceID,
					);
				}
				const fgInsuranceState = objectUtil.mapPascalCaseToCamelCase(renewal) as FgCustomerServiceGroupState;
				const fgList = fgInsurance.fGs.filter(
					(fg) => !(fg.provider === req.reference && fg.policyNumber === req.referenceNo),
				);
				const newFgInsurance = {
					...fgInsurance,
					fGs: fgList,
				};

				const fgState = produce(newFgInsurance, (draft) => {
					const group = draft.fGs?.find(
						(fg) => fg.provider === fgInsuranceState.provider && fg.policyNumber === fgInsuranceState.policyNumber,
					);

					if (!group) {
						draft.fGs.push({
							provider: fgInsuranceState.provider,
							policyNumber: fgInsuranceState.policyNumber,
							customerServices: fgInsuranceState.customerServices,
							color: 'red',
						});
					}
				});
				this.store.setFgInsurance(fgServiceUtil(fgState, this.businessConfigService.companyCode()));
			}),
			finalize(() => {
				this.profileService.getClientHistories(req.customerID).pipe(take(1)).subscribe();
			}),
		);
	}

	updateFGInsurance(req: FgCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			withLatestFrom(this.FGPolicyOwners$),
			map(([x, ac]) => {
				return {
					...x,
					policyOwners: x.policyOwners
						? JSON.stringify(JSON.parse(x.policyOwners)?.filter((y) => ac?.find((c) => c.value === y)))
						: x.policyOwners,
				};
			}),
			mergeMap((x) =>
				this.businessService.UpdateFgInsurance({
					...x,
					serviceCode: ServicesCodes.FG,
				}),
			),
			switchMap((status) =>
				req.note
					? this.businessService.AddNote({
							CustomerID: req.customerID,
							CustomerServiceID: req.customerServiceID,
							Notes: req.note,
							ActivityType: NoteTypes.FG,
							StaffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
						})
					: of(status),
			),
			withLatestFrom(this.query.fgInsurance$, this.businessConfigQuery.fgUpdateV1Enabled$),
			tap(([x, fgInsurance, fGUpdateV1Feature]) =>
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							NoteTypes.FG,
							req.customerID,
							req.customerServiceID,
						);
					}
					const fgState = produce(fgInsurance, (draft) => {
						let fgList = draft?.fGs || [];
						const group = fgList?.find(
							(fg) =>
								fg.provider === req.insurer &&
								fg.policyNumber === req.fGPolicyNumber &&
								fg.customerServices.find((cs) => cs.customerServiceID === req.customerServiceID),
						);

						if (!group) {
							const services = [
								{
									...req,
									note: '',
									notes: req.note
										? [
												{
													notesID: +x,
													customerServiceID: req.customerServiceID,
													notes: req.note,
													isActive: true,
													createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
													createdByStaffId: this.userQuery.getValue().StaffID,
													customerID: req.customerID,
													staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
												},
											]
										: req.notes && req.notes.length > 0
											? req.notes
											: [],
								},
							];
							const original = services?.find((x) => x?.tracking === 'Original');
							fgList.push({
								provider: req.insurer,
								policyNumber: req.fGPolicyNumber,
								customerServices: services,
								status: req.status,
								sourceId: req.tracking === 'Original' ? original?.customerServiceID : req.sourceId,
								tracking: req.tracking,
							});
						} else {
							fgList?.forEach((fg) => {
								if (
									fg.provider === req.insurer &&
									fg.policyNumber === req.fGPolicyNumber &&
									fg.customerServices.find((cs) => cs.customerServiceID === req.customerServiceID)
								) {
									// Update and add note
									fg.customerServices?.forEach((cs2, i) => {
										if (cs2.customerServiceID === req.customerServiceID) {
											fg.customerServices[i] = req;

											if (req.note) {
												fg.customerServices[i].notes?.unshift({
													notesID: +x,
													customerServiceID: req.customerServiceID,
													notes: req.note,
													isActive: true,
													createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
													createdByStaffId: this.userQuery.getValue().StaffID,
													customerID: req.customerID,
													staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
												});
											} else {
												fg.customerServices[i].notes = cs2.notes;
											}

											fg.customerServices[i].note = '';
										}
									});
								}

								// NZFA Update fg status
								if (
									fGUpdateV1Feature &&
									req.status.toLowerCase() === 'inforce' &&
									req.tracking.toLowerCase() === 'renewal' &&
									fg.sourceId === req.sourceId
								) {
									const status = 'Closed/Renewed';
									fg.status = status;
									fg.customerServices = fg?.customerServices?.map((cs) => {
										if (cs.customerServiceID === req.sourceId) {
											const odata = {
												...cs,
												status,
											};
											return odata;
										}
										return cs;
									});
								}
							});
						}
						// Removes services that dont belong to the group
						fgList = fgList?.map((fg) => {
							return fg.provider !== req.insurer || fg.policyNumber !== req.fGPolicyNumber
								? {
										...fg,
										customerServices: fg.customerServices?.filter(
											(cs) => cs.customerServiceID !== req.customerServiceID,
										),
									}
								: fg;
						});

						// Removes empty Group
						fgList = fgList.filter((fg) => fg.customerServices.length > 0);
						draft.fGs = fgList;
					});

					this.store.setFgInsurance(fgServiceUtil(fgState, this.businessConfigService.companyCode()));
				}),
			),
			tap(() => this.profileService.getCriterias(req.customerID).pipe(take(1)).subscribe()),
			tap(() => this.profileService.getClientHistories(req.customerID).pipe(take(1)).subscribe()),
		);
	}

	deleteFGInsurance(req: FgCustomerServiceState) {
		return of(req).pipe(
			mergeMap((x) => this.businessService.DeactivateService(+x.customerServiceID)),
			withLatestFrom(this.query.fgInsurance$),
			tap(([x, fgState]) => {
				applyTransaction(() => {
					if (x) {
						const state = produce(fgState, (draft) => {
							draft.fGs = draft.fGs?.map((fg) => {
								fg.customerServices = fg.customerServices?.filter((f) => req.customerServiceID !== f.customerServiceID);
								return fg;
							});
							// Removes empty fgs
							draft.fGs = draft.fGs?.filter((fg) => fg.customerServices.length !== 0);
						});

						this.store.setFgInsurance(fgServiceUtil(state, this.businessConfigService.companyCode()));
					}
				});
			}),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline?.notes?.filter((n) => n.customerServiceID !== req.customerServiceID);
					this.store.setActivityTimeline({
						activities: activityTimeline?.activities,
						notes: notesState,
					});
				}),
			),
			tap(() => this.profileService.getCriterias(req.customerID).pipe(take(1)).subscribe()),
			tap(() => this.profileService.getClientHistories(req.customerID).pipe(take(1)).subscribe()),
			catchError(() => of('')),
		);
	}

	archiveFGInsurance(data: FgCustomerServiceState, provider: string, policyNumber: string, isArchive: boolean) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], data));
		return of(newReq).pipe(
			withLatestFrom(this.FGPolicyOwners$),
			map(([x, ac]) => {
				return {
					...x,
					policyOwners: x.policyOwners
						? JSON.stringify(JSON.parse(x.policyOwners)?.filter((y) => ac?.find((c) => c.value === y)))
						: x.policyOwners,
				};
			}),
			mergeMap((x) =>
				this.businessService.UpdateFgInsurance({
					...x,
					isActive: isArchive ? 2 : 1,
					serviceCode: ServicesCodes.FG,
				}),
			),
			withLatestFrom(this.query.fgInsurance$),
			tap(([x, fgState]) =>
				applyTransaction(() => {
					if (x) {
						const state = produce(fgState, (draft) => {
							draft.fGs = draft.fGs?.map((fg) =>
								fg.provider === provider && fg.policyNumber === policyNumber
									? // Check if FG exist in this group, if yes then update
										fg.customerServices?.find((cs) => cs.customerServiceID === newReq.customerServiceID)
										? // If existing
											{
												// Update FG
												...fg,
												customerServices: fg.customerServices?.map((cs) =>
													cs.customerServiceID === newReq.customerServiceID
														? {
																...newReq,
																isActive: isArchive ? 2 : 1,
																notes: data.notes,
															}
														: cs,
												),
											}
										: fg
									: fg,
							);
						});

						this.store.setFgInsurance(fgServiceUtil(state, this.businessConfigService.companyCode()));
					}
				}),
			),
			tap(() => this.profileService.getCriterias(newReq.customerID).pipe(take(1)).subscribe()),
			catchError(() => of('')),
		);
	}

	deleteFGInsuranceNote(id: number, data, provider, policyNumber) {
		return of(data).pipe(
			mergeMap(() => this.profileService.deleteActivityNote(id)),
			withLatestFrom(this.query.fgInsurance$),
			tap(([x, fgState]) =>
				applyTransaction(() => {
					if (x) {
						const state = produce(fgState, (draft) => {
							draft.fGs = draft.fGs?.map((fg) =>
								fg.provider === provider && fg.policyNumber === policyNumber
									? // Check if FG exist in this group, if yes then update
										fg.customerServices?.find((cs) => cs.customerServiceID === data.customerServiceID)
										? // If existing
											{
												// Update FG
												...fg,
												customerServices: fg.customerServices?.map((cs) =>
													cs.customerServiceID === data.customerServiceID
														? {
																...data,
																// @ts-ignore-next
																notes: cs.notes?.filter((n) => n.notesID !== id),
															}
														: cs,
												),
											}
										: fg
									: fg,
							);
						});

						this.store.setFgInsurance(state);
					}
				}),
			),
			catchError(() => of('')),
		);
	}

	syncNote(notes: NoteState) {
		return of(notes).pipe(
			withLatestFrom(this.query.fgInsurance$),
			tap(([note, fgState]) =>
				applyTransaction(() => {
					const state = produce(fgState, (draft) => {
						draft.fGs = draft.fGs?.map((fg) =>
							fg.customerServices?.find((cs) => cs.customerServiceID === note.customerServiceID)
								? {
										...fg,
										customerServices: fg.customerServices?.map((cs) =>
											cs.customerServiceID === note.customerServiceID
												? {
														...cs,
														// @ts-ignore-next
														notes: cs.notes?.filter((n) => note.notesID !== n.notesID),
													}
												: cs,
										),
									}
								: fg,
						);
					});
					this.store.setFgInsurance(fgServiceUtil(state, this.businessConfigService.companyCode()));
				}),
			),
		);
	}

	upsertFGLinkDocument(id, provider, policyNumber, customerServiceID) {
		const fgState = this.query.getValue().fgInsurance;

		const first$ = of({
			CustomerServiceId: customerServiceID,
			CustomerServiceType: ServicesCodes.FG,
			MetaKey: 'Document Link',
			MetaValue: id,
		});

		return first$.pipe(
			mergeMap(() =>
				concat(
					first$,
					from([customerServiceID]).pipe(
						map((i) => {
							return {
								CustomerServiceId: i,
								CustomerServiceType: ServicesCodes.FG,
								MetaKey: 'Document Link',
								MetaValue: id,
							};
						}),
						concatMap((req2) =>
							this.businessService.UpsertDocument(req2).pipe(
								tap((x) => {
									applyTransaction(() => {
										if (x) {
											const state = produce(fgState, (draft) => {
												draft.fGs = draft.fGs?.map((fg) => {
													if (
														fg.provider === provider &&
														fg.policyNumber === policyNumber &&
														(fg.sourceId === customerServiceID ||
															fg.customerServices.find((cs) => +cs.customerServiceID === +customerServiceID))
													) {
														fg.customerServices?.forEach((cs2, i) => {
															if (cs2.customerServiceID === customerServiceID) {
																fg.customerServices[i] = {
																	...cs2,
																	documentLink: id,
																};
															}
														});
														return {
															...fg,
															linkDocument: id,
														};
													} else {
														return fg;
													}
												});
											});

											this.store.setFgInsurance(fgServiceUtil(state, this.businessConfigService.companyCode()));
										}
									});
								}),
							),
						),
					),
				),
			),
			catchError(() => of('')),
		);
	}

	addNote(req: FgCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			switchMap(() =>
				this.businessService.AddNote({
					CustomerID: req.customerID,
					CustomerServiceID: req.customerServiceID,
					Notes: req.note,
					ActivityType: NoteTypes.FG,
					StaffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
				}),
			),
			withLatestFrom(this.query.fgInsurance$),
			tap(([x, fgInsurance]) =>
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							NoteTypes.FG,
							req.customerID,
							req.customerServiceID,
						);
					}
					const fgState = produce(fgInsurance, (draft) => {
						let fgList = draft?.fGs || [];
						const group = fgList?.find((lr) => lr.provider === req.insurer && lr.policyNumber === req.fGPolicyNumber);

						if (!group) {
							fgList.push({
								provider: req.insurer,
								policyNumber: req.fGPolicyNumber,
								customerServices: [
									{
										...req,
										note: '',
										notes: req.note
											? [
													{
														notesID: +x,
														customerServiceID: req.customerServiceID,
														notes: req.note,
														isActive: true,
														createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
														createdByStaffId: this.userQuery.getValue().StaffID,
														customerID: req.customerID,
														staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
													},
												]
											: req.notes && req.notes.length > 0
												? req.notes
												: [],
									},
								],
								status: req.status,
							});
						} else {
							fgList?.forEach((fg) => {
								if (fg.provider === req.insurer && fg.policyNumber === req.fGPolicyNumber) {
									const service = fg.customerServices?.find((cs) => cs.customerServiceID === req.customerServiceID);

									if (!service) {
										// push on current service
										fg.customerServices.push(req);
									} else {
										// Update and add note
										fg.customerServices?.forEach((cs2, i) => {
											if (cs2.customerServiceID === req.customerServiceID) {
												fg.customerServices[i] = req;

												if (req.note) {
													fg.customerServices[i].notes?.unshift({
														notesID: +x,
														customerServiceID: req.customerServiceID,
														notes: req.note,
														isActive: true,
														createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
														createdByStaffId: this.userQuery.getValue().StaffID,
														customerID: req.customerID,
														staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
													});
												} else {
													fg.customerServices[i].notes = cs2.notes;
												}

												fg.customerServices[i].note = '';
											}
										});
									}
								}
							});
						}
						// Removes services that dont belong to the group
						fgList = fgList?.map((fg) => {
							return fg.provider !== req.insurer || fg.policyNumber !== req.fGPolicyNumber
								? {
										...fg,
										customerServices: fg.customerServices?.filter(
											(cs) => cs.customerServiceID !== req.customerServiceID,
										),
									}
								: fg;
						});

						// Removes empty Group
						fgList = fgList?.filter((fg) => fg.customerServices.length > 0);
						draft.fGs = fgList;
					});
					this.store.setFgInsurance(fgServiceUtil(fgState, this.businessConfigService.companyCode()));
				}),
			),
			tap(() => this.profileService.getClientHistories(req.customerID).pipe(take(1)).subscribe()),
		);
	}
}
