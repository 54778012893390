import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import produce from 'immer';
import { ActivityRequest, ActivitySearchModel, Row } from './activity.model';
import * as R from 'ramda';
import { ActivityUiStore } from './activity-ui.store';
import { Injectable } from '@angular/core';

interface ColumnWidth {
	metakey: string;
	width: number;
}

export interface ActivityState extends EntityState<Row> {
	columns: string[];
	columnWidths: ColumnWidth[];
	count: number;
	isComplete: boolean;
	searchForm: ActivityRequest;
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activities', idKey: 'ActivityId' })
export class ActivityStore extends EntityStore<ActivityState, Row> {
	constructor(public uiStore: ActivityUiStore) {
		super({
			columns: [],
			columnWidths: [],
			count: 0,
			isComplete: false,
			searchForm: null,
		});
	}

	setColumns(columns: string[]) {
		this.update(produce(x => { x.columns = columns; }));
	}

	setColumnWidths(columnWidths: ColumnWidth[]) {
		this.update(produce(x => {
			x.columnWidths = columnWidths;
		}));
	}

	updateActivity(ac: ActivitySearchModel) {
		this.update(ac.ActivityId, produce(draft => {
			draft = ac;
		})
		);
	}

	setSearchForm(form: ActivityRequest) {
		this.update(
			produce(x => {
				x.searchForm = form;
			})
		);
	}

	updateField(ActivityId, MetaKey: string, MetaValue: string) {
		this.update(ActivityId, produce(draft => {
			const fields = R.omit([
				'ActivityId',
				'CustomerId',
				'AdviserId',
				'AssignedToName',
				'link',
				'ContactStatus',
				'ContactType',
				'CreatedByName',
				'IsCompany',
				'IsAccessible',
				'LeadGenName',
				'LeadOrigin'
			], draft);
			Object.values(fields).find(x => x.metakey === MetaKey).value = MetaValue;
		}));
	}
}
