import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { IChartistData, IChartistAnimationOptions, IBarChartOptions } from 'chartist';
import { ChartType, ChartEvent } from 'ng-chartist';
import * as R from 'ramda';
import { BusinessConfigQuery } from '../../domain/business-config/business-config.query';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import MomentUtil from '../../util/moment.util';
import { numUtil } from '../../util/util';
import * as numeral from 'numeral';
import * as Chartist from 'chartist';

/**
 * @description
 * Reusable bar chart component.
 * Shows bar chart.
 */
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartComponent implements OnInit, OnDestroy {
  /**
   * chartist data
   */
  @Input() data: IChartistData = {
    series: [],
    labels: []
  };
  @Input() showCurrencySignAxisY = false;
  @Input() isFinancialYear = false;

  /**
   * Type of chartist to generate.
   * Could be readonly?
   */
  type: ChartType = 'Bar';
  /**
   * Chartist bar chart options
   */
  options: IBarChartOptions = {
    axisX: {
      showGrid: true,
      showLabel: true,
    },
    axisY: {
      showLabel: true,
      offset: 70,
      scaleMinSpace: 10,
      onlyInteger: true,
      labelInterpolationFnc: value => {
        return this.showCurrencySignAxisY ? `${numeral(value).format('$0,0')}` : `${numUtil.formatToCount(value)}`;
      }
    },
    height: '100%',
    width: '100%',
    distributeSeries: true,
  };
  /**
   * Sort of lifecycle hook for chartist.
   */
  events: ChartEvent;

  /**
   * Stroke colors
   */
  strokes: string[] = [];

  /** Observable that should trigger unsubscription to others */
  onDestroy$ = new Subject<void>();

  /** Theme config object */
  themeConfig$ = this.businessConfigQuery.themeConfig$.pipe(filter(x => !!x));

  constructor(private cd: ChangeDetectorRef, private businessConfigQuery: BusinessConfigQuery) {}

  /**
   * For Manual refresh. Chartist doesn't expose its renderChart method
   * so we'll refresh the data instead
   */
  public refresh() {
    this.data = R.clone(this.data);
    this.cd.detectChanges();
  }

  get isFY() {
    return !!this.isFinancialYear;
  }

  /**
   * Setup chartist event
   */
  ngOnInit() {
    // Current quarter in Calendar
    let quarter = MomentUtil.createMomentNz().quarter();

    // For Financial Current Quarter
    quarter = this.isFY ? (quarter > 1 ? --quarter : 4) : quarter;

    this.themeConfig$.subscribe(x => {
      const widgetColour1 = x.widgetcolor1 && x.widgetcolor1 !== '' ? x.widgetcolor1 : x.primarycolor;
      const widgetColour2 = x.widgetcolor2 && x.widgetcolor2 !== '' ? x.widgetcolor2 : x.secondarycolor;
      // this.strokes = [widgetColour1, '#d5dee1', '#e3eff6', widgetColour2];
      this.strokes = ['#888888', '#d5dee1', '#e3eff6', '#D3D3D3']?.map(
        // Current quarter will change the default color into widgetColour 1 or Primary
        (color, index) => (index === quarter - 1 ? widgetColour1 : color)
      );
    });

    this.events = {
      draw: data => {
        if (data.type === 'bar') {
          data.element.animate({
            y2: {
              dur: '0.5s',
              from: data.y1,
              to: data.y2,
              easing: 'easeOutQuad'
            } as IChartistAnimationOptions
          });

          if (data.seriesIndex <= this.strokes.length - 1) {
            data.element.attr({
              style: `stroke: ${this.strokes[data.seriesIndex]}; stroke-width:  ${data.axisX.stepLength}px;`
            });
          } else {
            data.element.attr({ style: `stroke-width:  ${data.axisX.stepLength}px;` });
          }
        }
      }
    };
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
