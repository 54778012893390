import { PropertyAssetCustomerServiceState } from '../../models/services/property-asset/property-asset';

/** Policy match
 * @param a
 * @param b
 * @returns {number}
 */
export const match = (regExp: RegExp, str: string): boolean => {
	const match = str?.match(regExp);
	return match && str === match[0];
};

/** Policy status sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const sorter = (a: string, b: string, type: string): number => {
	const matchA = match(new RegExp(type), a ? a?.toLowerCase() : '');
	const matchB = match(new RegExp(type), b ? b?.toLowerCase() : '');

	if (matchA < matchB) {
		return 1;
	} else if (matchA > matchB) {
		return -1;
	} else {
		return 0;
	}
};

/** Policy status group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const statusGroupSorter = (a: string, b: string): number => {
	return (
		sorter(a, b, 'current') ||
		sorter(a, b, 'current asset') ||
		sorter(a, b, 'current liability') ||
		sorter(a, b, 'potential purchase') ||
		sorter(a, b, 'sold') ||
		sorter(a, b, 'sold asset') ||
		sorter(a, b, '') ||
		sorter(a, b, null) ||
		sorter(a, b, 'discharged')
	);
};

/** Mortgage type group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const typeGroupSorter = (a: string, b: string): number => {
	return sorter(a, b, 's') || sorter(a, b, 'a');
};

export const valuationSorter = (a: number, b: number) => {
	return +(a === null) - +(b === null) || -(a > b) || +(a < b);
};

/** Main Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mainGroupSorter = (
	a: PropertyAssetCustomerServiceState | any,
	b: PropertyAssetCustomerServiceState | any
): number => {
	return (
		statusGroupSorter(a?.status, b?.status) ||
		typeGroupSorter(a?.sectionCode, b?.sectionCode) ||
		valuationSorter(+a?.gRM1Value, +b?.gRM1Value) ||
		valuationSorter(+a?.value, +b?.value)
	);
};
