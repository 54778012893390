import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ExistingPolicyStructureState } from '@models/client-review-template/existing-policy-structure/existing-policy-structure.model';

@Injectable()
@StoreConfig({ name: 'ExistingPolicyStructure', idKey: 'cRTId' })
export class ExistingPolicyStructureStore extends EntityStore<ExistingPolicyStructureState> {
	constructor() {
		super({});
	}
}
