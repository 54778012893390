import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownRoutingModule } from './dropdown-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { DropdownComponent } from './views/dropdown/dropdown.component';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { DropdownResolver } from './resolver/dropdown-resolver.service';
import { DropdownService } from '../../core/dropdown/dropdown.service';
import { DropdownValuesService } from 'src/app/core/dropdown/dropdown-values.service';


@NgModule({
  imports: [CommonModule, DropdownRoutingModule, SharedModule,
    SortableModule.forRoot()],
  declarations: [DropdownComponent],
  providers: [
    DropdownResolver,
    DropdownService,
    DropdownValuesService
  ],
})
export class DropdownModule { }
