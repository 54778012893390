import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { InputModule } from '@shared/components/input/input.module';
import { UserDocumentModalComponent } from './user-document-modal.component';

@NgModule({
	declarations: [UserDocumentModalComponent],
	imports: [CommonModule, SharedModule, InputModule],
	exports: [UserDocumentModalComponent],
})
export class UserDocumentModalModule {}
