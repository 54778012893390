export interface ConfirmationCallQuestion {
	qId: number;
	question: string;
	answer: string;
	orderNo: number;
	isDefault: boolean;
	status: number | boolean;
}

export interface ConfirmationCallQuestionSetting {
	questions: ConfirmationCallQuestion[];
	clientName: string;
	address: string;
	emailAddress: string;
	referenceId: number;
	type: 'LOATCCQ';
	cRTSettingsId: number;
	activityAssignedUser: string;
	activityAssignedUserName: string;
	automaticActivity: boolean;
}

export const DEFAULT_FINAL_SECTION = "Does that sound right? Great. \n\nBefore I let you go. I just wanted to let you know that we have a Customer Care Team here at %BUSINESS_FAP_NAME% - so if you ever need help with anything, obviously feel free to call %ADVISER_FIRST_NAME% %ADVISER_LAST_NAME% but if he/she is not available. you can always call us on %ADVISER_BUSINESS_PHONE%. \nDo you have any questions for me? Great. \nWell, thank you for your time and welcome as a client of %BUSINESS_FAP_NAME% - I'll issue your policy right away. \nHave a great day, bye!\n";
export interface ConfirmationCallFinalSectionSetting {
	finalSection: string;
	referenceId: number;
	type: 'LOATCCF';
}
