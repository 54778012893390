import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { EmailTemplateModel, EmailTemplateStore } from './email-template.store';
import { ApiService } from '../../../../core/base/api.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { convertUtil, objectUtil } from 'src/app/util/util';
import { EmailTypes } from '@shared/models/emails/crt/email.model';
import MomentUtil from '@util/moment.util';
import { HtmlToPdfService } from '@modules/crm/crt-page/_shared/service/html-pdf/html-to-pdf.service';
import { environment } from '@environment';

@Injectable({
	providedIn: 'root',
})
export class EmailTemplateService {
	constructor(
		private api: ApiService,
		private emailTemplateStore: EmailTemplateStore,
		private htmlToPdfService: HtmlToPdfService
	) {}

	get(
		filter: any
	): Observable<{ TotalRecordCount: number; Data: EmailTemplateModel[] }> {
		const endpoint = `search/email-templates`;
		return this.api
			.post<{
				TotalRecordCount: number;
				EmailTemplateSettings: EmailTemplateModel[];
			}>(endpoint, filter)
			.pipe(
				map((result) => {
					const items = result.EmailTemplateSettings.map((t) => {
						const m = objectUtil.mapPascalCaseToCamelCase(
							t
						) as EmailTemplateModel;
						m.createdDateTime = MomentUtil.formatToDisplayDate(
							MomentUtil.DateStringToMoment(m.createdDateTime)
						);
						m.modifiedDateTime = MomentUtil.formatToDisplayDate(
							MomentUtil.DateStringToMoment(m.modifiedDateTime)
						);
						m.isLoading = false;
						return m;
					});
					return {
						TotalRecordCount: result.TotalRecordCount,
						Data: items,
					};
				})
			);
	}

	getEmailTemplatesByType(
		serviceCode: string = ''
	): Observable<EmailTemplateModel[]> {
		const endpoint = `emails/template-settings?type=${serviceCode}`;
		return this.api.get<EmailTemplateModel[]>(endpoint).pipe(
			map((data) =>
				data.map((estList) => {
					return objectUtil.mapPascalCaseToCamelCase(
						estList
					) as EmailTemplateModel;
				})
			)
		);
	}

	sendEmail(data, type, referenceId) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post(`emails/single`, {
			EmailFrom: environment.cpEmailSender,
			EmailDestination: d.EmailDestination,
			EmailCC: d?.CarbonCopy,
			EmailBCC: d?.BlindCarbonCopy,
			EmailSubject: d?.Subject,
			Type: type,
			ReferenceId: referenceId,
			HTMLBody: d.HTMLBody,
			Attachment: d.Attachments,
			StringBody: d?.StringBody || '',
		});
	}

	getContentByDocumentLink(documentLink: string): Observable<string> {
		return this.api.getExternalResource(documentLink, {
			responseType: 'text',
		});
	}

	getBody(documentId: number): Observable<any> {
		const endpoint = `documents/${documentId}`;
		return this.api.get(endpoint).pipe(
			map((data) => objectUtil.mapPascalCaseToCamelCase(data)),
			mergeMap((data) => this.getContentByDocumentLink(data?.documentLink))
		);
	}

	private mapToUpsert(
		data: EmailTemplateModel,
		documentId: number
	): EmailTemplateModel {
		return objectUtil.mapCamelCaseToPascalCase({
			EmailTemplateId: data.emailTemplateId,
			TemplateName: data.templateName,
			CarbonCopy: data.carbonCopy ?? '',
			BlindCarbonCopy: data.blindCarbonCopy ?? '',
			Subject: data.subject,
			TemplateID: '',
			Body: documentId,
			Type: EmailTypes.EmailTemplate,
			MergeTag: [],
			EmailTemplateSettingsType: data.emailTemplateSettingsType,
		});
	}

	add(data: EmailTemplateModel): Observable<boolean> {
		return convertUtil.convertToBase64(data?.bodyContent).pipe(
			map((template) => {
				return {
					document: template,
					referenceId: 0,
					fileName: `${data.templateName}.txt`,
					type: EmailTypes.EmailTemplate,
				};
			}),
			mergeMap((doc) => this.api.post3('documents', doc)),
			map((documentId: number) => this.mapToUpsert(data, documentId)),
			mergeMap((doc) => {
				return this.api.post(`emails/template-settings`, doc).pipe(
					map(() => true),
					catchError((err) => {
						return throwError(err);
					})
				);
			})
		);
	}

	deleteSettings(data: EmailTemplateModel): Observable<boolean> {
		return this.api
			.put(`emails/deactivate/template-settings/${data.emailTemplateId}`)
			.pipe(
				map(() => true),
				catchError((err) => {
					return of(false);
				})
			);
	}

	update(data: EmailTemplateModel): Observable<boolean> {
		return convertUtil.convertToBase64(data?.bodyContent).pipe(
			map((template) => {
				return {
					document: template,
					referenceId: data.body,
					fileName: `${data.templateName}.txt`,
					type: EmailTypes.EmailTemplate,
				};
			}),
			mergeMap((doc) => this.api.post3('documents', doc)),
			map((documentId: number) => this.mapToUpsert(data, documentId)),
			mergeMap((doc) => {
				return this.api
					.put(`emails/template-settings/${data.emailTemplateId}`, doc)
					.pipe(
						map(() => true),
						catchError((err) => {
							return throwError(err);
						})
					);
			})
		);
	}
}
