import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import {
  DocumentModelState,
  DocumentUploadState,
} from '../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../util/util';
import { ApiService } from '../../../../../core/base/api.service';
import { DeclarationEmailSettingsState } from './declaration-email-settings.model';
import { MortgageSettingsQuery } from '../../../state/mortgage-settings.query'
import { MortgageSettingsStore } from '../../../state/mortgage-settings.store'
import { SettingsTypes } from '../../../state/mortgage-settings.model';

@Injectable()
export class DeclarationEmailSettingsService {

  declarationEmailSettings$ = this.query.declarationEmail$;

  constructor(
    private api: ApiService,
    private store: MortgageSettingsStore,
    private query: MortgageSettingsQuery
  ) {}

  getDeclarationEmailSettings(data: number, settingsCode: SettingsTypes) {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<DeclarationEmailSettingsState>(endpoint).pipe(
      tap((res) =>
        applyTransaction(() => {
          const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
          this.store.setDeclarationEmailSettings(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateDeclarationEmailSettings(data: DeclarationEmailSettingsState) {
    const newValue = {
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<DeclarationEmailSettingsState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setDeclarationEmailSettings(newValue);
        })
      ),
      take(1)
    );
  }

  newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
