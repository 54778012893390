import { Pipe, PipeTransform } from '@angular/core';
import sort from 'fast-sort';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(
    value: any[],
    column: string | string[] = '',
    order: 'asc' | 'desc' = 'asc'
  ): any[] {
    try {
      if (!value) {
        return value;
      } // no array

      if (value.length <= 1) {
        return value;
      } // array with only one item

      if (typeof value[0] === 'object' && (!column || column === '')) {
        return value;
      }

      if (typeof value[0] !== 'object' && (!column || column === '')) {
        if (order === 'asc') {
          return sort(value).asc();
        } else {
          return sort(value).desc();
        }
      } // sort 1d array

      if (typeof column === 'string') {
        column = [...[column]];
      }

      return sort(value).by(
        column?.map(x => ({ [order]: c => (c[x] ? c[x]?.toString()?.toLowerCase() : c[x]) })) as any
      );
    } catch (error) {
      return value;
    }
  }
}
