import { AdviceProcessSectionCodes } from '../../../advice-process/advice-process.model';
import { Asset, AssetState } from './asset.model';
export class AssetMapper {
	static mapToAssetDetails(m: AssetState, adviceProcessId): Asset {
		return {
			CRTId: m?.cRTId ?? null,
			AdviceProcessId: adviceProcessId,
			SectionCode: AdviceProcessSectionCodes.AssetsInvestments,
			Asset: m.asset,
			Owner:
				typeof m.owner === 'string'
					? JSON.stringify(
							(JSON.parse(m.owner) as (string | number)[])?.map((x) => +x)
					  )
					: !!m.owner
					? JSON.stringify((m.owner as (string | number)[])?.map((x) => +x))
					: null,
			Value: +m.value,
			Description: m.description,
			FactFindComputation: {
				SectionCode: AdviceProcessSectionCodes.FFComputation,
				TotalPropertyValue: 0,
				TotalAssetValue: 0,
				OverallTotalGrossIncome: 0,
				TotalNetRentalIncome: 0,
				AnnualTaxableJointIncome: 0,
				MonthlyTaxableJointIncome: 0,
				MonthlyAfterTaxIncome: 0,
				TotalAnnualIncome: 0,
			},
			AssetStatus: m?.assetStatus,
			Status: 1,
			PendingAdviserApproval: m?.pendingAdviserApproval ?? false,
		};
	}

	static mapToUpsert(m, crtId) {
		return {
			adviceProcessId: m.AdviceProcessId,
			asset: m.Asset,
			cRTId: crtId,
			description: m.Description,
			owner: m.Owner,
			value: m.Value,
		};
	}
}
