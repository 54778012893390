import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Subject, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ContactStatusCode } from 'src/app/shared/models/business-profile/business/business.model';
import { NoteTypes } from 'src/app/shared/models/notes/note.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ActivityTimelineComponent } from '../../../../shared/activity-timeline/activity-timeline.component';
import { NoteState } from '../../../../shared/models/activity-timeline/activity-timeline.model';
import { CurrentActivityCriteriaState } from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { CustomerTypes } from '../../../../shared/models/_general/client.model';
import { ClientProfileTabsComponent } from '../client-profile-tabs/client-profile-tabs.component';
import { ClientProfileService } from '../states/client-profile.service';
import { FgInsuranceService } from '../states/pipelines/fg-insurance.service';
import { KiwisaverService } from '../states/pipelines/kiwisaver.service';
import { InvestmentService } from '../states/pipelines/investment.service';
import { LrInsuranceService } from '../states/pipelines/lr-insurance.service';
import { MortgageService } from '../states/pipelines/mortgage.service';
import { PropertyAssetService } from '../states/pipelines/property-asset.service';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { ReferClientPopupService } from '@shared/components/refer-client-popup/refer-client-popup.service';
import { ClientReferralSettingService } from '@modules/special-features/client-referral-setting/state/client-referral-setting.service';
import { ClientReferralSettingReferral } from '@modules/special-features/client-referral-setting/state/client-referral-setting.model';
import { ClientReferralEmailModalComponent } from '@shared/client-referral-email-modal/client-referral-email-modal.component';
declare var $: any;

@Component({
	selector: 'app-client-profile-edit',
	templateUrl: './client-profile-edit.component.html',
	styleUrls: ['./client-profile-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProfileEditComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	private onDestroy$ = new Subject<void>();
	public advisers$ = this.service.adviserChoices$;
	public allStaffChoices$ = this.service.allStaffChoices$;
	public leadGens$ = this.service.leadGenChoices$;
	PCT$ = this.service.PCT$;
	PCE$ = this.service.PCE$;
	PCPC$ = this.service.PCPC$;
	PCLE$ = this.service.PCLE$;
	PCLT$ = this.service.PCLT$;
	PCR$ = this.service.PCR$;

	public PreferredEmailContacts$ = this.service.PreferredEmailContacts$;

	LS$ = this.service.LS$;
	LT$ = this.service.LT$;
	SAS$ = this.service.SAS$;
	customerID$ = this.service.customerID$;
	primaryClient$ = this.service.primaryClient$;
	contactStatus$ = this.service.clientContactStatus$;
	fullName$ = this.primaryClient$.pipe(
		map((x) => (x ? `${x.firstName} ${x.lastName}` : ''))
	);
	activityType$ = this.service.AT$;
	activityMeeting$ = this.service.AM$;
	adviserChoices$ = this.service.adviserChoices$;
	allActiveStaffs$ = this.service.allActiveStaffs$;
	altAdviserChoices$ = this.service.altAdviserChoices$;
	activityAdviserChoices$ = this.service.activityAdviserChoices$;
	adviserCalendarChoices$ = this.service.adviserCalendarChoices$;
	currentActivityCriterias$ = this.service.criterias$;
	allStaff$ = this.blStaffQuery.allUnfilteredStaffs$;

	isLead$ = this.contactStatus$.pipe(
		map((x) => x && x === ContactStatusCode.Lead)
	);

	/**
	 * Activity Timeline
	 */
	pendingActivities$ = this.service.pendingActivities$;
	completedActivities$ = this.service.completedActivities$;
	completedNotes$ = this.service.completedNotes$;
	allCompletedNotes$ = this.service.allCompletedNotes$;

	addActivityNote$ = this.service.addActivityNote;
	deleteActivityNote$ = this.service.deleteActivityNote;
	pinNoteFn$ = this.service.pinNote;

	addActivity$ = this.service.addActivity;
	updateActivity$ = this.service.updateActivity;
	cancelActivity$ = this.service.cancelActivity;
	deleteActivity$ = this.service.deleteActivity;
	addPhoneCall$ = this.service.addPhoneCall;

	isCollapsed: boolean;

	@ViewChild(ClientProfileTabsComponent)
	clientProfileTab: ClientProfileTabsComponent;
	@ViewChild('activityTimelineComponent') acComp: ActivityTimelineComponent;
	@ViewChild('activityTimeline', { read: ElementRef })
	activityTimelineElement: ElementRef;

	innerWidth: number;
	isMobileMode: boolean;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
		if (this.innerWidth < 992) {
			this.isMobileMode = true;
		} else if (this.innerWidth < 1200) {
			this.collapseProfile(true);
			this.isMobileMode = false;
		} else {
			this.isMobileMode = false;
		}
	}

	@ViewChild('modal') modal: ClientReferralEmailModalComponent;

	documentInfo: {
		documentType: 'RO';
		type: any;
		referenceId: number;
		customerId: number;
	} = {
		documentType: 'RO',
		type: 'RO',
		referenceId: 0,
		customerId: 0,
	};

	documnetBodyLoaderFn$ = (data: ClientReferralSettingReferral) => {
		return this.clientReferralSettingService.getBody(data.body);
	};

	constructor(
		private service: ClientProfileService,
		private lrService: LrInsuranceService,
		private mortgageService: MortgageService,
		private propertyAssetService: PropertyAssetService,
		private fgService: FgInsuranceService,
		private ksService: KiwisaverService,
		private invService: InvestmentService,
		private blStaffQuery: BLStaffsQuery,
		private clientReferralSettingService: ClientReferralSettingService
	) {
		const profileIsCollapse = localStorage.getItem('profileIsCollapsed');
		const isCollapse: boolean = profileIsCollapse
			? JSON.parse(profileIsCollapse)
			: false;
		this.isCollapsed = isCollapse;
	}

	ngOnInit() {
		this.isMobileView();
	}

	ngAfterViewInit() {
		const self = this;
		// Activity timeline scroll event
		// For some reason angular scroll event
		// is not working on mac devices
		$(this.activityTimelineElement.nativeElement).on('scroll', (e) => {
			e.preventDefault();
			self.scrollHandler(e);
		});
	}

	isMobileView() {
		if (window.innerWidth < 992) {
			this.isMobileMode = true;
		} else if (this.innerWidth < 1200) {
			this.collapseProfile(true);
			this.isMobileMode = false;
		} else {
			this.isMobileMode = false;
		}
	}

	collapseProfile(collapsed?: boolean) {
		this.isCollapsed = collapsed ? collapsed : !this.isCollapsed;
		localStorage.setItem(
			'profileIsCollapsed',
			JSON.stringify(this.isCollapsed)
		);
	}

	selectTab(item: CurrentActivityCriteriaState) {
		this.clientProfileTab.selectTab(item);
	}

	// This is scroll paging for activity timeline completed
	// @HostListener('scroll', ['$event'])
	scrollHandler(event) {
		const scrollMax = event.target.scrollHeight;
		const scrollPosFloor = Math.floor(
			event.target.scrollTop + event.target.offsetHeight
		);
		const scrollPosCeil = Math.ceil(
			event.target.scrollTop + event.target.offsetHeight
		)+1;
		const scrollPos = event.target.scrollTop + event.target.offsetHeight;
		let data: any[];
		if (this.acComp.notesFilter === 'activities') {
			data = this.acComp.completedActivities;
		} else if (this.acComp.notesFilter === 'notes') {
			data = this.acComp.completedNotes;
		} else {
			data = this.acComp.allCompletedNotes;
		}
		if (
			scrollMax >= scrollPosFloor && 
			scrollMax <= scrollPosCeil
		) {
			const dataCount = data && data.length > 0 ? data.length : 0;
			const totalPage = Math.ceil(dataCount / 10);
			const currentPage = this.acComp.currentPage;
			if (dataCount > 0 && totalPage > currentPage) {
				this.acComp.setPage(
					{
						page: this.acComp.currentPage + 1,
						itemsPerPage: this.acComp.itemsPerPage,
					},
					data
				);
			}
		} else if (scrollPos - event.target.offsetHeight === 0) {
			let lst = 0;
			const st = event.target.scrollTop;
			if (st > lst) {
				lst = st;
				return;
			}
		}
	}

	deleteNoteEvent(notes: NoteState) {
		if (notes && !notes.activityName && !notes.isActivity) {
			if (notes.activityType === CustomerTypes.PrimaryCustomerIndividual) {
				this.clientProfileTab.peopleEntitiesComponent.formPerson.noteTable.setPage(
					{ page: 1, itemsPerPage: 5 }
				);
			}
			if (notes.activityType === CustomerTypes.PrimaryCustomerCompany) {
				const form =
					this.clientProfileTab.peopleEntitiesComponent.formBusinesses;
				form.forEach((x) => {
					if (
						x.noteTable &&
						x.noteTable.customerId &&
						x.noteTable.customerId === notes.customerID
					) {
						x.noteTable.setPage({ page: 1, itemsPerPage: 5 });
					}
				});
			}
			if (notes.activityType === CustomerTypes.SecondaryCustomerIndividual) {
				const form =
					this.clientProfileTab.peopleEntitiesComponent.formSecondaries;
				form.forEach((x) => {
					if (
						x.noteTable &&
						x.noteTable.customerId &&
						x.noteTable.customerId === notes.customerID
					) {
						x.noteTable.setPage({ page: 1, itemsPerPage: 5 });
					}
				});
			}
			if (notes.activityType === CustomerTypes.SecondaryCustomerTrust) {
				const form = this.clientProfileTab.peopleEntitiesComponent.formTrusts;
				form.forEach((x) => {
					if (
						x.noteTable &&
						x.noteTable.customerId &&
						x.noteTable.customerId === notes.customerID
					) {
						x.noteTable.setPage({ page: 1, itemsPerPage: 5 });
					}
				});
			}
			if (notes.activityType === CustomerTypes.SecondaryCustomerProfessional) {
				const form = this.clientProfileTab.peopleEntitiesComponent.formProfs;
				form.forEach((x) => {
					if (
						x.noteTable &&
						x.noteTable.customerId &&
						x.noteTable.customerId === notes.customerID
					) {
						x.noteTable.setPage({ page: 1, itemsPerPage: 5 });
					}
				});
			}
			if (notes.activityType === NoteTypes.LR) {
				this.lrService.syncNote(notes).pipe(take(1)).subscribe();
			}
			if (notes.activityType === NoteTypes.Mortgage) {
				this.mortgageService.syncNote(notes).pipe(take(1)).subscribe();
			}
			if (notes.activityType === NoteTypes.Property) {
				this.propertyAssetService.syncNote(notes).pipe(take(1)).subscribe();
			}
			if (notes.activityType === NoteTypes.Asset) {
				this.propertyAssetService
					.syncAssetNote(notes)
					.pipe(take(1))
					.subscribe();
			}
			if (notes.activityType === NoteTypes.Liability) {
				this.propertyAssetService
					.syncLiabilityNote(notes)
					.pipe(take(1))
					.subscribe();
			}
			if (notes.activityType === NoteTypes.FG) {
				this.fgService.syncNote(notes).pipe(take(1)).subscribe();
			}
			if (notes.activityType === ServicesCodes.KiwiSaver) {
				this.ksService.syncNote(notes).pipe(take(1)).subscribe();
			}
			if (notes.activityType === ServicesCodes.Investment) {
				this.invService.syncNote(notes).pipe(take(1)).subscribe();
			}
		}
	}
	ngOnDestroy() {
		$(this.activityTimelineElement?.nativeElement)?.off('scroll');
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
