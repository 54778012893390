import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LoanStructureStore, LoanStructuretState } from './loan-structure.store';

@Injectable()
export class LoanStructureQuery extends QueryEntity<LoanStructuretState> {
	loanStructures$ = this.selectAll();
	constructor(store: LoanStructureStore) {
		super(store);
	}
}
