import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import * as R from 'ramda';
import { forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserQuery } from '../../../../../../domain/user/user.query';
import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../../util/util';
import { CrtMortgageQuery } from '../crt-mortgage.query';
import { CrtMortgageService } from '../crt-mortgage.service';
import { CrtMortgageStore } from '../crt-mortgage.store';
import { MergeTagsQuery } from './merge-tags.query';
import {
	FactFindSubSectionCodes,
	FactFindSubSectionCodesMOAT,
	LsSoaSubSectionCodes,
} from '../../../../../../shared/models/advice-process/advice-process.model';

const factFindCodes: string[] = [
	...FactFindSubSectionCodes,
	...FactFindSubSectionCodesMOAT,
].map((code: string) => code.toLowerCase());

const LsSoaCodes: string[] = [
	...LsSoaSubSectionCodes,
].map((code: string) => code.toLowerCase());

@Injectable()
export class MergeTagsService extends CrtMortgageService {
	mergeTags$ = this.mtQuery.mergeTags$;

	constructor(
		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected userQuery: UserQuery,
		protected mtQuery: MergeTagsQuery
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	getDefaultMergeTags(refetch = false) {
		let requests$ = [];
		const refId = 0;

		const getMtGeneral$ = this.getMergeTags('general', refId);
		const getMtBusiness$ = this.getMergeTags('business', refId);
		if (R.isNil(this.query.getValue().mergeTags) || refetch) {
			requests$ = [getMtGeneral$, getMtBusiness$];
		}
		return forkJoin([...requests$]);
	}

	getMergeTags(tableCode: string, referenceId: number, dataType?: string) {
		let endpoint = `crt/merge-tag/${tableCode}/reference/${referenceId}`;
		if (dataType) {
			endpoint = `${endpoint}/${dataType}`;
		}
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getFactFindMt(adviceProcessId: number) {
		const body = {
			ReferenceId: +adviceProcessId,
			DataType: factFindCodes,
			Table: ['fact-find'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getLsSoaMt(adviceProcessId: number) {
		const body = {
			ReferenceId: +adviceProcessId,
			DataType: LsSoaCodes,
			Table: ['structure-statement-of-advice'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => {
				if (x) {
					return x?.map((m) => {
						const mt = objectUtil.mapPascalCaseToCamelCase(m);
						if (mt.metaKey === 'LOAN_STRUCTURE_NOTES') {
							mt.value = mt.value?.map((val) => val.replaceAll('\n', '<br>'));
						}
						return mt;
					})
				}
			}),
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getSosMt(adviceProcessId: number) {
		const body = {
			ReferenceId: +adviceProcessId,
			DataType: ['sos'],
			Table: ['scope-of-service'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getApplicationMt(applicationId: number) {
		const body = {
			ReferenceId: +applicationId,
			DataType: ['MAFRP'],
			Table: ['application'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getApplicationReasonMt(applicationId: number) {
		const body = {
			ReferenceId: +applicationId,
			DataType: ['MAP'],
			Table: ['application'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getAdviserProviderMt(adviserId?: number) {
		const id = adviserId || +this.query.getValue().adviceProcess?.adviser;
		return forkJoin([
			this.getMergeTags('staff', id),
			this.getMtSettings(id, 'PCT'),
			// @TODO: Uncomment only when it is ready to use
			// this.getMtSettings(id, 'PCM'),
			// this.getMtSettingDetails(id, 'PCM'),
		]);
	}

	getMtSettings(referenceId: number, dataType?: string) {
		const endpoint = `crt/merge-tag/settings/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getMtSettingDetails(referenceId: number, dataType?: string) {
		const endpoint = `crt/merge-tag/setting-details/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	updateMtState(data) {
		applyTransaction(() => {
			const state = this.query.getValue().mergeTags || [];
			const updatedData = data?.reduce((acc, curr) => {
				const index = acc?.findIndex((item) => item.metaKey === curr.metaKey);
				if (index === -1) {
					return [...acc, curr];
				}
				acc[index] = curr;
				return acc;
			}, state);
			this.store.setMergeTags(updatedData);
		});
	}
}
