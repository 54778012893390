import { QueryEntity } from '@datorama/akita';
import { BusinessPageState, BusinessPageStore } from './business-page.store';
import { Injectable } from '@angular/core';
import { map, withLatestFrom, distinctUntilChanged, combineLatest } from 'rxjs/operators';
import { columns } from '../business-page-datatable.config';
import * as R from 'ramda';
import { BusinessPageModel } from './business-page.model';
import { util } from '../../../util/util';
import sort from 'fast-sort';

@Injectable()
export class BusinessPageQuery extends QueryEntity<BusinessPageState, BusinessPageModel> {
	constructor(protected store: BusinessPageStore) {
		super(store);
	}
	columns$ = this.select(x => x.columns);
	rows$ = this.selectAll();
	archivedRows$ = this.selectAll(); // To do: waiting for archive field

	isLoading$ = this.select(x => x.isLoading);
	isSearching$ = this.select(x => x.isSearching);
	showAll$ = this.select(x => x.showAll);

	isColumnSaving$ = this.select(x => x.isColumnSaving);
	isOpenColumnsSettings$ = this.select(x => x.toggleColumnsSetting);
	sorts$ = this.select(x => [{ dir: x.sort, prop: x.propSort }]);

	plMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.PLCurrentMonth)
			);
			return result;
		})
	);
	aiaMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.AIACurrentMonth)
			);
			return result;
		})
	);
	fidelityLifeMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.FidelityLifeCurrentMonth)
			);
			return result;
		})
	);
	asteronMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.AsteronCurrentMonth)
			);
			return result;
		})
	);
	nibMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.NIBCurrentMonth)
			);
			return result;
		})
	);
	cignaLifeMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.CignaLifeCurrentMonth)
			);
			return result;
		})
	);
	blanketMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.BlanketCurrentMonth)
			);
			return result;
		})
	);
	totalMonth$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.TotalCurrentMonth)
			);
			return result;
		})
	);

	totalYear$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.TotalCurrentYear)
			);
			return result;
		})
	);
	noOfClients$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.NoOfClients)
			);
			return result;
		})
	);
	noOfAdvisers$ = this.selectAll().pipe(
		combineLatest(this.showAll$),
		map(([r, isActive]) => {
			let result = 0;
			r?.filter(a => (!isActive && a.IsActive === 1) || (isActive && a.IsActive > 0))?.forEach(
				x => (result += x.NoOfAdvisers)
			);
			return result;
		})
	);

	tableColumns$ = this.columns$.pipe(
		map(tableColumns => {
			return tableColumns?.map(col => columns?.find(y => y.metakey === col))?.filter(x => x);
		}),
		withLatestFrom(this.select(x => x.columnWidths)),
		map(([tableColumns, widths]) =>
			tableColumns?.map(column => {
				const widthConf = widths?.filter(x => x)?.find(width => width.metakey === column.metakey);
				if (widthConf) {
					return { ...column, width: widthConf.width };
				} else {
					return column;
				}
			})
		),
		distinctUntilChanged((x, y) => R.equals(x, y))
	);

	hiddenTableColumns$ = this.columns$.pipe(
		map(tableColumns => {
			return columns?.filter(x => !tableColumns?.includes(x.metakey));
		})
	);

	sortedRows$ = this.select(x => [x.propSort, x.sort]).pipe(
		distinctUntilChanged((p, q) => R.equals(p, q)),
		// tslint:disable-next-line: deprecation
		combineLatest(this.rows$),
		withLatestFrom(this.tableColumns$),
		withLatestFrom(this.showAll$),
		map(([[[[prop, sortDirection], rows], tableColumns], showAll]) => {
			if (sortDirection === '' || prop === '') {
				return sort(showAll ? rows : rows?.filter(x => x.IsActive === 1)).by({
					[sortDirection ? sortDirection : 'asc']: b => b[prop ? prop : 'BusinessName'],
				} as any);
			} else {
				const column = tableColumns?.find(x => x.prop === prop);
				if (util.isNullOrEmpty(column)) {
					return rows;
				}

				const allRows = showAll ? rows : rows?.filter(x => x.IsActive === 1);
				const decorated = allRows?.map(r => {
					const actualValue = column.sortValueGetter(r[prop], column.choices);
					return [this.spaceSortValueGetter(actualValue), R.defaultTo('', actualValue), r.BusinessID, r];
				});
				return sortDirection === 'asc'
					? sort(decorated)
						.by([{ asc: u => u[0] }, { asc: u => u[1] }, { asc: u => u[2] }])
						?.map(x => x[3])
					: sort(decorated)
						.by([{ asc: u => u[0] }, { desc: u => u[1] }, { asc: u => u[2] }])
						?.map(x => x[3]);
			}
		})
	);

	/** Create a value usable as index for sorting.
	 * Its only necessary to know if value is empty or not.
	 * So if it is not empty, return 1 which is first in sort index.
	 * And 2 if empty.
	 */
	private spaceSortValueGetter(fieldValue: string | number | null | undefined): 1 | 2 {
		let stringValue: string;
		if (util.isNullOrEmpty(fieldValue)) {
			stringValue = '';
		} else if (typeof fieldValue === 'string') {
			stringValue = fieldValue?.trim();
		} else {
			stringValue = fieldValue?.toString();
		}
		if (stringValue === '') {
			return 2;
		} else {
			return 1;
		}
	}
}
