import { AuthenticationService } from './core/authentication/authentication.service';
import { LocalService } from './core/services/local.service';
import { catchError, from, of, tap } from 'rxjs';
import { environment as env } from '@environment';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';

export function appInitializer(
	authService: AuthenticationService,
	localService: LocalService
) {
	return () =>
		new Promise((resolve: any) => {
			const idleTime = localService.getValue('idleTime');
			const idleTimeout = !!idleTime ? env.idleTime * 1000 + idleTime : null;
			const datadogRumConfig = env?.datadogRum;

			if (env.production) {
				const initDatadogRum = () =>
					datadogRum.init({
						...datadogRumConfig,
						version: env.appVersion
					} as RumInitConfiguration);

				initDatadogRum();
			}

			// attempt to refresh token on app start up to auto authenticate
			if (authService.isLoggedIn) {
				if (
					!authService.isAuthorized &&
					(!idleTimeout || (!!idleTimeout && Date.now() < idleTimeout))
				) {
					authService.refreshToken().subscribe().add(resolve);
				} else {
					if (!!idleTimeout && Date.now() >= idleTimeout) {
						authService.logout().subscribe();
					} else {
						of(null).subscribe().add(resolve);
					}
				}
			} else {
				of(null).subscribe().add(resolve);
			}
		});
}
