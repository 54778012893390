<form [formGroup]="form" class="pb-3" novalidate>
  <div class="form-row clearfix align-items-center mb-2">
    <div class="col-12 col-lg-3 font-weight-bold">Assets</div>
    <div class="col-12 col-lg-3 font-weight-bold">Owner</div>
    <div class="col-12 col-lg-3 font-weight-bold">Description</div>
    <div class="col-12 col-lg-2 font-weight-bold">Value</div>
    <div class="col-12 col-lg-1 font-weight-bold"></div>
  </div>
  <div formArrayName="assetsArray">
    <div
      class="form-row clearfix align-items-center mb-2"
      *ngFor="let item of AssetsArray.controls; let i = index; trackBy: trackByFn"
      [formGroupName]="i"
    >
      <div class="col-12 col-lg-3">
        <span class="custom-dropdown w-100">
          <select
            formControlName="asset"
            class="form-control theme-crm-field"
            id="asset_{{i}}"
            tabindex="21"
          >
          <option
            value=""
            selected
            disabled
            hidden
          >
            Select Asset
          </option>
            <option *ngFor="let t of assetOptions$ | async" [value]="t.value">
              {{ t.display }}
            </option>
          </select>
        </span>
      </div>
      <div class="col-12 col-lg-3">
        <!-- OWNER -->
        <span class="custom-dropdown w-100">
          <app-chips
            textboxClass="theme-crm-field"
            [formControl]="item.get('owner')"
            [choices]="getOwnerChoices(item.get('owner').value)"
            id="owner_{{i}}"
            tabindex="20"
          ></app-chips>
        </span>
      </div>
      <div class="col-12 col-lg-3">
        <input
          type="text"
          formControlName="description"
          class="form-control theme-crm-field-disabled"
          placeholder="- -"
          id="description_{{i}}"
        />
      </div>
      <div class="col-12 col-lg-2">
        <div class="dollar-icon">
          <input
            type="text"
            formControlName="value"
            class="form-control theme-crm-field-disabled"
            placeholder="- -"
            id="value_{{i}}"
            tabindex="28"
            currencyMask
						appCurrencyMaskExt
          />
          <i>$</i>
        </div>
      </div>
      <div class="col-12 col-lg-1 font-weight-bold">
        <ng-container *ngIf="!item.get('isLoading')?.value"
        ><button
          *ngIf="!item.get('isEdit')?.value && item.get('status')?.value != 'Sold'"
          type="button"
          class="icon-btn w-auto h-auto px-1"
          (click)="enableFormItem(i)"
          id="editAsset_{{i}}"
					[disabled]="addMode || (editMode && !item.get('isEdit')?.value) || isUpdatingOrAdding"
        >
          <i class="material-icons md-16 tap-text-color-h6">edit</i>
        </button>
        <button
          *ngIf="!item.get('isEdit')?.value"
          type="button"
          class="icon-btn w-auto h-auto px-1"
          (click)="deleteModal(i)"
          id="deleteAsset_{{i}}"
					[disabled]="addMode || (editMode && !item.get('isEdit')?.value) || isUpdatingOrAdding"
        >
          <i class="material-icons md-16 tap-text-color-h6">delete</i>
        </button>
        <button
          *ngIf="(!item.get('isEdit')?.value && item.get('status')?.value != 'Sold')"
          type="button"
          class="icon-btn w-auto h-auto px-1"
          (click)="markAsSold(i)"
          title="Mark as Sold"
          id="markAsSold_{{i}}"
					[disabled]="addMode || (editMode && !item.get('isEdit')?.value) || isUpdatingOrAdding"
        >
          <i class="material-icons md-16 tap-text-color-h6">local_offer</i>
        </button>
        <ng-container *ngIf="!!item.get('customerServiceID')?.value && item.get('status')?.value != 'Sold'">
          <button
            color="primary"
            *ngIf="item.get('isEdit')?.value"
            type="button"
            class="icon-btn w-auto h-auto px-1"
            (click)="saveAsset(i, false)"
            id="saveAsset_{{i}}"
          >
            <i class="material-icons md-16 tap-text-color-h6">save</i>
          </button>
          <button
            *ngIf="item.get('isEdit')?.value"
            type="button"
            class="icon-btn w-auto h-auto px-1"
            (click)="cancelEdit(i)"
            id="closeAsset_{{i}}"
          >
            <i class="material-icons md-16 tap-text-color-h6">close</i>
          </button>
        </ng-container>
        <ng-container *ngIf="!item.get('customerServiceID')?.value">
          <button
            color="primary"
            *ngIf="item.get('isEdit')?.value"
            type="button"
            class="icon-btn w-auto h-auto px-1"
            (click)="saveAsset(i, true)"
            id="saveAsset_{{i}}"
          >
            <i class="material-icons md-16 tap-text-color-h6">save</i>
          </button>
          <button
            *ngIf="item.get('isEdit')?.value"
            type="button"
            class="icon-btn w-auto h-auto px-1"
            (click)="cancelAsset(i)"
            id="closeAsset_{{i}}"
          >
            <i class="material-icons md-16 tap-text-color-h6">close</i>
          </button>
        </ng-container>
      </ng-container>
      </div>
    </div>
  </div>

  <div class="form-row clearfix mb-2">
    <div class="col-5 col-lg-3 mb-2">
      <ng-container *ngIf="assetStatus != 'Sold'">
        <ng-container *ngIf="isLoading">
          <app-manual-loader-mini
            [isLoading]="isLoading"
          ></app-manual-loader-mini>
        </ng-container>
        <button
          *ngIf="!isLoading"
          type="button"
          (click)="addNew()"
          class="tap-btn tap-btn--default"
          id="addAsset"
					[disabled]="addMode || editMode || isSaving"
        >
          Add Asset +
        </button>
      </ng-container>

    </div>
    <div class="offset-lg-4 col-lg-4">
      <div class="row mt-4 mt-lg-2 font-weight-bold">
        <div class="col-auto col-lg-6 text-left text-lg-right">
          <span class="font-weight-bold">Total Assets Value:</span>
        </div>
        <div class="col-auto col-lg-3">
          <span class="">{{
            assets?.totalValue | currency: "USD":"$":"1.2-2"
          }}</span>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="assetStatus != 'Sold' && assets.customerServices.length">
    <br />
    <div class="form-row clearfix align-items-center py-2">
      <div class="col-lg d-flex align-items-center">
        <strong class="mr-3">Notes</strong>
        <ng-container *ngIf="isLead">
          <button
            *appRestrictTo="['FALN']"
            type="button"
            class="icon-btn h-auto"
            [disabled]="isSaving"
            (click)="openModalAddNote()"
            id="addAssetNote"
          >
            <i class="material-icons md-20 tap-text-color-h6">note_add</i>
          </button>
        </ng-container>
        <ng-container *ngIf="!isLead">
          <button
            *appRestrictTo="['FAN']"
            type="button"
            class="icon-btn h-auto"
            [disabled]="isSaving"
            (click)="openModalAddNote()"
            id="addAssetNote"
          >
            <i class="material-icons md-20 tap-text-color-h6">note_add</i>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg col-12">
        <app-table-paginate-notes
          [isService]="true"
          [isLead]="isLead"
          [notes]="notes"
          [itemsPerPage]="5"
          [deleteFn$]="deleteNote$"
          [showHeader]="false"
        >
        </app-table-paginate-notes>
      </div>
    </div>
  </ng-container>
</form>
