import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { CustomerService } from '@core/customer/customer.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { DropdownValueService } from '@domain/dropdown-value/dropdown-value.service';
import { ConfirmationCallQuestionSetting } from '@modules/crt-settings/confirmation-call/states/confirmation-call-question.model';
import { ConfirmationCallSettingsService } from '@modules/crt-settings/confirmation-call/states/confirmation-call-settings.service';
import {
	FSSubSectionCodes,
	ServiceAdviceProcessState,
} from '@shared/models/advice-process/advice-process.model';
import { PeopleDetails } from '@shared/models/client-review-template/people/people-details.model';
import { EmailTypes } from '@shared/models/emails/crt/email.model';
import { convertUtil, objectUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { clone, prop, sortBy, uniqBy } from 'ramda';
import { EMPTY, Observable, Subject, defer, of, zip } from 'rxjs';
import {
	catchError,
	delay,
	map,
	mergeMap,
	shareReplay,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ConfirmationCallCompleteModalComponent } from './confirmation-call-complete-modal.component';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { EmailLinksMT } from '@shared/models/client-review-template/merge-tags/email-settings/email-links';
import {
	getContentWithMergeTags,
	normalizeHTMLSymbols,
	removeMtWrappers,
} from '@shared/converter/content-merge-tags';
import { getEmailContentNoHtml } from '@shared/converter/content-email';
import { FinalStructureState } from '@shared/models/client-review-template/final-structure/final-structure.model';
import { ProposedInsuranceState } from '@shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import {
	ClientAcceptance,
	ClientAcceptanceState,
} from '@shared/models/client-review-template/client-acceptance/client-acceptance.model';
import MomentUtil from '@util/moment.util';
import { SecondaryClientState } from '@shared/models/client-profile/secondary-client/secondary-client.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { Attachment } from '@shared/models/_general/attachment.model';
import { DocumentModelState } from '@shared/models/documents/document.model';

@Injectable({ providedIn: 'root' })
export class ConfirmationCallCompleteModalService {
	constructor(
		private modalService: BsModalService,
		private confirmationCallSettingsService: ConfirmationCallSettingsService,
		protected customerService: CustomerService,
		private api: ApiService,
		private businessConfigQuery: BusinessConfigQuery,
		private dropdownValueService: DropdownValueService,
		private bLStaffsQuery: BLStaffsQuery
	) {}

	getCallRequest(callId): Observable<any> {
		const endpoint = `concall/${callId}`;
		return this.api.get(endpoint).pipe(
			map((result) => objectUtil.mapPascalCaseToCamelCase(result)),
			map((result) => {
				result.initialRequestData.providerSection =
					result.initialRequestData.providerSection.map((p) => {
						p.sendToIssueDate = MomentUtil.serverDateToDisplayDate(
							p.sendToIssueDate
						);
						p.startDate = MomentUtil.serverDateToDisplayDate(p.startDate);
						p.firstPaymentDate = MomentUtil.serverDateToDisplayDate(
							p.firstPaymentDate
						);
						return p;
					});
				return result;
			})
		);
	}

	getCompleteConCall(conCallId: number): Observable<any> {
		const endpoint = `concall/complete/${conCallId}`;
		return this.api
			.get(endpoint)
			.pipe(map((result) => objectUtil.mapPascalCaseToCamelCase(result)));
	}

	getQuestions(): Observable<ConfirmationCallQuestionSetting> {
		return this.confirmationCallSettingsService.getQuestions().pipe(
			take(1),
			map((result) => {
				const questions = result.questions.filter((q) => q.status);
				result.questions = sortBy(prop('orderNo'), questions);
				return result;
			})
		);
	}

	getPersonInfo(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<PeopleDetails>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => EMPTY)
		);
	}

	getPeopleFromAdviceProcess(adviceProcessId: number): Observable<any[]> {
		const endpoint = `crt/fact-find/${adviceProcessId}/group/PE`;
		return this.api.get<any>(endpoint).pipe(
			map(({ Company, Dependents, People, Trust }) => {
				return [...Company, ...Dependents, ...People, ...Trust].map((p) => {
					return objectUtil.mapPascalCaseToCamelCase(p);
				});
			}),
			shareReplay()
		);
	}

	getClientAcceptance(
		adviceProcessId: number
	): Observable<ClientAcceptanceState> {
		return this.api.get<ClientAcceptance[]>(`crt/${adviceProcessId}/CA`).pipe(
			map((x) =>
				!!x && x?.length > 0 && !!x[0]
					? (objectUtil.mapPascalCaseToCamelCase(x[0]) as ClientAcceptanceState)
					: null
			),
			catchError(() => of(undefined))
		);
	}

	getChoicesFromFinalStructure(clientId: number): Observable<any> {
		return this.customerService.GetContacts(clientId).pipe(
			map((result) => {
				const allSCI = result.SCI
					? (result.SCI.map(
							objectUtil.mapPascalCaseToCamelCase
						) as SecondaryClientState[])
					: [];

				const linkCustomer = result?.LC
					? (result?.LC
							// @ts-ignore-next
							?.filter((lci) => +lci.IsActive === 1 || +lci.IsActive === 3)
							.map(objectUtil.mapPascalCaseToCamelCase) as LinkedContactState[])
					: [];

				return [...(allSCI ?? []), ...(linkCustomer ?? [])].map((p: any) => {
					if (!p.customerId) {
						p.customerId = p.customerID;
					}
					return p;
				});
			})
		);
	}

	show(
		adviceProcess: ServiceAdviceProcessState,
		callId: any,
		callAgain = false,
		viewMode = false
	): Observable<any> {
		return defer(() => {
			const subject = new Subject();
			let modalRef: BsModalRef;
			zip(
				this.getQuestions(),
				this.confirmationCallSettingsService.getFinalSection(),
				this.bLStaffsQuery.selectEntity(adviceProcess.adviser),
				this.getFinalStructureData(adviceProcess.adviceProcessID),
				this.getPeopleFromAdviceProcess(adviceProcess.adviceProcessID),
				this.getClientAcceptance(adviceProcess.adviceProcessID),
				this.getCallRequest(callId),
				this.getChoicesFromFinalStructure(adviceProcess.customerID)
			)
				.pipe(
					take(1),
					tap(
						([
							questions,
							finalSection,
							adviser,
							finalStructure,
							people,
							clientAcceptance,
							conCallData,
							people2
						]) => {
							modalRef = this.modalService.show(
								ConfirmationCallCompleteModalComponent,
								{
									initialState: {
										modalSubject: subject,
										adviceProcessId: adviceProcess.adviceProcessID,
										adviceProcess: adviceProcess,
										conCallId: callId,
										questionsData: questions,
										finalSectionData: finalSection,
										adviser,
										finalStructure,
										viewMode,
										people: uniqBy((a) => {
											return a.customerId ?? a.customerID;
										}, [...(people ?? []), ...(people2 ?? [])]),
										conCallData: {
											...conCallData.initialRequestData,
											status: conCallData.status,
											conCallId: conCallData.concallId,
											adviceProcessId: conCallData.adviceProcessId,
										},
										conCallComplete: {
											...conCallData.completedData,
											status: conCallData.status,
											conCallId: conCallData.concallId,
											adviceProcessId: conCallData.adviceProcessId,
										},
										clientAcceptance,
									},
									ignoreBackdropClick: true,
									class:
										'modal-dialog-centered modal-lg confirmation-call-complete-modal',
									show: true,
									keyboard: false,
								}
							);
						}
					)
				)
				.subscribe();
			return subject.asObservable().pipe(tap(() => {
				modalRef?.hide();
			}));
		});
	}

	getFinalStructureData(adviceProcessId: number): Observable<{
		finalStructure: FinalStructureState;
		insurance: ProposedInsuranceState[];
	}> {
		return this.getFinalStructure(adviceProcessId).pipe(
			take(1),
			mergeMap((finalStructure) => {
				return this.getProposedInsurance(adviceProcessId).pipe(
					take(1),
					map((insurance) => {
						return {
							finalStructure,
							insurance,
						};
					})
				);
			})
		);
	}

	private getFinalStructure(adviceProcessId: number) {
		return this.api.get<any>(`crt/${adviceProcessId}/FS`).pipe(
			map((x) =>
				!!x && x?.length > 0
					? (objectUtil.mapPascalCaseToCamelCase(x[0]) as FinalStructureState)
					: null
			),
			catchError(() => of(undefined))
		);
	}

	private getProposedInsurance(
		adviceProcessId: number,
		sectionCode: string = FSSubSectionCodes.ProposedInsurance
	) {
		return this.api.get<any>(`crt/${adviceProcessId}/${sectionCode}`).pipe(
			map(
				(x) =>
					x?.map(
						objectUtil.mapPascalCaseToCamelCase
					) as ProposedInsuranceState[]
			),
			catchError(() => EMPTY)
		);
	}

	sendEmail(data, type, referenceId) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.post(`emails/single`, {
			EmailFrom: d?.EmailFrom,
			EmailDestination: d.EmailDestination,
			EmailCC: d?.EmailCC,
			EmailBCC: d?.EmailBCC,
			EmailSubject: d?.EmailSubject,
			Type: type,
			ReferenceId: referenceId,
			HTMLBody: d.HTMLBody,
			Attachment: d.Attachments,
			StringBody: d?.StringBody || '',
		});
	}

	sendRequest(data: any, type: EmailTypes, refId: number): Observable<any> {
		const customMTags = [];

		return new Observable<{
			emailDropdown: string;
			emailRecipient: string;
			bodyContent: string;
			emailSubject: string;
		}>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			withLatestFrom(of([])),
			map(([, mergeTags]) =>
				MergeTagsMapper.updateEmailMergeTags(clone(mergeTags), [
					...clone(customMTags),
					...clone(EmailLinksMT),
				])
			),
			map((mergeTags) => {
				return {
					bodyContent: getContentWithMergeTags(data?.bodyContent, mergeTags),
					emailSubject: getContentWithMergeTags(data?.emailSubject, mergeTags),
				};
			}),
			map((emailData) => {
				return {
					bodyContent: removeMtWrappers(emailData.bodyContent),
					emailSubject: removeMtWrappers(emailData.emailSubject),
				};
			}),
			map((emailData) => ({
				...emailData,
				emailSubject: normalizeHTMLSymbols(emailData.emailSubject),
			})),
			mergeMap((emailData) => {
				return this.getBodyContent(emailData.bodyContent).pipe(
					map((bodyContentResult) => {
						return {
							bodyContent: bodyContentResult,
							emailSubject: emailData.emailSubject,
						};
					})
				);
			}),
			map((emailData) => {
				return {
					...data,
					emailSubject: emailData.emailSubject,
					...emailData.bodyContent,
				};
			}),
			mergeMap((payload) =>
				this.sendEmail(payload, type, refId).pipe(
					map(() => {
						let emailRecipients =
							data?.emailDestination?.map((i) => i?.Email) || [];
						emailRecipients = emailRecipients?.filter(Boolean)?.join(', ');
						return emailRecipients;
					}),
					catchError(() => EMPTY)
				)
			)
		);
	}

	getBodyContent(content) {
		return of(content).pipe(
			mergeMap((x) => convertUtil.convertToBase64(x)),
			map((x) => ({ HTMLBody: x, StringBody: getEmailContentNoHtml(content) })),
			take(1)
		);
	}

	getCompleteData(conCallId: number): Observable<any> {
		const endpoint = `concall/complete/${conCallId}`;
		return this.api.get(endpoint).pipe(
			map((data) => objectUtil.mapCamelCaseToPascalCase(data)),
			catchError(() => {
				return of({});
			})
		);
	}

	upsert(data: any): Observable<any> {
		return data.conCallId ? this.update(data) : this.add(data);
	}

	update(data: any): Observable<any> {
		const questionAnswerList = data.questionAnswerList.map((d) =>
			objectUtil.mapCamelCaseToPascalCase(d)
		);
		const providerSection = data.providerSection.map((d) =>
			objectUtil.mapCamelCaseToPascalCase(d)
		);
		const parsedData = objectUtil.mapCamelCaseToPascalCase(data);
		parsedData.QuestionAnswerList = questionAnswerList;
		parsedData.ProviderSection = providerSection;
		const endpoint = `concall/complete/${data.conCallId}`;
		return this.api.put(endpoint, parsedData);
	}

	add(data: any): Observable<any> {
		const questionAnswerList = data.questionAnswerList.map((d) =>
			objectUtil.mapCamelCaseToPascalCase(d)
		);
		const providerSection = data.providerSection.map((d) =>
			objectUtil.mapCamelCaseToPascalCase(d)
		);
		const parsedData = objectUtil.mapCamelCaseToPascalCase(data);
		parsedData.QuestionAnswerList = questionAnswerList;
		parsedData.ProviderSection = providerSection;
		const endpoint = `concall/complete`;
		return this.api.post(endpoint, parsedData);
	}

	getDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getUploadDoc(document) {
		if (!document) {
			return of(null);
		}
		return this.api.getExternalResourceAsBlob(document?.DocumentLink).pipe(
			map((content) => ({
				content: content,
				documentName: document?.DocumentName,
			})),
			mergeMap((content: any) => {
				return convertUtil.simpleConvertToBase64(content.content).pipe(
					map((result) => {
						content.content = result;
						return content;
					}),
					map(
						(file) =>
							({
								fileName: document?.DocumentName,
								content: file?.content
									?.replace('data:', '')
									.replace(/^.+,/, ''),
							}) as Attachment
					)
				);
			}),
			take(1)
		);
	}

	generateAttachment(documentID: number, filename: string): Observable<Attachment> {
		return this.getDocumentFile(documentID).pipe(
			mergeMap((data: any) => {
				return this.getUploadDoc(data).pipe(
					map((doc) => {
						doc.allowDeleting = true;
						doc.fileName = filename + data.FileExtension;
						doc.fileUrl = data.DocumentLink;
						doc.disableDownload = false;
						return doc;
					})
				);
			})
		);
	}

	getPersonInfoFromClientProfile(customerId: number): Observable<any> {
		return this.api
			.get(`/contacts/${customerId}`)
			.pipe(map((info) => objectUtil.mapPascalCaseToCamelCase(info)));
	}
}

