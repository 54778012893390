import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, concat, from, iif, of, Subject } from 'rxjs';
import { concatMap, delay, filter, finalize, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { BusinessService } from 'src/app/core/business/business.service';
import { NoteSettingsService } from 'src/app/modules/mortgage-settings/review-application-settings/note-settings/state/note-settings.service';
import { SettingsTypes } from 'src/app/modules/mortgage-settings/state/mortgage-settings.model';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { convertUtil, objectUtil } from 'src/app/util/util';
import { NoteMapper } from './state/note.mapper';
import { DocumentTypes, Note } from './state/note.model';
import { NoteService } from './state/note.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {
	optionsWysiswyg = {
		heightMax: 250,
		heightMin: 150,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};
	private onDestroy$ = new Subject<void>();
	form: UntypedFormGroup;
	isLoading = false;
	@Input() parentCRTId: number;
	@Output() saveCompleted: EventEmitter<object> = new EventEmitter<object>();

	@ViewChild('purposeEditor') purposeEditor: WysiwygComponent;
	@ViewChild('clientBackgroundEditor') clientBackgroundEditor: WysiwygComponent;
	@ViewChild('recommendationEditor') recommendationEditor: WysiwygComponent;
	@ViewChild('otherInformationEditor') otherInformationEditor: WysiwygComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private noteService: NoteService,
		private businessService: BusinessService,
		private noteSettingsService: NoteSettingsService
	) {
		this.buildForm();
	}

  ngOnInit(): void {
		this.loadNotes();
	}

	loadNotes() {
		combineLatest([
			this.noteService.get(this.parentCRTId),
			this.noteSettingsService.getNoteSettings(0, SettingsTypes.MOATReviewNotes)
		])
		.pipe(
			map(([data, settings]) => {
				if (!!data) {
					return {
						note: Object.values(data)[0] as Note,
						settings: objectUtil.mapPascalCaseToCamelCase(settings)
					} 
				}
				return {
					note: null,
					settings: objectUtil.mapPascalCaseToCamelCase(settings)
				}
			}),
			tap(({ note, settings }) => this.CRTId.setValue(note?.cRTId)),
			concatMap(({ note, settings}) => {
				return this.renderNotes(note, settings)
			}),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

	get Purpose() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.PURPOSE);
		return doc.get('content');
	}

	get PurposeId() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.PURPOSE);
		return doc.get('id');
	}

	get Recommendation() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.RECOMMENDATION);
		return doc.get('content');
	}

	get RecommendationId() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.RECOMMENDATION);
		return doc.get('id');
	}

	get ClientBackgroundCharacter() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.CLIENT_BACKGROUND_CHARACTER);
		return doc.get('content');
	}

	get ClientBackgroundCharacterId() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.CLIENT_BACKGROUND_CHARACTER);
		return doc.get('id');
	}

	get OtherInformation() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.OTHER_INFORMATION);
		return doc.get('content');
	}

	get OtherInformationId() {
		const doc = this.Documents.controls?.find(control => control.get('key').value === DocumentTypes.OTHER_INFORMATION);
		return doc.get('id');
	}

	get Documents() {
		return this.form.get('documents') as UntypedFormArray;
	}

	get CRTId() {
		return this.form.get('cRTId');
	}

	buildForm(): void {
		this.form = this.fb.group({
			purpose: [''],
			purposeId: [null],
			recommendation: [''],
			recommendationId: [null],
			clientBackgroundCharacter: [''],
			clientBackgroundCharacterId: [null],
			otherInformation: [''],
			otherInformationId: [null],
			cRTId: [null],
			documents: this.fb.array([])
		})

		const documents = this.form.get('documents') as UntypedFormArray
		Object.values(DocumentTypes)?.map(type => {
			documents.push(this.fb.group({
				content: '',
				key: type,
				id: null,
				fileName: type?.replace('Id', '')
			}));
		})
	}

	renderNotes(data, settings) {
		if (!data && settings) {
			this.renderNotesControlValue(settings);
		}
		return of(data).pipe(
			take(1),
			filter(filteredData => !!filteredData),
			concatMap((concatData) => {
				return concat(
					from(this.Documents.controls).pipe(
						concatMap(control => {
							const key = control.get('key').value;
							const id = concatData[key];
							control.get('id').setValue(id);
							return id ? this.businessService.GetFile(id).pipe(
								map((document) => {
									return {
										document,
										key
									}
								})
							) : of(null);
						}),
						concatMap((res) => {
							return res?.document?.DocumentLink ? this.businessService.GetDocumentFromURL(res?.document?.DocumentLink).pipe(
								map((content) => {
									return {
										content,
										key: res.key
									}
								})
							) : of(null)
						}),
						tap((res) => {
							if (!!!res) {
								if (settings) {
									this.renderNotesControlValue(settings);
									this.updateNoteContentOnState();
									return;
								}
								return;
							}

							const control = this.Documents.controls?.find(x => x.get('key').value === res?.key);
							control.get('content').setValue(res?.content);
							this.updateNoteContentOnState();
						})
					)
				)
			})
		);
	}

	renderNotesControlValue(data) {
		if (this.Purpose) {
			this.Purpose.setValue(data.purpose);
		}

		if (this.ClientBackgroundCharacter) {
			this.ClientBackgroundCharacter.setValue(data.clientBackgroundAndCharacter);
		}

		if (this.Recommendation) {
			this.Recommendation.setValue(data.recommendation);
		}

		if (this.OtherInformation) {
			this.OtherInformation.setValue(data.otherInformation);
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	next() {
		this.save(true);
	}

	previous() {
		this.save();
	}


	save(isNext = false, redirect: boolean = true) {
		this.Purpose.setValue(this.purposeEditor.content);
		this.Recommendation.setValue(this.recommendationEditor.content);
		this.ClientBackgroundCharacter.setValue(this.clientBackgroundEditor.content);
		this.OtherInformation.setValue(this.otherInformationEditor.content);
		const documents = {
			purposeId: null,
			clientBackgroundCharacterId: null,
			recommendationId: null,
			otherInformationId: null
		};

		let tmpControl = null;
		const formValue = this.form.getRawValue();
		of(formValue).pipe(
			take(1),
			concatMap(() =>
				concat(
					from(this.Documents.controls).pipe(
						concatMap(control => {
							tmpControl = control;
							const value = control.get('content').value ? control.get('content').value : '<p></p>'
							return convertUtil.convertToBase64(value);
						}),
						map(content => {
							const doc = NoteMapper.mapDocument(content,
								tmpControl.get('id').value,
								0,
								tmpControl.get('key').value,
								tmpControl.get('fileName').value
							);
							return doc
						}),
						concatMap(doc => {
							return doc.documentId ? this.businessService.UpdateDocument(doc) : this.businessService.UploadDocument(doc).pipe(
								map(documentId => {
									return {
										id: doc.id,
										documentId: formValue[doc.id] ? formValue[doc.id] : documentId
									}
								})
							)
						})
					)
				)
			),
			map((result) => {
				documents[result?.id] = result?.documentId;
			}),
			finalize(() => {
				const payload = {
					purposeId: this.PurposeId.value ? this.PurposeId.value : documents?.purposeId,
					clientBackgroundCharacterId: this.ClientBackgroundCharacterId.value
						? this.ClientBackgroundCharacterId.value
						: documents.clientBackgroundCharacterId,
					recommendationId: this.RecommendationId.value ? this.RecommendationId.value : documents?.recommendationId,
					otherInformationId: this.OtherInformationId.value ? this.OtherInformationId.value : documents?.otherInformationId,
					cRTId: formValue?.cRTId
				}

				this.PurposeId.setValue(payload.purposeId);
				this.ClientBackgroundCharacterId.setValue(payload.clientBackgroundCharacterId);
				this.RecommendationId.setValue(payload.recommendationId);
				this.OtherInformationId.setValue(payload.otherInformationId);
				this.upsertNotes(this.form.get('cRTId').value, payload, isNext, redirect);
			})
		).subscribe()
	}

	upsertNotes(cRTId, payload, isNext = false, redirect = true) {
		of(cRTId).pipe(
			concatMap((id) =>
				iif(
					() => !!id,
					this.noteService.update(payload, this.parentCRTId),
					this.noteService.add(payload, this.parentCRTId)
				)
			)
		).subscribe(
			res => {
				if (!cRTId) {
					this.form.get('cRTId').setValue(res.cRTId);
				}
				this.updateNoteContentOnState();
				this.saveCompleted.emit({ isSuccess: true, isNext, redirect })
			},
			() => {
				this.updateNoteContentOnState();
				this.saveCompleted.emit({ isSuccess: true, isNext, redirect })
			}
		)
	}

	updateNoteContentOnState() {
		this.noteService.updateNoteContentState({
			purpose: this.Purpose.value,
			clientBackgroundCharacter: this.ClientBackgroundCharacter?.value || '',
			recommendation: this.Recommendation?.value || '',
			otherInformation: this.OtherInformation?.value || '',
		})
	}
}
