<button
	id="closeModalBtn"
	class="tap-btn tap-btn-outline modal-btn-close"
	(click)="bsModalRef.hide()"
>
	<span class="material-icons"> close </span>
</button>
<ng-container>
	<div class="modal-header">
		<h1 class="modal-title" id="myModalLabel">
			{{ selectedDetail }}
		</h1>
		<app-manual-loader-mini [isLoading]="loading"></app-manual-loader-mini>
	</div>
	<div class="modal-body" [class.loading]="loading">
		<div class="fallback clearfix">
			<tabset #sidebarNav class="tap-tabset-sidebar" [vertical]="true">
				<tab
					*ngIf="(hasLR$ | async) && isTabAvailable('LR')"
					heading="L&R Insurance"
					id="lr"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('LR')"
				>
					<div class="clearfix px-0 px-md-3">
						<table class="table table-hover">
							<tbody>
								<tr
									*ngFor="let item of listPerPageLR; let i = index"
									id="document_{{ i }}"
									(click)="selectLinkDocument(item)"
									[class.loading]="loading"
								>
									<th class="max-width-150px" scope="row">
										{{ item.fileName }}
									</th>
									<td>{{ item.createDateTime | momentDatetime }}</td>
								</tr>
							</tbody>
						</table>
						<ng-container
							*ngIf="
								document?.lR &&
								document.lR?.length > itemPerPage &&
								activeDocType === 'LR'
							"
						>
							<pagination
								class="p-2"
								[totalItems]="
									document.lR && document.lR?.length > 0
										? document.lR?.length
										: 0
								"
								[(ngModel)]="currentpage"
								[itemsPerPage]="itemPerPage"
								[maxSize]="5"
								(pageChanged)="setPage($event, 'lr')"
							>
							</pagination>
						</ng-container>
					</div>
				</tab>
				<tab
					*ngIf="(hasM$ | async) && isTabAvailable('M')"
					heading="Mortgage"
					id="m"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('M')"
				>
					<div class="clearfix px-0 px-md-3">
						<table class="table table-hover">
							<tbody>
								<tr
									*ngFor="let item of listPerPageM; let i = index"
									id="document_{{ i }}"
									(click)="selectLinkDocument(item)"
								>
									<th class="max-width-150px" scope="row">
										{{ item.fileName }}
									</th>
									<td>{{ item.createDateTime | momentDatetime }}</td>
								</tr>
							</tbody>
						</table>
						<ng-container
							*ngIf="
								document.m &&
								document.m?.length > itemPerPage &&
								activeDocType === 'M'
							"
						>
							<pagination
								class="p-2"
								[totalItems]="
									document.m && document.m?.length > 0 ? document.m?.length : 0
								"
								[(ngModel)]="currentpage"
								[itemsPerPage]="itemPerPage"
								[maxSize]="5"
								(pageChanged)="setPage($event, 'm')"
							>
							</pagination>
						</ng-container>
					</div>
				</tab>
				<tab
					*ngIf="(hasFG$ | async) && isTabAvailable('FG')"
					heading="F&G Insurance"
					id="fg"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('FG')"
				>
					<div class="clearfix px-0 px-md-3">
						<table class="table table-hover">
							<tbody>
								<tr
									*ngFor="let item of listPerPageFG; let i = index"
									id="document_{{ i }}"
									(click)="selectLinkDocument(item)"
								>
									<th class="max-width-150px" scope="row">
										{{ item.fileName }}
									</th>
									<td>{{ item.createDateTime | momentDatetime }}</td>
								</tr>
							</tbody>
						</table>
						<ng-container
							*ngIf="
								document.fG &&
								document.fG?.length > itemPerPage &&
								activeDocType === 'FG'
							"
						>
							<pagination
								class="p-2"
								[totalItems]="
									document.fG && document.fG?.length > 0
										? document.fG?.length
										: 0
								"
								[(ngModel)]="currentpage"
								[itemsPerPage]="itemPerPage"
								[maxSize]="5"
								(pageChanged)="setPage($event, 'fg')"
							>
							</pagination>
						</ng-container>
					</div>
				</tab>
				<tab
					*ngIf="((hasK$ | async) || (hasI$ | async)) && isTabAvailable('K')"
					heading="Investment"
					id="k"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('K')"
				>
					<div class="clearfix px-0 px-md-3">
						<table class="table table-hover">
							<tbody>
								<tr
									*ngFor="let item of listPerPageK; let i = index"
									id="document_{{ i }}"
									(click)="selectLinkDocument(item)"
								>
									<th class="max-width-150px" scope="row">
										{{ item.fileName }}
									</th>
									<td>{{ item.createDateTime | momentDatetime }}</td>
								</tr>
							</tbody>
						</table>
						<ng-container
							*ngIf="
								document.k &&
								document.k?.length > itemPerPage &&
								activeDocType === 'K'
							"
						>
							<pagination
								class="p-2"
								[totalItems]="
									document.k && document.k?.length > 0 ? document.k?.length : 0
								"
								[(ngModel)]="currentpage"
								[itemsPerPage]="itemPerPage"
								[maxSize]="5"
								(pageChanged)="setPage($event, 'k')"
							>
							</pagination>
						</ng-container>
					</div>
				</tab>
				<tab
					*ngIf="isTabAvailable('O')"
					heading="Others"
					id="o"
					customClass="linkdocument-navtabs"
					(selectTab)="selectType('O')"
				>
					<div class="clearfix px-0 px-md-3">
						<table class="table table-hover">
							<tbody>
								<tr
									*ngFor="let item of listPerPageO; let i = index"
									id="document_{{ i }}"
									(click)="selectLinkDocument(item)"
								>
									<th class="max-width-150px" scope="row">
										{{ item.fileName }}
									</th>
									<td>{{ item.createDateTime | momentDatetime }}</td>
								</tr>
							</tbody>
						</table>
						<ng-container
							*ngIf="
								document.o &&
								document.o?.length > itemPerPage &&
								activeDocType === 'O'
							"
						>
							<pagination
								class="p-2"
								[totalItems]="
									document.o && document.o?.length > 0 ? document.o?.length : 0
								"
								[(ngModel)]="currentpage"
								[itemsPerPage]="itemPerPage"
								[maxSize]="5"
								(pageChanged)="setPage($event, 'o')"
							>
							</pagination>
						</ng-container>
					</div>
				</tab>
				<ng-container *ngIf="isTabAvailable('AP') && document?.aP?.length > 0">
					<tab
						*appRestrictTo="['FAP']"
						heading="Advice Process"
						id="ap"
						customClass="linkdocument-navtabs"
						(selectTab)="selectType('AP')"
					>
						<div class="clearfix px-0 px-md-3">
							<table class="table table-hover">
								<tbody>
									<tr
										*ngFor="let item of listPerPageAP; let i = index"
										id="document_{{ i }}"
										(click)="selectLinkDocument(item)"
									>
										<th class="max-width-150px" scope="row">
											{{ item.fileName }}
										</th>
										<td>{{ item.createDateTime | momentDatetime }}</td>
									</tr>
								</tbody>
							</table>
							<ng-container
								*ngIf="
									document.aP &&
									document.aP?.length > itemPerPage &&
									activeDocType === 'AP'
								"
							>
								<pagination
									class="p-2"
									[totalItems]="
										document.aP && document.aP?.length > 0
											? document.aP?.length
											: 0
									"
									[(ngModel)]="currentpage"
									[itemsPerPage]="itemPerPage"
									[maxSize]="5"
									(pageChanged)="setPage($event, 'ap')"
								>
								</pagination>
							</ng-container>
						</div>
					</tab>
				</ng-container>
				<ng-container
					*ngIf="
						(hasCustomerPortalDocument$ | async) && isTabAvailable('CPMOATD')
					"
				>
					<tab
						heading="Client Portal"
						id="cp"
						customClass="linkdocument-navtabs"
						(selectTab)="selectType('CP')"
					>
						<div class="clearfix px-0 px-md-3">
							<table class="table table-hover">
								<tbody>
									<tr
										*ngFor="let item of listPerPageCP; let i = index"
										id="document_{{ i }}"
										(click)="selectLinkDocument(item)"
									>
										<th class="max-width-150px" scope="row">
											{{ item.fileName }}
										</th>
										<td>{{ item.createDateTime | momentDatetime }}</td>
									</tr>
								</tbody>
							</table>
							<ng-container
								*ngIf="
									document.cP &&
									document.cP?.length > itemPerPage &&
									activeDocType === 'CP'
								"
							>
								<pagination
									class="p-2"
									[totalItems]="
										document.cP && document.cP?.length > 0
											? document.cP?.length
											: 0
									"
									[(ngModel)]="currentpage"
									[itemsPerPage]="itemPerPage"
									[maxSize]="5"
									(pageChanged)="setPage($event, 'cp')"
								>
								</pagination>
							</ng-container>
						</div>
					</tab>
				</ng-container>
			</tabset>
		</div>
	</div>
</ng-container>
