<div class="container-fluid tap-business-profile clearfix">
    <div class="row">
      <div class="col tap-business-profile__main-content py-3 px-3">
        <div class="row">
          <div class="col">
            <app-business-profile-group
              class="d-block"
              [addMode]="true"
              [isLead]="isLead$ | async"
              [advisers]="advisers$ | async"
              [altAdvisers]="altAdviserChoices$ | async"
              [leadGens]="leadGenChoices$ | async"
              [PCT]="PCT$ | async"
              [PCE]="PCE$ | async"
              [PCPC]="PCPC$ | async"
              [PCLE]="PCLE$ | async"
              [PCLT]="PCLT$ | async"
              [PCR]="PCR$ | async"
              [LS]="LS$ | async"
              [SAS]="SAS$ | async"
							[allStaff]="allStaff$ | async"
              (saveEvent)="addNewCompany($event)"
            >
            </app-business-profile-group>
          </div>
        </div>
  
        <div class="row">
          <div class="col px-0">
            <tabset class="tap-tabset tap-tabset--border-radius-left-right tap-client-profile__tabset">
              <tab heading="Business & Linked Contacts" id="businessAndLinkedContacts" [customClass]="'m-0'">
                <div class="row clearfix mx-0">
                  <div class="col">
                    <app-form-company
                      [isLead]="isLead$ | async"
                      [addMode]="true"
                      [isEdit]="true"
                      [isSaving]="false"
                      [leadTypeChoices]="leadTypeChoices$ | async"
                      [leadOriginChoices]="leadOriginChoices$ | async"
                      [leadGenChoices]="leadGenChoices$ | async"
                      [adviserGenChoices]="adviserGenChoices$ | async"
                			[allStaffChoices]="allStaffChoices$ | async"
                    ></app-form-company>
                  </div>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
  