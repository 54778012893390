import { LrInsuranceRequest } from './lr-insurance-request.model';
import { staticConf } from '../../../../core/config/static-config.service';

// This returns an object
export const comparable = o =>
	typeof o !== 'object' || !o
		? o
		: Object.keys(o)
				?.sort()
				?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (
	req: LrInsuranceRequest,
	saveReq: LrInsuranceRequest
) => {
	let r = true;

	if (!req || !saveReq) {
		return (r = false);
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedAdvisers)) !==
		JSON.stringify(comparable(saveReq.SelectedAdvisers))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedAltAdvisers)) !==
		JSON.stringify(comparable(saveReq.SelectedAltAdvisers))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedInsuranceProviders)) !==
		JSON.stringify(comparable(saveReq.SelectedInsuranceProviders))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedPolicyStatus)) !==
		JSON.stringify(comparable(saveReq.SelectedPolicyStatus))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedRetentionStatus)) !==
		JSON.stringify(comparable(saveReq.SelectedRetentionStatus))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedClaimStatus)) !==
		JSON.stringify(comparable(saveReq.SelectedClaimStatus))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedInsuranceTypes)) !==
		JSON.stringify(comparable(saveReq.SelectedInsuranceTypes))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedLeadTypes)) !==
		JSON.stringify(comparable(saveReq.SelectedLeadTypes))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedLeadOrigins)) !==
		JSON.stringify(comparable(saveReq.SelectedLeadOrigins))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.NextActivityDateMin)) !==
		JSON.stringify(comparable(saveReq.NextActivityDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.NextActivityDateMax)) !==
		JSON.stringify(comparable(saveReq.NextActivityDateMax))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.PolicyStartDateMin)) !==
		JSON.stringify(comparable(saveReq.PolicyStartDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.PolicyStartDateMax)) !==
		JSON.stringify(comparable(saveReq.PolicyStartDateMax))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.NextReviewDateMin)) !==
		JSON.stringify(comparable(saveReq.NextReviewDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.NextReviewDateMax)) !==
		JSON.stringify(comparable(saveReq.NextReviewDateMax))
	) {
		r = false;
	}

	if (
		JSON.stringify(comparable(req.SubmittedDateMin)) !==
		JSON.stringify(comparable(saveReq.SubmittedDateMin))
	) {
		r = false;
	}
	if (
		JSON.stringify(comparable(req.SubmittedDateMax)) !==
		JSON.stringify(comparable(saveReq.SubmittedDateMax))
	) {
		r = false;
	}

	return r;
};

export const request = (
	filter: any,
	form: LrInsuranceRequest,
	index: number
) => {
	const req = {
		ServiceCode: staticConf.lrServiceCode,
		SelectedAdvisers: form.SelectedAdvisers,
		SelectedAltAdvisers: form.SelectedAltAdvisers,
		SelectedInsuranceProviders: form.SelectedInsuranceProviders,

		SelectedPolicyStatus: form.SelectedPolicyStatus,
		SelectedRetentionStatus: form.SelectedRetentionStatus,
		SelectedClaimStatus: form.SelectedClaimStatus,

		SelectedInsuranceTypes: form.SelectedInsuranceTypes,
		SelectedLeadTypes: form.SelectedLeadTypes,
		SelectedLeadOrigins: form.SelectedLeadOrigins,
		SelectedAdviserStatuses: form.SelectedAdviserStatuses,

		NextActivityDateMin: form.NextActivityDateMin,
		NextActivityDateMax: form.NextActivityDateMax,
		PolicyStartDateMin: form.PolicyStartDateMin,
		PolicyStartDateMax: form.PolicyStartDateMax,
		NextReviewDateMin: form.NextReviewDateMin,
		NextReviewDateMax: form.NextReviewDateMax,
		SubmittedDateMin: form.SubmittedDateMin,
		SubmittedDateMax: form.SubmittedDateMax,

		Paging: {
			Index: index,
			Column: filter ? filter.Column : null,
			Direction: filter ? filter.Direction : null,
		},
	};
	return req;
};
