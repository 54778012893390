import {
	Component,
	OnInit,
	AfterViewInit,
	NgZone,
	OnDestroy,
	ViewChild,
	ChangeDetectionStrategy,
} from '@angular/core';
import { MortgageQuery } from './states/mortgage.query';
import { linkColumn, columns, Metakey } from './mortgage-datatable.config';
import {
	map,
	debounceTime,
	takeUntil,
	withLatestFrom,
	tap,
	shareReplay,
	take,
	mergeMap,
} from 'rxjs/operators';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { Observable, Subject, fromEvent, of } from 'rxjs';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { MortgageService } from './states/mortgage.service';
import { Row } from './states/mortgage.model';
import {
	CommandRoute,
	RouteService,
} from '../../../../core/config/route.service';
import { TableColumn, DatatableComponent } from '@swimlane/ngx-datatable';
import { staticConf } from '../../../../core/config/static-config.service';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivityModalComponent } from '../../../../shared/modal/activity/activity-modal/activity-modal.component';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import MomentUtil from '../../../../util/moment.util';
import { util } from '../../../../util/util';
import * as moment from 'moment';
import { MortgageFormComponent } from './mortgage-form/mortgage-form.component';
import { comparer, request } from './mortgage.util';
import { MortgageUiQuery } from './states/mortgage-ui.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { LocalService } from 'src/app/core/services/local.service';
import * as R from 'ramda';
import { NoteTypes } from 'src/app/shared/models/notes/note.model';
declare var $: any;

@Component({
	selector: 'app-mortgage',
	templateUrl: './mortgage.component.html',
	styleUrls: ['./mortgage.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MortgageComponent implements OnInit, AfterViewInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	columns$ = this.mortgageQuery.tableColumns$;
	columnsWithLink$ = this.columns$.pipe(map((x) => [...x, linkColumn]));
	linkColumn = linkColumn;

	cellsLoading$ = this.mortgageQuery.cellsLoading$;
	cellsEditing$ = this.mortgageQuery.cellsEditing$;
	cellsTempvalue$ = this.mortgageQuery.cellsTempvalue$;
	rowsLoading$ = this.mortgageQuery.rowsLoading$;
	isSearching$ = this.mortgageQuery.isSearching$;

	rows$ =
		this.mortgageQuery.getValue().count <= 500
			? this.mortgageQuery.sortedRows$
			: this.mortgageQuery.rows$;
	hasRow$ = this.mortgageQuery.hasRows$;
	propSort$ = this.mortgageQuery.uiQuery.propSort$;
	sorts$ = this.mortgageQuery.sorts$;

	hasPermissions$ = this.userQuery.hasPermission$;

	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;
	allAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$;
	allAdviserChoicesAsObject$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);

	edit = this.mortgageService.edit;
	cancel = this.mortgageService.cancel;
	setTempValue = this.mortgageService.setTempValue;

	properties = columns;

	showSearchFields = false;
	tblHeight: SafeStyle;
	activityTypes = NoteTypes;

	resizeEvent$ = fromEvent(window, 'resize');

	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild(MortgageFormComponent)
	mortgageFormComponent: MortgageFormComponent;
	trackByFn(index, item: TableColumn) {
		return item.prop;
	}
	rowIdentity = (row: Row) => row.CustomerServiceID;

	route = (metakey: Metakey, row: Row): CommandRoute | string => {
		switch (metakey) {
			case 'Name':
				return row.link.IsCompany
					? this.routeService.businessMCustService(
							row.CustomerID,
							row.CustomerServiceID
					  )
					: this.routeService.customerMCustService(
							row.CustomerID,
							row.CustomerServiceID
					  );
			default:
				return null;
		}
	};
	constructor(
		private mortgageQuery: MortgageQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private mortgageService: MortgageService,
		private routeService: RouteService,
		private sanitizer: DomSanitizer,
		private ngZone: NgZone,
		private modalService: BsModalService,
		private mortgageUiQuery: MortgageUiQuery,
		private userQuery: UserQuery,
		private localService: LocalService
	) {}

	ngOnInit() {
		this.tblHeight =
			this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - 141px)`);

		this.resizeEvent$
			.pipe(debounceTime(500), takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.mortgageService.reloadData();
			});
	}

	ngAfterViewInit() {
		this.dragHeader();
	}

	businessRoute(id: number, customerSvcId: number) {
		return this.ngZone.run(() =>
			this.routeService.businessMCustService(id, customerSvcId)
		);
	}
	clientRoute(id: number, customerSvcId: number) {
		return this.ngZone.run(() =>
			this.routeService.customerMCustService(id, customerSvcId)
		);
	}

	reorder = (reorderEvent: {
		column: TableColumn;
		newValue: number;
		prevValue: number;
	}) => {
		this.mortgageService
			.reorderColumn(reorderEvent.prevValue, reorderEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	resize = (event: { column: TableColumn; newValue: number }) => {
		if (event && (!event.column || !event.newValue)) {
			return;
		}
		this.mortgageService
			.resizeColumn(`${event.column.prop}`, event.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	sort(a: { sorts; column; prevValue; newValue }) {
		if (this.mortgageQuery.getValue().count <= 500) {
			return this.mortgageService?.sort(a.sorts[0].prop, a.sorts[0].dir);
		}

		this.mortgageService
			.search(
				request(
					{
						Column: a.sorts[0].prop,
						Direction: a.sorts[0].dir,
					},
					this.mortgageFormComponent.prepareFormValue(),
					1
				)
			)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.mortgageService?.sort(a.sorts[0].prop, a.sorts[0].dir);
			});
	}

	saveField = (
		row: Row,
		CustomerServiceID: number,
		MetaKey: Metakey,
		Key: string,
		metadata: FieldMetadata<any>
	) => {
		this.mortgageService
			.saveField({
				CustomerID: row.CustomerID,
				CustomerServiceID,
				MetaKey,
				MetaValue: metadata.value,
				CustomerServiceType: staticConf.mortgageServiceCode,
			})
			.pipe(
				withLatestFrom(this.propSort$),
				mergeMap(([res, prop]) => {
					return this.rows$.pipe(
						tap((rows) => {
							if (prop === Key) {
								const i = rows?.findIndex(
									(x) => x.CustomerServiceID === row.CustomerServiceID
								);
								this.table.bodyComponent.updateOffsetY(
									i / this.table.bodyComponent.pageSize
								);
								this.table.bodyComponent.selected.push(row);
								setTimeout(() => {
									this.table.bodyComponent.selected = [];
									$('.datatable-body-row').removeClass('active');
								}, 1000);
							}
						}),
						take(1)
					);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	createClientNextActivity(customerId: number) {
		new Observable((obs) => {
			const formItem = {
				Customer: { CustomerId: customerId },
			};
			const initState: any = {
				formItem,
				AT$: this.AT$,
				AM$: this.AM$,
				adviserChoices$: this.adviserChoices$,
				adviserCalendarChoices$: this.adviserCalendarChoices$,
				header: 'Schedule Activity',
				hideClient: true,
				savefn: this.saveClientNextActivity,
				isModal: true,
				isEditForm: false,
				permissionsToComplete: ['FCCA', 'FCLA'],
			};
			this.modalService.show(ActivityModalComponent, {
				class: `modal-dialog-centered ${
					this.localService.getValue('loginType') === 'microsoft'
						? 'modal-dialog-outlook-xl'
						: 'modal-xl'
				}`,
				initialState: initState,
				ignoreBackdropClick: true,
			});
			obs.complete();
		})
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}
	saveClientNextActivity = (ac: ActivityViewModel) =>
		this.mortgageService.createClientNextActivity(ac);

	updateClientNextActivityField = (customerId: number) => {
		this.mortgageService
			.updateClientAndUserNextActivity(customerId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};
	updateUserNextActivityField = (customerId: number) => {
		this.mortgageService
			.updateClientAndUserNextActivity(customerId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	updateNoteField = (customerServiceId: number, metadata: any) =>
		this.mortgageService.updateNote(customerServiceId, metadata.value);

	convertDateToNZ(d: string) {
		const nzToday = MomentUtil.createMomentNz().format('LL');
		const splicedDate = d?.slice(0, 10);
		const date = moment(splicedDate, 'DD/MM/YYYY');
		return date.diff(nzToday, 'days');
	}

	getRowClass = (row: Row) => {
		const nd = this.convertDateToNZ(
			row.ClientNextActivity.value ? row.ClientNextActivity.value : ''
		);
		// If 0 meaning its today
		if (nd === 0) {
			return 'isDueDate';
		}
		// if negative meaning overdue
		if (nd < 0) {
			return 'isOverdue';
		}
		if (nd < 6) {
			return 'isDueNextFiveDays';
		}
	};

	getCellClass(metadata: FieldMetadata<any>, row: Row): string {
		if (
			(metadata.metakey === 'Client Next Activity' &&
				row.ClientNextActivity.value) ||
			(metadata.metakey === 'User Next Activity' && row.UserNextActivity.value)
		) {
			const rowValue =
				metadata.metakey === 'Client Next Activity'
					? row.ClientNextActivity.value
					: row.UserNextActivity.value;
			const nd = this.convertDateToNZ(rowValue);
			// If 0 meaning its today
			if (nd === 0) {
				return 'isDueDate';
			}
			// if negative meaning overdue
			if (nd < 0) {
				return 'isOverdue';
			}
			if (nd < 6) {
				return 'isDueNextFiveDays';
			}
		}
	}

	onToggleSearch(data) {
		this.showSearchFields = data.showSearch;
		if (this.showSearchFields && data.width > 1199) {
			this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
				`calc(100vh - ${104 + data.height}px)`
			);
		} else {
			this.tblHeight =
				this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - 141px)`);
		}
		setTimeout(() => {
			this.mortgageService.reloadData();
			document.body.style.overflowY = 'auto';
		}, 500);
	}

	// Dragging column header
	dragHeader() {
		const _tbl = 'ngx-datatable';
		const _header = 'datatable-header';
		const _headerInner = 'datatable-header-inner';
		const _headerCell = 'datatable-header-cell';
		const _body = 'datatable-body';
		const _draggable = 'draggable';
		const _draggingState = 'dragging-state';

		const tblHeaderCell = `${_tbl} ${_header} ${_headerCell}`;
		const tblHeaderCellDrag = `${_tbl} ${_header} ${_headerCell} .${_draggable}`;
		const header = $(`${_tbl} ${_header}`);
		const tblBody = $(`${_tbl} ${_body}`);

		let isDragging = 0;
		let timer;
		let scrollTimer;
		let _self;
		let tranlateLeft;

		const scrollLeftHeader = (pageX, headerWidth) => {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			const innerHeader = header?.find(`.${_headerInner}`);
			const key = 'MSStream';
			const os = /Windows/.test(navigator.userAgent) && !window[key];
			const maxScroll =
				innerHeader[0].scrollWidth - tblBody.width() + (os ? 16 : 0);
			const isDraggingState = $(tblHeaderCell)
				.parent()
				.hasClass(`${_draggingState}`);

			// Check if the mouse is in the left edge of header while dragging
			if (pageX <= 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() > 0) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() - 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft -= 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the left edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
			// Check if the mouse is in the right edge of header while dragging
			if (pageX >= headerWidth - 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() <= maxScroll) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() + 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft += 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the right edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
		};

		$(document)
			.on('mousedown', tblHeaderCellDrag, function (e) {
				if (e.which !== 1) {
					return;
				}
				_self = $(this);
				tranlateLeft = 0;

				isDragging = 1;

				timer = setTimeout(() => {
					_self.closest(tblHeaderCell).parent().addClass(`${_draggingState}`);
				}, 500);
			})

			.on('mouseup', function () {
				if (timer) {
					clearTimeout(timer);
				}
				if (scrollTimer) {
					clearTimeout(scrollTimer);
					_self
						.closest(`${_headerCell}`)
						.css('transform', `translate3d(0px, 0px, 0px)`);
				}
				if (isDragging) {
					$(tblHeaderCell)
						.removeClass(`${_draggable}`)
						.parent()
						.removeClass(`${_draggingState}`);
					isDragging = 0;
				}
			})

			// Scroll header when dragging column into the edge of header
			.on('mousemove', function (e) {
				const headerWidth = header.width();
				const pageX = e.pageX - header.offset().left;
				scrollLeftHeader(pageX, headerWidth);
			});
	}

	ngOnDestroy() {
		$(document).off('mousedown mouseup mousemove');

		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	createChoicesFromEntityList(
		list: {
			Id: number;
			Name: string;
		}[]
	): ViewDisplayValue[] {
		return R.uniq(
			list?.map((x) => ViewDisplayValue.Map(x.Id?.toString(), x.Name))
		);
	}
	createLookupFromEntityList(
		list: {
			Id: number;
			Name: string;
		}[]
	): { [key: string]: string } {
		return util.createLookupFromList(
			(x) => x.Id,
			(x) => x.Name,
			list
		);
	}

	onPage(event: any, indexes: any) {
		if (this.mortgageQuery.getValue().count <= 500) {
			return;
		}
		// pre-fetch the next page
		const calc = this.mortgageQuery.getCount() - 2;
		if (calc <= indexes.last) {
			this.loadMore();
		}
	}

	loadMore() {
		const sl = this.table.element.querySelector('.datatable-body').scrollLeft;
		const sh = this.table.bodyComponent.scrollHeight;
		this.mortgageQuery.searchForm$
			.pipe(
				withLatestFrom(this.mortgageQuery.isComplete$),
				withLatestFrom(this.mortgageUiQuery.isSearching$),
				map(([[x, isComplete], isSearching]) => {
					const paging = {
						Column: x ? x.Paging.Column : '',
						Direction: x ? x.Paging.Direction : '',
					};

					// Checking if filter has changed
					const compare = comparer(
						request(paging, this.mortgageFormComponent.prepareFormValue(), 0),
						x
					);
					const currentIndex = x && x.Paging ? x.Paging.Index + 1 : 0;

					return {
						compare,
						isSearching,
						isComplete,
						paging,
						currentIndex,
					};
				}),
				mergeMap((x) => {
					if (x.compare && !x.isSearching && !x.isComplete) {
						return this.mortgageService.search(
							request(
								x.paging,
								this.mortgageFormComponent.prepareFormValue(),
								x.currentIndex
							)
						);
					}
					return of(null);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.table.element.querySelector('.datatable-body').scrollTop = sh;
				this.table.element.querySelector('.datatable-body').scrollLeft = sl;
			});
	}
}
