import { Injectable } from '@angular/core';

import {
  tap,
  switchMap,
  take,
  withLatestFrom,
  mergeMap,
  catchError,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../../../core/base/api.service';

import { applyTransaction } from '@datorama/akita';
import { UserQuery } from '../../../../../domain/user/user.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BusinessProfileStore } from '../business-profile.store';
import { BusinessProfileQuery } from '../business-profile.query';
import produce from 'immer';
import MomentUtil from '../../../../../util/moment.util';
import { BusinessProfileUtilsService } from '../business-profile.utils.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { BusinessProfileService } from '../business-profile.service';
import { kiwiSaverServiceUtil } from '../../../../../shared/services/service-utils/kiwisaver.util';
import { BusinessConfigQuery } from '../../../../../domain/business-config/business-config.query';
import { NoteState } from '../../../../../shared/models/activity-timeline/activity-timeline.model';
import * as R from 'ramda';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { InvestmentState } from '../../../../../shared/models/services/investments/investments.model';
import { NoteTypes } from 'src/app/shared/models/notes/note.model';

@Injectable()
export class InvestmentService extends BusinessProfileUtilsService {
  constructor(
    protected dropdownValueQuery: DropdownValueQuery,
    protected blStaffsQuery: BLStaffsQuery,
    protected store: BusinessProfileStore,
    protected query: BusinessProfileQuery,
    protected api: ApiService,
    protected businessService: BusinessService,
    protected userQuery: UserQuery,
    protected profileService: BusinessProfileService,
    protected businessConfigQuery: BusinessConfigQuery
  ) {
    super(
      store,
      query,
      dropdownValueQuery,
      blStaffsQuery,
      businessConfigQuery,
      userQuery
    );
  }

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  createInvestment(req: InvestmentState) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      mergeMap((x) =>
        this.businessService.AddInvestments({
          ...x,
          serviceCode: ServicesCodes.Investment,
          customerServiceID: 0,
        })
      ),
      tap((x) => (req.customerServiceID = +x)),
      switchMap((status) =>
        req.note
          ? this.businessService.AddNote({
              CustomerID: req.customerID,
              CustomerServiceID: req.customerServiceID,
              Notes: req.note,
              ActivityType: NoteTypes.Investment,
              StaffName: `${this.userQuery.getValue().FirstName} ${
                this.userQuery.getValue().LastName
              }`,
            })
          : of(status)
      ),
      tap((x) => {
        applyTransaction(() => {
          if (x && req.note) {
            this.profileService.addToActivityTimelineNotes(
              +x,
              req.note,
							NoteTypes.Investment,
              req.customerID,
              req.customerServiceID
            );
          }
          const iState = this.query.getValue().investments;
          const state = produce<InvestmentState[]>(iState, (draft) => {
            draft.push({
              ...req,
              note: '',
              notes: req.note
                ? [
                    {
                      notesID: +x,
                      customerServiceID: req.customerServiceID,
                      notes: req.note,
                      isActive: true,
                      createDateTime: MomentUtil.formatToServerDatetime(
                        MomentUtil.createMomentNz()
                      ),
                      createdByStaffId: this.userQuery.getValue().StaffID,
                      customerID: req.customerID,
                      staffName: `${this.userQuery.getValue().FirstName} ${
                        this.userQuery.getValue().LastName
                      }`,
                      staffLevel: 0,
                      dueDateTime: null,
                      activityType: null,
                      activityName: null,
                      assignedToAdviser: null,
                    },
                  ]
                : [],
            });
          });

          this.store.setInvestments(kiwiSaverServiceUtil(state));
        });
      }),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  updateInvestment(req: InvestmentState) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      mergeMap((x) =>
        this.businessService.UpdateInvestments({ ...x, serviceCode: ServicesCodes.Investment })
      ),
      switchMap((status) =>
        req.note
          ? this.businessService.AddNote({
              CustomerID: req.customerID,
              CustomerServiceID: req.customerServiceID,
              Notes: req.note,
              ActivityType: NoteTypes.Investment,
              StaffName: `${this.userQuery.getValue().FirstName} ${
                this.userQuery.getValue().LastName
              }`,
            })
          : of(status)
      ),
      tap((x) => {
        applyTransaction(() => {
          if (x) {
            if (x && req.note) {
              this.profileService.addToActivityTimelineNotes(
                +x,
                req.note,
                NoteTypes.Investment,
                req.customerID,
                req.customerServiceID
              );
            }
            const kState = this.query.getValue().investments;
            const state = produce(kState, (draft) => {
              draft?.forEach((ks, i) => {
                if (draft[i].customerServiceID === req.customerServiceID) {
                  draft[i] = req;

                  if (req.note) {
                    draft[i].notes?.unshift({
                      notesID: +x,
                      customerServiceID: req.customerServiceID,
                      notes: req.note,
                      isActive: true,
                      createDateTime: MomentUtil.formatToServerDatetime(
                        MomentUtil.createMomentNz()
                      ),
                      createdByStaffId: this.userQuery.getValue().StaffID,
                      customerID: req.customerID,
                      staffName: `${this.userQuery.getValue().FirstName} ${
                        this.userQuery.getValue().LastName
                      }`,
                      staffLevel: 0,
                      dueDateTime: null,
                      activityType: null,
                      activityName: null,
                      assignedToAdviser: null,
                    });
                  } else {
                    draft[i].notes = ks.notes;
                  }

                  draft[i].note = '';
                }
              });
            });

            this.store.setInvestments(kiwiSaverServiceUtil(state));
          }
        });
      }),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  deleteInvestment(req: InvestmentState) {
    return of(req).pipe(
      mergeMap((x) =>
        this.businessService.DeactivateService(+x.customerServiceID)
      ),
      tap((x) => {
        applyTransaction(() => {
          if (x) {
            const kState = this.query.getValue().investments;

            const state = kState?.filter(
              (cs) => cs.customerServiceID !== req.customerServiceID
            );

            this.store.setInvestments(kiwiSaverServiceUtil(state));
          }
        });
      }),
      tap(() =>
        applyTransaction(() => {
          const activityTimeline = this.query.getValue().activityTimeline;
          const notesState = activityTimeline?.notes?.filter(
            (n) => n.customerServiceID !== req.customerServiceID
          );
          this.store.setActivityTimeline({
            activities: activityTimeline?.activities,
            notes: notesState,
          });
        })
      ),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  archiveInvestment(req: InvestmentState, isArchive: boolean) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      mergeMap((data) =>
        this.businessService.UpdateInvestments({
          ...data,
          isActive: isArchive ? 2 : 1,
          serviceCode: ServicesCodes.Investment,
        })
      ),
      tap((x) => {
        applyTransaction(() => {
          if (x) {
            const iState = this.query.getValue().investments;

            const state = produce<InvestmentState[]>(iState, (draft) => {
              draft?.forEach((fg) => {
                if (fg.customerServiceID === newReq.customerServiceID) {
                  fg.isActive = isArchive ? 2 : 1;
                  fg.notes = fg.notes;
                }
              });
            });

            this.store.setInvestments(kiwiSaverServiceUtil(state));
          }
        });
      }),
      tap(() =>
        this.profileService
          .getCriterias(newReq.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  deleteNote(id: number, data) {
    return of(data).pipe(
      mergeMap(() => this.profileService.deleteActivityNote(id)),
      tap((x) => {
        applyTransaction(() => {
          if (x) {
            const kState = this.query.getValue().investments;

            const state = produce<InvestmentState[]>(kState, (draft) => {
              draft?.forEach((fg) => {
                if (fg.customerServiceID === data.customerServiceID) {
                  fg.notes = fg.notes?.filter((n) => n.notesID !== id);
                }
              });
            });
            this.store.setInvestments(kiwiSaverServiceUtil(state));
          }
        });
      }),
      catchError(() => of(''))
    );
  }

  syncNote(notes: NoteState) {
    return of(notes).pipe(
      withLatestFrom(this.query.investments$),
      tap(([note, ksState]) =>
        applyTransaction(() => {
          const state = produce<InvestmentState[]>(ksState, (draft) => {
            draft?.forEach((ks) => {
              if (ks.customerServiceID === note.customerServiceID) {
                ks.notes = ks.notes?.filter((n) => note.notesID !== n.notesID);
              }
            });
          });
          this.store.setInvestments(kiwiSaverServiceUtil(state));
        })
      )
    );
  }

  upsertInvestmentLinkDocument(id, customerServiceID) {
    const iState = this.query.getValue().investments;

    return of(customerServiceID).pipe(
      mergeMap((x) =>
        this.businessService.UpsertDocument({
					CustomerServiceId: x,
					CustomerServiceType: ServicesCodes.Investment,
					MetaKey: 'Document Link',
					MetaValue: id,
				})
      ),
      tap((x) => {
        applyTransaction(() => {
          if (x) {
            const state = produce<InvestmentState[]>(iState, (draft) => {
              draft?.forEach((inv) => {
                if (inv.customerServiceID === customerServiceID) {
                  inv.documentLink = id;
                }
              });
            });

            this.store.setInvestments(kiwiSaverServiceUtil(state));
          }
        });
      }),
      catchError(() => of(''))
    );
  }

  addNote(req: InvestmentState) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      switchMap(() =>
        this.businessService.AddNote({
          CustomerID: req.customerID,
          CustomerServiceID: req.customerServiceID,
          Notes: req.note,
          ActivityType: NoteTypes.Investment,
          StaffName: `${this.userQuery.getValue().FirstName} ${
            this.userQuery.getValue().LastName
          }`,
        })
      ),
      tap((x) => {
        applyTransaction(() => {
          if (x) {
            if (x && req.note) {
              this.profileService.addToActivityTimelineNotes(
                +x,
                req.note,
                NoteTypes.Investment,
                req.customerID,
                req.customerServiceID
              );
            }
            const kState = this.query.getValue().investments;
            const state = produce(kState, (draft) => {
              draft?.forEach((ks, i) => {
                if (draft[i].customerServiceID === req.customerServiceID) {
                  draft[i] = req;

                  if (req.note) {
                    draft[i].notes?.unshift({
                      notesID: +x,
                      customerServiceID: req.customerServiceID,
                      notes: req.note,
                      isActive: true,
                      createDateTime: MomentUtil.formatToServerDatetime(
                        MomentUtil.createMomentNz()
                      ),
                      createdByStaffId: this.userQuery.getValue().StaffID,
                      customerID: req.customerID,
                      staffName: `${this.userQuery.getValue().FirstName} ${
                        this.userQuery.getValue().LastName
                      }`,
                      staffLevel: 0,
                      dueDateTime: null,
                      activityType: null,
                      activityName: null,
                      assignedToAdviser: null,
                    });
                  } else {
                    draft[i].notes = ks.notes;
                  }

                  draft[i].note = '';
                }
              });
            });

            this.store.setInvestments(kiwiSaverServiceUtil(state));
          }
        });
      }),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      )
    );
  }
}
