import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { util } from '../../../util/util';

export interface ButtonGroupItem {
	text?: string;
	value?: any;
}

@Component({
	selector: 'app-button-group',
	templateUrl: './button-group.component.html',
	styleUrls: ['./button-group.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ButtonGroupComponent),
			multi: true,
		},
	],
})
export class ButtonGroupComponent<T extends ButtonGroupItem>
	implements ControlValueAccessor
{
	@Input() items$?: Observable<T[]> | null = null;

	@Input() valueProperty: keyof T = 'value';

	@Input() displayProperty: keyof T = 'text';

	// if set to group buttons apearrance will be like bootstrap button group
	@Input() stylingMode: 'button' | 'group' = 'button';

	@Input() btnColor: 'primary' | 'secondary' = 'secondary';

	// if true user can select multiple buttons
	@Input() multiple = false;

	@Input() disabled = false;

	@Input() value: any;

	@Input() btnStyle = '';

	selectedItems = new Set<any>();

	change: (value: string) => void;

  constructor(private cdr: ChangeDetectorRef) {}

	toggle(item: T): void {
		if (!this.multiple) {
			this.selectedItems.clear();
		}
		if (this.selectedItems.has(item[this.valueProperty])) {
			this.selectedItems.delete(item[this.valueProperty]);
		} else {
			this.selectedItems.add(item[this.valueProperty]);
		}
    this.change?.(item[this.valueProperty] as any);
	}

	writeValue(obj: any): void {
    this.selectedItems.clear();
		if (this.multiple) {
			this.value = util.tryParseJson(obj);
      this.value.forEach((val: string | number) => this.selectedItems.add(val));
		} else {
			this.value = obj;
      this.selectedItems.add(this.value);
		}

		const value = this.selectedItems.size
			? util.tryStringifyJson(Array.from(this.selectedItems))
			: null;

    this.value = value;
	}

	registerOnChange(fn: (value: string) => void): void {
		this.change = fn;
	}

	registerOnTouched(fn: any): void {}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
    this.cdr.detectChanges();
	}
}
