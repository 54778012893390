import { TLStaff } from './tl-staff.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface TLStaffsState extends EntityState<TLStaff> { }

@Injectable()
@StoreConfig({
    name: 'tlStaffs',
    idKey: 'StaffID'
})
export class TLStaffsStore extends EntityStore<TLStaffsState, TLStaff> {
    constructor() {
        super();
    }
}
