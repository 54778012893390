import { EmailSettingsModel } from '../../_general/email-settings.model'

export interface CrtEmailModel extends EmailSettingsModel {
	attachment: object
}

export enum EmailTypes {
	DISCLOSURE = 'DD',
	SCOPE_OF_SERVICE = 'MOATSOSES'
}

export enum EmailBodyTypes {
	DISCLOSURE = 'MOATDDE',
	SCOPE_OF_SERVICE = 'MOATSOSES',
	NOTES = 'MOATAN'
}
