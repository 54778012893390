import { Injectable } from "@angular/core";
import { QueryEntity } from '@datorama/akita';
import { EmailTemplateModel, EmailTemplateModelState, EmailTemplateState, EmailTemplateStore } from "./email-template.store";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class EmailTemplateQuery extends QueryEntity<EmailTemplateModelState> {
	constructor(protected store: EmailTemplateStore) {
		super(store);
	}

	getDataByRange(
		startIndex: number,
		endIndex: number
	): Observable<EmailTemplateState[] | null> {
		const rangeCached = this.getValue().rangeIDs?.[`${startIndex}-${startIndex + endIndex}`];
		if (!rangeCached) {
			return of(null);
		}
		// @ts-ignore-next
		return this.selectMany(rangeCached);
	}
}

