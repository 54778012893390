import {
	Component,
	Inject,
	Input,
	OnInit,
	OnDestroy,
	Renderer2,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ComponentBase } from '@core/base/component-base';
import { DocumentService } from '@modules/crm/document/states/document.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, of } from 'rxjs';
import { concatMap, delay, finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import {
	CalcTemplateType,
	TlMoatSettingsTypes,
} from '../../state/tl-moat-settings.model';
import { ServicingCalculatorSettingsMapper } from '../state/servicing-calculators-settings.mapper';
import { ServicingCalculatorState } from '../state/servicing-calculators-settings.model';
import { ServicingCalculatorSettingsService } from '../state/servicing-calculators-settings.service';
import { UpdateServicingTemplateModalComponent } from '@shared/modal/update-servicing-template-modal/update-servicing-template-modal.component';

@Component({
	selector: 'app-servicing-blank-templates',
	templateUrl: './servicing-blank-templates.component.html',
	styleUrls: ['./servicing-blank-templates.component.scss'],
})
export class ServicingBlankTemplatesComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	public bsModalRef: BsModalRef;

	@Input() data$: Observable<ServicingCalculatorState[]>;

	form: UntypedFormGroup;

	constructor(
		@Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private service: ServicingCalculatorSettingsService,
		private docService: DocumentService,
		private renderer: Renderer2
	) {
		super();
		this.buildForm();
	}

	get TemplateList() {
		return this.form.get('templateList') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			templateList: this.fb.array([]),
		});
	}

	prepList(data: ServicingCalculatorState[]) {
		while (this.TemplateList.length > 0) {
			this.TemplateList.removeAt(0);
		}
		data?.forEach((document: ServicingCalculatorState) => {
			this.TemplateList.push(this.patchValue(document));
		});
	}

	patchValue(template: ServicingCalculatorState): UntypedFormGroup {
		return this.fb.group({
			...template,
			documentName: template?.documentName || '',
			templateType: CalcTemplateType.Blank,
			documentId: template?.documentId,
			isLoading: false,
		});
	}

	ngOnInit() {
		this.data$
			.pipe(
				tap((data) => this.prepList(data)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	toggleLoading(index: number, value: boolean) {
		this.TemplateList.controls[index].get('isLoading').setValue(value);
	}

	addNewTemplate() {
		const close$ = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Upload Template',
			message: '',
			savefn$: this.addTemplateFn$,
			isTapLevel: true,
			close$,
		};
		this.bsModalRef = this.modalService.show(
			UpdateServicingTemplateModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-md modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}

	updateTemplate(index: number) {
		this.toggleLoading(index, true);
		const formItem = this.form.getRawValue()?.templateList?.[index];
		const close$ = new Observable((obs: Observer<any>) => {
			obs.complete();
			this.toggleLoading(index, false);
		});
		const initState = {
			header: 'Update Template',
			message: '',
			savefn$: this.updateTemplateFn$,
			formItem,
			isTapLevel: true,
			close$,
		};

		this.bsModalRef = this.modalService.show(
			UpdateServicingTemplateModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-md modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}

	removeTemplate(index: number) {
		const formValue = this.form.getRawValue()?.templateList?.[index];
		this.toggleLoading(index, true);

		this.updateTemplateFn$({
			...formValue,
			isActive: false,
			status: 0,
		})
			.pipe(
				delay(300),
				concatMap(() => this.service.getServicingCalculatorList()),
				finalize(() => this.toggleLoading(index, false)),
				take(1)
			)
			.subscribe();
	}

	downloadTemplate(index: number) {
		const formValue = this.form.getRawValue()?.templateList?.[index];
		this.toggleLoading(index, true);

		this.docService
			.tlDownloadLink(+formValue?.documentId)
			.pipe(
				tap((x) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
					this.toggleLoading(index, false);
				}),
				take(1)
			)
			.subscribe();
	}

	addTemplateFn$ = (data: ServicingCalculatorState) =>
		of(data).pipe(
			map((x) =>
				ServicingCalculatorSettingsMapper.mapToUpsert({
					settingsId: null,
					referenceId: 0,
					isActive: true,
					status: 1,
					sortNo: null,
					fileOutputFormat: x?.fileOutputFormat || '',
					documentName: x?.documentName || '',
					documentId: x?.documentId,
					type: TlMoatSettingsTypes.ServicingCalc,
					templateType: CalcTemplateType.Blank,
				})
			),
			concatMap((x) => this.service.addTemplate(x)),
			concatMap(() => this.service.getServicingCalculatorList()),
			take(1)
		);

	updateTemplateFn$ = (data: ServicingCalculatorState) =>
		of(data).pipe(
			map((x) =>
				ServicingCalculatorSettingsMapper.mapToUpsert({
					...x,
					type: TlMoatSettingsTypes.ServicingCalc,
					templateType: CalcTemplateType.Blank,
				})
			),
			concatMap((x) => this.service.updateTemplate(x)),
			finalize(() => {
				const list = this.TemplateList.getRawValue();
				const index = list?.findIndex(
					(x) => +x?.settingsId === +data?.settingsId
				);
				this.toggleLoading(index, false);
			}),
			take(1)
		);

	ngOnDestroy(): void {
		super.dispose();
	}
}
