import { CrtMortgageQuery } from './../../state/crt-mortgage.query';
import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of, Observable, combineLatest } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { objectUtil } from 'src/app/util/util';
import {
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	MortgageAdviceProcessPageIds,
} from './../../../../../../shared/models/advice-process/advice-process.model';
import { Application } from './application.model';
import { ApplicationQuery } from './application.query';
import { ApplicationStore } from './application.store';
import { Sidebar, SidebarStatus } from '../../../_shared/models/sidebar.model';
import { CrtMortgageService } from '../../state/crt-mortgage.service';

@Injectable({
	providedIn: 'root',
})
export class ApplicationService {
	applications$ = this.query.selectAll();
	isLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		private store: ApplicationStore,
		private query: ApplicationQuery,
		private crtMortgageQuery: CrtMortgageQuery,
		private crtMortgageService: CrtMortgageService
	) {}

	application = (id: number) => this.query.getEntity(id);

	/**
	 * Get all applications
	 */
	get(adviceProcessId: number) {
		this.store.setLoading(true);
		return this.api
			.get<Application[]>(
				`crt/${adviceProcessId}/${AdviceProcessSectionCodes.Application}`
			)
			.pipe(
				map((x) => x?.map(objectUtil.mapPascalCaseToCamelCase)),
				tap((x) => {
					const sort = x?.sort((a, b) => (+a.cRTId < +b.cRTId) ? 1 : -1)
					applyTransaction(() => {
						this.store.set(sort);
					})
				}),
				finalize(() => this.store.setLoading(false)),
				catchError(this.catchError)
			);
	}

	/**
	 * Get appliction by CRTID
	 * @param id cRTId: number
	 */
	getById(id: number) {
		return this.api.get<Application>(`crt/${id}`).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x) as Application),
			catchError(this.catchError)
		);
	}

	/**
	 * Add applications
	 */
	add(app: Application) {
		const applicationNameNumbers = this.query.getAll().map((a) => {
			// get file name excluding the first part Application string
			const getName =
				a?.name?.split('-')?.length > 1 ? a?.name?.split('-')[1] : '0';
			// get number from file name
			const getNumber = getName?.match(/\d+/);
			return parseInt(getNumber?.length > 0 ? getNumber[0] : '0', 0);
		});

		// get the highest number in array
		const lastCreatedApplicationNumber = applicationNameNumbers?.length > 0 ? Math.max(...applicationNameNumbers) : 0;

		const clientName = `${
			this.crtMortgageQuery.getValue().primaryClient?.firstName
		} ${this.crtMortgageQuery.getValue().primaryClient?.lastName}`;
		const defaultName = `${clientName} Bank Application - `;
		const name = defaultName?.concat(`${lastCreatedApplicationNumber + 1}`);

		const body = {
			...app,
			name,
			cRTId: null,
			bank: '', // leave this blank
			lastEdited: '', // leave this blank
			sectionCode: AdviceProcessSectionCodes.Application,
		} as Application;

		return this.api
			.post<number>(`crt`, objectUtil.mapCamelCaseToPascalCase(body))
			.pipe(
				switchMap((x) => this.getById(x)),
				tap((x) => applyTransaction(() => this.store.add(x))),
				catchError(this.catchError)
			);
	}

	/**
	 * Update applications
	 */
	update(app: Application) {
		return this.api
			.put<number | Application>(
				`crt/${app?.cRTId}`,
				objectUtil.mapCamelCaseToPascalCase(app)
			)
			.pipe(
				switchMap((x) => this.getById(app.cRTId)),
				tap((x) => applyTransaction(() => this.store.upsert(app.cRTId, x))),
				catchError(this.catchError)
			);
	}

	/**
	 * Delete applications
	 */
	delete(app: Application | number) {
		this.store.setLoading(true);
		const cRTId = typeof app === 'number' ? app : app?.cRTId;
		return this.api.delete<string>(`crt/${cRTId}`).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.remove(cRTId);
				})
			),
			finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	catchError = (x) => of(undefined);

	setCurrentPage(cRTId: number, currentPage: string) {
		return of({ cRTId, currentPage }).pipe(
			map((x) => ({
				...this.query.getEntity(+x.cRTId),
				currentPage: x.currentPage,
			} as Application)),
			tap((data) =>
				applyTransaction(() => {
					this.store.update(cRTId, data);
				})
			),
		);
	}

	getEntity(cRTId: number) {
		return this.query.getEntity(cRTId);
	}

	selectEntity(cRTId: number) {
		return this.query.selectEntity(cRTId);
	}

	duplicateApplication(app: Application) {
		this.store.setLoading(true);
		return this.api.post3<string>(`crt/${app.cRTId}/duplicate`).pipe(
			finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

  prepareServiceCalcDownload(req: any): Observable<any> {
    const endpoint = 'documents/download/servicing-calculator';
    return this.api.post(endpoint, objectUtil.mapCamelCaseToPascalCase(req));
  }

  downloadServiceCalculator(req: any): Observable<any> {
    const endpoint = 'documents/download/servicing-calculator';
    return this.api.post(endpoint, req, {
      responseType: 'blob'
    })
  }

  // postFileDownload: (endpoint: string, body?: any) => Observable<Blob> = (endpoint, body) =>
  //   this.http.post(util.toUrl(apiUrl, endpoint), body, {
  //     responseType: 'blob',
  //   });

	setAsActive(id: number) {
		this.store.setActive(id);
	}

	setIsUpdatedLoans(data: boolean) {
		this.store.update({
			isUpdatedLoans: data
		});
	}

	setTabColor() {
		return combineLatest([
			this.crtMortgageQuery.adviceProcess$,
			this.applications$,
			this.crtMortgageQuery.mortApPageStarted$,
			this.crtMortgageQuery.mortApPageCompleted$,
		]).pipe(
			tap(([ap, applications, pageStarted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				// if (pageStarted?.includes(AdviceProcessPageCodes.Application)) {
				// 	status = SidebarStatus.Incomplete;
				// 	warning = 'At least one application should be saved';
				// }

				// if (applications?.length > 0) {
				// 	status = SidebarStatus.Completed;
				// 	warning = null;
				// }

				this.crtMortgageService.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.Application,
					false,
					status,
					warning
				);
			})
		);
	}

	disableApplication(sidebar: Sidebar[]): boolean {
		const appIndex =
			sidebar?.findIndex(
				(x) => x?.id === MortgageAdviceProcessPageIds.Application
			) || 0;

		if (appIndex <= 0) {
			return false;
		}

		const disableApplication = [...sidebar]
			?.splice(0, appIndex)
			?.map((x) => x?.status === 2)
			?.includes(false);

		return disableApplication;
	}
}
