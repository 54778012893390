import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ServiceListService } from './service-list.service';
import { ReplaySubject, Observable } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { combineLatest, map, take } from 'rxjs/operators';

@Component({
	selector: 'app-services-list',
	templateUrl: './services-list.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ServicesListComponent,
			multi: true,
		},
		ServiceListService,
	],
})
export class ServicesListComponent implements OnInit, ControlValueAccessor {
	_choices$: ReplaySubject<ViewDisplayValue[]>;
	_choices: ViewDisplayValue[];
	@Input()
	set choices(value: ViewDisplayValue[]) {
		this._choices$.next(value);
		this._choices = value;
	}
	get choices() {
		return this._choices;
	}
	@Input() hideTableHeader = false;
	@Output() selectEvent = new EventEmitter<{
		code: string;
		checked: boolean;
	}>();
	choices$: Observable<ViewDisplayValue[]>;
	model$: Observable<Checked<ViewDisplayValue>[]>;

	isAllChecked$: Observable<boolean>;
	disabled: boolean;

	onChange: (value: string[]) => void = () => {};
	onTouch = () => {};
	constructor(public serviceListService: ServiceListService) {
		this._choices$ = new ReplaySubject(1);
		this.choices$ = this._choices$.asObservable();

		this.model$ = this.serviceListService.model$.pipe(
			combineLatest(this.choices$),
			map(([model, services]) => {
				return services?.map(
					(y) => new Checked<ViewDisplayValue>(y, model?.includes(y.value))
				);
			})
		);
		this.isAllChecked$ = this.model$.pipe(
			map((x) => x?.every((y) => y.checked))
		);
		this.disabled = false;
	}

	writeValue(value: string[]): void {
		this.serviceListService.set(value);
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnInit() {
		this.serviceListService.model$.subscribe((x) => {
			this.onChange(x);
		});
	}

	check(code: string, checked: boolean, service: any): void {
		if (code === 'LR' && !checked) {
			this.toggleSome(['CAR'], false);
		}
		this.selectEvent.emit({ code, checked });
		this.onTouch();
		checked
			? this.serviceListService.add(code)
			: this.serviceListService.remove(code);
	}

	checkAll(checked: boolean): void {
		checked
			? this.choices$
					.pipe(take(1))
					.subscribe((x) => this.serviceListService.set(x?.map((y) => y.value)))
			: this.serviceListService.set([]);
	}

	toggleSome(codes: string[], checked: boolean): void {
		codes.forEach((code) => {
			if (checked) {
				return this.serviceListService.add(code);
			}
			this.serviceListService.remove(code)
		})
	}

	isDisabled(code: string): boolean {
		if (code === 'CAR') {
			return !this.serviceListService.has('LR');
		}
		return false;
	}

	private toggleChoices(codes: string[], checked: boolean): void {
		const updatedValues = this.choices.map((choice) => {
			if (codes.includes(choice.value)) {
				choice.isActive = checked;
			}
			return choice;
		});
		this._choices$.next(updatedValues);
	}
}

class Checked<T> {
	checked: boolean;
	item: T;
	constructor(item: T, checked: boolean = false) {
		this.item = item;
		this.checked = checked;
	}
}
