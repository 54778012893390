import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
    ConfirmationCallQuestionSetting
} from './confirmation-call-question.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ConfirmationCallQuestion', idKey: 'qId' })
export class ConfirmationCallQuestionStore extends Store<ConfirmationCallQuestionSetting> {
	constructor() {
		super({});
	}
}
