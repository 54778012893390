import * as R from 'ramda';
import { lrProvidersSettings } from './lr-providers.template';

export const lProvidersMetaKey = {
	sectionName: 'SOA_LR_PROVIDERS_SECTION',
	providersName: 'SOA_LR_PROVIDERS_NAME',
	maxUpfront: 'SOA_LR_PROVIDERS_MAX_UPFRONT',
	maxRenewal: 'SOA_LR_PROVIDERS_MAX_RENEWAL',
	businessFapName: 'BUSINESS_FAP_NAME',
};

export const lrProvidersMergeTags = [
	{
		metaKey: lProvidersMetaKey.sectionName,
		description: 'SOA > L&R Providers Section',
		value: [''],
		type: 'T',
		secondaryValue: lrProvidersSettings,
		noWrap: true,
	},
];

export const lrProvidersMt = [
	{
		metaKey: lProvidersMetaKey.providersName,
		description: 'SOA > L&R Providers Section > Provider',
		value: 'provider',
		type: 'T',
		secondaryValue: 'ANZ',
		noWrap: true,
	},
	{
		metaKey: lProvidersMetaKey.maxUpfront,
		description: 'SOA > L&R Providers Section > Provider Max Upfront',
		value: 'maxUpfront',
		type: 'T',
		secondaryValue: '2%',
		noWrap: true,
	},
	{
		metaKey: lProvidersMetaKey.maxRenewal,
		description: 'SOA > L&R Providers Section > Provider Max Renewal',
		value: 'maxRenewal',
		type: 'T',
		secondaryValue: '2%',
		noWrap: true,
	},
];

export const lrPoviderMtList = () => {
	const lrProviderSection = R.pluck('metaKey', lrProvidersMergeTags);
	const lrProviderMt = R.pluck('metaKey', lrProvidersMt);
	return [...lrProviderSection, ...lrProviderMt];
};
