import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	CrtMortgageState,
	CrtMortgageStore,
} from '../../state/crt-mortgage.store';

@Injectable()
export class DisclosureQuery extends Query<CrtMortgageState> {
	constructor(protected store: CrtMortgageStore) {
		super(store);
	}

	/**
	 * @returns Observable object of Disclosure Document CRT
	 */
	disclosureDocument$ = this.select((state) => state.disclosureDocument);

	/**
	 * @returns Observable object of Disclosure Settings
	 */
	disclosureSettings$ = this.select((state) => state.disclosureSettings);
}
