<div class="property-sold">
  <div class="property-sold-header font-weight-bold mb-3">
		<span [class.highlight-label-invalid]="properties?.length === 0"
			>Property to be sold</span
		>
	</div>
  <ng-container *ngIf="(isLoading$ | async); else showForm">
		<div class="pb-3">
			<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
		</div>
  </ng-container>
  <ng-template #showForm>
    <app-property-sold-form
      #propertySoldForm
      [SU$]="SU$"
      [SV1$]="SV1$"
      [ST$]="ST$"
      [STI$]="STI$"
      [APCRTF$]="APCRTF$"
      [propertyOwners]="propertyOwners$ | async"
      [adviceProcessId]="adviceProcessId"
      [properties]="properties"
      [propertyAddresses]="propertyAddresses$ | async"
      [addFn$]="add"
      [updateFn$]="update"
      [deleteFn$]="delete"
			[getOwnerChoices]="getOwnerChoices"
    >
    </app-property-sold-form>
  </ng-template>
</div>
