<div class="security" [class.submitted]="submitted">
	<!-- <div class="security-header mb-3"></div> -->
	<app-manual-loader
		*ngIf="isLoading$ | async as isLoading"
		[isLoading]="isLoading"
	></app-manual-loader>
	<form [formGroup]="form" class="form" class="mb-3">
		<div class="form-row pl-1">
			<div class="col-12 font-weight-bold mb-2">
				What security will {{ application?.bank || "bank" }} take?
			</div>
			<app-manual-loader-mini
				*ngIf="isLoading"
				[isLoading]="isLoading"
			></app-manual-loader-mini>
			<ng-container *ngIf="!isLoading">
				<div formArrayName="bankWillTake" class="col-12">
					<div
						class="form-row mb-2"
						*ngFor="
							let b of BankWillTake?.controls;
							let i = index;
							let last = last
						"
						[formGroupName]="i"
					>
						<div class="col-lg">
							<div class="custom-control custom-checkbox">
								<input
									type="checkbox"
									id="property{{ b?.get('aLPropertiesCrtId')?.value }}"
									class="checkbox custom-control-input"
									formControlName="isTick"
									(change)="this.recomputeTotals()"
								/>
								<label
									class="custom-control-label"
									for="property{{ b?.get('aLPropertiesCrtId')?.value }}"
									[class.invalid-cbox]="this.isNoSecurity"
								>
									{{ b?.get("propertyAddress")?.value }}
								</label>
							</div>
						</div>
						<div class="col-lg-3 text-right">
							{{ b?.get("amount")?.value | currency }}
						</div>
					</div>
				</div>
			</ng-container>
		</div>
		<hr />
		<div class="form-row mb-2 text-right">
			<div
				class="
					col-lg-4
					offset-lg-6
					col-md-4
					offset-md-6
					tap-text-primary
					font-weight-bold
					text-truncate
				"
			>
				Total Security Value
			</div>
			<div class="col-lg-2 col-md-2 text-truncate">
				{{ TotalSecurityValue.value | currency }}
			</div>
		</div>
		<div class="form-row mb-2 text-right">
			<div
				class="
					col-lg-4
					offset-lg-6
					col-md-4
					offset-md-6
					tap-text-primary
					font-weight-bold
					text-truncate
				"
			>
				Proposed Total Debt
			</div>
			<div class="col-lg-2 col-md-2 text-truncate">
				{{ ProposedTotalDebt.value | currency }}
			</div>
		</div>
		<div class="form-row mb-2 text-right">
			<div
				class="
					col-lg-4
					offset-lg-6
					col-md-4
					offset-md-6
					tap-text-primary
					font-weight-bold
					text-truncate
				"
			>
				Proposed LVR
			</div>
			<div class="col-lg-2 col-md-2 text-truncate">
				{{ ProposedLVR.value | number }}%
			</div>
		</div>
		<div class="form-row mb-2 text-right">
			<div
				class="
					col-lg-4
					offset-lg-6
					col-md-4
					offset-md-6
					tap-text-primary
					font-weight-bold
					text-truncate
				"
			>
				Scaled LVR
			</div>
			<div class="col-lg-2 col-md-2 text-truncate">
				{{ ScaledLVR.value | number }}%
			</div>
		</div>
	</form>
</div>
