import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { StructureSoa } from './structure-soa.model';
import { StructureSoaEmailSettings } from './structure-soa.model';

export interface StructureSoaState extends EntityState<StructureSoa> {
}


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'structure-soa', idKey: 'cRTId' })
export class StructureSoaStore extends EntityStore<StructureSoaState> {

  constructor() {
    super();
  }

  setEmailSettings(settings: StructureSoaEmailSettings): void {
    this.update({ emailSettings: settings });
  }

  updateCurrentPage(crtId, currentPage) {
    this.update(crtId, { currentPage });
  }
}
