import {
	Component,
	OnDestroy,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	ElementRef,
	ViewChild,
} from '@angular/core';
import { FieldMetadata } from '../../dynamic-field/field-metadata.model';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { util } from '../../../util/util';
import { MultiSelectModalComponent } from '../../modal/multiselect-modal/multiselect-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { concatMap, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommandRoute } from '../../../core/config/route.service';
import { NgZone, ChangeDetectorRef } from '@angular/core';
declare var $: any;

@Component({
	selector: 'app-multiselect',
	templateUrl: './multiselect.component.html',
	styleUrls: ['./multiselect.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectComponent implements OnChanges, OnDestroy {
	/** element to attach the tooltip */
	@ViewChild('longText', { static: true }) longText: ElementRef;
	/** route to go to */
	@Input() route: CommandRoute;
	/** value of dropdown */
	@Input() value: string;
	/** index number for use in ID */
	@Input() index: any;
	/** field name for use in ID */
	@Input() fieldId: any;
	/** restrictions */
	@Input() restrict: string[];
	/** if currently saving */
	@Input() isLoading: boolean;
	/** if currently in edit mode */
	@Input() isEditing: boolean;
	/** to use on save event for now. maybe get rid later */
	@Input() metadata: FieldMetadata<any>;
	/** input row data from table */
	@Input() row: any;
	/** input metakey from table */
	@Input() metakey: string;

	/** choices for dropdown */
	@Input() choices: ViewDisplayValue[];
	/** choices as object for faster lookup.
	 * object key is `viewdisplayvalues`'s `value`.
	 * Should be computed by the component that uses
	 * this component so that computation does not
	 * repeat every instance of dropdown.
	 */
	@Input() choicesObject: { [key: string]: string };
	/** input whether field is required */
	@Input() isRequired: boolean;
	/** input whether field is editable */
	@Input() isEditable: boolean;
	/** input function for validation */
	@Input() validatorFn$: (row?, metakey?, value?) => Observable<any>;
	/** String name to show which column field is being edited in modal */
	@Input() fieldName: string;
	/** event for saving */
	@Output() saveEvent = new EventEmitter<FieldMetadata<any>>();
	/** Display text */
	display: string;

	constructor(
		private modalService: BsModalService,
		private ngZone: NgZone,
		private cd: ChangeDetectorRef
	) {}

	/** Update display and tooltip */
	ngOnChanges(changes: SimpleChanges): void {
		this.valueChanges(changes);
	}

	/** remove tooltip */
	ngOnDestroy(): void {
		this.removeTooltip();
	}

	/** Check if valid multiselect value.
	 * if so map to names from adviser lookup and assign to `display`.
	 *
	 * Put a switch whether values from value can be used as is.
	 * This will aid performance by not doing anymore computation.
	 *
	 * check performance. If it is negatively affected, try a way with lookup in template.
	 */
	valueChanges(changes: SimpleChanges) {
		const notChanged =
			changes.value === undefined ||
			changes.value.currentValue === changes.value.previousValue;
		if (notChanged) {
			return;
		}

		const checkifEmpty = (val) => {
			const a = JSON.parse(val);
			return a && a.length > 0 ? a : null;
		};

		const currValue = changes.value.currentValue;
		const multiselectValue: string[] =
			currValue && typeof currValue === 'string'
				? checkifEmpty(currValue)
				: Array.isArray(currValue)
				? currValue
				: null;
		const validMultiselect = Array.isArray(multiselectValue);
		if (!validMultiselect) {
			this.display = '';
		} else {
			this.display = multiselectValue
				?.map((x) => this.choicesObject[x])
				?.filter((x) => x !== undefined)
				?.join(', ');
		}
		this.removeTooltip();
		this.addTooltip(this.display);
	}

	editMultiSelectModal() {
		const initState: Partial<MultiSelectModalComponent> = {
			header: !util.isNullOrEmpty(this.fieldName)
				? `Update ${this.fieldName}`
				: `Update record`,
			valuesJson: this.value,
			savefn: this.saveMultiSelect,
			choices: this.choices,
			isRequired: this.isRequired,
		};
		this.modalService.show(MultiSelectModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveMultiSelect = (value: string[]) => {
		let hasError = null;
		return of(value).pipe(
			map((v) => (v ? JSON.stringify(v) : null)),
			concatMap((parsedValue) => {
				if (!!this.validatorFn$) {
					return this.validatorFn$(this.row, this.metakey, parsedValue).pipe(
						map((error) => {
							hasError = error;
							if (error) {
								throw new Error(error);
							} else {
								return parsedValue;
							}
						})
					);
				} else {
					return of(parsedValue);
				}
			}),
			tap((result) => {
				if (!hasError) {
					this.saveEvent.emit({
						...this.metadata,
						value: result,
					});
				}
			})
		);
	};

	/** add tooltip. */
	private addTooltip(title: string) {
		if (!this.longText) {
			return;
		}
		$(this.longText.nativeElement).tooltip({
			placement: 'right',
			title: () => title,
		});
	}
	/** remove tooltip */
	private removeTooltip() {
		if (!this.longText) {
			return;
		}
		$(this.longText.nativeElement).tooltip('dispose');
	}

	/**
	 * routeLink click, gets stuck when using ngx-datatable.
	 * Somehow solves it.
	 */
	onClick() {
		this.ngZone.run(() => this.cd.detectChanges());
	}
}
