import {
	contentEditable,
	getContentWithMergeTags,
} from '../../../../../converter/content-merge-tags';
import { util } from '../../../../../../util/util';
import {
	moatSosMergeTags,
	sosMergeTags,
	sosMetaKey,
	sosOtherAdvice,
	sosOtherAdviceList,
	sosServices,
	sosServicesList,
} from './sos.merge-tag';
import { sosTemplate } from './sos.template';
import { MergeTagsMapper } from '../../merge-tags.mapper';
import { MergeTagState } from '../../merge-tags.model';
import { ScopeOfServiceState } from 'src/app/modules/crm/crt-page/crt-mortgage/scope-of-services/state/scope-of-service.model';

export class SosMTMapper {
	public static cleanTemplate(template) {
		// Remove \n and \t from content string
		return template?.replace(/\n|\t/g, '') || '';
	}

	public static getTemplate(template, mergeTag) {
		// Get template for each insurance merge tag
		const content = this.cleanTemplate(template);
		return getContentWithMergeTags(content, mergeTag) ?? '';
	}

	public static getTextAreaContent(data, mt) {
		// Parse content from textareas
		const content = util.tryCatchParse(data) ? JSON.parse(data) : data;
		return getContentWithMergeTags(content, mt, true) ?? '';
	}

	public static parseValues(data) {
		// Parse merge tag values
		return (
			data?.reduce((a, c) => {
				const newVal = util.tryCatchParse(c) ? JSON.parse(c) : c;
				return [...a, newVal];
			}, []) || []
		);
	}

	//////////////////////////
	//// MOAT > ROA > ROA ////
	//////////////////////////

	/**
	 * Get Dynamic SOS Merge Tags for ROA
	 * @param mergeTags : MergeTagState[]
	 * @returns : Updated list of merge tags with updated SOS
	 */
	public static updateSosMergeTags(mergeTags: MergeTagState[] = []) {
		return (
			[...mergeTags, ...sosMergeTags]?.map((i) => {
				if (i?.metaKey === sosMetaKey.introText) {
					return {
						...i,
						value: this.getIntro(mergeTags),
						noWrap: true,
					};
				}
				if (i?.metaKey === sosMetaKey.notesText) {
					return {
						...i,
						value: this.getSosNotes(i),
						noWrap: true,
					};
				}
				if (i?.metaKey === sosMetaKey.otherAdviceNotes) {
					return {
						...i,
						value: this.getOtherNotes(i?.value),
					};
				}
				return i;
			}) || []
		);
	}

	public static getIntro(mergeTags: MergeTagState[] = []) {
		const intro = this.getTemplate(sosTemplate.sosIntro, mergeTags);
		const value = this.getTemplate(intro, mergeTags) || '';
		return MergeTagsMapper.wrapMTValueInDiv(sosMetaKey.introText, value);
	}

	public static getSosNotes(mergeTag: MergeTagState) {
		const value = `${MergeTagsMapper.updateNewLine(
			mergeTag?.value || ''
		)} <br /><br />`;
		return MergeTagsMapper.wrapMTValueInDiv(mergeTag?.metaKey, value);
	}

	public static getOtherNotes(value: string[]) {
		return value?.map((x) => `<span ${contentEditable.false}>${x}</span>`);
	}

	public static resetMergeTags(content = '') {
		let newContent = content;
		// Intro text
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.introText
		);
		// SOS Other note
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.notesText
		);
		return newContent;
	}

	//////////////////////////
	////    MOAT > SOS    ////
	//////////////////////////

	/**
	 * Get Dynamic SOS Merge Tags for MOAT > SOS
	 * @param mergeTags <MergeTagState[]>
	 * @param sosData <ScopeOfServiceState>: CRT Data of SOS
	 * @param settings <SosTemplateSettingsState>: SOS Settings
	 * @returns : Updated list of merge tags with updated SOS
	 */
	public static moatSosMergeTags(
		mergeTags: MergeTagState[] = [],
		sosData: ScopeOfServiceState,
		settings: ScopeOfServiceState
	) {
		return [...mergeTags, ...moatSosMergeTags]?.map((i) => {
			if (i?.metaKey === sosMetaKey.sosServicesCheckbox) {
				return {
					...i,
					value: this.getServicesCheckboxes(sosData, settings),
				};
			}
			if (i?.metaKey === sosMetaKey.sosOtherAdviceCheckbox) {
				return {
					...i,
					value: this.getOtherAdviceCheckboxes(sosData, settings),
				};
			}
			if (
				i?.metaKey === sosMetaKey.sosClientGoalsText ||
				i?.metaKey === sosMetaKey.sosIntro
			) {
				return {
					...i,
					value: i?.value?.map((x) => x?.replace(/(?:\r\n|\r|\n)/gm, '<br />')),
				};
			}
			return i;
		});
	}

	private static isCheckedIcon = `<svg width="20" height="20" class="mx-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="none">&nbsp;
				<circle cx="30" cy="30" r="27.5" stroke="#6DBE45" stroke-width="5"></circle>
				<path d="M18.5 30L27 38.5L43.5 22" stroke="#6DBE45" stroke-width="5"></path>&nbsp;</svg>`;
	private static notCheckedIcon = `<svg width="20" height="20" class="mx-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="none">&nbsp;
				<path d="M20 20L41 41" stroke="#FF0000" stroke-width="5"></path>
				<path d="M20 41L41 20" stroke="#FF0000" stroke-width="5"></path>
				<circle cx="30" cy="30" r="27.5" stroke="#FF0000" stroke-width="5"></circle>&nbsp;</svg>`;

	/**
	 * @param sosData <ScopeOfServiceState>: CRT Data of SOS
	 * @param settings <SosTemplateSettingsState>: SOS Settings
	 * @returns <string> HTML content based on selected services
	 */
	public static getServicesCheckboxes(
		sosData: ScopeOfServiceState,
		settings: ScopeOfServiceState
	) {
		const createCbox = (
			id: string,
			labelText: string,
			isChecked: boolean,
			note?: string
		) => {
			const otherNotes =
				isChecked && !!note
					? note?.replace(/(?:\r\n|\r|\n)/gm, '<br />') : '';
			return `
			<li>
				<div class="icon">${isChecked ? SosMTMapper.isCheckedIcon : SosMTMapper.notCheckedIcon}</div>
				<div class="service">
					<p>${labelText}</p>
					<span class="sos-cbox-notes">${otherNotes}</span>
				</div>
			</li>
			`;
		};

		const html = sosServicesList?.reduce((a, c) => {
			if (c === sosServices.newPurchase) {
				const isChecked = sosData?.newPurchase === 1;
				const cBoxValue = createCbox(
					'newPurchase',
					sosServices.newPurchase,
					isChecked
				);
				return +settings?.newPurchase === 1 ? `${a} ${cBoxValue}` : a;
			}
			if (c === sosServices.lendingTopup) {
				const isChecked = sosData?.lendingTopup === 1;
				const cBoxValue = createCbox(
					'lendingTopup',
					sosServices.lendingTopup,
					isChecked
				);
				return +settings?.lendingTopup === 1 ? `${a} ${cBoxValue}` : a;
			}
			if (c === sosServices.businessLending) {
				const isChecked = sosData?.businessLending === 1;
				const cBoxValue = createCbox(
					'businessLending',
					sosServices.businessLending,
					isChecked
				);
				return +settings?.businessLending === 1 ? `${a} ${cBoxValue}` : a;
			}
			if (c === sosServices.refinance) {
				const isChecked = sosData?.refinance === 1;
				const cBoxValue = createCbox(
					'refinance',
					sosServices.refinance,
					isChecked
				);
				return +settings?.refinance === 1 ? `${a} ${cBoxValue}` : a;
			}
			if (c === sosServices.generalStructureReview) {
				const isChecked = sosData?.generalStructureReview === 1;
				const cBoxValue = createCbox(
					'generalStructureReview',
					sosServices.generalStructureReview,
					isChecked
				);
				return +settings?.generalStructureReview === 1
					? `${a} ${cBoxValue}`
					: a;
			}
			if (c === sosServices.otherService) {
				const isChecked = sosData?.otherService === 1;
				const cBoxValue = createCbox(
					'otherService',
					sosServices.otherService,
					isChecked,
					sosData.otherServiceNotes
				);
				return +settings?.otherService === 1 ? `${a} ${cBoxValue}` : a;
			}
		}, '');
		return `<ul class="sos-cbox">${html}</ul>`;
	}

	/**
	 * @param sosData <ScopeOfServiceState>: CRT Data of SOS
	 * @param settings <SosTemplateSettingsState>: SOS Settings
	 * @returns <string> HTML content based on selected services
	 */
	public static getOtherAdviceCheckboxes(
		sosData: ScopeOfServiceState,
		settings: ScopeOfServiceState
	) {
		const createCboxNote = (
			id: string,
			labelText: string,
			isChecked: boolean,
			note?: string
		) => {
			return `
				<li>
					<div class="icon">${isChecked ? SosMTMapper.isCheckedIcon : SosMTMapper.notCheckedIcon}</div>
					<div class="service">
						<p>${labelText}</p>
						<span class="sos-other-advice-notes">${isChecked ? note?.replace(/(?:\r\n|\r|\n)/gm, '<br />') : ''}</span>
					</div>
				</li>
			`
		};

		const html = sosOtherAdviceList?.reduce((a, c) => {
			if (c === sosOtherAdvice.kiwiSaverAdvice) {
				const isChecked = sosData?.kiwiSaverAdvice === 1;
				const cBoxValue = createCboxNote(
					'kiwiSaverAdvice',
					sosOtherAdvice.kiwiSaverAdvice,
					isChecked,
					sosData?.kiwiSaverAdviceDetails
				);
				return +settings?.kiwiSaverAdvice === 1 ? `${a} ${cBoxValue}` : a;
			}
			if (c === sosOtherAdvice.lifeAndRiskReview) {
				const isChecked = sosData?.lifeAndRiskReview === 1;
				const cBoxValue = createCboxNote(
					'lifeAndRiskReview',
					sosOtherAdvice.lifeAndRiskReview,
					isChecked,
					sosData?.lifeAndRiskReviewDetails
				);
				return +settings?.lifeAndRiskReview === 1 ? `${a} ${cBoxValue}` : a;
			}
			if (c === sosOtherAdvice.houseCarContentsQuote) {
				const isChecked = sosData?.houseCarContentsQuote === 1;
				const cBoxValue = createCboxNote(
					'houseCarContentsQuote',
					sosOtherAdvice.houseCarContentsQuote,
					isChecked,
					sosData?.houseCarContentsQuoteDetails
				);
				return +settings?.houseCarContentsQuote === 1 ? `${a} ${cBoxValue}` : a;
			}
			if (c === sosOtherAdvice.otherAdvice) {
				const isChecked = sosData?.otherAdvice === 1;
				const cBoxValue = createCboxNote(
					'otherAdvice',
					sosOtherAdvice.otherAdvice,
					isChecked,
					sosData?.otherAdviceNotes
				);
				return +settings?.otherAdvice === 1 ? `${a} ${cBoxValue}` : a;
			}
		}, '');
		return `<ul class="sos-cbox">${html}</ul>`;
	}

	public static updateSosMergeTag(mergeTags: MergeTagState[]) {
		const otherValue =
			mergeTags?.find((x) => x?.metaKey === sosMetaKey.sosOtherServiceNote)
				?.value?.[0] || '';
		const otherClass = 'sos-other-description';

		return (mergeTags || [])?.map((x) => {
			if (x?.metaKey === sosMetaKey.sosServicesIncluded) {
				const data = x?.value?.map((i) => {
					return i === 'Other'
						? `${i}<br><span class="${otherClass}">${otherValue}</span>`
						: i;
				});
				return {
					...x,
					value: data,
				};
			}
			return x;
		});
	}
}
