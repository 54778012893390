import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientSearchPageComponent } from './client-search-page/client-search-page.component';
import { ClientSearchResolver } from './client-search.resolver';

const routes: Routes = [
  {
    path: '',
    component: ClientSearchPageComponent,
    resolve: {
      data: ClientSearchResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientSearchRoutingModule { }
