import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTablePaginatorComponent } from './mat-table-paginator/mat-table-paginator.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MatTableModule } from '@angular/material/table';

@NgModule({
	declarations: [MatTablePaginatorComponent],
	exports: [MatTablePaginatorComponent],
	imports: [CommonModule, PaginationModule, MatTableModule],
})
export class CRMMatTableModule {}
