import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, concat, from } from 'rxjs';
import { LrInsuranceQuery } from './lr-insurance.query';
import { LrInsuranceStore } from './lr-insurance.store';
import {
	LrInsuranceResponse,
	LrInsuranceRequest,
} from '../lr-insurance-request.model';
import {
	ApiService,
	JsonResultStatus,
} from '../../../../../core/base/api.service';
import {
	mergeMap,
	map,
	withLatestFrom,
	tap,
	finalize,
	take,
	filter,
	shareReplay,
	concatMap,
	reduce,
	switchMap,
	catchError,
	retry,
} from 'rxjs/operators';
import { produce } from 'immer';
import {
	createRowFromPrototype,
	columns,
	Metakey,
} from '../lr-insurance-datatable.config';
import { applyTransaction } from '@datorama/akita';
import * as R from 'ramda';
import { UserQuery } from '../../../../../domain/user/user.query';
import { UserStore } from '../../../../../domain/user/user.store';
import { ConfigService } from '../../../../../core/config/config.service';
import { ActivityViewModel } from '../../../../../shared/models/_general/activity.viewmodel';
import MomentUtil from '../../../../../util/moment.util';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Injectable()
export class LrInsuranceService {
	constructor(
		protected api: ApiService,
		private lrInsuranceStore: LrInsuranceStore,
		private lrInsuranceQuery: LrInsuranceQuery,
		private userQuery: UserQuery,
		private userStore: UserStore,
		protected configService: ConfigService,
		private activityService: ActivityService,
		private loggerService: LoggerService
	) {}

	clear(): void {
		applyTransaction(() => {
			this.lrInsuranceStore.reset();
			this.lrInsuranceStore.uiStore.reset();
		});
	}

	openPopup = () => this.lrInsuranceStore.uiStore.toggleColumnPopup(true);
	closePopup = () => this.lrInsuranceStore.uiStore.toggleColumnPopup(false);
	togglePopup = () =>
		this.lrInsuranceStore.uiStore.toggleColumnPopup(
			!this.lrInsuranceQuery.uiQuery.getValue().columnFormPopupOpen
		);

	search2(req: LrInsuranceRequest) {
		this.lrInsuranceStore.uiStore.setSort(null, null);
		const batchLength = 100;

		const getIndexesToFetch: (res: LrInsuranceResponse) => number[] = R.pipe(
			(res: LrInsuranceResponse) => Math.ceil(res.TotalCount / batchLength - 1),
			(totalPages: number) =>
				Array(totalPages)
					.fill(1)
					.map((x, i) => i + 1)
					.slice(1)
		);
		const searchRequest = (request: LrInsuranceRequest) =>
			this.api.post3<LrInsuranceResponse>('search/services/lr', request);

		const firstPage$ = of(req).pipe(
			map(
				produce((draft) => {
					draft.Paging = {
						Index: 2,
						Column: req.Paging.Column,
						Direction: req.Paging.Direction,
					};
				})
			),
			mergeMap(searchRequest),
			shareReplay()
		);

		return firstPage$.pipe(
			mergeMap((res) =>
				concat(
					firstPage$,
					from(getIndexesToFetch(res)).pipe(
						map((i) =>
							produce(req, (draft) => {
								draft.Paging = {
									Index: i + 1,
									Column: req.Paging.Column,
									Direction: req.Paging.Direction,
								};
							})
						),
						concatMap((req2) => searchRequest(req2))
					)
				)
			),
			reduce((acc, v) =>
				produce(acc, (draft) => {
					draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
					draft.IsComplete = true;
				})
			),
			map((res) =>
				applyTransaction(() => {
					return res;
				})
			)
		);
	}

	search(req: LrInsuranceRequest) {
		this.lrInsuranceStore.uiStore.setIsSearching(true);

		return this.api.post3<LrInsuranceResponse>('search/services/lr', req).pipe(
			withLatestFrom(this.lrInsuranceQuery.templateRow$),
			switchMap(([x, templateRow]) => {
				if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
					// Saves initial fetch
					const rows: any[] = R.map(
						createRowFromPrototype(templateRow),
						x.SearchResults
					);
					this.lrInsuranceStore.set([]);
					this.lrInsuranceStore.set([...rows]);
					return this.search2(req);
				}
				return of(x);
			}),
			withLatestFrom(this.lrInsuranceQuery.templateRow$, (res, template) => ({
				response: res,
				templateRow: template,
			})),
			map((res) =>
				applyTransaction(() => {
					const rows: any[] = R.map(
						createRowFromPrototype(res.templateRow),
						res.response.SearchResults
					);

					if (
						req.Paging.Index === 1 &&
						this.lrInsuranceQuery.getCount() !== 0 &&
						res.response.TotalCount > 500
					) {
						this.lrInsuranceStore.set([]);
						res.response.IsComplete = false;
					}

					const a = this.lrInsuranceQuery.getAll();
					if (
						req.Paging.Index === 1 &&
						res.response.IsComplete &&
						res.response.TotalCount <= 100
					) {
						this.lrInsuranceStore.set([]);
						this.lrInsuranceStore.set([...rows]);
					} else {
						this.lrInsuranceStore.set([...a, ...rows]);
					}

					this.lrInsuranceStore.update((state) => ({
						...state,
						count: res.response.TotalCount,
						totalAPI: res.response.TotalAPI,
						isComplete: res.response.IsComplete,
					}));

					return res.response;
				})
			),
			finalize(() => {
				this.lrInsuranceStore.setSearchForm(req);
				this.lrInsuranceStore.uiStore.setIsSearching(false);
			})
		);
	}

	/**
	 * @param {LrInsuranceRequest} req request
	 * Sets IsExport to true in akita store
	 * @returns {Observable} Blob
	 * Sets IsExport to false in akita store
	 */
	export(req: LrInsuranceRequest): Observable<any> {
		return this.api.post4('export/services/lr', req).pipe(
			map((x) => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			retry(1),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.shared.export.error);
				return throwError(new Error(err));
			})
		);
	}

	tryParseJSON(jsonString: any): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}

	reloadData() {
		const data = this.lrInsuranceQuery.getAll();
		this.lrInsuranceStore.set([]);
		this.lrInsuranceStore.set([...data]);
	}

	getColumns(): Observable<any> {
		return this.lrInsuranceQuery
			.select((x) => x.columns)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				withLatestFrom(
					this.userQuery.staffSettings$,
					this.userQuery.isUserWithOptFields$
				),
				map(([, staffSettings, isUserWithOptionFields]) =>
					!isUserWithOptionFields
						? JSON.parse(staffSettings?.LRSearchColumns) ?? []
						: (
								(JSON.parse(staffSettings?.LRSearchColumns) as string[]) ?? []
						  ).filter((x) => x !== 'Email')
				),
				tap((x) =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.lrInsuranceStore.setColumns(x)
						: null
				)
			);
	}

	getColumnWidths(): Observable<any> {
		return this.lrInsuranceQuery
			.select((x) => x.columnWidths)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				withLatestFrom(
					this.userQuery.staffSettings$,
					this.userQuery.isUserWithOptFields$
				),
				map(([, staffSettings, isUserWithOptionFields]) =>
					!isUserWithOptionFields
						? JSON.parse(staffSettings?.LRSearchColumnsWidth) ?? []
						: (
								(JSON.parse(staffSettings?.LRSearchColumnsWidth) as {
									metakey: string;
									width: number;
								}[]) ?? []
						  ).filter((x) => x?.metakey !== 'Email')
				),
				tap((x) =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.lrInsuranceStore.setColumnWidths(x)
						: null
				)
			);
	}

	reorderColumn = (oldIndex: number, newIndex: number) => {
		const newCol = produce(
			this.lrInsuranceQuery.getValue().columns,
			(draft) => {
				const movedCol = draft?.splice(oldIndex, 1);
				draft?.splice(newIndex, 0, movedCol[0]);
			}
		);
		const newColWith = produce(
			this.lrInsuranceQuery.getValue().columnWidths,
			(draft) => {
				const movedCol = draft?.splice(oldIndex, 1);
				draft?.splice(newIndex, 0, movedCol[0]);
			}
		);
		return of(newCol).pipe(
			tap(() => {
				this.lrInsuranceStore.setColumns(newCol);
				this.lrInsuranceStore.setColumnWidths(newColWith);
			}),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					LRSearchColumns: JSON.stringify(newVal),
					LRSearchColumnsWidth: JSON.stringify(newColWith),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			)
		);
	};

	resizeColumn(prop: string, width: number): Observable<any> {
		const oldColWidths = this.lrInsuranceQuery
			.getValue()
			.columnWidths?.filter((x) => x);
		const newColWidths = produce(oldColWidths, (draft) => {
			const col = columns?.find((x) => x.prop === prop);
			const exists = draft?.some((x) => col.metakey === x.metakey);
			if (exists) {
				draft.find((x) => col.metakey === x.metakey).width = width;
			} else {
				draft.push({ metakey: col.metakey, width });
			}
		});

		return of(newColWidths).pipe(
			tap((x) => this.lrInsuranceStore.setColumnWidths(x)),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					LRSearchColumnsWidth: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)
			)
		);
	}

	saveVisibleColumns = (metakeys: Metakey[]) => {
		const newColumns = metakeys;
		const oldColumns = this.lrInsuranceQuery.getValue().columns;
		if (R.equals(newColumns, oldColumns)) {
			return of();
		}

		this.lrInsuranceStore.uiStore.setIsColumnSaving(true);

		const newColumnMetakeys = newColumns;

		return of(newColumnMetakeys).pipe(
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					LRSearchColumns: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			),
			finalize(() => this.lrInsuranceStore.uiStore.setIsColumnSaving(false)),
			tap(() => this.lrInsuranceStore.setColumns(newColumnMetakeys))
		);
	};

	saveField(req: {
		CustomerID: number;
		CustomerServiceID: number;
		MetaKey: Metakey;
		MetaValue: string;
		CustomerServiceType: string;
	}): Observable<JsonResultStatus> {
		const record = this.lrInsuranceQuery.getEntity(req.CustomerServiceID);
		const fields = R.omit(
			[
				'CustomerID',
				'CustomerServiceID',
				'link',
				'ActivityId',
				'PolicyOwnersList',
				'ClientNextActivityId',
				'UserNextActivityId',
			],
			record
		);
		const isCustomerDetail = Object.values(fields)?.find(
			(x) => x.metakey === req.MetaKey
		).isCustomerDetail;

		this.lrInsuranceStore.uiStore.setLoad(
			req.CustomerServiceID,
			req.MetaKey,
			true
		);
		const apiUrl = isCustomerDetail
			? `contacts/${req.CustomerID}?isPatch=true`
			: `services/${req.CustomerServiceID}?isPatch=true`;
		return this.api
			.put<JsonResultStatus>(
				`${apiUrl}`,
				R.omit(['CustomerServiceID', 'CustomerID'], req)
			)
			.pipe(
				tap(() =>
					applyTransaction(() => {
						const stateReq = {
							...req,
							CustomerServiceId: isCustomerDetail
								? this.lrInsuranceQuery
										.getAll({
											filterBy: (row) => row.CustomerID === req.CustomerID,
										})
										?.map((x) => x.CustomerServiceID)
								: req.CustomerServiceID,
						};
						this.lrInsuranceStore.updateField(stateReq);
						this.lrInsuranceStore.uiStore.setEdit(
							req.CustomerServiceID,
							req.MetaKey,
							false
						);
						this.setTempValue(req.CustomerServiceID, req.MetaKey, undefined);
					})
				),
				finalize(() =>
					this.lrInsuranceStore.uiStore.setLoad(
						req.CustomerServiceID,
						req.MetaKey,
						false
					)
				)
			);
	}

	edit = (customerServiceId: number, metakey: Metakey) =>
		this.lrInsuranceStore.uiStore.setEdit(customerServiceId, metakey, true);
	cancel = (customerServiceId: number, metakey: Metakey) =>
		applyTransaction(() => {
			this.lrInsuranceStore.uiStore.setEdit(customerServiceId, metakey, false);
			this.setTempValue(customerServiceId, metakey, undefined);
		});

	updateExclusions = (customerServiceId: number, value: string) => {
		this.lrInsuranceStore.update(
			customerServiceId,
			produce((draft) => {
				draft.Exclusions.value = value;
			})
		);
	};

	updateNote = (customerServiceID: number, value: string) => {
		this.lrInsuranceStore.update(
			customerServiceID,
			produce((draft) => {
				const nzToday = MomentUtil.createMomentNz().format('DD/MM/YYYY');
				const ln =
					nzToday +
					' - ' +
					this.userQuery.getValue().FirstName +
					' ' +
					this.userQuery.getValue().LastName +
					' - ' +
					value;
				draft.LastNote.value = ln;
			})
		);
	};

	updateClientAndUserNextActivity = (customerId: number) => {
		return forkJoin(
			this.updateClientNextActivity(customerId),
			this.updateUserNextActivity(customerId)
		);
	};

	updateClientNextActivity = (customerId: number) => {
		const customerServiceIds = this.lrInsuranceQuery
			.getAll({ filterBy: (row) => row.CustomerID === customerId })
			?.map((z) => z.CustomerServiceID);
		customerServiceIds?.forEach((id) => {
			this.lrInsuranceStore.uiStore.setLoad(id, 'Client Next Activity', true);
		});
		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/customer`, {
				nextActivityOnly: true,
			})
			.pipe(
				tap((x) => {
					this.lrInsuranceStore.update(
						customerServiceIds,
						produce((draft) => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.ClientNextActivityId = x.ActivityId;
								// tslint:disable-next-line: max-line-length
								draft.ClientNextActivity.value =
									formattedDate +
									' - ' +
									x.AssignedToAdviserName +
									' - ' +
									x.ActivityType +
									' - ' +
									x.ActivityName;
							} else {
								draft.ClientNextActivityId = null;
								draft.ClientNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() => {
					customerServiceIds?.forEach((id) => {
						this.lrInsuranceStore.uiStore.setLoad(
							id,
							'Client Next Activity',
							false
						);
					});
				})
			);
	};

	updateUserNextActivity = (customerId: number) => {
		const customerServiceIds = this.lrInsuranceQuery
			.getAll({ filterBy: (row) => row.CustomerID === customerId })
			?.map((z) => z.CustomerServiceID);
		customerServiceIds?.forEach((id) => {
			this.lrInsuranceStore.uiStore.setLoad(id, 'User Next Activity', true);
		});
		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/adviser`, {
				nextActivityOnly: true,
			})
			.pipe(
				tap((x) => {
					this.lrInsuranceStore.update(
						customerServiceIds,
						produce((draft) => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.UserNextActivityId = x.ActivityId;
								draft.UserNextActivity.value =
									formattedDate +
									' - ' +
									x.ActivityType +
									' - ' +
									x.ActivityName;
							} else {
								draft.UserNextActivityId = null;
								draft.UserNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() => {
					customerServiceIds?.forEach((id) => {
						this.lrInsuranceStore.uiStore.setLoad(
							id,
							'User Next Activity',
							false
						);
					});
				})
			);
	};

	createClientNextActivity = (ac: ActivityViewModel) =>
		of(ActivityViewModel.MapToAdd(ac)).pipe(
			mergeMap((x) => this.activityService.Post(x)),
			mergeMap(
				(y) => {
					if (y) {
						return this.updateClientAndUserNextActivity(ac.Customer.CustomerId);
					}
				},
				(o) => o
			)
		);

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.lrInsuranceStore.uiStore.setSort(propSort, sort);
	}

	setTempValue = (customerServiceId: number, metakey: string, value: any) =>
		this.lrInsuranceStore.uiStore.setTempValue(
			customerServiceId,
			metakey,
			value
		);

	setSearchForm = (req: LrInsuranceRequest) => {
		this.lrInsuranceStore.setSearchForm(req);
	};
}
