import { numUtil } from '../../../../../util/util';
import { sum, subtract } from 'ramda';

const getTotalFromDropdownValue = (data) => {
  const total = data?.reduce((sum, { value }) => sum + +value, 0);
  return +numUtil.formatToNumCurrency(total);
};

const getMonthlySurplusShortfallStopOrStart = (a, b, c, d) => {
  // Monthly Surplus / (Shortfall) - stop/start = a + b + c - d
  // a = Monthly Surplus / (Shortfall) - Lost,
  // b = Expenses that could stop,
  // c = What income could start - Value
  // d = What expenses could start - Value
  const totalSum = sum([a, b, c]);
  const total = subtract(totalSum, d);
  return +numUtil.formatToNumCurrency(total);
};

const getTotalDays = (a, b, c) => {
  // Total = SUM of (a) Sick leaves, (b) Annual leaves, and (c) Living off Savings
  c = c === 'N/A' ? 0 : c;
  const result = sum([a || 0, b || 0, c]);
  return +numUtil.formatToNumCurrency(result);
};

const getTotalRequired = (a, b, c) => {
  // Total Required = (a) Period Covered + (b) Period Partner + (c) Extra Costs
  const result = sum([a, b, c]);
  return +numUtil.formatToNumCurrency(result);
};

const incomeAddArray = (lostAfterTax: number, ...deductArray) => {
  let total = 0;
  deductArray?.forEach(deductions => {
    deductions?.forEach(data => {
      total += Number(data.value) || 0
    });
  });
  return Number(Number(lostAfterTax + total).toFixed(2));
}

const incomeLessArray = (lostAfterTax: number, ...deductArray) => {
  let total = 0;
  deductArray?.forEach(deductions => {
    deductions?.forEach(data => {
      total += Number(data.value) || 0
    });
  });
  return Number(Number(lostAfterTax - total).toFixed(2));
}

export const calcUtil = {
  getTotalFromDropdownValue,
  getMonthlySurplusShortfallStopOrStart,
  getTotalDays,
  getTotalRequired,
  incomeAddArray,
  incomeLessArray,
};
