import { MonthlyExpenseDetailsState } from '../models/monthly-expense.model';
import { numUtil } from '../../../../../util/util';
import { convertToMonthly, curFreqType } from './annual-conversion';
import * as R from 'ramda';

const totalExpenseFields = [
	'rentNow',
	'rentFuture',
	'utilities',
	'ownerOccupiedRates',
	'investmentRates',
	'healthMedicalCare',
	'householdContentsInsurance',
	'houseInsurance',
	'vehicleInsurance',
	'hMFPInsurance',
	'lRInsurance',
	'groceries',
	'ongoingHouseholdExpenses',
	'childCare',
	'childSupportMaintenance',
	'personalCareClothing',
	'publicEducation',
	'privateEducation',
	'transport',
	'kiwiSaverContribution',
	'entertainmentRecreation',
	'tithing',
	'mortgagePayments',
	'personalDebt',
	'bodyCorporate'
];

export interface ExpensesTotals {
	monthlyTotal: number;
	totalMinusDiscontinued: number;
}

export const computeTotalExpense = (
	monthlyExpense: MonthlyExpenseDetailsState
) => {
	let totalExpenseList = 0;
	let discontinued = 0;

	if (Array.isArray(monthlyExpense.expenseList)) {
		monthlyExpense.expenseList?.map((x) => {
			if (x.expenseNotContinuing) {
				discontinued += convertToMonthly(
					+x.expenseValue,
					x.expenseFrequency as curFreqType
				);
			}
			totalExpenseList += convertToMonthly(
				+x.expenseValue,
				x.expenseFrequency as curFreqType
			);
		});
	}

	const totalExpenseArray = [];
	const createExpenseArray = (field: string) => {
		const notContinuing = !!monthlyExpense[`${field}NotContinuing`];
		if (notContinuing) {
			discontinued += convertToMonthly(
				+monthlyExpense[field],
				monthlyExpense[field.concat('Frequency')] as curFreqType
			);
		}

		totalExpenseArray.push(
			convertToMonthly(
				+monthlyExpense[field],
				monthlyExpense[field.concat('Frequency')] as curFreqType
			)
		);
	};
	R.forEach(createExpenseArray, totalExpenseFields);

	const totalExpense = R.sum([
		...totalExpenseArray,
		// expense list
		+totalExpenseList,
	]);

	const totals: ExpensesTotals = {
		monthlyTotal: +numUtil.formatToNumCurrency(totalExpense),
		totalMinusDiscontinued: +numUtil.formatToNumCurrency(
			totalExpense - discontinued
		),
	};

	return totals;
};
