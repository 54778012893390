import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, fromEvent, of } from 'rxjs';
import {
	debounceTime,
	map,
	mergeMap,
	shareReplay,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { LocalService } from 'src/app/core/services/local.service';
import {
	CommandRoute,
	RouteService,
} from '../../../../core/config/route.service';
import { staticConf } from '../../../../core/config/static-config.service';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { ActivityModalComponent } from '../../../../shared/modal/activity/activity-modal/activity-modal.component';
import {
	DeleteModalComponent,
	DeleteModalModel,
} from '../../../../shared/modal/delete-modal/delete-modal.component';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../util/moment.util';
import { util } from '../../../../util/util';
import { LeadSearchFormComponent } from '../lead-search-form/lead-search-form.component';
import { comparer, request } from '../lead-search.util';
import { LeadSearchUiQuery } from '../state/lead-search-ui.query';
import { LeadSearchUiStore } from '../state/lead-search-ui.store';
import { Row } from '../state/lead-search.model';
import { LeadSearchQuery } from '../state/lead-search.query';
import { LeadSearchService } from '../state/lead-search.service';
import { LeadSearchStore } from '../state/lead-search.store';
import { Metakey, linkColumn } from './lead-search-datatable.config';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { UserQuery } from '@domain/user/user.query';
import { Transfer } from '@modules/transfer/state/transfer.model';

declare var $: any;

@Component({
	selector: 'app-lead-search-page',
	templateUrl: './lead-search-page.component.html',
	styleUrls: ['./lead-search-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadSearchPageComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	/** Currently active with column settings. */
	columns$ = this.leadQuery.tableColumns$;
	adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

	/** Currently active columns including column for link */
	columnsWithLink$ = this.columns$.pipe(map((x) => [...x, linkColumn]));
	/** Link column details. Used to show link column. */
	linkColumn = linkColumn;

	/** Cells currently loading. lookup object format */
	cellsLoading$ = this.leadQuery.cellsLoading$;
	/** Cells currently editing. lookup object format */
	cellsEditing$ = this.leadQuery.cellsEditing$;
	/** Cells current temporary value. lookup object format */
	cellsTempvalue$ = this.leadQuery.cellsTempvalue$;
	/** Rows currently loading. lookup object format */
	rowsLoading$ = this.leadQuery.rowsLoading$;
	isSearching$ = this.leadQuery.isSearching$;
	isSelectAll$ = this.leadSearchUiQuery.isSelectAll$;
	hasBulkItemCheckedEntity$ = this.leadSearchQuery.hasBulkItemCheckedEntity$;
	hasBulkItemUnCheckedEntity$ =
		this.leadSearchQuery.hasBulkItemUnCheckedEntity$;

	/** Row data. Already sorted. */
	rows$ =
		this.leadQuery.getValue().count <= 500
			? this.leadQuery.sortedRows$
			: this.leadQuery.rows$;
	checkedRows$ = this.rows$.pipe(
		map((rows) => rows.filter((row) => row?.bulk?.value))
	);
	/** Column currently sorted. */
	propSort$ = this.leadQuery.uiStore.propSort$;
	/** Current sort direction. */
	sorts$ = this.leadQuery.sorts$;
	hasRow$ = this.leadQuery.hasRows$;

	// For activity modal
	/** Activity type choices */
	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');
	/** Adviser choices */
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	/** adviser calendar choices. */
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	availableStaffsChoices$ = this.blStaffsQuery.availableStaffsChoices$;
	availableStaffsChoicesAsObject$ =
		this.blStaffsQuery.availableStaffsChoices$.pipe(
			map((choices) =>
				util.createLookupFromList(
					(c) => c.value,
					(c) => c.display,
					choices
				)
			),
			shareReplay(1)
		);

	allUnfilteredAdvisersChoices$ = this.blStaffsQuery.altAdviserChoices$;
	allUnfilteredAdvisersChoicesAsObject$ =
		this.blStaffsQuery.altAdviserChoices$.pipe(
			map((choices) =>
				util.createLookupFromList(
					(c) => c.value,
					(c) => c.display,
					choices
				)
			),
			shareReplay(1)
		);

	allAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$;
	allAdviserChoicesAsObject$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);
	allStaffsChoices$ = this.blStaffsQuery.allStaffsChoices$;
	allStaffsChoicesObject$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);
	leadGenChoices$ = this.blStaffsQuery.leadGenChoices$;
	leadGenChoicesAsObject$ = this.blStaffsQuery.leadGenChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);

	/** Put cell into edit mode  */
	edit = this.leadService.edit;
	/** Remove cell into edit mode  */
	cancel = this.leadService.cancel;
	/** Set temporary value for cell */
	setTempValue = this.leadService.setTempValue;

	showSearchFields = false;
	tblHeight: SafeStyle;
	transferRowsLimit = Transfer.LIMIT;

	/** Observable that emits when window resizes */
	resizeEvent$ = fromEvent(window, 'resize');

	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild(LeadSearchFormComponent)
	leadSearchFormComponent: LeadSearchFormComponent;

	/** For datatable optimization to determine table column idetifier. */
	trackByFn(item: TableColumn) {
		return item.prop;
	}

	/** For datatable optimization to determine row identifier. */
	rowIdentity = (row: Row) => row.CustomerId;

	/** Gets route for link depending on ID and column. */
	route = (metakey: Metakey, row: Row): CommandRoute | string => {
		switch (metakey) {
			case 'Name':
				return row.link.IsCompany
					? this.ngZone.run(() =>
							this.routeService.businessEdit(row.CustomerId)
					  )
					: this.ngZone.run(() =>
							this.routeService.customerView(row.CustomerId)
					  );
			case 'Physical Address':
				return this.routeService.externalRoute(
					staticConf.googleMapApi + row.PhysicalAddress.value
				);
			default:
				return null;
		}
	};

	adviserChoices: ViewDisplayValue[];
	allAdviserChoices: ViewDisplayValue[];

	constructor(
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private leadQuery: LeadSearchQuery,
		private leadService: LeadSearchService,
		private routeService: RouteService,
		private modalService: BsModalService,
		private ngZone: NgZone,
		private sanitizer: DomSanitizer,
		private leadSearchUiQuery: LeadSearchUiQuery,
		private localService: LocalService,
		protected leadSearchStore: LeadSearchStore,
		protected leadSearchUiStore: LeadSearchUiStore,
		protected businessConfigQuery: BusinessConfigQuery,
		protected userQuery: UserQuery,
		protected leadSearchQuery: LeadSearchQuery
	) {}

	ngOnInit() {
		this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
			'calc(100vh - 141px)'
		);

		this.resizeEvent$
			.pipe(debounceTime(500), takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.leadService.reloadData();
			});
	}

	ngAfterViewInit(): void {
		this.dragHeader();
	}

	businessRoute(id: number) {
		return this.routeService.businessEdit(id);
	}
	clientRoute(id: number) {
		return this.routeService.customerView(id);
	}

	saveField = (
		row: Row,
		MetaKey: Metakey,
		key: string,
		metadata: FieldMetadata<any>
	) => {
		let newMetaValue = null;
		if (MetaKey === 'Alt. Adviser') {
			newMetaValue = JSON.stringify(
				JSON.parse(metadata.value).map((i) => parseInt(i))
			);
		}
		this.leadService
			.saveField({
				CustomerId: row.CustomerId,
				MetaKey,
				MetaValue: newMetaValue ? newMetaValue : metadata.value,
			})
			.pipe(
				withLatestFrom(this.propSort$),
				mergeMap(([res, prop]) => {
					return this.rows$.pipe(
						tap((rows) => {
							if (prop === key) {
								const i = rows?.findIndex(
									(x) => x.CustomerId === row.CustomerId
								);
								this.table.bodyComponent.updateOffsetY(
									i / this.table.bodyComponent.pageSize
								);
								this.table.bodyComponent.selected.push(row);
								setTimeout(() => {
									this.table.bodyComponent.selected = [];
									$('.datatable-body-row').removeClass('active');
								}, 1000);
							}
						}),
						take(1)
					);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	updateNoteField = (CustomerId: number, metadata: any) =>
		this.leadService.updateNote(CustomerId, metadata.value);

	updateClientNextActivityField = (customerId: number) => {
		this.leadService
			.updateClientAndUserNextActivity(customerId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};
	updateUserNextActivityField = (customerId: number) => {
		this.leadService
			.updateClientAndUserNextActivity(customerId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	reorder = (reorderEvent: {
		column: TableColumn;
		newValue: number;
		prevValue: number;
	}) => {
		this.leadService
			.reorderColumn(reorderEvent.prevValue, reorderEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};
	resize = (event: { column: TableColumn; newValue: number }) => {
		if (event && (!event.column || !event.newValue)) {
			return;
		}
		this.leadService
			.resizeColumn(`${event.column.prop}`, event.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	sort(a: { sorts; column; prevValue; newValue }) {
		const prop = a.sorts?.[0]?.prop;
		const dir = a.sorts?.[0]?.dir;
		if (this.leadQuery.getValue().count <= 500) {
			this.leadService?.sort(prop, dir);
			return;
		}
		this.leadService
			.search(
				request(
					{
						Column: prop,
						Direction: dir,
					},
					this.leadSearchFormComponent.prepareFormValue(),
					1
				)
			)
			.subscribe(() => {
				this.leadService?.sort(prop, dir);
				this.scrollToTopRow();
			});
	}

	delete(customerId: number) {
		const confirm$ = this.leadService.delete(customerId);

		const initState: Partial<DeleteModalModel> = {
			delete$: confirm$,
			header: 'Delete Lead',
			message: `Are you sure you want to delete ${this.leadQuery.getCustomerName(
				customerId
			)}`,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Activity modal
	createClientNextActivity(customerId: number) {
		new Observable((obs) => {
			const formItem = {
				Customer: { CustomerId: customerId },
			};
			const initState: any = {
				formItem,
				AT$: this.AT$,
				AM$: this.AM$,
				adviserChoices$: this.adviserChoices$,
				adviserCalendarChoices$: this.adviserCalendarChoices$,
				header: 'Schedule Activity',
				hideClient: true,
				savefn: this.saveClientNextActivity,
				isModal: true,
				isEditForm: false,
				permissionsToComplete: ['FCLA'],
			};
			this.modalService.show(ActivityModalComponent, {
				class: `modal-dialog-centered ${
					this.localService.getValue('loginType') === 'microsoft'
						? 'modal-dialog-outlook-xl'
						: 'modal-xl'
				}`,
				initialState: initState,
				ignoreBackdropClick: true,
			});
			obs.complete();
		})
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	saveClientNextActivity = (ac: ActivityViewModel) =>
		this.leadService.createClientNextActivity(ac);

	convertDateToNZ(d: string) {
		const nzToday = MomentUtil.createMomentNz().format('LL');
		const splicedDate = d?.slice(0, 10);
		const date = moment(splicedDate, 'DD/MM/YYYY');
		return date.diff(nzToday, 'days');
	}

	getRowClass = (row: Row) => {
		const nd = this.convertDateToNZ(
			row.ClientNextActivity.value ? row.ClientNextActivity.value : ''
		);
		// If 0 meaning its today
		if (nd === 0) {
			return 'isDueDate';
		}
		// if negative meaning overdue
		if (nd < 0) {
			return 'isOverdue';
		}
		if (nd < 6) {
			return 'isDueNextFiveDays';
		}
	};

	getCellClass(metadata: FieldMetadata<any>, row: Row): string {
		if (
			(metadata.metakey === 'Client Next Activity' &&
				row.ClientNextActivity.value) ||
			(metadata.metakey === 'User Next Activity' && row.UserNextActivity.value)
		) {
			const rowValue =
				metadata.metakey === 'Client Next Activity'
					? row.ClientNextActivity.value
					: row.UserNextActivity.value;
			const nd = this.convertDateToNZ(rowValue);
			// If 0 meaning its today
			if (nd === 0) {
				return 'isDueDate';
			}
			// if negative meaning overdue
			if (nd < 0) {
				return 'isOverdue';
			}
			if (nd < 6) {
				return 'isDueNextFiveDays';
			}
		}
	}

	onToggleSearch(data) {
		this.showSearchFields = data.showSearch;
		if (this.showSearchFields && data.width > 1199) {
			this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
				`calc(100vh - ${104 + data.height}px)`
			);
		} else {
			this.tblHeight =
				this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - 141px)`);
		}
		setTimeout(() => {
			this.leadService.reloadData();
			document.body.style.overflowY = 'auto';
		}, 500);
	}

	// Dragging column header
	dragHeader() {
		const _tbl = 'ngx-datatable';
		const _header = 'datatable-header';
		const _headerInner = 'datatable-header-inner';
		const _headerCell = 'datatable-header-cell';
		const _body = 'datatable-body';
		const _draggable = 'draggable';
		const _draggingState = 'dragging-state';

		const tblHeaderCell = `${_tbl} ${_header} ${_headerCell}`;
		const tblHeaderCellDrag = `${_tbl} ${_header} ${_headerCell} .${_draggable}`;
		const header = $(`${_tbl} ${_header}`);
		const tblBody = $(`${_tbl} ${_body}`);

		let isDragging = 0;
		let timer;
		let scrollTimer;
		let _self;
		let tranlateLeft;

		const scrollLeftHeader = (pageX, headerWidth) => {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			const innerHeader = header?.find(`.${_headerInner}`);
			const key = 'MSStream';
			const os = /Windows/.test(navigator.userAgent) && !window[key];
			const maxScroll =
				innerHeader[0].scrollWidth - tblBody.width() + (os ? 16 : 0);
			const isDraggingState = $(tblHeaderCell)
				.parent()
				.hasClass(`${_draggingState}`);

			// Check if the mouse is in the left edge of header while dragging
			if (pageX <= 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() > 0) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() - 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft -= 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the left edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
			// Check if the mouse is in the right edge of header while dragging
			if (pageX >= headerWidth - 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() <= maxScroll) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() + 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft += 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the right edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
		};

		$(document)
			.on('mousedown', tblHeaderCellDrag, function (e) {
				if (e.which !== 1) {
					return;
				}
				_self = $(this);
				tranlateLeft = 0;

				isDragging = 1;

				timer = setTimeout(() => {
					_self.closest(tblHeaderCell).parent().addClass(`${_draggingState}`);
				}, 500);
			})

			.on('mouseup', function () {
				if (timer) {
					clearTimeout(timer);
				}
				if (scrollTimer) {
					clearTimeout(scrollTimer);
					_self
						.closest(`${_headerCell}`)
						.css('transform', `translate3d(0px, 0px, 0px)`);
				}
				if (isDragging) {
					$(tblHeaderCell)
						.removeClass(`${_draggable}`)
						.parent()
						.removeClass(`${_draggingState}`);
					isDragging = 0;
				}
			})

			// Scroll header when dragging column into the edge of header
			.on('mousemove', function (e) {
				const headerWidth = header.width();
				const pageX = e.pageX - header.offset().left;
				scrollLeftHeader(pageX, headerWidth);
			});
	}

	ngOnDestroy() {
		$(document).off('mousedown mouseup mousemove');

		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	onPage(event: any, indexes: any) {
		if (this.leadQuery.getValue().count <= 500) {
			return;
		}
		// pre-fetch the next page
		const calc = this.leadQuery.getCount() - 2;
		if (calc <= indexes.last) {
			this.loadMore();
		}
	}

	loadMore() {
		const sl = this.table.element.querySelector('.datatable-body').scrollLeft;
		const sh = this.table.bodyComponent.scrollHeight;
		this.leadQuery.searchForm$
			.pipe(
				withLatestFrom(this.leadQuery.isComplete$),
				withLatestFrom(this.leadSearchUiQuery.isSearching$),
				map(([[x, isComplete], isSearching]) => {
					const paging = {
						Column: x ? x.Paging.Column : '',
						Direction: x ? x.Paging.Direction : '',
					};

					// Checking if filter has changed
					const compare = comparer(
						request(paging, this.leadSearchFormComponent.prepareFormValue(), 0),
						x
					);

					const currentIndex = x && x.Paging ? x.Paging.Index + 1 : 0;

					return {
						compare,
						isSearching,
						isComplete,
						paging,
						currentIndex,
					};
				}),
				mergeMap((x) => {
					if (x.compare && !x.isSearching && !x.isComplete) {
						return this.leadService.search(
							request(
								x.paging,
								this.leadSearchFormComponent.prepareFormValue(),
								x.currentIndex
							)
						);
					}
					return of(null);
				}),
				take(1)
			)
			.subscribe(() => {
				this.table.element.querySelector('.datatable-body').scrollTop = sh;
				this.table.element.querySelector('.datatable-body').scrollLeft = sl;
			});
	}

	scrollToTopRow() {
		this.table.element.querySelector('.datatable-body').scrollTop = 0;
		this.table.bodyComponent.updateOffsetY(0);
		this.table.bodyComponent.offset = 0;
	}

	onChangeCheckValue(value: boolean, row: Row): void {
		const customerId = row.CustomerId;
		this.updateCustomerBulkCheck(value, customerId);
	}

	updateCustomerBulkCheck(value: boolean, customerId?: number): void {
		this.rows$
			.pipe(
				take(1),
				map((rows) => {
					const checkedRowsCount = rows.filter(
						(row) => !!row.bulk?.value
					).length;
					if (value && customerId && checkedRowsCount + 1 > Transfer.LIMIT) {
						return rows;
					}
					return rows.map((row, index) => {
						return {
							...row,
							bulk: !!row?.bulk
								? {
										...row.bulk,
										value:
											(!customerId && index < Transfer.LIMIT) ||
											+row.CustomerId === +customerId
												? value
												: row.bulk?.value,
								  }
								: row.bulk,
						};
					});
				})
			)
			.subscribe((updatedRows) => {
				this.leadSearchStore.set([...updatedRows], {
					idKey: 'CustomerId',
				} as any);
				this.isManuallySelectAll();
			});
	}

	isManuallySelectAll() {
		this.rows$.pipe(
			tap((rows) => {
				const checkedRows = rows?.filter((x) => !!x?.bulk?.value);
				if (rows?.length === checkedRows?.length) {
					this.leadSearchUiStore.setIsSelectAll(true);
				} else {
					this.leadSearchUiStore.setIsSelectAll(false);
				}
			}),
			take(1)
		).subscribe();
	}

	onChangeBulkCheckValue(event: Event): void {
		const value = (event.target as HTMLInputElement).checked;
		this.leadSearchUiStore.setIsSelectAll(value);
		this.updateCustomerBulkCheck(value);
	}

	deSelectAll() {
		this.leadSearchUiStore.setIsSelectAll(false);
		this.updateCustomerBulkCheck(false);
	}
}
