import { Store, StoreConfig } from '@datorama/akita';
import UserResponse from './user.response-model';
import { produce } from 'immer';
import { Injectable } from "@angular/core";

const storeName = 'user';

export interface UserState extends UserResponse {}

export function createInitialState(): UserState {
  return {
    AdviserIdsAssigned: [],
    AdviserManager: 0,
    BirthDate: '',
    BusinessPhone: '',
    Businesses: [],
    CreatedByStaffId: 0,
    CreatedByStaffLevel: 0,
    EmailAddress: '',
    FirstName: '',
    HomeAddress: '',
    IsActive: 0,
    LastName: '',
    MobilePhone: '',
    RouteTo: '',
    SecurityGroup: '',
    SecurityRoles: [],
    Services: null,
    StaffID: 0,
    StartDate: '',
    WidgetOrder: [],
    StaffSettings: null,
    IsLoading: false,
  };
}

@Injectable()
@StoreConfig({
  name: storeName,
  idKey: 'id',
})
export class UserStore extends Store<UserState> {
  public static storeName = storeName;
  constructor() {
    super(createInitialState());
  }

  updateStaffSettings(staffSettings) {
    this.update(
      produce((x) => {
        x.StaffSettings = staffSettings;
      })
    );
  }

  updateStaffLoading(data: boolean) {
    this.update({ IsLoading: data });
  }
}
