<form
	[formGroup]="form"
	class="form employment-form"
	[class.submitted]="submitted"
>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-3 text-left">
			<label for="" class="position-relative font-weight-bold">
				Income Earner
			</label>
			<ng-container *ngIf="isLoading">
				<app-manual-loader-mini
					[isLoading]="true"
				></app-manual-loader-mini>
			</ng-container>
		</div>
		<div class="col-12 col-lg-3 mb-2 mb-lg-0">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="incomeEarner"
					formControlName="incomeEarner"
					(change)="getPersonInfo($event)"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option
						*ngFor="
							let p of peopleList;
							let index = i;
							trackBy: trackByFn
						"
						[value]="p.value"
						[selected]="p.value == this.IncomeEarner.value
					">
						{{ p.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-3 text-left">
			<label for="" class="position-relative font-weight-bold">
				Income Type
			</label>
		</div>
		<div class="col-12 col-8 col-lg-3">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectEmpIncomeType"
					formControlName="incomeType"
					(change)="selectIncomeType()"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of incomeType" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-3 text-left">
			<label for="" class="position-relative font-weight-bold">
				Occupation
			</label>
		</div>
		<div class="col-12 col-lg-3 mb-2 mb-lg-0">
			<input
				id="empOccupation"
				class="form-control crt-form-control"
				formControlName="occupation"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-3 text-left">
			<label for="" class="position-relative font-weight-bold">
				Employment
			</label>
		</div>
		<div class="col-12 col-lg-3">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectEmpEmployment"
					formControlName="employment"
					(change)="selectEmployment()"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of employment" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-3 text-left">
			<label for="" class="position-relative font-weight-bold">
				Employer
			</label>
		</div>
		<div class="col-12 col-lg-3 mb-2 mb-lg-0">
			<input
				id="selectEmpEmployer"
				class="form-control crt-form-control"
				formControlName="employer"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-3 text-left">
			<label for="" class="position-relative font-weight-bold">
				Industry
			</label>
		</div>
		<div class="col-12 col-lg-3">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectEmpIndustry"
					formControlName="industry"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of industry" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>

	<ng-container *ngIf="showTimeInJob; else defaultDetails">
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-3 text-left">
				<label for="" class="position-relative font-weight-bold">
					Time in job
				</label>
			</div>
			<div class="col-12 col-lg-3 long-label-icon--right">
				<input
					id="years"
					formControlName="years"
					class="form-control crt-form-control pr-5"
					maxlength="3"
					numeric
				/>
				<i>Years</i>
			</div>
			<div class="col-12 col-lg-3 mid-long-label-icon--right">
				<input
					id="months"
					formControlName="months"
					class="form-control crt-form-control"
					maxlength="2"
					numeric
				/>
				<i>Months</i>
			</div>
		</div>
	</ng-container>

	<ng-template #defaultDetails>
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-3 text-left">
				<label for="" class="position-relative font-weight-bold">
					Details
				</label>
			</div>
			<div class="col-12 col-lg-3 long-label-icon--right">
				<input
					id="hours"
					formControlName="hours"
					class="form-control crt-form-control"
					maxlength="3"
					numeric
				/>
				<i>Hours per week</i>
			</div>
			<div class="col-12 col-lg-3 long-label-icon--right">
				<input
					id="years"
					formControlName="years"
					class="form-control crt-form-control"
					maxlength="3"
					numeric
					style="padding-right: 90px;"
				/>
				<i>Years in job</i>
			</div>
			<div class="col-12 col-lg-3 long-label-icon--right">
				<input
					id="manual"
					formControlName="manual"
					class="form-control crt-form-control"
					maxlength="3"
					numeric
				/>
				<i>% Manual labour</i>
			</div>
		</div>
	</ng-template>

	<div formArrayName="detailsList" class="mb-2">
		<label class="position-relative font-weight-bold pb-1"
			>Gross Income:
		</label>
		<div
			class="form-row employment-form__expense-list align-items-center mb-2"
			*ngFor="let item of DetailsList.controls; let pointIndex=index"
			[formGroupName]="pointIndex"
		>
			<div class="d-md-none col-12 col-lg-3 text-right"></div>
			<div class="col-12 col-lg-3 text-left">
				<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
					<select
						id="selectEmpIncomeSourceType_{{pointIndex}}"
						formControlName="incomeSourceType"
						class="form-control crt-form-control theme-crm-field-disabled"
						placeholder="Select"
					>
						<option value="" selected [attr.disabled]="true" hidden>
							Select
						</option>
						<option *ngFor="let d of incomeSourceType" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-8 col-lg-3">
				<div class="dollar-icon">
					<input
						id="empIncomeSourceValue_{{pointIndex}}"
						class="form-control crt-form-control"
						type="text"
						formControlName="incomeSourceValue"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-4 col-lg-3">
				<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
					<select
						id="selectEmpIncomeFrequency_{{pointIndex}}"
						formControlName="incomeSourceFrequency"
						class="form-control crt-form-control theme-crm-field-disabled"
						placeholder="Income Frequency"
					>
						<option *ngFor="let d of incomeFrequency" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
				<span
					class="tap-required"
					*ngIf="item.get('incomeSourceFrequency').invalid"
					>*</span
				>
			</div>
			<div class="col-12 col-8 col-lg-3 pt-1">
				<ng-container
					*ngIf="item.get('btnDelete').value && DetailsList.length > 1 && !viewMode"
				>
					<button
						id="deleteSellingPointButton"
						type="button"
						class="icon-btn w-auto h-auto pr-0"
						(click)="deleteSellingPoint(pointIndex)"
					>
						<i class="material-icons md-16 pr-0"> delete </i>
					</button>
				</ng-container>
				<ng-container *ngIf="item.get('btnAdd').value && !viewMode">
					<button
						id="addSellingPointButton"
						type="button"
						class="icon-btn w-auto h-auto pl-0"
						(click)="addSellingPoint()"
					>
						<i class="material-icons md-16 pr-0"> add </i>
					</button>
				</ng-container>
			</div>
			<div class="d-none d-md-block col-12 col-lg-3"></div>
		</div>
	</div>

	<div class="form-row align-items-center">
		<div class="col-12 col-lg-3 text-left">
			<label for="" class="position-relative font-weight-bold"
				>KiwiSaver Contribution</label
			>
		</div>
		<div class="col-12 col-8 col-lg-3">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectKiwiSaver"
					formControlName="kiwiSaverContribution"
					class="form-control crt-form-control theme-crm-field-disabled"
				>
					<option value=""></option>
					<option *ngFor="let d of kiwiSaverContribution" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
</form>

<div class="form-buttons">
	<ng-container *ngIf="viewMode else upsertMode">
		<button
			id="closeButton"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancelClick()"
			[disabled]="loading"
		>
			Close
		</button>
	</ng-container>
	<ng-template #upsertMode>
		<button
			id="saveButton"
			type="button"
			class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
			(click)="save()"
			[disabled]="loading"
		>
			Save
			<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
		</button>
		<button
			id="cancelButton"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancelClick()"
			[disabled]="loading"
		>
			Cancel
		</button>
	</ng-template>
</div>
