import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CrtKiwiSaverStore, CrtKiwiSaverState } from './crt-kiwisaver.store';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CrtKiwiSaverQuery extends Query<CrtKiwiSaverState> {
	/**
	 * @returns observable object of primaryClient
	 */
	primaryClient$ = this.select((state) => state.primaryClient);
	/**
	 * @returns observable object of secondary clients
	 */
	secondaryClients$ = this.select((state) => state.secondaryClients);
	/**
	 * @returns observable object of secondary trusts
	 */
	secondaryTrusts$ = this.select((state) => state.secondaryTrusts);
	/**
	 * @returns observable object of secondary companies
	 */
	secondaryCompanies$ = this.select((state) => state.secondaryCompanies);
	/**
	 * @returns observable object of secondary professional contacts
	 */
	secondaryProfessionals$ = this.select(
		(state) => state.secondaryProfessionals
	);
	/*
	 * @returns observable of Merge Tags
	 */
	mergeTags$ = this.select((state) => state.mergeTags);

	sidebars$ = this.select((state) => state.sidebars);
	adviceProcessId$ = this.select((state) => state.adviceProcessId);
	adviceProcess$ = this.select((state) => state.adviceProcess);
	isCompany$ = this.select((state) => state.isCompany);
	pageStarted$ = this.select((state) => state.pageStarted);
	pageCompleted$ = this.select((state) => state.pageCompleted);
	isTabLoading$ = this.select((state) => state.isTabLoading);

	intro$ = this.select((state) => state.intro);

	// Scope of Services
	scopeOfService$ = this.select((state) => state.scopeOfService);
	sosDefault$ = this.select((state) => state.sosDefault);
	sosTemplateSettings$ = this.select((state) => state.sosTemplateSettings);

	declaration$ = this.select((state) => state.declaration);

  declarationSettings$ = this.select((state) => state.declarationSettings);

  declarationFormValue$ = this.select((state) => state.declarationFormValue);

  declarationEmailSettings$ = this.select((state) => state.declarationEmailSettings);

  // clientFactFindTemplateSettings$ = this.select((state) => state.clientFactFindTemplateSettings);

	adviceSummaryEmailSettings$ = this.select((state) => state.adviceSummaryEmailSettings);

	isAdviceProcessEnded$ = this.adviceProcess$.pipe(
		map((ap) => ap?.status > 2 && ap?.status < 5)
	);

	constructor(
		protected store: CrtKiwiSaverStore
	) {
		super(store);
	}
}
