import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CBStore, CBState } from './cb.store';

/**
 * Query Birthday widget data
 */
@Injectable()
export class CBQuery extends Query<CBState> {
  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create Birthday query
   */
  data$ = this.select(state => state.ClientBirthdayList);

  /**
   * Create L&R Anniversary query
   */
  constructor(protected store: CBStore) {
    super(store);
  }
}
