import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, Observable, of } from 'rxjs';

@Component({
	selector: 'app-select-input',
	templateUrl: './select-input.component.html',
	styleUrls: ['./select-input.component.scss'],
	exportAs: 'selectInput',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectInputComponent),
			multi: true,
		},
	],
})
export class SelectInputComponent<T> implements ControlValueAccessor {
	@ViewChild('textInput', { static: false })
	textInput: ElementRef<HTMLInputElement>;
	/**
	 * remove host element tabindex to remove duplicate
	 * tabindex when tabindex binding is added
	 */
	@HostBinding('attr.tabindex') get tabIndex() {
		return null;
	}

	@HostBinding('attr.placeholder') get attrPlaceholder() {
		return null;
	}

	@Input() placeholder = '';

	@Output() onTextboxKeyDown = new EventEmitter<any>();

	@Input() tabindex: number;

	@Input() selectClassName: string = '';
	@Input() textBoxClassName: string = '';
	@Input() textBoxPlaceholder: string = '';

	@Input() direction = 'ltr';

	/**
	 * input value
	 */
	@Input() dropdownValue: string;

	@Input() items: Observable<T[]> | T[];

	@Input() value: string;

	/**
	 * Set this property to the name of a data property that provides displayed values
	 */
	@Input() displayProperty = 'display';

	/**
	 * Set this property to the name of a data property that provides value to the select input
	 */
	@Input() valueProperty = 'value';

	/**
	 * whenever the selected value from the selectbox is equal to
	 * this property textbox will be shown and selectbox will be hidden
	 */
	@Input() otherValue = 'Other';

	/**
	 * weather the input is disabled or not
	 */
	@Input() disabled = false;

	/*
	 * emit when textbox value changed
	 */
	@Output() dropdownValueChanged = new EventEmitter<string>();

	/**
	 * emit whenever input or seletbox value changed
	 */
	@Output() valueChanged = new EventEmitter<string>();

	private change: (value: string) => void;

	get hasValue(): boolean {
		return Boolean(this.value);
	}

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
	}

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: (value: string) => void): void {
		this.change = fn;
	}

	registerOnTouched(_fn: any): void {}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onSelectboxChanged(e: Event): void {
		const value = (e.target as HTMLSelectElement).value;
		switch (value) {
			case this.otherValue:
				this.change(this.otherValue);
				this.dropdownValueChanged.emit('');
				// setTimeout: wait for the element to render
				setTimeout(() => this.textInput.nativeElement.focus());
				break;
			default:
				this.change(value);
				break;
		}
		this.valueChanged.emit(value);
		// this.value = value;
		this.cdr.detectChanges();
	}

	onTextboxChanged(e: Event): void {
		const value = (e.target as HTMLInputElement).value;
		if (value) {
			this.change(this.otherValue);
			this.dropdownValueChanged.emit(value);
			this.valueChanged.emit(value);
		} else {
			this.change('');
			this.value = '';
			this.valueChanged.emit('');
		}
		this.cdr.detectChanges();
	}

	onTextboxBlur(e: Event): void {
		if (!(e.target as HTMLInputElement).value) {
			this.change('');
			this.value = '';
			this.dropdownValueChanged.emit('');
		}
		this.cdr.detectChanges();
	}

	toBoolean(value: any): boolean {
		return Boolean(value);
	}
}
