import produce from 'immer';
import { sum } from 'ramda';
import {
	fgDefaultStatusColors,
	fgOrderListBlanket,
	fgStatusColorsBlanket,
} from '../../../shared/models/services/fg-insurance/fg-status.defaults';
import { FgInsuranceState } from '../../models/services/fg-insurance/fg-provider-group.model';
import { mainGroupSorter, subGroupSorter } from './fg-sorter.util';

export const fgColorStatus = (status: string, customFgStatus) => {
	const fgStatusLegend = customFgStatus ?? fgDefaultStatusColors;
	const green = fgStatusLegend?.green;
	const yellow = fgStatusLegend?.yellow;
	const red = fgStatusLegend?.red;
	const grey = fgStatusLegend?.grey;

	const stat = status ? status?.toLowerCase() : '';

	if (green?.includes(stat)) {
		return 'green';
	}

	if (yellow?.includes(stat)) {
		return 'yellow';
	}

	if (red?.includes(stat)) {
		return 'red';
	}

	if (grey?.includes(stat)) {
		return 'grey';
	}

	return 'dark';
};

export const fgServiceUtil = (state: FgInsuranceState, companyCode?: string): FgInsuranceState => {
	let fgColorStatusLegend = fgDefaultStatusColors;
	let fgCustomSorting = null;

	if (companyCode === 'blanket') {
		fgColorStatusLegend = fgStatusColorsBlanket;
		fgCustomSorting = fgOrderListBlanket;
	}

	return produce(state, (draft) => {
		let totalApi = 0;

		draft.fGs?.forEach((fg) => {
			let firstPolicyDate = null;
			let linkDocument = null;
			const services = fg?.customerServices?.sort((a, b) => subGroupSorter(a, b, fgCustomSorting));
			const totalValue = services?.reduce((a, c) => {
				return c?.status === 'Inforce' ? sum([+a, +(c?.totalPremium || 0)]) : +a;
			}, 0);
			const original = fg?.customerServices?.find((x) => x?.tracking === 'Original');
			if (original) {
				// Check Tracking "Original" first
				firstPolicyDate = original?.firstPolicyDate;
				linkDocument = fg?.linkDocument ? fg.linkDocument : +original.documentLink;
			} else {
				const renewal = fg?.customerServices?.find((x) => x?.tracking === 'Renewal');
				firstPolicyDate = renewal ? renewal?.firstPolicyDate : fg?.customerServices[0]?.firstPolicyDate;
				linkDocument = fg?.linkDocument
					? fg.linkDocument
					: renewal
						? +renewal.documentLink
						: fg?.customerServices[0]?.documentLink;
			}
			fg.linkDocument = linkDocument;
			fg.aPI = totalValue;
			fg.customerServices = services;
			fg.color = fgColorStatus(services[0].status, fgColorStatusLegend);
			fg.status = services[0].status;
			fg.firstPolicyDate = firstPolicyDate || '';
			totalApi = sum([+totalApi, +totalValue]);
		});

		draft.totalInforceApi = totalApi;
		draft.fGs?.sort((a, b) => mainGroupSorter(a, b, fgCustomSorting));
	});
};
