import { QueryEntity } from '@datorama/akita';
import { EmailStatusState, EmailSettingsStore } from './email-settings.store';
import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { columns } from '../email-settings-datatable.config';
import * as R from 'ramda';
import { EmailSettingsModel } from './email-settings.model';

@Injectable()
export class EmailSettingsQuery extends QueryEntity<EmailStatusState, EmailSettingsModel> {
	cellsLoading$ = this.select(state => state.cellLoadingStatus);
	cellsEditing$ = this.select(state => state.cellEditStatus);
	cellsTempValue$ = this.select(state => state.cellTempValue);

	columns$ = this.select(x => x.columns);
	rows$ = this.selectAll({ filterBy: x => x.IsActive > 0 });
	tableColumns$ = this.columns$.pipe(
		map(tableColumns => {
			return tableColumns?.map(col => columns?.find(y => y.metakey === col))?.filter(x => x);
		}),
		map(tableColumns =>
			tableColumns?.map(column => {
				return column;
			})
		),
		distinctUntilChanged((x, y) => R.equals(x, y))
	);

	constructor(protected store: EmailSettingsStore) {
		super(store);
	}
}
