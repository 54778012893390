import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UserQuery } from '@domain/user/user.query';
import { MergeTagsCrtSettingsService } from '@modules/crt-settings/merge-tags/state/merge-tags-crt-settings.service';
import { EmailTemplateModalComponent } from '@shared/email-template-modal/email-template-modal.component';
import { CRMMatTableDataSource } from '@shared/mat-table/mat-table-datasource';
import { MergeTagDataTypes, MergeTagState, MergeTagTable } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { clone } from 'ramda';
import { finalize, map, of, take, tap, zip } from 'rxjs';
import { EmailTemplateQuery } from '../email-settings/state/email-template.query';
import { EmailTemplateService } from '../email-settings/state/email-template.service';
import {
	EmailTemplateModel,
	EmailTemplateStore,
	ClaimTypeChoices,
} from '../email-settings/state/email-template.store';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ServicesCodes } from '@shared/models/services/services.model';

@Component({
	selector: 'app-email-template',
	templateUrl: './email-template.component.html',
	styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent {
	@ViewChild('emailTemplateModal')
	emailTemplateModal: EmailTemplateModalComponent;

	displayedColumns: string[] = [
		'templateName',
		'emailTemplateSettingsType',
		'createdDateTime',
		'modifiedDateTime',
		'action',
		'delete',
	];

	emailTemplateMTs: MergeTagState[];

	dataSource = new CRMMatTableDataSource<EmailTemplateModel>({
		pageSize: 10,
		filter$: of({
			Paging: {
				Index: 1,
				Column: 'Default',
				Direction: 'asc', //always ascending based on ticket
				Size: 10,
			},
		}),
		loadFn$: (filter) => this.emailTemplateService.get(filter),
		query: this.emailTemplateQuery,
		store: this.emailTemplateStore,
	});
	claimsFeature = this.businessConfigQuery.getValue().config?.Claims;

	constructor(
		private emailTemplateQuery: EmailTemplateQuery,
		private emailTemplateStore: EmailTemplateStore,
		private emailTemplateService: EmailTemplateService,
		private userQuery: UserQuery,
		private cdr: ChangeDetectorRef,
		private businessConfigQuery: BusinessConfigQuery,
		private mtService: MergeTagsCrtSettingsService
	) {}

	get emailTemplateTypes(): ViewDisplayValue[] {
		const allowedServices = this.getAllowedServices() || [];
		return ClaimTypeChoices?.filter((type) => {
			if (!type.code) {
				return true;
			}
			if (type?.value === ServicesCodes.NotesClaims && !this.claimsFeature) {
				return false;
			}
			if (typeof type?.code === 'object') {
				return type?.code?.some((x) => allowedServices?.includes(x));
			}
			return allowedServices?.includes(type.code);
		})
	}

	getAllowedServices() {
		const businessServices =
			this.businessConfigQuery.getValue().config.Services;
		const userServices =
			JSON.parse(this.userQuery.getValue().Services)?.filter((us) =>
				businessServices.some((bs) => bs === us)
			) || [];
		const allowedServices = this.userQuery.isTapLevel()
			? businessServices
			: businessServices.filter((x) => userServices.includes(x));
		return allowedServices;
	}

	createTemplate(): void {
		const user = this.userQuery.getValue();
		this.emailTemplateModal
			.showAdd({
				user: `${user.FirstName} ${user.LastName}`,
				userEmail: user.EmailAddress,
				userFirstname: user.FirstName,
			})
			.pipe(
				take(1),
				tap((isSaved) => isSaved && this.dataSource.refresh())
			)
			.subscribe();
	}

	edit(data: EmailTemplateModel): void {
		data.isLoading = true;
		this.cdr.detectChanges();
		const clonedData = clone(data);
		const user = this.userQuery.getValue();
		clonedData.user = `${user.FirstName} ${user.LastName}`;
		clonedData.userEmail = user.EmailAddress;
		clonedData.userFirstname = user.FirstName;
		this.emailTemplateModal
			.showEdit(clonedData)
			.pipe(
				take(1),
				tap((isSaved) => isSaved && this.dataSource.refresh()),
				finalize(() => {
					data.isLoading = false;
					this.cdr.detectChanges();
				})
			)
			.subscribe();
	}

	createFn$ = (data: EmailTemplateModel) => this.emailTemplateService.add(data);

	updateFn$ = (data: EmailTemplateModel) =>
		this.emailTemplateService.update(data);

	delete(data: EmailTemplateModel): void {
		data.isLoading = true;
		this.cdr.detectChanges();
		this.createFn$;
		this.emailTemplateService
			.deleteSettings(data)
			.pipe(
				take(1),
				finalize(() => (data.isLoading = false))
			)
			.subscribe((isDeleted) => isDeleted && this.dataSource.refresh());
	}

	getMT$ = () => {
		let i = 0;
		const refId = 0;

		if (this.emailTemplateMTs?.length) {
			return of(this.emailTemplateMTs);
		}

		return zip(
			this.mtService.getMergeTags(i++, MergeTagTable.General, refId),
			this.mtService.getMergeTags(i++, MergeTagTable.Business, refId),
			this.mtService.getMergeTags(i++, MergeTagTable.Staff, +refId),
			// Referral Merge Tags
			this.getPostMergeTag(
				[
					MergeTagDataTypes.PrimaryClient,
					MergeTagDataTypes.SecondaryClient,
					MergeTagDataTypes.PrimaryCompany,
				],
				[MergeTagTable.CustomerDetails]
			),
			this.getPostMergeTag(
				[MergeTagDataTypes.AdviceProcess],
				[MergeTagTable.AdviceProcess]
			),
			this.getPostMergeTag(
				[MergeTagDataTypes.Claims],
				[MergeTagTable.AdviceProcess]
			),
			this.getPostMergeTag([MergeTagDataTypes.LR], [MergeTagTable.LR]),
			this.getPostMergeTag(
				[MergeTagDataTypes.Mortgage],
				[MergeTagTable.Mortgage]
			),
			this.getPostMergeTag([MergeTagDataTypes.FG], [MergeTagTable.FG]),
			this.getPostMergeTag(
				[MergeTagDataTypes.Investment],
				[MergeTagTable.Investment]
			),
			this.getPostMergeTag(
				[MergeTagDataTypes.KiwiSaver],
				[MergeTagTable.KiwiSaver]
			)
		).pipe(
			map((result) => {
				const arr = [];
				result.forEach((mt) => mt?.length && arr.push(...mt));
				return arr.map((m) => {
					m.value ??= m.secondaryValue;
					return m;
				});
			}),
			tap((mt) => (this.emailTemplateMTs = mt))
		);
	};

	getPostMergeTag(dataType: string[], table: string[]) {
		return this.mtService.postMergeTag({
			dataType,
			table,
			referenceId: 0
		})
	}

	convertServiceCodesToLabels(serviceCodes: string[]): string {
		const serviceCodesLabels = serviceCodes.map(param => {
      const mapping = ClaimTypeChoices?.find(i => i.value === param);
      return mapping ? mapping.display : param;
    });
    return serviceCodesLabels.join(', ');
  }
}
