import {
	Component,
	OnInit,
	OnDestroy,
	AfterViewInit,
	ViewChild,
	NgZone,
} from '@angular/core';
import { Subject, fromEvent, Observable, of } from 'rxjs';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import {
	takeUntil,
	debounceTime,
	map,
	mergeMap,
	finalize,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { request, comparer } from './activity.util';
import { RouteService, CommandRoute } from '../../../core/config/route.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BLStaffsQuery } from '../../../domain/bl-staff/bl-staffs.query';
import { ActivatedRoute } from '@angular/router';
import { ActivityViewModel } from '../../../shared/models/_general/activity.viewmodel';
import * as moment from 'moment';
import { ActivityModalComponent } from '../../../shared/modal/activity/activity-modal/activity-modal.component';
import {
	PutActivityParam,
	ActivityModel,
} from '../../../shared/models/_general/activity.model';
import { ActivityCancelModalComponent } from '../../../shared/modal/activity/activity-cancel-modal/activity-cancel-modal.component';
import {
	DeleteModalModel,
	DeleteModalComponent,
} from '../../../shared/modal/delete-modal/delete-modal.component';
import MomentUtil from '../../../util/moment.util';
import { ActivityStatus, Row } from './states/activity.model';
import { Metakey, linkColumn } from './activity-datatable.config';
import { ActivityStore } from './states/activity.store';
import { ActivityQuery } from './states/activity.query';
import { ActivityService } from './states/activity.service';
import { ActivityService as CoreActivityService } from '../../../core/services/activity/activity.service';
import { ActivityFormComponent } from './activity-form/activity-form.component';
import { produce } from 'immer';
import { LocalService } from 'src/app/core/services/local.service';
declare var $: any;

@Component({
	selector: 'app-activity',
	templateUrl: './activity.component.html',
	styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit, OnDestroy, AfterViewInit {
	private onDestroy$ = new Subject<void>();

	columns$ = this.query.tableColumns$;
	columnsWithLink$ = this.columns$.pipe(map((x) => [...x, linkColumn]));
	linkColumn = linkColumn;

	cellsLoading$ = this.query.cellsLoading$;
	cellsEditing$ = this.query.cellsEditing$;
	cellsTempvalue$ = this.query.cellsTempvalue$;
	rowsLoading$ = this.query.rowsLoading$;
	isSearching$ = this.query.isSearching$;

	rows$ =
		this.query.getValue().count <= 500
			? this.query.sortedRows$
			: this.query.rows$;
	hasRow$ = this.query.hasRows$;
	propSort$ = this.query.uiQuery.propSort$;
	sorts$ = this.query.sorts$;

	edit = this.service.edit;
	cancel = this.service.cancel;
	setTempValue = this.service.setTempValue;

	showSearchFields = false;
	tblHeight: SafeStyle;
	resizeEvent$ = fromEvent(window, 'resize');
	activityStatuses = ActivityStatus;

	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild(ActivityFormComponent) searchFormComponent: ActivityFormComponent;
	/** Activity Type choices */
	// Todo
	AT$: Observable<ViewDisplayValue[]> = this.service.AT$;
	AM$: Observable<ViewDisplayValue[]> = this.service.AM$;

	/** Adviser choices */
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	/** Adviser calendar choices */
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	/** complete advisers including deleted. */
	completeAdvisers$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allStaffsChoices$;
	/** faster lookup advisers */
	adviserChoicesAsObject$: Observable<{ [key: string]: string }> =
		this.blStaffsQuery.allStaffsChoicesLookup$;
	reorder = (reorderEvent: {
		column: TableColumn;
		newValue: number;
		prevValue: number;
	}) => {
		return this.service
			.reorderColumn(reorderEvent.prevValue, reorderEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	resize = (event: { column: TableColumn; newValue: number }) => {
		if (event && (!event.column || !event.newValue)) {
			return;
		}
		return this.service
			.resizeColumn(`${event.column.prop}`, event.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	sort(a: { sorts; column; prevValue; newValue }) {
		if (this.query.getValue().count <= 500) {
			return this.service?.sort(a.sorts[0].prop, a.sorts[0].dir); 
		}

		this.service
			.searchActivities(
				request(
					{
						Column:
							a.sorts[0].prop === 'AssignedToId'
								? 'AssignedTo'
								: a.sorts[0].prop,
						Direction: a.sorts[0].dir,
					},
					this.searchFormComponent.prepareFormValue(),
					1
				)
			)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.service?.sort(a.sorts[0].prop, a.sorts[0].dir);
			});
	}

	constructor(
		private service: ActivityService,
		private store: ActivityStore,
		private query: ActivityQuery,
		private routeService: RouteService,
		private sanitizer: DomSanitizer,
		private modalService: BsModalService,
		private activityService: CoreActivityService,
		private blStaffsQuery: BLStaffsQuery,
		private activitedRoute: ActivatedRoute,
		private ngZone: NgZone,
		private localService: LocalService
	) {}

	ngOnInit() {
		this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
			'calc(100vh - 141px)'
		);

		this.resizeEvent$
			.pipe(debounceTime(500), takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.service.reloadData();
			});

		this.activitedRoute.paramMap
			.pipe(
				map((x) => {
					if (x.has('activityId')) {
						this.editActivityModal(+x.get('activityId'));
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngAfterViewInit() {
		this.dragHeader();
	}
	rowIdentity = (row: Row) => row.ActivityId;

	route(activity: Row): CommandRoute {
		if (
			activity.IsAccessible &&
			!!activity.CustomerId &&
			activity.ActivityStatus.value === ActivityStatus.Pending
		) {
			if (activity.IsCompany) {
				return this.ngZone.run(() =>
					this.routeService.businessActivity(
						activity.CustomerId,
						activity.ActivityId
					)
				);
			}
			return this.ngZone.run(() =>
				this.routeService.customerActivity(
					activity.CustomerId,
					activity.ActivityId
				)
			);
		}

		if (
			activity.IsAccessible &&
			!!activity.CustomerId &&
			(activity.ActivityStatus.value === ActivityStatus.Completed ||
				activity.ActivityStatus.value === ActivityStatus.Cancelled)
		) {
			if (activity.IsCompany) {
				return this.ngZone.run(() =>
					this.routeService.businessView(activity.CustomerId)
				);
			}
			return this.ngZone.run(() =>
				this.routeService.customerView(activity.CustomerId)
			);
		}

		return null;
	}

	saveField = (activityId: number, metakey: Metakey, metadata: any) => {
		this.store.uiStore.setLoad(activityId, metakey, true);
		this.activityService
			.GetById(activityId)
			.pipe(
				map((x) =>
					ActivityViewModel.MapToEdit(ActivityViewModel.MapToViewModel(x))
				),
				mergeMap((x) =>
					this.service.saveField(
						{
							...x,
							[metakey === 'Assigned To'
								? 'Adviser'
								: metakey === 'Activity Type'
								? 'ActivityType'
								: metakey === 'Activity Name'
								? 'ActivityName'
								: metakey === 'Details'
								? 'Details'
								: metakey === 'Meeting'
								? 'Meeting'
								: 'DueDate']:
								metakey === 'Assigned To' ? +metadata.value : metadata.value,
						},
						metakey,
						metadata.value
					)
				),
				finalize(() => this.store.uiStore.setLoad(activityId, metakey, false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	saveDetails = (details: string) => {
		return of(details);
	};

	/** Open activity modal.
	 * * Fetch activity data for editing.
	 * * on saving, call `saveEditActivity()`
	 */
	editActivityModal(activityId: number) {
		this.store.uiStore.setIsEditing(activityId, true);
		this.activityService
			.GetById(activityId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(activity) => {
					if (activity) {
						const formItem = {
							...activity,
							DueDate: moment(activity.DueDate),
							Customer: activity.Client,
						};
						const initState: any = {
							formItem,
							AT$: this.AT$,
							AM$: this.AM$,
							adviserChoices$: this.adviserChoices$,
							adviserCalendarChoices$: this.adviserCalendarChoices$,
							header: 'Schedule Activity',
							hideClient: false,
							savefn: this.saveEditActivity,
							isModal: true,
							isRequiredDate: true,
							permissionsToComplete: ['FCOA'],
						};
						this.modalService.show(ActivityModalComponent, {
							class: `modal-dialog-centered ${
								this.localService.getValue('loginType') === 'microsoft'
									? 'modal-dialog-outlook-xl'
									: 'modal-xl'
							}`,
							initialState: initState,
							ignoreBackdropClick: true,
							keyboard: false,
						});
					}
				},
				() => {},
				() => this.store.uiStore.setIsEditing(activityId, false)
			);
	}

	/**
	 * Sends request to API to persist changes.
	 * Emits changes to put into state store.
	 */
	saveEditActivity = (ac: ActivityViewModel) =>
		new Observable<PutActivityParam>((obs) => {
			const model = ActivityViewModel.MapToEdit(ac);
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) => {
				return this.activityService.Put(x);
			}),
			mergeMap(() =>
				this.service.updateActivity(ActivityViewModel.MapToModel(ac)).pipe(
					tap(() => {
						setTimeout(() => {
							$('datatable-body').scrollTop(1);
							$('datatable-body').scrollLeft(1);
						}, 1);
						setTimeout(() => {
							$('datatable-body').scrollTop(0);
							$('datatable-body').scrollLeft(0);
						}, 1);
					})
				)
			)
		);

	complete(activityId: number) {
		this.store.uiStore.setIsEditing(activityId, true);
		this.activityService
			.GetById(activityId)
			.pipe(
				takeUntil(this.onDestroy$),
				mergeMap((x) => {
					return this.activityService.Put({
						ActivityId: x.ActivityId,
						ActivityName: x.ActivityName,
						ActivityType: x.ActivityType,
						Adviser: x.Adviser,
						Appointment: x.Appointment,
						CustomerId: x.Client ? x.Client.CustomerId : null,
						CustomerName: x.Client ? x.Client.Name : null,
						Details: x.Details,
						DueDate: x.DueDate,
						DueTime: x.DueTime,
						Duration: x.Duration,
						IsCompleted: true,
						Location: x.Location,
						IsActive: x.IsActive,
					});
				}),
				withLatestFrom(this.query.searchForm$),
				tap(([x, form]) => {
					if (
						form.ActivityStatus.IsCompleted ||
						(!form.ActivityStatus.IsCompleted &&
							!form.ActivityStatus.IsPending &&
							!form.ActivityStatus.IsCancelled)
					) {
						this.store.update(
							activityId,
							produce((draft) => {
								draft.ActivityStatus = {
									...draft.ActivityStatus,
									value: ActivityStatus.Completed,
								};
								draft.CompleteDate = {
									...draft.CompleteDate,
									value: MomentUtil.formatDateToServerDate(
										MomentUtil.createMomentNz()
									),
								};
							})
						);
					} else {
						this.store.remove(activityId);
					}
					this.store.uiStore.setIsCompleting(activityId, false);
				})
			)
			.subscribe(
				() => {
					this.store.uiStore.setIsEditing(activityId, false);
				},
				() => {
					this.store.uiStore.setIsEditing(activityId, false);
				},
				() => {
					this.store.uiStore.setIsEditing(activityId, false);
				}
			);
	}

	cancelActivity = (data: any) => {
		this.store.uiStore.setIsEditing(data.activity.ActivityId, true);
		return this.activityService.GetById(data.activity.ActivityId).pipe(
			takeUntil(this.onDestroy$),
			mergeMap((x) => {
				return this.activityService.Put({
					ActivityId: x.ActivityId,
					ActivityName: x.ActivityName,
					ActivityType: x.ActivityType,
					Adviser: x.Adviser,
					Appointment: x.Appointment,
					CustomerId: x.Client ? x.Client.CustomerId : null,
					CustomerName: x.Client ? x.Client.Name : null,
					Details: x.Details,
					DueDate: x.DueDate,
					DueTime: x.DueTime,
					Duration: x.Duration,
					IsCompleted: x.IsCompleted,
					IsCancelled: true,
					Reason: data.reason,
					Location: x.Location,
					IsActive: x.IsActive,
				});
			}),
			withLatestFrom(this.query.searchForm$),
			tap(([status, form]) => {
				if (
					form.ActivityStatus.IsCancelled ||
					(!form.ActivityStatus.IsCancelled &&
						!form.ActivityStatus.IsPending &&
						!form.ActivityStatus.IsCompleted)
				) {
					this.store.update(
						data.activity.ActivityId,
						produce((draft) => {
							draft.ActivityStatus = {
								...draft.ActivityStatus,
								value: ActivityStatus.Cancelled,
							};
							draft.CancelledDate = {
								...draft.CancelledDate,
								value: MomentUtil.formatDateToServerDate(
									MomentUtil.createMomentNz()
								),
							};
						})
					);
				} else {
					this.store.remove(data.activity.ActivityId);
				}
				this.store.uiStore.setIsCancelling(data.activity.ActivityId, false);
			}),
			finalize(() =>
				this.store.uiStore.setIsCancelling(data.activity.ActivityId, false)
			),
			map(([status, form]) => status)
		);
	};

	confirmCancelActivity = (activity: ActivityModel) => {
		new Observable((obs) => {
			const initState: any = {
				header: 'Cancel Activity',
				message: `Are you sure you want to cancel this activity?`,
				activity,
				savefn: this.cancelActivity,
			};
			this.modalService.show(ActivityCancelModalComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});

			obs.complete();
		})
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	deleteActivity = (ac: ActivityModel) =>
		this.service
			.deleteActivity(ac)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();

	confirmDeleteActivity = (ac: ActivityModel) =>
		new Observable((obs) => {
			const confirm = new Observable((confirmObs) => {
				this.deleteActivity(ac);
				confirmObs.complete();
			});
			const initState: DeleteModalModel = {
				delete$: confirm,
				header: 'Delete Activity',
				message: `Are you sure you want to delete this activity?`,
			};
			this.modalService.show(DeleteModalComponent, {
				class: 'modal-dialog-centered',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
			obs.complete();
		})
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();

	onToggleSearch(data) {
		this.showSearchFields = data.showSearch;
		if (this.showSearchFields && data.width > 1199) {
			this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
				`calc(100vh - ${104 + data.height}px)`
			);
		} else {
			this.tblHeight =
				this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - 141px)`);
		}
		setTimeout(() => {
			this.service.reloadData();
			document.body.style.overflowY = 'auto';
		}, 500);
	}

	onPage(event: any, indexes: any) {
		if (this.query.getValue().count <= 500) {
			return;
		}

		const calc = this.query.getCount() - 2;
		if (calc <= indexes.last) {
			this.loadMore();
		}
	}

	loadMore() {
		const sl = this.table.element.querySelector('.datatable-body').scrollLeft;
		const sh = this.table.bodyComponent.scrollHeight;
		this.query.searchForm$
			.pipe(
				withLatestFrom(this.query.isComplete$, this.query.isSearching$),
				map(([x, isComplete, isSearching]) => {
					const paging = {
						Column: x ? x.Paging.Column : '',
						Direction: x ? x.Paging.Direction : '',
					};
					const compare = comparer(
						request(paging, this.searchFormComponent.prepareFormValue(), 0),
						x
					);
					const currentIndex = x && x.Paging ? x.Paging.Index + 1 : 0;

					return {
						compare,
						isSearching,
						isComplete,
						paging,
						currentIndex,
					};
				}),
				mergeMap((x) => {
					if (x.compare && !x.isSearching && !x.isComplete) {
						return this.service.searchActivities(
							request(
								x.paging,
								this.searchFormComponent.prepareFormValue(),
								x.currentIndex
							)
						);
					}
					return of(null);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.table.element.querySelector('.datatable-body').scrollTop = sh;
				this.table.element.querySelector('.datatable-body').scrollLeft = sl;
			});
	}

	// Dragging column header
	dragHeader() {
		const _tbl = 'ngx-datatable';
		const _header = 'datatable-header';
		const _headerInner = 'datatable-header-inner';
		const _headerCell = 'datatable-header-cell';
		const _body = 'datatable-body';
		const _draggable = 'draggable';
		const _draggingState = 'dragging-state';

		const tblHeaderCell = `${_tbl} ${_header} ${_headerCell}`;
		const tblHeaderCellDrag = `${_tbl} ${_header} ${_headerCell} .${_draggable}`;
		const header = $(`${_tbl} ${_header}`);
		const tblBody = $(`${_tbl} ${_body}`);

		let isDragging = 0;
		let timer;
		let scrollTimer;
		let _self;
		let tranlateLeft;

		const scrollLeftHeader = (pageX, headerWidth) => {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			const innerHeader = header?.find(`.${_headerInner}`);
			const key = 'MSStream';
			const os = /Windows/.test(navigator.userAgent) && !window[key];
			const maxScroll =
				innerHeader[0].scrollWidth - tblBody.width() + (os ? 16 : 0);
			const isDraggingState = $(tblHeaderCell)
				.parent()
				.hasClass(`${_draggingState}`);

			// Check if the mouse is in the left edge of header while dragging
			if (pageX <= 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() > 0) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() - 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft -= 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the left edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
			// Check if the mouse is in the right edge of header while dragging
			if (pageX >= headerWidth - 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() <= maxScroll) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() + 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft += 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the right edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
		};

		$(document)
			.on('mousedown', tblHeaderCellDrag, function (e) {
				if (e.which !== 1) {
					return;
				}
				_self = $(this);
				tranlateLeft = 0;

				isDragging = 1;

				timer = setTimeout(() => {
					_self.closest(tblHeaderCell).parent().addClass(`${_draggingState}`);
				}, 500);
			})

			.on('mouseup', function () {
				if (timer) {
					clearTimeout(timer);
				}
				if (scrollTimer) {
					clearTimeout(scrollTimer);
					_self
						.closest(`${_headerCell}`)
						.css('transform', `translate3d(0px, 0px, 0px)`);
				}
				if (isDragging) {
					$(tblHeaderCell)
						.removeClass(`${_draggable}`)
						.parent()
						.removeClass(`${_draggingState}`);
					isDragging = 0;
				}
			})

			// Scroll header when dragging column into the edge of header
			.on('mousemove', function (e) {
				const headerWidth = header.width();
				const pageX = e.pageX - header.offset().left;
				scrollLeftHeader(pageX, headerWidth);
			});
	}

	ngOnDestroy() {
		$(document).off('mousedown mouseup mousemove');
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	convertDateToNZ(d: string) {
		const nzToday = MomentUtil.createMomentNz().format('LL');
		const splicedDate = moment(d.slice(0, 10)).format('DD/MM/YYYY');
		const date = moment(splicedDate, 'DD/MM/YYYY');
		return date.diff(nzToday, 'days');
	}
	getRowClass = (row: Row) => {
		if (row.DueDate.value) {
			const nd = this.convertDateToNZ(
				row.DueDate.value ? row.DueDate.value : ''
			);
			// If 0 meaning its today
			if (nd === 0) {
				return 'isDueDate';
			}
			// if negative meaning overdue
			if (nd < 0) {
				return 'isOverdue';
			}
			if (nd < 6) {
				return 'isDueNextFiveDays';
			}
		}
	};

	getCellClass(row: Row): string {
		const rowValue = row.DueDate.value;
		const nd = this.convertDateToNZ(rowValue);
		// If 0 meaning its today
		if (nd === 0) {
			return 'isDueDate';
		}
		// if negative meaning overdue
		if (nd < 0) {
			return 'isOverdue';
		}
		if (nd < 6) {
			return 'isDueNextFiveDays';
		}
	}
}
