import { Injectable } from '@angular/core';
import { EmailSettingsModel } from './email-settings.model';
import { EmailSettingsStore } from './email-settings.store';
import { EmailSettingsQuery } from './email-settings.query';
import { ApiService } from '../../../../core/base/api.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { tap, mergeMap, finalize, map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { Subject, of } from 'rxjs';
import { Metakey, columns } from '../email-settings-datatable.config';
import sort from 'fast-sort';

@Injectable()
export class EmailSettingsService {
	private onDestroy$ = new Subject<void>();
	status: any = [{}];

	constructor(
		private api: ApiService,
		private store: EmailSettingsStore,
		private query: EmailSettingsQuery,
		protected loggerService: LoggerService
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getEmailSettings() {
		this.store.setLoading(true);
		return this.api.get<EmailSettingsModel[]>('EmailSettings').pipe(
			tap(x =>
				applyTransaction(() => {
					const data = sort(x).asc(e => e.EmailType);
					this.store.set(data);
				})
			)
		);
	}

	saveField(req: { id: number; metakey: Metakey; metavalue: any }) {
		return of(req).pipe(
			tap(x => this.store.setLoad(x.id, x.metakey, true)),
			map(x => {
				const data = this.query.getEntity(x.id);
				return { ...data, [columns?.find(c => c.metakey === x.metakey).prop]: x.metavalue };
			}),
			mergeMap(x => this.api.put(`emailsettings/${x.EmailSettingsId}`, x)),
			tap(() =>
				applyTransaction(() => {
					this.store.setEdit(req.id, req.metakey, false);
					this.store.updateField(req.id, req.metakey, req.metavalue);
					this.setTempValue(req.id, req.metakey, undefined);
				})
			),
			finalize(() => {
				this.store.setLoad(req.id, req.metakey, false);
				this.reloadData();
			})
		);
	}

	saveIntervals(row: EmailSettingsModel, metakey: Metakey) {
		return of(row).pipe(
			tap(x => this.store.setLoad(x.EmailSettingsId, metakey, true)),
			mergeMap(x => this.api.put(`emailsettings/${x.EmailSettingsId}`, x)),
			tap(() =>
				applyTransaction(() => {
					this.store.setEdit(row.EmailSettingsId, metakey, false);
					this.store.update(row.EmailSettingsId, row);
				})
			),
			finalize(() => {
				this.store.setLoad(row.EmailSettingsId, metakey, false);
				this.reloadData();
			})
		);
	}

	edit = (id: number, metakey: Metakey) => this.store.setEdit(id, metakey, true);
	cancel = (id: number, metakey: Metakey) =>
		applyTransaction(() => {
			this.store.setEdit(id, metakey, false);
			this.setTempValue(id, metakey, undefined);
		});

	setTempValue = (id: number, metakey: string, value: any) => this.store.setTempValue(id, metakey, value);

	reloadData() {
		const data = this.query.getAll();
		this.store.set([...data]);
	}
}
