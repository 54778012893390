import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrmComponent } from './crm.component';
import { LRCrtGuard } from '../crm/guards/lr-crt.guard';
import { loatCanActivate } from './loat/loat.guard';

const routes: Routes = [
	{
		path: '',
		component: CrmComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'dashboard',
			},
			{
				path: 'lead/search',
				data: {
					breadcrumb: 'Lead Search',
					isLead: true,
				},
				loadChildren: () =>
					import('../../modules/crm/lead-search/lead-search.module').then(
						(m) => m.LeadSearchModule
					),
			},
			{
				path: 'lead/client',
				data: {
					breadcrumb: 'Lead: Add New Person',
					ignoreChildBreadcrumb: true, // Child/Children data breadcumb will be ignored
					isLead: true,
				},
				loadChildren: () =>
					import('../../modules/crm/client-profile/client-profile.module').then(
						(m) => m.ClientProfileModule
					),
			},
			{
				path: 'lead/business',
				data: {
					breadcrumb: 'Lead: Add New Business',
					ignoreChildBreadcrumb: true, // Child/Children breadcrumb will be ignored
					isLead: true,
				},
				loadChildren: () =>
					import(
						'../../modules/crm/business-profile/business-profile.module'
					).then((m) => m.BusinessProfileModule),
			},
			{
				path: 'client/search',
				data: {
					breadcrumb: 'Client Search',
				},
				loadChildren: () =>
					import('../../modules/crm/client-search/client-search.module').then(
						(m) => m.ClientSearchModule
					),
			},
			{
				path: 'dashboard',
				data: {
					breadcrumb: 'Dashboard',
				},
				loadChildren: () =>
					import('../../modules/crm/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
			},
			{
				path: 'client',
				data: {
					breadcrumb: 'Client',
				},
				loadChildren: () =>
					import('../../modules/crm/client-profile/client-profile.module').then(
						(m) => m.ClientProfileModule
					),
			},
			{
				path: 'business',
				data: {
					breadcrumb: 'Business',
				},
				loadChildren: () =>
					import(
						'../../modules/crm/business-profile/business-profile.module'
					).then((m) => m.BusinessProfileModule),
			},
			{
				path: 'pipeline',
				loadChildren: () =>
					import('../crm/pipeline/pipeline.module').then(
						(m) => m.PipelineModule
					),
			},
			{
				path: 'activity',
				data: {
					breadcrumb: 'Activities',
				},
				loadChildren: () =>
					import('../crm/activity/activity.module').then(
						(m) => m.ActivityModule
					),
			},
			{
				path: 'document',
				data: {
					breadcrumb: 'Documents',
				},
				loadChildren: () =>
					import('../../modules/crm/document/document.module').then(
						(m) => m.DocumentModule
					),
			},
			{
				path: 'report',
				data: {
					breadcrumb: 'Reports',
				},
				loadChildren: () =>
					import('../../modules/crm/report/report.module').then(
						(m) => m.ReportModule
					),
			},
			{
				path: 'crt/:customerType/:clientId/advice-process/:adviceProcessId',
				canActivate: [loatCanActivate, LRCrtGuard],
				data: {
					breadcrumb: 'Client Review Template',
					routeVersion: 'v1',
				},
				loadChildren: () =>
					import(
						'../../modules/crm/client-review-template/client-review-template.module'
					).then((m) => m.ClientReviewTemplateModule),
			},
			{
				path: 'loat-v2/:customerType/:clientId/advice-process/:adviceProcessId',
				canActivate: [loatCanActivate, LRCrtGuard],
				data: {
					breadcrumb: 'Client Review Template',
					routeVersion: 'v2',
				},
				loadChildren: () =>
					import('../../modules/crm/loat/loat.module').then(
						(m) => m.LoatModule
					),
			},
			{
				path: 'car/:customerType/:clientId/advice-process/:adviceProcessId',
				data: {
					breadcumb: 'Client Alteration Request',
				},
				canActivate: [LRCrtGuard],
				loadChildren: () =>
					import(
						'./client-alteration-request/client-alteration-request.module'
					).then((m) => m.ClientAlterationRequestModule),
			},
			{
				path: 'crt-page',
				data: {
					breadcrumb: 'Client Review Template',
				},
				loadChildren: () =>
					import('../../modules/crm/crt-page/crt-page.module').then(
						(m) => m.CrtPageModule
					),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CrmRoutingModule {}
