import { BodyMeasureState } from './measures.model';

export class BodyMeasureMapper {
	/** Mapping for view */
	static mapToView(data: BodyMeasureState) {
		if (!data) {
			return { ...data };
		}
		const val = {
			...data
		};
		return val;
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: BodyMeasureState, personInfo: BodyMeasureState, height: string, weight: string): BodyMeasureState {
		return {
			...data,
			adviceProcessId: personInfo?.adviceProcessId,
			heightOptions: height,
			weightOptions: weight,
			cRTId: personInfo?.cRTId || data?.cRTId,
			sectionCode: 'FMS',
			state: 1
		};
	}
}
