<form novalidate [formGroup]="form" class="filter-form">
	<div class="clearfix">
		<div class="row d-flex justify-content-between">
			<div class="col-md-12 col-lg-auto align-self-center mb-3 mb-lg-0">
				<h1 class="d-inline tap-text-secondary tap-text-h2">
					L&R Insurance Pipeline
				</h1>
				<h6
					class="mb-0 px-0 px-md-2 d-inline tap-text-secondary d-block d-md-inline-block"
				>
					{{ count$ | async | number }} Results
					<span class="px-2"
						>Total API: ${{ totalApi$ | async | number : "1.2-2" }}</span
					>
				</h6>
			</div>

			<div class="col-md-12 col-lg-auto align-self-center ml-auto">
				<div class="form-row align-items-center justify-content-end">
					<div class="col-auto">
						<button
							id="toggleFilterBtn"
							type="button"
							title="Filter"
							class="icon-btn font-weight-bold"
							(click)="collapse.Toggle(); toggle()"
						>
							<i class="material-icons md-20 tap-text-color-h6 pt-1">
								filter_list
							</i>
						</button>
					</div>
					<div class="col-auto">
						<button
							id="resetFilterBtn"
							type="button"
							class="icon-btn"
							(click)="reset()"
						>
							<i class="fas fa-undo fa-14 tap-text-color-h6 pt-1"></i>
						</button>
					</div>
					<ng-container *appRestrictTo="['FSP']">
						<div class="col-auto">
							<button
								id="searchBtn"
								type="submit"
								class="tap-btn tap-btn--default font-weight-bold px-lg-4"
								(click)="search()"
								[disabled]="isSearching$ | async"
							>
								Search
							</button>
						</div>
					</ng-container>
					<ng-container *appRestrictTo="['FEP']">
						<div
							class="col-auto"
							(mouseenter)="showPopover()"
							[placement]="'bottom'"
							[popover]="(exportStatus$ | async) === 3 ? popTemplate : null"
							containerClass="popover-info-container"
							triggers=""
						>
							<button
								id="exportBtn"
								type="button"
								title="Export"
								class="icon-btn"
								(click)="export()"
								[disabled]="
									(isExporting$ | async) || (exportStatus$ | async) === 3
								"
								data-toggle="modal"
								data-target="#lrExportModal"
							>
								<i class="fas fa-download fa-14 tap-text-color-h6 pt-1"></i>
							</button>
						</div>
					</ng-container>
					<div class="col-auto">
						<app-manual-loader-mini
							[isLoading]="(isSearching$ | async) || (isExporting$ | async)"
						>
						</app-manual-loader-mini>
					</div>
					<div class="col-auto">
						<span class="position-relative d-inline-block">
							<app-lr-insurance-columns-form
								*ngIf="columnFromOpen$ | async"
								class="search-settings"
							>
							</app-lr-insurance-columns-form>
							<button
								id="settingsBtn"
								type="button"
								title="Settings"
								class="icon-btn"
								(click)="openColumnPopup()"
							>
								<i class="material-icons md-20 column-settings-icon pt-1">
									settings
								</i>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		app-collapse
		#collapse
		[initialExpandedState]="false"
		class="search-collapse"
	>
		<div class="container-fluid ml-0 px-0 pt-3">
			<div class="row">
				<!-- 1st column -->
				<div class="col-12 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="adviserId"
								formControlName="SelectedAdvisers"
								[choices]="availableStaffChoices$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Policy Status</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="policyStatusId"
								formControlName="SelectedPolicyStatus"
								[choices]="LRPS$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Provider</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="providerId"
								formControlName="SelectedInsuranceProviders"
								[choices]="LRP$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<!-- 2nd column -->
				<div class="col-12 col-lg-5">
					<div class="row align-items-center">
						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Policy Type</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="policyTypeId"
										formControlName="SelectedInsuranceTypes"
										[choices]="LRPT$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Origin</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadOriginId"
										formControlName="SelectedLeadOrigins"
										[choices]="PCLE$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div
							class="col-12 col-lg-4"
							*featureToggle="{
								id: 'AdviserRework',
								show: false
							}"
						>
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Alt Adviser</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="SelectedAltAdvisersId"
										formControlName="SelectedAltAdvisers"
										[choices]="availableStaffChoices$ | async"
									>
									</app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Claim Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="claimStatusId"
										formControlName="SelectedClaimStatus"
										[choices]="LRCS$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Type</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadTypeId"
										formControlName="SelectedLeadTypes"
										[choices]="PCLT$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Adviser Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="adviserStatusId"
										formControlName="SelectedAdviserStatuses"
										[choices]="AdviserStatus$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Retention Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="retentionStatusId"
										formControlName="SelectedRetentionStatus"
										[choices]="LRRS$ | async"
									></app-chips>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 4th column -->
				<div class="col-12 col-lg-4 d-flex flex-column justify-content-between">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Activity Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="nextActivityDateMinId"
									class="col"
									formControlName="NextActivityDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-1">to</div>
								<app-date-input
									textboxId="nextActivityDateMaxId"
									class="col"
									formControlName="NextActivityDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Policy Start Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="policyStartDateMinId"
									class="col"
									formControlName="PolicyStartDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-1">to</div>
								<app-date-input
									textboxId="policyStartDateMaxId"
									class="col"
									formControlName="PolicyStartDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Review Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="nextReviewDateMinId"
									class="col"
									formControlName="NextReviewDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-1">to</div>
								<app-date-input
									textboxId="nextReviewDateMaxId"
									class="col"
									formControlName="NextReviewDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Submitted Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="submittedDateMinId"
									class="col"
									formControlName="SubmittedDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-1">to</div>
								<app-date-input
									textboxId="submittedDateMaxId"
									class="col"
									formControlName="SubmittedDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!-- Modal -->
<ng-template #exportTemplate>
	<app-export-progress
		[progress]="progress"
		[percent]="percent"
		[msg]="msg"
	></app-export-progress>
</ng-template>
<!-- End Modal -->

<ng-template #popTemplate>
	<div class="popover-info">
		<p>Export currently in progress, please wait until its completion</p>
	</div>
</ng-template>
