import MomentUtil from '../../../util/moment.util';
import { KiwisaverState } from '../../models/services/kiwisaver/kiwisaver.model';

/** Policy match
 * @param a
 * @param b
 * @returns {number}
 */
export const statusMatch = (regExp: RegExp, str: string): boolean => {
  const match = str?.match(regExp);
  return match && str === match[0];
};

/** Policy status sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const statusSorter = (a: string, b: string, type: string): number => {
  const matchA = statusMatch(new RegExp(type), a ? a?.toLowerCase() : '');
  const matchB = statusMatch(new RegExp(type), b ? b?.toLowerCase() : '');

  if ((matchA)
    < (matchB)) {
    return 1;
  } else if ((matchA)
    > (matchB)) {
    return -1;
  } else {
    return 0;
  }
};

/** Policy status group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const statusGroupSorter = (a: string, b: string): number => {
  return statusSorter(a, b, 'active') ||
		statusSorter(a, b, 'active-pending') ||
    statusSorter(a, b, 'existing') ||
    statusSorter(a, b, 'existing ks') ||
    statusSorter(a, b, 'lead') ||
    statusSorter(a, b, 'submitted') ||
    statusSorter(a, b, 'info required') ||
    statusSorter(a, b, 'ntu') ||
    statusSorter(a, b, 'cancelled');
};

/********************* End of status sorter *********************/


/********************* Date sorter *********************/
export const dateGroupSorter = (a, b) => {
  const convertA = a ? MomentUtil.formatDateToMoment(a).unix() : null;
  const convertB = b ? MomentUtil.formatDateToMoment(b).unix() : null;
  return +(convertA === null) - +(convertB === null) || +(convertA > convertB) || -(convertA < convertB);
};
/********************* End of Date sorter *********************/

/********************* Kiwisaver IsActive sorter *********************/
/**isActive Sorter 
 * * @param a
 * @param b
 * @returns {number}
*/
export const kiwiIsActiveSorter = (a:number,b:number):number => {
	if(a===2&&b!==2)	return 1;
	if(a!==2&&b==2)	return -1;
	return 0;
}
/********************* End of isActive sorter *********************/

/** Main Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mainGroupSorter = (a: KiwisaverState, b: KiwisaverState): number => {
  // Sort by Status
  // Sort by start date
  return kiwiIsActiveSorter(a.isActive, b.isActive)
    || statusGroupSorter(a.status, b.status)
    || dateGroupSorter(a.startDate, b.startDate);
};
