import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import * as moment from 'moment';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, mapTo, take, tap } from 'rxjs/operators';
import { BusinessService } from 'src/app/core/business/business.service';
import { CompanyState } from 'src/app/shared/models/client-review-template/company/company.model';
import { TrustState } from 'src/app/shared/models/client-review-template/trust/trust.model';
import { ApiService } from '../../../../../core/base/api.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { DependentDetailsState } from '../../../../../shared/models/client-review-template/dependent/dependent-details';
import {
	Dependent,
	DependentState,
} from '../../../../../shared/models/client-review-template/dependent/dependent.model';
import {
	LOATPeopleDetailsState,
	PeopleDetails,
} from '../../../../../shared/models/client-review-template/people/people-details.model';
import {
	People,
	PeopleState,
} from '../../../../../shared/models/client-review-template/people/people.model';
import { TrustDetailsState } from '../../../../../shared/models/client-review-template/trust/trust-details';
import { objectUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';

export const BLANK_PEOPLE_ID = -1;

export interface PeopleEntities {
	People?: PeopleState[];
	Dependents?: DependentState[];
	Trust?: TrustState[];
	Company?: CompanyState[];
}

@Injectable()
export class PeopleService
	extends ClientReviewTemplateService
	implements Resolve<boolean>
{
	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getPersonInfo(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<PeopleDetails>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => EMPTY)
		);
	}

	getPeopleEntities(
		adviceProcessId: number,
		showLoader = true
	): Observable<PeopleEntities> {
		if (showLoader) {
			this.store.setPeopleIsLoading(true);
			this.store.setDependentsIsLoading(true);
			this.store.setTrustsIsLoading(true);
			this.store.setCompanyIsLoading(true);
		}
		const endpoint = `crt/fact-find/${adviceProcessId}/group/PE`;
		return this.api.get<PeopleEntities>(endpoint).pipe(
			tap((result) => {
				const mapEntities = <T>(items: T[]) => {
					return items?.length
						? (items?.map(objectUtil.mapPascalCaseToCamelCase) as T[])
						: [];
				};
				// set people state
				this.store.setPeople(mapEntities(result?.People));
				// set dependents state
				this.store.setDependents(mapEntities(result.Dependents));
				// set trust state
				this.store.setTrust(mapEntities(result.Trust));
				// set company state
				this.store.setCompany(mapEntities(result?.Company));
			}),
			finalize(() => {
				this.store.setPeopleIsLoading(false);
				this.store.setDependentsIsLoading(false);
				this.store.setTrustsIsLoading(false);
				this.store.setCompanyIsLoading(false);
			})
		);
	}

	getPeople(adviceProcessId: number, sectionCode: string, noLoaders?: boolean) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		if (!noLoaders) {
			this.store.setPeopleIsLoading(true);
		}
		return this.api.get<People[]>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? (x?.map(objectUtil.mapPascalCaseToCamelCase) as PeopleState[])
						: [];
					this.store.setPeople(state);
				})
			),
			catchError(() => of(undefined)),
			finalize(() => this.store.setPeopleIsLoading(false))
		);
	}

	addPeople(people: LOATPeopleDetailsState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(people);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = people?.dateOfBirth
						? moment().diff(people.dateOfBirth, 'years')
						: null;
					const data = [
						...this.query.getValue().people,
						{
							customerId: people.customerID,
							age: people.dateOfBirth ? (age < 0 ? 0 : age) : null,
							birthDate: people.dateOfBirth,
							cRTId: x,
							customerType: people.customerType,
							name:
								people.firstName && people.lastName
									? people.firstName?.concat(' ', people.lastName)
									: null,
							relationship: people.relationship,
						},
					].filter((val) => val.cRTId && val.cRTId !== BLANK_PEOPLE_ID);
					this.store.setPeople(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updatePeople(people: LOATPeopleDetailsState) {
		const endpoint = `crt/${people.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(people);
		return this.api.put<PeopleDetails>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = people?.dateOfBirth
						? moment().diff(people.dateOfBirth, 'years')
						: null;
					const data = this.query.getValue().people?.map((y) =>
						y.cRTId === people.cRTId
							? {
									customerId: people.customerID,
									age: people.dateOfBirth ? (age < 0 ? 0 : age) : null,
									birthDate: people.dateOfBirth,
									cRTId: people.cRTId,
									customerType: people.customerType,
									name:
										people.firstName && people.lastName
											? people.firstName?.concat(' ', people.lastName)
											: null,
									relationship: people.relationship,
							  }
							: y
					);
					this.store.setPeople(data);
				})
			),
			tap(() => {
				const customerID = this.query.getValue()?.primaryClient?.customerID;
				this.getPrimaryClient(customerID).pipe(take(1)).subscribe();
				this.getSecondaryClients(customerID).pipe(take(1)).subscribe();
				this.getLinkedContacts(customerID).pipe(take(1)).subscribe();
			}),
			catchError((err) => {
				return EMPTY;
			})
		);
	}

	updatePeopleInfo(people: LOATPeopleDetailsState) {
		applyTransaction(() => {
			const data = this.query.getValue().people?.map((y) => {
				const age = people?.dateOfBirth
					? moment().diff(people.dateOfBirth, 'years')
					: null;
				return y.cRTId === people.cRTId
					? {
							customerId: people.customerID,
							age: people.dateOfBirth ? (age < 0 ? 0 : age) : null,
							birthDate: people.dateOfBirth,
							cRTId: people.cRTId,
							customerType: people.customerType,
							name:
								people.firstName && people.lastName
									? people.firstName?.concat(' ', people.lastName)
									: null,
							relationship: people.relationship,
					  }
					: y;
			});
			this.store.setPeople(data);
		});
	}

	deletePeople(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<PeopleDetails>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.people?.filter((y) => y.cRTId !== id);
					this.store.setPeople(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	// If Deletion from People, Dependants, Company or Trust occur, set loading status for refetching
	setRefetchingAfterDelete(status: boolean) {
		this.store.setLoading(status);
	}

	addBlankPeople() {
		const people = this.query.getValue().people;
		const state = people
			? (people?.map(objectUtil.mapPascalCaseToCamelCase) as PeopleState[])
			: [];
		state.push({
			cRTId: BLANK_PEOPLE_ID,
			age: 0,
			birthDate: '',
			customerType: '',
			name: '',
			relationship: '',
			customerId: 0,
		});
		this.store.setPeople(state);
	}

	addBlankDependent() {
		const dependents = this.query.getValue().dependents;
		const state = dependents
			? (dependents?.map(
					objectUtil.mapPascalCaseToCamelCase
			  ) as DependentState[])
			: [];
		state.push({
			cRTId: 0,
			age: 0,
			birthDate: '',
			customerType: '',
			name: '',
			relationship: '',
			customerId: 0,
		});
		this.store.setDependents(state);
	}

	addBlankCompany() {
		const companies = this.query.getValue().company;
		const state = companies
			? (companies?.map(objectUtil.mapPascalCaseToCamelCase) as CompanyState[])
			: [];

		const newState = [
			...state,
			{
				cRTId: 0,
				age: 0,
				birthDate: '',
				customerType: '',
				name: '',
				relationship: '',
				customerId: 0,
				industry: '',
			},
		];

		this.store.setCompany(newState);
	}

	addBlankTrust() {
		const trusts = this.query.getValue().trusts;
		const state = trusts
			? (trusts?.map(objectUtil.mapPascalCaseToCamelCase) as TrustState[])
			: [];

		const newState = [
			...state,
			{
				cRTId: 0,
				age: 0,
				birthDate: '',
				customerType: '',
				name: '',
				relationship: '',
				customerId: 0,
			},
		];

		this.store.setTrust(newState);
	}

	getDependents(
		adviceProcessId: number,
		sectionCode: string,
		forceFetch?: boolean,
		noLoaders?: boolean
	) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		if (!noLoaders) {
			this.store.setDependentsIsLoading(true);
		}
		return !forceFetch && !!this.query.getValue().dependents
			? of(undefined).pipe(
					finalize(() => this.store.setDependentsIsLoading(false))
			  )
			: this.api.get<Dependent[]>(endpoint).pipe(
					tap((x) =>
						applyTransaction(() => {
							const state = x
								? (x?.map(
										objectUtil.mapPascalCaseToCamelCase
								  ) as DependentState[])
								: [];
							this.store.setDependents(state);
						})
					),
					catchError(() => of(undefined)),
					finalize(() => this.store.setDependentsIsLoading(false))
			  );
	}

	setDependentsIsLoading(value) {
		this.store.setDependentsIsLoading(value);
	}

	addDependent(dependent: DependentDetailsState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(dependent);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = dependent?.dateOfBirth
						? moment().diff(dependent.dateOfBirth, 'years')
						: null;
					const data = [
						...this.query.getValue().dependents,
						{
							customerId: dependent.customerID,
							age: dependent.dateOfBirth ? (age < 0 ? 0 : age) : null,
							birthDate: dependent.dateOfBirth,
							cRTId: +x,
							name:
								dependent.firstName && dependent.lastName
									? dependent.firstName?.concat(' ', dependent.lastName)
									: null,
							relationship: dependent.relationship,
						},
					].filter((val) => val.cRTId && val.cRTId !== 0) as DependentState[];
					this.store.setDependents(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteDependent(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<string>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.dependents?.filter((y) => y.cRTId !== id);
					this.store.setDependents(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateDependent(dependent: DependentDetailsState) {
		const endpoint = `crt/${dependent.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(dependent);
		return this.api.put<DependentDetailsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = dependent?.dateOfBirth
						? moment().diff(dependent.dateOfBirth, 'years')
						: null;
					const data = this.query.getValue().dependents?.map((y) =>
						y.cRTId === dependent.cRTId
							? {
									customerId: dependent.customerID,
									age: dependent.dateOfBirth ? (age < 0 ? 0 : age) : null,
									birthDate: dependent.dateOfBirth,
									cRTId: dependent.cRTId,
									name:
										dependent.firstName && dependent.lastName
											? dependent.firstName?.concat(' ', dependent.lastName)
											: null,
									relationship: dependent.relationship,
							  }
							: y
					) as DependentState[];
					this.store.setDependents(data);
					this.getSecondaryClients(
						this.query.getValue().primaryClient.customerID
					)
						.pipe(take(1))
						.subscribe();
				})
			),
			catchError(() => EMPTY)
		);
	}

	getTrusts(
		adviceProcessId: number,
		sectionCode: string,
		forceFetch?: boolean
	) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		this.store.setTrustsIsLoading(true);
		return !forceFetch && !!this.query.getValue().trusts
			? of(undefined)
			: this.api.get<any>(endpoint).pipe(
					tap((x) =>
						applyTransaction(() => {
							const state = x
								? (x?.map(objectUtil.mapPascalCaseToCamelCase) as any)
								: [];
							this.store.setTrust(state);
						})
					),
					catchError(() => of(undefined)),
					finalize(() => this.store.setTrustsIsLoading(false))
			  );
	}

	addTrust(trust: any, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(trust);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().trusts,
						{
							customerId: trust.customerID,
							cRTId: +x,
							name: trust.trustName,
						},
					]?.filter((val) => val.cRTId && val.cRTId !== 0) as TrustState[];
					this.store.setTrust(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateTrust(trust: TrustDetailsState) {
		const endpoint = `crt/${trust.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(trust);
		return this.api.put<TrustDetailsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().trusts?.map((y) =>
						y.cRTId === trust.cRTId
							? {
									customerId: trust.customerID,
									age: null,
									birthDate: null,
									cRTId: trust.cRTId,
									name: trust.trustName,
							  }
							: y
					) as TrustState[];
					this.store.setTrust(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteTrust(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<string>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.trusts?.filter((y) => y.cRTId !== id);
					this.store.setTrust(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getCompany(
		adviceProcessId: number,
		sectionCode: string,
		forceFetch?: boolean
	) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		this.store.setCompanyIsLoading(true);
		return !forceFetch && !!this.query.getValue().company
			? of(undefined)
			: this.api.get<any>(endpoint).pipe(
					tap((x) =>
						applyTransaction(() => {
							const state = x
								? (x?.map(objectUtil.mapPascalCaseToCamelCase) as any)
								: [];
							this.store.setCompany(state);
						})
					),
					catchError(() => EMPTY),
					finalize(() => this.store.setCompanyIsLoading(false))
			  );
	}

	addCompany(company: any, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(company);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().company,
						{
							customerId: company.customerID,
							cRTId: +x,
							name: company.businessName,
							tradingName: company.tradingName,
							industry: company.industry,
						},
					].filter((val) => val.cRTId && val.cRTId !== 0) as CompanyState[];
					this.store.setCompany(data);
					this.getSecondaryCompanies(
						this.query.getValue().primaryClient.customerID
					)
						.pipe(take(1))
						.subscribe();
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateCompany(company: any) {
		const endpoint = `crt/${company.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(company);
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().company?.map((y) =>
						y.cRTId === company.cRTId
							? {
									customerId: company.customerID,
									cRTId: company.cRTId,
									name: company.businessName,
									tradingName: company.tradingName,
									industry: company.industry,
							  }
							: y
					) as CompanyState[];
					this.store.setCompany(data);
					this.getSecondaryCompanies(
						this.query.getValue().primaryClient.customerID
					)
						.pipe(take(1))
						.subscribe();
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteCompany(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<string>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.company?.filter((y) => y.cRTId !== id);
					this.store.setCompany(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		const dependents$ = this.getDependents(
			this.query.getValue().adviceProcessId,
			'FPD'
		);
		return forkJoin([dependents$]).pipe(mapTo(true));
	}
}
