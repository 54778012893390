<div
	class="container-fluid tap-client-profile clearfix"
	[class.tap-client-profile--collapsed]="isCollapsed"
	[class.is-mobile-mode]="isMobileMode"
>
	<div class="row position-relative">
		<div class="col tap-client-profile__main-content py-3 pr-3">
			<div class="tap-client-profile__main-content__inner">
				<div class="row">
					<div class="col">
						<app-client-profile-group
							class="d-block"
							[addMode]="false"
							[isEdit]="false"
							[isLead]="isLead$ | async"
							[data]="primaryClient$ | async"
							[advisers]="advisers$ | async"
							[allStaffChoices]="allStaffChoices$ | async"
							[altAdvisers]="altAdviserChoices$ | async"
							[leadGens]="leadGens$ | async"
							[PCT]="PCT$ | async"
							[PCE]="PCE$ | async"
							[PCPC]="PCPC$ | async"
							[PCLE]="PCLE$ | async"
							[PCLT]="PCLT$ | async"
							[PCR]="PCR$ | async"
							[LS]="LS$ | async"
							[LT]="LT$ | async"
							[SAS]="SAS$ | async"
							[preferredEmailContacts]="PreferredEmailContacts$ | async"
							[criterias]="currentActivityCriterias$ | async"
							[allStaff]="allStaff$ | async"
							(selectServiceTab)="selectTab($event)"
						>
						</app-client-profile-group>
					</div>
				</div>
				<div class="row">
					<div class="col px-0">
						<app-client-profile-tabs
							class="d-block"
							[addMode]="false"
							[isLead]="isLead$ | async"
							[primaryClient]="primaryClient$ | async"
						></app-client-profile-tabs>
					</div>
				</div>
			</div>
		</div>
		<div
			#activityTimeline
			class="col tap-client-profile__activity-timeline"
			[class.tap-client-profile__activity-timeline--collapsed]="isCollapsed"
			[class.is-mobile-mode]="isMobileMode"
		>
			<app-activity-timeline
				#activityTimelineComponent
				[class.d-none]="isCollapsed"
				[isLead]="isLead$ | async"
				[customerId]="customerID$ | async"
				[customerName]="fullName$ | async"
				[activityType$]="activityType$"
				[activityMeeting$]="activityMeeting$"
				[adviserChoices$]="allActiveStaffs$"
				[adviserCalendarChoices$]="adviserCalendarChoices$"
				[pendingActivities]="pendingActivities$ | async"
				[allCompletedNotes]="allCompletedNotes$ | async"
				[completedActivities]="completedActivities$ | async"
				[completedNotes]="completedNotes$ | async"
				[addNoteFn$]="addActivityNote$"
				[deleteNoteFn$]="deleteActivityNote$"
				[addActivityFn$]="addActivity$"
				[updateActivityFn$]="updateActivity$"
				[cancelActivityFn$]="cancelActivity$"
				[deleteActivityFn$]="deleteActivity$"
				[addPhoneCallFn$]="addPhoneCall$"
				[location]="(primaryClient$ | async)?.physicalAddress"
				[pinNoteFn$]="pinNoteFn$"
				(deleteNoteEvent)="deleteNoteEvent($event)"
			></app-activity-timeline>
		</div>
		<span
			class="tap-btn-collapse-expand material-icons"
			(click)="collapseProfile()"
		>
			{{ isCollapsed ? 'chevron_left' : isMobileMode ? 'close' : 'chevron_right'
			}}
		</span>
	</div>
</div>

<app-client-referral-email-modal
	#modal
	[documentInfo]="documentInfo"
	[documnetBodyLoaderFn$]="documnetBodyLoaderFn$"
	[isSettings]="false"
	[allowDocumentLinking]="true"
>
</app-client-referral-email-modal>
