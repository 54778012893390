import { Injectable } from '@angular/core';
import { GlobalSearchService } from '../../../core/customer/global-search/global-search.service';
import { ConfigService } from '../../../core/config/config.service';
import { GlobalSearchResult, GlobalSearchResultService, GlobalSearch } from '../../../shared/models/_general/global-search.model';
import { RouteService, CommandRoute } from '../../../core/config/route.service';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { mergeMap, withLatestFrom, tap, map } from 'rxjs/operators';
import * as R from 'ramda';
import { staticConf } from '../../../core/config/static-config.service';

@Injectable()
export class GlobalSearchComponentService {
  public readonly companyCode$: Observable<string>
  searchResult$: Observable<ViewGlobalSearch[]>;
  private searchResultSubj: BehaviorSubject<ViewGlobalSearch[]>;

  constructor(
    private gsService: GlobalSearchService,
    private configService: ConfigService,
    private routeService: RouteService
  ) {
    this.companyCode$ = this.configService.CompanyCode;

    this.searchResultSubj = new BehaviorSubject<ViewGlobalSearch[]>([]);
    this.searchResult$ = this.searchResultSubj.asObservable();
  }
  search(keyword: string): Observable<ViewGlobalSearch[]> {
    return of(keyword).pipe(
      withLatestFrom(this.companyCode$),
      mergeMap(([key, ccode]) => this.gsService.globalSearch(key, ccode)),
      map(x => {
        const vm = x?.map(y =>
          ViewGlobalSearch.MapFromModel(
            y,
            this.routeService.customerView,
            this.routeService.businessEdit,
            // LR
            this.routeService.customerLrCustService,
            this.routeService.businessLrCustService,
            // M
            this.routeService.customerMCustService,
            this.routeService.businessMCustService,
            // FG
            this.routeService.customerFgCustService,
            this.routeService.businessFgCustService,
            // K
            this.routeService.customerKCustService,
            this.routeService.businessKCustService
          )
        );
        return vm;
      }),
      tap(x => this.searchResultSubj.next(x))
    );
  }
}
type serviceList = { name: string; policies: string[]; policiesConcat: string };
const createServiceList = R.curry((code: string, policies: string[]): serviceList => ({
  name: code,
  policies,
  policiesConcat: R.join(', ', policies)
}));

const hasPolicies = R.propSatisfies(R.complement(R.isEmpty), 'policies' as keyof serviceList)
export class ViewGlobalSearchResult {
  name: string;
  services: serviceList[];
  link: CommandRoute;

  static MapFromModel(
    model: GlobalSearchResult,
    clientLinkFn: (id) => CommandRoute, businessLinkFn: (id) => CommandRoute): ViewGlobalSearchResult {
    const codes = [
      staticConf.lrServiceCode,
      staticConf.mortgageServiceCode,
      staticConf.fgServiceCode,
      staticConf.kiwisaverServiceCode
    ];

    const filterServiceListByCode = createServiceListFilter(model.Services);
    const groupedServices = R.filter(hasPolicies, R.map(filterServiceListByCode, codes));
    const primaryId = model.PrimaryCustomerId ? model.PrimaryCustomerId : model.CustomerId;

    return {
      name: model.Name,
      services: [],
      link: model.IsCompany ? businessLinkFn(primaryId) : clientLinkFn(primaryId)
    };
  }
}

const isServiceOfType = (code: string) => R.whereEq({ ServiceCode: code } as Pick<GlobalSearchResultService, 'ServiceCode'>);
const filterServicesOfType = (code: string) => R.filter<GlobalSearchResultService>(isServiceOfType(code));

const getLrNumber = R.pair(isServiceOfType(staticConf.lrServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));
const getMNumber = R.pair(isServiceOfType(staticConf.mortgageServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));
const getFgNumber = R.pair(isServiceOfType(staticConf.fgServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));
const getKNumber = R.pair(isServiceOfType(staticConf.kiwisaverServiceCode), R.propOr('', 'Number' as keyof GlobalSearchResultService));

const getNumberAccordingToCode = R.cond([
  getLrNumber,
  getMNumber,
  getFgNumber,
  getKNumber
]);

const createServiceListFilter = R.curry((list: GlobalSearchResultService[], code: string) =>
  R.pipe(
		filterServicesOfType(code),
		R.map(getNumberAccordingToCode),
		createServiceList(code)
	)(list));


export class ViewGlobalSearch {
  name: string;
  detail: string;
  detail2: string;
  isCompany: boolean;
  isService: boolean;
  link: CommandRoute;

  static MapFromModel(
    model: GlobalSearch,
    customerLink: (id) => CommandRoute,
    businessLink: (id) => CommandRoute,
    customerLrLink: (id, sId) => CommandRoute,
    businessLrLink: (id, sId) => CommandRoute,
    customerMLink: (id, sId) => CommandRoute,
    businessMLink: (id, sId) => CommandRoute,
    customerFgLink: (id, sId) => CommandRoute,
    businessFgLink: (id, sId) => CommandRoute,
    customerKLink: (id, sId) => CommandRoute,
    businessKLink: (id, sId) => CommandRoute
  ): ViewGlobalSearch {

    let redirectTo: CommandRoute;
    if (model.IsCompany) {
      if (model.CustomerServiceId && model.ServiceType === staticConf.lrServiceCode) {
        redirectTo = businessLrLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.mortgageServiceCode) {
        redirectTo = businessMLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.fgServiceCode) {
        redirectTo = businessFgLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.kiwisaverServiceCode) {
        redirectTo = businessKLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.investmentServiceCode) {
        redirectTo = businessKLink(model.CustomerId, model.CustomerServiceId);
      } else {
        redirectTo = businessLink(model.CustomerId);
      }
    } else {
      if (model.CustomerServiceId && model.ServiceType === staticConf.lrServiceCode) {
        redirectTo = customerLrLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.mortgageServiceCode) {
        redirectTo = customerMLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.fgServiceCode) {
        redirectTo = customerFgLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.kiwisaverServiceCode) {
        redirectTo = customerKLink(model.CustomerId, model.CustomerServiceId);
      } else if (model.CustomerServiceId && model.ServiceType === staticConf.investmentServiceCode) {
        redirectTo = customerKLink(model.CustomerId, model.CustomerServiceId);
      } else {
        redirectTo = customerLink(model.CustomerId);
      }
    }

    return {
      name: model.Name,
      detail: model.Detail,
      detail2: model.Detail2,
      isCompany: model.IsCompany,
      isService: (!!model.CustomerServiceId && !!model.ServiceType),
      link: redirectTo
    };
  }
}
