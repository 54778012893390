import { Injectable } from '@angular/core';
import { BLStaffSecGroupsStore } from './bl-staff-security-groups.store';
import { BLStaffSecGroup } from './bl-staff-security-group.model';
import { Observable, of } from 'rxjs';
import { BLStaffSecGroupsQuery } from './bl-staff-security-groups.query';
import { ApiService } from '../../core/base/api.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class BLStaffSecGroupsService {

    constructor(
        private api: ApiService,
        private secGroupStore: BLStaffSecGroupsStore,
        private secGroupQuery: BLStaffSecGroupsQuery) { }

    addList(): Observable<BLStaffSecGroup[]> | Promise<BLStaffSecGroup[]> {
        const sq = this.secGroupQuery.getAll();

        if (sq.length > 0) {
            return of(sq);
        }
        const endpoint = `security-groups?type=bl`;
        return this.api.get<BLStaffSecGroup[]>(endpoint).pipe(tap(x => this.secGroupStore.add(x)));
    }

}
