import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import {
	AdviceProcessPageCodes,
	ServiceAdviceProcessState,
} from '@shared/models/advice-process/advice-process.model';
import { objectUtil } from '@util/util';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { CrtKiwiSaverQuery } from '../state/crt-kiwisaver.query';
@Injectable()
export class AdviceSummaryGuard implements CanActivate {
	constructor(
		private router: Router,
		private api: ApiService,
		private crtKiwiSaverQuery: CrtKiwiSaverQuery
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		const { companyCode, customerType, clientId, adviceProcessId } =
			next.params;
		const kiwisaverProviderUrl = `${companyCode}/crt-page/kiwisaver/${customerType}/${clientId}/advice-process/${adviceProcessId}/fact-find/providers`;

		return this.crtKiwiSaverQuery.adviceProcess$.pipe(
			take(1),
			mergeMap((adviceProcess) => {
				if (adviceProcess) {
					return of(adviceProcess);
				}
				return this.api
					.get<any>(`adviceprocesses/${adviceProcessId}`)
					.pipe(
						map(
							(result) =>
								objectUtil.mapPascalCaseToCamelCase(
									result
								) as ServiceAdviceProcessState
						)
					);
			}),
			map((adviceProcess) => {
				const providersIsCompleted = adviceProcess?.pageCompleted?.includes(
					AdviceProcessPageCodes.Providers
				);
				if (!providersIsCompleted) {
					return this.router.parseUrl(kiwisaverProviderUrl);
				}
				return providersIsCompleted;
			})
		);
	}
}
