import {
	appLoanStructureMergeTag,
	appLoanStructureMetaKey,
} from './loan-structure.merge-tags';
import { MergeTagsMapper } from '../../../merge-tags.mapper';
import { loanStructureTbl } from './loan-structure.template';
import { LoanStructure } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/loan-structure/state/loan-structure.model';
import { getPerFrequency } from 'src/app/modules/crm/crt-page/_shared/calculations/monthly-conversion';
import { contentEditable } from 'src/app/shared/converter/content-merge-tags';

export class AppLoanStructureMergeTagMapper {
	public static getMergeTagValues(
		ls: LoanStructure | any,
		applicationData,
		propertyOwners,
		computations
	) {
		return appLoanStructureMergeTag?.map((x) => {
			let value = '';
			if (x?.metaKey === appLoanStructureMetaKey.loanStructureSection) {
				const html = applicationData?.loanStructureToFollow
					? this.loanStructureApproval(computations?.totalFundingRequired)
					: this.createLoanStructureTable(ls, propertyOwners);
				value = `<div ${contentEditable.false} id="${
					appLoanStructureMetaKey.loanStructureSection
				}">${html?.replace(/\n|\t/g, '')}</div>`;
			}
			return {
				...x,
				value,
			};
		});
	}

	public static loanStructureApproval(totalFundingRequired) {
		const value =
			MergeTagsMapper.formatCurrencyWholeNumber(totalFundingRequired);
		return `<p><span ${contentEditable.true}>${value} - clients would look to fix the whole loan. Structure to be confirmed after approval</span></p>`;
	}

	public static createLoanStructureTable(data, propertyOwners) {
		const header = loanStructureTbl.header;
		const body = data?.reduce((a, c) => {
			if (c) {
				return `${a}
				<tr>
					<td style="width: 12.7102%;">
						<span ${contentEditable.true}>${c?.loanType}</span>
					</td>
					<td style="width: 11.6932%;">
						<span ${contentEditable.true}>${c?.loanTerm} years</span>
					</td>
					<td style="width: 12.8791%;">
						<span ${contentEditable.true}>${c?.fixedPeriod}</span>
					</td>
					<td style="width: 10.3695%;">
						<span ${contentEditable.true}>${MergeTagsMapper.formatPercent(+c?.interestRate)}</span>
					</td>
					<td style="width: 15.851%;">
						<span ${contentEditable.true}>${this.getRepayment(c?.repaymentAmount, c?.repaymentFrequency)}</span>
					</td>
					<td style="width: 23.384%;">
						<span ${contentEditable.true}>${this.getBorrowers(c?.borrowers, propertyOwners)}</span>
					</td>
					<td style="width: 13.0079%;">
						<span ${contentEditable.true} style="font-size: 12px;">${MergeTagsMapper.formatCurrencyWholeNumber(+c?.loanAmount)}</span>
					</td>
				</tr>`;
			}
		}, '');

		return `<table style="width: 100%;">
			${header}
			<tbody>
				${body}
			</tbody>
		</table>`;
	}

	public static getRepayment(
		repaymentAmount: number,
		repaymentFrequency: string
	) {
		return `${MergeTagsMapper.formatCurrency(
			repaymentAmount
		)} ${getPerFrequency(repaymentFrequency)}`;
	}

	public static getBorrowers(borrowers: string, propertyOwners) {
		const owners =
			typeof borrowers === typeof []
				? borrowers
				: MergeTagsMapper.parseData(borrowers);

		return owners
			?.map((i) => {
				const data = propertyOwners?.find((o) => +o?.value === +i);
				return data?.display || '';
			})
			?.join(', ');
	}

	public static revertLoanStructureSection(content = '') {
		let newContent = content;

		// Loan Structure Table
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			appLoanStructureMetaKey.loanStructureSection
		);

		return newContent;
	}
}
