import { StoreConfig, Store } from '@datorama/akita';
import { MortgageOpportunity } from './mo.model';
import { produce } from 'immer';
import { Injectable } from "@angular/core";

export interface MOState {
  MortgageOpportunity: MortgageOpportunity[];
  error: string;
  isLoading: boolean;
}

export function CreateInitialState(): MOState {
  return {
    MortgageOpportunity: null,
    error: '',
    isLoading: false
  };
}

@Injectable()
@StoreConfig({
  name: 'MortgageOpportunity'
})
export class MOStore extends Store<MOState> {
  constructor() {
    super(CreateInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(
      produce(draft => {
        draft.isLoading = isLoading;
      })
    );
  }
}
