/**
 * LOAT V2 Merge Tags
 */
import { MergeTagState, MergeTagTypeCode } from '../../merge-tags.model';

export const LoatCffMetaKeys = {
	// People
	peopleIncome: 'PEOPLE_NAME',
	peopleReference: 'PEOPLE_NAME_WITH_REFERENCE',
	peopleDob: 'PEOPLE_REFERENCE_DOB',
	peopleGender: 'PEOPLE_REFERENCE_GENDER',
	peopleMobile: 'PEOPLE_REFERENCE_MOBILE',
	peopleHomePhone: 'PEOPLE_REFERENCE_HOME_PHONE',
	peopleWorkPhone: 'PEOPLE_REFERENCE_WORK_PHONE',
	peopleEmail: 'PEOPLE_REFERENCE_EMAIL',
	peopleAddress: 'PEOPLE_REFERENCE_ADDRESS',
	// Dependants
	dependantReference: 'DEPENDANT_NAME_WITH_REFERENCE',
	// Trust
	trustName: 'PEOPLE_TRUST_NAME',
	// Company
	companyName: 'PEOPLE_BUSINESS_NAME',
	// Income & Expense
	incomeSourceReference: 'INCOME_SOURCE_PEOPLE_NAME',
	incomeSourceOccupation: 'INCOME_SOURCE_OCCUPATION',
	incomeSourceEmployer: 'INCOME_SOURCE_EMPLOYER',
	incomeSourceEmployment: 'INCOME_SOURCE_EMPLOYMENT',
	incomeSourceGross: 'INCOME_SOURCE_GROSS',
	incomeSourceHours: 'INCOME_SOURCE_HOURS',
	rentalIncomeAsset: 'RENTAL_INCOME_PROPERTY_ASSET',
	otherIncomeType: 'OTHER_INCOME_TYPE',
	annualTaxable: 'INCOME_EXPENSE_ANNUAL_TAXABLE',
	// Budget
	fullBudgetCompleted: 'INCOME_EXPENSES_COMPLETED',
	otherExpenseAmount: 'INCOME_EXPENSES_OTHER_EXPENSE_AMOUNT',
	otherExpenseType: 'INCOME_EXPENSES_OTHER_EXPENSE_TYPE',
	// Asset & Liability
	propertyAddress: 'PROPERTY_ADDRESS',
	assetType: 'ASSET_TYPE',
	kiwiSaverProvider: 'KIWISAVER_PROVIDER',
	mortgageLender: 'MORTGAGE_LENDER',
	liabilityType: 'LIABILITY_TYPE',
	// Medical History
	gpName: 'MEDICAL_HISTORY_GP_NAME',
	gpPractice: 'MEDICAL_HISTORY_GP_PRACTICE',
	medHistoryClient: 'MEDICAL_HISTORY_CLIENT',
	medHistoryHealthIssue: 'MEDICAL_HISTORY_HEALTH_ISSUE',
	medHistoryFirstSymptoms: 'MEDICAL_HISTORY_FIRST_SYMPTOMS',
	medHistoryLastSymptoms: 'MEDICAL_HISTORY_LAST_SYMPTOMS',
	medHistoryCurrentTerms: 'MEDICAL_HISTORY_CURRENT_TERMS',
	medHistoryNotes: 'MEDICAL_HISTORY_NOTES',
	famHistoryClient: 'FAMILY_HISTORY_CLIENT',
	famHistoryHealthIssue: 'FAMILY_HISTORY_HEALTH_ISSUE',
	famHistoryRelationship: 'FAMILY_HISTORY_RELATIONSHIP',
	famHistoryAgeOfDiagnosis: 'FAMILY_HISTORY_AGE_DIAGNOSIS',
	famHistoryCurrentTerms: 'FAMILY_HISTORY_CURRENT_TERMS',
	famHistoryNotes: 'FAMILY_HISTORY_NOTES',
};

export const annualTaxableMT = {
	metaKey: LoatCffMetaKeys?.annualTaxable,
	description: 'Income & Expenses > Annual Taxable Joint Income',
	type: MergeTagTypeCode.text,
	value: null,
	secondaryValue: '$123',
} as MergeTagState;

export const gpClientMT: MergeTagState[] = [
	{
		metaKey: LoatCffMetaKeys?.gpName,
		description: 'Medical History > GP Details > Name',
		type: MergeTagTypeCode.text,
		value: null,
		secondaryValue: 'Dr. James',
	},
	{
		metaKey: LoatCffMetaKeys?.gpPractice,
		description: 'Medical History > GP Details > Practice',
		type: MergeTagTypeCode.text,
		value: null,
		secondaryValue: 'Dental Smiles',
	},
];

export const LoatCffPeopleMT = [
	LoatCffMetaKeys.peopleDob,
	LoatCffMetaKeys.peopleGender,
	LoatCffMetaKeys.peopleMobile,
	LoatCffMetaKeys.peopleHomePhone,
	LoatCffMetaKeys.peopleWorkPhone,
	LoatCffMetaKeys.peopleEmail,
	LoatCffMetaKeys.peopleAddress,
];

export const LoatCffIncomeMT = [
	LoatCffMetaKeys.incomeSourceOccupation,
	LoatCffMetaKeys.incomeSourceEmployer,
	LoatCffMetaKeys.incomeSourceEmployment,
	LoatCffMetaKeys.incomeSourceGross,
	LoatCffMetaKeys.incomeSourceHours,
];

export const LoatCffMedHistoryMT = [
	LoatCffMetaKeys.medHistoryClient,
	LoatCffMetaKeys.medHistoryHealthIssue,
	LoatCffMetaKeys.medHistoryFirstSymptoms,
	LoatCffMetaKeys.medHistoryLastSymptoms,
	LoatCffMetaKeys.medHistoryCurrentTerms,
	LoatCffMetaKeys.medHistoryNotes,
];

export const LoatCffFamHistoryMT = [
	LoatCffMetaKeys.famHistoryClient,
	LoatCffMetaKeys.famHistoryHealthIssue,
	LoatCffMetaKeys.famHistoryRelationship,
	LoatCffMetaKeys.famHistoryAgeOfDiagnosis,
	LoatCffMetaKeys.famHistoryCurrentTerms,
	LoatCffMetaKeys.famHistoryNotes,
];