import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, EMPTY, Observable, Observer, Subject, throwError } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { DropdownValue } from '../../../../../domain/dropdown-value/dropdown-value.model';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { ActivatedRoute } from '@angular/router';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DependentState } from '../../../../../shared/models/client-review-template/dependent/dependent.model';
import { PeopleModalComponent } from '../../../../../shared/modal/crt/fact-find/people/people-modal.component';
import * as moment from 'moment';
import { PeopleService } from '../../states/people/people.service';
import { peopleToViewDisplayValueUtil } from '../../util/mapViewDisplayValue.util';
import { DeleteModalComponent } from '../../../../../shared/modal/delete-modal/delete-modal.component';
import { DependentDetailsState } from 'src/app/shared/models/client-review-template/dependent/dependent-details';
import { logMessage } from 'src/app/shared/error-message/error-message';
declare var $: any;

@Component({
  selector: 'app-dependent',
  templateUrl: './dependent.component.html',
  styleUrls: ['./dependent.component.scss']
})
export class DependentComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  dropdownCodes: DropdownValue[];

  primaryColor: string;
  bsModalRef: BsModalRef;

	@Input() isAdviceProcessEnded: boolean;

  @Input() dependents$: Observable<DependentState[]>;
  @Input() SCR$: Observable<ViewDisplayValue[]>;
  @Input() APCRTG$: Observable<ViewDisplayValue[]>;
  @Input() isDependantsLoading$: Observable<boolean>;

  @Input() addFn$: ({ dependent, adviceProcessId }) => Observable<any>;
  @Input() addNewFn$: ({ dependent, adviceProcessId }) => Observable<any>;
  @Input() updateFn$: (dependent) => Observable<any>;
  @Input() deleteFn$: (id) => Observable<any>;


  elseMinusPeople = true;
  elseMinusDependents = true;
  elseMinusTrust = true;
  elseMinusCompany = true;
  isLoading = false;
  crtLoading: number;
  isAddNew = false;
  isSaving = false;

	isLoading$ = this.query.selectLoading();
  dependentList$ = new BehaviorSubject<ViewDisplayValue[]>(null);
  dependentInfo = crtId => this.peopleService.getPersonInfo(crtId);

  constructor(
    private modalService: BsModalService,
    private query: ClientReviewTemplateQuery,
    private route: ActivatedRoute,
    private peopleService: PeopleService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.query.dependentsFromCRMandCRTChildOnly$.pipe(
      mergeMap(x => peopleToViewDisplayValueUtil(x)),
      tap(x => this.dependentList$.next(x)),
      take(1)
    ).subscribe()
  }
  collapseDependents(showMore?: boolean) {
    $('#collapseDependents').toggle();
    this.elseMinusDependents = showMore ? false : true;
  }

  toggleLoader(cRTId: number, load: boolean) {
    this.crtLoading = (cRTId && load) ? +cRTId : null;
  }

  isCrtLoading(cRTId: number) {
    if (this.isAddNew && !this.isSaving) { return false; }
    if (this.isSaving && cRTId === 0) { return true; }
    if (!this.isSaving && cRTId === 0) { return false; }

    return +this.crtLoading === +cRTId;
  }

  addExistingDependent(p: DependentState) {
    this.isSaving = true;
    this.query.dependentsFromCRMChildOnlyNoFilter$.pipe(
      map((x) => x?.find(c => c.customerID === +p.customerId)),
      map(client => {
        const adviceProcessId = parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10);
        const dependent = {
          customerID: client.customerID,
          adviceProcessId,
          sectionCode: 'FPD',
          firstName: client.firstName,
          lastName: client.lastName,
          gender: client.gender ? (client.gender === 'F' ? 'Female' : 'Male') : null,
          dateOfBirth: client.dateOfBirth,
          physicalAddress: client.physicalAddress,
          relationship: client.relationship,
          email: client.email,
          knownAs: client.knownAs
        }
        return { dependent, adviceProcessId }
      }),
      take(1),
      mergeMap(res => this.addFn$({ dependent: res.dependent, adviceProcessId: res.adviceProcessId })),
      finalize(() => {
        this.isAddNew = false;
        this.isSaving = false;
        this.cd.detectChanges();
      })
    ).subscribe()
  }

  addDependent() {
    this.isAddNew = true;
    this.cd.detectChanges();
    this.query.dependentsFromCRMandCRTChildNotDeceasedOnly$.pipe(
      mergeMap(x => peopleToViewDisplayValueUtil(x)),
      tap(x => this.dependentList$.next(x)),
      take(1),
      tap(() => this.peopleService.addBlankDependent())
    ).subscribe()
  }

  addNewDependent = (model) => new Observable<DependentDetailsState>((obs) => {
    this.isSaving = true;
    obs.next(model);
    obs.complete();
  }).pipe(
    concatMap(x => this.addNewFn$({ dependent: x, adviceProcessId: parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10) })),
    catchError((err) => throwError(err)),
    finalize(() => {
      this.isAddNew = false;
      this.isSaving = false;
      this.cd.detectChanges();
    })
  )

  change(e, i) {
    if (e.target.value === 'new') {
      const decline = new Observable((obs: Observer<JSON>) => {
        // tslint:disable-next-line: no-angle-bracket-type-assertion
        (<HTMLInputElement> document.getElementById('addNewDependantsDropdown_' + i)).value = '';
        obs.complete();
      });
      const initState = {
        header: 'Dependant Details',
        message: `DependentDetails`,
        scr$: this.SCR$,
        apcrtg$: this.APCRTG$,
        savefn: this.addNewDependent,
        decline$: decline,
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    } else {
      this.query.dependentsFromCRMandCRTChildOnly$.pipe(
        map((x) => x?.find(y => y.customerID === +e.target.value)),
        mergeMap((x) => this.dependents$
          .pipe(
            map(p => {
              p[i] = {
                cRTId: 0,
                age: x?.dateOfBirth ? moment().diff(x.dateOfBirth, 'years') : null,
                birthDate: x?.dateOfBirth,
                customerType: x?.customerType,
                name: x?.firstName && x?.lastName ? x?.firstName.concat(' ', x.lastName) : null,
                relationship: x?.relationship,
                customerId: x?.customerID
              }
            })
          )
        ),
        take(1)
      ).subscribe()
    }
  }

  removeDependent(index) {
    this.dependents$.pipe(
      map(x =>
        x.splice(index, 1)
      ),
      take(1)
    ).subscribe(() => {
      this.isAddNew = false;
      this.cd.detectChanges();
    })
  }

  deleteDependent(id) {
    this.toggleLoader(id, true);

    const confirm = new Observable((obs) => {
      this.deleteItem(id);
      obs.next();
      obs.complete();
    });

    const decline = new Observable((obs: Observer<JSON>) => {
      this.toggleLoader(id, false);
      obs.complete();
    });

    const initState = {
      header: 'Delete Dependant',
      message: logMessage.oat.shared.factFind.delete,
      delete$: confirm,
      decline$: decline,
      confirmButton: 'OK',
      detachCloseIcon: false
    };
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-dialog-centered modal-dialog',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  deleteItem = (id) =>
    this.deleteFn$(id)
      .pipe(
        mergeMap(() => this.updateDependantDd()),
        finalize(() => (this.isLoading = false)),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe((x) => {
        this.toggleLoader(id, false);
      });


  updateDependent = (model) => new Observable<DependentDetailsState>((obs) => {
    obs.next(model);
    obs.complete();
  }).pipe(
    concatMap(x => this.updateFn$(x)),
    catchError((err) => throwError(err)),
    finalize(() => {
      this.isAddNew = false;
      this.toggleLoader(+model?.cRTId, false);
      this.cd.detectChanges();
    })
  )

  editDependent(crtId) {
    this.toggleLoader(+crtId, true);
    const decline = new Observable((obs: Observer<JSON>) => {
      this.toggleLoader(crtId, false);
      obs.complete();
    });
    this.dependentInfo(crtId).subscribe((x) => {
      const initState = {
        header: 'Dependent Details',
        message: `DependentDetails`,
        scr$: this.SCR$,
        apcrtg$: this.APCRTG$,
        dependentInfo: x,
        savefn: this.updateDependent,
        decline$: decline,
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    })
  }

  viewDependent(crtId) {
    this.toggleLoader(+crtId, true);
    const decline = new Observable((obs: Observer<JSON>) => {
      this.toggleLoader(crtId, false);
      obs.complete();
    });
    this.dependentInfo(crtId).subscribe((x) => {
      const initState = {
        header: 'Dependent Details',
        message: `DependentDetails`,
        scr$: this.SCR$,
        apcrtg$: this.APCRTG$,
        dependentInfo: x,
        decline$: decline,
				viewMode: true
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    })
  }

  updateDependantDd = () =>
    this.query.dependentsFromCRMandCRTChildNotDeceasedOnly$.pipe(
      mergeMap((x) => peopleToViewDisplayValueUtil(x)),
      tap((x) => this.dependentList$.next(x)),
      take(1)
    );

  ngOnDestroy() {
    this.dependents$
    .pipe(
      map((x) => {
        if (x?.some((p) => p?.cRTId === 0 || !p?.cRTId)) {
          x?.splice(
            x.findIndex((p) => p?.cRTId === 0 || !p.cRTId),
            1
          );

          return x;
        }
        return x;
      }),
      take(1)
    )
    .subscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
