import { DownloadableDocumentModel } from './templates-utils/templates.model';

const signature = (people) => {
	let result = [];
	for (let v of people) {
		result.push(`
    <p><span style="font-size: 12px;"><br></span></p>
    <p>
      <span style="font-size: 12px;">
        <span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
          Name: ____________________________ Signed: _____________________________
        </span>
      </span>
    </p>
    <p><span style="font-size: 12px;"><br></span></p>
    `);
	}
	return result.join('');
};

const signatureDefault = () => {
	return `
  <p><span style="font-size: 12px;"><br></span></p>
  <p>
    <span style="font-size: 12px;">
      <span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
        Name: ____________________________ Signed: _____________________________
      </span>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  `;
};

export const createBoarderConfirmationTemplate = (
	data: DownloadableDocumentModel
) => {
	const peopleName =
		data.peopleName && data.peopleName.length > 0
			? data.peopleName.join(', ')
			: '';
	const propertyAddresses =
		data?.propertyAddresses && data?.propertyAddresses.length > 0
			? data.propertyAddresses.join(', ')
			: '';

	return `
  <p style="text-align: center;">
    <span style="font-size: 12px; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
      <img src="${
				data?.businessLogo
			}" alt="Business Logo" style="width: 250px; height: 70px;"/>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Date: ${
		data?.dateToday
	}</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 24px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Boarder Confirmation</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">To whom it may concern:</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p>
    <span style="font-size: 12px;">
      <span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
        This is to confirm that I, _________________________ will be paying $________ per week board, plus expenses, for a room at ${peopleName}’s property located at ${propertyAddresses} as of ____/____/_______.
      </span>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  ${
		data.peopleName && data.peopleName.length > 0
			? signature(data.peopleName)
			: signatureDefault()
	}
  `;
};
