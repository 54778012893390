import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { finalize, take } from 'rxjs/operators';
import { ScopeOfServiceMapper } from './state/scope-of-service.mapper';
import { ScopeOfServiceService } from './state/scope-of-service.service';

@Component({
	selector: 'app-scope-of-service-settings',
	templateUrl: './scope-of-service-settings.component.html',
	styleUrls: ['./scope-of-service-settings.component.scss'],
})
export class ScopeOfServiceSettingsComponent implements OnInit {
	form: UntypedFormGroup;
	isLoading = false;
	scopeOfService$ = this.sService.scopeOfService$;
	kloatFeature$ = this.businessConfig.kloatFeature$;

	defaultDetails = 'is not included in the advice process because';

	constructor(
		private fb: UntypedFormBuilder,
		private sService: ScopeOfServiceService,
		private businessConfig: BusinessConfigQuery,
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
	}

	get ResidentialMortgageOrLending() {
		return this.form.get('residentialMortgageOrLending');
	}
	get HomeCarAndContentsInsurance() {
		return this.form.get('homeCarAndContentsInsurance');
	}
	get KiwiSaver() {
		return this.form.get('kiwiSaver');
	}
	get KiwiSaverAdvice() {
		return this.form.get('kiwiSaverAdvice');
	}

	buildForm() {
		this.form = this.fb.group({
			referenceId: [null],
			type: [null],
			intro: [null],
			lifeInsurance: [0],
			criticalIllness: [0],
			tPDInsurance: [0],
			disabilityInsurance: [0],
			medicalInsurance: [0],
			businessRiskInsurance: [0],
			kiwiSaver: [false],
			kiwiSaverAdvice: [false],
			homeCarAndContentsInsurance: [false],
			residentialMortgageOrLending: [false],
			other: [false],
			instructionOrLimitation: [null],
			otherInformation: [null],
			residentialMortgageOrLendingNote: [null],
			homeCarAndContentsInsuranceNote: [null],
			kiwiSaverNote: [null],
			businessRiskInsuranceNote: [null],
			lifeInsuranceDetails: [null],
			criticalIllnessDetails: [null],
			tPDInsuranceDetails: [null],
			disabilityInsuranceDetails: [null],
			medicalInsuranceDetails: [null],
		});
	}

	prepData() {
		this.scopeOfService$.pipe(take(1)).subscribe((data) => {
			if (data) {
				if (!data.lifeInsuranceDetails?.trim().length) {
					data.lifeInsuranceDetails = `Life Insurance ${this.defaultDetails}`;
				}

				if (!data.medicalInsuranceDetails?.trim().length) {
					data.medicalInsuranceDetails = `Medical Insurance ${this.defaultDetails}`;
				}

				if (!data.criticalIllnessDetails?.trim().length) {
					data.criticalIllnessDetails = `Critical Illness ${this.defaultDetails}`;
				}

				if (!data.tPDInsuranceDetails?.trim().length) {
					data.tPDInsuranceDetails = `TPD Insurance ${this.defaultDetails}`;
				}

				if (!data.disabilityInsuranceDetails?.trim().length) {
					data.disabilityInsuranceDetails = `Disability Insurance ${this.defaultDetails}`;
				}

				this.form.reset(ScopeOfServiceMapper.mapSosSettingsToView(data));
			}
		});
	}

	save() {
		this.isLoading = true;
		const formValue = ScopeOfServiceMapper.mapToUpsertSosSettings(this.form.getRawValue());
		this.sService
			.updateScopeOfService(formValue)
			.pipe(
				finalize(() => (this.isLoading = false)),
				take(1),
			)
			.subscribe();
	}

	checkKiwisaverCheckboxes(from: string) {
		if (this.KiwiSaverAdvice.value && from === 'advice' && this.KiwiSaver.value) this.KiwiSaver.setValue(false);
		if (this.KiwiSaver.value && from === 'referral' && this.KiwiSaverAdvice.value) this.KiwiSaverAdvice.setValue(false);
	}
}
