<app-manual-loader [isLoading]="isLoading"></app-manual-loader>
<form [formGroup]="form">
	<h4 class="card-header font-weight-bold text-light tap-bg-primary">
		Email Client
	</h4>
	<div class="p-4">
		<div class="row mt-2">
			<div class="col-12 col-lg-2">
				<label class="font-weight-bold" for="carbonCopy">From:</label>
			</div>
			<div class="col-12 col-lg-10">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="from"
					id="from"
				/>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12 col-lg-2">
				<label class="font-weight-bold" for="carbonCopy">To:</label>
			</div>
			<div class="col-12 col-lg-10">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="to"
					id="to"
				/>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12 col-lg-2">
				<label class="font-weight-bold" for="carbonCopy">CC:</label>
			</div>
			<div class="col-12 col-lg-10">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="carbonCopy"
					id="carbonCopy"
				/>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12 col-lg-2">
				<label class="font-weight-bold" for="blindCarbonCopy">BCC:</label>
			</div>
			<div class="col-12 col-lg-10">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="blindCarbonCopy"
					id="blindCarbonCopy"
				/>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12 col-lg-2">
				<label class="font-weight-bold" for="subject">Subject:</label>
			</div>
			<div class="col-12 col-lg-10">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="subject"
					id="subject"
				/>
			</div>
		</div>

		<div class="row align-items-center email-form__wysiwyg">
			<div class="col-12 col-lg-12 email-form__label">
				<label class="form-check-label font-weight-bold"></label>
			</div>
			<div class="col-12 col-lg-12 mt-2 email-form__wysiwyg">
				<p class="text-muted" [innerHTML]="parsedHeaderTemplate"></p>

				<app-wysiwyg
					#contentEditor
					[content]="parsedBodyContent"
					[simpleEditor]="true"
					[extendOptions]="froalaExtendOptions"
					(onChangeEvent)="onValueChanged($event)"
				></app-wysiwyg>
				<p class="text-muted mt-3" [innerHTML]="parsedFooterTemplate"></p>
			</div>
		</div>

		<div class="pt-2">
			<div class="form-row pl-1 font-weight-bold">Email Attachment</div>
			<div class="mt-2 pt-2 border-top">
				<ng-template #attachmentsLoader>
					<app-manual-loader-mini
						*ngIf="isLoadingAttachments"
						[isLoading]="true"
					></app-manual-loader-mini>
				</ng-template>
				<ng-container *ngIf="!isLoadingAttachments; else attachmentsLoader">
					<div class="row col-12 px-0 mx-0">
						<div class="col-9 pt-2 px-0">
							<div
								*ngFor="let attachment of attachments; let i = index"
								class="d-flex align-items-center"
							>
								<div
									id="downloadBtn_{{ i }}"
									class="btn btn-md document-button-container p-0 d-flex overflow-hidden"
									[class.disabled]="!!attachment?.queue"
									[class.uploaded]="
										attachment.type && attachment.type === 'uploaded'
									"
								>
									<i class="far fa-file-pdf text-danger pr-1"></i>
									<small class="text-muted text-truncate">{{ attachment.fileName }}</small>
								</div>
								<button
									id="file_{{ i }}"
									type="button"
									class="icon-btn w-auto px-1 h-100 d-flex"
									(click)="removeAttachment(i)"
								>
									<i class="material-icons md-16 close-icon"> close </i>
								</button>
							</div>
						</div>
						<div class="col-3 text-right pt-2 pr-0">
							<button
								(click)="openUploadOptionModal()"
								id="attachDocument"
								class="tap-btn tap-btn-outline tap-btn--shadow"
							>
								Attach Document
							</button>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="form-buttons pb-1 mb-0">
		<button
			id="sendBtn"
			class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
			[attr.disabled]="
				isSending || isLoadingAttachments || isLoading ? '' : null
			"
			(click)="send()"
		>
			Send
			<app-manual-loader-mini
				*ngIf="isSending"
				[isLoading]="true"
			></app-manual-loader-mini>
		</button>
		<button
			id="cancelBtn"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="cancel()"
		>
			Cancel
		</button>
	</div>
</form>

<ng-template #uploadOptionModal>
	<button
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="optionModalRef.hide()"
	>
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Attach Document</h4>
	</div>
	<div class="modal-body">
		<div class="clearfix py-3">
			<div class="d-flex justify-content-center">
				<button
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 mr-2"
					(click)="uploadDocuments(); optionModalRef.hide()"
				>
					Upload New
				</button>
				<button
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4"
					(click)="linkDocument(); optionModalRef.hide()"
				>
					Link Document
				</button>
			</div>
		</div>
	</div>
</ng-template>
