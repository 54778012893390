import {
	animate,
	keyframes,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
	HostBinding,
	EventEmitter,
	Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as R from 'ramda';
import { Observable, of, merge, Subject, BehaviorSubject } from 'rxjs';
import {
	auditTime,
	combineLatest,
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	map,
	startWith,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { Model, ModelFactory } from '../../core/base/model.service';
import { DomEventService } from '../../core/dom-event/dom-event.service';
import { util } from '../../core/util/util.service';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';

declare var $: any;

@Component({
	selector: 'app-chips,[app-chips]',
	templateUrl: './chips.component.html',
	styleUrls: ['./chips.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ChipsComponent,
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('isFocused', [
			state('false', style({ display: 'none' })),
			transition('true => false', [
				animate(
					200,
					keyframes([
						style({ opacity: 1, height: '*', overflow: 'hidden', offset: 0 }),
						style({
							opacity: 0.5,
							height: '50%',
							overflow: 'hidden',
							offset: 0.7,
						}),
						style({
							opacity: 0,
							height: '0px',
							overflow: 'hidden',
							offset: 1.0,
						}),
					])
				),
			]),
			transition('false => true', [
				animate(
					200,
					keyframes([
						style({ opacity: 0, height: '0px', overflow: 'hidden', offset: 0 }),
						style({
							opacity: 0.5,
							height: '50%',
							overflow: 'hidden',
							offset: 0.7,
						}),
						style({ opacity: 1, height: '*', overflow: 'hidden', offset: 1.0 }),
					])
				),
			]),
		]),
	],
})
export class ChipsComponent
	implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor
{
	/** on destroy */
	private readonly onDestroy$: Subject<void> = new Subject<void>();
	/** control's data as Model.  */
	private model: Model<string[]>;
	/** control's data Observable */
	public readonly model$: Observable<string[]>;
	/** control's choices as Model */
	private choicesModel: Model<ViewDisplayValue[]>;
	/** control's choices as Observable */
	public readonly choices$: Observable<ViewDisplayValue[]>;
	/** data view usable data */
	public readonly viewModel$: Observable<ViewDisplayValue[]>;
	/** control's switch whether disabled or not as Model */
	private isDisabledModel: Model<boolean> =
		this.booleanModeFactory.create(false);
	/** control's indicator whether disabled or not as Observable */
	public readonly isDisabled$: Observable<boolean> =
		this.isDisabledModel.data$.pipe(
			distinctUntilChanged(),
			takeUntil(this.onDestroy$)
		);
    /** equivalent of isDisabled$ observable */
  public isDisableValue: boolean;
	/** control's switch whether control is focused on or not as Model */
	private isFocusedModel: Model<boolean> =
		this.booleanModeFactory.create(false);
	/** control's indicator whether control is focused on or not as Observable */
	public readonly isFocused$: Observable<boolean> =
		this.isFocusedModel.data$.pipe(
			distinctUntilChanged(),
			takeUntil(this.onDestroy$)
		);
	/** control's search query for filtering choices as Model */
	private searchModel: Model<string>;
	/** control's search query for filtering choices as Observable */
	public readonly search$: Observable<string>;
	/** control's search query for filtering choices as Observable and debounced */
	public readonly debouncedSearch$: Observable<string>;
	/** control's currently selected choice index as Model */
	private SelectedIndexModel: Model<number>;
	/** control's currently selected choice index as Observable */
	public readonly selectedIndex$: Observable<number>;

	/** control's filtered choices based on search search query(search$) as Observable */
	public readonly filteredChoices$: Observable<
		(ViewDisplayValue & { isSelected: boolean })[]
	>;
	/** whether choices are shown. Based on if disabled and is focused on */
	public readonly isShown$: Observable<boolean> = this.isFocused$.pipe(
		combineLatest(this.isDisabled$),
		map(([isFocused, isDisabled]) => isFocused && !isDisabled),
		distinctUntilChanged()
	);
	/** element's z-index. changes on @Input() zIndex. */
	private zIndexSubj: BehaviorSubject<number | null>;
	/** element's z-index. sets choices's z-index. */
	public readonly zIndexStyle$: Observable<any>;
	/** control's indicator if badge (+more) is shown or not */
	public hasBadge$ = new BehaviorSubject<boolean>(false);

	/** angular input for css class to add style on textbox */
	@Input() textboxClass = '';
	/** angular input for id on textbox */
	@Input() textboxId = '';
	/** angular input for placeholder on textbox */
	@Input() textboxPlaceholder = '';
	/** css class of wrapper */
	public textboxNgClass$ = this.isDisabled$.pipe(
		combineLatest(this.isShown$),
		map(([isDisabled, isShown]) => {
			const disabledClass = this.textboxClass
				? this.textboxClass
				: 'app-form-control-disabled';
			if (!isDisabled) {
				if (this.wrapper && this.wrapper.nativeElement) {
					$(this.wrapper.nativeElement).css('background-color', '#ffffff');
				}
			} else {
				if (this.wrapper && this.wrapper.nativeElement) {
					$(this.wrapper.nativeElement).css('background-color', '');
				}
			}
			return {
				[disabledClass]: isDisabled,
				'app-form-control-focus': isShown,
			};
		})
	);
	/** Placeholder filled by angular.
	 * should be called when the control's value
	 * changes in the UI.
	 */
	onChange: (value) => void;
	/** Placeholder filled by angular.
	 * should be called when the control receives
	 * a blur event.
	 */
	onTouched: () => void;
	/** Input for z-index of the choices */
	@Input(`custom-z-index`) set zIndex(v: number) {
		this.zIndexSubj.next(v);
	}
	/** Input choices. Is filtered by search query. */
	@Input()
	set choices(value: ViewDisplayValue[] | Observable<ViewDisplayValue[]>) {
		const assignChoices = (
			m: Model<ViewDisplayValue[]>,
			v: ViewDisplayValue[]
		) => {
			if (Array.isArray(v)) {
				m.set(v);
			} else {
				m.set([]);
			}
		};
		if (value instanceof Observable) {
			value
				.pipe(takeUntil(this.onDestroy$))
				.subscribe((x) => assignChoices(this.choicesModel, x));
		} else {
			assignChoices(this.choicesModel, value);
		}
	}
	@Input() change: any;
	/** input to set tab index of control. applied to textbox. */
	@Input() tabindex: number;
	/** limit selected value */
	@Input() limit: number;
	/** limit selected value */
	@Input() isRemoveChipsPadding: boolean;
  /** if the chips is collapsible or not */
	@Input() collapsible: boolean = true;
  /** this is to show and hide the remove */
	@Input() showRemoveIcon: boolean = false;
  /** Prefix of id for html tags of values; For QA automation */
	@Input() valueId: boolean = false;

	/** Output events */
	@Output() onChangeEvent = new EventEmitter<any>();

	/** reference to the textbox on the template */
	@ViewChild('textbox') textbox: ElementRef;
	/** reference to the choices on the template */
	@ViewChild('dropdown') dropdown: ElementRef;
	/** reference to the wrapper on the template */
	@ViewChild('wrapper') wrapper: ElementRef;
	/** reference to the selected items on the template */
	@ViewChildren('selectedItem') selectedItem: QueryList<ElementRef>;
	/** badge to show how many more is hidden */
	@ViewChild('badge') badge: ElementRef;
	/**
	 * remove the tabindex attribute from host element
	 * to remove duplicate tab index that is attached to input element
	 */
	@HostBinding('attr.tabindex') get tabIndex() {
		return null;
	}
	/** current approximate scroll position in choices */
	scrollHeight = 0;
	/** increments on up key, decrements on down key.
	 * resets when passing limit (8 right ow) on up.
	 * retains when passing limit (0) on down.
	 */
	scrollUpCount = 0;
	/**
	 * percentage of the dropdown scroll view scrolled
	 */
	scrollPercentage: number;

	addValue = 0;
	idPattern = /[^a-zA-Z]+/g;

	/** Flag to close the dropdown options when an item is selected */
	@Input() remainOpenOnSelect = false;

	constructor(
		private domEventService: DomEventService,
		private sanitizer: DomSanitizer,

		modelFactory: ModelFactory<string[]>,
		choiceFactory: ModelFactory<ViewDisplayValue[]>,
		private booleanModeFactory: ModelFactory<boolean>,
		stringModelFactory: ModelFactory<string>,
		numberModelFactory: ModelFactory<number>,
		private elementRef: ElementRef,
		private cd: ChangeDetectorRef
	) {
		this.model = modelFactory.create([]);
		this.model$ = this.model.data$;
		this.choicesModel = choiceFactory.create([]);
		this.choices$ = this.choicesModel.data$;
		this.viewModel$ = this.choices$.pipe(
			combineLatest(this.model$),
			map(([choice, model]) => {
				return model
					?.map((m) => choice?.find((c) => c && c.value === m))
					?.filter((x) => x);
			})
		);
		this.searchModel = stringModelFactory.create('');
		this.search$ = this.searchModel.data$;
		this.debouncedSearch$ = this.search$.pipe(debounceTime(200));

		this.SelectedIndexModel = numberModelFactory.create(0);
		this.selectedIndex$ = this.SelectedIndexModel.data$;

		this.filteredChoices$ = this.model$.pipe(
			combineLatest(this.choices$, this.debouncedSearch$, this.selectedIndex$),
			map(([model, choices, search, selectedIndex]) => {
				const newChoices = choices?.filter(
					(x) => x && !model?.some((y) => y === x.value)
				);
				const filteredChoices = this.matchFunction(newChoices, search);

				return filteredChoices?.map((x, i) => ({
					...x,
					isSelected: selectedIndex === i,
				}));
			})
		);
		this.model$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => {
				this.onChange ? this.onChange(x) : '';
				this.onChangeEvent.emit(x);
			});

		this.isDisabled$
			.pipe(
				tap((x) => {
          this.isDisableValue = x;
					// activates this even when not the input is not focused or clicked
					if (!x) {
						// There is always around 19.14+ width difference when passively editing the input,
						// versus clicking it to be focused
						this.addValue = 19.14; 
						this.collapseControl();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => this.clearSearch());

		domEventService.DocumentClickEvent$.pipe(
			withLatestFrom(this.isDisabled$),
			filter(([, isDisabled]) => !isDisabled),
			map(([ev]) => ev),
			takeUntil(this.onDestroy$)
		).subscribe(this.onDocClick.bind(this));
		this.zIndexSubj = new BehaviorSubject(null);
		this.zIndexStyle$ = this.zIndexSubj
			.asObservable()
			.pipe(
				map((x) => this.sanitizer.bypassSecurityTrustStyle(`z-index: ${x};`))
			);
	}

	ngOnInit() {
		const filteredChoicesSubs = () => {
			/**
			 * re-scroll dropdown base on the scrollPercentage property
			 * when the choices is rendered
			 */
			const scrollTop =
				(this.scrollPercentage / 100) *
				(this.dropdown.nativeElement.scrollHeight -
					this.dropdown.nativeElement.clientHeight);
			this.dropdown.nativeElement.scrollTo({ top: scrollTop });
		};
		this.filteredChoices$
			.pipe(
				distinctUntilChanged(
					(prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe(filteredChoicesSubs);
	}

	/** Subscribe to isShown$ to trigger collapse and expand
	 *  of control. On `isShown`, also trigger focus on textbox
	 */
	ngAfterViewInit() {
		const windowResize$ = this.domEventService.windowResize$.pipe(
			startWith(null as any)
		);
		this.isShown$
			.pipe(
				auditTime(0),
				filter((x) => !!x),
				tap(this.expandControl.bind(this)),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => this.textbox.nativeElement.focus());

		merge(this.model$, windowResize$)
		.pipe(
			auditTime(0),
			combineLatest(this.isShown$),
			filter(([, isShown]) => !isShown),
			takeUntil(this.onDestroy$)
		)
		.subscribe(this.collapseControl.bind(this));
	}
	/** triggers destruction of other observables */
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
	/** placeholder to be called by angular when programmatic
	 * (model -> view) changes are requested
	 */
	writeValue: (obj: any) => void = (obj) => {
		if (Array.isArray(obj)) {
			this.model.set(obj);
		}
	};
	/** function to call to overwrite the onChange function. */
	registerOnChange: (fn: any) => void = (fn) => {
		this.onChange = fn;
	};
	/** function to call to overwrite the onTouched function. */
	registerOnTouched: (fn: any) => void = (fn) => {
		this.onTouched = fn;
	};
	/** Called by angular when control should be disabled. */
	setDisabledState: (isDisabled: boolean) => void = (isDisabled) => {
		this.isDisabledModel.set(isDisabled);
	};
	/** called when search query changes. makes search subj
	 * emit and affect choices
	 */
	setSearch(value: string) {
		this.SelectedIndexModel.set(0);
		this.searchModel.set(value);
		this.scrollHeight = 0;
		this.scrollUpCount = 0;
	}
	/** add a choice to the selected values */
	choose = (value: ViewDisplayValue, event: KeyboardEvent) => {
		of(value)
			.pipe(withLatestFrom(this.model$), takeUntil(this.onDestroy$))
			.subscribe(([v, m]) => {
				if (event.ctrlKey) {
					this.textbox.nativeElement.focus();
					this.isFocusedModel.set(true);
				} else if (event.which === 13) {
					this.textbox.nativeElement.focus();
					this.isFocusedModel.set(true);
				} else {
					this.isFocusedModel.set(this.remainOpenOnSelect);
				}

				this.model.set(m.concat([v.value]));
				this.clearSearch();

				this.scrollHeight = 0;
				this.scrollUpCount = 0;
			});
	};
	/** Removes a choice from the selected values.
	 *
	 *
	 * @Notes Delayed so that `onDocClick()` will run first.
	 * This is done so that delete button clicked can still be
	 * traced if its in the current control instance.
	 * Otherwise this(`remove()`) will trigger first and docClick
	 * will collapse the control.
	 */
	remove = (value: ViewDisplayValue) =>
		of(value)
			.pipe(withLatestFrom(this.model$), delay(0), takeUntil(this.onDestroy$))
			.subscribe(([v, m]) => {
				const newModel = util.removeIf(m, (x) => x === v.value);
				this.model.set(newModel);
			});
	/**
	 * On click anywhere inside the control, set `isFocused` to true.
	 * On click anywhere outside the control, set `isFocused` to false.
	 */
	onDocClick = (event: MouseEvent) => {
		const targetElement: HTMLElement = event.target as HTMLElement;
		if (
			(targetElement &&
				this.elementRef.nativeElement.contains(targetElement)) ||
			(typeof targetElement.className === 'string' &&
				targetElement.className?.indexOf('btn-close') > -1 &&
				this.elementRef.nativeElement.contains(targetElement))
		) {
			this.isFocusedModel.set(true);
		} else {
			this.isFocusedModel.set(false);
		}
	};

	/** triggered by blur event on textbox. catches cases when user
	 * tabs out of this control which should change isFocused state
	 */
	onBlur() {
		this.onTouched();
	}

	/** triggered by focus event on textbox. catches cases when user
	 * tabs to this control which should change isFocused state
	 */
	onFocus() {
		this.scrollHeight = 0;
		this.scrollUpCount = 0;

		this.SelectedIndexModel.set(0);
		this.isFocusedModel.set(true);
	}

	onKeyDown(event) {
		if (!!this.limit && this.selectedItem?.length >= this.limit) {
			// Prevent typing if selection limit is reached
			event.preventDefault();
			return;
		}
	}

	/** function to filter choices using search string. */
	private matchFunction: (
		items: ViewDisplayValue[],
		searchTerm: string
	) => ViewDisplayValue[] = (items, search) => {
		if (!search) {
			return items;
		}
		return items?.filter((x) => {
			let formattedItem = x.display?.replace(' ', '');
			formattedItem = formattedItem?.toLowerCase();

			let formattedSearch = search?.replace(' ', '');
			formattedSearch = formattedSearch?.toLowerCase();

			return formattedItem?.indexOf(formattedSearch) > -1;
		});
	};
	/** clears search query model that filters choices */
	private clearSearch = () => this.searchModel.set('');
	/** for ngFor optimization */
	trackByFn = (i: number, o: ViewDisplayValue) => o.value;

	/** selects currently selected choice. triggers on enter key. */
	pressEnter(event: KeyboardEvent) {
		this.filteredChoices$
			.pipe(take(1), withLatestFrom(this.selectedIndex$, this.search$))
			.subscribe(([choices, index, search]) => {
				const hasSelected = choices?.find((x) => x.isSelected);
				if (typeof search === 'string' && search.length > 0 && !hasSelected) {
					return;
				}
				if (index === choices.length - 1) {
					this.SelectedIndexModel.set(index - 1);
				}
				this.choose(hasSelected, event);
			});
	}
	/** changes which choice is selected. scrolls the view if necessary. */
	pressArrowDown() {
		this.filteredChoices$
			.pipe(take(1), withLatestFrom(this.selectedIndex$))
			.subscribe(([choices, index]) => {
				if (choices.length - 1 === index) {
					return;
				}
				this.SelectedIndexModel.set(index + 1);

				if (this.scrollUpCount > 0) {
					this.scrollUpCount -= 1;
				}

				if (index !== choices.length - 1 && index > 6) {
					this.scrollHeight = this.scrollHeight + 30;
					$(this.dropdown.nativeElement).scrollTop(this.scrollHeight);
				}
			});
	}

	/** changes which choice is selected. scrolls the view if necessary. */
	pressArrowUp() {
		this.filteredChoices$
			.pipe(take(1), withLatestFrom(this.selectedIndex$))
			.subscribe(([, index]) => {
				if (index === 0) {
					return;
				}
				this.SelectedIndexModel.set(index - 1);

				this.scrollUpCount += 1;
				if (this.scrollUpCount === 8) {
					this.scrollUpCount = 0;
					this.scrollHeight =
						this.scrollHeight - 252 < 0 ? 0 : this.scrollHeight - 252;
					$(this.dropdown.nativeElement).scrollTop(this.scrollHeight);
				}
			});
	}
	/** delete last choice selected */
	pressBackspace() {
		this.search$
			.pipe(take(1), withLatestFrom(this.model$, this.viewModel$))
			.subscribe(([s, , vm]) => {
				if ((typeof s === 'string' && s.length > 0) || vm.length === 0) {
					return;
				}
				this.remove(vm[vm.length - 1]);
			});
	}
	/** sets isFocused to false. triggers on pressing tab. */
	pressTab() {
		this.isFocusedModel.set(false);
	}
	/**
	 * Expand control and show all items
	 *
	 * * hide count badge.
	 * * show all selected items that were hidden when control was collapsed.
	 * * set height to its natural height which would show all selected items.
	 */
	expandControl() {
		this.hideBadge();
		this.resetSelectedItemStyles();
		this.resetWrapperStyles();
	}
	/**
	 * Collapse control to hide other items.
	 *
	 * * Reset styles to be able to get real size.
	 * * collapse control height.
	 * * hide `selectedItem`s that are not on the first line.
	 * * show count badge if there are hidden `selectedItem`s.
	 * * truncate first item if text is too long.
	 */
	collapseControl() {
		if (!this.collapsible) {
			this.resetWrapperStyles();
			return;
		}
		this.resetSelectedItemStyles();
		this.collapseWrapper();
		
		let wrapperWidth = $(this.wrapper && this.wrapper.nativeElement).actual(
			'width'
		);
		const badgeApproxWidth = 50;
		const WrapperWidthWithBadge = wrapperWidth - badgeApproxWidth;
		const selectedItemWidths =
			this.selectedItem &&
			this.selectedItem
				?.map((el) => el.nativeElement)
				?.map($)
				?.map((x: any) =>
					x.actual('outerWidth', {
						includeMargin: true,
						display: 'inline-block',
						clone: true,
					})
				);

		let updateWidths = selectedItemWidths;
		if (selectedItemWidths && this.addValue) {
			updateWidths = updateWidths.map((width) => this.addValue + width);
		}
		const totalWidthOfAllItems = updateWidths?.reduce((a, c) => a + c, 0);
		wrapperWidth =
			totalWidthOfAllItems < wrapperWidth
				? wrapperWidth
				: WrapperWidthWithBadge;

		const MinIndexExceedingWrapperWidth = this.getLeastExceedingMax(
			wrapperWidth,
			updateWidths
		);
		const hasItems = this.selectedItem && this.selectedItem.length > 0;
		const firstItemExceedsWrapperSize = hasItems
			? wrapperWidth <
			  $(this.selectedItem.first.nativeElement).actual('outerWidth', {
					includeMargin: true,
					display: 'inline-block',
					clone: true,
			  })
			: false;

		const hasPillsToHide = hasItems && MinIndexExceedingWrapperWidth > 0;
		const countOfPillsToHide = hasPillsToHide
			? this.selectedItem.length - MinIndexExceedingWrapperWidth
			: 0;

		if (countOfPillsToHide > 0) {
			this.hideOtherSelectedItems(MinIndexExceedingWrapperWidth);
			this.showBadge(countOfPillsToHide);
		} else {
			this.hideBadge();
			if (
				countOfPillsToHide === 0 &&
				selectedItemWidths &&
				selectedItemWidths.length > 1 &&
				MinIndexExceedingWrapperWidth >= 0
			) {
				const selectedItemWidthsLength =
					selectedItemWidths && selectedItemWidths.length - 1;
				if (selectedItemWidthsLength > 0) {
					this.showBadge(selectedItemWidthsLength);
					this.hideOtherSelectedItems(MinIndexExceedingWrapperWidth + 1);
				}
			}
		}
		if (hasItems && firstItemExceedsWrapperSize) {
			this.truncateFirstItemThatIsToolong();
		}

		this.addValue = 0;
	}

	/**
	 * reset styles for selectedItems element.
	 * Used to get correct sizes to compute what to show.
	 */
	private resetSelectedItemStyles() {
		$(this.selectedItem && this.selectedItem?.map((x) => x.nativeElement))
			.removeClass('d-none')
			.addClass('d-flex')
			.css('width', '')
			.children()
			.first()
			.removeClass('text-truncate');
	}

	/** Reset styles for wrapper element.
	 * Show textbox that was hidden by `collapseWrapper()`
	 *
	 * @note
	 * Should reverse `collapseWrapper()` method.
	 */
	private resetWrapperStyles() {
		$(this.wrapper && this.wrapper.nativeElement)
			.removeClass('chips-control__container--collapsed')
			.removeClass('overflow-hidden')
			.css('padding-right', '');
		// $(this.textbox.nativeElement).removeClass('d-none');
	}

	/**
	 * Collapse wrapper to be one liner by height.
	 * Hide textbox since it is not included in size calculations
	 *
	 * @note
	 * `resetWrapperStyles()` to reset styles.
	 */
	private collapseWrapper() {
		$(this.wrapper && this.wrapper.nativeElement)
			.addClass('chips-control__container--collapsed')
			.addClass('overflow-hidden');
		// .css('padding-right', '60px');
		// $(this.textbox.nativeElement).addClass('d-none');
	}

	/**
	 * Hide `selectedItem` that are not on the first line when control is collapsed.
	 * Should be reversed by `resetSelectedItemStyles()`
	 *
	 * @param startIndex
	 * `selectedItem` start index to hide
	 */
	private hideOtherSelectedItems(startIndex: number) {
		$(this.selectedItem?.map((x) => x.nativeElement))
			?.slice(startIndex)
			.removeClass('d-flex')
			.addClass('d-none');
	}
	/** style very long first item as elipsis */
	private truncateFirstItemThatIsToolong() {
		$(this.selectedItem.first.nativeElement)
			.css('width', '90%') // reduce this to 90% from 100% for the input to have a space for input within the same line and not to make a new line
			.children()
			.first()
			.addClass('text-truncate');
	}

	/**
	 * Show badge by manipulating classes.
	 * Also sets badge value.
	 *
	 * @param pillsToHideCount
	 * count of `selectedItems` to hide.
	 * Need this to show how many is hidden when control is collapsed.
	 */
	private showBadge(pillsToHideCount: number) {
		this.hasBadge$.next(true);
		$(this.badge.nativeElement)
			.text(`+${pillsToHideCount} more`)
			.removeClass('d-none');
	}

	/** hide badge by manipulating classes. */
	private hideBadge() {
		this.hasBadge$.next(false);
		$(this.badge && this.badge.nativeElement).addClass('d-none');
	}

	/** Get chip index that surpass wrapper width
	 * @description
	 * Get index of the first item (in `addends`) to have its accumulated
	 * value (from the start) to exceed `max`.
	 * Used in getting start index of `selectedItem` elements to hide.
	 *
	 * @param max
	 * number to exceed.
	 * @param addends
	 * list of numbers in order.
	 */
	private getLeastExceedingMax(max: number, addends: number[]) {
		return addends?.findIndex(
			(el, i, all) => all.slice(0, i + 1)?.reduce((x, y) => x + y, 0) > max
		);
	}

	labels(labels: ViewDisplayValue[]) {
		return labels
			? R.map((label) => label.display, labels)?.join(', ')
			: labels;
	}

	onDropdownScroll(e: Event): void {
		/**
		 * calculate drop down scroll
		 * when user select multiple using ctrl + click
		 * so we can re-scroll the dropdown when it render
		 */
		const target = e.target as HTMLElement;
		this.scrollPercentage = Math.floor(
			(target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100
		);
	}
}
