import { Note, NoteState } from '../../notes/note.model';

export interface AssetCustomerService {
	CustomerServiceID: number;
	CustomerID: number;
	Asset: string;
	Description: string;
	Owner: string;
	Value: number;
	ServiceCode: string;
	Note: string;
	Notes: Note[];
	Status: string;
}

export interface AssetCustomerServiceState {
	serviceCode: string;
	customerServiceID: number;
	customerID: number;
	asset: string;
	description: string;
	owner: string;
	value: number;
	note?: string;
	notes?: NoteState[];
	status?: string;
	color?: string;
}

export interface Asset {
	CustomerServices: AssetCustomerService[];
	Notes: NoteState[];
}

export interface AssetState {
	totalValue?: string | number;
	notes: NoteState[];
	customerServices: AssetCustomerServiceState[];
}

export interface LiabilityCustomerServiceState {
	customerID: number;
	customerServiceID: number;
	borrower: string;
	liability: string;
	loanType: string;
	lender: string;
	interestRate: number;
	loanBalance: number;
	loanLimit: string;
	loanRepayment: number;
	repaymentFrequency: string;
	status: string;
	serviceCode: string;
	note?: string;

	// UI properties
	isEditing?: boolean;
	isAdd?: boolean;
	isDeleting?: boolean;
}

export interface LiabilityState {
	totalValue?: string | number;
	notes: NoteState[];
	customerServices: LiabilityCustomerServiceState[];
}

export enum AssetStatus {
	Current = 'Current',
	Sold = 'Sold',
}
