<div class="card-header font-weight-bold text-light tap-bg-primary">
  {{ header }}
</div>
<div class="card-body p-4">
  <form [formGroup]="form" [class.submitted]="submitted">
    <div class="form-row align-items-center">
      <div class="col-4 col-lg-2 mb-2 text-left">
        <label for="" class="position-relative font-weight-bold"> Name </label>
      </div>
      <div class="col-8 col-lg-3 mb-2">
        <input
          id="gPName"
          formControlName="gPName"
          class="crt-form-control form-control"
          type="text"
          placeholder="Name"
        />
      </div>
      <div class="col-4 col-lg-3 text-lg-right mb-2">
        <label for="" class="position-relative font-weight-bold"> Clients </label>
      </div>
      <div class="col-8 col-lg-4 mb-2">
        <app-chips
					id="client"
					textboxId="client"
					class="crt-form-control-field"
					formControlName="client"
					[choices]="peoples$ | async"
					[attr.disabled]="viewMode"
				></app-chips>
      </div>
    </div>
    <div class="form-row align-items-center">
      <div class="col-4 col-lg-2 mb-2 text-left">
        <label for="" class="position-relative font-weight-bold"> Professional Type </label>
      </div>
      <div class="col-8 col-lg-3 mb-2 ">
				<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
          <select
            id="professionalType"
            formControlName="professionalType"
            class="crt-form-control form-control theme-crm-field-disabled"
          >
            <option value="" selected hidden></option>
            <option
              *ngFor="let d of professionalTypes"
              [value]="d.DropdownValue"
            >
              {{ d.DropdownValue }}
            </option>
          </select>
        </span>
      </div>
      <div class="col-4 col-lg-3 text-lg-right mb-2">
        <label for="" class="position-relative font-weight-bold">Email</label>
      </div>
      <div class="col-8 col-lg-4 mb-2">
        <input
          id="emailAddress"
          formControlName="emailAddress"
          class="crt-form-control form-control"
          type="text"
          placeholder="Email"
        />
      </div>
    </div>
    <div class="form-row align-items-center">
      <div class="col-4 col-lg-2 text-left mb-2">
        <label for="" class="position-relative font-weight-bold"> Business Name </label>
      </div>
      <div class="col-8 col-lg-3 mb-2">
        <input
          id="businessName"
          formControlName="businessName"
          class="crt-form-control form-control"
          type="text"
          placeholder="Business Name"
        />
      </div>
      <div class="col-4 col-lg-3 text-lg-right mb-2">
        <label for="" class="position-relative font-weight-bold"> Phone </label>
      </div>
      <div class="col-8 col-lg-4 mb-2">
        <input
          id="phoneNumber"
          formControlName="phoneNumber"
          class="crt-form-control form-control"
          type="text"
          placeholder="Phone Number"
        />
      </div>
    </div>
    <div class="form-row align-items-center">
      <div class="col-4 col-lg-2 mb-2 text-left">
        <label for="" class="position-relative font-weight-bold"> Physical Address </label>
      </div>
      <div class="col-8 col-lg-10 mb-2">
        <app-address-input
          inputId="physicalAddress"
          class="crt-form-control-field"
          formControlName="physicalAddress"
          textboxClass="theme-crm-field-disabled"
          tabindex="24"
					[attr.disabled]="viewMode"
        ></app-address-input>
      </div>
    </div>
    <div class="form-buttons">
			<ng-container *ngIf="viewMode else upsertMode">
				<button
					id="closeButton"
					type="button"
					class="btn-light btn-block mb-2 mb-lg-0"
					(click)="cancelClick()"
				>
					Close
				</button>
			</ng-container>
			<ng-template #upsertMode>
				<button
					id="saveButton"
					type="button"
					class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
					(click)="save()"
					[disabled]="loadingState"
				>
					Save
					<i *ngIf="loadingState" class="fas fa-spinner fa-pulse"></i>
				</button>
				<button
					id="cancelButton"
					type="button"
					class="btn-light btn-block mb-2 mb-lg-0"
					(click)="cancelClick()"
					[disabled]="loadingState"
				>
					Cancel
				</button>
			</ng-template>
    </div>
  </form>
</div>
