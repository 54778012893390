import { Injectable } from '@angular/core';
import { staticConf } from '@core/config/static-config.service';
import MomentUtil from '@util/moment.util';
import { convertUtil, objectUtil } from '@util/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { delay, map, mergeMap, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { Announcement } from '../../state/announcement.model';
import { AnnouncementPopupComponent } from './announcement-popup.component';

@Injectable()
export class AnnouncementPopupService {
	popupIsShown = false;

	constructor(
		private apiService: ApiService,
		private modalService: BsModalService
	) {}

	show(staffId: number): Observable<any> {
		if (this.popupIsShown) {
			return of(null);
		}
		this.popupIsShown = true;
		return this.getAll(staffId).pipe(
			mergeMap((announcements) =>
				announcements?.length
					? this.showPopup(staffId, announcements)
					: of(true)
			)
		);
	}

	private showPopup(
		staffId: number,
		announcements: Announcement[]
	): Observable<boolean> {
		const acknowledged = new Subject<boolean>();
		const modalRef = this.modalService.show(AnnouncementPopupComponent, {
			class: 'modal-dialog modal-dialog-centered modal-lg announcement-popup',
			ignoreBackdropClick: true,
			initialState: {
				acknowledged,
				announcements,
				staffId,
				announcemenstUrlBodyToHtml: (announcement: Announcement[]) =>
					this.announcemenstUrlBodyToHtml(announcement),
				acknowledge: (ids: number[]) => this.acknowledge(staffId, ids),
			},
		});
		return acknowledged.pipe(tap(() => modalRef.hide()));
	}

	getAll(staffId: number): Observable<Announcement[]> {
		const endpoint = `announcements/popup/${staffId}`;
		return this.apiService.get(endpoint).pipe(
			map((x: any[]) => {
				return Array.isArray(x)
					? (x?.map(objectUtil.mapPascalCaseToCamelCase) as Announcement[]) ??
							[]
					: [];
			}),
			map((x) => {
				return x?.map((announcement) => {
					announcement.scheduledDate = MomentUtil.formatToDisplayDate(
						MomentUtil.DateStringToMoment(announcement.scheduledDate),
						staticConf.displayDateFormat
					);
					return announcement;
				});
			})
		);
	}

	acknowledge(staffId: number, ids: number[]): Observable<boolean> {
		const endpoint = `announcements/acknowledge`;
		return this.apiService.post(endpoint, {
			AnnouncementIds: ids.map((id) => +id),
			StaffId: staffId,
		});
	}

	announcemenstUrlBodyToHtml(
		announcements: Announcement[]
	): Observable<Announcement[]> {
		const getBodyFromUrl = (
			announcement: Announcement
		): Observable<Announcement> => {
			return this.apiService
				.getExternalResource(announcement.announcementLink as string, {
					responseType: 'blob',
				})
				.pipe(
					mergeMap((result) => {
						return convertUtil.convertToText(result);
					}),
					map((htmlBody) => {
						announcement.hTMLBody = htmlBody;
						return announcement;
					})
				);
		};
		const obs = announcements.map((announcement) =>
			getBodyFromUrl(announcement)
		);
		return forkJoin(obs);
	}
}
