import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import { iif, Observable, of } from 'rxjs';
import {
	catchError,
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { MergeTagsService } from '../../../../../../modules/user/service/merge-tags.service';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { ProviderCommissionService } from '../../../../../../modules/user/service/provider-commission.service';
import { ProviderCommissionMapper } from '../../../../../../shared/models/provider-commission/provider-commission.mapper';
import * as R from 'ramda';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-fg-provider',
	templateUrl: './fg-provider.component.html',
	styleUrls: ['./fg-provider.component.scss'],
})
export class FgProviderComponent implements OnInit {
	@Input() staffId: number;
	@Input() isProviderLoading$: Observable<boolean>;

	form: UntypedFormGroup;
	typeCode = 'PCFG';
	providerCommissionFG$ = this.pcService.providerCommissionFG$;
	init = true;
	editMode = false;
	addMode = false;
	isSaving = false;
	tempData;

	constructor(
		private fb: UntypedFormBuilder,
		private pcService: ProviderCommissionService,
		private loggerService: LoggerService,
		private mtService: MergeTagsService
	) {
		this.buildForm();
	}

	get ProviderCommission() {
		return this.form.get('providerCommission') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.isProviderLoading$
			.pipe(
				filter((x) => !x),
				tap(() => this.prepData()),
				take(1)
			)
			.subscribe();
	}

	prepData() {
		this.providerCommissionFG$
			.pipe(
				map((data) => data?.map((item) => this.addItem(item))),
				delay(100),
				tap(() => {
					this.form.disable();
					this.init = false;
				}),
				take(1)
			)
			.subscribe();
	}

	buildForm() {
		this.form = this.fb.group({
			providerCommission: this.fb.array([]),
		});
	}

	addItem(data?: any, isAdd: boolean = false) {
		const defaultOrder = isAdd
			? this.ProviderCommission.length
			: data?.providerCommissionOrder || 0;
		this.ProviderCommission.push(
			this.fb.group({
				referenceId: [data?.referenceId ?? this.staffId],
				settingsId: [data?.settingsId ?? null],
				type: [data?.type || null],
				isTicked: [isAdd ? true : data?.isTicked],
				provider: [data?.provider ?? ''],
				minUpfront: [data?.minUpfront ?? ''],
				maxUpfront: [data?.maxUpfront ?? ''],
				minRenewal: [data?.minRenewal ?? ''],
				maxRenewal: [data?.maxRenewal ?? ''],
				fee: [data?.fee ?? ''],
				fromGlobalSettings: [data?.fromGlobalSettings || false],
				providerCommissionOrder: [defaultOrder],
				order: defaultOrder,
				isNew: isAdd,
				isLoading: false,
				isTickUpdateLoading: false,
			})
		);
	}

	saveItem(i, updateTick?) {
		const itemValue = this.form.getRawValue()?.providerCommission[i];

		if (this.form.invalid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		this.ProviderCommission.controls[i].get('isLoading').setValue(true);
		this.isSaving = true;

		of(itemValue)
			.pipe(
				map((data) =>
					ProviderCommissionMapper.mapToUpsert(
						{ ...data, ...updateTick },
						this.staffId,
						this.typeCode
					)
				),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.settingsId),
						this.pcService.addProviderCommissionFG(data),
						this.pcService.updateProviderCommissionFG(data)
					)
				),
				tap((res) => {
					if (typeof res === 'number') {
						this.ProviderCommission.controls[i].get('settingsId').setValue(res);
					}
				}),
				delay(1000),
				mergeMap(() =>
					// this.mtService.getNewProviderTags(3, 'settings', +this.staffId)
					this.mtService.getProviderMergeTagValues(this.staffId, this.typeCode)
				),
				catchError(() => {
					return of(null);
				}),
				take(1)
			)
			.subscribe((x) => {
				if (x) {
					this.ProviderCommission.controls[i].get('isNew').setValue(false);
					this.ProviderCommission.controls[i].get('isLoading').setValue(false);

					const formData = this.form.getRawValue();
					this.patchTempData(formData);
					this.reset();
				} else {
					this.isSaving = false;
					this.ProviderCommission.controls[i].get('isLoading').setValue(false);
				}
			});
	}

	setDisabled(action: boolean = false) {
		this.ProviderCommission.controls?.forEach((x, i) => {
			if (action) {
				this.ProviderCommission.controls[i].get('provider').disable();
				this.ProviderCommission.controls[i].get('minUpfront').disable();
				this.ProviderCommission.controls[i].get('maxUpfront').disable();
				this.ProviderCommission.controls[i].get('minRenewal').disable();
				this.ProviderCommission.controls[i].get('maxRenewal').disable();
				this.ProviderCommission.controls[i].get('fee').disable();
			} else {
				this.ProviderCommission.controls[i].get('provider').enable();
				this.ProviderCommission.controls[i].get('minUpfront').enable();
				this.ProviderCommission.controls[i].get('maxUpfront').enable();
				this.ProviderCommission.controls[i].get('minRenewal').enable();
				this.ProviderCommission.controls[i].get('maxRenewal').enable();
				this.ProviderCommission.controls[i].get('fee').enable();
			}
		});
	}

	deleteItem(i) {
		const itemValue = this.form.getRawValue()?.providerCommission[i];
		this.ProviderCommission.controls[i].get('isLoading').setValue(true);

		of(itemValue)
			.pipe(
				mergeMap((data) =>
					this.pcService.deleteProviderCommissionFG(+data.settingsId)
				),
				mergeMap(() =>
					this.mtService.getProviderMergeTagValues(this.staffId, this.typeCode)
				),
				take(1)
			)
			.subscribe(() => {
				this.ProviderCommission.controls[i].get('isLoading').setValue(false);
				this.ProviderCommission.removeAt(i);
				this.tempData?.providerCommission?.splice(i, 1);
			});
	}

	addNewItem() {
		this.addMode = true;
		this.addItem({}, true);
	}

	deleteNewItem(index) {
		this.addMode = false;
		this.ProviderCommission.removeAt(index);
	}

	updateTick(i) {
		const item = this.ProviderCommission.controls[i];
		const currentValue =
			this.ProviderCommission.controls[i].get('isTicked').value;
		if (!item?.value.isNew && !this.init) {
			this.saveItem(i, {
				isTicked: !currentValue,
			});
		}
		this.ProviderCommission.controls[i].get('isTicked').setValue(!currentValue);
	}

	cancelEdit() {
		if (this.addMode) {
			this.ProviderCommission.controls?.pop();
		}
		this.patchTempData();
		this.reset();
	}

	patchTempData(data?) {
		if (data) {
			this.form.reset(data);
		} else {
			this.form.patchValue(this.tempData);
		}
		this.tempData = null;
	}

	editAll() {
		this.editMode = true;
		this.tempData = this.form.getRawValue();
		this.setDisabled(false);
	}

	saveAll() {
		this.isSaving = true;
		const formData = this.ProviderCommission.getRawValue() || [];

		of(formData)
			.pipe(
				map((x) => ProviderCommissionMapper.updateOrder(x)),
				map((x) =>
					x?.map((i) =>
						ProviderCommissionMapper.mapToUpsert(i, this.staffId, this.typeCode)
					)
				),
				mergeMap((x) => this.pcService.bulkPcUpdate(x, this.typeCode)),
				mergeMap(() =>
					this.mtService.getProviderMergeTagValues(this.staffId, this.typeCode)
				),
				finalize(() => this.reset()),
				take(1)
			)
			.subscribe();
	}

	reset() {
		this.editMode = false;
		this.addMode = false;
		this.isSaving = false;
		this.tempData = null;
		this.setDisabled(true);
	}

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			// if transfer, recalculate the order of previous (the list from drag)
			event.previousContainer.data?.forEach((x, index) => {
				x.order = index;
			});
		}
		// always, recalculate the order of the container (the list to drag)
		event.container.data?.forEach((x, index) => {
			x.order = index;
		});
	}
}
