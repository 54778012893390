<div class="row tap-container soa-settings pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Document List">
					<app-document-list></app-document-list>
				</tab>
			</ng-container>
			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Advice Summary - Template">
					<app-advice-summary-template
						[adviceSummaryData]="data"
						[template]="template"
						[mergeTags$]="mergeTags$"
					>
					</app-advice-summary-template>
				</tab>
			</ng-container>

			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Email Settings">
					<app-advice-summary-email-settings
						[adviceSummaryData]="data"
						[mergeTags$]="mergeTags$"
						[emailSettings]="adviceSummaryEmailSettings$ | async"
					></app-advice-summary-email-settings>
				</tab>
			</ng-container>

			<ng-container
				*ngIf="['SO', 'BO', 'SM']?.includes(userSecurityGroup$ | async)"
			>
				<tab heading="Merge Tags Wordings">
					<app-advice-summary-merge-tags-wording [adviceSummaryData]="data"></app-advice-summary-merge-tags-wording>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
