import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	ReplaySubject,
	throwError,
	Observable,
	of,
} from 'rxjs';
import Config from '../../shared/models/_general/config';
import { Router, NavigationEnd } from '@angular/router';
import Service from '../../shared/models/_general/service';
import { ApiService } from '../base/api.service';
import {
	catchError,
	distinctUntilChanged,
	filter,
	tap,
	map,
} from 'rxjs/operators';
import { ModelFactory, Model } from '../base/model.service';
import { LocalService } from '../services/local.service';

@Injectable()
export class ConfigService {
	CompanyCodeModel: Model<string>;
	CompanyCode: Observable<string>;
	CompanyModel: Model<Config>;
	BusinessConfig: Observable<Config>;

	constructor(
		private router: Router,
		private api: ApiService,
		private localService: LocalService,
		stringModelFactory: ModelFactory<string>,
		companyModelFactory: ModelFactory<Config>
	) {
		this.CompanyCodeModel = stringModelFactory.create('');
		this.CompanyCode = this.CompanyCodeModel.data$.pipe(distinctUntilChanged());
		this.CompanyModel = companyModelFactory.create(null);
		this.BusinessConfig = this.CompanyModel.data$.pipe(distinctUntilChanged());

		this.BusinessConfig.subscribe();
		this.CompanyCode.pipe(filter((x) => !x)).subscribe((x) => {
			this.ClearBusinessConfig();
		});

		this.CompanyCodeModel.set(this.getCompanyCode('CompanyCode'));
		this.router.events
			.pipe(filter((x) => x instanceof NavigationEnd))
			.subscribe((x) => {
				const elems = document.querySelectorAll(
					'app-client-review-template,app-loat'
				);
				if (elems.length > 0) {
					elems.forEach((elem) => elem.scrollTo(0, 0));
				} else {
					window.scrollTo(0, 0);
				}
				// const elem = elems.length > 0 ? elems[0] : window;
				// elem.scrollTo(0, 0);
			});
	}

	GetBlBusinessConfig() {
		const endpoint = `businesses/active`;
		return this.api.get<Config>(endpoint);
	}
	ReloadBusinessConfig(): Observable<Config> {
		return this.GetBlBusinessConfig().pipe(
			tap((x) => this.CompanyModel.set(x))
		);
	}
	ClearBusinessConfig() {
		this.CompanyModel.set(null);
	}

	SetCompanyCode(code: string) {
		// const endpoint = 'Home/setCompanyCode';
		// const body = {
		//   code,
		// };
		// return this.api
		//   .post3(endpoint, body)
		this.localService.setValue('code', code);
		return of(code)
			.pipe(
				catchError((err) => {
					return throwError(new Error(err));
				})
			)
			.pipe(
				map((x) => this.getCompanyCode('CompanyCode')),
				tap((x) => this.CompanyCodeModel.set(x))
			);
	}
	clearCompanyCode() {
		return this.SetCompanyCode(null);
	}
	getCompanyCode(name): string {
		// const value = '; ' + document.cookie;
		// const parts = value.split('; ' + name + '=');
		// if (parts.length === 2) {
		//   return parts
		//     .pop()
		//     .split(';')
		//     .shift();
		// }
		return this.localService.getValue('code') ?? '';
	}
	GetAllServices(): Observable<Service[]> {
		const endpoint = `Service/GetAllServices`;
		return this.api.post3<Service[]>(endpoint, {});
	}
	// tslint:disable-next-line: member-ordering
	private isViewTapLevel = new BehaviorSubject<boolean>(false);
	get IsViewTapLevel() {
		return this.isViewTapLevel.asObservable();
	}
	SetIsViewTapLevel(isTapLevelView: boolean) {
		this.isViewTapLevel.next(isTapLevelView);
	}
	// tslint:disable-next-line: member-ordering
	private isViewCrmLevel = new ReplaySubject<boolean>(1);
	get IsViewCrmLevel() {
		return this.isViewCrmLevel.asObservable();
	}
	SetIsViewCrmLevel(isViewCrmLevel: boolean) {
		this.isViewCrmLevel.next(isViewCrmLevel);
	}
}
