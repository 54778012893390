import { MoatVersionService } from './../../service/moat-version.service';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isNil } from 'ramda';
import {
	combineLatest,
	forkJoin,
	from,
	iif,
	Observable,
	of,
	Subject,
} from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	skipWhile,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	Fields,
	getInvalidWarning,
	logMessage,
	getAlreadyExists,
} from 'src/app/shared/error-message/error-message';
import {
	IncomeType,
	PropertyUse,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { convertToAnnual } from '../../../../../../modules/crm/client-review-template/income-budget/calculations/annual-conversion';
import {
	computeAnnualTaxable,
	computeMonthlyAfterTaxIncome,
	computeMonthlyTaxable,
} from '../../calculations/compute-taxable';
import { numUtil } from '../../../../../../util/util';
import { PropertyService } from '../../../crt-mortgage/client-sop/assets-and-liabilities/state/property/property.service';
import { PropertyAddressState } from '../../../crt-mortgage/client-sop/assets-and-liabilities/state/property/property.store';
import { IncomeService } from '../../../crt-mortgage/client-sop/income/state/income.service';
import { CrtMortgageQuery } from '../../../crt-mortgage/state/crt-mortgage.query';
import { RentalIncomeMapper } from '../../mapper/income.mapper';
import {
	FactFindComputationState,
	IncomeSourceState,
	RentalIncomeObjState,
	RentalIncomeState,
} from '../../models/income.model';

declare var $: any;

@Component({
	selector: 'app-crt-rental-income',
	templateUrl: './crt-rental-income.component.html',
	styleUrls: ['./crt-rental-income.component.scss'],
})
export class CrtRentalIncomeComponent implements OnInit, OnDestroy {
	@Input() netRentalIncome$: Observable<RentalIncomeObjState>;
	@Input() properties$: Observable<PropertyAddressState[]>;
	@Input() updateFn$: (netRentalIncome) => Observable<RentalIncomeState>;
	@Input() addNewFn$: (netRentalIncome) => Observable<RentalIncomeState>;
	@Input() deleteFn$: (netRentalIncome) => Observable<number>;
	@Input() factFindComputation$: Observable<FactFindComputationState>;
	@Input() incomeSource$: Observable<IncomeSourceState[]>;
	@Input() allIncomeList$: Observable<any>;
	onDestroy$ = new Subject<void>();

	elseMinusRental = true;
	idRental = 0;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	factFind: FactFindComputationState = {};
	incomeList: IncomeSourceState[];
	propertyList: PropertyAddressState[];
	isListLoading = true;
	editMode = false;
	addMode = false;
	totalRental = 0;
	annualInvestmentInterest = 0;
	adviceProcessId = this.moatQuery.getValue().adviceProcessId;
	allIncomeList;
	tempData;

	isMoatV2: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private loggerService: LoggerService,
		private incomeService: IncomeService,
		private propertyService: PropertyService,
		private moatQuery: CrtMortgageQuery,
		private zone: NgZone,
		private moatVersionService: MoatVersionService
	) {
		this.buildForm();
	}

	get rentalArray() {
		return this.form.get('rentalArray') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			rentalArray: this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.prepData();
		this.getAllIncome();

		combineLatest([this.incomeSource$, this.factFindComputation$])
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(([income, factFind]) => {
				this.incomeList = income;
				this.totalRental = +factFind?.totalNetRentalIncome;
				this.factFind = factFind;
			});

			this.isMoatV2 = this.moatVersionService.isMoatV2();
	}

	selectedPropertyTooltip(index) {
		return this.form.getRawValue()?.rentalArray[index]?.propertyAddress;
	}

	getAllIncome() {
		this.allIncomeList$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.allIncomeList = x));
	}

	updateRentalValue(index: number, keyName: string, value: any) {
		this.rentalArray.controls[index].get(keyName).setValue(value);
	}

	filteredRentalIncomeList(rental, prop) {
		if (!rental.netRentalIncomeList?.length || !prop?.length) {
			return {
				...rental,
				netRentalIncomeList: [],
			};
		}
		const filteredProp = prop?.filter((property) => property);
		const rentalList = [...(rental?.netRentalIncomeList || [])]?.filter((r) =>
			filteredProp.find((prop) => prop.cRTId === r.propertyAsset)
		);
		return {
			...rental,
			netRentalIncomeList: rentalList,
		};
	}

	prepData() {
		combineLatest([
			this.netRentalIncome$,
			this.properties$,
			this.propertyService.isLoading$,
		])
			.pipe(
				skipWhile(([rental, prop, isPropertyLoading]) => isPropertyLoading),
				filter(([rental, prop]) => !!rental && !!prop),
				tap(([rental, prop]) => {
					this.form.reset();
					RentalIncomeMapper.mapRentalIncome(
						this.filteredRentalIncomeList(rental, prop),
						prop
					)?.map((x, i) => this.addRental(x));
					this.form.disable();
					this.updatePropertyList(
						this.filteredRentalIncomeList(rental, prop)?.netRentalIncomeList
					);
				}),
				switchMap(([rental, prop]) => this.getPropertyRentals(rental, prop)),
				map((PropertyRentals) => {
					PropertyRentals?.forEach((item, i) => {
						if (item?.toUpdate) {
							this.saveRental(i, true, item);
						}
					});
				}),
				take(1)
			)
			.subscribe(() => {
				this.isListLoading = false;
			});
	}

	addRental(data?: any, isAdd: boolean = false) {
		this.rentalArray.push(
			this.fb.group({
				cRTId: [data?.cRTId || ''],
				adviceProcessId: [this.adviceProcessId || ''],
				propertyAsset: [
					data?.propertyAsset || '',
					[Validators.required, NoWhitespaceValidator],
				],
				propertyAddress: [data?.propertyAddress || ''],
				propertyType: [data?.propertyType || ''],
				netRentalIncome: [data?.netRentalIncome || '0'],
				btnSaveRental: isAdd,
				btnEditRental: !isAdd,
				isNew: isAdd,
				isLoading: [false],
				incomeType: [data?.incomeType || ''],
			})
		);
	}

	selectProperty(index: number) {
		const pid = this.rentalArray.controls[index].get('propertyAsset').value;
		this.updateRentalValue(index, 'netRentalIncome', '');
		this.updateRentalValue(index, 'isLoading', true);

		this.incomeService
			.getInfoByCRT(+pid)
			.pipe(
				tap((x) => {
					this.updateRentalValue(index, 'isLoading', false);

					if (this.addMode && x?.propertyUse === PropertyUse.OwnerOccupied) {
						this.rentalArray?.removeAt(index);
						if (!this.isPropertyAdded(pid, IncomeType.Rental)) {
							// If there's no rental income for the selected property
							// Add new entry for boarder income
							this.addNewIncome(x, IncomeType.Rental);
						}
						if (!this.isPropertyAdded(pid, IncomeType.Boarder)) {
							// If there's no boarder income for the selected property
							// Add new entry for boarder income
							this.addNewIncome(x, IncomeType.Boarder);
						}
					} else {
						if (this.addMode) {
							// Make sure to clear other added Rental/Board Income entries that are not yet saved
							this.removeOtherNewlyAdded();
							this.addNewIncome(x, IncomeType.Rental);
						} else {
							// Update current newly added to rental
							this.updateRentalValue(index, 'incomeType', IncomeType.Rental);
							this.computeNetRental(x, index);
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}

	removeOtherNewlyAdded() {
		this.form.getRawValue()?.rentalArray?.forEach((data, index) => {
			if (!data?.cRTId) {
				this.rentalArray?.removeAt(
					this.form.getRawValue()?.rentalArray?.length - 1
				);
			}
		});
	}

	addNewIncome(property, incomeType: string) {
		this.addMode = true;
		const data = {
			adviceProcessId: this.adviceProcessId,
			propertyAsset: +property?.cRTId,
			propertyAddress: property?.propertyAddress,
			propertyType: property?.propertyType,
			incomeType,
			netRentalIncome:
				incomeType === IncomeType.Boarder
					? convertToAnnual(
							+property?.boarderIncome || 0,
							property?.boarderIncomeFrequency
					  )
					: convertToAnnual(
							+property?.rentalIncome || 0,
							property?.rentalIncomeFrequency
					  ),
		};
		this.addRental(data, true);
	}

	isPropertyAdded(id: number, incomeType: string) {
		const rentals = this.form.getRawValue()?.rentalArray || [];
		return !!rentals?.find(
			(x) => +x?.propertyAsset === +id && x?.incomeType === incomeType
		);
	}

	computeNetRental(data: any, index?: number) {
		const rentalIncome = convertToAnnual(
			+data.rentalIncome || 0,
			data.rentalIncomeFrequency
		);
		const totalNetRental = +numUtil.formatToNumCurrency(rentalIncome);
		if (isNaN(index) || isNil(index)) {
			return +totalNetRental;
		}
		this.updateRentalValue(index, 'netRentalIncome', +totalNetRental);
		this.updateRentalValue(index, 'isLoading', false);
	}

	saveRental(index, bypassValidation: boolean = false, newData?) {
		if (!bypassValidation && this.rentalArray?.invalid) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.NetRentalIncome));
			return;
		}
		const isNew = this.rentalArray.controls[index].get('isNew').value;
		const cRTId = !isNew
			? +this.rentalArray.controls[index].get('cRTId').value
			: null;
		const tempFactFind = {
			...this.factFind,
			...this.recompute(
				index,
				this.rentalArray.controls[index].get('netRentalIncome').value,
				this.factFind
			),
		};
		const data = newData ?? this.form.getRawValue()?.rentalArray[index];
		const formValue = RentalIncomeMapper.mapToUpsert(
			data,
			cRTId,
			this.adviceProcessId,
			tempFactFind
		);

		this.updateRentalValue(index, 'isLoading', true);

		// if there is a error we assume that the rental income is already created
		let hasError = false;
		of(formValue)
			.pipe(
				mergeMap((val) => {
					if (isNew) {
						return this.addNewFn$(val).pipe(
							tap((x) => {
								this.updateRentalValue(index, 'cRTId', +x);
							})
						);
					}
					return this.updateFn$(val);
				}),
				catchError((err) => {
					const RentalIncomeAlreadyExistError = err?.['Property Asset'] === 'Record already exist.';
					if (!RentalIncomeAlreadyExistError) {
						this.zone.run(() =>
							this.loggerService.Log(
								err,
								err?.error?.Message || logMessage.shared.general.error
							)
						);
					}
					hasError = true;
					return of(err);
				}),
				tap(() => !hasError && this.setDisabled(index)),
				withLatestFrom(this.properties$),
				tap(([_, properties]) => {
					if (hasError) {
						return;
					}
					const currentAddress = properties?.find(
						(item) => +item.cRTId === +data.propertyAsset
					)?.propertyAddress;
					this.updateRentalValue(index, 'propertyAddress', currentAddress);
					this.updatePropertyList();
				}),
				finalize(() => {
					if (newData) {
						this.updateRentalValue(
							index,
							'netRentalIncome',
							newData?.netRentalIncome
						);
					}
					if (hasError) {
						this.updateRentalValue(index, 'isNew', true);
					} else {
						this.updateRentalValue(index, 'btnSaveRental', false);
						this.updateRentalValue(index, 'btnEditRental', true);
						this.updateRentalValue(index, 'isNew', false);
					}
					this.updateRentalValue(index, 'isLoading', false);

					const hasUnsaved = this.form
						.getRawValue()
						?.rentalArray?.filter((x) => !x?.cRTId);

					if (+hasUnsaved?.length === 0) {
						// Reset only if there are no unsaved new rental/board income entry
						this.tempData = null;
						this.editMode = false;
						this.addMode = false;
						this.updatePropertyList();
					}

					hasError = false;
				}),
				take(1)
			)
			.subscribe();
	}

	updatePropertyList(rentalList?, insert?: number) {
		this.properties$
			.pipe(
				tap((properties) => {
					const getRentalIncomeList = rentalList
						? rentalList
						: this.form.getRawValue()?.rentalArray;
					const selectedAssets = getRentalIncomeList?.map(
						(item) => +item.propertyAsset
					);

					const filterProperties = (item): boolean => {
						return (
							!selectedAssets?.includes(+item.cRTId) &&
							!!item?.rentalIncome &&
							+item.rentalIncome > 0 &&
							!!item?.rentalIncomeFrequency
						);
					};
					const filterPropertyUse = (item): boolean => {
						const rental = getRentalIncomeList?.filter(
							(x) => +x?.propertyAsset === +item?.cRTId && !!x?.cRTId
						);

						return !this.editMode &&
							item?.propertyUse === PropertyUse.OwnerOccupied
							? rental?.length < 2
							: false;
					};
					this.propertyList = properties?.filter(
						(item) => filterProperties(item) || filterPropertyUse(item)
					);

					if (insert) {
						const getInsert = properties?.find(
							(item) => +item.cRTId === +insert
						);
						this.propertyList.push(getInsert);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	setDisabled(i: number) {
		this.updateRentalValue(i, 'btnSaveRental', false);
		this.updateRentalValue(i, 'btnEditRental', true);
		this.rentalArray.controls[i].get('propertyAsset').disable();
	}

	editRental(index) {
		this.editMode = true;
		this.tempData = this.form.getRawValue()?.rentalArray[index];
		this.updateRentalValue(index, 'btnSaveRental', true);
		this.updateRentalValue(index, 'btnEditRental', false);
		this.rentalArray.controls[index].get('propertyAsset').enable();

		const current = this.rentalArray.controls[index].get('propertyAsset').value;
		this.updatePropertyList(null, +current);
	}

	deleteRental(index) {
		this.updateRentalValue(index, 'isLoading', true);

		this.deleteFn$(+this.rentalArray.controls[index].get('cRTId').value)
			.pipe(
				finalize(() => {
					this.rentalArray?.removeAt(index);
					this.updatePropertyList();
				})
			)
			.subscribe();
	}

	deleteNewRental(index) {
		this.rentalArray?.removeAt(index);
		this.addMode = false;
		this.editMode = false;
	}

	cancelEdit(i) {
		this.rentalArray.controls[i].patchValue(this.tempData);
		this.setDisabled(i);
		this.editMode = false;
		this.addMode = false;
		this.tempData = null;
		this.updatePropertyList();
	}

	addNewRental() {
		this.addMode = true;
		const selectedProperty = this.propertyList&&this.propertyList.length>0 ? this.propertyList[0]:null;
		if(this.isMoatV2 && selectedProperty){
			let data = {
				propertyAsset: selectedProperty?.cRTId,
				propertyAddress: selectedProperty?.propertyAddresses,
				propertyType: selectedProperty?.propertyType,
				netRentalIncome: selectedProperty?.netRentalIncome ,
				incomeType: selectedProperty?.incomeType,
			}
			this.addRental(data,true);
			this.selectProperty(this.rentalArray.length-1);
		}else{
			this.addRental({}, true);
		}
	}

	getPropertyRentals = (rental: RentalIncomeObjState, properties = []) => {
		return properties.length > 0
			? from(properties).pipe(
					map(
						() =>
							this.filteredRentalIncomeList(rental, properties)
								?.netRentalIncomeList
					),
					map((data) =>
						data?.map((item) => {
							return {
								...item,
								netRentalIncome: this.getIncome(item, properties),
								toUpdate:
									+this.getIncome(item, properties) !== +item?.netRentalIncome, // Indicator if the property rental/boarder income needs to be auto-updated
							};
						})
					)
			  )
			: of([]);
	};

	getIncome(data, properties) {
		const property = properties?.find(
			(val) => val.cRTId === data.propertyAsset
		);
		return data?.incomeType === IncomeType.Boarder
			? convertToAnnual(
					+property?.boarderIncome || 0,
					property?.boarderIncomeFrequency
			  )
			: convertToAnnual(
					+property?.rentalIncome || 0,
					property?.rentalIncomeFrequency
			  );
	}

	recompute(
		index: number,
		newValue: number,
		factFind: FactFindComputationState
	) {
		// annual
		let tempTotalNetRental = 0;
		this.rentalArray.controls?.map((rental: any, i) => {
			if (index === i) {
				tempTotalNetRental += +newValue;
			} else {
				tempTotalNetRental += +rental.value.netRentalIncome || 0;
			}
		});

		const annualTaxable = computeAnnualTaxable({
			...factFind,
			totalNetRentalIncome: tempTotalNetRental,
		});
		const monthlyTaxable = computeMonthlyTaxable(+annualTaxable);
		const monthlyAfterTax = computeMonthlyAfterTaxIncome(
			this.incomeList,
			{
				...factFind,
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
			},
			this.allIncomeList
		);

		return {
			totalNetRentalIncome: +numUtil.formatToNumCurrency(tempTotalNetRental),
			annualTaxableJointIncome: +annualTaxable,
			monthlyTaxableJointIncome: +monthlyTaxable,
			monthlyAfterTaxIncome: +monthlyAfterTax,
		};
	}

	collapseFalse() {
		this.elseMinusRental = false;
	}

	collapseMoreRental() {
		$('#collapseRental').toggle();
		this.elseMinusRental = false;
	}

	collapseLessRental() {
		$('#collapseRental').toggle();
		this.elseMinusRental = true;
	}

	getTooltipValue = (form): string => {
		if(form) {
			return form?.value;
		}
		return '';
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
