import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { Injectable } from '@angular/core';
import { FgGoalTrackCommercialState } from './fggtc.model';

/**
 * F&G Goal Track Commercial widget state model
 */
export interface FGGTCState {
	/**
	 * F&G Goal Track Commercial data
	 */
	GoalTrack: FgGoalTrackCommercialState;
	/**
	 * error message. will show up when not empty
	 */
	error: string;
	/**
	 * indicator if widget is currently fetching
	 */
	isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): FGGTCState {
	return {
		GoalTrack: {
			QuarterActual: 0,
			QuarterExpected: 0,
			YearActual: 0,
			YearExpected: 0,
			Quarter1: 0,
			Quarter2: 0,
			Quarter3: 0,
			Quarter4: 0,
		},
		error: '',
		isLoading: false,
	};
}

/**
 * F&G Goal Track Commercial widget data store
 */
@Injectable()
@StoreConfig({
	name: 'fgGoalTrackCommercial',
})
export class FGGTCStore extends Store<FGGTCState> {
	/**
	 * F&G Goal Track Commercial store
	 */
	constructor() {
		super(createInitialState());
	}

	/**
	 * Set if widget is loading or not
	 * @param isLoading if widget is loading
	 */
	setLoading(isLoading: boolean): void {
		this.update(
			produce((draft) => {
				draft.isLoading = isLoading;
			})
		);
	}
}
