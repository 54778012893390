<ng-template #popTemplate>
  <form [formGroup]="form" class="dateRange">
    <div class="form-group mb-2">
      <app-date-input
        #minDateInput
        formControlName="ActivityDateMin"
        textboxClass="theme-crm-field-disabled"
        databoxClass="tap-date-range-datepicker"
        placeholder="Min"
        style="flex: 1;"
        class="popover-width"
      >
      </app-date-input>
    </div>
    <div class="form-group mb-3">
      <app-date-input
        #maxDateInput
        formControlName="ActivityDateMax"
        textboxClass="theme-crm-field-disabled"
        databoxClass="tap-date-range-datepicker"
        placeholder="Max"
        style="flex: 1;"
      >
      </app-date-input>
    </div>
    <div class="form-group d-flex justify-content-end">
      <div class="pr-2">
        <button type="button" class="tap-btn tap-btn--default" (click)="set()">
          Set
        </button>
      </div>
      <div>
        <button
          type="button"
          class="tap-btn tap-btn--default"
          (click)="close()"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</ng-template>
<button
  type="button"
  data-container="body"
  containerClass="tap-data-range"
  [placement]="'bottom left'"
  [popover]="popTemplate"
  popoverTitle="Select Date Range"
  class="tap-btn tap-btn--default btn-min-width"
  (onShown)="resetForm($event)"
>
  <i class="material-icons mr-1">
    date_range
  </i>
  {{ nzDateMin | momentDatetimeShort }} - {{ nzDateMax | momentDatetimeShort }}
</button>
