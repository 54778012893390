import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import {
	DocumentModelState,
	DocumentUploadState,
} from '../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../util/util';
import { ApiService } from '../../../../core/base/api.service';
import { CrtSettingsQuery } from '../../state/crt-settings.query';
import { CrtSettingsStore } from '../../state/crt-settings.store';
import { EndProcessEmailSettingsState } from './end-process.model';

@Injectable()
export class EndProcessEmailSettingsService {
	endProcessEmailSettings$ = this.query.endProcessEmailSettings$;

	constructor(
		private api: ApiService,
		private store: CrtSettingsStore,
		private query: CrtSettingsQuery
	) {}

	getSettings(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<EndProcessEmailSettingsState>(endpoint).pipe(
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			tap((res) =>
				applyTransaction(() => this.store.setEndProcessEmailSettings(res))
			),
			catchError(() => of(undefined))
		);
	}

	update(data: EndProcessEmailSettingsState) {
		const endpoint = `crt/settings/${data?.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<EndProcessEmailSettingsState>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.setEndProcessEmailSettings(data);
				})
			),
			take(1)
		);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}
}
