<div
  #wrapper
  class="
    chips-control__container
    position-relative
    d-flex
    py-0
    chips__wrapper
  "
	[class.chips-control__container--collapsed]="!!collapsible"
	[class.overflow-hidden]="!!collapsible"
  [class]="isRemoveChipsPadding ? 'chips-display-container' : 'flex-wrap'"
  [ngClass]="textboxNgClass$ | async"
	[tooltip]="(isShown$ | async) !== true ? labels(viewModel$ | async): ''"
  data-toggle="tooltip"
  container="body"
>
  <div
    #selectedItem
    class="align-items-center chips d-flex mr-1"
    [class]="isRemoveChipsPadding ? '' : 'px-2'"
    [ngClass]="{
      'disabled-chip': isDisabled$ | async,
      'last-chip': last || count <= 1
    }"
    *ngFor="
      let item of viewModel$ | async;
      let i = index;
      let last = last;
      let count = count;
      trackBy: trackByFn
    "
    data-toggle="tooltip"
  >
		<span
			class=""
			[attr.id]="
				!!valueId ? valueId + '_selected_' + item?.display?.replace(idPattern, '') : null
			"
		>
			{{ item.display }}</span
		>
    <button
      type="button"
      class="btn-chips-close btn btn-link py-0 px-1 btn-close"
      style="line-height: 0"
      (click)="!isDisableValue && remove(item)"
      *ngIf="showRemoveIcon || !(isDisabled$ | async)"
      tabindex="-1"
    >
      &times;
    </button>
  </div>
  <input
    #textbox
    size="1"
    type="text"
    class="
      flex-grow-1
      border-0
      no-outline no-disable
      bg-transparent
      theme-crm-field-disabled
    "
    [ngModel]="search$ | async"
    [disabled]="isDisabled$ | async"
    [tabindex]="tabindex"
    (ngModelChange)="this.setSearch($event)"
    (keydown.enter)="$event.preventDefault(); pressEnter($event)"
    (keydown.arrowdown)="pressArrowDown()"
    (keydown.arrowup)="pressArrowUp()"
    (keydown.backspace)="pressBackspace()"
    (keydown.tab)="pressTab()"
    (keydown.shift.tab)="pressTab()"
		(keydown)="onKeyDown($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
		[id]="textboxId"
  />

  <span class="placeholder py-1" *ngIf="(viewModel$ | async).length === 0">{{
    textboxPlaceholder
  }}</span>

  <div
    #badge
    class="
      position-absolute
      chips__badge
      badge badge-pill
      bg-light
      text-dark
      py-1
    "
    data-toggle="tooltip"
  ></div>
  <div
    #dropdown
    [@isFocused]="isShown$ | async"
    class="chips-dropdown custom-width border bg-white"
    [style]="zIndexStyle$ | async"
		[class.d-none]="!(isShown$ | async)"
    [ngClass]="{
      'disabled-dropdown':
        !!limit &&
        (selectedItem?.length === limit || selectedItem?.length > limit)
    }"
    (scroll)="onDropdownScroll($event)"
  >
    <button
      type="button"
      [class.selected]="choice.isSelected"
      *ngFor="let choice of filteredChoices$ | async; trackBy: trackByFn"
      class="w-100 btn btn-link text-dark py-1 text-left"
      (click)="$event.stopPropagation(); choose(choice, $event)"
      tabindex="-1"
    >
		<div
			class="d-flex justify-content-start"
			[attr.id]="
				!!valueId ? valueId + '_suggested_' + choice?.display?.replace(idPattern, '') : null
			"
		>
			{{ choice.display }}
		</div>
    </button>
  </div>
</div>
