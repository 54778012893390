import { Moment } from 'moment';
import { CustomerSearchModel } from '../../_general/customerSearchModel';
import { ServicesCodes } from '@shared/models/services/services.model';

export interface PrimaryClient {
	ContactStatus: string;
	CustomerID: string | number;
	ImportID?: string;
	CreatedByStaffId: string | number;
	IsActive?: boolean;
	CreatedByStaffLevel: string;
	CreateDateTime: string | Date;
	ModifiedDateTime: string | Date;
	Work: string;
	MailingAddress1: string;
	Adviser: string | number;
	LeadType: string;
	Occupation: string;
	LostType: string;
	ReviewPending: string;
	LeadPending: string;
	MailingAddress2: string;
	PhysicalAddress: string;
	DataImportID: string | number;
	KnownAs: string;
	LeadGen: string | number;
	Gender: string;
	PreferredContact: string;
	PreferredEmailContact: string | number;
	Email: string;
	Employment: string;
	Employer: string;
	NextReview: string;
	City: string;
	LeadStatus: string;
	Rank: string;
	FirstName: string;
	ClientSince: string;
	Postcode: string | number;
	DateOfBirth: string;
	HomePhone: string | number;
	LastName: string;
	MiddleName: string;
	Title: string;
	Income: number;
	LastReview: string;
	AltAdviser: string[];
	Mobile: string | number;
	LeadOrigin: string;
	ReferredBy: CustomerSearchModel;
	GroupName: string;
	Relationship: string;
	CustomerType: string;
	PhotoURL?: string;
	FileName?: string;
	IsVulnerable?: boolean;
	LinkedContacts?: any[];
	CountryOfOrigin?: string;
	LRNextReview?: string;
	MortgageNextReview?: string;
	FGNextReview?: string;
	InvestmentKSNextReview?: string;

	// Adviser Rework
	VulnerableNotes?: string;
	LRAdviser?: number;
	MortgageAdviser?: number;
	FGAdviser?: number;
	KSAdviser?: number;
	InvestmentAdviser?: number;
	GIAdviser?: number;
}

export interface PrimaryClientState {
	customerID: number;
	contactStatus: string;
	importID?: string;
	createdByStaffId: string | number;
	isActive?: boolean | number;
	createdByStaffLevel: string;
	createDateTime: string | Moment;
	modifiedDateTime: string | Moment;
	work: string;
	mailingAddress1: string;
	adviser?: string | number;
	preferredEmailContact: string | number;
	leadType: string;
	occupation: string;
	lostType: string;
	reviewPending: string | boolean;
	leadPending: string | boolean;
	mailingAddress2: string;
	physicalAddress: string;
	dataImportID: string | number;
	knownAs: string;
	relationship: string;
	leadGen: string | number;
	gender: string;
	preferredContact: string;
	email: string;
	employment: string;
	employer: string;
	nextReview: string | Moment;
	city: string;
	leadStatus: string;
	rank: string;
	firstName: string;
	clientSince: string;
	postcode: string | number;
	dateOfBirth: string | Moment;
	homePhone: string | number;
	lastName: string;
	middleName: string;
	title: string;
	income: number;
	lastReview: string | Moment;
	altAdviser: string | string[] | number[];
	mobile: string | number;
	leadOrigin: string;
	referredBy:
		| {
				customerId: number;
				isCompany: boolean;
				name: string;
				primaryCustomerId: number;
				isAccessible: boolean;
		  }
		| any;
	groupName: string;
	customerType: string;
	photoURL?: string;
	fileName?: string;
	note?: string;
	professionalContacts: string;
	industry?: string;
	isVulnerable?: boolean;
	linkedContacts?: any[];
	countryOfOrigin?: string;
	adviserGen: string | number;
	lRNextReview?: string;
	mortgageNextReview?: string;
	fGNextReview?: string;
	investmentKSNextReview?: string;

	// Adviser Rework
	vulnerableNotes?: string;
	lRAdviser?: number;
	mortgageAdviser?: number;
	fGAdviser?: number;
	kSAdviser?: number;
	investmentAdviser?: number;
	gIAdviser?: number;
	advisers?: any; //FE Only
	isKeyContact?: boolean;

	//Flag for email duplicate
	saveAnyway?:boolean;
}

export interface AdviserServiceState {
	adviserId: number;
	services: string[];
}

export const SasReference = [
	{
		id: 'lRAdviser',
		display: 'L&R Insurance',
		service: ServicesCodes.LR,
	},
	{
		id: 'mortgageAdviser',
		display: 'Mortgage',
		service: ServicesCodes.Mortgage,
	},
	{
		id: 'fGAdviser',
		display: 'F&G Insurance',
		service: ServicesCodes.FG,
	},
	{
	id: 'kSAdviser',
		display: 'KiwiSaver',
		service: ServicesCodes.KiwiSaver,
	},
	{
		id: 'investmentAdviser',
		display: 'Investment',
		service: ServicesCodes.Investment,
	},
	{
		id: 'gIAdviser',
		display: 'Group Insurance',
		service: ServicesCodes.LR, // Only shows when L&R is selected
	},
];

export interface EmailDuplicateState {
  customerID: number;
  fullName: string;
  customerURL: string;
  dateOfBirth: string;
  contactStatus: string;
}
