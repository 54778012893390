<div class="disability clearfix" #disabilityContent>
	<div class="disability__header d-flex flex-wrap mb-3">
		<ng-container *ngIf="isLoading && !!!activePerson">
			<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
		</ng-container>

		<ng-container *ngIf="!!activePerson && !isLoading">
			<app-risk-analysis-people-tabs
				(selectActivePerson)="setActive($event)"
			></app-risk-analysis-people-tabs>
		</ng-container>
	</div>
	<ng-container *ngIf="!!activePerson && !isLoading">
		<div
			class="disability__content"
			*ngIf="!!(people$ | async) && (people$ | async).length > 0"
		>
			<form [formGroup]="form" novalidate>
				<div class="form-row mb-3">
					<div class="col-12">
						<label class="font-weight-bold label-font tap-text-primary"
							>{{ activePerson?.name }}, How are you affected if you cannot
							work?</label
						>
					</div>
				</div>

				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Lost After Tax Income per month:
					</div>
					<div class="col-12 col-lg-2 mb-2">
						<div class="dollar-icon">
							<input
								class="crt-form-control form-control"
								type="text"
								id="lostAfterTaxIncomerPerMonthId"
								formControlName="lostAfterTaxIncomerPerMonth"
								currencyMask
							/>
							<i>$</i>
						</div>
					</div>
				</div>
				<div class="form-row align-items-center mb-3">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Monthly Surplus / <span class="text-danger">(Shortfall)</span>:
					</div>
					<div class="col-12 col-lg-2 mb-2">
						<div class="dollar-icon">
							<input
								class="crt-form-control form-control"
								type="text"
								id="monthlySurplusOrShortfallLostId"
								formControlName="monthlySurplusOrShortfallLost"
								currencyMask
							/>
							<i>$</i>
						</div>
					</div>
				</div>

				<div class="clearfix" formArrayName="expensesCouldStopList">
					<div
						class="form-row align-items-center"
						*ngFor="
							let item of ExpensesCouldStopList.controls;
							let i = index;
							let f = first;
							let l = last
						"
						[formGroupName]="i"
					>
						<div class="col-12 d-lg-none d-xl-block col-xl-1"></div>
						<div class="col-12 col-lg-2 text-lg-right mb-2">
							<ng-container *ngIf="f">
								Are there any monthly expenses that would stop?
							</ng-container>
						</div>
						<div class="col-12 col-lg-3 mb-2">
							<span class="crt-custom-dropdown w-100">
								<select
									formControlName="dropdown"
									id="expensesStopDropdownId_{{ i }}"
									class="crt-form-control form-control"
									(change)="onChange()"
								>
									<option value=""></option>
									<option *ngFor="let d of apcrte$ | async" [value]="d.value">
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
						<div class="col-12 col-lg-2 mb-2">
							<div class="dollar-icon">
								<input
									id="expensesStopValueId_{{ i }}"
									formControlName="value"
									class="crt-form-control form-control"
									type="text"
									(focusout)="onChange()"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
						<div
							class="col-12 col-lg-auto d-flex align-items-center justify-content-end mb-2"
						>
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
								<button
									id="removeExpensesCouldStopBtn_{{ i }}"
									*ngIf="ExpensesCouldStopList.controls.length > 1"
									(click)="removeList(ExpensesCouldStopList, i)"
									type="button"
									class="icon-btn w-auto h-auto px-1 d-flex"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
								<button
									id="clearExpensesCouldStopBtn_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1 d-flex"
									(click)="clear(ExpensesCouldStopList, i)"
								>
									<i class="material-icons md-16">clear</i>
								</button>
								<button
									id="addExpensesCouldStopBtn_{{ i }}"
									*ngIf="l"
									type="button"
									(click)="addExpensesCouldStopList()"
									class="tap-btn tap-btn-outline tap-btn--shadow px-4"
								>
									Add +
								</button>
							</ng-container>
						</div>
					</div>
				</div>
				<hr class="mb-3" />

				<div class="clearfix mb-3" formArrayName="incomeCouldStartList">
					<div
						class="form-row align-items-center"
						*ngFor="
							let item of IncomeCouldStartList.controls;
							let i = index;
							let f = first;
							let l = last
						"
						[formGroupName]="i"
					>
						<div class="col-12 d-lg-none d-xl-block col-xl-1"></div>
						<div class="col-12 col-lg-2 text-lg-right mb-2">
							<ng-container *ngIf="f">
								Is there any monthly income that would start?
							</ng-container>
						</div>
						<div class="col-12 col-lg-3 mb-2">
							<span class="crt-custom-dropdown w-100">
								<select
									id="incomeStartDropdownId_{{ i }}"
									formControlName="dropdown"
									class="crt-form-control form-control"
									(change)="onChange()"
								>
									<option value=""></option>
									<option *ngFor="let d of apcrtiid$ | async" [value]="d.value">
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
						<div class="col-12 col-lg-2 mb-2">
							<div class="dollar-icon">
								<input
									id="incomeStartValueId_{{ i }}"
									formControlName="value"
									class="crt-form-control form-control"
									type="text"
									(focusout)="onChange()"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
						<div
							class="col-12 col-lg-auto mb-2 d-flex align-items-center justify-content-end"
						>
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
								<button
									id="removeIncomeCouldStartBtn_{{ i }}"
									*ngIf="IncomeCouldStartList.controls.length > 1"
									(click)="removeList(IncomeCouldStartList, i)"
									type="button"
									class="icon-btn w-auto h-auto px-1 d-flex"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
								<button
									id="clearIncomeCouldStartBtn_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1 d-flex"
									(click)="clear(IncomeCouldStartList, i)"
								>
									<i class="material-icons md-16">clear</i>
								</button>
								<button
									id="addIncomeCouldStartBtn_{{ i }}"
									*ngIf="l"
									type="button"
									(click)="addIncomeCouldStartList()"
									class="tap-btn tap-btn-outline tap-btn--shadow px-4"
								>
									Add +
								</button>
							</ng-container>
						</div>
					</div>
				</div>
				<hr class="mb-3" />

				<div class="clearfix" formArrayName="expensesCouldStartList">
					<div
						class="form-row align-items-center"
						*ngFor="
							let item of ExpensesCouldStartList.controls;
							let i = index;
							let f = first;
							let l = last
						"
						[formGroupName]="i"
					>
						<div class="col-12 d-lg-none d-xl-block col-xl-1"></div>
						<div class="col-12 col-lg-2 text-lg-right mb-2">
							<ng-container *ngIf="f">
								Are there any monthly expenses that would start?
							</ng-container>
						</div>
						<div class="col-12 col-lg-3 mb-2">
							<span class="crt-custom-dropdown w-100">
								<select
									formControlName="dropdown"
									id="expensesStartDropdownId_{{ i }}"
									class="crt-form-control form-control"
									(change)="onChange()"
								>
									<option value=""></option>
									<option *ngFor="let d of apcrtesi$ | async" [value]="d.value">
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
						<div class="col-12 col-lg-2 mb-2">
							<div class="dollar-icon">
								<input
									id="expensesStartValueId_{{ i }}"
									formControlName="value"
									class="crt-form-control form-control"
									type="text"
									(focusout)="onChange()"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
						<div
							class="col-12 col-lg-auto mb-2 d-flex align-items-center justify-content-end"
						>
							<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
								<button
									id="removeExpensesCouldStartBtn_{{ i }}}"
									*ngIf="ExpensesCouldStartList.controls.length > 1"
									(click)="removeList(ExpensesCouldStartList, i)"
									type="button"
									class="icon-btn w-auto h-auto px-1 d-flex"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
								<button
									id="clearExpensesCouldStartBtn_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1 d-flex"
									(click)="clear(ExpensesCouldStartList, i)"
								>
									<i class="material-icons md-16">clear</i>
								</button>
								<button
									id="addExpensesCouldStartBtn_{{ i }}"
									*ngIf="l"
									type="button"
									(click)="addExpensesCouldStartList()"
									class="tap-btn tap-btn-outline tap-btn--shadow px-4"
								>
									Add +
								</button>
							</ng-container>
						</div>
					</div>
				</div>
				<hr class="mb-3" />

				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Monthly Surplus / <span class="text-danger">(Shortfall)</span>:
					</div>
					<div class="col-12 col-lg-2 mb-2">
						<div class="dollar-icon">
							<input
								id="monthlySurplusShortfallStopOrStartId"
								formControlName="monthlySurplusShortfallStopOrStart"
								class="crt-form-control form-control"
								type="text"
								currencyMask
							/>
							<i>$</i>
						</div>
					</div>
				</div>
				<div class="form-row align-items-center mb-3">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Shortfall % of Pre Tax Income
					</div>
					<div class="col-12 col-lg-2 mb-2 percent-icon">
						<input
							id="shortfallOfPreTaxIncomeId"
							formControlName="shortfallOfPreTaxIncome"
							class="crt-form-control form-control"
							type="text"
						/>
						<i>%</i>
					</div>
				</div>

				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						What % of your pre tax income would you like covered?
					</div>
					<div class="col-12 col-lg-2 mb-2 percent-icon">
						<input
							appTwoDigitDecimalNumberV2
							formControlName="whatPercentageOfPreTaxIncomeLikeCovered"
							id="whatPercentageOfPreTaxIncomeLikeCoveredId"
							class="crt-form-control form-control"
							type="text"
							(change)="onChange(); onSelectionChange($event?.target?.value)"
						/>
						<i>%</i>
					</div>
				</div>

				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Monthly Income Cover Required:
					</div>
					<div class="col-12 col-lg-2 mb-2">
						<div class="dollar-icon">
							<input
								formControlName="monthlyIncomeCoverRequired"
								id="monthlyIncomeCoverRequiredId"
								class="crt-form-control form-control"
								type="text"
								(focusout)="computePreTaxIncome()"
								currencyMask
								appCurrencyMaskExt
							/>
							<i>$</i>
						</div>
					</div>
				</div>

				<div class="form-row align-items-center mb-3">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						How long would you want your income covered for?
					</div>
					<div class="col-12 col-lg-2 mb-2">
						<span class="crt-custom-dropdown w-100">
							<select
								formControlName="howLongWouldYouWantYourIncomeCoveredFor"
								id="howLongWouldYouWantYourIncomeCoveredForId"
								placeholder="Property Address"
								class="crt-form-control form-control"
								(change)="
									onChange();
									onSelectionChange(
										form?.get('whatPercentageOfPreTaxIncomeLikeCovered')?.value
									)
								"
							>
								<option value=""></option>
								<option *ngFor="let d of apcrtdip$ | async" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>

				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						How long could you cover without insurance?
					</div>
				</div>
				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Available Sick Leave:
					</div>
					<div class="col col-lg-2 mb-2">
						<div class="days dollar-icon dollar-icon-right">
							<input
								numeric
								id="availableSickLeaveId"
								formControlName="availableSickLeave"
								class="crt-form-control form-control"
								type="text"
								(change)="onChange()"
							/>
							<i>days</i>
						</div>
					</div>
				</div>
				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Available Annual leave:
					</div>
					<div class="col col-lg-2 mb-2">
						<div class="days dollar-icon dollar-icon-right">
							<input
								numeric
								id="availableAnnualLeaveId"
								formControlName="availableAnnualLeave"
								class="crt-form-control form-control"
								type="text"
								(change)="onChange()"
							/>
							<i>days</i>
						</div>
					</div>
				</div>
				<div class="form-row align-items-center">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Living off Savings:
					</div>
					<div class="col col-lg-2 mb-2">
						<div class="days dollar-icon dollar-icon-right">
							<input
								id="livingOffSavingsId"
								formControlName="livingOffSavings"
								class="crt-form-control form-control"
								type="text"
							/>
							<i>days</i>
						</div>
					</div>
				</div>

				<div class="form-row align-items-center mb-3">
					<div class="col-12 col-lg-4 mb-2"></div>
					<div
						class="col-12 col-lg-2 text-lg-right mb-2 font-weight-bold tap-text-primary d-lg-flex justify-content-end align-items-center"
					>
						Total:
					</div>
					<div class="col-12 col-lg-2 mb-2">
						<div class="days dollar-icon dollar-icon-right">
							<input
								id="totalId"
								formControlName="total"
								class="crt-form-control form-control"
								type="text"
							/>
							<i>days</i>
						</div>
					</div>
				</div>
				<div class="form-row align-items-center mb-3">
					<div class="col-1"></div>
					<div class="col-12 col-lg-5 text-lg-right mb-2">
						Preferred Wait Period:
					</div>
					<div class="col col-lg-2 mb-2">
						<span class="crt-custom-dropdown w-100">
							<select
								formControlName="preferredWaitPeriod"
								id="preferredWaitPeriod"
								class="crt-form-control form-control"
								(change)="onChange()"
							>
								<option value=""></option>
								<option *ngFor="let d of apcrtpwp$ | async" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</form>
		</div>
	</ng-container>
</div>
