<ul
  class="nav flex-column flex-md-row d-md-flex justify-content-md-start align-items-md-center"
  
>
  <!--BL - Dashboard Access-->
  <ng-container *appRestrictTo="['FDSA']">
    <li
      class="nav-item"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div [routerLink]="dashboardLink$ | async">
        <mat-icon class="tap-icon menu-icon" svgIcon="dashboard"></mat-icon>
        <a
          id="dashboardLink"
          class="nav-link"
          >Dashboard</a
        >
      </div>
    </li>
  </ng-container>
  <!-- Lead Customers -->
  <ng-container *appRestrictTo="['FLA']">
    <li
      class="nav-item dropdown"
      dropdown
      appHeaderNavHover
      [isMobile]="isSideNav"
      [isNavOpen]="navIsShown"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      #rla="routerLinkActive"
      [ngClass]="{ 
        'active-link': rla.isActive,
        'show': navIsShown && rla.isActive,
        'open': navIsShown && rla.isActive 
      }"
    >
      <div 
        class="nav-link-container"
        [routerLink]="isSideNav ? null : leadSearchLink$ | async"
      >
        <mat-icon class="tap-icon menu-icon" svgIcon="leads"></mat-icon>
        <a
          href
          dropdownToggle
          (click)="(false)"
          class="nav-link"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          id="leadLink"
        >
          Lead
          <mat-icon class="tap-icon" svgIcon="caret-down"></mat-icon>
        </a>
      </div>
      <ul class="nav-subitem" [ngClass]="{ 'dropdown-menu': !isSideNav}" fill="currentColor">
        <!--BL - Search Lead-->
        <ng-container *appRestrictTo="['FSL']">
          <li [routerLink]="leadSearchLink$ | async" (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="search"></mat-icon>
              <a 
                id="searchLeadLink"
                fill="currentColor"
                class="nav-link"
                >Search Lead</a
              >
            </div>
          </li>
        </ng-container>
        <!--BL - Add Lead CLient-->
        <ng-container *appRestrictTo="['FAL']">
          <li [routerLink]="leadClientAddLink$ | async" (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="leads"></mat-icon>
              <a 
                id="addNewPersonLink"
                fill="currentColor"
                class="nav-link"
                >Add New Personal File</a
              >
            </div>
          </li>
        </ng-container>
        <!--BL - Add Lead Business-->
        <ng-container *appRestrictTo="['FAL']">
          <li [routerLink]="leadBusinessAddLink$ | async" (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="business-lead"></mat-icon>
              <a 
                id="addNewBusinessLeadLink"
                fill="currentColor"
                class="nav-link"
                >Add New Business File</a
              >
            </div>
          </li>
        </ng-container>
      </ul>
    </li>
  </ng-container>
  <!--BL - Customers Access-->
  <ng-container *appRestrictTo="['FCA']">
    <li
      class="nav-item dropdown"
      appHeaderNavHover
      [isMobile]="isSideNav"
      [isNavOpen]="navIsShown"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      #rla="routerLinkActive"
			[ngClass]="{
				'active-link': rla.isActive && !isAdminView,
        'show': navIsShown && rla.isActive,
        'open': navIsShown && rla.isActive
			}"
    >
      <div 
        class="nav-link-container"
        [routerLink]="isSideNav ? null : customerSearchLink$ | async"
      >
        <mat-icon class="tap-icon menu-icon" svgIcon="client"></mat-icon>
        <a
          id="clientLink"
          (click)="(false)"
          class="nav-link"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Client
          <mat-icon class="tap-icon" svgIcon="caret-down"></mat-icon>
        </a>
      </div>
      <ul class="nav-subitem" [ngClass]="{ 'dropdown-menu': !isSideNav}"fill="currentColor">
        <!--BL - Search Customers-->
        <ng-container *appRestrictTo="['FSC']">
          <li [routerLink]="customerSearchLink$ | async" (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="search"></mat-icon>
              <a 
                id="searchCustomerLink"
                fill="currentColor"
                class="nav-link"
                >Search Client</a
              >
            </div>
          </li>
        </ng-container>
        <!--BL - Add Customers-->
        <ng-container *appRestrictTo="['FAC']">
          <li [routerLink]="customerAddLink$ | async"  (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="client"></mat-icon>
              <a 
                id="addNewPersonCustomerLink"
                fill="currentColor"
                class="nav-link"
                >Add New Personal File</a
              >
            </div>
          </li>
          <li [routerLink]="businessAddLink$ | async"  (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="business"></mat-icon>
              <a 
                id="addNewBusinessCustomerLink"
                fill="currentColor"
                class="nav-link"
                >Add New Business File</a
              >
            </div>
          </li>
        </ng-container>
      </ul>
    </li>
  </ng-container>
  <!--BL - Pipeline Access-->
  <ng-container *appRestrictTo="['FPA']">
    <li
      class="nav-item dropdown"
      dropdown
      appHeaderNavHover
      [isMobile]="isSideNav"
      [isNavOpen]="navIsShown"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      #rla="routerLinkActive"
      [ngClass]="{ 
        'active-link':isSideNav && navIsShown && currentSelectedMenuItem === 'pipeline',
        'show': navIsShown && rla.isActive,
        'open': navIsShown && rla.isActive
      }"
    >
      <div 
        class="nav-link-container"
        [routerLink]="isSideNav ? null : navigateRoute(adviceProcessPipelineRoute)" 
      >
        <mat-icon class="tap-icon menu-icon" svgIcon="pipelines"></mat-icon>
        <a
          id="pipelinesLink"
          href
          dropdownToggle
          class="nav-link"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Pipelines
          <mat-icon class="tap-icon" svgIcon="caret-down"></mat-icon>
        </a>
      </div>
      <ul class="nav-subitem" 
        [ngClass]="{ 
          'dropdown-menu': !isSideNav,
          'dropdown-2col': (orderedServiceLabelLinkModel$ | async).length > 3
        }"fill="currentColor">
        <ng-template
          ngFor
          let-item
          [ngForOf]="orderedServiceLabelLinkModel$ | async"
          let-index
        >
          <li 
            *ngIf="!!item?.label"
            [routerLink]="navigateRoute(item?.link)" 
            (click)="navOpen()"
          >
            <div>
							<mat-icon
								class="tap-icon menu-icon"
								[svgIcon]="'pipeline-' + item?.code.toLowerCase()"
								[class.svg-no-fill]="item?.code.toLowerCase() === 'i'"
							></mat-icon>
              <a
                id="pipelinesLink_{{ index }}"
                fill="currentColor"
                class="nav-link"
                >{{ item?.label }}</a>
            </div>
          </li>
        </ng-template>
      </ul>
    </li>
  </ng-container>
  <!--BL - Activities Access-->
  <ng-container *appRestrictTo="['FAAC']">
    <li
      class="nav-item"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div [routerLink]="activityLink$ | async">
        <mat-icon class="tap-icon menu-icon" svgIcon="activities"></mat-icon>
        <a
          id="activitiesLink"
          class="nav-link"
          >Activities</a
        >
      </div>
    </li>
  </ng-container>
  <!--BL - Document Access-->
  <ng-container *appRestrictTo="['FDAC', 'FRA']">
    <li
      class="nav-item dropdown"
      dropdown
      appHeaderNavHover
      [isMobile]="isSideNav"
      [isNavOpen]="navIsShown"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      #rla="routerLinkActive"
      [ngClass]="{ 
        'active-link': ['document', 'report'].includes(currentSelectedMenuItem),
        'show': navIsShown && rla.isActive,
        'open': navIsShown && rla.isActive  
      }"
    >
      <div 
        class="nav-link-container"
        [routerLink]="isSideNav ? null : documentLink$ | async"
      >
        <mat-icon class="tap-icon menu-icon" svgIcon="documents"></mat-icon>
        <a
          id="documentsLink"
          href
          dropdownToggle 
          class="nav-link"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Documents
          <mat-icon class="tap-icon" svgIcon="caret-down"></mat-icon>
        </a>
      </div>
      <ul class="nav-subitem" [ngClass]="{ 'dropdown-menu': !isSideNav}"fill="currentColor">
        <ng-container *appRestrictTo="['FDAC']">
          <li [routerLink]="documentLink$ | async"  (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="documents"></mat-icon>
              <a 
                id="documentsSubLink"
                fill="currentColor"
                class="nav-link"
                >Documents</a
              >
            </div>
          </li>
        </ng-container>
        <ng-container *appRestrictTo="['FRA']">
          <li [routerLink]="reportLink$ | async"  (click)="navOpen()">
            <div>
              <mat-icon class="tap-icon menu-icon" svgIcon="business"></mat-icon>
              <a 
                id="reportsLink"
                fill="currentColor"
                class="nav-link"
                >Reports</a
              >
            </div>
          </li>
        </ng-container>
      </ul>
    </li>
  </ng-container>
</ul>