import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-delete-modal',
	templateUrl: './delete-modal.component.html',
	styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements DeleteModalModel, OnDestroy {
	private onDestroy$ = new Subject<void>();
	delete$: Observable<any>;
	decline$: Observable<any>;
	close$: Observable<any>;
	header: string;
	message: string | any;
	canDelete = true;
	confirmButton: string;
	confirmButton2: string;
	detachCloseIcon: boolean;

	isLoading: boolean;
	cancelLoading: boolean;

	constructor(public bsModalRef: BsModalRef) {
		this.detachCloseIcon = true;
		this.setupProps();
		this.setupSubs();
	}
	setupProps() {
		this.header = 'Delete';
		this.message = 'Delete?';
	}
	setupSubs() {}

	delete() {
		if (this.cancelLoading) {
			return;
		}
		this.isLoading = true;
		this.delete$
			.pipe(
				finalize(() => {
					this.isLoading = false;

					// If Remove, just hide modal
					if (!!this.confirmButton2) {
						this.bsModalRef.hide();
						return;
					}

					this.decline();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}
	decline() {
		this.cancelLoading = true;
		if (!!this.decline$) {
			this.decline$.pipe(takeUntil(this.onDestroy$)).subscribe();
		}
		this.bsModalRef.hide();
		setTimeout(() => this.cancelLoading = false, 500);
	}

	close() {
		if (!!this.close$) {
			this.close$.pipe(takeUntil(this.onDestroy$)).subscribe();
		}
		this.bsModalRef.hide();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
export interface DeleteModalModel {
	delete$: Observable<any>;
	decline$?: Observable<any>;
	close$?: Observable<any>;
	header: string;
	message: string;
}
