import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DocumentModelState } from '../../../../shared/models/documents/document.model';
import { ApiService } from '../../../../core/base/api.service';
import { objectUtil } from '../../../../util/util';
import { KiwiSaverSettingsQuery } from '../../state/kiwisaver-settings.query';
import { KiwiSaverSettingsStore } from '../../state/kiwisaver-settings.store';
import { DocumentUploadState, AdviceSummarySettingsState } from './advice-summary-settings.model';
import { KiwiMergeTagsCrtSettingsService } from '../../merge-tags/state/kiwi-merge-tags-crt-settings.service';
import { SettingsTypes } from '../../state/kiwisaver-settings.model';

@Injectable()
export class AdviceSummarySettingsService {
	adviceSummarySettings$ = this.query.adviceSummarySettings$;

	constructor(
		private api: ApiService,
		private store: KiwiSaverSettingsStore,
		private query: KiwiSaverSettingsQuery,
		private mtService: KiwiMergeTagsCrtSettingsService
	) {}

	getAdviceSummarySettings(refId: number, settingsCode: string) {
		const endpoint = `crt/settings/${refId}/${settingsCode}`;
		return this.api.get<AdviceSummarySettingsState>(endpoint).pipe(
			tap((data) => {
				return applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setAdviceSummarySettings(state);
					const mergeTag = state?.mergeTag?.map((i) => ({
						...i,
						secondaryValue: i?.value?.replace(/\n|\t/g, '<br>') || '' ,
						value: i?.value?.replace(/\n|\t/g, '<br>') || '' ,
					}));
					this.mtService.updateMtState(mergeTag || []);
				})
			}),
			catchError(() => of(undefined))
		);
	}

	updateAdviceSummarySettings(data: AdviceSummarySettingsState) {
		const newValue = {
			...this.query.getValue().adviceSummarySettings,
			...data,
		};
		const { templateLink, ...payload } = newValue;
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(payload);
		return this.api.put<AdviceSummarySettingsState>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.setAdviceSummarySettings(newValue);
				})
			)
		);
	}

	newFileUploadAdviceSummary(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUploadAdviceSummary(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getSoaDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
    if (!url) { return of(null); }
    return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getDefaultSoaTemplateUrl() {
		const endpoint = `crt/oat-template/${SettingsTypes.KOATAdviceSummary}`;
		return this.api.get<string>(endpoint);
	}
}
