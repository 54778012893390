import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-assets-liabilities-modal',
  templateUrl: './assets-liabilities.modal.component.html',
  styleUrls: ['./assets-liabilities.modal.component.scss'],
})
export class MOATAssetsLiabilitiesModalComponent
  implements ConfirmModalModel, OnInit
{
  saveFn$: (model: any) => Observable<any>;
  decline$: Observable<any>;
  header: string;
  message: string;
  secondaryMessage: string;

  policyOwners: ViewDisplayValue[];
  lifeAssuredList$: Observable<ViewDisplayValue[]>;

  borrowers: Observable<ViewDisplayValue[]>;
  securities: Observable<ViewDisplayValue[]>;

  crtInfo: any;

  su$: Observable<ViewDisplayValue[]>;
  sv1$: Observable<ViewDisplayValue[]>;
  st$: Observable<ViewDisplayValue[]>;
  sti$: Observable<ViewDisplayValue[]>;

  kp$: Observable<ViewDisplayValue[]>;

  mlt$: Observable<ViewDisplayValue[]>;
  mp$: Observable<ViewDisplayValue[]>;

  mlp$: Observable<ViewDisplayValue[]>;
  apcrtf$: Observable<ViewDisplayValue[]>;
  apcrtrp$: Observable<ViewDisplayValue[]>;
  apcrtyn$: Observable<ViewDisplayValue[]>;
  apcrtynna$: Observable<ViewDisplayValue[]>;
  apcrtl$: Observable<ViewDisplayValue[]>;
  kft$: Observable<ViewDisplayValue[]>;

  isLoading = false;
  
	getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

  constructor(public bsModalRef: BsModalRef) {
    this.setupProps();
    this.setupSubs();
  }

  ngOnInit() {}

  setupProps() {
    this.header = 'Confirm';
    this.message = 'Confirm?';
  }
  setupSubs() {}

  save(model) {
    this.isLoading = true;
    this.saveFn$(model)
      .pipe(
        finalize(() => {
          this.bsModalRef.hide();
        })
      )
      .subscribe();
  }

  decline() {
    if (!!this.decline$) {
      this.decline$.subscribe();
    }
    this.bsModalRef.hide();
  }

  cancel() {
    this.decline();
  }
}
export interface ConfirmModalModel {
  decline$?: Observable<any>;
  header: string;
  message: string;
  secondaryMessage: string;
}
