import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../core/base/api.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { ClientProfileUtilsService } from '../client-profie-utils.service';

import { applyTransaction } from '@datorama/akita';
import { UserQuery } from '../../../../../domain/user/user.query';
import { ClientProfileQuery } from '../client-profile.query';
import { ClientProfileStore } from '../client-profile.store';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { of } from 'rxjs';
import {
  mergeMap,
  tap,
  switchMap,
  withLatestFrom,
  take,
  catchError,
  map,
} from 'rxjs/operators';
import { MortgageCustomerServiceState } from '../../../../../shared/models/services/mortgage/mortgage.model';
import { mortgageSortServiceUtil } from '../../../../../shared/services/service-utils/mortgage-utils';
import produce from 'immer';
import { ClientProfileService } from '../client-profile.service';
import { BusinessConfigQuery } from '../../../../../domain/business-config/business-config.query';
import MomentUtil from '../../../../../util/moment.util';
import { NoteState } from '../../../../../shared/models/activity-timeline/activity-timeline.model';
import * as R from 'ramda';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { NoteTypes } from 'src/app/shared/models/notes/note.model';

@Injectable()
export class MortgageService extends ClientProfileUtilsService {
  constructor(
    protected dropdownValueQuery: DropdownValueQuery,
    protected blStaffsQuery: BLStaffsQuery,
    protected store: ClientProfileStore,
    protected query: ClientProfileQuery,
    protected api: ApiService,
    protected customerService: CustomerService,
    protected userQuery: UserQuery,
    protected profileService: ClientProfileService,
    protected businessConfigQuery: BusinessConfigQuery
  ) {
    super(
      store,
      query,
      dropdownValueQuery,
      blStaffsQuery,
      businessConfigQuery,
      userQuery
    );
  }

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  createMortgage(req: MortgageCustomerServiceState, linkDocumentId: string) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      mergeMap((x) =>
        this.customerService.AddMortgage({
          ...x,
          serviceCode: ServicesCodes.Mortgage,
          customerServiceID: 0,
        })
      ),
      tap((x) => (req.customerServiceID = +x)),
      switchMap((x) =>
        linkDocumentId
          ? this.customerService.UpsertDocument({
              customerServicesID: x,
              documentID: linkDocumentId,
            })
          : of(x)
      ),
      switchMap((status) =>
        req.note
          ? this.customerService.AddNote({
              CustomerID: req.customerID,
              CustomerServiceID: req.customerServiceID,
              Notes: req.note,
              ActivityType: NoteTypes.Mortgage,
              StaffName: `${this.userQuery.getValue().FirstName} ${
                this.userQuery.getValue().LastName
              }`,
            })
          : of(status)
      ),
      withLatestFrom(this.query.mortgage$),
      tap(([x, mortgage]) =>
        applyTransaction(() => {
          if (x) {
            if (req.note) {
              this.profileService.addToActivityTimelineNotes(
                +x,
                req.note,
                NoteTypes.Mortgage,
                req.customerID,
                req.customerServiceID
              );
            }
            const mState = produce(mortgage, (draft) => {
              const group = draft.mortgages?.find(
                (m) =>
                  m.provider === req.provider && m.loanNumber === req.loanNumber
              );

              if (!group) {
                // New Group with mortgage
                draft.mortgages.push({
                  provider: req.provider,
                  loanNumber: req.loanNumber,
                  customerServices: [
                    {
                      ...req,
                      note: '',
                      notes: req.note
                        ? [
                            {
                              notesID: +x,
                              customerServiceID: req.customerServiceID,
                              notes: req.note,
                              isActive: true,
                              createDateTime: MomentUtil.formatToServerDatetime(
                                MomentUtil.createMomentNz()
                              ),
                              createdByStaffId:
                                this.userQuery.getValue().StaffID,
                              customerID: req.customerID,
                              staffName: `${
                                this.userQuery.getValue().FirstName
                              } ${this.userQuery.getValue().LastName}`,
                              staffLevel: 0,
                              dueDateTime: null,
                              activityType: null,
                              activityName: null,
                              assignedToAdviser: null,
                            },
                          ]
                        : null,
                    },
                  ],
                  status: req.status,
                });
              } else {
                // New Mortgage,
                draft.mortgages?.forEach((y) =>
                  y.provider === req.provider && y.loanNumber === req.loanNumber
                    ? y.customerServices.push({
                        ...req,
                        note: '',
                        notes: req.note
                          ? [
                              {
                                notesID: +x,
                                customerServiceID: req.customerServiceID,
                                notes: req.note,
                                isActive: true,
                                createDateTime:
                                  MomentUtil.formatToServerDatetime(
                                    MomentUtil.createMomentNz()
                                  ),
                                createdByStaffId:
                                  this.userQuery.getValue().StaffID,
                                customerID: req.customerID,
                                staffName: `${
                                  this.userQuery.getValue().FirstName
                                } ${this.userQuery.getValue().LastName}`,
                                staffLevel: 0,
                                dueDateTime: null,
                                activityType: null,
                                activityName: null,
                                assignedToAdviser: null,
                              },
                            ]
                          : null,
                      })
                    : y
                );
              }
            });

            // Removes empty lrs
            mState.mortgages?.filter((m) => m.customerServices.length !== 0);
            this.store.setMortgage(mortgageSortServiceUtil(mState));
          }
        })
      ),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      )
    );
  }

  updateMortgage(req: MortgageCustomerServiceState) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      withLatestFrom(this.BorrowingEntities$),
      map(([x, ac]) => {
        return {
          ...x,
          borrowingEntities: !!x.borrowingEntities
            ? JSON.stringify(
                JSON.parse(x.borrowingEntities)?.filter((y) =>
                  ac?.find((c) => c.value === y)
                )
              )
            : x.borrowingEntities,
        };
      }),
      mergeMap((x) =>
        this.customerService.UpdateMortgage({ ...x, serviceCode: ServicesCodes.Mortgage })
      ),
      switchMap((status) =>
        req.note
          ? this.customerService.AddNote({
              CustomerID: req.customerID,
              CustomerServiceID: req.customerServiceID,
              Notes: req.note,
              ActivityType: NoteTypes.Mortgage,
              StaffName: `${this.userQuery.getValue().FirstName} ${
                this.userQuery.getValue().LastName
              }`,
            })
          : of(status)
      ),
      withLatestFrom(this.query.mortgage$),
      tap(([x, mortgage]) => {
        applyTransaction(() => {
          if (x) {
            if (x && req.note) {
              this.profileService.addToActivityTimelineNotes(
                +x,
                req.note,
                NoteTypes.Mortgage,
                req.customerID,
                req.customerServiceID
              );
            }
            const mState = produce(mortgage, (draft) => {
              const group = draft.mortgages?.find(
                (m) =>
                  m.provider === req.provider && m.loanNumber === req.loanNumber
              );

              // If !group, create new Group
              if (!group) {
                draft.mortgages.push({
                  provider: req.provider,
                  loanNumber: req.loanNumber,
                  customerServices: [
                    {
                      ...req,
                      note: '',
                      notes: req.note
                        ? [
                            {
                              notesID: +x,
                              customerServiceID: req.customerServiceID,
                              notes: req.note,
                              isActive: true,
                              createDateTime: MomentUtil.formatToServerDatetime(
                                MomentUtil.createMomentNz()
                              ),
                              createdByStaffId:
                                this.userQuery.getValue().StaffID,
                              customerID: req.customerID,
                              staffName: `${
                                this.userQuery.getValue().FirstName
                              } ${this.userQuery.getValue().LastName}`,
                            },
                          ]
                        : req.notes && req.notes.length > 0
                        ? req.notes
                        : [],
                    },
                  ],
                });
              } else {
                draft.mortgages?.forEach((m) => {
                  if (
                    m.provider === req.provider &&
                    m.loanNumber === req.loanNumber
                  ) {
                    const service = m.customerServices?.find(
                      (cs) => cs.customerServiceID === req.customerServiceID
                    );

                    if (!service) {
                      // push on current service
                      m.customerServices.push(req);
                      m.totalLending = m.totalLending + req.loanValue;
                    } else {
                      // Update and add note
                      m.customerServices?.forEach((cs2, i) => {
                        if (cs2.customerServiceID === req.customerServiceID) {
                          m.customerServices[i] = req;

                          if (req.note) {
                            m.customerServices[i].notes?.unshift({
                              notesID: +x,
                              customerServiceID: req.customerServiceID,
                              notes: req.note,
                              isActive: true,
                              createDateTime: MomentUtil.formatToServerDatetime(
                                MomentUtil.createMomentNz()
                              ),
                              createdByStaffId:
                                this.userQuery.getValue().StaffID,
                              customerID: req.customerID,
                              staffName: `${
                                this.userQuery.getValue().FirstName
                              } ${this.userQuery.getValue().LastName}`,
                              staffLevel: 0,
                              dueDateTime: null,
                              activityType: null,
                              activityName: null,
                              assignedToAdviser: null,
                            });
                          } else {
                            m.customerServices[i].notes = cs2.notes;
                          }
                          m.customerServices[i].note = '';
                        }
                      });
                    }
                  }
                });
              }

              // Removes services that dont belong to the group
              draft.mortgages = draft.mortgages?.map((m) => {
                return m.provider !== req.provider ||
                  m.loanNumber !== req.loanNumber
                  ? {
                      ...m,
                      customerServices: m.customerServices?.filter(
                        (cs) => cs.customerServiceID !== req.customerServiceID
                      ),
                    }
                  : m;
              });

              // Removes empty mortages on group
              draft.mortgages = draft.mortgages?.filter(
                (m) => m.customerServices.length !== 0
              );
            });

            this.store.setMortgage(mortgageSortServiceUtil(mState));
          }
        });
      }),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      )
    );
  }

  deleteMortgage(req) {
    return of(req).pipe(
      mergeMap((x) =>
        this.customerService.DeactivateService(+x.customerServiceID)
      ),
      withLatestFrom(this.query.mortgage$),
      tap(([x, mortgage]) => {
        applyTransaction(() => {
          if (x) {
            const mState = produce(mortgage, (draft) => {
              draft.mortgages = draft.mortgages?.map((m) =>
                m.provider === req.provider && m.loanNumber === req.loanNumber
                  ? {
                      ...m,
                      customerServices: m.customerServices?.filter(
                        (cs) => cs.customerServiceID !== req.customerServiceID
                      ),
                    }
                  : m
              );

              // Removes empty mortages
              draft.mortgages = draft.mortgages?.filter(
                (m) => m.customerServices.length !== 0
              );
            });
            this.store.setMortgage(mortgageSortServiceUtil(mState));
          }
        });
      }),
      tap(() =>
        applyTransaction(() => {
          const activityTimeline = this.query.getValue().activityTimeline;
          const notesState = activityTimeline?.notes?.filter(
            (n) => n.customerServiceID !== req.customerServiceID
          );
          this.store.setActivityTimeline({
            activities: activityTimeline?.activities,
            notes: notesState,
          });
        })
      ),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  archiveMortgage(req: MortgageCustomerServiceState, isArchive: boolean) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      withLatestFrom(this.BorrowingEntities$),
      map(([x, ac]) => {
        return {
          ...x,
          borrowingEntities: !!x.borrowingEntities
            ? JSON.stringify(
                JSON.parse(x.borrowingEntities)?.filter((y) =>
                  ac?.find((c) => c.value === y)
                )
              )
            : x.borrowingEntities,
        };
      }),
      mergeMap((data) =>
        this.customerService.UpdateMortgage({
          ...data,
          isActive: isArchive ? 2 : 1,
          serviceCode: ServicesCodes.Mortgage,
        })
      ),
      withLatestFrom(this.query.mortgage$),
      tap(([x, mortgage]) => {
        applyTransaction(() => {
          if (x) {
            const mState = produce(mortgage, (draft) => {
              draft.mortgages = draft.mortgages?.map((m) =>
                m.provider === newReq.provider &&
                m.loanNumber === newReq.loanNumber
                  ? // Check if Mortgage exist in this group, if yes then update
                    m.customerServices?.find(
                      (cs) => cs.customerServiceID === newReq.customerServiceID
                    )
                    ? // If existing
                      {
                        // Update Mortgage
                        ...m,
                        customerServices: m.customerServices?.map((cs) =>
                          cs.customerServiceID === newReq.customerServiceID
                            ? {
                                ...newReq,
                                isActive: isArchive ? 2 : 1,
                                notes: req.notes,
                              }
                            : cs
                        ),
                      }
                    : m
                  : m
              );
            });

            this.store.setMortgage(mortgageSortServiceUtil(mState));
          }
        });
      }),
      tap(() =>
        this.profileService
          .getCriterias(req.customerID)
          .pipe(take(1))
          .subscribe()
      ),
      catchError(() => of(''))
    );
  }

  deleteMortgageNote(id: number, req) {
    return of(req).pipe(
      mergeMap(() => this.profileService.deleteActivityNote(id)),
      withLatestFrom(this.query.mortgage$),
      tap(([x, mortgage]) => {
        applyTransaction(() => {
          if (x) {
            const state = produce(mortgage, (draft) => {
              draft.mortgages = draft.mortgages?.map((m) =>
                m.provider === req.provider && m.loanNumber === req.loanNumber
                  ? // Check if LR exist in this group, if yes then update
                    m.customerServices?.find(
                      (cs) => cs.customerServiceID === req.customerServiceID
                    )
                    ? // If existing
                      {
                        // Update LR
                        ...m,
                        customerServices: m.customerServices?.map((cs) =>
                          cs.customerServiceID === req.customerServiceID
                            ? {
                                ...req,
                                notes: cs.notes?.filter((n) => n.notesID !== id),
                              }
                            : cs
                        ),
                      }
                    : m
                  : m
              );
            });

            this.store.setMortgage(mortgageSortServiceUtil(state));
          }
        });
      }),
      catchError(() => of(''))
    );
  }

  syncNote(notes: NoteState) {
    return of(notes).pipe(
      withLatestFrom(this.query.mortgage$),
      tap(([note, mortgage]) =>
        applyTransaction(() => {
          const state = produce(mortgage, (draft) => {
            draft.mortgages = draft.mortgages?.map((m) =>
              m.customerServices?.find(
                (cs) => cs.customerServiceID === note.customerServiceID
              )
                ? {
                    ...m,
                    customerServices: m.customerServices?.map((cs) =>
                      cs.customerServiceID === note.customerServiceID
                        ? {
                            ...cs,
                            notes: cs.notes?.filter(
                              (n) => note.notesID !== n.notesID
                            ),
                          }
                        : cs
                    ),
                  }
                : m
            );
          });
          this.store.setMortgage(mortgageSortServiceUtil(state));
        })
      )
    );
  }

  upsertMortgageLinkDocument(id, provider, loanNumber) {
    const mState = this.query.getValue().mortgage;

    const group = mState.mortgages?.find(
      (m) => m.provider === provider && m.loanNumber === loanNumber
    );

    const ids = group.customerServices?.map(
      ({ customerServiceID }) => customerServiceID
    );

    return of(ids).pipe(
      mergeMap((x) =>
        this.customerService.UpsertDocument({
          customerServicesID: x,
          documentID: id,
        })
      ),
      tap((x) => {
        applyTransaction(() => {
          if (x) {
            const state = produce(mState, (draft) => {
              draft.mortgages = draft.mortgages?.map((m) =>
                m.provider === provider && m.loanNumber === loanNumber
                  ? { ...m, linkDocument: id }
                  : m
              );
            });

            this.store.setMortgage(mortgageSortServiceUtil(state));
          }
        });
      }),
      catchError(() => of(''))
    );
  }

  addNote(req: MortgageCustomerServiceState) {
    const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
    return of(newReq).pipe(
      switchMap(() =>
        this.customerService.AddNote({
          CustomerID: req.customerID,
          CustomerServiceID: req.customerServiceID,
          Notes: req.note,
          ActivityType: NoteTypes.Mortgage,
          StaffName: `${this.userQuery.getValue().FirstName} ${
            this.userQuery.getValue().LastName
          }`,
        })
      ),
      withLatestFrom(this.query.mortgage$),
      tap(([x, mortgage]) => {
        applyTransaction(() => {
          if (x) {
            if (x && req.note) {
              this.profileService.addToActivityTimelineNotes(
                +x,
                req.note,
                NoteTypes.Mortgage,
                req.customerID,
                req.customerServiceID
              );
            }
            const mState = produce(mortgage, (draft) => {
              const group = draft.mortgages?.find(
                (m) =>
                  m.provider === req.provider && m.loanNumber === req.loanNumber
              );

              // If !group, create new Group
              if (!group) {
                draft.mortgages.push({
                  provider: req.provider,
                  loanNumber: req.loanNumber,
                  customerServices: [
                    {
                      ...req,
                      note: '',
                      notes: req.note
                        ? [
                            {
                              notesID: +x,
                              customerServiceID: req.customerServiceID,
                              notes: req.note,
                              isActive: true,
                              createDateTime: MomentUtil.formatToServerDatetime(
                                MomentUtil.createMomentNz()
                              ),
                              createdByStaffId:
                                this.userQuery.getValue().StaffID,
                              customerID: req.customerID,
                              staffName: `${
                                this.userQuery.getValue().FirstName
                              } ${this.userQuery.getValue().LastName}`,
                            },
                          ]
                        : req.notes && req.notes.length > 0
                        ? req.notes
                        : [],
                    },
                  ],
                });
              } else {
                draft.mortgages?.forEach((m) => {
                  if (
                    m.provider === req.provider &&
                    m.loanNumber === req.loanNumber
                  ) {
                    const service = m.customerServices?.find(
                      (cs) => cs.customerServiceID === req.customerServiceID
                    );

                    if (!service) {
                      // push on current service
                      m.customerServices.push(req);
                      m.totalLending = m.totalLending + req.loanValue;
                    } else {
                      // Update and add note
                      m.customerServices?.forEach((cs2, i) => {
                        if (cs2.customerServiceID === req.customerServiceID) {
                          m.customerServices[i] = req;

                          if (req.note) {
                            m.customerServices[i].notes?.unshift({
                              notesID: +x,
                              customerServiceID: req.customerServiceID,
                              notes: req.note,
                              isActive: true,
                              createDateTime: MomentUtil.formatToServerDatetime(
                                MomentUtil.createMomentNz()
                              ),
                              createdByStaffId:
                                this.userQuery.getValue().StaffID,
                              customerID: req.customerID,
                              staffName: `${
                                this.userQuery.getValue().FirstName
                              } ${this.userQuery.getValue().LastName}`,
                              staffLevel: 0,
                              dueDateTime: null,
                              activityType: null,
                              activityName: null,
                              assignedToAdviser: null,
                            });
                          } else {
                            m.customerServices[i].notes = cs2.notes;
                          }
                          m.customerServices[i].note = '';
                        }
                      });
                    }
                  }
                });
              }

              // Removes services that dont belong to the group
              draft.mortgages = draft.mortgages?.map((m) => {
                return m.provider !== req.provider ||
                  m.loanNumber !== req.loanNumber
                  ? {
                      ...m,
                      customerServices: m.customerServices?.filter(
                        (cs) => cs.customerServiceID !== req.customerServiceID
                      ),
                    }
                  : m;
              });

              // Removes empty mortages on group
              draft.mortgages = draft.mortgages?.filter(
                (m) => m.customerServices.length !== 0
              );
            });

            this.store.setMortgage(mortgageSortServiceUtil(mState));
          }
        });
      }),
      tap(() =>
        this.profileService
          .getClientHistories(req.customerID)
          .pipe(take(1))
          .subscribe()
      )
    );
  }
}
