import { Component, OnInit } from '@angular/core';
import { AdviceProcessDocumentState, AdviceProcessPageCodes, FactFindCodes, GetPageCodeForDocumentField, MOATDocumentField, MOATDocumentsDisplayOffline } from 'src/app/shared/models/advice-process/advice-process.model';
import { AdviceProcessDocumentBaseComponent } from '../advice-process-document-base/advice-process-document-base.component';

@Component({
  selector: 'app-advice-process-moat-documents',
  templateUrl: './advice-process-moat-documents.component.html'
})
export class AdviceProcessMoatDocumentsComponent extends AdviceProcessDocumentBaseComponent implements OnInit {

  moatDocumentFields = MOATDocumentField;
  constructor() {
    super()
  }

  ngOnInit(): void {
  }

  prepDocuments() {
    this.documents = this.adviceProcess.documents?.map((x: AdviceProcessDocumentState) => {
      if (x.field === MOATDocumentField.FactFindDeclaration) {
        return {
          ...x,
          show:
            !!this.adviceProcess.pageStarted?.some((page) =>
              FactFindCodes.includes(page)
            ) ||
            !!this.adviceProcess.pageStarted?.includes(AdviceProcessPageCodes.Declaration),
          isUploading: false,
          isDeleting: false,
          isDownloading: false,
        };
      } else if (MOATDocumentsDisplayOffline.includes(x.field)) {
        return {
          ...x,
          show: true,
          isUploading: false,
          isDeleting: false,
          isDownloading: false,
        }
      }
      else {
        const field = GetPageCodeForDocumentField(
          x.field,
          this.adviceProcess.processCode
        );
        return this.getDocumentState(field, x);
      }
    });
  }

}
