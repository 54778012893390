import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { CustomerService } from '@core/customer/customer.service';
import {
	FSSubSectionCodes,
	ServiceAdviceProcessState,
} from '@shared/models/advice-process/advice-process.model';
import { FinalStructureState } from '@shared/models/client-review-template/final-structure/final-structure.model';
import { ProposedInsuranceState } from '@shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { objectUtil, util } from '@util/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, Observable, Subject, defer, of, zip } from 'rxjs';
import {
	catchError,
	map,
	mergeMap,
	shareReplay,
	take,
	tap,
} from 'rxjs/operators';
import { ConfirmationCallSendIssueModalComponent } from './confirmation-call-send-issue-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationCallSendIssueModalService {
	constructor(
		private modalService: BsModalService,
		protected customerService: CustomerService,
		private api: ApiService
	) {}

	getClientsInvolved(
		primaryClientId: number,
		clientsInvolved: string
	): Observable<{ value: any; text: string; mobile: string }[]> {
		return zip(
			this.customerService.GetPrimaryClient(primaryClientId),
			this.customerService.GetSecondaryClientsByPrimaryClient(primaryClientId),
			this.customerService.GetSecondaryBusinessesByPrimaryClient(
				primaryClientId
			),
			this.customerService.GetSecondaryTrustByPrimaryClient(primaryClientId),
			this.customerService.GetLinkedContactsByPrimaryClient(primaryClientId)
		).pipe(
			shareReplay(),
			map(
				([
					primaryClient,
					secondaryClients,
					secondaryBusinesses,
					secondaryTrusts,
					linkedContacts,
				]) => {
					const getFullName = (data: any) =>
						`${data.FirstName} ${data.LastName}`;
					return [
						{
							value: primaryClient.CustomerID,
							text: getFullName(primaryClient),
							mobile: primaryClient.Mobile,
						},
						...(secondaryClients?.map((s) => ({
							value: s.CustomerID,
							text: getFullName(s),
							mobile: s.Mobile,
						})) ?? []),
						...(secondaryBusinesses?.map((s) => ({
							value: s.CustomerID,
							text: s.CompanyName,
							// @ts-ignore-next
							mobile: s.Mobile,
						})) ?? []),
						// @ts-ignore-next
						...(secondaryTrusts?.map((s) => ({
							value: s.CustomerID,
							text: s.TrustName,
							// @ts-ignore-next
							mobile: s.Mobile ?? '',
						})) ?? []),
						...(linkedContacts?.map((s) => {
							return {
								// @ts-ignore-next
								value: s?.LinkedFromPrimaryCustomer
									? s.RelatedCustomerId?.toString()
									: s.CustomerId?.toString(),
								// @ts-ignore-next
								text: getFullName(s),
								// @ts-ignore-next
								mobile: s.Mobile,
							};
						}) ?? []),
					];
				}
			),
			map((lookups) => {
				const clients = new Set(util.tryParseJson(clientsInvolved) ?? []);
				return lookups.filter(
					(x) => clients.has(x.value) || clients.has(x.value.toString())
				);
			})
		);
	}

	private getFinalStructure(adviceProcessId: number) {
		return this.api.get<any>(`crt/${adviceProcessId}/FS`).pipe(
			map((x) =>
				!!x && x?.length > 0
					? (objectUtil.mapPascalCaseToCamelCase(x[0]) as FinalStructureState)
					: null
			),
			catchError(() => of(undefined))
		);
	}

	private getProposedInsurance(
		adviceProcessId: number,
		sectionCode: string = FSSubSectionCodes.ProposedInsurance
	) {
		return this.api.get<any>(`crt/${adviceProcessId}/${sectionCode}`).pipe(
			map(
				(x) =>
					x?.map(
						objectUtil.mapPascalCaseToCamelCase
					) as ProposedInsuranceState[]
			),
			catchError(() => EMPTY)
		);
	}

	getFinalStructureData(adviceProcessId: number): Observable<{
		finalStructure: FinalStructureState;
		insurance: ProposedInsuranceState[];
	}> {
		return this.getFinalStructure(adviceProcessId).pipe(
			take(1),
			mergeMap((finalStructure) => {
				return this.getProposedInsurance(adviceProcessId).pipe(
					take(1),
					map((insurance) => {
						return {
							finalStructure,
							insurance,
						};
					})
				);
			})
		);
	}

	show(adviceProcess: ServiceAdviceProcessState): Observable<any> {
		return this.getFinalStructureData(adviceProcess.adviceProcessID).pipe(
			mergeMap((finalStructure) => {
				return defer(() => {
					const subject = new Subject();
					const modalRef = this.modalService.show(
						ConfirmationCallSendIssueModalComponent,
						{
							initialState: {
								finalStructure,
								modalSubject: subject,
								adviceProcessId: adviceProcess.adviceProcessID,
								clientsInvolved$: this.getClientsInvolved(
									adviceProcess.customerID,
									adviceProcess.clientsInvolved
								),
								sendCall$: (data) => this.send(data),
							},
							ignoreBackdropClick: true,
							class:
								'modal-dialog-centered modal-lg confirmation-call-send-issue-modal',
							show: true,
						}
					);
					return subject.asObservable().pipe(tap(() => modalRef.hide()));
				});
			})
		);
	}

	send(data: any): Observable<any> {
		data.providerSection = data.providerSection.map((p) =>
			objectUtil.mapCamelCaseToPascalCase(p)
		);
		const endpoint = `concall/request`;
		return this.api.post(endpoint, objectUtil.mapCamelCaseToPascalCase(data));
	}
}
