import { Component, Input, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-primary-base-widget',
  templateUrl: './primary-base-widget.component.html',
  styleUrls: ['./primary-base-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryBaseWidgetComponent implements AfterViewInit {

  hasControl = false;

  @ViewChild('widgetControl') control: ElementRef;
  @Input() headerText: string;
  @Input() subHeaderText: string;
  @Input() subHeaderInlineText: string;

  @Input() widgetCode: string;

  constructor(private cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.hasControl = !!this.control.nativeElement.innerHTML.trim();
    this.cd.detectChanges();
  }
}
