import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CrtMortgageService } from '@modules/crm/crt-page/crt-mortgage/state/crt-mortgage.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { finalize, take, takeUntil, tap, map } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { AssetState } from 'src/app/shared/models/client-review-template/assets-liabilities/asset/asset.model';
import { DeleteModalComponent } from './../../../../../../../shared/modal/delete-modal/delete-modal.component';
import { AssetMapper } from './../../../../../../../shared/models/client-review-template/assets-liabilities/asset/asset.mapper';
import { ViewDisplayValue } from './../../../../../../../shared/models/_general/display-value.viewmodel';
import { Asset } from './../../../../crt-mortgage/client-sop/assets-and-liabilities/state/asset/asset.model';
import { MoatVersionService } from './../../../service/moat-version.service';
import { KiwiSaverTypes } from '@models/services/kiwisaver/kiwisaver.model';
import { logMessage } from '@errorMsgs';
@Component({
	selector: 'app-crt-asset-form',
	templateUrl: './crt-asset-form.component.html',
	styleUrls: ['./crt-asset-form.component.scss']
})
export class CrtAssetFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() formId: number;
	@Input() asset: Asset;
	@Input() totalValue: number;
	@Input() policyOwners: ViewDisplayValue[];
	@Input() APCRTA$: Observable<ViewDisplayValue[]>;
	@Input() KFT$: Observable<ViewDisplayValue[]>;
	@Input() KFT: ViewDisplayValue[];
	@Input() APCRTRP$: Observable<ViewDisplayValue[]>;
	@Input() adviceProcessId: number;
	@Input() disableUpdate: boolean;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	@Input() addMode: boolean;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	// tslint:disable-next-line: variable-name
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	// tslint:disable-next-line: variable-name
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<boolean>();
	@Output() editModeEvent = new EventEmitter<any>();

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;
	submitted: boolean;

	ownerTooltipDisplay: string;

	isMoatV2: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private crtService: CrtMortgageService,
		private moatVersionService: MoatVersionService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();

		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));

		this.isMoatV2 = this.moatVersionService.isMoatV2();
	}

	prepareData() {
		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);

		const owners =
			!!this?.asset?.owner && typeof this.asset.owner === 'string'
				? Array.isArray(JSON.parse(this.asset.owner))
					? JSON.parse(this.asset.owner)?.map((x) => x?.toString())
					: []
				: [];

		this.form.reset({
			asset: this.asset.asset || null,
			owner: owners || null,
			value: this.asset.value || 0,
			description: this.asset.description || null,
		});

		owners?.forEach((owner, index) => {
			const ownerChoice = this.policyOwnerChoices(owners).find((x) => x.value === owner);
			this.ownerTooltipDisplay = this.ownerTooltipDisplay || ''
			if (ownerChoice.display !== undefined || ownerChoice.display !== 'undefined') {
				this.ownerTooltipDisplay += ownerChoice.display;
				if (index !== owners.length - 1) {
					this.ownerTooltipDisplay += ", ";
				}
			}
		});
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.crtService.getOwnerChoices(owners, this.policyOwners);
	}

	buildForm() {
		this.form = this.fb.group({
			asset: this.fb.control('', [Validators.required]),
			owner: this.fb.control([], [Validators.required]),
			value: this.fb.control(0, [Validators.required, Validators.min(0.01)]),
			description: this.fb.control(null),
		});
	}

	prepareFormValue() {
		const form = this.form.getRawValue();
		return {
			...form,
		};
	}

	validateForm() {
		// Your validation here
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	save() {
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}

		this.toggleSaving(true);
		const data: AssetState = this.prepareFormValue();
		const d = AssetMapper.mapToAssetDetails(
			data,
			this.adviceProcessId + +data.value
		);

		if (!!this.addMode) {
			this?.addFn$(d)
				.pipe(
					take(1),
					finalize(() => {
						this.toggleSaving(false);
						this.submitted = false;
						this.saveEvent.emit(data);
						this.resetEdit();
					})
				)
				.subscribe();
		} else {
			this?.updateFn$({ ...d, CRTId: this.asset.cRTId })
				.pipe(
					take(1),
					tap((x) => {
						this.asset = x;
						this.prepareData();
					}),
					finalize(() => {
						this.toggleSaving(false);
						this.submitted = false;
						this.saveEvent.emit(data);
						this.resetEdit();
					})
				)
				.subscribe();
		}
	}

	delete(remove?: string) {
		this.toggleSaving(true);
		this.deleteFn$({
			...AssetMapper.mapToAssetDetails(
				this.asset as AssetState,
				this.adviceProcessId
			),
			AssetStatus: !remove ? 'Sold' : '',
			CRTId: this.asset.cRTId,
			Status: 0,
		})
			.pipe(
				finalize(() => {
					this.toggleSaving(false);
				})
			)
			.subscribe();
	}

	deleteAsset() {
		const data = this.form.getRawValue();
		const isInvestment = data?.asset === KiwiSaverTypes.Investment;
		const confirm = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});
		const message = isInvestment
			? logMessage.oat.shared.factFind.assetsLiabilities.investments.remove
			: logMessage.oat.shared.factFind.assetsLiabilities.otherAssets.sold;

		const initState = {
			message,
			header: `Delete ${isInvestment ? 'Investment' : 'Other Asset'}`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'Confirm',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	edit() {
		this.editModeEvent.emit(this.asset?.cRTId);
		this.toggleEdit(true);
	}

	cancel() {
		if (!!this.addMode) {
			this.cancelEvent.emit(true);
		} else {
			this.toggleEdit(false);
		}
		this.prepareData();
		this.resetEdit();
	}

	resetEdit() {
		this.editModeEvent.emit(null);
	}

	approveConfirmation() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.toggleSaving(true);
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.save();
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Delete Other Assets/Investments',
			message: `This Other Asset / Investment has been removed from the CP. If this is an Asset, please confirm this should be marked as sold - note that until this Advice Process has been synced to the CRM, this will only remove data in the Online Advice Tool. If this is an Investment, this will only remove data in the Online Advice Tool, it will not be deleted from the CRM.`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'Yes',
			confirmButton2: 'No',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
