import * as R from 'ramda';
import { ClientAcceptanceState } from './client-acceptance.model';

export class ClientAcceptanceMapper {
	static mapToView(x: ClientAcceptanceState) {
		return R.omit(
			[
				'createDateTime',
				'createdByStaffId',
				'createdByStaffLevel',
				// 'documentId',
				'modifiedByStaffId',
				'modifiedDateTime',
				// 'sectionCode',
				'status',
			],
			{
				...x,
				presentedSOA: !!x.presentedSOA
					? 'Presented SOA'
					: 'Alternative Structure',
			}
		);
	}

	static mapToUpsert(x: ClientAcceptanceState) {
		return R.omit(
			[
				'createDateTime',
				'createdByStaffId',
				'createdByStaffLevel',
				// 'documentId',
				'modifiedByStaffId',
				'modifiedDateTime',
				// 'sectionCode',
				'status',
			],
			{
				...x,
				signatures: x.signatures?.map((s) => R.omit(['signature'], s)),
			}
		);
	}
}
