import { objectUtil, util } from '@util/util';

export class ViewDropdownMapper {
	static formatValueCamelToPascal(value: string) {
		if (
			!!value &&
			typeof value === 'string' &&
			(value.startsWith('{') || value?.endsWith('}'))
		) {
			const data = util.tryParseJson(value);
			return !!data && typeof data === 'object'
				? JSON.stringify(objectUtil.mapCamelCaseToPascalCase(data))
				: value;
		}
		return value;
	}

	static formatValuePascalToCamel(value: string) {
		if (
			!!value &&
			typeof value === 'string' &&
			(value.startsWith('{') || value?.endsWith('}'))
		) {
			const data = util.tryParseJson(value);
			return !!data && typeof data === 'object'
				? objectUtil.mapPascalCaseToCamelCase(data)
				: {};
		}
		return value || {};
	}
}
