import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable, Subject, Observer } from 'rxjs';
import { finalize, mergeMap, take, takeUntil, tap, first } from 'rxjs/operators';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { GpModalComponent } from '../../../../../shared/modal/crt/fact-find/history/gp-modal/gp-modal.component';
import { GpHistoryMapper } from '../../../../../shared/models/client-review-template/history/gp.mapper';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { ClientReviewTemplateStore } from '../../states/client-review-template.store';
import { HistoryService } from '../../states/history/history.service';
import { peopleToViewDisplayValueUtil } from '../../util/mapViewDisplayValue.util';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';

declare var $: any;

@Component({
	selector: 'app-history-gp',
	templateUrl: './gp.component.html',
	styleUrls: ['./gp.component.scss'],
})
export class GpComponent implements OnInit, OnDestroy {
	@Input() isAdviceProcessEnded: boolean;

	@Input() peoples$: Observable<any>;
	@Input() isGpClientsLoading$: Observable<boolean>;
	@Input() addFn: ({ gp, adviceProcessId, isNew }) => Observable<any>;
	@Input() editFn: ({ gp }) => Observable<any>;

	onDestroy$ = new Subject<void>();
	form: UntypedFormGroup;
	elseMinusGP = true;
	adviceProcessId: string;
	clientId: string;
	gpClients = [];
	gpHistory = [];

	public bsModalRef: BsModalRef;

	crtEdit = null;
	isLoading = true;
	isAddMode = false;
	isEditMode = false;

	get GPArray() {
		return this.form.get('gpArray') as UntypedFormArray;
	}

	peopleList$ = new BehaviorSubject<ViewDisplayValue[]>(null);
	constructor(
		private fb: UntypedFormBuilder,
		private historyService: HistoryService,
		private route: ActivatedRoute,
		private modalService: BsModalService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery
	) {
		this.form = this.fb.group({
			gpArray: this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.clientId = this.route.snapshot.paramMap.get('clientId');
		this.adviceProcessId = this.route.snapshot.paramMap.get('adviceProcessId');

		this.peoples$
			.pipe(
				mergeMap((x) => peopleToViewDisplayValueUtil(x)),
				tap((x) => this.peopleList$.next(x)),
				take(1)
			)
			.subscribe();

		combineLatest([this.query.gpClients$, this.query.gpHistory$])
			.pipe(
				tap(([gpClients, gpHistory]) => {
					if (!gpClients || !gpHistory) {
						return;
					}
					const clients = gpClients?.filter(
						(client) =>
							client?.professionalType === 'Doctor' &&
							!gpHistory?.find((x) => +x.customerId === +client?.customerID)
					);
					this.gpClients = clients || [];
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isLoading = false;
				},
				() => {
					this.isLoading = false;
				},
				() => {
					this.isLoading = false;
				}
			);

		this.query.gpHistory$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
			this.gpHistory = data;
			this.prepGpDetails();
		});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	prepGpDetails() {
		while (this.GPArray.length !== 0) {
			this.GPArray.removeAt(0);
		}
		(this.gpHistory ?? [])?.forEach((x) => {
			const fg = this.fb.group({
				client: '',
				customerId: x.customerId,
				gPName: x.gPName,
				businessName: x.businessName,
				phoneNumber: x.phoneNumber,
				physicalAddress: x.physicalAddress,
				emailAddress: x.emailAddress,
				professionalType: x.professionalType,

				btnDisable: false,
				btnSaveGP: false,
				btnEditGP: true,
				isSaving: false,
			});
			const tryArr = [];
			const client = JSON.parse(x.client);
			(client ? client : []).forEach((val) => tryArr?.push(Number(val)));
			fg.controls.client.setValue(tryArr);
			fg.disable();
			this.GPArray?.push(fg);
		});
	}

	addGP() {
		this.isAddMode = true;
		this.GPArray?.push(
			this.fb.group({
				client: [''],

				customerId: [''],
				gPName: [''],
				businessName: [''],
				phoneNumber: [''],
				physicalAddress: [''],
				emailAddress: [''],
				professionalType: ['Doctor'],

				btnDisable: true,
				btnSaveGP: true,
				btnEditGP: false,
				isSaving: false,
			})
		);
	}

	newGP(index: number = 0) {
		const initState = {
			header: 'Professional Contact',
			message: `ProfessionalContact`,
			peoples$: this.peopleList$,
			savefn: this.addNewGpDetails,
			isNew: true,
		};
		this.bsModalRef = this.modalService.show(GpModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.bsModalRef.content.cancel.subscribe(() => {
			(document.getElementById('nameGp-' + index) as HTMLInputElement).value =
				'';
			this.removeGp(index);
		});
	}

	addNewGpDetails = (model) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
			{
				const primaryCustomer = Number(
					this.route.snapshot.paramMap.get('clientId')
				);
				const gp = GpHistoryMapper.mapToUpsert({...x, primaryCustomer}, null);
				const adviceProcessId = Number(
					this.route.snapshot.paramMap.get('adviceProcessId')
				);
				return this.addFn({gp, adviceProcessId, isNew: true })
			}),
			finalize(() => {
				this.crtEdit = null;
				this.isEditMode = false;
				this.isAddMode = false;
			})
		);

	saveGP(index: number) {
		this.GPArray.controls[index].get('btnSaveGP').setValue(false);
		this.GPArray.controls[index].get('btnEditGP').setValue(true);
		this.GPArray.controls[index].get('isSaving').setValue(true);

		const formArray = this.form.controls.gpArray as UntypedFormArray;
		formArray.controls[index].disable();

		const gp = GpHistoryMapper.mapToUpsert(
			this.form.controls.gpArray.value[index],
			this.gpHistory[index] || null
		);
		if (gp.cRTId > 0) {
			this.editFn({ gp })
				.pipe(
					finalize(() => {
						this.crtEdit = null;
						this.isAddMode = false;
						this.isEditMode = false;
					})
				)
				.subscribe();
		} else {
			this.addFn({
				gp,
				adviceProcessId: Number(
					this.route.snapshot.paramMap.get('adviceProcessId')
				),
				isNew: false,
			})
				.pipe(
					finalize(() => {
						this.crtEdit = null;
						this.isAddMode = false;
						this.isEditMode = false;
					})
				)
				.subscribe(
					() => this.GPArray.controls[index].get('isSaving').setValue(false),
					() => this.GPArray.controls[index].get('isSaving').setValue(false),
					() => this.GPArray.controls[index].get('isSaving').setValue(false)
				);
		}
	}

	editGP(index: number) {
		this.isEditMode = true;

		const formArray = this.form.controls.gpArray as UntypedFormArray;
		formArray.controls[index].disable();

		const gpDetails = GpHistoryMapper.mapToUpsert(
			this.form.controls.gpArray.value[index],
			this.gpHistory[index] || null
		);

		const initState = {
			header: 'Professional Contact',
			message: `ProfessionalContact`,
			peoples$: this.peopleList$,
			savefn: this.updateGpDetails,
			cRTId: this.gpHistory[index]?.cRTId,
			gpDetails,
		};
		this.bsModalRef = this.modalService.show(GpModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});

		this.bsModalRef.content.cancel.subscribe(() => {
			this.crtEdit = null;
			this.isAddMode = false;
			this.isEditMode = false;
		});
	}

	updateGpDetails = (model) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
			{
				const primaryCustomer = Number(
					this.route.snapshot.paramMap.get('clientId')
				);
				const adviceProcessId = Number(
					this.route.snapshot.paramMap.get('adviceProcessId')
				);
				const gp = GpHistoryMapper.mapToUpsert(
					{...x, primaryCustomer, adviceProcessId}
				);
				return this.editFn({gp});
			}),
			finalize(() => {
				this.crtEdit = null;
				this.isAddMode = false;
				this.isEditMode = false;
			})
		);

	viewGP(index: number) {
		this.isEditMode = true;

		const formArray = this.form.controls.gpArray as UntypedFormArray;
		formArray.controls[index].disable();

		const gpDetails = GpHistoryMapper.mapToUpsert(
			this.form.controls.gpArray.value[index],
			this.gpHistory[index] || null
		);

		const initState = {
			header: 'Professional Contact',
			message: `ProfessionalContact`,
			peoples$: this.peopleList$,
			viewMode: true,
			gpDetails,
		};
		this.bsModalRef = this.modalService.show(GpModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});

		this.bsModalRef.content.saveNew.subscribe((data) => {
			data.primaryCustomer = Number(
				this.route.snapshot.paramMap.get('clientId')
			);
			const gp = GpHistoryMapper.mapToUpsert(
				data,
				this.gpHistory[index] || null
			);

			this.editFn({
				gp,
			})
				.pipe(
					finalize(() => {
						this.crtEdit = null;
						this.isAddMode = false;
						this.isEditMode = false;
					})
				)
				.subscribe();
		});

		this.bsModalRef.content.cancel.subscribe(() => {
			this.crtEdit = null;
			this.isAddMode = false;
			this.isEditMode = false;
		});
	}

	deleteGP(index: number, item: UntypedFormGroup): void {
		const confirm = new Observable<boolean>((obs) => {
			this.GPArray.controls[index].get('isSaving').setValue(true);
			this.GPArray.removeAt(index);
			this.historyService
				.removeGpHistory(
					this.gpHistory[index].cRTId,
					this.gpHistory[index].customerId
				)
				.subscribe(
					() => this.GPArray.controls[index]?.get('isSaving').setValue(false),
					() => this.GPArray.controls[index]?.get('isSaving').setValue(false),
					() => this.GPArray.controls[index]?.get('isSaving').setValue(false)
				);
			this.gpHistory.splice(index, 1);
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs) => {
			obs.complete();
		});
		const initState = {
			header: '',
			message: `Are you sure you want to delete '${item.value.gPName}'? This will also delete this GP detail on the CRM.`,
			confirm$: confirm,
			decline$: decline,
			confirmTxt: 'Delete',
			cancelTxt: 'Cancel',
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	removeGp(index: number) {
		if (index < this.gpHistory.length) {
			this.GPArray.controls[index].get('btnSaveGP').setValue(false);
			this.GPArray.controls[index].get('btnEditGP').setValue(true);
			const formArray = this.form.controls.gpArray as UntypedFormArray;
			formArray.controls[index].disable();
			this.prepGpDetails();
		} else {
			this.GPArray.removeAt(index);
		}
		this.crtEdit = null;
		this.isEditMode = false;
		this.isAddMode = false;
	}

	collapseGP() {
		$('#collapseGP').toggle();
		this.elseMinusGP = !this.elseMinusGP;
	}

	selectNewGpClient(event, index: number) {
		if (event.target.value === 'addNew') {
			this.newGP(index);
		} else {
			if (!event.target.value) {
				this.GPArray.controls[index].get('btnDisable').setValue(true);
			} else {
				this.GPArray.controls[index].get('btnDisable').setValue(false);
				const client = this.gpClients?.filter((x) => {
					return +x.customerID === +event.target.value;
				})[0];
				this.GPArray.controls[index]
					.get('businessName')
					.setValue(client.businessName || '');
				this.GPArray.controls[index].get('gPName').setValue(client.name || '');
			}
			this.GPArray.controls[index].get('professionalType').setValue('Doctor');
		}
	}
}
