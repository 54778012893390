<ngx-avatars
    [src]="googleImageUrl$ | async"
    [name]="name$ | async"
    [round]="true"
    size="40"
  >
</ngx-avatars>
<mat-icon class="tap-icon" svgIcon="settings"></mat-icon>

<ul 
  *ngIf="popoverIsShown$ | async" 
  #dropdown 
  class="dropdown" 
  [ngStyle]="{'top': positionPopup().y + 'px'}"
>
  <li class="user-details">
    <ngx-avatars
      [src]="googleImageUrl$ | async"
      [name]="name$ | async"
      [round]="true"
      size="50"
    ></ngx-avatars>
		<div class="text-container">
			<div class="name">{{ name$ | async }}</div>
			<div class="email">{{ email$ | async }}</div>
		</div>
  </li>
  <!--GA - Switch Business-->
  <ng-container *appRestrictTo="['GSB']">
    <li 
      *ngIf="isTapLevelUser$ | async" 
      [routerLink]="adminBusinessList"
      class="nav-item"
    > 
      <div>
        <mat-icon class="tap-icon menu-icon" svgIcon="businesses"></mat-icon>
        <a
          class="nav-link"
          >View My Businesses</a
        >
      </div>
    </li>
  </ng-container>
  <!--GA - Access CRM Backend-->
  <ng-container *appRestrictTo="['GACB']">
    <li 
      *ngIf="!isOnTapLevelView && isOnCrmLevelView"
      [routerLink]="businessBusiness" 
      class="nav-item" 
    >
      <div>
        <mat-icon class="tap-icon menu-icon" svgIcon="business"></mat-icon>
        <a
          class="nav-link"
          >View Business Account</a
        >
      </div>
    </li>
    <li 
      *ngIf="canViewUserDetails && isOnCrmLevelView"
      [routerLink]="userDetails" 
      class="nav-item" 
    >
      <div>
        <mat-icon class="tap-icon menu-icon" svgIcon="client"></mat-icon>
        <a
          class="nav-link"
          >User Details</a
        >
      </div>
    </li>
    <li 
      *ngIf="canManageUsers && isOnCrmLevelView"
      [routerLink]="manageUsers" 
      class="nav-item" 
    >
      <div>
        <mat-icon class="tap-icon menu-icon" svgIcon="edit"></mat-icon>
        <a
          class="nav-link"
          >Manage Users</a
        >
      </div>
    </li>
    <li 
      *ngIf="!isOnTapLevelView && !isOnCrmLevelView"
      [routerLink]="dashboard" 
      class="nav-item" 
    >
      <div>
        <mat-icon class="tap-icon menu-icon" svgIcon="dashboard"></mat-icon>
        <a
          class="nav-link"
          >View Dashboard</a
        >
      </div>
    </li>
  </ng-container>
  <li class="nav-item" (click)="logout()">
    <div>
      <mat-icon class="tap-icon menu-icon" svgIcon="logout"></mat-icon>
      <a
        class="nav-link"
        >Logout</a
      >
    </div>
  </li>
</ul>
