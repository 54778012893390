import { Injectable } from '@angular/core';
import { ApiService, JsonResultStatus } from '../../core/base/api.service';
import { DropdownValueQuery } from './dropdown-value.query';
import { DropdownValueStore } from './dropdown-value.store';
import { DropdownValueResponse } from './dropdown-value-response.model';
import { tap, mergeMap, catchError } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import * as R from 'ramda';
import {
  DropdownValueUpdate,
  DropdownValueAdd,
  DropdownUpdateAll,
} from './dropdown-value-request.model';
import { Observable, of } from 'rxjs';

@Injectable()
export class DropdownValueService {
  constructor(
    private api: ApiService,
    private store: DropdownValueStore,
    private query: DropdownValueQuery
  ) { }

  getDropdownValueList$(codes: string[]): Observable<DropdownValueResponse[]> {
    const endpoint = 'dropdown/dropdown-values';
    const codesNotInStore = codes?.filter(
      (c) => {
        // This is specific to LRP only
        if (c === 'LRP') {
          return true;
        }

        return !this.query.hasEntity((x) => x.DropdownCode === c)
      }
    );
    
    const updatedCodes = this.query.getValue().updatedCodes;
    const codesToQuery = [...codesNotInStore, ...updatedCodes]?.join();

    return R.isEmpty(codesNotInStore) && R.isEmpty(updatedCodes)
      ? of([])
      : this.api
        .get<DropdownValueResponse[]>(endpoint, {
          type: codesToQuery,
        })
        .pipe(
          tap((res) => {
            applyTransaction(() => {
              this.store.remove((d) =>
                codesNotInStore?.includes(d.DropdownCode)
              );
              this.store.remove((d) =>
                updatedCodes?.includes(d.DropdownCode)
              );
              this.store.add(res);
              this.store.setUpdatedCodes([]);
            });
          }),
          catchError(() => of(undefined))
        );
  }

  getDropdownValues$(code: string): Observable<DropdownValueResponse[]> {
    return this.getDropdownValueList$([code]);
  }

  saveDropdownValueName(dropdown: DropdownValueUpdate, dCode: string) {
    const endpoint = 'Dropdown/UpdateDropdownValue';
    const body = {
      param: dropdown,
    };
    return this.api
      .post3<JsonResultStatus>(endpoint, body)
      .pipe(mergeMap(() => this.getDropdownValues$(dCode)));
  }

  addDropdownValueName(dropdown: DropdownValueAdd, dCode: string) {
    const endpoint = 'Dropdown/AddDropdownValue';
    const body = {
      param: dropdown,
    };
    return this.api
      .post3<JsonResultStatus>(endpoint, body)
      .pipe(mergeMap(() => this.getDropdownValues$(dCode)));
  }

  UpdateDropdownValues(dropdowns: DropdownUpdateAll, dCode: string) {
    const endpoint = 'Dropdown/UpdateAllDropdownValues';
    const body: {} = {
      param: dropdowns,
    };
    return this.api
      .post3<JsonResultStatus>(endpoint, body)
      .pipe(mergeMap(() => this.getDropdownValues$(dCode)));
  }
  DeleteDropdownValue(id: number, newId: number): Observable<JsonResultStatus> {
    const endpoint = 'Dropdown/DeactivateDropdownValue';
    const body = { dropdown_value_id: id, new_dropdown_value_id: newId };
    return this.api
      .post3<JsonResultStatus>(endpoint, body)
      .pipe(this.store.remove(id));
  }

  setDropdownUpdateCodes(code: any) {
    const currentCodes = this.query.getValue().updatedCodes;
    if (currentCodes.includes(code)) {
      return;
    }
    const newCodes = [...currentCodes, code]
    this.store.setUpdatedCodes(newCodes);
  }
}
