import * as R from 'ramda';
import { of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { numUtil, util } from 'src/app/util/util';
import {
	contentEditable,
	contentEditableClass,
} from '../../../../shared/converter/content-merge-tags';
import {
	MergeTagState,
	MergeTagTypeCode,
} from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import * as numeral from 'numeral';

export class MergeTagsMapper {
	/**
	 * Convert merge tags to shortcodes for Froala
	 * @param data : MergeTagState[] = Array of merge tags
	 * @returns object<{ [metaKey] : [description] }>
	 */
	public static mapTagsForWysiwyg(data: MergeTagState[] = []) {
		if (R.either(R.isNil, R.isEmpty)(data)) {
			return [];
		}
		const newData = data?.map((x) => ({
			...x,
			description: x?.description || '',
		}));
		// Convert merge tags to shortcodes for wysiswyg
		const newList = R.sortBy(R.compose(R.toLower, R.prop('description')))(
			newData
		);
		return newList?.reduce((acc, cur: any) => {
			return { ...acc, [cur.metaKey]: cur.description };
		}, {});
	}

	/**
	 * Convert merge tags to shortcodes for Froala
	 * @param data : MergeTagState[] = Array of merge tags
	 * @returns Updated merge tags
	 */
	public static sortMergeTags(data: MergeTagState[] = []) {
		if (R.either(R.isNil, R.isEmpty)(data)) {
			return [];
		}
		const newData = data?.map((x) => ({ ...x, metaKey: x?.metaKey || '' }));

		return R.sortBy(R.compose(R.toLower, R.prop('metaKey')))(newData);
	}

	/**
	 * Map merge tags according to type
	 * @param data : MergeTagState[] = Array of merge tags
	 * @param useSecondary : boolean = enable to use values from secondaryValue; for Settings Preview
	 * @returns Observable<string> of new html
	 */
	public static mapMergeTags(
		data: MergeTagState[] = [],
		useSecondary: boolean = false
	) {
		let newData = data;
		newData = this.mapListDropdownPreview(newData, useSecondary);

		return newData?.map((item) => ({
			...item,
			value: useSecondary ? item?.secondaryValue : item?.value,
		}));
	}

	public static mapListDropdownPreview(
		data: MergeTagState[] = [],
		useSecondary: boolean = false
	) {
		// Preview for Merge Tag Type: 'LO', 'O', 'D', 'DV'
		return data?.map((item) =>
			[
				MergeTagTypeCode.listOfObject.toString(),
				MergeTagTypeCode.object.toString(),
				MergeTagTypeCode.dropdown.toString(),
				MergeTagTypeCode.dropdownValue.toString(),
			]?.includes(item?.type)
				? {
						...item,
						value: useSecondary
							? this.setValues(item?.secondaryValue, 'value')
							: this.setValues(item?.value, 'value'),
				  }
				: item
		);
	}

	// Mappers for CRT Page
	public static mapCrtMergeTags(data: MergeTagState[] = []) {
		let newData = [...data];
		newData = this.mapListOfObject(newData);
		newData = this.mapDropdownValue(newData);
		newData = this.mapDropdown(newData);
		newData = this.mapDropdownOthers(newData);
		newData = this.mapReferenceValue(newData);
		newData = this.mapReference(newData);
		newData = this.mapTextWithComputation(newData);

		return newData;
	}

	public static remapMergeTagsTypes(data: MergeTagState[] = []) {
		// Observable of mapCrtMergeTags
		// Need to map in order

		return of([...data]).pipe(
			map((x) => this.mapListOfObject(x)),
			map((x) => this.mapDropdownValue(x)),
			map((x) => this.mapDropdown(x)),
			map((x) => this.mapDropdownOthers(x)),
			map((x) => this.mapReferenceValue(x)),
			map((x) => this.mapReference(x)),
			map((x) => this.mapTextWithComputation(x)),
			take(1)
		);
	}

	public static mapTextWithComputation(data: MergeTagState[] = []) {
		// Merge Tag Type TC
		return data?.map((item) =>
			item?.type === MergeTagTypeCode.textWithComputation.toString()
				? // convert type to "T"
				  {
						...item,
						type: 'T',
				  }
				: item
		);
	}

	public static mapListOfObject(data: MergeTagState[] = []) {
		// Merge Tag Type LO
		const getData = (items) => {
			items = this.orderByReferenceId(items);
			return items?.map((i) => i.value);
		};
		return data?.map((item) =>
			[MergeTagTypeCode.listOfObject.toString()]?.includes(item?.type)
				? {
						...item,
						value: getData(item?.value ?? []) || [],
				  }
				: item
		);
	}

	public static mapDropdownOthers(data: MergeTagState[] = []) {
		// Merge Tag Type DO
		const getData = (items) => {
			return items?.map((i) =>
				i?.dropdown === 'Other' ? i?.value : i?.dropdown
			);
		};
		return data?.map((item) =>
			[MergeTagTypeCode.dropdownOther.toString()]?.includes(item?.type)
				? {
						...item,
						value: getData(item?.value ?? []) || [],
				  }
				: item
		);
	}

	public static mapDropdownValue(data: MergeTagState[] = []) {
		// Merge Tag Type DV
		const getData = (val) => {
			const obj = R.find(R.propEq('metaKey', val))(data) as MergeTagState;
			let remapObj = this.getDropdownValues(obj?.value);
			remapObj = remapObj?.map((i) => i.value);
			return remapObj;
		};
		return data?.map((item) =>
			[MergeTagTypeCode.dropdownValue.toString()]?.includes(item?.type)
				? {
						...item,
						value: getData(item?.value ?? []) || [],
				  }
				: item
		);
	}

	public static mapDropdown(data: MergeTagState[] = []) {
		// Merge Tag Type D & ODV
		const getData = (val) => {
			let remapObj = this.getDropdownValues(val);
			remapObj = remapObj?.map((i) => i.dropdown);
			return remapObj;
		};
		return data?.map((item) =>
			[
				MergeTagTypeCode.dropdown.toString(),
				MergeTagTypeCode.otherDropdownValue.toString(),
			].includes(item?.type)
				? {
						...item,
						value: getData(item?.value ?? []) || [],
				  }
				: item
		);
	}

	// Merge Tag Type R
	public static mapReference(data: MergeTagState[] = []) {
		const getData = (items) => {
			return items?.reduce((a, c) => [...a, c?.value || ''], []);
		};
		return data?.map((item) =>
			[MergeTagTypeCode.reference.toString()]?.includes(item?.type)
				? {
						...item,
						value: getData(item?.value ?? []) || [],
				  }
				: item
		);
	}

	public static mapReferenceValue(data: MergeTagState[] = []) {
		// Merge Tag Type RV
		const getData = (items) => {
			return items?.reduce((a, c) => {
				if (R.complement(R.either(R.isNil, R.isEmpty))(c?.value)) {
					return [...a, c.value];
				}
				return a;
			}, []);
		};
		return data?.map((item) =>
			[MergeTagTypeCode.referenceValue.toString()]?.includes(item?.type)
				? {
						...item,
						value: getData(item?.value ?? []) || [],
				  }
				: item
		);
	}

	public static getDropdownValues(data = []) {
		const newData = data?.reduce((acc, cur) => {
			const totalRepeat = this.getMaxLength(cur?.value);
			const arrRepeat = Array.from(Array(+totalRepeat).keys());

			const newValue = arrRepeat?.reduce((a, c) => {
				const items = this.orderByReferenceId(cur?.value);
				const obj = this.reformatMergeTags(items, c);
				return [...a, { dropdown: cur?.dropdown, value: obj }];
			}, []);
			return [...acc, ...newValue];
		}, []);
		return newData;
	}

	public static reformatMergeTags = (data = [], index: number = 0) => {
		const getVal = (val, i) => val[i] ?? '';
		const arr = [];
		data?.forEach((i) => arr.push(getVal(i?.value, index)));
		return arr;
	};

	public static orderByReferenceId = (data = []) => {
		let newData = [...data];
		const otherData = newData?.filter((x) => +x?.referenceId !== 0);
		const dependantData = newData?.find((x) => +x?.referenceId === 0);

		newData = otherData?.sort((a, b) => {
			const x = +a.referenceId;
			const y = +b.referenceId;
			if (y === 0) {
				return -1;
			}
			return x - y;
		});

		if (dependantData) {
			newData.push(dependantData);
		}
		return newData;
	};

	public static getMaxLength = (data = []) =>
		data?.reduce((a, c) => {
			return c?.value.length > a ? c?.value.length : a;
		}, 0);

	public static setValues(data, key) {
		if (typeof data === 'object') {
			return data?.map((i) => i[key]);
		}
		return [data];
	}

	/**
	 * Get dynamic values for Merge Tags
	 * @param data : MergeTagState[] = Array of merge tags
	 * @param keyName :
	 * Input keyName as string for first level
	 * Input keyName as object { parentKeyName : childKeyName } for second level
	 * @returns new value
	 */
	public static getMtValue(data, keyName) {
		let newData = [];
		if (!data) {
			return [];
		}

		if (Array.isArray(data)) {
			if (typeof keyName === 'string') {
				newData = data?.map((i) => i[keyName] ?? '');
			} else {
				newData = data?.map((item) => {
					const [parent, child]: any = Object.entries(keyName)[0];
					return item[parent]?.map((c) => c[child] ?? '');
				});
			}
		} else {
			if (typeof keyName === 'string' && data[keyName]) {
				newData.push(data[keyName]);
			} else {
				const [parent, child]: any = Object.entries(keyName)[0];
				if (data[parent]) {
					newData = data[parent]?.map((c) => c[child] ?? '');
				}
			}
		}
		return newData;
	}

	/**
	 * Update Email MergeTags
	 * @param mergeTags : MergeTagState[] = Array of merge tags
	 * @param emailMt : MergeTagState[] = Array of merge tags of Email
	 * @returns updated value of merge tags
	 */
	public static updateEmailMergeTags(mergeTags, emailMt) {
		const mtList = mergeTags || [];
		const updatedMt = emailMt?.reduce((acc, curr) => {
			const index = acc?.findIndex((item) => item.metaKey === curr.metaKey);
			if (index === -1) {
				return [...acc, curr];
			}
			acc[index] = curr;
			return acc;
		}, mtList);
		return updatedMt;
	}

	/**
	 * Update Merge Tag value, if has \n \t, convert to <br />
	 * @param mergeTagValue
	 * @returns updated value
	 */
	public static updateNewLine(mergeTagValue) {
		return Array.isArray(mergeTagValue)
			? mergeTagValue?.map((x) => (x || '')?.replace(/\n|\t/g, '<br />'))
			: mergeTagValue;
	}

	/**
	 * Wrap the merge tag value with DIV
	 * @param metaKey <string> Merge Tag meta key
	 * @param value <string> The merge tag values to be added inside the div
	 * @param editable <boolean> is contentEditable
	 * @param hide <boolean> Optional; If hidden, will add 'd-none' class
	 * @returns <string> updated HTML content
	 */
	public static wrapMTValueInDiv(
		metaKey: string,
		value: string,
		editable: boolean = false,
		hide?: boolean
	) {
		const html = `<div ${
			editable ? contentEditable.true : contentEditable.false
		} id="${metaKey}">${value?.replace(/\n|\t/g, '') || ''}</div>`;
		const newHtml = document.createRange().createContextualFragment(html);
		newHtml.querySelectorAll(`#${metaKey}`).forEach((e: HTMLElement) => {
			e.classList.remove('d-none');
			if (!!hide) {
				e.classList.add('d-none');
			}
		});
		return newHtml.querySelector(`#${metaKey}`).outerHTML || '';
	}
	public static wrapMTValueInElement(elementTag: keyof HTMLElementTagNameMap, metaKey: string, value: string, editable: boolean = false) {
		if(this.isMTValueHTML(value) || value.length === 0) {
			return value;
		}
		const content = value?.replace(/\n|\t/g, '') || '';
		const element = document.createElement(elementTag);
		element.setAttribute('id', metaKey);
		element.setAttribute(contentEditableClass, editable.toString());
		element.innerText = content;
		return element.outerHTML;
	}

	private static isMTValueHTML(str: string) {
		const doc = new DOMParser().parseFromString(str, "text/html");
		return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
	}

	/**
	 * Revert back converted merge tags created by FE
	 * @param currentContent <string> HTML content
	 * @param metaKey <string> Merge Tag meta key
	 * @param customRegex <string> Optional Regex
	 * @returns <string> updated HTML content
	 */
	public static revertMergeTag = (
		currentContent: string,
		metaKey: string,
		regex?: string,
		isEditable?: boolean
	) => {
		const editable = !!isEditable
			? contentEditable.true
			: contentEditable.false;
		const customRegex =
			regex ??
			`(?:<div ${editable} id="${metaKey}")(?=.* class="[^>]*">)(.*?)(?:<\/div>)`;
		const regEx = new RegExp(customRegex, 'g');
		const checks = currentContent?.match(regEx);

		if (checks?.length > 0) {
			// Catch format:
			// <div contenteditable="false" id="MERGETAG_METAKEY">...</div>
			currentContent = checks?.reduce((acc, cur) => {
				return acc?.replace(cur, `%${metaKey}%`);
			}, currentContent);
		} else {
			// Catch format:
			// <div id="MERGETAG_METAKEY" contenteditable="false">...</div>
			const idFirstRegex = `(?:<div id="${metaKey}" ${editable}>[^>]*>)(.*?)(?:<\/div>)`;
			const idFirstreg = new RegExp(idFirstRegex, 'g');
			const idFirstcheck = currentContent?.match(idFirstreg);

			if (idFirstcheck?.length > 0) {
				currentContent = idFirstcheck?.reduce((acc, cur) => {
					return acc?.replace(cur, `%${metaKey}%`);
				}, currentContent);
			}
		}

		return currentContent;
	};

	/**
	 * Remap merge tag values based on a merge tag reference ID
	 * @param mergeTag <object> Merge Tag Value
	 * @param reference <array> Merge Tag Value of your Reference MergeTag
	 * @returns <array> Array of values according to reference
	 */
	public static getReferenceValueAsText(
		mergeTag: MergeTagState,
		reference = []
	) {
		const metaValue = mergeTag?.value || [];

		const result = reference?.reduce((acc, cur, i) => {
			const value = metaValue?.find(
				(d) => +d?.referenceId === +cur?.referenceId
			)?.value || [' '];
			return [...acc, ...value];
		}, []);
		return result;
	}

	/**
	 * Remap merge tag values based on a merge tag reference for Table
	 * This is to ensure that all cells will be filled
	 * @param mergeTag <object> Merge Tag Value
	 * @param reference <array> Merge Tag Value of your Reference MergeTag
	 * @param removeEmptyRows <boolean> optional, if all values are empty, return empty value
	 * @returns <array> Array of values according to reference
	 */
	public static getReferenceValueForTable(
		mergeTag: MergeTagState,
		reference = [],
		removeEmptyRows?: boolean
	) {
		const metaValue = mergeTag?.value || [];
		const dataValues = metaValue?.filter(
			(x) => !!reference?.find((i) => +i?.referenceId === +x?.referenceId)
		);
		const repeat = this.repeatCount(dataValues) || 1;

		return Array.from(Array(repeat).keys())?.reduce((a, c, i) => {
			let result = reference?.reduce((acc, cur) => {
				const data = metaValue?.find(
					(d) => +d?.referenceId === +cur?.referenceId
				);
				return [...acc, data?.value[i] || ''];
			}, []);

			if (removeEmptyRows) {
				result = result?.some((res) => !!res) ? result : [];
			}

			return [...a, { referenceId: i, value: result }];
		}, []);
	}

	/**
	 * Remap merge tag values based on a merge tag reference for Repeat Section Table
	 * * This is to ensure that all cells will be filled
	 * @param mergeTag <object> Merge Tag
	 * @param reference <array> Merge Tag Value of your Reference MergeTag
	 * @param customRepeatCount <number> optional custom repeat count
	 * @param removeEmptyRows <boolean> optional, if all values are empty, return empty value
	 * @returns <array> Array of values according to reference
	 */
	public static getReferenceMultiValueForTable(
		mergeTag: MergeTagState,
		reference = [],
		customRepeatCount?: number,
		removeEmptyRows?: boolean
	) {
		const metaValue = mergeTag?.value || [];
		const dataValues = metaValue?.filter(
			(x) => !!reference?.find((i) => +i?.referenceId === +x?.referenceId)
		);
		const repeatSectionCount =
			customRepeatCount ?? (this.repeatCount(dataValues) || 1);

		return Array.from(Array(repeatSectionCount).keys())
			?.reduce((a, c, i) => {
				const repeatRowCount =
					dataValues?.reduce((aRow, cRow) => {
						return cRow?.value[i]?.length > aRow
							? cRow?.value[i]?.length
							: aRow;
					}, 0) || 1;

				const finalValue = Array.from(Array(repeatRowCount).keys())?.reduce(
					(ac, cc, ic) => {
						let result = reference?.reduce((acc, cur) => {
							const data = metaValue?.find(
								(d) => +d?.referenceId === +cur?.referenceId
							);
							try {
								return [...acc, data?.value[i][ic] || ''];
							} catch (error) {
								return [...acc, ''];
							}
						}, []);

						if (removeEmptyRows) {
							result = result?.some((res) => !!res) ? result : [];
						}

						return [...ac, { referenceId: ic, value: result }];
					},
					[]
				);

				return [...a, finalValue];
			}, [])
			?.map((x) => x.map((y) => y?.value));
	}

	/**
	 * Remap merge tag values based on a merge tag reference ID
	 * @param mergeTag <object> Merge Tag Value
	 * @param reference <array> Merge Tag Value of your Reference MergeTag
	 * @returns <array> Array of values according to reference
	 */
	public static getReferenceMultiValueAsText(
		mergeTag: MergeTagState,
		reference = [],
		customRepeatCount?: number
	) {
		const metaValue = mergeTag?.value || [];
		const dataValues = metaValue?.filter(
			(x) => !!reference?.find((i) => +i?.referenceId === +x?.referenceId)
		);
		const repeatSectionCount =
			customRepeatCount ?? (this.repeatCount(dataValues) || 1);

		return Array.from(Array(repeatSectionCount).keys())?.reduce((a, c, i) => {
			const result = reference?.reduce((acc, cur) => {
				const data = metaValue?.find(
					(d) => +d?.referenceId === +cur?.referenceId
				);
				try {
					return [...acc, data?.value[i] || ' '];
				} catch (error) {
					return [...acc, ' '];
				}
			}, []);
			return [...a, { referenceId: i, value: result }];
		}, []);
	}

	public static repeatCount(metaValue: any[] = [], isMultiValue?: boolean) {
		if (isMultiValue) {
			return metaValue?.reduce((a, c) => {
				const res = c?.value?.reduce((ac, cc) => {
					return cc?.value?.length > ac ? cc?.value?.length : ac;
				}, 0);
				return res > a ? res : a;
			}, 0);
		} else {
			return metaValue?.reduce((a, c) => {
				return c?.value?.length > a ? c?.value?.length : a;
			}, 0);
		}
	}

	/**
	 * Change the (Image & Link) Type of Merge Tags to text
	 * @param data <MergeTagState[]> - Array of merge tags
	 * @returns <MergeTagState[]> Array of Merge Tags with updated type of Images & Links to Text
	 */
	public static convertImageLinkToTextMT(data: MergeTagState[]) {
		return (
			data?.map((x) => {
				if (x?.type === 'L') {
					// Link
					return {
						...x,
						type: 'T',
					};
				}
				if (x?.type === 'I') {
					// Image
					return {
						...x,
						value: '',
						type: 'T',
					};
				}
				return x;
			}) || []
		);
	}

	public static parseData(data) {
		return util.tryCatchParse(data) ? JSON.parse(data) : data || [];
	}

	public static formatPercent(value) {
		return numUtil.isNumber(+value)
			? `${numeral(+value).format('0,0[.]00')}%`
			: '0%';
	}

	public static formatCurrencyWholeNumber(value) {
		return numUtil.isNumber(+value)
			? numUtil.formatWholeNumNoDecimal(+value)
			: '$0';
	}

	public static formatCurrency(value) {
		return numUtil.isNumber(+value)
			? numUtil.formatToCurrency(+value)
			: '$0.00';
	}

	public static getContentByDivId(
		content: string,
		key: string
	) {
		const wrapId = 'getContentByDivId';
		const html = document
			.createRange()
			.createContextualFragment(`<div id="${wrapId}">${content}</div>`);
		const newHtml = [];

		html.querySelectorAll(`div#${key}`).forEach((i: HTMLElement) => {
			newHtml.push(i?.innerHTML || '');
		});
		return newHtml?.join('') || '';
	}

	public static convertNewLineToParagraph (value: string) {
		const convert = (content: string) => {
			if (!content) {
				return '';
			}
			let newContent = content?.replace(/\n{2}/g, '&nbsp;</p><p>');
			newContent = newContent?.replace(/\n/g, '&nbsp;<br />');
			return '<p>' + newContent || '' + '</p>'; 
		}
		return Array.isArray(value)
			? value?.map(convert)
			: convert(value);
	}
}
