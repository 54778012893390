import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { FGPremiumStatsLeadType } from './state/fgpslt.model';
import { map, filter, takeUntil, delay, auditTime, startWith, tap, withLatestFrom, take } from 'rxjs/operators';
import { DashboardQuery } from '../../state/dashboard.query';
import { FGPremiumStatsLeadTypeQuery } from './state/fgpslt.query';
import { FGPremiumStatsLeadTypeService } from './state/fgpslt.service';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { BipolarBarChartComponent } from '../../../../../shared/bipolar-bar-chart/bipolar-bar-chart.component';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { FGPremiumStatsLeadTypeStore } from './state/fgpslt.store';

@Component({
  selector: 'app-fgpslt',
  templateUrl: './fgpslt.component.html',
  styleUrls: ['./fgpslt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FgpsltComponent implements OnInit, OnDestroy {
  public static widgetCode = 'fgpslt';
  public static widgetName = 'F&G Premium Stats - Rolling 12 Months - Lead Type';
  public static sizeX = 1000;
  public static sizeY = 400;
  public static minSizeX = 1000;
  public static minSizeY = 400;
  public static maxSizeY = 500;

  widgetCode = FgpsltComponent.widgetCode;
	chartId = 'fgpslt-bar-chart';

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  form: UntypedFormGroup = this.fb.group({
    leadTypes: this.fb.control([]),
    selectAllLeadType: this.fb.control(true),
  });

  PCLT$: Observable<any[]> =
    this.dropdownValueQuery.orderedChoices$('PCLT');

  isAllSelected = true;

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  isFetching = false;

  /**
   * Bipolar Bar chart element. referenced for manual refreshing
   * when gridstack resizes div.
   */
  @ViewChild('fgpsltBipolarBarChart') fgpsltBarChart: BipolarBarChartComponent;

  /**
   * FGPSLT Data for Rolling 12 Months (Year) (x3)
   */
  data$: Observable<FGPremiumStatsLeadType[]> = this.query.FGPremiumStatsLeadTypeData$;

  viewData$ = this.data$.pipe(
    map((data) => {
      return {
        series: [
          {
            name: 'New Business',
            data: data?.map(x => x.NewBusiness),
            meta: [
              {
                name: 'DomesticNewBusiness',
                data: data?.map(x => x.DomesticNewBusiness)
              },
              {
                name: 'CommercialNewBusiness',
                data: data?.map(x => x.CommercialNewBusiness)
              },
            ]
          },
          {
            name: 'Renewal',
            data: data?.map(x => x.Renewal),
            meta: [
              {
                name: 'DomesticRenewal',
                data: data?.map(x => x.DomesticRenewal)
              },
              {
                name: 'CommercialRenewal',
                data: data?.map(x => x.CommercialRenewal)
              },
            ]
          },
          {
            name: 'MTA',
            data: data?.map(x => x.MTA),
            meta: [
              {
                name: 'DomesticMTA',
                data: data?.map(x => x.DomesticMTA)
              },
              {
                name: 'CommercialMTA',
                data: data?.map(x => x.CommercialMTA)
              },
            ]
          },
        ],
        labels: data?.map(x => x.Month)
      };
    })
  );

  get selectAllLeadType() {
    return this.form.get('selectAllLeadType');
  }

  get leadTypes() {
    return this.form.get('leadTypes');
  }

  constructor(
    private dashboardQuery: DashboardQuery,
    private query: FGPremiumStatsLeadTypeQuery,
    private service: FGPremiumStatsLeadTypeService,
    private dashboard: DashboardComponent,
    private store: FGPremiumStatsLeadTypeStore,
    private dropdownValueQuery: DropdownValueQuery,
    private fb: UntypedFormBuilder) { }

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of annual API stats.
   */
  ngOnInit() {
    this.PCLT$.pipe(
      take(1),
      tap((leadTypes) => {
        const types = leadTypes?.map((type) => type?.value);
        this.leadTypes.setValue(types);
        this.store.setSelectedTypes(types);
      })
    ).subscribe();

    combineLatest([
      this.dashboardQuery.adviserFilter$,
      this.query.selectedTypes$,
    ]).pipe(
      filter(([advisers]) => advisers.length > 0),
      takeUntil(this.onDestroy$),
    ).subscribe(([advisers, types]) => {
      this.service.getFGPremiumStatsLeadTypeStats(
        advisers,
        types
      );
    });

    this.dashboard.gridChangeEvent.pipe(
      delay(200),
      takeUntil(this.onDestroy$)
    ).subscribe((res) => {
      if (res === FgpsltComponent.widgetCode) {
        this.fgpsltBarChart.refresh();
      }
    });

    this.selectAllLeadType.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      withLatestFrom(this.PCLT$),
    ).subscribe(([check, types]) => {
      if (check) {
        this.leadTypes.setValue(types?.map((type: any) => type?.value));
      } else {
        this.leadTypes.setValue([]);
      }
    });

    this.leadTypes.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      withLatestFrom(this.PCLT$),
    ).subscribe(([selected, types]) => {
      this.isAllSelected = selected.length === types.length;
      this.store.setSelectedTypes(selected);
    });
  }



  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
