import { Store, StoreConfig } from '@datorama/akita';
import { ServicingCalculatorState } from '../servicing-calculators/state/servicing-calculators-settings.model';
import { Injectable } from '@angular/core';

export interface TlMoatSettingsState {
	servicingCalculators: ServicingCalculatorState[];
}

export function createInitialState(): TlMoatSettingsState {
	return {
		servicingCalculators: [],
	};
}

@Injectable()
@StoreConfig({ name: 'tl-moat-settings' })
export class TlMoatSettingsStore extends Store<TlMoatSettingsState> {
	constructor() {
		super(createInitialState());
	}

	setServicingCalculators(data: ServicingCalculatorState[]) {
		this.update({ servicingCalculators: data });
	}
}
