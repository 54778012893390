<div
	class="form-row align-items-center mb-2"
	*ngFor="let d of documents; trackBy: trackByDocument"
>
	<div class="col-md-4">{{ d?.field }}</div>
	<div class="col-md d-flex align-items-center">
		<!-- Offline Advice Process Buttons -->
		<div class="w-100">
			<app-offline-documents
				[isAPClaim]="isClaim"
				[d]="d"
				[isEditable]="isEditable"
				(doDownload)="downloadFile(
              d?.value?.documentID,
              d?.field,
              d.value.documentName
            )"
				(doUpload)="openUploadModal('Upload New', d?.field)"
				(doRemove)="removeFile(d?.field)"
				(doView)="viewFile(d?.field)"
				(doLinkDocument)="linkDocument(d?.field)"
			>
			</app-offline-documents>
		</div>
	</div>
</div>
<div class="form-row align-items-center" *ngIf="isClaim && isEditable">
	<div class="col-md-4">&nbsp;</div>
	<div class="col-md d-flex align-items-center pr-0">
		<div style="width: 80%">
			<a class="tap-btn tap-btn--default w-100 d-block" (click)="addDocument()">
				Add Document +
			</a>
		</div>
	</div>
</div>
