import { Store, StoreConfig } from '@datorama/akita';
import { ClientAcceptanceSettingsState } from '../../../../modules/crt-settings/client-acceptance-settings/state/client-acceptance-settings.model';
import { SoaSettingsState } from '../../../../modules/crt-settings/soa-settings/state/soa-settings.model';
import { ServiceAdviceProcessState } from '../../../../shared/models/advice-process/advice-process.model';
import { PrimaryCustomerCompanyState } from '../../../../shared/models/business-profile/business/business.model';
import { PrimaryClientState } from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from '../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from '../../../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from '../../../../shared/models/client-profile/seondary-business/secondary-business.model';
import { AssetState } from '../../../../shared/models/client-review-template/assets-liabilities/asset/asset.model';
import { KiwiSaverState } from '../../../../shared/models/client-review-template/assets-liabilities/kiwiSaver/kiwiSaver.model';
import { LiabilityInfo, LiabilityState } from '../../../../shared/models/client-review-template/assets-liabilities/liability/liability.model';
import { MortgageState } from '../../../../shared/models/client-review-template/assets-liabilities/mortgage/mortgage.model';
import { OtherState } from '../../../../shared/models/client-review-template/assets-liabilities/other/other.model';
import { PropertyListItemState } from '../../../../shared/models/client-review-template/assets-liabilities/property/property.model';
import { ClientAcceptanceState } from '../../../../shared/models/client-review-template/client-acceptance/client-acceptance.model';
import { CompanyState } from '../../../../shared/models/client-review-template/company/company.model';
import { CurrentInsuranceState } from '../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { DeclarationState } from '../../../../shared/models/client-review-template/declaration/declaration.model';
import { DependentState } from '../../../../shared/models/client-review-template/dependent/dependent.model';
import { DisclosureDocumentState, DisclosureState } from '../../../../shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { DocumentModel } from '../../../../shared/models/client-review-template/document/document.model';
import { CarInsuranceState } from '../../../../shared/models/client-review-template/fg-insurance/car-insurance.model';
import { ContentInsuranceState } from '../../../../shared/models/client-review-template/fg-insurance/content-insurance.model';
import { HouseInsuranceState } from '../../../../shared/models/client-review-template/fg-insurance/house-insurance.model';
import { FinalStructureState } from '../../../../shared/models/client-review-template/final-structure/final-structure.model';
import { FamilyHistoryState } from '../../../../shared/models/client-review-template/history/family.model';
import { GpHistoryState } from '../../../../shared/models/client-review-template/history/gp.model';
import { BodyMeasureState } from '../../../../shared/models/client-review-template/history/measures.model';
import { MedicalHistoryState } from '../../../../shared/models/client-review-template/history/medical.model';
import { MedicalHistoryOptionsState } from '../../../../shared/models/client-review-template/history/medical.model';
import { FactFindComputationState } from '../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import { IncomeSourceState } from '../../../../shared/models/client-review-template/income-budget/income-source-details.model';
import { MonthlyExpenseDetailsState } from '../../../../shared/models/client-review-template/income-budget/monthly-expense.model';
import { OtherIncomeObjState } from '../../../../shared/models/client-review-template/income-budget/other-income.model';
import { RentalIncomeObjState } from '../../../../shared/models/client-review-template/income-budget/rental-income.model';
import { IntroductionState } from '../../../../shared/models/client-review-template/intro/intro.model';
import { PeopleState } from '../../../../shared/models/client-review-template/people/people.model';
import { CriticalIllnessState } from '../../../../shared/models/client-review-template/risk-analysis/critical-illness/critical-illness.model';
import { GoalsState } from '../../../../shared/models/client-review-template/risk-analysis/goals/goals.model';
import { RiskAnalysisLifeState } from '../../../../shared/models/client-review-template/risk-analysis/life/life.model';
import { RiskAnalysisMedicalState } from '../../../../shared/models/client-review-template/risk-analysis/medical/medical.model';
import { RiskProfileState } from '../../../../shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.model';
import { RiskAnalysisTPDState } from '../../../../shared/models/client-review-template/risk-analysis/tpd/tpd.model';
import { ScopeOfServiceState } from '../../../../shared/models/client-review-template/scope-of-service/scope-of-service.model';
import { TrustState } from '../../../../shared/models/client-review-template/trust/trust.model';
import { Kiwisaver } from '../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { MortgageCustomerService } from '../../../../shared/models/services/mortgage/mortgage.model';
import { PropertyAssetCustomerService } from '../../../../shared/models/services/property-asset/property-asset';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { DeclarationSettingState } from './../../../../modules/crt-settings/declaration-settings/declaration-template-settings/state/declaration-settings.model';
import { MergeTagState } from './../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ProposedInsuranceState as FSProposedInsuranceState } from '../../../../shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { ReferralState } from 'src/app/modules/referral-options/state/referral-options.model';
import { DeclarationEmailSettingsState } from 'src/app/modules/crt-settings/declaration-settings/declaration-email-settings/state/declaration-email-settings.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { Injectable } from "@angular/core";

export interface ClientReviewTemplateState {
	primaryClient: PrimaryClientState | any;
	secondaryClients: SecondaryClientState[];
	allSecondaryClients: SecondaryClientState[];
	secondaryTrusts: SecondaryTrustState[];
	secondaryCompanies: SecondaryBusinessState[];
	linkedContacts: LinkedContactState[];

	adviceProcessId: number;
	isCompany: boolean;

	// Fact/find PEOPLE
	people: PeopleState[];
	dependents: DependentState[];
	trusts: TrustState[];
	company: CompanyState[];

	// People Loaders
	peopleIsLoading: boolean;
	dependantsIsLoading: boolean;
	trustsIsLoading: boolean;
	companyIsLoading: boolean;

	// PCI and SCI Clients
	clientIndividuals: any[];
	// Trust Clients
	trustClients: any[];
	// Business/Company Clients
	companyClients: any[];

	// Fact and Find - Assets and Liabilities
	properties: PropertyListItemState[];
	kiwiSavers: KiwiSaverState[];
	assets: AssetState[];
	mortgages: MortgageState[];
	liabilities: LiabilityState[];
	others: Partial<OtherState[]>;

	isPropertiesLoading: boolean;
	isKiwiSaversLoading: boolean;
	isAssetsLoading: boolean;
	isMortgagesLoading: boolean;
	isLiabilitiesLoading: boolean;
	isOthersLoading: boolean;

	propertyAddresses: PropertyAssetCustomerService[];
	crmKiwiSavers: Kiwisaver[];
	crmMortgages: MortgageCustomerService[];
	crmLiabilities: LiabilityInfo[];

	// Fact find Income
	incomeSource: IncomeSourceState[];
	rentalDetails: RentalIncomeObjState;
	otherIncomeDetails: OtherIncomeObjState;
	factFindComputation: FactFindComputationState;
	monthlyExpense: MonthlyExpenseDetailsState;

	// Current L&R
	currentInsurances: CurrentInsuranceState[];
	currentInsurancesNext: boolean;
	lrApUpdateLoading: boolean;
	lrApPageCompleted: any[];
	lrApPageStarted: any[];
	lifeAssured: ViewDisplayValue[];
	isLoadingCurrentInsurance: boolean;

	// F&G Insurance
	houseInsurance: HouseInsuranceState[];
	carInsurance: CarInsuranceState[];
	contentInsurance: ContentInsuranceState[];
	blanketQuote: any;
	blanketOptions: ReferralState[];

	// body
	bodyMeasures: BodyMeasureState[];
	// medical
	medicalHistory: MedicalHistoryState[];
	// medical history options
	medicalHistoryOptions: MedicalHistoryOptionsState;
	// GP Loaders
	medicalHistoryIsLoading: boolean;
	// family
	familyHistory: FamilyHistoryState[];
	// GP Loaders
	familyHistoryIsLoading: boolean;
	// gp
	gpHistory: GpHistoryState[];

	gpClients: any[];
	// GP Loaders
	gpClientsIsLoading: boolean;

	// RISK-ANALYSIS
	goals: GoalsState;
	riskAnalysisLife: RiskAnalysisLifeState[];
	riskAnalysisTpd: RiskAnalysisTPDState[];
	riskAnalysisMedical: RiskAnalysisMedicalState[];
	criticalIllness: CriticalIllnessState[];
	riskProfile: RiskProfileState;

	// Merge Tags
	mergeTags: MergeTagState[];

	// Disclosure Document
	disclosureDocument: DisclosureState;
	disclosureSettings: DisclosureDocumentState;

	// Scope Of Service
	scopeOfService: ScopeOfServiceState;
	sosDefault: ScopeOfServiceState;

	// Declaration
	declaration: DeclarationState;
	declarationFormValue: DeclarationState | { content?: string };
	declarationDocument: DeclarationSettingState;
	declarationEmailSettings: DeclarationEmailSettingsState;

	adviceProcess: ServiceAdviceProcessState;

	// SOA
	soaSettings: SoaSettingsState;
	hasSoaChanges: boolean;
	triggerLeaveSoa: any;

	intro: IntroductionState;

	// Client Acceptance
	clientAcceptance: ClientAcceptanceState;
	clientAcceptanceSettings: ClientAcceptanceSettingsState;
	clientAcceptanceFormValue: ClientAcceptanceState;
	isLoadingClientAcceptance: boolean;
	// Client Acceptance Alternative structure
	caAlternativeStructure: CurrentInsuranceState[];
	// Client Acceptance Signature
	caSignature: DocumentModel;
	// Client Acceptance - PI Documents
	caProposedInsuranceDocuments: DocumentModel[];

	// Final Structure
	finalStructure: FinalStructureState;
	finalStructureFormValue: FinalStructureState;
	fsProposedInsurance: FSProposedInsuranceState[];
	isLoadingFinalStructure: boolean;
	isUpdatingFinalStructure: boolean;

	hasFormChanges: boolean;
}

export interface ClientReviewTemplateContacts {
	PCI: PrimaryClientState | PrimaryCustomerCompanyState;
	SCI: SecondaryClientState[];
	SCT: SecondaryTrustState[];
	LC: LinkedContactState[];
	PCC: SecondaryBusinessState[];
}

@Injectable()
@StoreConfig({ name: 'clientReviewTemplate' })
export class ClientReviewTemplateStore extends Store<ClientReviewTemplateState> {
	constructor() {
		super({
			isCompany: null,

			primaryClient: null,
			secondaryClients: null,
			allSecondaryClients: null,
			secondaryTrusts: null,
			secondaryCompanies: null,
			linkedContacts: null,

			adviceProcessId: null,
			people: null,
			dependents: null,
			trusts: null,
			company: null,

			clientIndividuals: null,
			trustClients: null,
			companyClients: null,

			// Fact and Find - Assets and Liabilities
			properties: null,
			kiwiSavers: null,
			assets: null,
			mortgages: null,
			liabilities: null,
			others: null,

			isPropertiesLoading: false,
			isKiwiSaversLoading: false,
			isAssetsLoading: false,
			isMortgagesLoading: false,
			isLiabilitiesLoading: false,
			isOthersLoading: false,

			propertyAddresses: null,
			crmKiwiSavers: null,
			crmMortgages: null,

			incomeSource: null,
			rentalDetails: null,
			otherIncomeDetails: null,
			monthlyExpense: null,
			factFindComputation: {},

			currentInsurances: null,
			currentInsurancesNext: false,
			lrApUpdateLoading: false,
			lrApPageCompleted: [],
			lrApPageStarted: [],
			lifeAssured: null,
			houseInsurance: null,
			carInsurance: null,
			contentInsurance: null,

			bodyMeasures: null,
			medicalHistory: null,
			medicalHistoryOptions: null,
			familyHistory: null,
			gpHistory: null,
			gpClients: null,

			goals: null,
			riskAnalysisLife: null,
			riskAnalysisTpd: null,
			riskAnalysisMedical: null,
			criticalIllness: null,
			riskProfile: null,

			mergeTags: null,
			disclosureDocument: null,
			scopeOfService: null,
			sosDefault: null,
			declaration: null,
			declarationFormValue: null,
			declarationDocument: null,
			adviceProcess: null,
			intro: null,

			// Client Acceptance
			clientAcceptance: null,
			clientAcceptanceFormValue: null,
			isLoadingClientAcceptance: false,
			// Client Acceptance Alternative structure
			caAlternativeStructure: null,
			// Client Acceptance Signature
			caSignature: null,

			soaSettings: null,
			hasSoaChanges: false,
			triggerLeaveSoa: null,

			finalStructure: null,
			finalStructureFormValue: null,
			fsProposedInsurance: null,
			isLoadingFinalStructure: false,

			hasFormChanges: false,
			isLoadingCurrentInsurance: false,
			clientAcceptanceSettings: null,
		});
	}

	setIsCompany(c: boolean) {
		this.update({ isCompany: c });
	}

	setPrimaryClient(c: PrimaryClientState | PrimaryCustomerCompanyState) {
		this.update({ primaryClient: c });
	}

	setSecondaryClients(c: SecondaryClientState[]) {
		this.update({ secondaryClients: c });
	}

	setAllSecondaryClients(c: SecondaryClientState[]) {
		this.update({ allSecondaryClients: c });
	}

	setSecondaryTrusts(c: SecondaryTrustState[]) {
		this.update({ secondaryTrusts: c });
	}

	setSecondaryCompanies(c: SecondaryBusinessState[]) {
		this.update({ secondaryCompanies: c });
	}

	setLinkedContacts(c: LinkedContactState[]) {
		this.update({ linkedContacts: c });
	}

	setAdviceProcessId(id: number) {
		this.update({ adviceProcessId: id });
	}

	setPeople(p: PeopleState[]) {
		this.update({ people: p });
	}

	setPeopleIsLoading(isLoading: boolean) {
		this.update({ peopleIsLoading: isLoading });
	}

	setDependents(p: DependentState[]) {
		this.update({ dependents: p });
	}

	setDependentsIsLoading(isLoading: boolean) {
		this.update({ dependantsIsLoading: isLoading });
	}

	setTrust(p: TrustState[]) {
		this.update({ trusts: p });
	}

	setTrustsIsLoading(isLoading: boolean) {
		this.update({ trustsIsLoading: isLoading });
	}

	setCompany(p: CompanyState[]) {
		this.update({ company: p });
	}

	setCompanyIsLoading(isLoading: boolean) {
		this.update({ companyIsLoading: isLoading });
	}

	setClientIndividuals(p: any) {
		this.update({ clientIndividuals: p });
	}

	setTrustClients(p: any) {
		this.update({ trustClients: p });
	}

	/**
	 * Store Fact and Find - Assets and Liabilities
	 * @param data list of properties, kiwiSaver, other
	 * assets and investments, mortgages, or liabilities
	 * @param type FAP | FAK | FAO | FAM | FAL
	 */
	setAssetsAndLiabilities(data, type: string) {
		switch (type) {
			case 'FAP':
				this.update({ properties: data });
				break;
			case 'FAK':
				this.update({ kiwiSavers: data });
				break;
			case 'FAA':
				this.update({ assets: data });
				break;
			case 'FAM':
				this.update({ mortgages: data });
				break;
			case 'FAL':
				this.update({ liabilities: data });
				break;
			case 'FAO':
				this.update({ others: data });
				break;
		}
	}

	setAssetsAndLiabilitiesLoading(isLoading, type: string) {
		switch (type) {
			case 'FAP':
				this.update({ isPropertiesLoading: isLoading });
				break;
			case 'FAK':
				this.update({ isKiwiSaversLoading: isLoading });
				break;
			case 'FAA':
				this.update({ isAssetsLoading: isLoading });
				break;
			case 'FAM':
				this.update({ isMortgagesLoading: isLoading });
				break;
			case 'FAL':
				this.update({ isLiabilitiesLoading: isLoading });
				break;
			case 'FAO':
				this.update({ isOthersLoading: isLoading });
				break;
		}
	}

	setPropertyAddresses(data) {
		this.update({ propertyAddresses: data });
	}

	setCrmKiwiSavers(data) {
		this.update({ crmKiwiSavers: data });
	}

	setCrmMortgages(data) {
		this.update({ crmMortgages: data });
	}

	setCrmLiabilities(data) {
		this.update({ crmLiabilities: data });
	}

	setCompanyClients(p: any) {
		this.update({ companyClients: p });
	}

	setIncomeSource(p: IncomeSourceState[]) {
		this.update({ incomeSource: p });
	}

	setRentalIncome(p: RentalIncomeObjState) {
		this.update({ rentalDetails: p });
	}

	setOtherIncome(p: OtherIncomeObjState) {
		this.update({ otherIncomeDetails: p });
	}

	setProperty(p: PropertyListItemState[]) {
		this.update({ properties: p });
	}

	setMonthlyExpense(p: MonthlyExpenseDetailsState) {
		this.update({ monthlyExpense: p });
	}

	setFactFindComputation(p: FactFindComputationState) {
		this.update({ factFindComputation: p });
	}

	setCurrentInsurance(p) {
		this.update({ currentInsurances: p });
	}

	setCurrentInsuranceNext(p: boolean) {
		this.update({ currentInsurancesNext: p });
	}

	setLrApUpdateLoading(p: boolean) {
		this.update({ lrApUpdateLoading: p });
	}

	setLrApPageCompleted(p: any[]) {
		this.update({ lrApPageCompleted: p });
	}

	setLrApPageStarted(p: any[]) {
		this.update({ lrApPageStarted: p });
	}

	setCurrentInsuranceIsLoading(isLoading: boolean) {
		this.update({ isLoadingCurrentInsurance: isLoading });
	}

	setLifeAssured(p: ViewDisplayValue[]) {
		this.update({ lifeAssured: p });
	}

	setHouseInsurance(p: HouseInsuranceState[]) {
		this.update({ houseInsurance: p });
	}

	setCarInsurance(p: CarInsuranceState[]) {
		this.update({ carInsurance: p });
	}

	setContentInsurance(p: ContentInsuranceState[]) {
		this.update({ contentInsurance: p });
	}

	setBlanketQuote(p: any) {
		this.update({ blanketQuote: p });
	}

	setBlanketOptions(p: any) {
		this.update({ blanketOptions: p });
	}

	setBodyMeasures(p: any) {
		this.update({ bodyMeasures: p });
	}

	setMedicalHistory(p: any) {
		this.update({ medicalHistory: p });
	}

	setMedicalHistoryOptions(p: any) {
		this.update({ medicalHistoryOptions: p });
	}

	setGpMedicalHistoryIsLoading(isLoading: boolean) {
		this.update({ medicalHistoryIsLoading: isLoading });
	}

	setFamilyHistory(p: any) {
		this.update({ familyHistory: p });
	}

	setFamilyHistoryIsLoading(isLoading: boolean) {
		this.update({ familyHistoryIsLoading: isLoading });
	}

	setGpHistory(p: any) {
		this.update({ gpHistory: p });
	}

	setGpClients(p: any) {
		this.update({ gpClients: p });
	}

	setGpClientsIsLoading(isLoading: boolean) {
		this.update({ gpClientsIsLoading: isLoading });
	}

	setGoals(p: GoalsState) {
		this.update({ goals: p });
	}

	setRiskAnalysisLife(p: RiskAnalysisLifeState[]) {
		this.update({ riskAnalysisLife: p });
	}

	setRiskAnalysisTpd(p: RiskAnalysisTPDState[]) {
		this.update({ riskAnalysisTpd: p });
	}

	setRiskAnalysisMedical(p: RiskAnalysisMedicalState[]) {
		this.update({ riskAnalysisMedical: p });
	}

	setCriticalIllness(p: CriticalIllnessState[]) {
		this.update({ criticalIllness: p });
	}

	setRiskProfile(p: RiskProfileState) {
		this.update({ riskProfile: p });
	}

	setMergeTags(p: MergeTagState[]) {
		this.update({ mergeTags: p });
	}

	setDisclosureDocument(p: DisclosureState) {
		this.update({ disclosureDocument: p });
	}

	setDisclosureSettings(p: DisclosureDocumentState) {
		this.update({ disclosureSettings: p });
	}

	setScopeOfService(p: ScopeOfServiceState) {
		this.update({ scopeOfService: p });
	}

	setSosDefault(p: ScopeOfServiceState) {
		this.update({ sosDefault: p });
	}

	setDeclaration(p: DeclarationState) {
		this.update({ declaration: p });
	}

	setDeclarationFromValue(p: DeclarationState) {
		this.update({ declarationFormValue: p });
	}

	setDeclarationDocument(p: DeclarationSettingState) {
		this.update({ declarationDocument: p });
	}

	setDeclarationEmailSettings(p: DeclarationEmailSettingsState) {
		this.update({ declarationEmailSettings: p });
	}

	setAdviceProcess(p: ServiceAdviceProcessState) {
		this.update({ adviceProcess: p });
	}

	setIntro(p: IntroductionState) {
		this.update({ intro: p });
	}

	setClientAcceptance(ca: ClientAcceptanceState) {
		this.update({ clientAcceptance: ca });
	}

	setClientAcceptanceFormValue(ca: ClientAcceptanceState) {
		this.update({ clientAcceptanceFormValue: ca });
	}

	setIsLoadingClientAcceptance(isLoading: boolean) {
		this.update({ isLoadingClientAcceptance: isLoading });
	}

	setFinalStructure(fs: FinalStructureState) {
		this.update({ finalStructure: fs });
	}

	setFinalStructureFormValue(fs: FinalStructureState) {
		this.update({ finalStructureFormValue: fs });
	}

	setFSProposedInsurance(fsp: FSProposedInsuranceState[]) {
		this.update({ fsProposedInsurance: fsp });
	}

	setSoaSettings(p: SoaSettingsState) {
		this.update({ soaSettings: p });
	}

	setHasSoaChanges(p: boolean) {
		this.update({ hasSoaChanges: p });
	}

	setTriggerLeaveSoa(p: any) {
		this.update({ triggerLeaveSoa: p });
	}

	setIsLoadingFinalStructure(isLoading: boolean) {
		this.update({ isLoadingFinalStructure: isLoading });
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.update({ hasFormChanges });
	}
}
