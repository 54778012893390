import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Disability } from 'src/app/shared/models/client-review-template/risk-analysis/disability/disability.model';

export interface DisabilityState extends EntityState<Disability> {
  activeParentCRTId: number;
  formValue: Disability;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'disability', idKey: 'parentCRTId' })
export class DisabilityStore extends EntityStore<DisabilityState> {
  constructor() {
    super({
      activeParentCRTId: null,
      formValue: null,
    });
  }

  setActiveParentCRTId(parenCRTId) {
    this.update((state) => ({ activeParentCRTId: parenCRTId }));
  }

  setFormValue(formValue) {
    this.update((state) => ({ formValue }));
  }
}
