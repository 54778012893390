<span class="tap-required" *ngIf="isEditing && !field.valid">*</span>
<div class="field">
  <div #longText *ngIf="!isEditing; else fieldTemplate;">
    <a class="m-auto" [ngClass]="{'clickable' : route}" [routerLink]="route" (click)="onClick()" id="{{fieldId}}Link_{{index}}">
      {{ value }}
    </a>
  </div>
</div>

<ng-container *ngIf="!restrict; else hasRestriction;">
  <div class="control">
    <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()" id="{{fieldId}}EditBtn_{{index}}">
      <i class="material-icons md-16 dark">&#xE3C9;</i>
    </button>
  </div>
</ng-container>

<ng-template #hasRestriction>
  <ng-container *appRestrictTo="restrict">
    <div class="control">
      <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="edit()" id="{{fieldId}}EditBtn_{{index}}">
        <i class="material-icons md-16 dark">&#xE3C9;</i>
      </button>
    </div>
  </ng-container>
</ng-template>

<div>
  <app-manual-loader-mini *ngIf="isLoading" [isLoading]="isLoading" class="loader-mini">
  </app-manual-loader-mini>
</div>

<div class="edit-controls d-flex flex-column" *ngIf="isEditing && !isLoading">
  <button type="submit" class="icon-btn-bg btn-save" (click)="save()" id="{{fieldId}}SaveBtn_{{index}}">
    <i class="material-icons md-16 dark">&#xE161;</i>
  </button>
  <button type="button" class="icon-btn-bg btn-cancel" (click)="cancel()" id="{{fieldId}}CancelBtn_{{index}}">
    <i class="material-icons md-16 dark">&#xE5C9;</i>
  </button>
</div>


<!-- Template for edit mode. shows address textbox. -->
<ng-template #fieldTemplate>
  <input appAddressField type="text" [formControl]="field" class="form-control" id="{{fieldId}}RowId_{{index}}" />
</ng-template>