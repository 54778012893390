import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as R from 'ramda';

const createFormGroup = (
	fb: UntypedFormBuilder,
	schema: { [key: string]: any },
	validator?: { [key: string]: any }
) => {
	const keys = R.keys(schema);
	return fb.group(
		R.mergeAll(
			R.map(
				(x) => ({
					[x]: R.is(Array, schema[x]) ? createFormArray(fb, schema[x]) : [
						schema[x],
						(validator ? (validator[x] || []) : []),
					],
				}),
				keys
			)
		)
	);
};

const createFormArray = (
	fb: UntypedFormBuilder,
	schema: any[],
	validator?: { [key: string]: any } | null
) => {
	const values = R.values(schema);
	return fb.array(
			R.map(
				(s) =>
					!R.is(Object, s)
						? [s, validator || []]
						: createFormGroup(fb, s, validator),
				values
			)
		 || []
	);
};

const clearFormArray = (formArray: UntypedFormArray) => {
	while (formArray.length > 0) {
		formArray.removeAt(0);
	}
	return formArray;
};

const resetForm = (form: UntypedFormGroup | UntypedFormArray | UntypedFormControl) => {
	form.reset();
	return form;
};

const getFormValue = (form: UntypedFormGroup | UntypedFormArray | UntypedFormControl) => {
	let value;
	if (form instanceof UntypedFormGroup) {
		value = form.getRawValue();
	}

	if (form instanceof UntypedFormGroup) {
		value = form.getRawValue();
	}

	if (form instanceof UntypedFormControl) {
		value = form.value;
	}
	return value;
};

const disableForm = (form: UntypedFormGroup | UntypedFormArray | UntypedFormControl) => {
	form.disable();
	return form;
};

const enableForm = (form: UntypedFormGroup | UntypedFormArray | UntypedFormControl) => {
	form.enable();
	return form;
};

const isDisabledForm = (form: UntypedFormGroup | UntypedFormArray | UntypedFormControl) => {
	return form.disabled;
};

const isEnabledForm = (form: UntypedFormGroup | UntypedFormArray | UntypedFormControl) => {
	return form.enabled;
};

export const formUtil = {
	createFormGroup,
	createFormArray,
	clearFormArray,
	getFormValue,
	disableForm,
	enableForm,
	isDisabledForm,
	isEnabledForm,
	resetForm,
};
