<div class="row tap-container disclosure-document pt-3">
  <div class="col">
    <tabset [appTheme]="{ color: 'primarycolor' }">
      <tab heading="Email Settings">
        <app-final-structure-email-settings
          [emailSettings]="emailSettings$ | async"
          [mergeTags$]="mergeTags$"
        >
        </app-final-structure-email-settings>
      </tab>
    </tabset>
  </div>
</div>
