import { Component, OnInit, OnDestroy } from '@angular/core';

import { uniq, complement, isNil, isEmpty, either } from 'ramda';
import { iif, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	take,
	tap,
	takeUntil,
} from 'rxjs/operators';
import {
	DocumentUploadState,
	IntroductionState,
} from '../../../../../shared/models/client-review-template/intro/intro.model';
import { MortgageAdviceProcessService } from '../state/mortgage-adviceprocess/mortgage-advice-process.service';
import {
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { CrtMortgageQuery } from '../state/crt-mortgage.query';
import { CrtMortgageService } from '../state/crt-mortgage.service';
import { SidebarStatus } from '../../_shared/models/sidebar.model';
import { AdviceProcessPageCodes } from '../../../../../shared/models/advice-process/advice-process.model';

@Component({
	selector: 'app-intro',
	templateUrl: './intro.component.html',
	styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	intro$ = this.query.intro$;
	introduction: IntroductionState;
	document: DocumentUploadState;
	isLoading = false;
	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;
	sidebar = this.query.getValue().sidebars;

	constructor(
		private query: CrtMortgageQuery,
		private mApService: MortgageAdviceProcessService,
		private mService: CrtMortgageService
	) {}

	ngOnInit(): void {
		this.mApService
			.updateMortApPageStarted(AdviceProcessPageCodes.Introduction)
			.pipe(take(1))
			.subscribe();

		this.intro$
			.pipe(
				tap(() => (this.isLoading = true)),
				filter((data) => !!data),
				tap((data) => {
          this.introduction = data;
        }),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();

		this.query.adviceProcess$
			.pipe(
				tap((ap) => {
					if (!ap?.pageStarted?.includes(AdviceProcessPageCodes.Introduction)) {
						this.mService.setSideSidebarStatus(
							MortgageAdviceProcessPageIds.Introduction,
							false,
							SidebarStatus.Completed
						);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.query.mortApPageCompleted$
			.pipe(
				concatMap((data) => {
					const code = AdviceProcessPageCodes.Introduction;
					const list = data ? uniq([...data, code]) : [];
					return of(list).pipe(
						tap((x) => this.mApService.setMortApPageCompletedState(x)),
						concatMap(() =>
							iif(
								() =>
									!complement(either(isNil, isEmpty))(data) ||
									!data?.some((x) => x === code),
								this.mApService.updateMortApPageCompleted(list),
								of(null)
							)
						)
					);
				}),
				take(1)
			)
			.subscribe();
	}

	next() {}

	pauseExit() {}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
