<div class="review-application pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<tab heading="Notes">
				<app-note-settings></app-note-settings>
			</tab>
			<tab heading="Document List">
				<app-document-list></app-document-list>
			</tab>
			<tab heading="Email Settings">
				<app-review-application-email-settings [emailSettings]="emailSettings$ | async" [mergeTags$]="mergeTags$"></app-review-application-email-settings>
			</tab>
			<tab heading="Application">
				<div
					class="col-12 pt-4 review-application__froala position-relative"
					[class.view-mode]="!isTapLevel"
				>
					<app-wysiwyg
						#contentEditor
						[id]="'reviewApplicationEditor'"
						[shortcodes]="shortcodes"
						[content]="currentTemplate"
						[isTapLevel]="isTapLevel"
						[undoReset$]="undoReset$"
						(undoStack)="getUndoStack($event)"
						designClass="pdf-design-v2-froala"
					></app-wysiwyg>
			
					<div class="row pl-3 pr-1 mb-2">
						<div class="col-12 col-lg-6 pl-0">
							<ng-container *ngIf="isTapLevel && !isLoading">
								<button
									type="button"
									class="btn btn-primary my-2 tap-bg-primary"
									(click)="reloadTemplate($event)"
									*ngIf="!isPreview"
									[disabled]="isLoading"
								>
									RELOAD TEMPLATE
								</button>
							</ng-container>
						</div>
						<div class="col-12 col-lg-6 text-right pr-2">
							<ng-container *ngIf="isTapLevel && !isLoading">
								<button
									type="button"
									class="btn btn-primary my-2 mx-1 tap-bg-primary"
									(click)="backToEdit()"
									*ngIf="isPreview"
								>
									BACK TO EDIT
								</button>
								<button
									type="button"
									class="btn btn-primary my-2 mx-1 tap-bg-primary"
									(click)="showPreview()"
									*ngIf="!isPreview"
									[disabled]="isLoading"
								>
									PREVIEW
								</button>
								<button
									type="button"
									class="btn btn-primary my-2 mx-1 tap-bg-primary"
									(click)="save($event)"
									*ngIf="!isPreview"
									[disabled]="isLoading"
								>
									SAVE
								</button>
							</ng-container>
			
							<ng-container *ngIf="isLoading">
								<app-manual-loader-mini
									[isLoading]="isLoading"
								></app-manual-loader-mini>
							</ng-container>
						</div>
					</div>
				</div>
			</tab>
		</tabset>
	</div>

</div>
