import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { SimpleDataTableModel } from '@models/_general/simple-data-table.model';
import { of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { RouteService } from '@core/config/route.service';
import { Router } from '@angular/router';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
	selector: 'app-simple-data-table',
	templateUrl: './simple-data-table.component.html',
	styleUrls: ['./simple-data-table.component.scss'],
})
export class SimpleDataTableComponent implements OnInit {
	@Input() displayedColumns: string[] = [];
	@Input() tableColumns: SimpleDataTableModel[] = [];
	@Input() itemPerPage: number = 10;
	@Input() enablePagination: boolean = true;
	@Input() dataSource: any[] = [];
	@Input() isLoadingData = false;

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	listPerPage: any[];
	currentPage = 0;
	isLoading = false;

	constructor(
		private cd: ChangeDetectorRef,
		private routeService: RouteService,
		private router: Router,
		private docService: CrtDocumentService
	) {}

	ngOnInit(): void {
		this.sliceData();
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges?.dataSource) {
			this.render();
		}
	}

	render() {
		this.isLoading = true;
		this.sliceData();
		this.cd.detectChanges();
		setTimeout(() => {
			this.currentPage = 0;
			this.isLoading = false;
		}, 100);
	}

	sliceData() {
		if (this.enablePagination) {
			this.listPerPage =
				this.dataSource?.length > 0
					? this.dataSource.slice(0, this.itemPerPage)
					: [];
		} else {
			this.listPerPage = this.dataSource || [];
		}
	}

	setPage(event: PageChangedEvent): void {
		this.currentPage = event.page;
		const startItem = (event.page - 1) * event.itemsPerPage;
		const endItem = event.page * event.itemsPerPage;
		this.listPerPage = this.dataSource?.slice(startItem, endItem);
		this.cd.detectChanges();
	}

	isEllipsisActive(e: HTMLElement): boolean {
		return e ? e.offsetWidth < e.scrollWidth : false;
	}

	viewFile(data) {
		return of({
			documentId: data?.Id,
			name: data?.FileName || '',
		})
			.pipe(
				mergeMap((res) => {
					const ext = res?.name?.split('.')?.reverse()?.[0];
					if (!!ext && !!res?.documentId) {
						if (ext?.toLowerCase() === 'pdf') {
							return of(res).pipe(
								tap(() => {
									const pdfUrl = this.router.serializeUrl(
										this.router.createUrlTree(this.routeService.viewPdf(res))
									);
									window.open(pdfUrl, '_blank');
								}),
								take(1)
							);
						} else {
							return this.downloadDocumentFn$({
								documentId: res?.documentId,
								fileName: res?.name,
							});
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}

	downloadDocumentFn$ = (req: { documentId: number; fileName: string }) => {
		return this.docService.downloadLink(+req?.documentId).pipe(
			tap((fileUrl) => {
				if (!fileUrl) {
					return;
				}
				const a = document.createElement('a');
				a.href = fileUrl;
				a.setAttribute('download', req.fileName || '');
				a.click();
			}),
			take(1)
		);
	};

	open() {
		this.popover.show();
	}

	close() {
		this.popover.hide();
	}
}
