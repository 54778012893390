import { Row } from './mortgage.model';
import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { MortgageUiStore } from './mortgage-ui.store';
import produce from 'immer';
import * as R from 'ramda';
import { MortgageRequest } from '../mortgage-request.model';
import { Injectable } from '@angular/core';

interface ColumnWidth {
  metakey: string;
  width: number;
}

export interface MortgageState extends EntityState<Row> {
  columns: string[];
  columnWidths: ColumnWidth[];
  count: number;
  totalAPI: number;
  isComplete: boolean;
  searchForm: MortgageRequest;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'MortgageSearch', idKey: 'CustomerServiceID' })
export class MortgageStore extends EntityStore<MortgageState, Row> {

  constructor(public uiStore: MortgageUiStore) {
    super({ columns: [], count: 0, totalAPI: 0, isComplete: false, columnWidths: [], searchForm: null });
  }

  setColumns(columns: string[]) {
    this.update(produce(x => { x.columns = columns; }));
  }

  setColumnWidths(columnWidths: ColumnWidth[]) {
    this.update(produce(x => {
      x.columnWidths = columnWidths;
    }));
  }

  setSearchForm(req: MortgageRequest) {
    this.update(produce(x => {
      x.searchForm = req;
    }));
  }

  updateField(req: { CustomerServiceId: number | number[], MetaKey: string, MetaValue: string }) {
    this.update(req.CustomerServiceId, produce(draft => {
      const fields = R.omit(['CustomerID', 'CustomerServiceID', 'link',
        'ActivityId', 'BorrowingEntitiesList', 'SecuritiesList', 'ClientNextActivityId', 'UserNextActivityId'], draft);
      Object.values(fields).find(x => x.metakey === req.MetaKey).value = req.MetaValue;
      if (req.MetaKey === 'Status') {
        draft.StatusClock.value = '0 days';
      }

      if (req.MetaKey === 'Loan Value' && req.MetaValue === '0') {
        draft.LoanValue.value = '0.00';
      }

      if (req.MetaKey === 'Interest Rate' && req.MetaValue === '0') {
        draft.InterestRate.value = '0.00';
      }
    }));
  }
}
