<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.AssetsLiabilities"></app-header>
</div>

<!-- Conttent -->
<div role="main-content">
	<!-- Propety -->
	<div class="main-content-section">
		<div class="main-content-section__header mb-3">Property</div>
		<div class="main-content-section__body mb-3">
			<app-property
				[properties]="properties$ | async"
				[totalPropertyValue]="totalPropertyValues$ | async"
				[policyOwners]="policyOwners$ | async"
				[SU$]="SU$"
				[SV1$]="SV1$"
				[ST$]="ST$"
				[STI$]="STI$"
				[APCRTF$]="APCRTF$"
				[addFn$]="addPropertyFn$"
				[updateFn$]="updatePropertyFn$"
				[deleteFn$]="deletePropertyFn$"
				[propertyAddresses]="propertyAddresses$ | async"
				[adviceProcessId]="adviceProcessId"
				[getOwnerChoices]="getOwnerChoices"
			>
				<app-manual-loader-mini
					*ngIf="isLoadingProperty$ | async as isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</app-property>
		</div>
		<div class="main-content-section__footer mb-3">
			<div class="border-bottom"></div>
		</div>
	</div>

	<!-- KiwiSaver -->
	<div class="main-content-section">
		<div class="main-content-section__header mb-3">KiwiSaver</div>
		<div class="main-content-section__body mb-4">
			<app-crt-kiwisaver-template
				[kiwiSavers]="kiwiSavers$ | async"
				[lifeAssuredList$]="lifeAssuredList$"
				[KP$]="KP$"
				[KFT$]="KFT$"
				[APCRTRP$]="APCRTRP$"
				[addFn$]="addKiwiSaverFn$"
				[updateFn$]="updateKiwiSaverFn$"
				[deleteFn$]="deleteKiwiSaverFn$"
				[crmKiwiSavers]="crmKiwiSavers$ | async"
				[crmKiwiSaversChoinces]="crmKiwiSaversChoinces$ | async"
				[transferedSCIList]="transferedSCIList$ | async"
				[adviceProcessId]="adviceProcessId"
				[getOwnerChoices]="getOwnerChoices"
			>
				<app-manual-loader-mini
					*ngIf="isLoadingKiwiSaver$ | async as isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</app-crt-kiwisaver-template>
		</div>
		<div class="main-content-section__footer mb-3">
			<div _ngcontent-cng-c423="" class="border-bottom"></div>
		</div>
	</div>

	<!-- Other Assets/Investments -->
	<div class="main-content-section">
		<div class="main-content-section__header mb-3">
			Other Assets/Investments
		</div>
		<div class="main-content-section__body mb-4">
			<app-crt-asset
				[assets]="assets$ | async"
				[totalValue]="totalValue$ | async"
				[policyOwners$]="pciAndSciFromCrmAndCrtChoicesWithBusinessTrust$"
				[APCRTA$]="APCRTA$"
				[addFn$]="addAssetFn$"
				[updateFn$]="updateAssetFn$"
				[deleteFn$]="deleteAssetFn$"
				[adviceProcessId]="adviceProcessId"
			>
				<app-manual-loader-mini
					*ngIf="isLoadingAsset$ | async as isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</app-crt-asset>
		</div>
		<div class="main-content-section__footer mb-3">
			<div _ngcontent-cng-c423="" class="border-bottom"></div>
		</div>
	</div>

	<!-- Mortgage -->
	<div class="main-content-section">
		<div class="main-content-section__header mb-3">Mortgages</div>
		<div class="main-content-section__body mb-4">
			<app-crt-mortgage-template
				[mortgages]="mortgages$ | async"
				[borrowers$]="borrowers$"
				[securities$]="mortgageSecurities$"
				[crmMortgagesChoices]="crmMortgagesChoices$ | async"
				[crmMortgages]="crmMortgages$ | async"
				[MP$]="MP$"
				[MLT$]="MLT$"
				[APCRTF$]="APCRTF$"
				[addFn$]="addMortgageFn$"
				[updateFn$]="updateMortgageFn$"
				[deleteFn$]="deleteMortgageFn$"
				[adviceProcessId]="adviceProcessId"
				[getOwnerChoices]="getOwnerChoices"
			>
				<app-manual-loader-mini
					*ngIf="isLoadingMortgage$ | async as isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</app-crt-mortgage-template>
		</div>
		<div class="main-content-section__footer mb-3">
			<div _ngcontent-cng-c423="" class="border-bottom"></div>
		</div>
	</div>

	<!-- Liabilities -->
	<div class="main-content-section">
		<div class="main-content-section__header mb-3">Liabilities</div>
		<div class="main-content-section__body mb-4">
			<app-crt-liability
				[liabilities]="liabilities$ | async"
				[borrowers$]="borrowers$"
				[securities$]="securities$"
				[MP$]="MP$"
				[MLT$]="MLT$"
				[APCRTF$]="APCRTF$"
				[APCRTYN$]="APCRTYN$"
				[APCRTYNNA$]="APCRTYNNA$"
				[APCRTL$]="APCRTL$"
				[addFn$]="addLiabilityFn$"
				[updateFn$]="updateLiabilityFn$"
				[deleteFn$]="deleteLiabilityFn$"
				[adviceProcessId]="adviceProcessId"
				[getOwnerChoices]="getOwnerChoices"
			>
				<app-manual-loader-mini
					*ngIf="isLoadingLiability$ | async as isLoading"
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</app-crt-liability>
		</div>
		<div class="main-content-section__footer mb-3">
			<div _ngcontent-cng-c423="" class="border-bottom"></div>
		</div>
	</div>

	<!-- Questions -->
	<div class="main-content-section">
		<div class="main-content-section__body mb-3">
			<app-manual-loader-mini
				*ngIf="isLoadingOther$ | async as isLoading; else questions"
				[isLoading]="isLoading"
			></app-manual-loader-mini>
			<ng-template #questions>
				<form [formGroup]="form" [class.submitted]="submitted" class="form">
					<div class="form-row">
						<div class="col-3">
							<label for="" class="font-weight-bold"
								>Do you have any personal guarantees?</label
							>
						</div>
						<div class="col-9 mb-2">
							<span class="crt-custom-dropdown w-auto">
								<select
									class="form-control crt-form-control"
									formControlName="HasPersonalGuarantees"
									id="personalGuarantees"
								>
									<option value="" selected hidden></option>
									<option
										*ngFor="let dropdown of APCRTYNNA$ | async"
										[value]="dropdown.value"
									>
										{{ dropdown.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
					<div class="form-row mb-3">
						<div class="col-3"></div>
						<div class="col-9 mb-2">
							<ng-container
								*ngIf="form.get('HasPersonalGuarantees').value === 'Yes'"
							>
								<div class="pt-2">
									<textarea
										class="form-control crt-form-control"
										formControlName="PersonalGuaranteesDetails"
										rows="3"
										id="personalGuaranteesDetails"
									></textarea>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="form-row">
						<div class="col-3">
							<label for="" class="font-weight-bold">
								Do you have any other potential financial responsibilities?
							</label>
						</div>
						<div class="col-9 mb-2">
							<span class="crt-custom-dropdown w-auto">
								<select
									class="form-control crt-form-control"
									formControlName="HasFinancialResponsibilities"
									id="potentialFinancialResponsibilities"
								>
									<option value="" selected hidden></option>
									<option
										*ngFor="let dropdown of APCRTYNNA$ | async"
										[value]="dropdown.value"
									>
										{{ dropdown.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
					<div class="form-row mb-3">
						<div class="col-3"></div>
						<div class="col-9 mb-2">
							<ng-container
								*ngIf="form.get('HasFinancialResponsibilities').value === 'Yes'"
							>
								<div class="pt-2">
									<textarea
										class="form-control crt-form-control"
										formControlName="FinancialResponsibilitiesDetails"
										id="financialResponsibilitiesDetails"
										rows="3"
									></textarea>
								</div>
							</ng-container>
						</div>
					</div>
				</form>
			</ng-template>
		</div>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer
		[currentPageId]="pageIds.AssetsLiabilities"
		[sidebar]="sidebar"
	></app-footer>
</div>
