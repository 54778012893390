import { AdviceProcessSectionCodes } from '../../../advice-process/advice-process.model';
import {
  DropdownGoalsState, GoalsState,
  RetirementState
} from './goals.model';

export class GoalsMapper {

  /**
   * Map to update
   */
  public static mapGoalsToUpsert(list: DropdownGoalsState[]): DropdownGoalsState[] {
    if (!list) {
      return [];
    }
    return list?.map((item) => {
      return {
        dropdown: item.dropdown,
        value: item.value,
      };
    });
  }

  public static mapRetireToUpsert(list: RetirementState[]): RetirementState[] {
    if (!list) {
      return [];
    }
    return list?.map((item) => {
      return {
        cRTId: +item.cRTId,
        age: item.age,
      };
    });
  }


  public static mapGoalsObjToUpsert(data: GoalsState): GoalsState {
    return {
      ...data,
      status: 1,
      sectionCode: AdviceProcessSectionCodes.Goals,
    };
  }

}
