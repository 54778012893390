import {
	Component,
	OnInit,
	AfterViewInit,
	ViewChild,
	Input,
	OnChanges,
	ChangeDetectorRef,
	OnDestroy,
	ViewChildren,
	QueryList,
	ChangeDetectionStrategy,
	SimpleChanges,
} from '@angular/core';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClientProfileService } from '../../states/client-profile.service';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { CollapseComponent } from '../../../../../shared/collapse/collapse.component';
import { RouteService } from '../../../../../core/config/route.service';
import { map, take, tap, mergeMap, takeUntil, finalize, switchMap } from 'rxjs/operators';
import { Subject, of, Observable } from 'rxjs';
import { ConfirmModalComponent } from '../../../../../shared/modal/confirm-modal/confirm-modal.component';
import { FormPersonComponent } from '../../forms/form-person/form-person.component';
import { FormSecondaryComponent } from '../../forms/form-secondary/form-secondary.component';
import { PrimaryClientState } from '../../../../../shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from '../../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryBusinessState } from '../../../../../shared/models/client-profile/seondary-business/secondary-business.model';
import { ClientProfilePrimaryMapper } from '../../../../../shared/models/client-profile/primary-client/primary-client.mapper';
import { SecondaryTrustState } from '../../../../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryProfessionalState } from '../../../../../shared/models/client-profile/secondary-professional/secondary-professional.model';
import { AddPhotoRequest } from '../../../../../core/customer/customer.service';
import { NoteState } from '../../../../../shared/models/notes/note.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { objectUtil } from '../../../../../util/util';
import { NoteRequest } from '../../../../../shared/models/activity-timeline/activity-timeline.model';
import { FormBusinessComponent } from '../../forms/form-business/form-business.component';
import { FormTrustComponent } from '../../forms/form-trust/form-trust.component';
import { FormProfessionalComponent } from '../../forms/form-professional/form-professional.component';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { ContactStatusCode } from 'src/app/shared/models/business-profile/business/business.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientProfileQuery } from '../../states/client-profile.query';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { EmailDuplicateService } from '@core/services/email-duplicate.service';

@Component({
	selector: 'app-people-entities-tab',
	templateUrl: './people-entities-tab.component.html',
	styleUrls: ['./people-entities-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleEntitiesTabComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
  @Input() addMode: boolean;
  @Input() isLead: boolean;
  @Input() primaryClient: PrimaryClientState;
  @Input() title: ViewDisplayValue[];
  @Input() employment: ViewDisplayValue[];
  @Input() preferredContact: ViewDisplayValue[];
  @Input() relationship: ViewDisplayValue[];
  @Input() leadTypeChoices: ViewDisplayValue[];
  @Input() leadOriginChoices: ViewDisplayValue[];
  @Input() leadGenChoices: ViewDisplayValue[];
  @Input() adviserGenChoices: ViewDisplayValue[];
  @Input() allStaffChoices: ViewDisplayValue[];
  @Input() trustTypes: ViewDisplayValue[];
  @Input() professionalTypes: ViewDisplayValue[];
  @Input() professionalContacts: ViewDisplayValue[];
  @Input() industry: ViewDisplayValue[];

  @ViewChild('peopleEntities') peopleEntities: TabsetComponent;
  @ViewChild('collapsePerson') collapsePerson: CollapseComponent;
  @ViewChild('collapseTrust') collapseTrust: CollapseComponent;

  @ViewChild(FormPersonComponent) formPerson: FormPersonComponent;
  @ViewChildren(FormSecondaryComponent)
  formSecondaries: QueryList<FormSecondaryComponent>;
  @ViewChildren(FormBusinessComponent)
  formBusinesses: QueryList<FormBusinessComponent>;
  @ViewChildren(FormTrustComponent) formTrusts: QueryList<FormTrustComponent>;
  @ViewChildren(FormProfessionalComponent)
  formProfs: QueryList<FormProfessionalComponent>;

  activeTabPeId: string;
  isAddPerson = false;
  isAddTrust = false;
  isAddProfessional = false;
  isAddLinkedContact = false;

  isSavingPrimary = false;
  isEditPrimary = false;

  // TODO: to be removed and to be fetch in states
  tableHeads = [
    { title: 'Created By' },
    { title: 'Created date & Time' },
    { title: 'Details' },
    { title: ' ' },
  ];

  addNewBusiness$ = this.routerService.RouteChange$.pipe(
    map((x) =>
      !!this.primaryClient && this.primaryClient.contactStatus === ContactStatusCode.Lead
        ? x.leadBusinessAdd
        : x.businessAdd
    )
  );

  // Secondary Client
  secondaryClients$: Observable<SecondaryClientState[]> = this.service.secondaryClients$;

  secondaryClient: SecondaryClientState;

  // Secondary Business
  secondaryBusinesses$: Observable<SecondaryBusinessState[]> = this.service
    .secondaryBusinesses$;
  secondaryBusiness: SecondaryBusinessState;

  // Secondary Trust
  secondaryTrusts$: Observable<SecondaryTrustState[]> = this.service
    .secondaryTrusts$;
  secondaryTrust: SecondaryTrustState;

  // Secondary Professional
  secondaryProfessionlContacts$: Observable<SecondaryProfessionalState[]> = this
    .service.secondaryProfessionals$;
  secondaryProfessionalContact: SecondaryProfessionalState;

  sciIsLoading$ = this.service.sciIsLoading$;
  sccIsLoading$ = this.service.sccIsLoading$;
  sctIsLoading$ = this.service.sctIsLoading$;
  scpIsLoading$ = this.service.scpIsLoading$;

  // Linked Contacts
  sclIsLoading$ = this.service.scpIsLoading$;

  tap: Observable<any>; // tap a task in observable

  secondaryClientId: number;
  addPhotoRequest: AddPhotoRequest;
	linkedContacts: LinkedContactState[];
  addPhoto$ = this.service.addPhoto$;
  uploadPhoto$ = (req: AddPhotoRequest) =>
    new Observable((obs) => {
      this.addPhotoRequest = req;
      obs.next();
      obs.complete();
    });

  disableAdd = () =>
    [this.isAddPerson, this.isAddTrust, this.isAddProfessional]?.some((x) => x);

  linkedContactForm: UntypedFormGroup = this.fb.group({
    primaryContact: this.fb.control('', [Validators.required]),
    relationship: this.fb.control('', [Validators.required]),
  });

	// LinkedContacts
	linkedContacts$: Observable<LinkedContactState[]> =
		this.clientQuery.linkedContacts$;

	isSavingLinkedContact: boolean = false;

	get LinkedContact() {
		return this.linkedContactForm.get('primaryContact');
	}

	get LinkedContactExists() {
		const customerId = this.LinkedContact.value?.CustomerId;
		const linkedData = this.linkedContacts?.find((x) => {
			const linkCustomerId = x?.linkedFromPrimaryCustomer
				? +x?.relatedCustomerId
				: +x?.customerId;
			return linkCustomerId === +customerId;
		});
		return !!linkedData;
	}
	businessConfig$ = this.businessConfigQuery.businessConfig$;

  constructor(
    private service: ClientProfileService,
    private routerService: RouteService,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private clientQuery: ClientProfileQuery,
		private businessConfigQuery: BusinessConfigQuery,
    private emailDupService:EmailDuplicateService
  ) { }

  get fullName() {
    return this.primaryClient
      ? `${this.primaryClient.firstName} ${this.primaryClient.lastName}`
      : '';
  }

  ngOnInit(): void {
		this.linkedContacts$.pipe(
			tap((x) => {
				this.isAddLinkedContact = false;
				this.linkedContacts = x;
			}),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.primaryClient && !changes.primaryClient?.currentValue && changes.primaryClient.previousValue) {
			this.resetPeopleTab();
			this.selectPrimary();
    }

  }

  ngAfterViewInit(): void {
    this.selectPrimary();
  }

  trackbyFn(index, customer) {
    return customer.customerID;
  }

  trackbyFnContacts(index, customer) {
    return customer.id;
  }

	resetPeopleTab() {
		this.isAddLinkedContact = false;
		this.isAddPerson = false;
		this.isAddProfessional = false;
		this.isAddTrust = false;
		this.isEditPrimary = false;
		this.isLead = false;
		this.isSavingLinkedContact = false;
		this.isSavingPrimary = false;
		this.isEditPrimary = false;
		this.tap = null;
	}

  addNotes(note: NoteRequest) {
    return this.service.addNote({
      customerID: note.customerID,
      customerServiceID: note.customerServiceID,
      notes: note.notes,
      activityType: note.activityType,
    });
  }

  addNotesPCI$ = (note: NoteRequest) =>
    this.addNotes({ ...note, activityType: CustomerTypes.PrimaryCustomerIndividual });
  addNotesSCI$ = (note: NoteRequest) =>
    this.addNotes({ ...note, activityType: CustomerTypes.SecondaryCustomerIndividual });
  addNotesPCC$ = (note: NoteRequest) =>
    this.addNotes({ ...note, activityType: CustomerTypes.PrimaryCustomerCompany });
  addNotesSCT$ = (note: NoteRequest) =>
    this.addNotes({ ...note, activityType: CustomerTypes.SecondaryCustomerTrust });
  addNotesSCPC$ = (note: NoteRequest) =>
    this.addNotes({ ...note, activityType: CustomerTypes.SecondaryCustomerProfessional });

  deleteNotes$ = (note: NoteState) => {
    return this.service.deactivateNote(note);
  };

  getNotes$ = (req: GetNotes) => {
    return this.service
      .getNotes(req)
      .pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
  };

  ////////////////////////////// Primary Client //////////////////////////////
  selectPrimary() {
    selectTab(this.peopleEntities, 'PrimaryClientTab', this);
  }
  /**
   * Update Primary Client State
   * @param data PrimaryClientSate
   */
  updatePrimaryClient(data) {
    this.formPerson.toggleSaving(true);
    this.formPerson.toggleEdit(false);
    const d = ClientProfilePrimaryMapper.mapToUpsert(data);
    const confirmCb = () => {
      //Add creation service call here to proceed need customer ID
      this.formPerson.toggleSaving(true);
      this.formPerson.toggleEdit(false);
      d['saveAnyway'] = true;
      return this.service
        .updatePrimaryClient(d as PrimaryClientState)
        .pipe(finalize(()=>{this.formPerson.toggleSaving(false); this.formPerson.toggleEdit(false); }));
    };
    this.service
      .updatePrimaryClient(d as PrimaryClientState)
      .pipe(switchMap((res)=>{
        if(res.hasOwnProperty('errorMessage')){
          this.formPerson.toggleEdit(true);
          const errMessage = res['errorMessage'];
          this.emailDupService.displayDuplicateDialog(errMessage, confirmCb);
        }
        return of(res);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe({
        next:(res) => {
					if (!res['errorMessage']) {
						this.formPerson.toggleEdit(false);
					}
        },
        error:(err) => {
          this.formPerson.toggleEdit(true);
          this.formPerson.cancel();
        },
        complete:() => {
          this.formPerson.toggleSaving(false);
        }
      });
  }
  ////////////////////////////// End //////////////////////////////

  ////////////////////////////// Secondary Client //////////////////////////////
  selectSecondary(sci: SecondaryClientState, hasConfirmation: boolean = false) {
    this.tap = new Observable((obs) => {
      this.secondaryClient = sci;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'SecondaryClientTab', this, hasConfirmation);
  }

  selectAddSecondary() {
    this.secondaryClient = undefined;
    this.tap = new Observable((obs) => {
      this.isAddPerson = true;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'AddNewSecondaryTab', this);
  }

  saveSecondaryClient$ = (data: SecondaryClientState) => {
    data['primaryCustomer'] = +this.primaryClient.customerID;
    data['customerType'] = CustomerTypes.SecondaryCustomerIndividual;
    return this.service.addSecondaryClient(data).pipe(mergeMap((id) =>
      !!this.addPhotoRequest && this.addPhotoRequest.Photo && !id['errorMessage']
        ? this.service.addPhoto$(
          {
            ...this.addPhotoRequest,
            CustomerID: +id,
          },
          true
        )
        : of(id)
    ),
    map((x) => x['errorMessage']?x:({
      ...data,
      customerID:
        !!this.addPhotoRequest && this.addPhotoRequest.Photo
          ? x.CustomerID
          : +x,
      photoURL: x && x.PhotoURL ? x.PhotoURL : null,
      fileName: x && x.FileName ? x.FileName : null,
    })),
    tap((x) => {
      if (!x['errorMessage']) {
        this.secondaryClient = undefined;
        this.addPhotoRequest = undefined;
        this.isAddPerson = false;
        this.selectSecondary(x);
      }
    }))
  };

  updateSecondary$ = (data: SecondaryClientState) => {
    return of(data).pipe(
      mergeMap((x) => this.service.updateSecondaryClient(data)),
      tap((x) => {
        if (!x['errorMessage']) {
          this.selectSecondary(data);
        }
      })
    );
  };

  deleteSecondary$ = (data: SecondaryClientState) => {
    return this.service
      .deleteSecondaryClient(data)
      .pipe(tap(() => this.selectPrimary()));
  };

  copySecondary$ = (id: number) => this.service.copySecondaryClient(id)

  cancelAddSecondaryClient(cancelled: boolean) {
    this.tap = new Observable((obs) => {
      this.isAddPerson = !cancelled;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'PrimaryClientTab', this, true);
  }

  transferSCI$ = (data) => this.service.transferSCI(data)

  ////////////////////////////// End //////////////////////////////

  ////////////////////////////// Secondary Business //////////////////////////////
  selectBusiness(business: SecondaryBusinessState) {
    this.tap = new Observable((obs) => {
      this.secondaryClient = undefined
      this.secondaryBusiness = business;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'SecondaryBusinessTab', this);
  }
  ////////////////////////////// End //////////////////////////////

  ////////////////////////////// Trust //////////////////////////////
  selectTrust(sct: SecondaryTrustState, hasConfirmation: boolean = false) {
    this.tap = new Observable((obs) => {
      this.secondaryClient = undefined
      this.secondaryTrust = sct;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'SecondaryTrustTab', this, hasConfirmation);
  }

  selectAddTrust() {
    this.tap = new Observable((obs) => {
      this.secondaryClient = undefined
      this.isAddTrust = true;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'AddNewTrustTab', this);
  }

  addSecondaryTrust$ = (secondaryTrust: SecondaryTrustState) => {
    return this.service
      .addSecondaryTrusts({
        ...secondaryTrust,
        primaryCustomer: +this.primaryClient.customerID,
        customerType: CustomerTypes.SecondaryCustomerTrust,
      })
      .pipe(
        tap((id) => {
          this.secondaryTrust = undefined;
          this.isAddTrust = false;
          if (!!id) {
            this.selectTrust({ ...secondaryTrust, customerID: +id });
          }
        })
      );
  };

  updateSecondaryTrust$ = (secondaryTrust: SecondaryTrustState) => {
    return this.service.updateSecondaryTrust(secondaryTrust).pipe(
      tap((x) => {
        this.selectTrust(secondaryTrust);
      })
    );
  };

  deleteSecondaryTrust$ = (secondaryTrust: SecondaryTrustState) => {
    return this.service.deleteSecondaryTrust(secondaryTrust).pipe(
      tap((x) => {
        this.selectPrimary();
      })
    );
  };

  cancelAddNewTrust(cancelled: boolean) {
    this.tap = new Observable((obs) => {
      this.isAddTrust = !cancelled;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'PrimaryClientTab', this, true);
  }
  ////////////////////////////// End //////////////////////////////

  ////////////////////////////// Secondary Professional Contacts //////////////////////////////
  selectProfessional(
    scp: SecondaryProfessionalState,
    hasConfirmation: boolean = false
  ) {
    this.tap = new Observable((obs) => {
      this.secondaryClient = undefined
      this.secondaryProfessionalContact = scp;
      obs.next();
      obs.complete();
    });
    selectTab(
      this.peopleEntities,
      'SecondaryProfessionalTab',
      this,
      hasConfirmation
    );
  }

  selectAddProfessional() {
    this.tap = new Observable((obs) => {
      this.secondaryClient = undefined
      this.isAddProfessional = true;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'AddNewProfessionalTab', this);
  }

  addSecondaryProfessional$ = (
    secondaryProfessional: SecondaryProfessionalState
  ) => {
    return this.service
      .addSecondaryProfessional({
        ...secondaryProfessional,
        primaryCustomer: +this.primaryClient.customerID,
        customerType: CustomerTypes.SecondaryCustomerProfessional,
      })
      .pipe(
        tap((id) => {
          this.secondaryProfessionalContact = undefined;
          this.isAddProfessional = false;
          if (!!id) {
            this.selectProfessional({
              ...secondaryProfessional,
              customerID: +id,
            });
          }
        })
      );
  };

  updateSecondaryProfessional$ = (
    secondaryProfessional: SecondaryProfessionalState
  ) => {
    return this.service.updateSecondaryProfessional(secondaryProfessional).pipe(
      take(1),
      tap((res) => {
        this.selectProfessional(secondaryProfessional);
      })
    );
  };

  deleteSecondaryProfessional$ = (
    secondaryProfessional: SecondaryProfessionalState
  ) => {
    return this.service.deleteSecondaryProfessional(secondaryProfessional).pipe(
      take(1),
      tap((res) => {
        this.selectPrimary();
      })
    );
  };

  cancelAddProfessional(cancelled) {
    this.tap = new Observable((obs) => {
      this.isAddProfessional = !cancelled;
      obs.next();
      obs.complete();
    });
    selectTab(this.peopleEntities, 'PrimaryClientTab', this, true);
  }

 ////////////////////////////// Linked Contacts //////////////////////////////

  selectLinkedContacts() {
    selectTab(this.peopleEntities, 'LinkedContactsTab', this);
  }

  cancelAddLinkedContact() {
    this.isAddLinkedContact = false;
    this.linkedContactForm.reset();
  }

  saveLinkedContact() {
    this.isSavingLinkedContact = true;
    const value = this.linkedContactForm.value;

    const data = {
      id: 0,
      customerId: this.primaryClient.customerID,
      relatedCustomerId: value.primaryContact.CustomerId,
      type: CustomerTypes.LinkedContact,
      remarks: value.relationship,
      isActive: true
    }

    this.service.updateLinkedContact(data)
      .pipe(take(1))
      .subscribe(() => {
        this.isSavingLinkedContact = false;
        this.cancelAddLinkedContact();
      }),
      (err) => {
        this.isSavingLinkedContact = false;
      };
  }

  ////////////////////////////// End //////////////////////////////

  detectChanges() {
    this.cd.detectChanges();
  }

  confirm(id: string) {
    // Show modal
    const confirm = new Observable((obs) => {
      // If true
      const activeTabPE: TabDirective = this.peopleEntities.tabs?.find(
        (x) => x.id && x.id?.toLowerCase() === id?.toLowerCase()
      );
      if (activeTabPE) {
        this.peopleEntities.tabs?.forEach((x) => (x.active = false));
        activeTabPE.active = true;
        this.activeTabPeId = activeTabPE.id;
        this.cd.detectChanges();
      }

      if (!!this.tap) {
        this.tap.pipe(takeUntil(this.onDestroy$)).subscribe(
          () => { },
          () => { },
          () => {
            this.isAddPerson = false;
            this.isAddTrust = false;
            this.isAddProfessional = false;

            this.cd.detectChanges();
          }
        );
      }

      obs.next();
      obs.complete();
    });

    const initState = {
      header: 'Discard Confirmation',
      message: `Are you sure you want to discard your changes?`,
      confirm$: confirm,
    };

    this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog-centered',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  onTap() {
    this.tap.pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}

/**
 * Set active tab for People / Entities
 * @param id - string id of the tab to be selected
 */
const selectTab = (
	tabset: TabsetComponent,
	id: string,
	comp: PeopleEntitiesTabComponent,
	hasConfirmation: boolean = false
): void => {
  const isAddingTab = [
    'AddNewSecondaryTab',
    'AddNewTrustTab',
    'AddNewProfessionalTab',
  ]?.some((x) => comp.activeTabPeId === x);
  const isAdding = [
    comp.isAddPerson,
    comp.isAddTrust,
    comp.isAddProfessional
  ]?.some((x) => !!x);
  if (hasConfirmation || (!hasConfirmation && isAdding && isAddingTab)) {
    comp.confirm(id);
  } else {
    if (!!comp.tap) {
      comp.onTap();
    }
    const activeTabPE: TabDirective = tabset?.tabs?.find(
      (x) => x.id && x.id?.toUpperCase() === id?.toUpperCase()
    );
    if (activeTabPE) {
      activeTabPE.active = true;
      comp.activeTabPeId = activeTabPE.id;
      comp.detectChanges();
    }
  }
};
