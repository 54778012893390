import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { DropdownValue } from '../../../../modules/dropdown/model/dropdownValue';
import { DropdownValuesService } from '../../../../core/dropdown/dropdown-values.service';
import { HistoryService } from '../states/history/history.service';
import { ActivatedRoute } from '@angular/router';
import { ClientReviewTemplateService } from '../states/client-review-template.service';
import { takeUntil } from 'rxjs/operators';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit, OnDestroy {
	dropdownCodes: DropdownValue[];
	clients = this.query.peopleAndDependentsFromCRTOnly$;

	clientId: string;
	adviceProcessId: string;

	onDestroy$ = new Subject<void>();
	themeConfig$ = this.businessConfigQuery.themeConfig$;

	peoples$ = this.query.peopleFromCRMAndCRTRemoveDoubles$;
	isGpClientsLoading$ = this.query.isGpClientsLoading$;
	medicalLoading$ = this.query.isMedicalHistoryLoading$;
	familyLoading$ = this.query.isFamilyHistoryLoading$;
	apcrtcc$ = this.service.APCRTCC$;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$;

	constructor(
		private businessConfigQuery: BusinessConfigQuery,
		private dropdownValueService: DropdownValuesService,
		private service: ClientReviewTemplateService,
		private historyService: HistoryService,
		private route: ActivatedRoute,
		private query: ClientReviewTemplateQuery
	) {}

	ngOnInit(): void {
		this.clientId = this.route.snapshot.paramMap.get('clientId');
		this.adviceProcessId = this.route.snapshot.paramMap.get('adviceProcessId');

		const dropdownCodes: string[] = ['LRP', 'LRPR', 'APCRTF'];
		this.dropdownValueService
			.getDropdownValueList$(dropdownCodes)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.dropdownCodes = x));
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	// measurement
	addMeasurementFn$ = (req: { measures }) =>
		this.historyService.addBodyMeasures(req.measures);
	updateMeasurementFn$ = (req: { measures }) =>
		this.historyService.updateBodyMeasures(req.measures);
	updateMedicalHistoryFn$ = (req: { measures; history }) =>
		this.historyService.updateMedicalHistoryOptions(req.measures, req.history);

	// medical
	addMedicalFn$ = (req: { medical; adviceProcessId }) =>
		this.historyService.addMedical(req.medical, req.adviceProcessId);
	updateMedicalFn$ = (req: { medical }) =>
		this.historyService.updateMedical(req.medical);

	// family
	addFamilyFn$ = (req: { family; adviceProcessId }) =>
		this.historyService.addFamily(req.family, req.adviceProcessId);
	updateFamilyFn$ = (req: { family }) =>
		this.historyService.updateFamily(req.family);

	// gp
	addGpFn$ = (req: { gp; adviceProcessId; isNew }) =>
		this.historyService.addGp(req.gp, req.adviceProcessId, req.isNew);
	updateGpFn$ = (req: { gp }) => this.historyService.updateGp(req.gp);
}
