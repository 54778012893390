<app-user-details
	[staff]="staff"
	[staffSettings]="staffSettings"
	[secGroups]="secGroups"
	[LRP]="LRP$ | async"
	[SCS]="SCS$ | async"
	[SCT]="SCT$ | async"
	[SAS]="SAS$ | async"
	[SQCKI]="SQCKI$ | async"
	[UPDTP]="UPDTP$ | async"
	[UPDTC]="UPDTC$ | async"
	[UPDTS]="UPDTS$ | async"
	[updateStaffFn$]="updateStaffFn$"
	[onSubmitProvideAccess$]="provideAccess"
	[userSecurityGroup]="userSecurityGroup$ | async"
	[addPhoto$]="addPhoto$"
	[upsertStaffQualificationFn$]="upsertStaffQualificationFn$"
	[updateStaffGoalsFn$]="updateStaffGoalsFn$"
	[upsertStaffDocumentFn$]="upsertStaffDocumentFn$"
	[removeStaffDocumentFn$]="removeStaffDocumentFn$"
	[getUserDocumentsFn$]="getUserDocumentsFn$"
	[deleteUserDocumentsFn$]="deleteUserDocumentsFn$"
	[upsertPdTrackingFn$]="upsertPdTrackingFn$"
	[companyCode]="companyCode"
	[isTapLevel]="isTapLevel"
	(cancelEvent)="cancel()"
>
</app-user-details>
