import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export class errorLog {
  /**
   *
   */
  constructor(
    public errorObject: object,
    public message: string,
    public objects: object[],
    public state?: number // -1: error, 0: warning, 1: success
  ) {}
}
@Injectable()
export class LoggerService {
  private _log$: Subject<errorLog>;
  log$: Observable<errorLog>;
  constructor() {
    this._log$ = new Subject();
    this.log$ = this._log$.asObservable();
  }

  Log(error: object, message: string, ...relatedObjects: object[]) {
    const errorLogObj = new errorLog(error, message, relatedObjects, -1);
    this._log$.next(errorLogObj);
  }

  Warning(error: object, message: string, ...relatedObjects: object[]) {
    const errorLogObj = new errorLog(error, message, relatedObjects, 0);
    this._log$.next(errorLogObj);
  }

  Success(error: object, message: string, ...relatedObjects: object[]) {
    const errorLogObj = new errorLog(error, message, relatedObjects, 1);
    this._log$.next(errorLogObj);
  }

	MultipleWarnings(errors) {
		if (errors?.length > 0) {
			errors?.forEach((err) => {
				this.Warning({}, err);
			});
		}
	}
}
