import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { finalize, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { trustsToViewDisplayValueUtil } from 'src/app/modules/crm/client-review-template/util/mapViewDisplayValue.util';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { PeopleModalComponent } from 'src/app/shared/modal/crt/fact-find/people/people-modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { TrustState } from 'src/app/shared/models/client-review-template/trust/trust.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { PeopleEntitiesQuery } from '../state/people-entities.query';
import { PeopleEntitiesService } from '../state/people-entities.service';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
declare var $: any;

@Component({
  selector: 'app-trust',
  templateUrl: './trust.component.html',
  styleUrls: ['./trust.component.scss']
})
export class TrustComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();

  @Input() addFn$: ({ trust, adviceProcessId }) => Observable<any>;
  @Input() deleteFn$: (id: number) => Observable<any>;
  @Input() addNewFn$: ({ trust, adviceProcessId }) => Observable<any>;
  @Input() updateFn$: (trust: TrustState) => Observable<any>;

  @Input() SCTT$: Observable<ViewDisplayValue[]>;
  @Input() trusts$: Observable<any[]>;
  @Input() isTrustsLoading$: Observable<boolean>;

  elseMinusTrust = true;
  isLoading = false;
  crtLoading: number;
  isAddNew = false;
  isSaving = false;

  trustList$ = new BehaviorSubject<ViewDisplayValue[]>(null);

  bsModalRef: BsModalRef;
  trustInfo = (crtId: number) => this.peopleEntitiesService.getPersonInfo(crtId);
  constructor(
    private cd: ChangeDetectorRef,
    private peopleEntitiesQuery: PeopleEntitiesQuery,
    private peopleEntitiesService: PeopleEntitiesService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
		private MoatQuery: CrtMortgageQuery,
  ) { }

  ngOnInit(): void {
  }

  collapseMoreTrust() {
    $('#collapseTrust').toggle();
    this.elseMinusTrust = false;
  }

  collapseLessTrust() {
    $('#collapseTrust').toggle();
    this.elseMinusTrust = true;
  }

  toggleLoader(cRTId: number, load: boolean) {
    this.crtLoading = (cRTId && load) ? +cRTId : null;
  }

  isCrtLoading(cRTId: number) {
    if (this.isAddNew && !this.isSaving) { return false; }
    if (this.isSaving && cRTId === 0) { return true; }
    return +this.crtLoading === +cRTId;
  }


  addTrust() {
    this.isAddNew = true;
    this.cd.detectChanges();
    this.peopleEntitiesQuery.trustsFromCRMAndCRT$.pipe(
      mergeMap(x => trustsToViewDisplayValueUtil(x)),
      tap(x => this.trustList$.next(x)),
      switchMap(() => this.trusts$.pipe(
        map(x => {
          const newTrust: TrustState = {
            cRTId: 0,
            age: 0,
            birthDate: '',
            customerType: '',
            name: '',
            relationship: '',
            customerId: 0
          };
          x?.push(newTrust);
        })

      )),
      take(1)
    ).subscribe()
  }

  addNewTrust = (model: TrustState) => new Observable<any>((obs) => {
    this.isSaving = true;
    obs.next(model);
    obs.complete();
  }).pipe(
    mergeMap(x => this.addNewFn$({ trust: x, adviceProcessId: parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10) })),
    finalize(() => {
      this.isAddNew = false;
      this.isSaving = false;
      this.cd.detectChanges();
    })
  )

  change(e, i: number) {
    if (e.target.value === 'new') {
      const decline = new Observable((obs: Observer<any>) => {
        (document.getElementById('addNewTrustDropdown_' + i) as HTMLInputElement).value = '';
        obs.complete();
      });
			const moatQueryValue = this.MoatQuery.getValue();
			const peopleAndDependents = [
				...moatQueryValue?.people,
				...moatQueryValue?.dependents,
			];
			const people = moatQueryValue?.people;
      const initState = {
        header: 'Trust Details',
        message: `TrustDetails`,
        sctt$: this.SCTT$,
        savefn: this.addNewTrust,
        decline$: decline,
				peopleAndDependents,
				people,
				location: 'MOAT',
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-md modal-workflow moatV1',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    } else {
      this.peopleEntitiesQuery.trustsFromCRMAndCRT$.pipe(
        map((x) => x?.find(y => y.customerID === +e.target.value)),
        mergeMap((x) => this.trusts$
          .pipe(
            map(p => {
              p[i] = {
                cRTId: 0,
                age: x.dateOfBirth ? moment().diff(x.dateOfBirth, 'years') : null,
                birthDate: x.dateOfBirth,
                customerType: x.customerType,
                name: x.trustName,
                relationship: x.relationship,
                customerId: x.customerID
              }
            })
          )
        ),
        take(1)
      ).subscribe()
    }
  }

  updateTrust = (model: TrustState) => new Observable<any>((obs) => {
    obs.next(model);
    obs.complete();
  }).pipe(
    mergeMap(x => this.updateFn$(x)),
    finalize(() => {
      this.isAddNew = false;
      this.toggleLoader(+model?.cRTId, false);
      this.cd.detectChanges();
    })
  )


  editTrust(crtId: number) {
    this.toggleLoader(+crtId, true);
    const decline = new Observable((obs: Observer<any>) => {
      this.toggleLoader(crtId, false);
      obs.complete();
    });
		const moatQueryValue = this.MoatQuery.getValue();
		const peopleAndDependents = [
			...moatQueryValue?.people,
			...moatQueryValue?.dependents,
		];
		const people = moatQueryValue?.people;
    this.trustInfo(crtId).subscribe((x) => {
      const initState = {
        header: 'Trust Details',
        message: `TrustDetails`,
        sctt$: this.SCTT$,
        trustInfo: x,
        savefn: this.updateTrust,
        decline$: decline,
				peopleAndDependents,
				people,
				location: 'MOAT',
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-md modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    })
  }

  deleteTrust(id: number) {
    this.toggleLoader(id, true);

    const confirm = new Observable((obs) => {
      this.deleteItem(id);
      obs.next();
      obs.complete();
    });

    const decline = new Observable((obs: Observer<any>) => {
      this.toggleLoader(id, false);
      obs.complete();
    });

    const initState = {
      header: 'Delete Trust',
      message: logMessage.oat.shared.factFind.delete,
      delete$: confirm,
      decline$: decline,
      confirmButton: 'OK',
      detachCloseIcon: false
    };
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-dialog-centered modal-dialog',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  deleteItem = (id: number) =>
    this.deleteFn$(id)
      .pipe(
        mergeMap(() => this.updateDependantDd()),
        finalize(() => (this.isLoading = false)),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe((x) => {
        this.toggleLoader(id, false);
      });

  addExistingTrust(p: TrustState) {
    this.isSaving = true;
    this.peopleEntitiesQuery.trustsFromCRMAndCRTNoFilter$.pipe(
      map((x) => x?.find(c => c.customerID === +p.customerId)),
      map(client => {
        const adviceProcessId = parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10);
        const trust = {
          customerID: client.customerID,
          adviceProcessId,
          sectionCode: 'FPT',
          trustName: client.trustName,
          trustType: client.trustType,
          trustee: client.trustee || client.fullTrustName,
          independentTrustName: client.independentTrustName,
          tradingName: client.tradingName
        }
        return { trust, adviceProcessId }
      }),
      take(1),
      mergeMap(res => this.addFn$({ trust: res.trust, adviceProcessId: res.adviceProcessId })),
      tap(() => {
        this.isAddNew = false;
        this.isSaving = false;
        this.cd.detectChanges();
      })
    ).subscribe()
  }

  removeTrust(index: number) {
    this.trusts$.pipe(
      map(x =>
        x?.splice(index, 1)
      ),
      take(1)
    ).subscribe(() => {
      this.isAddNew = false;
      this.cd.detectChanges();
    })
  }

  updateDependantDd = () =>
    this.peopleEntitiesQuery.trustsFromCRMAndCRT$.pipe(
      mergeMap((x) => trustsToViewDisplayValueUtil(x)),
      tap((x) => this.trustList$.next(x)),
      take(1)
    );

  ngOnDestroy() {
    this.trusts$
			.pipe(
				map((x) => {
					if (x?.some((p) => p?.cRTId === 0 || !p?.cRTId)) {
						x?.splice(
							x.findIndex((p) => p?.cRTId === 0 || !p.cRTId),
							1
						);

						return x;
					}
					return x;
				}),
        take(1)
			)
			.subscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
