<form [formGroup]="form" novalidate>
	<div class="form-row mb-2 mortgage-label-background">
		<div class="col-7 col-sm-3 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary"
				>Mortgages</label
			>
		</div>
		<div class="col-5 col-sm-9 text-right px-0 iconShow">
			<button
				id="mortgage_collapseButton"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 mortgage-collapse-background"
			>
				<span *ngIf="elseMinusMortgages; else elsePlusMortgages">
					<i (click)="collapseMoreMortgages()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusMortgages>
					<span>
						<i
							(click)="collapseLessMortgages()"
							class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div class="collapse expandable" id="collapseMortgages">
		<div
			class="form-row d-none d-lg-flex mb-2 font-weight-bold"
			[class.tap-opacity-25]="(mortgages$ | async)?.length === 0"
		>
			<div class="col-12 col-lg-3">Lender</div>
			<div class="col-12 col-lg-3">Interest Rate</div>
			<div class="col-12 col-lg-3">Fixed Until</div>
			<div class="col-12 col-lg-3">Loan Value</div>
		</div>
		<div
			class="form-row align-items-center mb-2"
			*ngFor="let m of mortgages$ | async; let i = index; let last = last"
			[class.ng-invalid]="(!m.mortgageFrequency && m.cRTId) || (!m.loanType && m.cRTId)"
		>
			<div class="d-md-none col-lg-1 text-right">
				<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
					<ng-container *ngIf="m.cRTId">
						<ng-container *ngIf="editIndex !== i">
							<button
								id="mortgage_editMortgageButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="isEditing || !!deletingId"
								(click)="editMortgage(m.cRTId, i)"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
							<button
								id="mortgage_deleteModalButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="isEditing || !!deletingId"
								(click)="deleteModal(m.cRTId)"
							>
								<i class="material-icons md-16"> delete </i>
							</button>
						</ng-container>
						<ng-container *ngIf="editIndex === i">
							<app-manual-loader-mini
								[isLoading]="isEditing"
							></app-manual-loader-mini>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!m.cRTId">
						<ng-container *ngIf="!isAdding">
							<button
								id="mortgage_saveMortgageButton_{{ i }}"
								color="primary"
								[disabled]="!m.lender"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="saveMortgage(m)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
							<button
								id="mortgage_cancelAddButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="cancelAdd(i)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-template #viewOnlySmall>
					<button
						id="mortgage_viewMortgageButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1 p-0"
						[disabled]="isEditing || !!deletingId"
						(click)="viewMortgage(m.cRTId, i)"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-template>
			</div>
			<div class="col-lg-3" [class.border-bottom]="m.cRTId && !last">
				<ng-container *ngIf="!!m.cRTId">
					<span class="w-100">
						<select
							id="mortgageLender"
							class="form-control crt-form-control theme-crm-field-disabled"
							[disabled]="true"
						>
							<option
								value=""
								selected
								disabled
								hidden
								class="text-muted"
							></option>
							<option
								*ngFor="let d of mp$ | async"
								[value]="d.value"
								[selected]="m.lender === d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</ng-container>
				<ng-container *ngIf="!m.cRTId">
					<span class="w-100" [class.crt-custom-dropdown]="!selectedProvider">
						<select
							id="mortgageSelectFromCrm"
							class="form-control crt-form-control theme-crm-field-disabled"
							[disabled]="m.lender"
							(change)="onSelectMortgage($event.target.value, i)"
							[class.select-text-muted]="!selectedProvider"
						>
							<option
								value=""
								selected
								disabled
								hidden
								class="select-text-muted"
							>
								Select Lender
							</option>
							<option
								*ngFor="let d of crmMortgagesChoices$ | async"
								[value]="d.value"
								[selected]="+d.value === selectedProvider"
								class="select-text-option"
							>
								{{ d.display }}
							</option>
							<option value="new" class="select-text-option">
								+ Add new Mortgage
							</option>
						</select>
					</span>
				</ng-container>
			</div>
			<div class="col-lg-3" [class.border-bottom]="m.cRTId && !last">
				<ng-container *ngIf="!!m.cRTId">
					<input
						type="text"
						id="mortgageInterestRate"
						class="form-control crt-form-control"
						placeholder="Interest Rate"
						[value]="+m.interestRate + '%'"
						[disabled]="true"
					/>
				</ng-container>
			</div>
			<div class="col-lg-3" [class.border-bottom]="m.cRTId && !last">
				<ng-container *ngIf="!!m.cRTId">
					<input
						type="text"
						id="mortgageFixedUntil"
						class="form-control crt-form-control"
						placeholder="Fixed Until"
						[value]="m.fixedUntil | momentDatetime"
						[disabled]="true"
					/>
				</ng-container>
			</div>
			<div class="col-lg-2" [class.border-bottom]="m.cRTId && !last">
				<ng-container *ngIf="!!m.cRTId">
					<div class="dollar-icon">
						<input
							type="text"
							id="mortgageValue"
							class="form-control crt-form-control"
							placeholder="Loan Value"
							[disabled]="true"
							[(ngModel)]="m.loanValue"
							[ngModelOptions]="{ standalone: true }"
							currencyMask
						/>
						<i>$</i>
					</div>
				</ng-container>
			</div>
			<div
				class="d-none d-md-block col-lg-1 text-right pt-2"
				[class.border-bottom]="m.cRTId && !last"
			>
				<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
					<ng-container *ngIf="m.cRTId">
						<ng-container *ngIf="editIndex !== i">
							<button
								id="mortgage_editMortgageButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="isEditing || !!deletingId"
								(click)="editMortgage(m.cRTId, i)"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
							<button
								id="mortgage_deleteModalButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="isEditing || !!deletingId"
								(click)="deleteModal(m.cRTId)"
							>
								<i class="material-icons md-16"> delete </i>
							</button>
						</ng-container>
						<ng-container *ngIf="editIndex === i">
							<app-manual-loader-mini
								[isLoading]="isEditing"
							></app-manual-loader-mini>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!m.cRTId">
						<ng-container *ngIf="!isAdding">
							<button
								id="mortgage_saveMortgageButton_{{ i }}"
								color="primary"
								[disabled]="!m.lender"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="saveMortgage(m)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
							<button
								id="mortgage_cancelAddButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="cancelAdd(i)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>
						<ng-container *ngIf="isAdding">
							<app-manual-loader-mini
								[isLoading]="isAdding"
							></app-manual-loader-mini>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-template #viewOnly>
					<button
						id="mortgage_viewMortgageButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1 p-0"
						[disabled]="isEditing || !!deletingId"
						(click)="viewMortgage(m.cRTId, i)"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-template>
			</div>
		</div>

		<div class="form-row my-4">
			<div class="col-5 col-lg-3">
				<ng-container *ngIf="isLoading$ | async as loading">
					<app-manual-loader-mini
						[isLoading]="loading"
					></app-manual-loader-mini>
				</ng-container>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="mortgage_addMortgageButton"
						*ngIf="!(isLoading$ | async)"
						type="button"
						(click)="addMortgage()"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
						[disabled]="isAddNew"
					>
						Add Mortgage +
					</button>
				</ng-container>
			</div>
			<div class="offset-lg-1 col-sm-6 col-lg-5"></div>
		</div>
		<div class="border-bottom mb-3"></div>
	</div>
</form>
