const introText = `<label class="font-weight-bold tap-text-primary">Intro:</label>
<p>I/we the client would like to engage %BUSINESS_FAP_NAME% to review and provide advice for the following services:</p>`;

const willText = `<label class="font-weight-bold tap-text-primary">Intro:</label>
<p>I/we the client would like to engage %BUSINESS_FAP_NAME% to review and provide advice for the following services:</p>`;

const wontText = `<label class="font-weight-bold tap-text-primary">Intro:</label>
<p>I/we the client would like to engage %BUSINESS_FAP_NAME% to review and provide advice for the following services:</p>`;

const introHeader = `<p>%BUSINESS_LOGO_OAT%</p>
<p><br></p>
<h2 class="tap-text-primary">Scope of Service</h2><br>
${introText}`;

export const sosTemplate = {
	introHeader,
	sosWill: willText,
	sosWont: wontText
};
