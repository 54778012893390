<form [formGroup]="form" [class.submitted]="submitted" novalidate>
	<div class="form-row mb-2 assets-label-background">
		<div class="col-7 col-sm-3 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary">
				Property
			</label>
		</div>
		<div class="col-5 col-sm-9 text-right iconShow">
			<button
				id="property_collapseButton"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 assets-collapse-background"
			>
				<span *ngIf="elseMinusProperty; else elsePlusProperty">
					<i (click)="collapseMoreProperty()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusProperty>
					<span>
						<i
							(click)="collapseLessProperty()"
							class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div class="collapse expandable" id="collapseProperty">
		<div
			class="form-row d-none d-lg-flex mb-2"
			[class.tap-opacity-25]="PropertyFormList?.controls?.length === 0"
		>
			<div class="col-12 col-lg-4 font-weight-bold">Property Address</div>
			<div class="col-12 col-lg-3 font-weight-bold">Owner(s)</div>
			<div class="col-12 col-lg-2 font-weight-bold">Property Use</div>
			<div class="col-12 col-lg-2 font-weight-bold">Property Value</div>
		</div>
		<div formArrayName="properties">
			<div
				(mouseenter)="isEllipsisActive(p.value)"
				class="form-row align-items-center mb-2"
				*ngFor="
					let p of PropertyFormList.controls;
					let i = index;
					let last = last;
					trackBy: trackByFn
				"
				[formGroupName]="i"
				[class.invalid-row]="
					p.get('propertyUse')?.value !== propertyUse.OwnerOccupied &&
					!!p.get('cRTId')?.value &&
					!!p.get('rentalIncome')?.value &&
					!p.get('rentalFrequency')?.value
				"
				[class.border-bottom]="
					!p.value.isNew && !p.get('isEdit')?.value && !last
				"
			>
				<div class="d-md-none col-lg-auto text-right">
					<ng-container *ngIf="!p.value.isLoading">
						<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
							<ng-container *ngIf="!p.get('isEdit')?.value">
								<button
									id="property_editPropertyButton_new_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing"
									(click)="editProperty(p.get('cRTId')?.value, i)"
								>
									<i class="material-icons md-16">edit</i>
								</button>
								<button
									id="property_handleDeleteButton_new_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing"
									(click)="handleDelete(i)"
								>
									<i class="material-icons md-16">delete</i>
								</button>
							</ng-container>
							<ng-container *ngIf="!!p.get('isEdit')?.value">
								<button
									id="property_savePropertyButton_new_{{ i }}"
									color="primary"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="saveProperty(i)"
								>
									<i class="material-icons md-16">save</i>
								</button>
								<button
									id="property_removePropertyButton_new_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="removeProperty(i)"
								>
									<i class="material-icons md-16">close</i>
								</button>
							</ng-container>
						</ng-container>
						<ng-template #viewOnlySmall>
								<button
								id="property_viewPropertyButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="p.value.isLoading"
								(click)="viewProperty(p.get('cRTId')?.value, i)"
							>
								<i class="material-icons md-16">info</i>
							</button>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="p.value.isLoading">
						<app-manual-loader-mini
							class="md-14"
							[isLoading]="p.value.isLoading"
						></app-manual-loader-mini>
					</ng-container>
				</div>
				<div class="col-12 col-lg-4">
					<ng-container *ngIf="!p.value.isNew">
						<app-address-input
							inputId="property_propertyAddress_{{ i }}"
							[attr.disabled]="p.get('propertyAddress').disabled"
							class="crt-form-control-field"
							formControlName="propertyAddress"
							[textboxClass]="'theme-crm-field-disabled'"
							[tooltip]="p.value.propertyAddress"
						></app-address-input>
					</ng-container>
					<ng-container *ngIf="p.value.isNew">
						<span class="crt-custom-dropdown w-100">
							<select
								id="property_propertyAddress_{{ i }}"
								formControlName="propertyAddress"
								(change)="onSelectProperty($event.target.value, i)"
								id="propertyAddressAdd"
								placeholder="Property Address"
								class="form-control crt-form-control"
							>
								<option
									*ngFor="let d of propertyAdressChoices$ | async"
									[value]="d.value"
									[selected]="p.get('propertyAddress').value === d.display"
								>
									{{ d.display }}
								</option>
								<option value="new">+ Add New Property</option>
							</select>
						</span>
					</ng-container>
				</div>
				<div class="col-lg-3">
					<ng-container *ngIf="!p.value.isNew">
						<app-chips
							textboxId="propery_owner_tb_{{ i }}"
							id="propery_owner_{{ i }}"
							[attr.disabled]="p.get('owner')?.disabled"
							class="crt-form-control-field"
							[formControl]="p.get('owner')"
							textboxClass="theme-crm-field"
							[isRemoveChipsPadding]="p.get('owner')?.disabled"
							[choices]="policyOwnerChoices(p.get('owner').value)"
						></app-chips>
					</ng-container>
				</div>
				<div class="col-lg-2">
					<ng-container *ngIf="!p.value.isNew">
						<span class="w-100" [class.crt-custom-dropdown]="p.value.isNew">
							<select
								id="property_propertyUse_{{ i }}"
								formControlName="propertyUse"
								id="propertyUse"
								class="form-control theme-crm-field-disabled crt-form-control"
							>
								<option value=""></option>
								<option *ngFor="let d of su$ | async" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</ng-container>
				</div>
				<div class="col-lg-2">
					<ng-container *ngIf="!p.value.isNew">
						<div class="dollar-icon">
							<input
								id="property_propertyValue_{{ i }}"
								formControlName="propertyValue"
								type="text"
								id="propertyValue"
								class="form-control crt-form-control"
								placeholder="Property Value"
								currencyMask
								appCurrencyMaskExt
							/>
							<i class="d-flex">$</i>
						</div>
					</ng-container>
				</div>

				<div class="d-none d-md-block col-lg-1 text-right">
					<ng-container *ngIf="!p.value.isLoading">
						<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
							<ng-container *ngIf="!p.get('isEdit')?.value">
								<button
									id="property_editPropertyButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing"
									(click)="editProperty(p.get('cRTId')?.value, i)"
								>
									<i class="material-icons md-16">edit</i>
								</button>
								<button
									id="property_handleDeleteButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="isEditing"
									(click)="handleDelete(i)"
								>
									<i class="material-icons md-16">delete</i>
								</button>
							</ng-container>
							<ng-container *ngIf="!!p.get('isEdit')?.value">
								<button
									id="property_savePropertyButton_{{ i }}"
									color="primary"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									[disabled]="
										!p.get('propertyAddress').value ||
										!p.get('owner')?.value ||
										p.get('owner')?.value?.length === 0 ||
										!p.get('propertyValue').value
									"
									(click)="saveProperty(i)"
								>
									<i class="material-icons md-16"> save </i>
								</button>
								<button
									id="property_removePropertyButton_{{ i }}"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="removeProperty(i)"
								>
									<i class="material-icons md-16">close</i>
								</button>
							</ng-container>
						</ng-container>
						<ng-template #viewOnly>
							<button
								id="property_viewPropertyButton_{{ i }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="isEditing"
								(click)="viewProperty(p.get('cRTId')?.value, i)"
							>
								<i class="material-icons md-16">info</i>
							</button>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="p.value.isLoading">
						<app-manual-loader-mini
							[isLoading]="p.value.isLoading"
						></app-manual-loader-mini>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="form-row my-4">
			<div class="col-5 col-lg-3 align-items-center">
				<ng-container *ngIf="isLoading$ | async as loading">
					<app-manual-loader-mini
						[isLoading]="loading"
					></app-manual-loader-mini>
				</ng-container>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="property_addPropertyButton"
						*ngIf="!(isLoading$ | async)"
						type="button"
						(click)="addProperty()"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
						[disabled]="isAddNew"
					>
						Add Property +
					</button>
				</ng-container>
				<ng-container *ngIf="isLoading">
					<app-manual-loader-mini
						[isLoading]="isLoading"
					></app-manual-loader-mini>
				</ng-container>
			</div>
			<div class="offset-lg-5 col-lg-3">
				<div class="form-row mt-4 mt-lg-2 font-weight-bold">
					<div class="col-auto col-lg-4 text-left text-lg-right">
						<span class="font-weight-bold tap-text-primary">Total:</span>
					</div>
					<div class="col-auto col-lg-3">
						<span class="">{{
							totalPropertyValues$ | async | currency: "USD":"$":"1.2-2"
						}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="border-bottom mb-3"></div>
	</div>
</form>
