<div class="title py-3 pl-4 text-white">
	<span> Confirmation Call </span>
	<button
		type="button"
		aria-label="Close"
		class="close mr-4 text-white border"
		(click)="cancel()"
	>
		<span class="material-icons md-16">close</span>
	</button>
</div>
<form [formGroup]="formGroup" class="px-5 py-3">
	<div class="row m-0 mb-4">
		<div class="col-12 col-lg-6 pl-0">
			<label for="" class="font-weight-bold w-100 mb-1">Completed by:</label>
			<app-input-select
				formControlName="completedBy"
				appearance="crt"
				[items]="businessAdmins$ | async"
				valueProperty="StaffID"
				displayProperty="FullName"
				placeholder="Select"
				(valueChange)="onCompletedByValueChanged($event)"
			>
			</app-input-select>
		</div>
		<div class="col-12 col-lg-6 pr-0">
			<label for="" class="font-weight-bold w-100 mb-1">Spoke to:</label>
			<app-input-select
				formControlName="spokeTo"
				appearance="crt"
				[items$]="people$"
				valueProperty="customerId"
				displayProperty="name"
				placeholder="Select"
				(valueChange)="onSpokeToValueChanged($event)"
			></app-input-select>
		</div>
	</div>

	<div class="row m-0 mb-4">
		<div class="col-12 p-0">
			<label for="" class="font-weight-bold w-100 mb-1">Important Documents:</label>
		</div>
		<div class="row col-12 p-0">
			<div class="col-4">
				<span class="col-md-4">Final Structure </span>
				<button class="tap-btn tap-btn--primary text-light text-nowrap" (click)="viewDocument(documents.finalStructure)" *ngIf="!viewMode">View</button>
			</div>
			<div class="col-8" *ngIf="documents?.offerOfTerms.length > 0">
				<div class="row">
					<span class="pl-3 pr-3">Offer of Terms </span>
					<div class="col-md-8 pl-0">
						<button *ngFor="let d of documents?.offerOfTerms"
								(click)="viewDocument(d)"
								class="tap-btn tap-btn--primary text-light text-nowrap mr-2 mb-2" >
								{{documents?.offerOfTerms.length > 1 ? 'View ' + (d.provider || '') : 'View'}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row m-0">
		<div class="col-12 p-0">
			<p class="pb-0 mb-0">
				Hi, is this {{ client?.firstName }} {{ client?.lastName }}?
			</p>
			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
			<p class="pt-0 mt-0">
				Hi {{ client?.firstName }} {{ client?.lastName }} , it's
				{{ staff?.FirstName }} {{ staff?.LastName }} here calling on behalf of
				{{ adviser?.FirstName }} about your insurance with
				{{ formattedProviders }}, how are you today? That's good.
			</p>
			<p class="pt-2">
				This is just a quick call to welcome you as a client, and make sure that
				we have your correct details on file before issuing your policy, okay?
				Great.
			</p>
			<p class="pt-2 mb-0 pb-0">
				Just before we start can you please confirm your full name, postal
				address and email?
			</p>
			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
			<div class="row m-0 p-0">
				<div class="col-2 pl-0 d-flex align-items-center">
					<span> Client Name </span>
				</div>
				<div class="col-10 m-0 px-0">
					<div class="row m-0 p-0">
						<div class="col-4 px-0 d-flex">
							<app-input
								appearance="crt"
								formControlName="clientFirstName"
							></app-input>
							<div class="field-separator"></div>
							<!-- <div class="readonly-field readonly-field-separator"> -->
							<!-- 	<span> {{ client?.firstName }} </span> -->
							<!-- </div> -->
						</div>
						<div class="col-4 px-0 d-flex">
							<app-input
								appearance="crt"
								formControlName="clientLastName"
							></app-input>
							<div class="field-separator"></div>
							<!-- <div class="readonly-field readonly-field-separator"> -->
							<!-- 	<span> {{ client?.middleName }} </span> -->
							<!-- </div> -->
						</div>
						<div class="col-4 px-0">
							<app-input
								appearance="crt"
								formControlName="clientSurname"
							></app-input>
							<!-- <div class="readonly-field"> -->
							<!-- 	<span> {{ client?.lastName }} </span> -->
							<!-- </div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
			<div class="row m-0 p-0">
				<div class="col-2 pl-0 d-flex align-items-center">
					<span> Address </span>
				</div>
				<div class="col-10 px-0">
					<app-input appearance="crt" formControlName="address"></app-input>
					<!-- <div class="readonly-field"> -->
					<!-- 	<span> {{ client?.physicalAddress }} </span> -->
					<!-- </div> -->
				</div>
			</div>
			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
			<div class="row m-0 p-0">
				<div class="col-2 pl-0 d-flex align-items-center">
					<span> Email Address </span>
				</div>
				<div class="col-10 px-0">
					<app-input
						appearance="crt"
						formControlName="emailAddress"
					></app-input>
					<!-- <div class="readonly-field"> -->
					<!-- 	<span> {{ client?.email }} </span> -->
					<!-- </div> -->
				</div>
			</div>

			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>

			<div class="questions-container" formArrayName="questionAnswerList">
				<ng-container
					*ngFor="
						let question of questionAnswerList.controls;
						let index = index;
						let last = last
					"
					[formGroupName]="index"
				>
					<ng-container [ngSwitch]="question.value.qId">
						<ng-container *ngSwitchCase="MEDICAL_QUESTION_QID">
							<ng-container *ngIf="showLifeAssuredQuestion">
								<div class="row m-0 px-0 question-container">
									<div class="row m-0 p-0 w-100">
										<p class="" [innerHTML]="question.value.question"></p>
									</div>
									<div class="row m-0 p-0 w-100">
										<app-input-radio-group
											[name]="index"
											formControlName="answer"
											[items]="questionsChoices"
											displayProperty="text"
											valueProperty="value"
											[radioHeight]="16"
											[radioWidth]="16"
											[radioGap]="35"
											[disabled]="viewMode"
										>
										</app-input-radio-group>
									</div>
								</div>
								<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
							</ng-container>
						</ng-container>

						<ng-container *ngSwitchCase="CLIENT_ACCEPTANCE_QID">
							<ng-container *ngIf="showAuthorityToProceedQuestion">
								<div class="row m-0 px-0 question-container">
									<div class="row m-0 p-0 w-100">
										<p class="" [innerHTML]="question.value.question"></p>
									</div>
									<div class="row m-0 p-0 w-100">
										<app-input-radio-group
											[name]="index"
											formControlName="answer"
											[items]="questionsChoices"
											displayProperty="text"
											valueProperty="value"
											[radioHeight]="16"
											[radioWidth]="16"
											[radioGap]="35"
											[disabled]="viewMode"
										>
										</app-input-radio-group>
									</div>
								</div>
								<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
							</ng-container>
						</ng-container>

						<ng-container *ngSwitchCase="TERMS_QUESTION_QID">
							<ng-container *ngIf="showTermsQuestion">
								<div class="row m-0 px-0 question-container">
									<div class="row m-0 p-0 w-100">
										<p class="" [innerHTML]="question.value.question"></p>
									</div>
									<div class="row m-0 p-0 w-100">
										<app-input-radio-group
											[name]="index"
											formControlName="answer"
											[items]="questionsChoices"
											displayProperty="text"
											valueProperty="value"
											[radioHeight]="16"
											[radioWidth]="16"
											[radioGap]="35"
											[disabled]="viewMode"
										>
										</app-input-radio-group>
									</div>
								</div>
								<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
							</ng-container>
						</ng-container>

						<ng-container *ngSwitchDefault>
							<div class="row m-0 px-0 question-container">
								<div class="row m-0 p-0 w-100">
									<p class="" [innerHTML]="question.value.question"></p>
								</div>
								<div class="row m-0 p-0 w-100">
									<app-input-radio-group
										[name]="index"
										formControlName="answer"
										[items]="questionsChoices"
										displayProperty="text"
										valueProperty="value"
										[radioHeight]="16"
										[radioWidth]="16"
										[radioGap]="35"
										[disabled]="viewMode"
									>
									</app-input-radio-group>
								</div>
							</div>
							<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>

			<p class="mb-0">
				That all seems great. Your policy documents should be sent out by your
				provider(s) shortly, so you can expect those in the mail over the next
				week or so.
			</p>
			<p class="mb-2">Your Policy(ies) will cover you from:</p>

			<div class="providers m-0 p-0 w-100" formArrayName="providerSection">
				<div class="providers-container"					
					*ngFor="
						let provider of providerSection.controls; 
						let index = index;
						let last = last"
					[formGroupName]="index"
				>
					<div class="row m-0">
						<label for="" class="w-100 font-weight-bold">{{
							provider.value.provider
						}}</label>
					</div>
					<hr class="m-0 p-0" />
					<div class="row m-0">
						<div class="col-12 col-lg-4 px-0">
							<label class="font-weight-bold w-100 border-bottom pb-2 w-100"
								>Start Date</label
							>
							<app-input-date formControlName="startDate"></app-input-date>
						</div>
						<div class="col-12 col-lg-4 pr-0">
							<label class="font-weight-bold w-100 border-bottom pb-2 w-100"
								>First Payment Date</label
							>
							<app-input-date formControlName="firstPaymentDate"></app-input-date>
						</div>
						<div class="col-12 col-lg-4 pr-0">
							<label class="font-weight-bold w-100 border-bottom pb-2 w-100"
								>Frequency</label
							>
							<app-input-select
								appearance="crt"
								[items]="APCRTF$ | async"
								valueProperty="value"
								displayProperty="display"
								formControlName="frequency"
								inputClassname="h-35px"
							>
							</app-input-select>
						</div>
						<!-- <div class="col-4 px-0">
							<span>Frequency:</span>
							<span class="font-weight-bold ml-1">{{
								conCallData.frequency
							}}</span>
						</div> -->
					</div>
				</div>
			</div>
			<!-- <div class="row mx-0 mt-4">
				<div class="col-12 pl-0">
					<label class="font-weight-bold">Frequency</label>
				</div>
				<div class="col-12 mt-3 pl-0">
					<app-input-select
						appearance="crt"
						[items]="APCRTF$ | async"
						valueProperty="value"
						displayProperty="display"
						formControlName="frequency"
					>
					</app-input-select>
				</div>
			</div> -->

			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
			
			<p class="paragraph-br pt-2 mb-1 mt-1 pb-2" [innerHTML]="finalSectionValue | formatLinebreak"></p>

			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>

			<p>Call Outcome</p>

			<app-input-radio-group
				formControlName="callOutcome"
				name="success"
				[items]="successChoices"
				displayProperty="text"
				valueProperty="value"
				[radioGap]="35"
				[radioHeight]="16"
				[radioWidth]="16"
				[disabled]="viewMode"
			>
			</app-input-radio-group>
			<div class="col-12 py-3 m-0 px-0"><hr class="m-0 p-0" /></div>
			<div class="p-0 m-0">
				<textarea
					formControlName="notes"
					class="crt-form-control form-control"
					cols="30"
					rows="5"
				></textarea>
			</div>

			<div *ngIf="!viewMode; else viewModeButtons"
				class="d-flex justify-content-end mt-2 confirmation-call-complete-buttons-bottom"
			>
				<app-loader-mini2 [isLoading]="isSaving" [height]="20" [width]="20">
					<button
						class="btn btn-primary tap-bg-primary text-light text-nowrap"
						[class.mr-2]="!!emailSettings?.isEnableEmailOption"
						(click)="saveAndExit()"
					>
						Save & Exit
					</button>
					<ng-container *ngIf="!!emailSettings?.isEnableEmailOption">
						<button
							[disabled]="UNSUCCESS_CALLOUTCOME_ANSWER === callOutcome.value"
							class="btn btn-primary bg-light text-dark border text-nowrap"
							(click)="saveAndSendIssue()"
						>
							Save & Send to Issue
						</button>
					</ng-container>
				</app-loader-mini2>
			</div>
			<ng-template #viewModeButtons>
				<div 
				class="d-flex justify-content-end mt-2 confirmation-call-complete-buttons-bottom"
				>
				<button
							class="btn btn-primary bg-light text-dark border text-nowrap"
						[class.mr-2]="!!emailSettings?.isEnableEmailOption"
						(click)="cancel()"
					>
						Close
					</button>
				</div>
			</ng-template>
		</div>
	</div>
</form>
