<div class="row m-0">
	<div class="row w-100">
		<div class="col">
			<div class="table-container pt-3">
				<table
					mat-table
					[dataSource]="dataSource"
					class="w-100"
					[ngClass]="{ loading: dataSource.isLoading$ | async }"
				>
					<ng-container matColumnDef="templateName">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="header-title pl-0 font-weight-normal text-truncate px-2"
						>
							<span class="font-weight-bold"> Template Name </span>
						</th>
						<td
							mat-cell
							*matCellDef="let element"
							class="pl-0 text-truncate px-2"
							#titleCell
						>
							<span #titleContainer class="position-relative">
								<span class="title-tooltip-wrapper position-absolute"></span>
								{{ element.templateName }}
							</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="emailTemplateSettingsType">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="font-weight-normal text-truncate px-2"
						>
							<span class="font-weight-bold"> Type </span>
						</th>
						<td mat-cell *matCellDef="let element" class="text-truncate col-type px-2">
							<span 
								[tooltip]="typeToolTip"
								[adaptivePosition]="false"
								placement="top">
								{{ convertServiceCodesToLabels(element.emailTemplateSettingsType) }}
							</span>
							<ng-template #typeToolTip>
								<div [innerHTML]="convertServiceCodesToLabels(element.emailTemplateSettingsType)"></div>
							</ng-template>
						</td>
					</ng-container>

					<ng-container matColumnDef="createdDateTime">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="font-weight-normal text-truncate px-2"
						>
							<span class="font-weight-bold"> Created Date </span>
						</th>
						<td mat-cell *matCellDef="let element" class="text-truncate px-2">
							{{ element.createdDateTime }}
						</td>
					</ng-container>

					<ng-container matColumnDef="modifiedDateTime">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="font-weight-normal text-truncate px-2"
						>
							<span class="font-weight-bold"> Last Edited </span>
						</th>
						<td mat-cell *matCellDef="let element" class="text-truncate px-2">
							{{ element.modifiedDateTime }}
						</td>
					</ng-container>

					<ng-container matColumnDef="action">
						<th
							mat-header-cell
							style="width: 100px"
							*matHeaderCellDef
							class="font-weight-normal text-truncate px-2"
						></th>
						<td mat-cell *matCellDef="let element" class="px-2">
							<u
								class="text-truncate action-button"
								role="button"
								(click)="edit(element)"
								[ngClass]="{ 'loading': element.isLoading }"
							>
								Edit Content
							</u>
						</td>
					</ng-container>

					<ng-container matColumnDef="delete">
						<th
							mat-header-cell
							*matHeaderCellDef
							style="width: 100px"
							class="font-weight-normal text-truncate px-2"
						></th>
						<td mat-cell *matCellDef="let element" class="px-2">
							<u
								class="text-truncate action-button"
								role="button"
								(click)="delete(element)"
								[ngClass]="{ 'loading': element.isLoading }"
							>
								Delete
							</u>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>
				<div class="row m-x p-0 m-3">
					<div class="col-6 p-0 m-0 d-flex align-items-center">
						<app-mat-table-paginator
							(pageIndexChanged)="dataSource.pageIndex($event)"
							[pageIndex]="dataSource.pageIndex$ | async"
							[recordCount]="dataSource.recordCount$ | async"
						>
						</app-mat-table-paginator>
					</div>
					<div
						class="col-6 p-0 m-0 d-flex justify-content-end align-items-center"
					>
						<button
							class="btn btn-light border create-template-btn mr-lg-4"
							(click)="createTemplate()"
						>
							+ Create Template
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-email-template-modal
	#emailTemplateModal
	[createFn$]="createFn$"
	[updateFn$]="updateFn$"
	[getMT$]="getMT$"
	[emailTemplateTypeChoices]="emailTemplateTypes"
>
</app-email-template-modal>
