<div class="form-row mb-2 dependents-label-background">
	<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
		<label for="" class="font-weight-bold label-font tap-text-primary">
			Dependants
		</label>
	</div>
	<div class="col-5 col-lg-9 text-right iconShow">
		<button
			id="dependent_collapseButton"
			type="button"
			class="btn-toggle bg-white collapse-btn border-0 dependents-collapse-background"
		>
			<span *ngIf="elseMinusDependents; else elsePlusDependents">
				<i (click)="collapseDependents(true)" class="material-icons md-16 pt-1"
					>expand_more</i
				>
			</span>
			<ng-template #elsePlusDependents>
				<span>
					<i
						(click)="collapseDependents(false)"
						class="material-icons md-16 pt-1"
						>expand_less</i
					>
				</span>
			</ng-template>
		</button>
	</div>
</div>

<div
	class="form-row d-none d-lg-flex mb-2"
	[class.tap-opacity-25]="(dependents$ | async)?.length === 0"
>
	<div class="col-lg-3">
		<span class="font-weight-bold">Name</span>
	</div>
	<div class="col-lg-2">
		<span class="font-weight-bold">Date of Birth</span>
	</div>
	<div class="col-lg-2">
		<span class="font-weight-bold">Age</span>
	</div>
</div>

<div class="collapse expandable" id="collapseDependents">
	<div
		class="form-row align-items-center"
		*ngFor="let d of dependents$ | async; let i = index; let last = last"
	>
		<div class="d-md-none col-lg-2 text-right">
			<ng-container *ngIf="!isAdviceProcessEnded else viewOnlySmall">
				<button
					id="dependent_editButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="editDependent(d.cRTId)"
				>
					<i class="material-icons md-16"> edit </i>
				</button>
				<button
					id="dependent_deleteButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
				>
					<i class="material-icons md-16"> delete </i>
				</button>
			</ng-container>
			<ng-template #viewOnlySmall>
				<ng-container>
					<button
						id="dependent_viewDependent_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1 p-0"
						(click)="viewDependent(d.cRTId)"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-container>
			</ng-template>
		</div>
		
		
		<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="d.name && !last">
			<ng-container *ngIf="d.name">
				<input
					id="dependent_name_{{ i }}"
					class="form-control crt-form-control"
					placeholder="People"
					type="text"
					value="{{ d.name }}"
					[disabled]="d.name"
				/>
			</ng-container>
			<ng-container *ngIf="!d.name">
				<span class="custom-dropdown w-100">
					<select
						[id]="'addNewDependantsDropdown_' + i"
						name="addNewDependantsDropdown"
						class="form-control crt-form-control"
						(change)="change($event, i)"
					>
						<option value=""></option>
						<option *ngFor="let d of dependentList$ | async" [value]="d.value">
							{{ d.display }}
						</option>
						<option value="new">+ Add New Dependant</option>
					</select>
				</span>
			</ng-container>
		</div>
		<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="d.name && !last">
			<input
				*ngIf="d.name && d.name !== 'new'"
				id="dependent_dateOfBirth_{{ i }}"
				class="form-control crt-form-control"
				type="text"
				value="{{ d.birthDate | momentDatetime }}"
				[disabled]="d.name"
			/>
		</div>
		<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="d.name && !last">
			<input
				*ngIf="d.name && d.name !== 'new'"
				id="dependent_age_{{ i }}"
				class="form-control crt-form-control"
				placeholder="Age"
				type="text"
				value="{{ d.age }}"
				[disabled]="d.name"
			/>
		</div>
			<div
				class="d-none d-md-block col-auto"
				[class.border-bottom]="d.name && !last"
			>
			<ng-container *ngIf="d.cRTId && !isCrtLoading(d?.cRTId)">
				<ng-container *ngIf="!isAdviceProcessEnded else viewOnly">
					<button
						id="dependent_editDependentButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="editDependent(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> edit </i>
					</button>
					<button
						id="dependent_deleteDependentButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="deleteDependent(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> delete </i>
					</button>
				</ng-container>
				<ng-template #viewOnly>
					<button
						id="dependent_viewDependentButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1 p-0"
						(click)="viewDependent(d.cRTId)"
						[disabled]="!!crtLoading"
					>
						<i class="material-icons md-16"> info </i>
					</button>
				</ng-template>
			</ng-container>

			<ng-container *ngIf="isCrtLoading(d?.cRTId)">
				<app-manual-loader-mini
					[isLoading]="true"
				></app-manual-loader-mini>
			</ng-container>

			<ng-container *ngIf="!d.cRTId && !isCrtLoading(d?.cRTId)">
				<button
					id="dependent_addExistingDependentButton_{{ i }}"
					type="button"
					color="primary"
					class="icon-btn w-auto h-auto px-1"
					(click)="addExistingDependent(d)"
					[disabled]="!d.name"
				>
					<i class="material-icons md-16"> save </i>
				</button>
				<button
					id="dependent_removeDependentButton_{{ i }}"
					type="button"
					class="icon-btn w-auto h-auto px-1"
					(click)="removeDependent(i)"
				>
					<i class="material-icons md-16"> close </i>
				</button>
			</ng-container>
		</div>
	</div>
	<div class="form-row my-4">
		<div class="col-12 pl-2">
			<ng-container *ngIf="!isAdviceProcessEnded">
				<button
					id="dependent_addDependentButton"
					type="button"
					(click)="addDependent()"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
					[disabled]="
						isAddNew || (isDependantsLoading$ | async) || (isLoading$ | async)
					"
				>
					Add Dependants +
				</button>
			</ng-container>
			<ng-container *ngIf="isDependantsLoading$ | async">
				<app-manual-loader-mini
					[isLoading]="isDependantsLoading$ | async"
				></app-manual-loader-mini>
			</ng-container>
		</div>
	</div>
	<div class="form-row line mb-3"></div>
</div>
