import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MeetingStatsState, MeetingStatsStore } from './msw.store';

/**
 * Query for Meeting Stats widget data
 */
@Injectable()
export class MeetingStatsQuery extends Query<MeetingStatsState> {

  /** Meeting Stats data. */
  MeetingStatsData$ = this.select(state =>
    state.MeetingStats
  );

  /** Indicator if Meeting Stats is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for Meeting Stats pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: MeetingStatsStore) {
    super(store);
  }
}
