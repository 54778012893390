import { Injectable } from '@angular/core';
import produce from 'immer';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService, JsonResultStatus } from '../../../../core/base/api.service';
import { UserQuery } from '../../../../domain/user/user.query';
import UserResponse from '../../../../domain/user/user.response-model';
import { UserStore } from '../../../../domain/user/user.store';
import { DashboardQuery } from './dashboard.query';
import { DashboardStore } from './dashboard.store';
import { Widget } from './widget.model';

/**
 * Service for managing dashboard data.
 *
 * * Fetch dashboard settings.
 * * Update dashboard state.
 * * Update dashboard saved data.
 */
@Injectable()
export class DashboardService {
	constructor(
		private dashboardStore: DashboardStore,
		private dashboardQuery: DashboardQuery,
		private api: ApiService,
		private userQuery: UserQuery,
		private userStore: UserStore
	) {}

	/**
	 * reset store data
	 */
	reset(): void {
		this.dashboardStore.reset();
	}
	/**
	 * sets adviser ids chosen for filtering widget data
	 *
	 * @param value - string array of adviser ids
	 */
	public setFilter(value: string[]) {
		this.dashboardStore.update(state =>
			produce(state, draft => {
				draft.adviserFilter = value;
			})
		);
	}

	/**
	 * Get all active widgets
	 * Store all active widgets for the dashboard
	 */
	getWidgets() {
		const isTapLevel = this.userQuery.isTapLevel();
		const codes = this.dashboardQuery.availableWidgets?.map(x => x.widgetCode);
		const widgetOrder = this.userQuery
			.getValue()
			.WidgetOrder?.filter(x => codes?.find(c => c === x.code?.toLowerCase()))
			?.map(x => x.code?.toLowerCase());

		let widgets: Widget[] = widgetOrder?.map(x => ({ code: x }));
		if (isTapLevel) {
			this.dashboardStore.setWidgets(widgets);
			return of([]);
		} else {
			return of([]).pipe(
				withLatestFrom(this.userQuery.userInfo$),
				tap(([x, user]) => {
					if (user) {
						widgets = !user.StaffSettings.DashboardWidgetConfig
							? []
							: JSON.parse(user.StaffSettings.DashboardWidgetConfig);
						this.dashboardStore.setWidgets(widgets);
					}
				}),
				map(([x]) => of(x)),
				catchError(() => of(null))
			);
		}
	}

	/**
	 * Update the positions and sizes of the widgets
	 *
	 * @param widgets : List of widgets
	 * @return void
	 */
	updateAllWidgets(widgets: Widget[]) {
		return of(widgets).pipe(
			tap(() => this.dashboardStore.setWidgets(widgets)),
			withLatestFrom(this.userQuery.userInfo$),
			map(
				([newVal, userInfo]): UserResponse =>
					({
						...userInfo,
						StaffSettings: {
							...userInfo.StaffSettings,
							DashboardWidgetConfig: JSON.stringify(newVal),
						},
					} as UserResponse)
			),
			tap(req => this.userStore.update(req)),
			withLatestFrom(this.userQuery.isTapLevel$),
			mergeMap(([req, isTop]) =>
				isTop
					? of(null)
					: this.api
							.put<JsonResultStatus>(`staff/${this.userQuery.getValue().StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			),
			tap(() => {
				this.dashboardStore.setIsSavingWidgets(false);
			})
		);
	}

	/**
	 * Save and set active widgets
	 * @param widgetCodes : List of widgets code
	 */
	updateVisibleWidgets(widgetCodes: string[]) {
		this.dashboardStore.setIsSavingWidgets(true);

		const newWidgetCodes = widgetCodes;
		const oldWidgetCodes = this.dashboardQuery.getValue().widgets?.map(x => x.code);

		const retainedWidgetCodes = oldWidgetCodes?.filter(ow => newWidgetCodes?.indexOf(ow) > -1);
		const addedWidgetCodes = newWidgetCodes?.filter(nw => oldWidgetCodes?.indexOf(nw) === -1);

		const widgets = this.dashboardQuery.getValue().widgets;
		const retainedWidgets = widgets?.filter(w => retainedWidgetCodes?.find(rwc => w.code === rwc));
		const addedWidgets = addedWidgetCodes?.map(w => { return { code: w } as Widget });

		const newWidgets = [...retainedWidgets, ...addedWidgets];

		return this.updateAllWidgets(newWidgets);
	}

	getReferrals() {
		return this.api.post3<ReferralResponse[]>('widgets?type=R').pipe(tap(x => this.dashboardStore.setReferrals(x)));
	}
}

export class ReferralResponse {
	Link: string;
	Name: string;
	isActive: boolean;
}
