import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { DropdownValueService } from '@domain/dropdown-value/dropdown-value.service';
import { UserQuery } from '@domain/user/user.query';
import { PrimaryClient, PrimaryClientState } from '@models/client-profile/primary-client/primary-client.model';
import { SecondaryClient } from '@models/client-profile/secondary-client/secondary-client.model';
import { SecondaryProfessional } from '@models/client-profile/secondary-professional/secondary-professional.model';
import { SecondaryTrust } from '@models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusiness } from '@models/client-profile/seondary-business/secondary-business.model';
import { DocumentList, DocumentListState } from '@modules/kiwisaver-settings/advice-summary-settings/document-list/state/document-list.model';
import { DocumentListService } from '@modules/kiwisaver-settings/advice-summary-settings/document-list/state/document-list.service';
import { AdviceSummarySettingsState } from '@modules/kiwisaver-settings/advice-summary-settings/state/advice-summary-settings.model';
import { AdviceSummarySettingsService } from '@modules/kiwisaver-settings/advice-summary-settings/state/advice-summary-settings.service';
import { ProvidersSettingsState } from '@modules/kiwisaver-settings/fact-find-settings/providers/state/providers-settings.model';
import { ProviderSettingsService } from '@modules/kiwisaver-settings/fact-find-settings/providers/state/providers-settings.service';
import { FactFindSubpagesSettingsState } from '@modules/kiwisaver-settings/fact-find-settings/subpages/state/fact-find-subpages-settings.model';
import { FactFindSubpagesSettingsService } from '@modules/kiwisaver-settings/fact-find-settings/subpages/state/fact-find-subpages-settings.service';
import { SettingsTypes } from '@modules/kiwisaver-settings/state/kiwisaver-settings.model';
import {
	AdviceProcessSectionCodes,
	KiwiSaverAdviceProcessSectionCodes,
} from '@shared/models/advice-process/advice-process.model';
import { LinkedContact } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { Observable, forkJoin, from, of } from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	finalize,
	map,
	mapTo,
	mergeMap,
	tap,
} from 'rxjs/operators';
import { objectUtil } from '../../../../util/util';
import { ClientProfileService } from '../../client-profile/states/client-profile.service';
import { MergeTagsService } from '../crt-kiwisaver/state/merge-tags/crt-kiwisaver-mergetags.service';
import { AdviceSummaryService } from './advice-summary/states/advice-summary.service';
import { DeclarationService } from './declaration/state/declaration.service';
import { DisclosureService } from './disclosure/state/disclosure.service';
import { KoatIntroService } from './introduction/state/introduction.service';
import { DependantsService } from './people-entities/dependants/state/dependants.service';
import { PeopleService } from './people-entities/people/state/people.service';
import { ScopeOfServiceService } from './scope-of-services/state/scope-of-service.service';
import { CrtKiwiSaverQuery } from './state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from './state/crt-kiwisaver.service';
import { CrtKiwiSaverStore } from './state/crt-kiwisaver.store';
import { AdviceSummaryReportBuilderPeopleStore } from './advice-summary/advice-summary-report-builder/states/advice-summary-report-builder-people.store';
import { AdviceSummaryReportBuilderStore } from './advice-summary/advice-summary-report-builder/states/advice-summary-report-builder.store';

const dropdownCodes: string[] = ['PCE', 'APCRTVD', 'APCRTG', 'SCR'];

@Injectable()
export class CrtKiwiSaverResolver implements Resolve<boolean> {
	constructor(
		private service: CrtKiwiSaverService,
		private query: CrtKiwiSaverQuery,
		private store: CrtKiwiSaverStore,
		private dropdownValueService: DropdownValueService,
		private userQuery: UserQuery,
		private mtService: MergeTagsService,
		private clientProfileService: ClientProfileService,
		private introService: KoatIntroService,
		private disclosureService: DisclosureService,
		private scopeService: ScopeOfServiceService,
		private peopleService: PeopleService,
		private dependantsService: DependantsService,
		private facFindSettingsService: FactFindSubpagesSettingsService,
		private providerSettingsService: ProviderSettingsService,
		private declarationService: DeclarationService,
		private koatDocumentListService: DocumentListService,
		private adviceSummarySettingsService: AdviceSummarySettingsService,
		private adviceSummaryService: AdviceSummaryService,
		private adviceSummaryReportBuilderStore: AdviceSummaryReportBuilderStore,
		private adviceSummaryReportBuilderPeopleStore: AdviceSummaryReportBuilderPeopleStore,
		
	) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		this.service.clearData();
		this.peopleService.clearData();
		this.dependantsService.clearData();
		this.scopeService.reset();
		this.disclosureService.clearData();
		this.adviceSummaryReportBuilderStore.reset();
		this.adviceSummaryReportBuilderPeopleStore.reset();

		const staffId = +this.userQuery.getValue().StaffID;
		const dropdowns$ =
			this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		const adviceProcessId = +route.paramMap.get('adviceProcessId');
		const clientId = +route.paramMap.get('clientId');
		const isCompany =
			route.paramMap.get('customerType')?.toLowerCase() !== 'client';
		let loadSections$: Observable<any>;

		let primaryClient$: Observable<PrimaryClient | PrimaryClientState>;
		let secondaryClients$: Observable<SecondaryClient[]>;
		let secondaryTrust$: Observable<SecondaryTrust[]>;
		let secondaryCompanies$: Observable<SecondaryBusiness[]>;
		let secondaryProfessionals$: Observable<SecondaryProfessional[]>;
		let linkedContacts$: Observable<LinkedContact[]>;
		let factFindSettings$: Observable<FactFindSubpagesSettingsState>;
		let factFindProdiderSettings$: Observable<ProvidersSettingsState>;
		let koatDocumentListService$: Observable<DocumentListState[] | DocumentList[]>;
		let adviceSummarySettings$: Observable<AdviceSummarySettingsState>;

		this.service.setIsCompany(isCompany);

		if (!!adviceProcessId && !!clientId) {
			this.service.setAdviceProcessId(adviceProcessId);
			this.service.setClientId(clientId);

			primaryClient$ = this.service
				.getPrimaryClient(clientId)
				.pipe(catchError(() => of(undefined)));
			secondaryClients$ = this.service
				.getSecondaryClients(clientId)
				.pipe(catchError(() => of([])));
			secondaryTrust$ = this.service
				.getSecondaryTrusts(clientId)
				.pipe(catchError(() => of([])));
			secondaryCompanies$ = this.service
				.getSecondaryCompanies(clientId)
				.pipe(catchError(() => of([])));
			secondaryProfessionals$ = this.service
				.getSecondaryProfessionals(clientId)
				.pipe(catchError(() => of([])));
			linkedContacts$ = this.service
				.getLinkedContacts(clientId)
				.pipe(catchError(() => of([])));
			factFindSettings$ = this.facFindSettingsService
				.getFactFindSubpagesSettings(0, SettingsTypes.KOATFactFind)
				.pipe(
					map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
					tap((x) => this.store.setFactFindSidebar(x)),
					catchError(() => of([]))
				);
			factFindProdiderSettings$ =
				this.providerSettingsService.getProvidersSettings(
					0,
					SettingsTypes.KOATFactFindProviders
				);
			koatDocumentListService$ = this.koatDocumentListService.getDocumentList(
				0,
				SettingsTypes.AdviceSummaryDocumentListSettings
			);

			adviceSummarySettings$ =
				this.adviceSummarySettingsService.getAdviceSummarySettings(
					0,
					SettingsTypes.KOATAdviceSummary
				);
			loadSections$ = from([
				this.introService.getIntro(),
				this.disclosureService.getDisclosureDocument(+adviceProcessId),
				this.peopleService.get(+adviceProcessId),
				this.dependantsService.get(+adviceProcessId),
			]).pipe(mergeMap((x) => x));
		}

		return adviceProcessId
			? forkJoin([
					dropdowns$,
					// Get Profiles
					primaryClient$,
					secondaryClients$,
					secondaryTrust$,
					secondaryCompanies$,
					secondaryProfessionals$,
					linkedContacts$,
					factFindSettings$,
					factFindProdiderSettings$,
					koatDocumentListService$,
					adviceSummarySettings$,

					this.service.getAdviceProcess(adviceProcessId),
					this.service.getPrimaryClient(clientId),
					this.clientProfileService.getClientDocuments(clientId).pipe(catchError(() => of([]))),

					this.scopeService.getSosDefault(),
					this.scopeService.getScopeOfService(
						adviceProcessId,
						AdviceProcessSectionCodes.SOS
					),
					this.scopeService.getSosTemplateSettings(),
					// Merge Tag
					this.mtService.getDefaultMergeTags(true),

					// Declaration
					this.declarationService.getDeclaration(
						adviceProcessId,
						KiwiSaverAdviceProcessSectionCodes.Declaration
					),
					this.declarationService.getDeclarationDocument(
						0,
						SettingsTypes.Declaration
					),
					this.declarationService.getDeclarationEmailSettings(
						0,
						SettingsTypes.KOATDeclarationEmail
					),
					this.adviceSummaryService.getEmailSettings(
						0,
						SettingsTypes.KOATAdviceSummaryEmail
					),
			  ]).pipe(
					delay(0),
					concatMap(() =>
						this.mtService.getAdviserProviderMt(
							this.query.getValue().adviceProcess?.adviser
						)
					),
					finalize(() => {
						loadSections$.subscribe();
					}),
					mapTo(true)
			  )
			: forkJoin([dropdowns$]).pipe(mapTo(true));
	}
}
