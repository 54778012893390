import { CombinedAttachment } from '@shared/models/_general/attachment.model';
import { DocumentTypes } from '@shared/models/documents/document.model';
import { EmailSettingsModel } from 'src/app/shared/models/_general/email-settings.model';

export interface StructureSoa {
	cRTId?: number;
	adviceProcessId?: number;
	sectionCode?: string;

	name?: string;
	currentPage?: string;
	documentId?: number;
  emailSettings?: StructureSoaEmailSettings;
  modifiedDateTime?: string;
}

export interface StructureSoaState {
  referenceId: number;
  document: string;
  fileName: string;
  type: string;
}

export enum SignatureTypesMOAT {
	StatementOfAdvice = 'MOATSA',
}

// OrderNo = 0 is reserved for Aesthetic cover page (combined by BE)
export const MoatRoaPdfOrder: CombinedAttachment[] = [
	{
		orderNo: 1,
		document: '',
		type: DocumentTypes.MOATGenericCover,
		toGeneratePdf: true,
	},
	{
		orderNo: 2,
		document: '',
		type: DocumentTypes.MOATRecordOfAdviceDocument,
		toGeneratePdf: true,
	},
]

export interface StructureSoaEmailSettings extends EmailSettingsModel {}


