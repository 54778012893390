import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ProviderCommissionSettingState } from '../../../shared/models/provider-commission/provider-commission.model';
import { ApiService } from '../../../core/base/api.service';
import { objectUtil } from '../../../util/util';
import { UserSettingsQuery } from '../state/user-settings/user-settings.query';
import { UserSettingsStore } from '../state/user-settings/user-settings.store';
import * as R from 'ramda';

@Injectable()
export class ProviderCommissionService {
	providerCommissionLR$ = this.query.providerCommissionLR$;
	providerCommissionM$ = this.query.providerCommissionM$;
	providerCommissionFG$ = this.query.providerCommissionFG$;
	providerCommissionK$ = this.query.providerCommissionK$;

	constructor(
		private api: ApiService,
		private store: UserSettingsStore,
		protected query: UserSettingsQuery
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	// PCLR (L&R) services
	getProviderCommissionLR(staffId: number) {
		const settingsCode = 'PCLR';
		const endpoint = `staff/${staffId}/settings/${settingsCode}`;
		return this.api.get<ProviderCommissionSettingState[]>(endpoint).pipe(
			map((data) => data?.map(objectUtil.mapPascalCaseToCamelCase) || []),
			map((data) => {
				const defaultPrio = data?.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							order: item?.providerCommissionOrder ?? defaultPrio,
					  }))
					: [];
			}),
			map((data) => R.sort((a, b) => a?.order - b?.order, data)),
			tap((data) =>
				applyTransaction(() => {
					const state = data || [];
					this.store.setProviderCommissionLR(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addProviderCommissionLR(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const getCurrent = this.query.getValue().providerCommissionLR || [];
					const newData = [
						...getCurrent,
						{
							...data,
							settingsId: x,
						},
					] as ProviderCommissionSettingState[];
					this.store.setProviderCommissionLR(newData);
				})
			)
		);
	}

	updateProviderCommissionLR(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings/${data.settingsId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<ProviderCommissionSettingState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const newData = (
						this.query
							.getValue()
							.providerCommissionLR?.map((y) =>
								y.settingsId === data.settingsId ? { ...data } : y
							)
					) as ProviderCommissionSettingState[];
					this.store.setProviderCommissionLR(newData);
				})
			)
		);
	}

	deleteProviderCommissionLR(settingsId: number) {
		const endpoint = `staff/settings/${settingsId}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const newData = this.query
						.getValue()
						.providerCommissionLR?.filter((y) => y.settingsId !== settingsId);
					this.store.setProviderCommissionLR(newData);
				});
			})
		);
	}

	// PCM (Mortgage) services
	getProviderCommissionM(staffId: number) {
		const settingsCode = 'PCM';
		const endpoint = `staff/${staffId}/settings/${settingsCode}`;
		this.store.setLoading(true);
		return this.api.get<ProviderCommissionSettingState[]>(endpoint).pipe(
			map((data) => data?.map(objectUtil.mapPascalCaseToCamelCase) || []),
			map((data) => {
				const defaultPrio = data?.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							order: item?.providerCommissionOrder ?? defaultPrio,
					  }))
					: [];
			}),
			map((data) => R.sort((a, b) => a?.order - b?.order, data)),
			tap((data) =>
				applyTransaction(() => {
					const state = data || [];
					this.store.setProviderCommissionM(state);
				})
			),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of([]))
		);
	}

	addProviderCommissionM(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const getCurrent = this.query.getValue().providerCommissionM || [];
					const newData = [
						...getCurrent,
						{
							...data,
							settingsId: x,
						},
					] as ProviderCommissionSettingState[];
					this.store.setProviderCommissionM(newData);
				})
			)
		);
	}

	updateProviderCommissionM(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings/${data.settingsId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<ProviderCommissionSettingState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const newData = (
						this.query
							.getValue()
							.providerCommissionM?.map((y) =>
								y.settingsId === data.settingsId ? { ...data } : y
							)
					) as ProviderCommissionSettingState[];
					this.store.setProviderCommissionM(newData);
				})
			)
		);
	}

	deleteProviderCommissionM(settingsId: number) {
		const endpoint = `staff/settings/${settingsId}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const newData = this.query
						.getValue()
						.providerCommissionM?.filter((y) => y.settingsId !== settingsId);
					this.store.setProviderCommissionM(newData);
				});
			})
		);
	}

	// PCFG (F&G) services
	getProviderCommissionFG(staffId: number) {
		const settingsCode = 'PCFG';
		const endpoint = `staff/${staffId}/settings/${settingsCode}`;
		return this.api.get<ProviderCommissionSettingState[]>(endpoint).pipe(
			map((data) => data?.map(objectUtil.mapPascalCaseToCamelCase) || []),
			map((data) => {
				const defaultPrio = data?.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							order: item?.providerCommissionOrder ?? defaultPrio,
					  }))
					: [];
			}),
			map((data) => R.sort((a, b) => a?.order - b?.order, data)),
			tap((data) =>
				applyTransaction(() => {
					const state = data || [];
					this.store.setProviderCommissionFG(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addProviderCommissionFG(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const getCurrent = this.query.getValue().providerCommissionFG || [];
					const newData = [
						...getCurrent,
						{
							...data,
							settingsId: x,
						},
					] as ProviderCommissionSettingState[];
					this.store.setProviderCommissionFG(newData);
				})
			)
		);
	}

	updateProviderCommissionFG(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings/${data.settingsId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<ProviderCommissionSettingState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const newData = (
						this.query
							.getValue()
							.providerCommissionFG?.map((y) =>
								y.settingsId === data.settingsId ? { ...data } : y
							)
					) as ProviderCommissionSettingState[];
					this.store.setProviderCommissionFG(newData);
				})
			)
		);
	}

	deleteProviderCommissionFG(settingsId: number) {
		const endpoint = `staff/settings/${settingsId}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const newData = this.query
						.getValue()
						.providerCommissionFG?.filter((y) => y.settingsId !== settingsId);
					this.store.setProviderCommissionFG(newData);
				});
			})
		);
	}

	// PCK (KiwiSaver) services
	getProviderCommissionK(staffId: number) {
		const settingsCode = 'PCK';
		const endpoint = `staff/${staffId}/settings/${settingsCode}`;
		return this.api.get<ProviderCommissionSettingState[]>(endpoint).pipe(
			map((data) => data?.map(objectUtil.mapPascalCaseToCamelCase) || []),
			map((data) => {
				const defaultPrio = data?.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							order: item?.providerCommissionOrder ?? defaultPrio,
					  }))
					: [];
			}),
			map((data) => R.sort((a, b) => a?.order - b?.order, data)),
			tap((data) =>
				applyTransaction(() => {
					const state = data || [];
					this.store.setProviderCommissionK(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addProviderCommissionK(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const getCurrent = this.query.getValue().providerCommissionK || [];
					const newData = [
						...getCurrent,
						{
							...data,
							settingsId: x,
						},
					] as ProviderCommissionSettingState[];
					this.store.setProviderCommissionK(newData);
				})
			)
		);
	}

	updateProviderCommissionK(data: ProviderCommissionSettingState) {
		const endpoint = `staff/settings/${data.settingsId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<ProviderCommissionSettingState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const newData = (
						this.query
							.getValue()
							.providerCommissionK?.map((y) =>
								y.settingsId === data.settingsId ? { ...data } : y
							)
					) as ProviderCommissionSettingState[];
					this.store.setProviderCommissionK(newData);
				})
			)
		);
	}

	deleteProviderCommissionK(settingsId: number) {
		const endpoint = `staff/settings/${settingsId}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const newData = this.query
						.getValue()
						.providerCommissionK?.filter((y) => y.settingsId !== settingsId);
					this.store.setProviderCommissionK(newData);
				});
			})
		);
	}

	syncProviderCommission(staffId: number, type: string) {
		const endpoint = `staff/sync/${staffId}/provider-commission/${type}`;
		return this.api.post3(endpoint);
	}

	bulkPcUpdate(data: ProviderCommissionSettingState[], type: string) {
		const endpoint = `staff/settings/provider-commissions`;
		const body = data?.map((x) => objectUtil.mapCamelCaseToPascalCase(x));
		return this.api.put<ProviderCommissionSettingState[]>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					if (type === 'PCLR') {
						this.store.setProviderCommissionLR(data);
					}
					if (type === 'PCFG') {
						this.store.setProviderCommissionFG(data);
					}
					if (type === 'PCK') {
						this.store.setProviderCommissionK(data);
					}
					if (type === 'PCM') {
						this.store.setProviderCommissionM(data);
					}
				})
			)
		);
	}
}
