import { TransferService } from './state/transfer.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TransferComponent } from './transfer.component';
import { ProgressPopupComponent } from '@shared/progress-popup/progress-popup.component';

@NgModule({
	declarations: [
    TransferComponent
  ],
	imports: [CommonModule, ProgressPopupComponent],
  exports: [TransferComponent],
  providers: [TransferService]
})
export class TransferModule {}
