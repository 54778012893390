import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ViewDisplayValue } from '@models/_general/display-value.viewmodel';
import { KOATPeopleMapper } from '@models/client-review-template/people/people.mapper';
import { KOATPeopleDetailsState, ResidencyStatus } from '@models/client-review-template/people/people-details.model';
import { LoggerService } from '@loggerService';
import { NoWhitespaceValidator } from '@shared/validator/no-whitespace/no-whitespace.directive';
import { CustomerTypes, GetGenderViewDisplay, RelationshipTypes } from '@models/_general/client.model';
import { AdviceProcessSectionCodes } from '@models/advice-process/advice-process.model';
import { logMessage } from '@errorMsgs';
import MomentUtil from '@momentUtil';
import { validMomentDate } from '@shared/validator/valid-moment-date/valid-moment-date';

@Component({
  selector: 'app-people-form',
  templateUrl: './people-form.html',
  styleUrls: ['./people-form.scss'],
})
export class PeopleFormComponent implements OnInit {
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveEvent = new EventEmitter<KOATPeopleDetailsState>();
  @Input() personInfo: KOATPeopleDetailsState;
  @Input() relationship: ViewDisplayValue[];
  @Input() employment: ViewDisplayValue[];
  @Input() visaDetails: ViewDisplayValue[];
  @Input() apcrtg: ViewDisplayValue[]; // gender dropdown api
  @Input() timeInResidence: ViewDisplayValue[];
  @Input() maritalStatus: ViewDisplayValue[];
  @Input() hasCountry: boolean;
  @Input() title: ViewDisplayValue[];
  @Input() loadingState: boolean;

  public bsModalRef: BsModalRef;
  form: UntypedFormGroup;
  submitted = false;
  formSubmitted: boolean;
  cancelLoading: boolean;
	nzCitizenStatus = ResidencyStatus.nzCitizen
	relationshipDd: ViewDisplayValue[];

  gender: ViewDisplayValue[] = GetGenderViewDisplay(); // gender app model

  constructor(private fb: UntypedFormBuilder, private loggerService: LoggerService) {
    this.buildForm();
  }

  ngOnInit(): void {
		this.relationshipDd = [...this.relationship]?.filter(
			(x) => x?.value !== RelationshipTypes.Child
		);
    this.prepData();
  }

  prepData() {
    if (!this.personInfo) {
      return;
    }

    const mapped = KOATPeopleMapper.mapToView(this.personInfo);
    this.form.reset(mapped);
  }

  get FirstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get DateOfBirth() {
    return this.form.get('dateOfBirth');
  }
  get Residency() {
    return this.form.get('residencyStatus');
  }

  buildForm() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, NoWhitespaceValidator]],
      middleName: [''],
      lastName: ['', [Validators.required, NoWhitespaceValidator]],
      knownAs: [''],
      gender: [''],
      dateOfBirth: ['', [Validators.required, validMomentDate()]],
      email: [''],
      mobilePhone: [''],
      homePhone: [''],
      workPhone: [''],
      physicalAddress: [''],
      relationship: [''],
      employment: [''],
      occupation: [''],
      employer: [''],
      hours: [''],
      years: [''],
      manual: [''],
      residencyStatus: [''],
      outletRetails: [''],
      title: [''],
      countryOfOrigin: [''],
      previousAddress: [''],
      timeInResidencePhysicalAddress: [''],
      timeInResidencePreviousAddress: [''],
      maritalStatus: [''],
      income: ['']
    });
  }

  save() {
    if (this.submitted || this.cancelLoading) {
      return;
    }

    this.formSubmitted = true;

    if (
      !this.form.valid ||
      this.form.value.firstName?.trim() === '' ||
      this.form.value.lastName?.trim() === '' ||
      (this.form.value.dateOfBirth !== '' && this.form.value.dateOfBirth !== null && !MomentUtil.isValidMoment(this.form.value.dateOfBirth))
    ) {
      this.loggerService.Warning({}, logMessage.shared.general.warning.required);
      return;
    }

    this.submitted = true;
    const data = KOATPeopleMapper.mapToUpsert(this.form.value as KOATPeopleDetailsState, this.personInfo);

		  if (!this.personInfo) {
			data.sectionCode = data?.relationship === RelationshipTypes.Child ? AdviceProcessSectionCodes.Dependants : AdviceProcessSectionCodes.People
		}

    this.saveEvent.emit(data as KOATPeopleDetailsState);
  }

  cancelClick() {
    this.cancelLoading = true;
    this.cancelEvent.emit(true);
    setTimeout(() => this.cancelLoading = false, 500);
  }

	/**
	 * Flag to show the relationship field
	 *
	 * @readonly
	 * @type {boolean}
	 */
	get showRelationShipField(): boolean {
		return this.personInfo?.customerType !== CustomerTypes.PrimaryCustomerIndividual;
	}

  onDateChange(e: any) {
    if (e.targetElement.value) {
      this.form.get('dateOfBirth').setValidators([Validators.required, validMomentDate()]);
      this.form.get('dateOfBirth').updateValueAndValidity();
    }
  }

	trackByValue = (i: number, item: ViewDisplayValue) => item?.value;
}
