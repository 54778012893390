import {
	Component,
	ContentChild,
	Input,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { EmailTemplateService } from '@modules/emails/email-settings/state/email-template.service';
import { EmailTemplateModel } from '@modules/emails/email-settings/state/email-template.store';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from '@shared/wysiwyg/wysiwyg.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, defer } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { EmailTemplateModalFormComponent } from './email-template-modal-form/email-template-modal-form.component';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';

@Component({
	selector: 'app-email-template-modal',
	templateUrl: './email-template-modal.component.html',
	styleUrls: ['./email-template-modal.component.scss'],
})
export class EmailTemplateModalComponent {
	@ViewChild('editor') editor: WysiwygComponent;

	@ContentChild(WysiwygComponent) d: WysiwygComponent;

	@Input() title = 'Create Email Template';

	@Input() createFn$: (data: any) => Observable<any>;

	@Input() updateFn$: (data: any) => Observable<any>;

	@Input() testEmailFn$: (data: any) => Observable<boolean>;

	@Input() getMT$: () => Observable<MergeTagState[]>;

	@ViewChild('templateRef') templateRef: TemplateRef<any>;

	@ViewChild('testEmailTemplateRef') testEmailTemplateRef: TemplateRef<any>;

	@Input() shortCodes: object;

	@Input() emailTemplateTypeChoices: ViewDisplayValue[] = [];

	modalRef: BsModalRef;

	private closeSubject = new Subject<boolean>();

	constructor(
		private bsModalService: BsModalService,
		private emailTemplateService: EmailTemplateService
	) {}

	showEdit(data: EmailTemplateModel): Observable<boolean> {
		return defer(() => {
			const isPredefinedTemplate = data.body == '0';
			const getBody$ = isPredefinedTemplate
				? this.emailTemplateService.getContentByDocumentLink(data.documentLink)
				: this.emailTemplateService.getBody(data.body);
			return getBody$.pipe(
				mergeMap((content) => {
					data.bodyContent = content;
					this.modalRef = this.bsModalService.show(
						EmailTemplateModalFormComponent,
						{
							class:
								' modal-dialog modal-dialog-centered modal-lg email-modal-template',
							ignoreBackdropClick: true,
							initialState: {
								data: data,
								title: 'Edit Email Template',
								isCreate: false,
								createFn$: this.createFn$,
								updateFn$: this.updateFn$,
								testEmailFn$: this.testEmailFn$,
								getMT$: this.getMT$,
								closeSubject: this.closeSubject,
								shortCodes: this.shortCodes,
								etTypeChoices: this.emailTemplateTypeChoices,
							},
							keyboard: false,
						}
					);
					return this.closeSubject
						.asObservable()
						.pipe(tap(() => this.modalRef?.hide()));
				})
			);
		});
	}

	showAdd(data: Partial<EmailTemplateModel>): Observable<any> {
		return defer(() => {
			this.modalRef = this.bsModalService.show(
				EmailTemplateModalFormComponent,
				{
					class:
						' modal-dialog modal-dialog-centered modal-lg email-modal-template',
					ignoreBackdropClick: true,
					initialState: {
						closeSubject: this.closeSubject,
						title: 'Create Email Template',
						isCreate: true,
						data: data,
						createFn$: this.createFn$,
						updateFn$: this.updateFn$,
						testEmailFn$: this.testEmailFn$,
						getMT$: this.getMT$,
						shortCodes: this.shortCodes,
						etTypeChoices: this.emailTemplateTypeChoices,
					},
					keyboard: false,
				}
			);
			return this.closeSubject.asObservable().pipe(
				tap(() => {
					this.modalRef?.hide();
				})
			);
		});
	}
}
