import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { viewSecGroup } from '../../../viewmodels/viewSecGroup';
import { viewBusiness } from '../../../viewmodels/viewBusiness';
import { TlStaffViewModel } from '../../../viewmodels/tl-staff.viewmodel';
import { tap, mergeMap } from 'rxjs/operators';
import { RouteService } from '../../../../../core/config/route.service';
import { TLStaffsService } from '../../../../../domain/tl-staff/tl-staffs.service';
import { TLStaffSecGroupsQuery } from '../../../../../domain/tl-staff-security-group/tl-staff-security-groups.query';

@Component({
    selector: 'app-tl-add',
    templateUrl: './tl-add.component.html'
})
export class TlAddComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private routeService: RouteService,
        private tlStaffsService: TLStaffsService,
        private tlStaffSecGroupQuery: TLStaffSecGroupsQuery) { }
    subs: Subscription[] = [];
    staff: TlStaffViewModel = new TlStaffViewModel();
    secGroups: viewSecGroup[] = [];
    // tl business
    businesses: viewBusiness[] = [];
    ngOnInit() {
        const sg = this.tlStaffSecGroupQuery.getAll();
        this.secGroups = viewSecGroup.mapSecGroups(sg);

        const sub = this.route.data.subscribe((data: { secGroups; businesses }) => {
            // tl business choice
            this.businesses = viewBusiness.mapBusinesses(data.businesses);
        });
        this.subs.push(sub);
    }

    submitAdd = (v: TlStaffViewModel) =>
        new Observable(obs => {
            const staff = TlStaffViewModel.mapToTlAddRequest(v);
            obs.next(staff);
            obs.complete();
        }).pipe(mergeMap(x => this.tlStaffsService.add(x)), tap(() => this.cancel()))

    cancel() {
        this.router.navigate(
            this.routeService.splitToRoutePath(this.routeService.adminUsersList)
        );
    }
    ngOnDestroy(): void {
        this.subs?.forEach(s => s.unsubscribe());
    }
}
