import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { Observable } from 'rxjs';
import { util } from '../../../core/util/util.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NoWhitespaceValidator } from '../../validator/no-whitespace/no-whitespace.directive';
import * as R from 'ramda';
import MomentUtil from '../../../util/moment.util';
import { ContactTypes } from '../../models/_general/client.model';

@Component({
	selector: 'app-client-add-modal',
	templateUrl: './client-add-modal.component.html',
	styleUrls: ['./client-add-modal.component.scss'],
})
export class ClientAddModalComponent implements OnInit {
	header: string;
	name: string;
	advisers: ViewDisplayValue[];
	trustTypes: ViewDisplayValue[];
	contactTypes = [];
	isSaving = false;
	selectedContactType: string = null;
	savefn: (model: any, note: any) => Observable<any>;
	maxDate = util.MomentNow();
	searchMode: string;
	constructor(private fb: UntypedFormBuilder, public bsModalRef: BsModalRef, private cd: ChangeDetectorRef) {}

	form: UntypedFormGroup = this.fb.group({
		contactType: null,
		notes: null,
		adviser: '',
		physicalAddress: '',

		// Client
		firstName: '',
		lastName: '',
		mobile: '',
		email: '',
		dateOfBirth: '',

		// Business
		businessName: '',
		work: '',

		// Trust
		trustName: '',
		trustType: '',
		customer: null,
	});

	ngOnInit() {
		if (this.searchMode === 'Director') {
			this.contactTypes = [ContactTypes.Client];
		} else if (this.searchMode === 'ClientsInvolved') {
			this.contactTypes = [ContactTypes.Client, ContactTypes.Trust];
		} else {
			this.contactTypes = [ContactTypes.Client, ContactTypes.Business, ContactTypes.Trust];
		}
	}

	get adviser() {
		return this.form.get('adviser');
	}
	get firstName() {
		return this.form.get('firstName');
	}
	get lastName() {
		return this.form.get('lastName');
	}
	get businessName() {
		return this.form.get('businessName');
	}
	get trustName() {
		return this.form.get('trustName');
	}
	get trustType() {
		return this.form.get('trustType');
	}
	get customer() {
		return this.form.get('customer');
	}

	add() {
		const ct = this.form.get('contactType').value;
		this.selectedContactType = ct;
		if (ct === ContactTypes.Client) {
			this.header = `${this.header} - ${ContactTypes.Client}`;
			this.form.get('firstName').setValue(this.name);

			this.form.get('firstName').setValidators([Validators.required, NoWhitespaceValidator]);
			this.form.get('lastName').setValidators([Validators.required, NoWhitespaceValidator]);
			if (this.searchMode !== 'ClientsInvolved') {
				this.form.get('adviser').setValidators(Validators.required);
			}
		}
		if (ct === ContactTypes.Business) {
			this.header = `${this.header} - ${ContactTypes.Business}`;
			this.form.get('businessName').setValue(this.name);

			this.form.get('businessName').setValidators([Validators.required, NoWhitespaceValidator]);
			this.form.get('adviser').setValidators(Validators.required);
		}
		if (ct === ContactTypes.Trust) {
			this.header = `${this.header} - ${ContactTypes.Trust}`;
			this.form.get('trustName').setValue(this.name);

			this.form.get('trustName').setValidators([Validators.required, NoWhitespaceValidator]);
			this.form.get('trustType').setValidators(Validators.required);
			if (this.searchMode !== 'ClientsInvolved') {
				this.form.get('customer').setValidators(Validators.required);
			}
		}
	}

	save(vm: any) {
		if (!this.form.valid) {
			return;
		}
		this.isSaving = true;
		let model: any;
		if (vm.contactType === ContactTypes.Client) {
			model =
				this.searchMode === 'ClientsInvolved'
					? R.omit(['adviser'], this.mapToClientRequestModel(vm))
					: this.mapToClientRequestModel(vm);
		}
		if (vm.contactType === ContactTypes.Business) {
			model = this.mapToBusinessRequestModel(vm);
		}
		if (vm.contactType === ContactTypes.Trust) {
			model =
				this.searchMode === 'ClientsInvolved'
					? R.omit(['primaryCustomer'], this.mapToTrustRequestModel(vm))
					: this.mapToTrustRequestModel(vm);
		}

		const note = {
			customerServiceID: 0,
			customerID: null,
			notes: vm.notes,
		};
		this.savefn(model, note).subscribe(
			res => {
				if (res) {
					this.isSaving = false;
				}

    this.decline();
			},
			() => {
				this.isSaving = false;
			}
		);
	}

	decline = () => this.bsModalRef.hide();

	mapToClientRequestModel(vm: any) {
		return {
			firstName: vm.firstName,
			lastName: vm.lastName,
			mobile: vm.mobile,
			email: vm.email,
			dateOfBirth: MomentUtil.formatDateToServerDate(vm.dateOfBirth),
			adviser: !!vm.adviser ? vm.adviser : null,
			physicalAddress: vm.physicalAddress,
		};
	}

	mapToBusinessRequestModel(vm: any) {
		return {
			companyName: vm.businessName,
			work: vm.work,
			adviser: vm.adviser,
			physicalAddress: vm.physicalAddress,
		};
	}

	mapToTrustRequestModel(vm: any) {
		return {
			primaryCustomer: !!vm.customer && !!vm.customer.CustomerId ? vm.customer.CustomerId : null,
			trustName: vm.trustName,
			trustType: vm.trustType,
		};
	}
}
