import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { FGBookBreakdown } from './fgbb.model';
import { Injectable } from "@angular/core";

export interface FGBookBreakdownState {

  /**
   * F&G BookBreakdown fields
   */
  fGBookBreakdowns: FGBookBreakdown[];

  /**
   * error message. will show up when not empty
   */
  error: string;

  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): FGBookBreakdownState {
  return {
    fGBookBreakdowns: [],
    error: '',
    isLoading: false
  };
}


/**
 * F&G Book Breakdown widget data store
 */
@Injectable()
@StoreConfig({
  name: 'FGBookBreakdown'
})
export class FGBookBreakdownStore extends Store<FGBookBreakdownState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Set if F&G Book Breakdown is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
