import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { complement, either, isEmpty, isNil } from 'ramda';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import { concatMap, filter, finalize, map, mergeMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/base/component-base';
import { UserQuery } from 'src/app/domain/user/user.query';
import {
	contentEditable,
	getContentWithMergeTags,
	removeEmptyParagraphs,
} from 'src/app/shared/converter/content-merge-tags';
import { koatSosMergeTags } from 'src/app/shared/models/client-review-template/merge-tags/crt-kiwisaver/scope-of-service/sos.merge-tag';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { convertUtil } from 'src/app/util/util';
import { SettingsTypes } from '../../state/kiwisaver-settings.model';
import { KiwiSaverSettingsQuery } from '../../state/kiwisaver-settings.query';
import { KiwiScopeOfServiceSettingsService } from '../state/kiwi-scope-of-service-settings.service';
import { KiwiScopeOfServiceService } from '../state/kiwi-scope-of-service.service';
import {
	KiwiScopeOfServiceTemplateSettingsMapper,
	KiwiScopeOfServiceTemplateSettingsMapper as sosTemplateSettingsMapper,
} from './state/kiwi-sos-template-settings.mapper';
import { KiwiSosTemplateSettingsService } from './state/kiwi-sos-template-settings.service';

const componentName = 'app-kiwi-sos-template-settings';
@Component({
	selector: componentName,
	templateUrl: './kiwi-sos-template-settings.component.html',
	styleUrls: ['./kiwi-sos-template-settings.component.scss'],
})
export class KiwiSosTemplateSettingsComponent extends ComponentBase implements OnInit, OnDestroy {
	isLoading = false;
	isPreview = false;
	isReload = true;
	customButtons = [];
	currentTemplate = '<p></p>';
	undoReset$ = new BehaviorSubject<unknown>(null);
	undoStack$ = new BehaviorSubject<unknown>(null);

	tempData: string;
	templateId: number;
	shortcodes: object;
	data: object;
	template: string;
	mergeTag: MergeTagState[];
	undoStack = [];

	templateSettings$ = this.sosTemplateSettingsService.templateSettings$;
	mergeTags$ = this.kiwiSaverSettingsQuery.mergeTags$;
	isTapLevel = this.userQuery.isTapLevel();
	scopeOfService$ = this.sService.scopeOfService$;

	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(
		private kiwiSaverSettingsQuery: KiwiSaverSettingsQuery,
		private sosTemplateSettingsService: KiwiSosTemplateSettingsService,
		private sosSettingsService: KiwiScopeOfServiceSettingsService,
		private sService: KiwiScopeOfServiceService,
		private cd: ChangeDetectorRef,
		private userQuery: UserQuery,
	) {
		super();
	}

	ngOnInit(): void {
		combineLatest([this.mergeTags$, this.scopeOfService$])
			.pipe(
				map(([mt, sos]) => KiwiScopeOfServiceTemplateSettingsMapper.mapSosPreviewSettings(mt, sos)),
				map((mt) => sosTemplateSettingsMapper.filterMergeTagsToShow(mt)),
				map((mt) => MergeTagsMapper.mapMergeTags([...mt, ...koatSosMergeTags], true)),
				tap((x) => {
					this.mergeTag = x;
				}),
				map((mt) => MergeTagsMapper.mapTagsForWysiwyg(mt)),
				tap((mt) => (this.shortcodes = mt)),
				takeUntil(this.onDestroy$),
			)
			.subscribe(() => this.backToEdit());
		this.prepData();
	}

	prepData() {
		combineLatest([this.templateSettings$, this.kiwiSaverSettingsQuery.mergeTags$])
			.pipe(
				filter(([settings, mt]) => {
					return !!settings && !!mt;
				}),
				tap(([settings]) => {
					this.data = settings;
					this.templateId = +settings?.template;
				}),
				concatMap(([settings]) => this.sosSettingsService.getDocumentFromURL(settings?.templateLink)),
				tap((content) => {
					if (content) {
						this.currentTemplate = content;
					}
				}),
				take(1),
			)
			.subscribe();
	}

	hasTemplate = (template) => complement(either(isNil, isEmpty))(template) && !Number.isNaN(+template) && +template > 0;

	getUndoStack(event) {
		this.undoStack = event;
	}

	save() {
		this.isLoading = true;
		of(this.isReload)
			.pipe(
				concatMap((x) => iif(() => !!x, this.saveReloadTemplate(x), this.saveTemplate())),
				finalize(() => (this.isLoading = false)),
				take(1),
			)
			.subscribe();
	}

	saveReloadTemplate = (isReload: boolean) => {
		return of(this.undoStack).pipe(
			concatMap((undoStack) =>
				iif(
					() => isReload && isEmpty(undoStack),
					this.sosTemplateSettingsService
						.updateSettings({
							template: null,
							referenceId: 0,
							type: SettingsTypes.KOATSosTemplate,
						})
						.pipe(
							finalize(() => {
								this.isReload = false;
								this.templateId = null;
							}),
						),
					this.saveTemplate(),
				),
			),
		);
	};

	saveTemplate = () => {
		return of(this.editor?.content).pipe(
			mergeMap((curTmpl) => convertUtil.convertToBase64(curTmpl)),
			withLatestFrom(of(this.templateId)),
			map(([curTmpl, tmplId]) => sosTemplateSettingsMapper.mapDocumentUpload(curTmpl, tmplId)),
			concatMap((data) =>
				iif(
					() => isNil(data?.documentId) || Number.isNaN(data?.documentId) || data?.documentId === 0,
					this.sosSettingsService.newFileUpload(data),
					this.sosSettingsService.updateFileUpload(data),
				),
			),
			tap((data) => {
				this.templateId = this.hasTemplate(this.templateId) ? this.templateId : +data;
			}),
			withLatestFrom(of(this.templateId)),
			concatMap(([data, tmplId]) =>
				iif(
					() => this.hasTemplate(tmplId),
					of(tmplId),
					this.sosTemplateSettingsService.updateSettings({
						template: data,
						referenceId: 0,
						type: SettingsTypes.KOATSosTemplate,
					}),
				),
			),
			finalize(() => (this.isLoading = false)),
			take(1),
		);
	};

	reloadTemplate(event) {
		this.isLoading = true;
		this.sosTemplateSettingsService
			.getDefaultTemplateUrl()
			.pipe(
				concatMap((url: string) => this.sosSettingsService.getDocumentFromURL(url)),
				finalize(() => {
					this.isLoading = false;
					this.isReload = true;
					this.templateId = null;
					this.undoReset$.next(event);
				}),
				take(1),
			)
			.subscribe((data) => {
				if (data) {
					const template = this.isTapLevel ? data : `<div ${contentEditable.false}>${data}</div>`;
					this.currentTemplate = template;
					this.editor.content = template;
				}
			});
	}

	backToEdit() {
		this.currentTemplate = this.tempData ?? this.editor?.content;
		this.isPreview = false;
		this.disableToolbar(false);
		this.refresh();
	}

	showPreview() {
		this.isPreview = true;
		this.tempData = this.editor?.content;
		const getConvertedContent = getContentWithMergeTags(this.editor.content, this.mergeTag);
		this.currentTemplate = removeEmptyParagraphs(getConvertedContent);
		this.disableToolbar(true);
		this.refresh();
	}

	disableToolbar(disable: boolean) {
		$(`${componentName} .fr-element.fr-view, ${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css(
			'pointer-events',
			disable ? 'none' : 'unset',
		);
		$(`${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: disable ? 0.6 : 1,
		});
	}

	refresh() {
		this.cd.detectChanges();
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
