import { FactFindComputationState } from '../../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import { IncomeSourceState } from '../../../../../shared/models/client-review-template/income-budget/income-source-details.model';
import { numUtil, util } from '../../../../../util/util';
import { getTaxAcc } from './tax-and-acc';
import { sum, divide, subtract } from 'ramda';

// ==============================
//     Use this only on LOAT
// ==============================

export const computeAnnualTaxable = (factFind: FactFindComputationState) => {
  const annualTaxable = sum([
    (factFind && factFind.overallTotalGrossIncome) || 0,
    (factFind && factFind.totalNetRentalIncome) || 0,
    (factFind && factFind.totalAnnualIncome) || 0,
  ]);
  return +numUtil.formatToNumCurrency(annualTaxable);
};

export const computeMonthlyTaxable = (annualTaxable: number) => {
  return +numUtil.formatToNumCurrency(divide(+annualTaxable, 12));
};

export const computeMonthlyAfterTaxIncome = (
  incomeSource: IncomeSourceState[],
  factFind: FactFindComputationState,
  incomeList?
) => {
  // Compute TAX+ACC of All Income Earner
  const taxAccIncomeEarner = incomeSource?.reduce((acc, person) => {
    const totalGross = getTotalIndividualIncome(person, incomeList);
    const taxx = getTaxAcc(totalGross);
    return (acc += taxx);
  }, 0);


  // Compute TAX+ACC of Others;
  // From the owners of the property in the Net Rental Income
  const totalTaxAccOthers = computeTaxAccOfOthers(incomeList);

  // Compute Sum of TAX+ACC of Income Earners + Others in Net Rental Income Properties
  const totalTaxAcc = sum([+taxAccIncomeEarner, totalTaxAccOthers]);

  // Result is Monthly
  // Compute Monthly After Tax Income
  // (Annual Taxable Joint Income - Total TAX+ACC) / 12
  const getAfterTax = +subtract(
    factFind.annualTaxableJointIncome,
    totalTaxAcc
  );
  return +numUtil.formatToNumCurrency(divide(getAfterTax, 12));
};

export const computeTaxAccOfOthers = (incomeList) => {
  const incomeSource: IncomeSourceState[] = incomeList?.incomeSource || [];
  const netRental = incomeList?.rentalList || [];
  const properties = incomeList?.properties || [];
  const incomeEarners = incomeSource?.map((i) => +i.incomeEarner);

  // Compute the Net Rental Income of Other Owners of the property
  const netRentalOther = netRental?.reduce((a, c) => {
    const netRentalIncome = c?.netRentalIncome;
    const property = properties?.find((i) => +i?.cRTId === +c?.propertyAsset);
    const owners = util.tryCatchParse(property?.owner)
      ? JSON.parse(property?.owner)
      : [];
    const totalTaxAcc = owners?.reduce((acc, cur) => {
      const isIncomeEarner = incomeEarners.includes(+cur);
      const toDivide = owners.length;

      if (!isIncomeEarner) {
        const totalPerPerson = +divide(netRentalIncome, toDivide) || 0;
        const taxAccPerPerson = +getTaxAcc(totalPerPerson);
        return (acc += +taxAccPerPerson);
      }
      return acc;
    }, 0);

    return (a += +totalTaxAcc);
  }, 0);
  // Result is Annual
  return +netRentalOther;
};

export const getTotalIndividualIncome = (data, incomeList) => {
  const cRTId = data?.cRTId;
  const incomeEarners = (incomeList?.incomeSource || []).length;
  const netRental = incomeList?.rentalList || [];
  const otherIncome = incomeList?.otherList || [];
  const properties = incomeList?.properties || [];

  const getPropertyVal = (id, propId) => {
    const property = properties?.find((i) => +i?.cRTId === +propId);
    const isOwner = property?.owner?.includes(id);
    if (property && isOwner) {
      const owners = util.tryCatchParse(property?.owner)
        ? JSON.parse(property?.owner)
        : [];
      return owners?.length;
    }
    return 0;
  };

  if (cRTId) {
    // Compute the Total Gross Income of Individual
    const totalGross = data?.totalGrossIncome;

    // Compute the Net Rental Income of Individual
    // Divide Net Rental Income by the # of owners
    const totalNet = netRental?.reduce((a, c) => {
      const toDivide =
        getPropertyVal(+data?.incomeEarner, +c?.propertyAsset) || 0;
      const total = toDivide > 0 ? divide(c?.netRentalIncome, toDivide) : 0;
      return (a += total);
    }, 0);

    // Compute the Other Income of Individual
    // Divide the Total Income by the # of Income Earners
    let totalOther = otherIncome?.reduce((a, c) => a + +c?.annualIncome, 0);
    totalOther = divide(totalOther, incomeEarners);

    // Compute SUM:
    // Total Individual Income = Total Gross Income + Net Rental Income of Individual + Other Income of Individual
    // Result is Annual
    const totalIndvIncome = sum([totalGross, totalNet, totalOther]);
    return totalIndvIncome;
  }
  return 0;
};
