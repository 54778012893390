<!-- default form -->
<div #formProp class="pb-50px" appResponsive>
	<div class="row align-items-center mb-3">
		<div class="col-auto" #headerEl>
			<strong>{{
				groupName +
					(fg && fg.fGPolicyNumberSuffix ? " - " + fg.fGPolicyNumberSuffix : "")
			}}</strong>
		</div>
		<div class="col">
			<div class="d-flex flex-row bd-highlight justify-content-end">
				<span *ngIf="isSaving">
					<div class="d-flex flex-row bd-highlight justify-content-end">
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isSaving"
						></app-manual-loader-mini>
					</div>
				</span>

				<!-- Save Service -->
				<ng-container *appRestrictTo="['FES']">
					<div *ngIf="(isEditForm || addMode) && !isSaving">
						<span class="position-relative">
							<button
								type="submit"
								class="icon-btn"
								(click)="save(false)"
								id="fgSave_{{ formId }}"
								tabindex="26"
							>
								<i class="material-icons md-20 tap-text-color-h6"> save </i>
							</button>
						</span>
						<span class="position-relative">
							<button
								type="cancel"
								class="icon-btn"
								(click)="cancel()"
								id="fgCancel_{{ formId }}"
							>
								<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
							</button>
						</span>
					</div>
				</ng-container>

				<ng-container *ngIf="!addMode && !isEditForm && !isSaving">
					<div class="d-flex flex-row bd-highlight justify-content-end">
						<!-- Archive Service -->
						<ng-container *appRestrictTo="['FARS']">
							<span>
								<button
									*ngIf="fg.isActive === 1"
									class="icon-btn"
									type="button"
									(click)="archive(true)"
									id="fgArchive_{{ formId }}"
								>
									<i
										data-toggle="tooltip"
										title="archive"
										class="material-icons md-20 tap-text-color-h6 pt-1"
									>
										archive
									</i>
								</button>
								<button
									*ngIf="fg.isActive !== 1"
									class="icon-btn"
									type="button"
									(click)="archive(false)"
									id="fgUnarchive_{{ formId }}"
								>
									<i
										data-toggle="tooltip"
										title="unarchive"
										class="material-icons md-20 tap-text-color-h6 pt-1"
									>
										unarchive
									</i>
								</button>
							</span>
						</ng-container>

						<!-- Edit Service -->
						<ng-container *appRestrictTo="['FES']">
							<span>
								<button
									data-toggle="tooltip"
									title="edit"
									class="icon-btn"
									type="button"
									(click)="editForm(true); fgTapCollapse?.show()"
									id="fgEdit_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6 pt-1">
										edit
									</i>
								</button>
							</span>
						</ng-container>

						<!-- Delete Service -->
						<ng-container *appRestrictTo="['FDS']">
							<span>
								<button
									data-toggle="tooltip"
									title="delete"
									class="btn btn-link p-1 ng-star-inserted"
									type="button"
									(click)="deleteConfirm()"
									id="fgDelete_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">  </i>
								</button>
							</span>
						</ng-container>
					</div>
				</ng-container>

				<span>
					<button
						data-toggle="tooltip"
						class="btn btn-link p-1 ng-star-inserted"
						(click)="fgTapCollapse?.toggle()"
					>
						<i
							class="material-icons md-20 tap-text-color-h6"
							title="Collapse"
							*ngIf="fgTapCollapse?.visible; else expandLessTemplate"
						>
							expand_less
						</i>
						<ng-template #expandLessTemplate>
							<i class="material-icons md-20 tap-text-color-h6">
								expand_more
							</i>
						</ng-template>
					</button>
				</span>
			</div>
		</div>
	</div>

	<form
		novalidate
		[formGroup]="form"
		class="pb-3"
		*ngIf="
			(addMode || (fg && fg.tracking !== 'MTA') || isAddNewRenewal) &&
			!(addMode && fg && !isAddNewRenewal)
		"
	>
		<div
			class="container-fluid clearfix px-0"
			[class.archived]="fg && fg.isActive ? fg.isActive === 2 : false"
		>
			<!-- First Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12">
							<label for="">Policy Lines</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="policyLines"
								[choices]="policyLines"
								id="policyLines_{{ formId }}"
								tabindex="7"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="fGPolicyNumber.invalid"
								>*</span
							>
							<label for="">Policy Number</label>
						</div>
						<div class="col-lg-6 col-12 form-inline">
							<input
								type="text"
								formControlName="fGPolicyNumber"
								class="form-control theme-crm-field-disabled w-59"
								placeholder="- -"
								id="policyNumber_{{ formId }}"
								tabindex="15"
							/>
							<span class="p-1 font-weight-bold text-dark">-</span>
							<input
								type="text"
								formControlName="fGPolicyNumberSuffix"
								placeholder="- -"
								class="form-control theme-crm-field-disabled px-2 w-30"
								id="fGPolicyNumberSuffix_{{ formId }}"
								tabindex="16"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Premium</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="premium"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="premium_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="21"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Second Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12">
							<label for="">Policy Owners</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="policyOwners"
								[choices]="policyOwnersDropdown"
								id="policyOwners_{{ formId }}"
								tabindex="8"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Quote Number</label>
						</div>
						<div class="col-lg-6 col-12">
							<input
								type="text"
								formControlName="quoteNumber"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="quoteNumber_{{ formId }}"
								tabindex="17"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Broker Fee</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="brokerFee"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="brokerFee_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="22"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Third Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="status.invalid">*</span>
							<label for="">Status</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="status"
									class="form-control theme-crm-field-disabled"
									id="fgstatus_{{ formId }}"
									tabindex="9"
								>
									<option value=""></option>
									<option
										*ngFor="let s of statuses; trackBy: trackByValue"
										[value]="s.value"
									>
										{{ s.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="insurer.invalid">*</span>
							<label for="">Insurer</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="insurer"
									class="form-control theme-crm-field-disabled"
									id="insurer_{{ formId }}"
									tabindex="12"
								>
									<option value=""></option>
									<option
										*ngFor="let i of insurers; trackBy: trackByValue"
										[value]="i.value"
									>
										{{ i.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Issue Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#issueDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="issueDate"
								id="issueDate_{{ formId }}"
								tabindex="18"
							>
							</app-date-input>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Admin Fee</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="adminFee"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="adminFee_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="22"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>

			<app-tap-collapse [visible]="false">
				<!-- Fourth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Account Status</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="accountStatus"
										class="form-control theme-crm-field-disabled"
										id="accountStatus_{{ formId }}"
										tabindex="10"
									>
										<option value=""></option>
										<option
											*ngFor="let p of accountStatus; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Policy Writer</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="policyWriter"
										class="form-control theme-crm-field-disabled"
										id="policyWriter_{{ formId }}"
										tabindex="13"
									>
										<option value=""></option>
										<option
											*ngFor="let p of policyWriters; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Inception Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#inceptionDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="inceptionDate"
									id="inceptionDate_{{ formId }}"
									tabindex="18"
								>
								</app-date-input>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Total Premium</label>
							</div>
							<div class="col-lg-6 col-12">
								<div class="dollar-icon">
									<input
										readonly
										type="text"
										formControlName="totalPremium"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										id="totalPremium_{{ formId }}"
										appTwoDigitDecimalNumber
									/>
									<i>$</i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Fifth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Payment Method</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="paymentMethod"
										class="form-control theme-crm-field-disabled"
										id="paymentMethod_{{ formId }}"
										tabindex="11"
									>
										<option value=""></option>
										<option
											*ngFor="let p of paymentMethods; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Policy Type</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="policyType"
										class="form-control theme-crm-field-disabled"
										id="policyType_{{ formId }}"
										tabindex="11"
									>
										<option value=""></option>
										<option
											*ngFor="let p of policyTypes; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<!-- <div class="col-lg-3 col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-6 col-12">
                <label for="">Claim Status</label>
              </div>
              <div class="col-lg-6 col-12">
                <span class="custom-dropdown w-100">
                  <select
                      formControlName="claimStatus"
                      class="form-control theme-crm-field-disabled"
                      id="claimStatus_{{ formId }}"
                      tabindex="14"
                      >
                      <option value=""></option>
                      <option *ngFor="let p of claimStatus" [value]="p.value">
                      {{ p.display }}
                      </option>
                  </select>
                </span>
              </div>
            </div>
          </div> -->
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Renewal Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#renewalDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="renewalDate"
									id="renewalDate_{{ formId }}"
									tabindex="19"
								>
								</app-date-input>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">First Policy Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#firstPolicyDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="firstPolicyDate"
									id="firstPolicyDate_{{ formId }}"
									tabindex="23"
									[disabled]="isAddNewRenewal"
								>
								</app-date-input>
							</div>
						</div>
					</div>
				</div>
				<!-- Sixth -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Frequency</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="frequency"
										class="form-control theme-crm-field-disabled"
										id="frequency_{{ formId }}"
										tabindex="14"
									>
										<option value=""></option>
										<option
											*ngFor="let p of frequencies; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Claim Status</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="claimStatus"
										class="form-control theme-crm-field-disabled"
										id="claimStatus_{{ formId }}"
										tabindex="14"
									>
										<option value=""></option>
										<option
											*ngFor="let p of claimStatus; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<span class="tap-required-field" *ngIf="originalAdviser.invalid"
									>*</span
								>
								<label for="">Original Adviser</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										tabindex="20"
										[class.disabled]="
											addMode
												? false
												: (hasPermission(['FEO']) | async)
												? false
												: true
										"
										formControlName="originalAdviser"
										class="form-control theme-crm-field-disabled"
										id="originalFgAdvisers_{{ formId }}"
										tabindex="19"
									>
										<option value=""></option>
										<option
											*ngFor="let c of originalAdvisers$ | async"
											[value]="c.value"
										>
											{{ c.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Policy End Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#policyEndDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="policyEndDate"
									id="cancellationDate_{{ formId }}"
									tabindex="24"
								>
								</app-date-input>
							</div>
						</div>
					</div>
				</div>
				<!-- Seventh Row -->
				<ng-container
					*featureToggle="{
						id: 'FGUpdateV1',
						show: true
					}"
				>
					<div class="form-row clearfix align-items-center mb-2">
						<div class="col-lg-3 col-12" *ngIf="fg?.tracking === 'Renewal' || isAddNewRenewal">
							<div class="form-row align-items-center">
								<div class="col-lg-6 col-12">
									<label for="">Auto-Renewal</label>
								</div>
								<div class="col-lg-6 col-12">
									<input
										class="chckbox form-control theme-crm-field-disabled"
										formControlName="autoRenewal"
										type="checkbox"
										id="autoRenewal_{{ formId }}"
										tabindex="24.1"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-12">
							<div class="form-row align-items-center">
								<div class="col-lg-6 col-12">
									<label for="">Cancellation Reason</label>
								</div>
								<div class="col-lg-6 col-12">
									<span class="custom-dropdown w-100">
										<select
											formControlName="cancellationReason"
											class="form-control theme-crm-field-disabled"
											id="cancellationReason_{{ formId }}"
											tabindex="24.2"
										>
											<option value=""></option>
											<option
												*ngFor="
													let p of cancellationReason;
													trackBy: trackByValue
												"
												[value]="p.value"
											>
												{{ p.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-12"></div>
					</div>
				</ng-container>

				<div class="form-row clearfix align-items-center mb-2 pt-2">
					<div class="col">
						<label for="exampleFormControlTextarea1">Required</label>
						<textarea
							class="form-control theme-crm-field-disabled mt-2"
							formControlName="required"
							rows="4"
							id="required_{{ formId }}"
							tabindex="25"
						></textarea>
					</div>
				</div>
				<ng-container *ngIf="addMode">
					<div class="form-row clearfix align-items-center pt-2">
						<div class="col-lg">
							<label for="note{{ formId }}">Add a note</label>
							<textarea
								class="form-control theme-crm-field-disabled mt-2"
								formControlName="note"
								rows="4"
								id="note_{{ formId }}"
								tabindex="26"
							></textarea>
						</div>
					</div>
				</ng-container>
				<!-- Eigth Row -->
				<ng-container *ngIf="claimsFeature">
					<ng-container *ngIf="fGClaims && fGClaims.length > 0">
						<div class="row clearfix align-items-center py-2">
							<div class="col-lg d-flex align-items-center">
								<strong class="mr-3">Claims</strong>
							</div>
						</div>
						<div class="row clearfix align-items-center">
							<div class="col-lg col-12">
								<app-table-paginate-claims
									id="claims_{{ formId }}"
									[claims]="fGClaims"
									[itemsPerPage]="5"
									[redirectToAP$]="redirectToAP$"
									[claimsType]="apProcessCodes.FGClaim"
								>
								</app-table-paginate-claims>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="!addMode">
					<br />
					<div class="form-row clearfix align-items-center py-2">
						<div class="col-lg d-flex align-items-center">
							<strong class="mr-3">Notes</strong>
							<ng-container *ngIf="isLead">
								<button
									*appRestrictTo="['FALN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openModalAddNote()"
									id="fgAddNote_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<ng-container *ngIf="emailClientTemplateFeature$ | async">
									<button
										*appRestrictTo="['FALN']"
										type="button"
										class="icon-btn h-auto"
										[disabled]="isSaving"
										(click)="openEmailPopup()"
										id="fgOpenEmailClient_{{ formId }}"
									>
										<i class="material-icons md-20 tap-text-color-h6">email</i>
									</button>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="!isLead">
								<button
									*appRestrictTo="['FAN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openModalAddNote()"
									id="fgAddNote_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<ng-container *ngIf="emailClientTemplateFeature$ | async">
									<button
										*appRestrictTo="['FAN']"
										type="button"
										class="icon-btn h-auto"
										[disabled]="isSaving"
										(click)="openEmailPopup()"
										id="fgOpenEmailClient_{{ formId }}"
									>
										<i class="material-icons md-20 tap-text-color-h6">email</i>
									</button>
								</ng-container>
							</ng-container>
						</div>
					</div>
					<div class="form-row clearfix align-items-center mb-2">
						<div class="col-lg col-12">
							<app-table-paginate-notes
								id="fgNote_{{ formId }}"
								[isService]="true"
								[isLead]="isLead"
								[notes]="notes"
								[itemsPerPage]="5"
								[deleteFn$]="deleteNote$"
								[showHeader]="false"
							>
							</app-table-paginate-notes>
						</div>
					</div>
				</ng-container>
			</app-tap-collapse>
		</div>
	</form>

	<form
		novalidate
		[formGroup]="form"
		class="pb-3"
		*ngIf="
			(addMode && fg && !isAddNewRenewal) ||
			(fg && fg.tracking === 'MTA' && !isAddNewRenewal)
		"
	>
		<div
			class="container-fluid clearfix px-0"
			[class.archived]="fg && fg.isActive ? fg.isActive === 2 : false"
		>
			<!-- First Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12">
							<label for="">Policy Lines</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="policyLines"
								[choices]="policyLines"
								id="policyLines_{{ formId }}"
								tabindex="7"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="fGPolicyNumber.invalid"
								>*</span
							>
							<label for="">Policy Number</label>
						</div>
						<div class="col-lg-6 col-12 form-inline">
							<input
								type="text"
								formControlName="fGPolicyNumber"
								class="form-control theme-crm-field-disabled w-59"
								placeholder="- -"
								id="policyNumber_{{ formId }}"
								tabindex="8"
							/>
							<span class="p-1 font-weight-bold text-dark">-</span>
							<input
								type="text"
								formControlName="fGPolicyNumberSuffix"
								placeholder="- -"
								class="form-control theme-crm-field-disabled px-2 w-30"
								id="fGPolicyNumberSuffix_{{ formId }}"
								tabindex="9"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Premium</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="premium"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="premium_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="10"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Second Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="status.invalid">*</span>
							<label for="">Status</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="status"
									class="form-control theme-crm-field-disabled"
									id="fgstatus_{{ formId }}"
									tabindex="11"
								>
									<option value=""></option>
									<option
										*ngFor="let s of statuses; trackBy: trackByValue"
										[value]="s.value"
									>
										{{ s.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Claim Status</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="claimStatus"
									class="form-control theme-crm-field-disabled"
									id="claimStatus_{{ formId }}"
									tabindex="12"
								>
									<option value=""></option>
									<option
										*ngFor="let p of claimStatus; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Quote Number</label>
						</div>
						<div class="col-lg-6 col-12">
							<input
								type="text"
								formControlName="quoteNumber"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								id="quoteNumber_{{ formId }}"
								tabindex="13"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Broker Fee</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="brokerFee"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="brokerFee_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="14"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Third Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<span class="tap-required-field" *ngIf="originalAdviser.invalid"
								>*</span
							>
							<label for="">Original Adviser</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									tabindex="15"
									[class.disabled]="
										addMode
											? false
											: (hasPermission(['FEO']) | async)
											? false
											: true
									"
									formControlName="originalAdviser"
									class="form-control theme-crm-field-disabled"
									id="originalFgAdvisers_{{ formId }}"
								>
									<option value=""></option>
									<option
										*ngFor="let c of originalAdvisers$ | async"
										[value]="c.value"
									>
										{{ c.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Account Status</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="accountStatus"
									class="form-control theme-crm-field-disabled"
									id="accountStatus_{{ formId }}"
									tabindex="16"
								>
									<option value=""></option>
									<option
										*ngFor="let p of accountStatus; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Inception Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#inceptionDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="inceptionDate"
								id="inceptionDate_{{ formId }}"
								tabindex="17"
							>
							</app-date-input>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12">
							<label for="">Admin Fee</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="adminFee"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="adminFee_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="18"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<app-tap-collapse [visible]="false">
				<!-- Fourth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<ng-container
						*featureToggle="{
							id: 'FGUpdateV1',
							show: true
						}"
					>
						<div class="col-lg-3 col-12">
							<div class="form-row align-items-center">
								<div class="col-lg-6 col-12">
									<label for="">Cancellation Reason</label>
								</div>
								<div class="col-lg-6 col-12">
									<span class="custom-dropdown w-100">
										<select
											formControlName="cancellationReason"
											class="form-control theme-crm-field-disabled"
											id="cancellationReason_{{ formId }}"
											tabindex="19"
										>
											<option value=""></option>
											<option
												*ngFor="
													let p of cancellationReason;
													trackBy: trackByValue
												"
												[value]="p.value"
											>
												{{ p.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
					</ng-container>

					<div class="col-lg-3 col-12" [class.offset-lg-3]="!fGUpdateV1Feature">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Issue Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#issueDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="issueDate"
									id="issueDate_{{ formId }}"
									tabindex="20"
								>
								</app-date-input>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Policy End Date</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#cancellationDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="policyEndDate"
									id="cancellationDate_{{ formId }}"
									tabindex="21"
								>
								</app-date-input>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Total Premium</label>
							</div>
							<div class="col-lg-6 col-12">
								<div class="dollar-icon">
									<input
										readonly
										type="text"
										formControlName="totalPremium"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										id="totalPremium_{{ formId }}"
										appTwoDigitDecimalNumber
									/>
									<i>$</i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Fifth Row -->
				<div class="form-row clearfix align-items-center mb-2 pt-2">
					<div class="col">
						<label for="exampleFormControlTextarea1">Required</label>
						<textarea
							class="form-control theme-crm-field-disabled mt-2"
							formControlName="required"
							rows="4"
							id="required_{{ formId }}"
							tabindex="22"
						></textarea>
					</div>
				</div>
				<ng-container *ngIf="addMode">
					<div class="form-row clearfix align-items-center pt-2">
						<div class="col-lg">
							<label for="note{{ formId }}">Add a note</label>
							<textarea
								class="form-control theme-crm-field-disabled mt-2"
								formControlName="note"
								rows="4"
								id="note_{{ formId }}"
								tabindex="23"
							></textarea>
						</div>
					</div>
				</ng-container>
				<!-- Sixth Row -->
				<ng-container *ngIf="claimsFeature">
					<ng-container *ngIf="fGClaims && fGClaims.length > 0">
						<div class="row clearfix align-items-center py-2">
							<div class="col-lg d-flex align-items-center">
								<strong class="mr-3">Claims</strong>
							</div>
						</div>
						<div class="row clearfix align-items-center">
							<div class="col-lg col-12">
								<app-table-paginate-claims
									id="claims_{{ formId }}"
									[claims]="fGClaims"
									[itemsPerPage]="5"
									[redirectToAP$]="redirectToAP$"
									[claimsType]="apProcessCodes.FGClaim"
								>
								</app-table-paginate-claims>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="!addMode">
					<br />
					<div class="form-row clearfix align-items-center py-2">
						<div class="col-lg d-flex align-items-center">
							<strong class="mr-3">Notes</strong>
							<ng-container *ngIf="isLead">
								<button
									*appRestrictTo="['FALN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openModalAddNote()"
									id="fgAddNote_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<button
									*appRestrictTo="['FALN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openEmailPopup()"
									id="fgMTAOpenEmailClient_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">email</i>
								</button>
							</ng-container>
							<ng-container *ngIf="!isLead">
								<button
									*appRestrictTo="['FAN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openModalAddNote()"
									id="fgAddNote_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<button
									*appRestrictTo="['FAN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openEmailPopup()"
									id="fgMTAOpenEmailClient_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">email</i>
								</button>
							</ng-container>
						</div>
					</div>
					<div class="form-row clearfix align-items-center mb-2">
						<div class="col-lg col-12">
							<app-table-paginate-notes
								id="fgNote_{{ formId }}"
								[isService]="true"
								[isLead]="isLead"
								[notes]="notes"
								[itemsPerPage]="5"
								[deleteFn$]="deleteNote$"
								[showHeader]="false"
							>
							</app-table-paginate-notes>
						</div>
					</div>
				</ng-container>
			</app-tap-collapse>
		</div>
	</form>
</div>
