<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
      close
  </span>
</button>
<div class="modal-header">
  <h1 class="modal-title">
    {{ header }}
  </h1>
</div>

<div *ngIf="canTransfer" class="align-text modal-body text-center" style="word-break: break-all;">
  <p [innerHTML]="message"></p>
</div>
<div *ngIf="!canTransfer" class="modal-body align-text text-left">
  <p [innerHTML]="message"></p>
</div>
<div class="modal-footer">
  <button
    *ngIf="confirmation"
    id="declineButton"
    type="button"
    class="tap-btn tap-btn--default"
    (click)="decline()
  ">
    Okay
  </button>

  <ng-container *ngIf="!confirmation">
    <button id="transferButton" type="button" class="tap-btn tap-btn--default mr-2" [disabled]="!canTransfer"
      (click)="transfer()">{{ confirmButton || 'Transfer' }}</button>
    <button id="declineButton" type="button" class="tap-btn tap-btn--default" (click)="decline()">Cancel</button>
  </ng-container>
</div>
