import * as moment from 'moment';
import { Moment } from 'moment';
import 'moment-timezone';
import { Injectable } from '@angular/core';
import { staticConf } from '../config/static-config.service';
import { Observable } from 'rxjs';
import { environment as env } from '@environment';
import { data } from 'jquery';

@Injectable()
export class UtilService {
  constructor() { }
  // tslint:disable-next-line: ban-types
  static fromJson(response: string | Object) {
    const data = JSON.stringify(response);
    if (typeof response === 'string') {
      return JSON.parse(data);
    } else {
      return response;
    }
  }
  static toUrl(...endpoint: string[]): string {
    return `${env.apiProtocol}//${endpoint?.join('/')}`;
  }
  static toPair(id: number | string, value: string): [string, string] {
    return [id?.toString(), value];
  }
  static getNewId(...ids: number[]) {
    return Math.max(0, ...ids?.filter((x) => !isNaN(x))) + 1;
  }
  static prepareDataList<T>(data: T[]): T[] {
    const dataClone = data?.map((dataItem) => {
      const dataItemClone = Object.assign({}, dataItem);
      Object.keys(dataItemClone)?.forEach((cloneKey) => {
        if (Array.isArray(dataItemClone[cloneKey])) {
          dataItemClone[cloneKey] = (dataItemClone[cloneKey] as Array<any>)?.map(
            (item) => {
              if (typeof item === typeof {}) {
                return Object.assign({}, item);
              } else {
                return item;
              }
            }
          );
        }
      });
      return dataItemClone;
    });
    return dataClone;
  }
  static toBool(prop: string): boolean {
    return prop ? prop?.toString().trim().toLowerCase() === 'true' : false;
  }
  static toStringArray(data: string): string[] {
    const parsed = this.isJsonString(data);
    if (parsed) {
      if (parsed instanceof Array) {
        return parsed;
      } else {
        return [parsed?.toString()];
      }
    }
    return [data.toString()];
  }
  // tslint:disable-next-line: ban-types
  static isJsonString(data: string): Object | Array<any> | boolean {
    try {
      const o = JSON.parse(data);
      if (o && typeof o === typeof {}) {
        return o;
      }
    } catch (e) { }
    return false;
  }
  static filterNumbers(data: string): number {
    const str = data || '';
    const result = str
      ?.split('')
      ?.filter(
        (x, i) =>
          (!isNaN(Number(x)) && x !== ' ') ||
          (x === '.' && str?.indexOf('.') === i) ||
          (x === '-' && i === 0)
      )
      ?.join('');
    return +result;
  }
  static asTuple<T, T1>(x: T, y: T1) {
    return [x, y] as [T, T1];
  }

  // --------------------------------------------------------------------- NEW BATCH
  /**
   * use to make a moment from date only string from server
   * Currently unused as the old endpoints converts to date before returning
   * @param date date expected to be in serverDateFormat format
   * @returns Moment object
   */
  static DateStringToMoment(date: string): Moment {
    return moment(date, staticConf.serverDateFormat, true);
  }
  /**
   * use to make a moment from date only string from server (Loose meaning it expects it might not totally adhere to format)
   * @param date date expected to be in serverDateFormat format
   * @returns Moment object
   */
  static DateStringToMomentLoose(date: string): Moment {
    return moment(date, staticConf.serverDateFormat, false);
  }
  /**
   * use to make a moment from datetime string from server
   * Currently unused as the old endpoints converts to date (which is not ISO) before returning
   * @param date date expected to be in serverDatetimeFormat format
   * @returns Moment object
   */
  static DatetimeStringToMoment(date: string): Moment {
    return moment(date, staticConf.serverDatetimeFormat, true);
  }
  /**
   * use to make a moment from datetime string from server (Loose meaning it expects it might not totally adhere to format)
   * @param date date expected to be in serverDateFormat format
   * @returns Moment object
   */
  static DatetimeStringToMomentLoose(date: string): Moment {
    return moment(date, staticConf.serverDatetimeFormat);
  }
  static DateAndTimeStringToMomentLoose(date: string, time: string): Moment {
    const dateParse = this.DatetimeStringToMomentLoose(date);
    if (time && this.isValidTime(time)) {
      dateParse.set({
        hour: this.StringToTime(time)[0],
        minute: this.StringToTime(time)[1],
      });
    }
    return dateParse;
  }

  /**
   * use to make a date only string from moment object
   * @param mom moment object to be converted to date string
   * @returns date string
   */
  static MomentToDateString(mom: Moment): string {
    if (this.isValidMoment(mom)) {
      return mom.format(staticConf.serverDateFormat);
    }
    return '';
  }
  /**
   * use to make a datetime string from moment object
   * @param mom moment object to be converted to datetime string
   * @returns datetime string
   */
  static MomentToDatetimeString(mom: Moment): string {
    if (this.isValidMoment(mom)) {
      return mom.format(staticConf.serverDatetimeSecondFormat);
    }
    return '';
  }
  /**
   * converts moment to date string DISPLAY
   * @param mom moment object to be converted to date string for display
   * @returns date string for DISPLAY
   */
  static MomentToDateStringDisplay(mom: Moment): string {
    if (this.isValidMoment(mom)) {
      return mom.format(staticConf.displayDateFormat);
    }
    return '';
  }
  /**
   * converts moment to datetime string DISPLAY
   * @param mom moment object to be converted to datetime string for display
   * @returns datetime string for DISPLAY
   */
  static MomentToDatetimeStringDisplay(mom: Moment): string {
    if (this.isValidMoment(mom)) {
      return mom.format(staticConf.displayDatetimeFormat);
    }
    return '';
  }
  // ----------------------------------------- use iso format end
  static StringToTime(time: string): [number, number] {
    const timeParse = moment(time, ['h:m a', 'H:m']);
    return [timeParse.get('hour'), timeParse.get('minute')];
  }
  static isValidTime(time: string): boolean {
    return moment(time, ['h:m a', 'H:m']).isValid();
  }
  static isValidMoment(mom: Moment | any): mom is Moment {
    return moment.isMoment(mom) && mom.isValid();
  }
  static emptyMoment(): Moment {
    return moment.invalid();
  }
  static MomentNow(): Moment {
    return moment();
  }
  static MomentNowNz(): Moment {
    return moment.tz(staticConf.momentTimezone);
  }
  static isWithin(targetDate: Moment, frequency: number, unit: 'days' | 'months' | 'years'): boolean {
    const currentDate = this.MomentNow();
    const dateTo = currentDate.clone().add(frequency, unit);

    const isSameOrAfter =  targetDate.isSameOrAfter(currentDate, 'days');
    const isBefore = targetDate.isBefore(dateTo);

    return isSameOrAfter && isBefore;
  }
  // tslint:disable-next-line: member-ordering
  static now$ = new Observable<Moment>((obs) => {
    obs.next(util.MomentNow());
    obs.complete();
  });
  // tslint:disable-next-line: member-ordering
  static nowTz$ = new Observable<Moment>((obs) => {
    obs.next(util.MomentNowNz());
    obs.complete();
  });
  static ignoreTimezone(m: Moment): Moment {
    if (moment.isMoment(m)) {
      return moment(m.clone().format('YYYY-MM-DDTHH:mm'));
    } else {
      this.emptyMoment();
    }
  }

  static toTz(m: Moment, adjustTime: boolean = true): Moment {
    const dateLocal = m.clone();
    const dateTz = dateLocal.clone().tz(staticConf.momentTimezone);
    if (adjustTime) {
      return dateTz;
    } else {
      const offsetLocal = dateLocal.utcOffset();
      const offsetTz = dateTz.utcOffset();
      const offsetDifference = offsetLocal - offsetTz;
      return dateTz.clone().add(offsetDifference, 'm');
    }
  }
  // static
  static DateStringToDateStringDisplay(date: string): string {
    const dateParse = this.DateStringToMomentLoose(date);
    return this.isValidMoment(dateParse)
      ? this.MomentToDateStringDisplay(dateParse)
      : '';
  }
  static DatetimeStringToDatetimeStringDisplay(date: string): string;
  static DatetimeStringToDatetimeStringDisplay(
    date: string,
    // tslint:disable-next-line: unified-signatures
    time: string
  ): string;
  static DatetimeStringToDatetimeStringDisplay(
    date: string,
    time?: string
  ): string {
    // let dateParse = this.DatetimeStringToMomentLoose(date);
    // if (time && this.isValidTime(time))
    //   dateParse.set({
    //     hour: this.StringToTime(time)[0],
    //     minute: this.StringToTime(time)[1]
    //   });
    const dateParse = this.DateAndTimeStringToMomentLoose(date, time);
    return this.isValidMoment(dateParse)
      ? this.MomentToDatetimeStringDisplay(dateParse)
      : '';
  }

  static concatDateTime(date: Moment, time: string): Moment {
    if (!moment.isMoment(date)) {
      return null;
    }
    const timeparse = this.StringToTime(time);
    return date.clone().hour(timeparse[0]).minute(timeparse[1]);
  }

  static Pluck<T, K extends keyof T>(o: T, ...names: K[]): Pick<T, K> {
    return names.reduce((p, c) => {
      p[c] = o[c];
      return p;
    }, {} as Pick<T, K>);
  }
  // static PluckExcept<T, K extends keyof T>(
  //   o: T,
  //   ...names: K[]
  // ): Omit<T, Exclude2<keyof T, K>> {
  //   let n: Omit<T, Exclude2<keyof T, K>>;
  //   for (let a in o) {
  //     if (names.some(x => x === a)) continue;
  //     n = Object.assign(n || ({} as any), { [a]: o[a] });
  //   }
  //   return n;
  // }

  static removeIf = <T>(arr: T[], expression: (x: T) => boolean): T[] => {
    const res: T[] = [];

    for (const o of arr) {
      if (!expression(o)) { res.push(o); }
    }
    return res;
  };
  static replaceIf = <T>(
    arr: T[],
    expression: (x: T) => boolean,
    replacement: T
  ): T[] => {
    const res: T[] = [];
    for (const o of arr) {
      if (!expression(o)) { res.push(o); }
      else { res.push(replacement); }
    }
    return res;
  };

  static AddNTime(date, amount, duration) {
    // return date.duration(time, duration);
    return date.add(amount, duration)
  }
}

export const util = UtilService;
// type Diff<T extends string, U extends string> = ({ [P in T]: P } &
//   { [P in U]: never } & { [x: string]: never })[T];
// type Omit<T, K extends keyof T> = Pick<T, Diff<keyof T, K>>;
type Omit<T, K extends keyof T> = T extends any
  ? Pick<T, Exclude2<keyof T, K>>
  : never;
export type Exclude2<T, U> = T extends U ? never : T;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
