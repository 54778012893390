import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';

@Component({
	selector: 'app-people-tabs',
	templateUrl: './people-tabs.component.html',
	styleUrls: ['./people-tabs.component.scss'],
})
export class PeopleTabsComponent implements OnInit {

	@Input() people$: Observable<PeopleState[]>;
	@Input() selectedCrt: number;
	@Output() selectCrtFromTab = new EventEmitter<number>();

	constructor() {}

	ngOnInit(): void {}

	clickPeople(id: number) {
		this.selectCrtFromTab.emit(id);
	}
}
