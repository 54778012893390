import { ApplicationDocument, downloadable } from './documents.model';

export class ApplicationDocumentMapper {
	public static mapBankDocuments(
		data: ApplicationDocument[],
		bank: string,
		bankFiles: string[]
	) {
		return data?.map((item) => {
			if (bankFiles?.includes(item?.document)) {
				const isAnzDocument =
					bank?.toLocaleLowerCase() === 'anz' &&
					item?.document === downloadable.bankFiles.ANZ.toString();
				const isWestPacDocument =
					bank?.toLocaleLowerCase() === 'westpac' &&
					item?.document === downloadable.bankFiles.WestPac.toString();

				if (isAnzDocument || isWestPacDocument) {
					return {
						...item,
						show: true,
					};
				}
				return {
					...item,
					combine: false,
					show: false,
				};
			}
			return {
				...item,
				show: true,
			};
		});
	}
}
