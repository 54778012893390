import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentComponent } from './document.component';

import { DocumentPageResolver } from './document.resolver';

const routes: Routes = [
  {
    path: '',
    component: DocumentComponent,
    resolve: {
      data: DocumentPageResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule { }
