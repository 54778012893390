import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CRTMortgageNotesState, CrtMortgageNoteStore } from './crt-mortgage-note.store';
@Injectable()
export class CrtMortgageNoteQuery extends QueryEntity<CRTMortgageNotesState> {
  crtNotesFactFind$ = this.selectAll({
    sortBy: (a, b) =>
      new Date(b.createDateTime).valueOf() -
      new Date(a.createDateTime).valueOf(),
  });

  activeType$ = this.select((state) => state.activeType);
  crtTypes$ = this.select((x) => x.crtNoteTypes);

  constructor(protected store: CrtMortgageNoteStore) {
    super(store);
  }
}
