import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { objectUtil } from '../../../../../util/util';
import {
	ClientFactFindSettingState,
	DocumentUploadState,
} from './client-fact-find-template-settings.model';
import { DocumentModelState } from '../../../../../shared/models/documents/document.model';
import { MortgageSettingsStore } from '../../../state/mortgage-settings.store';
import { MortgageSettingsQuery } from '../../../state/mortgage-settings.query';
import { ReloadDocumentType } from '../../../state/mortgage-settings.model';

@Injectable()
export class ClientFactFindSettingsService {
	clientFactFindDocuments$ = this.query.clientFactFindSettings$;

	constructor(
		private api: ApiService,
		private store: MortgageSettingsStore,
		private query: MortgageSettingsQuery
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getClientFactFindDocument(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<ClientFactFindSettingState>(endpoint).pipe(
			tap((res) =>
				applyTransaction(() => {
					const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
					this.store.setClientFactFindSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateClientFactFind(data: ClientFactFindSettingState) {
		const endpoint = `crt/settings/${data.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<ClientFactFindSettingState>(endpoint, body).pipe(
			tap((res) =>
				applyTransaction(() => {
					this.store.setClientFactFindSettings(res);
				})
			)
		);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getClientFactFindDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(
			`${url}?p=${Math.floor(Date.now() / 1000)}`,
			{ responseType: 'text' }
		);
	}

	getDefaultTemplateUrl() {
		const endpoint = `crt/oat-template/${ReloadDocumentType.ClientFactFind}`;
		return this.api.get<string>(endpoint);
	}
}
