// Default Options for all CRM
export const fgDefaultStatusColors = {
	green: ['inforce'],
	yellow: [
		'lead',
		'contact made',
		'info requested',
		'info received',
		'quoted',
		'underwriting',
		'offer of terms',
		'policy accepted',
	],
	red: ['closed/renewed', 'cancelled'],
	grey: ['ntu'],
};

// Custom Options For CRM Blanket
export const fgStatusColorsBlanket = {
	green: ['inforce'],
	yellow: [
		'quoting',
		'info requested',
		'quote presented',
		'underwriting',
		'policy issued',
	],
	red: ['closed/renewed', 'cancelled'],
	grey: ['ntu', 'referral on hold'],
};

export const fgOrderListBlanket = [
	'inforce',
	'quoting',
	'info requested',
	'quote presented',
	'underwriting',
	'policy issued',
	'closed/renewed',
	'cancelled',
	'ntu',
	'referral on hold',
];

export const fgPipelinePreset = [
	'Quoting',
	'Info Requested',
	'Quote Presented',
	'Underwriting',
	'Policy Issued',
];
