import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FGPremiumStatsDomesticState, FGPremiumStatsDomesticStore } from './fgpsd.store';

/**
 * Query for FG Premium Stats Domestic widget data for Rollling 12 Months (Year)
 */
@Injectable()
export class FGPremiumStatsDomesticQuery extends Query<FGPremiumStatsDomesticState> {

  /** FG Premium Stats Domestic data. */
  FGPremiumStatsDomesticData$ = this.select(state =>
    state.FGPremiumStatsDomestic
  );

  /** Indicator if FGPremiumStatsDomestic is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for FGPremiumStatsDomestic pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: FGPremiumStatsDomesticStore) {
    super(store);
  }
}
