import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { IChartistData, IBarChartOptions } from 'chartist';
import { ChartType, ChartEvent } from 'ng-chartist';
import 'chartist-plugin-tooltip';
import '../../../assets/js/chartist-plugin-bar-label';
import '../../../assets/js/chartist-plugin-target-line';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { filter } from 'rxjs/operators';
import * as R from 'ramda';
import * as Chartist from 'chartist';

export interface HorizontalBarChartOptions extends IBarChartOptions {
	showGridBackground?: boolean;
}

@Component({
	selector: 'app-horizontal-bar-chart',
	templateUrl: './horizontal-bar-chart.component.html',
	styleUrls: ['./horizontal-bar-chart.component.scss'],
})
export class HorizontalBarChartComponent implements OnInit {
	@Input() targetValue = null;
	@Input() barData = null;
	@Input() barDataArr = null;
	@Input() barDataArrTooltip = null;
	@Input() barColorArr = null;
	@Input() isPercentBar = false;
	barColorList;

	type: ChartType = 'Bar';
	data: IChartistData = {
		series: [],
		labels: [],
	};
	events: ChartEvent;
	options: HorizontalBarChartOptions = {
		high: 100,
		low: 0,
		chartPadding: {
			top: 0,
			right: 2,
			bottom: 0,
			left: 2,
		},
		seriesBarDistance: 12,
		horizontalBars: true,
		stackBars: true,
		stackMode: 'accumulate',
		axisY: {
			offset: 0,
			showGrid: false,
			showLabel: false,
		},
		axisX: {
			offset: 0,
			showGrid: false,
			showLabel: false,
		},
		height: '80px',
		showGridBackground: false,
	};

	/** Default bar chart styles */
	barCssProperties = {
		color: {
			firstBarLow: '#ED1C24', // Red
			firstBarHigh: '#54A8A8', // Blue Green
			secondBarDefault: '#FFFFFF', // White
			barGray: '#f0f0f0',
			barBlack: '#000000'
		},
		width: '35px',
		filter: `drop-shadow(-2px 0px 0px #ccc)
			drop-shadow(1px -1px 0px #ccc)
			drop-shadow(1px 1px 0px #ccc)
			drop-shadow(1px 0px 0px #ccc)`,
		secondBarTextColor: '#54A8A8',
	};

	maxTotal = 100;
	barLabels = [];
	barColorMap: Map<number, string>;

	/** Theme config object */
	themeConfig$ = this.businessConfigQuery.themeConfig$.pipe(filter((x) => !!x));

	constructor(
		private cd: ChangeDetectorRef,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	/**
	 * For Manual refresh. Chartist doesn't expose its renderChart method
	 * so we'll refresh the data instead
	 */
	public refresh() {
		this.data = R.clone(this.data);
		this.renderChart();
		this.cd.detectChanges();
	}

	ngOnInit() {
		this.themeConfig$.subscribe((x) => {
			if (!!x.widgetcolor1) {
				this.barCssProperties.color.firstBarHigh = x.widgetcolor1;
			}
			if (!!x.tertiarycolor) {
				this.barCssProperties.color.firstBarLow = x.tertiarycolor;
			}

			this.barColorList = {
				red: this.barCssProperties.color.firstBarLow,
				blueGreen: this.barCssProperties.color.firstBarHigh,
				white: this.barCssProperties.color.secondBarDefault,
				gray: this.barCssProperties.color.barGray,	
			};
		});

		this.renderChart();
	}

	renderChart() {
		if (this.isPercentBar) {
			if(this.barDataArr) {
				// TAP1-722: If the bar value <= 5, the percentage will only show up on the tooltip
				this.barLabels = this.barDataArr?.map(x => (!x || +x <= 5 ? [''] : [`${x}%`]));
				const value = this.barDataArr?.reduce((a, b) => a + b, 0) ? this.barDataArr : null;
				if(value) {
					this.data.series = value?.map(x => (!x ? [''] : [x]));
				} else {
					this.data.series = [[0], [this.options.high]];
				}
			} else {
				const value = this.barData?.[0] || 0;
				const barValue = value < this.options.high ? value : this.options.high;
				this.barLabels = [`${value}%`, ''];
				this.data.series = [[barValue], [this.options.high]];
			}
		} else {
			this.barLabels = this.barData;
			this.maxTotal = this.barData?.reduce(
				(a, c) => R.sum([+(a || 0), +(c || 0)]),
				0
			);
			const barData = this.barData?.reduce((a, c) => {
				let result = R.divide(+c, +this.maxTotal);
				result = result > 0 ? R.multiply(result, 100) : 0;
				result = Math.round((result + Number.EPSILON) * 100) / 100;
				return [...a, [result]];
			}, []);
			this.data.series = barData || [];
		}
		this.setupOptionsEvents();
	}

	// Setup chartist options & events
	setupOptionsEvents() {
		const targetText = `${this.targetValue}%`;
		const targetValue =
			this.targetValue > this.options.high
				? this.options.high
				: this.targetValue;

		this.options = {
			...this.options,
			plugins: [
				Chartist.plugins.ctBarLabels({
					customizeBarLabels: true,
					position: {
						x: (data) => {
							return data.x2 - 17;
						},
						y: (data) => {
							return data.y2 + 3;
						},
					},
					labelInterpolationFnc: (data) => {
						return this.barLabels[data?.seriesIndex];
					},
					allowWhiteFont: {
						enable: true,
						whiteFontValueMax: +this.targetValue,
						defaultFontColor: this.barCssProperties.secondBarTextColor,
						seriesBars: [0], // Apply white font color condition to the first bar only
					},
					coloredLabels: this.barDataArr ? [
						this.barCssProperties.color.barBlack, 
						this.barCssProperties.color.barBlack, 
						this.barDataArr?.length === 3 ? this.barCssProperties.color.secondBarDefault : this.barCssProperties.color.barBlack,
						this.barCssProperties.color.secondBarDefault
					] : null,
				}),
				Chartist.plugins.ctTargetLine({
					axis: 'x',
					value: targetValue,
					textValue: targetText,
				}),
				Chartist.plugins.tooltip({
					valueTransform: (value: number) => {
						return this.isPercentBar && !this.barDataArr
							? this.barLabels?.[0] || ''
							: value;
					},
					seriesName: false,
				}),
			],
		};
		// if barColorArr is null
		const colorMap = new Map([
				[0, this.barCssProperties.color.firstBarHigh],
				[1, this.barCssProperties.color.secondBarDefault],
				[2, this.barDataArr?.length === 3 ? this.barCssProperties.color.firstBarLow : this.barCssProperties.color.barGray],
				[3, this.barCssProperties.color.firstBarLow]
			]);
		this.barColorMap = colorMap;
		this.events = {
			draw: (data) => {
				if (data.type === this.type.toLocaleLowerCase()) {
					let style = `filter: ${this.barCssProperties.filter}; stroke-width: ${this.barCssProperties.width};`;
					
					if(this.barDataArr) {
						style = `${style} stroke: ${this.barColorArr ? this.barColorArr[data.seriesIndex] : colorMap.get(data.seriesIndex)};`;
					} else {
						if (data.seriesIndex === 0) {
							style = `${style} stroke: ${
								this.targetValue > data?.series[0]
									? this.barCssProperties.color.firstBarLow
									: this.barCssProperties.color.firstBarHigh
							};`;
						} else {
							style = `${style} stroke: ${this.barCssProperties.color.secondBarDefault};`;
						}
					}
					data.element.attr({ style });
					if (this.barDataArrTooltip) {
						const value = +(data?.value?.x || 0);
						let customTooltip = this.barDataArrTooltip?.[data?.seriesIndex];
						if (value <= 5) {
							// TAP1-722: If the bar value <= 5, the percentage will only show up on the tooltip
							customTooltip = `${customTooltip} ${value}%`;
						}
						// Override ct:value for custom tooltip
						data.element.attr({ 'ct:value': customTooltip });
					}
				}
			},
		};
	}
}
