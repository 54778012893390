import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EmailDuplicateState } from '@shared/models/client-profile/primary-client/primary-client.model';

@Component({
	selector: 'app-check-duplicate-email-modal',
	templateUrl: './check-duplicate-email-modal.component.html',
	styleUrls: ['./check-duplicate-email-modal.component.scss'],
})
export class CheckDuplicateEmailModalComponent {
	confirm$: Observable<any>;
	decline$: Observable<any>;
	close$: Observable<any>;
	emails: EmailDuplicateState[];
	duplicates: string;
	header: string;
	message: string;
	detachCloseIcon = false;
	cancelLoading: boolean;
	confirmTxt = 'Ok';
	cancelTxt = 'Cancel';

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
	}
	setupProps() {
		this.header = 'Email Duplicate';
		this.message =
			'The email address you are trying to save already belongs to another client';
	}

	continue() {
		if (this.cancelLoading) {
			return;
		}
		this.confirm$.subscribe();
		this.bsModalRef.hide();
	}
	decline() {
		this.cancelLoading = true;
		if (!!this.decline$) {
			this.decline$.subscribe();
		}
		this.bsModalRef.hide();
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	close() {
		this.cancelLoading = true;
		if (!!this.close$) {
			this.close$.subscribe();
		}
		this.bsModalRef.hide();
		setTimeout(() => (this.cancelLoading = false), 500);
	}
	
	checkDupLength() {
		if (this.duplicates) {
			return (this.duplicates.match(new RegExp('<li>', 'g')).length);
		}else{
			return 0
		}
	}
}
export interface ConfirmModalModel {
	confirm$: Observable<any>;
	decline$?: Observable<any>;
	emails?: EmailDuplicateState[];
	duplicates?: string;
	close$?: Observable<any>;
	header: string;
	message: string;
	confirmTxt?: string;
	cancelTxt?: string;
	detachCloseIcon?: boolean;
}
