import { AdviceProcessRequest } from './advice-process-request.model';

// This returns an object
export const comparable = (o) =>
  typeof o !== 'object' || !o
    ? o
    : Object.keys(o)
        ?.sort()
        ?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (
  req: AdviceProcessRequest,
  saveReq: AdviceProcessRequest
) => {
  let r = true;

  if (!req || !saveReq) {
    return (r = false);
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.Advisers)) !==
    JSON.stringify(comparable(saveReq.Advisers))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AltAdvisers)) !==
    JSON.stringify(comparable(saveReq.AltAdvisers))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.ReviewedBy)) !==
    JSON.stringify(comparable(saveReq.ReviewedBy))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AdviceProcesses)) !==
    JSON.stringify(comparable(saveReq.AdviceProcesses))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AdviceStatuses)) !==
    JSON.stringify(comparable(saveReq.AdviceStatuses))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AdviceDocuments)) !==
    JSON.stringify(comparable(saveReq.AdviceDocuments))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.LeadTypes)) !==
    JSON.stringify(comparable(saveReq.LeadTypes))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.LeadOrigins)) !==
    JSON.stringify(comparable(saveReq.LeadOrigins))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.ContactStatuses)) !==
    JSON.stringify(comparable(saveReq.ContactStatuses))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AdviceStartDateMin)) !==
    JSON.stringify(comparable(saveReq.AdviceStartDateMin))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AdviceStartDateMax)) !==
    JSON.stringify(comparable(saveReq.AdviceStartDateMax))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AdviceEndDateMin)) !==
    JSON.stringify(comparable(saveReq.AdviceEndDateMin))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.AdviceEndDateMax)) !==
    JSON.stringify(comparable(saveReq.AdviceEndDateMax))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.NextActivityDateMin)) !==
    JSON.stringify(comparable(saveReq.NextActivityDateMin))
  ) {
    r = false;
  }
  // tslint:disable-next-line: max-line-length
  if (
    JSON.stringify(comparable(req.NextActivityDateMax)) !==
    JSON.stringify(comparable(saveReq.NextActivityDateMax))
  ) {
    r = false;
  }
  return r;
};

export const request = (
  filter: any,
  form: AdviceProcessRequest,
  index: number
) => {
  const req = {
    Advisers: form.Advisers,
    AltAdvisers: form.AltAdvisers,
    ReviewedBy: form.ReviewedBy,

    AdviceProcesses: form.AdviceProcesses,
    AdviceStatuses: form.AdviceStatuses,

    AdviceDocuments: form.AdviceDocuments,
    LeadTypes: form.LeadTypes,

    LeadOrigins: form.LeadOrigins,
    ContactStatuses: form.ContactStatuses,

    AdviceStartDateMin: form.AdviceStartDateMin,
    AdviceStartDateMax: form.AdviceStartDateMax,
    AdviceEndDateMin: form.AdviceEndDateMin,
    AdviceEndDateMax: form.AdviceEndDateMax,
    NextActivityDateMin: form.NextActivityDateMin,
    NextActivityDateMax: form.NextActivityDateMax,

    AdviceOnlineOffline: form.AdviceOnlineOffline,
		AdviceStages: form.AdviceStages,

    Paging: {
      Index: index,
      Column: filter ? filter.Column : null,
      Direction: filter ? filter.Direction : null,
    },
  };
  return req;
};
