<div class="funding-required-cash-deposit">
	<ng-container *ngIf="isLoading; else showForm">
		<app-manual-loader-mini [isLoading]="true"> </app-manual-loader-mini>
	</ng-container>

	<ng-template #showForm
		><app-cash-deposit-form
			#cashDepositForm
			[cRTId$]="cRTId$"
			[parentCRTId]="parentCRTId"
			[adviceProcessId]="adviceProcessId"
			[data$]="cashDeposit$"
			[kiwiSavers$]="kiwiSavers$"
			[assets$]="assets$"
			[propertyPurchases$]="propertyPurchases$"
			[purpose$]="purpose$"
			[loanRefinance$]="loanRefinance$"
			[isRefinance$]="isRefinance$"
			[isSellingProperty$]="isSellingProperty$"
			[propertyToBeSold$]="propertyToBeSold$"
			[loansRepaid$]="loansRepaid$"
			[isLendingTopup$]="isLendingTopup$"
			[topUpRequirement$]="topUpRequirement$"
			[activeBank]="activeBank"
		></app-cash-deposit-form>
	</ng-template>
</div>
