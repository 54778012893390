import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, filter, map, takeUntil } from 'rxjs/operators';
import { DonutChartComponent } from '../../../../../shared/donut-chart/donut-chart.component';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { DashboardQuery } from '../../state/dashboard.query';
import { MonthlyMortgageGoalTrack } from './state/mmgt.model';
import { MMGTQuery } from './state/mmgt.query';
import { MMGTService } from './state/mmgt.service';

@Component({
  selector: 'app-mmgt',
  templateUrl: './mmgt.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MmgtComponent implements OnInit, OnDestroy {
  public static widgetCode = 'mgt';
  public static widgetName = 'Monthly Mortgage Goal Track';
  public static sizeX = 600;
  public static sizeY = 310;
  public static minSizeX = 600;
  public static minSizeY = 310;

  widgetCode = MmgtComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();
  /**
   * Kiwisaver Goal Track data
   */
  data$: Observable<MonthlyMortgageGoalTrack> = this.query.data$;

  /** Pluck Previous Month data. Used for donut chart */
  previousMonth$ = this.data$.pipe(map(data => data.PreviousMonth));
  /** Pluck Current Month data. Used for donut chart */
  currentMonth$ = this.data$.pipe(map(data => data.CurrentMonth));
  /** Pluck NextMonth data. Used for donut chart */
  nextMonth$ = this.data$.pipe(map(data => data.NextMonth));
  /** Pluck Quarter Expected data. Used for donut chart */
  quarterExpected$ = this.data$.pipe(map(data => data.QuarterExpected));

  /**
   * Indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;
  /**
   * Error message string. Show error if not empty.
   */
  error$ = this.query.error$;

  /**
   * Donut chart element. referenced for manual refreshing
   * when gridstack resizes div.
   */
  @ViewChildren(DonutChartComponent) donuts: QueryList<DonutChartComponent>;

  constructor(
    private dashboardQuery: DashboardQuery,
    private service: MMGTService,
    private query: MMGTQuery,
    private dashboard: DashboardComponent
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of goal track.
   *
   * Chart does not automatically resize on div resize(gridstack)
   *   so listen to resize event to trigger manual resize via refreshing
   *   of data.
   *
   * @caveat
   * Currently, I haven't figured out how to listen to specific widget
   *   and listen specifically to resize events only, so it triggers
   *   refresh on every event
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetMonthlyMortgageGoalTrack(advisers);
      });

    this.dashboard.gridChangeEvent.pipe(delay(200), takeUntil(this.onDestroy$)).subscribe(res => {
      if (res === MmgtComponent.widgetCode) {
        this.donuts?.forEach(x => x.refresh());
      }
    });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
