<div
	class="modal-header text-light tap-bg-primary d-flex justify-content-between"
>
	<h4 class="modal-title font-weight-normal">Announcements</h4>
	<div class="clrearfix d-flex">
		<button
			type="button"
			class="open-in-full mr-1"
			aria-label="Fullscreen"
			(click)="toggleFullscreen()"
		>
			<span class="material-symbols-outlined"
				>{{ isFullscreen ? "close_fullscreen" : "open_in_full" }}</span
			>
		</button>
	</div>
</div>
<div class="modal-body">
	<div class="modal-body__inner" #modalBody tabindex="-1">
		<app-loader-mini2 [isLoading]="isLoading">
			<ng-container *ngFor="let announcement of visibleAnnouncements">
				<div class="tap-froala__editor">
					<!-- Content -->
					<div class="announcement-container py-4">
						<div class="date">{{ announcement.scheduledDate }}</div>
						<div class="title mb-4">{{ announcement.title }}</div>
						<div [froalaView]="announcement.hTMLBody"></div>
					</div>
				</div>
			</ng-container>
		</app-loader-mini2>
		<!-- <button -->
		<!-- 	class="btn-read" -->
		<!-- 	*ngIf="visibleAnnouncements?.length" -->
		<!-- 	[disabled]="isLoading || isSaving" -->
		<!-- 	(click)="save()" -->
		<!-- > -->
		<!-- 	I have read this announcement -->
		<!-- </button> -->
		<div class="tap-froala__editor button-container mb-0">
			<app-button
				class="btn-read"
				*ngIf="visibleAnnouncements?.length"
				text="I have read this announcement"
				[clickFn$]="acknowledge$"
			>
			</app-button>
		</div>
		<!-- Buttons -->
	</div>
</div>

<div class="modal-footer"></div>
