import { of } from 'rxjs';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';

// CRM (PCI and SCI) and CRT People to ViewDisplayValue
export const peopleToViewDisplayValueUtil = (clients) => {
  if (!clients) {
    return null
  }

  return of(clients?.map(x => {
    return {
      display: x.firstName ? x.firstName.concat(' ', x.lastName) : x.name,
      value: x.customerID ? x.customerID : (x.customerId ? x.customerId : null),
      isDefault: false,
    }
  }) as ViewDisplayValue[]
  );
};

// CRM (SCT) to ViewDisplayValue
export const trustsToViewDisplayValueUtil = (clients) => {
  if (!clients) {
    return null
  }

  return of(clients?.map(x => {
    return {
      display: x.trustName,
      value: x.customerID ? x.customerID : (x.customerId ? x.customerId : null),
      isDefault: false,
    }
  }) as ViewDisplayValue[]
  );
};



// CRM (SCT) to ViewDisplayValue
export const companyToViewDisplayValueUtil = (company) => {
  if (!company) {
    return null
  }

  return of(company?.map(x => {
    return {
      display: x.companyName,
      value: x.customerID ? x.customerID : (x.customerId ? x.customerId : null),
      isDefault: false,
    }
  }) as ViewDisplayValue[]
  );
};
