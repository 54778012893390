<ng-container *ngIf="url">
	<ngx-extended-pdf-viewer
		[src]="url"
		useBrowserLocale="false"
		height="100vh"
		[zoom]="'auto'"
		[filenameForDownload]="fileName"
		[handTool]="false"
		[showOpenFileButton]="false"
		[showRotateButton]="false"
		[showTextEditor]="false"
		[showDrawEditor]="false"
		[showStampEditor]="false"
		[showBorders]="true"
		(pageRendered)="adjustCanvas($event)"
		backgroundColor="#5c5c5c"
		theme="dark"
	></ngx-extended-pdf-viewer>
</ng-container>
