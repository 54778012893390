import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { EmailStatusModel } from './email-status.model';
import produce from 'immer';
import { TableResult, ValueReference } from '../../../../shared/models/emails/email-history/email-history.model';
import { Injectable } from "@angular/core";

export interface EmailStatusState extends EntityState<EmailStatusModel> {
	columns: string[];
	settings: EmailStatusModel[];
	tableResult: TableResult;
	status: ValueReference[];
	cellLoadingStatus: {};
	propSort: string;
	sort: string;
}

@Injectable()
@StoreConfig({ name: 'emailStatus', idKey: 'EmailId' })
export class EmailStatusStore extends EntityStore<EmailStatusState, EmailStatusModel> {

	constructor() {
		super({
			columns: [],
			settings: null,
			tableResult: null,
			status: null,
			rowLoadingStatus: {},
			propSort: '',
			sort: ''
		});
	}

	setLoad(id: number, loading: boolean) {
		const key = `${id}`;
		this.update(state => ({
			rowLoadingStatus: { ...state.rowLoadingStatus, [key]: loading },
		}));
	}

	setColumns(columns: string[]) {
		this.update(produce(x => { x.columns = columns; }));
	}

	setAll(e: EmailStatusModel[]) {
		this.update({ settings: e });
	}

	setTableResult(e: TableResult) {
		this.update({ tableResult: e });
	}

	setStatus(e) {
		this.update({ status: e });
	}

	setSort(propSort: string, sort: 'asc' | 'desc') {
		this.update(
			produce(draft => {
				draft.propSort = propSort;
				draft.sort = sort;
			})
		);
	}

}
