<div class="container-fluid h-100 px-0">
  <div *ngIf="headerRow" class="form-row simple-table__row flex-nowrap font-weight-bold">
    <div class="col align-self-center text-bold text-truncate tap-text-color-h5 header-col" *ngFor="let header of headerRow"
      [ngClass]="header.class" [title]="header.text">
      {{header.text}}
    </div>
  </div>
  <div class="row bg-white simple-table__body" [class.simple-table__body--has-header-row]="headerRow" [class.simple-table__body--has-sub-header-row]="hasSubHeader">
    <div class="col px-3">
      <div class="form-row simple-table__row flex-nowrap" *ngFor="let cells of rows">
        <div class="col align-self-center body-col tap-text-color-h4" *ngFor="let cell of cells" [ngClass]="cell.class">
          <a *ngIf="cell.route; else textOnly" class="tap-text-color-h4" [routerLink]="cell.route" [title]="cell.text">
            {{cell.text}}
          </a>
          <ng-template #textOnly>
            {{cell.text}}
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>