import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { sum, subtract, isNil, isEmpty } from 'ramda';
import {
	combineLatest,
	forkJoin,
	from,
	iif,
	Observable,
	of,
	Subject,
} from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	toArray,
	withLatestFrom,
} from 'rxjs/operators';
import { IncomeSourceState } from '../../../../../shared/models/client-review-template/income-budget/income-source-details.model';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { convertToAnnual } from '../../../../../modules/crm/client-review-template/income-budget/calculations/annual-conversion';
import {
	computeAnnualTaxable,
	computeMonthlyAfterTaxIncome,
	computeMonthlyTaxable,
} from '../../../../../modules/crm/client-review-template/income-budget/calculations/compute-taxable';
import { PropertyState } from '../../../../../shared/models/client-review-template/assets-liabilities/property.model';
import { FactFindComputationState } from '../../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import { RentalIncomeMapper } from '../../../../../shared/models/client-review-template/income-budget/rental-income.mapper';
import {
	RentalIncomeObjState,
	RentalIncomeState,
} from '../../../../../shared/models/client-review-template/income-budget/rental-income.model';
import { numUtil } from '../../../../../util/util';
import { IncomeService } from '../../states/income-budget/income.service';
import { NoWhitespaceValidator } from '../../../../../shared/validator/no-whitespace/no-whitespace.directive';
import {
	Fields,
	getInvalidWarning,
} from 'src/app/shared/error-message/error-message';
import { PropertyInfoState } from 'src/app/shared/models/client-review-template/assets-liabilities/property/property.model';

declare var $: any;

@Component({
	selector: 'app-net-rental-income',
	templateUrl: './rental-income.component.html',
	styleUrls: ['./rental-income.component.scss'],
})
export class RentalIncomeComponent implements OnInit, OnDestroy {
	@Input() isAdviceProcessEnded: boolean;
	@Input() netRentalIncome$: Observable<RentalIncomeObjState>;
	@Input() properties$: Observable<PropertyState[]>;
	@Input() updateFn$: (
		netRentalIncome: RentalIncomeState
	) => Observable<RentalIncomeState>;
	@Input() addNewFn$: (
		netRentalIncome: RentalIncomeState
	) => Observable<RentalIncomeState>;
	@Input() deleteFn$: (netRentalIncome: number) => Observable<number>;
	@Input() factFindComputation$: Observable<FactFindComputationState>;
	@Input() incomeSource$: Observable<IncomeSourceState[]>;
	onDestroy$ = new Subject<void>();

	elseMinusRental = true;
	idRental = 0;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	totalRental = 0;
	annualInvestmentInterest = 0;
	factFind: FactFindComputationState = {};
	incomeList: IncomeSourceState[];
	propertyList: PropertyState[];
	isListLoading = true;
	editMode = false;
	addMode = false;
	tempData: object;

	addressToolTip: string;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private loggerService: LoggerService,
		private incomeService: IncomeService,
		private cdr: ChangeDetectorRef
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.properties$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((properties) => {
				if (!properties) {
					return;
				}
				this.buildForm();
				this.prepData();
				combineLatest([this.incomeSource$, this.factFindComputation$])
					.pipe(takeUntil(this.onDestroy$))
					.subscribe(([income, factFind]) => {
						this.incomeList = income;
						this.totalRental = +factFind?.totalNetRentalIncome;
						this.factFind = factFind;
					});
			});
	}

	get rentalArray() {
		return this.form.get('rentalArray') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			rentalArray: this.fb.array([]),
		});
	}

	addRental(data?: RentalIncomeState, isAdd: boolean = false) {
		this.rentalArray?.push(
			this.fb.group({
				cRTId: [(data && data.cRTId) || ''],
				adviceProcessId: [(data && data.adviceProcessId) || ''],
				propertyAsset: [
					(data && data.propertyAsset) || '',
					[Validators.required, NoWhitespaceValidator],
				],
				propertyAddress: [(data && data.propertyAddress) || ''],
				netRentalIncome: [(data && data.netRentalIncome) || '0'],
				btnSaveRental: isAdd,
				btnEditRental: !isAdd,
				isNew: isAdd,
				isLoading: [false],
			})
		);
	}

	selectProperty(index: number) {
		const pid = this.rentalArray.controls[index].get('propertyAsset').value;
		if (isEmpty(pid) || isNil(pid)) {
			return;
		} else {
			this.rentalArray.controls[index].get('netRentalIncome').setValue('');
			this.rentalArray.controls[index].get('isLoading').setValue(true);
			this.incomeService
				.getInfoByCRT(+pid)
				.pipe(
					finalize(() =>
						this.rentalArray.controls[index].get('isLoading').setValue(false)
					),
					take(1)
				)
				.subscribe((x) => this.computeNetRental(x, index));
		}
	}

	computeNetRental(data: PropertyInfoState, index?: number) {
		const rentalIncome = convertToAnnual(
			+data.rentalIncome || 0,
			data.rentalIncomeFrequency
		);
		const rate = convertToAnnual(+data.rates || 0, data.ratesFrequency);
		const other = convertToAnnual(
			+data.otherExpense || 0,
			data.otherExpenseFrequency
		);
		const insurance = convertToAnnual(
			+data.insurance || 0,
			data.insuranceFrequency
		);
		const sumROI = sum([rate, other, insurance]);
		const totalNetRental = +numUtil.formatToNumCurrency(
			subtract(rentalIncome, subtract(sumROI, this.annualInvestmentInterest))
		);
		if (isNaN(index) || isNil(index)) {
			return +totalNetRental;
		}
		this.rentalArray.controls[index]
			.get('netRentalIncome')
			.setValue(+totalNetRental);
		this.rentalArray.controls[index].get('isLoading').setValue(false);
	}

	saveRental(index: number, bypassValidation: boolean = false, newData?) {
		if (!bypassValidation && this.rentalArray?.invalid) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.NetRentalIncome));
			return;
		}
		const isNew = this.rentalArray.controls[index].get('isNew').value;
		const adviceProcessId = parseInt(
			this.route.snapshot.paramMap.get('adviceProcessId'),
			10
		);
		const cRTId = !isNew
			? +this.rentalArray.controls[index].get('cRTId').value
			: null;
		const tempFactFind = {
			...this.factFind,
			...this.recompute(
				index,
				this.rentalArray.controls[index].get('netRentalIncome').value,
				this.factFind
			),
		};
		const data = newData ?? this.form.getRawValue()?.rentalArray[index];
		const formValue = RentalIncomeMapper.mapToUpsert(
			data,
			cRTId,
			adviceProcessId,
			tempFactFind
		);

		this.rentalArray.controls[index].get('isLoading').setValue(true);

		of(formValue)
			.pipe(
				mergeMap((val) =>
					iif(
						() => isNew,
						this.addNewFn$(val).pipe(
							tap(() => {
								this.buildForm();
								this.prepData();
							})
						),
						this.updateFn$(val)
					)
				),
				tap(() => this.setDisabled(index)),
				withLatestFrom(this.properties$),
				tap(([_, properties]) => {
					const currentAddress = properties?.find(
						(item) => +item.cRTId === +data.propertyAsset
					)?.propertyAddress;
					this.rentalArray.controls[index]
						.get('propertyAddress')
						.setValue(currentAddress);

					this.updatePropertyList();
				}),
				finalize(() => {
					this.rentalArray.controls[index].get('isLoading').setValue(false);
					if (newData) {
						this.rentalArray.controls[index]
							.get('netRentalIncome')
							.setValue(newData?.netRentalIncome);
					}
					this.tempData = null;
					this.editMode = false;
					this.addMode = false;
				}),
				take(1)
			)
			.subscribe();
	}

	updatePropertyList(rentalList?: RentalIncomeState[], insert?: number) {
		this.properties$
			.pipe(
				tap((properties) => {
					const getRentalIncomeList = rentalList
						? rentalList
						: this.form.getRawValue()?.rentalArray;
					const selectedAssets = getRentalIncomeList?.map(
						(item) => +item.propertyAsset
					);

					this.propertyList = properties?.filter(
						(item) =>
							!selectedAssets?.includes(+item.cRTId) &&
							item.propertyUse === 'Investment'
					);

					if (insert) {
						const getInsert = properties?.find(
							(item) => +item.cRTId === +insert
						);
						this.propertyList?.push(getInsert);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	setDisabled(i: number) {
		this.rentalArray.controls[i].get('btnSaveRental').setValue(false);
		this.rentalArray.controls[i].get('btnEditRental').setValue(true);
		this.rentalArray.controls[i].get('propertyAsset').disable();
	}

	editRental(index: number) {
		this.editMode = true;
		this.tempData = this.form.getRawValue()?.rentalArray[index];
		this.rentalArray.controls[index].get('btnSaveRental').setValue(true);
		this.rentalArray.controls[index].get('btnEditRental').setValue(false);
		this.rentalArray.controls[index].get('propertyAsset').enable();

		const current = this.rentalArray.controls[index].get('propertyAsset').value;
		this.updatePropertyList(null, +current);
	}

	deleteRental(index: number) {
		this.rentalArray.controls[index].get('isLoading').setValue(true);

		this.deleteFn$(+this.rentalArray.controls[index].get('cRTId').value)
			.pipe(
				finalize(() => {
					this.rentalArray.removeAt(index);
					this.updatePropertyList();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	deleteNewRental(index: number) {
		this.rentalArray.removeAt(index);
		this.addMode = false;
	}

	cancelEdit(i: number) {
		this.rentalArray.controls[i].patchValue(this.tempData);
		this.updatePropertyList();
		this.setDisabled(i);
		this.editMode = false;
		this.addMode = false;
		this.tempData = null;
	}

	addNewRental() {
		this.addRental(null, true);
		this.addMode = true;
	}

	filteredRentalIncomeList(
		rental: RentalIncomeObjState,
		properties: PropertyState[]
	): RentalIncomeObjState {
		if (!rental?.netRentalIncomeList?.length || !properties?.length) {
			return {
				...rental,
				netRentalIncomeList: [],
			};
		}
		const filteredProp = properties?.filter(
			(property) => property.propertyUse === 'Investment'
		);
		const rentalList = [...(rental?.netRentalIncomeList || [])]?.filter((r) =>
			filteredProp?.find((x) => x?.cRTId === r.propertyAsset)
		);
		return {
			...rental,
			netRentalIncomeList: rentalList,
		} as RentalIncomeObjState;
	}

	prepData() {
		this.netRentalIncome$
			.pipe(
				withLatestFrom(this.properties$),
				filter(([rental, properties]) => !!rental && !!properties),
				tap(([rental, properties]) => {
					this.form.reset();
					const rentalIncome = this.filteredRentalIncomeList(
						rental,
						properties
					) as RentalIncomeObjState;
					RentalIncomeMapper.mapRentalIncome(rentalIncome, properties)?.forEach(
						(x) => this.addRental(x)
					);
					this.form.disable();
					this.updatePropertyList(rentalIncome?.netRentalIncomeList || []);
				}),
				concatMap(([rental, properties]) =>
					forkJoin([of(rental), this.getPropertyDetails(properties)])
				),
				map(([rental, properties]) => {
					const rentalIncome = this.filteredRentalIncomeList(
						rental,
						properties
					) as RentalIncomeObjState;
					const toUpdate = rentalIncome?.netRentalIncomeList || [];
					toUpdate?.map((item, i) => {
						const property = properties?.find(
							(val) => val.cRTId === item.propertyAsset
						);

						if (property?.netRentalIncome !== item?.netRentalIncome) {
							this.saveRental(i, true, {
								...item,
								netRentalIncome: property?.netRentalIncome,
							});
						}
					});
				}),
				finalize(() => {
					this.isListLoading = false;
					this.cdr.detectChanges();
				}),
				take(1)
			)
			.subscribe();
	}

	getPropertyDetails = (properties: PropertyState[] = []) =>
		from(properties).pipe(
			mergeMap((item) =>
				this.incomeService.getInfoByCRT(item.cRTId).pipe(
					map((detail) => {
						return { ...item, ...detail };
					})
				)
			),
			map((data) => ({
				...data,
				netRentalIncome: this.computeNetRental(data),
			})),
			toArray(),
			take(1)
		);

	recompute(
		index: number,
		newValue: number,
		factFind: FactFindComputationState
	) {
		let tempTotalNetRental = 0;
		this.rentalArray.controls?.map((rental: UntypedFormGroup, i: number) => {
			if (index === i) {
				tempTotalNetRental += +newValue;
			} else {
				tempTotalNetRental += +rental.value.netRentalIncome || 0;
			}
		});

		const annualTaxable = computeAnnualTaxable({
			...factFind,
			totalNetRentalIncome: tempTotalNetRental,
		});
		const monthlyTaxable = computeMonthlyTaxable(+annualTaxable);
		const monthlyAfterTax = computeMonthlyAfterTaxIncome(
			this.incomeList,
			{
				...factFind,
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
			},
			this.incomeService.getIncomeLists()
		);

		const obj = {
			totalNetRentalIncome: +numUtil.formatToNumCurrency(tempTotalNetRental),
			annualTaxableJointIncome: +annualTaxable,
			monthlyTaxableJointIncome: +monthlyTaxable,
			monthlyAfterTaxIncome: +monthlyAfterTax,
		};

		return obj;
	}

	collapseFalse() {
		this.elseMinusRental = false;
	}

	collapseMoreRental() {
		$('#collapseRental').toggle();
		this.elseMinusRental = false;
	}

	collapseLessRental() {
		$('#collapseRental').toggle();
		this.elseMinusRental = true;
	}

	isEllipsisActive(value) {
		this.addressToolTip = value.propertyAddress;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
