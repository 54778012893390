import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import {
	tableColumns,
	defaultManageUserConfig,
} from './manage-users-list.table-config';
import { BehaviorSubject, Observable, iif, of } from 'rxjs';
import { ManageUsersListService } from './states/manage-users-list.service';
import { ManageUsersListQuery } from './states/manage-users-list.query';
import { ComponentBase } from '@core/base/component-base';
import {
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RouteService } from '@core/config/route.service';
import MomentUtil from '@util/moment.util';
import { IsActiveOptionsDd } from '@shared/models/_general/bl-staff.model';
import { MatDataTableModel } from '@shared/models/_general/mat-data-table.model';
import { viewSecGroup } from '@modules/user/viewmodels/viewSecGroup';
import { ManageUsersListMapper } from './states/manage-users-list.mapper';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { util } from '@util/util';
import { util as utilService } from '@core/util/util.service';
import { ExportsService } from '@modules/exports/state/exports.service';
import {
	ExportsStatus,
	ExportsType,
} from '@modules/exports/state/exports.model';
import { ActivatedRoute } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ManageUsersListDataSource } from './manage-users-list.datasource';

@Component({
	selector: 'app-manage-users-list',
	templateUrl: './manage-users-list.component.html',
	styleUrls: ['./manage-users-list.component.scss'],
})
export class ManageUsersListComponent extends ComponentBase implements OnInit {
	@Input() secGroups$: Observable<viewSecGroup[]>;
	@Input() userInfo$: Observable<BlStaffViewmodel>;
	@Input() isTapLevel: boolean;
	@Input() saveColumnSettingsFn$: (data: any) => Observable<any>;
	@Input() saveColumnWidthSettingsFn$: (data: any) => Observable<any>;

	searchFilter$ = new BehaviorSubject<any>(defaultManageUserConfig.filter);
	sortBy$ = new BehaviorSubject<any>(defaultManageUserConfig.sort);
	userStatus$ = new BehaviorSubject<any>(IsActiveOptionsDd);
	isSearching$ = new BehaviorSubject<boolean>(false);

	formGroup: FormGroup;
	resultCount: number;
	tableColumnConfig: MatDataTableModel[] = [];
	openColumnSelection = false;
	isSearchFormExpanded = false;
	PAGE_INDEX = defaultManageUserConfig.pageIndex;
	PAGE_SIZE = defaultManageUserConfig.pageSize;
	COL_WIDTH = defaultManageUserConfig.colWidth;
	VIEWPORT_TABLE_ROW_HEIGHT = '33.5';
	CustomDataSource = ManageUsersListDataSource;

	UserAddLink = this.routeService.usersAdd;
	isLoading$ = this.query.isLoading$;

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	exportStatus$ = this.exportsQuery.status$;

	constructor(
		private fb: FormBuilder,
		private service: ManageUsersListService,
		private query: ManageUsersListQuery,
		private routeService: RouteService,
		private cd: ChangeDetectorRef,
		private exportsService: ExportsService,
		private route: ActivatedRoute,
		private renderer: Renderer2,
		private exportsQuery: ExportsQuery
	) {
		super();
		this.formGroup = this.fb.group({
			FirstName: [''],
			LastName: [''],
			EmailAddress: [''],
			CorrespondenceEmail: [''],
			UserStatuses: [],
			SecurityGroups: [],
			StartDateMin: [''],
			StartDateMax: [''],
			LastLoginMin: [''],
			LastLoginMax: [''],
		});
	}

	ngOnInit(): void {
		this.setDefaultFilter();
		this.setTableColumns();
	}

	fetchUsersFn$ = (filter) => {
		this.setSearchingStatus(true);
		return of(filter).pipe(
			map(ManageUsersListMapper.mapSearchFilter),
			mergeMap(this.service.get),
			map(ManageUsersListMapper.mapToView),
			tap((res) => (this.resultCount = res?.TotalCount)),
			map((res) => res?.SearchResults || []),
			tap(() => this.setSearchingStatus(false)),
			take(1)
		);
	};

	loadDataFn$(filter) {
		this.setSearchingStatus(true);
		const payload = ManageUsersListMapper.mapSearchFilter(filter);
		return this.service.get(payload).pipe(
			map(ManageUsersListMapper.mapToView),
			tap(() => this.setSearchingStatus(false)),
			take(1)
		);
	}

	setSearchingStatus(val: boolean) {
		this.isSearching$.next(val);
		this.cd.detectChanges();
	}

	setDefaultFilter() {
		const status = JSON.stringify([defaultManageUserConfig.status]);
		this.formGroup.get('UserStatuses').setValue(status);
	}

	formatValidDate(value) {
		return utilService.isValidMoment(value)
			? value
			: MomentUtil.formatDateToMoment(value);
	}

	setTableColumns() {
		this.userInfo$
			.pipe(
				tap((user) => {
					this.tableColumnConfig = !!this.isTapLevel
						? tableColumns
						: ManageUsersListMapper.mapTableColumns(user, tableColumns);
				}),
				take(1)
			)
			.subscribe();
	}

	onToggleColumns(event) {
		const cols = [
			...event?.fixedColumns,
			...event?.visibleColumns,
			...event?.hiddenColumns,
		] as MatDataTableModel[];
		this.tableColumnConfig = cols;

		this.setSearchingStatus(true);
		of([...event.fixedColumns, ...event?.visibleColumns])
			.pipe(
				mergeMap((res) =>
					iif(
						() =>
							!this.isTapLevel &&
							typeof this.saveColumnSettingsFn$ === 'function',
						this.saveColumnSettingsFn$(res).pipe(map(() => res)),
						of(res)
					)
				),
				finalize(() => this.setSearchingStatus(false)),
				take(1)
			)
			.subscribe();
	}

	onSortColumn(event) {
		this.sortBy$.next({
			Column: event?.active || '',
			Direction: event?.direction || '',
		});
		this.filterSearch();
	}

	onResizeColumn(event) {
		const table = this.tableColumnConfig;
		if (!event?.columnIndex) {
			return;
		}
		const col = table[event?.columnIndex];

		this.setSearchingStatus(true);
		of(col)
			.pipe(
				map((res) => ({ ...res, width: event.newWidth || this.COL_WIDTH })),
				tap(() => {
					this.tableColumnConfig = table?.map((x) =>
						col?.columnId === x?.columnId
							? { ...x, width: event.newWidth || this.COL_WIDTH }
							: x
					);
				}),
				mergeMap((res) =>
					iif(
						() =>
							!this.isTapLevel &&
							typeof this.saveColumnWidthSettingsFn$ === 'function',
						this.saveColumnWidthSettingsFn$(res).pipe(map(() => res)),
						of(res)
					)
				),
				finalize(() => this.setSearchingStatus(false)),
				take(1)
			)
			.subscribe();
	}

	filterSearch() {
		of(this.formGroup.getRawValue())
			.pipe(
				withLatestFrom(this.sortBy$),
				map(([formValue, sortBy]) => {
					const stats = util
						.tryParseJson(formValue?.UserStatuses)
						?.filter(Boolean)
						?.map((x) => +x);

					return {
						FirstName: formValue?.FirstName || '',
						LastName: formValue?.LastName || '',
						EmailAddress: formValue?.EmailAddress || '',
						CorrespondenceEmail: formValue?.CorrespondenceEmail || '',
						StartDateMin: this.formatValidDate(formValue?.StartDateMin),
						StartDateMax: this.formatValidDate(formValue?.StartDateMax),
						LastLoginMin: this.formatValidDate(formValue?.LastLoginMin),
						LastLoginMax: this.formatValidDate(formValue?.LastLoginMax),
						UserStatuses: stats ?? [],
						SecurityGroups: util.tryParseJson(formValue?.SecurityGroups) ?? [],
						Paging: {
							Column: sortBy?.Column,
							Direction: sortBy?.Direction,
							// Index: this.PAGE_INDEX,
							// Size: this.PAGE_SIZE,
						},
					};
				}),
				tap((data) => this.searchFilter$.next(data)),
				take(1)
			)
			.subscribe();
	}

	resetSearchFilter() {
		this.formGroup.get('FirstName').reset('');
		this.formGroup.get('LastName').reset('');
		this.formGroup.get('EmailAddress').reset('');
		this.formGroup.get('CorrespondenceEmail').reset('');
		this.formGroup.get('SecurityGroups').reset([]);
		this.formGroup.get('StartDateMin').reset(null);
		this.formGroup.get('StartDateMax').reset(null);
		this.formGroup.get('LastLoginMin').reset(null);
		this.formGroup.get('LastLoginMax').reset(null);
		this.formGroup
			.get('UserStatuses')
			.reset(JSON.stringify([defaultManageUserConfig.status]));
		this.filterSearch();
	}

	openColumnPopup() {
		this.openColumnSelection = !this.openColumnSelection;
	}

	closeSelection() {
		this.openColumnSelection = false;
	}

	searchFormToggle(event: boolean) {
		this.isSearchFormExpanded = event;
	}

	onDragColumn(event) {
		this.setSearchingStatus(true);
		of(event)
			.pipe(
				tap(() => (this.tableColumnConfig = event)),
				map((res) => res?.filter((x) => !!x?.visible)),
				mergeMap((res) =>
					iif(
						() =>
							!this.isTapLevel &&
							typeof this.saveColumnSettingsFn$ === 'function',
						this.saveColumnSettingsFn$(res).pipe(map(() => res)),
						of(res)
					)
				),
				finalize(() => this.setSearchingStatus(false)),
				take(1)
			)
			.subscribe();
	}

	export() {
		const formValue = this.formGroup.getRawValue();

		const stats = util
			.tryParseJson(formValue?.UserStatuses)
			?.filter(Boolean)
			?.map((x) => +x);

		const request = {
			...formValue,
			StartDateMin: this.formatValidDate(formValue?.StartDateMin),
			StartDateMax: this.formatValidDate(formValue?.StartDateMax),
			LastLoginMin: this.formatValidDate(formValue?.LastLoginMin),
			LastLoginMax: this.formatValidDate(formValue?.LastLoginMax),
			UserStatuses: stats,
			SecurityGroups: util.tryParseJson(formValue?.SecurityGroups) ?? [],
		};

		this.exportsService
			.queueExport(request, ExportsType.UDE)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(
						this.exportsService.getExportStatus(id)
					)
				)
			)
			.subscribe(
				(data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport(data.DocumentLink);
						this.hidePopover();
					}
				},
				(err) => {
					this.hidePopover();
				}
			);
	}

	downloadExport(url: string) {
		const name =
			this.route.snapshot.paramMap.get('companyCode') + '-Customer.csv';
		const a = this.renderer.createElement('a');

		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
