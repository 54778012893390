import * as R from 'ramda';

/**
 * SOS Pages Object
 * In order of the pages in SOA Template
 * @key : Is based from the key name in SOS object
 * @value : Match the value here to the h1/title of their pages in SOA Template
 */
export const soaSosPages = {
	lifeInsurance: 'Life Cover Calculation',
	criticalIllness: 'Trauma Cover Calculation',
	disabilityInsurance: 'Disability Benefit Calculation',
	tPDInsurance: 'TPD Cover Calculation',
	medicalInsurance: 'Medical Insurance',
};

export const loatSosPagesIds = Object.keys(soaSosPages);

export const sosMetaKey = {
	// MergeTags from FE
	introText: 'SOS_INTRO_TEXT',
	servicesIncludedList: 'SOS_SERVICES_INCLUDED_LIST',
	servicesExcludedList: 'SOS_SERVICES_EXCLUDED_LIST',
	notesText: 'SOS_NOTES_TEXT',
	otherAdviseList: 'SOS_OTHER_ADVISE_LIST',
	otherAdviceNotes: 'SOS_OTHER_ADVICE_NOTES',

	// MergeTags from BE endpoint
	intro: 'SOS_INTRO',
	servicesIncluded: 'SOS_SERVICES_INCLUDED',
	servicesExcluded: 'SOS_SERVICES_EXCLUDED',
	servicesExcludedNotes: 'SOS_SERVICES_EXCLUDED_NOTES',
	notes: 'SOS_NOTES',
	otherAdvice: 'SOS_OTHER_ADVICE',
};

export const sosMergeTags = [
	{
		metaKey: sosMetaKey.introText,
		description: 'CRT Settings > SOS > Intro Text',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: sosMetaKey.servicesIncludedList,
		description: 'CRT > Scope of Service > Services Included List',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: sosMetaKey.servicesExcludedList,
		description: 'CRT > Scope of Service > Services Excluded List',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: sosMetaKey.notesText,
		description: 'CRT > Scope of Service > Notes Text',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: sosMetaKey.otherAdviseList,
		description: 'CRT > Scope of Service > Other Advise List',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
];

export const sosOtherMt = [
	{
		metaKey: sosMetaKey.otherAdviceNotes,
		description: 'CRT > Scope of Service > Other Advise Notes',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
];

export const sosMtList = () => {
	const sosMt = R.pluck('metaKey', sosMergeTags);
	const sosMtOther = R.pluck('metaKey', sosOtherMt);
	return [...sosMt, ...sosMtOther];
};
