import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { SafeStyle } from '@angular/platform-browser';
import {
  linkColumn,
  attachments$,
  Metakey,
  columns,
} from './email-settings-datatable.config';
import { EmailSettingsQuery } from './state/email-settings.query';
import { EmailSettingsStore } from './state/email-settings.store';
import { EmailSettingsService } from './state/email-settings.service';
import {
  map,
  takeUntil,
  debounceTime
} from 'rxjs/operators';
import { Subject, of, fromEvent } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EmailSettingsModel } from './state/email-settings.model';
import { FieldMetadata } from 'src/app/shared/dynamic-field/field-metadata.model';
import { util } from 'src/app/util/util';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss'],
})
export class EmailSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('mydatatable') table: DatatableComponent;
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;

  resizeEvent$ = fromEvent(window, 'resize');
  private onDestroy$ = new Subject<void>();

  cellsLoading$ = this.query.cellsLoading$;
  cellsEditing$ = this.query.cellsEditing$;
  cellsTempValue$ = this.query.cellsTempValue$;
  totalRows: number;
  minRange = 0;
  maxRange = 0;
  pageNumber = 1;
  pageSize = 10;

  edit = this.service.edit;
  cancel = this.service.cancel;
  setTempValue = this.service.setTempValue;

  isLoading$ = this.query.selectLoading();

  form: UntypedFormGroup = this.fb.group({
    row: this.fb.array([]),
  });

  tblHeight: SafeStyle;
  linkColumn = linkColumn;
  rows$ = this.query.rows$;

  columns$ = this.query.tableColumns$;
  columnsWithLink$ = this.columns$.pipe(map((x) => [...x, linkColumn]));

  contacts$ = of([
    {
      display: 'Adviser',
      value: 'A',
    },
    {
      display: 'Other',
      value: 'O',
    },
  ]);

  contactsWithTradingName$ = of([
    {
      display: 'Adviser',
      value: 'A',
    },
    {
      display: 'Trading Name',
      value: 'T',
    },
    {
      display: 'Other',
      value: 'O',
    },
  ]);

  attachments$ = attachments$;

  constructor(
    private query: EmailSettingsQuery,
    private store: EmailSettingsStore,
    private service: EmailSettingsService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.resizeEvent$
      .pipe(debounceTime(500), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.service.reloadData();
      });
    this.query.rows$.subscribe((x) => {
      this.totalRows = x.length;
      this.minRange = 1;
      this.maxRange = this.totalRows < 10 ? this.totalRows : 10;
    });
  }

  saveField = (
    row: EmailSettingsModel,
    metakey: Metakey,
    key: string,
    metadata: FieldMetadata<any>
  ) => {
    if (metakey === 'Is Active') {
      metadata.value = !!metadata.value ? 1 : 2;
    }
    const value = metakey === 'Unsubscribe Group Id' ? +metadata?.value : metadata.value;
    this.service
      .saveField({
        id: row.EmailSettingsId,
        metakey,
        metavalue: value,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  };

  save = (
    row: EmailSettingsModel,
    metakey: Metakey,
    key: string,
    metadata: EmailSettingsModel
  ) => {
    const data = {
      value: metadata[columns?.find((x) => x.metakey === metakey).prop],
    } as FieldMetadata<any>;
    this.saveField(row, metakey, key, data);
  };

  saveIntervals = (row: EmailSettingsModel, metakey: Metakey) => {
    this.service
      .saveIntervals(row, metakey)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  };

  displayPositive(num) {
    return Math.abs(num);
  }

  setPage(event, rowCount) {
    this.pageNumber = event.page;
    this.minRange = this.pageSize * this.pageNumber - (this.pageSize - 1);
    this.maxRange =
      this.pageSize * this.pageNumber > rowCount
        ? rowCount
        : this.pageSize * this.pageNumber;
  }

  getRowHeight(row) {
    if (!row) {
      return 50;
    }
    if (row.height === undefined) {
      return 50;
    }
    return row.height;
  }

  getRowClass = (row) => {
    return {
      'row-color': true,
    };
  };

  createLookupFromEntityList(
    list: { value: string; display: string }[]
  ): { [key: string]: string } {
    return util.createLookupFromList(
      (c) => c.value,
      (c) => c.display,
      list
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
