import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html'
})
export class OthersComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @Output() othersEvent = new EventEmitter();

  // Adviser - AM Fields
  AAMF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AAMF');
  // Notes
  NF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('NF');
  // Activities
  AF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AF');
  // Documents
  DF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('DF');
  // KiwiSaver Member Number
  KMNF$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('KMNF');

  constructor(private fb: UntypedFormBuilder, private dropdownValueQuery: DropdownValueQuery) { }

  public othersForm = this.fb.group({
    othersType: ['', Validators.required]
  });

  ngOnInit() {
    // Todo: fetch from database
    this.othersForm
      .get('othersType')
      .valueChanges.pipe(
        mergeMap(type => {
          switch (type) {
            case 'NDI':
              return this.NF$;
            case 'ACTDI':
              return this.AF$;
            case 'DDI':
              return this.DF$;
            case 'ADI':
              return this.AAMF$;
            case 'KSOADI':
              return this.KMNF$;
            default:
              return of<ViewDisplayValue[]>([]);
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(x => {
        this.othersEvent.emit({ othersType: this.othersForm.get('othersType').value, dropdownValues: x });
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
