import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { isNil, isEmpty, forEach } from 'ramda';
import { Observable, Observer } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { formUtil } from 'src/app/util/form.util';
import { objectUtil } from 'src/app/util/util';
import { TopupRequirementsMapper } from '../state/topup-requirements.mapper';
import { TopupRequirementsDetailsState } from '../state/topup-requirements.model';
import { TopupRequirementsQuery } from '../state/topup-requirements.query';
@Component({
  selector: 'app-topup-requirements-form',
  templateUrl: './topup-requirements-form.component.html',
  styleUrls: ['./topup-requirements-form.component.scss']
})
export class TopupRequirementsFormComponent implements OnInit, OnChanges {

  @Input() topupRequirements: TopupRequirementsDetailsState[];
  @Input() options$: Observable<ViewDisplayValue[]>;
  @Input() addFn$: (req: any) => Observable<any>;
  @Input() updateFn$: (req: any) => Observable<any>;
  @Input() deleteFn$: (req: any) => Observable<any>;

  form: UntypedFormGroup;
  bsModalRef: BsModalRef;
  isAdd = false;
  isLoading = false;
  totalTopupRequired = 0;
  optionList;

  validators = {
    topupRequirementValue: [Validators.required, Validators.min(0)],
    topupRequirement: [Validators.required],
  }

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private query: TopupRequirementsQuery,
  ) { }

  ngOnChanges(changes) {
    if(changes.topupRequirements) {
      this.totalTopupRequired = this.query.getValue().totalTopupRequired;
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.prepareData();
  }

  get TopupList() {
    return this.form.get('topups') as UntypedFormArray;
  }

  buildForm(): void {
    this.form = this.fb.group({
      topups: this.fb.array([])
    });
  }

  createSchema(data) {
   return {
      adviceProcessId: data.adviceProcessId,
      parentCRTId: data.parentCRTId,
      cRTId: data.cRTId ? data.cRTId : null,
      topupRequirementValue: data.topupRequirementValue ? data.topupRequirementValue : 0,
      topupRequirement: isNil(data.topupRequirement) || isEmpty(data.topupRequirement) ? null : data.topupRequirement,
      other: data.other ? data.other : null,
      isLoading: false,
      isEdit: data.isEdit != null ? data.isEdit : false,
    }
  }

  prepareData() {
    this.totalTopupRequired = 0;
    const processData = (topup: TopupRequirementsDetailsState) => {
      const caseChange = objectUtil.mapPascalCaseToCamelCase(topup);
      const processed = TopupRequirementsMapper.mapToView(caseChange);
      const schema = this.createSchema(processed);
      const validator = schema.topupRequirement === 'Other' ? {
        ...this.validators,
        other: [Validators.required],
      } : this.validators;
      const group = formUtil.createFormGroup(this.fb, schema, validator);
      this.TopupList.push(group);
    }

    if (this.topupRequirements?.length) {
      forEach(processData, this.topupRequirements);
    }

    this.totalTopupRequired = this.query.getValue().totalTopupRequired;

  }

  add() {
    this.isAdd = true;
  }

  cancel() {
    this.isAdd = false;
  }

  cancelEdit(index: number) {
    if(this.TopupList.controls[index].get('cRTId').value) {
      this.resetValues(index);
      this.TopupList.controls[index].get('isEdit').setValue(false);
    }
    else {
      this.cancel();
      this.TopupList.removeAt(index);
    }
  }

  resetValues(index: number) {
    this.TopupList.controls[index].get('topupRequirement').setValue(this.topupRequirements[index].topupRequirement);
    this.TopupList.controls[index].get('topupRequirementValue').setValue(+this.topupRequirements[index].topupRequirementValue);
    this.TopupList.controls[index].get('other').setValue(this.topupRequirements[index].other);
  }

  delete(index: number) {
    this.TopupList.controls[index].get('isLoading').setValue(true);
    const cRTId = this.TopupList.controls[index].get('cRTId').value
    this.deleteFn$(+cRTId).pipe(
      take(1),
      finalize(() => {
        this.TopupList.removeAt(index);
      })
    ).subscribe();
  }

  confirmDelete(index: number) {
    const confirm = new Observable((obs: Observer<any>) => {
			this.delete(index);
			obs.complete();
		});

    const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

    const initState = {
			header: 'Delete Requirement',
			message: `Are you sure you want to delete this requirement?`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
		};

    this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
  }

  edit(index: number) {
    this.TopupList.controls[index].get('isEdit').setValue(true);
  }

  save(index: number) {
    if(this.TopupList.controls[index].invalid) {
      return;
    }

    const cRTId = this.TopupList.controls[index].get('cRTId').value;
    this.TopupList.controls[index].get('isLoading').setValue(true);
    const data = this.TopupList.controls[index].value;
    if(isNil(cRTId) || isEmpty(cRTId)) {
      this.addFn$(data).pipe(
        take(1),
        tap((addedData: TopupRequirementsDetailsState) => {
          this.TopupList.controls[index].reset(addedData);
        }),
        finalize(() => {
          this.resetDisplayMode(index);
          this.isAdd = false;
        })
      ).subscribe();
    }
    else {
      this.updateFn$(data).pipe(
        take(1),
        finalize(() => this.resetDisplayMode(index))
      ).subscribe();
    }
  }

  resetDisplayMode(index: number) {
    this.TopupList.controls[index].get('isEdit').setValue(false);
    this.TopupList.controls[index].get('isLoading').setValue(false);
  }

  handleAddNew() {
    this.isAdd = true;
    const schema = this.createSchema({
      adviceProcessId: null,
      parentCRTId: null,
      cRTId: null,
      topupRequirementValue: 0,
      topupRequirement: null,
      other: null,
      isLoading: false,
      isEdit: true,
    });
    this.TopupList.push(formUtil.createFormGroup(this.fb, schema, this.validators));
  }

  requirementChanged(index: number) {
    if(this.TopupList.controls[index].get('topupRequirement').value === 'Other') {
      this.TopupList.controls[index].get('other').setValidators(Validators.required);
    }
    else {
      this.TopupList.controls[index].get('other').setValidators(null);
    }
  }

  clearAdd() {
    if(this.isAdd) {
      this.TopupList.removeAt(this.TopupList.controls.length - 1);
      this.cancel();
    }
  }

}
