import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DocumentModelState } from '../../../../shared/models/documents/document.model';
import { ApiService } from '../../../../core/base/api.service';
import { objectUtil } from '../../../../util/util';
import { CrtSettingsQuery } from '../../state/crt-settings.query';
import { CrtSettingsStore } from '../../state/crt-settings.store';
import {
  IntroSettingsState,
  DocumentUploadState,
} from './intro-settings.model';

@Injectable()
export class IntroSettingsService {
  intro$ = this.query.intro$;

  constructor(
    private api: ApiService,
    private store: CrtSettingsStore,
    private query: CrtSettingsQuery
  ) {}

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  getIntroSettings(referenceId: number, settingsCode: string) {
    const endpoint = `crt/settings/${referenceId}/${settingsCode}`;
    return this.api.get<IntroSettingsState>(endpoint).pipe(
      tap((data) =>
        applyTransaction(() => {
          const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
          this.store.setIntroSettings(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateIntroSettings(data: IntroSettingsState) {
    const endpoint = `crt/settings/0`;
    const body = objectUtil.mapCamelCaseToPascalCase(data);
    return this.api.put<IntroSettingsState>(endpoint, body).pipe(
      tap((x) =>
        applyTransaction(() => {
          const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
          this.store.setIntroSettings(state);
        })
      )
    );
  }

  newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }

  deleteFile(id: number) {
    return this.api.delete<number>(`documents/${id}`);
  }
}
