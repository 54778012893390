import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Other } from './other.model';

export interface OtherState extends EntityState<Other> {}

@Injectable()
@StoreConfig({ name: 'other', idKey: 'cRTId' })
export class OtherStore extends EntityStore<OtherState, Other> {
	constructor() {
		super();
	}
}
