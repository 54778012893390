<div class="container-fluid">
	<div class="col pt-1 pl-0 text-dark">
		<div class="row">
			<div class="col-4 col-sm-4 text-left"></div>
			<div class="col-4 col-sm-4"></div>
			<div class="col-4 col-sm-4 text-right pr-0">
				{{ minRange }} - {{ maxRange }} of {{ totalRows }}
			</div>
		</div>
	</div>
	<div class="row" [class.one-page]="totalRows <= pageSize">
		<div class="col pt-2 px-0">
			<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
			<ngx-datatable
				#mydatatable
				class="bootstrap"
				[rows]="rows$ | async"
				[columns]="columnsWithLink$ | async"
				[columnMode]="'standard'"
				[headerHeight]="33"
				[footerHeight]="50"
				[rowHeight]="'row'"
				[limit]="10"
				[rowClass]="getRowClass"
				[scrollbarH]="true"
				[trackByProp]="'EmailSettingsId'"
			>
				<ngx-datatable-column
					*ngFor="let c of columns$ | async"
					[prop]="c.prop"
					[width]="c.width"
					[resizeable]="true"
					[cellClass]="c.cellClass"
					[draggable]="false"
					[sortable]="false"
					[name]="c.name"
					[frozenLeft]="c.metakey === 'Email Type' || c.metakey === 'Is Active'"
				>
					<ng-template
						ngx-datatable-cell-template
						let-row="row"
						let-value="value"
					>
						<ng-container [ngSwitch]="c.metakey">
							<ng-container *ngSwitchCase="'Is Active'">
								<app-switch
									[value]="value === 1"
									fieldId="{{ row.EmailSettingsId }}"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="true"
									[hideControl]="true"
									[tempValue]="
										(cellsTempValue$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isRequired]="true"
									[isEditable]="true"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="saveField(row, c.metakey, c.prop, $event)"
									(fieldUpdateEvent)="
										setTempValue(row.EmailSettingsId, c.metakey, $event)
									"
								></app-switch>
							</ng-container>

							<ng-container *ngSwitchCase="'Email Subject'">
								<app-textbox
									[value]="value"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[tempValue]="
										(cellsTempValue$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isRequired]="true"
									[isEditable]="true"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="saveField(row, c.metakey, c.prop, $event)"
									(fieldUpdateEvent)="
										setTempValue(row.EmailSettingsId, c.metakey, $event)
									"
								></app-textbox>
							</ng-container>

							<ng-container *ngSwitchCase="'Unsubscribe Group Id'">
								<app-textbox
									[value]="value"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[tempValue]="
										(cellsTempValue$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isRequired]="true"
									[isEditable]="true"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="saveField(row, c.metakey, c.prop, $event)"
									(fieldUpdateEvent)="
										setTempValue(row.EmailSettingsId, c.metakey, $event)
									"
								></app-textbox>
							</ng-container>

							<ng-container *ngSwitchCase="'Interval Date Start'">
								<app-email-settings-form
									[row]="row"
									[prop]="c.prop"
									[metakey]="c.metakey"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="saveIntervals($event, c.metakey)"
								>
								</app-email-settings-form>
							</ng-container>

							<ng-container *ngSwitchCase="'From Email'">
								<app-email-settings-form
									[row]="row"
									[prop]="c.prop"
									[metakey]="c.metakey"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="save(row, c.metakey, c.prop, $event)"
								>
								</app-email-settings-form>
							</ng-container>

							<ng-container *ngSwitchCase="'From Name'">
								<app-email-settings-form
									[row]="row"
									[prop]="c.prop"
									[metakey]="c.metakey"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="save(row, c.metakey, c.prop, $event)"
								>
								</app-email-settings-form>
							</ng-container>

							<ng-container *ngSwitchCase="'CC'">
								<app-email-settings-form
									[row]="row"
									[prop]="c.prop"
									[metakey]="c.metakey"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="save(row, c.metakey, c.prop, $event)"
								>
								</app-email-settings-form>
							</ng-container>

							<ng-container *ngSwitchCase="'BCC'">
								<app-email-settings-form
									[row]="row"
									[prop]="c.prop"
									[metakey]="c.metakey"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="save(row, c.metakey, c.prop, $event)"
								>
								</app-email-settings-form>
							</ng-container>

							<ng-container *ngSwitchCase="'Template Id'">
								<app-textbox
									[value]="value"
									[restrict]="['BEST']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[tempValue]="
										(cellsTempValue$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isRequired]="true"
									[isEditable]="true"
									(editEvent)="edit(row.EmailSettingsId, c.metakey)"
									(cancelEvent)="cancel(row.EmailSettingsId, c.metakey)"
									(saveEvent)="saveField(row, c.metakey, c.prop, $event)"
									(fieldUpdateEvent)="
										setTempValue(row.EmailSettingsId, c.metakey, $event)
									"
								></app-textbox>
							</ng-container>

							<ng-container *ngSwitchCase="'Attachment'">
								<app-multiselect
									[value]="value"
									[restrict]="['BESS']"
									[isLoading]="
										(cellsLoading$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[isEditing]="
										(cellsEditing$ | async)[
											row.EmailSettingsId + '-' + c.metakey
										]
									"
									[choices]="c.choices"
									[choicesObject]="c.choicesAsObject"
									[fieldName]="c.name"
									(saveEvent)="saveField(row, c.metakey, c.prop, $event)"
								>
								</app-multiselect>
							</ng-container>

							<ng-container *ngSwitchCase="'Development Triggers'">
								<ng-container
									*ngIf="
										value === 'ELFMB' ||
										value === 'ELFMB1' ||
										value === 'ELFMB2' ||
										value === 'ELFMBP1' ||
										value === 'ELFMBP2' ||
										value === 'ELFMBPC'
									"
								>
									<app-display
										[value]="'Activity > CreatedDateTime'"
									></app-display>
								</ng-container>
								<ng-container
									*ngIf="
										value === 'ELFMU' ||
										value === 'ELFMU1' ||
										value === 'ELFMU2'
									"
								>
									<app-display
										[value]="'Activity > ModifiedDateTime'"
									></app-display>
								</ng-container>
								<ng-container *ngIf="value === 'ELFMR'">
									<app-display [value]="'Activity > DueDateTime'"></app-display>
								</ng-container>
								<ng-container
									*ngIf="
										value === 'ELRBNP' ||
										value === 'ELRPI' ||
										value === 'ELRWK' ||
										value === 'ELRFR'
									"
								>
									<app-display
										[value]="'L&R > Policy Start Date'"
									></app-display>
								</ng-container>
								<ng-container *ngIf="value === 'ELROTR'">
									<app-display
										[value]="'L&R > Policy Status Updated'"
									></app-display>
								</ng-container>
								<ng-container *ngIf="value === 'ELRAL'">
									<app-display [value]="'L&R > Anniversary Date'"></app-display>
								</ng-container>
								<ng-container *ngIf="value === 'ERMO'">
									<app-display
										[value]="'Primary Customer > Next Review Date'"
									></app-display>
								</ng-container>
								<ng-container
									*ngIf="
										value === 'ERMB' || value === 'ERMB1' || value === 'ERMB2'
									"
								>
									<app-display
										[value]="'Activity > CreatedDateTime'"
									></app-display>
								</ng-container>
								<ng-container
									*ngIf="
										value === 'ERMU' || value === 'ERMU1' || value === 'ERMU2'
									"
								>
									<app-display
										[value]="'Activity > ModifiedDateTime'"
									></app-display>
								</ng-container>
								<ng-container *ngIf="value === 'ERMR' || value === 'ERMRMR'">
									<app-display [value]="'Activity > DueDateTime'"></app-display>
								</ng-container>
								<ng-container *ngIf="value === 'EKSWE'">
									<app-display [value]="'KiwiSaver > Start Date'"></app-display>
								</ng-container>
								<ng-container
									*ngIf="
										value === 'EMRRE1' ||
										value === 'EMRRE2' ||
										value === 'EMRRE3'
									"
								>
									<app-display
										[value]="'Mortgage >  Fixed Period End Date'"
									></app-display>
								</ng-container>
								<ng-container *ngIf="value === 'EBE'">
									<app-display
										[value]="'Customer > Date of Birth'"
									></app-display>
								</ng-container>
							</ng-container>

							<ng-container *ngSwitchDefault>
								<ng-container *ngIf="c.controlType === 'display'">
									<app-display [value]="value"></app-display>
								</ng-container>
								<ng-container *ngIf="c.controlType === 'long-display'">
									<app-long-display
										[value]="value"
										[class.wrap]="c.metakey === 'Template Id'"
									></app-long-display>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-footer>
					<ng-template
						ngx-datatable-footer-template
						let-rowCount="rowCount"
						let-pageSize="pageSize"
						let-selectedCount="selectedCount"
						let-curPage="curPage"
						let-offset="offset"
						let-isVisible="isVisible"
					>
						<div class="page-count">
							{{ pageSize * curPage - (pageSize - 1) }} -
							{{
								pageSize * curPage > rowCount ? rowCount : pageSize * curPage
							}}
							of
							{{ rowCount.toLocaleString() }}
						</div>
						<datatable-pager
							[pagerLeftArrowIcon]="'datatable-icon-left'"
							[pagerRightArrowIcon]="'datatable-icon-right'"
							[pagerPreviousIcon]="'datatable-icon-prev'"
							[pagerNextIcon]="'datatable-icon-skip'"
							[page]="curPage"
							[size]="pageSize"
							[count]="rowCount"
							[hidden]="!(rowCount / pageSize > 1)"
							(change)="table.onFooterPage($event); setPage($event, rowCount)"
						>
						</datatable-pager>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>
