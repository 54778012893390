import { createDeedOfAcknowledgementTemplate } from '../deed-of-acknowledgement.template';
import { createBoarderConfirmationTemplate } from '../boarder-confirmation.template';
import { createGiftingLetterTemplate } from '../gifting-letter.template';
import { createCovidDeclarationTemplate } from '../covid-declaration.template';

export const createMOATDocumentTemplate = (documentName: string, data) => {
	let result = '';
	switch (documentName) {
		case 'Deed of Acknowledgement of Debt':
			result = createDeedOfAcknowledgementTemplate(data);
			break;
		case 'Boarder Confirmation':
			result = createBoarderConfirmationTemplate(data);
			break;
		case 'Gifting Letter':
			result = createGiftingLetterTemplate(data);
			break;
		case 'Financial Declaration':
			result = createCovidDeclarationTemplate(data);
			break;
		default:
			break;
	}
	return result;
};
