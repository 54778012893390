import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	TlMoatSettingsState,
	TlMoatSettingsStore,
} from './tl-moat-settings.store';

@Injectable()
export class TlMoatSettingsQuery extends Query<TlMoatSettingsState> {
	/**
	 * @returns observable of Servicing Calculators Settings
	 */
	servicingCalculators$ = this.select((state) => state.servicingCalculators);

	constructor(protected store: TlMoatSettingsStore) {
		super(store);
	}
}
