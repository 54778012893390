import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	Renderer2,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import { viewSecGroup } from '../../viewmodels/viewSecGroup';
import {
	BlStaffViewmodel,
	defaultConversions,
} from '../../viewmodels/bl-staff.viewmodel';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
	finalize,
	tap,
	mergeMap,
	catchError,
	map,
	takeUntil,
	concatMap,
	filter,
	take,
} from 'rxjs/operators';
import {
	Observer,
	Observable,
	of,
	throwError,
	Subject,
	combineLatest,
} from 'rxjs';
import { ActionLoaderService } from '../../../../core/loader/loader.service';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { ServicesQuery } from '../../../../domain/service/services.query';
import {
	BLStaffsService,
	TransferActivityModel,
} from '../../../../domain/bl-staff/bl-staffs.service';
import { util } from '../../../../core/util/util.service';
import sort from 'fast-sort';
import { strUtil } from '../../../../util/util';
import { DeleteDropdownModalComponent } from '../../../../shared/modal/delete-dropdown-modal/delete-dropdown-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BlStaffService } from '../../../../core/staff/bl-staff.service';
import { ProvideAccessModalComponent } from '../../../../shared/modal/provide-access-modal/provide-access-modal.component';
import { TransferActivityModalComponent } from '../../../../shared/modal/transfer-activity-modal/transfer-activity-modal.component';
import { UserQuery } from '../../../../domain/user/user.query';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';

// For BL
@Component({
	selector: 'app-view-user',
	templateUrl: './view-user.component.html',
	styleUrls: ['./view-user.component.scss'],
	providers: [ActionLoaderService],
})
export class ViewUserComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input()
	set staff(value: BlStaffViewmodel) {
		this._data = value;
		this.form.reset(value);
		this.form.get('StaffSettings.ShowInAdviserList').enable();
	}
	@Input()
	set isView(value: boolean) {
		if (value) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}
	@Input() companyCode: string;

	hasCPMOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasMOAT$,
		this.businessConfigQuery.hasCPMOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasMOAT$,
		this.userQuery.isUserHasCPMOAT$,
	]).pipe(
		map(
			([
				hasbusinessAP,
				hasbusinessMOAT,
				hasBusinessCPMOAT,
				hasUserAP,
				hasUserMOAT,
				hasUserCPMOAT,
			]) =>
				hasbusinessAP &&
				hasbusinessMOAT &&
				hasBusinessCPMOAT &&
				hasUserAP &&
				hasUserMOAT &&
				hasUserCPMOAT
		)
	);

	get IsBlanket() {
		return this.companyCode.toLowerCase() === 'blanket';
	}

	constructor(
		private fb: UntypedFormBuilder,
		private blStaffQuery: BLStaffsQuery,
		private servicesQuery: ServicesQuery,
		private modalService: BsModalService,
		private blStaffService: BLStaffsService,
		private blService: BlStaffService,
		private blsStaffService: BLStaffsService,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery,
		private renderer: Renderer2
	) {
		this.buildForm();
		this.form
			.get('StaffSettings.ShowInAdviserList')
			.valueChanges.pipe(
				filter(() => this.form.disabled),
				map(() => {
					const payload = this.prepareData();
					payload.StaffSettings.ShowInAdviserList =
						!payload.StaffSettings.ShowInAdviserList;
					return payload;
				}),
				concatMap((x) => {
					return this.onChangeSubmit(x);
				}),
				finalize(() => (this.isSaving = false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	get FirstName() {
		return this.form.get('FirstName');
	}
	get LastName() {
		return this.form.get('LastName');
	}
	get EmailAddress() {
		return this.form.get('EmailAddress');
	}
	get SecurityGroup() {
		return this.form.get('SecurityGroup');
	}
	get StartDate() {
		return this.form.get('StartDate');
	}
	get Stats() {
		return this.form.get('Stats');
	}
	get isHaven() {
		return this.companyCode?.toLowerCase() === 'haven';
	}
	private onDestroy$ = new Subject<void>();
	isActiveOptions = [
		{ name: 'YES', option: 1 },
		{ name: 'PAUSED', option: 2 },
		{ name: 'REMOVED', option: 0 },
	];

	// tslint:disable-next-line: variable-name
	_data: BlStaffViewmodel;
	form: UntypedFormGroup;
	@Input() secGroups: viewSecGroup[] = [];
	@Input() LRP: ViewDisplayValue[] = [];
	// tslint:disable-next-line: member-ordering
	staffChoices: any[] = [];
	// tslint:disable-next-line: member-ordering
	inActiveStaffChoices: ViewDisplayValue[] = [];
	// tslint:disable-next-line: member-ordering
	serviceChoices: ViewDisplayValue[] = [];
	// tslint:disable-next-line: member-ordering
	isSaving = false;
	// tslint:disable-next-line: member-ordering
	showAllStaffs = false;
	// tslint:disable-next-line: member-ordering
	adviserIdsAssignedList = [];

	// tslint:disable-next-line: member-ordering
	isSavingProvideAccess = false;
	// tslint:disable-next-line: member-ordering
	@Input() btnText = 'Save';
	// tslint:disable-next-line: member-ordering
	@Input() header = '';
	// tslint:disable-next-line: member-ordering
	@Input() isAdd = false;

	// tslint:disable-next-line: member-ordering
	@Output() cancelEvent = new EventEmitter();
	@Output() deleteUser: EventEmitter<number> = new EventEmitter<number>();
	@ViewChild('showInAdviser') showInAdviserRef: ElementRef;
	@Input() onSubmitClick: (data: BlStaffViewmodel) => Observable<any> = (
		data
	) => of(data);
	@Input() onChangeSubmit: (data: BlStaffViewmodel) => Observable<any> = (
		data
	) => of(data);
	@Input() onSubmitProvideAccess: (data: BlStaffViewmodel) => Observable<any> =
		(data) => of(data);

	buildForm() {
		this.form = this.fb.group({
			AdviserManager: null,
			BirthDate: util.emptyMoment(),
			BusinessPhone: [''],
			EmailAddress: ['', Validators.required],
			FirstName: ['', Validators.required],
			HomeAddress: [''],
			IsActive: [''],
			LastName: ['', Validators.required],
			MobilePhone: [''],
			SecurityGroup: ['', Validators.required],
			Services: [],
			StaffID: null,
			StartDate: ['', Validators.required],
			// AdviserIdsAssigned: [],
			Stats: this.fb.group({
				fsp_number: '',
				self_gen_quarter: '',
				api_quarter: '',
				self_gen_annual: '',
				api_annual: '',
				kiwisaver_quarter: '',
				kiwisaver_annual: '',
				mortgage_quarter: '',
				mortgage_annual: '',
				blanketnewbusiness_quarter: '',
				blanketnewbusiness_annual: '',
				domestic_fg_quarter: '',
				domestic_fg_annual: '',
				commercial_fg_quarter: '',
				commercial_fg_annual: '',
				// client_conversion: '',
				lr_conversion: '',
				kiwisaver_conversion: '',
				blanket_conversion: '',
			}),
			StaffSettings: this.fb.group({
				FacebookLink: '',
				LinkedInLink: '',
				SignOffName: '',
				CorrespondenceEmail: '',
				ShowInAdviserList: [{ value: false, disabled: false }],
			}),
		});
	}

	ngOnInit() {
		this.adviserIdsAssignedList = this._data.AdviserIdsAssigned;

		this.blStaffQuery
			.selectAll()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((staff) => {
				this.staffChoices = staff
					?.filter((x) => x.IsActive || x.IsActive === 0)
					?.map((s) => {
						const viewDisplay = ViewDisplayValue.Map(
							s.StaffID?.toString(),
							`${s.FirstName} ${s.LastName}`,
							false,
							s.IsActive === 1 ? true : false
						)

						return {
							securityGroup: this.mapSecGroup(s?.SecurityGroup),
							status: s.IsActive,
							...viewDisplay,
							value: +viewDisplay.value,
						}
					})
					?.sort(this.compare)
					?.filter((x) => {
						if (this._data && this._data.StaffID) {
							return x.value !== (this._data.StaffID || '');
						}
						return true;
					});

				this.staffChoices = sort(this.staffChoices).by([
					{ desc: (u) => u.isActive },
					{
						asc: (u) =>
							u.display && typeof u.display === 'string'
								? u.display?.toLowerCase()
								: u.display,
					},
				]);
			});

		this.serviceChoices = this.servicesQuery
			.getAll()
			?.map((x) => ViewDisplayValue.Map(x.ServiceCode, x.Service));
	}

	public mapSecGroup(secGroup: string) {
    const security = this.secGroups.find((sec) => sec.securityGroupCode === secGroup);

    if (security) {
      return security.securityGroupName;
    }

    return secGroup;
  }

	ngAfterViewInit() {
		this.renderer?.removeAttribute(
			this.showInAdviserRef?.nativeElement,
			'disabled'
		);
	}

	hasService(code: string): boolean {
		return (this.form.get('Services').value as string[])?.some(
			(x) => x?.toLowerCase() === code?.toLowerCase()
		);
	}
	checkToggle(code: string | number, checked: boolean) {
		const id = code?.toString();
		if (this.hasService(id)) {
			if (!checked) {
				const newServices = (
					this.form.get('Services').value as string[]
				)?.filter((x) => x !== id);
				this.form.patchValue({ services: newServices });
			}
		} else {
			if (checked) {
				const newServices = this.form.get('Services').value as string[];
				newServices.push(id);
				this.form.patchValue({ Services: newServices });
			}
		}
	}

	prepareData(): BlStaffViewmodel {
		const formValue = this.form.value;
		return Object.assign(this._data || {}, {
			...formValue,
			// tslint:disable-next-line: no-string-literal
			Services: this.form.controls['Services'].value?.filter((x) => x),
			// tslint:disable-next-line: no-string-literal
			EmailAddress: strUtil.removeSpace(
				this.form.controls['EmailAddress'].value
			),
			StaffSettings: {
				...this._data.StaffSettings,
				FacebookLink: formValue.StaffSettings.FacebookLink,
				LinkedInLink: formValue.StaffSettings.LinkedInLink,
				SignOffName: formValue.StaffSettings.SignOffName,
				CorrespondenceEmail: strUtil.removeSpace(
					formValue.StaffSettings.CorrespondenceEmail
				),
				ShowInAdviserList: !!formValue?.StaffSettings?.ShowInAdviserList,
			},
		});
	}

	removeSpaces() {
		return (this.form.value.emailAddress = (
			this.form.value.emailAddress || ''
		)?.replace(/\s/g, ''));
	}

	delete(id: number) {
		this.deleteUser.emit(id);
	}

	submitBtnClick() {
		if (this.form.invalid) {
			return;
		}
		if (this.btnText === 'Save') {
			const confirm = new Observable((obs: Observer<any>) => {
				obs.next(obs);
				obs.complete();
			}).pipe(
				tap(() => {
					this.save();
				})
			);

			const staffs = this.blStaffQuery.getAll();
			const activeBlStaffs = staffs
				?.filter(
					(x) => x.IsActive && x.StaffID !== this.form.get('StaffID').value
				)
				?.map((y) => {
					return {
						StaffID: y.StaffID,
						FirstName: y.FirstName,
						LastName: y.LastName,
					};
				})
				?.sort((a, b) => {
					const mapA = a.FirstName?.concat(' ', a.LastName);
					const mapB = b.FirstName?.concat(' ', b.LastName);
					return mapA?.localeCompare(mapB);
				});

			if (this.form.get('IsActive').value === '0') {
				const blInitState = {
					header: 'Delete Staff',
					staff: {
						StaffID: this.form.get('StaffID').value,
						FirstName: this.form.get('FirstName').value,
						LastName: this.form.get('LastName').value,
					},
					advisers: activeBlStaffs,
					confirm$: confirm,
					transfer: this.transferActivity,
				};

				this.modalService.show(DeleteDropdownModalComponent, {
					class: 'modal-dialog-centered',
					initialState: blInitState,
					ignoreBackdropClick: true,
				});
			} else {
				this.save();
			}
		}

		if (this.btnText === 'Edit' || this.btnText === 'Add') {
			this.save();
		}
	}

	cancel() {
		this.cancelEvent.emit();
	}

	transferActivity = (data: TransferActivityModel) =>
		new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.blStaffService.TransferActivity(x)),
			tap((x) => {
				if (x) {
					this.exportHistory(x);
				}
			}),
			finalize(() => {
				this.save();
			})
		);

	save() {
		of(this.prepareData())
			.pipe(
				tap(() => (this.isSaving = true)),
				mergeMap(this.onSubmitClick),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();
	}

	saveProvideAccess = (data) =>
		new Observable<any>((obs) => {
			obs.next({ ...this.prepareData(), BLSecurityGroups: data });
			obs.complete();
		}).pipe(
			tap(() => (this.isSavingProvideAccess = true)),
			mergeMap(this.onSubmitProvideAccess),
			finalize(() => (this.isSavingProvideAccess = false))
		);

	provideAccess() {
		const initialState = {
			header: 'Provide Access',
			securityGroups: this.secGroups,
			savefn: this.saveProvideAccess,
		};
		this.modalService.show(ProvideAccessModalComponent, {
			class: 'modal-dialog-centered',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	TransferActivity() {
		const activeBlStaffs = this.blStaffQuery
			.getAll()
			?.filter(
				(x) => x.IsActive && x.StaffID !== this.form.get('StaffID').value
			)
			?.map((y) => {
				return {
					StaffID: y.StaffID,
					FirstName: y.FirstName,
					LastName: y.LastName,
				};
			});

		const blInitState = {
			header: 'Transfer Activities',
			staff: {
				StaffID: this.form.get('StaffID').value,
				FirstName: this.form.get('FirstName').value,
				LastName: this.form.get('LastName').value,
			},
			advisers: activeBlStaffs?.sort((a, b) => {
				const mapA = a.FirstName?.concat(' ', a.LastName);
				const mapB = b.FirstName?.concat(' ', b.LastName);
				return mapA?.localeCompare(mapB);
			}),
			transferFn: this.transferActivityNoSave,
		};

		this.modalService.show(TransferActivityModalComponent, {
			class: 'modal-dialog-centered',
			initialState: blInitState,
			ignoreBackdropClick: true,
		});
	}

	transferActivityNoSave = (data: TransferActivityModel) =>
		this.blStaffService.TransferActivity(data).pipe(
			tap((x) => {
				if (x) {
					this.exportHistory(x);
				}
			})
		);

	exportHistory(file: any) {
		const name = 'activities.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		const url = window.URL.createObjectURL(file);
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
		window.URL.revokeObjectURL(url);
	}

	compare(a, b) {
		const splitA = a.display?.split(' ');
		const splitB = b.display?.split(' ');

		const firstA = splitA[0];
		const firstB = splitB[0];

		const lastA = splitA[splitA.length - 1];
		const lastB = splitB[splitB.length - 1];

		if (firstA > firstB) {
			return 1;
		} else if (firstA < firstB) {
			return -1;
		} else {
			if (lastA < lastB) {
				return -1;
			} else if (lastA > lastB) {
				return 1;
			} else {
				return 0;
			}
		}
	}

	showAll() {
		this.showAllStaffs = !this.showAllStaffs;
	}

	removeFileFn$ = ({ file, type }) => {
		return of(this.removeSetDocument(file, type)).pipe(
			mergeMap(() => of(this.prepareData()).pipe(mergeMap(this.update))),
			catchError(() => {
				this.removeSetDocument(file, type);
				return throwError('error');
			})
		);
	};

	removeSetDocument(data, type) {
		this._data = Object.assign(this._data, {
			...this._data,
			StaffSettings: {
				...this._data.StaffSettings,
				DisclosureDocument:
					type === 'Disclosure' && !data.FileName
						? null
						: this._data.StaffSettings.DisclosureDocument,
				// tslint:disable-next-line: max-line-length
				DeclarationDocument:
					type === 'Declaration' && !data.FileName
						? null
						: this._data.StaffSettings.DeclarationDocument,
				ScopeOfService:
					type === 'Scope of Service' && !data.FileName
						? null
						: this._data.StaffSettings.ScopeOfService,
				// tslint:disable-next-line: max-line-length
				LetterOfAuthority:
					type === 'Letter of Authority' && !data.FileName
						? null
						: this._data.StaffSettings.LetterOfAuthority,
				// tslint:disable-next-line: max-line-length
				BusinessOverview:
					type === 'Business Overview' && !data.FileName
						? null
						: this._data.StaffSettings.BusinessOverview,
			},
		});
	}

	uploadFileFn$ = (data: { file; docType }) => {
		return this.blStaffService.UploadDocument(data.file).pipe(
			mergeMap((id) => this.blService.getDocumentById(+id)),
			mergeMap((document: any) => {
				this.setDocument(data, document.DocumentID);
				return of('Success');
			}),
			mergeMap(() => of(this.prepareData()).pipe(mergeMap(this.update))),
			catchError(() => {
				this.setDocument(data, null);

				return throwError('error');
			})
		);
	};

	setDocument(data, documentId) {
		this._data = Object.assign(this._data, {
			...this._data,
			StaffSettings: {
				...this._data.StaffSettings,
				DisclosureDocument:
					data.docType === 'Disclosure' && document
						? documentId
						: this._data.StaffSettings.DisclosureDocument,
				// tslint:disable-next-line: max-line-length
				DeclarationDocument:
					data.docType === 'Declaration' && document
						? documentId
						: this._data.StaffSettings.DeclarationDocument,
				ScopeOfService:
					data.docType === 'Scope of Service' && document
						? documentId
						: this._data.StaffSettings.ScopeOfService,
				// tslint:disable-next-line: max-line-length
				LetterOfAuthority:
					data.docType === 'Letter of Authority' && document
						? documentId
						: this._data.StaffSettings.LetterOfAuthority,
				// tslint:disable-next-line: max-line-length
				BusinessOverview:
					data.docType === 'Business Overview' && document
						? documentId
						: this._data.StaffSettings.BusinessOverview,
			},
		});
	}

	update = (data) =>
		of(data).pipe(
			map((x) => BlStaffViewmodel.mapToEditModel(x)),
			mergeMap((x) => this.blsStaffService.update(x))
		);

	getDocumentById(id) {
		return this.blService.getDocumentById(+id);
	}

	securityChange() {
		const security = this.SecurityGroup.value;

		if (this.isHaven) {
			let lr_conversion = null;
			let kiwisaver_conversion = null;
			let blanket_conversion = null;
			let mortgage_conversion = null;

			if (['AM', 'A', 'BO']?.includes(security)) {
				lr_conversion = defaultConversions.lr_conversion;
				kiwisaver_conversion = defaultConversions.kiwisaver_conversion;
				blanket_conversion = defaultConversions.blanket_conversion;
				mortgage_conversion = defaultConversions.mortgage_conversion;
			}
			this.Stats.get('lr_conversion').setValue(lr_conversion);
			this.Stats.get('kiwisaver_conversion').setValue(kiwisaver_conversion);
			this.Stats.get('blanket_conversion').setValue(blanket_conversion);
			this.Stats.get('blanket_conversion').setValue(blanket_conversion);
		}
	}

	updateAccessiblitySettings$ = (event: any) => {
		const { staff, adviserIds } = event;
		return this.blStaffService.updateStaffSettings(
			staff.StaffID,
			{ AdviserIdsAssigned: adviserIds },
			'UTA'
		);
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
