import {
	Component,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AssetsLiabilitiesService } from '../states/assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';

@Component({
	selector: 'app-assets-liabilities',
	templateUrl: './assets-liabilities.component.html',
	styleUrls: ['./assets-liabilities.component.scss'],
})
export class AssetsLiabilitiesComponent implements OnInit {
	onDestroy$ = new Subject<void>();

	su$ = this.service.SU$;
	kp$ = this.service.KP$;
	apcrta$ = this.service.APCRTA$;
	mp$ = this.service.MP$;
	apcrtf$ = this.service.APCRTF$;
	APCRTYN$ = this.service.APCRTYN$;
	APCRTYNNA$ = this.service.APCRTYNNA$;

	public bsModalRef: BsModalRef;

	elseMinusProperty = true;
	elseMinusKiwiSaver = true;
	elseMinusOthers = true;
	elseMinusMortgages = true;
	elseMinusLiabilities = true;
	iconShow = true;
	submitted = false;

	dataArray = [];
	demoArray = [];

	arrayItems: {
		id: number;
		title: string;
	}[];

	form: UntypedFormGroup;

	// List
	properties$ = this.service.properties$;
	kiwiSavers$ = this.service.kiwiSavers$;
	others$ = this.service.others$;
	mortgages$ = this.service.mortgages$;
	liabilities$ = this.service.liabilities$;
	policyOwners$ = this.query.policyOwnersWithCRT$;
	getPropertyAddresses$ = this.service.getPropertyAddresses$;
	propertyAddresses$ = this.service.propertyAddresses$;
	lifeAssuredList$ = this.query.peopleFromCrmAndCrtChoices$;
	alPolicyOwners$ = this.query.pciAndSciFromCrmAndCrtChoices$.pipe(
		map((people) => people?.filter((person) => +person.value))
	);
	
	alPolicyOwnersWithBusinessTrusts$ = this.query.pciAndSciFromCrmAndCrtChoicesWithBusinessTrusts$.pipe(
		map((people) => people?.filter((person) => +person.value))
	);

	// Computations
	totalPropertyValues$ = this.service.totalPropertyValues$;

	isLoading = false;
	isEditing = false;

	isLoading$ = this.query.isOthersLoading$;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$;

	deceasedSciList$ = this.query.deceasedSciList$;

	constructor(
		private fb: UntypedFormBuilder,
		private service: AssetsLiabilitiesService,
		private query: ClientReviewTemplateQuery
	) {
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			AdviceProcessId: [''],
			CRTId: [''],
			HasPersonalGuarantees: ['', Validators.required],
			PersonalGuaranteesDetails: [''],
			HasFinancialResponsibilities: ['', Validators.required],
			FinancialResponsibilitiesDetails: [''],
			HasCurrentWill: ['', Validators.required],
			CurrentWillDetails: [''],
		});
	}

	collapseFalse() {
		this.elseMinusProperty = false;
		this.elseMinusKiwiSaver = false;
		this.elseMinusOthers = false;
		this.elseMinusMortgages = false;
		this.elseMinusLiabilities = false;
	}

	ngOnInit(): void {
		this.arrayItems = [];

		this.prepareData();
	}

	prepareData() {
		this.form.reset();
		this.others$
			.pipe(
				tap((x) => {
					if (!!x && x.length > 0) {
						const data = {
							AdviceProcessId: x[0]?.adviceProcessId,
							CRTId: x[0]?.cRTId,
							HasPersonalGuarantees: x[0]?.hasPersonalGuarantees,
							PersonalGuaranteesDetails: x[0]?.personalGuaranteesDetails,
							HasFinancialResponsibilities: x[0]?.hasFinancialResponsibilities,
							FinancialResponsibilitiesDetails:
								x[0]?.financialResponsibilitiesDetails,
							HasCurrentWill: x[0]?.hasCurrentWill,
							CurrentWillDetails: x[0]?.currentWillDetails,
						};
						this.form.reset(data);
					}
				})
			)
			.subscribe();
	}

	disableForm() {
		this.isEditing = false;
		this.prepareData();
		this.form.disable();
	}

	enableForm() {
		this.isEditing = true;
		this.form.enable();
	}

	onValueChanges() {
		this.service.setAdditionalQuestions({
			...this.form.value,
			adviceProcessId: this.query.getValue().adviceProcessId,
			invalid: this.form.invalid,
		});
	}
}
