import {
	AfterViewInit,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
	finalize,
	first,
	map,
	mergeMap,
	startWith,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { RecursivePartial } from '@core/util/util.service';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '@domain/user/user.query';
import {
	AdviceProcessCode,
	AdviceProcessStages,
	AdviceProcessTypesList,
} from '@models/advice-process/advice-process.model';
import {
	ContactStatusCode,
	GetActiveContactStatuses,
	GetContactStatusViewDisplay,
} from '@models/_general/client.model';
import { ViewDisplayValue } from '@models/_general/display-value.viewmodel';
import { AdviceProcessRequest } from '../advice-process-request.model';
import { request } from '../advice-process.util';
import { AdviceProcessUiQuery } from '../states/advice-process-ui.query';
import { AdviceProcessUiStore } from '../states/advice-process-ui.store';
import {
	AdviceStatuses,
	GetAdviceDocumentStatusViewDisplay,
	NotStartedStage,
} from '../states/advice-process.model';
import { AdviceProcessQuery } from '../states/advice-process.query';
import { AdviceProcessService } from '../states/advice-process.service';
import { AdviceProcessStore } from '../states/advice-process.store';
import { ServicesCodes } from '@models/services/services.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { staticConf } from '@staticConfig';
import MomentUtil from '@momentUtil';
import * as R from 'ramda';
import {
	ExportsStatus,
	ExportsType,
} from '@modules/exports/state/exports.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsService } from '@modules/exports/state/exports.service';
import { omit } from 'ramda';
declare var $: any;

const patchValue: <T>(
	form: AbstractControl,
	value: RecursivePartial<T>
) => void = (form, value) => {
	form.patchValue(value);
};

@Component({
	selector: 'app-advice-process-form',
	templateUrl: './advice-process-form.component.html',
	styleUrls: ['./advice-process-form.component.scss'],
})
export class AdviceProcessFormComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$: Subject<void> = new Subject<void>();

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	exportStatus$ = this.exportsQuery.status$;

	@Output() toggleSearch = new EventEmitter<{
		showSearch: boolean;
		height: number;
		width: number;
	}>();

	isSearching$ = this.query.uiQuery.isSearching$;
	isExporting$ = this.query.uiQuery.isExporting$;
	columnFromOpen$ = this.query.uiQuery.columnFormPopupOpen$;

	openColumnPopup = this.service.togglePopup;
	count$ = this.query.count$;

	isTapLevel$ = this.userQuery.isTapLevel$;
	userInfo$ = this.userQuery.userInfo$;

	adviceStatuses$ = of([
		{ display: AdviceStatuses.InProgress, value: '1' },
		{ display: AdviceStatuses.EndedCompleted, value: '3' },
		{ display: AdviceStatuses.EndedIncomplete, value: '4' },
		{ display: AdviceStatuses.Cancelled, value: '6' },
	]);

	adviceDocuments$ = of(GetAdviceDocumentStatusViewDisplay());

	businessServices = this.businessConfigQuery.getValue().config.Services;

	types = AdviceProcessTypesList;

	filteredTypes = this.types.filter((type) =>
		!type.code ? true : this.businessServices.includes(type.code)
	);
	filteredUserTypes = this.userQuery.isTapLevel()
		? this.filteredTypes
		: this.filteredTypes.filter((type) => {
				return !type.code
					? true
					: JSON.parse(this.userQuery.getValue().Services).includes(type.code)
		});

	LeadTypeChoices$ = this.dropdownValueQuery.orderedChoices$('PCLT');
	LeadOriginChoices$ = this.dropdownValueQuery.orderedChoices$('PCLE');
	OfflineOnlineChoice$ = this.dropdownValueQuery.offlineOnlineChoice$;
	OfflineOnlineChoice: ViewDisplayValue[] = [];
	AdviceStagesChoices: ViewDisplayValue[] = [];
	offlineAP = [
		AdviceProcessCode.MortgageRefix,
		AdviceProcessCode.BlanketAdvice,
		AdviceProcessCode.BlanketAdvice,
		AdviceProcessCode.Complaint,
		AdviceProcessCode.LRAdviceAlteration,
		AdviceProcessCode.Investment,
		AdviceProcessCode.FGDomesticNewBusinessAdvice,
		AdviceProcessCode.FGCommercialNewBusinessAdvice,
		AdviceProcessCode.FGDomesticMTAAdvice,
		AdviceProcessCode.FGCommercialMTAAdvice,
		AdviceProcessCode.FGDomesticRenewalAdvice,
		AdviceProcessCode.FGCommercialRenewalAdvice,
	]?.map((x) => x?.toString());
	notStarted = NotStartedStage.stage;

	// Get Active and (Paused and Inactive A and AM)
	availableStaffChoices$: Observable<ViewDisplayValue[]> =
		this.bLStaffsQuery.adviserChoicesOption$;

	reviewedBy$ = this.bLStaffsQuery.availableStaffs$.pipe(
		map((x) =>
			R.filter(
				(y) =>
					y.SecurityGroup === 'BO' ||
					y.SecurityGroup === 'BAI' ||
					y.SecurityGroup === 'BM' ||
					y.SecurityGroup === 'AM',
				x
			)
		),
		map((x) =>
			R.map(
				(y) =>
					ViewDisplayValue.Map(
						y.StaffID?.toString(),
						`${y.FirstName} ${y.LastName}`
					),
				x
			)
		)
	);

	readonly contactStatusChoices$ = of(GetContactStatusViewDisplay());

	form: UntypedFormGroup = this.fb.group({
		Advisers: this.fb.control([]),
		AltAdvisers: this.fb.control([]),
		ReviewedBy: this.fb.control([]),

		AdviceProcesses: this.fb.control([]),
		AdviceStatuses: this.fb.control([]),

		AdviceDocuments: this.fb.control([]),
		LeadTypes: this.fb.control([]),

		AdviceOnlineOffline: this.fb.control([]),
		AdviceStages: this.fb.control([]),

		LeadOrigins: this.fb.control([]),
		ContactStatuses: this.fb.control([ContactStatusCode.CurrentClient]),

		AdviceStartDateMin: this.fb.control(null),
		AdviceStartDateMax: this.fb.control(null),

		AdviceEndDateMin: this.fb.control(null),
		AdviceEndDateMax: this.fb.control(null),

		NextActivityDateMin: this.fb.control(null),
		NextActivityDateMax: this.fb.control(null),
	} as { [key in keyof AdviceProcessRequest]: UntypedFormControl });

	showSearch = false;
	isTapLevel: boolean;

	params$ = this.route.paramMap;

	////////// Progress Bar //////////////
	progress = {
		width: '0%',
	};
	index = 0;
	percent = 0;
	count = 0;
	totalCount = 0;
	modalRef: BsModalRef;
	msg = 'Export Progress';
	///////// END Progress Bar ////////

	hasOnline: boolean;
	hasOffline: boolean;

	claimsFeature:boolean = this.businessConfigQuery.getValue()?.config?.Claims;
	

	get FormAdviceProcesses() {
		return this.form.get('AdviceProcesses');
	}
	get FormAdviceOnlineOffline() {
		return this.form.get('AdviceOnlineOffline');
	}
	get FormAdviceStages() {
		return this.form.get('AdviceStages');
	}

	constructor(
		private fb: UntypedFormBuilder,
		private bLStaffsQuery: BLStaffsQuery,
		private userQuery: UserQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private service: AdviceProcessService,
		private query: AdviceProcessQuery,
		public store: AdviceProcessStore,
		public uiQuery: AdviceProcessUiQuery,
		public uiStore: AdviceProcessUiStore,
		private businessConfigQuery: BusinessConfigQuery,
		private exportsQuery: ExportsQuery,
		private exportsService: ExportsService
	) {}

	ngOnInit(): void {
		//Filter Claims process code
		this.filteredUserTypes = this.filteredUserTypes.filter((item)=>{
			if((item.value=== AdviceProcessCode.LRClaim||item.value=== AdviceProcessCode.FGClaim)&&!this.claimsFeature){
				return false;
			}
			return true;
		});
		this.setAdviceStagesDropdown();

		this.isTapLevel$
			.pipe(withLatestFrom(this.userInfo$), takeUntil(this.onDestroy$))
			.subscribe(([isTapLevel, user]) => {
				this.reset();
				if (isTapLevel) {
					this.service?.sort('PolicyStatus', 'desc');

					patchValue(this.form, {
						ContactStatuses: GetActiveContactStatuses(),
					});
				} else {
					this.service?.sort('NextActivity', 'desc');

					patchValue(this.form, {
						Advisers:
							!['BO', 'AM', 'A'].includes(user.SecurityGroup) ||
							!!user?.StaffSettings?.ShowInAdviserList
								? []
								: [user.StaffID?.toString()],
						ContactStatuses: GetActiveContactStatuses(),
					});
				}
			});

		const hasMOAT = this.businessServices.includes(
			staticConf.mortgageOnlineAdviceToolCode
		);
		const hasCRT = this.businessServices.includes(
			staticConf.clientReviewTemplateCode
		);
		const hasLR = this.businessServices.includes(ServicesCodes.LR);
		const hasM = this.businessServices.includes(ServicesCodes.Mortgage);

		this.hasOnline = hasMOAT || hasCRT;
		this.hasOffline = hasLR || hasM;

		const onlineOptions: ViewDisplayValue[] = [];

		if (this.hasOnline) {
			const online: ViewDisplayValue = {
				display: 'Online',
				value: 'Online',
			};
			onlineOptions.push(online);
		}

		if (this.hasOffline) {
			const offline: ViewDisplayValue = {
				display: 'Offline',
				value: 'Offline',
			};
			onlineOptions.push(offline);
		}

		this.OfflineOnlineChoice = onlineOptions;
	}

	setAdviceStagesDropdown() {
		combineLatest([
			this.FormAdviceProcesses.valueChanges.pipe(startWith(null)),
			this.FormAdviceOnlineOffline.valueChanges.pipe(startWith(null)),
		])
			.pipe(
				withLatestFrom(
					this.uiQuery.adviceStagesDropdown$,
					this.businessConfigQuery.conversionFeature$
				),
				tap(
					([[processCodes, onlineOffline], stages, conversionFeature]) => {
						const updatedStages = stages.map((s) => {
							if (!s?.stages.find((x) => x?.stage === this.notStarted)) {
								if(!this.claimsFeature &&
										(s.processCode===AdviceProcessCode.LRClaim
										||s.processCode===AdviceProcessCode.FGClaim)){// Filter Claim Stages
									s.stages = [];
								}
								if (!this.offlineAP?.includes(s?.processCode)) {
									s.stages?.push({ ...NotStartedStage, isOnline: true });
								}
								s.stages?.push(NotStartedStage);
							}
							return s;
						});
						const filteredAP = this.filteredUserTypes?.map((x) =>
							x?.value?.toString()
						);
						const isOnline = onlineOffline?.includes('Online');
						const isOffline = onlineOffline?.includes('Offline');
						const choices = R.uniq(
							updatedStages?.reduce((a, c) => {
								if (
									(processCodes?.includes(c?.processCode) ||
										processCodes?.length === 0) &&
									filteredAP?.includes(c?.processCode)
								) {
									const noOnlineOfflineOption = this.offlineAP?.includes(
										c?.processCode
									);
									const filteredStages = c?.stages
										?.reduce((as, cs) => {
											if (
												cs.status === 1 &&
												(onlineOffline?.length === 0 ||
													!!noOnlineOfflineOption ||
													(cs?.isOnline && isOnline) ||
													(!cs?.isOnline && isOffline))
											) {
												return [...as, cs?.stage];
											}
											return as;
										}, [])
										?.filter((s) => {
											if (
												[
													AdviceProcessCode.LRAdviceNew.toString(),
													AdviceProcessCode.LRAdviceReview.toString(),
												]?.includes(c?.processCode) &&
												!conversionFeature
											) {
												return s !== AdviceProcessStages.MeetingBooked;
											}
											return true;
										});
									return [...a, ...filteredStages];
								}
								return a;
							}, []) || []
						)
							?.filter(Boolean)
							?.map((value) => {
								let display = value;
								if (display === 'Final Structure Stage') {
									display = display?.replace(' Stage', '').trim();
								}
								return { display, value };
							})
							?.sort((a, b) => a.display?.localeCompare(b.display));
						this.AdviceStagesChoices = choices;

						if (this.FormAdviceStages?.touched) {
							let stageValues = this.FormAdviceStages?.value || [];
							stageValues = stageValues?.filter((x) => {
								return choices?.find((c) => c?.value === x);
							});
							this.FormAdviceStages.setValue(stageValues);
						}
					}
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngAfterViewInit(): void {}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	public prepareFormValue(): AdviceProcessRequest {
		const formValue: formType = this.form.value;

		const req = {
			...formValue,
			AdviceProcesses: formValue.AdviceProcesses.length
				? formValue.AdviceProcesses
				: this.filteredUserTypes.map((type) => type.value),
			AdviceOnlineOffline: formValue.AdviceOnlineOffline.length
				? formValue.AdviceOnlineOffline
				: this.OfflineOnlineChoice.map((choice) => choice.value),
			AdviceStages: formValue.AdviceStages.length
				? formValue.AdviceStages
				: this.AdviceStagesChoices.map((choice) => choice.value),
			Advisers: formValue.Advisers?.map((x) => x),
			AltAdvisers: formValue.AltAdvisers?.map((x) => x),
			ReviewedBy: formValue.ReviewedBy?.map((x) => x),
			AdviceStatuses: (formValue.AdviceStatuses as any[])?.some(
				(x) => x === '1'
			)
				? [...formValue.AdviceStatuses, '5']
				: formValue.AdviceStatuses,
			AdviceStartDateMin: MomentUtil.formatToServerDate(
				formValue.AdviceStartDateMin
			),
			AdviceStartDateMax: MomentUtil.formatToServerDate(
				formValue.AdviceStartDateMax
			),
			AdviceEndDateMin: MomentUtil.formatToServerDate(
				formValue.AdviceEndDateMin
			),
			AdviceEndDateMax: MomentUtil.formatToServerDate(
				formValue.AdviceEndDateMax
			),
			NextActivityDateMin: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMin
			),
			NextActivityDateMax: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMax
			),
		} as AdviceProcessRequest;
		return req;
	}

	search() {
		const req = JSON.parse(JSON.stringify(this.prepareFormValue()));
		this.uiQuery.currentSort$
			.pipe(
				first(),
				map((x) => {
					req.Paging = {
						Index: 1,
						Column: x.propSort,
						Direction: x.sort,
					};
				}),
				finalize(() => {
					this.service
						.search(req)
						.pipe(takeUntil(this.onDestroy$))
						.subscribe(() => {
							setTimeout(
								() => $('datatable-body').scrollTop(1).scrollLeft(1),
								1
							);
							setTimeout(
								() => $('datatable-body').scrollTop(0).scrollLeft(0),
								1
							);
						});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	/**
	 * Api calls (Endpoint)
	 * @returns void
	 * Downloads file in .csv format
	 */
	//////////////////////////////////// EXPORT /////////////////////////////////
	export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		this.index++;
		const req = request(
			this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
			this.prepareFormValue(),
			this.index
		);
		const newReq = omit(['Paging'], req);

		this.exportsService
			.queueExport(newReq, ExportsType.APE)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(
						this.exportsService.getExportStatus(id)
					)
				)
			)
			.subscribe({
				next: (data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport(data.DocumentLink);
						this.hidePopover();
					}
				},
				error: () => {
					this.hidePopover();
				},
			});

		setTimeout(() => {
			this.popover.hide();
		}, 2500);
	}

	downloadExport(url: string) {
		const name =
			this.route.snapshot.paramMap.get('companyCode') + '-AdviceProcess.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	clearExportProgress() {
		this.index = 0;
		this.percent = 0;
		this.progress = {
			width: '0%',
		};
		this.totalCount = 0;
	}
	//////////////////////////////////// END EXPORT /////////////////////////////////

	toggle() {
		this.showSearch = !this.showSearch;
		document.body.style.overflowY = 'hidden';
		let x = 0;
		let data;
		const setIntervalHeight = setInterval(() => {
			if (x < 1 && !data) {
				x += 1;
				data = {
					showSearch: this.showSearch,
					height: $('form').height(),
					width: $('form').width(),
				};
			} else if (x > 0) {
				x += 1;
				if (data.height !== $('form').height()) {
					data.height = $('form').height();
				} else {
					this.toggleSearch.emit(data);
					clearInterval(setIntervalHeight);
				}
			}
		}, 100);
	}

	/** Reset form fields value */
	reset() {
		this.form.get('Advisers').reset([]);
		this.form.get('AltAdvisers')?.reset([]);
		this.form.get('ReviewedBy').reset([]);

		this.form.get('AdviceProcesses').reset([]);
		this.form.get('AdviceStatuses').reset([]);

		this.form.get('AdviceDocuments').reset([]);
		this.form.get('LeadTypes').reset([]);

		this.form.get('LeadOrigins').reset([]);
		this.form.get('ContactStatuses').reset([]);

		this.form.get('AdviceStartDateMin').reset(null);
		this.form.get('AdviceStartDateMax').reset(null);
		this.form.get('AdviceEndDateMin').reset(null);
		this.form.get('AdviceEndDateMax').reset(null);

		this.form.get('NextActivityDateMin').reset(null);
		this.form.get('NextActivityDateMax').reset(null);

		this.form.get('AdviceOnlineOffline').reset([]);
		this.form.get('AdviceStages').reset([]);
	}

	showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}
}

type formType = { [key in keyof any]: any };
