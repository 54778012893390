import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
	MatDatepicker,
	MatDatepickerInput,
	MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { Moment } from 'moment';
import { Observable, Subscription, catchError, of, take, tap } from 'rxjs';
import { staticConf } from 'src/app/core/config/static-config.service';
import MomentUtil from 'src/app/util/moment.util';
import { DateInputService } from './date-input.service';
import { CUSTOM_MAT_MOMENT_DATE_FORMATS } from './moment-date-adapter2';

@Component({
	selector: 'app-date-input',
	templateUrl: './date-input.component.html',
	styleUrls: ['./date-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateInputComponent,
			multi: true,
		},
		DateInputService,
		MomentDateAdapter,
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_MOMENT_DATE_FORMATS },
	],
})
export class DateInputComponent
	implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit
{
	subs: Subscription[];

	@Input() noYear: boolean;
	@Input() disabled = false;
	@Input() min: Moment;
	@Input() max: Moment;
	@Input() textboxClass = '';
	@Input() dateboxClass = '';
	@Input() tabindex: number;
	@Input() placeholder = 'dd/mm/yyyy';
	@Input() textboxId = '';
	@Input() showButton = true;
	@Input() useActionButtons = false;
	@Input() isSavingDate = false;
	@Input() updateDateFn$: (data) => Observable<any> = (data) => of(data);
	@Output() onChangeEvent = new EventEmitter<any>();
	@Output() onSaveDateEvent = new EventEmitter<any>();
	date$: Observable<Moment>;
	datafacade: string;
	isSaving = false;

	@ViewChild('date') dateInput: ElementRef;
	@ViewChild('date') matInput: MatDatepickerInput<any>;
	@ViewChild('picker') picker: MatDatepicker<any>;

	/**
	 * remove the tabindex attribute from host element
	 * to remove duplicate tab index that is attached to input element
	 **/
	@HostBinding('attr.tabindex') get tabIndex() {
		return null;
	}

	constructor(
		private service: DateInputService,
		@Inject(MAT_DATE_FORMATS) private format
	) {
		this.subs = [];
		this.date$ = this.service.date$;

		const sub1 = this.service.date$.subscribe((x) => this.onChange(x));
		this.subs.push(sub1);
	}

	ngAfterViewInit() {
		if (this.noYear) {
			this.format.parse.dateInput = 'DD/MM';
			this.format.display.dateInput = 'DD/MM';
			this.format.display.monthYearLabel = 'MMM';
			this.format.display.monthYearA11yLabel = 'MMM';
		}
	}

	get dateBoxClass() {
		return !!this.dateboxClass ? this.dateboxClass : '';
	}

	writeValue(obj: any): void {
		this.change(obj);
		if (obj) {
			this.datafacade = (obj as Moment).format();
		} else {
			this.datafacade = '';
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnInit() {
		if (this.picker) {
			this.picker.panelClass = this.dateBoxClass;
		}
	}

	ngOnDestroy(): void {
		this.subs?.forEach((x) => {
			x.unsubscribe();
		});
		this.subs = [];
	}

	changeDate(event: MatDatepickerInputEvent<Moment>) {
		this.emitChanges(event);
	}

	emitChanges(event: MatDatepickerInputEvent<Moment>) {
		this.change(event.value);
		this.onChangeEvent.emit(event);
	}

	saveDate() {
		this.onSaveDateEvent.emit('event');
	}

	// saveDate(event: MatDatepickerInputEvent<Moment>) {
	// 	if (!this.updateDateFn$) {
	// 		this.emitChanges(event);
	// 		return;
	// 	}
	// 	if (!!this.isSaving) {
	// 		return;
	// 	}
	// 	this.isSaving = true;
	// 	this.setDisabledState(true);
	// 	this.updateDateFn$(event)
	// 		.pipe(
	// 			catchError((error) => {
	// 				if (error) {
	// 					this.isSaving = false;
	// 					this.setDisabledState(false);
	// 				}
	// 				throw error;
	// 			}),
	// 			tap(() => {
	// 				this.isSaving = false;
	// 				this.setDisabledState(false);
	// 				this.emitChanges(event);
	// 			}),
	// 			take(1)
	// 		)
	// 		.subscribe();
	// }

	change(mom?: Moment) {
		this.service.set(mom);
	}
	onChange = (value: Moment) => {};
	onTouched = () => {};

	isInvalid() {
		return (
			!!this.dateInput?.nativeElement?.value &&
			this.dateInput?.nativeElement?.value.trim() !== '' &&
			!MomentUtil.IsValidDateFormat(
				this.dateInput.nativeElement.value,
				staticConf.displayDateFormat
			)
		);
	}

	reset() {
		this.dateInput.nativeElement.value = '';
	}
}
