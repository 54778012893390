import {
	AfterContentInit,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { objectUtil, strUtil, util } from '@util/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SortableComponent } from 'ngx-bootstrap/sortable';
import * as R from 'ramda';
import {
	BehaviorSubject,
	Observable,
	ReplaySubject,
	Subject,
	Subscription,
	of,
} from 'rxjs';
import { map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ControlLoaderService } from 'src/app/core/services/control-loader.service';
import { DropdownValueService } from 'src/app/domain/dropdown-value/dropdown-value.service';
import { ConfigService } from '../../../../core/config/config.service';
import { DropdownService } from '../../../../core/dropdown/dropdown.service';
import { ActionLoaderService } from '../../../../core/loader/loader.service';
import { BusinessConfig } from '../../../../domain/business-config/business-config.model';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { CustomDropdowns, Dropdown } from '../../model/dropdown';
import { ViewDropdown } from '../viewModel/viewDropdown';
import { ViewDropdownValue } from '../viewModel/viewDropdownValue';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	providers: [ControlLoaderService, ActionLoaderService],
})
export class DropdownComponent implements OnInit, AfterContentInit, OnDestroy {
	isSavingDropdown = false;
	customDropdowns = CustomDropdowns;

	horizontalHeaderWidth: number = 0;
	@ViewChild('dropdownHeader') dropdownHeader: ElementRef;
	@ViewChild('colName') colName: ElementRef;
	@ViewChild('colCustomFields') colCustomFields: ElementRef;
	@ViewChild('colDefault') colDefault: ElementRef;
	@ViewChild('colButtons') colButtons: ElementRef;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		// event.target.innerWidth;
		this.setHorizontalWith();
	}

	emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	constructor(
		private route: ActivatedRoute,
		private dService: DropdownService,
		private configService: ConfigService,
		private actionLoaderService: ActionLoaderService,
		private modalService: BsModalService,
		private businessConfigQuery: BusinessConfigQuery,
		private dropdownValueService: DropdownValueService,
		private loggerService: LoggerService
	) {}
	isDeleting = false;
	isEditing = false;
	model: ComponentModel = new ComponentModel();

	dropdownList: ViewDropdownValue[];
	dropdownListSwitchTo: ViewDropdownValue[];
	isAddMode = false;
	isSaving = false;
	subs: Subscription[] = [];
	modalInstance: any;
	@ViewChild('deleteModal') deleteModal: TemplateRef<any>;
	@ViewChild(SortableComponent) sortableComponent: SortableComponent;
	ngOnInit() {
		this.model.businessConfig = this.businessConfigQuery.businessConfig$;
		this.model.orderedDropdownValues
			.pipe(
				tap((x) =>
					x.map((y) => {
						const dropdownValue = y.dropdownValue;
						const ddValue =
							typeof dropdownValue === 'string' &&
							dropdownValue.startsWith('{') &&
							dropdownValue.endsWith('}')
								? util.tryParseJson(dropdownValue)
								: y.dropdownCode;
						const isJsonDdValue = typeof ddValue === 'object';
						const value = isJsonDdValue
							? objectUtil.mapPascalCaseToCamelCase(ddValue)
							: null;
						if (!!value && isJsonDdValue) {
							const otherDdValue = R.omit(['value'], value);
							const customFields = R.keys(otherDdValue) as string[];
							this.model.customFieldsHeaders =
								CustomDropdowns[this.model.currentDropdown];
							customFields.forEach((cf) => {
								this.model.customFields?.push({
									...CustomDropdowns[this.model.currentDropdown].find(
										(cd) => cf === cd.key?.toLowerCase()
									),
									dropdownValueId: y.dropdownValueId,
									value: value[cf],
								});
							});
						}
					})
				),
				map((x) =>
					x.map((y) => {
						const dropdownValue = y.dropdownValue;
						const ddValue =
							typeof dropdownValue === 'string' &&
							dropdownValue.startsWith('{') &&
							dropdownValue.endsWith('}')
								? util.tryParseJson(dropdownValue)
								: dropdownValue;
						const isJsonDdValue = typeof ddValue === 'object';
						const value = isJsonDdValue
							? objectUtil.mapPascalCaseToCamelCase(ddValue)?.value
							: ddValue;
						y.dropdownValue = value;
						y.parsedValue = isJsonDdValue
							? R.omit(['value'], objectUtil.mapPascalCaseToCamelCase(ddValue))
							: null;
						y.parsedDefaultValue = isJsonDdValue
							? R.omit(['value'], objectUtil.mapPascalCaseToCamelCase(ddValue))
							: null;
						return y;
					})
				),
				tap((x) => {
					this.dropdownList = x;
				})
			)
			.subscribe();

		const sub = this.route.data.subscribe(
			(data: { dropdownList: Dropdown[] }) => {
				this.configService.CompanyCode.subscribe(
					(x) => (this.model.businessCompanyCode = x)
				);
				this.model.dropdownList = data.dropdownList?.map((x) =>
					ViewDropdown.mapFromModel(x)
				);
			}
		);
		const sub1 = this.model.onIsDefault
			.pipe(
				withLatestFrom(this.model.dropdownValues, (dv, dvl) => ({
					dv: Object.assign(new ViewDropdownValue(), dv),
					dvl: dvl?.map((d) => Object.assign(new ViewDropdownValue(), d)),
				}))
			)
			.subscribe(({ dv, dvl }) => {
				// receive dv to set as default, get with latest from dvl
				// set all in dvl to default = false
				// set isdefault of dropdown equivalent in list to true
				// push as next dvl
				dvl?.forEach((d) => {
					d.isDefault = false;
				});
				dvl.find((d) => d.dropdownValueId === dv.dropdownValueId).isDefault =
					true;
				this.model.dropdownValues.next(dvl);
			});

		const sub2 = this.model.onDropdownChange.subscribe((dropdownCode) => {
			this.model.currentDropdown = dropdownCode;

			const customDropdowns = R.keys(CustomDropdowns) as string[];
			this.model.hasCustomFields = customDropdowns?.includes(dropdownCode);
			this.model.customFields = [];
			this.model.customFieldsHeaders = [];

			this.model.viewShowSettings.next(false);
			this.model.dropdownValues.next([]);
			this.model.newDropdownValues.next([]);
			this.model.dropdownValuesBackup.next([]);
			this.dService.dropdownValuesService
				.getDropdownValuesJson(dropdownCode)
				.pipe(map((dv) => dv?.map(ViewDropdownValue.mapFromModel)))
				.subscribe((x) => {
					this.model.dropdownValues.next(clone(x));
					this.model.dropdownValuesBackup.next(clone(x));
					this.model.viewShowSettings.next(true);
					setTimeout(() => this.setHorizontalWith(), 300);
				}, undefined);
		});

		this.subs.push(sub, sub1, sub2);
	}
	ngAfterContentInit(): void {
		setTimeout(() => this.setHorizontalWith(), 300);
	}
	ngOnDestroy(): void {
		this.subs?.forEach((x) => x.unsubscribe());
		this.subs = [];
	}

	setHorizontalWith() {
		const dropdownHeader =
			(this.dropdownHeader?.nativeElement as HTMLElement)?.offsetWidth || 0;
		const colName =
			(this.colName?.nativeElement as HTMLElement)?.offsetWidth || 0;
		const colCustomFields =
			(this.colCustomFields?.nativeElement as HTMLElement)?.offsetWidth || 0;
		const colDefault =
			(this.colDefault?.nativeElement as HTMLElement)?.offsetWidth || 0;
		const colButtons =
			(this.colButtons?.nativeElement as HTMLElement)?.offsetWidth || 0;

		const totalWith = R.sum([colName, colCustomFields, colDefault, colButtons]);

		if (totalWith > 0 && !!this.model.hasCustomFields) {
			this.horizontalHeaderWidth = totalWith;
		} else {
			this.horizontalHeaderWidth = 0.75 * dropdownHeader;
		}
	}

	addDropdown() {
		this.isAddMode = true;
		const currentDDFields = this.customDropdowns[this.model.currentDropdown];
		const parsedValue = !!currentDDFields
			? R.mergeAll(currentDDFields?.map((x) => ({ [x.key]: null })))
			: null;
		this.dropdownList.push(
			new ViewDropdownValue(
				undefined,
				this.model.currentDropdown,
				'',
				+(this.dropdownList.length + 1 + '0'),
				false,
				true,
				null,
				true,
				parsedValue,
				parsedValue
			)
		);
		// Reloads sortable component
		this.sortableComponent.writeValue(this.dropdownList);
	}

	changeDefault(item, isChecked) {
		this.dropdownList?.filter((x) => {
			x.isDefault = false;
		});

		if(isChecked) {
			return;
		}

		return (item.isDefault = true);
	}

	save() {
		this.actionLoaderService.addIsWorking();
		this.isSaving = true;
		this.isSavingDropdown = true;
		for (let i = 0; i < this.dropdownList.length; i++) {
			// Just resetting dropdownOrder
			const index = +(i + 1 + '0');
			this.dropdownList[i].dropdownOrder = index;
		}
		const ddList = this.dropdownList;
		const model = ViewDropdownValue.mapToAllUpdateModel(
			this.model.businessCompanyCode,
			ddList.map((x) => {
				const ddValue = (v: string) =>
					typeof v === 'string' && (v.startsWith('{') || v.endsWith('}'))
						? v
						: !!x.parsedValue
						? JSON.stringify({ value: x.dropdownValue, ...x.parsedValue })
						: x.dropdownValue;
				x.dropdownValue = ddValue(x.dropdownValue);
				return x;
			})
		);
		this.dService.dropdownValuesService
			.UpdateDropdownValues(model, this.model.currentDropdown)
			.subscribe(
				() => {
					const dropdownList = this.dropdownList;
					this.dropdownList = dropdownList.map((x) => {
						const ddlist = (v: string) =>
							typeof v === 'string' && (v.startsWith('{') || v.endsWith('}'))
								? util.tryParseJson(v)?.value
								: v;
						x.dropdownValue = ddlist(x.dropdownValue);
						x.parsedValue = x.parsedDefaultValue
							? { ...x.parsedDefaultValue }
							: null;
						return x;
					});

					this.actionLoaderService.removeIsWorking();
					this.isSaving = false;
					this.isSavingDropdown = false;
					this.isAddMode = false;
				},
				() => {
					this.isSaving = false;
					this.isSavingDropdown = false;
				}
			);

		this.dropdownValueService.setDropdownUpdateCodes(
			this.model.currentDropdown
		);
	}

	update(dd: ViewDropdownValue, value: string) {
		if (dd?.parsedValue?.email && !strUtil.isEmail(dd?.parsedValue?.email)) {
			return;
		}
		const existing = this.dropdownList.find(
			(item) => item.dropdownValue === value
		);
		if (existing && dd.isNew) {
			this.loggerService.Log({}, 'Value already exists.');
			return;
		}

		dd.isSaving = true;
		if (!dd.parsedValue) {
			dd.dropdownValue = value;
		} else {
			dd.dropdownValue = JSON.stringify({
				value: value,
				...dd.parsedValue,
			});
		}
		if (dd.dropdownValueId) {
			this.dService.dropdownValuesService
				.saveDropdownValueName(
					ViewDropdownValue.mapToDropdownValueUpdate(
						dd.dropdownValueId,
						dd,
						this.model.businessCompanyCode
					),
					this.model.currentDropdown
				)
				.subscribe(
					() => {
						const ddList = this.dropdownList;
						this.dropdownList = ddList.map((x) => {
							if (+x.dropdownValueId === +dd.dropdownValueId) {
								const ddlist = (v: string) =>
									typeof v === 'string' &&
									(v.startsWith('{') || v.endsWith('}'))
										? util.tryParseJson(v)?.value
										: v;
								x.dropdownValue = ddlist(x.dropdownValue);
								x.parsedDefaultValue = !!x.parsedValue
									? { ...x.parsedValue }
									: null;
							}
							return x;
						});
						dd.isSaving = false;
						dd.isEdit = false;
						this.isAddMode = false;
					},
					() => {
						dd.isSaving = false;
					}
				);
		} else {
			this.dService.dropdownValuesService
				.addDropdownValueName(
					ViewDropdownValue.mapToDropdownValueAdd(
						dd,
						this.model.businessCompanyCode
					),
					this.model.currentDropdown
				)
				.subscribe(
					(id) => {
						const dropdownList = this.dropdownList;
						this.dropdownList = dropdownList.map((x) => {
							const ddlist = (v: string) =>
								typeof v === 'string' && (v.startsWith('{') || v.endsWith('}'))
									? util.tryParseJson(v)?.value
									: v;
							x.dropdownValue = ddlist(x.dropdownValue);
							x.parsedValue = x.parsedDefaultValue
								? { ...x.parsedDefaultValue }
								: null;
							return x;
						});
						dd.dropdownValueId = +id;
						dd.isSaving = false;
						dd.isEdit = false;
						dd.isNew = false;
						this.isAddMode = false;
					},
					() => {
						dd.isSaving = false;
					}
				);
		}
	}

	delete(index: number, dd: ViewDropdownValue) {
		if (dd.dropdownValueId) {
			this.dropdownListSwitchTo = [
				...this.dropdownList?.filter(
					(listOption) => +listOption.dropdownValueId !== +dd.dropdownValueId
				),
			];
			this.modalInstance = this.modalService.show(this.deleteModal, {
				class: 'modal-dialog-centered',
				ignoreBackdropClick: true,
			});
			this.model.onDeleteConfirm
				.pipe(
					take(1),
					tap(() => {
						this.isDeleting = true;
					}),
					mergeMap((newId) =>
						this.dService.dropdownValuesService.DeleteDropdownValue(
							dd.dropdownValueId,
							newId,
							this.model.currentDropdown
						)
					)
				)
				.subscribe(() => {
					this.isDeleting = false;
					this.modalInstance.hide();
					this.dropdownList?.splice(index, 1);
					// Reloads sortable component
					this.sortableComponent.writeValue(this.dropdownList);
				});
		} else {
			this.dropdownList.splice(index, 1);
			// Reloads sortable component
			this.sortableComponent.writeValue(this.dropdownList);
		}
	}

	cancel(index: number, dd: ViewDropdownValue) {
		this.isAddMode = false;
		if (!dd.dropdownValueId) {
			this.dropdownList?.splice(index, 1);
		}

		const dropdownList = this.dropdownList;
		this.dropdownList = dropdownList.map((x) => {
			const ddlist = (v: string) =>
				typeof v === 'string' && (v.startsWith('{') || v.endsWith('}'))
					? util.tryParseJson(v)?.value
					: v;
			x.dropdownValue = ddlist(x.dropdownValue);
			x.parsedValue = x.parsedDefaultValue ? { ...x.parsedDefaultValue } : null;
			return x;
		});
		// Reloads sortable component
		// this.sortableComponent.writeValue(this.dropdownList);
	}

	cancelEdit() {
		this.isAddMode = false;
		this.model.currentDropdown = '';
		this.model.viewShowSettings.next(false);
		window.scrollTo(0, 0);
	}

	confirmCancel() {
		this.modalInstance.hide();
	}

}

class ComponentModel {
	// values
	businessCompanyCode: string;
	currentDropdown: string;
	hasCustomFields: boolean;
	customFields: any[];
	customFieldsHeaders: string[];
	businessConfig: Observable<BusinessConfig> = of(null);
	dropdownList: ViewDropdown[];
	newDropdownValues: BehaviorSubject<ViewDropdownValue[]> = new BehaviorSubject<
		ViewDropdownValue[]
	>([]);
	dropdownValues: BehaviorSubject<ViewDropdownValue[]> = new BehaviorSubject<
		ViewDropdownValue[]
	>([]);
	orderedDropdownValues = this.dropdownValues.pipe(
		map((x) => x?.sort((a, b) => a.dropdownOrder - b.dropdownOrder))
	);
	dropdownValuesBackup: BehaviorSubject<ViewDropdownValue[]> =
		new BehaviorSubject<ViewDropdownValue[]>([]);
	deleteDropdownValues: ReplaySubject<ViewDropdownValue[]> = new ReplaySubject(
		1
	);
	// events
	onIsDefault: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onDropdownChange: Subject<string> = new Subject<string>();
	onUp: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onDown: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onEdit: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onEditCancel: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onEditSave: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onDelete: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onDeleteConfirm: Subject<number> = new Subject<number>();
	onDeleteCancel: Subject<any> = new Subject<any>();

	onNewSave: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	onNewCancel: Subject<ViewDropdownValue> = new Subject<ViewDropdownValue>();
	// view properties
	viewShowSettings: Subject<boolean> = new Subject<boolean>();
}
function clone<T>(obj: Array<T> | T): any {
	if (Array.isArray(obj)) {
		return obj?.map((x) => Object.assign({}, x));
	} else if (typeof obj === 'object' && obj !== null) {
		return Object.assign({}, obj);
	} else {
		throw new Error('Cloning Error!');
	}
}
