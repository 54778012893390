import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { forkJoin, from, Observable, Subject, zip, of, iif } from 'rxjs';
import { concatMap, map, mergeMap, retry, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ApiService } from '../../../../../../../core/base/api.service';
import {
	AdviceProcessSectionCodes,
	AdviceProcessSectionCodes as SectionCodes,
} from '../../../../../../../shared/models/advice-process/advice-process.model';
import { objectUtil, convertUtil } from '../../../../../../../util/util';

import { MergeTagState } from '../../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { CrtMortgageQuery } from '../../../../crt-mortgage/state/crt-mortgage.query';
import { CrtInviteCpModel } from './crt-invite-cp.model';
import { Attachment } from '@shared/models/_general/attachment.model';
import { DocumentModel } from '@shared/models/documents/document.model';
import { MergeTagsService } from '@modules/crm/crt-page/crt-mortgage/state/merge-tags/merge-tags.service';
import { DisclosureService } from '@modules/crm/crt-page/crt-mortgage/disclosure/state/disclosure.service';
import * as R from 'ramda';
import { either, isEmpty, isNil } from 'ramda';
import { getContentWithMergeTags } from '@shared/converter/content-merge-tags';
import { HtmlToPdfService } from '../../../service/html-pdf/html-to-pdf.service';

export interface InvitationEmailSettings {
	settingsId?: number;
	referenceId?: number;
	type?: string;
	template?: string;
	isEnableEmailOption?: boolean;
	sender?: string;
	carbonCopy?: string;
	blindCarbonCopy?: string;
	subject?: string;
	templateID?: string;
	unsubscribeGroupID?: number;
	mergeTag?: MergeTagState[];
	body?: string | number;
	crtId?: number;
	bodyContent?: string;
}

@Injectable({ providedIn: 'root' })
export class CrtInviteCpService {
	private percent = new Subject<number | void>();

	sectionCodes = [
		SectionCodes.People,
		SectionCodes.Dependants,
		SectionCodes.Trust,
		SectionCodes.Company,
		SectionCodes.Property,
		SectionCodes.KiwiSaver,
		SectionCodes.AssetsInvestments,
		SectionCodes.Mortgages,
		SectionCodes.IncomeSource,
		SectionCodes.Liabilities,
	];

	constructor(
		private api: ApiService,
		private http: HttpClient,
		private authenticationService: AuthenticationService,
		private router: Router,
		protected query: CrtMortgageQuery,
		private mtService: MergeTagsService,
		private dService: DisclosureService,
		private htmlToPdfService: HtmlToPdfService
	) {}

	private createApiUrl(url: string): string {
		return `https://${environment.cpUrl}/api/v${environment.apiVersion}/${url}`;
	}

	private companyCode() {
		let route = this.router.routerState.root;
		while (route.firstChild) {
			route = route.firstChild;
		}
		return route.snapshot.params && route.snapshot.params.companyCode
			? route.snapshot.params.companyCode
			: '';
	}

	private createHeader(): { [key: string]: string } {
		return {
			Authorization: `${this.authenticationService.user.token_type} ${this.authenticationService.user.access_token}`,
			'Content-Type': 'application/json',
			CompanyCode: this.companyCode(),
		};
	}

	sendInvitation(invite: Partial<CrtInviteCpModel>): Observable<any> {
		invite.emailDestination = [
			objectUtil.mapCamelCaseToPascalCase(invite.emailDestination[0]),
		];
		const body = objectUtil.mapCamelCaseToPascalCase(invite);
		const url = this.createApiUrl(`emails/customer-portal/invite`);
		return this.http.post(url, body, {
			headers: this.createHeader(),
		});
	}

	sendAdditionalInformation(data: any): Observable<boolean> {
		const url = this.createApiUrl(`emails/customer-portal/single`);
		return this.http.post(url, data, {
			headers: this.createHeader(),
		}) as Observable<boolean>;
	}

	sync(adviceProcessId: number) {
		const endpont = `adviceprocesses/${adviceProcessId}/sync`;
		const fetchData = (sc: AdviceProcessSectionCodes[]) =>
			from(
				sc?.map((s) =>
					this.api.put(`${endpont}/${s}`, null).pipe(
						tap(() => this.setPercent(this.computePercent(s))),
						retry(1)
					)
				)
			).pipe(concatMap((x) => x));
		return forkJoin([fetchData(this.sectionCodes)]);
	}

	updateCPStatus(adviceProcessId: number, status: number): Observable<boolean> {
		return this.api
			.put(`adviceprocesses/customer-portal/${adviceProcessId}/${status}`, {
				AdviceProcessId: adviceProcessId,
				AdviceProcessCode: 'APM',
				CPStatus: status,
			})
			.pipe(map((result) => Boolean(result)));
	}

	setPercent(progress: number) {
		this.percent.next(progress);
	}

	clearPercent() {
		this.percent.next();
	}

	getPercent() {
		return this.percent.asObservable();
	}

	computePercent(section): number {
		return +(
			((this.sectionCodes.indexOf(section) + 1) * 100) /
			this.sectionCodes.length
		).toFixed(2);
	}

	private getIntroduction(): Observable<Attachment> {
		return this.api.get(`crt/settings/0/MOATIP`).pipe(
			mergeMap((x: any) => {
				if (either(isNil, isEmpty)(x?.DocumentID)) {
					return of(null);
				}
				return of(x).pipe(
					mergeMap((settings) =>
						this.api
							.get<DocumentModel>(`documents/${settings.DocumentID}`)
							.pipe(map((doc) => ({ settings, doc })))
					),
					mergeMap((result: any) =>
						this.http
							.get(result.doc.DocumentLink, {
								responseType: 'blob',
							})
							.pipe(map((file) => ({ ...result, file })))
					),
					mergeMap((result) =>
						convertUtil
							.simpleConvertToBase64(
								new File([result.file], result.doc.DocumentName)
							)
							.pipe(
								map(
									(base64: string) =>
										({
											fileName: 'Introduction.pdf',
											content: base64.replace('data:', '').replace(/^.+,/, ''),
											type: 'pdf',
										} as Attachment)
								)
							)
					)
				);
			})
		);
	}

	private getDisclosureAttachment(adviceProcessId: number, adviserId: number): Observable<any> {
		return zip(
			this.dService.getDisclosureDocument(adviceProcessId).pipe(
				map((result) => result?.[0]),
				map((doc) => {
					if (!doc) {
						return null;
					}
					return objectUtil.mapPascalCaseToCamelCase(doc);
				})
			),
			this.dService.getDisclosureSettings()
		).pipe(
			map(([doc, settings]) => {
				return doc?.document?.referenceId
					? doc?.document?.referenceId
					: settings.templateType === 'UD'
					? settings?.uploadTemplate
					: settings?.template;
			}),
			mergeMap((refId) =>
				iif(
					() => isNil(refId),
					of(null),
					this.dService.getDisclosureDocumentFile(refId)
				)
			),
			mergeMap((data: any) => {
				if (data?.FileExtension === '.txt') {
					return this.getTemplateDoc(data, adviserId);
				}
				return this.getUploadDoc(data);
			}),
			take(1)
		);
	}

	getTemplateDoc(document, adviserId: number) {
		if (!document) {
			return of(null);
		}
		return this.dService.getDocumentFromURL(document?.DocumentLink).pipe(
			map((data) => ({
				content: data,
				documentName: data?.DocumentName,
			})),
			mergeMap((result: any) => {
				return this.mtService.getAdviserProviderMt(adviserId).pipe(
					map((result) => {
						return [...(result?.[0] ?? []), ...(result?.[1] ?? [])];
					}),
					map(
						(mt) =>
							({
								fileName: 'DisclosureDocument.pdf',
								content: getContentWithMergeTags(
									`<div class="disclosure-pdf-file">${result.content || ''}</div>`,
									mt
								),
							} as Attachment)
					),
					mergeMap((content: any) => {
						return this.htmlToPdfService
							.downloadDocumentPDF(
								content.content,
								content.fileName,
								this.dService.getDdPdfOptions()
							)
							.pipe(
								mergeMap((file) => convertUtil.simpleConvertToBase64(file)),
								map(
									(fileBase64: string) =>
										({
											fileName: content.fileName,
											content: fileBase64
												.replace('data:', '')
												.replace(/^.+,/, ''),
										} as Attachment)
								)
							);
					})
				);
			}),
			take(1)
		);
	}

	getUploadDoc(document) {
		if (!document) {
			return of(null);
		}
		return this.api.getExternalResourceAsBlob(document?.DocumentLink).pipe(
			map((content) => ({
				content: content,
				documentName: document?.DocumentName,
			})),
			mergeMap((content: any) => {
				return convertUtil.simpleConvertToBase64(content.content).pipe(
					map((result) => {
						content.content = result;
						return content;
					}),
					map(
						(file) =>
							({
								fileName: document?.DocumentName,
								content: file?.content
									?.replace('data:', '')
									.replace(/^.+,/, ''),
							} as Attachment)
					)
				);
			}),
			take(1)
		);
	}

	getIntroductionAndDisclosureAttachments(
		adviceProcessId: number,
		adviserId: number
	): Observable<Attachment[]> {
		return zip(
			this.getIntroduction(),
			this.getDisclosureAttachment(adviceProcessId, adviserId)
		);
	}
}
