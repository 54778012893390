import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/base/api.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { CrtMortgageQuery } from '../../../../../state/crt-mortgage.query';
import { PropertyPurchaseStore } from './property-purchase.store';
import { PropertyPurchaseQuery } from './property-purchase.query';
import { objectUtil } from 'src/app/util/util';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mapTo,
	tap,
} from 'rxjs/operators';
import { PropertyPurchaseDetailsState } from './property-purchase.model';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { PropertyService } from '../../../../../client-sop/assets-and-liabilities/state/property/property.service';
import { computeUtil } from '../../../../../../../../../modules/crm/crt-page/_shared/calculations/funding-required';
import { PropertyAssetCustomerService } from 'src/app/shared/models/services/property-asset/property-asset';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class PropertyPurchaseService {
	SU$ = this.ddQuery.orderedChoices$('SU');
	SV1$ = this.ddQuery.orderedChoices$('SV1');
	ST$ = this.ddQuery.orderedChoices$('ST');
	STI$ = this.ddQuery.orderedChoices$('STI');
	APCRTF$ = this.ddQuery.orderedChoices$('APCRTF');
	isLoading$ = this.query.selectLoading();

	propertyPurchases$ = this.query.selectAll();

	constructor(
		private api: ApiService,
		protected store: PropertyPurchaseStore,
		protected query: PropertyPurchaseQuery,
		private ddQuery: DropdownValueQuery,
		private customerService: CustomerService,
		protected crtMortgageQuery: CrtMortgageQuery,
		private propertyService: PropertyService
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

  get(applicationId: number) {
		this.store.setLoading(true);
		return this.api.get<PropertyPurchaseDetailsState[]>(`crt/${AdviceProcessSectionCodes.Application}/${applicationId}/sub-section/${AdviceProcessSectionCodes.PropertyPurchase}`).pipe(
			map((x) => (x || [])?.map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => {
				this.store.set(x || []);
				this.calculateTotalPurchasePrice();
			}),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.PropertyPurchase,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => {
				this.store.add(x);
				this.calculateTotalPurchasePrice();
			}),
			concatMap((x) =>
				this.getPrimaryClientProperties(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			concatMap((x) =>
				this.propertyService.get(this.crtMortgageQuery.getValue().adviceProcessId).pipe(mapTo(x))
			),
			catchError(() => of(undefined))
		);
	}

	update(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.PropertyPurchase,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap((x) => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => {
				this.store.upsert(x?.cRTId, x);
				this.calculateTotalPurchasePrice();
			}),
			concatMap((x) =>
				this.getPrimaryClientProperties(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			concatMap((x) =>
				this.propertyService.get(this.crtMortgageQuery.getValue().adviceProcessId).pipe(mapTo(x))
			),
			catchError(() => of(undefined))
		);
	}

	delete(data: number) {
		return this.api.delete(`crt/${data.toString()}`).pipe(
			tap((x) => {
				this.store.remove(data);
				this.calculateTotalPurchasePrice();
			}),
			concatMap((x) =>
				this.getPrimaryClientProperties(
					this.crtMortgageQuery.getValue()?.primaryClient?.customerID
				).pipe(mapTo(x))
			),
			concatMap((x) =>
				this.propertyService.get(this.crtMortgageQuery.getValue().adviceProcessId).pipe(mapTo(x))
			),
			catchError(() => of(undefined))
		);
	}

	getPrimaryClientProperties(primaryClientId: number) {
		return this.customerService
			.GetSecuritiesByPrimaryClientId(primaryClientId)
			.pipe(
				map(objectUtil.mapPascalCaseToCamelCase),
				map((x) => x.customerServices?.filter((p) => p.status !== 'Sold')),
				tap((x) => this.store.update({ propertyAddresses: x })),
				catchError(() => of(undefined))
			);
	}

	calculateTotalPurchasePrice() {
		const total = computeUtil.totalPurchasePrice(this.query.getAll());
		this.store.update({ totalPurchasePrice: total });
	}

	getPotentialPurchaseList(customerId: number) {
		this.store.setLoading(true);
		return this.api.post<PropertyAssetCustomerService[]>(`/contacts/${customerId}/services-by-type`, 
		{
				CustomerId: customerId,
				ServiceCode: "S",
				TypeKey: "Status",
				TypeValue: ["Potential Purchase"]
		}).pipe(
			map((x) => (x || [])),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}
}
