import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SecurityState, SecurityStore } from './security.store';

@Injectable({ providedIn: 'root' })
export class SecurityQuery extends QueryEntity<SecurityState> {
	constructor(protected store: SecurityStore) {
		super(store);
	}
}
