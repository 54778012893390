<ng-container [formGroup]="clientForm">
  <div class="row tap-container">
    <div class="col pt-1">
      <h6>Contact Status</h6>
    </div>
  </div>
  <div class="row tap-container">
    <div class="col-md-6 pr-md-0">
      <div class="form-group form-inline row mb-1">
        <div class="col-4">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="crL" value="L" formControlName="contactStatus">
            <label class="custom-control-label pt-1" for="crL">Lead</label>
          </div>
        </div>
        <div class="col-4">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="crC" value="C" formControlName="contactStatus">
            <label class="custom-control-label pt-1" for="crC">Current Client</label>
          </div>
        </div>
        <div class="col-4">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="crX" value="X" formControlName="contactStatus">
            <label class="custom-control-label pt-1" for="crX">Ex-Client</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row tap-container">
    <div class="col-md-6 px-0">
      <div class="row tap-container">
        <div class="col pt-2">
          <h6>Primary Type</h6>
        </div>
      </div>
      <div class="row tap-container">
        <div class="col-md-12 pr-md-0">
          <div class="form-group form-inline row mb-1">
            <div class="col-4 ">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="client" value="PCIDI" formControlName="primaryType">
                <label class="custom-control-label pt-1" for="client">Client</label>
              </div>
            </div>
            <div class="col-4">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="business" value="PCCDI"
                  formControlName="primaryType">
                <label class="custom-control-label pt-1" for="business">Business</label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 px-0">
      <div class="row tap-container">
        <div class="col pt-2">
          <h6>Additional Contacts</h6>
        </div>
      </div>
      <div class="row tap-container">
        <div class="col-md-12 pr-md-0">
          <div class="form-group form-inline row mb-1">
            <div class="col-4 ">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="people" value="SCIDI" formControlName="primaryType">
                <label class="custom-control-label pt-1" for="people">People</label>
              </div>
            </div>
            <div class="col-4">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="familyTrust" value="SCTDI" formControlName="primaryType">
                <label class="custom-control-label pt-1" for="familyTrust">Family Trust</label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>