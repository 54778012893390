import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { uniqBy, prop, propEq, find, complement, either, isNil, isEmpty } from 'ramda';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { CriticalIllnessState } from '../../../../../../shared/models/client-review-template/risk-analysis/critical-illness/critical-illness.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { objectUtil } from '../../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../client-review-template.query';
import { ClientReviewTemplateService } from '../../client-review-template.service';
import { ClientReviewTemplateStore } from '../../client-review-template.store';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

@Injectable()
export class CriticalIllnessService
  extends ClientReviewTemplateService
  implements Resolve<boolean>
{
  criticalIllness$ = this.query.criticalIllness$;
  endpoint = 'crt/risk-analysis/advice-process/';

  constructor(
    private api: ApiService,
    protected dropdownValueQuery: DropdownValueQuery,
    protected store: ClientReviewTemplateStore,
    protected query: ClientReviewTemplateQuery,
    protected customerService: CustomerService,
    protected businessService: BusinessService
  ) {
    super(dropdownValueQuery, store, query, customerService, businessService);
  }

  getCriticalIllness(adviceProcessId: number, sectionCode: string) {
    const endpoint = this.endpoint + `${adviceProcessId}/${sectionCode}`;
    return this.api.get<any>(endpoint).pipe(
      tap((x) =>
        applyTransaction(() => {
          const data = x
            .map((item) => ({
              ...item,
              adviceProcessId,
            }))
            .map(objectUtil.mapPascalCaseToCamelCase) as CriticalIllnessState[];

          const state = complement(either(isNil, isEmpty))(x)
            ? data
            : [];

          this.store.setCriticalIllness(state);
        })
      ),
      catchError(() => of([]))
    );
  }

  addCriticalIllness(personIllness: CriticalIllnessState) {
    const { touched, ...data } = personIllness;
    const endpoint = `crt`;
    const body = objectUtil.mapCamelCaseToPascalCase(data);
    return this.api.post3<number>(endpoint, body).pipe(
      tap((x) =>
        applyTransaction(() => {
          const state = this.query.getValue().criticalIllness || [];
          const criticalIllnessData = state.map(
            (y: CriticalIllnessState) =>
              y.parentCRTId === +personIllness.parentCRTId
                ? {
                  ...personIllness,
                  cRTId: x,
                  touched: false,
                }
                : y
          ) as CriticalIllnessState[];
          this.store.setCriticalIllness(criticalIllnessData);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateCriticalIllness(personIllness: CriticalIllnessState) {
    const { touched, ...data } = personIllness;
    const endpoint = `crt/${personIllness.cRTId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(data);
    return this.api.put<CriticalIllnessState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          const criticalIllnessData = (
            this.query.getValue().criticalIllness.map((y) =>
              y.cRTId === personIllness.cRTId
                ? {
                  ...personIllness,
                  touched: false,
                }
                : y
            )
          ) as CriticalIllnessState[];
          this.store.setCriticalIllness(criticalIllnessData);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  deleteCriticalIllness(id: number) {
    const endpoint = `crt/${id}`;
    return this.api.delete<any>(endpoint).pipe(
      tap(() => {
        applyTransaction(() => {
          const data = this.query
            .getValue()
            .criticalIllness.filter((y) => y.cRTId !== id);
          this.store.setCriticalIllness(data);
        });
      }),
      catchError(() => EMPTY)
    );
  }

  updateCriticalIllnessState(parentCRTId: number, personIllness: CriticalIllnessState) {
    applyTransaction(() => {
      let data = [];
      let state = this.query.getValue().criticalIllness || [];
      const isNew = isNil(
        find(propEq('parentCRTId', parentCRTId))(state)
      );
      if (isNew) {
        // tslint:disable-next-line: no-angle-bracket-type-assertion
        data = <CriticalIllnessState[]> [
          ...state,
          {
            ...personIllness,
          },
        ];
      } else {
        state = uniqBy(prop('parentCRTId'), state);
        // tslint:disable-next-line: no-angle-bracket-type-assertion
        data = <CriticalIllnessState[]> state.map((y: CriticalIllnessState) =>
          y.parentCRTId === +parentCRTId
            ? {
              ...personIllness,
            }
            : y
        );
      }
      this.setHasFormChanges(true);
      this.store.setCriticalIllness(data);
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const adviceProcessId = +route.paramMap.get('adviceProcessId');

    const getCriticalIllness$ = isNil(this.query.getValue().criticalIllness)
      ? this.getCriticalIllness(adviceProcessId, AdviceProcessSectionCodes.CriticalIllness)
      : of(undefined);

    return forkJoin([getCriticalIllness$]).pipe(mapTo(true));
  }
}
