import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as R from 'ramda';
import { iif, Observable, of, Subject } from 'rxjs';
import {
  concatMap,
  filter,
  finalize,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MergeTagsMapper } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { DisclosureSettingsService } from '../../../../../../modules/user/service/disclosure-settings.service';
import { getContentWithMergeTags } from '../../../../../../shared/converter/content-merge-tags';
import { DisclosureDocumentMapper } from '../../../../../../shared/models/client-review-template/disclosure-document/disclosure-document.mapper';
import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { convertUtil, objectUtil } from '../../../../../../util/util';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { MergeTagsService } from '@modules/user/service/merge-tags.service';
declare var $: any;

@Component({
  selector: 'app-disclosure-template',
  templateUrl: './disclosure-template.component.html',
  styleUrls: ['./disclosure-template.component.scss'],
})
export class DisclosureTemplateComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  @Input() staffId: number;
  @Input() settingsId$: Observable<number>;
  mergeTags$: Observable<MergeTagState[]>;

  isLoading = false;
  isPreview = false;

  customButtons: [];
  currentTemplate = '<p></p>';
  tempData: string;
  templateId: number;
  shortcodes: object;
  mergeTags: MergeTagState[];
  @ViewChild('contentEditor') editor: WysiwygComponent;
  disclosureSettings$ = this.dService.disclosureSettings$;
  pCTmergeTagsLoaded$ = this.mtService.pCTmergeTagsLoaded$;

  constructor(
    private dService: DisclosureSettingsService,
    private mtService: MergeTagsService,
  ) {}

  ngOnInit(): void {
    this.mtService.mergeTags$
      .pipe(
        map((mt) => MergeTagsMapper.mapMergeTags(mt)),
        filter((data) => !!data),
        tap((data) => (this.mergeTags = data)),
        tap((data) => {
          this.shortcodes = MergeTagsMapper.mapTagsForWysiwyg(data)
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.prepData();
  }

  prepData() {
    this.disclosureSettings$
      .pipe(
        filter((data) => !!data),
        tap((data) => {
          this.templateId = +data?.template || 0;
        }),
        mergeMap((data) =>
          iif(
            () => R.isNil(data?.template),
            of(null),
            this.dService.getDisclosureDocumentFile(+data?.template)
          )
        ),
        map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
        mergeMap((res) =>
          iif(
            () => res?.documentLink,
            this.dService.getDocumentFromURL(res?.documentLink),
            of(null)
          )
        ),
        tap((data) => {
          if (data) {
            this.currentTemplate = data;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  save() {
    this.isLoading = true;

		const content = Boolean(this.editor?.content?.trim()) ? this.editor?.content : '<p></p>';
    of(content)
      .pipe(
        mergeMap((currentTemplate) =>
          convertUtil.convertToBase64(currentTemplate)
        ),
        withLatestFrom(of(this.templateId)),
        map(([currentTemplate, templateId]) =>
          DisclosureDocumentMapper.mapNewDocumentUpload(
            currentTemplate,
            this.staffId
          )
        ),
        // concatMap((data) =>
        //   iif(
        //     () => R.isNil(data.documentId),
        //     this.dService.newFileUploadDD(data),
        //     this.dService.updateFileUploadDD(data)
        //   )
        // ),
        concatMap((data) => {
          return this.dService.newFileUploadDD(data);
        }),
        withLatestFrom(of(this.templateId), this.settingsId$),
        concatMap(([data, templateId, settingsId]) => {
          const isNewTmpl = templateId === 0 || !templateId;
          const newTmplId = isNewTmpl ? data : templateId;
          const formValue = DisclosureDocumentMapper.mapDocTemplateToUpsert({
            referenceId: this.staffId,
            template: newTmplId,
          });
          this.templateId = newTmplId;

          return iif(
            () => R.isNil(settingsId),
            this.dService.addDisclosureDocument(+this.staffId, formValue),
            this.updateDdTemplate(isNewTmpl, settingsId, formValue)
          );
        }),
        finalize(() => (this.isLoading = false)),
        take(1)
      )
      .subscribe();
  }

  updateDdTemplate = (toUpdateDd: boolean, settingsId: number, formValue) => {
    return this.dService.updateDisclosureDocument(+this.staffId, {
      ...formValue,
      settingsId,
      templateType: 'FT'
    })
  }


  backToEdit() {
    this.currentTemplate = this.tempData;
    this.isPreview = false;
    $(
      'app-disclosure-template .fr-element.fr-view, app-disclosure-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css('pointer-events', 'unset');
    $(
      'app-disclosure-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css({ opacity: 1 });
  }

  showPreview() {
    this.isPreview = true;
    this.tempData = this.editor?.content;
    const getConvertedContent = getContentWithMergeTags(
      this.editor?.content,
      this.mergeTags
    );
    $(
      'app-disclosure-template .fr-element.fr-view, app-disclosure-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css('pointer-events', 'none');
    $(
      'app-disclosure-template .fr-toolbar .fr-btn:not([id*="fullscreen"])'
    ).css({ opacity: 0.6 });
    this.currentTemplate = getConvertedContent;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
