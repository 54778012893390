import { FactFindRiskProfileQASettingsState, FactFindRiskProfileOutcomesSettingsState, RiskProfileOutcomeState } from './fact-find-risk-profile-settings.model';
import { SettingsTypes } from '../../../state/kiwisaver-settings.model';

export class FactFindRiskProfileQASettingsMapper {
	public static mapToView(data: FactFindRiskProfileQASettingsState) {
		const {
			qA,
			cRTSettingsId
		} = data;

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFindRiskProfileQA,
			cRTSettingsId, 
			qA
		};
	}

	public static mapToUpsert(data: FactFindRiskProfileQASettingsState) {
		const {
			qA,
			cRTSettingsId
		} = data;

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFindRiskProfileQA,
			cRTSettingsId, 
			qA
		};
	}
}

export class FactFindRiskProfileOutcomeSettingsMapper {
	public static mapToView(data: FactFindRiskProfileOutcomesSettingsState) {
		const {
			outcomes,
			cRTSettingsId
		} = data;

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFindRiskProfileOutcomes,
			cRTSettingsId, 
			outcomes
		};
	}

	public static mapToUpsert(data: FactFindRiskProfileOutcomesSettingsState): FactFindRiskProfileOutcomesSettingsState {
		const {
			outcomes,
			cRTSettingsId
		} = data;

		const newOutcomes: RiskProfileOutcomeState[] = outcomes?.map((outcome) => {
			return {
				id: outcome.id,
				outcome: outcome.outcome,
				score: outcome.score || 0,
				status: outcome.status,
				isDefault: outcome.isDefault,
			};
		});

		return {
			referenceId: 0,
			type: SettingsTypes.KOATFactFindRiskProfileOutcomes,
			cRTSettingsId, 
			outcomes: newOutcomes
		};
	}
}
