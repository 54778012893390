import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanDeactivate,
} from '@angular/router';
import { ExportsStatus } from '@modules/exports/state/exports.model';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsService } from '@modules/exports/state/exports.service';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class BusinessLeaveGuard implements CanDeactivate<CanComponentDeactivate> {
	constructor(
    private exportsService: ExportsService,
    private exportsQuery: ExportsQuery,
  ) {}

	canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.exportsQuery.status$.pipe(
      take(1),
      tap((status) => {
        if (status === ExportsStatus.STARTED) {
          this.exportsService.stopPolling();
        }
      }),
      map(() => true),
      catchError(() => {
        return of(true);
      })
    );
	}
}
