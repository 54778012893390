import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { finalize, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { companyToViewDisplayValueUtil } from 'src/app/modules/crm/client-review-template/util/mapViewDisplayValue.util';
import { PeopleModalComponent } from 'src/app/shared/modal/crt/moat/people/people-modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { PeopleEntitiesQuery } from '../state/people-entities.query';
import { PeopleEntitiesService } from '../state/people-entities.service';
import { CompanyState } from 'src/app/shared/models/client-review-template/company/company.model';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import { logMessage } from 'src/app/shared/error-message/error-message';
declare var $: any;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  @Input() company$: Observable<any[]>;

  @Input() APCRTBP$: Observable<ViewDisplayValue[]>;
  @Input() APCRTYN$: Observable<ViewDisplayValue[]>;
  @Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;

  @Input() addFn$: ({ company, adviceProcessId }) => Observable<any>;
  @Input() addNewFn$: ({ company, adviceProcessId }) => Observable<any>;
  @Input() updateFn$: (company) => Observable<any>;
  @Input() deleteFn$: (id) => Observable<any>;
  @Input() isCompanyLoading$: Observable<boolean>;

  elseMinusCompany = true;
  bsModalRef: BsModalRef;
  crtLoading: number;
  isAddNew = false;
  isSaving = false;

  companyList$ = new BehaviorSubject<ViewDisplayValue[]>(null);
  companyInfo = crtId => this.peopleEntitiesService.getPersonInfo(crtId);
  constructor(
    private peopleEntitiesQuery: PeopleEntitiesQuery,
    private peopleEntitiesService: PeopleEntitiesService,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private route: ActivatedRoute) { }
  ngOnInit(): void {
  }

  collapseMoreCompany() {
    $('#collapseCompany').toggle();
    this.elseMinusCompany = false;
  }
  collapseLessCompany() {
    $('#collapseCompany').toggle();
    this.elseMinusCompany = true;
  }

  toggleLoader(cRTId: number, load: boolean) {
    this.crtLoading = (cRTId && load) ? +cRTId : null;
  }

  isCrtLoading(cRTId: number) {
    if (this.isAddNew && !this.isSaving) { return false; }
    if (this.isSaving && cRTId === 0) { return true; }
    return +this.crtLoading === +cRTId;
  }

  updateCompany = (model: CompanyState) => new Observable<any>((obs) => {
    obs.next(model);
    obs.complete();
  }).pipe(
    mergeMap(x => this.updateFn$(x)),
    finalize(() => {
      this.isAddNew = false;
      this.toggleLoader(+model?.cRTId, false);
      this.cd.detectChanges();
    })
  )

  editCompany(crtId: number) {
    this.toggleLoader(+crtId, true);
    const decline = new Observable((obs: Observer<any>) => {
      this.toggleLoader(crtId, false);
      obs.complete();
    });
    this.companyInfo(crtId).subscribe((x) => {
      const initState = {
        header: 'Company Details',
        message: `CompanyDetails`,
        apcrtbp$: this.APCRTBP$,
        apcrtyn$: this.APCRTYN$,
        apcrtynna$: this.APCRTYNNA$,
        companyInfo: x,
        savefn: this.updateCompany,
        decline$: decline,
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    })
  }

  deleteItem = (id: number) =>
    this.deleteFn$(id)
      .pipe(
        mergeMap(() => this.updateCompanyDd()),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe((x) => {
        this.toggleLoader(id, false);
      });


  deleteCompany(id: number) {
    this.toggleLoader(id, true);

    const confirm = new Observable((obs) => {
      this.deleteItem(id);
      obs.next();
      obs.complete();
    });

    const decline = new Observable((obs: Observer<any>) => {
      this.toggleLoader(id, false);
      obs.complete();
    });

    const initState = {
      header: 'Delete Company',
      message: logMessage.oat.shared.factFind.delete,
      delete$: confirm,
      decline$: decline,
      confirmButton: 'OK',
      detachCloseIcon: false
    };
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-dialog-centered modal-dialog',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  addCompany() {
    this.isAddNew = true;
    this.cd.detectChanges();
    this.peopleEntitiesQuery.companyFromCRMAndCRT$.pipe(
      mergeMap(x => companyToViewDisplayValueUtil(x)),
      tap(x => this.companyList$.next(x)),
      switchMap(() => this.company$.pipe(
        map(x => {
          const newPeople: PeopleState = {
            cRTId: 0,
            age: 0,
            birthDate: '',
            customerType: '',
            name: '',
            relationship: '',
            customerId: 0
          }
          x.push({
            ...newPeople,
            industry: ''
          })
        })

      )),
      take(1)
    ).subscribe()
  }

  addNewCompany = (model: CompanyState) => new Observable<any>((obs) => {
    this.isSaving = true;
    obs.next(model);
    obs.complete();
  }).pipe(
    mergeMap(x => this.addNewFn$({ company: x, adviceProcessId: parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10) })),
    finalize(() => {
      this.isAddNew = false;
      this.isSaving = false;
      this.cd.detectChanges();
    })
  )

  change(e, i: number) {
    if (e.target.value === 'new') {
      const decline = new Observable((obs: Observer<any>) => {
        (document.getElementById('addNewCompanyDropdown_' + i) as HTMLInputElement).value = '';
        obs.complete();
      });
      const initState = {
        header: 'Company Details',
        message: `CompanyDetails`,
        apcrtbp$: this.APCRTBP$,
        apcrtyn$: this.APCRTYN$,
        apcrtynna$: this.APCRTYNNA$,
        savefn: this.addNewCompany,
        decline$: decline,
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    } else {
      this.peopleEntitiesQuery.companyFromCRMAndCRT$.pipe(
        map((x) => x?.find(y => y.customerID === +e.target.value)),
        mergeMap((x) => this.company$
          .pipe(
            map(p => {
              const newPeople: PeopleState = {
                cRTId: 0,
                age: null,
                birthDate: null,
                customerType: x.customerType,
                name: x.companyName,
                relationship: null,
                customerId: x.customerID
              }
              p[i] = {
                ...newPeople,
                tradingName: x.tradingName,
                industry: x.industry
              }
            })
          )
        ),
        take(1)
      ).subscribe()
    }
  }

  addExistingCompany(p) {
    this.isSaving = true;
    this.peopleEntitiesQuery.companyFromCRMAndCRTNoFilter$.pipe(
      map((x) => x?.find(c => c.customerID === +p.customerId)),
      map(client => {
        const adviceProcessId = parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10);
        const company = {
          customerID: client.customerID,
          adviceProcessId,
          sectionCode: 'FPC',
          businessName: client.companyName,
          tradingName: client.tradingName,
          purpose: client.purpose ? client.purpose : '',
          industry: client.industry ? client.industry : '',
          noOfEmployees: client.noOfEmployees ? client.noOfEmployees : 0,
          hasAccountant: client.hasAccountant ? client.hasAccountant : '',
          hasShareholdersAgreement: client.hasShareholdersAgreement ? client.hasShareholdersAgreement : '',
          hasBuySellAgreement: client.hasBuySellAgreement ? client.hasBuySellAgreement : '',
          businessValued: client.businessValued ? client.businessValued : '',
          businessValue: client.businessValue ? client.businessValue : 0,
          shareholderUnableToWork: client.shareholderUnableToWork ? client.shareholderUnableToWork : '',
          shareholderPassesAway: client.shareholderPassesAway ? client.shareholderPassesAway : '',
          shareholdersList: client.shareholdersList ? client.shareholdersList : [],
          directorsList: client.directorsList ? client.directorsList : [],
          otherKeyPeopleList: client.otherKeyPeopleList ? client.otherKeyPeopleList : [],
          accountantList: client.accountantList ? client.accountantList : []
        }
        return { company, adviceProcessId }
      }),
      take(1),
      mergeMap(res => this.addFn$({ company: res.company, adviceProcessId: res.adviceProcessId })),
      finalize(() => {
        this.isAddNew = false;
        this.isSaving = false;
        this.cd.detectChanges();
      })
    ).subscribe()
  }

  removeCompany(index: number) {
    this.company$.pipe(
      map(x =>
        x?.splice(index, 1)
      ),
      take(1)
    ).subscribe(() => {
      this.isAddNew = false;
      this.cd.detectChanges();
    })
  }


  updateCompanyDd = () =>
    this.peopleEntitiesQuery.companyFromCRMAndCRT$.pipe(
      mergeMap((x) => companyToViewDisplayValueUtil(x)),
      tap((x) => this.companyList$.next(x?.filter((y) => !!y.value))),
      take(1)
    );

  ngOnDestroy() {
    this.company$
			.pipe(
				map((x) => {
					if (x?.some((p) => p?.cRTId === 0 || !p?.cRTId)) {
						x?.splice(
							x.findIndex((p) => p?.cRTId === 0 || !p.cRTId),
							1
						);

						return x;
					}
					return x;
				}),
        take(1)
			)
			.subscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
