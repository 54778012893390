import { Injectable } from '@angular/core';
import { ApiService, JsonResultStatus } from 'src/app/core/base/api.service';
import { ConfigService } from 'src/app/core/config/config.service';
import { UserQuery } from 'src/app/domain/user/user.query';
import { UserStore } from 'src/app/domain/user/user.store';
import { AdviceProcessQuery } from './advice-process.query';
import { AdviceProcessStore } from './advice-process.store';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { applyTransaction } from '@datorama/akita';
import { AdviceProcessRequest } from '../advice-process-request.model';
import * as R from 'ramda';
import { AdviceProcessResponse } from './advice-process.model';
import { concat, forkJoin, from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mergeMap,
  reduce,
  retry,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import produce from 'immer';
import {
  columns,
  createRowFromPrototype,
  Metakey,
} from '../advice-process-datatable.config';
import { ActivityViewModel } from 'src/app/shared/models/_general/activity.viewmodel';
import MomentUtil from 'src/app/util/moment.util';
import * as moment from 'moment';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { objectUtil } from '@util/util';

@Injectable()
export class AdviceProcessService {
  constructor(
    private api: ApiService,
    private store: AdviceProcessStore,
    private query: AdviceProcessQuery,
    private userQuery: UserQuery,
    private userStore: UserStore,
    protected configService: ConfigService,
    private activityService: ActivityService,
    private loggerService: LoggerService
  ) {}

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
      this.store.uiStore.reset();
    });
  }

  openPopup = () => this.store.uiStore.toggleColumnPopup(true);
  closePopup = () => this.store.uiStore.toggleColumnPopup(false);
  togglePopup = () =>
    this.store.uiStore.toggleColumnPopup(
      !this.query.uiQuery.getValue().columnFormPopupOpen
    );

  search2(req: AdviceProcessRequest) {
    this.store.uiStore.setSort(null, null);
    const batchLength = 100;

    const getIndexesToFetch: (res: AdviceProcessResponse) => number[] = R.pipe(
      (res: AdviceProcessResponse) =>
        Math.ceil(res.TotalCount / batchLength - 1),
      (totalPages: number) =>
        Array(totalPages)
          ?.fill(1)
          ?.map((x, i) => i + 1)
          .slice(1)
    );
    const searchRequest = (request: AdviceProcessRequest) =>
      this.api.post3<AdviceProcessResponse>('search/advice-process', request);

    const firstPage$ = of(req).pipe(
      map(
        produce((draft) => {
          draft.Paging = {
            Index: 2,
            Column: req.Paging.Column,
            Direction: req.Paging.Direction,
          };
        })
      ),
      mergeMap(searchRequest),
      shareReplay()
    );

    return firstPage$.pipe(
      mergeMap((res) =>
        concat(
          firstPage$,
          from(getIndexesToFetch(res)).pipe(
            map((i) =>
              produce(req, (draft) => {
                draft.Paging = {
                  Index: i + 1,
                  Column: req.Paging.Column,
                  Direction: req.Paging.Direction,
                };
              })
            ),
            concatMap((req2) => searchRequest(req2))
          )
        )
      ),
      reduce((acc, v) =>
        produce(acc, (draft) => {
          draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
          draft.IsComplete = true;
        })
      ),
      map((res) =>
        applyTransaction(() => {
          return res;
        })
      )
    );
  }

  search(req: AdviceProcessRequest) {
    this.store.uiStore.setIsSearching(true);

    return this.api
      .post3<AdviceProcessResponse>('search/advice-process', req)
      .pipe(
        withLatestFrom(this.query.templateRow$),
        switchMap(([x, templateRow]) => {
          if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
            // Saves initial fetch
            const rows: any[] = R.map(
              createRowFromPrototype(templateRow),
              x.SearchResults
            );
            this.store.set([]);
            this.store.set([...rows]);
            return this.search2(req);
          }
          return of(x);
        }),
        withLatestFrom(this.query.templateRow$, (res, template) => ({
          response: res,
          templateRow: template,
        })),
        map((res) =>
          applyTransaction(() => {
            const rows: any[] = R.map(
              createRowFromPrototype(res.templateRow),
              res.response.SearchResults
            );

            if (
              req.Paging.Index === 1 &&
              this.query.getCount() !== 0 &&
              res.response.TotalCount > 500
            ) {
              this.store.set([]);
              res.response.IsComplete = false;
            }

            const a = this.query.getAll();
            if (
              req.Paging.Index === 1 &&
              res.response.IsComplete &&
              res.response.TotalCount <= 100
            ) {
              this.store.set([]);
              this.store.set([...rows]);
            } else {
              this.store.set([...a, ...rows]);
            }

            this.store.update((state) => ({
              ...state,
              count: res.response.TotalCount,
              totalAPI: res.response.TotalAPI,
              isComplete: res.response.IsComplete,
            }));

            return res.response;
          })
        ),
        finalize(() => {
          this.store.setSearchForm(req);
          this.store.uiStore.setIsSearching(false);
        })
      );
  }

  /**
   * @param {AdviceProcessRequest} req request
   * Sets IsExport to true in akita store
   * @returns {Observable} Blob
   * Sets IsExport to false in akita store
   */
  export(req: AdviceProcessRequest): Observable<any> {
    return this.api.post4('export/advice-process', req).pipe(
      map((x) => {
        const obj = this.tryParseJSON(x);
        if (!obj) {
          return new Blob([x], {
            type: 'text/plain',
          });
        } else {
          return obj;
        }
      }),
      retry(1),
      catchError((err) => {
        this.loggerService.Log(err, logMessage.shared.export.error);
        return throwError(new Error(err));
      })
    );
  }

  tryParseJSON(jsonString: any): boolean {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  reloadData() {
    const data = this.query.getAll();
    this.store.set([]);
    this.store.set([...data]);
  }

  getColumns(): Observable<any> {
    return this.query
      .select((x) => x.columns)
      .pipe(
        take(1),
        filter((x) => (x ? x.length < 1 : false)),
        mergeMap((x) => this.userQuery.staffSettings$),
        tap((x) =>
          R.complement(R.either(R.isNil, R.isEmpty))(x)
            ? this.store.setColumns(
                !!x.AdviceProcessSearchColumns
                  ? JSON.parse(x.AdviceProcessSearchColumns)
                  : []
              )
            : null
        )
      );
  }

  getColumnWidths(): Observable<any> {
    return this.query
      .select((x) => x.columnWidths)
      .pipe(
        take(1),
        filter((x) => (x ? x.length < 1 : false)),
        mergeMap((x) => this.userQuery.staffSettings$),
        tap((x) =>
          R.complement(R.either(R.isNil, R.isEmpty))(x)
            ? this.store.setColumnWidths(
                !!x.AdviceProcessSearchColumnsWidth
                  ? JSON.parse(x.AdviceProcessSearchColumnsWidth)
                  : []
              )
            : null
        )
      );
  }

  reorderColumn = (oldIndex: number, newIndex: number) => {
    const newCol = produce(this.query.getValue().columns, (draft) => {
      const movedCol = draft?.splice(oldIndex, 1);
      draft?.splice(newIndex, 0, movedCol[0]);
    });
    const newColWith = produce(this.query.getValue().columnWidths, (draft) => {
      const movedCol = draft?.splice(oldIndex, 1);
      draft?.splice(newIndex, 0, movedCol[0]);
    });
    return of(newCol).pipe(
      tap(() => {
        this.store.setColumns(newCol);
        this.store.setColumnWidths(newColWith);
      }),
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          AdviceProcessSearchColumns: JSON.stringify(newVal),
          AdviceProcessSearchColumnsWidth: JSON.stringify(newColWith),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap
          ? of(null)
          : this.api
              .put(`staff/${user.StaffID}/bl`, req)
              .pipe(tap(() => this.userStore.update(req)))
      )
    );
  };

  resizeColumn(prop: string, width: number): Observable<any> {
    const oldColWidths = this.query.getValue().columnWidths?.filter((x) => x);
    const newColWidths = produce(oldColWidths, (draft) => {
      const col = columns?.find((x) => x.prop === prop);
      const exists = draft?.some((x) => col.metakey === x.metakey);
      if (exists) {
        draft.find((x) => col.metakey === x.metakey).width = width;
      } else {
        draft.push({ metakey: col.metakey, width });
      }
    });

    return of(newColWidths).pipe(
      tap((x) => this.store.setColumnWidths(x)),
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          AdviceProcessSearchColumnsWidth: JSON.stringify(newVal),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)
      )
    );
  }

  saveVisibleColumns = (metakeys: Metakey[]) => {
    const newColumns = metakeys;
    const oldColumns = this.query.getValue().columns;

    if (R.equals(newColumns, oldColumns)) {
      return of();
    }

    this.store.uiStore.setIsColumnSaving(true);

    const newColumnMetakeys = newColumns;

    return of(newColumnMetakeys).pipe(
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          AdviceProcessSearchColumns: JSON.stringify(newVal),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap
          ? of(null)
          : this.api
              .put(`staff/${user.StaffID}/bl`, req)
              .pipe(tap(() => this.userStore.update(req)))
      ),
      finalize(() => this.store.uiStore.setIsColumnSaving(false)),
      tap(() => this.store.setColumns(newColumnMetakeys))
    );
  };

  saveField(req: {
    CustomerID: number;
    AdviceProcessID: number;
    MetaKey: Metakey;
    MetaValue: string;
    CustomerServiceType: string;
  },claimsField:boolean = false): Observable<JsonResultStatus> {
    const record = this.query.getEntity(req.AdviceProcessID);
    const fields = R.omit(
      [
        'CustomerID',
        'AdviserName',
        'ReviewedByName',
        'link',
        'ClientNextActivityId',
      ],
      record
    );
    const omitPayloadParam = ['CustomerID', 'AdviceProcessID'];
    const noSpaceMetakey = req.MetaKey.replace(/\s+/g,'');
    const payloadReq = !claimsField ? req:{
      Key:noSpaceMetakey,
      ReferenceID: req.AdviceProcessID,
      SecondaryReferenceID:0,
      Value:req.MetaValue
    };


    this.store.uiStore.setLoad(req.AdviceProcessID, req.MetaKey, true);
    const apiUrl = !claimsField ?
      `contacts/${req.CustomerID}?isPatch=true`: 
      `adviceprocesses`;

    const serviceCall = !claimsField ?
    this.api
    .put<JsonResultStatus>(
      `${apiUrl}`,
      R.omit(omitPayloadParam, payloadReq)
    ): this.api
    .patch<JsonResultStatus>(
      `${apiUrl}`,[payloadReq]
    );
    
    return serviceCall
      .pipe(
        tap(() =>
          applyTransaction(() => {
            const stateReq = {
              ...req,
            };
            this.store.updateField(stateReq);
            this.store.uiStore.setEdit(req.AdviceProcessID, req.MetaKey, false);
            this.setTempValue(req.AdviceProcessID, req.MetaKey, undefined);
          })
        ),
        finalize(() =>
          this.store.uiStore.setLoad(req.AdviceProcessID, req.MetaKey, false)
        )
      );
  }

  edit = (customerServiceId: number, metakey: Metakey) =>
    this.store.uiStore.setEdit(customerServiceId, metakey, true);
  cancel = (customerServiceId: number, metakey: Metakey) =>
    applyTransaction(() => {
      this.store.uiStore.setEdit(customerServiceId, metakey, false);
      this.setTempValue(customerServiceId, metakey, undefined);
    });

  updateExclusions = (customerServiceId: number, value: string) => {
    this.store.update(
      customerServiceId,
      produce((draft) => {
        draft.Exclusions.value = value;
      })
    );
  };

  updateNote = (AdviceProcessID: number, value: string) => {
    this.store.update(
      AdviceProcessID,
      produce((draft) => {
        const nzToday = MomentUtil.createMomentNz().format('DD/MM/YYYY');
        const ln =
          nzToday +
          ' - ' +
          this.userQuery.getValue().FirstName +
          ' ' +
          this.userQuery.getValue().LastName +
          ' - ' +
          value;
        draft.LastNote.value = ln;
      })
    );
  };

  updateClientAndUserNextActivity = (customerId: number) => {
    return forkJoin(
      this.updateClientNextActivity(customerId),
      this.updateUserNextActivity(customerId)
    );
  };

  updateClientNextActivity = (customerId: number) => {
    const adviceProcessId = this.query
      .getAll({
        filterBy: (row) => row.CustomerID === customerId,
      })
      ?.map((z) => z.AdviceProcessID);
    adviceProcessId?.forEach((id) => {
      this.store.uiStore.setLoad(id, 'Next Activity', true);
    });
    return this.api
      .get<ActivityViewModel>(`activities/${customerId}/customer`, {
        nextActivityOnly: true,
      })
      .pipe(
        tap((x) => {
          this.store.update(
            adviceProcessId,
            produce((draft) => {
              if (x && !!x.ActivityId) {
                const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
                draft.ClientNextActivityId = x.ActivityId;
                // tslint:disable-next-line: max-line-length
                draft.NextActivity.value =
                  formattedDate +
                  ' - ' +
                  x.AssignedToAdviserName +
                  ' - ' +
                  x.ActivityType +
                  ' - ' +
                  x.ActivityName;
              } else {
                draft.ClientNextActivityId = null;
                draft.NextActivity.value = null;
              }
            })
          );
        }),
        finalize(() => {
          adviceProcessId?.forEach((id) => {
            this.store.uiStore.setLoad(id, 'Next Activity', false);
          });
        })
      );
  };

  updateUserNextActivity = (customerId: number) => {
    const adviceProcessIds = this.query
      .getAll({ filterBy: (row) => row.CustomerID === customerId })
      ?.map((z) => z.AdviceProcessID);
    adviceProcessIds?.forEach((id) => {
      this.store.uiStore.setLoad(id, 'Next Activity', true);
    });
    return this.api
      .get<ActivityViewModel>(`activities/${customerId}/adviser`, {
        nextActivityOnly: true,
      })
      .pipe(
        tap((x) => {
          this.store.update(
            adviceProcessIds,
            produce((draft) => {
              if (x && !!x.ActivityId) {
                const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
                draft.UserNextActivityId = x.ActivityId;
                draft.NextActivity.value =
                  formattedDate +
                  ' - ' +
                  x.ActivityType +
                  ' - ' +
                  x.ActivityName;
              } else {
                draft.UserNextActivityId = null;
                draft.NextActivity.value = null;
              }
            })
          );
        }),
        finalize(() => {
          adviceProcessIds?.forEach((id) => {
            this.store.uiStore.setLoad(id, 'Next Activity', false);
          });
        })
      );
  };

  createClientNextActivity = (ac: ActivityViewModel) =>
    of(ActivityViewModel.MapToAdd(ac)).pipe(
      mergeMap((x) => this.activityService.Post(x)),
      mergeMap(
        (y) => {
          if (y) {
            return this.updateClientAndUserNextActivity(ac.Customer.CustomerId);
          }
        },
        (o) => o
      )
    );

  sort(propSort: string, sort: 'asc' | 'desc') {
    this.store.uiStore.setSort(propSort, sort);
  }

  setTempValue = (customerServiceId: number, metakey: string, value: any) =>
    this.store.uiStore.setTempValue(customerServiceId, metakey, value);

  delete(adviceProcessId: number): Observable<any> {
    return of(adviceProcessId).pipe(
      tap(() => this.store.uiStore.setIsDeleting(adviceProcessId, true)),
      mergeMap((id) =>
        this.api.delete<JsonResultStatus>(`adviceprocesses/${id}`)
      ),
      finalize(() => this.store.uiStore.setIsDeleting(adviceProcessId, false)),
      tap(() => {
        this.store.remove(adviceProcessId);
        this.store.update((x) => ({ count: x.count - 1 }));
      })
    );
  }

	getAdviceStages() {
		const endpoint = `adviceprocesses/settings/all/stages`;
    return this.api.get<any>(endpoint).pipe(
			map((data) => data?.map(objectUtil.mapPascalCaseToCamelCase) || []),
			tap((data) =>
				applyTransaction(() => {
					this.store.uiStore.setAdviceStages(data);
				})
			),
			catchError(() => of([]))
		);
  }
}
