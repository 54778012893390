<div class="clearfix">
	<div
		class="form-row font-weight-bold d-none d-lg-flex mb-2"
		[class.tap-opacity-25]="(!assets || assets?.length === 0) && !isAdd"
	>
		<div class="col-12 col-lg-3">Assets</div>
		<div class="col-12 col-lg-3">Owner</div>
		<div class="col-12 col-lg-3">Description</div>
		<div class="col-12 col-lg-3">Value</div>
	</div>

	<ng-content select="app-manual-loader-mini"></ng-content>

	<div class="mb-4">
		<app-crt-asset-form
				*ngFor="
				let a of assets;
				let i = index;
				let last = last;
				trackBy: trackByFn
			"
			[formId]="i"
			[asset]="a"
			[totalValue]="totalValue"
			[policyOwners]="policyOwners$ | async"
			[APCRTA$]="APCRTA$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[disableUpdate]="isAdd || (!!(editMode$ | async) && +(editMode$ | async) !== +a?.cRTId)"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			(editModeEvent)="isEditMode($event)"
			class="d-block mb-2"
			[class.border-bottom]="!last"
		></app-crt-asset-form>
	</div>

	<div class="clearfix mb-4">
		<app-crt-asset-form
			*ngIf="isAdd"
			[addMode]="true"
			[asset]="{}"
			[totalValue]="totalValue"
			[policyOwners]="policyOwners$ | async"
			[APCRTA$]="APCRTA$"
			[addFn$]="addFn$"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="mb-2"
		></app-crt-asset-form>
	</div>

	<div class="form-row align-items-center mb-4">
		<div class="col-lg-3">
			<button
				id="assetAddButton"
				(click)="add()"
				type="button"
				[disabled]="isAdd || !!(editMode$ | async)"
				class="tap-btn tap-btn-outline tap-btn--shadow px-4"
			>
				Add Asset +
			</button>
		</div>
		<div class="col-lg-3"></div>
		<div class="col-lg-3 font-weight-bold tap-text-primary text-right">
			Total Assets Value:
		</div>
		<div class="col-lg-3 font-weight-bold">
			{{ totalValue | currency: "USD":"$":"1.2-2" }}
		</div>
	</div>
</div>
