<div class="clearfix p-3">
	<!-- <h2>Customer Portal Invitation Email</h2>
	<hr />

	<p>
		What information would you like to include in your Customer Portal
		Invitation Email?
	</p>

	<div class="clearfix mb-3">
		<app-crt-email-settings
			[showBody]="true"
			[hideEnableEmailOption]="true"
			(save)="saveInvitationEmail($event)"
			[emailSettings]="currentSettings"
			[isLoading]="isLoading"
			[bodyContent]="content"
		></app-crt-email-settings>
	</div> -->

	<!-- <div class="d-flex justify-content-end mb-4">
		<ng-container *ngIf="!isLoading; else isSaving">
			<button
				type="button"
				class="btn btn-primary my-2 mx-1 tap-bg-primary"
				(click)="saveInvitationEmail()"
			>
				SAVE
			</button>
		</ng-container>
		<ng-template #isSaving>
			<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
		</ng-template>
	</div> -->

	<div class="clearfix mb-3">
		<h2>Current Client Portal Access</h2>
		<hr />

		<div class="ccpa-header border-bottom pb-3">
			<div class="row font-weight-bold">
				<div class="col-md-3">Client</div>
				<div class="col-md-3">Active Advice Process Instances</div>
				<div class="col-md-3">Email Address</div>
			</div>
		</div>

		<div class="clearfix">
			<div
				class="row pt-3"
				*ngFor="
					let item of emailAccessPerAdviceProcess$ | async;
					let last = last
				"
			>
				<div class="col-md-3 font-weight-bold">
					{{ item?.clientFirstName }} {{ item?.clientLastName }}
				</div>
				<div class="col-md-3">{{ item?.adviceProcessCount }}</div>
				<div class="col-md-3">{{ item?.emailAddress }}</div>
				<!-- <div
					class="col-md-3 d-flex justify-content-end"
					(click)="isCollapsed = !isCollapsed"
					[attr.aria-expanded]="!isCollapsed"
					aria-controls="collapseBasic"
				>
					<span class="material-icons md-16 clickable">expand_{{ isCollapsed ? "more" : "less" }}</span>
				</div> -->
				<!-- <div
					class="col-12"
					id="collapseBasic"
					[collapse]="isCollapsed"
					[isAnimated]="true"
				>
					<div class="row py-3">
						<div class="col-md-3">
							<div class="font-weight-bold pl-3">Advice Process #1</div>
						</div>
						<div class="col-md-3">create on 12/12/21</div>
						<div class="col-md-3">example@email.com</div>
					</div>
				</div> -->
				<div class="col-12" [class.d-none]="last"><hr class="mb-0" /></div>
			</div>
		</div>
	</div>
</div>
