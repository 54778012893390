import { util } from '../../../../util/util';
import { SettingsTypes } from '../../state/kiwisaver-settings.model';
import { KiwiScopeOfServiceState } from './kiwi-scope-of-service.model';

export class KiwiScopeOfServiceMapper {
  public static mapToView(data: KiwiScopeOfServiceState) {
    return {
      whatWeWillTalkAboutToday:
        this.parseData(data?.whatWeWillTalkAboutToday) || '',
      whatWeWontTalkAboutToday: this.parseData(data?.whatWeWontTalkAboutToday) || '',
      lifeAndRiskReview: data?.lifeAndRiskReview || false,
      lifeAndRiskReviewDetails:
        this.parseData(data?.lifeAndRiskReviewDetails) || '',
      houseCarAndContentsInsurance: data?.houseCarAndContentsInsurance || false,
      houseCarAndContentsInsuranceDetails:
        this.parseData(data?.houseCarAndContentsInsuranceDetails) || '',
      residentialMortgageOrLending: data?.residentialMortgageOrLending || false,
      residentialMortgageOrLendingDetails: this.parseData(data?.residentialMortgageOrLendingDetails) || '',
      other: data?.other || false,
      type: data?.type ?? SettingsTypes.SOS,
      referenceId: data?.referenceId ?? 0,
    };
  }

  public static mapSosSettingsToView(data: KiwiScopeOfServiceState) {
    // For SOS Settings Only
    return {
      referenceId: 0,
      whatWeWillTalkAboutToday: this.parseData(data?.whatWeWillTalkAboutToday) || '',
      whatWeWontTalkAboutToday: this.parseData(data?.whatWeWontTalkAboutToday) || '',
      lifeAndRiskReview: data?.lifeAndRiskReview || 0,
      lifeAndRiskReviewDetails:
        this.parseData(data?.lifeAndRiskReviewDetails) || '',
      houseCarAndContentsInsurance: data?.houseCarAndContentsInsurance || 0,
      houseCarAndContentsInsuranceDetails:
        this.parseData(data?.houseCarAndContentsInsuranceDetails) || '',
      residentialMortgageOrLending: data?.residentialMortgageOrLending || 0,
      residentialMortgageOrLendingDetails:
          this.parseData(data?.residentialMortgageOrLendingDetails) || '',
      other: data?.other || 0,
      type: SettingsTypes.SOS,
    };
  }

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }

  public static mapToUpsertSosSettings(data: KiwiScopeOfServiceState) {
    return {
      ...data,
      referenceId: 0,
      whatWeWillTalkAboutToday: !!data.whatWeWillTalkAboutToday
        ? JSON.stringify(data.whatWeWillTalkAboutToday)
        : '',
      whatWeWontTalkAboutToday: !!data.whatWeWontTalkAboutToday
        ? JSON.stringify(data.whatWeWontTalkAboutToday)
        : '',        
      lifeAndRiskReviewDetails: !!data.lifeAndRiskReviewDetails
        ? JSON.stringify(data.lifeAndRiskReviewDetails)
        : '',
      houseCarAndContentsInsuranceDetails: !!data.houseCarAndContentsInsurance
        ? JSON.stringify(data.houseCarAndContentsInsuranceDetails)
        : '',
      residentialMortgageOrLendingDetails: !!data.residentialMortgageOrLendingDetails
        ? JSON.stringify(data.residentialMortgageOrLendingDetails)
        : '',
      type: SettingsTypes.SOS,
    };
  }
}
