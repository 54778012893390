import {
  ServiceUpdate,
  ServiceAdd,
  ServiceDelete,
  ServiceArchive
} from '../../../shared/models/_general/services/baseService';
import { ApiService, JsonResultStatus } from '../../base/api.service';

export abstract class BaseService<T> {
  private updateEndpoint = 'customerservice/UpdateService';
  private addEndpoint = 'customerservice/AddService';
  private deleteEndpoint = 'customerservice/DeactivateService';
  private archiveEndpoint = 'customerservice/ArchiveService';

  constructor(protected api: ApiService) { }
  Update(data: ServiceUpdate<T>) {
    return this.api.post3<JsonResultStatus>(this.updateEndpoint, data);
  }
  Add(data: ServiceAdd<T>) {
    return this.api.post3<JsonResultStatus>(this.addEndpoint, data);
  }
  Delete(data: ServiceDelete) {
    return this.api.post3<JsonResultStatus>(this.deleteEndpoint, data);
  }
  Archive(data: ServiceArchive) {
    return this.api.post3<JsonResultStatus>(this.archiveEndpoint, data);
  }
}
