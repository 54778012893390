import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { CompanyMapper } from '../../../../../../shared/models/client-review-template/company/company.mapper';
import { CompanyDetailsState } from '../../../../../../shared/models/client-review-template/company/company-details';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { objectUtil } from 'src/app/util/util';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-company-form',
	templateUrl: './company-form.html',
	styleUrls: ['./company-form.scss'],
})
export class CompanyFormComponent implements OnInit {
	@Input() companyInfo: CompanyDetailsState;
	@Output() saveEvent = new EventEmitter<CompanyDetailsState>();

	@Output() cancelEvent = new EventEmitter<any>();
	@Input() businessPurpose: ViewDisplayValue[];
	@Input() agree: ViewDisplayValue[];
	@Input() viewMode: boolean;
	@Input() loatv2Enabled: boolean;
	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;

	@Input() loading;
	cancelLoading: boolean;

	constructor(
		private loggerService: LoggerService,
		private fb: UntypedFormBuilder
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
	}

	get BusinessName() {
		return this.form.get('businessName');
	}
	get TradingName() {
		return this.form.get('tradingName');
	}
	get Purpose() {
		return this.form.get('purpose');
	}
	get HasShareholdersAgreement() {
		return this.form.get('hasShareholdersAgreement');
	}
	get HasBuySellAgreement() {
		return this.form.get('hasBuySellAgreement');
	}
	get shareholdersList(): UntypedFormArray {
		return this.form.get('shareholdersList') as UntypedFormArray;
	}
	get directorsList(): UntypedFormArray {
		return this.form.get('directorsList') as UntypedFormArray;
	}
	get otherKeyPeopleList(): UntypedFormArray {
		return this.form.get('otherKeyPeopleList') as UntypedFormArray;
	}

	prepData() {
		this.form.reset({ ...CompanyMapper.mapToView(this.companyInfo) });
		const acList = !!this.companyInfo?.accountantList
			? this.companyInfo?.accountantList[0]
			: null;
		this.form.controls.accountantName.setValue(acList?.accountantName);
		this.form.controls.businessAccountName.setValue(acList?.businessName);
		this.form.controls.primaryContact.setValue(
			objectUtil.mapCamelCaseToPascalCase(this.companyInfo?.primaryContact)
		);

		if (this.companyInfo?.shareholdersList) {
			this.setStringControlArray(
				this.companyInfo.shareholdersList,
				'shareholdersList'
			);
		}
		if (this.companyInfo?.directorsList) {
			this.setStringControlArray(
				this.companyInfo.directorsList,
				'directorsList'
			);
		}

		if (this.companyInfo?.otherKeyPeopleList) {
			this.setStringControlArray(
				this.companyInfo.otherKeyPeopleList,
				'otherKeyPeopleList'
			);
		}
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	}

	setStringControlArray(array, controlArrayName: string = '') {
		const controls =
			array === null ? null : array?.map((x) => this.fb.control(x));
		const formArr = this.form.controls[controlArrayName] as UntypedFormArray;

		while (formArr.length !== 0) {
			formArr.removeAt(0);
		}

		if (controlArrayName === 'shareholdersList') {
			if (controls !== null) {
				controls?.forEach((x) =>
					(this.form.controls[controlArrayName] as UntypedFormArray).push(
						this.fb.group({
							name: x.value.name,
							shareholding: x.value.shareholding,
							positionInBusiness: x.value.positionInBusiness,
							keyToBusiness: x.value.keyToBusiness,
						})
					)
				);
			}
		}

		if (controlArrayName === 'directorsList') {
			if (controls !== null) {
				controls?.forEach((x) =>
					(this.form.controls[controlArrayName] as UntypedFormArray).push(
						this.fb.group({
							name: x.value.name,
						})
					)
				);
			}
		}

		if (controlArrayName === 'otherKeyPeopleList') {
			if (controls !== null) {
				controls?.forEach((x) =>
					(this.form.controls[controlArrayName] as UntypedFormArray).push(
						this.fb.group({
							name: x.value.name,
							positionInBusiness: x.value.positionInBusiness,
						})
					)
				);
			}
		}
	}

	buildForm() {
		this.form = this.fb.group({
			businessName: ['', [Validators.required, NoWhitespaceValidator]],
			tradingName: ['', [Validators.required, NoWhitespaceValidator]],
			purpose: ['', Validators.required],
			industry: [''],
			noOfEmployees: [''],

			shareholdersList: this.fb.array([
				this.fb.group({
					name: [''],
					shareholding: [''],
					positionInBusiness: [''],
					keyToBusiness: [''],
				}),
			]),

			directorsList: this.fb.array([
				this.fb.group({
					name: [''],
				}),
			]),

			otherKeyPeopleList: this.fb.array([
				this.fb.group({
					name: [''],
					positionInBusiness: [''],
				}),
			]),

			hasAccountant: [''],
			accountantName: [''],
			businessAccountName: [''],
			hasShareholdersAgreement: [''],
			hasBuySellAgreement: [''],
			businessValued: [''],
			businessValue: [''],
			shareholderUnableToWork: [''],
			shareholderPassesAway: [''],
			primaryContact: [''],
		});
	}

	addShareholderItem() {
		this.shareholdersList.push(
			this.fb.group({
				name: [''],
				shareholding: [''],
				positionInBusiness: [''],
				keyToBusiness: [''],
			})
		);
	}

	addDirectorItem() {
		this.directorsList.push(
			this.fb.group({
				name: [''],
			})
		);
	}

	addPersonItem() {
		this.otherKeyPeopleList.push(
			this.fb.group({
				name: [''],
				positionInBusiness: [''],
			})
		);
	}

	removeShareholder(i: number) {
		this.shareholdersList.removeAt(i);
	}
	removeDirector(i: number) {
		this.directorsList.removeAt(i);
	}
	removePerson(i: number) {
		this.otherKeyPeopleList.removeAt(i);
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => this.cancelLoading, 500);
	}

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (!this.form.valid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		const accountantList = [
			{
				accountantName: this.form.value.accountantName,
				businessName: this.form.value.businessAccountName,
			},
		];

		const data = CompanyMapper.mapToUpsert(
			this.form.value,
			this.companyInfo,
			accountantList
		);
		this.saveEvent.emit(data);
	}
}
