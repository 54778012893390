import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DeclarationEmailSettingsState } from 'src/app/modules/crt-settings/declaration-settings/declaration-email-settings/state/declaration-email-settings.model';
import { ClientFactFindSettingState } from 'src/app/modules/mortgage-settings/declaration-settings/client-fact-find-template-settings/state/client-fact-find-template-settings.model';
import { DeclarationSettingState } from '../../../../../mortgage-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.model';
import { DeclarationState } from './declaration.model';

export interface MoatDeclarationState {
  settings: DeclarationSettingState,
  declaration: DeclarationState,
  formValue: DeclarationState,
  
  declarationEmailSettings: DeclarationEmailSettingsState;
  clientFactFindTemplateSettings: ClientFactFindSettingState;
}

export function createInitialState(): MoatDeclarationState {
  return {
    settings: null,
    declaration: null,
    formValue: null,
    declarationEmailSettings: null,
    clientFactFindTemplateSettings: null,
  }
}

@Injectable()
@StoreConfig({ name: 'declaration', idKey: 'cRTId' })
export class DeclarationStore extends Store<MoatDeclarationState> {
	constructor() {
		super(createInitialState());
	}

  setDeclaration(p: DeclarationState) {
    this.update({ declaration: p });
  }

  setDeclarationSettings(p: DeclarationSettingState) {
    this.update({ settings: p });
  }

  setDeclarationFromValue(p: DeclarationState) {
		this.update({ formValue: p });
	}

  setDeclarationEmailSettings(p: DeclarationEmailSettingsState) {
    this.update({ declarationEmailSettings: p });
  }

  setClientFactFindSettings(p: ClientFactFindSettingState) {
    this.update({ clientFactFindTemplateSettings: p});
  }
}
