<form [formGroup]="form" [class.submitted]="submitted" novalidate>
	<div class="form-row mb-2 others-label-background">
		<div class="col-7 col-sm-3 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary">
				Other Assets/Investments
			</label>
		</div>
		<div class="col-5 col-sm-9 text-right iconShow">
			<button
				id="otherAssets_collapseButton"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 others-collapse-background"
			>
				<span *ngIf="elseMinusOthers; else elsePlusOthers">
					<i (click)="collapseMoreOthers()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusOthers>
					<span>
						<i (click)="collapseLessOthers()" class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div class="collapse expandable" id="collapseOthers">
		<div
			class="form-row d-none d-lg-flex mb-2"
			[class.tap-opacity-25]="OtherArray?.controls?.length === 0"
		>
			<div class="col-12 col-lg-3 font-weight-bold">Assets</div>
			<div class="col-12 col-lg-3 font-weight-bold">Owner</div>
			<div class="col-12 col-lg-3 font-weight-bold">Description</div>
			<div class="col-12 col-lg-3 font-weight-bold">Value</div>
		</div>
		<div formArrayName="otherArray">
			<div
				class="form-row align-items-center"
				*ngFor="let item of OtherArray.controls; let i = index; let last = last"
				[formGroupName]="i"
			>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<div class="d-md-none col-lg-1 text-right">
						<button
							id="otherAssets_enableFormItemButton_{{ i }}"
							*ngIf="!item.get('isEdit')?.value"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							[disabled]="(isEdit || isAdd) && !item.get('isEdit')?.value"
							(click)="editItem(i)"
						>
							<i class="material-icons md-16"> edit </i>
						</button>
						<button
							id="otherAssets_deleteModalButton_{{ i }}"
							*ngIf="!item.get('isEdit')?.value"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							[disabled]="(isEdit || isAdd) && !item.get('isEdit')?.value"
							(click)="deleteModal(i)"
						>
							<i class="material-icons md-16"> delete </i>
						</button>
						<ng-container *ngIf="!!item.get('cRTId')?.value">
							<button
								id="otherAssets_saveOtherButton_{{ i }}"
								color="primary"
								*ngIf="item.get('isEdit')?.value"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="saveOther(i, false)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
							<button
								id="otherAssets_cancelEditOtherButton_{{ i }}"
								*ngIf="item.get('isEdit')?.value"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="cancelEditOther(i)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>
						<ng-container *ngIf="!item.get('cRTId')?.value">
							<button
								id="otherAssets_saveOtherButton_x{{ i }}"
								color="primary"
								*ngIf="item.get('isEdit')?.value"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="saveOther(i, true)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
							<button
								id="otherAssets_cancelOtherButton_x{{ i }}"
								*ngIf="item.get('isEdit')?.value"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="cancelOther(i)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>
					</div>
				</ng-container>
				<div
					class="col-lg-3 mb-2"
					[class.border-bottom]="item.get('asset').disabled && !last"
				>
					<span
						class="w-100"
						[class.crt-custom-dropdown]="item.get('asset').enabled"
					>
						<select
							formControlName="asset"
							id="otherAsset"
							class="form-control crt-form-control theme-crm-field-disabled"
							[class.select-text-muted]="!item?.get('asset')?.value"
						>
							<option
								value=""
								selected
								disabled
								hidden
								class="select-text-muted"
							>
								Select Asset
							</option>
							<option
								*ngFor="let d of apcrta$ | async"
								[value]="d.value"
								class="select-text-option"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<div
					class="col-lg-3 mb-2 owner-field"
					[class.border-bottom]="item.get('owner')?.disabled && !last"
				>
					<app-chips
						id="otherAssets_owner"
						textboxId="otherAssets_owner"
						[attr.disabled]="item.get('owner')?.disabled"
						class="crt-form-control-field"
						[formControl]="item.get('owner')"
						textboxClass="theme-crm-field"
						[choices]="policyOwnerChoices(item.get('owner').value)"
					></app-chips>
				</div>
				<div
					class="col-lg-3 mb-2"
					[class.border-bottom]="item.get('description')?.disabled && !last"
				>
					<input
						formControlName="description"
						type="text"
						id="otherDescription"
						class="form-control crt-form-control"
						placeholder="Description"
					/>
				</div>
				<div
					class="col-lg-2 mb-2 value-field"
					[class.border-bottom]="item.get('value')?.disabled && !last"
				>
					<div class="dollar-icon">
						<input
							formControlName="value"
							type="text"
							id="otherValue"
							class="form-control crt-form-control"
							placeholder="Value"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<div
						class="d-none d-md-block col-lg-1 text-right"
						[class.border-bottom]="!item.get('isEdit')?.value && !last"
					>
						<ng-container *ngIf="!item.get('isLoading').value"
							><button
								id="otherAssets_enableFormItemButton_{{ i }}"
								*ngIf="!item.get('isEdit')?.value"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="(isEdit || isAdd) && !item.get('isEdit')?.value"
								(click)="editItem(i)"
							>
								<i class="material-icons md-16">edit</i>
							</button>
							<button
								id="otherAssets_deleteModalButton_{{ i }}"
								*ngIf="!item.get('isEdit')?.value"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="(isEdit || isAdd) && !item.get('isEdit')?.value"
								(click)="deleteModal(i)"
							>
								<i class="material-icons md-16">delete</i>
							</button>
							<ng-container *ngIf="!!item.get('cRTId')?.value">
								<button
									id="otherAssets_saveOtherButton_{{ i }}"
									color="primary"
									*ngIf="item.get('isEdit')?.value"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="saveOther(i, false)"
								>
									<i class="material-icons md-16">save</i>
								</button>
								<button
									id="otherAssets_cancelEditOtherButton_{{ i }}"
									*ngIf="item.get('isEdit')?.value"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="cancelEditOther(i)"
								>
									<i class="material-icons md-16">close</i>
								</button>
							</ng-container>
							<ng-container *ngIf="!item.get('cRTId')?.value">
								<button
									id="otherAssets_saveOtherButton_{{ i }}"
									color="primary"
									*ngIf="item.get('isEdit')?.value"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="saveOther(i, true)"
									[disabled]="item.invalid"
								>
									<i class="material-icons md-16">save</i>
								</button>
								<button
									id="otherAssets_cancelOtherButton_{{ i }}"
									*ngIf="item.get('isEdit')?.value"
									type="button"
									class="icon-btn w-auto h-auto px-1"
									(click)="cancelOther(i)"
								>
									<i class="material-icons md-16">close</i>
								</button>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="!!item.get('isLoading').value">
							<app-manual-loader-mini
								[isLoading]="!!item.get('isLoading').value"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>

		<div class="form-row my-4">
			<div class="col-5 col-lg-3">
				<ng-container *ngIf="isLoading$ | async as loading">
					<app-manual-loader-mini
						[isLoading]="loading"
					></app-manual-loader-mini>
				</ng-container>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="otherAssets_addOtherButton"
						*ngIf="!(isLoading$ | async)"
						type="button"
						(click)="addOther()"
						class="tap-btn tap-btn-outline tap-btn--shadow border-0 px-4 col-auto"
						[disabled]="isAdd || isEdit"
					>
						Add Asset +
					</button>
				</ng-container>
			</div>
			<div class="offset-lg-4 col-lg-4">
				<div class="row mt-4 mt-lg-2 font-weight-bold">
					<div class="col-auto col-lg-6 text-left text-lg-right pr-1">
						<span class="font-weight-bold tap-text-primary"
							>Total Assets Value:</span
						>
					</div>
					<div class="col-auto col-lg-3 pl-1">
						<span class="">{{
							totalAssets$ | async | currency: "USD":"$":"1.2-2"
						}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row line mb-3"></div>
	</div>
</form>
