import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '../../../core/base/api.service';
import { UserSettingsQuery } from '../state/user-settings/user-settings.query';
import {
  DisclosureDocumentState,
  DocumentUploadState,
  defaultDD,
} from '../../../shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { DocumentModelState } from '../../../shared/models/documents/document.model';
import { objectUtil } from '../../../util/util';
import { UserSettingsStore } from '../state/user-settings/user-settings.store';
import * as R from 'ramda';
import { SettingsTypes } from '../../crt-settings/state/crt-settings.model';

@Injectable()
export class DisclosureSettingsService {
  disclosureSettings$ = this.query.disclosureSettings$;

  constructor(
    private api: ApiService,
    private store: UserSettingsStore,
    protected query: UserSettingsQuery
  ) {}

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  getDisclosureDocumentByStaffId(staffId?: number) {
    const settingsCode = SettingsTypes.Disclosure;
    const endpoint = `staff/${staffId}/settings/${settingsCode}`;
    return this.api.get<DisclosureDocumentState>(endpoint).pipe(
      tap((data) => {
        return applyTransaction(() => {
          const state = R.complement(R.either(R.isNil, R.isEmpty))(data)
            ? objectUtil.mapPascalCaseToCamelCase(data)
            : {};
          this.store.setDisclosureDocument(state);
        })
      }),
      catchError(() => of({}))
    );
  }

  updateDisclosureDocument(id: number, data: DisclosureDocumentState) {
    const newValue = {
      ...defaultDD,
      ...this.query.getValue().disclosureSettings,
      ...data,
      ...{
        referenceId: id
      }
    };
    const settingsId = data?.settingsId;
    const endpoint = `staff/settings/${settingsId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<DisclosureDocumentState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setDisclosureDocument(newValue);
        })
      )
    );
  }

  addDisclosureDocument(id: number, data: DisclosureDocumentState) {
    const newValue = {
      ...defaultDD,
      ...this.query.getValue().disclosureSettings,
      ...data,
    };
    const endpoint = `staff/settings`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.post3<DisclosureDocumentState>(endpoint, body).pipe(
      tap((res) =>
        applyTransaction(() => {
          this.store.setDisclosureDocument({
            ...newValue,
            settingsId: +res,
          });
        })
      )
    );
  }

  newFileUploadDD(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUploadDD(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getDisclosureDocumentFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
