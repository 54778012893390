import { Component, OnInit } from '@angular/core';
import { LoggerService } from '@core/logger/logger.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { logMessage } from '@shared/error-message/error-message';
import { fileUtil } from '@util/util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Subject, of, iif } from 'rxjs';
import { concatMap, filter, map, take, tap } from 'rxjs/operators';

@Component({
	selector: 'app-link-document-single-tab',
	templateUrl: './link-document-single-tab.component.html',
	styleUrls: ['./link-document-single-tab.component.scss'],
})
export class LinkDocumentSingleTabComponent implements OnInit {
	public getSelectedDocumentValue$: Subject<string>;
	title: string = 'Link Document';
	data: any[];
	message: string;
	selectedDetail: string;
	maxFileSize: number; // File Size in KB
	page: number;
	filteredList: any[];
	listPerPage: any[];
	documents: any[];
	currentFileSize: number;

	itemPerPage = 10;
	currentpage = 1;
	loading = false;

	constructor(
		public bsModalRef: BsModalRef,
		public businessConfigQuery: BusinessConfigQuery,
		public crtDocumentService: CrtDocumentService,
		public loggerService: LoggerService
	) {}

	get getDocuments() {
		return !!this.documents ? this.documents : null;
	}

	ngOnInit(): void {
		this.getSelectedDocumentValue$ = new Subject();
		this.listPerPage =
			this.documents?.length > 0
				? this.documents.slice(0, this.itemPerPage)
				: null;
	}

	// side pagination
	pageChanged(event: any): void {
		this.page = event.page;
	}

	setPage(event: PageChangedEvent): void {
		this.currentpage = event.page;
		const startItem = (event.page - 1) * event.itemsPerPage;
		const endItem = event.page * event.itemsPerPage;
		this.listPerPage = this.getDocuments?.slice(startItem, endItem);
	}

	close() {
		this.bsModalRef.hide();
	}

	selectLinkDocument(dataselected: any) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		of(dataselected)
			.pipe(
				map((file) => {
					if (!file?.fileName?.includes('.')) {
						return {
							...file,
							fileName: `${file.fileName}${file.fileExtension}`,
						};
					}
					return file;
				}),
				concatMap((file) =>
					iif(
						() => !!this.maxFileSize && !!file?.documentLink,
						of(file).pipe(
							concatMap((x) =>
								this.crtDocumentService.getDocumentLink(x?.documentLink, {
									responseType: 'blob',
								})
							),
							map((x) => {
								const size = +fileUtil.formatBytes(x?.size, 'KB');

								if (size > +this.maxFileSize) {
									this.loggerService.Warning(
										{},
										logMessage.shared.fileLinkSize.single.error
									);

									setTimeout(() => (this.loading = false), 500);
									return null;
								}

								const totalSize = +size + (this.currentFileSize || 0);
								if (this.currentFileSize && totalSize > +this.maxFileSize) {
									this.loggerService.Warning(
										{},
										logMessage.shared.fileLinkSize.total.error
									);
									setTimeout(() => (this.loading = false), 500);
									return null;
								}
								return file;
							}),
							take(1)
						),
						of(file)
					)
				),
				filter((x) => !!x),
				tap(() => {
					setTimeout(() => (this.loading = false), 500);
					this.getSelectedDocumentValue$.next(dataselected);
					this.close();
				}),
				take(1)
			)
			.subscribe();
	}
}
