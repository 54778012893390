import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlBusinessViewComponent } from './bl-business-view/bl-business-view.component';
import { BlBusinessComponentService } from './bl-business-component.service';

const routes: Routes = [
  {
    path: '',
    component: BlBusinessViewComponent,
    resolve: { business: BlBusinessComponentService },
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlBusinessRoutingModule { }
