import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { omit } from 'ramda';
import { concat, EMPTY, from, iif, Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mapTo,
	mergeMap,
	reduce,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ClientAcceptanceSettingsState } from 'src/app/modules/crt-settings/client-acceptance-settings/state/client-acceptance-settings.model';
import {
	AdviceProcessSectionCodes,
	ATPSubSectionCodes,
	SOASubSectionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { ClientAcceptanceMapper } from 'src/app/shared/models/client-review-template/client-acceptance/client-acceptance.mapper';
import {
	ClientAcceptance,
	ClientAcceptanceState,
} from 'src/app/shared/models/client-review-template/client-acceptance/client-acceptance.model';
import {
	CurrentInsurance,
	CurrentInsuranceState,
} from 'src/app/shared/models/client-review-template/current-insurance/current-insurance.model';
import { DocumentModel } from 'src/app/shared/models/client-review-template/document/document.model';
import { DocumentTypes } from 'src/app/shared/models/documents/document.model';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { convertUtil, objectUtil } from 'src/app/util/util';
import { ToPdfService } from 'src/app/shared/services/to-pdf/to-pdf.service';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';

@Injectable()
export class ClientAcceptanceService extends ClientReviewTemplateService {
	clientAcceptance$ = this.query.select((x) => x.clientAcceptance);
	formValue$ = this.query.select((x) => x.clientAcceptanceFormValue);
	alternativeStructure$ = this.query.select((x) => x.caAlternativeStructure);
	signature$ = this.query.select((x) => x.caSignature);

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
    private toPdfService: ToPdfService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getClientAcceptance(
		adviceProcessId: number
	): Observable<ClientAcceptanceState> {
		this.store.setIsLoadingClientAcceptance(true);
		return this.api.get<ClientAcceptance[]>(`crt/${adviceProcessId}/CA`).pipe(
			map((x) =>
				!!x && x?.length > 0 && !!x[0]
					? (objectUtil.mapPascalCaseToCamelCase(x[0]) as ClientAcceptanceState)
					: null
			),
			tap((x) =>
				applyTransaction(() => this.store.update({ clientAcceptance: x }))
			),
			switchMap((x) =>
				iif(
					() => !!x && !x.presentedSOA,
					this.getAlternativeStructure(x.adviceProcessId),
					of(x)
				).pipe(mapTo(x))
			),
			finalize(() => this.store.setIsLoadingClientAcceptance(false)),
			catchError(() => of(undefined))
		);
	}

	getCASettings() {
		return this.api
			.get<ClientAcceptanceSettingsState>(`crt/settings/0/CA`)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const ca = objectUtil.mapPascalCaseToCamelCase(x);
						this.store.update({ clientAcceptanceSettings: ca });
					})
				),
				catchError(() => EMPTY)
			);
	}

	getAlternativeStructure(adviceProcessId: number) {
		return this.api
			.get<CurrentInsurance[]>(
				`crt/${adviceProcessId}/${ATPSubSectionCodes.ProposedInsurance}`
			)
			.pipe(
				map(
					(x) =>
						x?.map(
							objectUtil.mapPascalCaseToCamelCase
						) as CurrentInsuranceState[]
				),
				tap((x) =>
					applyTransaction(() =>
						this.store.update({ caAlternativeStructure: x })
					)
				),
				withLatestFrom(this.clientAcceptance$),
				mergeMap(([caAltS, ca]) =>
					iif(
						() => !!ca && !ca.presentedSOA && !!caAltS && caAltS.length === 0,
						this.saveSOAPIToCAPI(ca),
						of(caAltS)
					)
				),
				catchError(() => of(undefined))
			);
	}

	saveSOAPIToCAPI(ca: ClientAcceptanceState) {
		let soapi: CurrentInsuranceState[];
		return this.api
			.get<CurrentInsurance[]>(
				`crt/soa/${ca.sOA}/${SOASubSectionCodes.ProposedInsurance}`
			)
			.pipe(
				map((x) => {
					soapi = x.map(
						objectUtil.mapPascalCaseToCamelCase
					) as CurrentInsuranceState[];
					return soapi;
				}),
				mergeMap((x) =>
					iif(
						() => !!x && x.length > 0,
						from(x).pipe(
							map((pi) => ({
								...pi,
								cRTId: 0,
								parentCRTId: ca.cRTId,
								sectionCode: ATPSubSectionCodes.ProposedInsurance,
							})),
							concatMap((pi) =>
								this.addCurrentInsurance(
									pi,
									this.query.getValue().adviceProcessId
								)
							)
						),
						of(x)
					)
				),
				catchError(() => EMPTY)
			);
	}

	// Document type: CRTCAS
	getSignature(documentId: number): Observable<DocumentModel> {
		return this.api
			.get<DocumentModel>(`documents/${documentId}`)
			.pipe(catchError(() => of(undefined)));
	}

	saveSignature(imgUrl: string) {
		return of(imgUrl).pipe(
			withLatestFrom(this.query.caSignature$, this.query.clientAcceptance$),
			mergeMap(([x, s, c]) =>
				iif(
					() => !!s,
					this.api
						.put(`documents/${s?.DocumentID}/document-link`, {
							ReferenceId: c?.cRTId,
							Document: x,
							FileName: 'CASignature.png',
							Type: 'CRTCAS',
						})
						.pipe(mapTo(s?.DocumentID)),
					this.api
						.post(`documents`, {
							ReferenceId: c?.cRTId,
							Document: x,
							FileName: 'CASignature.png',
							Type: 'CRTCAS',
						})
						.pipe(
							switchMap((d) =>
								this.updateClientAcceptance({
									...c,
									signatureDocument: d,
								}).pipe(mapTo(d))
							)
						)
				)
			),
			switchMap((docId) => this.getSignature(+docId)),
			catchError(() => EMPTY)
		);
	}

	addCurrentInsurance(
		currentInsurance: CurrentInsuranceState,
		adviceProcessId?: number
	) {
		const newCurrentInsurance = Object.assign(
			{},
			omit(
				['policyDocumentFormData', 'policyDocumentsName'],
				currentInsurance
			)
		);
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(newCurrentInsurance);
		//body.PolicyDocuments = '';
		body.AdviceProcessId = adviceProcessId;

		return this.api.post3<number>(endpoint, body).pipe(
			tap((id) =>
				applyTransaction(() => {
					const ci = this.query.getValue().caAlternativeStructure
						? this.query.getValue().caAlternativeStructure
						: [];

					const data = [
						...ci,
						{
							...currentInsurance,
							cRTId: id,
						},
					]?.filter((x) => x.cRTId && x.cRTId !== 0);
					this.store.update({ caAlternativeStructure: data });
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateCurrentInsurance(currentInsurance: CurrentInsuranceState) {
		const newCurrentInsurance = Object.assign(
			{},
			omit(
				['policyDocumentFormData', 'policyDocumentsName'],
				currentInsurance
			)
		);
		const endpoint = `crt/${currentInsurance.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(newCurrentInsurance);
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<string>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					const data = this.query.getValue().caAlternativeStructure?.map((y) =>
						y.cRTId === currentInsurance.cRTId
							? {
									...currentInsurance,
									policyDocuments: typeof currentInsurance?.policyDocuments === 'string' ? JSON.parse(currentInsurance?.policyDocuments) : currentInsurance?.policyDocuments
							  }
							: y
					);
					this.store.update({ caAlternativeStructure: data });
				})
			),
			map(() => currentInsurance.cRTId),
			catchError(() => EMPTY)
		);
	}

	uploadDocument(currentInsurance: CurrentInsuranceState, crtId?: number, clientId?: number) {
		const uploadDocs: any[] = [];
		for (const data of currentInsurance.policyDocumentFormData.entries()) {
			uploadDocs.push(data[1])
		}

		const first$ = of({
			ReferenceId: crtId,
			Document: '',
			FileName: uploadDocs ? convertUtil.imageFilenameToPDF(uploadDocs[0]) : '',
			Type: DocumentTypes.Upload,
		});

		const documentIds = [];

		return first$.pipe(
			switchMap(() =>
				concat(
					first$,
					from(uploadDocs).pipe(
						mergeMap(
							(x: File) =>
                iif(() => x.type.startsWith('image/'),
                    this.toPdfService.fromImage(x),
                    this.convertToBase64(x)),
							(file: File, content: any) => [file, content]
						),
						map(([o, i]) => {
							return {
								ReferenceId: crtId,
                Document: i.content,
								FileName: i.filename,
								Type: DocumentTypes.Upload,
                DocumentType: ServicesCodes.LR,
                CustomerId: clientId
							};
						}),
						concatMap((req2) => this.api.post('documents', req2))
					)
				)
			),
			reduce((_acc, v) => documentIds?.push(v)),
			map((ids) =>
				applyTransaction(() => {
					const data = [...this.query.getValue().caAlternativeStructure];
					data?.forEach((i) => {
						if (i.cRTId === crtId) {
							const docs = [];
							documentIds?.forEach((a, val) => {
								docs?.push({
									referenceId: a,
									value: convertUtil.imageFilenameToPDF(uploadDocs[val]),
								});
							});
							i.policyDocumentsName = currentInsurance.policyDocumentsName;
							const list =
								i.policyDocumentsList && i.policyDocumentsList.length > 0
									? [...i.policyDocumentsList, ...docs]
									: docs;
							i.policyDocuments = list;
							i.policyDocumentsList = list;
						}
					});
					this.store.update({ caAlternativeStructure: data });
					return ids;
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteCurrentInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.caAlternativeStructure?.filter((y) => y.cRTId !== id);
					this.store.update({ caAlternativeStructure: data });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	convertToBase64 = (file: File, reader = new FileReader()): Observable<{ content: string; filename: string; }> =>
		new Observable((obs) => {
			reader.onload = () => obs.next({
        content: (reader.result as string).split(',')[1],
        filename: file.name
      });
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(file);
		});

	addClientAcceptance(data) {
		let newClientAcceptance: ClientAcceptanceState;
		return of(data).pipe(
			withLatestFrom(this.query.clientAcceptance$),
			map(() => {
				newClientAcceptance = {
					adviceProcessId: this.query.getValue().adviceProcessId,
					sectionCode: AdviceProcessSectionCodes.AuthorityToProceed,
					sOA: data?.sOA ?? 0,
					presentedSOA: data?.presentedSOA ?? 0,
					alternativeStructure: data?.alternativeStructure ?? 0,
					fullName: data?.fullName ?? null,
					date: data?.date ?? null,
					signatureDocument: 0,
					document: {},
				};
				return newClientAcceptance;
			}),
			mergeMap((x) => {
				return this.api.post(
					`crt`,
					objectUtil.mapCamelCaseToPascalCase(
						ClientAcceptanceMapper.mapToUpsert(x)
					)
				);
			}),
			tap((x) =>
				applyTransaction(() => {
					newClientAcceptance = { ...newClientAcceptance, cRTId: +x };
					this.store.update({
						clientAcceptance: newClientAcceptance,
					});
				})
			),
			withLatestFrom(this.clientAcceptance$),
			mergeMap(([, x]) =>
				iif(
					() => !x.presentedSOA || x.presentedSOA === 0,
					this.getAlternativeStructure(x.adviceProcessId),
					of(newClientAcceptance)
				)
			),
			catchError(() => EMPTY)
		);
	}

	updateClientAcceptance(data) {
		let newClientAcceptance: ClientAcceptanceState;
		return of(data).pipe(
			withLatestFrom(this.query.clientAcceptance$),
			map(([x, c]) => {
				newClientAcceptance = {
					...c,
					sOA: x?.sOA,
					presentedSOA: x?.presentedSOA,
					alternativeStructure: x?.alternativeStructure,
					document: x.document?.referenceId > 0 ? x.document : c.document,
					signatures:
						!!x?.signatures && x?.signatures.length > 0
							? x?.signatures
							: c?.signatures,
				};
				return newClientAcceptance;
			}),
			mergeMap((x) =>
				this.api.put(
					`crt/${x?.cRTId}`,
					objectUtil.mapCamelCaseToPascalCase(
						ClientAcceptanceMapper.mapToUpsert(x)
					)
				)
			),
			tap(() => this.setClientAcceptance(newClientAcceptance)),
			withLatestFrom(this.clientAcceptance$),
			mergeMap(([, x]) =>
				iif(
					() => !x.presentedSOA || x.presentedSOA === 0,
					this.getAlternativeStructure(x.adviceProcessId),
					of(newClientAcceptance)
				)
			),
			catchError(() => EMPTY)
		);
	}

	setClientAcceptance(data) {
		applyTransaction(() => {
			this.store.update({ clientAcceptance: data });
		});
	}

	uploadClientAcceptance(data) {
		of(data).pipe(
			withLatestFrom(this.clientAcceptance$),
			mergeMap(([d, ca]) =>
				iif(
					() => !!ca && !!ca?.document?.referenceId,
					this.api.post(`documents`, d),
					this.api.put(`documents/${ca.document?.referenceId}`, d)
				)
			),
			catchError(() => EMPTY)
		);
	}

	downloadLink(referenceId: number) {
		return this.api.get<string>(`documents/download/${referenceId}`).pipe(catchError(() => EMPTY));
	}

	setFormValue(data, hasChange = true) {
		this.setHasFormChanges(hasChange);
		this.store.setClientAcceptanceFormValue(data);
	}
}
