import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { ContentInsuranceMapper } from '../../../../../../shared/models/client-review-template/fg-insurance/content-insurance.mapper';
import { ContentInsuranceState } from '../../../../../../shared/models/client-review-template/fg-insurance/content-insurance.model';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { NoWhitespaceValidator } from '../../../../../directive/no-whitespace/no-whitespace.directive';
import { Observable, Subject, takeUntil } from 'rxjs';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Component({
	selector: 'app-contents-form',
	templateUrl: './contents-form.html',
	styleUrls: ['./contents-form.scss'],
})
export class ContentsFormComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Output() cancelEvent = new EventEmitter<any>();
	@Output() saveEvent = new EventEmitter<any>();
	@Input() currentProvider$: Observable<ViewDisplayValue[]>;
	@Input() APCRTCLC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTSI$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() cRTId: number;
	@Input() cRTInfo: ContentInsuranceState;
	@Input() isAdd: boolean;
	@Input() viewMode: boolean;
	@Input() loading: boolean;
	@Input() loatv2Enabled: boolean;

	public bsModalRef: BsModalRef;

	form: UntypedFormGroup;
	showClaimInLast12Months = false;
	submitted = false;

	cancelLoading: boolean;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		if (!this.isAdd) {
			this.form.reset(ContentInsuranceMapper.mapToView(this.cRTInfo));
			if (this.cRTInfo.specificItemList.length > 0) {
				this.cRTInfo.specificItemList?.map((x, i) => this.addItem(x, i === 0));
			} else {
				this.addItem({}, true);
			}
			this.selectChange();
		} else {
			this.addItem({}, true);
		}
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);

    this.watchFormChanges();
	}

  watchFormChanges() {
		this.WhatHappenedOther.valueChanges.pipe(takeUntil(this.onDestroy$))
			.subscribe((value) => {
				if(!value && !this.WhatHappenedOther.pristine) {
					this.WhatHappened.setValue('');
				}
		});
    this.WhatHappened.valueChanges
      .pipe(takeUntil(this.onDestroy$ ))
      .subscribe((value) => {
      if (value === 'Other') {
        this.WhatHappenedOther.setValidators(Validators.required);
      } else {
        this.WhatHappenedOther.clearValidators();
				this.WhatHappenedOther.markAsPristine();
      }
      this.WhatHappenedOther.updateValueAndValidity();
    });
  }

	get Address() {
		return this.form.get('address') as UntypedFormArray;
	}
	get SumInsured() {
		return this.form.get('sumInsured');
	}
	get Provider() {
		return this.form.get('provider');
	}
	get Premium() {
		return this.form.get('premium');
	}
	get LastReviewed() {
		return this.form.get('lastReviewed');
	}
	get SpecificItemList() {
		return this.form.get('specificItemList') as UntypedFormArray;
	}
	get WhatHappened() {
		return this.form.get('whatHappened');
	}
	get WhatHappenedOther() {
		return this.form.get('whatHappenedOther');
	}
	get HasClaimedInLast12Months() {
		return this.form.get('hasClaimedInLast12Months');
	}

	addItem(data?: any, showAdd?: boolean) {
		this.SpecificItemList.push(
			this.fb.group({
				specifiedItem: [(data && data.specifiedItem) || ''],
				description: [(data && data.description) || ''],
				value: [(data && data.value) || ''],
				btnAdd: showAdd || false,
				btnDelete: true,
			})
		);

		this.SpecificItemList.controls[0].get('btnDelete').setValue(true);
	}

	deleteItem(index: number) {
		this.SpecificItemList?.removeAt(index);

		this.SpecificItemList.controls[0].get('btnAdd').setValue(true);
		this.SpecificItemList.controls[0].get('btnDelete').setValue(true);

		if (this.SpecificItemList.controls.length === 1) {
			this.SpecificItemList.controls[0].get('btnAdd').setValue(true);
			this.SpecificItemList.controls[0].get('btnDelete').setValue(false);
		}
	}

	buildForm() {
		this.form = this.fb.group({
			address: ['', [Validators.required, NoWhitespaceValidator]],
			sumInsured: [''],
			provider: ['', Validators.required],
			premium: [''],
			lastReviewed: [''],
			currentExcess: [''],
			hasClaimedInLast12Months: [''],
			driverName: [''],
			whatHappened: [''],
      whatHappenedOther: [''],
			date: [''],
			cost: [''],
			houseAlarmed: [''],
			specificItemList: this.fb.array([]),
		});
	}

	selectChange() {
		this.showClaimInLast12Months = this.HasClaimedInLast12Months.value === 'Yes' ? true : false;
	}

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		const adviceProcessId = parseInt(
			this.route.snapshot.paramMap.get('adviceProcessId'),
			10
		);
		const data = ContentInsuranceMapper.mapToUpsert(
			this.form.value,
			+this.cRTId,
			+adviceProcessId
		);
		this.saveEvent.emit(data);
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
