import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { MortgageProviderCommissionState } from '../../../provider-comission/state/provider-commission.model';
import { MortgageProviderCommissionService } from '../../../provider-comission/state/provider-commission.service';
import { PurposeService } from '../purpose/state/purpose.service';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import { SidebarStatus } from '../../../../_shared/models/sidebar.model';
import {
	AdviceProcessPageCodes,
	MortgageAdviceProcessPageIds,
} from '../../../../../../../shared/models/advice-process/advice-process.model';
import { CrtMortgageService } from '../../../state/crt-mortgage.service';
import { combineLatest } from 'rxjs';
import { PropertySoldQuery } from '../funding-required/selling-section/property-sold/state/property-sold.query';
import { PropertyPurchaseService } from '../funding-required/property-purchase/state/property-purchase.service';

import { sort } from 'ramda';

@Injectable()
export class ApplicationStepsService {
	applicationBanks$ = this.providerCommission.settings$.pipe(
		map((pcm: MortgageProviderCommissionState[]) =>
			pcm?.filter((pc) => pc?.isActive === 1 && !!pc?.isTicked)
		),
		map((pcm) =>
			sort(
				(a, b) => a?.providerCommissionOrder - b?.providerCommissionOrder,
				pcm || []
			)
		),
		map((pcm) =>
			pcm?.map((item) => {
				return {
					display: item.provider,
					value: item.provider,
				} as ViewDisplayValue;
			})
		)
	);
	primarybanks$ = this.ddQuery.orderedChoices$('MP');

	constructor(
		private ddQuery: DropdownValueQuery,
		private providerCommission: MortgageProviderCommissionService,
		private query: CrtMortgageQuery,
		private service: CrtMortgageService,
		private purposeService: PurposeService,
		private propertySoldQuery: PropertySoldQuery,
		private propertyPurchaseService: PropertyPurchaseService
	) {}

	setTabColor() {
		return combineLatest([
			this.query.adviceProcess$,
			this.purposeService.purposes$,
			this.propertySoldQuery.properties$,
			this.propertyPurchaseService.propertyPurchases$,
		]).pipe(
			tap(([ap, purposes, propertySold, propertyPurchases]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (ap?.pageStarted?.includes(AdviceProcessPageCodes.Application)) {
					status = SidebarStatus.Incomplete;
				}

				if (!!purposes && purposes?.length === 0) {
					warning = 'At least one Application Purpose is required';
				}

				if (
					!!purposes &&
					purposes?.length > 0 &&
					purposes.some((x) => !x?.bank || x?.bank === '')
				) {
					warning = 'Application Bank is a required field';
				}

				if (!!propertySold && propertySold?.length === 0) {
					warning = 'At least one property to be sold needs to be selected';
				}

				if (!!propertyPurchases && propertyPurchases?.length === 0) {
					warning = 'At least one property to be purchased needs to be entered';
				}

				this.service.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.Application,
					false,
					status,
					warning
				);
			})
		);
	}
}
