import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import {
	DocumentModelState,
	DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';
import { ApiService } from '../../../core/base/api.service';
import { objectUtil } from '../../../util/util';
import { UserSettingsQuery } from '../state/user-settings/user-settings.query';
import { EmailSettingsModel } from '../../emails/email-settings/state/email-settings.model';
import { DocumentModel } from '../comps/view-user/documents/documents.component';
import {
	EmailAccessPerAdviceProcess,
	UserSettingsStore,
} from '../state/user-settings/user-settings.store';

export interface CPIESettings extends EmailSettingsModel {
	settingsId: number;
	document?: DocumentModel;
	documentId: number;
	referenceId: number;
	body: string | number;
	bodyContent?: string;
}

@Injectable()
export class CustomerPortalService {
	sectionCode = 'CPIES';

	constructor(
		private api: ApiService,
		private store: UserSettingsStore,
		protected query: UserSettingsQuery
	) {}

	getInvitationEmail(staffId: number): Observable<CPIESettings> {
		return of(staffId).pipe(
			filter((x) => !!x),
			mergeMap((x) =>
				this.api.get<CPIESettings[]>(
					`staff/${staffId}/settings/${this.sectionCode}`
				)
			),
			// map((x) => (!!x && x.length > 0 ? x[0] : null)),
			map((x) => (!!x ? objectUtil.mapPascalCaseToCamelCase(x) : null))
		);
	}

	saveInvitationEmail(req) {
		return of(req).pipe(
			mergeMap((x) => {
				// UPDATE
				if (!!x?.SettingsId) {
					return this.api.put(`staff/settings/${x?.SettingsId}`, req);
				}
				// POST
				return this.api.post('staff/settings', req);
			})
		);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getCustomerPortalAccess(staffId: number, cpStatus = [1, 5, 7, 8]) {
		const endpoint = `staff/${staffId}/1/true`;
		return this.api
			.post<EmailAccessPerAdviceProcess[]>(endpoint, { CPStatus: cpStatus })
			.pipe(
				map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
				map((x) =>
					x?.emailAccessPerAdviceProcess?.map(
						objectUtil.mapPascalCaseToCamelCase
					)
				),
				tap((x) => this.store.setEmailAccessPerAdviceProcess(x))
			);
	}
}
