import { DocumentGroupState } from '../../models/documents/document-group.model';

export const documentUtil = (document: DocumentGroupState, linkDocumentId: number) => {
	const docLR = document && document.lR ? document.lR?.find(x => x.id === linkDocumentId) : null;
	const docM = document && document.m ? document.m?.find(x => x.id === linkDocumentId) : null;
	const docFG = document && document.fG ? document.fG?.find(x => x.id === linkDocumentId) : null;
	const docK = document && document.k ? document.k?.find(x => x.id === linkDocumentId) : null;
	const docO = document && document.o ? document.o?.find(x => x.id === linkDocumentId) : null;
	const docOD = document && document.oD ? document.oD?.find(x => x.id === linkDocumentId) : null;
	const docAXD = document && document.aXD ? document.aXD?.find(x => x.id === linkDocumentId) : null;
	const docXD = document && document.xD ? document.xD?.find(x => x.id === linkDocumentId) : null;
	const docAP = document && document.aP ? document.aP?.find(x => x.id === linkDocumentId) : null;
	const docCP = document && document.cP ? document.cP?.find(x => x.id === linkDocumentId) : null;

	if (docLR) {
		return docLR;
	}
	if (docM) {
		return docM;
	}
	if (docFG) {
		return docFG;
	}
	if (docK) {
		return docK;
	}
	if (docO) {
		return docO;
	}
	if (docOD) {
		return docOD;
	}
	if (docAXD) {
		return docAXD;
	}
	if (docXD) {
		return docXD;
	}
	if (docAP) {
		return docAP;
	}
  if (docCP) {
    return docCP;
  }

	return '';
};

export const capitalizeFirstLetter = (s) => {
	if (typeof s !== 'string') { return ''; }
	return s.charAt(0)?.toUpperCase() + s?.slice(1);
};

export const smallFirstLetter = (s) => {
	if (typeof s !== 'string') { return ''; }
	return s.charAt(0)?.toLowerCase() + s?.slice(1);
};


