import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PrimaryClientState } from '@models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from '@models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from '@models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from '@models/client-profile/seondary-business/secondary-business.model';
import { Sidebar } from '../../_shared/models/sidebar.model';
import { MergeTagState } from '@models/client-review-template/merge-tags/merge-tags.model';
import { SecondaryProfessionalState } from '@models/client-profile/secondary-professional/secondary-professional.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import {
	AdviceProcessPageCodes,
	KiwiSaverAdviceProcessPageIds,
	KiwiSaverAdviceProcessPageNames,
	ServiceAdviceProcessState,
} from '@models/advice-process/advice-process.model';
import { PrimaryCustomerCompanyState } from '@shared/models/business-profile/business/business.model';
import { IntroductionState } from '@shared/models/client-review-template/intro/intro.model';
import { ScopeOfServiceState } from '../scope-of-services/state/scope-of-service.model';
import { DeclarationState } from '../declaration/state/declaration.model';
import { DeclarationSettingState } from '@modules/kiwisaver-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.model';
import { DeclarationEmailSettingsState } from '@modules/kiwisaver-settings/declaration-settings/declaration-email-settings/state/declaration-email-settings.model';
import { kiwisaverSidebars } from './crt-kiwisaver-sidebars';
import { FactFindSubpagesSettingsState } from '@modules/kiwisaver-settings/fact-find-settings/subpages/state/fact-find-subpages-settings.model';
import { AdviceSummaryEmailSettingsState } from '@modules/kiwisaver-settings/advice-summary-settings/advice-summary-email-settings/state/advice-summary-email-settings.model';

// Move this somewhere
export interface SosTemplateSettingsState {
	referenceId: number;
	type: string;
	template: number;
	templateLink?: string;
}

export interface CrtKiwiSaverState {
	primaryClient: PrimaryClientState | any;
	secondaryClients: SecondaryClientState[];
	secondaryTrusts: SecondaryTrustState[];
	secondaryCompanies: SecondaryBusinessState[];
	secondaryProfessionals: SecondaryProfessionalState[];
	linkedContacts: LinkedContactState[];
	sidebars: Sidebar[];
	adviceProcess: ServiceAdviceProcessState;
	adviceProcessId: number;
	clientId: number;
	isCompany: boolean;
	mergeTags: MergeTagState[];
	pageCompleted: any[];
	pageStarted: any[];
	intro: IntroductionState;
	hasFormChanges: boolean;
	scopeOfService: ScopeOfServiceState;
	sosDefault: ScopeOfServiceState;
	sosTemplateSettings: SosTemplateSettingsState;
	isTabLoading: boolean;
	declaration: DeclarationState;
	declarationFormValue: DeclarationState;
	declarationSettings: DeclarationSettingState;
	declarationEmailSettings: DeclarationEmailSettingsState;
  adviceSummaryEmailSettings: AdviceSummaryEmailSettingsState;
}

export function createInitialState(): CrtKiwiSaverState {
	return {
		primaryClient: null,
		secondaryClients: null,
		secondaryTrusts: null,
		secondaryCompanies: null,
		secondaryProfessionals: null,
		linkedContacts: null,
		sidebars: [
			{
				id: KiwiSaverAdviceProcessPageIds.Introduction,
				name: KiwiSaverAdviceProcessPageNames.Introduction,
				link: KiwiSaverAdviceProcessPageIds.Introduction,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.Introduction,
			},
			{
				id: KiwiSaverAdviceProcessPageIds.Disclosure,
				name: KiwiSaverAdviceProcessPageNames.Disclosure,
				link: KiwiSaverAdviceProcessPageIds.Disclosure,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.Disclosure,
			},
			{
				id: KiwiSaverAdviceProcessPageIds.ScopeOfService,
				name: KiwiSaverAdviceProcessPageNames.ScopeOfService,
				link: KiwiSaverAdviceProcessPageIds.ScopeOfService,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.SOS,
			},
			{
				id: KiwiSaverAdviceProcessPageIds.FactFind,
				name: KiwiSaverAdviceProcessPageNames.FactFind,
				link: KiwiSaverAdviceProcessPageIds.FactFind,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: 'APFF',
			},
			{
				id: KiwiSaverAdviceProcessPageIds.Declaration,
				name: KiwiSaverAdviceProcessPageNames.Declaration,
				link: KiwiSaverAdviceProcessPageIds.Declaration,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: AdviceProcessPageCodes.Declaration,
			},
			{
				id: KiwiSaverAdviceProcessPageIds.AdviceSummary,
				name: KiwiSaverAdviceProcessPageNames.AdviceSummary,
				link: KiwiSaverAdviceProcessPageIds.AdviceSummary,
				isSubMenu: false,
				subMenu: [],
				customClass: '',
				status: 0,
				warning: '',
				pageCode: 'APAS',
			},
		],
		adviceProcess: null,
		adviceProcessId: null,
		clientId: null,
		isCompany: null,
		mergeTags: null,
		pageCompleted: null,
		pageStarted: null,
		intro: null,
		hasFormChanges: false,
		scopeOfService: null,
		sosDefault: null,
		sosTemplateSettings: null,
		isTabLoading: false,
		declaration: null,
		declarationFormValue: null,
		declarationSettings: null,
		declarationEmailSettings: null,
		adviceSummaryEmailSettings: null
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'crt-kiwisaver' })
export class CrtKiwiSaverStore extends Store<CrtKiwiSaverState> {
	constructor() {
		super(createInitialState());
	}

	setAdviceProcess(p: ServiceAdviceProcessState) {
		this.update({ adviceProcess: p });
	}

	setAdviceProcessId(id: number) {
		this.update({ adviceProcessId: id });
	}

	setPrimaryClient(pci: PrimaryClientState | PrimaryCustomerCompanyState) {
		this.update({ primaryClient: pci });
	}

	setClientId(id: number) {
		this.update({ clientId: id });
	}

	setIsCompany(c: boolean) {
		this.update({ isCompany: c });
	}

	setSecondaryClients(c: SecondaryClientState[]) {
		this.update({ secondaryClients: c });
	}

	setSecondaryTrusts(c: SecondaryTrustState[]) {
		this.update({ secondaryTrusts: c });
	}

	setSecondaryCompanies(c: SecondaryBusinessState[]) {
		this.update({ secondaryCompanies: c });
	}

	setSecondaryProfessionals(c: SecondaryProfessionalState[]) {
		this.update({ secondaryProfessionals: c });
	}

	setLinkedContacts(c: LinkedContactState[]) {
		this.update({ linkedContacts: c });
	}

	setPageCompleted(p: any[]) {
		this.update({ pageCompleted: p });
	}

	setPageStarted(p: any[]) {
		this.update({ pageStarted: p });
	}

	setMergeTags(p: MergeTagState[]) {
		this.update({ mergeTags: p });
	}

	setIntro(p: IntroductionState) {
		this.update({ intro: p });
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.update({ hasFormChanges });
	}

	setScopeOfService(p: ScopeOfServiceState) {
		this.update({ scopeOfService: p });
	}

	setSosDefault(p: ScopeOfServiceState) {
		this.update({ sosDefault: p });
	}

	setSosTemplateSettings(p: SosTemplateSettingsState) {
		this.update({ sosTemplateSettings: p });
	}

	setTabLoading(p: boolean) {
		this.update({ isTabLoading: p });
	}

	setDeclaration(p: DeclarationState) {
    this.update({ declaration: p });
  }

	setDeclarationFormValue(p: DeclarationState) {
    this.update({ declarationFormValue: p });
  }

	setDeclarationSettings(p: DeclarationSettingState) {
    this.update({ declarationSettings: p });
  }

	setDeclarationEmailSettings(p: DeclarationEmailSettingsState) {
    this.update({ declarationEmailSettings: p });
  }

	setAdviceSummaryEmailSettings(p: AdviceSummaryEmailSettingsState ) {
    this.update({ adviceSummaryEmailSettings: p });
  }

	setFactFindSidebar(ff: FactFindSubpagesSettingsState) {
		const sidebars = kiwisaverSidebars.filter((nav) => {
			const animations = [
				AdviceProcessPageCodes.WhyKiwiSaver,
				AdviceProcessPageCodes.HowKiwiSaverWorks,
				AdviceProcessPageCodes.InvestmentStrategy,
				// AdviceProcessPageCodes.PassiveVsActive,
				// AdviceProcessPageCodes.RiskVsVolatility,
			];
			const navigation = nav.pageCode as AdviceProcessPageCodes;
			if (animations.includes(navigation)) {
				if (navigation === animations[0]) {
					return !!ff?.whyKiwiSaver;
				}
				if (navigation === animations[1]) {
					return !!ff?.howKiwiSaverWorks;
				}
				if (navigation === animations[2]) {
					return !!ff?.investmentStrategy;
				}
				// if (navigation === animations[2]) {
				// 	return !!ff?.passiveVsActive;
				// }
				// if (navigation === animations[3]) {
				// 	return !!ff?.riskVsVolatility;
				// }
			} else {
				return true;
			}
		});
		this.update({ sidebars });
	}
}
