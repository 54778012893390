import { PropertyAssetState } from '../../models/services/property-asset/property-asset-group.model';
import produce from 'immer';
import { mainGroupSorter } from './property-asset-sorter.util';
import { AssetState } from '../../models/services/assets/assets';

export const propertyAndAssetColorStatus = (status: string) => {
	const green = ['current', 'current asset'];
	const yellow = ['potential purchase'];
	const black = ['sold', 'sold asset'];

	const stat = status ? status?.toLowerCase() : '';

	if (green?.includes(stat)) {
		return 'green';
	}

	if (yellow?.includes(stat)) {
		return 'yellow';
	}

	if (black?.includes(stat)) {
		return 'dark';
	}

	return '';
};

export const propAndAssetServiceUtil = (
	state: PropertyAssetState | AssetState | any
): PropertyAssetState | any => {
	return produce(state, (draft) => {
		let total = 0;

		draft.customerServices?.forEach((pa) => {
			pa.color = propertyAndAssetColorStatus(pa.status);
			total =
				total + (pa?.gRM1Value ? +pa?.gRM1Value : +(pa?.value ? +pa.value : 0));
		});

		draft.totalValue = total;
		draft.customerServices?.sort(mainGroupSorter);
	});
};

export const assetServiceUtil = (state: AssetState): AssetState => {
	return produce(state, (draft) => {
		let total = 0;

		draft.customerServices?.forEach((a) => {
			total = total + +a.value;
		});

		draft.totalValue = total;
	});
};
