import { Injectable } from "@angular/core";
import { applyTransaction } from "@datorama/akita";
import { of } from 'rxjs';
import {
	catchError,
	finalize,
	map,
	tap
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { objectUtil } from 'src/app/util/util';
import { AdviceProcessSectionCodes } from '../../../../../../../../../../shared/models/advice-process/advice-process.model';
import { CrtMortgageQuery } from '../../../../../../state/crt-mortgage.query';
import { PropertySoldDetailsState } from './property-sold.model';
import { PropertySoldQuery } from './property-sold.query';
import { PropertySoldStore } from './property-sold.store';

@Injectable()
export class PropertySoldService {
	SU$ = this.ddQuery.orderedChoices$('SU');
	SV1$ = this.ddQuery.orderedChoices$('SV1');
	ST$ = this.ddQuery.orderedChoices$('ST');
	STI$ = this.ddQuery.orderedChoices$('STI');
	APCRTF$ = this.ddQuery.orderedChoices$('APCRTF');
	isLoading$ = this.query.selectLoading();

	// propertySold$ = this.query.selectAll();

  constructor(
    private api: ApiService,
    protected store: PropertySoldStore,
    protected query: PropertySoldQuery,
		private ddQuery: DropdownValueQuery,
		protected crtMortgageQuery: CrtMortgageQuery,
  ) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

  get(applicationId: number) {
		this.store.setLoading(true);
		return this.api.get<PropertySoldDetailsState[]>(`crt/${AdviceProcessSectionCodes.Application}/${applicationId}/sub-section/${AdviceProcessSectionCodes.PropertySold}`).pipe(
			map((x) => x.filter((y) => y)),
			map((x) => (x || []).map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => {
				this.store.set(x || []);
			}),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data) {
		this.store.setLoading(true);
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.PropertySold,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					const properties: PropertySoldDetailsState[] = [
						...this.query.getValue().properties,
						{
							adviceProcessId: data.AdviceProcessId,
							parentCRTId: data.ParentCRTId,
							sectionCode: data.SectionCode,
							propertyAddress: data.PropertyAddress,
							propertyUse: data.PropertyUse,
							propertyValue: data.PropertyValue,
							propertyOwner: data.PropertyOwner,
							total: data.Total,
							purchaseDate: data.PurchaseDate,
							valuationType: data.ValuationType,
							valuationTypeDate: data.ValuationTypeDate,
							rentalIncome: data.RentalIncome,
							rentalIncomeFrequency: data.RentalIncomeFrequency,
							title: data.Title,
							rates: data.Rates,
							ratesFrequency: data.RatesFrequency,
							type: data.Type,
							insurance: data.Insurance,
							insuranceFrequency: data.InsuranceFrequency,
							otherExpense: data.OtherExpense,
							otherExpenseFrequency: data.OtherExpenseFrequency,
							salesPrice: data.SalesPrice,
							cRTId: +x,
							status: data.Status
						}
					].filter(p => p.cRTId && p.cRTId !== 0)
					this.store.add(properties);
				})
			}),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	update(data) {
		this.store.setLoading(true);
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.PropertySold,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.upsert(body.CRTId, {
						adviceProcessId: data.AdviceProcessId,
						parentCRTId: data.ParentCRTId,
						sectionCode: data.SectionCode,
						propertyAddress: data.PropertyAddress,
						propertyUse: data.PropertyUse,
						propertyValue: data.PropertyValue,
						propertyOwner: data.PropertyOwner,
						total: data.Total,
						purchaseDate: data.PurchaseDate,
						valuationType: data.ValuationType,
						valuationTypeDate: data.ValuationTypeDate,
						rentalIncome: data.RentalIncome,
						rentalIncomeFrequency: data.RentalIncomeFrequency,
						title: data.Title,
						rates: data.Rates,
						ratesFrequency: data.RatesFrequency,
						type: data.Type,
						insurance: data.Insurance,
						insuranceFrequency: data.InsuranceFrequency,
						otherExpense: data.OtherExpense,
						otherExpenseFrequency: data.OtherExpenseFrequency,
						salesPrice: data.SalesPrice,
						cRTId: +body.CRTId
					});
				})
			}),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	delete(data: number) {
		return this.api.delete(`crt/${data.toString()}`).pipe(
			tap((x) => {
				this.store.remove(data);
			}),
			catchError(() => of(undefined))
		);
	}
}
