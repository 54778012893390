import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pdt-donut-widget',
  templateUrl: './pdt-donut-widget.component.html',
  styleUrls: ['./pdt-donut-widget.component.scss'],
})
export class PDTDonutWidgetComponent implements OnInit, OnChanges {

  @Input() part: number = 0;
  @Input() total: number = 0;
  @Input() label: string;
  @Input() suffix: string;

  strokeColor: 'success' | 'error';


  ngOnInit(): void {
    this.renderColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.part || changes.total) {
      this.renderColor();
    }
  }

  renderColor() {
    if (this.part >= this.total) {
      this.strokeColor = 'success';
    } else {
      this.strokeColor = 'error';
    }
  }

}
