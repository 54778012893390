import * as R from 'ramda';

export const soaInsuranceMetaKey = {
	proposedInsuranceStructure: 'SOA_PROPOSED_INSURANCE_TYPE1',
	proposedInsurancePremium: 'SOA_PROPOSED_INSURANCE_TYPE2',
	currentInsurancePremium: 'SOA_CURRENT_INSURANCE_TABLE',

	currentInsuranceNoCover: 'CI_NO_COVER'
};

export const soaMergeTags = [
	{
		metaKey: soaInsuranceMetaKey.proposedInsuranceStructure,
		description: 'SOA > Proposed Insurance Structure Table',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: soaInsuranceMetaKey.proposedInsurancePremium,
		description: 'SOA > Proposed Insurance Premium Table',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: soaInsuranceMetaKey.currentInsurancePremium,
		description: 'SOA > Current Insurance Table',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
	{
		metaKey: soaInsuranceMetaKey.currentInsuranceNoCover,
		description: 'SOA > No Current Insurance Cover',
		value: [''],
		type: 'T',
		secondaryValue: '',
		noWrap: true,
	},
];

export const insuranceMtList = () => {
	const soaMergeTagsList = R.pluck('metaKey', soaMergeTags);
	return soaMergeTagsList;
};
