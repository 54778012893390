import { LrInsuranceState } from '../../models/services/lr-insurance/lr-provider-group.model';
import produce from 'immer';
import { subGroupSorter, mainGroupSorter } from './lr-insurance-sorter.util';

export const lrColorStatus = (status: string) => {
  const green = ['inforce', 'inforce-pending'];
  const yellow = ['lead', 'first meeting', 'submitted', 'underwriting', 'notes/tests required', 'offer of terms', 'policy accepted',
    // Additional status for haven
    'incomplete app', 'underwriting-info req', 'inforce-pending',
    // Additional status for advice plus
    'prepare report', 'anaylsis', 'report presented'
  ];
  const red = ['cancelled',
    // Additional status for haven
    'claim paid'];
  const grey = ['ntu', 'open ntu', 'lead ntu',
    // Additional status for haven
    'delayed app'];

  const stat = status ? status?.toLowerCase() : '';

  if (green?.includes(stat)) {
    return 'green';
  }

  if (yellow?.includes(stat)) {
    return 'yellow';
  }

  if (red?.includes(stat)) {
    return 'red';
  }

  if (grey?.includes(stat)) {
    return 'grey';
  }

  return 'dark';
};

export const lrSortServiceUtil = (state: LrInsuranceState): LrInsuranceState => {
  return produce(state, draft => {
    let totalApi = 0;
    draft.lRs?.forEach(lr => {
      lr.aPI = 0;
      const services = lr.customerServices?.sort(subGroupSorter);

      for (let i = 0; i < services.length; i++) {
        if (services[i].isActive === 1 && services[i].policyStatus === 'Inforce' && services[i].premium) {
          totalApi = totalApi + +services[i].premium;
          lr.aPI = lr.aPI + +services[i].premium;
        }
      }

      lr.customerServices = services;
      lr.startDate = services[0].policyType === 'Primary' && services[0].policyStatus === 'Inforce' ? services[0].startDate : '';
      lr.color = lrColorStatus(services[0].policyStatus);
      lr.status = services[0].policyStatus;
    });

    draft.totalInforceAPI = totalApi;
    draft.lRs?.sort(mainGroupSorter);
  });
};



