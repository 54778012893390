import { DOCUMENT } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { AdviceProcessStatusState, ClaimsTable } from '@shared/models/advice-process/advice-process.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Observable, ReplaySubject, Subscription, debounceTime, fromEvent, take } from 'rxjs';
@Component({
	selector: 'app-table-paginate-claims',
	templateUrl: './table-paginate-claims.component.html',
	styleUrls: ['./table-paginate-claims.component.scss'],
})
export class TablePaginateClaimsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() isLoading: boolean;
	@Input() count = 0;
	@Input() itemsPerPage = 5;
	@Input() maxSize = 5;
	@Input() claims: ClaimsTable[];
	@Input() hasPaging = false;
	@Input() redirectToAP$: (data: {
		id?: string;
		serviceCode?: string;
		customerServiceId?: number;
	}) => Observable<unknown>;
	@Input() claimsType: string;

	private subscriptions = new Subscription();

	isLoading$ = new ReplaySubject<boolean>(1);
	currentpage = 1;
	claimsList: ClaimsTable[] = [];
	get getClaimsList() {
		return this.claims ? this.claims : [];
	}

	get hasPagingTable() {
		return !!this.hasPaging;
	}

	constructor(
		private cdr: ChangeDetectorRef,
		@Inject(DOCUMENT) private doc: HTMLDocument,
	) {}

	ngOnInit(): void {
		const sub = fromEvent(window, 'resize')
			.pipe(debounceTime(500))
			.subscribe(() => {
				this.cdr.detectChanges();
			});
		this.subscriptions.add(sub);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.claims) {
			const startItem = (this.currentpage - 1) * this.itemsPerPage;
			const endItem = this.currentpage * this.itemsPerPage;
			const data = changes.claims.currentValue;
			this.claims = data;
			const claimsList =
				data && data.length > 0
					? this.hasPaging
						? data?.length > this.itemsPerPage
							? data?.slice(startItem, endItem)
							: data
						: data?.slice(startItem, endItem)
					: [];
			this.setClaimsList(claimsList);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private setClaimsList(list: ClaimsTable[]): void {
		this.claimsList =
			list?.map((l) => {
				l.claimType?.join(', ');
				// @ts-ignore-next
				l.status = typeof l.status === 'number' ? this.convertAPStatus(l.status) : l.status;
				return l;
			}) ?? [];
	}

	redirectToAdvice(item: ClaimsTable) {
		this.redirectToAP$({ customerServiceId: item.adviceProcessID, serviceCode: this.claimsType })
			.pipe(take(1))
			.subscribe();
	}
	trackByFn(_, item) {
		return item.adviceProcessId;
	}
	setPage(event: PageChangedEvent): void {
		this.currentpage = event.page;
		this.isLoading$.next(true);
		if (!this.hasPagingTable) {
			const startItem = (event.page - 1) * event.itemsPerPage;
			const endItem = event.page * event.itemsPerPage;
			const data = this.getClaimsList;
			this.setClaimsList(data && data.length > 0 ? data?.slice(startItem, endItem) : []);
			if (data?.length > 0 && this.claimsList.length === 0) {
				this.currentpage = 1;
				this.setClaimsList(data);
			}
			this.isLoading$.next(false);
			setTimeout(() => this.cdr.detectChanges());
		}
	}

	convertAPStatus(data: AdviceProcessStatusState) {
		switch (data) {
			case AdviceProcessStatusState.deleted:
				return 'Deleted';
			case AdviceProcessStatusState.active:
				return 'In Progress';
			case AdviceProcessStatusState.complete:
				return 'Ended - Completed';
			case AdviceProcessStatusState.incomplete:
				return 'Ended - Incomplete';
			case AdviceProcessStatusState.cancelled:
				return 'Cancelled';
			case AdviceProcessStatusState.archived:
				return 'Archived';
			case AdviceProcessStatusState.reopened:
				return 'In Progress';
			default:
				return '';
		}
	}

	formatClaimType(claimType: string[]) {
		if (claimType && claimType.length > 0) {
			return claimType.toString().trim().replace(/,/g, ', ');
		}
		return claimType;
	}

	isEllipsisActive(cell: HTMLDivElement, valueContainer: HTMLElement): boolean {
		const paddingXInPX = 8;
		return cell?.getBoundingClientRect()?.width - paddingXInPX < valueContainer?.getBoundingClientRect()?.width;
	}
}
