import { ActivityRequest } from './states/activity.model';

export const comparable = o =>
  typeof o !== 'object' || !o
    ? o
    : Object.keys(o)
      ?.sort()
      ?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (req: ActivityRequest, saveReq: ActivityRequest) => {
  let r = true;

  if (!req || !saveReq) {
    return (r = false);
  }

  if (JSON.stringify(comparable(req.Advisers)) !== JSON.stringify(comparable(saveReq.Advisers))) {
    r = false;
  }

  if (JSON.stringify(comparable(req.AltAdvisers)) !== JSON.stringify(comparable(saveReq.AltAdvisers))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.ActivityType)) !== JSON.stringify(comparable(saveReq.ActivityType))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.CreatedBy)) !== JSON.stringify(comparable(saveReq.CreatedBy))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.AssignedTo)) !== JSON.stringify(comparable(saveReq.AssignedTo))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.ActivityStatus)) !== JSON.stringify(comparable(saveReq.ActivityStatus))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.AdviserStatus)) !== JSON.stringify(comparable(saveReq.AdviserStatus))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.ClientFirstName)) !== JSON.stringify(comparable(saveReq.ClientFirstName))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.ClientSurname)) !== JSON.stringify(comparable(saveReq.ClientSurname))) {
    r = false;
  }
  if (
    JSON.stringify(comparable(req.ActivityCreateDateMin)) !== JSON.stringify(comparable(saveReq.ActivityCreateDateMin))
  ) {
    r = false;
  }
  if (
    JSON.stringify(comparable(req.ActivityCreateDateMax)) !== JSON.stringify(comparable(saveReq.ActivityCreateDateMax))
  ) {
    r = false;
  }
  if (JSON.stringify(comparable(req.ActivityDueDateMin)) !== JSON.stringify(comparable(saveReq.ActivityDueDateMin))) {
    r = false;
  }
  if (JSON.stringify(comparable(req.ActivityDueDateMax)) !== JSON.stringify(comparable(saveReq.ActivityDueDateMax))) {
    r = false;
  }
  if (
    JSON.stringify(comparable(req.ActivityCompleteDateMin)) !==
    JSON.stringify(comparable(saveReq.ActivityCompleteDateMin))
  ) {
    r = false;
  }
  if (
    JSON.stringify(comparable(req.ActivityCompleteDateMax)) !==
    JSON.stringify(comparable(saveReq.ActivityCompleteDateMax))
  ) {
    r = false;
  }

  // Returns true if no data has been modified
  return r;
};

export const request = (filter: any, form: ActivityRequest, index: number) => {
  const req = {
    Advisers: form.Advisers,
    AltAdvisers: form.AltAdvisers,
    CreatedBy: form.CreatedBy,
    AssignedTo: form.AssignedTo,
    ActivityStatus: form.ActivityStatus,
    AdviserStatus: form.AdviserStatus,
    ClientFirstName: form.ClientFirstName,
    ClientSurname: form.ClientSurname,
    ActivityCreateDateMin: form.ActivityCreateDateMin,
    ActivityCreateDateMax: form.ActivityCreateDateMax,
    ActivityDueDateMin: form.ActivityDueDateMin,
    ActivityDueDateMax: form.ActivityDueDateMax,
    ActivityCompleteDateMin: form.ActivityCompleteDateMin,
    ActivityCompleteDateMax: form.ActivityCompleteDateMax,
    ActivityType: form.ActivityType,
    Paging: {
      Index: index,
      Column: filter ? filter.Column : null,
      Direction: filter ? filter.Direction : null
    }
  };
  return req;
};
