import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { BusinessAdminGuard } from '@core/general/business-admin.guard';
import { BusinessLeaveGuard } from '@core/general/business-leave.guard';
import { environment } from '@environment';
import { LoginAutomationComponent } from '@modules/login-automation/login-automation.component';
import { NavigationComponent } from '@modules/navigation/navigation.component';
import { BusinessClearGuard } from './core/general/business-clear.guard';
import { BusinessSetterGuard } from './core/general/business-setter.guard';
import { CrmDataService } from './core/general/crm-data.service';
import { RedirectGuard } from './core/general/redirect.guard';
import { SetViewBusLevelGuard } from './core/general/set-view-bus-level.guard';
import { SetViewCrmLevelGuard, SetViewNotCrmLevelGuard } from './core/general/set-view-crm-level.guard';
import { SetViewTapLevelGuard } from './core/general/set-view-tap-level.guard';
import { TlAdminDataService } from './core/general/tl-admin-data.service';
import { AuthAutomationGuard } from './core/guards/auth-automation.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { ErrorComponent } from './modules/error/error.component';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';
import { ViewPdfComponent } from './modules/view-pdf/view-pdf.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
	{
		path: 'externalRedirect',
		canActivate: [externalUrlProvider],
		component: NotFoundComponent,
	},
	{
		path: 'login',
		data: { breadcrumb: 'Login' },
		component: environment.staging ? LoginAutomationComponent : LoginComponent,
	},
	{
		path: 'login-automation',
		data: { breadcrumb: 'Login Automation' },
		component: LoginAutomationComponent,
		canMatch: [AuthAutomationGuard], // Guarded for dev, test and staging environment only
	},
	{
		path: 'login/unauthorized',
		data: {
			breadcrumb: 'Login',
		},
		component: UnauthorizedComponent,
	},
	{
		path: 'admin',
		canActivate: [AuthGuard, BusinessClearGuard, SetViewNotCrmLevelGuard, SetViewTapLevelGuard],
		resolve: [TlAdminDataService],
		component: HomeComponent,
		children: [
			{
				path: 'business',
				data: { isTapLevelView: true },
				loadChildren: () =>
					import('../app/modules/business-page/business-page.module').then((m) => m.BusinessPageModule),
			},
			{
				path: 'report',
				data: {
					isTapLevelView: true,
					breadcrumb: 'Reports',
				},
				loadChildren: () => import('../app/modules/tl-report/tl-report.module').then((m) => m.TlReportModule),
			},
			{
				path: 'users',
				data: {
					isTapLevelView: true,
					breadcrumb: 'Users',
				},
				loadChildren: () => import('../app/modules/user/modules/tl-user/tl-user.module').then((m) => m.TlUserModule),
			},
			{
				path: '',
				redirectTo: 'business',
				pathMatch: 'full',
			},
			{
				path: 'moat-settings',
				data: {
					isTapLevelView: true,
					breadcrumb: 'Mortgage Online Advice Tool',
				},
				loadChildren: () =>
					import('./modules/tl-moat-settings/tl-moat-settings.module').then((m) => m.TlMoatSettingsModule),
			},
			{
				path: 'mobile-app-settings',
				data: {
					isTapLevelView: true,
					breadcrumb: 'Mobile App',
				},
				loadChildren: () =>
					import('./modules/tl-mobile-settings/tl-mobile-settings.module').then((m) => m.TlMobileSettingsModule),
			},
			{
				path: 'announcements',
				data: {
					isTapLevelView: true,
					breadcrumb: 'Announcements',
				},
				loadChildren: () => import('./modules/annoucements/announcements.module').then((m) => m.AnnouncementsModule),
			},
		],
	},
	{
		path: 'error',
		data: {
			breadcrumb: 'Error',
		},
		component: ErrorComponent,
	},
	{
		path: ':companyCode',
		canActivate: [BusinessSetterGuard, SetViewBusLevelGuard],
		data: {
			breadcrumb: 'Login',
		},
		component: LoginComponent,
	},
	{
		path: ':companyCode',
		canActivate: [BusinessSetterGuard, SetViewBusLevelGuard],
		canDeactivate: [BusinessLeaveGuard],
		resolve: {
			data: CrmDataService,
		},
		data: { isTapLevelView: false },
		children: [
			{
				path: 'admin',
				component: HomeComponent,
				canActivate: [AuthGuard, SetViewNotCrmLevelGuard, BusinessAdminGuard],
				children: [
					{
						path: 'theme',
						data: {
							breadcrumb: 'Theme',
						},
						loadChildren: () => import('../app/modules/admin/theme/theme.module').then((m) => m.ThemeModule),
					},
					{
						path: 'images',
						data: {
							breadcrumb: 'Images',
						},
						loadChildren: () => import('../app/modules/admin/images/images.module').then((m) => m.ImagesModule),
					},
					{
						path: 'business',
						data: {
							breadcrumb: 'Business',
						},
						loadChildren: () =>
							import('../app/modules/business/bl-business/bl-business.module').then((m) => m.BlBusinessModule),
					},
					{
						path: 'users',
						data: {
							breadcrumb: 'Users',
						},
						loadChildren: () =>
							import('../app/modules/user/modules/bl-user/bl-user.module').then((m) => m.BlUserModule),
					},
					{
						path: 'special-features',
						data: {
							breadcrumb: 'Special Features',
						},
						loadChildren: () =>
							import('../app/modules/special-features/special-features.module').then((m) => m.SpecialFeaturesModule),
					},
					{
						path: 'activities',
						data: {
							breadcrumb: 'Activities',
						},
						loadChildren: () =>
							import('../app/modules/admin/activities/activities.module').then((m) => m.ActivitiesModule),
					},
					{
						path: 'primarycustomer',
						data: {
							breadcrumb: 'Primary Customer',
						},
						loadChildren: () =>
							import('../app/modules/admin/primary-customer/primary-customer.module').then(
								(m) => m.PrimaryCustomerModule,
							),
					},
					{
						path: 'secondarycustomer',
						data: {
							breadcrumb: 'Secondary Customer',
						},
						loadChildren: () =>
							import('../app/modules/admin/secondary-customer/secondary-customer.module').then(
								(m) => m.SecondaryCustomerModule,
							),
					},
					{
						path: 'services',
						data: {
							breadcrumb: 'Services',
						},
						loadChildren: () => import('../app/modules/admin/services/services.module').then((m) => m.ServicesModule),
					},
					{
						path: 'dataimport',
						data: {
							breadcrumb: 'Data Import',
						},
						loadChildren: () => import('../app/modules/data-import/data-import.module').then((m) => m.DataImportModule),
					},
					{
						path: 'downloadDocuments',
						data: {
							breadcrumb: 'Download Documents',
						},
						loadChildren: () =>
							import('../app/modules/download-documents/download-documents.module').then(
								(m) => m.DownloadDocumentsModule,
							),
					},
					{
						path: 'adviceProcess',
						data: {
							breadcrumb: 'Download Documents',
						},
						loadChildren: () =>
							import('../app/modules/advice-process/advice-process.module').then((m) => m.AdviceProcessModule),
					},
					{
						path: 'emails',
						data: {
							breadcrumb: 'Emails',
						},
						loadChildren: () => import('../app/modules/emails/emails.module').then((m) => m.EmailsModule),
					},
					{
						path: 'crt-settings',
						data: {
							breadcrumb: 'CRT Settings',
						},
						loadChildren: () =>
							import('../app/modules/crt-settings/crt-settings.module').then((m) => m.CrtSettingsModule),
					},
					{ path: '', pathMatch: 'full', redirectTo: 'business' },
					{
						path: 'mortgage-settings',
						data: {
							breadcrumb: 'Mortgage Settings',
						},
						loadChildren: () =>
							import('../app/modules/mortgage-settings/mortgage-settings.module').then((m) => m.MortgageSettingsModule),
					},
					{
						path: 'cp-settings',
						data: {
							breadcrumb: 'Client Portal Settings',
						},
						loadChildren: () => import('../app/modules/cp-settings/cp-settings.module').then((m) => m.CpSettingsModule),
					},
					{
						path: 'kiwisaver-settings',
						data: {
							breadcrumb: 'KiwiSaver Settings',
						},
						loadChildren: () =>
							import('../app/modules/kiwisaver-settings/kiwisaver-settings.module').then(
								(m) => m.KiwiSaverSettingsModule,
							),
					},
					{
						path: 'car-settings',
						data: {
							breadcrumb: 'Customer Alteration Request Settings',
						},
						loadChildren: () =>
							import(
								'../app/modules/customer-alteration-request-settings/customer-alteration-request-settings.module'
							).then((m) => m.CustomerAlterationRequestSettingsModule),
					},
				],
			},
			{
				path: 'view-pdf',
				data: { breadcrumb: 'View PDF' },
				component: ViewPdfComponent,
			},
			{
				path: '',
				canActivate: [SetViewCrmLevelGuard, AuthGuard],
				loadChildren: () => import('./modules/crm/crm.module').then((x) => x.CrmModule),
			},
		],
	},
	{
		path: '',
		canActivate: [AuthGuard, RedirectGuard],
		pathMatch: 'full',
		component: NavigationComponent,
	},
	{ path: '**', component: NotFoundComponent },
];
@NgModule({
	declarations: [],
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			paramsInheritanceStrategy: 'always',
		}),
	],
	exports: [RouterModule],
	providers: [
		BusinessClearGuard,
		{
			provide: externalUrlProvider,
			useValue: (route: ActivatedRouteSnapshot) => {
				const externalUrl = route.paramMap.get('externalUrl');
				window.open(externalUrl, '_blank');
			},
		},
	],
})
export class AppRoutingModule {}
