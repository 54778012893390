import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TlMobileSettingsState, TlMobileSettingsStore } from './tl-mobile-settings.store';


@Injectable()
export class TlMobileSettingsQuery extends Query<TlMobileSettingsState> {
	/**
	 * @returns observable of Servicing Calculators Settings
	 */
	iosVersion$ = this.select((state) => state.iosVersion);

	constructor(protected store: TlMobileSettingsStore) {
		super(store);
	}
}
