import { Moment } from "moment";
import { PropertyAssetCustomerService, PropertyAssetCustomerServiceState } from "../../../services/property-asset/property-asset";
import { FactFindComputationDetails, FactFindComputationState } from "../../income-budget/factfind-computation.model";


// For Displays on Main Screens
export interface PropertyListItemState {
  adviceProcessId?: number;
  cRTId: number;
  owner: string;
  propertyAddress: string;
  propertyValue: number;
  propertyUse?: string;
	rentalIncome?: number;
	rentalFrequency?: string;

  customerServiceID?: number;
  fromCRM?: boolean;
}

// Base Property Model - Received From API
export interface PropertyBase {
	AdviceProcessId: number;
	CRTId?: number;
	CreateDateTime?: string;
	CreatedByStaffId?: number;
	CreatedByStaffLevel?: number;
	CustomerServiceID?: number;
	Insurance: string;
	InsuranceFrequency: string;
	ModifiedByStaffId?: number;
	ModifiedDateTime?: string;
	OtherExpense: string;
	OtherExpenseFrequency: string;
	PropertyAddress: string;
	PropertyValue: number;
	PropertyOwner: string;
	PropertyUse: string;
	PurchaseDate: string;
	Rates: number;
	RatesFrequency: string;
	RentalIncome: number;
	RentalIncomeFrequency: string;
	BoarderIncome?: number;
	BoarderIncomeFrequency?: string;
	IncomeType?: string;
	Status: number;
	SectionCode: string;
	Title: string;
	Total: string;
	Type: string;
	ValuationType: string;
	ValuationTypeDate: string;
	PendingAdviserApproval?: boolean;
}

// Base Model - Parsed and mapped to camel case
export interface PropertyStateBase {
	adviceProcessId?: number;
	cRTId: number;
	createDateTime?: string;
	createdByStaffId?: number;
	createdByStaffLevel?: number;
	customerServiceID?: number;
	insurance: string;
	insuranceFrequency: string;
	modifiedByStaffId?: number;
	modifiedDateTime?: string;
	otherExpense: string;
	otherExpenseFrequency: string;
	propertyAddress: string;
	propertyOwner: string;
	propertyUse: string;
	propertyValue: number;
	purchaseDate: string;
	rates: number;
	ratesFrequency: string;
	rentalIncome: number;
	rentalIncomeFrequency: string;
	boarderIncome?: number;
	boarderIncomeFrequency?: string;
	incomeType?: string;
	sectionCode: string;
	status: number;
	title: string;
	total: string;
	type: string;
	valuationType: string;
	valuationTypeDate: string;
	propertyOwnerName?: string;
	pendingAdviserApproval?: boolean;
}

export interface ExistingProperty extends PropertyAssetCustomerService {
  PropertyAddress: string;
  PropertyValue: number;
  PropertyOwner: string;
  PropertyUse: string;
}

// LOAT Property Model - Received from API
export interface LOATProperty extends PropertyBase {
  FactFindComputation?: FactFindComputationDetails;
  Valuation1?: string;
  GRM1Date?: string;
}

// LOAT Property Model - Parsed and mapped to camel case
export interface LOATPropertyState extends Omit<PropertyStateBase, 'purchaseDate' | 'valuationTypeDate'> {
  factFindComputation?: FactFindComputationState;
  valuation1?: string;
  gRM1Date?: string;
  purchaseDate: Moment;
  valuationTypeDate: Moment;
}

export interface PropertyDetails {
  CRTId?: number;
  AdviceProcessId: number;
  SectionCode: string;
  PropertyAddress: string;
  PropertyValue: number;
  PropertyOwner: string; // 'number[]'
  Total: string;
  PropertyUse: string;
  PurchaseDate: string;
  ValuationType: string;
  ValuationTypeDate: string;
  RentalIncome: number;
  RentalIncomeFrequency: string;
  BoarderIncome?: number;
  BoarderIncomeFrequency?: string;
  IncomeType?: string;
  Title: string;
  Rates: number;
  RatesFrequency: string;
  Type: string;
  Insurance: string;
  InsuranceFrequency: string;
  OtherExpense: string;
  OtherExpenseFrequency: string;
  CustomerServiceID?: number;
  FactFindComputation: {
    // AdviceProcessId: number;
    SectionCode: string;
    TotalPropertyValue: number;
    TotalAssetValue: number;
    OverallTotalGrossIncome: number;
    TotalNetRentalIncome: number;
    AnnualTaxableJointIncome: number;
    MonthlyTaxableJointIncome: number;
    MonthlyAfterTaxIncome: number;
  };
  Status?: number;
}

export interface PropertyInfo {
  AdviceProcessId: number;
  CRTId: number;
  CreateDateTime: string;
  CreatedByStaffId: number;
  CreatedByStaffLevel: number;
  CustomerServiceID: number;
  FactFindComputation: string;
  Insurance: string;
  InsuranceFrequency: string;
  ModifiedByStaffId: number;
  ModifiedDateTime: string;
  OtherExpense: string;
  OtherExpenseFrequency: string;
  PropertyAddress: string;
  PropertyOwner: string;
  PropertyUse: string;
  PropertyValue: number;
  FinanceDate?: string;
  PurchaseDate: string;
  Rates: number;
  RatesFrequency: string;
  RentalIncome: number;
  RentalIncomeFrequency: string;
  BoarderIncome?: number;
  BoarderIncomeFrequency?: string;
  IncomeType?: string;
  SectionCode: string;
  Status: number;
  Title: string;
  Total: number;
  Type: string;
  ValuationType: string;
  ValuationTypeDate: string;
}

export interface PropertyInfoState {
  adviceProcessId?: number;
  cRTId: number;
  createDateTime: string;
  createdByStaffId: number;
  createdByStaffLevel: number;
  customerServiceID: number;
  factFindComputation: string;
  insurance: string;
  insuranceFrequency: string;
  modifiedByStaffId: number;
  modifiedDateTime: string;
  otherExpense: string;
  otherExpenseFrequency: string;
  propertyAddress: string;
  propertyOwner: string;
  propertyUse: string;
  propertyValue: number | string;
  purchaseDate: string;
  rates: number;
  ratesFrequency: string;
  rentalIncome: number | string;
  rentalIncomeFrequency: string;
  boarderIncome: number | string;
  boarderIncomeFrequency: string;
  incomeType: string;
  sectionCode: string;
  security: string;
  status: number;
  title: string;
  total: number;
  type: string;
  valuationType: string;
  valuationTypeDate: string;
  propertyOwnerName?: string;
  isTBC: boolean;

  fromCRM?: boolean;
  owner?: string;
  propertyStatus?: string;
}

export enum PropertyStatus {
	Current = 'Current'
}

export enum PropertyUse {
	OwnerOccupied = 'Owner Occupied'
}
