import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BookBreakdown } from './state/bb.model';
import { map, filter, takeUntil } from 'rxjs/operators';
import { BodyRow, HeaderRow } from '../../shared/simple-table/simple-table.model';
import { DashboardQuery } from '../../state/dashboard.query';
import { BookBreakdownQuery } from './state/bb.query';
import { BookBreakdownService } from './state/bb.service';
import { numUtil } from '../../../../../util/util';

@Component({
  selector: 'app-bb',
  templateUrl: './bb.component.html',
  styleUrls: ['./bb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BbComponent implements OnInit, OnDestroy {
  public static widgetCode = 'bb';
  public static widgetName = 'Book Breakdown';
  public static sizeX = 800;
  public static sizeY = 245;
  public static minSizeX = 350;
  public static minSizeY = 245;

  widgetCode = BbComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * Book Breakdown Data
   */
  data$: Observable<BookBreakdown[]> = this.query.BookBreakdownData$;

  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return data?.map<BodyRow>(item => {
          return [
            { text: `${item.Provider}`, class: 'col' },
            { text: numUtil.formatToCurrency(item.TotalInforce), class: 'col' },
            { text: numUtil.formatToCurrency(item.Issued), class: 'col' },
            { text: numUtil.formatToCurrency(item.Cancelled), class: 'col' },
            { text: numUtil.formatToPercent(item.Persistency), class: 'col' }
          ];
        });
      }
    })
  );

  /** header for simple-table header object */
  header: HeaderRow = [
    { text: 'Provider', class: 'col' },
    { text: 'Total Inforce API', class: 'col' },
    { text: 'Issued API', class: 'col' },
    { text: 'Cancelled API', class: 'col' },
    { text: 'Persistency', class: 'col' }
  ];

  constructor(
    private dashboardQuery: DashboardQuery,
    private query: BookBreakdownQuery,
    private service: BookBreakdownService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of book breakdown stats.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetBookBreakdown(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
