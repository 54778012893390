import { EntityState } from '@datorama/akita';
import { ClientReferralAttachments } from '@shared/client-referral-email-modal/client-referral-email-modal-form/client-referral-email-modal-form.component';

interface _ClientReferralSettingReferral {
	referralService: string;
	isDefault: boolean;
	referralOptionType: string;
	referralLink: string;
	isChecked: boolean;
	priority: number;
	carbonCopy: any;
	blindCarbonCopy: any;
	subject: string;
	emailDestinationName: string;
	emailDestinationEmailAddress: string;
	body: any;
	mergeTag: any;
	activityName: string;
	activityType: string;
	duration: number;
	adviser: string;
	assignedToAdviserName: string;
	location: string;
	details: string;
	meeting: string;
	settingsId: number;
	referenceId: number;
	type: string;
	createdDateTime: string;
	modifiedDateTime: string;
	createdByStaffId: number;
	modifiedByStaffId: 23111601;
	createdByStaffLevel: number;
	modifiedByStaffLevel: number;
	status: number;

	bodyContent: string;
	attachment: string;
	attachmentFiles: ClientReferralAttachments[];

	bodyWithoutSignature: string;
	customerId: number;
	customerName: string;

  hTMLBody: string;
	emailDestination: { Email: string; Name: string; FirstName: string; }[];
	emailDestinationLastName: any;
	emailDestinationFirstName: string;
	adviceProcessId: any;
}

export interface CRActivityFromDataSetting {
	activityName: string;
	activityType: string;
	duration?: number;
	adviser: string;
	assignedToAdviserName: string;
	location: string;
	details: string;
	meeting: string;
}

export interface ClientReferralSettingReferral
	extends Partial<_ClientReferralSettingReferral> {
}

export interface ClientReferralSettingReferralState
	extends EntityState<ClientReferralSettingReferral> {}

export enum ReferalOptionType {
	link = 'Link',
	email = 'Email',
	activity = 'Activity',
}

export enum ActivitySettingProps {
  ActivityName = 'ActivityName',
  ActivityType = 'ActivityType',
  Duration = 'Duration',
  Adviser = 'Adviser',
  AssignedToAdviserName = 'AssignedToAdviserName',
  Location = 'Location',
  Details = 'Details',
  Meeting = 'Meeting',
  Appointment = 'Appointment',
}
