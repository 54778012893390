<div class="container-fluid">
	<div class="row">
		<div class="col-auto custom-sidebar-nav custom-sidebar-nav--vertical mb-3">
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
			>
				<div class="col font-weight-bold px-0">People</div>
				<ng-container *ngIf="isLead">
					<ng-container *appRestrictTo="['FAL']">
						<div class="col-auto px-0">
							<i
								class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
								id="btn_add_sci"
								[class.disabled]="disableAdd()"
								(click)="
									selectAddSecondary();
									peopleEntitiesTop.scrollIntoView({
										behavior: 'smooth',
										block: 'start'
									})
								"
								>add</i
							>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="sciIsLoading$ | async">
					<div class="col-auto px-0">
						<app-manual-loader-mini
							*ngIf="sciIsLoading$ | async"
							[isLoading]="sciIsLoading$ | async"
						></app-manual-loader-mini>
					</div>
				</ng-container>
				<ng-container *ngIf="!isLead && !(sciIsLoading$ | async)">
					<ng-container *appRestrictTo="['FAC']">
						<div class="col-auto px-0">
							<i
								class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
								id="btn_add_sci"
								[class.disabled]="disableAdd()"
								(click)="
									selectAddSecondary();
									peopleEntitiesTop.scrollIntoView({
										behavior: 'smooth',
										block: 'start'
									})
								"
								>add</i
							>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<!-- Primary CLient -->
			<div
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--success"
				[ngClass]="{ active: activeTabPeId === 'PrimaryClientTab' }"
				id="select_pci"
				(click)="
					selectPrimary();
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong>{{ fullName }}</strong>
				<span class="font-italic">"Primary"</span>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>
			<!-- Secondary Clients -->
			<div
				*ngFor="
					let sci of secondaryClients$ | async;
					let i = index;
					trackBy: trackbyFn
				"
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2"
				[class.list--success]="sci.contactMethod != 'Deceased'"
				[ngClass]="{
					'list--success': sci?.tempStatus === 'Active',
					'list--grey': sci?.tempStatus === 'Deceased',
					'list--black': sci?.tempStatus === 'Transferred',
					'list--danger': sci?.tempStatus === 'Active' && sci?.wasCopied
				}"
				id="select_sci_{{ i }}"
				[class.active]="
					activeTabPeId === 'SecondaryClientTab' &&
					secondaryClient?.customerID === sci?.customerID &&
					sci?.isActive !== 3
				"
				(click)="
					selectSecondary(sci);
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong>{{ sci.firstName }} {{ sci.lastName }}</strong>
				<span class="font-italic">"{{ sci.relationship }}"</span>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>
			<div
				*ngIf="isAddPerson"
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--success last--index"
				id="select_add_sci"
				[ngClass]="{ active: activeTabPeId === 'AddNewSecondaryTab' }"
				(click)="
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong>Add New Contact</strong>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>
			<!-- Secondary Business -->
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2 mt-3"
			>
				<div class="col font-weight-bold px-0">Business</div>

				<ng-container *ngIf="sccIsLoading$ | async">
					<div class="col-auto px-0">
						<app-manual-loader-mini
							*ngIf="sccIsLoading$ | async"
							[isLoading]="sccIsLoading$ | async"
						></app-manual-loader-mini>
					</div>
				</ng-container>
				<ng-container *ngIf="isLead && !(sccIsLoading$ | async)">
					<ng-container *appRestrictTo="['FAL']">
						<div class="col-auto px-0">
							<a
								[routerLink]="addNewBusiness$ | async"
								target="_blank"
								id="btn_add_pcc"
								[class.disabled]="disableAdd()"
							>
								<i
									class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
									>add</i
								>
							</a>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!isLead && !(sccIsLoading$ | async)">
					<ng-container *appRestrictTo="['FAC']">
						<div class="col-auto px-0">
							<a
								[routerLink]="addNewBusiness$ | async"
								target="_blank"
								id="btn_add_pcc"
								[class.disabled]="disableAdd()"
							>
								<i
									class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
									>add</i
								>
							</a>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div
				*ngFor="
					let business of secondaryBusinesses$ | async;
					let i = index;
					trackBy: trackbyFn
				"
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--purple"
				id="select_pcc_{{ i }}"
				[ngClass]="{
					active:
						activeTabPeId === 'SecondaryBusinessTab' &&
						secondaryBusiness?.customerID === business?.customerID
				}"
				(click)="
					selectBusiness(business);
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong
					>{{ business.companyName | slice: 0:40
					}}<ng-container *ngIf="business?.companyName?.length > 40"
						>...</ng-container
					></strong
				>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>
			<!-- Secondary Trust -->
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2 mt-3"
			>
				<div class="col font-weight-bold px-0">Trust</div>
				<ng-container *ngIf="sctIsLoading$ | async">
					<div class="col-auto px-0">
						<app-manual-loader-mini
							*ngIf="sctIsLoading$ | async"
							[isLoading]="sctIsLoading$ | async"
						></app-manual-loader-mini>
					</div>
				</ng-container>
				<ng-container *ngIf="isLead && !(sctIsLoading$ | async)">
					<ng-container *appRestrictTo="['FAL']">
						<div class="col-auto px-0">
							<i
								class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
								id="btn_add_sct"
								[class.disabled]="disableAdd()"
								(click)="
									selectAddTrust();
									peopleEntitiesTop.scrollIntoView({
										behavior: 'smooth',
										block: 'start'
									})
								"
								>add</i
							>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!isLead && !(sctIsLoading$ | async)">
					<ng-container *appRestrictTo="['FAC']">
						<div class="col-auto px-0">
							<i
								class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
								id="btn_add_sct"
								[class.disabled]="disableAdd()"
								(click)="
									selectAddTrust();
									peopleEntitiesTop.scrollIntoView({
										behavior: 'smooth',
										block: 'start'
									})
								"
								>add</i
							>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div
				*ngFor="
					let sct of secondaryTrusts$ | async;
					let i = index;
					trackBy: trackbyFn
				"
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--info"
				id="select_sct_{{ i }}"
				[ngClass]="{
					active:
						activeTabPeId === 'SecondaryTrustTab' &&
						sct?.customerID === secondaryTrust?.customerID
				}"
				(click)="
					selectTrust(sct);
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong
					>{{ sct.trustName | slice: 0:40
					}}<ng-container *ngIf="sct?.trustName?.length > 40"
						>...</ng-container
					></strong
				>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>
			<div
				*ngIf="isAddTrust"
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--info last--index"
				id="select_add_sct"
				[ngClass]="{ active: activeTabPeId === 'AddNewTrustTab' }"
				(click)="
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong>Add New Contact</strong>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>
			<!-- Secondary Professional -->
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2 mt-3"
			>
				<div class="col font-weight-bold px-0">Professional Contacts</div>

				<ng-container *ngIf="scpIsLoading$ | async">
					<div class="col-auto px-0">
						<app-manual-loader-mini
							*ngIf="scpIsLoading$ | async"
							[isLoading]="scpIsLoading$ | async"
						></app-manual-loader-mini>
					</div>
				</ng-container>
				<ng-container *ngIf="isLead && !(scpIsLoading$ | async)">
					<ng-container *appRestrictTo="['FAL']">
						<div class="col-auto px-0">
							<i
								class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
								[class.disabled]="disableAdd()"
								id="btn_add_scp"
								(click)="
									selectAddProfessional();
									peopleEntitiesTop.scrollIntoView({
										behavior: 'smooth',
										block: 'start'
									})
								"
								>add</i
							>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!isLead && !(scpIsLoading$ | async)">
					<ng-container *appRestrictTo="['FAC']">
						<div class="col-auto px-0">
							<i
								class="material-icons md-20 font-weight-bold tap-text-color-h6 clickable"
								id="btn_add_scp"
								[class.disabled]="disableAdd()"
								(click)="
									selectAddProfessional();
									peopleEntitiesTop.scrollIntoView({
										behavior: 'smooth',
										block: 'start'
									})
								"
								>add</i
							>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div
				*ngFor="
					let scp of secondaryProfessionlContacts$ | async;
					let i = index;
					trackBy: trackbyFn
				"
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--info"
				id="select_scp_{{ i }}"
				[ngClass]="{
					active:
						activeTabPeId === 'SecondaryProfessionalTab' &&
						scp?.customerID === secondaryProfessionalContact?.customerID
				}"
				(click)="
					selectProfessional(scp);
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong
					>{{ scp.name | slice: 0:40
					}}<ng-container *ngIf="scp?.name?.length > 40"
						>...</ng-container
					></strong
				>
				<span class="font-italic">"{{scp.professionalType}}"</span>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>
			<div
				*ngIf="isAddProfessional"
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--info last--index"
				id="select_add_scp"
				[ngClass]="{ active: activeTabPeId === 'AddNewProfessionalTab' }"
				(click)="
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
			>
				<strong>Add New Contact</strong>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
			</div>

			<!-- Secondary Linked Contacts -->
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2 mt-3"
			>
				<div class="col font-weight-bold px-0">Linked Contacts</div>
			</div>

			<div
				class="custom-sidebar-nav__list d-flex flex-column px-3 py-2 list--danger last--index"
				[ngClass]="{ active: activeTabPeId === 'LinkedContactsTab' }"
				(click)="
					selectLinkedContacts();
					peopleEntitiesTop.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				"
				id="selectLinkedContacts"
			>
				<strong>{{ (linkedContacts$ | async)?.length || 0 }} Linked</strong>
				<i class="material-icons md-20 arrow-right">chevron_right</i>
				<ng-container *ngIf="sclIsLoading$ | async">
					<app-manual-loader-mini
						class="pt-1"
						[isLoading]="sclIsLoading$ | async"
					></app-manual-loader-mini>
				</ng-container>
			</div>
		</div>

		<div
			class="col-12 col-md"
			[ngClass]="
				secondaryClient?.isActive === 3 &&
				activeTabPeId !== 'PrimaryClientTab' &&
				activeTabPeId !== 'LinkedContactsTab'
					? 'transfered'
					: 'not-transfered'
			"
			#peopleEntitiesTop
		>
			<tabset
				#peopleEntities
				class="tap-tabset-sidebar tap-tabset-sidebar--hidden-header"
				[vertical]="true"
			>
				<tab heading="Primary Client" id="PrimaryClientTab">
					<app-form-person
						formID="pci"
						[isLead]="isLead"
						[data]="primaryClient"
						[isSaving]="isSavingPrimary"
						[isEdit]="isEditPrimary"
						[disableDelete]="true"
						[title]="title"
						[employment]="employment"
						[preferredContact]="preferredContact"
						[relationship]="relationship"
						[leadTypeChoices]="leadTypeChoices"
						[leadOriginChoices]="leadOriginChoices"
						[leadGenChoices]="leadGenChoices"
						[adviserGenChoices]="adviserGenChoices"
						[allStaffChoices]="allStaffChoices"
						[professionalContacts]="professionalContacts"
						[industry]="industry"
						[businessConfig$]="businessConfig$"
						[addPhoto$]="addPhoto$"
						(saveEvent)="updatePrimaryClient($event)"
						[getNotesFn$]="getNotes$"
						[addNotesFn$]="addNotesPCI$"
						[deleteNotesFn$]="deleteNotes$"
					></app-form-person>
				</tab>
				<!-- Secondary Client Individual -->
				<tab heading="Secondary Client" id="SecondaryClientTab">
					<ng-template
						ngFor
						let-sci
						[ngForOf]="secondaryClients$ | async"
						let-i="index"
						[ngForTrackBy]="trackbyFn"
					>
						<app-form-secondary
							formID="sci_{{ i }}"
							[class.d-none]="secondaryClient?.customerID !== sci?.customerID"
							[isLead]="isLead"
							[secondaryClient]="sci"
							[addMode]="false"
							[isEdit]="false"
							[isSaving]="false"
							[title]="title"
							[employment]="employment"
							[relationship]="relationship"
							[professionalContacts]="professionalContacts"
							[contactMethods]="preferredContact"
							[industry]="industry"
							[primaryClient]="primaryClient"
							[businessConfig$]="businessConfig$"
							[deleteFn$]="deleteSecondary$"
							[updateFn$]="updateSecondary$"
							[copySCIFn$]="copySecondary$"
							[addPhoto$]="addPhoto$"
							[getNotesFn$]="getNotes$"
							[addNotesFn$]="addNotesSCI$"
							[deleteNotesFn$]="deleteNotes$"
							[transferSCIFn$]="transferSCI$"
						></app-form-secondary>
					</ng-template>
				</tab>
				<!-- Add Secondary Client Individual -->
				<tab heading="Add New Contact" id="AddNewSecondaryTab">
					<ng-container *ngIf="isAddPerson">
						<app-form-secondary
							formID="sci_add"
							[isLead]="isLead"
							[primaryClient]="primaryClient"
							[secondaryClient]="secondaryClient"
							[businessConfig$]="businessConfig$"
							[addMode]="true"
							[isEdit]="true"
							[isSaving]="false"
							[disableDelete]="false"
							[title]="title"
							[employment]="employment"
							[relationship]="relationship"
							[professionalContacts]="professionalContacts"
							[contactMethods]="preferredContact"
							[industry]="industry"
							[addPhoto$]="uploadPhoto$"
							[saveFn$]="saveSecondaryClient$"
							(cancelEvent)="cancelAddSecondaryClient($event)"
						></app-form-secondary>
					</ng-container>
				</tab>
				<!-- Secondary Business -->
				<tab heading="Secondary Business" id="SecondaryBusinessTab">
					<ng-template
						ngFor
						let-b
						[ngForOf]="secondaryBusinesses$ | async"
						let-i="index"
						[ngForTrackBy]="trackbyFn"
					>
						<app-form-business
							formID="pcc_{{ i }}"
							[class.d-none]="secondaryBusiness?.customerID !== b?.customerID"
							[isLead]="isLead"
							[business]="b"
							[getNotesFn$]="getNotes$"
							[addNotesFn$]="addNotesPCC$"
							[deleteNotesFn$]="deleteNotes$"
						></app-form-business>
					</ng-template>
				</tab>
				<!-- Secondary Trust -->
				<tab heading="Secondary Trust" id="SecondaryTrustTab">
					<ng-template
						ngFor
						let-sct
						[ngForOf]="secondaryTrusts$ | async"
						let-i="index"
						[ngForTrackBy]="trackbyFn"
					>
						<app-form-trust
							formID="sct_{{ i }}"
							[class.d-none]="secondaryTrust?.customerID !== sct?.customerID"
							[isLead]="isLead"
							[secondaryTrust]="sct"
							[addMode]="false"
							[isEdit]="false"
							[trustTypes]="trustTypes"
							[updateFn$]="updateSecondaryTrust$"
							[deleteFn$]="deleteSecondaryTrust$"
							[getNotesFn$]="getNotes$"
							[addNotesFn$]="addNotesSCT$"
							[deleteNotesFn$]="deleteNotes$"
						></app-form-trust>
					</ng-template>
				</tab>
				<!-- Add Secondary Trust -->
				<tab heading="Add New Contact" id="AddNewTrustTab">
					<ng-container *ngIf="isAddTrust">
						<app-form-trust
							formID="sct_add"
							[isLead]="isLead"
							[secondaryTrust]="secondaryTrust"
							[addMode]="true"
							[isEdit]="true"
							[disableDelete]="false"
							[trustTypes]="trustTypes"
							[addFn$]="addSecondaryTrust$"
							(cancelEvent)="cancelAddNewTrust($event)"
						></app-form-trust>
					</ng-container>
				</tab>

				<!-- Secondary Professional Contacts -->
				<tab heading="Professional Contact" id="SecondaryProfessionalTab">
					<ng-template
						ngFor
						let-scp
						[ngForOf]="secondaryProfessionlContacts$ | async"
						let-i="index"
						[ngForTrackBy]="trackbyFn"
					>
						<app-form-professional
							formID="scp_{{ i }}"
							[class.d-none]="
								secondaryProfessionalContact?.customerID !== scp?.customerID
							"
							[isLead]="isLead"
							[addMode]="false"
							[isEdit]="false"
							[secondaryProfessional]="scp"
							[professionalTypes]="professionalTypes"
							[updateFn$]="updateSecondaryProfessional$"
							[deleteFn$]="deleteSecondaryProfessional$"
							[getNotesFn$]="getNotes$"
							[addNotesFn$]="addNotesSCPC$"
							[deleteNotesFn$]="deleteNotes$"
						></app-form-professional>
					</ng-template>
				</tab>
				<tab heading="Add New Professional Contact" id="AddNewProfessionalTab">
					<ng-container *ngIf="isAddProfessional">
						<app-form-professional
							formID="scp_add"
							[isLead]="isLead"
							[addMode]="true"
							[isEdit]="true"
							[disableDelete]="false"
							[secondaryProfessional]="secondaryProfessionalContact"
							[professionalTypes]="professionalTypes"
							[addFn$]="addSecondaryProfessional$"
							(cancelEvent)="cancelAddProfessional($event)"
						></app-form-professional>
					</ng-container>
				</tab>

				<!-- Linked Contacts -->
				<tab heading="Linked Contacts" id="LinkedContactsTab">
					<ng-template
						ngFor
						let-lc
						[ngForOf]="linkedContacts$ | async"
						let-i="index"
						[ngForTrackBy]="trackbyFnContacts"
					>
						<app-form-linked-contact
							[formID]="i"
							[linkedContact]="lc"
							[relationship]="relationship"
							[preferredContact]="preferredContact"
						></app-form-linked-contact>
					</ng-template>

					<ng-container *ngIf="!(linkedContacts$ | async)?.length">
						<div class="mt-5"></div>
					</ng-container>

					<ng-container *ngIf="isAddLinkedContact">
						<form [formGroup]="linkedContactForm" novalidate>
							<div class="container-fluid clearfix px-0">
								<div class="form-row clearfix align-items-center mb-1">
									<div class="col-lg-3 col-12">
										<div class="form-row align-items-center">
											<!-- <div class="col-lg-4 col-12">
												<label for="primaryContact">Name</label>
											</div> -->
											<div class="col-lg col-12">
												<app-client-search-control
													id="primaryContact"
													ngProjectAs="input"
													textboxClass="theme-crm-field-disabled"
													formControlName="primaryContact"
													[showDeceased]="true"
													searchMode="LinkedContacts"
												></app-client-search-control>
											</div>
										</div>
									</div>
									<div class="col-lg-4 col-12">
										<div class="form-row">
											<div class="col-lg col-12">
												<div class="form-row align-items-center">
													<div class="col-lg-3 col-12">
														<label for="relationship">Relationship</label>
													</div>
													<div class="col-lg-9 col-12">
														<span class="custom-dropdown w-100">
															<select
																formControlName="relationship"
																class="form-control theme-crm-field-disabled"
																id="relationship"
																tabindex="12"
																[ngClass]="{ disabled: isSavingLinkedContact }"
															>
																<option value=""></option>
																<option
																	*ngFor="let d of relationship"
																	[value]="d.value"
																>
																	{{ d.display }}
																</option>
															</select>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-3 col-12">
										<div class="form-row align-items-center">
											<span
												class="position-relative"
												*ngIf="isSavingLinkedContact"
											>
												<app-manual-loader-mini
													[isLoading]="isSavingLinkedContact"
													class="p-0"
												></app-manual-loader-mini>
											</span>
											<ng-container *ngIf="!isSavingLinkedContact">
												<span class="position-relative">
													<button
														type="button"
														class="icon-btn"
														id="save"
														(click)="saveLinkedContact()"
														[disabled]="
															!linkedContactForm.valid || LinkedContactExists
														"
													>
														<i class="material-icons md-20 tap-text-color-h6">
															save
														</i>
													</button>
												</span>
												<span class="position-relative">
													<button
														type="button"
														id="cancel"
														class="icon-btn"
														(click)="cancelAddLinkedContact()"
													>
														<i class="material-icons md-20 tap-text-color-h6">
															cancel
														</i>
													</button>
												</span>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</form>
					</ng-container>

					<button
						id="addLinkedContact"
						class="tap-btn tap-btn-outline border-0 tap-text-primary px-1 col-auto mb-2"
						[disabled]="isAddLinkedContact"
						(click)="isAddLinkedContact = true"
					>
						Add Linked Contact +
					</button>
				</tab>
			</tabset>
		</div>
	</div>
</div>
