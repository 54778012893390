<form
	[formGroup]="form"
	class="form monthly-expenses-form"
	[class.submitted]="submitted && Completed?.valid"
>
	<div class="form-row" *ngIf="isListLoading; else showForm">
		<div class="col-12 col-lg-4 mb-2 text-lg-right text-left"></div>
		<div class="col-12 col-lg-3 mb-2">
			<span class="d-block">
				<app-manual-loader-mini
					[isLoading]="isListLoading"
				></app-manual-loader-mini>
			</span>
		</div>
	</div>

	<ng-template #showForm>
		<!-- Property & Loan Expenses -->
		<div class="form-row">
			<div class="col-lg-4 pl-10 mb-2">
				<label for="" class="main-content-section__header">
					Property & Loan Expenses
				</label>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div class="col-12 col-lg-4 mb-2"></div>
			<div class="col-12 col-lg-3 mb-2"></div>
			<div
				class="col-12 offset-lg-1 col-lg-3 mb-2 text-center d-flex justify-content-center align-items-center"
			>
				<label class="font-weight-bold" style="margin-left: -30px"
					>Not continuing</label
				>
			</div>
		</div>

		<!-- Rent (now) -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="How much rent are you paying now?"
					>Rent (now)</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="rentNow"
						class="form-control crt-form-control"
						formControlName="rentNow"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="rentNowFrequency"
						class="form-control crt-form-control"
						formControlName="rentNowFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleRentNow"
						formControlName="rentNowNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleRentNow"></label>
				</div>
			</div>
		</div>

		<!-- Rent (future) -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label
					class=""
					tooltip="How much rent will you pay after this transaction?"
					>Rent (future)</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="rentFuture"
						class="form-control crt-form-control"
						formControlName="rentFuture"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="rentFutureFrequency"
						class="form-control crt-form-control"
						formControlName="rentFutureFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleRentFuture"
						formControlName="rentFutureNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleRentFuture"></label>
				</div>
			</div>
		</div>

		<!-- Mortgage -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="tap-text-primary">Mortgage</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="mortgagePayments"
						class="form-control crt-form-control"
						type="text"
						formControlName="mortgagePayments"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
						(focusout)="onChangeCompute()"
					/>
					<i>$</i>
				</div>
				<!-- .dollar-icon -->
			</div>
			<div class="col-12 col-lg-2">
				<span class="custom-dropdown w-100">
					<select
						id="mortgagePaymentsFrequency"
						class="form-control crt-form-control"
						formControlName="mortgagePaymentsFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleMortgagePayments"
						formControlName="mortgagePaymentsNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleMortgagePayments"
					></label>
				</div>
			</div>
		</div>

		<!-- Body Corporate -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Body Corporate"
					>Body Corporate</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="bodyCorporate"
						class="form-control crt-form-control"
						formControlName="bodyCorporate"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="bodyCorporateFrequency"
						class="form-control crt-form-control"
						formControlName="bodyCorporateFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleBodyCorporate"
						formControlName="bodyCorporateNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleBodyCorporate"></label>
				</div>
			</div>
		</div>

		<!-- Owner Occupied Rates -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="tap-text-primary">Owner Occupied Rates</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="ownerOccupiedRates"
						class="form-control crt-form-control"
						type="text"
						formControlName="ownerOccupiedRates"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
						(focusout)="onChangeCompute()"
					/>
					<i>$</i>
				</div>
				<!-- .dollar-icon -->
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="ownerOccupiedRatesFrequency"
						class="form-control crt-form-control"
						formControlName="ownerOccupiedRatesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleOwnerOccupiedRates"
						formControlName="ownerOccupiedRatesNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleOwnerOccupiedRates"
					></label>
				</div>
			</div>
		</div>

		<!-- Investment Rates -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="tap-text-primary">Investment Rates</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="investmentRates"
						class="form-control crt-form-control"
						type="text"
						formControlName="investmentRates"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
						(focusout)="onChangeCompute()"
					/>
					<i>$</i>
				</div>
				<!-- .dollar-icon -->
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="investmentRatesFrequency"
						class="form-control crt-form-control"
						formControlName="investmentRatesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleInvestmentRates"
						formControlName="investmentRatesNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleInvestmentRates"
					></label>
				</div>
			</div>
		</div>

		<!-- Personal Debt -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class="tap-text-primary"> Personal Debt </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="personalLoan"
						class="form-control crt-form-control"
						type="text"
						formControlName="personalDebt"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
						(focusout)="onChangeCompute()"
					/>
					<i>$</i>
				</div>
				<!-- .dollar-icon -->
			</div>
			<div class="col-12 col-lg-2">
				<span class="custom-dropdown w-100">
					<select
						id="personalDebtFrequency"
						class="form-control crt-form-control"
						formControlName="personalDebtFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="togglePersonalDebt"
						formControlName="personalDebtNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="togglePersonalDebt"></label>
				</div>
			</div>
		</div>

		<!-- Health & Insurance Expenses -->
		<div class="form-row">
			<div class="col-lg-4 pl-10 mb-2">
				<label for="" class="main-content-section__header">
					Health & Insurance Expenses
				</label>
			</div>
		</div>

		<!-- Health & Medical Care -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Doctors, dentist, prescriptions, physio"
					>Health & Medical Care</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="healthMedicalCare"
						class="form-control crt-form-control"
						formControlName="healthMedicalCare"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="healthMedicalCareFrequency"
						class="form-control crt-form-control"
						formControlName="healthMedicalCareFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleHealthMedicalCare"
						formControlName="healthMedicalCareNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleHealthMedicalCare"
					></label>
				</div>
			</div>
		</div>

		<!-- Household & Contents Insurance -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Owner Occupied"
					>House & Contents Insurance</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="householdContentsInsurance"
						class="form-control crt-form-control"
						formControlName="householdContentsInsurance"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="householdContentsInsuranceFrequency"
						class="form-control crt-form-control"
						formControlName="householdContentsInsuranceFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleHouseholdContentsInsurance"
						formControlName="householdContentsInsuranceNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleHouseholdContentsInsurance"
					></label>
				</div>
			</div>
		</div>

		<!-- House Insurance -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Investment">Investment House Insurance</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="houseInsurance"
						class="form-control crt-form-control"
						formControlName="houseInsurance"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="houseInsuranceFrequency"
						class="form-control crt-form-control"
						formControlName="houseInsuranceFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleHouseInsurance"
						formControlName="houseInsuranceNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleHouseInsurance"
					></label>
				</div>
			</div>
		</div>

		<!-- Vehicle Insurance -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Cars, boats, trailers, jet skis, motorcycles"
					>Vehicle Insurance</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="vehicleInsurance"
						class="form-control crt-form-control"
						formControlName="vehicleInsurance"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="vehicleInsuranceFrequency"
						class="form-control crt-form-control"
						formControlName="vehicleInsuranceFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleVehicleInsurance"
						formControlName="vehicleInsuranceNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleVehicleInsurance"
					></label>
				</div>
			</div>
		</div>

		<!-- Health/Medical/Funeral/Pet Insurance -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="">Health/Medical/Funeral/Pet Insurance</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="hMFPInsurance"
						class="form-control crt-form-control"
						formControlName="hMFPInsurance"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="hMFPInsuranceFrequency"
						class="form-control crt-form-control"
						formControlName="hMFPInsuranceFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleHMFPInsurance"
						formControlName="hMFPInsuranceNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleHMFPInsurance"></label>
				</div>
			</div>
		</div>

		<!-- Life & Risk Insurance -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Life, Trauma, Income, Disability"
					>Life & Risk Insurance</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="lRInsurance"
						class="form-control crt-form-control"
						formControlName="lRInsurance"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="lRInsuranceFrequency"
						class="form-control crt-form-control"
						formControlName="lRInsuranceFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleLRInsurance"
						formControlName="lRInsuranceNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleLRInsurance"></label>
				</div>
			</div>
		</div>

		<!-- Household & Family Expenses -->
		<div class="form-row">
			<div class="col-lg-4 pl-10 mb-2">
				<label for="" class="main-content-section__header">
					Household & Family Expenses
				</label>
			</div>
		</div>

		<!-- Utilities -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Power, phone, internet, gas, water"
					>Utilities</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="utilities"
						class="form-control crt-form-control"
						formControlName="utilities"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="utilitiesFrequency"
						class="form-control crt-form-control"
						formControlName="utilitiesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleUtilities"
						formControlName="utilitiesNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleUtilities"></label>
				</div>
			</div>
		</div>

		<!-- Groceries -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Household items, food, meat, vegetables, etc"
					>Groceries</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="groceries"
						class="form-control crt-form-control"
						formControlName="groceries"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="groceriesFrequency"
						class="form-control crt-form-control"
						formControlName="groceriesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleGroceries"
						formControlName="groceriesNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleGroceries"></label>
				</div>
			</div>
		</div>

		<!-- Ongoing Household Expenses -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Regular Maintenance, lawn-mowing, cleaning"
					>Ongoing Household Expenses</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="ongoingHouseholdExpenses"
						class="form-control crt-form-control"
						formControlName="ongoingHouseholdExpenses"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="ongoingHouseholdExpensesFrequency"
						class="form-control crt-form-control"
						formControlName="ongoingHouseholdExpensesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleOngoingHouseholdExpenses"
						formControlName="ongoingHouseholdExpensesNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleOngoingHouseholdExpenses"
					></label>
				</div>
			</div>
		</div>

		<!-- Childcare -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label
					class=""
					tooltip="Daycare, after school care, babysitting, Au Pair"
					>Childcare</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="childCare"
						class="form-control crt-form-control"
						formControlName="childCare"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="childCareFrequency"
						class="form-control crt-form-control"
						formControlName="childCareFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleChildCare"
						formControlName="childCareNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleChildCare"></label>
				</div>
			</div>
		</div>

		<!-- Child Support / Maintenance -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="What you pay to someone else"
					>Child Support / Maintenance</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="childSupportMaintenance"
						class="form-control crt-form-control"
						formControlName="childSupportMaintenance"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="childSupportMaintenanceFrequency"
						class="form-control crt-form-control"
						formControlName="childSupportMaintenanceFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleChildSupportMaintenance"
						formControlName="childSupportMaintenanceNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleChildSupportMaintenance"
					></label>
				</div>
			</div>
		</div>

		<!-- Personal Care & Clothing -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Clothes, footwear, hairdresser, etc."
					>Personal Care & Clothing</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="personalCareClothing"
						class="form-control crt-form-control"
						formControlName="personalCareClothing"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="personalCareClothingFrequency"
						class="form-control crt-form-control"
						formControlName="personalCareClothingFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="togglePersonalCareClothing"
						formControlName="personalCareClothingNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="togglePersonalCareClothing"
					></label>
				</div>
			</div>
		</div>

		<!-- Public Education -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Fees and donations">Public Education</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="publicEducation"
						class="form-control crt-form-control"
						formControlName="publicEducation"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="publicEducationFrequency"
						class="form-control crt-form-control"
						formControlName="publicEducationFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="togglePublicEducation"
						formControlName="publicEducationNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="togglePublicEducation"
					></label>
				</div>
			</div>
		</div>

		<!-- Private Education -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Fees and donations">Private Education</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="privateEducation"
						class="form-control crt-form-control"
						formControlName="privateEducation"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="privateEducationFrequency"
						class="form-control crt-form-control"
						formControlName="privateEducationFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="togglePrivateEducation"
						formControlName="privateEducationNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="togglePrivateEducation"
					></label>
				</div>
			</div>
		</div>

		<!-- Transport -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label
					class=""
					tooltip="Parking, Petrol/Diesel WOF, Rego, Servicing, Public Transport, Uber, etc."
					>Transport</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="transport"
						class="form-control crt-form-control"
						formControlName="transport"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="transportFrequency"
						class="form-control crt-form-control"
						formControlName="transportFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleTransport"
						formControlName="transportNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleTransport"></label>
				</div>
			</div>
		</div>

		<!-- Discretionary Spending -->
		<div class="form-row">
			<div class="col-lg-4 pl-10 mb-2">
				<label for="" class="main-content-section__header">
					Discretionary Spending
				</label>
			</div>
		</div>

		<!-- KiwiSaver Contribution -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label
					class=""
					tooltip="Additional KiwiSaver Contribution"
					>Additional KiwiSaver Contribution</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="kiwiSaverContribution"
						class="form-control crt-form-control"
						formControlName="kiwiSaverContribution"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="kiwiSaverContributionFrequency"
						class="form-control crt-form-control"
						formControlName="kiwiSaverContributionFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleKiwiSaverContribution"
						formControlName="kiwiSaverContributionNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleKiwiSaverContribution"
					></label>
				</div>
			</div>
		</div>

		<!-- Entertainment & Recreation -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label
					class=""
					tooltip="Pay TV, streaming, alcohol, restaurants, pubs/clubs/bars, takeaways, hobbies, gym membership, sports registrations"
					>Entertainment & Recreation</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="entertainmentRecreation"
						class="form-control crt-form-control"
						formControlName="entertainmentRecreation"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="entertainmentRecreationFrequency"
						class="form-control crt-form-control"
						formControlName="entertainmentRecreationFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleEntertainmentRecreation"
						formControlName="entertainmentRecreationNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label
						class="custom-control-label"
						for="toggleEntertainmentRecreation"
					></label>
				</div>
			</div>
		</div>

		<!-- Tithing -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="" tooltip="Regular religious / church donations"
					>Tithing</label
				>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="tithing"
						class="form-control crt-form-control"
						formControlName="tithing"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="tithingFrequency"
						class="form-control crt-form-control"
						formControlName="tithingFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
			</div>
			<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
				<div class="custom-control custom-checkbox pl-0">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="toggleTithing"
						formControlName="tithingNotContinuing"
						(change)="onChangeCompute()"
					/>
					<label class="custom-control-label" for="toggleTithing"></label>
				</div>
			</div>
		</div>

		<!-- Other / Expense List -->
		<div formArrayName="expenseList">
			<div
				class="form-row algin-items-center"
				*ngFor="let arrayItem of expenseList?.controls; let pIndex = index"
				[formGroupName]="pIndex"
			>
				<div class="d-lg-none text-right col-12 col-lg-2">
					<button
						id="deleteExpenseItemButton_{{ pIndex }}"
						type="button"
						class="icon-btn w-auto h-auto"
						(click)="deleteExpenseItem(pIndex)"
					>
						<i class="material-icons md-16">delete</i>
					</button>
				</div>
				<div class="col-12 offset-lg-1 col-lg-3 text-lg-right text-left mb-2">
					<input
						id="expense_{{ pIndex }}"
						class="form-control text-lg-right crt-form-control"
						type="text"
						formControlName="expenseField"
						placeholder="Expense"
						(focusout)="onChangeCompute()"
					/>
				</div>
				<div class="col-12 col-lg-3 mb-2">
					<div class="dollar-icon">
						<input
							id="expenseTitle_{{ pIndex }}"
							class="form-control crt-form-control"
							type="text"
							formControlName="expenseValue"
							placeholder="Amount"
							(focusout)="onChangeCompute()"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<span class="custom-dropdown col-md-12 p-0">
						<select
							id="selectExpense_{{ pIndex }}"
							class="form-control d-inline crt-form-control"
							formControlName="expenseFrequency"
							(focusout)="onChangeCompute()"
						>
							<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
								{{ f.display }}
							</option>
						</select>
					</span>
					<!-- <button
						id="deleteExpenseItemButton_{{ pIndex }}"
						type="button"
						class="d-none d-lg-inline d-md-none d-xs-none d-sm-none icon-btn w-auto h-auto position-absolute pt-2 delete-btn"
						(click)="deleteExpenseItem(pIndex)"
					>
						<i class="material-icons md-16">delete</i>
					</button> -->
				</div>
				<div class="col-12 col-lg-1 mb-2 delete-btn-cta pl-0">
					<button
						id="deleteExpenseItemButton_{{ pIndex }}"
						type="button"
						class="d-none d-lg-inline d-md-none d-xs-none d-sm-none icon-btn w-auto h-auto position-absolute pt-2 delete-btn pl-0"
						(click)="deleteExpenseItem(pIndex)"
					>
						<i class="material-icons md-16">delete</i>
					</button>
				</div>
				<div class="col-12 col-lg-1 mb-2 mt-1 text-left ml-3">
					<div class="custom-control custom-checkbox pl-0">
						<input
							type="checkbox"
							class="chckbox custom-control-input"
							id="toggleExpense_{{ pIndex }}"
							formControlName="expenseNotContinuing"
							(change)="onChangeCompute()"
						/>
						<label
							class="custom-control-label"
							for="toggleExpense_{{ pIndex }}"
						></label>
					</div>
				</div>
			</div>
			<div class="form-row algin-items-center">
				<div class="col-12 col-lg-4 text-lg-right text-left mb-2">
					<button
						id="addExpenseItemButton"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow col-auto p-1 px-4"
						(click)="addExpenseItem()"
					>
						Add Expense +
					</button>
				</div>
				<div class="col-12 col-lg-3"></div>
				<div class="col-12 col-lg-2"></div>
				<div class="col-12 col-lg-2"></div>
			</div>
		</div>

		<!-- TOTAL -->

		<div class="form-row">
			<div class="col-lg-4 pl-10 mb-2">
				<label for="" class="main-content-section__header"> Total </label>
			</div>
		</div>

		<!-- Total Monthly Expenses -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="tap-text-primary">Total Monthly Expenses:</label>
			</div>
			<div class="col-12 col-lg-3 mb-2 align-items-center d-lg-flex">
				<input
					id="totalExpenses"
					class="form-control crt-form-control"
					type="text"
					value="{{ TotalExpenses.value | currency }}"
					disabled
				/>
			</div>
			<div
				class="col-12 col-lg-3 text-right-lg mb-2 align-items-center d-lg-flex"
			>
				<label class="tap-text-primary"
					>Total Monthly Expenses (less discontinued expenses):</label
				>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<input
					id="totalExpenses"
					class="form-control crt-form-control"
					type="text"
					value="{{ TotalMinusDiscontinued.value | currency }}"
					disabled
				/>
			</div>
		</div>

		<!-- Mpmthly Income After Expenses -->
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="tap-text-primary">Monthly Income After Expenses:</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<input
					id="totalExpenses"
					class="form-control crt-form-control"
					type="text"
					value="{{ MonthlyIncomeAfterExpenses.value | currency }}"
					disabled
				/>
			</div>
		</div>
	</ng-template>
</form>
