import {
	BlStaffModel,
	BlStaffModelEdit,
	BlStaffModelAdd,
	BlStaffRequestModel,
} from '../../../shared/models/_general/bl-staff.model';
import { Moment } from 'moment';
import { util } from '../../../core/util/util.service';

export const defaultConversions = {
	lr_conversion: '35',
	kiwisaver_conversion: '30',
	blanket_conversion: '20',
	mortgage_conversion: '30',
};

export class BlStaffStatsViewModel {
	fsp_number: string;
	self_gen_quarter: string;
	api_quarter: string;
	self_gen_annual: string;
	api_annual: string;
	kiwisaver_quarter: string;
	kiwisaver_annual: string;
	mortgage_quarter: string;
	mortgage_annual: string;
	lr_conversion: string;
	kiwisaver_conversion: string;
	blanket_conversion: string;
	mortgage_conversion: string;
}
export class BlStaffViewmodel {
	/**
	 *
	 */
	constructor() {
		this.Services = [];
		this.Stats = new BlStaffStatsViewModel();
	}
	AdviserManager: number;
  AdviserServices: string[];
	BirthDate: Moment;
	BusinessPhone: string;
	EmailAddress: string;
	FirstName: string;
	HomeAddress: string;
	IsActive: number;
	LastName: string;
	MobilePhone: string;
	SecurityGroup: string;
	Services: string[];
	StaffID?: number;
	StartDate: Moment;
	CreationDate: Moment;
	AdviserIdsAssigned: number[];
	// stats: any[];
	Stats: BlStaffStatsViewModel;
	StaffSettings: any;

	BLSecurityGroups?: string[];
	WidgetOrder?: { code: string }[];
	UpdatedByStaffId?: number;
	StaffDetailValue?: any;
	SecurityRoles?: string[];
	RouteTo?: string;
	CalendarUrl?: string;
	CreatedByStaffId?: number;
	CreatedByStaffLevel?: number;


	static mapToViewmodel(m: BlStaffModel): BlStaffViewmodel {
		const blstaff = Object.assign<BlStaffStatsViewModel, BlStaffStatsViewModel>(
			new BlStaffStatsViewModel(),
			(m.Stats || [])?.reduce((p, c) => {
				return Object.assign(p, c);
			}, {})
		);
		const staffSettings = m.StaffSettings as any;
		return Object.assign(new BlStaffViewmodel(), {
			AdviserManager: m.AdviserManager,
			BirthDate: util.DateStringToMomentLoose(m.BirthDate),
			BusinessPhone: m.BusinessPhone,
			EmailAddress: m.EmailAddress,
			FirstName: m.FirstName,
			HomeAddress: m.HomeAddress,
			IsActive: m.IsActive,
			LastName: m.LastName,
			MobilePhone: m.MobilePhone,
			SecurityGroup: m.SecurityGroup,
			Services: m.Services || [],
			StaffID: m.StaffID,
			StartDate: util.DateStringToMomentLoose(m.StartDate),
			AdviserIdsAssigned: m.AdviserIdsAssigned || [],
			Stats: blstaff,
			StaffSettings: {
				...m.StaffSettings,
				ResignationDate: util.DateStringToMomentLoose(
					staffSettings?.ResignationDate
				),
				ExitDate: util.DateStringToMomentLoose(staffSettings?.ExitDate),
				FSPRegistrationDate: util.DateStringToMomentLoose(
					staffSettings?.FSPRegistrationDate
				),
				DeactivationDate: util.DateStringToMomentLoose(
					staffSettings?.DeactivationDate
				),
				ReactivationDate: util.DateStringToMomentLoose(
					staffSettings?.ReactivationDate
				),
			},
			UpdatedByStaffId: m.UpdatedByStaffId,
			StaffDetailValue: m.StaffDetailValue,
			SecurityRoles: m.SecurityRoles,
			RouteTo: m.RouteTo,
			CreatedByStaffId: m.CreatedByStaffId,
			CreatedByStaffLevel: m.CreatedByStaffLevel,
			CreationDate: util.DateStringToMomentLoose(m.CreationDate),
		} as BlStaffViewmodel);
	}

	static mapToModel(v: BlStaffViewmodel): BlStaffRequestModel {
		return {
			AdviserManager: v.AdviserManager,
			BirthDate: util.MomentToDateString(v.BirthDate),
			BusinessPhone: v.BusinessPhone,
			EmailAddress: v.EmailAddress,
			FirstName: v.FirstName,
			HomeAddress: v.HomeAddress,
			IsActive: isNaN(v.IsActive) ? v.IsActive : +v.IsActive,
			LastName: v.LastName,
			MobilePhone: v.MobilePhone,
			SecurityGroup: v.SecurityGroup,
			Services: JSON.stringify(v.Services),
			StartDate: util.MomentToDateString(v.StartDate),
			// AdviserIdsAssigned: (v.AdviserIdsAssigned || [])?.map(x => +x),
			// Stats: JSON.stringify(
			// 	Object.keys(v.Stats)?.map(x => {
			// 		return { [x]: v.Stats[x] };
			// 	})
			// ),
			StaffSettings: {
				...v.StaffSettings,
				ResignationDate: util.MomentToDateString(
					v.StaffSettings?.ResignationDate
				),
				ExitDate: util.MomentToDateString(v.StaffSettings?.ExitDate),
				FSPRegistrationDate: util.MomentToDateString(
					v.StaffSettings?.FSPRegistrationDate
				),
				DeactivationDate: util.MomentToDateString(
					v.StaffSettings?.DeactivationDate
				),
			},
			UpdatedByStaffId: v.UpdatedByStaffId,
			StaffDetailValue: v.StaffDetailValue,
			SecurityRoles: v.SecurityRoles,
			RouteTo: v.RouteTo,
			CreatedByStaffId: v.CreatedByStaffId,
			CreatedByStaffLevel: v.CreatedByStaffLevel,
			CreationDate: util.MomentToDateString(v.CreationDate),
		};
	}

	static mapToAddModel(v: BlStaffViewmodel): BlStaffModelAdd {
		return this.mapToModel(v);
	}
	static mapToEditModel(v: BlStaffViewmodel): BlStaffModelEdit {
		const model: BlStaffModelEdit = Object.assign(this.mapToModel(v));
		model.StaffID = v.StaffID;
		return model;
	}
}
