import { TlMobileSettingsComponent } from './tl-mobile-settings.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TlMobileSettingsRoutingModule } from './tl-mobile-settings-routing.module';
import { SharedModule } from '@shared/shared.module';


@NgModule({
  declarations: [TlMobileSettingsComponent],
  imports: [
    CommonModule,
    TlMobileSettingsRoutingModule,
		SharedModule
  ]
})
export class TlMobileSettingsModule { }
