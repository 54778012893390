import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iif, Observable, of, Subject } from 'rxjs';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import {
	AddOtherIncomeObj,
	OtherIncomeObjState,
	OtherIncomeState,
} from '../../../../../shared/models/client-review-template/income-budget/other-income.model';
import { OtherIncomeMapper } from '../../../../../shared/models/client-review-template/income-budget/other-income.mapper';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { NoWhitespaceValidator } from '../../../../../shared/directive/no-whitespace/no-whitespace.directive';
import { FactFindComputationState } from '../../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import {
	computeAnnualTaxable,
	computeMonthlyTaxable,
	computeMonthlyAfterTaxIncome,
} from '../../../../../modules/crm/client-review-template/income-budget/calculations/compute-taxable';
import { IncomeSourceState } from '../../../../../shared/models/client-review-template/income-budget/income-source-details.model';
import {
	filter,
	finalize,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { numUtil } from '../../../../../util/util';
import { find, filter as Rfilter, propEq } from 'ramda';
import { IncomeService } from '../../states/income-budget/income.service';
import { Fields, getInvalidWarning } from 'src/app/shared/error-message/error-message';

declare var $: any;

@Component({
	selector: 'app-other-income',
	templateUrl: './other-income.component.html',
	styleUrls: ['./other-income.component.scss'],
})
export class OtherIncomeComponent implements OnInit, OnDestroy {
	@Input() isAdviceProcessEnded: boolean;
	@Input() otherIncome$: Observable<OtherIncomeObjState>;
	@Input() updateFn$: (
		otherIncome: OtherIncomeState
	) => Observable<OtherIncomeState>;
	@Input() addNewFn$: (
		otherIncome: OtherIncomeState
	) => Observable<OtherIncomeState>;
	@Input() deleteFn$: (otherIncome: number) => Observable<number>;
	@Input() factFindComputation$: Observable<FactFindComputationState>;
	@Input() incomeSource$: Observable<IncomeSourceState[]>;

	onDestroy$ = new Subject<void>();

	elseMinusOthers = true;
	totalAnnual = 0;
	form: UntypedFormGroup;
	factFind: FactFindComputationState = {};
	incomeList: IncomeSourceState[];
	isListLoading = true;
	tempData = [];
	isEdit = false;
	isAdd = false;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private loggerService: LoggerService,
		private incomeService: IncomeService,
		private cdr: ChangeDetectorRef,
	) {
		this.buildForm();
	}

	get otherArray() {
		return this.form.get('otherArray') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.prepData();

		this.incomeSource$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => (this.incomeList = data));
	}

	buildForm() {
		this.form = this.fb.group({
			otherArray: this.fb.array([]),
		});
	}

	prepData() {
		this.otherIncome$
			.pipe(
				filter((data) => !!data),
				finalize(() => {
					this.isListLoading = false;
					this.cdr.detectChanges();
				}),
				take(1)
			)
			.subscribe((x: OtherIncomeObjState) => {
				this.form.reset();
				OtherIncomeMapper.mapOtherIncome(x)?.map((otherIncome) =>
					this.addOther(otherIncome)
				);
				this.form.disable();
			});

		this.factFindComputation$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.totalAnnual = +data?.totalAnnualIncome;
				this.factFind = data;
			});
	}

	addNewOtherIncome() {
		this.isEdit = false;
		this.isAdd = true;
		this.addOther(null, true);
	}

	addOther(data?: AddOtherIncomeObj, isAdd: boolean = false) {
		this.otherArray?.push(
			this.fb.group({
				cRTId: [(data && data.cRTId) || ''],
				adviceProcessId: [(data && data.adviceProcessId) || ''],
				incomeType: [
					(data && data.incomeType) || '',
					[Validators.required, NoWhitespaceValidator],
				],
				annualIncome: [
					(data && data.annualIncome) || '',
					[Validators.required, Validators.min(0.01)],
				],
				btnSaveOther: isAdd,
				btnEditOther: !isAdd,
				isNew: isAdd,
				isLoading: [false],
			})
		);
	}

	saveOther(index: number) {
		if (this.otherArray?.controls[index].invalid) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.Income));
			return;
		}

		const isNew = this.otherArray.controls[index].get('isNew').value;
		const adviceProcessId = parseInt(
			this.route.snapshot.paramMap.get('adviceProcessId'),
			10
		);
		const cRTId = !isNew
			? +this.otherArray.controls[index].get('cRTId').value
			: null;

		const tempFactFind = {
			...this.factFind,
			...this.recompute(
				index,
				this.otherArray.controls[index].get('annualIncome').value,
				this.factFind
			),
		};

		const data = OtherIncomeMapper.mapToUpsert(
			this.otherArray.at(index).value,
			cRTId,
			adviceProcessId,
			tempFactFind
		);

		this.otherArray.controls[index].get('isLoading').setValue(true);

		of(data)
			.pipe(
				mergeMap((val) =>
					iif(() => isNew, this.addNewFn$(val), this.updateFn$(val))
				),
				tap((i) => {
					if (isNew) {
						this.otherArray.controls[index].get('cRTId').setValue(i);
					}
					this.setDisabled(index);
				}),
				finalize(() => {
					this.patchTempData(index);
					this.otherArray.controls[index].get('isLoading').setValue(false);
					this.resetEditAddMode();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	editOther(index: number) {
		this.isEdit = true;
		this.isAdd = false;
		this.addTempData(index);
		for (let i = 0; i < this.otherArray?.length; i++) {
			if (index === i) {
				this.setEdit(index);
			} else {
				this.setDisabled(i);
			}
		}
	}

	deleteOther(index: number) {
		this.otherArray.controls[index].get('isLoading').setValue(true);

		this.deleteFn$(+this.otherArray.controls[index].get('cRTId').value)
			.pipe(
				finalize(() => {
					this.otherArray.removeAt(index);
					this.resetEditAddMode();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	deleteNewOther(index: number) {
		this.otherArray.removeAt(index);
		this.resetEditAddMode();
	}

	recompute(
		index: number,
		newValue: number,
		factFind: FactFindComputationState
	) {
		let tempTotalAnnualIncome = 0;
		this.otherArray.controls?.map((rental: UntypedFormGroup, i: number) => {
			if (index === i) {
				tempTotalAnnualIncome += +newValue;
			} else {
				tempTotalAnnualIncome += +rental.value.annualIncome || 0;
			}
		});
		const annualTaxable = computeAnnualTaxable({
			...factFind,
			totalAnnualIncome: tempTotalAnnualIncome,
		});
		const monthlyTaxable = computeMonthlyTaxable(+annualTaxable);
		const monthlyAfterTax = computeMonthlyAfterTaxIncome(
			this.incomeList,
			{
				...factFind,
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
			},
			this.incomeService.getIncomeLists()
		);

		const obj = {
			totalAnnualIncome: +numUtil.formatToNumCurrency(tempTotalAnnualIncome),
			annualTaxableJointIncome: +annualTaxable,
			monthlyTaxableJointIncome: +monthlyTaxable,
			monthlyAfterTaxIncome: +monthlyAfterTax,
		};

		return obj;
	}

	addTempData(i: number) {
		const currentValue = this.form.getRawValue()?.otherArray[i];
		if (currentValue) {
			this.tempData?.push(currentValue);
		}
	}

	patchTempData(i: number, allowPatch?: boolean) {
		const currentValue = this.form.getRawValue()?.otherArray[i];
		const tempValue = find(propEq('cRTId', currentValue?.cRTId))(
			this.tempData
		);
		if (allowPatch) {
			this.otherArray.controls[i].patchValue(tempValue);
		}
		if (tempValue) {
			this.tempData = Rfilter(
				(val) => +val?.cRTId !== +currentValue?.cRTId,
				this.tempData
			);
		}
	}

	cancelEdit(i: number) {
		this.patchTempData(i, true);
		this.setDisabled(i);
		this.resetEditAddMode();
	}

	resetEditAddMode() {
		this.isEdit = false;
		this.isAdd = false;
	}

	setDisabled(i: number) {
		this.otherArray.controls[i].get('btnSaveOther').setValue(false);
		this.otherArray.controls[i].get('btnEditOther').setValue(true);
		this.otherArray.controls[i].get('isNew').setValue(false);
		this.otherArray.controls[i].get('incomeType').disable();
		this.otherArray.controls[i].get('annualIncome').disable();
	}

	setEdit(i: number) {
		this.otherArray.controls[i].get('btnSaveOther').setValue(true);
		this.otherArray.controls[i].get('btnEditOther').setValue(false);
		this.otherArray.controls[i].get('incomeType').enable();
		this.otherArray.controls[i].get('annualIncome').enable();
	}

	collapseMoreOthers() {
		$('#collapseOthers').toggle();
		this.elseMinusOthers = false;
	}

	collapseLessOthers() {
		$('#collapseOthers').toggle();
		this.elseMinusOthers = true;
	}

	collapseFalse() {
		this.elseMinusOthers = false;
	}

	defaultShow() {
		$('#collapseOthers').css('display', 'block');
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
