import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TlUserRoutingModule } from './tl-user-routing.module';
import { UserComponentsModule } from '../user-components/user-components.module';
import { TlAddComponent } from '../../views/tl/tl-add/tl-add.component';
import { TlEditComponent } from '../../views/tl/tl-edit/tl-edit.component';
import { TlListComponent } from '../../views/tl/tl-list/tl-list.component';
import { TlViewComponent } from '../../views/tl/tl-view/tl-view.component';
import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    TlUserRoutingModule,
    SharedModule,
    UserComponentsModule
  ],
  declarations: [
    TlAddComponent,
    TlEditComponent,
    TlListComponent,
    TlViewComponent
  ]
})
export class TlUserModule { }
