<!-- default form -->
<div #formProp class="pb-50px">
	<div class="row align-items-center mb-3">
		<div class="col-auto">
			<strong>{{
				groupName +
					(mortgage?.loanNumberSuffix
						? " - " + mortgage.loanNumberSuffix
						: "") +
					(mortgage?.fixedPeriodEndDate
						? " (Fixed Until: " + (mortgage?.fixedPeriodEndDate | momentDatetime) + ")"
						: "")
			}}</strong>
		</div>
		<div class="col">
			<div class="d-flex flex-row bd-highlight justify-content-end">
				<span *ngIf="isSaving">
					<div class="d-flex flex-row bd-highlight justify-content-end">
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isSaving"
						></app-manual-loader-mini>
					</div>
				</span>

				<!-- Save Service -->
				<ng-container *appRestrictTo="['FES']">
					<div *ngIf="(isEditForm || addMode) && !isSaving">
						<span class="position-relative">
							<button type="submit" class="icon-btn" (click)="save(false)">
								<i class="material-icons md-20 tap-text-color-h6"> save </i>
							</button>
						</span>
						<span class="position-relative">
							<button type="cancel" class="icon-btn" (click)="cancel()">
								<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
							</button>
						</span>
					</div>
				</ng-container>

				<ng-container
					*ngIf="!addMode && !isEditForm && !isSaving"
					class="d-flex flex-row bd-highlight justify-content-end"
				>
					<!-- Transfer Service -->
					<ng-container *appRestrictTo="['FES']">
						<span *ngIf="mortgage.mortgageType !== 'Primary'">
							<button
								data-toggle="tooltip"
								title="split"
								class="icon-btn"
								type="button"
								(click)="transferGroup()"
								id="mortgageTransfer_{{ formId }}"
							>
								<i class="material-icons md-20 pt-1 tap-text-color-h6">redo</i>
							</button>
						</span>
					</ng-container>

					<!-- Archive Service -->
					<ng-container *appRestrictTo="['FARS']">
						<span *ngIf="mortgage.mortgageType !== 'Primary'">
							<button
								*ngIf="mortgage.isActive === 1"
								class="icon-btn"
								type="button"
								(click)="archive(true)"
								id="mortgageArchive_{{ formId }}"
							>
								<i
									data-toggle="tooltip"
									title="archive"
									class="material-icons md-20 tap-text-color-h6 pt-1"
								>
									archive
								</i>
							</button>
							<button
								*ngIf="mortgage.isActive !== 1"
								class="icon-btn"
								type="button"
								(click)="archive(false)"
								id="mortgageUnarchive_{{ formId }}"
							>
								<i
									data-toggle="tooltip"
									title="unarchive"
									class="material-icons md-20 tap-text-color-h6 pt-1"
								>
									unarchive
								</i>
							</button>
						</span>
					</ng-container>

					<!-- Edit Service -->
					<ng-container *appRestrictTo="['FES']">
						<span>
							<button
								data-toggle="tooltip"
								title="edit"
								class="icon-btn"
								type="button"
								(click)="editForm(true, false)"
								id="mortgageEdit_{{ formId }}"
							>
								<i class="material-icons md-20 tap-text-color-h6 pt-1">
									edit
								</i>
							</button>
						</span>
					</ng-container>

					<!-- Delete Service -->
					<ng-container *appRestrictTo="['FDS']">
						<span>
							<button
								data-toggle="tooltip"
								title="delete"
								class="btn btn-link p-1 ng-star-inserted"
								type="button"
								(click)="deleteConfirm()"
								id="mortgageDelete_{{ formId }}"
							>
								<i class="material-icons md-20 tap-text-color-h6">  </i>
							</button>
						</span>
					</ng-container>
				</ng-container>

				<button
					type="button"
					class="btn-toggle collapse-btn"
					(click)="collapseForm.Toggle()"
					id="mortgageToggle_{{ formId }}"
				>
					<span
						*ngIf="
							collapseForm &&
								collapseForm.IsExpanded$ &&
								(collapseForm.IsExpanded$ | async);
							else elsePlus
						"
					>
						<i class="material-icons md-20 tap-text-color-h6 pt-1"
							>expand_less</i
						>
					</span>
					<ng-template #elsePlus>
						<span>
							<i class="material-icons md-20 tap-text-color-h6 pt-1"
								>expand_more</i
							>
						</span>
					</ng-template>
				</button>
			</div>
		</div>
	</div>

	<form novalidate [formGroup]="form" class="pb-3">
		<div
			class="container-fluid clearfix px-0"
			[class.archived]="
				mortgage && mortgage.isActive ? mortgage.isActive === 2 : false
			"
		>
			<!-- First Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12 label-padding-bottom-md">
							<label for="">Borrowing Entities</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="borrowingEntities"
								id="borrowingEntities_{{ formId }}"
								[choices]="borrowingEntitiesDropdown"
								tabindex="7"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Loan Value</label>
						</div>
						<div class="col-lg-6 col-12">
							<div class="dollar-icon">
								<input
									type="text"
									formControlName="loanValue"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="loanValue_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="16"
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Submitted Date</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#submittedDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="submitted"
								id="submitted_{{ formId }}"
								tabindex="23"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>

			<!-- Second Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg-6 col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-3 col-12 label-padding-bottom-md">
							<label for="">Security</label>
						</div>
						<div class="col-lg-9 col-12">
							<app-chips
								textboxClass="theme-crm-field"
								formControlName="security"
								[choices]="securities"
								id="security_{{ formId }}"
								tabindex="8"
							>
							</app-chips>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<span class="tap-required-field" *ngIf="loanNumber.invalid"
								>*</span
							>
							<label for="">Loan Number</label>
						</div>
						<div class="col-lg-6 col-12 form-inline">
							<input
								type="text"
								formControlName="loanNumber"
								class="form-control theme-crm-field-disabled w-59"
								placeholder="- -"
								id="loanNumber_{{ formId }}"
								tabindex="17"
							/>
							<span class="p-1 font-weight-bold text-dark">-</span>
							<input
								type="text"
								formControlName="loanNumberSuffix"
								placeholder="- -"
								class="form-control theme-crm-field-disabled px-2 w-30"
								id="labelLoanNumberSuffix_{{ formId }}"
								tabindex="18"
							/>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Approved</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#approvedDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="approved"
								id="approved_{{ formId }}"
								tabindex="24"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>
			<!-- Third Row -->
			<div class="form-row clearfix align-items-center mb-2">
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<span class="tap-required-field" *ngIf="status.invalid">*</span>
							<label for="">Status</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="status"
									class="form-control theme-crm-field-disabled"
									id="mstatus_{{ formId }}"
									tabindex="9"
								>
									<option value=""></option>
									<option
										*ngFor="let s of statuses; trackBy: trackByValue"
										[value]="s.value"
									>
										{{ s.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Refix Status</label>
							<!--For Reference-->
							<!-- <label for="">{{ businessName$ | async }} Status</label> -->
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="businessRefix"
									class="form-control theme-crm-field-disabled"
									id="businessRefix_{{ formId }}"
									tabindex="12"
								>
									<option value=""></option>
									<option
										*ngFor="let p of refixStatus; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<span class="tap-required-field" *ngIf="provider.invalid">*</span>
							<label for="">Provider</label>
						</div>
						<div class="col-lg-6 col-12">
							<span class="custom-dropdown w-100">
								<select
									formControlName="provider"
									class="form-control theme-crm-field-disabled"
									id="provider_{{ formId }}"
									tabindex="19"
								>
									<option value=""></option>
									<option
										*ngFor="let p of providers; trackBy: trackByValue"
										[value]="p.value"
									>
										{{ p.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="col-lg col-12">
					<div class="form-row align-items-center">
						<div class="col-lg-6 col-12 label-padding-bottom-md">
							<label for="">Loan Drawdown</label>
						</div>
						<div class="col-lg-6 col-12">
							<app-date-input
								#loanDrawdownDateInput
								textboxClass="theme-crm-field-disabled"
								formControlName="loanDrawdown"
								id="loanDrawdown_{{ formId }}"
								tabindex="25"
							>
							</app-date-input>
						</div>
					</div>
				</div>
			</div>
			<!-- Hide Row -->
			<div
				app-collapse
				#collapseForm
				[initialExpandedState]="addMode || undefined"
			>
				<!-- Fourth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Mortgage Type</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="mortgageType"
										class="form-control theme-crm-field-disabled"
										id="mortgageType_{{ formId }}"
										tabindex="10"
									>
										<option value=""></option>
										<option
											*ngFor="let lt of mortgageTypes; trackBy: trackByValue"
											[value]="lt.value"
										>
											{{ lt.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Original Loan Term</label>
							</div>
							<div class="col-lg-6 col-12">
								<input
									type="text"
									formControlName="loanTerm"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="loanTerm_{{ formId }}"
									tabindex="13"
									appTwoDigitDecimalNumber
								/>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Loan Repayment</label>
							</div>
							<div class="col-lg-6 col-12">
								<input
									type="text"
									formControlName="loanRepayment"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="loanRepayment_{{ formId }}"
									tabindex="20"
									appTwoDigitDecimalNumber
								/>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Expected Settlement</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#expectedSettlementDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="expectedSettlement"
									id="expectedSettlement_{{ formId }}"
									tabindex="26"
								>
								</app-date-input>
							</div>
						</div>
					</div>
				</div>
				<!-- Fifth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Loan Type</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="loanType"
										class="form-control theme-crm-field-disabled"
										id="loanType_{{ formId }}"
										tabindex="11"
									>
										<option value=""></option>
										<option
											*ngFor="let p of loanTypes; trackBy: trackByValue"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Interest Rate</label>
							</div>
							<div class="col-lg-6 col-12">
								<input
									type="text"
									formControlName="interestRate"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="interestRate_{{ formId }}"
									appTwoDigitDecimalNumber
									tabindex="14"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg form-inline px-0">
								<div class="col-lg-6 col-12 px-1">
									<label for="">Repayment Frequency</label>
								</div>
								<div class="col-lg-6 col-12 px-1">
									<span class="custom-dropdown w-100">
										<select
											formControlName="repaymentFrequency"
											class="form-control theme-crm-field-disabled"
											id="repaymentFrequency_{{ formId }}"
											tabindex="21"
										>
											<option value=""></option>
											<option
												*ngFor="
													let p of repaymentFrequency;
													trackBy: trackByValue
												"
												[value]="p.value"
											>
												{{ p.display }}
											</option>
										</select>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Fixed Period End</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#fixedPeriodEndDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="fixedPeriodEndDate"
									id="fixedPeriodEndDate_{{ formId }}"
									tabindex="27"
								>
								</app-date-input>
							</div>
						</div>
					</div>
				</div>

				<!-- Sixth Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<span class="tap-required-field" *ngIf="originalAdviser.invalid"
									>*</span
								>
								<label for="">Original Adviser</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										[class.disabled]="
											addMode
												? false
												: (hasPermission(['FEO']) | async)
												? false
												: true
										"
										formControlName="originalAdviser"
										class="form-control theme-crm-field-disabled"
										id="originalAdviser_{{ formId }}"
										tabindex="12"
									>
										<option value=""></option>
										<option
											*ngFor="let p of originalAdvisers$ | async"
											[value]="p.value"
										>
											{{ p.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="">Fixed Period</label>
							</div>
							<div class="col-lg-6 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="fixedPeriodEnd"
										class="form-control theme-crm-field-disabled"
										id="fixedPeriodEnd_{{ formId }}"
										tabindex="15"
									>
										<option value=""></option>
										<option
											*ngFor="let fp of fixedPeriods; trackBy: trackByValue"
											[value]="fp.value"
										>
											{{ fp.display }}
										</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg form-inline px-0">
								<div class="col-lg-6 col-12 px-1">
									<label for="">LVR</label>
								</div>
								<div class="col-lg-6 col-12 px-1">
									<input
										type="text"
										formControlName="lVR"
										class="form-control theme-crm-field-disabled"
										placeholder="- -"
										id="lVR_{{ formId }}"
										tabindex="22"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div
							class="form-row align-items-center"
							*ngIf="shouldShowExpiryDate"
						>
							<div class="col-lg-6 col-12">
								<label for="">Interest only Expiry</label>
							</div>
							<div class="col-lg-6 col-12">
								<app-date-input
									#expiryDateInput
									textboxClass="theme-crm-field-disabled"
									formControlName="expiryDate"
									id="expiryDate_{{ formId }}"
									tabindex="28"
								>
								</app-date-input>
							</div>
						</div>
					</div>
				</div>

				<!-- Seventh Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="businessMortgage_{{ formId }}"
									>{{ businessName$ | async }} Mortgage</label
								>
							</div>
							<div class="col-lg-6 col-12">
								<input
									class="chckbox form-control theme-crm-field-disabled"
									formControlName="businessMortgage"
									type="checkbox"
									id="businessMortgage_{{ formId }}"
									tabindex="29"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg col-12">
						<div class="form-row align-items-center">
							<div class="col-lg-6 col-12">
								<label for="doubleSub_{{ formId }}">Double Sub</label>
							</div>
							<div class="col-lg-6 col-12">
								<input
									class="chckbox form-control theme-crm-field-disabled"
									formControlName="doubleSub"
									type="checkbox"
									id="doubleSub_{{ formId }}"
									tabindex="30"
								/>
							</div>
						</div>
					</div>
					<div class="col-lg col-12"></div>
					<div class="col-lg col-12"></div>
				</div>
				<!-- Eight Row -->
				<div class="form-row clearfix align-items-center mb-2">
					<div class="col">
						<label for="exampleFormControlTextarea1">Required</label>
						<textarea
							*ngIf="form.enabled; else requiredReadonly"
							class="form-control theme-crm-field-disabled"
							formControlName="required"
							rows="4"
							id="required_{{ formId }}}"
							tabindex="29"
						></textarea>
						<ng-template #requiredReadonly>
							<textarea
								class="form-control theme-crm-field-disabled"
								rows="4"
								id="required_{{ formId }}}"
								tabindex="29"
								[value]="form.get('required').value"
								readonly
							></textarea>
						</ng-template>
					</div>
				</div>

				<!-- Nineth Row -->
				<ng-container *ngIf="addMode">
					<div class="form-row clearfix align-items-center mt-1">
						<div class="col-lg">
							<label for="note{{ formId }}">Add a note</label>
							<textarea
								class="form-control theme-crm-field-disabled"
								formControlName="note"
								rows="4"
								id="note_{{ formId }}"
							></textarea>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="!addMode">
					<br />
					<div class="row clearfix align-items-center py-2">
						<div class="col-lg d-flex align-items-center">
							<strong class="mr-3">Notes</strong>
							<ng-container *ngIf="isLead">
								<button
									*appRestrictTo="['FALN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openModalAddNote()"
									id="mortgageAddNote_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<ng-container *ngIf="emailClientTemplateFeature$ | async">
									<button
										*appRestrictTo="['FALN']"
										type="button"
										class="icon-btn h-auto"
										[disabled]="isSaving"
										(click)="openEmailPopup()"
										id="mortgageOpenEmailClient_{{ formId }}"
									>
										<i class="material-icons md-20 tap-text-color-h6">email</i>
									</button>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="!isLead">
								<button
									*appRestrictTo="['FAN']"
									type="button"
									class="icon-btn h-auto"
									[disabled]="isSaving"
									(click)="openModalAddNote()"
									id="mortgageAddNote_{{ formId }}"
								>
									<i class="material-icons md-20 tap-text-color-h6">note_add</i>
								</button>
								<ng-container *ngIf="emailClientTemplateFeature$ | async">
									<button
										*appRestrictTo="['FAN']"
										type="button"
										class="icon-btn h-auto"
										[disabled]="isSaving"
										(click)="openEmailPopup()"
										id="mortgageOpenEmailClient_{{ formId }}"
									>
										<i class="material-icons md-20 tap-text-color-h6">email</i>
									</button>
								</ng-container>
							</ng-container>
						</div>
					</div>
					<div class="row clearfix align-items-center mb-2">
						<div class="col-lg col-12">
							<app-table-paginate-notes
								[isService]="true"
								[isLead]="isLead"
								[notes]="notes"
								[itemsPerPage]="5"
								[deleteFn$]="deleteNote$"
								id="notes_{{ formId }}"
								[showHeader]="false"
							>
							</app-table-paginate-notes>
						</div>
					</div>
				</ng-container>
				<br />
			</div>
		</div>
	</form>
</div>
