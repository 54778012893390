import { Component, Input } from '@angular/core';
import { InvestmentReturnData } from '@modules/crm/crt-page/_shared/calculations/haven-calculator';

@Component({
	selector: 'app-calculator-results',
	templateUrl: './calculator-results.component.html',
	styleUrls: ['./calculator-results.component.scss'],
})
export class CalculatorResultsComponent {
	@Input() InvestmentReturnData: InvestmentReturnData;
	panelOpenState = false;
}
