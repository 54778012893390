import { MergeTagTypeCode } from '../../../merge-tags.model';

export const appSecurityMetaKey = {
	propertyValue: 'APPLICATION_SECURITY_PROPERTY_VALUE',
	lvr: 'APPLICATION_SECURITY_LVR',
	maxLoanAmt: 'APPLICATION_SECURITY_MAX_LOAN_AMOUNT',
	valueType: 'APPLICATION_SECURITY_VALUE_TYPE',
	propertyType: 'APPLICATION_SECURITY_PROPERTY_TYPE',
	propertyAddress: 'APPLICATION_SECURITY_PROPERTY_ADDRESS',
	ownershipEntity: 'APPLICATION_SECURITY_OWNERSHIP_ENTITY',
	allPropertyAddress: 'APPLICATION_SECURITY_ALL_PROPERTY_ADDRESS',

	// TOTALS
	totalPropertyValue: 'APPLICATION_SECURITY_TOTAL_PROPERTY_VALUE',
	totalPropertyLoan: 'APPLICATION_SECURITY_TOTAL_PROPERTY_LOAN_AMOUNT',

	// Security & LVR section	formulas
	ppFormula: 'APPLICATION_SECURITY_PP_FORMULA',
	lvr1Formula: 'APPLICATION_SECURITY_LVR1_FORMULA',
	lvr2Formula: 'APPLICATION_SECURITY_LVR2_FORMULA',
	scaledLvrFormula: 'APPLICATION_SECURITY_SCALED_LVR_FORMULA',
};

export const appSecurityMergeTag = [
	{
		metaKey: appSecurityMetaKey.propertyValue,
		description: 'Application > Security > Property Value',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appSecurityMetaKey.lvr,
		description: 'Application > Security > LVR',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appSecurityMetaKey.maxLoanAmt,
		description: 'Application > Security > Max Loan Amount',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appSecurityMetaKey.valueType,
		description: 'Application > Security > Valuation Type',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'GV',
	},
	{
		metaKey: appSecurityMetaKey.propertyType,
		description: 'Application > Security > Property Type',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Owner Occupied',
	},
	{
		metaKey: appSecurityMetaKey.propertyAddress,
		description: 'Application > Security > Property Address',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Auckland, New Zealand',
	},
	{
		metaKey: appSecurityMetaKey.ownershipEntity,
		description: 'Application > Security > Ownership Entity',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'John Smith',
	},
	{
		metaKey: appSecurityMetaKey.totalPropertyValue,
		description: 'Application > Security > Total Property Value',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appSecurityMetaKey.totalPropertyLoan,
		description: 'Application > Security > Total Max Loan Amount',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
	{
		metaKey: appSecurityMetaKey.allPropertyAddress,
		description: 'Application > Security > All Property Address combined',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: 'Auckland, New Zealand & Taupo, New Zealand',
	},
	{
		metaKey: appSecurityMetaKey.ppFormula,
		description: 'Application > Security > PP Formula',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$Purchase Price x LVR% = $Amount',
	},
	{
		metaKey: appSecurityMetaKey.lvr1Formula,
		description: 'Application > Security > LVR1 Formula',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue: '$Proposed Total Debt / $Total Security Value = LVR%',
	},
	{
		metaKey: appSecurityMetaKey.lvr2Formula,
		description: 'Application > Security > LVR2 Formula',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue:
			'$Proposed Total Debt / $Total Property Value = LVR%',
	},
	{
		metaKey: appSecurityMetaKey.scaledLvrFormula,
		description: 'Application > Security > LVR2 Formula',
		value: [''],
		type: MergeTagTypeCode.text,
		secondaryValue:
			'$Proposed Total Debt / $Total Max Loan Amount = Scaled LVR%',
	},
];
