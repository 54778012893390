export const soaWordingsMetaKey = {
	// Common
	raiseComplaint: 'SOA_RAISE_COMPLAINT',
	thingsToConsider: 'SOA_THINGS_TO_CONSIDER',
	nextStep: 'SOA_NEXT_STEP',

	// LOAT only
	lifeCoverIntro: 'SOA_LIFE_COVER_CALC_INTRO',
	traumaCoverIntro: 'SOA_TRAUMA_COVER_CALC_INTRO',
	disabilityCoverIntro: 'SOA_DISABILITY_COVER__CALC_INTRO',
	tpdCoverIntro: 'SOA_TPD_COVER_CALC_INTRO',
	medicalCoverIntro: 'SOA_MEDICAL_INTRO',
};

export const riskAnalysisNotesMetaKey = {
	// LOAT Risk Analysis Notes
	lifeNotes: 'LIFE_NOTES',
	disabilityNotes: 'DISABILITY_NOTES',
	tpdNotes: 'TPD_NOTES',
	ciNotes: 'TRAUMA_NOTES',
	medicalNotes: 'MEDICAL_NOTES',
};

export const soaWordingsMergeTags = () => {
	return Object.values(soaWordingsMetaKey).map((metaKey) => ({
		metaKey,
		description: '',
		value: '',
		type: 'T',
		secondaryValue: '',
	}));
};
