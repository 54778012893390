import { Component, OnInit, ViewChild } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { sum } from 'ramda';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, take, takeUntil, switchMap, delay, distinctUntilChanged } from 'rxjs/operators';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { ClientReviewTemplateService } from '../states/client-review-template.service';
import { IncomeService } from '../states/income-budget/income.service';
import {
	convertToMonthly as convMonthly,
	Frequency,
} from './calculations/monthly-conversion';
import { convertToAnnual } from '../../../../modules/crm/client-review-template/income-budget/calculations/annual-conversion';
import { numUtil } from '../../../../util/util';
import { MonthlyExpenseDetailsState } from '../../crt-page/_shared/models/income.model';
import { IncomeBudgetGuardCanDeactive } from './income-budget.guard';
import { FullBudgetFormComponent } from './full-budget-form/full-budget-form.component';

@Component({
	selector: 'app-income-budget',
	templateUrl: './income-budget.component.html',
	styleUrls: ['./income-budget.component.scss'],
})
export class IncomeBudgetComponent
	implements OnInit, IncomeBudgetGuardCanDeactive
{
	@ViewChild('fullBudget') fullBudgetForm: FullBudgetFormComponent;

	onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	APCRTF$ = this.service.APCRTF$;
	APCRTKC$ = this.service.APCRTKC$;
	APCRTTC$ = this.service.APCRTTC$;
	APCRTI$ = this.service.APCRTI$;
	APCRTIST$ = this.service.APCRTIST$;
	APCRTIT$ = this.service.APCRTIT$;
	APCRTTM$ = this.service.APCRTTM$;
	APCRTYN$ = this.service.APCRTYN$;
	APCRTYNNA$ = this.service.APCRTYNNA$;
	PCE$ = this.service.PCE$;
	primaryClient$ = this.service.primaryClient$;
	secondaryClients$ = this.query.secondaryClients$;
	incomeSource$ = this.incomeService.incomeSource$;
	netRentalIncome$ = this.incomeService.netRentalIncome$;
	otherIncome$ = this.incomeService.otherIncome$;
	monthlyExpense$ = this.incomeService.monthlyExpense$;
	people$ = this.service.people$;
	properties$ = this.query.properties$;
	factFindComputation$ = this.incomeService.factFindComputation$;
	peopleFromCRMAndCRTExceptChild$ = this.query.peopleFromCRMAndCRTExceptChild$;

	mortgagePayments$ = this.query.mortgages$?.pipe(
		filter((data) => !!data),
		map((x) => {
			const totalInAnnual =
				sum(
					x?.map((m) =>
						convertToAnnual(m.loanRepayment, m.mortgageFrequency as Frequency)
					)
				) || 0;
			return +numUtil.formatToNumCurrency(
				convMonthly(totalInAnnual, 'Annually')
			);
		})
	);

	pHCValue$ = this.query.liabilities$?.pipe(
		filter((data) => !!data),
		map((x) => {
			const totalInAnnual =
				sum(
					x?.map((m) =>
						convertToAnnual(m.loanRepayment, m.mortgageFrequency as Frequency)
					)
				) || 0;
			return +numUtil.formatToNumCurrency(
				convMonthly(totalInAnnual, 'Annually')
			);
		})
	);

	annualTaxableJointIncome = 0;
	monthlyTaxableJointIncome = 0;
	monthlyAfterTaxIncome = 0;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$;

	constructor(
		private fb: UntypedFormBuilder,
		private service: ClientReviewTemplateService,
		private query: ClientReviewTemplateQuery,
		private incomeService: IncomeService
	) {
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			totalGrossIncome: [''],
			bonusValue: [''],
			bonusFrequency: [''],
			bonusTaxMode: [''],
			unknown: [''],
			kiwiSaverContribution: ['', Validators.required],
			taxCode: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.factFindComputation$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.annualTaxableJointIncome = +data?.annualTaxableJointIncome;
				this.monthlyTaxableJointIncome = +data?.monthlyTaxableJointIncome;
				this.monthlyAfterTaxIncome = +data?.monthlyAfterTaxIncome;
			});
	}

	// income source
	deleteEmployment$ = (id) => this.incomeService.deleteIncomeSource(id);
	// rental income
	addNewRental$ = (model) => this.incomeService.addRentalIncome(model);
	updateRental$ = (model) => this.incomeService.updateRentalIncome(model);
	deleteRental$ = (id) => this.incomeService.deleteRentalIncome(+id);
	// other income
	addNewOther$ = (model) => this.incomeService.addOtherIncome(model);
	updateOther$ = (model) => this.incomeService.updateOtherIncome(model);
	deleteOther$ = (id) => this.incomeService.deleteOtherIncome(+id);
	// monthly expense
	addNewMonthlyExpense$ = (model: MonthlyExpenseDetailsState) =>
		this.incomeService.addMonthlyExpense(model);
	updateMonthlyExpense$ = (model: MonthlyExpenseDetailsState) =>
		this.incomeService.updateMonthlyExpense(model);

	canDeactivate(): Observable<boolean> {
		return this.incomeService.monthlyExpense$.pipe(
			take(1),
			// filter((x) => !!x),
			switchMap((data) => {
				if (!data?.cRTId) {
					return of(true);
				}
				const updatedValues = {
					...data,
					...this.fullBudgetForm.getComputedValue(),
				};
				return this.incomeService
					.updateMonthlyExpense(updatedValues)
					.pipe(map(() => true));
			})
		);
	}
}
