<form [formGroup]="form">
	<div class="app-adduser-container fluid-container">
		<div class="app-user-header">
			<h1 class="py-2 px-3 mb-0">
				{{ header }}
			</h1>
		</div>
		<div class="app-adduser-content-container">
			<div class="container-fluid px-0 pt-3">
				<!-- <div class="row tap-container">
					<div class="col pt-3">
						<h6>User details</h6>
					</div>
				</div>
				<div class="row tap-container">
					<div class="col scc-border mb-2"></div>
				</div> -->

				<div class="row tap-container">
					<div class="col-md-3 pr-md-0">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label for="firstName" class="position-relative">
									First Name
									<span class="tap-required" *ngIf="FirstName.invalid">*</span>
								</label>
							</div>
							<div class="col-lg-8">
								<input
									class="form-control ng-untouched ng-pristine ng-valid"
									type="text"
									id="firstName"
									formControlName="FirstName"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-3 pr-md-0">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label for="lastName" class="position-relative">
									Last Name
									<span class="tap-required" *ngIf="LastName.invalid">*</span>
								</label>
							</div>
							<div class="col-lg-8">
								<input
									class="form-control"
									type="text"
									id="lastName"
									formControlName="LastName"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-3 pr-md-0">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label for="isActive">Is active</label>
							</div>
							<div class="col-lg-8">
								<select
									class="form-control ng-untouched ng-pristine ng-valid"
									id="isActive"
									formControlName="IsActive"
								>
									<option value=""></option>
									<option
										*ngFor="let opt of isActiveOptions"
										[value]="opt.option"
									>
										{{ opt.name }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label for="securityGroup" class="position-relative">
									Security group
									<span class="tap-required" *ngIf="SecurityGroup.invalid"
										>*</span
									>
								</label>
							</div>
							<div class="col-lg-8">
								<select
									class="form-control ng-untouched ng-pristine ng-valid"
									id="securityGroup"
									formControlName="SecurityGroup"
								>
									<option value=""></option>
									<option
										*ngFor="let sec of secGroups"
										[value]="sec.securityGroupCode"
									>
										{{ sec.securityGroupName }}
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="row tap-container">
					<div class="col-md-3 pr-md-0">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label for="startDate" class="position-relative">
									Start date
									<span class="tap-required" *ngIf="StartDate.invalid">*</span>
								</label>
							</div>
							<div class="col-lg-8">
								<app-date-input
									formControlName="StartDate"
									textboxId="startDate"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="col-md-3 pr-md-0">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label class="" for="birthDate">Birth date</label>
							</div>
							<div class="col-lg-8">
								<app-date-input
									formControlName="BirthDate"
									textboxId="birthDate"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="col-md-3 pr-md-0">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label class="" for="mobilePhone">Mobile phone</label>
							</div>
							<div class="col-lg-8">
								<input
									class="form-control ng-untouched ng-pristine ng-valid"
									type="text"
									id="mobilePhone"
									formControlName="MobilePhone"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-4 pr-0">
								<label class="" for="businessPhone">Business Phone</label>
							</div>
							<div class="col-lg-8">
								<input
									class="form-control ng-untouched ng-pristine ng-valid"
									type="text"
									id="businessPhone"
									formControlName="BusinessPhone"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="row tap-container">
					<div class="col-md-6 pr-md-0">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-2 pr-0">
								<label for="emailAddress" class="position-relative">
									Email address
									<span class="tap-required" *ngIf="EmailAddress.invalid"
										>*</span
									>
								</label>
							</div>
							<div class="col-lg-10 crm-email">
								<input
									class="form-control ng-untouched ng-pristine ng-valid"
									type="text"
									id="emailAddress"
									formControlName="EmailAddress"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group form-inline row mb-1">
							<div class="col-lg-2 pr-0">
								<label class="" for="homeAddress">Home address</label>
							</div>
							<div class="col-lg-10 crm-home">
								<input
									class="form-control ng-untouched ng-pristine ng-valid"
									type="text"
									id="homeAddress"
									formControlName="HomeAddress"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="row tap-container">
					<div class="col-md-6 px-0">
						<div class="row tap-container">
							<div class="col pt-3">
								<h6>Business access</h6>
							</div>
							<div class="d-flex justify-content-end">
								<div class="form-check form-check-inline">
									<input
										class="form-check-input"
										type="checkbox"
										id="allChkbox"
										(click)="showAll()"
									/>
									<label class="form-check-label">Show All</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row tap-container">
					<div class="col-lg-6 pr-lg-0">
						<table class="table">
							<thead>
								<th class="col1st absorber py-2 px-1 px-lg-2">
									<label for="checkAll" class="app-checkbox d-none">
										<input type="checkbox" id="checkAll" />
										<span>&nbsp;</span>
									</label>
								</th>
								<th scope="col" class="auto py-2 px-1 px-lg-2">
									Business name
								</th>
								<th scope="col" class="auto py-2 px-1 px-lg-2">Website</th>
								<th scope="col" class="auto py-2 px-1 px-lg-2">Domain</th>
							</thead>
							<tbody class="border-bottom">
								<tr *ngFor="let b of activeBusiness; let i = index">
									<td class="py-2 px-1 px-lg-2 border-right">
										<label [for]="b.companyCode" class="app-checkbox m-0">
											<input
												type="checkbox"
												[id]="b.companyCode"
												[checked]="hasBusiness(b.businessId)"
												(change)="
													checkToggle(b.businessId, $event.target.checked)
												"
												[disabled]="this.form.disabled"
											/>
											<span>&nbsp;</span>
										</label>
									</td>
									<td class="pt-2 pb-0 px-1 px-lg-2">
										{{ b.businessName }}
									</td>
									<td class="pt-2 pb-0 px-1 px-lg-2">
										{{ b.website }}
									</td>
									<td class="pt-2 pb-0 px-1 px-lg-2">
										{{ b.domain }}
									</td>
								</tr>
								<ng-container *ngIf="showAllBusiness">
									<tr
										class="disabledStaff"
										*ngFor="let b of inActiveBusiness; let i = index"
									>
										<td class="py-2 px-1 px-lg-2 border-right">
											<label [for]="b.companyCode" class="app-checkbox m-0">
												<input
													type="checkbox"
													[id]="b.companyCode"
													[checked]="hasBusiness(b.businessId)"
													(change)="
														checkToggle(b.businessId, $event.target.checked)
													"
													[disabled]="this.form.disabled"
												/>
												<span>&nbsp;</span>
											</label>
										</td>
										<td class="pt-2 pb-0 px-1 px-lg-2">
											{{ b.businessName }}
										</td>
										<td class="pt-2 pb-0 px-1 px-lg-2">
											{{ b.website }}
										</td>
										<td class="pt-2 pb-0 px-1 px-lg-2">
											{{ b.domain }}
										</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
					<!--TL - Edit User-->
					<ng-container *appRestrictTo="['TEU']">
						<div class="col mb-5 text-right">
							<ng-container *ngIf="!isSaving">
								<button id="btnStaffSave" class="btn btn-primary mr-2" (click)="submitBtnClick()">
									{{ btnText }}
								</button>
								<button id="btnStaffCancel" class="btn btn-light" (click)="cancel()">Cancel</button>
							</ng-container>
							<app-manual-loader-mini
								[isLoading]="isSaving"
							></app-manual-loader-mini>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</form>
