<div class="crt-banner-container fluid-container pr-3 pl-3">
	<div class="row">
		<div
			*ngIf="bgUrl"
			class="crt-banner-container__background col-12 d-flex align-items-center px-0"
			[ngStyle]="{ 'background-image': 'url(' + bgUrl + ')' }"
		>
			<div
				class="crt-banner-container__header col-12 text-right font-weight-bold pr-3"
			>
				<div class="row pr-3">
					<div class="header-text col-12 mb-2 text-left">
						<span>{{ headerText || "" }}</span>
					</div>
					<div class="text-left progress-text col-12" text-left>
						<span>The investment to a sound future.</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
