import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { MeetingStats } from './msw.model';
import { MeetingStatsStore } from './msw.store';

@Injectable()
export class MeetingStatsService {
	private readonly endpoint = 'widgets?type=MSW';

	constructor(private api: ApiService, private store: MeetingStatsStore) {}

	/**
	 * Fetch and store Meeting Stats data
	 * Search widget data related to theses advisers
	 */
	public GetMeetingStats(req: {
		advisers: string[];
		dateRangeMin: string;
		dateRangeMax: string;
	}): void {
		this.store.reset();
		this.store.setLoading(true);
		this.api
			.post<MeetingStats[]>(this.endpoint, {
				Advisers: req.advisers,
				DateRangeMin: req.dateRangeMin,
				DateRangeMax: req.dateRangeMax,
			})
			.subscribe(
				(res) =>
					this.store.update(
						produce((draft) => {
							draft.MeetingStats = res;
						})
					),
				(err) =>
					applyTransaction(() => {
						this.store.setError('Error fetching data!');
						this.store.setLoading(false);
					}),
				() => this.store.setLoading(false)
			);
	}
}
