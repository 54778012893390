import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { identical, filter as Rfilter } from 'ramda';
import { BehaviorSubject, combineLatest, Observable, Observer, Subject } from 'rxjs';
import {
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { AssetsLiabilitiesModalComponent } from 'src/app/shared/modal/crt/fact-find/assets-liabilities/assets-liabilities.modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { MortgageMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/mortgage/mortgage.mapper';
import { MortgageInfoState, MortgageState } from 'src/app/shared/models/client-review-template/assets-liabilities/mortgage/mortgage.model';
import { PropertyStatus } from 'src/app/shared/models/client-review-template/assets-liabilities/property/property.model';
import { MortgageCustomerService } from 'src/app/shared/models/services/mortgage/mortgage.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { MortgageStatus } from '../../../crt-page/crt-mortgage/client-sop/assets-and-liabilities/state/mortgage/mortgage.model';
import { AssetsLiabilitiesService } from '../../states/assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';

declare var $: any;

@Component({
	selector: 'app-mortgage',
	templateUrl: './mortgage.component.html',
	styleUrls: ['./mortgage.component.scss'],
})
export class MortgageComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Input() isAdviceProcessEnded: boolean;

	@Input() mp$: Observable<ViewDisplayValue[]>;
	// mortgages$: Observable<MortgageState[]> = this.query.mortgages$;
	mortgages$ = new BehaviorSubject<MortgageState[]>([]);

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;
	deletingId: typeof identical;

	elseMinusMortgages = true;
	isAdding = false;
	isAddNew = false;
	isEditing = false;
	editIndex: number;

	adviceProcessId = this.query?.getValue().adviceProcessId;
	policyOwners = [];
	security = this.query?.getValue().propertyAddresses;
	securities$ = combineLatest([this.query.propertyAddresses$]).pipe(
		map(([x]) =>
			Rfilter((p) => p?.PropertyAddress?.toUpperCase() !== 'TBC', x)
		)
	);
	selectedProvider: number;

	crmMortgages$ = this.query.crmMortgages$;
	crmMortgagesChoices$: Observable<ViewDisplayValue[]> = combineLatest([
		this.query.crmMortgages$,
		this.service.mortgages$,
	]).pipe(
		filter(([x, y]) => !!x && !!y),
		map(([x, mortgages]) =>
			x
				?.filter(
					(y) =>
						!mortgages
							?.filter((mg) => !!mg.cRTId)
							?.some((m) => m.customerServiceID === y.CustomerServiceID) &&
						[MortgageStatus.CurrentMortgage.toString(), MortgageStatus.DrawDown.toString()].includes(y?.Status)
				)
				?.map((m) =>
					ViewDisplayValue.Map(
						`${m.CustomerServiceID}`,
						`${m.Provider} - ${this.cp?.transform(
							isNaN(m.LoanValue) ? 0 : +m.LoanValue
						)}`
					)
				)
		)
	);

	crmMortgages: MortgageCustomerService[];
	selectedCrmMortgage;

	isLoading$ = this.query.isMortgagesLoading$;

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private service: AssetsLiabilitiesService,
		private query: ClientReviewTemplateQuery,
		private cp: CurrencyPipe
	) {
		this.buildFrom();
	}

	ngOnInit(): void {
		this.query.mortgages$.pipe(
			filter((x) => !!x),
			tap((x) => this.mortgages$.next(x)),
			takeUntil(this.onDestroy$)
		).subscribe();

		this.crmMortgages$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((res) => (this.crmMortgages = res));
	}

	buildFrom() {
		this.form = this.fb.group({});
	}

	collapseMoreMortgages() {
		$('#collapseMortgages').toggle();
		this.elseMinusMortgages = false;
	}
	collapseLessMortgages() {
		$('#collapseMortgages').toggle();
		this.elseMinusMortgages = true;
	}

	saveNew$ = (model: MortgageInfoState) =>
		new Observable((obs: Observer<MortgageInfoState>) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) => MortgageMapper.mapToMortgageDetails(x, this.adviceProcessId)),
			mergeMap((x) =>
				this.service.addAL(x, AdviceProcessSectionCodes.Mortgages)
			),
			tap(() => (this.isAddNew = false))
		);

	onSelectMortgage(e: string, i: number) {
		if (e === 'new') {
			const decline = new Observable((obs: Observer<JSON>) => {
				(
					document.getElementById('mortgageSelectFromCrm') as HTMLInputElement
				).value = '';
				obs.complete();
			});
			const initState = {
				header: 'Mortgages Details',
				message: `Mortgages`,
				borrowers: this.query.policyOwnersWithCRT$,
				getOwnerChoices: this.service.getOwnerChoices,
				securities: this.mortgageSecurities(),
				mp$: this.service.MP$,
				mlt$: this.service.MLT$,
				apcrtf$: this.service.APCRTF$,
				saveFn$: this.saveNew$,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(
				AssetsLiabilitiesModalComponent,
				{
					class: 'modal-dialog-centered modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				}
			);
		} else {
			const m: MortgageCustomerService = this.crmMortgages?.find(
				(x) => x.CustomerServiceID === +e
			);
			this.selectedProvider = m.CustomerServiceID;
			this.mortgages$
				.pipe(
					map((x) => {
						x[i] = {
							cRTId: 0,
							lender: m.Provider,
							loanValue: m.LoanValue,
							interestRate: m.InterestRate,
							fixedUntil: m.FixedPeriodEndDate,
							customerServiceID: m.CustomerServiceID,
						} as MortgageState;
					}),
					take(1)
				)
				.subscribe();
		}
	}

	addMortgage() {
		this.isAddNew = true;
		this.query.mortgages$
			.pipe(
				tap((x) => {
					const newData = {
						cRTId: 0,
						lender: null,
						loanValue: 0,
						interestRate: 0,
						fixedUntil: null,
					} as MortgageState
					this.mortgages$.next([...(x || []), newData]);
				}),
				take(1)
			)
			.subscribe();
	}

	saveMortgage(mortgage: MortgageState) {
		this.isAdding = true;
		const d = this.crmMortgages?.find(
			(x) => x.CustomerServiceID === +mortgage.customerServiceID
		);
		const data = MortgageMapper.mapToMortgageDetails(
			{
				...mortgage,
				fixedPeriodEnd: mortgage.fixedUntil,
				borrowingEntities:
					!!d && !!d.BorrowingEntities ? JSON.parse(d.BorrowingEntities) : null,
				actualRepayment: !!d && !!d.LoanRepayment ? d.LoanRepayment : null,
				mortgageFrequency:
					!!d && !!d.RepaymentFrequency ? d.RepaymentFrequency : null,
				loanType: !!d && !!d.LoanType ? d.LoanType : null,
				loanTerm: !!d && !!d.LoanTerm ? d.LoanTerm : null,
				security: !!d && !!d.Security ? JSON.parse(d?.Security) : null,
				purpose: '',
			},
			this.adviceProcessId
		);
		this.isAdding = true;
		this.service
			.addAL(data, AdviceProcessSectionCodes.Mortgages)
			.pipe(
				finalize(() => {
					this.selectedProvider = undefined;
					this.isAdding = false;
					this.isAddNew = false;
				}),
				take(1)
			)
			.subscribe();
	}

	updateFn$ = (model: MortgageInfoState) =>
		new Observable((obs: Observer<MortgageInfoState>) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) => MortgageMapper.mapToMortgageDetails(x, this.adviceProcessId)),
			mergeMap((x) =>
				this.service.updateAL(x, AdviceProcessSectionCodes.Mortgages)
			)
		);

	editMortgage(crtId: string, index: number) {
		this.isAdding = false;
		this.isAddNew = false;
		this.isEditing = true;
		this.editIndex = index;
		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});
		this.service
			.getCrtById(crtId)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.editIndex = undefined;
				}),
				take(1)
			)
			.subscribe((x) => {
				const initState = {
					header: 'Mortgages Details',
					message: `Mortgages`,
					borrowers: this.query.policyOwnersWithCRT$,
					getOwnerChoices: this.service.getOwnerChoices,
					securities: this.mortgageSecurities(x),
					mp$: this.service.MP$,
					mlt$: this.service.MLT$,
					apcrtf$: this.service.APCRTF$,
					crtInfo: x,
					saveFn$: this.updateFn$,
					decline$: decline,
				};
				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	viewMortgage(crtId: string, index: number) {
		this.isAdding = false;
		this.isAddNew = false;
		this.isEditing = true;
		this.editIndex = index;
		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});
		this.service
			.getCrtById(crtId)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.editIndex = undefined;
				}),
				take(1)
			)
			.subscribe((x) => {
				const initState = {
					header: 'Mortgages Details',
					message: `Mortgages`,
					borrowers: this.query.policyOwnersWithCRT$,
					getOwnerChoices: this.service.getOwnerChoices,
					securities: this.mortgageSecurities(x),
					mp$: this.service.MP$,
					mlt$: this.service.MLT$,
					apcrtf$: this.service.APCRTF$,
					crtInfo: x,
					decline$: decline,
					viewMode: true
				};
				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	delete(id: number) {
		this.deletingId = identical;
		this.service
			.deleteAL(id, AdviceProcessSectionCodes.Mortgages)
			.pipe(
				finalize(() => {
					this.selectedProvider = undefined;
					this.isAdding = false;
					this.isAddNew = false;
					this.deletingId = undefined;
				}),
				take(1)
			)
			.subscribe();
	}

	deleteModal(id: number) {
		const confirm = new Observable((obs: Observer<JSON>) => {
			this.delete(id);
			obs.complete();
		});
		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});
		const initState = {
			header: 'Delete Mortgage',
			message: logMessage.oat.shared.factFind.delete,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'OK',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	mortgageSecurities(mortgage?) {
		return this.securities$.pipe(
			map((x) => {
					let securities = mortgage?.Security ? JSON.parse(mortgage?.Security) : [];
					securities = securities?.map((i) => +i);
					return x?.filter((p) => p?.Status === PropertyStatus.Current|| securities?.includes(p?.CustomerServiceID));
				}
			),
			map((x) =>
				x?.map((a) =>
					ViewDisplayValue.Map(`${a.CustomerServiceID}`, a.PropertyAddress)
				)
			)
		)
	}

	cancelAdd(index: number) {
		this.mortgages$
			.pipe(
				map((x) => x?.splice(index, 1)),
				tap(() => (this.isAddNew = false)),
				tap(() => (this.selectedProvider = undefined)),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.mortgages$
			.pipe(
				map((x) => {
					if (x?.some((p) => p?.cRTId === 0 || !p?.cRTId)) {
						x?.splice(
							x?.findIndex((p) => p?.cRTId === 0 || !p.cRTId),
							1
						);

						return x;
					}
					return x;
				}),
				take(1)
			)
			.subscribe();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
