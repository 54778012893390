import { Injectable } from '@angular/core';
import { ApiService } from '../base/api.service';
import { Observable } from 'rxjs';
import { CustomerSearchRequest, CustomerSearch } from '../../shared/models/_general/customer-search.model';

import { BusinessSearchRequestModel } from '../../shared/models/_general/business-customer/request/business-search-request.model';
import { BusinessSearchResponseModel } from '../../shared/models/_general/business-customer/business-search-response.model';
import { CustomerSearchModel } from '../../shared/models/_general/customerSearchModel';

@Injectable()
export class CustomerSearchService {
	private querySearchEndpoint = 'search/CustomerSearchByQuery';

	constructor(private api: ApiService) { }

	searchCustomer(search: CustomerSearchRequest): Observable<CustomerSearch> {
		const endpoint = 'search/CustomerSearch';
		const body = search;
		return this.api.post3<CustomerSearch>(endpoint, body);
	}

	searchBusinessCustomer(req: BusinessSearchRequestModel): Observable<BusinessSearchResponseModel> {
		const endpoint = 'search/CompanySearch';
		return this.api.post3<BusinessSearchResponseModel>(endpoint, req);
	}

	searchClients(query: string): Observable<CustomerSearchModel[]> {
		const endpoint = `search?keyword=${query}&type=referred-by`;
		return this.api.get<CustomerSearchModel[]>(endpoint);
	}
	searchDirectors(query: string): Observable<CustomerSearchModel[]> {
		const endpoint = `search?keyword=${query}&type=directors`;
		return this.api.get<CustomerSearchModel[]>(endpoint);
	}
	searchShareholders(query: string): Observable<CustomerSearchModel[]> {
		const endpoint = `search?keyword=${query}&type=shareholders`;
		return this.api.get<CustomerSearchModel[]>(endpoint);
	}

	searchPrimaryCustomerIndividual(query: string): Observable<CustomerSearchModel[]> {
		const endpoint = `search?keyword=${query}&type=referred-by`;
		return this.api.get<CustomerSearchModel[]>(endpoint);
	}

	searchOthers(query: string): Observable<CustomerSearchModel[]> {
		const endpoint = `search?keyword=${query}&type=others`;
		return this.api.get<CustomerSearchModel[]>(endpoint);
	}

	downloadSearchCustomer(search: CustomerSearchRequest): Observable<Blob> {
		const endpoint = 'export/ExportCustomers';
		return this.api.postFileDownload(endpoint, search);
	}

	searchLinkedContacts(query: string): Observable<CustomerSearchModel[]> {
		const endpoint = `search?keyword=${query}&type=linked-contacts`;
		return this.api.get<CustomerSearchModel[]>(endpoint);
	}

}
