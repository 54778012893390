<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">
			Images
		</h1>
	</div>

	<div class="row tap-container service-tabs crt-settings">
		<div class="col">
			<tabset [appTheme]="{ color: 'primarycolor' }">
				<tab heading="OAT Logo">
					<ng-container *ngTemplateOutlet="oatLogoTemplate"></ng-container>
				</tab>
			</tabset>
		</div>
	</div>
</div>

<ng-template #oatLogoTemplate>
	<form novalidate class="p-4">
		<div class="row tap-container justify-content-center">
			<div class="col-xl-10 row px-0">
				<div class="col-md-4 pr-md-0">
					<div class="form-group form-inline row mb-1">
						<div class="col-lg-7">
							<app-photo id="upload_photo_business_oat_logo" [class.disabled]="isSaving$ | async" [uploadType]="'modal'"
								[notCustomer]="true" [imageUrl]="data$ | async" [uploadFn$]="uploadPhoto$"
								[headerTitle]="header"></app-photo>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</ng-template>