import { MortgageCustomerServiceState } from './mortgage.model';
import MomentUtil from '../../../../util/moment.util';
import { Moment } from 'moment';
import { util } from '../../../../core/util/util.service';

export class MortgageMapper {
	/**
	 * Map to view
	 */
	public static mapToView(data: MortgageCustomerServiceState): MortgageCustomerServiceState {
		if (!data) {
			return { ...data };
		}
		return {
			...data,
			// tslint:disable-next-line: max-line-length
			borrowingEntities: typeof data.borrowingEntities === 'string' ? (data.borrowingEntities ? JSON.parse(data.borrowingEntities) : []) : data.borrowingEntities??[],
			security: typeof data.security === 'string' ? (data.security ? JSON.parse(data.security) : []) : data.security??[],
			// tslint:disable-next-line: max-line-length
			fixedPeriodEndDate: util.isValidMoment(data.fixedPeriodEndDate) ? data.fixedPeriodEndDate : this.formatDateToMoment(data.fixedPeriodEndDate),
			loanDrawdown: util.isValidMoment(data.loanDrawdown) ? data.loanDrawdown : this.formatDateToMoment(data.loanDrawdown),
			submitted: util.isValidMoment(data.submitted) ? data.submitted : this.formatDateToMoment(data.submitted),
			expiryDate: util.isValidMoment(data.expiryDate) ? data.expiryDate : this.formatDateToMoment(data.expiryDate),
			approved: util.isValidMoment(data.approved) ? data.approved : this.formatDateToMoment(data.approved),
			// tslint:disable-next-line: max-line-length
			expectedSettlement: util.isValidMoment(data.expectedSettlement) ? data.expectedSettlement : this.formatDateToMoment(data.expectedSettlement),
			createDateTime: util.isValidMoment(data.createDateTime) ? data.createDateTime : this.formatDateToMoment(data.createDateTime),
			// tslint:disable-next-line: max-line-length
			notes: (data.notes && data.notes.length > 0) ? data.notes?.filter(x => util.isValidMoment(data.createDateTime) ? data.createDateTime : this.formatDateToMoment(x.createDateTime)) : []
		};
	}

	public static mapNextReviewView(value) {
		if (!value) {
			return '';
		}
		return util.isValidMoment(value) ? value : this.formatDateToMoment(value);
	}

	/**
	 * Map to add view
	 */
	public static mapToAddView(data: MortgageCustomerServiceState): MortgageCustomerServiceState {
		if (!data) {
			return { ...data };
		}
		return {
			...data,
			// tslint:disable-next-line: max-line-length
			borrowingEntities: typeof data.borrowingEntities === 'string' ? (data.borrowingEntities ? JSON.parse(data.borrowingEntities) : null) : data.borrowingEntities,
			security: typeof data.security === 'string' ? (data.security ? JSON.parse(data.security) : null) : data.security,
			loanNumberSuffix: null,
			status: '',
			interestRate: null,
			fixedPeriodEndDate: '',
			loanValue: null,
			loanDrawdown: '',
			mortgageType: '',
			businessMortgage: false,
			businessRefix: '',
			fixedPeriodEnd: '',
			lVR: '',
			loanRepayment: null,
			required: '',
			submitted: '',
			approved: '',
			expectedSettlement: '',
			loanType: '',
			expiryDate: '',
			mortgageStatusClock: '',
			mortgageRefixStatusClock: '',
			isActive: 1
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: MortgageCustomerServiceState): MortgageCustomerServiceState {
		return {
			...data,
			loanTerm: data.loanTerm ? +data.loanTerm : null,
			borrowingEntities: data.borrowingEntities && data.borrowingEntities.length > 0 ? JSON.stringify(data.borrowingEntities) : null,
			security: data.security && data.security.length > 0 ? JSON.stringify(data.security) : null,
			fixedPeriodEndDate: MomentUtil.formatDateToServerDate(data.fixedPeriodEndDate),
			loanDrawdown: MomentUtil.formatDateToServerDate(data.loanDrawdown),
			submitted: MomentUtil.formatDateToServerDate(data.submitted),
			approved: MomentUtil.formatDateToServerDate(data.approved),
			expectedSettlement: MomentUtil.formatDateToServerDate(data.expectedSettlement),
			createDateTime: MomentUtil.formatDateToServerDate(data.createDateTime),
			expiryDate: MomentUtil.formatDateToServerDate(data.expiryDate),
			originalAdviser: data.originalAdviser ? +data.originalAdviser : null
		};
	}

	public static mapNextReviewUpsert(value) {
		return MomentUtil.formatDateToServerDate(value);
	}

	/**
	 * Format date to Moment
	 * @param date string or Moment date
	 */
	static formatDateToMoment(date: string | Moment): Moment {
		return typeof date === 'string'
			? util.DateStringToMomentLoose(date)
			: util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
	}
}
