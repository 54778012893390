import { TLStaff } from './tl-staff.model';
import { TLStaffsStore } from './tl-staffs.store';
import { Injectable } from '@angular/core';
import { TLStaffsQuery } from './tl-staffs.query';
import { Observable, of } from 'rxjs';
import { ApiService, JsonResultStatus } from '../../core/base/api.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class TLStaffsService {
	constructor(
		private api: ApiService,
		private tlStaffsQuery: TLStaffsQuery,
		private tlStaffsStore: TLStaffsStore
	) { }

	addList(): Observable<TLStaff[]> | Promise<TLStaff[]> {
		const staffs = this.tlStaffsQuery.getAll();

		if (staffs.length > 0) {
			return of(staffs);
		} else {
			const endpoint = 'staff/tl';

			return this.api
				.get<TLStaff[]>(endpoint)
				.pipe(tap((x) => this.tlStaffsStore.add(x)));
		}
	}

	add(tlStaff: any) {
		const endpoint = 'staff/tl';
		const body = tlStaff;
		return this.api.post3<JsonResultStatus>(endpoint, body).pipe(
			tap(id => {
				if (id) {
					tlStaff.StaffID = Number(id);
					this.tlStaffsStore.add(tlStaff);
				}
			})
		);
	}

	update(tlStaff: any) {
		const endpoint = `staff/${tlStaff.StaffID}/tl`;
		return this.api.put<JsonResultStatus>(endpoint, tlStaff).pipe(
			tap(id => {
				if (id) {
					this.tlStaffsStore.update(tlStaff.StaffID, tlStaff);
				}
			})
		);
	}

	delete(StaffID: number) {
		const endpoint = `staff/${StaffID?.toString()}?type=tl`;
		return this.api.delete<JsonResultStatus>(endpoint).pipe(
			tap(id => {
				if (id) {
					this.tlStaffsStore.update(StaffID, {
						IsActive: false,
					});
				}
			})
		);
	}
}
