<ng-template #uploadOptionModal>
	<button
		id="modalHideButton"
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="optionModalRef.hide()"
	>
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ chooserTitle }}</h4>
	</div>
	<div class="modal-body">
		<div class="clearfix py-3">
			<div class="d-flex justify-content-center">

				<button
					id="uploadDocumentBtn"
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4"
					(click)="uploadNew()"
				>
					Upload New
				</button>

				<button
					id="linkDocumentButon"
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 ml-3"
					(click)="linkDocument()"
				>
					Link Document
				</button>
			</div>
		</div>
	</div>
</ng-template>
