import { Paging } from './paging.model';

export class Note {
  NotesID: number; // new!!
  CustomerServiceID: number;
  Notes: string;
  IsActive: boolean; // new!!
  CreateDateTime: string; // new!!
  CreatedByStaffId: number;
  CustomerID: number;
  StaffName: string;
  StaffLevel: number;
  DueDateTime: string; // new!!

  ActivityType: string;
  ActivityName: string;
  AssignedToAdviser: number;
}

export class NoteDelete {
  company_code: string;
  note_id: number;
}

export class NoteAdd {
  CompanyCode: string;
  Note: Note;
}

export class GetNote {
  CustomerID: number;
  CustomerServiceID: number;
  Paging: Paging;
}


export class NoteResponse {
  Notes: Note[];
  Count: number;
}
export class NoteParam {
  public CompanyCode: string;
  public Note: NoteRequest;
}

export class NoteRequest {
  public NoteId?: number;
  public CustomerServiceId?: number;
  public Notes: string;
  public CustomerId: number;
  public CreatedByStaffId?: number;
  public StaffName?: string;
  public StaffLevel?: 0 | 1;
}

export enum NoteTypes {
  Asset = 'A',
	Liability = 'L'
}
