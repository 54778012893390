<div class="row mb-2 mt-2">
	<div class="col-lg-6 form-name" [class.submitted]="submitted">
		<input
			type="text"
			class="form-control crt-form-control {{
				isNameExist ? 'ng-invalid' : ''
			}}"
			id="statementOfAdviceName"
			[formControl]="name"
			placeholder="Statement Of Advice Name"
		/>
	</div>
</div>

<div class="font-weight-bold mt-3 mb-3">
	What will be included in this Statement of Advice?
</div>

<ng-template #loader>
	<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
</ng-template>
<div
	class="scope-of-service scope-of-service__content"
	*ngIf="!isLoading; else loader"
>
	<form [formGroup]="form" class="form">
		<div class="row pb-0 pb-2">
			<div class="col-12">
				<div class="row">
					<div class="col-12 scope-of-service__labels">
						<label class="label-font font-weight-bold tap-text-primary"
							>Intro</label
						>
					</div>
					<div class="col-12 scope-of-service__textbox">
						{{ crtData?.intro }}
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pb-2 pt-2">
			<div class="col-12">
				<div class="row">
					<div class="col-12 scope-of-service__labels">
						<label class="label-font font-weight-bold tap-text-primary"
							>Services</label
						>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 col-12">
						<ng-container *ngIf="showLifeInsurance">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="lifeInsurance"
											[checked]="+LifeInsurance?.value === 1"
											(change)="tickChange($event)"
										/>
										<label class="custom-control-label" for="lifeInsurance"
											>Life Insurance</label
										>
									</div>
								</div>
							</div>
							<div
								class="row scope-of-service__services"
								*ngIf="LifeInsurance?.value === 0"
							>
								<div class="col-12 scope-of-service__textbox">
									<textarea
										id="lifeInsuranceDetails"
										name="lifeInsuranceDetails"
										formControlName="lifeInsuranceDetails"
										class="crt-form-control"
										placeholder="Details"
									></textarea>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="showCriticalIllness">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="criticalIllness"
											[checked]="+CriticalIllness?.value === 1"
											(change)="tickChange($event)"
										/>
										<label class="custom-control-label" for="criticalIllness"
											>Critical Illness</label
										>
									</div>
								</div>
							</div>
							<div
								class="row scope-of-service__services"
								*ngIf="CriticalIllness?.value === 0"
							>
								<div class="col-12 scope-of-service__textbox">
									<textarea
										id="criticalIllnessDetails"
										name="criticalIllnessDetails"
										formControlName="criticalIllnessDetails"
										class="crt-form-control"
										placeholder="Details"
									></textarea>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="showTPDInsurance">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="tPDInsurance"
											[checked]="+TPDInsurance?.value === 1"
											(change)="tickChange($event)"
										/>
										<label class="custom-control-label" for="tPDInsurance"
											>TPD Insurance</label
										>
									</div>
								</div>
							</div>
							<div
								class="row scope-of-service__services"
								*ngIf="TPDInsurance?.value === 0"
							>
								<div class="col-12 scope-of-service__textbox">
									<textarea
										id="tPDInsuranceDetails"
										name="tPDInsuranceDetails"
										formControlName="tPDInsuranceDetails"
										class="crt-form-control"
										placeholder="Details"
									></textarea>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="showDisabilityInsurance">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="disabilityInsurance"
											[checked]="+DisabilityInsurance?.value === 1"
											(change)="tickChange($event)"
										/>
										<label
											class="custom-control-label"
											for="disabilityInsurance"
											>Disability Insurance</label
										>
									</div>
								</div>
							</div>
							<div
								class="row scope-of-service__services"
								*ngIf="DisabilityInsurance?.value === 0"
							>
								<div class="col-12 scope-of-service__textbox">
									<textarea
										id="disabilityInsuranceDetails"
										name="disabilityInsuranceDetails"
										formControlName="disabilityInsuranceDetails"
										class="crt-form-control"
										placeholder="Details"
									></textarea>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="showMedicalInsurance">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="medicalInsurance"
											[checked]="+MedicalInsurance?.value === 1"
											(change)="tickChange($event)"
										/>
										<label class="custom-control-label" for="medicalInsurance"
											>Medical Insurance</label
										>
									</div>
								</div>
							</div>
							<div
								class="row scope-of-service__services"
								*ngIf="MedicalInsurance?.value === 0"
							>
								<div class="col-12 scope-of-service__textbox">
									<textarea
										id="medicalInsuranceDetails"
										name="medicalInsuranceDetails"
										formControlName="medicalInsuranceDetails"
										class="crt-form-control"
										placeholder="Details"
									></textarea>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pb-2 pt-2">
			<div class="col-12">
				<div class="row">
					<div class="col-12 scope-of-service__textbox">
						<textarea
							id="instructionOrLimitation"
							name="instructionOrLimitation"
							formControlName="instructionOrLimitation"
							placeholder="Any specific instruction or limitations to the service"
							class="crt-form-control"
						></textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pb-2 pt-2">
			<div class="col-12">
				<div class="row">
					<div class="col-12 scope-of-service__textbox">
						{{ crtData?.otherInformation }}
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pt-2">
			<div class="col-12">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold">Other Advice</label>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 b-2 pt-2">
			<div class="col-12">
				<ng-container *ngIf="showBusinessRiskInsurance">
					<div class="row">
						<div class="col-lg-12 col-12 pb-3">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="businessRiskInsurance"
											[checked]="+BusinessRiskInsurance?.value === 1"
											(change)="tickChange($event)"
										/>
										<label
											class="custom-control-label"
											for="businessRiskInsurance"
											>Business Risk Insurance</label
										>
									</div>
								</div>
							</div>

							<div class="row scope-of-service__services">
								<div
									class="col-12 scope-of-service__textbox"
									*ngIf="+BusinessRiskInsurance?.value === 1"
								>
									{{ crtData?.businessRiskInsuranceNote }}
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="showKiwiSaver">
					<div class="row">
						<div class="col-lg-12 col-12 pb-3">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="kiwiSaver"
											[checked]="+KiwiSaver?.value === 1"
											(change)="tickChange($event)"
										/>
										<label class="custom-control-label" for="kiwiSaver"
											>KiwiSaver</label
										>
									</div>
								</div>
							</div>

							<div class="row scope-of-service__services">
								<div
									class="col-12 scope-of-service__textbox"
									*ngIf="+KiwiSaver?.value === 1"
								>
									{{ crtData?.kiwiSaverNote }}
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="showHomeCarAndContentsInsurance">
					<div class="row">
						<div class="col-lg-12 col-12 pb-3">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="homeCarAndContentsInsurance"
											[checked]="+HomeCarAndContentsInsurance?.value === 1"
											(change)="tickChange($event)"
										/>
										<label
											class="custom-control-label"
											for="homeCarAndContentsInsurance"
											>Home, Car & Contents Insurance</label
										>
									</div>
								</div>
							</div>

							<div class="row scope-of-service__services">
								<div
									class="col-12 scope-of-service__textbox"
									*ngIf="+HomeCarAndContentsInsurance?.value === 1"
								>
									{{ crtData?.homeCarAndContentsInsuranceNote }}
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="showResidentialMortgageOrLending">
					<div class="row">
						<div class="col-lg-12 col-12 pb-3">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="residentialMortgageOrLending"
											[checked]="+ResidentialMortgageOrLending?.value === 1"
											(change)="tickChange($event)"
										/>
										<label
											class="custom-control-label"
											for="residentialMortgageOrLending"
											>Residential Mortgage / Lending</label
										>
									</div>
								</div>
							</div>

							<div class="row scope-of-service__services">
								<div
									class="col-12 scope-of-service__textbox"
									*ngIf="+ResidentialMortgageOrLending?.value === 1"
								>
									{{ crtData?.residentialMortgageOrLendingNote }}
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="showOther">
					<div class="row">
						<div class="col-lg-12 col-12">
							<div class="row scope-of-service__services">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="other"
											[checked]="+Other?.value === 1"
											(change)="tickChange($event)"
										/>
										<label class="custom-control-label" for="other"
											>Other</label
										>
									</div>
								</div>
							</div>

							<div
								class="row scope-of-service__services"
								*ngIf="Other?.value === 1"
							>
								<div class="col-12 scope-of-service__textbox">
									<textarea
										id="otherNotes"
										name="otherNotes"
										formControlName="otherNotes"
										class="crt-form-control"
									></textarea>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</form>
</div>
