import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { TLStaffsService } from '../../../../domain/tl-staff/tl-staffs.service';

@Injectable()
export class TlStaffsResolver implements Resolve<boolean> {

  resolve(): Observable<boolean> {
    const staffs$ = this.tlStaffService.addList();
    return forkJoin([staffs$]).pipe(mapTo(true));
  }

  constructor(private tlStaffService: TLStaffsService) { }
}
