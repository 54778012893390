<div class="main-goals p-4">
  <div class="form-row">
    <div class="column col-lg-12">

      <div class="form-row">
        <div class="col-lg-6">
          <app-short-term-goals
            [APCRTGL]="APCRTGL$ | async"
            [shortTermGoals$]="shortTermGoals$"
            [cRTId$]="cRTId$"
            [isNew]="isNew"
						[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
						></app-short-term-goals>
					</div>
					<div class="col-lg-6">
						<app-long-term-goals
            [APCRTGL]="APCRTGL$ | async"
            [longTermGoals$]="longTermGoals$"
            [cRTId$]="cRTId$"
            [isNew]="isNew"
						[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
						></app-long-term-goals>
					</div>
				</div>

				<div class="border-bottom mb-3"></div>

				<app-retire-goal
        [retireGoals$]="retireGoals$"
        [people]="people$ | async"
        [cRTId$]="cRTId$"
        [isNew]="isNew"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
				></app-retire-goal>

				<div class="border-bottom mb-3"></div>

				<app-client-goals
        [APCRTDP]="APCRTDP$ | async"
        [people$]="people$"
        [dependents$]="dependents$"
        [clientGoals$]="clientGoals$"
        [cRTId$]="cRTId$"
        [isNew]="isNew"
				[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
      ></app-client-goals>
    </div>
  </div>
</div>
