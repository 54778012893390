import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, concatMap, take } from 'rxjs/operators';
import { UserQuery } from '@domain/user/user.query';
import { of } from 'rxjs';
import { StaffSettingsService } from '@modules/user/service/staff-settings.service';

@Injectable()
export class BlStaffSettingsResolver implements Resolve<any> {
	resolve(route: ActivatedRouteSnapshot) {
		const id = route.paramMap.get('id');

		return this.uq.userInfo$.pipe(
			concatMap((user) => {
				const staffId = id || user.StaffID;
				return this.ssService.getBlStaffSettings(+staffId);
			}),
			catchError(() => of([])),
			take(1)
		);
	}
	constructor(private uq: UserQuery, private ssService: StaffSettingsService) {}
}
