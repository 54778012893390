import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import {
	catchError,
	finalize,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ServiceAdviceProcessState } from '../../../../../shared/models/advice-process/advice-process.model';
import { objectUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
@Injectable()
export class LoatDocumentService extends ClientReviewTemplateService {
	adviceProcess$ = this.query.adviceProcess$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	/*-----------------------------*\
   LOAT Related Document Requests
	\*-----------------------------*/

	/**
	 * Advice Process update document
	 * @param id DocumentID
	 * @param field Document in Advice Process CRM
	 * @returns Observable<string>
	 */
	updateAdviceProcess(id: number, type: string) {
		const adviceProcess = this.query.getValue().adviceProcess;
		return this.updateLRAdviceProcess(
			adviceProcess.adviceProcessID,
			id,
			type
		).pipe(take(1));
	}

	getAdviceProcess(adviceProcessId: number) {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			map((data) => (data ? objectUtil.mapPascalCaseToCamelCase(data) : null)),
			tap((data) =>
				applyTransaction(() => {
					this.store.setAdviceProcess(data);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateLRAdviceProcess(
		adviceProcessId: number,
		documentId: number,
		type: string
	) {
		const endpoint = `adviceprocesses/${adviceProcessId}/document/${documentId}/${type}`;
		return this.api
			.put<string>(endpoint)
			.pipe(
				mergeMap(() =>
					this.getAdviceProcess(adviceProcessId).pipe(take(1))
				)
			);
	}
}
