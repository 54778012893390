<div class="search">
  <div class="search-bar">
    <mat-icon
      class="tap-icon align-middle search-icon"
      svgIcon="search"
    />
    <input
      #searchInput
      type="text"
      class="search-input"
      (keyup.enter)="onEnter(searchInput.value)"
      (keyup.esc)="blurSearch()"
      (keyup)="onSearchKeyUp()"
      (keypress)="onSearchKeypress()"
      (focus)="onFocus($event)"
      (blur)="onFocus()"
      placeholder="Search (Name, Email, Phone, Policy Number, etc)"
      aria-label="Search"
      aria-describedby="basic-addon2"
      [(ngModel)]="keyword"
      id="globalSearch"
    />
  </div>
  <button class="tap-btn" (click)="onClose()" appTooltipV2="Close search (esc)">
    <mat-icon
      class="tap-icon align-middle search-icon"
      svgIcon="close"
    ></mat-icon><span class="tap-text">esc</span>
  </button>
</div>
<hr class="divider"/>
<div class="tags">
  <div>
    <mat-icon
      class="tap-icon align-middle search-icon"
      fontIcon="info"
      appTooltipV2="Use the tags to help <br/> filter your search"
    ></mat-icon>
    Tags
  </div>
  
  <button 
    *ngFor="let tag of tags" 
    [ngClass]="'tap-btn ' + (selectedTag === tag.type ? 'selected-tag' : '')"
    (click)="onTagSelect(tag)"  
    [disabled]="tag.disabled"
  >
    <mat-icon
      class="tap-icon align-middle"
      [svgIcon]="tag.icon"
    ></mat-icon>
    <span>{{tag.value}}</span>
    <mat-icon
      *ngIf="selectedTag === tag.type"
      class="tap-icon align-middle search-icon"
      svgIcon="close"
    ></mat-icon>
  </button>
</div>
<div class="results">
  <div class="result-count"><p class="tap-text">Results<span>{{(data$ | async)?.length}}</span></p></div>
  <hr class="divider"/>
  <div class="loader-container" *ngIf="(isLoading$ | async); else results">
    <app-manual-loader-mini
      class="global-search-v2__search-btn"
      [isLoading]="isLoading$ | async"
    ></app-manual-loader-mini>
  </div>
  <ng-template #results>
    <ng-container *ngIf="!!(data$ | async)?.length && (isShown$ | async) && !(noMatchMessage$ | async); then hasResults; else noResults;"></ng-container>
  </ng-template>
  <ng-template #hasResults>
    <div class="has-results">
      <search-result-item 
        #resultItem
        *ngFor="let item of data$ | async; let i = index; trackBy: trackByFn"
        [type]="item.isService ? 'policy' : item.isCompany ? 'business' : 'person'" 
        [profilePicture]="item.photo"
        [name]="item.name" 
        [details]="item.details" 
        [rightDetails]="item.isService ? item.date : ''"
        [routerLink]="item.link"
        [color]="primaryColor"
        (click)="onClose()"
      />
    </div>
  </ng-template>
  <ng-template #noResults>
    <div class="no-result" *ngIf="!(isLoading$ | async) && !!(noMatchMessage$ | async)">
      <mat-icon
        class="tap-icon align-middle search-icon"
        svgIcon="search"
      />
      <h1>No Match</h1>
      <p class="tap-text">{{noMatchMessage$ | async}}</p>
    </div>
  </ng-template>
</div>
