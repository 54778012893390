import { Injectable } from '@angular/core';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaff } from '../../../../../domain/bl-staff/bl-staff.model';
import { Observable, concat, from, of, forkJoin } from 'rxjs';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { applyTransaction } from '@datorama/akita';
import { InvestmentStore } from './investment.store';
import { InvestmentQuery } from './investment.query';
import {
  InvestmentResponse,
  InvestmentRequest,
} from '../investment-request.model';
import * as R from 'ramda';
import {
  ApiService,
  JsonResultStatus,
} from '../../../../../core/base/api.service';
import produce from 'immer';
import {
  mergeMap,
  map,
  shareReplay,
  concatMap,
  reduce,
  withLatestFrom,
  tap,
  finalize,
  take,
  filter,
  switchMap,
  catchError,
  retry,
} from 'rxjs/operators';
import {
  createRowFromPrototype,
  Metakey,
  columns,
} from '../investment-datatable.config';
import { UserQuery } from '../../../../../domain/user/user.query';
import { UserStore } from '../../../../../domain/user/user.store';
import {
  StaffSettings,
} from '../../../../../shared/models/_general/staff-settings.model';
import { ActivityViewModel } from '../../../../../shared/models/_general/activity.viewmodel';
import * as moment from 'moment';
import MomentUtil from '../../../../../util/moment.util';
import { throwError } from 'rxjs';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Injectable()
export class InvestmentService {
  public readonly staffList$: Observable<BLStaff[]> =
    this.blStaffsQuery.availableStaffs$.pipe(
      map((x) =>
        R.filter(
          (y) =>
            y.SecurityGroup === 'BO' ||
            y.SecurityGroup === 'AM' ||
            y.SecurityGroup === 'A',
          x
        )
      )
    );

  public readonly IP$ = this.dropdownValueQuery.orderedChoices$('IP');      // Provider
  public readonly IT$ = this.dropdownValueQuery.orderedChoices$('IT');      // Investment Types
  public readonly IS$ = this.dropdownValueQuery.orderedChoices$('IS');      // Status
  public readonly LRPT$ = this.dropdownValueQuery.orderedChoices$('LRPT');
  public readonly LRCS$ = this.dropdownValueQuery.orderedChoices$('LRCS');
  public readonly PCLE$ = this.dropdownValueQuery.orderedChoices$('PCLE');
	public readonly IPIRR$ = this.dropdownValueQuery.orderedChoices$('IPIRR'); // PIR Rate

  constructor(
    protected api: ApiService,
    private dropdownValueQuery: DropdownValueQuery,
    private blStaffsQuery: BLStaffsQuery,
    private investmentStore: InvestmentStore,
    private investmentQuery: InvestmentQuery,
    private userQuery: UserQuery,
    private userStore: UserStore,
    private activityService: ActivityService,
    private loggerService: LoggerService
  ) {}

  clear(): void {
    applyTransaction(() => {
      this.investmentStore.reset();
      this.investmentStore.uiStore.reset();
    });
  }

  openPopup = () => this.investmentStore.uiStore.toggleColumnPopup(true);
  closePopup = () => this.investmentStore.uiStore.toggleColumnPopup(false);
  togglePopup = () =>
    this.investmentStore.uiStore.toggleColumnPopup(
      !this.investmentQuery.uiQuery.getValue().columnFormPopupOpen
    );

  search2(req: InvestmentRequest) {
    this.investmentStore.uiStore.setSort(null, null);
    const batchLength = 100;

    const getIndexesToFetch: (res: InvestmentResponse) => number[] = R.pipe(
      (res: InvestmentResponse) => Math.ceil(res.TotalCount / batchLength - 1),
      (totalPages: number) =>
        Array(totalPages)
          ?.fill(1)
          ?.map((x, i) => i + 1)
          .slice(1)
    );
    const searchRequest = (request: InvestmentRequest) =>
      this.api.post3<InvestmentResponse>('search/services/i', request);

    const firstPage$ = of(req).pipe(
      map(
        produce((draft) => {
          draft.Paging = {
            Index: 2,
            Column: req.Paging.Column,
            Direction: req.Paging.Direction,
          };
        })
      ),
      mergeMap(searchRequest),
      shareReplay()
    );

    return firstPage$.pipe(
      mergeMap((res) =>
        concat(
          firstPage$,
          from(getIndexesToFetch(res)).pipe(
            map((i) =>
              produce(req, (draft) => {
                draft.Paging = {
                  Index: i + 1,
                  Column: req.Paging.Column,
                  Direction: req.Paging.Direction,
                };
              })
            ),
            concatMap((req2) => searchRequest(req2))
          )
        )
      ),
      reduce((acc, v) =>
        produce(acc, (draft) => {
          draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
          draft.IsComplete = true;
        })
      ),
      map((res) =>
        applyTransaction(() => {
          return res;
        })
      )
    );
  }

  search(req: InvestmentRequest) {
    this.investmentStore.uiStore.setIsSearching(true);

    return this.api.post3<InvestmentResponse>('search/services/i', req).pipe(
      withLatestFrom(this.investmentQuery.templateRow$),
      switchMap(([x, templateRow]) => {
        if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
          // Saves initial fetch
          const rows: any[] = R.map(
            createRowFromPrototype(templateRow),
            x.SearchResults
          );
          this.investmentStore.set([]);
          this.investmentStore.set([...rows]);
          return this.search2(req);
        }
        return of(x);
      }),
      withLatestFrom(this.investmentQuery.templateRow$, (res, template) => ({
        response: res,
        templateRow: template,
      })),
      map((res) =>
        applyTransaction(() => {
          const rows: any[] = R.map(
            createRowFromPrototype(res.templateRow),
            res.response.SearchResults
          );

          if (
            req.Paging.Index === 1 &&
            this.investmentQuery.getCount() !== 0 &&
            res.response.TotalCount > 500
          ) {
            this.investmentStore.set([]);
            res.response.IsComplete = false;
          }

          const a = this.investmentQuery.getAll();
          if (
            req.Paging.Index === 1 &&
            res.response.IsComplete &&
            res.response.TotalCount <= 100
          ) {
            this.investmentStore.set([]);
            this.investmentStore.set([...rows]);
          } else {
            this.investmentStore.set([...a, ...rows]);
          }

          this.investmentStore.update((state) => ({
            ...state,
            count: res.response.TotalCount,
            totalAPI: res.response.TotalAPI,
            isComplete: res.response.IsComplete,
          }));

          return res.response;
        })
      ),
      finalize(() => {
        this.investmentStore.setSearchForm(req);
        this.investmentStore.uiStore.setIsSearching(false);
      })
    );
  }

  /**
   * @param {InvestmentRequest} req request
   * Sets IsExport to true in akita store
   * @returns {Observable} Blob
   * Sets IsExport to false in akita store
   */
  export(req: InvestmentRequest): Observable<any> {
    return this.api.post4('export/services/i', req).pipe(
      map((x) => {
        const obj = this.tryParseJSON(x);
        if (!obj) {
          return new Blob([x], {
            type: 'text/plain',
          });
        } else {
          return obj;
        }
      }),
      retry(1),
      catchError((err) => {
        this.loggerService.Log(err, logMessage.shared.export.error);
        return throwError(new Error(err));
      })
    );
  }

  tryParseJSON(jsonString: any): boolean {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  reloadData() {
    const data = this.investmentQuery.getAll();
    this.investmentStore.set([]);
    this.investmentStore.set([...data]);
  }

  getColumns(): Observable<any> {
    return this.investmentQuery
      .select((x) => x.columns)
      .pipe(
        take(1),
        filter((x) => (x ? x.length < 1 : false)),
        mergeMap((x) => this.userQuery.staffSettings$),
        tap((x) =>
          R.complement(R.either(R.isNil, R.isEmpty))(x)
            ? this.investmentStore.setColumns(
                !!x.InvestmentSearchColumns
                  ? JSON.parse(x.InvestmentSearchColumns)
                  : []
              )
            : null
        )
      );
  }

  getColumnWidths(): Observable<any> {
    return this.investmentQuery
      .select((x) => x.columnWidths)
      .pipe(
        take(1),
        filter((x) => (x ? x.length < 1 : false)),
        mergeMap((x) => this.userQuery.staffSettings$),
        tap((x) =>
          R.complement(R.either(R.isNil, R.isEmpty))(x)
            ? this.investmentStore.setColumnWidths(
                !!x.InvestmentSearchColumnsWidth
                  ? JSON.parse(x.InvestmentSearchColumnsWidth)
                  : []
              )
            : null
        )
      );
  }

  reorderColumn = (oldIndex: number, newIndex: number) => {
    const newCol = produce(this.investmentQuery.getValue().columns, (draft) => {
      const movedCol = draft?.splice(oldIndex, 1);
      draft?.splice(newIndex, 0, movedCol[0]);
    });
    const newColWith = produce(
      this.investmentQuery.getValue().columnWidths,
      (draft) => {
        const movedCol = draft?.splice(oldIndex, 1);
        draft?.splice(newIndex, 0, movedCol[0]);
      }
    );
    return of(newCol).pipe(
      tap(() => {
        this.investmentStore.setColumns(newCol);
        this.investmentStore.setColumnWidths(newColWith);
      }),
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          InvestmentSearchColumns: JSON.stringify(newVal),
          InvestmentSearchColumnsWidth: JSON.stringify(newColWith),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap
          ? of(null)
          : this.api
              .put(`staff/${user.StaffID}/bl`, req)
              .pipe(tap(() => this.userStore.update(req)))
      )
    );
  };

  resizeColumn(prop: string, width: number): Observable<any> {
    const oldColWidths = this.investmentQuery
      .getValue()
      .columnWidths?.filter((x) => x);
    const newColWidths = produce(oldColWidths, (draft) => {
      const col = columns?.find((x) => x.prop === prop);
      const exists = draft?.some((x) => col.metakey === x.metakey);
      if (exists) {
        draft.find((x) => col.metakey === x.metakey).width = width;
      } else {
        draft.push({ metakey: col.metakey, width });
      }
    });

    return of(newColWidths).pipe(
      tap((x) => {
        this.investmentStore.setColumnWidths(x);
      }),
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          InvestmentSearchColumnsWidth: JSON.stringify(newVal),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)
      )
    );
  }

  saveVisibleColumns = (metakeys: Metakey[]) => {
    const newColumns = metakeys;
    const oldColumns = this.investmentQuery.getValue().columns;

    if (R.equals(newColumns, oldColumns)) {
      return of();
    }

    this.investmentStore.uiStore.setIsColumnSaving(true);

    const newColumnMetakeys = newColumns;

    return of(newColumnMetakeys).pipe(
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          InvestmentSearchColumns: JSON.stringify(newVal),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap
          ? of(null)
          : this.api
              .put(`staff/${user.StaffID}/bl`, req)
              .pipe(tap(() => this.userStore.update(req)))
      ),
      finalize(() => this.investmentStore.uiStore.setIsColumnSaving(false)),
      tap(() => this.investmentStore.setColumns(newColumnMetakeys))
    );
  };

  saveField(req: {
    CustomerID: number;
    CustomerServiceID: number;
    MetaKey: Metakey;
    MetaValue: string;
    CustomerServiceType: string;
  }): Observable<JsonResultStatus> {
    const record = this.investmentQuery.getEntity(req.CustomerServiceID);
    const fields = R.omit(
      [
        'CustomerID',
        'CustomerServiceID',
        'link',
        'ActivityId',
        'InvestorList',
        'ClientNextActivityId',
        'UserNextActivityId',
      ],
      record
    );
    const isCustomerDetail = Object.values(fields)?.find(
      (x) => x.metakey === req.MetaKey
    ).isCustomerDetail;

    this.investmentStore.uiStore.setLoad(
      req.CustomerServiceID,
      req.MetaKey,
      true
    );
    const apiUrl = isCustomerDetail
      ? `contacts/${req.CustomerID}?isPatch=true`
      : `services/${req.CustomerServiceID}?isPatch=true`;
    return this.api
      .put<JsonResultStatus>(
        `${apiUrl}`,
        R.omit(['CustomerServiceID', 'CustomerID'], req)
      )
      .pipe(
        tap(() =>
          applyTransaction(() => {
            const stateReq = {
              ...req,
              CustomerServiceId: isCustomerDetail
                ? this.investmentQuery
                    .getAll({
                      filterBy: (row) => row.CustomerID === req.CustomerID,
                    })
                    ?.map((x) => x.CustomerServiceID)
                : req.CustomerServiceID,
            };
            this.investmentStore.updateField(stateReq);
            this.investmentStore.uiStore.setEdit(
              req.CustomerServiceID,
              req.MetaKey,
              false
            );
            this.setTempValue(req.CustomerServiceID, req.MetaKey, undefined);
          })
        ),
        finalize(() =>
          this.investmentStore.uiStore.setLoad(
            req.CustomerServiceID,
            req.MetaKey,
            false
          )
        )
      );
  }

  edit = (customerServiceId: number, metakey: Metakey) =>
    this.investmentStore.uiStore.setEdit(customerServiceId, metakey, true);
  cancel = (customerServiceId: number, metakey: Metakey) =>
    applyTransaction(() => {
      this.investmentStore.uiStore.setEdit(customerServiceId, metakey, false);
      this.setTempValue(customerServiceId, metakey, undefined);
    });

  updateClientAndUserNextActivity = (customerId: number) => {
    return forkJoin(
      this.updateClientNextActivity(customerId),
      this.updateUserNextActivity(customerId)
    );
  };

  updateClientNextActivity = (customerId: number) => {
    const customerServiceIds = this.investmentQuery
      .getAll({ filterBy: (row) => row.CustomerID === customerId })
      ?.map((z) => z.CustomerServiceID);
    customerServiceIds?.forEach((id) => {
      this.investmentStore.uiStore.setLoad(id, 'Client Next Activity', true);
    });
    return this.api
      .get<ActivityViewModel>(`activities/${customerId}/customer`, {
        nextActivityOnly: true,
      })
      .pipe(
        tap((x) => {
          this.investmentStore.update(
            customerServiceIds,
            produce((draft) => {
              if (x) {
                const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
                draft.ClientNextActivityId = x.ActivityId;
                // tslint:disable-next-line: max-line-length
                draft.ClientNextActivity.value =
                  formattedDate +
                  ' - ' +
                  x.AssignedToAdviserName +
                  ' - ' +
                  x.ActivityType +
                  ' - ' +
                  x.ActivityName;
              } else {
                draft.ClientNextActivityId = null;
                draft.ClientNextActivity.value = null;
              }
            })
          );
        }),
        finalize(() => {
          customerServiceIds?.forEach((id) => {
            this.investmentStore.uiStore.setLoad(
              id,
              'Client Next Activity',
              false
            );
          });
        })
      );
  };

  updateUserNextActivity = (customerId: number) => {
    const customerServiceIds = this.investmentQuery
      .getAll({ filterBy: (row) => row.CustomerID === customerId })
      ?.map((z) => z.CustomerServiceID);
    customerServiceIds?.forEach((id) => {
      this.investmentStore.uiStore.setLoad(id, 'User Next Activity', true);
    });
    return this.api
      .get<ActivityViewModel>(`activities/${customerId}/adviser`, {
        nextActivityOnly: true,
      })
      .pipe(
        tap((x) => {
          this.investmentStore.update(
            customerServiceIds,
            produce((draft) => {
              if (x) {
                const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
                draft.UserNextActivityId = x.ActivityId;
                draft.UserNextActivity.value =
                  formattedDate +
                  ' - ' +
                  x.ActivityType +
                  ' - ' +
                  x.ActivityName;
              } else {
                draft.UserNextActivityId = null;
                draft.UserNextActivity.value = null;
              }
            })
          );
        }),
        finalize(() => {
          customerServiceIds?.forEach((id) => {
            this.investmentStore.uiStore.setLoad(
              id,
              'User Next Activity',
              false
            );
          });
        })
      );
  };

  createClientNextActivity = (ac: ActivityViewModel) =>
    of(ActivityViewModel.MapToAdd(ac)).pipe(
      mergeMap((x) => this.activityService.Post(x)),
      map(
        (y) => {
          if (y) {
            return this.updateClientAndUserNextActivity(ac.Customer.CustomerId);
          }
        },
        (o) => o
      )
    );

  sort(propSort: string, sort: 'asc' | 'desc') {
    this.investmentStore.uiStore.setSort(propSort, sort);
  }

  setTempValue = (customerId: number, metakey: string, value: any) =>
    this.investmentStore.uiStore.setTempValue(customerId, metakey, value);

  updateNote = (customerServiceId: number, value: string) => {
    this.investmentStore.update(
      customerServiceId,
      produce((draft) => {
        const nzToday = MomentUtil.createMomentNz().format('DD/MM/YYYY');
        const ln =
          nzToday +
          ' - ' +
          this.userQuery.getValue().FirstName +
          ' ' +
          this.userQuery.getValue().LastName +
          ' - ' +
          value;
        draft.LastNote.value = ln;
      })
    );
  };

  setSearchForm = (req: InvestmentRequest) => {
    this.investmentStore.setSearchForm(req);
  };
}
