import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY, iif, of } from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { ReferralOptionsQuery } from 'src/app/modules/referral-options/state/referral-options.query';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { objectUtil } from 'src/app/util/util';
import { OtherDetailsQuery } from './state/other-details.query';
import { OtherDetailsService } from './state/other-details.service';

import { isNil, isEmpty, forEach } from 'ramda';
import { OtherDetails } from './state/other-details.model';
import { DateInputComponent } from 'src/app/shared/date-input/date-input.component';
import {
	getInvalidWarning,
	Fields,
} from 'src/app/shared/error-message/error-message';
import { LoggerService } from 'src/app/core/logger/logger.service';
import MomentUtil from 'src/app/util/moment.util';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { ComponentBase } from 'src/app/core/base/component-base';
import {
	SecondaryProfessionalState,
	SecondaryProfessionalType,
} from 'src/app/shared/models/client-profile/secondary-professional/secondary-professional.model';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { RoaOtherDetailsMapper } from './state/other-details.mapper';

@Component({
	selector: 'app-other-details',
	templateUrl: './other-details.component.html',
	styleUrls: ['./other-details.component.scss'],
})
export class OtherDetailsComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@ViewChild('expectedSettlementDate')
	expectedSettlementDate: DateInputComponent;

	@Input() parentCRTId: number;
	@Input() adviceProcessId: number;
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
	}>();

	isLoading$ = this.query.selectLoading();
	blanketReferralOptions$ = this.referralQuery.blanketReferrals$;
	detailFieldNames = [
		'name',
		'email',
		'company',
		'preferredBranch',
		'preferredContact',
		'newInsurancePolicyRequired',
		'expectedSettlementDate',
	];

	form: UntypedFormGroup;
	lastSavedData: OtherDetails;
	secondaryProfessionals$ = this.crtMortgageQuery.secondaryProfessionals$.pipe(
		map((x) =>
			x?.filter(
				(i) => i?.professionalType === SecondaryProfessionalType.Solicitor
			)
		)
	);
	solicitors: SecondaryProfessionalState[];

	constructor(
		private service: OtherDetailsService,
		private query: OtherDetailsQuery,
		private fb: UntypedFormBuilder,
		private referralQuery: ReferralOptionsQuery,
		private loggerService: LoggerService,
		private crtMortgageQuery: CrtMortgageQuery,
		private crtMortgageService: CrtMortgageService
	) {
		super();
	}

	get Name() {
		return this.form.get('name');
	}
	get Email() {
		return this.form.get('email');
	}
	get Company() {
		return this.form.get('company');
	}
	get CustomerID() {
		return this.form.get('customerID');
	}

	ngOnInit(): void {
		this.buildForm();
		this.service
			.get(this.parentCRTId)
			.pipe(
				take(1),
				finalize(() => this.prepData())
			)
			.subscribe();
	}

	buildForm() {
		this.form = this.fb.group({
			adviceProcessId: this.adviceProcessId,
			sectionCode: AdviceProcessSectionCodes.OtherDetails,
			cRTId: [null],
			name: [null],
			email: [null, [Validators.email]],
			company: [null],
			preferredBranch: [null],
			preferredContact: [null],
			newInsurancePolicyRequired: [null],
			parentCRTId: this.parentCRTId,
			expectedSettlementDate: null,
			customerID: null,
		});
	}

	prepData() {
		this.secondaryProfessionals$
			.pipe(
				filter((x) => !!x),
				tap((x) => {
					this.solicitors = x;
					const activeOtherDetails = this.query.getActive();
					if (activeOtherDetails) {
						const processedData =
							objectUtil.mapPascalCaseToCamelCase(activeOtherDetails);
						if (processedData.expectedSettlementDate) {
							processedData.expectedSettlementDate =
								MomentUtil.DateStringToMoment(
									processedData.expectedSettlementDate
								);
						}

						processedData.expectedSettlementDate =
							this.form.patchValue(processedData);
						this.lastSavedData = processedData;
						const activeSolicitor = x?.find(
							(i) => i?.customerID === processedData?.customerID
						);
						setTimeout(() => {
							if (processedData?.customerID && activeSolicitor) {
								this.setActiveSolicitor(activeSolicitor?.customerID);
							} else {
								this.setActiveSolicitor(null);
								this.toggleSolicitorInput(true);
							}
						}, 10);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	setActiveSolicitor(customerID) {
		if (customerID) {
			const solicitor = this.solicitors?.find(
				(i) => +i?.customerID === +customerID
			);
			this.toggleSolicitorInput(true);
			this.CustomerID.setValue(customerID);
			this.Name.setValue(solicitor?.name);
			this.Company.setValue(solicitor?.businessName);
			this.Email.setValue(solicitor?.email);
		} else {
			this.toggleSolicitorInput(false);
			this.CustomerID.setValue(null);
			this.Name.setValue(null);
			this.Company.setValue(null);
			this.Email.setValue(null);
		}
	}

	blanketLink(path: string) {
		if (path?.trim() && !!path?.includes('http')) {
			window.open(path, '_blank');
		}
	}

	save(isNext: boolean) {
		if (this.expectedSettlementDate.isInvalid()) {
			this.loggerService.Warning(
				{},
				getInvalidWarning(Fields.ExpectedSettlementDate)
			);
			this.saveCompleted.emit({ isSuccess: false, isNext: false });
			return;
		}
		if (this.areAllDetailsEmpty() || this.areDetailsSame()) {
			this.saveCompleted.emit({ isSuccess: true, isNext });
			return;
		}

		const formValue = this.form.getRawValue();

		if (formValue?.expectedSettlementDate) {
			formValue.expectedSettlementDate =
				formValue.expectedSettlementDate.format('yyyy-MM-DD');
		}
		const data = this.query.getActiveId();
		of(formValue)
			.pipe(
				map((x) => RoaOtherDetailsMapper.mapToUpsert(x)),
				concatMap((x) =>
					iif(() => !!data, this.service.update(x), this.service.add(x))
				),
				delay(100),
				mergeMap(() => this.service.get(+this.parentCRTId)),
				mergeMap(() =>
					this.crtMortgageService.getSecondaryProfessionals(
						this.crtMortgageQuery.getValue().clientId
					)
				),
				take(1),
				catchError(() => {
					this.saveCompleted.emit({ isSuccess: false, isNext: false });
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.prepData();
				this.saveCompleted.emit({ isSuccess: true, isNext });
			});
	}

	areDetailsSame() {
		let isSame = true;
		const searchFormValues = (fieldName) => {
			if (this.lastSavedData) {
				const originalValue = this.lastSavedData[fieldName];
				const fieldValue = this.form.get(fieldName).value;
				if (originalValue?.toString() !== fieldValue?.toString()) {
					isSame = false;
				}
			} else {
				isSame = false;
			}
		};
		forEach(searchFormValues, this.detailFieldNames);
		return isSame;
	}

	areAllDetailsEmpty() {
		let isEmptyB = true;
		const searchFormValues = (fieldName) => {
			const fieldValue = this.form.get(fieldName).value;
			if (!isNil(fieldValue) && !isEmpty(fieldValue)) {
				isEmptyB = false;
			}
		};
		forEach(searchFormValues, this.detailFieldNames);
		return isEmptyB;
	}

	onSelectSolicitor(value) {
		if (value === 'new') {
			this.setActiveSolicitor(null);
		} else {
			this.setActiveSolicitor(value);
		}
	}

	toggleSolicitorInput(status: boolean) {
		if (status) {
			this.Name.disable();
			this.Email.disable();
			this.Company.disable();
		} else {
			this.Name.enable();
			this.Email.enable();
			this.Company.enable();
		}
	}

	clearData() {
		this.service.clearData();
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
