import { computeTotalExpense } from '../../../../_shared/calculations/monthly-expense';
import {
	ExpenseListObjState,
	MonthlyExpenseDetailsState,
} from '../../../../_shared/models/monthly-expense.model';

export class ExpensesMapper {
	public static mapToUpsert(data: MonthlyExpenseDetailsState) {
		const newData = {
			...data,
			expenseList:
				(data?.expenseList as ExpenseListObjState[])?.filter(
					(x) => !!x?.expenseField && !!x.expenseValue
				) || [],
		};
		const totals = computeTotalExpense(newData);
		return {
			...newData,
			totalExpenses: totals?.monthlyTotal,
		};
	}
}
