import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, Subject, throwError, iif } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mergeMap,
	tap,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { ClientProfileService } from 'src/app/modules/crm/client-profile/states/client-profile.service';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { DocumentGroup } from 'src/app/shared/models/documents/document-group.model';
import { objectUtil } from 'src/app/util/util';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../../../state/crt-mortgage.service';
import { CrtMortgageStore } from '../../../../state/crt-mortgage.store';
import { ApplicationDocumentStore } from '../state/documents.store';
import { ApplicationDocument } from './documents.model';

@Injectable({
	providedIn: 'root',
})
export class ApplicationDocumentService extends CrtMortgageService {
	shouldUpdateDocumentSort: Subject<boolean> = new Subject();

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected applicationDocumentStore: ApplicationDocumentStore,
		protected query: CrtMortgageQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected clientProfileService: ClientProfileService,
		protected businessService: BusinessService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	getApplicationDocuments(
		applicationCRTId: number
	): Observable<ApplicationDocument[]> {
		this.applicationDocumentStore.setLoading(true);
		const endpoint = `crt/${AdviceProcessSectionCodes.Application}/${applicationCRTId}/sub-section/${AdviceProcessSectionCodes.ApplicationDocument}`;
		return this.api.get<ApplicationDocument[]>(endpoint).pipe(
			map((x) =>
				(x || [])
					?.map(objectUtil.mapPascalCaseToCamelCase)
					?.sort((a, b) => a.order - b.order)
			),
			tap((x) => this.applicationDocumentStore.set(x || [])),
			finalize(() => this.applicationDocumentStore.setLoading(false)),
			catchError(() => EMPTY)
		);
	}

	add(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.ApplicationDocument,
			AdviceProcessId: this.query.getValue().adviceProcessId,
		};
		this.applicationDocumentStore.setLoading(true);
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.applicationDocumentStore.add(x)),
			finalize(() => this.applicationDocumentStore.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	update(data, refetch?: boolean) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.ApplicationDocument,
			AdviceProcessId: this.query.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap((x) => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.applicationDocumentStore.upsert(x?.cRTId, x)),
			concatMap((x) => {
				if (refetch) {
					return this.getApplicationDocuments(x?.parentCRTId);
				} else {
					return of(x);
				}
			}),
			mergeMap((x) =>
				iif(
					() =>
						// Since linking a image convert it to pdf.
						// This means that a new document will be created and the response from the server has
						// different documentid. We need to fetch and add the newly created document in the client documents lists
						// so we can map the client documentid in document component to show the linked document file name.
						!!x.documentId &&
						+x.documentId > 0 &&
						x.documentId !== data.documentId,
					this.api
						.get(`documents/${x.documentId}`)
						.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x))),
					of(x)
				)
			),
			catchError(() => of(undefined))
		);
	}

	delete(cRTId: number) {
		const endpoint = `crt/${cRTId}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				this.applicationDocumentStore.remove(cRTId);
			}),
			catchError(() => of(undefined))
		);
	}

	uploadDocument(req) {
		const endpoint = 'documents';
		return this.api
			.post3<any>(endpoint, req)
			.pipe(catchError(() => EMPTY))
			.pipe(
				concatMap((x) => this.refetchClientDocument().pipe(map(() => x))),
				catchError(() => of(undefined))
			);
	}

	downloadLink(referenceId) {
		return this.api.get<string>(`documents/download/${referenceId}`);
	}

	refetchClientDocument() {
		return this.clientProfileService
			.getClientDocuments(this.query.getValue().clientId)
			.pipe(catchError(() => of(undefined)));
	}

	/**
	 * Get Client Documents
	 * @param primaryClientId primaryClientId
	 */
	getClientDocuments(primaryClientId: number): Observable<DocumentGroup> {
		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetDocumentsClientId(x)),
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => EMPTY)
		);
	}

	downloadServiceCalculator(req, applicationId) {
		const { banks, adviceProcessId } = req;
		return this.api.postFileDownload(
			`documents/download/servicing-calculator`,
			{
				Bank: banks,
				AdviceProcessId: adviceProcessId,
				ApplicationId: applicationId,
			}
		);
	}

	downloadBankFiles(bankCode: string) {
		const endpoint = `documents/download/other-document/${bankCode}`;
		return this.api.getResponseBlob(endpoint).pipe(catchError(() => EMPTY));
	}

	imageToPDF(data: { document: string; fileName: string }): Observable<string> {
		const endpoint = 'documents/render/image-pdf';
		return this.api.post(endpoint, objectUtil.mapCamelCaseToPascalCase(data));
	}

	updateDocumentSorting(documents: any[]) {
		const endpoint = 'crt/application-document/sort';
		const list = this.applicationDocumentStore.getValue()?.entities || [];

		return this.api.put(endpoint, documents).pipe(
			tap(() => {
				const newDocs = Object.values(list)?.map((doc) => {
					const document = documents.find((d) => d?.CRTId === doc?.cRTId);

					return {
						...doc,
						combine: !!document ? document?.Combine : doc?.combine,
						order: !!document ? document?.Order : doc?.order,
					}
				});

				this.applicationDocumentStore.set(newDocs || []);
			}),
		);
	}
}
