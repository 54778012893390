import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {
	sum,
	subtract,
	divide,
	complement,
	either,
	isNil,
	isEmpty,
} from 'ramda';
import { combineLatest, from, Observable, Subject, throwError } from 'rxjs';
import {
	concatMap,
	delay,
	distinctUntilChanged,
	filter,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	toArray,
	withLatestFrom,
	catchError,
} from 'rxjs/operators';
import { AdviceProcessSectionCodes } from '../../../../../shared/models/advice-process/advice-process.model';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { PeopleState } from '../../../../../shared/models/client-review-template/people/people.model';
import { AssetsLiabilitiesService } from '../../states/assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { LifeQuery } from '../../states/risk-analysis/life/life.query';
import { LifeService } from '../../states/risk-analysis/life/life.service';
import { RiskAnalysisService } from '../../states/risk-analysis/risk-analysis.service';
import { calcUtil } from '../calculations/disability-calculations';
import { convertToAnnual } from '../../income-budget/calculations/annual-conversion';
import { numUtil, util } from '../../../../../util/util';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { DropdownState } from 'src/app/shared/models/client-review-template/risk-analysis/life/life.model';
import { RiskAnalysisLifeState } from 'src/app/shared/models/client-review-template/risk-analysis/life/life.model';
import { PropertyState } from 'src/app/shared/models/client-review-template/assets-liabilities/property.model';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';

@Component({
	selector: 'app-risk-life',
	templateUrl: './life.component.html',
	styleUrls: ['./life.component.scss'],
})
export class LifeComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	form: UntypedFormGroup;
	activePerson: PeopleState;

	people$: Observable<PeopleState[]>;
	activePerson$ = this.rService.activePerson$;
	riskLife$ = this.lifeQuery.selectLoading();
	isLoading = true;
	kiwisaverValue = 0;
	propertyList: PropertyState[] = null;

	APCRTIID$ = this.dropdownValueQuery.orderedChoices$('APCRTIID');
	APCRTESI$ = this.dropdownValueQuery.orderedChoices$('APCRTESI');
	APCRTE$ = this.dropdownValueQuery.orderedChoices$('APCRTE');
	APCRTR$ = this.dropdownValueQuery.orderedChoices$('APCRTR');
	APCRTLTCR$ = this.dropdownValueQuery.orderedChoices$('APCRTLTCR');
	APCRTYN$ = this.dropdownValueQuery.orderedChoices$('APCRTYN');
	APCRTYNNA$ = this.dropdownValueQuery.orderedChoices$('APCRTYNNA');

	isAdviceProcessEnded$ = this.crtQuery.isAdviceProcessEnded$;

	@ViewChild('lifeTabset') lifeTabset: TabsetComponent;

	get ExpensesCouldStop() {
		return this.form.get('expensesCouldStop') as UntypedFormArray;
	}

	get ExpensesCouldStart() {
		return this.form.get('expensesCouldStart') as UntypedFormArray;
	}

	get IncomeCouldStart() {
		return this.form.get('incomeCouldStart') as UntypedFormArray;
	}

	get Properties() {
		return this.form.get('properties') as UntypedFormArray;
	}

	get Assets() {
		return this.form.get('assets') as UntypedFormArray;
	}

	get Loans() {
		return this.form.get('loans') as UntypedFormArray;
	}

	get Requireds() {
		return this.form.get('requireds') as UntypedFormArray;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private crtQuery: ClientReviewTemplateQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private rService: RiskAnalysisService,
		private lifeService: LifeService,
		private lifeQuery: LifeQuery,
		private clientReviewTemplateService: ClientReviewTemplateService,
		private aLService: AssetsLiabilitiesService,
		private cdr: ChangeDetectorRef
	) {
		this.form = this.fb.group({
			sectionCode: [AdviceProcessSectionCodes.Life],
			adviceProcessId: [null],

			lostAfterTaxIncomePerMonth: [null],
			monthlySurplusShortfallLost: [null],

			expensesCouldStop: this.fb.array([]),
			expensesCouldStart: this.fb.array([]),
			incomeCouldStart: this.fb.array([]),

			monthlySurplusShortfallStopStart: [null],
			properties: this.fb.array([]),
			assets: this.fb.array([]),

			loans: this.fb.array([]),
			monthlySurplusShortfallLoans: [null],
			annualSurplusShortfallStopStart: [null],
			yearsShortfallCovered: [null, Validators.required],

			debtRepayment: [null],
			familyIncome: [null],

			requireds: this.fb.array([]),
			totalRequired: [null],
			lessKiwiSaverPaidOut: [null],
			lessNetPropertySales: [null],
			lessAssetSales: [null],
			lifeCoveredRequired: [null],

			parentCRTId: [null],
			cRTId: [null],
		});

		this.people$ = this.rService.getPeopleTabs(false);
		this.activePerson$
			.pipe(
				// distinctUntilChanged(),
				withLatestFrom(this.people$),
				tap(([person, people]) => {
					const index = people.findIndex((x) => x?.cRTId === person?.cRTId);
					if (person && this.lifeTabset?.tabs) {
						this.lifeTabset.tabs[index].active = true;
					}
				}),
				tap(([person]) => {
					if (!!person) {
						this.setActivePerson(person);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		combineLatest([this.crtQuery.properties$, this.people$])
			.pipe(
				filter(([properties, people]) => Boolean(properties) && Boolean(people)),
				take(1),
			)
			.subscribe(([, people]) => {
				if (people?.[0]) {
					this.setActivePerson(people[0]);
					// this.cdr.detectChanges();
				}
			});
	}

	ngOnInit(): void {
		this.disabledFields();
		this.crtQuery.people$
			.pipe(
				filter((people) => !!people),
				map((people) => people?.filter((p) => !!p.cRTId)),
				tap((people) => {
					if (people && people?.length > 0) {
						this.rService.setCurrentPersonIndex(0, true);
					}
				}),
				take(1)
			)
			.subscribe();

		this.lifeQuery
			.selectEntityAction()
			.pipe(
				filter((data) => !!data),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	disabledFields() {
		this.form.get('lostAfterTaxIncomePerMonth').disable();
		this.form.get('monthlySurplusShortfallLost').disable();
		this.form.get('monthlySurplusShortfallStopStart').disable();
		this.form.get('monthlySurplusShortfallLoans').disable();
		this.form.get('annualSurplusShortfallStopStart').disable();
		this.form.get('debtRepayment').disable();
		this.form.get('familyIncome').disable();
		this.form.get('totalRequired').disable();
		this.form.get('lessKiwiSaverPaidOut').disable();
		this.form.get('lessNetPropertySales').disable();
		this.form.get('lessAssetSales').disable();
		this.form.get('lifeCoveredRequired').disable();
	}

	getProperties(formData?: RiskAnalysisLifeState) {
		return this.crtQuery.properties$.pipe(
			filter((data) => !!data),
			concatMap((data) =>
				from(data).pipe(
					mergeMap((item) =>
						this.aLService
							.getInfoByCRT(item.cRTId)
							.pipe(map((detail) => ({ ...item, ...detail })))
					),
					toArray()
				)
			),
			tap((data) => (this.propertyList = data)),
			map(() => formData),
			take(1)
		);
	}

	prepareData() {
		this.kiwisaverValue = 0;
		this.form.reset();
		this.clearFormArray(this.ExpensesCouldStop);
		this.clearFormArray(this.ExpensesCouldStart);
		this.clearFormArray(this.IncomeCouldStart);
		this.clearFormArray(this.Requireds);
		this.clearFormArray(this.Properties);
		this.clearFormArray(this.Assets);
		this.clearFormArray(this.Loans);

		combineLatest([
			this.lifeQuery.selectEntity(this.activePerson?.cRTId),
			this.riskLife$,
		])
			.pipe(
				filter(([, tpd]) => !tpd),
				map(([data]) => data),
				map((x) => {
					const expensesCouldStop = x?.expensesCouldStop ?? [];
					const expensesCouldStart = x?.expensesCouldStart ?? [];
					const incomeCouldStart = x?.incomeCouldStart ?? [];
					const requireds = x?.requireds ?? [];
					if (x) {
						return {
							...x,
							expensesCouldStop,
							expensesCouldStart,
							incomeCouldStart,
							requireds,
						};
					} else {
						return x;
					}
				}),
				tap(() => (this.isLoading = true)),
				concatMap((data) => this.getProperties(data)),
				tap((x) => {
					{
						this.populateExpenseIncome(x);
						this.populatePropertyAsset(x);
						this.kiwisaverValue = this.rService.getKiwisaverPaidOut(
							+this.activePerson?.cRTId
						);
						const { loans, properties, assets, ...others } = x || {};

						const newData = {
							...others,
							properties: this.Properties.getRawValue(),
							assets: this.Assets.getRawValue(),
							loans: this.Loans.getRawValue(),
						};

						this.form?.patchValue({
							...others,
							...this.getComputations(newData),
							sectionCode: AdviceProcessSectionCodes.Life,
							parentCRTId: this.activePerson?.cRTId ?? null,
							dviceProcessId: this.crtQuery.getValue().adviceProcessId ?? null,
						});

						this.form.get('lessKiwiSaverPaidOut').setValue(this.kiwisaverValue);

						if (this.form.get('cRTId').value) {
							this.onChange();
						}
					}
				}),
				tap(() => (this.isLoading = false)),
				tap(() => this.form.markAllAsTouched()),
				withLatestFrom(this.crtQuery.isAdviceProcessEnded$),
				tap(([x, apEnded]) => {
					if (apEnded) {
						this.form.disable();
					}
				}),
				map(([x]) => x),
				take(1)
			)
			.subscribe();
	}

	populateExpenseIncome(x: RiskAnalysisLifeState) {
		this.clearFormArray(this.ExpensesCouldStop);
		this.clearFormArray(this.ExpensesCouldStart);
		this.clearFormArray(this.IncomeCouldStart);
		this.clearFormArray(this.Requireds);
		if (x) {
			(x.expensesCouldStop.length > 0
				? x.expensesCouldStop
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) => this.ExpensesCouldStop?.push(this.pushArrayList(l)));
			(x.expensesCouldStart.length > 0
				? x.expensesCouldStart
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) => this.ExpensesCouldStart?.push(this.pushArrayList(l)));
			(x.incomeCouldStart.length > 0
				? x.incomeCouldStart
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) => this.IncomeCouldStart?.push(this.pushArrayList(l)));
			(x.requireds.length > 0
				? x.requireds
				: [
						{
							dropdownValue: '',
							dropdown: '',
							value: '',
						},
				  ]
			)?.forEach((l) => this.Requireds?.push(this.pushArrayList(l)));
		} else {
			this.ExpensesCouldStop?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.ExpensesCouldStart?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.IncomeCouldStart?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.Requireds?.push(
				this.pushArrayList({
					dropdownValue: null,
					dropdown: null,
					value: 0,
				})
			);
		}
	}

	populatePropertyAsset(data: RiskAnalysisLifeState) {
		const getProperty = (val) => {
			if (data) {
				const res = (data.properties ?? [])?.find((x) => x.cRTId === val);
				return res?.sell || 'No';
			}
			return '';
		};

		const getAsset = (val) => {
			if (data) {
				const res = (data.assets ?? [])?.find((x) => x.cRTId === val);
				return res?.sell || 'No';
			}
			return '';
		};

		// Both Mortgage and Liabilities are saved under Loans list

		const getMortgage = (val) => {
			if (data) {
				const res = (data.loans ?? [])?.find((x) => x.cRTId === val);
				return res?.repay || '';
			}
			return '';
		};

		const getLiability = (val) => {
			if (data) {
				const res = (data.loans ?? [])?.find((x) => x.cRTId === val);
				return res?.repay || '';
			}
			return '';
		};

		const query = this.crtQuery.getValue();
		this.clearFormArray(this.Properties);
		(query?.properties ?? [])?.forEach((property) => {
			const propertyForm = this.fb.group({
				property: [
					{
						value: property.propertyAddress || 0,
						disabled: true,
					},
				],
				value: [
					{
						value: property.propertyValue || 0,
						disabled: true,
					},
				],
				sell: getProperty(property.cRTId),
				cRTId: property.cRTId,
				customerServiceId: property.customerServiceID,
			});
			this.Properties?.push(propertyForm);
		});

		this.clearFormArray(this.Assets);
		(query?.assets ?? [])?.forEach((asset) => {
			const assetForm = this.fb.group({
				assets: [
					{
						value: asset.asset || 0,
						disabled: true,
					},
				],
				value: [
					{
						value: asset.value || 0,
						disabled: true,
					},
				],
				sell: getAsset(asset.cRTId),
				cRTId: asset.cRTId,
			});
			this.Assets?.push(assetForm);
		});

		this.clearFormArray(this.Loans);
		(query?.mortgages ?? [])?.forEach((mortgage) => {
			if (mortgage.cRTId) {
				const loanForm = this.fb.group({
					lender: [
						{
							value: mortgage.lender || 0,
							disabled: true,
						},
					],
					value: [
						{
							value: mortgage?.loanValue || 0,
							disabled: true,
						},
					],
					loanRepayment: mortgage?.loanRepayment,
					repay: [getMortgage(mortgage.cRTId), Validators.required],
					cRTId: mortgage.cRTId,
				});
				this.Loans?.push(loanForm);
			}
		});

		(query?.liabilities ?? [])?.forEach((liability) => {
			const liabilityForm = this.fb.group({
				lender: [
					{
						value: liability?.liability || 0,
						disabled: true,
					},
				],
				value: [
					{
						value: liability?.loanBalance || 0,
						disabled: true,
					},
				],
				loanRepayment: liability?.loanRepayment,
				repay: [getLiability(liability.cRTId), Validators.required],
				cRTId: liability.cRTId,
			});
			this.Loans?.push(liabilityForm);
		});
	}

	computeNetRental(data) {
		const rentalIncome = convertToAnnual(
			+data.rentalIncome || 0,
			data.rentalIncomeFrequency
		);
		const rate = convertToAnnual(+data.rates || 0, data.ratesFrequency);
		const other = convertToAnnual(
			+data.otherExpense || 0,
			data.otherExpenseFrequency
		);
		const insurance = convertToAnnual(
			+data.insurance || 0,
			data.insuranceFrequency
		);
		const sumROI = sum([rate, other, insurance]);
		const totalNetRental = +numUtil.formatToNumCurrency(
			subtract(rentalIncome, subtract(sumROI, 0))
		);
		return divide(totalNetRental, 12);
	}

	getComputations(data) {
		const id = +this.activePerson?.cRTId;
		const lostAfterTaxIncomePerMonth =
			+this.rService.getLostAfterTaxIncomePerMonth(id);
		const monthlySurplusShortfallLost =
			+this.rService.getMonthlySurplusOrShortfallLost(id);

		let monthlySurplusShortfallStopStart = calcUtil.incomeAddArray(
			monthlySurplusShortfallLost,
			this.ExpensesCouldStop.getRawValue(),
			this.IncomeCouldStart.getRawValue()
		);

		monthlySurplusShortfallStopStart = calcUtil.incomeLessArray(
			monthlySurplusShortfallStopStart,
			this.ExpensesCouldStart.getRawValue()
		);

		const assetsValue = +this.rService.formatCurrency(
			data?.assets
				?.filter(({ sell }) => sell === 'Yes')
				?.reduce((sum, asset) => sum + +asset.value, 0)
		);

		const propertiesValue = +this.rService.formatCurrency(
			data?.properties
				?.filter(({ sell }) => sell === 'Yes')
				?.reduce((sum, property) => sum + +property.value, 0)
		);

		const isPropertyInvestment = (prop) => {
			const property = (this.propertyList ?? [])?.find(
				(x) =>
					x?.propertyAddress === prop?.property &&
					x?.propertyUse === 'Investment'
			);

			if (complement(either(isNil, isEmpty))(property)) {
				return this.computeNetRental(property) || 0;
			}
			return 0;
		};

		const monthlyNetRental = +this.rService.formatCurrency(
			data?.properties
				?.filter((p) => p?.sell === 'Yes')
				?.reduce((sum, p) => sum + +(isPropertyInvestment(p) || 0), 0)
		);
		const loans = this.Loans.getRawValue() || [];

		const loansValue = this.rService.getTotalLoanValue(
			loans,
			this.crtQuery.getValue().mortgages,
			this.crtQuery.getValue().liabilities
		);

		const debtRepayment = +this.rService.formatCurrency(
			loans
				?.filter(({ repay }) => repay)
				?.reduce(
					(sum, loan) => sum + (+loan.value * parseFloat(loan.repay)) / 100,
					0
				)
		);

		const monthlySurplusShortfallLoans = +this.rService.formatCurrency(
			+monthlySurplusShortfallStopStart + loansValue - +monthlyNetRental
		);
		const annualSurplusShortfallStopStart = +this.rService.formatCurrency(
			monthlySurplusShortfallLoans * 12
		);

		const lessKiwiSaverPaidOut = this.kiwisaverValue;
		const lessNetPropertySales = propertiesValue || 0;
		const lessAssetSales = assetsValue || 0;
		const yearsShortfallCovered = data?.yearsShortfallCovered || 0;

		let totalExpense = 0;
		if (data?.requireds) {
			totalExpense += calcUtil.incomeLessArray(0, data.requireds) * -1;
		}

		const familyIncome = +this.rService.getFamilyIncome(
			+yearsShortfallCovered,
			+annualSurplusShortfallStopStart
		);

		const totalRequired = +this.rService.formatCurrency(
			debtRepayment + familyIncome + +totalExpense
		);
		const lifeCoveredRequired = +this.rService.getCoverRequired(
			+totalRequired,
			+propertiesValue,
			+assetsValue,
			+this.kiwisaverValue
		);

		return {
			lostAfterTaxIncomePerMonth,
			monthlySurplusShortfallLost,
			monthlySurplusShortfallStopStart,
			lessKiwiSaverPaidOut,
			monthlySurplusShortfallLoans,
			annualSurplusShortfallStopStart,
			lessNetPropertySales,
			lessAssetSales,
			debtRepayment,
			familyIncome,
			totalRequired,
			lifeCoveredRequired,
		};
	}

	onChange() {
		this.setFormValueWithExpense();
	}

	onChangeOther(event: Event, item: UntypedFormGroup) {
		if (!(event.target as HTMLSelectElement).value) {
			item.get('dropdown').setValue('');
		}
		this.setFormValueWithExpense();
	}

	setFormValueWithExpense() {
		const formValue = this.form.getRawValue() as RiskAnalysisLifeState;
		if (formValue.expensesCouldStart?.some((z) => !z.dropdown && !z.value)) {
			const expensesCouldStartList = util.removeEmptyObjsFromArr(
				formValue.expensesCouldStart
			);
			formValue.expensesCouldStart = isEmpty(expensesCouldStartList)
				? null
				: expensesCouldStartList;
		}
		if (formValue.expensesCouldStop?.some((z) => !z.dropdown && !z.value)) {
			const expensesCouldStopList = util.removeEmptyObjsFromArr(
				formValue.expensesCouldStop
			);
			formValue.expensesCouldStop = isEmpty(expensesCouldStopList)
				? null
				: expensesCouldStopList;
		}
		if (formValue.incomeCouldStart?.some((z) => !z.dropdown && !z.value)) {
			const incomeCouldStartList = util.removeEmptyObjsFromArr(
				formValue.incomeCouldStart
			);
			formValue.incomeCouldStart = isEmpty(incomeCouldStartList)
				? null
				: incomeCouldStartList;
		}
		if (formValue.requireds?.some((z) => !z.dropdown && !z.value)) {
			const requiredsList = util.removeEmptyObjsFromArr(formValue.requireds);
			formValue.requireds = isEmpty(requiredsList) ? null : requiredsList;
		}

		const computations = this.getComputations(formValue);
		this.form.patchValue(computations);

		this.lifeService.setFormValue({
			...formValue,
			...computations,
			sectionCode: AdviceProcessSectionCodes.Life,
			parentCRTId: this.activePerson?.cRTId ?? null,
			adviceProcessId: this.crtQuery.getValue().adviceProcessId ?? null,
			requireds: formValue.requireds?.map<DropdownState>((x) => ({
				...x,
				dropdownValue: x.dropdown === 'Other' ? x.dropdownValue : '',
				value: +x.value,
			})),
		});
	}

	setActive(index: number) {
		this.rService.setCurrentPersonIndex(index, true).pipe(take(1)).subscribe();
	}

	setActivePerson(data: PeopleState) {
		this.activePerson = data;
		this.lifeService.setActiveParentCRTId(data.cRTId);

		this.prepareData();
	}

	pushArrayList(data: {
		dropdownValue?: string;
		dropdown: string;
		value: string | number;
	}) {
		return this.fb.group({
			dropdownValue: [data.dropdownValue],
			dropdown: [data.dropdown],
			value: [data.value],
		});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
	};

	addExpenseStop() {
		this.ExpensesCouldStop?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addIncome() {
		this.IncomeCouldStart?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addExpenseStart() {
		this.ExpensesCouldStart?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addRequired() {
		this.Requireds?.push(
			this.fb.group({
				dropdownValue: [''],
				dropdown: [''],
				value: [''],
			})
		);
	}

	removeList(formArray: UntypedFormArray, index: number) {
		formArray.removeAt(index);
		this.onChange();
	}

	clear(formArray: UntypedFormArray, index: number) {
		formArray.at(index).get('dropdown').setValue('');
		formArray.at(index).get('value').setValue('');
		this.onChange();
	}

	getFormArrayByIndex(
		formControlName: string,
		index: number,
		property: string
	): AbstractControl {
		return this.form.get(`${formControlName}.${index}.${property}`);
	}

	onClickCheckBoxSell(formControlName: string, index: number, val: string) {
		const prop: AbstractControl = this.getFormArrayByIndex(
			formControlName,
			index,
			'sell'
		);
		const value = prop.value === val ? 'No' : val;

		prop.setValue(value);
		this.onChange();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
