import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ApplicationDocument } from './documents.model';

export interface ApplicationDocumentState extends EntityState<ApplicationDocument> {
	applicationDocuments: ApplicationDocument[];
}

@Injectable()
@StoreConfig({ name: 'applicationDocument', idKey: 'cRTId' })
export class ApplicationDocumentStore extends EntityStore<ApplicationDocumentState, ApplicationDocument> {
	constructor() {
		super({
			applicationDocuments: [],
		});
	}
}
