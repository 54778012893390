import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DataImportService } from '../data-import.component.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfigService } from '../../../core/config/config.service';
declare var $: any;
@Component({
  selector: 'app-import-history',
  templateUrl: './import-history.component.html'
})
export class ImportHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  constructor(
    private dataImportService: DataImportService,
    protected configService: ConfigService,
    private fb: UntypedFormBuilder
  ) { }

  companyCode$ = this.configService.CompanyCode;
  histories$: Observable<any>;
  isLoading = false;

  public form = this.fb.group({
    importType: ['', Validators.required]
  });

  ngOnInit() {
    this.isLoading = true;
    this.dataImportService
      .getImportHistory(this.form.get('importType').value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(y => {
        // todo
        // if (y.info && y.info.length > 0) {
        if (y) {
          this.histories$ = of(y?.sort((a, b) => b.ImportedStartDateFormatted - a.ImportedStartDateFormatted));
        }
        this.isLoading = false;
      });
  }

  ngAfterViewInit(): void {
    $(document).ready(() => {
      $('.selectpicker').selectpicker();
    });
  }

  refresh() {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
