import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'ramda';
import { ApplicationStepsComponent } from './application-steps.component';
import { MoatApplicationStepHeader } from 'src/app/shared/models/advice-process/advice-process.model';

@Injectable()
export class CrtMortgageApplicationStepsCanDeactivateGuard
	implements CanDeactivate<ApplicationStepsComponent>
{
	canDeactivate(
		component: ApplicationStepsComponent
	): Observable<boolean> | boolean {
		return component.activeApplication$.pipe(
			switchMap((x) => {
				if (x.currentPage === MoatApplicationStepHeader.Documents) {
					return component.documents.updateSort().pipe(
						switchMap(() => of(true))
					);
				} else {
					switch (x.currentPage) {
						case MoatApplicationStepHeader.Purpose: {
							component.purpose.save(false, false, false);
							break;
						}
						case MoatApplicationStepHeader.FundingRequired: {
							component.cashDeposit.save(false, false, false);
							component.sellingSection?.clear();
							component.topups?.clear();
							break;
						}
						case MoatApplicationStepHeader.Security: {
							component.security.save(false, false, false);
							break;
						}
						case MoatApplicationStepHeader.Notes: {
							component.notes.save(false, false);
						}
					}
					return of(true);
				}
			}),
			catchError(() => of(true))
		);
	}
}
