import { LrInsuranceRequest } from '../../shared/models/_general/services/lrInsurance';
import { Security } from '../../shared/models/_general/services/security';
import { MortgageRequest } from '../../shared/models/_general/services/mortgage';
import { FgInsuranceRequest } from '../../shared/models/_general/services/fgInsurance';
import { KiwisaverRequest } from '../../shared/models/_general/services/kiwisaver';
import { BaseService } from './base/base.service';


export class LrInsuranceService extends BaseService<LrInsuranceRequest> {

}
export class SecurityService extends BaseService<Security> {

}
export class MortgageService extends BaseService<MortgageRequest> {

}
export class FgInsuranceService extends BaseService<FgInsuranceRequest> {

}
export class KiwisaverService extends BaseService<KiwisaverRequest> {

}
