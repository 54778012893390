import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CashDepositState } from './cash-deposit.model';
import { CashDepositStore } from './cash-deposit.store';

@Injectable()
export class CashDepositQuery extends QueryEntity<CashDepositState> {
	cashDeposit$ = this.selectAll();
	activeCashDeposit$ = this.selectActive();

	constructor(protected store: CashDepositStore) {
		super(store);
	}
}
