import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import {
  DocumentModelState,
  DocumentUploadState,
} from '../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../util/util';
import { ApiService } from '../../../../../core/base/api.service';
import { ReviewApplicationEmailSettingsState } from './review-application-email-settings.model';
import { MortgageSettingsQuery } from '../../../state/mortgage-settings.query'
import { MortgageSettingsStore } from '../../../state/mortgage-settings.store'

@Injectable()
export class ReviewApplicationEmailSettingsService {

  reviewApplicationEmailSettings$ = this.query.reviewApplicationEmail$;

  constructor(
    private api: ApiService,
    private store: MortgageSettingsStore,
    private query: MortgageSettingsQuery
  ) {}

  getReviewApplicationEmailSettings(data: number, settingsCode: string) {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<ReviewApplicationEmailSettingsState>(endpoint).pipe(
      tap((res) =>
        applyTransaction(() => {
          const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
          this.store.setReviewApplicationEmailSettings(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateReviewApplicationEmailSettings(data: ReviewApplicationEmailSettingsState) {
    const newValue = {
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<ReviewApplicationEmailSettingsState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setReviewApplicationEmailSettings(newValue);
        })
      ),
      take(1)
    );
  }

  newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(
			`${url}?p=${Math.floor(Date.now() / 1000)}`,
			{ responseType: 'text' }
		);
  }
}
