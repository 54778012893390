import { freqToAnnual } from './../../../../_shared/calculations/annual-conversion';
const compoundPeriod = 12;

const monthlyInterestRate = (inrestRate: number, frequency: number) =>
	(Math.pow(1 + inrestRate / 100 / compoundPeriod, compoundPeriod / frequency) -
		1) *
	100;

const pmt = (
	ratePerPeriod,
	numberOfPayments,
	presentValue,
	futureValue,
	type
) => {
	if (ratePerPeriod !== 0.0) {
		// Interest rate exists
		const q = Math.pow(1 + ratePerPeriod, numberOfPayments);
		return (
			-(ratePerPeriod * (futureValue + q * presentValue)) /
			((-1 + q) * (1 + ratePerPeriod * type))
		);
	}
	if (numberOfPayments !== 0.0) {
		// No interest rate, but number of payments exists
		return -(futureValue + presentValue) / numberOfPayments;
	}

	return 0;
};

export const calculateLoanRepayment = (
	loanAmount: number,
	interestRate: number,
	loanTerm: number,
	frequency: string,
	loanType: string,
) => {
	if (!frequency) {
		return;
	}

	if (['P&I', 'Principal & Interest'].includes(loanType)) {
		const repayments = pmt(
			monthlyInterestRate(interestRate, freqToAnnual[frequency]) / 100,
			loanTerm * freqToAnnual[frequency],
			loanAmount,
			0,
			0
		);
		return Math.abs(repayments);
	}
	else if(loanType === 'Interest Only' || loanType === 'Revolving Credit') {
		const repayments = ((interestRate / 100) / freqToAnnual[frequency]) * loanAmount;
		return Math.abs(repayments);
	}
};
