import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, of, Subject } from 'rxjs';
import {
	concatMap,
	delay,
	exhaustMap,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { CurrentInsuranceMapper } from '../../../../../../shared/models/client-review-template/current-insurance/current-insurance.mapper';
import { CurrentInsuranceState } from '../../../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { ProposedInsuranceState } from '../../../../../../shared/models/client-review-template/statement-of-advice/insurance/soa-insurance.model';
import { BusinessConfigQuery } from '../../../../../../domain/business-config/business-config.query';
import { CurrentInsuranceModalComponent } from '../../../../../../shared/modal/crt/fact-find/current-insurance/current-insurance-modal.component';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { ClientReviewTemplateQuery } from '../../../states/client-review-template.query';
import { ClientReviewTemplateService } from '../../../states/client-review-template.service';
import { StatementOfAdviceQuery } from '../../../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../../../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../../../states/statement-of-advice/statement-of-advice.store';
import { SOASubSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { addIndex, forEach, sort } from 'ramda';

@Component({
	selector: 'app-soa-current-insurance',
	templateUrl: './current-insurance.component.html',
})
export class SoaCurrentInsuranceComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() parentCRTId: number;
	@Input() viewMode: string;
	@Output() nextPage = new EventEmitter<void>();
	@Output() backPage = new EventEmitter<void>();

	documents: any[];

	providerHeader: string;

	themeConfig$ = this.businessConfigQuery.themeConfig$;
	primaryColor: string;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	transferedSCI: PeopleState[];

	lifeAssured$ = this.query.peopleFromCrmAndCrtExceptChildChoices$;

	APCRTF$ = this.service.APCRTF$;
	APCRTB$ = this.service.APCRTB$;
	APCRTNE$ = this.service.APCRTNE$;
	LRP$ = this.service.LRP$;
	LRPR$ = this.service.LRPR$;
	people$ = this.service.peopleEntities$;

	lifeAssuredList: ViewDisplayValue[] = [];
	elseMinusProvider = true;

  soaCurrentInsurances$ = this.soaQuery.soaCI$.pipe(
		map((x) => x || []),
    map((x: CurrentInsuranceState[]) => {
      const indexedForEach = addIndex(forEach);
      return indexedForEach((insurance: CurrentInsuranceState, index) => insurance.id = index, x);
    }),
    map((x: CurrentInsuranceState[]) => sort((a, b) => a.priority - b.priority, x))
	);

	constructor(
		private businessConfigQuery: BusinessConfigQuery,
		private modalService: BsModalService,
		private service: ClientReviewTemplateService,
		private query: ClientReviewTemplateQuery,
		private statementOfAdviceService: StatementOfAdviceService,
		private soaStore: StatementOfAdviceStore,
		private soaQuery: StatementOfAdviceQuery,
		private renderer: Renderer2
	) {}

	ngOnInit(): void {
		this.query.transferedSCIList$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.transferedSCI = x));

		this.service.lifeAssured$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.lifeAssuredList = x));
	}

	syncDataCI() {
		this.soaStore.setSOALoading(true, SOASubSectionCodes.CurrentInsurance);
		this.statementOfAdviceService
			.syncProposedInsurance(this.parentCRTId)
			.pipe(
				mergeMap(() =>
					this.statementOfAdviceService.getCurrentInsurance(this.parentCRTId)
				),
				finalize(() => this.soaStore.setSOALoading(false, SOASubSectionCodes.CurrentInsurance))
      )
			.subscribe();
	}

	addCurrentInsurance = (model: CurrentInsuranceState) => {
		let newCrtId;
		return new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			switchMap((x) =>
				this.statementOfAdviceService.addCurrentInsurance(x, this.parentCRTId)
			),
			tap((x) => (newCrtId = x)),
			delay(500),
			mergeMap(() =>
				this.statementOfAdviceService.getCurrentInsurance(this.parentCRTId)
			),
			map((data) => data?.find((ci) => +ci.cRTId === +newCrtId)),
			switchMap((data) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.statementOfAdviceService
							.uploadDocument(
								{
									...model,
									sectionCode: SOASubSectionCodes.CurrentInsurance,
									linkedCRTId: data?.linkedCRTId,
								},
								data?.cRTId
							)
							.pipe(map(() => data?.cRTId))
					: of(data?.cRTId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const currentInsurances = this.soaQuery.getValue().currentInsurance;
					const m = currentInsurances?.find((ci) => ci.cRTId === crtId);
					return this.statementOfAdviceService.updateCurrentInsurance(
						m,
						this.parentCRTId
					);
				}
				return of(crtId);
			}),
			map((x) => ({...model, cRTId: +x})),
		);
	};

	updateCurrentInsurance = (model: ProposedInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				this.statementOfAdviceService.updateCurrentInsurance(
					x,
					this.parentCRTId
				)
			),
			switchMap((crtId) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.statementOfAdviceService
					.uploadDocument({ ...model, sectionCode: SOASubSectionCodes.CurrentInsurance }, model.cRTId)
					.pipe(map(() => crtId))
					: of(crtId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const currentInsurances = this.soaQuery.getValue().currentInsurance;
					const m = currentInsurances?.find((ci) => ci.cRTId === model.cRTId);
					return this.statementOfAdviceService.updateCurrentInsurance(
						m,
						this.parentCRTId
					);
				}
				return of(crtId);
			}),
			map(() => model)
		);

	addProduct() {
		const confirm = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Current L&R Details',
			message: `CurrentL&RDetails`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			savefn: this.addCurrentInsurance,
			decline$: decline,
			hideNotes: true,
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	editProduct(insurance) {
		const confirm = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Current L&R Details',
			message: `CurrentL&RDetails`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			currentInsuranceInfo: insurance,
			savefn: this.updateCurrentInsurance,
			decline$: decline,
			hideNotes: true,
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	deleteCurrentInsurance(id) {
		this.statementOfAdviceService
			.deleteCurrentInsurance(id)
			.pipe(take(1))
			.subscribe();
	}

	convertLifeAssuredToName(id: number) {
		const name = CurrentInsuranceMapper.getLifeAssuredName(
			id,
			this.lifeAssuredList,
			this.transferedSCI
		);
		return name || '';
	}

	getSumOfLifeAssuredList(lifeAssured) {
		return (
			lifeAssured?.lifeAssured?.reduce(
				(a, b) => Number(a) + Number(b.premium),
				0
			) +
			(!lifeAssured?.policyFee || lifeAssured?.policyFee === 'N/A'
				? 0
				: +lifeAssured?.policyFee)
		);
	}

	allowDropdown = (product) =>
		product
			? [
					'Medical Base Plan',
					'Specialists & Tests',
					'Medical',
					'Medical + S&T',
			  ].includes(product)
			: false;

	next() {
		const paymentFrequency =
			this.soaQuery.getValue()?.currentInsurance[0]?.premiumFrequency;
		const soa = !!paymentFrequency ? { paymentFrequency } : {};
		this.statementOfAdviceService
			.updateSoa(this.parentCRTId, SOASubSectionCodes.ProposedInsurance, soa)
			.pipe(
				tap(() => this.soaStore.updateActive({ currentPage: SOASubSectionCodes.ProposedInsurance })),
        concatMap((x) =>
					this.statementOfAdviceService.getProposedInsurance(
						this.soaQuery.getActive()?.cRTId
					)
				),
				take(1)
			)
			.subscribe();
	}

	back() {
		this.statementOfAdviceService
			.updateSoa(this.parentCRTId, SOASubSectionCodes.SOS)
			.pipe(
				take(1),
				finalize(() => this.soaStore.updateActive({ currentPage: SOASubSectionCodes.SOS }))
      )
			.subscribe();
	}

	downloadLink(id) {
		this.statementOfAdviceService
			.downloadLink(id)
			.pipe(
				tap((x) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
				}),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
