<button class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()" id="closeNoteModal">
  <span class="material-icons">
    close
  </span>
</button>
<div class="modal-header">
  <h5 class="modal-title">
    {{ header }}
  </h5>
</div>
<div>
  <form novalidate [formGroup]="form">
    <div class="form-group m-0">
      <div class="clearfix">
        <textarea class="form-control" name="notes" id="note" cols="30" rows="6" formControlName="notes"></textarea>
      </div>
    </div>

    <div class="justify-content-end float-right modal-footer">
      <ng-container *ngIf="!isSaving; else loader">
				<button
					type="button"
					class="tap-btn tap-btn--default"
					(click)="save(form.value)"
					id="saveNoteModal"
					[disabled]="!form?.get('notes')?.value?.trim()"
				>
					Save
				</button>
      </ng-container>
      <ng-template #loader>
        <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
      </ng-template>
    </div>
  </form>
</div>
