import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import sort from 'fast-sort';

import { omit, clone } from 'ramda';
import { concat, EMPTY, forkJoin, from, iif, Observable, of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mapTo,
	mergeMap,
	reduce,
	switchMap,
	take,
	tap,
	toArray,
	withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { util } from '../../../../../core/util/util.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import {
	DocumentUploadState,
	SoaSettingsState,
} from '../../../../../modules/crt-settings/soa-settings/state/soa-settings.model';
import { getContentWithMergeTags } from '../../../../../shared/converter/content-merge-tags';
import {
	AdviceProcessSectionCodes,
	ServiceAdviceProcessState,
	SOASubSectionCodes,
} from '../../../../../shared/models/advice-process/advice-process.model';
import { CurrentInsuranceState } from '../../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { SosMergeTagsMapper } from '../../../../../shared/models/client-review-template/merge-tags/crt-lr-insurance/scope-of-service/sos.mapper';
import { ScopeOfServiceMapper } from '../../../../../shared/models/client-review-template/scope-of-service/scope-of-service.mapper';
import { ProposedInsuranceState } from '../../../../../shared/models/client-review-template/statement-of-advice/insurance/soa-insurance.model';
import { SOAScopeOfServiceState } from '../../../../../shared/models/client-review-template/statement-of-advice/scope-of-service/soa-scope-of-service.model';
import { StatementOfAdviceState } from '../../../../../shared/models/client-review-template/statement-of-advice/statement-of-advice.model';
import {
	DocumentModelState,
	DocumentTypes,
} from '../../../../../shared/models/documents/document.model';
import { ProviderCommissionSettingState } from '../../../../../shared/models/provider-commission/provider-commission.model';
import MomentUtil from '../../../../../util/moment.util';
import { convertUtil, objectUtil } from '../../../../../util/util';
import { CrtDocumentService } from '../../../crt-page/_shared/service/crt-document.service';
import { AssetsLiabilitiesService } from '../assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { CurrentInsuranceService } from '../current-insurance/current-insurance.service';
import { CrtMergeTagsService } from '../merge-tags/crt-merge-tags.service';
import { PeopleService } from '../people/people.service';
import { StatementOfAdviceQuery } from './statement-of-advice.query';
import { StatementOfAdviceStore } from './statement-of-advice.store';
import { MoatSoaEmailSettingsState } from 'src/app/modules/mortgage-settings/soa-settings/moat-soa-email-settings/state/moat-soa-email-settings.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ClientProfileQuery } from '@modules/crm/client-profile/states/client-profile.query';
import { ClientProfileStore } from '@modules/crm/client-profile/states/client-profile.store';
import { soaWordingsMergeTags } from '@shared/models/client-review-template/merge-tags/soa-wordings/soa.merge-tags';

export const soaPdfOptions = {
	FileName: 'STATEMENT-OF-ADVICE',
	DPI: '120',
	EnableIntelligentShrinking: 'false',
	MinimumFontSize: '9',
	LoadZoomFactor: '1.22',
	LoadJSDelay: '1500',
};

@Injectable()
export class StatementOfAdviceService
	extends ClientReviewTemplateService
	implements Resolve<boolean>
{
	soaSettings$ = this.query.soaSettings$;
	proposedInsurance$ = this.soaQuery.soaPI$;
	currentInsurance$ = this.soaQuery.soaCI$;
	soaScopeOfService$ = this.soaQuery.soaSOS$;
	soaCurrentTab$ = this.soaQuery.soaCurrentTab$;
	adviceProcess;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected peopleService: PeopleService,
		protected aLService: AssetsLiabilitiesService,
		private soaStore: StatementOfAdviceStore,
		private soaQuery: StatementOfAdviceQuery,
		private currentInsuranceService: CurrentInsuranceService,
		private mtService: CrtMergeTagsService,
		private crtDocService: CrtDocumentService,
		private clientProfileQuery: ClientProfileQuery,
		private clientProfileStore: ClientProfileStore
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	clearData() {
		applyTransaction(() => {
			this.soaStore.reset();
		});
	}

	getSoaPdfOptions() {
		return soaPdfOptions;
	}

	addSoa(soa: StatementOfAdviceState) {
		this.soaStore.setSOALoading(true, 'SOA');
		this.soaStore.setSOASaving(true, 'SOA');
		// get all number in SOAs file name
		const soaNameNumbers = this.soaQuery.getAll().map((a) => {
			// get file name excluding the first part STATEMENT OF ADVICE string
			const getName =
				a?.name?.split('-')?.length > 1 ? a?.name?.split('-')[1] : '0';
				// get number from file name
			const getNumber = getName?.match(/\d+/);
			return parseInt(getNumber?.length > 0 ? getNumber[0] : '0', 0);
		});
		// get the highest number in array
		const lastCreatedSOANumber =
			soaNameNumbers?.length > 0 ? Math.max(...soaNameNumbers) : 0;
		const groupName = this.query
			.getValue()
			.primaryClient?.groupName.toString()
			.toUpperCase();
		const name = `${groupName} STATEMENT OF ADVICE - ${
			lastCreatedSOANumber + 1
		}`;
		const data = {
			...soa,
			name,
			createDateTime: util.MomentToDatetimeString(MomentUtil.createMomentNz()),
		};
		return this.api
			.post3<number>(`crt`, objectUtil.mapCamelCaseToPascalCase(data))
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						this.soaStore.add(
							{
								...data,
								cRTId: +x,
							},
							{ prepend: true }
						);
					})
				),
				finalize(() => {
					this.soaStore.setSOALoading(false, 'SOA');
					this.soaStore.setSOASaving(false, 'SOA');
				})
			);
	}

	updateSoa(cRTId, sectionCode, soaData?: StatementOfAdviceState) {
		this.soaStore.setSOALoading(true, 'SOA');
		this.soaStore.setSOASaving(true, 'SOA');
		const oldSoa: StatementOfAdviceState = this.soaQuery.getEntity(+cRTId);
		const groupName = this.query
			.getValue()
			.primaryClient?.groupName.toString()
			.toUpperCase();

		let soaName = '';
		const namePrefix = `${groupName} STATEMENT OF ADVICE - `;
		if (soaData?.name) {
			if (soaData.name === oldSoa.name) {
				soaName = soaData.name;
			} else {
				if (soaData.name?.includes(namePrefix)) {
					const parsedName: string = soaData.name.slice(namePrefix.length - 1);
					soaName = `${namePrefix}${parsedName.toUpperCase()}`;
				} else {
					soaName = `${namePrefix}${soaData.name?.toUpperCase()}`;
				}
			}
		} else {
			soaName = oldSoa.name;
		}

		const soa = {
			...oldSoa,
			currentPage: !!oldSoa?.document?.referenceId
				? SOASubSectionCodes.Completed
				: !!sectionCode
				? sectionCode
				: oldSoa.currentPage,
			document:
				!!soaData?.document && soaData?.document?.referenceId > 0
					? soaData?.document
					: oldSoa?.document,
			name: soaName,
			paymentFrequency: soaData?.paymentFrequency ?? oldSoa?.paymentFrequency,
		} as StatementOfAdviceState;
		const data = objectUtil.mapCamelCaseToPascalCase(soa);
		return this.api.put<number>(`crt/${cRTId}`, data).pipe(
			tap((x) =>
				applyTransaction(() => {
					if (soa?.document) {
						this.soaStore.update(cRTId, { ...soa, currentPage: sectionCode });
					} else {
						this.soaStore.update(cRTId, soa);
					}
				})
			),
			catchError(() => EMPTY),
			finalize(() => {
				this.soaStore.setSOALoading(false, 'SOA');
				this.soaStore.setSOASaving(false, 'SOA');
			})
		);
	}

	renameSoa(cRTId: number, soaName: string) {
		const data = this.soaQuery.getEntity(+cRTId);
		const soa = {
			...data,
			name: soaName,
		} as StatementOfAdviceState;
		const body = objectUtil.mapCamelCaseToPascalCase(soa);

		return this.api.put<number>(`crt/${cRTId}`, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.soaStore.update(cRTId, soa);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateFrequency(cRTId, sectionCode, soaData?: StatementOfAdviceState) {
		return this.updateSoa(cRTId, sectionCode, soaData).pipe(
			concatMap(() => this.getProposedInsurance(cRTId))
		);
	}

	cloneSOA(soa) {
		return this.api.post3<string>(`crt/${soa.cRTId}/duplicate`).pipe(
			tap((id) => {
				const cloneSOA = {
					...soa,
					currentPage: SOASubSectionCodes.Completed, // SET CURRENT PAGE TO COMPLETED, SETTING IT TO SOASOS WILL MAKE IT AN INCOMPLETE CLONE SOA
				};
				this.soaStore.add({
					...cloneSOA,
					cRTId: +id,
					createDateTime: MomentUtil.formatToServerDatetime(
						MomentUtil.createMomentNz()
					),
				});
			}),
			catchError(() => EMPTY)
		);
	}

	finalize(cRTId, soaData?: StatementOfAdviceState) {
		const oldSoa: StatementOfAdviceState = this.soaQuery.getEntity(+cRTId);
		const soa: StatementOfAdviceState = {
			...oldSoa,
			currentPage: 'Finalized',
			document: soaData?.document || oldSoa.document,
			finalizeDate: MomentUtil.formatToServerDatetime(
				MomentUtil.createMomentNz()
			),
		};
		const data = objectUtil.mapCamelCaseToPascalCase(soa);
		return this.api.put<number>(`crt/${cRTId}`, data).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.soaStore.update(cRTId, soa);
				})
			),
			catchError(() => EMPTY)
		);
	}

	getSoa(adviceProcessId?: number): Observable<StatementOfAdviceState[]> {
		this.soaStore.setLoading(true);
		return this.api
			.get<[]>(
				`crt/${this.query.getValue().adviceProcessId || adviceProcessId}/SOA`
			)
			.pipe(
				map(
					(x) =>
						x?.map(
							objectUtil.mapPascalCaseToCamelCase
						) as StatementOfAdviceState[]
				),
				tap((x) =>
					applyTransaction(() => {
						const data = x ? sort(x).desc((soa) => soa?.createDateTime) : [];
						this.soaStore.set(data);
					})
				),
				finalize(() => this.soaStore.setLoading(false)),
				catchError(() => of([]))
			);
	}

	/**
	 * Get SOA Subpage by SOA ID and section code
	 * @param id SOA ID
	 * @param sectionCode Section Code
	 * @returns soapi
	 */
	getSoaSubpageSectionCode(id: number, sectionCode: string) {
		this.soaStore.setSOALoading(true, sectionCode);
		return this.api.get<[]>(`crt/soa/${id}/${sectionCode}`).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : [])),
			tap((x) =>
				applyTransaction(() => {
					// if (sectionCode === SOASubSectionCodes.SOS) {
					// 	this.soaStore.update(() => ({ scopeOfService: x }));
					// }
					// if (sectionCode === SOASubSectionCodes.CurrentInsurance) {
					// 	this.soaStore.update(() => ({ currentInsurance: x }));
					// }
					if (sectionCode === SOASubSectionCodes.ProposedInsurance) {
						this.soaStore.update(() => ({ proposedInsurance: x }));
					}
				})
			),
			finalize(() => {
				this.soaStore.setSOALoading(false, sectionCode);
			}),
			catchError(() => of(undefined))
		);
	}

  getSoaByCRT(crtId: number) {
    const endpoint = `crt/${crtId}`;
    return this.api.get<any>(endpoint).pipe(
      map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
      catchError(() => of(null))
    );
  }

	getScopeOfService(id) {
		return this.getSoaSubpageSectionCode(id, SOASubSectionCodes.SOS).pipe(
			catchError(() => of(undefined))
		);
	}

	syncProposedInsurance(soaID) {
		return this.api
			.post3(`crt/soa/${soaID}/CI`)
			.pipe(catchError(() => of(undefined)));
	}

	getCurrentInsurance(id) {
		return this.getSoaSubpageSectionCode(
			id,
			SOASubSectionCodes.CurrentInsurance
		).pipe(catchError(() => of(undefined)));
	}

	getProposedInsurance(id) {
		return this.getSoaSubpageSectionCode(
			id,
			SOASubSectionCodes.ProposedInsurance
		).pipe(catchError(() => of(undefined)));
	}

	addScopeOfService(soaSos) {
		this.soaStore.setSOASaving(true, SOASubSectionCodes.SOS);
		const data = objectUtil.mapCamelCaseToPascalCase(soaSos);
		return this.api.post3<number>(`crt`, data).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.soaStore.update(() => ({
						scopeOfService: [{ ...soaSos, cRTId: +x }],
					}));
				})
			),
			finalize(() => {
				this.soaStore.setSOASaving(false, SOASubSectionCodes.SOS);
			}),
			catchError((err) => of(undefined))
		);
	}

	updateScopeOfService(soaSos: SOAScopeOfServiceState) {
		this.soaStore.setSOASaving(true, SOASubSectionCodes.SOS);
		const oldData = this.soaQuery.getValue().scopeOfService;
		const data = objectUtil.mapCamelCaseToPascalCase(soaSos);
		return this.api.put<string>(`crt/${soaSos.cRTId}`, data).pipe(
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) =>
				applyTransaction(() => {
					this.soaStore.update(() => ({
						scopeOfService: oldData?.map((od) =>
							od.cRTId === soaSos?.cRTId ? soaSos : od
						),
					}));
				})
			),
			finalize(() => {
				this.soaStore.setSOASaving(false, SOASubSectionCodes.SOS);
			}),
			catchError((err) => of(undefined))
		);
	}

	addCurrentInsurance(
		currentInsurance: CurrentInsuranceState,
		parentCRTId: number
	) {
		const newCurrentInsurance = Object.assign(
			{},
			omit(
				['policyDocumentFormData', 'policyDocumentsName'],
				currentInsurance
			)
		);

		newCurrentInsurance.sectionCode = SOASubSectionCodes.CurrentInsurance;
		newCurrentInsurance.parentCRTId = parentCRTId;
		newCurrentInsurance.adviceProcessId = this.query.getValue().adviceProcessId;

		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(newCurrentInsurance);

		body.AdviceProcessId = this.query.getValue().adviceProcessId;

		return this.api.post3<number>(endpoint, body).pipe(
			tap((id) =>
				applyTransaction(() => {
					const ci = !!this.soaQuery.getValue().currentInsurance
						? this.soaQuery.getValue().currentInsurance
						: [];

					const data = [
						...ci,
						{
							...currentInsurance,
							cRTId: id,
						},
					]?.filter((x) => x?.cRTId && x?.cRTId !== 0);
					this.soaStore.update({ currentInsurance: data });
				})
			),
			switchMap((x) =>
				this.currentInsuranceService
					.getCurrentInsurance(
						this.query.getValue().adviceProcessId,
						AdviceProcessSectionCodes.LR
					)
					.pipe(mapTo(x))
			),
			catchError(() => EMPTY)
		);
	}

	updateCurrentInsurance(
		currentInsurance: CurrentInsuranceState,
		parentCRTId: number
	) {
		const endpoint = `crt/${currentInsurance.cRTId}`;

		currentInsurance.sectionCode = SOASubSectionCodes.CurrentInsurance;
		currentInsurance.adviceProcessId = this.query.getValue().adviceProcessId;
		currentInsurance.parentCRTId =
			+parentCRTId || currentInsurance?.parentCRTId;

		const body = objectUtil.mapCamelCaseToPascalCase(currentInsurance);
		return this.api.put<string>(endpoint, body).pipe(
			tap((id) =>
				applyTransaction(() => {
					const data = this.soaQuery.getValue().currentInsurance?.map((y) =>
						y.cRTId === currentInsurance.cRTId
							? {
									...currentInsurance,
									policyDocuments:
										typeof currentInsurance?.policyDocuments === 'string'
											? JSON.parse(currentInsurance?.policyDocuments)
											: currentInsurance?.policyDocuments,
							  }
							: y
					);
					this.soaStore.update({ currentInsurance: data });
				})
			),
			switchMap((x) =>
				this.currentInsuranceService
					.getCurrentInsurance(
						this.query.getValue().adviceProcessId,
						AdviceProcessSectionCodes.LR
					)
					.pipe(mapTo(x))
			),
			catchError(() => EMPTY)
		);
	}

	uploadDocument(insurance: CurrentInsuranceState, crtId?: number, clientId?: number) {
		const uploadDocs: any[] = [];
		for (const data of insurance.policyDocumentFormData.entries()) {
			uploadDocs.push(data[1]);
		}

		let clientDocuments = clone(
			this.clientProfileQuery.getValue().documents
		);

		const first$ = of({
			ReferenceId: crtId,
			Document: '',
			FileName: uploadDocs ? uploadDocs[0].name : '',
			Type: DocumentTypes.Upload
		});

		const documentIds = [];

		return first$.pipe(
			switchMap(() =>
				concat(
					first$,
					from(uploadDocs).pipe(
						mergeMap(
							(x) => this.convertToBase64(x),
							(o, i) => [o, i]
						),
						map(([o, i]) => {
							return {
								ReferenceId: crtId,
								Document: i ? i.split(',')[1] : '',
								FileName: o.name,
								Type: DocumentTypes.Upload,
                DocumentType: ServicesCodes.LR,
								DocumentTypeCode: ServicesCodes.LR,
                // DocumentTypeCode: DocumentTypes.AdviceProcess,
                CustomerId: clientId,
							};
						}),
						concatMap((req2) => this.api.post('documents', req2)),
						concatMap((id: number) => {
							return this.getSoaDocumentFile(id);
						}),
						map((file) => objectUtil.mapPascalCaseToCamelCase(file)),
						concatMap((file: any) => {
							const newLR = [...clientDocuments?.lR];
							newLR?.unshift({
								...file,
								id: file.documentID
							});
							clientDocuments = {
								...clientDocuments,
								lR: newLR
							};
							this.clientProfileStore.setDocuments(clientDocuments);
							return of(file.documentID);
						}),
						catchError((err) => {
							return throwError(new Error(err));
						})
					)
				)
			),
			reduce((acc, v) => {
				return documentIds.push(v);
			}),
			map((ids) =>
				applyTransaction(() => {
					const data =
						insurance.sectionCode === SOASubSectionCodes.CurrentInsurance
							? [...this.soaQuery.getValue().currentInsurance]
							: [...this.soaQuery.getValue().proposedInsurance];
					data.forEach((i) => {
						if (i.cRTId === crtId) {
							const docs = [];
							documentIds.forEach((a, val) => {
								docs.push({
									referenceId: a,
									value: uploadDocs[val].name,
								});
							});
							i.policyDocumentsName = insurance.policyDocumentsName;
							const list =
								i.policyDocumentsList && i.policyDocumentsList.length > 0
									? [...i.policyDocumentsList, ...docs]
									: docs;
							i.policyDocuments = list;
							i.policyDocumentsList = list;
						}
					});
					this.soaStore.update({
						[insurance.sectionCode === SOASubSectionCodes.CurrentInsurance
							? 'currentInsurance'
							: 'proposedInsurance']: data,
					});
					return ids;
				})
			),
			catchError(() => EMPTY)
		);
	}

	addProposedInsurance(
		proposedInsurance: ProposedInsuranceState,
		parentCRTId: number
	) {
		const newProposedInsurance = Object.assign(
			{},
			omit(
				['policyDocumentFormData', 'policyDocumentsName'],
				proposedInsurance
			)
		);

		newProposedInsurance.sectionCode = SOASubSectionCodes.ProposedInsurance;
		newProposedInsurance.parentCRTId = parentCRTId;
		newProposedInsurance.adviceProcessId =
			this.query.getValue().adviceProcessId;
		//newProposedInsurance.policyDocuments = [];

		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(newProposedInsurance);

		return this.api.post3<number>(endpoint, body).pipe(
			tap((id) =>
				applyTransaction(() => {
					const ci = this.soaQuery.getValue().proposedInsurance
						? this.soaQuery.getValue().proposedInsurance
						: [];

					const data = [
						...ci,
						{
							...proposedInsurance,
							cRTId: id,
						},
					]?.filter((x) => x?.cRTId && x?.cRTId !== 0);
					this.soaStore.update({ proposedInsurance: data });
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateProposedInsurance(
		proposedInsurance: ProposedInsuranceState,
		parentCRTId: number,
	) {
		const endpoint = `crt/${proposedInsurance.cRTId}`;
		proposedInsurance.sectionCode = SOASubSectionCodes.ProposedInsurance;
		proposedInsurance.parentCRTId = parentCRTId;
		proposedInsurance.adviceProcessId = this.query.getValue().adviceProcessId;
		const body = objectUtil.mapCamelCaseToPascalCase(proposedInsurance);
		return this.api.put<string>(endpoint, body).pipe(
			tap((id) =>
				applyTransaction(() => {
					const data = this.soaQuery.getValue().proposedInsurance?.map((y) =>
						y.cRTId === proposedInsurance.cRTId
							? {
									...proposedInsurance,
									policyDocuments:
										typeof proposedInsurance?.policyDocuments === 'string'
											? JSON.parse(proposedInsurance?.policyDocuments)
											: proposedInsurance?.policyDocuments,
							  }
							: y
					);
					this.soaStore.update({ proposedInsurance: data });
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteCurrentInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.soaQuery
						.getValue()
						.currentInsurance?.filter((y) => y?.cRTId !== id);
					this.soaStore.update({ currentInsurance: data });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	deleteProposedInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.soaQuery
						.getValue()
						.proposedInsurance?.filter((y) => y?.cRTId !== id);
					this.soaStore.update({ proposedInsurance: data });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	deleteSoa(id) {
		const endpoint = `crt/${id}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					this.soaStore.remove(id);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getDefaultSoaSettings(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<SoaSettingsState>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x ? objectUtil.mapPascalCaseToCamelCase(x) : null;
					this.store.setSoaSettings(state);
					const settingsMergeTag = state?.mergeTag;
					const mergeTag = soaWordingsMergeTags()?.map((i) => {
						const value = settingsMergeTag?.find((mt) => mt?.metaKey === i?.metaKey);
						return {
							metaKey: i?.metaKey,
							description: value?.description || '',
							type: value?.type || 'T',
							secondaryValue: value?.secondaryValue?.replace(/\n|\t/g, '<br>') || '',
							value: value?.value?.replace(/\n|\t/g, '<br>') || '',
						}
					});
					this.mtService.updateMtState(mergeTag || []);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getSoaEntityById(id: number) {
		return this.soaQuery.selectEntity(id).pipe(toArray());
	}

	newFileUploadSOA(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req).pipe(catchError(() => EMPTY));
	}

	updateFileUploadSOA(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api
			.put<DocumentUploadState>(endpoint, body)
			.pipe(catchError(() => EMPTY));
	}

	getSoaDocumentFile(id: number) {
		return this.api
			.get<DocumentModelState>(`documents/${id}`)
			.pipe(catchError(() => EMPTY));
	}

	getDocumentFromURL(url: string) {
		const dateTime = new Date().getTime();
		url = `${url}?${dateTime}`;
		return this.api
			.getExternalResource(url, { responseType: 'text' })
			.pipe(catchError(() => EMPTY));
	}

	getAdviceProcess(adviceProcessId): Observable<ServiceAdviceProcessState> {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			map((data) => (data ? objectUtil.mapPascalCaseToCamelCase(data) : null)),
			tap((data) =>
				applyTransaction(() => {
					this.adviceProcess = data;
					this.store.setAdviceProcess(data);
					this.store.setLrApPageCompleted(data?.pageCompleted || []);
					this.store.setLrApPageStarted(data?.pageStarted || []);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateLRAdviceProcess(data) {
		this.setLrApUpdateLoading(true);
		const body = objectUtil.mapCamelCaseToPascalCase({
			...data,
			pageCompleted: this.query.getValue().lrApPageCompleted,
			pageStarted: this.query.getValue().lrApPageStarted,
		});

		const endpoint = `adviceprocesses/${body.AdviceProcessID}`;
		return this.api.put<string>(endpoint, body).pipe(
			catchError(() => EMPTY),
			finalize(() =>
				this.getAdviceProcess(body.AdviceProcessID)
					.pipe(
						finalize(() => this.setLrApUpdateLoading(false)),
						take(1)
					)
					.subscribe()
			)
		);
	}

	setLrApUpdateLoading(b: boolean) {
		applyTransaction(() => {
			this.store.setLrApUpdateLoading(b);
		});
	}

	setHasSoaChanges(b: boolean) {
		applyTransaction(() => {
			this.store.setHasSoaChanges(b);
		});
	}

	setTriggerLeaveSoa(b: any) {
		applyTransaction(() => {
			this.store.setTriggerLeaveSoa(b);
		});
	}

	getLrProviders(staffId: number) {
		const settingsCode = 'PCLR';
		const endpoint = `staff/${staffId}/settings/${settingsCode}`;
		return this.api.get<ProviderCommissionSettingState[]>(endpoint).pipe(
			map((data) =>
				data ? data.map((x) => objectUtil.mapPascalCaseToCamelCase(x)) : []
			),
			catchError(() => of([]))
		);
	}

	documentMapper(data) {
		const docs = data.Documents;
		docs.forEach((doc) => {
			if (typeof doc.Value !== 'number') {
				doc.Value = doc.Value?.DocumentID;
			}
		});
		return docs;
	}

	convertToBase64 = (file, reader = new FileReader()) =>
		new Observable((obs) => {
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(file);
		});

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return forkJoin([]).pipe(mapTo(true));
	}

	getTemplateContent(docId?: number) {
		return of(docId).pipe(
			mergeMap((id) => this.getSoaDocumentFile(+id)),
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			mergeMap((res) =>
				iif(
					() => res?.documentLink,
					this.getDocumentFromURL(res?.documentLink),
					of(null)
				)
			),
			map((data) => data),
			catchError(() => EMPTY)
		);
	}

	convertSoaTemplateToBase64Pdf(template: string, name?: string) {
		return of(template).pipe(
			concatMap((x) =>
				this.crtDocService.downloadDocumentPDF(x, 'STATEMENT-OF-ADVICE', {
					...soaPdfOptions,
					FileName: name,
				})
			),
			concatMap((x) => convertUtil.convertToBase64(x)),
			catchError(() => EMPTY)
		);
	}

	updateSosMt = (content, mergeTagsData, sosCrtData, sosDefaultData) =>
		of(true).pipe(
			withLatestFrom(of(mergeTagsData), of(sosCrtData), of(sosDefaultData)),
			map(([, mergeTags, sosCrt, sosDefault]) =>
				SosMergeTagsMapper.getSosMergeTags(clone(mergeTags), sosCrt, sosDefault)
			),
			map((mergeTag) => {
				const template = SosMergeTagsMapper.revertSosMergeTags(content);
				return getContentWithMergeTags(template, mergeTag);
			}),
			take(1)
		);

	updateSosPages = (content = '', sosCrtData, sosDefaultData) =>
		of(true).pipe(
			withLatestFrom(of(sosCrtData), of(sosDefaultData)),
			map(([, crt, settings]) =>
				ScopeOfServiceMapper.mapCheckboxes(crt, settings)
			),
			map((data) => SosMergeTagsMapper.toggleSosPages(content, data)),
			take(1)
		);

	downloadLink(referenceId) {
		return this.api
			.get<string>(`documents/download/${referenceId}`)
			.pipe(catchError(() => EMPTY));
	}

	downloadFile(referenceId) {
		return this.api
			.getResponseBlob(`documents/download/oat/${referenceId}`)
			.pipe(catchError(() => EMPTY));
	}

	getDeclarationEmailSettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<MoatSoaEmailSettingsState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setDeclarationEmailSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	setSoaCurrentTab(code: string) {
		applyTransaction(() => {
			this.soaStore.setSoaCurrentTab(code);
		});
	}
}
