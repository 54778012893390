import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastMessageState, ToastService } from '@core/services/toast.service';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	logs: ToastMessageState[] = [];

	constructor(private service: ToastService) {}

	ngOnInit() {
		this.service.log$
			.pipe(
				filter((x) => !!x),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => {
				// find toast with duplicate message
				const duplicateIndex = this.logs?.findIndex(
					(a) => a?.message === x?.message
				);
				// if duplicate message is found
				if (duplicateIndex !== -1) {
					// remove it
					this.logs?.splice(duplicateIndex, 1);
				}
				if (this.logs?.length > 2) {
					this.logs?.shift();
				}
				this.logs?.push(x);

				window.setTimeout(() => {
					const i = this.logs?.indexOf(x);
					if (i > -1) {
						this.logs?.splice(i, 1);
					}
					this.remove(x);
				}, 15000);
			});
	}

	remove(item: ToastMessageState) {
		const i = this.logs?.indexOf(item);
		if (i > -1) {
			this.logs?.splice(i, 1);
		}
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
