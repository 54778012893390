import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import {
	debounceTime,
	map,
	mergeMap,
	shareReplay,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { LocalService } from 'src/app/core/services/local.service';
import {
	CommandRoute,
	RouteService,
} from '../../../../core/config/route.service';
import { staticConf } from '../../../../core/config/static-config.service';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { ActivityModalComponent } from '../../../../shared/modal/activity/activity-modal/activity-modal.component';
import {
	DeleteModalComponent,
	DeleteModalModel,
} from '../../../../shared/modal/delete-modal/delete-modal.component';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../util/moment.util';
import { ClientSearchFormComponent } from '../client-search-form/client-search-form.component';
import { comparer, request } from '../client-search.util';
import { ClientSearchUiQuery } from '../state/client-search-ui.query';
import { Row } from '../state/client-search.model';
import { ClientSearchQuery } from '../state/client-search.query';
import { ClientSearchService } from '../state/client-search.service';
import { linkColumn, Metakey } from './client-search-datatable.config';
declare var $: any;

/** Page component for client and business search */
@Component({
	selector: 'app-client-search-page',
	templateUrl: './client-search-page.component.html',
	styleUrls: ['./client-search-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSearchPageComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	columns$ = this.clientSearchQuery.tableColumns$;
	columnsWithLink$ = this.columns$.pipe(map((x) => [...x, linkColumn]));
	linkColumn = linkColumn;

	cellsLoading$ = this.clientSearchQuery.cellsLoading$;
	cellsEditing$ = this.clientSearchQuery.cellsEditing$;
	cellsTempvalue$ = this.clientSearchQuery.cellsTempvalue$;
	rowsLoading$ = this.clientSearchQuery.rowsLoading$;
	isSearching$ = this.clientSearchQuery.isSearching$;

	rows$ =
		this.clientSearchQuery.getValue().count <= 500
			? this.clientSearchQuery.sortedRows$
			: this.clientSearchQuery.rows$;
	hasRow$ = this.clientSearchQuery.hasRows$;

	propSort$ = this.clientSearchQuery.uiStore.propSort$;
	sorts$ = this.clientSearchQuery.sorts$;

	// For adding activity
	AT$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('AT');
	AM$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('AM');
	LS$ = this.dropdownValueQuery.orderedChoices$('LS');
	/** lead type choices */
	PCLT$ = this.dropdownValueQuery.orderedChoices$('PCLT');
	/** PCLT as object lookup */
	PCLTObject$ = this.PCLT$.pipe(
		map((choices) =>
			choices?.reduce(
				(prev, curr) => ({ ...prev, [curr.value]: curr.display }),
				{}
			)
		),
		shareReplay(1)
	);
	/** lead origin choices */
	PCLE$ = this.dropdownValueQuery.orderedChoices$('PCLE');
	/** PCLE as object lookup */
	PCLEObject$ = this.PCLE$.pipe(
		map((choices) =>
			choices?.reduce(
				(prev, curr) => ({ ...prev, [curr.value]: curr.display }),
				{}
			)
		),
		shareReplay(1)
	);
	/** active advisers */
	adviserChoices$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allActiveStaffs$;
	/** complete advisers including deleted. */
	completeAdvisers$: Observable<ViewDisplayValue[]> =
		this.blStaffsQuery.allStaffsChoices$;
	/** faster lookup advisers */
	adviserChoicesAsObject$: Observable<{ [key: string]: string }> =
		this.blStaffsQuery.allStaffsChoicesLookup$;
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	edit = this.clientSearchService.edit;
	cancel = this.clientSearchService.cancel;
	setTempValue = this.clientSearchService.setTempValue;

	showSearchFields = false;
	tblHeight: SafeStyle;

	resizeEvent$ = fromEvent(window, 'resize');

	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild(ClientSearchFormComponent)
	clientSearchFormComponent: ClientSearchFormComponent;
	trackByFn(index, item: TableColumn) {
		return item.prop;
	}
	rowIdentity = (row: Row) => row.CustomerId;
	route = (metakey: Metakey, row: Row): CommandRoute | string => {
		switch (metakey) {
			case 'Name':
				return row.link.IsCompany
					? this.routeService.businessEdit(row.CustomerId)
					: this.routeService.customerView(row.CustomerId);
			case 'LR Note':
				return !row.LRNote.value || row.LRNote.value?.indexOf('YES') === 0
					? row.link.IsCompany
						? this.routeService.businessLrPipeline(row.CustomerId)
						: this.routeService.customerLrPipeline(row.CustomerId)
					: null;
			case 'M Note':
				return !row.MNote.value || row.MNote.value?.indexOf('YES') === 0
					? row.link.IsCompany
						? this.routeService.businessMPipeline(row.CustomerId)
						: this.routeService.customerMPipeline(row.CustomerId)
					: null;
			case 'FG Note':
				return !row.FGNote.value || row.FGNote.value?.indexOf('YES') === 0
					? row.link.IsCompany
						? this.routeService.businessFgPipeline(row.CustomerId)
						: this.routeService.customerFgPipeline(row.CustomerId)
					: null;
			case 'K Note':
				return !row.KNote.value || row.KNote.value?.indexOf('YES') === 0
					? row.link.IsCompany
						? this.routeService.businessKPipeline(row.CustomerId)
						: this.routeService.customerKPipeline(row.CustomerId)
					: null;
			case 'Physical Address':
				return this.routeService.externalRoute(
					staticConf.googleMapApi + row.PhysicalAddress.value
				);
			default:
				return null;
		}
	};

	constructor(
		private dropdownValueQuery: DropdownValueQuery,
		private blStaffsQuery: BLStaffsQuery,
		private clientSearchQuery: ClientSearchQuery,
		private clientSearchService: ClientSearchService,
		private routeService: RouteService,
		private modalService: BsModalService,
		private sanitizer: DomSanitizer,
		private clientSearchUiQuery: ClientSearchUiQuery,
		private ngZone: NgZone,
		private localService: LocalService
	) {}

	ngOnInit() {
		this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
			'calc(100vh - 141px)'
		);

		this.resizeEvent$
			.pipe(debounceTime(500), takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.clientSearchService.reloadData();
			});
	}

	ngAfterViewInit(): void {
		this.dragHeader();
	}

	businessRoute(id: number) {
		return this.ngZone.run(() => this.routeService.businessEdit(id));
	}
	clientRoute(id: number) {
		return this.ngZone.run(() => this.routeService.customerView(id));
	}

	saveField = (
		row: Row,
		MetaKey: Metakey,
		key: string,
		metadata: FieldMetadata<any>
	) => {
		this.clientSearchService
			.saveField({
				CustomerId: row.CustomerId,
				MetaKey,
				MetaValue: metadata.value,
			})
			.pipe(
				withLatestFrom(this.propSort$),
				mergeMap(([res, prop]) => {
					return this.rows$.pipe(
						tap((rows) => {
							if (prop === key) {
								const i = rows?.findIndex(
									(x) => x.CustomerId === row.CustomerId
								);
								this.table.bodyComponent.updateOffsetY(
									i / this.table.bodyComponent.pageSize
								);
								this.table.bodyComponent.selected.push(row);
								setTimeout(() => {
									this.table.bodyComponent.selected = [];
									$('.datatable-body-row').removeClass('active');
								}, 1000);
							}
						}),
						take(1)
					);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	updateNoteField = (CustomerId: number, metadata: any) => {
		this.clientSearchService.updateNote(CustomerId, metadata.value);
	};

	updateClientNextActivityField = (customerId: number) => {
		this.clientSearchService
			.updateClientAndUserNextActivity(customerId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};
	updateUserNextActivityField = (customerId: number) => {
		this.clientSearchService
			.updateClientAndUserNextActivity(customerId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};
	reorder = (reorderEvent: {
		column: TableColumn;
		newValue: number;
		prevValue: number;
	}) => {
		this.clientSearchService
			.reorderColumn(reorderEvent.prevValue, reorderEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};
	resize = (event: { column: TableColumn; newValue: number }) => {
		if (event && (!event.column || !event.newValue)) {
			return;
		}
		this.clientSearchService
			.resizeColumn(`${event.column.prop}`, event.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	sort(a: { sorts; column; prevValue; newValue }) {
		if (this.clientSearchQuery.getValue().count <= 500) {
			return this.clientSearchService?.sort(a.sorts[0].prop, a.sorts[0].dir);
		}

		this.clientSearchService
			.search(
				request(
					{
						Column: a.sorts[0].prop,
						Direction: a.sorts[0].dir,
					},
					this.clientSearchFormComponent.prepareFormValue(),
					1
				)
			)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.clientSearchService?.sort(a.sorts[0].prop, a.sorts[0].dir);
			});
	}

	delete(customerId: number) {
		const confirm$ = this.clientSearchService.delete(customerId);

		const initState: Partial<DeleteModalModel> = {
			delete$: confirm$,
			header: 'Delete Client',
			message: `Are you sure you want to delete ${this.clientSearchQuery.getCustomerName(
				customerId
			)}`,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Activity modal
	createClientNextActivity(customerId: number) {
		const formItem = {
			Customer: { CustomerId: customerId },
		};
		const initState: any = {
			formItem,
			AT$: this.AT$,
			AM$: this.AM$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: true,
			savefn: this.saveClientNextActivity,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: ['FCCA'],
		};
		this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft'
					? 'modal-dialog-outlook-xl'
					: 'modal-xl'
			}`,
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveClientNextActivity = (ac: ActivityViewModel) =>
		this.clientSearchService.createClientNextActivity(ac);

	convertDateToNZ(d: string) {
		const nzToday = MomentUtil.createMomentNz().format('LL');
		const splicedDate = d?.slice(0, 10);
		const date = moment(splicedDate, 'DD/MM/YYYY');
		return date.diff(nzToday, 'days');
	}

	getRowClass = (row: Row) => {
		const nd = this.convertDateToNZ(
			row.ClientNextActivity.value ? row.ClientNextActivity.value : ''
		);
		// If 0 meaning its today
		if (nd === 0) {
			return 'isDueDate';
		}
		// if negative meaning overdue
		if (nd < 0) {
			return 'isOverdue';
		}
		if (nd < 6) {
			return 'isDueNextFiveDays';
		}
	};

	getCellClass(metadata: FieldMetadata<any>, row: Row): string {
		if (
			(metadata.metakey === 'Client Next Activity' &&
				row.ClientNextActivity.value) ||
			(metadata.metakey === 'User Next Activity' && row.UserNextActivity.value)
		) {
			const rowValue =
				metadata.metakey === 'Client Next Activity'
					? row.ClientNextActivity.value
					: row.UserNextActivity.value;
			const nd = this.convertDateToNZ(rowValue);
			// If 0 meaning its today
			if (nd === 0) {
				return 'isDueDate';
			}
			// if negative meaning overdue
			if (nd < 0) {
				return 'isOverdue';
			}
			if (nd < 6) {
				return 'isDueNextFiveDays';
			}
		}
	}

	onToggleSearch(data) {
		this.showSearchFields = data.showSearch;
		if (this.showSearchFields && data.width > 1199) {
			this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
				`calc(100vh - ${104 + data.height}px)`
			);
		} else {
			this.tblHeight =
				this.sanitizer.bypassSecurityTrustStyle(`calc(100vh - 141px)`);
		}
		setTimeout(() => {
			this.clientSearchService.reloadData();
			document.body.style.overflowY = 'auto';
		}, 500);
	}

	// Dragging column header
	dragHeader() {
		const _tbl = 'ngx-datatable';
		const _header = 'datatable-header';
		const _headerInner = 'datatable-header-inner';
		const _headerCell = 'datatable-header-cell';
		const _body = 'datatable-body';
		const _draggable = 'draggable';
		const _draggingState = 'dragging-state';

		const tblHeaderCell = `${_tbl} ${_header} ${_headerCell}`;
		const tblHeaderCellDrag = `${_tbl} ${_header} ${_headerCell} .${_draggable}`;
		const header = $(`${_tbl} ${_header}`);
		const tblBody = $(`${_tbl} ${_body}`);

		let isDragging = 0;
		let timer;
		let scrollTimer;
		let _self;
		let tranlateLeft;

		const scrollLeftHeader = (pageX, headerWidth) => {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			const innerHeader = header?.find(`.${_headerInner}`);
			const key = 'MSStream';
			const os = /Windows/.test(navigator.userAgent) && !window[key];
			const maxScroll =
				innerHeader[0].scrollWidth - tblBody.width() + (os ? 16 : 0);
			const isDraggingState = $(tblHeaderCell)
				.parent()
				.hasClass(`${_draggingState}`);

			// Check if the mouse is in the left edge of header while dragging
			if (pageX <= 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() > 0) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() - 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft -= 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the left edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
			// Check if the mouse is in the right edge of header while dragging
			if (pageX >= headerWidth - 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() <= maxScroll) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() + 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft += 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the right edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
		};

		$(document)
			.on('mousedown', tblHeaderCellDrag, function (e) {
				if (e.which !== 1) {
					return;
				}
				_self = $(this);
				tranlateLeft = 0;

				isDragging = 1;

				timer = setTimeout(() => {
					_self.closest(tblHeaderCell).parent().addClass(`${_draggingState}`);
				}, 500);
			})

			.on('mouseup', function () {
				if (timer) {
					clearTimeout(timer);
				}
				if (scrollTimer) {
					clearTimeout(scrollTimer);
					_self
						.closest(`${_headerCell}`)
						.css('transform', `translate3d(0px, 0px, 0px)`);
				}
				if (isDragging) {
					$(tblHeaderCell)
						.removeClass(`${_draggable}`)
						.parent()
						.removeClass(`${_draggingState}`);
					isDragging = 0;
				}
			})

			// Scroll header when dragging column into the edge of header
			.on('mousemove', function (e) {
				const headerWidth = header.width();
				const pageX = e.pageX - header.offset().left;
				scrollLeftHeader(pageX, headerWidth);
			});
	}

	ngOnDestroy() {
		$(document).off('mousedown mouseup mousemove');

		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	onPage(event: any, indexes: any) {
		if (this.clientSearchQuery.getValue().count <= 500) {
			return;
		}
		// pre-fetch the next page
		const calc = this.clientSearchQuery.getCount() - 2;
		if (calc <= indexes.last) {
			this.loadMore();
		}
	}

	loadMore() {
		const sl = this.table.element.querySelector('.datatable-body').scrollLeft;
		const sh = this.table.bodyComponent.scrollHeight;
		this.clientSearchQuery.searchForm$
			.pipe(
				withLatestFrom(this.clientSearchQuery.isComplete$),
				withLatestFrom(this.clientSearchUiQuery.isSearching$),
				map(([[x, isComplete], isSearching]) => {
					const paging = {
						Column: x ? x.Paging.Column : '',
						Direction: x ? x.Paging.Direction : '',
					};

					// Checking if filter has changed
					const compare = comparer(
						request(
							paging,
							this.clientSearchFormComponent.prepareFormValue(),
							0
						),
						x
					);
					const currentIndex = x && x.Paging ? x.Paging.Index + 1 : 0;

					return {
						compare,
						isSearching,
						isComplete,
						paging,
						currentIndex,
					};
				}),
				mergeMap((x) => {
					if (x.compare && !x.isSearching && !x.isComplete) {
						return this.clientSearchService.search(
							request(
								x.paging,
								this.clientSearchFormComponent.prepareFormValue(),
								x.currentIndex
							)
						);
					}
					return of(null);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.table.element.querySelector('.datatable-body').scrollTop = sh;
				this.table.element.querySelector('.datatable-body').scrollLeft = sl;
			});
	}
}
