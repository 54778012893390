import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { AdviserStat } from './adv.model';
import { Injectable } from "@angular/core";

export interface AdviserStatsState {

  /**
   * Adviser Stats fields
   */
  AdviserStats?: AdviserStat[];

  /**
   * error message. will show up when not empty
   */
  error: string;

  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): AdviserStatsState {
  return {
    AdviserStats: [],
    error: '',
    isLoading: false
  };
}


/**
 * Adviser Stat widget data store
 */
@Injectable()
@StoreConfig({
  name: 'AdviserStat'
})
export class AdviserStatsStore extends Store<AdviserStatsState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Set if Adviser Stat is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(produce(draft => { draft.isLoading = isLoading; }));
  }
}
