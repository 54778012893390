<form [formGroup]="form" class="form preference-form">
	<div class="form-row mt-3 mb-2 label-background">
		<div class="col-7 col-lg-3 mb-lg-3">
			<label
				for=""
				class="font-weight-bold label-font tap-text-primary"
				[ngClass]="{
					'ng-touched': DetailsList?.controls?.length === 0,
					'ng-invalid': DetailsList?.controls?.length === 0,
					border: DetailsList?.controls?.length === 0
				}"
				>Insurance Preferences:</label
			>
		</div>
		<div class="col-5 col-lg-9 text-right iconShow">
			<button
				id="collapseInsurancePreferenceBtn"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 collapse-background"
			>
				<span *ngIf="elseMinus; else elsePlus">
					<i (click)="collapseMore()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlus>
					<span>
						<i (click)="collapseLess()" class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div formArrayName="detailsList">
		<div class="collapse expandable" id="collapse">
			<div
				class="form-row align-items-center"
				*ngFor="let item of DetailsList.controls; let i = index"
				[formGroupName]="i"
			>
				<div
					class="col col-lg-4 mb-2"
					*ngIf="!(item.get('dropdown').value === 'Other')"
				>
					<span class="w-100" [class.crt-custom-dropdown]="!isAdviceProcessEnded">
						<select
							id="preferenceItemId_{{ i }}"
							formControlName="dropdown"
							class="form-control crt-form-control theme-crm-field-disabled"
							(change)="selectItem(i)"
						>
							<option value=""></option>
							<option *ngFor="let item of APCRTIP$" [value]="item.value">
								{{ item.display }}
							</option>
						</select>
					</span>
				</div>
				<div
					class="col col-lg-4 mb-2"
					*ngIf="item.get('dropdown').value === 'Other'"
				>
					<input
						id="othersId_{{ i }}"
						class="form-control"
						placeholder="Others"
						formControlName="value"
						type="text"
						(change)="onChange()"
					/>
				</div>
				<div class="col-auto">
					<ng-container *ngIf="!isAdviceProcessEnded">
						<button
							id="deleteItemBtn_{{ i }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							(click)="deleteItem(i)"
						>
							<i class="material-icons md-16"> close </i>
						</button>
					</ng-container>
				</div>
			</div>
			<div class="form-row my-4">
				<div class="col-8 col-lg-8">
					<ng-container *ngIf="!isAdviceProcessEnded">
						<button
							id="addPreferenceBtn"
							type="button"
							class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
							(click)="addNewItem()"
						>
							Add Preference +
						</button>
					</ng-container>
				</div>
				<div class="col-4 pt-1 col-lg-4 text-lg-left text-right"></div>
			</div>
		</div>
	</div>
</form>
