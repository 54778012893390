import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { BsModalService } from 'ngx-bootstrap/modal';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { RouteService } from '../../core/config/route.service';
import { ArchiveBusinessModalComponent } from '../../shared/modal/archive-business-modal/archive-business-modal.component';
import { Business } from '../../shared/models/_general/business';
import { LocalService } from './../../core/services/local.service';
import { linkColumn } from './business-page-datatable.config';
import { BusinessPageModel } from './state/business-page.model';
import { BusinessPageQuery } from './state/business-page.query';
import { BusinessPageService } from './state/business-page.service';
declare var $: any;
const os = /Windows/.test(navigator.userAgent) && !window['MSStream'];
@Component({
	selector: 'app-business-page',
	templateUrl: './business-page.component.html',
	styleUrls: ['./business-page.component.scss'],
})
export class BusinessPageComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(DatatableComponent) table: DatatableComponent;

	private onDestroy$ = new Subject<void>();
	hasRightIndent = os;

	linkColumn = linkColumn;
	businesses: BusinessPageModel[];
	showSearchFields = false;
	tblHeight: SafeStyle;

	isLoading$ = this.businessPageQuery.isLoading$;
	isSearching$ = this.businessPageQuery.isSearching$;
	showAll$ = this.businessPageQuery.showAll$;

	rows$ = this.businessPageQuery.sortedRows$;
	columns$ = this.businessPageQuery.tableColumns$;
	columnsWithLink$ = this.columns$.pipe(
		takeUntil(this.onDestroy$),
		map((x) => [...x, linkColumn])
	);
	resizeEvent$ = fromEvent(window, 'resize');
	sorts$ = this.businessPageQuery.sorts$;
	isOpenColumnsSettings$ = this.businessPageQuery.isOpenColumnsSettings$;

	plMonth$ = this.businessPageQuery.plMonth$;
	aiaMonth$ = this.businessPageQuery.aiaMonth$;
	fidelityLifeMonth$ = this.businessPageQuery.fidelityLifeMonth$;
	asteronMonth$ = this.businessPageQuery.asteronMonth$;
	nibMonth$ = this.businessPageQuery.nibMonth$;
	cignaLifeMonth$ = this.businessPageQuery.cignaLifeMonth$;
	blanketMonth$ = this.businessPageQuery.blanketMonth$;
	totalMonth$ = this.businessPageQuery.totalMonth$;
	totalYear$ = this.businessPageQuery.totalYear$;
	noOfClients$ = this.businessPageQuery.noOfClients$;
	noOfAdvisers$ = this.businessPageQuery.noOfAdvisers$;

	columnSizes: any;
	showAllRows = this.businessPageQuery.getValue().showAll;

	rowIdentity = (row: Business) => row.business_id;

	reorder = (reorderEvent: {
		column: TableColumn;
		newValue: number;
		prevValue: number;
	}) => {
		return this.businessPageService
			.reorderColumn(reorderEvent.prevValue, reorderEvent.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	resize = (event: { column: TableColumn; newValue: number }) => {
		if (event && (!event.column || !event.newValue)) {
			return;
		}
		return this.businessPageService
			.resizeColumn(`${event.column.prop}`, event.newValue)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	};

	sort(a: { sorts; column; prevValue; newValue }) {
		this.businessPageService?.sort(a.sorts[0].prop, a.sorts[0].dir);
	}

	constructor(
		private businessPageQuery: BusinessPageQuery,
		private sanitizer: DomSanitizer,
		private businessPageService: BusinessPageService,
		private routeService: RouteService,
		private modalService: BsModalService,
		private localService: LocalService
	) {}

	ngOnInit() {
		this.tblHeight = this.sanitizer.bypassSecurityTrustStyle(
			'calc(100vh - 200px)'
		);

		this.resizeEvent$
			.pipe(debounceTime(500), takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.businessPageService.reloadData();
			});
	}

	ngAfterViewInit() {
		/** For footer */
		const oldRecalculate =
			this.table && this.table.recalculateColumns
				? this.table.recalculateColumns
				: null;
		this.table.recalculateColumns = (...args) => {
			const sizedColumns = oldRecalculate.apply(this.table, args);
			if (sizedColumns) {
				this.columnSizes = sizedColumns?.map((c) => c.width);
			}
			return sizedColumns;
		};

		this.dragHeader();
	}

	load() {
		this.businessPageService
			.loadBusinesses()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	openColumnPopUp = () =>
		this.businessPageService.toggleColumnsSetting(
			!this.businessPageQuery.getValue().toggleColumnsSetting
		);

	onSelectTab(data) {
		setTimeout(() => {
			this.businessPageService.reloadData();
		}, 0);
	}

	businessRoute(metakey: string, code: string) {
		if (metakey === 'Business Name') {
			// this.localService.setValue('code', code);
			return this.routeService.customBusiness(code);
		}

		return null;
	}

	route(code: string) {
		// this.localService.setValue('code', code);
		return this.routeService.customBusiness(code);
	}

	showAll() {
		this.showAllRows = !this.showAllRows;
		this.businessPageService
			.showArchive(this.showAllRows)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	confirmArchive(row: BusinessPageModel, status: number) {
		const msg = status === 2 ? 'archive' : 'unarchive';
		new Observable((obs) => {
			const initState: any = {
				header: 'Archive Business',
				message: `Are you sure you want to ${msg} ${row.BusinessName} Business?`,
				business: row,
				businessId: row.BusinessID,
				companyCode: row.CompanyCode,
				status,
				isArchive: status === 2 ? true : false,
				archivefn: this.archive,
			};
			this.modalService.show(ArchiveBusinessModalComponent, {
				class: 'modal-dialog-centered',
				initialState: initState,
				ignoreBackdropClick: true,
			});
			obs.complete();
		})
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	archive = (
		business: any,
		businessId: number,
		companyCode: string,
		status: number
	) =>
		this.businessPageService.archiveBusiness({
			Business: business,
			Status: status,
		});

	getRowClass = (row) => {
		return { disabled: row.IsActive === 2 };
	};

	// Dragging column header
	dragHeader() {
		const _tbl = 'ngx-datatable';
		const _header = 'datatable-header';
		const _headerInner = 'datatable-header-inner';
		const _headerCell = 'datatable-header-cell';
		const _body = 'datatable-body';
		const _draggable = 'draggable';
		const _draggingState = 'dragging-state';

		const tblHeaderCell = `${_tbl} ${_header} ${_headerCell}`;
		const tblHeaderCellDrag = `${_tbl} ${_header} ${_headerCell} .${_draggable}`;
		const header = $(`${_tbl} ${_header}`);
		const tblBody = $(`${_tbl} ${_body}`);

		let isDragging = 0;
		let timer;
		let scrollTimer;
		let _self;
		let tranlateLeft;

		const scrollLeftHeader = (pageX, headerWidth) => {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			const innerHeader = header?.find(`.${_headerInner}`);
			const os = /Windows/.test(navigator.userAgent) && !window['MSStream'];
			const maxScroll =
				innerHeader[0].scrollWidth - tblBody.width() + (os ? 16 : 0);
			const isDraggingState = $(tblHeaderCell)
				.parent()
				.hasClass(`${_draggingState}`);

			// Check if the mouse is in the left edge of header while dragging
			if (pageX <= 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() > 0) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() - 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft -= 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the left edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
			// Check if the mouse is in the right edge of header while dragging
			if (pageX >= headerWidth - 15 && isDraggingState) {
				scrollTimer = setTimeout(() => {
					if (tblBody.scrollLeft() <= maxScroll) {
						// Do right scroll
						tblBody.scrollLeft(tblBody.scrollLeft() + 10);
						// Asjust column position while scrolling
						if (_self) {
							tranlateLeft += 10;
							_self
								.closest(`${_headerCell}`)
								.css('transform', `translate3d(${tranlateLeft}px, 0px, 0px)`);
						}
						// Check again if dragging still in the right edge of header
						scrollLeftHeader(pageX, headerWidth);
					} else {
						if (scrollTimer) {
							clearTimeout(scrollTimer);
						}
					}
				}, 0);
			}
		};

		$(document)
			.on('mousedown', tblHeaderCellDrag, (e) => {
				if (e.which !== 1) {
					return;
				}
				_self = $(this);
				tranlateLeft = 0;

				isDragging = 1;

				timer = setTimeout(() => {
					_self.closest(tblHeaderCell).parent().addClass(`${_draggingState}`);
				}, 500);
			})

			.on('mouseup', () => {
				if (timer) {
					clearTimeout(timer);
				}
				if (scrollTimer) {
					clearTimeout(scrollTimer);
					_self
						.closest(`${_headerCell}`)
						.css('transform', `translate3d(0px, 0px, 0px)`);
				}
				if (isDragging) {
					$(tblHeaderCell)
						.removeClass(`${_draggable}`)
						.parent()
						.removeClass(`${_draggingState}`);
					isDragging = 0;
				}
			})

			// Scroll header when dragging column into the edge of header
			.on('mousemove', (e) => {
				const headerWidth = header.width();
				const pageX = e.pageX - header.offset().left;
				scrollLeftHeader(pageX, headerWidth);
			});
	}

	ngOnDestroy() {
		$(document).off('mousedown mouseup mousemove');

		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
