import {
	Component,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Observable, Subject, combineLatest, map, takeUntil, tap } from 'rxjs';
import { RouteService } from '@core/config/route.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { UserQuery } from '@domain/user/user.query';
import { ServicesCodes } from '@shared/models/services/services.model';
import { CommonModule, DOCUMENT } from '@angular/common';
import { CrmRoutingModule } from '@modules/crm/crm-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ThemeModule } from '@modules/admin/theme/theme.module';
import { MatIconModule } from '@angular/material/icon';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HeaderNavHoverDirective } from './header-nav.directive';
import { HeaderService } from '../header.service';

@Component({
	selector: 'app-header-nav',
	templateUrl: './header-nav.component.html',
	styleUrls: ['./header-nav.component.scss'],
  imports: [
    CommonModule, 
    CrmRoutingModule, 
    ThemeModule, 
    SharedModule, 
    MatIconModule,
		HeaderNavHoverDirective
	],
  standalone: true
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  @Input() navIsShown: boolean;
  @Input() currentSelectedMenuItem: string;
  @Input() isSideNav: boolean;
	element:HeaderNavHoverDirective;
  
  @HostBinding('class')
  get classBinding() {
    return {
			'header-nav': true,
      'sidenav': this.isSideNav,
      'sidenav-close': this.isSideNav && !this.navIsShown
    }
  }
	
  private onDestroy$ = new Subject<void>();
  private routeChanges$ = this.routeService.RouteChange$;
  public dashboardLink$ = this.routeChanges$.pipe(map((x) => x.dashboard));
  public customerSearchLink$ = this.routeChanges$.pipe(
    map((x) => x.customerSearch)
  );
  public customerAddLink$ = this.routeChanges$.pipe(
    takeUntil(this.onDestroy$),
    map((x) => x.customerAdd)
  );
  public businessSearchLink$ = this.routeChanges$.pipe(
    map((x) => x.businessSearch)
  );
  public businessAddLink$ = this.routeChanges$.pipe(map((x) => x.businessAdd));
  public apPipelineLink$ = this.routeChanges$.pipe(map((x) => x.apPipeline));
  public activityLink$ = this.routeChanges$.pipe(map((x) => x.activity('')));
  public documentLink$ = this.routeChanges$.pipe(map((x) => x.document));
  public reportLink$ = this.routeChanges$.pipe(map((x) => x.report));

  public leadSearchLink$ = this.routeChanges$.pipe(map((x) => x.leadSearch));
  public leadClientAddLink$ = this.routeChanges$.pipe(
    map((x) => x.leadClientAdd)
  );
  public leadBusinessAddLink$ = this.routeChanges$.pipe(
    map((x) => x.leadBusinessAdd)
  );

  private orderedServiceCodes$: Observable<string[]> = combineLatest([
		this.businessConfigQuery.businessServices$,
		this.userQuery.services$,
	]).pipe(
		map(([bServices, uServices]) => {
			const userServices = (uServices as string[]).filter((us) =>
				bServices.some((bs) => bs === us)
			);
			return bServices.filter((x) => userServices.includes(x));
		}),
		takeUntil(this.onDestroy$)
	);

	adviceProcessPipelineRoute = this.routeService.apPipeline;

	private unorderedServiceLabelLinks$: Observable<LabelLinkPair[]> =
		this.routeChanges$.pipe(
			map((routes) => [
				new LabelLinkPair(
					ServicesCodes.LR,
					'L&R Insurance Pipeline',
					routes.lrPipeline
				),
				new LabelLinkPair(
					ServicesCodes.Mortgage,
					'Mortgage Pipeline',
					routes.mPipeline
				),
				new LabelLinkPair(
					ServicesCodes.FG,
					'F&G Insurance Pipeline',
					routes.fgPipeline
				),
				new LabelLinkPair(
					ServicesCodes.KiwiSaver,
					'KiwiSaver Pipeline',
					routes.kPipeline
				),
				new LabelLinkPair(
					ServicesCodes.Investment,
					'Investment Pipeline',
					routes.iPipeline
				),
				new LabelLinkPair(
					ServicesCodes.AdviceProcess,
					'Advice Process Pipeline',
					routes.apPipeline
				),
			])
		);

  public orderedServiceLabelLinkModel$: Observable<LabelLinkPair[]> =
		combineLatest([
			this.orderedServiceCodes$,
			this.unorderedServiceLabelLinks$,
		]).pipe(
			map(([codes, linkLabels]) =>
				sortLinkAndLabelAccordingToCode(linkLabels, codes)
			)
		);
	isAdminView = false;
	currentLocation: string;

  constructor(
    private userQuery: UserQuery,
    private businessConfigQuery: BusinessConfigQuery,
    private routeService: RouteService,
		private location: Location,
		private router: Router,
    private ngZone: NgZone,
		private headerService: HeaderService,
		@Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
		this.router.events
			.pipe(
				tap(() => {
					this.isAdminView = this.location?.path()?.includes('admin');
					this.currentLocation = this.location?.path()
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

			this.headerService.getMenuState().subscribe(state => {
				if (state) {
					this.document.body.classList.add('menu-open');
				} else {
					this.document.body.classList.remove('menu-open');
				}
			})
	}

  ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}


 	navigateRoute(link) {
    return this.ngZone.run(() => link);
  }

	
	navOpen() {
		if(this.isSideNav) {
			this.navIsShown = true;
		} else {
			this.navIsShown = false;
		}
	}

  
}

function sortLinkAndLabelAccordingToCode(
	labelLinks: LabelLinkPair[],
	code: string[]
): LabelLinkPair[] {
	return code.map((x) => getLabelLink(labelLinks, x));
}
function getLabelLink(
	labelLinks: LabelLinkPair[],
	code: string
): LabelLinkPair {
	return labelLinks.find(
		(x) => (x.code || '').toUpperCase() === (code || '').toUpperCase()
	);
}

class LabelLinkPair {
	constructor(public code: string, public label: string, public link: string) {}
}
