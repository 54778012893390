<div
	class="header d-flex align-items-center justify-content-between tap--bg-primary"
>
	<span class="text-white">Refer the Client</span>
</div>

<div
	class="content bg-white d-flex align-items-center justify-content-center flex-column"
>
	<p class="message">
	Please confirm that the Referral Form for {{ referral.referralService }} has been
		completed?
	</p>
	<div class="buttons d-flex align-items-center justify-content-center">
		<button
			class="btn btn-primary tap-bg-primary text-light p-0"
			[disabled]="isSaving"
			(click)="save()"
		>
			Yes
		</button>

		<button
			class="btn btn-light p-0 btn-no"
			(click)="close()"
			[disabled]="isSaving"
		>
			No
		</button>
	</div>
</div>
