import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ValidationErrors,
	ValidatorFn,
} from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { ClientReferralSettingReferral } from '@modules/special-features/client-referral-setting/state/client-referral-setting.model';
import { getContentWithMergeTags, removeMtWrappers } from '@shared/converter/content-merge-tags';
import { logMessage } from '@shared/error-message/error-message';
import { Attachment } from '@shared/models/_general/attachment.model';
import { EmailLinkList } from '@shared/models/client-review-template/merge-tags/email-settings/email-links';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from '@shared/wysiwyg/wysiwyg.component';
import { AppFormGroup } from '@util/form-group';
import { validatorUtil } from '@util/validator.util';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	Observable,
	Subject,
	catchError,
	finalize,
	map,
	mergeMap,
	of,
	take,
	tap,
    throwError,
} from 'rxjs';

export interface ClientReferralAttachments extends Attachment {
	IsDeleted?: boolean;
	FileName: string;
	GenerateContentCallback$?: Observable<any>;
	Content?: string;
	PdfOptions?: {
		orientation: string;
		format: string;
	};
	Queue?: boolean;
	DocumentID?: number;
	FileSize?: number;
	Type?: string;
	FileUrl?: string;
	AllowDeleting?: boolean;
	DisableDownload?: boolean;
}

@Component({
	selector: 'app-client-referral-email-modal-form',
	templateUrl: './client-referral-email-modal-form.component.html',
	styleUrls: ['./client-referral-email-modal-form.component.scss'],
})
export class ClientReferralEmailModalFormComponent {
	@ViewChild('editor') editor: WysiwygComponent;

	@Input() documentInfo: {
		documentType: any;
		type: any;
		referenceId: number;
		customerId: number;
	};

	@Input() saveFn$: (referral: ClientReferralSettingReferral) => Observable<any>;

	@Input() loadAttachmentFn$: (
		data: ClientReferralSettingReferral
	) => Observable<ClientReferralAttachments[]>;

	@Input() documnetBodyLoaderFn$: (
		data: ClientReferralSettingReferral
	) => Observable<[string, string]>;

	@Input() users$: Observable<
		{
			display: string;
			value: any;
			email: string;
			data: BLStaff;
		}[]
	>;

	@Input() subject: Subject<ClientReferralSettingReferral | null>;

	@Input() isSettings = false;

	@Input() allowDocumentLinking = false;

	@Input() mergeTags: MergeTagState[];

	isLoadingAttachment = false;

	isDataLoading = false;

	attachments: ClientReferralAttachments[] = [];

	loadDataFn$: () => Observable<ClientReferralSettingReferral>;

	isSaving = false;

	currentUser: any;

	formGroup = new AppFormGroup(
		{
			body: new FormControl(null),
			subject: new FormControl(null, [this.requiredValidator()]),
			emailDestinationName: new FormControl(null, [this.requiredValidator()]),
			emailDestinationFirstName: new FormControl(null),
			emailDestinationLastName: new FormControl(null),
			carbonCopy: new FormControl(null, [validatorUtil.emailValidator]),
			blindCarbonCopy: new FormControl(null, [
				validatorUtil.emailValidator,
				validatorUtil.uniqueEmailsInSameInputValidator,
			]),
			emailDestinationEmailAddress: new FormControl(null, [
				this.requiredValidator(),
				validatorUtil.emailValidator,
			]),
			referralService: new FormControl(null),
			isDefault: new FormControl(null),
			referralOptionType: new FormControl(null),
			referralLink: new FormControl(null),
			isChecked: new FormControl(null),
			priority: new FormControl(null),
			mergeTag: new FormControl(null),
			activityName: new FormControl(null),
			activityType: new FormControl(null),
			duration: new FormControl(null),
			location: new FormControl(null),
			details: new FormControl(null),
			meeting: new FormControl(null),
			linkToCalendar: new FormControl(null),
			settingsId: new FormControl(null),
			referenceId: new FormControl(null),
			type: new FormControl(null),
			createdDateTime: new FormControl(null),
			modifiedDateTime: new FormControl(null),
			createdByStaffId: new FormControl(null),
			modifiedByStaffId: new FormControl(null),
			createdByStaffLevel: new FormControl(null),
			modifiedByStaffLevel: new FormControl(null),
			status: new FormControl(null),
			bodyContent: new FormControl(null),

			attachment: new FormControl(null),
			attachmentFiles: new FormControl(null),

			bodyWithoutSignature: new FormControl(null),
			customerId: new FormControl(null),
			customerName: new FormControl(null),
			adviser: new FormControl(null),
			adviceProcessId: new FormControl(null),
		},
		{
			validators: validatorUtil.uniqueFieldsValidator([
				'carbonCopy',
				// 'blindCarbonCopy',
				'emailDestinationEmailAddress',
			]),
		}
	);

	optionsWysiswyg = {
		heightMax: 400,
		heightMin: 500,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkList: EmailLinkList,
		linkAutoPrefix: '',
	};
    data: ClientReferralSettingReferral;

	constructor(
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.isDataLoading = true;
		this.loadDataFn$?.()
			.pipe(
				take(1),
				tap((data) => {
					if (this.loadAttachmentFn$) {
						this.isLoadingAttachment = true;
					}
					this.loadAttachmentFn$?.(data)
						.pipe(
							take(1),
							finalize(() => (this.isLoadingAttachment = false))
						)
						.subscribe((attachments) => {
							this.isLoadingAttachment = false;
							this.attachments = (attachments ?? []).filter(Boolean);
						});
				}),
				mergeMap((data) => {
					// if email body is already modified
					if (data.bodyContent) {
						return of(data);
					}
					if (!data.body) {
						return of(data);
					}
					return this.documnetBodyLoaderFn$(data).pipe(
						map(([body, bodySignature]) => {
							const bodyContent = getContentWithMergeTags(
								body || '',
								this.mergeTags
							);
							data.subject = removeMtWrappers(
								getContentWithMergeTags(data?.subject || '', this.mergeTags)
							);
							data.bodyContent = `${bodyContent}${bodySignature}`;
							data.bodyWithoutSignature = bodyContent;
							return data;
						})
					);
				}),
				finalize(() => (this.isDataLoading = false))
			)
			.subscribe((data) => {
				this.data = data;
				this.formGroup.patchValue(data);
				if (data.emailDestinationName !== 'Other') {
					this.emailDropdownChanged(data.emailDestinationName);
				}
			});
	}

	bodyValidator(): ValidatorFn {
		return (): ValidationErrors | null => {
			if (!this.editor || this.editor?.isValid()) {
				return null;
			}
			return { required: true };
		};
	}

	requiredValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (Boolean(control.value?.trim())) {
				return null;
			}
			return { error: true };
		};
	}

	close(): void {
		// this.modalService.hide('client-referral-email-modal');
		this.subject.next(null);
	}

	onEditorValueChanged(e: any): void {
		// this.formGroup.controls.bodyContent.setValue(e.content);
	}

	private getValidationError(): ValidationErrors | null {
		if (!this.formGroup.invalid) {
			return null;
		}
		const controls = Object.keys(this.formGroup.controls);
		const controlWithError = controls.find((control) => {
			if (this.formGroup.get(control)?.errors) {
				return true;
			}
			return false;
		});
		if (!controlWithError) {
			return null;
		}
		return this.formGroup.get(controlWithError).errors;
	}

	save(): void {
		if (this.formGroup.invalid || !this.editor.isValid()) {
			const error = this.getValidationError();
			if (error?.duplicate) {
				return this.loggerService.Log({}, 'Duplicate Email Address.');
			}
			return this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
		}
		if (this.isSaving) {
			return;
		}
		const referral =
			this.formGroup.getRawValue() as ClientReferralSettingReferral;
		referral.attachmentFiles = this.attachments;
		referral.bodyContent = this.editor.content;
		referral.bodyWithoutSignature = this.editor.getContentWithoutSignature();
		this.isSaving = true;
		this.saveFn$(referral)
			.pipe(
				take(1),
				catchError((err) => {
					return throwError(err);
				}),
				finalize(() => this.isSaving = false),
			).subscribe();
	}

	emailDropdownChanged(id: any): void {
		this.users$
			.pipe(
				take(1),
				map((users) => users.find(({ value }) => value == id)),
				tap((user) => {
					this.formGroup.controls.emailDestinationEmailAddress.setValue(
						user?.email ?? ''
					);
					this.formGroup.controls.emailDestinationFirstName.setValue(
						user?.data.FirstName ?? ''
					);
					this.formGroup.controls.emailDestinationLastName.setValue(
						user?.data.LastName ?? ''
					);
				})
			)
			.subscribe();
	}

	removeAttachment(index: number): void {
		const attachment = this.attachments?.[index];
		if (!attachment) {
			return;
		}
		// if attachment is not yet saved
		// @ts-ignore-next
		if (!attachment.DocumentID) {
			this.attachments.splice(index, 1);
		} else {
			attachment.IsDeleted = true;
		}
		this.cdr.detectChanges();
	}
}
