<span
  *ngFor="let item of itemsSubject | async; let first = first; let last = last"
  class="text-truncate text-nowrap flex-shrink-0"
  [style.width]="item?.width ? item.width : 'auto'"
  [title]="item.label"
>
  <ng-container *ngIf="!item.hidden">
    {{ first ? '' : ', ' }}{{ item.label }}
  </ng-container>
</span>

<span
  class="badge bg-light text-dark ms-auto"
  [style.width.px]="MORE_ITEM_WIDTH_PX"
  [tooltip]="tooltipTemplate"
  *ngIf="(moreItems$ | async)?.length"
  >{{ (moreItems$ | async)?.length }} more</span
>

<ng-template #tooltipTemplate> {{ moreItems$ | async }} </ng-template>

<span #textPlaceholder class="text-placeholder"></span>
