<div
	class="header d-flex align-items-center justify-content-between tap--bg-primary"
>
	<span class="text-white">Refer the Client</span>
	<button
		class="close-btn text-white border bg-transparent d-flex align-items-center justify-content-center"
		aria-label="Close"
		(click)="close()"
	>
		<span class="crm-close-btn"></span>
	</button>
</div>

<div class="content bg-white">
	<form
		[formGroup]="formGroup"
		class="d-flex flex-column"
		[style.opacity]="isDataLoading ? .6 : 1"
	>
		<div class="row m-0">
			<div class="col-3 px-0 d-flex align-items-center">
				<label for="">To:</label>
			</div>
			<div class="col-5 pl-0">
				<span class="w-100 crt-custom-dropdown">
					<select
						formControlName="emailDestinationName"
						class="form-control theme-crm-field-disabled crt-form-control"
						(change)="emailDropdownChanged($event.target.value)"
					>
						<option value=""></option>
						<option *ngFor="let user of users$ | async" [value]="user.value">
							{{ user.display }}
						</option>
						<option value="Other">Other</option>
					</select>
				</span>
			</div>
			<div class="col-4">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="emailDestinationEmailAddress"
				/>
			</div>
		</div>
		<div class="row m-0">
			<div class="col-3 px-0 d-flex align-items-center">
				<label for="">CC:</label>
			</div>
			<div class="col-9 pl-0">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="carbonCopy"
				/>
			</div>
		</div>

		<ng-container *ngIf="isSettings; else emailSendingTemplate">
			<div class="row m-0">
				<div class="col-3 px-0 d-flex align-items-center">
					<label for="">BCC:</label>
				</div>
				<div class="col-9 pl-0 d-flex align-items-center">
					<span
						class="mr-2 hardcoded-user"
					>
						User
					</span>
					<input
						type="text"
						class="form-control crt-form-control"
						formControlName="blindCarbonCopy"
					/>
				</div>
			</div>
		</ng-container>

		<ng-template #emailSendingTemplate>
			<div class="row m-0">
				<div class="col-3 px-0 d-flex align-items-center">
					<label for="">BCC:</label>
				</div>
				<div class="col-9 pl-0 d-flex align-items-center">
					<span class="mr-2 hardcoded-user">
						{{ currentUser.EmailAddress }}
					</span>
				</div>
			</div>

			<div class="row m-0">
				<div class="col-3 px-0 d-flex align-items-center">
					<label for=""></label>
				</div>
				<div class="col-9 pl-0 d-flex align-items-center">
					<input
						type="text"
						class="form-control crt-form-control"
						formControlName="blindCarbonCopy"
					/>
				</div>
			</div>
		</ng-template>

		<div class="row m-0">
			<div class="col-3 px-0 d-flex align-items-center">
				<label for="">Subject:</label>
			</div>
			<div class="col-9 pl-0">
				<input
					type="text"
					class="form-control crt-form-control"
					formControlName="subject"
					appClipboardNoFormat
				/>
			</div>
		</div>

		<div class="row m-0">
			<div class="col-12 px-0 d-flex align-items-center">
				<label for="">Body:</label>
			</div>
		</div>

		<div class="row m-0">
			<div class="col-12 px-0">
				<app-wysiwyg
					#editor
					(onChangeEvent)="onEditorValueChanged($event)"
					[ngClass]="{ 'invalid': !(editor?.isValid()) ?? true }"
					[content]="data?.bodyContent"
					[simpleEditor]="true"
					[extendOptions]="optionsWysiswyg"
				></app-wysiwyg>
			</div>
		</div>
	</form>

	<div class="row m-0 p-0 flex-column mt-3">
		<span class="attachment-label"> Email Attachments </span>
		<hr class="attachment-separator" />
		<div class="d-flex w-100">
			<div class="attachments pr-3">
				<app-loader-mini2
					[isLoading]="isLoadingAttachment"
					[height]="15"
					[width]="15"
				>
					<ng-container
						*ngFor="
							let attachment of attachments;
							let index = index;
						"
					>
						<ng-container *ngIf="!attachment.IsDeleted">
							<div class="d-flex w-100 align-items-center">
								<i class="far fa-file-pdf text-danger pr-1"></i>
								<small class="text-muted text-truncate">
									{{ attachment?.FileName ?? attachment?.fileName ??
									attachment?.filename }}
								</small>
								<button
									type="button"
									class="icon-btn w-auto px-1 h-auto d-flex align-items-center"
									(click)="removeAttachment(index)"
								>
									<i class="material-icons md-16 close-icon"> close </i>
								</button>
							</div>
						</ng-container>
					</ng-container>
				</app-loader-mini2>
			</div>
			<div class="d-flex justify-content-end attach-documents-container">
				<button
					class="btn border attach-documents-btn rounded-0 p-0"
					[disabled]="isLoadingAttachment || isSaving"
					(click)="attachDoc.show()"
				>
					Attach Documents +
				</button>
			</div>
		</div>
	</div>
</div>

<div class="footer">
	<div class="buttons d-flex flex-column">
		<button
			class="btn btn-primary tap-bg-primary text-light w-100 p-0"
			[disabled]="isLoadingAttachment || isSaving"
			(click)="save()"
		>
			{{ isSettings ? 'Save' : 'Send' }}
		</button>

		<button
			class="btn btn-light w-100 p-0"
			(click)="close()"
			[disabled]="isSaving"
		>
			Cancel
		</button>
	</div>
</div>

<app-client-referral-email-modal-form-attach-doc
	[attachments]="attachments"
	[documentInfo]="documentInfo"
	[isSettings]="isSettings"
	[allowDocumentLinking]="allowDocumentLinking"
	#attachDoc
>
</app-client-referral-email-modal-form-attach-doc>
