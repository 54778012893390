import { ApplicationService } from './state/application.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteService } from 'src/app/core/config/route.service';
import {
	AdviceProcessPageCodes,
	MOATProcessRoutes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { AdviceProcessOnlineRoutes } from './../../../../../shared/models/advice-process/advice-process.model';
import { filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { CrtMortgageService } from '../state/crt-mortgage.service';
import { MortgageAdviceProcessService } from '../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { CrtMortgageQuery } from '../state/crt-mortgage.query';

@Component({
	selector: 'app-application',
	templateUrl: './application.component.html',
	styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;
	showFooter = true;
	isLoading$ = this.crtMortgageService.sectionsLoading$;
	sidebar = this.query.getValue().sidebars;

	adviceProcessId = +this.route.snapshot.paramMap.get('adviceProcessId');

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private routeService: RouteService,
		private service: ApplicationService,
		private crtMortgageService: CrtMortgageService,
		private moatService: MortgageAdviceProcessService,
		private query: CrtMortgageQuery
	) {}

	ngOnInit(): void {
		this.moatService
			.updateMortApPageStarted(AdviceProcessPageCodes.Application)
			.pipe(take(1))
			.subscribe();

		combineLatest([
			this.service.isLoading$,
			this.crtMortgageService.sectionsLoading$,
		])
			.pipe(
				filter(([x, y]) => !x && !y),
				withLatestFrom(this.crtMortgageService.sidebars$),
				tap(([, sidebars]) => {
					const isDisabled = this.service.disableApplication(sidebars);
					if (isDisabled) {
						this.navigateToTab(MOATProcessRoutes.Declaration);
					}
				}),
				take(1)
			)
			.subscribe();

		this.service.get(this.adviceProcessId).pipe(take(1)).subscribe();
		this.checkIfShowFooter();
		this.router.events
			.pipe(
				takeUntil(this.onDestroy$),
				tap(() => {
					this.checkIfShowFooter();
				})
			)
			.subscribe();
	}

	checkIfShowFooter() {
		const url = this.router.url;
		const urlSegments = url?.split('/');
		if (
			urlSegments?.length &&
			urlSegments[urlSegments.length - 1] === MOATProcessRoutes.Application
		) {
			this.showFooter = true;
		} else {
			this.showFooter = false;
		}
	}

	backToApplications() {
		this.navigateToTab(MOATProcessRoutes.Application);
	}

	navigateToTab(path) {
		// Base route
		const route = this.routeService.crtPage(
			this.route.snapshot.paramMap.get('customerType') !== 'client',
			+this.route.snapshot.paramMap.get('clientId'),
			+this.route.snapshot.paramMap.get('adviceProcessId'),
			AdviceProcessOnlineRoutes.Mortgage
		);

		// Navigate to route
		this.router.navigate([...route, path]);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
