export const sosMetaKey = {
	// MOAT > ROA
	introText: 'MOAT_SOS_INTRO_TEXT',
	notesText: 'MOAT_SOS_NOTES_TEXT',
	otherAdviceNotes: 'MOAT_SOS_OTHER_ADVICE_NOTES',
	sosOtherServiceNote: 'MOAT_SOS_OTHER_SERVICE_NOTE',
	sosServicesIncluded: 'MOAT_SOS_SERVICES_INCLUDED',

	// MOAT > SOS
	sosServicesCheckbox: 'MOAT_SOS_SERVICES_CHECKBOXES',
	sosOtherAdviceCheckbox: 'MOAT_SOS_OTHER_ADVICE_CHECKBOXES',
	sosClientGoalsText: 'MOAT_SOS_NOTES_TEXT',
	sosIntro: 'MOAT_SOS_INTRO'
};

//////////////////////////
//// MOAT > ROA > ROA ////
//////////////////////////

export const sosMergeTags = [
	{
		metaKey: sosMetaKey.introText,
		description: 'MOAT > Scope of Service > Intro Text',
		value: [''],
		type: 'T',
		secondaryValue: 'Scope of Service intro text goes here',
		noWrap: true,
	},
];

//////////////////////////
////    MOAT > SOS    ////
//////////////////////////
export const moatSosMergeTags = [
	{
		metaKey: sosMetaKey.sosServicesCheckbox,
		description: 'MOAT > Scope of Service > Services checkbox',
		value: [],
		type: 'T',
		secondaryValue: 'Scope of Service intro text goes here',
		noWrap: true,
	},
	{
		metaKey: sosMetaKey.sosOtherAdviceCheckbox,
		description: 'MOAT > Scope of Service > Other Advices checkbox',
		value: [],
		type: 'T',
		secondaryValue: 'Scope of Service intro text goes here',
		noWrap: true,
	},
];

export const sosServices = {
	newPurchase: 'New Purchase',
	lendingTopup: 'Lending Topup',
	businessLending: 'Business Lending',
	refinance: 'Refinance',
	generalStructureReview: 'General Structure Review',
	otherService: 'Other',
};

export const sosServicesList = [
	sosServices.newPurchase,
	sosServices.lendingTopup,
	sosServices.businessLending,
	sosServices.refinance,
	sosServices.generalStructureReview,
	sosServices.otherService,
];

export const sosOtherAdvice = {
	kiwiSaverAdvice: 'KiwiSaver Advice',
	lifeAndRiskReview: 'Life & Risk Review',
	houseCarContentsQuote: 'House, Car & Contents Insurance',
	otherAdvice: 'Other',
};

export const sosOtherAdviceList = [
	sosOtherAdvice.kiwiSaverAdvice,
	sosOtherAdvice.lifeAndRiskReview,
	sosOtherAdvice.houseCarContentsQuote,
	sosOtherAdvice.otherAdvice,
];
