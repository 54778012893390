<app-manual-loader [isLoading]="isLoadingSOA"></app-manual-loader>
<ng-container *ngIf="!isLoadingSOA">
	<div class="statement-of-advice clearfix d-flex flex-column">
		<div
			class="statement-of-advice__header d-flex flex-wrap mb-3"
			[class.invisible]="
				(hasActiveId$ | async) && ((active$ | async)?.currentPage === 'SOAR' || (active$ | async)?.currentPage === 'SOAD')
			"
			*ngIf="hasActiveId$ | async"
		>
			<div class="statement-of-advice__header__title mb-3 mb-lg-2 pr-0 pr-lg-3">
				<label class="mr-3" for="SOA_PaymentFrequency">Payment Frequency</label>
				<label *ngIf="active$ | async as soa">
					<span class="crt-custom-dropdown">
						<select
							id="SOA_PaymentFrequency"
							name="SOA_PaymentFrequency"
							class="d-inline-block form-control crt-form-control"
							(change)="
								onChangePaymentFrequency($event.target.value, soa.cRTId, soa)
							"
						>
							<option
								*ngFor="let f of paymentFrequency$ | async"
								[value]="f.value"
								[selected]="(active$ | async)?.paymentFrequency === f.value"
							>
								{{ f.display }}
							</option>
						</select>
					</span>
				</label>
			</div>
		</div>
		<div class="statement-of-advice__content d-flex flex-column flex-fill">
			<app-soa-index
				*ngIf="isIndex"
				(changeView)="changeView('view', $event)"
				(resetView)="changeView('reset', $event)"
				[otherMergeTags$]="otherMergeTags$"
			></app-soa-index>

			<ng-container *ngIf="!isIndex">
				<app-soa-subpage
					class="d-flex flex-column h-100"
					*ngIf="!isIndex"
					(changeView)="changeView('view')"
					[parentCRTId]="parentCrt"
					[viewMode]="viewMode"
					[otherMergeTags$]="otherMergeTags$"
					[lrProviders$]="lrProviders$"
					[isCompany]="isCompany$ | async"
					[adviceProcessId]="adviceProcessId"
					[clientId]="clientId"
				></app-soa-subpage>
			</ng-container>
		</div>
	</div>
</ng-container>
