import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Metakey } from '../lr-insurance-datatable.config';
import { LrInsuranceQuery } from '../states/lr-insurance.query';
import { LrInsuranceService } from '../states/lr-insurance.service';
import { Subject } from 'rxjs';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Row } from '../states/lr-insurance.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
declare var $: any;
@Component({
	selector: 'app-lr-insurance-columns-form',
	templateUrl: './lr-insurance-columns-form.component.html',
	styleUrls: ['./lr-insurance-columns-form.component.scss'],
})
export class LrInsuranceColumnsFormComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	private onDestroy$: Subject<void> = new Subject<void>();

	visibleColumns$ = this.lrInsuranceQuery.tableColumns$;
	hiddenColumns$ = this.lrInsuranceQuery.hiddenTableColumns$;

	visibleColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];
	hiddenColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];

	isColumnSaving$ = this.lrInsuranceQuery.uiQuery.isColumnSaving$;

	form = new UntypedFormGroup({});
	formInitialized = false;

	constructor(
		private lrInsuranceQuery: LrInsuranceQuery,
		private lrInsuranceService: LrInsuranceService,
		protected businessConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit() {
		this.visibleColumns$
			.pipe(
				takeUntil(this.onDestroy$),
				withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
				map(([vcolumns, adviserRework]) =>
					vcolumns.filter((x) =>
						adviserRework
							? x.metakey != 'Adviser'
							: x.metakey != 'LR Adviser' && x.metakey != 'GI Adviser'
					)
				)
			)
			.subscribe((res) => {
				return (this.visibleColumns = res?.filter((x) => x.metakey !== 'Name'));
			});

		this.hiddenColumns$
			.pipe(
				takeUntil(this.onDestroy$),
				withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
				map(([vcolumns, adviserRework]) =>
					vcolumns.filter((x) =>
						adviserRework
							? x.metakey != 'Adviser'
							: x.metakey != 'LR Adviser' && x.metakey != 'GI Adviser'
					)
				)
			)
			.subscribe((res) => {
				return (this.hiddenColumns = res);
			});
	}
	ngAfterViewInit() {
		$(document).ready(() => {
			$('.disabled-sortable').parent().addClass('disabled d-none');
		});
	}
	prepareFormValue() {
		return ['Name', ...this.visibleColumns?.map((x) => x.metakey)] as Metakey[];
	}

	save() {
		if (this.visibleColumns.length === 0) {
			return;
		}
		this.lrInsuranceService
			.saveVisibleColumns(this.prepareFormValue())
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				() => {},
				() => {},
				() => {
					this.lrInsuranceService.closePopup();
				}
			);
	}
	cancel() {
		this.lrInsuranceService.closePopup();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
