// please dont change the order. order is important to calculate next or previous route
export enum ClientAlterationRequestRouteName {
	Disclosure = 'disclosure',
	ScopeOfService = 'scope-of-service',
	ChangeRequest = 'change-request',
	UpdatedStructure = 'updated-structure',
}

export interface CARProgressDocumentValue {
	documentID: number;
	documentName: string;
	fileName: string;
}

export interface CARProgressDocument {
	field: string;
	value?: string | CARProgressDocumentValue;
	type: string;
}

export interface CARProgressStage {
	field: string;
	value: string;
	type: string;
}

export interface CARPrimaryCustomerIndividual {
	work: string;
	mailingAddress1: string;
	adviser: number;
	leadType: string;
	occupation: string;
	lostType: any;
	mailingAddress2: string;
	physicalAddress: any;
	dataImportID: any;
	knownAs: any;
	leadGen: any;
	originalLeadGen: any;
	leadGenClock: any;
	gender: any;
	isVulnerable: boolean;
	preferredEmailContact: any;
	employment: string;
	employer: string;
	email: string;
	alternateEmail: any;
	lRNextReview: string;
	mortgageNextReview: any;
	fGNextReview: any;
	investmentKSNextReview: any;
	city: any;
	leadStatus: any;
	rank: string;
	firstName: string;
	clientSince: string;
	postcode: any;
	dateOfBirth: any;
	homePhone: string;
	lastName: string;
	middleName: any;
	title: string;
	income: 123;
	lastReview: string;
	altAdviser: string;
	mobile: string;
	leadOrigin: string;
	referredBy: any;
	groupName: string;
	photoURL: any;
	preferredContact: string;
	professionalContacts: string;
	industry: string;
	referredByImportID: any;
	leadGenImportID: any;
	adviserImportID: any;
	countryOfOrigin: any;
	adviserGen: number;
	fullLegalName: string;
	lRAdviser: number;
	mortgageAdviser: number;
	fGAdviser: number;
	kSAdviser: number;
	investmentAdviser: number;
	gIAdviser: number;
	isKeyContact: boolean;
	vulnerableNotes: string;
	isRecycled: any;
	adviserEmail: any;
	leadGenEmail: any;
	addressLatitude: number;
	addressLongitude: number;
	contactStatus: string;
	customerID: number;
	importID: any;
	parentID: any;
	createdByStaffID: number;
	isActive: number;
	createdByStaffLevel: number;
	primaryCustomer: number;
	customerType: string;
	createDateTime: string;
	modifiedDateTime: string;
	modifiedByStaffID: any;
}

export interface CARPrimaryClient {
	contactStatus: string;
	customerID: string | number;
	importID?: string;
	createdByStaffId: string | number;
	isActive?: boolean;
	createdByStaffLevel: string;
	createDateTime: string | Date;
	modifiedDateTime: string | Date;
	work: string;
	mailingAddress1: string;
	adviser: string | number;
	leadType: string;
	occupation: string;
	lostType: string;
	reviewPending: string;
	leadPending: string;
	mailingAddress2: string;
	physicalAddress: string;
	dataImportID: string | number;
	knownAs: string;
	leadGen: string | number;
	gender: string;
	preferredContact: string;
	preferredEmailContact: string | number;
	email: string;
	employment: string;
	employer: string;
	nextReview: string;
	city: string;
	leadStatus: string;
	rank: string;
	firstName: string;
	clientSince: string;
	postcode: string | number;
	dateOfBirth: string;
	homePhone: string | number;
	lastName: string;
	middleName: string;
	title: string;
	income: number;
	lastReview: string;
	altAdviser: string[];
	mobile: string | number;
	leadOrigin: string;
	referredBy: any;
	groupName: string;
	relationship: string;
	customerType: string;
	photoURL?: string;
	fileName?: string;
	isVulnerable?: boolean;
	linkedContacts?: any[];
	countryOfOrigin?: string;
	lRNextReview?: string;
	mortgageNextReview?: string;
	fGNextReview?: string;
	investmentKSNextReview?: string;

	// Adviser Rework
	vulnerableNotes?: string;
	lRAdviser?: number;
	mortgageAdviser?: number;
	fGAdviser?: number;
	kSAdviser?: number;
	investmentAdviser?: number;
	gIAdviser?: number;
}

export interface SecondaryCustomerIndividual {
	firstName: string;
	middleName: string;
	lastName: string;
	gender: string;
	isVulnerable: boolean;
	title: any;
	employment: string;
	employer: any;
	relationship: any;
	mobile: any;
	dateOfBirth: string;
	occupation: any;
	income: number;
	email: any;
	alternateEmail: any;
	work: any;
	knownAs: any;
	physicalAddress: any;
	professionalContacts: string;
	photoURL: any;
	industry: any;
	contactMethod: any;
	countryOfOrigin: any;
	wasCopied: any;
	isKeyContact: boolean;
	vulnerableNotes: any;
	addressLatitude: number;
	addressLongitude: number;
	contactStatus: string;
	customerID: number;
	importID: any;
	parentID: any;
	createdByStaffID: number;
	isActive: number;
	createdByStaffLevel: number;
	primaryCustomer: number;
	customerType: string;
	createDateTime: string;
	modifiedDateTime: string;
	modifiedByStaffID: any;
}

export interface CARProcessClientsInvolved {
	PCI?: Partial<CARPrimaryCustomerIndividual>[];
	SCI: Partial<SecondaryCustomerIndividual>[];
}

interface _CARProcess {
	reviewGoals: any;
	importantFactors: any;
	adviceProcessID: number;
	customerID: number;
	processCode: 'CARLR';
	clientsInvolved: string;
	adviser: number;
	reviewedBy: string;
	isOnline: boolean;
	isStarted: boolean;
	startDate: string;
	endProcessDate: string;
	reopenDate: string;
	status: number;
	createdDateTime: string;
	createdByStaffId: number;
	modifiedDateTime: string;
	modifiedByStaffId: number;
	createdByStaffLevel: number;
	stages: CARProgressStage[];
	documents: CARProgressDocument[];
	completeQuestions: string[];
	inCompleteQuestions: string[];
	pageCompleted: string[];
	pageStarted: string[];
	showOriginalInsurancePolicy: boolean;
	sharedToClient: any;
	cPCurrentPage: any;
	cPStatus: number;
	cPDateSubmitted: string;
	cancellationReason: string;
	cPAdviceProcessInvitationDate: string;
	primaryClient: CARPrimaryClient;

	clients?: CARProcessClientsInvolved;
}

export interface CARProcess extends Partial<_CARProcess> {}

export enum CARSectionCodes {
	Disclosure = 'DD',
	ScopeOfService = 'CARSOS',
	ChangeRequest = 'CARCR',
	UpdatedStructure = 'CARUS',
	ChangeRequestNewPolicy = 'CRNPS',
	ChangeRequestExistingPolicy = 'CREPS',
	UpdatedStructureExistingPolicy = 'USEPS',
	UpdatedStructureFinalisedPolicy = 'USNPS',
}

export enum CARDocumentField {
	ScopeOfService = 'Scope of Service',
	ChangeRequest = 'Alteration Request',
	UpdatedStructure = 'Finalised Structure',
}

export enum CARDocumentType {
	Disclosure = 'APCARDD',
	ScopeOfService = 'APCARSOS',
	OriginRequest = 'APCAROR',
	ChangeRequest = 'APCARAR',
	UpdatedStructure = 'APCARFS',
	ChangeRequestExistingPolicy = 'CREPS',
}

export interface CARPolicyLifeAssured {
	provider?: string;
	isDeleting?: boolean;
	isDeleted?: boolean;
	fromExistingPolicy?: boolean;
	benefitFromExistingPolicy?: number;
	id?: number;
	lifeAssured?: number;
	product?: string;
	benefit?: number;
	notes?: string;
	premium?: number;
	premiumValue?: number;
	startDate?: string;
	newExisting?: string;
	priority?: number;
	netBenefitChange?: number;
	// indicator that the policy is added using "Add Product" button
	newAdded?: boolean;
}

export interface CARPolicyAttachedQuote {
	referenceId: number;
	value: string;
}

export interface CARPolicy {
	sumOfLifeAssuredList?: number;
	isDeleted?: boolean;
	newAdded?: boolean;
	policyDocuments?: CARPolicyAttachedQuote;
	policyFee: number;
	// this property came from existing policy
	totalPremium: number;
	// this property is calculated, is the sum of new policy premium
	totalPremiumValue: number;
	policyOwner: string;
	provider: string;
	premiumFrequency: string;
	additionalNotes: string;
	lifeAssured?: CARPolicyLifeAssured[];
	cRTId: number;
	sectionCode: CARSectionCodes;
	adviceProcessId: number;
	status: number;
	parentCRTId: number;
	attachedQuote?: CARPolicyAttachedQuote;
	netPremiumChange?: number;
	fromExistingPolicy?: boolean;

	isEditing?: boolean;
}
