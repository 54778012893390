import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';
import { PeopleEntitiesQuery } from '@modules/crm/crt-page/crt-mortgage/client-sop/people-entities/state/people-entities.query';
import { getEmailContentNoHtml } from '@shared/converter/content-email';
import { Attachment } from '@shared/models/_general/attachment.model';
import { ObservableUtil } from '@util/observable.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { combineLatest, iif, Observable, of, zip } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	first,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { CpSettingsService } from 'src/app/modules/cp-settings/cp-settings.service';
import { ApiService } from '../../../../../../core/base/api.service';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { BLStaffsQuery } from '../../../../../../domain/bl-staff/bl-staffs.query';
import { logMessage } from '../../../../../../shared/error-message/error-message';
import { MergeTagState } from '../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { CustomerTypes } from '../../../../../../shared/models/_general/client.model';
import { convertUtil, objectUtil, util } from '../../../../../../util/util';
import { CustomerPortalService } from '../../../../../user/service/customer-portal.service';
import { PeopleEntitiesService } from '../../../crt-mortgage/client-sop/people-entities/state/people-entities.service';
import { CrtMortgageQuery } from '../../../crt-mortgage/state/crt-mortgage.query';
import { CrtMortgageStore } from '../../../crt-mortgage/state/crt-mortgage.store';
import { CrtInviteCpAdditionalInfoModalComponent } from './crt-invite-cp-additional-info-modal/crt-invite-cp-additional-info-modal.component';
import { CrtInviteCpModalComponent } from './crt-invite-cp-modal/crt-invite-cp-modal.component';
import { CrtInviteCpModel } from './state/crt-invite-cp.model';
import { CrtInviteCpService } from './state/crt-invite-cp.service';

@Component({
	selector: 'app-crt-invite-cp',
	templateUrl: './crt-invite-cp.component.html',
	styleUrls: ['./crt-invite-cp.component.scss'],
})
export class CrtInviteCpComponent implements OnInit {
	modalRef: BsModalRef | null;
	@Input() hasNoPendingAdviserApproval: boolean;
	@Input() staffId: number;

	confirmationText = 'CONFIRM';
	isConfirm = false;

	/************** Progress Bar ****************/
	percent$ = this.service.getPercent().pipe(map((x) => x || 0));
	progress$ = this.service.getPercent().pipe(map((x) => ({ width: `${x}%` })));
	msg = 'Sync Progress';
	/************** END Progress Bar *************/

	primaryContacts$ = combineLatest([
		this.query.primaryClient$,
		this.query.secondaryClients$,
		this.query.people$,
		this.query.dependents$,
		this.peopleEntitiesService.people$,
	]).pipe(
		map(([primary, contacts, people, dependents, ffPeople]) => {
			const primaryContacts = [primary, ...contacts]?.filter((contact) => {
				if (
					!contact.email ||
					(!people?.some((a) => a.customerId === contact.customerID) &&
						!dependents?.some((a) => a.customerId === contact.customerID))
				) {
					return false;
				}
				if (
					contact.customerType === CustomerTypes.PrimaryCustomerIndividual ||
					contact.customerType === CustomerTypes.SecondaryCustomerIndividual
				) {
					// just to sync CP invitation dropdown values
					const contactInPeople = ffPeople?.find(
						(p) => p.customerId === contact.customerID
					);
					if (contactInPeople) {
						const email =
							// if FF > People email is modified
							// @ts-ignore-next
							contactInPeople.email ??
							// existing people email
							contact.email;
						// @ts-ignore-next
						contact.email = email;
						contact.invitationDisplay = `${contactInPeople.name} (${email})`;
					} else {
						// @ts-ignore-next
						contact.invitationDisplay = `${contact.firstName} ${contact.lastName} (${contact.email})`;
					}
					return true;
				}
				return false;
			});
			return primaryContacts;
		})
	);

	formShareToCP = new UntypedFormGroup({
		customerID: new UntypedFormControl(null),
		emailConfirm: new UntypedFormControl(null),
	});

	formConfirmUpdate = this.fb.group(
		{
			confirmationText: new UntypedFormControl(this.confirmationText, [
				Validators.required,
			]),
			confirmation: new UntypedFormControl(null, [Validators.required]),
		},
		{
			validator: MustMatch('confirmationText', 'confirmation'),
		}
	);

	hasInvitedCustomer$ = this.query.select((a) =>
		Boolean(
			util.tryParseJson(a.adviceProcess?.sharedToClient)?.filter((x) => !!x)
				?.length
		)
	);

	additionalInfoModalIsOpen: boolean;

	constructor(
		private service: CrtInviteCpService,
		private query: CrtMortgageQuery,
		private store: CrtMortgageStore,
		private modalService: BsModalService,
		private apiService: ApiService,
		private crtMortgageQuery: CrtMortgageQuery,
		private router: Router,
		private loggerService: LoggerService,
		private blStaffQuery: BLStaffsQuery,
		private cpService: CustomerPortalService,
		private peopleEntitiesService: PeopleEntitiesService,
		private fb: UntypedFormBuilder,
		private cpSettingsService: CpSettingsService,
		private activatedRoute: ActivatedRoute,
		private peopleEntitiesQuery: PeopleEntitiesQuery
	) {}

	ngOnInit(): void {}

	get customerID(): AbstractControl | null {
		return this.formShareToCP.controls.customerID;
	}

	get emailConfirm(): AbstractControl | null {
		return this.formShareToCP.controls.emailConfirm;
	}

	get confirmation(): AbstractControl | null {
		return this.formConfirmUpdate.controls.confirmation;
	}

	get disableShareButton(): boolean {
		if (
			// disable button if email is empty
			(this.customerID?.value !== 'other' && this.customerID?.value) ||
			// disable button if email is 'other' and other email is empty(emailConfirm)
			(this.customerID?.value === 'other' && this.emailConfirm?.value)
		) {
			return false;
		}
		return true;
	}

	get disableConfirmButton(): boolean {
		return this.confirmation?.value !== this.confirmationText;
	}

	private getCompanyCode(): string {
		let route = this.router.routerState.root;
		while (route.firstChild) {
			route = route.firstChild;
		}
		return route.snapshot.params && route.snapshot.params.companyCode
			? route.snapshot.params.companyCode
			: '';
	}

	private createEmailPayload(
		contact: any,
		adviser: string
	): Partial<CrtInviteCpModel> {
		const sender = this.store.getValue().primaryClient;
		return {
			customerID: contact.customerID,
			emailDestination: [
				{
					email: contact.email,
					name: `${contact.firstName} ${contact.lastName}`,
				},
			],
			htmlBody: '',
			emailBCC: [],
			emailCC: [],
			emailSubject: 'MOAT & SOS Email',
			emailFrom: environment.cpEmailSender,
			adviceProcessId: this.query.getValue()?.adviceProcessId,
		};
	}

	share(): void {
		if (this.disableShareButton) {
			return;
		}

		const cpSiteUrl = btoa(
			`${environment.cpSiteUrl}/${this.getCompanyCode()}/login`
		);
		const sendEmailToExistingContact = (): Observable<any> => {
			return of(+this.customerID?.value).pipe(
				mergeMap((customerId) =>
					this.primaryContacts$.pipe(
						map((contacts) => contacts.find((c) => c.customerID === customerId))
					)
				),
				mergeMap((contact) =>
					this.query.primaryClient$.pipe(
						map((primaryClient) => {
							return { contact, primaryClient };
						})
					)
				),
				mergeMap((res) => {
					return this.blStaffQuery.staffs$.pipe(
						map((staff: any) => {
							const adviser = this.store.getValue().adviceProcess.adviser;
							return staff?.find((a) => a.StaffID === adviser);
						}),
						map((staff) => {
							return { contact: res.contact, staff };
						})
					);
				}),
				map((data: any) =>
					this.createEmailPayload(data?.contact, data?.staff.FirstName)
				)
			);
		};

		const sendEmailUsingEmail = (): Observable<any> => {
			const email = this.emailConfirm?.value;
			return convertUtil
				.convertToBase64(
					`${environment.cpSiteUrl}/${this.getCompanyCode()}/login`
				)
				.pipe(
					mergeMap((cpSiteUrl) =>
						this.query.primaryClient$.pipe(map((client) => [client, cpSiteUrl]))
					),
					map(([client, cpSiteUrl]) => {
						return {
							customerID: client.customerID,
							emailDestination: [
								{
									email,
									name: '',
								},
							],
							emailBCC: [],
							emailCC: [],
							emailSubject: 'MOAT & SOS Email',
							emailFrom: environment.cpEmailSender,
							htmlBody: cpSiteUrl,
							adviceProcessId: this.query.getValue()?.adviceProcessId,
						} as Partial<CrtInviteCpModel>;
					})
				);
		};

		const IS_EXISTING_CLIENT = this.customerID?.value !== 'other';
		iif(
			() => IS_EXISTING_CLIENT,
			sendEmailToExistingContact(),
			sendEmailUsingEmail()
		)
			.pipe(first())
			.subscribe((data) => this.showEmailModal(data));
	}

	private showEmailModal(data: any): void {
		// if modal is visible dont continue
		// to prevent multiple will show
		if (this.modalRef) {
			return;
		}
		const sendFn$ = (
			emailData: Partial<CrtInviteCpModel>,
			attachments: Attachment[]
		) => {
			const adviceProcess = R.clone(
				this.crtMortgageQuery.getValue().adviceProcess
			);
			if (adviceProcess.documents) {
				adviceProcess.documents.map((document) => {
					const documentValueIsObject =
						typeof document.value === 'object' && document.value;
					if (documentValueIsObject) {
						// @ts-ignore-next
						document.value = document.value.documentID;
					}
					return document;
				});
			}
			const adviceProcessId = adviceProcess.adviceProcessID;
			const customerId = emailData.customerID?.toString();
			const sharedToClient: string[] = adviceProcess.sharedToClient
				? JSON.parse(adviceProcess.sharedToClient)
				: [];
			// if advice process is already shared to client
			if (sharedToClient.includes(customerId as string)) {
				// @ts-ignore-next
				const sharedToEmail = emailData.emailDestination[0].email;
				const sucessMessage = `${logMessage.oat.mortgage.cpInvitation.error} ${sharedToEmail}`;
				this.loggerService.Warning({}, sucessMessage);
				return this.service.sendInvitation(emailData);
			}
			if (emailData.customerID !== 0) {
				sharedToClient.push(customerId as string);
			}
			adviceProcess.SharedToClient = JSON.stringify(
				sharedToClient.filter((id) => Boolean(+id))
			);
			// @ts-ignore-next
			adviceProcess.SectionCode = 'Shared To Client';
			emailData.attachment = attachments.map((attachment) => ({
				content: attachment.content,
				disposition: 'attachment',
				filename: attachment.fileName,
			}));
			const CPShared = 7;
			return this.service.sendInvitation(emailData).pipe(
				mergeMap(() =>
					this.apiService.put(
						`adviceprocesses/${adviceProcessId}`,
						objectUtil.mapCamelCaseToPascalCase(adviceProcess)
					)
				),
				concatMap(() =>
					this.cpSettingsService.updateCPStatus(
						adviceProcess.adviceProcessID,
						CPShared
					)
				),
				ObservableUtil.finalizeSuccess(() => {
					this.formShareToCP.reset();
					this.formConfirmUpdate.reset({
						confirmationText: this.confirmationText,
						confirmation: null,
					});
					// @ts-ignore-next
					const sharedToEmail = emailData.emailDestination[0].Email;
					const sucessMessage = logMessage.oat.mortgage.cpInvitation.success;
					this.loggerService.Success({}, sucessMessage);

					const updatedAdviceProcess =
						this.crtMortgageQuery.getValue().adviceProcess;
					updatedAdviceProcess.sharedToClient = `["${customerId}"]`;
					this.store.setAdviceProcess(updatedAdviceProcess);
				})
			);
		};

		const loadContent$ = () => {
			return of(this.staffId).pipe(
				filter((x) => !!x),
				mergeMap((x) =>
					this.cpSettingsService.getEmailSettings(
						this.cpSettingsService.emailInvitationCode
					)
				),
				mergeMap((x) => {
					if (!!x && x !== '' && !!x?.body && !isNaN(+x?.body)) {
						return this.cpSettingsService
							.getDocumentFile(+x?.body)
							.pipe(map((doc) => ({ ...x, document: doc })));
					}
					if (!!x && x !== '' && !!x?.body && isNaN(+x?.body)) {
						return of({
							...x,
							bodyContent: atob(x?.body),
							subject: atob(x?.subject),
						});
					}
					return of(x);
				}),
				map((res) =>
					!!res && !!res?.document
						? {
								...res,
								document: objectUtil.mapPascalCaseToCamelCase(res.document),
						  }
						: res
				),
				mergeMap((res) =>
					iif(
						() => !!res?.document?.documentLink,
						this.cpSettingsService
							.getDocumentFromURL(res?.document?.documentLink)
							.pipe(map((bodyContent) => ({ ...res, bodyContent }))),
						of({ ...res, bodyContent: res?.bodyContent || '' })
					)
				),
				take(1)
			);
		};

		const people = this.query.getValue()?.people || [];
		const dependents = this.query.getValue()?.dependents || [];
		const customerId = +this.customerID?.value;
		const person = [...people, ...dependents]?.find(
			(p) => p.customerId === customerId
		);
		// Merge tags
		const mergeTags$: Observable<MergeTagState[]> = zip(
			this.cpSettingsService
				.getCPPersonMergeTag(person?.cRTId, person?.relationship !== 'Child')
				.pipe(take(1)),
			this.cpSettingsService.getMergeTags('general').pipe(take(1)),
			this.cpSettingsService.getMergeTags('business').pipe(take(1)),
			this.cpSettingsService
				.getMergeTags('staff', null, +this?.staffId)
				.pipe(take(1)),
			this.cpSettingsService
				.getMergeTags(
					'section',
					'fact-find',
					+this.query.getValue()?.adviceProcessId ?? 0,
					'FPP',
					true
				)
				.pipe(take(1))
		).pipe(
			map((x) => x.map((y) => R.values(y))),
			map((x) => R.flatten(x)),
			map((x) => x.map((y) => objectUtil.mapPascalCaseToCamelCase(y)))
		);

		this.modalRef = this.modalService.show(CrtInviteCpModalComponent, {
			// class: 'crt-invite-cp-modal',
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState: {
				adviceProcessId: +this.activatedRoute.snapshot.params.adviceProcessId,
				adviserId: +this.staffId,
				loadContent$,
				mergeTags$,
				sendFn$,
				data,
				company: this.getCompanyCode(),
			},
		});
		this.modalService.onHidden.pipe(first()).subscribe(() => {
			this.modalRef = null;
		});
	}

	confirmSync() {
		this.isConfirm = true;
	}

	confirm(): void {
		if (this.disableConfirmButton) {
			return;
		}
		this.service
			.sync(this.query.getValue()?.adviceProcessId)
			.pipe(
				tap((x) => {
					this.loggerService.Success({}, 'Successfully updated client info.');
				}),
				catchError((err) => {
					this.loggerService.Log(
						{},
						'Error encountered while updating client info.'
					);
					return err;
				}),
				finalize(() => {
					this.isConfirm = false;
					this.formConfirmUpdate.reset({
						confirmationText: this.confirmationText,
						confirmation: null,
					});
					setTimeout(() => {
						this.modalRef?.hide();
						this.service.clearPercent();
					}, 1000);
				}),
				take(1)
			)
			.subscribe();
	}

	openModal(syncTemplate: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			syncTemplate,
			Object.assign(
				{},
				{
					class: 'modal-dialog-centered gray modal-xl w-50',
					ignoreBackdropClick: true,
				}
			)
		);
	}

	showAdditionalInfoModal(): void {
		if (this.additionalInfoModalIsOpen) {
			return;
		}
		const mergeTags = (person) =>
			zip(
				this.cpSettingsService
					.getMergeTags(
						'section',
						'fact-find',
						+this.query.getValue()?.adviceProcessId || 0,
						'FPP',
						true
					)
					.pipe(take(1)),
				this.cpSettingsService.getMergeTags('general').pipe(take(1)),
				this.cpSettingsService.getMergeTags('business').pipe(take(1)),
				this.cpSettingsService
					.getMergeTags('staff', null, +this?.staffId)
					.pipe(take(1)),
				this.cpSettingsService.getCPPersonMergeTag(
					person?.cRTId,
					person?.relationship !== 'Child'
				)
			).pipe(
				map((x) => x.map((y) => R.values(y))),
				map((x) => R.flatten(x)),
				map((x) => x.map((y) => objectUtil.mapPascalCaseToCamelCase(y))),
				take(1)
			);

		this.additionalInfoModalIsOpen = true;
		this.primaryContacts$
			.pipe(
				map((contacts) => {
					const adviceProcess = this.store.getValue()?.adviceProcess;
					const sharedToClient = util.tryParseJson(
						adviceProcess.sharedToClient
					) as number[];
					return contacts.find((a) => a.customerID == sharedToClient[0]);
				}),
				mergeMap((contact) => {
					return this.cpSettingsService.getAdditionalSetting().pipe(
						map((settings) => {
							return { contact, settings };
						})
					);
				}),
				mergeMap(({ contact, settings }) => {
					return combineLatest([
						this.peopleEntitiesQuery.people$,
						this.peopleEntitiesQuery.dependents$,
					]).pipe(
						map(([people, dependents]) => ({
							contact,
							settings,
							people: [...people, ...dependents],
						}))
					);
				}),
				take(1)
			)
			.subscribe(({ contact, settings, people }) => {
				if (!contact) {
					this.additionalInfoModalIsOpen = false;
					return;
				}
				const data = {
					sendTo: contact.email,
					customerId: contact.customerID,
					name: `${contact.firstName} ${contact.lastName}`,
					email: contact.email,
					...settings,
				};

				const person = people.find((p) => p.customerId == contact.customerID);
				this.modalRef = this.modalService.show(
					CrtInviteCpAdditionalInfoModalComponent,
					{
						// class: 'crt-invite-cp-modal',
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						ignoreBackdropClick: true,
						keyboard: false,
						initialState: {
							sendFn$: this.sendAdditionalInformation,
							mergeTags$: mergeTags(person),
							data,
						},
					}
				);
				this.modalService.onHidden.pipe(first()).subscribe(() => {
					this.modalRef = null;
					this.additionalInfoModalIsOpen = false;
				});
			});
	}

	sendAdditionalInformation = (data: any, attachments: Attachment[]) => {
		const emailBody = data.content;

		const carbonCopy = data.carbonCopy
			?.split(',')
			?.filter((a: string) => Boolean(a))
			?.map((cc) => {
				return { Email: cc, Name: cc };
			});
		const blindCarbonCopy = data.blindCarbonCopy
			?.split(',')
			?.filter((a) => Boolean(a))
			?.map((bcc: string) => {
				return { Email: bcc, Name: bcc };
			});
		const emailData = {
			Attachment: attachments.map((attachment) => ({
				content: attachment.content,
				disposition: 'attachment',
				filename: attachment.fileName,
			})),
			EmailDestination: [
				{
					Email: data.email,
					Name: data.name,
				},
			],
			EmailBCC: blindCarbonCopy,
			EmailCC: carbonCopy,
			EmailSubject: data.subject,
			EmailFrom: environment.cpEmailSender,
			HTMLBody: '',
			AdviceProcessId: +this.activatedRoute.snapshot.params.adviceProcessId,
			CpClient: {
				Email: data.email,
				Name: data.name,
			},
			RequestType: 'Request client info',
			StringBody: getEmailContentNoHtml(emailBody),
		};
		return convertUtil.convertToBase64(emailBody).pipe(
			mergeMap((htmlBodyBase64) => {
				emailData.HTMLBody = htmlBodyBase64;
				return this.service.sendAdditionalInformation(emailData);
			}),
			take(1)
		);
	};
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
	return (formGroup: UntypedFormGroup) => {
		const control = formGroup.controls[controlName];
		const matchingControl = formGroup.controls[matchingControlName];

		if (matchingControl.errors && !matchingControl.errors.mustMatch) {
			// return if another validator has already found an error on the matchingControl
			return;
		}

		// set error on matchingControl if validation fails
		if (control.value !== matchingControl.value) {
			matchingControl.setErrors({ mustMatch: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
}
