import { DataImportService } from './data-import.component.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { DataImportComponent } from './data-import.component';
import { DataImportRoutingModule } from './data-import-routing.module';
import { ImportHistoryComponent } from './import-history/import-history.component';
import { NewImportComponent } from './new-import/new-import.component';
import { ImportTypeComponent } from './new-import/import-type/import-type.component';
import { ImportProgressComponent } from './new-import/import-progress/import-progress.component';
import { ImportSummaryComponent } from './new-import/import-summary/import-summary.component';
import { PartialViewComponent } from './new-import/import-type/partial-view/partial-view.component';
import { ClientComponent } from './new-import/import-type/client/client.component';
import { ServicesComponent } from './new-import/import-type/services/services.component';
import { OthersComponent } from './new-import/import-type/others/others.component';

@NgModule({
  imports: [CommonModule, DataImportRoutingModule, SharedModule],
  declarations: [
    DataImportComponent,
    ImportHistoryComponent,
    NewImportComponent,
    ImportTypeComponent,
    ImportProgressComponent,
    ImportSummaryComponent,
    PartialViewComponent,
    ClientComponent,
    ServicesComponent,
    OthersComponent
  ],
  providers: [
    DataImportService,
  ]
})

export class DataImportModule { }
