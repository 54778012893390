<div
	class="form-row d-lg-flex mb-2"
	*ngFor="let liability of liabilities; let index = index; trackBy: trackByFn"
>
	<div class="d-flex px-2 w-100 flex-column">
		<div class="d-flex justify-content-between align-items-center">
			<h5>{{ liability.lender }}: {{ liability.liability }}</h5>
			<div>
				<span
					*ngIf="
						isLoading &&
						(!!liability?.isEditing ||
							!!liability?.isAdd ||
							liability?.isDeleting)
					"
				>
					<div
						class="d-flex flex-row bd-highlight justify-content-end px-1 my-2 pb-2"
					>
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isLoading"
						></app-manual-loader-mini>
					</div>
				</span>
				<ng-container
					*ngIf="
						!isLoading ||
						(!liability?.isEditing &&
							!liability?.isDeleting &&
							!liability.isAdd)
					"
				>
					<ng-container *ngIf="!liability.isEditing && !liability.isAdd">
						<ng-container *appRestrictTo="['FES']">
							<button
								[disabled]="isLoading || isEditing || isAdd"
								type="button"
								class="icon-btn w-auto h-auto px-1 my-2"
								(click)="edit(index)"
							>
								<i class="material-icons md-20 tap-text-color-h6">edit</i>
							</button>
						</ng-container>
						<ng-container *appRestrictTo="['FDS']">
							<button
								[disabled]="isLoading || isEditing || isAdd"
								type="button"
								class="icon-btn w-auto h-auto px-1 my-2"
								(click)="delete(index)"
							>
								<i class="material-icons md-20 tap-text-color-h6">delete</i>
							</button>
						</ng-container>
					</ng-container>

					<ng-container *appRestrictTo="['FES']">
						<ng-container *ngIf="liability.isEditing || liability.isAdd">
							<button
								[disabled]="isLoading"
								type="button"
								class="icon-btn w-auto h-auto px-1 my-2"
								(click)="save(index, liabilityServiceForm)"
							>
								<i class="material-icons md-20 tap-text-color-h6">save</i>
							</button>
							<button
								[disabled]="isLoading"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="cancelAddOrEdit(index)"
							>
								<i class="material-icons md-20 tap-text-color-h6">close</i>
							</button>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div>
			<app-liability-service-form
				#liabilityServiceForm
				[liability]="liability"
				[isAdd]="liability.isAdd"
				[saveFn$]="saveFn$"
				[updateFn$]="updateFn$"
				[liabilityStatus]="liabilityStatus"
				[liabilityChoices]="liabilityChoices"
				[borrowers]="borrowers"
				[loanTypes]="loanTypes"
				[lenders]="lenders"
				[repaymentFrequency]="repaymentFrequency"
			>
			</app-liability-service-form>
		</div>
	</div>
</div>
<!-- <div class="form-row clearfix mb-2"> -->
<!-- 	<div class="col-5 col-lg-3 mb-2"> -->
<!-- 		<ng-container *ngIf="!isAdd"> -->
<!-- 			<ng-container *ngIf="isLoading"> -->
<!-- 				<app-manual-loader-mini -->
<!-- 					[isLoading]="isLoading" -->
<!-- 				></app-manual-loader-mini> -->
<!-- 			</ng-container> -->
<!-- 			<button -->
<!-- 				*ngIf="!isLoading" -->
<!-- 				type="button" -->
<!-- 				(click)="addNew()" -->
<!-- 				class="tap-btn tap-btn--default" -->
<!-- 				id="addLiability" -->
<!-- 			> -->
<!-- 				Add Liability + -->
<!-- 			</button> -->
<!-- 		</ng-container> -->
<!-- 	</div> -->
<!-- </div> -->
<ng-container *ngIf="!isAdd && type === 'current'">
	<br />
	<div class="form-row clearfix align-items-center py-2">
		<div class="col-lg d-flex align-items-center">
			<strong class="mr-3">Notes</strong>
			<ng-container *ngIf="isLead">
				<button
					type="button"
					class="icon-btn h-auto"
					[disabled]="isLoading"
					(click)="openModalAddNote()"
				>
					<i class="material-icons md-20 tap-text-color-h6">note_add</i>
				</button>
			</ng-container>
			<ng-container *ngIf="!isLead">
				<!-- *appRestrictTo="['FAN']" -->
				<button
					type="button"
					class="icon-btn h-auto"
					[disabled]="isLoading"
					(click)="openModalAddNote()"
				>
					<i class="material-icons md-20 tap-text-color-h6">note_add</i>
				</button>
			</ng-container>
		</div>
	</div>
	<div class="form-row clearfix align-items-center mb-2">
		<div class="col-lg col-12">
			<app-table-paginate-notes
				[isService]="true"
				[notes]="notes"
				[itemsPerPage]="5"
				[deleteFn$]="deleteNote$"
				[showHeader]="false"
			>
			</app-table-paginate-notes>
		</div>
	</div>
</ng-container>
