import { ChangeDetectorRef, Injectable } from '@angular/core';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { ApiService } from '../../../../../core/base/api.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { IncomeSourceState } from '../../../../../shared/models/client-review-template/income-budget/income-source-details.model';
import { FactFindComputationState } from '../../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import {
	RentalIncomeState,
	RentalIncomeObjState,
} from '../../../../../shared/models/client-review-template/income-budget/rental-income.model';
import {
	OtherIncomeState,
	OtherIncomeObjState,
} from '../../../../../shared/models/client-review-template/income-budget/other-income.model';
import { MonthlyExpenseDetailsState } from '../../../../../shared/models/client-review-template/income-budget/monthly-expense.model';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { objectUtil } from '../../../../../util/util';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { BusinessService } from 'src/app/core/business/business.service';
import {
	computeAnnualTaxable,
	computeMonthlyTaxable,
	computeMonthlyAfterTaxIncome,
} from '../../../../../modules/crm/client-review-template/income-budget/calculations/compute-taxable';
import { numUtil } from '../../../../../util/util';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AssetsLiabilitiesService } from '../assets-liabilities/assets-liabilities.service';
import { complement, either, isNil, isEmpty } from 'ramda';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

export interface IncomeAndExpensesEntities {
	IncomeSource?: IncomeSourceState[];
	RentalIncome?: RentalIncomeObjState[];
	OtherIncome?: OtherIncomeObjState[];
	MonthlyExpense?: MonthlyExpenseDetailsState[];
}

@Injectable()
export class IncomeService
	extends ClientReviewTemplateService
	implements Resolve<boolean>
{
	incomeSource$ = this.query.incomeSource$;
	netRentalIncome$ = this.query.netRentalIncome$;
	otherIncome$ = this.query.otherIncome$;
	properties$ = this.query.properties$;
	monthlyExpense$ = this.query.monthlyExpense$;
	factFindComputation$ = this.query.factFindComputation$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected aLService: AssetsLiabilitiesService,
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getInfoByCRT(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<any>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => of([]))
		);
	}

	getIncomeData(
		adviceProcessId: number
	): Observable<IncomeAndExpensesEntities> {
		const endpoint = `crt/fact-find/${adviceProcessId}/group/IE`;
		return this.api.get<IncomeAndExpensesEntities>(endpoint).pipe(
			tap((result) => {
				const getState = <T>(entities: T[]): T => {
					return complement(either(isNil, isEmpty))(entities)
						? entities?.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
				};

				const data = result?.IncomeSource
					?.map((item) => ({
						...item,
						adviceProcessId,
					}))
					?.map(objectUtil.mapPascalCaseToCamelCase) as IncomeSourceState[];
				const incomeSourceStateeSourceState = result?.IncomeSource ? data : [];
				this.store.setIncomeSource(incomeSourceStateeSourceState);
				this.setOverallTotalGross(incomeSourceStateeSourceState);

				const rentalIncomeState = getState(result.RentalIncome);
				this.store.setRentalIncome(rentalIncomeState);
				this.setTotalNetRentalIncome(rentalIncomeState, true);

				const otherIncomeState = getState(result.OtherIncome);
				this.store.setOtherIncome(otherIncomeState);
				this.setTotalAnnualIncome(otherIncomeState, true);

				const monthlyExpensesState = getState(result?.MonthlyExpense);
				this.store.setMonthlyExpense(monthlyExpensesState);
			}),
			catchError((err) => {
				return EMPTY;
			})
		);
	}

	getIncomeSources(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = x
						?.map((item) => ({
							...item,
							adviceProcessId,
						}))
						?.map(objectUtil.mapPascalCaseToCamelCase) as IncomeSourceState[];
					const state = x ? data : [];
					this.store.setIncomeSource(state);
					this.setOverallTotalGross(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addIncomeSource(incomeSource: IncomeSourceState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(incomeSource);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					// tslint:disable-next-line: no-angle-bracket-type-assertion
					const data = <IncomeSourceState[]>[
						...this.query.getValue().incomeSource,
						{
							cRTId: x,
							incomeEarner: incomeSource.incomeEarner,
							employment: incomeSource.employment,
							occupation: incomeSource.occupation,
							totalGrossIncome: incomeSource.totalGrossIncome,
							adviceProcessId,
							incomeType: incomeSource.incomeType,
						},
					];

					this.store.setIncomeSource(data);
					this.setOverallTotalGross(data);
				})
			),
			catchError((err) => {
				return EMPTY;
			})
		);
	}

	deleteIncomeSource(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.incomeSource?.filter((y) => y.cRTId !== id);
					this.store.setIncomeSource(data);
					this.setOverallTotalGross(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateIncomeSource(incomeSource: IncomeSourceState) {
		const endpoint = `crt/${incomeSource.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(incomeSource);
		return this.api.put<IncomeSourceState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					// tslint:disable-next-line: no-angle-bracket-type-assertion
					const data = <IncomeSourceState[]>(
						this.query.getValue().incomeSource.map((y) =>
							y.cRTId === incomeSource.cRTId
								? {
										incomeEarner: incomeSource.incomeEarner,
										employment: incomeSource.employment,
										occupation: incomeSource.occupation,
										totalGrossIncome: incomeSource.totalGrossIncome,
										adviceProcessId: incomeSource.adviceProcessId,
										cRTId: incomeSource.cRTId,
										incomeType: incomeSource.incomeType,
								  }
								: y
						)
					);
					this.store.setIncomeSource(data);
					this.setOverallTotalGross(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	getRentalIncome(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? x?.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
					this.store.setRentalIncome(state);
					this.setTotalNetRentalIncome(state, true);
				})
			),
			catchError(() => of([]))
		);
	}

	updateRentalIncome(netRentalIncome: RentalIncomeState) {
		const endpoint = `crt/${netRentalIncome.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(netRentalIncome);
		return this.api.put<RentalIncomeObjState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.rentalDetails?.netRentalIncomeList?.map((y) =>
							y.cRTId === netRentalIncome.cRTId
								? {
										status: 1,
										propertyAsset: netRentalIncome.propertyAsset,
										netRentalIncome: netRentalIncome.netRentalIncome,
										adviceProcessId: netRentalIncome.adviceProcessId,
										cRTId: netRentalIncome.cRTId,
								  }
								: y
						) as RentalIncomeState[];
					const data = {
						netRentalIncomeList: updateIncome,
						totalNetRentalIncome:
							this.query.getValue().rentalDetails.totalNetRentalIncome,
					};
					this.store.setRentalIncome(data);
					this.setTotalNetRentalIncome(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	addRentalIncome(
		netRentalIncome: RentalIncomeState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(netRentalIncome);
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const netRentalList =
						this.query.getValue().rentalDetails?.netRentalIncomeList || [];
					const totalNetRental =
						+this.query.getValue().rentalDetails?.totalNetRentalIncome || 0;
					const updateIncome = [
						...netRentalList,
						{
							cRTId: x,
							status: 1,
							propertyAsset: netRentalIncome.propertyAsset,
							netRentalIncome: +netRentalIncome.netRentalIncome,
							adviceProcessId: netRentalIncome.adviceProcessId,
						} as RentalIncomeState,
					] as RentalIncomeState[];
					const data = {
						netRentalIncomeList: updateIncome,
						totalNetRentalIncome:
							+netRentalIncome.netRentalIncome + +totalNetRental,
					};
					this.store.setRentalIncome(data);
					this.setTotalNetRentalIncome(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteRentalIncome(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.rentalDetails.netRentalIncomeList?.filter(
							(y) => y.cRTId !== id
						) as RentalIncomeState[];
					const data = {
						netRentalIncomeList: updateIncome,
						totalNetRentalIncome:
							+this.query.getValue().rentalDetails.totalNetRentalIncome,
					};
					this.store.setRentalIncome(data);
					this.setTotalNetRentalIncome(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getOtherIncome(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? x?.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
					this.store.setOtherIncome(state);
					this.setTotalAnnualIncome(state, true);
				})
			),
			catchError(() => of([]))
		);
	}

	updateOtherIncome(otherIncome: OtherIncomeState) {
		const endpoint = `crt/${otherIncome.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(otherIncome);
		return this.api.put<OtherIncomeObjState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.otherIncomeDetails?.otherIncome?.map((y) =>
							y.cRTId === otherIncome.cRTId
								? {
										status: 1,
										incomeType: otherIncome.incomeType,
										annualIncome: otherIncome.annualIncome,
										adviceProcessId: otherIncome.adviceProcessId,
										cRTId: otherIncome.cRTId,
								  }
								: y
						) as OtherIncomeState[];
					const data = {
						otherIncome: updateIncome,
						totalAnnualIncome:
							+this.query.getValue().otherIncomeDetails.totalAnnualIncome,
					};
					this.store.setOtherIncome(data);
					this.setTotalAnnualIncome(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	addOtherIncome(otherIncome: OtherIncomeState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(otherIncome);
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const otherIncomeList =
						this.query.getValue().otherIncomeDetails?.otherIncome || [];
					const totalOtherIncome =
						+this.query.getValue().otherIncomeDetails?.totalAnnualIncome || 0;
					const updateIncome = [
						...otherIncomeList,
						{
							cRTId: x,
							status: 1,
							incomeType: otherIncome.incomeType,
							annualIncome: +otherIncome.annualIncome,
							adviceProcessId: otherIncome.adviceProcessId,
						} as OtherIncomeState,
					] as OtherIncomeState[];
					const data = {
						otherIncome: updateIncome,
						totalAnnualIncome: +otherIncome.annualIncome + +totalOtherIncome,
					};
					this.store.setOtherIncome(data);
					this.setTotalAnnualIncome(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteOtherIncome(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.otherIncomeDetails.otherIncome?.filter(
							(y) => y.cRTId !== id
						) as OtherIncomeState[];
					const data = {
						otherIncome: updateIncome,
						totalAnnualIncome:
							+this.query.getValue().otherIncomeDetails.totalAnnualIncome,
					};
					this.store.setOtherIncome(data);
					this.setTotalAnnualIncome(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getMonthlyExpense(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? x?.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
					this.store.setMonthlyExpense(state);
				})
			),
			catchError(() => of([]))
		);
	}

	updateMonthlyExpenseState(monthlyExpense: MonthlyExpenseDetailsState) {
		this.setHasFormChanges(true);
		this.store.setMonthlyExpense(monthlyExpense);
	}

	updateMonthlyExpense(monthlyExpense: MonthlyExpenseDetailsState) {
		const endpoint = `crt/${monthlyExpense.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(monthlyExpense);
		return this.api.put<MonthlyExpenseDetailsState[]>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setMonthlyExpense(monthlyExpense);
				})
			),
			catchError(() => EMPTY)
		);
	}

	addMonthlyExpense(
		monthlyExpense: MonthlyExpenseDetailsState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(monthlyExpense);
		return this.api.post3<MonthlyExpenseDetailsState[]>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setMonthlyExpense({ ...monthlyExpense, cRTId: +x });
				})
			),
			catchError(() => EMPTY)
		);
	}

	setOverallTotalGross(data) {
		let overallGross = 0;
		const factFindComputation = this.query.getValue()
			.factFindComputation as FactFindComputationState;
		data?.map((item: any) => {
			overallGross += +item.totalGrossIncome;
		});

		this.store.setFactFindComputation({
			...factFindComputation,
			overallTotalGrossIncome: +numUtil.formatToNumCurrency(overallGross),
		});
		setTimeout(() => this.computeTaxable(), 50);
	}

	setTotalNetRentalIncome(data, isGet: boolean = false) {
		let totalNetRental = 0;
		const factFindComputation = this.query.getValue()
			.factFindComputation as FactFindComputationState;
		if (isGet) {
			totalNetRental = data.totalNetRentalIncome;
		} else {
			data.netRentalIncomeList?.map((item: any) => {
				totalNetRental += +item.netRentalIncome;
			});
		}
		this.store.setFactFindComputation({
			...factFindComputation,
			totalNetRentalIncome: +numUtil.formatToNumCurrency(totalNetRental),
		});
		setTimeout(() => {
			this.computeTaxable();
		}, 10);
	}

	setTotalAnnualIncome(data, isGet: boolean = false) {
		let totalAnnual = 0;
		const factFindComputation = this.query.getValue()
			.factFindComputation as FactFindComputationState;
		if (isGet) {
			totalAnnual = data.totalAnnualIncome;
		} else {
			data.otherIncome?.map((item: any) => {
				totalAnnual += +item.annualIncome;
			});
		}
		this.store.setFactFindComputation({
			...factFindComputation,
			totalAnnualIncome: +numUtil.formatToNumCurrency(totalAnnual),
		});
		setTimeout(() => {
			this.computeTaxable();
		}, 10);
	}

	computeTaxable(returnOnly: boolean = false) {
		const factFind = this.query.getValue()
			.factFindComputation as FactFindComputationState;
		let annualTaxable = 0;
		let monthlyTaxable = 0;
		let monthlyAfterTax = 0;
		if (factFind) {
			annualTaxable = computeAnnualTaxable(factFind);
			monthlyTaxable = computeMonthlyTaxable(+annualTaxable);

			monthlyAfterTax = computeMonthlyAfterTaxIncome(
				this.query.getValue().incomeSource || [],
				{
					...factFind,
					annualTaxableJointIncome: +annualTaxable,
					monthlyTaxableJointIncome: +monthlyTaxable,
				},
				this.getIncomeLists()
			);

			const taxables = {
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
				monthlyAfterTaxIncome: +monthlyAfterTax,
			};
			if (returnOnly) {
				return { ...factFind, ...taxables };
			} else {
				this.store.setFactFindComputation({ ...factFind, ...taxables });
			}
		}
	}

	getIncomeLists() {
		const incomeSource = this.query.getValue().incomeSource;
		const rentalDetails = this.query.getValue().rentalDetails;
		const otherIncomeDetails = this.query.getValue().otherIncomeDetails;
		const rentalList = rentalDetails?.netRentalIncomeList || [];
		const otherList = otherIncomeDetails?.otherIncome || [];
		const properties = this.query.getValue().properties || [];

		return {
			incomeSource,
			rentalList,
			otherList,
			properties,
		};
	}

	resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
		const adviceProcessId = +route.paramMap.get('adviceProcessId');

		const getIncomeSources$ = isNil(this.query.getValue().incomeSource)
			? this.getIncomeSources(
					adviceProcessId,
					AdviceProcessSectionCodes.IncomeSource
			  )
			: of(undefined);

		const getRentalIncome$ = isNil(this.query.getValue().rentalDetails)
			? this.getRentalIncome(
					adviceProcessId,
					AdviceProcessSectionCodes.RentalIncome
			  )
			: of(undefined);

		const getOtherIncome$ = isNil(this.query.getValue().otherIncomeDetails)
			? this.getOtherIncome(
					adviceProcessId,
					AdviceProcessSectionCodes.OtherIncome
			  )
			: of(undefined);

		const getMonthlyExpense$ = isNil(this.query.getValue().monthlyExpense)
			? this.getMonthlyExpense(
					adviceProcessId,
					AdviceProcessSectionCodes.MonthlyExpense
			  )
			: of(undefined);

		return forkJoin([
			getIncomeSources$,
			getRentalIncome$,
			getOtherIncome$,
			getMonthlyExpense$,
		]).pipe(mapTo(true));
	}
}
