import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { KIWISAVER_FF_STEP_COUNT } from '../crt-ks-fact-find-step-index';
import {
	CrtKiwiSaverFactFind,
	CrtKiwisaverFactFindStore,
} from './crt-ks-fact-find.store';

@Injectable({ providedIn: 'root' })
export class CrtKiwisaverFactFindQuery extends Query<CrtKiwiSaverFactFind> {
	constructor(protected store: CrtKiwisaverFactFindStore) {
		super(store);
	}

	getAnimationStepCount(): number {
		const disabledPageCount = Object.values(
			this.getValue()?.settings ?? {}
		).filter((value) => value === false).length;
		return KIWISAVER_FF_STEP_COUNT.ANIMATIONS - disabledPageCount;
	}
}
