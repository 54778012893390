<div class="scope-of-service pr-2">
	<form [formGroup]="form" class="form">
		<div class="row pb-0 pl-3 pt-4">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold"
							>What does the client need help with?</label
						>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 pb-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-lg-6 col-12">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="newPurchase"
									formControlName="newPurchase"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">New Purchase</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="lendingTopup"
									formControlName="lendingTopup"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Lending Topup</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="businessLending"
									formControlName="businessLending"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Business Lending</label>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-12">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="refinance"
									formControlName="refinance"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Refinance</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="generalStructureReview"
									formControlName="generalStructureReview"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">General Structure Review</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="otherService"
									formControlName="otherService"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Other</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold">Your goals:</label>
					</div>
				</div>
			</div>
		</div>
		<div class="row pb-0 pl-3 pb-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<textarea
							#contentEditor
							class="form-control theme-crm-field-disabled"
							formControlName="otherSpecificClientGoals"
							rows="18"
							id="contentEditor"
						>
							{{ OtherSpecificClientGoals.value  }}
						</textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold"
							>Scope of Service PDF Template</label
						>
					</div>
				</div>
			</div>
		</div>
		<div class="row pb-0 pl-3 pb-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12 scope-of-service__textbox">
						<textarea
							class="form-control"
							name="intro"
							id="intro"
							cols="30"
							rows="4"
							formControlName="intro"
						></textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold">Other Advice:</label>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 b-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-lg-12 col-12 pb-3">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="kiwiSaverAdvice"
									formControlName="kiwiSaverAdvice"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">KiwiSaver Advice</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-12 scope-of-service__textbox">
								<textarea
									class="form-control"
									name="kiwiSaverAdviceDetails"
									id="kiwiSaverAdviceDetails"
									cols="30"
									rows="4"
									formControlName="kiwiSaverAdviceDetails"
								></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-12 pb-3">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="lifeAndRiskReview"
									formControlName="lifeAndRiskReview"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Life & Risk Review</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-12 scope-of-service__textbox">
								<textarea
									class="form-control"
									name="lifeAndRiskReviewDetails"
									id="lifeAndRiskReviewDetails"
									cols="30"
									rows="4"
									formControlName="lifeAndRiskReviewDetails"
								></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-12 pb-3">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="houseCarContentsQuote"
									formControlName="houseCarContentsQuote"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label"
									>House, Car & Contents Insurance</label
								>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-12 scope-of-service__textbox">
								<textarea
									class="form-control"
									name="houseCarContentsQuoteDetails"
									id="houseCarContentsQuoteDetails"
									cols="30"
									rows="4"
									formControlName="houseCarContentsQuoteDetails"
								></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-12">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="otherAdvice"
									formControlName="otherAdvice"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Other</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 mb-4">
			<div class="col-12 col-lg-7 text-right">
				<app-manual-loader-mini
					[isLoading]="isLoading"
				></app-manual-loader-mini>
				<button
					type="button"
					class="btn btn-primary my-2 ml-1"
					[appTheme]="{ 'background-color': 'primarycolor' }"
					(click)="save()"
					*ngIf="!isLoading"
				>
					SAVE
				</button>
			</div>
		</div>
	</form>
</div>
