import { BLStaffSecGroup } from './bl-staff-security-group.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface SecGroupState extends EntityState<BLStaffSecGroup> { }

@Injectable()
@StoreConfig({
    name: 'blStaffSecGroups',
    idKey: 'SecurityGroupID'
})
export class BLStaffSecGroupsStore extends EntityStore<SecGroupState, BLStaffSecGroup> {
    constructor() {
        super();
    }
}
