import { Component, OnDestroy, OnInit } from '@angular/core';
import * as R from 'ramda';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MergeTagsMapper } from '../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { SoaSettingsMapper } from '../soa-settings/state/soa-settings.mapper';
import { MergeTagsCrtSettingsService } from './state/merge-tags-crt-settings.service';

@Component({
	selector: 'app-merge-tags',
	templateUrl: './merge-tags.component.html',
	styleUrls: ['./merge-tags.component.scss'],
})
export class MergeTagsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	mergeTagsList: MergeTagState[] = [];
	mergeTags$ = this.mtService.mergeTags$;

	constructor(private mtService: MergeTagsCrtSettingsService) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.mergeTags$
			.pipe(
				map((data) => MergeTagsMapper.mapMergeTags(data)),
				map((data) =>
					data?.map((i) =>
						R.either(R.isNil, R.isEmpty)(i?.value)
							? { ...i, value: i.secondaryValue }
							: i
					)
				),
				map((x) => SoaSettingsMapper.mapSoaMtWordingPreviewValues(x)),
				map((x) => MergeTagsMapper.sortMergeTags(x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe((data: MergeTagState[]) => {
				this.mergeTagsList = data;
			});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
