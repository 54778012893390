import { StoreConfig, EntityStore } from '@datorama/akita';
import produce from 'immer';

import {
  CustomerDocument
} from './document.model';
import { Injectable } from "@angular/core";

export interface DocumentState {
  adDocuments: CustomerDocument[];
  odDocuments: CustomerDocument[];
  fapDocuments: CustomerDocument[];
  xdDocuments: CustomerDocument[];
  documents: CustomerDocument[];

  isSearching: boolean;
}

@Injectable()
@StoreConfig({ name: 'documentPage', idKey: 'DocumentId' })
export class DocumentStore extends EntityStore<DocumentState, CustomerDocument> {
  constructor() {
    super({
      adDocuments: null,
      odDocuments: null,
      fapDocuments: null,
      xdDocuments: null,
      documents: null,
      isSearching: false
    });
  }

  setDocuments(docs: CustomerDocument[], docType: string) {
    if (docType === 'AXD') {
      this.update(produce(draft => {
        draft.adDocuments = docs;
      }));
    }
    if (docType === 'OD') {
      this.update(produce(draft => {
        draft.odDocuments = docs;
      }));
    }
    if (docType === 'FAP') {
      this.update(produce(draft => {
        draft.fapDocuments = docs;
      }));
    }
    if (docType === 'XD') {
      this.update(produce(draft => {
        draft.xdDocuments = docs;
      }));
    }
  }

  setIsSearching(isSearching: boolean) {
    this.update(produce(x => { x.isSearching = isSearching; }));
  }

}
