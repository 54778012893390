import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { Observable, Observer, of, ReplaySubject, Subject } from 'rxjs';
import {
	finalize,
	map,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { KiwiSaverMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/kiwiSaver/kiwiSaver.mapper';
import { KiwisaverState } from 'src/app/shared/models/services/kiwisaver/kiwisaver.model';
import { ViewDisplayValue } from '../../../../../../../shared/models/_general/display-value.viewmodel';
import { KiwiSaver } from '../../../../crt-mortgage/client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.model';
import { MOATAssetsLiabilitiesModalComponent } from './../../../../../../../shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { objectUtil } from './../../../../../../../util/util';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Component({
	selector: 'app-crt-kiwisaver-template-form',
	templateUrl: './crt-kiwisaver-template-form.component.html',
	styleUrls: ['./crt-kiwisaver-template-form.component.scss'],
})
export class CrtKiwisaverTemplateFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() formId: number;
	@Input() kiwiSaver: KiwiSaver;
	@Input() lifeAssuredList$: Observable<ViewDisplayValue[]>;
	@Input() lifeAssuredList: ViewDisplayValue[];
	@Input() KP$: Observable<ViewDisplayValue[]>;
	@Input() KFT$: Observable<ViewDisplayValue[]>;
	@Input() KFT: ViewDisplayValue[];
	@Input() APCRTRP$: Observable<ViewDisplayValue[]>;
	@Input() crmKiwiSavers: KiwisaverState[] = [];
	@Input() crmKiwiSaversChoinces: ViewDisplayValue[] = [];
	@Input() crtKiwiSavers: KiwiSaver[];
	@Input() transferedSCIList: any[] = [];
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	@Input() addMode: boolean;
	@Input()
	set isSaving(value: boolean) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	// tslint:disable-next-line: variable-name
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	// tslint:disable-next-line: variable-name
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<boolean>();

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;
	isMoatV2Enabled: Observable<boolean>;
	isMoatV2: boolean;

	ownerTooltipDisplay: string;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		this.buildForm();
		this.isMoatV2Enabled = this.businessConfigQuery.moatv2Enabled$;
	}

	ngOnInit(): void {

		this.isMoatV2Enabled.pipe(
			take(1)
		).subscribe((enabled)=> {
			if(enabled) {
				this.prepareDataV2();
				this.isMoatV2 = true;
			} else {
				this.prepareData();
				this.isMoatV2 = false;
			}
		});

		// this.prepareData();

		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));


		this.form.get('kiwiSaverfundOwner')?.valueChanges.subscribe((value) => {
			const ownerChoice = this.crmKiwiSaversChoinces.find((x) => x.value === value);
			const ownerDisplay = ownerChoice ? ownerChoice.display : '';
			this.ownerTooltipDisplay = ownerDisplay;
		});

	}

	prepareData() {
		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);
		const mappedOwners = (this?.kiwiSaver?.owner as number[])?.map((owner) =>
			owner.toString()
		);

		this.form.reset({
			owner: mappedOwners || null,
			fundType: this?.kiwiSaver?.fundType || [],
			provider: this?.kiwiSaver?.provider || null,
			currentBalance: this?.kiwiSaver?.currentBalance || 0,
			contributionRate: this?.kiwiSaver?.contributionRate || 0,
			customerServiceID: this?.kiwiSaver?.customerServiceID || null,
			kiwiSaverfundOwner: null,
		});
	}


	prepareDataV2() {
		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);

		const mappedOwners = (this?.kiwiSaver?.owner as number[])?.map((owner) =>
			owner.toString()
		);

		if(mappedOwners !==undefined) {
			this.form.reset({
				owner: mappedOwners || null,
				fundType: this?.kiwiSaver?.fundType || [],
				provider: this?.kiwiSaver?.provider || null,
				currentBalance: this?.kiwiSaver?.currentBalance || 0,
				contributionRate: this?.kiwiSaver?.contributionRate || 0,
				customerServiceID: this?.kiwiSaver?.customerServiceID || null,
				kiwiSaverfundOwner: null,
			});
			this.form.markAsUntouched();
		} else {
			this.getUnsavedKiwiInfo(this.crmKiwiSaversChoinces[0]?.value);
		}
	}

	getUnsavedKiwiInfo(kiwiId): void {
		const kiwiChoicesList = this.crmKiwiSaversChoinces?.length;
		if(kiwiChoicesList === 0) {
			this.onSelectKiwiSaver('new');
			this.cancelEvent.emit(true);
		} else {
			const d: KiwisaverState = this.crmKiwiSavers?.find((ks) => ks.customerServiceID === +kiwiId);
			//prefill the row
			this.form.reset({
				owner: d?.fundOwner || null,
				fundType: d?.fundType || [],
				provider: d?.provider || null,
				currentBalance: d?.fUM || 0,
				contributionRate: d?.contribution || null,
				customerServiceID: d?.customerServiceID || null,
				kiwiSaverfundOwner: kiwiId,
			});
			this.form.get('fundType')?.disable();
			this.form.get('provider')?.disable();
			this.form.get('currentBalance')?.disable();
			this.form.markAsUntouched();
		}
	}

	trackByFn(index: number, ks): number {
		return ks.value;
	}

	buildForm() {
		this.form = this.fb.group({
			owner: this.fb.control(null, [Validators.required]),
			fundType: this.fb.control([]),
			provider: this.fb.control(null, [Validators.required]),
			currentBalance: this.fb.control(0, [Validators.required]),
			contributionRate: this.fb.control(null),

			customerServiceID: this.fb.control(null),
			kiwiSaverfundOwner: this.fb.control(null),
		});
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.lifeAssuredList);
	}

	prepareFormValue() {
		const form = this.form.getRawValue();
		return {
			...form,
		};
	}

	validateForm() {
		// Your validation here
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	save() {
		const data = this.prepareFormValue();
		this.toggleSaving(true);

		this?.addFn$(
			KiwiSaverMapper.mapToKiwiSaverDetails(data, this.adviceProcessId)
		)
			.pipe(
				take(1),
				finalize(() => {
					this.saveEvent.emit(data);
					this.toggleSaving(false);
					this.form.reset();
				})
			)
			.subscribe();
	}

	delete() {
		this.toggleSaving(true);
		this.deleteFn$(this.kiwiSaver.cRTId)
			.pipe(
				finalize(() => {
					if (
						+this.kiwiSaver.owner &&
						this.checkSCIByCustomerId(+this.kiwiSaver.owner)
					) {
						this.invalidSciWarning();
					}
					this.toggleSaving(false);
				})
			)
			.subscribe();
	}

	deleteKiwiSaver() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Delete KiwiSaver',
			message: logMessage.oat.shared.factFind.delete,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'OK',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	edit() {
		this.toggleEdit(true);
	}

	cancel() {
		if (!!this.addMode) {
			this.cancelEvent.emit(true);
		} else {
			this.toggleEdit(false);
		}
		this.prepareData();
	}

	onSelectKiwiSaver(kiwiSaver?: string) {
		if (kiwiSaver === 'new') {
			const decline$ = new Observable((obs: Observer<any>) => {
				this.cancel();
				obs.complete();
			});

			const lifeAssuredList$ = of(this.crtKiwiSavers).pipe(
				withLatestFrom(this.lifeAssuredList$),
				map(([crtKiwiSavers, lifeassured]) =>
					(lifeassured || [])?.filter(
						(x) =>
							![...(crtKiwiSavers || [])]
								.reduce((a, c) => [...a, ...c.owner], [])
								.includes(+x.value)
					)
				)
			);

			const saveFn$ = (data) =>
				this.addFn$(
					KiwiSaverMapper.mapToKiwiSaverDetails(
						{
							...data,
							fundType: data?.fundType?.filter((f) =>
								this.KFT?.some((kft) => kft.value === f)
							),
						},
						this.adviceProcessId
					)
				).pipe(
					finalize(() => {
						if (
							+this.kiwiSaver.owner &&
							this.checkSCIByCustomerId(+this.kiwiSaver.owner)
						) {
							this.invalidSciWarning();
						}
						this.saveEvent.emit(data);
					})
				);

			const initState = {
				header: 'KiwiSaver Details',
				message: `KiwiSaver`,
				lifeAssuredList$,
				kp$: this.KP$,
				kft$: this.KFT$,
				apcrtrp$: this.APCRTRP$,
				saveFn$,
				decline$,
				getOwnerChoices: this.getOwnerChoices,
			};
			this.bsModalRef = this.modalService.show(
				MOATAssetsLiabilitiesModalComponent,
				{
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				}
			);
		} else {
			const d: KiwisaverState = this.crmKiwiSavers?.find(
				(ks) => ks.customerServiceID === +kiwiSaver
			);
			this.form.reset({
				owner: d?.fundOwner || null,
				fundType: d?.fundType || [],
				provider: d?.provider || null,
				currentBalance: d?.fUM || 0,
				contributionRate: d?.contribution || null,
				customerServiceID: d?.customerServiceID || null,
				kiwiSaverfundOwner: kiwiSaver,
			});
			//this.form.disable();
		}
	}

	editKiwiSaver() {
		const kiwiSaverData = this.kiwiSaver;
		const decline$ = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const lifeAssuredList$ = of(this.crtKiwiSavers).pipe(
			withLatestFrom(this.lifeAssuredList$),
			map(([crtKiwiSavers, lifeassured]) =>
				lifeassured?.filter(
					(x) =>
						![...(crtKiwiSavers || [])]
							.reduce((a, c) => [...a, ...c.owner], [])
							.includes(+x.value) ||
						[...(kiwiSaverData.owner || [])].includes(+x.value)
				)
			)
		);

		const saveFn$ = (data: KiwisaverState) =>
			this.updateFn$(
				KiwiSaverMapper.mapToKiwiSaverDetails(data, this.adviceProcessId)
			).pipe(
				tap((x) => {
					this.kiwiSaver = x;
					this.prepareData();
				}),
				finalize(() => {
					this.saveEvent.emit(data);
				})
			);

		const initState = {
			header: 'KiwiSaver Details',
			message: `KiwiSaver`,
			lifeAssuredList$,
			kp$: this.KP$,
			kft$: this.KFT$,
			apcrtrp$: this.APCRTRP$,
			crtInfo: objectUtil.mapCamelCaseToPascalCase(this.kiwiSaver),
			saveFn$,
			decline$,
			getOwnerChoices: this.getOwnerChoices,
		};
		this.bsModalRef = this.modalService.show(
			MOATAssetsLiabilitiesModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}

	checkSCIByCustomerId(id: number) {
		// Check if SCI, based on customerId
		const sci = this.transferedSCIList?.filter((x) => +x?.customerId === +id);
		return R.complement(R.either(R.isNil, R.isEmpty))(sci);
	}

	invalidSciWarning() {
		this.loggerService.Warning({}, logMessage.oat.shared.warning.invalidSci);
	}

	approveConfirmation() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			const data = {
				...this.kiwiSaver,
				...this.prepareFormValue(),
				pendingAdviserApproval: false,
			};
			this.updateFn$(
				KiwiSaverMapper.mapToKiwiSaverDetails(data, this.adviceProcessId)
			)
				.pipe(
					tap((x) => {
						this.kiwiSaver = x;
						this.prepareData();
					}),
					finalize(() => {
						this.saveEvent.emit(data);
					})
				)
				.subscribe();
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Delete KiwiSaver',
			message:
				logMessage.oat.shared.factFind.assetsLiabilities.kiwiSaver
					.deleteApproval,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
			confirmButton: 'Remove',
			confirmButton2: 'Cancel',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
