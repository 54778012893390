import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KiwiSaverSettingsComponent } from './kiwisaver-settings.component';
import { KiwiSaverSettingsResolver } from './kiwisaver-settings.resolver';

const routes: Routes = [
  {
    path: '',
    component: KiwiSaverSettingsComponent,
    resolve: { data: KiwiSaverSettingsResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KiwiSaverSettingsRoutingModule {}
