import { numUtil } from '../../../../../util/util';
export type Frequency =
  | 'Annually'
  | '6-Monthly'
  | 'Quarterly'
  | 'Monthly'
  | 'Fortnightly'
  | 'Weekly'
  | 'Daily'
  | 'Hourly';

const monthlyFrequency = {
  Annually: 12,
  '6-Monthly': 6,
  Quarterly: 3,
  Monthly: 1,
  Fortnightly: 2,
  Weekly: 4,
  Daily: 30,
  Hourly: 720,
};

const monthlyComputation = (amount, freq) => {
  switch (freq) {
    case 'Fortnightly':
    case 'Weekly':
    case 'Daily':
    case 'Hourly':
      return amount * monthlyFrequency[freq];
    case 'Annually':
    case '6-Monthly':
    case 'Quarterly':
    case 'Monthly':
      return amount / monthlyFrequency[freq];
  }
};

export const convertToMonthly = (
  amount: number,
  frequency: Frequency
): number =>
  !!amount && numUtil.isNumber(amount) && !!frequency
    ? monthlyComputation(amount, frequency)
    : 0;
