import { Injectable } from '@angular/core';
import { LiabilityState } from 'src/app/shared/models/services/assets/assets';
import { Observable, iif, of } from 'rxjs';
import {
	tap,
	withLatestFrom,
	switchMap,
	finalize,
	take,
	mergeMap,
	catchError,
	map,
} from 'rxjs/operators';

import {
	ApiService,
	JsonResultStatus,
} from '../../../../../core/base/api.service';

import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { UserQuery } from '../../../../../domain/user/user.query';
import { BusinessProfileQuery } from '../business-profile.query';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BusinessProfileStore } from '../business-profile.store';
import { applyTransaction, arrayAdd } from '@datorama/akita';
import { PropertyAssetState } from '../../../../../shared/models/services/property-asset/property-asset-group.model';
import { PropertyAssetCustomerServiceState } from '../../../../../shared/models/services/property-asset/property-asset';
import { BusinessProfileUtilsService } from '../business-profile.utils.service';
import produce from 'immer';
import { BusinessService } from '../../../../../core/business/business.service';
import { BusinessProfileService } from '../business-profile.service';
import {
	assetServiceUtil,
	propAndAssetServiceUtil,
} from '../../../../../shared/services/service-utils/property-assets.util';
import { BusinessConfigQuery } from '../../../../../domain/business-config/business-config.query';
import MomentUtil from '../../../../../util/moment.util';
import { NoteState } from '../../../../../shared/models/activity-timeline/activity-timeline.model';
import * as R from 'ramda';
import {
	AssetCustomerServiceState,
	AssetState,
	LiabilityCustomerServiceState,
} from 'src/app/shared/models/services/assets/assets';
import { NoteTypes } from 'src/app/shared/models/_general/note';

@Injectable()
export class PropertyAssetService extends BusinessProfileUtilsService {
	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected blStaffsQuery: BLStaffsQuery,
		protected store: BusinessProfileStore,
		protected query: BusinessProfileQuery,
		protected api: ApiService,
		protected businessService: BusinessService,
		protected userQuery: UserQuery,
		protected profileService: BusinessProfileService,
		protected businessConfigQuery: BusinessConfigQuery
	) {
		super(
			store,
			query,
			dropdownValueQuery,
			blStaffsQuery,
			businessConfigQuery,
			userQuery
		);
	}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	createSecurity(req: PropertyAssetCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			mergeMap((x) =>
				this.businessService.AddSecurity({
					...x,
					serviceCode: 'S',
					customerServiceID: 0,
				})
			),
			tap((x) => (req.customerServiceID = +x)),
			switchMap((status) =>
				req.note
					? this.businessService.AddNote({
							CustomerID: req.customerID,
							CustomerServiceID: req.customerServiceID,
							Notes: req.note,
							ActivityType: 'S',
							StaffName: `${this.userQuery.getValue().FirstName} ${
								this.userQuery.getValue().LastName
							}`,
					  })
					: of(status)
			),
			tap((x) => {
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							'S',
							req.customerID,
							req.customerServiceID
						);
					}
					const secState = this.query.getValue().propertyAsset;
					const state = produce<PropertyAssetState>(secState, (draft) => {
						draft.customerServices.push({
							...req,
							note: '',
							notes: req.note
								? [
										{
											notesID: +x,
											customerServiceID: req.customerServiceID,
											notes: req.note,
											isActive: true,
											createDateTime: MomentUtil.formatToServerDatetime(
												MomentUtil.createMomentNz()
											),
											createdByStaffId: this.userQuery.getValue().StaffID,
											customerID: req.customerID,
											staffName: `${this.userQuery.getValue().FirstName} ${
												this.userQuery.getValue().LastName
											}`,
											staffLevel: 0,
											dueDateTime: null,
											activityType: null,
											activityName: null,
											assignedToAdviser: null,
										},
								  ]
								: [],
						});

						draft.totalValue = +draft.totalValue + +req.gRM1Value;
					});

					this.store.setPropertyAsset(propAndAssetServiceUtil(state));
				});
			}),
			finalize(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	updateSecurity(req: PropertyAssetCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			withLatestFrom(this.PropertyOwners$),
			map(([x, ac]) => {
				return {
					...x,
					propertyOwner: !!x.propertyOwner
						? JSON.stringify(
								JSON.parse(x.propertyOwner)?.filter((y) =>
									ac?.find((c) => c.value === y)
								)
						  )
						: x.propertyOwner,
				};
			}),
			mergeMap((x) =>
				this.businessService.UpdateSecurity({ ...x, serviceCode: 'S' })
			),
			switchMap((status) =>
				req.note
					? this.businessService.AddNote({
							CustomerID: req.customerID,
							CustomerServiceID: req.customerServiceID,
							Notes: req.note,
							ActivityType: 'S',
							StaffName: `${this.userQuery.getValue().FirstName} ${
								this.userQuery.getValue().LastName
							}`,
					  })
					: of(status)
			),
			tap((x) => {
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							'S',
							req.customerID,
							req.customerServiceID
						);
					}
					const secState = this.query.getValue().propertyAsset;
					const state = produce<PropertyAssetState>(secState, (draft) => {
						let oldValue = 0;
						draft.customerServices?.forEach((pa, i) => {
							if (pa.customerServiceID === req.customerServiceID) {
								oldValue = pa.gRM1Value;
								draft.customerServices[i] = req;

								if (req.note) {
									draft.customerServices[i].notes?.unshift({
										notesID: +x,
										customerServiceID: req.customerServiceID,
										notes: req.note,
										isActive: true,
										createDateTime: MomentUtil.formatToServerDatetime(
											MomentUtil.createMomentNz()
										),
										createdByStaffId: this.userQuery.getValue().StaffID,
										customerID: req.customerID,
										staffName: `${this.userQuery.getValue().FirstName} ${
											this.userQuery.getValue().LastName
										}`,
										staffLevel: 0,
										dueDateTime: null,
										activityType: null,
										activityName: null,
										assignedToAdviser: null,
									});
								} else {
									draft.customerServices[i].notes = pa.notes;
								}
								draft.customerServices[i].note = '';
							}
						});

						draft.totalValue = +draft.totalValue - +oldValue;
						draft.totalValue = +draft.totalValue + +req.gRM1Value;
					});

					this.store.setPropertyAsset(propAndAssetServiceUtil(state));
				});
			}),
			finalize(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	deleteSecurity(req: PropertyAssetCustomerServiceState) {
		return of(req).pipe(
			mergeMap((x) =>
				this.businessService.DeactivateService(+x.customerServiceID)
			),
			tap((x) => {
				applyTransaction(() => {
					if (x) {
						const secState = this.query.getValue().propertyAsset;

						const state = produce<PropertyAssetState>(secState, (draft) => {
							draft.customerServices = draft.customerServices?.filter(
								(cs) => cs.customerServiceID !== req.customerServiceID
							);
							draft.totalValue = +draft.totalValue - +req.gRM1Value;
						});

						this.store.setPropertyAsset(propAndAssetServiceUtil(state));
					}
				});
			}),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline?.notes?.filter(
						(n) => n.customerServiceID !== req.customerServiceID
					);
					this.store.setActivityTimeline({
						activities: activityTimeline?.activities,
						notes: notesState,
					});
				})
			),
			catchError(() => of('')),
			finalize(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	archiveSecurity(req: PropertyAssetCustomerServiceState, isArchive: boolean) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			withLatestFrom(this.PropertyOwners$),
			map(([x, ac]) => {
				return {
					...x,
					propertyOwner: !!x.propertyOwner
						? JSON.stringify(
								JSON.parse(x.propertyOwner)?.filter((y) =>
									ac?.find((c) => c.value === y)
								)
						  )
						: x.propertyOwner,
				};
			}),
			mergeMap((data) =>
				this.businessService.UpdateSecurity({
					...data,
					isActive: isArchive ? 2 : 1,
					serviceCode: 'S',
				})
			),
			tap((x) => {
				applyTransaction(() => {
					if (x) {
						const secState = this.query.getValue().propertyAsset;

						const state = produce<PropertyAssetState>(secState, (draft) => {
							draft.customerServices?.forEach((sec) => {
								if (sec.customerServiceID === newReq.customerServiceID) {
									sec.isActive = isArchive ? 2 : 1;
									sec.notes = sec.notes;
								}
							});
						});

						this.store.setPropertyAsset(propAndAssetServiceUtil(state));
					}
				});
			}),
			catchError(() => of(''))
		);
	}

	deleteNote(id: number, data) {
		return of(data).pipe(
			mergeMap(() => this.profileService.deleteActivityNote(id)),
			tap((x) => {
				applyTransaction(() => {
					if (x) {
						const secState = this.query.getValue().propertyAsset;

						const state = produce<PropertyAssetState>(secState, (draft) => {
							draft.customerServices = draft.customerServices?.map((m) =>
								m.customerServiceID === data.customerServiceID
									? {
											...data,
											notes: m.notes?.filter((n) => n.notesID !== id),
									  }
									: m
							);
						});

						this.store.setPropertyAsset(propAndAssetServiceUtil(state));
					}
				});
			}),
			catchError(() => of(''))
		);
	}

	syncNote(notes: NoteState) {
		return of(notes).pipe(
			withLatestFrom(this.query.propertyAsset$),
			tap(([note, secState]) =>
				applyTransaction(() => {
					const state = produce(secState, (draft) => {
						draft.customerServices = draft.customerServices?.map((sec) =>
							sec.customerServiceID === note.customerServiceID
								? {
										...sec,
										notes: sec.notes?.filter((n) => note.notesID !== n.notesID),
								  }
								: sec
						);
					});
					this.store.setPropertyAsset(propAndAssetServiceUtil(state));
				})
			)
		);
	}

	syncLiabilityNote(notes: NoteState) {
		return of(notes).pipe(
			withLatestFrom(this.query.liability$),
			tap(([note, secState]) => {
				applyTransaction(() => {
					const state = produce(secState, (draft) => ({
						...draft,
						notes: [
							...secState.notes?.filter((n) => note.notesID !== n.notesID),
						],
					}));
					this.store.setLiability(state);
				});
			})
		);
	}

	addNote(req: PropertyAssetCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			switchMap(() =>
				this.businessService.AddNote({
					CustomerID: req.customerID,
					CustomerServiceID: req.customerServiceID,
					Notes: req.note,
					ActivityType: 'S',
					StaffName: `${this.userQuery.getValue().FirstName} ${
						this.userQuery.getValue().LastName
					}`,
				})
			),
			tap((x) => {
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							'S',
							req.customerID,
							req.customerServiceID
						);
					}
					const secState = this.query.getValue().propertyAsset;
					const state = produce<PropertyAssetState>(secState, (draft) => {
						draft.customerServices?.forEach((pa, i) => {
							if (pa.customerServiceID === req.customerServiceID) {
								draft.customerServices[i] = req;

								if (req.note) {
									draft.customerServices[i].notes?.unshift({
										notesID: +x,
										customerServiceID: req.customerServiceID,
										notes: req.note,
										isActive: true,
										createDateTime: MomentUtil.formatToServerDatetime(
											MomentUtil.createMomentNz()
										),
										createdByStaffId: this.userQuery.getValue().StaffID,
										customerID: req.customerID,
										staffName: `${this.userQuery.getValue().FirstName} ${
											this.userQuery.getValue().LastName
										}`,
										staffLevel: 0,
										dueDateTime: null,
										activityType: null,
										activityName: null,
										assignedToAdviser: null,
									});
								} else {
									draft.customerServices[i].notes = pa.notes;
								}
								draft.customerServices[i].note = '';
							}
						});
					});

					this.store.setPropertyAsset(propAndAssetServiceUtil(state));
				});
			}),
			tap(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	createAsset(req: AssetCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			mergeMap((x) =>
				this.businessService.AddAsset({
					...x,
					serviceCode: 'A',
					customerServiceID: 0,
				})
			),
			tap((x) => (req.customerServiceID = +x)),
			tap((x) => {
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							'A',
							req.customerID,
							req.customerServiceID
						);
					}
					const secState = this.query.getValue().asset;
					const state = produce<AssetState>(secState, (draft) => {
						draft.customerServices.push({
							...req,
						});
						draft.totalValue = +draft.totalValue + req.value;
					});

					this.store.setAsset(assetServiceUtil(state));
				});
			}),
			tap(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	updateAsset(req: AssetCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			mergeMap((x) =>
				this.businessService.UpdateAsset({ ...x, serviceCode: 'A' })
			),
			tap((x) => (req.customerServiceID = +x)),
			tap((x) => {
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							'A',
							req.customerID,
							req.customerServiceID
						);
					}
					const secState = this.query.getValue().asset;
					const state = produce<AssetState>(secState, (draft) => {
						const indexToUpdate = draft.customerServices?.findIndex(
							(value) => value.customerServiceID === req.customerServiceID
						);
						if (indexToUpdate > -1) {
							draft.customerServices[indexToUpdate] = { ...req };
						}
					});

					this.store.setAsset(assetServiceUtil(state));
				});
			}),
			tap(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	deleteAsset(req: AssetCustomerServiceState) {
		return of(req).pipe(
			mergeMap((x) =>
				this.businessService.DeactivateService(+x.customerServiceID)
			),
			mergeMap((x) =>
				iif(
					() => {
						const assets: AssetCustomerServiceState[] =
							this.query.getValue().asset.customerServices;
						const currentAssets: AssetCustomerServiceState[] = assets?.filter(
							(value: AssetCustomerServiceState) =>
								!R.hasIn('status', value) ||
								value?.status === null ||
								value.status === 'Current'
						);
						return currentAssets.length === 1;
					},
					this.deleteNotesByType(NoteTypes.Asset?.toString(), req.customerID),
					of(x)
				)
			),
			tap((x) => {
				applyTransaction(() => {
					if (x) {
						const secState = this.query.getValue().asset;

						const state = produce<AssetState>(secState, (draft) => {
							draft.customerServices = draft.customerServices?.filter(
								(cs) => cs.customerServiceID !== req.customerServiceID
							);
						});

						this.store.setAsset(assetServiceUtil(state));
					}
				});
			}),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline.notes?.filter(
						(n) => n.customerServiceID !== req.customerServiceID
					);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				})
			),
			tap(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			),
			catchError(() => of(''))
		);
	}

	soldAsset(req: AssetCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			mergeMap((x) =>
				this.businessService.UpdateAsset({ ...x, serviceCode: 'A' })
			),
			mergeMap((x) =>
				iif(
					() => {
						const assets: AssetCustomerServiceState[] =
							this.query.getValue().asset.customerServices;
						const currentAssets: AssetCustomerServiceState[] = assets?.filter(
							(value: AssetCustomerServiceState) =>
								!R.hasIn('status', value) ||
								value?.status === null ||
								value.status === 'Current'
						);
						return currentAssets.length === 1;
					},
					this.deleteNotesByType(
						NoteTypes.Asset?.toString(),
						req.customerID
					).pipe(map((y) => x)),
					of(x)
				)
			),
			tap((x) => (req.customerServiceID = +x)),
			tap((x) => {
				applyTransaction(() => {
					const secState = this.query.getValue().asset;
					const state = produce<AssetState>(secState, (draft) => {
						const indexToUpdate = draft.customerServices?.findIndex(
							(value) => value.customerServiceID === req.customerServiceID
						);
						if (indexToUpdate > -1) {
							draft.customerServices[indexToUpdate] = {
								...req,
								status: req.status,
							};
						}
					});

					this.store.setAsset(assetServiceUtil(state));
				});
			}),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline.notes?.filter(
						(n) => n.activityType !== NoteTypes.Asset
					);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				})
			),
			tap(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	addAssetNote(req: AssetCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			switchMap(() =>
				this.businessService.AddNote({
					CustomerID: req.customerID,
					CustomerServiceID: 0,
					Notes: req.note,
					IsActivity: true,
					Type: 'A',
					StaffName: `${this.userQuery.getValue().FirstName} ${
						this.userQuery.getValue().LastName
					}`,
				})
			),
			tap((x) => {
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							'A',
							req.customerID,
							req.customerServiceID
						);
					}
					const secState = this.query.getValue().asset;
					const state = produce<AssetState>(secState, (draft) => {
						if (req.note) {
							if (draft.notes?.length) {
								draft.notes?.unshift({
									notesID: +x,
									customerServiceID: 0,
									notes: req.note,
									isActive: true,
									createDateTime: MomentUtil.formatToServerDatetime(
										MomentUtil.createMomentNz()
									),
									createdByStaffId: this.userQuery.getValue().StaffID,
									customerID: req.customerID,
									staffName: `${this.userQuery.getValue().FirstName} ${
										this.userQuery.getValue().LastName
									}`,
									staffLevel: 0,
									dueDateTime: null,
									activityType: null,
									activityName: null,
									assignedToAdviser: null,
								});
							} else {
								draft.notes.push({
									notesID: +x,
									customerServiceID: 0,
									notes: req.note,
									isActive: true,
									createDateTime: MomentUtil.formatToServerDatetime(
										MomentUtil.createMomentNz()
									),
									createdByStaffId: this.userQuery.getValue().StaffID,
									customerID: req.customerID,
									staffName: `${this.userQuery.getValue().FirstName} ${
										this.userQuery.getValue().LastName
									}`,
									staffLevel: 0,
									dueDateTime: null,
									activityType: null,
									activityName: null,
									assignedToAdviser: null,
								});
							}
						} else {
							draft.notes = secState.notes;
						}
					});
					this.store.setAsset(state);
				});
			}),
			tap(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	deleteAssetNote(id: number, data) {
		return of(data).pipe(
			mergeMap(() => this.profileService.deleteActivityNote(id)),
			tap((x) => {
				applyTransaction(() => {
					if (x) {
						const secState = this.query.getValue().asset;

						const state = produce<AssetState>(secState, (draft) => {
							draft.notes = [...draft.notes?.filter((n) => n.notesID !== id)];
						});

						this.store.setAsset(state);
					}
				});
			}),
			catchError(() => of(''))
		);
	}

	addLiabilityNote(req: LiabilityCustomerServiceState) {
		const newReq = Object.assign({}, R.omit(['note', 'notes'], req));
		return of(newReq).pipe(
			switchMap(() =>
				this.businessService.AddNote({
					CustomerID: req.customerID,
					CustomerServiceID: 0,
					Notes: req.note,
					IsActivity: true,
					Type: NoteTypes.Liability,
					StaffName: `${this.userQuery.getValue().FirstName} ${
						this.userQuery.getValue().LastName
					}`,
				})
			),
			tap((x) => {
				applyTransaction(() => {
					if (x && req.note) {
						this.profileService.addToActivityTimelineNotes(
							+x,
							req.note,
							NoteTypes.Liability,
							req.customerID,
							req.customerServiceID
						);
					}
					const secState = this.query.getValue().liability;
					const state = produce<LiabilityState>(secState, (draft) => {
						if (req.note) {
							if (draft.notes?.length) {
								draft.notes?.unshift({
									notesID: +x,
									customerServiceID: 0,
									notes: req.note,
									isActive: true,
									createDateTime: MomentUtil.formatToServerDatetime(
										MomentUtil.createMomentNz()
									),
									createdByStaffId: this.userQuery.getValue().StaffID,
									customerID: req.customerID,
									staffName: `${this.userQuery.getValue().FirstName} ${
										this.userQuery.getValue().LastName
									}`,
									staffLevel: 0,
									dueDateTime: null,
									activityType: null,
									activityName: null,
									assignedToAdviser: null,
								});
							} else {
								draft.notes.push({
									notesID: +x,
									customerServiceID: 0,
									notes: req.note,
									isActive: true,
									createDateTime: MomentUtil.formatToServerDatetime(
										MomentUtil.createMomentNz()
									),
									createdByStaffId: this.userQuery.getValue().StaffID,
									customerID: req.customerID,
									staffName: `${this.userQuery.getValue().FirstName} ${
										this.userQuery.getValue().LastName
									}`,
									staffLevel: 0,
									dueDateTime: null,
									activityType: null,
									activityName: null,
									assignedToAdviser: null,
								});
							}
						} else {
							draft.notes = secState.notes;
						}
					});
					this.store.setLiability(state);
				});
			}),
			tap(() =>
				this.profileService
					.getClientHistories(req.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	deleteLiabilityNote(id: number, data: any) {
		return of(data).pipe(
			mergeMap(() => this.profileService.deleteActivityNote(id)),
			tap((x) => {
				applyTransaction(() => {
					if (x) {
						const secState = this.query.getValue().liability;
						const state = produce<LiabilityState>(secState, (draft) => {
							draft.notes = [...draft.notes?.filter((n) => n.notesID !== id)];
						});
						this.store.setLiability(state);
					}
				});
			}),
			catchError(() => of(''))
		);
	}

	deleteNotesByType(type: string, customerId: number) {
		return of(type).pipe(
			mergeMap(() =>
				this.profileService.deleteAllNotesByType(type, customerId)
			),
			tap((x: string | JsonResultStatus) => {
				applyTransaction(() => {
					if (x) {
						if (type === NoteTypes.Asset) {
							const secState = this.query.getValue().asset;

							const state = produce<AssetState>(secState, (draft) => {
								draft.notes = [];
							});

							this.store.setAsset(state);
						}
					}
				});
			})
		);
	}

	addLiability(liability: LiabilityCustomerServiceState): Observable<boolean> {
		return this.businessService.AddLiability(liability).pipe(
			tap((customerServiceId) => {
				liability.customerServiceID = customerServiceId;
				this.store.update((state) => {
					// state.liability.customerServices;
					return {
						...state,
						...{
							liability: {
								...state.liability,
								customerServices: arrayAdd(
									state?.liability?.customerServices,
									liability
								),
							},
						},
					};
				});
			}),
			tap(() =>
				this.profileService
					.getClientHistories(liability.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	updateLiability(
		liability: LiabilityCustomerServiceState
	): Observable<boolean> {
		return this.businessService.UpdateLiability(liability).pipe(
			tap((customerServiceID) => {
				this.store.update((state) => {
					state.liability.customerServices;
					return {
						...state,
						...{
							liability: {
								...state.liability,
								customerServices: state?.liability?.customerServices.map((l) =>
									l.customerServiceID === customerServiceID ? liability : l
								),
							},
						},
					};
				});
			}),
			tap(() =>
				this.profileService
					.getClientHistories(liability.customerID)
					.pipe(take(1))
					.subscribe()
			)
		);
	}

	deleteLiability(liability: LiabilityCustomerServiceState) {
		return this.businessService
			.DeactivateService(+liability.customerServiceID)
			.pipe(
				tap(() => {
					this.store.update((state) => {
						state.liability.customerServices;
						return {
							...state,
							...{
								liability: {
									...state.liability,
									customerServices: state?.liability?.customerServices.filter(
										(l) => l.customerServiceID !== liability?.customerServiceID
									),
								},
							},
						};
					});
				}),
				tap(() =>
					this.profileService
						.getClientHistories(liability.customerID)
						.pipe(take(1))
						.subscribe()
				)
			);
	}
}
