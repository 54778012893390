import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Row } from './mortgage.model';
import { map, withLatestFrom, distinctUntilChanged, combineLatest, auditTime, shareReplay } from 'rxjs/operators';
import { columns, EnhancedTableColumn, templateRow } from '../mortgage-datatable.config';
import * as R from 'ramda';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { Observable, of } from 'rxjs';
import { MortgageUiQuery } from './mortgage-ui.query';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { MortgageStore, MortgageState } from './mortgage.store';
import { mapToLookupObject } from '../../../../../domain/dropdown-value/dropdown-value.model';
import sort from 'fast-sort';
import { util } from '../../../../../util/util';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Injectable()
export class MortgageQuery extends QueryEntity<MortgageState, Row> {
  private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
    map(x => R.map(y => ViewDisplayValue.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
    map(x => (x ? x?.sort((a, b) => a.display.localeCompare(b.display)) : x))
  ); // Advisers
  private MP$ = this.dropdownQuery.orderedChoices$('MP'); // Provider
  private AT$ = this.dropdownQuery.orderedChoices$('AT'); // Activity Type
  private MS$ = this.dropdownQuery.orderedChoices$('MS'); // Mortgage Status
  private MRS$ = this.dropdownQuery.orderedChoices$('MRS'); // Mortgage Status
  private MT$ = this.dropdownQuery.orderedChoices$('MT'); // Mortgage Type
  private MFPE$ = this.dropdownQuery.orderedChoices$('MFPE'); // Fixed period
  private MRF$ = this.dropdownQuery.orderedChoices$('MRF'); // Repayment Frequency
  private MLT$ = this.dropdownQuery.orderedChoices$('MLT'); // Loan Type
  private PCLE$ = this.dropdownQuery.orderedChoices$('PCLE'); // Lead Origin

  columns$ = this.select(x => x.columns);
  tableColumns$ = this.columns$
    .pipe(
      map(tableColumns => {
        return tableColumns?.map(col => columns?.find(y => y.metakey === col))?.filter(x => x !== undefined);
      }),
      withLatestFrom(this.select(x => x.columnWidths), this.businessConfigQuery.adviserReworkFeature$),
      map(([tableColumns, widths, adviserReworkFeature]) =>
        tableColumns
					?.filter((x: any) => adviserReworkFeature ? (x != 'Adviser') : (x != 'Mortgage Adviser'))
					?.map(column => {
          const widthConf = widths?.filter(x => x)?.find(width => width.metakey === column.metakey);
          const newColumn = { ...column, $$id: column.name };
          if (widthConf) {
            return { ...newColumn, width: widthConf.width };
          } else {
            return newColumn;
          }
        })
      ),
      combineLatest(this.advisers$, this.MP$, this.AT$, this.MS$, this.MRS$),
      auditTime(0),
      combineLatest(this.MT$, this.MFPE$, this.MRF$, this.MLT$, this.PCLE$),
      auditTime(0),
      map(([[tableColumns, advisers, mp, , ms, mrs], mt, mfpe, mrf, mlt, pcle]) => {
        return tableColumns?.map(c => {
          switch (c.metakey) {
            case 'Adviser':
						case 'Mortgage Adviser':
              return this.fillUpChoices(advisers, c);
            case 'Original Adviser':
              return this.fillUpChoices(advisers, c);
            case 'Provider':
              return this.fillUpChoices(mp, c);
            case 'Status':
              return this.fillUpChoices(ms, c);
            case 'Business Refix':
              return this.fillUpChoices(mrs, c);
            case 'Mortgage Type':
              return this.fillUpChoices(mt, c);
            case 'Fixed Period End':
              return this.fillUpChoices(mfpe, c);
            case 'Repayment Frequency':
              return this.fillUpChoices(mrf, c);
            case 'Loan Type':
              return this.fillUpChoices(mlt, c);
            case 'Lead Origin':
              return this.fillUpChoices(pcle, c);
            default:
              return c;
          }
        });
      })
    )
    .pipe(
      shareReplay(1),
      distinctUntilChanged((x, y) => R.equals(x, y))
    );
  hiddenTableColumns$ = this.columns$.pipe(
    map(tableColumns => {
      return columns?.filter(x => !tableColumns?.includes(x.metakey));
    })
  );

  sorts$ = this.uiQuery.select(x => [{ dir: x.sort, prop: x.propSort }]);
  currentSort$ = this.select(x => x.currentSort);
  cellsLoading$ = this.uiQuery.select(state => state.cellLoadingStatus);
  cellsEditing$ = this.uiQuery.select(state => state.cellEditStatus);
  cellsTempvalue$ = this.uiQuery.select(state => state.cellTempValue);
  rowsLoading$ = this.uiQuery.select(state => state.rowLoadingStatus);
  isSearching$ = this.uiQuery.select(state => state.isSearching);
  allAdvisers$ = this.blstaffQuery.allStaffsChoices$;

  templateRow$: Observable<Row> = of(templateRow);

  rows$: Observable<Row[]> = this.selectAll();
  hasRows$: Observable<boolean> = this.selectCount().pipe(map(x => x > 0));

  count$ = this.select(x => x.count);
  totalApi$ = this.select(x => x.totalAPI);
  searchForm$ = this.select(x => x.searchForm).pipe(map(x => x));
  isComplete$ = this.select(x => x.isComplete);

  sortedRows$ = this.uiQuery
    .select(x => [x.propSort, x.sort])
    .pipe(
      distinctUntilChanged((p, q) => R.equals(p, q)),
      combineLatest(this.rows$, this.tableColumns$),
      withLatestFrom(this.isSearching$),
      withLatestFrom(this.allAdvisers$),
      auditTime(0),
      map(([[[[prop, sortDirection], rows, tableColumns], isSearching], allAdvisers]) => {
        if (sortDirection === '' || prop === '' || isSearching) {
          return rows;
        } else {
          const column = tableColumns?.find(x => x.prop === prop);
          if (util.isNullOrEmpty(column)) {
            return rows;
          }
          const decorated = rows?.map<[number, any, number, Row]>(r => {
						const adviserColumn = [
							'Adviser',
							'OriginalAdviser',
							'MortgageAdviser',
						].includes(prop) ? allAdvisers : column.choices;
            const actualValue = column.sortValueGetter(r[prop], adviserColumn, r);
            return [this.spaceSortValueGetter(actualValue), R.defaultTo('', actualValue), r.CustomerID, r];
          });
          return sort(decorated)
            .by([
              { asc: u => u[0] },
              { [sortDirection]: u => (u[1] && isNaN(u[1]) ? u[1]?.toLowerCase() : u[1]) } as any,
              { asc: u => u[2] }
            ])
            .map(x => x[3]);
        }
      })
    );

  getCustomerName = (customerServiceId: number) => this.getEntity(customerServiceId).Name.value;

  getSum(total: number, num: Row) {
    return total + +num.LoanValue.value;
  }

  constructor(
    protected dropdownQuery: DropdownValueQuery,
    protected blstaffQuery: BLStaffsQuery,
    protected mortgageStore: MortgageStore,
    protected businessConfigQuery: BusinessConfigQuery,
    public uiQuery: MortgageUiQuery
  ) {
    super(mortgageStore);
  }
  /** fill up choices and choices as object. */
  private fillUpChoices(choices: ViewDisplayValue[], column: EnhancedTableColumn) {
    return {
      ...column,
      choices,
      choicesAsObject: mapToLookupObject(choices)
    };
  }

  /** Create a value usable as index for sorting.
   * Its only necessary to know if value is empty or not.
   * So if it is not empty, return 1 which is first in sort index.
   * And 2 if empty.
   */
  private spaceSortValueGetter(fieldValue: string | number | null | undefined): 1 | 2 {
    let stringValue: string;
    if (util.isNullOrEmpty(fieldValue)) {
      stringValue = '';
    } else if (typeof fieldValue === 'string') {
      stringValue = fieldValue?.trim();
    } else {
      stringValue = fieldValue?.toString();
    }
    if (stringValue === '') {
      return 2;
    } else {
      return 1;
    }
  }
}
