import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import { FieldMetadata } from '../../dynamic-field/field-metadata.model';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import { ConfigService } from '../../../core/config/config.service';
import { tap, takeUntil, mergeMap } from 'rxjs/operators';
import { NoteService } from '../../../core/note/note.service';
import { CommandRoute, RouteService } from '../../../core/config/route.service';
import { NoteRequest } from '../../models/activity-timeline/activity-timeline.model';
import { NoteTypes } from '../../models/notes/note.model';
declare var $: any;

/** Component for note input.
 * Opens a modal with textarea.
 */
@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent implements OnChanges, OnDestroy {
  private onDestroy$ = new Subject<void>();
  /** element to attach the tooltip */
  @ViewChild('longText', { static: true }) longText: ElementRef;
  /** route to go to */
  @Input() route: CommandRoute;
  /** value to show */
  @Input() value: string;
  /** index number for use in ID */
  @Input() index: any;
  /** field name for use in ID */
  @Input() fieldId: any;
  /** class for overdue color code */
  @Input() cellClass: any;
  /** restrictions */
  @Input() restrict: string[];
  /** input for customer id to relate note to */
  @Input() customerId: number;
  /** input for service id to relate note to */
  @Input() customerServiceId: number;
  /** input for advice process id to relate note to */
  @Input() adviceProcessID: number;
  /** input for activity type to relate note to */
  @Input() activityType: string;
	/** input for isActivity note to appear on activity timeline */
  @Input() isActivity: boolean;
  /** to use on save event for now. maybe get rid later */
  @Input() metadata: FieldMetadata<any>;

  @Input() isEditable: boolean;

  /** event for note saving */
  @Output() updateNoteEvent = new EventEmitter<FieldMetadata<any>>();

  constructor(
    private noteService: NoteService,
    private configService: ConfigService,
    private modalService: BsModalService,
    private routeService: RouteService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) { }

  /** run all changes updater */
  ngOnChanges(changes: SimpleChanges): void {
    this.valueChanges(changes);
  }
  /** remove tooltip */
  ngOnDestroy(): void {
    this.removeTooltip();

    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  /** reAdd tooltip */
  valueChanges(changes: SimpleChanges) {
    const notChanged =
      changes.value === undefined ||
      changes.value.currentValue === changes.value.previousValue;
    if (notChanged) {
      return;
    }

    this.removeTooltip();
    this.addTooltip(changes.value.currentValue);
  }

  /** add tooltip.  */
  private addTooltip(title: string) {
    if (!this.longText) {
      return;
    }
    $(this.longText.nativeElement).tooltip({
      placement: 'right',
      title: () => title,
    });
  }
  /** remove tooltip */
  private removeTooltip() {
    if (!this.longText) {
      return;
    }
    $(this.longText.nativeElement).tooltip('dispose');
  }
  /** show add note modal */
  addNoteModal() {
    new Observable((obs) => {
      const initState: any = {
        header: 'Add Note',
        savefn: this.saveNote,
      };
      this.modalService.show(NoteAddModalComponent, {
        class: 'modal-dialog-centered modal-lg',
        initialState: initState,
        ignoreBackdropClick: true,
      });
      obs.complete();
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }
  /** save note */
  saveNote = (note: string) =>
    new Observable((obs) => {
      obs.next();
      obs.complete();
    }).pipe(
      mergeMap(() => {
        const noteModel =
					this.isActivity
					? {
							customerID: this.customerId,
							customerServiceID: this.customerServiceId || 0,
							notes: note,
							isActivity: true,
					  }
					: !!this.activityType &&
            this.activityType === NoteTypes.AdviceProcess &&
            this.adviceProcessID
            ? {
              ReferenceId: this.adviceProcessID,
              Type: 'AP',
              Notes: note,
            }
            : {
              customerID: this.customerId,
              customerServiceID: this.customerServiceId || 0,
              notes: note,
              activityType: this.activityType || null,
            };
        return !this.isActivity && !!this.activityType &&
          this.activityType === NoteTypes.AdviceProcess &&
          !!this.adviceProcessID
          ? this.noteService.AddAdviceProcessNote(
            noteModel as {
              ReferenceId: number;
              Type: string;
              Notes: string;
            }
          )
          : this.noteService.SaveNote(noteModel as NoteRequest);
      }),
      tap((x) => {
        if (x) {
          this.updateNoteEvent.emit({ ...this.metadata, value: note });
        }
      })
    );

  /** Gets route for link depending on ID and column. */
  // route = (link: string) =>
  //   this.routeService.externalRoute(staticConf.googleMapApi + link);

  /**
   * routeLink click, gets stuck when using ngx-datatable.
   * Somehow solves it.
   */
  onClick() {
    this.ngZone.run(() => this.cd.detectChanges());
  }
}
