import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { ManualLoaderMiniModule } from '@shared/loaders/manual-loader-mini/manual-loader-mini.module';

const components = [FooterComponent];

@NgModule({
	declarations: components,
	exports: components,
	imports: [CommonModule, ManualLoaderMiniModule],
})
export class CrtFooterModule {}
