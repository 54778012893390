import { SettingsTypes } from '../../../state/mortgage-settings.model';
import { NoteSettingsState } from './note-settings.model';

export class NoteSettingsMapper {
  public static mapToUpsert(data) {
    return {
      ...data,
      referenceId: data.referenceId || 0,
      type: SettingsTypes.MOATReviewNotes
    };
  }

  public static mapToView(data: NoteSettingsState) {
    return {
      referenceId: data ? data.referenceId : 0,
      type: data ? data.type : '',
      purpose: data ? data.purpose : '',
      clientBackgroundAndCharacter: data ? data.clientBackgroundAndCharacter : '',
      recommendation: data ? data.recommendation : '',
      otherInformation: data ? data.otherInformation : ''
    };
  }
}

