import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import { UserQualificationModalComponent } from '@shared/modal/user-qualification-modal/user-qualification-modal.component';
import {
	BlStaffSettingsModel,
	BlStaffQualifications,
	BlStaffQualificationsCoreKnowledge,
	BlStaffQualificationsOther,
	BlStaffStandardQualifications,
	StaffQualificationInstitute,
	BlStaffSettingTypes,
} from '@shared/models/_general/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { DocumentTypes } from '@shared/models/documents/document.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';

@Component({
	selector: 'app-user-qualifications',
	templateUrl: './user-qualifications.component.html',
	styleUrls: ['./user-qualifications.component.scss'],
})
export class UserQualificationsComponent implements OnInit {
	@Input() staff: BlStaffViewmodel;
	@Input() staffSettings: BlStaffSettingsModel[];
	@Input() SQCKI: ViewDisplayValue[] = [];
	@Input() disable: Boolean;
	@Input() upsertStaffQualificationFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() downloadDocumentFn$: (ac: {
		documentId: number;
		fileName: string;
	}) => Observable<any> = (ac) => of(ac);

	otherQualifications: BlStaffSettingsModel[];
	standardQualifications: BlStaffSettingsModel[];
	coreKnowledge = BlStaffQualificationsCoreKnowledge;
	otherQ = BlStaffQualificationsOther;
	OpenPolytech = StaffQualificationInstitute.Polytech;

	constructor(
		private modalService: BsModalService,
		private routeService: RouteService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.setQualifications(this.staffSettings);
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges?.staffSettings) {
			this.setQualifications(this.staffSettings);
		}
	}

	getQualificationName = (id: string) =>
		BlStaffQualifications?.find((x) => x.id === id)?.name;

	setQualifications(staffSettings: BlStaffSettingsModel[]) {
		const data =
			staffSettings?.map((x) => ({
				...x,
				QualificationId: '',
			})) || [];

		this.standardQualifications = BlStaffStandardQualifications?.map((x) => {
			const q = data?.find(
				(d) =>
					d?.QualificationName === x?.name &&
					d?.SettingsCode === BlStaffSettingTypes.Qualifications
			);
			return {
				...q,
				QualificationId: x?.id,
				QualificationName: q?.QualificationName || x?.name,
				SettingsCode: q?.SettingsCode || x?.type,
			};
		});

		this.otherQualifications = data
			?.filter(
				(x) => x?.SettingsCode === BlStaffSettingTypes.OtherQualifications
			)
			?.map((x) => ({
				...x,
				QualificationId: BlStaffSettingTypes.OtherQualifications,
			}));
	}

	addQualification(data: BlStaffSettingsModel, isOtherQualification?: boolean) {
		const id = isOtherQualification ? this.otherQ?.id : data?.QualificationId;
		const qName = isOtherQualification
			? this.otherQ?.name
			: data?.QualificationName;
		const institute = id === this.coreKnowledge.id ? this.SQCKI : null;
		const limitSingleDoc =
			id === this.coreKnowledge.id || !!isOtherQualification ? false : true;
		const docType = BlStaffQualifications?.find((x) => x.id === id)?.type;

		const initialState = {
			title: qName,
			qualificationName: qName,
			qualificationId: id,
			institute,
			limitSingleDoc,
			staffId: this.staff?.StaffID,
			type: docType,
			isOtherQualification,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Add',
			upsertStaffQualificationFn$: this.upsertStaffQualificationFn$,
		};
		this.modalService.show(UserQualificationModalComponent, {
			class:
				'modal-dialog-centered modal-dialog modal-md user-qualification-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	}

	editQualification(
		data: BlStaffSettingsModel,
		isOtherQualification?: boolean
	) {
		const qName = data?.QualificationName || '';
		const id = BlStaffQualifications?.find((x) => x?.name === qName)?.id || '';
		const institute = id === this.coreKnowledge.id ? this.SQCKI : null;
		const limitSingleDoc =
			id === this.coreKnowledge.id || !!isOtherQualification ? false : true;
		const type = data?.SettingsCode;

		const initialState = {
			data,
			type,
			title: !!isOtherQualification ? this.otherQ.name : qName,
			qualificationName: qName,
			isOtherQualification,
			qualificationId: id,
			institute,
			limitSingleDoc,
			staffId: this.staff?.StaffID,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Update',
			upsertStaffQualificationFn$: this.upsertStaffQualificationFn$,
			downloadDocumentFn$: this.downloadDocumentFn$,
		};
		this.modalService.show(UserQualificationModalComponent, {
			class:
				'modal-dialog-centered modal-dialog modal-md user-qualification-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	}

	viewDocument(data) {
		return of(data)
			.pipe(
				map((res) => ({
					documentId: res.ReferenceId,
					name: res?.Value || '',
				})),
				mergeMap((res) => {
					const ext = res?.name?.split('.')?.reverse()?.[0];
					if (!!ext && !!res?.documentId) {
						if (ext?.toLowerCase() === 'pdf') {
							return of(res).pipe(
								tap(() => {
									const pdfUrl = this.router.serializeUrl(
										this.router.createUrlTree(this.routeService.viewPdf(res))
									);
									window.open(pdfUrl, '_blank');
								}),
								take(1)
							);
						} else {
							return this.downloadDocumentFn$({
								documentId: res?.documentId,
								fileName: res?.name,
							});
						}
					}
				}),
				take(1)
			)
			.subscribe();
	}
}
