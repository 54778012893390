import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { LiabilityMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/liability/liability.mapper';
import { LiabilityInfo } from 'src/app/shared/models/client-review-template/assets-liabilities/liability/liability.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { BusinessService } from '@core/business/business.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-liabilities-form',
	templateUrl: './liabilities-form.html',
	styleUrls: ['./liabilities-form.scss'],
})
export class LiabilitiesFormComponent implements OnInit {
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() lender: ViewDisplayValue[];
	@Input() loanTypes: ViewDisplayValue[];
	@Input() mortgageFrequency: ViewDisplayValue[];
	@Input() agree: ViewDisplayValue[];
	@Input() liabilities: ViewDisplayValue[];
	@Input() liabilityInfo: LiabilityInfo;
	@Input() borrowers: ViewDisplayValue[];
	@Input() securities: ViewDisplayValue[];
	@Input() isLoading: boolean;
	@Input() viewMode: boolean;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;
	isLoadingBtn = false;

	cancelLoading: boolean;
	enableLoatV2$: Observable<boolean>;

	constructor(private fb: UntypedFormBuilder, private loggerService: LoggerService,
		private businessService: BusinessService) {
		this.enableLoatV2$ = this.businessService.loatv2Enabled$;
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();
	}

	prepareData() {
		this.form.reset(LiabilityMapper.mapToView(this.liabilityInfo));
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	}

	get Liability() {
		return this.form.get('liability');
	}
	get LoanLimit() {
		return this.form.get('loanLimit');
	}
	get LoanBalance() {
		return this.form.get('loanBalance');
	}
	get LoanRepayment() {
		return this.form.get('loanRepayment');
	}
	get Repayment() {
		return this.form.get('repayment');
	}
	get MortgageFrequency() {
		return this.form.get('mortgageFrequency');
	}

	buildForm() {
		this.form = this.fb.group({
			borrower: [''],
			security: [''],
			liability: ['', Validators.required],
			loanLimit: ['', Validators.required],
			lender: [''],
			loanBalance: ['', Validators.required],
			loanRepayment: ['', Validators.required],
			mortgageFrequency: ['Fortnightly', Validators.required],
			interestRate: [''],
			paidInFull: [''],
			loanType: [''],
		});
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.borrowers);
	}

	save() {
		if (this.isLoading || this.cancelLoading) {
			return;
		}
		this.submitted = true;
		this.isLoadingBtn = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			this.isLoadingBtn = false;
			return;
		}
		const value = this.form.value;
		this.saveEvent.emit(
			!!this.liabilityInfo
				? { ...value, cRTId: this.liabilityInfo.CRTId }
				: value
		);
		this.isLoadingBtn = false;
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => this.cancelLoading = false, 500);
	}
}
