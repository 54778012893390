import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
	CrtKiwiSaverRiskProfile,
	CrtKiwiSaverRiskProfileState,
	RiskProfileStore,
} from './risk-profile.store';

@Injectable({
	providedIn: 'root',
})
export class CrtKiwiSaverRiskProfileQuery extends QueryEntity<
	CrtKiwiSaverRiskProfileState,
	CrtKiwiSaverRiskProfile
> {
	allRiskProfile$ = this.selectAll();

	constructor(riskProfileStore: RiskProfileStore) {
		super(riskProfileStore);
	}
}
