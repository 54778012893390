<ng-container *appRestrictTo="['TCPUCI']">
	<ng-container *ngIf="hasInvitedCustomer$ | async">
		<accordion
			class="p-2 w-100 mt-3 bg-transparent"
			[class.disabled]="!hasNoPendingAdviserApproval"
			[isAnimated]="true"
		>
			<accordion-group #updateClientInfo>
				<button
					class="btn btn-link btn-block tap-bg-primary text-white d-flex justify-content-between align-items-center"
					accordion-heading
					type="button"
					[disabled]="!hasNoPendingAdviserApproval || null"
				>
					<div class="pull-left float-left">Sync to CRM</div>
					<i
						title="Collapse"
						class="material-icons md-20"
						*ngIf="updateClientInfo.isOpen"
					>
						expand_less
					</i>
					<i
						title="Collapse"
						class="material-icons md-20"
						*ngIf="!updateClientInfo.isOpen"
					>
						expand_more
					</i>
				</button>
				<ng-container *ngIf="hasNoPendingAdviserApproval">
					<ng-container *ngIf="!isConfirm">
						<div class="p-2">
							<p class="mt-3">
								Sync client data from the Fact Find to the CRM. Please note that this
								will overwrite any data currently in the client's file.
							</p>
							<!-- <p class="mt-3">
								Syncronise info between CRM data and data currently present in
								this Mortgage Application
							</p>
							<p>
								*Attention
								<br />
								This will overwrite any data currently present in the CRM
								attached to this client file.
							</p> -->
							<div class="mt-3">
								<button
									class="tap-btn share-btn rounded-0 text-white py-1 mr-1 tap-bg-primary w-100"
									(click)="confirmSync()"
								>
									Confirm
								</button>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="isConfirm">
						<form class="p-2" [formGroup]="formConfirmUpdate">
							<h5 class="mb-0 mt-3">Attention:</h5>
							<h5 class="tap-text-primary mt-0">Updating CRM information</h5>
							<p class="my-3">This process is irreversible.</p>
							<p>
								Please type <b>{{ confirmationText }}</b> to proceed with the sync.
							</p>
							<div class="form-row mt-3">
								<div class="col-12">
									<input
										type="text"
										placeholder="Please type {{ confirmationText }}"
										formControlName="confirmation"
										class="form-control crt-form-control"
									/>
								</div>
							</div>
							<div class="mt-3">
								<button
									class="tap-btn share-btn rounded-0 text-white py-1 mr-1 tap-bg-primary w-100"
									(click)="confirm(); openModal(syncTemplate)"
									[disabled]="disableConfirmButton || null"
								>
									Confirm
								</button>
							</div>
						</form>
					</ng-container>
				</ng-container>
			</accordion-group>
		</accordion>
	</ng-container>
</ng-container>

<ng-container *appRestrictTo="['TCPS']">
	<accordion
		class="pt-0 pb-2 px-2 w-100 bg-transparent"
		[isAnimated]="true"
		*ngIf="!(hasInvitedCustomer$ | async)"
	>
		<accordion-group class="mt-2" #shareToCustomerPortal>
			<button
				class="btn btn-link btn-block tap-bg-primary text-white d-flex justify-content-between align-items-center"
				accordion-heading
				type="button"
			>
				<div class="pull-left float-left">Share Online Fact Find</div>
				<i
					title="Collapse"
					class="material-icons md-20"
					*ngIf="shareToCustomerPortal.isOpen"
				>
					expand_less
				</i>
				<i
					title="Collapse"
					class="material-icons md-20"
					*ngIf="!shareToCustomerPortal.isOpen"
				>
					expand_more
				</i>
			</button>
			<form class="p-2" [formGroup]="formShareToCP">
				<!-- <p class="mt-2">
					Request relevant application information from the client by sharing to
					thier Customer Portal.
				</p> -->
				<div class="form-row mt-2">
					<div class="col-12">
						<span class="crt-custom-dropdown w-100">
							<select
								formControlName="customerID"
								class="form-control crt-form-control"
							>
								<option value=""></option>
								<option
									*ngFor="let d of primaryContacts$ | async"
									[value]="d.customerID"
								>
									{{ d.invitationDisplay }}
								</option>
							</select>
						</span>
					</div>
				</div>
				<div class="form-row mt-3" *ngIf="customerID.value == 'other'">
					<div class="col-12">
						<input
							type="text"
							formControlName="emailConfirm"
							placeholder="Confirm Email"
							class="form-control crt-form-control"
						/>
					</div>
				</div>
				<div class="form-row mt-3">
					<div class="col-12">
						<button
							class="tap-btn tap-bg-primary share-btn text-white py-1 mr-1 w-100 rounded-0"
							[ngClass]="{ disabled: disableShareButton }"
							(click)="share()"
						>
							Share
						</button>
					</div>
				</div>
			</form>
		</accordion-group>
	</accordion>
</ng-container>

<ng-container *appRestrictTo="['TCPRCI']">
	<accordion
		class="pt-0 pb-2 px-2 w-100 bg-transparent"
		[isAnimated]="true"
		*ngIf="hasInvitedCustomer$ | async"
	>
		<accordion-group class="mt-2" (click)="showAdditionalInfoModal()">
			<button
				class="btn btn-link btn-block tap-bg-primary text-white d-flex justify-content-between align-items-center"
				accordion-heading
				type="button"
			>
				<div class="pull-left float-left">Request Additional Info</div>
			</button>
		</accordion-group>
	</accordion>
</ng-container>

<!-- Modal -->
<ng-template #syncTemplate>
	<app-export-progress
		[progress]="progress$ | async"
		[percent]="percent$ | async"
		[msg]="msg"
	></app-export-progress>
</ng-template>
<!-- End Modal -->
