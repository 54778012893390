<div class="col-auto p-3 d-flex flex-column widget">
  <div class="description pb-2 d-flex align-items-center">
    <span [innerHTML]="label"></span>
  </div>
  <div class="row">
    <app-donut-chart
      class="col-12"
      [strokeColor]="strokeColor"
      [part]="part"
      [total]="total"
      [customSuffix]="suffix"
      [isDecimal]="true"
    >
    </app-donut-chart>
  </div>
</div>