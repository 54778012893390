import { BLStaff, BLStaffs, DEFAULT_ADVISER_FILER } from './bl-staff.model';
import { QueryEntity } from '@datorama/akita';
import { BLStaffsState, BLStaffsStore } from './bl-staffs.store';
import { Injectable } from '@angular/core';
import { UserQuery } from '../user/user.query';
import { map, mergeMap, shareReplay, withLatestFrom } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import * as R from 'ramda';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';
import { BusinessConfigQuery } from '../../domain/business-config/business-config.query';

// Query list of bl staff
@Injectable()
export class BLStaffsQuery extends QueryEntity<BLStaffsState, BLStaff> {
	/**
	 * Get all staff regardless of status
	 * @type Observable<BLStaff[]>
	 */
	allUnfilteredStaffs$ = this.selectAll();

	/**
	 * Get all staff active, paused but NOT removed
	 * @type Observable<BLStaff[]>
	 */
	staffs$: Observable<BLStaff[]> = this.selectAll({
		filterBy: (x) => x.IsActive > 0,
	});

	/**
	 * Get all staff active
	 * @type Observable<BLStaff[]>
	 */
	activeStaffs$: Observable<BLStaff[]> = this.selectAll({
		filterBy: (x) => x.IsActive === 1,
	});

	/**
	 * Get all Lead gen staffs that are active or paused
	 * @type Observable<BLStaff[]>
	 */
	leadGens$: Observable<BLStaff[]> = this.staffs$.pipe(
		map(BLStaffs.getLeadGensAndLeadGenInternal),
		shareReplay(1)
	);

  /**
	 * Get all Lead gen staffs that are active, deactivated or paused
	 * @type Observable<BLStaff[]>
	 */
	allLeadGens$: Observable<BLStaff[]> = this.allUnfilteredStaffs$.pipe(
		map(BLStaffs.getAllLeadGensAndLeadGenInternal),
		shareReplay(1)
	);

	/**
	 * Get all Lead gen staffs that are active or paused
	 * @type Observable<BLStaff[]>
	 */
	activeLeadGens$: Observable<BLStaff[]> = this.staffs$.pipe(
		map(BLStaffs.getLeadGensAndLeadGenInternal),
		map((x) => x.filter((y) => y.IsActive === 1)),
		shareReplay(1)
	);

	/**
	 *  Get all staffs under the current user that are active or paused
	 * @type Observable<BLStaff[]>
	 */
	availableStaffs$: Observable<BLStaff[]> = combineLatest([
		this.staffs$,
		this.userQuery.userInfo$,
	]).pipe(
		map(([staffs, user]) => BLStaffs.getAvailableAdvisers(user, staffs)),
		shareReplay(1)
	);

	/**
	 * Same as leadGens$ but in ViewDisplayValue model
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	leadGenChoices$: Observable<ViewDisplayValue[]> = this.leadGens$.pipe(
		map(R.map(mapStaffToChoices)),
		shareReplay(1)
	);

  	/**
	 * Same as leadGens$ but in ViewDisplayValue model
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	allLeadGenChoices$: Observable<ViewDisplayValue[]> = this.allLeadGens$.pipe(
		map(R.map(mapStaffToChoices)),
		shareReplay(1)
	);
	/**
	 * Same as leadGens$ but in ViewDisplayValue model
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	activeLeadGenChoices$: Observable<ViewDisplayValue[]> =
		this.activeLeadGens$.pipe(map(R.map(mapStaffToChoices)), shareReplay(1));

	/**
	 * Same as availableStaffs$ but in ViewDisplayValue model
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	availableStaffsChoices$: Observable<ViewDisplayValue[]> =
		this.availableStaffs$.pipe(map(R.map(mapStaffToChoices)), shareReplay(1));

	/**
	 * adviser and calendar-url pair
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	adviserCalendarChoices$: Observable<ViewDisplayValue[]> = this.staffs$.pipe(
		map((x) =>
			x?.map((y) => ViewDisplayValue.Map(y.StaffID?.toString(), y.CalendarUrl))
		),
		shareReplay(1)
	);

	adviserChoicesRaw$ = this.availableStaffs$.pipe(
		withLatestFrom(this.userQuery.userInfo$),
		map(([staffs, userInfo]) => {
			const availableStaffs = staffs
				// Filter based on the show in adviser list property
				?.filter(
					(x) =>
						DEFAULT_ADVISER_FILER?.includes(x.SecurityGroup) ||
						x?.StaffSettings?.ShowInAdviserList
				);

			return availableStaffs;
		})
	);

	adviserChoices$ = this.adviserChoicesRaw$.pipe(
		withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
		map(([staffs, isTapLevel, userInfo]) => {
			const availableStaffs = staffs
				?.filter((x) =>
					!isTapLevel
						? userInfo.AdviserIdsAssigned?.some((y) => y === x.StaffID)
						: x
				)
				// Map to ViewDisplayValue
				?.map((x) =>
					ViewDisplayValue.Map(
						x.StaffID?.toString(),
						`${x.FirstName} ${x.LastName}`
					)
				);

			// If Business Level user, add logged in BL
			const dsiplay = !isTapLevel
				? !userInfo.SecurityRoles?.includes('FDAA')
					? [
							ViewDisplayValue.Map(
								userInfo.StaffID?.toString(),
								`${userInfo.FirstName} ${userInfo.LastName}`
							),
					  ]
					: availableStaffs
				: availableStaffs;

			// Sort adviser dropdowns
			return dsiplay
				? dsiplay?.sort((a, b) => a.display?.localeCompare(b.display))
				: dsiplay;
		})
	);

	// All Staffs allowed/checked on the logged in user's accessibility settings
	allowedInAccessibility$ = this.allUnfilteredStaffs$.pipe(
		withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
		map(([staffs, isTapLevel, userInfo]) => {
			const availableStaffs = staffs
				?.filter((x) =>
					!isTapLevel
						? userInfo.AdviserIdsAssigned?.some((y) => y === x.StaffID)
						: x
				)
				// Map to ViewDisplayValue
				?.map((x) =>
					ViewDisplayValue.Map(
						x.StaffID?.toString(),
						`${x.FirstName} ${x.LastName}`
					)
				);

			// If Business Level user, add logged in BL
			const isLG =
				userInfo?.SecurityGroup === 'LG' || userInfo?.SecurityGroup === 'LGI';
			const dsiplay = !isTapLevel
				? !userInfo.SecurityRoles?.includes('FDAA') && !isLG
					? [
							ViewDisplayValue.Map(
								userInfo.StaffID?.toString(),
								`${userInfo.FirstName} ${userInfo.LastName}`
							),
					  ]
					: availableStaffs
				: availableStaffs;

			// Sort adviser dropdowns
			return dsiplay
				? dsiplay?.sort((a, b) => a.display?.localeCompare(b.display))
				: dsiplay;
		})
	);


	/**
	 * List of all staffs that are 'AM', 'A', 'BO' and ticked ShowInAdviserList
	 * Regardless of Accessbility
	 */
	allUnfilteredAdvisers$ = this.staffs$.pipe(
		map((staffs) => {
			const availableStaffs = staffs?.filter(
				(x) =>
					DEFAULT_ADVISER_FILER?.includes(x.SecurityGroup) ||
					x?.StaffSettings?.ShowInAdviserList
			);

			return availableStaffs;
		})
	);

	/**
	 * Get all staff active, paused, or removed
	 *
	 * @type Observable<BLStaff[]>
	 */
	allStaffs$: Observable<BLStaff[]> = this.selectAll();

	adviserChoicesOption$ = this.adviserChoicesRaw$.pipe(
		map((staffs) => {
			const availableStaffs = staffs
				?.map((x) =>
					ViewDisplayValue.Map(
						x.StaffID?.toString(),
						`${x.FirstName} ${x.LastName}`
					)
				)
				?.sort((a, b) => a.display?.localeCompare(b.display));
			return availableStaffs;
		})
	);

	/**
	 * Same as allStaffs$ but in ViewDisplayValue model
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	allStaffsChoices$: Observable<ViewDisplayValue[]> = this.allStaffs$.pipe(
		map(R.map(mapStaffToChoices)),
		shareReplay(1)
	);

	/**
	 * Same as allStaffs$ but in object lookup
	 *
	 * @type Observable<{[key: string]: string}>
	 */
	allStaffsChoicesLookup$: Observable<{
		[key: string]: string;
	}> = this.allStaffsChoices$.pipe(
		map((choices) =>
			choices?.reduce(
				(prev, curr) => ({ ...prev, [curr.value]: curr.display }),
				{}
			)
		),
		shareReplay(1)
	);

	/**
	 * Get all available staffs active, paused, or removed
	 *
	 * @type Observable<BLStaff[]>
	 */
	allAvailableStaffs$: Observable<BLStaff[]> = combineLatest([
		this.allStaffs$,
		this.userQuery.userInfo$,
	]).pipe(
		map(([staffs, user]) => BLStaffs.getAvailableAdvisers(user, staffs)),
		shareReplay(1)
	);

	/**
	 * Get active staffs, (paused or removed A and AM only)
	 *
	 *  @type Observable<BLStaff[]>
	 */
	activeStaffsAndAdviserManagers$: Observable<BLStaff[]> = combineLatest([
		this.allStaffs$,
		this.userQuery.userInfo$,
	]).pipe(
		map(([staffs, user]) =>
			BLStaffs.getActiveAdvisersAndAdviserManager(user, staffs)
		),
		shareReplay(1)
	);

	/**
	 * Same as allAvailableStaff$ but in ViewDisplayValue model
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	allAvailableStaffsChoices$: Observable<ViewDisplayValue[]> =
		this.allAvailableStaffs$.pipe(
			map(R.map(mapStaffToChoices)),
			shareReplay(1)
		);

	/**
	 * Same as allAvailableStaffs$ but in ViewDisplayValue model
	 *
	 * Get all active advisers and paused/inactive A and AM
	 * @type Observable<ViewDisplayValue[]>
	 */
	activeStaffsAndAdviserManagersChoices$: Observable<ViewDisplayValue[]> =
		this.activeStaffsAndAdviserManagers$.pipe(
			map(R.map(mapStaffToChoices)),
			shareReplay(1)
		);

	/**
	 * Same as activeStaffs$ but in ViewDisplayValue model
	 *
	 * Get all staff active
	 * @type Observable<ViewDisplayValue[]>
	 */
	allActiveStaffs$: Observable<ViewDisplayValue[]> = this.activeStaffs$.pipe(
		map(R.map(mapStaffToChoices)),
		shareReplay(1)
	);

	/**
	 * Same as allUnfilteredAdvisers$ but in ViewDisplayValue model
	 *
	 * @type Observable<ViewDisplayValue[]>
	 */
	altAdviserChoices$: Observable<ViewDisplayValue[]> =
		this.allUnfilteredAdvisers$.pipe(
			map(R.map(mapStaffToChoices)),
			shareReplay(1)
		);
	
	filterStaffAdviserRework(serviceId: string, staffs: BLStaff[]) {
		return this.businessConfigQuery.adviserReworkFeature$.pipe(
			map((adviserReworkEnabled) => {
				const filteredList = staffs?.filter((x) =>
					adviserReworkEnabled
						? x.StaffSettings?.AdviserServices?.includes(serviceId)
						: x
				);
				const availableStaffs = filteredList
					?.map((x) =>
						ViewDisplayValue.Map(
							x.StaffID?.toString(),
							`${x.FirstName} ${x.LastName}`
						)
					)
					?.sort((a, b) => a.display?.localeCompare(b.display));
				return availableStaffs;
			})
		)
	}

	LRAdviserChoicesOption$ = this.adviserChoicesRaw$.pipe(
		mergeMap(
			(staffs) =>
				this.filterStaffAdviserRework('L&R Insurance', staffs)
			)
	);

	FGAdviserChoicesOption$ = this.adviserChoicesRaw$.pipe(
		mergeMap(
			(staffs) =>
				this.filterStaffAdviserRework('F&G Insurance', staffs)
			)
	);

	MAdviserChoicesOption$ = this.adviserChoicesRaw$.pipe(
		mergeMap(
			(staffs) =>
				this.filterStaffAdviserRework('Mortgage', staffs)
			)
	);

	KsAdviserChoicesOption$ = this.adviserChoicesRaw$.pipe(
		mergeMap(
			(staffs) =>
				this.filterStaffAdviserRework('KiwiSaver', staffs)
			)
	);

  GAdviserChoicesOption$ = this.adviserChoicesRaw$.pipe(
		mergeMap(
			(staffs) =>
				this.filterStaffAdviserRework('Group Insurance', staffs)
			)
	);

  InvestmentAdviserChoicesOption$ = this.adviserChoicesRaw$.pipe(
		mergeMap(
			(staffs) =>
				this.filterStaffAdviserRework('Investment', staffs)
			)
	);

	constructor(
		protected store: BLStaffsStore,
		protected userQuery: UserQuery,
		protected businessConfigQuery: BusinessConfigQuery
	) {
		super(store);
	}
}

function mapStaffToChoices(staff: BLStaff): ViewDisplayValue {
	return {
		display: `${staff.FirstName} ${staff.LastName}`,
		value: `${staff.StaffID}`,
	};
}
