<app-user-details
	[staff]="staff"
	[staffSettings]="[]"
	[LRP]="LRP$ | async"
	[SCS]="SCS$ | async"
	[SCT]="SCT$ | async"
	[SAS]="SAS$ | async"
	[SQCKI]="SQCKI$ | async"
	[secGroups]="secGroups"
	[updateStaffFn$]="submitAdd"
	[companyCode]="companyCode"
	[isTapLevel]="isTapLevel"
	[userSecurityGroup]="userSecurityGroup$ | async"
	[isAdd]="true"
	header="Add user"
	(cancelEvent)="cancel()"
>
</app-user-details>
