import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDataTableModel } from '@shared/models/_general/mat-data-table.model';
import { of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
declare var $: any;

@Component({
	selector: 'app-manage-users-list-selection',
	templateUrl: './manage-users-list-selection.component.html',
	styleUrls: ['./manage-users-list-selection.component.scss'],
})
export class ManageUsersListSelectionComponent implements AfterViewInit {
	@Input() set columns(cols: MatDataTableModel[]) {
		this.setColumns(cols);
	}
	@Output() toggleColumns = new EventEmitter<any>();
	@Output() closeColumnSelection = new EventEmitter<any>();

	fixedColumns: MatDataTableModel[];
	visibleColumns: MatDataTableModel[];
	hiddenColumns: MatDataTableModel[];
	form: FormGroup;
	isLoading = false;

	ngAfterViewInit() {
		$(document).ready(() => {
			$('.disabled-sortable').parent().addClass('disabled d-none');
		});
	}

	setColumns(data: MatDataTableModel[]) {
		this.fixedColumns = data?.filter((x) => !!x?.sticky);
		this.visibleColumns = data?.filter((x) => !!x?.visible && !x?.sticky);
		this.hiddenColumns = data?.filter((x) => !x?.visible && !x?.sticky);
	}

	prepColumnValue() {
		return {
			fixedColumns: this.fixedColumns || [],
			visibleColumns:
				this.visibleColumns?.map((x) => ({
					...x,
					visible: true,
				})) || [],
			hiddenColumns:
				this.hiddenColumns?.map((x) => ({ ...x, visible: false })) || [],
		};
	}

	save() {
		if (this.visibleColumns.length === 0) {
			return;
		}
		of(this.prepColumnValue())
			.pipe(
				tap((data) => {
					this.toggleColumns.emit(data);
					this.closeColumnSelection.emit(data);
				}),
				take(1)
			)
			.subscribe();
	}

	cancel() {
		this.closeColumnSelection.emit(this.prepColumnValue());
	}
}
