import { Component } from '@angular/core';
import {
	AdviceProcessDocumentState,
	AdviceProcessPageCodes,
	GetPageCodeForDocumentField,
	KOATDocumentField,
	KOATDocumentsDisplayOffline,
} from '@shared/models/advice-process/advice-process.model';
import { AdviceProcessDocumentBaseComponent } from '../advice-process-document-base/advice-process-document-base.component';

@Component({
	selector: 'app-advice-process-koat-documents',
	templateUrl: './advice-process-koat-documents.component.html',
})
export class AdviceProcessKoatDocumentsComponent extends AdviceProcessDocumentBaseComponent {
	koatDocumentFields = KOATDocumentField;

	koatFieldsExceptions = [
		KOATDocumentField.Declaration,
		KOATDocumentField.KiwiSaverInformation,
		KOATDocumentField.RiskProfileReview,
		KOATDocumentField.AdviceSummary,
	].map((field) => field.toString());

	adviceSummaryHasDoc = false;

	adviceProcessPageCodes = AdviceProcessPageCodes;

	constructor() {
		super();
	}

	pageIsCompleted(code: AdviceProcessPageCodes): boolean {
		return this.adviceProcess?.pageCompleted?.includes(code) ?? false;
	}

	checkShow(field) {
		let check = false;

		if (field === KOATDocumentField.Declaration) {
			check = this.adviceProcess.pageStarted?.includes(
				AdviceProcessPageCodes.KiwiSaverDeclaration
			);
		}

		if (field === KOATDocumentField.KiwiSaverInformation) {
			check =
				this.adviceProcess.pageStarted?.includes(
					AdviceProcessPageCodes.FactFind
				) ||
				this.adviceProcess.pageStarted?.includes(
					AdviceProcessPageCodes.YourKiwiSaver
				);
		}

		if (field === KOATDocumentField.RiskProfileReview) {
			check = this.adviceProcess.pageStarted?.includes(
				AdviceProcessPageCodes.RiskProfile
			);
		}

		if (field === KOATDocumentField.AdviceSummary) {
			check = this.adviceProcess.pageCompleted?.includes(
				AdviceProcessPageCodes.Declaration
			);
		}

		return check;
	}

	prepDocuments() {
		this.documents = this.adviceProcess.documents?.map(
			(x: AdviceProcessDocumentState) => {
				if (this.koatFieldsExceptions.includes(x.field)) {
					return {
						...x,
						show: this.checkShow(x.field),
						isUploading: false,
						isDeleting: false,
						isDownloading: false,
					};
				}
				if (KOATDocumentsDisplayOffline.includes(x.field)) {
					return {
						...x,
						show: true,
						isUploading: false,
						isDeleting: false,
						isDownloading: false,
					};
				} else {
					const field = GetPageCodeForDocumentField(
						x.field,
						this.adviceProcess.processCode
					);
					return this.getDocumentState(field, x);
				}
			}
		);
	}
}
