import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { AuthorityToProceedSettingsService } from './state/authority-to-proceed-settings.service';

@Component({
  selector: 'app-authority-to-proceed-settings',
  templateUrl: './authority-to-proceed-settings.component.html'
})
export class AuthorityToProceedSettingsComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
  emailSettings$ = this.apService.authorityToProceed$;

  constructor(
    private apService: AuthorityToProceedSettingsService
  ) { }

  ngOnInit(): void {
    this.prepData();
  }

  prepData() {
    combineLatest([
      this.emailSettings$
    ]).pipe(
      map(([ap]) => MergeTagsMapper.mapMergeTags(ap?.mergeTag || [])),
      tap((mt) => this.mergeTags$.next(mt)),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

}
