<div class="field">
  <div #longText>
    <a
      *ngIf="route; else textTemplate"
      class="m-auto"
      [ngClass]="{ clickable: route }"
      [routerLink]="route"
      (click)="onClick()"
      id="{{fieldId}}Link_{{index}}"
    >
      {{ value }}
    </a>
  </div>
</div>

<ng-template #textTemplate>
  <a class="m-auto">
    {{ value }}
  </a>
</ng-template>

<ng-container *ngIf="!restrict; else hasRestriction">
  <div *ngIf="isEditable" class="control">
    <button type="button" class="icon-btn-bg btn-edit" (click)="addNoteModal()" id="{{fieldId}}AddBtn_{{index}}">
      <i class="material-icons md-16 dark">&#xE3C9;</i>
    </button>
  </div>
</ng-container>

<ng-template #hasRestriction>
  <ng-container *appRestrictTo="restrict">
    <div *ngIf="isEditable" class="control">
      <button
        type="button"
        class="icon-btn-bg btn-edit"
        (click)="addNoteModal()"
        id="{{fieldId}}AddBtn_{{index}}"
      >
        <i class="material-icons md-16 dark">&#xE3C9;</i>
      </button>
    </div>
  </ng-container>
</ng-template>
