import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { mapTo } from 'rxjs/operators';
import { resetStores } from '@datorama/akita';
import { UserStore } from '../../domain/user/user.store';
import { BusinessPageStore } from 'src/app/modules/business-page/state/business-page.store';

@Injectable()
export class BusinessClearGuard implements CanActivate {
  constructor(private configService: ConfigService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    resetStores({
      exclude: [UserStore.storeName, BusinessPageStore.storeName],
    });
    return this.clearAndConfig();
  }
  clearAndConfig() {
    return this.configService.clearCompanyCode().pipe(mapTo(true));
  }
}
