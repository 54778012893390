import { MergeTagTypeCode } from '../../../merge-tags.model';

export const appLoanStructureMetaKey = {
	loanStructureSection: 'APPLICATION_LOAN_STRUCTURE_SECTION',
};

export const appLoanStructureMergeTag = [
	{
		metaKey: appLoanStructureMetaKey.loanStructureSection,
		description: 'Application > Loan Structure Section',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '$100 - clients would look to fix the whole loan. Structure to be confirmed after approval',
		noWrap: true,
	},
];
