import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClientProfileQuery } from '@modules/crm/client-profile/states/client-profile.query';
import {
	mtMergeContent,
	normalizeHTMLSymbols,
	removeMtWrappers,
} from '@shared/converter/content-merge-tags';
import { UploadModalComponent } from '@shared/modal/upload-modal/upload-modal.component';
import { EmailLinksMT } from '@shared/models/client-review-template/merge-tags/email-settings/email-links';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { DocumentGroupState } from '@shared/models/documents/document-group.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { Attachment } from '@shared/models/_general/attachment.model';
import { LinkDocumentComponent } from '@shared/services/link-document/link-document.component';
import { convertUtil, fileUtil, objectUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { iif, Observable, Subject, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import {
	getRequiredWarning,
	logMessage,
} from 'src/app/shared/error-message/error-message';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { CrtDocumentService } from '../../../service/crt-document.service';

@Component({
	selector: 'app-crt-invite-cp-additional-info-modal',
	templateUrl: './crt-invite-cp-additional-info-modal.component.html',
	styleUrls: ['./crt-invite-cp-additional-info-modal.component.scss'],
})
export class CrtInviteCpAdditionalInfoModalComponent implements OnInit {
	@ViewChild('contentEditor') editor: WysiwygComponent;

	@ViewChild('uploadOptionModal', { read: TemplateRef })
	uploadOptionModal: TemplateRef<any>;

	@Input() attachments: Attachment[] = [];

	mergeTags$: Observable<MergeTagState[]> | null = null;

	sendFn$: (data: any, attachments: Attachment[]) => Observable<boolean>;

	isSending = false;

	isLoading = false;

	clientDocuments: DocumentGroupState;

	froalaExtendOptions = {
		heightMax: 200,
		heightMin: 300,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};

	formGroup = new UntypedFormGroup({
		carbonCopy: new UntypedFormControl(null),
		blindCarbonCopy: new UntypedFormControl(null),
		subject: new UntypedFormControl(null),
		sendTo: new UntypedFormControl({ value: null, disabled: true }),
	});

	// this is set in cp-invite component when
	// modal is initialized
	data: any;

	bodyContent: string;

	optionModalRef: BsModalRef;
	linkModalRef: BsModalRef;
    uploadModal: BsModalRef;

	constructor(
		public bsModalRef: BsModalRef,
		private loggerService: LoggerService,
		private cdr: ChangeDetectorRef,
		private modalService: BsModalService,
		private clientProfileQuery: ClientProfileQuery,
		private crtDocService: CrtDocumentService
	) {}

	ngOnInit(): void {
		this.isLoading = true;
		this.mergeTags$
			?.pipe(
				map((mergeTags: MergeTagState[]) =>
					MergeTagsMapper.updateEmailMergeTags(mergeTags, [
						// ...customMTags,
						...EmailLinksMT,
					])
				),
				map((data: MergeTagState[]) => {
					const bodyTags = mtMergeContent(this.data.body, data);
					this.data.body = bodyTags;

					// this formats the subject merge tags
					// const subjectMergeTags =
					// 	MergeTagsMapper.convertImageLinkToTextMT(data);
					const subjectContent = mtMergeContent(
						this.data.subject, // this.form.controls.subject.value,
						data,
						false
					);
					const parsedSubject = removeMtWrappers(subjectContent);
					this.data.subject = normalizeHTMLSymbols(parsedSubject);
					return this.data;
				}),
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((res) => {
				this.formGroup.patchValue(res);
				this.bodyContent = res.body;
				this.isLoading = false;
				this.cdr.detectChanges();
			});
		this.clientDocuments = R.clone(
			this.clientProfileQuery.getValue().documents
		);
	}

	send(): void {
		if (!this.bodyContent) {
			const msg = getRequiredWarning('Information');
			this.loggerService.Warning({}, msg);
			return;
		}

		this.isSending = true;
		this.data.content = this.editor.content;
		this.sendFn$(
			{ ...this.data, ...this.formGroup.getRawValue() },
			this.attachments
		)
			.pipe(
				take(1),
				catchError((err) => {
					this.isSending = false;
					return throwError(err);
				})
			)
			.subscribe(() => {
				this.isSending = false;
				this.loggerService.Success(
					{},
					logMessage.oat.mortgage.cpInvitation
						.successfullySentAdditionalInformation
				);
				this.cancel();
			});
	}

	cancel(): void {
		this.bsModalRef.hide();
	}

	onValueChanged(value: any): void {
		this.bodyContent = value.content;
	}

	openUploadOptionModal() {
		this.optionModalRef = this.modalService.show(this.uploadOptionModal, {
			class: 'modal-dialog-centered',
			ignoreBackdropClick: true,
		});
	}

	getTotalAttachmentsSize() {
		return this.attachments.reduce((a, b) => a + (+b.fileSize || 0), 0);
	}

	uploadDocuments() {
		const stop$ = new Subject<void>();

		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

		// convert Image File -> Base64 -> HTML img -> PDF
		const convertImageToBase64 = (file: File): Observable<string> => {
			return new Observable((obs) => {
				obs.next(file);
				obs.complete();
			}).pipe(
				// convert file to base64
				mergeMap(() => convertUtil.simpleConvertToBase64(file)),
				// create a html img string and add the converted base64 image in src
				map((base64) => `<img src="${base64}" />`),
				// convert html img with base64 src to pdf
				mergeMap((img) => {
					return this.crtDocService.generatePDFbase64(img, {
						FileName: convertFilename(file),
					});
				})
			);
		};

		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() =>
							// check if the file is image
							req[0].type.startsWith('image/'),
						// If the file is image. We need to convert it to pdf
						convertImageToBase64(req[0]),
						convertUtil.simpleConvertToBase64(x[0]).pipe(take(1))
					)
				),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileUploadSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				map((file) => {
					const fileStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					return {
						Document: fileStr,
						OriginalDocument: file,
						FileName: convertFilename(req[0]),
						DocumentType: null, // this.documentInfo.documentType,
						Type: null, // this.documentInfo.type,
						ReferenceId: null, // this.documentInfo.referenceId,
						CustomerId: null, // this.documentInfo.customerId,
						Size: +fileUtil.getFileSizeKb(fileStr),
					};
				}),
				mergeMap((file) =>
					this.crtDocService.uploadDocument(file).pipe(
						tap((x) => {
							const newFile = objectUtil.mapPascalCaseToCamelCase(file);
							this.attachments.push({
								documentId: +x,
								fileName: newFile.fileName,
								generateContentCallback$: undefined,
								content: newFile.document,
								pdfOptions: {
									orientation: '',
									format: '',
								},
								fileSize: newFile.size,
								type: 'uploaded',
							});
						})
					)
				),
				takeUntil(stop$),
				catchError((err) => {
					this.loggerService.Warning(
						{},
						logMessage.shared.fileUploadSize.single.error
					);
					return err;
				})
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Save Document',
			restrict: '.pdf,image/jpeg,image/jpg,image/png',
			maxFileSize: 10000, // kb
			maxFileSizeText: '10MB',
			currentFileSize: +this.getTotalAttachmentsSize(),
		};
		this.uploadModal = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	removeAttachment(index: number): void {
		this.attachments.splice(index, 1);
	}

	linkDocument() {
		const stop$ = new Subject<void>();
		const initState = {
			selectedDetail: 'Link Document',
			document: this.clientDocuments,
			initialSelectedTab: ServicesCodes.AdviceProcess?.toLowerCase(),
			maxFileSize: 10000,
			currentFileSize: +this.getTotalAttachmentsSize(),
		};
		this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		let fileName = '';
		let documentId = '';
		this.linkModalRef.content.getSelectedDocumentValue$
			.pipe(
				filter((x) => !!x),
				mergeMap((x: any) => {
					documentId = x.id;
					fileName = x.fileName;
					return this.crtDocService.getDocumentLink(x.documentLink, {
						responseType: 'blob',
					});
				}),
				mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileLinkSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				tap((x) => {
					const fileStr = `${((x as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					this.attachments.push({
						documentId: +documentId,
						fileName,
						generateContentCallback$: undefined,
						content: fileStr,
						pdfOptions: {
							orientation: '',
							format: '',
						},
						fileSize: +fileUtil.getFileSizeKb(fileStr),
						type: 'linked',
					});
				}),
				takeUntil(stop$),
				catchError((err) => {
					return err;
				})
			)
			.subscribe();
	}
}
