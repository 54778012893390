import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import {
	ProviderCommissionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MortgageProviderCommissionState } from './provider-commission.model';
import { MortgageProviderCommissionQuery } from './provider-commission.query';
import { MortgageProviderCommissionStore } from './provider-commission.store';
import { objectUtil } from 'src/app/util/util';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';

@Injectable()
export class MortgageProviderCommissionService {
	settings$ = this.query.all$;

	constructor(
		private api: ApiService,
		private moatQuery: CrtMortgageQuery,
		protected store: MortgageProviderCommissionStore,
		protected query: MortgageProviderCommissionQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(id?: number) {
		const adviserId = id || this.moatQuery.getValue().adviceProcess?.adviser;
		const endpoint = `staff/${adviserId}/settings/${ProviderCommissionCodes.Mortgage}`;

		return this.api.get<MortgageProviderCommissionState[]>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			tap((x) =>
				applyTransaction(() => {
					this.store.set(x);
				})
			),
			catchError(() => of([]))
		);
	}
}
