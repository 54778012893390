import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';

import { PipelineRoutingModule } from './pipeline-routing.module';

import { LrInsuranceComponent } from './lr-insurance/lr-insurance.component';
import { LrInsuranceFormComponent } from './lr-insurance/lr-insurance-form/lr-insurance-form.component';
import { LrInsuranceColumnsFormComponent } from './lr-insurance/lr-insurance-columns-form/lr-insurance-columns-form.component';
import { LrInsuranceService } from './lr-insurance/states/lr-insurance.service';
import { LrInsuranceResolver } from './lr-insurance/lr-insurance.resolver';
import { LrInsuranceUiStore } from './lr-insurance/states/lr-insurance-ui.store';
import { LrInsuranceUiQuery } from './lr-insurance/states/lr-insurance-ui.query';
import { LrInsuranceStore } from './lr-insurance/states/lr-insurance.store';
import { LrInsuranceQuery } from './lr-insurance/states/lr-insurance.query';

import { MortgageComponent } from './mortgage/mortgage.component';
import { MortgageService } from './mortgage/states/mortgage.service';
import { MortgageUiStore } from './mortgage/states/mortgage-ui.store';
import { MortgageUiQuery } from './mortgage/states/mortgage-ui.query';
import { MortgageStore } from './mortgage/states/mortgage.store';
import { MortgageQuery } from './mortgage/states/mortgage.query';
import { MortgageResolver } from './mortgage/mortgage.resolver';
import { MortgageFormComponent } from './mortgage/mortgage-form/mortgage-form.component';
import { MortgageColumnsFormComponent } from './mortgage/mortgage-columns-form/mortgage-columns-form.component';

import { KiwisaverResolver } from './kiwisaver/kiwisaver.resolver';
import { KiwisaverUiStore } from './kiwisaver/states/kiwisaver-ui.store';
import { KiwisaverUiQuery } from './kiwisaver/states/kiwisaver-ui.query';
import { KiwisaverStore } from './kiwisaver/states/kiwisaver.store';
import { KiwisaverQuery } from './kiwisaver/states/kiwisaver.query';
import { KiwisaverColumnsFormComponent } from './kiwisaver/kiwisaver-columns-form/kiwisaver-columns-form.component';
import { KiwisaverComponent } from './kiwisaver/kiwisaver.component';
import { KiwisaverFormComponent } from './kiwisaver/kiwisaver-form/kiwisaver-form.component';
import { KiwisaverService } from './kiwisaver/states/kiwisaver.service';

import { InvestmentResolver } from './investment/investment.resolver';
import { InvestmentUiStore } from './investment/states/investment-ui.store';
import { InvestmentUiQuery } from './investment/states/investment-ui.query';
import { InvestmentStore } from './investment/states/investment.store';
import { InvestmentQuery } from './investment/states/investment.query';
import { InvestmentColumnsFormComponent } from './investment/investment-columns-form/investment-columns-form.component';
import { InvestmentComponent } from './investment/investment.component';
import { InvestmentFormComponent } from './investment/investment-form/investment-form.component';
import { InvestmentService } from './investment/states/investment.service';

import { FgInsuranceComponent } from '../pipeline/fg-insurance/fg-insurance.component';
import { FgInsuranceFormComponent } from '../pipeline/fg-insurance/fg-insurance-form/fg-insurance-form.component';
import { FgInsuranceColumnsFormComponent } from '../pipeline/fg-insurance/fg-insurance-columns-form/fg-insurance-columns-form.component';
import { FgInsuranceService } from './fg-insurance/states/fg-insurance.service';
import { FgInsuranceUiStore } from './fg-insurance/states/fg-insurance-ui.store';
import { FgInsuranceUiQuery } from './fg-insurance/states/fg-insurance-ui.query';
import { FgInsuranceStore } from './fg-insurance/states/fg-insurance.store';
import { FgInsuranceQuery } from './fg-insurance/states/fg-insurance.query';
import { FgInsuranceResolver } from './fg-insurance/fg-insurance.resolver';

import { AdviceProcessComponent } from './advice-process/advice-process.component';
import { AdviceProcessFormComponent } from './advice-process/advice-process-form/advice-process-form.component';
import { AdviceProcessColumnsFormComponent } from './advice-process/advice-process-columns-form/advice-process-columns-form.component';
import { AdviceProcessService } from './advice-process/states/advice-process.service';
import { AdviceProcessUiStore } from './advice-process/states/advice-process-ui.store';
import { AdviceProcessUiQuery } from './advice-process/states/advice-process-ui.query';
import { AdviceProcessResolver } from './advice-process/advice-process.resolver';
import { AdviceProcessStore } from './advice-process/states/advice-process.store';
import { AdviceProcessQuery } from './advice-process/states/advice-process.query';

@NgModule({
  imports: [CommonModule, SharedModule, PipelineRoutingModule],
  declarations: [
    // L&R Insurnace
    LrInsuranceComponent,
    LrInsuranceFormComponent,
    LrInsuranceColumnsFormComponent,

    KiwisaverComponent,
    KiwisaverFormComponent,
    KiwisaverColumnsFormComponent,

    InvestmentComponent,
    InvestmentFormComponent,
    InvestmentColumnsFormComponent,

    MortgageComponent,
    MortgageFormComponent,
    MortgageColumnsFormComponent,
    // F&G Insurance
    FgInsuranceComponent,
    FgInsuranceFormComponent,
    FgInsuranceColumnsFormComponent,
    AdviceProcessComponent,
    AdviceProcessFormComponent,
    AdviceProcessColumnsFormComponent,
  ],
  providers: [
    // L&R Insurance
    LrInsuranceService,
    LrInsuranceUiStore,
    LrInsuranceUiQuery,
    LrInsuranceQuery,
    LrInsuranceResolver,
    LrInsuranceStore,

    // Mortgage
    MortgageService,
    MortgageUiStore,
    MortgageUiQuery,
    MortgageStore,
    MortgageQuery,
    MortgageResolver,

    // KiwiSaver
    KiwisaverService,
    KiwisaverUiStore,
    KiwisaverUiQuery,
    KiwisaverStore,
    KiwisaverQuery,
    KiwisaverResolver,

    // Investment
	 	InvestmentService,
	 	InvestmentUiStore,
	 	InvestmentUiQuery,
	 	InvestmentStore,
	 	InvestmentQuery,
	 	InvestmentResolver,

    // F&G Insurance
    FgInsuranceService,
    FgInsuranceUiStore,
    FgInsuranceUiQuery,
    FgInsuranceStore,
    FgInsuranceQuery,
    FgInsuranceResolver,

    // Advice Process
    AdviceProcessService,
    AdviceProcessUiStore,
    AdviceProcessUiQuery,
    AdviceProcessStore,
    AdviceProcessQuery,
    AdviceProcessResolver,
  ],
})
export class PipelineModule {}
