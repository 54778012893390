import {
	DropdownValue,
	DropdownValueUpdate,
	DropdownValueAdd,
	AllDropdownValues
} from '../../modules/dropdown/model/dropdownValue';
import { Observable } from 'rxjs';
import { ApiService, JsonResultStatus } from '../base/api.service';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { map, take, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { DropdownValueStore } from '@domain/dropdown-value/dropdown-value.store';

@Injectable()
export class DropdownValuesService {
	constructor(
		private api: ApiService,
		private configService: ConfigService,
		private store: DropdownValueStore,
	) {
		this.setupVariables();
	}

	private DVCsByCompany: [string, [string, Observable<DropdownValue[]>][]][];

	setupVariables() {
		this.DVCsByCompany = [];
	}

	getDropdownValuesCached: (
		code: string
	) => Observable<DropdownValue[]> = code =>
			this.configService.CompanyCode.pipe(
				take(1),
				map(x => {
					const companyDropdownsIndex = this.DVCsByCompany?.findIndex(
						y => y[0] === x
					);
					if (companyDropdownsIndex < 0) {
						const newLength = this.DVCsByCompany.push([x, []]);
						return this.DVCsByCompany[newLength - 1][1];
					} else { return this.DVCsByCompany[companyDropdownsIndex][1]; }
				}),
				mergeMap((x: [string, Observable<DropdownValue[]>][]) => {
					const DVIndex = x?.findIndex(index => index[0] === code);
					if (DVIndex > -1) {
						return x[DVIndex][1];
					} else {
						const newLength = x.push([
							code,
							this.getDropdownValues(code).pipe(shareReplay(1))
						]);
						return x[newLength - 1][1];
					}
				})
			)

	getDropdownValueList$(codes: string[]): Observable<DropdownValue[]> {
		const endpoint = `dropdown/dropdown-values?type=${codes}`;
		const body = {
			dropdownCodes: codes
		};
		return this.api.get<DropdownValue[]>(endpoint, body);
	}

	getDropdownValueListJson$(codes: string[]): Observable<DropdownValue[]> {
		const endpoint = `dropdown/settings/dropdown-values?type=${codes}`;
		const body = {
			dropdownCodes: codes
		};
		return this.api.get<DropdownValue[]>(endpoint, body);
	}

	getDropdownValues(code: string): Observable<DropdownValue[]> {
		return this.getDropdownValueList$([code]).pipe(
			map(x => x?.sort((a, b) => a.DropdownOrder - b.DropdownOrder))
		);
	}

	getDropdownValuesJson(code: string): Observable<DropdownValue[]> {
		return this.getDropdownValueListJson$([code]).pipe(
			map(x => x?.sort((a, b) => a.DropdownOrder - b.DropdownOrder))
		);
	}

	saveDropdownValueName(
		dropdown: DropdownValueUpdate,
		dCode: string
	): Observable<DropdownValue[]> {
		const endpoint = `dropdown/dropdown-values/${dropdown.DropdownValueID?.toString()}`;
		const body: {} = {
			DropdownValueID: dropdown.DropdownValueID,
			DropdownCode: dropdown.DropdownCode,
			DropdownValue: dropdown.DropdownValue,
			DropdownOrder: dropdown.DropdownOrder,
			IsDefault: dropdown.IsDefault,
			IsActive: true
		};

		return this.api
			.put<DropdownValue[]>(endpoint, body).pipe(
				tap(() => {
					this.store.remove(() => dCode);
				}),
				tap(() => {
					return this.removeCacheValue(dCode);
				}));
	}

	addDropdownValueName(
		dropdown: DropdownValueAdd,
		dCode: string
	): Observable<JsonResultStatus> {
		const endpoint = 'dropdown/dropdown-values';
		const body: {} = {
			DropdownCode: dropdown.DropdownCode,
			DropdownValue: dropdown.DropdownValue,
			DropdownOrder: dropdown.DropdownOrder,
			IsDefault: dropdown.IsDefault
		};
		return this.api.post3<JsonResultStatus>(endpoint, body).pipe(
			tap((res) => {
				this.store.remove(() => dCode);
			}),
			tap(() => this.removeCacheValue(dCode)));
	}

	UpdateDropdownValues(
		dropdowns: AllDropdownValues,
		dCode: string
	): Observable<JsonResultStatus> {
		const endpoint = `dropdown/dropdown-values`;
		const body: {} = {
			ListDropdownValue: dropdowns.dropdown_values
		};
		return this.api
			.put<JsonResultStatus>(endpoint, body).pipe(
				tap(() => {
					this.store.remove(() => dCode);
				}),
				tap(() => this.removeCacheValue(dCode)),
			);
	}

	DeleteDropdownValue(
		id: number,
		newId: number,
		dCode: string
	): Observable<any> {
		const endpoint = `dropdown/dropdown-values/${+id}/${+newId}`;
		return this.api.delete(endpoint).pipe(
			tap(() => {
				this.store.remove(() => dCode);
			}),
			tap(x => this.removeCacheValue(dCode))
		);
	}

	private removeCacheValue: (dCode: string) => Observable<any> = dCode => this.configService.CompanyCode.pipe(
		take(1),
		map(x => {
			const companyDropdownsIndex = this.DVCsByCompany?.findIndex(
				y => y[0] === x
			);

			if (companyDropdownsIndex < 0) {
				const newLength = this.DVCsByCompany.push([x, []]);
				return this.DVCsByCompany[newLength - 1][1];
			} else {
				return this.DVCsByCompany[companyDropdownsIndex][1];
			}
		}),
		map(x => {
			const iToRemove = x?.findIndex(y => y[0] === dCode);
			x?.splice(iToRemove, 1);
		}),
	)
}

