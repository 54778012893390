<div class="card-header font-weight-bold text-light tap-bg-primary">
  {{ header }}
</div>
<div class="card-body p-4" id="moat">
  <ng-container [ngSwitch]="message">
    <ng-container *ngSwitchCase="'Property'">
      <app-moat-property-form
        [propertyUse]="su$ | async"
        [securityValuation]="sv1$ | async"
        [type]="st$ | async"
        [title]="sti$ | async"
        [rentalFrequencyChoices]="apcrtf$ | async"
        [rateFrequencyChoices]="apcrtf$ | async"
        [insuranceFrequencyChoices]="apcrtf$ | async"
        [otherExpenseFrequencyChoices]="apcrtf$ | async"
        [policyOwners]="policyOwners"
        [propertyInfo]="crtInfo"
        [isLoading]="isLoading"
        (saveEvent)="save($event)"
        (cancelEvent)="cancel()"
        [getOwnerChoices]="getOwnerChoices"
      >
      </app-moat-property-form>
    </ng-container>
    <ng-container *ngSwitchCase="'Property Purchase'">
      <app-property-purchase-form
        [propertyUse]="su$ | async"
        [securityValuation]="sv1$ | async"
        [type]="st$ | async"
        [title]="sti$ | async"
        [rentalFrequencyChoices]="apcrtf$ | async"
        [rateFrequencyChoices]="apcrtf$ | async"
        [insuranceFrequencyChoices]="apcrtf$ | async"
        [otherExpenseFrequencyChoices]="apcrtf$ | async"
        [policyOwners]="policyOwners"
        [propertyInfo]="crtInfo"
        [isLoading]="isLoading"
        [getOwnerChoices]="getOwnerChoices"
        (saveEvent)="save($event)"
        (cancelEvent)="cancel()"
      >
      </app-property-purchase-form>
    </ng-container>
    <ng-container *ngSwitchCase="'KiwiSaver'">
      <app-moat-kiwisaver-form
        [provider]="kp$ | async"
        [riskProfile]="apcrtrp$ | async"
        [kiwiSaverInfo]="crtInfo"
        [fromCRM]="crtInfo?.FromCRM"
        [lifeAssuredList]="lifeAssuredList$ | async"
        [fundType]="kft$ | async"
        [isLoading]="isLoading"
        (saveEvent)="save($event)"
        (cancelEvent)="cancel()"
        [getOwnerChoices]="getOwnerChoices"
      >
      </app-moat-kiwisaver-form>
    </ng-container>
    <ng-container *ngSwitchCase="'Other'"> </ng-container>
    <ng-container *ngSwitchCase="'Mortgages'">
      <app-moat-mortgages-form
        [lender]="mp$ | async"
        [loanType]="mlt$ | async"
        [mortgageFrequency]="apcrtf$ | async"
        [borrowers]="borrowers | async"
        [securities]="securities | async"
        [mortgageInfo]="crtInfo"
        [isLoading]="isLoading"
        (saveEvent)="save($event)"
        (cancelEvent)="cancel()"
        [getOwnerChoices]="getOwnerChoices"
      ></app-moat-mortgages-form>
    </ng-container>
    <ng-container *ngSwitchCase="'Liabilities'">
      <app-moat-liabilities-form
        [lender]="mp$ | async"
        [loanTypes]="mlt$ | async"
        [mortgageFrequency]="apcrtf$ | async"
        [agree]="apcrtynna$ | async"
        [liabilities]="apcrtl$ | async"
        [borrowers]="borrowers | async"
        [securities]="securities | async"
        [liabilityInfo]="crtInfo"
        [isLoading]="isLoading"
        (saveEvent)="save($event)"
        (cancelEvent)="cancel()"
        [getOwnerChoices]="getOwnerChoices"
      >
      </app-moat-liabilities-form>
    </ng-container>
  </ng-container>
</div>
