<div class="user-detail-documents__specific container-fluid">
	<div class="row mt-4 mb-2 px-1">
		<div class="col-12 px-2">
			<span class="section-header tap-text-primary"
				>User Specific Documents</span
			>
		</div>
	</div>
	<div class="row">
		<div class="col-12 user-detail-documents__specific__cta my-1 px-3">
			<button
				*appRestrictTo="['BEU']"
				type="button"
				class="btn ui-btn-gray mr-3 px-4"
				id="addUserSpecificDocument"
				(click)="addDocument()"
				[disabled]="isLoading"
			>
				Add Document
			</button>
			<app-manual-loader-mini [isLoading]="isLoading"> </app-manual-loader-mini>
		</div>
	</div>
	<div class="row user-detail-documents__specific__documents mb-5">
		<div class="col-12 p-0">
			<div class="container-fluid p-0" *ngIf="!!dataSource">
				<app-simple-data-table
					[dataSource]="dataSource"
					[displayedColumns]="displayedColumns"
					[tableColumns]="tableColumns"
					[itemPerPage]="10"
					[enablePagination]="true"
					[class.disabled]="!!isLoading"
				></app-simple-data-table>
			</div>
		</div>
	</div>
</div>
