<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="close()">
	<span class="material-icons">
		close
	</span>
</button>

<div class="modal-body">
	<app-activity-form-dumb
		[AT]="AT$ | async"
		[AM]="AM$ | async"
		[advisers]="adviserChoices$ | async"
		[adviserCalendars]="adviserCalendarChoices$ | async"
		[isSaving]="isLoading"
		[hideClient]="hideClient"
		(saveEvent)="transfer($event)"
		(cancelEvent)="closeModal()"
		[initialState]="formItem"
		[isEditForm]="isEditForm"
		[isModal]="isModal"
		[isRequiredDate]="isRequiredDate"
		[permissionsToComplete]="permissionsToComplete"
		[isAR]="isActivityReferral"
		[isBulkTransfer]="true"
		[showCancelButton]="true"
		customSaveButton="Transfer"
	></app-activity-form-dumb>
</div>
