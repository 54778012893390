export let Kiwisaver = [
  'Fund Owner',
  'Provider',
  'Status',
  'Member Number',
  'Start Date',
  'Fund Type',
  'IRD Number',
  'Contribution',
  'Origin', 
  'Import ID',
  'Customer Import ID',
  'Original Adviser Import ID',
  'PIR Rate',
  'FUM'
];
