import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ClientReferralSettingReferralState } from './client-referral-setting.model';
import { ClientReferralSettingStore } from './client-referral-setting.store';

@Injectable({ providedIn: 'root' })
export class ClientReferralSettingQuery extends QueryEntity<ClientReferralSettingReferralState> {
	constructor(protected store: ClientReferralSettingStore) {
		super(store);
	}
}
