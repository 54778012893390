import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/domain/user/user.service';
import { Location } from '@angular/common';

@Injectable()
export class CrtPageGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private location: Location,
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const code = next.paramMap.get('companyCode');
		return this.userService.getValidateSecurityRole('FCRT').pipe(
			switchMap((x) => {
				if (!x) {
					const clientId = state?.url?.split('/');
					const link =
						this.router.url.length > 1 && this.router.url.length
							? this.router.url?.substring(1)
							: `${code}/client/${clientId[5]}`;

					this.router.navigateByUrl(link);
				}
				return !!x ? [true] : [false];
			}),
			catchError((err) => {
        this.router.navigate(['/error'], { queryParams: { url: this.location.path() }});
        return of(err);
      })
		);
	}
}
