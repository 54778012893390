import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import {
	catchError,
	delay,
	finalize,
	map,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import {
	MoatSidebarWarning,
	MortgageAdviceProcessPageIds,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { CompanyState } from 'src/app/shared/models/client-review-template/company/company.model';
import { DependentDetailsState } from 'src/app/shared/models/client-review-template/dependent/dependent-details';
import {
	Dependent,
	DependentState,
} from 'src/app/shared/models/client-review-template/dependent/dependent.model';
import {
	MOATPeopleDetails,
	MOATPeopleDetailsState,
	PeopleDetails,
} from 'src/app/shared/models/client-review-template/people/people-details.model';
import {
    GroupedPeopleState,
	People,
	PeopleState,
} from 'src/app/shared/models/client-review-template/people/people.model';
import { TrustDetailsState } from 'src/app/shared/models/client-review-template/trust/trust-details';
import { TrustState } from 'src/app/shared/models/client-review-template/trust/trust.model';
import { objectUtil } from 'src/app/util/util';
import { SidebarStatus } from '../../../../_shared/models/sidebar.model';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../../state/crt-mortgage.service';
import { CrtMortgageStore } from '../../../state/crt-mortgage.store';
import { PeopleEntitiesQuery } from './people-entities.query';

@Injectable({
	providedIn: 'root',
})
export class PeopleEntitiesService extends CrtMortgageService {
	people$ = this.peopleEntitiesQuery.people$;
	dependants$ = this.peopleEntitiesQuery.dependents$;
	trusts$ = this.peopleEntitiesQuery.trusts$;
	company$ = this.peopleEntitiesQuery.company$;
	linkedContactsNoFormat$ = this.peopleEntitiesQuery.linkedContactsNoFormat$;
	linkedContacts$ = this.peopleEntitiesQuery.linkedContacts$;

	policyOwnersWithCRT$ = this.peopleEntitiesQuery.policyOwnersWithCRT$;
	peopleFromCrmAndCrtChoices$ =
		this.peopleEntitiesQuery.peopleFromCrmAndCrtChoices$;
	peopleFromCrmAndCrtExceptChildChoices$ =
		this.peopleEntitiesQuery.peopleFromCrmAndCrtExceptChildChoices$;
	pciAndSciFromCrmAndCrtChoices$ =
		this.peopleEntitiesQuery.pciAndSciFromCrmAndCrtChoices$;

	pciAndSciFromCrmAndCrtChoicesWithBusinessTrust$ =
		this.peopleEntitiesQuery.pciAndSciFromCrmAndCrtChoicesWithBusinessTrusts$;

	deceasedSciList$ = this.peopleEntitiesQuery.deceasedSciList$;

	constructor(
		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected peopleEntitiesQuery: PeopleEntitiesQuery
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	getPersonInfo(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api
			.get<PeopleDetails>(endpoint)
			.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
	}

	updatePeopleInfo(people: MOATPeopleDetailsState) {
		applyTransaction(() => {
			const data = this.query.getValue().people?.map((y) => {
				const age = moment().diff(people.dateOfBirth, 'years');
				return y.cRTId === people.cRTId
					? {
							customerId: people.customerID,
							age: people.dateOfBirth ? (age < 0 ? 0 : age) : null,
							birthDate: people.dateOfBirth,
							cRTId: people.cRTId,
							customerType: people.customerType,
							name:
								people.firstName && people.lastName
									? people.firstName?.concat(' ', people.lastName)
									: null,
							relationship: people.relationship,
					  }
					: y;
			});
			this.store.setPeople(data);
		});
	}

	getPeople(adviceProcessId: number, sectionCode: string, noLoaders?: boolean) {
		if (!noLoaders) {
			this.store.setPeopleIsLoading(true);
		}
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<People[]>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? (x?.map(objectUtil.mapPascalCaseToCamelCase) as PeopleState[])
						: [];
					this.store.setPeople(state);
				})
			),
			catchError(() => of(undefined)),
			finalize(() => this.store.setPeopleIsLoading(false))
		);
	}

	addPeople(people: MOATPeopleDetailsState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(people);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = moment().diff(people.dateOfBirth, 'years');
					const data = [
						...this.query.getValue().people,
						{
							customerId: people.customerID,
							age: people.dateOfBirth ? (age < 0 ? 0 : age) : null,
							birthDate: people.dateOfBirth,
							cRTId: x,
							customerType: people.customerType,
							name:
								people.firstName && people.lastName
									? people.firstName?.concat(' ', people.lastName)
									: null,
							relationship: people.relationship,
						},
					]?.filter((y) => y.cRTId && y.cRTId !== 0);
					this.store.setPeople(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	 updatePeople(people: MOATPeopleDetailsState) {
		const endpoint = `crt/${people.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(people);

		const {
			customerID,
			cRTId,
			customerType,
			relationship,
			title,
			firstName,
			middleName,
			lastName,
			knownAs,
			gender,
			dateOfBirth,
			residencyStatus,
			mobilePhone,
			homePhone,
			workPhone,
			email,
			physicalAddress,
			timeInResidencePhysicalAddress,
			previousAddress,
			timeInResidencePreviousAddress,
			maritalStatus,
			countryOfOrigin,
		} = people;

		const peopleDetails = {
			customerID,
			cRTId,
			customerType,
			relationship,
			title,
			firstName,
			middleName,
			lastName,
			fullLegalName: `${firstName} ${middleName} ${lastName}`,
			knownAs,
			gender,
			dateOfBirth,
			birthDate: dateOfBirth,
			residencyStatus,
			mobilePhone,
			mobile: mobilePhone,
			homePhone,
			workPhone,
			work: workPhone,
			email,
			physicalAddress,
			timeInResidencePhysicalAddress,
			previousAddress,
			timeInResidencePreviousAddress,
			maritalStatus,
			countryOfOrigin,
		};

		return this.api.put<MOATPeopleDetails>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					// PEOPLE
					const age = moment().diff(dateOfBirth, 'years');
					const data = this.query.getValue().people?.map((y) =>
						y.cRTId === cRTId
							? {
									customerId: customerID,
									age: dateOfBirth ? (age < 0 ? 0 : age) : null,
									birthDate: dateOfBirth,
									dateOfBirth,
									cRTId,
									customerType,
									name:
										firstName && lastName
											? firstName?.concat(' ', lastName)
											: null,
									relationship,
									email: people.email,
							  }
							: y
					);
					this.store.setPeople(data);

					// PRIMARY CLIENT
					const primaryClient = this.query.getValue().primaryClient;
					if (people.customerID === primaryClient.customerID) {
						const clientData = {
							...primaryClient,
							...peopleDetails,
							age: dateOfBirth ? (age < 0 ? 0 : age) : null,
						};

						this.store.setPrimaryClient(clientData);
					}

					// SECONDARY CONTACTS
					const secondaryClients = this.query.getValue().secondaryClients;
					const secondaryClientsData = secondaryClients.map((client) => {
						if (people.customerID === client.customerID) {
							return {
								...client,
								...peopleDetails,
								age: dateOfBirth ? (age < 0 ? 0 : age) : null,
							};
						}
						return client;
					});

					this.store.setSecondaryClients(secondaryClientsData);

					// LINKED CONTACTS
					const linkedContacts = this.query.getValue().linkedContacts;
					const linkedContactsData = linkedContacts.map((contact) => {
						if (people.customerID === contact.relatedCustomerId) {
							return {
								...contact,
								...peopleDetails,
								age: dateOfBirth ? (age < 0 ? 0 : age) : null,
								name: `${firstName} ${lastName}`,
							};
						}
						return contact;
					});

					this.store.setLinkedContacts(linkedContactsData);
				})
			)
		);
	}

	deletePeople(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<MOATPeopleDetails>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.people?.filter((y) => y.cRTId !== id);
					this.store.setPeople(data);
				});
			})
		);
	}

	getDependents(
		adviceProcessId: number,
		sectionCode: string,
		forceFetch?: boolean,
		noLoaders?: boolean
	) {
		if (!noLoaders) {
			this.store.setDependentsIsLoading(true);
		}
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return !forceFetch && !!this.query.getValue().dependents
			? of(undefined).pipe(
					finalize(() => this.store.setDependentsIsLoading(false))
			  )
			: this.api.get<Dependent[]>(endpoint).pipe(
					tap((x) =>
						applyTransaction(() => {
							const state = x
								? (x?.map(
										objectUtil.mapPascalCaseToCamelCase
								  ) as DependentState[])
								: [];
							this.store.setDependents(state);
						})
					),
					catchError(() => of(undefined)),
					finalize(() => this.store.setDependentsIsLoading(false))
			  );
	}

	setDependentsIsLoading(value) {
		this.store.setDependentsIsLoading(value);
	}

	addDependent(dependent: DependentDetailsState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(dependent);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = moment().diff(dependent.dateOfBirth, 'years');
					const data = [
						...this.query.getValue().dependents,
						{
							customerId: dependent.customerID,
							age: dependent.dateOfBirth ? (age < 0 ? 0 : age) : null,
							birthDate: dependent.dateOfBirth,
							cRTId: +x,
							name:
								dependent.firstName && dependent.lastName
									? dependent.firstName?.concat(' ', dependent.lastName)
									: null,
							relationship: dependent.relationship,
						},
					]?.filter((y) => y.cRTId && y.cRTId !== 0) as DependentState[];
					this.store.setDependents(data);
				})
			)
		);
	}

	deleteDependent(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<string>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.dependents?.filter((y) => y.cRTId !== id);
					this.store.setDependents(data);
				});
			})
		);
	}

	updateDependent(dependent: DependentDetailsState) {
		const endpoint = `crt/${dependent.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(dependent);
		return this.api.put<DependentDetailsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const age = moment().diff(dependent.dateOfBirth, 'years');
					const data = this.query.getValue().dependents?.map((y) =>
						y.cRTId === dependent.cRTId
							? {
									customerId: dependent.customerID,
									age: dependent.dateOfBirth ? (age < 0 ? 0 : age) : null,
									birthDate: dependent.dateOfBirth,
									cRTId: dependent.cRTId,
									name:
										dependent.firstName && dependent.lastName
											? dependent.firstName?.concat(' ', dependent.lastName)
											: null,
									relationship: dependent.relationship,
							  }
							: y
					) as DependentState[];
					this.store.setDependents(data);
				})
			)
		);
	}

	getTrusts(
		adviceProcessId: number,
		sectionCode: string,
		forceFetch?: boolean
	) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		this.store.setTrustsIsLoading(true);
		return !forceFetch && !!this.query.getValue().trusts
			? of(undefined).pipe(
					finalize(() => this.store.setDependentsIsLoading(false))
			  )
			: this.api.get<any>(endpoint).pipe(
					tap((x) =>
						applyTransaction(() => {
							const state = x
								? (x?.map(objectUtil.mapPascalCaseToCamelCase) as any)
								: [];
							this.store.setTrust(state);
						})
					),
					catchError(() => of(undefined)),
					finalize(() => this.store.setTrustsIsLoading(false))
			  );
	}

	addTrust(trust: any, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(trust);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().trusts,
						{
							customerId: trust.customerID,
							cRTId: +x,
							name: trust.trustName,
						},
					]?.filter((y) => y.cRTId && y.cRTId !== 0) as TrustState[];
					this.store.setTrust(data);
				})
			)
		);
	}

	updateTrust(trust: TrustDetailsState) {
		const endpoint = `crt/${trust.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(trust);
		return this.api.put<TrustDetailsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().trusts.map((y) =>
						y.cRTId === trust.cRTId
							? {
									customerId: trust.customerID,
									age: null,
									birthDate: null,
									cRTId: trust.cRTId,
									name: trust.trustName,
							  }
							: y
					) as TrustState[];
					this.store.setTrust(data);
				})
			)
		);
	}

	deleteTrust(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<string>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.trusts?.filter((y) => y.cRTId !== id);
					this.store.setTrust(data);
				});
			})
		);
	}

	getCompany(
		adviceProcessId: number,
		sectionCode: string,
		forceFetch?: boolean
	) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		this.store.setCompanyIsLoading(true);
		return !forceFetch && !!this.query.getValue().company
			? of(undefined).pipe(
					finalize(() => this.store.setDependentsIsLoading(false))
			  )
			: this.api.get<any>(endpoint).pipe(
					tap((x) =>
						applyTransaction(() => {
							const state = x
								? (x?.map(objectUtil.mapPascalCaseToCamelCase) as any)
								: [];
							this.store.setCompany(state);
						})
					),
					catchError(() => EMPTY),
					finalize(() => this.store.setCompanyIsLoading(false))
			  );
	}

	addCompany(company: any, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(company);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().company,
						{
							customerId: company.customerID,
							cRTId: +x,
							name: company.businessName,
							tradingName: company.tradingName,
							industry: company.industry,
						},
					]?.filter((val) => val.cRTId && val.cRTId !== 0) as CompanyState[];
					this.store.setCompany(data);
				})
			)
		);
	}

	updateCompany(company: any) {
		const endpoint = `crt/${company.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(company);
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().company?.map((y) =>
						y.cRTId === company.cRTId
							? {
									customerId: company.customerID,
									cRTId: company.cRTId,
									name: company.businessName,
									tradingName: company.tradingName,
									industry: company.industry,
							  }
							: y
					) as CompanyState[];
					this.store.setCompany(data);
				})
			)
		);
	}

	deleteCompany(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<string>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.company?.filter((y) => y.cRTId !== id);
					this.store.setCompany(data);
				});
			})
		);
	}

	/**
	 * Update Sidebar > People Tab: Color status
	 * @returns update of sidebar status
	 */
	setPeopleTabColor() {
		return this.people$.pipe(
			delay(10),
			map((people) =>
				people?.length > 0 ? SidebarStatus.Completed : SidebarStatus.Incomplete
			),
			tap((status) => {
				const warningMsg =
					status === SidebarStatus.Incomplete
						? MoatSidebarWarning.people
						: null;
				this.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.People,
					false,
					status,
					warningMsg
				);
			})
		);
	}

	getGroupedPeople(customerId: number): Observable<GroupedPeopleState> {
		const endpoint = `/contacts/group/${customerId}`;
		return this.api.get<GroupedPeopleState>(endpoint).pipe(
			map((result) => {
				const mapEntities = <T>(entities: T[]): T[] =>
					entities?.map(
						(entity) => objectUtil.mapPascalCaseToCamelCase(entity) as T
					) ?? [];

				this.setPrimaryClient(objectUtil.mapPascalCaseToCamelCase(result.PCI));

				const secondaryClients = mapEntities(result?.SCI).filter(
					(y) => +y.isActive === 1
				);
				this.store.setSecondaryClients(secondaryClients);

				const secondaryTrust = mapEntities(result?.SCT);
				this.store.setSecondaryTrusts(secondaryTrust);

				const secondaryCompanies = mapEntities(result?.PCC);
				this.store.setSecondaryCompanies(secondaryCompanies);

				const secondaryProfessionals = mapEntities(result?.SCP);
				this.store.setSecondaryProfessionals(secondaryProfessionals);

				const linkedContacts = mapEntities(result?.LC);
				this.store.setLinkedContacts(linkedContacts);

				return result;
			})
		);
	}

}
