import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ApplicationStore, ApplicationState } from './application.store';

@Injectable({ providedIn: 'root' })
export class ApplicationQuery extends QueryEntity<ApplicationState> {
  isUpdatedLoans$ = this.select((state) => state.isUpdatedLoans);

  constructor(protected store: ApplicationStore) {
    super(store);
  }

}
