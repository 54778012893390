import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import {
  DocumentModelState,
  DocumentUploadState,
} from '../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../util/util';
import { ApiService } from '../../../../../core/base/api.service';
import { MortgageSettingsQuery } from '../../../state/mortgage-settings.query'
import { MortgageSettingsStore } from '../../../state/mortgage-settings.store'
import { MoatSoaEmailSettingsState } from './moat-soa-email-settings.model';
import { SettingsTypes } from '../../../state/mortgage-settings.model';

@Injectable()
export class MoatSoaEmailSettingsService {

  moatSoaEmailSettings$ = this.query.statementOfAdiceEmailSettings$;

  constructor(
    private api: ApiService,
    private store: MortgageSettingsStore,
    private query: MortgageSettingsQuery
  ) {}

  getEmailSettings(data: number, settingsCode: SettingsTypes): any {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<MoatSoaEmailSettingsState>(endpoint).pipe(
      tap((x) => {
        applyTransaction(() => {
          const state = x ? objectUtil.mapPascalCaseToCamelCase(x) : {};
          this.store.setStatementOfAdviceEmailSettings(state);
        });
      }),
      catchError(() => of({}))
    );
  }

  updateSettings(data: MoatSoaEmailSettingsState) {
    const newValue = {
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<MoatSoaEmailSettingsState>(endpoint, body).pipe(
      tap(() => applyTransaction(() => this.store.setStatementOfAdviceEmailSettings(newValue))),
      take(1)
    );
  }

  newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
