import { Component, OnInit, OnDestroy } from '@angular/core';
import {
	DocumentService
} from './states/document.service';

import { ViewCustomerDocument } from '../../../shared/models/_general/customer-document.viewmodel';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { Subject } from 'rxjs';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { DocumentQuery } from './states/document.query';
import { takeUntil } from 'rxjs/operators';
import { CustomerDocument } from './states/document.model';
import { CRMDocumentTypeCodes, GetCRMDocumentViewDisplay } from 'src/app/shared/models/documents/document.model';

@Component({
	selector: 'app-document',
	templateUrl: './document.component.html',
	styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	types: ViewDisplayValue[];
	activeDocType: string;
	documentTypeCodes = CRMDocumentTypeCodes;

	constructor(
		public service: DocumentService,
		public query: DocumentQuery,
	) {
		this.types = GetCRMDocumentViewDisplay();
	}

	adDocuments$ = this.query.adDocuments$;
	odDocuments$ = this.query.odDocuments$;
	fapDocuments$ = this.query.fapDocuments$;
	xdDocuments$ = this.query.xdDocuments$;

	ngOnInit() {
		this.activeDocType = CRMDocumentTypeCodes.Office;
	}

	onSelect(data: TabDirective) {
		this.activeDocType = data.id;
	}

	delete(doc: ViewCustomerDocument) {
		this.service.deleteDocument(doc, this.activeDocType).pipe(takeUntil(this.onDestroy$)).subscribe();
	}

	transfer({ doc, code }: { doc: ViewCustomerDocument; code: string }) {
		const reqDoc = {
			...doc,
			CreateDateTime: doc.CreateDateTime?.toString(),
			DocumentTypeCode: doc.DocumentTypeCode,
			ModifiedDateTime: doc.ModifiedDateTime?.toString(),
		} as CustomerDocument;

		this.service.transfer(reqDoc, code).pipe(takeUntil(this.onDestroy$)).subscribe();
	}

	uploadDocFn$ = (req: { doc: FormData; doctype: string; }) => {
		return this.service.uploadDocument(req);
	}

	downloadLink$ = ({ documentID }) => {
		return this.service.downloadLink(documentID);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

}
