import { MergeTagTypeCode } from '../../merge-tags.model';

export const reviewApplicationMetaKey = {
	// People Primary Income
	peopleReference: 'PEOPLE_NAME_WITH_REFERENCE',
	peoplePrimaryIncome: 'INCOME_PRIMARY_PEOPLE',

	// Client Declaration
	peopleFirstName: 'PEOPLE_FIRST_NAME',
	peopleGender: 'PEOPLE_GENDER',
	peopleAge: 'PEOPLE_AGE',
	peopleIncomePrimaryKiwi: 'INCOME_PRIMARY_PEOPLE_KIWISAVER',
	peopleFirstCombined: 'PEOPLE_FIRST_NAME_COMBINED',
	peoplePossessivePronoun: 'CLIENT_DECLARATION_PEOPLE_POSSESSIVE_PRONOUN',
	peoplePersonalPronoun: 'CLIENT_DECLARATION_PEOPLE_PERSONAL_PRONOUN',
	peopleLinkingVerb: 'CLIENT_DECLARATION_PEOPLE_LINK_VERB',

	// Exit Strategy
	exitStrategyPeopleAge: 'APPLICATION_EXIT_STRATEGY_PEOPLE_AGE',
	exitStrategyPeopleKiwi: 'APPLICATION_EXIT_STRATEGY_PEOPLE_KIWISAVER',

	liabilityRepayment: 'LIABILITY_REPAYMENT',
	// Totals the LIABILITY_REPAYMENT and multiplied by 12
	totalAnnualLiabilityRepayment: 'TOTAL_LIABILITY_REPAYMENT',
};

export const reviewApplicationMergeTag = [
	{
		metaKey: reviewApplicationMetaKey.peopleFirstCombined,
		description: 'Fact Find > People > First Names combined with &',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: 'John & Jane',
	},
	{
		metaKey: reviewApplicationMetaKey.peoplePossessivePronoun,
		description: 'MOAT > Review Application > People Possessive Pronoun',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: 'their/her/his',
	},
	{
		metaKey: reviewApplicationMetaKey.peoplePersonalPronoun,
		description: 'MOAT > Review Application > People Possessive Pronoun',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: 'they/she/he',
	},
	{
		metaKey: reviewApplicationMetaKey.peopleLinkingVerb,
		description: 'MOAT > Review Application > People Possessive Pronoun',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: 'are/is',
	},
	{
		metaKey: reviewApplicationMetaKey.exitStrategyPeopleAge,
		description: 'MOAT > Review Application > Exit Strategy (People & Age)',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: 'Mark is 44 & Caitlin is 42',
	},
	{
		metaKey: reviewApplicationMetaKey.exitStrategyPeopleKiwi,
		description: 'MOAT > Review Application > Exit Strategy (KiwiSaver)',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue:
			'Mark contributes to KiwiSaver at 4% & Caitlin contributes to KiwiSaver at 6%',
	},
	{
		metaKey: reviewApplicationMetaKey.totalAnnualLiabilityRepayment,
		description:
			'MOAT > Review Application > Totals the liability repayment amount and multipled by 12',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: '$123',
	},
];
