import {
	ChangeDetectorRef,
	Component,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject, Subscription, throwError, zip } from 'rxjs';
import {
	delay,
	filter,
	map,
	mergeMap,
	take,
	tap,
	catchError,
	finalize,
	switchMap,
} from 'rxjs/operators';
import * as R from 'ramda';
import { FinalStructureDocumentMapper } from '../../../../modules/crt-settings/final-structure-settings/state/final-structure-settings.mapper';
import { DisclosureDocumentMapper } from '../../../../shared/models/client-review-template/disclosure-document/disclosure-document.mapper';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { objectUtil } from '../../../../util/util';
import { FinalStructureSettingsService } from '../../../../modules/crt-settings/final-structure-settings/state/final-structure-settings.service';
import {
	Attachment,
	CombinedAttachment,
} from '../../../../shared/models/_general/attachment.model';
import { DocumentState } from '../../../../shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { BusinessConfig } from 'src/app/domain/business-config/business-config.model';
import { ServiceAdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { CrtDocumentService } from 'src/app/modules/crm/crt-page/_shared/service/crt-document.service';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { EmailFormComponent } from './email-form/email-form.component';
import { HtmlPdfConfigState } from '@modules/crm/crt-page/_shared/service/html-pdf/defaults-config';

export enum EmailModalResult {
	Sent,
	Next,
	Cancel
}

@Component({
	selector: 'app-email',
	templateUrl: './email-modal.component.html',
	styleUrls: ['./email-modal.component.scss'],
})
export class EmailModalComponent implements ConfirmModalModel, OnInit {
	saveEmailFn$: (model: any) => Observable<any>;
	sendEmailFn$: (model: any) => Observable<any>;
	downloadOfferTermsFn$: (model: any) => Observable<any>;
	beforeSendFn$: (model: any) => Observable<any>;
	updateMTOnRecepientChange$: (provider: string) => Observable<MergeTagState[]>;
	generateFinalisedEmailTemplateFn$: Observable<any>;
	decline$: Observable<any>;
	next$: Observable<any>;
	sent$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	emailSettings$: Observable<any>;
	emailDocumentTemplateBase64$: Observable<string>;
	emailDocumentTemplate: string;
	bodyContent = '<p></p>';
	shortCodes = null;
	peopleDropdown: Observable<any>;
	peopleList: ViewDisplayValue[];
	showEmailTemplate: boolean;
	bodyId: number;
	attachments: Attachment[];
	combinedAttachments: Observable<CombinedAttachment[]>;
	successMessage: string;
	attachFiles = true;
	offerTerms: DocumentState[];
	businessConfig$: Observable<BusinessConfig>;
	adviceProcess: ServiceAdviceProcessState;
	defaultLinkDocumentTab: string;
	disableEmailUpdate = false;
	showNextBtn = false;
	sendToLabel = 'To';
	initFormValue = null;
	getCrtInfoFn: (value: any) => Observable<any>;
	showAttachments = false;
	clientDocumentsLoader: () => Observable<any>;
	onModalClosed: () => {};

	attachmentsLoader$: () => Observable<Attachment[]>;
	isLoading = false;
	ccAdvisers: boolean;
	enableDefaultBcc: boolean;
	isCompany: boolean;

	primaryClient: any;

	mergeTags$: Observable<MergeTagState[]>;

	@ViewChild(EmailFormComponent) emailFormComponent: EmailFormComponent;

	documentInfo: any;

	isEmailCAR = false;

	attachloaderIsLoading = false;

	nextLabel = 'Next';

	aotAttachmentGenerationState$ = new BehaviorSubject<'idle' | 'inprogress' | 'done'>('idle');

	onCAREmailSavedFn$: (data: any) => Observable<any>;

	aotAttachmentGenerationSub = new Subscription();

	get showAttachmentUI(): boolean {
		return (
			Boolean(this.attachments?.length) ||
			this.showAttachments ||
			this.attachloaderIsLoading
		);
	}

	constructor(
		public bsModalRef: BsModalRef,
		private fsService: FinalStructureSettingsService,
		private crtDocService: CrtDocumentService,
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.prepData();

		if (this.isEmailCAR) {
			this.peopleDropdown.pipe(take(1)).subscribe((data) => {
				const pList = data;
				if (!R.any(R.propEq('display', 'Other'))(pList)) {
					pList.push({ display: 'Other', value: 'Other' });
				}
				this.peopleList = pList;
			});
		} else {
			this.peopleDropdown
				.pipe(
					map((data) => DisclosureDocumentMapper.mapCRTPeopleToEmailDd(data)),
					take(1)
				)
				.subscribe((data) => {
					this.peopleList = data;
				});
		}

		if (this.attachmentsLoader$) {
			this.attachloaderIsLoading = true;
			this.cdr.detectChanges();
			this.attachmentsLoader$()
				.pipe(finalize(() => (this.attachloaderIsLoading = false)))
				.subscribe((attachments) => {
					this.cdr.detectChanges();
					if (!attachments?.length) {
						return;
					}
					this.attachments.push(...attachments);

					const attachmentWithAOTGeneration = this.attachments.filter((a) => a.aheadOfTimeContentGeneration$);
					if (attachmentWithAOTGeneration?.length) {
						this.initializeAOTContentGeneration(attachmentWithAOTGeneration);
					}
				});
		}
	}

	ngOnDestroy(): void {
		this.aotAttachmentGenerationSub.unsubscribe();
	}

	private initializeAOTContentGeneration(attachments: Attachment[]): void {
		this.aotAttachmentGenerationState$.next('inprogress');
		const attachmentObs = attachments.map((a) => a.aheadOfTimeContentGeneration$.pipe(
			tap((content) => a.content = content)
		));
		const sub$ = zip(...attachmentObs)
			.pipe(
				finalize(() => {
					this.aotAttachmentGenerationState$.next('done');
				})
			).subscribe();
		this.aotAttachmentGenerationSub.add(sub$);
	}

	prepData() {
		combineLatest([this.emailSettings$])
			.pipe(
				filter(([data]) => !!data),
				tap(([data]) => (this.bodyId = +data?.body || 0)),
				map(([data]) =>
					FinalStructureDocumentMapper.mapEmailSettingsData(data)
				),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.fsService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.fsService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				catchError((err) => {
					return throwError(err);
				}),
				take(1)
			)
			.subscribe();
	}

	sent() {
		this.bsModalRef.hide();
		this.sent$?.pipe(take(1)).subscribe();
	}

	decline() {
		if (!!this.decline$) {
			this.decline$.pipe(take(1)).subscribe();
		}
		this.bsModalRef.hide();
	}

	next(): void {
		this.bsModalRef.hide();
		this.next$?.pipe(take(1)).subscribe();
	}

	downloadPdfAttachment(attachment: Attachment) {
		if (attachment?.allowDownloadCombine && attachment.combineDocumentInfo) {
			this.downloadCombineAttachments(attachment);
			return;
		}
		if (attachment.generateContentCallback$) {
			attachment.generateContentCallback$
				.pipe(
					delay(100),
					mergeMap((content) =>
						this.renderPdfDownload(content, attachment.newPdfOptions)
					),
					take(1)
				)
				.subscribe();
		}

		if (attachment.fileUrl) {
			this.downloadFile(attachment.fileUrl, attachment.fileName);
		}
	}

	downloadFile(fileUrl: any, fileName: string) {
		this.crtDocService
			.getDocumentLink(fileUrl, {
				responseType: 'blob',
			})
			.pipe(
				tap((file) => {
					const newFile = new File([file], fileName);
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(newFile);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', fileName);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			)
			.subscribe();
	}

	downloadOfferTerms(referenceId) {
		this.downloadOfferTermsFn$(referenceId).pipe(take(1)).subscribe();
	}

	renderPdfDownload = (content: string, pdfOptions?: HtmlPdfConfigState) =>
		this.crtDocService
			.downloadDocumentPDF(content, pdfOptions?.FileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	downloadCombineAttachments(attachment: Attachment) {
		this.combinedAttachments
			?.pipe(
				switchMap((data) => {
					return combineLatest(
						data?.map((x) => {
							if (!!x?.toGeneratePdf) {
								return of(x?.content).pipe(
									take(1),
									mergeMap((content) =>
										this.crtDocService.generatePDFbase64(content, x?.pdfOptions)
									),
									map((base64) => {
										return {
											document: base64,
											orderNo: x?.orderNo,
											type: x?.type,
										};
									})
								);
							}
							return of({
								document: '',
								orderNo: x?.orderNo,
								type: x?.type,
							});
						})
					);
				}),
				mergeMap((combineDocs) => {
					const DocumentObject =
						combineDocs?.map(objectUtil.mapCamelCaseToPascalCase) || [];
					const req = {
						DocumentObject,
						...attachment?.combineDocumentInfo,
					};
					return this.crtDocService.uploadCombineDocument(req);
				}),
				mergeMap((id: number) => this.crtDocService.getDocument(id)),
				tap((doc) => this.pdfDownloadFromUrl(doc?.DocumentLink)),
				take(1)
			)
			.subscribe();
	}

	pdfDownloadFromUrl = (fileUrl, pdfOptions?) => {
		const name = `${pdfOptions?.FileName}.pdf`;
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', fileUrl);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	};

	openAttachmentModal() {
		this.emailFormComponent.openUploadOptionModal();
	}

	removeAttachment(index: number) {
		this.attachments.splice(index, 1);
	}

}

export interface ConfirmModalModel {
	decline$?: Observable<any>;
	next$?: Observable<any>;
	sent$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
