<ng-container [formGroup]="serviceForm">
  <div class="row tap-container">
    <div class="col pt-1">
      <h6>Service Type</h6>
    </div>
  </div>


  <div class="row tap-container">
    <div class="col-md-12 pr-md-0 my-2">
      <div class="form-group form-inline row mb-1">
        <div class="col-2">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="stLR" value="SLDI" formControlName="serviceType">
            <label class="custom-control-label pt-1" for="stLR">L&R Insurance</label>
          </div>
        </div>
        <div class="col-2">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="stS" value="SSDI" formControlName="serviceType">
            <label class="custom-control-label pt-1" for="stS">Security</label>
          </div>
        </div>
        <div class="col-2">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="stM" value="SMDI" formControlName="serviceType">
            <label class="custom-control-label pt-1" for="stM">Mortgage</label>
          </div>
        </div>
        <div class="col-2">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="stFG" value="SFDI" formControlName="serviceType">
            <label class="custom-control-label pt-1" for="stFG">F&G Insurance</label>
          </div>
        </div>
        <div class="col-2">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="stK" value="SKDI" formControlName="serviceType">
            <label class="custom-control-label pt-1" for="stK">KiwiSaver</label>
          </div>
        </div>
        <div class="col-2">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="stI" value="SIDI" formControlName="serviceType">
            <label class="custom-control-label pt-1" for="stI">Investment</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>