import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { valuesIn, filter, flatten, map as Rmap, isNil } from 'ramda';
import { combineLatest, EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import MomentUtil from 'src/app/util/moment.util';
import { objectUtil } from 'src/app/util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { CRTNoteState } from './../../../../../shared/models/client-review-template/note/crt-note.model';
import { CrtNoteQuery } from './crt-note.query';
import { CrtNoteStore } from './crt-note.store';

@Injectable()
export class CrtNoteService extends ClientReviewTemplateService {
	activeType$ = this.crtNoteQuery.activeType$;
	crtNotesFactFind$ = combineLatest([
		this.crtNoteQuery.crtNotesFactFind$,
		this.crtNoteQuery.activeType$,
	]).pipe(
		map(([notes, type]) =>
			!!type ? notes?.filter((x) => x.type === type) : notes
		)
	);
	crtTypes$ = this.crtNoteQuery.crtTypes$;

	public activityAdvisers$ = this.blStaffsQuery.availableStaffs$.pipe(
		map((x) =>
			Rmap(
				(y) =>
					ViewDisplayValue.Map(
						y.StaffID.toString(),
						`${y.FirstName} ${y.LastName}`
					),
				x
			)
		)
	);

	public adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	activityAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.primaryClient$, this.activityAdvisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, primaryClient, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								x.value === (primaryClient ? primaryClient.adviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display.localeCompare(b.display))
				: all
		)
	);

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected crtNoteStore: CrtNoteStore,
		protected crtNoteQuery: CrtNoteQuery,
		private userQuery: UserQuery,
		private blStaffsQuery: BLStaffsQuery
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	clearData() {
		applyTransaction(() => {
			this.crtNoteStore.reset();
		});
	}

	getNotes(
		adviceProcessId: number,
		sections: string[]
	): Observable<CRTNoteState[]> {
		// this.store.setLoading(true);
		return from(sections).pipe(
			mergeMap((section) =>
				this.api.get<CRTNoteState[]>(`crt/${adviceProcessId}/notes/${section}`)
			),
			map(objectUtil.mapPascalCaseToCamelCase),
			map((x) => valuesIn(x)),
			map((x) => flatten(x)),
			map((x) => filter((y) => !isNil(y), x) as CRTNoteState[]),
			tap((x) =>
				applyTransaction(() => {
					this.crtNoteStore.upsertMany(x);
					this.store.setLoading(false);
					this.crtNoteStore.setHasCache(true);
				})
			),
			catchError(() => EMPTY)
		);
	}

	addNote(notes: string, type: string) {
		return this.api
			.post3<number>('notes/crt', {
				ReferenceId: this.query.getValue().adviceProcessId,
				Notes: notes,
				Type: type,
			})
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						this.crtNoteStore.add({
							referenceId: this.query.getValue().adviceProcessId,
							cRTNotesId: +x,
							notes,
							type,
							createDateTime: MomentUtil.formatToServerDatetimeSecond(
								MomentUtil.createMomentNz()
							),
							createdByStaffId: this.userQuery.getValue().StaffID,
							staffName: `${this.userQuery.getValue().FirstName} ${
								this.userQuery.getValue().LastName
							}`,
							isActive: 1,
							isPinned: null,
						} as CRTNoteState);
					})
				),
				catchError(() => EMPTY)
			);
	}

	updateNote(note: CRTNoteState) {
		return this.api
			.put<number>(`notes/crt/${note?.cRTNotesId}`, {
				CRTNotesId: note.cRTNotesId,
				ReferenceId: this.query.getValue().adviceProcessId,
				Notes: note.notes,
				Type: note?.type,
			})
			.pipe(
				tap(() =>
					applyTransaction(() => {
						this.crtNoteStore.update(note.cRTNotesId, {
							...this.crtNoteQuery.getEntity(note.cRTNotesId),
							notes: note?.notes,
						} as CRTNoteState);
					})
				),
				catchError(() => EMPTY)
			);
	}

	deleteNote(id: number) {
		return this.api.delete(`notes/${id}/crt`).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.crtNoteStore.remove(id);
				})
			),
			catchError(() => EMPTY)
		);
	}

	setActiveType(type?: string) {
		this.crtNoteStore.setActiveType(type ?? '');
	}

	export(req) {
		return this.api
			.postFileDownload(`export/crt-notes`, req)
			.pipe(catchError((x) => throwError(x)));
	}
}
