import MomentUtil from '../../../../util/moment.util';
import { Moment } from 'moment';
import { util } from '../../../../core/util/util.service';
import { InvestmentState } from './investments.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { KiwiSaverTypes } from '../kiwisaver/kiwisaver.model';

export class InvestmentMapper {
  /**
   * Map to view
   */
  public static mapToView(data: InvestmentState): InvestmentState {
    if (!data) {
      return { ...data };
    }

    return {
      ...data,
      investor: typeof data.investor === 'string'
      ? data.investor
        ? JSON.parse(data.investor)
        : null
      : data.investor,
      startDate: util.isValidMoment(data.startDate)
        ? data.startDate
        : this.formatDateToMoment(data.startDate),
      submittedDate: util.isValidMoment(data.submittedDate)
        ? data.submittedDate
        : this.formatDateToMoment(data.submittedDate),
      // tslint:disable-next-line: max-line-length
      notes:
        data.notes && data.notes.length > 0
          ? data.notes?.filter((x) =>
              util.isValidMoment(data.createDateTime)
                ? data.createDateTime
                : this.formatDateToMoment(x.createDateTime)
            )
          : [],
      startTrans: util.isValidMoment(data.startTrans)
      ? data.startDate
      : this.formatDateToMoment(data.startTrans),
      cancellationDate: util.isValidMoment(data.cancellationDate)
      ? data.startDate
      : this.formatDateToMoment(data.cancellationDate),
    };
  }

  /**
   * Map to update
   */
  public static mapToUpsert(data: InvestmentState): InvestmentState {
    return {
      ...data,
      fUM: data.fUM ? +data.fUM : null,
      startDate: MomentUtil.formatDateToServerDate(data.startDate),
      startTrans: MomentUtil.formatDateToServerDate(data.startTrans),
      submittedDate: MomentUtil.formatDateToServerDate(data.submittedDate),
      modifiedDateTime: MomentUtil.formatDateToServerDate(
        data.modifiedDateTime
      ),
			serviceCode: ServicesCodes.Investment,
      cancellationDate: MomentUtil.formatDateToServerDate(data.cancellationDate),
    };
  }

  /**
   * Format date to Moment
   * @param date string or Moment date
   */
  static formatDateToMoment(date: string | Moment): Moment {
    return typeof date === 'string'
      ? util.DateStringToMomentLoose(date)
      : util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
  }

  public static mapInvestmentNextReviewView(value) {
		if (!value) {
			return '';
		}
		return util.isValidMoment(value) ? value : this.formatDateToMoment(value);
	}

  public static mapInvestmentNextReviewUpsert(value) {
		return MomentUtil.formatDateToServerDate(value);
	}
}
