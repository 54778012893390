<div
	class="card-header font-weight-bold text-light"
	[appTheme]="{ 'background-color': 'primarycolor' }"
>
	{{ header }}
</div>
<div class="card-body pt-4 pb-4 pl-2 pr-2">
	<app-product-form
		[currentInsuranceInfo]="currentInsuranceInfo"
		[policyOwners]="policyOwners$ | async"
		[provider]="lrp$ | async"
		[product]="lrpr$ | async"
		[premiumFrequency]="apcrtf$ | async"
		[benefit]="apcrtb$ | async"
		[isSingleUpload]="!!isSingleUpload"
		[lifeAssuredDropdownRaw]="lifeAssuredList$ | async"
		[getOwnerChoices]="getOwnerChoices"
		[currentInsuranceLifeAssured]="currentInsuranceLifeAssured$ | async"
		[isProposedInsurance]="isProposedInsurance"
		(saveEvent)="saveChange($event)"
		(cancelEvent)="cancelChange($event)"
		[isLoading]="isLoading"
		[newExistingChoices]="apcrtne$ | async"
		[defaultPaymentFrequency]="defaultPaymentFrequency"
		[enableLinkDocument]="enableLinkDocument"
		[hideNotes]="hideNotes"
    [restrictFileTypes]="restrictFileTypes"
		[allowedFileExtensions]="allowedFileExtensions"
		[viewMode]="viewMode"
		[defaultNewExistingValue]="defaultNewExistingValue"
		[isAPCAR]="isAddProductCar"
		[primaryClient]="primaryClient"
		[enableAiQuote]="enableAiQuote"
		[adviceProcessId]="adviceProcessId"
		[clientId]="clientId"
		[isAdviceProcessEnded$]="isAdviceProcessEnded$"
	></app-product-form>
</div>
<ng-container *ngIf="false">
	<div class="card-footer bg-transparent footer-height">
		<label class="pt-3 pl-3"> + Add Additional [Information] </label>
	</div>
</ng-container>
