import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule, SELECTION_MODEL_FACTORY } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppInputChipsFor } from './input-chips-for';
import { InputChipsReadonlyItemComponent } from './input-chips-readonly-item/input-chips-readonly-item.component';
import { InputChipsSelectionModelFactory } from './input-chips-selection-model';
import { InputChipsComponent } from './input-chips.component';

@NgModule({
	declarations: [
		InputChipsComponent,
		InputChipsReadonlyItemComponent,
		AppInputChipsFor,
	],
	exports: [InputChipsComponent],
	imports: [CommonModule, NgSelectModule, FormsModule, TooltipModule],
	providers: [
		{
			provide: SELECTION_MODEL_FACTORY,
			useValue: InputChipsSelectionModelFactory,
		},
	],
})
export class InputChipsModule {}
