import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { complement, either, isNil, isEmpty } from 'ramda';
import {
	BehaviorSubject,
	combineLatest,
	iif,
	interval,
	Observable,
	Observer,
	of,
	Subject,
} from 'rxjs';
import {
	concatMap,
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	skipWhile,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { UserQuery } from '../../../../../../../domain/user/user.query';
import {
	filterNonEmailMergeTags,
	getContentWithMergeTags,
	normalizeHTMLSymbols,
	removeEmptyParagraphs,
	removeHTMLContentWhiteSpaces,
	removeLastEmptyParagraph,
	removeMtWrappers,
	removeSoaDivs,
	resetMergeTags,
} from '../../../../../../../shared/converter/content-merge-tags';
import { ReviewAppMergeTagsMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/review-application/review-application.mapper';
import { MergeTagsMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { AppNoteMergeTagMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/application/notes/app-notes.mapper';
import { AppDocsMergeTagMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/application/documents/documents.mapper';
import { AppCashDepositMergeTagMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/application/cash-deposit/cash-deposit.mapper';
import { AppFundingRequiredMergeTagMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/application/funding-required/funding-required.mapper';
import { AppSecurityMergeTagMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/application/security/security.mapper';
import { AppLoanStructureMergeTagMapper } from '../../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/application/loan-structure/loan-structure.mapper';
import { MergeTagState } from '../../../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ProviderCommissionSettingState } from '../../../../../../../shared/models/provider-commission/provider-commission.model';
import { WysiwygComponent } from '../../../../../../../shared/wysiwyg/wysiwyg.component';
import { convertUtil, objectUtil } from '../../../../../../../util/util';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import { MergeTagsService } from '../../../state/merge-tags/merge-tags.service';
import { ApplicationService } from '../../state/application.service';
import { ApplicationDocumentQuery } from '../documents/state/documents.query';
import { CashDepositMapper } from '../funding-required/cash-deposit/state/cash-deposit.mapper';
import { CashDepositDetails } from '../funding-required/cash-deposit/state/cash-deposit.model';
import { CashDepositQuery } from '../funding-required/cash-deposit/state/cash-deposit.query';
import { PropertyPurchaseQuery } from '../funding-required/property-purchase/state/property-purchase.query';
import { SecurityService } from '../security/state/security.service';
import { ReviewApplicationMapper } from './state/review-application.mapper';
import {
	ApplicationComputationsState,
	ReviewApplicationState,
} from './state/review-application.model';
import { ReviewApplicationQuery } from './state/review-application.query';
import { ReviewApplicationService } from './state/review-application.service';
import { CrtDocumentService } from '../../../../_shared/service/crt-document.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import {
	AdviceProcessSectionCodes,
	MoatApplicationStepHeader,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NoteQuery } from '../notes/state/note.query';
import { computeUtil } from '../../../../_shared/calculations/funding-required';
import { PropertyPurchaseDetailsState } from '../funding-required/property-purchase/state/property-purchase.model';
import { LoanRefinanceQuery } from '../funding-required/loan-refinance/state/loan-refinance.query';
import { PropertySoldQuery } from '../funding-required/selling-section/property-sold/state/property-sold.query';
import { FundingRequiredLoanQuery } from '../funding-required/selling-section/loan-repaid/state/loan-repaid.query';
import { TopupRequirementsQuery } from '../funding-required/topup-requirements/state/topup-requirements.query';
import { LoanRefinanceState } from '../funding-required/loan-refinance/state/loan-refinance.model';
import { TopupRequirementsState } from '../funding-required/topup-requirements/state/topup-requirements.store';
import { PurposeService } from '../purpose/state/purpose.service';
import {
	PurposeDetailsState,
	PurposeTypes,
} from '../purpose/state/purpose.model';
import { PropertySoldState } from '../funding-required/selling-section/property-sold/state/property-sold.store';
import { FundingRequiredLoanState } from '../funding-required/selling-section/loan-repaid/state/loan-repaid.store';
import { Security } from '../security/state/security.model';
import { appFundingRequiredMetaKey } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/funding-required/funding-required.merge-tags';
import { AssetService } from '../../../client-sop/assets-and-liabilities/state/asset/asset.service';
import { PropertyService } from '../../../client-sop/assets-and-liabilities/state/property/property.service';
import { CrtMortgageService } from '../../../state/crt-mortgage.service';
import { ApplicationDocumentService } from '../documents/state/documents.service';
import { PeopleEntitiesService } from '../../../client-sop/people-entities/state/people-entities.service';
import { LiabilityService } from '../../../client-sop/assets-and-liabilities/state/liability/liability.service';
import { ReviewApplicationEmailSettingsService } from 'src/app/modules/mortgage-settings/review-application-settings/review-application-email-settings/state/review-application-email-settings.service';
import { SettingsTypes } from 'src/app/modules/mortgage-settings/state/mortgage-settings.model';
import { ReviewApplicationEmailSettingsState } from 'src/app/modules/mortgage-settings/review-application-settings/review-application-email-settings/state/review-application-email-settings.model';
import { CrtEmailModalComponent } from 'src/app/shared/modal/crt/email/crt-email-modal/crt-email-modal.component';
import { appDocsMetaKey } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/application/documents/documents.merge-tags';
import produce from 'immer';
import MomentUtil from 'src/app/util/moment.util';
import { MortgageAdviceProcessService } from '../../../state/mortgage-adviceprocess/mortgage-advice-process.service';
import {
	DefaultFileNames,
	DocumentTypesMOAT,
} from 'src/app/shared/models/documents/document.model';
import { LoanStructureQuery } from '../loan-structure/state/loan-structure.query';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { TopupRequirementsDetailsState } from '../funding-required/topup-requirements/state/topup-requirements.model';
import { PropertySoldDetailsState } from '../funding-required/selling-section/property-sold/state/property-sold.model';
import { FundingRequiredLoan } from '../funding-required/selling-section/loan-repaid/state/loan-repaid.model';
import { DocumentListService } from '@modules/mortgage-settings/document-list/state/document-list.service';
import { PdfDesignV2Options } from '@shared/models/client-review-template/pdf-design-v2/pdf-design-v2.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { HtmlPdfConfigState } from '@modules/crm/crt-page/_shared/service/html-pdf/defaults-config';

@Component({
	selector: 'app-review-application',
	templateUrl: './review-application.component.html',
	styleUrls: ['./review-application.component.scss'],
})
export class ReviewApplicationComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Input() parentCRTId: number;
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}>();
	@Output() goBackToList: EventEmitter<string> = new EventEmitter<string>();
	@Output() doResetStores: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output() toggleLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() saveCurrentPage: EventEmitter<string> = new EventEmitter<string>();
	@Output() lastAutoSavedTime: EventEmitter<string> = new EventEmitter<string>(null);
	@Output() isAutoSaveLoading: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	isLoading: boolean;
	templateId: number;
	data: ReviewApplicationState;
	emailSettings: ReviewApplicationEmailSettingsState;
	cRTId: number;
	rawMergeTags: MergeTagState[]; // unmapped merge tags
	crtMergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
	mortgageProviders$ = new BehaviorSubject<ProviderCommissionSettingState[]>(
		null
	);

	currentTemplate = '<p></p>';
	prevTemplate = null;
	isInit = true;
	isNew = false;
	isMergeTagLoading = false;
	isFinalizing = false;
	isFinalized = false;
	isResetting = false;
	isAutoSaveEnabled = false;
	groupName = this.moatQuery
		.getValue()
		.primaryClient?.groupName?.toString()
		?.toUpperCase();
	fileName = `${this.groupName} REVIEW APPLICATION.pdf`;
	adviceProcessId = this.moatQuery.getValue().adviceProcessId;
	adviceProcess = this.moatQuery.getValue().adviceProcess;
	adviserId = +this.moatQuery.getValue().adviceProcess?.adviser;
	isTapLevel = this.userQuery.isTapLevel();
	mergeTags$ = this.mtService.mergeTags$;
	mergeTags = this.moatQuery.getValue().mergeTags;
	cashDeposit$ = this.cashDepositQuery.activeCashDeposit$.pipe(
		map((x) =>
			CashDepositMapper.mapToView(objectUtil.mapPascalCaseToCamelCase(x || []))
		)
	);
	emailSettings$ = this.rapEmailSettingsService.reviewApplicationEmailSettings$;
	documents$ = this.documentQuery.applicationDocuments$;
	propertyPurchase$ = this.propertyPurchaseQuery.properties$;
	notes$ = this.notesQuery.activeNotes$;
	propertyToBeSold$ = this.propertySoldQuery.properties$;
	topUpRequirement$ = this.topUpQuery.topupRequirementList$;
	aLAssets$ = this.assetService.assets$;
	propertyAddress$ = this.propertyService.propertyAddresses$;
	ffProperties$ = this.propertyService.properties$;
	ffliabilities$ = this.liabilityService.liabilities$;
	propertyOwners$ = this.peopleService.policyOwnersWithCRT$;
	people$ = this.peopleService.people$;
	loanStructure$ = this.loanStructureQuery.loanStructures$;
	loanRefinance$ = this.loanRefinanceQuery.loanRefinance$.pipe(
		map((x) => (x?.length > 0 ? x?.filter((i) => !!!i?.hidden) : []))
	);
	loansRepaid$ = this.fundingRequiredLoanQuery.loans$.pipe(
		map((x) => (x?.length > 0 ? x?.filter((i) => !!!i?.hidden) : []))
	);
	purpose$ = this.purposeService.purposes$.pipe(
		map((x) => (x?.length > 0 ? x[0] : null))
	);
	securities$ = this.securityService.securities$.pipe(
		map((x) => (x?.length > 0 ? x[0] : null))
	);

	@ViewChild('contentEditor') editor: WysiwygComponent;
	public bsModalRef: BsModalRef;

	themeConfig$ = this.businessConfigQuery.themeConfig$;
	pdfClasses = ' pdf-design-v2 pdf-design-v2-body';

	peopleAndDependents$ = this.moatQuery.peopleAndDependentsFromCRTOnly$;

	constructor(
		private router: Router,
		private moatQuery: CrtMortgageQuery,
		private service: ReviewApplicationService,
		private query: ReviewApplicationQuery,
		private userQuery: UserQuery,
		private mtService: MergeTagsService,
		private appService: ApplicationService,
		private cashDepositQuery: CashDepositQuery,
		private securityService: SecurityService,
		private documentQuery: ApplicationDocumentQuery,
		private propertyPurchaseQuery: PropertyPurchaseQuery,
		private crtDocService: CrtDocumentService,
		private notesQuery: NoteQuery,
		private modalService: BsModalService,
		private loanRefinanceQuery: LoanRefinanceQuery,
		private propertySoldQuery: PropertySoldQuery,
		private fundingRequiredLoanQuery: FundingRequiredLoanQuery,
		private topUpQuery: TopupRequirementsQuery,
		private purposeService: PurposeService,
		private assetService: AssetService,
		private propertyService: PropertyService,
		private crtMortageService: CrtMortgageService,
		private appDocService: ApplicationDocumentService,
		private peopleService: PeopleEntitiesService,
		private liabilityService: LiabilityService,
		private rapEmailSettingsService: ReviewApplicationEmailSettingsService,
		private mApService: MortgageAdviceProcessService,
		private loanStructureQuery: LoanStructureQuery,
		private moatDocumentSettings: DocumentListService,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit(): void {
		this.isFinalized =
			this.appService.getEntity(+this.parentCRTId)?.currentPage === 'Finalized';

		this.moatQuery.triggerLeaveSoa$
			.pipe(
				skipWhile(() => this.isInit),
				filter((x) => !!x),
				tap((x) => {
					if (x !== 'review-application') {
						this.leaveSOA(null, x);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.moatQuery.reviewApplicationShowModal$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((y) => {
				if (y) {
					this.togglePauseAndExit();
				}
			});
	}

	load() {
		this.toggleLoading.emit(true);
		this.prepData();
	}

	prepData() {
		this.isLoading = true;
		this.moatQuery.reviewApplicationSettings$
			.pipe(
				filter((x) => !!x),
				concatMap(() => this.query.activeReviewApplication$),
				tap((data: ReviewApplicationState) => {
					this.data = data;
					this.cRTId = data?.cRTId;
				}),
				concatMap(() => this.fetchOtherData()),
				concatMap(() => this.getMergeTags()),
				concatMap(() => this.previewContent()),
				finalize(() => {
					this.isLoading = false;
					this.toggleLoading.emit(false);
					this.initializeAutoSave();
				}),
				take(1)
			)
			.subscribe();
	}

	initializeAutoSave() {
		if (this.isAutoSaveEnabled) { return; } // If already running
		this.isAutoSaveEnabled = true;

		interval(300000).pipe( // 5 minutes
			filter(() => {
				const data = this.appService.application(this.parentCRTId);
				return (data?.currentPage === MoatApplicationStepHeader.ReviewApplication);
			}),
			tap(() => this.isAutoSaveLoading.emit(true)),
				switchMap(() => this.uploadFile()),
				tap(() => {
					const lastSaved = MomentUtil.formatToServerTime(
						MomentUtil.createMomentNz()
					);
					this.lastAutoSavedTime.emit(lastSaved);
					this.isAutoSaveLoading.emit(false);
				}),
				takeUntil(this.onDestroy$)
			).subscribe();
	}

	fetchOtherData() {
		return of(true).pipe(
			withLatestFrom(this.securities$),
			concatMap(([, x]) =>
				iif(
					() => !!x,
					of(x),
					this.securityService.get(this.parentCRTId, this.adviceProcessId)
				)
			),
			take(1)
		);
	}

	getMergeTags = () =>
		this.mtService.mergeTags$.pipe(
			filter((data) => !!data),
			tap(() => (this.isMergeTagLoading = true)),
			take(1),
			concatMap(() =>
				iif(
					() => this.isInit,
					this.mtService.getFactFindMt(+this.adviceProcessId),
					of([])
				)
			),
			concatMap(() => this.mtService.getSosMt(+this.adviceProcessId)),
			concatMap(() => this.mtService.getApplicationMt(+this.parentCRTId)),
			withLatestFrom(this.mergeTags$),
			tap(([, mt]) => (this.rawMergeTags = mt)),
			map(([, mt]) => ReviewAppMergeTagsMapper.peoplePrimaryIncome(mt)),
			switchMap((mt) => MergeTagsMapper.remapMergeTagsTypes(mt)),
			tap((mt) => (this.mergeTags = mt)),
			tap(() => (this.isMergeTagLoading = false))
		);

	previewContent = () =>
		this.query.activeReviewApplication$.pipe(
			switchMap((x: ReviewApplicationState) => {
				const hasId = either(isNil, isEmpty)(x?.cRTId) || either(isNil, isEmpty)(x?.document?.referenceId);
				const template = iif(
					() => hasId,
					this.getSettingsTemplate(),
					this.getTemplate(+x?.document?.referenceId));

				return of(x).pipe(
					switchMap(() => template),
					switchMap((content) => this.updateMergeTags(content))
				)
			}),
			tap((content) => {
				this.currentTemplate = content;
				this.prevTemplate = content;
			}),
			finalize(() => {
				this.isInit = false;
			}),
			take(1)
		);

	convertContent = (content = '', mergeTags?) =>
		of(content).pipe(
			// Reset merge tags first
			map((x) => AppLoanStructureMergeTagMapper.revertLoanStructureSection(x)),
			switchMap((x) => this.resetMergeTags(x)),
			// Re-convert merge tags to update
			map((x) => getContentWithMergeTags(x, mergeTags || this.mergeTags))
		);

	updateMergeTags = (content: string) =>
		this.applicationMergeTags().pipe(
			delay(100),
			switchMap((x) => MergeTagsMapper.remapMergeTagsTypes(x)),
			switchMap((x) => this.convertContent(content, x)),
			switchMap((x) => this.processHiddenElements(x)),
			map((x) => removeEmptyParagraphs(x)),
			take(1)
		);

	applicationMergeTags() {
		return combineLatest([
			this.cashDeposit$.pipe(startWith(null as any)),
			this.securities$.pipe(startWith(null as any)),
			this.documents$,
			this.propertyPurchase$,
			this.notes$,
			this.purpose$,
			this.loanRefinance$,
			this.topUpRequirement$,
			this.propertyToBeSold$,
			this.loansRepaid$,
			this.aLAssets$,
			this.propertyAddress$,
			this.ffProperties$,
			this.ffliabilities$,
			this.propertyOwners$,
			this.loanStructure$,
			this.people$,
			this.moatDocumentSettings.documentList$
		]).pipe(
			map(
				([
					cashDeposit,
					securities,
					documents,
					propertyPurchase,
					notes,
					purpose,
					loanRefinance,
					topupReq,
					propertyToBeSold,
					loansRepaid,
					aLAssets,
					propertyAddress,
					ffProperties,
					ffliabilities,
					propertyOwners,
					loanStructure,
					people,
					moatDocumentSettings
				]) => {
					const applicationData = this.appService.getEntity(+this.parentCRTId);
					// Computations
					const computations = this.computations(
						purpose,
						cashDeposit,
						propertyPurchase,
						loanRefinance,
						topupReq,
						propertyToBeSold,
						loansRepaid,
						securities,
						this.mergeTags
					);

					// Cash/Deposit MergeTags
					const appCashDepositMT =
						AppCashDepositMergeTagMapper.getMergeTagValues(
							cashDeposit,
							computations
						);

					// Other Funding Required MergeTags
					const appFundingReqMT =
						AppFundingRequiredMergeTagMapper.getMergeTagValues(
							purpose,
							propertyPurchase,
							loanRefinance,
							topupReq,
							propertyToBeSold,
							loansRepaid,
							aLAssets,
							ffliabilities,
							propertyAddress,
							computations
						);

					// Security MergeTags
					const appSecurityMT = AppSecurityMergeTagMapper.getMergeTagValues(
						propertyOwners,
						securities,
						ffProperties,
						propertyPurchase,
						computations
					);

					// Loan Structure Merge Tags
					const appLoanStructureMt =
						AppLoanStructureMergeTagMapper.getMergeTagValues(
							loanStructure,
							applicationData,
							propertyOwners,
							computations
						);

					// Notes MergeTags
					const appNotesMT = AppNoteMergeTagMapper.getMergeTagValues(notes);

					// Documents
					const appDocsMT = AppDocsMergeTagMapper.getMergeTagValues(
						documents,
						moatDocumentSettings,
						purpose
					);

					// Other Merge Tags
					// Includes Client & Declaration section here
					const appReviewMT = ReviewAppMergeTagsMapper.getMergeTagValues(
						this.mergeTags,
						this.rawMergeTags,
						people
					);

					const mergeTags = [
						...this.mergeTags,
						...appCashDepositMT,
						...appFundingReqMT,
						...appSecurityMT,
						...appNotesMT,
						...appReviewMT,
						...appDocsMT,
						...appLoanStructureMt,
					];

					return mergeTags;
				}
			)
		);
	}

	resetMergeTags = (template) =>
		of(template).pipe(
			map((x) => (this.hasTemplate(this.templateId) ? resetMergeTags(x) : x)),
			take(1)
		);

	getSettingsTemplate = () =>
		this.moatQuery.reviewApplicationSettings$.pipe(
			tap((x) => {
				this.templateId = null;
				this.isNew = true;
			}),
			concatMap((x) => this.service.getDocumentFromURL(x?.templateLink)),
			take(1)
		);

	getTemplate = (documentId?: number, isNew = false) =>
		of(documentId).pipe(
			tap((id) => {
				this.templateId = isNew ? null : +id;
				this.isNew = isNew;
			}),
			concatMap((id) => this.service.getDocumentFile(+id)),
			concatMap((res) =>
				iif(
					() => res?.documentLink,
					this.service.getDocumentFromURL(res?.documentLink),
					of(null)
				)
			),
			take(1)
		);

	hasTemplate = (template) =>
		complement(either(isNil, isEmpty))(template) &&
		!isNaN(+template) &&
		+template > 0;

	next = () => this.save(true);
	previous = () => this.save();

	computations(
		purpose: PurposeDetailsState,
		cashDeposit: CashDepositDetails,
		propertyPurchase: PropertyPurchaseDetailsState[],
		loanRefinance: LoanRefinanceState[],
		topUpRequirement: TopupRequirementsState[] | TopupRequirementsDetailsState[],
		propertyToBeSold: PropertySoldState[] | PropertySoldDetailsState[],
		loansRepaid: FundingRequiredLoanState[] | FundingRequiredLoan[],
		security: Security,
		mergeTags: MergeTagState[]
	) {
		// Purpose Status
		const isRefinance = purpose?.types?.find((p) =>
			p?.includes(PurposeTypes.Refinance)
		);
		const isLendingTopup = purpose?.types?.find((p) =>
			p?.includes(PurposeTypes.LendingTopup)
		);
		const refinanceList = isRefinance ? loanRefinance : [];
		const topupList = isLendingTopup ? topUpRequirement : [];
		const securityList = security?.bankWillTake?.filter((a) => !!a?.isTick);
		const totalPurchasePrice = +(
			this.propertyPurchaseQuery.getValue().totalPurchasePrice || 0
		);

		const totalContribution = computeUtil.totalContribution(cashDeposit);
		const proposedTotalDebt = security?.proposedTotalDebt;
		const totalSecurityValue = security?.totalSecurityValue;
		const totalDeposit = totalContribution || 0;
		const totalNetLoanProceeds = computeUtil.totalNetLoanProceeds(
			cashDeposit,
			propertyToBeSold || [],
			loansRepaid || []
		);
		const totalFundingRequired = computeUtil.totalFundingRequired(
			totalDeposit,
			refinanceList,
			topupList,
			totalPurchasePrice
		);
		const proposedLVR = security?.proposedLVR;
		const totalPropertyValue =
			AppFundingRequiredMergeTagMapper.getTotalValueOnMT(
				mergeTags,
				appFundingRequiredMetaKey.totalPropertyValue.toString()
			);
		const maxLoanAmount = AppFundingRequiredMergeTagMapper.getTotalValueOnMT(
			mergeTags,
			appFundingRequiredMetaKey.totalPropertyLoanAmount.toString()
		);
		const propertyLVR = computeUtil.propertyLVR(
			proposedTotalDebt,
			totalPropertyValue
		);
		const scaledLVR = computeUtil.scaledLVR(proposedTotalDebt, maxLoanAmount);

		return {
			totalContribution,
			totalDeposit,
			totalNetLoanProceeds,
			totalFundingRequired,
			totalPurchasePrice,
			proposedTotalDebt,
			proposedLVR,
			totalPropertyValue,
			maxLoanAmount,
			propertyLVR,
			scaledLVR,
			totalSecurityValue,
		} as ApplicationComputationsState;
	}

	processHiddenElements(content: string) {
		return of(content).pipe(
			withLatestFrom(this.cashDeposit$),
			map(([x, cashDeposit]) =>
				AppCashDepositMergeTagMapper.hideDepositFields(x, cashDeposit)
			),
			map((x) => ReviewAppMergeTagsMapper.hideDollarSign(x)),
			take(1)
		);
	}

	resetAllMergeTags() {
		this.isResetting = true;
		of(this.editor?.content)
			.pipe(
				concatMap(() => this.refetchMergeTags()),
				switchMap(() => this.updateMergeTags(this.editor?.content)),
				tap((content) => {
					this.currentTemplate = content;
					this.prevTemplate = content;
				}),
				finalize(() => (this.isResetting = false)),
				take(1)
			)
			.subscribe();
	}

	refetchMergeTags = () =>
		this.mtService.getDefaultMergeTags(true).pipe(
			concatMap(() => this.mtService.getAdviserProviderMt()),
			concatMap(() => this.mtService.getFactFindMt(+this.adviceProcessId)),
			concatMap(() => this.mtService.getSosMt(+this.adviceProcessId)),
			concatMap(() => this.mtService.getApplicationMt(+this.parentCRTId)),
			withLatestFrom(this.mergeTags$),
			tap(([, mt]) => (this.rawMergeTags = mt)),
			map(([, mt]) => ReviewAppMergeTagsMapper.peoplePrimaryIncome(mt)),
			switchMap((mt) => MergeTagsMapper.remapMergeTagsTypes(mt)),
			tap((mt) => (this.mergeTags = mt)),
			take(1)
		);

	/**
	 * Save Review Application
	 * @param isNext (boolean) - Define if next set as true; If previous, leave empty
	 */
	save(isNext = false, shouldRedirect = true, path?: string) {
		this.uploadFile()
			.pipe(
				delay(300),
				finalize(() => {
					if (shouldRedirect) {
						this.exit();
					} else if (path) {
						this.exit(path);
					}
				}),
				take(1)
			)
			.subscribe(
				(data) =>
					this.saveCompleted.emit({ isSuccess: true, isNext, redirect: true }),
				(err) =>
					this.saveCompleted.emit({ isSuccess: false, isNext, redirect: true })
			);
	}

	/**
	 * Create or Update Review Application File
	 * @returns
	 */
	uploadFile() {
		return of(this.editor?.content).pipe(
			mergeMap((content) => convertUtil.convertToBase64(content)),
			withLatestFrom([this.templateId], [this.parentCRTId]),
			map(([content, templateId, cRTId]) =>
				ReviewApplicationMapper.mapDocumentUpload(content, cRTId, templateId)
			),
			concatMap((data) =>
				iif(
					() => this.hasTemplate(data?.documentId),
					this.service.updateFileUpload(data),
					this.service.newFileUpload(data)
				)
			),
			tap((data) => {
				this.templateId = this.hasTemplate(this.templateId)
					? this.templateId
					: +data;
			}),
			withLatestFrom([this.templateId], [this.parentCRTId], [this.cRTId]),
			map(([id, templateId, parentCRTId, cRTId]) => {
				const documentId = cRTId ? templateId : id;
				return ReviewApplicationMapper.mapToUpsert(
					{
						parentCRTId,
						cRTId,
						adviceProcessId: this.adviceProcessId,
					},
					documentId
				);
			}),
			concatMap((data) =>
				iif(
					() => data?.cRTId === 0 || !data?.cRTId,
					this.service.add(data).pipe(
						withLatestFrom(this.query.activeReviewApplication$),
						tap(([id, reviewApp]) => {
							this.data = reviewApp;
							this.cRTId = id as number;
						})
					),
					of(null)
				)
			)
		);
	}

	regenerateMergeTags() {
		this.getMergeTags()
			.pipe(
				map(() =>
					this.hasTemplate(this.templateId)
						? this.editor.content
						: this.currentTemplate
				),
				tap((x) => {
					this.currentTemplate = x;
					this.prevTemplate = x;
				}),
				take(1)
			)
			.subscribe();
	}

	exit(path?: string) {
		if (path) {
			this.navigateToUrl(path);
		} else {
			this.goBackToList.emit(AdviceProcessSectionCodes.ReviewApplication);
		}

		this.crtMortageService.setReviewApplicationModal(false);
		this.crtMortageService.setReviewApplicationChangeValue(null);
		this.service.setHasSoaChanges(false);
		this.service.setHasReviewApplicationChanges(false);
	}

	clearData() {
		this.service.clearData();
	}

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(data),
					this.peopleService.updatePeople(data)
				)
			)
		);
	};


	emailReviewApplication() {
		// Email Download Application
		return this.rapEmailSettingsService
			.getReviewApplicationEmailSettings(
				0,
				SettingsTypes.MOATReviewApplicationEmail
			)
			.pipe(
				map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
				map((x) => this.getEmailSettings(x)),
				withLatestFrom(this.purposeService.purposes$),
				tap(([, purpose]) => {
					const bankName = purpose?.[0]?.bank || '';
					const initState = {
						header: 'Email',
						enableDefaultBcc: true,
						sendEmailFn$: this.finalizedAndDownload,
						saveEmailFn$: this.saveCrtEmail,
						peopleDropdown$: this.peopleAndDependents$,
						emailSettings$: this.emailSettings$,
						adviceProcess: this.adviceProcess,
						showAdviserOnRecipient: true,
						successMessage: logMessage.oat.mortgage.reviewApplication.success,
						hideEmailOnSuccessMsg: true,
						isCrtOnPeopleMergeTag: true,
						// hideManualAttachment: true,
						queueAttachments: true,
						attachments: [
							{
								fileName: `${bankName}_Application.pdf`,
								queue: true,
							},
						],
						mergeTags$: filterNonEmailMergeTags(this.applicationMergeTags(), [
							{ key: appDocsMetaKey.documents, exact: true },
						]),
						defaultLinkDocumentTab: ServicesCodes.Mortgage?.toLowerCase(),
						documentInfo: {
							documentType: ServicesCodes.Mortgage,
							type: DocumentTypesMOAT.Application,
							referenceId: this.moatQuery.getValue().adviceProcessId,
							customerId: this.moatQuery.getValue().primaryClient?.customerID,
						},
					};

					this.modalService.show(CrtEmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				}),
				take(1)
			);
	}

	cleanContentForDownload = (content = '') =>
		// Use this only when converting final contents to PDF
		of(content).pipe(
			map((x) => removeSoaDivs(x)),
			map((x) => removeMtWrappers(x)),
			map((x) => removeEmptyParagraphs(x)),
			map((x) => removeLastEmptyParagraph(x)),
			take(1)
		);

	/**
	 * Finalized And Download the Review Application Document
	 */
	finalizedAndDownload = (data?) => {
		this.isFinalizing = true;
		return this.uploadFile().pipe(
			switchMap(() => this.cleanContentForDownload(this.editor.content)),
			map((x) => `<div class="moat-review-app-pdf ${this.pdfClasses}">${x}</div>`),
			concatMap((x: string) => this.getReviewApplicationPDF(x)),
			concatMap((x) => convertUtil.simpleConvertToBase64(x)),
			map((x) => (x as string).replace(/^data:(.*,)?/, '')),
			map((x) =>
				ReviewApplicationMapper.mapDocumentUploadPDF(
					x,
					+this.parentCRTId,
					+this.moatQuery.getValue().clientId,
					this.fileName
				)
			),
			concatMap((x) => this.service.newFileUpload(x)),
			concatMap((x) =>
				this.service.update({
					...this.data,
					cRTId: +this.cRTId,
					reviewApplicationPDF: +x,
				} as ReviewApplicationState)
			),
			concatMap(() => this.uploadEmailBodyAsDocument(data?.bodyContent)),
			map((documentId) =>
				ReviewApplicationMapper.mapQueueDocumentUpload(
					documentId,
					+this.parentCRTId,
					+this.adviceProcessId,
					data
				)
			),
			concatMap((x) => this.service.finalizedAndDownload(x)),
			concatMap(() =>
				this.appService.setCurrentPage(+this.parentCRTId, 'Finalized')
			),
			concatMap((x) =>
				this.appDocService.refetchClientDocument().pipe(map(() => x))
			),
			concatMap((x) =>
				this.appDocService
					.getApplicationDocuments(+this.parentCRTId)
					.pipe(map(() => x))
			),
			concatMap((x) => this.appService.update(x)),
			concatMap(() => this.updateAdviceProcess()),
			finalize(() => (this.isFinalizing = false)),
			take(1)
		);
	};

	updateAdviceProcess = () => {
		return this.moatQuery.adviceProcess$.pipe(
			skipWhile(() => this.moatQuery.getValue().mortApIsLoading),
			concatMap((data) => {
				const adviceProcess = Object.assign({}, data);
				const mappedStage = (remove: boolean) =>
					adviceProcess.stages?.map((el) =>
						produce(el, (draft) => {
							if (draft.field === 'Application Submitted') {
								draft.value = !remove
									? MomentUtil.formatToServerDatetime(
											MomentUtil.createMomentNz()
									  )
									: null;
							}
							return draft;
						})
					);

				const hasDate = adviceProcess.stages?.find(
					(x) => x.field === 'Application Submitted' && !!x.value
				);
				if (!hasDate) {
					const mortgageAdvice = {
						...adviceProcess,
						stages: [...mappedStage(false)],
						documents: adviceProcess.documents?.map((doc) => {
							return {
								...doc,
								value: doc.value?.documentID,
							};
						}),
					};
					return this.mApService.updateMortgageAdviceProcess(mortgageAdvice);
				} else {
					return of(null);
				}
			})
		);
	};

	getEmailSettings(emailSettings: ReviewApplicationEmailSettingsState) {
		return this.convertEmailContentMT(emailSettings?.subject).pipe(
			map((content) => {
				this.emailSettings = {
					...emailSettings,
					subject: normalizeHTMLSymbols(content),
				};
				return this.emailSettings;
			}),
			take(1)
		);
	}

	convertEmailContentMT(value: string) {
		return this.applicationMergeTags().pipe(
			delay(10),
			map((mt) => {
				const subjectMergeTags = MergeTagsMapper.convertImageLinkToTextMT(mt);
				const subjectContent = getContentWithMergeTags(
					value || '',
					subjectMergeTags
				);
				const parsedSubject = removeMtWrappers(subjectContent);
				return parsedSubject;
			}),
			take(1)
		);
	}

	uploadEmailBodyAsDocument(bodyContent: string) {
		return of(bodyContent || '').pipe(
			map((x) => removeMtWrappers(x)),
			concatMap((x) => convertUtil.convertToBase64(x)),
			map((x) => ({
				document: x,
				referenceId: this.parentCRTId,
				fileName: DefaultFileNames.ReviewApplicationEmailBody,
				type: DocumentTypesMOAT.MOATReviewAppEmailBody,
			})),
			concatMap((x) => this.service.newFileUpload(x)),
			take(1)
		);
	}

	getReviewApplicationPDF(content = '') {
		const FileName = 'Review Application';
		return this.getPdfHeaderFooterOptions().pipe(
			mergeMap((options) => {
				return this.crtDocService.downloadDocumentPDF(content, FileName, {
					...options,
					FileName,
				})
			})
		);
	}

	leaveSOA(event?, path?) {
		const confirm = new Observable((obs: Observer<any>) => {
			if (event) {
				this.save(false);
			} else if (path) {
				this.save(false, false, path);
			}
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (event) {
				this.doResetStores.emit(true);
				this.exit(path);
			} else {
				this.doResetStores.emit(true);
				this.navigateToUrl(path);
			}
			obs.complete();
		});

		const close = new Observable((obs: Observer<any>) => {
			this.service.setTriggerLeaveSoa(null);
			obs.complete();
		});

		this.toggleModal(confirm, decline, close);
	}

	leaveSummary() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.save(false, false);
			this.regenerateMergeTags();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.currentTemplate = '<p></p>';
			setTimeout(() => {
				this.currentTemplate = this.prevTemplate;
				this.saveCompleted.emit({
					isSuccess: true,
					isNext: false,
					redirect: true,
				});
				obs.complete();
			}, 100);
		});

		const close = new Observable((obs: Observer<any>) => {
			this.toggleLoading.emit(false);
			obs.complete();
		});

		this.toggleModal(confirm, decline, close);
	}

	togglePauseAndExit() {
		const confirm = new Observable((obs: Observer<any>) => {
			// this.saveCurrentPage.emit('Documents');
			this.save(false, false);
			this.crtMortageService.setReviewApplicationChangeValue('confirm');
			this.crtMortageService.setReviewApplicationModal(false);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.doResetStores.emit(true);
			this.crtMortageService.setReviewApplicationChangeValue('confirm');
			this.crtMortageService.setReviewApplicationModal(false);
			obs.complete();
		});

		const close = new Observable((obs: Observer<any>) => {
			this.crtMortageService.setReviewApplicationChangeValue(null);
			this.crtMortageService.setReviewApplicationModal(false);
			obs.complete();
		});

		this.toggleModal(confirm, decline, close);
	}

	toggleModal(confirm, decline, close) {
		const initState = {
			header: 'Leave Review Application Summary?',
			message: `Changes that you made may not be saved.`,
			confirm$: confirm,
			decline$: decline,
			close$: close,
			confirmTxt: 'Save',
			cancelTxt: 'Leave',
			detachCloseIcon: true,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	navigateToUrl(path) {
		if (isNil(path)) {
			this.service.setTriggerLeaveSoa(null);
		} else {
			const urlArr = this.router.url?.split('/') ?? [];
			const baseUrl = urlArr?.slice(1, 8)?.join('/');
			const navigateTo = `${baseUrl}/${path}`;
			this.router.navigateByUrl(navigateTo);
		}
	}

	resetLeaveSoa() {
		this.service.setHasSoaChanges(false);
		this.service.setTriggerLeaveSoa(null);
		this.isAutoSaveEnabled = false;
	}

	ngOnDestroy() {
		this.resetLeaveSoa();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	private getPdfHeaderFooterOptions() {
		return this.themeConfig$.pipe(
			withLatestFrom(this.businessConfigQuery.businessOATLogo$),
			map(([theme, oatLogo]) => {
				// Header config For PDF
				const HeaderHtmlUrlParam = new URLSearchParams({
					headerText: 'Diary Note',
					headerLogo: oatLogo?.toString()?.trim() || '',
				})?.toString();
				// Footer config for PDF
				const FooterHtmlUrlParam = new URLSearchParams({
					footerColor: theme?.primarycolor || '#00263e',
				})?.toString();
				return {
					...PdfDesignV2Options,
					HeaderHtmlUrlParam,
					FooterHtmlUrlParam,
				} as HtmlPdfConfigState;
			}),
			map((options) => {
				const otherHeaderOptions = new URLSearchParams({
					startHeaderOnPage: '1',
				})?.toString();
				const otherFooterOptions = new URLSearchParams({
					startPageNumberOn: '1',
				})?.toString();
				return {
					...options,
					FooterHtmlUrlParam: `${options?.FooterHtmlUrlParam}&${otherFooterOptions}`,
					HeaderHtmlUrlParam: `${options?.HeaderHtmlUrlParam}&${otherHeaderOptions}`,
				};
			}),
			take(1)
		);
	}
}
