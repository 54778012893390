import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserQuery } from 'src/app/domain/user/user.query';
import { AnnouncementPopupService } from './announcement-popup.service';

@Injectable()
export class AnnouncementPopupGuard implements CanActivate {
	constructor(
		private announcementPopupService: AnnouncementPopupService,
		private userQuery: UserQuery
	) {}

	canActivate(): Observable<boolean> {
		return this.userQuery.userId$.pipe(
			mergeMap((staffId) =>
				staffId ? this.announcementPopupService.show(staffId) : of(true)
			)
		);
	}
}
