<button
  id="closeModalBtn"
  class="tap-btn tap-btn-outline modal-btn-close"
  (click)="bsModalRef.hide()"
>
  <span class="material-icons"> close </span>
</button>
<div class="modal-header">
  <h5 class="modal-title">
    {{ header }}
  </h5>
</div>
<div>
  <form novalidate>
    <div class="form-group m-0">
      <div class="clearfix text-center mb-2">
        {{ subHeader }}
      </div>
      <div class="clearfix border w-100">
        <div
          #signatureContainer
          class="signature-pad-container clearfix position-relative"
        >
          <app-signature-field
            [formControl]="signature"
            class="position-relative d-block clearfix"
          ></app-signature-field>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-lg-between flex-row-reverse modal-footer"
    >
      <ng-container *ngIf="!isSaving; else loader">
        <button type="button" class="tap-btn tap-btn--default" (click)="save()" id="saveBtn">
          Save
        </button>
        <button
          id="clearBtn"
          type="button"
          class="tap-btn tap-btn--default"
          data-dismiss="modal"
          (click)="clear()"
        >
          Clear
        </button>
      </ng-container>
      <ng-template #loader>
        <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
      </ng-template>
    </div>
  </form>
</div>
