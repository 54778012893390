import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { HouseInsuranceMapper } from '../../../../../../shared/models/client-review-template/fg-insurance/house-insurance.mapper';
import { HouseInsuranceState } from '../../../../../../shared/models/client-review-template/fg-insurance/house-insurance.model';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { NoWhitespaceValidator } from '../../../../../directive/no-whitespace/no-whitespace.directive';
import { Observable, Subject, takeUntil } from 'rxjs';
import { logMessage } from '@errorMsgs';

@Component({
	selector: 'app-house-form',
	templateUrl: './house-form.html',
	styleUrls: ['./house-form.scss'],
})
export class HouseFormComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Output() cancelEvent = new EventEmitter<any>();
	@Output() saveEvent = new EventEmitter<any>();
	@Input() currentProvider$: Observable<ViewDisplayValue[]>;
	@Input() APCRTCLC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() cRTId: number;
	@Input() isAdd: boolean;
	@Input() cRTInfo: HouseInsuranceState;
	@Input() viewMode: boolean;
	@Input() loading: boolean;
	@Input() loatv2Enabled: boolean;

	public bsModalRef: BsModalRef;

	form: UntypedFormGroup;
	showClaimInLast12Months = false;
	submitted = false;

	cancelLoading: boolean;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		if (!this.isAdd) {
			this.form.reset(HouseInsuranceMapper.mapToView(this.cRTInfo));
			this.selectChange();
		}
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);

		this.watchFormChanges();
	}

	get LastReviewed() {
		return this.form.get('lastReviewed');
	}
	get HasClaimedInLast12Months() {
		return this.form.get('hasClaimedInLast12Months');
	}
	get FloorArea() {
		return this.form.get('floorArea');
	}
	get HouseType() {
		return this.form.get('houseType');
	}
	get Pool() {
		return this.form.get('pool');
	}
	get InternalGarage() {
		return this.form.get('internalGarage');
	}
	get RoofConstruction() {
		return this.form.get('roofConstruction');
	}
	get WallConstruction() {
		return this.form.get('wallConstruction');
	}
	get Deck() {
		return this.form.get('deck');
	}
	get YearBuilt() {
		return this.form.get('yearBuilt');
	}
	get HouseCondition() {
		return this.form.get('houseCondition');
	}
	get HistoricalBuilding() {
		return this.form.get('historicalBuilding');
	}
	get Alarmed() {
		return this.form.get('alarmed');
	}
	get Address() {
		return this.form.get('address');
	}
	get Provider() {
		return this.form.get('provider');
	}
	get Premium() {
		return this.form.get('premium');
	}
	get WhatHappened() {
		return this.form.get('whatHappened');
	}
	get WhatHappenedOther() {
		return this.form.get('whatHappenedOther');
	}

	watchFormChanges() {
		this.WhatHappenedOther.valueChanges.pipe(takeUntil(this.onDestroy$))
			.subscribe((value) => {
				if(!value && !this.WhatHappenedOther.pristine) {
					this.WhatHappened.setValue('');
				}
		});
		this.WhatHappened.valueChanges
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((value) => {
				if (value === 'Other') {
					this.WhatHappenedOther.setValidators(Validators.required);
				} else {
					this.WhatHappenedOther.clearValidators();
					this.WhatHappenedOther.markAsPristine();
				}
				this.WhatHappenedOther.updateValueAndValidity();
			});
	}

	buildForm() {
		this.form = this.fb.group({
			address: ['', [Validators.required, NoWhitespaceValidator]],
			provider: ['', Validators.required],
			replacementValue: [''],
			premium: [''],
			lastReviewed: [''],
			currentExcess: [''],
			hasClaimedInLast12Months: [''],
			driverName: [''],
			whatHappened: [''],
			whatHappenedOther: [''],
			date: [''],
			cost: [''],
			floorArea: [''],
			houseType: [''],
			pool: [''],
			internalGarage: [''],
			roofConstruction: [''],
			wallConstruction: [''],
			deck: [''],
			yearBuilt: [''],
			houseCondition: [''],
			historicalBuilding: [''],
			alarmed: [''],
		});
	}

	selectChange() {
		this.showClaimInLast12Months = this.HasClaimedInLast12Months.value === 'Yes' ? true : false;
	}

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		const adviceProcessId = parseInt(
			this.route.snapshot.paramMap.get('adviceProcessId'),
			10
		);
		const data = HouseInsuranceMapper.mapToUpsert(
			this.form.value,
			+this.cRTId,
			+adviceProcessId
		);
		this.saveEvent.emit(data);
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
