<div class="app-adduser-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Theme</h1>
	</div>
	<div class="app-adduser-content-container">
		<div class="container-fluid px-0">
			<div class="row tap-container justify-content-center">
				<div class="col-xl-10 pt-3">
					<h6>Your interface theme</h6>
				</div>
			</div>
			<div class="row tap-container justify-content-center">
				<div class="col-xl-10 scc-border mb-2"></div>
			</div>
			<form [formGroup]="dataForm" novalidate>
				<div class="row tap-container justify-content-center">
					<div class="col-xl-10 row px-0">
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">Primary Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="primarycolor"
										id="primarycolor"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">Secondary Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="secondarycolor"
										id="secondarycolor"
									/>
								</div>
							</div>
						</div>
						<!-- <div class="col-md-4">
              <div class="form-group form-inline row mb-1">
                <div class="col-lg-4 pr-0">
                  <label for="">Tertiary Colour</label>
                </div>
                <div class="col-lg-8">
                  <input #input type="text" class="form-control" formControlName="tertiarycolor" id="tertiarycolor" />
                </div>
              </div>
            </div> -->
					</div>
				</div>

				<div class="row tap-container justify-content-center">
					<div class="col-xl-10 row px-0">
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<!-- <label for="">Heading 1</label> -->
									<label for="">Heading Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="h1color"
										id="h1color"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<!-- <label for="">Heading 6</label> -->
									<label for="">Icon Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="h6color"
										id="h6color"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row tap-container justify-content-center">
					<div class="col-xl-10 row px-0">
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">Field Background Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="fieldBackgroundColor"
										id="fieldBackgroundColor"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">Field Text Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="fieldTextColor"
										id="fieldTextColor"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row tap-container justify-content-center">
					<div class="col-xl-10 row px-0">
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<!-- <label for="">Heading 5</label> -->
									<label for="">Widget Table Heading Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="h5color"
										id="h5color"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<!-- <label for="">Heading 4</label> -->
									<label for="">Widget Table Text Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="h4color"
										id="h4color"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">SOA Heading Colour</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="soaHeadingColor"
										id="soaHeadingColor"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row tap-container justify-content-center d-none">
					<div class="col-xl-10 row px-0">
						<div class="col-md-4 pr-md-0 d-none">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">Heading 2</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="h2color"
										id="h2color"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0 d-none">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">Heading 3</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="h3color"
										id="h3color"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row tap-container justify-content-center">
					<div class="col-xl-10 row px-0">
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="widgetcolor1">Widget Colour 1</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="widgetcolor1"
										id="widgetcolor1"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="widgetcolor2">Widget Colour 2</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="widgetcolor2"
										id="widgetcolor2"
									/>
								</div>
							</div>
						</div>
						<div class="col-md-4 pr-md-0">
							<div class="form-group form-inline row mb-1">
								<div class="col-lg-5 pr-0">
									<label for="">Widget Colour 3</label>
								</div>
								<div class="col-lg-7">
									<input
										#input
										type="text"
										class="form-control"
										formControlName="tertiarycolor"
										id="tertiarycolor"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

			<div class="row tap-container justify-content-center">
				<div class="col-xl-10 row pt-2 pb-5">
					<span [hidden]="isLoading$ | async">
						<button
							[appTheme]="{ 'background-color': 'primarycolor' }"
							type="button"
							class="btn btn-primary mr-2"
							#save
						>
							Save
						</button>
						<button type="button" class="btn btn-light" #cancel>Cancel</button>
					</span>
					<!-- <app-loader-mini service="ActionLoaderService"></app-loader-mini> -->
					<app-manual-loader-mini
						[isLoading]="isLoading$ | async"
					></app-manual-loader-mini>
				</div>
			</div>
		</div>
	</div>
</div>
