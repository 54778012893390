import { util } from 'src/app/util/util';
import { AdviceProcessSectionCodes } from '../../../../../../../../../shared/models/advice-process/advice-process.model';
import { LoanRefinanceState } from './loan-refinance.model';

export class LoanRefinanceMapper {
	public static mapToView(data: LoanRefinanceState[]) {
		return (
			data?.map((x) => ({
				...x,
				security:
					x?.security && util.tryCatchParse(x?.security)
						? JSON.parse(x?.security)
						: x?.security,
				cRTId: x?.cRTId > 0 ? x?.cRTId : null,
			})) || []
		);
	}

	public static mapToUpsert(data): LoanRefinanceState {
		const { isLoading, ...loanRefinanceData } = data;
		return {
			...loanRefinanceData,
			adviceProcessId: +data?.adviceProcessId,
			parentCRTId: +data?.parentCRTId,
			security: loanRefinanceData?.security
				? JSON.stringify(loanRefinanceData?.security)
				: loanRefinanceData?.security,
			sectionCode: AdviceProcessSectionCodes.LoanRefinance,
			sourceType: data.sourceType ?? 'M',
			cRTId: data?.cRTId || null,
			status: 1,
		};
	}
}
