import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BookBreakdownStore, BookBreakdownState } from './bb.store';

/**
 * Query for Book breakdown widget data
 */
@Injectable()
export class BookBreakdownQuery extends Query<BookBreakdownState> {

  /** Book Breakdown data. */
  BookBreakdownData$ = this.select(state =>
    state.bookBreakdowns
  );

  /** Indicator if Book Breakdown is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for Book Breakdown */
  error$ = this.select(state => state.error);

  constructor(protected store: BookBreakdownStore) {
    super(store);
  }
}
