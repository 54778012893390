import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { UserQuery } from '../../../domain/user/user.query';
import { RouteService } from '../../../core/config/route.service';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { combineLatest, map } from 'rxjs';
import { environment as env } from '@environment';

@Component({
  selector: 'app-business-sidenav',
  templateUrl: './business-sidenav.component.html',
  styleUrls: ['./business-sidenav.component.scss'],
})
export class BusinessSidenavComponent implements OnInit {
  constructor(
    private userQuery: UserQuery,
    private routeService: RouteService,
    private authService: AuthenticationService,
    private businessProfileService: BusinessProfileService
  ) {}
  name: string;
  isTapLevelUser = false;
  profilePic = '';
  tapLevelLogoUrl = `${env.defaultImgUrl}/logo.png`;
  googleImageUrl: string;
	staffId: number;

  isAppearanceCollapsed = true;
  isUsersCollapsed = true;
  isViewsCollapsed = true;
  isSettingsCollapsed = true;
	isViewingUserDetailsOnly = false;

  BusinessLink = '';
  UserListLink = '';
  UserAddLink = '';
  DropdownLink = '';
  ThemeLink = '';
  LogoLink = '';
  ActivitiesLink = '';
  PrimaryCustomerLink = '';
  SecondaryCustomerLink = '';
  ServicesLink = '';
  WidgetsLink = '';
  DataImportLink = '';
  EmailsLink = '';
  DownloadDocuments = '';
  AdviceProcess = '';
  ReferralOptions = '';
	CrtSettings = '';
	MortgageSettings = '';
  CPSettings = '';
	CARSettings = '';
  KiwiSaverSettings = '';
	UserDetailsLink = '';
  PDGoalsLink = '';
  SpecialFeatureLink = '';

  hasService = {
    hasLR: true,
    hasLOAT: true,
    hasM: true,
    hasMOAT: true,
    hasK: true,
    hasKOAT: true,
		hasCAR: true,
  };

  ngOnInit() {
    this.userQuery.userInfo$?.forEach((x) => {
			this.staffId = +x?.StaffID;
      this.name = x.FirstName + ' ' + x.LastName;
      this.googleImageUrl = this.authService.user
        ? this.authService.user.picture
        : '';
			this.isViewingUserDetailsOnly =
				!x?.SecurityRoles?.includes('BUA') &&
				!['LG', 'LGI', 'CCA']?.includes(x?.SecurityGroup); // TAPNZ-13088
    });
    this.userQuery.isTapLevel$.subscribe((x) => (this.isTapLevelUser = x));
    this.userQuery.profilePic$.subscribe((x) => (this.profilePic = x));
    this.routeService.RouteChange$.subscribe((x) => {
      this.BusinessLink = x.business;
      this.UserListLink = x.usersList;
      this.UserAddLink = x.usersAdd;
      this.DropdownLink = x.dropdown;
      this.ThemeLink = x.theme;
      this.LogoLink = x.logo;

      this.ActivitiesLink = x.activities;
      this.PrimaryCustomerLink = x.primaryCustomer;
      this.SecondaryCustomerLink = x.secondaryCustomer;
      this.ServicesLink = x.services;
      this.WidgetsLink = x.widgets;
      this.DataImportLink = x.dataImport;
      this.EmailsLink = x.emails;
      this.DownloadDocuments = x.DownloadDocuments;
      this.AdviceProcess = x.AdviceProcess;
      this.ReferralOptions = x.ReferralOptions;
			this.CrtSettings = 'crt-settings';
			this.MortgageSettings = x.MortgageSettings;
      this.CPSettings = `/${x.cCode}/${x.adminCustomerPortalSettings}`;
			this.CARSettings = x.CARSettings;
      this.KiwiSaverSettings = 'kiwisaver-settings';
			this.UserDetailsLink = x.usersView(this.staffId);

      this.PDGoalsLink = x.pdGoals;
      this.SpecialFeatureLink = x.sfSettings;
    });

    this.checkHasService();
  }

  private checkHasService(): void {
    const  hasService$ = combineLatest([
      this.businessProfileService.hasLR$,
      this.businessProfileService.hasCRT$,
      this.businessProfileService.hasM$,
      this.businessProfileService.hasMOAT$,
      this.businessProfileService.hasK$,
      this.businessProfileService.hasKOAT$,
			this.businessProfileService.hasClientAlterationRequest$,
    ]).pipe(
      map(([hasLR, hasLOAT, hasM, hasMOAT, hasK, hasKOAT, hasCAR]) => {
        return {hasLR, hasLOAT, hasM, hasMOAT, hasK, hasKOAT, hasCAR }
      })
    );

    hasService$.subscribe(x => {
      this.hasService = x;
    });
  }
}
