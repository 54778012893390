import * as R from 'ramda';
import { numUtil } from '../../../../../../util/util';

/*
 * For CRT LOAT Advice Process > SOA > Proposed Insurance Structure
 */
const proposedInsuranceStructureTd = (lifeAssured) => {
	const tds = [];
	lifeAssured?.forEach((data) => {
		const td = `
		<td>${data?.provider || ''}</td>
		<td>${data?.product || ''}</td>
		<td>${data?.benefit || ''}</td>
		<td>${data?.notes?.replace(/\n|\t/g, '<br>')  || ''}</td>
	`;
		tds.push(`<tr>${td}</tr>`);
	});
	return tds?.join('')?.toString();
};

const proposedInsuranceStructureTable = (combinedTR: string) => {
	return `
		<table style="width: 100%;">
			<thead>
				<tr>
					<th style="width: 20%;">Provider</th>
					<th style="width: 20%;">Product</th>
					<th style="width: 20%;">Benefit</th>
					<th style="width: 40%;">Product Detail</th>
				</tr>
			</thead>
			<tbody>${combinedTR}</tbody>
    </table>
  `;
};

export const createProposedInsuranceStructure = {
	td: proposedInsuranceStructureTd,
	table: proposedInsuranceStructureTable,
};

/*
 * For CRT LOAT Advice Process > SOA > Proposed Insurance Premium
 */
const proposedInsurancePremiumTd = (lifeAssured) => {
	const tds = [];
	lifeAssured?.forEach((data) => {
		const td = `
		<td>${data?.provider || ''}</td>
		<td>${data?.product || ''}</td>
		<td>${data?.benefit || ''}</td>
		<td>${data?.newExisting || ''}</td>
		<td>${
			isValidNumber(+data?.premium)
				? numUtil.formatToCurrency(data?.premium)
				: ''
		}</td>
	`;
		tds.push(`<tr>${td}</tr>`);
	});
	return tds?.join('')?.toString();
};

const proposedInsurancePremiumTable = (combinedTR: string) => {
	return `
    <table style="width: 100%;">
			<thead>
				<tr>
					<th style="width: 25%;">Provider</th>
					<th style="width: 17.5%;">Product</th>
					<th style="width: 17.5%;">Benefit</th>
					<th style="width: 25%;">New/Replacement</th>
					<th style="width: 15%;">Premium</th>
				</tr>
			</thead>
			<tbody>
				${combinedTR}
			</tbody>
    </table>
  `;
};

const proposedInsurancePremiumTotalTable = (data) => {
	return `
		<table style="width: 100%; border: none!important;" border="0">
			<tbody>
				<tr>
					<td class="cellNoBorder" style="text-align:right; width: 85%; padding-left:0px!important;"><strong>Policy Fee:</strong></td>
					<td style="width: 15%;">${data?.policyFee}</td>
				</tr>
				<tr>
					<td class="cellNoBorder" style="text-align:right; width: 85%; padding-left:0px!important;"><strong>Total ${data?.paymentFrequency} Premium:</strong></td>
					<td style="width: 15%;">${data?.totalPremium}</td>
				</tr>
			</tbody>
		</table>
	 `;
};

export const createProposedInsurancePremium = {
	td: proposedInsurancePremiumTd,
	table: proposedInsurancePremiumTable,
	totalTable: proposedInsurancePremiumTotalTable,
};

/*
 * For CRT LOAT Advice Process > SOA > Current Insurance Premium
 */
const currentInsurancePremiumTd = (lifeAssured) => {
	const tds = [];
	lifeAssured?.forEach((data) => {
		const td = `
		<td>${data?.provider || ''}</td>
		<td>${data?.product || ''}</td>
		<td>${data?.benefit || ''}</td>
		<td>${data?.notes?.replace(/\n|\t/g, '<br>')  || ''}</td>
		<td>${
			isValidNumber(data?.premium)
				? numUtil.formatToCurrency(data?.premium)
				: ''
		}</td>
	`;
		tds.push(`<tr>${td}</tr>`);
	});
	return tds?.join('')?.toString();
};

const currentInsurancePremiumTable = (combinedTR: string) => {
	return `
    <table style="width: 100%;">
			<thead>
				<tr>
					<th style="width: 15%;">Provider</th>
					<th style="width: 15%;">Product</th>
					<th style="width: 15%;">Benefit</th>
					<th style="width: 40%;">Product Detail</th>
					<th style="width: 15%;">Premium</th>
				</tr>
			</thead>
			<tbody>
				${combinedTR}
			</tbody>
    </table>
  `;
};

const currentInsurancePremiumTotalTable = (data) => {
	return `
		<table style="width: 100%; border: none!important;" border="0">
			<tbody>
				<tr>
					<td class="cellNoBorder" style="text-align:right; width: 85%; padding-left:0px!important;"><strong>Policy Fee:</strong></td>
					<td style="width: 15%;">${data?.policyFee}</td>
				</tr>
				<tr>
					<td class="cellNoBorder" style="text-align:right; width: 85%; padding-left:0px!important;"><strong>Total ${data?.paymentFrequency} Premium:</strong></td>
					<td style="width: 15%;">${data?.totalPremium}</td>
				</tr>
			</tbody>
		</table>
	 `;
};

export const createCurrentInsurancePremium = {
	td: currentInsurancePremiumTd,
	table: currentInsurancePremiumTable,
	totalTable: currentInsurancePremiumTotalTable,
};

const isValidNumber = (value) => {
	return (
		R.complement(R.either(R.isNil, R.isEmpty))(value) &&
		numUtil.isNumber(+value)
	);
};
