import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { UserPdTrackingModalComponent } from '@shared/modal/user-pd-tracking-modal/user-pd-tracking-modal.component';
import { DocumentTypes } from '@shared/models/documents/document.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	displayedColumns,
	tableColumns,
} from './user-pd-tracking-scheduled.table-config';
import { SimpleDataTableModel } from '@shared/models/_general/simple-data-table.model';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import {
	BlStaffPdTypes,
	BlStaffSettingTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { Observable, of } from 'rxjs';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import MomentUtil from '@util/moment.util';
import { util } from '@core/util/util.service';
import { staticConf } from '@core/config/static-config.service';

@Component({
	selector: 'app-user-pd-tracking-scheduled',
	templateUrl: './user-pd-tracking-scheduled.component.html',
	styleUrls: ['./user-pd-tracking-scheduled.component.scss'],
})
export class UserPdTrackingScheduledComponent implements OnInit {
	@Input() staff: BlStaffViewmodel;
	@Input() data: BlStaffSettingsModel[];
	@Input() isTapLevel: boolean;
	@Input() userSecurityGroup: string;
	@Input() loggedUserId: number;
	@Input() UPDTP: ViewDisplayValue[] = []; // Provider Dropdown
	@Input() UPDTC: ViewDisplayValue[] = []; // Category Dropdown
	@Input() UPDTS: ViewDisplayValue[] = []; // Status Dropdown
	@Input() upsertPdTrackingFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() downloadDocumentFn$: (data: {
		documentId: number;
		fileName: string;
	}) => Observable<any> = (data) => of(data);

	displayedColumns: string[] = [];
	tableColumns: SimpleDataTableModel[] = [];

	dataSource = [];
	modalTitle = 'Personal Development Tracking';

	get CanAdd() {
		return ['SO', 'SA', 'SM', 'BO', 'BAI', 'A', 'AM', 'BAV', 'BM']?.includes(
			this.userSecurityGroup
		);
	}
	get CanEditAnyEntries() {
		return ['SO', 'SM', 'BO', 'BM']?.includes(this.userSecurityGroup);
	}

	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef
	) {
		this.displayedColumns = displayedColumns;
	}

	ngOnInit(): void {
		this.setTableData(this.data);
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges?.data) {
			this.setTableData(this.data);
		}
	}

	setTableData(data: BlStaffSettingsModel[]) {
		this.tableColumns = tableColumns.map((x) => ({
			...x,
			actionCallback: x?.columnId === 'EditAction' ? this.editScheduled : null,
		}));

		this.dataSource = data?.map((x) => ({
			...x,
			ProviderBusiness:
				x?.Provider === 'Other' ? x?.OtherProvider : x?.Provider,
			class: this.dueDateStatus(x),
			DueDateFormatted: MomentUtil.formatToDisplayDate(
				MomentUtil.DateStringToMoment(x.DueDate),
				staticConf.displayDateFormat
			),
		}));

		this.cd.detectChanges();
	}

	dueDateStatus(data) {
		const due = this.formatValidDate(data?.DueDate);
		const nzToday = util.MomentNow();
		const isOverdue = due.diff(nzToday, 'days');
		return isOverdue < 0 ? 'over-due' : 'due-soon';
	}

	formatValidDate(value) {
		return util.isValidMoment(value)
			? value
			: MomentUtil.formatDateToMoment(value);
	}

	addUpcoming() {
		const statusList = this.UPDTS?.filter(
			(x) => x?.value !== BlStaffPdTypes.Completed
		);
		const initialState = {
			title: this.modalTitle,
			limitSingleDoc: true,
			toggleCompletedDate: true,
			staffId: this.staff?.StaffID,
			UPDTP: this.UPDTP,
			UPDTC: this.UPDTC,
			UPDTS: statusList,
			enableAllFields: true,
			initializeEdit: true,
			defaultStatus: BlStaffPdTypes.Upcoming,
			type: BlStaffSettingTypes.PersonalDevelopment,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Save',
			upsertFn$: this.upsertPdTrackingFn$,
			downloadDocumentFn$: this.downloadDocumentFn$,
		};
		this.modalService.show(UserPdTrackingModalComponent, {
			class:
				'modal-dialog-centered modal-dialog modal-lg user-pd-tracking-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	}

	editScheduled = (pd) => {
		const initialState = {
			title: this.modalTitle,
			data: pd,
			limitSingleDoc: true,
			toggleCompletedDate: true,
			staffId: this.staff?.StaffID,
			UPDTP: this.UPDTP,
			UPDTC: this.UPDTC,
			UPDTS: this.UPDTS,
			enableAllFields:
				this.CanEditAnyEntries || +this.loggedUserId === +pd?.CreatedByStaffId,
			initializeEdit: true,
			defaultStatus: BlStaffPdTypes.Upcoming,
			type: pd?.SettingsCode || BlStaffSettingTypes.PersonalDevelopment,
			documentTypeCode: DocumentTypes.UserTrackerDocument,
			btnText: 'Save',
			upsertFn$: this.upsertPdTrackingFn$,
			downloadDocumentFn$: this.downloadDocumentFn$,
		};
		this.modalService.show(UserPdTrackingModalComponent, {
			class:
				'modal-dialog-centered modal-dialog modal-lg user-pd-tracking-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState,
		});
	};
}
