<form [formGroup]="form" (ngSubmit)="getBtnClick()">
  <div class="container-fluid px-0">

    <div class="row tap-container">
      <div class="col mb-4"></div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-4 col-md-4 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              Email Type
            </label>
          </div>
          <div class="col-lg-8 col-md-8 px-0">
            <app-chips formControlName="emailCode" [choices]="emailType" textboxClass="theme-crm-field" id="emailCode">
            </app-chips>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="">
              Recipient First Name
            </label>
          </div>
          <div class="col-lg-2 col-md-2 px-0">
            <input class="form-control ng-untouched ng-pristine ng-valid" type="text" id="firstName"
              formControlName="firstName" />
          </div>
          <div class="col-lg-2 col-md-2 pl-lg-2 pl-0">
            <label for="" class="text-right">
              Recipient Last Name
            </label>
          </div>
          <div class="col-lg-2 col-md-2 px-0">
            <input class="form-control ng-untouched ng-pristine ng-valid" type="text" id="lastName"
              formControlName="lastName" />
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-4 col-md-4 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              Status
            </label>
          </div>
          <div class="col-lg-8 col-md-8 px-0">
            <app-chips formControlName="status" [choices]="emailStatus" textboxClass="theme-crm-field">
            </app-chips>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              Recipient Email Address
            </label>
          </div>
          <div class="col-lg-6 col-md-6 px-0">
            <input class="form-control ng-untouched ng-pristine ng-valid" type="text" id="emailAddress"
              formControlName="emailAddress" />
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-4 col-md-4 pr-md-0">
        <div class="form-group form-inline row mb-3">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              Date Range
            </label>
          </div>

          <div class='col-6 col-lg-4 col-md-4 pr-2 pl-0'>
            <app-date-input ngProjectAs="input" textboxClass="theme-crm-field-disabled" formControlName="emailDateMin"
              placeholder="dd/mm/yyyy" tabindex="4" textboxId="emailDateMin"></app-date-input>
          </div>
          <div class='col-6 col-lg-4 col-md-4 px-0'>
            <app-date-input ngProjectAs="input" textboxClass="theme-crm-field-disabled" formControlName="emailDateMax"
              placeholder="dd/mm/yyyy" tabindex="4" textboxId="emailDateMax"></app-date-input>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              Email Mode
            </label>
          </div>
          <div class="col-lg-6 col-md-6 px-0">
            <app-chips formControlName="emailMode" [choices]="emailModeList" textboxClass="theme-crm-field">
            </app-chips>
          </div>
        </div>
      </div>
    </div>


    <div class="row tap-container">
      <div class="col-lg-4 col-md-4 pr-md-0">
        <div class="form-group form-inline row mb-3">
          <div class="col-lg-2 col-md-2 px-0">
            <label for="" class="position-relative">
              History ID
            </label>
          </div>
          <div class='col-lg-8 col-md-8 px-0'>
            <input class="form-control" type="text" id="emailAddress"
               formControlName="emailHistoryId" />
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-md-8 pr-md-0">
        <div class="form-group form-inline row mb-0">
          <div class="col-lg-8 col-md-8 px-0 text-right">

            <button type="button" class="btn btn-light mr-2" id="" (click)="clearForm()">
              Clear
            </button>

            <button type="submit" class="btn btn-primary mr-2" *ngIf="!isSaving" id="searchReport" (click)="getBtnClick()">
              Search
            </button>
            <app-manual-loader-mini class="mr-2" *ngIf="isSaving" [isLoading]="isSaving"></app-manual-loader-mini>

            <ng-container *appRestrictTo="['BETX']">
              <button type="button" class="btn btn-primary" *ngIf="!isExport" (click)="export()">
                Export
              </button>
            </ng-container>
            <app-manual-loader-mini *ngIf="isExport" [isLoading]="isExport"></app-manual-loader-mini>
          </div>
        </div>
      </div>
    </div>


  </div>
</form>
