
<div *ngIf="isModalBlock" class="modal-block-header">
	<span>
		{{ header }}
	</span>
	<button
			type="button"
			aria-label="Close"
			class="close text-white border"
			(click)="bsModalRef.hide()"
		>
			<span class="material-icons md-16">close</span>
		</button>
	</div>
<div [ngClass]="isModalBlock ? 'modal-block-p' : 'p-2'">
	<button
		*ngIf="!isModalBlock"
		id="hideModalButton"
		class="tap-btn tap-btn-outline modal-btn-close"
		(click)="bsModalRef.hide()"
	>
		<span class="material-icons"> close </span>
	</button>
	<div *ngIf="!isModalBlock" class="modal-header">
		<h5 class="modal-title">
			{{ header }}
		</h5>
	</div>
	<div class="modal-body">
		<form novalidate [formGroup]="form" *ngIf="form">
			<ng-container [ngSwitch]="service">

				<!-- L&R -->
				<ng-container *ngSwitchCase="'LR'">
					<ng-container *ngIf="isComplete; else isLRInComplete">
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QCLR7 Details' &&
												form.get('QCLR5')?.value === 'Yes') ||
											(q.questionKey === 'QCLR2 Details' &&
												!isUploadedAllDocuments) ||
											(q.questionKey === 'QCLR4 Details' &&
												form.get('QCLR4')?.value === 'No')
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Date'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QCLR4 Date' &&
												form.get('QCLR4')?.value === 'Yes' &&
												adviceProcess.processCode ===
													processCodes.LRAdviceNew) ||
											(q.questionKey === 'QCLR4 Date' &&
												adviceProcess.processCode ===
													processCodes.LRAdviceReview)
										"
									>
										<div class="form-group row align-items-baseline">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<div class="clearfix">
													<app-date-input
														[textboxId]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														textboxClass="theme-crm-field-disabled"
														placeholder="dd/mm/yyyy"
													></app-date-input>
													<div class="clearfix mt-2">
														<button
															id="addActivityButton"
															class="tap-btn tap-btn--default"
															type="button"
															(click)="addActivity(q.questionKey)"
														>
															Create Activity
														</button>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCLR6'">
									<ng-container *ngIf="form.get('QCLR5')?.value === 'Yes'">
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'External'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>External</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'Internal'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Internal</label
													>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCLR7'">
									<ng-container *ngIf="form.get('QCLR5')?.value === 'Yes'">
										<div class="form-group row align-items-center">
											<div *ngIf="q.dataType !== 'Label'" class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div class="col-md-5">
												<app-chips
													[id]="generateControl(q.questionKey) + i"
													[textboxId]="generateControl(q.questionKey) + i"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													[choices]="apcrtqclr7Choices$ | async"
												></app-chips>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCLR8'">
									<ng-container>
										<div class="form-group align-items-center">
											<div class="row">
												<div class="col-12">{{ q?.question }}</div>
											</div>
											<div [formArrayName]="q?.questionKey">
												<div
													class="row mb-1 mt-1"
													*ngFor="
														let client of this.getFormArrayControl(
															q?.questionKey
														)?.controls;
														let i = index
													"
													[formGroupName]="i"
												>
													<div class="col-4 d-flex align-items-center">
														<input
															id="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															class="chckbox mr-1"
															type="checkbox"
															formControlName="isVulnerable"
															(change)="
																toggleVulnerableNotes(q?.questionKey, i)
															"
														/>
														<label
															for="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															id="{{ q?.questionKey }}_clientInvolvedName_{{
																i
															}}"
															>{{ getPeopleName(client?.value?.id) }}</label
														>
													</div>
													<div
														class="col-8"
														*ngIf="client.get('isVulnerable')?.value === true"
													>
														<textarea
															id="{{ q?.questionKey }}_clientInvolvedNotes_{{
																i
															}}"
															class="form-control"
															formControlName="notes"
															rows="2"
															placeholder="Notes"
															[readonly]="!!isViewSummary"
														></textarea>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<ng-container
										*ngIf="
											q.questionKey !== 'QCLR2' ||
											(q.questionKey === 'QCLR2' && !isUploadedAllDocuments)
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'Yes'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Yes</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'No'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>No</label
													>
												</div>
											</div>
											<ng-container
												*ngIf="
													q.questionKey === 'QCLR2' &&
													!isUploadedAllDocuments &&
													missingDocuments?.length > 0
												"
											>
												<div class="col-12">
													<ul class="missing-documents">
														<li *ngFor="let doc of missingDocuments">
															{{ doc?.field }}
														</li>
													</ul>
												</div>
											</ng-container>
										</div>
									</ng-container>
									<ng-container
										*ngIf="
											q.questionKey === 'QCLR3' &&
											form.get('QCLR3')?.value === 'Yes'
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-7"></div>
											<div *ngIf="q.dataType !== 'Label'" class="col-5">
												<button
													id="addActivityButton"
													class="tap-btn tap-btn--default"
													type="button"
													(click)="addActivity()"
												>
													Create Activity
												</button>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-container>
					<ng-template #isLRInComplete>
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QILR1 Details' &&
												form.get('QILR4')?.value === 'No') ||
											(q.questionKey === 'QILR2 Details' &&
												form.get('QILR2')?.value === 'No') ||
											(q.questionKey === 'QILR3 Details' &&
												form.get('QILR3')?.value === 'No') ||
											(q.questionKey === 'QILR5 Details' &&
												form.get('QILR5')?.value === 'Yes' &&
												form.get('QILR4')?.value === 'Yes') ||
											(q.questionKey === 'QILR6 Details' &&
												form.get('QILR4')?.value === 'Yes')
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Date'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QILR2 Date' && adviceProcess.processCode === 'APLN' && form.get('QILR2')?.value === 'Yes') ||
											(q.questionKey === 'QILR2 Date' && adviceProcess.processCode === 'APLR')
										"
									>
										<div class="form-group row align-items-baseline">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<div class="clearfix mb-2">
													<app-date-input
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[id]="form.controls[generateControl(q.questionKey)]"
														[textboxId]="generateControl(q.questionKey) + i"
														textboxClass="theme-crm-field-disabled"
														[placeholder]="
															generateControl(q.questionKey) === 'QILR2Date'
																? 'Enter Next Review Date'
																: 'dd/mm/yyyy'
														"
													></app-date-input>
												</div>
												<div class="clearfix">
													<button
														id="addActivityButton"
														class="tap-btn tap-btn--default"
														type="button"
														(click)="addActivity()"
													>
														Create Activity
													</button>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QILR1'">
									<ng-container *ngIf="form.get('QILR4')?.value === 'No'">
										<div class="form-group row align-items-center">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<span
													class="custom-dropdown w-100"
													*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
												>
													<select
														#otherSelect
														[id]="'otherSelect_' + q.questionKey"
														class="form-control theme-crm-field-disabled"
														(change)="
															onChangeDropdownOther($event, q.questionKey)
														"
														[value]="dropdownOtherValue(q.questionKey)"
													>
														<option value=""></option>
														<option
															*ngFor="let d of apcrtqi1Choices$ | async"
															[value]="d?.value"
															[selected]="
																d?.value === dropdownOtherValue(q.questionKey)
															"
														>
															{{ d?.value }}
														</option>
													</select>
												</span>
												<span
													*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
												>
													<input
														#otherInput
														[id]="'otherInput_' + q.questionKey"
														(keyup)="onChangeInputOther($event, q.questionKey)"
														(focusout)="
															onChangeInputOther($event, q.questionKey)
														"
														class="form-control"
														placeholder="Other"
														type="text"
														[value]="inputOtherValue(q.questionKey)"
													/>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QILR6'">
									<ng-container *ngIf="form.get('QILR4')?.value === 'Yes'">
										<div class="form-group row align-items-center">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<span
													class="custom-dropdown w-100"
													*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
												>
													<select
														#otherSelect
														[id]="'otherSelect_' + q.questionKey"
														class="form-control theme-crm-field-disabled"
														(change)="
															onChangeDropdownOther($event, q.questionKey)
														"
														[value]="dropdownOtherValue(q.questionKey)"
													>
														<option value=""></option>
														<option
															*ngFor="let d of apcrtqi6Choices$ | async"
															[value]="d?.value"
															[selected]="
																d?.value === dropdownOtherValue(q.questionKey)
															"
														>
															{{ d?.value }}
														</option>
													</select>
												</span>
												<span
													*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
												>
													<input
														#otherInput
														[id]="'otherInput_' + q.questionKey"
														(keyup)="onChangeInputOther($event, q.questionKey)"
														(focusout)="
															onChangeInputOther($event, q.questionKey)
														"
														class="form-control"
														placeholder="Other"
														type="text"
														[value]="inputOtherValue(q.questionKey)"
													/>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QILR7'">
									<ng-container>
										<div class="form-group align-items-center">
											<div class="row">
												<div class="col-12">{{ q?.question }}</div>
											</div>
											<div [formArrayName]="q?.questionKey">
												<div
													class="row mb-1 mt-1"
													*ngFor="
														let client of this.getFormArrayControl(
															q?.questionKey
														)?.controls;
														let i = index
													"
													[formGroupName]="i"
												>
													<div class="col-4 d-flex align-items-center">
														<input
															id="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															class="chckbox mr-1"
															type="checkbox"
															formControlName="isVulnerable"
															(change)="
																toggleVulnerableNotes(q?.questionKey, i)
															"
														/>
														<label
															for="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															id="{{ q?.questionKey }}_clientInvolvedName_{{
																i
															}}"
															>{{ getPeopleName(client?.value?.id) }}</label
														>
													</div>
													<div
														class="col-8"
														*ngIf="client.get('isVulnerable')?.value === true"
													>
														<textarea
															id="{{ q?.questionKey }}_clientInvolvedNotes_{{
																i
															}}"
															class="form-control"
															formControlName="notes"
															rows="2"
															placeholder="Notes"
															[readonly]="!!isViewSummary"
														></textarea>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<ng-container
										*ngIf="
											q.questionKey !== 'QILR5' ||
											(q.questionKey === 'QILR5' &&
												form.get('QILR4')?.value === 'Yes')
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'Yes'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Yes</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'No'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>No</label
													>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-template>
				</ng-container>

				<!-- Mortgage -->
				<ng-container *ngSwitchCase="'M'">
					<ng-container *ngIf="isComplete; else isMInComplete">
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											(adviceProcess.processCode === processCodes.MortgageAdvice &&
												(
													(q.questionKey === 'QCM1 Details' && form.get('QCM1')?.value === 'No' && showOldMortgageQuestions) ||
													(q.questionKey === 'QCM2 Details' && !isUploadedAllDocuments) ||
													(q.questionKey === 'QCM8 Details' && form.get('QCM8')?.value === 'No' && !showOldMortgageQuestions) ||
													(q.questionKey === 'QCM4 Details' && form.get('QCM4')?.value === 'Yes' && !showOldMortgageQuestions) ||
													(q.questionKey === 'QCM5 Details' && form.get('QCM4')?.value === 'Yes' && !showOldMortgageQuestions) ||
													(q.questionKey === 'QCM6 Details' && !showOldMortgageQuestions)
												)
											) ||
											(adviceProcess.processCode === processCodes.MortgageRefix &&
												(
													(q.questionKey === 'QCM1 Details' && form.get('QCM1')?.value === 'No') ||
													(q.questionKey === 'QCM2 Details' && !isUploadedAllDocuments)
												)
											)

										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCM3'">
									<ng-container *ngIf="showOldMortgageQuestions || adviceProcess.processCode === processCodes.MortgageRefix">
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCM6'">
									<div
										class="form-group row align-items-center"
										*ngIf="!showOldMortgageQuestions"
									>
										<div class="col-12 align-self-start mb-2">
											{{ q.question }}
										</div>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'QCM7'">
									<div
										class="form-group align-items-center"
										*ngIf="!showOldMortgageQuestions"
									>
										<div class="row">
											<div class="col-12">{{ q?.question }}</div>
										</div>
										<div [formArrayName]="q?.questionKey">
											<div
												class="row mb-1 mt-1"
												*ngFor="
													let client of this.getFormArrayControl(
														q?.questionKey
													)?.controls;
													let i = index
												"
												[formGroupName]="i"
											>
												<div class="col-4 d-flex align-items-center">
													<input
														id="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														class="chckbox mr-1"
														type="checkbox"
														formControlName="isVulnerable"
														(change)="
															toggleVulnerableNotes(q?.questionKey, i)
														"
													/>
													<label
														for="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														id="{{ q?.questionKey }}_clientInvolvedName_{{
															i
														}}"
														>{{ getPeopleName(client?.value?.id) }}</label
													>
												</div>
												<div
													class="col-8"
													*ngIf="client.get('isVulnerable')?.value === true"
												>
													<textarea
														id="{{ q?.questionKey }}_clientInvolvedNotes_{{
															i
														}}"
														class="form-control"
														formControlName="notes"
														rows="2"
														placeholder="Notes"
														[readonly]="!!isViewSummary"
													></textarea>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<ng-container
										*ngIf="
											(adviceProcess.processCode === processCodes.MortgageAdvice &&
												(
													(q.questionKey === 'QCM1' && showOldMortgageQuestions) ||
													(q.questionKey === 'QCM2' && !isUploadedAllDocuments) ||
													(q.questionKey === 'QCM3' && showOldMortgageQuestions) ||
													(!['QCM1', 'QCM2', 'QCM3']?.includes(q.questionKey) && !showOldMortgageQuestions)
												)
											) ||
											(adviceProcess.processCode === processCodes.MortgageRefix &&
												(
													q.questionKey !== 'QCM2' || (q.questionKey === 'QCM2' && !isUploadedAllDocuments)
												)
											)

										"
									>
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'Yes'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Yes</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'No'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>No</label
													>
												</div>
											</div>
										</div>
										<ng-container
											*ngIf="
												q.questionKey === 'QCM2' &&
												!isUploadedAllDocuments &&
												missingDocuments?.length > 0
											"
										>
											<div class="col-12 mb-3">
												<ul class="missing-documents">
													<li *ngFor="let doc of missingDocuments">
														{{ doc?.field }}
													</li>
												</ul>
											</div>
										</ng-container>
									</ng-container>
									<ng-container
										*ngIf="
											q.questionKey === 'QCM8' &&
											form.get('QCM8')?.value === 'Yes' &&
											!showOldMortgageQuestions
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-7"></div>
											<div *ngIf="q.dataType !== 'Label'" class="col-5">
												<button
													id="addActivityButton"
													class="tap-btn tap-btn--default"
													type="button"
													(click)="addActivity()"
												>
													Create Activity
												</button>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-container>
					<ng-template #isMInComplete>
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											q.questionKey === 'QIM1 Details' ||
											(q.questionKey === 'QIM2 Details' &&
												form.get('QIM2')?.value === 'No')
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QIM1'">
									<div class="form-group row align-items-center">
										<div class="col-5">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*
											</span>
											{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-7">
											<span
												class="custom-dropdown w-100"
												*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
											>
												<select
													#otherSelect
													[id]="'otherSelect_' + q.questionKey"
													class="form-control theme-crm-field-disabled"
													(change)="
														onChangeDropdownOther($event, q.questionKey)
													"
													[value]="dropdownOtherValue(q.questionKey)"
												>
													<option value=""></option>
													<option
														*ngFor="let d of apcrtqi1Choices$ | async"
														[value]="d?.value"
														[selected]="
															d?.value === dropdownOtherValue(q.questionKey)
														"
													>
														{{ d?.value }}
													</option>
												</select>
											</span>
											<span
												*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
											>
												<input
													#otherInput
													[id]="'otherInput_' + q.questionKey"
													(keyup)="onChangeInputOther($event, q.questionKey)"
													(focusout)="
														onChangeInputOther($event, q.questionKey)
													"
													class="form-control"
													placeholder="Other"
													type="text"
													[value]="inputOtherValue(q.questionKey)"
												/>
											</span>
										</div>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'QIM3'">
									<ng-container *ngIf="!showOldMortgageQuestions">
										<div class="form-group align-items-center">
											<div class="row">
												<div class="col-12">{{ q?.question }}</div>
											</div>
											<div [formArrayName]="q?.questionKey">
												<div
													class="row mb-1 mt-1"
													*ngFor="
														let client of this.getFormArrayControl(
															q?.questionKey
														)?.controls;
														let i = index
													"
													[formGroupName]="i"
												>
													<div class="col-4 d-flex align-items-center">
														<input
															id="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															class="chckbox mr-1"
															type="checkbox"
															formControlName="isVulnerable"
															(change)="
																toggleVulnerableNotes(q?.questionKey, i)
															"
														/>
														<label
															for="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															id="{{ q?.questionKey }}_clientInvolvedName_{{
																i
															}}"
															>{{ getPeopleName(client?.value?.id) }}</label
														>
													</div>
													<div
														class="col-8"
														*ngIf="client.get('isVulnerable')?.value === true"
													>
														<textarea
															id="{{ q?.questionKey }}_clientInvolvedNotes_{{
																i
															}}"
															class="form-control"
															formControlName="notes"
															rows="2"
															placeholder="Notes"
															[readonly]="!!isViewSummary"
														></textarea>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Date'"> </ng-container>
								<ng-container *ngSwitchDefault>
									<div class="form-group row align-items-center">
										<div class="col-md-7">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													[id]="generateControl(q.questionKey) + i"
													[name]="generateControl(q.questionKey) + i"
													[value]="'Yes'"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
												/>
												<label
													class="form-check-label"
													[for]="generateControl(q.questionKey)"
													>Yes</label
												>
											</div>
											<div class="form-check form-check-inline mr-0">
												<input
													class="form-check-input"
													type="radio"
													[id]="generateControl(q.questionKey) + i"
													[name]="generateControl(q.questionKey) + i"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													[value]="'No'"
												/>
												<label
													class="form-check-label"
													[for]="generateControl(q.questionKey)"
													>No</label
												>
											</div>
										</div>
									</div>
									<ng-container
										*ngIf="
											(q.questionKey === 'QIM2' &&
											form.get('QIM2')?.value === 'Yes') ||
											(q.questionKey === 'QCM8' &&
											form.get('QCM8')?.value === 'Yes')
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-7"></div>
											<div *ngIf="q.dataType !== 'Label'" class="col-5">
												<button
													id="addActivityButton"
													class="tap-btn tap-btn--default"
													type="button"
													(click)="addActivity()"
												>
													Create Activity
												</button>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-template>
				</ng-container>

				<!-- KiwiSaver -->
				<ng-container *ngSwitchCase="'K'">
					<ng-container *ngIf="isComplete; else isKInComplete">
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="q.questionKey === 'QCK10 Details' && form.get('QCK10')?.value === 'No'"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Date -->
								<ng-container *ngSwitchCase="'Date'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QCK10 Date' && form.get('QCK10')?.value === 'Yes')
										"
									>
										<div class="form-group row align-items-baseline">
											<div class="col-5">
												<!-- <span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }} -->
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<!-- <div class="clearfix mb-2">
													<app-date-input
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[id]="form.controls[generateControl(q.questionKey)]"
														[textboxId]="generateControl(q.questionKey) + i"
														textboxClass="theme-crm-field-disabled"
														[placeholder]="
															generateControl(q.questionKey) === 'QILR2Date'
																? 'Enter Review Date'
																: 'dd/mm/yyyy'
														"
													></app-date-input>
												</div> -->
												<div class="clearfix">
													<button
														id="addActivityButton"
														class="tap-btn tap-btn--default float-right"
														type="button"
														(click)="addActivity()"
													>
														Create Activity
													</button>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCK4'">
									<ng-container *ngIf="form.get('QCK3')?.value === 'Yes'">
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Custom -->
								<ng-container *ngSwitchCase="'QCK5'">
									<ng-container *ngIf="form.get('QCK3')?.value === 'Yes'">
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCK7'">
									<ng-container *ngIf="form.get('QCK6')?.value === 'Yes'">
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Custom -->
								<ng-container *ngSwitchCase="'QCK8'">
									<ng-container *ngIf="form.get('QCK6')?.value === 'Yes'">
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Custom: Vulnerable -->
								<ng-container *ngSwitchCase="'QCK9'">
									<div class="form-group align-items-center">
										<div class="row">
											<div class="col-12">{{ q?.question }}</div>
										</div>
										<div [formArrayName]="q?.questionKey">
											<div
												class="row mb-1 mt-1"
												*ngFor="
													let client of this.getFormArrayControl(
														q?.questionKey
													)?.controls;
													let i = index
												"
												[formGroupName]="i"
											>
												<div class="col-4 d-flex align-items-center">
													<input
														id="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														class="chckbox mr-1"
														type="checkbox"
														formControlName="isVulnerable"
														(change)="
															toggleVulnerableNotes(q?.questionKey, i)
														"
													/>
													<label
														for="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														id="{{ q?.questionKey }}_clientInvolvedName_{{
															i
														}}"
														>{{ getPeopleName(client?.value?.id) }}</label
													>
												</div>
												<div
													class="col-8"
													*ngIf="client.get('isVulnerable')?.value === true"
												>
													<textarea
														id="{{ q?.questionKey }}_clientInvolvedNotes_{{
															i
														}}"
														class="form-control"
														formControlName="notes"
														rows="2"
														placeholder="Notes"
														[readonly]="!!isViewSummary"
													></textarea>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<ng-container
										*ngIf="['QCK3', 'QCK6', 'QCK10'].includes(q.questionKey)"
									>
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'Yes'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Yes</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'No'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>No</label
													>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-container>

					<ng-template #isKInComplete>
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<!-- Details -->
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QIK1 Details' && !hasAdviceSummaryPresented) ||
											(q.questionKey === 'QIK2 Details' && form.get('QIK2')?.value === 'No') ||
											(q.questionKey === 'QIK16 Details' &&
												hasAdviceSummaryPresented &&
												!isUploadedAllDocuments)
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Date -->
								<ng-container *ngSwitchCase="'Date'">
									<ng-container
										*ngIf="(q.questionKey === 'QIK2 Date' &&	form.get('QIK2')?.value === 'Yes')"
									>
										<div class="form-group row align-items-baseline">
											<div class="col-5">
												<!-- <span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }} -->
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<!-- <div class="clearfix mb-2">
													<app-date-input
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[id]="form.controls[generateControl(q.questionKey)]"
														[textboxId]="generateControl(q.questionKey) + i"
														textboxClass="theme-crm-field-disabled"
														[placeholder]="
															generateControl(q.questionKey) === 'QILR2Date'
																? 'Enter Review Date'
																: 'dd/mm/yyyy'
														"
													></app-date-input>
												</div> -->
												<div class="clearfix">
													<button
														id="addActivityButton"
														class="tap-btn tap-btn--default float-right"
														type="button"
														(click)="addActivity()"
													>
														Create Activity
													</button>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Custom: Dropdown with others -->
								<ng-container *ngSwitchCase="'QIK1'">
									<ng-container *ngIf="!hasAdviceSummaryPresented">
										<div class="form-group row align-items-center">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<span
													class="custom-dropdown w-100"
													*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
												>
													<select
														#otherSelect
														[id]="'otherSelect_' + q.questionKey"
														class="form-control theme-crm-field-disabled"
														(change)="
															onChangeDropdownOther($event, q.questionKey)
														"
														[value]="dropdownOtherValue(q.questionKey)"
													>
														<option value=""></option>
														<option
															*ngFor="let d of apcrtqi1Choices$ | async"
															[value]="d?.value"
															[selected]="
																d?.value === dropdownOtherValue(q.questionKey)
															"
														>
															{{ d?.value }}
														</option>
													</select>
												</span>
												<span
													*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
												>
													<input
														#otherInput
														[id]="'otherInput_' + q.questionKey"
														(keyup)="onChangeInputOther($event, q.questionKey)"
														(focusout)="
															onChangeInputOther($event, q.questionKey)
														"
														class="form-control"
														placeholder="Other"
														type="text"
														[value]="inputOtherValue(q.questionKey)"
													/>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>

								<!-- Custom: Dropdown with others -->
								<ng-container *ngSwitchCase="'QIK15'">
									<ng-container *ngIf="hasAdviceSummaryPresented">
										<div class="form-group row align-items-center">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<span
													class="custom-dropdown w-100"
													*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
												>
													<select
														#otherSelect
														[id]="'otherSelect_' + q.questionKey"
														class="form-control theme-crm-field-disabled"
														(change)="
															onChangeDropdownOther($event, q.questionKey)
														"
														[value]="dropdownOtherValue(q.questionKey)"
													>
														<option value=""></option>
														<option
															*ngFor="let d of apcrtqik15Choices$ | async"
															[value]="d?.value"
															[selected]="
																d?.value === dropdownOtherValue(q.questionKey)
															"
														>
															{{ d?.value }}
														</option>
													</select>
												</span>
												<span
													*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
												>
													<input
														#otherInput
														[id]="'otherInput_' + q.questionKey"
														(keyup)="onChangeInputOther($event, q.questionKey)"
														(focusout)="
															onChangeInputOther($event, q.questionKey)
														"
														class="form-control"
														placeholder="Other"
														type="text"
														[value]="inputOtherValue(q.questionKey)"
													/>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>

								<!-- Custom: Vulnerable -->
								<ng-container *ngSwitchCase="'QIK7'">
									<div class="form-group align-items-center">
										<div class="row">
											<div class="col-12">{{ q?.question }}</div>
										</div>
										<div [formArrayName]="q?.questionKey">
											<div
												class="row mb-1 mt-1"
												*ngFor="
													let client of this.getFormArrayControl(
														q?.questionKey
													)?.controls;
													let i = index
												"
												[formGroupName]="i"
											>
												<div class="col-4 d-flex align-items-center">
													<input
														id="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														class="chckbox mr-1"
														type="checkbox"
														formControlName="isVulnerable"
														(change)="
															toggleVulnerableNotes(q?.questionKey, i)
														"
													/>
													<label
														for="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														id="{{ q?.questionKey }}_clientInvolvedName_{{
															i
														}}"
														>{{ getPeopleName(client?.value?.id) }}</label
													>
												</div>
												<div
													class="col-8"
													*ngIf="client.get('isVulnerable')?.value === true"
												>
													<textarea
														id="{{ q?.questionKey }}_clientInvolvedNotes_{{
															i
														}}"
														class="form-control"
														formControlName="notes"
														rows="2"
														placeholder="Notes"
														[readonly]="!!isViewSummary"
													></textarea>
												</div>
											</div>
										</div>
									</div>
								</ng-container>

								<!-- Default -->
								<ng-container *ngSwitchDefault>
									<ng-container *ngIf="
										q.questionKey === 'QIK2' ||
										(q.questionKey === 'QIK16' &&
										hasAdviceSummaryPresented &&
										!isUploadedAllDocuments)
									">
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'Yes'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Yes</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'No'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>No</label
													>
												</div>
											</div>
										</div>
									</ng-container>
									<ng-container
										*ngIf="
											q.questionKey === 'QIK16' &&
											hasAdviceSummaryPresented &&
											!isUploadedAllDocuments &&
											missingDocuments?.length > 0
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12">
												<ul class="missing-documents">
													<li *ngFor="let doc of missingDocuments">
														{{ doc?.field }}
													</li>
												</ul>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-template>
				</ng-container>

				<!-- FG -->
				<ng-container *ngSwitchCase="'FG'">
					<ng-container *ngIf="isComplete; else isBInComplete">
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QCB1 Details' &&
												form.get('QCB1')?.value === 'No') ||
											(q.questionKey === 'QCB2 Details' &&
												!isUploadedAllDocuments)
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QCB3'">
									<div class="form-group row align-items-center">
										<div class="col-12 align-self-start mb-2">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>
											{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-12">
											<textarea
												[formControl]="
													form.controls[generateControl(q.questionKey)]
												"
												class="form-control"
												[id]="generateControl(q.questionKey)"
												[name]="generateControl(q.questionKey)"
												rows="3"
											></textarea>
										</div>
									</div>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<ng-container
										*ngIf="
											q.questionKey !== 'QCB2' ||
											(q.questionKey === 'QCB2' && !isUploadedAllDocuments)
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'Yes'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Yes</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'No'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>No</label
													>
												</div>
											</div>
										</div>
									</ng-container>
									<ng-container
										*ngIf="
											q.questionKey === 'QCB2' &&
											!isUploadedAllDocuments &&
											missingDocuments?.length > 0
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12">
												<ul class="missing-documents">
													<li *ngFor="let doc of missingDocuments">
														{{ doc?.field }}
													</li>
												</ul>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-container>
					<ng-template #isBInComplete>
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											q.questionKey === 'QIB1 Details' ||
											(q.questionKey === 'QIB2 Details' &&
												form.get('QIB2')?.value === 'No')
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QIB1'">
									<div class="form-group row align-items-center">
										<div class="col-5">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-7">
											<span class="custom-dropdown w-100">
												<select
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													[id]="form.controls[generateControl(q.questionKey)]"
													class="form-control theme-crm-field-disabled"
												>
													<option value=""></option>
													<option
														*ngFor="let d of apcrtqi1Choices$ | async"
														[value]="d?.value"
													>
														{{ d?.value }}
													</option>
												</select>
											</span>
										</div>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'Date'"> </ng-container>
								<ng-container *ngSwitchDefault>
									<div class="form-group row align-items-center">
										<div class="col-md-7">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													[id]="generateControl(q.questionKey) + i"
													[name]="generateControl(q.questionKey) + i"
													[value]="'Yes'"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
												/>
												<label
													class="form-check-label"
													[for]="generateControl(q.questionKey)"
													>Yes</label
												>
											</div>
											<div class="form-check form-check-inline mr-0">
												<input
													class="form-check-input"
													type="radio"
													[id]="generateControl(q.questionKey) + i"
													[name]="generateControl(q.questionKey) + i"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													[value]="'No'"
												/>
												<label
													class="form-check-label"
													[for]="generateControl(q.questionKey)"
													>No</label
												>
											</div>
										</div>
									</div>
									<ng-container
										*ngIf="
											q.questionKey === 'QIB2' &&
											form.get('QIB2')?.value === 'Yes'
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-7"></div>
											<div *ngIf="q.dataType !== 'Label'" class="col-5">
												<button
													id="addActivityButton"
													class="tap-btn tap-btn--default"
													type="button"
													(click)="addActivity()"
												>
													Create Activity
												</button>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-template>
				</ng-container>

				<!-- Complaint -->
				<ng-container *ngSwitchCase="'C'">
					<ng-container *ngIf="isComplete">
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											q.questionKey === 'QCC1 Details' &&
											form.get('QCC1')?.value === 'No'
										"
									>
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<div class="form-group row align-items-center">
										<div class="col-md-7">{{ q.question }}</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													[id]="generateControl(q.questionKey) + i"
													[name]="generateControl(q.questionKey) + i"
													[value]="'Yes'"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
												/>
												<label
													class="form-check-label"
													[for]="generateControl(q.questionKey)"
													>Yes</label
												>
											</div>
											<div class="form-check form-check-inline mr-0">
												<input
													class="form-check-input"
													type="radio"
													[id]="generateControl(q.questionKey) + i"
													[name]="generateControl(q.questionKey) + i"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													[value]="'No'"
												/>
												<label
													class="form-check-label"
													[for]="generateControl(q.questionKey)"
													>No</label
												>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-container>
				</ng-container>

				<!-- Investment -->
				<ng-container *ngSwitchCase="'I'">
					<ng-container *ngIf="isComplete; else isInvestmentInComplete">
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
						<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
							<!-- Details -->
							<ng-container *ngSwitchCase="'Details'">
								<ng-container
									*ngIf="
										(q.questionKey === 'QCI1 Details' && !isUploadedAllDocuments && missingDocuments?.length > 0) ||
										(q.questionKey === 'QCI5 Details' && form.get('QCI5')?.value === 'No')
									">
									<div class="form-group row align-items-center">
										<div class="col-12 align-self-start mb-2">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>
											{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-12">
											<textarea
												[formControl]="
													form.controls[generateControl(q.questionKey)]
												"
												class="form-control"
												[id]="generateControl(q.questionKey)"
												[name]="generateControl(q.questionKey)"
												rows="3"
											></textarea>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<!-- Date -->
							<ng-container *ngSwitchCase="'Date'">
								<ng-container
									*ngIf="
										(q.questionKey === 'QCI5 Date' && form.get('QCI5')?.value === 'Yes')
									">
									<div class="form-group row align-items-baseline">
										<div class="col-5">{{ q.question }}</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-7">
											<div class="clearfix">
												<app-date-input
													[textboxId]="generateControl(q.questionKey) + i"
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													textboxClass="theme-crm-field-disabled"
													placeholder="dd/mm/yyyy"
												></app-date-input>
												<div class="clearfix mt-2">
													<button
														id="addActivityButton"
														class="tap-btn tap-btn--default"
														type="button"
														(click)="addActivity(q.questionKey)"
													>
														Create Activity
													</button>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<!-- Custom -->
							<ng-container *ngSwitchCase="'QCI2'">
								<div class="form-group align-items-center">
									<div class="row">
										<div class="col-12">{{ q?.question }}</div>
									</div>
									<div [formArrayName]="q?.questionKey">
										<div
											class="row mb-1 mt-1"
											*ngFor="
												let client of this.getFormArrayControl(
													q?.questionKey
												)?.controls;
												let i = index
											"
											[formGroupName]="i"
										>
											<div class="col-4 d-flex align-items-center">
												<input
													id="{{
														q?.questionKey
													}}_clientInvolvedIsVulnerable_{{ i }}"
													class="chckbox mr-1"
													type="checkbox"
													formControlName="isVulnerable"
													(change)="
														toggleVulnerableNotes(q?.questionKey, i)
													"
												/>
												<label
													for="{{
														q?.questionKey
													}}_clientInvolvedIsVulnerable_{{ i }}"
													id="{{ q?.questionKey }}_clientInvolvedName_{{
														i
													}}"
													>{{ getPeopleName(client?.value?.id) }}</label
												>
											</div>
											<div
												class="col-8"
												*ngIf="client.get('isVulnerable')?.value === true"
											>
												<textarea
													id="{{ q?.questionKey }}_clientInvolvedNotes_{{
														i
													}}"
													class="form-control"
													formControlName="notes"
													rows="2"
													placeholder="Notes"
													[readonly]="!!isViewSummary"
												></textarea>
											</div>
										</div>
									</div>
								</div>
							</ng-container>

							<!-- Other question types -->
							<ng-container *ngSwitchDefault>
								<!-- Other questions with the same types -->
								<ng-container *ngIf="['QCI3', 'QCI4'].includes(q.questionKey) else defaultIC">
									<!-- Dropdown select only-->
									<ng-container>
										<div class="form-group row align-items-center">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<span class="custom-dropdown w-100">
													<select
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[id]="form.controls[generateControl(q.questionKey)]"
														class="form-control theme-crm-field-disabled"
													>
														<option value=""></option>
														<ng-container *ngFor="let d of apcrtfccChoices$ | async">
															<option
																*ngIf="q.questionKey === 'QCI3'"
																[value]="d?.value"
															>
																{{ d?.value }}
															</option>
														</ng-container>
														<ng-container *ngFor="let d of apcrtynnaChoices$ | async">
															<option
																*ngIf="q.questionKey === 'QCI4'"
																[value]="d?.value"
															>
																{{ d?.value }}
															</option>
														</ng-container>
													</select>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Default -->
								<ng-template #defaultIC>
									<ng-container *ngIf="
										q.questionKey !== 'QCI1' ||
										(q.questionKey === 'QCI1' && !isUploadedAllDocuments)
									">
										<div class="form-group row align-items-center">
											<div class="col-md-7">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[value]="'Yes'"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>Yes</label
													>
												</div>
												<div class="form-check form-check-inline mr-0">
													<input
														class="form-check-input"
														type="radio"
														[id]="generateControl(q.questionKey) + i"
														[name]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[value]="'No'"
													/>
													<label
														class="form-check-label"
														[for]="generateControl(q.questionKey)"
														>No</label
													>
												</div>
											</div>
											<ng-container
												*ngIf="
													q.questionKey === 'QCI1' &&
													!isUploadedAllDocuments &&
													missingDocuments?.length > 0
												"
											>
												<div class="col-12">
													<ul class="missing-documents">
														<li *ngFor="let doc of missingDocuments">
															{{ doc?.field }}
														</li>
													</ul>
												</div>
											</ng-container>
										</div>
									</ng-container>
								</ng-template>
							</ng-container>
						</ng-container>
						</ng-template>
					</ng-container>

					<!-- End Process for Investment Advice Process (Incomplete) -->
					<ng-template #isInvestmentInComplete>
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<!-- Details -->
								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QII2 Details' && !!form.get('QII2')?.value) ||
											(q.questionKey === 'QII3 Details' && form.get('QII3')?.value === 'No') ||
											(q.questionKey === 'QII7 Details' && form.get('QII7')?.value === 'No')
										">
										<div class="form-group row align-items-center">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="3"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Date -->
								<ng-container *ngSwitchCase="'Date'">
									<ng-container *ngIf="q.questionKey === 'QII7 Date' && form.get('QII7')?.value === 'Yes'">
										<div class="form-group row align-items-baseline">
											<div class="col-5">{{ q.question }}</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<div class="clearfix">
													<app-date-input
														[textboxId]="generateControl(q.questionKey) + i"
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														textboxClass="theme-crm-field-disabled"
														placeholder="dd/mm/yyyy"
													></app-date-input>
													<div class="clearfix mt-2">
														<button
															id="addActivityButton"
															class="tap-btn tap-btn--default"
															type="button"
															(click)="addActivity(q.questionKey)"
														>
															Create Activity
														</button>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Custom -->
								<ng-container *ngSwitchCase="'QII1'">
									<ng-container *ngIf="(q.questionKey === 'QII1' && form.get('QII2')?.value === 'No')">
										<div class="form-group row align-items-center">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<span
													class="custom-dropdown w-100"
													*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
												>
													<select
														#otherSelect
														[id]="'otherSelect_' + q.questionKey"
														class="form-control theme-crm-field-disabled"
														(change)="
															onChangeDropdownOther($event, q.questionKey)
														"
														[value]="dropdownOtherValue(q.questionKey)"
													>
														<option value=""></option>
														<option
															*ngFor="let d of apcrtqi1Choices$ | async"
															[value]="d?.value"
															[selected]="
																d?.value === dropdownOtherValue(q.questionKey)
															"
														>
															{{ d?.value }}
														</option>
													</select>
												</span>
												<span
													*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
												>
													<input
														#otherInput
														[id]="'otherInput_' + q.questionKey"
														(keyup)="onChangeInputOther($event, q.questionKey)"
														(focusout)="
															onChangeInputOther($event, q.questionKey)
														"
														class="form-control"
														placeholder="Other"
														type="text"
														[value]="inputOtherValue(q.questionKey)"
													/>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'QII4'">
									<div class="form-group align-items-center">
										<div class="row">
											<div class="col-12">{{ q?.question }}</div>
										</div>
										<div [formArrayName]="q?.questionKey">
											<div
												class="row mb-1 mt-1"
												*ngFor="
													let client of this.getFormArrayControl(
														q?.questionKey
													)?.controls;
													let i = index
												"
												[formGroupName]="i"
											>
												<div class="col-4 d-flex align-items-center">
													<input
														id="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														class="chckbox mr-1"
														type="checkbox"
														formControlName="isVulnerable"
														(change)="
															toggleVulnerableNotes(q?.questionKey, i)
														"
													/>
													<label
														for="{{
															q?.questionKey
														}}_clientInvolvedIsVulnerable_{{ i }}"
														id="{{ q?.questionKey }}_clientInvolvedName_{{
															i
														}}"
														>{{ getPeopleName(client?.value?.id) }}</label
													>
												</div>
												<div
													class="col-8"
													*ngIf="client.get('isVulnerable')?.value === true"
												>
													<textarea
														id="{{ q?.questionKey }}_clientInvolvedNotes_{{
															i
														}}"
														class="form-control"
														formControlName="notes"
														rows="2"
														placeholder="Notes"
														[readonly]="!!isViewSummary"
													></textarea>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'QII6'">
									<ng-container *ngIf="(q.questionKey === 'QII6' && form.get('QII2')?.value === 'Yes')">
										<div class="form-group row align-items-center">
											<div class="col-5">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-7">
												<span
													class="custom-dropdown w-100"
													*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
												>
													<select
														#otherSelect
														[id]="'otherSelect_' + q.questionKey"
														class="form-control theme-crm-field-disabled"
														(change)="
															onChangeDropdownOther($event, q.questionKey)
														"
														[value]="dropdownOtherValue(q.questionKey)"
													>
														<option value=""></option>
														<option
															*ngFor="let d of apcrtqi6Choices$ | async"
															[value]="d?.value"
															[selected]="
																d?.value === dropdownOtherValue(q.questionKey)
															"
														>
															{{ d?.value }}
														</option>
													</select>
												</span>
												<span
													*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
												>
													<input
														#otherInput
														[id]="'otherInput_' + q.questionKey"
														(keyup)="onChangeInputOther($event, q.questionKey)"
														(focusout)="
															onChangeInputOther($event, q.questionKey)
														"
														class="form-control"
														placeholder="Other"
														type="text"
														[value]="inputOtherValue(q.questionKey)"
													/>
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<!-- Other question types -->
								<ng-container *ngSwitchDefault>
									<!-- Other questions with the same types -->
									<ng-container *ngIf="['QII5', 'QII8'].includes(q.questionKey) else defaultII">
										<!-- Dropdown select only-->
										<ng-container>
											<div class="form-group row align-items-center">
												<div class="col-5">
													<span
														class="tap-required-field ml-2"
														*ngIf="
															getFormControl(generateControl(q.questionKey))
																?.invalid && q.dataType !== 'Label'
														"
														>*</span
													>{{ q.question }}
												</div>
												<div *ngIf="q.dataType !== 'Label'" class="col-7">
													<span class="custom-dropdown w-100">
														<select
															[formControl]="
																form.controls[generateControl(q.questionKey)]
															"
															[id]="form.controls[generateControl(q.questionKey)]"
															class="form-control theme-crm-field-disabled"
														>
															<option value=""></option>
															<ng-container *ngFor="let d of apcrtfccChoices$ | async">
																<option
																	*ngIf="q.questionKey === 'QII5'"
																	[value]="d?.value"
																>
																	{{ d?.value }}
																</option>
															</ng-container>
															<ng-container *ngFor="let d of apcrtynnaChoices$ | async">
																<option
																	*ngIf="q.questionKey === 'QII8'"
																	[value]="d?.value"
																>
																	{{ d?.value }}
																</option>
															</ng-container>
														</select>
													</span>
												</div>
											</div>
										</ng-container>
									</ng-container>
									<!-- Default -->
									<ng-template #defaultII>
										<ng-container *ngIf="q.questionKey?.includes('QII')">
											<div class="form-group row align-items-center">
												<div class="col-md-7">
													<span
														class="tap-required-field ml-2"
														*ngIf="
															getFormControl(generateControl(q.questionKey))
																?.invalid && q.dataType !== 'Label'
														"
														>*</span
													>{{ q.question }}
												</div>
												<div *ngIf="q.dataType !== 'Label'" class="col-md-5">
													<div class="form-check form-check-inline">
														<input
															class="form-check-input"
															type="radio"
															[id]="generateControl(q.questionKey) + i"
															[name]="generateControl(q.questionKey) + i"
															[value]="'Yes'"
															[formControl]="
																form.controls[generateControl(q.questionKey)]
															"
														/>
														<label
															class="form-check-label"
															[for]="generateControl(q.questionKey)"
															>Yes</label
														>
													</div>
													<div class="form-check form-check-inline mr-0">
														<input
															class="form-check-input"
															type="radio"
															[id]="generateControl(q.questionKey) + i"
															[name]="generateControl(q.questionKey) + i"
															[formControl]="
																form.controls[generateControl(q.questionKey)]
															"
															[value]="'No'"
														/>
														<label
															class="form-check-label"
															[for]="generateControl(q.questionKey)"
															>No</label
														>
													</div>
												</div>
											</div>
										</ng-container>
									</ng-template>
								</ng-container>
							</ng-container>
						</ng-template>
					</ng-template>
				</ng-container>

				<!-- CAR -->
				<ng-container *ngSwitchCase="'CAR'">
					<ng-container *ngIf="isComplete; else isCARInComplete">
						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>

							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'QCCAR1'">
									<ng-container *ngIf="q.dataType === 'Label' && isMissingDocuments">
										<div class="row align-items-center">
											<div class="col-md-12">
												<div class="car-missing-documents">
													{{ q.question }}
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>

								<ng-container *ngSwitchCase="'QCCAR2'">
									<div *ngIf="isMissingDocuments" class="form-group row align-items-center">
										<div class="col-md-12">
											<div class="row">
												<div class="col-12 mb-30">
													<span
														class="tap-required-field ml-2"
														*ngIf="
															getFormControl(generateControl(q.questionKey))
																?.invalid && q.dataType !== 'Label'
														"
														>*</span>
													{{ q.question }}
												</div>
											</div>
											<div class="row">
												<div class="col-6">Name of Document</div>
												<div class="col-6">Notes</div>
											</div>
											<!-- Missing Documents -->
											<div [formArrayName]="q?.questionKey">
												<div
													class="row mb-1 mt-1"
													*ngFor="
														let document of this.getFormArrayControl(
															q?.questionKey
														)?.controls;
														let i = index
													"
													[formGroupName]="i"
												>
													<div class="col-12">
														<hr class="car-horizontal-docs-separator" />
													</div>
													<div class="col-6 d-flex align-items-center">
														<label
															id="{{ q?.questionKey }}_missingDocumentsName_{{
																i
															}}"
															class="car-document-name">
															{{ document?.value?.documentName }}
														</label>
													</div>
													<div class="col-6">
														<span
															class="tap-required-field ml-2"
															*ngIf="document?.value?.notes === null || document?.value?.notes.trim() === ''"
															>*</span>
														<textarea
															id="{{ q?.questionKey }}_missingDocumentsNotes_{{
																i
															}}"
															class="form-control"
															formControlName="notes"
															rows="2"
															placeholder="Notes"
															[readonly]="!!isViewSummary"
														></textarea>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngSwitchCase="'QCCAR3'">
									<hr *ngIf="isMissingDocuments" class="car-horizontal-separator-bottom" />
									<div class="form-group row align-items-center">
										<div class="col-md-12 mb-15">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>
											{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-md-6">
											<app-button-group
												btnStyle="min-width: 138px!important; height: 40px; background-color: var(--tap-white)"
												[items$]="yesOrNo$"
												[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
											>
											</app-button-group>
										</div>
									</div>
								</ng-container>

								<ng-container *ngSwitchCase="'QCCAR4'">
									<hr class="car-horizontal-separator" />
									<div class="form-group row align-items-center">
										<div class="col-md-12 mb-15">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>
											{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-md-6">
											<app-button-group
												btnStyle="min-width: 138px!important; height: 40px; background-color: var(--tap-white)"
												[items$]="yesOrNo$"
												[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
											>
											</app-button-group>
										</div>
									</div>
								</ng-container>

								<ng-container *ngSwitchCase="'QCCAR5'">
									<ng-container>
										<hr class="car-horizontal-separator" />
										<div class="form-group align-items-center">
											<div class="row">
												<div class="col-6 mb-15">{{ q?.question }}</div>
												<div *ngIf="checkCarCompleteHasCIVulnerable" class="col-6 mb-15">Notes</div>
											</div>
											<div [formArrayName]="q?.questionKey">
												<div
													class="row mb-1 mt-1"
													*ngFor="
														let client of this.getFormArrayControl(
															q?.questionKey
														)?.controls;
														let i = index
													"
													[formGroupName]="i"
												>
													<div class="col-6 d-flex align-items-center mb-15">
														<input
															id="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															class="chckbox mr-1"
															type="checkbox"
															formControlName="isVulnerable"
															(change)="
																toggleVulnerableNotes(q?.questionKey, i)
															"
														/>
														<label
															for="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															id="{{ q?.questionKey }}_clientInvolvedName_{{
																i
															}}"
															>{{ getPeopleName(client?.value?.id) }}</label
														>
													</div>
													<div
														class="col-6 mb-15"
														*ngIf="client.get('isVulnerable')?.value === true"
													>
														<textarea
															id="{{ q?.questionKey }}_clientInvolvedNotes_{{
																i
															}}"
															class="form-control"
															formControlName="notes"
															rows="2"
															placeholder="Notes"
															[readonly]="!!isViewSummary"
														></textarea>
													</div>
												</div>
											</div>
										</div>
										<hr class="car-horizontal-separator" />
									</ng-container>
								</ng-container>

								<ng-container *ngSwitchCase="'QCCAR6'">
									<div class="form-group row align-items-center">
										<div class="col-md-12 mb-15">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>
											{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-md-6">
											<app-button-group
												btnStyle="min-width: 138px!important; height: 40px; background-color: var(--tap-white)"
												[items$]="yesOrNo$"
												[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
											>
											</app-button-group>
										</div>
									</div>
								</ng-container>

								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
												(q.questionKey === 'QCCAR3 Details' && form.get('QCCAR3')?.value === 'No') ||
												(q.questionKey === 'QCCAR4 Details' && form.get('QCCAR4')?.value === 'Yes') ||
												(q.questionKey === 'QCCAR6 Details' && form.get('QCCAR6')?.value === 'No')
											">
										<div class="form-group row align-items-center mt-30">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="5"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>

								<ng-container *ngSwitchCase="'Date'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QCCAR6 Date' && form.get('QCCAR6')?.value === 'Yes')
										">
										<div class="form-group row align-items-baseline">
											<div class="col-12 mb-15">
												<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-6">
												<div class="clearfix mb-2">
													<app-date-input
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[id]="form.controls[generateControl(q.questionKey)]"
														[textboxId]="generateControl(q.questionKey) + i"
														textboxClass="theme-crm-field-disabled car-date"
														[placeholder]="'DD/MM/YYYY'"
													></app-date-input>
												</div>
												<!-- <div class="clearfix">
													<button
														id="addActivityButton"
														class="tap-btn tap-btn--default"
														type="button"
														(click)="addActivity()"
													>
														Create Activity
													</button>
												</div> -->
											</div>
										</div>
									</ng-container>
								</ng-container>


							</ng-container>
						</ng-template>
					</ng-container>
					<ng-template #isCARInComplete>

						<ng-template
							ngFor
							let-q
							[ngForOf]="questions"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container [ngSwitch]="checkQuestionType(q.questionKey)">
								<ng-container *ngSwitchCase="'QICAR1'">
										<div class="form-group row align-items-center">
											<div class="col-12 mb-15">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<span
													class="custom-dropdown w-100"
													*ngIf="dropdownOtherValue(q.questionKey) !== 'Other'"
												>
													<select
														#otherSelect
														[id]="'otherSelect_' + q.questionKey"
														class="form-control theme-crm-field-disabled car-select"
														(change)="
															onChangeDropdownOther($event, q.questionKey)
														"
														[value]="dropdownOtherValue(q.questionKey)"
														placeholder="Select"
													>
														<option value=""></option>
														<option
															*ngFor="let d of carqicar1$ | async"
															[value]="d?.value"
															[selected]="
																d?.value === dropdownOtherValue(q.questionKey)
															"
														>
															{{ d?.value }}
														</option>
													</select>
												</span>
												<span
													*ngIf="dropdownOtherValue(q.questionKey) === 'Other'"
												>
													<input
														#otherInput
														[id]="'otherInput_' + q.questionKey"
														(keyup)="onChangeInputOther($event, q.questionKey)"
														(focusout)="
															onChangeInputOther($event, q.questionKey)
														"
														class="form-control car-text"
														placeholder="Other"
														type="text"
														[value]="inputOtherValue(q.questionKey)"
													/>
												</span>
											</div>
										</div>
								</ng-container>

								<ng-container *ngSwitchCase="'Details'">
									<ng-container
										*ngIf="
												(q.questionKey === 'QICAR1 Details') ||
												(q.questionKey === 'QICAR3 Details' && form.get('QICAR3')?.value === 'No')
											">
										<div class="form-group row align-items-center mt-30">
											<div class="col-12 align-self-start mb-2">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*</span
												>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-12">
												<textarea
													[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
													class="form-control"
													[id]="generateControl(q.questionKey)"
													[name]="generateControl(q.questionKey)"
													rows="5"
												></textarea>
											</div>
										</div>
									</ng-container>
								</ng-container>

								<ng-container *ngSwitchCase="'QICAR2'">
									<ng-container>
										<hr class="car-horizontal-separator" />
										<div class="form-group align-items-center">
											<div class="row">
												<div class="col-6 mb-15">{{ q?.question }}</div>
												<div *ngIf="checkCarHasCIVulnerable" class="col-6 mb-15">Notes</div>
											</div>
											<div [formArrayName]="q?.questionKey">
												<div
													class="row mb-1 mt-1"
													*ngFor="
														let client of this.getFormArrayControl(
															q?.questionKey
														)?.controls;
														let i = index
													"
													[formGroupName]="i"
												>
													<div class="col-6 d-flex align-items-center mb-15">
														<input
															id="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															class="chckbox mr-1"
															type="checkbox"
															formControlName="isVulnerable"
															(change)="
																toggleVulnerableNotes(q?.questionKey, i)
															"
														/>
														<label
															for="{{
																q?.questionKey
															}}_clientInvolvedIsVulnerable_{{ i }}"
															id="{{ q?.questionKey }}_clientInvolvedName_{{
																i
															}}"
															>{{ getPeopleName(client?.value?.id) }}</label
														>
													</div>
													<div
														class="col-6 mb-15"
														*ngIf="client.get('isVulnerable')?.value === true"
													>
														<textarea
															id="{{ q?.questionKey }}_clientInvolvedNotes_{{
																i
															}}"
															class="form-control"
															formControlName="notes"
															rows="2"
															placeholder="Notes"
															[readonly]="!!isViewSummary"
														></textarea>
													</div>
												</div>
											</div>
										</div>
										<hr class="car-horizontal-separator" />
									</ng-container>
								</ng-container>

								<ng-container *ngSwitchCase="'QICAR3'">
									<div class="form-group row align-items-center">
										<div class="col-md-12 mb-15">
											<span
												class="tap-required-field ml-2"
												*ngIf="
													getFormControl(generateControl(q.questionKey))
														?.invalid && q.dataType !== 'Label'
												"
												>*</span
											>
											{{ q.question }}
										</div>
										<div *ngIf="q.dataType !== 'Label'" class="col-md-6">
											<app-button-group
												btnStyle="min-width: 138px!important; height: 40px; background-color: var(--tap-white)"
												[items$]="yesOrNo$"
												[formControl]="
														form.controls[generateControl(q.questionKey)]
													"
											>
											</app-button-group>
										</div>
									</div>
								</ng-container>

								<ng-container *ngSwitchCase="'Date'">
									<ng-container
										*ngIf="
											(q.questionKey === 'QICAR3 Date' && form.get('QICAR3')?.value === 'Yes')
										">
										<div class="form-group row align-items-baseline">
											<div class="col-12 mb-15">
												<span
													class="tap-required-field ml-2"
													*ngIf="
														getFormControl(generateControl(q.questionKey))
															?.invalid && q.dataType !== 'Label'
													"
													>*
												</span>
												{{ q.question }}
											</div>
											<div *ngIf="q.dataType !== 'Label'" class="col-6">
												<div class="clearfix mb-2">
													<app-date-input
														[formControl]="
															form.controls[generateControl(q.questionKey)]
														"
														[id]="form.controls[generateControl(q.questionKey)]"
														[textboxId]="generateControl(q.questionKey) + i"
														textboxClass="theme-crm-field-disabled car-date"
														[placeholder]="'DD/MM/YYYY'"
													></app-date-input>
												</div>
												<!-- <div class="clearfix">
													<button
														id="addActivityButton"
														class="tap-btn tap-btn--default"
														type="button"
														(click)="addActivity()"
													>
														Create Activity
													</button>
												</div> -->
											</div>
										</div>
									</ng-container>
								</ng-container>


							</ng-container>
						</ng-template>

					</ng-template>

				</ng-container>
			</ng-container>

			<hr *ngIf="adviceProcess.processCode === processCodes.ClientAlterationRequest" class="car-horizontal-separator" />

			<div class="justify-content-end modal-footer">
				<ng-container *ngIf="!isSaving && !isEmailLoading; else loader">
					<ng-container *ngIf="endProcessSettings$ | async as emailSettings">
						<button	utton
							*ngIf="
								adviceProcess?.isOnline &&
								!isComplete &&
								emailSettings.isEnableEmailOption &&
								(adviceProcess.processCode === processCodes.LRAdviceNew ||
									adviceProcess.processCode === processCodes.ClientAlterationRequest ||
									adviceProcess.processCode === processCodes.LRAdviceReview ||
									adviceProcess.processCode === processCodes.KiwiSaverAdvice ||
									adviceProcess.processCode === processCodes.MortgageAdvice)
							"
							id="emailButton"
							type="button"
							class="tap-btn tap-btn--default mr-1"
							[class.car-email-button]="adviceProcess.processCode === processCodes.ClientAlterationRequest"
							(click)="onClickEmail()"
							[attr.disabled]="isEmailLoading ? isEmailLoading : null"
						>
							Email Client
						</button>
					</ng-container>
					<button
						id="saveButton"
						type="button"
						class="tap-btn tap-btn--default m-0"
						[class.car-save-button]="adviceProcess.processCode === processCodes.ClientAlterationRequest"
						(click)="save()"
						[disabled]="!isValid"
					>
						Save
					</button>
				</ng-container>
				<ng-template #loader>
					<app-manual-loader-mini
						[isLoading]="isSaving || isEmailLoading"
					></app-manual-loader-mini>
				</ng-template>
			</div>
		</form>
	</div>
</div>
