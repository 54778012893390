<div class="tap-report-page py-2 clearfix">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-1 py-2">
        <h1 class="d-inline tap-text-secondary">Reports</h1>
      </div>
    </div>
  </div>
  <!-- Report Form Component -->
  <div class="tap-report-page__table clearfix px-2 py-2">
    <app-report-form [reportTypes]="reportTypes$ | async"></app-report-form>
  </div>

  <!-- TODO: Run report table - Low prio for now -->
  <!-- <ng-container <ng-container [ngSwitch]="(reportType$ | async)">
    <ng-container *ngSwitchCase="'AA'">
      <div class="tap-report-page__table clearfix px-3 py-2">
        <app-adviser-activity-table></app-adviser-activity-table>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'AS'">
      <div class="tap-report-page__table clearfix px-3 py-2">
        <app-adviser-summary-table></app-adviser-summary-table>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'CCK'">
      <div class="tap-report-page__table clearfix px-3 py-2">
        <app-cc-kpi-table></app-cc-kpi-table>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="clearfix px-3 py-2">
        <div class="blank-slate d-flex align-items-center justify-content-center">
          <span>Report</span>
        </div>
      </div>
    </ng-container>
  </ng-container> -->
</div>