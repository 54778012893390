import { Injectable } from '@angular/core';
import {
	DocumentModelState,
	DocumentTypes,
	DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from '../../../core/base/api.service';
import {
	CARChangeRequestSettingState,
	CARUpdatedStructureSettingState,
	CustomerAlterationRequestEmailSettings,
} from './customer-alteration-request.model';
import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';
import { EmailBodyTypes } from '@shared/models/emails/crt/email.model';
import { applyTransaction } from '@datorama/akita';
import { ReloadDocumentType, SettingsTypes } from '@modules/crt-settings/state/crt-settings.model';
import { CARSettingsStore } from './customer-alteration-request.store';
import { CARSettingsQuery } from './customer-alteration-request.query';

@Injectable({
	providedIn: 'root',
})
export class CustomerAlterrationRequestService {
	carChangeRequestDocuments$ = this.query.carChangeRequest$;
	carUpdatedStructureDocuments$ = this.query.carUpdatedStructure$;

	defaultCARCRTemplate$ = new BehaviorSubject<any | string>(null);
	defaultCARUSTemplate$ = new BehaviorSubject<any | string>(null);

	constructor(
		private api: ApiService,
		private store: CARSettingsStore,
		private query: CARSettingsQuery
	) {}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getEmailSettings(
		type: EmailBodyTypes
	): Observable<CustomerAlterationRequestEmailSettings> {
		const url = `crt/settings/0/${type}`;
		return this.api
			.get(url)
			.pipe(map((setting) => objectUtil.mapPascalCaseToCamelCase(setting)));
	}

	updateEndProcess(
		settings: CustomerAlterationRequestEmailSettings
	): Observable<any> {
		const endpoint = `crt/settings/${settings.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(settings);
		return this.api.put<CustomerAlterationRequestEmailSettings>(endpoint, body);
	}

	getCARChangeRequestSettings(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<CARChangeRequestSettingState>(endpoint).pipe(
			tap((res) =>
				applyTransaction(() => {
					const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
					this.store.setCARChangeRequestSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getCARUpdatedStructureSettings(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<CARUpdatedStructureSettingState>(endpoint).pipe(
			tap((res) =>
				applyTransaction(() => {
					const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
					this.store.setCARUpdateStructureSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getDefaultCARCRTemplateUrl() {
		const endpoint = `crt/oat-template/${ReloadDocumentType.ChangeRequest}`;
		return this.api.get<string>(endpoint);
	}

	getDefaultCARUSTemplateUrl() {
		const endpoint = `crt/oat-template/${ReloadDocumentType.UpdatedStructure}`;
		return this.api.get<string>(endpoint);
	}

	getCARDocumentFile(url: string) {
		return this.api.getExternalResource(
			`${url}?p=${Math.floor(Date.now() / 1000)}`,
			{ responseType: 'text' }
		);
	}

	updateCARCRTemplate(data: CARChangeRequestSettingState) {
		const endpoint = `crt/settings/${data.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<CARChangeRequestSettingState>(endpoint, body).pipe(
			tap((res) =>
				applyTransaction(() => {
					this.store.setCARChangeRequestSettings(res);
				})
			)
		);
	}

	updateCARUSTemplate(data: CARUpdatedStructureSettingState) {
		const endpoint = `crt/settings/${data.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<CARUpdatedStructureSettingState>(endpoint, body).pipe(
			tap((res) =>
				applyTransaction(() => {
					this.store.setCARUpdateStructureSettings(res);
				})
			)
		);
	}
}
