import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import produce from 'immer';
import * as moment from 'moment';
import * as R from 'ramda';
import { concat, forkJoin, from, Observable, of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	reduce,
	retry,
	shareReplay,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import {
	ApiService,
	JsonResultStatus,
} from '../../../../core/base/api.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { UserQuery } from '../../../../domain/user/user.query';
import { UserStore } from '../../../../domain/user/user.store';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { StaffSettings } from '../../../../shared/models/_general/staff-settings.model';
import MomentUtil from '../../../../util/moment.util';
import {
	columns,
	createRowFromPrototype,
	Metakey,
} from '../client-search-page/client-search-datatable.config';
import {
	ClientSearchRequest,
	ClientSearchResponse,
} from '../client-search-request.model';
import { ClientSearchUiQuery } from './client-search-ui.query';
import { ClientSearchQuery } from './client-search.query';
import { ClientSearchStore } from './client-search.store';

@Injectable()
export class ClientSearchService {
	/**
	 *
	 */
	constructor(
		protected api: ApiService,
		protected activityService: ActivityService,
		protected userQuery: UserQuery,
		protected userStore: UserStore,
		protected clientSearchStore: ClientSearchStore,
		protected clientSearchQuery: ClientSearchQuery,
		protected clientSearchUiQuery: ClientSearchUiQuery,
		protected loggerService: LoggerService
	) {}

	clear(): void {
		applyTransaction(() => {
			this.clientSearchStore.reset();
			this.clientSearchStore.ui.reset();
		});
	}

	search2(req: ClientSearchRequest) {
		this.clientSearchStore.uiStore.setSort(null, null);
		const batchLength = 100;

		const getIndexesToFetch: (res: ClientSearchResponse) => number[] = R.pipe(
			(res: ClientSearchResponse) =>
				Math.ceil(res.TotalCount / batchLength - 1),
			(totalPages: number) =>
				Array(totalPages)
					?.fill(1)
					?.map((x, i) => i + 1)
					?.slice(1)
		);
		const searchRequest = (request: ClientSearchRequest) =>
			this.api.post3<ClientSearchResponse>('search/clients/client', request);

		const firstPage$ = of(req).pipe(
			map(
				produce((draft) => {
					draft.Paging = {
						Index: 2,
						Column: req.Paging.Column,
						Direction: req.Paging.Direction,
					};
				})
			),
			mergeMap(searchRequest),
			shareReplay()
		);

		return firstPage$.pipe(
			mergeMap((res) =>
				concat(
					firstPage$,
					from(getIndexesToFetch(res)).pipe(
						map((i) =>
							produce(req, (draft) => {
								draft.Paging = {
									Index: i + 1,
									Column: req.Paging.Column,
									Direction: req.Paging.Direction,
								};
							})
						),
						concatMap((req2) => searchRequest(req2))
					)
				)
			),
			reduce((acc, v) =>
				produce(acc, (draft) => {
					draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
					draft.IsComplete = true;
				})
			),
			map((res) =>
				applyTransaction(() => {
					return res;
				})
			)
		);
	}

	search(req: ClientSearchRequest) {
		this.clientSearchStore.uiStore.setIsSearching(true);

		return this.api
			.post3<ClientSearchResponse>('search/clients/client', req)
			.pipe(
				withLatestFrom(this.clientSearchQuery.templateRow$),
				switchMap(([x, templateRow]) => {
					if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
						// Saves initial fetch
						const rows: any[] = R.map(
							createRowFromPrototype(templateRow),
							x.SearchResults
						);
						this.clientSearchStore.set([]);
						this.clientSearchStore.set([...rows]);
						return this.search2(req);
					}
					return of(x);
				}),
				withLatestFrom(
					this.clientSearchQuery.templateRow$,
					(res, template) => ({
						response: res,
						templateRow: template,
					})
				),
				map((res) =>
					applyTransaction(() => {
						const rows: any = R.map(
							createRowFromPrototype(res.templateRow),
							res.response.SearchResults
						);

						// Checks if there are existing data on local storage and total count > 500
						if (
							req.Paging.Index === 1 &&
							this.clientSearchQuery.getCount() !== 0 &&
							res.response.TotalCount > 500
						) {
							// Resets store to clear existing data
							this.clientSearchStore.set([]);
							// This resets isComplete status on local storage
							res.response.IsComplete = false;
						}

						const a = this.clientSearchQuery.getAll();
						if (
							req.Paging.Index === 1 &&
							res.response.IsComplete &&
							res.response.TotalCount <= 100
						) {
							this.clientSearchStore.set([]);
							this.clientSearchStore.set([...rows]);
						} else {
							this.clientSearchStore.set([...a, ...rows]);
						}
						this.clientSearchStore.update((state) => ({
							...state,
							count: res.response.TotalCount,
							totalAPI: res.response.TotalAPI,
							isComplete: res.response.IsComplete,
						}));

						return res.response;
					})
				),
				finalize(() => {
					this.clientSearchStore.setSearchForm(req);
					this.clientSearchStore.uiStore.setIsSearching(false);
				})
			);
	}

	reloadData() {
		const data = this.clientSearchQuery.getAll();
		this.clientSearchStore.set([...data]);
	}

	export(req: ClientSearchRequest): Observable<any> {
		return this.api.post4('export/clients/client', req).pipe(
			map((x) => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			retry(1),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.shared.export.error);
				return throwError(err);
			})
		);
	}

	tryParseJSON(jsonString: any): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}

	getColumns(): Observable<any> {
		return this.clientSearchQuery
			.select((x) => x.columns)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				withLatestFrom(
					this.userQuery.staffSettings$,
					this.userQuery.isUserWithOptFields$
				),
				map(([, staffSettings, isUserWithOptionFields]) =>
					!isUserWithOptionFields
						? JSON.parse(staffSettings?.CustomerSearchColumns) ?? []
						: (
								(JSON.parse(
									staffSettings?.CustomerSearchColumns
								) as string[]) ?? []
						  ).filter(
								(x) =>
									x !== 'Mobile' &&
									x !== 'Email' &&
									x !== 'Physical Address' &&
									x !== 'Work'
						  )
				),
				tap((x) =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.clientSearchStore.setColumns(x)
						: null
				)
			);
	}

	getColumnWidths(): Observable<any> {
		return this.clientSearchQuery
			.select((x) => x.columnWidths)
			.pipe(
				take(1),
				filter((x) => (x ? x.length < 1 : false)),
				withLatestFrom(
					this.userQuery.staffSettings$,
					this.userQuery.isUserWithOptFields$
				),
				map(([, staffSettings, isUserWithOptionFields]) =>
					!isUserWithOptionFields
						? JSON.parse(staffSettings?.CustomerSearchColumnsWidth) ?? []
						: (
								(JSON.parse(staffSettings?.CustomerSearchColumnsWidth) as {
									metakey: string;
									width: number;
								}[]) ?? []
						  ).filter(
								(x) =>
									x?.metakey !== 'Mobile' &&
									x?.metakey !== 'Email' &&
									x?.metakey !== 'Physical Address' &&
									x?.metakey !== 'Work'
						  )
				),
				tap((x) =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.clientSearchStore.setColumnWidths(x)
						: null
				)
			);
	}

	reorderColumn = (oldIndex: number, newIndex: number) => {
		const oldCol = this.clientSearchQuery.getValue().columns;
		const newCol = produce(oldCol, (draft) => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});
		const newColWith = produce(
			this.clientSearchQuery.getValue().columnWidths,
			(draft) => {
				const movedCol = draft?.splice(oldIndex, 1);
				draft?.splice(newIndex, 0, movedCol[0]);
			}
		);

		return of(newCol).pipe(
			tap(() => {
				this.clientSearchStore.setColumns(newCol);
				this.clientSearchStore.setColumnWidths(newColWith);
			}),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					CustomerSearchColumns: JSON.stringify(newVal),
					CustomerSearchColumnsWidth: JSON.stringify(newColWith),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			)
		);
	};

	resizeColumn(prop: string, width: number): Observable<any> {
		const oldColWidths = this.clientSearchQuery
			.getValue()
			.columnWidths?.filter((x) => x);
		const newColWidths = produce(oldColWidths, (draft) => {
			const col = columns?.find((x) => x.prop === prop);
			const exists = draft?.some((x) => col.metakey === x.metakey);
			if (exists) {
				draft.find((x) => col.metakey === x.metakey).width = width;
			} else {
				draft.push({ metakey: col.metakey, width });
			}
		});

		return of(newColWidths).pipe(
			tap((x) => this.clientSearchStore.setColumnWidths(x)),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					CustomerSearchColumnsWidth: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)
			)
		);
	}

	saveVisibleColumns = (metakeys: Metakey[]) => {
		const newColumns = metakeys;
		const oldColumns = this.clientSearchQuery.getValue().columns;
		if (R.equals(newColumns, oldColumns)) {
			return of();
		}

		this.clientSearchStore.uiStore.setIsColumnSaving(true);

		const newColumnMetakeys = newColumns;

		return of(newColumnMetakeys).pipe(
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					CustomerSearchColumns: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap
					? of(null)
					: this.api
							.put(`staff/${user.StaffID}/bl`, req)
							.pipe(tap(() => this.userStore.update(req)))
			),
			finalize(() => this.clientSearchStore.uiStore.setIsColumnSaving(false)),
			tap(() => this.clientSearchStore.setColumns(newColumnMetakeys))
		);
	};

	saveField(req: {
		CustomerId: number;
		MetaKey: Metakey;
		MetaValue: string;
	}): Observable<JsonResultStatus> {
		this.clientSearchStore.uiStore.setLoad(req.CustomerId, req.MetaKey, true);
		return this.api
			.put<JsonResultStatus>(
				`contacts/${req.CustomerId}/?isPatch=true`,
				R.omit(['CustomerId'], req)
			)
			.pipe(
				tap(() =>
					applyTransaction(() => {
						this.clientSearchStore.uiStore.setEdit(
							req.CustomerId,
							req.MetaKey,
							false
						);
						this.clientSearchStore.updateField(req);
						this.setTempValue(req.CustomerId, req.MetaKey, undefined);
					})
				),
				finalize(() =>
					this.clientSearchStore.uiStore.setLoad(
						req.CustomerId,
						req.MetaKey,
						false
					)
				)
			);
	}

	edit = (customerId: number, metakey: Metakey) =>
		this.clientSearchStore.uiStore.setEdit(customerId, metakey, true);

	cancel = (customerId: number, metakey: Metakey) =>
		applyTransaction(() => {
			this.clientSearchStore.uiStore.setEdit(customerId, metakey, false);
			this.setTempValue(customerId, metakey, undefined);
		});

	updateNote = (customerId: number, value: number) => {
		this.clientSearchStore.update(
			customerId,
			produce((draft) => {
				const nzToday = MomentUtil.createMomentNz().format('DD/MM/YYYY');
				const ln =
					nzToday +
					' - ' +
					this.userQuery.getValue().FirstName +
					' ' +
					this.userQuery.getValue().LastName +
					' - ' +
					value;
				draft.LastNote.value = ln;
			})
		);
	};

	updateClientAndUserNextActivity = (customerId: number) => {
		return forkJoin([
			this.updateClientNextActivity(customerId),
			this.updateUserNextActivity(customerId),
		]);
	};

	updateClientNextActivity = (customerId: number) => {
		this.clientSearchStore.uiStore.setLoad(
			customerId,
			'Client Next Activity',
			true
		);

		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/customer`, {
				nextActivityOnly: true,
			})
			.pipe(
				tap((x) => {
					this.clientSearchStore.update(
						customerId,
						produce((draft) => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.ClientNextActivityId = x.ActivityId;
								// tslint:disable-next-line: max-line-length
								draft.ClientNextActivity.value =
									formattedDate +
									' - ' +
									x.AssignedToAdviserName +
									' - ' +
									x.ActivityType +
									' - ' +
									x.ActivityName;
							} else {
								draft.ClientNextActivityId = null;
								draft.ClientNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() =>
					this.clientSearchStore.uiStore.setLoad(
						customerId,
						'Client Next Activity',
						false
					)
				)
			);
	};

	updateUserNextActivity = (customerId: number) => {
		this.clientSearchStore.uiStore.setLoad(
			customerId,
			'User Next Activity',
			true
		);

		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/adviser`, {
				nextActivityOnly: true,
			})
			.pipe(
				tap((x) => {
					this.clientSearchStore.update(
						customerId,
						produce((draft) => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.UserNextActivityId = x.ActivityId;
								draft.UserNextActivity.value =
									formattedDate +
									' - ' +
									x.ActivityType +
									' - ' +
									x.ActivityName;
							} else {
								draft.UserNextActivityId = null;
								draft.UserNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() =>
					this.clientSearchStore.uiStore.setLoad(
						customerId,
						'User Next Activity',
						false
					)
				)
			);
	};

	createClientNextActivity = (ac: ActivityViewModel) =>
		of(ActivityViewModel.MapToAdd(ac)).pipe(
			mergeMap((x) => this.activityService.Post(x)),
			mergeMap(
				(y) => {
					if (y) {
						return this.updateClientAndUserNextActivity(ac.Customer.CustomerId);
					}
				},
				(o) => o
			)
		);

	openPopup = () => this.clientSearchStore.uiStore.toggleColumnPopup(true);
	closePopup = () => this.clientSearchStore.uiStore.toggleColumnPopup(false);
	togglePopup = () =>
		this.clientSearchStore.uiStore.toggleColumnPopup(
			!this.clientSearchQuery.uiStore.getValue().columnFormPopupOpen
		);

	delete(customerId: number): Observable<any> {
		return of(customerId).pipe(
			tap(() => this.clientSearchStore.uiStore.setIsDeleting(customerId, true)),
			mergeMap((id) => this.api.delete<JsonResultStatus>(`contacts/${id}`)),
			finalize(() =>
				this.clientSearchStore.uiStore.setIsDeleting(customerId, false)
			),
			tap(() => {
				this.clientSearchStore.remove(customerId);
			})
		);
	}

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.clientSearchStore.uiStore.setSort(propSort, sort);
	}

	setTempValue = (customerId: number, metakey: string, value: any) =>
		this.clientSearchStore.uiStore.setTempValue(customerId, metakey, value);
}
