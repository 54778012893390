<form
	[formGroup]="form"
	class="form email-settings"
	[class.submitted]="submitted"
>
	<div class="row pb-0 pl-3 pb-2 pt-4">
		<div class="col-12 col-lg-6">
			<ng-container *ngIf="!hideEnableEmailOption">
				<div class="row mb-4">
					<div class="col-1 email-settings__checkbox m-0 pr-0 mw-50">
						<input
							type="checkbox"
							class="chckbox form-control theme-crm-field-disabled"
							id="isEnableEmailOption"
							formControlName="isEnableEmailOption"
						/>
					</div>
					<div class="col-10 pt-1 pl-0 email-settings__label">
						<label class="email-settings__label__text"
							>Enable Email option</label
						>
					</div>
				</div>
			</ng-container>

			<div [hidden]="!hideEnableEmailOption ? !IsEnableEmailOption : false">
				<div class="row pb-2" *ngIf="showFromField">
					<div class="col-12 col-lg-5 email-settings__label">
						<label class="form-check-label">From:</label>
					</div>
					<div class="col-12 col-lg-7 email-settings__textbox">
						<input
							type="text"
							class="form-control theme-crm-field-disabled"
							formControlName="sender"
						/>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-12 col-lg-5 email-settings__label">
						<label class="form-check-label">CC:</label>
					</div>
					<div class="col-12 col-lg-7 email-settings__textbox d-flex">
						<input
							type="text"
							class="form-control theme-crm-field-disabled"
							formControlName="carbonCopy"
						/>
						<label *ngIf="hasAutoCCAdviserOption" class="d-flex align-items-center ml-2 w-fill">
							<input
								type="checkbox"
								class="chckbox form-control theme-crm-field-disabled mr-2"
								id="autoCCAdviser"
								formControlName="autoCCAdviser"
							/>
							Auto CC Adviser
						</label>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-12 col-lg-5 email-settings__label">
						<label class="form-check-label">BCC:</label>
					</div>
					<div
						class="col-12 col-lg-1 email-settings__bcc d-flex align-items-center"
					>
						<span class="tap-text-color bcc-user">User, </span>
					</div>
					<div
						class="col-12 col-lg-6 email-settings__textbox"
					>
						<input
							type="text"
							class="form-control theme-crm-field-disabled"
							formControlName="blindCarbonCopy"
						/>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-12 col-lg-5 email-settings__label">
						<label class="form-check-label">Subject:</label>
					</div>
					<div class="col-12 col-lg-7 email-settings__textbox">
						<input
							type="text"
							class="form-control theme-crm-field-disabled"
							formControlName="subject"
							appClipboardNoFormat
						/>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-12 col-lg-5 email-settings__label">
						<label class="form-check-label">Template ID:</label>
					</div>
					<div class="col-12 col-lg-7 email-settings__textbox">
						<input
							type="text"
							class="form-control theme-crm-field-disabled"
							formControlName="templateID"
							appClipboardNoFormat
						/>
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-12 col-lg-5 email-settings__label">
						<label class="form-check-label">Unsubscribe Group ID</label>
					</div>
					<div class="col-12 col-lg-7 email-settings__textbox">
						<input
							type="text"
							class="form-control theme-crm-field-disabled"
							formControlName="unsubscribeGroupID"
						/>
					</div>
				</div>

				<div class="row email-settings__wysiwyg" *ngIf="showBody">
					<div class="col-12 col-lg-12 email-settings__label">
						<label class="form-check-label">Body:</label>
					</div>
					<div class="col-12 col-lg-12 mt-2 email-settings__wysiwyg">
						<app-wysiwyg
							#contentEditor
							[content]="bodyContent"
							[simpleEditor]="true"
							[extendOptions]="optionsWysiwyg"
							[shortcodes]="shortCodes"
						></app-wysiwyg>
					</div>
				</div>
			</div>

			<div class="row pb-0 pl-3 pt-2 mb-4">
				<div class="col-12 text-right">
					<app-manual-loader-mini
						[isLoading]="isLoading"
					></app-manual-loader-mini>
					<button
						type="button"
						class="btn btn-primary my-2 tap-bg-primary"
						(click)="onSave()"
						*ngIf="!isLoading"
					>
						SAVE
					</button>
				</div>
			</div>
		</div>
	</div>
</form>
