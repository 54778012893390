import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';

@Component({
  selector: 'app-partial-view',
  templateUrl: './partial-view.component.html',
  styleUrls: ['./partial-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartialViewComponent implements OnInit {

  @ViewChild('tbodyTarget') tbodyTarget;
  @Input() excelData: any;
  @Input() dropdownValues: ViewDisplayValue[];
  @Input() columnOrder: string[];

  @Output() reOrderColumnEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  changeColumnOrder(event: any, selectedDDIndex: any) {
    const itemIndex = this.columnOrder?.findIndex(item => item === event.target.value); // Returns -1 if metakey didnt match

    // Sets dropdown option and columnOrder duplicate label to empty
    if (itemIndex > -1) {
      // Sets dropdown option to empty
      const el = this.tbodyTarget.nativeElement;
      const dd = el.getElementsByClassName('ddOption');

      for (let i = 0; i < dd.length; i++) {
        if (dd[i].value === event.target.value && selectedDDIndex !== i) {
          dd[i].value = '';
        }
      }

      // Sets column order to empty
      this.columnOrder[itemIndex] = '';
    }
    // Set value base on index
    this.columnOrder[selectedDDIndex] = event.target.value;

    this.reOrderColumnEvent.emit(this.columnOrder);
  }

}
