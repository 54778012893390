<form
	[formGroup]="form"
	class="form monthly-expenses-form"
	[class.submitted]="submitted && Completed.valid"
>
	<div class="form-row flex-row align-items-center mb-3">
		<div class="col-12 col-lg-4 mb-2 text-lg-right text-left mb-2">
			<label class="">Will a full budget be completed?</label>
		</div>
		<div class="col-12 col-lg-3 mb-2">
			<span class="custom-dropdown col-md-12 p-0">
				<select
					id="fullBudgetCompleted"
					class="form-control crt-form-control"
					formControlName="completed"
					(click)="Completed.markAsTouched()"
					(change)="selectChange()"
				>
					<option
						*ngFor="let dropdown of APCRTYN$ | async"
						[value]="dropdown.value"
					>
						{{ dropdown.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row" *ngIf="isListLoading">
		<div class="col-12 col-lg-4 mb-2 text-lg-right text-left"></div>
		<div class="col-12 col-lg-3 mb-2">
			<span class="d-block">
				<app-manual-loader-mini
					[isLoading]="isListLoading"
				></app-manual-loader-mini>
			</span>
		</div>
	</div>

	<ng-container *ngIf="showTrueMonthly">
		<div class="form-row">
			<div class="col-lg-4 pl-10 mb-2">
				<label for="" class="font-weight-bold label-font tap-text-primary">
					Monthly Expenses
				</label>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="tap-text-primary">Mortgage Payments</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<input
					id="mortgagePayments"
					class="form-control crt-form-control"
					type="text"
					value="{{ MortgagePayments.value | currency }}"
					disabled
				/>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left d-lg-flex justify-content-end align-items-center mb-2"
			>
				<label class="">Rent</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="rent"
						class="form-control crt-form-control"
						formControlName="rentValue"
						type="text"
						placeholder="Amount"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="rentFrequency"
						class="form-control crt-form-control"
						formControlName="rentFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class="tap-text-primary"> Personal Loans/HP/Car Loans </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<input
					id="personalLoan"
					class="form-control crt-form-control"
					type="text"
					value="{{ PHCValue.value | currency }}"
					disabled
				/>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Power, Gas, Water </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="powerGasWater"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="pGWValue"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectPowerGasWater"
						class="form-control crt-form-control"
						formControlName="pGWValueFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Phone, Internet, Pay TV </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="phoneInternetTv"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="pIPValue"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectPhoneInternetTv"
						class="form-control crt-form-control"
						formControlName="pIPValueFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> House, Car & Contents </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="houseCareContents"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="hCCValue"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectHouseCareContents"
						class="form-control crt-form-control"
						formControlName="hCCValueFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Life, Risk & Medical Insurance </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="lifeRiskMedical"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="lRMValue"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectLifeRiskMedical"
						class="form-control crt-form-control"
						formControlName="lRMValueFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Car Expenses </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="carExpenses"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="carExpenses"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectCarExpenses"
						class="form-control crt-form-control"
						formControlName="carExpensesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Home Rates </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="homeRates"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="homeRates"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectHomeRates"
						class="form-control crt-form-control"
						formControlName="homeRatesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Pets </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="pets"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="pets"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectPets"
						class="form-control crt-form-control"
						formControlName="petsFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> School Fees </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="schoolFees"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="schoolFees"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectSchoolFees"
						class="form-control crt-form-control"
						formControlName="schoolFeesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Child Care </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="childCare"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="childCare"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown col-md-12 p-0">
					<select
						id="selectChildCare"
						class="form-control crt-form-control"
						formControlName="childCareFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Food, clothes </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="foodClothes"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="foodClothes"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectFoodClothes"
						class="form-control crt-form-control"
						formControlName="foodClothesFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div formArrayName="expenseList">
			<div
				class="form-row algin-items-center"
				*ngFor="let arrayItem of expenseList.controls; let pIndex = index"
				[formGroupName]="pIndex"
			>
				<div class="d-lg-none text-right col-12 col-lg-2">
					<ng-container *ngIf="!isAdviceProcessEnded">
						<button
							id="fullBudget_deleteExpenseItemButton_{{ pIndex }}"
							type="button"
							class="icon-btn w-auto h-auto"
							(click)="deleteExpenseItem(pIndex)"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
				</div>
				<div class="col-12 offset-lg-1 col-lg-3 text-lg-right text-left mb-2">
					<input
						id="expense_{{ pIndex }}"
						class="form-control text-lg-right crt-form-control"
						type="text"
						formControlName="expenseField"
						placeholder="Expense"
						(focusout)="onChangeCompute()"
					/>
				</div>
				<div class="col-12 col-lg-3 mb-2">
					<div class="dollar-icon">
						<input
							id="expenseTitle_{{ pIndex }}"
							class="form-control crt-form-control"
							type="text"
							formControlName="expenseValue"
							placeholder="Amount"
							(focusout)="onChangeCompute()"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<span class="custom-dropdown col-md-12 p-0">
						<select
							id="selectExpense_{{ pIndex }}"
							class="form-control d-inline crt-form-control"
							formControlName="expenseFrequency"
							(focusout)="onChangeCompute()"
						>
							<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
								{{ f.display }}
							</option>
						</select>
					</span>
					<ng-container *ngIf="!isAdviceProcessEnded">
						<button
							id="fullBudget_deleteExpenseItemButton_{{ pIndex }}"
							type="button"
							class="d-none d-lg-inline d-md-none d-xs-none d-sm-none icon-btn w-auto h-auto position-absolute pt-2"
							(click)="deleteExpenseItem(pIndex)"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
				</div>
			</div>
			<div class="form-row algin-items-center">
				<div class="col-12 col-lg-4 text-lg-right text-left mb-2">
					<ng-container *ngIf="!isAdviceProcessEnded">
						<button
							id="fullBudget_addExpenseItemButton"
							type="button"
							class="tap-btn tap-btn-outline tap-btn--shadow col-auto p-1 px-4"
							(click)="addExpenseItem()"
						>
							Add Expense +
						</button>
					</ng-container>
				</div>
				<div class="col-12 col-lg-3"></div>
				<div class="col-12 col-lg-2"></div>
				<div class="col-12 col-lg-2"></div>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class=""> Discretionary Spending </label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="discretionarySpending"
						class="form-control crt-form-control"
						type="text"
						placeholder="Amount"
						formControlName="discretionarySpendingValue"
						(focusout)="onChangeCompute()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
			<div class="col-12 col-lg-2">
				<span class="custom-dropdown w-100">
					<select
						id="selectDiscretionarySpending"
						class="form-control crt-form-control"
						formControlName="discretionarySpendingValueFrequency"
						(focusout)="onChangeCompute()"
					>
						<option *ngFor="let f of APCRTF$ | async" [value]="f.value">
							{{ f.display }}
						</option>
					</select>
				</span>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class="tap-text-primary">Total Expenses</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<input
					id="totalExpenses"
					class="form-control crt-form-control"
					type="text"
					value="{{ TotalExpenses.value | currency }}"
					disabled
				/>
			</div>
		</div>
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class="tap-text-primary">Unallocated Monthly Income</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<input
					id="unallocatedMonthlyIncomeTrue"
					class="form-control crt-form-control"
					type="text"
					value="{{ UnallocatedMonthlyIncome.value | currency }}"
					disabled
				/>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="showfalseMonthly">
		<div class="form-row algin-items-center">
			<div
				class="col-12 col-lg-4 text-lg-right text-left mb-2 d-lg-flex justify-content-end align-items-center"
			>
				<label class="">Unallocated Monthly Income</label>
			</div>
			<div class="col-12 col-lg-3 mb-2">
				<div class="dollar-icon">
					<input
						id="unallocatedMonthlyIncomeFalse"
						class="form-control crt-form-control"
						type="text"
						formControlName="unallocatedMonthlyIncome"
						(focusout)="onChange()"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
		</div>
	</ng-container>
</form>
