import { StoreConfig, Store } from '@datorama/akita';
import { produce } from 'immer';
import { PrimaryClientState } from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from '../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryBusinessState } from '../../../../shared/models/client-profile/seondary-business/secondary-business.model';
import { SecondaryTrustState } from '../../../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryProfessionalState } from '../../../../shared/models/client-profile/secondary-professional/secondary-professional.model';
import { LinkedContactState } from '../../../../shared/models/client-profile/linked-contact/linked.contact.model';

// Service
import { LrInsuranceState } from '../../../../shared/models/services/lr-insurance/lr-provider-group.model';
import { MortgageState } from '../../../../shared/models/services/mortgage/mortgage-group.model';
import { DocumentGroupState } from '../../../../shared/models/documents/document-group.model';
import { PropertyAssetState } from '../../../../shared/models/services/property-asset/property-asset-group.model';
import { FgInsuranceState } from '../../../../shared/models/services/fg-insurance/fg-provider-group.model';
import { KiwisaverState } from '../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { ActivityTimelineState } from '../../../../shared/models/activity-timeline/activity-timeline.model';
import { EditHistoryState } from '../../../../shared/models/history/history.model';
import { CurrentActivityCriteriaState } from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { AdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { AssetState, LiabilityState } from 'src/app/shared/models/services/assets/assets';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { InvestmentState } from 'src/app/shared/models/services/investments/investments.model';
import { Injectable } from "@angular/core";

export interface ClientProfileState {
	primaryClient: PrimaryClientState;
	secondaryClients: SecondaryClientState[];
	secondaryBusinesses: SecondaryBusinessState[];
	secondaryTrusts: SecondaryTrustState[];
	secondaryProfessionals: SecondaryProfessionalState[];
	linkedContacts: LinkedContactState[];
	lrInsurance: LrInsuranceState;
	mortgage: MortgageState;
	propertyAsset: PropertyAssetState | any;
	asset: AssetState;
	liability: LiabilityState;
	fgInsurance: FgInsuranceState;
	kiwiSavers: KiwisaverState[];
	investments: InvestmentState[];
	histories: EditHistoryState[];
	documents: DocumentGroupState;
	activityTimeline: ActivityTimelineState;
	currentActivityCriterias: CurrentActivityCriteriaState[];
	adviceProcesses: AdviceProcessState;

	sciIsLoading: boolean;
	sccIsLoading: boolean;
	sctIsLoading: boolean;
	scpIsLoading: boolean;

	lrIsLoading: boolean;
	mIsLoading: boolean;
	paIsLoading: boolean;
	aIsLoading: boolean;
	fgIsLoading: boolean;
	kIsLoading: boolean;
	iIsLoading: boolean;

	historyIsLoading: boolean;
	documentIsLoading: boolean;

	adviceProcessIsLoading: boolean;
}

@Injectable()
@StoreConfig({ name: 'clientProfile' })
export class ClientProfileStore extends Store<ClientProfileState> {
	constructor() {
		super({
			primaryClient: null,
			secondaryClients: null,
			secondaryBusinesses: null,
			secondaryTrusts: null,
			secondaryProfessionals: null,
			linkedContacts: null,
			lrInsurance: null,
			mortgage: null,
			propertyAsset: null,
			asset: null,
			liability: null,
			fgInsurance: null,
			kiwiSavers: null,
			investments: null,
			activityTimeline: null,
			histories: [],
			documents: null,
			currentActivityCriterias: null,
			adviceProcesses: null,

			sciIsLoading: false,
			sccIsLoading: false,
			sctIsLoading: false,
			scpIsLoading: false,

			lrIsLoading: false,
			mIsLoading: false,
			paIsLoading: false,
			aIsLoading: false,
			fgIsLoading: false,
			kIsLoading: false,

			historyIsLoading: false,
			documentIsLoading: false,
		} as ClientProfileState);
	}

	setPrimaryClient(p: PrimaryClientState) {
		this.update({ primaryClient: p });
	}

	setSecondaryClients(s: SecondaryClientState[]) {
		this.update({ secondaryClients: s });
	}

	setSecondaryBusinesses(b: SecondaryBusinessState[]) {
		this.update({ secondaryBusinesses: b });
	}

	setSecondaryTrusts(t: SecondaryTrustState[]) {
		this.update({ secondaryTrusts: t });
	}

	setSecondaryProfessionals(p: SecondaryProfessionalState[]) {
		this.update({ secondaryProfessionals: p });
	}

	setLinkedContacts(p: LinkedContactState[]) {
		this.update({ linkedContacts: p });
	}

	setLrInsurance(lr: LrInsuranceState) {
		this.update(
			produce((draft) => {
				draft.lrInsurance = lr;
			})
		);
	}

	setMortgage(m: MortgageState) {
		this.update(
			produce((draft) => {
				draft.mortgage = m;
			})
		);
	}

	setPropertyAsset(pa: PropertyAssetState | any) {
		this.update(
			produce((draft) => {
				draft.propertyAsset = pa;
			})
		);
	}

	setAsset(a: AssetState) {
		this.update(
			produce((draft) => {
				draft.asset = a;
			})
		);
	}

	setLiability(liabilities: LiabilityState) {
		this.update(
			produce((draft) => {
				draft.liability = liabilities;
			})
		);
	}

	setFgInsurance(fg: FgInsuranceState) {
		this.update(
			produce((draft) => {
				draft.fgInsurance = fg;
			})
		);
	}

	setKiwiSaver(k: KiwisaverState[]) {
		this.update({ kiwiSavers: k });
	}

	setInvestments(k: InvestmentState[]) {
		this.update({ investments: k });
	}

	setHistories(h: EditHistoryState[]) {
		this.update({ histories: h });
	}

	setDocuments(d: any) {
		this.update(
			produce((draft) => {
				draft.documents = d;
			})
		);
	}

	setActivityTimeline(activityTimeline: ActivityTimelineState) {
		this.update(
			produce((draft) => {
				draft.activityTimeline = activityTimeline;
			})
		);
	}

	setCriterias(c: CurrentActivityCriteriaState[]) {
		this.update(
			produce((draft) => {
				draft.currentActivityCriterias = c;
			})
		);
	}

	setAdviceProcesses(ap: AdviceProcessState) {
		this.update(
			produce((draft) => {
				draft.adviceProcesses = ap;
			})
		);
	}

	setIsLoading(isLoading: boolean, key: string) {
		this.update(
			produce((draft) => {
				if (key === CustomerTypes.SecondaryCustomerIndividual?.toLowerCase()) {
					draft.sciIsLoading = isLoading;
				}
				if (key === CustomerTypes.SecondaryCustomerCompany?.toLowerCase()) {
					draft.sccIsLoading = isLoading;
				}
				if (key === CustomerTypes.SecondaryCustomerTrust?.toLowerCase()) {
					draft.sctIsLoading = isLoading;
				}
				if (
					key === CustomerTypes.SecondaryCustomerProfessional?.toLowerCase()
				) {
					draft.scpIsLoading = isLoading;
				}

				if (key === ServicesCodes.LR?.toLowerCase()) {
					draft.lrIsLoading = isLoading;
				}
				if (key === ServicesCodes.Mortgage?.toLowerCase()) {
					draft.mIsLoading = isLoading;
				}
				if (key === ServicesCodes.Property?.toLowerCase()) {
					draft.paIsLoading = isLoading;
				}
				if (key === ServicesCodes.Asset?.toLowerCase()) {
					draft.aIsLoading = isLoading;
				}
				if (key === ServicesCodes.FG?.toLowerCase()) {
					draft.fgIsLoading = isLoading;
				}
				if (key === ServicesCodes.KiwiSaver?.toLowerCase()) {
					draft.kIsLoading = isLoading;
				}
				if (key === ServicesCodes.Investment?.toLowerCase()) {
					draft.iIsLoading = isLoading;
				}

				if (key === 'document') {
					draft.documentIsLoading = isLoading;
				}
				if (key === 'history') {
					draft.historyIsLoading = isLoading;
				}
				if (key === ServicesCodes.AdviceProcess?.toLowerCase()) {
					draft.adviceProcessIsLoading = isLoading;
				}
			})
		);
	}
}
