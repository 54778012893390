<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.People"></app-header>
</div>

<!-- Content -->
<div role="main-content" class="people clearfix">
	<div class="main-content-section">
		<div class="main-content-section__header mb-3">People</div>
		<div class="main-content-section__body mb-3">
			<app-manual-loader-mini
				*ngIf="isPeopleLoading$ | async as isLoading"
				[isLoading]="isLoading"
			></app-manual-loader-mini>
			<app-people
				[people$]="people$"
				[SCR$]="SCR$"
				[PCE$]="PCE$"
				[APCRTG$]="APCRTG$"
				[APCRTVD$]="APCRTVD$"
				[APCRTTIR$]="APCRTTIR$"
				[APCRTMS$]="APCRTMS$"
				[PCT$]="PCT$"
				[isPeopleLoading$]="isPeopleLoading$"
				[addNewPeopleFn$]="addNewPeopleFn$"
				[addNewDependantFn$]="addNewDependentFn$"
				[addExistingPeopleFn$]="addExistingPeopleFn$"
				[updateFn$]="updatePeopleFn$"
				[deleteFn$]="deletePeopleFn$"
			></app-people>
		</div>
		<div class="main-content-section__footer mb-3">
			<div class="border-bottom"></div>
		</div>
	</div>

	<div class="main-content-section">
		<div class="main-content-section__header mb-3">Dependants</div>
		<div class="main-content-section__body mb-3">
			<app-manual-loader-mini
				*ngIf="isDependantsLoading$ | async as isLoading"
				[isLoading]="isLoading"
			></app-manual-loader-mini>
			<app-dependant
				[dependents$]="dependents$"
				[SCR$]="SCR$"
				[APCRTG$]="APCRTG$"
				[isDependantsLoading$]="isDependantsLoading$"
				[addFn$]="addExistingDependentFn$"
				[addNewFn$]="addNewDependentFn$"
				[updateFn$]="updateDepentdentFn$"
				[deleteFn$]="deleteDepentdentFn$"
				[addNewPeopleFn$]="addNewPeopleFn$"
			></app-dependant>
		</div>
		<div class="main-content-section__footer mb-3">
			<div class="border-bottom"></div>
		</div>
	</div>

	<div class="main-content-section">
		<div class="main-content-section__header mb-3">Trusts</div>
		<div class="main-content-section__body mb-3">
			<app-manual-loader-mini
				*ngIf="isTrustsLoading$ | async as isLoading"
				[isLoading]="isLoading"
			></app-manual-loader-mini>
			<app-trust
				[trusts$]="trusts$"
				[SCTT$]="SCTT$"
				[isTrustsLoading$]="isTrustsLoading$"
				[addFn$]="addExistingTrustFn$"
				[addNewFn$]="addNewTrustFn$"
				[updateFn$]="updateTrustFn$"
				[deleteFn$]="deleteTrustFn$"
			></app-trust>
		</div>
		<div class="main-content-section__footer mb-3">
			<div class="border-bottom"></div>
		</div>
	</div>

	<div class="main-content-section">
		<div class="main-content-section__header mb-3">Company</div>
		<div class="main-content-section__body mb-3">
			<app-manual-loader-mini
				*ngIf="isCompanyLoading$ | async as isLoading"
				[isLoading]="isLoading"
			></app-manual-loader-mini>
			<app-company
				[company$]="company$"
				[APCRTBP$]="APCRTBP$"
				[APCRTYNNA$]="APCRTYNNA$"
				[isCompanyLoading$]="isCompanyLoading$"
				[addFn$]="addExistingCompanyFn$"
				[addNewFn$]="addNewCompanyFn$"
				[updateFn$]="updateCompanyFn$"
				[deleteFn$]="deleteCompanyFn$"
			></app-company>
		</div>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer [currentPageId]="pageIds.People" [sidebar]="sidebar"></app-footer>
</div>
