import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
	filter,
	finalize,
	map,

	take,
	takeUntil,
	tap
} from 'rxjs/operators';
import { PeopleDetailsState } from '../../../../../../shared/models/client-review-template/people/people-details.model';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { IncomeSourceMapper, PreviousIncomeSourceMapper } from '../../mapper/income.mapper';
import {
	PreviousIncomeSourceState
} from '../../models/income.model';

declare var $: any;

@Component({
	selector: 'app-crt-previous-income-source',
	templateUrl: './crt-previous-income-source.component.html',
	styleUrls: ['./crt-previous-income-source.component.scss'],
})
export class CrtPreviousIncomeSourceComponent implements OnInit, OnDestroy {
	@Input() deleteFn$: (id) => Observable<any>;
	@Input() updateFn$: (data) => Observable<any>;
	@Input() addNewFn$: (req: any) => Observable<any>;
	@Input() adviceProcessId: number;
	@Input() incomeSource$: Observable<PreviousIncomeSourceState[]>;
	@Input() transferedSCIList$: Observable<PeopleState[]>;
	@Input() peopleFromCRMAndCRTExceptChild$: Observable<any[]>;
	@Input() people$: Observable<PeopleDetailsState[]>;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	onDestroy$ = new Subject<void>();
	isAdd: boolean;
	editMode$ = new BehaviorSubject<any>(null); // contains cRTId of asset being edited

	elseMinusIncome = true;
	isListLoading = false;
	overallPrevious = 0;
	incomeList: PreviousIncomeSourceState[];
	peopleList: ViewDisplayValue[];
	transferedSCI: PeopleState[];

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	@Input() deceasedSciList$: Observable<any[]>;

	constructor(
		private fb: UntypedFormBuilder,
	) {
		this.buildForm();
	}

	get IncomeArray() {
		return this.form.get('incomeArray') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.getPeopleList();
		this.prepData();

		this.incomeSource$
			.pipe(
				filter((data) => !!data),
				takeUntil(this.onDestroy$)
			)
			.subscribe((data) => (this.incomeList = data));
	}

	prepData() {
		this.incomeSource$
			.pipe(
				tap(() => (this.isListLoading = true)),
				filter((data) => !!data && !!this.peopleList),
				map(
					(data) =>
						data?.map((item) => PreviousIncomeSourceMapper.mapIncomeToView(item)) || []
				),
				tap(data => this.incomeList = [...data]),
				finalize(() => (this.isListLoading = false)),
				take(1)
			)
			.subscribe();
	}

	buildForm() {
		this.form = this.fb.group({
			incomeArray: this.fb.array([]),
		});
	}

	getPeopleList() {
		combineLatest([this.peopleFromCRMAndCRTExceptChild$, this.people$])
			.pipe(
				map(([crmPeople, crtPeople]) => [
					...IncomeSourceMapper.mapCRMPeopleToDd(crmPeople),
					...IncomeSourceMapper.mapCRTPeopleToDd(crtPeople),
				]),
				takeUntil(this.onDestroy$)
			)
			.subscribe((res) => {
				this.peopleList = res;
			});
	}

	collapseFalse() {
		this.elseMinusIncome = false;
	}

	collapseMoreIncome() {
		$('#collapseIncome').toggle();
		this.elseMinusIncome = false;
	}

	collapseLessIncome() {
		$('#collapseIncome').toggle();
		this.elseMinusIncome = true;
	}

	trackByFn(index: number, income: PreviousIncomeSourceState): number {
		return income.cRTId;
	}

	add() {
		this.isAdd = true;
		this.editMode$.next(null);
	}

	cancelEvent() {
		this.isAdd = false;
		this.editMode$.next(null);
	}

	saveEvent() {
		this.isAdd = false;
		this.editMode$.next(null);
	}

	isEditMode(value?) {
		this.isAdd = false;
		this.editMode$.next(value);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
