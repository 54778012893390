import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { PropertyPurchaseFormMapper } from '../../../../../../modules/crm/crt-page/_shared/mapper/property-purchase-form.mapper';
import { logMessage } from '../../../../../error-message/error-message';
@Component({
  selector: 'app-property-purchase-form',
  templateUrl: './property-purchase-form.html',
  styleUrls: ['./property-purchase-form.scss'],
})
export class PropertyPurchaseFormComponent implements OnInit {
  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Input() propertyUse: ViewDisplayValue[];
  @Input() securityValuation: ViewDisplayValue[];
  @Input() type: ViewDisplayValue[];
  @Input() title: ViewDisplayValue[];
  @Input() rentalFrequencyChoices: ViewDisplayValue[];
  @Input() rateFrequencyChoices: ViewDisplayValue[];
  @Input() insuranceFrequencyChoices: ViewDisplayValue[];
  @Input() otherExpenseFrequencyChoices: ViewDisplayValue[];
  @Input() policyOwners: ViewDisplayValue[];

  @Input() propertyInfo: any;
  @Input() isLoading: boolean;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

  public bsModalRef: BsModalRef;
  form: UntypedFormGroup;
  submitted = false;
  isLoadingBtn = false;

  constructor(
    private fb: UntypedFormBuilder,
    private loggerService: LoggerService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.prepareData();
  }

  prepareData() {
    if (!this.propertyInfo) {
      return;
    }

    if (this.propertyInfo.IsTBC) {
      this.PropertyAddress.disable()
    }

    this.form.reset(PropertyPurchaseFormMapper.mapToView(this.propertyInfo));
  }

  get PropertyAddress() {
    return this.form.get('propertyAddress');
  }
  get PropertyValue() {
    return this.form.get('propertyValue');
  }
  get Owners() {
    return this.form.get('propertyOwner');
  }

  get RentalIncome() {
    return this.form.get('rentalIncome');
  }

  get PropertyUse() {
    return this.form.get('propertyUse');
  }

  get IsTBC() {
    return this.form.get('isTBC');
  }

  get BoarderIncome() {
    return this.form.get('boarderIncome');
  }

  buildForm() {
    this.form = this.fb.group({
      propertyAddress: ['', Validators.required],
      propertyUse: [''],
      financeDate: [null],
      purchaseDate: [null],
      propertyValue: ['', Validators.required],
      valuationType: [null],
      valuationTypeDate: [null],
      propertyOwner: ['', Validators.required],
      rentalIncome: [null],
      rentalIncomeFrequency: ['Fortnightly'],
      boarderIncome: [null],
      boarderIncomeFrequency: ['Fortnightly'],
      title: [null],
      rates: [null],
      ratesFrequency: ['Quarterly'],
      type: [null],
      insurance: [null],
      insuranceFrequency: ['Monthly'],
      otherExpense: [null],
      otherExpenseFrequency: ['Monthly'],
      isTBC: [null]
    });
  }

  saveBtnClick() {
    if (this.isLoading) {
      return;
    }
    this.submitted = true;
    this.isLoadingBtn = true;
    if (this.form.invalid) {
      this.loggerService.Warning(null, logMessage.shared.general.warning.required);
      this.isLoadingBtn = false;
      return;
    }
    let value = this.form.getRawValue();
    const isTBC = this.IsTBC.value
    value = {
      ...value,
      propertyAddress: isTBC ? this.propertyInfo.PropertyAddress  || null : this.PropertyAddress.value
    }
    this.saveEvent.emit(
      !!this.propertyInfo
        ? {
            ...value,
            cRTId: this.propertyInfo.CRTId,
            customerServiceID: this.propertyInfo.CustomerServiceID,
          }
        : value
    );
    this.isLoadingBtn = false;
  }

  cancelClick() {
    this.cancelEvent.emit(true);
  }

  get showBoarderIncomeField() {
		return this.PropertyUse.value === 'Owner Occupied';
	}

  toggleTBC(event) {
    if (event.target.checked) {
      this.PropertyAddress.disable()
      this.PropertyAddress.setValue('TBC')
    } else {
      const propertyAddress = this.propertyInfo.PropertyAddress
      if (propertyAddress) {
        this.PropertyAddress.setValue(propertyAddress)
      }
      this.PropertyAddress.enable()
    }
  }

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.policyOwners);
	}
}
