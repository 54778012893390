<div class="app-dropdown-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">Dropdowns</h1>
	</div>
	<div class="app-dropdown-content-container">
		<div class="container-fluid">
			<div class="row tap-container justify-content-center">
				<div class="col-xl-8 pt-3" #dropdownHeader>
					<h6>Select the dropdown value you wish to edit</h6>
					<div
						class="scc-border mb-2"
						[ngStyle]="{ 'width.px': horizontalHeaderWidth }"
					></div>
				</div>
			</div>
			<!-- <div class="form-row tap-container justify-content-center">
				<div class="col-xl-6 scc-border mb-2"></div>
				<div class="col-xl-2 mb-2"></div>
			</div> -->
			<div class="row tap-container justify-content-center">
				<div class="app-content col-xl-8">
					<div class="row align-items-center mb-3">
						<div class="app-choice col-auto px-3">
							<span class="custom-dropdown">
								<select
									class="w-100"
									[(ngModel)]="model.currentDropdown"
									(change)="model.onDropdownChange.next($event.target.value)"
									class="p-2"
								>
									<option value=""></option>
									<option
										*ngFor="let dd of model.dropdownList"
										[value]="dd.dropdownCode"
									>
										{{ dd.dropdownName }}
									</option>
								</select>
							</span>
						</div>
						<div class="col-auto">
							<app-loader-mini service="ControlLoaderService"></app-loader-mini>
						</div>
					</div>
					<div class="row">
						<div
							class="app-settings mb-5 col-12"
							*ngIf="model.viewShowSettings | async"
						>
							<!-- <h6 class="mb-3">Settings</h6> -->
							<h6 class="mb-3"></h6>
							<!-- Sortable -->
							<div class="form-row">
								<div class="col-3">Name</div>
								<ng-container *ngIf="model.hasCustomFields">
									<ng-container
										*ngFor="
											let field of customDropdowns[this.model.currentDropdown]
										"
									>
										<ng-container *ngIf="field.type === 'email'">
											<div class="col-4">Email</div>
										</ng-container>
									</ng-container>
								</ng-container>
								<div class="col-1">Default</div>
								<div class="col-3"></div>
							</div>

							<bs-sortable
								[(ngModel)]="dropdownList"
								fieldName="dropdownValue"
								[itemTemplate]="ddItem"
								itemClass="sortable-item py-1"
								itemActiveClass="sortable-item-active bg-light"
								placeholderItem="Drag here"
								placeholderClass="placeholder-drag text-muted"
								wrapperClass="visible-sortable-wrapper py-3"
							>
							</bs-sortable>

							<ng-container *appRestrictTo="['BADV']">
								<div class="form-row">
									<div
										class="col-3"
										[ngClass]="{
											'offset-8': model?.hasCustomFields,
											'offset-4': !model?.hasCustomFields
										}"
									>
										<button
											class="btn btn-primary ml-4"
											(click)="addDropdown()"
											[appTheme]="{ 'background-color': 'primarycolor' }"
											[disabled]="isSavingDropdown || isAddMode"
										>
											+ Add
										</button>
									</div>
								</div>
							</ng-container>

							<div>
								<button
									*ngIf="!isSaving"
									class="btn btn-primary app-save mr-2"
									(click)="save()"
									[disabled]="isSaving || isSavingDropdown || isAddMode"
									[appTheme]="{ 'background-color': 'primarycolor' }"
								>
									Save
								</button>
								<button
									*ngIf="!isSaving"
									class="btn btn-light app-cancel"
									(click)="cancelEdit()"
								>
									Cancel
								</button>

								<app-manual-loader-mini *ngIf="isSaving" [isLoading]="isSaving">
								</app-manual-loader-mini>
							</div>

							<!-- End Of Sortable -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ng-template #deleteModal>
		<div class="modal-header">Delete a dropdown value?</div>
		<div class="modal-body text-center">
			<p>Choose a value to switch for deleted value:</p>
			<select #options>
				<option disabled value=""></option>
				<option
					*ngFor="let d of this.dropdownListSwitchTo"
					[value]="d.dropdownValueId"
				>
					{{ d.dropdownValue }}
				</option>
			</select>
		</div>
		<div class="modal-footer">
			<ng-container *ngIf="!isDeleting">
				<button
					type="button"
					class="btn btn-danger"
					[disabled]="!options.value"
					(click)="
						options.value ? model.onDeleteConfirm.next(options.value) : false
					"
				>
					Delete
				</button>
				<button type="button" class="btn btn-light" (click)="confirmCancel()">
					Cancel
				</button>
			</ng-container>
			<app-manual-loader-mini [isLoading]="isDeleting"></app-manual-loader-mini>
		</div>
	</ng-template>

	<ng-template #ddItem let-item="item" let-index="index">
		<div class="form-row align-items-center">
			<div class="col-3 d-flex align-items-center" #colName>
				<span class="sortable-icon pt-1-icon"
					><i class="material-icons md-10 dark">reorder</i></span
				>
				<span class="sortable-name flex-grow-1 px-2">
					<span *ngIf="!item.initData.isEdit" class="sortable-name pb-1">{{
						item.value
					}}</span>
					<input
						*ngIf="item.initData.isEdit"
						[ngClass]="{
							'form-control': item.initData.isEdit,
							'w-100': !item.initData.isEdit,
							'border-danger': name?.dirty && name?.errors?.required
						}"
						type="text"
						[(ngModel)]="item.value"
						required
						#name="ngModel"
					/>
				</span>
			</div>

			<div
				*ngIf="model.hasCustomFields"
				class="col-4 text-truncate"
				#colCustomFields
			>
				<ng-container
					*ngFor="let field of customDropdowns[this.model.currentDropdown]"
				>
					<span
						*ngIf="!item.initData.isEdit"
						class="sortable-name pb-1"
						title="{{ item.initData?.parsedValue?.[field?.key] }}"
						>{{ item.initData?.parsedValue?.[field?.key] }}</span
					>
					<ng-container *ngIf="item.initData.isEdit">
						<ng-container *ngIf="field.type === 'email'">
							<span class="sortable-name">
								<input
									type="email"
									name="email"
									[pattern]="emailRegex"
									class="form-control"
									[ngClass]="{
										'border-danger': email?.dirty && email?.errors?.pattern
									}"
									[(ngModel)]="item.initData?.parsedValue[field?.key]"
									#email="ngModel"
								/>
							</span>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>

			<div class="col-auto" #colDefault>
				<span class="sortable-name ml-2">
					<input
						[disabled]="!item.initData.dropdownValueId"
						type="radio"
						name="isDefault"
						[checked]="item.initData.isDefault"
						(click)="changeDefault(item.initData, item.initData.isDefault)"
						#radio
					/>
				</span>
			</div>

			<div class="col-auto" #colButtons>
				<!--BL - Edit dropdown values-->
				<ng-container *ngIf="!item.initData.isSaving">
					<ng-container *appRestrictTo="['BEDV']">
						<!-- EDIT button -->
						<button
							*ngIf="!item.initData.isEdit"
							class="btn-placeholder btn btn-link p-1"
						>
							<i class="material-icons md-20 dark">&#xE3C9;</i>
						</button>

						<button
							*ngIf="!item.initData.isEdit"
							class="btn btn-link p-1"
							(click)="item.initData.isEdit = true; isAddMode = true"
							[disabled]="isSaving || isSavingDropdown"
						>
							<i class="material-icons md-20 dark">&#xE3C9;</i>
						</button>
						<!-- CANCEL button in edit mode -->
						<button
							*ngIf="item.initData.isEdit"
							class="btn btn-link p-1"
							(click)="
								cancel(index, item.initData); item.initData.isEdit = false
							"
						>
							<i class="material-icons md-20 dark">&#xE5C9;</i>
						</button>
						<!-- SAVE button in edit mode -->
						<button
							*ngIf="item.initData.isEdit"
							class="btn btn-link p-1"
							(click)="update(item.initData, item.value)"
							[disabled]="isSaving || isSavingDropdown || !item.value"
						>
							<i class="material-icons md-20 dark">save</i>
						</button>
					</ng-container>
					<ng-container *appRestrictTo="['BDDV']">
						<!-- DELETE button in edit mode -->
						<button
							*ngIf="item.initData.dropdownValueId"
							class="btn btn-link p-1"
							[disabled]="isSavingDropdown"
							(click)="delete(index, item.initData)"
						>
							<i class="material-icons md-20 dark">&#xE872;</i>
						</button>
					</ng-container>
				</ng-container>
				<div class="p-2" *ngIf="item.initData.isSaving">
					<app-manual-loader-mini [isLoading]="item.initData.isSaving">
					</app-manual-loader-mini>
				</div>
			</div>
		</div>
	</ng-template>
</div>
