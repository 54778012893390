import {
  MergeTagDetails,
  MergeTagState,
} from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';

export interface DisclosureDocumentDetails {
  SettingsId: number;
  ReferenceId: number;
  Type: string;
  Template: string;
  IsEnableEmailOption: boolean;
  Sender: string;
  CarbonCopy: string;
  BlindCarbonCopy: string;
  Subject: string;
  TemplateID: string;
  UnsubscribeGroupID: number;
  MergeTag: MergeTagDetails[];
}

export interface DisclosureDocumentState {
  crtId?: number;
  settingsId?: number;
  referenceId?: number;
  type?: string;
  template?: string;
  isEnableEmailOption?: boolean;
  sender?: string;
  carbonCopy?: string;
  blindCarbonCopy?: string;
  subject?: string;
  templateID?: string;
  unsubscribeGroupID?: number;
  mergeTag?: MergeTagState[];
  body?: string | number;
  templateType?: string;
  uploadTemplate?: string | number;
}

export interface DisclosureState {
  cRTId: number;
  adviceProcessId: number;
  document: DocumentState;
  sectionCode?: string;
  status?: number;
  parentCRTId?: number;
  content?: string;
  clickedNext?: boolean;
  templateType?: string;
  fileInfo?: LOATDisclosureDocument
}

export interface DocumentState {
  referenceId: number;
  value: string;
}

export interface LOATDisclosureDocument {
  fileName: string;
  fileUrl: string;
  documentID: string | number;
}

export interface DocumentUploadState {
  referenceId?: number;
  document: string;
  documentId?: number;
  fileName?: string;
  type?: string;
  name?: string;
  documentLink?: string;
  documentName?: string;
}

export const defaultDD = {
  template: null,
  isEnableEmailOption: false,
  sender: '',
  carbonCopy: '',
  blindCarbonCopy: '',
  subject: '',
  templateID: '',
  unsubscribeGroupID: null,
  mergeTag: [],
  body: null,
};

export interface DisclosureSettingsState {
  referenceId: number;
  type: string;
  disclosure: string;
  disclosureType: string;
  documentID: string;
  disclosureTypeUD?: boolean;
  disclosureTypeFT?: boolean;
}
