import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { logMessage } from '@shared/error-message/error-message';
import {
	ALLOWED_DOCUMENT_FILE_TYPES,
	UploadModalComponent,
} from '@shared/modal/upload-modal/upload-modal.component';
import { AdviceProcessCode, ClaimsDocumentField } from '@shared/models/advice-process/advice-process.model';
import { DocumentGroupState } from '@shared/models/documents/document-group.model';
import { LinkDocumentComponent } from '@shared/services/link-document/link-document.component';
import { AppFormGroup } from '@util/form-group';
import { convertUtil, fileUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	Observable,
	Subject,
	concatMap,
	filter,
	finalize,
	iif,
	map,
	mergeMap,
	of,
	take,
	tap,
} from 'rxjs';
import { AddDocumentModalShowOption } from './add-document-modal.service';
import { clone } from 'ramda';

@Component({
	selector: 'app-add-document-modal',
	templateUrl: './add-document-modal.component.html',
	styleUrls: ['./add-document-modal.component.scss'],
})
export class AddDocumentModalComponent implements AddDocumentModalShowOption {
	@Input() title = '+ Add Claim Document';

	@Input() documentNames$: Observable<
		{
			display: string;
			value: string;
		}[]
	>;

	@Input() uploadFn$: (doc: any, data: any) => Observable<any>;

	@Input() linkFn$: (doc: any, data: any) => Observable<any>;

	@Input() clientDocumentLoader$: () => Observable<DocumentGroupState>;

	@Input() defaultLinkDocumentTab: string;

	@Input() documentType: string;

	clientDocumentIsLoading = false;

	adviceProcessCode: AdviceProcessCode;

	clientDocuments: DocumentGroupState;

	customerId: number;

	result$: Subject<any>;

	isUploading = false;

	bsModalRefUpload: BsModalRef;

	linkModalRef: BsModalRef;

	formGroup = new AppFormGroup({
		documentName: new FormControl(null, [Validators.required]),
	});

	documentToUpload: any;

	isOthersDocumentSelected = false;

	get documentNameForm() {
		return this.formGroup.get('documentName');
	}

	constructor(
		private modalService: BsModalService,
		private bsModalRef: BsModalRef,
		private crtDocService: CrtDocumentService,
		private loggerService: LoggerService
	) {}

	hide(): void {
		this.bsModalRef.hide();
	}

	upload(): void {
		if (this.isUploading) {
			return;
		}
		if (this.formGroup.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		this.isUploading = true;
		of(true)
			.pipe(
				mergeMap(() => {
					if (this.documentToUpload.Type === 'linked') {
						return this.linkFn$(
							this.documentToUpload,
							this.formGroup.getRawValue()
						);
					}
					return this.uploadFn$(
						this.documentToUpload,
						this.formGroup.getRawValue()
					);
				}),
				take(1),
				finalize(() => {
					this.isUploading = false;
					this.hide();
				})
			)
			.subscribe();
	}

	uploadDocument() {
		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

		// convert Image File -> Base64 -> HTML img -> PDF
		const convertImageToBase64 = (file: File): Observable<string> => {
			return new Observable((obs) => {
				obs.next(file);
				obs.complete();
			}).pipe(
				// convert file to base64
				mergeMap(() => convertUtil.simpleConvertToBase64(file)),
				// create a html img string and add the converted base64 image in src
				map((base64) => `<img src="${base64}" />`),
				// convert html img with base64 src to pdf
				mergeMap((img) => {
					return this.crtDocService.generatePDFbase64(img, {
						FileName: convertFilename(file),
					});
				})
			);
		};

		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() =>
							// check if the file is image
							req[0].type.startsWith('image/'),
						// If the file is image. We need to convert it to pdf
						convertImageToBase64(req[0]),
						convertUtil.simpleConvertToBase64(x[0]).pipe(take(1))
					)
				),
				map((content: any) => ({
					Document: `${((content as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`,
					FileName: convertFilename(req[0]),
					DocumentName: convertFilename(req[0]),
					DocumentType: this.documentType,
					Type: this.adviceProcessCode,
					// ReferenceId: this.customerId,
					CustomerId: this.customerId,
				})),
				tap((doc) => {
					this.documentToUpload = doc;
					this.bsModalRefUpload?.hide();
				}),
				take(1)
			);

		const initialState = {
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Add New Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
			customUpload: upload,
		};
		this.bsModalRefUpload = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	linkDocument() {
		const linkDoc = () => {
			const stop$ = new Subject<void>();
			const initialSelectedTab = this.defaultLinkDocumentTab?.toLowerCase();

			const initState = {
				initialSelectedTab,
				selectedDetail: 'Link Document',
				document: this.clientDocuments,
				maxFileSize: 10000,
				currentFileSize: 0,
				allowedFileExtensions: ['pdf', 'jpg', 'jpeg', 'png','csv', 'doc','docx'],
			};
			this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
			});
			let fileName = '';
			let documentId = '';
			this.linkModalRef.content.getSelectedDocumentValue$
				.pipe(
					filter((x) => !!x),
					mergeMap((x: any) => {
						documentId = x.id;
						fileName = x.fileName;
						return this.crtDocService.getDocumentLink(x.documentLink, {
							responseType: 'blob',
						});
					}),
					mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
					map((file) => {
						const filStr = `${((file as string) ?? '')?.replace(
							/^data:(.*,)?/,
							''
						)}`;
						const size = fileUtil.getFileSizeKb(filStr);
						const totalSize = +size;

						if (totalSize > 10000) {
							this.loggerService.Warning(
								{},
								logMessage.shared.fileLinkSize.single.error
							);
							stop$.next();
						}
						return file;
					}),
					tap((x) => {
						const fileStr = `${((x as string) ?? '')?.replace(
							/^data:(.*,)?/,
							''
						)}`;
						this.documentToUpload = {
							// @ts-ignore-next
							DocumentId: +documentId,
							FileName: fileName,
							GenerateContentCallback$: undefined,
							Content: fileStr,
							PdfOptions: {
								orientation: '',
								format: '',
							},
							FileSize: +fileUtil.getFileSizeKb(fileStr),
							Type: 'linked',
						};
					})
				)
				.subscribe();
		};

		of(true)
			.pipe(
				mergeMap(() => {
					if (this.clientDocuments) {
						return of(this.clientDocuments);
					}
					this.clientDocumentIsLoading = true;
					return this.clientDocumentLoader$().pipe(take(1));
				}),
				tap((clientDocuments) => {
					this.clientDocuments = clone(clientDocuments);
					linkDoc();
				}),
				finalize(() => (this.clientDocumentIsLoading = false))
			)
			.subscribe();
	}

	checkDropdownValue(item){
		if(item===ClaimsDocumentField.Others){
			this.isOthersDocumentSelected = true;
			this.documentNameForm.setValue('');
			setTimeout(()=>{
				const inputElement = document.getElementById('documentName') as HTMLInputElement;
				if(inputElement){
						inputElement.focus();
				}
			},10)

		}
	}

	checkInputValue(){
		if(this.documentNameForm.value==='')
			this.isOthersDocumentSelected = false;
	}
}
