import {
  Directive,
  ViewContainerRef,
  Input,
  OnInit,
  ComponentFactoryResolver,
  Type,
  AfterViewInit,
  ElementRef,
  Renderer2
} from '@angular/core';
import { NotFoundComponent } from '../../../../shared/not-found/not-found.component';
import { Widget } from '../state/widget.model';
import { codeComponentPair } from '../code-component-pair';

/**
 * Directive to host the widget component
 */
@Directive({
  selector: '[appWidgetHost]'
})
export class WidgetHostDirective implements OnInit, AfterViewInit {
  /**
   * widget component to show.
   */
  @Input() widget: Widget;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private el: ElementRef,
    public viewContainerRef: ViewContainerRef,
    public render: Renderer2
  ) {}

  ngOnInit() {
    const widgetFactory: Type<any> = codeComponentPair[this.widget.code] || NotFoundComponent;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(widgetFactory);
    this.viewContainerRef.clear();

    this.viewContainerRef.createComponent(componentFactory);

    this.el.nativeElement.previousElementSibling.classList.add(
      'item',
      'widget-item',
      'd-block',
      'shadow-sm',
      'animated',
      'fadeIn'
    );
    this.el.nativeElement.previousElementSibling.setAttribute('id', `${this.widget.code}`);
    this.el.nativeElement.previousElementSibling.setAttribute('data-grid-code', `${this.widget.code}`);
    this.el.nativeElement.previousElementSibling.setAttribute(
      'data-grid-width',
      `${this.widget.sizeX ? this.widget.sizeX : codeComponentPair[this.widget.code].sizeX}`
    );
    this.el.nativeElement.previousElementSibling.setAttribute(
      'data-grid-height',
      `${this.widget.sizeY ? this.widget.sizeY : codeComponentPair[this.widget.code].sizeY}`
    );
    this.el.nativeElement.previousElementSibling.setAttribute(
      'data-grid-min-width',
      `${codeComponentPair[this.widget.code].minSizeX}`
    );
    this.el.nativeElement.previousElementSibling.setAttribute(
      'data-grid-min-height',
      `${codeComponentPair[this.widget.code].minSizeY}`
    );
    this.el.nativeElement.previousElementSibling.setAttribute(
      'data-grid-max-width',
      `${codeComponentPair[this.widget.code].maxSizeX || ''}`
    );
    this.el.nativeElement.previousElementSibling.setAttribute(
      'data-grid-max-height',
      `${codeComponentPair[this.widget.code].maxSizeY || ''}`
    );
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.previousElementSibling && this.el.nativeElement.previousElementSibling.children[0]) {
      this.el.nativeElement.previousElementSibling.children[0].classList.add('item-content');
    }
  }
}
