<button
	id="detachCloseIconButton"
	class="tap-btn tap-btn-outline modal-btn-close"
	(click)="detachCloseIcon ? close() : decline()"
>
	<span class="material-icons"> close </span>
</button>
<div class="modal-header">
	<h1 class="modal-title">
		{{ header }}
	</h1>
</div>
<div class="modal-body mb-4">
	<p>{{ message }}</p>
	<u class="existing-file-header d-block mt-2"
		>Existing {{ (emails?.length > 1||checkDupLength()>1) ? "Files" : "File" }}</u
	>
	<div class="email-duplicates-div" *ngIf="duplicates" [innerHTML]="duplicates"></div>
	<ul class="email-duplicates m-0 p-0 pl-3" *ngIf="emails">
		<li *ngFor="let e of emails">
			<span class="fullName">{{ e?.fullName || "" }}</span>
			<span class="contactStatus ml-1">({{ e?.contactStatus || "" }})</span>:
			<span class="dOB">{{ e?.dateOfBirth | momentDatetime }}</span> -
			<a
				[href]="e?.customerURL"
				target="_blank"
				class="customerURL"
				*ngIf="!!e?.customerURL"
				>Link</a
			>
		</li>
	</ul>
</div>

<div class="modal-footer">
	<button
		id="confirmBtn"
		type="button"
		class="tap-btn tap-text-h4 tap-btn--default mx-2"
		(click)="continue()"
	>
		{{ confirmTxt }}
	</button>
	<button
		id="cancelBtn"
		type="button"
		class="tap-btn tap-text-h4 tap-btn--default"
		(click)="decline()"
	>
		{{ cancelTxt }}
	</button>
</div>
