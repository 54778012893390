import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-import-summary',
  templateUrl: './import-summary.component.html',
  styleUrls: ['./import-summary.component.scss']
})
export class ImportSummaryComponent implements OnInit {
  @Input() count: number;
  @Input() rowCount: number;
  @Input() successCount: number;
  @Input() failCount: number;
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() strictError: string;
  @Input() summaryLink: string;
  @Input() showInitSummary: string;

  @Output() importNewEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  importNew() {
    this.importNewEvent.emit();
  }

}
