import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownValue } from '../../../../../modules/dropdown/model/dropdownValue';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { HouseInsuranceState } from 'src/app/shared/models/client-review-template/fg-insurance/house-insurance.model';
import { BusinessService } from '@core/business/business.service';
@Component({
	selector: 'app-house-modal',
	templateUrl: './fg-insurance-modal.component.html',
	styleUrls: ['./fg-insurance-modal.component.scss'],
})
export class FGInsuranceModalComponent implements ConfirmModalModel, OnInit {
	savefn: (model: any) => Observable<any>;
	confirm$: Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	dropdownCodes: DropdownValue[];
	viewMode: boolean;
	public cRTId: number;
	public cRTInfo: any;
	public isAdd: boolean;
	public fgi$: Observable<ViewDisplayValue[]>;
	public APCRTCLC$: Observable<ViewDisplayValue[]>;
	public APCRTVP$: Observable<ViewDisplayValue[]>;
	public APCRTKPY$: Observable<ViewDisplayValue[]>;
	public APCRTSI$: Observable<ViewDisplayValue[]>;
	public APCRTYN$: Observable<ViewDisplayValue[]>;
	public APCRTYNNA$: Observable<ViewDisplayValue[]>;

	isLoading: boolean;

	loatv2Enabled$ = this.businessService.loatv2Enabled$;

	constructor(
		public bsModalRef: BsModalRef,
		private businessService: BusinessService
	) {
		this.setupProps();
		this.setupSubs();
	}

	ngOnInit() {}

	setupProps() {
		this.header = 'Confirm';
		this.message = 'Confirm?';
	}
	setupSubs() {}

	continue() {
		this.confirm$.subscribe();
		this.bsModalRef.hide();
	}
	decline() {
		if (!!this.decline$) {
			this.decline$.subscribe();
		}
		this.bsModalRef.hide();
	}

	cancelChange(): void {
		this.bsModalRef.hide();
	}

	save(model: HouseInsuranceState) {
		this.isLoading = true;
		this.savefn(model).subscribe(() => {
			this.bsModalRef.hide();
			setTimeout(() => (this.isLoading = false), 500);
		});
	}
}

export interface ConfirmModalModel {
	confirm$: Observable<any>;
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
