import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';
import { Query } from '@datorama/akita';
import * as R from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { staticConf } from '../../core/config/static-config.service';
import { BusinessConfigState, BusinessConfigStore } from './business-config.store';
import { DerivedThemeConfig, ThemeConfig } from './theme-config.model';

const codeNamePair: [string, string][] = [
	[staticConf.lrServiceCode, staticConf.lrServiceHeader],
	[staticConf.mortgageServiceCode, staticConf.mortgageServiceHeader],
	[staticConf.fgServiceCode, staticConf.fgServiceHeader],
	[staticConf.kiwisaverServiceCode, staticConf.kiwisaverServiceHeader],
];

@Injectable()
export class BusinessConfigQuery extends Query<BusinessConfigState> {
	/** Constant value. move to static conf later. */
	private readonly lightenValue = 20;
	/** get config property of the store */
	public readonly businessConfig$ = this.select(({ config }) => config);
	/** pluck config business name property to get business name */
	public readonly businessName$ = this.businessConfig$.pipe(map((b) => (b ? b.BusinessName : '')));
	/** pluck config business code property to get business name */
	public readonly businessCode$ = this.businessConfig$.pipe(map((b) => (b ? b.BusinessCode : '')));
	/** pluck config services property to get services available to business */
	public readonly businessServices$ = this.businessConfig$.pipe(map((b) => (b ? b.Services : [])));

	/** pluck config services property to get services available to business */
	public readonly businessOATS$ = this.businessConfig$.pipe(map((b) => (b ? b.OAT : [])));
	/** URL to business logo. usually from azure blob */
	public readonly businessLogoUrl$ = this.businessConfig$.pipe(map((b) => (b ? b.Logo : '')));
	/** get a list of services the business offers (LR, M, etc...) */
	public readonly businessCodeHeaderPair$ = this.businessServices$.pipe(
		map(
			R.map((c) => ({
				code: c,
				name: R.nth(
					1,
					codeNamePair?.find((y) => y[0] === c),
				),
			})),
		),
	);

	public readonly fgUpdateV1Enabled$ = this.businessConfig$.pipe(map((x) => x?.FGUpdateV1 ?? false));

	public readonly specialFeatures$ = this.businessConfig$.pipe(map((x) => x?.SpecialFeature));

	public readonly moatv2Enabled$ = this.businessConfig$.pipe(map((config) => config.MOAT2Quickwins));

	public readonly loatv2_3Enabled$ = this.businessConfig$.pipe(map((config) => config?.LOATQuickWinsV2_3 ?? false));

	public readonly kloatFeature$ = this.businessConfig$.pipe(map((config) => config?.KLOAT ?? false));

	/** Indicator whether the business has L&R */
	public readonly hasLR$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.lrServiceCode)));
	/** Indicator whether the business has Mortgage */
	public readonly hasM$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.mortgageServiceCode)));
	/** Indicator whether the business has FG */
	public readonly hasFG$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.fgServiceCode)));
	/** Indicator whether the business has Kiwisaver */
	public readonly hasK$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.kiwisaverServiceCode)));
	/** Indicator whether the business has Investment */
	public readonly hasI$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.investmentServiceCode)));
	/** Indicator whether the business has AP */
	public readonly hasAP$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.adviceProcessCode)));
	/** Indicator whether the business has AP */
	public readonly hasCustomerPortalDocument$ = this.businessServices$.pipe(
		map((x) => x?.includes(staticConf.customerPortalDocuments)),
	);
	/** Indicator whether the business has AP */
	public readonly hasCRT$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.clientReviewTemplateCode)));
	/** Indicator whether the business has MOAT */
	public readonly hasMOAT$ = this.businessServices$.pipe(
		map((x) => x?.includes(staticConf.mortgageOnlineAdviceToolCode)),
	);
	/** Indicator whether the business has KOAT */
	public readonly hasKOAT$ = this.businessServices$.pipe(
		map((x) => x?.includes(staticConf.kiwisaverOnlineAdviceToolCode)),
	);
	/** Indicator whether the business has Customer Portal MOAT */
	public readonly hasCPMOAT$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.customerPortalMOATCode)));
	/** Indicator whether the business has Customer Portal Documents MOAT */
	public readonly hasCPMOATD$ = this.businessServices$.pipe(
		map((x) => x?.includes(staticConf.customerPortalDocuments)),
	);
	/** Indicator whether the business has Customer Portal LOAT */
	public readonly hasLOATV2$ = this.businessOATS$.pipe(map((x) => x?.includes(staticConf.customerPortalLOATCode)));
	public readonly hasCAR$ = this.businessServices$.pipe(map((x) => x?.includes(staticConf.clientAlterationRequest)));
	/** Extract theme object from Business Config object */
	public readonly themeConfig$: Observable<ThemeConfig & DerivedThemeConfig> = this.select(({ config }) =>
		config
			? {
					primarycolor: config.PrimaryColor,
					secondarycolor: config.SecondaryColor,
					tertiarycolor: config.TertiaryColor,
					h1color: config.H1Color,
					h2color: config.H2Color,
					h3color: config.H3Color,
					h4color: config.H4Color,
					h5color: config.H5Color,
					h6color: config.H6Color,
					pcolor: config.PColor,
					fieldBackgroundColor: config.FieldBackgroundColor,
					fieldTextColor: config.FieldTextColor,
					widgetcolor1: config.WidgetColor1,
					widgetcolor2: config.WidgetColor2,
					lighterprimarycolor: new TinyColor(config.PrimaryColor).lighten(this.lightenValue)?.toHexString(),
					lightersecondarycolor: new TinyColor(config.SecondaryColor).lighten(this.lightenValue)?.toHexString(),
					soaHeadingColor: config.SOAHeadingColor,
				}
			: null,
	);

	public readonly widgets$ = this.select((x) => (x && x.config && x.config.Widgets ? x.config.Widgets : []));

	public readonly businessOATLogo$ = this.select((x) => x?.config?.BusinessOATLogo ?? '');

	public readonly businessLOATFactFinderCover$ = this.select((x) => x?.config?.BusinessLOATFactFindCover ?? '');
	public readonly businessLOATSOAAestheticCover$ = this.select((x) => x?.config?.BusinessLOATSOAAestheticCover ?? '');
	public readonly businessMOATFactFindAestheticCover$ = this.select(
		(x) => x?.config?.BusinessMOATFactFindAestheticCover ?? '',
	);
	public readonly businessMOATROAAestheticCover$ = this.select((x) => x?.config?.BusinessMOATROAAestheticCover ?? '');
	public readonly businessKOATAdviceSummaryAestheticCover$ = this.select(
		(x) => x?.config?.BusinessKOATAdviceSummaryAestheticCover ?? '',
	);

	public readonly businessLOATGenericCover$ = this.select((x) => x?.config?.LOATGenericCover ?? '');
	public readonly businessMOATGenericCover$ = this.select((x) => x?.config?.MOATGenericCover ?? '');
	public readonly businessKOATGenericCover$ = this.select((x) => x?.config?.KOATGenericCover ?? '');
	public readonly businessCARGenericCover$ = this.select((x) => x?.config?.CARGenericCover ?? '');

	public readonly businessFapName$ = this.select((x) => x?.config?.FAP ?? '');

	/* Feature Toggle */
	// Adviser Rework
	public readonly adviserReworkFeature$ = this.select((x) => !!x?.config?.AdviserRework);

	// Email Client Template
	public readonly emailClientTemplateFeature$ = this.select((x) => !!x?.config?.EmailClientTemplate);

	// Original Lead Gen
	public readonly conversionFeature$ = this.select((x) => !!x?.config?.Conversion);

	/* Feature Bulk Transfer */
	public readonly bulkTransferFeature$ = (filter: string) =>
		this.select((x) => x?.config?.BulkTransfer?.find((f) => f === filter));

	// Menu Rework
	public readonly menuReworkFeature$ = this.select((x) => !!x?.config?.MenuRework);

	// Search Rework
	public readonly searchReworkFeature$ = this.select((x) => !!x?.config?.SearchRework);

	// AI PDF Quote
	public readonly aiQuoteFeature$ = this.select((x) => !!x?.config?.AIQuote);

	//Loat v2.3 Quick Wins
	public readonly loatV2_3QuickWins$ = this.select((x) => !!x?.config?.LOATQuickWinsV2_3);

	// Claims
	public readonly claimsFeature$ = this.select((x) => !!x?.config?.Claims);

	// FGUpdatev1 (NZFA)
	public readonly fGUpdateV1Feature$ = this.select((x) => !!x?.config?.FGUpdateV1);

	constructor(protected store: BusinessConfigStore) {
		super(store);
	}

	// TODO: Hotfix only, for improvement
	/**
	 * Get first service Code available to business.
	 * Used for default service tab to show for example.
	 */
	public getFirstServiceCode(): string {
		const allowedServices = ['LR', 'M', 'FG', 'K', 'I'];
		const snapShot = this.getValue();
		const services = snapShot.config ? snapShot.config.Services : [];

		if (services.length < 1) {
			return null;
		}

		const filteredServices = services?.filter((service) => allowedServices.includes(service));

		const customSort = (a, b) => {
			const indexA = allowedServices.indexOf(a);
			const indexB = allowedServices.indexOf(b);

			return indexA - indexB;
		};

		const sortedServices = filteredServices?.sort(customSort);

		return sortedServices[0];
	}
}
