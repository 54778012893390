import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, iif, of, combineLatest } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import {
	DocumentModel,
	DocumentModelState,
	DocumentTypes,
} from '../../../../../../shared/models/documents/document.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { objectUtil } from '../../../../../../util/util';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { CrtMortgageStore } from '../../state/crt-mortgage.store';
import * as R from 'ramda';
import {
	DisclosureDocument,
	DisclosureDocumentState,
	DisclosureState,
	DocumentUploadState,
} from './disclosure.model';
import { EmailTypes } from 'src/app/shared/models/emails/mortgage/email.model';
import { DisclosureQuery } from './disclosure.query';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { SidebarStatus } from '../../../_shared/models/sidebar.model';
import {
	AdviceProcessPageCodes,
	MortgageAdviceProcessPageIds,
} from '../../../../../../shared/models/advice-process/advice-process.model';

export const disclosurePdfOptions = {
	FileName: 'DISCLOSURE-DOCUMENT',
	DPI: '120',
};
@Injectable({
	providedIn: 'root',
})
export class DisclosureService extends CrtMortgageService {
	disclosureDocument$ = this.dQuery.disclosureDocument$;
	disclosureSettings$ = this.dQuery.disclosureSettings$;

	constructor(
		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected dQuery: DisclosureQuery
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	getDdPdfOptions() {
		return disclosurePdfOptions;
	}

	getDisclosureSettings(forceRefetch: boolean = false) {
		const adviserId = +this.query.getValue().adviceProcess?.adviser || 0;
		const endpoint = `staff/${adviserId}/settings/DD`;

		return forceRefetch || R.isNil(this.query.getValue().disclosureSettings)
			? this.api.get<DisclosureDocumentState>(endpoint).pipe(
					tap((data) =>
						applyTransaction(() => {
							const state = R.complement(R.either(R.isNil, R.isEmpty))(data)
								? objectUtil.mapPascalCaseToCamelCase(data)
								: {};
							this.store.setDisclosureSettings(state);
						})
					),
					catchError(() => of(undefined))
			  )
			: of(this.query.getValue().disclosureSettings);
	}

	getDisclosureDocument(adviceProcessId: number) {
		const endpoint = `crt/${adviceProcessId}/DD`;
		return this.api.get<DisclosureState[]>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = R.complement(R.either(R.isNil, R.isEmpty))(x)
						? {
								...objectUtil.mapPascalCaseToCamelCase(x[0]),
								clickedNext: true,
						  }
						: {
								cRTId: null,
								adviceProcessId: this.query.getValue().adviceProcessId,
								document: { referenceId: null, value: null },
								clickedNext: false,
						  };
					this.store.setDisclosureDocument(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getDisclosureDocumentFile(id: number) {
		return this.api
			.get<DocumentModelState>(`documents/${id}`)
			.pipe(catchError(() => EMPTY));
	}

	getDocumentFromURL(url: string) {
		const dateTime = new Date().getTime();
		url = `${url}?${dateTime}`;
		return this.api.getExternalResource(url, { responseType: 'text' }).pipe(
			catchError(() => EMPTY),
			take(1)
		);
	}

	getInfoByCRT(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<any>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => of([]))
		);
	}

	sendEmail(data) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);
		const adviceProcessId = this.query.getValue().adviceProcessId;
		let dd: DisclosureDocument;
		return this.api.get<DisclosureDocument[]>(`crt/${adviceProcessId}/DD`).pipe(
			map((x) => {
				const res = x?.map(
					(y) => objectUtil.mapPascalCaseToCamelCase(y) as DisclosureDocument
				);
				dd = res.length > 0 ? res[0] : null;
				return dd;
			}),
			// Update or Add Document for Disclosure Document
			mergeMap((x) =>
				iif(
					() => !!x && !!x?.documentID,
					// Update the current document
					this.api.put(`documents/${x?.documentID}/document-link`, {
						Document: d.Document,
						DocumentID: x?.documentID,
					}),
					// Upload new document
					this.api.post(`documents`, {
						ReferenceId: adviceProcessId,
						Document: d.Document,
						FileName: 'Disclosure Document.pdf',
						Type: DocumentTypes.Disclosure,
					})
				)
			),
			// Create New or update Disclosure document
			mergeMap((x) =>
				iif(
					() => !!dd,
					// Update the current Disclosure Document
					this.api.put(`crt/${dd?.cRTId}`, {
						AdviceProcessId: adviceProcessId,
						SectionCode: AdviceProcessSectionCodes.Disclosure,
						DocumentID: dd?.documentID,
						ParentCRTId: 0,
						CRTId: dd?.cRTId,
					}),
					// Create new Disclosure Document
					this.api.post(`crt`, {
						AdviceProcessId: adviceProcessId,
						SectionCode: AdviceProcessSectionCodes.Disclosure,
						DocumentID: x,
						ParentCRTId: 0,
					})
				)
			),
			// Send the email
			mergeMap((x) =>
				this.api.post(`emails/single`, {
					EmailDestination: d.EmailDestination,
					Type: EmailTypes.DISCLOSURE,
					ReferenceId: typeof x === 'number' ? x : dd.cRTId,
				})
			),
			catchError(() => EMPTY)
		);
	}

	newFileUploadDD(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req).pipe(
			catchError(() => EMPTY),
			take(1)
		);
	}

	updateFileUploadDD(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api
			.put<DocumentUploadState>(endpoint, body)
			.pipe(catchError(() => EMPTY));
	}

	getDocumentDdByRefId(id: number) {
		return this.api
			.get<DocumentModel[]>(`documents/reference/${id}/CRTDD`)
			.pipe(
				catchError(() => EMPTY),
				take(1)
			);
	}

	deleteFileDD(id: number) {
		const endpoint = `documents/${id}`;
		return this.api.delete<any>(endpoint).pipe(catchError(() => EMPTY));
	}

	uploadDocument(req) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req).pipe(catchError(() => EMPTY));
	}

	addNewDisclosure(data) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDisclosureDocument({ ...data, cRTId: +x });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateDisclosure(data) {
		const endpoint = `crt/${data.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDisclosureDocument(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	setTabColor() {
		return combineLatest([
			this.query.adviceProcess$,
			this.query.mortApPageStarted$,
			this.query.mortApPageCompleted$,
		]).pipe(
			tap(([ap, pageStarted, pageCompleted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (pageStarted?.includes(AdviceProcessPageCodes.Disclosure)) {
					status = SidebarStatus.Incomplete;
					warning = 'Document must be saved to file';
				}

				if (
					!!ap?.documents &&
					ap?.documents?.length > 0 &&
					ap?.documents?.some((x) => x?.field === 'Disclosure' && !!x?.value)
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.Disclosure,
					false,
					status,
					warning
				);
			})
		);
	}
}
