import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RouteService, CommandRoute } from '../../../../../core/config/route.service';
import { Observable, Observer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TransferClientModalComponent } from '@shared/modal/transfer-client-modal/transfer-client-modal.component';
import { ClientProfileService } from '../../states/client-profile.service';
import { ClientProfileQuery } from '../../states/client-profile.query';
import { CustomerTypes } from '@shared/models/_general/client.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { AdviceProcessCode, AdviceProcessNames } from '@shared/models/advice-process/advice-process.model';

@Component({
  selector: 'app-form-linked-contact',
  templateUrl: './form-linked-contact.component.html',
  styleUrls: ['./form-linked-contact.component.scss']
})
export class FormLinkedContactComponent implements OnInit, OnChanges {
  @Input() formID: any;
  @Input() linkedContact: LinkedContactState;
	@Input() relationship: ViewDisplayValue[];
  @Input() isLoading: any;
	@Input() preferredContact: ViewDisplayValue[];

  clientRoute: CommandRoute = [];

  public bsModalRef: BsModalRef;

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private routeService: RouteService,
    private modalService: BsModalService,
    private clientProfileService: ClientProfileService,
    private clientQuery: ClientProfileQuery,
  ) {
		this.buildForm();
  }

  ngOnInit() {
    this.prepData();
  }

  ngOnChanges(changes: SimpleChanges): void {
		this.prepData();
	}

	buildForm() {
		this.form = this.fb.group({
      primaryContact: [{value: null, disabled: true}],
			work: [{value: null, disabled: true}],
			email: [{value: null, disabled: true}],
			physicalAddress: [{value: null, disabled: true}],
			contactMethod: [{value: null, disabled: true}],
			relationship: [{value: null, disabled: true}],
      isAccessible: [{value: null, disabled: true}]
    });
	}

	prepData() {
    const data = {
      ...this.linkedContact,
      primaryContact: this.linkedContact.name,
      work: this.linkedContact.mobile
    }
    this.form.reset(data);
    this.clientRoute = this.routeService.customerView(+this.linkedContact.primaryCustomerId);
    setTimeout(() => {
			this.form.disable();
    }, 10);
	}

  cancelContact() {
		const id =
			this.linkedContact?.linkedFromPrimaryCustomer
				? this.linkedContact?.relatedCustomerId
				: this.linkedContact?.customerId;

		const remove = new Observable((obs: Observer<any>) => {
			this.cancelLinkedContact(id);
			obs.complete();
		});

		const getMessage = `Are you sure you want to remove '${this.linkedContact.name}'?`;

		const initState = {
			header: 'Remove linked contact',
			message: getMessage,
			transfer$: remove,
			canTransfer: true,
			confirmButton: 'Remove'
		};

		this.modalService.show(TransferClientModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
  }

	getAdviceProcessName(code) {
		const apCode = Object.keys(AdviceProcessCode)[Object.values(AdviceProcessCode).indexOf(code)];
		return AdviceProcessNames[apCode];
	}

  cancelLinkedContact(relatedCustomerId: number) {
    this.isLoading = true;
    const linkedContacts = this.clientQuery.getValue().linkedContacts;
    const contact = linkedContacts.find((c) => {
			const id =
				c?.linkedFromPrimaryCustomer
					? c?.relatedCustomerId
					: c?.customerId;
			return id === relatedCustomerId
		});

    if (contact) {
      const data = {
        id: contact.id,
        customerId: this.linkedContact.customerId,
        relatedCustomerId,
        type: CustomerTypes.LinkedContact,
        remarks: contact.relationship,
        isActive: false
      } as LinkedContactState;

		this.clientProfileService.updateLinkedContact(data)
			.pipe(take(1))
			.subscribe(
				() => {
					this.isLoading = false;
				},
				(err) => {
					this.isLoading = false;

					const errors = err.map((error) => {
						return ` - ${error.MetaKey} of ${error.Value}  <br />`
					})

					const message = `'${this.linkedContact.name}' is a <br /> ${errors
						?.toString()
						?.replace(/,/g, ' ')} Kindly remove ${
							err && err.length > 1 ? 'these' : 'this'
					} before unlinking.`;

					const initState = {
						header: 'Remove linked contact',
						message: message,
						confirmation: true,
					};

					this.modalService.show(TransferClientModalComponent, {
						class: 'modal-dialog-centered',
						initialState: initState,
						ignoreBackdropClick: true,
					});
				}
			);
    }
  }
}
