import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PropertySoldQuery } from './property-sold/state/property-sold.query'
import { FundingRequiredLoanQuery } from './loan-repaid/state/loan-repaid.query'
import { computeUtil } from '../../../../../_shared/calculations/funding-required';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PropertySoldComponent } from './property-sold/property-sold.component';
import { LoanRefinanceQuery } from '../loan-refinance/state/loan-refinance.query';
import { FundingRequiredLoanService } from './loan-repaid/state/loan-repaid.service';
import { ApplicationService } from '../../../state/application.service';
import { ApplicationQuery } from '../../../state/application.query';

@Component({
	selector: 'app-selling-section',
	templateUrl: './selling-section.component.html',
})
export class SellingSectionComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
	@Input() parentCRTId: number;
  @Input() isRefinance$: Observable<any>;

  properties$ = this.propertySoldQuery.properties$;
  propertiesLoading$ = this.propertySoldQuery.isLoading$;

  loans$ = this.fundingRequiredLoanQuery.loans$;
  loansLoading$ = this.fundingRequiredLoanQuery.isLoading$;

  netProceeds$ = combineLatest([
    this.properties$,
    this.loans$
  ]).pipe(
    map(([p, l]) => {
      return computeUtil.totalNetProceeds(p, l);
    })
  );

  netProceeds: number = 0;

  @ViewChild ('propertySold') propertySold: PropertySoldComponent;

  @Input() deceasedSciList$: Observable<any[]>;

  constructor(
    private propertySoldQuery: PropertySoldQuery,
    private fundingRequiredLoanQuery: FundingRequiredLoanQuery,
    private fundingRequiredLoanService: FundingRequiredLoanService,
    private loanRefinanceQuery: LoanRefinanceQuery,
    private appService: ApplicationService,
    private appQuery: ApplicationQuery,
  ) { }

  ngOnInit(): void {
    this.appQuery.isUpdatedLoans$.pipe(
			filter(loan => !!loan),
			withLatestFrom(
				this.loanRefinanceQuery.loanRefinance$
			),
			map(([, loans]) => loans),
      takeUntil(this.onDestroy$)
		).subscribe((data: any) => {
				const ids = data.filter(loan => loan.isRefinance).map(loan => loan.linkedCRTId);
				this.fundingRequiredLoanService.updateStore(ids)
				this.appService.setIsUpdatedLoans(false);
			}
		)
  }

  clear() {
    this.propertySold.clear();
  }

  ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
