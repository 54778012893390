<div class="row pt-4 pl-3">
	<div class="col-lg-6 col-12">
		<div class="d-flex align-items-center  pb-2 mb-1">
			<label for="assignTo" class="d-flex align-items-center">
				<input #assignToCheck type="checkbox" class="mr-1" [(ngModel)]="enableActivityAssignedTo" [disabled]="formGroup.disabled">
				Enable automatic activity assigned to:
			</label>
			<span class="custom-dropdown ml-3">
				<select
					[(ngModel)]="activityAssignedTo"
					class="form-control theme-crm-field-disabled"
					placeholder="- -"
					tabindex="26"
					[disabled]="formGroup.disabled || !assignToCheck.checked"
				>
					<option value=""></option>
					<option *ngFor="let c of allStaff" [value]="c.value">
						{{ c.display }}
					</option>
				</select>
			</span>
		</div>

		<p class="bottom pb-2 mb-1">Hi, is this Client Name?</p>
		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>
		<p class="pt-2 mt-1">
			Hi Client Name, it's User here calling on behalf of Adviser Name about you
			insurance with Provider, how are you today? That's good.
		</p>
		<p class="pt-2">
			This is just a quick call to welcome you as a client, and make sure that
			we have your correct details on file before issuing your policy, okay?
			Great.
		</p>
		<p class="pt-2 mb-0 bottom pb-2 mb-1">
			Just before we start can you please confirm your full name, postal address
			and email?
		</p>
		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>
		<p class="my-1 py-2">Client Name</p>
		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>
		<p class="my-1 py-2">Address</p>
		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>
		<p class="my-1 py-2">Email Address</p>
		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>
		<div
			class="questions-container pt-2 my-1 pb-2 my-1"
			[class.mb-5]="formGroup.enabled"
			[class.my-4]="formGroup.enabled"
			[class.my-2]="formGroup.disabled"
		>
			<form [formGroup]="formGroup">
				<div class="row mx-0" formArrayName="questions">
					<div
						cdkDropList
						#listOne="cdkDropList"
						(cdkDropListDropped)="drop($event)"
						[cdkDropListData]="questions.controls"
						[cdkDropListSortPredicate]="cdkDropListSortPredicate"
						*ngIf="questions.controls.length"
					>
						<div
							*ngFor="
								let question of questions.controls;
								let index = index;
								let last = last
							"
							[formGroupName]="index"
							cdkDrag
							cdkDragPreviewContainer="parent"
							cdkDragPreviewClass="drag-preview"
							[cdkDragData]="question"
							[cdkDragDisabled]="formGroup.disabled"
						>
							<div
								class="row m-0 pt-1 drag-wrapper"
								[ngClass]="{ 'pb-3': !last }"
							>
								<div
									*ngIf="formGroup.enabled"
									class="col-1 d-flex align-items-start px-0 side-control-container"
								>
									<span *ngIf="formGroup.enabled">
										<input type="checkbox" formControlName="status" />
									</span>
									<span *ngIf="formGroup.enabled">
										<i
											class="material-icons md-18 tap-text-color-h6 drag-handle"
											[ngClass]="{ disabled: formGroup.disabled }"
											cdkDragHandle
											[cdkDragHandleDisabled]="formGroup.disabled"
											>drag_indicator</i
										>
									</span>
								</div>
								<div
									[class.col-11]="formGroup.enabled"
									[class.col-12]="formGroup.disabled"
									[class.px-0]="formGroup.disabled"
								>
									<ng-container
										*ngIf="question.value.isDefault; else readOnlyTemplate"
									>
										<p
											class="m-0 p-0"
											[innerHTML]="question.value.question"
										></p>
									</ng-container>
									<ng-template #readOnlyTemplate>
										<div class="d-flex w-100">
											<input
												*ngIf="formGroup.enabled"
												type="text"
												formControlName="question"
												class="form-control w-100"
											/>
											<p
												*ngIf="formGroup.disabled"
												class="m-0 p-0"
												[innerHTML]="question.value.question"
											></p>
											<button
												*ngIf="formGroup.enabled"
												type="button"
												class="icon-btn ml-2"
												(click)="delete(question.value, index)"
											>
												<i class="material-icons md-20 tap-text-color-h6"
													>delete</i
												>
											</button>
										</div>
									</ng-template>
								</div>
							</div>
						</div>
						<div *ngIf="formGroup.enabled" class="mt-2">
							<button class="btn btn-light border" (click)="addQuestion()">
								+ Add a question
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>

		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>

		<p class="pt-2 mt-1 mb-1 pb-2">
			That all seems great. Your policy documents should be sent out by your
			provider(s) shortly, so you can expect those in the mail over the next
			week or so.
			<br />
			Commencement Details
		</p>

		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>
		<ng-container *ngIf="formGroup.disabled; else editFinalSection">
			<p class="paragraph-br pt-2 mb-1 mt-1 pb-2" [innerHTML]="finalSection | nextline"></p>

		</ng-container>
		<ng-template #editFinalSection>
			<textarea 
				class="form-control my-3" 
				rows="4"
				[(ngModel)]="finalSection"	
			>
			</textarea>
		</ng-template>
		<div class="col-12 p-0 m-0"><hr class="p-0 m-0" /></div>
		<p class="pt-2 mt-1">Call Outcome</p>
	</div>
</div>
<div class="row pb-4 pt-2 pl-3 m-0">
	<div class="col-12 col-lg-1 p-0">
		<div class="row justify-content-start m-0">
			<button
				*ngIf="formGroup.disabled"
				class="btn-edit tap-btn tap-btn--primary mr-3"
				(click)="formGroup.enable()"
				[disabled]="isSaving"
			>
				Edit
			</button>
			<button
				*ngIf="formGroup.enabled"
				class="btn-save tap-btn tap-btn--primary mr-3"
				(click)="save()"
				[disabled]="isSaving || modelIsEmpty(finalSection) || (modelIsEmpty(activityAssignedTo) && enableActivityAssignedTo)"
			>
				Save
			</button>
		</div>
	</div>
</div>
