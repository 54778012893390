import { Note } from './note.model'
import { EmailBodyTypes } from '../../../../../../../../shared/models/emails/mortgage/email.model';
export class NoteMapper{
  public static mapToView(data: Note) {
    return {
      ...data,
    }
  }

  public static mapToUpsert(data: Note) {
    return {
      cRTId: data.cRTId ? data.cRTId : null,
			adviceProcessId: data?.adviceProcessId,
			sectionCode: data.sectionCode,
			parentCRTId: data.parentCRTId,
			purposeId: data.purposeId,
			clientBackgroundCharacterId: data.clientBackgroundCharacterId,
			recommendationId: data.recommendationId,
			otherInformationId: data.otherInformationId
    }
  }

	public static mapDocument(document, docId, staffId, key, file) {
    if (docId === 0 || !docId) {
      return {
        document,
        referenceId: +staffId,
        fileName: file + '.txt',
				type: EmailBodyTypes.NOTES,
				id: key
      };
    }
    return {
      document,
      documentId: +docId,
    };
  }
}
