import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { EntityState, EntityStore, QueryEntity } from '@datorama/akita';
import { clone } from 'ramda';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, take } from 'rxjs/operators';

export interface AppMatTableDataSourceState<T> extends EntityState<T, number> {}

export type AppMatTableDataSourceLoadFn<T> = (filter: any) => Observable<{
	TotalRecordCount: number;
	Data: T[];
}>;

export interface CRMMatTableDataSourceItemQuery<T> extends EntityState<T, number> {
	rangeIDs: { [key: string]: number[] };
	setRangeData: (data: T[], startId: number, endId: number) => void;
}

export interface CRMMatTableDataSourceOptions<T> {
	store: EntityStore<CRMMatTableDataSourceItemQuery<T>>;
	query: QueryEntity<CRMMatTableDataSourceItemQuery<T>>;
	pageSize: number;
	loadFn$: AppMatTableDataSourceLoadFn<T>;
	backgroundLoadFn$?: AppMatTableDataSourceLoadFn<T>;
	filter$: Observable<any>;
}

export class CRMMatTableDataSource<T = any> extends MatTableDataSource<T> {
	private subs = new Subscription();

	pageIndex$ = new BehaviorSubject<number>(0);

	pageSize$ = new BehaviorSubject<number | null>(null);

	recordCount$ = new BehaviorSubject<number>(0);

	isLoading$ = new BehaviorSubject<boolean>(false);

	dataLoaded$ = new BehaviorSubject<T[]>([]);

	constructor(private options: CRMMatTableDataSourceOptions<T>) {
		super([]);

		this.pageSize$.next(options.pageSize);
		const indexSub = this.pageIndex$
			.pipe(
				debounceTime(100),
				mergeMap((pageIndex) => {
					return options.filter$.pipe(
						take(1),
						map((filter) => {
							filter.Paging.Index = pageIndex + 1;
							return { filter, pageIndex };
						})
					);
				}),
				switchMap(({ filter }) => {
					return this.getItemsByIndexRange(
						filter.Paging.Index,
						filter.Paging.Size
					).pipe(
						map((cachedData) => ({
							filter,
							cachedData,
						}))
					);
				}),
				switchMap(({ filter, cachedData }) => {
					if (cachedData?.length) {
						return of(cachedData);
					}
					this.isLoading$.next(true);
					return options.loadFn$(filter).pipe(
						map((result) => {
							this.recordCount$.next(result.TotalRecordCount);
							// @ts-ignore-next
							this.options.store.setRangeData(
								result.Data,
								filter.Paging.Index,
								filter.Paging.Size
							);
							return result.Data;
						})
					);
				})
			)
			.subscribe((data) => {
				this.data = clone(data);
				this.dataLoaded$.next(data);
				this.isLoading$.next(false);
			});
		this.subs.add(indexSub);

		const filterSub = options.filter$.subscribe(() => {
			// @ts-ignore-next
			this.options.store.resetRangeData();
			this.pageIndex$.next(0);
		});
		this.subs.add(filterSub);
	}

	dispose(): void {
		this.subs.unsubscribe();
	}

	refresh(): void {
		// @ts-ignore-next
		this.options.store.resetRangeData();
		this.pageIndex$.next(this.pageIndex$.getValue());
	}

	private getItemsByIndexRange(start: number, end: number): Observable<T[]> {
		// @ts-ignore-next
		return this.options.query.getDataByRange(start, end);
	}

	pageIndex(page: number): void {
		this.pageIndex$.next(page);
	}
}
