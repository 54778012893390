import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Asset } from './asset.model';

export interface AssetState extends EntityState<Asset> {}

@Injectable()
@StoreConfig({ name: 'asset', idKey: 'cRTId' })
export class AssetStore extends EntityStore<AssetState, Asset> {
	constructor() {
		super();
	}
}
