import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import {
	catchError,
	concatMap,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { ApiService } from '@core/base/api.service';
import { BusinessService } from '@core/business/business.service';
import { CustomerService } from '@core/customer/customer.service';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { CrtKiwiSaverStore } from './crt-kiwisaver.store';
import { CrtKiwiSaverQuery } from './crt-kiwisaver.query';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { ServiceAdviceProcessState } from '@shared/models/advice-process/advice-process.model';
import { objectUtil } from '@util/util';
import { EMPTY, Observable, of } from 'rxjs';
import { SecondaryClientState } from '@shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from '@shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from '@shared/models/client-profile/seondary-business/secondary-business.model';
import { SecondaryProfessionalState } from '@shared/models/client-profile/secondary-professional/secondary-professional.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { DocumentUpload } from '@shared/models/documents/document.model';
import { Sidebar, SidebarStatus } from '../../_shared/models/sidebar.model';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { util } from 'src/app/util/util';

@Injectable()
export class CrtKiwiSaverService {
	snapshot = this.query.getValue();
	sidebars$ = this.query.sidebars$;
	primaryClient$ = this.query.primaryClient$;
	primaryClient = this.snapshot.primaryClient;
	adviceProcess$ = this.query.adviceProcess$;
	pageStarted$ = this.query.pageStarted$;
	pageCompleted$ = this.query.pageCompleted$;
	isTabLoading$ = this.query.isTabLoading$;
	isCompany$ = this.query.isCompany$;

	// Dropdown values
	PCE$ = this.dropdownValueQuery.orderedChoices$('PCE');

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtKiwiSaverStore,
		protected query: CrtKiwiSaverQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected clientProfileService: ClientProfileService,
	) {}

	clearData(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	/**
	 *  REQUESTS
	 */
	getAdviceProcess(adviceProcessId): Observable<ServiceAdviceProcessState> {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			map((data) => (data ? objectUtil.mapPascalCaseToCamelCase(data) : null)),
			tap((data) =>
				applyTransaction(() => {
					this.store.setAdviceProcess(data);
					this.store.setPageCompleted(data?.pageCompleted || []);
					this.store.setPageStarted(data?.pageStarted || []);
				})
			),
			catchError(() => of(null))
		);
	}

	getPrimaryClient(primaryClientId: number) {
		return this.customerService.GetPrimaryClient(primaryClientId).pipe(
			map(objectUtil.mapPascalCaseToCamelCase),
			tap(this.setPrimaryClient),
			catchError(() => of(undefined))
		);
	}

	getSecondaryClients(customerId) {
		return this.customerService
			.GetSecondaryClientsByPrimaryClient(customerId)
			.pipe(
				map((x) => x?.filter((y) => +y.IsActive === 1)),
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryClientState[])
							: [];
						this.store.setSecondaryClients(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getSecondaryTrusts(customerId) {
		return this.customerService
			.GetSecondaryTrustByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryTrustState[])
							: [];
						this.store.setSecondaryTrusts(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getSecondaryCompanies(customerId) {
		return this.customerService
			.GetSecondaryBusinessesByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryBusinessState[])
							: [];
						this.store.setSecondaryCompanies(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getSecondaryProfessionals(customerId) {
		return this.customerService
			.GetSecondaryProfessionalsByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryProfessionalState[])
							: [];
						this.store.setSecondaryProfessionals(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getLinkedContacts(customerId) {
		return this.customerService
			.GetLinkedContactsByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as LinkedContactState[])
							: [];
						this.store.setLinkedContacts(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	updatePageStarted(data) {
		const pageStarted = [...(this.query.getValue().pageStarted || [])];

		if (pageStarted.includes(data)) {
			return EMPTY;
		} else {
			pageStarted.push(data);
			const body = objectUtil.mapCamelCaseToPascalCase({
				referenceID: +this.query.getValue().adviceProcessId,
				secondaryReferenceID: 0,
				key: 'PageStarted',
				value: JSON.stringify(pageStarted || []),
			});
			const endpoint = `adviceProcesses`;

			return this.api.patch<any>(endpoint, [body]).pipe(
				tap((x) =>
					applyTransaction(() => {
						this.store.setPageStarted(pageStarted);
					})
				),
				catchError(() => EMPTY)
			);
		}
	}

	updatePageCompleted(data) {
		const codes = data?.filter(Boolean) || [];
		const body = objectUtil.mapCamelCaseToPascalCase({
			referenceID: +this.query.getValue().adviceProcessId,
			secondaryReferenceID: 0,
			key: 'PageCompleted',
			value: JSON.stringify(codes),
		});
		const endpoint = `adviceprocesses`;

		return this.api.patch<any>(endpoint, [body]).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setPageCompleted(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateAdviceProcess(id: number, type: string) {
		const adviceProcess = this.query.getValue().adviceProcess;
		return this.updateAP(adviceProcess.adviceProcessID, id, type).pipe(take(1));
	}

	updateAP(adviceProcessId: number, documentId: number, type: string) {
		const endpoint = `adviceprocesses/${adviceProcessId}/document/${documentId}/${type}`;
		return this.api.put<string>(endpoint).pipe(
			concatMap((updateAPres) =>
				this.getAdviceProcess(adviceProcessId).pipe(map(() => updateAPres))
			),
			catchError(() => EMPTY)
		);
	}

	saveDocument(req: DocumentUpload): Observable<number> {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}

	/**
	 * States
	 */

	setAdviceProces = (data: ServiceAdviceProcessState) =>
		applyTransaction(() => {
			this.store.setAdviceProcess(data);
		});

	setAdviceProcessId(id: number) {
		applyTransaction(() => {
			this.store.setAdviceProcessId(id);
		});
	}

	setPrimaryClient = (data: PrimaryClientState) =>
		applyTransaction(() => {
			this.store.setPrimaryClient(data);
		});

	setClientId(id: number) {
		applyTransaction(() => {
			this.store.setClientId(id);
		});
	}

	setIsCompany(isCompany: boolean) {
		applyTransaction(() => {
			this.store.setIsCompany(isCompany);
		});
	}

	setPageCompleted(b: any[]) {
		applyTransaction(() => {
			this.store.setPageCompleted(b);
		});
	}

	setPageStarted(b: any[]) {
		applyTransaction(() => {
			this.store.setPageStarted(b);
		});
	}

	/**
	 *
	 * @param menuId Sidebar id
	 * @param isSubMenu Sidebar isSubMenu
	 * @param status Sidebar status
	 * @param warning warning message for tooltip
	 * 0 = not yet visited
	 * 1 = visited but not completed
	 * 2 = completed requirements
	 */
	setSideSidebarStatus(
		menuId: string,
		isSubMenu: boolean,
		status: number | SidebarStatus,
		warning?: string
	) {
		const warningMessage = warning || 'Please review required fields.';
		let sidebars = [...this.query.getValue().sidebars];

		if (isSubMenu) {
			sidebars = sidebars.map((x) =>
				x.isSubMenu
					? {
							...x,
							subMenu: x.subMenu.map((y) =>
								y.id === menuId
									? {
											...y,
											status,
											warning: warningMessage || '',
									  }
									: y
							),
					  }
					: x
			);
		} else {
			sidebars = sidebars.map((x) => {
				if (x.id === menuId) {
					return {
						...x,
						status,
						warning: warningMessage || '',
					};
				} else {
					if (x.subMenu?.find((sub) => sub.id === menuId)) {
						return {
							...x,
							subMenu: x.subMenu.map((y) =>
								y.id === menuId
									? {
											...y,
											status,
											warning: warningMessage || '',
									  }
									: y
							),
						};
					} else {
						return x;
					}
				}
			});
		}

		applyTransaction(() => this.store.update({ sidebars }));
	}

	isTabLoading(status: boolean) {
		applyTransaction(() => {
			this.store.setTabLoading(status);
		});
	}

	clearCrtKiwiSaverData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.store.setHasFormChanges(hasFormChanges);
	}

	refetchClientDocument() {
		return this.clientProfileService
			.getClientDocuments(this.query.getValue().clientId)
			.pipe(catchError(() => of(undefined)));
	}

	getJSONTemplate(code: string, type: string) {
		const endpoint = `crt/oat-template/${code}/${type}`;
		return this.api.get<string>(endpoint).pipe(
			mergeMap((url) =>
				this.api.getExternalResource(url, { responseType: 'text' })
			),
			map((x) => util.tryParseJson(x)),
		);
	}

	disableSidebarTab(sidebar: Sidebar[], code: string): boolean {
		const pageCompleted = this.query.getValue().pageCompleted;
		const appIndex = sidebar?.findIndex((x) => x?.id === code) || 0;

		if (appIndex <= 0) {
			return false;
		}

		const previousTab = sidebar?.[appIndex - 1]?.pageCode;
		const isPrevComplete = previousTab
			? pageCompleted?.includes(previousTab)
			: false;

		return !isPrevComplete;
	}
}
