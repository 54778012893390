<div class="dashboard-control-wrapper container-fluid px-4">
	<div class="row justify-content-between">
		<div class="col-12 col-md my-1 my-md-0 position-relative clearfix">
			<button
				*appRestrictTo="['FDARW']"
				type="button"
				(click)="onToggleWidgets()"
				class="tap-btn tap-btn--default tap-btn--lg--block font-weight-bold dashboard-control-btn mr-lg-2 mb-2 mb-lg-0"
			>
				Add/Remove Widgets
			</button>
			<ng-container *ngIf="widgetsFormOpen">
				<div
					class="add-remove-widget clearfix shadow"
					[ngClass]="{
						'add-remove-widget__dynamic-width': (widgets$ | async).length > 23
					}"
				>
					<div
						class="add-remove-widget__header d-flex justify-content-between flex-wrap"
					>
						<h3 class="add-remove-widget__title p-0 col-12 col-md-auto">
							<span
								class="add-remove-widget__title--border-bottom pr-2 tap-text-secondary"
								>Add/Remove Widgets</span
							>
						</h3>
						<div
							class="clearfix align-self-end d-flex p-0 col-12 col-md-auto mt-2 mt-md-0"
						>
							<label
								for="selectAllWidgets"
								class="mr-3 clickable select-all-widgets add-remove-widget__label"
								>Select all widgets</label
							>
							<app-checkbox class="clickable">
								<input
									type="checkbox"
									[checked]="isSelectedAllWidgets$ | async"
									name="selectAllWidgets"
									id="selectAllWidgets"
									class="checkbox"
									(change)="selectAllWidgets($event.target.checked)"
								/>
							</app-checkbox>
						</div>
					</div>
					<div class="add-remove-widget__content pt-0">
						<app-manual-loader
							[isLoading]="isSavingWidgets$ | async"
						></app-manual-loader>
						<form novalidate [formGroup]="form" class="clearfix">
							<div class="d-flex d-sm-inline-flex flex-column flex-wrap">
								<div
									*ngFor="let w of widgets$ | async"
									class="clickable-widget d-flex px-0"
								>
									<label
										class="add-remove-widget__label w-100 p-0 clickable font-weight-bold mr-3"
										[for]="w.widgetCode"
										>{{ w.widgetName }}</label
									>
									<app-checkbox
										class="flex-shrink-1"
										[classList]="'font-weight-bold'"
									>
										<input
											type="checkbox"
											[formControlName]="w.widgetCode"
											[id]="w.widgetCode"
											class="checkbox"
										/>
									</app-checkbox>
								</div>
							</div>
							<div
								class="add-remove-widget__content__btn d-flex justify-content-end"
							>
								<button
									class="tap-btn tap-btn--default mr-2"
									type="button"
									[disabled]="isSavingWidgets$ | async"
									(click)="save()"
								>
									Apply
								</button>
								<button
									class="tap-btn tap-btn--default"
									type="button"
									(click)="cancel()"
								>
									Cancel
								</button>
							</div>
						</form>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="col-12 col-md-auto">
			<div class="form-row" *appRestrictTo="['FDCU']">
				<div
					class="dashboard-control__columns col-12 col-md-auto my-1 my-md-0 d-flex pt-1"
				>
					<label for="dashboard-all-adviser-checkbox" class="d-flex">
						<span class="pr-2 tap-text-color">See all advisers</span>
					</label>
					<input
						type="checkbox"
						id="dashboard-all-adviser-checkbox"
						class="chckbox"
						[checked]="allAdvisersSelected$ | async"
						(change)="selectAll($event.target.checked)"
					/>
				</div>
				<div
					class="dashboard-control__columns adviser-multiselect col-12 col-md my-1 my-md-0"
				>
					<app-chips
						[formControl]="adviserField"
						[choices]="adviserChoices$ | async"
					></app-chips>
				</div>
				<div class="dashboard-control__columns col-12 col-md-auto my-1 my-md-0">
					<button
						type="button"
						class="tap-btn tap-btn--default tap-btn--md--block font-weight-bold dashboard-control-btn"
						(click)="filter()"
					>
						Load
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
