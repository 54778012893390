import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { EMPTY, Observable, Observer } from 'rxjs';
import { BlStaffViewmodel } from '../../../viewmodels/bl-staff.viewmodel';
import { BlStaffService } from 'src/app/core/staff/bl-staff.service';
import { take, tap, mergeMap, switchMap, catchError, finalize, map } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadModalComponent } from 'src/app/shared/modal/upload-modal/upload-modal.component';

@Component({
	selector: 'app-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

	@Input() uploadFileFn$: ({ file, docType }) => Observable<any>;
	@Input() removeFileFn$: ({ file, type }) => Observable<any>;
	@Input() downloadFileFn$: ({ id, fileName }) => Observable<any>;
	@Input() data: BlStaffViewmodel;
	public bsModalRef: BsModalRef;

	disclosureDocFile = new DocumentFileClass();
	declarationDocFile = new DocumentFileClass();
	scopeOfServiceDocFile = new DocumentFileClass();
	letterOfAuthorityDocFile = new DocumentFileClass();
	businessOverviewDocFile = new DocumentFileClass();

	disclosure = new DocumentModel();
	declaration = new DocumentModel();
	scopeOfService = new DocumentModel();
	letterOfAuthority = new DocumentModel();
	businessOverview = new DocumentModel();

	uploadingDisclosure = false;
	uploadingDeclaration = false;
	uploadingScopeOfService = false;
	uploadingletterOfAuthority = false;
	uploadingbusinessOverview = false;

	uploadingRemoveDisclosure = false;
	uploadingRemoveDeclaration = false;
	uploadingRemoveScopeOfService = false;
	uploadingRemoveletterOfAuthority = false;
	uploadingRemovebusinessOverview = false;

	showDisclosureDocFileTemplate = false;
	showDeclarationDocFileTemplate = false;
	showScopeOfServiceDocFileTemplate = false;
	showLetterOfAuthorityDocFileTemplate = false;
	showBusinessOverviewDocFileTemplate = false;

	disclosureDocumentLabel = null;
	declarationDocumentLabel = null;
	scopeOfServiceLabel = null;
	letterOfAuthorityLabel = null;
	businessOverviewLabel = null;

	disclosureDocumentLabelRemove = null;
	declarationDocumentLabelRemove = null;
	scopeOfServiceLabelRemove = null;
	letterOfAuthorityLabelRemove = null;
	businessOverviewLabelRemove = null;

	constructor(private blService: BlStaffService, private renderer: Renderer2,
		           private modalService: BsModalService) { }

	ngOnInit() {
		if (this.data) {

			if (this.data.StaffSettings.DisclosureDocument && typeof +this.data.StaffSettings.DisclosureDocument === 'number') {
				this.blService.getDocumentById(+this.data.StaffSettings.DisclosureDocument).pipe(
					tap(x => {
						this.disclosure = x;
						this.disclosureDocumentLabel = x.DocumentName;
						this.disclosureDocumentLabelRemove = x.DocumentName;
					}),
					take(1)
				).subscribe();
			}
			// tslint:disable-next-line: max-line-length
			if (this.data.StaffSettings.DeclarationDocument && typeof +this.data.StaffSettings.DeclarationDocument === 'number') {
				this.blService.getDocumentById(+this.data.StaffSettings.DeclarationDocument).pipe(
					tap(x => {
						this.declaration = x;
						this.declarationDocumentLabel = x.DocumentName;
						this.declarationDocumentLabelRemove = x.DocumentName;
					}),
					take(1)
				).subscribe();
			}
			if (this.data.StaffSettings.ScopeOfService && typeof +this.data.StaffSettings.ScopeOfService === 'number') {
				this.blService.getDocumentById(+this.data.StaffSettings.ScopeOfService).pipe(
					tap(x => {
						this.scopeOfService = x;
						this.scopeOfServiceLabel = x.DocumentName;
						this.scopeOfServiceLabelRemove = x.DocumentName;
					}),
					take(1)
				).subscribe();
			}
			// tslint:disable-next-line: max-line-length
			if (this.data.StaffSettings.LetterOfAuthority && typeof +this.data.StaffSettings.LetterOfAuthority === 'number') {
				this.blService.getDocumentById(+this.data.StaffSettings.LetterOfAuthority).pipe(
					tap(x => {
						this.letterOfAuthority = x;
						this.letterOfAuthorityLabel = x.DocumentName;
						this.letterOfAuthorityLabelRemove = x.DocumentName;
					}),
					take(1)
				).subscribe();
			}
			// tslint:disable-next-line: max-line-length
			if (this.data.StaffSettings.BusinessOverview && typeof +this.data.StaffSettings.BusinessOverview === 'number') {
				this.blService.getDocumentById(+this.data.StaffSettings.BusinessOverview).pipe(
					tap(x => {
						this.businessOverview = x;
						this.businessOverviewLabel = x.DocumentName;
						this.businessOverviewLabelRemove = x.DocumentName;
					}),
					take(1)
				).subscribe();
			}
		}
	}

	removeDocument(type, show) {
		let file: RemoveFileRef;
		if (type === 'Disclosure') {
			file = {
				fileName: this.disclosureDocumentLabel,
				docName: 'Disclosure'
			};
		}
		if (type === 'Declaration') {
			file = {
				fileName: this.declarationDocumentLabel,
				docName: 'Declaration'
			};
		}
		if (type === 'Scope of Service') {
			file = {
				fileName: this.scopeOfServiceLabel,
				docName: 'Scope of Service'
			};
		}
		if (type === 'Letter of Authority') {
			file = {
				fileName: this.letterOfAuthorityLabel,
				docName: 'Letter of Authority'
			};
		}
		if (type === 'Business Overview') {
			file = {
				fileName: this.businessOverviewLabel,
				docName: 'Business Overview'
			};
		}

		const confirm = new Observable(obs => {
			this.sendConfirm(type);
			obs.next();
			obs.complete();
		}).pipe(
			mergeMap(() => this.saveDocument(type))
		);

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: '',
			message: `Are you sure you want to remove?`,
			secondaryMessage: `${file.docName} = ${file.fileName}`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveDocument(type) {
		let file: {};
		if (type === 'Disclosure') {
			file = {
				FileName: null,
			};
			this.uploadingRemoveDisclosure = true;
		}
		if (type === 'Declaration') {
			file = {
				FileName: null,
			};
			this.uploadingRemoveDeclaration = true;
		}
		if (type === 'Scope of Service') {
			file = {
				FileName: null,
			};
			this.uploadingRemoveScopeOfService = true;
		}
		if (type === 'Letter of Authority') {
			file = {
				FileName: null,
			};
			this.uploadingRemoveletterOfAuthority = true;
		}
		if (type === 'Business Overview') {
			file = {
				FileName: null,
			};
			this.uploadingRemovebusinessOverview = true;
		}

		return this.removeFileFn$({ file, type })
			.pipe(
				take(1),
				tap(() => {
					this.replaceUpload(type, true);
				}),
			);
	}

	sendConfirm(type) {
		if (type === 'Disclosure') {
			this.disclosure.DocumentName = '';
		}
		if (type === 'Declaration') {
			this.declaration.DocumentName = '';
		}
		if (type === 'Scope of Service') {
			this.scopeOfService.DocumentName = '';
		}
		if (type === 'Letter of Authority') {
			this.letterOfAuthority.DocumentName = '';
		}
		if (type === 'Business Overview') {
			this.businessOverview.DocumentName = '';
		}
		this.replaceUpload(type, true);
	}

	convertFileToBase64 = (blob, reader = new FileReader()) =>
		new Observable(obs => {
			if (!(blob instanceof Blob)) {
				return;
			}
			reader.onabort = () => obs.error(reader.error);
			reader.onerror = () => obs.error(reader.error);
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(blob);
		})

	uploadModal(type: string) {
		const upload = req =>
			new Observable(obs => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				mergeMap(x => this.convertFileToBase64(x[0]).pipe(take(1))),
				map((base64File: any) => {
					if (type === 'Disclosure') {
						this.disclosureDocFile = {
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Declaration') {
						this.declarationDocFile = {
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Scope of Service') {
						this.scopeOfServiceDocFile = {
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Letter of Authority') {
						this.letterOfAuthorityDocFile = {
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
					if (type === 'Business Overview') {
						this.businessOverviewDocFile = {
							Document: `${base64File?.split(',')[1]}`,
							FileName: `${req[0].name}`,
						};
					}
				}),
				switchMap(() => this.uploadFile(type))
			);


		const initState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: `Add New ${type} Document`
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	uploadFile(type: string) {
		let file = {};
		if (type === 'Disclosure') {
			file = this.disclosureDocFile;
			this.uploadingDisclosure = true;
		}
		if (type === 'Declaration') {
			file = this.declarationDocFile;
			this.uploadingDeclaration = true;
		}
		if (type === 'Scope of Service') {
			file = this.scopeOfServiceDocFile;
			this.uploadingScopeOfService = true;
		}
		if (type === 'Letter of Authority') {
			file = this.letterOfAuthorityDocFile;
			this.uploadingletterOfAuthority = true;
		}
		if (type === 'Business Overview') {
			file = this.businessOverviewDocFile;
			this.uploadingbusinessOverview = true;
		}

		return this.uploadFileFn$({ file, docType: type }).pipe(
			switchMap(() => {
				if ((type === 'Disclosure') && this.data.StaffSettings.DisclosureDocument && typeof +this.data.StaffSettings.DisclosureDocument === 'number') {
					return this.blService.getDocumentById(+this.data.StaffSettings.DisclosureDocument).pipe(
						tap(x => {
							this.disclosure = x;
							this.disclosureDocumentLabel = x.DocumentName;
							this.disclosureDocumentLabelRemove = x.DocumentName;
						})
					);
				}
				if ((type === 'Declaration') && this.data.StaffSettings.DeclarationDocument && typeof +this.data.StaffSettings.DeclarationDocument === 'number') {
					return this.blService.getDocumentById(+this.data.StaffSettings.DeclarationDocument).pipe(
						tap(x => {
							this.declaration = x;
							this.declarationDocumentLabel = x.DocumentName;
							this.declarationDocumentLabelRemove = x.DocumentName;
						})
					);
				}
				if ((type === 'Scope of Service') && this.data.StaffSettings.ScopeOfService && typeof +this.data.StaffSettings.ScopeOfService === 'number') {
					return this.blService.getDocumentById(+this.data.StaffSettings.ScopeOfService).pipe(
						tap(x => {
							this.scopeOfService = x;
							this.scopeOfServiceLabel = x.DocumentName;
							this.scopeOfServiceLabelRemove = x.DocumentName;
						})
					);
				}
				// tslint:disable-next-line: max-line-length
				if ((type === 'Letter of Authority') && this.data.StaffSettings.LetterOfAuthority && typeof +this.data.StaffSettings.LetterOfAuthority === 'number') {
					return this.blService.getDocumentById(+this.data.StaffSettings.LetterOfAuthority).pipe(
						tap(x => {
							this.letterOfAuthority = x;
							this.letterOfAuthorityLabel = x.DocumentName;
							this.letterOfAuthorityLabelRemove = x.DocumentName;
						})
					);
				}
				if ((type === 'Business Overview') && this.data.StaffSettings.BusinessOverview && typeof +this.data.StaffSettings.BusinessOverview === 'number') {
					return this.blService.getDocumentById(+this.data.StaffSettings.BusinessOverview).pipe(
						tap(x => {
							this.businessOverview = x;
							this.businessOverviewLabel = x.DocumentName;
							this.businessOverviewLabelRemove = x.DocumentName;
						})
					);
				}
			}),
			catchError(() => {
				this.reset(type); this.replaceUpload(type, true);
				return EMPTY;
			}),
			finalize(() => {
				this.reset(type); this.replaceUpload(type, false);
			})
		);
	}

	replaceUpload(type: string, show: boolean) {
		if (type === 'Disclosure') {
			this.showDisclosureDocFileTemplate = show;
			this.disclosure.DocumentName = this.disclosureDocumentLabel;

			this.uploadingRemoveDisclosure = false;
		}
		if (type === 'Declaration') {
			this.showDeclarationDocFileTemplate = show;
			this.declaration.DocumentName = this.declarationDocumentLabel;

			this.uploadingRemoveDeclaration = false;
		}
		if (type === 'Scope of Service') {
			this.showScopeOfServiceDocFileTemplate = show;
			this.scopeOfService.DocumentName = this.scopeOfServiceLabel;

			this.uploadingRemoveScopeOfService = false;
		}
		if (type === 'Letter of Authority') {
			this.showLetterOfAuthorityDocFileTemplate = show;
			this.letterOfAuthority.DocumentName = this.letterOfAuthorityLabel;

			this.uploadingRemoveletterOfAuthority = false;
		}
		if (type === 'Business Overview') {
			this.showBusinessOverviewDocFileTemplate = show;
			this.businessOverview.DocumentName = this.businessOverviewLabel;

			this.uploadingRemovebusinessOverview = false;
		}
	}

	replaceUrlToName(path: string) {
		if (!path) {
			return path;
		}
		return path?.replace(/^.*[\\\/]/, '');
	}

	reset(type: string) {
		if (type === 'Disclosure') {
			this.disclosureDocFile = new DocumentFileClass();
			this.uploadingDisclosure = false;
			this.showDisclosureDocFileTemplate = false;
		}
		if (type === 'Declaration') {
			this.declarationDocFile = new DocumentFileClass();
			this.uploadingDeclaration = false;
			this.showDeclarationDocFileTemplate = false;
		}
		if (type === 'Scope of Service') {
			this.scopeOfServiceDocFile = new DocumentFileClass();
			this.uploadingScopeOfService = false;
			this.showScopeOfServiceDocFileTemplate = false;
		}
		if (type === 'Letter of Authority') {
			this.letterOfAuthorityDocFile = new DocumentFileClass();
			this.uploadingletterOfAuthority = false;
			this.showLetterOfAuthorityDocFileTemplate = false;
		}
		if (type === 'Business Overview') {
			this.businessOverviewDocFile = new DocumentFileClass();
			this.uploadingbusinessOverview = false;
			this.showBusinessOverviewDocFileTemplate = false;
		}
	}

	downloadFile(id, fileName) {
		return this.blService.getDocumentUrlById(id).pipe(
			tap((fileUrl) => {
				if (!fileUrl) {
					return;
				}
				this.downloadExport(fileUrl, fileName);
			})
		).subscribe();
	}

	downloadExport(fileUrl: any, fileName: string) {
		const a = document.createElement('a');
		a.href = fileUrl;
		a.setAttribute('download', fileName);
		a.click();
	}
}

export class DocumentFileClass {
	FileName: string;
	Document: string;
}

export class DocumentModel {
	Id: number;
	DocumentName: string;
	FileName: string;
	FileExtension: string;
	CreateDateTime: string;
	CreatedByStaffId: number;
	ModifiedDateTime: string;
	ModifiedByStaffId: number;
	IsActive: boolean;
	CustomerID: number;
	DocumentTypeCode: string;
	DocumentLink: string;
}

export class RemoveFileRef {
	fileName: string;
	docName: string;
}
