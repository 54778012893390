<div class="cleafix">
	<!--row height 45px;-->
	<!--<table class="table" appResponsive>-->
	<!--	<thead>-->
	<!--		<tr class="align-items-center">-->
	<!--			<th class="created-date px-1 pl-0 text-truncate">Created Date</th>-->
	<!--			<th class="reference-number px-1 text-truncate">Ref. Number</th>-->
	<!--			<th class="claim-type px-1 text-truncate">Claim Type</th>-->
	<!--			<th class="outcome px-1 text-truncate">Outcome</th>-->
	<!--			<th class="total-paid px-1 text-truncate">Total Paid</th>-->
	<!--			<th class="status px-1 text-truncate">Status</th>-->
	<!--			<th class="view-process px-1 pr-0 text-truncate align-self-stretch">-->
	<!--				<ng-container *ngIf="isLoading">-->
	<!--					<div class="d-flex flex-row bd-highlight justify-content-end px-2">-->
	<!--						<app-manual-loader-mini-->
	<!--							*ngIf="isLoading"-->
	<!--							[isLoading]="isLoading"-->
	<!--						></app-manual-loader-mini>-->
	<!--					</div>-->
	<!--				</ng-container>-->
	<!--			</th>-->
	<!--		</tr>-->
	<!--	</thead>-->
	<!--	<tbody></tbody>-->
	<!--</table>-->
	<ng-container *ngIf="isLoading$ | async">
		<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	</ng-container>
	<div class="claims-table" appResponsive>
		<div *ngIf="!(isLoading$ | async)" class="w-100">
			<div
				class="claim-row-header row mx-0 align-items-center d-lg-flex d-none"
			>
				<div class="px-1 col-lg-1 text-truncate" title="Created Date">
					Created Date
				</div>
				<div class="px-1 col-lg-2 text-truncate" title="Ref. Number">
					Ref. Number
				</div>
				<div class="px-1 col-lg-5 text-truncate" title="Claim Type">
					Claim Type
				</div>
				<div class="px-1 col-lg-1 text-truncate" title="Outcome">Outcome</div>
				<div class="px-1 col-lg-1 text-truncate" title="Total Paid">
					Total Paid
				</div>
				<div class="px-1 pr-0 py-0 col-lg-1 text-truncate" title="Status">
					Status
				</div>
				<div class="px-1 pr-0 py-0 col-lg-1 text-truncate"></div>
			</div>
			<div
				class="claim-row-container align-items-center row mx-0 w-100 mb-lg-0 mb-2"
				*ngFor="let claim of claimsList; trackBy: trackByFn; let i = index"
			>
				<div
					class="created-date col-lg-1 col-12 claims-table-cell"
					#createdDate
				>
					<span class="position-relative" #createdDateContainer>
						{{ claim.createdDate | momentDatetime: false }}
						<span
							class="tooltip-wrapper position-absolute"
							tooltip="{{ claim.createdDate | momentDatetime: false }}"
							[style.width.px]="createdDate.offsetWidth"
							container="body"
							*ngIf="isEllipsisActive(createdDate, createdDateContainer)"
						></span>
					</span>
				</div>

				<div
					class="reference-number col-lg-2 col-12 claims-table-cell"
					#referenceNumber
				>
					<span #referenceNumberContainer class="position-relative">
						<span
							class="tooltip-wrapper position-absolute"
							[tooltip]="claim.referenceNumber"
							[style.width.px]="referenceNumber.offsetWidth"
							container="body"
							*ngIf="isEllipsisActive(referenceNumber, referenceNumberContainer)"
						></span>
						{{ claim.referenceNumber }}
					</span>
				</div>
				<div class="claim-type col-lg-5 col-12 claims-table-cell" #claimType>
					<span #claimTypeContainer class="position-relative">
						<span
							class="tooltip-wrapper position-absolute"
							tooltip="{{ formatClaimType(claim.claimType) }}"
							[style.width.px]="claimType.offsetWidth"
							container="body"
							*ngIf="isEllipsisActive(claimType, claimTypeContainer)"
						></span>
						{{ formatClaimType(claim.claimType) }}
					</span>
				</div>
				<div class="outcome col-lg-1 col-12 claims-table-cell" #claimOutcome>
					<span #claimOutcomeContainer class="position-relative">
						<span
							class="tooltip-wrapper position-absolute"
							[tooltip]="claim.claimOutcome"
							[style.width.px]="claimOutcome.offsetWidth"
							container="body"
							*ngIf="isEllipsisActive(claimOutcome, claimOutcomeContainer)"
						></span>
						{{ claim.claimOutcome }}
					</span>
				</div>
				<div class="total-paid col-lg-1 col-12 claims-table-cell" #totalPaid>
					<span #totalPaidContainer class="position-relative">
						<span
							class="tooltip-wrapper position-absolute"
							[tooltip]="claim.totalPaid"
							[style.width.px]="totalPaid.offsetWidth"
							container="body"
							*ngIf="isEllipsisActive(totalPaid, totalPaidContainer)"
						></span>
						{{ claim.totalPaid }}
					</span>
				</div>
				<div
					class="status col-lg-2 col-12align-items-center d-flex justify-content-between"
				>
					<div #status class="claims-table-cell">
						<span #statusContainer class="position-relative">
							<span
								class="tooltip-wrapper position-absolute"
								tooltip="{{ claim.status }}"
								[style.width.px]="status.offsetWidth"
								container="body"
								*ngIf="isEllipsisActive(status, statusContainer)"
							></span>
							{{ claim.status }}
						</span>
					</div>

					<button
						type="button"
						class="btn btn-link p-1 d-flex align-items-center ml-auto text-decoration-none"
						*ngIf="claim.adviceProcessID"
						id="deleteNote_{{ i }}"
						(click)="redirectToAdvice(claim)"
					>
						<i class="material-icons tap-text-color-h6 md-20">visibility</i>
					</button>
				</div>
				<!--<div class="view-process px-1 pr-0 text-right py-0 col-lg-1 claims-table-cell">-->
				<!---->
				<!--</div>-->
			</div>
		</div>
	</div>

	<ng-container
		*ngIf="
			(!!count && count > itemsPerPage) ||
			(!!claims && claims?.length > itemsPerPage)
		"
	>
		<pagination
			[totalItems]="
				count && count > 0 ? count : claims && claims?.length ? claims.length : 0
			"
			[(ngModel)]="currentpage"
			[itemsPerPage]="itemsPerPage"
			[maxSize]="maxSize"
			(pageChanged)="setPage($event)"
		>
		</pagination>
	</ng-container>
</div>
