import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';

@Component({
  selector: 'app-textarea-modal',
  templateUrl: './textarea-modal.component.html',
})
export class TextareaModalComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  header: string;
  value: string;
  isSaving = false;
  savefn: (model: any) => Observable<any>;

  form: UntypedFormGroup = this.fb.group({
    textareamodal: this.fb.control(null, [NoWhitespaceValidator])
  });

  constructor(private fb: UntypedFormBuilder, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.form.get('textareamodal').setValue(this.value);
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    this.isSaving = true;
    const value = this.form.value.textareamodal;

    this.subs.push(
      this.savefn(value).subscribe(
        res => {
          if (res) {
            this.isSaving = false;
          }
          this.decline();
        },
        () => {
          this.isSaving = false;
        }
      )
    );
  }

  decline = () => this.bsModalRef.hide();

  ngOnDestroy() {
    this.subs?.forEach(x => x.unsubscribe());
    this.subs = [];
  }
}
