import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { BLStaffsService } from '../../domain/bl-staff/bl-staffs.service';
import { BusinessConfigService, getCompanyCode } from '../../domain/business-config/business-config.service';
import { UserService } from '../../domain/user/user.service';
import { Location } from '@angular/common';  

@Injectable()
export class CrmDataService implements Resolve<boolean> {
  constructor(
    private domainUserService: UserService,
    private businessConfigService: BusinessConfigService,
    private blStaffsService: BLStaffsService,
    private router: Router,
    private location: Location
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const companyCode = route.paramMap.get('companyCode') || getCompanyCode();
    const user$ = this.domainUserService.get();
    const staff$ = this.blStaffsService.addList();
    const config$ = this.businessConfigService.get(false, companyCode);

    return forkJoin([user$, staff$, config$]).pipe(
			mapTo(true),
      catchError((err) => {
        this.router.navigate(['/error'], { queryParams: { url: this.location.path() }});
        return of(err);
      })
		);
  }
}
