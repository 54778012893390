import {
	Component,
	OnInit,
	Input,
	ViewChild,
	OnChanges,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	SimpleChanges,
	ViewChildren,
	QueryList,
	OnDestroy,
	NgZone,
	ViewContainerRef,
	TemplateRef,
} from '@angular/core';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import {
	KiwisaverState,
	KiwiSaverTypes,
} from '../../models/services/kiwisaver/kiwisaver.model';
import { documentUtil } from '../service-utils/document.util';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import {
	Observable,
	throwError,
	Observer,
	Subject,
	iif,
	combineLatest,
	of,
} from 'rxjs';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import {
	tap,
	catchError,
	takeUntil,
	map,
	take,
	filter,
	withLatestFrom,
	concatMap,
} from 'rxjs/operators';
import { LinkDocumentComponent } from '../link-document/link-document.component';
import {
	convertFundOwnersIdToName,
	kiwiSaverServiceUtil,
} from '../service-utils/kiwisaver.util';
import { KiwisaverServiceFormComponent } from '../kiwisaver-service-form/kiwisaver-service-form.component';
import { KiwiSaverTypeModalComponent } from '../../modal/kiwisaver-type-modal/kiwisaver-type-modal.component';
import { ClientProfileService } from 'src/app/modules/crm/client-profile/states/client-profile.service';
import { ServicesCodes } from '../../models/services/services.model';
import { InvestmentState } from '../../models/services/investments/investments.model';
import { InvestmentServiceFormComponent } from '../investment-service-form/investment-service-form.component';
import { CustomerTypes } from '@shared/models/_general/client.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import MomentUtil from '@util/moment.util';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { InvestmentMapper } from '@shared/models/services/investments/investments.mapper';

@Component({
	selector: 'app-kiwisaver-service',
	templateUrl: './kiwisaver-service.component.html',
	styleUrls: ['./kiwisaver-service.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KiwisaverServiceComponent implements OnInit, OnChanges, OnDestroy {
	private onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;
	@Input() isLead: boolean;
	@Input() isLoading: boolean;

	@Input() addKsFn$: (data) => Observable<any>;
	@Input() saveKsFn$: (data) => Observable<any>;
	@Input() deleteKsFn$: (data) => Observable<any>;
	@Input() archiveKsFn$: ({ data, isArchive }) => Observable<any>;
	@Input() deleteNoteKsFn$: ({ id, data }) => Observable<any>;
	@Input() updateDocumentKsFn$: ({ id, customerServiceID }) => Observable<any>;
	@Input() addNoteKsFn$: (data) => Observable<any>;
	@Input() addInvFn$: (data) => Observable<any>;
	@Input() saveInvFn$: (data) => Observable<any>;
	@Input() deleteInvFn$: (data) => Observable<any>;
	@Input() archiveInvFn$: ({ data, isArchive }) => Observable<any>;
	@Input() deleteNoteInvFn$: ({ id, data }) => Observable<any>;
	@Input() updateDocumentInvFn$: ({ id, customerServiceID }) => Observable<any>;
	@Input() addNoteInvFn$: (data) => Observable<any>;
	@Input() downloadLink$: ({ documentID }) => Observable<string>;

	@Input() providers: ViewDisplayValue[];
	@Input() iProviders: ViewDisplayValue[];
	@Input() fundTypes: ViewDisplayValue[];
	@Input() kstatus: ViewDisplayValue[];
	@Input() istatus: ViewDisplayValue[];
	@Input() origins: ViewDisplayValue[];
	@Input() investmentTypes: ViewDisplayValue[];
	@Input() kPIRRates: ViewDisplayValue[];
	@Input() iPIRRates: ViewDisplayValue[];
	@Input() fundOwners: ViewDisplayValue[];
	@Input() types: ViewDisplayValue[];
	@Input() document: DocumentGroupState;
	@Input() isCompany: boolean;
	@Input() hasK: boolean;
	@Input() hasI: boolean;
	@Input() customerId: number;
	@Input() kiwiSavers: KiwisaverState[];
	@Input() investments: InvestmentState[];
	@Input() propertyOwners: ViewDisplayValue[];
	@Input() documentIsLoading$: Observable<boolean>;
	@Input() documents$: Observable<DocumentGroupState>;
	@Input() iretentionStatus: ViewDisplayValue[];
	@Input() kretentionStatus: ViewDisplayValue[];

	@Input() updateNextReviewFn$: (data) => Observable<any>;

	@Input() primaryClient: PrimaryClientState;
	@Input() primaryCompany: any;

	@ViewChild('sidebarNav') sidebarNav: TabsetComponent;
	@ViewChildren('kForms') kForms: QueryList<KiwisaverServiceFormComponent>;
	@ViewChildren('iForms') iForms: QueryList<InvestmentServiceFormComponent>;

	kiwiSaversState: KiwisaverState[];
	kiwiSaversAndInvestment: any[] = [];
	currentKs: KiwisaverState | InvestmentState | any;

	isAddNew = false;
	isSaving = false;
	isSavingDocument = false;
	isAddNewGroup = false;
	isAddNewKs = false;
	hasSelectedDocument = false;
	isShowArchived = false;
	isAddNewPortfolio = false;
	documentIsLoading = false;
	isSavingNRD = false;

	activeProvider: string;
	activeGroupNavId: string;

	currentLinkedDocument: any;
	currentLinkedDocumentId: string;
	currentCustomerServiceId: number;
	documents: DocumentGroupState;

	ksList: KiwisaverState[];

	clientsInvolvedRaw$ = this.clientService.clientsInvolvedRaw$;
	clientsInvolved: ViewDisplayValue[];

	ksTypes = KiwiSaverTypes;
	serviceCodes = ServicesCodes;

	@ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
	@ViewChild('content', { read: TemplateRef }) contentRef: TemplateRef<any>;

	form: FormGroup;

	totalFUM = 0;

	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private clientService: ClientProfileService,
		private zone: NgZone,
		private fb: FormBuilder
	) {
		this.form = this.fb.group({
			investmentKSNextReview: [''],
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (!!this.isCompany && changes?.fundOwners) {
				this.clientsInvolved = changes?.fundOwners?.currentValue || [];
			}
			if (changes.primaryClient || changes.primaryCompany) {
				this.setInvestmentKSReviewDate();
			}
			if (
				(changes.kiwiSavers && changes.kiwiSavers.currentValue !== null) ||
				(changes.investments && changes.investments.currentValue !== null)
			) {
				const list = this.updatedKsList() || [];
				// this.kiwiSaversAndInvestment = Object.assign([], list);
				this.kiwiSaversAndInvestment = [...list];
				this.ksList = this.kiwiSaversAndInvestment;

				// update current ks when navigating through global search
				const currentNotInList = !list?.find(x => x?.customerServiceID === this.currentCustomerServiceId);
				if(list?.length > 0 && currentNotInList) {
					this.setInit();
				}

				if (
					this.kiwiSaversAndInvestment &&
					this.kiwiSaversAndInvestment.length > 0 &&
					!this.activeProvider
				) {
					this.setInit();
				}
			}

			if (changes?.document) {
				this.setPortfolio();
			}

			if (
				((changes.kiwiSavers && changes.kiwiSavers.previousValue) ||
					(changes.investments && changes.investments.previousValue)) &&
				this.activeGroupNavId
			) {
				let i = -1;
				this.kiwiSaversAndInvestment?.filter((k, index) => {
					if (+this.activeGroupNavId === k.customerServiceID) {
						return (i = index);
					}
				});

				if (i === -1) {
					// set last tab to active
					return setTimeout(() => {
						const tab =
							this.sidebarNav?.tabs[this.kiwiSaversAndInvestment.length - 1];
						if (tab) {
							tab.active = true;
						}
					}, 0);
				} else {
					return setTimeout(() => {
						const tab = this.sidebarNav?.tabs[i];
						if (tab) {
							tab.active = true;
						}
					}, 0);
				}
			}
		}
	}

	setInvestmentKSReviewDate() {
		const data = this.isCompany ? this.primaryCompany : this.primaryClient;
		const investmentKSNextReview = InvestmentMapper.mapInvestmentNextReviewView(
			data?.investmentKSNextReview
		);
		this.form.reset({ investmentKSNextReview });
	}

	rerender() {
		if (!this.outletRef) {
			return;
		}
		this.outletRef.clear();
		this.outletRef.createEmbeddedView(this.contentRef);
	}

	ngOnInit() {
		this.clientsInvolvedRaw$
			.pipe(
				map((clientsInvolvedRaw) => {
					return clientsInvolvedRaw.map((client: any) => {
						let display = '';
						let value = `${client.customerID}`;
						if (
							client.customerType === CustomerTypes.PrimaryCustomerIndividual
						) {
							display = `${client.firstName} ${client.lastName}`;
						}

						if (
							client.customerType === CustomerTypes.SecondaryCustomerIndividual
						) {
							display = `${client.firstName} ${client.lastName}`;
						}

						if (client.customerType === CustomerTypes.PrimaryCustomerCompany) {
							display = `${client.companyName}`;
						}

						if (client.customerType === CustomerTypes.SecondaryCustomerTrust) {
							display = `${client.trustName}`;
						}

						if (client.customerType === CustomerTypes.LinkedContact) {
							display = `${client.name}`;
							value = `${
								client.linkedFromPrimaryCustomer
									? client?.relatedCustomerId
									: client?.customerId
							}`;
						}

						return {
							value,
							display,
							isActive: false,
							isDefault: false,
						};
					});
				}),
				map((clientsInvolved) =>
					this.isCompany ? this.fundOwners : clientsInvolved
				),
				tap((owners) => (this.clientsInvolved = owners)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.documentIsLoading$
			.pipe(
				withLatestFrom(this.documents$),
				filter(([loading]) => !loading),
				tap(([, documents]) => {
					if (!this.currentLinkedDocument) {
						this.document = documents;
						this.setPortfolio();
					}
				}),
				take(1)
			)
			.subscribe();

		if (!this.kiwiSavers && !this.investments) {
			this.kiwiSaversAndInvestment = [];
			this.ksList = [];
		} else {
			const list = this.updatedKsList() || [];
			this.kiwiSaversAndInvestment = Object.assign([], list);
			this.ksList = list;
		}

		if (
			!this.kiwiSaversAndInvestment ||
			this.kiwiSaversAndInvestment.length < 1
		) {
			this.activeGroupNavId = '';
			this.activeProvider = '';
			this.currentLinkedDocumentId = '';
			this.currentLinkedDocument = null;
			this.currentCustomerServiceId = 0;
			this.currentKs = null;
		} else {
			this.setInit();
		}

		this.setInvestmentKSReviewDate();
	}

	refresh() {
		this.cd.detectChanges();
	}

	trackByFn(index: number, item: KiwisaverState) {
		return index;
	}

	trackById(index: number, item: KiwisaverState) {
		return item.customerServiceID;
	}

	setInit() {
		this.activeGroupNavId =
			this.kiwiSaversAndInvestment?.[0]?.customerServiceID?.toString();
		this.activeProvider = this.kiwiSaversAndInvestment?.[0]?.provider;
		this.currentCustomerServiceId =
			this.kiwiSaversAndInvestment?.[0]?.customerServiceID;
		this.currentKs = this.kiwiSaversAndInvestment?.[0];
		this.setPortfolio();
	}

	setPortfolio() {
		if (!!this.currentKs?.documentLink) {
			this.currentLinkedDocumentId = this.currentKs?.documentLink;
			this.currentLinkedDocument = this.currentLinkedDocumentId
				? documentUtil(this.document, +this.currentLinkedDocumentId)
				: null;
		} else {
			this.currentLinkedDocument = null;
			this.currentLinkedDocumentId = null;
		}
	}

	selectKTab = (item) => {
		const id = !!item.id ? item.id : item.customerServiceId;
		const index = this.ksList?.findIndex((k) => k.customerServiceID === id);
		if (index > -1) {
			const form =
				this.kForms?.find((k) => k.form.value.customerServiceID === id) ||
				this.iForms?.find((k) => k.form.value.customerServiceID === id);

			setTimeout(() => {
				if (this.sidebarNav?.tabs?.[index]) {
					this.sidebarNav.tabs[index].active = true;
				}
			}, 2);

			if (form) {
				setTimeout(() => {
					form.formProp.nativeElement.scrollIntoView({ behavior: 'smooth' });
				}, 100);
			}
		}
	};

	updatedKsList() {
		const kiwiSavers = this.hasK ? this.kiwiSavers : [];
		const investments = this.hasI ? this.investments : [];
		const list = [...(kiwiSavers || []), ...(investments || [])];

		this.totalFUM = list
			.filter((item) => item?.status === 'Active')
			.reduce((a, b) => a + b?.fUM, 0);

		return kiwiSaverServiceUtil(list);
	}

	/**
	 * Show/Hide archived services
	 * @param isShowArchived boolean
	 */
	showArchived(isShowArchived: boolean) {
		this.isShowArchived = isShowArchived;
	}

	selectTab() {
		let ksCount = 0;
		if (this.isShowArchived) {
			ksCount = this.kiwiSaversAndInvestment?.filter((x) => x).length - 1;
		} else {
			ksCount =
				this.kiwiSaversAndInvestment?.filter((x) => x.isActive === 1).length -
				1;
		}

		setTimeout(() => (this.sidebarNav.tabs[ksCount].active = true), 2);
	}

	createModal() {
		const types = this.types?.filter((x) => {
			if (x?.value === KiwiSaverTypes.KiwiSaver) {
				return this.hasK;
			}
			if (x?.value === KiwiSaverTypes.Investment) {
				return this.hasI;
			}
			return false;
		});
		const initState = {
			header: 'Select a type:',
			savefn: this.addNewGroup,
			KT: types,
		};

		this.bsModalRef = this.modalService.show(KiwiSaverTypeModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.bsModalRef.content.saveEvt.subscribe((res) => {
			this.addNewGroup(res);
		});

		this.refresh();
	}

	addNewGroup(type?: any) {
		this.isAddNew = true;
		this.isAddNewGroup = true;
		this.isAddNewKs = !!(type === KiwiSaverTypes.KiwiSaver);
		// Should add new data on the list
		const data =
			type === KiwiSaverTypes.KiwiSaver
				? this.addNewKiwiSaver(type)
				: this.addNewInvestment(type);

		this.kiwiSaversAndInvestment = [...this.kiwiSaversAndInvestment, data];

		this.currentLinkedDocumentId = '';
		this.currentLinkedDocument = null;

		this.refresh();
		this.selectTab();
	}

	addNewKiwiSaver(type) {
		return {
			fundOwner: [''],
			provider: '',
			status: '',
			memberNumber: '',
			startDate: '',
			fundType: '',
			iRDNumber: '',
			contribution: 0,
			fUM: 0,
			origin: '',
			kiwisaverStatusClock: '',
			color: 'dark',
			customerServiceID: 0,
			customerID: 0,
			submittedDate: '',
			isActive: 1,
			originalAdviser: 0,
			serviceCode: ServicesCodes.KiwiSaver,
			type,
		};
	}

	addNewInvestment(type) {
		return {
			investor: [''],
			provider: '',
			status: '',
			investorNumber: '',
			startDate: '',
			iRDNumber: '',
			contribution: 0,
			fUM: 0,
			color: 'dark',
			customerServiceID: 0,
			customerID: 0,
			originalAdviser: 0,
			submittedDate: '',
			investmentType: '',
			pIRRate: '',
			product: '',
			totalWithdrawals: '',
			documentLink: '',
			isActive: 1,
			serviceCode: ServicesCodes.Investment,
			type,
		};
	}

	selectGroupConfirm(tab, ks) {
		if (!tab.id || +tab.id === 0) {
			return;
		}

		if (this.isAddNewGroup && this.isAddNew) {
			this.selectGroup();
		}

		this.activeGroupNavId = tab.id;
		this.currentCustomerServiceId = +ks.customerServiceID;
		this.currentKs = ks;
		this.currentLinkedDocumentId = ks.documentLink;
		this.currentLinkedDocument = this.currentLinkedDocumentId
			? documentUtil(this.document, +this.currentLinkedDocumentId)
			: null;
	}

	selectGroup() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.isAddNew = false;
			this.isAddNewGroup = false;
			this.isAddNewKs = false;
			setTimeout(
				() =>
					(this.sidebarNav.tabs.find(
						(x) => x.id === this.activeGroupNavId
					).active = true),
				2
			);
			this.kiwiSaversAndInvestment?.pop();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: 'Discard Confirmation',
			message: `Current information will be discarded?`,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		setTimeout(() => {
			this.activeGroupNavId = this.sidebarNav?.tabs?.find((x) => x.active)?.id;
			this.selectTab();
		});
	}

	cancelAddNewGroup(cancel: boolean) {
		this.isAddNew = cancel;
		this.isAddNewGroup = false;
		this.isAddNewKs = false;
		this.sidebarNav.tabs[0].active = true;
		this.kiwiSaversAndInvestment?.pop();
	}

	createKs(data) {
		this.isSaving = true;
		data.customerID = this.customerId;

		iif(
			() => data?.serviceCode === ServicesCodes.KiwiSaver,
			this.addKsFn$(data),
			this.addInvFn$(data)
		)
			.pipe(
				tap((x) => {
					if (x) {
						if (this.isAddNewGroup) {
							this.currentLinkedDocument = null;
							this.currentLinkedDocumentId = null;
						}
						this.isAddNew = false;
						this.isAddNewGroup = false;
						this.isAddNewKs = false;
						this.isSaving = false;
						this.activeGroupNavId = data.customerServiceID;
						this.currentCustomerServiceId = data.customerServiceID;
						this.currentKs = data;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	saveKs(data) {
		const forms =
			data?.serviceCode === ServicesCodes.KiwiSaver ? this.kForms : this.iForms;
		forms
			?.find((x) => x.form.value.customerServiceID === data.customerServiceID)
			.formSaving(true);

		this.isAddNewGroup = false;
		this.isAddNewKs = false;
		data.customerID = this.customerId;

		iif(
			() => data?.serviceCode === ServicesCodes.KiwiSaver,
			this.saveKsFn$(data),
			this.saveInvFn$(data)
		)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
						forms
							?.find(
								(y) => y.form.value.customerServiceID === data.customerServiceID
							)
							.formSaving(false);
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					forms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);

					forms
						?.find(
							(y) => y.form.value.customerServiceID === data.customerServiceID
						)
						.editForm(false);
				},
				() => {
					const k = forms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (k) {
						k.formSaving(false);
						k.prepData();
					}
				},
				() => {
					forms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	deleteKs(data) {
		this.isSaving = true;
		data.customerID = this.customerId;

		iif(
			() => data?.serviceCode === ServicesCodes.KiwiSaver,
			this.deleteKsFn$(data),
			this.deleteInvFn$(data)
		)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	archiveKs(req: { ks?; investment?; isArchive }) {
		this.isSaving = true;
		const request = !!req?.ks
			? req.ks
			: !!req?.investment
			? req.investment
			: null;

		request.customerID = this?.customerId;

		iif(
			() => request?.serviceCode === ServicesCodes.KiwiSaver,
			this.archiveKsFn$({ data: request, isArchive: req.isArchive }),
			this.archiveInvFn$({ data: request, isArchive: req.isArchive })
		)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	deleteNote = (req: { noteId; data }) => {
		this.isSaving = true;
		req.data.customerID = this.customerId;

		iif(
			() => req?.data?.serviceCode === ServicesCodes.KiwiSaver,
			this.deleteNoteKsFn$({
				id: req.noteId,
				data: req.data,
			}),
			this.deleteNoteInvFn$({
				id: req.noteId,
				data: req.data,
			})
		)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	};

	upsertDocument(id) {
		this.isSavingDocument = true;
		this.refresh();

		iif(
			() => this.currentKs?.serviceCode === ServicesCodes.KiwiSaver,
			this.updateDocumentKsFn$({
				id,
				customerServiceID: this.currentCustomerServiceId,
			}),
			this.updateDocumentInvFn$({
				id,
				customerServiceID: this.currentCustomerServiceId,
			})
		)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSavingDocument = false;
						this.isSaving = false;
						this.currentLinkedDocumentId = id;
						this.currentLinkedDocument = id
							? documentUtil(this.document, +this.currentLinkedDocumentId)
							: null;
						this.refresh();
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSavingDocument = false;
				},
				() => {
					this.isSavingDocument = false;
				},
				() => {
					this.isSavingDocument = false;
				}
			);
	}

	linkDocument() {
		const initState = {
			selectedDetail: 'Link Portfolio',
			document: this.document,
			initialSelectedTab: ServicesCodes.KiwiSaver?.toLowerCase(),
		};
		this.bsModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
		this.bsModalRef.content.getSelectedDocumentValue$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(doc) => {
					// this.currentLinkedDocument = doc;
					this.zone.run(() => {
						this.documentIsLoading = true;
						this.upsertDocument(doc.id);
						this.hasSelectedDocument = true;
					});
				},
				() => {
					this.hasSelectedDocument = false;
				},
				() => {}
			);
	}

	unlinkedDocument() {
		this.documentIsLoading = true;
		this.upsertDocument('');
		this.hasSelectedDocument = false;
		this.cd.markForCheck();
	}

	downloadLink = (data) => {
		this.downloadLink$({ documentID: data })
			.pipe(
				tap((res) => {
					window.location.href = res;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	// Fund Owner/Investment Name
	fundOwnerName = (ks) => convertFundOwnersIdToName(this.clientsInvolved, ks);

	addNote(data) {
		const forms =
			data?.serviceCode === ServicesCodes.KiwiSaver ? this.kForms : this.iForms;
		data.customerID = this.customerId;

		iif(
			() => data?.serviceCode === ServicesCodes.KiwiSaver,
			this.addNoteKsFn$(data),
			this.addNoteInvFn$(data)
		)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
						forms
							?.find(
								(y) => y.form.value.customerServiceID === data.customerServiceID
							)
							.formSaving(false);
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					forms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					const k = forms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (k) {
						k.formSaving(false);
						k.prepData();
					}
				},
				() => {
					forms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	// updateReviewDateFn$ = (event: MatDatepickerInputEvent<Moment>) =>
	// 	of(event).pipe(
	// 		map((x) => (!!x?.value ? MomentUtil.formatToServerDate(x.value) : '')),
	// 		concatMap(this.updateNextReviewFn$)
	// 	);

	updateReviewDate() {
		this.isSavingNRD = true;
		const formControl = this.form.get('investmentKSNextReview');
		const formatedDate = !!formControl?.value
			? MomentUtil.formatToServerDate(formControl?.value)
			: null;

		// if (formatedDate) {
			const currentErrors = formControl.errors;
			// If there are errors and 'dateError' is present, remove it
			if (currentErrors && currentErrors.dateError) {
				delete currentErrors.dateError;

				// Set the updated errors
				formControl.setErrors(
					Object.keys(currentErrors).length > 0 ? currentErrors : null
				);
			}
			return this.updateNextReviewFn$(formatedDate)
				.pipe(take(1))
				.subscribe(() => {
					this.isSavingNRD = false;
				});
		// } else {
		// 	this.setInvestmentKSReviewDate();
		// 	this.isSavingNRD = false;
		// }
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
