import { Moment } from 'moment';
import { AdviserServiceState, PrimaryClientState, SasReference } from './primary-client.model';
import MomentUtil from '../../../../util/moment.util';
import { util } from '../../../../core/util/util.service';
import { objectUtil } from '../../../../util/util';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';

export class ClientProfilePrimaryMapper {
	/** Mapping for view */
	static mapToView(data: PrimaryClientState) {
		const val = {
			...data,
			preferredEmailContact:
				data && data.preferredEmailContact
					? data.preferredEmailContact?.toString()
					: '',
			adviser: data && data.adviser ? data.adviser?.toString() : null,
			lastReview: this.formatDateToMoment(data.lastReview),
			nextReview: this.formatDateToMoment(data.nextReview),
			reviewPending: util.toBool(data.reviewPending as string),
			leadPending: util.toBool(data.leadPending as string),
			dateOfBirth: this.formatDateToMoment(data.dateOfBirth),
			altAdviser:
				data.altAdviser && typeof data.altAdviser === 'string'
					? typeof JSON.parse(data.altAdviser) === 'object' &&
					  JSON.parse(data.altAdviser).length > 0
						? JSON.parse(data.altAdviser)
								?.filter((x) => x)
								?.map(String)
						: []
					: [],
			referredBy: data.referredBy
				? objectUtil.mapCamelCaseToPascalCase(data.referredBy)
				: null,
			professionalContacts: data.professionalContacts
				? JSON.parse(data.professionalContacts)
				: null,
		};
		return val;
	}

	/** Mapping for view */
	static mapToUpsert(data: PrimaryClientState) {
		return {
			...data,
			email: data.email,
			adviser: +data.adviser,
			preferredEmailContact: data.preferredEmailContact,
			lastReview: this.formatDateToServerDate(data.lastReview),
			nextReview: this.formatDateToServerDate(data.nextReview),
			reviewPending: util.toBool(data.reviewPending as string),
			leadPending: util.toBool(data.leadPending as string),
			dateOfBirth: this.formatDateToServerDate(data.dateOfBirth),
			altAdviser:
				data.altAdviser && typeof data.altAdviser === 'string'
					? data.altAdviser
					: data.altAdviser &&
					  Array.isArray(data.altAdviser) &&
					  data.altAdviser.length > 0
					? JSON.stringify(data.altAdviser)
					: null,
			referredBy:
				data.referredBy &&
				(+data.referredBy.CustomerId > 0 || data.referredBy.customerId > 0)
					? objectUtil.mapPascalCaseToCamelCase(data.referredBy)
					: null,
			// tslint:disable-next-line: max-line-length
			professionalContacts:
				data.professionalContacts &&
				data.professionalContacts.length > 0 &&
				Array.isArray(data.professionalContacts)
					? JSON.stringify(data.professionalContacts)
					: data.professionalContacts &&
					  typeof data.professionalContacts === 'string'
					? data.professionalContacts
					: null,
			lRNextReview: data?.lRNextReview || null,
			mortgageNextReview: data?.mortgageNextReview || null,
			fGNextReview: data?.fGNextReview || null,
			investmentKSNextReview: data?.investmentKSNextReview || null,
			isActive: 1,
			saveAnyway:false,
		};
	}

	/**
	 * Format date to string YYYY-MM-DD
	 * @param date string or Moment date
	 */
	static formatDateToServerDate(date: string | Moment): string {
		return typeof date === 'string'
			? MomentUtil.formatToServerDate(util.DateStringToMomentLoose(date))
			: MomentUtil.formatToServerDate(date);
	}

	/**
	 * Format date to Moment
	 * @param date string or Moment date
	 */
	static formatDateToMoment(date: string | Moment): Moment {
		return typeof date === 'string'
			? util.DateStringToMomentLoose(date)
			: util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
	}

	/**
	 * Adviser Rework Mappers
	 */
	static mapAdviserReworkServicesView(data: PrimaryClientState) {
		const result = SasReference?.reduce((a, c) => {
			if (!!data?.[c?.id]) {
				const ifExists = a?.find((x) => +x?.adviserId === +data?.[c?.id]);
				if (ifExists) {
					return a?.map((x) => {
						if (+x?.adviserId === +data?.[c?.id]) {
							return {
								adviserId: +x?.adviserId,
								services: [...(x?.services || []), c?.display],
							};
						} else {
							return x;
						}
					});
				} else {
					return [...a, { adviserId: data?.[c?.id], services: [c?.display] }];
				}
			}
			return a;
		}, []);
		return result as AdviserServiceState[];
	}

	static mapAdviserReworkServicesUpsert(adviserServices) {
		const getAdviser = (id) => {
			const serviceName = SasReference?.find((s) => s?.id === id)?.display;
			const adviser = adviserServices?.find(
				(x) => !!x?.services?.includes(serviceName)
			)?.adviserId;
			return !!adviser ? +adviser : null;
		};

		return {
			lRAdviser: getAdviser('lRAdviser'),
			mortgageAdviser: getAdviser('mortgageAdviser'),
			fGAdviser: getAdviser('fGAdviser'),
			kSAdviser: getAdviser('kSAdviser'),
			investmentAdviser: getAdviser('investmentAdviser'),
			gIAdviser: getAdviser('gIAdviser'),
		};
	}

	static getAdviserReworkChoices(
		adviserId: number,
		allStaff: BLStaff[]
	): ViewDisplayValue[] {
		const staffDetails = allStaff?.find((x) => +x?.StaffID === adviserId);
		const adviserServices = staffDetails?.StaffSettings?.AdviserServices;
		const adviserSettings = !!staffDetails
			? (!!staffDetails?.Services
				? JSON.parse(staffDetails?.Services?.toString())
				: [])
			: [];

		if (!adviserServices) {
			return [];
		}
		const availableServices = JSON.parse(adviserServices || '[]') || [];
		return availableServices
			?.map((service) => {
				return {
					display: service,
					value: service,
				};
			})
			?.filter((x, _i, _arr) => {
				// TAPNZ-13977 Group Insurance - only shows if L&R turned on for the
				const a = !(
					x?.value === 'Group Insurance' &&
					adviserSettings?.find((a) => a?.value === 'LR')
				);
				return a;
			});
	}
}
