import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CurrentActivityCriteriaState } from '../models/current-activity-criteria/current-activity-criteria.model';
import { ServicesCodes } from '../models/services/services.model';
import { AdviceProcessCode } from '@shared/models/advice-process/advice-process.model';

@Component({
  selector: 'app-table-criteria',
  templateUrl: './table-criteria.component.html',
  styleUrls: ['./table-criteria.component.scss'],
})
export class TableCriteriaComponent {
  @Input() criterias: any;
	@Input() formID: any;
  @Output() selectedCriteriaEvent = new EventEmitter<any>();

	apCodes = AdviceProcessCode;

  isAdviceProcess(sc: string){
    return sc.startsWith(ServicesCodes.AdviceProcess);
  }

  criteria(item: CurrentActivityCriteriaState) {
    this.selectedCriteriaEvent.emit(item);
  }

}
