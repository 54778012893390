import { Moment } from 'moment';
import { Note } from '../../notes/note.model';

export interface FgCustomerService {
	PolicyOwners: string;
	PolicyLines: string;
	Premium: number;
	Status: string;
	ClaimStatus: string;
	FGPolicyNumber: string;
	BrokerFee: number;
	RenewalDate: string | Moment;
	AccountStatus: string;
	Insurer: string;
	TotalPremium: number;
	InceptionDate: string | Moment;
	PolicyType: string;
	PolicyWriter: string;
	QuoteNumber: string | number;
	PolicyEndDate: string | Moment;
	FirstPolicyDate: string | Moment;
	FixedPeriodEnd: string | Moment;
	OriginalAdviser: number;
	Required: string;
	FGStatusClock: string | Moment;
	FGPolicyNumberSuffix: string;
	CustomerServiceID: number;
	CustomerID: number;
	ServiceCode: string;
	IsActive: number;
	CreateDateTime?: string | Moment;
	CreatedByStaffId?: number;
	ModifiedDateTime: string | Moment;
	ModifiedByStaffId: number;
	CreatedByStaffLevel: number;
	ImportID?: string;
	Notes: Note[];
	DocumentLink: string;
	Color: string;
	SourceId?: number;
	PaymentMethod?: string;
	Frequency?: string;
	RenewalPolicyNumber?: string;
}

export interface SampleCustomerService {
	key: string;
	value: string;
}

export interface FgCustomerServiceState {
	policyOwners: string;
	policyLines: string;
	premium: number;
	status: string;
	claimStatus: string;
	fGPolicyNumber: string;
	brokerFee: number;
	renewalDate: string | Moment;
	accountStatus: string;
	insurer: string;
	totalPremium: number;
	inceptionDate: string | Moment;
	policyType: string;
	policyWriter: string;
	quoteNumber: string | number;
	policyEndDate: string | Moment;
	firstPolicyDate: string | Moment;
	fixedPeriodEnd: string | Moment;
	originalAdviser: string | number;
	required: string;
	fGStatusClock: string | Moment;
	fGPolicyNumberSuffix: string;
	customerServiceID?: number;
	customerID: number;
	serviceCode?: string;
	isActive?: number;
	createDateTime?: string | Moment;
	createdByStaffId?: number;
	modifiedDateTime?: string | Moment;
	modifiedByStaffId?: number;
	createdByStaffLevel?: number;
	importID?: string;
	note?: string;
	notes?: unknown[];
	documentLink?: string;
	color?: string;
	tracking?: string;
	sourceId?: number;
	adminFee?: number;
	issueDate?: string | Moment;
	paymentMethod?: string;
	frequency?: string;
	renewalPolicyNumber?: string;
}

export interface FgCustomerServiceGroupState {
	aPI?: number;
	customerServices?: FgCustomerServiceState[];
	provider: string;
	policyNumber: string;
	startDate?: string | Moment;
	color?: string;
	linkDocument?: string;
	firstPolicyDate?: string | Moment;
	// Sorting
	status?: string;
	tracking?: string;
	sourceId?: number;
	isActive?: number;
	renewalPolicyNumber?: string;
}

export const accountStatusNoRenewal = [
	'Overdue 14+',
	'Overdue 30+',
	'Overdue 60+',
	'Funding Arrears',
	'Funding Arrears 14+',
];
