import { Injectable } from '@angular/core';
import { BusinessInternalServices } from '@domain/business-config/business-config.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	AdviceProcess,
	ServiceAdviceProcess,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { BusinessPeopleTypes } from 'src/app/shared/models/business-profile/business/business.model';
import {
	AssetCustomerServiceState,
	LiabilityCustomerServiceState,
} from 'src/app/shared/models/services/assets/assets';
import { InvestmentState } from 'src/app/shared/models/services/investments/investments.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { objectUtil } from 'src/app/util/util';
import { ActivityTimeline } from '../../shared/models/activity-timeline/activity-timeline.model';
import { CurrentActivityCriteria } from '../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { DocumentGroup } from '../../shared/models/documents/document-group.model';
import { EditHistory } from '../../shared/models/history/history.model';
import {
	AddNote,
	GetNotes,
	NoteResponse,
} from '../../shared/models/notes/note-params.model';
import { FgCustomerServiceState } from '../../shared/models/services/fg-insurance/fg-insurance.model';
import { KiwisaverState } from '../../shared/models/services/kiwisaver/kiwisaver.model';
import { LrCustomerServiceState } from '../../shared/models/services/lr-insurance/lr-insurance.model';
import { LrInsurance } from '../../shared/models/services/lr-insurance/lr-provider-group.model';
import { Mortgage } from '../../shared/models/services/mortgage/mortgage-group.model';
import { MortgageCustomerServiceState } from '../../shared/models/services/mortgage/mortgage.model';
import { PropertyAssetCustomerServiceState } from '../../shared/models/services/property-asset/property-asset';
import { Business } from '../../shared/models/_general/business';
import { ApiService, JsonResultStatus } from '../base/api.service';

@Injectable()
export class BusinessService {
	loatv2Enabled$ = this.query.select(
		({ config }) => config.OAT?.includes(BusinessInternalServices.LoatV2)
	);
	moatv2Enabled$ = this.query.select(
		({ config }) => config.OAT?.includes(BusinessInternalServices.MoatV2)
	);

	constructor(
		private api: ApiService,
		private query: BusinessConfigQuery
	) {}

	getBusinesses(): Observable<Business[]> {
		const endpoint = `Business/BusinessList`;
		return this.api.post2<string>(endpoint).pipe(
			map<string, Business[]>((x) => {
				const parsed = JSON.parse(x);
				const businessList: Business[] = JSON.parse(x);
				parsed
					?.map((business) => JSON.parse(business.services))
					?.forEach((servicesArray, i) => {
						businessList[i].services = servicesArray;
					});
				return businessList;
			})
		);
	}

	GetPrimaryCompany(id: number) {
		const endpoint = `contacts/${id}`;
		return this.api.get<any>(endpoint);
	}

	AddCompany(company: any) {
		const endpoint = 'contacts';
		const body = objectUtil.mapCamelCaseToPascalCase(company);
		return this.api.post3<JsonResultStatus>(endpoint, body);
	}

	UpdateCompany(company: any) {
		const endpoint = `contacts/${company.customerID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(company);
		return this.api.put<JsonResultStatus>(endpoint, body);
	}

	ConvertCompany(request) {
		const endpoint = `contacts/${request.customerID}/convert`;
		const body = objectUtil.mapCamelCaseToPascalCase(request);
		return this.api.put<any>(endpoint, body);
	}

	GetDirectors(id: number) {
		const endpoint = `contacts/${id}/company-members?type=${BusinessPeopleTypes.Director?.toLowerCase()}`;
		return this.api.get<any>(endpoint);
	}

	GetShareholders(id: number) {
		const endpoint = `contacts/${id}/company-members?type=${BusinessPeopleTypes.Shareholder?.toLowerCase()}`;
		return this.api.get<any>(endpoint);
	}

	GetOthers(id: number) {
		const endpoint = `contacts/${id}/company-members?type=${BusinessPeopleTypes.Others?.toLowerCase()}`;
		return this.api.get<any>(endpoint);
	}

	UpdateLinkedMembers(req) {
		const endpoint = `contacts/${req.customerId}/company-members`;
		const body = {
			CustomerID: req.customerId,
			Directors: req.directors,
			Others: req.others,
			Shareholders: req.shareholders,
		};
		return this.api.put<JsonResultStatus>(endpoint, body);
	}

	ArchiveService(req: { set_archive: boolean; customerServiceID: number }) {
		const endpoint = `Customerservice/ArchiveService`;
		const body = {
			set_archive: req.set_archive,
			customerservice_id: req.customerServiceID,
		};
		return this.api.put<JsonResultStatus>(endpoint, body);
	}

	// Get notes
	GetNotes(request: GetNotes) {
		const endpoint = `contacts/${request.CustomerID}/notes?pageNumber=${request.PageNumber}&pageSize=${request.PageSize}`;
		return this.api.get<NoteResponse>(endpoint);
	}
	// Add note
	AddNote(request: AddNote) {
		const endpoint = 'notes';
		const body = objectUtil.mapCamelCaseToPascalCase(request);
		return this.api.post3<any>(endpoint, body);
	}

	DeleteNote(id: number, isAp?: boolean) {
		const endpoint = !isAp ? `notes/${id}` : `/notes/${id}/crt`;
		return this.api.delete(endpoint);
	}

	PinNote = (noteId: number, pin: boolean, isAp?: boolean) => {
		const endpoint = !isAp
			? `notes/pin/${noteId}/${pin}`
			: `notes/pin/${noteId}?type=crt&pin=${pin}`;
		const body = null;
		return this.api.put<any>(endpoint, body);
	};

	UpsertDocument(req) {
		const endpoint = `services/${req.CustomerServiceId}?IsPatch=true`;
		const body = req;
		return this.api.put<any>(endpoint, body);
	}

	// LR
	GetLrInsurancesByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.LR?.toLowerCase()}&isGrouped=true`;
		return this.api.get<LrInsurance>(endpoint);
	}

	AddLrInsurance(lr: LrCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(lr);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateLrInsurance(lr: LrCustomerServiceState) {
		const endpoint = `services/${lr.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(lr);
		return this.api.put<any>(endpoint, body);
	}

	DeactivateService(id: number) {
		const endpoint = `services/${id}`;
		return this.api.delete<any>(endpoint);
	}

	GetDocumentsClientId(id: number) {
		const endpoint = `contacts/${id}/documents?isGrouped=true`;
		return this.api.get<DocumentGroup>(endpoint);
	}

	GetActivityTimeline(id: number) {
		const endpoint = `contacts/${id}/activity-timeline`;
		return this.api.get<ActivityTimeline>(endpoint);
	}

	TransferDocument(req) {
		const endpoint = `documents/${req.Id}`;
		return this.api.put<any>(endpoint, req);
	}

	UploadDocument(req) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	UpdateDocument(req) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<any>(endpoint, body);
	}

	GetFile(id: number) {
		return this.api.get<any>(`documents/${id}`);
	}

	GetDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	DeactivateDocument(id) {
		const endpoint = `documents/${id}`;
		return this.api.delete<any>(endpoint);
	}

	GetHistoriesClientId(id: number) {
		const endpoint = `contacts/${id}/notes/0/history`;
		return this.api.get<EditHistory[]>(endpoint);
	}

	GetCriteriaClientId(id: number) {
		const endpoint = `contacts/${id}/services/service-timeline`;
		return this.api.get<CurrentActivityCriteria[]>(endpoint);
	}

	// Mortage
	GetMortgagesByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Mortgage?.toLowerCase()}&isGrouped=true`;
		return this.api.get<Mortgage>(endpoint);
	}

	AddMortgage(m: MortgageCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateMortgage(m: MortgageCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	// Change data
	ArchiveMortgage(req: { set_archive: boolean; customerServiceID: number }) {
		const endpoint = `Customerservice/ArchiveService`;
		const body = {
			set_archive: req.set_archive,
			customerservice_id: req.customerServiceID,
		};
		return this.api.put<JsonResultStatus>(endpoint, body);
	}

	// Security
	GetSecuritiesByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Security?.toLowerCase()}&isGrouped=true`;
		return this.api.get<any>(endpoint);
	}

	AddSecurity(m: PropertyAssetCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateSecurity(m: PropertyAssetCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	// Assets
	GetAssetsByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Asset?.toLowerCase()}&isGrouped=true`;
		return this.api.get<any>(endpoint);
	}

	AddAsset(m: AssetCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateAsset(m: AssetCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	// Assets
	GetLiabilityByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services/${ServicesCodes.Liability?.toLowerCase()}/true`;
		return this.api.get<any>(endpoint);
	}

	AddLiability(liability: LiabilityCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(liability);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateLiability(liability: LiabilityCustomerServiceState) {
		const endpoint = `services/${liability.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(liability);
		return this.api.put2<any>(endpoint, body);
	}

	GetFgInsurancesByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.FG?.toLowerCase()}&isGrouped=true`;
		return this.api.get<any>(endpoint);
	}

	AddFgInsurance(m: FgCustomerServiceState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateFgInsurance(m: FgCustomerServiceState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	GetKiwisaversByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.KiwiSaver?.toLowerCase()}`;
		return this.api.get<any[]>(endpoint);
	}

	AddKiwisaver(m: KiwisaverState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateKiwisaver(m: KiwisaverState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	GetInvestmentsByPrimaryClientId(id: number) {
		const endpoint = `contacts/${id}/services?type=${ServicesCodes.Investment?.toLowerCase()}`;
		return this.api.get<any[]>(endpoint);
	}

	AddInvestments(m: InvestmentState) {
		const endpoint = `services`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.post3<any>(endpoint, body);
	}

	UpdateInvestments(m: InvestmentState) {
		const endpoint = `services/${m.customerServiceID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(m);
		return this.api.put<any>(endpoint, body);
	}

	convert = (request: ConvertCustomerModel) =>
		this.api.post3<JsonResultStatus>('Customer/ConvertCustomer', request);
	AddPhoto(req: AddPhotoRequest) {
		const endpoint = 'Customer/UploadCustomerPhoto';
		return this.api.put(endpoint, req);
	}

	/**
	 * Get Advice Processes
	 * @param id CustomerID
	 */
	GetAdviceProcessesByPrimaryId(
		id: number,
		params?: any
	): Observable<AdviceProcess> {
		const endpoint = `contacts/${id}/adviceprocesses/1`;
		return this.api.get<AdviceProcess>(endpoint, params);
	}

	/**
	 * Add Advice Process
	 * @param req AdviceProcess
	 */
	AddAdviceProcess(req: ServiceAdviceProcess) {
		const endpoint = `adviceprocesses`;
		return this.api.post3<AdviceProcess>(endpoint, req);
	}

	/**
	 * Update Advice Process
	 * @param req AdviceProcess
	 */
	UpdateAdviceProcess(req: ServiceAdviceProcess) {
		const endpoint = `adviceprocesses/${req?.AdviceProcessID}`;
		return this.api.put<AdviceProcess>(endpoint, req);
	}

	/**
	 * Delete Advice Process
	 * @param id AdviceProcessID
	 */
	DeleteAdviceProcess(id: number) {
		const endpoint = `adviceprocesses/${id}`;
		return this.api.delete<ServiceAdviceProcess>(endpoint);
	}

	UpdateRenewal(
		customerID: number,
		data: { reference: string; referenceNo: string; sourceId: number }
	) {
		const endpoint = `contacts/${customerID}/customer-services/${data.sourceId}/renew`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<any>(endpoint, body);
	}
}

export interface ConvertCustomerModel {
	CustomerId: number;
	ContactStatus: string;
	Note: string;
	LostType: string;
}

export interface AddPhotoRequest {
	CustomerId: number;
	Photo: string | ArrayBuffer;
	FileName?: string;
	PhotoURL?: string;
}
