<div class="mb-4">
	<app-user-pd-tracking-widgets
		[scheduledPd]="scheduledPd"
		[completedPd]="completedPd"
	></app-user-pd-tracking-widgets>
</div>
<div class="mb-4 mt-2">
	<app-user-pd-tracking-scheduled
		[staff]="staff"
		[isTapLevel]="isTapLevel"
		[userSecurityGroup]="userSecurityGroup"
		[data]="scheduledPd"
		[loggedUserId]="loggedUserId"
		[UPDTP]="UPDTP"
		[UPDTC]="UPDTC"
		[UPDTS]="UPDTS"
		[upsertPdTrackingFn$]="upsertPdTrackingFn$"
		[downloadDocumentFn$]="downloadDocumentFn$"
	></app-user-pd-tracking-scheduled>
</div>
<div class="mb-4">
	<app-user-pd-tracking-completed
		[staff]="staff"
		[isTapLevel]="isTapLevel"
		[userSecurityGroup]="userSecurityGroup"
		[data]="completedPd"
		[loggedUserId]="loggedUserId"
		[UPDTP]="UPDTP"
		[UPDTC]="UPDTC"
		[UPDTS]="UPDTS"
		[upsertPdTrackingFn$]="upsertPdTrackingFn$"
		[downloadDocumentFn$]="downloadDocumentFn$"
	></app-user-pd-tracking-completed>
</div>
