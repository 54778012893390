import { FactFindComputationDetails, FactFindComputationState } from 'src/app/shared/models/client-review-template/income-budget/factfind-computation.model';
import { PropertyBase, PropertyInfo, PropertyStateBase } from '../../../../../../../../shared/models/client-review-template/assets-liabilities/property/property.model';

export interface Property extends PropertyBase {
	FactFindComputation?: FactFindComputationDetails;
}

export interface PropertyState extends PropertyStateBase {
	factFindComputation?: FactFindComputationState;
}

export enum PropertyStatus {
	Current = 'Current'
}