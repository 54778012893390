import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DependantState } from './dependants.model';
import { DependantsStore } from './dependants.store';

@Injectable()
export class DependantsQuery extends QueryEntity<DependantState[]> {
	dependants$ = this.selectAll();

	constructor(protected store: DependantsStore) {
		super(store);
	}
}
