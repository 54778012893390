import { StoreConfig, Store } from '@datorama/akita';
import { Metakey } from '../lead-search-page/lead-search-datatable.config';
import produce from 'immer';
import { Injectable } from "@angular/core";

/** Lead ui state */
export interface LeadSearchUiState {
  /** if search page is currently fetching data */
  isSearching: boolean;
  /** if select / checked all rows */
  isSelectAll: boolean;
  /** if search page is currently exporting data */
  isExporting: boolean;
  /** if column form is open or closed */
  columnFormPopupOpen: boolean;
  /** if column form is currently saving */
  isColumnSaving: boolean;
  /** which property is currently being sorted. use propname */
  propSort: string;
  /** sort direction */
  sort: 'asc' | 'desc' | '';
  /** propSort and sort direction */
  currentSort: any;
  

  /** edit status of cell. key is id and propname */
  cellEditStatus: Record<string, boolean>;
  /** loading status of cell. key is id and propname */
  cellLoadingStatus: Record<string, boolean>;
  /** temp value of cell */
  cellTempValue: Record<string, any>;
  /** loading status of row */
  rowLoadingStatus: { [key: number]: boolean };
}

function createInitialUiState(): LeadSearchUiState {
  return {
    isSearching: false,
    isSelectAll: false,
    isExporting: false,
    columnFormPopupOpen: false,
    propSort: '',
    sort: '',
    isColumnSaving: false,
    cellEditStatus: {},
    cellLoadingStatus: {},
    cellTempValue: {},
    rowLoadingStatus: {},
    currentSort: {}
  };
}

@Injectable()
@StoreConfig({ name: 'leadSearchUi' })
export class LeadSearchUiStore extends Store<LeadSearchUiState> {
  constructor() {
    super(createInitialUiState());
  }

  setEdit(customerId: number, metakey: Metakey, edit: boolean) {
    const key = `${customerId}-${metakey}`;

    this.update((state) => ({
      cellEditStatus: { ...state.cellEditStatus, [key]: edit }
    }));
  }

  setLoad(customerId: number, metakey: Metakey, loading: boolean) {
    const key = `${customerId}-${metakey}`;
    this.update(state => ({
      cellLoadingStatus: { ...state.cellLoadingStatus, [key]: loading }
    }));
  }

  setIsSearching(isSearching: boolean) {
    this.update(produce(draft => {
      draft.isSearching = isSearching;
    }));
  }

  setIsSelectAll(isSelectAll: boolean) {
    this.update(produce(draft => {
      draft.isSelectAll = isSelectAll;
    }));
  }

  setIsExporting(isExporting: boolean) {
    this.update(produce(draft => {
      draft.isExporting = isExporting;
    }));
  }

  toggleColumnPopup(open: boolean) {
    this.update(produce(draft => {
      draft.columnFormPopupOpen = open;
    }));
  }

  setIsColumnSaving(isSaving: boolean) {
    this.update(produce(draft => {
      draft.isColumnSaving = isSaving;
    }));
  }

  setIsDeleting(customerId: number, isDeleting: boolean) {
    const key = customerId;
    this.update(state => ({
      rowLoadingStatus: { ...state.rowLoadingStatus, [key]: isDeleting }
    }));
  }

  setSort(propSort: string, sort: 'asc' | 'desc') {
    this.update(produce(draft => {
      draft.propSort = propSort;
      draft.sort = sort;
      draft.currentSort = {
        propSort,
        sort
      };
    }));
  }

  setTempValue(customerId: number, metakey: string, value: any) {
    const key = `${customerId}-${metakey}`;
    this.update(state => ({
      cellTempValue: { ...state.cellTempValue, [key]: value }
    }));
  }
}
