import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
	combineLatest,
	delay,
	filter,
	map,
	startWith,
	takeUntil
} from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/base/component-base';
import { HorizontalBarChartComponent } from 'src/app/shared/horizontal-bar-chart/horizontal-bar-chart.component';
import { DateRange } from '../../../../../shared/date-range/date-range.component';
import MomentUtil from '../../../../../util/moment.util';
import { numUtil } from '../../../../../util/util';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { DashboardQuery } from '../../state/dashboard.query';
import { MOQuery } from './state/mo.query';
import { MOService } from './state/mo.service';

@Component({
	selector: 'app-mo',
	templateUrl: './mo.component.html',
	styleUrls: ['./mo.component.scss'],
})
export class MoComponent extends ComponentBase implements OnInit, OnDestroy {
	public static widgetCode = 'mo';
	public static widgetName = 'Mortgage Opportunity';
	public static sizeX = 480;
	public static sizeY = 480;
	public static minSizeX = 480;
	public static minSizeY = 480;
	public static maxSizeX = 480;
	public static maxSizeY = 480;

	private subs: Subscription[] = [];

	widgetCode = MoComponent.widgetCode;
	widgetName = MoComponent.widgetName;

	moLegends = [
		{name: 'Settled', color: 'blueGreen'},
		{name: 'In progress', color: 'white'},
		{name: 'NTU', color: 'red'},
	];

	moConversion$ = this.query.data$.pipe(
		filter((x) => !!x),
		map((x) => x?.find((i) => i?.Type === 'M')),
		map((x) => ({
			...x,
			Data: [
				Math.round(x?.Settled) || 0,
				Math.round(x?.InProgress) || 0,
				Math.round(x?.Ntu) || 0,
			],
			Tooltip: [
				`Settled (${x?.SettledCount || 0})`,
				`In Progress (${x?.InProgressCount || 0})`,
				`NTU (${x?.NtuCount || 0})`,
			],
			Target: Math.round(x?.Target || 0),
		}))
	);

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.isLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.error$;

	/** form control for date range.
	 * Should default to 1 week behind up to now.
	 */
	dateControl: UntypedFormControl = new UntypedFormControl({
		min: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().subtract(7, 'months')
		),
		max: MomentUtil.formatToServerDate(
			MomentUtil.createMomentNz().subtract(1, 'months')
		),
	} as DateRange);

	@ViewChild(HorizontalBarChartComponent)
	hbarChart: HorizontalBarChartComponent;

	constructor(
		private service: MOService,
		private query: MOQuery,
		private dashboardQuery: DashboardQuery,
		private dashboard: DashboardComponent
	) {
		super();
	}

	ngOnInit() {
		this.subs.push(
			this.dashboardQuery.adviserFilter$
				.pipe(
					filter((x) => x.length > 0),
					combineLatest(
						this.dateControl.valueChanges.pipe(
							startWith(this.dateControl.value)
						)
					),
					takeUntil(this.onDestroy$)
				)
				.subscribe(([advisers, dateRange]) => {
					this.service.GetMortgageOpportunity({
						advisers,
						dateRangeMin: dateRange.min,
						dateRangeMax: dateRange.max,
					});
				})
		);

		this.subs.push(
			this.dashboard.gridChangeEvent.pipe(delay(200)).subscribe((res) => {
				if (res === MoComponent.widgetCode) {
					this.hbarChart.refresh();
				}
			})
		);
	}

	fortmatToCount(num: number) {
		return numUtil.formatToCount(num);
	}

	ngOnDestroy() {
		this.subs?.forEach((x) => x.unsubscribe);
		this.subs = [];
		super.dispose();
	}
}
