import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddDocumentModalComponent } from './add-document-modal.component';
import { AddDocumentModalService } from './add-document-modal.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSelectModule } from '../input-select/input-select.module';

@NgModule({
	exports: [AddDocumentModalComponent],
	declarations: [AddDocumentModalComponent],
	imports: [CommonModule, ModalModule, ReactiveFormsModule, FormsModule, InputSelectModule],
	providers: [AddDocumentModalService],
})
export class AddDocumentModalModule {}
