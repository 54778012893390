<!-- Former name: Lead Conversion -->
<app-secondary-base-widget
	[headerText]="widgetName"
	[widgetCode]="widgetCode"
	class="nb-conversion"
>
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<app-date-range
		ngProjectAs="input"
		[formControl]="dateControl"
	></app-date-range>

	<div *ngIf="!(isLoading$ | async)" class="nb-conversion-widget">
		<!-- L&R Opportunities -->
		<div class="form-row">
			<div class="col-8">
				<label class="mt-2 conversion-name">Life & Risk</label>
			</div>
			<div class="col-4 text-right pr-3">
				<label class="mt-2 conversion-name">Opportunities</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart #hbarChart
					[barDataArr]="(lrOpportunity$ | async)?.Data"
					[barDataArrTooltip]="(lrOpportunity$ | async)?.Tooltip || []"
					[targetValue]="(lrOpportunity$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(lrOpportunity$ | async)?.Total || 0
				}}</span>
			</div>
		</div>
		<div class="form-row legend-container px-1 pt-3 pb-4">
			<ng-container  *ngFor="let legend of lrLegends">
				<div class="legend" [ngStyle]="{'background': hbarChart.barColorList[legend.color]}"></div>
				<label class="mx-2 legend-name">{{legend.name}}</label>
			</ng-container>
		</div>

		<!-- KiwiSaver -->
		<div class="form-row mt-4">
			<div class="col-12 col-lg-8">
				<label class="mt-2 conversion-name">KiwiSaver</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart #hbarChart
					[barDataArr]="(kiwiSaver$ | async)?.Data"
					[barDataArrTooltip]="(kiwiSaver$ | async)?.Tooltip || []"
					[targetValue]="(kiwiSaver$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(kiwiSaver$ | async)?.Total || 0
				}}</span>
			</div>
		</div>
		<div class="form-row legend-container px-1 py-3">
			<ng-container  *ngFor="let legend of kLegends">
				<div class="legend" [ngStyle]="{'background': hbarChart.barColorList[legend.color]}"></div>
				<label class="mx-2 legend-name">{{legend.name}}</label>
			</ng-container>
		</div>
	</div>
</app-secondary-base-widget>
