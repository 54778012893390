import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PurposeDetailsState } from './purpose.model';

export interface PurposeState extends EntityState<PurposeDetailsState> {}

@Injectable()
@StoreConfig({ name: 'purpose', idKey: 'parentCRTId' })
export class PurposeStore extends EntityStore<PurposeState, PurposeDetailsState> {
	constructor() {
		super();
	}
}
