import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { map as Rmap, isNil, sum } from 'ramda';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	mapTo,
	take,
	tap,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import {
	AdviceProcessSectionCodes,
	AssetsSubSectionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { KiwisaverState } from 'src/app/shared/models/services/kiwisaver/kiwisaver.model';
import { objectUtil } from 'src/app/util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { ViewDisplayValue } from './../../../../../shared/models/_general/display-value.viewmodel';

const section = {
	FAP: 'properties',
	FAK: 'kiwiSavers',
	FAA: 'assets',
	FAM: 'mortgages',
	FAL: 'liabilities',
	FAO: 'others',
};
@Injectable()
export class AssetsLiabilitiesService
	extends ClientReviewTemplateService
	implements Resolve<boolean>
{
	readonly endpoint = 'crt';
	readonly section = 'fact-find';

	// List
	properties$ = this.query.properties$;
	kiwiSavers$ = this.query.kiwiSavers$;
	others$ = this.query.others$;
	mortgages$ = this.query.mortgages$;
	liabilities$ = this.query.liabilities$;
	propertyAddresses$ = this.query.propertyAddresses$;
	assets$ = this.query.assets$;

	// Computations
	totalPropertyValues$ = this.query.totalPropertyValues$;
	totalAssets = !!this.snapshot?.assets
		? sum(
				(!isNil(this.snapshot?.assets) ? this.snapshot?.assets : [])?.map(
					(x) => x.value ?? 0
				)
		  )
		: 0;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	/**
	 * Fetch Fact and Find - Assets and Liabilities
	 * @param apID Advice Process ID
	 * @param sectionCode FAP | FAK | FAO | FAM | FAL | ae
	 */
	getAssetLiabilities = (apID: number, sectionCode: string): Observable<[]> => {
		if (sectionCode === 'ae') {
			this.setIsLoading(true, 'FAP');
			this.setIsLoading(true, 'FAK');
			this.setIsLoading(true, 'FAA');
			this.setIsLoading(true, 'FAM');
			this.setIsLoading(true, 'FAL');
			this.setIsLoading(true, 'FAO');
		} else {
			this.setIsLoading(true, sectionCode);
		}

		const apiUrl =
			sectionCode === 'ae'
				? `${this.endpoint}/${this.section}/${apID}/group/${sectionCode}`
				: `${this.endpoint}/${this.section}/${apID}/${sectionCode}`;

		return this.api.get<[]>(apiUrl).pipe(
			map((x) =>
				sectionCode?.toLowerCase() !== 'ae'
					? (x?.map(objectUtil.mapPascalCaseToCamelCase) as [])
					: objectUtil.mapPascalCaseToCamelCase(x)
			),
			tap((x) =>
				applyTransaction(() => {
					if (sectionCode?.toLowerCase() === 'ae') {
						this.store.setAssetsAndLiabilities(x?.property || [], 'FAP');
						const ks = x?.kiwiSaver?.map((data: KiwisaverState) => {
							return {
								...data,
								fundOwner: data.fundOwner?.map((owner) => owner.toString()),
							};
						});
						this.store.setAssetsAndLiabilities(ks || [], 'FAK');
						this.store.setAssetsAndLiabilities(
							x?.assetsInvestments || [],
							'FAA'
						);
						this.store.setAssetsAndLiabilities(x?.mortgages || [], 'FAM');
						this.store.setAssetsAndLiabilities(x?.liabilities || [], 'FAL');
						this.store.setAssetsAndLiabilities(x?.others || [], 'FAO');
					} else if (sectionCode === AdviceProcessSectionCodes.KiwiSaver) {
						const parsedData = x?.map((data: KiwisaverState) => {
							return {
								...data,
								fundOwner: data.fundOwner?.map((owner) => owner.toString()),
							};
						});
						this.store.setAssetsAndLiabilities(parsedData, sectionCode);
					} else {
						this.store.setAssetsAndLiabilities(x, sectionCode);
					}
				})
			),
			finalize(() => {
				if (sectionCode === 'ae') {
					this.setIsLoading(false, 'FAP');
					this.setIsLoading(false, 'FAK');
					this.setIsLoading(false, 'FAA');
					this.setIsLoading(false, 'FAM');
					this.setIsLoading(false, 'FAL');
					this.setIsLoading(false, 'FAO');
				} else {
					this.setIsLoading(false, sectionCode);
				}
			}),
			catchError(() => of(undefined))
		);
	};

	setIsLoading(isLoading: boolean, sectionCode: string) {
		applyTransaction(() => {
			this.store.setAssetsAndLiabilitiesLoading(isLoading, sectionCode);
		});
	}

	addAL(data, sectionCode, isExisting?: boolean) {
		return this.api.post3(`${this.endpoint}`, data).pipe(
			concatMap((x) =>
				this.getAssetLiabilities(
					this.query.getValue().adviceProcessId,
					sectionCode
				)
			),
			catchError(() => EMPTY),
			finalize(() =>
				this.fetchCRMServices(sectionCode).pipe(take(1)).subscribe()
			)
		);
	}

	updateAL(data, sectionCode) {
		return this.api
			.put(
				`${this.endpoint}/${data.CRTId}`,
				objectUtil.mapCamelCaseToPascalCase(data)
			)
			.pipe(
				concatMap((x) =>
					this.getAssetLiabilities(
						this.query.getValue().adviceProcessId,
						sectionCode
					)
				),
				catchError(() => EMPTY),
				finalize(() => {
					this.fetchCRMServices(sectionCode).pipe(take(1)).subscribe();
					this.getCrmMortgages$(this.query.getValue().primaryClient.customerID)
						.pipe(take(1))
						.subscribe();
					this.getCrmLiabilities$(this.query.getValue().primaryClient.customerID)
						.pipe(take(1))
						.subscribe();
				})
			);
	}

	deleteAL(cRTId, sectionCode) {
		return this.api.delete(`${this.endpoint}/${cRTId}`).pipe(
			// tap(() =>
			// 	applyTransaction(() => {
			// 		const oldList = this.query.getValue()[section[sectionCode]];
			// 		const newList = oldList.filter((x) => x.cRTId !== cRTId);
			// 		this.store.setAssetsAndLiabilities(newList, sectionCode);
			// 	})
			// ),
			concatMap((x) =>
				this.getAssetLiabilities(
					this.query.getValue().adviceProcessId,
					sectionCode
				)
			),
			catchError(() => EMPTY),
			finalize(() =>
				this.fetchCRMServices(sectionCode).pipe(take(1)).subscribe()
			)
		);
	}

	getCrtById(cRTId) {
		return this.api.get(`crt/${cRTId}`).pipe(catchError(() => EMPTY));
	}

	setAdditionalQuestions(formValue) {
		applyTransaction(() => {
			const data = objectUtil.mapPascalCaseToCamelCase(formValue);
			const newArray = [{ ...data }];
			this.store.setHasFormChanges(true);
			this.store.setAssetsAndLiabilities(
				newArray,
				AdviceProcessSectionCodes.Others
			);
		});
	}

	fetchCRMServices(sectionCode) {
		if (sectionCode === AdviceProcessSectionCodes.Property) {
			return this.getPropertyAddresses$(
				this.query.getValue()?.primaryClient?.customerID,
				true
			);
		}
		if (sectionCode === AdviceProcessSectionCodes.KiwiSaver) {
			return this.getCrmKiwiSavers$(
				this.query.getValue()?.primaryClient?.customerID,
				true
			);
		}
		if (sectionCode === AdviceProcessSectionCodes.Mortgages) {
			return this.getCrmMortgages$(
				this.query.getValue()?.primaryClient?.customerID,
				true
			);
		}
		if (sectionCode === AdviceProcessSectionCodes.Liabilities) {
			return this.getCrmLiabilities$(
				this.query.getValue()?.primaryClient?.customerID,
				true
			);
		}
		return of(undefined);
	}

	getPropertyAddresses$ = (clientId?: number, force?: boolean) =>
		!this.query.getValue().propertyAddresses || force
			? this.customerService
					.GetSecuritiesByPrimaryClientId(
						clientId ?? this.query.getValue()?.primaryClient?.customerID
					)
					.pipe(
						map((x) => x.CustomerServices?.filter((p) => p.Status !== 'Sold')),
						tap((x) => this.store.setPropertyAddresses(x)),
						map((x: any) =>
							x.map((a) =>
								ViewDisplayValue.Map(a.CustomerServiceID, a.PropertyAddress)
							)
						),
						catchError((err) => of(undefined))
					)
			: of(undefined);

	getCrmKiwiSavers$ = (customerID: number, force?: boolean) =>
		!this.query.getValue().crmKiwiSavers || force
			? this.customerService.GetKiwisaversByPrimaryClientId(customerID).pipe(
					tap((x) => this.store.setCrmKiwiSavers(x)),
					catchError((error) => of(undefined))
			  )
			: of(undefined);

	getCrmMortgages$ = (customerID: number, force?: boolean) =>
		!this.query.getValue().crmMortgages || force
			? this.customerService
					.GetMortgagesByPrimaryClientId(customerID, false)
					.pipe(
						tap((x) => this.store.setCrmMortgages(x)),
						catchError((error) => of(undefined))
					)
			: of(undefined);

	getCrmLiabilities$ = (customerID: number, force?: boolean) =>
		!this.query.getValue().crmLiabilities || force
			? this.customerService
					.GetLiabilityByPrimaryClientId(customerID)
					.pipe(
						map(x => x.CustomerServices),
						tap((x) => this.store.setCrmLiabilities(x)),
						catchError((error) => of(undefined))
					)
			: of(undefined);

	getInfoByCRT(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<any>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => of([]))
		);
	}

	deleteState(cRTId, sectionCode) {
		applyTransaction(() => {
			const oldList = this.query.getValue()[section[sectionCode]];
			const newList = oldList?.filter((x) => x.cRTId !== cRTId);
			this.store.setAssetsAndLiabilities(newList, sectionCode);
		});
	}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		const getAssetsAndLiabilities$ = forkJoin(
			Rmap(
				(x) =>
					!!this.query.getValue()[section[x]]
						? of(undefined)
						: this.getAssetLiabilities(
								+route.paramMap.get('adviceProcessId'),
								x
						  ).pipe(catchError(() => of(undefined))),
				AssetsSubSectionCodes
			)
		);

		return forkJoin([
			getAssetsAndLiabilities$,
			this.getPropertyAddresses$(+route.paramMap.get('clientId')),
			this.getCrmKiwiSavers$(+route.paramMap.get('clientId')),
			this.getCrmMortgages$(+route.paramMap.get('clientId')),
			this.getCrmLiabilities$(+route.paramMap.get('clientId'))
		]).pipe(mapTo(true));
	}
}
