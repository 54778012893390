import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { ClientSearchColumnsFormComponent } from './client-search-columns-form/client-search-columns-form.component';
import { ClientSearchFormComponent } from './client-search-form/client-search-form.component';
import { ClientSearchPageComponent } from './client-search-page/client-search-page.component';
import { ClientSearchRoutingModule } from './client-search-routing.module';
import { ClientSearchResolver } from './client-search.resolver';
import { ClientSearchUiQuery } from './state/client-search-ui.query';
import { ClientSearchUiStore } from './state/client-search-ui.store';
import { ClientSearchQuery } from './state/client-search.query';
import { ClientSearchService } from './state/client-search.service';
import { ClientSearchStore } from './state/client-search.store';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ClientSearchRoutingModule,
  ],
  declarations: [ClientSearchPageComponent, ClientSearchFormComponent, ClientSearchColumnsFormComponent],
  providers: [
    ClientSearchUiStore,
    ClientSearchUiQuery,

    ClientSearchResolver,
    ClientSearchStore,
    ClientSearchQuery,
    ClientSearchService
  ]
})
export class ClientSearchModule { }
