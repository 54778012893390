import {
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ControlValueAccessor,
	FormsModule,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

/**
 * NOTE: that active background color will base on the host element color
 */
@Component({
	selector: 'app-input-switch',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './input-switch.component.html',
	styleUrls: ['./input-switch.component.scss'],
	host: {
		class: 'd-flex',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputSwitchComponent),
			multi: true,
		},
	],
})
export class InputSwitchComponent implements ControlValueAccessor {
	@Input() value = true;

	@Input() disabled = false;
	private _change: any;

	@HostBinding('style.height.px') get elHeight() {
		return this.height ?? null;
	}

	@HostBinding('style.width.px') get elWidth() {
		return this.width ?? null;
	}

	@HostBinding('class.disabled') get elDisabled() {
		return this.disabled;
	}

	@Input() height: number;

	@Input() width: number;

	constructor(private cdr: ChangeDetectorRef) {}

	writeValue(obj: any): void {
		this.value = obj;
		this.cdr.detectChanges();
	}

	registerOnChange(fn: any): void {
		this._change = fn;
	}

	registerOnTouched(fn: any): void {}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onValueChanged(el: HTMLInputElement): void {
		this.value = el.checked;
		this._change?.(this.value);
	}
}
