import secGroup from '../../../shared/models/_general/secGroup';

// tslint:disable-next-line: class-name
export class viewSecGroup {
  securityGroupID: number;
  securityGroupName: string;
  securityGroupCode: string;
  static create(id, name, code): viewSecGroup {
    return {
      securityGroupID: id,
      securityGroupName: name,
      securityGroupCode: code
    } as viewSecGroup;
  }
  static mapSecGroup(s: secGroup): viewSecGroup {
    return this.create(
      s.SecurityGroupID,
      s.SecurityGroupName,
      s.SecurityGroupCode
    );
  }
  // tslint:disable-next-line: no-shadowed-variable
  static mapSecGroups(secGroup: secGroup[]): viewSecGroup[] {
    return secGroup?.map(s =>
      this.mapSecGroup(s)
    );
  }
}
