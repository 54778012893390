<app-secondary-base-widget headerText="F&G Premium Stats - Rolling 12 Months - Lead Type" [widgetCode]="widgetCode">
  <app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
  <div
    class="d-flex"
    ngProjectAs="input"
  >
    <form class="d-flex lead-type-filters" [formGroup]="form">
      <label for="see-all-checkbox" class="d-flex">
        <span class="pr-2 tap-text-color">See All</span>
      </label>
      <input
        type="checkbox"
        id="see-all-checkbox"
        class="chckbox"
        [checked]="isAllSelected"
        formControlName="selectAllLeadType"
      />
      <div
        class="pl-2 lead-type-multiselect"
      >
        <app-chips
          formControlName="leadTypes"
          [choices]="PCLT$ | async"
        ></app-chips>
      </div>
    </form>
  </div>

  <div class="position-relative clearfix h-100 px-0 fgpslt-chart">
    <app-bipolar-bar-chart #fgpsltBipolarBarChart [data]="viewData$ | async" [showCurrencySignAxisY]="true" [chartId]="chartId" [displayAdditionalData]="true"></app-bipolar-bar-chart>
  </div>
</app-secondary-base-widget>
