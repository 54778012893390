import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { objectUtil } from '../../../../../util/util';
import {
  DeclarationSettingState,
  DocumentUploadState,
} from './declaration-template-settings.model';
import { DocumentModelState } from '../../../../../shared/models/documents/document.model';
import { KiwiSaverSettingsStore } from '@modules/kiwisaver-settings/state/kiwisaver-settings.store';
import { KiwiSaverSettingsQuery } from '@modules/kiwisaver-settings/state/kiwisaver-settings.query';

@Injectable()
export class DeclarationTemplateSettingsService {
  declarationDocuments$ = this.query.declaration$;

  constructor(
    private api: ApiService,
    private store: KiwiSaverSettingsStore,
    private query: KiwiSaverSettingsQuery
  ) {}

  clear(): void {
    applyTransaction(() => {
      this.store.reset();
    });
  }

  getDeclarationDocument(data: number, settingsCode: string) {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<DeclarationSettingState>(endpoint).pipe(
      tap((res) =>
        applyTransaction(() => {
          const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
          this.store.setDeclarationSettings(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateDeclaration(data: DeclarationSettingState) {
    const endpoint = `crt/settings/${data.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(data);
    return this.api.put<DeclarationSettingState>(endpoint, body).pipe(
      tap((res) =>
        applyTransaction(() => {
          this.store.setDeclarationSettings(res);
        })
      )
    );
  }

  newFileUploadDD(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUploadDD(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getDeclarationDocumentFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
