import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LifeAssuredListState, CurrentInsuranceState } from '../../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { CurrentInsuranceService } from '../../../../../modules/crm/client-review-template/states/current-insurance/current-insurance.service';
import { DropdownValue } from '../../../../../modules/dropdown/model/dropdownValue';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { ProposedInsuranceState as SoaProposedInsuranceState } from '@shared/models/client-review-template/statement-of-advice/insurance/soa-insurance.model';
import { ProposedInsuranceState } from '@shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';

@Component({
	selector: 'app-current-insurance-modal',
	templateUrl: './current-insurance-modal.component.html',
	styleUrls: ['./current-insurance-modal.component.scss'],
})
export class CurrentInsuranceModalComponent
	implements ConfirmModalModel, OnInit
{
	savefn: (
		model: any
	) => Observable<
		CurrentInsuranceState | ProposedInsuranceState | SoaProposedInsuranceState
	>;
	uploadDocfn: (model: any) => Observable<any>;
	public onClose: Subject<string>;
	confirm$: Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	dropdownCodes: DropdownValue[];
	docType: string;
	isSingleUpload: boolean;
	isProposedInsurance: boolean;
	isLoading: boolean;
	defaultPaymentFrequency: string;
	enableLinkDocument: boolean;
  restrictFileTypes: string;
	allowedFileExtensions: string[];
  viewMode: boolean;
  enableAiQuote: boolean;
	currentInsuranceInfo: any;
	defaultNewExistingValue: string;
	primaryClient: any;
  adviceProcessId: number;
	clientId: number;
	isAdviceProcessEnded$: Observable<boolean>;
	public lrp$: Observable<ViewDisplayValue[]>;
	public lrpr$: Observable<ViewDisplayValue[]>;
	public apcrtf$: Observable<ViewDisplayValue[]>;
	public apcrtme$: Observable<ViewDisplayValue[]>;
	public apcrtb$: Observable<ViewDisplayValue[]>;
	public apcrtne$: Observable<ViewDisplayValue[]>;
	public policyOwners$: Observable<ViewDisplayValue[]>;
	public lifeAssuredList$: Observable<ViewDisplayValue[]>;

	getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	currentInsuranceLifeAssured$: Observable<LifeAssuredListState[]>;
	hideNotes: boolean;

	isAddProductCar: boolean = false;

	constructor(
		public bsModalRef: BsModalRef,
		public service: CurrentInsuranceService
	) {
		this.setupProps();
		this.setupSubs();
	}

	ngOnInit() {}

	setupProps() {
		this.header = 'Confirm';
		this.message = 'Confirm?';
	}
	setupSubs() {}

	continue() {
		this.confirm$.subscribe();
		this.bsModalRef.hide();
	}
	decline() {
		if (!!this.decline$) {
			this.decline$.subscribe();
		}
		this.bsModalRef.hide();
	}

	saveChange(value: CurrentInsuranceState) {
		this.isLoading = true;
		this.savefn(value)
			.pipe(
				finalize(() => {
					this.bsModalRef.hide();
					this.isLoading = false;
				})
			)
			.subscribe();
	}

	cancelChange(value) {
		this.decline$.subscribe();
		this.bsModalRef.hide();
	}
}
export interface ConfirmModalModel {
	confirm$: Observable<any>;
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	isSingleUpload?: boolean;
	enableLinkDocument?: boolean;
	hideNotes?: boolean;
}
