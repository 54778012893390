import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DocumentModelState } from '../../../../shared/models/documents/document.model';
import { ApiService } from '../../../../core/base/api.service';
import { objectUtil } from '../../../../util/util';
import { MortgageSettingsQuery } from '../../state/mortgage-settings.query';
import { MortgageSettingsStore } from '../../state/mortgage-settings.store';
import { DocumentUploadState, SoaSettingsState } from './soa-settings.model';
import { MergeTagsCrtSettingsService } from '../../merge-tags/state/merge-tags-crt-settings.service';
import { SettingsTypes } from '../../state/mortgage-settings.model';

@Injectable()
export class SoaSettingsService {
	statementOfAdvice$ = this.query.statementOfAdvice$;

	constructor(
		private api: ApiService,
		private store: MortgageSettingsStore,
		private query: MortgageSettingsQuery,
		private mtService: MergeTagsCrtSettingsService
	) {}

	getSoaSettings(refId: number, settingsCode: string) {
		const endpoint = `crt/settings/${refId}/${settingsCode}`;
		return this.api.get<SoaSettingsState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setSoaSettings(state);
					const mergeTag = state?.mergeTag?.map((i) => ({
						...i,
						secondaryValue: i?.value?.replace(/\n|\t/g, '<br>') || '' ,
						value: i?.value?.replace(/\n|\t/g, '<br>') || '' ,
					}));
					this.mtService.updateMtState(mergeTag || []);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateSoaSettings(data: SoaSettingsState) {
		const newValue = {
			...this.query.getValue().statementOfAdvice,
			...data,
		};
		const { templateLink, ...payload } = newValue;
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(payload);
		return this.api.put<SoaSettingsState>(endpoint, body).pipe(
			tap(() =>
				applyTransaction(() => {
					this.store.setSoaSettings(newValue);
				})
			)
		);
	}

	newFileUploadSOA(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUploadSOA(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getSoaDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
    if (!url) { return of(null); }
    return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getDefaultSoaTemplateUrl() {
		const endpoint = `crt/oat-template/${SettingsTypes.MOATDefaultTemplate}`;
		return this.api.get<string>(endpoint);
	}
}
