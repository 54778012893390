import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	EventEmitter,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import produce from 'immer';
import { uniq, either, isNil, isEmpty } from 'ramda';
import {
	BehaviorSubject,
	combineLatest,
	iif,
	Observable,
	of,
	Subject,
} from 'rxjs';
import {
	concatMap,
	debounceTime,
	delay,
	distinctUntilChanged,
	map,
	mergeMap,
	skip,
	skipWhile,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import {
	AdviceProcessPageCodes,
	AdviceProcessSubRoutes,
	AdviceProcessRoutes,
	AdviceProcessDocumentField,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MortgageState } from 'src/app/shared/models/client-review-template/assets-liabilities/mortgage/mortgage.model';
import { PropertyUse } from 'src/app/shared/models/client-review-template/assets-liabilities/property/property.model';
import MomentUtil from 'src/app/util/moment.util';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { ClientReviewTemplateState } from '../states/client-review-template.store';
import { LrAdviceprocessService } from '../states/lr-adviceprocess/lr-adviceprocess.service';
import { CriticalIllnessService } from '../states/risk-analysis/critical-illness/critical-illness.service';
import { DisabilityQuery } from '../states/risk-analysis/disability/disability.query';
import { DisabilityService } from '../states/risk-analysis/disability/disability.service';
import { LifeQuery } from '../states/risk-analysis/life/life.query';
import { LifeService } from '../states/risk-analysis/life/life.service';
import { MedicalQuery } from '../states/risk-analysis/medical/medical.query';
import { RiskAnalysisMedicalService } from '../states/risk-analysis/medical/medical.service';
import { RiskProfileService } from '../states/risk-analysis/risk-profile/risk-profile.service';
import { TpdQuery } from '../states/risk-analysis/tpd/tpd.query';
import { TpdService } from '../states/risk-analysis/tpd/tpd.service';
import { StatementOfAdviceQuery } from '../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../states/statement-of-advice/statement-of-advice.service';
declare var $: any;

@Component({
	selector: 'app-client-review-template-tabs',
	templateUrl: './client-review-template-tabs.component.html',
	styleUrls: ['./client-review-template-tabs.component.scss'],
})
export class ClientReviewTemplateTabsComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	@Input() currentTab: string;
	@Input() isCRTLoading: boolean;
	@Output() navigateToTab: EventEmitter<string> = new EventEmitter<string>();
	MENU_ITEM_SUCCESS = 'menu-item--success';
	MENU_ITEM_INCOMPLETE = 'menu-item--orange';
	MENU_ITEM_UNOPENED = 'menu-item--grey';
	private onDestroy$ = new Subject<void>();

	isRiskAnalysisOpen = true;
	isFactFindOpen = true;
	isTabOpen = false;
	innerWidth: number;
	isMobileMode: boolean;

	factFindPath = 'fact-find';
	riskAnalysisPath = 'risk-analysis';

	queryValue: ClientReviewTemplateState;
	colorCode = {
		intro: 'menu-item--grey',
		disclosure: 'menu-item--grey',
		scopeOfService: 'menu-item--grey',

		factFind: 'menu-item--grey',
		people: 'menu-item--grey',
		assetsLiabilities: 'menu-item--grey',
		incomeBudget: 'menu-item--grey',
		medicalHistory: 'menu-item--grey',
		currentInsurance: 'menu-item--grey',
		fg: 'menu-item--grey',

		riskAnalysis: 'menu-item--grey',
		goals: 'menu-item--grey',
		life: 'menu-item--grey',
		disability: 'menu-item--grey',
		tpd: 'menu-item--grey',
		criticalIllness: 'menu-item--grey',
		medical: 'menu-item--grey',
		riskProfile: 'menu-item--grey',

		declaration: 'menu-item--grey',
		statementOfAdvice: 'menu-item--grey',
		authorityToProceed: 'menu-item--grey',
		finalStructure: 'menu-item--grey',
	};

	isToggleSidebar = false;
	isCompleted$: Observable<boolean> = combineLatest([
		this.query.clientAcceptance$,
		this.query.finalStructure$,
		this.query.adviceProcess$,
	]).pipe(
		map(
			([ca, fs, adviceProcess]) =>
				!!ca &&
				!!adviceProcess?.documents?.some(
					(x) =>
						x.field === AdviceProcessDocumentField.AuthorityToProceed &&
						!!x?.value?.documentID
				) &&
				!!fs
		)
	);

	hasLife$: Observable<boolean> = this.query.hasLife$;
	hasDisability$: Observable<boolean> = this.query.hasDisability$;
	hasCriticalIllness$: Observable<boolean> = this.query.hasCriticalIllness$;
	hasTPD$: Observable<boolean> = this.query.hasTPD$;
	hasMedical$: Observable<boolean> = this.query.hasMedical$;
	hasHomeCarContentsInsurance$: Observable<boolean> = this.query.hasHomeCarContentsInsurance$;

	factFindColor$ = new BehaviorSubject<string>('menu-item--grey');
	factFindFGColor$ = new BehaviorSubject<string>('menu-item--grey');
	incomeSourceTooltip = 'Please add at least one income source.';
	incomeExpensesTooltip = '';
	goalsToolTip = '';
	adviceProcessRoutes = AdviceProcessRoutes;
	adviceProcessRouteEnd = AdviceProcessSubRoutes;

	isLoading$ = this.query.selectLoading();

	isAdviceProcessEnded$ = this.query.adviceProcess$.pipe(map(x => x?.status > 2 && x?.status < 5));

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private query: ClientReviewTemplateQuery,
		private lifeQuery: LifeQuery,
		private disabilityQuery: DisabilityQuery,
		private tpdQuery: TpdQuery,
		private medicalQuery: MedicalQuery,
		private soaQuery: StatementOfAdviceQuery,
		private soaService: StatementOfAdviceService,
		private lifeService: LifeService,
		private disabilityService: DisabilityService,
		private illnessService: CriticalIllnessService,
		private riskProfileService: RiskProfileService,
		private tpdService: TpdService,
		private medicalService: RiskAnalysisMedicalService,
		private cd: ChangeDetectorRef,
		private lrApService: LrAdviceprocessService
	) {}

	ngOnInit(): void {
		this.soaService.soaCurrentTab$.pipe(
			skip(1),
			tap((x) => this.hideSidebar()),
			takeUntil(this.onDestroy$)
		).subscribe();

		this.query
			.select()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.queryValue = data;
				this.updateColorCode();
			});

		this.lifeQuery
			.select()
			.pipe(
				takeUntil(this.onDestroy$),
				tap((data) => {
					this.colorCode.life = this.checkLifeComponent(data);
				}),
				concatMap(() => this.checkRiskAnalysis())
			)
			.subscribe();

		combineLatest([
			this.disabilityQuery.select(),
			this.query.people$,
			this.query.dependents$,
		])
			.pipe(
				takeUntil(this.onDestroy$),
				tap(([data, people, dependants]) => {
					this.colorCode.disability = this.checkDisabilityComponent(data);
					// this.cd.detectChanges();
				}),
				concatMap(() => this.checkRiskAnalysis())
			)
			.subscribe();

		combineLatest([
			this.tpdQuery.select(),
			this.query.people$,
			this.query.dependents$,
		])
			.pipe(
				tap(([data, people, dependants]) => {
					this.colorCode.tpd = this.checkTPDComponent(data);
					// this.cd.detectChanges();
				}),
				concatMap(() => this.checkRiskAnalysis()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		combineLatest([
			this.medicalQuery.select(),
			this.query.people$,
			this.query.dependents$,
		])
			.pipe(
				tap(([data, people, dependants]) => {
					setTimeout(() => {
						this.colorCode.medical = this.checkMedicalComponent(data);
						// this.cd.detectChanges();
					}, 0);
				}),
				concatMap(() => this.checkRiskAnalysis()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.soaQuery.finalizedSOA$
			.pipe(
				takeUntil(this.onDestroy$),
				tap((data) => {
					this.colorCode.statementOfAdvice =
						this.checkStatementOfAdviceComponent(data);
				})
			)
			.subscribe();

		this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
			if (val instanceof NavigationStart) {
				this.hideSidebar();
			}
			if (val instanceof NavigationEnd) {
				this.queryValue = this.query.getValue();
				this.updateColorCode();
			}
		});
		this.isFactFind();
		this.isRiskAnalysis();

		const updateAdviceProcess = () => {
			return this.query.adviceProcess$.pipe(
				skipWhile(() => this.query.getValue().lrApUpdateLoading),
				concatMap((data) => {
					const adviceProcess = Object.assign({}, data);
					const mappedStage = (remove: boolean) =>
						adviceProcess.stages?.map((el) =>
							produce(el, (draft) => {
								if (draft.field === 'Fact Find') {
									draft.value = !remove
										? MomentUtil.formatToServerDatetime(
												MomentUtil.createMomentNz()
										  )
										: null;
								}
								return draft;
							})
						);

					const hasDate = adviceProcess.stages?.find(
						(x) => x.field === 'Fact Find' && !!x.value
					);
					if (this.colorCode.factFind === this.MENU_ITEM_SUCCESS && !hasDate) {
						const lradvice = {
							...adviceProcess,
							stages: [...mappedStage(false)],
							documents: adviceProcess.documents?.map((doc) => {
								return {
									...doc,
									value: doc.value?.documentID,
								};
							}),
						};
						return this.soaService.updateLRAdviceProcess(lradvice);
					} else {
						return of(null);
					}
				})
			);
		};

		const updateAP = (code: string, tab: string, isRemove?: boolean) => {
			return this.query.lrApPageCompleted$.pipe(
				distinctUntilChanged(),
				concatMap((data) => {
					let pageCompleted = data || [];
					const completed = data?.some((x) => x === code);

					if (this.colorCode[tab] !== this.MENU_ITEM_SUCCESS) {
						if (!completed) {
							return of(null);
						}
						if (!!isRemove) {
							pageCompleted = pageCompleted?.filter((x) => x !== code);
						}
					} else {
						if (completed) {
							return of(null);
						}
						pageCompleted = [...pageCompleted, code];
					}
					pageCompleted = [...uniq(pageCompleted)];

					return of(pageCompleted).pipe(
						tap((x) => this.lrApService.setLrApPageCompletedState(x)),
						concatMap((x) => this.lrApService.updateLrApPageCompleted(x)),
						take(1)
					);
				})
			);
		};

		let isSame = true;
		let isSameFG = true;
		this.factFindColor$
			.pipe(
				debounceTime(500),
				distinctUntilChanged((prev, curr) => {
					prev === curr ? (isSame = true) : (isSame = false);
					return isSame;
				}),
				mergeMap(() => iif(() => !!isSame, of(null), updateAdviceProcess())),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.factFindFGColor$
			.pipe(
				delay(350),
				skipWhile(() => this.query.getValue().lrApUpdateLoading),
				debounceTime(500),
				distinctUntilChanged((prev, curr) => {
					prev === curr ? (isSameFG = true) : (isSameFG = false);
					return isSameFG;
				}),
				mergeMap(() =>
					iif(
						() => !!isSameFG,
						of(null),
						updateAP(AdviceProcessPageCodes.FG, 'fg')
					)
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
		if (this.innerWidth < 992) {
			this.isToggleSidebar = false;
			$('.crt__left-sidebar').removeClass('open');
		}
	}

	updateColorCode() {
		this.colorCode.intro = this.checkIntroPage();
		this.colorCode.people = this.checkPeopleComponent();
		this.colorCode.assetsLiabilities = this.checkAssetsLiabilitiesComponent();
		this.colorCode.incomeBudget = this.checkIncomeComponent();
		this.colorCode.medicalHistory = this.checkMedicalHistoryComponent();
		this.colorCode.currentInsurance = this.checkInsuranceComponent();
		this.colorCode.fg = this.checkFgComponent();
		this.factFindFGColor$.next(this.colorCode.fg);

		// this.checkFactFind();
		this.checkFactFind().pipe(takeUntil(this.onDestroy$)).subscribe();

		// Risk Analysis
		this.colorCode.goals = this.checkGoalsComponent();
		this.colorCode.criticalIllness = this.checkCriticalIllnessComponent();
		this.colorCode.life = this.checkLifeComponent();
		this.colorCode.tpd = this.checkTPDComponent();
		this.colorCode.riskProfile = this.checkRiskProfileComponent();
		this.checkRiskAnalysis().pipe(takeUntil(this.onDestroy$)).subscribe();

		// Declaration
		this.colorCode.declaration = this.checkDeclarationComponent();

		// // SOA
		this.colorCode.statementOfAdvice = this.checkStatementOfAdviceComponent(
			this.soaQuery.getAll()?.filter((x) => x.currentPage === 'Finalized')
		);

		// Authority To Proceed
		this.colorCode.authorityToProceed = this.checkAuthorityToProceedComponent();

		// Final Structure
		this.colorCode.finalStructure = this.checkFinalstructureComponent();

		// Intro
		this.colorCode.intro =
			this.colorCode.intro === this.MENU_ITEM_SUCCESS ||
			this.isActive(AdviceProcessRoutes.Introduction)
				? this.MENU_ITEM_SUCCESS
				: this.MENU_ITEM_UNOPENED;

		// Discousure Document
		this.colorCode.disclosure = this.checkDisclousureComponent();

		// Scope of Service
		this.colorCode.scopeOfService = this.checkScopeOfServiceComponent();

		// this.cd.detectChanges();
	}

	checkIntroPage() {
		try {
			if (this.queryValue.lrApPageCompleted?.some((x) => x === 'IP')) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkPeopleComponent() {
		try {
			if (this.queryValue.people.length > 0) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkAssetsLiabilitiesComponent() {
		try {
			if (
				this.queryValue.others[0]?.hasPersonalGuarantees &&
				this.queryValue.others[0]?.hasFinancialResponsibilities &&
				this.queryValue.others[0]?.hasCurrentWill &&
				((this.queryValue.mortgages?.length &&
					!this.queryValue.mortgages?.some(
						(mortgage: MortgageState) => mortgage.mortgageFrequency === null
					)) ||
					this.queryValue.mortgages?.length === 0)
					&&
				((this.queryValue.properties?.length > 0 &&
						!this.queryValue.properties?.some(
							(p) =>
								p?.propertyUse !== PropertyUse.OwnerOccupied &&
								!!p?.rentalIncome &&
								!p?.rentalFrequency
						)) ||
						this.queryValue.properties?.length === 0)
			) {
				return this.MENU_ITEM_SUCCESS;
			} else if (
				(this.queryValue.properties?.length < 1 &&
					this.queryValue.kiwiSavers?.length < 1 &&
					this.queryValue.mortgages?.length < 1 &&
					this.queryValue.assets?.length < 1) ||
				(this.queryValue.mortgages?.length &&
					this.queryValue.mortgages?.some(
						(mortgage: MortgageState) => mortgage.mortgageFrequency === null
					)) ||
				(!this.queryValue.others[0]?.hasPersonalGuarantees &&
					!this.queryValue.others[0]?.hasFinancialResponsibilities &&
					!this.queryValue.others[0]?.hasCurrentWill) ||
				this.isActive(AdviceProcessSubRoutes.AssetsLiabilities)
			) {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkIncomeComponent() {
		try {
			if (
				(this.queryValue.incomeSource?.length > 0 ||
					this.queryValue.rentalDetails.netRentalIncomeList?.length > 0 ||
					this.queryValue.otherIncomeDetails.otherIncome?.length > 0) &&
				!!this.queryValue.monthlyExpense?.completed
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				this.incomeExpensesTooltip = this.incomeSourceTooltip;
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			if (
				!(
					this.queryValue.incomeSource?.length > 0 ||
					this.queryValue.rentalDetails?.netRentalIncomeList?.length > 0 ||
					this.queryValue.otherIncomeDetails?.otherIncome?.length > 0
				) &&
				this.queryValue?.monthlyExpense
			) {
				this.incomeExpensesTooltip = this.incomeSourceTooltip;
				return this.MENU_ITEM_INCOMPLETE;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		}
	}

	checkMedicalHistoryComponent() {
		const smokingStatus = this.queryValue.bodyMeasures?.filter(
			(x) => !!x.smokingStatus
		);
		try {
			if (
				this.queryValue.bodyMeasures?.length > 0 &&
				this.queryValue.bodyMeasures?.length === smokingStatus.length
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkInsuranceComponent() {
		try {
			if (
				this.queryValue.currentInsurancesNext ||
				this.queryValue.lrApPageCompleted?.some((x) => x === 'FCLNO') ||
				(this.queryValue.lrApPageCompleted?.some((x) => x === 'FCLYES') &&
					this.queryValue.currentInsurances?.some((x) => x))
			) {
				return this.MENU_ITEM_SUCCESS;
				// tslint:disable-next-line: max-line-length
			} else if (
				(this.queryValue.lrApPageCompleted?.some((x) => x === 'FCLYES') &&
					!this.queryValue.currentInsurances?.some((x) => x)) ||
				this.isActive(AdviceProcessSubRoutes.CurrentInsurance)
			) {
				return this.MENU_ITEM_INCOMPLETE;
			}
			return this.MENU_ITEM_UNOPENED;
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkFgComponent() {
		try {
			if (
				this.isActive(AdviceProcessSubRoutes.FG) ||
				this.queryValue.lrApPageCompleted?.some(
					(x) => x === AdviceProcessPageCodes.FG
				)
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkGoalsComponent() {
		const goals = this.queryValue?.goals;
		try {
			const shortTermGoals = [...goals?.shortTermGoals]?.filter(
				(x) =>
					!!x.dropdown &&
					((x?.dropdown === 'Other' && !!x?.value) || x?.dropdown !== 'Other')
			);
			const longTermGoals = [...goals?.longTermGoals]?.filter(
				(x) =>
					!!x.dropdown &&
					((x?.dropdown === 'Other' && !!x?.value) || x?.dropdown !== 'Other')
			);
			if (shortTermGoals?.length > 0 || longTermGoals?.length > 0) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				this.goalsToolTip = 'At least one goal is required';
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkLifeComponent(list?) {
		let data = [];
		if (list) {
			data = list?.ids?.map((x) => list?.entities[x]) || [];
		} else {
			data = this.lifeQuery.getAll();
		}
		const mortgages = this.query.getValue().mortgages || [];
		const filterMortgages = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				mortgages?.some((y) =>
					either(
						isNil,
						isEmpty
					)(x?.loans?.find((z) => +z?.cRTId === +y?.cRTId))
				)
		);
		const liabilities = this.query.getValue().liabilities || [];
		const filterLiabilities = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				liabilities?.some((y) =>
					either(
						isNil,
						isEmpty
					)(x?.loans?.find((z) => +z?.cRTId === +y?.cRTId))
				)
		);
		const filterEntitieLoans = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				x?.loans?.some((y) => either(isNil, isEmpty)(y?.repay))
		);
		const filterEntities = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				!either(isNil, isEmpty)(x?.yearsShortfallCovered) &&
				+x?.yearsShortfallCovered > -1
		);
		try {
			if (
				filterMortgages?.length === 0 &&
				filterLiabilities?.length === 0 &&
				filterEntitieLoans?.length === 0 &&
				filterEntities?.length === this.queryValue?.people?.length
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkDisabilityComponent(data) {
		const checkAllPeople = this.queryValue?.people?.length === data.ids?.length;
		const filterEntities = data.ids?.filter((x) => {
			return (
				(+data?.entities[x].whatPercentageOfPreTaxIncomeLikeCovered > 0 &&
					!!data?.entities[x].howLongWouldYouWantYourIncomeCoveredFor) ||
				(!either(
					isNil,
					isEmpty
				)(data?.entities[x].whatPercentageOfPreTaxIncomeLikeCovered) &&
					+data?.entities[x].whatPercentageOfPreTaxIncomeLikeCovered === 0)
			);
		});

		try {
			if (
				checkAllPeople &&
				filterEntities?.length === this.queryValue?.people?.length
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkTPDComponent(list?) {
		let data = [];
		if (list) {
			data = list?.ids?.map((x) => list?.entities[x]) || [];
		} else {
			data = this.tpdQuery.getAll();
		}
		const mortgages = this.query.getValue().mortgages || [];
		const filterMortgages = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				mortgages?.some((y) =>
					either(
						isNil,
						isEmpty
					)(x?.loansList?.find((z) => +z?.cRTId === +y?.cRTId))
				)
		);
		const liabilities = this.query.getValue().liabilities || [];
		const filterLiabilities = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				liabilities?.some((y) =>
					either(
						isNil,
						isEmpty
					)(x?.loansList?.find((z) => +z?.cRTId === +y?.cRTId))
				)
		);
		const filterEntitieLoans = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				x?.loansList?.some((y) => either(isNil, isEmpty)(y?.repay))
		);
		const filterEntities = data?.filter(
			(x) =>
				x?.parentCRTId > 0 &&
				!either(isNil, isEmpty)(x?.yearsShortfallCovered) &&
				+x?.yearsShortfallCovered > -1
		);
		try {
			if (
				filterMortgages?.length === 0 &&
				filterLiabilities?.length === 0 &&
				filterEntitieLoans?.length === 0 &&
				filterEntities?.length === this.queryValue?.people?.length
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkCriticalIllnessComponent() {
		const firstDropdown = this.queryValue.criticalIllness?.filter(
			(x) => x.periodOfCoverList?.filter((y) => !!y?.dropdown)?.length > 0
		);
		const checkAllPeople =
			this.queryValue?.people?.length === firstDropdown?.length;
		try {
			if (firstDropdown?.length > 0 && checkAllPeople) {
				return this.MENU_ITEM_SUCCESS;
			} else if (
				either(isNil, isEmpty)(firstDropdown) ||
				this.colorCode.criticalIllness === this.MENU_ITEM_INCOMPLETE ||
				this.isActive(AdviceProcessSubRoutes.CriticalIllnness) ||
				!checkAllPeople
			) {
				return this.MENU_ITEM_INCOMPLETE;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkMedicalComponent(data) {
		const ids = Object.assign([], data.ids);
		const people = this.queryValue.people;
		const countDependants =
			this.queryValue.dependents?.filter((dep) => +dep.cRTId).length > 0
				? 1
				: 0;
		const findDependants = data.ids.findIndex((x: number) => x === 0);
		if (countDependants > 0 && findDependants === -1) {
			ids?.push(0);
		}
		const filterEntities = ids?.filter(
			(x) =>
				!!data?.entities[x]?.relyOnNewZealandPublicHealthSystem &&
				!!data?.entities[x]?.newZealandPrivateMedicalPolicyBasePlan &&
				!!data?.entities[x]?.medicalDrugTreatmentsApprovedforUseInNZ &&
				!!data?.entities[x]?.policyProvidingAccessToSpecialistsAndTests &&
				!!data?.entities[x]?.policyProvidingAccessToOpticalAndDentalTreatment &&
				!!data?.entities[x]?.policyProvidingAccessToGPVisitsAndPrescriptions &&
				// Check whatLevelOfExcessAreYouComfortableSelfInsuring only if relyOnNewZealandPublicHealthSystem is No
				((data?.entities[x]?.relyOnNewZealandPublicHealthSystem === 'No' &&
					!!data?.entities[x]
						?.whatLevelOfExcessAreYouComfortableSelfInsuring) ||
					data?.entities[x]?.relyOnNewZealandPublicHealthSystem === 'Yes')
		);

		try {
			if (
				filterEntities?.length > 0 &&
				ids?.length > 0 &&
				filterEntities?.length === ids?.length &&
				people?.length + countDependants === ids?.length
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkRiskProfileComponent() {
		const people = this.queryValue.people;
		const countDependants =
			this.queryValue.dependents?.filter((dep) => +dep.cRTId).length > 0
				? 1
				: 0;
		const filterEntities = this.queryValue.riskProfile?.event?.some((x) => {
			if (x.isDependents) {
				return (
					!x.prematureDeath ||
					!x.sufferFromCriticalIllnessOrTrauma ||
					!x.medicalConditionRequiringHospitalTreatment
				);
			} else {
				return (
					!x.prematureDeath ||
					!x.unableToWorkPermanently ||
					!x.sufferFromCriticalIllnessOrTrauma ||
					!x.unableToWorkDueToTemporarySickness ||
					!x.medicalConditionRequiringHospitalTreatment
				);
			}
		});

		try {
			if (
				!filterEntities &&
				this.queryValue.riskProfile?.insurancePreferences?.length > 0 &&
				this.queryValue.riskProfile?.insurancePreferences?.some(
					(x) =>
						!!x.dropdown &&
						((x?.dropdown === 'Other' && !!x?.value) || x?.dropdown !== 'Other')
				) &&
				people.length + countDependants ===
					this.queryValue.riskProfile?.event.length
			) {
				return this.MENU_ITEM_SUCCESS;
			} else {
				return this.MENU_ITEM_INCOMPLETE;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkDeclarationComponent() {
		try {
			if (
				(this.queryValue?.declaration?.signatures?.length > 0 &&
					this.queryValue?.declaration?.signatures?.every(
						(x) => !!x?.secondaryReferenceID
					)) ||
				this.queryValue.adviceProcess.documents?.find(
					(d) => d.field === 'PIA & Declaration'
				)?.value
			) {
				return this.MENU_ITEM_SUCCESS;
			} else if (
				this.queryValue?.declaration?.cRTId ||
				this.colorCode.declaration === this.MENU_ITEM_INCOMPLETE ||
				this.isActive(AdviceProcessRoutes.Declaration)
			) {
				return this.MENU_ITEM_INCOMPLETE;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkStatementOfAdviceComponent(data) {
		try {
			if (data && data.length > 0) {
				return this.MENU_ITEM_SUCCESS;
			} else if (this.isActive(AdviceProcessRoutes.SOA)) {
				return this.MENU_ITEM_INCOMPLETE;
			}
			return this.MENU_ITEM_UNOPENED;
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkAuthorityToProceedComponent() {
		try {
			if (
				!!this.queryValue?.adviceProcess?.documents?.some(
					(x) => x.field === 'Authority to Proceed' && !!x.value
				)
			) {
				return this.MENU_ITEM_SUCCESS;
			} else if (this.colorCode.authorityToProceed === this.MENU_ITEM_INCOMPLETE) {
				return this.MENU_ITEM_INCOMPLETE;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkFinalstructureComponent() {
		try {
			if (this.queryValue.finalStructure?.isEmailSent) {
				return this.MENU_ITEM_SUCCESS;
			} else if (this.isActive(AdviceProcessRoutes.FinalStructure)) {
				return this.MENU_ITEM_INCOMPLETE;
			}
			return this.MENU_ITEM_UNOPENED;
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkDisclousureComponent() {
		try {
			if (
				this.queryValue.adviceProcess.documents?.find(
					(d) => d.field === 'Disclosure Document'
				)?.value
			) {
				return this.MENU_ITEM_SUCCESS;
			} else if (
				this.colorCode.disclosure === this.MENU_ITEM_INCOMPLETE ||
				this.isActive(AdviceProcessRoutes.Disclosure) ||
				this.queryValue.adviceProcess.pageStarted?.includes(AdviceProcessPageCodes.Disclosure)
			) {
				return this.MENU_ITEM_INCOMPLETE;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkScopeOfServiceComponent() {
		try {
			if (
				this.queryValue.adviceProcess.documents?.find(
					(d) => d.field === AdviceProcessDocumentField.SOS
				)?.value
			) {
				return this.MENU_ITEM_SUCCESS;
			} else if (
				this.colorCode.scopeOfService === this.MENU_ITEM_INCOMPLETE ||
				this.isActive(AdviceProcessRoutes.SOS) ||
				this.queryValue.adviceProcess.pageStarted?.includes(AdviceProcessPageCodes.SOS)
			) {
				return this.MENU_ITEM_INCOMPLETE;
			} else {
				return this.MENU_ITEM_UNOPENED;
			}
		} catch (error) {
			return this.MENU_ITEM_UNOPENED;
		}
	}

	checkFactFind() {
		return this.hasHomeCarContentsInsurance$.pipe(
			tap((hasHomeCarContents) => {
				if (
					this.colorCode.people === this.MENU_ITEM_SUCCESS &&
					this.colorCode.assetsLiabilities === this.MENU_ITEM_SUCCESS &&
					this.colorCode.incomeBudget === this.MENU_ITEM_SUCCESS &&
					this.colorCode.medicalHistory === this.MENU_ITEM_SUCCESS &&
					this.colorCode.currentInsurance === this.MENU_ITEM_SUCCESS &&
					(this.colorCode.fg === this.MENU_ITEM_SUCCESS || !hasHomeCarContents)
				) {
					this.colorCode.factFind = this.MENU_ITEM_SUCCESS;
				} else if (
					this.colorCode.people === this.MENU_ITEM_UNOPENED &&
					this.colorCode.assetsLiabilities === this.MENU_ITEM_UNOPENED &&
					this.colorCode.incomeBudget === this.MENU_ITEM_UNOPENED &&
					this.colorCode.medicalHistory === this.MENU_ITEM_UNOPENED &&
					this.colorCode.currentInsurance === this.MENU_ITEM_UNOPENED &&
					this.colorCode.fg === this.MENU_ITEM_UNOPENED
				) {
					this.colorCode.factFind = this.MENU_ITEM_UNOPENED;
				} else {
					this.colorCode.factFind = this.MENU_ITEM_INCOMPLETE;
				}
				this.factFindColor$.next(this.colorCode.factFind);
			})
		);
	}

	checkRiskAnalysis() {
		return combineLatest([
			this.hasCriticalIllness$,
			this.hasDisability$,
			this.hasLife$,
			this.hasMedical$,
			this.hasTPD$,
		]).pipe(
			tap(
				([hasCriticalIllness, hasDisability, hasLife, hasMedical, hasTPD]) => {
					if (
						this.colorCode.goals === this.MENU_ITEM_SUCCESS &&
						(this.colorCode.life === this.MENU_ITEM_SUCCESS || !hasLife) &&
						(this.colorCode.disability === this.MENU_ITEM_SUCCESS ||
							!hasDisability) &&
						(this.colorCode.tpd === this.MENU_ITEM_SUCCESS || !hasTPD) &&
						(this.colorCode.criticalIllness === this.MENU_ITEM_SUCCESS ||
							!hasCriticalIllness) &&
						(this.colorCode.medical === this.MENU_ITEM_SUCCESS ||
							!hasMedical) &&
						this.colorCode.riskProfile === this.MENU_ITEM_SUCCESS
					) {
						this.colorCode.riskAnalysis = this.MENU_ITEM_SUCCESS;
					} else if (
						this.colorCode.goals === this.MENU_ITEM_UNOPENED &&
						(this.colorCode.life === this.MENU_ITEM_UNOPENED || !hasLife) &&
						(this.colorCode.disability === this.MENU_ITEM_UNOPENED || !hasLife) &&
						(this.colorCode.tpd === this.MENU_ITEM_UNOPENED || !hasLife) &&
						(this.colorCode.criticalIllness === this.MENU_ITEM_UNOPENED ||
							!hasCriticalIllness) &&
						(this.colorCode.medical === this.MENU_ITEM_UNOPENED || !hasMedical) &&
						this.colorCode.riskProfile === this.MENU_ITEM_UNOPENED
					) {
						this.colorCode.riskAnalysis = this.MENU_ITEM_UNOPENED;
					} else {
						this.colorCode.riskAnalysis = this.MENU_ITEM_INCOMPLETE;
					}
				}
			)
		);
	}

	checkAge(id: number, age: number) {
		const people = this.queryValue.people?.find((x) => id === x.cRTId);
		return age > people?.age;
	}

	get IsFactFindTab() {
		return this.route.snapshot.firstChild.url[0].path === this.factFindPath;
	}

	get IsRiskAnalysisTab() {
		return this.route.snapshot.firstChild.url[0].path === this.riskAnalysisPath;
	}

	isFactFind() {
		return (this.isFactFindOpen =
			this.factFindPath === this.route.snapshot.firstChild.url[0].path);
	}

	openFactFind() {
		this.isFactFindOpen = !this.isFactFindOpen;
		this.isRiskAnalysisOpen = false;
	}

	isRiskAnalysis() {
		return (this.isRiskAnalysisOpen =
			this.riskAnalysisPath === this.route.snapshot.firstChild.url[0].path);
	}

	openRiskAnalysis() {
		this.isRiskAnalysisOpen = !this.isRiskAnalysisOpen;
		this.isFactFindOpen = false;
	}

	isActive(path) {
		const getCurrentPath = !!this.route.snapshot.firstChild.firstChild
			? this.route.snapshot.firstChild.firstChild.url[0].path
			: this.route.snapshot.firstChild.url[0].path;
		return path === getCurrentPath;
	}

	goToFF(path) {
		return `${this.factFindPath}/${path}`;
	}

	goToRA(path) {
		this.displayInvalidCurrentTab();
		let navTimer;
		navTimer = setTimeout(() => {
			this.router.navigate([`${this.riskAnalysisPath}/${path}`], {
				relativeTo: this.route,
			});
			if (navTimer) {
				clearTimeout(navTimer);
			}
		}, 500);
	}

	triggerNavigate(path: string, isStoreLoading = false) {
		const isSavingSOA = this.soaQuery.getValue().isSavingSOA;
		const isSavingPI = this.soaQuery.getValue().isSavingPI;
		const isSavingSOAReview = this.soaQuery.getValue().isSavingSOAReview;
		const hasSoaChanges = this.query.getValue().hasSoaChanges;

		if (isStoreLoading) {
			return;
		}

		if (hasSoaChanges) {
			this.soaService.setTriggerLeaveSoa(path);
			return;
		}

		if (this.isCRTLoading || isSavingSOA || isSavingPI || isSavingSOAReview) {
			return;
		}

		if (path.includes(AdviceProcessRoutes.RiskAnalysis)) {
			this.displayInvalidCurrentTab();
			let navTimer;
			navTimer = setTimeout(() => {
				this.navigateToTab.emit(path);
				if (navTimer) {
					clearTimeout(navTimer);
				}
			}, 50);
		} else {
			this.navigateToTab.emit(path);
		}
	}

	displayInvalidCurrentTab() {
		switch (this.currentTab) {
			// Life
			case AdviceProcessPageCodes.Life:
				this.lifeService.setShowInvalid(true);
				break;

			// Disability
			case AdviceProcessPageCodes.Disability:
				this.disabilityService.setShowInvalid(true);
				break;

			// TPD
			case AdviceProcessPageCodes.TPD:
				this.tpdService.setShowInvalid(true);
				break;

			// Critical Illness
			case AdviceProcessPageCodes.CriticalIllnness:
				this.illnessService.setShowInvalid(true);
				break;

			// Medical
			case AdviceProcessPageCodes.Medical:
				this.medicalService.setShowInvalid(true);
				break;

			// Risk Profile
			case AdviceProcessPageCodes.RiskProfile:
				this.riskProfileService.setShowInvalid(true);
				break;

			default:
				return null;
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	toggleSidebar(tab?: string) {
		if (tab === AdviceProcessRoutes.Introduction) {
			this.colorCode.intro = this.MENU_ITEM_SUCCESS;
		}

		if (tab === AdviceProcessSubRoutes.CriticalIllnness) {
			this.colorCode.criticalIllness =
				tab === AdviceProcessSubRoutes.CriticalIllnness &&
				this.colorCode.criticalIllness !== this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_INCOMPLETE
					: this.colorCode.criticalIllness === this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_SUCCESS
					: this.MENU_ITEM_UNOPENED;
		}

		if (tab === AdviceProcessRoutes.Disclosure) {
			this.colorCode.disclosure =
				tab === AdviceProcessRoutes.Disclosure &&
				this.colorCode.disclosure !== this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_INCOMPLETE
					: this.colorCode.disclosure === this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_SUCCESS
					: this.MENU_ITEM_UNOPENED;
		}

		if (tab === AdviceProcessRoutes.SOS) {
			this.colorCode.scopeOfService =
				tab === AdviceProcessRoutes.SOS &&
				this.colorCode.scopeOfService !== this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_INCOMPLETE
					: this.colorCode.scopeOfService === this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_SUCCESS
					: this.MENU_ITEM_UNOPENED;
		}

		if (tab === AdviceProcessRoutes.SOA) {
			this.colorCode.statementOfAdvice =
				this.colorCode.statementOfAdvice !== this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_INCOMPLETE
					: this.colorCode.statementOfAdvice === this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_SUCCESS
					: this.MENU_ITEM_UNOPENED;
		}

		if (tab === AdviceProcessRoutes.AuthorityToProceed) {
			this.colorCode.authorityToProceed =
				this.colorCode.authorityToProceed !== this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_INCOMPLETE
					: this.colorCode.authorityToProceed === this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_SUCCESS
					: this.MENU_ITEM_UNOPENED;
		}

		if (tab === AdviceProcessRoutes.FinalStructure) {
			this.colorCode.finalStructure =
				this.colorCode.finalStructure !== this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_INCOMPLETE
					: this.colorCode.finalStructure === this.MENU_ITEM_SUCCESS
					? this.MENU_ITEM_SUCCESS
					: this.MENU_ITEM_UNOPENED;
		}

		this.isToggleSidebar = !this.isToggleSidebar;
		if (this.isToggleSidebar) {
			$('.crt__left-sidebar').addClass('open');
		} else {
			$('.crt__left-sidebar').removeClass('open');
		}
	}

	hideSidebar() {
		this.isToggleSidebar = false;
		$('.crt__left-sidebar').removeClass('open');
	}

	disableSoaTab(): boolean {
		return (
			this.colorCode.factFind !== this.MENU_ITEM_SUCCESS &&
			this.colorCode.riskAnalysis !== this.MENU_ITEM_SUCCESS
		);
	}

	get disableAuthToProceedTab(): boolean {
		return this.colorCode.statementOfAdvice !== this.MENU_ITEM_SUCCESS;
	}

	colorCodeIncludes(colorCodes: any[], str: string) {
		if (!colorCodes && !str) {
			return false;
		}
		return colorCodes?.includes(str);
	}

	ngAfterViewInit() {
		// this.cd.detectChanges();
	}
}
