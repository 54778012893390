import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { AdviserStat } from './adv.model';
import { AdviserStatsStore } from './adv.store';

@Injectable()
export class AdviserStatsService {

  private readonly endpoint = 'widgets?type=ADV';

  constructor(
    private api: ApiService,
    private store: AdviserStatsStore
  ) { }

  /**
   * Fetch and store Adviser Stat data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetAdviserStats(req: {
    advisers: string[],
    dateRangeMin: string,
    dateRangeMax: string
  }): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post3<AdviserStat[]>(this.endpoint, {
      Advisers: req.advisers,
      DateRangeMin: req.dateRangeMin,
      DateRangeMax: req.dateRangeMax
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.AdviserStats = res;
      })),
      err => applyTransaction(() => {
        this.store.setError('Error fetching data!');
        this.store.setLoading(false);
      }),
      () => this.store.setLoading(false)
    );
  }
}
