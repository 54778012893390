import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import {
	MoatSidebarWarning,
	ServiceAdviceProcessState,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { PrimaryClientState } from 'src/app/shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from 'src/app/shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from 'src/app/shared/models/client-profile/seondary-business/secondary-business.model';
import { DocumentUpload } from 'src/app/shared/models/documents/document.model';
import { objectUtil } from 'src/app/util/util';
import { CrtMortgageQuery } from './crt-mortgage.query';
import { CrtMortgageStore } from './crt-mortgage.store';
import { SidebarStatus } from '../../_shared/models/sidebar.model';
import { SecondaryProfessionalState } from 'src/app/shared/models/client-profile/secondary-professional/secondary-professional.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { uniq, prop, uniqBy } from 'ramda';

@Injectable()
export class CrtMortgageService {
	snapshot = this.query.getValue();
	sidebars$ = this.query.sidebars$;
	sectionsLoading$ = this.query.sectionsLoading$;

	// Dropdown values
	SCR$ = this.dropdownValueQuery.orderedChoices$('SCR');
	PCE$ = this.dropdownValueQuery.orderedChoices$('PCE');
	SCTT$ = this.dropdownValueQuery.orderedChoices$('SCTT');
	APCRTVD$ = this.dropdownValueQuery.orderedChoices$('APCRTVD');
	APCRTG$ = this.dropdownValueQuery.orderedChoices$('APCRTG');
	APCRTBP$ = this.dropdownValueQuery.orderedChoices$('APCRTBP');
	APCRTYNNA$ = this.dropdownValueQuery.orderedChoices$('APCRTYNNA');
	APCRTF$ = this.dropdownValueQuery.orderedChoices$('APCRTF');
	APCRTKC$ = this.dropdownValueQuery.orderedChoices$('APCRTKC');
	APCRTTC$ = this.dropdownValueQuery.orderedChoices$('APCRTTC');
	APCRTI$ = this.dropdownValueQuery.orderedChoices$('APCRTI');
	APCRTIST$ = this.dropdownValueQuery.orderedChoices$('APCRTIST');
	APCRTIT$ = this.dropdownValueQuery.orderedChoices$('APCRTIT');
	APCRTTM$ = this.dropdownValueQuery.orderedChoices$('APCRTTM');
	APCRTYN$ = this.dropdownValueQuery.orderedChoices$('APCRTYN');
	MP$ = this.dropdownValueQuery.orderedChoices$('MP');
	MLT$ = this.dropdownValueQuery.orderedChoices$('MLT');
	MFPE$ = this.dropdownValueQuery.orderedChoices$('MFPE');
	APCRTTIR$ = this.dropdownValueQuery.orderedChoices$('APCRTTIR');
	APCRTMS$ = this.dropdownValueQuery.orderedChoices$('APCRTMS');
	PCT$ = this.dropdownValueQuery.orderedChoices$('PCT');

	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');

	primaryClient$ = this.query.primaryClient$;
	primaryClient = this.snapshot.primaryClient;
	adviceProcess$ = this.query.adviceProcess$;
	mortApPageStarted$ = this.query.mortApPageStarted$;

	hasReviewApplicationChanges$ = this.query.hasReviewApplicationChanges$;
	reviewApplicationChangeValue$ = this.query.reviewApplicationChangeValue$;
	reviewApplicationShowModal$ = this.query.reviewApplicationShowModal$;
	appCurrentTab$ = this.query.appCurrentTab$;

	roaCurrentTab$ = this.query.roaCurrentTab$;
	triggerLeaveROA$ = this.query.triggerLeaveROA$;

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {}

	clearMoatData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	setAdviceProces = (data: ServiceAdviceProcessState) =>
		applyTransaction(() => {
			this.store.setAdviceProcess(data);
		});

	setAdviceProcessId(id: number) {
		applyTransaction(() => {
			this.store.setAdviceProcessId(id);
		});
	}

	setPrimaryClient = (data: PrimaryClientState) =>
		applyTransaction(() => {
			this.store.setPrimaryClient(data);
		});

	getPrimaryClient(primaryClientId: number) {
		return this.customerService.GetPrimaryClient(primaryClientId).pipe(
			map(objectUtil.mapPascalCaseToCamelCase),
			tap(this.setPrimaryClient),
			catchError(() => of(undefined))
		);
	}

	setClientId(id: number) {
		applyTransaction(() => {
			this.store.setClientId(id);
		});
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.store.setHasFormChanges(hasFormChanges);
	}

	setIsCompany(isCompany: boolean) {
		applyTransaction(() => {
			this.store.setIsCompany(isCompany);
		});
	}

	setReviewApplicationModal(toggle: boolean) {
		applyTransaction(() => {
			this.store.setReviewApplicationShowModal(toggle);
		});
	}

	setReviewApplicationChangeValue(value: string) {
		applyTransaction(() => {
			this.store.setReviewApplicationChangeValue(value);
		});
	}

	setHasReviewApplicationChanges(change: boolean) {
		applyTransaction(() => {
			this.store.setHasReviewApplicationChanges(change);
		});
	}

	setTriggerLeaveROA(change: string) {
		applyTransaction(() => {
			this.store.setTriggerLeaveROA(change);
		});
	}

	setRoaCurrentTab(change: string) {
		applyTransaction(() => {
			this.store.setRoaCurrentTab(change);
		});
	}

	setAppCurrentTab(change: string) {
		applyTransaction(() => {
			this.store.setAppCurrentTab(change);
		});
	}

	getSecondaryClients(customerId) {
		return this.customerService
			.GetSecondaryClientsByPrimaryClient(customerId)
			.pipe(
				map((x) => x?.filter((y) => +y.IsActive === 1)),
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryClientState[])
							: [];
						this.store.setSecondaryClients(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getSecondaryTrusts(customerId) {
		return this.customerService
			.GetSecondaryTrustByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryTrustState[])
							: [];
						this.store.setSecondaryTrusts(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getSecondaryCompanies(customerId) {
		return this.customerService
			.GetSecondaryBusinessesByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryBusinessState[])
							: [];
						this.store.setSecondaryCompanies(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getSecondaryProfessionals(customerId) {
		return this.customerService
			.GetSecondaryProfessionalsByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryProfessionalState[])
							: [];
						this.store.setSecondaryProfessionals(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getLinkedContacts(customerId) {
		return this.customerService
			.GetLinkedContactsByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as LinkedContactState[])
							: [];
						this.store.setLinkedContacts(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	saveDocument(req: DocumentUpload): Observable<number> {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}

	setSectionsLoading(value: boolean) {
		applyTransaction(() => this.store.setSectionsLoading(value));
	}

	/**
	 *
	 * @param menuId Sidebar id
	 * @param isSubMenu Sidebar isSubMenu
	 * @param status Sidebar status
	 * @param warning warning message for tooltip
	 * 0 = not yet visited
	 * 1 = visited but not completed
	 * 2 = completed requirements
	 */
	setSideSidebarStatus(
		menuId: string,
		isSubMenu: boolean,
		status: number | SidebarStatus,
		warning?: string
	) {
		const warningMessage = warning || MoatSidebarWarning.default;
		let sidebars = [...this.query.getValue().sidebars];

		if (isSubMenu) {
			sidebars = sidebars.map((x) =>
				x.isSubMenu
					? {
							...x,
							subMenu: x.subMenu.map((y) =>
								y.id === menuId
									? {
											...y,
											status,
											warning: warningMessage || '',
									  }
									: y
							),
					  }
					: x
			);
		} else {
			sidebars = sidebars.map((x) => {
				if (x.id === menuId) {
					return {
						...x,
						status,
						warning: warningMessage || '',
					};
				} else {
					if (x.subMenu?.find((sub) => sub.id === menuId)) {
						return {
							...x,
							subMenu: x.subMenu.map((y) =>
								y.id === menuId
									? {
											...y,
											status,
											warning: warningMessage || '',
									  }
									: y
							),
						};
					} else {
						return x;
					}
				}
			});
		}

		applyTransaction(() => this.store.update({ sidebars }));
	}

		/**
	 * Get the list of available choices for people from CRM
	 * @param ownersIds Value of specific input field selected
	 * @param policyOwners available choices
	 * @returns list of available choices with or without deceased clients & linked contacts
	 */
		 getOwnerChoices = (
			ownersIds: (string | number)[],
			policyOwners: ViewDisplayValue[]
		) => {
			const owners = (ownersIds || [])?.map((x) => +x);
			const allSci = (this.query.getValue().secondaryClients || [])?.map((x) => ({
				...x,
				id: +x?.customerID,
			}));
			const allLinked = (this.query.getValue().linkedContacts || [])?.map(
				(x) => ({
					...x,
					isLinked: true,
					id: x?.linkedFromPrimaryCustomer
						? +x?.relatedCustomerId
						: +x?.customerId,
				})
			);
			const desceasedSciIds = allSci
				?.filter((x) => x?.contactMethod === 'Deceased')
				?.map((x) => +x?.id);
			const deceasedLinkedIds = allLinked
				?.filter((x) => x?.contactMethod === 'Deceased')
				?.map((x) => +x?.id);
			const deceasedIds = uniq([...desceasedSciIds, ...deceasedLinkedIds]);
			const newPolicyOwnersNoDeceased = (policyOwners || [])
				?.filter((x) => !deceasedIds?.includes(+x?.value))
				?.sort((a, b) => a.display?.localeCompare(b.display));
	
			if (owners?.length > 0) {
				const deceased = owners?.filter((x) => deceasedIds?.includes(+x));
	
				if (deceased?.length > 0) {
					const policyOwnersDeceased = [...allSci, ...allLinked]?.filter(
						(x) => +deceased?.some((t) => +t === +x.id)
					) as any;
					const policyOwnersDeceasedIds = policyOwnersDeceased?.map(
						(x) => +x?.id
					);
					const potChoices =
						policyOwnersDeceased?.map((x) =>
							ViewDisplayValue.Map(
								`${x.id}`,
								`${x?.isLinked ? x?.name : x.firstName.concat(' ', x.lastName)}`
							)
						) || [];
					const newPolicyOwners =
						policyOwners
							?.filter((x) => {
								const isDeceased = deceasedIds?.includes(+x?.value);
								const isSelected = policyOwnersDeceasedIds?.includes(+x?.value);
								return isSelected ? false : !isDeceased;
							})
							?.map((x) => ({ ...x, value: x?.value?.toString() })) || [];
					const newList = uniqBy(prop('value'), [
						...newPolicyOwners,
						...potChoices,
					]);
	
					return newList?.sort((a, b) => a.display?.localeCompare(b.display));
				} else {
					const newList = uniqBy(prop('value'), newPolicyOwnersNoDeceased);
					return newList?.sort((a, b) => a.display?.localeCompare(b.display));
				}
			} else {
				const newList = uniqBy(prop('value'), newPolicyOwnersNoDeceased);
				return newList?.sort((a, b) => a.display?.localeCompare(b.display));
			}
		};
}
