import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { EmailHistoryModel } from './email-history.model';
import produce from 'immer';
import { TableResult } from '../../../../shared/models/emails/email-history/email-history.model';
import { Injectable } from "@angular/core";
export interface EmailHistoryState extends EntityState<EmailHistoryModel> {
	columns: string[];
	tableResult: TableResult;
	propSort: string;
	sort: string;
}

@Injectable()
@StoreConfig({ name: 'emailHistory', idKey: 'EmailHistoryId' })
export class EmailHistoryStore extends EntityStore<EmailHistoryState, EmailHistoryModel> {

	constructor() {
		super({
			columns: [],
			tableResult: null,
			propSort: '',
			sort: ''
		});
	}

	setColumns(columns: string[]) {
		this.update(produce(x => { x.columns = columns; }));
	}

	setTableResult(e: TableResult) {
		this.update({ tableResult: e });
	}

	setContentMergeTages(e: any) {
		this.update({ contentMergeTages: e });
	}

	setSort(propSort: string, sort: 'asc' | 'desc') {
		this.update(
			produce(draft => {
				draft.propSort = propSort;
				draft.sort = sort;
			})
		);
	}

}
