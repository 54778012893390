import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

import { LoggerService } from 'src/app/core/logger/logger.service';
import { validatorUtil } from '../../util/validator.util';
import { Fields, getInvalidWarning } from '../error-message/error-message';
import { EmailLinkList } from '../models/client-review-template/merge-tags/email-settings/email-links';
import { EmailSettingsModel } from '../models/_general/email-settings.model';
import { WysiwygComponent } from '../wysiwyg/wysiwyg.component';

@Component({
	selector: 'app-crt-email-settings',
	templateUrl: './email-settings.component.html',
	styleUrls: ['./email-settings.component.scss'],
})
export class EmailSettingsComponent implements OnInit, OnChanges {
	@Input() emailSettings: EmailSettingsModel;
	@Input() showBody: boolean;
	// tslint:disable-next-line: ban-types
	@Input() shortCodes: Object;

	@Input() isLoading: boolean;
	@Output() save = new EventEmitter();
	@Input() bodyContent: string;
	@Input() showFromField: boolean;

	@Input() hideEnableEmailOption: boolean;

	@Input() hasAutoCCAdviserOption: boolean;
	@Input() autoCCAdviser: boolean;

	form: UntypedFormGroup;
	optionsWysiwyg = {
		heightMax: null,
		heightMin: 250,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkList: EmailLinkList,
		linkAutoPrefix: '',
	};
	submitted: boolean;
	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(private fb: UntypedFormBuilder, private loggerService: LoggerService) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
		this.form.get('isEnableEmailOption').valueChanges.subscribe(x => {
			if(this.hasAutoCCAdviserOption) {
				this.form.get('autoCCAdviser').setValue(x);
			}
		})
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.emailSettings && this.emailSettings) {
			this.form.reset(this.emailSettings);
		}
	}

	prepData() {
		of(this.emailSettings)
			.pipe(take(1))
			.subscribe((data) => {
				if (data) {
					this.form.reset(data);
				}
			});
	}

	get sender() {
		return this.form.get('sender');
	}

	get carbonCopy() {
		return this.form.get('carbonCopy');
	}

	get blindCarbonCopy() {
		return this.form.get('blindCarbonCopy');
	}

	buildForm() {
		this.form = this.fb.group({
			isEnableEmailOption: [false],
			sender: [null, validatorUtil.emailValidator],
			carbonCopy: [null, validatorUtil.emailValidator],
			blindCarbonCopy: [null, validatorUtil.emailValidator],
			subject: [null],
			templateID: [null],
			unsubscribeGroupID: [null],
			autoCCAdviser: [this.hasAutoCCAdviserOption ? this.autoCCAdviser : false]
		});
	}

	get IsEnableEmailOption() {
		return this.form.get('isEnableEmailOption').value;
	}

	onSave() {
		this.submitted = true;

		if (
			this.carbonCopy.errors?.emailCommaSeparated ||
			this.blindCarbonCopy.errors?.emailCommaSeparated ||
			this.sender?.errors?.emailCommaSeparated
		) {
			this.loggerService.Warning(null, getInvalidWarning(Fields.EmailAddress));
			return;
		}

		const form = {
			...this.form.getRawValue(),
		};

		if (!this.showFromField) {
			delete form.sender;
		}

		this.save.emit({
			...form,
			bodyContent: this.editor?.content,
		});
		this.bodyContent = this.editor?.content;
	}
}
