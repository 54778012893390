import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { clone, multiply } from 'ramda';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	debounceTime,
	filter,
	map,
	mergeMap,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/base/component-base';
import { numUtil, objectUtil } from 'src/app/util/util';
import { computeUtil } from '../../../_shared/calculations/funding-required';
import { computeMoatAppUtil } from '../../../_shared/calculations/moat-application';
import { PropertyPurchaseDetailsState } from '../../application/application-steps/funding-required/property-purchase/state/property-purchase.model';
import { SecurityMapper } from '../../application/application-steps/security/state/security.mapper';
import { Application } from '../../application/state/application.model';
import { PropertyState } from '../../client-sop/assets-and-liabilities/state/property/property.model';
import { PropertyService } from '../../client-sop/assets-and-liabilities/state/property/property.service';
import { LoanStructure } from '../loan/state/loan.model';
import { LoanService } from '../loan/state/loan.service';
import { StructureSoaSecurity } from './state/structure-soa-security.model';
import { StructureSoaSecurityQuery } from './state/structure-soa-security.query';
import { StructureSoaSecurityService } from './state/structure-soa-security.service';
import { StructureSoaSecurityStore } from './state/structure-soa-security.store';
import { PropertyPurchaseService } from '../../application/application-steps/funding-required/property-purchase/state/property-purchase.service';
import { LoggerService } from 'src/app/core/logger/logger.service';

@Component({
	selector: 'app-structure-soa-security',
	templateUrl: './structure-soa-security.component.html',
	exportAs: 'structureSoaSecurity',
})
export class StructureSoaSecurityComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
	}> = new EventEmitter<{ isSuccess: boolean; isNext: boolean }>();

	@Input() parentCRTId: string;

	@Input() application: Application;

	@Input() adviceProcessId: string;

	isLoading$ = this.service.isLoading$;

	// for mini loader
	miniLoaderIsLoading = false;

	loanStructure: LoanStructure;

	securities$ = this.service.securities$;
	propertyPurchase$ = of([]);
	ffProperties$ = this.propertyService.properties$;
	propertyPurchases$ = this.propertyPurchaseService.propertyPurchases$;
	propertyPurchase: PropertyPurchaseDetailsState[];
	ffProperties: PropertyState[];
	submitted = false;

	formGroup = this.fb.group({
		bankWillTake: this.fb.array([]),
		totalSecurityValue: this.fb.control(''),
		approvedTotalDebt: this.fb.control(''),
		approvedLVR: this.fb.control(''),
		scaledLVR: this.fb.control(''),
	});

	get bankWillTake() {
		return this.formGroup.get('bankWillTake') as UntypedFormArray;
	}

	get TotalSecurityValue() {
		return this.formGroup.get('totalSecurityValue');
	}

	get ApprovedTotalDebt() {
		return this.formGroup.get('approvedTotalDebt');
	}

	get ApprovedLVR() {
		return this.formGroup.get('approvedLVR');
	}

	get ScaledLVR() {
		return this.formGroup.get('scaledLVR');
	}

	get isNoSecurity() {
		const securities = this.bankWillTake?.value?.filter((a) => !!a?.isTick);
		return securities?.length === 0;
	}

	constructor(
		private service: StructureSoaSecurityService,
		private fb: UntypedFormBuilder,
		private query: StructureSoaSecurityQuery,
		private store: StructureSoaSecurityStore,
		private loanService: LoanService,
		private propertyService: PropertyService,
		private propertyPurchaseService: PropertyPurchaseService,
		private loggerService: LoggerService
	) {
		super();
	}

	ngOnInit(): void {
		super.subscribe(
			this.bankWillTake.valueChanges.pipe(debounceTime(500)),
			(res) => {
				this.store.update(this.parentCRTId, res);
			}
		);
	}

	ngOnDestroy(): void {
		super.dispose();
	}

	prepareFormdata(security: StructureSoaSecurity): StructureSoaSecurity {
		this.bankWillTake.clear();
		security?.bankWillTake?.forEach((take) => {
			this.bankWillTake.push(
				this.fb.group({
					aLPropertiesCrtId: [take?.aLPropertiesCrtId],
					propertyAddress: [take?.propertyAddress],
					amount: [take?.amount],
					isTick: [take?.isTick],
					fromFactFind: [take?.fromFactFind],
				})
			);
		});
		return security;
	}

	/**
	 * NOTE this method is involed in StructureSoa component
	 * when Security tab is selected
	 */
	load(): void {
		this.miniLoaderIsLoading = true;
		const securitiesSub = this.service
			.get(+this.parentCRTId, +this.adviceProcessId)
			.pipe(
				map((x) => this.prepareFormdata(x[0])),
				switchMap(() => this.loanService.loanStructure$),
				tap(
					(loanStructure: LoanStructure) => (this.loanStructure = loanStructure)
				),
				concatMap((x) =>
					this.propertyPurchaseService.get(x?.approvedApplication)
				),
				withLatestFrom(this.ffProperties$),
				tap(([propertyPurchase, ffProperties]) => {
					this.ffProperties = ffProperties;
					this.propertyPurchase = propertyPurchase;
					this.recomputeTotals();
					this.miniLoaderIsLoading = false;
				}),
				catchError(() => {
					this.miniLoaderIsLoading = false;
					return of(null);
				})
			);
		super.subscribe(securitiesSub);
	}

	formErrors() {
		return of(this.formGroup.getRawValue()).pipe(
			map((x) => {
				const securities = x?.bankWillTake?.filter((a) => !!a?.isTick);
				const errors = [];
				if (securities?.length === 0) {
					errors.push('At least one Security is required');
				}
				return errors;
			}),
			take(1)
		);
	}

	save(isNext = false, checkValidations: boolean = true): void {
		const formValue = this.formGroup.getRawValue();
		const security = clone(this.query.getAll()?.[0]);
		const bwt = this.bankWillTake
			.getRawValue()
			?.map(objectUtil.mapCamelCaseToPascalCase);
		if (security) {
			security.bankWillTake = bwt;
		}

		this.formErrors()
			.pipe(
				filter((errors) => {
					if (checkValidations && errors?.length > 0) {
						if (this.submitted) {
							// If already submitted, allow to proceed regardless of requirements
							this.submitted = false;
							return true;
						} else {
							// If first time submission and incomplete form requirements
							this.submitted = true;
							this.saveCompleted.emit({ isSuccess: false, isNext });
							this.loggerService.MultipleWarnings(errors);
							return false;
						}
					} else {
						this.submitted = false;
						return true;
					}
				}),
				mergeMap(() => of(security)),
				map((data) => ({
					...data,
					approvedLVR: formValue?.approvedLVR || 0,
					scaledLVR: formValue?.scaledLVR || 0,
					approvedTotalDebt: formValue?.approvedTotalDebt || 0,
					totalSecurityValue: formValue?.totalSecurityValue || 0,
					parentCRTId: this.parentCRTId,
				})),
				concatMap((x) => this.service.update(x)),
				tap(
					(next) => {
						this.saveCompleted.emit({ isSuccess: true, isNext });
					},
					(err) => this.saveCompleted.emit({ isSuccess: false, isNext: false })
				),
				take(1)
			)
			.subscribe();
	}

	getApprovedTotalDebt() {
		return this.loanStructure?.approvedMortgageAmount || 0;
	}

	getTotalSecurityValue() {
		const bwt = this.bankWillTake.getRawValue();
		const result = bwt?.reduce(
			(prev, curr) => (prev += curr.isTick ? curr.amount : 0),
			0
		);
		return +numUtil.formatToNumCurrency(result);
	}

	getApprovedLVR() {
		// Approved LVR = (Approved Total Debt / Total Security Value) * 100
		const totalSecurityValue = this.getTotalSecurityValue();
		const approvedTotalDebt = this.getApprovedTotalDebt();
		if (totalSecurityValue > 0) {
			let result = multiply(approvedTotalDebt / totalSecurityValue, 100);
			result = Math.ceil(result);
			return +result?.toFixed(0);
		} else {
			return 0;
		}
	}

	getScaledLVR() {
		const securities = SecurityMapper.getSecurityDetails(
			this.bankWillTake.getRawValue(),
			this.propertyPurchase,
			this.ffProperties
		);
		const totalDebt = this.getApprovedTotalDebt() || 0;
		const totalMaxLoanAmount =
			computeMoatAppUtil.totalMaxLoanAmount(securities) || 0;

		return computeUtil.scaledLVR(totalDebt, totalMaxLoanAmount) || 0;
	}

	recomputeTotals() {
		const totals = {
			totalSecurityValue: this.getTotalSecurityValue(),
			approvedTotalDebt: this.getApprovedTotalDebt(),
			approvedLVR: this.getApprovedLVR(),
			scaledLVR: this.getScaledLVR(),
		};
		this.formGroup.patchValue(totals);
	}
}
