<header
	class="crm-header container-fluid border-bottom tap-bg-primary tap-header-username-color"
>
	<div class="row header h-100">
		<div
			class="bg-white col-12 col-lg-2 d-flex justify-content-center align-items-center"
		>
			<div class="header-logo-container p-2">
				<app-logo
					*ngIf="isTapLevelView; else logo"
					[routerLink]="tapLevelLogoLink"
					[src]="tapLevelLogoUrl"
				></app-logo>
			</div>
		</div>
		<div
			class="col-lg-10 col-12 d-flex justify-content-center justify-content-lg-end align-items-center py-lg-0 py-3"
		>
			<h1
				*ngIf="showAnnouncementHeader$ | async"
				class="announcement-header font-weight-normal position-absolute pl-4 d-none d-lg-block"
			>
				Announcement
			</h1>
			<div
				class="h-100 d-flex justify-content-center justify-content-lg-end align-items-center"
				[ngClass]="{ clickable: hasPopover }"
				(click)="popoverIsShown = !popoverIsShown"
			>
				<ngx-avatars
					class="mr-3"
					[src]="googleImageUrl$ | async"
					[name]="name$ | async"
					[round]="true"
					size="30"
				></ngx-avatars>
				<div class="app-user-name align-self-center mr-3">
					{{ name$ | async }}
				</div>
				<div
					class="app-popover-btn align-self-center border-left px-2"
					*ngIf="hasPopover"
				>
					<div class="carret"></div>
				</div>
			</div>
			<div class="pt-70px">
				<app-popover *ngIf="popoverIsShown"></app-popover>
			</div>
		</div>
	</div>
</header>

<ng-template #logo>
	<app-logo [routerLink]="logoLink" [src]="logoUrl"></app-logo>
</ng-template>
