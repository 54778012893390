import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { akitaConfig } from '@datorama/akita';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then((ref) => {
		const NG_REF = 'ngRef';
		if (window[NG_REF]) {
			window[NG_REF].destroy();
		}
		window[NG_REF] = ref;
	})
	.catch((err) => console.log(err));

akitaConfig({
	resettable: true,
});
