import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { Paging } from '../../../../shared/models/_general/paging.model';
import * as R from 'ramda';
export interface ActivitySearchModel {
  CustomerId: number;
  ActivityId: number;
  AdviserId: number;
  AssignedToName: string;
  IsAccessible: boolean;
  IsCompany: boolean;

  ClientName: string;
  AssignedToId: number;
  AdviserName: string;
  ActivityType: string;
  ActivityName: string;
  Details?: string;

  LRAdviserName: string;
  GIAdviserName: string;
  MortgageAdviserName: string;
  FGAdviserName: string;
  KSAdviserName: string;
  InvestmentAdviserName: string;

  CreateDate: string;
  DueDate: string;
  CompleteDate: string;
  CancelledDate: string;

  ActivityStatus: string;

  ContactStatus: string;
  ContactType: string;
  CreatedByName?: string;
  LeadGenName?: string;
  LeadOrigin?: string;

  link?: {
    ActivityId: number;
  };

  Meeting?: string;
}

export interface MetaPair<T> {
  MetaKey: string;
  Value: T;
}

export interface ActivityRequest {
  Advisers: any[];
  AltAdvisers: any[];
  AssignedTo: number[];
  CreatedBy: number[];
  ActivityCreateDateMin: string;
  ActivityCreateDateMax: string;
  ActivityDueDateMin: string;
  ActivityDueDateMax: string;
  ActivityCompleteDateMin: string;
  ActivityCompleteDateMax: string;
  ActivityType?: any[];
  ClientFirstName: string;
  ClientSurname: string;
  ActivityStatus: {
    IsCompleted: boolean;
    IsCancelled: boolean;
    IsPending: boolean;
  };
  AdviserStatus: number[];
  // DateMin?: string;
  // DateMax?: string;
  // IsCancelled?: boolean;
  // IsCompleted?: boolean;
  // PagingIndex?: number;
  Paging?: Paging;
}

export interface Row {
  CustomerId: number;
  ActivityId: number;
  AdviserId: number;
  AssignedToName: string;
  IsAccessible: boolean;
  IsCompany: boolean;

  ClientName: FieldMetadata<string>;
  AssignedToId: FieldMetadata<string>;
  AdviserName: FieldMetadata<string>;
  LRAdviserName: FieldMetadata<string>;
  GIAdviserName: FieldMetadata<string>;
  MortgageAdviserName: FieldMetadata<string>;
  FGAdviserName: FieldMetadata<string>;
  KSAdviserName: FieldMetadata<string>;
  InvestmentAdviserName: FieldMetadata<string>;
  ActivityType: FieldMetadata<string>;
  ActivityName: FieldMetadata<string>;
  Details: FieldMetadata<string>;

  CreateDate: FieldMetadata<string>;
  DueDate: FieldMetadata<string>;
  CompleteDate: FieldMetadata<string>;
  CancelledDate: FieldMetadata<string>;

  ActivityStatus: FieldMetadata<string>;

  link: {
    ActivityId: number;
  };

  ContactStatus?: string;
  ContactType?: string;
  CreatedByName?: string;
  LeadGenName?: string;
  LeadOrigin?: string;

  Meeting: FieldMetadata<string>;
}

export interface GetActivitiesResponse {
  TotalCount: number;
  IsComplete: boolean;
  SearchResults: ActivitySearchModel[];
}

export enum ActivityStatus {
  Pending = 'Pending',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export enum ActivityStatusBool {
  Pending = 'IsPending',
  Completed = 'IsCompleted',
  Cancelled = 'IsCancelled'
}

export enum AdviserStatus {
  Inactive = '0',
  Active = '1',
  Paused = '2'
}

export const GetActivityDisplayValue = () => {
  const keys = Object.keys(ActivityStatusBool);
  const activityStatuses: ViewDisplayValue[] = [];
  const makeViewDisplay = (key: string) => {
      activityStatuses.push({
        display: key,
        value: ActivityStatusBool[key as keyof typeof ActivityStatusBool]
      })
  }
  R.forEach(makeViewDisplay, keys);
  return activityStatuses;
}
