import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { ApiService } from '../../core/base/api.service';
import { DropdownValueService } from '../../domain/dropdown-value/dropdown-value.service';
import { Observable, forkJoin, of } from 'rxjs';

const dropdownCodes: string[] = [
  // Client
  'PCIF', 'PCCF', 'SCIF', 'SCTF',
  // Staff
  'SF', 'AAMF',
  // Services
  'CSLRF', 'CSSF', 'CSMF', 'CSFGF', 'CSKF', 'CSIF',
  // Others
  'NF', 'AF', 'DF', 'KMNF'
];

@Injectable()
export class DataImportService implements Resolve<boolean> {
  constructor(
    protected api: ApiService,
    private dropdownValuesService: DropdownValueService
  ) { }

  resolve(): Observable<boolean> {
    return forkJoin(
      [this.dropdownValuesService.getDropdownValueList$(dropdownCodes)]
    ).pipe(mapTo(true));
  }

  importData(req: any): Observable<any> {
    return this.api.post3<any>('import', req);
  }

  migrateDate(req: FormData): Observable<any> {
    return of(null);
  }

  getImportHistory(req: string[]): Observable<any> {
    return this.api.get<any>(`import?type=${req}`);
  }

  cancelImport(req: number): Observable<any> {
    return this.api.post3<any>(`import/cancel/${req}`);
  }

  export() {
    return of(null);
  }
}
