import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { viewSecGroup } from '../../../viewmodels/viewSecGroup';
import { viewBusiness } from '../../../viewmodels/viewBusiness';
import { TlStaffViewModel } from '../../../viewmodels/tl-staff.viewmodel';
import { RouteService } from '../../../../../core/config/route.service';
import { TLStaffSecGroupsQuery } from '../../../../../domain/tl-staff-security-group/tl-staff-security-groups.query';
@Component({
	selector: 'app-tl-view',
	templateUrl: './tl-view.component.html'
})
export class TlViewComponent implements OnInit, OnDestroy {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private routeService: RouteService,
		private tlStaffSecGroupQuery: TLStaffSecGroupsQuery
	) {}
	subs: Subscription[] = [];
	staff: TlStaffViewModel;
	secGroups: viewSecGroup[] = [];
	// tl business
	businesses: viewBusiness[] = [];
	ngOnInit() {
		const sg = this.tlStaffSecGroupQuery.getAll();
		this.secGroups = viewSecGroup.mapSecGroups(sg);

		const sub = this.route.data.subscribe((data: { staff; businesses }) => {
			this.staff = TlStaffViewModel.MapToViewModel(data.staff);
			// tl business choice
			this.businesses = viewBusiness.mapBusinesses(data.businesses);
		});
		this.subs.push(sub);
	}

	ngOnDestroy(): void {
		this.subs?.forEach(s => s.unsubscribe());
	}

	goToEdit = () =>
		new Observable(obs => {
			this.router.navigate(this.routeService.splitToRoutePath(this.routeService.adminUsersEdit(this.staff.StaffID)));
			obs.next();
			obs.complete();
		});

	cancel() {
		this.router.navigate(this.routeService.splitToRoutePath(this.routeService.adminUsersList));
	}
}
