import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DisclosureDocumentState, DisclosureState } from './disclosure.model';

export interface koatDisclosureState extends EntityState<DisclosureState> {
	settings: DisclosureDocumentState;
}

@Injectable()
@StoreConfig({ name: 'disclosureDocument', idKey: 'cRTId' })
export class DisclosureStore extends EntityStore<koatDisclosureState> {
	constructor() {
		super();
	}
	setDisclosureSettings(settings: DisclosureDocumentState) {
		this.update((state) => ({ settings }));
	}
}
