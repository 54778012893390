import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { ClientReferralSettingReferral } from '@modules/special-features/client-referral-setting/state/client-referral-setting.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, defer, mergeMap, take, tap } from 'rxjs';
import { ClientReferralLinkModalComponent } from './client-referral-link-modal.component';

@Injectable()
export class ClientReferralLinkModalService {
	constructor(
		private modalService: BsModalService,
		private api: ApiService
	) {}

	referLink(referral: ClientReferralSettingReferral): Observable<boolean> {
		const payload = {
			ActivityName: referral.referralService,
			ActivityType: 'Referral',
			Adviser: referral.adviser,
			AdviceProcessId: referral.adviceProcessId,
			Location: '',
			Appointment: true,
			CustomerId: referral.customerId,
			CustomerName: referral.customerName,
			Details: `Referral Form (${referral.referralLink}) for ${referral.referralService} completed.`,
			DueDate: '',
			DueTime: '',
			Duration: 30,
			IsCompleted: true,
			IsActive: true,
			Meeting: 'Phone Meeting',
		};
		return this.api.post(`activities/referral`, payload).pipe(
			take(1),
			mergeMap((result) => this.addNotes(referral))
		);
	}

	private addNotes(referral: ClientReferralSettingReferral): Observable<any> {
		const isOAT = Boolean(referral.adviceProcessId);
		if (isOAT) {
			return this.api.post(`notes/crt`, {
				ReferenceId: referral.adviceProcessId,
				Notes: `Referral Form for ${referral.referralService} completed`,
				Type: 'AP',
			});
		}
		return this.api.post(`notes`, {
			CustomerServiceID: '0',
			Notes: `Referral Form for ${referral.referralService} completed`,
			CustomerID: referral.customerId,
			IsActivity: true,
			Type: '',
		});
	}

	show(
		referral: ClientReferralSettingReferral
	): Observable<ClientReferralSettingReferral> {
		window.open(referral.referralLink, '_blank');
		return defer(() => {
			const subject = new Subject<ClientReferralSettingReferral>();
			const modalRef = this.modalService.show(
				ClientReferralLinkModalComponent,
				{
					id: 'client-referral-link-modal',
					ignoreBackdropClick: true,
					class: 'modal-dialog-centered modal-lg client-referral-link-modal',
					initialState: {
						subject,
						referral,
						saveFn$: (r) => this.referLink(r),
					},
					show: true,
					keyboard: false,
				}
			);
			return subject.asObservable().pipe(tap(() => modalRef.hide()));
		});
	}
}
