import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CrtBannerSimpleComponent } from './crt-banner-simple.component';

const components = [CrtBannerSimpleComponent];

@NgModule({
	declarations: components,
	exports: components,
	imports: [CommonModule],
})
export class CrtBannerSimpleModule {}
