<tabset
	#tpdTabset
	class="tap-tabset tap-tabset--border-radius-left-right tap-client-profile__tabset tabset-ra"
>
	<tab
		*ngFor="let person of people$ | async; let arrayIndex = index"
		heading="{{ person.name }}"
		id="tpdTabset"
		[customClass]="'m-0 tpd-container'"
		(selectTab)="setActive(arrayIndex)"
		[disabled]="isLoading"
	>
		<div *ngIf="isLoading; else showForm" class="d-block ml-4">
			<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
		</div>

		<ng-template #showForm>
			<div class="main-form">
				<div class="form-row px-4">
					<div class="col-12">
						<div class="form-row collapse-label-background pt-4">
							<div class="col-8 mb-3">
								<label
									for=""
									class="font-weight-bold label-font tap-text-primary"
								>
									{{ person.name }}, if you were disabled and could not return
									to your job, what income would you and your family need?
								</label>
							</div>
						</div>
						<form [formGroup]="form">
							<div class="" id="collapse">
								<div class="form-row">
									<div class="col-1"></div>
									<div
										class="col-5 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										Lost After Tax Income per month:
									</div>
									<div class="col-2 mb-2">
										<div class="dollar-icon">
											<input
												id="lostAfterTaxIncomePerMonthId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="lostAfterTaxIncomePerMonth"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>
								<div class="form-row">
									<div class="col-3 col-md-2"></div>
									<div
										class="col-3 col-md-4 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										<div>
											Monthly Surplus /
											<span class="text-danger">(Shortfall)</span>:
										</div>
									</div>
									<div class="col-2 mb-2">
										<div class="dollar-icon">
											<input
												id="monthlySurplusShortfallLostId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="monthlySurplusShortfallLost"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>
								<div formArrayName="expensesCouldStop">
									<div
										class="form-row"
										*ngFor="
											let item of ExpensesCouldStop.controls;
											let pointIndex = index;
											let f = first;
											let l = last
										"
										[formGroupName]="pointIndex"
									>
										<div class="col-12 d-lg-none d-xl-block col-xl-1"></div>
										<div class="col-12 col-lg-3 col-xl-2 text-right">
											<span *ngIf="f"
												>Are there any monthly expenses that would stop?</span
											>
										</div>
										<div class="col-12 col-lg-3 mb-2">
											<span class="crt-custom-dropdown w-100">
												<select
													id="expensesStopDropdownId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													class="form-control crt-form-control"
													formControlName="dropdown"
													(change)="onChange()"
												>
													<option value=""></option>
													<option
														*ngFor="let dropdown of APCRTE$ | async"
														[value]="dropdown.value"
													>
														{{ dropdown.display }}
													</option>
												</select>
											</span>
										</div>
										<div class="col-12 col-lg-2 mb-2">
											<div class="dollar-icon">
												<input
													id="expensesStopValueId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													class="form-control crt-form-control"
													type="text"
													formControlName="value"
													(focusout)="onChange()"
													currencyMask
													appCurrencyMaskExt
												/>
												<i>$</i>
											</div>
										</div>
										<div
											class="d-block col-lg-3 align-items-center d-flex mb-2 text-left"
										>
											<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
												<button
													id="removeExpensesCouldStopBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													*ngIf="ExpensesCouldStop.length > 1"
													class="icon-btn w-auto h-auto px-1"
													(click)="removeList(ExpensesCouldStop, pointIndex)"
												>
													<i class="material-icons md-16 d-flex"> delete </i>
												</button>
												<button
													id="clearExpensesCouldStopBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													class="icon-btn w-auto h-auto px-1"
													(click)="clear(ExpensesCouldStop, pointIndex)"
												>
													<i class="material-icons md-16">clear</i>
												</button>
												<button
													id="addExpensesCouldStopBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													class="tap-btn tap-btn-outline tap-btn--shadow col-auto px-4 d-flex"
													(click)="addExpenseStop()"
													*ngIf="l"
												>
													Add +
												</button>
											</ng-container>
										</div>
									</div>
								</div>
								<hr class="mb-3" />
								<div formArrayName="incomeCouldStart">
									<div
										class="form-row"
										*ngFor="
											let item of IncomeCouldStart.controls;
											let pointIndex = index;
											let f = first;
											let l = last
										"
										[formGroupName]="pointIndex"
									>
										<div class="col-12 d-lg-none d-xl-block col-xl-1"></div>
										<div class="col-12 col-lg-3 col-xl-2 text-right">
											<span *ngIf="f"
												>Is there any monthly income that would start?</span
											>
										</div>
										<div class="col-12 col-lg-3 mb-2">
											<span class="crt-custom-dropdown w-100">
												<select
													id="incomeStartDropdownId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													class="form-control crt-form-control"
													formControlName="dropdown"
													(change)="onChange()"
												>
													<option value=""></option>
													<option
														*ngFor="let dropdown of APCRTIID$ | async"
														[value]="dropdown.value"
													>
														{{ dropdown.display }}
													</option>
												</select>
											</span>
										</div>
										<div class="col-12 col-lg-2 mb-2">
											<div class="dollar-icon">
												<input
													id="incomeStartValueId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													class="form-control crt-form-control"
													type="text"
													formControlName="value"
													(focusout)="onChange()"
													currencyMask
													appCurrencyMaskExt
												/>
												<i>$</i>
											</div>
										</div>
										<div
											class="d-block col-lg-3 text-left align-items-center d-flex mb-2"
										>
											<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
												<button
													id="removeIncomeCouldStartBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													*ngIf="IncomeCouldStart.length > 1"
													class="icon-btn w-auto h-auto px-1 d-flex"
													(click)="removeList(IncomeCouldStart, pointIndex)"
												>
													<i class="material-icons md-16"> delete </i>
												</button>
												<button
													id="clearIncomeCouldStartBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													class="icon-btn w-auto h-auto px-1 d-flex"
													(click)="clear(IncomeCouldStart, pointIndex)"
												>
													<i class="material-icons md-16">clear</i>
												</button>
												<button
													id="addIncomeCouldStartBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													class="tap-btn tap-btn-outline tap-btn--shadow col-auto px-4"
													(click)="addIncome()"
													*ngIf="l"
												>
													Add +
												</button>
											</ng-container>
										</div>
									</div>
								</div>
								<hr class="mb-3" />
								<div formArrayName="expensesCouldStart">
									<div
										class="form-row"
										*ngFor="
											let item of ExpensesCouldStart.controls;
											let pointIndex = index;
											let f = first;
											let l = last
										"
										[formGroupName]="pointIndex"
									>
										<div class="col-12 d-lg-none d-xl-block col-xl-1"></div>
										<div class="col-12 col-lg-3 col-xl-2 text-right">
											<span *ngIf="f"
												>Are there any monthly expenses that would start?</span
											>
										</div>
										<div class="col-12 col-lg-3 mb-2">
											<span class="crt-custom-dropdown w-100">
												<select
													id="expensesStartDropdownId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													class="form-control crt-form-control"
													formControlName="dropdown"
													(change)="onChange()"
												>
													<option value=""></option>
													<option
														*ngFor="let dropdown of APCRTESI$ | async"
														[value]="dropdown.value"
													>
														{{ dropdown.display }}
													</option>
												</select>
											</span>
										</div>
										<div class="col-12 col-lg-2 mb-2">
											<div class="dollar-icon">
												<input
													id="expensesStartValueId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													class="form-control crt-form-control"
													type="text"
													formControlName="value"
													(focusout)="onChange()"
													currencyMask
													appCurrencyMaskExt
												/>
												<i>$</i>
											</div>
										</div>
										<div
											class="d-block col-lg-3 text-left align-items-center d-flex mb-2"
										>
											<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
												<button
													id="removeExpensesCouldStartBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													*ngIf="ExpensesCouldStart.length > 1"
													class="icon-btn w-auto h-auto px-1 d-flex"
													(click)="removeList(ExpensesCouldStart, pointIndex)"
												>
													<i class="material-icons md-16"> delete </i>
												</button>
												<button
													id="clearExpensesCouldStartBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													class="icon-btn w-auto h-auto px-1 col-auto d-flex"
													(click)="clear(ExpensesCouldStart, pointIndex)"
												>
													<i class="material-icons md-16">clear</i>
												</button>
												<button
													id="addExpensesCouldStartBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													class="tap-btn tap-btn-outline tap-btn--shadow col-auto px-4"
													(click)="addExpenseStart()"
													*ngIf="l"
												>
													Add +
												</button>
											</ng-container>
										</div>
									</div>
								</div>
								<hr class="mb-3" />
								<div class="form-row">
									<div class="col-3"></div>
									<div
										class="col-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										<div>
											Monthly Surplus /
											<span class="text-danger">(Shortfall)</span>:
										</div>
									</div>
									<div class="col-2 mb-2">
										<div class="dollar-icon">
											<input
												id="monthlySurplusShortfallStopStart_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="monthlySurplusShortfallStopStart"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>
							</div>
							<div class="form-row collapse-label-background pt-2">
								<div class="col-8 mb-3">
									<label
										for=""
										class="font-weight-bold label-font tap-text-primary"
									>
										What assets would you like to sell?
									</label>
								</div>
							</div>

							<div class="" id="collapseAsset">
								<div class="form-row">
									<div class="col-12 col-lg-6 mb-2">
										<label for="" class=""> </label>
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<label for="" class=""> Value </label>
									</div>
									<div class="col-12 col-lg-3 mb-2">
										<label for="" class=""> Sell? </label>
									</div>
								</div>

								<div formArrayName="propertiesList">
									<div
										class="form-row"
										*ngFor="
											let item of PropertiesList.controls;
											let pointIndex = index
										"
										[formGroupName]="pointIndex"
									>
										<div class="col-12 col-lg-6 mb-2">
											<input
												id="propertyId_{{ arrayIndex }}_{{ pointIndex }}"
												class="form-control crt-form-control"
												placeholder="Property"
												formControlName="property"
												type="text"
											/>
										</div>
										<div class="col-12 col-lg-2 mb-2">
											<div class="dollar-icon">
												<input
													id="propertyValueId_{{ arrayIndex }}_{{ pointIndex }}"
													class="form-control crt-form-control"
													placeholder="Value"
													formControlName="value"
													type="text"
													currencyMask
												/>
												<i>$</i>
											</div>
										</div>
										<div class="col-12 col-lg-2 col-xl-1 mb-2 d-flex">
											<div class="pr-2 d-flex">
												<input
													id="propertySellId_{{ arrayIndex }}_{{ pointIndex }}"
													class="m-auto"
													type="checkbox"
													[checked]="
														form.get('propertiesList.' + pointIndex + '.sell')
															?.value === 'Yes'
													"
													[value]="'Yes'"
													[disabled]="isAdviceProcessEnded$ | async"
													(click)="
														onClickCheckBoxSell(
															'propertiesList',
															pointIndex,
															'Yes'
														)
													"
												/>
											</div>
										</div>
									</div>
								</div>

								<div formArrayName="assetsList">
									<div
										class="form-row mb-2"
										*ngFor="
											let item of AssetsList.controls;
											let pointIndex = index
										"
										[formGroupName]="pointIndex"
									>
										<div class="col-12 col-lg-6 mb-2">
											<input
												id="assetsId_{{ arrayIndex }}_{{ pointIndex }}"
												class="form-control crt-form-control"
												placeholder="Property"
												formControlName="asset"
												type="text"
											/>
										</div>
										<div class="col-12 col-lg-2 mb-2">
											<div class="dollar-icon">
												<input
													id="assetsValueId_{{ arrayIndex }}_{{ pointIndex }}"
													class="form-control crt-form-control"
													placeholder="Value"
													formControlName="value"
													type="text"
													currencyMask
												/>
												<i>$</i>
											</div>
										</div>
										<div class="col-12 col-lg-2 col-xl-1 mb-2 d-flex">
											<div class="pr-2 d-flex">
												<input
													id="assetsSellId_{{ arrayIndex }}_{{ pointIndex }}"
													class="m-auto"
													type="checkbox"
													[checked]="
														form.get('assetsList.' + pointIndex + '.sell')
															?.value === 'Yes'
													"
													[value]="'Yes'"
													[disabled]="isAdviceProcessEnded$ | async"
													(click)="
														onClickCheckBoxSell('assetsList', pointIndex, 'Yes')
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<hr class="mb-3" />

							<div class="form-row collapse-label-background pt-2">
								<div class="col-8 mb-2">
									<label
										for=""
										class="font-weight-bold label-font tap-text-primary"
									>
										What loans would you like to repay?
									</label>
								</div>
							</div>

							<div class="" id="collapseLoan">
								<div class="form-row">
									<div class="col-lg-2 text-right"></div>
									<div class="col-12 col-lg-2 mb-2">
										<label for="" class=""> Lender </label>
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<label for="" class=""> Loan Value </label>
									</div>
									<div class="col-12 col-lg-3 mb-2">
										<label for="" class=""> Repay </label>
									</div>
									<div class="d-none d-lg-block col-lg-1 text-right"></div>
								</div>

								<div formArrayName="loansList">
									<div
										class="form-row mb-2"
										*ngFor="
											let item of LoansList.controls;
											let pointIndex = index
										"
										[formGroupName]="pointIndex"
									>
										<div class="col-lg-2 text-right"></div>
										<div class="col-12 col-lg-2 mb-2">
											<input
												id="loansId_{{ arrayIndex }}_{{ pointIndex }}"
												class="form-control crt-form-control"
												placeholder="Lender"
												formControlName="lender"
												type="text"
											/>
										</div>
										<div class="col-12 col-lg-2 mb-2">
											<div class="dollar-icon">
												<input
													id="loansValueId_{{ arrayIndex }}_{{ pointIndex }}"
													class="form-control crt-form-control"
													placeholder="Value"
													formControlName="value"
													type="text"
													currencyMask
												/>
												<i>$</i>
											</div>
										</div>
										<div class="col-12 col-lg-2 mb-2">
											<span class="crt-custom-dropdown w-100">
												<select
													id="loansRepayId_{{ arrayIndex }}_{{ pointIndex }}"
													class="form-control crt-form-control"
													formControlName="repay"
													(change)="onChange()"
												>
													<option
														value=""
														[attr.disabled]="true"
														selected
														hidden
													></option>
													<option
														*ngFor="let dropdown of APCRTR$ | async"
														[value]="dropdown.value"
													>
														{{ dropdown.display }}
													</option>
												</select>
											</span>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										<div>
											Monthly Surplus /
											<span class="text-danger">(Shortfall)</span>:
										</div>
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="monthlySurplusShortfallLoansId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="monthlySurplusShortfallLoans"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										Annual Surplus / Shortfall:
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="annualSurplusShortfallStopStartId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="annualSurplusShortfallStopStart"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div class="form-row mb-2">
									<div class="col-lg-2"></div>
									<div
										class="col-12 col-lg-4 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										How many years do you need this shortfall covered?
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<input
											appTwoDigitDecimalNumberV2
											id="yearsShortfallCoveredId_{{ arrayIndex }}"
											class="form-control crt-form-control"
											type="text"
											formControlName="yearsShortfallCovered"
											(change)="onChange()"
										/>
									</div>
								</div>
							</div>
							<hr class="mb-3" />
							<div class="form-row collapse-label-background pt-2">
								<div class="col-8 mb-2">
									<label
										for=""
										class="font-weight-bold label-font tap-text-primary"
									>
										TPD Insurance Requirement Summary:
									</label>
								</div>
							</div>

							<div class="" id="collapseInsurance">
								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										Debt Repayment
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="debtRepaymentId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="debtRepayment"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										Family Income:
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="familyIncomeId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="familyIncome"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div formArrayName="requiredList">
									<div
										class="form-row"
										*ngFor="
											let item of RequiredList.controls;
											let pointIndex = index;
											let l = last
										"
										[formGroupName]="pointIndex"
									>
										<div class="col-lg-3"></div>
										<div
											class="col-12 col-lg-3 mb-2"
											*ngIf="item.get('dropdown').value !== 'Other'"
										>
											<span class="crt-custom-dropdown w-100">
												<select
													id="requiredsId_{{ arrayIndex }}_{{ pointIndex }}"
													class="form-control crt-form-control required-list"
													dir="rtl"
													formControlName="dropdown"
													(change)="onChange()"
												>
													<option value="" selected></option>
													<option
														*ngFor="let dropdown of APCRTLTCR$ | async"
														[value]="dropdown.value"
													>
														{{ dropdown.display }}
													</option>
												</select>
											</span>
										</div>
										<div
											class="col-12 col-lg-3 mb-2"
											*ngIf="item.get('dropdown').value === 'Other'"
										>
											<input
												id="othersId_{{ arrayIndex }}_{{ pointIndex }}"
												class="form-control crt-form-control text-right"
												placeholder="Other"
												formControlName="dropdownValue"
												(keyup)="onChangeOther($event, item)"
												(focusout)="onChangeOther($event, item)"
												type="text"
											/>
										</div>
										<div class="col-12 col-lg-2 mb-2 mb-2">
											<div class="dollar-icon">
												<input
													id="requiredsValueId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													class="form-control crt-form-control"
													placeholder="Value"
													formControlName="value"
													type="text"
													(focusout)="onChangeOther($event, item)"
													currencyMask
													appCurrencyMaskExt
												/>
												<i>$</i>
											</div>
										</div>
										<div
											class="d-block col-lg-3 text-left align-items-center d-flex mb-2"
										>
											<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
												<button
													id="removeRequiredsBtn_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													*ngIf="RequiredList.length > 1"
													class="icon-btn w-auto h-auto px-1 d-flex"
													(click)="removeList(RequiredList, pointIndex)"
												>
													<i class="material-icons md-16"> close </i>
												</button>
												<button
													id="addRequiredsBtnId_{{ arrayIndex }}_{{
														pointIndex
													}}"
													type="button"
													class="tap-btn tap-btn-outline tap-btn--shadow col-auto px-4"
													(click)="addRequired()"
													*ngIf="l"
												>
													Add +
												</button>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										Total Required:
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="totalRequiredId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="totalRequired"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										<span class="font-italic text-pre">less </span> Kiwisaver
										Paid Out:
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="lessKiwiSaverPaidOutId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="lessKiwiSaverPaidOut"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										<span class="font-italic text-pre">less </span> Net Property
										Sales:
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="lessNetPropertySalesId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="lessNetPropertySales"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div class="form-row">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right mb-2 d-flex align-items-center justify-content-end"
									>
										<span class="font-italic text-pre">less </span> Asset Sales:
									</div>
									<div class="col-12 col-lg-2 mb-2">
										<div class="dollar-icon">
											<input
												id="lessAssetSalesId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="lessAssetSales"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>

								<div class="form-row mb-2">
									<div class="col-lg-3"></div>
									<div
										class="col-12 col-lg-3 text-right d-flex align-items-center justify-content-end"
									>
										TPD Covered Required:
									</div>
									<div class="col-12 col-lg-2">
										<div class="dollar-icon">
											<input
												id="lTPDCoveredRequiredId_{{ arrayIndex }}"
												class="form-control crt-form-control"
												type="text"
												formControlName="lTPDCoveredRequired"
												currencyMask
											/>
											<i>$</i>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</ng-template>
	</tab>
</tabset>
