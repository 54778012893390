import { TableColumn } from '@swimlane/ngx-datatable';
import produce from 'immer';
import * as R from 'ramda';
import { FieldMetadata } from '../../../shared/dynamic-field/field-metadata.model';
import { Row, ActivitySearchModel } from './states/activity.model';
import * as moment from 'moment';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { datatableUtil } from 'src/app/util/datatable.util';

/**
 * possible metakeys
 */
export type Metakey =
	| 'Client Name'
	| 'Assigned To'
	| 'Adviser Name'
	| 'LR Adviser'
	| 'GI Adviser'
	| 'Mortgage Adviser'
	| 'FG Adviser'
	| 'KS Adviser'
	| 'Investment Adviser'
	| 'Activity Type'
	| 'Activity Name'
	| 'Details'
	| 'Meeting'
	| 'Create Date'
	| 'Due Date'
	| 'Complete Date'
	| 'Cancelled Date'
	| 'Activity Status';

/** possible control types. Move this somewhere more appropriate. */
export type controlType =
	| 'display'
	| 'checkbox'
	| 'long-display'
	| 'textbox'
	| 'note'
	| 'dropdown'
	| 'date'
	| 'activity'
	| 'display-date'
	| 'textarea';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof ActivitySearchModel;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (field: any, choices?: ViewDisplayValue[]) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
	columnId?: string;
	fieldId?: string;
};

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return R.propOr<string, ViewDisplayValue, string>(
		'',
		'display' as keyof FieldMetadata<any>,
		choiceItem
	);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => (field.value ? choices?.findIndex((x) => x.value === field.value) : null);
/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
	const b = R?.defaultTo('', date)?.split(/\D/);
	return b?.join('/');
};
/** Get value to be used for sorting.
 * replace YES with 1 and NO with 2 to sort
 */
const getServiceSortValue = (value: string): string => {
	const actualValue = R?.defaultTo('NO', value);
	if (actualValue?.indexOf('YES') === 0) {
		return actualValue?.replace('YES', '1');
	} else {
		return '2';
	}
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
	const splicedDate = R?.defaultTo('', note)?.slice(0, 10);
	return reformatDateString(splicedDate);
};

/** get sort value for checkboxes */
const getCheckboxSortValue = (field: FieldMetadata<any>) => {
	const isTrue: boolean =
		field.value === true ||
		(typeof field.value === typeof '' && field.value?.toLowerCase() === 'true');
	return R?.defaultTo('', isTrue ? '1' : '2');
};

/**
 * possible columns with their own configurations
 */
export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'Client Name',
		prop: 'ClientName',
		name: 'Client name',
		width: 200,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold fixed-column',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Client Name'),
		fieldId: datatableUtil.formatFieldId('Client Name'),
	},
	{
		metakey: 'Assigned To',
		prop: 'AssignedToId',
		name: 'Assigned to',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Assigned To'),
		fieldId: datatableUtil.formatFieldId('Assigned To'),
	},
	{
		metakey: 'Adviser Name',
		prop: 'AdviserName',
		name: 'Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Adviser Name'),
		fieldId: datatableUtil.formatFieldId('Adviser Name'),
	},
	{
		metakey: 'Activity Type',
		prop: 'ActivityType',
		name: 'Activity type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Activity Type'),
		fieldId: datatableUtil.formatFieldId('Activity Type'),
	},
	{
		metakey: 'Activity Name',
		prop: 'ActivityName',
		name: 'Activity name',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Activity Name'),
		fieldId: datatableUtil.formatFieldId('Activity Name'),
	},
	{
		metakey: 'Details',
		prop: 'Details',
		name: 'Details',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textarea',
		columnId: datatableUtil.formatColumnId('Details'),
		fieldId: datatableUtil.formatFieldId('Details'),
	},
	{
		metakey: 'Meeting',
		prop: 'Meeting',
		name: 'Meeting',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		isRequired: false,
		columnId: datatableUtil.formatColumnId('Meeting'),
		fieldId: datatableUtil.formatFieldId('Meeting'),
	},
	{
		metakey: 'Create Date',
		prop: 'CreateDate',
		name: 'Created Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display-date',
		columnId: datatableUtil.formatColumnId('Create Date'),
		fieldId: datatableUtil.formatFieldId('Create Date'),
	},
	{
		metakey: 'Due Date',
		prop: 'DueDate',
		name: 'Due Date',
		width: 200,
		headerClass: 'secondary-background',
		cellClass: 'activity',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Due Date'),
		fieldId: datatableUtil.formatFieldId('Due Date'),
	},
	{
		metakey: 'Complete Date',
		prop: 'CompleteDate',
		name: 'Completed Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display-date',
		columnId: datatableUtil.formatColumnId('Complete Date'),
		fieldId: datatableUtil.formatFieldId('Complete Date'),
	},
	{
		metakey: 'Cancelled Date',
		prop: 'CancelledDate',
		name: 'Cancelled Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display-date',
		columnId: datatableUtil.formatColumnId('Cancelled Date'),
		fieldId: datatableUtil.formatFieldId('Cancelled Date'),
	},
	{
		metakey: 'Activity Status',
		prop: 'ActivityStatus',
		name: 'Activity Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Activity Status'),
		fieldId: datatableUtil.formatFieldId('Activity Status'),
	},
];

export const futureWorkColumns: EnhancedTableColumn[] = [
	{
		metakey: 'LR Adviser',
		prop: 'LRAdviserName',
		name: 'L&R Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('LRAdviserName'),
		fieldId: datatableUtil.formatFieldId('LRAdviserName')
	}, 
	{
		metakey: 'GI Adviser',
		prop: 'GIAdviserName',
		name: 'Group Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('GIAdviserName'),
		fieldId: datatableUtil.formatFieldId('GIAdviserName')
	},
	{
		metakey: 'Mortgage Adviser',
		prop: 'MortgageAdviserName',
		name: 'Mortgage Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('MortgageAdviserName'),
		fieldId: datatableUtil.formatFieldId('MortgageAdviserName')
	},
	{
		metakey: 'FG Adviser',
		prop: 'FGAdviserName',
		name: 'F&G Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('FGAdviserName'),
		fieldId: datatableUtil.formatFieldId('FGAdviserName')
	},
	{
		metakey: 'KS Adviser',
		prop: 'KSAdviserName',
		name: 'KiwiSaver Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('KSAdviserName'),
		fieldId: datatableUtil.formatFieldId('KSAdviserName')
	},
	{
		metakey: 'Investment Adviser',
		prop: 'InvestmentAdviserName',
		name: 'Investment Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('InvestmentAdviserName'),
		fieldId: datatableUtil.formatFieldId('InvestmentAdviserName')
	},
	];
/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 18,
};

export const templateRow: Row = {
	CustomerId: null,
	ActivityId: null,
	AdviserId: null,
	AssignedToName: null,
	IsAccessible: null,
	IsCompany: null,

	ClientName: {
		metakey: 'Client Name',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Client Name').prop,
		required: false,
		editable: false,
	},
	AssignedToId: {
		metakey: 'Assigned To',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Assigned To').prop,
		required: true,
		editable: true,
		restrict: ['FEA'],
	},
	AdviserName: {
		metakey: 'Adviser Name',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Adviser Name').prop,
		required: false,
		editable: false,
	},
	// future work
	LRAdviserName: {
		metakey: 'LR Adviser',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'LR Adviser')?.prop,
		required: true,
		editable: false,
		restrict: ['FEL'],
	},
	GIAdviserName: {
		metakey: 'GI Adviser',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'GI Adviser')?.prop,
		required: true,
		editable: false,
		restrict: ['FEL'],
	},
	MortgageAdviserName: {
		metakey: 'Mortgage Adviser',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Mortgage Adviser')?.prop,
		required: true,
		editable: false,
		restrict: ['FEL'],
	},
	FGAdviserName: {
		metakey: 'FG Adviser',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'FG Adviser')?.prop,
		required: true,
		editable: false,
		restrict: ['FEL'],
	},
	KSAdviserName: {
		metakey: 'KS Adviser',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'KS Adviser')?.prop,
		required: true,
		editable: false,
		restrict: ['FEL'],
	},
	InvestmentAdviserName: {
		metakey: 'Investment Adviser',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Investment Adviser')?.prop,
		required: true,
		editable: false,
		restrict: ['FEL'],
	},
	// END future work
	ActivityType: {
		metakey: 'Activity Type',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Activity Type').prop,
		required: true,
		editable: true,
		restrict: ['FEA'],
	},
	ActivityName: {
		metakey: 'Activity Name',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Activity Name').prop,
		required: true,
		editable: true,
		restrict: ['FEA'],
	},
	Details: {
		metakey: 'Details',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Activity Name').prop,
		required: true,
		editable: true,
		hasToolTip: true,
		restrict: ['FEA'],
	},
	Meeting: {
		metakey: 'Meeting',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Meeting').prop,
		required: false,
		editable: true,
		restrict: ['FEA'],
	},
	CreateDate: {
		metakey: 'Create Date',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Create Date').prop,
		required: false,
		editable: false,
	},
	DueDate: {
		metakey: 'Due Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Due Date').prop,
		required: false,
		editable: true,
		restrict: ['FEA'],
	},
	CompleteDate: {
		metakey: 'Complete Date',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Complete Date').prop,
		required: false,
		editable: true,
		restrict: ['FEA'],
	},
	CancelledDate: {
		metakey: 'Cancelled Date',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Cancelled Date').prop,
		required: false,
		editable: false,
	},
	ActivityStatus: {
		metakey: 'Activity Status',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Activity Status').prop,
		required: false,
		editable: true,
		restrict: ['FEA'],
	},
	link: null,
};

export const createRowFromPrototype = R.curry(
	(template: Row, row: ActivitySearchModel) =>
		produce<Row>(template, (draft) => {
			draft.CustomerId = row.CustomerId;
			draft.ActivityId = row.ActivityId;
			draft.AdviserId = row.AdviserId;
			draft.AssignedToName = row.AssignedToName;
			draft.IsAccessible = row.IsAccessible;
			draft.IsCompany = row.IsCompany;

			draft.ClientName.metakey = 'Client Name';
			draft.ClientName.value = row.ClientName;

			draft.AssignedToId.metakey = 'Assigned To';
			draft.AssignedToId.value = row.AssignedToId
				? row.AssignedToId?.toString()
				: null;
			draft.AssignedToId.editable = row.ActivityStatus === 'Pending';

			draft.AdviserName.metakey = 'Adviser Name';
			draft.AdviserName.value = row.AdviserName;
      
			// FUTUREWORK
			draft.LRAdviserName.metakey = 'LR Adviser';
			draft.LRAdviserName.value = row.LRAdviserName;

			draft.GIAdviserName.metakey = 'GI Adviser';
			draft.GIAdviserName.value = row.GIAdviserName;

			draft.MortgageAdviserName.metakey = 'Mortgage Adviser';
			draft.MortgageAdviserName.value = row.MortgageAdviserName;

			draft.FGAdviserName.metakey = 'FG Adviser';
			draft.FGAdviserName.value = row.FGAdviserName;

			draft.KSAdviserName.metakey = 'KS Adviser';
			draft.KSAdviserName.value = row.KSAdviserName;

			draft.InvestmentAdviserName.metakey = 'Investment Adviser';
			draft.InvestmentAdviserName.value = row.InvestmentAdviserName

			// FUTUREWORK END

			draft.ActivityType.metakey = 'Activity Type';
			draft.ActivityType.value = row.ActivityType;

			draft.ActivityName.metakey = 'Activity Name';
			draft.ActivityName.value = row.ActivityName;

			draft.Details.metakey = 'Details';
			draft.Details.value = row.Details;

			draft.CreateDate.metakey = `Create Date`;
			draft.CreateDate.value = !!row.CreateDate
				? moment(row.CreateDate).format('YYYY-MM-DD')
				: '';

			draft.DueDate.metakey = 'Due Date';
			draft.DueDate.value = !!row.DueDate
				? moment(row.DueDate).format('YYYY-MM-DD')
				: '';

			draft.CompleteDate.metakey = 'Complete Date';
			draft.CompleteDate.value = !!row.CompleteDate
				? moment(row.CompleteDate).format('YYYY-MM-DD')
				: '';

			draft.CancelledDate.metakey = 'Cancelled Date';
			draft.CancelledDate.value = !!row.CancelledDate
				? moment(row.CancelledDate).format('YYYY-MM-DD')
				: '';

			draft.ActivityStatus.metakey = 'Activity Status';
			draft.ActivityStatus.value = row.ActivityStatus;

			draft.link = {
				ActivityId: row.ActivityId,
			};

			draft.Meeting.metakey = 'Meeting';
			draft.Meeting.value = row.Meeting;
		})
);
