import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { subtract, complement, either, isNil, isEmpty } from 'ramda';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	skipWhile,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { computeTotalExpense } from '../../../../../modules/crm/client-review-template/income-budget/calculations/monthly-expense';
import { FactFindComputationState } from '../../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import { MonthlyExpenseMapper } from '../../../../../shared/models/client-review-template/income-budget/monthly-expense.mapper';
import {
	ExpenseListObjState,
	MonthlyExpenseDetailsState,
} from '../../../../../shared/models/client-review-template/income-budget/monthly-expense.model';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { numUtil } from '../../../../../util/util';
import { IncomeService } from '../../states/income-budget/income.service';

@Component({
	selector: 'app-full-budget-form',
	templateUrl: './full-budget-form.component.html',
	styleUrls: ['./full-budget-form.component.scss'],
})
export class FullBudgetFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() isAdviceProcessEnded: boolean;

	@Input() monthlyExpense$: Observable<MonthlyExpenseDetailsState>;
	@Input() updateFn$: (
		monthlyExpense: MonthlyExpenseDetailsState
	) => Observable<MonthlyExpenseDetailsState>;
	@Input() addNewFn$: (
		monthlyExpense: MonthlyExpenseDetailsState
	) => Observable<MonthlyExpenseDetailsState>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() monthlyAfterTaxIncome: number;
	@Input() mortgagePayments: number;
	@Input() pHCValue: number;
	@Input() factFindComputation$: Observable<FactFindComputationState>;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	cRTId: number;
	adviceProcessId: number;
	isNew = false;
	idExpense = 0;
	totalExpenses = 0;
	showTrueMonthly = false;
	showfalseMonthly = false;
	submitted = false;
	isListLoading = true;
	defaultFrequencyValue = 'Monthly';
	otherExpenseList = new BehaviorSubject<ExpenseListObjState[]>([]);
	factFind: FactFindComputationState = {};

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private incomeService: IncomeService
	) {
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			completed: ['Yes', Validators.required],
			mortgagePayments: [null],
			rentValue: [null],
			rentFrequency: [this.defaultFrequencyValue],
			pHCValue: [null],
			pGWValue: [null],
			pGWValueFrequency: [this.defaultFrequencyValue],
			pIPValue: [null],
			pIPValueFrequency: [this.defaultFrequencyValue],
			hCCValue: [null],
			hCCValueFrequency: [this.defaultFrequencyValue],
			lRMValue: [null],
			lRMValueFrequency: [this.defaultFrequencyValue],
			carExpenses: [null],
			carExpensesFrequency: [this.defaultFrequencyValue],
			homeRates: [null],
			homeRatesFrequency: [this.defaultFrequencyValue],
			pets: [null],
			petsFrequency: [this.defaultFrequencyValue],
			schoolFees: [null],
			schoolFeesFrequency: [this.defaultFrequencyValue],
			childCare: [null],
			childCareFrequency: [this.defaultFrequencyValue],
			foodClothes: [null],
			foodClothesFrequency: [this.defaultFrequencyValue],
			expense: [null],
			discretionarySpendingValue: [null],
			discretionarySpendingValueFrequency: [this.defaultFrequencyValue],
			expenseList: this.fb.array([]),
			unallocatedMonthlyIncome: [null],
			totalExpenses: ['0'],
		});
	}

	get expenseList() {
		return this.form.get('expenseList') as UntypedFormArray;
	}
	get TotalExpenses() {
		return this.form.get('totalExpenses');
	}
	get MortgagePayments() {
		return this.form.get('mortgagePayments');
	}
	get PHCValue() {
		return this.form.get('pHCValue');
	}
	get UnallocatedMonthlyIncome() {
		return this.form.get('unallocatedMonthlyIncome');
	}
	get Completed() {
		return this.form.get('completed');
	}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.Completed.disable();
		this.factFindComputation$
			.pipe(
				tap((data) => (this.factFind = data)),
				skipWhile(() => this.isListLoading),
				withLatestFrom(this.monthlyExpense$),
				tap(([_, monthly]) => (this.cRTId = monthly?.cRTId)),
				debounceTime(350),
				distinctUntilChanged(),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				if (this.Completed.value === 'Yes') {
					this.recompute();
				}
			});
		this.adviceProcessId = +this.route.snapshot.paramMap.get('adviceProcessId');
		this.monthlyExpense$
			.pipe(
				tap(() => (this.isListLoading = true)),
				filter((data) => !!data),
				tap((x) => {
					const data = MonthlyExpenseMapper.mapToView({
						...x,
						mortgagePayments: this.mortgagePayments,
						pHCValue: this.pHCValue,
					});
					this.cRTId = x?.cRTId;
					this.form.patchValue({
						...data,
						adviceProcessId: +this.adviceProcessId,
					});
				}),
				delay(500),
				tap(() => this.selectChange(true)),
				tap(() => this.Completed.enable()),
				tap(() => (this.isListLoading = false)),
				take(1)
			)
			.subscribe((x) => {
				if (x?.cRTId) {
					x?.expenseList?.map((expense) => this.addExpenseItem(expense));
				}
				if (this.Completed.value === 'Yes') {
					this.recompute(true);
				}

				if (this.isAdviceProcessEnded) {
					this.form.disable();
					this.Completed.disable();
					this.expenseList.disable();
					this.UnallocatedMonthlyIncome.disable();
				}
			});
	}

	addExpenseItem(data?: ExpenseListObjState) {
		this.idExpense++;
		this.expenseList?.push(
			this.fb.group({
				expenseField: [(data && data.expenseField) || ''],
				expenseValue: [(data && data.expenseValue) || ''],
				expenseFrequency: [
					data?.expenseFrequency ?? this.defaultFrequencyValue,
				],
			})
		);
		if (!data) {
			this.form.markAllAsTouched();
			this.recompute();
		}
	}

	deleteExpenseItem(index: number) {
		this.expenseList.removeAt(index);
		this.recompute();
	}

	selectChange(isInit?: boolean) {
		const value = this.Completed.value;
		if (value === 'Yes') {
			this.showTrueMonthly = true;
			this.showfalseMonthly = false;
			if (!this.cRTId) {
				if (this.expenseList.length === 0) {
					this.addExpenseItem();
				}
				this.UnallocatedMonthlyIncome.disable();
			}
			this.recompute(isInit);
		} else if (value === 'No') {
			this.showfalseMonthly = true;
			this.showTrueMonthly = false;
			this.UnallocatedMonthlyIncome.enable();
			if (!isInit) {
				this.UnallocatedMonthlyIncome.setValue('0');
			}
		} else if (value === '') {
			this.showfalseMonthly = false;
			this.showTrueMonthly = false;
			this.UnallocatedMonthlyIncome.enable();
			this.UnallocatedMonthlyIncome.setValue('0');
		}
		this.submitted = true;
		if (!isInit) {
			this.onChange();
		}
	}

	recompute(isInit: boolean = false) {
		const val = +computeTotalExpense(
			this.form.getRawValue(),
			this.form.value.expenseList
		);
		this.TotalExpenses.setValue(val);

		const unallocatedMonthly = +numUtil.formatToNumCurrency(
			subtract(+this.factFind?.monthlyAfterTaxIncome, +val)
		);
		this.UnallocatedMonthlyIncome.setValue(unallocatedMonthly);
		if (!isInit) {
			this.onChange();
		}
	}

	onChange() {
		this.submitted = true;
		const formValue = this.form.getRawValue();
		formValue.expenseList = formValue.expenseList?.map((x) => ({
			...x,
			expenseValue: complement(either(isNil, isEmpty))(x?.expenseValue)
				? x?.expenseValue
				: 0,
		}));
		const data = MonthlyExpenseMapper.mapToUpsert(
			formValue,
			+this.adviceProcessId,
			+this.cRTId
		);
		data.isInvalid = !this.form.valid;
		this.incomeService.updateMonthlyExpenseState({
			...data,
			adviceProcessId: this.adviceProcessId,
		});
	}

	onChangeCompute() {
		this.submitted = true;
		this.recompute();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	getComputedValue(): any {
		return this.form.getRawValue();
	}
}
