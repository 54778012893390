<ng-container *ngIf="designV2; else designV1">
	<div class="tap-modal-header modal-header">
		<button class="tap-btn tap-modal-close" (click)="detachCloseIcon ? close() : decline()">
			<span class="material-icons"> close </span>
		</button>
		<h3 class="modal-title">
			{{ header }}
		</h3>
	</div>
	<div class="tap-modal-body modal-body">
		<p>{{ message }}</p>
		<p *ngIf="subMessage && subMessage !== ''" class="pb-3">{{ subMessage }}</p>
		<p class="font-weight-bold text-break">{{ secondaryMessage }}</p>
	</div>
	<ng-template [ngTemplateOutlet]="modalFooter"></ng-template>
</ng-container>

<ng-template #designV1>
	<button id="detachCloseIconButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="detachCloseIcon ? close() : decline()">
		<span class="material-icons"> close </span>
	</button>
	<div class="modal-header">
		<h1 class="modal-title">
			{{ header }}
		</h1>
	
	</div>
	<div class="modal-body text-center mb-4">
		<p>{{ message }}</p>
		<p *ngIf="subMessage && subMessage !== ''" class="pb-3">{{ subMessage }}</p>
		<p class="font-weight-bold text-break">{{ secondaryMessage }}</p>
	</div>
	<ng-template [ngTemplateOutlet]="modalFooter"></ng-template>
</ng-template>

<ng-template #modalFooter>
	<ng-container *ngIf="customButtons?.length">
		<div class="modal-footer" [ngClass]="designV2 ? 'tap-modal-footer' : ''">
				<button
						*ngFor="let button of customButtons"
						type="button"
						class="tap-btn tap-btn--default confirm-modal-custom-btn"
						[ngClass]="!designV2 ? 'tap-text-h4' : ''"
						[disabled]="customBtnIsExecuting"
						(click)="customBtnClick(button)"
						>
						{{ button.text }}
				</button>
		</div>
	</ng-container>
	
	<ng-container *ngIf="!customButtons?.length">
		<ng-container *ngIf="isOkBtn && !isAcceptBtn">
			<div class="modal-footer justify-content-center" [ngClass]="designV2 ? 'tap-modal-footer' : ''">
				<button
						id="confirmBtn"
						type="button"
						class="tap-btn tap-btn--default mx-2"
						[ngClass]="!designV2 ? 'tap-text-h4' : ''"
						(click)="continue()"
						>
						{{designV2 ? 'Okay': 'OK'}}
				</button>
			</div>
		</ng-container>
	
		<ng-container *ngIf="!isOkBtn && !isAcceptBtn">
			<div class="modal-footer" [ngClass]="designV2 ? 'tap-modal-footer' : ''">
				<button
						id="confirmBtn"
						type="button"
						class="tap-btn tap-btn--default mx-2"
						[ngClass]="!designV2 ? 'tap-text-h4' : ''"
						(click)="continue()"
						>
						{{ confirmTxt }}
				</button>
					<button
							id="cancelBtn"
							type="button"
							class="tap-btn tap-btn--default"
							[ngClass]="!designV2 ? 'tap-text-h4' : ''"
							(click)="decline()"
							>
							{{ cancelTxt }}
					</button>
			</div>
		</ng-container>
	
		<ng-container *ngIf="!isOkBtn && isAcceptBtn">
			<div class="modal-footer" [ngClass]="designV2 ? 'tap-modal-footer' : ''">
				<button
						id="replaceBtn"
						type="button"
						class="tap-btn tap-text-h4 tap-btn--default mx-2"
						(click)="continue()"
						>
						{{ confirmBtnText }}
				</button>
					<button
							id="cancelBtn"
							type="button"
							class="tap-btn tap-text-h4 tap-btn--default"
							(click)="decline()"
							>
							{{ declineBtnText }}
					</button>
			</div>
		</ng-container>
	</ng-container>
</ng-template>