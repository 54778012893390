<app-manual-loader
	*ngIf="isLoading$ | async as isLoading"
	[isLoading]="isLoading"
></app-manual-loader>

<form [formGroup]="form" class="form" novalidate [class.submitted]="submitted">
	<div class="row align-items-center mb-3">
		<div class="col-lg-6">
			<div class="row align-items-center">
				<div class="col-lg-12">
					<div class="form-row align-items-center">
						<div class="col-lg-4 col-4 font-weight-bold">
							Approved Applications
						</div>
						<div class="col-lg-8 col-8">
							<span class="w-100 crt-custom-dropdown">
								<select
									formControlName="approvedApplication"
									id="approvedApplication"
									class="form-control crt-form-control theme-crm-field-disabled"
									(change)="save(false, false)"
									[class.disabled]="approvedApplicationIsLoading"
								>
									<option value="0"></option>
									<option
										*ngFor="let d of applications$ | async"
										[value]="d.value"
									>
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
            <div class="loader-container" *ngIf="!(isLoading$ | async)">
              <app-manual-loader
                *ngIf="approvedApplicationIsLoading"
                [isLoading]="approvedApplicationIsLoading"
                >
              </app-manual-loader>
            </div><!-- .loader-container -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row align-items-center mb-3">
		<div class="col-lg-12">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="form-row align-items-center">
						<div class="col-lg-4 col-4 font-weight-bold">
              Total Approved Lending
						</div>
						<div class="col-lg-4 col-4">
							<div class="dollar-icon">
								<input
									formControlName="approvedMortgageAmount"
									type="text"
									class="form-control crt-form-control font-weight-bold"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-row align-items-center">
						<div class="col-lg-4 col-4 font-weight-bold">
							Cash Incentive Offered
						</div>
						<div class="col-lg-4 col-4">
							<div class="dollar-icon">
								<input
									formControlName="cashIncentiveOffered"
									type="text"
									class="form-control crt-form-control font-weight-bold"
									currencyMask
									appCurrencyMaskExt
								/>
								<i>$</i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</form>

<table class="table table-hover">
	<thead>
		<tr>
			<th scope="col">Loan Amount</th>
			<th scope="col">Structure</th>
			<th scope="col">Fixed Term</th>
			<th scope="col">Interest Rate</th>
			<th scope="col">Repayment</th>
			<th scope="col">Borrowers</th>
			<th scope="col"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let l of loanSplits$ | async; let i = index">
			<td>$ {{ l?.loanAmount | currency: "USD":"":"1.2-2" }}</td>
			<td>{{ l?.loanType }}</td>
			<td>{{ l?.fixedPeriod }}</td>
			<td>{{ l?.interestRate | number: "1.2" }} %</td>
			<td>
				$ {{ l?.repaymentAmount }} {{ perFrequncy(l?.repaymentFrequency) }}
			</td>
			<td>{{ checkPeopleList(l?.borrowers) }}</td>
			<td>
				<ng-container *ngIf="l.cRTId && !(isLoading$ | async)">
					<button
						id="loanEditPersonButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="editLoanSplit(l)"
						[disabled]="!!(isLoading$ | async)"
					>
						<i class="material-icons md-16"> edit </i>
					</button>
					<button
						id="loanDeletePersonButton_{{ i }}"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						(click)="confirmDelete(l)"
						[disabled]="!!(isLoading$ | async)"
					>
						<i class="material-icons md-16">delete</i>
					</button>
				</ng-container>

				<ng-container *ngIf="l.cRTId && (isLoading$ | async)">
					<app-manual-loader-mini
						[isLoading]="isLoading$ | async"
					></app-manual-loader-mini>
				</ng-container>
			</td>
		</tr>
		<tr>
			<th>{{ remainingToAllocate$ | async }}</th>
			<th colspan="6">remaining to allocate</th>
		</tr>
	</tbody>
</table>

<button
	(click)="addLoanSplit()"
	class="tap-btn tap-btn--primary mr-auto text-white"
>
	Add Loan Split +
</button>
