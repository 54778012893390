import { Moment } from 'moment';

export interface SignatureModel {
	name?: string;
	date?: string | Moment;
	image?: string;
	signature?: string;
	fullName?: string;
}

export const signatureTemplate = (s: SignatureModel[]) => {
	const result = [];

	for (let i = 0; i < s?.length; i++) {
		const img = s[i]?.image ? `<img src="${s[i]?.image}" width="155px" height="auto"/>` : '';

		const signature = `<div class="signature-wrapper"><p class="signature-container"><span>${s[i]?.name}</span><span>${s[i]?.date}</span><span>${img}</span></p><p class="signature-container"><strong>Full Name</strong><strong>Date</strong><strong>Signature</strong></p></div>`;
		result.push(signature);
	}

	const signatures = result.join('');

	const newHtml = document.createRange().createContextualFragment(`<div id="signatures">${signatures}</div>`);

	return newHtml.querySelector('#signatures').innerHTML;
};
