import { Directive, ElementRef, HostListener } from '@angular/core';
import { numUtil } from 'src/app/util/util';

@Directive({
	selector: '[appFeetInchesNumber]',
})
export class FeetInchesNumberDirective {
	private regex: RegExp = new RegExp(
		`^[\\d]*(?:\\.[\\d]+|)(?:\\s\\d+\\/\\d+|)(?:\\s|\\'|\\\"|)[\\d]+(?:\\.[\\d]+|)(?:\\s\\d+\\/\d+|)(?:\\'|\\\"|)$`,
		'g'
	);
	private specialKeys: Array<string> = [
		'Backspace',
		'Tab',
		'End',
		'Home',
		'ArrowLeft',
		'ArrowRight',
		'Delete',
		'Control',
	];

	constructor(private el: ElementRef) {}

	@HostListener('keydown', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		const ckey = (event.ctrlKey || event.metaKey) && event.key === 'c';
		const vkey = (event.ctrlKey || event.metaKey) && event.key === 'v';

		// Allow Backspace, tab, end, delete and home keys
		if (this.specialKeys?.indexOf(event.key) !== -1) {
			return;
		}

		const current: string = this.el.nativeElement.value;
		const position = this.el.nativeElement.selectionStart;
		const next: string = [
			current?.slice(0, position),
			event.key,
			current?.slice(position),
		]?.join('');

		// Checks if user hits copy/paste
		if (
			(ckey || vkey) &&
			next?.match(/[A-Za-z]/g).length === 1 &&
			(event.key === 'v' || event.key === 'c')
		) {
			return;
		}
		const checkNext = next?.toString()?.replace('\'', '')?.replace('\"', '');
		if (current?.length <= 1 && !numUtil.isValid(+checkNext)) {
			// If first typed character is not a number, prevent from proceeding
			event.preventDefault();
		}
		if (checkNext === '') {
			// If user typed quotes only
			event.preventDefault();
		}
		if (current && next && !String(next)?.match(this.regex)) {
			// validate feet & inches formatting
			event.preventDefault();
		}
	}

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const prevValue = +this.el.nativeElement.value?.match(/\./g)?.length;
		const currValue = +pastedText?.match(/\./g)?.length;

		if (
			prevValue + currValue > 1 ||
			(pastedText?.match(/[A-Za-z]/g) &&
				pastedText?.match(/[A-Za-z]/g).length > 1 &&
				pastedText !== 'v')
		) {
			event.preventDefault();
		}
	}
}
