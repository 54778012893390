<div class="p-4 main-income-budgets">
	<form [formGroup]="form" class="form">
		<div class="form-row">
			<div class="column col-lg-12">

				<!-- Income Source -->
				<app-income-source
					[APCRTF$]="APCRTF$"
					[APCRTKC$]="APCRTKC$"
					[APCRTTC$]="APCRTTC$"
					[APCRTI$]="APCRTI$"
					[APCRTIST$]="APCRTIST$"
					[APCRTIT$]="APCRTIT$"
					[APCRTTM$]="APCRTTM$"
					[APCRTYNNA$]="APCRTYNNA$"
					[PCE$]="PCE$"
					[incomeSource$]="incomeSource$"
					[primaryClient$]="primaryClient$"
					[secondaryClients$]="secondaryClients$"
					[people$]="people$"
					[deleteFn$]="deleteEmployment$"
					[factFindComputation$]="factFindComputation$"
					[peopleFromCRMAndCRTExceptChild$]="peopleFromCRMAndCRTExceptChild$"
					[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
				></app-income-source>

				<!-- Net Rental Income -->
				<app-net-rental-income
					[netRentalIncome$]="netRentalIncome$"
					[properties$]="properties$"
					[addNewFn$]="addNewRental$"
					[updateFn$]="updateRental$"
					[deleteFn$]="deleteRental$"
					[factFindComputation$]="factFindComputation$"
					[incomeSource$]="incomeSource$"
					[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
				></app-net-rental-income>

				<!-- Other Income -->
				<app-other-income
					[otherIncome$]="otherIncome$"
					[addNewFn$]="addNewOther$"
					[updateFn$]="updateOther$"
					[deleteFn$]="deleteOther$"
					[factFindComputation$]="factFindComputation$"
					[incomeSource$]="incomeSource$"
					[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
				></app-other-income>

				<div class="form-row mb-2 mt-3">
					<div class="col-9 col-lg-9 text-right pt-1">
						<label class="font-weight-bold tap-text-primary">
							Annual Taxable Joint Income
						</label>
					</div>
					<div class="col-3 col-lg-3 pt-1">
						<label>
							{{ annualTaxableJointIncome | currency }}
						</label>
					</div>
				</div>
				<div class="form-row mb-2">
					<div class="col-9 col-lg-9 text-right pt-1">
						<label class="font-weight-bold tap-text-primary">
							Monthly Taxable Joint Income
						</label>
					</div>
					<div class="col-3 col-lg-3 pt-1">
						<label>
							{{ monthlyTaxableJointIncome | currency }}
						</label>
					</div>
				</div>
				<div class="form-row mb-3">
					<div class="col-9 col-lg-9 text-right pt-1">
						<label class="font-weight-bold tap-text-primary">
							Monthly After Tax Income
						</label>
					</div>
					<div class="col-3 col-lg-3 pt-1">
						<label>
							{{ monthlyAfterTaxIncome | currency }}
						</label>
					</div>
				</div>

				<hr />

				<!-- Full budget form -->
				<app-full-budget-form
					#fullBudget
					[monthlyExpense$]="monthlyExpense$"
					[addNewFn$]="addNewMonthlyExpense$"
					[updateFn$]="updateMonthlyExpense$"
					[APCRTF$]="APCRTF$"
					[APCRTYN$]="APCRTYN$"
					[mortgagePayments]="mortgagePayments$ | async"
					[pHCValue]="pHCValue$ | async"
					[factFindComputation$]="factFindComputation$"
					[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
				></app-full-budget-form>
			</div>
		</div>
	</form>
</div>
