import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DocumentUploadState } from '@shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { BehaviorSubject, combineLatest, Observable, of, Subject, iif } from 'rxjs';
import { map, takeUntil, take, tap, mergeMap, withLatestFrom, concatMap, finalize } from 'rxjs/operators';
import { DisclosureDocumentState } from '../../../../../shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { MergeTagState } from '../../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { DisclosureSettingsService } from '../../../service/disclosure-settings.service';
import { MergeTagsService } from '../../../service/merge-tags.service';
import { BlStaffViewmodel } from '../../../viewmodels/bl-staff.viewmodel';
import * as R from 'ramda';
import { convertUtil, objectUtil } from '@util/util';
import { UploadModalComponent } from '@shared/modal/upload-modal/upload-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DisclosureDocumentMapper } from '@shared/models/client-review-template/disclosure-document/disclosure-document.mapper';

@Component({
  selector: 'app-disclosure-settings',
  templateUrl: './disclosure-settings.component.html',
})
export class DisclosureSettingsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  @Input() data: BlStaffViewmodel;

  staffId = 0;
  disclosureData$ = new BehaviorSubject<DisclosureDocumentState>(null);
  settingsId$ = new BehaviorSubject<number>(null);
  mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
  disclosureSettings$ = this.dService.disclosureSettings$;

  form: UntypedFormGroup;
  documentID: number;
  document: DocumentUploadState;
  tempDoc: DocumentUploadState;
  isLoading = false;
  tempDocBase64: string;

  templateId: number;
  settings: any;

  get DisclosureTypeFT() {
    return this.form.get('disclosureTypeFT');
  }
  get DisclosureTypeUD() {
    return this.form.get('disclosureTypeUD');
  }
  get DisclosureType() {
    return this.form.get('disclosureType');
  }
  get Disclosure() {
    return this.form.get('disclosure');
  }
  get DocumentID() {
    return this.form.get('documentID');
  }

  constructor(
    private dService: DisclosureSettingsService,
    private mtService: MergeTagsService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.staffId = this.data?.StaffID;
    this.prepData();
  }

  buildForm() {
    this.form = this.fb.group({
      disclosureTypeFT: [false],
      disclosureTypeUD: [false],
      disclosureType: [null],
      type: ['DD'],
      disclosure: [''],
      documentID: [''],
    });
  }

  prepData() {
    combineLatest([
      this.dService.disclosureSettings$
    ]).pipe(
        tap(([dd]) => {
          this.settings = dd;

          if (dd?.uploadTemplate) {
            this.DocumentID.setValue(dd.uploadTemplate);
            this.documentID = +dd.uploadTemplate;
  
            this.getDisclosureFile(dd.uploadTemplate).subscribe();
          }

          if (dd?.templateType === 'UD') {
            this.DisclosureType.setValue('UD');
            this.DisclosureTypeUD.setValue(true);
          }

          if (dd?.templateType === 'FT') {
            this.DisclosureType.setValue('FT');
            this.DisclosureTypeFT.setValue(true);
          }

          this.templateId = +dd?.template || 0;
          this.settingsId$.next(dd?.settingsId)
        }),
        // map(([dd, mt]) => MergeTagsMapper.mapMergeTags(mt)),
        // tap((mt) => this.mergeTags$.next(mt)),
        takeUntil(this.onDestroy$)
        // take(1)
      )
      .subscribe();
  }

  toggleDisclosureTypeUD() {
    this.DisclosureType.setValue('UD');
    this.DisclosureTypeUD.setValue(false);
  }

  toggleDisclosureTypeTemplate() {
    this.DisclosureType.setValue('FT');
    this.DisclosureTypeFT.setValue(false);
  }

  openUploadModal() {
    const upload = (req) =>
      new Observable((obs) => {
        obs.next();
        obs.complete();
      }).pipe(
        map(() => req),
        tap((x) => this.addTempDocument(x)),
        mergeMap((x) => convertUtil.convertToBase64(x[0])),
        tap((x) => {
          this.tempDocBase64 = x;
        })
      );

    const initialState = {
      customUpload: upload,
      isSingleUpload: true,
      isFileList: true,
      headerTitle: 'Upload Intro Document',
      additionalInfo: 'Upload PDF',
      restrict: '.pdf'
    };

    this.modalService.show(UploadModalComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState,
      ignoreBackdropClick: true,
    });
  }

  addTempDocument(data) {
    if (R.complement(R.either(R.isNil, R.isEmpty))(data)) {
      this.tempDoc = data[0];
    }
  }

  submitForm() {
    // this.isLoading = true;
    const formValues = DisclosureDocumentMapper.mapToUpsert(this.form.getRawValue());

    of(formValues)
      .pipe(
        tap(() => (this.isLoading = true)),
        concatMap((x) =>
          iif(
            () =>
              x?.disclosureType === 'UD' &&
              (!formValues.documentID || !R.isNil(this.tempDocBase64)),
            this.uploadDocument(formValues),
            of(x).pipe(
              tap(() => this.clearTempDoc()),
              take(1)
            )
          )
        ),
        withLatestFrom(this.settingsId$, this.dService.disclosureSettings$),
        concatMap(([data, settingsId, settings]) => {
					const formValue = DisclosureDocumentMapper.mapTemplateTypeToUpsert({
						...settings,
						referenceId: +this.staffId,
						uploadTemplate: data?.documentID,
						templateType: data?.disclosureType
					});

					return iif(
						() => R.isNil(settingsId),
						this.addTemplateType(formValue),
						this.updateTemplateType(settingsId, formValue)
					)
        }),
        concatMap((x) =>
          iif(
            () => formValues?.disclosureType === 'UD',
            this.reloadDocument(),
            of(x)
          )
        ),
        finalize(() => {
          this.clearTempDoc();
          this.isLoading = false;
        }),
        take(1)
      )
      .subscribe();
  }

  clearTempDoc() {
		this.tempDoc = null;
		this.tempDocBase64 = null;
	}

  uploadDocument = (formValues) => {
		return of(this.documentID).pipe(
			withLatestFrom([this.tempDoc], [this.tempDocBase64]),
			map(([id, doc, base64]) => {
        return DisclosureDocumentMapper.mapCRTDocumentUploadFile(doc, this.staffId, base64)
      }),
			concatMap((data) => this.dService.newFileUploadDD(data)),
			tap((res) => {
        this.documentID = +res;
      }),
			tap((res) => this.DocumentID.setValue(+res)),
			mergeMap((res) => of({ ...formValues, documentID: +res })),
			take(1)
		);
	}

  addTemplateType = (data: DisclosureDocumentState) => {
		return this.dService.addDisclosureDocument(+this.staffId, data);
  }

  updateTemplateType = (settingsId: number, data: DisclosureDocumentState) => {
    return this.dService.updateDisclosureDocument(+this.staffId, {
      ...data,
      settingsId
    });
  }

  reloadDocument = () => {
    return of(this.documentID).pipe(
      concatMap((x) => this.getDisclosureFile(+x)),
      take(1)
    );
  }

  getDisclosureFile = (documentID) => {
    return of(documentID).pipe(
      mergeMap((x) => this.dService.getDisclosureDocumentFile(x)),
      map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
      tap((data) => {
        this.document = data;
      }),
      take(1)
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
