import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	OnDestroy,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, map, withLatestFrom } from 'rxjs/operators';
import { PQuery } from '../state/p.query';
import { LrPipeline } from '../state/p.model';
import { PService } from '../state/p.service';
import { DashboardQuery } from '../../../state/dashboard.query';
import { RouteService } from '../../../../../../core/config/route.service';
import {
	BodyRow,
	HeaderRow,
} from '../../../shared/simple-table/simple-table.model';
import { numUtil } from '../../../../../../util/util';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';

/**
 * LR pipeline table. Uses simple table.
 */
@Component({
	selector: 'app-lrp',
	templateUrl: './lrp.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LrpComponent implements OnInit, OnDestroy {
	/**
	 * key pair value where the key is keys in lr pipeline response
	 * and value is the real status value.
	 *
	 * The real status value is used for values to search when user
	 * is routed to pipeline search due to clicking a status.
	 */
	// private readonly LrPropertyRealStatus = (() => {
	//   const statuses = {
	//     Total: '',
	//     Leads: 'Lead',
	//     FirstMeeting: 'First Meeting',
	//     Analysis: 'Analysis',
	//     PrepareReport: 'Prepare Report',
	//     ReportPresented: 'Report Presented',
	//     Submitted: 'Submitted',
	//     Underwriting: 'Underwriting',
	//     UnderwritingInfoRequired: 'Underwriting Info Required',
	//     NotesTests: 'Notes/Tests Required',
	//     OfferOfTerms: 'Offer of terms',
	//     PolicyAccepted: 'Policy Accepted',
	//     InforcePending: 'Inforce Pending',
	//     IncompleteApp: 'Incomplete App'
	//   };
	//   statuses.Total = Object.keys(statuses)
	//     .filter(x => x !== 'Total')
	//     .map(x => statuses[x])
	//     .join(',');
	//   return statuses;
	// })();

	/**
	 * Display name for Statuses.
	 * keys are the keys in lr pipeline response.
	 */
	// private readonly LrPropertyName = {
	//   Leads: 'Leads',
	//   FirstMeeting: 'First Meeting',
	//   Analysis: 'Analysis',
	//   PrepareReport: 'Prepare Report',
	//   ReportPresented: 'Report Presented',
	//   Submitted: 'Submitted',
	//   Underwriting: 'Underwriting',
	//   UnderwritingInfoRequired: 'Underwriting Info Required',
	//   NotesTests: 'Notes/Tests Required',
	//   OfferOfTerms: 'Offer Of Terms',
	//   PolicyAccepted: 'Policy Accepted',
	//   InforcePending: 'Inforce Pending',
	//   IncompleteApp: 'Incomplete App',
	//   Total: 'Total',
	// };
	/**
	 * destroy event observable. Is watched to know when to
	 * unsubscribe subscribers. emits on ngOnDestroy.
	 */
	private onDestroy$ = new Subject<void>();

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.lrIsLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.lrError$;
	/**
	 * LR pipeline data
	 */
	data$: Observable<LrPipeline> = this.query.lrData$;

	isServiceLoading$: Observable<boolean> = this.query.isServiceLoading$;

	/**
	 * transform lr data to simple-table object.
	 *
	 * For status label, show text from LrPropertyName
	 * For route get from routeService with status from LrPropertyRealStatus
	 */
	viewData$ = this.data$.pipe(
		withLatestFrom(
			this.dashboardQuery.hasSelectedAllAdvisers$,
			this.dashboardQuery.allAdvisersId$
		),
		map(([data, hasSelectedAllAdvisers, allAdviserIds]): BodyRow[] => {
			if (data === null) {
				return [];
			} else {
				const selectedAdvisers = this.dashboardQuery.getValue();
				const totalStatus = Object.keys(data)
					?.filter((key) => key !== 'Total')
					?.map((key) => data[key].Status);

				return Object.keys(data)?.map<BodyRow>((key) => {
					return [
						{
							text: data[key].Status,
							route: this.routeService.lrPipelineWithParams({
								advisers: !hasSelectedAllAdvisers
									? selectedAdvisers.adviserFilter?.join(',')
									: allAdviserIds?.join(','),
								statuses:
									data[key].Status !== 'Total'
										? data[key].Status
										: totalStatus?.join(','),
							}),
						},
						{
							text: numUtil.formatToCurrency(data[key].Premium),
							class: 'col-4',
						},
						{ text: numUtil.formatToCount(data[key].Count), class: 'col-2' },
					];
				});
			}
		})
	);

	/** header for simple-table header object */
	header: HeaderRow = [
		{ text: 'Status' },
		{ text: 'API', class: 'col-4' },
		{ text: 'Count', class: 'col-2' },
	];

	constructor(
		private routeService: RouteService,
		private dashboardQuery: DashboardQuery,
		private query: PQuery,
		private service: PService,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	/** trigger LR pipeline data fetch when adviser changes */
	ngOnInit() {
		this.dashboardQuery.adviserFilter$
			.pipe(
				filter((x) => x.length > 0),
				withLatestFrom(
					this.isServiceLoading$
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([advisers, isServiceLoading]) => {
				if (!isServiceLoading) {
					this.service.GetAllPipelines(advisers);
				}
			});
	}

	/**
	 * Gets onDestroy$ to emit and unsubscribe.
	 */
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
