import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-archive-business-modal',
  templateUrl: './archive-business-modal.component.html'
})
export class ArchiveBusinessModalComponent implements ArchiveModel {
  business: any;
  businessId: number;
  companyCode: string;
  status: number;

  header: string;
  message: string;
  isArchive = false;
  archivefn: (business: any, businessId: number, companyCode: string, status: number) => Observable<any>;

  constructor(public bsModalRef: BsModalRef) {
    this.setupProps();
    this.setupSubs();
  }
  setupProps() {
    this.header = 'Archive';
    this.message = 'Archive?';
  }
  setupSubs() { }

  confirm() {
    this.archivefn(this.business, this.businessId, this.companyCode, this.status).subscribe();
    this.decline();
  }
  decline() {
    this.bsModalRef.hide();
  }
}
export interface ArchiveModel {
  header: string;
  message: string;
  isArchive: boolean;

  business: any;
  businessId: number;
  companyCode: string;
  status: number;
}
