import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-crt-banner-simple',
	templateUrl: './crt-banner-simple.component.html',
	styleUrls: ['./crt-banner-simple.component.scss'],
})
export class CrtBannerSimpleComponent {
	@Input() headerText: string;
	@Input() subHeaderText: string;
	@Input() logoUrl: string;
	@Input() height: string;

	constructor() {}
}
