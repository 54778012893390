import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { objectUtil } from '../../../../util/util';
import { KiwiSaverSettingsStore } from '../../state/kiwisaver-settings.store';
import { KiwiSaverSettingsQuery } from '../../state/kiwisaver-settings.query';
import { LsSoaSubSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

const LsSoaCodes: string[] = [...LsSoaSubSectionCodes].map((code: string) =>
	code.toLowerCase()
);
@Injectable()
export class KiwiMergeTagsCrtSettingsService {
	mergeTags$ = this.query.mergeTags$;

	constructor(
		private api: ApiService,
		private store: KiwiSaverSettingsStore,
		private query: KiwiSaverSettingsQuery
	) {}

	getMergeTags(
		order: number,
		tableCode: string,
		referenceId: number,
		dataType?: string
	) {
		let endpoint = `crt/merge-tag/${tableCode}/reference/${referenceId}`;
		if (dataType) {
			endpoint = `${endpoint}/${dataType}`;
		}
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags || [], ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getMtFactFind(order: number, dataType?: string) {
		const referenceId = 0;
		const endpoint = `crt/merge-tag/fact-find/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags || [], ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getSosMt() {
		const body = {
			ReferenceId: 0,
			DataType: ['sos'],
			Table: ['scope-of-service'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getLsSoaMt() {
		const body = {
			ReferenceId: 0,
			DataType: LsSoaCodes,
			Table: ['structure-statement-of-advice'],
		};
		return this.api.post3<MergeTagState[]>('crt/merge-tag', body).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			tap((x) =>
				applyTransaction(() => {
					this.updateMtState(x);
				})
			),
			catchError(() => of([]))
		);
	}

	getMtSettings(order: number, dataType?: string) {
		const referenceId = 0;
		const endpoint = `crt/merge-tag/settings/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags || [], ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getMtSettingDetails(order: number, dataType?: string) {
		const referenceId = 0;
		const endpoint = `crt/merge-tag/setting-details/reference/${referenceId}/${dataType}`;
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags || [], ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateMtState(data) {
		applyTransaction(() => {
			const state = this.query.getValue().mergeTags || [];
			const updatedData =
				data?.reduce((acc, curr) => {
					const index = acc?.findIndex((item) => item.metaKey === curr.metaKey);
					if (index === -1) {
						return [...acc, curr];
					}
					acc[index] = curr;
					return acc;
				}, state) || [];
			this.store.setMergeTags([...updatedData]);
		});
	}
}
