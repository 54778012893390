import { Injectable } from '@angular/core';
import produce from 'immer';
import { ApiService } from '../../../../../../core/base/api.service';
import { RMStore } from './rm.store';
import { RefixMortgage } from './rm.model';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class RMService {
  private readonly endpoint = 'widgets?type=RM';
  /**
   *
   */
  constructor(
    private api: ApiService,
    private store: RMStore
  ) { }


  /**
   * Fetch and store Refix mortgage data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetRefixMortgage(req: {
    advisers: string[],
    dateRangeMin: string,
    dateRangeMax: string
  }): void {
    this.store.reset();
    this.store.setLoading(true);
    this.api.post<RefixMortgage[]>(this.endpoint, {
      Advisers: req.advisers,
      DateRangeMin: req.dateRangeMin,
      DateRangeMax: req.dateRangeMax
    }).subscribe(
      res => this.store.update(produce(draft => {
        draft.RefixMortgageList = res;
      })),
      err => applyTransaction(() => {
        this.store.setError('Error fetching data!');
        this.store.setLoading(false);
      }),
      () => this.store.setLoading(false)
    );
  }

}
