<div class="app-business-container tap-search-page">
	<tabset #staticTabs>
		<!-- Business List -->
		<tab
			heading="Businesses"
			id="business"
			(selectTab)="onSelectTab($event)"
			customClass="mx-0"
		>
			<div class="px-3">
				<div class="d-flex justify-content-end align-items-center py-1">
					<div class="mr-3">
						<button
							type="button"
							class="tap-btn tap-btn--default"
							(click)="load()"
						>
							Load
						</button>
					</div>
					<div class="form-check form-check-inline">
						<input
							type="checkbox"
							class="form-check-input"
							id="allChkbox"
							[value]="showAll$ | async"
							[checked]="showAll$ | async"
							(change)="showAll()"
						/>
						<label class="form-check-label">Show All</label>
					</div>

					<div class="py-2">
						<app-manual-loader-mini
							[isLoading]="(isSearching$ | async) || (isLoading$ | async)"
						>
						</app-manual-loader-mini>
					</div>

					<div>
						<app-business-page-columns-form
							*ngIf="isOpenColumnsSettings$ | async"
							class="search-settings"
						>
						</app-business-page-columns-form>
						<button type="button" class="icon-btn" (click)="openColumnPopUp()">
							<i class="material-icons md-20 dark py-2"> settings </i>
						</button>
					</div>
				</div>

				<ngx-datatable
					#mydatatable
					class="bootstrap"
					[class.hasRightIndent]="hasRightIndent"
					[style.height]="tblHeight"
					[columnMode]="'standard'"
					[rows]="rows$ | async"
					[columns]="columnsWithLink$ | async"
					[headerHeight]="40"
					[scrollbarV]="true"
					[scrollbarH]="true"
					[rowHeight]="40"
					[rowIdentity]="rowIdentity"
					[externalSorting]="true"
					[trackByProp]="'BusinessID'"
					[rowClass]="getRowClass"
					(reorder)="reorder($event)"
					(resize)="resize($event)"
					(sort)="sort($event)"
				>
					<ngx-datatable-column
						*ngFor="let c of columns$ | async"
						[prop]="c.prop"
						[width]="c.width"
						[resizeable]="true"
						[name]="c.name"
						[headerClass]="
							c.controlType === 'money' || c.controlType === 'number'
								? 'hasTotal'
								: ''
						"
						[cellClass]="c.cellClass"
						[draggable]="!(c.metakey === 'Business Name')"
						[frozenLeft]="c.metakey === 'Business Name'"
					>
						<ng-template
							ngx-datatable-header-template
							let-column="column"
							let-sort="sortFn"
						>
							<i
								class="material-icons draggable mt-1"
								[id]="c.columnId"
								[class.d-none]="c.metakey === 'Business Name'"
								>drag_indicator</i
							>
							<span class="datatable-header-cell-wrapper" (click)="sort()">
								<ng-container [ngSwitch]="c.metakey">
									<ng-container *ngSwitchCase="'PL Current Month'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ plMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'AIA Current Month'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ aiaMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container
										*ngSwitchCase="'Total Inforce API Current Month'"
									>
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ totalMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container
										*ngSwitchCase="'Total Inforce API Current Month'"
									>
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ totalMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container
										*ngSwitchCase="'Total Inforce API Current Calendar Year'"
									>
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ totalYear$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'# of Clients'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ noOfClients$ | async | number }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'# of Advisers'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ noOfAdvisers$ | async | number }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'Fidelity Life Current Month'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ fidelityLifeMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'Asteron Current Month'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ asteronMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'NIB Current Month'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ nibMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'Blanket Current Month'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ blanketMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchCase="'Cigna Life Current Month'">
										<span class="datatable-header-cell-label"
											>{{ column.name }} <br />
											{{ cignaLifeMonth$ | async | currency }}</span
										><br />
									</ng-container>
									<ng-container *ngSwitchDefault>
										<span
											class="
												datatable-header-cell-label
												d-inline-block
												margin-top-7rem
											"
											>{{ column.name }}</span
										>
									</ng-container>
								</ng-container>
							</span>
						</ng-template>

						<ng-template
							ngx-datatable-cell-template
							let-row="row"
							let-value="value"
							let-rowIndex="rowIndex"
						>
							<ng-container [ngSwitch]="c.controlType">
								<ng-container *ngSwitchCase="'display'">
									<ng-container>
										<app-display
											[route]="businessRoute(c.metakey, row.CompanyCode)"
											[value]="value"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
										>
										</app-display>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'money'">
									<ng-container>
										<app-display
											[route]="businessRoute(c.metakey, row.CompanyCode)"
											[value]="value | currency"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
										>
										</app-display>
									</ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'number'">
									<ng-container>
										<app-display
											[route]="businessRoute(c.metakey, row.CompanyCode)"
											[value]="value | number"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
										>
										</app-display>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-template>
					</ngx-datatable-column>
					<ngx-datatable-column
						[prop]="linkColumn.prop"
						[name]="linkColumn.name"
						[width]="linkColumn.width"
						[resizeable]="false"
						headerClass="link-header"
					>
						<ng-template
							ngx-datatable-cell-template
							let-value="value"
							let-row="row"
							let-rowIndex="rowIndex"
						>
							<ng-container>
								<div
									class="d-flex justify-content-around align-items-center pt-2"
								>
									<a
										[class.disabled-icon]="
											(isSearching$ | async) || (isLoading$ | async)
										"
										*ngIf="row.IsActive === 1"
										class="icon-btn btn-edit"
										(click)="confirmArchive(row, 2)"
										id="archiveBtn_{{rowIndex}}"
									>
										<i
											class="material-icons md-20 dark"
											data-toggle="tooltip"
											title="archive"
											>archive</i
										>
									</a>
									<a
										[class.disabled-icon]="
											(isSearching$ | async) || (isLoading$ | async)
										"
										*ngIf="row.IsActive === 2"
										class="icon-btn btn-edit"
										(click)="confirmArchive(row, 1)"
										id="unarchiveBtn_{{rowIndex}}"
									>
										<i
											class="material-icons md-20 dark"
											data-toggle="tooltip"
											title="unarchive"
											>unarchive</i
										>
									</a>
									<a
										[class.disabled-icon]="
											(isSearching$ | async) || (isLoading$ | async)
										"
										class="icon-btn btn-edit"
										[routerLink]="route(row.CompanyCode)"
										id="editBtn_{{rowIndex}}"
									>
										<i class="material-icons md-20 dark">edit</i>
									</a>
								</div>
							</ng-container>
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>
			</div>
		</tab>
	</tabset>
</div>
