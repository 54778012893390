import {
	AdviceProcessPageCodes,
	KiwiSaverAdviceProcessPageIds,
	KiwiSaverAdviceProcessPageNames,
} from '../../../../../shared/models/advice-process/advice-process.model';
import { Sidebar } from '../../_shared/models/sidebar.model';
export const kiwisaverSidebars: Sidebar[] = [
	{
		id: KiwiSaverAdviceProcessPageIds.Introduction,
		name: KiwiSaverAdviceProcessPageNames.Introduction,
		link: KiwiSaverAdviceProcessPageIds.Introduction,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.Introduction,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.Disclosure,
		name: KiwiSaverAdviceProcessPageNames.Disclosure,
		link: KiwiSaverAdviceProcessPageIds.Disclosure,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.Disclosure,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.ScopeOfService,
		name: KiwiSaverAdviceProcessPageNames.ScopeOfService,
		link: KiwiSaverAdviceProcessPageIds.ScopeOfService,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.SOS,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.FactFind,
		name: KiwiSaverAdviceProcessPageNames.YourKiwiSaver,
		link: KiwiSaverAdviceProcessPageIds.YourKiwiSaver,
		parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.FactFind,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.WhyKiwiSaver,
		name: KiwiSaverAdviceProcessPageNames.WhyKiwiSaver,
		link: KiwiSaverAdviceProcessPageIds.WhyKiwiSaver,
		parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.WhyKiwiSaver,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.HowKiwiSaverWorks,
		name: KiwiSaverAdviceProcessPageNames.HowKiwiSaverWorks,
		link: KiwiSaverAdviceProcessPageIds.HowKiwiSaverWorks,
		parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.HowKiwiSaverWorks,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.InvestmentStrategy,
		name: KiwiSaverAdviceProcessPageNames.InvestmentStrategy,
		link: KiwiSaverAdviceProcessPageIds.InvestmentStrategy,
		parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.InvestmentStrategy,
	},
	// {
	// 	id: KiwiSaverAdviceProcessPageIds.PassiveVsActive,
	// 	name: KiwiSaverAdviceProcessPageNames.PassiveVsActive,
	// 	link: KiwiSaverAdviceProcessPageIds.PassiveVsActive,
	// 	parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
	// 	isSubMenu: false,
	// 	subMenu: [],
	// 	customClass: '',
	// 	status: 0,
	// 	warning: '',
	// 	pageCode: AdviceProcessPageCodes.PassiveVsActive,
	// },
	// {
	// 	id: KiwiSaverAdviceProcessPageIds.RiskVsVolatility,
	// 	name: KiwiSaverAdviceProcessPageNames.RiskVsVolatility,
	// 	link: KiwiSaverAdviceProcessPageIds.RiskVsVolatility,
	// 	parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
	// 	isSubMenu: false,
	// 	subMenu: [],
	// 	customClass: '',
	// 	status: 0,
	// 	warning: '',
	// 	pageCode: AdviceProcessPageCodes.RiskVsVolatility,
	// },
	{
		id: KiwiSaverAdviceProcessPageIds.KiwiSaverProjection,
		name: KiwiSaverAdviceProcessPageNames.KiwiSaverProjection,
		link: KiwiSaverAdviceProcessPageIds.KiwiSaverProjection,
		parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.KiwiSaverProjection,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.RiskProfile,
		name: KiwiSaverAdviceProcessPageNames.RiskProfile,
		link: KiwiSaverAdviceProcessPageIds.RiskProfile,
		parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.RiskProfile,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.Providers,
		name: KiwiSaverAdviceProcessPageNames.Providers,
		link: KiwiSaverAdviceProcessPageIds.Providers,
		parentRouterLink: KiwiSaverAdviceProcessPageIds.FactFind,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.Providers,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.Declaration,
		name: KiwiSaverAdviceProcessPageNames.Declaration,
		link: KiwiSaverAdviceProcessPageIds.Declaration,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.Declaration,
	},
	{
		id: KiwiSaverAdviceProcessPageIds.AdviceSummary,
		name: KiwiSaverAdviceProcessPageNames.AdviceSummary,
		link: KiwiSaverAdviceProcessPageIds.AdviceSummary,
		isSubMenu: false,
		subMenu: [],
		customClass: '',
		status: 0,
		warning: '',
		pageCode: AdviceProcessPageCodes.AdviceSummary,
	},
];
