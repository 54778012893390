import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { MatDataTableScrollComponent } from './mat-data-table-scroll.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [MatDataTableScrollComponent],
	exports: [MatDataTableScrollComponent],
	imports: [
		CommonModule,
		SharedModule,
		DragDropModule,
		MatTableModule,
		MatSortModule,
		ScrollingModule,
		CdkTableModule,
		MatTooltipModule,
	],
})
export class MatDataTableScrollModule {}
