import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LiabilityState, LiabilityStore } from './liability.store';

@Injectable()
export class LiabilityQuery extends QueryEntity<LiabilityState> {
	liabilities$ = this.selectAll({ filterBy: (x) => !!x.status });
	isLoading$ = this.selectLoading();

	constructor(protected store: LiabilityStore) {
		super(store);
	}
}
