import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	KiwiSaverSettingsState,
	KiwiSaverSettingsStore,
} from './kiwisaver-settings.store';
import { of } from 'rxjs';
import {
	map,
	shareReplay,
	mergeMap,
	switchMap,
	filter,
	tap,
} from 'rxjs/operators';
import { mapDropdownToChoices } from '@domain/dropdown-value/dropdown-value.model';
@Injectable({
	providedIn: 'root',
})
export class KiwiSaverSettingsQuery extends Query<KiwiSaverSettingsState> {
	mergeTags$ = this.select((state) => state.mergeTags);

	introSettings$ = this.select((state) => state.introSettings);
	scopeOfService$ = this.select((state) => state.scopeOfService);

	scopeOfServiceTemplateSettings$ = this.select(
		(state) => state.scopeOfServiceTemplateSettings
	);

	/**
	 * @returns observable of Declaration
	 */
	declaration$ = this.select((state) => state.declaration);

	/**
	 * @returns observable of Declaration Email
	 */
	declarationEmail$ = this.select((state) => state.declarationEmailSettings);

	factFindSubpagesSettings$ = this.select(
		(state) => state.factFindSubpagesSettings
	);

	factFindRiskProfileQASettings$ = this.select(
		(state) => state.factFindRiskProfileQASettings
	);

	factFindRiskProfileOutcomesSettings$ = this.select(
		(state) => state.factFindRiskProfileOutcomesSettings
	);

	adviceSummarySettings$ = this.select((state) => state.adviceSummarySettings);

	adviceSummaryEmailSettings$ = this.select(
		(state) => state.adviceSummaryEmailSettings
	);

	documentList$ = this.select((state) => state.documentList);

	providersSettings$ = this.select((state) => state.providersSettings);

	endProcessEmailSettings$ = this.select(
		(state) => state.endProcessEmailSettings
	);

	orderedChoices$ = this.select((state) => state.providerDropdowns).pipe(
		map(mapDropdownToChoices)
	);

	constructor(protected store: KiwiSaverSettingsStore) {
		super(store);
	}
}
