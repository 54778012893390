import { Routes, RouterModule } from '@angular/router';
import { TlReportComponent } from './tl-report.component';
import { NgModule } from '@angular/core';
import { TlReportService } from './tl-report.service';

const routes: Routes = [
    {
        path: '',
        component: TlReportComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [TlReportService]
})
export class TlReportRoutingModule { }
