<div *ngIf="isProviderLoading$ | async; else showForm" class="d-block pt-3">
	<app-manual-loader-mini
		[isLoading]="isProviderLoading$ | async"
	></app-manual-loader-mini>
</div>

<ng-template #showForm>
	<form [formGroup]="form" class="form fg-provider-form">
		<div class="form-row pb-0 pl-3 pb-2 pt-4 provider-commission-fg">
			<div class="col-12">
				<div class="col-12">
					<div class="form-row mb-4 provider-commission-fg__header">
						<div
							class="
								col-12 col-lg-1
								pt-3 pt-lg-0
								d-lg-inline-block d-none
								provider-commission-fg__header__switch
							"
						></div>
						<div class="col-12 col-lg-2 d-lg-inline-block d-none">
							<label class="provider-commission-fg__header__description"
								>Provider</label
							>
						</div>
						<div class="col-12 col-lg-1 p-0 pl-2 pr-1 d-lg-inline-block d-none">
							<label class="provider-commission-fg__header__description"
								>Min Upfront</label
							>
						</div>
						<div class="col-12 col-lg-1 p-0 pl-2 pr-1 d-lg-inline-block d-none">
							<label class="provider-commission-fg__header__description"
								>Max Upfront</label
							>
						</div>
						<div class="col-12 col-lg-1 p-0 pl-1 pr-1 d-lg-inline-block d-none">
							<label class="provider-commission-fg__header__description"
								>Min Renewal</label
							>
						</div>
						<div class="col-12 col-lg-1 p-0 pl-1 pr-1 d-lg-inline-block d-none">
							<label class="provider-commission-fg__header__description"
								>Max Renewal</label
							>
						</div>
						<div class="col-12 col-lg-2 p-0 pl-1 pr-1 d-lg-inline-block d-none">
							<label class="provider-commission-fg__header__description"
								>Fee</label
							>
						</div>
						<div class="col-12 col-lg-1 d-lg-inline-block d-none"></div>
					</div>

					<div formArrayName="providerCommission">
						<div
							cdkDropList
							class="referral-options__list"
							(cdkDropListDropped)="drop($event)"
							[cdkDropListData]="ProviderCommission.controls"
						>
							<div
								class="form-row provider-commission-fg__values pt-0"
								*ngFor="
									let item of ProviderCommission.controls;
									let pointIndex = index
								"
								[formGroupName]="pointIndex"
								cdkDrag
								[cdkDragData]="pointIndex"
								[cdkDragDisabled]="!editMode || addMode"
							>
								<div
									class="
										col-12 col-lg-1
										p-0
										pt-3
										pl-0
										pt-lg-0
										pl-lg-0
										provider-commission-fg__values__switch
									"
									[class.disabled]="editMode"
								>
									<span>
										<app-switch
											[tempValue]="item.get('isTicked').value"
											fieldId="switchFG-{{ pointIndex }}"
											[isEditing]="!editMode"
											[isEditable]="true"
											[hideControl]="true"
											[isRequired]="false"
											[restrict]="[]"
											[noRestriction]="true"
											(fieldUpdateEvent)="updateTick(pointIndex)"
										></app-switch>
									</span>
								</div>
								<div
									class="
										col-12
										p-1
										col-lg-2
										provider-commission-fg__values__provider
									"
								>
									<input
										id="provider"
										class="form-control"
										type="text"
										formControlName="provider"
										placeholder="Provider"
										[class.disabled]="
											!!item.get('fromGlobalSettings').value ||
											(addMode && !item.get('isNew').value)
										"
										(mousedown)="$event.stopPropagation()"
									/>
								</div>
								<div
									class="
										col-12
										p-1
										col-lg-1
										provider-commission-fg__values__min-upfront
									"
								>
									<input
										id="minUpfront"
										class="form-control"
										type="text"
										formControlName="minUpfront"
										[class.disabled]="addMode && !item.get('isNew').value"
										(mousedown)="$event.stopPropagation()"
									/>
								</div>
								<div
									class="
										col-12
										p-1
										col-lg-1
										provider-commission-fg__values__max-upfront
									"
								>
									<input
										id="maxUpfront"
										class="form-control"
										type="text"
										formControlName="maxUpfront"
										[class.disabled]="addMode && !item.get('isNew').value"
										(mousedown)="$event.stopPropagation()"
									/>
								</div>
								<div
									class="
										col-12
										p-1
										col-lg-1
										provider-commission-fg__values__min-renewal
									"
								>
									<input
										id="minRenewal"
										class="form-control"
										type="text"
										formControlName="minRenewal"
										[class.disabled]="addMode && !item.get('isNew').value"
										(mousedown)="$event.stopPropagation()"
									/>
								</div>
								<div
									class="
										col-12
										p-1
										col-lg-1
										provider-commission-fg__values__max-renewal
									"
								>
									<input
										id="maxRenewal"
										class="form-control"
										type="text"
										formControlName="maxRenewal"
										[class.disabled]="addMode && !item.get('isNew').value"
										(mousedown)="$event.stopPropagation()"
									/>
								</div>
								<div
									class="
										col-12
										p-1
										col-lg-2
										provider-commission-fg__values__fee
									"
								>
									<div>
										<input
											id="fee"
											class="form-control"
											type="text"
											formControlName="fee"
											placeholder="Amount"
											[class.disabled]="addMode && !item.get('isNew').value"
											(mousedown)="$event.stopPropagation()"
										/>
									</div>
								</div>
								<div
									class="
										col-12 col-lg-2
										p-0
										m-0
										pt-1
										text-right text-lg-left
										provider-commission-fg__values__action
									"
								>
									<ng-container>
										<span
											class="
												provider-commission-fg__values__loader
												d-lg-none d-inline-block
												mt-1
											"
										>
											<app-manual-loader-mini
												[isLoading]="item.get('isLoading').value"
											></app-manual-loader-mini>
										</span>
									</ng-container>

									<ng-container *ngIf="editMode">
										<span
											class="
												provider-commission-fg__drag
												sortable-item
												ml-1
												mr-1
												pl-1
												pr-1
											"
											*ngIf="!addMode"
										>
											<i class="material-icons md-18 tap-text-color-h6"
												>drag_indicator</i
											>
										</span>

										<button
											type="button"
											class="icon-btn p-0"
											(click)="saveItem(pointIndex)"
											*ngIf="item.get('isNew').value"
											[disabled]="isSaving"
										>
											<i class="material-icons md-20 tap-text-color-h6 p-0">
												save
											</i>
										</button>

										<ng-container *ngIf="!item.get('isNew').value">
											<button
												type="button"
												class="icon-btn p-0"
												(click)="deleteItem(pointIndex)"
												[disabled]="!!item.get('fromGlobalSettings').value"
												*ngIf="!addMode"
											>
												<i class="material-icons md-20 tap-text-color-h6 p-0">
													delete
												</i>
											</button>
										</ng-container>
										<ng-container *ngIf="item.get('isNew').value">
											<button
												type="button"
												class="icon-btn p-0"
												(click)="deleteNewItem(pointIndex)"
												[disabled]="isSaving"
											>
												<i class="material-icons md-20 tap-text-color-h6 p-0">
													close
												</i>
											</button>
										</ng-container>
										<ng-container>
											<span
												class="
													provider-commission-fg__values__loader
													d-lg-inline-block d-none
													position-absolute
													mt-1
												"
											>
												<!-- loader for delete -->
												<app-manual-loader-mini
													[isLoading]="!isSaving && item.get('isLoading').value"
												></app-manual-loader-mini>
											</span>
										</ng-container>
									</ng-container>
								</div>
							</div>
						</div>
					</div>

					<div class="form-row mb-4 mt-3 provider-commission-fg__buttons">
						<div
							class="
								col-12 col-lg-12
								pt-3 pt-lg-0
								provider-commission-fg__buttons__switch
							"
						></div>

						<div
							class="col-12 provider-commission-fg__buttons__add"
							*ngIf="!isSaving"
						>
							<ng-template #editModeBtns>
								<button
									type="button"
									class="btn btn-primary tap-bg-primary mr-2"
									(click)="addNewItem()"
									[disabled]="addMode"
								>
									Add New
								</button>

								<button
									type="button"
									class="btn btn-primary tap-bg-primary mr-2"
									(click)="saveAll()"
									[disabled]="addMode"
								>
									Save
								</button>

								<button
									type="button"
									class="btn btn-primary tap-bg-primary mr-2"
									(click)="cancelEdit()"
								>
									Cancel
								</button>
							</ng-template>

							<button
								type="button"
								class="btn btn-primary tap-bg-primary mr-2"
								(click)="editAll()"
								*ngIf="!editMode; else editModeBtns"
							>
								Edit
							</button>
						</div>
						<div
							class="col-12 provider-commission-fg__buttons__add__loading"
							*ngIf="isSaving"
						>
							<app-manual-loader-mini
								[isLoading]="isSaving"
							></app-manual-loader-mini>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</ng-template>
