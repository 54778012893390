import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { AdviceProcessSectionCodes, LoatFactFindMergeTagCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { EmailTypes } from 'src/app/shared/models/emails/crt/email.model';
import { AuthorityToProceedSettingsService } from './authority-to-proceed-settings/state/authority-to-proceed-settings.service';
import { ClientAcceptanceSettingsService } from './client-acceptance-settings/state/client-acceptance-settings.service';
import {
  DeclarationEmailSettingsService
} from './declaration-settings/declaration-email-settings/state/declaration-email-settings.service';
import { DeclarationSettingsService } from './declaration-settings/declaration-template-settings/state/declaration-settings.service';
import { EndProcessEmailSettingsService } from './end-process/state/end-process.service';
import { FinalStructureSettingsService } from './final-structure-settings/state/final-structure-settings.service';
import { IntroSettingsService } from './intro-page-settings/state/intro-settings.service';
import { MergeTagsCrtSettingsService } from './merge-tags/state/merge-tags-crt-settings.service';
import { ScopeOfServiceService } from './scope-of-service-settings/state/scope-of-service.service';
import { SoaSettingsService } from './soa-settings/state/soa-settings.service';
import { DocumentListService } from './soa-settings/document-list/state/document-list.service';
import { SettingsTypes } from './state/crt-settings.model';
import { LoatCffTemplateSettingsService } from './loat-cff-template-settings/state/loat-cff-template-settings.service';
import { SpecialFeaturesSettingsService } from '@modules/special-features/state/special-features-settings.service';
import { RouteService } from '@core/config/route.service';
import { BusinessConfigService } from '@domain/business-config/business-config.service';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';

// import { DocumentListService } from './document-list/state/document-list.service';
// import { DocumentListService as MoatDocumentListService } from '../mortgage-settings/document-list/state/document-list.service';

@Injectable()
export class CrtSettingsResolver implements Resolve<boolean> {
  constructor(
    private sService: ScopeOfServiceService,
    private iService: IntroSettingsService,
    private decService: DeclarationSettingsService,
    private decEmailService: DeclarationEmailSettingsService,
    private soaService: SoaSettingsService,
    private mtService: MergeTagsCrtSettingsService,
    private fsService: FinalStructureSettingsService,
    private caService: ClientAcceptanceSettingsService,
    private apService: AuthorityToProceedSettingsService,
    private epService: EndProcessEmailSettingsService,
    private dlService: DocumentListService,
    private loatCffSettings: LoatCffTemplateSettingsService,
		private specialFeaturesSettingsService: SpecialFeaturesSettingsService,
    // private moatDocumentListService: MoatDocumentListService,
    private router: Router,
    private routeService: RouteService,
		private bConfigService: BusinessConfigService,
		private bProfileService: BusinessProfileService
  ) {}

  resolve(): Observable<boolean> {
		const companyCode = this.bConfigService.companyCode();
    this.iService.clear();

    const refId = 0;
    const getIntro$ = this.iService.getIntroSettings(refId, AdviceProcessSectionCodes.Introduction);
    const getScopeOfService$ = this.sService.getScopeOfService(refId, AdviceProcessSectionCodes.SOS);
    const getDeclaration$ = this.decService.getDeclarationDocument(refId, AdviceProcessSectionCodes.Declaration);
    const getDeclarationEmail$ = this.decEmailService.getDeclarationEmailSettings(refId, EmailTypes.Declaration);
    const getSoaSettings$ = this.soaService.getSoaSettings(refId, AdviceProcessSectionCodes.StatementOfAdvice);
    const getFinalStructure$ = this.fsService.getFinalStructure(refId, AdviceProcessSectionCodes.FinalStructure);
    const getClientAcceptance$ = this.caService.getClientAcceptance(refId, AdviceProcessSectionCodes.AuthorityToProceed);
		const getSoaEmailSettings$ = this.soaService.getEmailSettings(refId, AdviceProcessSectionCodes.SOAEmailSettings);

    const getAuthorityToProceed$ = this.apService.getAuthorityToProceed(refId, 'ATP');
    const getEndProcessEmail$ = this.epService.getSettings(refId, AdviceProcessSectionCodes.EndProcess);
    const getSoaDocumentList$ = this.dlService.getDocumentList(refId, SettingsTypes.DocumentList);
		const getLoatCffSettings$ = this.loatCffSettings.getLoatCffDocument(refId, SettingsTypes.LOATClientFactFind);

    // const getCARChangeRequestSettings$ = this.carService.getCARChangeRequestSettings(refId, SettingsTypes.CARChangeRequest);
    // const getCARUpdatedStructureSettings$ = this.carService.getCARUpdatedStructureSettings(refId, SettingsTypes.CARUpdatedStructure);

    // const getDocumentList$ = this.moatDocumentListService.getDocumentList(
		// 	0,
		// 	'MGDL'
		// );

    // Merge Tags
    let i = 0;
    const getMtGeneral$ = this.mtService.getMergeTags(i++, 'general', refId);
    const getMtBusiness$ = this.mtService.getMergeTags(i++, 'business', refId);
    const getMTStaff$ = this.mtService.getMergeTags(i++, 'staff', +refId);
    // Settings
    const getMTProviders$ = this.mtService.getMtSettings(i++, 'PCT');
    const getMTLrProvider$ = this.mtService.getMtSettings(i++, 'PCLR');
    const getMTLrDetails$ = this.mtService.getMtSettingDetails(i++, 'PCLR');
    // Fact Find > People
    const getPeople$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.People);
    const getDependants$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.Dependants);
    const getTrusts$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.Trust);
    const getCompany$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.Company);
    // Fact Find > A&L
    const getProperties$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.Property);
    const getKiwiSaver$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.KiwiSaver);
    const getAssets$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.AssetsInvestments);
    const getMortages$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.Mortgages);
    const getLiabilities$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.Liabilities);
    const getAssetsLiabilities$ = this.mtService.getMtFactFind(i++, AdviceProcessSectionCodes.AssetsLiabilities);
		// Declaration Client Fact Find
    const getLoatCffPeople$ = this.mtService.getMtFactFind(i++, LoatFactFindMergeTagCodes.People);
    const getLoatCffCompany$ = this.mtService.getMtFactFind(i++, LoatFactFindMergeTagCodes.Company);
    const getLoatCffIncomeExpense$ = this.mtService.getMtFactFind(i++, LoatFactFindMergeTagCodes.IncomeExpense);
    const getLoatCffMedicalHistory$ = this.mtService.getMtFactFind(i++, LoatFactFindMergeTagCodes.MedicalHistory);
    // Risk Analysis
    const getGoals$ = this.mtService.getMtRiskAnalysis(i++, AdviceProcessSectionCodes.Goals);
    const getRiskProfile$ = this.mtService.getMtRiskAnalysis(i++, AdviceProcessSectionCodes.RiskProfile);
    const getLife$ = this.mtService.getMtRiskAnalysis(i++, AdviceProcessSectionCodes.Life);
    const getCriticalIllness$ = this.mtService.getMtRiskAnalysis(i++, AdviceProcessSectionCodes.CriticalIllness);
    const getDisability$ = this.mtService.getMtRiskAnalysis(i++, AdviceProcessSectionCodes.Disability);
    const getMedical$ = this.mtService.getMtRiskAnalysis(i++, AdviceProcessSectionCodes.Medical);
    const getTpd$ = this.mtService.getMtRiskAnalysis(i++, AdviceProcessSectionCodes.TPD);
		const specialFeaturesSettings$ = this.specialFeaturesSettingsService.getSpecialFeatures();

    return forkJoin([
      getIntro$,
      getScopeOfService$,
      getDeclaration$,
      getDeclarationEmail$,
      getSoaSettings$,
      getSoaDocumentList$,
			getLoatCffSettings$,
      // getDocumentList$,

      // CAR
      // getCARChangeRequestSettings$,
      // getCARUpdatedStructureSettings$,

      // Merge Tags
      getMtGeneral$,
      getMtBusiness$,
      getMTProviders$,
      getMTLrProvider$,
      getMTLrDetails$,
      getMTStaff$,
      // Fact Find
      getPeople$,
      getDependants$,
      getTrusts$,
      getCompany$,
      getProperties$,
      getKiwiSaver$,
      getAssets$,
      getMortages$,
      getLiabilities$,
      getAssetsLiabilities$,
			// Declaration Client Fact Find
			getLoatCffPeople$,
			getLoatCffCompany$,
			getLoatCffIncomeExpense$,
			getLoatCffMedicalHistory$,
      // Risk Analysis
      getGoals$,
      getRiskProfile$,
      getLife$,
      getCriticalIllness$,
      getDisability$,
      getMedical$,
      getTpd$,
      // Authority to Proceed
      getAuthorityToProceed$,
      // Final Structure
      getFinalStructure$,
      // Client Acceptance
      getClientAcceptance$,
			getSoaEmailSettings$,
			// End Process Email Settings
			getEndProcessEmail$,
			specialFeaturesSettings$
    ]).pipe(
			withLatestFrom(
				this.bProfileService.hasLR$,
				this.bProfileService.hasCRT$
			),
			tap(([, hasLR, hasLOAT]) => {
				if (!hasLR || !hasLOAT) {
					const redirect = this.routeService.customBusiness(companyCode);
					this.router.navigate(this.routeService.splitToRoutePath(redirect));
				}
			}),
		mapTo(true)
		);
  }
}
