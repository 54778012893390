export enum SettingsTypes {
  AuthorityToProceed = 'CA',
  Declaration = 'D',
  Disclosure = 'DD',
  Introduction = 'IP',
  SOA = 'SOA',
  SOS = 'SOS',
  EndProcess = 'APEP',
  DocumentList = 'LOATSOADL',
	LOATClientFactFind = 'LOATCFF',
	ConfirmationCall = 'LOATCCES',

  // CAR
	CARChangeRequest = 'CARCR',
	CARUpdatedStructure = 'CARUS'
}

export enum ReloadDocumentType {
	// LOAT
	ClientFactFind = 'LOATCFF',

  // CAR
  ChangeRequest = 'CARCR',
	UpdatedStructure = 'CARUS'
}