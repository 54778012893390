<div class="mx-3 pt-3">
  <h5 class="py-1 my-0 font-weight-bold">Import Progress <small class="mx-3 font-italic text-dark">do not close
      this page
      and refrain from cancelling</small></h5>
  <hr class="divider" />
  <div class="progress my-2">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
      aria-valuemin="0" aria-valuemax="100" [ngStyle]="progress">{{percent }}%</div>
  </div>
  <div>
    <p class="my-1">
      {{uploadCount}} / {{rowCount ? rowCount : '0' }} records uploaded
    </p>
    <p class="my-1">
      {{rowCount}} records to upload
    </p>
    <p class="my-1">
      {{successCount}} success
    </p>
    <p class="my-1">
      {{failCount}} failed
    </p>
    <p class="my-1">
      Starts at {{startTime}}
    </p>
  </div>

  <div class="container-fluid px-0">
    <div class="d-flex justify-content-end align-items-center mx-3">
      <button type="button" class="btn btn-primary my-2 mx-1" [appTheme]="{'background-color':'primarycolor'}"
        (click)="cancelImport()" [disabled]="uploadCount === 0">CANCEL</button>
    </div>
  </div>
</div>