import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	QueryList,
	ViewChildren,
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { Observable, iif, of } from 'rxjs';
import {
	concatMap,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { LocalService } from 'src/app/core/services/local.service';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { ClientProfileQuery } from 'src/app/modules/crm/client-profile/states/client-profile.query';
import { ClientReviewTemplateQuery } from 'src/app/modules/crm/client-review-template/states/client-review-template.query';
import { EmailService } from 'src/app/modules/crm/client-review-template/states/email/email.service';
import { CrtMergeTagsService } from 'src/app/modules/crm/client-review-template/states/merge-tags/crt-merge-tags.service';
import { PeopleService } from 'src/app/modules/crm/client-review-template/states/people/people.service';
import { CrtSettingsQuery } from 'src/app/modules/crt-settings/state/crt-settings.query';
import MomentUtil from 'src/app/util/moment.util';
import {
	addMonthsToDateWithFormat,
	strUtil,
} from 'src/app/util/util';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import { logMessage } from '../../error-message/error-message';
import {
	AdviceProcessCode,
	AdviceProcessDocumentState,
	AdviceProcessSectionCodes,
	DynamicFieldState,
	ServiceAdviceProcessState,
} from '../../models/advice-process/advice-process.model';
import { DependentState } from '../../models/client-review-template/dependent/dependent.model';
import { PeopleState } from '../../models/client-review-template/people/people.model';
import { EmailBodyTypes, EmailTypes } from '../../models/emails/crt/email.model';
import { ServicesCodes } from '../../models/services/services.model';
import { ActivityViewModel } from '../../models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { ActivityModalComponent } from '../activity/activity-modal/activity-modal.component';
import { CrmEmailModalComponent } from '../crm-email-modal/crm-email-modal.component';
import { KiwiSaverSettingsQuery } from '@modules/kiwisaver-settings/state/kiwisaver-settings.query';
import { EndProcessEmailSettingsService as KsEndProcessEmailSettingsService } from 'src/app/modules/kiwisaver-settings/end-process/state/end-process.service';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { MortgageEndProcessSettingsService } from '@modules/mortgage-settings/mortgage-end-process/state/mortgage-end-process.service';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { CustomerAlterrationRequestService } from '@modules/customer-alteration-request-settings/states/customer-alterration-request.service';
import { EmailModalComponent } from '../crt/email/email-modal.component';
import { PrimaryCustomerCompanyState } from '@shared/models/business-profile/business/business.model';


declare var $: any;
export const questionTypes = {
	details: 'Details',
	date: 'Date',
	listOfObjects: 'LO',
};

@Component({
	selector: 'app-advice-process-end-modal',
	templateUrl: './advice-process-end-modal.component.html',
	styleUrls: ['./advice-process-end-modal.component.scss'],
})
export class AdviceProcessEndModalComponent implements OnInit, AfterViewInit {
	header = '';
	isComplete = false;
	questions: {
		adviceProcessQuestionnaireID: number;
		question: string;
		questionKey: string;
		questionType: string;
		dataType: string;
	}[] = [];
	answers: DynamicFieldState[] = [];
	questionType = '';
	service = '';
	saveFn: (data) => Observable<any>;

	// Activity
	isLead: boolean;
	customerId: number;
	customerName: string;
	location: string;
	adviser: number;
	activityType$: Observable<ViewDisplayValue[]>;
	adviserChoices$: Observable<ViewDisplayValue[]>;
	adviserCalendarChoices$: Observable<ViewDisplayValue[]>;
	apcrtqi1Choices$: Observable<ViewDisplayValue[]>;
	apcrtqclr7Choices$: Observable<ViewDisplayValue[]>;
	apcrtqi6Choices$: Observable<ViewDisplayValue[]>;
	apcrtfccChoices$: Observable<ViewDisplayValue[]>;
	apcrtynnaChoices$: Observable<ViewDisplayValue[]>;
	apcrtqik6Choices$: Observable<ViewDisplayValue[]>;
	carqicar1$: Observable<ViewDisplayValue[]>;
	apcrtqik15Choices$: Observable<ViewDisplayValue[]>;
	clientChoices: ViewDisplayValue[];
	addActivityFn$: (req: ActivityViewModel) => Observable<any>;
	isUploadedAllDocuments: boolean;
	missingDocuments: AdviceProcessDocumentState[] = [];

	// Advice Process
	clientsInvolved: ViewDisplayValue[] = []; // All clients involved
	clientsWithCrtId: ViewDisplayValue[] = [];
	peopleInvolved: ViewDisplayValue[] = []; // All clients involved that are PCI and SCI only
	adviceProcess: ServiceAdviceProcessState;

	hasAdviceSummaryPresented = false;

	isSaving = false;
	isValid = false;
	isViewSummary = false;

	form: UntypedFormGroup;

	// Custom for inclomplete L&R
	isAnswerCompleted: boolean;
	isCompany: boolean;
	mergeTags$ = this.mtService.mergeTags$;
  isAdviserReworkOn = this.bsCongifQuery.getValue().config.AdviserRework;
	processCodes = AdviceProcessCode;
	endProcessSettings$ = of({});
	@ViewChildren('otherInput') otherInputRefs: QueryList<ElementRef>;
	@ViewChildren('otherSelect') otherSelectRefs: QueryList<ElementRef>;

	adviceProcessPrefills: any;
	primaryClient: PrimaryClientState;
	primaryCompany: PrimaryCustomerCompanyState;
	isEmailLoading = false;
	showOldMortgageQuestions = false;

	yesOrNo$ = of([
		{
			text: 'Yes',
			value: 'Yes',
		},
		{
			text: 'No',
			value: 'No',
		},
	]);

	constructor(
		public bsModalRef: BsModalRef,
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private localService: LocalService,
		private emailService: EmailService,
		private crtSettingsQuery: CrtSettingsQuery,
		private mtService: CrtMergeTagsService,
		private peopleService: PeopleService,
		private bsCongifQuery: BusinessConfigQuery,
		private crtQuery: ClientReviewTemplateQuery,
		private clientProfileQuery: ClientProfileQuery,
		public kiwiSaverQuery: KiwiSaverSettingsQuery,
		private kiwiSaverEndprocess: KsEndProcessEmailSettingsService,
		private ref: ChangeDetectorRef,
		private clientProfileService: ClientProfileService,
		private mortgageEmailSettingsService: MortgageEndProcessSettingsService,
		private carService: CustomerAlterrationRequestService
	) {}

	get isModalBlock(): boolean {
		return this.service === ServicesCodes.ClientAlterationRequest;
	}

	get isMissingDocuments(): boolean {
		return !R.isEmpty(this.missingDocuments); 
	}

	ngAfterViewInit() {
		this.isValid = this.checkRequiredFields();
	}

	ngOnInit(): void {
		this.endProcessSettings$ =
			this.service === ServicesCodes.KiwiSaver
				? this.kiwiSaverQuery.endProcessEmailSettings$
			  : this.adviceProcess.processCode === AdviceProcessCode.MortgageAdvice
				? this.mortgageEmailSettingsService.endProcessEmailSettings$
			  : this.adviceProcess.processCode === AdviceProcessCode.ClientAlterationRequest
				? this.carService.getEmailSettings(EmailBodyTypes.CAREndProcess)
				: this.crtSettingsQuery.endProcessEmailSettings$;

		this.peopleInvolved = this.getPeopleInvolved();

		this.createForm();
		this.isValid = this.checkRequiredFields();

		this.hasAdviceSummaryPresented = !!this.adviceProcess?.stages?.find(
			(stage) => stage?.field === 'Advice Summary Presented'
		)?.value;

		this.getHeaderTitle();

		this.form.valueChanges.subscribe((x) => {
			this.isValid = this.checkRequiredFields();
		});
	}

	getHeaderTitle() {
		const isLR =
			this.adviceProcess.processCode === AdviceProcessCode.LRAdviceReview ||
			this.adviceProcess.processCode === AdviceProcessCode.LRAdviceNew;
		const oldHeader = this.header;

		this.header = `${oldHeader}
			${isLR ? ' ' : ' - '}
			${this.isComplete || this.isAnswerCompleted ? 'Complete' : 'Incomplete'}`;
	}

	trackByFn(index, item) {
		return item.AdviceProcessQuestionnaireID;
	}

	createForm() {
		this.showOldMortgageQuestions = this.toShowOldMortgageQuestions();
		const qType = this.questionType;
		const questions = this.questions;
		const answers = this.answers?.map((x) =>
			x.field === 'QCLR7'
				? { ...x, value: !!x.value ? JSON.parse(x.value) : x.value }
				: ['QILR7', 'QCLR8', 'QIM3', 'QCM7', 'QCI2', 'QII4', 'QIK7', 'QCK9', 'QICAR2', 'QCCAR5'].includes(x.field) // Questions with vulnerable
				? { ...x, value: this.getClientsInvolvedAnswers(x?.value) }
				: x.field === 'QCM4 Details'
				? { ...x, value: !!x.value ? x.value : this.adviceProcessPrefills?.currentLender }
				: x.field === 'QCM5 Details'
				? { ...x, value: !!x.value ? x.value : this.adviceProcessPrefills?.previousLender }
				: x.field === 'QCM6 Details'
				? { ...x, value: !!x.value ? x.value : this.adviceProcessPrefills?.applicationPurpose }
				: x.field === 'QIK10' || x.field === 'QCK4'
				? { ...x, value: !!x.value ? x.value : this.getPreviousProvider() }
				: x.field === 'QIK11' || x.field === 'QCK5'
				? { ...x, value: !!x.value ? x.value : this.getNewProvider() }
				: x.field === 'QIK13' || x.field === 'QCK7'
				? { ...x, value: !!x.value ? x.value : this.getPreviousFund() }
				: x.field === 'QIK14' || x.field === 'QCK8'
				? { ...x, value: !!x.value ? x.value : this.getNewFund() }
				: (x.field === 'QILR2 Date' || x.field === 'QCLR4 Date') &&
					this.adviceProcess.processCode === AdviceProcessCode.LRAdviceReview
				? { ...x, value: !!x.value ? x.value : this.getNextReviewDate() }
				: (x.field === 'QICAR3 Date' || x.field === 'QCCAR6 Date') &&
					this.adviceProcess.processCode === AdviceProcessCode.ClientAlterationRequest
				? { ...x, value: !!x.value ? x.value : this.getNextReviewDate() }
				: (x.field === 'QICAR3 Date' || x.field === 'QCCAR6 Date') &&
					this.adviceProcess.processCode === AdviceProcessCode.ClientAlterationRequest
				? { ...x, value: !!x.value ? x.value : this.getNextReviewDate() }
				: (x.field === 'QCCAR2') &&
					this.adviceProcess.processCode === AdviceProcessCode.ClientAlterationRequest
				? { ...x, value: !!x.value ? x.value : this.getMissingDocumentsAnswers() }
				: x
		);

		this.form = this.fb.group(
			R.mergeAll(
				R.map((x) => {
					const getValue = () => {
						if (x.questionType === qType) {
							const answer = answers?.find(
								(a) => a.field === x.questionKey
							)?.value;
							if (!!answer) {
								if (R.includes(questionTypes.date, x.questionKey)) {
									return [
										MomentUtil.formatDateToMoment(answer),
										[Validators.required, NoWhitespaceValidator],
									];
								} else if (x?.dataType === questionTypes.listOfObjects) {
									return this.fb.array([
										...answer?.map((i) => this.fb.group(i)),
									]);
								} else {
									return [answer, [Validators.required, NoWhitespaceValidator]];
								}
							}
						}
						return [null, [Validators.required, NoWhitespaceValidator]];
					};

					const value = getValue();

					return {
						[strUtil.removeSpace(x.questionKey)]: value,
					};
				}, questions)
			)
		);
	}

	getClientsInvolvedAnswers(answers) {
		const people = this.peopleInvolved?.sort((a, b) =>
			a.display?.localeCompare(b.display)
		);
		const sciList = this.clientProfileQuery.getValue().secondaryClients;
		const pci = this.clientProfileQuery.getValue().primaryClient;
		const isFirstEndProcess = !answers;
		const isVulnerableStatus = (id) => {
			if (+pci.customerID === +id) {
				return pci?.isVulnerable || false;
			} else {
				return (
					sciList?.find((x) => +x?.customerID === +id)?.isVulnerable || false
				);
			}
		};
		const vulnerableNote = (id: number, notesFromAnswer: string) => {
			if (!id) {
				return '';
			}
			if (+pci.customerID === +id) {
				// Primary
				return isFirstEndProcess ? pci?.vulnerableNotes : notesFromAnswer;
			} else {
				// Secondary
				const sciData = sciList?.find((x) => +x?.customerID === +id);
				return isFirstEndProcess ? sciData?.vulnerableNotes : notesFromAnswer;
			}
		};

		return (
			people?.map((x) => {
				const answer = answers?.find((i) => +i?.id === +x?.value);
				// Need to toggle adviser rework here
				let notes = isVulnerableStatus(+x?.value) ? answer?.notes || '' : '';
				if (this.isAdviserReworkOn) {
					notes = isVulnerableStatus(+x?.value)
						? vulnerableNote(+x?.value, answer?.notes)
						: ''
				}
				return {
					id: +x?.value,
					isVulnerable: isVulnerableStatus(+x?.value),
					noteId: answer?.noteId ?? 0,
					notes
				};
			}) || []
		);
	}

	getMissingDocumentsAnswers() {
		const missingDocument = this.missingDocuments;

		return (
			missingDocument?.map((docs) => {
				return {
					documentName: docs.field,
					notes: ''
				};
			}) || []
		);
	}

	getPreviousProvider() {
		if (
			this.adviceProcessPrefills &&
			this.adviceProcessPrefills.length &&
			this.adviceProcessPrefills[0]
		) {
			const providers = this.adviceProcessPrefills[0]
				?.filter((provider) => provider?.currentProvider)
				?.map(
					(provider) =>
						`${provider.customerName} - ${provider?.currentProvider}\r\n`
				)
				?.join('');

			return providers;
		}

		return '';
	}

	getNewProvider() {
		if (
			this.adviceProcessPrefills &&
			this.adviceProcessPrefills.length &&
			this.adviceProcessPrefills[1] &&
			this.adviceProcessPrefills[2]
		) {
			const prefills = [
				...this.adviceProcessPrefills[1]?.sort(
					(a, b) => a?.CustomerId - b?.CustomerId
				),
				...this.adviceProcessPrefills[2]?.sort(
					(a, b) => a?.CustomerId - b?.CustomerId
				),
			];

			const providers = prefills
				?.filter((provider) => provider?.Provider)
				?.map((provider) => {
					const name = this.clientsInvolved?.find(
						(client) => +client?.value === provider?.CustomerId
					);

					return `${name ? name?.display : ''} - ${provider.Provider}\r\n`;
				})
				?.join('');

			return providers;
		}

		return '';
	}

	getPreviousFund() {
		if (
			this.adviceProcessPrefills &&
			this.adviceProcessPrefills.length &&
			this.adviceProcessPrefills[0]
		) {
			const funds = this.adviceProcessPrefills[0]
				?.map(
					(fund) =>
						`${fund?.customerName} - ${JSON.parse(fund?.fundType)?.join(
							', '
						)}\r\n`
				)
				?.join('');

			return funds;
		}

		return '';
	}

	getNewFund() {
		if (
			this.adviceProcessPrefills &&
			this.adviceProcessPrefills.length &&
			this.adviceProcessPrefills[1] &&
			this.adviceProcessPrefills[2]
		) {
			const prefills: any[] = [
				...this.adviceProcessPrefills[1],
				...this.adviceProcessPrefills[2],
			];

			const funds = prefills
				?.map((fund) => {
					const name = this.clientsInvolved?.find(
						(client) => +client?.value === fund?.CustomerId
					);
					const funds = fund?.FundStructure?.filter((fund) => fund?.FundType)
						?.map((fund) => fund?.FundType)
						?.join(', ');

					return `${name ? name?.display : ''} - ${funds}\r\n`;
				})
				.join('');

			return funds;
		}

		return '';
	}

	getNextReviewDate() {
		if(this.service === ServicesCodes.ClientAlterationRequest) {
			return this.primaryClient.lRNextReview;
		}

		if (
			!this.adviceProcess.isOnline &&
			this.adviceProcess.processCode === AdviceProcessCode.LRAdviceReview
		) {
			// TAP1-142 Pull the existing value from Next Review Date L&R Insurance if there is one
			return this.isCompany
				? this.primaryCompany?.lRNextReview
				: this.primaryClient?.lRNextReview;
		}

		if (this.adviceProcessPrefills && this.adviceProcessPrefills[0]) {
			const dt = addMonthsToDateWithFormat(
				new Date(),
				this.adviceProcessPrefills[0]?.nextReviewDateRollOver
			);
			return dt;
		}

		return null;
	}

	getFormControl(formControl: string): AbstractControl {
		return this.form.get(formControl);
	}

	getFormArrayControl(formArrayControl: string) {
		return this.form.get(
			this.generateControl(formArrayControl)
		) as UntypedFormArray;
	}

	toShowOldMortgageQuestions() {
		const status = this.adviceProcess.status;
		const answers = this.answers || [];

		if (
			this.isViewSummary &&
			this.service === ServicesCodes.Mortgage &&
			status > 2 && status < 5
		) {
			if (this.isComplete) {
				const oldQuestionsAnswered = answers
					?.filter((x) => ['QCM1', 'QCM3'].includes(x?.field))
					?.some((x) => !!x?.value);
				const newQuestionsAnswered = answers
					?.filter((x) => ['QCM4', 'QCM6', 'QCM8'].includes(x?.field))
					?.some((x) => !!x?.value);
				return oldQuestionsAnswered && !newQuestionsAnswered;
			} else {
				const oldQuestionsAnswered = answers
					?.filter((x) => ['QIM1', 'QIM2'].includes(x?.field))
					?.some((x) => !!x?.value);
				const newQuestionsAnswered = answers
					?.filter((x) => ['QIM3'].includes(x?.field))
					?.some((x) => !!x?.value);
				return oldQuestionsAnswered && !newQuestionsAnswered;
			}
		}
		return false;
	}

	checkRequiredFields() {
		const f = this.form?.value;
		const form = R.mergeAll(
			R.map(
				(x) => ({
					[x]: typeof f[x] === 'string' ? strUtil.safeTrim(f[x]) : f[x],
				}),
				R.keys(this.form?.value)
			)
		);

		if (this.service === ServicesCodes.LR) {
			if (this.isComplete) {
				if (!form.QCLR5 || !form.QCLR3) {
					return false;
				}
				if (!this.isUploadedAllDocuments && !form.QCLR2Details) {
					return false;
				}
				if (
					this.adviceProcess.processCode === AdviceProcessCode.LRAdviceReview
				) {
					if (
						!form.QCLR4Date ||
						!MomentUtil.formatDateToServerDate(form.QCLR4Date)
					) {
						return false;
					}
				} else {
					if (form?.QCLR4 === 'Yes') {
						if (
							!form.QCLR4Date ||
							!MomentUtil.formatDateToServerDate(form.QCLR4Date)
						) {
							return false;
						}
					} else if (form?.QCLR4 === 'No') {
						if (!form.QCLR4Details) {
							return false;
						}
					}
				}
				if (
					form?.QCLR5 === 'Yes' &&
					(!form.QCLR7Details ||
						!form.QCLR6 ||
						!form.QCLR7 ||
						(!!form.QCLR7 && form.QCLR7.length === 0))
				) {
					return false;
				}
				return true;
			} else {
				if (form?.QILR4 === 'Yes') {
					this.isAnswerCompleted = true;
					const isOther = this.isDropdownOther('QILR6');
					const otherValue = this.otherInputValue('QILR6');
					if (!form.QILR6 || !form.QILR6Details || form.QILR6Details === '') {
						return false;
					}
					if (
						!!form.QILR6 &&
						isOther &&
						R.either(R.isNil, R.isEmpty)(otherValue)
					) {
						return false;
					}
				} else {
					this.isAnswerCompleted = false;
					const isOther = this.isDropdownOther('QILR1');
					const otherValue = this.otherInputValue('QILR1');
					if (
						!!form.QILR1 &&
						isOther &&
						R.either(R.isNil, R.isEmpty)(otherValue)
					) {
						return false;
					}
				}
				if (!form.QILR4 || !form.QILR3) {
					return false;
				}
				if (form.QILR4 === 'Yes' && !form.QILR5) {
					return false;
				}
				if (
					form.QILR4 === 'Yes' &&
					form.QILR5 === 'Yes' &&
					!form.QILR5Details
				) {
					return false;
				}
				if (form.QILR4 === 'No' && (!form.QILR1 || !form.QILR1Details)) {
					return false;
				}
				if (form?.QILR3 === 'No' && !form.QILR3Details) {
					return false;
				}
				if (
					this.adviceProcess.processCode === AdviceProcessCode.LRAdviceNew &&
					(!form?.QILR2 ||
						(form?.QILR2 === 'Yes' &&
							(!form.QILR2Date ||
								!MomentUtil.formatDateToServerDate(form.QILR2Date))))
				) {
					return false;
				}
				if (
					this.adviceProcess.processCode === AdviceProcessCode.LRAdviceNew &&
					(!form?.QILR2 || (form?.QILR2 === 'No' && !form.QILR2Details))
				) {
					return false;
				}

				if (
					this.adviceProcess.processCode === AdviceProcessCode.LRAdviceReview &&
					(!form.QILR2Date ||
						!MomentUtil.formatDateToServerDate(form.QILR2Date))
				) {
					return false;
				}

				return true;
			}
		}
		if (this.service === ServicesCodes.Mortgage) {
			if (this.isComplete) {
				if (
					this.adviceProcess.processCode === AdviceProcessCode.MortgageRefix ||
					this.showOldMortgageQuestions
				) {
					if (!form.QCM1 || !form.QCM3) {
						return false;
					}
					if (form?.QCM1 === 'No' && !form.QCM1Details) {
						return false;
					}
					if (!this.isUploadedAllDocuments && !form.QCM2Details) {
						return false;
					}
				} else {
					if (!this.isUploadedAllDocuments && !form.QCM2Details) {
						return false;
					}
					if (!form.QCM4 || !form.QCM6Details || !form.QCM8) {
						return false;
					}
					if (form.QCM4 === 'Yes' && (!form.QCM4Details || !form.QCM5Details)) {
						return false;
					}
					if (form.QCM8 === 'No' && !form.QCM8Details) {
						return false;
					}
				}
				return true;
			} else {
				if (!form.QIM1 || !form.QIM1Details || !form.QIM2) {
					return false;
				}
				if (form?.QIM2 === 'No' && !form.QIM2Details) {
					return false;
				}
				return true;
			}
		}
		if (this.service === ServicesCodes.KiwiSaver) {
			if (this.isComplete) {
				if (!form.QCK3 || (form.QCK3 === 'Yes' && !form.QCK4)) {
					return false;
				}

				if (!form.QCK3 || (form.QCK3 === 'Yes' && !form.QCK5)) {
					return false;
				}

				if (!form.QCK6 || (form.QCK6 === 'Yes' && !form.QCK7)) {
					return false;
				}

				if (!form.QCK6 || (form.QCK6 === 'Yes' && !form.QCK8)) {
					return false;
				}

				if (!form.QCK10 || (form.QCK10 === 'No' && !form.QCK10Details)) {
					return false;
				}

				return true;
			} else {
				if (
					!this.hasAdviceSummaryPresented &&
					(!form.QIK1 || !form.QIK1Details)
				) {
					return false;
				}

				if (this.hasAdviceSummaryPresented && !form.QIK15) {
					return false;
				}

				if (
					this.hasAdviceSummaryPresented &&
					!this.isUploadedAllDocuments &&
					!form.QIK16Details
				) {
					return false;
				}

				if (!form.QIK2 || (form.QIK2 === 'No' && !form.QIK2Details)) {
					return false;
				}

				return true;
			}
		}
		if (
			this.service === ServicesCodes.BlanketAdvice ||
			this.service === ServicesCodes.FG
		) {
			if (this.isComplete) {
				if (!form.QCB1 || !form.QCB3) {
					return false;
				}
				if (form?.QCB1 === 'No' && !form.QCB1Details) {
					return false;
				}
				if (!this.isUploadedAllDocuments && !form.QCB2Details) {
					return false;
				}
				return true;
			} else {
				if (!form.QIB1 || !form.QIB1Details || !form.QIB2) {
					return false;
				}
				if (form.QIB2 === 'No' && !form.QIB2Details) {
					return false;
				}
				return true;
			}
		}
		if (this.service === ServicesCodes.ComplaintAdvice) {
			if (this.isComplete) {
				if (!form?.QCC1) {
					return false;
				}
				if (form?.QCC1 === 'No' && !form.QCC1Details) {
					return false;
				}
				return true;
			}
		}
		if (this.service === ServicesCodes.Investment) {
			if (this.isComplete) {
				if (!form?.QCI3 || !form?.QCI4 || !form?.QCI5) {
					return false;
				}
				if (!this.isUploadedAllDocuments && !form?.QCI1Details) {
					return false;
				}
				if (form?.QCI5 === 'Yes' && !form.QCI5Date) {
					return false;
				}
				if (form?.QCI5 === 'No' && !form.QCI5Details) {
					return false;
				}
				return true;
			} else {
				if (
					!form?.QII2 ||
					!form?.QII3 ||
					!form?.QII5 ||
					!form?.QII7 ||
					!form?.QII8
				) {
					return false;
				}
				if (!!form?.QII2 && !form.QII2Details) {
					return false;
				}
				if (form?.QII2 === 'Yes' && !form.QII6) {
					return false;
				}
				if (form?.QII2 === 'No' && !form.QII1) {
					return false;
				}
				if (form?.QII3 === 'No' && !form.QII3Details) {
					return false;
				}
				if (form?.QII7 === 'Yes' && !form.QII7Date) {
					return false;
				}
				if (form?.QII7 === 'No' && !form.QII7Details) {
					return false;
				}
				return true;
			}
		}
		if (this.service === ServicesCodes.ClientAlterationRequest) {
			const isNilOrEmpty = (value: any) => R.isNil(value) || R.isEmpty(value);

			if (this.isComplete) {
				const QCCAR6Date = MomentUtil.formatDateToServerDate(form.QCCAR6Date);
				if (
					isNilOrEmpty(form.QCCAR3) ||
					(form.QCCAR3 === 'No' && isNilOrEmpty(form.QCCAR3Details)) ||
					isNilOrEmpty(form.QCCAR4) ||
					(form.QCCAR4 === 'Yes' && isNilOrEmpty(form.QCCAR4Details)) ||
					isNilOrEmpty(form.QCCAR6) ||
					(form.QCCAR6 === 'No' && isNilOrEmpty(form.QCCAR6Details)) ||
					(form.QCCAR6 === 'Yes' && isNilOrEmpty(QCCAR6Date))
				) {
					return false;
				}

				if (this.isMissingDocuments) {
					const isInvalidObject = (obj: any): boolean => {
						return R.anyPass([
							R.isNil,  
							R.isEmpty,
							// Check if notes is null, empty string, or only spaces
							R.propSatisfies(R.either(R.isNil, R.pipe(R.trim, R.isEmpty)), 'notes')  
  						])(obj);
					}

					if (isNilOrEmpty(form.QCCAR2) || !R.isEmpty(form.QCCAR2.filter(isInvalidObject))) {
						return false
					}
				}

				return true;
				
			} else {
				const QICAR3Date = MomentUtil.formatDateToServerDate(form.QICAR3Date);

				if (
					(isNilOrEmpty(form.QICAR1) || form.QICAR1 === 'Other') ||
					isNilOrEmpty(form.QICAR1Details) ||
					isNilOrEmpty(form.QICAR2) ||
					isNilOrEmpty(form.QICAR3) ||
					(form.QICAR3 === 'No' && isNilOrEmpty(form.QICAR3Details)) ||
					(form.QICAR3 === 'Yes' && isNilOrEmpty(QICAR3Date))
				) {
					return false;
				}

				return true;
			}
		}
	}

	generateControl(control: string): string {
		return strUtil.removeSpace(control);
	}

	checkQuestionType(q) {
		if (q?.includes(questionTypes.details)) {
			return questionTypes.details;
		} else if (q?.includes(questionTypes.date)) {
			return questionTypes.date;
		} else {
			return q;
		}
	}

	prepareFormValue() {
		const form = this.form.value;
		const answers = this.answers;
		let data = R.map(
			(x) => ({
				...x,
				value: R.includes(questionTypes.date, x.field)
					? MomentUtil.formatDateToServerDate(
							form[strUtil.removeSpace(x.field)]
					  )
					: form[strUtil.removeSpace(x.field)],
			}),
			answers
		);
		if (this.service === ServicesCodes.LR) {
			if (this.isComplete) {
				// if (data?.find((x) => x.field === 'QCLR1')?.value === 'Yes') {
				// 	data = R.map(
				// 		(x) => (x.field === 'QCLR1 Details' ? { ...x, value: null } : x),
				// 		data
				// 	);
				// }
				if (this.isUploadedAllDocuments) {
					data = R.map(
						(x) => (x.field === 'QCLR2 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QCLR4')?.value === 'No') {
					data = R.map(
						(x) => (x.field === 'QCLR4 Date' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QCLR4')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QCLR4 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QCLR5')?.value === 'No') {
					data = R.map(
						(x) =>
							x.field === 'QCLR7 Details' ||
							x.field === 'QCLR6' ||
							x.field === 'QCLR7'
								? { ...x, value: null }
								: x,
						data
					);
				}
				if (data?.find((x) => x.field === 'QCLR5')?.value === 'Yes') {
					data = R.map(
						(x) =>
							x.field === 'QCLR7'
								? {
										...x,
										value:
											!!x.value && (x.value as string[]).length > 0
												? JSON.stringify(x.value)
												: null,
								  }
								: x,
						data
					);
				}
			} else {
				if (
					data?.find((x) => x.field === 'QILR5')?.value === 'No' ||
					data?.find((x) => x.field === 'QILR4')?.value === 'No'
				) {
					data = R.map(
						(x) => (x.field === 'QILR5 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QILR3')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QILR3 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QILR2')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QILR2 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QILR2')?.value === 'No') {
					data = R.map(
						(x) => (x.field === 'QILR2 Date' ? { ...x, value: null } : x),
						data
					);
				}

				if (data?.find((x) => x.field === 'QILR4')?.value === 'Yes') {
					data = R.map((x) => {
						if (x.field === 'QILR6') {
							const otherSelect = this.otherSelectValue(x.field);
							const otherValue = this.otherInputValue(x.field);

							return this.isDropdownOther('QILR6')
								? { ...x, value: `[${x.field}O]${otherValue}` }
								: { ...x, value: otherSelect };
						} else if (x.field === 'QILR1') {
							return { ...x, value: null };
						}
						return x;
					}, data);
				} else {
					data = R.map((x) => {
						if (x.field === 'QILR1') {
							const otherSelect = this.otherSelectValue(x.field);
							const otherValue = this.otherInputValue(x.field);

							return this.isDropdownOther('QILR1')
								? { ...x, value: `[${x.field}O]${otherValue}` }
								: { ...x, value: otherSelect };
						} else if (['QILR6', 'QILR6 Details'].includes(x.field)) {
							return { ...x, value: null };
						}
						return x;
					}, data);
				}
			}
		}
		if (this.service === ServicesCodes.Mortgage) {
			if (this.isComplete) {
				if (this.adviceProcess.processCode === AdviceProcessCode.MortgageRefix) {
					if (data?.find((x) => x.field === 'QCM1')?.value === 'Yes') {
						data = R.map(
							(x) => (x.field === 'QCM1 Details' ? { ...x, value: null } : x),
							data
						);
					}
					if (this.isUploadedAllDocuments) {
						data = R.map(
							(x) => (x.field === 'QCM2 Details' ? { ...x, value: null } : x),
							data
						);
					}
				} else if (this.adviceProcess.processCode === AdviceProcessCode.MortgageAdvice) {
					if (this.isUploadedAllDocuments) {
						data = R.map(
							(x) => (x.field === 'QCM2 Details' ? { ...x, value: null } : x),
							data
						);
					}
					if (data?.find((x) => x.field === 'QCM4')?.value === 'No') {
						data = R.map(
							(x) => (['QCM4 Details', 'QCM5 Details']?.includes(x?.field) ? { ...x, value: null } : x),
							data
						);
					}
				}
			} else {
				if (data?.find((x) => x.field === 'QIM2')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QIM2 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QIM2')?.value === 'No') {
					data = R.map(
						(x) => (x.field === 'QIM2 Date' ? { ...x, value: null } : x),
						data
					);
				}
			}
		}
		if (this.service === ServicesCodes.KiwiSaver) {
			if (this.isComplete) {
				if (data?.find((x) => x.field === 'QCK1')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QCK1 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (this.isUploadedAllDocuments) {
					data = R.map(
						(x) => (x.field === 'QCK2 Details' ? { ...x, value: null } : x),
						data
					);
				}
			} else {
				// if (data?.find((x) => x.field === 'QIK2')?.value === 'Yes') {
				// 	data = R.map(
				// 		(x) => (x.field === 'QIK2 Details' ? { ...x, value: null } : x),
				// 		data
				// 	);
				// }
				// if (data?.find((x) => x.field === 'QIK2')?.value === 'No') {
				// 	data = R.map(
				// 		(x) => (x.field === 'QIK2 Date' ? { ...x, value: null } : x),
				// 		data
				// 	);
				// }
			}
		}
		if (
			this.service === ServicesCodes.BlanketAdvice ||
			this.service === ServicesCodes.FG
		) {
			if (this.isComplete) {
				if (data?.find((x) => x.field === 'QCH1')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QCB1 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (this.isUploadedAllDocuments) {
					data = R.map(
						(x) => (x.field === 'QCB2 Details' ? { ...x, value: null } : x),
						data
					);
				}
			} else {
				if (data?.find((x) => x.field === 'QIB2')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QIB2 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QIB2')?.value === 'No') {
					data = R.map(
						(x) => (x.field === 'QIB2 Date' ? { ...x, value: null } : x),
						data
					);
				}
			}
		}
		if (this.service === ServicesCodes.ComplaintAdvice) {
			if (this.isComplete) {
				if (data?.find((x) => x.field === 'QCC1')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QCC1 Details' ? { ...x, value: null } : x),
						data
					);
				}
			}
		}
		if (this.service === ServicesCodes.Investment) {
			if (this.isComplete) {
				if (this.isUploadedAllDocuments) {
					data = R.map(
						(x) => (x.field === 'QCI1 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QCI5')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QCI5 Details' ? { ...x, value: null } : x),
						data
					);
				} else if (data?.find((x) => x.field === 'QCI5')?.value === 'No') {
					data = R.map(
						(x) => (x.field === 'QCI5 Date' ? { ...x, value: null } : x),
						data
					);
				}
			} else {
				if (data?.find((x) => x.field === 'QII2')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QII1' ? { ...x, value: null } : x),
						data
					);
				} else if (data?.find((x) => x.field === 'QII2')?.value === 'No') {
					data = R.map(
						(x) => (x.field === 'QII6' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QII3')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QII3 Details' ? { ...x, value: null } : x),
						data
					);
				}
				if (data?.find((x) => x.field === 'QII7')?.value === 'Yes') {
					data = R.map(
						(x) => (x.field === 'QII7 Details' ? { ...x, value: null } : x),
						data
					);
				} else if (data?.find((x) => x.field === 'QII7')?.value === 'No') {
					data = R.map(
						(x) => (x.field === 'QII7 Date' ? { ...x, value: null } : x),
						data
					);
				}
			}
		}
		// Vulnerable Notes
		const vulnerableFields = ['QILR7', 'QCLR8', 'QIM3', 'QCM7', 'QCI2', 'QII4', 'QIK7', 'QCK9', 'QICAR2', 'QCCAR5'];
		if (data?.find((x) => vulnerableFields.includes(x?.field))) {
			data = R.map(
				(x) =>
					vulnerableFields.includes(x?.field)
						? {
								...x,
								value:
									x?.value?.map((i) => ({
										...i,
										notes: !i?.isVulnerable ? '' : i?.notes,
									})) || [],
						  }
						: x,
				data
			);
		}
		if (this.isViewSummary && this.showOldMortgageQuestions) {
			data = data?.filter(
				(x) => !['QIM3', 'QCM4', 'QCM6', 'QCM8']?.includes(x?.field)
			);
		}
		return data;
	}

	save() {
		this.isSaving = true;
		this.saveFn(this.prepareFormValue())
			.pipe(
				tap(
					(x) => {
						this.bsModalRef.hide();
					},
					() => {},
					() => (this.isSaving = true)
				)
			)
			.subscribe();
	}

	// Add activity
	addActivity(questionKey?: string) {
		const formItem = {
			Customer: { CustomerId: this.customerId, Name: this.customerName },
			Location: this.location,
			Duration: 10,
			Adviser: this.adviser,
			ActivityType:
				this.service === ServicesCodes.LR
					? this.isComplete && !R.contains('QCLR4', questionKey || '')
						? 'Exclusion Review'
						: 'EB - Review Reminder'
					: this.service === ServicesCodes.Mortgage
					? 'Mortgage Review'
					: this.service === ServicesCodes.KiwiSaver
					? 'KiwiSaver Review'
					: this.service === ServicesCodes.BlanketAdvice ||
					  this.service === ServicesCodes.FG
					? 'Home, Car and Contents Review'
					: null,
		};
		const initState: any = {
			formItem,
			AT$: this.activityType$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: true,
			savefn: this.saveActivityFn,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: !!this.isLead ? ['FCLA'] : ['FCCA'],
		};
		this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft'
					? 'modal-dialog-outlook-xl'
					: 'modal-xl'
			}`,
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	saveActivityFn = (ac: ActivityViewModel) => {
		return this.addActivityFn$({
			...ac,
			Adviser: +ac.Adviser,
			IsCompleted: !!ac.IsCompleted,
		}).pipe(
			tap(
				() => {
					this.loggerService.Success(
						{},
						logMessage.crm.activity.success.create,
						[]
					);
				},
				() => {},
				() => {}
			)
		);
	};

	onClickEmail() {
		this.isEmailLoading = true;

		const settingsType =
			this.service === ServicesCodes.KiwiSaver
				? this.kiwiSaverQuery.endProcessEmailSettings$
			  : this.adviceProcess.processCode === AdviceProcessCode.MortgageAdvice
				? this.mortgageEmailSettingsService.endProcessEmailSettings$
				: this.adviceProcess.processCode === AdviceProcessCode.ClientAlterationRequest
				? this.carService.getEmailSettings(EmailBodyTypes.CAREndProcess)
				: this.crtSettingsQuery.endProcessEmailSettings$;
		this.ref.detectChanges();

		settingsType
			.pipe(
				tap((x) => (this.endProcessSettings$ = of(x))),
				concatMap(() =>
					this.mtService.getMergeTags('staff', this.adviceProcess.adviser)
				),
				concatMap(() => {
					if (this.adviceProcess.isOnline && this.adviceProcess.isStarted) {
						return this.peopleService
							.getPeople(
								this.adviceProcess.adviceProcessID,
								AdviceProcessSectionCodes.People
							)
							.pipe(
								mergeMap(() =>
									this.peopleService.getDependents(
										this.adviceProcess.adviceProcessID,
										AdviceProcessSectionCodes.Dependants
									)
								),
								tap(() => {
									this.clientsWithCrtId = [];
									const peopleAndDependents = [
										...this.crtQuery.getValue().people,
										...this.crtQuery.getValue().dependents,
									];
									if (peopleAndDependents.length) {
										const getCrtId = (person: PeopleState | DependentState) => {
											this.clientsWithCrtId.push({
												value: person.cRTId.toString(),
												display: person.name,
											});
										};
										R.forEach(getCrtId, peopleAndDependents);
										this.clientsWithCrtId.push({
											value: 'Other',
											display: 'Other',
										});
									}
								}),
								take(1)
							);
					} else {
						this.clientsInvolved = this.getPeopleInvolved();
						this.clientsInvolved.push({
							value: 'Other',
							display: 'Other',
						});
						return of(null);
					}
				}),
				concatMap((x) =>
					this.kiwiSaverEndprocess.getFactFindPeople(
						this.adviceProcess.adviceProcessID,
						'FPP'
					)
				),
				concatMap((x: any) => {
					return iif(
						() => x && x.length,
						of(x),
						this.kiwiSaverEndprocess.addFactFindPeople(
							this.adviceProcess.adviceProcessID,
							'FPP'
						)
					);
				}),
				concatMap((x) =>
					this.kiwiSaverEndprocess.getFactFindPeople(
						this.adviceProcess.adviceProcessID,
						'FPP'
					)
				),
				concatMap((x: any) =>
					this.kiwiSaverEndprocess
						.getFactFindPeople(this.adviceProcess.adviceProcessID, 'FPD')
						.pipe(map((y: any) => [...x, ...y]))
				),
				tap((data) => {
					const people = data?.map((person) => {
						return {
							display: person.Name,
							value: `${person.CRTId}`,
						};
					});

					const isServiceCar = this.service === ServicesCodes.ClientAlterationRequest;

					const initState = {
						header: 'Email Client',
						enableDefaultBcc: true,
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.endProcessSettings$,
						peopleDropdown$: of(people || []),
						attachments: [],
						successMessage: 'End Process has been emailed to',
						adviceProcess: this.adviceProcess,
						businessConfig: this.bsCongifQuery.getValue().config,
						mergeTags$: this.mergeTags$
					};

					if (isServiceCar) {
						this.modalService.show(EmailModalComponent, {
							class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
							initialState: {
								...initState,
								peopleDropdown: of(this.peopleInvolved || []),
								isEmailCAR: isServiceCar
							},
							ignoreBackdropClick: true,
							keyboard: false,
						});
					} else {
						this.modalService.show(CrmEmailModalComponent, {
							class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
							initialState: initState,
							ignoreBackdropClick: true,
							keyboard: false,
						});
					}
				}),
				tap(() => (this.isEmailLoading = false)),
				take(1)
			)
			.subscribe();
	}

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(mergeMap(() => this.clientProfileService.updatePeople(data)));
	};

	getPeopleInvolved() {
		const sciList = this.clientProfileQuery.getValue()?.secondaryClients;
		const pci = this.clientProfileQuery.getValue()?.primaryClient;
		const sciClientsInvolved =
			this.clientsInvolved?.filter(
				(client) =>
					!!sciList?.find((sci) => sci?.customerID === +client?.value) ||
					pci?.customerID === +client?.value
			) || [];
		return [...sciClientsInvolved];
	}

	getPeopleName(id) {
		return id
			? this.peopleInvolved?.find((x) => +x?.value === +id)?.display || ''
			: '';
	}

	get checkCarHasCIVulnerable(): boolean {
		return this.getFormArrayControl('QICAR2').controls
			.some(control => control.value.isVulnerable === true);
	}

	get checkCarCompleteHasCIVulnerable(): boolean {
		return this.getFormArrayControl('QCCAR5').controls
			.some(control => control.value.isVulnerable === true);
	}

	toggleVulnerableNotes(controlName: string, index: number) {
		const form = this.getFormArrayControl(controlName);
		// return form?.controls[index].get('notes').setValue('');
	}

	otherSelectValue(questionKey: string) {
		return (
			this.otherSelectRefs?.find(
				(el) => el?.nativeElement.id === `otherSelect_${questionKey}`
			)?.nativeElement?.value || ''
		);
	}

	otherInputValue(questionKey: string) {
		return (
			this.otherInputRefs?.find(
				(el) => el?.nativeElement.id === `otherInput_${questionKey}`
			)?.nativeElement?.value || ''
		);
	}

	isDropdownOther(questionKey) {
		const otherSelect = this.otherSelectValue(questionKey);
		const value = this.getFormControl(questionKey)?.value || '';

		return (
			otherSelect === 'Other' ||
			value === 'Other' ||
			R.includes(`[${questionKey}O]`, value)
		);
	}

	dropdownOtherValue(questionKey) {
		const value = this.getFormControl(questionKey)?.value || '';
		return R.includes(`[${questionKey}O]`, value) ? 'Other' : value;
	}

	inputOtherValue(questionKey) {
		const value = this.getFormControl(questionKey)?.value || '';
		if (R.includes(`[${questionKey}O]`, value)) {
			return value?.replace(`[${questionKey}O]`, '');
		} else if (value === 'Other') {
			return '';
		} else {
			return value;
		}
	}

	onChangeInputOther(event, questionKey) {
		if (event?.code === 'CapsLock') {
			return;
		}
		if (!event.target.value) {
			this.getFormControl(questionKey).setValue('');
		} else {
			this.getFormControl(questionKey).setValue(
				`[${questionKey}O]${event.target.value}`
			);
		}
	}

	onChangeDropdownOther(event, questionKey) {
		this.getFormControl(questionKey).setValue(event.target.value);
		setTimeout(() => {
			$(`input[id="otherInput_${questionKey}"]`).focus();
		}, 10);
	}

	sendToRecipients = (data) => {
		return this.emailService.sendToRecipients(
			data,
			this.service === ServicesCodes.KiwiSaver
				? AdviceProcessSectionCodes.KiwiSaverEndProcess
			  : this.adviceProcess.processCode === AdviceProcessCode.MortgageAdvice
				? EmailTypes.MortgageEndProcess
				: EmailTypes.EndProcess,
			this.adviceProcess.adviceProcessID,
			this.mergeTags$
		);
	}; // tslint:disable-line
}
