import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownValue } from '../../../../../modules/dropdown/model/dropdownValue';
import { PrimaryClientState } from '../../../../../shared/models/client-profile/primary-client/primary-client.model';
import { PeopleState } from '../../../../../shared/models/client-review-template/people/people.model';
import { SecondaryClientState } from '../../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { IncomeSourceState } from 'src/app/shared/models/client-review-template/income-budget/income-source-details.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { PeopleDetailsState } from '@shared/models/client-review-template/people/people-details.model';

@Component({
	selector: 'app-income-budget-modal',
	templateUrl: './income-budget-modal.component.html',
	styleUrls: ['./income-budget-modal.component.scss'],
})
export class IncomeBudgetModalComponent implements ConfirmModalModel, OnInit {
	savefn: (model: any, index: number) => Observable<any>;
	cancelAddUpdate: (index: number) => Observable<any>;
	confirm$: Observable<any>;
	decline$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	dropdownCodes: DropdownValue[];
	incomeSources$: Observable<IncomeSourceState[]>;

	isLoading: boolean;
	viewMode: boolean;

	public apcrtf$: Observable<ViewDisplayValue[]>;
	public apcrttm$: Observable<ViewDisplayValue[]>;
	public apcrtkc$: Observable<ViewDisplayValue[]>;
	public apcrttc$: Observable<ViewDisplayValue[]>;
	public apcrti$: Observable<ViewDisplayValue[]>;
	public pce$: Observable<ViewDisplayValue[]>;
	public apcrtist$: Observable<ViewDisplayValue[]>;
	public apcrtyn$: Observable<ViewDisplayValue[]>;
	public apcrtynna$: Observable<ViewDisplayValue[]>;
	public apcrtit$: Observable<ViewDisplayValue[]>;
	public primaryClient$: Observable<PrimaryClientState>;
	public secondaryClients$: Observable<SecondaryClientState[]>;
	public peopleFromCRMAndCRTExceptChild$: Observable<SecondaryClientState[]>;
	public people$: Observable<PeopleDetailsState[]>;
	public transferedSCI: PeopleState[];
	public isAdd: boolean;
	public cRTId: number;
	public index: number;
	public adviceProcessId: number;
	public removeFactFindComputation: boolean;
	public showTimeInJob: boolean;

	getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
		this.setupSubs();
	}

	ngOnInit() {}

	setupProps() {
		this.header = 'Confirm';
		this.message = 'Confirm?';
	}
	setupSubs() {}

	continue() {
		this.confirm$.subscribe();
		this.bsModalRef.hide();
	}
	decline() {
		if (!!this.decline$) {
			this.decline$.subscribe();
		}
		this.bsModalRef.hide();
	}

	cancelChange(value) {
		this.bsModalRef.hide();
		this.cancelAddUpdate(+this.index).subscribe();
	}

	save(model) {
		this.isLoading = true;
		this.savefn(model, +this.index).subscribe();
		this.bsModalRef.hide();
		setTimeout(() => this.isLoading = false, 500);
	}
}
export interface ConfirmModalModel {
	confirm$: Observable<any>;
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
