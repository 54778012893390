import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { CommandRoute } from 'src/app/core/config/route.service';
import {
	AdviceProcessCode,
	AdviceProcessDocumentField,
	AdviceProcessDocumentState,
	AdviceProcessPageCodes,
	AdviceProcessRoutes,
	FactFindCodes,
	GetPageCodeForDocumentField,
	MOATDocumentField,
	MOATDocumentsDisplayOffline,
	MOATProcessRoutes,
	RiskAnalysisCodes,
	ServiceAdviceProcessState,
} from '../../models/advice-process/advice-process.model';
import { FinalStructure } from '../../models/client-review-template/final-structure/final-structure.model';

interface DocumentDownloadData {
	documentId: number;
	field: string;
	name: string;
}

interface DocumentUploadData {
	action: string;
	field: string;
}

interface DocumentReplaceData {
	field: string;
	show: boolean;
}

interface DocumentLinkData {}

@Component({
	selector: 'app-advice-process-documents',
	templateUrl: './advice-process-documents.component.html',
})
export class AdviceProcessDocumentsComponent implements OnInit, OnChanges {
	@Input() hasCRT: boolean;
	@Input() hasMOAT: boolean;
	@Input() hasKOAT: boolean;
	@Input() hasLOATV2: boolean;
	@Input() isEditable: boolean;
	@Input() adviceProcess: ServiceAdviceProcessState;
	@Input() hasFinalized: boolean;
	@Input() finalStructure: FinalStructure;
	@Input() crtPage: CommandRoute;
	@Output() doDownload: EventEmitter<{
		documentId: number;
		field: string;
		name: string;
	}> = new EventEmitter<{
		documentId: number;
		field: string;
		name: string;
	}>();
	@Output() doLinkDocument: EventEmitter<{ field: string; tab: string }> =
		new EventEmitter<{ field: string; tab: string }>();
	@Output() doUpload: EventEmitter<{
		action: string;
		field: string;
	}> = new EventEmitter<{
		action: string;
		field: string;
	}>();
	@Output() doReplace: EventEmitter<{
		field: string;
		show: boolean;
	}> = new EventEmitter<{
		field: string;
		show: boolean;
	}>();

	@Output() doCliamAddDocument = new EventEmitter<any>();

	@Output() doRemove: EventEmitter<{
		field: string;
	}> = new EventEmitter<{
		field: string;
		show: boolean;
	}>();
	@Output() doView: EventEmitter<{
		field: string;
	}> = new EventEmitter<{
		field: string;
	}>();

	documents: (AdviceProcessDocumentState & {
		show?: boolean;
		isUploading?: boolean;
		isDeleting?: boolean;
		isDownloading?: boolean;
	})[] = [];
	documentFields = AdviceProcessDocumentField;
	moatDocumentFields = MOATDocumentField;
	processCodes = AdviceProcessCode;

	constructor() {}

	get isLR() {
		switch (this.adviceProcess.processCode) {
			case this.processCodes.LRAdviceNew:
			case this.processCodes.LRAdviceReview:
			case this.processCodes.ClientAlterationRequest:
				return true;
		}
		return false;
	}

	get isMortgage() {
		return (
			this.adviceProcess.processCode === this.processCodes.MortgageAdvice ||
			this.adviceProcess.processCode === this.processCodes.MortgageRefix
		);
	}

	get isKiwiSaver() {
		return this.adviceProcess.processCode === this.processCodes.KiwiSaverAdvice;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.adviceProcess &&
			changes.adviceProcess.currentValue !== undefined
		) {
			this.prepDocuments();
		}
	}

	ngOnInit(): void {
		this.prepDocuments();
	}

	prepDocuments() {
		if (this.adviceProcess.processCode === AdviceProcessCode.MortgageAdvice) {
			this.processMOATDocuments();
		} else {
			this.processLOATDocuments();
		}
	}

	processLOATDocuments() {
		this.documents = this.adviceProcess.documents.map(
			(x: AdviceProcessDocumentState) => {
				if (x.field === AdviceProcessDocumentField.FactFind) {
					return {
						...x,
						show:
							!!this.adviceProcess.pageStarted?.some((page) =>
								FactFindCodes.includes(page)
							) ||
							!!this.adviceProcess.pageStarted?.some((page) =>
								RiskAnalysisCodes.includes(page)
							),
						isUploading: false,
						isDeleting: false,
						isDownloading: false,
					};
				} else if (x.field === AdviceProcessDocumentField.FinalStructure) {
					return {
						...x,
						show:
							!!this.documents.find(
								(doc) =>
									doc.field === AdviceProcessDocumentField.AuthorityToProceed &&
									!!doc.value?.documentID
							) &&
							!!this.adviceProcess.pageCompleted?.some(
								(page) => page === AdviceProcessPageCodes.AuthorityToProceed
							),
						isUploading: false,
						isDeleting: false,
						isDownloading: false,
					};
				} else {
					const field = GetPageCodeForDocumentField(
						x.field,
						this.adviceProcess.processCode
					);
					return this.getDocumentState(field, x);
				}
			}
		);
	}

	processMOATDocuments() {
		this.documents = this.adviceProcess.documents.map(
			(x: AdviceProcessDocumentState) => {
				if (x.field === MOATDocumentField.FactFindDeclaration) {
					return {
						...x,
						show:
							!!this.adviceProcess.pageStarted?.some((page) =>
								FactFindCodes.includes(page)
							) ||
							!!this.adviceProcess.pageStarted?.includes(
								AdviceProcessPageCodes.Declaration
							),
						isUploading: false,
						isDeleting: false,
						isDownloading: false,
					};
				} else if (MOATDocumentsDisplayOffline.includes(x.field)) {
					return {
						...x,
						show: true,
						isUploading: false,
						isDeleting: false,
						isDownloading: false,
					};
				} else {
					const field = GetPageCodeForDocumentField(
						x.field,
						this.adviceProcess.processCode
					);
					return this.getDocumentState(field, x);
				}
			}
		);
	}

	getDocumentState(pageCode: string, document: AdviceProcessDocumentState) {
		return {
			...document,
			show: !!this.adviceProcess.pageStarted?.some((page) => page === pageCode),
			isUploading: false,
			isDeleting: false,
			isDownloading: false,
		};
	}

	trackByDocument(index: number, item: any) {
		return item.field;
	}

	downloadFile(data: DocumentDownloadData) {
		this.doDownload.emit({ ...data });
	}

	goToCrtPage(section?: string) {
		switch (section) {
			case AdviceProcessDocumentField.Declaration:
				return [...this.crtPage, AdviceProcessRoutes.Declaration];
				break;
			case AdviceProcessDocumentField.SOS:
				return [...this.crtPage, AdviceProcessRoutes.SOS];
				break;
			case AdviceProcessDocumentField.FactFind:
				return [...this.crtPage, AdviceProcessRoutes.FactFind];
				break;
			case AdviceProcessDocumentField.SOA:
				{
					if (
						this.adviceProcess.processCode === AdviceProcessCode.LRAdviceNew ||
						this.adviceProcess.processCode === AdviceProcessCode.LRAdviceReview
					) {
						return [...this.crtPage, AdviceProcessRoutes.SOA];
					} else if (
						this.adviceProcess.processCode ===
							AdviceProcessCode.MortgageAdvice ||
						this.adviceProcess.processCode === AdviceProcessCode.MortgageRefix
					) {
						return [...this.crtPage, MOATProcessRoutes.ROA];
					}
				}
				break;
			case AdviceProcessDocumentField.AuthorityToProceed:
				return [...this.crtPage, AdviceProcessRoutes.AuthorityToProceed];
				break;
			case 'Client Acceptance':
				return [...this.crtPage, AdviceProcessRoutes.AuthorityToProceed];
				break;
			case AdviceProcessDocumentField.FinalStructure:
				return [
					...this.crtPage,
					AdviceProcessRoutes.FinalStructure,
					{ sync: !this.finalStructure },
				];
				break;
			case MOATDocumentField.Disclosure:
			case AdviceProcessDocumentField.Disclosure:
				return [...this.crtPage, AdviceProcessRoutes.Disclosure];
				break;
			case MOATDocumentField.FactFindDeclaration:
				return [...this.crtPage, ...MOATProcessRoutes.People.split('/')];
			default:
				return this.crtPage;
				break;
		}
	}

	openUploadModal(data: DocumentUploadData) {
		this.doUpload.emit(data);
	}

	replaceFile(data: DocumentReplaceData) {
		this.doReplace.emit({ ...data });
	}

	linkDocument(field: string, tab: string = 'ap') {
		this.doLinkDocument.emit({field, tab});
	}

	cliamAddDocument(e: any): void {
		this.doCliamAddDocument.emit(e);
	}

	removeFile(field: any): void {
		this.doRemove.emit(field);
	}

	viewFile(field: any): void {
		this.doView.emit(field)
	}


}
