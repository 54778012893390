import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import produce from 'immer';
import { Subject, Subscription, combineLatest, of } from 'rxjs';
import {
	concatMap,
	distinctUntilChanged,
	filter,
	map,
	mergeMap,
	skip,
	skipWhile,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { MOATProcessRoutes } from 'src/app/shared/models/advice-process/advice-process.model';
import MomentUtil from 'src/app/util/moment.util';
import { MortgageAdviceProcessPageIds } from '../../../../shared/models/advice-process/advice-process.model';
import { PrimaryClientState } from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { Sidebar, SidebarStatus } from '../_shared/models/sidebar.model';
import { ReviewApplicationService } from './application/application-steps/review-application/state/review-application.service';
import { ApplicationQuery } from './application/state/application.query';
import { ApplicationService } from './application/state/application.service';
import { AssetsAndLiabilitiesService } from './client-sop/assets-and-liabilities/assets-and-liabilities.service';
import { ExpenseService } from './client-sop/expenses/state/expenses.service';
import { IncomeService } from './client-sop/income/state/income.service';
import { PeopleEntitiesService } from './client-sop/people-entities/state/people-entities.service';
import { DeclarationService } from './declaration/state/declaration.service';
import { DisclosureService } from './disclosure/state/disclosure.service';
import { MoatIntroService } from './intro/state/moat-intro.service';
import { ScopeOfServiceService } from './scope-of-services/state/scope-of-service.service';
import { CrtMortgageQuery } from './state/crt-mortgage.query';
import { CrtMortgageService } from './state/crt-mortgage.service';
import { MortgageAdviceProcessService } from './state/mortgage-adviceprocess/mortgage-advice-process.service';
import { CrtMortgageNoteQuery } from './state/note/crt-mortgage-note.query';
import { CrtMortgageNoteService } from './state/note/crt-mortgage-note.service';
import { StructureSoaService } from './structure-soa/state/structure-soa.service';

declare var $: any;

@Component({
	selector: 'app-crt-mortgage',
	templateUrl: './crt-mortgage.component.html',
	styleUrls: ['./crt-mortgage.component.scss'],
})
export class CrtMortgageComponent
	implements OnInit, AfterContentChecked, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	sidebars: Sidebar[];
	sidebars$ = this.query.sidebars$.pipe(
		distinctUntilChanged(),
		map((x) => this.disableTabs(x)),
		tap((x) => {
			this.sidebars = x;
			this.cd.detectChanges();
		})
	);
	isClientSOPOpen = false;
	clientSOPPath = MOATProcessRoutes.ClientSOP;

	innerWidth: number;
	isToggleSidebar: boolean;
	adviceProcessRoutes = MOATProcessRoutes;

	familyName$ = this.query.primaryClient$.pipe(
		map((x: PrimaryClientState) => x?.groupName || '')
	);

	isShareToCustomerReady$ = this.query.sidebars$.pipe(
		map((x) =>
			x
				.filter(
					(s) =>
						s.id === MortgageAdviceProcessPageIds.Introduction ||
						s.id === MortgageAdviceProcessPageIds.Disclosure ||
						s.id === MortgageAdviceProcessPageIds.ScopeOfService
				)
				.every((s) => s.status === SidebarStatus.Completed)
		)
	);

	hasNoPendingAdviserApproval$ = combineLatest([
		this.alService.properties$.pipe(map((x) => this.hasPendingApproval(x))),
		this.alService.kiwiSavers$.pipe(map((x) => this.hasPendingApproval(x))),
		this.alService.assets$.pipe(map((x) => this.hasPendingApproval(x))),
		this.alService.mortgages$.pipe(map((x) => this.hasPendingApproval(x))),
		this.alService.liabilities$.pipe(map((x) => this.hasPendingApproval(x))),
	]).pipe(map(([p, k, a, m, l]) => [p, k, a, m, l].every((x) => !x)));

	hasCPMOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasMOAT$,
		this.businessConfigQuery.hasCPMOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasMOAT$,
		this.userQuery.isUserHasCPMOAT$,
	]).pipe(
		map(
			([
				hasbusinessAP,
				hasbusinessMOAT,
				hasBusinessCPMOAT,
				hasUserAP,
				hasUserMOAT,
				hasUserCPMOAT,
			]) =>
				hasbusinessAP &&
				hasbusinessMOAT &&
				hasBusinessCPMOAT &&
				hasUserAP &&
				hasUserMOAT &&
				hasUserCPMOAT
		)
	);

	staffId$ = this.service.adviceProcess$.pipe(
		map((x) => {
			return x.adviser;
		})
	);
	appCurrentTab$ = this.service.appCurrentTab$;
	roaCurrentTab$ = this.service.roaCurrentTab$;

	noteSub: Subscription;

	@ViewChild('oatHeader') oatHeader: ElementRef;

	constructor(
		private service: CrtMortgageService,
		private route: ActivatedRoute,
		private router: Router,
		private cd: ChangeDetectorRef,
		private ngZone: NgZone,
		private reviewApplicationService: ReviewApplicationService,
		private query: CrtMortgageQuery,
		private applicationQuery: ApplicationQuery,
		private mApService: MortgageAdviceProcessService,
		private introService: MoatIntroService,
		private ddService: DisclosureService,
		private sosService: ScopeOfServiceService,
		private peopleService: PeopleEntitiesService,
		private alService: AssetsAndLiabilitiesService,
		private incomeService: IncomeService,
		private expenseService: ExpenseService,
		private dcService: DeclarationService,
		private applicationService: ApplicationService,
		private roaService: StructureSoaService,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery,
		private notesService: CrtMortgageNoteService,
		private notesQuery: CrtMortgageNoteQuery
	) {}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
		if (this.innerWidth < 992) {
			this.isToggleSidebar = false;
			$('.crt-main-page').removeClass('open');
		}
	}

	toggleSidebar(tab) {
		this.isToggleSidebar = !this.isToggleSidebar;
		if (this.isToggleSidebar) {
			$('.crt-main-page').addClass('open');
		} else {
			$('.crt-main-page').removeClass('open');
		}
	}

	isClientSOP() {
		return (this.isClientSOPOpen =
			this.clientSOPPath === this.route.snapshot.firstChild.url[0].path);
	}

	ngOnInit(): void {
		this.isClientSOP();
		this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
			if (val instanceof NavigationStart) {
				this.hideSidebar();
			}
			if (val instanceof NavigationEnd) {
				this.isClientSOP();
				this.cd.detectChanges();
			}
		});

		this.sidebars$.pipe(takeUntil(this.onDestroy$)).subscribe();

		combineLatest([this.appCurrentTab$, this.roaCurrentTab$])
			.pipe(
				skip(1),
				tap(() => this.hideSidebar()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		// Init - Sidebar Color Indicator
		this.initSidebarColors();

		this.noteSub = this.notesService
			.getIsNotesCollapse()
			.pipe(
				filter((isCollapse) => !!isCollapse && !this.notesQuery.getHasCache()),
				concatMap(() => this.query.adviceProcessId$),
				filter((adviceProcessId) => Boolean(adviceProcessId)),
				concatMap((adviceProcessId) => {
					return this.notesService
						.getNotes(
							adviceProcessId,
							this.notesQuery.getValue().crtNoteTypes.map((x) => x.value)
						)
						.pipe(takeUntil(this.onDestroy$));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	disableTabs(sidebar: Sidebar[]): Sidebar[] {
		return sidebar?.map((x) => {
			if (x?.id === MortgageAdviceProcessPageIds.Declaration) {
				return {
					...x,
					isDisabled: this.dcService.disableDeclaration(sidebar),
				};
			}
			if (x?.id === MortgageAdviceProcessPageIds.Application) {
				return {
					...x,
					isDisabled: this.applicationService.disableApplication(sidebar),
				};
			}
			if (x?.id === MortgageAdviceProcessPageIds.ROA) {
				return {
					...x,
					isDisabled:
						this.applicationService.disableApplication(sidebar) ||
						this.roaService.disableROA(sidebar),
				};
			}
			return {
				...x,
				isDisabled: false,
			};
		});
	}

	clientSOP() {
		const x = this.query.getValue().sidebars;
		const clientSOP = x.find(
			(m) => m.id === MortgageAdviceProcessPageIds.ClientSOP
		);

		const isCompleted = clientSOP.subMenu.every(
			(sm) => sm.status === SidebarStatus.Completed
		);

		let status = SidebarStatus.Incomplete;

		if (isCompleted) {
			status = SidebarStatus.Completed;
		}

		this.service.setSideSidebarStatus(
			MortgageAdviceProcessPageIds.ClientSOP,
			false,
			status
		);

		if (isCompleted) {
			this.updateAdviceProcess(isCompleted)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe();
		}
	}

	updateAdviceProcess = (isCompleted) => {
		return this.query.adviceProcess$.pipe(
			skipWhile(() => this.query.getValue().mortApIsLoading),
			concatMap((data) => {
				const adviceProcess = Object.assign({}, data);
				const mappedStage = (remove: boolean) =>
					adviceProcess.stages?.map((el) =>
						produce(el, (draft) => {
							if (draft.field === 'Needs Analysis') {
								draft.value = !remove
									? MomentUtil.formatToServerDatetime(
											MomentUtil.createMomentNz()
									  )
									: null;
							}
							return draft;
						})
					);

				const hasDate = adviceProcess.stages?.find(
					(x) => x.field === 'Needs Analysis' && !!x.value
				);
				if (isCompleted && !hasDate) {
					const mortgageAdvice = {
						...adviceProcess,
						stages: [...mappedStage(false)],
						documents: adviceProcess.documents?.map((doc) => {
							return {
								...doc,
								value: doc.value?.documentID,
							};
						}),
					};
					return this.mApService.updateMortgageAdviceProcess(mortgageAdvice);
				} else {
					return of(null);
				}
			})
		);
	};

	initSidebarColors() {
		combineLatest([
			this.introService.setTabColor(),
			this.ddService.setTabColor(),
			this.sosService.setTabColor(),
			this.peopleService.setPeopleTabColor(),
			this.alService.setSidebarColor(),
			this.incomeService.setTabColor(),
			this.expenseService.setTabColor(),
			this.dcService.setTabColor(),
			this.applicationService.setTabColor(),
		])
			.pipe(
				tap(() => this.clientSOP()),
				tap(() => this.cd.detectChanges()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	trackByFn(index: number, item: Sidebar) {
		return item.id;
	}

	ngAfterContentChecked() {
		this.cd.detectChanges();
		const fHeight = $('.crt-main-page__sidebar__header').height();
		const fOuterHeight = $('.crt-main-page__sidebar__header').outerHeight();
		$('app-header').height(fHeight);
		$('[role="main-content"]').css(
			'min-height',
			`calc(100% - ${56 + fOuterHeight}px)`
		);
	}

	triggerNavigate(
		path: string,
		isSub: boolean,
		pageCode: string,
		subpath = null
	) {
		const hasSoaChanges = this.query.getValue().hasSoaChanges;
		const hasActiveSoa = this.applicationQuery.getActive();
		if (hasSoaChanges && hasActiveSoa) {
			const pathForUrl = subpath ?? path;
			this.reviewApplicationService.setTriggerLeaveSoa(pathForUrl);
			return;
		}

		const isInROA = this.query.getValue().roaCurrentTab;
		if (isInROA) {
			const pathForUrl = subpath ?? path;
			this.service.setTriggerLeaveROA(pathForUrl);
			return;
		}

		// this.updatePageStarted(pageCode);

		const urlArr = this.router.url?.split('/') ?? [];
		let end = null;
		if (urlArr.includes(MOATProcessRoutes.Application)) {
			end = urlArr.indexOf(MOATProcessRoutes.Application);
		} else if (urlArr.includes(MOATProcessRoutes.ClientSOP)) {
			end = urlArr.indexOf(MOATProcessRoutes.ClientSOP);
		} else {
			end = urlArr.length - 1;
		}
		const baseUrl = urlArr.slice(1, end)?.join('/');
		const navigateTo = subpath ? `${baseUrl}/${subpath}` : `${baseUrl}/${path}`;
		this.router.navigateByUrl(navigateTo);
	}

	isActive(path: string) {
		if (path) {
			if (path.includes('/')) {
				path = path.split('/').slice(1)[0];
			}
			const getCurrentPath = this.getCurrentPath();
			return path === getCurrentPath;
		}
		return false;
	}

	getCurrentPath() {
		// Check if there's a sub path
		if (!!this.route.snapshot.firstChild.firstChild) {
			if (this.route.snapshot.firstChild.firstChild.url?.length) {
				// Handles Application path. If it has an Application Id, return 'application' only
				if (this.route.snapshot.firstChild.firstChild.params?.applicationId) {
					return this.route.snapshot.firstChild.url[0]?.path;
				}

				// Return the last bit of the Url instead
				const urlEnd = this.route.snapshot.firstChild.firstChild.url.length - 1;

				return this.route.snapshot.firstChild.firstChild.url[urlEnd]?.path;
			} else {
				return this.route.snapshot.firstChild.url[0]?.path;
			}
		} else {
			// Return the last bit of the Url instead
			const urlEnd = this.route.snapshot.firstChild.url?.length - 1;
			return this.route.snapshot.firstChild.url[urlEnd]?.path;
		}
	}

	updatePageStarted(page: string) {
		if (!page || page === '') {
			return;
		}
		this.mApService.updateMortApPageStarted(page).pipe(take(1)).subscribe();
	}

	onActivate(event?) {
		this.ngZone.run(() => {
			setTimeout(() => {
				this.oatHeader?.nativeElement?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}, 100);
		});
	}

	hideSidebar() {
		this.isToggleSidebar = false;
		$('.crt-main-page').removeClass('open');
	}

	hasPendingApproval(x): boolean {
		return x?.some((y) => !!y?.pendingAdviserApproval) || false;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
		this.notesService.setNoteIsCollapsed(false);
		this.noteSub.unsubscribe();
	}
}
