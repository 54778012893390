import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators'
import { DropdownValue } from '../../../../../modules/dropdown/model/dropdownValue';
import { CompanyDetailsState } from '../../../../../shared/models/client-review-template/company/company-details';
import { DependentDetailsState } from '../../../../../shared/models/client-review-template/dependent/dependent-details';
import { MOATPeopleDetailsState } from '../../../../../shared/models/client-review-template/people/people-details.model';
import { TrustDetailsState } from '../../../../../shared/models/client-review-template/trust/trust-details';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';

@Component({
  selector: 'app-people-modal',
  templateUrl: './people-modal.component.html',
  styleUrls: ['./people-modal.component.scss']
})
export class PeopleModalComponent implements ConfirmModalModel, OnInit {
  savefn: (model: any) => Observable<any>;
  decline$: Observable<any>;
  header: string;
  message: string;
  secondaryMessage: string;
  dropdownCodes: DropdownValue[];

  hasCountry: boolean;

  loadingState: boolean;

  public scr$: Observable<ViewDisplayValue[]>;
  public pce$: Observable<ViewDisplayValue[]>;
  public sctt$: Observable<ViewDisplayValue[]>;
  public apcrtvd$: Observable<ViewDisplayValue[]>;
  public apcrtg$: Observable<ViewDisplayValue[]>;
  public apcrtbp$: Observable<ViewDisplayValue[]>;
  public apcrtyn$: Observable<ViewDisplayValue[]>;
  public apcrtynna$: Observable<ViewDisplayValue[]>;

  public apcrttir$: Observable<ViewDisplayValue[]>;
  public apcrtms$: Observable<ViewDisplayValue[]>;
  public pct$: Observable<ViewDisplayValue[]>;

  personInfo: MOATPeopleDetailsState;
  dependentInfo: DependentDetailsState;
  trustInfo: TrustDetailsState;
  companyInfo: CompanyDetailsState;

  constructor(public bsModalRef: BsModalRef) {
    this.setupProps();
    this.setupSubs();
  }

  ngOnInit() {
  }

  setupProps() {
    this.header = 'Confirm';
    this.message = 'Confirm?';
  }
  setupSubs() { }

  save(model) {
    this.loadingState = true;
    this.savefn(model).pipe(
			take(1)
		).subscribe(
			(val) => {
        this.close();
			},
			(err) => {
				this.loadingState = false;
			},
			() => {
        this.close();
			},
		)
  }

	close() {
		this.bsModalRef.hide();
		this.loadingState = false;
	}

  decline() {
    if (!!this.decline$) {
      this.decline$.subscribe();
    }
    this.bsModalRef.hide();
  }

  cancelChange(value) {
    this.bsModalRef.hide();
    this.decline$.subscribe();
  }
}
export interface ConfirmModalModel {
  decline$?: Observable<any>;
  header: string;
  message: string;
  secondaryMessage: string;
}
