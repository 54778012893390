import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { DocumentModelState } from '../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../util/util';
import { ReloadDocumentType } from '../../state/mortgage-settings.model';
import { MortgageSettingsQuery } from '../../state/mortgage-settings.query';
import { MortgageSettingsStore } from '../../state/mortgage-settings.store';
import {
	DocumentUploadState,
	ReviewApplicationSettingsState,
} from './review-application-settings.model';

@Injectable()
export class ReviewApplicationSettingsService {
	reviewApplicationSettings$ = this.query.reviewApplicationSettings$;

	constructor(
		private api: ApiService,
		private store: MortgageSettingsStore,
		private query: MortgageSettingsQuery
	) {}

	clearReviewApplicationSettings(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getReviewApplicationSetting(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<ReviewApplicationSettingsState>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x ? objectUtil.mapPascalCaseToCamelCase(x) : null;
					this.store.setReviewApplicationSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateReviewApplicationSettings(data: ReviewApplicationSettingsState) {
		const endpoint = `crt/settings/${data.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<ReviewApplicationSettingsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setReviewApplicationSettings(x);
				})
			)
		);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getDocumentFile(id: number) {
		return this.api
			.get<DocumentModelState>(`documents/${id}`)
			.pipe(map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)));
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(url, { responseType: 'text' });
	}

	getDefaultTemplateUrl() {
		const endpoint = `crt/oat-template/${ReloadDocumentType.ReviewApplication}`;
		return this.api.get<string>(endpoint);
	}
}
