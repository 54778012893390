import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { CriticalIllnessService } from '../../states/risk-analysis/critical-illness/critical-illness.service';
import { RiskAnalysisService } from '../../states/risk-analysis/risk-analysis.service';

@Component({
	selector: 'app-critical-illness',
	templateUrl: './critical-illness.component.html',
	styleUrls: ['./critical-illness.component.scss'],
})
export class CriticalIllnessComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	APCRTTRC$ = this.service.APCRTTRC$;
	APCRTEC$ = this.service.APCRTEC$;
	criticalIllness$ = this.illnessService.criticalIllness$;
	people$ = this.service.people$.pipe(map((x) => x?.filter((p) => !!p.cRTId)));
	adviceProcessId = this.query.adviceProcessId$;

	isNew = false;
	cRTId: number;
	selectedCrt = this.riskAnalysisService.selectedPerson$;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$

	constructor(
		private service: ClientReviewTemplateService,
		private illnessService: CriticalIllnessService,
		private query: ClientReviewTemplateQuery,
		private riskAnalysisService: RiskAnalysisService
	) {}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
