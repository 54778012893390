import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DropdownResolver } from './resolver/dropdown-resolver.service';
import { DropdownComponent } from './views/dropdown/dropdown.component';

const routes: Routes = [
  {
    path: '',
    resolve: {
      dropdownList: DropdownResolver
    },
    component: DropdownComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [DropdownResolver]
})
export class DropdownRoutingModule { }
