<app-primary-base-widget
	headerText="{{ widgetName }}"
	[widgetCode]="widgetCode"
>
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<div
		class="position-relative clearfix h-100 px-0 scrollable-auto-y scrollable-hidden-x"
	>
		<div class="row mb-4">
			<app-donut-chart
				class="col-12 col-sm-6"
				headerTitle="Current Quarter"
				[part]="quarterActual$ | async"
				strokeColor="primarycolor"
				[total]="quarterExpected$ | async"
				[showCurrencySign]="true"
			>
			</app-donut-chart>

			<app-donut-chart
				class="col-12 col-sm-6"
				headerTitle="Calendar Year"
				[part]="yearActual$ | async"
				strokeColor="secondarycolor"
				[total]="yearExpected$ | async"
				[showCurrencySign]="true"
			>
			</app-donut-chart>
		</div>
		<div class="row justify-content-center">
			<p class="col-auto font-weight-bold mb-0 header-bar-chart">
				Quarterly Progress Tracker
			</p>
			<div class="w-100"></div>
			<app-bar-chart
				class="col"
				[data]="barChartData$ | async"
				[showCurrencySignAxisY]="true"
			></app-bar-chart>
		</div>
	</div>
</app-primary-base-widget>
