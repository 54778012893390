import { Injectable } from '@angular/core';
import { APTState, APTStore } from './apt.store';
import { Query } from '@datorama/akita';

@Injectable()
export class APTQuery extends Query<APTState> {

  isLoading$ = this.select(state => state.isLoading);

  error$ = this.select(state => state.error);

  data$ = this.select(state => state.APTracking);

  constructor(protected store: APTStore) {
    super(store);
  }
}
