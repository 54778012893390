import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, Subject } from 'rxjs';
import { filter, finalize, first, map, mergeMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { LocalService } from 'src/app/core/services/local.service';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '../../../../domain/user/user.query';
import { ActivityModalComponent } from '../../../../shared/modal/activity/activity-modal/activity-modal.component';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../util/moment.util';
import { strUtil } from '../../../../util/util';
import { ActivitySearchRequest } from '../activity-search-request.model';
import { request } from '../activity.util';
import { ActivityRequest, ActivityStatusBool, GetActivityDisplayValue } from '../states/activity.model';
import { ActivityQuery } from '../states/activity.query';
import { ActivityService } from '../states/activity.service';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsStatus, ExportsType } from '@modules/exports/state/exports.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { omit } from 'ramda';
import { ExportsService } from '@modules/exports/state/exports.service';
declare var $: any;

@Component({
	selector: 'app-activity-form-page',
	templateUrl: './activity-form.component.html',
	styleUrls: ['./activity-form.component.scss'],
})
export class ActivityFormComponent implements OnInit, AfterViewInit, OnDestroy {
	private onDestroy$: Subject<void> = new Subject<void>();

	exportStatus$ = this.exportsQuery.status$;

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	@Input() count: number;
	// Min and Max dates
	@Input() additionalParams: any;

	staffChoices$: Observable<ViewDisplayValue[]> = this.blStaffsQuery.adviserChoices$;

	availableStaffChoices$: Observable<ViewDisplayValue[]> = this.blStaffsQuery.availableStaffs$.pipe(
		map(x => {
			const list = x?.map(s => ViewDisplayValue?.Map(s.StaffID?.toString(), `${s.FirstName} ${s.LastName}`));
			return list ? list?.sort((a, b) => a.display?.localeCompare(b.display)) : list;
		})
	);
	activityStatuses = GetActivityDisplayValue();
	activityStatus$: Observable<ViewDisplayValue[]> = of(this.activityStatuses);

	adviserStatus$: Observable<ViewDisplayValue[]> = of([
		{ display: 'Inactive', value: '0' },
		{ display: 'Active', value: '1' },
		{ display: 'Paused', value: '2' },
	]);

	count$ = this.query.count$;

	isSearching$ = this.query.uiQuery.isSearching$;
	isExporting$ = this.query.uiQuery.isExporting$;
	isOpenColumnsSettings$ = this.query.uiQuery.columnFormPopupOpen$;
  adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

	/** Activity Type choices */
	AT$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AT');
	/** Activity Meeting choices */
	AM$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('AM');
	/** Adviser choices */
	adviserChoices$: Observable<ViewDisplayValue[]> = this.blStaffsQuery.allActiveStaffs$;
	/** Adviser calendar choices */
	adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;

	isTapLevel$ = this.userQuery.isTapLevel$;
	userInfo$ = this.userQuery.userInfo$;

	form: UntypedFormGroup = this.fb.group({
		Advisers: this.fb.control([]),
		AltAdvisers: this.fb.control([]),
		AssignedTo: this.fb.control([]),
		CreatedBy: this.fb.control([]),

		ClientFirstName: this.fb.control(''),
		ClientSurname: this.fb.control(''),

		ActivityStatus: this.fb.control([]),
		AdviserStatus: this.fb.control([]),

		ActivityCreateDateMin: this.fb.control(''),
		ActivityCreateDateMax: this.fb.control(''),

		ActivityDueDateMin: this.fb.control(''),
		ActivityDueDateMax: this.fb.control(''),

		ActivityCompleteDateMin: this.fb.control(''),
		ActivityCompleteDateMax: this.fb.control(''),

		ActivityType: this.fb.control([]),
	} as { [key in keyof ActivitySearchRequest]: any });

	@Output() toggleSearch = new EventEmitter<{
		showSearch: boolean;
		height: number;
		width: number;
	}>();
	showSearch = false;

	////////// Progress Bar //////////////
	progress = {
		width: '0%',
	};
	index = 0;
	percent = 0;
	totalCount = 0;
	modalRef: BsModalRef;
	msg = 'Export Progress';
	///////// END Progress Bar ////////

	openColumnPopUp = this.service.togglePopup;

	constructor(
		private fb: UntypedFormBuilder,
		private service: ActivityService,
		private query: ActivityQuery,
		private blStaffsQuery: BLStaffsQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private modalService: BsModalService,
		private renderer: Renderer2,
		private userQuery: UserQuery,
		private activitedRoute: ActivatedRoute,
		private localService: LocalService,
		private exportsQuery: ExportsQuery,
		private exportsService: ExportsService,
    protected businessConfigQuery: BusinessConfigQuery
	) { }
	ngOnInit() {
		this.isTapLevel$
			.pipe(
				filter(x => !x),
				withLatestFrom(this.userInfo$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([, user]) => {
				this.form.get('AssignedTo').setValue([user.StaffID.toString()]);
			});

		this.form.get('ActivityStatus').setValue([ActivityStatusBool.Pending]);
		this.service.sort('DueDate', 'asc');
	}

	ngAfterViewInit() {
		if (this.query.getValue().count > 0 || this.query.getValue().searchForm) {
			const advisers = this.query.getValue().searchForm.Advisers;
			const altAdvisers = this.query.getValue().searchForm.AltAdvisers;
			const at = this.query.getValue().searchForm.AssignedTo;
			const fname = this.query.getValue().searchForm.ClientFirstName;
			const lname = this.query.getValue().searchForm.ClientSurname;
			const cb = this.query.getValue().searchForm.CreatedBy;
			const as = this.query.getValue().searchForm.ActivityStatus;
			const ads = this.query.getValue().searchForm.AdviserStatus;
			const asMapped = as ? Object.keys(as).map(x => { if (as[x] === true) { return x; } }).filter(x => x) : null;

			const acMin = this.query.getValue().searchForm.ActivityCreateDateMin;
			const acMax = this.query.getValue().searchForm.ActivityCreateDateMax;
			const adMin = this.query.getValue().searchForm.ActivityDueDateMin;
			const adMax = this.query.getValue().searchForm.ActivityDueDateMax;
			const acdMin = this.query.getValue().searchForm.ActivityCompleteDateMin;
			const acdMax = this.query.getValue().searchForm.ActivityCompleteDateMax;
			const act = this.query.getValue().searchForm.ActivityType;

			this.form.get('ClientFirstName').setValue(fname);
			this.form.get('ClientSurname').setValue(lname);
			this.form.get('Advisers').setValue(advisers?.map(x => x?.toString()));
			this.form.get('AltAdvisers').setValue(altAdvisers?.map(x => x?.toString()));
			this.form.get('AssignedTo').setValue(at?.map(x => x?.toString()));
			this.form.get('CreatedBy').setValue(cb?.map(x => x?.toString()));
			this.form.get('ActivityStatus').setValue(asMapped);
			this.form.get('AdviserStatus').setValue(ads?.map(x => x?.toString()));
			this.form.get('ActivityType').setValue(act?.map(x => x?.toString()));

			this.form.get('ActivityCreateDateMin').setValue(MomentUtil.formatDateToMoment(acMin));
			this.form.get('ActivityCreateDateMax').setValue(MomentUtil.formatDateToMoment(acMax));
			this.form.get('ActivityDueDateMin').setValue(MomentUtil.formatDateToMoment(adMin));
			this.form.get('ActivityDueDateMax').setValue(MomentUtil.formatDateToMoment(adMax));
			this.form.get('ActivityCompleteDateMin').setValue(MomentUtil.formatDateToMoment(acdMin));
			this.form.get('ActivityCompleteDateMax').setValue(MomentUtil.formatDateToMoment(acdMax));
		}
	}

	public prepareFormValue(): ActivityRequest {
		const formValue: formType = this.form.value;

		const req: ActivityRequest = {
			...formValue,
			Advisers: formValue?.Advisers?.map((x: string): number => +x),
			AltAdvisers: formValue?.AltAdvisers?.map((x: string): number => +x),
			CreatedBy: formValue?.CreatedBy?.map((x: string): number => +x),
			AssignedTo: formValue?.AssignedTo?.map((x: string): number => +x),
			ClientFirstName: strUtil?.safeTrim(formValue.ClientFirstName),
			ClientSurname: strUtil?.safeTrim(formValue.ClientSurname),
			ActivityStatus: {
				IsPending: Array.from(formValue?.ActivityStatus).indexOf(ActivityStatusBool.Pending) !== -1,
				IsCompleted: Array.from(formValue?.ActivityStatus).indexOf(ActivityStatusBool.Completed) !== -1,
				IsCancelled: Array.from(formValue?.ActivityStatus).indexOf(ActivityStatusBool.Cancelled) !== -1,
			},
			AdviserStatus: formValue?.AdviserStatus?.map((x: string): number => +x),
			ActivityCreateDateMin: MomentUtil.formatToServerDate(formValue?.ActivityCreateDateMin),
			ActivityCreateDateMax: MomentUtil.formatToServerDate(formValue?.ActivityCreateDateMax),
			ActivityDueDateMin: MomentUtil.formatToServerDate(formValue?.ActivityDueDateMin),
			ActivityDueDateMax: MomentUtil.formatToServerDate(formValue?.ActivityDueDateMax),
			ActivityCompleteDateMin: MomentUtil.formatToServerDate(formValue?.ActivityCompleteDateMin),
			ActivityCompleteDateMax: MomentUtil.formatToServerDate(formValue?.ActivityCompleteDateMax)
		};

		return req;
	}

	search() {
		const req = this.prepareFormValue();
		this.query.uiQuery.currentSort$
			.pipe(
				first(),
				map(x => {
					req.Paging = {
						Index: 1,
						Column: x.propSort,
						Direction: x.sort,
					};
				}),
				finalize(() => {
					this.service
						.searchActivities(req)
						.pipe(takeUntil(this.onDestroy$))
						.subscribe(() => {
							setTimeout(() => {
								$('datatable-body').scrollTop(1);
								$('datatable-body').scrollLeft(1);
							}, 1);
							setTimeout(() => {
								$('datatable-body').scrollTop(0);
								$('datatable-body').scrollLeft(0);
							}, 1);
						});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	toggle() {
		this.showSearch = !this.showSearch;
		document.body.style.overflowY = 'hidden';
		let x = 0;
		let data;
		const setIntervalHeight = setInterval(() => {
			if (x < 1 && !data) {
				x += 1;
				data = {
					showSearch: this.showSearch,
					height: $('form').height(),
					width: $('form').width(),
				};
			} else if (x > 0) {
				x += 1;
				if (data.height !== $('form').height()) {
					data.height = $('form').height();
				} else {
					this.toggleSearch.emit(data);
					clearInterval(setIntervalHeight);
				}
			}
		}, 100);
	}

	reset() {
		this.form.get('Advisers').reset([]);
		this.form.get('AltAdvisers').reset([]);
		this.form.get('AssignedTo').reset([]);
		this.form.get('CreatedBy').reset([]);

		this.form.get('ActivityStatus').reset([]);
		this.form.get('AdviserStatus').reset([]);

		this.form.get('ClientFirstName').reset('');
		this.form.get('ClientSurname').reset('');

		this.form.get('ActivityCreateDateMin').reset('');
		this.form.get('ActivityCreateDateMax').reset('');

		this.form.get('ActivityDueDateMin').reset('');
		this.form.get('ActivityDueDateMax').reset('');

		this.form.get('ActivityCompleteDateMin').reset('');
		this.form.get('ActivityCompleteDateMax').reset('');
		this.form.get('ActivityType').reset([]);
	}

	/**
	 * setup Activity modal and show.
	 * closes date range control.
	 */
	createActivityModal() {
		const initState: any = {
			formItem: {},
			AT$: this.AT$,
			AM$: this.AM$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: false,
			savefn: this.saveActivityFn,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: ['FCOA'],
		};
		this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${this.localService.getValue('loginType') === 'microsoft' ? 'modal-dialog-outlook-xl' : 'modal-xl'}`,
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
	}

	/**
	 * Save activity
	 */
	saveActivityFn = (ac: ActivityViewModel) =>
		this.service.addActivity(ActivityViewModel.MapToAdd(ac)).pipe(
			tap(() => {
				setTimeout(() => {
					$('datatable-body').scrollTop(1);
					$('datatable-body').scrollLeft(1);
				}, 1);
				setTimeout(() => {
					$('datatable-body').scrollTop(0);
					$('datatable-body').scrollLeft(0);
				}, 1);
			})
		);

	//////////////////////////////////// EXPORT /////////////////////////////////
	export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		this.index++;
		const req = request(
			this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
			this.prepareFormValue(),
			this.index
		);
		const newReq = omit(['Paging', 'ActivityStatus'], {
			...req,
			IsCompleted: req?.ActivityStatus?.IsCompleted,
			IsPending: req?.ActivityStatus?.IsPending,
			IsCancelled: req?.ActivityStatus?.IsCancelled,
		});

		this.exportsService
			.queueExport(newReq, ExportsType.AE)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(
						this.exportsService.getExportStatus(id)
					)
				)
			)
			.subscribe({
				next: (data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport(data.DocumentLink);
						this.hidePopover();
					}
				},
				error: () => {
					this.hidePopover();
				},
			});

		setTimeout(() => {
			this.popover.hide();
		}, 2500);
	}

	downloadExport(url: string) {
		const name = this.activitedRoute.snapshot.paramMap.get('companyCode') + '-Activity.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	//////////////////////////////////// END EXPORT /////////////////////////////////

	showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

type formType = { [key in keyof ActivitySearchRequest]: any };
