import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ApplicationDocumentState, ApplicationDocumentStore } from './documents.store';

@Injectable()
export class ApplicationDocumentQuery extends QueryEntity<ApplicationDocumentState> {
	applicationDocuments$ = this.selectAll();

	constructor(protected store: ApplicationDocumentStore) {
		super(store);
	}
}
