import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { ClientProfileService } from '../states/client-profile.service';

const dropdownCodes: string[] = [
  // primary form
  'PCT', 'PCE', 'PCPC', 'PCLE', 'PCLT', 'PCR', 'LS', 'LT', 'CI', 'SAS'
];

@Injectable()
export class ClientProfileAddResolver implements Resolve<boolean> {
  constructor(
    private dropdownValueService: DropdownValueService,
    private clientService: ClientProfileService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const dropdowns$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);
    this.clientService.clear();
    return forkJoin([dropdowns$]).pipe(mapTo(true));
  }
}
