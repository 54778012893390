<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
    <span class="material-icons">
        close
    </span>
</button>
<div class="modal-header">
    <h1 class="modal-title">
        {{ header }}
    </h1>
</div>
<div class="modal-body">
    <form novalidate [formGroup]="form">
        <div>
            <div class="modal-body text-center">
                <p>{{ message }}</p>
            </div>
            <div class="row tap-container justify-content-center pb-3">
                <span class="custom-dropdown">
                    <select id="adviser" class="form-control w-100" formControlName="adviser">
                        <option value=""></option>
                        <option *ngFor="let d of advisers" [value]="d.StaffID">{{ d.FirstName }}
                            {{ d.LastName }}</option>
                    </select>
                </span>
            </div>
        </div>

        <div class="modal-footer">
            <ng-container *ngIf="!isSaving; else loader">
                <button id="continueButton" type="button" class="tap-btn tap-btn--default mr-2" (click)="continue()">Transfer</button>
                <button id="declineButton" type="button" class="tap-btn tap-btn--default" (click)="decline()">Cancel</button>
            </ng-container>

            <ng-template #loader>
                <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
            </ng-template>
        </div>
    </form>
</div>