import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appOneDigitDecimalPointFiveNumber]',
})
export class OneDigitDecimalPointFiveNumberDirective {
	@Input('decimals') decimals: number = 0;

	private regex: RegExp = new RegExp(
		/^(?!\d*\.(0|1|2|3|4|6|7|8|9)\d*$)\d*\.?\d{0,1}$/
	);
	private specialKeys: Array<string> = [
		'Backspace',
		'Tab',
		'End',
		'Home',
		'ArrowLeft',
		'ArrowRight',
		'Delete',
	];

	constructor(private el: ElementRef) {}

	private check(value: string, decimals: number) {
		return String(value).match(this.regex);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}

		let current: string = this.el.nativeElement.value;
		let position: number = this.el.nativeElement.selectionStart;
		let next: string = [
			current.slice(0, position),
			event.key,
			current.slice(position),
		].join('');

		if (next && !this.check(next, this.decimals)) {
			event.preventDefault();
		}
	}


	@HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    const currentValue: string = this.el.nativeElement.value;

    // Check if the current value ends with a dot
    if (currentValue.endsWith('.')) {
      // Format it properly by adding '0'
      this.el.nativeElement.value = currentValue + '0';
    }
  }
}
