<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.Introduction"></app-header>
</div>

<!-- Content -->
<div role="main-content" class="intro clearfix">
	<ng-container *ngIf="isLoading">
		<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
	</ng-container>

	<div class="intro__content" *ngIf="!isLoading">
		<app-crt-intro [introData$]="intro$"></app-crt-intro>
	</div>
</div>

<!-- Footer -->
<div
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer
		[currentPageId]="pageIds.Introduction"
		[sidebar]="sidebar"
	></app-footer>
</div>
