<div class="row tap-container provider-commission pt-3">
	<div class="col">
		<tabset>
			<tab heading="L&R">
				<app-lr-provider
					[lrp]="lrp"
					[staffId]="staffId"
					[isProviderLoading$]="isProviderLoading$"
				></app-lr-provider>
			</tab>
			<tab heading="Mortgage">
				<app-mortgage-provider
					[staffId]="staffId"
					[isProviderLoading$]="isProviderLoading$"
				></app-mortgage-provider>
			</tab>
			<tab heading="F&G">
				<app-fg-provider
					[staffId]="staffId"
					[isProviderLoading$]="isProviderLoading$"
				></app-fg-provider>
			</tab>
			<tab heading="KiwiSaver">
				<app-kiwisaver-provider
					[staffId]="staffId"
					[isProviderLoading$]="isProviderLoading$"
				></app-kiwisaver-provider>
			</tab>
		</tabset>
	</div>
</div>
