import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RMState, RMStore } from './rm.store';

/**
 * Query Refix mortgage widget data
 */
@Injectable()
export class RMQuery extends Query<RMState> {
  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create LR Goal Track query
   */
  data$ = this.select(state => state.RefixMortgageList);

  /**
   * Create Refix mortgage query
   */
  constructor(protected store: RMStore) {
    super(store);
  }
}
