<div class="soa-mergetag-wording pr-2">
	<form [formGroup]="form" class="form">
		<div class="row pb-0 pl-3 pb-2 pt-4">
			<div class="col-12 col-lg-10">

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>‘How to raise a concern or complaint about my advice’ Section on
							the Things you should know Page</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaRaiseComplaint"
							id="soaRaiseComplaint"
							formControlName="soaRaiseComplaint"
							cols="30"
							rows="6"
						></textarea>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold"
							>Things to Consider</label
						>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control"
							name="soaThingsToConsider"
							id="soaThingsToConsider"
							formControlName="soaThingsToConsider"
							cols="30"
							rows="15"
						></textarea>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-12 soa-mergetag-wording__labels">
						<label class="label-font font-weight-bold">The Next Step</label>
					</div>
					<div class="col-12 soa-mergetag-wording__textbox">
						<textarea
							class="form-control large-text-"
							name="soaTheNextStep"
							id="soaTheNextStep"
							formControlName="soaTheNextStep"
							cols="30"
							rows="15"
						></textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 mb-4">
			<div class="col-12 col-lg-10 text-right">
				<app-manual-loader-mini
					[isLoading]="isLoading"
				></app-manual-loader-mini>
				<button
					type="button"
					class="btn btn-primary my-2 ml-1 tap-bg-primary"
					(click)="save()"
					*ngIf="!isLoading"
				>
					SAVE
				</button>
			</div>
		</div>
	</form>
</div>
