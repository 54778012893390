export enum SettingsTypes {
	// LOAT
	AuthorityToProceed = 'CA',
	Disclosure = 'DD',
	Introduction = 'IP',
	SOA = 'SOA',
	DocumentList = 'MGDL',
	DocumentListSettings = 'MOATADL',

	// MOAT
	Declaration = 'MOATD',
	ReviewApplication = 'MOATRA',
	MOATDeclarationEmail = 'MOATDES',
	MOATClientFactFind = 'MOATCFF',
	MOATReviewApplicationEmail = 'MOATRAES',
	MOATSosTemplate = 'MOATSOSP',
	MOATRoaEmail = 'MOATROAES',
	SOS = 'MOATSOS',
	MOATROA = 'MOATROA',
	MOATDefaultTemplate = 'MROA',
	MOATReviewNotes = 'MOATRANS',
	MOATCPDocuments = 'MOATCPD',
	MOATCPCustomDocuments = 'CPCD',
}

export enum DocumentSettingsTypes {
	// MOAT
	ReviewApplication = 'CRTMOATRA',
	SosTemplate = 'MOATSOSP',
}

export enum ReloadDocumentType {
	// MOAT
	ReviewApplication = 'MARA',
	SosTemplate = 'MOATSOSP',
	ClientFactFind = 'MOATCFF',
}
