import { Injectable } from '@angular/core';
import { SpecialFeaturesSettingsStore } from '../state/special-features-settings.store';
import { SpecialFeaturesSettingsQuery } from '../state/special-features-settings.query';
import { ApiService } from '@core/base/api.service';
import { Observable, catchError, map, mergeMap, of, take, tap } from 'rxjs';
import { applyTransaction } from '@datorama/akita';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { SpecialFeaturesSettingsModel } from './special-features-settings.model';
import { BusinessConfigService } from '../../../domain/business-config/business-config.service';

@Injectable({
	providedIn: 'root',
})
export class SpecialFeaturesSettingsService {
	specialFeatureList$: Observable<string[]> = this.query.select().pipe(
		map((sf) => {
			return sf.specialFeaturesSettings;
		})
	);

	constructor(
		private api: ApiService,
		private store: SpecialFeaturesSettingsStore,
		private query: SpecialFeaturesSettingsQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private businessConfigService: BusinessConfigService
	) {}

	getSpecialFeatures() {
		const companyCode =
			this.businessConfigQuery.getValue().config?.BusinessCode;
		const endpoint = `businesses/${companyCode}/features`;

		return this.api.get<string[]>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					this.store.setSpecialFeaturesSettings(Object.values(data));
				})
			),
			catchError(() => of(undefined))
		);
	}

	setSpecialFeaturesActiveStatus(
		specialFeaturesSettings: SpecialFeaturesSettingsModel
	) {
		const companyCode =
			this.businessConfigQuery.getValue().config?.BusinessCode;
		const endpoint = `businesses/${companyCode}/features/toggle`;
		const body = specialFeaturesSettings;

		return this.api.put<any>(endpoint, body).pipe(
			tap(() => {
				applyTransaction(() => {
					this.store.setSpecialFeaturesSettings(body.SpecialFeature);
				});
			}),
			tap(() => this.businessConfigService.get(true).pipe(take(1)).subscribe()),
			catchError((err) => of(err))
		);
	}
}
