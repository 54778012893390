import { Component, Input } from '@angular/core';
import { MortgageEndProcessEmailSettingsState } from '../state/mortgage-end-process.model';
import {
	Observable,
	catchError,
	combineLatest,
	concatMap,
	filter,
	finalize,
	iif,
	map,
	mergeMap,
	of,
	take,
	tap,
} from 'rxjs';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { MortgageEndProcessSettingsService } from '../state/mortgage-end-process.service';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MortgageEndProcessEmailSettingsMapper } from '../state/mortgage-end-process.mapper';
import { isNil } from 'ramda';
import { convertUtil, objectUtil } from '@util/util';

@Component({
	selector: 'app-mortgage-end-process-email-settings',
	templateUrl: './mortgage-end-process-email-settings.component.html'
})
export class MortgageEndProcessEmailSettingsComponent {
	@Input() staffId: number;
	@Input() emailSettings$: Observable<MortgageEndProcessEmailSettingsState>;
	@Input() mergeTags$: Observable<MergeTagState[]>;

	isLoading = false;
	bodyContent = '<p></p>';
	bodyId: number;
	shortCodes: object;
	emailSettings: MortgageEndProcessEmailSettingsState;

	constructor(private endProcessService: MortgageEndProcessSettingsService) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		combineLatest([this.emailSettings$, this.mergeTags$])
			.pipe(
				filter(([data, mt]) => !!data && !!mt),
				tap(([data, mt]) => {
					this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt);
					this.emailSettings = data;
				}),
				tap(([data]) => (this.bodyId = +data?.body || 0)),
				map(([data]) =>
					MortgageEndProcessEmailSettingsMapper.mapEmailSettingsData(data)
				),
				mergeMap((data) =>
					iif(
						() => isNil(data?.body),
						of(null),
						this.endProcessService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.endProcessService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save(emailSettings) {
		this.isLoading = true;
		of(emailSettings)
			.pipe(
				mergeMap((data) => convertUtil.convertToBase64(data?.bodyContent)),
				map((body) =>
					MortgageEndProcessEmailSettingsMapper.mapStaffEmailBody(
						body,
						+this.bodyId,
						0
					)
				),
				concatMap((data) =>
					iif(
						() => isNil(data.documentId),
						this.endProcessService
							.newFileUpload(data)
							.pipe(catchError((err) => of(err))),
						this.endProcessService.updateFileUpload(data)
					)
				),
				concatMap((data) => {
					const isNewBody = this.bodyId === 0 || !this.bodyId;
					const newBodyId = isNewBody ? data : +this.bodyId;
					const formValue =
						MortgageEndProcessEmailSettingsMapper.mapEmailSettingsToUpsert({
							...emailSettings,
							referenceId: 0,
							body: newBodyId,
						});
					this.bodyId = newBodyId;
					return this.endProcessService.update(formValue);
				}),
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe();
	}
}
