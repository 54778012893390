<form [formGroup]="form" novalidate class="filter-form">
	<div class="clearfix">
		<div class="row d-flex justify-content-between">
			<div class="col-md-12 col-lg-auto align-self-center mb-3 mb-lg-0">
				<h1 class="d-inline tap-text-secondary tap-text-h2">
					Advice Process Pipeline
				</h1>
				<h6
					class="
						mb-0
						px-0 px-md-2
						d-inline
						tap-text-secondary
						d-block d-md-inline-block
					"
				>
					{{ count$ | async | number }} Results
				</h6>
			</div>

			<div class="col-md-12 col-lg-auto align-self-center ml-auto">
				<div class="form-row align-items-center justify-content-end">
					<div class="col-auto">
						<button
							id="toggleFilterBtn"
							type="button"
							title="Filter"
							class="icon-btn font-weight-bold"
						>
							<i
								class="material-icons md-20 tap-text-color-h6 pt-1"
								(click)="collapse.Toggle(); toggle()"
							>
								filter_list
							</i>
						</button>
					</div>
					<div class="col-auto">
						<button id="resetFilterBtn" type="button" class="icon-btn" (click)="reset()">
							<i class="fas fa-undo fa-14 tap-text-color-h6 pt-1"></i>
						</button>
					</div>
					<ng-container *appRestrictTo="['FSP']">
						<div class="col-auto">
							<button
								id="searchBtn"
								type="submit"
								class="tap-btn tap-btn--default font-weight-bold px-lg-4"
								(click)="search()"
								[disabled]="isSearching$ | async"
							>
								Search
							</button>
						</div>
					</ng-container>
					<ng-container *appRestrictTo="['FEP']">
						<div
							class="col-auto"
							(mouseenter)="showPopover()"
							[placement]="'bottom'"
							[popover]="(exportStatus$ | async) === 3 ? popTemplate : null"
							containerClass="popover-info-container"
							triggers=""
						>
							<button
								id="exportBtn"
								type="button"
								title="Export"
								class="icon-btn"
								(click)="export();"
								[disabled]="(isExporting$ | async) || (exportStatus$ | async) === 3"
								data-toggle="modal"
								data-target="#apExportModal"
							>
								<i class="fas fa-download fa-14 tap-text-color-h6 pt-1"></i>
							</button>
						</div>
					</ng-container>
					<div class="col-auto">
						<app-manual-loader-mini
							[isLoading]="(isSearching$ | async) || (isExporting$ | async)"
						>
						</app-manual-loader-mini>
					</div>
					<div class="col-auto">
						<span class="position-relative d-inline-block">
							<app-advice-process-columns-form
								*ngIf="columnFromOpen$ | async"
								class="search-settings"
								[claimsFeature]="claimsFeature"
							>
							</app-advice-process-columns-form>
							<button
								id="settingsBtn"
								type="button"
								title="Settings"
								class="icon-btn"
								(click)="openColumnPopup()"
							>
								<i class="material-icons md-20 column-settings-icon pt-1">
									settings
								</i>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		app-collapse
		#collapse
		[initialExpandedState]="false"
		class="search-collapse"
	>
		<div class="container-fluid ml-0 px-0 pt-3">
			<div class="row">
				<div class="col-12 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="advisersId"
								formControlName="Advisers"
								[choices]="availableStaffChoices$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Reviewed By {{claimsFeature?'/ Claim Manager':''}}</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="reviewedById"
								formControlName="ReviewedBy"
								[choices]="reviewedBy$ | async"
							></app-chips>
						</div>
					</div>

					<div
						class="row align-items-center mb-1"
						*featureToggle="{
							id: 'AdviserRework',
							show: false
						}"
					>
						<div class="col-12">
							<label for="">Alt Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="AltAdvisersId"
								formControlName="AltAdvisers"
								[choices]="availableStaffChoices$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6">
					<div class="row align-items-center">
						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Advice Process</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="adviceProcessId"
										formControlName="AdviceProcesses"
										[choices]="filteredUserTypes"
									></app-chips>
								</div>
							</div>

							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Advice Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="adviceStatusId"
										formControlName="AdviceStatuses"
										[choices]="adviceStatuses$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Advice Documents</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="adviceDocumentsId"
										formControlName="AdviceDocuments"
										[choices]="adviceDocuments$ | async"
									></app-chips>
								</div>
							</div>

							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Contact Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="contactStatusId"
										formControlName="ContactStatuses"
										[choices]="contactStatusChoices$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Origin</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadOriginsId"
										formControlName="LeadOrigins"
										[choices]="LeadOriginChoices$ | async"
									></app-chips>
								</div>
							</div>

							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Type</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadTypesId"
										formControlName="LeadTypes"
										[choices]="LeadTypeChoices$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="AdviceStages">Advice Stages</label>
								</div>
								<div class="col-12">
									<app-chips
										id="AdviceStages"
										formControlName="AdviceStages"
										[choices]="AdviceStagesChoices"
									>
                  </app-chips>
								</div>
							</div>
            </div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="AdviceOnlineOffline">Online/Offline</label>
								</div>
								<div class="col-12">
									<app-chips
										id="AdviceOnlineOffline"
										formControlName="AdviceOnlineOffline"
										[choices]="OfflineOnlineChoice"
									>
                  </app-chips>
								</div>
							</div>
            </div>
					</div>
				</div>

				<div class="col-12 col-lg-3 d-flex flex-column justify-content-between">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Advice Start Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="adviceStartDateMinId"
									class="col"
									formControlName="AdviceStartDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-1">to</div>
								<app-date-input
									textboxId="adviceStartDateMaxId"
									class="col"
									formControlName="AdviceStartDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Advice End Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="adviceEndDateMinId"
									class="col"
									formControlName="AdviceEndDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-1">to</div>
								<app-date-input
									textboxId="adviceEndDateMaxId"
									class="col"
									formControlName="AdviceEndDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Activity Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="NextActivityDateMinId"
									class="col"
									formControlName="NextActivityDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-1">to</div>
								<app-date-input
									textboxId="NextActivityDateMaxId"
									class="col"
									formControlName="NextActivityDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!-- Modal -->
<ng-template #exportTemplate>
	<app-export-progress
		[progress]="progress"
		[percent]="percent"
		[msg]="msg"
	></app-export-progress>
</ng-template>
<!-- End Modal -->

<ng-template #popTemplate>
	<div class="popover-info">
		<p>
			Export currently in progress, please wait until its completion
		</p>
	</div>
</ng-template>
