import { Injectable } from '@angular/core';
import { ApiService } from '../../core/base/api.service';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class TlReportService {
	constructor(
		private api: ApiService) { }

	export(req: {
		ReportType: string;
		DateMin?: string;
		DateMax?: string;
		PageIndex?: number;
	}) {
		if (req.ReportType === 'ProductionReport') {
			return of(req).pipe(
				mergeMap(r => this.api.postFileDownload(`export/production-reports/br`, r)),
				catchError(err => {
					return throwError(err);
				})
			);
		} else if (req.ReportType === 'ProductionReportByAdviser') {
			return of(req).pipe(
				mergeMap(r => this.api.postFileDownload(`export/production-reports/a`, r)),
				catchError(err => {
					return throwError(err);
				})
			);
		} else if (req.ReportType === 'BookBreakdown') {
			return of(req).pipe(
				mergeMap(r => this.api.postFileDownload(`export/production-reports/bsr`)),
				catchError(err => {
					return throwError(err);
				})
			);
		}
	}
}
