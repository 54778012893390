import { TransferModule } from './../../transfer/transfer.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { LeadSearchRoutingModule } from './lead-search-routing.module';
import { LeadSearchPageComponent } from './lead-search-page/lead-search-page.component';
import { LeadSearchFormComponent } from './lead-search-form/lead-search-form.component';
import { LeadSearchColumnsFormComponent } from './lead-search-columns-form/lead-search-columns-form.component';

import { LeadSearchUiStore } from './state/lead-search-ui.store';
import { LeadSearchUiQuery } from './state/lead-search-ui.query';
import { LeadSearchResolver } from './lead-search.resolver';
import { LeadSearchStore } from './state/lead-search.store';
import { LeadSearchQuery } from './state/lead-search.query';
import { LeadSearchService } from './state/lead-search.service';
import { LeadBulkTransferComponent } from './lead-bulk-transfer/lead-bulk-transfer.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TransferModule,
    LeadSearchRoutingModule
  ],
  declarations: [LeadSearchPageComponent, LeadSearchFormComponent, LeadSearchColumnsFormComponent, LeadBulkTransferComponent],
  providers: [
    LeadSearchUiStore,
    LeadSearchUiQuery,

    LeadSearchResolver,
    LeadSearchStore,
    LeadSearchQuery,
    LeadSearchService
  ]
})
export class LeadSearchModule { }
