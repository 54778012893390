import { Component, OnInit } from '@angular/core';
import { environment as env } from '@environment';
import { ActivatedRoute } from '@angular/router';
import { LocalService } from 'src/app/core/services/local.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
	logo = env.defaultLoginLogo;
	returnUrl: string;
	companyCode: string;
	isBranded: boolean;
	loginPage: any[];
	isGoToLoginBtn = true;

	constructor(
		private authService: AuthenticationService,
		private localService: LocalService,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.companyCode = this.route.snapshot.paramMap.get('companyCode') || this.localService.getValue('code');
		// Checks if it will have a 'Go to login' button
		if (this.authService.isLoggedIn) {
			if (!this.authService.user.IsTL) {
				this.isGoToLoginBtn = false;
			}
		}

		this.loginPage = this.companyCode ? ['/', this.companyCode] : ['/login'];
	}
}
