import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { FGGTDStore } from './fggtd.store';
import { FgGoalTrackDomesticState } from './fggtd.model';
import { applyTransaction } from '@datorama/akita';

/**
 * F&G Goal Track Domestic service for fetching
 */
@Injectable()
export class FGGTDService {
	/**
	 * partial url endpoint
	 */
	private readonly endpoint = 'widgets?type=FGGTD';

	constructor(private api: ApiService, private store: FGGTDStore) {}

	/**
	 * Fetch and store Refix data
	 *
	 * @param adviserIdList
	 * Search widget data related to theses advisers
	 */
	public GetFgGoalTrackDomestic(adviserIdList: string[]): void {
		this.store.setLoading(true);
		this.api
			.post<FgGoalTrackDomesticState>(this.endpoint, {
				Advisers: adviserIdList,
			})
			.subscribe(
				(res) => this.store.update({ GoalTrack: res }),
				(err) =>
					applyTransaction(() => {
						this.store.setError('Error fetching data!');
						this.store.setLoading(false);
					}),
				() => this.store.setLoading(false)
			);
	}
}
