import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { Service } from './service.model';
import { ServicesQuery } from './services.query';
import { ServicesStore } from './services.store';

@Injectable()
export class ServicesService {
	constructor(
		private servicesStore: ServicesStore,
		private servicesQuery: ServicesQuery
	) {}

	addList(): Observable<Service[]> {
		const sq = this.servicesQuery.getAll();

		if (sq.length > 0) {
			return of(sq);
		}

		return of([
			{ ServiceID: 1, Service: 'L&R Insurance', ServiceCode: ServicesCodes.LR },
			{
				ServiceID: 2,
				Service: 'Mortgage',
				ServiceCode: ServicesCodes.Mortgage,
			},
			{ ServiceID: 3, Service: 'F&G Insurance', ServiceCode: ServicesCodes.FG },
			{
				ServiceID: 4,
				Service: 'Kiwisaver',
				ServiceCode: ServicesCodes.KiwiSaver,
			},
			{
				ServiceID: 5,
				Service: 'Investment',
				ServiceCode: ServicesCodes.Investment,
			},
			{
				ServiceID: 6,
				Service: 'Advice Process',
				ServiceCode: ServicesCodes.AdviceProcess,
			},
			{
				ServiceID: 7,
				Service: 'L&R Online Advice Tool',
				ServiceCode: ServicesCodes.ClientReviewTemplate,
			},
			{
				ServiceID: 8,
				Service: 'Mortgage Online Advice Tool',
				ServiceCode: ServicesCodes.MortgageOnlineAdviceTool,
			},
			{
				ServiceID: 11,
				Service: 'KiwiSaver Online Advice Tool',
				ServiceCode: ServicesCodes.KiwisaverOnlineAdviceTool,
			},
			{
				ServiceID: 9,
				Service: 'Client Portal',
				ServiceCode: ServicesCodes.CustomerPortalMOAT,
			},
			{
				ServiceID: 10,
				Service: 'Client Portal Documents',
				ServiceCode: ServicesCodes.CustomerPortalMOATDocuments,
			},
			{
				ServiceID: 12,
				Service: 'Client Alteration Request',
				ServiceCode: ServicesCodes.ClientAlterationRequest,
			}
		]).pipe(tap((x) => this.servicesStore.add(x)));
	}
}
