<ng-container *appRestrictTo="['FEC', 'FAC', 'FAL']">
  <ng-container [ngSwitch]="uploadType">
    <ng-container *ngSwitchCase="'default'">
      <ng-container *ngIf="!tempImage && !isViewOnly">
        <input
          name="photofile"
          type="file"
          id="photofile"
          class="photo-file p-0"
          [accept]="'image/x-png,image/jpeg,image/png'"
          (change)="onFileChange($event)"
        />
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'modal'">
      <ng-container *appRestrictTo="['FEC', 'FAC', 'FAL']">
        <ng-container *ngIf="!tempImage && !isViewOnly">
          <button type="button" class="photo-btn-modal" (click)="openUploadModal()"></button>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<!-- Thumbnail wrapper -->
<ng-container *ngIf="(!!customThumbnail); else defaultThumbnail">
	<div class="custom-thumbnail position-relative h-100 d-flex align-items-center justify-content-center">
		<ng-container *ngIf="!tempImage && !isViewOnly; else hasPhoto">
			<div class="px-1 text-center">
				<i class="custom-thumbnail__icon far fa-images fa-flip-horizontal mb-2"></i> <br />
				<span class="custom-thumbnail__text">{{ customThumbnailText || 'Maximum upload size is 25MB.'}}</span>
			</div>
		</ng-container>
	</div>
</ng-container>
<ng-template #defaultThumbnail>
	<div class="position-relative h-100 d-flex align-items-center justify-content-center">
		<ng-container *ngIf="!tempImage && !isViewOnly; else hasPhoto">
			<div class="px-1 text-center">
				Upload Photo <br />
				<small class="text-muted max-text">Maximum upload size is 25MB. {{additionalInfo}}</small>
			</div>
		</ng-container>
	</div>
</ng-template>

<!-- Edit and Delete Buttons -->
<ng-container *ngIf="!!tempImage && !isViewOnly">
  <div class="photo-btn align-items-center justify-content-center">
    <ng-container *appRestrictTo="['FEC', 'FAC', 'FAL']">
      <ng-container [ngSwitch]="uploadType">
        <ng-container *ngSwitchCase="'default'">
          <button class="icon-btn mr-4 position-relative clickable w-auto h-auto p-1" type="button" title="Upload File">
            <input
              name="btnfile"
              type="file"
              id="btnfile"
              class="photo-file p-0 clickable"
              [accept]="'image/x-png,image/jpeg,image/png'"
              (change)="onFileChange($event)"
            />
            <span class="material-icons md-20">edit</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'modal'">
          <button
            class="icon-btn mr-4 position-relative clickable w-auto h-auto p-1"
            type="button"
            title="Upload File"
            (click)="openUploadModal()"
          >
            <span class="material-icons md-20">edit</span>
          </button>
        </ng-container>
      </ng-container>

      <button class="icon-btn clickable w-auto h-auto p-1" type="button" title="Delete" (click)="deletePhoto()">
        <span class="material-icons md-20">delete</span>
      </button>
    </ng-container>
  </div>
</ng-container>

<!-- Thumbnail -->
<ng-template #hasPhoto>
  <app-manual-loader [isLoading]="isLoadingThumbnail || isDeleting"></app-manual-loader>
  <img
    [src]="tempImage"
    *ngIf="!!tempImage"
    class="img-fluid photo-thumbnail"
    (click)="viewPhoto()"
    (load)="isLoadingThumbnail = false"
    (error)="isLoadingThumbnail = false"
  />
</ng-template>

<!-- Photo view - it's like lightbox -->
<ng-container *ngIf="isViewPhoto">
  <div class="photo-view d-flex justify-content-center align-items-center">
    <div class="photo-view__overlay" (click)="viewPhoto()"></div>
    <div class="photo-view__image">
      <app-manual-loader [isLoading]="isLoadingView"></app-manual-loader>
      <button *ngIf="!isLoadingView" class="tap-btn tap-btn-outline photo-view__close" (click)="viewPhoto()">
        <span class="material-icons">close</span>
      </button>
      <img [src]="tempImage" (load)="isLoadingView = false" (error)="isLoadingView = false" />
    </div>
  </div>
</ng-container>
