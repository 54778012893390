<div class="view-soa">
	<div
		class="view-soa__editor mb-2"
		[class.wysiwyg-loading]="isLoading$ | async"
	>
		<app-wysiwyg
			#contentEditor
			[content]="currentTemplate"
			[hasSidebarSections]="false"
			[isTapLevel]="isTapLevel"
			[hideMergeTagButtons]="true"
		></app-wysiwyg>
	</div>
	<div
		class="view-soa__loading position-absolute mb-3 ml-2"
		*ngIf="!(isSavingSOAReview$ | async)"
	>
		<button
			id="resetMergeTagsBtn"
			class="tap-btn tap-btn-outline tap-btn--shadow px-4"
			(click)="resetAllMergeTags()"
			[disabled]="
				(isSavingSOA$ | async) ||
				(isSavingSOAReview$ | async) ||
				(isLoading$ | async)
			"
		>
			Reset Merge Tags
		</button>
		<button
			id="combineBtn"
			class="tap-btn tap-btn-outline tap-btn--shadow px-4 ml-2"
			(click)="combineDocuments()"
			[disabled]="
				(isSavingSOA$ | async) ||
				(isSavingSOAReview$ | async) ||
				(isLoading$ | async) ||
				(combineDocuments$ | async)?.length === 0
			"
		>
			Combine
		</button>
		<app-manual-loader-mini
			[isLoading]="(isLoading$ | async)"
		></app-manual-loader-mini>
	</div>
	<div
		class="position-absolute last-modified text-right mt-1 pt-2"
		*ngIf="!!lastAutoSaveDateTime && !(isSavingSOAReview$ | async)"
	>
		<span>Last saved {{ lastAutoSaveDateTime }}</span>
	</div>
</div>
