import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	CrtMortgageState,
	CrtMortgageStore,
} from '../../state/crt-mortgage.store';

@Injectable()
export class MergeTagsQuery extends Query<CrtMortgageState> {
	constructor(protected store: CrtMortgageStore) {
		super(store);
	}

	/**
	 * @returns Observable list of Merge Tags
	 */
	mergeTags$ = this.select((state) => state.mergeTags);
}
