// import * as R from 'ramda';
import { curry, pipe, sort } from 'ramda';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';

export interface DropdownValue {
  DropdownCode: string;
  DropdownOrder: number;
  DropdownValue: string;
  DropdownValueID: number;
  IsDefault: boolean;
  IsActive?: boolean;
}

export function createDropdownValue({
  DropdownValueID = null,
  DropdownCode = '',
  DropdownValue = '',
  DropdownOrder = null,
  IsDefault = false
}: Partial<DropdownValue>): DropdownValue {
  return {
    DropdownValueID,
    DropdownCode,
    DropdownValue,
    DropdownOrder,
    IsDefault,
  };
}

export const filterDropdownsByCode = curry((code: string, dropdownValues: DropdownValue[]): DropdownValue[] =>
  dropdownValues?.filter(x => x.DropdownCode === code)
)
export const mapDropdownToChoices = (dropdownValues: DropdownValue[]): ViewDisplayValue[] => {
  return dropdownValues?.map(d => ViewDisplayValue.Map(d.DropdownValue, d.DropdownValue, d.IsDefault));
};
export const sortDropdowns = (dropdownValues: any) => sort<DropdownValue>((a, b) => a.DropdownOrder - b.DropdownOrder, dropdownValues);

export const filterAndMapDropdowns = (code: string) => pipe(filterDropdownsByCode(code), sortDropdowns, mapDropdownToChoices);

export function mapToLookupObject(choices: ViewDisplayValue[]): { [key: string]: string } {
  return choices?.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.display }), {});
}
