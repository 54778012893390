import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import * as R from 'ramda';
import { of } from 'rxjs';
import { catchError, map, tap, finalize } from 'rxjs/operators';
import { ApiService } from '../../../core/base/api.service';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { objectUtil } from '../../../util/util';
import { UserSettingsQuery } from '../state/user-settings/user-settings.query';
import { UserSettingsStore } from '../state/user-settings/user-settings.store';

@Injectable()
export class MergeTagsService {
	mergeTags$ = this.query.mergeTags$;
	pCTmergeTagsLoaded$ = this.query.pCTmergeTagsLoaded$;

	constructor(
		private api: ApiService,
		private store: UserSettingsStore,
		protected query: UserSettingsQuery
	) {}

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getMergeTags(
		order: number,
		tableCode: string,
		referenceId: number,
		dataType?: string
	) {
		let endpoint = `crt/merge-tag/${tableCode}/reference/${referenceId}`;
		if (dataType) {
			endpoint = `${endpoint}/${dataType}`;
		}
		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					let state = [...this.query.getValue().mergeTags, ...x];
					state = state?.sort((i1, i2) => i1.order - i2.order);
					this.store.setMergeTags(state);
				})
			),
			catchError(() => of([]))
		);
	}

	getNewProviderTags(order: number, tableCode: string, referenceId: number) {
		const dataType = 'PCT';
		const endpoint = `crt/merge-tag/${tableCode}/reference/${referenceId}/${dataType}`;

		return this.api.get<MergeTagState[]>(endpoint).pipe(
			map((x) => (x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : null)),
			map((x) => x?.map((i) => ({ ...i, order: +order }))),
			tap((x) =>
				applyTransaction(() => {
					const tags = [...this.query.getValue().mergeTags];
					x?.forEach((data) => {
						const i = R.findIndex(R.propEq('metaKey', data?.metaKey))(tags);
						tags[i] = data;
					});
					this.store.setMergeTags(tags);
				})
			),
			catchError(() => of([]))
		);
	}

	private setMergeTagValue = (metaKey: string, value: string[]) => {
		const tags = this.query.getValue().mergeTags;

		const mergeTags = tags.map((x) =>
			x.metaKey === metaKey ? { ...x, value: value } : x
		);

		this.store.setMergeTags(mergeTags);
	};

	getProviderMergeTagValues(staffId: number, provider: string) {
		const endpoint = `staff/${staffId}/provider-commission/${provider}`;
		return this.api.get<string[]>(endpoint).pipe(
			tap((x) => {
				applyTransaction(() => {
					switch (provider) {
						case 'PCLR':
							this.setMergeTagValue('LR_PROVIDERS', x);
							break;
						case 'PCM':
							this.setMergeTagValue('MORTGAGE_PROVIDERS', x);
							break;
						case 'PCFG':
							this.setMergeTagValue('FG_PROVIDERS', x);
							break;
						case 'PCK':
							this.setMergeTagValue('KIWISAVER_PROVIDERS', x);
							break;
						default:
							break;
					}
				});
			})
		);
	}

	setPCTMergeTagsLoaded(status) {
		this.store.setPCTMergeTagsLoaded(status);
	}
}
