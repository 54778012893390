<div class="row tap-container disclosure-document pt-3">
  <div class="col">
    <tabset [appTheme]="{ color: 'primarycolor' }">
      <tab heading="Email Settings">
        <app-authority-to-proceed-email-settings [emailSettings]="emailSettings$ | async" [mergeTags$]="mergeTags$"></app-authority-to-proceed-email-settings>
      </tab>
      <tab heading="Client Acceptance Wording">
        <app-client-acceptance-settings></app-client-acceptance-settings>
      </tab>
    </tabset>
  </div>
</div>
