import { Injectable } from '@angular/core';
import { UserStore, UserState } from './user.store';
import { ApiService } from '../../core/base/api.service';
import { tap, catchError } from 'rxjs/operators';
import UserResponse from './user.response-model';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { UserQuery } from './user.query';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environment';
import { LocalService } from '@core/services/local.service';

@Injectable()
export class UserService {
	constructor(
		private api: ApiService,
		private userStore: UserStore,
		private userQuery: UserQuery,
		private authService: AuthenticationService,
		private localService: LocalService
	) {}

	get(): Observable<UserResponse | any> {
		const endpoint = `staff`;
		this.userStore.updateStaffLoading(true);
		return this.api
			.get<UserResponse>(endpoint, { email: this.authService?.user?.email })
			.pipe(
				tap((x) => {
					this.userStore.update({
						...x,
						SecurityRoles: x.SecurityRoles?.filter((r) => r),
					});

					if (environment.production) {
						datadogRum.setUser({
							id: x?.StaffID?.toString(),
							name: x?.FirstName + ' ' + x?.LastName,
							email: x?.EmailAddress,
							role: x?.SecurityGroup,
							business_name: this.localService.getValue('code') ?? '',
						});
					}

					this.userStore.updateStaffLoading(false);
				}),
				catchError((err) => {
					this.userStore.updateStaffLoading(false);
					return throwError(err);
				})
			);
	}

	getValidateSecurityRole(code: string): Observable<boolean> {
		const endpoint = `security-groups/check/security-role/${code}`;
		return this.api.get<boolean>(endpoint).pipe(
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	getStaffNoLoading(): Observable<UserResponse | any> {
		const endpoint = `staff`;
		return this.api
			.get<UserResponse>(endpoint, { email: this.authService.user.email })
			.pipe(
				tap((x) => {
					this.userStore.update({
						...x,
						SecurityRoles: x.SecurityRoles?.filter((r) => r),
					});
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	update(req: UserResponse): Observable<UserResponse> {
		const endpoint = `staff/${this.userQuery.getValue().StaffID}/${
			this.authService.user.IsTL ? 'tl' : 'bl'
		}`;
		return this.api
			.put<UserState>(endpoint, {
				...req,
				Services: JSON.stringify(req.Services),
			})
			.pipe(
				tap(() => {
					this.userStore.update({
						...req,
					});
				})
			);
	}
}
