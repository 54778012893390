<table class="table table-bordered table-striped">
  <thead>
    <tr>
      <th scope="col">Adviser</th>
      <th scope="col">Completed Call centre meetings</th>
      <th scope="col">Completed Review Meetings</th>
      <th scope="col">Completed 1st meetings(self Gen)</th>
      <th scope="col">NB - Recommendation Meetings</th>
      <th scope="col">SG - Recommendation Meetings</th>
      <th scope="col">EB - Recommendation Meetings</th>
      <th scope="col">Submitted L&R API</th>
      <th scope="col">Issued L&R API</th>
      <th scope="col">Cancelled L&R API</th>
      <th scope="col">Completed KS</th>
      <th scope="col">Completed Blanket</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of 12">
      <td>{{data}}</td>
    </tr>
  </tbody>
</table>