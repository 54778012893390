<form [formGroup]="form" novalidate>
  <div
    class="container-fluid clearfix px-0"
    [ngClass]="
      secondaryClient?.isActive === 3 ? 'transfered' : 'not-transfered'
    "
  >
    <div class="row clearfix align-items-center mb-1">
      <div class="col-lg col-12"></div>
      <div class="col-lg-auto col-12 d-flex action-btns justify-content-end">
        <span class="position-relative" *ngIf="isSaving$ | async">
          <app-manual-loader-mini
            [isLoading]="isSaving$ | async"
            class="p-0"
          >
          </app-manual-loader-mini>
        </span>
        <ng-container *ngIf="!(isSaving$ | async)">
          <!-- Restriction for lead -->
          <ng-container *ngIf="isLead">
            <ng-container *ngIf="!(isEdit$ | async)">
              <ng-container *ngIf="secondaryClient?.isActive < 3">
                <ng-container *appRestrictTo="['FTSC']">
                  <span class="position-relative">
                    <button
                      type="button"
                      class="icon-btn"
                      id="transfer_{{ formID }}"
                      (click)="transfer()"
                      [disabled]="secondaryClient.contactMethod === 'Deceased'"
                    >
                      <i class="material-icons md-20 tap-text-color-h6">
                        arrow_forward
                      </i>
                    </button>
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="secondaryClient?.isActive < 3">
                <ng-container *appRestrictTo="['FCSC']">
                  <span class="position-relative">
                    <button
                      type="button"
                      class="icon-btn"
                      id="copy_{{ formID }}"
                      (click)="copySecondaryClient(secondaryClient.customerID)"
                      [disabled]="secondaryClient.contactMethod === 'Deceased'"
                    >
                      <i class="material-icons md-20 tap-text-color-h6">
                        file_copy
                      </i>
                    </button>
                  </span>
                </ng-container>
                <ng-container *appRestrictTo="['FEL']">
                  <span class="position-relative">
                    <button
                      type="button"
                      class="icon-btn"
                      id="edit_{{ formID }}"
                      (click)="edit()"
                    >
                      <i class="material-icons md-20 tap-text-color-h6">
                        edit
                      </i>
                    </button>
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *appRestrictTo="['FDL']">
                <span class="position-relative" *ngIf="!disableDelete">
                  <button
                    type="button"
                    class="icon-btn"
                    id="delete_{{ formID }}"
                    (click)="confirmDelete()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6">
                      delete
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isEdit$ | async">
              <ng-container *appRestrictTo="['FAL', 'FEL']">
                <span
                  class="position-relative"
                  *ngIf="(isEdit$ | async) && !isSaving"
                >
                  <button
                    type="button"
                    class="icon-btn"
                    id="save_{{ formID }}"
                    (click)="save()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6"> save </i>
                  </button>
                </span>
                <span class="position-relative">
                  <button
                    type="button"
                    class="icon-btn"
                    id="cancel_{{ formID }}"
                    [disabled]="isSaving"
                    (click)="cancel()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6">
                      cancel
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- Restriction for current client -->
          <ng-container *ngIf="!isLead">
            <ng-container *ngIf="!(isEdit$ | async)">
              <ng-container *ngIf="secondaryClient?.isActive < 3">
                <ng-container *appRestrictTo="['FTSC']">
                  <span class="position-relative">
                    <button
                      type="button"
                      class="icon-btn"
                      id="transfer_{{ formID }}"
                      (click)="transfer()"
                      [disabled]="secondaryClient.contactMethod === 'Deceased'"
                    >
                      <i class="material-icons md-20 tap-text-color-h6">
                        arrow_forward
                      </i>
                    </button>
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="secondaryClient?.isActive < 3">
                <ng-container *appRestrictTo="['FCSC']">
                  <span class="position-relative">
                    <button
                      type="button"
                      class="icon-btn"
                      id="copy_{{ formID }}"
                      (click)="copySecondaryClient(secondaryClient?.customerID)"
                      [disabled]="secondaryClient.contactMethod === 'Deceased'"
                    >
                      <i class="material-icons md-20 tap-text-color-h6">
                        file_copy
                      </i>
                    </button>
                  </span>
                </ng-container>
                <ng-container *appRestrictTo="['FEC']">
                  <span class="position-relative">
                    <button
                      type="button"
                      class="icon-btn"
                      id="edit_{{ formID }}"
                      (click)="edit()"
                    >
                      <i class="material-icons md-20 tap-text-color-h6">
                        edit
                      </i>
                    </button>
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *appRestrictTo="['FDC']">
                <span class="position-relative" *ngIf="!disableDelete">
                  <button
                    type="button"
                    class="icon-btn"
                    id="delete_{{ formID }}"
                    (click)="confirmDelete()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6">
                      delete
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="isEdit$ | async">
              <ng-container *appRestrictTo="['FAL', 'FEC']">
                <span
                  class="position-relative"
                  *ngIf="(isEdit$ | async) && !isSaving"
                >
                  <button
                    type="button"
                    class="icon-btn"
                    id="save_{{ formID }}"
                    (click)="save()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6"> save </i>
                  </button>
                </span>
                <span class="position-relative" *ngIf="isEdit$ | async">
                  <button
                    type="button"
                    class="icon-btn"
                    id="cancel_{{ formID }}"
                    [disabled]="isSaving"
                    (click)="cancel()"
                  >
                    <i class="material-icons md-20 tap-text-color-h6">
                      cancel
                    </i>
                  </button>
                </span>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="form-row clearfix align-items-center mb-2">
      <div class="col-lg-3 text-center order-lg-2">
        <div class="d-flex align-items-center justify-content-center">
          <app-photo
            id="uploadPhoto_{{ formID }}"
            [class.disabled]="
              (isSaving$ | async) || secondaryClient?.isActive === 3
            "
            [uploadType]="'modal'"
            [customer]="secondaryClient"
            [imageUrl]="secondaryClient?.photoURL"
            [uploadFn$]="addPhoto$"
          ></app-photo>
        </div>
      </div>
      <div class="col-lg-9 order-lg-1">
        <div class="form-row clearfix align-items-center mb-2">
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="title_{{ formID }}">Title</label>
              </div>
              <div class="col-lg-8 col-12">
                <span class="custom-dropdown w-100">
                  <select
                    formControlName="title"
                    class="form-control theme-crm-field-disabled"
                    id="title_{{ formID }}"
                    tabindex="7"
                  >
                    <option value=""></option>
                    <option *ngFor="let d of title" [value]="d.value">
                      {{ d.display }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <span class="tap-required-field" *ngIf="firstName.invalid"
                  >*</span
                >
                <label for="firstName_{{ formID }}">First Name</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  type="text"
                  formControlName="firstName"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="firstName_{{ formID }}"
                  tabindex="8"
                />
              </div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <span class="tap-required-field" *ngIf="lastName.invalid"
                  >*</span
                >
                <label for="lastName_{{ formID }}">Last Name</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  type="text"
                  formControlName="lastName"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="lastName_{{ formID }}"
                  tabindex="9"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-row clearfix align-items-center mb-2">
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="middleName_{{ formID }}">Middle Name</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  type="text"
                  formControlName="middleName"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="middleName_{{ formID }}"
                  tabindex="9"
                />
              </div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="dateOfBirth_{{ formID }}">Date of Birth</label>
              </div>
              <div class="col-lg-6 col-12">
                <app-date-input
                  #dateOfBirthDateInput
                  textboxId="dateOfBirth_{{ formID }}"
                  ngProjectAs="input"
                  textboxClass="theme-crm-field-disabled"
                  formControlName="dateOfBirth"
                  placeholder="dd/mm/yyyy"
                  tabindex="10"
									(onChangeEvent)="computeAge()"
                ></app-date-input>
              </div>
							<div class="col-lg-2 col-12 mt-lg-0 mt-1">
								<input
									id="age_{{ formID }}"
									[value]="age?.value || 0"
									class="form-control theme-crm-field-disabled"
									disabled
								/>
							</div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="gender_{{ formID }}">Gender</label>
              </div>
              <div class="col-lg-8 col-12">
								<span class="custom-dropdown w-100">
									<select
										formControlName="gender"
										class="form-control theme-crm-field-disabled"
										id="gender_{{ formID }}"
									>
										<option *ngFor="let d of gender" [value]="d.value">{{ d.display }}</option>
									</select>
								</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row clearfix align-items-center mb-2">
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="knownAs_{{ formID }}">Known As</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  type="text"
                  formControlName="knownAs"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="knownAs_{{ formID }}"
                  tabindex="11"
                />
              </div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="countryOfOrigin_{{ formID }}">Country of Origin</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  type="text"
                  formControlName="countryOfOrigin"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="countryOfOrigin_{{ formID }}"
                  tabindex="12.1"
                />
              </div>
            </div>
          </div>
          <div
						class="col-lg col-12"
						*featureToggle="{
							id: 'AdviserRework',
							show: false,
						}"
					>
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="vulnerable_{{ formID }}">Vulnerable?</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  class="chckbox form-control theme-crm-field-disabled"
                  formControlName="isVulnerable"
                  type="checkbox"
                  id="vulnerable_{{ formID }}"
                  tabindex="12.2"
                />
              </div>
            </div>
          </div>
          <div
						class="col-lg col-12"
						*featureToggle="{
							id: 'AdviserRework',
							show: true,
						}"
					>
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="isKeyContact_{{ formID }}">Key Contact</label>
              </div>
              <div class="col-lg-8 col-12">
								<input
									class="chckbox form-control theme-crm-field-disabled"
									formControlName="isKeyContact"
									type="checkbox"
									id="isKeyContact_{{ formID }}"
									tabindex="12.2"
								/>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row clearfix align-items-center mb-2">
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="employment_{{ formID }}">Employment</label>
              </div>
              <div class="col-lg-8 col-12">
                <span class="custom-dropdown w-100">
                  <select
                    formControlName="employment"
                    class="form-control theme-crm-field-disabled"
                    id="employment_{{ formID }}"
                    tabindex="13.1"
                  >
                    <option value=""></option>
                    <option *ngFor="let d of employment" [value]="d.value">
                      {{ d.display }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="occupation_{{ formID }}">Occupation</label>
              </div>
              <div class="col-lg-8 col-12">
                <input
                  type="text"
                  formControlName="occupation"
                  class="form-control theme-crm-field-disabled"
                  placeholder="- -"
                  id="occupation_{{ formID }}"
                  tabindex="13.2"
                />
              </div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="income_{{ formID }}">Income</label>
              </div>
              <div class="col-lg-8 col-12">
                <div class="dollar-icon">
                  <input
                    type="text"
                    formControlName="income"
                    class="form-control theme-crm-field-disabled"
                    placeholder="- -"
                    id="income_{{ formID }}"
                    appTwoDigitDecimalNumber
                    tabindex="13.3"
                    maxlength="16"
                  />
                  <i>$</i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row clearfix align-items-center mb-2">
					<!-- Employer -->
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
								<label for="employer_{{ formID }}">Employer</label>
							</div>
              <div class="col-lg-8 col-12">
								<input
									type="text"
									formControlName="employer"
									class="form-control theme-crm-field-disabled"
									placeholder="- -"
									id="employer_{{ formID }}"
									tabindex="14"
								/>
							</div>
						</div>
					</div>
					<!-- Industry -->
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="industry_{{ formID }}">Industry</label>
              </div>
              <div class="col-lg-8 col-12">
                <span class="custom-dropdown w-100">
                  <select
                    formControlName="industry"
                    class="form-control theme-crm-field-disabled"
                    id="industry_{{ formID }}"
                    tabindex="15.1"
                  >
                    <option value=""></option>
                    <option *ngFor="let d of industry" [value]="d.value">
                      {{ d.display }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg col-12">
            <div class="form-row align-items-center">
              <div class="col-lg-4 col-12">
                <label for="relationship_{{ formID }}">Relationship</label>
              </div>
              <div class="col-lg-8 col-12">
                <span class="custom-dropdown w-100">
                  <select
                    formControlName="relationship"
                    class="form-control theme-crm-field-disabled"
                    id="relationship_{{ formID }}"
                    tabindex="15.2"
                  >
                    <option value=""></option>
                    <option *ngFor="let d of relationship" [value]="d.value">
                      {{ d.display }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="form-row clearfix align-items-center mb-2">
			<!-- Contact Method -->
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="contactMethod_{{ formID }}">Contact Method</label>
					</div>
					<div class="col-lg-8 col-12">
						<span class="custom-dropdown w-100">
							<select
								formControlName="contactMethod"
								id="contactMethod_{{ formID }}"
								class="form-control theme-crm-field-disabled"
								placeholder="- -"
								tabindex="16"
							>
								<option value=""></option>
								<option *ngFor="let d of contactMethods" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</div>
			<!-- Mobile -->
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="mobile_{{ formID }}">Mobile</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="mobile"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="mobile_{{ formID }}"
							tabindex="17"
						/>
					</div>
				</div>
			</div>
			<!-- Work -->
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="work_{{ formID }}">Work</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="work"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="work_{{ formID }}"
							tabindex="18"
						/>
					</div>
				</div>
			</div>
			<div class="col-lg col-12"></div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
			<!-- Email -->
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="email_{{ formID }}">Email</label>
          </div>
          <div class="col-lg-10 col-12">
            <input
              type="text"
              formControlName="email"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="email_{{ formID }}"
              tabindex="19"
            />
          </div>
        </div>
      </div>
			<!-- Alternate Email -->
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="alternateEmail_{{ formID }}">Alternate Email</label>
          </div>
          <div class="col-lg-10 col-12">
            <input
              type="text"
              formControlName="alternateEmail"
              class="form-control theme-crm-field-disabled"
              placeholder="- -"
              id="alternateEmail_{{ formID }}"
              tabindex="20"
            />
          </div>
        </div>
      </div>
    </div>
		<div class="form-row clearfix align-items-center mb-2">
			<!-- Physical Address -->
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="physicalAddress_{{ formID }}">Physical Address</label>
          </div>
          <div class="col-lg-10 col-12">
            <app-address-input
              inputId="physicalAddress_{{ formID }}"
              formControlName="physicalAddress"
              textboxClass="theme-crm-field-disabled"
              tabindex="21"
            ></app-address-input>
          </div>
        </div>
      </div>
      <div class="col-lg col-6"></div>
    </div>
    <div class="form-row clearfix align-items-center mb-2">
			<!-- Professional Contacts -->
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="professionalContacts_{{ formID }}"
              >Professional Contacts</label
            >
          </div>
          <div class="col-lg-10 col-12">
            <app-chips
              textboxClass="theme-crm-field"
              formControlName="professionalContacts"
              [choices]="professionalContacts"
              id="professionalContacts_{{ formID }}"
              tabindex="22"
            >
            </app-chips>
          </div>
        </div>
      </div>
      <div class="col-lg col-6"></div>
    </div>

    <div
			class="form-row clearfix align-items-center mb-2 feat-adviser-rework"
			*featureToggle="{
				id: 'AdviserRework',
				show: true,
			}"
		>
			<!-- Vulnerable -->
      <div class="col-lg col-12">
        <div class="form-row align-items-center">
          <div class="col-lg-2 col-12">
            <label for="vulnerable_{{ formID }}">Vulnerable?</label>
          </div>
          <div class="col-lg-10 col-12 d-flex align-items-center">
            <input
							class="chckbox form-control theme-crm-field-disabled cbox-vulnerable d-inline-block"
							formControlName="isVulnerable"
							type="checkbox"
							id="vulnerable_{{ formID }}"
							tabindex="23"
						/>
						<input
							type="text"
							formControlName="vulnerableNotes"
							class="form-control theme-crm-field-disabled input-vulnerable d-inline-block ml-2"
							placeholder="- -"
							id="vulnerableNotes_{{ formID }}"
							tabindex="24"
						/>
          </div>
        </div>
      </div>
      <div class="col-lg col-6"></div>
    </div>

    <ng-container *ngIf="addMode">
      <div class="form-row clearfix align-items-center mb-2">
        <div class="col-lg-12"><label for="note_{{ formID }}">Add a note</label></div>
        <div class="col-lg-12">
          <textarea
            class="form-control theme-crm-field-disabled"
            formControlName="note"
            rows="4"
            id="note_{{ formID }}"
						tabindex="25"
          ></textarea>
        </div>
      </div>
    </ng-container>
  </div>
</form>

<ng-container *ngIf="secondaryClient?.customerID && !addMode">
  <app-table-paginate-notes
    [isLead]="isLead"
    [hasAddNote]="true"
    [customerId]="secondaryClient?.customerID"
    [notes]="notes?.notes"
    [count]="notes?.count"
    [hasPaging]="true"
    [getFn$]="getNotes$"
    [addFn$]="addNotes$"
    [deleteFn$]="deleteNotes$"
    [hideNote]="secondaryClient.isActive === 3"
		[refreshNotes$]="refreshNotes$"
  ></app-table-paginate-notes>
</ng-container>
