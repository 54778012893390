<img class="tap-dp" [src]="profilePicture" alt="profile" *ngIf="profilePicture; else useIcon"/>
<ng-template #useIcon>
  <mat-icon class="tap-icon" [svgIcon]="icon"></mat-icon>
</ng-template>
<div class="item-details">
  <h3 [ngClass]="type + ' tap-text-body'">{{name}}</h3>
  <div *ngIf="details && details.length > 0">
    <span 
      *ngFor="let detail of details"
      [ngClass]="'tap-text-body ' + (detail.highlighted ? 'highlighted' : '')"
    >
      {{detail.value}}
    </span>
  </div>
</div>
<div class="item-action" *ngIf="(isHovered$ | async)">
  <div>
    <mat-icon fontIcon="arrow_forward"></mat-icon>
    View <span>{{type}}</span>
  </div>
</div>
<div class="item-action" *ngIf="!(isHovered$ | async)">
  <div>{{rightDetails}}</div>
</div>
