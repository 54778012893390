import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import {
	DocumentTypesMOAT
} from '../../../../../../shared/models/documents/document.model';

export class DisclosureMapper {
		public static mapApDocumentUpload(data) {
		return {
			document: data?.document,
			referenceId: data?.referenceId,
			fileName: data?.fileName,
			type: DocumentTypesMOAT.Disclosure,
		};
	}

	public static mapCrtToUpsert(data) {
		return {
			...data,
			sectionCode: AdviceProcessSectionCodes.Disclosure,
			status: 1,
		};
	}
}
