<form novalidate [formGroup]="form" class="filter-form">
	<div class="clearfix">
		<div class="row d-flex justify-content-between">
			<div class="col-md-12 col-lg-auto align-self-center mb-3 mb-lg-0">
				<h1 class="d-inline tap-text-secondary tap-text-h2">
					Mortgage Pipeline
				</h1>
				<h6
					class="
						mb-0
						px-0 px-md-2
						d-inline
						tap-text-secondary
						d-block d-md-inline-block
					"
				>
					{{ count$ | async | number }} Results
					<span class="px-2">
						Total Loan Value: ${{ totalApi$ | async | number: "1.2" }}
					</span>
				</h6>
			</div>

			<div class="col-md-12 col-lg-auto align-self-center ml-auto">
				<div class="form-row align-items-center justify-content-end">
					<div class="col-auto">
						<button
							id="toggleFilterBtn"
							type="button"
							title="Filter"
							class="icon-btn font-weight-bold"
							(click)="collapse.Toggle(); toggle()"
						>
							<i class="material-icons md-20 tap-text-color-h6 pt-1">
								filter_list
							</i>
						</button>
					</div>
					<div class="col-auto">
						<button
							id="resetFilterBtn"
							type="button"
							title="Reset"
							class="icon-btn"
							(click)="reset()"
						>
							<i class="fas fa-undo fa-14 tap-text-color-h6 pt-1"></i>
						</button>
					</div>
					<ng-container *appRestrictTo="['FSP']">
						<div class="col-auto">
							<button
								id="searchBtn"
								type="submit"
								class="tap-btn tap-btn--default font-weight-bold px-lg-4"
								(click)="search()"
								[disabled]="isSearching$ | async"
							>
								Search
							</button>
						</div>
					</ng-container>
					<ng-container *appRestrictTo="['FEP']">
						<div
							class="col-auto"
							(mouseenter)="showPopover()"
							[placement]="'bottom'"
							[popover]="(exportStatus$ | async) === 3 ? popTemplate : null"
							containerClass="popover-info-container"
							triggers=""
						>
							<button
								id="exportBtn"
								type="button"
								title="Export"
								class="icon-btn"
								(click)="export();"
								[disabled]="(isExporting$ | async) || (exportStatus$ | async) === 3"
								data-toggle="modal"
								data-target="#mortgageSearchExportModal"
							>
								<i class="fas fa-download fa-14 tap-text-color-h6 pt-1"></i>
							</button>
						</div>
					</ng-container>
					<div class="col-auto">
						<app-manual-loader-mini
							[isLoading]="(isSearching$ | async) || (isExporting$ | async)"
						>
						</app-manual-loader-mini>
					</div>
					<div class="col-auto">
						<span class="position-relative d-inline-block">
							<app-mortgage-columns-form
								*ngIf="columnFromOpen$ | async"
								class="search-settings"
							>
							</app-mortgage-columns-form>
							<button
								id="settingsBtn"
								type="button"
								title="Settings"
								class="icon-btn"
								(click)="openColumnPopup()"
							>
								<i class="material-icons md-20 column-settings-icon pt-1">
									settings
								</i>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		app-collapse
		#collapse
		[initialExpandedState]="false"
		class="search-collapse"
	>
		<ng-container
			*ngTemplateOutlet="
				(adviserReworkFeature$ | async) ? adviserReworkFilters : defaultSearchFilters
			"></ng-container>
	</div>

	<ng-template #defaultSearchFilters>
		<div class="container-fluid ml-0 px-0 pt-3">
			<div class="row">
				<!-- 1st Col -->
				<div class="col-12 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="adviserId"
								formControlName="SelectedAdvisers"
								[choices]="advisers$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Mortgage Status</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="mortgageStatusId"
								formControlName="SelectedStatuses"
								[choices]="MS$ | async"
								[tabindex]="60"
							>
							</app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Provider</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="providerId"
								formControlName="SelectedProviders"
								[choices]="MP$ | async"
								[tabindex]="60"
							>
							</app-chips>
						</div>
					</div>
				</div>

				<!-- 2nd column -->
				<div class="col-12 col-lg-5">
					<div class="row">
						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Refix Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="refixStatusId"
										formControlName="SelectedRefixSatuses"
										[choices]="MRS$ | async"
										[tabindex]="60"
									>
									</app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Type</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadTypeId"
										formControlName="SelectedLeadTypes"
										[choices]="PCLT$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Alt Adviser</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="SelectedAltAdvisersId"
										formControlName="SelectedAltAdvisers"
										[choices]="advisers$ | async"
									>
									</app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">{{ businessName$ | async }} Mortgage</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="businessMortgageId"
										formControlName="BusinessMortgage"
										[choices]="businessMortgage$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Origin</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadOriginId"
										formControlName="SelectedLeadOrigins"
										[choices]="PCLE$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-4">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Adviser Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="adviserStatusId"
										formControlName="SelectedAdviserStatuses"
										[choices]="adviserStatus$ | async"
									></app-chips>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 3rd column -->
				<div class="col-12 col-lg-4 d-flex flex-column justify-content-between">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Fixed Period End</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="fixedPeriodEndDateMinId"
									class="col"
									formControlName="FixedPeriodEndDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
									style="flex: 1"
								></app-date-input>
								<div class="col-auto align-self-center px-0">to</div>
								<app-date-input
									textboxId="fixedPeriodEndDateMaxId"
									class="col"
									formControlName="FixedPeriodEndDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
									style="flex: 1"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Loan Drawdown</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="loanDrawdownDateMinId"
									class="col"
									formControlName="LoanDrawdownDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-0">to</div>
								<app-date-input
									textboxId="loanDrawdownDateMaxId"
									class="col"
									formControlName="LoanDrawdownDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Activity Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="nextActivityDateMinId"
									class="col"
									formControlName="NextActivityDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-0">to</div>
								<app-date-input
									textboxId="nextActivityDateMaxId"
									class="col"
									formControlName="NextActivityDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

	<!-- Search Filters -->
	<ng-template #adviserReworkFilters>
		<div class="container-fluid ml-0 px-0 pt-3">
			<div class="row">
				<!-- 1st Col -->
				<div class="col-12 col-lg-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="adviserId"
								formControlName="SelectedAdvisers"
								[choices]="advisers$ | async"
							></app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Mortgage Status</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="mortgageStatusId"
								formControlName="SelectedStatuses"
								[choices]="MS$ | async"
								[tabindex]="60"
							>
							</app-chips>
						</div>
					</div>

					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Provider</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="providerId"
								formControlName="SelectedProviders"
								[choices]="MP$ | async"
								[tabindex]="60"
							>
							</app-chips>
						</div>
					</div>
				</div>

				<!-- 2nd column -->
				<div class="col-12 col-lg-4">
					<div class="row">
						<div class="col-12 col-lg-6">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Refix Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="refixStatusId"
										formControlName="SelectedRefixSatuses"
										[choices]="MRS$ | async"
										[tabindex]="60"
									>
									</app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-6">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Type</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadTypeId"
										formControlName="SelectedLeadTypes"
										[choices]="PCLT$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-6">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">{{ businessName$ | async }} Mortgage</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="businessMortgageId"
										formControlName="BusinessMortgage"
										[choices]="businessMortgage$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-6">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Lead Origin</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="leadOriginId"
										formControlName="SelectedLeadOrigins"
										[choices]="PCLE$ | async"
									></app-chips>
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-6">
						</div>

						<div class="col-12 col-lg-6">
							<div class="row align-items-center mb-1">
								<div class="col-12">
									<label for="">Adviser Status</label>
								</div>
								<div class="col-12">
									<app-chips
										textboxId="adviserStatusId"
										formControlName="SelectedAdviserStatuses"
										[choices]="adviserStatus$ | async"
									></app-chips>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 3rd column -->
				<div class="col-12 col-lg-5 d-flex flex-column justify-content-between">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Fixed Period End</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="fixedPeriodEndDateMinId"
									class="col"
									formControlName="FixedPeriodEndDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
									style="flex: 1"
								></app-date-input>
								<div class="col-auto align-self-center px-0">to</div>
								<app-date-input
									textboxId="fixedPeriodEndDateMaxId"
									class="col"
									formControlName="FixedPeriodEndDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
									style="flex: 1"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Loan Drawdown</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="loanDrawdownDateMinId"
									class="col"
									formControlName="LoanDrawdownDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-0">to</div>
								<app-date-input
									textboxId="loanDrawdownDateMaxId"
									class="col"
									formControlName="LoanDrawdownDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Activity Date</label>
						</div>
						<div class="col-12">
							<div class="row">
								<app-date-input
									textboxId="nextActivityDateMinId"
									class="col"
									formControlName="NextActivityDateMin"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
								<div class="col-auto align-self-center px-0">to</div>
								<app-date-input
									textboxId="nextActivityDateMaxId"
									class="col"
									formControlName="NextActivityDateMax"
									textboxClass="theme-crm-field-disabled"
									placeholder="dd/mm/yyyy"
								></app-date-input>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-1"></div>
			</div>
		</div>
	</ng-template>
</form>

<!-- Modal -->
<ng-template #exportTemplate>
	<app-export-progress
		[progress]="progress"
		[percent]="percent"
		[msg]="msg"
	></app-export-progress>
</ng-template>
<!-- End Modal -->

<ng-template #popTemplate>
	<div class="popover-info">
		<p>
			Export currently in progress, please wait until its completion
		</p>
	</div>
</ng-template>
