import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, EMPTY, iif, Observable, Observer, of, Subject, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { peopleToViewDisplayValueUtil } from 'src/app/modules/crm/client-review-template/util/mapViewDisplayValue.util';
import { PeopleModalComponent } from 'src/app/shared/modal/crt/moat/people/people-modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import { DependentState } from 'src/app/shared/models/client-review-template/dependent/dependent.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import { PeopleEntitiesQuery } from '../state/people-entities.query';
import { PeopleEntitiesService } from '../state/people-entities.service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { complement, isNil, isEmpty, either } from 'ramda';
import { RelationshipTypes } from 'src/app/shared/models/_general/client.model';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
declare var $: any;

@Component({
	selector: 'app-people',
	templateUrl: './people.component.html',
	styleUrls: ['./people.component.scss'],
})
export class PeopleComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	@Input() people$: Observable<PeopleState[]>;
	@Input() SCR$: Observable<ViewDisplayValue[]>;
	@Input() PCE$: Observable<ViewDisplayValue[]>;
	@Input() APCRTG$: Observable<ViewDisplayValue[]>;
	@Input() APCRTVD$: Observable<ViewDisplayValue[]>;
	@Input() isPeopleLoading$: Observable<boolean>;
	@Input() APCRTTIR$: Observable<ViewDisplayValue[]>;
	@Input() APCRTMS$: Observable<ViewDisplayValue[]>;
	@Input() PCT$: Observable<ViewDisplayValue[]>;

	@Input() addNewPeopleFn$: ({ people, adviceProcessId }) => Observable<any>;
	@Input() addNewDependantFn$: ({
		dependent,
		adviceProcessId,
	}) => Observable<any>;
	@Input() addExistingPeopleFn$: ({
		people,
		adviceProcessId,
	}) => Observable<any>;
	@Input() updateFn$: (people) => Observable<any>;
	@Input() deleteFn$: (id) => Observable<any>;

	clientId = this.route.snapshot.paramMap.get('clientId');
	elseMinusPeople = true;
	elseMinusDependents = true;
	elseMinusTrust = true;
	elseMinusCompany = true;

	isAddNew = false;
	isLoading = false;
	isSaving = false;

	crtLoading: number;
	peopleList$ = new BehaviorSubject<ViewDisplayValue[]>(null);

	bsModalRef: BsModalRef;
	transferedSCI: PeopleState[];
	linkedContacts: LinkedContactState[];
	maxPeopleWarning = logMessage.oat.mortgage.factFind.people.warning.maxPeople;

	personInfo = (crtId: number) => this.peopleEntitiesService.getPersonInfo(crtId);

	constructor(
		private peopleEntitiesQuery: PeopleEntitiesQuery,
		private peopleEntitiesService: PeopleEntitiesService,
		private crtMortgageQuery: CrtMortgageQuery,
		private cd: ChangeDetectorRef,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private loggerService: LoggerService,
	) { }

	ngOnInit(): void {
		this.peopleEntitiesQuery.linkedContacts$
			.pipe(
				tap((linkedContacts) => {
					this.linkedContacts = linkedContacts;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	toggleLoader(cRTId: number, load: boolean) {
		this.crtLoading = cRTId && load ? +cRTId : null;
	}

	isCrtLoading(cRTId: number) {
		if (this.isAddNew && !this.isSaving) {
			return false;
		}
		if (this.isSaving && cRTId === 0) {
			return true;
		}
		return +this.crtLoading === +cRTId;
	}

	collapseMorePeople() {
		$('#collapsePeople').toggle();
		this.elseMinusPeople = false;
	}
	collapseLessPeople() {
		$('#collapsePeople').toggle();
		this.elseMinusPeople = true;
	}

	editPerson(crtId: number) {

		this.toggleLoader(+crtId, true);
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
			this.toggleLoader(crtId, false);
		});
		this.personInfo(crtId)
			.pipe(take(1))
			.subscribe((x) => {
				const initState = {
					header: 'People Details',
					message: `LifeAssuredDetails`,
					scr$: this.SCR$,
					pce$: this.PCE$,
					apcrtvd$: this.APCRTVD$,
					apcrtg$: this.APCRTG$,
					apcrttir$: this.APCRTTIR$,
					apcrtms$: this.APCRTMS$,
					pct$: this.PCT$,
					hasCountry: true,
					personInfo: x,
					savefn: this.updatePeople,
					decline$: decline,
				};
				this.bsModalRef = this.modalService.show(PeopleModalComponent, {
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false
				});
			});
	}

	deletePerson(id: number) {
		this.toggleLoader(id, true);

		const confirm = new Observable((obs) => {
			this.deleteItem(id);
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.toggleLoader(id, false);
			obs.complete();
		});

		const initState = {
			header: 'Delete Person',
			message: logMessage.oat.shared.factFind.delete,
			delete$: confirm,
			decline$: decline,
			confirmButton: 'OK',
			detachCloseIcon: false,
		};
		this.bsModalRef = this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
	}

	deleteItem = (id: number) => {
		this.deleteFn$(id)
			.pipe(
				mergeMap(() => this.updatePeopleDd()),
				tap(() => {
					if (id && this.checkSCIByCrtId(id)) {
						this.invalidSciWarning();
					}
				}),
				finalize(() => (this.isLoading = false)),
				take(1),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => {
				this.toggleLoader(id, false);
			});
	};

	updatePeople = (model: PeopleState) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) => this.updateFn$(x)),
			finalize(() => {
				if (
					!this.isLinkedContact(+model?.customerID) &&
					+model?.customerID &&
					this.checkSCIByCustomerId(+model?.customerID)
				) {
					this.invalidSciWarning();
				}
				this.isAddNew = false;
				this.isSaving = false;
				this.toggleLoader(+model?.cRTId, false);
				this.cd.detectChanges();
			})
		);

	addExistingPerson(p: PeopleState) {
		this.isSaving = true;
		this.peopleEntitiesQuery.peopleFromCRMAndCRTNoFilter$
			.pipe(
				tap(() => (this.isLoading = true)),
				map((x) => x?.find((c) => c.customerID === +p.customerId)),
				map((client) => {
					const adviceProcessId = parseInt(
						this.route.snapshot.paramMap.get('adviceProcessId'),
						10
					);
					const newClient = {
						adviceProcessId: 0,
						cRTId: 0,
						customerID: client.customerID,
						customerType: client.customerType,
						countryOfOrigin: client.countryOfOrigin,
						dateOfBirth: client.dateOfBirth || client.birthDate,
						email: client.email,
						employer: null,
						employment: client.employment,
						firstName: client.firstName,
						gender: client.gender
							? client.gender === 'F'
								? 'Female'
								: 'Male'
							: null,
						homePhone: client.homePhone,
						knownAs: client.knownAs,
						lastName: client.lastName,
						manual: 0,
						middleName: client.middleName,
						mobilePhone: client.mobile,
						occupation: client.occupation,
						physicalAddress: client.physicalAddress,
						relationship: client.relationship,
						sectionCode: AdviceProcessSectionCodes.People,
						visaDetails: null,
						workPhone: client.work,
						age: null,
						status: null,
						timeInResidencePhysicalAddress:
							client.timeInResidencePhysicalAddress,
						previousAddress: client.previousAddress,
						timeInResidencePreviousAddress:
							client.timeInResidencePreviousAddress,
						maritalStatus: client.maritalStatus,
						title: client?.title,
					};
					return { newClient, adviceProcessId };
				}),
				take(1),
				mergeMap((res) =>
					this.addExistingPeopleFn$({
						people: res.newClient,
						adviceProcessId: res.adviceProcessId,
					})
				),
				tap(() => {
					this.isAddNew = false;
					this.isSaving = false;
					this.cd.detectChanges();
				})
			)
			.subscribe();
	}

	removePerson(index: number) {
		this.people$
			.pipe(
				map((x) => x?.splice(index, 1)),
				take(1)
			)
			.subscribe(() => {
				this.isAddNew = false;
				this.cd.detectChanges();
			});
	}

	addPeopleOrDependent = (model: PeopleState | DependentState) =>
		new Observable<any>((obs) => {
			this.isSaving = true;
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => x?.relationship === RelationshipTypes.Child,
					this.addNewDependantFn$({
						dependent: x,
						adviceProcessId: parseInt(
							this.route.snapshot.paramMap.get('adviceProcessId'),
							10
						),
					}).pipe(
						withLatestFrom(this.people$),
						map(([, people]) => {
							this.removePerson(people.length - 1);
						})
					),
					this.addPeople(model)
				)
			)
		);

	change(e, i: number) {
		if (e.target.value === 'new') {
			const decline = new Observable((obs: Observer<any>) => {
				// tslint:disable-next-line: no-angle-bracket-type-assertion
				(<HTMLInputElement> (
					document.getElementById('addNewPersonDropdown_' + i)
				)).value = '';
				this.isSaving = false;
				this.cd.detectChanges();
				obs.complete();
			});

			const initState = {
				header: 'People Details',
				message: `LifeAssuredDetails`,
				scr$: this.SCR$,
				pce$: this.PCE$,
				apcrtvd$: this.APCRTVD$,
				apcrtg$: this.APCRTG$,
				isCompany$: this.crtMortgageQuery.isCompany$,
				apcrttir$: this.APCRTTIR$,
				apcrtms$: this.APCRTMS$,
				pct$: this.PCT$,
				hasCountry: true,
				savefn: this.addPeopleOrDependent,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(PeopleModalComponent, {
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false
			});
		} else {
			this.peopleEntitiesQuery.peopleFromCRMAndCRTExceptChild$
				.pipe(
					map((x) => x?.find((y) => y.customerID === +e.target.value)),
					mergeMap((x) =>
						this.people$.pipe(
							map((p) => {
								const age = moment().diff(x.dateOfBirth || x.birthDate, 'years');
								p[i] = {
									cRTId: 0,
									age: x.dateOfBirth || x.birthDate ? (age < 0 ? 0 : age) : null,
									birthDate: x.dateOfBirth || x.birthDate,
									customerType: x.customerType,
									name:
										x.firstName && x.lastName
											? x.firstName?.concat(' ', x.lastName)
											: null,
									relationship: x.relationship,
									customerId: x.customerID,
									title: x?.title
								};
							})
						)
					),
					take(1)
				)
				.subscribe();
		}
	}

	addPerson() {
		this.isAddNew = true;
		this.peopleEntitiesQuery.peopleFromCRMAndCRTExceptChildNotDeceased$
			.pipe(
				mergeMap((x) => peopleToViewDisplayValueUtil(x)),
				map((x) => {
					this.peopleList$.next(x);
					return x;
				}),
				switchMap((list) => {
					return this.people$.pipe(
						map((x) => {
							const newPeople: PeopleState = {
								cRTId: 0,
								age: 0,
								birthDate: '',
								customerType: '',
								name: '',
								relationship: '',
								customerId: 0,
							}
							x?.push(newPeople);

							return x;
						}),
						// tap((people) => {
						// 	if (list.length < 1) {
						// 		this.change({ target: { value: 'new' } }, people.length - 1);
						// 	}
						// })
					);
				}),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.peopleEntitiesQuery.people$
			.pipe(
				map((x) => {
					if (x?.some((p) => p?.cRTId === 0 || !p?.cRTId)) {
						x?.splice(
							x?.findIndex((p) => p?.cRTId === 0 || !p.cRTId),
							1
						);

						return x;
					}
					return x;
				})
			)
			.subscribe();

		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	warnMsg = () => {
		this.loggerService.Warning({}, this.maxPeopleWarning)
		return EMPTY;
	}

	addPeople = (model: PeopleState) =>
		new Observable<any>((obs) => {
			this.isSaving = true;
			obs.next(model);
			obs.complete();
		}).pipe(
			withLatestFrom(this.peopleEntitiesQuery.people$),
			concatMap(([peopleModel, people]) => {
				const count = people?.filter(x => x.cRTId > 0)?.length;
				return count >= 4 ? this.warnMsg() : of(peopleModel)
			}),
			filter(x => !!x),
			mergeMap((x) =>
				this.addNewPeopleFn$({
					people: x,
					adviceProcessId: parseInt(
						this.route.snapshot.paramMap.get('adviceProcessId'),
						10
					),
				})
			),
			tap(() => {
				this.isAddNew = false;
				this.isSaving = false;
				this.cd.detectChanges();
			})
		);

	// Utilities
	updatePeopleDd = () =>
		this.peopleEntitiesQuery.peopleFromCRMAndCRTExceptChildNotDeceased$.pipe(
			map((x) => x?.filter((i) => +i?.isActive === 1)),
			mergeMap((x) => peopleToViewDisplayValueUtil(x)),
			tap((x) => this.peopleList$.next(x)),
			take(1)
		);

	checkSCIByCrtId(id: number) {
		// Check if SCI, based on cRTId
		const sci = this.transferedSCI?.filter((x) => +x?.cRTId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	invalidSciWarning() {
		this.loggerService.Warning(
			{},
			logMessage.oat.shared.warning.invalidSci
		);
	}

	checkSCIByCustomerId(id: number) {
		// Check if SCI, based on customerId
		const sci = this.transferedSCI?.filter((x) => +x?.customerId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	isLinkedContact(id: number) {
		return !!this.linkedContacts?.find((x) => +x?.customerID === +id);
	}
}
