import { Component, OnInit, ViewChild } from '@angular/core';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { convertUtil, objectUtil } from 'src/app/util/util';
import { MergeTagsCrtSettingsService } from '../../merge-tags/state/merge-tags-crt-settings.service';
import { ClientFactFindSettingsService } from './state/client-fact-find-template-settings.service';
import { ClientFactFindSettingsMapper } from './state/client-fact-find-template-settings.mapper';
import { SettingsTypes } from 'src/app/modules/mortgage-settings/state/mortgage-settings.model';
import { getContentWithMergeTags } from 'src/app/shared/converter/content-merge-tags';
declare var $: any;

export const componentName = 'app-client-fact-find-template-settings';

@Component({
	selector: componentName,
	templateUrl: './client-fact-find-template-settings.component.html',
	styleUrls: ['./client-fact-find-template-settings.component.scss'],
})
export class ClientFactFindTemplateSettingsComponent implements OnInit {
	template: string;
	mergeTag: MergeTagState[];
	data;
	isLoading = false;
	isPreview = false;
	isReload = true;
	customButtons: [];
	currentTemplate = '<p></p>';
	undoReset$ = new BehaviorSubject<any>(null);
	undoStack$ = new BehaviorSubject<any>(null);

	tempData: string;
	templateId: number;
	shortcodes: object;
	undoStack = [];

	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(
		private cffService: ClientFactFindSettingsService,
		private mtService: MergeTagsCrtSettingsService
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		combineLatest([
			this.cffService.clientFactFindDocuments$,
			this.mtService.mergeTags$,
		])
			.pipe(
				filter(([data, mt]) => !!mt),
				tap(([data, mt]) => (this.data = data)),
				tap(([data, mt]) => (this.templateId = +data?.template)),
				tap(([data, mt]) => this.setMergeTags(mt)),
				concatMap(([data, mt]) =>
					iif(
						() =>
							(R.complement(R.isNil)(data?.template) ||
								!isNaN(+data?.template)) &&
							+data?.template !== 0,
						this.cffService.getClientFactFindDocumentFile(+data?.template).pipe(
							map((res) =>
								res ? objectUtil.mapPascalCaseToCamelCase(res) : null
							),
							concatMap((res) =>
								iif(
									() => res?.documentLink,
									this.cffService.getDocumentFromURL(res?.documentLink),
									of(null)
								)
							)
						),
						this.cffService.getDocumentFromURL(data?.templateLink)
					)
				),
				tap((data) => {
					this.currentTemplate = data
						? data?.toString().replace(/><\/label>/g, '> </label>')
						: '<p></p>';
				}),
				take(1)
			)
			.subscribe();
	}

	setMergeTags(mergeTags) {
		of(mergeTags)
			.pipe(
				map((mt) => MergeTagsMapper.mapMergeTags(mt, true)),
				tap((mt) => (this.mergeTag = mt)),
				map((mt) => MergeTagsMapper.mapTagsForWysiwyg(mt)),
				tap((mt) => (this.shortcodes = mt)),
				take(1)
			)
			.subscribe();
	}

	getUndoStack(event) {
		this.undoStack = event;
	}

	save() {
		this.isLoading = true;
		of(this.isReload)
			.pipe(
				concatMap((x) =>
					iif(() => x, this.saveReloadTemplate(x), this.saveTemplate())
				),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	saveReloadTemplate = (isReload) =>
		of(this.undoStack).pipe(
			concatMap((undoStack) =>
				iif(
					() => isReload && R.isEmpty(undoStack),
					this.cffService
						.updateClientFactFind({
							template: null,
							referenceId: 0,
							type: SettingsTypes.MOATClientFactFind,
						})
						.pipe(
							finalize(() => {
								this.isReload = false;
								this.templateId = null;
							})
						),
					this.saveTemplate()
				)
			)
		);

	saveTemplate = () =>
		of(this.editor?.content).pipe(
			mergeMap((template) => convertUtil.convertToBase64(template)),
			map((template) =>
				ClientFactFindSettingsMapper.mapDocumentUpload(
					template,
					this.templateId
				)
			),
			concatMap((data) =>
				iif(
					() =>
						R.isNil(data?.documentId) ||
						isNaN(data?.documentId) ||
						data?.documentId === 0,
					this.cffService.newFileUpload(data),
					this.cffService.updateFileUpload(data)
				)
			),
			tap((data) => {
				this.templateId = this.hasTemplate(this.templateId)
					? this.templateId
					: +data;
			}),
			concatMap(() =>
				this.cffService.updateClientFactFind({
					template: this.templateId?.toString(),
					referenceId: 0,
					type: SettingsTypes.MOATClientFactFind,
					mergeTag: [],
				})
			),
			finalize(() => (this.isLoading = false)),
			take(1)
		);

	hasTemplate = (template) =>
		R.complement(R.either(R.isNil, R.isEmpty))(template) &&
		!isNaN(+template) &&
		+template > 0;

	reloadTemplate(event) {
		this.isLoading = true;
		this.cffService
			.getDefaultTemplateUrl()
			.pipe(
				concatMap((url: string) => this.cffService.getDocumentFromURL(url)),
				finalize(() => {
					this.isLoading = false;
					this.isReload = true;
					this.templateId = null;
					this.undoReset$.next(event);
				}),
				take(1)
			)
			.subscribe((data) => {
				if (data) {
					this.currentTemplate = data;
					this.editor.content = data;
				}
			});
	}

	backToEdit() {
		this.currentTemplate = this.tempData;
		this.isPreview = false;
		$(
			`${componentName} .fr-element.fr-view, ${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'unset');
		$(`${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 1,
		});
	}

	showPreview() {
		this.isPreview = true;
		this.tempData = this.editor?.content;
		const getConvertedContent = getContentWithMergeTags(
			this.editor.content,
			this.mergeTag
		);
		$(
			`${componentName} .fr-element.fr-view, ${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'none');
		$(`${componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 0.6,
		});
		this.currentTemplate = getConvertedContent;
	}
}
