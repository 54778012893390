import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	AdviceProcessPageCodes,
	KiwiSaverAdviceProcessPageIds,
} from '@shared/models/advice-process/advice-process.model';
import { CrtKiwisaverCanDeactivateGuard } from './crt-kiwisaver-can-deactivate.guard';
import { CrtKiwiSaverResolver } from './crt-kiwisaver-resolver';
import { CrtKiwiSaverComponent } from './crt-kiwisaver.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { CrtKiwiSaverFactFindResolver } from './fact-find/states/crt-ks-fact-find.resolver';
import { IntroComponent } from './introduction/introduction.component';
import { ScopeOfServicesComponent } from './scope-of-services/scope-of-services.component';

const routes: Routes = [
	{
		path: ':customerType/:clientId/advice-process/:adviceProcessId',
		component: CrtKiwiSaverComponent,
		data: {
			breadcrumb: 'KiwiSaver Online Application Template',
		},
		canActivate: [CrtKiwiSaverFactFindResolver],
		resolve: {
			data: CrtKiwiSaverResolver,
		},
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: KiwiSaverAdviceProcessPageIds.Introduction,
			},
			{
				path: KiwiSaverAdviceProcessPageIds.Introduction,
				component: IntroComponent,
				data: {
					type: AdviceProcessPageCodes.Introduction,
				},
			},
			{
				path: KiwiSaverAdviceProcessPageIds.ScopeOfService,
				component: ScopeOfServicesComponent,
				data: {
					type: AdviceProcessPageCodes.SOS,
				},
				canDeactivate: [CrtKiwisaverCanDeactivateGuard],
			},
			{
				path: KiwiSaverAdviceProcessPageIds.Declaration,
				component: DeclarationComponent,
				data: {
					type: AdviceProcessPageCodes.Declaration,
				},
			},
			{
				path: KiwiSaverAdviceProcessPageIds.Disclosure,
				component: DisclosureComponent,
				data: {
					type: AdviceProcessPageCodes.Disclosure,
				},
				canDeactivate: [CrtKiwisaverCanDeactivateGuard],
			},
			{
				path: KiwiSaverAdviceProcessPageIds.FactFind,
				data: {
					type: AdviceProcessPageCodes.FactFind,
				},
				loadChildren: () =>
					import('./fact-find/crt-ks-fact-find.module').then(
						(m) => m.CrtKiwiSaverFactFindModule
					),
			},
			{
				path: KiwiSaverAdviceProcessPageIds.AdviceSummary,
				data: {
					type: AdviceProcessPageCodes.AdviceSummary,
				},
				loadChildren: () =>
					import('./advice-summary/advice-summary.module').then(
						(m) => m.AdviceSummaryModule
					),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CrtKiwiSaverRoutingModule {}
