import { ActivityUiQuery } from './../states/activity-ui.query';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivityService } from '../states/activity.service';
import { Subject } from 'rxjs';
import { ActivityQuery } from '../states/activity.query';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Metakey } from '../activity-datatable.config';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ActivitySearchModel } from '../states/activity.model';
declare var $: any;

@Component({
	selector: 'app-activity-columns-form',
	templateUrl: './activity-columns-form.component.html',
	styleUrls: ['./activity-columns-form.component.scss'],
})
export class ActivityColumnsFormComponent
	implements OnInit, OnDestroy, AfterViewInit {
	private onDestroy$: Subject<void> = new Subject<void>();

	visibleColumns$ = this.query.tableColumns$;
	hiddenColumns$ = this.query.hiddenTableColumns$;

	visibleColumns: (TableColumn & {
		metakey: Metakey;
		prop: keyof ActivitySearchModel;
	})[];
	hiddenColumns: (TableColumn & {
		metakey: Metakey;
		prop: keyof ActivitySearchModel;
	})[];

	form = new UntypedFormGroup({});
	formInitialized = false;

  isColumnSaving$ = this.activityUiQuery.isColumnSaving$;

	constructor(private service: ActivityService, 
    private query: ActivityQuery, 
    private activityUiQuery: ActivityUiQuery) { }

	ngOnInit() {
		this.visibleColumns$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(res) =>
					(this.visibleColumns = res.filter((x) => x.metakey !== 'Client Name'))
			);
		this.hiddenColumns$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((res) => (this.hiddenColumns = res));
	}

	ngAfterViewInit() {
		$(document).ready(() => {
			$('.disabled-sortable').parent().addClass('disabled d-none');
		});
	}

	prepareFormValue() {
		return [
			'Client Name',
			...this.visibleColumns?.map((x) => x.metakey),
		] as Metakey[];
	}

	save() {
		if (this.visibleColumns.length === 0) {
			return;
		}
		this.service
			.saveVisibleColumns(this.prepareFormValue())
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				() => { },
				() => { },
				() => {
					this.service.closePopup();
				}
			);
	}

	cancel() {
		this.service.closePopup();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
