import { FgInsuranceRequest } from './fg-insurance-request.model';

// This returns an object
export const comparable = (o) =>
	typeof o !== 'object' || !o
		? o
		: Object.keys(o)
			?.sort()
			?.reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

export const comparer = (
	req: FgInsuranceRequest,
	saveReq: FgInsuranceRequest
) => {
	let r = true;

	if (!req || !saveReq) {
		return (r = false);
	}

	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedAdvisers)) !==
		JSON.stringify(comparable(saveReq.SelectedAdvisers))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedAltAdvisers)) !==
		JSON.stringify(comparable(saveReq.SelectedAltAdvisers))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedProviders)) !==
		JSON.stringify(comparable(saveReq.SelectedProviders))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedLeadOrigins)) !==
		JSON.stringify(comparable(saveReq.SelectedLeadOrigins))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedPolicyTypes)) !==
		JSON.stringify(comparable(saveReq.SelectedPolicyTypes))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedPolicyStatus)) !==
		JSON.stringify(comparable(saveReq.SelectedPolicyStatus))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedClaimStatus)) !==
		JSON.stringify(comparable(saveReq.SelectedClaimStatus))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.SelectedAccountStatus)) !==
		JSON.stringify(comparable(saveReq.SelectedAccountStatus))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.NextActivityDateMin)) !==
		JSON.stringify(comparable(saveReq.NextActivityDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.NextActivityDateMax)) !==
		JSON.stringify(comparable(saveReq.NextActivityDateMax))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.RenewalDateMin)) !==
		JSON.stringify(comparable(saveReq.RenewalDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.RenewalDateMax)) !==
		JSON.stringify(comparable(saveReq.RenewalDateMax))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.FirstPolicyDateMin)) !==
		JSON.stringify(comparable(saveReq.FirstPolicyDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.FirstPolicyDateMax)) !==
		JSON.stringify(comparable(saveReq.FirstPolicyDateMax))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.InceptionDateMin)) !==
		JSON.stringify(comparable(saveReq.InceptionDateMin))
	) {
		r = false;
	}
	// tslint:disable-next-line: max-line-length
	if (
		JSON.stringify(comparable(req.InceptionDateMax)) !==
		JSON.stringify(comparable(saveReq.InceptionDateMax))
	) {
		r = false;
	}
	// if (JSON.stringify(this.comparable(req.Paging.Column)) != JSON.stringify(this.comparable(saveReq.Paging.Column))) {
	//   r = false;
	// }
	// if (JSON.stringify(this.comparable(req.Paging.Direction)) != JSON.stringify(this.comparable(saveReq.Paging.Direction))) {
	//   r = false;
	// }

	return r;
};

export const request = (
	filter: any,
	form: FgInsuranceRequest,
	index: number
) => {
	const req = {
		SelectedAdvisers: form.SelectedAdvisers,
		SelectedAltAdvisers: form.SelectedAltAdvisers,
		SelectedProviders: form.SelectedProviders,

		SelectedLeadOrigins: form.SelectedLeadOrigins,
		SelectedPolicyTypes: form.SelectedPolicyTypes,

		SelectedPolicyStatus: form.SelectedPolicyStatus,
		SelectedClaimStatus: form.SelectedClaimStatus,
		SelectedAccountStatus: form.SelectedAccountStatus,
		SelectedAdviserStatuses: form.SelectedAdviserStatuses,
		SelectedPolicyWriters: form.SelectedPolicyWriters,

		NextActivityDateMin: form.NextActivityDateMin,
		NextActivityDateMax: form.NextActivityDateMax,
		RenewalDateMin: form.RenewalDateMin,
		RenewalDateMax: form.RenewalDateMax,

		FirstPolicyDateMin: form.FirstPolicyDateMin,
		FirstPolicyDateMax: form.FirstPolicyDateMax,
		InceptionDateMin: form.InceptionDateMin,
		InceptionDateMax: form.InceptionDateMax,

		Paging: {
			Index: index,
			Column: filter ? filter.Column : null,
			Direction: filter ? filter.Direction : null,
		},
	};
	return req;
};
