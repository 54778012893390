<div class="row soa-template pt-4 px-3">
	<div
		class="col-12 soa-template__froala position-relative"
		[class.view-mode]="!isTapLevel"
	>
		<app-wysiwyg
			#contentEditor
			[id]="'soaEditor'"
			[shortcodes]="shortcodes"
			[content]="currentTemplate"
			[isTapLevel]="isTapLevel"
			[undoReset$]="undoReset$"
			(undoStack)="getUndoStack($event)"
			designClass="pdf-design-v2-froala"
		></app-wysiwyg>

		<div class="row pl-3 mb-2">
			<div class="col-12 col-lg-6 pl-0">
				<ng-container *ngIf="isTapLevel && !isLoading">
					<button
						type="button"
						class="btn btn-primary my-2 tap-bg-primary"
						(click)="reloadTemplate($event)"
						*ngIf="!isPreview"
					>
						RELOAD TEMPLATE
					</button>
				</ng-container>
			</div>
			<div class="col-12 col-lg-6 text-right">
				<ng-container *ngIf="isTapLevel && !isLoading">
					<button
						type="button"
						class="btn btn-primary my-2 mx-1 tap-bg-primary"
						(click)="backToEdit()"
						*ngIf="isPreview"
					>
						BACK TO EDIT
					</button>
					<button
						type="button"
						class="btn btn-primary my-2 mx-1 tap-bg-primary"
						(click)="showPreview()"
						*ngIf="!isPreview"
					>
						PREVIEW
					</button>
					<button
						type="button"
						class="btn btn-primary my-2 ml-1 tap-bg-primary"
						(click)="save($event)"
						*ngIf="!isPreview"
					>
						SAVE
					</button>
				</ng-container>

				<ng-container *ngIf="isLoading">
					<app-manual-loader-mini
						[isLoading]="isLoading"
					></app-manual-loader-mini>
				</ng-container>
			</div>
		</div>
	</div>
</div>
