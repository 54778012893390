<div class="mx-3 pt-3">
  <h5 class="py-1 my-0 font-weight-bold">Summary</h5>
  <hr class="divider" />
  <div class="pt-3" *ngIf="showInitSummary; else showAllSummary">
    <p class="text-danger my-1">{{strictError}}</p>
  </div>
</div>
<ng-template #showAllSummary>
  <div class="pt-3">
    <p class="my-1">
      {{count}} / {{rowCount}} records uploaded
    </p>
    <p class="my-1">
      {{successCount}} success
    </p>
    <p class="my-1">
      {{failCount}} failed
    </p>
    <p class="my-1" *ngIf="startTime">
      Starts at {{startTime}}
    </p>
    <p class="my-1" *ngIf="endTime">
      Ends at {{endTime}}
    </p>
    <p class="text-danger my-1">{{strictError}}</p>
  </div>
</ng-template>

<div class="container-fluid px-0">
  <div class="d-flex justify-content-start mx-3">
    <a *ngIf="summaryLink" class="btn btn-primary mt-2 h-100 mr-3" [href]="summaryLink"
      [appTheme]="{'background-color':'primarycolor'}">EXPORT
      SUMMARY</a>
    <button type="button" class="btn btn-primary my-2 mr-3" (click)="importNew()"
      [appTheme]="{'background-color':'primarycolor'}">IMPORT NEW FILE</button>
  </div>
</div>