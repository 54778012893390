<div class="container-fluid">
	<div class="row">
		<div class="col plus-icon">
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
			>
				<div class="col px-0 form-inline">
					<span class="font-weight-bold px-0 sidebar__title"
						>Total FUM:
						{{ totalFUM | currency }}
					</span>
					<!-- Add New Group -->
					<ng-container *appRestrictTo="['FAS']">
						<button
							type="button"
							[disabled]="isAddNew || isLoading"
							(click)="createModal()"
							id="createKiwisaver"
							class="add-button-sidenav border-0 font-weight-bold md-20 tap-text-color-h6"
						>
							<i class="material-icons add-button-sidenav-plus">add</i>
						</button>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="col-auto float-right px-5 pb-3">
			<div class="row">
				<!-- <ng-container *ngIf="isLoading">
          <div
            class="d-flex flex-row bd-highlight justify-content-end px-2 pt-2"
          >
            <app-manual-loader-mini
              *ngIf="isLoading"
              [isLoading]="isLoading"
            ></app-manual-loader-mini>
          </div>
        </ng-container> -->

				<div *ngIf="isSavingDocument || (documentIsLoading$ | async)">
					<div
						class="d-flex flex-row bd-highlight justify-content-end px-2 pt-2"
					>
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="true"
						></app-manual-loader-mini>
					</div>
				</div>

				<!-- Link Portfolio -->
				<div
					*ngIf="
						!isAddNewGroup &&
						currentLinkedDocument &&
						kiwiSaversAndInvestment?.length > 0 &&
						(!isSavingDocument || !(documentIsLoading$ | async))
					"
				>
					<div class="btn btn-sm document-button-container px-2 mr-1 mb-1">
						<i class="material-icons md-20 document-font-color">description</i>

						<span
							*ngIf="currentLinkedDocument.id"
							class="wrap-document document-font-color cursor-pointer"
							data-toggle="tooltip"
							title="{{
								'Portfolio Schedule - ' +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}"
							(click)="downloadLink(currentLinkedDocument.id)"
							target="_blank"
						>
							<span>{{
								"Portfolio Schedule - " +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}</span>
						</span>

						<div
							class="wrap-document document-font-color"
							*ngIf="!currentLinkedDocument.documentLink"
						>
							<span>{{
								"Portfolio Schedule - " +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}</span>
						</div>

						<ng-container *appRestrictTo="['FES']">
							<button
								type="button"
								class="border-0 document-button p-0 pl-1"
								(click)="unlinkedDocument()"
								[disabled]="isSaving"
								id="ksUnlinkDocument"
							>
								<span class="material-icons pt-1"> close </span>
							</button>
						</ng-container>
					</div>
					<ng-container *appRestrictTo="['FES']">
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm mr-2 mb-1"
							[disabled]="isSaving"
							(click)="linkDocument()"
							id="ksReplaceDocument"
						>
							Replace Portfolio
						</button>
					</ng-container>
				</div>

				<ng-container *appRestrictTo="['FES']">
					<div
						*ngIf="
							!isAddNewGroup &&
							!currentLinkedDocument &&
							kiwiSaversAndInvestment?.length > 0
						"
					>
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm mr-2"
							[disabled]="isAddNewPortfolio && isSaving"
							(click)="linkDocument()"
							id="ksLinkDocument"
						>
							Link Portfolio
						</button>
					</div>
				</ng-container>

				<!-- Show Archive -->
				<ng-container *appRestrictTo="['FVAS']">
					<div *ngIf="!isAddNewGroup && kiwiSaversAndInvestment?.length > 0">
						<label for="showArchive" class="mr-2">Show Archived</label>
						<app-checkbox
							class="clickable d-inline-block"
							style="transform: translate(0px, 4px)"
						>
							<input
								type="checkbox"
								[checked]="!!isShowArchived"
								name="showArchive"
								id="showArchive"
								class="checkbox"
								(change)="showArchived($event.target.checked)"
							/>
						</app-checkbox>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="row next-review-date p-0 m-0 mb-3" [formGroup]="form">
		<div class="col-5 p-0 d-flex align-items-center">
			<label>Next Review:</label>
		</div>
		<div class="col-7 p-0">
			<app-date-input
				textboxId="investmentKSNextReview"
				formControlName="investmentKSNextReview"
				textboxClass="theme-crm-field-disabled"
				placeholder="dd/mm/yyyy"
				style="flex: 1"
				useActionButtons="true"
				[isSavingDate]="isSavingNRD"
				(onSaveDateEvent)="updateReviewDate()"
			>
			</app-date-input>
		</div>
	</div>

	<div class="row main-content">
		<div class="col">
			<ng-container *ngIf="isLoading">
				<app-manual-loader
					[isLoading]="isLoading"
					[opts]="{
						lines: 9,
						length: 27,
						width: 20,
						radius: 45,
						scale: 0.5
					}"
				></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!isLoading">
				<ng-container #outlet [ngTemplateOutlet]="content">
				</ng-container>
				
				<ng-template #content>
					<tabset #sidebarNav class="tap-tabset-sidebar" [vertical]="true">
						<ng-template
							ngFor
							let-ks
							[ngForOf]="kiwiSaversAndInvestment"
							let-i="index"
							[ngForTrackBy]="trackByFn"
						>
							<ng-container
								*ngIf="
									ks?.isActive !== 2 || (ks?.isActive === 2 && isShowArchived)
								"
							>
								<tab
									(selectTab)="selectGroupConfirm($event, ks)"
									id="{{ ks.customerServiceID }}"
									[customClass]="
										ks.color === 'green'
											? 'nav-item--success'
											: ks.color === 'yellow'
											? 'nav-item--warning'
											: ks.color === 'red'
											? 'nav-item--danger'
											: ks.color === 'dark'
											? 'nav-item--dark'
											: ''
									"
								>
									<div class="col">
										<ng-template tabHeading>
											<ng-container
												*ngIf="
													ks.customerServiceID && ks.customerServiceID > 0;
													else tabHeadingAdd
												"
											></ng-container>
											<ng-container
												*ngIf="ks.customerServiceID && ks.customerServiceID > 0"
											>
												<strong class="break-text">{{ fundOwnerName(ks) }}</strong
												><br />
												<span class="font-italic">{{ ks.provider }}</span
												><br />
												<strong>{{ ks.serviceCode === serviceCodes.KiwiSaver ? ksTypes.KiwiSaver : ksTypes.Investment }}</strong>
												<i
													class="material-icons arrow-right md-20 d-flex align-items-center"
													>chevron_right</i
												>
											</ng-container>
										</ng-template>
									</div>
	
									<div class="col px-0 px-md-3 pr-lg-0">
										<div class="container-fluid px-0 px-md-3 pr-lg-0">
											<div *ngIf="isAddNew">
												<ng-container
													*ngIf="
														ks?.serviceCode === serviceCodes.KiwiSaver;
														else showInvesmentFormAdd
													"
												>
													<app-kiwisaver-service-form
														id="kiwisaver_{{ i }}"
														[formId]="
															ks?.customerServiceID && ks?.customerServiceID !== 0
																? i
																: 'add'
														"
														[isLead]="isLead"
														[isCompany]="isCompany"
														[addMode]="true"
														[fundTypes]="fundTypes"
														[statuses]="kstatus"
														[origins]="origins"
														[providers]="providers"
														[fundOwners]="fundOwners"
														[clientsInvolved]="clientsInvolved"
														[types]="types"
														[pirRates]="kPIRRates"
														[groupName]="
															ks.customerServiceID !== 0
																? ks.provider + ': ' + fundOwnerName(ks)
																: 'New Group'
														"
														(saveEvent)="createKs($event)"
														(cancelAddEvent)="cancelAddNewGroup($event)"
														[propertyOwners]="propertyOwners"
														[retentionStatuses]="kretentionStatus"
													>
													</app-kiwisaver-service-form>
												</ng-container>
												<ng-template #showInvesmentFormAdd>
													<app-investment-service-form
														id="kiwisaver_{{ i }}"
														[formId]="
															ks?.customerServiceID && ks?.customerServiceID !== 0
																? i
																: 'add'
														"
														[isLead]="isLead"
														[isCompany]="isCompany"
														[addMode]="true"
														[statuses]="istatus"
														[providers]="iProviders"
														[investmentTypes]="investmentTypes"
														[pirRates]="iPIRRates"
														[clientsInvolved]="clientsInvolved"
														[types]="types"
														[groupName]="
															ks.customerServiceID !== 0
																? ks.provider + ': ' + fundOwnerName(ks)
																: 'New Group'
														"
														(saveEvent)="createKs($event)"
														(cancelAddEvent)="cancelAddNewGroup($event)"
														[propertyOwners]="propertyOwners"
														[retentionStatuses]="iretentionStatus"
													>
													</app-investment-service-form>
												</ng-template>
											</div>
	
											<div *ngIf="!isAddNew">
												<ng-container
													*ngIf="
														ks?.serviceCode === serviceCodes.KiwiSaver;
														else showInvesmentForm
													"
												>
													<app-kiwisaver-service-form
														#kForms
														id="kiwisaver_{{ i }}"
														[isLead]="isLead"
														[isCompany]="isCompany"
														[isSaving]="isSaving"
														[addMode]="false"
														[ks]="ks"
														[fundTypes]="fundTypes"
														[statuses]="kstatus"
														[origins]="origins"
														[providers]="providers"
														[fundOwners]="fundOwners"
														[clientsInvolved]="clientsInvolved"
														[types]="types"
														[pirRates]="kPIRRates"
														[groupName]="fundOwnerName(ks)"
														[formId]="i"
														(saveEvent)="saveKs($event)"
														(deleteEvent)="deleteKs($event)"
														(archiveEvent)="archiveKs($event)"
														(deleteNoteEvent)="deleteNote($event)"
														(addNoteEvent)="addNote($event)"
														[propertyOwners]="propertyOwners"
														[retentionStatuses]="kretentionStatus"
													>
													</app-kiwisaver-service-form>
												</ng-container>
												<ng-template #showInvesmentForm>
													<app-investment-service-form
														#iForms
														id="kiwisaver_{{ i }}"
														[isLead]="isLead"
														[isCompany]="isCompany"
														[isSaving]="isSaving"
														[addMode]="false"
														[investment]="ks"
														[statuses]="istatus"
														[providers]="iProviders"
														[clientsInvolved]="clientsInvolved"
														[investmentTypes]="investmentTypes"
														[pirRates]="iPIRRates"
														[types]="types"
														[groupName]="fundOwnerName(ks)"
														[formId]="i"
														(saveEvent)="saveKs($event)"
														(deleteEvent)="deleteKs($event)"
														(archiveEvent)="archiveKs($event)"
														(deleteNoteEvent)="deleteNote($event)"
														(addNoteEvent)="addNote($event)"
														[propertyOwners]="propertyOwners"
														[retentionStatuses]="iretentionStatus"
													>
													</app-investment-service-form>
												</ng-template>
											</div>
										</div>
									</div>
								</tab>
							</ng-container>
						</ng-template>
					</tabset>
				</ng-template>

			</ng-container>
		</div>
	</div>
</div>

<ng-template #tabHeadingAdd>
	<strong
		>Add {{ isAddNewKs ? ksTypes?.KiwiSaver : ksTypes?.Investment }}</strong
	><br />
	<i class="material-icons arrow-right md-20 d-flex align-items-center"
		>chevron_right</i
	>
</ng-template>
