<div class="app-user-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">KiwiSaver Online Advice Tool</h1>
	</div>

	<div class="row tap-container service-tabs crt-settings">
		<div class="col">
			<tabset [appTheme]="{ color: 'primarycolor' }">
				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Intro Page">
						<app-kiwi-intro-settings></app-kiwi-intro-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Scope of Service">
						<app-kiwi-scope-of-service-settings></app-kiwi-scope-of-service-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Declaration">
						<app-declaration-settings></app-declaration-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Fact Find">
						<app-fact-find-settings></app-fact-find-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'BO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="Advice Summary">
						<app-advice-summary-settings></app-advice-summary-settings>
					</tab>
				</ng-container>

				<ng-container
					*ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)"
				>
					<tab heading="End Process">
						<app-end-process></app-end-process>
					</tab>
				</ng-container>
			</tabset>
		</div>
	</div>
</div>
