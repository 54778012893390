import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-crt-banner',
	templateUrl: './crt-banner.component.html',
	styleUrls: ['./crt-banner.component.scss'],
})
export class CrtBannerComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Input() headerText: string;
	@Input() bgUrl: string;
	@Input() showProgress: boolean;
	@Input() progressValue$: Observable<number>;

	constructor() {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
