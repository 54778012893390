import {
	Component,
	OnInit,
	OnDestroy,
	Output,
	EventEmitter,
	Renderer2,
	TemplateRef,
	AfterViewInit,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	UntypedFormControl,
	AbstractControl,
} from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import {
	takeUntil,
	map,
	withLatestFrom,
	first,
	finalize,
	take,
	tap,
	mergeMap,
} from 'rxjs/operators';
import { KiwisaverService } from '../states/kiwisaver.service';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../../util/moment.util';
import {
	KiwisaverRequest,
	KiwisaverRequestModel,
} from '../kiwisaver-request.model';
import { KiwisaverQuery } from '../states/kiwisaver.query';
import { UserQuery } from '../../../../../domain/user/user.query';
import { RecursivePartial } from '../../../../../core/util/util.service';
import { ActivatedRoute } from '@angular/router';
import { strUtil } from '../../../../../util/util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { KiwisaverUiQuery } from '../states/kiwisaver-ui.query';
import { request } from '../kiwisaver.util';
import { KiwisaverUiStore } from '../states/kiwisaver-ui.store';
import { ChipsComponent } from '../../../../../shared/chips/chips.component';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { AdviserStatusViewDisplay } from '../../pipeline.model';
import { KiwiSaverTypes } from 'src/app/shared/models/services/kiwisaver/kiwisaver.model';
import {
	ExportsStatus,
	ExportsType,
} from '@modules/exports/state/exports.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsService } from '@modules/exports/state/exports.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
declare var $: any;

const patchValue: <T>(
	form: AbstractControl,
	value: RecursivePartial<T>
) => void = (form, value) => {
	form.patchValue(value);
};

@Component({
	selector: 'app-kiwisaver-form',
	templateUrl: './kiwisaver-form.component.html',
	styleUrls: ['./kiwisaver-form.component.scss'],
})
export class KiwisaverFormComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$: Subject<void> = new Subject<void>();

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	exportStatus$ = this.exportsQuery.status$;

	isSearching$ = this.kiwisaverQuery.uiQuery.isSearching$;
	isExporting$ = this.kiwisaverQuery.uiQuery.isExporting$;
	columnFromOpen$ = this.kiwisaverQuery.uiQuery.columnFormPopupOpen$;

	openColumnPopup = this.kiwisaverService.togglePopup;

	advisers$: Observable<ViewDisplayValue[]> =
		this.bLStaffsQuery.KsAdviserChoicesOption$;
	AdviserStatus$: Observable<ViewDisplayValue[]> = of(AdviserStatusViewDisplay);

	KP$ = this.kiwisaverService.KP$;
	KT$ = this.kiwisaverService.KT$;
	KS$ = this.kiwisaverService.KS$;
	PCLE$ = this.kiwisaverService.PCLE$;
	KO$ = this.kiwisaverService.KO$;

	isTapLevel$ = this.userQuery.isTapLevel$;
	userInfo$ = this.userQuery.userInfo$;
  adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

	// tslint:disable-next-line: no-angle-bracket-type-assertion
	form: UntypedFormGroup = this.fb.group(<
		{ [key in keyof KiwisaverRequestModel]: UntypedFormControl }
	>{
		SelectedAdvisers: this.fb.control([]),
		SelectedAltAdvisers: this.fb.control([]),
		SelectedAdviserStatuses: this.fb.control([]),
		SelectedProviders: this.fb.control([]),

		SelectedStatus: this.fb.control([]),
		SelectedLeadOrigins: this.fb.control([]),

		SelectedOrigins: this.fb.control([]),

		NextActivityDateMin: this.fb.control(null),
		NextActivityDateMax: this.fb.control(null),
		StartDateMin: this.fb.control(null),
		StartDateMax: this.fb.control(null),
		NextReviewDateMin: this.fb.control(null),
    NextReviewDateMax: this.fb.control(null),
	});

	count$ = this.kiwisaverQuery.count$;
	totalApi$ = this.kiwisaverQuery.totalApi$;
	@Output() toggleSearch = new EventEmitter<{
		showSearch: boolean;
		height: number;
		width: number;
	}>();
	showSearch = false;
	isTapLevel: boolean;
	params$ = this.route.paramMap.pipe(takeUntil(this.onDestroy$));

	////////// Progress Bar //////////////
	progress = {
		width: '0%',
	};
	index = 0;
	percent = 0;
	count = 0;
	totalCount = 0;
	modalRef: BsModalRef;
	msg = 'Export Progress';
	///////// END Progress Bar ////////

	@ViewChild('selectedStatus') selectedStatus: ChipsComponent;
	@ViewChild('SelectedAdvisers') SelectedAdvisers: ChipsComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private kiwisaverService: KiwisaverService,
		private kiwisaverQuery: KiwisaverQuery,
		private userQuery: UserQuery,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private kiwisaverUiQuery: KiwisaverUiQuery,
		private bLStaffsQuery: BLStaffsQuery,
		private exportsQuery: ExportsQuery,
		private exportsService: ExportsService,
    private businessConfigQuery: BusinessConfigQuery
	) {}

	ngOnInit() {
		this.params$
			.pipe(
				withLatestFrom(this.isTapLevel$, this.userInfo$, this.KS$),
				map(([params, isTapLevel, , ks]) => {
					this.isTapLevel = isTapLevel;
					const adv = params.get('advisers');
					const stat = params.get('statuses');
					if (params && (adv || stat)) {
						const req = JSON.parse(JSON.stringify(this.prepareFormValue()));
						this.kiwisaverService.setSearchForm({
							...req,
							SelectedAdvisers: !!adv ? adv?.split(',') : [],
							SelectedStatuses: strUtil.stringIsNotEmpty(stat)
								? stat?.split(',').filter((x) => ks?.find((y) => y.value === x))
								: [],
						});
						return {
							hasStatus: stat ? true : false,
							SelectedAdvisers: strUtil.stringIsNotEmpty(adv)
								? adv?.split(',')
								: [],
							SelectedStatus: strUtil.stringIsNotEmpty(stat)
								? stat?.split(',').filter((x) => ks?.find((y) => y.value === x))
								: [],
						};
					} else if (!isTapLevel) {
						const policyStatusList = ['Lead', 'Submitted', 'Info Required'];
						return {
							SelectedStatus: policyStatusList?.filter((x) =>
								ks?.find((y) => y.value === x)
							),
						};
					} else {
						const policyStatusList = ['Lead', 'Submitted', 'Info Required'];
						return {
							SelectedStatus: policyStatusList?.filter((x) =>
								ks?.find((y) => y.value === x)
							),
						};
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe((fValue) => {
				this.reset();
				if (fValue) {
					patchValue(this.form, fValue);
					if (this.isTapLevel) {
						this.kiwisaverService?.sort('Status', 'desc');
					} else {
						this.kiwisaverService?.sort('ClientNextActivity', 'desc');
					}
					if (fValue && fValue.hasStatus) {
						this.search();
					}
				}
			});
	}

	ngAfterViewInit() {
		this.SelectedAdvisers.onFocus();
		this.selectedStatus.onFocus();

		if (
			this.kiwisaverQuery.getValue().count > 0 ||
			this.kiwisaverQuery.getValue().searchForm
		) {
			const advisers =
				this.kiwisaverQuery.getValue().searchForm.SelectedAdvisers;
			const altAdvisers =
				this.kiwisaverQuery.getValue().searchForm.SelectedAltAdvisers;
			const p = this.kiwisaverQuery.getValue().searchForm.SelectedProviders;
			const lo = this.kiwisaverQuery.getValue().searchForm.SelectedLeadOrigins;
			const as =
				this.kiwisaverQuery.getValue().searchForm.SelectedAdviserStatuses;

			const naMin =
				this.kiwisaverQuery.getValue().searchForm.NextActivityDateMin;
			const naMax =
				this.kiwisaverQuery.getValue().searchForm.NextActivityDateMax;
			const sMin = this.kiwisaverQuery.getValue().searchForm.StartDateMin;
			const sMax = this.kiwisaverQuery.getValue().searchForm.StartDateMax;

			const nrMin =
				this.kiwisaverQuery.getValue().searchForm.NextReviewDateMin;
			const nrMax =
				this.kiwisaverQuery.getValue().searchForm.NextReviewDateMax;

			this.form
				.get('SelectedAdvisers')
				.setValue(advisers?.map((x) => x?.toString()));
			this.form
				.get('SelectedAltAdvisers')
				.setValue(altAdvisers?.map((x) => x?.toString()));
			this.form.get('SelectedProviders').setValue(p?.map((x) => x?.toString()));
			this.form
				.get('SelectedLeadOrigins')
				.setValue(lo?.map((x) => x?.toString()));
			this.form
				.get('SelectedAdviserStatuses')
				.setValue(as?.map((x) => x?.toString()));

			this.form
				.get('NextActivityDateMin')
				.setValue(MomentUtil.formatDateToMoment(naMin));
			this.form
				.get('NextActivityDateMax')
				.setValue(MomentUtil.formatDateToMoment(naMax));
			this.form
				.get('StartDateMin')
				.setValue(MomentUtil.formatDateToMoment(sMin));
			this.form
				.get('StartDateMax')
				.setValue(MomentUtil.formatDateToMoment(sMax));

			this.form
				.get('NextReviewDateMin')
				.setValue(MomentUtil.formatDateToMoment(nrMin));
			this.form
				.get('NextReviewDateMax')
				.setValue(MomentUtil.formatDateToMoment(nrMax));
		}
	}

	public prepareFormValue(): KiwisaverRequest {
		const formValue: formType = this.form.value;

		const req: KiwisaverRequest = {
			...formValue,
			SelectedAdvisers: formValue.SelectedAdvisers?.map((x) => +x),
			SelectedAltAdvisers: formValue.SelectedAltAdvisers?.map((x) => +x),
			SelectedProviders: formValue.SelectedProviders?.map((x) => x),
			SelectedStatus: formValue.SelectedStatus?.map((x) => x),
			SelectedLeadOrigins: formValue.SelectedLeadOrigins?.map((x) => x),
			SelectedTypes: [KiwiSaverTypes.KiwiSaver], // TAPNZ-9518 Strictly KiwiSaver only

			NextActivityDateMin: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMin
			),
			NextActivityDateMax: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMax
			),
			StartDateMin: MomentUtil.formatToServerDate(formValue.StartDateMin),
			StartDateMax: MomentUtil.formatToServerDate(formValue.StartDateMax),
			NextReviewDateMin: MomentUtil.formatToServerDate(formValue.NextReviewDateMin),
			NextReviewDateMax: MomentUtil.formatToServerDate(formValue.NextReviewDateMax),
		};
		return req;
	}

	search() {
		const req = JSON.parse(JSON.stringify(this.prepareFormValue()));

		this.kiwisaverUiQuery.currentSort$
			.pipe(
				first(),
				map((x) => {
					req.Paging = {
						Index: 1,
						Column: x.propSort,
						Direction: x.sort,
					};
				}),
				finalize(() => {
					this.kiwisaverService
						.search(req)
						.pipe(takeUntil(this.onDestroy$))
						.subscribe(() => {
							setTimeout(
								() => $('datatable-body').scrollTop(1).scrollLeft(1),
								1
							);
							setTimeout(
								() => $('datatable-body').scrollTop(0).scrollLeft(0),
								1
							);
						});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	/**
	 * Api calls (Endpoint)
	 * @returns void
	 * Downloads file in .csv format
	 */
	//////////////////////////////////// EXPORT /////////////////////////////////
	openModal(exportTemplate: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			exportTemplate,
			Object.assign(
				{},
				{
					class: 'modal-dialog-centered gray modal-xl w-50',
					ignoreBackdropClick: true,
				}
			)
		);
	}

	export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		this.index++;
		const req = request(
			this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
			this.prepareFormValue(),
			this.index
		);

		const newReq = {
			Advisers: req?.SelectedAdvisers || [],
			Providers: req?.SelectedProviders || [],
			Status: req?.SelectedStatus || [],
			Types: req?.SelectedTypes || [],
			AdviserStatuses: req?.SelectedAdviserStatuses || [],
			LeadOrigins: req?.SelectedLeadOrigins || [],
			Origins: req?.SelectedOrigins || [],
			AltAdvisers: req?.SelectedAltAdvisers || [],
			NextActivityDateMin: req?.NextActivityDateMin || '',
			NextActivityDateMax: req?.NextActivityDateMax || '',
			StartDateMin: req?.StartDateMin || '',
			StartDateMax: req?.StartDateMax || '',
			NextReviewDateMin: req?.NextReviewDateMin || '',
			NextReviewDateMax: req?.NextReviewDateMax || '',
		};

		this.exportsService
			.queueExport(newReq, ExportsType.KSE)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(
						this.exportsService.getExportStatus(id)
					)
				)
			)
			.subscribe({
				next: (data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport(data.DocumentLink);
						this.hidePopover();
					}
				},
				error: () => {
					this.hidePopover();
				},
			});

		setTimeout(() => {
			this.popover.hide();
		}, 2500);
	}

	computeProgess() {
		this.percent = +((this.index * 100) / this.totalCount).toFixed(2);
		this.progress = {
			width: this.percent + '%',
		};
	}

	downloadExport(url: string) {
		const name =
			this.route.snapshot.paramMap.get('companyCode') + '-KiwiSaver.csv';

		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	clearExportProgress() {
		this.index = 0;
		this.percent = 0;
		this.progress = {
			width: '0%',
		};
		this.totalCount = 0;
	}
	//////////////////////////////////// END EXPORT /////////////////////////////////

	toggle() {
		this.showSearch = !this.showSearch;
		document.body.style.overflowY = 'hidden';
		let x = 0;
		let data;
		const setIntervalHeight = setInterval(() => {
			if (x < 1 && !data) {
				x += 1;
				data = {
					showSearch: this.showSearch,
					height: $('form').height(),
					width: $('form').width(),
				};
			} else if (x > 0) {
				x += 1;
				if (data.height !== $('form').height()) {
					data.height = $('form').height();
				} else {
					this.toggleSearch.emit(data);
					clearInterval(setIntervalHeight);
				}
			}
		}, 100);
	}

	reset() {
		this.form.get('SelectedAdvisers').reset([]);
		this.form.get('SelectedAltAdvisers').reset([]);
		this.form.get('SelectedAdviserStatuses').reset([]);
		this.form.get('SelectedProviders').reset([]);
		this.form.get('SelectedStatus').reset([]);
		this.form.get('SelectedLeadOrigins').reset([]);
		this.form.get('SelectedOrigins').reset([]);

		this.form.get('NextActivityDateMin').reset(null);
		this.form.get('NextActivityDateMax').reset(null);
		this.form.get('StartDateMin').reset(null);
		this.form.get('StartDateMax').reset(null);

		this.form.get('NextReviewDateMin').reset(null);
    this.form.get('NextReviewDateMax').reset(null);
	}

	showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

type formType = { [key in keyof KiwisaverRequestModel]: any };
