import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { KiwiSaverInfo } from 'src/app/shared/models/client-review-template/assets-liabilities/kiwiSaver/kiwiSaver.model';
import { KiwiSaverMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/kiwiSaver/kiwiSaver.mapper';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { BusinessService } from '@core/business/business.service';

@Component({
	selector: 'app-kiwisaver-form',
	templateUrl: './kiwisaver-form.html',
	styleUrls: ['./kiwisaver-form.scss'],
})
export class KiwiSaverFormComponent implements OnInit {
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() provider: ViewDisplayValue[];
	@Input() riskProfile: ViewDisplayValue[];
	@Input() lifeAssuredList: ViewDisplayValue[];
	@Input() kiwiSaverInfo: KiwiSaverInfo;
	@Input() fundType: ViewDisplayValue[];
	@Input() fromCRM = false;
	@Input() isLoading: boolean;
	@Input() viewMode: boolean;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;
	isLoadingBtn = false;

	cancelLoading: boolean;
	enableLoatV2$: Observable<boolean>;

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService,
		private businessService: BusinessService
	) {
		this.enableLoatV2$ = this.businessService.loatv2Enabled$;
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();
	}

	prepareData() {
		of(this.kiwiSaverInfo)
			.pipe(
				map((x) => KiwiSaverMapper.mapToView(x)),
				tap((x) => this.form.reset(x)),
				tap(() => {
					if (this.viewMode) {
						this.form.disable();
					} else {
						this.disableFields()
					}
				}),
				take(1)
			)
			.subscribe();
	}

	get CurrentBalance() {
		return this.form.get('currentBalance');
	}
	get Provider() {
		return this.form.get('provider');
	}
	get Owners() {
		return this.form.get('owner');
	}
	get RiskProfile() {
		return this.form.get('riskProfile');
	}
	get FundType() {
		return this.form.get('fundType');
	}

	buildForm() {
		this.form = this.fb.group({
			owner: ['', Validators.required],
			currentBalance: ['', Validators.required],
			provider: ['', Validators.required],
			fundType: [''],
			contributionRate: [''],
			riskProfile: [''],
		});
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.lifeAssuredList);
	}

	disableFields() {
		if (this.fromCRM) {
			this.Owners.disable();
			this.Provider.disable();
			this.FundType.disable();
		} else {
			this.Owners.enable();
			this.Provider.enable();
			this.FundType.enable();
		}
	}

	saveBtnClick() {
		if (this.isLoading || this.cancelLoading) {
			return;
		}
		this.submitted = true;
		this.isLoadingBtn = true;
		if (this.form.invalid) {
			this.loggerService.Warning(null, logMessage.shared.general.warning.required);
			this.isLoadingBtn = false;
			return;
		}
		const value = this.form.getRawValue();
		this.saveEvent.emit(
			!!this.kiwiSaverInfo
				? {
						...value,
						cRTId: this.kiwiSaverInfo.CRTId,
						customerServiceID: this.kiwiSaverInfo.CustomerServiceID,
				  }
				: value
		);
		this.isLoadingBtn = false;
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => this.cancelLoading = true, 500);
	}
}
