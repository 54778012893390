import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { MortgageUiState, MortgageUiStore } from './mortgage-ui.store';

@Injectable()
export class MortgageUiQuery extends Query<MortgageUiState> {
  isSearching$ = this.select(x => x.isSearching);
  isExporting$ = this.select(x => x.isExporting);
  columnFormPopupOpen$ = this.select(x => x.columnFormPopupOpen);
  isColumnSaving$ = this.select(x => x.isColumnSaving);

  propSort$ = this.select(x => x.propSort);
  sort$ = this.select(x => x.sort);

  currentSort$ = this.select(x => x.currentSort);

  constructor(protected mortgageUiStore: MortgageUiStore) {
    super(mortgageUiStore);
  }
}
