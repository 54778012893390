<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
    close
  </span>
</button>
<div class="clearfix">
  <div class="modal-header">
    <h1 class="modal-title">
      {{ header }}
    </h1>
  </div>
  <div>
    <form novalidate [formGroup]="form">
      <div class="form-group m-0">
        <div class="clearfix">
          <textarea id="textareamodal" class="form-control" cols="30" rows="6" formControlName="textareamodal"></textarea>
        </div>
      </div>

      <div class="justify-content-end float-right modal-footer">
        <ng-container *ngIf="!isSaving; else loader">
          <button id="saveButton" type="button" class="tap-btn tap-btn--default modal-btn-confirm" (click)="save()">Save</button>
        </ng-container>
        <ng-template #loader>
          <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
        </ng-template>
      </div>
    </form>
  </div>
</div>
