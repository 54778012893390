import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import produce from 'immer';
import { applyTransaction } from '@datorama/akita';
import { ApiVsSubmittedYearStat } from './asry.model';
import { ApiVsSubmittedYearStatsStore } from './asry.store';


@Injectable()
export class ApiVsSubmittedYearStatsService {

  private readonly endpoint = 'widgets?type=ASRY';

  constructor(
    private api: ApiService,
    private store: ApiVsSubmittedYearStatsStore
  ) { }

  /**
   * Fetch and store API Vs Submitted Stats data for Rolling 12 Months (Year)
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetApiVsSubmittedYearStats(adviserIdList: string[]): void {
    this.store.reset();
    this.store.setLoading(true);

    this.api.post<ApiVsSubmittedYearStat>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
        res => this.store.update(produce(draft => {
          draft.ApiVsSubmittedYearStats = res;
        })),
        err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
        () => this.store.setLoading(false)
      );
  }
}
