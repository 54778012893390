<div class="p-2 pt-4">
	<form [formGroup]="form" class="form">
		<div class="row pb-0 pl-3 pb-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-lg-6 col-12">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="whyKiwiSaver"
									formControlName="whyKiwiSaver"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label for="whyKiwiSaver" class="form-check-label"
									>Why KiwiSaver?</label
								>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="howKiwiSaverWorks"
									formControlName="howKiwiSaverWorks"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label for="howKiwiSaverWorks" class="form-check-label"
									>How KiwiSaver Works</label
								>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-12">

						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="investmentStrategy"
									formControlName="investmentStrategy"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label for="investmentStrategy" class="form-check-label"
									>Investment Strategy</label
								>
							</div>
						</div>

						<!-- <div class="row scope-of-service__services"> -->
						<!-- 	<div class="col-1 scope-of-service__services__checkbox"> -->
						<!-- 		<input -->
						<!-- 			type="checkbox" -->
						<!-- 			class="chckbox form-control theme-crm-field-disabled" -->
						<!-- 			id="passiveVsActive" -->
						<!-- 			formControlName="passiveVsActive" -->
						<!-- 		/> -->
						<!-- 	</div> -->
						<!-- 	<div class="col-10 pt-1 pl-2 scope-of-service__services__label"> -->
						<!-- 		<label for="passiveVsActive" class="form-check-label" -->
						<!-- 			>Passive vs. Active</label -->
						<!-- 		> -->
						<!-- 	</div> -->
						<!-- </div> -->

						<!-- <div class="row scope-of-service__services"> -->
						<!-- 	<div class="col-1 scope-of-service__services__checkbox"> -->
						<!-- 		<input -->
						<!-- 			type="checkbox" -->
						<!-- 			class="chckbox form-control theme-crm-field-disabled" -->
						<!-- 			id="riskVsVolatility" -->
						<!-- 			formControlName="riskVsVolatility" -->
						<!-- 		/> -->
						<!-- 	</div> -->
						<!-- 	<div class="col-10 pt-1 pl-2 scope-of-service__services__label"> -->
						<!-- 		<label for="riskVsVolatility" class="form-check-label" -->
						<!-- 			>Risk vs. Volatility</label -->
						<!-- 		> -->
						<!-- 	</div> -->
						<!-- </div> -->

					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 mb-4">
			<div class="col-12 col-lg-7 text-right">
				<app-manual-loader-mini
					[isLoading]="isLoading"
				></app-manual-loader-mini>
				<button
					type="button"
					class="btn btn-primary my-2 ml-1"
					[appTheme]="{ 'background-color': 'primarycolor' }"
					(click)="save()"
					*ngIf="!isLoading"
				>
					SAVE
				</button>
			</div>
		</div>
	</form>
</div>
