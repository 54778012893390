<div class="calculator-results container mb-20">
	<mat-expansion-panel
		(opened)="panelOpenState = true"
		(closed)="panelOpenState = false"
		hideToggle="true"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
				<span class="header-text w-100">Assumptions</span></mat-panel-title
			>
			<mat-panel-description class="mr-0">
				<span class="header-toggle w-100 text-right">
					<i class="material-icons md-16 pt-1">
						{{ panelOpenState ? "close" : "add" }}
					</i>
				</span>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<div class="form-row mb-2 calculator-results__wordings">
			<p>
				These graphs are for illustrative purposes only to help you understand
				how different investment choices may affect KiwiSaver savings. The
				figures and data may not reflect actual returns and balances. The
				calculator assumes a minimum starting salary of $35,000. Please note
				that returns may be negative for any given period and will fluctuate due
				to investment and other risks. This includes the risk of not getting
				back all of the money that you put in.
			</p>
			<p>
				The figures used in these graphs are based on assumptions consistent
				with those used on sorted.org.nz and are as follows:
			</p>
			<ul>
				<li>
					There is the option to have the balances adjusted to take into account
					inflation. This is based on a rate of 2% so that results are shown in
					today's dollars.
				</li>
				<li>
					Employer contributions of 3% of the stated before-tax salary are taken
					into account after deduction of employer's superannuation contribution
					tax at the current applicable rates.
				</li>
				<li>
					Government annual contributions are applied based on the current
					contribution of 50 cents for every dollar of member contributions up
					to a maximum of $1,042.86 per annum.
				</li>
				<li>
					Salaries will increase by 3.5% each year (1.5% increase plus 2% for
					inflation).
				</li>
				<li>You do not take any savings suspensions.</li>
				<li>No amounts are withdrawn for any purpose.</li>
				<li>
					The after-fee returns used are based on the sorted.org.nz KiwiSaver
					Calculator.
				</li>
				<li>
					The fees used in the calculation are based on the 5 year average fee
					for each fund type as a percentage from sorted.org.nz.
				</li>
				<li>
					All calculations are based on annual member and employer
					contributions.
				</li>
				<li>
					PIR rate used assumes 2 consecutive years of the current annualised
					salary.
				</li>
				<li>
					All figures are based on a PIR rate of 28%. The assumed rate of return
					used for each fund type choice is outlined below. These returns are
					after all fees and taxes.
				</li>
			</ul>
		</div>
		<div class="form-row mb-2 calculator-results__assumption-table">
			<table class="mb-2">
				<thead>
					<tr>
						<th>FUND TYPE</th>
						<th>INVESTMENT RETURN (PER ANNUM)</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td style="width: 50%">Defensive</td>
						<td style="width: 50%">{{ InvestmentReturnData.defensive }}%</td>
					</tr>
					<tr>
						<td style="width: 50%">Conservative</td>
						<td style="width: 50%">{{ InvestmentReturnData.conservative }}%</td>
					</tr>
					<tr>
						<td style="width: 50%">Balanced</td>
						<td style="width: 50%">{{ InvestmentReturnData.balanced }}%</td>
					</tr>
					<tr>
						<td style="width: 50%">Growth</td>
						<td style="width: 50%">{{ InvestmentReturnData.growth }}%</td>
					</tr>
					<tr>
						<td style="width: 50%">Aggressive</td>
						<td style="width: 50%">{{ InvestmentReturnData.aggressive }}%</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="form-row mb-2 calculator-results__footer">
			<p>
				<em>
					To be eligible, you must be 18 years or older and mainly live in New
					Zealand. If you don't meet these requirements, the maximum annual
					government contribution you'll get will be based on the time you are
					eligible.
				</em>
			</p>
		</div>
	</mat-expansion-panel>
</div>
