import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	CrtKiwiSaverRiskProfileQuestionOutcome,
	RiskProfileOutcomesStore,
} from './risk-profile-outcomes.store';

@Injectable({ providedIn: 'root' })
export class CrtKiwiSaverRiskProfileOutcomeQuery extends Query<CrtKiwiSaverRiskProfileQuestionOutcome> {
	constructor(protected store: RiskProfileOutcomesStore) {
		super(store);
	}
}
