import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	finalize,
	map,
	take,
	tap,
} from 'rxjs/operators';
import { ApiService } from '../../../../../../core/base/api.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { ServiceAdviceProcessState } from '../../../../../../shared/models/advice-process/advice-process.model';
import { objectUtil } from '../../../../../../util/util';
import { CrtMortgageQuery } from '../crt-mortgage.query';
import { CrtMortgageService } from '../crt-mortgage.service';
import { CrtMortgageStore } from '../crt-mortgage.store';

@Injectable()
export class MortgageAdviceProcessService extends CrtMortgageService {
	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	getAdviceProcess(adviceProcessId): Observable<ServiceAdviceProcessState> {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			map((data) => (data ? objectUtil.mapPascalCaseToCamelCase(data) : null)),
			tap((data) =>
				applyTransaction(() => {
					this.store.setAdviceProcess(data);
					this.store.setMortApPageCompleted(data?.pageCompleted || []);
					this.store.setMortApPageStarted(data?.pageStarted || []);
				})
			),
			catchError(() => of(null))
		);
	}

	updateMortApPageStarted(data) {
		const pageStarted = [...(this.query.getValue().mortApPageStarted || [])];
		if (pageStarted.includes(data)) {
			return EMPTY;
		} else {
			pageStarted.push(data);
			const body = objectUtil.mapCamelCaseToPascalCase({
				referenceID: +this.query.getValue().adviceProcessId,
				secondaryReferenceID: 0,
				key: 'PageStarted',
				value: JSON.stringify(pageStarted || []),
			});
			const endpoint = `adviceProcesses`;

			return this.api.patch<any>(endpoint, [body]).pipe(
				tap((x) =>
					applyTransaction(() => {
						this.store.setMortApPageStarted(pageStarted);
					})
				),
				catchError(() => EMPTY)
			);
		}
	}

	updateMortApPageCompleted(data) {
		const body = objectUtil.mapCamelCaseToPascalCase({
			referenceID: +this.query.getValue().adviceProcessId,
			secondaryReferenceID: 0,
			key: 'PageCompleted',
			value: JSON.stringify(data || []),
		});
		const endpoint = `adviceprocesses`;

		return this.api.patch<any>(endpoint, [body]).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setMortApPageCompleted(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateAdviceProcess(id: number, type: string) {
		const adviceProcess = this.query.getValue().adviceProcess;
		return this.updateAP(adviceProcess.adviceProcessID, id, type).pipe(take(1));
	}

	updateAP(adviceProcessId: number, documentId: number, type: string) {
		const endpoint = `adviceprocesses/${adviceProcessId}/document/${documentId}/${type}`;
		return this.api.put<string>(endpoint).pipe(
			concatMap((updateAPres) =>
				this.getAdviceProcess(adviceProcessId).pipe(map(() => updateAPres))
			),
			catchError(() => EMPTY)
		);
	}

	/*
	 * State Update
	 */
	setMortApPageCompletedState(b: any[]) {
		applyTransaction(() => {
			this.store.setMortApPageCompleted(b);
		});
	}

	setMortApPageStartedState(b: any[]) {
		applyTransaction(() => {
			this.store.setMortApPageStarted(b);
		});
	}

	updateMortgageAdviceProcess(data) {
		this.setMortApUpdateLoading(true);
		const body = objectUtil.mapCamelCaseToPascalCase({
			...data,
			pageStarted: this.query.getValue().mortApPageStarted ?? data?.pageStarted,
			pageCompleted:
				this.query.getValue().mortApPageCompleted ?? data?.pageCompleted,
		});

		const endpoint = `adviceprocesses/${body.AdviceProcessID}`;
		return this.api.put<string>(endpoint, body).pipe(
			catchError(() => EMPTY),
			finalize(() =>
				this.getAdviceProcess(body.AdviceProcessID)
					.pipe(
						finalize(() => this.setMortApUpdateLoading(false)),
						take(1)
					)
					.subscribe()
			)
		);
	}

	setMortApUpdateLoading(b: boolean) {
		applyTransaction(() => {
			this.store.setMortApIsLoading(b);
		});
	}
}
