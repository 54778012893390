import { CriticalIllnessState, DropdownState } from './critical-illness.model';

export class CriticalIllnessMapper {
  /**
   * Map to update
   */
  public static mapToUpsert(data: CriticalIllnessState) {
    return {
      ...data,
      periodOfCoverList: data.periodOfCoverList?.map(
        (item) =>
          // tslint:disable-next-line: no-angle-bracket-type-assertion
          item?.dropdown ? <DropdownState> {
            dropdown: item.dropdown,
            value: +item.value,
          } : null
      )?.filter(x => x),
      periodOfPartnerOffList: data.periodOfPartnerOffList?.map(
        (item) =>
          // tslint:disable-next-line: no-angle-bracket-type-assertion
          <DropdownState> {
            dropdown: item.dropdown,
            value: +item.value,
          }
      ),
      extraCostsList: data.extraCostsList?.map(
        (item) =>
          // tslint:disable-next-line: no-angle-bracket-type-assertion
          <DropdownState> {
            // tslint:disable-next-line: max-line-length
            dropdownValue: item.dropdown === 'Other' ? item.dropdownValue : '',
            dropdown: item.dropdown,
            value: +item.value,
          }
      ),
      totalRequired: +data.totalRequired,
      status: 1,
      sectionCode: 'RACI',
    };
  }
}
