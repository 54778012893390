import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, Router } from '@angular/router';
import { forEach, isNil } from 'ramda';
import { Observable, Subscription } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { RouteService } from 'src/app/core/config/route.service';
import { Sidebar } from '../../models/sidebar.model';
declare var $: any;

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
	@Input() currentPageId = '';
	@Input() sidebar: Sidebar[];
	@Input() manualLoading: boolean;
	@Input() callbackFn$: (
		data,
		callbackType?: 'next' | 'previous'
	) => Observable<any>;
	@Input() pageStartedFn$: (data) => Observable<any>;
	@Input() disabledNext: boolean;
	@Input() disabledPrev: boolean;
	@Input() nextText = 'Next';
	@Input() prevText = 'Previous';
	@Input() ignoreNextAndPrevPage: boolean;
	@Input() previous$: () => Observable<any>;

	@Input() nextFn$: () => Observable<boolean>;

	pageIndex: number;
	totalSidebarItems: number;
	nonSubmenuSidebar: Sidebar[] = [];
	isToggleSidebar = false;
	loading = false;

	private callbackFnSubs$: Subscription;

	get isLoading() {
		return isNil(this.manualLoading) ? this.loading : this.manualLoading;
	}

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private routeService: RouteService
	) {}

	ngOnInit(): void {
		this.getCurrentPageIndex();
	}

	pauseAndExit() {
		let redirectRoute = [];
		const clientId = +this.route.snapshot.paramMap.get('clientId');
		const apId = +this.route.snapshot.paramMap.get('adviceProcessId');

		const goToCrm = () => {
			redirectRoute = this.routeService.customerAdviceProcess(clientId, apId);
			this.router.navigate(redirectRoute);
		};

		goToCrm();
	}

	getCurrentPageIndex() {
		const sidebarItems: Sidebar[] = [];
		const flattenSidebar = (item: Sidebar) => {
			if (item.isSubMenu) {
				sidebarItems.push(...item.subMenu);
			} else {
				sidebarItems.push(item);
			}
		};

		forEach(flattenSidebar, this.sidebar);
		this.nonSubmenuSidebar = [...sidebarItems];
		this.totalSidebarItems = sidebarItems.length;
		this.pageIndex = sidebarItems?.findIndex(
			(item: Sidebar) => item.id === this.currentPageId
		);
	}

	previous() {
		if (this.callbackFnSubs$) {
			return;
		}
		const move = () => {
			if (this.pageIndex > 0) {
				const baseUrl = this.router.url?.split('/')?.slice(1, 8)?.join('/');
				const menu = this.nonSubmenuSidebar[this.pageIndex - 1];
				let newRoute = menu.link;
				if (!!menu?.parentRouterLink && menu?.parentRouterLink !== '') {
					newRoute = menu.parentRouterLink + '/' + newRoute;
				}
				const navigateTo = baseUrl + '/' + newRoute;

				this.router.navigateByUrl(navigateTo);
			}
		};
		if (this.previous$) {
			return this.previous$()
				.pipe(take(1))
				.subscribe((canNavigate) => canNavigate && move());
		}
		if (this.callbackFn$) {
			this.loading = true;
			this.callbackFnSubs$ = this.callbackFn$(true, 'previous')
				.pipe(
					finalize(() => {
						if (!this.ignoreNextAndPrevPage) {
							move();
						}
					}),
					take(1)
				)
				.subscribe(() => {
					if (this.callbackFnSubs$) {
						this.callbackFnSubs$?.unsubscribe();
						this.callbackFnSubs$ = null;
					}
					this.loading = false;
				});
		} else {
			if (!this.ignoreNextAndPrevPage) {
				move();
			}
		}
	}

	next() {
		if (this.callbackFnSubs$) {
			return;
		}
		const move = () => {
			if (this.pageIndex < this.totalSidebarItems - 1) {
				const baseUrl = this.router.url?.split('/')?.slice(1, 8)?.join('/');
				const menu = this.nonSubmenuSidebar[this.pageIndex + 1];
				let newRoute = menu.link;
				if (!!menu?.parentRouterLink && menu?.parentRouterLink !== '') {
					newRoute = menu.parentRouterLink + '/' + newRoute;
				}
				const navigateTo = baseUrl + '/' + newRoute;
				this.router.navigateByUrl(navigateTo);
			}
		};

		if (this.nextFn$) {
			this.callbackFnSubs$ = this.nextFn$()
				.pipe(
					take(1),
					tap((canNavigateNext) => canNavigateNext && move())
				)
				.subscribe(() => {
					this.callbackFnSubs$?.unsubscribe?.();
					this.callbackFnSubs$ = null;
				});
			return;
		}

		if (this.callbackFn$) {
			this.loading = true;
			this.callbackFnSubs$ = this.callbackFn$(true, 'next')
				.pipe(
					finalize(() => {
						if (!this.ignoreNextAndPrevPage) {
							move();
						}
					}),
					take(1)
				)
				.subscribe(() => {
					if (this.callbackFnSubs$) {
						this.callbackFnSubs$?.unsubscribe();
						this.callbackFnSubs$ = null;
					}
					this.loading = false;
				});
		} else {
			if (!this.ignoreNextAndPrevPage) {
				move();
			}
		}
	}
}
