import { Component, OnInit } from '@angular/core';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { FactFindRiskProfileSettingsService } from './state/fact-find-risk-profile-settings.service';
import { FactFindRiskProfileQASettingsMapper, FactFindRiskProfileOutcomeSettingsMapper} from './state/fact-find-risk-profile-settings.mapper';
import { FactFindRiskProfileQASettingsState, FactFindRiskProfileOutcomesSettingsState } from './state/fact-find-risk-profile-settings.model';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-fact-find-risk-profile-settings',
	templateUrl: './fact-find-risk-profile-settings.component.html',
	styleUrls: ['./fact-find-risk-profile-settings.component.scss'],
})
export class FactFindRiskProfileSettingsComponent implements OnInit {
	isLoading = false;
	isDisabled = false;

	isLoadingOutcomes = false;
	isDisabledOutcomes = false;

  factFindRiskProfileQASettings$ = this.factFindRiskProfileService.factFindRiskProfileQASettings$;
	factFindRiskProfileOutcomesSettings$ = this.factFindRiskProfileService.factFindRiskProfileOutcomesSettings$;

	riskProfileQAData: FactFindRiskProfileQASettingsState;
	riskProfileOutcomesData: FactFindRiskProfileOutcomesSettingsState;

	private onDestroy$ = new Subject<void>();

	alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

	shouldUpdate = 0;

	constructor(
		private factFindRiskProfileService: FactFindRiskProfileSettingsService,
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		this.factFindRiskProfileQASettings$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      if (data) {
				this.riskProfileQAData = data;
      }
    });

		this.factFindRiskProfileOutcomesSettings$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      if (data) {
				this.riskProfileOutcomesData = data;
      }
    });
	}

	disableForms(data) {
		this.isDisabled = data;
	}

	disableFormsOutcomes(data) {
		this.isDisabledOutcomes= data;
	}

	// saves Questions & Answers to state only
	save(data) {
		this.isLoading = true;
		
		let newQA = [];

		if (!this.riskProfileQAData?.qA || (this.riskProfileQAData?.qA && this.riskProfileQAData?.qA?.length === 0)) {
			newQA = [
				{
					...data
				}
			]
		} else {
			newQA = this.riskProfileQAData?.qA?.map((qa) => {
				if (qa.question === data.question) {
					return {
						...qa,
						answers: data.answers
					}
				}
				return qa;
			});
		}

		const newData = {
			...this.riskProfileQAData,
			qA: newQA
		}

    // const formValue = FactFindRiskProfileQASettingsMapper.mapToUpsert(newData);

    this.factFindRiskProfileService
      .updateFactFindRiskProfileQASettingsStateOnly(newData)
      .pipe(
        finalize(() => {
					this.isLoading = false;

					if (data?.shouldSaveToAPI) {
						this.saveToAPI();
					}
				}),
        take(1)
      ).subscribe();
	}

	// saves Questions & Answers to API
	saveToAPI() {
		this.isLoading = true;

		const newQA = this.riskProfileQAData?.qA?.map((question) => {
			const newAnswers = question?.answers?.map((answer) => {
				return {
					...answer,
					origScore: answer.score,
					origStatus: answer.status,
					origOrderNo: answer.orderNo,
				}
			});

			return {
				...question,
				answers: newAnswers
			}
		});

		const data = FactFindRiskProfileQASettingsMapper.mapToUpsert({
			...this.riskProfileOutcomesData,
			qA: newQA
		});

    this.factFindRiskProfileService
      .updateFactFindRiskProfileQASettings(data)
      .pipe(
        finalize(() => {
					this.isLoading = false;
				}),
        take(1)
      ).subscribe();
	}

	cancelUpdates() {
		this.cancelOutcomeUpdates();
		this.cancelQuestionAnswersUpdates();
	}

	cancelQuestionAnswersUpdates() {
		const newQA = this.riskProfileQAData?.qA?.map((qa) => {
			const newAnswers = qa?.answers?.map((answer) => {
				return {
					id: answer.id,
					answer: answer.answer,
					isDefault: answer.isDefault,
					orderNo: answer.origOrderNo,
					origOrderNo: answer.origOrderNo,
					score: answer.origScore || 0,
					origScore: answer.origScore || 0,
					status: answer.origStatus || answer.status,
					origStatus: answer.origStatus|| answer.status,
				}
			});

			return {
				...qa,
				answers: newAnswers
			}
		});

    this.factFindRiskProfileService
      .updateFactFindRiskProfileQASettingsStateOnly({
				...this.riskProfileQAData,
				qA: newQA
			})
      .pipe(
        finalize(() => {
					this.isLoadingOutcomes = false;
					// this.shouldUpdate = this.shouldUpdate + 1;
					this.isDisabled = false;
				}),
        take(1)
      ).subscribe();
	}

	cancelOutcomeUpdates() {
		const newOutcomes = this.riskProfileOutcomesData?.outcomes?.map((outcome) => {
			return {
				id: outcome.id,
				outcome: outcome.outcome,
				score: outcome.origScore || outcome.score || 0,
				origScore: outcome.origScore || outcome.score || 0,
				status: outcome.origStatus || outcome.status,
				origStatus: outcome.origStatus || outcome.status,
				isDefault: outcome.isDefault,
			};
		});

    this.factFindRiskProfileService
      .updateFactFindRiskProfileOutcomesSettingsStateOnly({
				...this.riskProfileOutcomesData,
				outcomes: newOutcomes
			})
      .pipe(
        finalize(() => {
					this.shouldUpdate = this.shouldUpdate + 1;
					this.isDisabledOutcomes= false;
				}),
        take(1)
      ).subscribe();
	}

	// saves outcomes to state only
	saveOutcomes(data) {
		this.isLoadingOutcomes = true;

		const newData = {
			...this.riskProfileOutcomesData,
			outcomes: data,
		}

    this.factFindRiskProfileService
      .updateFactFindRiskProfileOutcomesSettingsStateOnly(newData)
      .pipe(
        finalize(() => (this.isLoadingOutcomes = false)),
        take(1)
      ).subscribe();
	}

	// saves outcomes to API
	saveOutcomesToAPI() {
		this.isLoadingOutcomes = true;

		const data = FactFindRiskProfileOutcomeSettingsMapper.mapToUpsert(this.riskProfileOutcomesData);

		this.factFindRiskProfileService
			.updateFactFindRiskProfileOutcomesSettings(data)
			.pipe(
				finalize(() => {
					this.isLoadingOutcomes = false;
					this.shouldUpdate = this.shouldUpdate + 1;
				}),
				take(1)
			).subscribe();
	}

	saveUpdates() {
		this.saveToAPI();
		this.saveOutcomesToAPI();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
