<button
	id="hideModalButton"
	class="tap-btn tap-btn-outline modal-btn-close"
	(click)="bsModalRef.hide()"
>
	<span class="material-icons"> close </span>
</button>
<div class="modal-header pb-1">
	<h5 class="modal-title">
		{{ header }}
	</h5>
</div>
<div class="modal-body">
	<form novalidate [formGroup]="form" *ngIf="form">
		<p class="mb-3">{{ message }}</p>
		<div class="form-check form-check-inline mb-3 flex-column align-items-baseline">
			<ng-container *ngFor="let r of reasonOptions; let i = index">
				<label for="reason_{{ i }}" class="d-flex align-items-center pt-0 pb-0 pl-0 pr-2">
					<input
						id="reason_{{ i }}"
						type="radio"
						formControlName="cancellationReason"
						class="mr-1"
						[value]="r"
					/>
					<span>{{r}}</span>
				</label>
			</ng-container>
		</div>
		<div>Reason</div>
		<textarea formControlName="notes" class="form-control" rows="3"></textarea>

		<div class="d-flex justify-content-end modal-footer">
			<ng-container *ngIf="!isSaving; else loader">
				<button
					id="saveButton"
					type="button"
					class="tap-btn tap-btn--default m-0 mr-2"
					(click)="save()"
					[disabled]="form.invalid"
				>
					Confirm
				</button>
				<button
					id="saveButton"
					type="button"
					class="tap-btn tap-btn--default m-0"
					(click)="cancel()"
				>
					Cancel
				</button>
			</ng-container>
			<ng-template #loader>
				<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
			</ng-template>
		</div>
	</form>
</div>
