<form [formGroup]="form" class="form" class="mb-3">
	<div formArrayName="loanRefinance">
		<div
			*ngFor="
				let data of LoanRefinanceList?.controls;
				let i = index;
				let last = last
			"
      [formGroupName]="i"
		>
			<div *ngIf="!data?.get('hidden').value" class="row align-items-center" [ngClass]="last ? 'pb-0' : 'pb-3'">
				<div class="col-12 col-lg-3">
          <div class="form-row align-items-center">
            <div class="col-12 col-lg-4 text-left mb-2">
              <label for="lenderName" class="position-relative font-weight-bold">
                Lender
              </label>
            </div>
            <div class="col-12 col-lg-8 mb-2 pr-md-0">
              <input
                id="lenderName"
                class="form-control crt-form-control"
                type="text"
                formControlName="lender"
                [class.disabled]="true"
              />
            </div>
          </div>
				</div>
				<div class="col-12 col-lg-3">
          <div class="form-row align-items-center">
            <div class="col-12 col-lg-4 text-left mb-2">
              <label for="" class="position-relative font-weight-bold">
                Type
              </label>
            </div>
            <div class="col-12 col-lg-8 mb-2 pr-md-0">
              <select formControlName="sourceType" class="form-control theme-crm-field-disabled crt-form-control disabled">
                <option *ngFor="let s of sourceType$ | async" [value]="s.value">{{ s.text }}</option>
              </select>
            </div>
          </div>
				</div>
				<div class="col-12 col-lg-4">
          <div class="form-row align-items-center">
            <div class="col-4 col-lg-5 text-left mb-2">
              <label for="" class="position-relative font-weight-bold">
                Loan Amount
              </label>
            </div>
            <div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
              <div class="dollar-icon">
                <input
                  id="loanAmount"
                  class="form-control crt-form-control"
                  type="text"
                  formControlName="loanValue"
                  [class.disabled]="true"
                  currencyMask
                  appCurrencyMaskExt
                />
                <i>$</i>
              </div>
            </div>
          </div>
				</div>
        <div class="col-12 col-lg-2">
          <div class="col mb-2">
            <div class="custom-control custom-checkbox">
              <input
                  type="checkbox"
                  id="refinanceTick_{{ i }}"
                  class="chckbox custom-control-input"
                  [attr.checked]="data?.get('isRefinance').value ? '' : null"
                  [disabled]="data?.get('isLoading').value"
                  (change)="updateTick(i)"
                  />
              <label class="custom-control-label" for="refinanceTick_{{ i }}"></label>
              <app-manual-loader-mini
                  [isLoading]="data?.get('isLoading').value"
                  >
              </app-manual-loader-mini>
            </div>
          </div>
        </div>
			</div>
		</div>
	</div>
</form>

<div class="row">
	<div class="col-lg-2">
		<button
			(click)="addLoanRefinance()"
			type="button"
			class="tap-btn tap-btn-outline tap-btn--shadow px-4 ml-1"
			[disabled]="isAdding || updateLoading"
		>
			Add Loan +
		</button>
	</div>
	<div class="col-lg-4 offset-lg-4">
		<div class="form-row align-items-center">
			<div class="col-4 col-lg-5 text-left mb-2">
				<label class="position-relative font-weight-bold tap-text-primary">
					Total Refinance
				</label>
			</div>
			<div class="col-8 col-lg-7 mb-2 pl-0 mb-2">
				{{ totalRefinance | currency }}
			</div>
		</div>
	</div>
</div>
