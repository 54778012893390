import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { produce } from 'immer';
import { ActivityModel } from '../../../../../../shared/models/_general/activity.model';
import { Injectable } from "@angular/core";

export interface Column {
  name: string;
  prop: string;
  width?: number;
}

export interface ActivityState extends EntityState<ActivityModel> {
  // columns: Column[];
  count: number;
}

/**
 * Activity widget data store
 */
@Injectable()
@StoreConfig({
  name: 'Activities',
  idKey: 'ActivityId'
})
export class AStore extends EntityStore<ActivityState, ActivityModel> {
  constructor() {
    super({
      // columns: [],
      count: 0,
    });
  }

  setColumns(columns: Column[]) {
    this.update(produce(x => { x.columns = columns; }));
  }
}
