import { Component, OnInit } from '@angular/core';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
})
export class TransferModalComponent implements OnInit {
  header: string;
  message: string;
  types: ViewDisplayValue[];
  transferSubject: Subject<any>;
  typeCode: string;
  constructor(public bsModalRef: BsModalRef) {
    this.setupProps();
  }
  setupProps() {
    this.header = 'Transfer Document'
    this.message = 'This document will be transferred, are you sure?';
    this.types = [];
    this.typeCode = ''
  }
  ngOnInit() { }
  cancel() {
    this.transferSubject.complete();
    this.bsModalRef.hide();
  }

  upload() {
    this.transferSubject.next(this.typeCode);
    this.cancel();
  }
}
