<app-secondary-base-widget headerText="Refix Mortgage" [widgetCode]="widgetCode">
  <app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
  <app-date-range ngProjectAs="input" [formControl]="dateControl"></app-date-range>
  <div class="position-relative h-100">
    <div class="container-fluid h-100 px-0">
      <div class="row pb-2 refix_mortgage__row font-weight-bold">
        <div class="col-3 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          <a [routerLink]="mortgageRoute" class="tap-text-color-h4">
            Refix Date
          </a>
        </div>
        <div class="col-5 text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          <a [routerLink]="mortgageRoute" class="tap-text-color-h4">
            Borrowing Entities
          </a>
        </div>
        <div class="col text-bold tap-text-h6 tap-text-color-h5 header-col text-truncate">
          <a [routerLink]="mortgageRoute" class="tap-text-color-h4">
            Provider
          </a>
        </div>
      </div>
      <div class="row bg-white refix_mortgage__table-body">
        <div class="col px-3 position-relative h-100">
          <div class="refix_mortgage__table-body__wrapper">
            <ng-container *ngFor="let row of data$ | async">
              <a [routerLink]="customerRoute(row.CustomerID, row.IsCompany)">
                <div class="row refix_mortgage__row row-link" >
                  <div class="col-3 py-2 body-col tap-text-color-h4">
                    {{ row.RefixDate | momentDatetime }}
                  </div>
                  <div class="col-5 py-2 font-weight-bold body-col tap-text-color-h4">
                    <span class="tap-text-color-h4">
                      {{ row.ClientName }}
                    </span>
                  </div>
                  <div class="col py-2 font-weight-bold body-col tap-text-color-h4">
                    {{ row.Provider }}
                  </div>
              
                  <div class="col-12">
                    <div class="border-bottom"></div>
                  </div>
                </div>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-secondary-base-widget>
