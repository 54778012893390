import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { iif, Observable, of } from 'rxjs';
import {
	filter,
	map,
	mergeMap,
	take,
	tap
} from 'rxjs/operators';
import { objectUtil } from 'src/app/util/util';
import {
	DocumentUploadState,
	IntroductionState
} from '../../../../../../shared/models/client-review-template/intro/intro.model';
import { MoatIntroService } from '../../../crt-mortgage/intro/state/moat-intro.service';
import { either, isNil, isEmpty, complement } from 'ramda';

@Component({
	selector: 'app-crt-intro',
	templateUrl: './crt-intro.component.html',
	styleUrls: ['./crt-intro.component.scss'],
})
export class CrtIntroComponent implements OnInit {
	@Input() introData$: Observable<IntroductionState>;

	introduction: IntroductionState;
	document: DocumentUploadState;
	imgExt = ['.jpg', '.png'];
	pdfExt = ['.pdf'];
	fileUrl: any;
	isImg = false;
	isPdfLoaded = false;
	showHeader = false;

	constructor(
		private introService: MoatIntroService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit(): void {
		this.introData$
			.pipe(
				filter((data) => !!data),
				tap((data) => (this.introduction = data)),
				mergeMap((data) =>
					iif(
						() =>
							data?.introType === 'UD' &&
							complement(either(isNil, isEmpty))(+data?.documentID),
						this.getIntroFile(+data?.documentID),
						this.returnLink('')
					)
				),
				take(1)
			)
			.subscribe();
	}

	getIntroFile = (documentID) =>
		of(documentID).pipe(
			mergeMap((x) => this.introService.getFile(x)),
			map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
			tap((data) => (this.document = data)),
			mergeMap((data) => {
				this.isImg = this.imgExt?.includes(data?.fileExtension);
				return iif(
					() => this.isImg,
					this.returnLink(data?.documentLink),
					this.returnPdf(data?.documentLink)
				);
			}),
			take(1)
		);

	returnLink = (docLink) =>
		of(docLink).pipe(
			map((x) => this.sanitizer.bypassSecurityTrustResourceUrl(x)),
			tap((x) => (this.fileUrl = x)),
			take(1)
		);

	returnPdf = (docLink) =>
		of(docLink).pipe(
			tap((x) => (this.fileUrl = x)),
			take(1)
		);

	onPageRendered(event) {
		this.isPdfLoaded = true;
	}
}
