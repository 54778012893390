import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of, BehaviorSubject } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { objectUtil } from 'src/app/util/util';
import { Loan, LoanStructure } from './loan.model';
import { LoanQuery } from './loan.query';
import { LoanStore } from './loan.store';

@Injectable({ providedIn: 'root' })
export class LoanService {
	isLoading$ = this.query.selectLoading();
	loanSplits$ = this.query.selectAll();
	loanStructure$ = this.query.select((x) => x?.loanStructure);
  /**
   * flag when loan structure is updating
   */
  isUpdating$ = new BehaviorSubject<boolean>(false);

	constructor(
		protected store: LoanStore,
		protected query: LoanQuery,
		private api: ApiService
	) {
  }

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(parentCRTId: number) {
		return this.api
			.get<Loan[]>(
				`crt/${AdviceProcessSectionCodes.ROA}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.LoanSplit}`
			)
			.pipe(
				tap(() => this.store.setLoading(true)),
				map((x) => x?.map(objectUtil.mapPascalCaseToCamelCase) as Loan[]),
				tap((entities) => {
					this.store.set(entities);
				}),
				finalize(() => this.store.setLoading(false)),
				catchError(this.catchError)
			);
	}

	getLoanStructure(parentCRTId: number) {
		return this.api
			.get<Loan[]>(
				`crt/${AdviceProcessSectionCodes.ROA}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.LoanStructure}`
			)
			.pipe(
				map(
					(x) => x?.map(objectUtil.mapPascalCaseToCamelCase) as LoanStructure[]
				),
				tap((ls) => {
					this.store.update({
						loanStructure: !!ls && ls?.length > 0 ? ls[0] : null,
					});
				}),
				catchError(this.catchError)
			);
	}

	getById(id: number) {
		return this.api.get<Loan>(`crt/${id}`).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x) as Loan),
			catchError(this.catchError)
		);
	}

	add(structureSoa: Loan) {
		const body = {
			...structureSoa,
			sectionCode: AdviceProcessSectionCodes.LoanSplit,
		};
		return this.api.post(`crt`, objectUtil.mapCamelCaseToPascalCase(body)).pipe(
			// tap(() => this.store.setLoading(true)),
			switchMap((x) => this.getById(+x)),
			tap((x) => {
        return this.store.add(x);
      }),
			// finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	addLoanStructure(structureSoa: LoanStructure) {
		const body = {
			...structureSoa,
			sectionCode: AdviceProcessSectionCodes.LoanStructure,
		};
		return this.api.post(`crt`, objectUtil.mapCamelCaseToPascalCase(body)).pipe(
			// tap(() => this.store.setLoading(true)),
			switchMap((x) => this.getById(+x)),
			tap((x) => this.store.update({ loanStructure: x })),
			// finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	update(structureSoa: Partial<Loan>) {
		const body = {
			...structureSoa,
			sectionCode: AdviceProcessSectionCodes.LoanSplit,
		};
		return this.api
			.put(`crt/${body?.cRTId}`, objectUtil.mapCamelCaseToPascalCase(body))
			.pipe(
				// tap(() => this.store.setLoading(true)),z
				switchMap((x) => this.getById(body?.cRTId)),
				tap((res) => this.store.update(body?.cRTId, res)),
				// finalize(() => this.store.setLoading(false)),
				catchError(this.catchError)
			);
	}

	updateLoanStructure(structureSoa: Partial<LoanStructure>) {
		const body = {
			...structureSoa,
			sectionCode: AdviceProcessSectionCodes.LoanStructure,
		};
		return this.api
			.put(`crt/${body?.cRTId}`, objectUtil.mapCamelCaseToPascalCase(body))
			.pipe(
        tap(() => this.isUpdating$.next(true)),
				switchMap((x) => this.getById(body?.cRTId)),
				tap((res) => this.store.update({ loanStructure: res })),
        finalize(() => this.isUpdating$.next(false)),
				catchError(this.catchError)
			);
	}

	delete(structure: Loan | number) {
		this.store.setLoading(true);
		const cRTId = typeof structure === 'number' ? structure : structure?.cRTId;
		return this.api.delete<string>(`crt/${cRTId}`).pipe(
			tap(() => this.store.setLoading(true)),
			tap(() => this.store.remove(cRTId)),
			finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	catchError = (x) => of(undefined);
}
