import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { toBoolean } from '@datorama/akita';
import {
	sum,
	isNil,
	filter as Rfilter,
	map as Rmap,
	merge,
	uniqBy,
} from 'ramda';
import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	map,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	AdviceProcessPageCodes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { PropertyAssetCustomerServiceState } from 'src/app/shared/models/services/property-asset/property-asset';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { MortgageAdviceProcessService } from '../../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { IncomeService } from '../income/state/income.service';
import { PeopleEntitiesService } from '../people-entities/state/people-entities.service';
import { ViewDisplayValue } from './../../../../../../shared/models/_general/display-value.viewmodel';
import { CrtMortgageQuery } from './../../state/crt-mortgage.query';
import { AssetService } from './state/asset/asset.service';
import { KiwiSaverService } from './state/kiwiSaver/kiwiSaver.service';
import { LiabilityService } from './state/liability/liability.service';
import { Mortgage, MortgageStatus } from './state/mortgage/mortgage.model';
import { MortgageService } from './state/mortgage/mortgage.service';
import { OtherService } from './state/other/other.service';
import { PropertyService } from './state/property/property.service';

import { BaseCrtMortgageComponent } from '../../base-crt-mortgage.component';
import { KiwiSaverStatus } from 'src/app/shared/models/services/kiwisaver/kiwisaver.model';
import { PropertyStatus } from './state/property/property.model';
import { CrtMortgageService } from '../../state/crt-mortgage.service';

@Component({
	selector: 'app-assets-and-liabilities',
	templateUrl: './assets-and-liabilities.component.html',
	styleUrls: ['./assets-and-liabilities.component.scss'],
})
export class AssetsAndLiabilitiesComponent
	implements OnInit, OnDestroy, BaseCrtMortgageComponent
{
	onDestroy$ = new Subject<void>();
	title: string;
	adviceProcessId = +this.route.snapshot.paramMap.get('adviceProcessId');
	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;

	// Start: Property
	properties$ = this.propertyService.properties$;
	totalPropertyValues$ = this.propertyService.properties$.pipe(
		map((x) => sum((!isNil(x) ? x : [])?.map((p) => +p.propertyValue)))
	);
	isLoadingProperty$ = this.propertyService.isLoading$;

	SU$ = this.propertyService.SU$;
	SV1$ = this.propertyService.SV1$;
	ST$ = this.propertyService.ST$;
	STI$ = this.propertyService.STI$;
	APCRTF$ = this.propertyService.APCRTF$;
	sidebar = this.crtMortgageQuery.getValue().sidebars;
	policyOwners$ = this.peopleEntitiesService.policyOwnersWithCRT$;

	propertyAddresses$: Observable<PropertyAssetCustomerServiceState[]> =
		combineLatest([
			this.propertyService.propertyAddresses$,
			this.propertyService.properties$,
		]).pipe(
			filter(([x, y]) => toBoolean(x) && toBoolean(y)),
			map(([x, properties]) =>
				x?.filter(
					(y) =>
						!properties?.some((z) => z.propertyAddress === y.propertyAddress)
				)
			),
			map((x) => x?.filter((p) => p?.status === PropertyStatus.Current))
		);
	// End: Property

	// Start: KiwiSaver
	kiwiSavers$ = this.kiwiSaverService.kiwiSavers$;
	isLoadingKiwiSaver$ = this.kiwiSaverService.isLoading$;

	KP$: Observable<ViewDisplayValue[]> = this.kiwiSaverService.KP$;
	KFT$: Observable<ViewDisplayValue[]> = this.kiwiSaverService.KFT$;
	APCRTRP$: Observable<ViewDisplayValue[]> = this.kiwiSaverService.APCRTRP$;

	lifeAssuredList$ = this.peopleEntitiesService.peopleFromCrmAndCrtChoices$;
	crmKiwiSavers$ = this.kiwiSaverService.crmKiwiSavers$.pipe(
		map((x) => x?.filter((p) => p?.status === KiwiSaverStatus.Active))
	);
	crmKiwiSaversChoinces$: Observable<ViewDisplayValue[]> = combineLatest([
		this.kiwiSaverService.crmKiwiSavers$,
		this.peopleEntitiesService.peopleFromCrmAndCrtExceptChildChoices$,
		this.kiwiSaverService.kiwiSavers$,
	]).pipe(
		filter(([x, y, z]) => !!x && !!y && !!z),
		map(([x, po, kiwiSavers]) => {
			const crmKiwiSavers = x
				?.filter((p) => p?.status === KiwiSaverStatus.Active)
				?.filter(
					(y) =>
						!kiwiSavers
							?.filter((ks) => !!ks.cRTId)
							?.some((k) => k.customerServiceID === y.customerServiceID)
				);

			// List of owner IDs that already has data on CRT > Kiwisaver
			const ids = kiwiSavers.reduce((acc, curr) => [...acc, ...curr.owner], []);
			// TAPNZ-10383: Filter out crm kiwisaver choices where its owner already exists on CRT > Kiwisaver
			const newList = crmKiwiSavers.filter(
				(ks) => !ks.fundOwner.some((fo) => ids.includes(fo))
			);

			return newList?.map((k) => {
				const ownerDisplays: string[] = [];
				po?.forEach((person) => {
					if (k.fundOwner?.find((owner) => +owner === +person.value)) {
						ownerDisplays.push(person.display);
					}
				});

				return ViewDisplayValue.Map(
					`${k.customerServiceID}`,
					`${ownerDisplays.join(', ')} - ${k.provider}`
				);
			});
		})
	);
	transferedSCIList$ = combineLatest([
		this.crtMortgageQuery.secondaryClients$,
		this.peopleEntitiesService.people$,
	]).pipe(
		filter(([sci, people]) => !!people && !!sci),
		map(([sci, people]) => {
			const crtPeople =
				people?.filter(
					(x) => x?.customerType === CustomerTypes.SecondaryCustomerIndividual
				) || [];
			const list = crtPeople?.filter(
				(x) => !sci?.some((s) => +x?.customerId === +s?.customerID)
			);
			return list || [];
		})
	);
	// End: KiwiSaver

	// Start: Mortgage
	mortgages$ = this.mortgageService.mortgages$.pipe(
		map((x) => this.mapInterestRate(x))
	);
	crmMortgages$ = this.mortgageService.crmMortgages$;
	isLoadingMortgage$ = this.mortgageService.isLoading$;
	mortgageSecurities$ = combineLatest([
		this.propertyService.propertyAddresses$,
		this.properties$,
	]).pipe(
		map(([propertyAddresses, properties]) => {
			const propertiesAndPropertyAddresses = uniqBy(
				({ customerServiceID }) => customerServiceID,
				[...propertyAddresses, ...properties]
			);
			return Rfilter(
				(p) => p.propertyAddress?.toUpperCase() !== 'TBC',
				propertiesAndPropertyAddresses
			);
		})
	);
	crmMortgagesChoices$: Observable<ViewDisplayValue[]> = combineLatest([
		this.crmMortgages$,
		this.mortgages$,
	]).pipe(
		// by in a conversation by randy
		map(([x, mortgages]) =>
			x
				?.filter(
					(y) =>
						!mortgages
							?.filter((mg) => !!mg.cRTId)
							?.some((m) => m.customerServiceID === y.customerServiceID) &&
						[
							MortgageStatus.CurrentMortgage.toString(),
							MortgageStatus.DrawDown.toString(),
						].includes(y?.status)
				)
				?.map((m) =>
					ViewDisplayValue.Map(
						`${m.customerServiceID}`,
						`${m.provider} - ${this.cp.transform(
							isNaN(m.loanValue) ? 0 : +m.loanValue
						)}`
					)
				)
		)
	);
	// End: Mortgage

	// Start: Assets
	assets$ = this.assetService.assets$;
	totalValue$ = this.assetService.assets$.pipe(
		map((x) => (x ? sum(x?.map((y) => y.value ?? 0)) : 0))
	);
	isLoadingAsset$ = this.assetService.isLoading$;
	APCRTA$ = this.assetService.APCRTA$;
	pciAndSciFromCrmAndCrtChoices$ =
		this.peopleEntitiesService.pciAndSciFromCrmAndCrtChoices$;

	pciAndSciFromCrmAndCrtChoicesWithBusinessTrust$ =
		this.peopleEntitiesService.pciAndSciFromCrmAndCrtChoicesWithBusinessTrust$;
	// End: Assets

	// Start: Liability
	liabilities$ = this.liabilityService.liabilities$;
	isLoadingLiability$ = this.liabilityService.isLoading$;
	MP$ = this.liabilityService.MP$;
	MLT$ = this.liabilityService.MLT$;
	APCRTYN$ = this.liabilityService.APCRTYN$;
	APCRTYNNA$ = this.liabilityService.APCRTYNNA$;
	APCRTL$ = this.liabilityService.APCRTL$;
	borrowers$ = this.policyOwners$;
	securities$ = this.assets$.pipe(
		map((x) => x?.map((a) => ViewDisplayValue.Map(`${a.cRTId}`, `${a.asset}`)))
	);
	// End: Liability

	// Start: Liability
	others$ = this.otherService.others$;
	isLoadingOther$ = this.otherService.isLoading$;
	form: UntypedFormGroup;
	submitted: boolean;
	// End: Liability

	deceasedSciList$ = this.peopleEntitiesService.deceasedSciList$;

	getOwnerChoices = this.crtService.getOwnerChoices;

	constructor(
		private fb: UntypedFormBuilder,
		private propertyService: PropertyService,
		private peopleEntitiesService: PeopleEntitiesService,
		private kiwiSaverService: KiwiSaverService,
		private mortgageService: MortgageService,
		private crtMortgageQuery: CrtMortgageQuery,
		private liabilityService: LiabilityService,
		private assetService: AssetService,
		private otherService: OtherService,
		private cp: CurrencyPipe,
		private moatService: MortgageAdviceProcessService,
		private route: ActivatedRoute,
		private incomeService: IncomeService,
		private crtService: CrtMortgageService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();
		this.moatService
			.updateMortApPageStarted(AdviceProcessPageCodes.AssetsLiabilities)
			.pipe(take(1))
			.subscribe();
	}

	refetchIncome(data) {
		// Refetch Income but return the data instead
		return this.incomeService
			.getRentalIncome(this.adviceProcessId)
			.pipe(take(1))
			.subscribe();
	}
	// Start: Property Methods
	addPropertyFn$ = (data) =>
		this.propertyService.add(data).pipe(tap((x) => this.refetchIncome(x)));
	updatePropertyFn$ = (data) =>
		this.propertyService.update(data).pipe(tap((x) => this.refetchIncome(x)));
	deletePropertyFn$ = (data) =>
		this.propertyService.delete(data).pipe(tap((x) => this.refetchIncome(x)));
	// End: Property Methods

	// Start: KiwiSaver Methods
	addKiwiSaverFn$ = (data) => this.kiwiSaverService.add(data);
	updateKiwiSaverFn$ = (data) => this.kiwiSaverService.update(data);
	deleteKiwiSaverFn$ = (data) => this.kiwiSaverService.delete(data);
	// End: KiwiSaver Methods

	// Start: Mortgage Methods
	addMortgageFn$ = (data) => this.mortgageService.add(data);
	updateMortgageFn$ = (data) => this.mortgageService.update(data);
	deleteMortgageFn$ = (data) => this.mortgageService.delete(data);

	mapInterestRate = (mortgages: Mortgage[]) =>
		Rmap((m) => merge(m, { interestRate: +m.interestRate }), mortgages);
	// End: Mortgage Methods

	// Start: Assets Methods
	addAssetFn$ = (data) => this.assetService.add(data);
	updateAssetFn$ = (data) => this.assetService.update(data);
	deleteAssetFn$ = (data) => this.assetService.delete(data);
	// End: Asset Methods

	// Start: Liability Methods
	addLiabilityFn$ = (data) => this.liabilityService.add(data);
	updateLiabilityFn$ = (data) => this.liabilityService.update(data);
	deleteLiabilityFn$ = (data) => this.liabilityService.delete(data);
	// End: Liability Methods

	// Start: Other Methods
	addOtherFn$ = (data) => this.otherService.add(data);
	updateOtherFn$ = (data) => this.otherService.update(data);
	deleteOtherFn$ = (data) => this.otherService.delete(data);

	buildForm() {
		this.form = this.fb.group({
			AdviceProcessId: [''],
			CRTId: [''],
			HasPersonalGuarantees: ['', Validators.required],
			PersonalGuaranteesDetails: [''],
			HasFinancialResponsibilities: ['', Validators.required],
			FinancialResponsibilitiesDetails: [''],
		});
	}

	prepareData() {
		this.form.reset();
		this.others$
			.pipe(
				tap((x) => {
					if (!!x && x.length > 0) {
						const data = {
							AdviceProcessId: x[0]?.adviceProcessId,
							CRTId: x[0]?.cRTId,
							HasPersonalGuarantees: x[0]?.hasPersonalGuarantees,
							PersonalGuaranteesDetails: x[0]?.personalGuaranteesDetails,
							HasFinancialResponsibilities: x[0]?.hasFinancialResponsibilities,
							FinancialResponsibilitiesDetails:
								x[0]?.financialResponsibilitiesDetails,
						};
						this.form.reset(data);
					} else {
						this.form.reset({
							HasPersonalGuarantees: 'No',
							HasFinancialResponsibilities: 'No',
						});
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	saveFn$ = () =>
		of(this.form.getRawValue()).pipe(
			withLatestFrom(this.others$),
			concatMap(([data, others]) =>
				iif(
					() => !!others && others?.length > 0,
					this.updateOtherFn$(data),
					this.addOtherFn$(data)
				)
			)
		);

	saveComponentFunction = () => this.saveFn$();

	// End: Other Methods
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
