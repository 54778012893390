import { Injectable } from '@angular/core';
import { MOState, MOStore } from './mo.store';
import { Query } from '@datorama/akita';

@Injectable()
export class MOQuery extends Query<MOState> {

  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create lead Conversions query
   */
  data$ = this.select(state => state.MortgageOpportunity);

  constructor(protected store: MOStore) {
    super(store);
  }
}
