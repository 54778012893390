import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
	BlDocumentModel,
	DocumentModel,
} from '../../shared/models/documents/document.model';
import {
	BlStaffListModel,
	BlStaffModel,
	BlStaffModelAdd,
	BlStaffModelEdit,
} from '../../shared/models/_general/bl-staff.model';
import { ApiService, JsonResultStatus } from '../base/api.service';

@Injectable()
export class BlStaffService {
	private UpdatedStaffListSubj = new Subject<void>();
	public UpdatedStaffList$ = this.UpdatedStaffListSubj.asObservable();

	constructor(private api: ApiService) {}

	deactivateBlStaff(id: number) {
		const endpoint = 'Staff/DeactivateBLStaff';
		const body = { staff_id: id };
		return this.api.post(endpoint, body);
	}

	addBlStaff(staff: BlStaffModelAdd) {
		const endpoint = 'Staff/AddBLStaff';
		const body = staff;
		return this.api.post3<JsonResultStatus>(endpoint, body).pipe(
			tap(() => {
				this.UpdatedStaffListSubj.next();
			})
		);
	}

	editBlStaff(staff: BlStaffModelEdit) {
		const endpoint = 'Staff/UpdateBLStaff';
		const body = staff;
		return this.api.post3<JsonResultStatus>(endpoint, body).pipe(
			tap(() => {
				this.UpdatedStaffListSubj.next();
			})
		);
	}

	getBlStaff(id: number | string) {
		const endpoint = `staff/${id}/bl`;
		const body = {
			staff_level: 'bl',
			id,
		};
		return this.api.get<BlStaffModel>(endpoint, body).pipe(
			map((x) => {
				const parse2: any = x;
				parse2.Services =
					parse2 && parse2.Services
						? JSON.parse(parse2.Services)
						: parse2.Services;
				parse2.Stats =
					parse2 && parse2.Stats ? JSON.parse(parse2.Stats) : parse2.Stats;
				return parse2;
			})
		);
	}

	getBlStaffs(): Observable<BlStaffModel[]> {
		const endpoint = 'staff/bl';
		const body = {
			staff_level: 'BL',
			show_disabed: true,
		};
		return this.api.get<BlStaffModel[]>(endpoint, body);
	}

	getBlActiveStaffs(
		blStaffs$: Observable<BlStaffModel[]> = this.getBlStaffs()
	): Observable<BlStaffModel[]> {
		return blStaffs$.pipe(map(BlStaffListModel.getActiveStaffs));
	}

	getDocumentById(id: number) {
		return this.api.get<DocumentModel>(`documents/${id}`);
	}

	getDocumentUrlById(id: number) {
		return this.api.get<any>(`documents/download/${id}`);
	}
}
