export enum SettingsTypes {
	// LOAT
	AuthorityToProceed = 'CA',
	Disclosure = 'DD',
	Introduction = 'IP',
	SOA = 'SOA',
	DocumentList = 'KGDL',
	AdviceSummaryDocumentListSettings = 'KOATASDL',

	// KOAT
	Declaration = 'KOATD',
	ReviewApplication = 'KOATRA',
	KOATDeclarationEmail = 'KOATDES',
	KOATClientFactFind = 'KOATCFF',
	KOATReviewApplicationEmail = 'KOATRAES',
	KOATSosTemplate = 'KOATSOSP',
	SOS = 'KOATSOS',
	KOATReviewNotes = 'KOATRANS',
	KOATServicingCalc = 'KOATASC',
	KOATIntro = 'KOATIP',
	KOATIntroDocument = 'KOATI',
	KOATFactFind = 'KOATP',
	KOATFactFindRiskProfileQuestion = 'KOATRPQ',
	KOATFactFindRiskProfileOutCome = 'KOATRPQO',
	KOATFactFindRiskProfileQA = 'KOATRPQ',
	KOATFactFindRiskProfileOutcomes = 'KOATRPQO',
	KOATAdviceSummary = 'KOATAS',
	KOATAdviceSummaryDocument = 'KOATASE',
	KOATAdviceSummaryEmail = 'KOATASES',
	KOATFactFindProviders = 'KOATFFRPP',
}

export enum DocumentSettingsTypes {
	ReviewApplication = 'CRTKOATRA',
	SosTemplate = 'KOATSOSPS',
	ThreePillars = 'KOATTP',
}

export enum ReloadDocumentType {
	ReviewApplication = 'KARA',
	SosTemplate = 'KOATSOSP',
	ClientFactFind = 'KOATCFF',
}
