import { numUtil } from '../../../../../util/util';
import { StatementOfAdviceMapper as SoaMapper } from '../../../../../shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';

export const tdExistingFinalStructureTemplate = (lifeAssured) => {
	const tds = [];
	lifeAssured?.forEach((data) => {
		const td = `
			<td style="border: 1px solid #DDD; padding: 3px;">
				${data?.provider || ''}
			</td>
			<td style="background-color: #ffffff; border: 1px solid #DDD; padding: 3px;">
				${data?.product || ''}
			</td>
			<td style="border: 1px solid #DDD; padding: 3px;">
				${SoaMapper.formatBenefit(data) || ''}
			</td>
			<td style="border: 1px solid #DDD; padding: 3px;">
				${data?.outcome || ''}
			</td>
		`;
		tds.push(
			`<tr style="border: 1px solid #DDD; text-align: left; padding: 3px;">${td}</tr>`
		);
	});
	return tds?.join('').toString();
};

export const tableExistingFinalStructureTemplate = (combinedTR: string) => {
	return `
    <table style="border: none; border-collapse: collapse; width: 100%; font-family: Arial, Helvetica, sans-serif;">
        <colgroup>
					<col width="90" />
					<col width="120" />
					<col width="90" />
					<col width="90" />
        </colgroup>
        <tbody>
          <tr style="background-color: #efefef; border: 1px solid #DDD; padding: 3px;">
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Provider</p>
            </td>
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Product</p>
            </td>
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Benefit</p>
            </td>
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Outcome</p>
            </td>
          </tr>
          ${combinedTR}
        </tbody>
    </table>
  `;
};

export const tdFinalizedStructureTemplate = (lifeAssured) => {
	const tds = [];
	lifeAssured?.forEach((data) => {
		const td = `
		<td style="border: 1px solid #DDD; padding: 3px;">${data?.provider || ''}</td>
		<td style="background-color: #ffffff; border: 1px solid #DDD; padding: 3px;">${
			data?.product || ''
		}</td>
		<td style="border: 1px solid #DDD; padding: 3px;">${
			SoaMapper.formatBenefit(data) || ''
		}</td>
		<td style="border: 1px solid #DDD; padding: 3px;">${
			data?.newExisting || ''
		}</td>
		<td style="border: 1px solid #DDD; padding: 3px;">${data?.notes || ''}</td>
	`;
		tds.push(
			`<tr style="border: 1px solid #DDD; text-align: left; padding: 3px;">${td}</tr>`
		);
	});
	return tds?.join('').toString();
};

export const tableFinalizedStructureTemplate = (combinedTR: string) => {
	return `
    <table style="border: none; border-collapse: collapse; width: 100%; font-family: Arial, Helvetica, sans-serif;">
        <colgroup>
          <col width="70" />
          <col width="100" />
          <col width="70" />
          <col width="70" />
          <col width="150" />
        </colgroup>
        <tbody>
          <tr style="background-color: #efefef; border: 1px solid #DDD; padding: 3px;">
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Provider</p>
            </td>
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Product</p>
            </td>
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Benefit</p>
            </td>
            <td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Outcome</p>
            </td>
						<td style="border: 1px solid #DDD; padding: 3px; text-align: center;">
              <p>Notes</p>
            </td>
          </tr>
          ${combinedTR}
        </tbody>
    </table>
  `;
};

export const tableCiPolicyFeeAndPremium = (data) => {
	return `
	<table style="width: 100%; border: none!important;" border="0">
	<colgroup>
		<col width="70" />
		<col width="240" />
		<col width="150" />
	</colgroup>
		<tbody>
			<tr>
				<td class="cellNoBorder">&nbsp;</td>
				<td class="cellNoBorder" style="text-align:right;"><strong>Policy Fee:</strong></td>
				<td style="border: 1px solid #DDD; padding: 3px;">${
					numUtil.formatToCurrency(data?.policyFee) || '$0.00'
				}</td>
			</tr>
			<tr>
				<td class="cellNoBorder">&nbsp;</td>
				<td class="cellNoBorder" style="text-align:right;"><strong>Total ${
					data?.premiumFrequency || ''
				} Premium:</strong></td>
				<td style="border: 1px solid #DDD; padding: 3px;">${
					numUtil.formatToCurrency(data?.totalPremium) || '$0.00'
				}</td>
			</tr>
		</tbody>
	</table>
	`;
};

export const tableFsPolicyFeeAndPremium = (data) => {
	return `
	 <table style="width: 100%; border: none!important;" border="0">
			<colgroup>
				<col width="70" />
				<col width="240" />
				<col width="150" />
			</colgroup>
			<tbody>
				<tr>
					<td class="cellNoBorder">&nbsp;</td>
					<td class="cellNoBorder" style="text-align:right;"><strong>Policy Fee:</strong></td>
					<td style="border: 1px solid #DDD; padding: 3px;">${
						numUtil.formatToCurrency(data?.policyFee) || '$0.00'
					}</td>
				<tr>
					<td class="cellNoBorder">&nbsp;</td>
					<td class="cellNoBorder"  style="text-align:right;"><strong>Total ${
						data?.premiumFrequency || ''
					} Premium:</strong></td>
					<td style="border: 1px solid #DDD; padding: 3px;">${
						numUtil.formatToCurrency(data?.totalPremium) || '$0.00'
					}</td>
				</tr>
			</tbody>
		</table>
	 `;
};
