import {
	Component,
	Input,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { InputChipsComponent } from '@shared/components/input-chips/input-chips.component';
import { logMessage } from '@shared/error-message/error-message';
import { LiabilityCustomerServiceState } from '@shared/models/services/assets/assets';
import { ServicesCodes } from '@shared/models/services/services.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { AppFormGroup } from '@util/form-group';
import { util } from '@util/util';
import { clone } from 'ramda';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'app-liability-service-form',
	templateUrl: './liability-service-form.component.html',
	styleUrls: ['./liability-service-form.component.scss'],
})
export class LiabilityServiceFormComponent implements OnInit {
	@ViewChild('form', { read: FormGroupDirective }) form: FormGroupDirective;

	@ViewChild('borrowers') inputChipsBorrower: InputChipsComponent<any>;

	// @Input() liability: LiabilityCustomerServiceState;
	@Input()
	set liability(liability: LiabilityCustomerServiceState) {
		if (!liability) {
			return;
		}

		const liabilityClone = clone(liability);
		liabilityClone.borrower = liabilityClone.borrower
			? util.tryParseJson(liabilityClone.borrower)
			: null;
		this.formGroup.patchValue(liabilityClone);

		if (liabilityClone.isEditing || liabilityClone.isAdd) {
			this.formGroup.enable();
		} else {
			this.formGroup.disable();
		}

		setTimeout(() => this.inputChipsBorrower?.forRenderItems?.(), 1000);
	}

	@Input() liabilityStatus: ViewDisplayValue[];
	@Input() borrowers: ViewDisplayValue[];
	@Input() loanTypes: ViewDisplayValue[];
	@Input() lenders: ViewDisplayValue[];
	@Input() repaymentFrequency: ViewDisplayValue[];
	@Input() liabilityChoices: ViewDisplayValue[];

	@Input() isAdd = false;

	@Input() saveFn$: (
		liability: LiabilityCustomerServiceState
	) => Observable<boolean>;

	@Input() updateFn$: (
		liability: LiabilityCustomerServiceState
	) => Observable<boolean>;

	formGroup = new AppFormGroup<LiabilityCustomerServiceState>({
		borrower: new FormControl(),
		cRTId: new FormControl(),
		customerID: new FormControl(),
		customerServiceID: new FormControl(),
		interestRate: new FormControl(),
		lender: new FormControl(null, [Validators.required]),
		liability: new FormControl(null, [Validators.required]),
		loanBalance: new FormControl(null, [Validators.required]),
		loanLimit: new FormControl(null, [Validators.required]),
		loanRepayment: new FormControl(null, [Validators.required]),
		loanType: new FormControl(),
		paidInFull: new FormControl(),
		repaymentFrequency: new FormControl(null, [Validators.required]),
		security: new FormControl(),
		serviceCode: new FormControl(ServicesCodes.Liability),
		status: new FormControl(null, [Validators.required]),
	});

	get lender(): AbstractControl {
		return this.formGroup.controls.lender;
	}

	get liabilityControl(): AbstractControl {
		return this.formGroup.controls.liability;
	}

	get loanRepayment(): AbstractControl {
		return this.formGroup.controls.loanRepayment;
	}

	get loanBalance(): AbstractControl {
		return this.formGroup.controls.loanBalance;
	}

	get loanLimit(): AbstractControl {
		return this.formGroup.controls.loanLimit;
	}

	get repaymentFrequencyControl(): AbstractControl {
		return this.formGroup.controls.repaymentFrequency;
	}

	get status(): AbstractControl {
		return this.formGroup.controls.status;
	}

	borrowers$: Observable<ViewDisplayValue[]>;

	validationMessage = {
		required: '',
	};

	isSubmitted = false;

	constructor(
		private loggerService: LoggerService,
		private cdr: ChangeDetectorRef
	) {}

	save = (): Observable<boolean> => {
		this.isSubmitted = true;
		if (this.formGroup.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return of(false);
		}
		const formValue = this.formGroup.getRawValue();
		const data = {
			...formValue,
			borrower:
				typeof formValue?.borrower !== 'string'
					? util.tryStringifyJson(formValue?.borrower || [])
					: formValue?.borrower,
		};
		data.loanBalance = +data.loanBalance;
		data.loanLimit = +data.loanLimit;
		return this.isAdd ? this.saveFn$(data) : this.updateFn$(data);
	};

	ngOnInit(): void {
		this.borrowers$ = of(this.borrowers);
	}

	compareWith = (item: any, value: any) => item.value == value;
}
