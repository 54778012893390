import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';

import { uniqBy, prop } from 'ramda';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PrimaryCustomerCompanyState } from 'src/app/shared/models/business-profile/business/business.model';
import { PrimaryClientState } from 'src/app/shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from 'src/app/shared/models/client-profile/secondary-trust/secondary-trust.model';
import {
	CustomerTypes,
	RelationshipTypes,
} from 'src/app/shared/models/_general/client.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import {
	CrtMortgageState,
	CrtMortgageStore,
} from '../../../state/crt-mortgage.store';

@Injectable()
export class PeopleEntitiesQuery extends Query<CrtMortgageState> {
	constructor(
		protected store: CrtMortgageStore,
		protected crtMortgageQuery: CrtMortgageQuery
	) {
		super(store);
	}

	/**
	 * @returns observable list of people
	 */
	people$ = this.select((state) => state.people);

	isPeopleLoading$ = this.select((state) => state.peopleIsLoading);

	/**
	 * @returns observable list of dependents
	 */
	dependents$ = this.select((state) => state.dependents);

	isDependantLoading$ = this.select((state) => state.dependantsIsLoading);

	/**
	 * @returns observable list of trusts
	 */
	trusts$ = this.select((state) => state.trusts);

	isTrustLoading$ = this.select((state) => state.trustsIsLoading);

	/**
	 * @returns observable list of dependents
	 */
	company$ = this.select((state) => state.company);

	isCompanyLoading$ = this.select((state) => state.companyIsLoading);

	linkedContactsNoFormat$ = this.select((state) => state.linkedContacts);

	linkedContacts$ = this.select((state) => state.linkedContacts).pipe(
		map((contacts) => {
			return (
				contacts?.map((contact) => ({
					...contact,
					customerID: contact.linkedFromPrimaryCustomer
						? contact.relatedCustomerId
						: contact.customerId,
				})) || []
			);
		})
	);

	secondaryCompanies$ = this.select((state) => state.secondaryCompanies);

	secondaryTrusts$ = this.select((state) => state.secondaryTrusts);

	// Composed of PCI, SCI(Except relationship !== 'child') and CRT (People)
	peopleFromCRMAndCRTExceptChild$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
		this.linkedContacts$,
	]).pipe(
		filter(([pci, sci, people, linkedContacts]) => !!people),
		map(([pci, sci, people, linkedContacts]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) => x.relationship !== RelationshipTypes.Child
				  )
				: [];

			const l = linkedContacts
				? (
						linkedContacts?.map(objectUtil.mapPascalCaseToCamelCase) as any
				  ).filter((x) => x.relationship !== RelationshipTypes.Child)
				: [];

			const mapPeople = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
				...l,
			];

			const mapped = mapPeople?.filter((o) =>
				people && people.length > 0
					? !people?.find((c) => o.customerID === c.customerId)
					: o
			);

			return mapped;
		})
	);

	// Composed of PCI, SCI(Except relationship !== 'child' && contactMethod !== 'Deceased') and CRT (People)
	peopleFromCRMAndCRTExceptChildNotDeceased$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
		this.dependents$,
		this.linkedContacts$,
	]).pipe(
		filter(([pci, sci, people]) => !!people),
		map(([pci, sci, people, dependants, linkedContacts]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) =>
							x.relationship !== RelationshipTypes.Child &&
							x.contactMethod !== 'Deceased' &&
							!dependants?.find((d) => +d?.customerId === +x?.customerID) // Filter out if sci is already added on CRT > Dependants
				  )
				: [];

			const l = linkedContacts
				? (
						linkedContacts?.map(objectUtil.mapPascalCaseToCamelCase) as any
				  ).filter(
						(x) =>
							x.relationship !== RelationshipTypes.Child &&
							x.contactMethod !== 'Deceased'
				  )
				: [];

			const p = objectUtil.mapPascalCaseToCamelCase(pci) as any;

			const mapPeople = [{ ...p }, ...s, ...l];

			const mapped = mapPeople?.filter((o) =>
				people && people.length > 0
					? !people?.find((c) => o.customerID === c.customerId)
					: o
			);
			return mapped;
		})
	);

	// Composed of PCI, SCI and CRT (People) No filter
	peopleFromCRMAndCRTNoFilter$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, people, linkedContacts]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const l = linkedContacts
				? (
						linkedContacts?.map(objectUtil.mapPascalCaseToCamelCase) as any
				  ).filter((x) => x.relationship !== RelationshipTypes.Child)
				: [];

			const mapPeople = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
				...(people || []),
				...l,
			];

			return mapPeople;
		})
	);

	dependentsFromCRMandCRTChildOnly$ = combineLatest([
		this.crtMortgageQuery.secondaryClients$,
		this.dependents$,
	]).pipe(
		map(([sci, dependents]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any)?.filter(
						(x) => x.relationship === RelationshipTypes.Child
				  )
				: [];

			const peopleMap = [
				...s,
				...dependents?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return peopleMap?.filter((o) =>
				dependents && dependents.length > 0
					? !dependents?.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	dependentsFromCRMandCRTChildNotDeceasedOnly$ = combineLatest([
		this.crtMortgageQuery.secondaryClients$,
		this.dependents$,
		this.people$,
	]).pipe(
		map(([sci, dependents, people]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any)?.filter(
						(x) =>
							x.relationship === RelationshipTypes.Child &&
							x.contactMethod !== 'Deceased' &&
							!people?.find((d) => +d?.customerId === +x?.customerID) // Filter out if sci is already added on CRT > People
				  )
				: [];

			const peopleMap = [
				...s,
				...dependents?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return peopleMap?.filter((o) =>
				dependents && dependents.length > 0
					? !dependents?.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	dependentsFromCRMChildOnlyNoFilter$ = combineLatest([
		this.crtMortgageQuery.secondaryClients$,
		this.dependents$,
	]).pipe(
		map(([sci, dependents]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any)?.filter(
						(x) => x.relationship === RelationshipTypes.Child
				  )
				: [];

			const peopleMap = [...s, ...dependents];
			return peopleMap;
		})
	);

	peopleAndDependentsFromCRTOnly$ = combineLatest([
		this.people$,
		this.dependents$,
	]).pipe(
		filter(([people, dependents]) => !!people && !!dependents),
		map(([people, dependents]) => {
			const list = uniqBy(prop('customerId') as any, [
				...people,
				...dependents,
			]);
			return list?.sort((item1, item2) =>
				item1.name?.localeCompare(item2.name)
			);
		})
	);

	// List of Transfered SCI
	transferedSCIList$ = combineLatest([
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
	]).pipe(
		filter(([sci, people]) => !!people && !!sci),
		map(([sci, people]) => {
			const crtPeople =
				people?.filter(
					(x) =>
						x?.customerType ===
						CustomerTypes.SecondaryCustomerIndividual?.toString()
				) || [];
			const list = crtPeople?.filter(
				(x) => !sci?.some((val) => +x?.customerId === +val?.customerID)
			);
			return list || [];
		})
	);

	trustsFromCRMAndCRT$ = combineLatest([
		this.crtMortgageQuery.secondaryTrusts$,
		this.trusts$,
	]).pipe(
		map(([sct, trusts]) => {
			const s = sct
				? (sct?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const peopleMap = [
				...s,
				...trusts?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return peopleMap?.filter((o) =>
				trusts && trusts.length > 0
					? !trusts?.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	trustsFromCRMAndCRTNoFilter$ = combineLatest([
		this.crtMortgageQuery.secondaryTrusts$,
		this.trusts$,
	]).pipe(
		map(([sct, trusts]) => {
			const s = sct
				? (sct?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const peopleMap = [
				...s,
				...trusts?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return peopleMap;
		})
	);

	companyFromCRMAndCRTNoFilter$ = combineLatest([
		this.crtMortgageQuery.secondaryCompanies$,
		this.company$,
	]).pipe(
		map(([scc, company]) => {
			const s = scc
				? (scc?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const peopleMap = [
				...s,
				...company?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return peopleMap;
		})
	);

	companyFromCRMAndCRT$ = combineLatest([
		this.crtMortgageQuery.secondaryCompanies$,
		this.company$,
	]).pipe(
		map(([scc, company]) => {
			const s = scc
				? (scc?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const peopleMap = [
				...s,
				...company?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return peopleMap?.filter((o) =>
				company && company.length > 0
					? !company?.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	/**
	 * @returns observable list of policy owners for Property
	 * from both crt and crm
	 */
	policyOwnersWithCRT$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.crtMortgageQuery.secondaryTrusts$,
		this.crtMortgageQuery.secondaryCompanies$,
		this.people$,
		this.trusts$,
		this.company$,
		this.crtMortgageQuery.isCompany$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, sct, scc, p, t, c, isCompany, linkedContacts]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? [])?.map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName?.concat(' ', x.lastName)}`
				)
			);

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			const sctList = ((sct as SecondaryTrustState[]) ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerID}`, `${x.trustName}`)
			);

			const sccList = ((scc as PrimaryCustomerCompanyState[]) ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerID}`, `${x.companyName}`)
			);

			const people = (p ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);

			const trusts = (t ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			const companies = (c ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...sctList,
				...sccList,
				...people,
				...trusts,
				...companies,
				...sclList,
			])?.filter((option) => +option.value);
		})
	);

	// For AL Kiwisavers
	peopleFromCrmAndCrtChoices$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
		this.crtMortgageQuery.isCompany$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, p, isCompany, linkedContacts]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? [])?.map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName?.concat(' ', x.lastName)}`
				)
			);

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			const people = (p ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...people,
				...sclList,
			]);
		})
	);

	peopleFromCrmAndCrtExceptChildChoices$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
		this.crtMortgageQuery.isCompany$,
	]).pipe(
		map(([pci, sci, p, isCompany]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? [])
				// .filter((s) => s.relationship !== RelationshipTypes.Child)
				.map((x) =>
					ViewDisplayValue.Map(
						`${x.customerID}`,
						`${x.firstName?.concat(' ', x.lastName)}`
					)
				);

			const people = (p ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [...pciList, ...sciList, ...people]);
		})
	);

	pciAndSciFromCrmAndCrtChoices$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
		this.dependents$,
		this.crtMortgageQuery.isCompany$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, p, d, isCompany, linkedContacts]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? [])?.map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName?.concat(' ', x.lastName)}`
				)
			);

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			const people = (p ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);

			const dependants = (d ?? [])?.map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...people,
				...dependants,
				...sclList,
			])?.filter((option) => +option.value);
		})
	);

	pciAndSciFromCrmAndCrtChoicesWithBusinessTrusts$ = combineLatest([
		this.crtMortgageQuery.primaryClient$,
		this.crtMortgageQuery.secondaryClients$,
		this.people$,
		this.dependents$,
		this.crtMortgageQuery.isCompany$,
		this.linkedContacts$,
		this.secondaryCompanies$,
		this.secondaryTrusts$,
		this.company$,
		this.trusts$,
	]).pipe(
		map(
			([
				pci,
				sci,
				p,
				d,
				isCompany,
				linkedContacts,
				businesses,
				trusts,
				moatBusinesses,
				moatTrusts,
			]) => {
				const pciList = [
					ViewDisplayValue.Map(
						`${
							(pci as PrimaryClientState | PrimaryCustomerCompanyState)
								?.customerID
						}`,
						`${
							!isCompany
								? (pci as PrimaryClientState)?.firstName?.concat(
										' ',
										(pci as PrimaryClientState)?.lastName
								  )
								: (pci as PrimaryCustomerCompanyState)?.companyName
						}`
					),
				];

				const sciList = ((sci as SecondaryClientState[]) ?? [])?.map((x) =>
					ViewDisplayValue.Map(
						`${x.customerID}`,
						`${x.firstName?.concat(' ', x.lastName)}`
					)
				);

				const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
					(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
				);

				const people = (p ?? [])?.map((x) =>
					ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
				);

				const dependants = (d ?? [])?.map((x) =>
					ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
				);

				const businessList = (businesses ?? [])?.map((x) =>
					ViewDisplayValue.Map(`${x.customerID}`, `${x.companyName}`)
				);

				const trustList = (trusts ?? [])?.map((x) =>
					ViewDisplayValue.Map(`${x.customerID}`, `${x.trustName}`)
				);

				const moatBusinessList = (moatBusinesses ?? [])?.map((x) =>
					ViewDisplayValue.Map(`${x?.customerId}`, `${x.name}`)
				);

				const moatTrustList = (moatTrusts ?? [])?.map((x) =>
					ViewDisplayValue.Map(`${x?.customerId}`, `${x.name}`)
				);

				return uniqBy(prop('value'), [
					...pciList,
					...sciList,
					...people,
					...dependants,
					...sclList,
					...businessList,
					...trustList,
					...moatBusinessList,
					...moatTrustList,
				])?.filter((option) => +option.value);
			}
		)
	);

	deceasedSciList$ = combineLatest([
		this.crtMortgageQuery.secondaryClients$,
		this.linkedContacts$,
	]).pipe(
		map(([sci, lci]) => {
			const sciList = ((sci as SecondaryClientState[]) ?? [])?.filter(
				(item) => item.contactMethod === 'Deceased'
			);

			const lciList = ((lci as LinkedContactState[]) ?? [])
				?.filter((item) => item.contactMethod === 'Deceased')
				?.map((item) => ({
					...item,
					customerID: item.linkedFromPrimaryCustomer
						? item.relatedCustomerId
						: item.customerId,
				}));

			return [...sciList, ...lciList];
		})
	);
}
