import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	QueryList,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewChildren,
	ViewContainerRef,
} from '@angular/core';
import { ServicesCodes } from '@shared/models/services/services.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Observable, Observer, Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { PropertyAssetsTypeModalComponent } from '../../modal/property-assets-type-modal/property-assets-type-modal.component';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import {
	AssetCustomerServiceState,
	AssetState,
	LiabilityCustomerServiceState,
	LiabilityState,
} from '../../models/services/assets/assets';
import {
	PropertyAssetCustomerServiceState,
	PropertyStatus,
} from '../../models/services/property-asset/property-asset';
import { PropertyAssetState } from '../../models/services/property-asset/property-asset-group.model';
import { AssetsServiceFormComponent } from '../assets-service-form/assets-service-form.component';
import { LiabilityServiceComponent } from '../liability-service/liability-service.component';
import { PropertyAssetsFormComponent } from '../property-assets-form/property-assets-form.component';
import { mainGroupSorter } from '../service-utils/property-asset-sorter.util';

@Component({
	selector: 'app-property-assets-service',
	templateUrl: './property-assets-service.component.html',
	styleUrls: ['./property-assets-service.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyAssetsServiceComponent
	implements OnInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;
	@Input() isLead: boolean;
	@Input() isCompany: boolean;
	@Input() addFn$: (data) => Observable<any>;
	@Input() saveFn$: (data) => Observable<any>;
	@Input() deleteFn$: (data) => Observable<any>;
	@Input() archiveFn$: ({ data, isArchive }) => Observable<any>;
	@Input() deleteNoteFn$: ({ id, data }) => Observable<any>;
	@Input() isLoading$: Observable<boolean>;
	@Input() addNoteFn$: (data) => Observable<any>;
	@Input() addNoteAssetFn$: (data) => Observable<any>;
	@Input() deleteNoteAssetFn$: ({ id, data }) => Observable<any>;
	@Input() addAssetFn$: (data) => Observable<any>;
	@Input() saveAssetFn$: (data) => Observable<any>;
	@Input() deleteAssetFn$: (data) => Observable<any>;
	@Input() soldAssetFn$: (data) => Observable<any>;

	@Input() addNoteLiabilityFn$: (data) => Observable<any>;
	@Input() deleteNoteLiabilityFn$: ({ id, data }) => Observable<any>;

	private _saveLiabilityFn$: (
		data: LiabilityCustomerServiceState
	) => Observable<boolean>;
	@Input()
	set saveLiabilityFn$(
		save: (data: LiabilityCustomerServiceState) => Observable<boolean>
	) {
		this._saveLiabilityFn$ = (data) =>
			save(data).pipe(
				tap((result) => {
					if (result) {
						this.addNewLiability = false;
					}
				})
			);
	}
	get saveLiabilityFn$() {
		return this._saveLiabilityFn$;
	}
	// @Input() saveLiabilityFn$: (
	// 	data: LiabilityCustomerServiceState
	// ) => Observable<boolean>;

	@Input() updateLiabilityFn$: (
		data: LiabilityCustomerServiceState
	) => Observable<boolean>;
	@Input() deleteLiabilityFn$: (
		data: LiabilityCustomerServiceState
	) => Observable<boolean>;

	@Input() propertyOwners: ViewDisplayValue[];
	@Input() assetOwners: ViewDisplayValue[];
	@Input() valuations1: ViewDisplayValue[];
	@Input() valuations2: ViewDisplayValue[];
	@Input() types: ViewDisplayValue[];
	@Input() uses: ViewDisplayValue[];
	@Input() titles: ViewDisplayValue[];
	@Input() status: ViewDisplayValue[];
	@Input() paDropTypes: ViewDisplayValue[];
	@Input() liabilityStatus: ViewDisplayValue[];
	@Input() loanTypes: ViewDisplayValue[];
	@Input() lenders: ViewDisplayValue[];
	@Input() repaymentFrequency: ViewDisplayValue[];
	@Input() liabilities: ViewDisplayValue[];

	@Input() customerId: number;
	@Input() propertyAsset: PropertyAssetState;
	@Input() document: DocumentGroupState;
	@Input() assetState: AssetState;
	@Input() liabilityState: LiabilityState = {
		totalValue: 0,
		notes: [],
		customerServices: [],
	};

	// weather Assets & Liabilities tab is active
	@Input() isActive = false;

	@ViewChild('sidebarNav') sidebarNav: TabsetComponent;
	@ViewChildren('paForms') paForms: QueryList<PropertyAssetsFormComponent>;
	@ViewChild('assets') asset: AssetsServiceFormComponent;
	@ViewChild('soldAssetsList') soldAssetsList: AssetsServiceFormComponent;

	@ViewChild('currentLiabilityService')
	currentLiabilityService: LiabilityServiceComponent;
	@ViewChild('dischargedLiabilityService')
	dischargedLiabilityService: LiabilityServiceComponent;

	propertyAssetState: PropertyAssetState;

	activeGroupNavId: string;
	activeProvider: string;

	isAddNew = false;
	isSaving = false;
	isAddNewGroup = false;
	isShowArchived = false;
	addNewAsset = false;
	assetSold = 'Sold';
	assetCurrent = 'Current';

	currentAssets: AssetState;
	soldAssets: AssetState;
	currentAssetsTabId = 'currentAssetsTab';
	soldAssetsTabId = 'soldAssetsTab';

	// _addNewLiability = false;
	// set addNewLiability(val: boolean) {
	// 	this._addNewLiability = val;
	// }
	// get addNewLiability() {
	// 	return this._addNewLiability;
	// }

	addNewLiability = false;
	isEditingLiability = false;

	liabilityCurrent: LiabilityState = {
		notes: [],
		totalValue: 0,
		customerServices: [],
	};
	liabilityDischarged: LiabilityState;

	liabilityCurrentId = 'currentLiabilityTab';
	liabilityDischargedId = 'dischargedLiabilityTab';

	assetTypes = {
		current: 'current',
		sold: 'sold',
	};

	liabilityTypes = {
		current: 'Current Liability',
		discharged: 'Discharged',
	};

	nonAssetProperty: any[];

	propertiesAndAssets: any[] = [];

	hasCurrentAssets: boolean;

	hasCurrentLiability = false;

	hasDischargedLiability = false;

	tablist: any[] = [];

	@ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
	@ViewChild('content', { read: TemplateRef }) contentRef: TemplateRef<any>;

	get PortfolioValue(): number {
		// FORMULA: TAP1-2500 Current Properties Total
		return this.propertiesAndAssets.reduce((prev, cur) => {
			// Property with current status
			if (
				cur.serviceCode === ServicesCodes.Security &&
				cur.status === PropertyStatus.Current &&
				+cur.isActive === 1
			) {
				return prev + (cur.gRM1Value ?? 0);
			}
			return prev;
		}, 0);
	}

	get showNewDischargedLiabilityTab(): boolean {
		return (
			this.addNewLiability &&
			!this.hasDischargedLiability &&
			this.activeGroupNavId === this.liabilityDischargedId
		);
	}

	get showNewCurrentLiabilityTab(): boolean {
		return (
			this.addNewLiability &&
			!this.hasCurrentLiability &&
			this.activeGroupNavId === this.liabilityCurrentId
		);
	}

	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.isActive?.currentValue) {
				setTimeout(() => {
					// this.currentLiabilityService?.disableAdding();
					// this.dischargedLiabilityService?.disableAdding();

					if (
						(this.activeGroupNavId === this.liabilityDischargedId &&
							!this.liabilityDischarged.customerServices?.length) ||
						(this.activeGroupNavId === this.liabilityCurrentId &&
							!this.liabilityCurrent.customerServices?.length)
					) {
						this.selectLastTab();
					}
				});
			}

			if (
				changes.propertyAsset &&
				changes.propertyAsset.currentValue !== null
			) {
				this.getNonAssetProperty();
			} else if (changes.liabilityState) {
				this.getNonAssetProperty();
				switch (this.activeGroupNavId) {
					case this.liabilityDischargedId:
					case this.liabilityCurrentId:
						this.navigateLiabilityTab(this.activeGroupNavId);
						break;
					default:
						this.selectLastTab();
						break;
				}
			} else if (changes.assetState) {
				this.addNewAsset = false;
				this.getNonAssetProperty();

				this.refresh();
				this.asset?.buildForm();
				this.asset?.prepareData();

				if (this.soldAssets.customerServices.length) {
					this.soldAssetsList?.buildForm();
					this.soldAssetsList?.prepareData();
				}

				const prevCurrentAssets =
					changes?.assetState?.previousValue?.customerServices?.filter(
						(x) => x?.status !== this.assetSold
					);
				const newCurrentAssets =
					changes?.assetState?.currentValue?.customerServices?.filter(
						(x) => x?.status !== this.assetSold
					);
				const prevSoldAssets =
					changes?.assetState?.previousValue?.customerServices?.filter(
						(x) => x?.status === this.assetSold
					);
				const newSoldAssets =
					changes?.assetState?.currentValue?.customerServices?.filter(
						(x) => x?.status === this.assetSold
					);
				if (
					this.activeGroupNavId === this.currentAssetsTabId &&
					!prevCurrentAssets?.length &&
					!!newCurrentAssets?.length
				) {
					this.selectTabById(this.currentAssetsTabId);
					return;
				} else if (
					this.activeGroupNavId !== this.soldAssetsTabId &&
					!prevSoldAssets?.length &&
					!!newSoldAssets?.length
				) {
					this.rerender();
					this.navigateAssetTab(this.assetTypes.sold);
					return;
				}
			}

			if (
				this.propertyAssetState &&
				this.propertyAssetState.customerServices.length > 0 &&
				!this.activeGroupNavId
			) {
				this.activeGroupNavId =
					this.propertyAssetState.customerServices[0].propertyAddress;
			}

			if (this.activeGroupNavId) {
				this.refresh();
				return setTimeout(() => {
					const i =
						this.sidebarNav?.tabs?.findIndex(
							(x) => x?.id == this.activeGroupNavId
						) ?? -1;
					const tab = this.sidebarNav?.tabs[i];
					if (tab) {
						this.sidebarNav.tabs[i].active = true;
						this.refresh();
					} else {
						this.selectLastTab();
					}
				}, 100);
			}
		}
	}

	getNonAssetProperty() {
		const nonAssetProperties =
			this.propertyAsset?.customerServices?.filter((cs: any) => !cs.asset) ||
			[];

		const currentAssetList: AssetCustomerServiceState[] = [];
		const soldAssetList: AssetCustomerServiceState[] = [];
		let currentTotal = 0;
		let soldTotal = 0;

		const currentAssetsValue = this.assetState;

		currentAssetsValue?.customerServices?.forEach(
			(asset: AssetCustomerServiceState) => {
				if (asset.status === 'Sold') {
					soldAssetList.push(asset);
					soldTotal = soldTotal + asset.value;
				} else {
					currentAssetList.push(asset);
					currentTotal = currentTotal + asset.value;
				}
			}
		);

		this.currentAssets = {
			...currentAssetsValue,
			totalValue: currentTotal,
			customerServices: [...currentAssetList],
		};

		this.soldAssets = {
			...currentAssetsValue,
			totalValue: soldTotal,
			customerServices: [...soldAssetList],
		};

		let list: any[] = [...nonAssetProperties];

		if (currentAssetList.length > 0) {
			list = [
				...list,
				{
					...this.currentAssets,
					color: 'green',
					assetType: this.assetTypes.current,
					status: 'Current Asset',
					value: this.currentAssets.totalValue,
					customerServiceID:
						this.currentAssets?.customerServices[0]?.customerServiceID,
					isActive: 1,
				},
			];
			this.hasCurrentAssets = true;
		} else {
			this.hasCurrentAssets = false;
		}

		if (soldAssetList.length > 0) {
			list = [
				...list,
				{
					...this.soldAssets,
					color: 'dark',
					assetType: this.assetTypes.sold,
					status: 'Sold Asset',
					value: this.soldAssets.totalValue,
					customerServiceID:
						this.soldAssets?.customerServices[0]?.customerServiceID,
					isActive: 1,
				},
			];
		}

		const currentLiabilities =
			this.liabilityState?.customerServices?.filter(
				(c) => c.status === this.liabilityTypes.current
			) ?? [];

		this.liabilityCurrent = {
			customerServices: currentLiabilities,
			notes: this.liabilityState?.notes ?? [],
			totalValue: currentLiabilities.reduce((prev, cur) => {
				return prev + (+cur?.loanLimit ?? 0);
			}, 0),
		};

		const dischargedLiabilities =
			this.liabilityState?.customerServices?.filter(
				(c) => c.status === this.liabilityTypes.discharged
			) ?? [];
		this.liabilityDischarged = {
			customerServices: dischargedLiabilities,
			notes: [],
			totalValue: dischargedLiabilities.reduce((prev, cur) => {
				return prev + (+cur?.loanLimit ?? 0);
			}, 0),
		};

		if (this.liabilityCurrent?.customerServices?.length) {
			list = [
				...list,
				{
					...this.liabilityCurrent,
					color: 'green',
					assetType: this.liabilityTypes.current,
					status: this.liabilityTypes.current,
					value: 0,
					customerServiceID:
						this.liabilityCurrent?.customerServices[0]?.customerServiceID,
					isActive: 1,
				},
			];
		}

		if (this.liabilityDischarged?.customerServices?.length) {
			list = [
				...list,
				{
					...this.liabilityDischarged,
					color: 'dark',
					assetType: this.liabilityTypes.discharged,
					status: this.liabilityTypes.discharged,
					value: 0,
					customerServiceID:
						this.liabilityDischarged?.customerServices[0]?.customerServiceID,
					isActive: 1,
				},
			];
		}

		this.hasCurrentLiability = Boolean(
			this.liabilityCurrent?.customerServices?.length
		);
		this.hasDischargedLiability = Boolean(
			this.liabilityDischarged?.customerServices?.length
		);

		const sorted = list.sort(mainGroupSorter);

		this.propertiesAndAssets = sorted;
	}

	rerender() {
		if (!this.outletRef) {
			return;
		}
		this.outletRef.clear();
		this.outletRef.createEmbeddedView(this.contentRef);
	}

	ngOnInit() {
		this.isLoading$
			.pipe(
				filter((x) => !x),
				tap(() => {
					this.prepareData();
					this.refresh();
					// this.reOrderAssetsTab();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	prepareData() {
		if (!this.propertyAsset) {
			this.propertyAssetState = Object.assign(
				{},
				{
					totalValue: 0,
					customerServices: [],
				}
			);
		}

		if (!this.assetState) {
			this.assetState = Object.assign(
				{},
				{
					notes: [],
					customerServices: [],
				}
			);
		}

		if (
			!this.propertyAssetState ||
			this.propertyAssetState.customerServices.length < 1
		) {
			this.activeGroupNavId = '';
			this.cd.detectChanges();
		} else {
			this.activeGroupNavId =
				this.propertyAssetState.customerServices[0].customerServiceID?.toString();
			this.cd.detectChanges();
		}
	}

	/**
	 * Show/Hide archived services
	 * @param isShowArchived boolean
	 */
	showArchived(isShowArchived: boolean) {
		this.isShowArchived = isShowArchived;
	}

	selectTab() {
		let count = 0;
		if (this.isShowArchived) {
			count = this.propertiesAndAssets?.filter((x) => x).length - 1;
		} else {
			count =
				this.propertiesAndAssets?.filter((x) => x.isActive === 1).length - 1;
		}

		setTimeout(() => {
			if (this.sidebarNav?.tabs?.[count]) {
				this.sidebarNav.tabs[count].active = true;
				this.activeGroupNavId = this.sidebarNav.tabs[count].id;
				this.cd.detectChanges();
			}
		}, 100);
	}

	selectLastTab() {
		setTimeout(() => {
			if (this.sidebarNav?.tabs?.length) {
				this.sidebarNav.tabs[this.sidebarNav?.tabs?.length - 1].active = true;
				this.activeGroupNavId =
					this.sidebarNav?.tabs?.[this.sidebarNav?.tabs?.length - 1]?.id;
				this.cd.detectChanges();
			} else {
				this.activeGroupNavId = '';
				this.cd.detectChanges();
			}
		}, 500);
	}

	refresh() {
		this.cd.detectChanges();
	}

	trackByFn(index: number, item: PropertyAssetCustomerServiceState) {
		return index;
	}

	trackById(index: number, item: PropertyAssetCustomerServiceState) {
		return item.customerServiceID;
	}

	createSecurity(data) {
		this.isSaving = true;
		this.isAddNewGroup = false;
		data.customerID = this.customerId;

		this.addFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isAddNew = false;
						this.isSaving = false;
						setTimeout(() => {
							const index = this.sidebarNav?.tabs?.findIndex(
								(x) => x.id == data.customerServiceID
							);
							this.sidebarNav.tabs[index].active = true;
							this.activeGroupNavId = data.customerServiceID;
							this.cd.detectChanges();
						}, 200);
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	saveSecurity(data) {
		this.paForms
			?.find((x) => x.form.value.customerServiceID === data.customerServiceID)
			.formSaving(true);

		this.isAddNewGroup = false;
		data.customerID = this.customerId;

		this.saveFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
						this.paForms
							?.find(
								(pa) =>
									pa.form.value.customerServiceID === data.customerServiceID
							)
							.formSaving(false);
						this.paForms
							?.find(
								(pa) =>
									pa.form.value.customerServiceID === data.customerServiceID
							)
							.editForm(false);
						setTimeout(() => {
							const index = this.sidebarNav?.tabs?.findIndex(
								(x) => x.id == data.customerServiceID
							);
							this.sidebarNav.tabs[index].active = true;
							this.activeGroupNavId = data.customerServiceID;
							this.cd.detectChanges();
						}, 200);
						this.cd.detectChanges();
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.paForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					const p = this.paForms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (p) {
						p.formSaving(false);
						p.prepData();
					}
				},
				() => {
					this.paForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	deleteSecurity(data) {
		this.isSaving = true;
		data.customerID = this.customerId;

		this.deleteFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					setTimeout(() => {
						// this.reOrderAssetsTab();
						this.selectTab();
					}, 100);
					this.isSaving = false;
					this.refresh();
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	archiveSecurity(req: { propertyAsset; isArchive }) {
		this.isSaving = true;
		req.propertyAsset.customerID = this.customerId;

		this.archiveFn$({ data: req.propertyAsset, isArchive: req.isArchive })
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	addAsset(data) {
		this.isSaving = true;
		this.addAssetFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.asset.formSaving(false);
			});
	}

	saveAsset(data) {
		this.isSaving = true;
		this.saveAssetFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.asset.formSaving(false);
			});
	}

	deleteAsset(data) {
		this.isSaving = true;
		this.cd.detectChanges();
		this.deleteAssetFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
					this.refresh();
					this.asset?.refresh();
					this.asset?.prepNotes();
					if (this.assetState?.customerServices?.length <= 1) {
						setTimeout(() => {
							this.selectTab();
						}, 100);
					}
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.asset?.formSaving(false);
					this.isSaving = false;
				}
			);
	}

	soldAsset(data) {
		this.isSaving = true;
		this.soldAssetFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
					this.refresh();
					this.asset.refresh();
					this.asset.prepNotes();
					this.navigateAssetTab(this.assetTypes.sold);
				},
				() => {
					this.isSaving = false;
					this.navigateAssetTab(this.assetTypes.sold);
				},
				() => {
					this.asset.formSaving(false);
					this.isSaving = false;
					this.navigateAssetTab(this.assetTypes.sold);
				}
			);
	}

	addNoteAsset(data) {
		this.isSaving = true;
		this.addNoteAssetFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.refresh();
				this.asset.refresh();
				this.asset.prepNotes();
				this.asset.formSaving(false);
			});
	}

	deleteNoteAsset = (req: { noteId; data }) => {
		this.isSaving = true;
		req.data.customerID = this.customerId;

		this.deleteNoteAssetFn$({
			id: req.noteId,
			data: req.data,
		})
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
					this.asset.prepNotes();
					this.asset.formSaving(false);
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	};

	addNoteLiability(data) {
		this.isSaving = true;
		this.addNoteLiabilityFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				// this.refresh();
				// this.asset.refresh();
				// this.asset.prepNotes();
				// this.asset.formSaving(false);
			});
	}

	deleteNoteLiability = (req: { noteId: number; data: any }) => {
		this.isSaving = true;
		req.data.customerID = this.customerId;

		this.deleteNoteLiabilityFn$({
			id: req.noteId,
			data: req.data,
		})
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	};

	addNewGroup() {
		this.isAddNew = true;
		this.isAddNewGroup = true;

		// Should add new data on the list
		const customerServices = {
			propertyAddress: '',
			propertyOwner: '',
			valuation1: '',
			valuation2: '',
			gRM1Value: 0,
			gRM2Value: 0,
			gRM1Date: '',
			gRM2Date: '',
			type: '',
			rentalIncome: 0,
			use: '',
			title: '',
			status: '',
			customerServiceID: 0,
			customerID: 0,
			serviceCode: '',
			documentLink: '',
			purchaseDate: '',
			note: '',
			isActive: 1,
			color: 'dark',
			isNew: true,
		};

		this.propertiesAndAssets = [...this.propertiesAndAssets, customerServices];

		this.refresh();
		setTimeout(() => {
			// this.reOrderAssetsTab();
			this.selectTab();
		}, 10);
	}

	navigateAssetTab(status: string) {
		if (!status) {
			return;
		}
		const i = this.propertiesAndAssets?.findIndex(
			(x) => x?.assetType === status
		);
		if (i > -1) {
			setTimeout(() => {
				if (this.sidebarNav?.tabs[i]) {
					this.sidebarNav.tabs[i].active = true;
				}
				this.cd.detectChanges();
			}, 100);
		}
		this.activeGroupNavId =
			status === this.assetTypes.current
				? this.currentAssetsTabId
				: this.soldAssetsTabId;
		this.cd.detectChanges();
	}

	navigateLiabilityTab(
		tabId: typeof this.liabilityCurrentId | typeof this.liabilityDischargedId
	): void {
		setTimeout(() => {
			const tabIndex = this.sidebarNav?.tabs?.findIndex((t) => t.id === tabId);
			if (tabIndex !== -1) {
				this.sidebarNav.tabs[tabIndex].active = true;
				this.activeGroupNavId = this.sidebarNav?.tabs?.[tabIndex]?.id;
				this.cd.detectChanges();
			} else {
				this.selectLastTab();
				this.cd.detectChanges();
			}
		}, 100);
	}

	selectAssetTab(tabId) {
		this.disableLiabilityEditing();
		setTimeout(() => {
			if (this.activeGroupNavId?.toString() !== tabId?.toString()) {
				// this.deselectLiabilityTab();
				this.removedLiabilityTab();
				this.selectTabById(tabId?.toString());
				this.cd.detectChanges();
			}
			// if (this.activeGroupNavId !== tabId) {
			// 	const currentSelectedTabId = this.sidebarNav?.tabs?.findIndex(
			// 		(x) => x.id === this.activeGroupNavId
			// 	);
			// 	if (currentSelectedTabId > -1) {
			// 		this.sidebarNav.tabs[currentSelectedTabId].active = false;
			// 		this.activeGroupNavId = this.sidebarNav?.tabs?.[currentSelectedTabId]?.id;
			// 	}
			// 	this.activeGroupNavId = tabId;
			// 	const assetTabIndex = this.sidebarNav?.tabs?.findIndex(
			// 		(x) => x.id === this.activeGroupNavId
			// 	);
			// 	if (assetTabIndex > -1 && assetTabIndex < this.sidebarNav?.tabs?.length) {
			// 		this.sidebarNav.tabs[assetTabIndex].active = true;
			// 		this.activeGroupNavId = this.sidebarNav?.tabs?.[assetTabIndex]?.id;
			// 		this.cd.detectChanges();
			// 	}
			// }
		}, 100);
	}

	disableLiabilityEditing(): void {
		this.currentLiabilityService?.disableEditing();
		this.dischargedLiabilityService?.disableEditing();
	}

	reOrderAssetsTab() {
		if (!this.sidebarNav) {
			return;
		}
		const assetsTabIndex = this.sidebarNav.tabs?.findIndex(
			(value) =>
				value.id === this.soldAssetsTabId ||
				value.id === this.currentAssetsTabId
		);
		if (assetsTabIndex > -1) {
			this.sidebarNav.tabs?.sort((a, b) => {
				if (a.id === this.currentAssetsTabId) {
					if (b.id === this.soldAssetsTabId) {
						return -1;
					} else {
						return 1;
					}
				} else if (b.id === this.currentAssetsTabId) {
					if (a.id === this.soldAssetsTabId) {
						return 1;
					} else {
						return -1;
					}
				} else if (b.id === this.soldAssetsTabId) {
					return -1;
				} else if (a.id === '0') {
					if (
						b.id === this.soldAssetsTabId ||
						b.id === this.currentAssetsTabId
					) {
						return -1;
					} else {
						return 1;
					}
				}
			});
		}
	}

	deselectCurrentTab() {
		const activeIndex = this.sidebarNav?.tabs?.findIndex(
			(x) => x.active === true
		);
		if (activeIndex > -1) {
			this.sidebarNav.tabs[activeIndex].active = false;
			this.cd.detectChanges();
		}
	}

	selectGroupConfirm(tabId, sec) {
		this.disableLiabilityEditing();
		if (!tabId || +tabId === 0) {
			return;
		}

		if (this.activeGroupNavId?.toString() !== tabId?.toString()) {
			// this.deselectLiabilityTab();
			this.removedLiabilityTab();
		}

		if (this.isAddNewGroup && this.isAddNew) {
			this.selectGroup();
		} else {
			this.selectTabById(tabId?.toString());
		}

		this.activeGroupNavId = tabId?.toString();
	}

	selectGroup() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.isAddNew = false;
			this.isAddNewGroup = false;
			this.propertiesAndAssets?.pop();
			this.refresh();
			this.selectLastTab();
			obs.complete();
		});

		const initState = {
			header: 'Discard Confirmation',
			message: `Current information will be discarded?`,
			confirm$: confirm,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		setTimeout(() => {
			this.activeGroupNavId = this.sidebarNav?.tabs?.find((x) => x.active)?.id;
			return this.selectTab();
		}, 100);
	}

	deleteNote = (req: { noteId; data }) => {
		this.isSaving = true;
		req.data.customerID = this.customerId;

		this.deleteNoteFn$({
			id: req.noteId,
			data: req.data,
		})
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
						setTimeout(
							() =>
								(this.sidebarNav.tabs.find(
									(t) => t.id === this.activeGroupNavId
								).active = true),
							100
						);
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				},
				() => {
					this.isSaving = false;
				}
			);
	};

	cancelAddNewGroup(cancel: boolean) {
		this.isAddNew = cancel;
		this.isAddNewGroup = false;
		this.propertiesAndAssets = this.propertiesAndAssets.filter(
			(property) => !property.isNew
		);
		if (this.sidebarNav?.tabs[0]?.id) {
			this.selectTabById(this.sidebarNav?.tabs[0]?.id);
		}
	}

	addNote(data) {
		this.isAddNewGroup = false;
		data.customerID = this.customerId;

		this.addNoteFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.isSaving = false;
						this.paForms
							?.find(
								(pa) =>
									pa.form.value.customerServiceID === data.customerServiceID
							)
							.formSaving(false);
					}
				}),
				catchError((err) => {
					this.isSaving = false;
					return throwError(new Error(err));
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.paForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					const p = this.paForms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (p) {
						p.formSaving(false);
						p.prepData();
					}
				},
				() => {
					this.paForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	createModal() {
		const initState = {
			header: 'Select a type:',
			KT: this.paDropTypes,
		};

		this.bsModalRef = this.modalService.show(PropertyAssetsTypeModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.bsModalRef.content.selectEvt.subscribe((selected) => {
			switch (selected) {
				case 'Property':
					this.addNewProperty();
					break;
				case 'Asset':
					this.addNewAsset = true;
					this.refresh();
					this.asset?.addNew();
					this.refresh();
					this.asset?.refresh();

					this.deselectCurrentTab();
					this.selectTabById(this.currentAssetsTabId);
					this.refresh();
					break;
				case 'Liability':
					this.addNewLiabilityData();
					break;
			}
		});

		this.refresh();
	}

	addNewProperty() {
		this.addNewGroup();
		this.addNewAsset = false;
	}

	addNewLiabilityData(): void {
		const isCurrentLiability =
			this.activeGroupNavId === this.liabilityCurrentId;
		const isDischargedLiability =
			this.activeGroupNavId === this.liabilityDischargedId;

		this.activeGroupNavId = isCurrentLiability
			? this.liabilityCurrentId
			: isDischargedLiability
			? this.liabilityDischargedId
			: this.liabilityCurrentId;

		this.addNewLiability = true;
		this.refresh();
		// deselect tab if not liability
		if (!isCurrentLiability && !isDischargedLiability) {
			this.deselectCurrentTab();
		}
		this.selectTabById(this.activeGroupNavId);

		if (this.activeGroupNavId === this.liabilityDischargedId) {
			this.dischargedLiabilityService?.addNew();
			this.refresh();
		} else {
			this.currentLiabilityService?.addNew();
			this.refresh();
		}
	}

	selectTabById(id: string) {
		setTimeout(() => {
			const tabIndex = this.sidebarNav?.tabs?.findIndex((tab) => tab.id === id);
			if (tabIndex > -1) {
				this.sidebarNav.tabs[tabIndex].active = true;
				this.activeGroupNavId = this.sidebarNav?.tabs?.[tabIndex]?.id;
				this.cd.detectChanges();
			}
		}, 100);
	}

	stringify() {
		return JSON.stringify(
			this.propertiesAndAssets.map((a) => a.customerServiceID)
		);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	onAddedLiability(e: any): void {
		this.addNewLiability = false;
		this.cd.detectChanges();
	}

	onCancelAddOrEdit(e: any): void {
		this.addNewLiability = false;
		this.isEditingLiability = false;
		this.cd.detectChanges();

		if (
			!this.liabilityCurrent.customerServices?.length &&
			!this.liabilityDischarged.customerServices?.length
		) {
			this.selectLastTab();
		}
	}

	onEditLiability(e: any): void {
		this.isEditingLiability = true;
		this.cd.detectChanges();
	}

	deselectLiabilityTab() {
		if (this.currentLiabilityService) {
			if (this.currentLiabilityService.isAdd === true) {
				this.addNewLiability = false;
			}
			this.currentLiabilityService.liabilities =
				this.currentLiabilityService.liabilities.map((x) => ({
					...x,
					isAdd: false,
				}));

			this.currentLiabilityService.isAdd = false;
			this.cd.detectChanges();
		}
		if (this.dischargedLiabilityService) {
			if (this.dischargedLiabilityService.isAdd === true) {
				this.addNewLiability = false;
			}
			this.dischargedLiabilityService.liabilities =
				this.dischargedLiabilityService.liabilities.map((x) => ({
					...x,
					isAdd: false,
				}));

			this.dischargedLiabilityService.isAdd = false;
			this.cd.detectChanges();
		}
	}

	removedLiabilityTab() {
		this.addNewLiability = false;
	}
}
