<!-- Footer -->
<ng-container *ngIf="!isLoading; else loader">
	<button
		class="tap-btn tap-btn--default py-1 mr-1 btn-prev"
		*ngIf="pageIndex > 0"
		[disabled]="disabledPrev"
		(click)="previous()"
	>
		{{ prevText }}
	</button>
	<button
		class="tap-btn tap-btn--primary text-white btn-next"
		*ngIf="pageIndex < totalSidebarItems - 1"
		[disabled]="disabledNext"
		(click)="next()"
	>
		{{ nextText }}
	</button>
	<!-- <button
		class="tap-btn tap-btn--primary text-white d-block d-lg-none"
		(click)="pauseAndExit()"
	>
		Pause & Exit
	</button> -->
</ng-container>
<ng-template #loader>
	<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
</ng-template>
