import * as R from 'ramda';
import { PropertyPurchaseDetailsState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/property-purchase/state/property-purchase.model';
import { ApplicationSecurityDetails } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/review-application/state/review-application.model';
import { Security } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/security/state/security.model';
import { PropertyAddressState } from 'src/app/modules/crm/crt-page/crt-mortgage/client-sop/assets-and-liabilities/state/property/property.store';
import { computeMoatAppUtil } from 'src/app/modules/crm/crt-page/_shared/calculations/moat-application';
import { numUtil, util } from 'src/app/util/util';
import { appSecurityMergeTag, appSecurityMetaKey } from './security.merge-tags';
import * as numeral from 'numeral';

export class AppSecurityMergeTagMapper {
	public static getMergeTagValues(
		propertyOwners,
		security: Security,
		ffProperties: PropertyAddressState[] | any[],
		propertyPurchase: PropertyPurchaseDetailsState[] | any[],
		computations: any
	) {
		const securities = this.getSecurityDetails(
			security,
			ffProperties,
			propertyPurchase
		);
		return appSecurityMergeTag?.map((x) => {
			let value = [];
			if (x?.metaKey === appSecurityMetaKey.propertyValue) {
				value = securities?.map((i) => this.formatCurrency(i?.propertyValue));
			}
			if (x?.metaKey === appSecurityMetaKey.lvr) {
				value = this.getLvr(securities)?.map((i) => `x${i}%`);
			}
			if (x?.metaKey === appSecurityMetaKey.maxLoanAmt) {
				value = this.getMaxLoanAmount(securities)?.map((i) =>
					this.formatCurrency(i)
				);
			}
			if (x?.metaKey === appSecurityMetaKey.valueType) {
				value = securities?.map((i) => i?.valuationType || '');
			}
			if (x?.metaKey === appSecurityMetaKey.propertyType) {
				value = securities?.map((i) => i?.propertyUse || '');
			}
			if (x?.metaKey === appSecurityMetaKey.propertyAddress) {
				value = securities?.map((i) => i?.securityAddress || '');
			}
			if (x?.metaKey === appSecurityMetaKey.ownershipEntity) {
				value = this.getBorrowers(propertyOwners, securities);
			}
			if (x?.metaKey === appSecurityMetaKey.allPropertyAddress) {
				value = [this.getAllSecurityAddressCombined(securities)];
			}
			if (x?.metaKey === appSecurityMetaKey.totalPropertyValue) {
				value = [this.getTotalPropertyValue(securities)];
			}
			if (x?.metaKey === appSecurityMetaKey.totalPropertyLoan) {
				value = [this.getTotalMaxLoanAmount(securities)];
			}
			if (x?.metaKey === appSecurityMetaKey.ppFormula) {
				value = [this.getPpFormula(propertyPurchase)];
			}
			if (x?.metaKey === appSecurityMetaKey.lvr1Formula) {
				value = [this.getLvr1Formula(securities, computations)];
			}
			if (x?.metaKey === appSecurityMetaKey.lvr2Formula) {
				value = [this.getLvr2Formula(securities, computations)];
			}
			if (x?.metaKey === appSecurityMetaKey.scaledLvrFormula) {
				value = [this.getScaledLvrFormula(securities, computations)];
			}
			return {
				...x,
				value,
			};
		});
	}

	public static getSecurityDetails(
		security: Security,
		ffProperties: PropertyAddressState[],
		propertyPurchase: PropertyPurchaseDetailsState[]
	) {
		const list = security?.bankWillTake?.filter((x) => !!x?.isTick) || [];

		return list?.map((x) => {
			const data = x?.fromFactFind
				? ffProperties?.find((i) => +i?.cRTId === +x?.aLPropertiesCrtId)
				: propertyPurchase?.find((i) => +i?.cRTId === +x?.aLPropertiesCrtId);

			return {
				...data,
				securityAddress: x?.propertyAddress,
				fromFactFind: x?.fromFactFind,
				aLPropertiesCrtId: x?.aLPropertiesCrtId,
				amount: x?.amount,
				isTick: x?.isTick,
			};
		}) as ApplicationSecurityDetails[];
	}

	public static getLvr(securities: ApplicationSecurityDetails[]) {
		return securities?.map((x) => {
			return x?.propertyUse === 'Investment' ? 70 : 80;
		});
	}

	public static getMaxLoanAmount(securities: ApplicationSecurityDetails[]) {
		return computeMoatAppUtil.maxLoanAmount(securities) || [];
	}

	public static getTotalMaxLoanAmount(
		securities: ApplicationSecurityDetails[]
	) {
		const result = computeMoatAppUtil.totalMaxLoanAmount(securities) || 0;
		return this.formatCurrency(result);
	}

	public static getTotalPropertyValue(
		securities: ApplicationSecurityDetails[]
	) {
		const result = computeMoatAppUtil.totalPropertyValue(securities) || 0;
		return this.formatCurrency(result);
	}

	public static getBorrowers(
		propertyOwners,
		securities: ApplicationSecurityDetails[]
	) {
		return securities?.map((x) => {
			const owners = this.parseData(x?.propertyOwner);
			return owners
				?.map((i) => {
					const data = propertyOwners?.find((o) => +o?.value === +i);
					return data?.display || '';
				})
				?.join(', ');
		});
	}

	public static getAllSecurityAddressCombined(
		securities: ApplicationSecurityDetails[]
	) {
		return securities?.map((x) => x?.propertyAddress || '')?.join(' & ');
	}

	public static getPpFormula(
		propertyPurchase: PropertyPurchaseDetailsState[]
	) {
		// From TAPNZ-6790: ($Total Purchase Price x LVR) / 100 = $00
		// Where Purchase Price is Proposed Total Debt
		const purchasePrice = propertyPurchase?.reduce((a, c) => {
			const pp = +c?.propertyValue;
			const lvr = this.getLVR(c);
			const newData = {
				display: `(${this.formatCurrency(pp)} x ${this.getLVR(c)}%)`,
				value: +(computeMoatAppUtil.getTotalPp(pp, lvr) || 0)
			}
			return [...a, newData]
		}, []);
		const formula = purchasePrice?.map((x) => x?.display)?.join(' + ');
		const totalPp = purchasePrice?.reduce((a, c) => (+a + c?.value), 0);
		return `${formula} = ${this.formatCurrency(totalPp)}`;
	}

	public static getLVR(property: PropertyPurchaseDetailsState) {
		return property?.propertyUse === 'Investment' ? 70 : 80;
	}

	public static getLvr1Formula(
		security: ApplicationSecurityDetails[],
		computations: any
	) {
		// From TAPNZ-6790: (Total Debt / Total Security) x 100
		const proposedTotalDebt = +(computations?.proposedTotalDebt || 0);
		const totalSecurityValue = +(computations?.totalSecurityValue || 0);
		const proposedLvr = +R.multiply(
			R.divide(+proposedTotalDebt, +totalSecurityValue),
			100
		);

		return `${this.formatPercent(proposedLvr)} = ${this.formatCurrency(
			proposedTotalDebt
		)} / ${this.formatCurrency(totalSecurityValue)}`;
	}

	public static getLvr2Formula(
		security: ApplicationSecurityDetails[],
		computations: any
	) {
		// From TAPNZ-6790: ($Proposed Total Debt / $Total Property Value) x 100
		const proposedTotalDebt = +(computations?.proposedTotalDebt || 0);
		const totalPropertyValue = +(
			computeMoatAppUtil.totalPropertyValue(security) || 0
		);
		const lvr = R.multiply(
			R.divide(proposedTotalDebt, totalPropertyValue),
			100
		);

		return `${this.formatPercent(lvr)} = ${this.formatCurrency(
			proposedTotalDebt
		)} / ${this.formatCurrency(totalPropertyValue)}`;
	}

	public static getScaledLvrFormula(
		security: ApplicationSecurityDetails[],
		computations: any
	) {
		// From TAPNZ-6790: ($Proposed Total Debt / $Total Max Loan Amount Column) x 100
		const proposedTotalDebt = +(computations?.proposedTotalDebt || 0);
		const totalMaxLoanAmount = +(
			computeMoatAppUtil.totalMaxLoanAmount(security) || 0
		);
		const lvr = R.multiply(
			R.divide(proposedTotalDebt, totalMaxLoanAmount),
			100
		);

		return `${this.formatPercent(lvr)} = ${this.formatCurrency(
			proposedTotalDebt
		)} / ${this.formatCurrency(totalMaxLoanAmount)}`;
	}

	public static parseData(data) {
		return util.tryCatchParse(data) ? JSON.parse(data) : data || [];
	}

	public static formatPercent(value) {
		return numUtil.isNumber(+value)
			? `${numeral(+value).format('0,0[.]00')}%`
			: '0%';
	}

	public static formatCurrency(value) {
		return numUtil.isNumber(+value)
			? numUtil.formatWholeNumNoDecimal(+value)
			: '$0';
	}
}
