<div class="modal-header">
	<h4 class="modal-title pull-left">Confirmation</h4>
	<button
		type="button"
		class="btn-close close pull-right"
		aria-label="Close"
		[attr.disabled]="isSaving ? '' : null"
		(click)="close(false)"
	>
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>
<div class="modal-body">
	<p class="text-center">There are unsaved changes, do you wish to save them?</p>
</div>
<div class="modal-footer">
	<button
		type="button"
		class="btn btn-sm btn-default border d-flex justify-content-center align-items-center"
		(click)="save()"
		[attr.disabled]="isSaving ? '' : null"
	>
		<span> Yes </span>
		<div class="spinner-border spinner" role="status" *ngIf="isSaving">
			<span class="sr-only"></span>
		</div>
	</button>
	<button
		type="button"
		class="btn btn-sm btn-default border"
		(click)="close(true)"
		[attr.disabled]="isSaving ? '' : null"
	>
		No
	</button>
</div>
