import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from '@angular/router';
import { BlStaffModel } from '../../../../shared/models/_general/bl-staff.model';
import { BlStaffService } from '../../../../core/staff/bl-staff.service';

@Injectable()
export class BlStaffResolver implements Resolve<BlStaffModel> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.paramMap.get('id');
    return this.bss.getBlStaff(id);
  }

  constructor(private bss: BlStaffService) { }
}
