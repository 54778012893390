import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { objectUtil } from '../../../../../util/util';
import { KiwiSaverSettingsQuery } from '../../../state/kiwisaver-settings.query';
import { KiwiSaverSettingsStore } from '../../../state/kiwisaver-settings.store';
import {
	FactFindSubpagesSettingsState,
} from './fact-find-subpages-settings.model';

@Injectable()
export class FactFindSubpagesSettingsService {
	factFindSubpagesSettings$ = this.query.factFindSubpagesSettings$;

	constructor(
		private api: ApiService,
		private store: KiwiSaverSettingsStore,
		private query: KiwiSaverSettingsQuery
	) {}

	clearFactFindSettings(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	getFactFindSubpagesSettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;
		return this.api.get<FactFindSubpagesSettingsState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setFactFindSubpagesSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateFactFindSubpagesSettings(data: FactFindSubpagesSettingsState) {
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<FactFindSubpagesSettingsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setFactFindSubpagesSettings(state);
				})
			)
		);
	}
}
