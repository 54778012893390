import {
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
	ElementRef,
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { omit, complement, isNil } from 'ramda';
import {
	BehaviorSubject,
	from,
	iif,
	Observable,
	Observer,
	of,
	Subject,
	throwError,
	combineLatest as clatest,
} from 'rxjs';
import {
	catchError,
	combineLatest,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	toArray,
	withLatestFrom,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { DeclarationSettingState } from 'src/app/modules/mortgage-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.model';
import { SettingsTypes } from 'src/app/modules/mortgage-settings/state/mortgage-settings.model';
import {
	BR,
	filterNonEmailMergeTags,
	getContentWithMergeTags,
	pageBreak,
	removeEmptyParagraphs,
	removeLastEmptyParagraph,
} from 'src/app/shared/converter/content-merge-tags';
import {
	getSaveSuccess,
	logMessage,
} from 'src/app/shared/error-message/error-message';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { EmailModalComponent } from 'src/app/shared/modal/crt/email/email-modal.component';
import { SignatureModalComponent } from 'src/app/shared/modal/signature-modal/signature-modal.component';
import {
	UploadModalComponent,
	ALLOWED_DOCUMENT_FILE_TYPES,
} from 'src/app/shared/modal/upload-modal/upload-modal.component';
import {
	AdviceProcessOnlineRoutes,
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	AdviceProcessSubRoutes,
	MOATDocumentField,
	MOATProcessRoutes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import {
	AdviceProcessDocumentTypesMOAT,
	DefaultFileNames,
	DocumentModelState,
	DocumentTypes,
	DocumentTypesMOAT,
	SignatureTypesMOAT,
} from 'src/app/shared/models/documents/document.model';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import MomentUtil from 'src/app/util/moment.util';
import { convertUtil, objectUtil } from 'src/app/util/util';
import { EmailService } from '../../../client-review-template/states/email/email.service';
import {
	SignatureModel,
	signatureTemplate,
} from '../../../client-review-template/util/templates/signature-template';
import { ClientFactFindMapper } from '../../_shared/mapper/client-fact-find.mapper';
import { CrtDocumentService } from '../../_shared/service/crt-document.service';
import { ApplicationDocumentService } from '../application/application-steps/documents/state/documents.service';
import { PeopleEntitiesQuery } from '../client-sop/people-entities/state/people-entities.query';
import { CrtMortgageQuery } from '../state/crt-mortgage.query';
import { CrtMortgageService } from '../state/crt-mortgage.service';
import { MergeTagsService } from '../state/merge-tags/merge-tags.service';
import { MortgageAdviceProcessService } from '../state/mortgage-adviceprocess/mortgage-advice-process.service';
import {
	DeclarationState,
	MoatCffPdfOrder,
	SignatureState,
} from './state/declaration.model';
import { DeclarationQuery } from './state/declaration.query';
import {
	DeclarationService,
	MoatCffPdfOptions,
} from './state/declaration.service';
import { ToPdfService } from 'src/app/shared/services/to-pdf/to-pdf.service';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { RouteService } from 'src/app/core/config/route.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PeopleEntitiesService } from '../client-sop/people-entities/state/people-entities.service';
import { HtmlPdfConfigState } from '../../_shared/service/html-pdf/defaults-config';
import { CombinedAttachment } from '@shared/models/_general/attachment.model';
import { PdfDesignV2Mapper } from '@shared/models/client-review-template/pdf-design-v2/pdf-design-v2.mapper';
import { SosMTMapper } from '@shared/models/client-review-template/merge-tags/crt-mortgage/scope-of-service/sos.mapper';
import { ScopeOfServiceState } from '../scope-of-services/state/scope-of-service.model';
import { ClientProfileQuery } from '@modules/crm/client-profile/states/client-profile.query';

@Component({
	selector: 'app-declaration',
	templateUrl: './declaration.component.html',
	styleUrls: ['./declaration.component.scss'],
})
export class DeclarationComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
	public bsModalRefSign: BsModalRef;
	public bsModalRefUpload: BsModalRef;

	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;

	declaration$ = this.declarationQuery.declaration$;
	people$ = this.peopleQuery.people$;

	form: UntypedFormGroup;
	signature: any = {
		DocumentLink: '',
	};
	hasSignature = false;

	content = '';
	data: DeclarationSettingState;
	isEnableEmailOption = false;
	triggerPrint = new BehaviorSubject<object>(null);
	generatePdf$ = new BehaviorSubject<string>(null);
	pdf: string;
	document;
	pdfUrlString: string;

	declarationSettings$ = this.declarationService.declarationSettings$;
	isCollapsed: boolean;

	isLoadingDocument: boolean;
	isDownloading: boolean;
	isLoading = false;

	isReloadDocument: boolean;

	combinedCFFDeclaration: string;

	sidebar = this.moatQuery.getValue().sidebars;
	groupName = this.moatQuery
		.getValue()
		.primaryClient?.groupName?.toString()
		?.toUpperCase();
	fileName = `${this.groupName} FACT FIND & DECLARATION`;
	declarationFileName = `${this.groupName} DECLARATION`;
	uploadDeclarationFileName = `${this.groupName} AMENDED FACT FIND & DECLARATION`;

	@ViewChild('contentEditor') editor: WysiwygComponent;
	@ViewChild('signatureSection') signatureSection: ElementRef;

	zoom$ = this.moatQuery.mortApPageCompleted$.pipe(
		map((x) =>
			x?.some((y) => y?.includes('ZOOM'))
				? +x?.find((y) => y?.includes('ZOOM'))?.substr(4)
				: 120
		)
	);

	documents$ = this.clientProfileQuery.documents$;
	adviceProcessId = this.moatQuery.getValue().adviceProcessId;
	hasDeclarationSaved$ = this.documents$.pipe(
		map((x) => x?.m || []),
		map((x) =>
			x?.filter(
				(d) =>
					d?.type === AdviceProcessDocumentTypesMOAT.Declaration &&
					d?.referenceID === this.adviceProcessId
			)
		),
		map((x) => !!x?.length)
	);
	mergeTags$ = this.moatQuery.mergeTags$;
	themeConfig$ = this.businessConfigQuery.themeConfig$;
	declarationEmailSettings$ = this.declarationService.declarationEmailSettings$;
	peopleAndDependents$ = this.moatQuery.peopleAndDependentsFromCRTOnly$;
	adviceProcess = this.moatQuery.getValue().adviceProcess;
	clientFactFindSettings$ = this.declarationService.clientFactFindSettings$;
	scopeOfService$ = this.moatQuery.scopeOfService$;
	sosDefault$ = this.moatQuery.sosDefault$;
	otherMergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
	adviceDocumentName = 'Fact Find & Declaration Document';
	defaultContent = '<p></p>';
	pdfHeaderText = {
		cff: 'Client Fact Find',
		declaration: 'Declaration',
	};
	pdfTemplateClass = {
		cff: 'moat-cff-pdf pdf-design-v2',
		declaration: 'moat-declaration-pdf pdf-design-v2 pdf-design-v2-body',
	};

	constructor(
		private fb: UntypedFormBuilder,
		private moatQuery: CrtMortgageQuery,
		private moatService: CrtMortgageService,
		private peopleQuery: PeopleEntitiesQuery,
		private declarationQuery: DeclarationQuery,
		private declarationService: DeclarationService,
		private modalService: BsModalService,
		private zone: NgZone,
		private loggerService: LoggerService,
		private crtDocService: CrtDocumentService,
		private moatAdviceProcessService: MortgageAdviceProcessService,
		private peopleService: PeopleEntitiesService,
		private emailService: EmailService,
		private mtService: MergeTagsService,
		private query: DeclarationQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private renderer: Renderer2,
		private appDocService: ApplicationDocumentService,
		private toPdfService: ToPdfService,
		private router: Router,
		private route: ActivatedRoute,
		private routeService: RouteService,
		private clientProfileQuery: ClientProfileQuery
	) {
		this.buildForm();
	}

	get Signatures() {
		return this.form.get('signatures') as UntypedFormArray;
	}

	ngOnInit(): void {
		const adviceProcessId = +this.moatQuery.getValue().adviceProcessId;
		this.prepareData();
		this.moatAdviceProcessService
			.updateMortApPageStarted(AdviceProcessPageCodes.Declaration)
			.pipe(take(1))
			.subscribe();

		this.mtService.mergeTags$
			.pipe(
				filter((data) => !!data),
				take(1),
				mergeMap(async () => {
					let results = [];
					const requests = [
						this.mtService
							.getFactFindMt(adviceProcessId)
							.pipe(tap((x) => (results = [...results, ...x]))),
						this.mtService
							.getSosMt(adviceProcessId)
							.pipe(tap((x) => (results = [...results, ...x]))),
					];
					for (const request of requests) {
						await request.toPromise();
					}
					return results;
				}),
				tap((x) => this.otherMergeTags$.next(x)),
				withLatestFrom(this.moatService.sidebars$),
				tap(([, sidebars]) => {
					const isDisabled =
						this.declarationService.disableDeclaration(sidebars);
					if (isDisabled) {
						this.navigateToTab(
							MOATProcessRoutes.ClientSOP,
							AdviceProcessSubRoutes.People
						);
					}
				})
			)
			.subscribe();

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap((x) => {
					const data = this.prepareFormValue(x);
					this.declarationService.setDeclarationFormValue({
						...data,
						content: !!this.isReloadDocument ? this.editor?.content : '',
					});
				})
			)
			.subscribe();
	}

	/**
	 * Build a reactive form
	 */
	buildForm() {
		this.form = this.fb.group({
			document: this.fb.group({
				referenceId: [null],
				value: [''],
			}),
			signatures: this.fb.array([]),
		});
	}

	/**
	 * On load page, prepare the data
	 */
	prepareData() {
		this.declaration$
			.pipe(
				filter((x) => !!x),
				combineLatest(this.people$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([d, people]) => {
				this.form.reset();
				this.clearFormArray(this.Signatures);

				if (!!d && !!d?.document) {
					this.form.patchValue({
						document: {
							referenceId: d?.document?.referenceId || 0,
							value: d?.document?.value || null,
						},
					});
				}

				if (!!people) {
					people?.forEach((p) => {
						this.Signatures.push(
							this.patchSignature(
								p,
								(d?.signatures ?? [])?.find((x) => +x.referenceID === +p.cRTId)
							)
						);
					});
				}

				if (!!d && d?.signatures?.length > 0) {
					this.Signatures.controls?.forEach((s, i) => {
						this.getSignature(+s.get('secondaryReferenceID')?.value, i);
					});
				}

				if (!this.content) {
					this.loadDeclaration();
				}

				this.loadEmailSettings();
			});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray?.removeAt(0);
		}
		// tslint:disable-next-line: semicolon
	};

	scrollToSignature() {
		setTimeout(() => {
			this.signatureSection?.nativeElement?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}, 300);
	}

	/**
	 * Loads the document
	 */
	loadDeclaration(scrollToSignature?: boolean) {
		this.isLoadingDocument = true;
		this.declarationSettings$
			.pipe(
				tap((data) => {
					this.data = data;
				}),
				withLatestFrom(this.declarationQuery.declaration$),
				concatMap(([dataSettings, declaration]) =>
					!dataSettings
						? of(null)
						: this.declarationService
								.getDeclarationDocumentFile(
									!!declaration?.document?.referenceId
										? +declaration?.document?.referenceId
										: +dataSettings.template
								)
								.pipe(
									map((res) =>
										res
											? (objectUtil.mapPascalCaseToCamelCase(
													res
											  ) as DocumentModelState)
											: null
									)
								)
				),
				concatMap((data) =>
					iif(
						() => !!data?.documentLink,
						this.declarationService.getDocumentFromURL(data?.documentLink),
						of(this.defaultContent)
					)
				),
				withLatestFrom(this.declarationQuery.declaration$),
				tap(([content, declaration]) => {
					this.content = getContentWithMergeTags(
						content,
						this.moatQuery.getValue().mergeTags
					);
				}),
				concatMap(([x, declaration]) =>
					!x
						? of(null)
						: iif(
								() => isNil(declaration?.cRTId) || declaration?.cRTId === 0,
								this.addDeclaration(),
								of(x)
						  )
				),
				tap(() => this.generatedPdf()),
				finalize(() => {
					this.isLoadingDocument = false;
					if (scrollToSignature) {
						this.scrollToSignature();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Load declaration from settings
	 */
	reloadSettings() {
		this.isLoadingDocument = true;
		this.declarationService
			.getDeclarationDocument(0, SettingsTypes.Declaration)
			.pipe(
				concatMap((x: any) =>
					iif(
						() => x?.Template,
						this.declarationService
							.getDeclarationDocumentFile(+x?.Template)
							.pipe(take(1)),
						of(null)
					)
				),
				concatMap((data: any) =>
					this.declarationService.getDocumentFromURL(data.DocumentLink)
				),
				map((content) => {
					const template = content || this.defaultContent;
					const newData = getContentWithMergeTags(
						template,
						this.moatQuery.getValue().mergeTags
					);
					this.content = newData;
					return newData;
				}),
				tap((x) => {
					this.isReloadDocument = true;
					const form = this.prepareFormValue();
					const data = {
						...form,
						signatures: form.signatures?.map((y) => ({
							referenceID: y.referenceID,
							secondaryReferenceID: null,
							value: y?.fullName,
							dateValue: null,
						})),
						dateValue: null,
					};
					this.form.reset(data);
					this.declarationService.setDeclarationFormValue({
						...data,
						content: x,
					});
				}),
				concatMap((x) => convertUtil.convertToBase64(x)),
				withLatestFrom(this.declaration$),
				concatMap(([x, declaration]) =>
					this.saveDeclarationDocument(
						x,
						declaration.cRTId,
						declaration?.document?.referenceId
					)
				),
				tap((x) =>
					this.form.patchValue({
						document: {
							referenceId: x,
							value: 'Declaration.text',
						},
					})
				),
				concatMap((x) => this.updateDeclaration(this.prepareFormValue())),
				finalize(() => (this.isLoadingDocument = false)),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Reload Template
	 */
	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.reloadSettings();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	/** Print functionality */
	print(event) {
		this.triggerPrint.next(event);
	}

	/**
	 * Generate a PDF
	 * @param event
	 */
	generatedPdf() {
		const signatures = this.Signatures?.controls?.map(
			(x) =>
				({
					name: x.get('fullName').value,
					date: MomentUtil.formatToDisplayDate(
						MomentUtil.DateStringToMoment(x.get('date').value)
					),
					image: x.get('signature').value,
				} as SignatureModel)
		);
		const signatureContent = signatureTemplate(signatures ?? []);
		const template = `${this.content}${BR}${BR}${signatureContent}`;
		const content = this.cFFHtmlTemplate('', template);
		this.generatePdf$.next(content);
	}

	/** Get the pdf url string */
	getPdf(pdf) {
		this.pdf = pdf;
	}

	open(evt) {
		this.isCollapsed = evt;
	}

	/**
	 * Get the document signature and
	 * assigning the signature on specific for
	 * @param documentId signature dodument id
	 * @param i index of form
	 * @returns document object for the signaure
	 */
	getSignature(documentId: number, i: number) {
		if (!documentId) {
			return;
		}

		this.declarationService
			.getSignature(+documentId)
			.pipe(take(1))
			.subscribe((data) => {
				this.Signatures.controls[i]
					.get('signature')
					.setValue(data?.DocumentLink);
			});
	}

	patchSignature(p?: PeopleState, s?: SignatureState) {
		return this.fb.group({
			referenceID: [p?.cRTId],
			secondaryReferenceID: [
				!!s?.secondaryReferenceID ? s?.secondaryReferenceID : null,
			],
			fullName: [{ value: p?.name, disabled: true }],
			date: [
				!!s?.dateValue
					? MomentUtil.formatDateToMoment(s?.dateValue)
					: MomentUtil.momentNowNz(),
			],
			signature: [''],
		});
	}

	/**
	 * Form Mapper
	 * @returns form
	 */
	prepareFormValue(x?: DeclarationState) {
		const data = this.form.getRawValue();
		return {
			...(x ? x : data),
			document: {
				referenceId: data?.document?.referenceId || 0,
				value: data?.document?.value || null,
			},
			signatures: data.signatures?.map((y) => ({
				referenceID: y.referenceID,
				secondaryReferenceID: !!y.secondaryReferenceID
					? y?.secondaryReferenceID
					: 0,
				value: y?.fullName,
				dateValue: MomentUtil.formatDateToServerDate(y?.date),
			})),
		};
	}

	/**
	 * Save Document for Declaration
	 * @param document Document to be uploaded
	 */
	saveDeclarationDocument(
		document: string,
		referenceId: number,
		documentId?: number
	) {
		const request = {
			ReferenceId: referenceId,
			Document: document,
			FileName: DefaultFileNames.Declaration,
			Type: DocumentTypesMOAT.Declaration,
			DocumentID: documentId,
		};
		return this.moatService.saveDocument(request);
	}

	/**
	 * Add New Declaration
	 */
	addDeclaration() {
		let referenceId;
		const data = this.prepareFormValue();

		return this.declarationService.addDeclaration(data).pipe(
			tap((x) => (referenceId = x)),
			concatMap((x) =>
				convertUtil.convertToBase64(
					getContentWithMergeTags(
						this.content,
						this.moatQuery.getValue().mergeTags
					)
				)
			),
			concatMap((x) => this.saveDeclarationDocument(x, referenceId)),
			tap((x) =>
				this.form.patchValue({
					document: {
						referenceId: x,
						value: 'Declaration.text',
					},
				})
			),
			concatMap((x) =>
				this.declarationService.updateDeclaration({
					...data,
					cRTId: +referenceId,
					document: {
						referenceId: x,
						value: 'Declaration.text',
					},
				})
			)
		);
	}

	updateDeclaration(declaration: DeclarationState) {
		return of(declaration).pipe(
			withLatestFrom(this.declarationQuery.declaration$),
			concatMap(([x, declarationState]) =>
				this.declarationService.updateDeclaration({ ...declarationState, ...x })
			),
			take(1)
		);
	}

	/**
	 * Save Document for Declaration
	 * @param document Document to be uploaded
	 */
	saveSignatureDocument(document: string, name: string) {
		const request = {
			ReferenceId: this.declarationQuery.getValue()?.declaration?.cRTId,
			Document: document,
			FileName: `${name ?? ' Client'}-Signature.png`,
			Type: SignatureTypesMOAT.Declaration,
		};
		return this.moatService.saveDocument(request);
	}

	saveSignature(signature: string, index: number) {
		const name = this.Signatures.controls[index]?.get('value')?.value;
		return of(signature).pipe(
			concatMap((x) =>
				iif(
					() => !!x,
					this.saveSignatureDocument(x?.replace(/^data:(.*,)?/, ''), name),
					of(x)
				)
			),
			tap((x) => {
				this.Signatures.controls[index]
					?.get('secondaryReferenceID')
					.setValue(x);
				this.Signatures.controls[index]?.get('signature').setValue('');
			}),
			concatMap(() => this.updateDeclaration(this.prepareFormValue())),
			take(1)
		);
	}

	/**
	 * Signing the form and openning the popup modal where to sign
	 * @param i Indexed of the form signature
	 */
	sign(i) {
		const saveFn$ = (signature: string) =>
			new Observable((obs) => {
				this.onChange();
				obs.next(signature as string);
				obs.complete();
			}).pipe(
				mergeMap((x: string) => this.saveSignature(x, i)),
				tap(() => {
					this.loadDeclaration(true);
				})
			);

		const initialState = {
			// header: 'Signature',
			subHeader: 'Draw Signature',
			saveFn$,
		};

		this.bsModalRefSign = this.modalService.show(SignatureModalComponent, {
			initialState,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-dialog',
			keyboard: false,
		});
	}

	/**
	 * This will save a document on CRM Advice Process
	 * @param id Document ReferenceId saved in declaration
	 */
	saveDocument() {
		const field = MOATDocumentField?.FactFindDeclaration;
		this.isLoading = !this.isLoading;

		if (
			!!this.moatQuery
				.getValue()
				?.adviceProcess?.documents?.some((x) => x?.field === field && x?.value)
		) {
			const confirm = new Observable((obs) => {
				this.uploadSeparateDeclarationPDF()
					.pipe(
						switchMap(() => this.savePDF()),
						tap(() => (this.isLoading = false)),
						catchError((err) => {
							this.isLoading = false;
							return throwError(new Error(err));
						}),
						take(1)
					)
					.subscribe();
				obs.next();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				this.isLoading = false;
				obs.complete();
			});

			const latestDeclarationFileName = this.moatQuery
				.getValue()
				.adviceProcess.documents?.find((document) => document.field === field);

			const initState = {
				header: '',
				message: `A document is already uploaded for Declaration,`,
				subMessage:
					latestDeclarationFileName?.value?.documentName ?? this.fileName,
				secondaryMessage: `Do you want to replace this?`,
				confirm$: confirm,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.uploadSeparateDeclarationPDF()
				.pipe(
					switchMap(() => this.savePDF()),
					tap(() => (this.isLoading = false)),
					catchError((err) => {
						this.isLoading = false;
						return throwError(new Error(err));
					}),
					take(1)
				)
				.subscribe();
		}
	}

	combinePDF(file: { Document: string; FileName: string }) {
		let uploadDocumentId = null;
		const uploadDocument = {
			ReferenceId: this.moatQuery.getValue().adviceProcessId,
			CustomerId: this.moatQuery.getValue().primaryClient?.customerID,
			Document: file.Document,
			FileName: file.FileName,
			Type: AdviceProcessDocumentTypesMOAT.Declaration,
			DocumentType: ServicesCodes.Mortgage,
		};

		return of(uploadDocument).pipe(
			concatMap((x) => this.crtDocService.saveDocument(x)),
			concatMap((x) => {
				uploadDocumentId = x;
				return this.crtDocService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesMOAT.Declaration
				);
			}),
			concatMap(() => this.getDeclarationCffSettingsTemplate()),
			withLatestFrom(
				this.people$,
				this.businessConfigQuery.businessConfig$,
				this.scopeOfService$,
				this.sosDefault$
			),
			mergeMap(([content, people, businessConfig, sosData, sosSettings]) =>
				this.prepDocWithGenericCover(
					content,
					people,
					businessConfig?.MOATGenericCover,
					sosData,
					sosSettings,
					true
				)
			),
			concatMap((x) => this.saveToFileToCrm(x, this.prepareFormValue())),
			take(1),
			tap(
				() => {
					this.zone.run(() =>
						this.loggerService.Success(
							{},
							getSaveSuccess(this.adviceDocumentName)
						)
					);
				},
				(err) => {
					this.zone.run(() =>
						this.loggerService.Warning(err, logMessage.shared.general.error)
					);
				}
			)
		);
	}

	savePDF() {
		return this.getDeclarationCffSettingsTemplate().pipe(
			withLatestFrom(
				this.people$,
				this.businessConfigQuery.businessConfig$,
				this.scopeOfService$,
				this.sosDefault$
			),
			mergeMap(([content, people, businessConfig, sosData, sosSettings]) =>
				this.prepDocWithGenericCover(
					content,
					people,
					businessConfig?.MOATGenericCover,
					sosData,
					sosSettings
				)
			),
			concatMap((x) => this.saveToFileToCrm(x, this.prepareFormValue())),
			take(1),
			tap(
				() => {
					this.zone.run(() =>
						this.loggerService.Success(
							{},
							getSaveSuccess(this.adviceDocumentName)
						)
					);
				},
				(err) => {
					this.zone.run(() =>
						this.loggerService.Warning(err, logMessage.shared.general.error)
					);
				}
			)
		);
	}

	uploadSeparateDeclarationPDF(file?: { Document: string; FileName: string }) {
		const template = file || this.content;
		return this.patchSignatureImages().pipe(
			map((x) => {
				const signature = x?.map(
					(x: any) =>
						({
							name: x.fullName,
							date: MomentUtil.formatToDisplayDate(x.date),
							image: x.signature,
						} as SignatureModel)
				);
				return `<div class="${
					this.pdfTemplateClass.declaration
				}">${template}${BR}${BR}${signatureTemplate(signature)}<div>`;
			}),
			withLatestFrom(this.getDeclarationHeaderFooterOptions()),
			concatMap(([x, pdfOptions]) =>
				this.crtDocService.generatePDFbase64(x, {
					...pdfOptions,
					filename: `${this.declarationFileName}.pdf`,
				})
			),
			map((x) => ({
				ReferenceId: this.moatQuery.getValue().adviceProcessId,
				CustomerId: this.moatQuery.getValue().primaryClient?.customerID,
				Document: !file ? x : file.Document,
				FileName: !file ? `${this.declarationFileName}.pdf` : file.FileName,
				DocumentType: ServicesCodes.Mortgage,
				Type: AdviceProcessDocumentTypesMOAT.Declaration,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			concatMap((x) =>
				this.crtDocService.updateAdviceProcess(
					x,
					AdviceProcessDocumentTypesMOAT.Declaration
				)
			),
			mergeMap(() => this.appDocService.refetchClientDocument()),
			take(1)
		);
	}

	patchSignatureImages() {
		return of(this.form.getRawValue()?.signatures).pipe(
			concatMap((signatures) =>
				from(signatures).pipe(
					concatMap((item: SignatureState) =>
						iif(
							() => !!item?.secondaryReferenceID && !item?.signature,
							this.declarationService
								.getSignature(item.secondaryReferenceID)
								.pipe(
									map((detail) => ({
										...item,
										signature: detail?.DocumentLink,
									}))
								),
							of(item)
						)
					),
					toArray()
				)
			),
			take(1)
		);
	}

	/**
	 * Update Advice Process with Document
	 * @param id Document ID to be save in Advice Process
	 * @param type Field to where to save in Advice Process
	 * @returns Advice Process
	 */
	updateAdviceProcess(id: number, type: string) {
		return this.crtDocService.updateAdviceProcess(id, type).pipe(take(1));
	}

	getSignatureContent() {
		const data = this.form.getRawValue();

		const signatures = data?.signatures?.map(
			(x) =>
				({
					name: x.fullName,
					date: MomentUtil.formatToDisplayDate(x.date),
					image: x.signature,
				} as SignatureModel)
		);

		return signatureTemplate(signatures ?? []);
	}

	getDeclarationContent() {
		const content = this.content;
		const signatureContent = this.getSignatureContent();

		return `${content}${BR}${BR}${signatureContent}`;
	}

	getDeclarationCffSettingsTemplate() {
		return this.declarationService.getClientFactFindSettings().pipe(
			withLatestFrom(this.clientFactFindSettings$),
			concatMap(([, clientFactFindSettings]) =>
				iif(
					() =>
						(complement(isNil)(clientFactFindSettings?.template) ||
							!isNaN(+clientFactFindSettings?.template)) &&
						+clientFactFindSettings?.template !== 0,
					this.declarationService
						.getDeclarationDocumentFile(+clientFactFindSettings.template)
						.pipe(
							map((res) =>
								res
									? (objectUtil.mapPascalCaseToCamelCase(
											res
									  ) as DocumentModelState)
									: null
							),
							concatMap((res) =>
								iif(
									() => !!res?.documentLink,
									this.declarationService.getDocumentFromURL(res?.documentLink),
									of(null)
								)
							)
						),
					this.declarationService.getDocumentFromURL(
						clientFactFindSettings.templateLink
					)
				)
			)
		);
	}

	getClientFactFindContent(
		cffSettingsTemplate: string,
		people: PeopleState[],
		genericCoverBg: string,
		sosData: ScopeOfServiceState,
		sosSettings: ScopeOfServiceState,
		excludeDeclaration?: boolean
	) {
		const mergeTags = this.cFFMergeTags() || [];
		const updatedMT = SosMTMapper.moatSosMergeTags(
			mergeTags,
			sosData,
			sosSettings
		);
		// const declaration = this.getDeclarationContent();
		const convertedCFF = getContentWithMergeTags(
			cffSettingsTemplate,
			updatedMT
		);
		const parsedTemplate = ClientFactFindMapper.processCFF(
			convertedCFF,
			updatedMT,
			this.businessConfigQuery.getValue().config,
			people
		);
		// const combined = !!excludeDeclaration
		// 	? parsedTemplate
		// 	: `${parsedTemplate}${pageBreak}${declaration}`;
		const templates = PdfDesignV2Mapper.splitTemplate(
			parsedTemplate,
			this.pdfTemplateClass.cff,
			genericCoverBg
		);

		return {
			genericCover: this.crtDocService.contentForPdf(templates?.genericCover),
			body: this.crtDocService.contentForPdf(templates?.body),
		};
	}

	downloadDocumentPDF() {
		this.isDownloading = true;
		this.isLoading = !this.isLoading;

		this.uploadSeparateDeclarationPDF()
			.pipe(
				switchMap(() => this.getDeclarationCffSettingsTemplate()),
				withLatestFrom(
					this.people$,
					this.businessConfigQuery.businessConfig$,
					this.scopeOfService$,
					this.sosDefault$
				),
				mergeMap(([content, people, businessConfig, sosData, sosSettings]) =>
					this.prepDocWithGenericCover(
						content,
						people,
						businessConfig?.MOATGenericCover,
						sosData,
						sosSettings
					)
				),
				mergeMap((id: number) => this.crtDocService.getDocument(id)),
				tap((doc) => this.pdfDownloadFromUrl(doc?.DocumentLink)),
				tap(() => (this.isLoading = false)),
				switchMap((x) =>
					this.saveToFileToCrm(x?.DocumentID, this.prepareFormValue())
				),
				take(1)
			)
			.subscribe();
	}

	saveToFileToCrm = (documentId: number, formValue) =>
		this.updateAdviceProcessDocument(documentId).pipe(
			switchMap(() => this.updateDeclaration(formValue)),
			mergeMap(() => this.appDocService.refetchClientDocument()),
			take(1)
		);

	cFFMergeTags() {
		const CFFMergeTags = ClientFactFindMapper.updateCFFMergeTags(
			this.moatQuery.getValue().mergeTags
		);
		return MergeTagsMapper.mapCrtMergeTags(CFFMergeTags);
	}

	cFFHtmlTemplate(cff: string, declaration: string) {
		const template = removeLastEmptyParagraph(
			removeEmptyParagraphs(
				`<div class="${this.pdfTemplateClass.cff}">${cff}${declaration}</div>`
			)
		);
		return template;
	}

	uploadDocument() {
		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				tap(() => (this.isLoading = true)),
				map(() => req),
				mergeMap((x) =>
					iif(
						() => req[0].type.startsWith('image/'),
						this.toPdfService.fromImage(req[0]),
						convertUtil.toBase64(x[0]).pipe(take(1))
					)
				),
				map((file: any) => {
					return {
						Document: `${((file.content as string) ?? '')?.replace(
							/^data:(.*,)?/,
							''
						)}`,
						FileName: `${file.filename}`,
					};
				}),
				concatMap((x) => this.combinePDF(x).pipe(map(() => x))),
				mergeMap(() => this.appDocService.refetchClientDocument()),
				tap(() => (this.isLoading = false)),
				// switchMap((x) => this.uploadSeparateDeclarationPDF(x)),
				takeUntil(this.onDestroy$)
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Save Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
		};
		this.bsModalRefUpload = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	onChange() {
		this.moatService.setHasFormChanges(true);
	}

	zoomFn(zoom) {
		const update = (d: string[]) =>
			this.moatAdviceProcessService.updateMortApPageCompleted(d).pipe(
				tap(() => this.moatAdviceProcessService.updateMortApPageCompleted(d)),
				take(1)
			);

		of(zoom)
			.pipe(
				// debounceTime(100),
				withLatestFrom(this.moatQuery.mortApPageCompleted$),
				concatMap(([z, list]) =>
					update([
						...(list ?? [])?.filter((l) => !l?.includes('ZOOM')),
						`ZOOM${z}`,
					])
				),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Load declaration email settings
	 */
	loadEmailSettings() {
		this.declarationEmailSettings$
			.pipe(
				take(1),
				tap((data) => {
					if (data) {
						this.isEnableEmailOption = data.isEnableEmailOption;
					}
				})
			)
			.subscribe();
	}

	sendEmail() {
		const adviceProcessId = +this.moatQuery.getValue()?.adviceProcessId;
		const customerID = this.moatQuery.getValue()?.primaryClient?.customerID;
		this.getDeclarationCffSettingsTemplate()
			.pipe(
				withLatestFrom(
					this.people$,
					this.businessConfigQuery.businessConfig$,
					this.scopeOfService$,
					this.sosDefault$
				),
				tap(([cffContent, people, businessConfig, sosData, sosSettings]) => {
					const initState = {
						header: 'Email Client',
						enableDefaultBcc: true,
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.declarationEmailSettings$,
						peopleDropdown: this.peopleAndDependents$,
						attachments: [
							{
								fileName: `${this.fileName}.pdf`,
								content: '',
								disableDownload: true,
								// allowDownloadCombine: true,
								// combineDocumentInfo: {
								// 	ReferenceId: adviceProcessId,
								// 	CustomerId: customerID,
								// 	FileName: `${this.fileName}.pdf`,
								// 	Type: AdviceProcessDocumentTypesMOAT.FactFind,
								// 	DocumentType: ServicesCodes.Mortgage,
								// },
							},
						],
						combinedAttachments: this.getCombinedAttachments$(
							cffContent,
							people,
							businessConfig?.MOATGenericCover,
							sosData,
							sosSettings
						),
						successMessage: 'Declaration document has been emailed to',
						adviceProcess: this.adviceProcess,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.Mortgage,
						documentInfo: {
							documentType: ServicesCodes.Mortgage,
							type: DocumentTypesMOAT.Application,
							referenceId: adviceProcessId,
							customerId: customerID,
						},
					};
					this.modalService.show(EmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				})
			)
			.subscribe();
	}

	updateAdviceProcessDocument(documentId?: number) {
		return this.crtDocService.updateAdviceProcess(
			documentId,
			AdviceProcessDocumentTypesMOAT.FacfFind
		);
	}

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(Object.assign({}, omit(['age'], data)));
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(x),
					this.peopleService.updatePeople(x)
				)
			),
			takeUntil(this.onDestroy$)
		);
		// tslint:disable-next-line: semicolon
	};

	sendToRecipients = (data) => {
		return this.emailService.sendToRecipients(
			data,
			SettingsTypes.MOATDeclarationEmail,
			this.query.getValue()?.declaration.cRTId,
			filterNonEmailMergeTags(this.mergeTags$)
		);
	}; // tslint:disable-line

	navigateToTab(path, subPath) {
		// Base route
		const route = this.routeService.crtPage(
			this.route.snapshot.paramMap.get('customerType') !== 'client',
			+this.route.snapshot.paramMap.get('clientId'),
			+this.route.snapshot.paramMap.get('adviceProcessId'),
			AdviceProcessOnlineRoutes.Mortgage
		);

		// Navigate to route
		this.router.navigate([...route, path, subPath]);
	}

	getDeclarationHeaderFooterOptions() {
		// For Separate Declaration PDF Only
		const pdfHeader = this.pdfHeaderText.declaration;
		return this.getMoatCffPdfOptions$(pdfHeader).pipe(
			map((options) => {
				const otherHeaderOptions = new URLSearchParams({
					startHeaderOnPage: '1',
				})?.toString();
				const otherFooterOptions = new URLSearchParams({
					startPageNumberOn: '1',
				})?.toString();
				return {
					...options,
					FileName: options?.filename,
					FooterHtmlUrlParam: `${options?.FooterHtmlUrlParam}&${otherFooterOptions}`,
					HeaderHtmlUrlParam: `${options?.HeaderHtmlUrlParam}&${otherHeaderOptions}`,
				};
			}),
			take(1)
		);
	}

	getMoatCffPdfOptions$ = (header: string) => {
		return this.themeConfig$.pipe(
			withLatestFrom(this.businessConfigQuery.businessOATLogo$),
			map(([theme, oatLogo]) => {
				// Header config For PDF
				const HeaderHtmlUrlParam = new URLSearchParams({
					headerText: header || '',
					headerLogo: oatLogo?.toString()?.trim() || '',
				})?.toString();
				// Footer config for PDF
				const FooterHtmlUrlParam = new URLSearchParams({
					footerColor: theme?.primarycolor || '#00263e',
				})?.toString();

				return {
					...this.declarationService.getDeclarationPdfOptions(),
					...MoatCffPdfOptions,
					HeaderHtmlUrlParam,
					FooterHtmlUrlParam,
				} as HtmlPdfConfigState;
			}),
			take(1)
		);
	};

	prepDocWithGenericCover = (
		content: string,
		people: PeopleState[],
		genericCoverBg: string,
		sosData: ScopeOfServiceState,
		sosSettings: ScopeOfServiceState,
		excludeDeclaration?: boolean
	) =>
		this.getCombinedAttachments$(
			content,
			people,
			genericCoverBg,
			sosData,
			sosSettings,
			excludeDeclaration
		).pipe(
			switchMap((data) => {
				return clatest(
					data?.map((x) => {
						if (!!x?.toGeneratePdf) {
							return of(x?.content).pipe(
								take(1),
								mergeMap((content) =>
									this.crtDocService.generatePDFbase64(content, x?.pdfOptions)
								),
								map((base64) => {
									return {
										document: base64,
										orderNo: x?.orderNo,
										type: x?.type,
									};
								})
							);
						}
						return of({
							document: '',
							orderNo: x?.orderNo,
							type: x?.type,
						});
					})
				);
			}),
			mergeMap((combineDocs) => {
				const DocumentObject =
					combineDocs?.map(objectUtil.mapCamelCaseToPascalCase) || [];
				const req = {
					DocumentObject,
					ReferenceId: this.moatQuery.getValue()?.adviceProcessId,
					CustomerId: this.moatQuery.getValue()?.primaryClient?.customerID,
					FileName: `${this.fileName}.pdf`,
					Type: AdviceProcessDocumentTypesMOAT.FacfFind,
					DocumentType: ServicesCodes.Mortgage,
				};
				return this.crtDocService.uploadCombineDocument(req);
			})
		);

	getCombinedAttachments$ = (
		content: string,
		people: PeopleState[],
		genericCoverBg: string,
		sosData: ScopeOfServiceState,
		sosSettings: ScopeOfServiceState,
		excludeDeclaration?: boolean
	) => {
		const templates = this.getClientFactFindContent(
			content,
			people,
			genericCoverBg,
			sosData,
			sosSettings,
			excludeDeclaration
		);

		return this.getMoatCffPdfOptions$(this.pdfHeaderText.cff).pipe(
			map((pdfHeaderFooter) => {
				const genericCover = this.crtDocService.contentForPdf(
					templates?.genericCover
				);
				const clientFactFind = this.crtDocService.contentForPdf(
					templates?.body
				);

				return MoatCffPdfOrder?.map((x) => {
					if (x?.type === DocumentTypes.MOATGenericCover) {
						const otherHeaderOptions = new URLSearchParams({
							startHeaderOnPage: '1',
						})?.toString();
						const otherFooterOptions = new URLSearchParams({
							startPageNumberOn: '-1',
						})?.toString();
						let pdfOptions = {
							...pdfHeaderFooter,
							FooterHtmlUrlParam: `${pdfHeaderFooter?.FooterHtmlUrlParam}&${otherFooterOptions}`,
							HeaderHtmlUrlParam: `${pdfHeaderFooter?.HeaderHtmlUrlParam}&${otherHeaderOptions}`,
							MarginBottom: genericCoverBg
								? '0cm'
								: pdfHeaderFooter?.MarginBottom,
						} as HtmlPdfConfigState;
						if (!!genericCoverBg) {
							pdfOptions = omit(
								['FooterHtmlUrlParam', 'FooterHtmlType'],
								pdfOptions
							);
						} else {
							pdfOptions = omit(
								['HeaderHtmlUrlParam', 'HeaderHtmlType'],
								pdfOptions
							);
						}
						return {
							...x,
							content: genericCover,
							pdfOptions,
						};
					}
					if (x?.type === DocumentTypes.MOATFFDocument) {
						const otherHeaderOptions = new URLSearchParams({
							startHeaderOnPage: '1',
						})?.toString();
						const otherFooterOptions = new URLSearchParams({
							startPageNumberOn: '1',
						})?.toString();
						const FooterHtmlUrlParam = `${pdfHeaderFooter?.FooterHtmlUrlParam}&${otherFooterOptions}`;
						const HeaderHtmlUrlParam = `${pdfHeaderFooter?.HeaderHtmlUrlParam}&${otherHeaderOptions}`;
						return {
							...x,
							content: clientFactFind,
							pdfOptions: {
								...pdfHeaderFooter,
								HeaderHtmlUrlParam,
								FooterHtmlUrlParam,
							},
						};
					}
					return {
						...x,
						pdfOptions: pdfHeaderFooter,
					};
				})?.filter(
					(x) => !x?.toGeneratePdf || (!!x?.toGeneratePdf && !!x?.content)
				) as CombinedAttachment[];
			})
		);
	};

	pdfDownloadFromUrl = (fileUrl, pdfOptions?) => {
		const name = `${pdfOptions?.FileName}.pdf`;
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', fileUrl);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	};

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
