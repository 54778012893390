import { util } from '../../../../util/util';
import { SettingsTypes } from '../../state/crt-settings.model';
import { ScopeOfServiceState } from './scope-of-service.model';

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class ScopeOfServiceMapper {
	public static mapToView(data: ScopeOfServiceState) {
		return {
			personalLifeAndRiskInsurance: data?.personalLifeAndRiskInsurance || false,
			medicalInsurance: data?.medicalInsurance || false,
			kiwiSaver: data?.kiwiSaver || false,
			residentialMortgageOrLending: data?.residentialMortgageOrLending || false,
			incomeOrMortgageInsurance: data?.incomeOrMortgageInsurance || false,
			homeCarAndContentsInsurance: data?.homeCarAndContentsInsurance || false,
			businessRiskInsurance: data?.businessRiskInsurance || false,
			other: data?.other || false,
			intro: this.parseData(data?.intro) || '',
			instructionOrLimitation: this.parseData(data?.instructionOrLimitation) || '',
			otherInformation: this.parseData(data?.otherInformation) || '',
			residentialMortgageOrLendingNote: this.parseData(data?.residentialMortgageOrLendingNote) || '',
			homeCarAndContentsInsuranceNote: this.parseData(data?.homeCarAndContentsInsuranceNote) || '',
			kiwiSaverNote: this.parseData(data?.kiwiSaverNote) || '',
			providersWeWorkWith: this.parseData(data?.providersWeWorkWith) || '',
			type: data?.type ?? 'SOS',
			referenceId: data?.referenceId ?? 0,
			kiwiSaverAdvice: data?.kiwiSaverAdvice || false,
		};
	}

	public static mapSosSettingsToView(data: ScopeOfServiceState) {
		// For SOS Settings Only
		return {
			referenceId: 0,
			lifeInsurance: data?.lifeInsurance || 0,
			criticalIllness: data?.criticalIllness || 0,
			tPDInsurance: data?.tPDInsurance || 0,
			disabilityInsurance: data?.disabilityInsurance || 0,
			medicalInsurance: data?.medicalInsurance || 0,
			businessRiskInsurance: data?.businessRiskInsurance || 0,
			kiwiSaver: data?.kiwiSaver || 0,
			homeCarAndContentsInsurance: data?.homeCarAndContentsInsurance || 0,
			residentialMortgageOrLending: data?.residentialMortgageOrLending || 0,
			other: data?.other || 0,
			intro: this.parseData(data?.intro) || '',
			instructionOrLimitation: this.parseData(data?.instructionOrLimitation) || '',
			residentialMortgageOrLendingNote: this.parseData(data?.residentialMortgageOrLendingNote) || '',
			homeCarAndContentsInsuranceNote: this.parseData(data?.homeCarAndContentsInsuranceNote) || '',
			businessRiskInsuranceNote: this.parseData(data?.businessRiskInsuranceNote) || '',
			kiwiSaverNote: this.parseData(data?.kiwiSaverNote) || '',
			otherInformation: this.parseData(data?.otherInformation) || '',
			lifeInsuranceDetails: this.parseData(data?.lifeInsuranceDetails) || '',
			criticalIllnessDetails: this.parseData(data?.criticalIllnessDetails) || '',
			tPDInsuranceDetails: this.parseData(data?.tPDInsuranceDetails) || '',
			disabilityInsuranceDetails: this.parseData(data?.disabilityInsuranceDetails) || '',
			medicalInsuranceDetails: this.parseData(data?.medicalInsuranceDetails) || '',
			type: SettingsTypes.SOS,
			kiwiSaverAdvice: data?.kiwiSaverAdvice || 0,
		};
	}

	public static parseData(data) {
		return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
	}

	public static mapToUpsertSosSettings(data: ScopeOfServiceState) {
		return {
			...data,
			referenceId: 0,
			businessRiskInsurance: data?.businessRiskInsurance ? 1 : 0,
			intro: data.intro ? JSON.stringify(data.intro) : '',
			instructionOrLimitation: data.instructionOrLimitation ? JSON.stringify(data.instructionOrLimitation) : '',
			residentialMortgageOrLendingNote: data.residentialMortgageOrLendingNote
				? JSON.stringify(data.residentialMortgageOrLendingNote)
				: '',
			homeCarAndContentsInsuranceNote: data.homeCarAndContentsInsuranceNote
				? JSON.stringify(data.homeCarAndContentsInsuranceNote)
				: '',
			businessRiskInsuranceNote: data.businessRiskInsuranceNote ? JSON.stringify(data.businessRiskInsuranceNote) : '',
			kiwiSaverNote: data.kiwiSaverNote ? JSON.stringify(data.kiwiSaverNote) : '',
			otherInformation: data.otherInformation ? JSON.stringify(data.otherInformation) : '',
			type: SettingsTypes.SOS,
			kiwiSaver: data?.kiwiSaver ? 1 : 0,
			kiwiSaverAdvice: data?.kiwiSaverAdvice ? 1 : 0,
		};
	}
}
