import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import produce from 'immer';

/** Status match **/
export const statusMatch = (regExp: RegExp, str: string): any => {
	const match = str?.match(regExp);
	return match && str === match[0];
};

/** Status sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const statusSorter = (a: string, b: string, type: string): number => {
	const matchA = statusMatch(new RegExp(type), a ? a?.toLowerCase() : '');
	const matchB = statusMatch(new RegExp(type), b ? b?.toLowerCase() : '');

	if (matchA < matchB) {
		return 1;
	} else if (matchA > matchB) {
		return -1;
	} else {
		return 0;
	}
};

/** Relationship group sorter **/
export const relationshipGroupSorter = (a: string, b: string): number => {
	return (
		statusSorter(a, b, 'partner/spouse') ||
		statusSorter(a, b, 'child') ||
		statusSorter(a, b, 'parent') ||
		statusSorter(a, b, 'friend') ||
		statusSorter(a, b, 'other') ||
		statusSorter(a, b, 'policy owner') ||
		statusSorter(a, b, 'business partner') ||
		statusSorter(a, b, 'sibling')
	);
};

/** Contact Method group sorter **/
export const contactMethodGroupSorter = (a: string, b: string): number => {
	return (
		statusSorter(a, b, 'home phone') ||
		statusSorter(a, b, 'email') ||
		statusSorter(a, b, 'mobile') ||
		statusSorter(a, b, 'post') ||
		statusSorter(a, b, 'do not contact') ||
		statusSorter(a, b, 'deceased')
	);
};

/** Main Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mainGroupSorter = (
	a: LinkedContactState,
	b: LinkedContactState
): number => {
	// TAP1-5094 Sorting 
	// 1. Sort by Relationship
	// 2. Sort by Contact Method
	return (
		relationshipGroupSorter(a.relationship, b.relationship) ||
		contactMethodGroupSorter(a.contactMethod, b.contactMethod)
	);
};

export const linkedContactSortUtil = (
	state: LinkedContactState[]
): LinkedContactState[] => {
	return produce(state, (draft) => {
		draft.sort(mainGroupSorter);
	});
};
