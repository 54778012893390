import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MergeTagsMapper } from '../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { EndProcessEmailSettingsService } from './state/end-process.service';

@Component({
	selector: 'app-end-process',
	templateUrl: './end-process.component.html'
})
export class EndProcessComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	emailSettings$ = this.endProcessService.endProcessEmailSettings$;
	mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);

	constructor(private endProcessService: EndProcessEmailSettingsService) {}

	ngOnInit(): void {
		combineLatest([this.emailSettings$])
			.pipe(
				map(([dec]) => MergeTagsMapper.mapMergeTags(dec?.mergeTag || [])),
				tap((mt) => this.mergeTags$.next(mt)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
