import { RiskAnalysisTPD, RiskAnalysisTPDState } from './tpd.model';

export class RiskAnalysisTpdMapper {
  /** Mapping for view */
  static mapToView(data: RiskAnalysisTPD) {
    if (!data) {
      return { ...data };
    }
    const val = {
      ...data,
    };
    return val;
  }

  /**
   * Map to update
   */
  public static mapToUpsert(data: RiskAnalysisTPDState): RiskAnalysisTPDState {
		const changeOfExpenseList = data.changeOfExpenseList?.filter((c) => c.dropdown && c.value);
    return {
      ...data,
			changeOfExpenseList,
      loans: JSON.stringify(data?.loansList),
      assets: JSON.stringify(data?.assetsList),
      properties: JSON.stringify(data?.propertiesList),
      sectionCode: 'RAT',
    };
  }
}
