import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { InputModule } from '@shared/components/input/input.module';
import { InputSelectModule } from '@shared/components/input-select/input-select.module';
import { UserQualificationModalComponent } from './user-qualification-modal.component';

@NgModule({
	imports: [CommonModule, SharedModule, InputModule, InputSelectModule],
	declarations: [UserQualificationModalComponent],
	exports: [UserQualificationModalComponent],
})
export class UserQualificationModalModule {}
