import { DownloadableDocumentModel } from './templates-utils/templates.model';

const peopleFirstName = (firstName) => {
	let result = [];
	for (let v of firstName) {
		result.push(`
    <td>
      <p>
        <span style="font-size: 12px;">
        <span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
          ${v ? v : ''}
        </span>
      </span>
      </p>
    </td>
    `);
	}
	return result.join('');
};

export const createCovidDeclarationTemplate = (
	data: DownloadableDocumentModel
) => {
	return `
  <p style="text-align: center;">
    <span style="font-size: 12px; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
      <img src="${
				data.businessLogo
			}" alt="Business Logo" style="width: 250px; height: 70px;"/>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p>
    <span style="font-size: 12px;">
      <span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
        Date: ${data.dateToday}
      </span>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 24px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Financial Declaration</span></span></p>
  <table style="width:468pt;">
     <span style="font-size: 12px;">
        <colgroup>
           <col>
           <col>
        </colgroup>
     </span>
     <tbody>
        <tr>
          ${peopleFirstName(data.peopleFirstName)}
        </tr>
     </tbody>
  </table>
  <p><span style="font-size: 12px;"><br></span></p>
  <ol style="margin-left: 10px;">
     <li style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;">
        <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Has your employer (or self-employed business) received any wage subsidy since March 2020 to the best of your knowledge?</span></span></p>
     </li>
  </ol>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <ol start="2" style="margin-left: 10px;">
     <li style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;">
        <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Are you currently on a wage subsidy or reduced hours?</span></span></p>
     </li>
  </ol>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <ol start="3" style="margin-left: 10px;">
     <li style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;">
        <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Has your income been impacted since March 2020 and do you expect it to be impacted by any further lockdown events?</span></span></p>
     </li>
  </ol>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <ol start="4" style="margin-left: 10px;">
     <li style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;">
        <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Are you aware of any current changes or restructuring in your place of employment?</span></span></p>
     </li>
  </ol>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <ol start="5" style="margin-left: 10px;">
     <li style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;">
        <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Do you expect any decrease in your income in the first 12 months following the completion of this transaction?</span></span></p>
     </li>
  </ol>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <ol start="6" style="margin-left: 10px;">
     <li style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;">
        <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Do you expect any specific increases to your expenses/outgoings in the first 12 months following the completion of this transaction? (If so, please explain)</span></span></p>
     </li>
  </ol>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><br></p>
  `;
};
