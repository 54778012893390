<form [formGroup]="form" [class.loatv2]="loatv2Enabled" [class.submitted]="submitted">
  <div class="form-row align-items-center">
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold">
        First Name
      </label>
    </div>
    <div class="col-8 col-md-4 mb-2">
      <input
        id="firstName"
        class="form-control crt-form-control"
        formControlName="firstName"
        type="text"
      />
    </div>
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold"> Gender </label>
    </div>
    <div class="col-8 col-md-4 mb-2">
      <span class="w-100" [class.crt-custom-dropdown]="!viewMode">
        <select
          id="gender"
          formControlName="gender"
          class="form-control theme-crm-field-disabled crt-form-control"
        >
          <option value=""></option>
          <option *ngFor="let d of gender" [value]="d.value">
            {{ d.display }}
          </option>
        </select>
      </span>
    </div>
  </div>
  <div class="form-row align-items-center">
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold">
        Surname
      </label>
    </div>
    <div class="col-8 col-md-4 mb-2">
      <input
        id="lastName"
        class="form-control crt-form-control"
        formControlName="lastName"
        type="text"
      />
    </div>
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold">
        Relationship
      </label>
    </div>
    <div class="col-8 col-md-4 mb-2">
      <span class="w-100" [class.crt-custom-dropdown]="!viewMode">
        <select
          id="relationship"
          formControlName="relationship"
          class="form-control theme-crm-field-disabled crt-form-control"
        >
          <option *ngFor="let d of relationship" [value]="d.value">
            {{ d.display }}
          </option>
        </select>
      </span>
    </div>
  </div>
  <div class="form-row align-items-center">
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold"> D.O.B </label>
    </div>
    <div class="col-8 col-md-4 mb-2">
      <app-date-input
        id="dateOfBirth"
        textboxId="dateOfBirth"
        class="crt-form-control-field"
        formControlName="dateOfBirth"
        placeholder="dd/mm/yyyy"
				[attr.disabled]="viewMode"
      ></app-date-input>
    </div>
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold">
        Known As
      </label>
    </div>
    <div class="col-8 col-md-4 mb-2">
      <input id="knownAs" class="form-control crt-form-control" formControlName="knownAs" type="text" />
    </div>
  </div>
  <div class="form-row align-items-center">
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold"> Email </label>
    </div>
    <div class="col-8 col-md-6 mb-2">
      <input id="email" class="form-control crt-form-control" formControlName="email" type="text" />
    </div>
  </div>
  <div class="form-row align-items-center">
    <div class="col-4 col-md-2 text-left mb-2">
      <label for="" class="position-relative font-weight-bold">
        Physical Address
      </label>
    </div>
    <div class="col-8 col-md-10 mb-2">
      <app-address-input
        id="physicalAddress"
        class="crt-form-control-field"
        inputId="physicalAddress"
        formControlName="physicalAddress"
        textboxClass="theme-crm-field-disabled"
        tabindex="24"
				[attr.disabled]="viewMode"
      ></app-address-input>
    </div>
  </div>

  <div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button id="closeButton" type="button" class="btn-light btn-block mb-2 mb-lg-0" (click)="cancelClick()">
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
      id="saveButton"
      type="button"
      class="btn btn-primary tap-bg-primary text-light btn-block mb-2"
      (click)="save()"
      [disabled]="loadingState"
    >
      Save
      <i *ngIf="loadingState" class="fas fa-spinner fa-pulse"></i>
    </button>
    <button
      id="cancelButton"
      type="button"
      class="btn-light btn-block mb-2 mb-lg-0"
      (click)="cancelClick()"
      [disabled]="loadingState"
    >
      Cancel
    </button>
		</ng-template>
  </div>
</form>
