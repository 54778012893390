import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Service } from './service.model';

export interface ServicesState extends EntityState<Service> { }

@Injectable()
@StoreConfig({
	name: 'servicesOffered',
	idKey: 'ServiceID'
})
export class ServicesStore extends EntityStore<ServicesState, Service> {
	constructor() {
		super();
	}
}
