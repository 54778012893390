import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { combineLatest, EMPTY, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { DocumentModelState } from '../../../../../../shared/models/documents/document.model';
import { ApiService } from '../../../../../../core/base/api.service';
import { IntroductionState } from '../../../../../../shared/models/client-review-template/intro/intro.model';
import { objectUtil } from '../../../../../../util/util';
import { DropdownValueQuery } from '../../../../../../domain/dropdown-value/dropdown-value.query';
import { CustomerService } from '../../../../../../core/customer/customer.service';
import { BusinessService } from '../../../../../../core/business/business.service';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { CrtMortgageStore } from '../../state/crt-mortgage.store';
import {
	AdviceProcessPageCodes,
	MortgageAdviceProcessPageIds,
} from '../../../../../../shared/models/advice-process/advice-process.model';
import { SidebarStatus } from '../../../_shared/models/sidebar.model';

@Injectable({
	providedIn: 'root',
})
export class MoatIntroService extends CrtMortgageService {
	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	getIntro(adviceProcessId) {
		const endpoint = `crt/settings/${adviceProcessId}/MOATIP`;
		return !!this.query.getValue().intro
			? of(undefined)
			: this.api.get<IntroductionState>(endpoint).pipe(
					tap((x) => {
						applyTransaction(() => {
							const state = x ? objectUtil.mapPascalCaseToCamelCase(x) : {};
							this.store.setIntro(state);
						});
					}),
					catchError(() => of({}))
			  );
	}

	updateIntro(intro) {
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(intro);
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setIntro(intro);
				})
			),
			catchError(() => EMPTY)
		);
	}

	getFile(id: number) {
		return this.api
			.get<DocumentModelState>(`documents/${id}`)
			.pipe(catchError(() => EMPTY));
	}

	getDocumentFromURL(url: string) {
		return this.api
			.getExternalResource(url, { responseType: 'text' })
			.pipe(catchError(() => EMPTY));
	}

	setTabColor() {
		return combineLatest([this.query.adviceProcess$]).pipe(
			tap((status) => {
				this.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.Introduction,
					false,
					SidebarStatus.Completed
				);
			})
		);
	}
}
