<div class="clearfix">
	<div class="application-list-header mb-3">Funding Applications</div>
	<div
		*ngIf="applications$ | async as app"
		class="form-row font-weight-bold d-none d-lg-flex mb-2"
		[class.tap-opacity-25]="!app || app?.length === 0"
	>
		<div class="col-12 col-lg-4">Name</div>
		<div class="col-12 col-lg-3">Provider</div>
		<div class="col-12 col-lg-3">Date</div>
	</div>

	<div class="mb-3">
		<app-manual-loader-mini
			*ngIf="!isDuplicating && (isLoading$ | async) as isLoading"
			[isLoading]="isLoading"
		></app-manual-loader-mini>
		<div
			class="form-row py-2 align-items-center"
			[class.border-bottom]="!last"
			*ngFor="let app of applications$ | async; let i = index; let last = last"
		>
			<div class="col-12 col-lg-4 mb-2 mt-2 d-flex">
				<ng-template #showName>
					<div class="d-flex align-items-center exclude-button-width">
						<i class="material-icons md-16 mr-2">description</i>
						<span [title]="(app.name).toUpperCase()" class="text-truncate">
							{{ (app.name).toUpperCase() }}
						</span>
					</div>
					<div class="d-inline-block">
						<button
							(click)="editName(app)"
							id="renameBtn_{{ i }}"
							title="Rename"
							class="icon-btn ml-1"
							[disabled]="isRenaming"
						>
							<i class="fas fa-edit"></i>
						</button>
					</div>
				</ng-template>

				<ng-container *ngIf="!!isRenamingApp(+app.cRTId); else showName">
					<div class="d-flex align-items-center">
						<div class="">
							<i class="material-icons md-16 mr-2">description</i>
							{{ this.primaryClient?.firstName.toUpperCase() }}
							{{ this.primaryClient?.lastName.toUpperCase() }} BANK APPLICATION
							-
						</div>
						<span>
							<input
								[formControl]="applicationName"
								id="applicationNameId_{{ i }}"
								class="
									form-control
									crt-form-control
									rename-soa__input
									text-uppercase
									d-inline-block
								"
							/>
						</span>

						<ng-container *ngIf="!isSavingName">
							<button
								id="saveRenameBtn_{{ i }}"
								title="Save"
								class="icon-btn ml-0 ml-lg-2 tap-text-primary"
								(click)="saveRename(app)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
							<button
								id="cancelRenameBtn_{{ i }}"
								title="Cancel"
								class="icon-btn"
								(click)="cancelRename()"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>

						<app-manual-loader-mini
							*ngIf="isSavingName"
							[isLoading]="isSavingName"
						></app-manual-loader-mini>
					</div>
				</ng-container>
			</div>
			<div class="col-12 col-lg-3">{{ app?.bank }}</div>
			<div class="col-12 col-lg-3">
				{{
					(app?.lastEdited ? app?.lastEdited : app?.modifiedDateTime)
						| momentDatetime
				}}
			</div>
			<ng-container *ngIf="isDuplicatingApp(+app.cRTId)">
				<div class="col-12 col-md-auto ml-auto">
					<app-manual-loader-mini [isLoading]="true">
					</app-manual-loader-mini>
				</div>
			</ng-container>
			<ng-container *ngIf="!isDuplicatingApp(+app.cRTId)">
				<div class="col-12 col-md-auto ml-auto">
					<button (click)="duplicateApplication(app)" class="icon-btn" title="Duplicate" [disabled]="isDuplicating">
						<i class="fas fa-clone fa-16"></i>
					</button>
					<a [routerLink]="editApplication(app)" class="icon-btn" title="Edit" [class.disabled]="isDuplicating">
						<i class="material-icons md-16">edit</i>
					</a>
					<button (click)="deleteApplication(app)" class="icon-btn" title="Delete" [disabled]="isDuplicating">
						<i class="material-icons md-16">delete</i>
					</button>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="clearfix mb-3"></div>

	<ng-container *ngIf="!(isLoading$ | async)">
		<ng-container *ngIf="isAdding; else addNew">
			<app-manual-loader-mini [isLoading]="isAdding"></app-manual-loader-mini>
		</ng-container>
	</ng-container>

	<ng-template #addNew>
		<button
			(click)="addApplication()"
			type="button"
			class="tap-btn tap-btn-outline tap-btn--shadow px-4"
			[disabled]="isDuplicating"
		>
			New Application +
		</button>
	</ng-template>
</div>
