import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-archive-modal',
  templateUrl: './archive-modal.component.html'
})
export class ArchiveModalComponent implements ArchiveModalModel {

  confirm$: Observable<any>;
  header: string;
  message: string;
  isArchive = false;
  cancelLoading: boolean;

  constructor(public bsModalRef: BsModalRef) {
    this.setupProps();
    this.setupSubs();
  }

  setupProps() {
    this.header = 'Archive';
    this.message = 'Archive?';
  }

  setupSubs() { }

  confirm() {
    if (this.cancelLoading) {
      return;
    }
    this.confirm$.subscribe();
    this.decline();
  }
  decline() {
    this.cancelLoading = true;
    this.bsModalRef.hide();
    setTimeout(() => this.cancelLoading = false, 500);
  }

}

export interface ArchiveModalModel {
  confirm$: Observable<any>;
  header: string;
  message: string;
  isArchive?: boolean;
}
