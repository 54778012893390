<form
	[formGroup]="form"
	class="clearfix"
	[class.ng-invalid]="
		(!addMode &&
			!!property?.rentalIncome &&
			!property?.rentalIncomeFrequency) ||
		(!addMode &&
			!!property?.boarderIncome &&
			!property?.boarderIncomeFrequency) ||
		(!addMode && !property?.propertyOwner) ||
		(!addMode && property?.pendingAdviserApproval)
	"
	novalidate
>
	<div class="form-row align-items-center">
		
		<div class="col-lg-4" *ngIf="!isMoatV2; else moatV2Template">
			<ng-container
				*ngIf="
					!addMode ||
					(!!addMode &&
						!!form.get('propertyAddress')?.value &&
						form.get('propertyAddress')?.value !== 'new')
				"
			>
				<app-address-input
					inputId="propertyAddress"
					formControlName="propertyAddress"
					[textboxClass]="'theme-crm-field-disabled'"
					class="crt-form-control-field"
					[attr.disabled]="form.disabled"
				>
				</app-address-input>
			</ng-container>

			<ng-container
				*ngIf="
					!!addMode &&
					(!form.get('propertyAddress')?.value ||
						form.get('propertyAddress')?.value === 'new')
				"
			>
				<span class="w-100" [class.crt-custom-dropdown]="form.enabled">
					<select
						id="propertyAddressAdd"
						formControlName="propertyAddressAdd"
						class="form-control theme-crm-field-disabled crt-form-control propertyAddressAdd"
						(change)="onSelectProperty($event.target.value)"
					>
						<option
							*ngFor="let d of propertyAddresses"
							[value]="d.customerServiceID"
						>
							{{ d.propertyAddress }}
						</option>
						<option value="new">+ Add New Property</option>
					</select>
				</span>
			</ng-container>
		</div>
		
		<ng-template #moatV2Template>
			<div class="col-lg-4" >
				<ng-container
					*ngIf="!addMode; else showPropertyDropdown"
				>
					<app-address-input
						data-toggle="tooltip"
						container="body"
						[tooltip]="form.get('propertyAddress')?.value.length > 20 ? form.get('propertyAddress')?.value : ''"
						inputId="propertyAddress"
						formControlName="propertyAddress"
						[textboxClass]="'theme-crm-field-disabled'"
						class="crt-form-control-field"
						[attr.disabled]="form.disabled"
					>
					</app-address-input>
				</ng-container>
	
				<ng-template #showPropertyDropdown>
					<span class="w-100" [class.crt-custom-dropdown]="form.enabled">
						<select
							[tooltip]="(form.get('propertyAddress')?.value && form.get('propertyAddress')?.value.length > 20) ? form.get('propertyAddress')?.value : ''"
							data-toggle="tooltip"
							container="body"
							id="propertyAddressAdd"
							class="form-control theme-crm-field-disabled crt-form-control propertyAddressAdd"
							(change)="onSelectProperty($event.target.value)"
						>
							<option
								*ngFor="let d of propertyAddresses"
								[value]="d.customerServiceID"
								[selected]="d.customerServiceID === form.get('customerServiceID')?.value"
							>
								{{ d.propertyAddress }}
							</option>
							<option value="new">+ Add New Property</option>
						</select>
					</span>
				</ng-template>
			</div>
		</ng-template>
		

		<div class="col-lg-3" *ngIf="!isMoatV2; else moatV2Template2">
			<ng-container
				*ngIf="
					!addMode ||
					(!!addMode &&
						!!form.get('propertyAddress')?.value &&
						form.get('propertyAddress')?.value !== 'new')
				"
			>
				<app-chips
					textboxId="propertyOwner"
					formControlName="propertyOwner"
					class="crt-form-control-field"
					textboxClass="theme-crm-field"
					[attr.disabled]="form.disabled"
					[isRemoveChipsPadding]="form.disabled"
					[choices]="policyOwnerChoices(form.get('propertyOwner').value)"
				></app-chips>
			</ng-container>
		</div>

		<ng-template #moatV2Template2>
			<div class="col-lg-3" > 
				<ng-container *ngIf="!addMode; else addModeDisplay">
					<app-chips
						textboxId="propertyOwner"
						formControlName="propertyOwner"
						class="crt-form-control-field property-disable"
						textboxClass="theme-crm-field"
						[attr.disabled]="true"
						[isRemoveChipsPadding]="true"
						[choices]="policyOwnerChoices(form.get('propertyOwner').value)"
					></app-chips>
				</ng-container>
				<ng-template #addModeDisplay>
					<div [tooltip]="ownerTooltipDisplay">
						<input
						type="text"
						id="propertyOwner"
						class="form-control crt-form-control"
						placeholder="Owner"
						[value]="ownerTooltipDisplay"
						disabled>
					</div>
				</ng-template>
			</div>
		</ng-template>

		
		<div class="col-lg-2" *ngIf="!isMoatV2; else moatV2Template3">
			<ng-container
				*ngIf="
					!addMode ||
					(!!addMode &&
						!!form.get('propertyAddress')?.value &&
						form.get('propertyAddress')?.value !== 'new')
				"
			>
				<span class="w-100" [class.crt-custom-dropdown]="form.enabled" [class.hideDropdownIcon]="addMode">
					<select
						formControlName="propertyUse"
						id="propertyUse"
						class="form-control theme-crm-field-disabled crt-form-control"
					>
						<option value=""></option>
						<option *ngFor="let d of SU$ | async" [value]="d.value">
							{{ d.display }}
						</option>
					</select>
				</span>
			</ng-container>
		</div>

		<ng-template #moatV2Template3>
			<div class="col-lg-2">
				<ng-container>
					<span class="w-100" [class.crt-custom-dropdown]="form.enabled" [class.hideDropdownIcon]="addMode">
						<select
							formControlName="propertyUse"
							id="propertyUse"
							class="form-control theme-crm-field-disabled crt-form-control"
							disabled
						>
							<option value=""></option>
							<option *ngFor="let d of SU$ | async" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</ng-container>
			</div>
		</ng-template>

		<div class="col-lg-2" *ngIf="!isMoatV2; else moatV2Template4">
			<ng-container
				*ngIf="
					!addMode ||
					(!!addMode &&
						!!form.get('propertyAddress')?.value &&
						form.get('propertyAddress')?.value !== 'new')
				"
			>
				<div class="dollar-icon">
					<input
						formControlName="propertyValue"
						type="text"
						id="propertyValue"
						class="form-control crt-form-control"
						placeholder="Property Value"
						currencyMask
						appCurrencyMaskExt
					/>
					<i class="d-flex">$</i>
				</div>
			</ng-container>
		</div>

		<ng-template #moatV2Template4>
			<ng-container>
				<div class="dollar-icon">
					<input
						formControlName="propertyValue"
						type="text"
						id="propertyValue"
						class="form-control crt-form-control"
						placeholder="Property Value"
						currencyMask
						appCurrencyMaskExt
						disabled
					/>
					<i class="d-flex">$</i>
				</div>
			</ng-container>
		</ng-template>

		<div class="col-lg-1 order-first text-right order-lg-last">
			<ng-container *ngIf="isSaving$ | async as isSaving; else buttons">
				<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
			</ng-container>
			<ng-template #buttons>
				<ng-container *ngIf="!(isEdit$ | async)">
					<ng-container
						*ngIf="
							!property?.pendingAdviserApproval;
							else pendingAdviserApproval
						"
					>
						<button
							id="propertyEditPropertyButton"
							(click)="editProperty()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">edit</i>
						</button>
						<button
							id="propertyDeletePropertyButton"
							(click)="deleteProperty()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="material-icons md-16">delete</i>
						</button>
					</ng-container>
					<ng-template #pendingAdviserApproval>
						<button
							id="pendingAdviserApprovalButton"
							(click)="approveConfirmation()"
							type="button"
							class="icon-btn w-auto h-auto px-1"
						>
							<i class="fas fa-exclamation-triangle fa-16"></i>
						</button>
					</ng-template>
				</ng-container>
				<ng-container *ngIf="isEdit$ | async">
					<button
						id="propertySaveButton"
						(click)="save()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
						[disabled]="
							!form.get('propertyAddress')?.value ||
							!form.get('propertyOwner')?.value ||
							form.get('propertyOwner')?.value?.length === 0 ||
							form.get('propertyValue')?.value === '' ||
							form.get('propertyValue')?.value === null
						"
					>
						<i class="material-icons md-16 tap-text-primary">save</i>
					</button>
					<button
						id="propertyCancelButton"
						(click)="cancel()"
						type="button"
						class="icon-btn w-auto h-auto px-1"
					>
						<i class="material-icons md-16">close</i>
					</button>
				</ng-container>
			</ng-template>
		</div>
	</div>
</form>
