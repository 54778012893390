import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../../core/base/api.service';
import { objectUtil } from '../../../../../util/util';
import { KiwiSaverSettingsQuery } from '../../../state/kiwisaver-settings.query';
import { KiwiSaverSettingsStore } from '../../../state/kiwisaver-settings.store';
import {
	ProviderLogoState,
	ProvidersSettingsState,
} from './providers-settings.model';
import { DropdownValuesService } from '@core/dropdown/dropdown-values.service';

@Injectable()
export class ProviderSettingsService {
	providersSettings$ = this.query.providersSettings$;

	constructor(
		private api: ApiService,
		private store: KiwiSaverSettingsStore,
		private query: KiwiSaverSettingsQuery,
	) {}

	clearFactFindSettings(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}
	
	uploadProviderLogo(data: ProviderLogoState) {
		const endpoint = `documents/upload-photo`;
		const state = data ? objectUtil.mapCamelCaseToPascalCase(data) : null;
		return this.api.post(endpoint, state).pipe(
			map((data) => {
				return objectUtil.mapPascalCaseToCamelCase(data);
			})
		);
	}

	getProvidersSettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<ProvidersSettingsState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setProvidersSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateProvidersSettings(data: ProvidersSettingsState) {
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);

		return this.api.put<ProvidersSettingsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setProvidersSettings(state);
				})
			)
		);
	}
}
