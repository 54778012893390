import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { find, propEq, isNil } from 'ramda';
import { iif, Observable, of, Subject } from 'rxjs';
import {
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { GoalsMapper } from '../../../../../../shared/models/client-review-template/risk-analysis/goals/goals.mapper';
import { RetirementState } from '../../../../../../shared/models/client-review-template/risk-analysis/goals/goals.model';
import { GoalsService } from '../../../states/risk-analysis/goals/goals.service';

declare var $: any;

@Component({
	selector: 'app-retire-goal',
	templateUrl: './retire-goal.component.html',
	styleUrls: ['./retire-goal.component.scss'],
})
export class RetireGoalComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
	@Input() retireGoals$: Observable<RetirementState[]>;
	@Input() cRTId$: Observable<number>;
	@Input() isAdviceProcessEnded: boolean;
	@Input() people: PeopleState[];
	@Input() isNew: boolean;

	elseMinus = true;
	submitted = false;
	isListLoading = true;
	defaultRetireAge = 65;
	form: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder, private goalsService: GoalsService) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.people?.sort((item1, item2) => item1.name?.localeCompare(item2.name));
		this.prepData();
	}

	get DetailsList() {
		return this.form.get('detailsList') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			detailsList: this.fb.array([]),
		});
	}

	prepData() {
		this.retireGoals$
			.pipe(
				filter((data) => !!data),
				withLatestFrom([this.cRTId$]),
				mergeMap(([goals, cRTId]) =>
					iif(() => isNil(cRTId), this.addPeople(), this.addFromData(goals))
				),
				tap(() => {
					if (this.isAdviceProcessEnded) {
						this.form.disable();
					}
				}),
				finalize(() => (this.isListLoading = false)),
				take(1)
			)
			.subscribe();
	}

	addPeople = () =>
		of(this.people).pipe(
			map((data) =>
				data?.map((p) =>
					this.addItem({
						cRTId: p.cRTId,
						age: this.defaultRetireAge,
						clientName: p.name,
					})
				)
			),
			take(1)
		);

	addFromData = (data: RetirementState[]) =>
		of(data).pipe(
			map((x) => this.checkPeopleList(x)),
			map((x) => x?.map((item) => this.addItem(item))),
			delay(100),
			tap(() => this.onChange()),
			take(1)
		);

	checkPeopleList(data: RetirementState[]) {
		const list = this.people?.map(({ cRTId }) => {
			const getAge = data?.find((age) => age.cRTId === cRTId);
			return {
				cRTId,
				age: getAge?.age || this.defaultRetireAge,
				clientName: this.getClientName(cRTId),
			};
		});
		return list;
	}

	getClientName(id: number) {
		const nameDisplay = find(propEq('cRTId', id))(
			this.people
		) as PeopleState;
		return nameDisplay?.name;
	}

	addItem(data?: RetirementState) {
		this.DetailsList?.push(
			this.fb.group({
				cRTId: {
					value: data?.cRTId || '',
					disabled: true,
				},
				clientName: {
					value: data?.clientName || '',
					disabled: true,
				},
				age: data?.age || this.defaultRetireAge,
			})
		);
	}

	deleteItem(index: number) {
		this.DetailsList.removeAt(index);
	}

	onChange() {
		const getData = GoalsMapper.mapRetireToUpsert(
			this.form.getRawValue().detailsList
		);
		this.goalsService.updateGoalsState({
			retirements: getData,
		});
	}

	collapseMore() {
		$('#collapse').toggle();
		this.elseMinus = false;
	}

	collapseLess() {
		$('#collapse').toggle();
		this.elseMinus = true;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
