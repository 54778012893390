<div class="row">
	<div class="col-12">
		<app-crt-email-settings
			[showBody]="true"
			(save)="save($event)"
			[emailSettings]="emailSettings"
			[bodyContent]="bodyContent"
			[isLoading]="isLoading"
			[autoCCAdviser]="autoCCAdviser"
			[hasAutoCCAdviserOption]="true"
		>
		</app-crt-email-settings>
	</div>
</div>
