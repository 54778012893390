
import { isNil } from 'ramda';
import {
	DefaultFileNames,
	DocumentTypesMOAT,
} from '../../../../../../../../shared/models/documents/document.model';
import { AdviceProcessSectionCodes } from '../../../../../../../../shared/models/advice-process/advice-process.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import {
	QueingDownloadState,
	ReviewApplicationState,
} from './review-application.model';
export class ReviewApplicationMapper {
	public static mapToView(data: ReviewApplicationState) {
		return {
			...data,
			cRTId: data?.cRTId > 0 ? data?.cRTId : null,
			sectionCode: AdviceProcessSectionCodes.ReviewApplication,
		};
	}

	public static mapToUpsert(data, documentId) {
		return {
			adviceProcessId: +data?.adviceProcessId,
			parentCRTId: +data?.parentCRTId,
			sectionCode: AdviceProcessSectionCodes.ReviewApplication,
			cRTId: data?.cRTId || null,
			document: {
				referenceId: +documentId,
				value: DefaultFileNames.ReviewApplication,
			},
			status: 1,
		};
	}

	public static mapDocumentUpload(document, cRTId: number, docId) {
		if (+docId === 0 || isNaN(docId) || isNil(docId)) {
			return {
				document,
				referenceId: cRTId,
				fileName: DefaultFileNames.ReviewApplication,
				type: DocumentTypesMOAT.ReviewApplication,
			};
		}
		return {
			document,
			documentId: +docId,
		};
	}

	public static mapDocumentUploadPDF(
		document,
		parentCRTId: number,
		clientId: number,
		fileName: string
	) {
		return {
			document,
			fileName,
			customerId: +clientId,
			referenceId: +parentCRTId,
			type: DocumentTypesMOAT.ReviewApplicationPDF,
			documentType: ServicesCodes.Mortgage
		};
	}

	public static mapQueueDocumentUpload(
		documentId: number,
		parentCRTId: number,
		adviceProcessId: number,
		data
	): QueingDownloadState {
		const formatEmailList = (value) => {
			const list = value?.map((x) => x?.Email || '');
			return list?.filter((x) => !!x);
		};
		return {
			documentId,
			adviceProcessId,
			cRTId: +parentCRTId,
			subject: data?.emailSubject || '',
			from: {
				name: '',
				email: data?.emailFrom || '',
			},
			to: {
				name: data?.emailDestination?.[0]?.Name || '',
				email: data?.emailDestination?.[0]?.Email || '',
			},
			cC: formatEmailList(data?.emailCC || []),
			bCC: formatEmailList(data?.emailBCC || []),
			attachment: data?.attachments
		};
	}
}
