import {
	Component,
	Input,
	ChangeDetectorRef,
	HostBinding,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { staticConf } from '../../core/config/static-config.service';

@Component({
	selector: 'app-address-input',
	templateUrl: './address-input.component.html',
	styleUrls: ['./address-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: AddressInputComponent,
			multi: true,
		},
	],
})
export class AddressInputComponent implements ControlValueAccessor {
	@Input() tabindex: number;
	@Input() inputId = '';
	@Input() textboxClass = '';
	@Input() placeholder = '- -';
	/**
	 * remove the tabindex attribute from host element
	 * to remove duplicate tab index that is attached to input element
	 * */
	@HostBinding('attr.tabindex') get tabIndex() {
		return null;
	}

	disabled: boolean;
	address: string;

	constructor(private cd: ChangeDetectorRef) {}

	onChange: (value: string) => void;

	writeValue(obj: string): void {
		this.address = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.cd.detectChanges();
	}

	valueChange = (value: string) => {
		this.address = value;
		this.onChange(value);
	};

	onTouched = () => {};

	change(value: string) {
		this.onChange(value);
	}

	public googlemap = (value: string) => {
		if (value !== null && value !== '') {
			window.open(staticConf.googleMapApi + value, '_blank');
		}
	};
}
