import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { complement, either, isNil, isEmpty } from 'ramda';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { concatMap, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import { NoWhitespaceValidator } from 'src/app/shared/validator/no-whitespace/no-whitespace.directive';
import { LoggerService } from '../../../../../../../core/logger/logger.service';
import { IncomeSourceMapper } from '../../../../../../../shared/models/client-review-template/income-budget/income-source.mapper';
import { PeopleDetailsState } from '../../../../../../../shared/models/client-review-template/people/people-details.model';
import { ViewDisplayValue } from '../../../../../../../shared/models/_general/display-value.viewmodel';
import { MoatVersionService } from './../../../service/moat-version.service';
import { PreviousIncomeSourceMapper } from '../../../mapper/income.mapper';
import { PreviousIncomeSourceState } from '../../../models/income.model';
@Component({
	selector: 'app-crt-previous-income-form',
	templateUrl: './crt-previous-income-source-form.component.html',
	styleUrls: ['./crt-previous-income-source-form.component.scss'],
})
export class CrtPreviousIncomeSourceFormComponent implements OnInit, OnDestroy, OnChanges {
	private onDestroy$ = new Subject<void>();

	@Input() formId: number;
	@Input() incomeSource: PreviousIncomeSourceState;
	@Input() adviceProcessId: number;
	@Input() transferedSCIList: PeopleState[];
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;
	@Input() disableUpdate: boolean;
	@Input() addMode: boolean;
	@Input() people: PeopleDetailsState[];
	@Input() peopleFromCRMAndCRTExceptChild$: Observable<any[]>;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	// tslint:disable-next-line: variable-name
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	// tslint:disable-next-line: variable-name
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<boolean>();
	@Output() editModeEvent = new EventEmitter<any>();

	form: UntypedFormGroup;
	submitted: boolean;

	peopleList: ViewDisplayValue[];
	transferedSCI: PeopleState[];
	isMoatV2: boolean = false;

	toolTipDisplayIncomeEarner: string;

	@Input() deceasedSciList: any[];

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService,
		private moatVersionService: MoatVersionService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		if (this.addMode) {
			this.getPeopleList();
		}
		this.prepareData();

		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));

		this.transferedSCI = this.transferedSCIList?.filter(data => !!data);

		this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this.getPeopleList(x?.incomeEarner);
			this.toolTipDisplayIncomeEarner = this.getTooltipValueIncomeEarner(x?.incomeEarner);
		});

		this.isMoatV2 = this.moatVersionService.isMoatV2();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.people?.previousValue===null){
			this.getPeopleList(this.incomeSource?.incomeEarner);
		}
	}

	prepareData() {
		this.isEdit$.next(!!this.addMode);
		this.isSaving$.next(false);
		this.getPeopleList(this.incomeSource?.incomeEarner);
		this.form.reset({
			cRTId: this.incomeSource?.cRTId || null,
			incomeEarner: this.incomeSource?.incomeEarner || '',
			employer: this.incomeSource?.employer || '',
			previousOccupation: this.incomeSource?.previousOccupation || '',
			periodOfEmployment: this.incomeSource?.periodOfEmployment || '',
		});
	}

	buildForm() {
		this.form = this.fb.group({
			cRTId: this.fb.control(''),
			incomeEarner: this.fb.control('', [Validators.required, NoWhitespaceValidator]),
			employer: this.fb.control('', [Validators.required, NoWhitespaceValidator]),
			previousOccupation: this.fb.control('', [Validators.required, NoWhitespaceValidator]),
			periodOfEmployment: this.fb.control('', [Validators.required, Validators.min(0), NoWhitespaceValidator]),
		});
	}

	getPeopleList(id?: string | number) {
		this.peopleFromCRMAndCRTExceptChild$.pipe(take(1)).subscribe((x) => {
			this.peopleList = this.incomeEarnerChoices(id, this.mapPeople(x));
		});
	}

	mapPeople(data) {
		return [
			...IncomeSourceMapper.mapCRMPeopleToDd(data),
			...IncomeSourceMapper.mapCRTPeopleToDd(this.people),
		];
	}

	prepareFormValue() {
		const form = this.form.getRawValue();
		return {
			...form,
		};
	}
	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	isSciInvalid(id: number) {
		const sci = this.transferedSCI?.filter((x) => +x?.customerId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	invalidSciWarning() {
		this.loggerService.Warning(
			{},
			logMessage.oat.shared.warning.invalidSci
		);
	}

	save() {
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(null, logMessage.shared.general.warning.required);
			return;
		}

		this.toggleSaving(true);
		const data = this.prepareFormValue();
		const d = PreviousIncomeSourceMapper.mapToUpsert(
			data,
			this.incomeSource.cRTId,
			this.adviceProcessId,
		);

		of(d).pipe(
			concatMap(data => {
				if(!!this.addMode) {
					return this.addFn$(data);
				}
				else {
					return this.updateFn$(data).pipe(
						tap(() => {
							this.incomeSource = d;
							this.prepareData();
						})
					);
				}
			}),
			take(1),
			finalize(() => {
				this.toggleSaving(false);
				this.submitted = false;
				this.checkSCIValidity();
				this.saveEvent.emit(data);
				this.resetEdit();
			})
		).subscribe();
	}

	checkSCIValidity(){
		if (this.form.get('incomeEarner').value && this.isSciInvalid(+this.form.get('incomeEarner').value)) {
			this.invalidSciWarning();
		}
	}

	deleteIncome() {
		this.toggleSaving(true);
		this.deleteFn$(this.incomeSource.cRTId)
			.pipe(
				take(1),
				finalize(() => {
					this.toggleSaving(false);
					this.checkSCIValidity();
				})
			)
			.subscribe();
	}

	edit() {
		this.editModeEvent.emit(this.incomeSource?.cRTId);
		this.toggleEdit(true);
	}

	cancel() {
		if (!!this.addMode) {
			this.cancelEvent.emit(true);
		} else {
			this.toggleEdit(false);
		}
		this.prepareData();
		this.resetEdit();
	}

	resetEdit() {
		this.editModeEvent.emit(null);
	}

	incomeEarnerChoices(owners: string | number, peopleList) {
		return this.getOwnerChoices(!!owners ? [owners] : [], peopleList);
	}

	trackByFn(_index: number) {
		return _index;
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	getTooltipValue = (form): string => {
		if(form) {
			return form?.value;
		}
		return '';
	}

	getTooltipValueIncomeEarner = (incomeEarnerId: string | number): string => {
		if(incomeEarnerId) {
			const incomeEarner = this.peopleList?.find(x => +x.value === +incomeEarnerId);
			return incomeEarner?.display;
		}
		return '';
	}
}

