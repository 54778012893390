import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs/internal/observable/of';
import { concatMap, map, take, tap } from 'rxjs/operators';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { KiwiMergeTagsCrtSettingsService } from '../../merge-tags/state/kiwi-merge-tags-crt-settings.service';
import { AdviceSummarySettingsMapper } from '../state/advice-summary-settings.mapper';
import { AdviceSummarySettingsState } from '../state/advice-summary-settings.model';
import { AdviceSummarySettingsService } from '../state/advice-summary-settings.service';
import * as R from 'ramda';
import { EMPTY } from 'rxjs';

@Component({
	selector: 'app-advice-summary-merge-tags-wording',
	templateUrl: './advice-summary-merge-tags-wording.component.html'
})
export class AdviceSummaryMergeTagsWordingComponent implements OnInit {
	@Input() adviceSummaryData: AdviceSummarySettingsState;

	isLoading = false;
	mergeTags: MergeTagState[];
	form: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder,
		private service: AdviceSummarySettingsService,
		private mtService: KiwiMergeTagsCrtSettingsService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
	}

	get AdviceSummaryRaiseComplaint() {
		return this.form.get('adviceSummaryRaiseComplaint');
	}
	get AdviceSummaryThingsToConsider() {
		return this.form.get('adviceSummaryThingsToConsider');
	}
	get AdviceSummaryTheNextStep() {
		return this.form.get('adviceSummaryTheNextStep');
	}
	get InvestmentAdvice() {
		return this.form.get('investmentAdvice');
	}

	buildForm() {
		this.form = this.fb.group({
			adviceSummaryRaiseComplaint: [null],
			adviceSummaryThingsToConsider: [null],
			adviceSummaryTheNextStep: [null],
			investmentAdvice: [null]
		});
	}

	prepData() {
		of(this.adviceSummaryData)
			.pipe(
				tap((x) => (this.mergeTags = x?.mergeTag || [])),
				map((x) => AdviceSummarySettingsMapper.mapAdviceSummaryMergeTagWording(x?.mergeTag || [])),
				take(1)
			)
			.subscribe((data) => this.form.reset(data));
	}

	save() {
		this.isLoading = true;
		if(R.isNil(this.mergeTags) || R.isEmpty(this.mergeTags)) {
			this.isLoading = false;
			return;
		}
		of(this.form.getRawValue())
			.pipe(
				map((x) => AdviceSummarySettingsMapper.mapToUpsertMtWording(this.mergeTags, x)),
				tap((x) => {
					this.mergeTags = x;
					const data = AdviceSummarySettingsMapper.mapAdviceSummaryMtWordingPreviewValues(x);
					this.mtService.updateMtState(data);
				}),
				concatMap((x) => {
					if(R.isNil(x) || R.isEmpty(x)) {
						return of(EMPTY);
					}
					else {
						return this.service.updateAdviceSummarySettings({ mergeTag: x });
					}
				}),
				take(1)
			)
			.subscribe(() => (this.isLoading = false));
	}
}
