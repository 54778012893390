<form [formGroup]="form" novalidate>
	<div class="container-fluid clearfix px-0 mb-4">
		<div class="form-row clearfix align-items-center mb-1">
			<div class="col-lg col-12"></div>
			<div class="col-lg-auto col-12 d-flex action-btns justify-content-end">
				<span class="position-relative" *ngIf="isLoading">
					<app-manual-loader-mini
						[isLoading]="isLoading"
						class="p-0"
					></app-manual-loader-mini>
				</span>

				<ng-container *ngIf="!isLoading">
					<ng-container *appRestrictTo="['FEL']">
						<span class="position-relative">
							<a 
								class="btn btn-link icon-btn p-0"
								[routerLink]="form.get('isAccessible')?.value ? clientRoute : null"
								[attr.target]="form.get('isAccessible')?.value ? '_blank' : null"
								[attr.disabled]="!form.get('isAccessible')?.value"
							>
								<button type="button" class="icon-btn" id="edit_{{ formID }}">
									<i class="material-icons md-20 tap-text-color-h6"> edit </i>
								</button>
							</a>
						</span>

						<span class="position-relative">
							<button
								id="cancel_{{ formID }}"
								class="icon-btn"
								(click)="cancelContact()"
							>
								<i class="material-icons md-20 tap-text-color-h6"> cancel </i>
							</button>
						</span>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="primaryContact_{{ formID }}">Name</label>
					</div>
					<div class="col-lg-8 col-12">
						<div class="d-flex">
							<input
								type="text"
								formControlName="primaryContact"
								class="form-control theme-crm-field-disabled"
								placeholder=""
								id="primaryContact_{{ formID }}"
							/>

							<a
								class="btn btn-link icon-btn p-0"
								[routerLink]="form.get('isAccessible')?.value ? clientRoute : null"
								[attr.target]="form.get('isAccessible')?.value ? '_blank' : null"
								[attr.disabled]="!form.get('isAccessible')?.value"
							>
								<span>
									<i class="material-icons align-middle p-1 contact-icon"
										>&#xE7FD;</i
									>
								</span>
							</a>
						</div>

						<!-- <app-client-search-control
              id="primaryContact_{{ formID }}"
              ngProjectAs="input"
              textboxClass="theme-crm-field-disabled"
              formControlName="primaryContact"
            ></app-client-search-control> -->
					</div>
				</div>
			</div>
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="phone_{{ formID }}">Mobile</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="work"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="phone_{{ formID }}"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="email_{{ formID }}">Email</label>
					</div>
					<div class="col-lg-8 col-12">
						<input
							type="text"
							formControlName="email"
							class="form-control theme-crm-field-disabled"
							placeholder="- -"
							id="email_{{ formID }}"
						/>
					</div>
				</div>
			</div>
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="physicalAddress_{{ formID }}">Physical Address</label>
					</div>
					<div class="col-lg-8 col-12">
						<app-address-input
							inputId="physicalAddress_{{ formID }}"
							formControlName="physicalAddress"
							textboxClass="theme-crm-field-disabled"
							[attr.disabled]="form.get('physicalAddress').disabled"
						></app-address-input>
					</div>
				</div>
			</div>
		</div>

		<div class="form-row clearfix align-items-center mb-2">
			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="contactMethod_{{ formID }}">Contact Method</label>
					</div>
					<div class="col-lg-8 col-12">
						<span class="custom-dropdown w-100">
							<select
								formControlName="contactMethod"
								class="form-control theme-crm-field-disabled"
								id="contactMethod_{{ formID }}"
								tabindex="12"
							>
								<option value=""></option>
								<option *ngFor="let d of preferredContact" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</div>

			<div class="col-lg col-12">
				<div class="form-row align-items-center">
					<div class="col-lg-4 col-12">
						<label for="relationship_{{ formID }}">Relationship</label>
					</div>
					<div class="col-lg-8 col-12">
						<span class="custom-dropdown w-100">
							<select
								formControlName="relationship"
								class="form-control theme-crm-field-disabled"
								id="relationship_{{ formID }}"
								tabindex="12"
							>
								<option value=""></option>
								<option *ngFor="let d of relationship" [value]="d.value">
									{{ d.display }}
								</option>
							</select>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
