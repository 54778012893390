import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { MoatSoaEmailSettingsState } from './state/moat-soa-email-settings.model';
import { MoatSoaEmailSettingsMapper } from './state/maot-soa-email-settings.mapper';
import { ComponentBase } from 'src/app/core/base/component-base';
import { MoatSoaEmailSettingsService } from './state/moat-soa-email-settings.service';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import {
	filter,
	tap,
	map,
	mergeMap,
	take,
	withLatestFrom,
	concatMap,
	finalize,
	catchError,
} from 'rxjs/operators';
import { Observable, combineLatest, iif, of } from 'rxjs';
import { objectUtil, convertUtil } from 'src/app/util/util';
import * as R from 'ramda';
import { SettingsTypes } from '../../state/mortgage-settings.model';

@Component({
	selector: 'app-moat-soa-email-settings',
	templateUrl: './moat-soa-email-settings.component.html'
})
export class MoatSoaEmailSettingsComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@Input() emailSettings: MoatSoaEmailSettingsState;

	@Input() mergeTags$: Observable<MergeTagState[]>;

	@Input() soaData: any;

	moatSoaEmailSettings$ =
		this.moatSoaEmailSettingsService.moatSoaEmailSettings$;

	isLoading = false;
	bodyContent = '<p></p>';
	bodyId: number;
	shortCodes: object;

	constructor(
		private moatSoaEmailSettingsService: MoatSoaEmailSettingsService
	) {
		super();
	}

	ngOnInit(): void {
		this.prepData();
	}

	ngOnDestroy(): void {
		super.dispose();
	}

	prepData() {
		combineLatest([this.moatSoaEmailSettings$, this.mergeTags$])
			.pipe(
				filter(([data, mt]) => !!data && !!mt),
				tap(
					([_data, mt]) =>
						(this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
				),
				tap(([data, _mt]) => (this.bodyId = +data?.body || 0)),
				map(([data, _mt]) =>
					MoatSoaEmailSettingsMapper.mapEmailSettingsData(data)
				),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.moatSoaEmailSettingsService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.moatSoaEmailSettingsService.getDocumentFromURL(
							res?.documentLink
						),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save(emailSettings: any): void {
		this.isLoading = true;
		of(emailSettings)
			.pipe(
				mergeMap((currentTemplate) => {
					return convertUtil.convertToBase64(currentTemplate?.bodyContent);
				}),
				withLatestFrom(of(this.bodyId)),
				map(([currentTemplate, bodyId]) =>
					MoatSoaEmailSettingsMapper.mapStaffEmailBody(
						currentTemplate,
						bodyId,
						0
					)
				),
				concatMap((data) =>
					iif(
						() => R.isNil(data.documentId),
						this.moatSoaEmailSettingsService
							.newFileUpload(data)
							.pipe(catchError((err) => of(err))),
						this.moatSoaEmailSettingsService.updateFileUpload(data)
					)
				),
				withLatestFrom(of(this.bodyId)),
				concatMap(([data, body]) => {
					const isNewBody = body === 0 || !body;
					const newBodyId = isNewBody ? data : body;
					const formValue = MoatSoaEmailSettingsMapper.mapEmailSettingsToUpsert(
						{
							...emailSettings,
							referenceId: 0,
							body: newBodyId,
							type: SettingsTypes.MOATRoaEmail,
						}
					);
					this.bodyId = newBodyId;
					return this.moatSoaEmailSettingsService.updateSettings(formValue);
				}),
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe();
	}
}
