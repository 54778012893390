import { Component, OnInit, Input, Output, Renderer2, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EmailStatusModel } from '../state/email-status.model';
import { EmailHistoryModel } from '../../email-history/state/email-history.model';
import { EmailStatusService } from '../state/email-status.service';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { util } from '../../../../core/util/util.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-email-status-form',
	templateUrl: './email-status-form.component.html'
})
export class EmailStatusFormComponent implements OnInit, OnDestroy, OnChanges {
	private onDestroy$ = new Subject<void>();
	@Output() valueChange = new EventEmitter<boolean>();
	@Input() emailType: ViewDisplayValue[];
	@Input() emailStatus: ViewDisplayValue[];
	@Input() emailModeList: ViewDisplayValue[];
	@Input() activeRow: EmailHistoryModel;
	@Input() getReport: (values) => Observable<any>;
	@Input() resizeChange: boolean;

	form: UntypedFormGroup;
	prepData = {};
	isSaving = false;
	isExport = false;
	isSaving$ = new ReplaySubject<boolean>(1);
	isResizeChange: boolean;

	constructor(private fb: UntypedFormBuilder,
		           private emailStatusService: EmailStatusService,
		           private activitedRoute: ActivatedRoute,
		           private renderer: Renderer2) {
		this.buildForm();
	}

	ngOnInit(): void {
	}

	ngOnChanges() {
		this.isResizeChange = this.resizeChange ? this.resizeChange : false;
		if (this.activeRow && !this.isResizeChange) {
			this.form.patchValue({
				emailHistoryId: this.activeRow.EmailHistoryId ? this.activeRow.EmailHistoryId : null,
				emailCode: this.activeRow.EmailType ? this.activeRow.EmailType : null,
				emailMode: this.activeRow.EmailMode ? this.activeRow.EmailMode : null,
				emailDateMin: this.activeRow.DateSent ? moment(this.activeRow.DateSent) : null,
				emailDateMax: this.activeRow.DateSent ? moment(this.activeRow.DateSent) : null,
				status: this.activeRow.Status ? this.activeRow.Status : null,

				firstName: null,
				lastName: null,
				emailAddress: null
			});
			this.getBtnClick();
		}
	}

	buildForm() {
		this.form = this.fb.group({
			emailHistoryId: [],
			emailType: [],
			emailCode: [],
			emailMode: [],
			firstName: [],
			lastName: [],
			emailDateMin: [],
			emailDateMax: [],
			status: [],
			emailAddress: []
		});
	}

	prepareData(values) {
		return this.prepData = {
			EmailHistoryId: values.emailHistoryId ? values.emailHistoryId : null,
			EmailCode: (values.emailCode && values.emailCode.length !== 0) ? values.emailCode : null,
			EmailMode: (values.emailMode && values.emailMode.length !== 0) ? values.emailMode : null,
			FirstName: values.firstName ? values.firstName : null,
			LastName: values.lastName ? values.lastName : null,
			Status: (values.status && values.status.length !== 0) ? values.status : null,
			EmailDateMin: values.emailDateMin ? util.MomentToDateString(values.emailDateMin) : null,
			EmailDateMax: values.emailDateMax ? util.MomentToDateString(values.emailDateMax) : null,
			EmailAddress: values.emailAddress ? values.emailAddress : null
		};
	}

	clearForm() {
		this.form.reset();
		this.form.controls.status.setValue([]);
		this.form.controls.emailCode.setValue([]);
		this.form.controls.emailMode.setValue([]);
	}

	getBtnClick() {
		this.isSaving = true;
		const values = this.prepareData(this.form.value);
		this.getReport(values).pipe(
			tap(x => {
				this.isSaving = false;
				this.valueChange.emit(true);
			}),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

	export() {
		this.isExport = true;
		const values = this.prepareData(this.form.value) as EmailStatusModel;
		this.emailStatusService.exportStatus(values).pipe(
			takeUntil(this.onDestroy$)
		)
			.subscribe(x => {
				this.downloadExport(x);
				this.isExport = false;
			});
	}

	downloadExport(file: any) {
		const name = this.activitedRoute.snapshot.paramMap.get('companyCode') + '-Email Status.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		const url = window.URL.createObjectURL(file);
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
		window.URL.revokeObjectURL(url);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

}
