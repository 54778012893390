import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { BusinessPageModel } from './business-page.model';
import { ApiService } from '../../../core/base/api.service';
import { tap, mergeMap, finalize, withLatestFrom, map, take, filter, catchError } from 'rxjs/operators';
import * as R from 'ramda';
import { BusinessPageStore } from './business-page.store';
import { applyTransaction } from '@datorama/akita';
import { BusinessPageQuery } from './business-page.query';
import { Metakey, columns } from '../business-page-datatable.config';
import { UserQuery } from '../../../domain/user/user.query';
import produce from 'immer';
import { throwError, Subject } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';

@Injectable()
export class BusinessPageService {
	private onDestroy$ = new Subject<void>();
	constructor(
		private api: ApiService,
		private businessPageStore: BusinessPageStore,
		private businessPageQuery: BusinessPageQuery,
		protected userQuery: UserQuery,
		private configService: ConfigService
	) { }

	clear(): void {
		applyTransaction(() => {
			this.businessPageStore.reset();
		});
	}

	reloadData() {
		const data = this.businessPageQuery.getAll();
		this.businessPageStore.set([]);
		this.businessPageStore.set(data);
	}

	toggleColumnsSetting(open: boolean) {
		this.businessPageStore.toggleColumnsSetting(open);
	}

	getColumns(): Observable<any> {
		return this.businessPageQuery
			.select(x => x.columns)
			.pipe(
				take(1),
				filter(x => (x ? x.length < 1 : false)),
				mergeMap(() => this.userQuery.staffSettings$),
				tap(x =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.businessPageStore.setColumns(JSON.parse(x.BusinessPageColumns))
						: null
				)
			);
	}

	getColumnWidths(): Observable<any> {
		return this.businessPageQuery
			.select(x => x.columnWidths)
			.pipe(
				take(1),
				filter(x => (x ? x.length < 1 : false)),
				mergeMap(() => this.userQuery.staffSettings$),
				tap(x =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.businessPageStore.setColumnWidths(JSON.parse(x.BusinessPageColumnsWidth))
						: null
				)
			);
	}

	reorderColumn = (oldIndex: number, newIndex: number) => {
		const oldCol = this.businessPageQuery.getValue().columns;
		const newCol = produce(oldCol, draft => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});
		const newColWith = produce(this.businessPageQuery.getValue().columnWidths, draft => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});

		return of(newCol).pipe(
			tap(() => {
				this.businessPageStore.setColumns(newCol);
				this.businessPageStore.setColumnWidths(newColWith);
			}),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					BusinessPageColumns: JSON.stringify(newVal),
					BusinessPageColumnsWidth: JSON.stringify(newColWith),
				},
			})),
			withLatestFrom(this.userQuery.userInfo$),
			mergeMap(([req, user]) => this.api.put(`staff/${user.StaffID}/tl`, req))
		);
	};

	resizeColumn(prop: string, width: number) {
		const oldColWidths = this.businessPageQuery.getValue().columnWidths?.filter(x => x);
		const newColWidths = produce(oldColWidths, draft => {
			const col = columns?.find(x => x.prop === prop);
			const exists = draft?.some(x => col.metakey === x.metakey);
			if (exists) {
				draft.find(x => col.metakey === x.metakey).width = width;
			} else {
				draft.push({ metakey: col.metakey, width });
			}
		});

		return of(newColWidths).pipe(
			tap(x => this.businessPageStore.setColumnWidths(x)),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					BusinessPageColumnsWidth: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.userInfo$),
			mergeMap(([req, user]) => this.api.put(`staff/${user.StaffID}/tl`, req))
		);
	}

	saveVisibleColumns = (metakeys: Metakey[]) => {
		const newColumns = metakeys;
		const oldColumns = this.businessPageQuery.getValue().columns;
		if (R.equals(newColumns, oldColumns)) {
			return of();
		}
		const newColumnMetakeys = newColumns;

		return of(newColumnMetakeys).pipe(
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					BusinessPageColumns: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.userInfo$),
			mergeMap(([req, user]) => this.api.put(`staff/${user.StaffID}/tl`, req)),
			tap(() => this.businessPageStore.setColumns(newColumnMetakeys))
		);
	};

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.businessPageStore.setSort(propSort, sort);
	}

	loadBusinesses() {
		const isShowAll = this.businessPageQuery.getValue().showAll;
		return this.getBusinesses('active', true, !isShowAll).pipe(
			withLatestFrom(this.businessPageQuery.select(state => state.showAll)),
			mergeMap(([, x]) => (x ? this.getBusinesses('archived', true, true) : EMPTY))
		);
	}

	getBusinesses(type: string = 'active', includeCalculations = false, updateCalculation = true) {
		const endpoint = `businesses?type=${type}&includeCalculations=${includeCalculations}`;
		this.businessPageStore.setIsSearching(true);
		return this.api.get<BusinessPageModel[]>(endpoint).pipe(
			tap(x => applyTransaction(() => this.businessPageStore.upsertMany(x ?? []))),
			finalize(() => {
				this.businessPageStore.setIsSearching(false);
			})
		);
	}

	archiveBusiness(req: { Business: any; Status: number }): Observable<any> {
		this.businessPageStore.setIsLoading(true);
		const endpoint = `businesses/${req.Business.CompanyCode}`;
		this.configService.SetCompanyCode(req.Business.CompanyCode);
		return this.api.get<any>(endpoint).pipe(
			mergeMap(x => this.api.put<any>(endpoint, { ...x, IsActive: req.Status })),
			mergeMap(x => {
				this.businessPageStore.update(
					req.Business.BusinessID,
					produce(draft => {
						draft.IsActive = req.Status;
					})
				);

				return of(x);
			}),
			finalize(() => {
				this.businessPageStore.setIsLoading(false);
				this.configService.clearCompanyCode();
			})
		);
	}

	showArchive(show: boolean) {
		this.businessPageStore.setShowAll(show);
		return of(show).pipe(
			filter(() => !this.businessPageQuery.getValue().hasArchived),
			mergeMap(() => this.getBusinesses('archived')),
			finalize(() => {
				this.businessPageStore.update(
					produce(x => {
						x.hasArchived = true;
					})
				);
				this.reloadData();
			})
		);
	}

	export(req: { ReportType: string; DateMin?: string; DateMax?: string; PageIndex?: number }) {
		this.businessPageStore.setIsExporting(true);
		if (req.ReportType === 'ProductionReport') {
			return of(req).pipe(
				mergeMap(r => this.api.postFileDownload('Export/ExportBusinessesReport', r)),
				tap(() => this.businessPageStore.setIsExporting(false)),
				catchError(err => {
					this.businessPageStore.setIsExporting(false);
					return throwError(new Error(err));
				})
			);
		} else if (req.ReportType === 'BookBreakdown') {
			return of(req).pipe(
				mergeMap(r => this.api.postFileDownload('Export/ExportBookSummaryReport')),
				tap(() => this.businessPageStore.setIsExporting(false)),
				catchError(err => {
					this.businessPageStore.setIsExporting(false);
					return throwError(new Error(err));
				})
			);
		}
	}
}
