<div class="purpose" [class.submitted]="submitted">
	<div class="purpose-header mb-3">Application Purpose</div>
	<ng-container
		*ngIf="
			!applicationBanks ||
				(isLoadingPCM$ | async) ||
				(isLoading$ | async) ||
				(isApplicationDocumentLoading | async);
			else showForm
		"
	>
		<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
	</ng-container>
	<ng-template #showForm>
		<form [formGroup]="form" class="form" class="mb-3">
			<div class="row pb-0 pb-3">
				<div class="col-12 col-lg-6">
					<div class="row pl-1">
						<div class="col-lg-4 col-12">
							<div class="row py-1">
								<div class="col">
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="newPurchase"
											formControlName="newPurchase"
											[checked]="NewPurchase.value"
											(change)="tickChange($event, purposeTypes.NewPurchase)"
										/>
										<label
											class="custom-control-label"
											for="newPurchase"
											[class.invalid-cbox]="!this.HasPurpose"
												>New Purchase</label
											>
									</div>
								</div>
							</div>

							<div class="row py-1">
								<div class="custom-control custom-checkbox">
									<div class="col">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="lendingTopup"
											formControlName="lendingTopup"
											[checked]="LendingTopup.value"
											(change)="tickChange($event, purposeTypes.LendingTopup)"
										/>
										<label
											class="custom-control-label"
											for="lendingTopup"
											[class.invalid-cbox]="!this.HasPurpose"
												>Lending Topup</label
											>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-12">
							<div class="row py-1">
								<div class="custom-control">
									<div class="col">&nbsp;</div>
								</div>

								<!-- <div -->
								<!-- 	class="custom-control custom-checkbox" -->
								<!-- 	tooltip="Coming soon" -->
								<!-- > -->
								<!-- 	<div class="col"> -->
								<!-- 		<input -->
								<!-- 			type="checkbox" -->
								<!-- 			class="chckbox custom-control-input disabled" -->
								<!-- 			id="businessLending" -->
								<!-- 			formControlName="businessLending" -->
								<!-- 			[checked]="BusinessLending.value" -->
								<!-- 			(change)=" -->
								<!-- 				tickChange($event, purposeTypes.BusinessLending) -->
								<!-- 			" -->
								<!-- 		/> -->
								<!-- 		<label -->
								<!-- 			class="custom-control-label disabled" -->
								<!-- 			for="businessLending" -->
								<!-- 			>Business Lending</label -->
								<!-- 		> -->
								<!-- 	</div> -->
								<!-- </div> -->
							</div>

							<div class="row py-1">
								<div class="custom-control custom-checkbox">
									<div class="col">
										<input
											type="checkbox"
											class="chckbox custom-control-input"
											id="refinance"
											formControlName="refinance"
											[checked]="Refinance.value"
											(change)="tickChange($event, purposeTypes.Refinance)"
										/>
										<label
											class="custom-control-label"
											for="refinance"
											[class.invalid-cbox]="!this.HasPurpose"
												>Refinance</label
											>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6">
					<div class="row align-items-center justify-content-end mb-1">
						<div class="col-lg-3 col-12 font-weight-bold">Application Bank</div>
						<div class="col-lg-6 col-12">
							<span class="crt-custom-dropdown w-100">
								<select
									class="crt-form-control form-control"
									formControlName="bank"
								>
									<option value=""></option>
									<option
										*ngFor="
											let d of applicationBanks;
											trackBy: applicationBanksTrackBy
										"
										[value]="d.value"
									>
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
					</div>

					<div class="row align-items-center justify-content-end">
						<div class="col-lg-3 col-12 font-weight-bold">
							Current Primary Bank
						</div>
						<div class="col-lg-6 col-12">
							<span class="crt-custom-dropdown w-100">
								<select
									class="crt-form-control form-control"
									formControlName="currentPrimaryBank"
								>
									<option value=""></option>
									<option
										*ngFor="let d of primaryBanks | async"
										[value]="d.value"
									>
										{{ d.display }}
									</option>
								</select>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="main-content-section__footer mb-4">
				<div class="border-bottom"></div>
			</div>

			<div class="row mb-3 pl-1">
				<div class="col-auto">
					<div class="custom-control custom-checkbox">
						<input
							type="checkbox"
							class="chckbox custom-control-input"
							id="isPreApproval"
							formControlName="isPreApproval"
							[checked]="IsPreApproval.value"
						/>
						<label class="custom-control-label" for="isPreApproval"
							>Application is a Pre-approval</label
						>
					</div>
				</div>

				<div class="col-auto">
					<div class="custom-control custom-checkbox">
						<input
							type="checkbox"
							class="chckbox custom-control-input"
							id="isSellingProperties"
							[checked]="IsSellingProperties.value"
							formControlName="isSellingProperties"
						/>
						<label class="custom-control-label" for="isSellingProperties"
							>I am selling properties</label
						>
					</div>
				</div>
			</div>

			<div class="main-content-section__footer mb-3">
				<div class="border-bottom"></div>
			</div>

			<div class="row pb-0 pb-2">
				<div class="col-12">
					<div class="row">
						<div class="col-12">
							<label class="font-weight-bold"
								>Reason for sending this application to
								{{
									ApplicationBank?.value ? ApplicationBank.value : "Bank"
								}}</label
							>
						</div>
					</div>
				</div>
			</div>
			<div class="row pb-0 pb-2">
				<div class="col-12">
					<div class="row">
						<div class="col-12 scope-of-service__textbox">
							<app-wysiwyg
								#contentEditor
								[content]="Reason.value"
								[simpleEditor]="true"
								[extendOptions]="optionsWysiswyg"
                [showToolbar]="false"
							></app-wysiwyg>
						</div>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
</div>
