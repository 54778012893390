import { AssetCustomerServiceState } from './assets';

export class AssetsMapper {
  /*
	 * Map to view
	 */
	public static mapToView(data: AssetCustomerServiceState): AssetCustomerServiceState {
		if (!data) {
			return { ...data };
		}
		return {
			...data,
			customerServiceID: data.customerServiceID,
			customerID: data.customerID,
      asset: data.asset,
      description: data.description,
      owner: typeof data.owner === 'string' ? (data.owner ? JSON.parse(data.owner)?.map((x) => +x) : null) : data.owner,
      value: data.value,
			status: data.status ?? null
		};
	}

  /*
	 * Map to update
	 */
	public static mapToUpsert(data: AssetCustomerServiceState): AssetCustomerServiceState {
		const owners = (data.owner as any)?.map((x) => +x);
		return {
			...data,
			customerServiceID: data.customerServiceID,
			customerID: data.customerID,
      asset: data.asset,
      description: data.description,
      owner: data.owner && data.owner.length ? JSON.stringify(owners) : null,
      value: data.value ? +data.value : null,
		};
	}
}
