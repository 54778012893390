import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { find, propEq } from 'ramda';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { Disability } from 'src/app/shared/models/client-review-template/risk-analysis/disability/disability.model';
import { objectUtil } from 'src/app/util/util';
import { ClientReviewTemplateQuery } from '../../client-review-template.query';
import { ClientReviewTemplateService } from '../../client-review-template.service';
import { ClientReviewTemplateStore } from '../../client-review-template.store';
import { DisabilityQuery } from './disability.query';
import { DisabilityStore } from './disability.store';

@Injectable({
  providedIn: 'root',
})
export class DisabilityService
  extends ClientReviewTemplateService
  implements Resolve<boolean>
{
  formValue$ = this.disabilityQuery.select((x) => x.formValue);

  constructor(
    private api: ApiService,
    protected dropdownValueQuery: DropdownValueQuery,
    protected store: ClientReviewTemplateStore,
    protected query: ClientReviewTemplateQuery,
    protected customerService: CustomerService,
    protected businessService: BusinessService,
    protected disabilityStore: DisabilityStore,
    protected disabilityQuery: DisabilityQuery
  ) {
    super(dropdownValueQuery, store, query, customerService, businessService);
  }

  clearData() {
    applyTransaction(() => {
      this.disabilityStore.reset();
    });
  }

  getDisabilities() {
    return this.api
      .get<[]>(
        `crt/risk-analysis/advice-process/${
          this.query.getValue().adviceProcessId
        }/${AdviceProcessSectionCodes.Disability}`
      )
      .pipe(
        map((x) => x.map(objectUtil.mapPascalCaseToCamelCase) as Disability[]),
        tap((x) =>
          applyTransaction(() => {
            this.disabilityStore.set(x);
          })
        ),
        catchError(() => of(undefined))
      );
  }

  addDisability(disability: Disability) {
    const data = objectUtil.mapCamelCaseToPascalCase(disability);
    return this.api.post3<number>(`crt`, data).pipe(
      tap((x) =>
        applyTransaction(() => {
          this.disabilityStore.update(disability.parentCRTId, {
            ...disability,
            cRTId: x,
          });
        })
      ),
      catchError(() => EMPTY)
    );
  }

  updateDisability(disability: Disability) {
    const data = objectUtil.mapCamelCaseToPascalCase(disability);
    return this.api.put<number>(`crt/${disability.cRTId}`, data).pipe(
      tap(() =>
        applyTransaction(() => {
          this.disabilityStore.update(disability.parentCRTId, disability);
        })
      ),
      catchError(() => EMPTY)
    );
  }

  setActiveParentCRTId(parentCRTId: number) {
    this.disabilityStore.setActiveParentCRTId(parentCRTId);
  }

  setFormValue(data: Disability) {
    this.setHasFormChanges(true);
    const list = this.disabilityQuery.getAll();
    const isExisting = find(propEq('parentCRTId', data?.parentCRTId))(list);
    if (isExisting) {
      this.disabilityStore.update(data?.parentCRTId, data);
    } else {
      this.disabilityStore.add(data);
    }
  }

  resolve(): Observable<boolean> {
    const getDisabilities$ = !!this.disabilityQuery.getHasCache()
      ? of(null)
      : this.getDisabilities();

    return forkJoin([getDisabilities$]).pipe(mapTo(true));
  }
}
