import { Component } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-activity-modal',
	templateUrl: './activity-modal.component.html',
	styleUrls: ['./activity-modal.component.scss'],
})
export class ActivityModalComponent implements EditActivityModalModel {
	edit$: Observable<ActivityViewModel>;
	savefn: (ac: ActivityViewModel) => Observable<any>;
	header: string;
	message: string;
	formItem: ActivityViewModel;
	isLoading = false;
	isModal = false;
	hideClient = false;
	isRequiredDate = false;
	permissionsToComplete: string[];
	isEditForm = true;
	isActivitySettings: boolean = false;
	isActivityReferral: boolean = false;
	clearIds: () => void;

	public readonly AT$: Observable<ViewDisplayValue[]>;
	public readonly AM$: Observable<ViewDisplayValue[]>;
	public readonly adviserChoices$: Observable<ViewDisplayValue[]>;
	public readonly adviserCalendarChoices$: Observable<ViewDisplayValue[]>;

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
		this.setupSubs();
	}

	setupProps() {
		this.header = 'Edit';
		this.message = 'Edit?';
	}
	setupSubs() {}

	edit(activity: ActivityViewModel) {
		if (this.isRequiredDate && !activity.DueDate) {
			return;
		}
		this.isLoading = true;
		this.savefn(activity).pipe(
			finalize(() => this.isLoading = false)
		).subscribe(() => this.decline());
	}

	decline = () => this.bsModalRef.hide();

	close() {
		this.bsModalRef.hide();
		if (this.clearIds) {
			this.clearIds();
		}
	}
}

export interface EditActivityModalModel {
	edit$: Observable<any>;
	header: string;
	message: string;
	permissionsToComplete: string[];
}
