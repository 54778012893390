import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CrmRoutingModule } from './crm-routing.module';

import { DashboardModule } from './dashboard/dashboard.module';
import { LeadSearchModule } from './lead-search/lead-search.module';
import { ClientSearchModule } from './client-search/client-search.module';
import { PipelineModule } from './pipeline/pipeline.module';
import { ClientProfileModule } from './client-profile/client-profile.module';
import { BusinessProfileModule } from './business-profile/business-profile.module';
import { ActivityModule } from './activity/activity.module';
import { DocumentModule } from './document/document.module';
import { ReportModule } from './report/report.module';

import { CrmComponent } from './crm.component';
import { GlobalSearchComponent } from '../crm/global-search/global-search.component';

import { ControlLoaderService } from '../../core/services/control-loader.service';
import { ClientReviewTemplateModule } from './client-review-template/client-review-template.module';
import { LRCrtGuard } from './guards/lr-crt.guard';
import { ReferralOptionsService } from '../referral-options/state/referral-options.service';
import { ReferralOptionsStore } from '../referral-options/state/referral-options.store';
import { ReferralOptionsQuery } from '../referral-options/state/referral-options.query';
import { CrtPageModule } from './crt-page/crt-page.module';
import { AnnouncementPopupModule } from '@modules/annoucements/shared/announcement-popup/announcement-popup.module';
import { HeaderComponent } from '@shared/header/header.component';

@NgModule({
	imports: [
		CommonModule,
		CrmRoutingModule,
		SharedModule,

		DashboardModule,
		LeadSearchModule,
		ClientSearchModule,
		PipelineModule,
		ClientProfileModule,
		BusinessProfileModule,
		ActivityModule,
		DocumentModule,
		ReportModule,
		ClientReviewTemplateModule,
		CrtPageModule,
		AnnouncementPopupModule,
		HeaderComponent
	],
	declarations: [CrmComponent, GlobalSearchComponent],
	providers: [
		{
			provide: ControlLoaderService,
			useClass: ControlLoaderService,
		},
		LRCrtGuard,
		ReferralOptionsService,
		ReferralOptionsStore,
		ReferralOptionsQuery,
	],
})
export class CrmModule {}
