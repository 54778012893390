import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { strUtil } from 'src/app/util/util';
import { LoggerService } from '../../../../core/logger/logger.service';
import { PeopleService } from '../../../../modules/crm/client-review-template/states/people/people.service';
import { DisclosureDocumentMapper } from '../../../../shared/models/client-review-template/disclosure-document/disclosure-document.mapper';
import { DependentDetailsState } from '../../../../shared/models/client-review-template/dependent/dependent-details';
import { DependentMapper } from '../../../../shared/models/client-review-template/dependent/dependent.mapper';
import { PeopleDetailsState } from '../../../../shared/models/client-review-template/people/people-details.model';
import { PeopleMapper } from '../../../../shared/models/client-review-template/people/people.mapper';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { logMessage } from 'src/app/shared/error-message/error-message';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit, ConfirmModalModel {
  saveEmailFn: (model: any) => Observable<any>;
  sendEmailFn: (model: any) => Observable<any>;
  confirm$: Observable<any>;
  decline$: Observable<any>;
  header: string;
  message: string;
  dropdown: Observable<any>;
  dropdownList: ViewDisplayValue[];
  personInfo: PeopleDetailsState;
  dependentInfo: DependentDetailsState;
  isFPP = true;
  isLoading = false;
  submitted = false;
  form: UntypedFormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private loggerService: LoggerService,
    private peopleService: PeopleService
  ) {
    this.buildForm();
  }

  get EmailDropdown() {
    return this.form.get('emailDropdown');
  }
  get EmailRecipient() {
    return this.form.get('emailRecipient');
  }

  ngOnInit(): void {
    this.dropdown
      .pipe(
        map((data) => DisclosureDocumentMapper.mapCRTPeopleToEmailDd(data)),
        take(1)
      )
      .subscribe((data) => {
        this.dropdownList = data;
      });
  }

  buildForm() {
    this.form = this.fb.group({
      emailDropdown: [null],
      emailRecipient: [null, [Validators.required]],
    });
  }

  decline() {
    if (!!this.decline$) {
      this.decline$.subscribe();
    }
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }

  sendEmail() {
    this.submitted = true;
    if (this.form.invalid) {
      this.loggerService.Warning(null, logMessage.shared.general.warning.required);
      return;
    }

    this.sendEmailFn(this.form.getRawValue()).subscribe();
    this.bsModalRef.hide();
  }

  getCrtInfo(event) {
    const val = event.target.value;
    this.EmailRecipient.setValue('');

    if (val && val !== 'Other') {
      this.isLoading = true;
      this.EmailDropdown.disable();
      this.EmailRecipient.disable();

      this.peopleService
        .getPersonInfo(+val)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.EmailDropdown.enable();
            this.EmailRecipient.enable();
          }),
          take(1)
        )
        .subscribe((res) => {
          this.setPersonInfo(res);
        });
    } else {
      this.EmailDropdown.enable();
      this.EmailRecipient.enable();
    }
  }

  setPersonInfo(data) {
    this.EmailRecipient.setValue(data?.email);
    this.personInfo = null;
    this.dependentInfo = null;

    if (data?.sectionCode === 'FPP') {
      this.isFPP = true;
      this.personInfo = data;
    } else {
      this.isFPP = false;
      this.dependentInfo = data;
    }
  }

  saveCRTEmail() {
    const getPeopleData = (data) =>
      PeopleMapper.mapToUpsert(data, this.personInfo);
    const getDependentData = (data) =>
      DependentMapper.mapToUpsert(data, this.dependentInfo);

    of(this.isFPP)
      .pipe(
        map((isFPP) => {
          if (isFPP) {
            return getPeopleData({
              ...this.personInfo,
              email: this.EmailRecipient.value,
            });
          } else {
            return getDependentData({
              ...this.dependentInfo,
              email: this.EmailRecipient.value,
            });
          }
        }),
        filter((x) => strUtil.isEmail(x?.email)),
        tap((x) => (this.isLoading = true)),
        mergeMap((data) => {
          return this.saveEmailFn(data).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          );
        }),
        finalize(() => {
          this.isLoading = false;
        }),
        take(1)
      )
      .subscribe();
  }
}

export interface ConfirmModalModel {
  confirm$: Observable<any>;
  decline$?: Observable<any>;
  header: string;
  message: string;
}
