import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { complement, either, isNil, isEmpty, omit } from 'ramda';
import {
	combineLatest,
	iif,
	interval,
	Observable,
	Observer,
	of,
} from 'rxjs';
import {
	concatMap,
	delay,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
	catchError,
} from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/base/component-base';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { EmailService } from 'src/app/modules/crm/client-review-template/states/email/email.service';
import { EmailModalComponent } from 'src/app/shared/modal/crt/email/email-modal.component';
import {
	AdviceProcessSectionCodes,
	MoatStructurePages,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { StatementOfAdviceMapper } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';
import {
	AdviceProcessDocumentTypesMOAT,
	DocumentTypes,
	DocumentTypesMOAT,
} from 'src/app/shared/models/documents/document.model';
import { EmailTypes } from 'src/app/shared/models/emails/crt/email.model';
import { UserQuery } from '../../../../../../domain/user/user.query';
import { SoaSettingsState } from '../../../../../../modules/mortgage-settings/soa-settings/state/soa-settings.model';
import {
	filterNonEmailMergeTags,
	getContentWithMergeTags,
	removeEmptyParagraphs,
	resetMergeTags,
} from '../../../../../../shared/converter/content-merge-tags';
import { SosMTMapper } from '../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/scope-of-service/sos.mapper';
import { StructureSoaMapper } from '../../../../../../shared/models/client-review-template/merge-tags/crt-mortgage/structure-soa/structure-soa.mapper';
import { WysiwygComponent } from '../../../../../../shared/wysiwyg/wysiwyg.component';
import { convertUtil, objectUtil } from '../../../../../../util/util';
import { CrtDocumentService } from '../../../_shared/service/crt-document.service';
import { ApplicationService } from '../../application/state/application.service';
import { PeopleEntitiesQuery } from '../../client-sop/people-entities/state/people-entities.query';
import { MortgageProviderCommissionService } from '../../provider-comission/state/provider-commission.service';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { MergeTagsService } from '../../state/merge-tags/merge-tags.service';
import { MortgageAdviceProcessService } from '../../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { LoanService } from '../loan/state/loan.service';
import { MoatRoaPdfOrder, StructureSoa } from '../state/structure-soa.model';
import { StructureSoaService } from '../state/structure-soa.service';
import { SoaService } from './state/soa.service';
import { SettingsTypes } from 'src/app/modules/mortgage-settings/state/mortgage-settings.model';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { PeopleEntitiesService } from '../../client-sop/people-entities/state/people-entities.service';
import { HtmlPdfConfigState } from '@modules/crm/crt-page/_shared/service/html-pdf/defaults-config';
import { CombinedAttachment } from '@shared/models/_general/attachment.model';
import { PdfDesignV2Mapper } from '@shared/models/client-review-template/pdf-design-v2/pdf-design-v2.mapper';
import { PdfDesignV2Options, PdfDesignV2State } from '@shared/models/client-review-template/pdf-design-v2/pdf-design-v2.model';
import MomentUtil from '@util/moment.util';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';

@Component({
	selector: 'app-statement-of-advice',
	templateUrl: './statement-of-advice.component.html',
	styleUrls: ['./statement-of-advice.component.scss'],
	exportAs: 'statementOfAdvice',
})
export class StatementOfAdviceComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@ViewChild('contentEditor') editor: WysiwygComponent;
	@Input() cRTId: number;
	@Input() adviceProcessId: number;
	@Input() data$: Observable<StructureSoa>;
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
	}>();
	@Output() lastAutoSavedTime: EventEmitter<string> = new EventEmitter<string>(
		null
	);
	@Output() isAutoSaveLoading: EventEmitter<boolean> =
		new EventEmitter<boolean>(false);

	currentTemplate = '<p></p>';
	isMergeTagLoading = false;
	isInit = true;
	isAutoSaveEnabled = false;
	isLoading: boolean;
	isSaving: boolean;
	data: StructureSoa;
	templateId: number;
	soaSettings: SoaSettingsState;

	isTapLevel = this.userQuery.isTapLevel();
	mergeTags$ = this.mtService.mergeTags$;
	mergeTags = this.moatQuery.getValue().mergeTags;
	sosDefault$ = this.moatQuery.sosDefault$;
	sosCrt$ = this.moatQuery.scopeOfService$;
	themeConfig$ = this.businessConfigQuery.themeConfig$;
	approvedLender$ = this.loanService.loanStructure$.pipe(
		withLatestFrom(this.applicationService.applications$),
		map(([x, y]) => y?.find((i) => +i?.cRTId === +x?.approvedApplication)),
		map((x) => x?.bank || '')
	);
	groupName = this.moatQuery
		.getValue()
		.primaryClient?.groupName?.toString()
		?.toUpperCase();
	emailSettings$ = this.service.emailSettings$;
	// used in structure-soa.component template
	statementOfAdviceEmailEnabled$ = this.service.statementOfAdviceEmailEnabled$;
	peopleAndDependents$ = this.peopleQuery.peopleAndDependentsFromCRTOnly$;
	loanSplit$ = this.loanService.loanSplits$;
	public bsModalRef: BsModalRef;

	fileName = `${this.groupName} RECORD OF ADVICE`;
	pdfClasses = 'moat-soa-pdf pdf-design-v2';
	pdfHeaderText = 'Record of Advice'

	constructor(
		private service: StructureSoaService,
		private userQuery: UserQuery,
		private soaService: SoaService,
		private moatQuery: CrtMortgageQuery,
		private mtService: MergeTagsService,
		private loanService: LoanService,
		private mApService: MortgageAdviceProcessService,
		private crtDocsService: CrtDocumentService,
		private providerComission: MortgageProviderCommissionService,
		private applicationService: ApplicationService,
		private modalService: BsModalService,
		private emailService: EmailService,
		private peopleService: PeopleEntitiesService,
		private peopleQuery: PeopleEntitiesQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private crtMortgageService: CrtMortgageService,
		private router: Router
	) {
		super();
	}

	ngOnInit(): void {}

	loadComponent() {
		this.loadSOA();
	}

	loadSOA() {
		this.isLoading = true;
		combineLatest([this.service.isLoading$, this.soaService.getSoaSettings()])
			.pipe(
				filter(([isLoading, soaSettings]) => !isLoading && !!soaSettings),
				withLatestFrom(this.data$),
				tap(([, structureSOA]) => {
					this.data = structureSOA;
					this.cRTId = structureSOA?.cRTId;
				}),
				withLatestFrom(this.moatQuery.soaSettings$),
				tap(([, x]) => (this.soaSettings = x)),
				concatMap(() => this.getMergeTags()),
				concatMap(() => this.previewContent()),
				concatMap(() =>
					this.service.getEmailSettings(0, SettingsTypes.MOATRoaEmail)
				),
				finalize(() => {
					this.isLoading = false;
					this.isInit = false;
					this.initializeAutoSave();
				}),
				take(1)
			)
			.subscribe();
	}

	initializeAutoSave() {
		if (this.isAutoSaveEnabled) {
			return;
		} // If already running
		this.isAutoSaveEnabled = true;

		interval(300000) // 5 minutes
			.pipe(
				withLatestFrom(this.service.structureSOA$),
				filter(([, roa]) => {
					const data: StructureSoa[] = roa?.sort((a, b) =>
						b.modifiedDateTime > a.modifiedDateTime ? 1 : -1
					);
					return data[0]?.currentPage === MoatStructurePages.SOA;
				}),
				tap(() => this.isAutoSaveLoading.emit(true)),
				tap(() => (this.isSaving = true)),
				concatMap(() => this.saveTxtFile()),
				tap((id) => {
					this.templateId = this.hasTemplate(this.templateId)
						? this.templateId
						: +id;
				}),
				map((res) =>
					this.hasTemplate(this.templateId) ? this.templateId : res
				),
				concatMap((documentId) => {
					const newData = {
						...this.data,
						documentId,
						currentPage: MoatStructurePages.SOA,
					};
					return iif(
						() => !this.cRTId,
						this.service.add(newData).pipe(
							tap((id: any) => (this.data = { ...newData, cRTId: id })),
							tap((id: any) => (this.cRTId = id)),
							catchError((err) => of(err))
						),
						of(documentId).pipe(
							mergeMap(() =>
								iif(
									() => this.hasTemplate(this.data?.documentId),
									of(documentId),
									this.service.update(newData).pipe(
										tap(() => (this.data = newData)),
										catchError((err) => of(err))
									)
								)
							)
						)
					);
				}),
				tap(() => {
					const lastSaved = MomentUtil.formatToServerTime(
						MomentUtil.createMomentNz()
					);
					this.lastAutoSavedTime.emit(lastSaved);
					this.isAutoSaveLoading.emit(false);
					this.isSaving = false;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	getMergeTags = () => {
		this.isMergeTagLoading = true;
		return this.service.getLsSoaMergeTags(+this.adviceProcessId).pipe(
			withLatestFrom(this.mergeTags$),
			// map(([, mt]) => mt),
			map(([, mt]) => {
				return mt.map((m) => {
					switch (m.metaKey) {
						case 'APPLICATION_REASON':
							m.value =
							m?.value?.map((value) => {
							value = value
								?.replaceAll('<p>', '')
								?.replaceAll('</p>', '<br>');
								return value;
						}) ?? [];
						break;
					}
					return m;
				});
			}),
			map((mt) => MergeTagsMapper.mapCrtMergeTags(mt)),
			map((mt) => StructureSoaMapper.roaWordingsMergeTags(mt)),
			map((mt) => SosMTMapper.updateSosMergeTags(mt)),
			map((mt) => StructureSoaMapper.addLsSoaMergeTags(mt)),
			withLatestFrom(this.approvedLender$, this.providerComission.settings$),
			map(([mt, lender, pc]) =>
				StructureSoaMapper.addMortgageLenderMergeTags(mt, lender, pc)
			),
			map((mt) => StructureSoaMapper.convertLoanInterestOnlyMergeTags(mt)),
			tap((mt) => (this.mergeTags = mt)),
			tap(() => (this.isMergeTagLoading = false)),
			take(1)
		);
	};

	previewContent = () =>
		this.data$.pipe(
			switchMap((x: StructureSoa) =>
				iif(
					() => this.hasTemplate(x?.documentId),
					this.getTemplate(+x?.documentId),
					this.getSettingsTemplate()
				)
			),
			concatMap((content) => this.updateDynamicMT(content)),
			tap((content) => (this.currentTemplate = content)),
			take(1)
		);

	convertContent = (content: string, mergeTags?: MergeTagState[]) => {
		const mt = mergeTags || this.mergeTags;
		const updatedMT = SosMTMapper.updateSosMergeTag(mt);
		return getContentWithMergeTags(content || '', updatedMT);
	}


	getSettingsTemplate = () =>
		this.moatQuery.soaSettings$.pipe(
			tap(() => (this.templateId = null)),
			concatMap((x) => this.soaService.getDocumentFromURL(x?.templateLink)),
			take(1)
		);

	getTemplate = (documentId?: number, isNew = false) =>
		of(documentId).pipe(
			tap((id) => {
				this.templateId = isNew ? null : +id;
			}),
			concatMap((id) => this.soaService.getDocumentFile(+id)),
			concatMap((res) =>
				iif(
					() => res?.documentLink,
					this.soaService.getDocumentFromURL(res?.documentLink),
					of(null)
				)
			),
			take(1)
		);

	hasTemplate = (id) =>
		complement(either(isNil, isEmpty))(id) && !isNaN(+id) && +id > 0;

	processHiddenElements(content: string) {
		return of(content).pipe(
			map((x) => StatementOfAdviceMapper.hideLoanStructureFields(x)),
			take(1)
		);
	}

	updateDynamicMT = (content) => {
		return of(content || '').pipe(
			// Reset merge tags first
			map((x) => resetMergeTags(x)),
			map((x) => SosMTMapper.resetMergeTags(x)),
			// Re-convert merge tags to update
			map((x) => this.convertContent(x, this.mergeTags)),
			switchMap((x) => this.processHiddenElements(x)),
			map((x) => removeEmptyParagraphs(x)),
			take(1)
		);
	};

	saveTxtFile = () =>
		of(this.editor?.content).pipe(
			switchMap((content) => convertUtil.convertToBase64(content)),
			map((content) =>
				StatementOfAdviceMapper.mapDocumentUpload(
					content,
					this.data?.cRTId,
					this.templateId
				)
			),
			concatMap((data) =>
				iif(
					() => this.hasTemplate(data?.documentId),
					this.soaService.updateFileUpload(data),
					this.soaService.newFileUpload(data)
				)
			)
		);

	/**
	 * Save SOA
	 * @param isNext (boolean) - Define if next set as true; If previous, leave empty
	 */
	save(isNext = false, path?: string, isAutoSave?: boolean) {
		of(this.getContent())
			.pipe(
				tap(() => (this.isSaving = true)),
				mergeMap((content) => this.prepDocWithGenericCover(content)),
				concatMap((x) =>
					this.mApService.updateAdviceProcess(
						x,
						AdviceProcessDocumentTypesMOAT.ROA
					)
				),
				concatMap(() => this.saveTxtFile()),
				tap((data) => {
					this.templateId = this.hasTemplate(this.templateId)
						? this.templateId
						: +data;
				}),
				map((res) =>
					this.hasTemplate(this.templateId) ? this.templateId : res
				),
				concatMap((documentId) =>
					iif(
						() => !this.cRTId,
						this.service.add(this.data).pipe(
							tap((id: any) => (this.cRTId = id)),
							catchError((err) => of(err))
						),
						of(this.data).pipe(
							tap((x) => {
								this.service.updateDocumentId(this.cRTId, documentId);
							})
						)
						// Updating is handled by ROA of saving the current page
					)
				),
				delay(300),
				tap(() => (this.isSaving = false)),
				finalize(() => (path ? this.navigateToUrl(path) : null)),
				take(1)
			)
			.subscribe(
				(data) => {
					if (!isAutoSave) {
						this.saveCompleted.emit({ isSuccess: true, isNext });
					}
				},
				(err) => {
					if (!isAutoSave) {
						this.saveCompleted.emit({ isSuccess: false, isNext });
					}
				}
			);
	}

	ngOnDestroy(): void {
		this.isAutoSaveEnabled = false;
		super.dispose();
	}

	sendToRecipients = (data: any) => {
		return this.emailService.sendToRecipients(
			data,
			EmailTypes.MOATROAEmailSettings,
			this.cRTId,
			filterNonEmailMergeTags(this.mergeTags$)
		);
	}; // tslint:disable-line

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(Object.assign({}, omit(['age'], data)));
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(x),
					this.peopleService.updatePeople(x)
				)
			),
			takeUntil(this.onDestroy$)
		);
	};

	getContent() {
		return `<div class="${this.pdfClasses}">${this.editor?.content || ''}</div>`;
	}

	sendEmail(): void {
		const content = this.getContent();
		const adviceProcessId =  this.moatQuery.getValue().adviceProcessId;
		const customerID = this.moatQuery.getValue().primaryClient?.customerID;
		this.service
			.getEmailSettings(0, SettingsTypes.MOATRoaEmail)
			.pipe(
				tap(() => {
					const initialState = {
						header: 'Email Client',
						enableDefaultBcc: true,
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.service.emailSettings$,
						peopleDropdown: this.peopleAndDependents$,
						attachments: [
							{
								fileName: `${this.fileName}.pdf`,
								content: '',
								allowDownloadCombine: true,
								combineDocumentInfo: {
									ReferenceId: adviceProcessId,
									CustomerId: customerID,
									FileName: `${this.fileName}.pdf`,
									DocumentType: ServicesCodes.Mortgage,
									Type: AdviceProcessDocumentTypesMOAT.ROA,
								},
							},
						],
						combinedAttachments: this.getCombinedAttachments$(content),
						successMessage: 'Record of Advice has been emailed to',
						adviceProcess: this.moatQuery.getValue().adviceProcess,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.Mortgage,
						documentInfo: {
							documentType: ServicesCodes.Mortgage,
							rype: DocumentTypesMOAT.Application,
							referenceId: adviceProcessId,
							customerId: customerID,
						},
					};

					this.modalService.show(EmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				})
			)
			.subscribe(() => {});
	}

	leaveROA(event?, path?) {
		const confirm = new Observable((obs) => {
			this.save(true, path);
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (path) {
				this.navigateToUrl(path);
			}
			obs.complete();
		});

		const close = new Observable((obs: Observer<any>) => {
			this.crtMortgageService.setTriggerLeaveROA(null);
			obs.complete();
		});

		this.toggleModal(confirm, decline, close);
	}

	back() {
		const confirm = new Observable((obs) => {
			this.save(false);
			this.crtMortgageService.setRoaCurrentTab(
				AdviceProcessSectionCodes.OtherDetails
			);
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			setTimeout(() => {
				this.currentTemplate = '<p></p>';
				this.isInit = true;
				this.saveCompleted.emit({ isSuccess: true, isNext: false });
				this.crtMortgageService.setRoaCurrentTab(
					AdviceProcessSectionCodes.OtherDetails
				);
			}, 10);
			obs.complete();
		});

		const close = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		this.toggleModal(confirm, decline, close);
	}

	toggleModal(confirm, decline, close) {
		const initState = {
			header: 'Leave Record of Advice?',
			message: `Changes that you made may not be saved.`,
			confirm$: confirm,
			decline$: decline,
			close$: close,
			confirmTxt: 'Save',
			cancelTxt: 'Leave',
			detachCloseIcon: true,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	navigateToUrl(path) {
		if (isNil(path)) {
			this.crtMortgageService.setTriggerLeaveROA(null);
		} else {
			const urlArr = this.router.url?.split('/') ?? [];
			const baseUrl = urlArr?.slice(1, 8)?.join('/');
			const navigateTo = `${baseUrl}/${path}`;
			this.router.navigateByUrl(navigateTo);
			this.clearStore();
		}
	}

	prepDocWithGenericCover = (content: string) =>
		this.getCombinedAttachments$(content)
			.pipe(
				switchMap((data) => {
					return combineLatest(
						data?.map((x) => {
							if (!!x?.toGeneratePdf) {
								return of(x?.content).pipe(
									take(1),
									mergeMap((content) =>
										this.crtDocsService.generatePDFbase64(content, x?.pdfOptions)
									),
									map((base64) => {
										return {
											document: base64,
											orderNo: x?.orderNo,
											type: x?.type,
										};
									})
								);
							}
							return of({
								document: '',
								orderNo: x?.orderNo,
								type: x?.type,
							});
						})
					);
				}),
				mergeMap((combineDocs) => {
					const DocumentObject =
						combineDocs?.map(objectUtil.mapCamelCaseToPascalCase) || [];
					const req = {
						DocumentObject,
						ReferenceId: this.moatQuery.getValue()?.adviceProcessId,
						CustomerId: this.moatQuery.getValue()?.primaryClient?.customerID,
						FileName: `${ this.fileName}.pdf`,
						DocumentType: ServicesCodes.Mortgage,
						Type: AdviceProcessDocumentTypesMOAT.ROA,
					};
					return this.crtDocsService.uploadCombineDocument(req);
				})
			);

	getCombinedAttachments$ = (content: string) =>
		of(content).pipe(
			map(removeEmptyParagraphs),
			withLatestFrom(this.businessConfigQuery.businessConfig$),
			map(([template, config]) => {
				const genericCoverBg = config?.MOATGenericCover;
				const templates = PdfDesignV2Mapper.splitTemplate(
					template,
					this.pdfClasses,
					genericCoverBg
				);
				return {
					genericCover: !!templates?.genericCover
						? this.crtDocsService.contentForPdf(templates?.genericCover)
						: '',
					body: this.crtDocsService.contentForPdf(templates?.body),
				} as PdfDesignV2State;
			}),
			withLatestFrom(
				this.businessConfigQuery.businessConfig$,
				this.getPdfHeaderFooterDefaultOptions()
			),
			map(([templates, config, pdfHeaderFooter]) => {
				const genericCoverBg = config?.MOATGenericCover;
				const genericCover = templates?.genericCover || '';
				const soaBodyDocument = templates?.body || '';
				return MoatRoaPdfOrder?.map((x) => {
					if (x?.type === DocumentTypes.MOATGenericCover) {
						const otherHeaderOptions = new URLSearchParams({
							startHeaderOnPage: '1',
						})?.toString();
						const otherFooterOptions = new URLSearchParams({
							startPageNumberOn: '-1',
						})?.toString();
						let pdfOptions = {
							...pdfHeaderFooter,
							FooterHtmlUrlParam: `${pdfHeaderFooter?.FooterHtmlUrlParam}&${otherFooterOptions}`,
							HeaderHtmlUrlParam: `${pdfHeaderFooter?.HeaderHtmlUrlParam}&${otherHeaderOptions}`,
							MarginBottom: genericCoverBg
								? '0cm'
								: pdfHeaderFooter?.MarginBottom,
						} as HtmlPdfConfigState;
						if (!!genericCoverBg) {
							pdfOptions = omit(
								['FooterHtmlUrlParam', 'FooterHtmlType'],
								pdfOptions
							);
						} else {
							pdfOptions = omit(
								['HeaderHtmlUrlParam', 'HeaderHtmlType'],
								pdfOptions
							);
						}
						return {
							...x,
							content: genericCover,
							pdfOptions,
						};
					}
					if (x?.type === DocumentTypes.MOATRecordOfAdviceDocument) {
						const otherHeaderOptions = new URLSearchParams({
							startHeaderOnPage: '1',
						})?.toString();
						const otherFooterOptions = new URLSearchParams({
							startPageNumberOn: '1',
						})?.toString();
						const FooterHtmlUrlParam = `${pdfHeaderFooter?.FooterHtmlUrlParam}&${otherFooterOptions}`;
						const HeaderHtmlUrlParam = `${pdfHeaderFooter?.HeaderHtmlUrlParam}&${otherHeaderOptions}`;
						return {
							...x,
							content: soaBodyDocument,
							pdfOptions: {
								...pdfHeaderFooter,
								HeaderHtmlUrlParam,
								FooterHtmlUrlParam,
							},
						};
					}
					return {
						...x,
						pdfOptions: pdfHeaderFooter,
					};
				})?.filter(
					(x) => !x?.toGeneratePdf || (!!x?.toGeneratePdf && !!x?.content)
				) as CombinedAttachment[];
			})
		);

	getPdfHeaderFooterDefaultOptions() {
		return this.businessConfigQuery.themeConfig$.pipe(
			withLatestFrom(this.businessConfigQuery.businessOATLogo$),
			map(([theme, oatLogo]) => {
				// Header config For PDF
				const HeaderHtmlUrlParam = new URLSearchParams({
					headerText: this.pdfHeaderText,
					headerLogo: oatLogo?.toString()?.trim() || '',
				})?.toString();
				// Footer config for PDF
				const FooterHtmlUrlParam = new URLSearchParams({
					footerColor: theme?.primarycolor || '#00263e',
				})?.toString();

				return {
					...PdfDesignV2Options,
					FileName: this.fileName,
					HeaderHtmlUrlParam,
					FooterHtmlUrlParam,
				} as HtmlPdfConfigState;
			}),
			take(1)
		);
	}

	clearStore() {
		this.crtMortgageService.setTriggerLeaveROA(null);
		this.crtMortgageService.setRoaCurrentTab(null);
	}
}
