import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { Loan } from 'src/app/modules/crm/crt-page/crt-mortgage/structure-soa/loan/state/loan.model';
import { getPerFrequency } from 'src/app/modules/crm/crt-page/_shared/calculations/monthly-conversion';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { numUtil } from 'src/app/util/util';

const generateInterestOnlyOptions = () => {
	const options = [];
	const maxYears = 30;
	const minYears = 1;
	options.push({
		value: 0,
		label: '0 Years',
	});

	options.push({
		value: 6,
		label: '6 Months',
	});

	for (let i = minYears; i <= maxYears; i++) {
		options.push({
			value: i * 12,
			label: i === 1 ? `${i} Year` : `${i} Years`,
		});
	}

	return options;
};

@Component({
	selector: 'app-loan-modal',
	templateUrl: './loan-modal.component.html',
	styleUrls: ['./loan-modal.component.scss'],
})
export class LoanModalComponent implements OnInit {
	header: string = '';
	message: string = '';
	loan: Loan;
	saveFn$: (data) => Observable<any>;
	cancelFn$: (data?) => Observable<any>;

	// loanType === mlt
	loanTypes$: Observable<ViewDisplayValue[]>;
	// frequency = apcrtf
	frequencies$: Observable<ViewDisplayValue[]>;
	// fixedPeriods
	fixedPeriods$: Observable<ViewDisplayValue[]>;
	// borrowers
	borrowers: ViewDisplayValue[];

	getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	calculateLoanRepayment: (
		loanAmount: number,
		interestRate: number,
		loanTerm: number,
		frequency: string,
		loanType: string
	) => number;

	form: UntypedFormGroup;
	submitted: boolean;
	isSaving: boolean;

	interestOnlyPeriodOptions = generateInterestOnlyOptions();

	cancelLoading: boolean;

	constructor(
		private fb: UntypedFormBuilder,
		public bsModalRef: BsModalRef,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		if (this.loan) {
			this.form.patchValue({
				...this.loan,
				repaymentPerFrequency: getPerFrequency(this.loan?.repaymentFrequency),
			});
		}
		this.form.valueChanges.subscribe((x) => {
			this.form.patchValue(
				{
					repaymentAmount: this.loanRepaymentValue(x),
					repaymentPerFrequency: getPerFrequency(x?.repaymentFrequency) || '',
				},
				{ emitEvent: false }
			);
		});
	}

	buildForm() {
		this.form = this.fb.group({
			borrowers: [[], [Validators.required]],
			loanAmount: ['', [Validators.required]],
			loanType: ['', [Validators.required]],
			interestOnlyPeriod: [''],
			interestRate: [null, [Validators.required]],
			fixedPeriod: [''],
			loanTerm: ['', [Validators.required]],
			repaymentFrequency: ['', [Validators.required]],
			haveRequestedRateLock: [false],
			repaymentAmount: [''],
			repaymentPerFrequency: [''],
			notes: [''],
		});
	}

	loanRepaymentValue(data) {
		return this.calculateLoanRepayment(
			numUtil.toValidNumber(data?.loanAmount),
			numUtil.toValidNumber(data?.interestRate),
			numUtil.toValidNumber(data?.loanTerm),
			data?.repaymentFrequency,
			data?.loanType
		);
	}

	prepareFormValue() {
		const { repaymentPerFrequency, ...data } = this.form.getRawValue();
		return {
			...data,
			borrowers: data?.borrowers?.map((x) => +x),
			interestOnlyPeriod: +data?.interestOnlyPeriod,
			interestRate: +data?.interestRate,
			loanTerm: numUtil.toValidNumber(data?.loanTerm),
			repaymentAmount:
				Math.round(
					((this.loanRepaymentValue(data) || 0) + Number.EPSILON) * 100
				) / 100,
		};
	}

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		if (this.saveFn$) {
			this.isSaving = true;
			this.saveFn$(this.prepareFormValue())
				.pipe(
					take(1),
					finalize(() => {
						this.submitted = false;
						this.isSaving = false;
						this.bsModalRef.hide();
					})
				)
				.subscribe();
		} else {
			this.submitted = false;
			this.isSaving = false;
			this.bsModalRef.hide();
		}
	}

	cancel() {
		this.cancelLoading = true;
		if (!!this.cancelFn$) {
			this.cancelFn$()
				.pipe(
					finalize(() => {
						this.bsModalRef.hide();
						setTimeout(() => this.cancelLoading = false, 500);
					}),
					take(1)
				)
				.subscribe();
		} else {
			this.bsModalRef.hide();
			setTimeout(() => this.cancelLoading = false, 500);
		}
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.borrowers || []).map((x) => ({
			...x,
			value: +x.value,
		}));
	}
}
