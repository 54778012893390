import { Injectable } from '@angular/core';
import { CanMatch, Route, UrlSegment } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthAutomationGuard implements CanMatch {
	canMatch(_route: Route, _segments: UrlSegment[]): boolean {
		return !environment.live;
	}
}
