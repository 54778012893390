import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntil, tap, mergeMap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import * as R from 'ramda';

// Required Fields
import { Staff } from '../../required-fields/staff.model';
import { PrimaryCustomerIndividual } from '../../required-fields/pci.model';
import { PrimaryCustomerBusiness } from '../../required-fields/pcc.model';
import { SecondaryCustomerIndividual } from '../../required-fields/sci.model';
import { SecondaryCustomerTrust } from '../../required-fields/sct.model';
import { Activity } from '../../required-fields/activity.model';
import { LRInsurance } from '../../required-fields/lr.model';
import { Mortgage } from '../../required-fields/mortgage.model';
import { Security } from '../../required-fields/security.model';
import { FGInsurance } from '../../required-fields/fg.model';
import { Kiwisaver } from '../../required-fields/kiwisaver.model';
import { Investment } from '../../required-fields/investment.model';
import { Adviser } from '../../required-fields/adviser.model';
import { Notes } from '../../required-fields/notes.model';
import { DataImportService } from '../../data-import.component.service';
import { Subject, Observable, of } from 'rxjs';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';

@Component({
  selector: 'app-import-type',
  templateUrl: './import-type.component.html',
  styleUrls: ['./import-type.component.scss']
})
export class ImportTypeComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @Input() form: UntypedFormGroup;
  @Input() isCancelled: boolean;

  @Output()
  importEvent = new EventEmitter<FormData>();
  @Output()
  cancelEvent = new EventEmitter();

  @Output()
  importEventType = new EventEmitter<string>();
  @Output()
  importColumnOrder = new EventEmitter<string[]>();
  @Output()
  importFile = new EventEmitter<string>();
  @Output()
  importContactStatus = new EventEmitter<string>();

  @ViewChild('file') fileElem: ElementRef;
  StaffDropdownValues$: Observable<ViewDisplayValue[]> = this.dropdownValueQuery.orderedChoices$('SF');

  // To Do: Should get from database / BE
  // Required fields
  // Staff
  SDIReqFields$: Observable<string[]> = of(Staff);
  ADIReqFields$: Observable<string[]> = of(Adviser);

  // Client
  PCIReqFields$: Observable<string[]> = of(PrimaryCustomerIndividual);
  PCCReqFields$: Observable<string[]> = of(PrimaryCustomerBusiness);
  SCIReqFields$: Observable<string[]> = of(SecondaryCustomerIndividual);
  SCTReqFields$: Observable<string[]> = of(SecondaryCustomerTrust);

  // Services
  CSLReqFields$: Observable<string[]> = of(LRInsurance);
  CSSReqFields$: Observable<string[]> = of(Security);
  CSMReqFields$: Observable<string[]> = of(Mortgage);
  CSFReqFields$: Observable<string[]> = of(FGInsurance);
  CSKReqFields$: Observable<string[]> = of(Kiwisaver);
  CSIReqFields$: Observable<string[]> = of(Investment);
  

  // Others
  ACTReqFields$: Observable<string[]> = of(Activity);
  NReqFields$: Observable<string[]> = of(Notes);

  file: any;
  fileform: FormData = new FormData();
  fileTypeError: string;
  filename: string;

  dropdownValues: ViewDisplayValue[];

  isLoading: boolean;
  requiredFieldsMsg: string;
  showRequiredFieldsMissing: boolean;

  excelData: any;
  excelValue: any;
  fileHeader: string[];
  rowsCountToUpload: number;

  disableImportBtn: boolean;
  disableUploadBtn: boolean;
  disableUpdateBtn: boolean;

  columnOrder: string[] = null;

  clientModel: any;
  serviceModel: any;
  othersModel: any;

  importType: string;
  pageCount: number;
  pageNumber: number;

  isUpdateGoogleCalendar = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dropdownValueQuery: DropdownValueQuery,
    private dataImportService: DataImportService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.initData();
    this.form = this.fb.group({
      importType: this.fb.control('', Validators.required),
      pageNumber: this.fb.control(1, Validators.required),
      pageCount: this.fb.control(1000, Validators.required)
    });

    this.form.valueChanges
      .pipe(
        mergeMap(x => {
          this.initData();

          switch (x.importType) {
            case 'SDI':
              return this.StaffDropdownValues$;
            default:
              return of<ViewDisplayValue[]>([]);
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(x => {
        this.dropdownValues = x;
        this.setColumnOrder();
      });
  }

  initData() {
    this.excelData = [];
    this.excelValue = [];
    this.columnOrder = null;
    this.dropdownValues = null;
    this.rowsCountToUpload = 0;

    this.fileform = new FormData();
    this.file = null;
    this.filename = '';

    if (this.fileElem) {
      this.fileElem.nativeElement.value = '';
    }

    this.disableImportBtn = true;
    this.disableUploadBtn = true;

    this.fileHeader = null;
    this.requiredFieldsMsg = '';
    this.showRequiredFieldsMissing = false;
    this.fileTypeError = null;
    this.isLoading = false;

    this.clientModel = null;
    this.serviceModel = null;
    this.othersModel = null;

    this.importType = null;
    this.pageCount = null;
    this.pageNumber = null;
  }

  setFile(file: FileList) {
    if (file && file.length > 0) {
      Array.from(file)?.forEach(f => {
        this.fileform.set('', f, f.name);
      });
    }
  }

  setModel(model: {
    ImportType: string;
    page_count: number;
    page_no: number;
    document_type: string;
    SourcePath: string;
    SummarySourcePath: string;
    customer_type: any;
    ContactStatus: any;
    DataImportID: string;
  }) {
    // Set model
    Object.keys(model)?.forEach(d => {
      this.fileform.set(d, model[d]);
    });
  }

  /** sets column_order */
  reOrderColumnEvent(columnOrder: string[]) {
    // Set column order
    if (columnOrder && columnOrder.length > 0) {
      this.fileform.set('ColumnOrders', JSON.stringify(columnOrder));
    }
  }

  /** Client */
  clientEvent(model: { contactStatus: string; primaryType: string; dropdownValues: ViewDisplayValue[] }) {
    this.dropdownValues = model.dropdownValues;
    this.clientModel = model;
    if (model.primaryType) {
      this.importType = model.primaryType;
      this.disableUploadBtn = false;
    }
  }

  /** Services */
  serviceEvent(model: { serviceType: string; dropdownValues: ViewDisplayValue[] }) {
    this.dropdownValues = model.dropdownValues;
    this.serviceModel = model;
    if (model.serviceType) {
      this.importType = model.serviceType;
      this.disableUploadBtn = false;
    }
  }

  /** Others */
  othersEvent(model: { othersType: string; dropdownValues: ViewDisplayValue[] }) {
    this.dropdownValues = model.dropdownValues;
    this.othersModel = model;
    if (model.othersType) {
      this.importType = model.othersType;
      this.disableUploadBtn = false;
    }
  }

  chooseFile(event: any) {
    this.fileTypeError = null;
    if (!this.isValidFile(event.target.files)) {
      if (event.target.files.length < 1) {
        this.file = null;
        this.filename = '';
        this.disableImportBtn = true;
        this.disableUploadBtn = true;
        if (this.fileElem) {
          this.fileElem.nativeElement.value = '';
        }
        return;
      }
      this.fileTypeError = 'Wrong file type / File is in invalid format';
      return;
    } else {
      const file: FileList = event.target.files;
      this.file = event.target;
      if (file && file.length > 0) {
        this.setFile(file);
        this.filename = file[0].name;

        switch (this.form.get('importType').value) {
          case 'SDI':
            this.importType = 'SDI';
            return (this.disableUploadBtn = false);
          case 'ADI':
            this.importType = 'ADI';
            return (this.disableUploadBtn = false);
          case CustomerTypes.PrimaryCustomerIndividual:
            return this.clientModel
              ? ((this.disableUploadBtn = false), (this.importType = this.clientModel.primaryType))
              : (this.disableUploadBtn = true);
          case 'Services':
            return this.serviceModel ? (this.disableUploadBtn = false) : (this.disableUploadBtn = true);
          case 'Others':
            return this.othersModel
              ? ((this.disableUploadBtn = false), (this.importType = this.othersModel.othersType))
              : (this.disableUploadBtn = true);
          default:
            return (this.disableUploadBtn = true);
        }
      }
    }
  }

  isValidFile(file: any): boolean {
    if (file && file.length > 0) {
      const validExts = new Array('.xlsx', '.csv');
      let fileExt = file[0].name;
      fileExt = fileExt?.substring(fileExt?.lastIndexOf('.'));
      if (validExts?.indexOf(fileExt) < 0) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  uploadFile() {
    this.isLoading = true;
    this.requiredFieldsMsg = '';
    this.fileTypeError = null;
    this.showRequiredFieldsMissing = false;
    if (!this.file) {
      this.isLoading = false;
      return;
    }

    /* Wire up file reader */
    const target: DataTransfer = this.file as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* Read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary',
        raw: true,
        cellText: true
      });

      /* Grab first sheet */
      const wsname: string = wb.SheetNames[0];

      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* removes formatted number(exponential number) and gets the raw number */
      Object.keys(ws)?.forEach((s) => {
        if (ws[s].w && !moment(ws[s].w).isValid()) {
          delete ws[s].w;
          ws[s].z = '0';
        }
      });

      /* Save data */
      this.excelValue = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
        raw: false
      } as any);

      for (let i = 0; this.excelValue.length > i; i++) {
        if (i === 0) {
          const lastValueLength = !this.excelValue[i]?.slice(-1)?.toString() ? 0 : this.excelValue[i]?.slice(-1)?.toString().length;
          lastValueLength <= 2 ? this.excelData.push(this.excelValue[i]?.slice(0, -1))
            : this.excelData.push(this.excelValue[i]);
        } else {
          this.excelData.push(this.excelValue[i]);
        }
      }

      // Got this header from file
      this.fileHeader = this.excelData[0];

      // get row count
      this.rowsCountToUpload = this.excelData.length - 1;

      /* Set value for column order */
      this.setColumnOrder();
      this.importEventType.emit(this.importType ? this.importType : '');
      this.importContactStatus.emit(this.clientModel && this.clientModel.contactStatus ? this.clientModel.contactStatus : '');

      this.disableImportBtn = false;
      this.isLoading = false;
    };
    reader.readAsBinaryString(target.files[0]);


    const readerByte: FileReader = new FileReader();
    readerByte.onload = (e: any) => {
      this.importFile.emit(readerByte.result?.toString()?.split(',')[1] as any);
    }
    readerByte.readAsDataURL(target.files[0]);
  }

  /** Sets Column Order */
  setColumnOrder() {
    this.columnOrder = [];
    if (!this.fileHeader) {
      return;
    }
    /* Set value for column order */

    this.fileHeader?.forEach(value => {
      const v = this.dropdownValues?.filter(x => x.display === value)[0];
      this.columnOrder.push(v === undefined ? '' : v.display);
    });
    this.importColumnOrder.emit(this.columnOrder);
    this.fileform.set('ColumnOrders', JSON.stringify(this.columnOrder));
  }

  importData() {
    this.requiredFieldsMsg = '';
    if (!this.form.valid || !this.valid()) {
      this.showErrorMsg();
      return;
    }

    const model = {
      ImportType: this.importType ? this.importType : '',
      page_count: this.pageCount ? this.pageCount : 0,
      page_no: this.pageNumber ? this.pageNumber : 0,
      document_type: '',
      SourcePath: '',
      SummarySourcePath: '',
      customer_type: this.clientModel && this.clientModel.primaryType ? this.clientModel.primaryType : '',
      ContactStatus: this.clientModel && this.clientModel.contactStatus ? this.clientModel.contactStatus : '',
      DataImportID: ''
    };

    this.setModel(model);

    this.importEventType.emit(model.ImportType);
    this.importEvent.emit(this.fileform);
  }

  updateGoogleCalendar() {
    this.disableUpdateBtn = true;
    const name = 'AdditionalActivitiesGoogleCalendar.csv';

    this.dataImportService
      .export()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        file => {
          const a = this.renderer.createElement('a');
          this.renderer.setStyle(a, 'display', 'none');
          const url = window.URL.createObjectURL(file);
          this.renderer.setAttribute(a, 'href', url);
          this.renderer.setAttribute(a, 'download', name);
          a.click();
          window.URL.revokeObjectURL(url);
        },
        () => { },
        () => {
          this.form.get('importType').reset('');
          this.disableUpdateBtn = false;
        }
      );
  }

  valid(): boolean {
    let requiredFields: Observable<string[]>;

    switch (this.importType) {
      case 'SDI': // Staff
        requiredFields = this.SDIReqFields$;
        break;
      case 'ADI': // Adviser-AM
        requiredFields = this.ADIReqFields$;
        break;
      case 'PCIDI': // Primary Client Individual
        requiredFields = this.PCIReqFields$;
        break;
      case 'PCCDI': // Primary Client Company/Business
        requiredFields = this.PCCReqFields$;
        break;
      case 'SCIDI': // Secondary Client Individual
        requiredFields = this.SCIReqFields$;
        break;
      case 'SCTDI': // Secondary Client Trust
        requiredFields = this.SCTReqFields$;
        break;
      case 'SLDI': // L&R Insurance
        requiredFields = this.CSLReqFields$;
        break;
      case 'SSDI': // Security
        requiredFields = this.CSSReqFields$;
        break;
      case 'SMDI': // Mortgage
        requiredFields = this.CSMReqFields$;
        break;
      case 'SFDI': // F&G
        requiredFields = this.CSFReqFields$;
        break;
      case 'SKDI': // Kiwisaver
        requiredFields = this.CSKReqFields$;
        break;
      case 'SIDI': // Investment
        requiredFields = this.CSIReqFields$;
        break;
      case 'ACTDI': // Activity
        requiredFields = this.ACTReqFields$;
        break;
      case 'NDI': // Notes
        requiredFields = this.NReqFields$;
        break;
      default:
        return true;
    }

    requiredFields
      .pipe(
        tap(x => {
          x?.forEach(y => {
            if (!R.contains(y, this.columnOrder)) {
              this.appendRequiredFields(y);
            }
          });
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
    return this.requiredFieldsMsg ? false : true;
  }

  appendRequiredFields(value: string) {
    if (!this.requiredFieldsMsg) {
      this.requiredFieldsMsg += value;
    } else {
      this.requiredFieldsMsg += ', ' + value;
    }
  }

  showErrorMsg() {
    if (this.requiredFieldsMsg) {
      this.showRequiredFieldsMissing = true;
    }
  }

  cancel() {
    this.initData();
    this.form.reset({
      importType: '',
      pageCount: 1000,
      pageNumber: 1
    });

    this.isLoading = false;
    this.cancelEvent.emit();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
