<div class="form-row align-items-center position-relative global-search-v2">
	<div class="col">
		<div
			#searchWrapper
			class="global-search-v2__wrapper"
			[class.active]="isExpanded"
			(mouseleave)="focusOutSearch()"
		>
			<ng-container *ngIf="!(isLoading$ | async); else spinner">
				<button
					#searchBtn
					class="global-search-v2__search-btn"
					(click)="toggleOrSearch()"
				>
					<mat-icon
						class="tap-icon align-middle search-icon"
						svgIcon="search"
					></mat-icon>
				</button>
			</ng-container>
			<input
				#searchInput
				type="text"
				class="global-search-v2__search-input"
				(keyup.enter)="onEnter(searchInput.value)"
				(keyup.esc)="closeSearch()"
				(focus)="onFocus($event)"
				(blur)="onFocus()"
				placeholder="Search"
				aria-label="Search"
				aria-describedby="basic-addon2"
				[(ngModel)]="keyword"
				id="globalSearchTerm"
			/>
		</div>
		<div
			class="global-search-v2__result py-1 border"
			*ngIf="!!(data$ | async)?.length && (isShown$ | async)"
		>
			<div class="global-search-v2__result-main">
				<div
					*ngFor="let item of data$ | async; let last = last; let i = index"
					class="search-row"
				>
					<a
						[routerLink]="item.link"
						(click)="close()"
						id="globalSearchResult_{{ i }}"
						class="search-row-link d-flex align-items-center"
					>
						<ng-container
							*ngTemplateOutlet="queryResult; context: { item: item }"
						>
						</ng-container>
					</a>
				</div>
			</div>
		</div>
	</div>

	<div
		class="overlay"
		*ngIf="!!(data$ | async)?.length && (isShown$ | async)"
		(click)="closeOverlay()"
	></div>

	<ng-template #queryResult let-item="item">
		<span class="search-icon">
			<mat-icon
				class="tap-icon md-18 results-icon-svg d-block"
				[svgIcon]="
					item.isService ? 'policies' : item.isCompany ? 'business' : 'client'
				"
			></mat-icon>
		</span>
		<span class="px-3 py-1 search-details">
			<span class="d-block item-name">{{ item.name }}</span>
			<span class="item-detail">{{ item.detail }}</span
			><br />
			<span class="item-detail">{{ item.detail2 }}</span>
		</span>
	</ng-template>

	<ng-template #typeaheadItem let-model="item" let-index="index">
		<a [routerLink]="model.link">{{ model.name }}</a>
		<div class="ml-1" *ngFor="let s of model.services">
			{{ s.name }}: {{ s.policiesConcat }}
		</div>
	</ng-template>

	<ng-template #spinner>
		<app-manual-loader-mini
			class="global-search-v2__search-btn"
			[isLoading]="isLoading$ | async"
		></app-manual-loader-mini>
	</ng-template>
</div>
