import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { getCompanyCode } from 'src/app/domain/business-config/business-config.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocalService } from '../services/local.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
	constructor(
		private authenticationService: AuthenticationService,
		private localService: LocalService,
		private router: Router
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// add auth header with jwt if user is logged in and request is to the api url
		const isLoggedIn = this.authenticationService.isLoggedIn;
		const user = this.authenticationService.user;
		const isApiUrl = request.url.startsWith(
			environment.apiProtocol + '//' + environment.apiUrl
		);
		if (request.url.includes(environment.aiQuoteApi)) {
			request = request.clone({
				setHeaders: {
					Authorization: environment.aiQuoteAuth,
				},
			});
			return next.handle(request);
		}

		if (isLoggedIn && isApiUrl) {
			request = request.clone({
				setHeaders: {
					Authorization: `${user.token_type} ${user.access_token}`,
				},
			});
		}
		if (isApiUrl) {
			const code =
				this.companyCode() || (this.localService.getValue('code') ?? '');
			request = request.clone({
				setHeaders: {
					AuthType: this.localService.getValue('loginType') || '',
					CompanyCode: !!getCompanyCode() && getCompanyCode() !== 'login' ? getCompanyCode() :  code,
					'Cache-Control':
						'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
					Pragma: 'no-cache',
					Expires: '0',
				},
			});
			this.localService.setValue('code', code);
		}

		return next.handle(request);
	}

	companyCode() {
		let route = this.router.routerState.root;
		while (route.firstChild) {
			route = route.firstChild;
		}
		return route.snapshot.params && route.snapshot.params.companyCode
			? route.snapshot.params.companyCode
			: '';
	}
}
