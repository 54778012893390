import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { SecondaryClientState } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from 'src/app/shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from 'src/app/shared/models/client-profile/seondary-business/secondary-business.model';
import {
	DocumentGroup,
	DocumentGroupState,
} from 'src/app/shared/models/documents/document-group.model';
import {
	DocumentUpload,
	DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';
import { objectUtil } from '../../../..//util/util';
import { BusinessService } from '../../../../core/business/business.service';
import { CustomerService } from '../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { PrimaryCustomerCompanyState } from '../../../../shared/models/business-profile/business/business.model';
import { PrimaryClientState } from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { ClientReviewTemplateQuery } from './client-review-template.query';
import {
	ClientReviewTemplateContacts,
	ClientReviewTemplateStore,
} from './client-review-template.store';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { uniq, uniqBy, prop } from 'ramda';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';

@Injectable()
export class ClientReviewTemplateService {
	snapshot = this.query.getValue();
	showInvalid$ = new BehaviorSubject<boolean>(null);
	dataIsResolved$ = new BehaviorSubject<boolean>(false);

	// Dropdown values
	SCR$ = this.dropdownValueQuery.orderedChoices$('SCR');
	PCE$ = this.dropdownValueQuery.orderedChoices$('PCE');
	SCTT$ = this.dropdownValueQuery.orderedChoices$('SCTT');
	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');
	LRPR$ = this.dropdownValueQuery.orderedChoices$('LRPR');
	APCRTVD$ = this.dropdownValueQuery.orderedChoices$('APCRTVD');
	APCRTG$ = this.dropdownValueQuery.orderedChoices$('APCRTG');
	APCRTBP$ = this.dropdownValueQuery.orderedChoices$('APCRTBP');
	APCRTYN$ = this.dropdownValueQuery.orderedChoices$('APCRTYN');
	APCRTYNNA$ = this.dropdownValueQuery.orderedChoices$('APCRTYNNA');
	APCRTF$ = this.dropdownValueQuery.orderedChoices$('APCRTF');
	APCRTME$ = this.dropdownValueQuery.orderedChoices$('APCRTME');
	APCRTB$ = this.dropdownValueQuery.orderedChoices$('APCRTB');
	APCRTFM$ = this.dropdownValueQuery.orderedChoices$('APCRTFM');
	APCRTKC$ = this.dropdownValueQuery.orderedChoices$('APCRTKC');
	APCRTTC$ = this.dropdownValueQuery.orderedChoices$('APCRTTC');
	APCRTI$ = this.dropdownValueQuery.orderedChoices$('APCRTI');
	APCRTIST$ = this.dropdownValueQuery.orderedChoices$('APCRTIST');
	APCRTIT$ = this.dropdownValueQuery.orderedChoices$('APCRTIT');
	APCRTTM$ = this.dropdownValueQuery.orderedChoices$('APCRTTM');
	SU$ = this.dropdownValueQuery.orderedChoices$('SU');
	SV1$ = this.dropdownValueQuery.orderedChoices$('SV1');
	STI$ = this.dropdownValueQuery.orderedChoices$('STI');
	ST$ = this.dropdownValueQuery.orderedChoices$('ST');
	PCLT$ = this.dropdownValueQuery.orderedChoices$('PCLT');
	KP$ = this.dropdownValueQuery.orderedChoices$('KP');
	MP$ = this.dropdownValueQuery.orderedChoices$('MP');
	MLT$ = this.dropdownValueQuery.orderedChoices$('MLT');
	MLP$ = this.dropdownValueQuery.orderedChoices$('MLP');
	APCRTRP$ = this.dropdownValueQuery.orderedChoices$('APCRTRP');
	APCRTL$ = this.dropdownValueQuery.orderedChoices$('APCRTL');
	APCRTA$ = this.dropdownValueQuery.orderedChoices$('APCRTA');
	KFT$ = this.dropdownValueQuery.orderedChoices$('KFT');

	APCRTCLC$ = this.dropdownValueQuery.orderedChoices$('APCRTCLC');
	APCRTVP$ = this.dropdownValueQuery.orderedChoices$('APCRTVP');
	APCRTKPY$ = this.dropdownValueQuery.orderedChoices$('APCRTKPY');
	APCRTSI$ = this.dropdownValueQuery.orderedChoices$('APCRTSI');
	FGI$ = this.dropdownValueQuery.orderedChoices$('FGI');

	APCRTGL$ = this.dropdownValueQuery.orderedChoices$('APCRTGL');
	APCRTDP$ = this.dropdownValueQuery.orderedChoices$('APCRTDP');

	APCRTE$ = this.dropdownValueQuery.orderedChoices$('APCRTE');
	APCRTIID$ = this.dropdownValueQuery.orderedChoices$('APCRTIID');
	APCRTESI$ = this.dropdownValueQuery.orderedChoices$('APCRTESI');
	APCRTDIP$ = this.dropdownValueQuery.orderedChoices$('APCRTDIP');
	APCRTR$ = this.dropdownValueQuery.orderedChoices$('APCRTR');
	APCRTPWP$ = this.dropdownValueQuery.orderedChoices$('APCRTPWP');

	APCRTTRC$ = this.dropdownValueQuery.orderedChoices$('APCRTTRC');
	APCRTEC$ = this.dropdownValueQuery.orderedChoices$('APCRTEC');

	APCRTIP$ = this.dropdownValueQuery.orderedChoices$('APCRTIP');
	APCRTP$ = this.dropdownValueQuery.orderedChoices$('APCRTP');
	APCRTLTCR$ = this.dropdownValueQuery.orderedChoices$('APCRTLTCR');
	APCRTHO$ = this.dropdownValueQuery.orderedChoices$('APCRTHO');
	APCRTWO$ = this.dropdownValueQuery.orderedChoices$('APCRTWO');

	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');

	APCRTCC$ = this.dropdownValueQuery.orderedChoices$('APCRTCC');
	APCRTNE$ = this.dropdownValueQuery.orderedChoices$('APCRTNE');
	APCRTFSCCO$ = this.dropdownValueQuery.orderedChoices$('APCRTFSCCO');

	primaryClient$ = this.query.primaryClient$;
	primaryClient = this.snapshot.primaryClient;
	adviceProcessId$ = this.query.adviceProcessId$;
	adviceProcessId = this.snapshot.adviceProcessId;

	isCompany$ = this.query.isCompany$;
	people$ = this.query.people$;
	dependents$ = this.query.dependents$;
	trusts$ = this.query.trusts$;
	company$ = this.query.company$;
	peopleEntities$ = this.query.peopleEntities$;

	currentInsurances$ = this.query.currentInsurances$;
	isLoadingCurrentInsurance$ = this.query.isLoadingCurrentInsurance$;
	lifeAssured$ = this.query.lifeAssured$;

	policyOwners$ = this.query.policyOwnersWithCRT$;
	showCurrentInsuranceModal$: BehaviorSubject<boolean> =
		new BehaviorSubject<any>(null);

	staffId$ = this.query
		.select()
		.pipe(map((loat) => loat?.adviceProcess?.adviser));

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {}

	clear() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	setAdviceProcessId(id: number) {
		applyTransaction(() => {
			this.store.setAdviceProcessId(id);
		});
	}

	setIsCompany(isCompany: boolean) {
		applyTransaction(() => {
			this.store.setIsCompany(isCompany);
		});
	}

	getContacts(customerId: number): Observable<ClientReviewTemplateContacts> {
		return this.customerService.GetContacts(customerId).pipe(
			tap((result) => {
				const sci = result?.SCI
					? // @ts-ignore-next
					  (result?.SCI?.filter((sci) => +sci.IsActive === 1).map(
							objectUtil.mapPascalCaseToCamelCase
					  ) as SecondaryClientState[])
					: [];

				this.store.setSecondaryClients(sci);

				const allSCI = result.SCI
					? (result.SCI.map(
							objectUtil.mapPascalCaseToCamelCase
					  ) as SecondaryClientState[])
					: [];
				this.store.setAllSecondaryClients(allSCI);

				const primaryClient = objectUtil.mapPascalCaseToCamelCase(
					result.PCI
				) as PrimaryClientState | PrimaryCustomerCompanyState;
				this.store.setPrimaryClient(primaryClient);

				const linkCustomer = result?.LC
					? (result?.LC
							// @ts-ignore-next
							?.filter((lci) => +lci.IsActive === 1 || +lci.IsActive === 3)
							.map(objectUtil.mapPascalCaseToCamelCase) as LinkedContactState[])
					: [];
				this.store.setLinkedContacts(linkCustomer);

				const secondaryTrusts = result?.SCT
					? (result.SCT?.map(
							objectUtil.mapPascalCaseToCamelCase
					  ) as SecondaryTrustState[])
					: [];
				this.store.setSecondaryTrusts(secondaryTrusts);

				const secondaryCompanies = result.PCC
					? (result.PCC?.map(
							objectUtil.mapPascalCaseToCamelCase
					  ) as SecondaryBusinessState[])
					: [];
				this.store.setSecondaryCompanies(secondaryCompanies);
			})
		);
	}

	getPrimaryClient(customerId) {
		return this.customerService.GetPrimaryClient(customerId).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = objectUtil.mapPascalCaseToCamelCase(x) as
						| PrimaryClientState
						| PrimaryCustomerCompanyState;
					this.store.setPrimaryClient(state);
				})
			),
			catchError(() => EMPTY)
		);
	}

	getSecondaryClients(customerId) {
		this.store.setLoading(true);
		return this.customerService
			.GetSecondaryClientsByPrimaryClient(customerId)
			.pipe(
				map((x) => x?.filter((y) => +y.IsActive === 1 || +y.IsActive === 3)),
				tap((x) =>
					applyTransaction(() => {
						const sci = x
							? (x
									?.filter((sci) => +sci.IsActive === 1)
									.map(
										objectUtil.mapPascalCaseToCamelCase
									) as SecondaryClientState[])
							: [];

						this.store.setSecondaryClients(sci);

						const allSCI = x
							? (x.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryClientState[])
							: [];
						this.store.setAllSecondaryClients(allSCI);
					})
				),
				finalize(() => this.store.setLoading(false)),
				catchError(() => EMPTY)
			);
	}

	getSecondaryTrusts(customerId) {
		return this.customerService
			.GetSecondaryTrustByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryTrustState[])
							: [];
						this.store.setSecondaryTrusts(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	getSecondaryCompanies(customerId) {
		return this.customerService
			.GetSecondaryBusinessesByPrimaryClient(customerId)
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const state = x
							? (x?.map(
									objectUtil.mapPascalCaseToCamelCase
							  ) as SecondaryBusinessState[])
							: [];
						this.store.setSecondaryCompanies(state);
					})
				),
				catchError(() => EMPTY)
			);
	}

	saveDocument(req: DocumentUpload): Observable<number> {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}

	updateDocument(req: DocumentUpload) {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}

	updateSignature(req: DocumentUploadState | DocumentUpload, id: number) {
		return this.customerService
			.UpdateDocument(req, id)
			.pipe(catchError(() => EMPTY));
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.store.setHasFormChanges(hasFormChanges);
	}

	/**
	 * Get Client Documents
	 * @param primaryClientId primaryClientId
	 */
	getClientDocuments(primaryClientId: number): Observable<DocumentGroupState> {
		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetDocumentsClientId(x)),
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => EMPTY)
		);
	}

	setShowInvalid(invalidState) {
		this.showInvalid$.next(invalidState);
	}

	/**
	 * Get the list of available choices for people both CRM and CRT
	 * @param ownersIds Value of specific input field selected
	 * @param policyOwners available choices
	 * @returns list of available choices with or without transferred & deceased client/s & linked contacts
	 */
	getOwnerChoices = (
		ownersIds: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => {
		const owners = (ownersIds || [])?.map((x) => +x);
		const allSci = (this.query.getValue().allSecondaryClients || [])?.map(
			(x) => ({
				...x,
				id: +x?.customerID,
			})
		);
		const allLinked = (this.query.getValue().linkedContacts || [])?.map(
			(x) => ({
				...x,
				isLinked: true,
				id: x?.linkedFromPrimaryCustomer
					? +x?.relatedCustomerId
					: +x?.customerId,
			})
		);
		const desceasedSciIds = allSci
			?.filter((x) => x?.contactMethod === 'Deceased')
			?.map((x) => +x?.id);
		const deceasedLinkedIds = allLinked
			?.filter((x) => x?.contactMethod === 'Deceased')
			?.map((x) => +x?.id);
		const deceasedIds = uniq([...desceasedSciIds, ...deceasedLinkedIds]);
		const newPolicyOwnersNoDeceased = (policyOwners || [])
			?.filter((x) => !deceasedIds?.includes(+x?.value))
			?.sort((a, b) => a.display?.localeCompare(b.display));

		if (owners?.length > 0) {
			const transferredDeceased = owners?.filter((x) => {
				const isTransferred =
					policyOwners?.filter((y) => +y?.value === +x)?.length === 0;
				const isDeceased = deceasedIds?.includes(+x);
				return isTransferred || isDeceased;
			});

			if (transferredDeceased?.length > 0) {
				const policyOwnersTransferredDeceased = [
					...allSci,
					...allLinked,
				]?.filter((x) => +transferredDeceased.some((t) => +t === +x.id)) as any;
				const policyOwnersTransferredDeceasedIds =
					policyOwnersTransferredDeceased?.map((x) => +x?.id);
				const potChoices =
					policyOwnersTransferredDeceased?.map((x) =>
						ViewDisplayValue.Map(
							`${x.id}`,
							`${x?.isLinked ? x?.name : x.firstName.concat(' ', x.lastName)}`
						)
					) || [];
				const newPolicyOwners =
					policyOwners
						?.filter((x) => {
							const isDeceased = deceasedIds?.includes(+x?.value);
							const isSelected = policyOwnersTransferredDeceasedIds?.includes(
								+x?.value
							);
							return isSelected ? false : !isDeceased;
						})
						?.map((x) => ({ ...x, value: x?.value?.toString() })) || [];
				const newList = uniqBy(prop('value'), [
					...newPolicyOwners,
					...potChoices,
				]);

				return newList?.sort((a, b) => a.display?.localeCompare(b.display));
			} else {
				const newList = uniqBy(prop('value'), newPolicyOwnersNoDeceased);
				return newList?.sort((a, b) => a.display?.localeCompare(b.display));
			}
		} else {
			const newList = uniqBy(prop('value'), newPolicyOwnersNoDeceased);
			return newList?.sort((a, b) => a.display?.localeCompare(b.display));
		}
	};

	getLinkedContacts(customerId) {
		this.store.setLoading(true);
		return this.customerService
			.GetLinkedContactsByPrimaryClient(customerId)
			.pipe(
				map((x) => x?.filter((y) => +y.IsActive === 1 || +y.IsActive === 3)),
				tap((x) =>
					applyTransaction(() => {
						const lci = x
							? (x
									?.filter((lci) => +lci.IsActive === 1)
									.map(
										objectUtil.mapPascalCaseToCamelCase
									) as LinkedContactState[])
							: [];

						this.store.setLinkedContacts(lci);
					})
				),
				finalize(() => this.store.setLoading(false)),
				catchError(() => EMPTY)
			);
	}
}
