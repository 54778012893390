import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MGTState, MGTStore } from './mgt.store';
import { shareReplay } from 'rxjs/operators';

/**
 * Query Mortgage Goal track widget data
 */
@Injectable()
export class MGTQuery extends Query<MGTState> {

  /**
   * Goal track data.
   */
  data$ = this.select(state => {
    return state.GoalTrack;
  }).pipe(
    shareReplay(1)
  );
  /**
   * Indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * Error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create Mortgage Goal Track query
   */
  constructor(protected store: MGTStore) {
    super(store);
  }
}
