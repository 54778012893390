<div class="modal-header">
    <h5 class="modal-title">
        {{ msg }}
    </h5>
</div>
<div class="modal-body">
    <div class="form-group m-2">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info tap-bg-primary" role="progressbar"
                [ngStyle]="progress" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                {{ percent }}%
            </div>
        </div>
    </div>
</div>