import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';

import { ReportRoutingModule } from './report-routing.module';
import { ReportComponent } from './report.component';
import { ReportFormComponent } from './report-form/report-form.component';
import { ReportService } from './state/report.service';
import { ReportStore } from './state/report.store';
import { ReportQuery } from './state/report.query';
import { AdviserActivityTableComponent } from './tables/adviser-activity-table/adviser-activity-table.component';
import { AdviserSummaryTableComponent } from './tables/adviser-summary-table/adviser-summary-table.component';
import { CcKpiTableComponent } from './tables/cc-kpi-table/cc-kpi-table.component';
import { ReportPageResolver } from './report.resolver';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReportRoutingModule
  ],
  declarations: [ReportComponent, ReportFormComponent, AdviserActivityTableComponent, AdviserSummaryTableComponent, CcKpiTableComponent],
  providers: [
    ReportPageResolver,
    ReportService,
    ReportStore,
    ReportQuery]
})
export class ReportModule { }
