import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RiskAnalysisTPDState } from 'src/app/shared/models/client-review-template/risk-analysis/tpd/tpd.model';

export interface TpdState extends EntityState<RiskAnalysisTPDState> {
  activeParentCRTId: number;
  formValue: RiskAnalysisTPDState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tpd', idKey: 'parentCRTId' })
export class TpdStore extends EntityStore<TpdState> {
  constructor() {
    super({
      activeParentCRTId: null,
      formValue: null,
    });
  }

  setActiveParentCRTId(parenCRTId) {
    this.update((state) => ({ activeParentCRTId: parenCRTId }));
  }

  setFormValue(formValue) {
    this.update((state) => ({ formValue }));
  }
}
