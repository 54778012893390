import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { complement, either, isNil, isEmpty } from 'ramda';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { GoalsMapper } from '../../../../../../shared/models/client-review-template/risk-analysis/goals/goals.mapper';
import { DropdownGoalsState } from '../../../../../../shared/models/client-review-template/risk-analysis/goals/goals.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { GoalsService } from '../../../states/risk-analysis/goals/goals.service';

declare var $: any;

@Component({
	selector: 'app-long-term-goals',
	templateUrl: './long-term-goals.component.html',
	styleUrls: ['./long-term-goals.component.scss'],
})
export class LongTermGoalsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
	@Input() longTermGoals$: Observable<DropdownGoalsState[]>;
	@Input() cRTId$: Observable<number>;
	@Input() APCRTGL: ViewDisplayValue[];
	@Input() isAdviceProcessEnded: boolean;
	@Input() isNew: boolean;

	elseMinus = true;
	submitted = false;
	isListLoading = true;
	form: UntypedFormGroup;
	isValid$ = this.goalsService.goals$.pipe(
		map(
			(x) =>
				(!!x?.shortTermGoals &&
					x?.shortTermGoals?.length > 0 &&
					x?.shortTermGoals?.some(
						(v) =>
							!!v.dropdown &&
							(v.dropdown !== 'Other' || (v.dropdown === 'Other' && !!v.value))
					)) ||
				(x?.longTermGoals?.length > 0 &&
					x?.longTermGoals?.some(
						(v) =>
							!!v.dropdown &&
							(v.dropdown !== 'Other' || (v.dropdown === 'Other' && !!v.value))
					))
		)
	);

	constructor(private fb: UntypedFormBuilder, private goalsService: GoalsService) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
	}

	get DetailsList() {
		return this.form.get('detailsList') as UntypedFormArray;
	}

	prepData() {
		this.longTermGoals$
			.pipe(
				filter((data) => !!data),
				tap((data) => {
					if (complement(either(isNil, isEmpty))(data)) {
						data?.map((item) => this.addItem(item));
					} else {
						this.addItem();
					}
					this.isListLoading = false;

					if(this.isAdviceProcessEnded) {
						this.form.disable();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	buildForm() {
		this.form = this.fb.group({
			detailsList: this.fb.array([]),
		});
	}

	addItem(data?: DropdownGoalsState, isAdd: boolean = false) {
		this.DetailsList.push(
			this.fb.group({
				dropdown: data?.dropdown || '',
				value: data?.value || '',
				isNew: isAdd,
			})
		);
	}

	addNewGoal() {
		this.addItem(null, true);
		this.onChange();
	}

	deleteGoal(index: number) {
		this.DetailsList.removeAt(index);
		this.onChange();
	}

	selectGoal(index: number) {
		this.DetailsList.controls[index].get('value').setValue('');
		this.onChange();
	}

	onChange() {
		const getData = GoalsMapper.mapGoalsToUpsert(
			this.form.getRawValue().detailsList
		);
		this.goalsService.updateGoalsState({
			longTermGoals: getData,
		});
	}

	onFocusOut(event: Event, item: UntypedFormGroup) {
		if (!(event.target as HTMLSelectElement).value) {
			item.get('dropdown').setValue('');
		}
	}

	collapseMore() {
		$('#collapse').toggle();
		this.elseMinus = false;
	}

	collapseLess() {
		$('#collapse').toggle();
		this.elseMinus = true;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
