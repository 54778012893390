import { TLStaffSecGroup } from './tl-staff-security-group.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TLStaffSecGroupsStore, SecGroupState } from './tl-staff-security-groups.store';

// Query list of tl staff security group
@Injectable()
export class TLStaffSecGroupsQuery extends QueryEntity<SecGroupState, TLStaffSecGroup> {
    constructor(protected store: TLStaffSecGroupsStore) {
        super(store);
    }
}
