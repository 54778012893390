import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-host',
  template: `
    <div class="field">

    </div>
    <div class="control">
      <ng-content select="control"></ng-content>
    </div>
    <div>
      <ng-content select="app-manual-loader-mini"></ng-content>
    </div>
    <div class="edit-controls d-flex flex-column">
      <ng-content select="edit-controls"></ng-content>
    </div>
  `,
  styleUrls: ['./host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostComponent {
  constructor() { }

}
