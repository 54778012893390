import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs/internal/observable/of';
import { concatMap, map, take, tap } from 'rxjs/operators';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { MergeTagsCrtSettingsService } from '../../merge-tags/state/merge-tags-crt-settings.service';
import { SoaSettingsMapper } from '../state/soa-settings.mapper';
import { SoaSettingsState } from '../state/soa-settings.model';
import { SoaSettingsService } from '../state/soa-settings.service';
import * as R from 'ramda';
import { EMPTY } from 'rxjs';

@Component({
	selector: 'app-merge-tags-wording',
	templateUrl: './merge-tags-wording.component.html'
})
export class MergeTagsWordingComponent implements OnInit {
	@Input() soaData: SoaSettingsState;

	isLoading = false;
	mergeTags: MergeTagState[];
	form: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder,
		private service: SoaSettingsService,
		private mtService: MergeTagsCrtSettingsService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepData();
	}

	get SoaRaiseComplaint() {
		return this.form.get('soaRaiseComplaint');
	}
	get SoaThingsToConsider() {
		return this.form.get('soaThingsToConsider');
	}
	get SoaTheNextStep() {
		return this.form.get('soaTheNextStep');
	}

	buildForm() {
		this.form = this.fb.group({
			soaRaiseComplaint: [null],
			soaThingsToConsider: [null],
			soaTheNextStep: [null],
		});
	}

	prepData() {
		of(this.soaData)
			.pipe(
				tap((x) => (this.mergeTags = x?.mergeTag || [])),
				map((x) => SoaSettingsMapper.mapSoaMergeTagWording(x?.mergeTag || [])),
				take(1)
			)
			.subscribe((data) => this.form.reset(data));
	}

	save() {
		this.isLoading = true;
		if(R.isNil(this.mergeTags) || R.isEmpty(this.mergeTags)) {
			this.isLoading = false;
			return;
		}
		of(this.form.getRawValue())
			.pipe(
				map((x) => SoaSettingsMapper.mapToUpsertMtWording(this.mergeTags, x)),
				tap((x) => {
					this.mergeTags = x;
					const data = SoaSettingsMapper.mapSoaMtWordingPreviewValues(x);
					this.mtService.updateMtState(data);
				}),
				concatMap((x) => {
					if(R.isNil(x) || R.isEmpty(x)) {
						return of(EMPTY);
					}
					else {
						return this.service.updateSoaSettings({ mergeTag: x });
					}
				}),
				take(1)
			)
			.subscribe(() => (this.isLoading = false));
	}
}
