import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import produce from 'immer';
import * as R from 'ramda';
import { FgInsuranceUiStore } from './fg-insurance-ui.store';
import { Row } from './fg-insurance.model';
import { FgInsuranceRequest } from '../fg-insurance-request.model';
import { Injectable } from '@angular/core';

interface ColumnWidth {
  metakey: string;
  width: number;
}

export interface FgInsuranceState extends EntityState<Row> {
  columns: string[];
  columnWidths: ColumnWidth[];
  count: number;
  totalPremium: number;
  isComplete: boolean;
  searchForm: FgInsuranceRequest;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fgInsurance', idKey: 'CustomerServiceID' })
export class FgInsuranceStore extends EntityStore<FgInsuranceState, Row> {

  constructor(public uiStore: FgInsuranceUiStore) {
    super({ columns: [], count: 0, totalPremium: 0, isComplete: false, columnWidths: [], searchForm: null });
  }

  setColumns(columns: string[]) {
    this.update(produce(x => { x.columns = columns; }));
  }

  setColumnWidths(columnWidths: ColumnWidth[]) {
    this.update(produce(x => {
      x.columnWidths = columnWidths;
    }));
  }

  setSearchForm(req: FgInsuranceRequest) {
    this.update(produce(x => {
      x.searchForm = req;
    }));
  }

  updateField(req: { CustomerServiceID: number | number[], MetaKey: string, MetaValue: string }) {
    this.update(req.CustomerServiceID, produce(draft => {
      const fields = R.omit(['CustomerID', 'CustomerServiceID', 'link', 'ClientNextActivityId',
        'UserNextActivityId', 'ActivityId', 'PolicyOwnersList'], draft);
      Object.values(fields).find(x => x.metakey === req.MetaKey).value = req.MetaValue;
      if (req.MetaKey === 'Status') {
        draft.StatusClock.value = '0 days';
      }

      if (req.MetaKey === 'Premium' && req.MetaValue === '0') {
        draft.API.value = '0.00';
      }

      if (req.MetaKey === 'Broker Fee' && req.MetaValue === '0') {
        draft.BrokerFee.value = '0.00';
      }

    }));
  }
}
