import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import produce from 'immer';
import { GetAdviceProcessMortgagePageViewDisplay } from '../../../../../../shared/models/advice-process/advice-process.model';
import { CRTNoteState } from '../../../../../../shared/models/client-review-template/note/crt-note.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';

const CRTMortgageNoteTypes = GetAdviceProcessMortgagePageViewDisplay();

export interface CRTMortgageNotesState extends EntityState<CRTNoteState> {
  activeType: string;
  crtNoteTypes: ViewDisplayValue[];
}

@Injectable()
@StoreConfig({ name: 'CrtNotes', idKey: 'cRTNotesId' })
export class CrtMortgageNoteStore extends EntityStore<CRTMortgageNotesState, CRTNoteState> {
  constructor() {
    super({
      activeType: '',
      crtNoteTypes: CRTMortgageNoteTypes,
    });
  }

  setActiveType(type?: string) {
    this.update(
      produce((x) => {
        x.activeType = type ?? '';
      })
    );
  }
}
