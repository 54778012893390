import { TableColumn } from '@swimlane/ngx-datatable';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { of } from 'rxjs';
import { util } from 'src/app/util/util';

export type Metakey =
	| 'Email Type'
	| 'Is Active'
	| 'Email Subject'
	| 'Interval Date Start'
	| 'Interval Time Start'
	| 'Interval Date End'
	| 'Interval Time End'
	| 'Development Triggers'
	| 'From Email'
	| 'From Name'
	| 'CC'
	| 'BCC'
	| 'Template Id'
	| 'Attachment'
	| 'Unsubscribe Group Id';

export const attachments = [
	{
		display: 'Business - Disclosure Document',
		value: 'BDID',
	},
	{
		display: 'Business - Declaration Document',
		value: 'BDED',
	},
	{
		display: 'Business - Scope of Service',
		value: 'BSOS',
	},

	{
		display: 'Business - Letter of Authority',
		value: 'BLOA',
	},

	{
		display: 'Business - Business Overview',
		value: 'BBUO',
	},

	{
		display: 'Adviser - Disclosure Document',
		value: 'ADID',
	},

	{
		display: 'Adviser - Declaration Document',
		value: 'ADED',
	},
	{
		display: 'Adviser - Scope of Service',
		value: 'ASOS',
	},

	{
		display: 'Adviser - Letter of Authority',
		value: 'ALOA',
	},

	{
		display: 'Adviser - Business Overview',
		value: 'ABUO',
	},
];

export const attachments$ = of(attachments);

export const interval$ = of([
	{
		display: 'days before',
		value: -1,
	},
	{
		display: 'same day',
		value: 0,
	},
	{
		display: 'days after',
		value: 1,
	},
]);

export type controlType = 'display' | 'money' | 'number' | 'long-display';
/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */

type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof any;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (field: any, choices?: ViewDisplayValue[]) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
};

export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'Email Type',
		prop: 'EmailType',
		name: 'Email Name',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold fixed-column fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Is Active',
		prop: 'IsActive',
		name: 'Is Active',
		width: 80,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Email Subject',
		prop: 'EmailSubject',
		name: 'Email Subject',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Interval Date Start',
		prop: 'IntervalDateStart',
		name: 'Interval',
		width: 400,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Interval Time Start',
		prop: 'IntervalTimeStart',
		name: 'Interval Time Start',
		width: 400,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Interval Date End',
		prop: 'IntervalDateEnd',
		name: 'Interval Date End',
		width: 400,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Interval Time End',
		prop: 'IntervalTimeEnd',
		name: 'Interval Time End',
		width: 400,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Development Triggers',
		prop: 'EmailCode',
		name: 'Development Triggers',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'From Email',
		prop: 'Sender',
		name: 'From Email',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'From Name',
		prop: 'FromName',
		name: 'From Name',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'CC',
		prop: 'CarbonCopy',
		name: 'CC',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'BCC',
		prop: 'BlindCarbonCopy',
		name: 'BCC',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
	{
		metakey: 'Template Id',
		prop: 'TemplateId',
		name: 'Template ID',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'long-display',
	},
	{
		metakey: 'Attachment',
		prop: 'Attachment',
		name: 'Attachment',
		width: 400,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'long-display',
		choices: attachments,
		choicesAsObject: util.createLookupFromList(
			c => c.value,
			c => c.display,
			attachments
		),
	},
	{
		metakey: 'Unsubscribe Group Id',
		prop: 'UnsubscribeGroupId',
		name: 'Unsubscribe Group ID',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'fixed-column-border',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
	},
];

/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 119,
};
