export const getTaxAcc = (income: number) => {
  const tax = +getTax(income);
  const acc = +getAcc(income);
  return tax + acc;
};

export const getTax = (income: number) => {
  if (income > 180000) {
    return (income - 180000) * 0.39 + 50320;
  } else if (income > 70000) {
    return (income - 70000) * 0.33 + 14020;
  } else if (income > 48000) {
    return (income - 48000) * 0.3 + 7420;
  } else if (income > 14000) {
    return (income - 14000) * 0.175 + 1470;
  } else {
    return income * 0.105;
  }
};

export const getAcc = (income: number) => {
  if (income > 128470) {
    return 1785.73;
  } else {
    return income * 0.0139;
  }
};
