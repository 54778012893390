import { Store, StoreConfig } from '@datorama/akita';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { ScopeOfServiceState } from '../scope-of-service-settings/state/scope-of-service.model';
import { DeclarationSettingState } from '../declaration-settings/declaration-template-settings/state/declaration-template-settings.model';
import { ScopeOfServiceEmailSettingsState } from '../scope-of-service-settings/state/scope-of-service-settings.model';
import { IntroSettingsState } from '../intro-settings/state/intro-settings.model';
import { DocumentListState } from '../document-list/state/document-list.model';
import { SoaSettingsState } from '../soa-settings/state/soa-settings.model';
import { ReviewApplicationSettingsState } from '../review-application-settings/state/review-application-settings.model';
import { DeclarationEmailSettingsState } from '../declaration-settings/declaration-email-settings/state/declaration-email-settings.model';
import { ClientFactFindSettingState } from '../declaration-settings/client-fact-find-template-settings/state/client-fact-find-template-settings.model';
import { ReviewApplicationEmailSettingsState } from '../review-application-settings/review-application-email-settings/state/review-application-email-settings.model';
import { MoatSoaEmailSettingsState } from '../soa-settings/moat-soa-email-settings/state/moat-soa-email-settings.model';
import { SosTemplateSettingsState } from '../scope-of-service-settings/sos-template-settings/state/sos-template-settings.model';
import { NoteSettingsState } from '../review-application-settings/note-settings/state/note-settings.model';
import { Injectable } from '@angular/core';
import { MortgageEndProcessEmailSettingsState } from '../mortgage-end-process/state/mortgage-end-process.model';

export interface MortgageSettingsState {
	introSettings: IntroSettingsState;
	declaration: DeclarationSettingState;
	statementOfAdvice: SoaSettingsState;
	scopeOfService: ScopeOfServiceState;
	scopeOfServiceEmailSettings: ScopeOfServiceEmailSettingsState;
	scopeOfServiceTemplateSettings: SosTemplateSettingsState;
	mergeTags: MergeTagState[];
	documentList: DocumentListState[];
	reviewApplicationSettings: ReviewApplicationSettingsState;
	declarationEmailSettings: DeclarationEmailSettingsState;
	soaEmailSettings: MoatSoaEmailSettingsState;
	clientFactFindSettings: ClientFactFindSettingState;
	reviewApplicationEmailSettings: ReviewApplicationEmailSettingsState;
	statementOfAdviceEmailSettings: MoatSoaEmailSettingsState;
	noteSettings: NoteSettingsState;
	endProcessEmailSettings: MortgageEndProcessEmailSettingsState;
}

export function createInitialState(): MortgageSettingsState {
	return {
		introSettings: null,
		declaration: null,
		statementOfAdvice: null,
		scopeOfService: null,
		scopeOfServiceEmailSettings: null,
		scopeOfServiceTemplateSettings: null,
		mergeTags: [],
		documentList: [],
		reviewApplicationSettings: null,
		declarationEmailSettings: null,
		clientFactFindSettings: null,
		reviewApplicationEmailSettings: null,
		soaEmailSettings: null,
		statementOfAdviceEmailSettings: null,
		noteSettings: null,
		endProcessEmailSettings: null
	};
}

@Injectable()
@StoreConfig({ name: 'moatSettings' })
export class MortgageSettingsStore extends Store<MortgageSettingsState> {
	constructor() {
		super(createInitialState());
	}

	setIntroSettings(data: IntroSettingsState) {
		this.update({ introSettings: data });
	}

	setScopeOfService(data: ScopeOfServiceState) {
		this.update({ scopeOfService: data });
	}

	setScopeOfServiceEmailSettings(data: ScopeOfServiceEmailSettingsState) {
		this.update({ scopeOfServiceEmailSettings: data });
	}

	setScopeOfServiceTemplateSettings(data: SosTemplateSettingsState) {
		this.update({ scopeOfServiceTemplateSettings: data });
	}

	setDeclarationSettings(data: DeclarationSettingState) {
		this.update({ declaration: data });
	}

	setDeclarationEmailSettings(data: DeclarationSettingState) {
		this.update({ declarationEmailSettings: data });
	}

	setClientFactFindSettings(data: ClientFactFindSettingState) {
		this.update({ clientFactFindSettings: data });
	}

	setSoaSettings(data: SoaSettingsState) {
		this.update({ statementOfAdvice: data });
	}

	setMergeTags(data: MergeTagState[]) {
		this.update({ mergeTags: data });
	}

	setDocumentList(data: DocumentListState[]) {
		this.update({ documentList: data });
	}

	setReviewApplicationSettings(data: ReviewApplicationSettingsState) {
		this.update({ reviewApplicationSettings: data });
	}

	setReviewApplicationEmailSettings(data: ReviewApplicationSettingsState) {
		this.update({ reviewApplicationEmailSettings: data });
	}

	setStatementOfAdviceEmailSettings(data: MoatSoaEmailSettingsState) {
		this.update({ statementOfAdviceEmailSettings: data });
	}

	setNoteSettings(data: NoteSettingsState) {
		this.update({ noteSettings: data });
	}

	setEndProcessEmailSettings(data: MortgageEndProcessEmailSettingsState) {
		this.update({ endProcessEmailSettings: data });
	}
}
