import {
	contentEditable,
	getContentWithMergeTags,
	removeHTMLContentWhiteSpaces,
} from '../../../../../../shared/converter/content-merge-tags';
import { MergeTagsMapper } from '../../merge-tags.mapper';
import { soaInsuranceMetaKey, soaMergeTags } from './insurance.merge-tag';
import {
	createCurrentInsurancePremium,
	createProposedInsurancePremium,
	createProposedInsuranceStructure,
} from './insurance.template';
import { previewData } from './insurance.preview-data';
import { MergeTagState } from '../../merge-tags.model';

export class InsuranceMergeTagsMapper {
	public static getTemplate(template: string, mergeTag: MergeTagState[]) {
		// Get template for each insurance merge tag
		const content = template?.replace(/\n|\t/g, '') || '';
		return (
			removeHTMLContentWhiteSpaces(
				getContentWithMergeTags(content, mergeTag)
			) ?? ''
		);
	}

	public static getSoaMergeTags(data) {
		const {
			proposedInsuranceStructure,
			proposedInsurancePremium,
			currentInsurancePremium,
			currentInsuranceNoCover,
		} = data;

		return (
			soaMergeTags?.map((i) => {
				let value = '';
				if (i?.metaKey === soaInsuranceMetaKey.proposedInsuranceStructure) {
					value = proposedInsuranceStructure;
				} else if (
					i?.metaKey === soaInsuranceMetaKey.proposedInsurancePremium
				) {
					value = proposedInsurancePremium;
				} else if (i?.metaKey === soaInsuranceMetaKey.currentInsurancePremium) {
					value = currentInsurancePremium;
				} else if (i?.metaKey === soaInsuranceMetaKey.currentInsuranceNoCover) {
					value = currentInsuranceNoCover;
				}
				return {
					...i,
					value,
				};
			}) ?? []
		);
	}

	// Main SOA Merge Tags
	public static revertInsuranceMergeTags(content: string) {
		// Convert back all dynamic tables of SOA insurance to Merge Tag Codes
		let newContent = content || '';

		// Proposed Insurance Structure
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			soaInsuranceMetaKey.proposedInsuranceStructure
		);

		// Proposed Insurance Premium
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			soaInsuranceMetaKey.proposedInsurancePremium
		);

		// Current Insurance Premium
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			soaInsuranceMetaKey.currentInsurancePremium
		);

		// Current No Insurance Cover
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			soaInsuranceMetaKey.currentInsuranceNoCover
		);

		return newContent;
	}

	/**
	 * CRT Settings Preview
	 * Generate Insurance Tables
	 */
	public static generateInsuranceTablePreviews() {
		return (
			soaMergeTags?.map((i) => {
				let secondaryValue = '';
				if (i?.metaKey === soaInsuranceMetaKey.proposedInsuranceStructure) {
					secondaryValue = this.proposedInsuranceStucturePreview();
				} else if (
					i?.metaKey === soaInsuranceMetaKey.proposedInsurancePremium
				) {
					secondaryValue = this.proposedInsurancePremiumPreview();
				} else if (i?.metaKey === soaInsuranceMetaKey.currentInsurancePremium) {
					secondaryValue = this.currentInsurancePremiumPreview();
				}
				return {
					...i,
					secondaryValue,
				};
			}) ?? []
		);
	}

	public static proposedInsuranceStucturePreview() {
		// Generate Proposed Insurance Structure Preview
		const insuranceTables = [];
		const BR = '<br />';

		previewData?.forEach((i) => {
			const td = createProposedInsuranceStructure.td(i?.lifeAssuredList);
			const table = createProposedInsuranceStructure.table(td);
			const lifeAssuredName = `<p><strong>${i?.lifeAssuredName}:</strong></p>`;

			insuranceTables.push(`${lifeAssuredName}${table}${BR}`);
		});

		return `<div ${contentEditable.false} id="${
			soaInsuranceMetaKey.proposedInsuranceStructure
		}">${insuranceTables?.join('').toString()}</div>`;
	}

	public static proposedInsurancePremiumPreview() {
		// Generate Proposed Insurance Premium Preview
		const insuranceTables = [];
		const BR = '<br />';

		previewData?.forEach((i) => {
			const td = createProposedInsurancePremium.td(i?.lifeAssuredList);
			const table = createProposedInsurancePremium.table(td);
			const lifeAssuredName = `<p><strong>${i?.lifeAssuredName}:</strong></p>`;

			insuranceTables.push(`${lifeAssuredName}${table}${BR}`);
		});

		const totalPremium = createProposedInsurancePremium.totalTable({
			policyFee: '$123.00',
			totalPremium: '$123.00',
			paymentFrequency: 'Monthly',
		});

		return `<div ${contentEditable.false} id="${
			soaInsuranceMetaKey.proposedInsurancePremium
		}">${insuranceTables?.join('').toString()}${totalPremium}</div>`;
	}

	public static currentInsurancePremiumPreview() {
		// Generate Current Insurance Premium Preview
		const insuranceTables = [];
		const BR = '<br />';

		previewData?.forEach((i) => {
			const td = createCurrentInsurancePremium.td(i?.lifeAssuredList);
			const table = createCurrentInsurancePremium.table(td);
			const lifeAssuredName = `<p><strong>${i?.lifeAssuredName}:</strong></p>`;

			insuranceTables.push(`${lifeAssuredName}${table}${BR}`);
		});

		const totalPremium = createCurrentInsurancePremium.totalTable({
			policyFee: '$123.00',
			totalPremium: '$123.00',
			paymentFrequency: 'Monthly',
		});

		return `<div ${contentEditable.false} id="${
			soaInsuranceMetaKey.currentInsurancePremium
		}">${insuranceTables?.join('').toString()}${totalPremium}</div>`;
	}
}
