<app-secondary-base-widget
	headerText="E.B. Conversion"
	[widgetCode]="widgetCode"
	class="eb-conversion"
>
	<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
	<app-date-range
		ngProjectAs="input"
		[formControl]="dateControl"
	></app-date-range>

	<!-- L&R Conversion -->
	<div *ngIf="!(isLoading$ | async)" class="eb-conversion-widget">
		<div class="form-row">
			<div class="col-12 col-lg-8">
				<label class="mt-2 conversion-name">Life & Risk</label>
			</div>
			<div class="d-none d-lg-block col-lg-4 text-right pr-3">
				<label class="mt-2 conversion-name">Leads</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart
					[barData]="[(ebConversion$ | async)?.Conversion|| 0]"
					[targetValue]="(ebConversion$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(ebConversion$ | async)?.Activities || 0
				}}</span>
			</div>
		</div>

		<!-- KiwiSaver Conversion -->
		<div class="form-row">
			<div class="col-12">
				<label class="mt-4 conversion-name">KiwiSaver</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart
					[barData]="[(kiwisaverConversion$ | async)?.Conversion || 0]"
					[targetValue]="(kiwisaverConversion$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(kiwisaverConversion$ | async)?.Activities || 0
				}}</span>
			</div>
		</div>

		<!-- Blanket Conversion -->
		<div class="form-row">
			<div class="col-12">
				<label class="mt-4 conversion-name">Blanket</label>
			</div>
		</div>
		<div class="form-row bar-chart pb-2 pt-1">
			<div class="col-11">
				<app-horizontal-bar-chart
					[barData]="[(blanketConversion$ | async)?.Conversion || 0]"
					[targetValue]="(blanketConversion$ | async)?.Target || 0"
					[isPercentBar]="true"
				></app-horizontal-bar-chart>
			</div>
			<div class="col-1 p-0">
				<span class="conversion-percent">{{
					(blanketConversion$ | async)?.Activities || 0
				}}</span>
			</div>
		</div>
	</div>
</app-secondary-base-widget>
