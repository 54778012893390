import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	MortgageSettingsState,
	MortgageSettingsStore,
} from './mortgage-settings.store';

@Injectable()
export class MortgageSettingsQuery extends Query<MortgageSettingsState> {
	/**
	 * @returns observable of Scope of Service Settings
	 */
	scopeOfService$ = this.select((state) => state.scopeOfService);
	scopeOfServiceEmailSettings$ = this.select(
		(state) => state.scopeOfServiceEmailSettings
	);
	scopeOfServiceTemplateSettings$ = this.select(
		(state) => state.scopeOfServiceTemplateSettings
	);

	/**
	 * @returns observable of Declaration
	 */
	declaration$ = this.select((state) => state.declaration);

	/**
	 * @returns observable of Declaration Email
	 */
	declarationEmail$ = this.select((state) => state.declarationEmailSettings);

	/**
	 * @returns observable of Record of Advice > Email Settings
	 */
	soaEmail$ = this.select((state) => state.soaEmailSettings);

	/**
	 * @returns observable of Client Fact Find Settings
	 */
	clientFactFindSettings$ = this.select(
		(state) => state.clientFactFindSettings
	);

	/**
	 * @returns observable of Statement of Advice
	 */
	statementOfAdvice$ = this.select((state) => state.statementOfAdvice);
	/**
	 * @returns observable of Statement of Advice
	 */
	statementOfAdiceEmailSettings$ = this.select(
		(state) => state.statementOfAdviceEmailSettings
	);

	/**
	 * @returns observable of Merge Tags
	 */
	mergeTags$ = this.select((state) => state.mergeTags);

	introSettings$ = this.select((state) => state.introSettings);
	documentList$ = this.select((state) => state.documentList);

	/**
	 * @returns observable of Review Application
	 */
	reviewApplicationSettings$ = this.select(
		(state) => state.reviewApplicationSettings
	);

	/**
	 * @returns observable of Declaration Email
	 */
	reviewApplicationEmail$ = this.select(
		(state) => state.reviewApplicationEmailSettings
	);
	/**
	 * @returns observable of Note Settings
	 */
	noteSettings$ = this.select((state) => state.noteSettings);
	/**
	 * @returns observable of End Process Email Settings
	 */
	endProcessEmailSettings$ = this.select(
		(state) => state.endProcessEmailSettings
	);

	constructor(protected store: MortgageSettingsStore) {
		super(store);
	}
}
