<div class="row tap-container soa-settings pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Document List">
					<app-soa-document-list> </app-soa-document-list>
				</tab>
			</ng-container>

			<ng-container *ngIf="['SO', 'SM']?.includes(userSecurityGroup$ | async)">
				<tab heading="Statement of Advice - Template">
					<app-soa-document-template
						[soaData]="data"
						[template]="template"
						[mergeTags$]="mergeTags$"
					>
					</app-soa-document-template>
				</tab>
			</ng-container>
			<ng-container
				*ngIf="['SO', 'BO', 'SM']?.includes(userSecurityGroup$ | async)"
			>
				<tab heading="Merge Tags Wordings">
					<app-merge-tags-wording [soaData]="data"></app-merge-tags-wording>
				</tab>
			</ng-container>
			<ng-container *ngIf="showEmailSettings$ | async">
				<tab heading="Email Settings" *oatFeature="oatFeatures.LOATV2">
					<app-soa-email-settings
						[emailSettings]="emailSettings$ | async"
						[mergeTags$]="mergeTags$"
					>
					</app-soa-email-settings>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
