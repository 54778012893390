import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  DocumentModelState,
  DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from '../../../../core/base/api.service';
import { CrtSettingsQuery } from '../../state/crt-settings.query';
import { CrtSettingsStore } from '../../state/crt-settings.store';
import { FinalStructureState } from './final-structure-settings.model';

@Injectable()
export class FinalStructureSettingsService {
  finalStructure$ = this.query.finalStructure$;

  constructor(
    private api: ApiService,
    private store: CrtSettingsStore,
    private query: CrtSettingsQuery
  ) {}

  getFinalStructure(data: number, settingsCode: string) {
    const endpoint = `crt/settings/${data}/${settingsCode}`;
    return this.api.get<FinalStructureState>(endpoint).pipe(
      tap((res) =>
        applyTransaction(() => {
          const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
          this.store.setFinalStructure(state);
        })
      ),
      catchError(() => of(undefined))
    );
  }

  updateFinalStructure(data: FinalStructureState) {
    const newValue = {
      ...this.query.getValue().finalStructure,
      ...data,
    };
    const endpoint = `crt/settings/${newValue.referenceId}`;
    const body = objectUtil.mapCamelCaseToPascalCase(newValue);
    return this.api.put<FinalStructureState>(endpoint, body).pipe(
      tap(() =>
        applyTransaction(() => {
          this.store.setFinalStructure(newValue);
        })
      )
    );
  }

  newFileUpload(req: DocumentUploadState) {
    const endpoint = 'documents';
    return this.api.post3<any>(endpoint, req);
  }

  updateFileUpload(req: DocumentUploadState) {
    const endpoint = `documents/${req.documentId}/document-link`;
    const body = objectUtil.mapCamelCaseToPascalCase(req);
    return this.api.put<DocumentUploadState>(endpoint, body);
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`);
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' });
  }
}
