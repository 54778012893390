import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, iif, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../../../core/base/api.service';
import { SoaSettingsState } from '../../../../../../../modules/mortgage-settings/soa-settings/state/soa-settings.model';
import { SettingsTypes } from '../../../../../../../modules/mortgage-settings/state/mortgage-settings.model';
import {
	DocumentModelState,
	DocumentUploadState
} from '../../../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../../../util/util';
import { CrtMortgageStore } from '../../../state/crt-mortgage.store';
import { MergeTagsService } from '../../../state/merge-tags/merge-tags.service';

export const soaPdfOptions = {
	FileName: 'STATEMENT-OF-ADVICE',
	DPI: '120',
	EnableIntelligentShrinking: 'false',
	MinimumFontSize: '9',
	LoadZoomFactor: '1.22',
	LoadJSDelay: '1500',
};

@Injectable({ providedIn: 'root' })
export class SoaService {
	constructor(
		private api: ApiService,
		private moatStore: CrtMortgageStore,
		private mtService: MergeTagsService,
	) {}

	getSoaSettings() {
		const endpoint = `crt/settings/0/${SettingsTypes.MOATROA}`;
		return this.api.get<SoaSettingsState>(endpoint).pipe(
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			tap((x) =>
				applyTransaction(() => {
					// Add SOA Settings to MOAT store
					this.moatStore.setSoaSettings(x);

					// Add SOA merge tag wordings from settings to Merge tag store
					const mergeTag = x?.mergeTag?.map((i) => ({
						...i,
						secondaryValue:
							i?.secondaryValue?.replace(/\n|\t/g, '<br>') || '',
						value: i?.value?.replace(/\n|\t/g, '<br>') || '',
					}));
					this.mtService.updateMtState(mergeTag || []);
				})
			),
			catchError(() => of(null))
		);
	}

	getDocumentByID(documentId: number) {
		return this.getDocumentFile(documentId).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			concatMap((x) =>
				iif(
					() => x?.documentLink,
					this.getDocumentFromURL(x?.documentLink),
					of(null)
				)
			),
			catchError(() => of(null))
		);
	}

	getDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`).pipe(
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			catchError(() => EMPTY)
		);
	}

	getDocumentFromURL(url: string) {
		const dateTime = new Date().getTime();
		url = `${url}?${dateTime}`;

		return this.api
			.getExternalResource(url, { responseType: 'text' })
			.pipe(catchError(() => EMPTY));
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

}
