import { Component, OnInit, ViewChild } from '@angular/core';

import { sum, isNil } from 'ramda';
import { combineLatest, of } from 'rxjs';
// import { iif } from 'rxjs/internal/observable/iif';
import {
	filter,
	finalize,
	map,
	switchMap,
	take,
	withLatestFrom,
	mergeMap,
} from 'rxjs/operators';
import {
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	MortgageAdviceProcessPageIds,
	MortgageAdviceProcessPageNames,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { numUtil } from 'src/app/util/util';
import { convertToAnnual } from '../../../_shared/calculations/annual-conversion';
import { Frequency } from '../../../_shared/calculations/monthly-conversion';
import { CrtBudgetFormComponent } from '../../../_shared/components/crt-budget-form/crt-budget-form.component';
import { IncomeSourceMapper } from '../../../_shared/mapper/income.mapper';
import { CrtMortgageQuery } from '../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../state/crt-mortgage.service';
import { MortgageAdviceProcessService } from '../../state/mortgage-adviceprocess/mortgage-advice-process.service';
import { LiabilityQuery } from '../assets-and-liabilities/state/liability/liability.query';
import { MortgageQuery } from '../assets-and-liabilities/state/mortgage/mortgage.query';
import { PropertyQuery } from '../assets-and-liabilities/state/property/property.query';
import { IncomeQuery } from '../income/state/income.query';
import { PeopleEntitiesQuery } from '../people-entities/state/people-entities.query';
import { ExpenseService } from './state/expenses.service';
import { ExpensesMapper } from './state/expenses.mapper';

import { BaseCrtMortgageComponent } from '../../base-crt-mortgage.component';

@Component({
	selector: 'app-expenses',
	templateUrl: './expenses.component.html',
	styleUrls: ['./expenses.component.scss'],
})
export class ExpensesComponent implements OnInit, BaseCrtMortgageComponent {
	pageHeaders = MortgageAdviceProcessPageNames;
	pageIds = MortgageAdviceProcessPageIds;
	monthlyExpense$ = this.service.expenses$;
	people$ = this.peopleQuery.people$;
	properties$ = this.propertyQuery.properties$;
	factFindComputation$ = this.incomeQuery.factFindComputation$;
	APCRTF$ = this.moatService.APCRTF$;
	APCRTYN$ = this.moatService.APCRTYN$;
	sidebar = this.moatQuery.getValue().sidebars;

	mortgagePayments$ = this.mortgageQuery.mortgages$.pipe(
		withLatestFrom(this.mortgageQuery.isLoading$),
		filter(([data, isLoading]) => !!data && !isLoading),
		map(([x]) => {
			const totalInAnnual =
				sum(
					x?.map((m) =>
						convertToAnnual(m.loanRepayment, m.mortgageFrequency as Frequency)
					)
				) || 0;
			return +numUtil.formatToNumCurrency(totalInAnnual);
		})
	);

	personalDebtValue$ = this.liabilityQuery.liabilities$?.pipe(
		withLatestFrom(this.liabilityQuery.isLoading$),
		filter(([data, isLoading]) => !!data && !isLoading),
		map(([x]) => {
			const totalInAnnual =
				sum(
					x?.map((m) =>
						convertToAnnual(m.loanRepayment, m.mortgageFrequency as Frequency)
					)
				) || 0;
			return +numUtil.formatToNumCurrency(totalInAnnual);
		})
	);

	ownerOccupiedRates$ = this.propertyQuery.properties$.pipe(
		withLatestFrom(this.propertyQuery.isLoading$),
		filter(([data, isLoading]) => !!data && !isLoading),
		map(([x]) => {
			const totalInAnnual =
				sum(
					x
						?.filter((property) => property.propertyUse === 'Owner Occupied')
						.map((p) => convertToAnnual(p.rates, p.ratesFrequency as Frequency))
				) || 0;
			return +numUtil.formatToNumCurrency(totalInAnnual);
		})
	);

	investmentRates$ = this.propertyQuery.properties$.pipe(
		withLatestFrom(this.propertyQuery.isLoading$),
		filter(([data, isLoading]) => !!data && !isLoading),
		map(([x]) => {
			const totalInAnnual =
				sum(
					x
						?.filter((property) => property.propertyUse === 'Investment')
						.map((p) => convertToAnnual(p.rates, p.ratesFrequency as Frequency))
				) || 0;
			return +numUtil.formatToNumCurrency(totalInAnnual);
		})
	);

	peopleList$ = combineLatest([
		this.peopleQuery.peopleFromCRMAndCRTExceptChild$,
		this.people$,
	]).pipe(
		filter(([x, y]) => !!x && !!y),
		map(([crmPeople, crtPeople]) => [
			...IncomeSourceMapper.mapCRMPeopleToDd(crmPeople),
			...IncomeSourceMapper.mapCRTPeopleToDd(crtPeople),
		])
	);

	@ViewChild('expenses') expensesForm: CrtBudgetFormComponent;

	constructor(
		private service: ExpenseService,
		private peopleQuery: PeopleEntitiesQuery,
		private propertyQuery: PropertyQuery,
		private incomeQuery: IncomeQuery,
		private moatService: CrtMortgageService,
		private mortgageQuery: MortgageQuery,
		private liabilityQuery: LiabilityQuery,
		private moatQuery: CrtMortgageQuery,
		private moatAdviceProcessService: MortgageAdviceProcessService
	) {}

	ngOnInit(): void {
		this.moatAdviceProcessService
			.updateMortApPageStarted(AdviceProcessPageCodes.Expenses)
			.pipe(take(1))
			.subscribe();
	}

	addNewMonthlyExpense$ = (model) =>
		of(model).pipe(
			map((x) => ExpensesMapper.mapToUpsert(x)),
			mergeMap((x) => this.service.addMonthlyExpense(x)),
			finalize(() => {
				this.moatService.setHasFormChanges(false);
			})
		);

	updateMonthlyExpense$ = (model) =>
		of(model).pipe(
			map((x) => ExpensesMapper.mapToUpsert(x)),
			mergeMap((x) => this.service.updateMonthlyExpense(x)),
			finalize(() => {
				this.moatService.setHasFormChanges(false);
			})
		);

	updateExpensesState$ = (model) =>
		this.service.updateMonthlyExpenseState(model);

	saveMonthlyExpense = () => {
		const processedData = this.expensesForm.saveMonthlyExpenses();
		return of(processedData).pipe(
			take(1),
			filter((x) => !!x),
			mergeMap((x) =>
				this.service
					.getMonthlyExpense(
						x.adviceProcessId,
						AdviceProcessSectionCodes.MonthlyExpense
					)
					.pipe(
						map((monthlyExpenses) => {
							return [x, monthlyExpenses];
						})
					)
			),
			switchMap(([data, monthlyExpenses]) => {
				if (!(!isNil(data?.isInvalid) && !data.isInvalid)) {
					return of(true).pipe();
				}
				if (monthlyExpenses?.length) {
					data.cRTId = monthlyExpenses[0].CRTId;
				}
				return Boolean(monthlyExpenses?.length)
					? this.updateMonthlyExpense$(data)
					: this.addNewMonthlyExpense$(data);
			}),
			finalize(() => {
				this.moatService.setHasFormChanges(false);
			})
		);
	};

	saveComponentFunction = () => this.saveMonthlyExpense();
}
