<div class="col m-auto w-75 p-2 pt-4">
	<div class="row">
		<div class="col mb-4">
			<div class="row">
				<div class="header">
					<h1>Questions</h1>
				</div>
			</div>
		</div>

		<ng-container *ngIf="(!riskProfileQAData || !riskProfileQAData.qA) || (riskProfileQAData.qA && riskProfileQAData.qA.length === 0); else questionTemplate">
			<div class="w-100 mb-4">			
				<app-question-form
					[question]="'Default Question'"
					[letter]="'A'"
					[answers]="[]"
					(saveEvent)="save($event)"
					[loading]="isLoading"
					(disableEvent)="disableForms($event)"
					[disabled]="isDisabled">
				</app-question-form>
			</div>
		</ng-container>
	
		<ng-template #questionTemplate>
			<div
				class="w-100 mb-4"
				*ngFor="let item of riskProfileQAData.qA; let i = index;"
			>
				<app-question-form
					[question]="item.question"
					[letter]="alphabet[i]"
					[answers]="item.answers"
					(saveEvent)="save($event)"
					[loading]="isLoading"
					(disableEvent)="disableForms($event)"
					[disabled]="isDisabled">
				</app-question-form>
			</div>
		</ng-template>
	</div>
	<div class="row">
		<div class="col-12 mb-4">
			<div class="row">
				<div class="header">
					<h1>Outcome</h1>
				</div>
			</div>
		</div>
		<div class="w-100 mb-4">
			<app-outcome-form
				[outcomes]="riskProfileOutcomesData.outcomes"
				(saveEvent)="saveOutcomes($event)"
				[loading]="isLoadingOutcomes"
				(disableEvent)="disableFormsOutcomes($event)"
				[disabled]="isDisabledOutcomes"
				[shouldUpdate]="shouldUpdate">
			</app-outcome-form>
		</div>
	</div>

	<div class="d-flex">
		<div class="row">
			<div class="col">
				<button
					type="button"
					class="btn btn-primary tap-bg-primary btn-save mr-1"
					(click)="saveUpdates()"
					[disabled]="isLoading || isDisabled || isLoadingOutcomes || isDisabledOutcomes"
				>
					Save
				</button>
				<button
					type="button"
					class="btn btn-light ml-1"
					(click)="cancelUpdates()"
				>
					Cancel
				</button>
			</div>
		</div>
	</div>
</div>
