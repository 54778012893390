import { Injectable } from '@angular/core';
import { ApiService, JsonResultStatus } from '@core/base/api.service';
import { UserSettingsStore } from '../state/user-settings/user-settings.store';
import { UserSettingsQuery } from '../state/user-settings/user-settings.query';
import { BlStaffSettingsModel } from '@shared/models/_general/bl-staff.model';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class StaffSettingsService {
	staffSettings$ = this.query.staffSettings$;

	constructor(
		private api: ApiService,
		private store: UserSettingsStore,
		protected query: UserSettingsQuery
	) {}

	getBlStaffSettings(id: number | string) {
		const endpoint = `staff/${id}/staff-settings/all`;
		return this.api.get<any>(endpoint).pipe(
			tap((data) => {
				applyTransaction(() => {
					this.store.setStaffSettings(data);
				});
			}),
			catchError(() => of(null))
		);
	}

	addBlStaffSettings(
		data: BlStaffSettingsModel,
		staffId: number,
		type: string
	) {
		const endpoint = `staff/${staffId}/staff-settings/${type}`;
		return this.api.post3<BlStaffSettingsModel>(endpoint, data).pipe(
			tap((x) => {
				applyTransaction(() => {
					const state = [
						...this.query.getValue().staffSettings,
						{ ...data, StaffSettingsId: +x },
					];
					this.store.setStaffSettings(state);
				});
			}),
			catchError(() => of(null))
		);
	}

	updateBlStaffSettings(
		data: BlStaffSettingsModel,
		staffId: number,
		staffSettingsId: number,
		type: string
	) {
		const endpoint = `staff/${staffId}/staff-settings/${staffSettingsId}/${type}`;
		return this.api.put<any>(endpoint, data).pipe(
			tap((x) => {
				applyTransaction(() => {
					const state = <BlStaffSettingsModel[]>(
						this.query
							.getValue()
							.staffSettings.map((y) =>
								+y.StaffSettingsId === +staffSettingsId ? { ...y, ...data } : y
							)
					);
					this.store.setStaffSettings(state);
				});
			}),
			catchError(() => of(null))
		);
	}

	removeBlStaffSettings(settingsId: number) {
		const endpoint = `staff/staff-settings/${settingsId}`;
		return this.api.delete<any>(endpoint).pipe(
			tap((x) => {
				applyTransaction(() => {
					const state = this.query
						.getValue()
						.staffSettings?.filter((y) => +y.StaffSettingsId !== settingsId);
					this.store.setStaffSettings(state);
				});
			}),
			catchError(() => of(null))
		);
	}

	getPDTrackingWidgets(id: number | string) {
		const endpoint = `widgets/staff/${id}`;
		return this.api.get<any>(endpoint).pipe(
			tap((data) => {
				applyTransaction(() => {
					this.store.setPDTWidget(data);
				});
			}),
			catchError(() => of(null))
		);
	}
}
