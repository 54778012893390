<button class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
  <span class="material-icons">
    close
  </span>
</button>
<div class="modal-header">
  {{ header }}
</div>
<div class="modal-body text-center">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="tap-btn tap-btn--default mr-2" (click)="confirm()">
    {{ isArchive ? 'Archive' : 'Unarchive' }}
  </button>
  <button type="button" class="tap-btn tap-btn--default" (click)="decline()">Cancel</button>
</div>
