<div class="card-header font-weight-bold text-light">
	{{ header }}
</div>
<form [formGroup]="form" class="card-body p-4" [class.submitted]="sumbitted">
	<!-- Message -->
	<div class="form-row align-items-center pb-2" *ngIf="!!message">
		<div class="col-12 text-left mb-2">
			<p>{{ message }}</p>
		</div>
	</div>
	<!-- Template Name -->
	<div class="form-row align-items-center pb-2">
		<div class="col-4 text-left mb-2">
			<label for="" class="position-relative font-weight-bold">
				Template Name
			</label>
		</div>
		<div class="col-8 mb-2">
			<input
				id="documentName"
				formControlName="documentName"
				class="form-control crt-form-control"
				type="text"
				placeholder="Template Name"
			/>
		</div>
	</div>
	<!-- Upload button -->
	<div class="form-row align-items-center pb-2 upload-file">
		<button
			type="button"
			class="tap-btn tap-btn--default"
			(click)="openUploadModal()"
			[class.invalid]="sumbitted && !(!!tempDoc || !!document)"
		>
			Upload
		</button>
		<span class="ml-2" *ngIf="!!tempDoc">{{ tempDoc?.name }}</span>
		<span class="ml-2" *ngIf="!tempDoc && !!document"
			><a href="{{ document.documentLink }}" target="_blank">{{
				document?.fileName
			}}</a>
		</span>
	</div>

	<div class="form-buttons">
		<button
			id="saveButton"
			type="button"
			class="btn btn-primary btn-save text-light btn-block mb-2"
			(click)="save()"
			[disabled]="isLoading"
		>
			Save
			<i *ngIf="isLoading" class="fas fa-spinner fa-pulse"></i>
		</button>
		<button
			id="cancelButton"
			type="button"
			class="btn-light btn-block mb-2 mb-lg-0"
			(click)="close()"
			[disabled]="isLoading"
		>
			Cancel
		</button>
	</div>
</form>
