export let Investment = [
  'Investor',
  'Investor Number',
  'Provider',
  'Status',
  'Start Date',
  'IRD Number',
  'Investment Type',
  'Product',
  'PIR Rate',
  'Contribution',
  'FUM',
  'Total Withdrawals',
  'Import ID',
  'Original Adviser Import ID',
  'Customer Import ID'
];
