import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WeeklyApiStat } from './state/was.model';
import { map, filter, takeUntil } from 'rxjs/operators';
import { BodyRow } from '../../shared/simple-table/simple-table.model';
import { DashboardQuery } from '../../state/dashboard.query';
import { WeeklyApiStatsQuery } from './state/was.query';
import { WeeklyApiStatsService } from './state/was.service';
import { numUtil } from '../../../../../util/util';

@Component({
  selector: 'app-was',
  templateUrl: './was.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WasComponent implements OnInit, OnDestroy {
  public static widgetCode = 'was';
  public static widgetName = 'Weekly API Stats';
  public static sizeX = 350;
  public static sizeY = 180;
  public static minSizeX = 350;
  public static minSizeY = 180;

  widgetCode = WasComponent.widgetCode;

  /**
   * Display name for Statuses.
   */
  private readonly propertyName = {
    Submitted: 'Submitted API',
    New: 'Issued API',
    Lost: 'Cancelled API',
    NetNew: 'Net API'
  };

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;

  /**
   * AAS Data
   */
  data$: Observable<WeeklyApiStat> = this.query.weeklyApiStatData$;

  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return Object.keys(data).map<BodyRow>(key => {
          const label = this.propertyName[key];
          const value = numUtil.formatToCurrency(data[key]);
          return [{ text: label }, { text: value, class: 'col-4' }];
        });
      }
    })
  );
  constructor(
    private dashboardQuery: DashboardQuery,
    private query: WeeklyApiStatsQuery,
    private service: WeeklyApiStatsService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of Weekly api stats.
   *
   * Chart does not automatically resize on div resize(gridstack)
   *   so listen to resize event to trigger manual resize via refreshing
   *   of data.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$
      .pipe(
        filter(x => x.length > 0),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advisers => {
        this.service.GetWeeklyApiStats(advisers);
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
