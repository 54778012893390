import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PropertySoldDetailsState } from '../../property-sold/state/property-sold.model'
import { PropertySoldMapper } from '../state/property-sold.mapper';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { MOATAssetsLiabilitiesModalComponent } from './../../../../../../../../../../shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';

import { omit, forEach } from 'ramda';
import { Observable, Observer } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';

const defaultValuationType = 'S&P';

@Component({
  selector: 'app-property-sold-form',
  templateUrl: './property-sold-form.component.html',
})
export class PropertySoldFormComponent implements OnInit {
  @Input() properties: PropertySoldDetailsState[];
  @Input() propertyOwners: ViewDisplayValue[];
  @Input() SU$: Observable<ViewDisplayValue[]>;
	@Input() SV1$: Observable<ViewDisplayValue[]>;
	@Input() ST$: Observable<ViewDisplayValue[]>;
	@Input() STI$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;

  @Input() propertyAddresses = [];

  @Input() adviceProcessId: number;

  @Input() addFn$: (req: any) => Observable<any>;
  @Input() updateFn$: (req: any) => Observable<any>;
  @Input() deleteFn$: (req: any) => Observable<any>;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];
  
  form: UntypedFormGroup;
  bsModalRef: BsModalRef;

  isAdd = false;
  addMode = false;
  isLoading = false;
  isAdding = false;
  isAddLoading = false;
  
  property = null;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: BsModalService
  ) {
    this.buildForm();
  }

  ngOnChanges(changes) {
    if (changes.properties) {
      this.buildForm();
      this.prepareData();
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.prepareData();
  }

  buildForm(): void {
    this.form = this.fb.group({
      properties: this.fb.array([]),
      newPropertyAddress: this.fb.control(null, [Validators.required]),
      newPropertySalesPrice: this.fb.control(0, [Validators.required]),
      propertyAddress: this.fb.control(null)
    });
  }

  prepareData() {
    const processData = (property: PropertySoldDetailsState) => {
      const processed = PropertySoldMapper.mapToView(property);
      const group = this.fb.group({
        adviceProcessId: this.fb.control(processed.adviceProcessId),
        parentCRTId: this.fb.control(processed.parentCRTId),
        propertyAddress: this.fb.control(processed.propertyAddress),
        propertyValue: this.fb.control(processed.propertyValue),
        propertyOwner: this.fb.control(processed.propertyOwner),
			  propertyUse: this.fb.control(processed.propertyUse),
        purchaseDate: this.fb.control(processed.purchaseDate),
        valuationType: this.fb.control(processed.valuationType),
        valuationTypeDate: this.fb.control(processed.valuationTypeDate),
        rentalIncome: this.fb.control(processed.rentalIncome),
        rentalIncomeFrequency: this.fb.control(processed.rentalIncomeFrequency),
        title: this.fb.control(processed.title),
        rates: this.fb.control(processed.rates),
        ratesFrequency: this.fb.control(processed.ratesFrequency),
        type: this.fb.control(processed.type),
        insurance: this.fb.control(processed.insurance),
        insuranceFrequency: this.fb.control(processed.insuranceFrequency),
        otherExpense: this.fb.control(processed.otherExpense),
        otherExpenseFrequency: this.fb.control(processed.otherExpenseFrequency),
        cRTId: this.fb.control(processed.cRTId ? processed.cRTId : null),
        isLoading: this.fb.control(false),
        isEdit: this.fb.control(false),
        salesPrice: this.fb.control(processed.salesPrice ? processed.salesPrice : 0)
      })
      this.PropertiesList.push(group);
    }

    if (this.properties?.length) {
      forEach(processData, this.properties);
    }
  }

  get PropertiesList() {
    return this.form.get('properties') as UntypedFormArray;
  }

	close(index: number) {
		if (this.PropertiesList.controls[index].get('cRTId').value) {
			this.PropertiesList.controls[index]
				.get('salesPrice')
				.setValue(this.properties[index].salesPrice);
			this.PropertiesList.controls[index].get('isEdit').setValue(false);
		} else {
			this.cancel();
			this.PropertiesList.removeAt(index);
		}
	}

  edit(index: number) {
    this.PropertiesList.controls[index].get('isEdit').setValue(true);
  }

  save(index: number) {
    this.PropertiesList.controls[index].get('isLoading').setValue(true);
    const data = this.PropertiesList.controls[index].value;
    const newData = Object.assign({}, omit(['isEdit', 'isLoading'], data))
    this.updateFn$(newData).pipe(
      take(1),
      finalize(() => this.PropertiesList.controls[index].get('isEdit').setValue(false))
    ).subscribe();
  }

  onSelectProperty(property) {
    if (property === 'new') {
      this.addMode = false
      this.isAdding = false
      this.handleAddNew()
    } else {
      const p = this.propertyAddresses?.find((x) => x.customerServiceID === +property)
      this.property = p;
      this.form.get('newPropertyAddress').setValue(p.propertyAddress);
      this.form.get('newPropertySalesPrice').setValue(0);
      this.addMode = true
      this.isAdding = false
    }
  }

  addNewProperty() {
    this.removeSelectedAddress();
    this.isAdding = true;
    this.form.get('propertyAddress').setValue(null);
  }

  handleAddNew() {
    const saveFn$ = (data: PropertySoldDetailsState) =>
      this.addFn$(data).pipe(
        take(1),
        finalize(() => this.cancel())
      );

    const defaultData = {
      ValuationType: defaultValuationType
    }

    this.prepAndOpenModal(defaultData, saveFn$, null);
  }

  cancel() {
    this.isAdd = false;
    this.addMode = false;
    this.isAdding = false;
    this.isAddLoading = false;
  }

  prepAndOpenModal(defaultData, saveFn$, index?: number) {
    const decline$ = new Observable((obs: Observer<any>) => {
      if (index === null) {
        this.cancel();
      } else {
        this.PropertiesList.controls[index].get('isEdit').setValue(false)
      }
      obs.complete();
    });

    const initState = {
      header: 'Property Details',
      message: `Property`,
      policyOwners: this.propertyOwners,
      crtInfo: defaultData,
      su$: this.SU$,
      sv1$: this.SV1$,
      st$: this.ST$,
      sti$: this.STI$,
      apcrtf$: this.APCRTF$,
			getOwnerChoices: this.getOwnerChoices,
      saveFn$,
      decline$,
    };
    this.bsModalRef = this.modalService.show(
      MOATAssetsLiabilitiesModalComponent,
      {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      }
    );
  }

  delete(index: number) {
    this.PropertiesList.controls[index].get('isLoading').setValue(true);
    const cRTId = this.PropertiesList.controls[index].get('cRTId').value;
    const propertyAddress = this.PropertiesList.controls[index].get('propertyAddress').value;
    this.deleteFn$(+cRTId).pipe(
      take(1),
      tap(() => {
        const addressToBeDeleted = this.PropertiesList.value.find(x => x.propertyAddress === propertyAddress);
        const addressAlreadyExist = this.propertyAddresses.filter(x => x.propertyAddress === addressToBeDeleted.propertyAddress).length > 0 ? false : true;
        if (addressAlreadyExist && addressToBeDeleted.customerServiceID) {
          this.propertyAddresses.push(addressToBeDeleted);
        }
      })
    ).subscribe();
  }

  confirmDelete(index: number) {
    this.cancel();
    const confirm = new Observable((obs: Observer<any>) => {
			this.delete(index);
			obs.complete();
		});

    const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

    const initState = {
			header: 'Delete Property',
			message: `Are you sure you want to delete this property?`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
		};

    this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
  }

  closeNew() {
    this.isAdding = false;
    this.addMode = false;
    this.form.get('propertyAddress').setValue('');
    this.removeSelectedAddress();
  }

  saveNew() {
    this.isAddLoading = true;
    const data = {
      ...this.property,
      salesPrice: this.form.get('newPropertySalesPrice').value,
      cRTId: null
    }
    this.addFn$(data).pipe(
      take(1),
      tap(() => this.cancel())
    ).subscribe();
  }

  removeSelectedAddress() {
    const result = this.propertyAddresses.filter(val1 => !this.PropertiesList.value.find(val2 => val1.propertyAddress === val2.propertyAddress));
    this.propertyAddresses = result;
  }

  clearAdd() {
    if(this.isAdding) {
      this.isAdding = false;
      this.PropertiesList.removeAt(this.PropertiesList.controls.length - 1);
    }
  }

}
