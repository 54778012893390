import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/base/api.service';
import { MGTStore } from './mgt.store';
import { GoalTrack } from './mgt.model';
import { applyTransaction } from '@datorama/akita';

/**
 * Mortgage Goal track service for fetching
 */
@Injectable()
export class MGTService {
  /**
   * partial url endpoint
   */
  private readonly endpoint = 'widgets?type=MGT';

  constructor(
    private api: ApiService,
    private store: MGTStore
  ) { }


  /**
   * Fetch and store Refix mortgage data
   *
   * @param adviserIdList
   * Search widget data related to theses advisers
   */
  public GetMortgageGoalTrack(adviserIdList: string[]): void {
    this.store.setLoading(true);
    this.api.post<GoalTrack>(this.endpoint, {
      Advisers: adviserIdList
    }).subscribe(
      res => this.store.update({GoalTrack: res}),
      err => applyTransaction(() => {
          this.store.setError('Error fetching data!');
          this.store.setLoading(false);
        }),
      () => this.store.setLoading(false)
    );
  }
}
