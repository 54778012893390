import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements ConfirmModalModel, OnInit {
	confirm$: Observable<any>;
	decline$: Observable<any>;
	close$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	subMessage?: string;
	isOkBtn?: boolean;
	isAcceptBtn?: boolean;
	confirmTxt = 'Yes';
	cancelTxt = 'No';
	detachCloseIcon = false;
	cancelLoading: boolean;
	confirmBtnText = 'Replace';
	declineBtnText = 'Cancel';
	customButtons?: { text: string; action$: Observable<any>; closeOnActionDone?: boolean; }[];
	customBtnIsExecuting = false;
	designV2: boolean = false;
	subject: Subject<boolean | string>;

	@HostBinding('class.tap-modal') modalV2: boolean;

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
		this.setupSubs();
	}
	ngOnInit(): void {
		this.modalV2 = this.designV2;
	}
	setupProps() {
		this.header = 'Confirm';
		this.message = 'Confirm?';
	}
	setupSubs() { }

	continue() {
		if (this.cancelLoading) {
			return;
		}
		this.confirm$.subscribe();
		this.bsModalRef.hide();
		this.action(true);
	}
	decline() {
		this.cancelLoading = true;
		if (!!this.decline$) {
			this.decline$.subscribe();
			this.action(false);
		}
		this.bsModalRef.hide();
		setTimeout(() => this.cancelLoading = false, 500);
	}

	close() {
		this.cancelLoading = true;
		if (!!this.close$) {
			this.close$.subscribe();
			this.action('close');
		}
		this.bsModalRef.hide();
		setTimeout(() => this.cancelLoading = false, 500);
	}

	customBtnClick(item: { text: string; action$: Observable<any>; closeOnActionDone: boolean; }): void {
		if (this.customBtnIsExecuting) {
			return;
		}
		this.customBtnIsExecuting = true;
		item.action$
		.pipe(
			finalize(() => {
				this.customBtnIsExecuting = false;
				// by default if cloneOnActionDone will be true if not specified
				if (item.closeOnActionDone || item.closeOnActionDone === undefined) {
					this.bsModalRef.hide();
				}
			}),
			take(1)
		)
		.subscribe()
	}

	action(value: boolean | string) {
		if (!!this.subject) {
			this.subject.next(value);
			this.subject.complete();
		}
	}

}
export interface ConfirmModalModel {
	confirm$: Observable<any>;
	decline$?: Observable<any>;
	close$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	subMessage?: string;
	isOkBtn?: boolean;
	isAcceptBtn?: boolean;
	confirmTxt?: string;
	cancelTxt?: string;
	detachCloseIcon?: boolean;
	customButtons?: {
		text: string;
		action$: Observable<any>;
		closeOnActionDone?: boolean;
	}[]
}
