import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/base/api.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { objectUtil } from 'src/app/util/util';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { Liability } from './liability.model';
import { LiabilityQuery } from './liability.query';
import { LiabilityStore } from './liability.store';

@Injectable()
export class LiabilityService {
	liabilities$ = this.query.liabilities$;
	MP$ = this.ddQuery.orderedChoices$('MP');
	MLT$ = this.ddQuery.orderedChoices$('MLT');
	APCRTF$ = this.ddQuery.orderedChoices$('APCRTF');
	APCRTYN$ = this.ddQuery.orderedChoices$('APCRTYN');
	APCRTYNNA$ = this.ddQuery.orderedChoices$('APCRTYNNA');
	APCRTL$ = this.ddQuery.orderedChoices$('APCRTL');
	isLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		protected store: LiabilityStore,
		protected query: LiabilityQuery,
		private ddQuery: DropdownValueQuery,
		private customerService: CustomerService,
		protected crtMortgageQuery: CrtMortgageQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(adviceProcessId: number) {
		this.store.setLoading(true);
		return this.api.get<Liability[]>(`crt/${adviceProcessId}/FAL`).pipe(
			map((x) => (x || [])?.map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => this.store.set(x || [])),
			finalize(() => this.store.setLoading(false)),
			catchError(() => of(undefined))
		);
	}

	add(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAL',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.add(x)),
			catchError(() => of(undefined))
		);
	}

	update(data) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: 'FAL',
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap(() => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.upsert(x?.cRTId, x)),
			catchError(() => of(undefined))
		);
	}

	delete(id) {
		return this.api.delete(`crt/${id}`).pipe(
			tap(() => this.store.remove(+id)),
			catchError(() => of(undefined))
		);
	}
}
