import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { CrtAssetFormComponent } from './components/crt-asset/crt-asset-form/crt-asset-form.component';
import { CrtAssetComponent } from './components/crt-asset/crt-asset.component';
import { CrtIncomeSourceComponent } from './components/crt-income-source/crt-income-source.component';
import { CrtPreviousIncomeSourceComponent } from './components/crt-previous-income-source/crt-previous-income-source.component';
import { CrtPreviousIncomeSourceFormComponent } from './components/crt-previous-income-source/crt-previous-income-source-form/crt-previous-income-source-form.component';
import { CrtIntroComponent } from './components/crt-intro/crt-intro.component';
import { CrtKiwisaverTemplateFormComponent } from './components/crt-kiwisaver-template/crt-kiwisaver-template-form/crt-kiwisaver-template-form.component';
import { CrtKiwisaverTemplateComponent } from './components/crt-kiwisaver-template/crt-kiwisaver-template.component';
import { CrtLiabilityFormComponent } from './components/crt-liability/crt-liability-form/crt-liability-form.component';
import { CrtLiabilityComponent } from './components/crt-liability/crt-liability.component';
import { CrtMortgageTemplateFormComponent } from './components/crt-mortgage-template/crt-mortgage-template-form/crt-mortgage-template-form.component';
import { CrtMortgageTemplateComponent } from './components/crt-mortgage-template/crt-mortgage-template.component';
import { CrtOtherIncomeComponent } from './components/crt-other-income/crt-other-income.component';
import { CrtRentalIncomeComponent } from './components/crt-rental-income/crt-rental-income.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { PropertyFormComponent } from './components/property/property-form/property-form.component';
import { PropertyComponent } from './components/property/property.component';
import { CrtDocumentService } from './service/crt-document.service';
import { CrtBudgetFormComponent } from './components/crt-budget-form/crt-budget-form.component';
import { CrtInviteCpComponent } from './components/crt-invite-cp/crt-invite-cp.component';
import { CrtInviteCpModalComponent } from './components/crt-invite-cp/crt-invite-cp-modal/crt-invite-cp-modal.component';
import { CrtInviteCpAdditionalInfoModalComponent } from './components/crt-invite-cp/crt-invite-cp-additional-info-modal/crt-invite-cp-additional-info-modal.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CrtHeaderModule } from './components/header/crt-header.module';
import { CrtFooterModule } from './components/footer/crt-footer.module';
import { AiQuoteService } from './service/ai-quote/ai-quote.service';

@NgModule({
    declarations: [
        // HeaderComponent,
        // FooterComponent,
        MainPageComponent,
        PropertyComponent,
        PropertyFormComponent,
        CrtIntroComponent,
        CrtKiwisaverTemplateComponent,
        CrtKiwisaverTemplateFormComponent,
        CrtMortgageTemplateComponent,
        CrtMortgageTemplateFormComponent,
        CrtOtherIncomeComponent,
        CrtAssetComponent,
        CrtAssetFormComponent,
        CrtPreviousIncomeSourceFormComponent,
        CrtLiabilityComponent,
        CrtLiabilityFormComponent,
        CrtIncomeSourceComponent,
        CrtPreviousIncomeSourceComponent,
        CrtRentalIncomeComponent,
        CrtBudgetFormComponent,
        CrtInviteCpComponent,
        CrtInviteCpModalComponent,
        CrtInviteCpAdditionalInfoModalComponent,
    ],
    imports: [CommonModule, SharedModule, CrtHeaderModule, CrtFooterModule],
    exports: [
        // Modules
        CommonModule,
        SharedModule,
        // Components
        HeaderComponent,
        FooterComponent,
        MainPageComponent,
        PropertyComponent,
        PropertyFormComponent,
        CrtIntroComponent,
        CrtKiwisaverTemplateComponent,
        CrtKiwisaverTemplateFormComponent,
        CrtMortgageTemplateComponent,
        CrtMortgageTemplateFormComponent,
        CrtOtherIncomeComponent,
        CrtAssetComponent,
        CrtAssetFormComponent,
        CrtLiabilityComponent,
        CrtLiabilityFormComponent,
        CrtIncomeSourceComponent,
        CrtPreviousIncomeSourceComponent,
        CrtPreviousIncomeSourceFormComponent,
        CrtRentalIncomeComponent,
        CrtBudgetFormComponent,
        CrtInviteCpComponent,
        CrtInviteCpModalComponent,
        CrtInviteCpAdditionalInfoModalComponent,
    ],
    providers: [
			CrtDocumentService,
			AiQuoteService
		]
})
export class CrtSharedModule {}
