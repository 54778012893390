import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, defer, map, shareReplay } from 'rxjs';
import { AddDocumentModalComponent } from './add-document-modal.component';
import { DocumentGroupState } from '@shared/models/documents/document-group.model';
import { ApiService } from '@core/base/api.service';
import {
	AdviceProcessModel,
	AdviceProcessTypes,
} from '@modules/advice-process/state/advice-process.model';

export interface AddDocumentModalShowOption {
	title?: string;
	customerId: number,
	documentType: string;
	documentNames$: Observable<
		{
			display: string;
			value: string;
		}[]
	>;
	uploadFn$: (doc: any, data: any) => Observable<any>;
	linkFn$: (doc: any, data: any) => Observable<any>;
	clientDocumentLoader$: () => Observable<DocumentGroupState>;
	defaultLinkDocumentTab: string;
}

@Injectable({
	providedIn: 'root',
})
export class AddDocumentModalService {

	private AdviceProcessSettings = {};

	constructor(
		private modalService: BsModalService,
		private api: ApiService
	) {}

	show(options: AddDocumentModalShowOption): Observable<any> {
		return defer(() => {
			const result$ = new Subject();
			this.modalService.show(AddDocumentModalComponent, {
				class: 'modal-dialog modal-dialog-centered modal-md add-document-modal',
				ignoreBackdropClick: true,
				// @ts-ignore-next
				initialState: {
					result$,
					...options,
				},
			});
			return result$.asObservable();
		});
	}

	getAdviceProcessSettings(processCode) {
		if(!this.AdviceProcessSettings[processCode]){
			this.AdviceProcessSettings[processCode] = this.api
			.get<AdviceProcessModel[]>(`adviceprocesses/settings/${processCode}`)
			.pipe(
				map((result) => {
					const listDT = result
						? result
								?.filter(
									(x) =>
										x.Type === AdviceProcessTypes.OfflineDocuments &&
										x.Status === 1
								)
								?.sort((a, b) => a?.SettingOrder - b?.SettingOrder)
						: null;
					return listDT.map((d) => {
						return {
							display: d.Value,
							value: d.Value,
						};
					});

				}),
				shareReplay(1)
			);
		}
		return this.AdviceProcessSettings[processCode];

	}
}
