<div class="container-fluid manage-users">
	<div class="row p-0 manage-users__table mt-3 mb-3">
		<div class="col-12 p-3">
			<app-simple-data-table
				[tableColumns]="tableDataColumns"
				[dataSource]="dataSource"
				[displayedColumns]="displayedDataColumns"
				[itemPerPage]="10"
				[enablePagination]="true"
				[isLoadingData]="isLoadingData"
			></app-simple-data-table>
		</div>
	</div>
</div>
