<div class="border-0 rounded position-relative">
  <div class="position-relative d-flex align-items-center">
    <input
      #physicalAddress
      appAddressField
      type="text"
      [class]="'form-control ' + textboxClass"
      [id]="inputId"
      [placeholder]="placeholder"
      aria-label=""
      aria-describedby="basic-addon2"
      [disabled]="disabled"
      [tabindex]="tabindex"
      [ngModel]="address"
      (ngModelChange)="valueChange($event)"
    />

    <button
      (click)="googlemap(physicalAddress.value)"
      class="btn btn-outline-secondary border-0 p-0"
      type="button"
    >
      <i class="material-icons align-middle pl-1 pr-0">&#xE55F;</i>
    </button>
  </div>
</div>
