import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private menuState: BehaviorSubject<boolean>;

  constructor() {
    this.menuState = new BehaviorSubject<boolean>(false);
  }

  getMenuState(): Observable<boolean> {
    return this.menuState.asObservable();
  }
  setMenuState(newValue): void {
    this.menuState.next(newValue);
  }

}