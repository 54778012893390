import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FGPremiumStatsCommercialState, FGPremiumStatsCommercialStore } from './fgpsc.store';

/**
 * Query for FG Premium Stats Commercial widget data for Rollling 12 Months (Year)
 */
@Injectable()
export class FGPremiumStatsCommercialQuery extends Query<FGPremiumStatsCommercialState> {

  /** FG Premium Stats Commercial data. */
  FGPremiumStatsCommercialData$ = this.select(state =>
    state.FGPremiumStatsCommercial
  );

  /** Indicator if FGPremiumStatsCommercial is loading */
  isLoading$ = this.select(state => state.isLoading);

  /** error message for FGPremiumStatsCommercial pipeline */
  error$ = this.select(state => state.error);

  constructor(protected store: FGPremiumStatsCommercialStore) {
    super(store);
  }
}
