import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DisclosureStore, koatDisclosureState } from './disclosure.store';

@Injectable()
export class DisclosureQuery extends QueryEntity<koatDisclosureState> {
	activeDisclosure$ = this.selectActive();
	disclosureSettings$ = this.select((x) => x?.settings);

	constructor(protected store: DisclosureStore) {
		super(store);
	}
}
