import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { EmailHistoryQuery } from './state/email-history.query';
import { EmailHistoryService } from './state/email-history.service';
import { map, takeUntil, debounceTime, tap } from 'rxjs/operators';
import { of, fromEvent, Subject } from 'rxjs';
import { linkColumn } from './email-history-datatable.config';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import * as R from 'ramda';
import { ValueReference, Page } from '../../../shared/models/emails/email-history/email-history.model';
import { Router, Event } from '@angular/router';
import { EmailStatusQuery } from '../email-status/state/email-status.query';

@Component({
	selector: 'app-email-history',
	templateUrl: './email-history.component.html',
	styleUrls: ['./email-history.component.scss']
})
export class EmailHistoryComponent implements OnInit {
	@ViewChild('mydatatable') table: DatatableComponent;
	@Input() transferToStatus: (data) => void;

	obj: {} = {};
	page = new Page();

	minRange = 0;
	maxRange = 0;


	resizeEvent$ = fromEvent(window, 'resize');
	private onDestroy$ = new Subject<void>();

	public emailModeList$: ViewDisplayValue[];
	emailModeList: ValueReference[] = [
		{ name: 'Initialized', value: '0' },
		{ name: 'Automated', value: '1' },
		{ name: 'Manual', value: '2' },
	];
	public emailType$ = this.emailHistoryQuery.emailType$;
	public tableResult$ = this.emailHistoryQuery.tableResult$;

	isShowTable = false;
	tblHeight: SafeStyle;
	linkColumn = linkColumn;
	rows$ = this.emailHistoryQuery.sortedRows$;
	columns$ = this.emailHistoryQuery.tableColumns$;
	columnsWithLink$ = this.columns$.pipe(
		map(x => [...x, linkColumn])
	);

	constructor(private emailHistoryQuery: EmailHistoryQuery,
		           private emailStatusQuery: EmailStatusQuery,
		           private emailHistoryService: EmailHistoryService,
		           private sanitizer: DomSanitizer,
		           private router: Router) {
		this.router
        .events
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((event: Event) => {
          this.isShowTable = false;
        });

		this.page.pageNumber = 1;
		this.page.size = 500;
		this.page.totalElements = 20;
	}

	onFooterPage(pageInfo) {
		const setObj = {
			...this.obj,
			Paging: {
				Index: pageInfo.page,
				Size: this.page.size
			}
		};

		this.page.pageNumber = pageInfo.page;
		this.minRange = (this.page.size * this.page.pageNumber) - (this.page.size - 1);
		let valueCount = 0;
		// const valueCount1 = this.tableResult$.subscribe(x => valueCount = x.total);
		this.maxRange = (this.page.size * this.page.pageNumber) > valueCount ? valueCount : (this.page.size * this.page.pageNumber);

		return this.emailHistoryService.getReport(setObj).subscribe();
	}

	getReport = (values) => {
		this.obj = {
			...values,
			Paging: {
				Index: 1,
				Size: this.page.size
			}
		};

		return this.emailHistoryService.getReport(this.obj).pipe(
			tap(x => {
				this.page.totalElements = x.Total;

				this.minRange = (this.page.size * this.page.pageNumber) - (this.page.size - 1);
				let valueCount = 0;
				const valueCount1 = this.tableResult$.subscribe(val => valueCount = (val && val.total) | 0);
				this.maxRange = (this.page.size * this.page.pageNumber) > valueCount ? valueCount : (this.page.size * this.page.pageNumber);

			}),
		);
	}

	acceptValueChange(change) {
		this.isShowTable = change;
	}

	onSort(a: { sorts; column; prevValue; newValue }) {
		this.emailHistoryService?.sort(a.sorts[0].prop, a.sorts[0].dir);
		this.table.offset = this.page.pageNumber - 1;
	}

	ngOnInit() {
		this.resizeEvent$.pipe(debounceTime(500), takeUntil(this.onDestroy$)).subscribe(() => {
			this.emailHistoryService.reloadData();
		});

		this.tblHeight = this.sanitizer.bypassSecurityTrustStyle('calc(100vh - 200px)');
		of(this.emailModeList).pipe(
			map((emailMode) => {
				const em = R.map(y => ViewDisplayValue.Map(!y.value ? '' : y.value?.toString(), `${y.name}`), emailMode);
				this.emailModeList$ = [...em];
			}),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

	transfer(row, type) {
		let emailTypeArray = [];
		const emailMode = row.EmailMode ? this.emailModeList?.filter(x => x.name === row.EmailMode)[0].value : 0;
		this.emailStatusQuery.emailType$.subscribe(x => emailTypeArray = x);
		const emailType = row.EmailType ? emailTypeArray?.filter(x => x.display === row.EmailType)[0].value : '';
		let obj = {};
		if (type === 'display') {
			obj = {
				EmailHistoryId: row.EmailHistoryId,
				DateTarget: row.DateTarget,
				DateSent: row.DateSent,
				EmailMode: [],
				EmailType: [emailType],
				Status: []
			};
		}
		if (type === 'success') {
			obj = {
				EmailHistoryId: row.EmailHistoryId,
				DateTarget: row.DateTarget,
				DateSent: row.DateSent,
				EmailMode: [],
				EmailType: [emailType],
				Status: ['delivered', 'open', 'click', 'unsubscribe', 'group_unsubscribe', 'group_resubscribe', 'spamreport']
			};
		}
		if (type === 'failed') {
			obj = {
				EmailHistoryId: row.EmailHistoryId,
				DateTarget: row.DateTarget,
				DateSent: row.DateSent,
				EmailMode: [],
				EmailType: [emailType],
				Status: ['Forbidden', 'BadRequest', 'Accepted', 'processed', 'dropped', 'deferred', 'bounce', 'blocked']
			};
		}
		this.transferToStatus(obj);
	}

	resize = (event: { column: TableColumn; newValue: number }) => {
		if (event && (!event.column || !event.newValue)) {
			return;
		}
	}

	getRowClass = (row) => {
		return {
			'row-color': true
		};
	}

	getCellClass = (row) => {
		return {
			'cell-color': true
		};
	}

}

