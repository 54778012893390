import { PropertyPurchaseDetailsState } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/funding-required/property-purchase/state/property-purchase.model';
import { ApplicationSecurityDetails } from 'src/app/modules/crm/crt-page/crt-mortgage/application/application-steps/review-application/state/review-application.model';
import { BankWillTake } from '../state/security.model';

export class SecurityMapper {
	public static getSecurityDetails(
		security: BankWillTake[],
		propertyPurchase: PropertyPurchaseDetailsState[],
		ffProperties
	) {
		const list = security?.filter((x) => !!x?.isTick) || [];

		return list?.map((x) => {
			const data = x?.fromFactFind
				? ffProperties?.find((i) => +i?.cRTId === +x?.aLPropertiesCrtId)
				: propertyPurchase?.find((i) => +i?.cRTId === +x?.aLPropertiesCrtId);

			return {
				...data,
				securityAddress: x?.propertyAddress,
				fromFactFind: x?.fromFactFind,
				aLPropertiesCrtId: x?.aLPropertiesCrtId,
				amount: x?.amount,
				isTick: x?.isTick,
			};
		}) as ApplicationSecurityDetails[];
	}
}
