import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickwinsFlagDirective } from './quickwins-flag.directive';

const publicComponent = [
	QuickwinsFlagDirective,
]

@NgModule({
  declarations: publicComponent,
  exports: publicComponent,
  imports: [
    CommonModule
  ]
})
export class QuickwinsModule { }
