import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { uniqBy, prop, sum, isNil } from 'ramda';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import {
	CustomerTypes,
	RelationshipTypes,
} from 'src/app/shared/models/_general/client.model';
import { PrimaryCustomerCompanyState } from '../../../../shared/models/business-profile/business/business.model';
import { PrimaryClientState } from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClientState } from '../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from '../../../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { DependentState } from '../../../../shared/models/client-review-template/dependent/dependent.model';
import { ScopeOfServiceMapper as sosMapper } from '../../../../shared/models/client-review-template/scope-of-service/scope-of-service.mapper';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { objectUtil } from '../../../../util/util';
import {
	ClientReviewTemplateState,
	ClientReviewTemplateStore,
} from './client-review-template.store';

@Injectable()
export class ClientReviewTemplateQuery extends Query<ClientReviewTemplateState> {
	constructor(protected store: ClientReviewTemplateStore) {
		super(store);
	}
	/**
	 * @returns observable boolean of isCompany
	 */
	isCompany$ = this.select((state) => state.isCompany);
	/**
	 * @returns observable object of primaryClient
	 */
	primaryClient$ = this.select((state) => state.primaryClient);
	/**
	 * @returns observable object of secondary clients
	 */
	secondaryClients$ = this.select((state) => state.secondaryClients);
	/**
	 * @returns observable object of all secondary clients including transfered
	 */
	allSecondaryClients$ = this.select((state) => state.allSecondaryClients);
	/**
	 * @returns observable object of secondary trusts
	 */
	secondaryTrusts$ = this.select((state) => state.secondaryTrusts);
	/**
	 * @returns observable object of secondary companies
	 */
	secondaryCompanies$ = this.select((state) => state.secondaryCompanies);

	/**
	 * @returns observable object of linked contacts
	 */
	linkedContacts$ = this.select((state) => state.linkedContacts).pipe(
		map((contacts) => {
			return (
				contacts?.map((contact) => ({
					...contact,
					customerID: contact.linkedFromPrimaryCustomer
						? contact.relatedCustomerId
						: contact.customerId,
				})) || []
			);
		})
	);

	/**
	 * @returns observable object of advice process ID
	 */
	adviceProcessId$ = this.select((state) => state.adviceProcessId);

	/*
	 * @returns observable of Merge Tags
	 */
	mergeTags$ = this.select((state) => state.mergeTags);

	/*
	 * @returns observable of Disclosure Document (CRT, Settings, MT)
	 */
	disclosureDocument$ = this.select((state) => state.disclosureDocument);
	disclosureSettings$ = this.select((state) => state.disclosureSettings);

	/*
	 * @returns observable of Scope of Service
	 */
	scopeOfService$ = this.select((state) => state.scopeOfService);
	sosDefault$ = this.select((state) => state.sosDefault);

	hasLife$: Observable<boolean> = this.select(
		(state) => state.scopeOfService
	).pipe(
		withLatestFrom(this.sosDefault$),
		map(([crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
		map((x) => {
			return !!x && +x?.lifeInsurance === 1;
		}),
	);
	hasDisability$: Observable<boolean> = this.select(
		(state) => state.scopeOfService
	).pipe(
		withLatestFrom(this.sosDefault$),
		map(([crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
		map((x) => !!x && +x.disabilityInsurance === 1)
	);
	hasCriticalIllness$: Observable<boolean> = this.select(
		(state) => state.scopeOfService
	).pipe(
		withLatestFrom(this.sosDefault$),
		map(([crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
		map((x) => !!x && +x.criticalIllness === 1)
	);
	hasTPD$: Observable<boolean> = this.select(
		(state) => state.scopeOfService
	).pipe(
		withLatestFrom(this.sosDefault$),
		map(([crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
		map((x) => !!x && +x.tPDInsurance === 1)
	);
	hasMedical$: Observable<boolean> = this.select(
		(state) => state.scopeOfService
	).pipe(
		withLatestFrom(this.sosDefault$),
		map(([crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
		map((x) => !!x && +x.medicalInsurance === 1)
	);
	hasHomeCarContentsInsurance$: Observable<boolean> = this.select(
		(state) => state.scopeOfService
	).pipe(
		withLatestFrom(this.sosDefault$),
		map(([crt, settings]) => sosMapper.mapCheckboxes(crt, settings)),
		map((x) => !!x && +x.homeCarAndContentsInsurance === 1)
	);

	/**
	 * @returns observable list of people
	 */
	people$ = this.select((state) => state.people);

	isPeopleLoading$ = this.select((state) => state.peopleIsLoading);

	/**
	 * @returns observable list of dependents
	 */
	dependents$ = this.select((state) => state.dependents);

	isDependantLoading$ = this.select((state) => state.dependantsIsLoading);

	/**
	 * @returns observable list of trusts
	 */
	trusts$ = this.select((state) => state.trusts);

	isTrustLoading$ = this.select((state) => state.trustsIsLoading);

	/**
	 * @returns observable list of dependents
	 */
	company$ = this.select((state) => state.company);

	isCompanyLoading$ = this.select((state) => state.companyIsLoading);

	/**
	 * @returns observable list of PCI and SCI
	 */
	clientIndividuals$ = this.select((state) => state.clientIndividuals);

	/**
	 * @returns Observable L&R Advice Process loading & Page Completed/Started List
	 */
	lrApUpdateLoading$ = this.select((state) => state.lrApUpdateLoading);
	lrApPageCompleted$ = this.select((state) => state.lrApPageCompleted);
	lrApPageStarted$ = this.select((state) => state.lrApPageStarted);

	clientAcceptanceSettings$ = this.select(
		(state) => state.clientAcceptanceSettings
	);

	peopleFromCrmAndCrtExceptChildChoices$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.isCompany$,
	]).pipe(
		map(([pci, sci, p, isCompany]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? [])
				// .filter((s) => s.relationship !== RelationshipTypes.Child)
				.map((x) =>
					ViewDisplayValue.Map(
						`${x.customerID}`,
						`${x.firstName.concat(' ', x.lastName)}`
					)
				);

			const people = (p ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [...pciList, ...sciList, ...people]);
		})
	);
	peopleFromCrmAndCrtChoices$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.isCompany$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, p, isCompany, linkedContacts]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? []).map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName.concat(' ', x.lastName)}`
				)
			);

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			const people = (p ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...people,
				...sclList,
			]);
		})
	);

	pciAndSciFromCrmAndCrtChoices$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.dependents$,
		this.isCompany$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, p, d, isCompany, linkedContacts]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? []).map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName.concat(' ', x.lastName)}`
				)
			);

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			const people = (p ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);

			const defendants = (d ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...people,
				...defendants,
				...sclList,
			]);
		})
	);

	pciAndSciFromCrmAndCrtChoicesWithBusinessTrusts$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.dependents$,
		this.isCompany$,
		this.linkedContacts$,
		this.secondaryCompanies$,
		this.secondaryTrusts$
	]).pipe(
		map(([pci, sci, p, d, isCompany, linkedContacts, businesses, trusts]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? []).map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName.concat(' ', x.lastName)}`
				)
			);

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			const people = (p ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);

			const defendants = (d ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);

			const businessList = (businesses ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerID}`, `${x.companyName}`)
			);

			const trustList = (trusts ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerID}`, `${x.trustName}`)
			);

			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...people,
				...defendants,
				...sclList,
				...businessList,
				...trustList
			]);
		})
	);

	//
	pciAndSciFromCrmAndCrtChoicesNoDeceased$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.dependents$,
		this.isCompany$,
	]).pipe(
		map(([pci, sci, p, d, isCompany]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? [])
				.filter((sci) => sci.contactMethod !== 'Deceased')
				.map((x) =>
					ViewDisplayValue.Map(
						`${x.customerID}`,
						`${x.firstName.concat(' ', x.lastName)}`
					)
				);

			const people = (p ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);

			const defendants = (d ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...people,
				...defendants,
			]);
		})
	);

	/**
	 * @returns observable list of policy owners
	 * from both crt and crm
	 */
	policyOwnersWithCRT$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.secondaryTrusts$,
		this.secondaryCompanies$,
		this.people$,
		this.trusts$,
		this.company$,
		this.isCompany$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, sct, scc, p, t, c, isCompany, linkedContacts]) => {
			const pciList = [
				ViewDisplayValue.Map(
					`${
						(pci as PrimaryClientState | PrimaryCustomerCompanyState)
							?.customerID
					}`,
					`${
						!isCompany
							? (pci as PrimaryClientState)?.firstName?.concat(
									' ',
									(pci as PrimaryClientState)?.lastName
							  )
							: (pci as PrimaryCustomerCompanyState)?.companyName
					}`
				),
			];

			const sciList = ((sci as SecondaryClientState[]) ?? []).map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName.concat(' ', x.lastName)}`
				)
			);

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			const sctList = ((sct as SecondaryTrustState[]) ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerID}`, `${x.trustName}`)
			);

			const sccList = ((scc as PrimaryCustomerCompanyState[]) ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerID}`, `${x.companyName}`)
			);

			const people = (p ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			const trusts = (t ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			const companies = (c ?? []).map((x) =>
				ViewDisplayValue.Map(`${x.customerId}`, `${x.name}`)
			);
			return uniqBy(prop('value'), [
				...pciList,
				...sciList,
				...sctList,
				...sccList,
				...people,
				...trusts,
				...companies,
				...sclList,
			]) as ViewDisplayValue[];
		})
	);

	/**
	 * @returns Property list (Fact and Find )
	 */
	properties$ = this.select((state) => state.properties);

	// Total Property values
	totalPropertyValues$ = this.properties$.pipe(
		map((x) => sum((!isNil(x) ? x : []).map((p) => +p.propertyValue)))
	);

	/**
	 * @returns KiwiSaver list (Fact and Find )
	 */
	kiwiSavers$ = this.select((state) => state.kiwiSavers);

	/**
	 * @returns Other Assets and Investments list (Fact and Find )
	 */
	assets$ = this.select((state) => state.assets);

	/**
	 * @returns Mortgage list (Fact and Find )
	 */
	mortgages$ = this.select((state) => state.mortgages);

	/**
	 * @returns Liabilities list (Fact and Find )
	 */
	liabilities$ = this.select((state) => state.liabilities);

	/**
	 * @returns Other Assets and Investments list (Fact and Find )
	 */
	others$ = this.select((state) => state.others);

	isPropertiesLoading$ = this.select((x) => x.isPropertiesLoading);
	isKiwiSaversLoading$ = this.select((x) => x.isKiwiSaversLoading);
	isAssetsLoading$ = this.select((x) => x.isAssetsLoading);
	isMortgagesLoading$ = this.select((x) => x.isMortgagesLoading);
	isLiabilitiesLoading$ = this.select((x) => x.isLiabilitiesLoading);
	isOthersLoading$ = this.select((x) => x.isOthersLoading);

	propertyAddresses$ = this.select((state) => state.propertyAddresses);
	crmKiwiSavers$ = this.select((state) => state.crmKiwiSavers);
	crmMortgages$ = this.select((state) => state.crmMortgages);
	crmLiabilities$ = this.select((state) => state.crmLiabilities);

	/**
	 * @returns observable list of income sources
	 */
	incomeSource$ = this.select((state) => state.incomeSource);

	/**
	 * @returns observable list of rental income
	 */
	netRentalIncome$ = this.select((state) => state.rentalDetails);

	/**
	 * @returns observable list of other income
	 */
	otherIncome$ = this.select((state) => state.otherIncomeDetails);

	/*
	 * @returns observable of monthly expenses
	 */
	monthlyExpense$ = this.select((state) => state.monthlyExpense);

	/*
	 * @returns observable of fact find computations
	 */
	factFindComputation$ = this.select((state) => state.factFindComputation);

	/**
	 * @returns observable list of people
	 */
	currentInsurances$ = this.select((state) => state.currentInsurances);
	/**
	 * @returns observable boolean of currentInsurance
	 */
	isLoadingCurrentInsurance$ = this.select(
		(state) => state.isLoadingCurrentInsurance
	);

	/**
	 * @returns observable list of life assured
	 */
	lifeAssured$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, linkedContacts]) => {
			const combinedPciSci = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...((sci as any) ?? []).map(objectUtil.mapPascalCaseToCamelCase),
			]?.map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName?.concat(' ', x.lastName)}`
				)
			) as ViewDisplayValue[];

			const sclList = ((linkedContacts as LinkedContactState[]) ?? [])?.map(
				(x) => ViewDisplayValue.Map(`${x.customerID}`, `${x.name}`)
			);

			return uniqBy(prop('value'), [
				...combinedPciSci,
				...sclList,
			]) as ViewDisplayValue[];
		})
	);

	/*
	 * @returns observable of house insurance
	 */
	houseInsurance$ = this.select((state) => state.houseInsurance);

	/*
	 * @returns observable of car insurance
	 */
	carInsurance$ = this.select((state) => state.carInsurance);

	/*
	 * @returns observable of content insurance
	 */
	contentInsurance$ = this.select((state) => state.contentInsurance);

	/*
	 * @returns observable of blanket Quote
	 */
	blanketQuote$ = this.select((state) => state.blanketQuote);

	blanketOptions$ = this.select((state) => state.blanketOptions);
	/*
	 * @returns observable of body measures
	 */
	bodyMeasures$ = this.select((state) => state.bodyMeasures);

	/*
	 * @returns observable of medical history
	 */
	medicalHistory$ = this.select((state) => state.medicalHistory);

	/*
	 * @returns observable of medical history options
	 */
	medicalHistoryOptions$ = this.select((state) => state.medicalHistoryOptions);

	isMedicalHistoryLoading$ = this.select(
		(state) => state.medicalHistoryIsLoading
	);

	/*
	 * @returns observable of family history
	 */
	familyHistory$ = this.select((state) => state.familyHistory);

	isFamilyHistoryLoading$ = this.select(
		(state) => state.familyHistoryIsLoading
	);

	/*
	 * @returns observable of gp details history
	 */
	gpHistory$ = this.select((state) => state.gpHistory);

	/*
	 * @returns observable of gp clients
	 */
	gpClients$ = this.select((state) => state.gpClients);

	isGpClientsLoading$ = this.select((state) => state.gpClientsIsLoading);

	/*
	 * @returns observable of Risk Analysis > Goals
	 */
	goals$ = this.select((state) => state.goals);

	/*
	 * @returns observable of Risk Analysis > Critical Illness
	 */
	criticalIllness$ = this.select((state) =>
		state.criticalIllness?.filter((x) => !!x.parentCRTId || +x.parentCRTId > 0)
	);

	/*
	 * @returns observable of Risk Analysis > Risk Profile
	 */
	riskProfile$ = this.select((state) => state.riskProfile);

	declaration$ = this.select((state) => state.declaration);
	declarationFormValue$ = this.select((state) => state.declarationFormValue);

	// Composed of PCI, SCI and CRT (People)
	peopleFromCRMAndCRT$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
	]).pipe(
		map(([pci, sci, people]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];
			const mapItem = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
			];
			return mapItem.filter((o) =>
				people && people.length > 0
					? !people.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	// Composed of PCI, SCI(Except relationship !== 'child') and CRT (People)
	peopleFromCRMAndCRTExceptChild$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.linkedContacts$,
	]).pipe(
		filter(([pci, sci, people]) => !!people),
		map(([pci, sci, people, linkedContacts]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) => x.relationship !== RelationshipTypes.Child
				  )
				: [];

			const l = linkedContacts
				? (
						linkedContacts?.map(objectUtil.mapPascalCaseToCamelCase) as any
				  ).filter((x) => x.relationship !== RelationshipTypes.Child)
				: [];

			const mapItem = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
				...l,
			];

			return mapItem.filter((o) =>
				people && people.length > 0
					? !people.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	// Composed of PCI, SCI(Except relationship !== 'child' && contactMethod !== 'Deceased') and CRT (People)
	peopleFromCRMAndCRTExceptChildNotDeceased$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.linkedContacts$,
	]).pipe(
		filter(([pci, sci, people]) => !!people),
		map(([pci, sci, people, linkedContacts]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) =>
							x.relationship !== RelationshipTypes.Child &&
							x.contactMethod !== 'Deceased'
				  )
				: [];

			const l = linkedContacts
				? (
						linkedContacts?.map(objectUtil.mapPascalCaseToCamelCase) as any
				  ).filter(
						(x) =>
							x.relationship !== RelationshipTypes.Child &&
							x.contactMethod !== 'Deceased'
				  )
				: [];

			const mapItem = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
				...l,
			];

			return mapItem.filter((o) =>
				people && people.length > 0
					? !people.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	// List of Transfered SCI
	transferedSCIList$ = combineLatest([
		this.secondaryClients$,
		this.people$,
	]).pipe(
		filter(([sci, people]) => !!people && !!sci),
		map(([sci, people]) => {
			const crtPeople =
				people?.filter(
					(x) => x?.customerType === CustomerTypes.SecondaryCustomerIndividual
				) || [];
			const list = crtPeople?.filter(
				(x) => !sci?.some((val) => +x?.customerId === +val?.customerID)
			);
			return list || [];
		})
	);

	// [NEW] Composed of PCI, SCI(Except relationship !== 'child') PLUS CRT (People)
	peopleFromCRMPlusCRTExceptChild$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
	]).pipe(
		filter(([pci, sci, people]) => !!people),
		map(([pci, sci, people]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) => x.relationship !== RelationshipTypes.Child
				  )
				: [];
			const mapItem = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
			];

			return [
				...mapItem.filter((o) =>
					people && people.length > 0
						? !people.find((c) => o.customerID === c.customerId)
						: o
				),
				...people,
			];
		})
	);

	// Composed of PCI, SCI and CRT (People) No filter
	peopleFromCRMAndCRTNoFilter$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
		this.linkedContacts$,
	]).pipe(
		map(([pci, sci, people, linkedContacts]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const l = linkedContacts
				? (linkedContacts?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const mapItem = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
				...(people || []),
				...l,
			];
			return mapItem;
		})
	);

	// Composed of PCI, SCI and CRT (People) - Removes PCI or SCI that are already included in People
	peopleFromCRMAndCRTRemoveDoubles$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.people$,
	]).pipe(
		map(([pci, sci, people]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];
			const mapItem = [
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
				...s,
			];

			if (people) {
				return [
					...mapItem.filter((o) =>
						people.length > 0
							? !people.find((c) => o.customerID === c.customerId)
							: o
					),
					...people,
				];
			} else {
				return mapItem;
			}
		})
	);

	dependentsFromCRMandCRTChildOnly$ = combineLatest([
		this.secondaryClients$,
		this.dependents$,
	]).pipe(
		map(([sci, dependents]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) => x.relationship === RelationshipTypes.Child
				  )
				: [];

			const mapItem = dependents
				? [
						...s,
						...(dependents || [])?.map((x) => {
							return {
								...x,
								customerID: x.customerId,
							};
						}),
				  ]
				: [...s];
			return mapItem.filter((o) =>
				dependents && dependents.length > 0
					? !dependents.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	dependentsFromCRMandCRTChildNotDeceasedOnly$ = combineLatest([
		this.secondaryClients$,
		this.dependents$,
	]).pipe(
		map(([sci, dependents]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) =>
							x.relationship === RelationshipTypes.Child &&
							x.contactMethod !== 'Deceased'
				  )
				: [];

			const mapItem = dependents
				? [
						...s,
						...(dependents || [])?.map((x) => {
							return {
								...x,
								customerID: x.customerId,
							};
						}),
				  ]
				: [...s];
			return mapItem.filter((o) =>
				dependents && dependents.length > 0
					? !dependents.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	dependentsFromCRMChildOnlyNoFilter$ = combineLatest([
		this.secondaryClients$,
		this.dependents$,
	]).pipe(
		map(([sci, dependents]) => {
			const s = sci
				? (sci?.map(objectUtil.mapPascalCaseToCamelCase) as any).filter(
						(x) => x.relationship === RelationshipTypes.Child
				  )
				: [];

			const mapItem = [...s, ...dependents];
			return mapItem;
		})
	);

	peopleAndDependentsFromCRTOnly$ = combineLatest([
		this.people$,
		this.dependents$,
	]).pipe(
		filter(([people, dependents]) => !!people && !!dependents),
		map(([people, dependents]) => {
			const list = uniqBy(prop('customerId') as any, [
				...people,
				...dependents,
			]);
			return list.sort((item1, item2) => item1.name?.localeCompare(item2.name));
		})
	);

	peopleAndDependantsFromCRTOnly$ = combineLatest([
		this.people$,
		this.dependents$,
	]).pipe(
		filter(([people, dependents]) => !!people && !!dependents),
		map(([people, dependents]) => {
			let dependant = null;
			if (dependents?.filter((dependent) => dependent.cRTId).length > 0) {
				dependant = {
					name: 'Dependants',
					cRTId: 0,
				} as DependentState;
			}

			const list = uniqBy(prop('customerId') as any, [...people]);
			if (dependant) {
				list.push(dependant);
			}

			return list;
		})
	);

	trustsFromCRMAndCRT$ = combineLatest([
		this.secondaryTrusts$,
		this.trusts$,
	]).pipe(
		map(([sct, trusts]) => {
			const s = sct
				? (sct?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const mapItem = [
				...s,
				...trusts?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return mapItem.filter((o) =>
				trusts && trusts.length > 0
					? !trusts.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	trustsFromCRMAndCRTNoFilter$ = combineLatest([
		this.secondaryTrusts$,
		this.trusts$,
	]).pipe(
		map(([sct, trusts]) => {
			const s = sct
				? (sct?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const mapItem = [
				...s,
				...trusts?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return mapItem;
		})
	);

	companyFromCRMAndCRT$ = combineLatest([
		this.secondaryCompanies$,
		this.company$,
	]).pipe(
		map(([scc, company]) => {
			const s = scc
				? (scc?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const mapItem = [
				...s,
				...company?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return mapItem.filter((o) =>
				company && company.length > 0
					? !company.find((c) => o.customerID === c.customerId)
					: o
			);
		})
	);

	companyFromCRMAndCRTNoFilter$ = combineLatest([
		this.secondaryCompanies$,
		this.company$,
	]).pipe(
		map(([scc, company]) => {
			const s = scc
				? (scc?.map(objectUtil.mapPascalCaseToCamelCase) as any)
				: [];

			const mapItem = [
				...s,
				...company?.map((x) => {
					return {
						...x,
						customerID: x.customerId,
					};
				}),
			];
			return mapItem;
		})
	);

	/*
	 * @returns observable of Risk Analysis
	 */
	riskAnalysisLife$ = this.select((state) =>
		state.riskAnalysisLife.filter((x) => !!x.parentCRTId || +x.parentCRTId > 0)
	);
	riskAnalysisTpd$ = this.select((state) =>
		state.riskAnalysisTpd.filter((x) => !!x.parentCRTId || +x.parentCRTId > 0)
	);
	riskAnalysisMedical$ = this.select((state) => state.riskAnalysisMedical);

	/*
	 * @returns observable of SOA
	 */
	soaSettings$ = this.select((state) => state.soaSettings);
	hasSoaChanges$ = this.select((state) => state.hasSoaChanges);
	triggerLeaveSoa$ = this.select((state) => state.triggerLeaveSoa);

	intro$ = this.select((state) => state.intro);
	declarationDocument$ = this.select((state) => state.declarationDocument);
	declarationEmailSettings$ = this.select(
		(state) => state.declarationEmailSettings
	);
	adviceProcess$ = this.select((state) => state.adviceProcess);

	clientAcceptance$ = this.select((x) => x.clientAcceptance);
	caAlternativeStructure$ = this.select((x) => x.caAlternativeStructure);
	isLoadingClientAcceptance$ = this.select((x) => x.isLoadingClientAcceptance);
	caSignature$ = this.select((x) => x.caSignature);

	finalStructure$ = this.select((x) => x.finalStructure);
	fsProposedInsurance$ = this.select((x) => x.fsProposedInsurance);
	isLoadingFinalStructure$ = this.select((x) => x.isLoadingFinalStructure);
	isUpdatingFinalStructure$ = this.select((x) => x.isUpdatingFinalStructure);

	isAdviceProcessEnded$ = this.adviceProcess$.pipe(
		map((ap) => ap?.status > 2 && ap?.status < 5)
	);

	pciAndSciFromCRM$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
	]).pipe(
		map(([pci, sci]) => {
			const combine = [
				...((sci as any) ?? []).map(objectUtil.mapPascalCaseToCamelCase),
				{ ...(objectUtil.mapPascalCaseToCamelCase(pci) as any) },
			];

			const lifeAssured = combine.map((x) =>
				ViewDisplayValue.Map(
					`${x.customerID}`,
					`${x.firstName?.concat(' ', x.lastName)}`
				)
			) as ViewDisplayValue[];

			return lifeAssured;
		})
	);

	hastFormChanges$ = this.select((x) => x.hasFormChanges);

	peopleEntities$ = combineLatest([
		this.people$,
		this.dependents$,
		this.trusts$,
		this.company$,
	]).pipe(
		map(([people, dependents, trusts, companies]) => {
			const p = (people ?? []).map((x) =>
				ViewDisplayValue.Map(x.customerId, `${x.name}`)
			);
			const d = (dependents ?? []).map((x) =>
				ViewDisplayValue.Map(x.customerId, `${x.name}`)
			);
			const t = (trusts ?? []).map((x) =>
				ViewDisplayValue.Map(x.customerId, `${x.name}`)
			);
			const c = (companies ?? []).map((x) =>
				ViewDisplayValue.Map(x.customerId, `${x.name}`)
			);

			return uniqBy(prop('value'), [...p, ...d, ...t, ...c]);
		})
	);

	/**
	 * @returns <ViewDisplayValue[]> observable list of all deceased SCI and linked contacts
	 */
	deceasedSciList$ = combineLatest([
		this.secondaryClients$,
		this.linkedContacts$,
	]).pipe(
		map(([sci, lci]) => {
			const sciList = ((sci as SecondaryClientState[]) ?? [])
				?.filter((item) => item.contactMethod === 'Deceased')
				?.map((x) =>
					ViewDisplayValue.Map(
						x.customerID?.toString(),
						`${x.firstName} ${x.lastName}`
					)
				);

			const lciList = ((lci as LinkedContactState[]) ?? [])
				?.filter((item) => item.contactMethod === 'Deceased')
				?.map((item) => ({
					...item,
					customerID: item.linkedFromPrimaryCustomer
						? item.relatedCustomerId
						: item.customerId,
				}))
				?.map((x) =>
					ViewDisplayValue.Map(x.customerID?.toString(), `${x.name}`)
				);

			return uniqBy(prop('value'), [...sciList, ...lciList])?.sort(
				(item1, item2) => item1.display?.localeCompare(item2.display)
			) as ViewDisplayValue[];
		})
	);

	findCurrentInsurance$ = (id: number) => {
		return combineLatest([this.currentInsurances$, this.people$]).pipe(
			map(([currentInsurance, people]) => {
				const ppl = (people ?? []).find((x) => x.cRTId === id);
				const curIns = (currentInsurance ?? [])
					.filter((x) =>
						x.lifeAssuredList.some((l) => l.lifeAssured === ppl?.customerId)
					)
					.map((c) => ({
						...c,
						lifeAssuredList: c.lifeAssuredList.filter(
							(la) => la.lifeAssured === ppl?.customerId
						),
					}));

				return curIns ?? [];
			})
		);
	};
}
