import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferClientPopupComponent } from './refer-client-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReferClientPopupService } from './refer-client-popup.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InputSelectModule } from '../input-select/input-select.module';

@NgModule({
	declarations: [ReferClientPopupComponent],
	exports: [ReferClientPopupComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ModalModule,
		InputSelectModule,
	],
	providers: [ReferClientPopupService],
})
export class ReferClientPopupModule {}
