<div class="pd-tracking-widgets d-flex py-4 px-3">
	<app-pdt-donut-widget
		[part]="pdHoursCompleted"
		[total]="annualPersonalDevelopmentHours"
		suffix="Hours"
		label="PD Hours <b>Completed</b> over <br> last 12 months:"
	></app-pdt-donut-widget>

	<app-pdt-info-widget
		[total]="pdHoursScheduled"
		suffix="Hours"
		label="PD Hours <b>Scheduled</b> over <br> next 3 months:"
		[color]="pdHoursScheduled >= annualPersonalDevelopmentHoursQuarter ? 'success' : 'error'"
		icon="timer"
	></app-pdt-info-widget>

	<app-pdt-donut-widget
		[part]="totalCountCompletedPDTracking"
		[total]="totalCountPDTracking"
		suffix="Tests"
		label="Provider Tests Completed:"
	></app-pdt-donut-widget>

	<app-pdt-info-widget
		[total]="totalCountOverduePDTracking"
		suffix="Overdue"
		label="Over Due Requirements:"
		[color]="totalCountOverduePDTracking > 0 ? 'error' : 'success'"
		icon="error_outline"
	></app-pdt-info-widget>
</div>
