import * as moment from 'moment';
import * as numeral from 'numeral';

export const computeDateFromTodayInYears = (date) => {
	if (!date) {
		return 0;
	}
	const result = date?.diff(moment(), 'years', true);
	if (result <= 0) {
		return 0;
	}
	return numeral(result).format('0[.]00');
};
