<div class="row tap-container pt-3">
	<div class="col">
		<tabset>
			<tab heading="Email Settings">
				<form
					[formGroup]="formGroup"
					class="form email-settings"
					(submit)="submit($event)"
				>
					<div class="row pb-0 pl-3 pb-2 pt-4">
						<div class="col-12 col-lg-6">
							<div>
								<div class="row pb-2">
									<div class="col-12 col-lg-5 email-settings__label">
										<label class="form-check-label">CC:</label>
									</div>
									<div class="col-12 col-lg-7 email-settings__textbox">
										<input
											type="text"
											class="form-control theme-crm-field-disabled"
											formControlName="carbonCopy"
										/>
									</div>
								</div>
								<div class="row pb-2">
									<div class="col-12 col-lg-5 email-settings__label">
										<label class="form-check-label">BCC:</label>
									</div>
									<div class="col-12 col-lg-7 email-settings__textbox">
										<input
											type="text"
											class="form-control theme-crm-field-disabled"
											formControlName="blindCarbonCopy"
										/>
									</div>
								</div>
								<div class="row pb-2">
									<div class="col-12 col-lg-5 email-settings__label">
										<label class="form-check-label">Subject:</label>
									</div>
									<div class="col-12 col-lg-7 email-settings__textbox">
										<input
											type="text"
											class="form-control theme-crm-field-disabled"
											formControlName="subject"
										/>
									</div>
								</div>
								<div class="row pb-2">
									<div class="col-12 col-lg-5 email-settings__label">
										<label class="form-check-label">Template ID:</label>
									</div>
									<div class="col-12 col-lg-7 email-settings__textbox">
										<input
											type="text"
											class="form-control theme-crm-field-disabled"
											formControlName="templateID"
										/>
									</div>
								</div>
								<div class="row pb-2">
									<div class="col-12 col-lg-5 email-settings__label">
										<label class="form-check-label">Unsubscribe Group ID</label>
									</div>
									<div class="col-12 col-lg-7 email-settings__textbox">
										<input
											type="text"
											class="form-control theme-crm-field-disabled"
											formControlName="unsubscribeGroupID"
										/>
									</div>
								</div>

								<div class="row email-settings__wysiwyg">
									<div class="col-12 col-lg-12 email-settings__label">
										<label class="form-check-label">Body:</label>
									</div>
									<div class="col-12 col-lg-12 mt-2 email-settings__wysiwyg">
										<app-wysiwyg
											#contentEditor
											[content]="bodyContent"
											[simpleEditor]="true"
											[extendOptions]="optionsWysiswyg"
										></app-wysiwyg>
									</div>
								</div>
							</div>

							<div class="row pb-0 pl-3 pt-2 mb-4">
								<div class="col-12 text-right">
									<app-manual-loader-mini
										*ngIf="isSaving"
										[isLoading]="true"
									></app-manual-loader-mini>
									<button
										*ngIf="!isSaving"
										type="button"
										class="btn btn-primary my-2 tap-bg-primary"
										[attr.disabled]="isLoading ? '' : null"
										(click)="update()"
									>
										SAVE
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</tab>
			<tab heading="Merge tags">
				<div class="row pb-0 pl-3 pb-2 pt-4 cp-settings-merge-tags">
					<div class="col-12">
						<div class="row mb-4 cp-settings-merge-tags__header">
							<div class="col-12 col-lg-3 d-none d-lg-block">
								<label
									class="cp-settings-merge-tags__header__tags font-weight-bolder"
									>Merge Tags</label
								>
							</div>
							<div class="col-12 col-lg-4 d-none d-lg-block">
								<label
									class="cp-settings-merge-tags__header__tags font-weight-bolder"
									>Description</label
								>
							</div>
							<div class="col-12 col-lg-4 d-none d-lg-block">
								<label
									class="cp-settings-merge-tags__header__tags font-weight-bolder"
									>Sample</label
								>
							</div>
						</div>

						<div *ngFor="let item of mergeTags$ | async">
							<div class="row mb-4 cp-settings-merge-tags__values">
								<div
									class="col-12 col-lg-3 cp-settings-merge-tags__values__tags text-truncate"
								>
									%{{ item.metaKey }}%
								</div>
								<div
									class="col-12 col-lg-4 cp-settings-merge-tags__values__description"
								>
									{{ item.description }}
								</div>
								<div
									class="col-12 col-lg-4 cp-settings-merge-tags__values__sample"
									*ngIf="item.value"
								>
									{{ item.value }}
								</div>
								<div
									class="col-12 col-lg-4 cp-settings-merge-tags__values__sample font-italic"
									*ngIf="!item.value && item.secondaryValue"
								>
									ex. {{ item.secondaryValue }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</tab>
		</tabset>
	</div>
</div>
