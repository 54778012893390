import MomentUtil from 'src/app/util/moment.util';
// import { PropertyPurchaseDetails } from '../../crt-mortgage/application/application-steps/funding-required/property-purchase/state/property-purchase.model';
// import { PropertyAssetCustomerService } from 'src/app/shared/models/services/property-asset/property-asset';

export class PropertyPurchaseFormMapper {
	static mapToView(v: any) {
		return !!v
			? {
					propertyAddress: v.PropertyAddress,
					propertyUse: v.PropertyUse ? v.PropertyUse : v.Use,
					financeDate: MomentUtil.formatDateToMoment(v.FinanceDate),
					purchaseDate: MomentUtil.formatDateToMoment(v.PurchaseDate),
					propertyValue: v.PropertyValue ? v.PropertyValue : v.GRM1Value,
					valuationType: v.ValuationType ? v.ValuationType : v.Valuation1,
					valuationTypeDate: v.ValuationTypeDate ? MomentUtil.formatDateToMoment(v.ValuationTypeDate) : MomentUtil.formatDateToMoment(v.GRM1Date),
					propertyOwner:
						!!v.PropertyOwner && typeof v.PropertyOwner === 'string'
							? JSON.parse(v.PropertyOwner)
							: [],
					rentalIncome: v.RentalIncome,
					rentalIncomeFrequency: v.RentalIncomeFrequency,
					boarderIncome: v.BoarderIncome,
					boarderIncomeFrequency: v.BoarderIncomeFrequency,
					title: v.Title,
					rates: v.Rates,
					ratesFrequency: v.RatesFrequency,
					type: v.Type,
					insurance: v.Insurance,
					insuranceFrequency: v.InsuranceFrequency,
					otherExpense: v.OtherExpense,
					otherExpenseFrequency: v.OtherExpenseFrequency,
					isTBC: v.IsTBC
			  }
			: {};
	}
}
