import {
	Component,
	OnInit,
	Input,
	ViewChild,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	ElementRef,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
	ValidationErrors,
} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, zip, of, Observer } from 'rxjs';
import { strUtil } from '../../../util/util';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { KiwisaverMapper } from '../../models/services/kiwisaver/kiwisaver.mapper';
import { KiwisaverState } from '../../models/services/kiwisaver/kiwisaver.model';
import { ArchiveModalComponent } from '../../modal/archive-modal/archive-modal.component';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import {
	take,
	map,
	withLatestFrom,
	filter,
	tap,
	takeUntil,
} from 'rxjs/operators';
import { patchValue } from '../service-utils/service.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from '../../date-input/date-input.component';
import { UserQuery } from 'src/app/domain/user/user.query';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import * as R from 'ramda';
import {
	logMessage,
	Fields,
	getInvalidWarning,
	getRequiredWarning,
} from '../../error-message/error-message';
import { KiwiSaverTypes } from '../../models/services/kiwisaver/kiwisaver.model';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ComponentBase } from '@core/base/component-base';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { ServicesCodes } from '@shared/models/services/services.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
	selector: 'app-kiwisaver-service-form',
	templateUrl: './kiwisaver-service-form.component.html',
	styleUrls: ['./kiwisaver-service-form.component.scss'],
})
export class KiwisaverServiceFormComponent
	extends ComponentBase
	implements OnInit, OnChanges, OnDestroy
{
	public bsModalRef: BsModalRef;

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() archiveEvent = new EventEmitter<any>();
	@Output() deleteNoteEvent = new EventEmitter<any>();
	@Output() cancelAddEvent = new EventEmitter<boolean>();
	@Output() addNoteEvent = new EventEmitter<any>();

	@Input() isLead: boolean;
	@Input() isCompany: boolean;
	@Input() addMode: boolean;

	@Input() ks: KiwisaverState;

	@Input() providers: ViewDisplayValue[];
	@Input() fundTypes: ViewDisplayValue[];
	@Input() statuses: ViewDisplayValue[];
	@Input() origins: ViewDisplayValue[];
	@Input() fundOwners: ViewDisplayValue[];
	@Input() types: ViewDisplayValue[];
	@Input() policyOwners: ViewDisplayValue[];
	@Input() pirRates: ViewDisplayValue[];

	@Input() groupName: string;
	@Input() formId: string;
	@Input() isSaving: boolean;

	@Input() clientsInvolved: any[];
	@Input() propertyOwners: ViewDisplayValue[];
	@Input() retentionStatuses: ViewDisplayValue[];
	fundOwnersDropdown: ViewDisplayValue[];

	@ViewChild('submittedDateInput') submittedDateInput: DateInputComponent;
	@ViewChild('startDateInput') startDateInput: DateInputComponent;
	@ViewChild('startTransInput') startTransInput: DateInputComponent;
	@ViewChild('cancellationDateInput') cancellationDateInput: DateInputComponent;

	isEditForm = false;
	notes: any[];

	ksTypes = KiwiSaverTypes;

	form: UntypedFormGroup = this.fb.group({
		customerServiceID: '',
		fundOwner: ['', [Validators.required]],
		provider: ['', [Validators.required]],
		status: ['', [Validators.required]],
		memberNumber: ['', [Validators.required, NoWhitespaceValidator]],
		startDate: '',
		submittedDate: '',
		fundType: ['', [Validators.required]],
		iRDNumber: ['', [Validators.required, NoWhitespaceValidator]],
		contribution: ['', [Validators.required, NoWhitespaceValidator]],
		fUM: null,
		origin: ['', [Validators.required]],
		originalAdviser: ['', [Validators.required]],
		pIRRate: '',
		note: '',

		notes: [],
		color: '',
		isActive: '',
		documentLink: '',
		startTrans: '',
		cancellationDate:'',
		retentionStatus:''
	});

	get originalAdviser() {
		return this.form.get('originalAdviser');
	}

	get fundOwner() {
		return this.form?.get('fundOwner');
	}

	get provider() {
		return this.form.get('provider');
	}

	get status() {
		return this.form.get('status');
	}

	get memberNumber() {
		return this.form.get('memberNumber');
	}

	get fundType() {
		return this.form?.get('fundType');
	}

	get iRDNumber() {
		return this.form.get('iRDNumber');
	}

	get contribution() {
		return this.form.get('contribution');
	}

	get origin() {
		return this.form.get('origin');
	}

	get customerServiceID() {
		return this.form.get('customerServiceID');
	}

	get cancellationDate() {
		return this.form.get('cancellationDate');
	}

	notesHeader = [
		{ title: 'Created By' },
		{ title: 'Created date & Time' },
		{ title: 'Details' },
		{ title: ' ' },
	];

	@ViewChild('formProp') formProp: ElementRef;

	hasPermission = this.userQuery.hasPermission$;

	advisers$ = this.blStaffsQuery.adviserChoicesOption$;

	originalAdvisers$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.advisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								+x.value === (this.ks ? this.ks.originalAdviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all
		)
	);

	emailClientTemplateFeature$ =
		this.businessConfigQuery.emailClientTemplateFeature$;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private loggerService: LoggerService,
		private userQuery: UserQuery,
		private blStaffsQuery: BLStaffsQuery,
		private clientService: ClientProfileService,
		private businessService: BusinessProfileService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.isSaving && !this.isSaving) {
				this.prepData();
			}

			if (changes.ks) {
				if (changes.ks.previousValue?.notes.length === this.ks.notes.length) {
					if (
						changes.ks.previousValue &&
						changes.ks.currentValue &&
						changes.ks.previousValue.notes !== changes.ks.currentValue.notes
					) {
						this.prepData();
					} else {
						setTimeout(() => {
							this.prepData();
						}, 700);
					}
				} else {
					this.notes = this.ks.notes;
					// this.form.patchValue({ notes: this.ks.notes });
					this.prepData();
				}

				this.isSaving = false;
			}
		}
		this.setFundOwners();
	}

	ngOnInit() {
		this.addMode ? this.form.enable() : this.form.disable();
		this.fundOwner.setValidators([Validators.required, this.validateFundOwner]);

		if (this.addMode) {
			zip(of(this.providers), of(this.origins), of(this.statuses), of(this.retentionStatuses))
				.pipe(
					take(1),
					map((ddListList) => {
						const defaultValueList: string[] = ddListList
							?.map((ddList) => ddList?.find((dd) => dd.isDefault))
							?.map((def) => def && def.value);
						return defaultValueList;
					})
				)
				.subscribe(this.setDropdownDefaults);
		}

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap(() => this.setFundOwners()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	setFundOwners() {
		this.fundOwnersDropdown = this.getOwnerChoices(this.fundOwner.value);
	}

	getOwnerChoices(owners: (string | number)[]) {
		if (this.isCompany) {
			return this.businessService
				.getOwnerChoices(owners || [], this.fundOwners)
				?.map((x) => ({ ...x, value: +x?.value }));
		}
		return this.clientService.getOwnerChoices(owners || [], this.fundOwners);
	}

	compareFn(v1, v2): boolean {
		return +v1 === +v2;
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([p, o, st, rs]) => {
		patchValue<any>(this.form, {
			provider: p,
			origin: o,
			status: st,
			retentionStatus:rs,
		});
	};

	refresh() {
		this.cd.detectChanges();
	}

	prepData() {
		this.notes =
			this.ks && this.ks.notes && this.ks.notes.length > 0
				? this.ks.notes
				: null;
		this.form.reset(KiwisaverMapper.mapToView(this.ks));
	}

	formSaving(isSaving: boolean) {
		this.isSaving = isSaving;
		this.refresh();
	}

	handleTypeChange() {
		this.fundOwner.updateValueAndValidity();
	}

	validateFundOwner = (): ValidationErrors | null => {
		if (R.isNil(this.fundOwner?.value)) {
			return null;
		}

		const numOfOwners = this.fundOwner.value?.length;
		if (numOfOwners === 1) {
			return null;
		} else {
			return numOfOwners > 1
				? { maxReached: true, required: true }
				: { required: true };
		}
	};

	save() {
		if (
			!this.form.valid ||
			this.submittedDateInput?.isInvalid() ||
			this.startDateInput?.isInvalid() ||
			this.startTransInput?.isInvalid() ||
			this.cancellationDateInput?.isInvalid()
		) {
			if (
				R.isNil(this.form.value.fundOwner) ||
				R.isEmpty(this.form.value.fundOwner)
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.FundOwner));
				return;
			}
			if (!this.form.value.provider) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Provider));
				return;
			}
			if (!this.form.value.status) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Status));
				return;
			}
			if (
				this.form.value.memberNumber?.trim() === '' ||
				!this.form.value.memberNumber
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.MemberNumber));
				return;
			}
			if (!this.form.value.fundType.length) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.FundType));
				return;
			}
			if (
				this.form.value.contribution?.trim() === '' ||
				!this.form.value.contribution
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Contribution));
				return;
			}
			if (
				this.form.value.iRDNumber?.trim() === '' ||
				!this.form.value.iRDNumber
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.IRDNumber));
				return;
			}
			if (!this.form.value.origin) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Origin));
				return;
			}

			if (this.submittedDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.SubmittedDate));
				return;
			}
			if (this.startDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.StartDate));
				return;
			}
			if (this.startTransInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.CommissionDate));
				return;
			}
			if (this.cancellationDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.CancellationDate));
				return;
			}
			if (!this.form.value?.originalAdviser) {
				this.loggerService.Warning(
					{},
					getRequiredWarning(Fields.OriginalAdviser)
				);
				return;
			}

			if (this.fundOwner.hasError('maxReached')) {
				this.loggerService.Warning(
					{},
					logMessage.crm.services.kiwiSaver.warning.fundOwnerMax
				);
				return;
			}
		}

		if (isNaN(this.form.value.fUM)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.FUM));
			return;
		}

		const form = this.form.value;

		// Commenting this validation at the moment as per TAPNZ-11076
		// const kiwisaverList = this.query.getValue().kiwiSavers;
		// const check = kiwisaverList
		// 	.filter(
		// 		(k) =>
		// 			!form?.customerServiceID ||
		// 			(form?.customerServiceID &&
		// 				k.customerServiceID !== form?.customerServiceID)
		// 	)
		// 	.find((kiwisaver) => {
		// 		return kiwisaver.fundOwner.includes(form.fundOwner[0]);
		// 	});
		//
		// if (check) {
		// 	this.loggerService.Warning(
		// 		{},
		// 		logMessage.crm.services.kiwiSaver.warning.fundOwnerExist
		// 	);
		// 	return;
		// }

		if (this.addMode) {
			this.isSaving = true;
		}

		form.customerServiceID = this.ks ? this.ks.customerServiceID : '';
		form.isActive = this.addMode ? 1 : this.ks.isActive;
		form.note = form.note;

		const data = KiwisaverMapper.mapToUpsert(form);
		this.saveEvent.emit(data);
	}

	archive(isArchive) {
		const msg = this.ks.isActive === 1 ? 'archive' : 'unarchive';
		const initState: any = {
			confirm$: new Observable((obs) => {
				const form = this.form.value;
				form.note = strUtil.safeTrim(form.note);
				const data = KiwisaverMapper.mapToUpsert(form);

				this.archiveEvent.emit({ ks: data, type: 'kiwisaver', isArchive });
				obs.complete();
			}),
			header: 'Archive service',
			message: `Are you sure you want to ${msg} this item?`,
			isArchive: isArchive ? true : false,
		};
		this.modalService.show(ArchiveModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	cancel() {
		if (this.addMode) {
			const confirm = new Observable((obs: Observer<any>) => {
				if (this.addMode) {
					this.cancelAddEvent.emit(false);
				}
				this.isEditForm = false;
				this.prepData();
				this.form.disable();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: 'Discard Confirmation',
				message: `Current information will be discarded?`,
				confirm$: confirm,
				decline$: decline,
			};

			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.isEditForm = false;
			this.prepData();
			this.form.disable();

			const data = KiwisaverMapper.mapToView(this.form.value);

			if (!data.submittedDate['_i']) {
				this.submittedDateInput.reset();
			}
			if (!data.startDate['_i']) {
				this.startDateInput.reset();
			}
		}
	}

	editForm(edit: boolean) {
		this.isEditForm = edit;
		edit ? this.form.enable() : this.form.disable();
	}

	deleteConfirm() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const initState = {
			header: 'Delete',
			message: `Are you sure you want to delete ?`,
			delete$: confirm,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	delete() {
		this.isSaving = true;
		const form = this.form.value;
		const data = KiwisaverMapper.mapToUpsert(form);

		this.deleteEvent.emit(data);
	}

	deleteNote$ = (data) => {
		return new Observable((obs) => {
			this.isSaving = true;
			const form = this.form.value;
			const mappedForm = KiwisaverMapper.mapToUpsert(form);

			this.deleteNoteEvent.emit({ noteId: data.notesID, data: mappedForm });
			obs.next();
			obs.complete();
		});
	};

	openModalAddNote() {
		const saveNote = (notes: string) =>
			new Observable((obs) => {
				const form = KiwisaverMapper.mapToView(this.ks);
				form.note = notes;
				this.addNoteEvent.emit(form);
				obs.next();
				obs.complete();
			});
		const initState: any = {
			header: 'Add Note',
			savefn: saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	trackByValue(index, item: ViewDisplayValue) {
		return item.value;
	}

	openEmailPopup() {
		if (this.isCompany) {
			this.openEmailBusinessPopup();
		} else {
			this.openEmailClientPopup();
		}
	}

	resetCancellationDate(event){
		if(event !== 'Cancelled' &&  this.cancellationDate.value !==''){
			this.form.patchValue({cancellationDate:''});
		}
	}

	private openEmailBusinessPopup() {
		this.businessService.openECModalSubject$.next({
			data: this.form.value,
			documentType: ServicesCodes.KiwiSaver,
			type: ServicesCodes.NotesKiwisaver,
			referenceId: this.customerServiceID.value || 0,
		});
	}

	private openEmailClientPopup(): void {
		this.clientService.openECModalSubject$.next({
			data: this.form.value,
			documentType: ServicesCodes.KiwiSaver,
			type: ServicesCodes.NotesKiwisaver,
			referenceId: this.customerServiceID.value || 0,
		});
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
