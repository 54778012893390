import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject, Observable, ReplaySubject, Observer, of } from 'rxjs';
import { strUtil } from '../../../../../util/util';
import { BusinessMapper } from '../../../../../shared/models/business-profile/business/business.mapper';
import { PrimaryCustomerCompanyState } from '../../../../../shared/models/business-profile/business/business.model';
import { takeUntil, map, tap, take, catchError, switchMap, finalize } from 'rxjs/operators';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { TablePaginateNotesComponent } from '../../../../../shared/table-paginate-notes/table-paginate-notes.component';
import { NoteState } from '../../../../../shared/models/notes/note.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { ObservableUtil } from '../../../../../util/observable.util';
import { ClientSearchControlComponent } from '../../../../../shared/search-controls/client-search-control/client-search-control.component';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { BusinessProfileService } from '../../states/business-profile.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { Fields, getRequiredWarning } from 'src/app/shared/error-message/error-message';
import { EmailDuplicateService } from '@core/services/email-duplicate.service';
import { prop, uniqBy } from 'ramda';

@Component({
	selector: 'app-form-company',
	templateUrl: './form-company.component.html',
})
export class FormCompanyComponent implements OnInit, OnDestroy, OnChanges {
	private onDestroy$ = new Subject<void>();

	checkIfUserIsLinkedSubject: Subject<any> = new Subject();

	@Input() isLead: boolean;
	@Input() addMode: boolean;

	@Input() business: PrimaryCustomerCompanyState;
	@Input() LS: any;

	@Input() leadTypeChoices: ViewDisplayValue[];
	@Input() leadOriginChoices: ViewDisplayValue[];
	@Input() leadGenChoices: ViewDisplayValue[];
	@Input() adviserGenChoices: ViewDisplayValue[];
	@Input() allStaffChoices: ViewDisplayValue[];

	@Input() updateFn$: (data: any) => Observable<any>;

	// Notes
	@Input() getNotesFn$: (req: any) => Observable<any>;
	@Input() addNotesFn$: (req: any) => Observable<any>;
	@Input() deleteNotesFn$: (req: any) => Observable<any>;

	private _isSaving = false;
	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	isSaving$ = new ReplaySubject<boolean>(1);

	private _isEdit = false;
	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	isEdit$ = new ReplaySubject<boolean>(1);

	allAdviserGenChoices: ViewDisplayValue[] = [];

	@ViewChild(ClientSearchControlComponent) referredInput: ClientSearchControlComponent;
	@ViewChild(TablePaginateNotesComponent) noteTable: TablePaginateNotesComponent;

	form: UntypedFormGroup = this.fb.group({
		tradingName: [''],
		industry: [''],
		website: [''],

		annualTurnover: [''],
		iRDNumber: [''],

		primaryContact: [null, [Validators.required]],
		mobile: [''],
		work: [''],

		email: [''],
		alternateEmail: [''],
		physicalAddress: [''],
		mailingAddress1: [''],
		mailingAddress2: [''],
		city: [''],
		postcode: [''],

		leadType: [''],
		leadOrigin: [''],
		leadGen: [''],
		adviserGen: [''],

		customerType: [CustomerTypes.PrimaryCustomerCompany?.toString()],

		note: [CustomerTypes.PrimaryCustomerCompany?.toString()],
	});

	notes: { notes: NoteState[]; count: number };

	constructor(
		private fb: UntypedFormBuilder,
		private profile: BusinessProfileService,
		private modalService: BsModalService,
		private loggerService: LoggerService,
		private emailDupService: EmailDuplicateService
	) {}

	ngOnInit() {
		this.setAdviserGenChoices();
		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$.pipe(takeUntil(this.onDestroy$)).subscribe(x => (this._isSaving = x));
	}

	ngOnChanges() {
		if (this.addMode) {
			this.isEdit$.next(true);
			this.form.reset();
		} else {
      this.isEdit$.next(true);
      const data = this.business
      ? Object.assign(new BusinessMapper(), BusinessMapper.mapToView(this.business))
      : Object.assign(new BusinessMapper(), this.form.value);
			   this.form.reset(data);
			   this.isEdit$.next(false);
		}
		this.setAdviserGenChoices();
	}

	setAdviserGenChoices() {
		const adviserGenId = this.form.getRawValue().adviserGen;
		const isAdviserGenOnDropdown = this.adviserGenChoices?.find(
			(x) => +x?.value === +adviserGenId
		);
		if (!!isAdviserGenOnDropdown) {
			this.allAdviserGenChoices = uniqBy(prop('value'), [
				...this.adviserGenChoices,
			]) as ViewDisplayValue[];
			return;
		}
		const getAdviserGen = this.allStaffChoices?.find(
			(x) => +x?.value === +adviserGenId
		);
		
		const list = [...this.adviserGenChoices, getAdviserGen]
			?.filter(Boolean)
			?.sort((a, b) => a.display?.localeCompare(b.display));

		this.allAdviserGenChoices = uniqBy(prop('value'), [
			...list,
		]) as ViewDisplayValue[];
	}

	addNotes$ = note => {
		return this.addNotesFn$({
			customerID: note.customerID,
			customerServiceID: note.customerServiceID,
			notes: note.notes,
		});
	};

	deleteNotes$ = (note: NoteState) => {
		return this.deleteNotesFn$(note);
	};

	getNotes$ = (req: GetNotes) => {
		return this.getNotesFn$(req).pipe(
			tap(x => {
				this.notes = x;
			})
		);
	};

	connectControlIsInvalid = (keyName: keyof PrimaryCustomerCompanyState) => {
		const control = this.form.get(keyName);
		const statusChanges$ = control.statusChanges;
		return ObservableUtil.connectBehavior(statusChanges$, control.status).pipe(map(x => x === 'INVALID'));
	};

	// tslint:disable-next-line: member-ordering
	primaryContactInvalid$ = this.connectControlIsInvalid('primaryContact');

	get primaryContact() {
		return this.form.get('primaryContact');
	}

	prepareFormValue() {
		const form = this.form.value;

		return {
			...form,
			tradingName: strUtil.safeTrimExtraSpace(form.tradingName),
			industry: strUtil.safeTrimExtraSpace(form.industry),
			website: strUtil.safeTrimExtraSpace(form.website),
			annualTurnover: strUtil.safeTrimExtraSpace(form.annualTurnover),
			iRDNumber: strUtil.safeTrimExtraSpace(form.iRDNumber),

			mobile: strUtil.removeSpace(form.mobile),
			work: strUtil.removeSpace(form.work),
			email: strUtil.removeSpace(form.email),
			alternateEmail: strUtil.removeSpace(form.alternateEmail),
			physicalAddress: strUtil.safeTrimExtraSpace(form.physicalAddress),

			mailingAddress1: strUtil.safeTrimExtraSpace(form.mailingAddress1),
			mailingAddress2: strUtil.safeTrimExtraSpace(form.mailingAddress2),
			city: strUtil.safeTrimExtraSpace(form.city),
			postcode: strUtil.safeTrimExtraSpace(form.postcode),

			customerType: CustomerTypes.PrimaryCustomerCompany?.toString(),
		};
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	save() {
		if (!this.form.valid) {
			if (!this.prepareFormValue().primaryContact) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.PrimaryContact));
				return;
			}
		}

		const data = BusinessMapper.mapToUpsert({ ...this.business, ...this.prepareFormValue() });
		const declineCb = ()=>{
			this.isEdit$.next(true);
		}
		const confirmCb = () => {
			this.isSaving$.next(true);
			this.isEdit$.next(false);
			data['saveAnyway'] = true;
			return this.updateFn$(data).pipe(finalize(()=>{this.isSaving$.next(false); this.isEdit$.next(false);}));
		}
		this.isSaving$.next(true);
		this.isEdit$.next(false);
		this.updateFn$(data)
			.pipe(
				catchError((err)=>of(err)),
				switchMap((res)=>{
					if(res.hasOwnProperty('DuplicateEmail')){
						const ulMessage = res['DuplicateEmail'];
						this.emailDupService.displayDuplicateDialog(ulMessage, confirmCb, declineCb);
					}
					return of(res);
				}),
				take(1))
			.subscribe({
				next: res => {
					if(!res.hasOwnProperty('DuplicateEmail')){
						this.isEdit$.next(false);
					}
				},
				error: err => {
					this.isEdit$.next(true);
				},
				complete: () => {
					this.isSaving$.next(false);
				}
			});
	}
	edit() {
		this.toggleEdit(true);
	}

	cancel() {
		if (!this.addMode) {
			const data = this.business
				? Object.assign(new BusinessMapper(), BusinessMapper.mapToView(this.business))
				: Object.assign(new BusinessMapper(), this.form.value);
			this.form.reset(data);
		}
		this.toggleEdit(false);
	}

	checkIfLinkedFn$ = () => {
		const data = BusinessMapper.mapToUpsert({ ...this.business, ...this.prepareFormValue() });
		return this.profile.listOfServices(+data.customerID).pipe(
			tap(x => {
				const message = [];
				if (x) {
					x.lrs?.forEach(lr => {
						lr.customerServices?.forEach(cs => {
							const provider =
								cs.provider + ': ' + cs.policyNumber + (cs.policyNumberSuffix ? ' - ' + cs.policyNumberSuffix : '');

							message.push(`L&R Policy Owner of ${provider}`);
						});
					});

					x.mortgages?.forEach(m => {
						m.customerServices?.forEach(cs => {
							const provider =
								cs.provider + ': ' + cs.loanNumber + (cs.loanNumberSuffix ? ' - ' + cs.loanNumberSuffix : '');

							message.push(`Borrowing Entity of ${provider}`);
						});
					});

					x.propertyAssets?.forEach(pa => {
						message.push(`Property Owner of ${pa.propertyAddress}`);
					});

					x.fgs?.forEach(fg => {
						fg.customerServices?.forEach(cs => {
							const provider =
								cs.insurer + ': ' + cs.fGPolicyNumber + (cs.fGPolicyNumberSuffix ? ' - ' + cs.fGPolicyNumberSuffix : '');

							message.push(`F&G Policy Owner of ${provider}`);
						});
					});

					x.kiwiSavers?.forEach(ks => {
						message.push(`Fund Owner of ${ks.provider}`);
					});
				}

				const confirm = new Observable((obs: Observer<any>) => {
					obs.complete();
				});
				const initState = {
					header: 'Delete customer',
					message: x
						? `'${data.companyName}' is a ${message?.join(', ')}, Kindly remove ${
								message && message.length > 1 ? 'these' : 'this'
						  } before deleting.`
						: `Are you sure you want to delete '${data.companyName}'?`,
					delete$: confirm,
					canDelete: x ? false : true,
				};

				this.modalService.show(DeleteModalComponent, {
					class: 'modal-dialog-centered',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false
				});
			}),
			take(1)
		);
	};

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
