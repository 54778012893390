import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { BlStaffViewmodel } from '@modules/user/viewmodels/bl-staff.viewmodel';
import {
	BlStaffSettingTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'app-user-documents',
	templateUrl: './user-documents.component.html',
})
export class UserDocumentsComponent implements OnInit, OnChanges {
	@Input() staff: BlStaffViewmodel;
	@Input() staffSettings: BlStaffSettingsModel[];
	@Input() isTapLevel: boolean;
	@Input() userSecurityGroup: string;
	@Input() upsertStaffDocumentFn$: (
		data: BlStaffSettingsModel
	) => Observable<BlStaffSettingsModel> = (data) => of(data);
	@Input() removeStaffDocumentFn$: (
		staffId: number,
		settingsId: number
	) => Observable<any> = (data) => of(data);
	@Input() downloadDocumentFn$: (data: {
		documentId: number;
		fileName: string;
	}) => Observable<any> = (data) => of(data);
	@Input() getUserDocumentsFn$: (data: number) => Observable<any> = (data) =>
		of(data);
	@Input() deleteUserDocumentsFn$: (data: number) => Observable<any> = (data) =>
		of(data);

	onBoardingDocuments: BlStaffSettingsModel[];
	userSpecificDocuments: BlStaffSettingsModel[];

	constructor() {}

	ngOnInit(): void {
		this.setDocuments(this.staffSettings);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.staffSettings) {
			this.setDocuments(this.staffSettings);
		}
	}

	setDocuments(data: BlStaffSettingsModel[]) {
		this.onBoardingDocuments = data?.filter(
			(x) =>
				x?.SettingsCode === BlStaffSettingTypes.OnBoardingDocuments ||
				x?.SettingsCode === BlStaffSettingTypes.OtherOnBoardingDocuments
		);
		this.userSpecificDocuments = data?.filter(
			(x) =>
				x?.SettingsCode === BlStaffSettingTypes.OnBoardingDocuments ||
				x?.SettingsCode === BlStaffSettingTypes.OtherOnBoardingDocuments
		);
	}
}
