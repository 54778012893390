import {
	Component,
	OnInit,
	EventEmitter,
	Output,
	Input,
	OnDestroy,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
	UntypedFormArray,
} from '@angular/forms';
import { BehaviorSubject, of, zip, Subject, Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { ViewDisplayValue } from '@models/_general/display-value.viewmodel';
import { PrimaryClientState } from '@models/client-profile/primary-client/primary-client.model';
import { patchValue } from '../../../../../../shared/services/service-utils/service.util';
import { IncomeSourceMapper } from '@models/client-review-template/income-budget/income-source.mapper';
import { PeopleDetailsState } from '@models/client-review-template/people/people-details.model';
import { SecondaryClientState } from '@models/client-profile/secondary-client/secondary-client.model';
import { PeopleService } from '@modules/crm/client-review-template/states/people/people.service';
import { LoggerService } from '@loggerService';
import { CustomerService } from '@core/customer/customer.service';
import { objectUtil } from '@util/util';
import { PeopleState } from '@models/client-review-template/people/people.model';
import { IncomeSourceState } from '@models/client-review-template/income-budget/income-source-details.model';
import { CustomerTypes } from '@models/_general/client.model';
import { logMessage } from '@errorMsgs';
import { BusinessService } from '@core/business/business.service';
import { complement, either, find, isEmpty, isNil, omit, propEq } from 'ramda';

@Component({
	selector: 'app-employment-form',
	templateUrl: './employment-form.html',
	styleUrls: ['./employment-form.scss'],
})
export class EmploymentFormComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Output() saveEvent = new EventEmitter<any>();
	@Input() incomeFrequency: ViewDisplayValue[];
	@Input() kiwiSaverContribution: ViewDisplayValue[];
	@Input() taxCode: ViewDisplayValue[];
	@Input() employment: ViewDisplayValue[];
	@Input() industry: ViewDisplayValue[];
	@Input() incomeSourceType: ViewDisplayValue[];
	@Input() incomeType: ViewDisplayValue[];
	@Input() primaryClient: PrimaryClientState;
	@Input() secondaryClients: SecondaryClientState[];
	@Input() people: PeopleDetailsState[];
	@Input() transferedSCI: PeopleState[];
	@Input() isAdd: boolean;
	@Input() cRTId: number;
	@Input() adviceProcessId: number;
	@Input() incomeSources: IncomeSourceState[];
	@Input() peopleFromCRMAndCRTExceptChild$: Observable<any[]>;
	@Input() removeFactFindComputation: boolean;
	@Input() showTimeInJob: boolean;
	@Input() viewMode: boolean;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	public bsModalRef: BsModalRef;

	form: UntypedFormGroup;

	productForm: UntypedFormGroup;

	personInfo: PrimaryClientState;
	peopleList: ViewDisplayValue[];
	defaultFrequencyValue = new BehaviorSubject('');
	dataArraySalary = [];
	demoArraySalary = [];
	arrayItemsSalary: {
		id: number;
		title: string;
	}[];
	cRTIncomeEarner: number;

	submitted = false;
	isLoading = false;
	showTrash = false;
	showAdd = false;
	idExpense = 0;
	totalGross = 0;

	staticLabel = {
		employment: 'Full-time Employed',
		frequency: this.defaultFrequencyValue.value || 'Annually',
		sourceType: 'Salary / Wages',
		primaryIncome: 'Primary',
		secondaryIncome: 'Secondary',
	};

	@Input() loading;
	cancelLoading: boolean;

	constructor(
		private fb: UntypedFormBuilder,
		private peopleService: PeopleService,
		private loggerService: LoggerService,
		private customerService: CustomerService,
		private businessService: BusinessService
	) {
		this.buildForm();
	}

	get Occupation() {
		return this.form.get('occupation');
	}
	get IncomeEarner() {
		return this.form.get('incomeEarner');
	}
	get IncomeType() {
		return this.form.get('incomeType');
	}
	get Employment() {
		return this.form.get('employment');
	}
	get IncomeSourceValue() {
		return this.form.get('incomeSourceValue');
	}
	get IncomeSourceFrequency() {
		return this.form.get('incomeSourceFrequency');
	}
	get KiwiSaverContribution() {
		return this.form.get('kiwiSaverContribution');
	}
	get DetailsList() {
		return this.form.get('detailsList') as UntypedFormArray;
	}

	addSellingPoint(data?: any, showAdd?: boolean) {
		this.DetailsList.push(
			this.fb.group({
				incomeSourceType: [
					(data && data.incomeSourceType) || this.staticLabel.sourceType,
				],
				incomeSourceValue: [
					(data && data.incomeSourceValue) || '',
					[Validators.min(0)],
				],
				incomeSourceFrequency: [
					(data && data.incomeSourceFrequency) || this.staticLabel.frequency,
					Validators.required,
				],

				btnAdd: showAdd || false,
				btnDelete: true,
			})
		);

		this.DetailsList.controls[0].get('btnDelete').setValue(true);
	}

	deleteSellingPoint(index) {
		this.DetailsList.removeAt(index);

		this.DetailsList.controls[0].get('btnAdd').setValue(true);
		this.DetailsList.controls[0].get('btnDelete').setValue(true);

		if (this.DetailsList.controls.length === 1) {
			this.DetailsList.controls[0].get('btnAdd').setValue(true);
			this.DetailsList.controls[0].get('btnDelete').setValue(false);
		}
	}

	buildForm() {
		this.showAdd = true;
		this.showTrash = false;

		this.form = this.fb.group({
			incomeEarner: ['', Validators.required],
			incomeType: [{ value: '', disabled: false }, Validators.required],
			employment: ['', Validators.required],
			employer: [''],
			industry: [''],
			occupation: [''],
			kiwiSaverContribution: ['', Validators.required],
			details: [''],
			detailsList: this.fb.array([]),

			visaDetails: [''],
			hours: [''],
			years: [''],
			manual: [''],
			months: [''],
		});
	}

	ngOnInit(): void {
		this.businessService.loatv2Enabled$.pipe(take(1)).subscribe((enabled) => {
			if (enabled) {
				this.submitted = true;
			}
		});

		this.mapDefaultValues();
		this.idExpense++;
		this.showAdd = true;
		this.showTrash = false;

		this.arrayItemsSalary = [];

		const obj = [
			{
				id: this.idExpense,
				title: '',
				btnAdd: this.showAdd,
				btnDelete: this.showTrash,
			},
		];

		this.dataArraySalary.push(...obj);
		if (this.isAdd) {
			this.getPeopleList();
			this.addSellingPoint(null, true);
		} else {
			this.isLoading = true;
			this.peopleService
				.getPersonInfo(+this.cRTId)
				.pipe(withLatestFrom(this.peopleFromCRMAndCRTExceptChild$), take(1))
				.subscribe(
					([x, people]) => {
						const data = IncomeSourceMapper.mapToView(x);
						if (x.incomeType === this.staticLabel.primaryIncome) {
							this.cRTIncomeEarner = x?.incomeEarner;
						}
						const hasPrimaryIncome = this.hasPrimaryIncome(x?.incomeEarner);

						this.peopleList = this.policyOwnerChoices(
							x?.incomeEarner,
							this.mapPeople(people)
						);

						this.form.reset(data);

						if (
							(!hasPrimaryIncome ||
							!this.IncomeType.value ||
							(hasPrimaryIncome && x.incomeType === this.staticLabel.primaryIncome)) &&
							!this.viewMode
						) {

							this.enableIncomeType(true);
						} else {
							this.enableIncomeType(false);
						}

						x.detailsList?.map((val, i) => this.addSellingPoint(val, i === 0));
						this.isLoading = false;
						this.disableViewMode();
					},
					(err) => {
						this.isLoading = false;
					}
				);
		}
	}

	hasPrimaryIncome(incomeEarner: number) {
		const hasPrimary = this.incomeSources?.some(
			(is) =>
				+is.incomeEarner === +incomeEarner &&
				is.incomeType === this.staticLabel.primaryIncome
		);
		return !this.isAdd && this.cRTIncomeEarner === incomeEarner
			? false
			: hasPrimary;
	}

	enableIncomeType(status: boolean) {
		setTimeout(() => {
			status
				? this.IncomeType.enable({ onlySelf: true })
				: this.IncomeType.disable({ onlySelf: true });
		}, 100);
	}

	disableViewMode() {
		setTimeout(() => {
			if (this.viewMode) {
				this.form.disable();
			}
		}, 0);
	}

	getPeopleList(id?: string | number) {
		this.peopleFromCRMAndCRTExceptChild$.pipe(take(1)).subscribe((x) => {
			this.peopleList = this.policyOwnerChoices(id, this.mapPeople(x));
		});
	}

	mapPeople(data) {
		return [
			...IncomeSourceMapper.mapCRMPeopleToDd(data),
			...IncomeSourceMapper.mapCRTPeopleToDd(this.people),
		];
	}

	trackByFn(_index: number) {
		return _index;
	}

	policyOwnerChoices(owners: string | number, peopleList) {
		return this.getOwnerChoices(!!owners ? [owners] : [], peopleList);
	}

	mapDefaultValues() {
		of(this.incomeFrequency)
			.pipe(
				take(1),
				map((v: ViewDisplayValue[]) => v?.find((p) => p.isDefault))
			)
			.subscribe((x) => this.defaultFrequencyValue.next(x.value));

		zip(of(this.incomeType))
			.pipe(
				take(1),
				map((ddListList) => {
					const defaultValueList: string[] = ddListList
						?.map((ddList) => ddList?.find((dd) => dd.isDefault))
						?.map((def) => def && def.value);
					return defaultValueList;
				})
			)
			.subscribe(this.setDropdownDefaults);
	}

	selectIncomeType() {
		const hasPrimaryIncome = this.hasPrimaryIncome(+this.IncomeEarner.value);
		if (this.IncomeType.value === this.staticLabel.primaryIncome) {
			this.setEmploymentDetails();
		} else {
			this.clearEmploymentSalary();
		}

		setTimeout(() => {
			if (!this.IncomeType.value) {
				this.enableIncomeType(true);
			} else {
				this.enableIncomeType(!hasPrimaryIncome);
			}
		}, 100);
	}

	selectEmployment() {
		const employment = this.Employment.value;
		if (employment === this.staticLabel.employment) {
			const incomeEarner = this.IncomeEarner.value;
			const hasPrimaryIncome = this.hasPrimaryIncome(incomeEarner);
			if (incomeEarner) {
				this.setIncomeType(hasPrimaryIncome);
				if (!hasPrimaryIncome) {
					if (this.IncomeType.value === this.staticLabel.primaryIncome) {
						this.setEmploymentDetails(true);
					} else {
						this.clearEmploymentSalary();
					}
					this.Employment.setValue(employment);
				}
			}
		}
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray?.removeAt(0);
		}
	};

	setDropdownDefaults: (defaultValues: string[]) => void = ([it]) => {
		patchValue<any>(this.form, {
			incomeType: it,
		});
	};

	save() {
		if (this.cancelLoading) {
			return;
		}
		this.submitted = true;
		if (this.form.invalid) {
			this.loggerService.Warning(
				{},
				logMessage.shared.general.warning.required
			);
			return;
		}

		let data = IncomeSourceMapper.mapToUpsert(
			this.form.getRawValue(),
			+this.cRTId,
			+this.adviceProcessId
		);

		if (!!this.removeFactFindComputation) {
			data = omit(['factFindComputation'], data);
		}

		this.saveEvent.emit(data);
	}

	getPersonInfo(event) {
		if (!event.target.value) {
			return;
		}
		const targetVal = +event.target.value;
		// tslint:disable-next-line: no-angle-bracket-type-assertion
		const selected = <any>(
			// tslint:disable-next-line: no-angle-bracket-type-assertion
			find(propEq('value', +targetVal))(<any>this.peopleList)
		);
		const customerType = selected?.customerType;
		this.isLoading = true;
		if (
			customerType === CustomerTypes.SecondaryCustomerIndividual &&
			this.isSciInvalid(+targetVal)
		) {
			// If SCI is transfered, do not fetch CRM Info
			this.getCrtInfo(+selected?.value, +targetVal);
		} else {
			this.getContactInfo(+targetVal);
		}
	}

	getContactInfo(id: number) {
		this.customerService
			.GetPrimaryClient(+id)
			.pipe(take(1))
			.subscribe(
				(x) => {
					this.setPersonInfo(x, id);
					this.isLoading = false;
				},
				(err) => {
					this.isLoading = false;
				}
			);
	}

	getCrtInfo(id: number, incomeEarner: number) {
		const pid = find(propEq('customerId', +id))(
			this.people as any
		) as PeopleDetailsState;
		this.peopleService
			.getPersonInfo(+pid?.cRTId)
			.pipe(take(1))
			.subscribe(
				(x) => {
					this.setPersonInfo(x, incomeEarner);
					this.isLoading = false;
				},
				(err) => {
					this.isLoading = false;
				}
			);
	}

	isSciInvalid(id: number) {
		const sci = this.transferedSCI?.filter((x) => +x?.customerId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	setPersonInfo(data, incomeEarner: number) {
		if (!this.isAdd) {
			this.getPeopleList(incomeEarner);
		}
		this.personInfo = objectUtil.mapPascalCaseToCamelCase(data);
		const hasPrimaryIncome = this.hasPrimaryIncome(incomeEarner);
		this.setIncomeType(hasPrimaryIncome);

		if (this.IncomeType.value === this.staticLabel.primaryIncome) {
			this.setEmploymentDetails();
		} else {
			this.clearEmploymentSalary();
		}

		setTimeout(() => {
			this.isLoading = false;
			if (!this.IncomeType.value) {
				this.enableIncomeType(true);
			} else {
				this.enableIncomeType(!hasPrimaryIncome);
			}
		}, 100);
	}

	clearEmploymentSalary() {
		this.form.patchValue({
			employment: '',
			employer: '',
			industry: '',
			occupation: '',
		});
		this.clearFormArray(this.DetailsList);
		this.addSellingPoint(null, true);
	}

	setIncomeType(hasPrimaryIncome: boolean) {
		this.form.patchValue({
			incomeType: hasPrimaryIncome
				? this.staticLabel?.secondaryIncome
				: this.staticLabel?.primaryIncome,
		});
	}

	setEmploymentDetails(isFullTime?: boolean) {
		if (
			this.Employment.value === this.staticLabel.employment ||
			this.IncomeType.value === this.staticLabel.primaryIncome
		) {
			this.setOccupationIndustry(this.personInfo, isFullTime);
			this.setIncomeArray(this.personInfo);
		}
	}

	setIncomeArray(data) {
		if (either(isNil, isEmpty)(data?.income)) {
			return;
		}
		this.clearFormArray(this.DetailsList);
		this.addSellingPoint(
			{
				incomeSourceType: this.staticLabel.sourceType,
				incomeSourceValue: data?.income || '',
				incomeSourceFrequency: this.staticLabel.frequency,
			},
			true
		);
	}

	setOccupationIndustry(data, isFullTime?: boolean) {
		const formValue = this.form.getRawValue();
		this.form.patchValue({
			employment: isFullTime
				? this.staticLabel.employment
				: data?.employment || '',
			employer: data?.employer || formValue?.employer,
			industry: data?.industry || formValue?.industry,
			occupation: data?.occupation || formValue?.occupation,
		});
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => (this.cancelLoading = true), 500);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
