import { DownloadableDocumentModel } from './templates-utils/templates.model';

const signature = (people) => {
	let result = [];
	for (let v of people) {
		result.push(`
    <p><span style="font-size: 12px;"><br></span></p>
    <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Name: ____________________________ Signed: _____________________________</span></span></p>
    <p><span style="font-size: 12px;"><br></span></p>
    `);
	}
	return result.join('');
};

const signatureDefault = () => {
	return `
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Name: ____________________________ Signed: _____________________________</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  `;
};

export const createGiftingLetterTemplate = (
	data: DownloadableDocumentModel
) => {
	const peopleName =
		data.peopleName && data.peopleName.length > 0
			? data.peopleName.join(', ')
			: '';

	return `
  <p style="text-align: center;">
    <span style="font-size: 12px; font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
      <img src="${
				data.businessLogo
			}" alt="Business Logo" style="width: 250px; height: 70px;"/>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p>
    <span style="font-size: 12px;">
      <span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
        Date: ${data.dateToday}
      </span>
    </span>
  </p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 24px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Gifting Statement</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">To whom it may concern:</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">
  I, ____________________________ of _____________________________________, ____________________________ occupation ________________________ hereby declare that I am gifting the sum of $${
		data.depositGift && data.depositGift > 0 ? data.depositGift : 0
	} to ${peopleName} for the sole purpose of purchasing a property and do furthermore declare that no repayments of principal or interest are required and that no subsequent charges will be registered against the security property.&nbsp;</span></span></p>
  <p><span style="font-size: 12px;"><br></span></p>
  <p><span style="font-size: 12px;"><span style="font-family: Arial; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal;">Yours sincerely,&nbsp;</span></span></p>
  ${
		data.peopleName && data.peopleName.length > 0
			? signature(data.peopleName)
			: signatureDefault()
	}
  `;
};
