import { StoreConfig, Store } from '@datorama/akita';
import { EBConversion } from './ebc.model';
import { produce } from 'immer';
import { Injectable } from "@angular/core";

export interface EBCState {
  EBConversion: EBConversion[];
  error: string;
  isLoading: boolean;
}

export function CreateInitialState(): EBCState {
  return {
    EBConversion: null,
    error: '',
    isLoading: false
  };
}

@Injectable()
@StoreConfig({
  name: 'EBConversion'
})
export class EBCStore extends Store<EBCState> {
  constructor() {
    super(CreateInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(
      produce(draft => {
        draft.isLoading = isLoading;
      })
    );
  }
}
