import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { KiwiSaverStore, KiwiSaverState } from './kiwiSaver.store';

@Injectable()
export class KiwiSaverQuery extends QueryEntity<KiwiSaverState> {
	kiwiSavers$ = this.selectAll({ filterBy: (x) => !!x.status });

	constructor(protected store: KiwiSaverStore) {
		super(store);
	}
}
