import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import { ChartType, ChartEvent } from 'ng-chartist';
import {
	IChartistData,
	IPieChartOptions,
	IChartistAnimationOptions,
} from 'chartist';
import * as R from 'ramda';
import { numUtil } from '../../util/util';
import { Subject } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';
import { BusinessConfigQuery } from '../../domain/business-config/business-config.query';
import * as numeral from 'numeral';

/**
 * @description
 * Reusable donut chart component.
 * Shows a donut with fraction in the center.
 */
@Component({
	selector: 'app-donut-chart',
	templateUrl: './donut-chart.component.html',
	styleUrls: ['./donut-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent implements OnInit, OnChanges, OnDestroy {
	/**
	 * Donut width
	 */
	private readonly width = 30;
	/**
	 * Color of the gap from part to total
	 */
	private readonly gapStroke = 'transparent'; // Donut gap filler color (stroke)

	/**
	 * Title of the donut
	 */
	@Input() headerTitle: string;
	/**
	 * The numerator of the fraction.
	 * change to array if need be.
	 */
	@Input() part: number;
	/**
	 * Total of the fraction and donut.
	 */
	@Input() total: number;
	/**
	 * Indicator if Currency sign should show.
	 */
	@Input() showCurrencySign = false;
	/**
	 * Initial stroke
	 */
	@Input() strokeColor:
		| 'primarycolor'
		| 'secondarycolor'
		| 'tertiarycolor'
		| 'success'
		| 'error'; // additional colors added ticket ref TAPNZ-13091

	/**
	 * Indicates what suffix should be appended after the part and total values
	 */
	@Input() customSuffix;

	@Input() isDecimal = false;

	/**
	 * Chartist data. Used by ng-chartist
	 */
	data: IChartistData = {
		series: [],
	};
	/**
	 * Type of chartist to generate.
	 * Could be readonly?
	 */
	type: ChartType = 'Pie';
	/**
	 * Chartist pie chart options
	 */
	options: IPieChartOptions = {
		donut: true,
		donutWidth: this.width,
		showLabel: false,
		startAngle: 360,
		width: 174,
		ignoreEmptyValues: true,
		height: 174,
	};
	/**
	 * Sort of lifecycle hook for chartist.
	 */
	events: ChartEvent;

	/**
	 * Chartist needs another data in series to render the gap.
	 * Computed in ngOnChanges. fill = total - part
	 */
	fill: number;
	/**
	 * Sum of series when part becomes an array.
	 */
	seriesSum: number;
	/**
	 * Count of series
	 */
	seriesCount: number;
	/**
	 * Stroke colors
	 */
	strokes: string[] = [];

	displaySum = '';
	displayTotal = '';

	/** Observable that should trigger unsubscription to others */
	onDestroy$ = new Subject<void>();

	/** Theme config object */
	themeConfig$ = this.businessConfigQuery.themeConfig$.pipe(
		filter((x) => !!x),
		takeUntil(this.onDestroy$)
	);

	constructor(
		private cd: ChangeDetectorRef,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	/**
	 * For Manual refresh. Chartist doesn't expose its renderChart method
	 * so we'll refresh the data instead
	 */
	refresh(): void {
		this.data = R.clone(this.data);
		this.cd.detectChanges();
	}

	/**
	 * Compute variables needed by chartist whenever component input changes.
	 * @param changes combined changed component input properties in an object
	 *
	 * * compute graph necessary data
	 * * compute display data
	 */
	ngOnChanges(changes: SimpleChanges): void {
		this.data = { ...this.data, series: [this.part] };

		this.seriesCount = this.data.series.length;
		this.fill = 0;
		this.seriesSum = R.sum(this.data.series as Array<number>);

		if (this.total) {
			this.fill = this.total - this.seriesSum;
			if (this.fill > 0) {
				this.data = {
					...this.data,
					series: [...this.data.series, this.fill]?.filter(
						(x) => x !== 0
					) as Array<number>,
				};
				this.seriesCount = this.data.series.length;
			}
			this.options = { ...this.options, total: this.total };
		}

		this.displaySum = this.showCurrencySign
			? numeral(this.seriesSum).format('$0,0')
			: this.isDecimal && !Number.isInteger(this.seriesSum)
			? numUtil.formatToCountDecimal(this.seriesSum)
			: numUtil.formatToCount(this.seriesSum);

		this.displayTotal = this.showCurrencySign
			? numeral(this.total).format('$0,0')
			: this.isDecimal && !Number.isInteger(this.total)
			? numUtil.formatToCountDecimal(this.total)
			: numUtil.formatToCount(this.total);

		this.renderColor();
	}

	/**
	 * Setup chartist event
	 */
	ngOnInit() {
		this.renderColor();

		this.events = {
			draw: (data) => {
				if (data.type === 'slice') {
					const angle = data.endAngle - data.startAngle;
					const dur = (angle / 360) * 1000;

					const pathLength = data.element._node.getTotalLength();

					data.element.attr({
						'stroke-dasharray': pathLength + 'px ' + pathLength + 'px',
					});

					const animationDefinition = {
						'stroke-dashoffset': {
							id: 'anim' + data.index,
							dur,
							from: -pathLength + 'px',
							to: '0px',
							easing: 'easeOutCirc',
							fill: 'freeze',
						} as IChartistAnimationOptions,
					};

					if (data.index !== 0) {
						animationDefinition['stroke-dashoffset'].begin =
							'anim' + (data.index - 1) + '.end';
					}

					data.element.attr({
						'stroke-dashoffset': -pathLength + 'px',
					});

					if (data.index <= this.strokes.length - 1) {
						data.element.attr({
							style: `stroke-width: ${this.width}px; stroke: ${
								this.strokes[data.index]
							};`,
						});
					}

					if (this.fill > 0 && data.index === this.seriesCount - 1) {
						data.element.attr({
							style: `stroke-width: ${this.width}px; stroke: ${this.gapStroke};`,
						});
					}

					data.element.animate(animationDefinition, false);
				}
			},
		};
	}

	renderColor() {
		this.themeConfig$.pipe(take(1)).subscribe((x) => {
			const widgetColour1 =
				x.widgetcolor1 && x.widgetcolor1 !== ''
					? x.widgetcolor1
					: x.primarycolor;
			const widgetColour2 =
				x.widgetcolor2 && x.widgetcolor2 !== ''
					? x.widgetcolor2
					: x.secondarycolor;
			const tertiaryColor = x.tertiarycolor;
			this.strokes = [
				widgetColour2,
				widgetColour1,
				'#616161',
				'#c0c0c0',
				'#6cbe46',
				'#ed2a24',
			];
			if (this.strokeColor === 'primarycolor') {
				this.strokes = this.strokes?.splice(1, 1);
				this.strokes?.unshift(widgetColour1);
			}
			if (this.strokeColor === 'tertiarycolor') {
				this.strokes = this.strokes?.splice(0, 1);
				this.strokes?.unshift(tertiaryColor);
			}
			if (this.strokeColor === 'success') {
				this.strokes = this.strokes?.splice(0, 1);
				this.strokes?.unshift('#6cbe46');
			}
			if (this.strokeColor === 'error') {
				this.strokes = this.strokes?.splice(0, 1);
				this.strokes?.unshift('#ed2a24');
			}
		});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
