import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map as Rmap, flatten, sort, addIndex, forEach } from 'ramda';
import { Observable, Observer, of, Subject } from 'rxjs';
import {
	exhaustMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap
} from 'rxjs/operators';
import { SOASubSectionCodes } from '../../../../../../shared/models/advice-process/advice-process.model';
import { LifeAssuredListState, NewExistingStatus } from '../../../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { BusinessConfigQuery } from '../../../../../../domain/business-config/business-config.query';
import { CurrentInsuranceModalComponent } from '../../../../../../shared/modal/crt/fact-find/current-insurance/current-insurance-modal.component';
import { CurrentInsuranceMapper } from '../../../../../../shared/models/client-review-template/current-insurance/current-insurance.mapper';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { ProposedInsuranceState } from '../../../../../../shared/models/client-review-template/statement-of-advice/insurance/soa-insurance.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { ClientReviewTemplateQuery } from '../../../states/client-review-template.query';
import { ClientReviewTemplateService } from '../../../states/client-review-template.service';
import { StatementOfAdviceQuery } from '../../../states/statement-of-advice/statement-of-advice.query';
import { StatementOfAdviceService } from '../../../states/statement-of-advice/statement-of-advice.service';
import { StatementOfAdviceStore } from '../../../states/statement-of-advice/statement-of-advice.store';
import { ProductsWithPerMonth } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';
import { util } from 'src/app/util/util';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-soa-proposed-insurance',
	templateUrl: './proposed-insurance.component.html',
	styleUrls: ['./proposed-insurance.component.scss'],
})
export class SoaProposedInsuranceComponent implements OnInit {
	@Input() parentCRTId: number;
	@Input() viewMode: string;
	@Output() nextPage = new EventEmitter<void>();
	@Output() backPage = new EventEmitter<void>();
	@Input() parent: any;

	documents: any[];

	providerHeader: string;

	onDestroy$ = new Subject<void>();
	themeConfig$ = this.businessConfigQuery.themeConfig$;
	primaryColor: string;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	transferedSCI: PeopleState[];

	lifeAssured$ = this.query.peopleFromCrmAndCrtExceptChildChoices$;

	APCRTF$ = this.service.APCRTF$;
	APCRTB$ = this.service.APCRTB$;
	APCRTNE$ = this.service.APCRTNE$;
	LRP$ = this.service.LRP$;
	LRPR$ = this.service.LRPR$;
	people$ = this.service.peopleEntities$.pipe(
		map(people => people?.filter(person => +person.value)
	));

	currentInsuranceLifeAssured$ = this.query.currentInsurances$.pipe(
		map((x) => x || []),
		map((x) => Rmap((l) => sort((a, b) => a.priority - b.priority, l?.lifeAssuredList) ?? [], x)),
    map((x) => flatten(x)),
		map((x) => {
			const indexedForEach = addIndex(forEach);
			return indexedForEach((lifeAssured: LifeAssuredListState, index) => lifeAssured.id = index, x);
    })
	);

	lifeAssuredList: ViewDisplayValue[] = [];
	elseMinusProvider = true;
	isAddEditMode = false;

	proposedInsurances$ = this.soaQuery.soaPI$.pipe(
		map((x) => this.parsePolicyDocuments(x)),
		map((x) =>
			(x ?? []).map((pi) => {
				const lifeAssured = pi?.lifeAssured?.map((la) => ({
					...la,
					allowDropdown: this.allowDropdown(la?.product),
					lifeAssuredName: this.convertLifeAssuredToName(la?.lifeAssured),
					showPerMonth: this.showPerMonth(la?.product),
				}));
				return {
					...pi,
					lifeAssured,
					lifeAssuredList: lifeAssured,
					sumOfLifeAssured: this.getSumOfLifeAssuredList(pi),
				};
			})
		)
	);

	constructor(
		private businessConfigQuery: BusinessConfigQuery,
		private modalService: BsModalService,
		private service: ClientReviewTemplateService,
		private query: ClientReviewTemplateQuery,
		private statementOfAdviceService: StatementOfAdviceService,
		private soaStore: StatementOfAdviceStore,
		protected soaQuery: StatementOfAdviceQuery,
		private renderer: Renderer2,
    private route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.query.transferedSCIList$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.transferedSCI = x));

		this.service.lifeAssured$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.lifeAssuredList = x));

		this.statementOfAdviceService
			.getProposedInsurance(this.parentCRTId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	addProposedInsurance = (model: ProposedInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			switchMap((x) =>
				this.statementOfAdviceService.addProposedInsurance(x, this.parentCRTId)
			),
			switchMap((crtId) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.statementOfAdviceService
							.uploadDocument(model, crtId, +this.route.snapshot.params.clientId)
							.pipe(map(() => crtId))
					: of(crtId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const proposedInsurance = this.soaQuery.getValue().proposedInsurance;
					const m = proposedInsurance?.find((ci) => ci.cRTId === crtId);
					return this.statementOfAdviceService.updateProposedInsurance(
						m,
						this.parentCRTId
					);
				}
				return of(crtId);
			}),
			map((x) => ({...model, cRTId: +x})),
			finalize(() => this.isAddEditMode = false)
		);

	updateProposedInsurance = (model: ProposedInsuranceState) => {
		return new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((x) => {
				return this.statementOfAdviceService.updateProposedInsurance(
					x,
					this.parentCRTId
				)
			}),
			switchMap((crtId) => {
				return model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.statementOfAdviceService
							.uploadDocument(model, model.cRTId, +this.route.snapshot.params.clientId)
							.pipe(map(() => crtId))
					: of(crtId)
			}),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const proposedInsurance = this.soaQuery.getValue().proposedInsurance;
					const m = proposedInsurance?.find((ci) => ci.cRTId === model.cRTId);
					return this.statementOfAdviceService.updateProposedInsurance(
						m,
						this.parentCRTId
					);
				}
				return of(crtId);
			}),
			map(() => model),
			finalize(() => this.isAddEditMode = false)
		);
	}

	addProduct() {
		this.isAddEditMode = true;
		this.parent.checkSoaForUpdate(this.parentCRTId).pipe(
			filter((x) => !!x),
			tap(() => {
				const decline = new Observable((obs: Observer<any>) => {
					this.isAddEditMode = false;
					obs.complete();
				});
				const initState = {
					header: 'Proposed Insurance',
					message: `Proposed Insurance`,
					apcrtf$: this.APCRTF$,
					apcrtb$: this.APCRTB$,
					apcrtne$: this.APCRTNE$,
					lrp$: this.LRP$,
					lrpr$: this.LRPR$,
					policyOwners$: this.people$,
					lifeAssuredList$: this.lifeAssured$,
					getOwnerChoices: this.service.getOwnerChoices,
					currentInsuranceLifeAssured$: this.currentInsuranceLifeAssured$,
					isProposedInsurance: true,
					defaultPaymentFrequency: this.soaQuery.getActive()?.paymentFrequency,
					defaultNewExistingValue: NewExistingStatus.New,
					savefn: this.addProposedInsurance,
					decline$: decline,
					hideNotes: true,
				};
				this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
					class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
				});
			}),
			take(1)
		).subscribe();
	}

	editProduct(insurance: ProposedInsuranceState) {
		this.isAddEditMode = true;
		this.parent.checkSoaForUpdate(this.parentCRTId).pipe(
			filter((x) => !!x),
			tap(() => {
				const decline = new Observable((obs: Observer<any>) => {
					this.isAddEditMode = false;
					obs.complete();
				});
				const initState = {
					header: 'Proposed Insurance',
					message: `Proposed Insurance`,
					apcrtf$: this.APCRTF$,
					apcrtb$: this.APCRTB$,
					apcrtne$: this.APCRTNE$,
					lrp$: this.LRP$,
					lrpr$: this.LRPR$,
					policyOwners$: this.people$,
					lifeAssuredList$: this.lifeAssured$,
					getOwnerChoices: this.service.getOwnerChoices,
					currentInsuranceInfo: insurance,
					currentInsuranceLifeAssured$: this.currentInsuranceLifeAssured$,
					isProposedInsurance: true,
					defaultPaymentFrequency: this.soaQuery.getActive()?.paymentFrequency,
					defaultNewExistingValue: NewExistingStatus.New,
					savefn: this.updateProposedInsurance,
					decline$: decline,
					hideNotes: true,
				};
				this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
					class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
				});
			}),
			take(1)
		).subscribe();
	}

	deleteProposedInsurance(id: number) {
		this.parent.checkSoaForUpdate(this.parentCRTId).pipe(
			filter((x) => !!x),
			mergeMap(() => this.statementOfAdviceService.deleteProposedInsurance(id)),
			take(1)
		).subscribe();
	}

	convertLifeAssuredToName(id: number) {
		const name = CurrentInsuranceMapper.getLifeAssuredName(
			id,
			this.lifeAssuredList,
			this.transferedSCI
		);

		if (!name) {
			return (
				this.query
					.getValue()
					.allSecondaryClients?.filter((x) => +x.customerID === +id)
					?.map((sci) => sci.firstName.concat(' ', sci.lastName)) ?? ''
			);
		}

		return name || '';
	}

	getSumOfLifeAssuredList(lifeAssured: ProposedInsuranceState) {
		return (
			lifeAssured?.lifeAssured?.reduce(
				(a, b) => Number(a) + Number(b.premium),
				0
			) +
			(!lifeAssured?.policyFee || lifeAssured?.policyFee === 'N/A'
				? 0
				: +lifeAssured?.policyFee)
		);
	}

	allowDropdown = (product: string) =>
		product
			? [
					'Medical Base Plan',
					'Specialists & Tests',
					'Medical',
					'Medical + S&T',
					'Dental & Optical'
			  ].includes(product)
			: false;

	next() {
		this.statementOfAdviceService
			.updateSoa(this.parentCRTId, SOASubSectionCodes.Documents)
			.pipe(
				take(1),
        finalize(() => this.soaStore.updateActive({ currentPage: SOASubSectionCodes.Documents }))
      )
			.subscribe();
	}

	downloadLink(id: number) {
		this.statementOfAdviceService
			.downloadLink(id)
			.pipe(
				tap((x) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
				}),
				take(1)
			)
			.subscribe();
	}

	showPerMonth = (product: string) => ProductsWithPerMonth.includes(product);

	parsePolicyDocuments(data: ProposedInsuranceState[]) {
		return (data || [])?.map((item) => ({
			...item,
			policyDocuments:
				item?.policyDocuments && util.tryCatchParse(item?.policyDocuments)
					? JSON.parse(item?.policyDocuments)
					: item?.policyDocuments || [],
		}));
	}
}
