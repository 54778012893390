import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualLoaderMiniComponent } from './manual-loader-mini.component';

const components = [ManualLoaderMiniComponent];

@NgModule({
	declarations: components,
	exports: components,
	imports: [CommonModule],
})
export class ManualLoaderMiniModule {}
