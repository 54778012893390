import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { iif, Observable, of } from 'rxjs';
import {
	catchError,
	finalize,
	map,
	mergeMap,
	switchMap,
	tap,
} from 'rxjs/operators';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from './../../../../../../../../core/base/api.service';
import { AdviceProcessSectionCodes } from './../../../../../../../../shared/models/advice-process/advice-process.model';
import { Security } from './security.model';
import { SecurityQuery } from './security.query';
import { SecurityStore } from './security.store';

@Injectable({ providedIn: 'root' })
export class SecurityService {
	securities$: Observable<Security[]> = this.query.selectAll();
	isLoading$ = this.query.selectLoading();

	constructor(
		private store: SecurityStore,
		private query: SecurityQuery,
		private api: ApiService
	) {}

	clear() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(parentCRTId: number, adviceProcessId: number) {
		this.store.setLoading(true);
		return this.api
			.get<Security[]>(
				`crt/${AdviceProcessSectionCodes.Application}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.Security}`
			)
			.pipe(
				mergeMap((v) =>
					iif(
						() => Boolean(v?.length),
						of(v),
						this.add({
							parentCrtId: parentCRTId,
							adviceProcessId,
							sectionCode: AdviceProcessSectionCodes.Security,
						})
					)
				),
				map((x: any) => x?.map(objectUtil.mapPascalCaseToCamelCase) as Security[]),
				map((x) =>
					x?.map((y) => ({
						...y,
						bankWillTake: y.bankWillTake?.map(
							objectUtil.mapPascalCaseToCamelCase
						),
					}))
				),
				tap((entities) => {
					this.store.set(entities);
				}),
				finalize(() => this.store.setLoading(false)),
				catchError(this.catchError)
			);
	}

	getById(id: number) {
		return this.api.get<Security>(`crt/${id}`).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x) as Security),
			map((x) => ({
				...x,
				bankWillTake: x?.bankWillTake?.map(objectUtil.mapPascalCaseToCamelCase),
			})),
			catchError(this.catchError)
		);
	}

	add(security: Security) {
		const body = {
			...security,
			cRTId: null,
			sectionCode: AdviceProcessSectionCodes.Security,
			bankWillTake: security?.bankWillTake?.map(
				objectUtil.mapCamelCaseToPascalCase
			),
		} as Security;

		return of(true).pipe(
			tap((x) => this.store.setLoading(true)),
			mergeMap((x) =>
				this.api.post<number>(`crt`, objectUtil.mapCamelCaseToPascalCase(body))
			),
			switchMap((x) => this.getById(x)),
			tap((x) => this.store.add(x)),
			finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	update(security: Security) {
		const body = {
			...security,
			sectionCode: AdviceProcessSectionCodes.Security,
			bankWillTake: security?.bankWillTake?.map(
				objectUtil.mapCamelCaseToPascalCase
			),
		};
		return this.api
			.put<number>(
				`crt/${body.cRTId}`,
				objectUtil.mapCamelCaseToPascalCase(body)
			)
			.pipe(
				tap((x) => this.store.upsert(security.cRTId, security)),
				catchError(this.catchError)
			);
	}

	delete(security: number | Security) {
		const cRTId = typeof security === 'number' ? security : security?.cRTId;
		return this.api.delete<string>(`crt/${cRTId}`).pipe(
			tap(() => this.store.remove(cRTId)),
			finalize(() => this.store.setLoading(false)),
			catchError(this.catchError)
		);
	}

	catchError = (x) => of(undefined);
}
