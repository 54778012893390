import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LoanRefinanceStore, LoanRefinanceStateModel } from './loan-refinance.store';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoanRefinanceQuery extends QueryEntity<LoanRefinanceStateModel> {
	loanRefinance$ = this.selectAll();
	activeLoanRefinance$ = this.selectActive();
	isLoadedLoans$ = this.select((state) => state.isLoadedLoans);

  sourceType$ = new BehaviorSubject([{
    text: 'Mortgage',
    value: 'M'
  }, {
    text: 'Liability',
    value: 'L'
  }])

	constructor(protected store: LoanRefinanceStore) {
		super(store);
	}
}
