import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	AfterViewInit,
	OnChanges,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { ReplaySubject, Observer, Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { tap, takeUntil, take, map, finalize, switchMap } from 'rxjs/operators';
import * as R from 'ramda';
import { computeAgeBasedOnBirthdate, strUtil } from '../../../../../util/util';
import { DeleteModalComponent } from '../../../../../shared/modal/delete-modal/delete-modal.component';
import { SecondaryClientState } from '../../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { ClientProfileSecondaryMapper } from '../../../../../shared/models/client-profile/secondary-client/secondary-client.mapper';
import { AddPhotoRequest } from '../../../../../core/customer/customer.service';
import { PrimaryClientState } from '../../../../../shared/models/client-profile/primary-client/primary-client.model';
import { NoteState } from '../../../../../shared/models/notes/note.model';
import { GetNotes } from '../../../../../shared/models/notes/note-params.model';
import { TablePaginateNotesComponent } from '../../../../../shared/table-paginate-notes/table-paginate-notes.component';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { ClientProfileService } from '../../states/client-profile.service';
import { DateInputComponent } from 'src/app/shared/date-input/date-input.component';
import { TransferClientModalComponent } from 'src/app/shared/modal/transfer-client-modal/transfer-client-modal.component';
import { RouteService } from '../../../../../core/config/route.service';
import { CustomerTypes, GetGenderViewDisplay } from 'src/app/shared/models/_general/client.model';
import { Fields, getInvalidWarning, getRequiredWarning } from 'src/app/shared/error-message/error-message';
import { CopyClientModalComponent } from 'src/app/shared/modal/copy-client-modal/copy-client-modal.component';
import { BusinessConfig } from '@domain/business-config/business-config.model';
import { EmailDuplicateService } from '@core/services/email-duplicate.service';

@Component({
	selector: 'app-form-secondary',
	templateUrl: './form-secondary.component.html',
	styleUrls: ['./form-secondary.component.scss'],
})
export class FormSecondaryComponent
	implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;
	@Input() formID: any;
	@Input() isLead: boolean;
	@Input() primaryClient: PrimaryClientState;
	@Input() secondaryClient: SecondaryClientState;
	@Input() addMode: boolean;
	@Input() isAddNew: boolean;
	@Input() disableDelete: boolean;
	@Input() professionalContacts: ViewDisplayValue[];
	@Input() contactMethods: ViewDisplayValue[];
	@Input() businessConfig$: Observable<BusinessConfig>;
	@Input() deleteFn$: (sci: SecondaryClientState) => Observable<any>;
	@Input() updateFn$: (sci: SecondaryClientState) => Observable<any>;
	@Input() saveFn$: (req: SecondaryClientState) => Observable<any>;
	@Input() addPhoto$: (req: AddPhotoRequest) => Observable<any>;
	@Input() transferSCIFn$: (sci: SecondaryClientState) => Observable<any>;
	@Input() copySCIFn$: (id: number) => Observable<any>;

	// Notes
	@Input() getNotesFn$: (req: any) => Observable<any>;
	@Input() addNotesFn$: (req: any) => Observable<any>;
	@Input() deleteNotesFn$: (req: any) => Observable<any>;

	gender: ViewDisplayValue[] = GetGenderViewDisplay();

	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);
	refreshNotes$ = new BehaviorSubject<any>(null);

	@Input() title: ViewDisplayValue[];
	@Input() employment: ViewDisplayValue[];
	@Input() relationship: ViewDisplayValue[];
	@Input() industry: ViewDisplayValue[];

	@Output() cancelEvent = new EventEmitter<boolean>();
	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();

	tableHeads = [
		{ title: 'Created By' },
		{ title: 'Created date & Time' },
		{ title: 'Details' },
		{ title: ' ' },
	];

	form: UntypedFormGroup = this.fb.group({
		title: this.fb.control(''),
		firstName: this.fb.control('', [Validators.required]),
		middleName: this.fb.control(''),
		lastName: this.fb.control('', [Validators.required]),
		gender: this.fb.control(''),

		dateOfBirth: this.fb.control(''),
		mobile: this.fb.control(''),
		work: this.fb.control(''),
		email: this.fb.control(''),
		alternateEmail: this.fb.control(''),

		employment: this.fb.control(''),
		industry: this.fb.control(''),
		occupation: this.fb.control(''),
		income: this.fb.control(null),
		physicalAddress: this.fb.control(''),

		knownAs: this.fb.control(''),
		relationship: this.fb.control(''),

		note: this.fb.control(''),
		professionalContacts: this.fb.control(''),
		contactMethod: this.fb.control(''),
		isVulnerable: false,
		employer: this.fb.control(''),
		countryOfOrigin: this.fb.control(''),
		age: [0],
	});

	notes: { notes: NoteState[]; count: number };

	@ViewChild(TablePaginateNotesComponent)
	noteTable: TablePaginateNotesComponent;
	@ViewChild('dateOfBirthDateInput') dateOfBirthDateInput: DateInputComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private profile: ClientProfileService,
		private loggerService: LoggerService,
		private routeService: RouteService,
		private router: Router,
		private emailDupService: EmailDuplicateService
	) {}

	get firstName() {
		return this.form.get('firstName');
	}

	get lastName() {
		return this.form.get('lastName');
	}

	get age() {
		return this.form.get('age');
	}

	get isKeyContact() {
		// Adviser Rework Field
		return this.form.get('isKeyContact');
	}

	get vulnerableNotes() {
		// Adviser Rework Field
		return this.form.get('vulnerableNotes');
	}

	ngOnInit() {
		this.patchDefaultValues();
		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
		});
		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));
	}

	ngOnChanges(changes?: SimpleChanges) {
		if (this.addMode) {
			this.isEdit$.next(true);
			this.secondaryClient = undefined;
			this.form.reset();
			this.patchDefaultValues();
			this.form.patchValue({
				physicalAddress: this.primaryClient.physicalAddress,
			});
		} else {
			if (
				this.isEdit &&
				changes.secondaryClient?.currentValue?.photoURL !==
					changes.secondaryClient?.previousValue?.photoURL
			) {
				this.isEdit$.next(true);
			} else {
				this.isEdit$.next(false);
				const data = this.secondaryClient
					? Object.assign(
							new ClientProfileSecondaryMapper(),
							ClientProfileSecondaryMapper.mapToView(this.secondaryClient)
					  )
					: Object.assign(new ClientProfileSecondaryMapper(), this.form.value);
				this.form.reset(data);
			}
		}
		if (!this.addMode && !this.isEdit && changes?.secondaryClient  && !changes?.secondaryClient?.firstChange) {
			// Refresh notes when SCI is re-fetched from other tabs of crm profile
			this.refreshNotes$.next(changes);
		}
		this.computeAge();
		this.prepAdviserRework();
	}

	addNotes$ = (note) => {
		return this.addNotesFn$({
			customerID: note.customerID,
			customerServiceID: note.customerServiceID,
			notes: note.notes,
		});
	};

	deleteNotes$ = (note: NoteState) => {
		return this.deleteNotesFn$(note);
	};

	getNotes$ = (req: GetNotes) => {
		return this.getNotesFn$(req).pipe(
			tap((x) => {
				this.notes = x;
			})
		);
	};

	ngAfterViewInit() {}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	patchDefaultValues() {
		if (this.addMode) {
			const findDefault = R.find<ViewDisplayValue>((x) => x.isDefault);
			const getValue = (v: ViewDisplayValue): string =>
				R.propOr('', 'value', v);
			const mapToDefaultValues = R.pipe(R.map(findDefault), R.map(getValue));
			const defaults = mapToDefaultValues([
				this.title,
				this.employment,
				this.relationship,
			]);
			this.setDropdownDefaults(defaults);
		}
		this.computeAge();
	}

	private setDropdownDefaults = ([
		title,
		employment,
		relationship,
	]: string[]): void => {
		// tslint:disable-next-line: no-use-before-declare
		patchValue<SecondaryClientState>(this.form, {
			title,
			employment,
			relationship,
		});
	};

	edit() {
		this.isEdit$.next(true);
	}

	copySecondaryClient(id) {
		if (this.secondaryClient.contactMethod === 'Deceased') {
			return;
		}

		const copy = new Observable((obs: Observer<any>) => {
			this.isSaving$.next(true);
			this.copySCIFn$(id)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					(val) => {
						this.isSaving$.next(false);
						const route = this.routeService.customerView(val);
						this.router.navigate(route);
					},
					(err) => {
						this.isSaving$.next(false);
					},
					() => {
						this.isSaving$.next(false);
					}
				);
				obs.complete();
		});

		const initState = {
			header: 'Copy Customer',
			message: `Are you sure you want to copy '${this.secondaryClient.firstName} ${this.secondaryClient.lastName}'?`,
			copy$: copy
		};

		this.modalService.show(CopyClientModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});

	}

	prepareValue() {
		const data = this.form.value;
		const isDeceased = this.form.value.contactMethod === 'Deceased';

		return {
			...data,
			firstName: strUtil.safeTrimExtraSpace(data.firstName),
			lastName: strUtil.safeTrimExtraSpace(data.lastName),
			middleName: strUtil.safeTrimExtraSpace(data.middleName),
			knownAs: strUtil.safeTrimExtraSpace(data.knownAs),
			countryOfOrigin: strUtil.safeTrimExtraSpace(data.countryOfOrigin),
			occupation: strUtil.safeTrimExtraSpace(data.occupation),
			physicalAddress: strUtil.safeTrimExtraSpace(data.physicalAddress),

			email: isDeceased ? null : strUtil.removeSpace(data.email),
			alternateEmail: isDeceased ? null : strUtil.removeSpace(data.alternateEmail),
			work: isDeceased ? null : strUtil.removeSpace(data.work),
			mobile: isDeceased ? null :  strUtil.removeSpace(data.mobile),
			income: data.income ? +data.income : null,
			customerType: CustomerTypes.SecondaryCustomerIndividual,
		} as SecondaryClientState;
	}

	save() {
		if (
			!this.form.valid ||
			this.prepareValue().firstName?.trim() === '' ||
			this.prepareValue().lastName?.trim() === '' ||
			this.dateOfBirthDateInput?.isInvalid()
		) {
			if (
				this.prepareValue().firstName?.trim() === '' ||
				!this.prepareValue().firstName
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.FirstName));
				return;
			}
			if (
				this.prepareValue().lastName?.trim() === '' ||
				!this.prepareValue().lastName
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.LastName));
				return;
			}
			if (this.dateOfBirthDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.DateOfBirth));
				return;
			}
		}
		if (this.addMode) {
			this.isSaving$.next(true);
			this.isEdit$.next(false);
			const confirmCb = () => {
				this.isSaving$.next(true);
				this.isEdit$.next(false);
				//Add creation service call here to proceed need customer ID
				data['saveAnyway'] = true;
				return this.saveFn$(data).pipe(finalize(()=>{this.addSecondarySuccess();}))
			};
			const declineCb= () =>{
				this.addSecondarySuccess()
			};

			const data = ClientProfileSecondaryMapper.mapToUpsert(this.form.value);
				this.saveFn$(data)
					.pipe(switchMap((res)=>{
						if(res?.hasOwnProperty('errorMessage')){
							const errMessage = res['errorMessage'];
							this.emailDupService.displayDuplicateDialog(errMessage, confirmCb,declineCb);
						}
						return of(res);
					}),
						take(1))
					.subscribe({
						next: (res) => {
							if (!res.hasOwnProperty('errorMessage')) {
								this.addSecondarySuccess();
							}
						},
						error: (err) => {},
						complete: () => {
						}
					});
		} else {
			this.isSaving$.next(true);
			this.isEdit$.next(false);
			const data = {
				...ClientProfileSecondaryMapper.mapToUpsert({
					...this.secondaryClient,
					...this.prepareValue(),
				}),
			};
			const confirmCb = () => {
				this.isSaving$.next(true);
				this.isEdit$.next(false);
				//Add creation service call here to proceed need customer ID
				data['saveAnyway'] = true;
				return this.updateFn$(data).pipe(finalize(()=>{this.updateSecondarySuccess(data);}))
			};
			const declineCb = () =>{
				this.addSecondarySuccess();
			}
			this.updateFn$(data)
				.pipe(
					switchMap((res)=>{
						if(res.hasOwnProperty('errorMessage')){
							const errMessage = res['errorMessage'];
							this.emailDupService.displayDuplicateDialog(errMessage, confirmCb, declineCb);
						}
						return of(res)
					}),
					take(1)
				)
				.subscribe({
					next:(res) => {
						if (!res.hasOwnProperty('errorMessage')) {
							this.updateSecondarySuccess(data);
						}
					},
					error:(err) => {
						this.isEdit$.next(true);
					},
					complete:() => {
						this.isSaving$.next(false);
					}
				});
		}
	}

	addSecondarySuccess(){
		this.isEdit$.next(true);
		this.isSaving$.next(false);
	}

	updateSecondarySuccess(data){
		this.isEdit$.next(false);
		const isDeceased = data.contactMethod === 'Deceased';
		if (isDeceased) {
			this.form.get('email').reset(null);
			this.form.get('alternateEmail').reset(null);
			this.form.get('work').reset(null);
			this.form.get('mobile').reset(null);
		}
		this.isSaving$.next(false);
	}

	prepAdviserRework() {
		this.businessConfig$.pipe(
			map((config) => config?.AdviserRework),
			tap((isEnabled) => {
				if (isEnabled) {
					if (!this.form?.controls?.isKeyContact) {
						this.form.addControl('isKeyContact', this.fb.control(false));
					}
					if (!this.form?.controls?.vulnerableNotes) {
						this.form.addControl('vulnerableNotes', this.fb.control(''));
					}
					const customerId = +this.secondaryClient?.customerID;
					const keyContact = +this.primaryClient?.preferredEmailContact;
					this.isKeyContact.setValue(customerId === keyContact);
					this.vulnerableNotes.setValue(this.secondaryClient?.vulnerableNotes || '');
				}
			}),
			take(1),
		).subscribe();
	}

	cancel() {
		if (!this.addMode) {
			const data = this.secondaryClient
				? Object.assign(
						new ClientProfileSecondaryMapper(),
						ClientProfileSecondaryMapper.mapToView(this.secondaryClient)
				  )
				: Object.assign(new ClientProfileSecondaryMapper(), this.form.value);

			if (!data?.dateOfBirth._i) { this.dateOfBirthDateInput.reset(); }
			this.form.reset(data);
			this.isEdit$.next(false);
			this.prepAdviserRework();
		}
		this.computeAge();
		this.cancelEvent.emit(true);
	}

	delete(data: SecondaryClientState) {
		this.isSaving$.next(true);
		this.deleteFn$(data)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(res) => {},
				(err) => {},
				() => {
					this.isSaving$.next(false);
				}
			);
	}

	confirmDelete() {
		const data = {
			...ClientProfileSecondaryMapper.mapToUpsert(this.secondaryClient),
			...this.prepareValue(),
		};
		this.profile
			.listOfServices(data.customerID)
			.pipe(
				tap((x) => {
					const message = x ? this.validateUser(x) : [];
					const isInvalid = R.complement(R.either(R.isNil, R.isEmpty))(message);
					const confirm = new Observable((obs: Observer<any>) => {
						this.delete(data);
						obs.complete();
					});

					const initState = {
						header: 'Delete customer',
						message: isInvalid
							? `'${data.firstName} ${data.lastName}' is a <br /> ${message
									?.toString()
									?.replace(/,/g, ' ')} Kindly remove ${
									message && message.length > 1 ? 'these' : 'this'
							  } before deleting.`
							: `Are you sure you want to delete '${data.firstName} ${data.lastName}'?`,
						delete$: confirm,
						canDelete: x ? false : true,
					};

					this.modalService.show(DeleteModalComponent, {
						class: 'modal-dialog-centered',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false
					});
				}),
				take(1)
			)
			.subscribe();
	}

	transfer() {
		if (this.secondaryClient.contactMethod === 'Deceased') {
			return;
		}

		const data = {
			...ClientProfileSecondaryMapper.mapToUpsert(this.secondaryClient),
			...this.prepareValue(),
		};
		this.profile
			.listOfServices(data.customerID)
			.pipe(
				tap((x) => {
					const message = x ? this.validateUser(x, ['kiwiSavers'], true) : [];
					const isInvalid = R.complement(R.either(R.isNil, R.isEmpty))(message);
					const transfer = new Observable((obs: Observer<any>) => {
						this.redirectTransfer(data);
						obs.complete();
					});

					const getMessage = isInvalid
						? `'${data.firstName} ${data.lastName}' is a <br /> ${message
								?.toString()
								?.replace(/,/g, ' ')} Kindly remove ${
								message && message.length > 1 ? 'these' : 'this'
						  } before transferring.`
						: `Are you sure you want to transfer '${data.firstName} ${data.lastName}'?`;

					const hasNoError = R.isEmpty(message);
					const initState = {
						header: 'Transfer customer',
						message: getMessage,
						transfer$: transfer,
						canTransfer: hasNoError,
					};

					this.modalService.show(TransferClientModalComponent, {
						class: 'modal-dialog-centered',
						initialState: initState,
						ignoreBackdropClick: true,
					});
				}),
				take(1)
			)
			.subscribe();
	}

	validateUser(data, exclude = [], isTransfer?: boolean) {
		const message = [];
		if (data) {
			if (!R.contains('lrs', exclude)) {
				data.lrs?.forEach((lr) => {
					lr.customerServices?.forEach((cs) => {
						const provider =
							cs.provider +
							': ' +
							cs.policyNumber +
							(cs.policyNumberSuffix ? ' - ' + cs.policyNumberSuffix : '');
						message.push(` - L&R Policy Owner of ${provider} <br />`);
					});
				});
			}

			if (!R.contains('mortgages', exclude)) {
				data.mortgages?.forEach((m) => {
					m.customerServices?.forEach((cs) => {
						const provider =
							cs.provider +
							': ' +
							cs.loanNumber +
							(cs.loanNumberSuffix ? ' - ' + cs.loanNumberSuffix : '');
						message.push(` - Borrowing Entity of ${provider} <br />`);
					});
				});
			}

			if (!R.contains('propertyAssets', exclude)) {
				data.propertyAssets?.forEach((pa) => {
					message.push(` - Property Owner of ${pa.propertyAddress} <br />`);
				});
			}

			if (!R.contains('assets', exclude)) {
				const assets = isTransfer
					? data?.assets
							?.map((x) => ({
								...x,
								owner: x.owner ? JSON.parse(x.owner) : [],
							}))
							?.filter((x) => x?.owner?.length > 1)
					: data?.assets;
				assets?.forEach(({ asset }) => {
					message.push(` - Owner of ${asset} <br />`);
				});
			}

			if (!R.contains('liability', exclude)) {
				data?.liabilities?.forEach((liability) => {
					if (liability.borrower) {
						message.push(` - Borrower of ${liability.lender} <br />`);
					}
				});
			}

			if (!R.contains('fgs', exclude)) {
				data.fgs?.forEach((fg) => {
					const provider =
						fg.provider +
						': ' +
						fg.policyNumber +
						(fg.policyNumberSuffix ? ' - ' + fg.policyNumberSuffix : '');

					message.push(` - F&G Policy Owner of ${provider} <br />`);
				});
			}

			if (!R.contains('kiwiSavers', exclude)) {
				data.kiwiSavers?.forEach((ks) => {
					const provider = ks.provider + ': ' + ks.memberNumber;
					message.push(` - Fund Owner of ${provider} <br />`);
				});
			}

			if (!R.contains('investments', exclude)) {
				const investments = isTransfer
					? data?.investments?.filter((x) => x?.investor?.length > 1)
					: data?.investments;
				investments?.forEach((investment) => {
					const provider = `${investment.provider}: ${investment.investorNumber}`;
					message.push(` - Investment Owner of ${provider} <br />`);
				});
			}
		}

		return message;
	}

	redirectTransfer(data) {
		this.isSaving$.next(true);
		this.transferSCIFn$(data)
			.pipe(take(1))
			.subscribe(
				(res) => {
					this.isSaving$.next(false);
					const redirectRoute = this.routeService.customerView(res);
					this.router.navigate(redirectRoute);
				},
				(err) => {
					this.isSaving$.next(false);
				},
				() => {
					this.isSaving$.next(false);
				}
			);
	}

	computeAge() {
		const dob = this.form.get('dateOfBirth')?.value;
		const age = computeAgeBasedOnBirthdate(dob);
		this.age.setValue(age);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

const patchValue: <T>(form: UntypedFormGroup, partialViewModel: Partial<T>) => void = (
	form,
	partialViewModel
) => {
	const FormValueToPatch = Object.keys(partialViewModel)?.reduce((acc, v) => {
		const notEmpty = !['', null, undefined]?.some((x) => x === v);
		if (notEmpty) {
			const prop = { [v]: partialViewModel[v] };
			Object.assign(acc, prop);
		}
		return acc;
	}, {});
	form.patchValue(FormValueToPatch);
};
