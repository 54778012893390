import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { Observable, Observer } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { MOATAssetsLiabilitiesModalComponent } from 'src/app/shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { PropertyAssetCustomerService } from 'src/app/shared/models/services/property-asset/property-asset';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { objectUtil } from 'src/app/util/util';
import { PropertyPurchaseMapper } from '../state/property-purchase.mapper';
import { PropertyPurchaseDetailsState } from '../state/property-purchase.model';
import { PropertyPurchaseQuery } from '../state/property-purchase.query';

const defaultValuationType = 'S&P';

@Component({
  selector: 'app-property-purchase-form',
  templateUrl: './property-purchase-form.component.html',
})
export class PropertyPurchaseFormComponent implements OnInit, OnChanges {

  @Input() properties: PropertyPurchaseDetailsState[];
	@Input() propertyOwners: ViewDisplayValue[];
	@Input() SU$: Observable<ViewDisplayValue[]>;
	@Input() SV1$: Observable<ViewDisplayValue[]>;
	@Input() ST$: Observable<ViewDisplayValue[]>;
	@Input() STI$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
  @Input() adviceProcessId: number;
  @Input() addFn$: (req: any) => Observable<any>;
  @Input() updateFn$: (req: any) => Observable<any>;
  @Input() deleteFn$: (req: any) => Observable<any>;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

  @Input() potentialPurchases: PropertyAssetCustomerService[];
  newPotentialPurchases: PropertyAssetCustomerService[];

  form: UntypedFormGroup;
  bsModalRef: BsModalRef;
  isAdd = false;
  isLoading = false;
  totalPropertyValue = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private query: PropertyPurchaseQuery
  ) {
  }

  ngOnChanges(changes) {
    if (changes.properties) {
      this.buildForm();
      this.prepareData();
      const filterePotentialPurchases = this.potentialPurchases?.filter((pp) => !this.properties?.find(p => pp.PropertyAddress === p.propertyAddress))
      this.newPotentialPurchases = filterePotentialPurchases;
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.prepareData();
  }

  get PropertiesList() {
    return this.form.get('properties') as UntypedFormArray;
  }

  buildForm(): void {
    this.form = this.fb.group({
      properties: this.fb.array([]),
      propertyPurchaseValue: null
    });
  }

  prepareData() {
    this.totalPropertyValue = 0;
    const processData = (property: PropertyPurchaseDetailsState) => {
      const caseChange = objectUtil.mapPascalCaseToCamelCase(property);
      const processed = PropertyPurchaseMapper.mapToView(caseChange);
      const group = this.fb.group({
        adviceProcessId: this.fb.control(processed.adviceProcessId),
        parentCRTId: this.fb.control(processed.parentCRTId),
        linkedCRTId: this.fb.control(processed.linkedCRTId),
        propertyAddress: this.fb.control(processed.propertyAddress),
        propertyValue: this.fb.control(processed.propertyValue),
        propertyOwner: this.fb.control(processed.propertyOwner),
			  propertyUse: this.fb.control(processed.propertyUse),
        financeDate: this.fb.control(processed.financeDate),
        purchaseDate: this.fb.control(processed.purchaseDate),
        valuationType: this.fb.control(processed.valuationType),
        valuationTypeDate: this.fb.control(processed.valuationTypeDate),
        rentalIncome: this.fb.control(processed.rentalIncome),
        rentalIncomeFrequency: this.fb.control(processed.rentalIncomeFrequency),
        boarderIncome: this.fb.control(processed.boarderIncome),
        boarderIncomeFrequency: this.fb.control(processed.boarderIncomeFrequency),
        title: this.fb.control(processed.title),
        rates: this.fb.control(processed.rates),
        ratesFrequency: this.fb.control(processed.ratesFrequency),
        type: this.fb.control(processed.type),
        insurance: this.fb.control(processed.insurance),
        insuranceFrequency: this.fb.control(processed.insuranceFrequency),
        otherExpense: this.fb.control(processed.otherExpense),
        otherExpenseFrequency: this.fb.control(processed.otherExpenseFrequency),
        cRTId: this.fb.control(processed.cRTId ? processed.cRTId : null),
        isLoading: this.fb.control(false),
        isEdit: this.fb.control(false),
        isTBC: this.fb.control(processed.isTBC),
      })
      this.PropertiesList.push(group);
    }

    if (this.properties?.length) {
      R.forEach(processData, this.properties);
    }

    this.totalPropertyValue = this.query.getValue().totalPurchasePrice;

  }

  add() {
    this.isAdd = true;
  }

  cancel() {
    this.isAdd = false;
    this.form.get('propertyPurchaseValue').reset();
  }

  remove(index: number) {
    this.PropertiesList.removeAt(index);
  }

  edit(index: number) {
    this.PropertiesList.controls[index].get('isEdit').setValue(true);

    const defaultData = PropertyPurchaseMapper.mapToPropertyDetails(this.PropertiesList.controls[index].value);
    const saveFn$ = (data) => {
      data.linkedCRTId = defaultData.LinkedCRTId;
      return this.updateFn$(data).pipe(
        take(1),
        finalize(() => this.PropertiesList.controls[index].get('isEdit').setValue(false))
      );
    }

    this.prepAndOpenModal(defaultData, saveFn$, index);
  }

  delete(index: number) {
    this.PropertiesList.controls[index].get('isLoading').setValue(true);
    const cRTId = this.PropertiesList.controls[index].get('cRTId').value
    this.deleteFn$(cRTId).pipe(
      take(1)
    ).subscribe();
  }

  confirmDelete(index: number) {
    const confirm = new Observable((obs: Observer<any>) => {
			this.delete(index);
			obs.complete();
		});

    const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

    const initState = {
			header: 'Delete Property',
			message: `Are you sure you want to delete this property?`,
			delete$: confirm,
			decline$: decline,
			canDelete: true,
		};

    this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false
		});
  }

  save(index: number) {
    this.PropertiesList.controls[index].get('isLoading').setValue(true);
    const data = this.PropertiesList.controls[index].value;
    this.addFn$(data).pipe(
      take(1),
      finalize(() => this.PropertiesList.controls[index].get('isLoading').setValue(false))
    ).subscribe();
  }

  handleAddNew(value) {
    let defaultData = null;
    const saveFn$ = (data) =>
      this.addFn$(data).pipe(
        take(1),
        finalize(() => this.cancel())
      );

    if (value === 'new') {
      defaultData = {
        ValuationType: defaultValuationType
      }
    } else {
      defaultData = this.newPotentialPurchases[value];
    }

    this.prepAndOpenModal(defaultData, saveFn$, null);
  }

  prepAndOpenModal(defaultData, saveFn$, index?: number) {
    const decline$ = new Observable((obs: Observer<any>) => {
      if (index === null) {
        this.cancel();
      }
      else {
        this.PropertiesList.controls[index].get('isEdit').setValue(false)
      }
      obs.complete();
    });

    const initState = {
      header: 'Property Details',
      message: `Property Purchase`,
      policyOwners: this.propertyOwners,
      crtInfo: defaultData,
      su$: this.SU$,
      sv1$: this.SV1$,
      st$: this.ST$,
      sti$: this.STI$,
      apcrtf$: this.APCRTF$,
			getOwnerChoices: this.getOwnerChoices,
      saveFn$,
      decline$,
    };
    this.bsModalRef = this.modalService.show(
      MOATAssetsLiabilitiesModalComponent,
      {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      }
    );
  }

}
