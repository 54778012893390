import { MoatVersionService } from './../../service/moat-version.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { find, propEq, filter as Rfilter } from 'ramda';
import { iif, Observable, of, Subject } from 'rxjs';
import {
	filter,
	finalize,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { Fields, getInvalidWarning } from 'src/app/shared/error-message/error-message';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import {
	computeAnnualTaxable,
	computeMonthlyAfterTaxIncome,
	computeMonthlyTaxable,
} from '../../calculations/compute-taxable';
import { NoWhitespaceValidator } from '../../../../../../shared/validator/no-whitespace/no-whitespace.directive';
import { numUtil } from '../../../../../../util/util';
import { OtherIncomeMapper } from '../../mapper/income.mapper';
import {
	FactFindComputationState,
	IncomeSourceState,
	OtherIncomeObjState,
	OtherIncomeState,
} from '../../models/income.model';
declare var $: any;

@Component({
	selector: 'app-crt-other-income',
	templateUrl: './crt-other-income.component.html',
	styleUrls: ['./crt-other-income.component.scss'],
})
export class CrtOtherIncomeComponent implements OnInit, OnDestroy {
	@Input() otherIncome$: Observable<OtherIncomeObjState[]>;
	@Input() updateFn$: (otherIncome) => Observable<OtherIncomeState>;
	@Input() addNewFn$: (otherIncome) => Observable<OtherIncomeState>;
	@Input() deleteFn$: (otherIncome) => Observable<number>;
	@Input() factFindComputation$: Observable<FactFindComputationState>;
	@Input() incomeSource$: Observable<IncomeSourceState[]>;
	@Input() allIncomeList$: Observable<any>;

	onDestroy$ = new Subject<void>();

	form: UntypedFormGroup;
	factFind: FactFindComputationState = {};
	incomeList: IncomeSourceState[];
	isListLoading = true;
	elseMinusOthers = true;
	editMode = false;
	addMode = false;
	totalAnnual = 0;
	tempData = [];
	allIncomeList;

	isMoatV2: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private loggerService: LoggerService,
		private moatVersionService: MoatVersionService
	) {
		this.buildForm();
	}

	get otherArray() {
		return this.form.get('otherArray') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			otherArray: this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.prepData();
		this.getAllIncome();

		this.incomeSource$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => (this.incomeList = data));

		this.isMoatV2 = this.moatVersionService.isMoatV2();
	}

	prepData() {
		this.otherIncome$
			.pipe(
				filter((data) => !!data),
				finalize(() => (this.isListLoading = false)),
				take(1)
			)
			.subscribe((x: any) => {
				this.form.reset();
				OtherIncomeMapper.mapOtherIncome(x)?.map((val, i) => this.addOther(val));
				this.form.disable();
			});

		this.factFindComputation$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.totalAnnual = +data?.totalAnnualAllIncome;
				this.factFind = data;
			});
	}

	getAllIncome() {
		this.allIncomeList$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.allIncomeList = x));
	}

	addOther(data?: any, isAdd: boolean = false) {
		this.otherArray.push(
			this.fb.group({
				cRTId: [(data && data.cRTId) || ''],
				adviceProcessId: [(data && data.adviceProcessId) || ''],
				incomeType: [
					(data && data.incomeType) || '',
					[Validators.required, NoWhitespaceValidator],
				],
				annualIncome: [
					(data && data.annualIncome) || '',
					[Validators.required, Validators.min(0.01)],
				],
				isNonTaxable: data ? data.isNonTaxable : false,
				btnSaveOther: isAdd,
				btnEditOther: !isAdd,
				isNew: isAdd,
				isLoading: [false],
			})
		);
	}

	saveOther(index) {
		if (this.otherArray?.invalid) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.Income));
			return;
		}

		const isNew = this.otherArray.controls[index].get('isNew').value;
		const adviceProcessId = parseInt(
			this.route.snapshot.paramMap.get('adviceProcessId'),
			10
		);
		const cRTId = !isNew
			? +this.otherArray.controls[index].get('cRTId').value
			: null;

		const tempFactFind = {
			...this.factFind,
			...this.recompute(
				index,
				this.otherArray.controls[index].get('annualIncome').value,
				this.factFind
			),
		};

		const data = OtherIncomeMapper.mapToUpsert(
			this.otherArray.at(index).value,
			cRTId,
			adviceProcessId,
			tempFactFind
		);

		this.otherArray.controls[index].get('isLoading').setValue(true);

		of(data)
			.pipe(
				mergeMap((val) =>
					iif(
						() => isNew,
						this.addNewFn$(val).pipe(
							tap(() => {
								this.buildForm();
								this.prepData();
							})
						),
						this.updateFn$(val)
					)
				),
				tap(() => this.setDisabled(index)),
				finalize(() => {
					this.patchTempData(index);
					this.otherArray.controls[index].get('isLoading').setValue(false);
					this.editMode = false;
					this.addMode = false;
				})
			)
			.subscribe();
	}

	setDisabled(i: number) {
		this.otherArray.controls[i].get('btnSaveOther').setValue(false);
		this.otherArray.controls[i].get('btnEditOther').setValue(true);
		this.otherArray.controls[i].get('incomeType').disable();
		this.otherArray.controls[i].get('annualIncome').disable();
		this.otherArray.controls[i].get('isNonTaxable').disable();
	}

	editOther(index) {
		this.editMode = true;
		this.addTempData(index);
		this.otherArray.controls[index].get('btnSaveOther').setValue(true);
		this.otherArray.controls[index].get('btnEditOther').setValue(false);
		this.otherArray.controls[index].get('incomeType').enable();
		this.otherArray.controls[index].get('annualIncome').enable();
		this.otherArray.controls[index].get('isNonTaxable').enable();
	}

	deleteOther(index) {
		this.otherArray.controls[index].get('isLoading').setValue(true);

		this.deleteFn$(+this.otherArray.controls[index].get('cRTId').value)
			.pipe(
				finalize(() => {
					this.otherArray?.removeAt(index);
				})
			)
			.subscribe();
	}

	deleteNewOther(index) {
		this.otherArray?.removeAt(index);
		this.addMode = false;
	}

	addNewOtherIncome() {
		this.addOther({}, true);
		this.addMode = true;
	}

	collapseFalse() {
		this.elseMinusOthers = false;
	}

	defaultShow() {
		$('#collapseOthers').css('display', 'block');
	}

	recompute(
		index: number,
		newValue: number,
		factFind: FactFindComputationState
	) {
		let tempTotalAnnualIncome = 0;
		this.otherArray.controls?.map((rental: any, i) => {
			if (index === i) {
				tempTotalAnnualIncome += +newValue;
			} else {
				tempTotalAnnualIncome += +rental.value.annualIncome || 0;
			}
		});
		const annualTaxable = computeAnnualTaxable({
			...factFind,
			totalAnnualIncome: tempTotalAnnualIncome,
		});
		const monthlyTaxable = computeMonthlyTaxable(+annualTaxable);
		const monthlyAfterTax = computeMonthlyAfterTaxIncome(
			this.incomeList,
			{
				...factFind,
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
			},
			this.allIncomeList
		);

		return {
			totalAnnualIncome: +numUtil.formatToNumCurrency(tempTotalAnnualIncome),
			annualTaxableJointIncome: +annualTaxable,
			monthlyTaxableJointIncome: +monthlyTaxable,
			monthlyAfterTaxIncome: +monthlyAfterTax,
		};
	}

	cancelEdit(i: number) {
		this.patchTempData(i, true);
		this.setDisabled(i);
		this.editMode = false;
		this.addMode = false;
	}

	addTempData(i: number) {
		const currentValue = this.form.getRawValue()?.otherArray[i];
		if (currentValue) {
			this.tempData.push(currentValue);
		}
	}

	patchTempData(i: number, allowPatch?) {
		const currentValue = this.form.getRawValue()?.otherArray[i];
		const tempValue = find(propEq('cRTId', currentValue?.cRTId))(
			this.tempData
		);
		if (allowPatch) {
			this.otherArray.controls[i].patchValue(tempValue);
		}
		if (tempValue) {
			this.tempData = Rfilter(
				(val) => +val?.cRTId !== +currentValue?.cRTId,
				this.tempData
			);
		}
	}

	collapseMoreOthers() {
		$('#collapseOthers').toggle();
		this.elseMinusOthers = false;
	}

	collapseLessOthers() {
		$('#collapseOthers').toggle();
		this.elseMinusOthers = true;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	getTooltipValue = (form: UntypedFormGroup): string => {
		if(form) {
			return form?.value;
		}
		return '';
	}

}
