<div class="illness clearfix">
	<div class="illness__header d-flex flex-wrap mb-3 align-items-center">
		<div class="illness__header__list d-flex flex-wrap align-items-center">
			<app-risk-analysis-people-tabs></app-risk-analysis-people-tabs>
		</div>
	</div>
	<div class="illness__content">
		<app-illness-calculation
			[APCRTTRC$]="APCRTTRC$"
			[APCRTEC$]="APCRTEC$"
			[people$]="people$"
			[selectedCrt]="selectedCrt"
			[criticalIllness$]="criticalIllness$"
			[adviceProcessId]="adviceProcessId | async"
			[isAdviceProcessEnded]="isAdviceProcessEnded$ | async"
		>
		</app-illness-calculation>
	</div>
</div>
