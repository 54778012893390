<div class="app-adduser-container fluid-container">
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">
			{{ header }}
		</h1>
	</div>

	<div class="row tap-container service-tabs data-import">
		<tabset class="tab-container w-100">
			<tab heading="User Details">
				<form [formGroup]="form">
					<div class="app-adduser-content-container">
						<div class="container-fluid px-0">
							<div class="row tap-container mt-2">
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="firstName" class="position-relative">
												First Name
												<span class="tap-required" *ngIf="FirstName.invalid"
													>*</span
												>
											</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="firstName"
												formControlName="FirstName"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="lastName" class="position-relative">
												Last Name
												<span class="tap-required" *ngIf="LastName.invalid"
													>*</span
												>
											</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="lastName"
												formControlName="LastName"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="isActive">Is active</label>
										</div>
										<div class="col-lg-8">
											<select
												class="form-control"
												id="isActive"
												formControlName="IsActive"
											>
												<option value=""></option>
												<option
													*ngFor="let opt of isActiveOptions"
													[value]="opt.option"
												>
													{{ opt.name }}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="securityGroup" class="position-relative">
												Security group
												<span class="tap-required" *ngIf="SecurityGroup.invalid"
													>*</span
												>
											</label>
										</div>
										<div class="col-lg-8">
											<select
												class="form-control"
												id="isActive"
												id="securityGroup"
												formControlName="SecurityGroup"
												(change)="securityChange()"
											>
												<option value=""></option>
												<option
													*ngFor="let sec of secGroups"
													[value]="sec.securityGroupCode"
												>
													{{ sec.securityGroupName }}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class="row tap-container">
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="emailAddress" class="position-relative">
												Email address
												<span class="tap-required" *ngIf="EmailAddress.invalid"
													>*</span
												>
											</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="emailAddress"
												formControlName="EmailAddress"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="startDate" class="position-relative">
												Start date
												<span class="tap-required" *ngIf="StartDate.invalid"
													>*</span
												>
											</label>
										</div>
										<div class="col-lg-8">
											<app-date-input
												formControlName="StartDate"
												textboxId="startDate"
											></app-date-input>
										</div>
									</div>
								</div>
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="birthDate">Birth date</label>
										</div>
										<div class="col-lg-8">
											<app-date-input
												formControlName="BirthDate"
												textboxId="birthDate"
											></app-date-input>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="mobilePhone">Mobile phone</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="mobilePhone"
												formControlName="MobilePhone"
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="row tap-container">
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="businessPhone">Business Phone</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="businessPhone"
												formControlName="BusinessPhone"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="fspNumber">FSP number</label>
										</div>
										<div class="col-lg-8" formGroupName="Stats">
											<input
												class="form-control"
												type="text"
												id="fspNumber"
												formControlName="fsp_number"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-2 pr-0">
											<label for="homeAddress">Home address</label>
										</div>
										<div class="col-lg-10 crm-home">
											<input
												class="form-control"
												type="text"
												id="homeAddress"
												formControlName="HomeAddress"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="row tap-container" formGroupName="StaffSettings">
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="facebookLink">Facebook Link</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="facebookLink"
												formControlName="FacebookLink"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="linkedInLink">LinkedIn Link</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="linkedInLink"
												formControlName="LinkedInLink"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3 pr-md-0">
									<div class="form-group form-inline row mb-1">
										<div class="col-lg-4 pr-0">
											<label for="signOffName">Sign Off Name</label>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="signOffName"
												formControlName="SignOffName"
											/>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div
										class="form-group form-inline row mb-1"
										*ngIf="IsBlanket"
									>
										<div class="col-lg-4 pr-0">
											<label for="correspondenceEmail">
												Correspondence Email</label
											>
										</div>
										<div class="col-lg-8">
											<input
												class="form-control"
												type="text"
												id="correspondenceEmail"
												formControlName="CorrespondenceEmail"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="row tap-container">
								<div class="col-md-6 px-0" formGroupName="Stats">
									<div class="row tap-container">
										<div class="col pt-3">
											<h6>Stats</h6>
										</div>
									</div>

									<div class="row tap-container">
										<div class="col scc-border mb-2 mr-md-0"></div>
									</div>
									<div class="row tap-container">
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="selfGenQuarter">Self Gen Quarter</label>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="selfGenQuarter"
														formControlName="self_gen_quarter"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="selfGenAnnual">Self Gen Annual</label>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="selfGenAnnual"
														formControlName="self_gen_annual"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="apiQuarter">API Quarter</label>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="apiQuarter"
														formControlName="api_quarter"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="apiAnnual">API Annual</label>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="apiAnnual"
														formControlName="api_annual"
													/>
												</div>
											</div>
										</div>
									</div>

									<div class="row tap-container">
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="kiwiSaverQuarter"
														>KiwiSaver Quarter</label
													>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="kiwiSaverQuarter"
														formControlName="kiwisaver_quarter"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="kiwiSaverAnnual">KiwiSaver Annual</label>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="kiwiSaverAnnual"
														formControlName="kiwisaver_annual"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="mortgageQuarter">Mortgage Quarter</label>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="mortgageQuarter"
														formControlName="mortgage_quarter"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="mortgageAnnual">Mortgage Annual</label>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="mortgageAnnual"
														formControlName="mortgage_annual"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="blanketNewBusinessQuarter"
														>Blanket New Business Quarter</label
													>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="blanketNewBusinessQuarter"
														formControlName="blanketnewbusiness_quarter"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="blanketNewBusinessAnnual"
														>Blanket New Business Annual</label
													>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="blanketNewBusinessAnnual"
														formControlName="blanketnewbusiness_annual"
													/>
												</div>
											</div>
										</div>

										<!-- Domestic F&G Quarter & Annual -->
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="domesticFgQuarter"
														>Domestic F&G Quarter</label
													>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="domesticFgQuarter"
														formControlName="domestic_fg_quarter"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="domesticFgAnnual"
														>Domestic F&G Annual</label
													>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="domesticFgAnnual"
														formControlName="domestic_fg_annual"
													/>
												</div>
											</div>
										</div>

										<!-- Commercial F&G Quarter & Annual -->
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="commercialFgQuarter"
														>Commercial F&G Quarter</label
													>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="commercialFgQuarter"
														formControlName="commercial_fg_quarter"
													/>
												</div>
											</div>
										</div>
										<div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="commercialFgAnnual"
														>Commercial F&G Annual</label
													>
												</div>
												<div class="col-lg-8">
													<input
														class="form-control"
														type="text"
														id="commercialFgAnnual"
														formControlName="commercial_fg_annual"
													/>
												</div>
											</div>
										</div>
										<!-- OLD: Client Conversion -->
										<!-- <div class="col-md-6 pr-md-0">
											<div class="form-group form-inline row mb-1">
												<div class="col-lg-4 pr-0">
													<label for="clientConversion">Client Conversion</label>
												</div>
												<div class="col-lg-8 percent-icon">
													<input
														class="form-control"
														type="text"
														id="clientConversion"
														formControlName="client_conversion"
														appOneDigitDecimalNumber
													/>
													<i>%</i>
												</div>
											</div>
										</div> -->

										<ng-container *ngIf="isHaven">
											<!-- L&R Conversion -->
											<div class="col-md-12 pr-md-0">
												<div class="form-group form-inline row mb-1">
													<div class="col-lg-2 pr-0">
														<label for="lRConversion">L&R Conversion</label>
													</div>
													<div class="col-lg-4 percent-icon pr-2">
														<input
															class="form-control"
															type="text"
															id="lRConversion"
															formControlName="lr_conversion"
															appOneDigitDecimalNumber
														/>
														<i>%</i>
													</div>
													<div class="col-lg-auto"></div>
												</div>
											</div>

											<!-- KiwiSaver Conversion -->
											<div class="col-md-12 pr-md-0">
												<div class="form-group form-inline row mb-1">
													<div class="col-lg-2 pr-0">
														<label for="kiwisaverConversion"
															>KiwiSaver Conversion</label
														>
													</div>
													<div class="col-lg-4 percent-icon pr-2">
														<input
															class="form-control"
															type="text"
															id="kiwisaverConversion"
															formControlName="kiwisaver_conversion"
															appOneDigitDecimalNumber
														/>
														<i>%</i>
													</div>
													<div class="col-lg-auto"></div>
												</div>
											</div>

											<!-- Blanket Conversion -->
											<div class="col-md-12 pr-md-0">
												<div class="form-group form-inline row mb-1">
													<div class="col-lg-2 pr-0">
														<label for="blanketConversion"
															>Blanket Conversion</label
														>
													</div>
													<div class="col-lg-4 percent-icon pr-2">
														<input
															class="form-control"
															type="text"
															id="blanketConversion"
															formControlName="blanket_conversion"
															appOneDigitDecimalNumber
														/>
														<i>%</i>
													</div>
													<div class="col-lg-auto"></div>
												</div>
											</div>

											<!-- Mortgage Conversion -->
											<div class="col-md-12 pr-md-0">
												<div class="form-group form-inline row mb-1">
													<div class="col-lg-2 pr-0">
														<label for="blanketConversion"
															>Mortgage Conversion</label
														>
													</div>
													<div class="col-lg-4 percent-icon pr-2">
														<input
															class="form-control"
															type="text"
															id="mortgageConversion"
															formControlName="mortgage_conversion"
															appOneDigitDecimalNumber
														/>
														<i>%</i>
													</div>
													<div class="col-lg-auto"></div>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
								<div class="col-md-6 px-0 px-md-3">
									<div class="row tap-container">
										<div class="col pt-3">
											<h6>Services offered</h6>
										</div>
									</div>
									<div class="row tap-container">
										<div class="col px-md-0">
											<app-services-list
												formControlName="Services"
												[choices]="serviceChoices"
											></app-services-list>
										</div>
									</div>
								</div>
							</div>
							<div class="row tap-container">
								<div class="col-md-6 px-0 px-md-3">
									<!-- <ng-container *ngIf="showAccessibilitySetting">
										<ng-container *appRestrictTo="['BCAA']">
											<div class="row tap-container">
												<div class="col pt-3">
													<h6>Accessibility</h6>
												</div>
												<div class="d-flex justify-content-end">
													<div class="form-check form-check-inline">
														<input
															class="form-check-input"
															type="checkbox"
															id="allCheckbox"
															(click)="showAll()"
														/>
														<label class="form-check-label" for="allCheckbox">Show All</label>
													</div>
												</div>
											</div>
											<div class="row tap-container">
												<div class="col pt-3">
													<app-table-input
														formControlName="AdviserIdsAssigned"
														[choices]="staffChoices"
														[showAllStaffs]="showAllStaffs"
													>
														<div class="row">
															<div class="col">
																<span class="font-weight-bold">Users</span>
															</div>
															<div class="col">
																<span class="font-weight-bold">Security Group</span>
															</div>
															<div class="col">
																<span class="font-weight-bold">Status</span>
															</div>
														</div>
													</app-table-input>
												</div>
											</div>
										</ng-container>
									</ng-container> -->
								</div>
								<div class="col-md-6 px-0 px-md-3">
									<ng-container *appRestrictTo="['BEU']">
										<div class="row">
											<div *ngIf="form.disabled" class="col-auto mb-5">
												<button
													type="button"
													class="btn btn-primary tap-bg-primary"
													(click)="provideAccess()"
													id="provideAccess"
												>
													Provide Access
													<app-manual-loader-mini
														*ngIf="isSavingProvideAccess"
														[isLoading]="isSaving"
													>
													</app-manual-loader-mini>
												</button>
											</div>
											<div *ngIf="form.disabled" class="col mb-5">
												<button
													type="button"
													class="btn btn-primary tap-bg-primary"
													(click)="TransferActivity()"
													id="transferActivity"
												>
													Transfer Activities
													<app-manual-loader-mini
														*ngIf="isSavingProvideAccess"
														[isLoading]="isSaving"
													>
													</app-manual-loader-mini>
												</button>
											</div>
											<div
												class="mb-5 d-flex align-items-center"
												[ngClass]="form.disabled ? 'col-2' : 'col'"
												formGroupName="StaffSettings"
											>
												<input
													#showInAdviser
													[disabled]="false"
													id="showInAdviser"
													formControlName="ShowInAdviserList"
													type="checkbox"
												/>
												<label for="showInAdviser" class="pl-2"
													>Show in Adviser List</label
												>
											</div>
											<div class="col mb-5 text-right">
												<ng-container *ngIf="isSaving; else showButtons">
													<app-manual-loader-mini
														[isLoading]="isSaving"
													></app-manual-loader-mini>
												</ng-container>
												<ng-template #showButtons>
													<button
														class="btn btn-primary tap-bg-primary"
														(click)="submitBtnClick()"
														id="submit"
													>
														{{ btnText }}
													</button>
													<button
														class="btn btn-light"
														(click)="cancel()"
														id="cancel"
													>
														Cancel
													</button>
												</ng-template>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</form>
			</tab>
			<tab *ngIf="!isAdd" heading="Documents">
				<app-documents
					[data]="_data"
					[uploadFileFn$]="uploadFileFn$"
					[removeFileFn$]="removeFileFn$"
				></app-documents>
			</tab>
			<tab *ngIf="!isAdd" heading="Disclosure Document">
				<app-disclosure-settings [data]="_data"></app-disclosure-settings>
			</tab>
			<tab *ngIf="!isAdd" heading="Provider & Commission">
				<app-provider-commission
					[lrp]="LRP"
					[data]="_data"
				></app-provider-commission>
			</tab>
			<ng-container *appRestrictTo="['BCAA']">
				<tab *ngIf="!isAdd" heading="Accessibility Settings">
					<app-accessibility-settings
						[data]="_data"
						[staffChoices]="staffChoices"
						[updateAccessiblitySettingsFn$]="updateAccessiblitySettings$"
					></app-accessibility-settings>
				</tab>
			</ng-container>
			<ng-container *ngIf="hasCPMOAT$ | async">
				<tab *ngIf="!isAdd" heading="Client Portal">
					<app-customer-portal [data]="_data"></app-customer-portal>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
