import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable, Observer } from 'rxjs';
import { delay, finalize, first, map, takeUntil, tap } from 'rxjs/operators';
import { LoanModalComponent } from 'src/app/shared/modal/crt/moat/loan-modal/loan-modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { ComponentBase } from '../../../../../../../core/base/component-base';
import { calculateLoanRepayment } from '../../../../../crt-page/crt-mortgage/structure-soa/loan/state/loan.calculations';
import { getPerFrequency } from '../../../../_shared/calculations/monthly-conversion';
import { PeopleEntitiesService } from '../../../client-sop/people-entities/state/people-entities.service';
import { CrtMortgageService } from '../../../state/crt-mortgage.service';
import { Application } from '../../state/application.model';
import { ApplicationService } from '../../state/application.service';
import { LoanStructure } from './state/loan-structure.model';
import { LoanStructureQuery } from './state/loan-structure.query';
import { LoanStructureService } from './state/loan-structure.service';
import { numUtil } from 'src/app/util/util';

import { subtract } from 'ramda';
import { CashDepositQuery } from '../funding-required/cash-deposit/state/cash-deposit.query';
import { CashDepositState } from '../funding-required/cash-deposit/state/cash-deposit.model';

@Component({
	selector: 'app-loan-structure',
	templateUrl: './loan-structure.component.html',
	styleUrls: ['./loan-structure.component.scss'],
	exportAs: 'appLoanStructure',
})
export class LoanStructureComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	@Input() adviceProcessId: string;
	@Input() parentCRTId: string;
	@Input() fundingRequiredTotals$: Observable<any>;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	@Output() isSaving: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	private _application: Application;
	fileName: any;

	@Input()
	set application(application: Application) {
		if (!application) {
			return;
		}
		this.loanStructureToFollow.patchValue(application.loanStructureToFollow, {
			emitEvent: false,
		});
		this._application = application;
	}
	get application() {
		return this._application;
	}

	modalRef: BsModalRef;

	formGroup = new UntypedFormGroup({
		loanStructureToFollow: new UntypedFormControl({ value: true, disabled: false }),
	});

	loanStructureToFollowIsSaving: boolean;

	isLoading$ = this.query.selectLoading();

	remainingToAllocate$ = new BehaviorSubject<string>(null);

	peopleList: ViewDisplayValue[];
	borrowers: ViewDisplayValue[];

	loanStructure$ = this.service.loanStructure$.pipe(
		map((ls) =>
			ls?.map((x) => ({
				...x,
				perFrequency: getPerFrequency(x?.repaymentFrequency) || '',
				borrowerList: this.checkPeopleList(x?.borrowers) || '',
			}))
		)
	);

	constructor(
		private query: LoanStructureQuery,
		private service: LoanStructureService,
		private crtMortgageService: CrtMortgageService,
		private modalService: BsModalService,
		private peopleEntitiesService: PeopleEntitiesService,
		private applicationService: ApplicationService,
		private cashDepositQuery: CashDepositQuery
	) {
		super();
	}

	get loanStructureToFollow(): AbstractControl | null {
		return this.formGroup.controls?.loanStructureToFollow;
	}

	addLoanSplit(): void {
		const saveFn$ = (data: LoanStructure) =>
			this.service.add({
				...data,
				adviceProcessId: +this.adviceProcessId,
				parentCRTId: +this.parentCRTId,
				sectionCode: AdviceProcessSectionCodes.ApplicationLoanStructure,
			});

		const cancelFn$ = () =>
			new Observable((obs: Observer<any>) => obs.complete());

		const initState = {
			header: 'Loan Split Details',
			message: 'Add new loan split',
			loanTypes$: this.crtMortgageService.MLT$,
			frequencies$: this.crtMortgageService.APCRTF$,
			fixedPeriods$: this.crtMortgageService.MFPE$,
			borrowers: this.borrowers,
			getOwnerChoices: this.getOwnerChoices,
			calculateLoanRepayment,
			saveFn$,
			cancelFn$,
		};

		this.modalRef = this.modalService.show(LoanModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	editLoanSplit(loan: LoanStructure) {
		const saveFn$ = (data: LoanStructure) =>
			this.service.update({
				...data,
				cRTId: loan.cRTId,
				adviceProcessId: +this.adviceProcessId,
				parentCRTId: +this.parentCRTId,
			});

		const initState = {
			header: 'Loan Split Details',
			message: 'Edit loan split',
			loanTypes$: this.crtMortgageService.MLT$,
			frequencies$: this.crtMortgageService.APCRTF$,
			fixedPeriods$: this.crtMortgageService.MFPE$,
			borrowers: this.borrowers,
			getOwnerChoices: this.getOwnerChoices,
			loan,
			calculateLoanRepayment,
			saveFn$,
			cancelFn$: () => new Observable((obs: Observer<any>) => obs.complete()),
		};

		this.modalRef = this.modalService.show(LoanModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteLoanSplit(cRTId: number) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.service.delete(cRTId).pipe(first()).subscribe();
			obs.complete();
		});
		const initState = {
			header: 'Delete Loan Split',
			message: `Are you sure you want to delete this?`,
			delete$: confirm,
			canDelete: true,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	checkPeopleList(people): string {
		const peopleList = this.peopleList;
		return peopleList
			?.filter((x) => people?.some((y) => +y === +x.value))
			?.map((x) => x.display)
			?.join(', ');
	}

	ngOnInit(): void {
		super.subscribe(this.service.getAll(+this.parentCRTId));
		super.subscribe(
			this.peopleEntitiesService.policyOwnersWithCRT$.pipe(
				tap((x) => (this.peopleList = x))
			)
		);

		super.subscribe(this.loanStructureToFollow.valueChanges, (value) => {
			this.loanStructureToFollowLoading(true);
			this.applicationService
				.update({
					...this.application,
					loanStructureToFollow: value,
					sectionCode: AdviceProcessSectionCodes.Application,
				})
				.pipe(
					delay(100),
					finalize(() => this.loanStructureToFollowLoading(false)),
					first()
				)
				.subscribe();
		});

		combineLatest([
			this.service.totalLoanAmount$,
			this.cashDepositQuery.activeCashDeposit$,
		])
			.pipe(
				map(
					([totalLoanAmount, cashDeposit]) =>
						+subtract(
							(cashDeposit as CashDepositState)?.newLendingRequired || 0,
							totalLoanAmount || 0
						)
				),
				map((total) => numUtil.formatToCurrency2(total || 0)),
				tap((total) => this.remainingToAllocate$.next(total))
			)
			.subscribe();

		combineLatest([
			this.peopleEntitiesService.people$,
			this.peopleEntitiesService.dependants$,
			this.peopleEntitiesService.trusts$,
			this.peopleEntitiesService.company$
		]).pipe(
			map(([p, d, t, c]) => {
				const pp = p?.map((x) => ViewDisplayValue.Map(x.customerId, x.name));
				const dd = d?.map((x) => ViewDisplayValue.Map(x.customerId, x.name));
				const tt = t?.map((x) => ViewDisplayValue.Map(x.customerId, x.name));
				const cc = c?.map((x) => ViewDisplayValue.Map(x.customerId, x.name));
	
				const options = [...pp, ...dd, ...tt, ...cc]?.filter((option) => +option.value);
	
				return options;
			}),
			tap((x) => this.borrowers = x),
			takeUntil(this.onDestroy$)
		).subscribe();
	}

	loanStructureToFollowLoading(value: boolean) {
		this.loanStructureToFollowIsSaving = value;
		this.isSaving.emit(value);
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
