import { Component, OnInit, OnDestroy, AfterViewInit, HostBinding, HostListener, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { ConfigService } from '@core/config/config.service';
import { RouteService } from '@core/config/route.service';
import { User } from '@domain/user/user.model';
import { UserQuery } from '@domain/user/user.query';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from 'ngx-avatars';
import { CrmRoutingModule } from '@modules/crm/crm-routing.module';
import { SharedModule } from '@shared/shared.module';
import { TooltipV2Directive } from '@shared/directive/tooltip/tooltip-v2.directive';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
  imports: [
    CommonModule,  
    MatIconModule,
    AvatarModule,
    CrmRoutingModule,
    SharedModule
	],
  standalone: true,
  hostDirectives: [
    {
      directive: TooltipV2Directive,
      inputs: ['appTooltipV2: tapTooltip']
    }
  ]
})
export class UserAccountComponent implements OnInit, AfterViewInit, OnDestroy {
  private popoverIsShownSubj = new BehaviorSubject(false);
  public popoverIsShown$ = this.popoverIsShownSubj.asObservable();
  userInfo$ = this.userQuery.userInfo$;
  isTapLevelUser$ = this.userQuery.isTapLevel$;
  profilePic$ = this.userQuery.profilePic$;

  name$ = this.userInfo$.pipe(map((x) => x.FirstName + ' ' + x.LastName));
  email$ = this.userInfo$.pipe(map((x) => x.EmailAddress));
  googleImageUrl$ = of(
    this.authService.user ? this.authService.user.picture : ''
  );

  @HostBinding('class') 
  get userAccountClasses() {
    const classes = 'menu-item user-account align-items-center d-flex clickable justify-content-end';
    return classes.split(' ').reduce((a, v) => ({ ...a, [v]: true}), {});
  }

  @HostListener('click')
  onClick() {
    this.togglePopover();
  }

  @ViewChild('dropdown') dropdown: ElementRef<HTMLUListElement>;

  @HostListener('document:click', ['$event'])
	onOutsideClick(event) {
		if (!this.el.nativeElement?.contains(event.target)) {
			this.popoverIsShown$
				.pipe(
					take(1),
					map((x) => {
            this.el?.nativeElement.classList.remove('menu-item-selected');
            return false
          })
				)
				.subscribe((x) => this.popoverIsShownSubj.next(false));
		}
	}

  constructor(
    private el: ElementRef,
    private userQuery: UserQuery,
  private cs: ConfigService,
    private rs: RouteService,
    private ds: DomSanitizer,
    private businessConQuery: BusinessConfigQuery,
    private authService: AuthenticationService
  ) {}
  isOnTapLevelView = false;
  isOnCrmLevelView = true;
	canManageUsers = false;
	canViewUserDetails = false;
  profilePic = '';

  adminBusinessList = '';
  businessBusiness = '';
  dashboard = '';
	userDetails = '';
	manageUsers = '';
	userInfo: User;

  hasBusinessConfig: boolean;

  subs: Subscription[] = [];

  logoutlink = this.ds.bypassSecurityTrustUrl(
    window.location.origin + '/login/logout'
  );
  ngOnInit() {
    const sub4 = this.cs.IsViewTapLevel.subscribe(
      (x) => (this.isOnTapLevelView = x)
    );
    const sub5 = this.cs.IsViewCrmLevel.subscribe(
      (x) => (this.isOnCrmLevelView = x)
    );
		const sub6 = this.rs.RouteChange$.pipe(
			withLatestFrom(this.isTapLevelUser$, this.userQuery.userInfo$)
		).subscribe(([x, isTapLevel, userInfo]) => {
			this.adminBusinessList = x.adminBusinessList;
			this.businessBusiness = x.business;
			this.dashboard = x.dashboard;
			this.userInfo = userInfo;
			this.manageUsers = isTapLevel ? x.adminManageUsers : this.rs.usersList;
			this.userDetails = x.usersView(userInfo?.StaffID);
			this.canViewUserDetails =
				!isTapLevel && !['LG', 'LGI', 'CCA']?.includes(userInfo?.SecurityGroup); // TAPNZ-13088
			this.canManageUsers =
				!isTapLevel && ['AM', 'BM', 'BO']?.includes(userInfo?.SecurityGroup); // TAPNZ-13088
		});
    const sub7 = this.businessConQuery.businessConfig$.subscribe(
      (x) => (this.hasBusinessConfig = x !== null)
    );
    this.subs.push(sub4, sub5, sub6, sub7);
  }
  ngAfterViewInit() {
    const toggleSwitch: HTMLInputElement | null = document.querySelector(
      '.theme-switch input[type="checkbox"]'
    );
    const currentTheme = localStorage.getItem('theme')
      ? localStorage.getItem('theme')
      : null;
    if (currentTheme && this.hasBusinessConfig !== null) {
      document.documentElement.setAttribute('data-theme', currentTheme);

      if (toggleSwitch && currentTheme === 'dark') {
        toggleSwitch.checked = true;
      }
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }

  private togglePopover = () => {
		this.popoverIsShown$
			.pipe(
				take(1),
				map((x) => !x)
			)
			.subscribe((x) => {
        if (x) {
          this.el?.nativeElement.classList.add('menu-item-selected');
        } else {
          this.el?.nativeElement.classList.remove('menu-item-selected')
        }
        this.popoverIsShownSubj.next(x)
      });
  }

  positionPopup () {
    const topAdjustments = 4;
    const parent = this.el?.nativeElement.getBoundingClientRect();
    const menuPosX = parent.x;
    const menuPosY = parent.y + parent.height + topAdjustments;
    return {
      x: menuPosX,
      y: menuPosY
    }
 }

  /**
   * Toggle switch for dark and light theme
   */
  switchTheme(e) {
    if (e.target.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
  }

  ngOnDestroy(): void {
    this.subs?.forEach((x) => x.unsubscribe());
    this.subs = [];
  }
  logout() {
    // TODO:: clear cookies
    // and redirect to login page
    // window.location.href = window.location.host + '/login/logout';
    this.authService.logout().subscribe();
  }
}
