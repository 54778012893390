import { ReplaySubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

export class IsWorkingAccumulator {
  public get isWorking() {
    return this._items.length > 0;
  }
  public get isWorking$() {
    return this.items$.pipe(
      map((x) => x.length > 0),
      distinctUntilChanged()
    );
  }
  private items$: ReplaySubject<number[]>;
  private _items: number[];
  private get items(): number[] {
    return this._items?.map((x) => x);
  }

  constructor() {
    this._items = [];
    this.items$ = new ReplaySubject<number[]>();

    this.items$.subscribe((x) => {
      this._items = x;
    });
  }

  public addIsWorking(): void {
    const a = this.items;
    a.push(1);
    this.items$.next(a);
  }
  public removeIsWorking(): void {
    const a = this.items;
    a.pop();
    this.items$.next(a);
  }
}
