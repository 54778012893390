import { ChangeDetectorRef, Component, Inject, Renderer2 } from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownService } from '@core/dropdown/dropdown.service';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import {
	BusinessConfig,
	SecurityGroup,
} from '@domain/business-config/business-config.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import {
	ConfirmationCallFinalSectionSetting,
	ConfirmationCallQuestion,
	ConfirmationCallQuestionSetting,
	DEFAULT_FINAL_SECTION,
} from '@modules/crt-settings/confirmation-call/states/confirmation-call-question.model';
import { ConfirmationCallSettingsService } from '@modules/crt-settings/confirmation-call/states/confirmation-call-settings.service';
import { SettingsTypes } from '@modules/crt-settings/state/crt-settings.model';
import { InputRadioGroupComponent } from '@shared/components/input-radio-group/input-radio-group.component';
import { InputSelectModule } from '@shared/components/input-select/input-select.module';
import { InputModule } from '@shared/components/input/input.module';
import { LoaderMiniModule } from '@shared/components/loader-mini/loader-mini.module';
import { mtMergeContent } from '@shared/converter/content-merge-tags';
import { EmailModalComponent } from '@shared/modal/crt/email/email-modal.component';
import {
	LoatMergeTagCodes,
	LoatMergeTagTableCodes,
	ServiceAdviceProcessState,
} from '@shared/models/advice-process/advice-process.model';
import { DocumentTypes } from '@shared/models/documents/document.model';
import { EmailTypes } from '@shared/models/emails/crt/email.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { AppFormGroup } from '@util/form-group';
import { objectUtil, util } from '@util/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { clone, uniq } from 'ramda';
import {
	Observable,
	Subject,
	Subscription,
	concat,
	defer,
	forkJoin,
	of,
	zip,
} from 'rxjs';
import {
	delay,
	finalize,
	map,
	mergeMap,
	shareReplay,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ConfirmationCallCompleteModalService } from './confirmation-call-complete-modal.service';
import { ProposedInsuranceState } from '@shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { FinalStructureState } from '@shared/models/client-review-template/final-structure/final-structure.model';
import { ClientAcceptanceState } from '@shared/models/client-review-template/client-acceptance/client-acceptance.model';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ComfirmationCallDocumentStatus } from '@modules/crm/loat/util/loat-enums';
import { LoggerService } from '@core/logger/logger.service';
import { logMessage } from '@shared/error-message/error-message';
import { DocumentGroupState } from '@shared/models/documents/document-group.model';
import { CustomerService } from '@core/customer/customer.service';
import { ViewDropdownMapper } from '@modules/dropdown/views/viewModel/viewDropdown.mapper';
import { MergeTagsCrtSettingsService } from '@modules/crt-settings/merge-tags/state/merge-tags-crt-settings.service';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { RouteService } from '@core/config/route.service';
import MomentUtil from '@util/moment.util';
import { InputDateModule } from '@shared/components/input-date/input-date.module';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { staticConf } from '@core/config/static-config.service';
import { LineBreakPipe } from '@shared/pipe/nextline/linebreak.pipe';

@Component({
	selector: 'app-confirmation-call-complete-modal',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		InputRadioGroupComponent,
		InputModule,
		InputSelectModule,
		LoaderMiniModule,
		InputDateModule,
		LineBreakPipe
	],
	templateUrl: './confirmation-call-complete-modal.component.html',
	styleUrls: ['./confirmation-call-complete-modal.component.scss'],
})
export class ConfirmationCallCompleteModalComponent {
	adviceProcessId = 0;

	adviceProcess: ServiceAdviceProcessState;

	modalSubject: Subject<any>;

	providersDataIsLoading = false;

	conCallIsLoading = false;

	conCallId = 0;

	conCallCompleteData: any;

	isSaving = false;

	APCRTF$ = this.dropdownValueQuery.orderedChoices$('APCRTF');

	formGroup = new AppFormGroup({
		completedBy: new FormControl(null),
		spokeTo: new FormControl(null),
		clientFirstName: new FormControl(null),
		clientLastName: new FormControl(null),
		clientSurname: new FormControl(null),
		emailAddress: new FormControl(null),
		address: new FormControl(null),
		callOutcome: new FormControl(null, [Validators.required]),
		notes: new FormControl(null),
		conCallId: new FormControl(null),
		questionAnswerList: this.fb.array([]),
		adviceProcessId: new FormControl(null),
		providerSection: this.fb.array([]),
	});

	people$ = of([]);

	client: any;

	business: BusinessConfig;

	businessAdmins$: Observable<BLStaff[]>;

	formattedProviders: string;

	staff: BLStaff;

	questionsData: ConfirmationCallQuestionSetting;
	finalSectionData: ConfirmationCallFinalSectionSetting;
	finalSectionValue: string;

	adviser: BLStaff;

	clientAcceptance: ClientAcceptanceState;

	finalStructure: {
		finalStructure: FinalStructureState;
		insurance: ProposedInsuranceState[];
	};

	mergeTags: MergeTagState[];

	people: any[];

	questionsChoices = [
		{
			text: 'Yes',
			value: 'Yes',
		},
		{
			text: 'No',
			value: 'No',
		},
	];

	UNSUCCESS_CALLOUTCOME_ANSWER = 'Unsuccessful';
	successChoices = [
		{
			text: 'Successful',
			value: 'Successful',
		},
		{
			text: 'Unsuccessful',
			value: this.UNSUCCESS_CALLOUTCOME_ANSWER,
		},
	];

	conCallData: any;

	conCallComplete: any;

	getQuestionsRequest$: Observable<any>;

	// Question - It seems that from our records, application had been submitted...
	TERMS_QUESTION_QID = 1;
	MEDICAL_QUESTION_QID = 2;
	CLIENT_ACCEPTANCE_QID = 3;

	OFFER_OF_TERMS = 'Offer of Terms';
	FINAL_STRUCTURE_NO_MEDICAL = 'Standard Rates - No Medical Disclosure';
	
	// get showLifeAssuredQuestion(): boolean {
	// 	if (
	// 		typeof this.finalStructure.finalStructure.underwritingOutcome === 'string'
	// 	) {
	// 		// @ts-ignore-next
	// 		return this.finalStructure?.finalStructure?.underwritingOutcome?.startWith(
	// 			'Standard Rates'
	// 		);
	// 	}
	// 	return false;
	// }

	showLifeAssuredQuestion = false;
	showTermsQuestion = false;
	showAuthorityToProceedQuestion = false;

	emailSettings = null;

	policyOwners: string[] = [];

	documents = null;

	viewMode = false;

	get callOutcome(): AbstractControl {
		return this.formGroup.controls.callOutcome;
	}

	get questionAnswerList() {
		return this.formGroup.controls.questionAnswerList as FormArray<
			FormControl<ConfirmationCallQuestion>
		>;
	}

	get providerSection(): FormArray {
		return this.formGroup.controls.providerSection as FormArray;
	}

	constructor(
		private fb: FormBuilder,
		private confirmationCallSettingsService: ConfirmationCallSettingsService,
		private confirmationCallCompleteModalService: ConfirmationCallCompleteModalService,
		private activatedRoute: ActivatedRoute,
		private bLStaffsQuery: BLStaffsQuery,
		protected dropdownValueQuery: DropdownValueQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private cdr: ChangeDetectorRef,
		private modalService: BsModalService,
		private dService: DropdownService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private doc: HTMLDocument,
		private loggerService: LoggerService,
		protected customerService: CustomerService,
		private mtSettingsService: MergeTagsCrtSettingsService,
		private routeService: RouteService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.people$ = of(
			this.people.map((p) => {
				if (!p.name) {
					p.name = `${p.firstName ?? ''} ${p.lastName ?? ''}`;
				}
				return p;
			})
		);
		this.showLifeAssuredQuestion =
			this.finalStructure?.finalStructure?.underwritingOutcome?.findIndex(
				(u) => u.value === this.FINAL_STRUCTURE_NO_MEDICAL
			) !== -1;
		this.showTermsQuestion =
			this.finalStructure?.finalStructure?.underwritingOutcome?.findIndex(
				(u) => u.value === this.OFFER_OF_TERMS
			) !== -1;

		const ALTERNATE_STRUCTURE = 0;
		this.showAuthorityToProceedQuestion =
			this.clientAcceptance?.presentedSOA === ALTERNATE_STRUCTURE;

		this.businessAdmins$ = this.bLStaffsQuery.selectAll().pipe(
			map((staffs) => {
				return staffs?.filter(
					(s) => s.SecurityGroup === SecurityGroup.BusinessAdmin && s.IsActive
				);
			}),
			shareReplay()
		);

		this.businessConfigQuery.businessConfig$
			.pipe(take(1))
			.subscribe((config) => {
				this.business = config;
			});

		this.formattedProviders = this.formatProviders(
			this.conCallData.providerSection
				.map((p) => p.provider)
				?.filter((p) => Boolean(p))
		);
		this.formGroup.patchValue({
			spokeTo: this.conCallData.clientToCall,
			adviceProcessId: this.adviceProcessId,
		});

		this.getPolicyOwnerEmails();

		this.generateAttachments();

		this.populateProviders(this.conCallData.providerSection);

		const completeData = this.conCallComplete;
		const showCompleteModal =
			completeData?.status === ComfirmationCallDocumentStatus.Completed ||
			(completeData?.status === ComfirmationCallDocumentStatus.Requested &&
				completeData?.callOutcome === this.UNSUCCESS_CALLOUTCOME_ANSWER);
		if (showCompleteModal) {
			this.client = {
				firstName: this.conCallComplete.clientFirstName,
				lastName: this.conCallComplete.clientSurname,
			};
			this.formGroup.patchValue(this.conCallComplete);
			setTimeout(() =>
				this.populateQuestions(
					this.conCallComplete.questionAnswerList?.filter((a) =>
						Boolean(a.answer)
					)
				)
			);
		} else {
			this.onSpokeToValueChanged(this.conCallData.clientToCall);
			this.populateQuestions(this.questionsData.questions);
		}

		this.finalSectionValue = mtMergeContent(this.finalSectionData?.finalSection || DEFAULT_FINAL_SECTION, [
			{
				metaKey: 'ADVISER_NAME',
				value: `${this.adviser.FirstName} ${this.adviser.LastName}`,
			},
			{
				metaKey: 'ADVISER_FIRST_NAME',
				value: this.adviser.FirstName,
			},
			{
				metaKey: 'ADVISER_LAST_NAME',
				value: this.adviser.LastName,
			},
			{
				metaKey: 'BUSINESS_FAP_NAME',
				value: this.business?.FAP,
			},
			{
				metaKey: 'ADVISER_BUSINESS_PHONE',
				value: this.business?.BusinessPhoneNumber,
			},
		] as any,
		false);

		this.confirmationCallSettingsService
			.getEmailSettings(0, SettingsTypes.ConfirmationCall)
			.pipe(
				take(1),
				tap((x) => {
					const carbonCopy = [...new Set(this.policyOwners)].join(', ');
					this.emailSettings = { ...x, carbonCopy };
				})
			)
			.subscribe();
		
		if(this.viewMode){
			this.formGroup.disable();
		}
	}

	cancel(): void {
		this.modalSubject.next(false);
	}

	private populateProviders(providers) {
		const convertDate = (date: string) =>
			MomentUtil.formatToDisplayDate(
				MomentUtil.convertToMoment(date, staticConf.displayDateFormat),
				staticConf.serverDateFormat
			);
		providers.forEach((p) => {
			this.providerSection.push(
				// @ts-ignore-next
				this.fb.group({
					provider: new FormControl(p.provider),
					startDate: new FormControl(convertDate(p.startDate), [
						Validators.required,
					]),
					firstPaymentDate: new FormControl(convertDate(p.firstPaymentDate), [
						Validators.required,
					]),
					frequency: new FormControl(p.frequency || this.conCallData.frequency),
				})
			);
		});
	}

	private populateQuestions(questions: ConfirmationCallQuestion[]): void {
		questions = questions.map((q) => {
			const question = clone(q);
			const lifeAssured = this.getLifeAssured(
				this.people,
				this.finalStructure.insurance
			);

			if (q.qId === this.MEDICAL_QUESTION_QID) {
				// this question changes is specific only for medical question
				question.question = question.question.replace(
					'application',
					lifeAssured.length <= 1 ? 'application' : 'applications'
				);
			}

			question.question = mtMergeContent(
				question.question,
				[
					{
						metaKey: 'ADVISER_NAME',
						value: `${this.adviser.FirstName} ${this.adviser.LastName}`,
					},
					{
						metaKey: 'ADVISER_FIRST_NAME',
						value: this.adviser.FirstName,
					},
					{
						metaKey: 'LIFE_ASSURED',
						value: lifeAssured.text,
					},
					{
						metaKey: 'COMMENCEMENT_DETAILS',
						value: 'Commencement Details',
					},
				] as any,
				false
			);
			return question;
		});
		AppFormGroup.clearFormArray(this.questionAnswerList);
		questions.forEach((q, index) => {
			// do not require answer if life assured question or client acceptance
			// question is hidden
			const lifeAssuredAnswerNotRequired =
				q.qId === this.MEDICAL_QUESTION_QID && !this.showLifeAssuredQuestion;
			const clientAcceptanceAnswerNotRequired =
				q.qId === this.CLIENT_ACCEPTANCE_QID &&
				!this.showAuthorityToProceedQuestion;
			const termsAnswerNotRequired =
				q.qId === this.TERMS_QUESTION_QID && !this.showTermsQuestion;
			const answerValidation =
				lifeAssuredAnswerNotRequired || clientAcceptanceAnswerNotRequired || termsAnswerNotRequired
					? null
					: [Validators.required];

			this.questionAnswerList.push(
				// @ts-ignore-next
				this.fb.group({
					qId: new FormControl(q.qId),
					question: new FormControl(q.question),
					status: new FormControl(q.status),
					isDefault: new FormControl(q.isDefault),
					orderNo: new FormControl(q.orderNo),
					// answer: new FormControl(q.answer, [Validators.required]),
					answer: new FormControl(q.answer, answerValidation as any),
				})
			);
		});
	}

	private getLifeAssured(
		people: any[],
		insurance: ProposedInsuranceState[]
	): {
		text: string;
		length: number;
	} {
		const insuredNames: string[] = [];
		const peopleWithoutMedicalDisclosure =
			this.finalStructure?.finalStructure?.underwritingOutcome
				?.filter((u) => {
					return u.value === this.FINAL_STRUCTURE_NO_MEDICAL;
				})
				?.map((p) => p.lifeAssured) ?? [];
		insurance.forEach((i) => {
			i.lifeAssured?.forEach((life) => {
				const insured = people.find((p) => p.customerId == life.lifeAssured);
				if (!peopleWithoutMedicalDisclosure?.includes(life.lifeAssured)) {
					return;
				}
				if (insured) {
					insuredNames.push(
						insured.name ?? `${insured.firstName} ${insured.lastName}`
					);
				}
			});
		});
		return {
			text:
				this.formatProviders(uniq(insuredNames)) +
				(insuredNames.length ? `'s` : ''),
			length: insuredNames.length,
		};
	}

	getPolicyOwnerEmails() {
		const po = this.finalStructure.insurance
			.map((po) => {
				const id = JSON.parse(po?.policyOwner);
				// multiple policy owners
				if (typeof id === 'object') {
					return id.map((x) => Number(x));
				} else {
					return Number(id);
				}
			})
			.reduce((a, b) => a.concat(b), []);
		const policyOwnerIds = [...new Set(po)];

		policyOwnerIds.forEach((id: number) => {
			this.confirmationCallCompleteModalService
				.getPersonInfoFromClientProfile(id)
				.pipe(take(1))
				.subscribe((people) => {
					if (people?.email) {
						this.policyOwners.push(people?.email ?? '');
					}
				});
		});
	}

	onSpokeToValueChanged(customerId: any): void {
		const person = this.people.find((p) => p.customerId == customerId);
		if (person) {
			this.confirmationCallCompleteModalService
				.getPersonInfo(person.cRTId)
				.pipe(take(1))
				.subscribe((people) => {
					this.client = people;
					this.formGroup.patchValue({
						clientFirstName: people?.businessName ?? people?.firstName ?? '',
						clientSurname: people?.lastName ?? '',
						clientLastName: people?.middleName ?? '',
						emailAddress: people?.email ?? '',
						address: people?.physicalAddress ?? '',
					});
					this.cdr.detectChanges();
				});
		}
	}

	onCompletedByValueChanged(staffId: number): void {
		this.businessAdmins$
			.pipe(
				take(1),
				map((staffs) => staffs.find((s) => +s.StaffID === +staffId)),
				tap((staff) => {
					this.staff = staff;
				})
			)
			.subscribe();
	}

	private getDocumentUrl(doc) {
		if (doc) {
			return this.router.serializeUrl(
				this.router.createUrlTree(this.routeService.viewPdf(doc))
			);
		}
		return null;
	}

	viewDocument(doc) {
		if (doc) {
			if (doc.attachment?.fileUrl) {
				const fileUrl = doc.attachment?.fileUrl;
				const fileExt = fileUrl.split('.').pop();
				if (fileExt === 'pdf') {
					const pdfUrl = this.getDocumentUrl(doc);
					window.open(pdfUrl, '_blank');
				}
				window.open(fileUrl, '_blank');
			} else {
				const pdfUrl = this.getDocumentUrl(doc);
				window.open(pdfUrl, '_blank');
			}
		} else {
			throw Error('Document not found');
		}
	}

	formatProviders(value: string[]): string {
		// @ts-ignore-next
		const formatter = new Intl.ListFormat('en', {
			style: 'long',
			type: 'conjunction',
		});
		return formatter.format(value);
	}

	saveAndExit(): void {
		if (this.isSaving) {
			return;
		}
		if (this.formGroup.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		this.isSaving = true;
		this.confirmationCallCompleteModalService
			.upsert(this.getData())
			.pipe(
				take(1),
				finalize(() => {
					this.isSaving = false;
					this.modalSubject.next(true);
				})
			)
			.subscribe();
	}

	saveAndSendIssue(): void {
		if (this.isSaving) {
			return;
		}
		if (this.formGroup.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		this.isSaving = true;
		const clientId = this.activatedRoute.snapshot.children[0].params.clientId;
		const providers = this.conCallData.providerSection.map((p) => p.provider);

		this.confirmationCallCompleteModalService
			.upsert(this.getData())
			.pipe(
				take(1),
				mergeMap(() => this.sendIssue(clientId, providers, this.adviceProcess)),
				finalize(() => {
					this.isSaving = false;
					this.modalSubject.next(true);
				}),
				map(() => true)
			)
			.subscribe();
	}

	sendEmailFn$ = (data: any) => {
		return this.confirmationCallCompleteModalService.sendRequest(
			data,
			EmailTypes.ConfirmationCall,
			this.adviceProcessId
		);
	};

	getClientDocuments(clientId: number): Observable<any> {
		return of(clientId).pipe(
			mergeMap((x) => this.customerService.GetDocumentsClientId(x)),
			map((x) => {
				return objectUtil.mapPascalCaseToCamelCase(x) as DocumentGroupState;
			})
		);
	}

	updateMTOnRecepientChange = (provider: string) => {
		return new Observable<string>((obs) => {
			obs.next(provider);
			obs.complete();
		}).pipe(
			map((x) => this.concallMergeTags(x, this.mergeTags)),
			take(1)
		);
	};

	private generateAttachments() {
		const hasOfferOfTerms =
			this.finalStructure?.finalStructure?.underwritingOutcome?.some(
				(x) => x?.value === 'Offer of Terms'
			);

		const offerOfTerms = this.finalStructure?.insurance
			?.filter((insurance) => insurance.documentTerm)
			.map((insurance) => {
				return {
					documentId: insurance?.documentTerm?.referenceId,
					name: insurance?.documentTerm?.value,
					provider: insurance?.provider,
				};
			});

		this.documents = {
			finalStructure: {
				documentId: this.finalStructure?.finalStructure?.document?.referenceId,
				name: this.finalStructure?.finalStructure?.document?.value,
			},
			offerOfTerms: hasOfferOfTerms ? offerOfTerms : [],
		};

		if (hasOfferOfTerms) {
			this.documents.offerOfTerms.forEach((doc) => {
				if (doc.documentId) {
					this.confirmationCallCompleteModalService
						.generateAttachment(
							doc.documentId,
							`OFFER OF TERMS_${doc.provider ? doc.provider : doc.documentId}`
						)
						.subscribe((document) => {
							doc.attachment = document;
						});
				}
			});
		}
	}

	private buildEmailModal(
		customerId: number,
		adviceProcess: ServiceAdviceProcessState,
		emailSettings: any,
		providers: any[],
		provider: any,
		mergeTags: MergeTagState[]
	): Observable<boolean> {
		return defer(() => {
			const emailSentSubject = new Subject<boolean>();
			const initState = {
				header: 'Save and Send to Issue',
				disableEmailUpdate: true,
				sent$: of(true),
				showAttachments: true,
				enableDefaultBcc: true,
				ccAdvisers: this.emailSettings?.autoCCAdviser,
				initFormValue: {
					emailRecipient: provider?.parsedValue?.email,
					emailDropdown: provider?.name,
				},
				onModalClosed: () => {
					setTimeout(
						() => this.renderer.addClass(this.doc.body, 'modal-open'),
						500
					);
				},
				decline$: of(true).pipe(
					tap(() => {
						this.isSaving = false;
						this.formGroup.patchValue({
							conCallId: this.conCallId,
						});
					})
				),
				updateMTOnRecepientChange$: this.updateMTOnRecepientChange,
				sendEmailFn$: (data: any) =>
					this.sendEmailFn$(data).pipe(
						delay(500), // wait for the popup to close
						finalize(() => emailSentSubject.next(true))
					),
				// saveEmailFn$: this.saveCrtEmail,
				emailSettings$: of(emailSettings),
				peopleDropdown: of(providers),
				sendToLabel: 'Sent to Provider',
				attachments: this.documents?.offerOfTerms?.map((doc) => {
					delete doc.attachment.fileUrl;
					return doc.attachment;
				}),
				getCrtInfoFn: (value: any) =>
					of(providers).pipe(
						// @ts-ignore-next
						map((p) => {
							const prov = p.find((x) => x.parsedValue.value === value);
							return {
								emailRecipient: prov?.parsedValue?.email ?? '',
								// emailDropdown: provider.name,
							};
						})
					),
				successMessage: 'Issue has been emailed to',
				adviceProcess: adviceProcess,
				businessConfig$: this.businessConfigQuery.businessConfig$,
				mergeTags$: of(mergeTags),
				defaultLinkDocumentTab: ServicesCodes.LR,
				clientDocumentsLoader: () => this.getClientDocuments(customerId),
				documentInfo: {
					documentType: ServicesCodes.LR,
					type: DocumentTypes.ConfirmationCall,
					referenceId: adviceProcess.adviceProcessID,
					customerId: customerId,
				},
			};
			const modalRef = this.modalService.show(EmailModalComponent, {
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				// @ts-ignore-next
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
			return emailSentSubject.asObservable().pipe(
				take(1),
				tap(() => modalRef.hide())
			);
		});
	}

	getMergeTags() {
		return forkJoin([
			this.mtSettingsService.getMergeTags(0, LoatMergeTagTableCodes.General),
			this.mtSettingsService.getMergeTags(1, LoatMergeTagTableCodes.Business),
			this.mtSettingsService.getOtherMergeTags(
				this.conCallId,
				LoatMergeTagTableCodes.ConCall,
				LoatMergeTagCodes.ConCall
			),
			this.mtSettingsService.getOtherMergeTags(
				this.adviceProcessId,
				LoatMergeTagTableCodes.FinalStructure,
				LoatMergeTagCodes.FinalStructure
			),
		]).pipe(
			withLatestFrom(this.mtSettingsService.mergeTags$),
			map(([[g, b, concall, fs], defaults]) => [
				...(defaults || []),
				...(concall || []),
				...(fs || []),
			]),
			tap((x) => (this.mergeTags = x)),
			take(1)
		);
	}

	concallMergeTags(provider: string, mergeTags: MergeTagState[]) {
		const providerMT = 'CONCALL_FIRST_PAYMENT_PROVIDER';
		const firstPaymentDateMT = 'CONCALL_FIRST_PAYMENT_DATE';
		const policyOwner = 'PROVIDER_POLICY_OWNER';
		const frequencyMT = 'CONCALL_FREQUENCY';
		const providers =
			mergeTags?.find((x) => x?.metaKey === providerMT)?.value || [];
		const index = providers?.findIndex((x) => x === provider);

		return mergeTags?.map((mt) => {
			if (mt?.metaKey === providerMT) {
				return {
					...mt,
					value: provider || '',
				};
			}
			if ([firstPaymentDateMT, frequencyMT]?.includes(mt?.metaKey)) {
				return {
					...mt,
					value: mt?.value?.[index] || '',
				};
			}
			if (mt?.metaKey === policyOwner) {
				return {
					...mt,
					value: mt?.value?.join(', ') || '',
				};
			}
			return mt;
		});
	}

	sendIssue(
		clientId: number,
		providers: string[],
		adviceProcess: ServiceAdviceProcessState
	): Observable<boolean> {
		let sub$: Subscription;
		return zip(
			this.dService.dropdownValuesService.getDropdownValuesJson('LRP').pipe(
				map((dropdown) => dropdown.map(objectUtil.mapPascalCaseToCamelCase)),
				take(1)
			),
			this.getMergeTags()
		).pipe(
			map(([providersDropdown, mergeTags]) => {
				const dropdown = providersDropdown.map((d) => {
					const parseValue = ViewDropdownMapper.formatValuePascalToCamel(
						d?.dropdownValue
					);
					d.name = parseValue?.value;
					d.cRTId = parseValue?.value;
					d.parsedValue = parseValue;
					return d;
				});
				return { dropdown, mergeTags };
			}),
			mergeMap(({ dropdown, mergeTags }) => {
				sub$ = this.modalService.onHidden.subscribe(() => {
					// since we are opening multiple modals, we will ensure that modal scroll bar
					// is visible when modal is closed and another modal is open
					setTimeout(
						() => this.renderer.addClass(this.doc.body, 'modal-open'),
						500
					);
				});
				const obs = providers.map((_, index) => {
					const defaultProvider = dropdown.find(
						(d) => d.name === providers[index]
					);
					const mt = this.concallMergeTags(defaultProvider?.name, mergeTags);
					return this.buildEmailModal(
						adviceProcess.customerID,
						adviceProcess,
						this.emailSettings,
						dropdown,
						dropdown.find((d) => d.name === providers[index]),
						mt
					);
				});
				return concat(...obs);
			}),
			finalize(() => sub$?.unsubscribe())
		);
	}

	private getData(): any {
		const questions =
			this.conCallComplete.status === ComfirmationCallDocumentStatus.Completed
				? this.conCallComplete.questionAnswerList?.filter((a) =>
						Boolean(a.answer)
				  )
				: this.questionsData.questions;
		const formValue = this.formGroup.getRawValue();
		formValue.mergedQuestions = clone(formValue.questionAnswerList);
		// remove all html tags from merged question before sending it to B.E.
		formValue.mergedQuestions = formValue.mergedQuestions
			?.map((m) => {
				const lifeAssuredAnswerNotRequired =
					m.qId === this.MEDICAL_QUESTION_QID && !this.showLifeAssuredQuestion;
				const clientAcceptanceAnswerNotRequired =
					m.qId === this.CLIENT_ACCEPTANCE_QID &&
					!this.showAuthorityToProceedQuestion;
				const termsAnswerNotRequired =
					m.qId === this.TERMS_QUESTION_QID && !this.showTermsQuestion;
				if (lifeAssuredAnswerNotRequired || clientAcceptanceAnswerNotRequired || termsAnswerNotRequired) {
					return null;
				}

				m.question = m.question
					?.replaceAll('<b>', '')
					?.replaceAll('</b>', '')
					?.replaceAll('<br>', '')
					?.replaceAll('<br>', '');
				return m;
			})
			?.filter(Boolean);
		// revert merged tag question to its original question string
		questions.forEach((q, index) => {
			if (q.isDefault && formValue.questionAnswerList?.[index]) {
				formValue.questionAnswerList[index].answer =
					formValue.questionAnswerList[index].answer?.toString();
				formValue.questionAnswerList[index].question = q.question;
			}
		});
		formValue.providerSection = formValue.providerSection.map((p) => {
			p.startDate = MomentUtil.formatToServerDate(
				MomentUtil.convertToMoment(p.startDate)
			);
			p.firstPaymentDate = MomentUtil.formatToServerDate(
				MomentUtil.convertToMoment(p.firstPaymentDate)
			);
			return p;
		});
		formValue.CompletedBy = +formValue.completedBy;
		formValue.ConCallId = this.conCallId;
		return formValue;
	}
}
