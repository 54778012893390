import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CrtSettingsRoutingModule } from './crt-settings-routing.module';
import { CrtSettingsComponent } from './crt-settings.component';
import { CrtSettingsResolver } from './crt-settings.resolver';
import { DeclarationSettingsComponent } from './declaration-settings/declaration-settings.component';
import { DeclarationEmailSettingsComponent } from './declaration-settings/declaration-email-settings/declaration-email-settings.component';
import { DeclarationTemplateSettingsComponent } from './declaration-settings/declaration-template-settings/declaration-template-settings.component';
import { IntroPageSettingsComponent } from './intro-page-settings/intro-page-settings.component';
import { ScopeOfServiceSettingsComponent } from './scope-of-service-settings/scope-of-service-settings.component';
import { ScopeOfServiceService } from './scope-of-service-settings/state/scope-of-service.service';
import { CrtSettingsQuery } from './state/crt-settings.query';
import { CrtSettingsStore } from './state/crt-settings.store';
import { SoaSettingsComponent } from './soa-settings/soa-settings.component';
import { SoaSettingsService } from './soa-settings/state/soa-settings.service';
import { SoaDocumentTemplateComponent } from './soa-settings/soa-document-template/soa-document-template.component';
import { MergeTagsComponent } from './merge-tags/merge-tags.component';
import { DeclarationSettingsService } from './declaration-settings/declaration-template-settings/state/declaration-settings.service';
import { IntroSettingsService } from './intro-page-settings/state/intro-settings.service';
import { MergeTagsCrtSettingsService } from './merge-tags/state/merge-tags-crt-settings.service';
import { FinalStructureSettingsComponent } from './final-structure-settings/final-structure-settings.component';
import { FinalStructureEmailSettingsComponent } from './final-structure-settings/final-structure-email-settings/final-structure-email-settings.component';
import { FinalStructureSettingsService } from './final-structure-settings/state/final-structure-settings.service';
import { ClientAcceptanceSettingsComponent } from './client-acceptance-settings/client-acceptance-settings.component';
import { ClientAcceptanceSettingsService } from './client-acceptance-settings/state/client-acceptance-settings.service';
import { ClientAcceptanceSettingsFormComponent } from './client-acceptance-settings/client-acceptance-settings-form/client-acceptance-settings-form.component';
import { AuthorityToProceedSettingsComponent } from './authority-to-proceed-settings/authority-to-proceed-settings.component'
import { AuthorityToProceedEmailSettingsComponent } from './authority-to-proceed-settings/authority-to-proceed-email-settings/authority-to-proceed-email-settings.component';
import { AuthorityToProceedSettingsService } from './authority-to-proceed-settings/state/authority-to-proceed-settings.service';
import { MergeTagsWordingComponent } from './soa-settings/merge-tags-wording/merge-tags-wording.component'
import {
  DeclarationEmailSettingsService
} from './declaration-settings/declaration-email-settings/state/declaration-email-settings.service';
import { EndProcessComponent } from './end-process/end-process.component';
import { EndProcessEmailSettingsComponent } from './end-process/end-process-email-settings/end-process-email-settings.component';
import { EndProcessEmailSettingsService } from './end-process/state/end-process.service';
import { DocumentListService } from './soa-settings/document-list/state/document-list.service';
import { SoaDocumentListComponent } from './soa-settings/document-list/soa-document-list.component';
import { SoaDocumentListFormComponent } from './soa-settings/document-list/document-list-form/soa-document-list-form.component';
import { SoaEmailSettingsComponent } from './soa-settings/soa-email-settings/soa-email-settings.component';
import { LoatCffTemplateSettingsComponent } from './loat-cff-template-settings/loat-cff-template-settings.component';
import { LoatCffTemplateSettingsService } from './loat-cff-template-settings/state/loat-cff-template-settings.service'
import { ConfirmationCallQuestionComponent } from './confirmation-call/confirmation-call-question/confirmation-call-question.component';
import { ConfirmationCallComponent } from './confirmation-call/confirmation-call.component';
import { ConfirmationCallEmailSettingComponent } from './confirmation-call/confirmation-call-email-setting/confirmation-call-email-setting.component';

@NgModule({
  declarations: [
    CrtSettingsComponent,
    IntroPageSettingsComponent,
    ScopeOfServiceSettingsComponent,
    DeclarationSettingsComponent,
    DeclarationTemplateSettingsComponent,
    DeclarationEmailSettingsComponent,
    SoaSettingsComponent,
    SoaDocumentTemplateComponent,
    MergeTagsComponent,
    FinalStructureSettingsComponent,
    FinalStructureEmailSettingsComponent,
    ClientAcceptanceSettingsComponent,
    ClientAcceptanceSettingsFormComponent,
    AuthorityToProceedSettingsComponent,
    AuthorityToProceedEmailSettingsComponent,
    MergeTagsWordingComponent,
    EndProcessComponent,
    EndProcessEmailSettingsComponent,
    SoaDocumentListComponent,
    SoaDocumentListFormComponent,
    SoaEmailSettingsComponent,
    LoatCffTemplateSettingsComponent,
    ConfirmationCallQuestionComponent,
    ConfirmationCallComponent,
    ConfirmationCallEmailSettingComponent,
  ],
  imports: [CommonModule, SharedModule, CrtSettingsRoutingModule],
  providers: [
    CrtSettingsResolver,
    CrtSettingsStore,
    CrtSettingsQuery,

    ScopeOfServiceService,
    IntroSettingsService,
    SoaSettingsService,
    DeclarationSettingsService,
    DeclarationEmailSettingsService,
    MergeTagsCrtSettingsService,
    FinalStructureSettingsService,
    ClientAcceptanceSettingsService,
    AuthorityToProceedSettingsService,
		EndProcessEmailSettingsService,
    DocumentListService,
		LoatCffTemplateSettingsService
  ],
})
export class CrtSettingsModule {}
