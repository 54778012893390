import { StatementOfAdviceService } from './../states/statement-of-advice/statement-of-advice.service';
import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { StatementOfAdviceQuery } from '../states/statement-of-advice/statement-of-advice.query';

@Injectable()
export class AuthToProceedGuard implements CanActivate {
	constructor(
		private router: Router,
		private query: ClientReviewTemplateQuery,
		private soaQuery: StatementOfAdviceQuery,
		private statementOfAdviceService: StatementOfAdviceService
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const code = next.paramMap.get('companyCode');
		const customerType = next.paramMap.get('customerType');
		const clientId = next.paramMap.get('clientId');
		const adviceProcessId = next.paramMap.get('adviceProcessId');
		const returnUrl = `${code}/crt/${customerType}/${clientId}/advice-process/${adviceProcessId}/statement-of-advice`;

		const adviceProcess$ = this.statementOfAdviceService.getAdviceProcess(
			next.paramMap.get('adviceProcessId')
		);
		const statemenOfAdvice$ = this.statementOfAdviceService.getSoa(
			+next.paramMap.get('adviceProcessId')
		);

		return zip(adviceProcess$, statemenOfAdvice$).pipe(
			map(([ap, soa]) => {
				const hasDocument = ap?.documents?.some(
					(x) => x?.field === 'Statement of Advice' && !!x?.value
				);

				const hasFinialized = soa.some((x) => x.currentPage === 'Finalized');

				const isAdviceProcessEnded = ap.status > 2  && ap.status < 5;

				return (hasDocument || hasFinialized) && !isAdviceProcessEnded;
			}),
			tap((x) => {
				if (!x) {
					this.router.navigateByUrl(returnUrl);
				}
			})
		);
	}
}
