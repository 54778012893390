<div class="clearfix">
	<div
		class="form-row font-weight-bold d-none d-lg-flex mb-2"
		[class.tap-opacity-25]="(!kiwiSavers || kiwiSavers?.length === 0) && !isAdd"
	>
		<div class="col-12 col-lg-4">Owner</div>
		<div class="col-12 col-lg-3">Fund Type</div>
		<div class="col-12 col-lg-2">Provider</div>
		<div class="col-12 col-lg-2">Current Balance</div>
	</div>

	<ng-content select="app-manual-loader-mini"></ng-content>

	<div class="mb-4">
		<app-crt-kiwisaver-template-form
			*ngFor="
				let ks of kiwiSavers;
				let i = index;
				let last = last;
				trackBy: trackByFn
			"
			[formId]="i"
			[kiwiSaver]="ks"
			[lifeAssuredList$]="lifeAssuredList$"
			[lifeAssuredList]="lifeAssuredList$ | async"
			[KP$]="KP$"
			[KFT$]="KFT$"
			[KFT]="KFT$ | async"
			[APCRTRP$]="APCRTRP$"
			[addFn$]="addFn$"
			[updateFn$]="updateFn$"
			[deleteFn$]="deleteFn$"
			[crmKiwiSavers]="crmKiwiSavers"
			[crmKiwiSaversChoinces]="crmKiwiSaversChoinces"
			[crtKiwiSavers]="kiwiSavers"
			[transferedSCIList]="transferedSCIList"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="d-block mb-2"
			[class.border-bottom]="!last"
			[getOwnerChoices]="getOwnerChoices"
		></app-crt-kiwisaver-template-form>
	</div>

	<div class="clearfix mb-4">
		<app-crt-kiwisaver-template-form
			*ngIf="isAdd"
			[addMode]="true"
			[kiwiSaver]="{}"
			[lifeAssuredList$]="lifeAssuredList$"
			[lifeAssuredList]="lifeAssuredList$ | async"
			[KP$]="KP$"
			[KFT$]="KFT$"
			[KFT]="KFT$ | async"
			[APCRTRP$]="APCRTRP$"
			[addFn$]="addFn$"
			[crmKiwiSavers]="crmKiwiSavers"
			[crmKiwiSaversChoinces]="crmKiwiSaversChoinces"
			[crtKiwiSavers]="kiwiSavers"
			[transferedSCIList]="transferedSCIList"
			[adviceProcessId]="adviceProcessId"
			(cancelEvent)="cancelEvent($event)"
			(saveEvent)="saveEvent($event)"
			class="mb-2"
			[getOwnerChoices]="getOwnerChoices"
		></app-crt-kiwisaver-template-form>
	</div>

	<button
		id="kiwisaverAddKiwiSaverButton"
		(click)="add()"
		type="button"
		[disabled]="isAdd"
		class="tap-btn tap-btn-outline tap-btn--shadow px-4"
	>
		Add KiwiSaver +
	</button>
</div>
