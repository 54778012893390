import {
  Directive,
  Input,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Model, ModelFactory } from '../../../core/base/model.service';
import { UserQuery } from '../../../domain/user/user.query';
import { distinctUntilChanged, combineLatest } from 'rxjs/operators';

@Directive({
  selector: '[appRestrictTo]',
})
export class RestrictToDirective {
  hasView: boolean;
  allowed: Model<string[]>;
  allowed$: Observable<string[]>;
  unallowed: Model<string[]>;
  unallowed$: Observable<string[]>;
  /**
   * If roles specified here exists in user roles, then show
   */
  @Input()
  set appRestrictTo(value: string[]) {
    // this.appRestrictTo$.next(value);
    this.allowed.set(value);
  }
  /**
   * If roles specified here exists in user roles, then do not show
   */
  @Input()
  set not(value: string[]) {
    this.unallowed.set(value);
  }
  constructor(
    private userQuery: UserQuery,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private rolesModelFactory: ModelFactory<string[]>
  ) {
    this.hasView = false;
    this.allowed = rolesModelFactory.create([]);
    this.unallowed = rolesModelFactory.create([]);
    this.allowed$ = this.allowed.data$;
    this.unallowed$ = this.unallowed.data$;
    this.userQuery.userInfo$
      .pipe(
        distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
        combineLatest(
          this.allowed$,
          this.unallowed$
        )
      )
      .subscribe(([user, allowed, unallowed]) => {
        this.update(allowed, unallowed, user.SecurityRoles);
      });
  }

  update(
    allowedRoles: string[],
    unallowedRoles: string[],
    userRoles: string[]
  ) {
    const isAllowed: boolean =
      allowedRoles?.some((x) =>
        userRoles?.some((y) => y?.toLowerCase() === x?.toLowerCase())
      ) &&
      !unallowedRoles?.some((x) =>
        userRoles?.some((y) => x?.toLowerCase() === y?.toLowerCase())
      );
    if (isAllowed && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAllowed && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
