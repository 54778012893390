import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo, tap, take, mergeMap } from 'rxjs/operators';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { AdviceProcessService } from './states/advice-process.service';

const dropdownCodes: string[] = ['AP', 'PCLT', 'PCLE', 'AT', 'APCRTAS', 'LS', 'CCO','LRPR','FGPL'];

@Injectable()
export class AdviceProcessResolver implements Resolve<boolean> {
	constructor(private dropdownValueService: DropdownValueService, private service: AdviceProcessService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		// this.service.clear();
		return forkJoin([dropdown$]).pipe(
			tap(() => this.service.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.service.getColumnWidths().pipe(take(1)).subscribe()),
			mergeMap(() => this.service.getAdviceStages()),
			mapTo(true)
		);
	}
}
