import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { viewSecGroup } from '../../../viewmodels/viewSecGroup';
import { BlStaffViewmodel } from '../../../viewmodels/bl-staff.viewmodel';
import { tap, mergeMap } from 'rxjs/operators';
import { RouteService } from '../../../../../core/config/route.service';
import { BLStaffsService } from '../../../../../domain/bl-staff/bl-staffs.service';
import { BLStaffSecGroupsQuery } from '../../../../../domain/bl-staff-security-group/bl-staff-security-groups.query';
import { AddUserPhotoRequest, BlStaffModelAdd } from '../../../../../shared/models/_general/bl-staff.model';
import { BusinessConfigService } from 'src/app/domain/business-config/business-config.service';
import { UserQuery } from '@domain/user/user.query';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';

@Component({
  selector: 'app-bl-add',
  templateUrl: './bl-add.component.html'
})
export class BlAddComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private routeService: RouteService,
    private blStaffService: BLStaffsService,
    private blStaffSecGroupQuery: BLStaffSecGroupsQuery,
    private businessConfigService: BusinessConfigService,
		private userQuery: UserQuery,
		protected dropdownValueQuery: DropdownValueQuery
  ) { }
  subs: Subscription[] = [];
  staff: BlStaffViewmodel;
  secGroups: viewSecGroup[] = [];
	companyCode: string = this.businessConfigService.companyCode();
	isTapLevel = this.userQuery.isTapLevel();
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;
	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');
	SCS$ = this.dropdownValueQuery.orderedChoices$('SCS');
	SAS$ = this.dropdownValueQuery.orderedChoices$('SAS');
	SCT$ = this.dropdownValueQuery.orderedChoices$('SCT');
	SQCKI$ = this.dropdownValueQuery.orderedChoices$('SQCKI');

  ngOnInit() {
    const data = new BlStaffViewmodel();

    this.staff = {
			...data,
			Stats: {
				...data?.Stats,
				lr_conversion : null,
				kiwisaver_conversion : null,
				blanket_conversion : null,
				mortgage_conversion : null,
			}
		};
    const sg = this.blStaffSecGroupQuery.getAll();
    this.secGroups = viewSecGroup.mapSecGroups(sg);
  }

  submitAdd = (v: BlStaffViewmodel) =>
    new Observable<BlStaffModelAdd>(obs => {
      const m = BlStaffViewmodel.mapToAddModel({ ...v, AdviserManager: 0 });
      obs.next(m);
      obs.complete();
    }).pipe(
      mergeMap(x => this.blStaffService.add(x)),
      tap((x) => this.goToUser(x))
    )

	addPhoto$ = (req: AddUserPhotoRequest) => this.blStaffService.addPhoto(req);

  ngOnDestroy(): void {
    this.subs?.forEach(s => s.unsubscribe());
  }

  goToUser(id?: number) {
    this.router.navigate(
      this.routeService.splitToRoutePath(this.routeService.usersView(+id))
    );
  }

  cancel() {
    this.router.navigate(
      this.routeService.splitToRoutePath(this.routeService.usersList)
    );
  }
}
