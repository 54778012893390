import { Injectable } from '@angular/core';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { UserQuery } from '@domain/user/user.query';
import { environment as env } from '@environment';
import { objectUtil } from '@util/util';
import { Observable, of, zip } from 'rxjs';
import { delay, map, mergeMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { subtract } from 'ramda';

interface BuildLabel {
	icon: string;
	content: string;
}

export interface EmailSignature {
	referenceId: 0;
	type: 'ES';
	isActive: boolean;
	isLogoChecked: boolean;
	isCellphoneNumberChecked: boolean;
	isOfficePhoneNumberChecked: boolean;
	isAddressChecked: boolean;
	isMailAddressChecked: boolean;
	mailAddress: string;
	isWebsiteURLChecked: boolean;
	settingsId: number;
}

export interface EmailSignatureDetails {
	FirstName: string;
	LastName: string;
	BusinessPhone?: string;
	MobilePhone?: string;
}

@Injectable({ providedIn: 'root' })
export class EmailSignatureService {
	private emailSignatureLogo: {
		businessCode: string;
		url: string;
		height: number;  // Orig height
		width: number; // Orig width
		newHeight: number; // Recomputed height
		newWidth: number; // Recomputed width
	};
	readonly FONT_FAMILY = `font-family: 'Open Sans', sans-serif`;
	readonly VERTICAL_CENTER = `display:flex;align-items:center;`;
	readonly IMG_SOURCE = `${env.apiProtocol}//${env.apiUrl}/Resources/Images/email-signature/`;
	readonly SIG_WIDTH = 600; // Signature container max width
	readonly IMG_HEIGHT = 50; // Image max height
	readonly IMG_WIDTH = 240; // Image max width
	readonly MAX_WIDTH = `max-width:${this.SIG_WIDTH}px`; // style

	constructor(
		private apiService: ApiService,
		private userQuery: UserQuery,
		private businessQuery: BusinessConfigQuery
	) {}

	getStaff(): Observable<any> {
		const staffId = this.userQuery.getValue().EmailAddress;
		const url = `staff?email=${staffId}`;
		return this.apiService.get(url);
	}

	getTemplate(data?: EmailSignatureDetails): Observable<string> {
		this.buildEmailLogo();
		return this.getSettings().pipe(
			delay(100),
			mergeMap((setting) =>
				setting.isActive ? this.buildTemplate(setting, data) : of('')
			),
			map(
				(template) =>
					`<div style="${this.MAX_WIDTH}; width:100%; display:block;" contenteditable="false" id="email-signature-template">${template}</div>`
			)
		);
	}

	/**
	 * convert img via url to base 64
	 */
	private buildHeader(
		setting: EmailSignature,
		data?: EmailSignatureDetails
	): Observable<string> {
		const { PrimaryColor, Logo } = this.businessQuery.getValue()?.config;
		const user = this.userQuery.getValue();
		const FirstName = data ? data?.FirstName : user?.FirstName;
		const LastName = data ? data?.LastName : user?.LastName;
		const h1Style = `
			${this.FONT_FAMILY};
			color: ${PrimaryColor};
			font-size: 19px;
			border-bottom: 0px;
			padding: 0px;
			margin-bottom: 0px;
			display: flex;
			font-weight: 600;
		`;
		const headerStyle = `
			${this.FONT_FAMILY};
			display: block;
			color: ${PrimaryColor};
			font-size: 19px;
			font-weight: 600;
			width: 100%;
			table-layout: fixed;
			overflow-wrap: break-word;
			${this.MAX_WIDTH};
		`;
		if (!setting?.isActive) {
			return of(
				`<div style="${h1Style}" id="email-signature-name">${FirstName || ''} ${
					LastName || ''
				}</div>`
			);
		}
		const logo = this.emailSignatureLogo;
		const fullName = `
			<span style="font-weight: 600">${FirstName || ''} ${LastName || ''}</span>
		`;
		const img =
			setting?.isLogoChecked && logo?.url
				? `<a style="display:block;" target="_blank" rel="nofollow noreferrer">
					<img src="${logo.url ?? encodeURI(Logo)}" height="${
						logo.newHeight || this.IMG_HEIGHT
				  }" width="${logo.newWidth || 'auto'}" style="vertical-align:middle;">
				</a>`
				: '';

		return of(
			`<table style="${headerStyle}">
				<tbody style="height:${this.IMG_HEIGHT}px; width: 100%; ${this.MAX_WIDTH};">
					<tr style="height:${this.IMG_HEIGHT}px; width: 100%; ${this.MAX_WIDTH};">
						<td width="60%" style="padding: 0px; border: none; max-width: ${subtract(
							this.SIG_WIDTH,
							this.IMG_WIDTH
						)}px;">${fullName}</td>
						<td width="${
							this.IMG_WIDTH
						}" style="padding: 0px; border: none; text-align: right; height:${
				this.IMG_HEIGHT
			}px; max-width: ${this.IMG_WIDTH}px;">${img}</td>
					</tr>
				</tbody>
			</table>
			<div style="width: 100%; ${
				this.MAX_WIDTH
			}; margin-top: 10px; margin-bottom: 10px;">
				<hr style="border-color: ${PrimaryColor};">
			</div>`
		);
	}

	private buildImage(filename: string): string {
		return `<img src="${this.IMG_SOURCE}${filename}" style="height: 10px;width: 10px;float:left;">`;
	}

	private buildPhone(
		setting: EmailSignature,
		data?: EmailSignatureDetails
	): Observable<BuildLabel | null> {
		return this.getStaff().pipe(
			mergeMap((staff) => {
				if (
					!setting.isCellphoneNumberChecked &&
					!setting.isOfficePhoneNumberChecked
				) {
					return of(null);
				}
				const phoneNumber = setting.isOfficePhoneNumberChecked
					? data
						? data?.BusinessPhone
						: staff.BusinessPhone
					: '';
				const mobileNumber = setting.isCellphoneNumberChecked
					? data
						? data?.MobilePhone
						: staff.MobilePhone
					: '';
				const separator =
					Boolean(phoneNumber?.trim()) && Boolean(mobileNumber?.trim())
						? ' | '
						: '';
				return of({
					icon:
						setting.isCellphoneNumberChecked ||
						setting.isOfficePhoneNumberChecked
							? this.buildImage('phone.png')
							: '',
					content: `${phoneNumber || ''}${separator}${mobileNumber || ''}`,
				});
			})
		);
	}

	private buildAddress(setting: EmailSignature): Observable<BuildLabel | null> {
		const { Address1, Address2, City, Postcode, Country } =
			this.businessQuery.getValue()?.config;
		if (!setting.isAddressChecked) {
			return of(null);
		}
		return of({
			icon: this.buildImage('location.png'),
			// content: `${Address1} ${Address2} ${City} ${Postcode}`,
			content: [Address1, Address2, City, Country, Postcode]
				.filter((a) => Boolean(a?.trim()))
				.join(', '),
		});
	}

	private buildMailingAddress(
		setting: EmailSignature
	): Observable<BuildLabel | null> {
		if (!setting.isMailAddressChecked) {
			return of(null);
		}
		return of({
			icon: this.buildImage('envelop.png'),
			content: setting.mailAddress,
		});
	}

	private buildWebsiteURL(
		setting: EmailSignature
	): Observable<BuildLabel | null> {
		const { Website, PrimaryColor } = this.businessQuery.getValue()?.config;
		if (!setting.isWebsiteURLChecked) {
			return of(null);
		}
		return of({
			icon: this.buildImage('link.png'),
			content: `<a style="text-decoration:none;color:${PrimaryColor};" href="${Website}" target="_blank">${Website}</a>`,
		});
	}

	private buildEmailLogo() {
		const { Logo, BusinessCode } = this.businessQuery.getValue()?.config;
		if (
			Logo &&
			(!this.emailSignatureLogo ||
				BusinessCode !== this.emailSignatureLogo?.businessCode)
		) {
			let img = new Image();
			let width = 0;
			let height = 0;
			img.src = Logo;
			const self = this;
			img.onload = function (event) {
				let loadedImage = event.currentTarget as HTMLImageElement;
				width = loadedImage.width;
				height = loadedImage.height;
				const hRatio = height > self.IMG_HEIGHT ? height / self.IMG_HEIGHT : 1;
				let recomputedWidth =
					height > self.IMG_HEIGHT ? Math.ceil(width / hRatio) : width;
				let recomputedHeight =
					height > self.IMG_HEIGHT ? self.IMG_HEIGHT : height;

				if (recomputedWidth > self.IMG_WIDTH) {
					const wRatio = width / self.IMG_WIDTH;
					recomputedWidth = self.IMG_WIDTH;
					recomputedHeight = Math.ceil(height / wRatio);
				}

				self.emailSignatureLogo = {
					businessCode: BusinessCode,
					url: encodeURI(Logo),
					width,
					height,
					newWidth: recomputedWidth || width,
					newHeight: recomputedHeight || height,
				};
			};
		}
	}

	private buildTemplate(
		setting: EmailSignature,
		data?: EmailSignatureDetails
	): Observable<string> {
		return zip(
			this.buildHeader(setting, data),
			this.buildPhone(setting, data),
			this.buildAddress(setting),
			this.buildMailingAddress(setting),
			this.buildWebsiteURL(setting)
		).pipe(
			take(1),
			map((result) => {
				let table = result.reduce((prev, cur) => {
					if (!cur) {
						return prev;
					}
					if (typeof cur === 'string') {
						prev += cur;
						prev += `
						<table style="width:100%;font-size: 10px;font-weight: 600;">
							<colgroup>
								<col span="1" width="3%"/>
							</colgroup>
						`;
						return prev;
					}
					if (cur.content) {
						prev += `
							<tr>
								<td style="padding: 0px;border: none;">${cur.icon}</td>
								<td style="padding: 0px;border: none;">${cur.content}</td>
							</tr>
						`;
					}
					return prev;
				}, '') as string;
				table += '</table>';
				return table;
			})
		);
	}

	getSettings(): Observable<EmailSignature> {
		const url = `staff/0/settings/ES`;
		return this.apiService.get(url).pipe(
			map((res) => {
				if (res?.[0]) {
					return objectUtil.mapPascalCaseToCamelCase(res?.[0]);
				}
				return {
					referenceId: 0,
					type: 'ES',
					isActive: false,
					isLogoChecked: false,
					isCellphoneNumberChecked: false,
					isOfficePhoneNumberChecked: false,
					isAddressChecked: false,
					isMailAddressChecked: false,
					mailAddress: '',
					isWebsiteURLChecked: false,
					settingsId: 0
				};
			})
		);
	}

	upsert(data: EmailSignature): Observable<EmailSignature> {
		return data.settingsId ? this.update(data) : this.add(data);
	}

	update(data: EmailSignature): Observable<EmailSignature> {
		const url = `staff/settings/${data.settingsId}`;
		return this.apiService
			.put(url, objectUtil.mapCamelCaseToPascalCase(data))
			.pipe(map((result) => data));
	}

	add(data: EmailSignature): Observable<EmailSignature> {
		const url = `staff/settings`;
		return this.apiService
			.post(url, objectUtil.mapCamelCaseToPascalCase(data))
			.pipe(
				map((settingsId: number) => {
					data.settingsId = settingsId;
					return data;
				})
			);
	}
}
