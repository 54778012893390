<span class="tap-required" *ngIf="isEditing">*</span>
<div #longText class="field">
  <div>
    {{ value }}
  </div>
</div>

<ng-container *ngIf="!restrict; else hasRestriction;">
  <div *ngIf="isEditable" class="control">
    <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="editTextareaModal()" id="{{fieldId}}EditBtn_{{index}}">
      <i class="material-icons md-16 dark">&#xE3C9;</i>
    </button>
  </div>
</ng-container>

<ng-template #hasRestriction>
  <ng-container *appRestrictTo="restrict">
    <div *ngIf="isEditable" class="control">
      <button *ngIf="!isEditing" type="button" class="icon-btn-bg btn-edit" (click)="editTextareaModal()" id="{{fieldId}}EditBtn_{{index}}">
        <i class="material-icons md-16 dark">&#xE3C9;</i>
      </button>
    </div>
  </ng-container>
</ng-template>

<div>
  <app-manual-loader-mini *ngIf="isLoading" 
    [isLoading]="isLoading" class="loader-mini">
  </app-manual-loader-mini>
</div>