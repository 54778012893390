<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
    <span class="material-icons">
      close
    </span>
  </button>
  <div>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ header }}
      </h5>
    </div>
    <div class="modal-body">
      <div class="text-center" style="word-break: break-all;">
        <p>{{ message }}</p>
      </div>
      <div class="form-group m-0">
        <label class="my-1">Reason</label>
        <div>
          <textarea id="reason" maxlength="250" class="form-control" cols="30" rows="6" [(ngModel)]="reason"></textarea>
        </div>
      </div>
      <div class="justify-content-end float-right modal-footer">
        <ng-container *ngIf="!isSaving; else loader">
          <button id="confirmModalButton" type="button" class="tap-btn tap-btn--default mr-2" (click)="confirm(reason)">
            Confirm
          </button>
          <button id="cancelModalButton" type="button" class="tap-btn tap-btn--default" (click)="decline()">
            Cancel
          </button>
        </ng-container>
        <ng-template #loader>
          <app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
        </ng-template>
      </div>
    </div>
  </div>
  