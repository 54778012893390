import { StatementOfAdviceMapper as SoaMapper } from '../../../../../shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';

export const soaTemplate = (data: any) => {
	const proposedTable = [];
	// tslint:disable-next-line: prefer-for-of
	for (let i = 0; i < data.proposedInsurance?.length; i++) {
		const td = TR(
			data.proposedInsurance[i].provider,
			data.proposedInsurance[i].lifeAssured,
			data.peoples
		);
		proposedTable.push(`<tr>${td}</tr>`);
	}

	const combinedTR = `${proposedTable?.join('').toString()}`;
	const iorWe = data?.signatures?.length > 1 ? 'We' : 'I';
	const contIorWe = data?.signatures?.length > 1 ? 'we' : 'I';

	return `<span class="heading">Authority to Proceed:</span>
	<p>${iorWe} understand the Statement of Advice that was presented${
		!data.isProposedInsurance
			? `, however, ${contIorWe} would like to proceed to applications based on the structure outlined below`
			: ` and would like to proceed with the recommended insurance applications`
	}.</p>
	<br />
  <table style="width: 100%">
		<thead>
			<tr>
				<th style="width:16%;">Life Assured</th>
				<th style="width:16%;">Product</th>
				<th style="width:16%;">Benefit</th>
				<th style="width:16%;">Provider</th>
				<th style="width:36%;">Explanation / Notes</th>
			</tr>
		</thead>
		<tbody>${combinedTR}</tbody>
	</table>
  <br />
	<p class="atp-wordings">${data.caSettings}</p>`;
};

const TR = (provider: string, lifeAssured: any[], peoples: any[]) => {
	const tds = [];
	// tslint:disable-next-line: prefer-for-of
	for (let i = 0; i < lifeAssured.length; i++) {
		const people = peoples.find(
			(x) => +x.value === +lifeAssured[i].lifeAssured
		);
		if (!people) {
			continue;
		}

		const td = `
      <td style="width:16%;">${!!people ? people?.display : ''}</td>
      <td style="width:16%;">${
				!!lifeAssured && lifeAssured[i] ? lifeAssured[i]?.product : ''
			}</td>
      <td style="width:16%;">${
				!!lifeAssured && lifeAssured[i]
					? SoaMapper.formatBenefit(lifeAssured[i])
					: ''
			}</td>
      <td style="width:16%;">${!!provider ? provider : ''}</td>
      <td style="width:36%;">${
				!!lifeAssured && lifeAssured[i] ? lifeAssured[i].notes : ''
			}</td>
    `;
		tds.push(`<tr>${td}</tr>`);
	}
	return tds?.join('').toString();
};
