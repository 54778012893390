import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ToastStatus = 'info' | 'error' | 'warning' | 'success';

export interface ToastMessageState {
	header: string;
	message: string;
	allowHtmlMessage?: boolean;
	status?: ToastStatus;
	containerClass?: string;
}

@Injectable()
export class ToastService {
	log$ = new BehaviorSubject<ToastMessageState>(null);

	constructor() {}

	Info(obj: ToastMessageState) {
		this.log$.next({ ...obj, status: 'info' });
	}

	Error(obj: ToastMessageState) {
		this.log$.next({ ...obj, status: 'error' });
	}

	Warning(obj: ToastMessageState) {
		this.log$.next({ ...obj, status: 'warning' });
	}

	Success(obj: ToastMessageState) {
		this.log$.next({ ...obj, status: 'success' });
	}
}
