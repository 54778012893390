import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';
import { Observable, Observer, ReplaySubject } from 'rxjs';
import { NoteState } from '../models/notes/note.model';
import { DeleteModalComponent } from '../modal/delete-modal/delete-modal.component';
import { filter, tap } from 'rxjs/operators';
import { NoteAddModalComponent } from '../modal/note-modal/note-modal.component';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ServicesCodes } from '@shared/models/services/services.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';

@Component({
	selector: 'app-table-paginate-notes',
	templateUrl: './table-paginate-notes.component.html',
	styleUrls: ['./table-paginate-notes.component.scss'],
})
export class TablePaginateNotesComponent implements OnInit, OnChanges {
	@Input() isLead: boolean;
	@Input() header = 'Notes';
	@Input() customerId: number;
	@Input() customerServiceId: number;
	@Input() adviceProcessId: number;
	@Input() count = 0;
	@Input() itemsPerPage = 5;
	@Input() maxSize = 5;
	@Input() notes: (NoteState & { isDeleting?: boolean })[];
	@Input() hasAddNote = false;
	@Input() hasPaging = false;
	@Input() getFn$: (req: any) => Observable<any>;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;
	@Input() refreshNotes$: Observable<any>; // Manual refresh/refetch of notes
	@Input() isLoading: boolean;
	@Input() isService: boolean;
	@Input() isHistory: boolean;
	@Input() hideNote: boolean;
	@Input() hideEmail: boolean = true;
	@Output() deleteEvent = new EventEmitter<any>();
	@Input() documentType: string;

	@Input() showHeader: boolean = true;
	@Input() isCompany: boolean = false;

	currentpage = 1;
	noteList: (NoteState & { isDeleting?: boolean })[] = [];
	isAddingNote$ = new ReplaySubject<boolean>(1);
	isLoading$ = new ReplaySubject<boolean>(1);

	emailClientTemplateFeature$ =
		this.businessConfigQuery.emailClientTemplateFeature$;

	constructor(
		private modalService: BsModalService,
		private clientProfileService: ClientProfileService,
		private businessProfileService: BusinessProfileService,
		private cd: ChangeDetectorRef,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	get hasPagingTable() {
		return !!this.hasPaging;
	}

	get getNoteList() {
		return !!this.notes ? this.notes : [];
	}

	get getItemsPerPage() {
		return !!this.itemsPerPage ? +this.itemsPerPage : 5;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.notes) {
			const startItem = (this.currentpage - 1) * this.itemsPerPage;
			const endItem = this.currentpage * this.itemsPerPage;
			const data = changes.notes.currentValue;
			this.notes = data;
			this.noteList =
				data && data.length > 0
					? this.hasPaging
						? data?.length > this.itemsPerPage
							? data?.slice(startItem, endItem)
							: data
						: data?.slice(startItem, endItem)
					: [];

			// Changes for Services only
			if (this.isService || this.isHistory) {
				this.setPage({
					page: this.currentpage,
					itemsPerPage: this.getItemsPerPage,
				});
			}
		}
		if (
			(!!changes.customerId || !!changes.customerServiceId) &&
			(changes.customerId?.currentValue !== undefined ||
				changes.customerServiceId?.currentValue !== undefined)
		) {
			this.setPage({
				page: this.currentpage,
				itemsPerPage: this.getItemsPerPage,
			});
		}
	}

	ngOnInit() {
		this.refreshNotes$
			?.pipe(
				filter((x) => !!x),
				tap(() => (this.currentpage = 1)),
				tap(() =>
					this.setPage({
						page: this.currentpage,
						itemsPerPage: this.getItemsPerPage,
					})
				)
			)
			.subscribe();
	}

	trackByFn(item: any) {
		return item.notesID;
	}

	setPage(event: PageChangedEvent): void {
		this.currentpage = event.page;
		this.isLoading$.next(true);
		if (!this.hasPagingTable) {
			const startItem = (event.page - 1) * event.itemsPerPage;
			const endItem = event.page * event.itemsPerPage;
			const data = this.getNoteList;
			this.noteList =
				data && data.length > 0 ? data?.slice(startItem, endItem) : [];
			if (data?.length > 0 && this.noteList.length === 0) {
				this.currentpage = 1;
				this.noteList = data;
			}
			this.isLoading$.next(false);
		} else {
			this.getNotes(event);
		}
	}

	getNotes(event: PageChangedEvent) {
		if (!this.getFn$) {
			return;
		}
		this.getFn$({
			CustomerID: this.customerId,
			CustomerServiceID: !!this.customerServiceId ? this.customerServiceId : 0,
			PageNumber: event.page,
			PageSize: event.itemsPerPage,
		}).subscribe(
			(c) => {},
			() => {},
			() => {
				this.isLoading$.next(false);
			}
		);
	}

	saveNote = (notes: string) => {
		this.isAddingNote$.next(true);
		return this.addFn$({
			customerID: this.customerId,
			customerServiceID: !!this.customerServiceId ? this.customerServiceId : 0,
			notes,
		}).pipe(
			tap(
				() => {
					this.currentpage = 1;
					this.cd.detectChanges();
					this.setPage({
						page: this.currentpage,
						itemsPerPage: this.itemsPerPage,
					});
					this.isAddingNote$.next(false);
				},
				() => {
					this.isAddingNote$.next(false);
				},
				() => {
					this.isAddingNote$.next(false);
				}
			)
		);
		// .subscribe();
	};

	openModalAddNote() {
		const initState: any = {
			header: 'Add Note',
			savefn: this.saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteConfirm(note: any) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteNote(note);
			obs.complete();
		});
		const initState = {
			header: 'Delete Note',
			message: `Are you sure you want to delete this note?`,
			delete$: confirm,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteNote(note: NoteState & { isDeleting?: boolean }) {
		this.setIsDeleting(note.notesID, true);
		this.deleteFn$(note)
			.pipe(
				tap(() => {
					this.currentpage = !!this.count
						? this.currentpage <=
						  Math.ceil((this.count - 1) / this.itemsPerPage)
							? this.currentpage
							: this.currentpage >
							  Math.ceil((this.count - 1) / this.itemsPerPage)
							? Math.ceil((this.count - 1) / this.itemsPerPage)
							: 1
						: !!this.notes && this.notes.length > 0
						? this.currentpage <=
						  Math.ceil((this.notes.length - 1) / this.itemsPerPage)
							? this.currentpage
							: this.currentpage >
							  Math.ceil((this.notes.length - 1) / this.itemsPerPage)
							? Math.ceil((this.notes.length - 1) / this.itemsPerPage)
							: 1
						: 1;
					this.cd.detectChanges();
					this.setPage({
						page: this.currentpage,
						itemsPerPage: this.itemsPerPage,
					});
				})
			)
			.subscribe(
				() => {},
				() => {},
				() => {
					this.setIsDeleting(note.notesID, false);
					this.cd.detectChanges();
				}
			);
	}

	setIsDeleting(notesID: number, isDeleting: boolean) {
		this.noteList = this.notes?.map((x) => ({
			...x,
			isDeleting: x.notesID === notesID ? isDeleting : x.isDeleting,
		}));
		this.cd.detectChanges();
	}

	openEmailPopup() {
		if (this.isCompany) {
			this.openEmailBusinessPopup();
		} else {
			this.openEmailClientPopup();
		}
	}

	private openEmailBusinessPopup() {
		this.businessProfileService.openECModalSubject$.next({
			documentType: this.documentType ?? ServicesCodes.AdviceProcess,
			type: ServicesCodes.CRTNotesAdviceProcess,
			referenceId: this.adviceProcessId || 0,
		});
	}

	private openEmailClientPopup(): void {
		this.clientProfileService.openECModalSubject$.next({
			documentType: this.documentType ?? ServicesCodes.AdviceProcess,
			type: ServicesCodes.CRTNotesAdviceProcess,
			referenceId: this.adviceProcessId || 0,
		});
	}
}
