import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RouteService } from '../config/route.service';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from '../../domain/user/user.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { BusinessPageService } from '@modules/business-page/state/business-page.service';

@Injectable()
export class RedirectGuard implements CanActivate {
	constructor(
		private router: Router,
		private routeService: RouteService,
		private userService: UserService,
		private authService: AuthenticationService,
		private businessPageService: BusinessPageService
	) {}

	private rerouteTL(route) {
    return this.businessPageService.getBusinesses()
      .pipe(
        switchMap(businesses => {
          const routerPath = this.router.url.split('/')[1];
          const businessExists = businesses.find(x => x.BusinessName === routerPath);
          return !businessExists
            ? this.router.navigate(this.routeService.splitToRoutePath('/admin/business'))
            : this.router.navigate(route.splitToRoutePath(route.adminBusinessList));
        })
      );
  }

  private rerouteBL(route) {
		return this.userService.get()
			.pipe(
				switchMap(user => {
					if (user && user.RouteTo === 'dashboard') {
						return this.router.navigate(route.splitToRoutePath(route.dashboard));
					} else if (user && user.RouteTo === 'activity') {
						return this.router.navigate(route.splitToRoutePath(route.mainActivity));
					} else if (user && user.SecurityGroup === 'LG' && user.RouteTo === 'lead client add') {
						return this.router.navigate(route.splitToRoutePath(route.leadClientAdd));
					} else if (user && user.SecurityGroup === 'LGI' && user.RouteTo === 'lead search') {
						return this.router.navigate(route.splitToRoutePath(route.leadSearch));
					}
					return this.router.navigate(route.splitToRoutePath(route.dashboard));
				})
			);
  }


	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.routeService.RouteChange$.pipe(
				switchMap(route => {
					if(this.authService.isLoggedIn) {
						const loggedInIsTL = this.authService.user.IsTL;
						const invalidURL = !this.router.url.includes(this.authService.user.CompanyCode);
						if (invalidURL) {
							return this.router.navigate(this.routeService.splitToRoutePath(`/${this.authService.user.CompanyCode}/dashboard`))
						}
						if (loggedInIsTL) {
							return this.rerouteTL(route);
						} else {
							return this.rerouteBL(route);
						}
					}
					return this.router.navigate(this.routeService.splitToRoutePath('/login'));
			}),
			catchError(err => {
				return this.router.navigate(this.routeService.splitToRoutePath('/error'));
			})
		)
	}
}
