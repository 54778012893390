import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, iif, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { AdviceProcessSectionCodes, ServiceAdviceProcessState } from '../../../../../shared/models/advice-process/advice-process.model';
import {
	DisclosureDocumentState,
	DisclosureState,
	DocumentUploadState,
} from '../../../../../shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { DocumentModel } from '../../../../../shared/models/client-review-template/document/document.model';
import { DocumentModelState, DocumentTypes } from '../../../../../shared/models/documents/document.model';
import { objectUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { EmailTypes } from '../../../../../shared/models/emails/crt/email.model';
import { complement, either, isNil, isEmpty } from 'ramda';

export const disclosurePdfOptions = {
	FileName: 'DISCLOSURE-DOCUMENT',
	DPI: '120',
};

@Injectable()
export class DisclosureService extends ClientReviewTemplateService {
	disclosureDocument$ = this.query.disclosureDocument$;
	disclosureSettings$ = this.query.disclosureSettings$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getDdPdfOptions() {
		return disclosurePdfOptions;
	}

	getDisclosureSettings(forceRefetch: boolean = false) {
		const adviserId = +this.query.getValue().adviceProcess?.adviser || 0;
		const endpoint = `staff/${adviserId}/settings/DD`;

		return forceRefetch || isNil(this.query.getValue().disclosureSettings)
			? this.api.get<DisclosureDocumentState>(endpoint).pipe(
					tap((data) =>
						applyTransaction(() => {
							const state = complement(either(isNil, isEmpty))(data)
								? objectUtil.mapPascalCaseToCamelCase(data)
								: {};
							this.store.setDisclosureSettings(state);
						})
					),
					catchError(() => of(undefined))
			  )
			: of(this.query.getValue().disclosureSettings);
	}

	getDisclosureDocument(adviceProcessId: number) {
		const endpoint = `crt/${adviceProcessId}/DD`;
		return this.api.get<DisclosureState[]>(endpoint).pipe(
			tap((x) => {
				return applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? {
								...objectUtil.mapPascalCaseToCamelCase(x[0]),
								clickedNext: true,
						  }
						: {
								cRTId: null,
								adviceProcessId: this.query.getValue().adviceProcessId,
								document: { referenceId: null, value: null },
								clickedNext: false,
						  };
					this.store.setDisclosureDocument(state);
				})
			}),
			catchError(() => of(undefined))
		);
	}

	getDisclosureDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		const dateTime = new Date().getTime();
		url = `${url}?${dateTime}`;
		return this.api
			.getExternalResource(url, { responseType: 'text' })
			.pipe(take(1));
	}

	getInfoByCRT(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<any>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => of([]))
		);
	}

	sendEmail(data) {
		const d = objectUtil.mapCamelCaseToPascalCase(data);
		const adviceProcessId = this.query.getValue().adviceProcessId;
		let dd: DisclosureDocument;
		return this.api.get<DisclosureDocument[]>(`crt/${adviceProcessId}/DD`).pipe(
			map((x) => {
				const res = x?.map(
					(y) => objectUtil.mapPascalCaseToCamelCase(y) as DisclosureDocument
				);
				dd = res.length > 0 ? res[0] : null;
				return dd;
			}),
			// Update or Add Document for Disclosure Document
			mergeMap((x) =>
				iif(
					() => !!x && !!x?.documentID,
					// Update the current document
					this.api.put(`documents/${x?.documentID}/document-link`, {
						Document: d.Document,
						DocumentID: x?.documentID,
					}),
					// Upload new document
					this.api.post(`documents`, {
						ReferenceId: adviceProcessId,
						Document: d.Document,
						FileName: 'Disclosure Document.pdf',
						Type: DocumentTypes.Disclosure,
					})
				)
			),
			// Create New or update Disclosure document
			mergeMap((x) =>
				iif(
					() => !!dd,
					// Update the current Disclosure Document
					this.api.put(`crt/${dd?.cRTId}`, {
						AdviceProcessId: adviceProcessId,
						SectionCode: AdviceProcessSectionCodes.Disclosure,
						DocumentID: dd?.documentID,
						ParentCRTId: 0,
						CRTId: dd?.cRTId,
					}),
					// Create new Disclosure Document
					this.api.post(`crt`, {
						AdviceProcessId: adviceProcessId,
						SectionCode: AdviceProcessSectionCodes.Disclosure,
						DocumentID: x,
						ParentCRTId: 0,
					})
				)
			),
			// Send the email
			mergeMap((x) =>
				this.api.post(`emails/single`, {
					EmailDestination: d.EmailDestination,
					Type: EmailTypes.Disclosure,
					ReferenceId: typeof x === 'number' ? x : dd.cRTId,
				})
			),
			catchError(() => EMPTY)
		);
	}

	newFileUploadDD(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req).pipe(take(1));
	}

	updateFileUploadDD(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getDocumentDdByRefId(id: number) {
		return this.api
			.get<DocumentModel[]>(`documents/reference/${id}/CRTDD`)
			.pipe(take(1));
	}

	deleteFileDD(id: number) {
		const endpoint = `documents/${id}`;
		return this.api.delete<any>(endpoint);
	}

	getAdviceProcess(clientId) {
		const endpoint = `contacts/${clientId}/adviceprocesses?status=1`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			map((data) => (data ? objectUtil.mapPascalCaseToCamelCase(data) : null)),
			tap((data) =>
				applyTransaction(() => {
					this.store.setAdviceProcess(data);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateLRAdviceProcess(data) {
		const endpoint = `adviceprocesses/${data.adviceProcessID}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api
			.put<string>(endpoint, body)
			.pipe(
				finalize(() =>
					this.getAdviceProcess(body.AdviceProcessID).pipe(take(1)).subscribe()
				)
			);
	}

	uploadDocument(req) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req).pipe(catchError(() => EMPTY));
	}

	addNewDisclosure(data) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.post<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDisclosureDocument({ ...data, cRTId: +x });
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateDisclosure(data) {
		const endpoint = `crt/${data.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<any>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.store.setDisclosureDocument(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	updateDdTemp(data: DisclosureState) {
		applyTransaction(() => {
			this.store.setDisclosureDocument(data);
		});
	}

	setHasFormChangesDd() {
		applyTransaction(() => {
			this.setHasFormChanges(true);
		});
	}
}

export interface DisclosureDocument {
	documentID: number;
	cRTId: number;
	adviceProcessId: number;
	sectionCode: string;
	status: number;
	createDateTime: string;
	createdByStaffId: number;
	createdByStaffLevel: number;
	modifiedDateTime: string;
	modifiedByStaffId?: any;
}
