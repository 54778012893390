import {
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isNil, complement, isEmpty } from 'ramda';
import {
	BehaviorSubject,
	combineLatest,
	iif,
	Observable,
	Observer,
	of,
	Subject
} from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
  first,
	catchError
} from 'rxjs/operators';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import {
	AdviceProcessDocumentField,
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes
} from '../../../../shared/models/advice-process/advice-process.model';
import { DefaultFileNames, DocumentTypes } from '../../../../shared/models/documents/document.model';
import { EmailTypes } from '../../../../shared/models/emails/crt/email.model';
import { LoggerService } from '../../../../core/logger/logger.service';
import { UserQuery } from '../../../../domain/user/user.query';
import {
	filterNonEmailMergeTags,
	getContentWithMergeTags
} from '../../../../shared/converter/content-merge-tags';
import { ConfirmModalComponent } from '../../../../shared/modal/confirm-modal/confirm-modal.component';
import { EmailModalComponent } from '../../../../shared/modal/crt/email/email-modal.component';
import { DisclosureDocumentMapper } from '../../../../shared/models/client-review-template/disclosure-document/disclosure-document.mapper';
import {
	DisclosureDocumentState,
	DisclosureState
} from '../../../../shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { MergeTagState } from '../../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from '../../../../shared/wysiwyg/wysiwyg.component';
import { convertUtil, fileUtil, objectUtil } from '../../../../util/util';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { DisclosureService } from '../states/disclosure/disclosure.service';
import { LoatDocumentService } from '../states/document/loat-document.service';
import { EmailService } from '../states/email/email.service';
import { CrtMergeTagsService } from '../states/merge-tags/crt-merge-tags.service';
import { PeopleService } from '../states/people/people.service';
import { LrAdviceprocessService } from './../states/lr-adviceprocess/lr-adviceprocess.service';
import { CrtDocumentService } from '../../crt-page/_shared/service/crt-document.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';

@Component({
	selector: 'app-disclosure',
	templateUrl: './disclosure.component.html',
	styleUrls: ['./disclosure.component.scss'],
})
export class DisclosureComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	isCollapsed: boolean;
	content = '<p></p>';
	defaultSettings: DisclosureDocumentState;
	crtData: DisclosureState | any;
	isEnableEmailOption = false;
	hasExistingDoc = false;
	defaultFileName = DefaultFileNames.Disclosure;
	pdf: string;
	templateId: number;
	document;
	isCompLoading = false;
  isSendingEmail = false;

	triggerPrint = new BehaviorSubject<object>(null);
	generatePdf$ = new BehaviorSubject<string>(null);
	updateAP$ = new BehaviorSubject<boolean>(false);

	disclosureDocument$ = this.dService.disclosureDocument$;
	disclosureSettings$ = this.dService.disclosureSettings$;
	mergeTags$ = this.mtService.mergeTags$;
	transferedSCIList$ = this.query.transferedSCIList$;
	peopleAndDependents$ = this.query.peopleAndDependentsFromCRTOnly$.pipe(
		withLatestFrom(this.query.transferedSCIList$),
		map(([x, y]) => x?.filter((x) => !y?.find((i) => +i?.cRTId === +x?.cRTId)) || [])
	);
	adviceProcessId = this.query.getValue().adviceProcessId;
	adviceProcess$ = this.query.adviceProcess$;
	staffId = +this.userQuery.getValue().StaffID;
	clientId = this.route.snapshot.paramMap.get('clientId');
	groupName = this.query
		.getValue()
		.primaryClient?.groupName?.toString()
		?.toUpperCase();
	fileName = `${this.groupName} DISCLOSURE DOCUMENT`;
	adviceProcess = this.query.getValue().adviceProcess;

	@ViewChild('contentEditor') editor: WysiwygComponent;
	pdfUrlString: string;
	zoom$ = this.query.lrApPageCompleted$.pipe(
		map((x) =>
			x?.some((y) => y?.includes('ZOOM'))
				? +x?.find((y) => y?.includes('ZOOM'))?.substr(4)
				: 120
		)
	);
	mergeTags: MergeTagState[] = [];

	fileUrl: string;
	isPdfLoaded: boolean;
	crtFileType: string;
	documentData: any;

	constructor(
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private dService: DisclosureService,
		private query: ClientReviewTemplateQuery,
		private peopleService: PeopleService,
		private mtService: CrtMergeTagsService,
		private emailService: EmailService,
		private userQuery: UserQuery,
		private loatDocumentService: LoatDocumentService,
		private loggerService: LoggerService,
		private zone: NgZone,
		private businessConfigQuery: BusinessConfigQuery,
		private crtMtService: CrtMergeTagsService,
		private lrAdviceprocessService: LrAdviceprocessService,
		private renderer: Renderer2,
		private crtDocService: CrtDocumentService
	) {}

	ngOnInit(): void {
		this.isCompLoading = true;
		this.dService
			.getDisclosureSettings()
			.pipe(
				finalize(() => {
					this.isCompLoading = false;
					this.prepData();
				}),
				take(1)
			)
			.subscribe();
	}

	prepData() {
		let dataCrt;
		combineLatest([
			this.disclosureDocument$,
			this.disclosureSettings$,
			this.mergeTags$,
		])
			.pipe(
				tap(() => (this.isCompLoading = true)),
				filter(([crt, settings, mt]) => !!crt && !!settings && !!mt),
				take(1),
				tap(([crt, settings, mt]) => {
					this.mergeTags = mt;
					this.defaultSettings = settings;
					this.crtData = dataCrt = crt;
					this.isEnableEmailOption = settings?.isEnableEmailOption || false;
				}),
				mergeMap(([crt, settings]) => this.getContentFromDoc(crt, settings)),
				mergeMap((content) => content === 'PDF' ? this.updateContentPDF(content) : this.updateContent(content)),
				concatMap((content) =>
					iif(
						() =>
							isNil(dataCrt?.cRTId) &&
							complement(isEmpty)(this.defaultSettings),
						this.addDisclosure(dataCrt, content),
						of(null)
					)
				),
				finalize(() => (this.isCompLoading = false)),
				take(1)
			)
			.subscribe(() => this.updateDisclosureState());
	}

	// Create new Disclosure CRT
	addDisclosure(crtData: DisclosureState, content: string) {
		let docFile;
		if (this.defaultSettings.templateType === 'UD') {
			return this.dService.getDisclosureDocumentFile(+this.defaultSettings.uploadTemplate)
				.pipe(
					map((doc) => {
						return objectUtil.mapPascalCaseToCamelCase(doc);
					}),
					tap((doc) => {
						docFile = doc;
					}),
					mergeMap((doc) => {
						return this.getFileFormat(doc.documentLink, doc.documentID)
					}),
					map((file) =>
						DisclosureDocumentMapper.mapCRTDocumentUpload({
							fileName: file.fileName,
							document: file.content,
							referenceId: +this.adviceProcessId,
						})
					),
					concatMap((file) => {
						return this.dService.newFileUploadDD(file);
					}),
					concatMap((id) => this.dService.getDisclosureDocumentFile(+id)),
					map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
					tap((res) => {
						if (res) {
							this.document = res;
							this.crtData.document = {
								referenceId: +res.documentID,
								value: docFile.fileName,
							};
						}
					}),
					map((res) => {
						const upsert = DisclosureDocumentMapper.mapCrtToUpsert({
							...crtData,
							document: {
								referenceId: +res.documentID,
								value: docFile.fileName,
							},
						})
						return upsert;
					}),
					concatMap((x) => {
						return this.dService.addNewDisclosure(x)
					}),
					tap((x) => (this.crtData.cRTId = x)),
					catchError((err) => {
						return err
					}),
					take(1)
				);
		} else {
			return of(content).pipe(
				concatMap((x) => convertUtil.convertToBase64(x)),
				map((x) =>
					DisclosureDocumentMapper.mapCRTDocumentUpload({
						fileName: this.defaultFileName,
						document: x,
						referenceId: +this.adviceProcessId,
					})
				),
				concatMap((file) => {
					return this.dService.newFileUploadDD(file);
				}),
				concatMap((id) => this.dService.getDisclosureDocumentFile(+id)),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				tap((res) => {
					if (res) {
						this.document = res;
						this.crtData.document = {
							referenceId: +res.documentID,
							value: this.defaultFileName,
						};
					}
				}),
				map((res) => {
					return DisclosureDocumentMapper.mapCrtToUpsert({
						...crtData,
						document: {
							referenceId: +res.documentID,
							value: this.defaultFileName,
						},
					})
				}),
				concatMap((x) => {
					return this.dService.addNewDisclosure(x);
				}),
				tap((x) => (this.crtData.cRTId = x)),
				take(1)
			);
		}
	}

	// Pull Disclosure Document Template from CRT or Settings (If no CRT yet)
	getContentFromDoc(crt: DisclosureState, settings: any = {}, forceRefresh = false) {
		return of(crt).pipe(
			map((x) => {
				if (forceRefresh) {
					return settings.templateType === 'UD' ? settings?.uploadTemplate : settings?.template;
				}

				return x?.document?.referenceId ? x?.document?.referenceId : settings.templateType === 'UD' ? settings?.uploadTemplate : settings?.template
			}),
			concatMap((x) => {
				return x
					? this.dService.getDisclosureDocumentFile(+x).pipe(
							map((y) => (y ? objectUtil.mapPascalCaseToCamelCase(y) : null)),
							take(1)
						)
					: of(null)
			}),
			tap((data) => {
				if (data) {
					this.crtFileType = data.fileExtension;
					this.fileUrl = data.documentLink;
					this.documentData = data;
				}
			}),
			concatMap((data) => {
				return iif(
					() => isNil(data),
					of(this.content),
					iif(() => data.fileExtension === '.txt', this.dService.getDocumentFromURL(data?.documentLink), of('PDF')) 
				)
			}),
			take(1)
		);
	}

	// Update content with Merge Tags
	updateContent(content: string) {
		return of(content).pipe(
			map((x) => getContentWithMergeTags(x, this.mergeTags)),
			tap((x) => (this.content = x)),
			take(1)
		);
	}

	updateContentPDF(content: string) {
		return of(content).pipe(
			tap((x) => (this.content = x)),
			take(1)
		);
	}

	open(evt: boolean) {
		this.isCollapsed = evt;
	}

	getFileFormat(url, documentId) {
		return this.crtDocService.getDocumentLink(url, {
			responseType: 'blob'
		}).pipe(
			mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
			map((xx) => {
				const fileStr = `${((xx as string) ?? '')?.replace(/^data:(.*,)?/, '')}`;
		
				return {
					documentId: +documentId,
					fileName: `${this.fileName}.pdf`,
					generateContentCallback$: undefined,
					content: fileStr,
					pdfOptions: {
						orientation: '',
						format: ''
					},
					fileSize: +fileUtil.getFileSizeKb(fileStr),
					type: 'linked',
					fileUrl: this.fileUrl,
					allowDeleting: false
				};
			}),
			take(1)
		);
	}

	sendEmail() {
		if (this.isSendingEmail) return;
		this.isSendingEmail = true;
		this.dService.getDisclosureSettings(true).pipe(
			mergeMap(() => iif(
				() => this.crtData && this.crtFileType === '.pdf' || (!this.crtData && this.defaultSettings && this.defaultSettings.templateType === 'UD'),
				this.getFileFormat(this.fileUrl, this.defaultSettings.uploadTemplate),
				of({
					fileName: `${this.fileName}.pdf`,
					generateContentCallback$: this.generatePdf$,
					content: this.crtDocService.contentForPdf(
						`<div class="disclosure-pdf-file">${this.content}</div>`
					),
					newPdfOptions: {
						...this.dService.getDdPdfOptions(),
						FileName: this.fileName,
					},
				})
			)),
			tap((attachments) => {
				const initState = {
					header: 'Email Client',
					sendEmailFn$: this.sendToRecipients,
					saveEmailFn$: this.saveCrtEmail,
					emailSettings$: this.disclosureSettings$,
					peopleDropdown: this.peopleAndDependents$,
					attachments: [attachments],
					successMessage: 'Disclosure document has been emailed to',
					adviceProcess: this.adviceProcess,
					businessConfig$: this.businessConfigQuery.businessConfig$,
					mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
					defaultLinkDocumentTab: ServicesCodes.LR,
					documentInfo: {
						documentType: ServicesCodes.LR,
						type: DocumentTypes.soaDocument,
						referenceId: this.query.getValue().adviceProcessId,
						customerId: this.query.getValue().primaryClient?.customerID,
					}
				};
				this.modalService.show(EmailModalComponent, {
					class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false
				});

        this.modalService
					.onHidden
					.pipe(first())
					.subscribe(_ => this.isSendingEmail = false);
			}),
			take(1)
		).subscribe(
      null,
      () => this.isSendingEmail = false,
      () => this.isSendingEmail = false,
    );
	}

	saveCrtEmail = (data) => {
		return new Observable<JSON>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap(() =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.peopleService.updateDependent(data),
					this.peopleService.updatePeople(data)
				)
			)
		);
		// tslint:disable-next-line: semicolon
	};

	sendToRecipients = (data) => {
		return this.emailService.sendToRecipients(data, EmailTypes.Disclosure, this.query.getValue()?.disclosureDocument?.cRTId, filterNonEmailMergeTags(this.mergeTags$));
	}; // tslint:disable-line

	generatedPdf() {
		const content = this.crtDocService.contentForPdf(`<div class="disclosure-pdf-file">${this.content}</div>`);
		this.generatePdf$.next(content);
	}

	getPdf(pdf: string) {
		this.pdf = pdf;
	}

	renderPdfDownload = (content: string, pdfOptions: any = {}) => {
		return this.crtDocService
			.downloadDocumentPDF(content, this.fileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);
	}

	// Download Document as PDF
	downloadDocument() {
		this.isCompLoading = true;
		if (this.crtData && this.crtFileType === '.pdf') {
				this.adviceProcess$.pipe(
					map((adviceProcess) => {
						const ap = adviceProcess?.documents?.find(
							(d) => d.field === AdviceProcessDocumentField.Disclosure
						)?.value;

						return ap;
					}),
					concatMap((document) => {
						// return iif(
						// 	() => !!document,
						// 	this.crtDocService.downloadLink(document ? document.documentID : this.documentData.documentID),
						// 	this.getFileFormat(this.documentData.documentLink, this.documentData.documentID)
						// )
						return this.getFileFormat(this.documentData.documentLink, this.documentData.documentID);
					}),
					tap((file: any) => {
						if (typeof file === 'object') {
							this.downloadFileUpload(file?.fileUrl, file?.fileName);
						} else {
							this.downloadFileUpload(file, `${this.fileName}.pdf`);
						}
					}),
					tap(() => {
						this.isCompLoading = false;
					}),
					take(1)
				).subscribe();
		} else {
			of(this.content)
				.pipe(
					map((content) => `<div class="disclosure-pdf-file">${content}</div>`),
					mergeMap((content) =>
						this.renderPdfDownload(content, {
							...this.dService.getDdPdfOptions(),
							FileName: this.fileName,
						})
					),
					tap(() => (this.isCompLoading = false)),
					take(1)
				)
				.subscribe();
		}
	}

	downloadFileUpload(fileUrl: any, fileName: string) {
		this.crtDocService.getDocumentLink(fileUrl, {
			responseType: 'blob'
		}).pipe(
			tap((file) => {
				const newFile = new File([file], fileName);
				const a = this.renderer.createElement('a');
				this.renderer.setStyle(a, 'display', 'none');
				const url = window.URL.createObjectURL(newFile);
				this.renderer.setAttribute(a, 'href', url);
				this.renderer.setAttribute(a, 'download', fileName);
				a.click();
				window.URL.revokeObjectURL(url);
			}),
			take(1)
		)
		.subscribe();
	}

	downloadFile(fileUrl: any, fileName: string) {
		const a = document.createElement('a');
		a.href = fileUrl;
		a.setAttribute('download', fileName);
		a.click();
	}

	// Save Document
	saveDocument() {
		const updatedData = `<div class="disclosure-pdf-file">${this.content}</div>`;
		this.adviceProcess$.pipe(take(1)).subscribe((x) => {
			const dDocument = x?.documents?.find(
				(d) => d.field === AdviceProcessDocumentField.Disclosure
			)?.value;

			if (isNil(dDocument) || isEmpty(dDocument)) {
				if (this.defaultSettings && this.defaultSettings.templateType === 'UD') {
					this.saveNewDocumentPdf().pipe(take(1)).subscribe();
				} else {
					this.saveNewDocument(updatedData).pipe(take(1)).subscribe();
				}
			} else {
				this.saveExistingDocument(x, updatedData);
			}
		});
	}

	saveNewDocumentPdf() {
		return of(this.defaultSettings).pipe(
			tap(() => (this.isCompLoading = true)),
			mergeMap(() => {
				return this.getFileFormat(this.documentData.documentLink, this.documentData.documentID)
			}),
			map((file) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: file.content,
				FileName: `${this.fileName}.pdf`,
				DocumentType: ServicesCodes.LR,
				Type: AdviceProcessPageCodes.Disclosure,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap((x) => {
				return this.loatDocumentService.updateAdviceProcess(
					x,
					AdviceProcessPageCodes.Disclosure
				);
			}),
			tap(() =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						'Successfully saved Disclosure Document.'
					)
				)
			),
			finalize(() => (this.isCompLoading = false)),
			take(1)
		)
	}

	// Saving of New Document
	// If Advice Process has no Disclosure Document yet
	saveNewDocument(data: string) {
		return of(data).pipe(
			tap(() => (this.isCompLoading = true)),
			mergeMap((content) =>
				this.crtDocService.downloadDocumentPDF(content, this.fileName)
			),
			mergeMap((content) => convertUtil.convertToBase64(content)),
			map((template) => {
				const obj = {
					ReferenceId: this.query.getValue().adviceProcessId,
					CustomerId: this.query.getValue().primaryClient?.customerID,
					Document: template,
					FileName: `${this.fileName}.pdf`,
					DocumentType: ServicesCodes.LR,
					Type: AdviceProcessPageCodes.Disclosure,
				}
				return obj;
			}),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap((x) =>
				this.loatDocumentService.updateAdviceProcess(
					x,
					AdviceProcessPageCodes.Disclosure
				)
			),
			tap(() =>
				this.zone.run(() =>
					this.loggerService.Success(
						{},
						logMessage.oat.shared.disclosure.success.save
					)
				)
			),
			finalize(() => (this.isCompLoading = false)),
			take(1)
		);
	}

	// Saving of Existing Document
	// If Advice Process already has Disclosure Document uploaded
	saveExistingDocument(adviceProcess: any = {}, data: string) {
		const document = adviceProcess?.documents?.find(
			(d) => d.field === AdviceProcessDocumentField.Disclosure
		)?.value;

		const confirm = new Observable((obs) => {
			if (this.crtData && this.crtFileType === '.pdf') {
				this.saveNewDocumentPdf().pipe(take(1)).subscribe();
			} else {
				this.saveNewDocument(data).pipe(take(1)).subscribe();
			}

			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});

		const initState = {
			header: 'Save Document',
			message: `A document is already uploaded for Disclosure Document,`,
			subMessage: document?.fileName,
			secondaryMessage: `Do you want to replace this?`,
			confirm$: confirm,
			decline$: decline,
			isAcceptBtn: true,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Reload Template
	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.loadFromSettings();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	// Load template from settings
	loadFromSettings() {
		this.isCompLoading = true;
		let settings;

		of(true).pipe(
			concatMap(() => this.refetchMergeTags()),
			concatMap(() => this.dService.getDisclosureSettings(true)),
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			tap((x) => {
				settings = x;
			}),
			concatMap((x) => {
				if (!x) return of(null);

				if (x.templateType === 'FT') {
					if (x.template) {
						return this.getContentFromDoc(null, x, true)
					}
					return this.setUrlToNone()
				} else {
					if (x.uploadTemplate) {
						return this.getContentFromDoc(null, x, true)
					}
					return of('<p></p>')
				}
			}),
			mergeMap((x) => {
				return iif(() => settings && settings.templateType === 'FT', this.updateContent(x), of(null));
			}),
			tap(() => {
				this.updateDisclosureState();
			}),
			finalize(() => (this.isCompLoading = false)),
			take(1)
		).subscribe();
	}

	setUrlToNone() {
		return of(this.fileUrl).pipe(
			tap(() => {
				this.fileUrl = '';
			}),
			take(1)
		)
	}

	refetchMergeTags = () =>
		this.crtMtService.getDefaultMergeTags(true).pipe(
			concatMap(() => this.crtMtService.getAdviserProviderMt()),
			withLatestFrom(this.mtService.mergeTags$),
			tap(([, x]) => (this.mergeTags = x))
		);

	// Save data to akita
	setFormValue() {
		const formValue = DisclosureDocumentMapper.mapCrtToUpsert(this.crtData);
		this.dService.updateDdTemp({
			...formValue,
			content: this.content
		});
	}

	updateDisclosureState() {
		const formValue = DisclosureDocumentMapper.mapCrtToUpsert(this.crtData);

		this.dService.updateDdTemp({
			...formValue,
			content: this.content,
			templateType: this.crtFileType,
			fileInfo: {
				fileName: this.documentData?.documentName,
				fileUrl: this.documentData?.documentLink,
				documentID: this.crtData.document.referenceId
			}
		});
	}

	// Add Indicator of setHasFormChanges for Pause&Exit
	setHasFormChanges() {
		this.dService.setHasFormChangesDd();
	}

	zoomFn(zoom) {
		const update = (d: string[]) =>
			this.lrAdviceprocessService.updateLrApPageCompleted(d).pipe(
				tap(() => this.lrAdviceprocessService.setLrApPageCompletedState(d)),
				take(1)
			);

		of(zoom)
			.pipe(
				// debounceTime(100),
				withLatestFrom(this.query.lrApPageCompleted$),
				concatMap(([z, list]) =>
					update([
						...(list ?? [])?.filter((l) => !l.includes('ZOOM')),
						`ZOOM${z}`,
					])
				),
				take(1)
			)
			.subscribe();
	}

	onPageRendered(event: Event) {
		this.isPdfLoaded = true;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
