import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, iif, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/base/component-base';
import { UserQuery } from 'src/app/domain/user/user.query';
import {
	contentEditable,
	getContentWithMergeTags,
	removeEmptyParagraphs,
} from 'src/app/shared/converter/content-merge-tags';
import { moatSosMergeTags } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/scope-of-service/sos.merge-tag';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { convertUtil } from 'src/app/util/util';
import { MergeTagsCrtSettingsService } from '../../merge-tags/state/merge-tags-crt-settings.service';
import { SettingsTypes } from '../../state/mortgage-settings.model';
import { ScopeOfServiceTemplateSettingsMapper as sosTemplateSettingsMapper } from '../sos-template-settings/state/sos-template-settings.mapper';
import { ScopeOfServiceSettingsService } from '../state/scope-of-service-settings.service';
import { SosTemplateSettingsService } from './state/sos-template-settings.service';

@Component({
	selector: 'app-sos-template-settings',
	templateUrl: './sos-template-settings.component.html',
	styleUrls: ['./sos-template-settings.component.scss'],
})
export class SosTemplateSettingsComponent
	extends ComponentBase
	implements OnInit, OnDestroy
{
	isLoading = false;
	isPreview = false;
	isReload = true;
	customButtons = [];
	currentTemplate = '<p></p>';
	componentName = 'app-sos-template-settings';
	undoReset$ = new BehaviorSubject<any>(null);
	undoStack$ = new BehaviorSubject<any>(null);

	tempData: string;
	templateId: number;
	shortcodes: object;
	data: object;
	template: string;
	mergeTag: MergeTagState[];
	undoStack = [];

	templateSettings$ = this.sosTemplateSettingsService.templateSettings$;
	mergeTags$ = this.mtService.mergeTags$;
	isTapLevel = this.userQuery.isTapLevel();

	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(
		private sosTemplateSettingsService: SosTemplateSettingsService,
		private sosSettingsService: ScopeOfServiceSettingsService,
		private mtService: MergeTagsCrtSettingsService,
		private userQuery: UserQuery
	) {
		super();
	}

	ngOnInit(): void {
		this.mergeTags$
			.pipe(
				map((mt) => sosTemplateSettingsMapper.filterMergeTagsToShow(mt)),
				map((mt) =>
					MergeTagsMapper.mapMergeTags([...mt, ...moatSosMergeTags], true)
				),
				tap((x) => (this.mergeTag = x)),
				map((mt) => MergeTagsMapper.mapTagsForWysiwyg(mt)),
				tap((mt) => (this.shortcodes = mt)),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => this.backToEdit());
		this.prepData();
	}

	prepData() {
		combineLatest([this.templateSettings$, this.mtService.mergeTags$])
			.pipe(
				filter(([settings, mt]) => !!settings && !!mt),
				tap(([settings]) => {
					this.data = settings;
					this.templateId = +settings?.template;
				}),
				concatMap(([settings]) =>
					this.sosSettingsService.getDocumentFromURL(settings?.templateLink)
				),
				tap((content) => {
					if (content) {
						this.currentTemplate = content;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	hasTemplate = (template) =>
		R.complement(R.either(R.isNil, R.isEmpty))(template) &&
		!isNaN(+template) &&
		+template > 0;

	getUndoStack(event) {
		this.undoStack = event;
	}

	save(event?) {
		this.isLoading = true;
		of(this.isReload)
			.pipe(
				concatMap((x) =>
					iif(() => !!x, this.saveReloadTemplate(x), this.saveTemplate())
				),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	saveReloadTemplate = (isReload) =>
		of(this.undoStack).pipe(
			concatMap((undoStack) =>
				iif(
					() => isReload && R.isEmpty(undoStack),
					this.sosTemplateSettingsService
						.updateSettings({
							template: null,
							referenceId: 0,
							type: SettingsTypes.MOATSosTemplate,
						})
						.pipe(
							finalize(() => {
								this.isReload = false;
								this.templateId = null;
							})
						),
					this.saveTemplate()
				)
			)
		);

	saveTemplate = () =>
		of(this.editor?.content).pipe(
			mergeMap((curTmpl) => convertUtil.convertToBase64(curTmpl)),
			withLatestFrom(of(this.templateId)),
			map(([curTmpl, tmplId]) =>
				sosTemplateSettingsMapper.mapDocumentUpload(curTmpl, tmplId)
			),
			concatMap((data) =>
				iif(
					() =>
						R.isNil(data?.documentId) ||
						isNaN(data?.documentId) ||
						data?.documentId === 0,
					this.sosSettingsService.newFileUpload(data),
					this.sosSettingsService.updateFileUpload(data)
				)
			),
			tap((data) => {
				this.templateId = this.hasTemplate(this.templateId)
					? this.templateId
					: +data;
			}),
			withLatestFrom(of(this.templateId)),
			concatMap(([data, tmplId]) =>
				iif(
					() => this.hasTemplate(tmplId),
					of(tmplId),
					this.sosTemplateSettingsService.updateSettings({
						template: data,
						referenceId: 0,
						type: SettingsTypes.MOATSosTemplate,
					})
				)
			),
			finalize(() => (this.isLoading = false)),
			take(1)
		);

	reloadTemplate(event) {
		this.isLoading = true;
		this.sosTemplateSettingsService
			.getDefaultTemplateUrl()
			.pipe(
				concatMap((url: string) =>
					this.sosSettingsService.getDocumentFromURL(url)
				),
				finalize(() => {
					this.isLoading = false;
					this.isReload = true;
					this.templateId = null;
					this.undoReset$.next(event);
				}),
				take(1)
			)
			.subscribe((data) => {
				if (data) {
					const template = this.isTapLevel
						? data
						: `<div ${contentEditable.false}>${data}</div>`;
					this.currentTemplate = template;
					this.editor.content = template;
				}
			});
	}

	backToEdit() {
		this.currentTemplate = this.tempData;
		this.isPreview = false;
		$(
			`${this.componentName} .fr-element.fr-view, ${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'unset');
		$(`${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 1,
		});
	}

	showPreview() {
		this.isPreview = true;
		this.tempData = this.editor?.content;
		const getConvertedContent = getContentWithMergeTags(
			this.editor.content,
			this.mergeTag
		);

		$(
			`${this.componentName} .fr-element.fr-view, ${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`
		).css('pointer-events', 'none');
		$(`${this.componentName} .fr-toolbar .fr-btn:not([id*="fullscreen"])`).css({
			opacity: 0.6,
		});
		this.currentTemplate = removeEmptyParagraphs(getConvertedContent);
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
