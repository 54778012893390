import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface TlMobileSettingsState {
	iosVersion: string;
}

export function createInitialState(): TlMobileSettingsState {
	return {
		iosVersion: '',
	};
}

@Injectable()
@StoreConfig({ name: 'tl-mobile-settings' })
export class TlMobileSettingsStore extends Store<TlMobileSettingsState> {
	constructor() {
		super(createInitialState());
	}

	setIosVersion(iosVersion: string) {
		this.update({ iosVersion });
	}
}
