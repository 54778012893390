<form [formGroup]="form" [class.submitted]="submitted">
	<div class="form-row collapse-label-background">
		<div class="col-8 mb-3">
			<label for="" class="font-weight-bold label-font tap-text-primary">
				Medical History
			</label>
		</div>
	</div>

	<div class="">
		<div class="form-row align-items-center">
			<div class="col-lg-3 text-right"></div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="crt-custom-dropdown w-100">
					<select
						id="height"
						class="crt-form-control form-control"
						name="height"
						(change)="selectChangeHeight()"
						formControlName="height"
						[class.disabled]="
							!!currentPrimaryEdit?.toString() || !!isBodyMeasureLoading
						"
					>
						<option
							*ngFor="let item of heightOptionsDd$ | async"
							[value]="item.value"
						>
							{{ item.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<span class="crt-custom-dropdown w-100">
					<select
						id="weight"
						class="crt-form-control form-control"
						name="weight"
						(change)="selectChangeWeight()"
						formControlName="weight"
						[class.disabled]="
							!!currentPrimaryEdit?.toString() || !!isBodyMeasureLoading
						"
					>
						<option
							*ngFor="let item of weightOptionsDd$ | async"
							[value]="item.value"
						>
							{{ item.display }}
						</option>
					</select>
				</span>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<label
					for=""
					class="font-weight-bold"
					[class.tap-opacity-25]="PrimaryArray?.controls?.length === 0"
					>Smoking Status</label
				>
			</div>
			<div class="col-12 col-lg-2 mb-2">
				<label
					for=""
					class="font-weight-bold"
					[class.tap-opacity-25]="PrimaryArray?.controls?.length === 0"
				>
					BMI
				</label>
			</div>
			<div class="d-lg-block col-lg-1 text-right">
				<ng-container *ngIf="!!isBodyMeasureLoading">
					<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
				</ng-container>
			</div>
		</div>

		<div formArrayName="primaryArray">
			<div
				class="form-row algin-items-center"
				*ngFor="
					let item of PrimaryArray.controls;
					let pointIndex = index;
					let last = last
				"
				[formGroupName]="pointIndex"
			>
				<ng-container *ngIf="!!findPeople(item.get('client').value)">
					<ng-container *ngIf="!isAdviceProcessEnded">
						<div class="d-lg-none col-lg-2 text-right">
							<ng-container *ngIf="item.get('btnSavePrimary').value">
								<button
									id="medicalHistory_savePrimaryButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="savePrimary(pointIndex)"
									[disabled]="isPrimaryEdit(pointIndex)"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnEditPrimary').value">
								<button
									id="medicalHistory_editPrimaryButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editPrimary(pointIndex)"
									[disabled]="
										isPrimaryEdit(pointIndex) || !!isBodyMeasureLoading
									"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnSavePrimary').value">
								<button
									id="medicalHistory_removePrimaryButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removePrimary(pointIndex)"
									[disabled]="isPrimaryEdit(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</div>
					</ng-container>
					<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!last">
						<span
							class="w-100"
							[class.crt-custom-dropdown]="item.get('client').enabled"
						>
							<select
								id="medicalHistory_client_{{ pointIndex }}"
								class="crt-form-control form-control"
								name="people"
								formControlName="client"
								placeholder="Client"
								[attr.disabled]="true"
								[class.select-text-muted]="!item.get('client').value"
							>
								<option
									class="select-text-muted"
									value=""
									disabled
									selected
									hidden
								>
									Client
								</option>
								<option
									*ngFor="let gpClient of clientList"
									[value]="gpClient.value"
									class="select-text-option"
								>
									{{ gpClient.display }}
								</option>
							</select>
						</span>
					</div>
					<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="!last">
						<ng-container *ngIf="Height.value?.includes('ft'); else showCm">
							<input
								id="medicalHistory_heightValue_{{ pointIndex }}"
								(keyup)="inputChangeHeight(pointIndex)"
								appFeetInchesNumber
								class="crt-form-control form-control"
								placeholder="Height"
								formControlName="heightValue"
								type="text"
							/>
							<span
								class="tap-required-field"
								*ngIf="item.get('heightValue').invalid && submitted"
								>* Input correct format (e.g.: 5'6" or 5')</span
							>
						</ng-container>
						<ng-template #showCm>
							<input
								id="medicalHistory_heightValue_{{ pointIndex }}"
								(keyup)="inputChangeHeight(pointIndex)"
								appTwoDigitDecimalNumber
								class="crt-form-control form-control"
								placeholder="Height"
								formControlName="heightValue"
								type="text"
							/>
						</ng-template>
					</div>
					<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="!last">
						<input
							id="medicalHistory_weightValue_{{ pointIndex }}"
							(keyup)="inputChangeWeight(pointIndex)"
							appTwoDigitDecimalNumber
							class="crt-form-control form-control"
							placeholder="Weight"
							formControlName="weightValue"
							type="text"
						/>
					</div>
					<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="!last">
						<span
							class="w-100"
							[class.tap-opacity-25]="PrimaryArray.controls.length === 0"
							[class.crt-custom-dropdown]="item.get('smokingStatus').enabled"
						>
							<select
								id="medicalHistory_smokingStatus_{{ pointIndex }}"
								class="crt-form-control form-control"
								formControlName="smokingStatus"
								placeholder="Smoke?"
								[class.select-text-muted]="!item.get('smokingStatus').value"
							>
								<option
									class="select-text-muted"
									[attr.disabled]="true"
									selected
									hidden
									value
								>
									Smoke?
								</option>
								<option value=""></option>
								<option class="select-text-option" value="Smoker">
									Smoker
								</option>
								<option class="select-text-option" value="Non-smoker">
									Non-smoker
								</option>
							</select>
						</span>
					</div>
					<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="!last">
						<input
							id="medicalHistory_bmiPrimary_{{ pointIndex }}"
							appTwoDigitDecimalNumber
							class="crt-form-control form-control"
							[class.tap-opacity-25]="PrimaryArray.controls.length === 0"
							placeholder="BMI"
							formControlName="bMI"
							type="text"
						/>
					</div>
					<ng-container *ngIf="!isAdviceProcessEnded">
						<div
							class="d-none d-lg-block col-lg-1 text-right mb-2"
							[class.border-bottom]="!last"
						>
							<ng-container *ngIf="item.get('btnSavePrimary').value">
								<button
									id="medicalHistory_savePrimaryButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="savePrimary(pointIndex)"
									[disabled]="isPrimaryEdit(pointIndex)"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnEditPrimary').value">
								<button
									id="medicalHistory_editPrimaryButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editPrimary(pointIndex)"
									[disabled]="
										isPrimaryEdit(pointIndex) || !!isBodyMeasureLoading
									"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnSavePrimary').value">
								<button
									id="medicalHistory_removePrimaryButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removePrimary(pointIndex)"
									[disabled]="isPrimaryEdit(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<div class="form-row line mb-4 mt-4"></div>

		<div formArrayName="peopleArray">
			<div
				class="form-row mb-2 font-weight-bold"
				[class.tap-opacity-25]="PeopleArray.controls.length === 0"
			>
				<div class="col-12 col-lg-3"></div>
				<div class="col-12 col-lg-2">Health Issue</div>
				<div class="col-12 col-lg-2">First Symptoms</div>
				<div class="col-12 col-lg-2">Last Symptoms</div>
				<div class="col-12 col-lg-2">Current Terms</div>
			</div>
			<div
				class="form-row algin-items-center mb-2"
				*ngFor="
					let item of PeopleArray.controls;
					let pointIndex = index;
					let last = last
				"
				[formGroupName]="pointIndex"
			>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<div class="d-lg-none col-lg-2 text-right pt-3 pt-lg-0">
						<div *ngIf="!item.get('isSaving').value">
							<ng-container *ngIf="item.get('btnSavePeople').value">
								<button
									id="medicalHistory_savePeopleButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="savePeople(pointIndex)"
									[disabled]="isPeopleEdit(pointIndex)"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnEditPeople').value">
								{{ pointIndex }}
								<button
									id="medicalHistory_editPeopleButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editPeople(pointIndex)"
									[disabled]="isPeopleEdit(pointIndex)"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnSavePeople').value &&
									pointIndex < medicalHistory.length
								"
							>
								<button
									id="medicalHistory_deletePeopleButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="deletePeople(pointIndex)"
									[disabled]="isPeopleEdit(pointIndex)"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnSavePeople').value">
								<button
									id="medicalHistory_removePeopleButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removePeople(pointIndex)"
									[disabled]="isPeopleEdit(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</div>
						<ng-container *ngIf="item.get('isSaving').value">
							<app-manual-loader-mini
								[isLoading]="item.get('isSaving').value"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</ng-container>
				<div class="col-12 col-lg-3 mb-2">
					<span
						class="w-100"
						[class.crt-custom-dropdown]="item.get('client').enabled"
					>
						<select
							id="medicalHistory_client_{{ pointIndex }}"
							class="crt-form-control form-control"
							name="people"
							formControlName="client"
							placeholder="Client"
							(change)="onSelect(pointIndex, $event.target.value)"
							[class.select-text-muted]="!item.get('client').value"
						>
							<option class="select-text-muted" selected hidden value="">
								Client
							</option>
							<option
								*ngFor="let gpClient of clientList"
								[value]="gpClient.value"
								class="select-text-option"
							>
								{{ gpClient.display }}
							</option>
						</select>
					</span>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<input
						id="medicalHistory_healthIssue_{{ pointIndex }}"
						class="crt-form-control form-control"
						type="text"
						formControlName="healthIssue"
						placeholder="Health Issue"
					/>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<app-date-input
						textboxId="medicalHistory_dateRangeMin_{{ pointIndex }}"
						ngProjectAs="input"
						class="crt-form-control-field"
						[attr.disabled]="item.get('dateRangeMin').disabled"
						formControlName="dateRangeMin"
						placeholder="dd/mm/yyyy"
						textboxId="DateTargetMin"
					>
					</app-date-input>
				</div>
				<div class="col-12 col-lg-2 mb-2">
					<app-date-input
						textboxId="medicalHistory_dateRangeMax_{{ pointIndex }}"
						ngProjectAs="input"
						class="crt-form-control-field"
						[attr.disabled]="item.get('dateRangeMax').disabled"
						formControlName="dateRangeMax"
						placeholder="dd/mm/yyyy"
						textboxId="DateTargetMax"
					>
					</app-date-input>
				</div>
				<div class="col-12 col-lg-2 mb-1">
					<span
						class="w-100"
						[class.crt-custom-dropdown]="
							item.get('currentlyExcludedLoaded').enabled
						"
					>
						<select
							id="medicalHistory_currentlyExcludedLoaded_{{ pointIndex }}"
							class="crt-form-control form-control"
							name="exclude"
							formControlName="currentlyExcludedLoaded"
							[class.select-text-muted]="
								!item.get('currentlyExcludedLoaded').value
							"
						>
							<option
								class="select-text-muted"
								[attr.disabled]="true"
								selected
								hidden
								value
							>
								Current Terms
							</option>
							<option value=""></option>
							<option
								*ngFor="let d of currentTerms$ | async"
								class="select-text-option"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</span>
				</div>
				<ng-container *ngIf="!isAdviceProcessEnded">
					<div class="d-none d-lg-block col-lg-1 text-right">
						<div *ngIf="!item.get('isSaving').value">
							<ng-container *ngIf="item.get('btnSavePeople').value">
								<button
									id="medicalHistory_savePeopleButton_{{ pointIndex }}"
									color="primary"
									type="button"
									class="icon-btn"
									(click)="savePeople(pointIndex)"
									[disabled]="
										item.get('btnDisable').value || isPeopleEdit(pointIndex)
									"
								>
									<i class="material-icons md-16"> save </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnEditPeople').value">
								<button
									id="medicalHistory_editPeopleButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="editPeople(pointIndex)"
									[disabled]="isPeopleEdit(pointIndex)"
								>
									<i class="material-icons md-16"> edit </i>
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									item.get('btnEditPeople').value &&
									pointIndex < medicalHistory.length
								"
							>
								<button
									id="medicalHistory_deletePeopleButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="deletePeople(pointIndex)"
									[disabled]="isPeopleEdit(pointIndex)"
								>
									<i class="material-icons md-16"> delete </i>
								</button>
							</ng-container>
							<ng-container *ngIf="item.get('btnSavePeople').value">
								<button
									id="medicalHistory_removePeopleButton_{{ pointIndex }}"
									type="button"
									class="icon-btn"
									(click)="removePeople(pointIndex)"
									[disabled]="isPeopleEdit(pointIndex)"
								>
									<i class="material-icons md-16"> close </i>
								</button>
							</ng-container>
						</div>
						<ng-container *ngIf="item.get('isSaving').value">
							<app-manual-loader-mini
								[isLoading]="item.get('isSaving').value"
							></app-manual-loader-mini>
						</ng-container>
					</div>
				</ng-container>
				<div class="col-12 col-lg-12 mb-2" [class.border-bottom]="!last">
					<div class="col-12 col-lg-11 p-0">
						<textarea
							id="medicalHistory_detailsNotes_{{ pointIndex }}"
							class="crt-form-control form-control mb-3"
							rows="3"
							formControlName="detailsNotes"
							placeholder="Details/Notes"
						></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="form-row my-4">
			<div class="col-5 col-lg-6">
				<ng-container *ngIf="!isAdviceProcessEnded">
					<button
						id="medicalHistory_addMedicalHistoryButton"
						type="button"
						class="tap-btn tap-btn-outline tap-btn--shadow px-4 border-0 col-auto mb-2"
						(click)="addMedicalHistory()"
						[disabled]="(isMedicalLoading$ | async) || isAddMode || isEditMode"
					>
						Add Medical History +
					</button>
				</ng-container>
				<ng-container *ngIf="isMedicalLoading$ | async">
					<app-manual-loader-mini
						[isLoading]="isMedicalLoading$ | async"
					></app-manual-loader-mini>
				</ng-container>
			</div>
			<div class="col-7 pt-1 col-lg-6 text-right"></div>
		</div>
		<div class="form-row line mb-3"></div>
	</div>
</form>
