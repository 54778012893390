<div
	class="modal-header text-light tap-bg-primary d-flex justify-content-between"
>
	<h4 class="modal-title font-weight-normal">
		{{ title }}
		<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
	</h4>
	<div class="clrearfix d-flex">
		<button type="button" class="close" aria-label="Close" (click)="close()">
			<span class="material-symbols-outlined">close</span>
		</button>
	</div>
</div>
<div class="modal-body" [class.loading]="loading">
	<div class="modal-body__inner clearfix py-3">
		<div class="container">
			<div class="row mb-4" *ngIf="!!message">
				<span class="font-weight-bold">{{ message }}</span>
			</div>
			<div class="row mt-1">
				<div class="col-12 p-0">
					<!-- here -->
					<table class="table table-hover border-bottom">
						<tbody>
							<tr
								*ngFor="let item of listPerPage; let i = index"
								id="document_{{ i }}"
								(click)="selectLinkDocument(item)"
								[class.loading]="loading"
							>
								<th class="max-width-150px" scope="row">
									<span class="d-block file-name">{{ item.FileName }}</span>
								</th>
								<td>{{ item.CreateDateTime | momentDatetime }}</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="this.documents && this.documents?.length > itemPerPage"
					>
						<pagination
							class="p-0"
							[totalItems]="
								this.documents && this.documents?.length > 0
									? this.documents?.length
									: 0
							"
							[(ngModel)]="currentpage"
							[itemsPerPage]="itemPerPage"
							[maxSize]="5"
							(pageChanged)="setPage($event)"
						>
						</pagination>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
