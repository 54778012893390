import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, take, tap, mergeMap } from 'rxjs/operators';
import { ServiceAdviceProcessState } from '@shared/models/advice-process/advice-process.model';
import { objectUtil, convertUtil } from 'src/app/util/util';
import { ApiService } from '@core/base/api.service';
import { CustomerService } from '@core/customer/customer.service';
import { DocumentModel } from '@shared/models/client-review-template/document/document.model';
import { DocumentUpload } from '@shared/models/documents/document.model';
import { CrtMortgageQuery } from '../../crt-mortgage/state/crt-mortgage.query';
import { CrtMortgageStore } from '../../crt-mortgage/state/crt-mortgage.store';
import { HtmlToPdfService } from './html-pdf/html-to-pdf.service';
import { DocumentUploadState } from '@shared/models/documents/document.model';
import { CrtKiwiSaverQuery } from '../../crt-kiwisaver/state/crt-kiwisaver.query';
import { CrtKiwiSaverStore } from '../../crt-kiwisaver/state/crt-kiwisaver.store';
import { HtmlPdfConfigState } from './html-pdf/defaults-config';

@Injectable()
export class CrtDocumentService {

	constructor(
		private api: ApiService,
		protected customerService: CustomerService,
		private query: CrtMortgageQuery,
		private store: CrtMortgageStore,
		private htmlToPdfService: HtmlToPdfService,
		private koatQuery: CrtKiwiSaverQuery,
		private koatStore: CrtKiwiSaverStore,
	) {}

	/**
	 * Parse HTML String for PDF
	 * @param template HTML content as string
	 * @param orientation document orientation (default: portrait)
	 * @returns string
	 */
	contentForPdf = (template: string, orientation: string = 'portrait') => {
		return this.htmlToPdfService.contentForPdf(template, orientation);
	};

	/*-------------------------------------------------------------*\
  		This is for the NEW HTML-to-PDF integration from Endpoint
	\*-------------------------------------------------------------*/

	/**
	 * Convert HTML String content to the new downloadable PDF
	 * @param content HTML content as string
	 * @param documentName Document name on PDF
	 * @param options Optional wkhtmltopdf config options
	 * @returns Observable<string> of new html
	 */
	downloadDocumentPDF(
		content: string,
		documentName: string,
		options?: HtmlPdfConfigState
	) {
		return this.htmlToPdfService.downloadDocumentPDF(
			content || '',
			documentName || 'Document',
			options
		);
	}

	generatePDFbase64(content: string, pdfOptions?: HtmlPdfConfigState) {
		return this.htmlToPdfService.generatePDFbase64(content, pdfOptions);
	}

	/**
	 * Create a whole html with css styles containing the content provided as body
	 * @param bodyContent HTML body content as string
	 * @param documentName Document name on PDF
	 * @returns Observable<string> of new html
	 */

	getTemplateWithCss(bodyContent: string, documentName: string) {
		return this.htmlToPdfService.createHtmlForPdf(bodyContent, documentName);
	}

	/*-----------------------------*\
          	  REQUESTS
	\*-----------------------------*/

	// @TODO: Remove update advice processes from this service
	/**
	 * Advice Process update document
	 * @param id DocumentID
	 * @param field Document in Advice Process CRM
	 * @returns Observable<string>
	 */
	updateAdviceProcess(id: number, type: string) {
		const adviceProcess = this.query.getValue().adviceProcess;
		return this.updateOATAdviceProcess(
			adviceProcess.adviceProcessID,
			id,
			type
		).pipe(take(1));
	}

	updateKOATAdviceProcess(id: number, type: string) {
		const adviceProcess = this.koatQuery.getValue().adviceProcess;
		const oatType = 'K';
		return this.updateOATAdviceProcess(
			adviceProcess.adviceProcessID,
			id,
			type,
			oatType
		).pipe(take(1));
	}

	// Requests
	getAdviceProcess(adviceProcessId: number, oatType?: string) {
		const endpoint = `adviceprocesses/${adviceProcessId}`;
		return this.api.get<ServiceAdviceProcessState>(endpoint).pipe(
			map((data) => (data ? objectUtil.mapPascalCaseToCamelCase(data) : null)),
			tap((data) =>
				applyTransaction(() => {
					if (oatType && oatType === 'K') {
						this.koatStore.setAdviceProcess(data);
					} else {
						this.store.setAdviceProcess(data);
					}
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateOATAdviceProcess(
		adviceProcessId: number,
		documentId: number,
		type: string,
		oatType?: string,
	) {
		const endpoint = `adviceprocesses/${adviceProcessId}/document/${documentId}/${type}`;
		return this.api.put<string>(endpoint).pipe(
			catchError(() => EMPTY),
			finalize(() => {
				this.getAdviceProcess(adviceProcessId, oatType).pipe(take(1)).subscribe()
			})
		);
	}

	getDocument(documentId: number) {
		return this.api
			.get<DocumentModel>(`documents/${documentId}`)
			.pipe(catchError(() => EMPTY));
	}

	saveDocument(req: DocumentUpload): Observable<number> {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}

	updateDocument(req: DocumentUpload) {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}

	getDocumentLink(url: string, params?) {
		return this.api
			.getExternalResource(url, { ...params })
			.pipe(catchError((err) => {
				return EMPTY
			}));
	}

	imageToPdf(file: File): Observable<string> {
		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

    return of(file)
    .pipe(
			// convert file to base64
			mergeMap(() => convertUtil.simpleConvertToBase64(file)),
			// create a html img string and add the converted base64 image in src
			map((base64) => `<img src="${base64}" />`),
			// convert html img with base64 src to pdf
			mergeMap((img) => {
				return this.generatePDFbase64(img, {
					FileName: convertFilename(file),
				});
			})
		);
	}

	uploadDocument(req) {
		const endpoint = 'documents';
		return this.api
			.post3<any>(endpoint, req)
			.pipe(catchError(() => EMPTY))
			.pipe(
				catchError(() => of(undefined))
			);
	}

	combineDocuments(documentIds: number[]) {
		const endpoint = `documents/render/merge-pdf`;
		return this.api
			.postFileDownload(endpoint, documentIds)
			.pipe(catchError((x) => throwError(x)));
	}

	downloadLink(referenceId) {
		return this.api.get<string>(`documents/download/${referenceId}`);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req).pipe(
			catchError(() => EMPTY)
		);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api
			.put<DocumentUploadState>(endpoint, body)
			.pipe(catchError(() => EMPTY));
	}

  uploadCombineDocument(req: DocumentUpload): Observable<number> {
		return this.customerService.UploadCombineDocument(req);
	}
}
