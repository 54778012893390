import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Note } from './note.model';

export interface NoteState extends EntityState<Note> {}

@Injectable()
@StoreConfig({ name: 'note', idKey: 'cRTId' })
export class NoteStore extends EntityStore<NoteState, Note> {
	constructor() {
		super();
	}
}
