<!-- Main route -->
<router-outlet></router-outlet>

<!-- App Loader -->
<app-route-loader></app-route-loader>

<!-- Error logger -->
<app-error-log></app-error-log>
<app-toast></app-toast>

<!-- App theme (New: with dark mode) -->
<app-tap-theme></app-tap-theme>

<ng-container *ngIf="hasNewReleaseUpdate">
	<div class="new-release-notif">
		A new version of the CRM is available.
		<a (click)="activateNewReleaseUpdate()" class="activate-and-reload"
			>Click here to update.</a
		>
		<a (click)="closeNewReleaseUpdate()" class="ml-3 text-white"
			><i class="material-icons">close</i></a 
		>
	</div>
</ng-container>

<ng-container *ngIf="
	(exportsStatus$ | async) === 3 ||
	(exportsStatus$ | async) === 0
">
	<app-exports (closeExportPopupEvent)="closeExportPopup()"></app-exports>
</ng-container>


<ng-container *ngIf="
	(transferStatus$ | async) === 3 ||
	(transferStatus$ | async) === 0
">
	<app-transfer></app-transfer>
</ng-container>