import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, EMPTY, iif, Observable, Observer, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, mergeMap, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DropdownValue } from 'src/app/domain/dropdown-value/dropdown-value.model';
import { peopleToViewDisplayValueUtil } from 'src/app/modules/crm/client-review-template/util/mapViewDisplayValue.util';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { PeopleModalComponent } from 'src/app/shared/modal/crt/fact-find/people/people-modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { DependentState } from 'src/app/shared/models/client-review-template/dependent/dependent.model';
import { RelationshipTypes } from 'src/app/shared/models/_general/client.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { PeopleEntitiesQuery } from '../state/people-entities.query';
import { PeopleEntitiesService } from '../state/people-entities.service';
declare var $: any;

@Component({
  selector: 'app-dependant',
  templateUrl: './dependant.component.html',
  styleUrls: ['./dependant.component.scss']
})
export class DependantComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  dropdownCodes: DropdownValue[];

  primaryColor: string;
  bsModalRef: BsModalRef;

  @Input() dependents$: Observable<DependentState[]>;
  @Input() SCR$: Observable<ViewDisplayValue[]>;
  @Input() APCRTG$: Observable<ViewDisplayValue[]>;
  @Input() isDependantsLoading$: Observable<boolean>;

  @Input() addFn$: ({ dependent, adviceProcessId }) => Observable<any>;
  @Input() addNewFn$: ({ dependent, adviceProcessId }) => Observable<any>;
  @Input() addNewPeopleFn$: ({ people, adviceProcessId }) => Observable<any>;
  @Input() updateFn$: (dependent: DependentState) => Observable<any>;
  @Input() deleteFn$: (id: number) => Observable<any>;

  elseMinusPeople = true;
  elseMinusDependents = true;
  elseMinusTrust = true;
  elseMinusCompany = true;
  isLoading = false;
  crtLoading: number;
  isAddNew = false;
  isSaving = false;

  clientId = this.route.snapshot.paramMap.get('clientId');
  adviceProcessId = parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10);

  dependentList$ = new BehaviorSubject<ViewDisplayValue[]>(null);
  dependentInfo = crtId => this.peopleEntitiesService.getPersonInfo(crtId);
  constructor(
    private peopleEntitiesQuery: PeopleEntitiesQuery,
    private peopleEntitiesService: PeopleEntitiesService,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private loggerService: LoggerService) { }

  ngOnInit(): void {
  }

  collapseDependents(showMore?: boolean) {
    $('#collapseDependents').toggle();
    this.elseMinusDependents = showMore ? false : true;
  }

  toggleLoader(cRTId: number, load: boolean) {
    this.crtLoading = (cRTId && load) ? +cRTId : null;
  }

  isCrtLoading(cRTId: number) {
    if (this.isAddNew && !this.isSaving) { return false; }
    if (this.isSaving && cRTId === 0) { return true; }
    if (!this.isSaving && cRTId === 0) { return false; }
    return +this.crtLoading === +cRTId;
  }

  updateDependent = (model: DependentState) => new Observable<any>((obs) => {
    obs.next(model);
    obs.complete();
  }).pipe(
    withLatestFrom(this.peopleEntitiesQuery.people$),
    concatMap(([dependant, people]) => {
      const count = people?.filter(x => x.cRTId > 0)?.length;
      return (dependant?.relationship !== RelationshipTypes.Child && count >= 4) ? this.warnMsg() : of(model)
    }),
    catchError((err) => throwError(err)),
    filter(x => !!x),
    concatMap(x => this.updateFn$(x)),
    finalize(() => {
      this.isAddNew = false;
      this.toggleLoader(+model?.cRTId, false);
      this.cd.detectChanges();
    })
  )

  editDependent(crtId: number) {
    this.toggleLoader(+crtId, true);
    const decline = new Observable((obs: Observer<any>) => {
      this.toggleLoader(crtId, false);
      obs.complete();
    });
    this.dependentInfo(crtId).subscribe((x) => {
      const initState = {
        header: 'Dependent Details',
        message: `DependentDetails`,
        scr$: this.SCR$,
        apcrtg$: this.APCRTG$,
        dependentInfo: x,
        savefn: this.updateDependent,
        decline$: decline,
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    })
  }

  warnMsg = () => {
    const message = logMessage.oat.mortgage.factFind.people.warning.maxPeople;
    this.loggerService.Warning({}, message);
    throw new Error(message);
  }

  addNewDependent = (model: DependentState) => new Observable<any>((obs) => {
    this.isSaving = true;
    obs.next(model);
    obs.complete();
  }).pipe(
    withLatestFrom(this.peopleEntitiesQuery.people$),
    concatMap(([peopleModel, people]) => {
      const count = people?.filter(x => x.cRTId > 0)?.length;
      return (peopleModel?.relationship !== RelationshipTypes.Child && count >= 4) ? this.warnMsg() : of(peopleModel)
    }),
    catchError((err) => throwError(err)),
    filter(x => !!x),
    mergeMap(x =>
      iif(
        () => model?.relationship !== RelationshipTypes.Child,
        this.addNewPeopleFn$({ people: { ...model, sectionCode: AdviceProcessSectionCodes.People }, adviceProcessId: this.adviceProcessId }).pipe(
          concatMap((y) => this.peopleEntitiesService.getDependents(this.adviceProcessId, AdviceProcessSectionCodes.Dependants, true))
        ),
        this.addNewFn$({ dependent: x, adviceProcessId: this.adviceProcessId }))
    ),
    finalize(() => {
      this.isAddNew = false;
      this.isSaving = false;
      this.cd.detectChanges();
    })
  )

  change(e, i: number) {
    if (e.target.value === 'new') {
      const decline = new Observable((obs: Observer<any>) => {
        (document.getElementById('addNewDependantsDropdown_' + i) as HTMLInputElement).value = '';
        obs.complete();
      });
      const initState = {
        header: 'Dependant Details',
        message: `DependentDetails`,
        scr$: this.SCR$,
        apcrtg$: this.APCRTG$,
        savefn: this.addNewDependent,
        decline$: decline,
      };
      this.bsModalRef = this.modalService.show(PeopleModalComponent, {
        class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow moatV1',
        initialState: initState,
        ignoreBackdropClick: true,
        keyboard: false
      });
    } else {
      this.peopleEntitiesQuery.dependentsFromCRMandCRTChildOnly$.pipe(
        map((x) => x?.find(y => y.customerID === +e.target.value)),
        mergeMap((x) => this.dependents$
          .pipe(
            map(p => {
              p[i] = {
                cRTId: 0,
                age: x?.dateOfBirth ? moment().diff(x.dateOfBirth, 'years') : null,
                birthDate: x?.dateOfBirth,
                customerType: x?.customerType,
                name: x?.firstName && x?.lastName ? x?.firstName.concat(' ', x.lastName) : null,
                relationship: x?.relationship,
                customerId: x?.customerID
              }
            })
          )
        ),
        take(1)
      ).subscribe()
    }
  }

  deleteItem = (id: number) =>
    this.deleteFn$(id)
      .pipe(
        mergeMap(() => this.updateDependantDd()),
        finalize(() => (this.isLoading = false)),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe((x) => {
        this.toggleLoader(id, false);
      });

  deleteDependent(id: number) {
    this.toggleLoader(id, true);

    const confirm = new Observable((obs) => {
      this.deleteItem(id);
      obs.next();
      obs.complete();
    });

    const decline = new Observable((obs: Observer<any>) => {
      this.toggleLoader(id, false);
      obs.complete();
    });

    const initState = {
      header: 'Delete Dependant',
      message: logMessage.oat.shared.factFind.delete,
      delete$: confirm,
      decline$: decline,
      confirmButton: 'OK',
      detachCloseIcon: false
    };
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-dialog-centered modal-dialog',
      initialState: initState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  addExistingDependent(p: DependentState) {
    this.isSaving = true;
    this.peopleEntitiesQuery.dependentsFromCRMChildOnlyNoFilter$.pipe(
      map((x) => x?.find(c => c.customerID === +p.customerId)),
      map(client => {
        const adviceProcessId = parseInt(this.route.snapshot.paramMap.get('adviceProcessId'), 10);
        const dependent = {
          customerID: client.customerID,
          adviceProcessId,
          sectionCode: AdviceProcessSectionCodes.Dependants,
          firstName: client.firstName,
          lastName: client.lastName,
          gender: client.gender ? (client.gender === 'F' ? 'Female' : 'Male') : null,
          dateOfBirth: client.dateOfBirth,
          physicalAddress: client.physicalAddress,
          relationship: client.relationship,
          email: client.email,
          knownAs: client.knownAs,
          employment: client.employment,
          homePhone: client.homePhone,
        }
        return { dependent, adviceProcessId }
      }),
      take(1),
      mergeMap(res => this.addFn$({ dependent: res.dependent, adviceProcessId: res.adviceProcessId })),
      finalize(() => {
        this.isAddNew = false;
        this.isSaving = false;
        this.cd.detectChanges();
      })
    ).subscribe()
  }

  removeDependent(index: number) {
    this.dependents$.pipe(
      map(x =>
        x.splice(index, 1)
      ),
      take(1)
    ).subscribe(() => {
      this.isAddNew = false;
      this.cd.detectChanges();
    })
  }

  addDependent() {
    this.isAddNew = true;
    this.cd.detectChanges();
    this.peopleEntitiesQuery.dependentsFromCRMandCRTChildNotDeceasedOnly$.pipe(
      mergeMap(x => peopleToViewDisplayValueUtil(x)),
      tap(x => this.dependentList$.next(x)),
      switchMap(() => this.dependents$.pipe(
        map(x => {
          const newDependant: DependentState = {
            cRTId: 0,
            age: 0,
            birthDate: '',
            customerType: '',
            name: '',
            relationship: '',
            customerId: 0
          }
          x?.push(newDependant);
        })

      )),
      take(1)
    ).subscribe()
  }


  updateDependantDd = () =>
    this.peopleEntitiesQuery.dependentsFromCRMandCRTChildNotDeceasedOnly$.pipe(
      mergeMap((x) => peopleToViewDisplayValueUtil(x)),
      tap((x) => this.dependentList$.next(x)),
      take(1)
    );

  ngOnDestroy() {
    this.dependents$
			.pipe(
				map((x) => {
					if (x?.some((p) => p?.cRTId === 0 || !p?.cRTId)) {
						x?.splice(
							x.findIndex((p) => p?.cRTId === 0 || !p.cRTId),
							1
						);

						return x;
					}
					return x;
				}),
        take(1)
			)
			.subscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
