import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { LoggerService } from '@core/logger/logger.service';
import { applyTransaction } from '@datorama/akita';
import { Sidebar, SidebarStatus } from '@modules/crm/crt-page/_shared/models/sidebar.model';
import { DeclarationSettingState } from '@modules/kiwisaver-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.model';
import { logMessage } from '@shared/error-message/error-message';
import {
	AdviceProcessPageCodes,
	KiwiSaverAdviceProcessPageIds,
	KiwiSaverAdviceProcessSectionCodes,
} from '@shared/models/advice-process/advice-process.model';
import { DocumentModel } from '@shared/models/documents/document.model';
import { objectUtil } from '@util/util';
import { EMPTY, combineLatest, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CrtKiwiSaverQuery } from '../../state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../../state/crt-kiwisaver.service';
import { CrtKiwiSaverStore } from '../../state/crt-kiwisaver.store';
import { DeclarationState } from './declaration.model';

export const declarationPdfOptions = {
	FileName: 'DECLARATION',
	DPI: '120',
};

@Injectable()
export class DeclarationService {
	constructor(
		private api: ApiService,
		protected koatQuery: CrtKiwiSaverQuery,
		protected koatService: CrtKiwiSaverService,
		protected koatStore: CrtKiwiSaverStore,
		private loggerService: LoggerService,
	) {}

	clearData() {
		applyTransaction(() => {
			this.koatStore.reset();
		});
	}

	getDeclarationPdfOptions() {
		return declarationPdfOptions;
	}

	setDeclarationFormValue(d: DeclarationState) {
		const data = this.koatQuery.getValue().declaration;
		applyTransaction(() => {
			this.koatStore.setDeclarationFormValue({
				...data,
				...d,
			});
		});
	}

	addDeclaration(declaration) {
		const endpoint = 'crt';
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId: this.koatQuery.getValue().adviceProcessId,
			sectionCode: KiwiSaverAdviceProcessSectionCodes.Declaration,
			signatures: declaration?.signatures?.map((x) => objectUtil.mapCamelCaseToPascalCase(x)),
			status: 1,
		});
		delete body.CRTId;

		return this.api.post(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					this.koatStore.setDeclaration({ ...declaration, cRTId: +x });
				});
			}),
			catchError(() => EMPTY),
		);
	}

	updateDeclaration(declaration) {
		const endpoint = `crt/${declaration?.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase({
			...declaration,
			adviceProcessId: this.koatQuery.getValue().adviceProcessId,
			sectionCode: KiwiSaverAdviceProcessSectionCodes.Declaration,
			signatures: declaration?.signatures?.map((x) => objectUtil.mapCamelCaseToPascalCase(x)),
			status: 1,
		});
		return this.api.put(endpoint, body).pipe(
			tap(() => {
				applyTransaction(() => {
					this.koatStore.setDeclaration(objectUtil.mapPascalCaseToCamelCase(body));
				});
			}),
			catchError((err) => {
				this.loggerService.Log(err, logMessage.oat.mortgage.declaration.error);
				return EMPTY;
			}),
		);
	}

	getDeclaration(adviceProcessId: number, settingsCode: string) {
		const endpoint = `crt/${adviceProcessId}/${settingsCode}`;
		return this.api.get<DeclarationState[]>(endpoint).pipe(
			tap((x) => {
				applyTransaction(() => {
					const state =
						!!x && x?.length > 0
							? objectUtil.mapPascalCaseToCamelCase(x[0])
							: {
									cRTId: 0,
									adviceProcessId: this.koatQuery.getValue().adviceProcessId,
									document: { referenceId: null, value: null },
									signatures: [],
								};
					this.koatStore.setDeclaration(state);
				});
			}),
			catchError(() => of([])),
		);
	}

	getDeclarationDocument(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.koatStore.setDeclarationSettings(state);
				}),
			),
			catchError(() => of(undefined)),
		);
	}

	getDeclarationEmailSettings(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;
		return this.api.get<DeclarationSettingState>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.koatStore.setDeclarationEmailSettings(state);
				}),
			),
			catchError(() => of(undefined)),
		);
	}

	getDeclarationDocumentFile(id: number) {
		return this.api.get<DocumentModel>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(`${url}?p=${Math.floor(Date.now() / 1000)}`, { responseType: 'text' });
	}

	getSignature(documentId) {
		const endpoint = `documents/${documentId}`;
		return this.api.get<DocumentModel>(endpoint).pipe(catchError(() => EMPTY));
	}

	disableDeclaration(sidebar: Sidebar[]): boolean {
		const appIndex = sidebar?.findIndex((x) => x?.id === KiwiSaverAdviceProcessPageIds.Declaration) || 0;

		if (appIndex <= 0) {
			return false;
		}

		const disableDeclaration = [...sidebar]
			?.splice(0, appIndex)
			?.map((x) => x?.status === 2)
			?.includes(false);

		return disableDeclaration;
	}

	setTabColor() {
		return combineLatest([
			this.koatQuery.adviceProcess$,
			this.koatQuery.pageStarted$,
			this.koatQuery.pageCompleted$,
		]).pipe(
			tap(([ap, pageStarted, _pageCompleted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (pageStarted?.includes(AdviceProcessPageCodes.KiwiSaverDeclaration)) {
					status = SidebarStatus.Incomplete;
					warning = 'Document must be saved to file';
				}

				if (
					!!ap?.documents &&
					ap?.documents?.length > 0 &&
					ap?.documents?.some((x) => x?.field === 'Declaration' && !!x?.value)
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.koatService.setSideSidebarStatus(KiwiSaverAdviceProcessPageIds.Declaration, false, status, warning);
			}),
		);
	}
}
