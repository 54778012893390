<div
	class="app-adduser-container fluid-container"
	[class.submitted]="submitted"
>
	<div class="app-user-header">
		<h1 class="py-2 px-3 mb-0">
			{{ header || "User Details - " + _data.FirstName + " " + _data.LastName }}
		</h1>
	</div>

	<div class="row tap-container service-tabs data-import">
		<tabset class="tab-container w-100">
			<tab heading="User Details">
				<form [formGroup]="form" class="user-details-form">
					<!-- Basic Information -->
					<div class="row my-2 mx-0 pl-0 pt-4 pb-4 border-bottom">
						<div class="col-12 col-md-9 pr-0">
							<div class="container user-details-form__main-cta">
								<div class="row">
									<div class="col-auto p-0">
										<button
											type="button"
											class="btn btn-primary tap-bg-primary mr-3"
											(click)="provideAccess()"
											id="provideAccess"
											[disabled]="isSaving || isSavingOthers || isEdit"
											*ngIf="CanProvideAccess"
										>
											Provide Access
											<i
												*ngIf="isSavingProvideAccess"
												class="fas fa-spinner fa-pulse"
											></i>
										</button>
									</div>
									<div class="col-auto p-0">
										<button
											type="button"
											class="btn btn-primary tap-bg-primary mr-3"
											(click)="TransferActivity()"
											id="transferActivity"
											[disabled]="isSaving || isSavingOthers || isEdit"
											*ngIf="CanModifyOtherUserSettings"
										>
											Transfer Activities
											<i
												*ngIf="isSavingTransferActivity"
												class="fas fa-spinner fa-pulse"
											></i>
										</button>
									</div>
									<div class="col-auto p-0">
										<button
											type="button"
											class="btn btn-primary tap-bg-primary mr-3"
											(click)="TransferClient()"
											id="transferClient"
											[disabled]="isSaving || isSavingOthers || isEdit"
											*ngIf="CanModifyOtherUserSettings"
										>
											Transfer Clients
											<i
												*ngIf="isSavingTransferActivity"
												class="fas fa-spinner fa-pulse"
											></i>
										</button>
									</div>
									<div class="col-auto p-0">
										<button
											type="button"
											class="btn btn-primary tap-bg-primary mr-3"
											id="userDetailsEdit"
											[disabled]="isSaving || isSavingOthers"
											(click)="enableEdit()"
											*ngIf="CanEdit && !isEdit && !isAdd"
										>
											Edit
										</button>
										<button
											type="button"
											class="btn btn-primary tap-bg-primary mr-3"
											id="userDetailsSaveEdit"
											[disabled]="isSaving || isSavingOthers"
											(click)="saveBtn()"
											*ngIf="(CanEdit && !!isEdit) || !!isAdd"
										>
											Save
											<i *ngIf="isSaving" class="fas fa-spinner fa-pulse"></i>
										</button>
										<button
											type="button"
											class="btn btn-primary tap-bg-primary mr-3"
											id="userDetailsCancelEdit"
											[disabled]="isSaving || isSavingOthers"
											(click)="cancelEdit()"
											*ngIf="CanEdit && !!isEdit"
										>
											Cancel
										</button>
									</div>
									<div
										class="col-auto p-0 d-flex align-items-center"
										formGroupName="StaffSettings"
									>
										<ng-container
											*ngIf="
												CanUpdateShowInAdviserList &&
												!showInAdviserListFilter.includes(
													form.get('SecurityGroup').value
												)
											"
										>
											<input
												#showInAdviser
												id="showInAdviser"
												formControlName="ShowInAdviserList"
												type="checkbox"
												class="ui-form-control"
												[class.disabled]="isSaving || isSavingOthers || isEdit"
												(click)="updateShowAdviserList()"
											/>
											<label
												for="showInAdviser"
												class="pl-2 pr-2"
												[class.disabled]="isSaving || isSavingOthers || isEdit"
												>Show in Adviser List
											</label>
										</ng-container>
										<ng-container
											*ngIf="
												isTapLevel &&
												leadGeneratorSecurityGroup.includes(
													form.get('SecurityGroup').value
												)
											"
										>
											<input
												id="leadHodlingUser"
												formControlName="LeadHoldingUser"
												type="checkbox"
												class="ui-form-control"
												[class.disabled]="isSaving || isSavingOthers || isEdit"
												(click)="updateLeadHoldingUser()"
											/>
											<label
												for="leadHodlingUser"
												class="pl-2"
												[class.disabled]="isSaving || isSavingOthers || isEdit"
												>Lead Holding User
											</label>
										</ng-container>
										<app-manual-loader-mini
											*ngIf="isSavingShowAdviserList || isSavingLeadHolingUser"
											[isLoading]="true"
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							class="col-12 col-md-3 pt-3 pt-lg-0 align-items-lg-center d-lg-flex flex-lg-end"
						>
							<div class="user-details-date text-center text-lg-right w-100">
								<span *ngIf="!!CreationDate">
									User Creation Date: {{ CreationDate }} </span
								><br />
								<span *ngIf="userStatus === 1 && !!ReactivationDate">
									User Reactivation Date: {{ ReactivationDate }}
								</span>
								<span *ngIf="userStatus === 0 && !!DeactivationDate">
									User Deactivation Date: {{ DeactivationDate }}
								</span>
							</div>
						</div>
					</div>
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 col-md-9 p-0">
								<div class="container-fluid">
									<!-- Basic Information: First Row -->
									<div class="row mt-3 mb-4">
										<div class="col-12 col-md-4">
											<label for="firstName" class="mb-1">
												First Name
												<span class="tap-required" *ngIf="FirstName.invalid"
													>*</span
												> </label
											><br />
											<app-input
												inputClassname="ui-form-control"
												formControlName="FirstName"
												id="firstName"
											></app-input>
										</div>
										<div class="col-12 col-md-4">
											<label for="lastName" class="mb-1">
												Last Name
												<span class="tap-required" *ngIf="LastName.invalid"
													>*</span
												> </label
											><br />
											<app-input
												inputClassname="ui-form-control"
												id="lastName"
												formControlName="LastName"
											></app-input>
										</div>
										<div class="col-12 col-md-4">
											<label for="birthDate" class="mb-1">Date of Birth</label
											><br />
											<app-date-input
												class="ui-form-control"
												formControlName="BirthDate"
												textboxId="birthDate"
											></app-date-input>
										</div>
									</div>

									<!-- Basic Information: 2nd row -->
									<div class="row mt-3 mb-4">
										<div class="col-12 col-md-4">
											<label for="userStatus" class="mb-1"
												>User Status<span
													class="tap-required"
													*ngIf="UserStatus.invalid"
													>*</span
												></label
											><br />
											<app-input-select
												id="userStatus"
												formControlName="IsActive"
												inputClassname="ui-form-control"
												valueProperty="option"
												displayProperty="name"
												[items]="isActiveOptions"
											></app-input-select>
										</div>
										<div class="col-12 col-md-4">
											<label for="securityGroup" class="mb-1">
												Security Group
												<span class="tap-required" *ngIf="SecurityGroup.invalid"
													>*</span
												> </label
											><br />
											<app-input-select
												id="securityGroup"
												formControlName="SecurityGroup"
												inputClassname="ui-form-control"
												valueProperty="securityGroupCode"
												displayProperty="securityGroupName"
												[items]="secGroups"
											></app-input-select>
										</div>
										<div class="col-12 col-md-4" formGroupName="StaffSettings">
											<label for="commisionStructure" class="mb-1"
												>Commission Structure</label
											><br />
											<app-input-select
												id="commisionStructure"
												formControlName="CommisionStructure"
												inputClassname="ui-form-control"
												displayProperty="display"
												[items]="SCS"
											></app-input-select>
										</div>
									</div>

									<!-- Basic Information: Third Row -->
									<div class="row mt-3 mb-4" formGroupName="StaffSettings">
										<div class="col-12 col-md-4">
											<label for="fSPRegistrationDate" class="mb-1"
												>FSP Registration Date</label
											><br />
											<app-date-input
												class="ui-form-control"
												formControlName="FSPRegistrationDate"
												textboxId="fSPRegistrationDate"
											></app-date-input>
										</div>
										<div class="col-12 col-md-4">
											<label for="fspNumber" class="mb-1">FSP number</label
											><br />
											<app-input
												inputClassname="ui-form-control"
												id="fspNumber"
												formControlName="FSPNumber"
											></app-input>
										</div>
										<div class="col-12 col-md-4">
											<ng-container *ngIf="CanUpdateAdviserServices">
												<!-- &nbsp; -->
												<label for="adviserServices" class="mb-1"
													>Adviser Services</label
												><br />
												<app-input-chips
													appearance="bootstrap"
													inputClassname="ui-form-control"
													formControlName="AdviserServices"
													displayProperty="display"
													id="adviserServices"
													valueProperty="value"
													[closeOnSelect]="false"
													[items$]="adviserServices$"
												>
												</app-input-chips>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
							<div
								class="col-12 col-md-3 pt-4 pr-4"
								formGroupName="StaffSettings"
							>
								<app-photo
									id="photoURL"
									[uploadType]="'modal'"
									[class.disabled]="!CanEdit || isSaving || isEdit || isAdd"
									[notCustomer]="true"
									[uploadFn$]="uploadPhoto$"
									[customThumbnail]="true"
									[customThumbnailText]="
										!CanEdit || !!isAdd ? ' ' : 'Insert or Upload picture'
									"
									[headerTitle]="'Insert or Upload picture'"
									[additionalInfo]="''"
									[imageUrl]="this.form.get('StaffSettings.PhotoURL').value"
								></app-photo>
							</div>
						</div>
					</div>

					<!-- Basic Information: Fourth Row -->
					<div class="container-fluid mb-3">
						<div class="row">
							<div class="col-12 col-md-3">
								<label for="startDate" class="mb-1">
									Contract Start Date
									<span class="tap-required" *ngIf="StartDate.invalid"
										>*</span
									> </label
								><br />
								<app-date-input
									class="ui-form-control"
									formControlName="StartDate"
									textboxId="startDate"
								></app-date-input>
							</div>
							<div class="col-12 col-md-3" formGroupName="StaffSettings">
								<label for="contractType" class="mb-1">Contract Type</label
								><br />
								<app-input-select
									id="contractType"
									formControlName="ContractType"
									inputClassname="ui-form-control"
									displayProperty="display"
									[items]="SCT"
								></app-input-select>
							</div>
							<div class="col-12 col-md-3" formGroupName="StaffSettings">
								<label for="resignationDate" class="mb-1">
									Resignation Date </label
								><br />
								<app-date-input
									class="ui-form-control"
									formControlName="ResignationDate"
									textboxId="resignationDate"
								></app-date-input>
							</div>
							<div class="col-12 col-md-3" formGroupName="StaffSettings">
								<label for="exitDate" class="mb-1"> Exit Date </label><br />
								<app-date-input
									class="ui-form-control"
									formControlName="ExitDate"
									textboxId="exitDate"
								></app-date-input>
							</div>
						</div>
					</div>

					<!-- Work Contact Details & CRM Services Header -->
					<div class="container-fluid mt-5 mb-1">
						<div class="row">
							<div class="col-12 col-md-8">
								<span class="section-header tap-text-primary"
									>Work Contact Details</span
								>
							</div>
							<div class="col-12 col-md-4">
								<span class="section-header tap-text-primary"
									>CRM Services</span
								>
							</div>
						</div>
					</div>
					<div class="container-fluid m-0">
						<div class="row m-0">
							<div class="col-12 border-bottom"></div>
						</div>
					</div>

					<!-- Work Contact Details -->
					<div class="container-fluid mt-3 mb-1">
						<div class="row">
							<div class="col-12 col-md-8">
								<div class="container-fluid p-0">
									<!-- Work Contact Details : Row 1 -->
									<div class="row mb-4">
										<div class="col-12 col-md-6">
											<label for="emailAddress" class="mb-1">
												Login Email Address
												<span
													class="tap-required"
													*ngIf="
														EmailAddress.invalid &&
														EmailAddress.value?.trim() === ''
													"
													>*</span
												> </label
											><br />
											<app-input
												inputClassname="ui-form-control"
												id="emailAddress"
												formControlName="EmailAddress"
												[isInvalid]="
													EmailAddress.invalid && EmailAddress.value?.trim()
												"
											></app-input>
										</div>
										<div class="col-12 col-md-6" formGroupName="StaffSettings">
											<label for="correspondenceEmail" class="mb-1">
												Correspondence Address</label
											><br />
											<app-input
												inputClassname="ui-form-control"
												id="correspondenceEmail"
												formControlName="CorrespondenceEmail"
											></app-input>
										</div>
									</div>
									<!-- Work Contact Details: Row 2 -->
									<div class="row mb-3">
										<div class="col-12 col-md-4">
											<label for="mobilePhone" class="mb-1">Mobile Phone</label
											><br />
											<app-input
												validatePhoneField
												inputClassname="ui-form-control"
												id="mobilePhone"
												formControlName="MobilePhone"
											></app-input>
										</div>
										<div class="col-12 col-md-4">
											<label for="businessPhone" class="mb-1"
												>Business Phone</label
											><br />
											<app-input
												validatePhoneField
												inputClassname="ui-form-control"
												id="businessPhone"
												formControlName="BusinessPhone"
											></app-input>
										</div>
										<div class="col-12 col-md-4" formGroupName="StaffSettings">
											<label for="businessName" class="mb-1"
												>Business Name</label
											><br />
											<app-input
												inputClassname="ui-form-control"
												id="businessName"
												formControlName="BusinessName"
											></app-input>
										</div>
									</div>
									<!-- Work Contact Details: Row 3 -->
									<div class="row mb-3" formGroupName="StaffSettings">
										<div class="col-12 col-md-4">
											<label for="facebookLink">Facebook Link</label><br />
											<app-input
												inputClassname="ui-form-control"
												id="facebookLink"
												formControlName="FacebookLink"
											></app-input>
										</div>
										<div class="col-12 col-md-4">
											<label for="linkedInLink">LinkedIn Link</label><br />
											<app-input
												inputClassname="ui-form-control"
												id="linkedInLink"
												formControlName="LinkedInLink"
											></app-input>
										</div>
										<div class="col-12 col-md-4">
											<label for="signOffName">Sign Off Name</label><br />
											<app-input
												inputClassname="ui-form-control"
												id="signOffName"
												formControlName="SignOffName"
											></app-input>
										</div>
									</div>

									<!-- Personal Contact Details: Row 1 -->
									<div class="row mt-4 mb-2">
										<div class="col-12 col-md-6">
											<span class="tap-text-primary"
												>Personal Contact Details</span
											>
										</div>
									</div>
									<div class="row mb-3 pl-3 pr-3">
										<div class="col-12 border-bottom"></div>
									</div>
									<div class="row mb-4">
										<div class="col-12 col-md-6" formGroupName="StaffSettings">
											<label for="personalEmailAddress" class="mb-1">
												Email Address </label
											><br />
											<app-input
												inputClassname="ui-form-control"
												id="personalEmailAddress"
												formControlName="PersonalEmailAddress"
												[isInvalid]="
													PersonalEmailAddress.invalid &&
													PersonalEmailAddress.value?.trim() !== ''
												"
											></app-input>
										</div>
										<div class="col-12 col-md-6">
											<label for="homeAddress" class="mb-1">Home Address</label
											><br />
											<app-input
												inputClassname="ui-form-control"
												id="homeAddress"
												formControlName="HomeAddress"
											></app-input>
										</div>
									</div>
									<!-- Personal Contact Details: Row 2 -->
									<div class="row mb-3" formGroupName="StaffSettings">
										<div class="col-12 col-md-4">
											<label for="personalMobilePhone" class="mb-1"
												>Mobile Phone</label
											><br />
											<app-input
												validatePhoneField
												inputClassname="ui-form-control"
												id="personalMobilePhone"
												formControlName="PersonalMobilePhone"
											></app-input>
										</div>
										<div class="col-12 col-md-4">
											<label for="homePhone" class="mb-1">Home Phone</label
											><br />
											<app-input
												validatePhoneField
												inputClassname="ui-form-control"
												id="homePhone"
												formControlName="HomePhone"
											></app-input>
										</div>
										<div class="col-12 col-md-4">&nbsp;</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-md-4">
								<!-- CRM Services: Table -->
								<app-services-list
									id="userServices"
									formControlName="Services"
									[choices]="serviceChoices"
									[hideTableHeader]="true"
									(selectEvent)="resetAdviserServices($event)"
								></app-services-list>
							</div>
						</div>
					</div>

					<!-- Emergency Contact -->
					<div class="container-fluid mt-5 mb-1">
						<div class="row">
							<div class="col-12">
								<span class="section-header tap-text-primary"
									>Emergency Contact</span
								>
							</div>
						</div>
					</div>
					<div class="container-fluid m-0">
						<div class="row m-0">
							<div class="col-12 border-bottom"></div>
						</div>
					</div>
					<div class="container-fluid mt-3 mb-5" formGroupName="StaffSettings">
						<div class="row">
							<div class="col-12 col-md-3">
								<label for="emergencyFullName" class="mb-1">Full Name</label
								><br />
								<app-input
									inputClassname="ui-form-control"
									id="emergencyFullName"
									formControlName="EmergencyFullName"
								></app-input>
							</div>
							<div class="col-12 col-md-3">
								<label for="emergencyRelationship" class="mb-1"
									>Relationship</label
								><br />
								<app-input
									inputClassname="ui-form-control"
									id="emergencyRelationship"
									formControlName="EmergencyRelationship"
								></app-input>
							</div>
							<div class="col-12 col-md-3">
								<label for="emergencyContactNumber" class="mb-1"
									>Contact Number</label
								><br />
								<app-input
									validatePhoneField
									inputClassname="ui-form-control"
									id="emergencyContactNumber"
									formControlName="EmergencyContactNumber"
								></app-input>
							</div>
							<div class="col-12 col-md-3">&nbsp;</div>
						</div>
					</div>

					<ng-container *ngIf="!isAdd">
						<app-user-qualifications
							[staff]="_data"
							[staffSettings]="staffSettings"
							[SQCKI]="SQCKI"
							[upsertStaffQualificationFn$]="upsertStaffQualificationFn$"
							[downloadDocumentFn$]="downloadDocumentFn$"
							[disable]="isSaving || isSavingOthers || isEdit"
						></app-user-qualifications>
					</ng-container>
				</form>
			</tab>
			<tab *ngIf="!isAdd" heading="Goals">
				<app-user-goals
					[data]="_data"
					[isConversionEnabled]="isConversionEnabled$ | async"
					[updateStaffGoalsFn$]="updateStaffGoalsFn$"
				></app-user-goals>
			</tab>
			<tab *ngIf="CanModifyOtherUserSettings" heading="Documents">
				<app-user-documents
					[staff]="_data"
					[isTapLevel]="isTapLevel"
					[userSecurityGroup]="userSecurityGroup"
					[staffSettings]="staffSettings"
					[upsertStaffDocumentFn$]="upsertStaffDocumentFn$"
					[removeStaffDocumentFn$]="removeStaffDocumentFn$"
					[downloadDocumentFn$]="downloadDocumentFn$"
					[getUserDocumentsFn$]="getUserDocumentsFn$"
					[deleteUserDocumentsFn$]="deleteUserDocumentsFn$"
				>
				</app-user-documents>
			</tab>
			<tab *ngIf="CanAccessPdTracking" heading="PD Tracking">
				<app-user-pd-tracking
					[staff]="_data"
					[isTapLevel]="isTapLevel"
					[staffSettings]="staffSettings"
					[userSecurityGroup]="userSecurityGroup"
					[downloadDocumentFn$]="downloadDocumentFn$"
					[loggedUserId]="loggedUserId$ | async"
					[UPDTP]="UPDTP"
					[UPDTC]="UPDTC"
					[UPDTS]="UPDTS"
					[upsertPdTrackingFn$]="upsertPdTrackingFn$"
				></app-user-pd-tracking>
			</tab>

			<tab *ngIf="CanModifyOtherUserSettings" heading="Disclosure Document">
				<app-disclosure-settings [data]="_data"></app-disclosure-settings>
			</tab>
			<tab *ngIf="CanModifyOtherUserSettings" heading="Provider & Commission">
				<app-provider-commission
					[lrp]="LRP"
					[data]="_data"
				></app-provider-commission>
			</tab>
			<ng-container *ngIf="isTapLevel">
				<tab *ngIf="!isAdd" heading="Accessibility Settings">
					<app-accessibility-settings
						[data]="_data"
						[staffChoices]="staffChoices"
						[updateAccessiblitySettingsFn$]="updateAccessiblitySettings$"
						[secGroups]="secGroups"
					></app-accessibility-settings>
				</tab>
			</ng-container>
			<ng-container *ngIf="hasCPMOAT$ | async">
				<tab *ngIf="!isAdd" heading="Client Portal">
					<app-customer-portal [data]="_data"></app-customer-portal>
				</tab>
			</ng-container>
		</tabset>
	</div>
</div>
