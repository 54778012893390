<div class="card-header font-weight-bold text-light tap-bg-primary">
  Service Calculator
</div>
<div class="card-body p-4">
  <app-service-calculator-form
    [downloadEvent$]="downloadFn$"
    (closeModalEvent)="closeModal()"
    [adviceProcessId]="adviceProcessId"
    [clientFileName]="clientFileName"
    [bankList]="bankList"
    [bankDetails]="bankDetails"
  >
  </app-service-calculator-form>
</div>
