import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { LoanRefinanceState } from './loan-refinance.model';

export interface LoanRefinanceStateModel extends EntityState<LoanRefinanceState> {
	loans: LoanRefinanceState[];
	isUpdatedLoans: boolean;
	isLoadedLoans: boolean;
}

@Injectable()
@StoreConfig({ name: 'loanRefinance', idKey: 'cRTId' })
export class LoanRefinanceStore extends EntityStore<LoanRefinanceStateModel> {
	constructor() {
		super({
			isLoadedLoans: false
		});
	}
}
