import { Injectable } from '@angular/core';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { ApiService } from '../../../../../core/base/api.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { HouseInsuranceState } from '../../../../../shared/models/client-review-template/fg-insurance/house-insurance.model';
import { CarInsuranceState } from '../../../../../shared/models/client-review-template/fg-insurance/car-insurance.model';
import { ContentInsuranceState } from '../../../../../shared/models/client-review-template/fg-insurance/content-insurance.model';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { objectUtil } from '../../../../../util/util';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { BusinessService } from 'src/app/core/business/business.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { isNil } from 'ramda';
import { ReferralOptionsModel } from 'src/app/modules/referral-options/state/referral-options.model';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { FactFindFGInsurance } from '../../../../../shared/models/client-review-template/fg-insurance/fg-insurance.model';

@Injectable()
export class FgService
	extends ClientReviewTemplateService
	implements Resolve<boolean>
{
	carInsurance$ = this.query.carInsurance$;
	houseInsurance$ = this.query.houseInsurance$;
	contentInsurance$ = this.query.contentInsurance$;
	blanketQuote$ = this.query.blanketQuote$;
	blanketOptions$ = this.query.blanketOptions$;

	constructor(
		private api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	getPolicyInfo(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<any>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => of([]))
		);
	}

	getFGData(adviceProcessId: number): Observable<FactFindFGInsurance> {
		const endpoint = `crt/fact-find/${adviceProcessId}/group/fg`;
		return this.api.get<FactFindFGInsurance>(endpoint).pipe(
			tap((result) => {
				const mapState = <T>(items: T[]): T[] =>
					items?.length ? items.map(objectUtil.mapPascalCaseToCamelCase) : [];
				const houseInsuranceInsurance = mapState(result.HouseInsurance);
				this.store.setHouseInsurance(houseInsuranceInsurance);

				const carInsurancesurance = mapState(result.CarInsurance);
				this.store.setCarInsurance(carInsurancesurance);

				const contentInsurancentInsurance = mapState(result.ContentsInsurance);
				this.store.setContentInsurance(contentInsurancentInsurance);
			})
		);
	}

	getHouseInsurance(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x ? x.map(objectUtil.mapPascalCaseToCamelCase) : [];
					this.store.setHouseInsurance(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addHouseInsurance(
		houseInsurance: HouseInsuranceState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(houseInsurance);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().houseInsurance,
						{
							...houseInsurance,
							cRTId: x,
							adviceProcessId,
						},
					] as HouseInsuranceState[];
					this.store.setHouseInsurance(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateHouseInsurance(houseInsurance: HouseInsuranceState) {
		const endpoint = `crt/${houseInsurance.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(houseInsurance);
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<HouseInsuranceState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.houseInsurance?.map((y) =>
							y.cRTId === houseInsurance.cRTId ? { ...houseInsurance } : y
						) as HouseInsuranceState[];
					this.store.setHouseInsurance(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteHouseInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.houseInsurance?.filter((y) => y.cRTId !== id);
					this.store.setHouseInsurance(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getCarInsurance(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : [];
					this.store.setCarInsurance(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addCarInsurance(carInsurance: CarInsuranceState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(carInsurance);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().carInsurance,
						{
							...carInsurance,
							cRTId: x,
							adviceProcessId,
						},
					] as CarInsuranceState[];
					this.store.setCarInsurance(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateCarInsurance(carInsurance: CarInsuranceState) {
		const endpoint = `crt/${carInsurance.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(carInsurance);
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<CarInsuranceState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.carInsurance?.map((y) =>
							y.cRTId === carInsurance.cRTId ? { ...carInsurance } : y
						) as CarInsuranceState[];
					this.store.setCarInsurance(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteCarInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.carInsurance?.filter((y) => y.cRTId !== id);
					this.store.setCarInsurance(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getContentInsurance(adviceProcessId: number, sectionCode: string) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : [];
					this.store.setContentInsurance(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addContentInsurance(
		contentInsurance: ContentInsuranceState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(contentInsurance);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().contentInsurance,
						{
							...contentInsurance,
							cRTId: x,
							adviceProcessId,
						},
					] as ContentInsuranceState[];
					this.store.setContentInsurance(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	updateContentInsurance(contentInsurance: ContentInsuranceState) {
		const endpoint = `crt/${contentInsurance.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(contentInsurance);
		body.AdviceProcessId = this.query.getValue().adviceProcessId;
		return this.api.put<ContentInsuranceState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.contentInsurance?.map((y) =>
							y.cRTId === contentInsurance.cRTId ? { ...contentInsurance } : y
						) as ContentInsuranceState[];
					this.store.setContentInsurance(data);
				})
			),
			catchError(() => EMPTY)
		);
	}

	deleteContentInsurance(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.contentInsurance?.filter((y) => y.cRTId !== id);
					this.store.setContentInsurance(data);
				});
			}),
			catchError(() => EMPTY)
		);
	}

	getBlanketQuote(referenceId: number, settingsCode: string) {
		const endpoint = `crt/settings/${referenceId}/${settingsCode}`;

		return this.api.get(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setBlanketQuote(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	getBlanketOptions(referenceId: number, settingsCode: string) {
		const endpoint = `staff/${referenceId}/settings/${settingsCode}`;

		return this.api.get<ReferralOptionsModel[]>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.store.setBlanketOptions(Object.values(state).reverse());
				})
			),
			catchError(() => of([]))
		);
	}

	resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
		const adviceProcessId = +route.paramMap.get('adviceProcessId');

		const getHouseInsurance$ = isNil(this.query.getValue().houseInsurance)
			? this.getHouseInsurance(
					adviceProcessId,
					AdviceProcessSectionCodes.HouseInsurance
			  )
			: of(undefined);

		const getCarInsurance$ = isNil(this.query.getValue().carInsurance)
			? this.getCarInsurance(
					adviceProcessId,
					AdviceProcessSectionCodes.CarInsurance
			  )
			: of(undefined);

		const getContentInsurance$ = isNil(this.query.getValue().contentInsurance)
			? this.getContentInsurance(
					adviceProcessId,
					AdviceProcessSectionCodes.ContentInsurance
			  )
			: of(undefined);

		const getBlanketQuote$ = isNil(this.query.getValue().contentInsurance)
			? this.getBlanketQuote(0, 'BQ')
			: of(undefined);

		return forkJoin([
			getHouseInsurance$,
			getCarInsurance$,
			getContentInsurance$,
			getBlanketQuote$,
		]).pipe(mapTo(true));
	}
}
