<div class="form-row align-items-center position-relative">
  <div class="col">
    <div class="position-relative">
      <input
        #searchInput
        type="text"
        (keyup.enter)="onEnter(searchInput.value)"
        (keyup.esc)="close()"
        class="global-search-input form-control"
        placeholder="Search"
        aria-label="Search"
        aria-describedby="basic-addon2"
        [(ngModel)]="keyword"
        id="globalSearchTerm"
      />
      <div class="searchResult border" *ngIf="!!(data$ | async)?.length && (isShown$ | async)">
        <div class="search-result-main">
          <a
            [routerLink]="item.link"
            *ngFor="let item of data$ | async; let last = last; let i = index"
            (click)="close()"
            class="d-flex align-items-center"
            [class.border-bottom]="!last"
            id="globalSearchResult_{{i}}"
          >
            <ng-container *ngTemplateOutlet="queryResult; context: { item: item }"> </ng-container>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="input-group-append">
    <ng-container *ngIf="!(isLoading$ | async); else spinner">
      <button class="btn btn-outline-secondary border-0 p-0" type="button" (click)="onEnter(searchInput.value)" id="globalSearchBtn">
        <i class="material-icons align-middle pb-1 px-1">&#xE8B6;</i>
      </button>
    </ng-container>
  </div>
</div>
<div class="overlay" *ngIf="!!(data$ | async)?.length && (isShown$ | async)" (click)="close()"></div>

<ng-template #queryResult let-item="item">
  <i class="material-icons md-18 d-flex align-items-center align-self-stretch px-2 border-right">
    {{ item.isService ? 'settings_applications' : item.isCompany ? 'business' : 'person' }}
  </i>
  <span class="px-2 py-1">
    <strong class="d-block">{{ item.name }}</strong>
    <span>{{ item.detail }}</span><br/>
    <span>{{ item.detail2 }}</span>
  </span>
</ng-template>

<ng-template #typeaheadItem let-model="item" let-index="index">
  <a [routerLink]="model.link">{{ model.name }}</a>
  <div class="ml-1" *ngFor="let s of model.services">{{ s.name }}: {{ s.policiesConcat }}</div>
</ng-template>

<ng-template #spinner>
  <app-manual-loader-mini class="mt-2" [isLoading]="isLoading$ | async"></app-manual-loader-mini>
</ng-template>
