<form [formGroup]='form' #sendTestEmail>
  <div class="container-fluid px-0">

    <div class="row tap-container">
      <div class="col mb-4"></div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0">
            <label for="" class="position-relative pt-0">
              Email Type
              <span class="tap-required" *ngIf="EmailType?.invalid">*</span>
            </label>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0">
            <select formControlName="emailType" (change)='selectOption($event)'
              class="form-control theme-crm-field-disabled" id="EmailType">
              <option value=""></option>
              <option *ngFor="let d of emailType" [value]="d.value">{{ d.display }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0">
            <label for="" class="position-relative pt-0">
              Recipient Name
              <span class="tap-required" *ngIf="Name?.invalid">*</span>
            </label>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0">
            <input class="form-control" type="text" id="Name" formControlName="name" />
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0">
        <div class="form-group form-inline row mb-5">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0">
            <label for="" class="position-relative pt-0">
              Recipient Email
              <span class="tap-required" *ngIf="Email?.invalid">*</span>
            </label>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0">
            <input class="form-control" type="text" id="Email" formControlName="email" />
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-3 col-md-6 col-sm-6 px-0 contentFld">
            <label for="" class="position-relative font-weight-bold">
              Content Merge Tags
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0">
            <label for="" class="position-relative pt-0">
              Customer
              <span class="tap-required" *ngIf="CustomerID?.invalid">*</span>
            </label>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0">
            <app-client-search-control searchMode="Director" formControlName="customerID">
            </app-client-search-control>
          </div>
        </div>
      </div>
    </div>

    <div class="row tap-container">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0 ">
        <div [ngSwitch]="formElem" *ngFor="let formElem of formTemplate; let i = index;">
          <ng-container *ngSwitchCase="'UserFirstName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="UserFirstName" class="pt-0">
                 		User First Name
                  <span class="tap-required" *ngIf="UserFirstName?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="UserFirstName" formControlName="userFirstName" />
              </div>
            </div>
					</ng-container>

					<ng-container *ngSwitchCase="'ClientCreateDate'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="ClientCreateDate" class="pt-0">
                  Referral Date
									<span class="tap-required" *ngIf="ClientCreateDate?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <app-date-input id="ClientCreateDate" formControlName="clientCreateDate" placeholder="dd/mm/yyyy"></app-date-input>
              </div>
            </div>
					</ng-container>

          <ng-container *ngSwitchCase="'ClientLastName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Client Last Name
                </label>
                <span class="tap-required" *ngIf="ClientLastName?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="ClientLastName" formControlName="clientLastName" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'AdviserFirstName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Adviser First Name
                </label>
                <span class="tap-required" *ngIf="AdviserFirstName?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="AdviserFirstName" formControlName="adviserFirstName" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'LRProvider'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  LRProvider
                </label>
                <span class="tap-required" *ngIf="LRProvider?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="LRProvider" formControlName="lRProvider" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'LRPolicyNumber'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  LR Policy Number
                </label>
                <span class="tap-required" *ngIf="LRPolicyNumber?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="LRPolicyNumber" formControlName="lRPolicyNumber" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ClientLink'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Client Link
                </label>
                <span class="tap-required" *ngIf="ClientLink?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="ClientLink" formControlName="clientLink" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'AllClientIndividuals'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  All Client Individuals
                </label>
                <span class="tap-required" *ngIf="AllClientIndividuals?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="AllClientIndividuals"
                  formControlName="allClientIndividuals" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'LRStatusClock'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  LR Status Clock
                </label>
                <span class="tap-required" *ngIf="LRStatusClock?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="LRStatusClock" formControlName="lRStatusClock" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ClientFileName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Client File Name
                </label>
                <span class="tap-required" *ngIf="ClientFileName?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="ClientFileName" formControlName="clientFileName" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'KProvider'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  KProvider
                </label>
                <span class="tap-required" *ngIf="KProvider?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="KProvider" formControlName="kProvider" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ClientFirstName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Client First Name
                </label>
                <span class="tap-required" *ngIf="ClientFirstName?.invalid">*</span>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="ClientFirstName" formControlName="clientFirstName" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'SignOffName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Sign Off Name
                  <span class="tap-required" *ngIf="SignOffName?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="SignOffName" formControlName="signOffName" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'KStartDate'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Start Date
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <app-date-input formControlName="kStartDate" placeholder="dd/mm/yyyy"></app-date-input>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'MProvider'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  MProvider
                  <span class="tap-required" *ngIf="MProvider?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="MProvider" formControlName="mProvider" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'FixedPeriodMonth'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Fixed Period Month
                  <span class="tap-required" *ngIf="FixedPeriodMonth?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="FixedPeriodMonth" formControlName="fixedPeriodMonth" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'FundOwnerFirstName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Fund Owner First Name
                  <span class="tap-required" *ngIf="FundOwnerFirstName?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="FundOwnerFirstName" formControlName="fundOwnerFirstName" />
              </div>
            </div>
          </ng-container>

					<ng-container *ngSwitchCase="'InvestorFirstName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Investor First Name
                  <span class="tap-required" *ngIf="InvestorFirstName?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="InvestorFirstName" formControlName="investorFirstName" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ActivityDate'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Activity Date
                  <span class="tap-required" *ngIf="ActivityDate?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <app-date-input formControlName="activityDate" placeholder="dd/mm/yyyy"></app-date-input>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'LRSubmittedDate'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  LRSubmitted Date
                  <span class="tap-required" *ngIf="LRSubmittedDate?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <app-date-input formControlName="lRSubmittedDate" placeholder="dd/mm/yyyy"></app-date-input>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'DateTime'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="" class="pt-0">
                  Date Time
                  <span class="tap-required" *ngIf="DateTime?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <app-date-input formControlName="dateTime" placeholder="dd/mm/yyyy"></app-date-input>
              </div>
            </div>
          </ng-container>

          <div class="row mb-2 text-dark" *ngSwitchCase="'ActivityTime'">
            <div class="col-lg-2 col-md-3 col-sm-3 px-0">
              <label for="" class="pt-0">
                Activity Time
                <span class="tap-required" *ngIf="ActivityTime?.invalid">*</span>
              </label>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 px-0">
              <input type="text" class="form-control" formControlName="activityTime" placeholder="1:30PM" />
            </div>
          </div>

					<ng-container *ngSwitchCase="'LeadStatus'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="LeadStatus" class="pt-0">
                 		Lead Status
                  <span class="tap-required" *ngIf="LeadStatus?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="LeadStatus" formControlName="leadStatus" />
              </div>
            </div>
					</ng-container>

					<ng-container *ngSwitchCase="'AdviserName'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="AdviserName" class="pt-0">
                 		Internal Adviser
                  <span class="tap-required" *ngIf="AdviserName?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="AdviserName" formControlName="adviserName" />
              </div>
            </div>
          </ng-container>

					<ng-container *ngSwitchCase="'LeadOrigin'">
            <div class="row mb-2 text-dark">
              <div class="col-lg-2 col-md-3 col-sm-3 px-0">
                <label for="LeadOrigin" class="pt-0">
                 		Lead Origin
                  <span class="tap-required" *ngIf="LeadOrigin?.invalid">*</span>
                </label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 px-0">
                <input class="form-control" type="text" id="LeadOrigin" formControlName="leadOrigin" />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row tap-container pt-1">
      <div class="col-lg-12 col-md-12 col-sm-12 pr-md-0 text-right">
        <div class="form-group form-inline row mb-2">
          <div class="col-lg-2 col-md-3 col-sm-3 px-0">
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 px-0 text-right">
            <span class="text-success" *ngIf="isSent">Test Email Sent</span>
            <button class="btn btn-primary mx-2" (click)="sendBtnClick()" [disabled]="isSaving">
              Send Test
              <app-manual-loader-mini *ngIf="isSaving" [isLoading]="isSaving"></app-manual-loader-mini>
            </button>
            <button class="btn btn-light" (click)="clearForm()" [disabled]="isSaving">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</form>
