<div class="app-user-container fluid-container">
  <div class="app-user-header">
    <h1 class="py-2 px-3 mb-0">
      Data Import
    </h1>
  </div>

  <div class="row tap-container service-tabs data-import">
    <div class="col">
      <tabset [appTheme]="{'color':'primarycolor'}">
        <tab heading="New Import">
          <app-new-import></app-new-import>
        </tab>
        <tab heading="Import History">
          <app-import-history></app-import-history>
        </tab>

      </tabset>
    </div>
  </div>
</div>