import { QueryEntity } from '@datorama/akita';
import { ReferralOptionsState, ReferralOptionsStore } from './referral-options.store';
import { Injectable } from '@angular/core';
import { ReferralOptionsModel } from './referral-options.model';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class ReferralOptionsQuery extends QueryEntity<ReferralOptionsState, ReferralOptionsModel> {

	referrals$ = this.select(x => x.referrals);
  blanketReferrals$ = this.select(x => x.blanketReferrals);

	constructor(protected store: ReferralOptionsStore) {
		super(store);
	}

  get referrals() {
    return this.getValue().referrals;
  }

  get activeReferrals() {
    return this.getValue().referrals?.filter((ref) => !!ref?.status);
  }

  get enabledReferrals$() {
    return combineLatest([this.referrals$]).pipe(
      map(([referrals]) => {
        const refs = referrals?.filter((ref) => !!ref?.isChecked && !!ref?.status);
        return refs.length > 0 ? refs : null
      })
    )
  }

  get activeReferrals$() {
    return combineLatest([this.referrals$]).pipe(
      map(([referrals]) => {
        return referrals?.filter((ref) => ref?.status);
      })
    )
  }
}
