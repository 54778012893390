import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
	ExistingPolicyStructureState,
	LifeAssuredListState,
	LifeAssuredViewDisplayValue,
} from '@shared/models/client-review-template/existing-policy-structure/existing-policy-structure.model';
import { ExistingPolicyStructureMapper } from '@shared/models/client-review-template/existing-policy-structure/existing-policy-structure.mapper';
import { map as Rmap, prop, uniqBy } from 'ramda';
import { Observable, Subject, of } from 'rxjs';
import {
	mergeMap,
	take,
	takeUntil,
	map,
	finalize,
	delay,
	tap,
	filter,
} from 'rxjs/operators';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { PeopleState } from '@shared/models/client-review-template/people/people.model';
import {
	AllowDropdowns,
	AppendMonths,
	DefaultNewExistingOption,
	OtherProducts,
} from '@shared/models/client-review-template/current-insurance/current-insurance.model';
import { ExistingPolicyStructureService } from '../../states/existing-policy-structure/existing-policy-structure.service';

@Component({
	selector: 'app-existing-policy-structure',
	templateUrl: './existing-policy-structure.component.html',
	styleUrls: ['./existing-policy-structure.component.scss'],
})
export class ExistingPolicyStructureComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	@Input() existingPolicies$: Observable<ExistingPolicyStructureState[]>;
	@Input() lifeAssuredDropdownRaw: ViewDisplayValue[];
	@Input() transferedSCI: PeopleState[];
	@Input() deceasedSCI: ViewDisplayValue[];
	@Input() product: ViewDisplayValue[];
	@Input() benefit: ViewDisplayValue[];
	@Input() outcomes: ViewDisplayValue[];

	form: UntypedFormGroup;
	data: ExistingPolicyStructureState[];
	lifeAssuredDropdownList: LifeAssuredViewDisplayValue[];

	allowDropdownList = AllowDropdowns;
	otherProducts = OtherProducts;
	DEFAULT_NEW_EXISTING_OPTION = DefaultNewExistingOption;
	appendMonthTo = AppendMonths;
	isEdit = false;
	viewMode = true;

	constructor(
		private fb: UntypedFormBuilder,
		private service: ExistingPolicyStructureService
	) {
		this.buildForm();
	}

	get ExistingPolicies() {
		return this.form.get('existingPolicies') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.setLifeAssuredList();
		this.setBenefitDropdown();
		this.displayExistingPolicies();

		this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
			this.setLifeAssuredList();
		});
	}

	buildForm() {
		this.form = this.fb.group({
			existingPolicies: this.fb.array([]),
		});
	}

	displayExistingPolicies() {
		this.existingPolicies$
			.pipe(
				filter((x) => !!x),
				tap((data) => {
					this.data = data;
					const existingPolicies = data
						?.map(ExistingPolicyStructureMapper.mapToView)
						?.map((policy) =>
							this.fb.group({
								...policy,
								policyOwner: [policy?.policyOwner],
								lifeAssured: this.fb.array(
									this.createLifeAssured(policy?.lifeAssured)
								),
								isEdit: false,
								isLoading: false,
							})
						);
					this.form.setControl(
						'existingPolicies',
						this.fb.array(existingPolicies)
					);
					setTimeout(() => {
						this.form.disable();
						this.isEdit = false;
					}, 100);
				}),
				take(1)
			)
			.subscribe();
	}

	createLifeAssured(lifeAssured: LifeAssuredListState[]) {
		return lifeAssured?.map((x: LifeAssuredListState) => {
			return this.fb.group({
				lifeAssured: [x?.lifeAssured, [Validators.required]],
				product: [x?.product, [Validators.required]],
				benefit: [x?.benefit, [Validators.required]],
				outcome: [x?.outcome],
				showDropdown: this.allowDropdown(x?.product),
			});
		});
	}

	setLifeAssuredList() {
		const transferred = this.transferedSCI
			? Rmap(
					(y) => ViewDisplayValue.Map(y.customerID?.toString(), `${y.name}`),
					this.transferedSCI
			  )?.map((x) => ({ ...x, hidden: true }))
			: [];
		const deceased = this.deceasedSCI?.map((x) => ({ ...x, hidden: true }));
		const lifeAssured = this.lifeAssuredDropdownRaw?.filter(
			(l) => !this.deceasedSCI?.find((x) => +x?.value === +l?.value)
		);
		this.lifeAssuredDropdownList = uniqBy(prop('value'), [
			...transferred,
			...deceased,
			...lifeAssured,
		])?.sort((a, b) => a.display?.localeCompare(b.display));
	}

	setBenefitDropdown() {
		if (this.benefit && this.benefit.length > 0) {
			const mapBenefit = this.benefit?.map((x) =>
				x.value
					? {
							display: `$ ${x.display?.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								','
							)} excess`,
							value: x.value,
					  }
					: null
			);
			this.benefit = Object.assign([], mapBenefit);
		}
	}

	allowDropdown = (product: string) =>
		product ? this.allowDropdownList?.includes(product) : false;

	changeDropdown(existingPolicyIndex: number, lifeAssureIndex: number) {
		const existingPolicy = this.ExistingPolicies.controls[existingPolicyIndex];
		const list = existingPolicy.get('lifeAssured') as UntypedFormArray;

		if (
			this.otherProducts?.includes(list.controls[lifeAssureIndex].value.product)
		) {
			const a = {
				...list.controls[lifeAssureIndex].value,
				premium: 'N/A',
			};
			list.controls[lifeAssureIndex].patchValue(a);
		} else {
			const a = {
				...list.controls[lifeAssureIndex].value,
				premium: null,
			};
			list.controls[lifeAssureIndex].patchValue(a);
		}
		if (
			this.allowDropdownList?.includes(
				list.controls[lifeAssureIndex].value.product
			)
		) {
			const a = {
				...list.controls[lifeAssureIndex].value,
				showDropdown: true,
			};
			list.controls[lifeAssureIndex].patchValue(a);
		} else {
			const a = {
				...list.controls[lifeAssureIndex].value,
				showDropdown: false,
			};
			list.controls[lifeAssureIndex].patchValue(a);
		}
	}

	edit(index: number) {
		this.enableExistingPolicy(index, true);
	}

	save(index: number) {
		this.setLoading(index, true);
		const data = this.ExistingPolicies.controls[index];

		of(data?.value)
			.pipe(
				map(ExistingPolicyStructureMapper.mapToUpsert),
				mergeMap((x) => this.service.update(x)),
				delay(100),
				finalize(() => {
					this.displayExistingPolicies();
				}),
				take(1)
			)
			.subscribe();
	}

	remove(index: number) {
		this.setLoading(index, true);
		const data = this.ExistingPolicies.controls[index];

		of(data?.value)
			.pipe(
				mergeMap((x) => this.service.delete(+x?.cRTId)),
				delay(100),
				finalize(() => {
					this.displayExistingPolicies();
				}),
				take(1)
			)
			.subscribe();
	}

	enableExistingPolicy(index: number, status: boolean) {
		if (status) {
			this.ExistingPolicies.controls[index].enable();
		} else {
			this.ExistingPolicies.controls[index].disable();
		}
		this.isEdit = status;
		this.ExistingPolicies.controls[index].get('isEdit').setValue(status);
	}

	setLoading(index: number, status: boolean) {
		this.ExistingPolicies.controls[index].get('isLoading').setValue(status);
	}

	cancelEdit(index: number, cRTId: number) {
		this.displayExistingPolicies();
	}

	showMonth(value: string) {
		return this.appendMonthTo?.includes(value);
	}

	track(item: any, index: number) {
		return index;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
