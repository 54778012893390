import { CurrencyMaskInputMode } from 'ngx-currency';

/*
 *
 **** Documentation Links:
 * https://www.npmjs.com/package/ngx-currency
 * https://github.com/nbfontana/ngx-currency
 *
 **** Usage:
 * For reactive forms:
 * <input
 *	formControlName="amount"
 *	currencyMask
 * />
 *
 * For non-reactive/custom forms:
 * <input
 *	[(ngModel)]="amount"
 *	[ngModelOptions]="{ standalone: true }"
 *	currencyMask
 * />
 *
 */

// Check documentation for config values
export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};
