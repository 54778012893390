import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { KiwiSaver } from '../../../crt-mortgage/client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.model';
import { KiwisaverState } from 'src/app/shared/models/services/kiwisaver/kiwisaver.model';

@Component({
	selector: 'app-crt-kiwisaver-template',
	templateUrl: './crt-kiwisaver-template.component.html',
})
export class CrtKiwisaverTemplateComponent implements OnInit {
	@Input() kiwiSavers: KiwiSaver[];
	@Input() lifeAssuredList$: Observable<ViewDisplayValue[]>;
	@Input() KP$: Observable<ViewDisplayValue[]>;
	@Input() KFT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTRP$: Observable<ViewDisplayValue[]>;
	@Input() crmKiwiSavers: KiwisaverState[] = [];
	@Input() crmKiwiSaversChoinces: ViewDisplayValue[] = [];
	@Input() transferedSCIList: any[] = [];
	@Input() adviceProcessId: number;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	isAdd: boolean;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor() {}

	ngOnInit(): void {}

	trackByFn(index: number, ks: KiwiSaver): number {
		return ks.cRTId;
	}

	add() {
		this.isAdd = true;
	}

	cancelEvent(event?) {
		this.isAdd = false;
	}

	saveEvent(event?) {
		this.isAdd = false;
	}
}
