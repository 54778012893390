import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { staticConf } from '@core/config/static-config.service';
import { LoggerService } from '@core/logger/logger.service';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { DropdownValueService } from '@domain/dropdown-value/dropdown-value.service';
import { InputChipsComponent } from '@shared/components/input-chips/input-chips.component';
import { InputChipsModule } from '@shared/components/input-chips/input-chips.module';
import { InputDateModule } from '@shared/components/input-date/input-date.module';
import { InputRadioGroupComponent } from '@shared/components/input-radio-group/input-radio-group.component';
import { InputSelectModule } from '@shared/components/input-select/input-select.module';
import { InputModule } from '@shared/components/input/input.module';
import { logMessage } from '@shared/error-message/error-message';
import { FinalStructureState } from '@shared/models/client-review-template/final-structure/final-structure.model';
import { ProposedInsuranceState } from '@shared/models/client-review-template/proposed-insurance/proposed-insrurance.model';
import { AppFormGroup } from '@util/form-group';
import MomentUtil from '@util/moment.util';
import { Moment } from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { delay, finalize, map, startWith, take } from 'rxjs/operators';

@Component({
	selector: 'app-confirmation-call-send-issue-modal',
	templateUrl: './confirmation-call-send-issue-modal.component.html',
	styleUrls: ['./confirmation-call-send-issue-modal.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		InputRadioGroupComponent,
		InputModule,
		InputChipsModule,
		InputDateModule,
		InputSelectModule,
	],
})
export class ConfirmationCallSendIssueModalComponent {
	@ViewChild('providerChips') providerChips: InputChipsComponent<any>;

	APCRTF$ = this.dropdownValueQuery.orderedChoices$('APCRTF');
	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');

	clientsInvolved$: Observable<{ value: any; text: string; mobile: any }[]>;

	finalStructure: {
		finalStructure: FinalStructureState;
		insurance: ProposedInsuranceState[];
	};

	adviceProcessId = 0;

	sendCall$: (data: any) => Observable<any>;

	modalSubject: Subject<any>;

	private subscriptions = new Subscription();

	formGroup = new AppFormGroup({
		adviceProcessId: new FormControl(null),
		type: new FormControl(null, [Validators.required]),
		providers: new FormControl(null, [Validators.required]),
		frequency: new FormControl(null, [Validators.required]),
		providerSection: this.fb.array([]),
		clientToCall: new FormControl(null, [Validators.required]),
		mobile: new FormControl(null, [Validators.required]),
		notes: new FormControl(null),
	});

	get clientToCall(): AbstractControl {
		return this.formGroup.controls.clientToCall;
	}

	get mobile(): AbstractControl {
		return this.formGroup.controls.mobile;
	}

	isSending = false;

	get providerSection(): FormArray {
		return this.formGroup.controls.providerSection as FormArray;
	}

	get providers(): AbstractControl {
		return this.formGroup.controls.providers as FormArray;
	}

	get frequency(): AbstractControl {
		return this.formGroup.controls.frequency;
	}

	typeOfIssues = [
		{
			text: 'Full issue',
			value: 'FI',
		},
		{
			text: 'Partial issue',
			value: 'PI',
		},
	];

	constructor(
		private fb: FormBuilder,
		protected dropdownValueQuery: DropdownValueQuery,
		private dropdownValueService: DropdownValueService,
		private loggerService: LoggerService
	) {}

	onProviderAdded(e: { display: string; value: string }): void {
		const today = MomentUtil.convertToMoment(
			new Date(),
			staticConf.displayDateFormat
		);
		this.pushProviderList({
			provider: e.value,
			startDate: today,
			sendToIssueDate: today,
		});
	}

	onProviderRemoved(e: any): void {
		const removedProviderIndex = this.providerSection.controls.findIndex(
			(p) => p.value.provider === e.value.value
		);
		if (removedProviderIndex !== -1) {
			this.providerSection.removeAt(removedProviderIndex);
		}
	}

	ngOnInit(): void {
		this.formGroup.patchValue({
			adviceProcessId: this.adviceProcessId.toString(),
			frequency: this.finalStructure?.finalStructure?.paymentFrequency,
		});

		const valueChanges$ = this.providers.valueChanges
			.pipe(startWith(this.providers.value), delay(200))
			.subscribe((value) => {
				// @ts-ignore-next
				if (!this.providerChips?.value.length) {
					AppFormGroup.clearFormArray(this.providerSection);
				}
			});
		this.subscriptions.add(valueChanges$);

		// this.dropdownValueService
		// 	.getDropdownValueList$(['APCRTF', 'LRP'])
		// 	.pipe(take(1))
		// 	.subscribe();

		// this.APCRTF$.pipe(delay(500), take(1)).subscribe(() => {
		// 	this.frequency.setValue(
		// 		this.finalStructure.finalStructure.paymentFrequency
		// 	);
		// });

		this.prepopulateProvider();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	cancel(): void {
		this.modalSubject.next(false);
	}

	sendRequest(): void {
		if (this.isSending) {
			return;
		}
		if (this.formGroup.invalid) {
			this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
			return;
		}
		this.isSending = true;
		this.sendCall$(this.getValueToSave())
			.pipe(
				take(1),
				finalize(() => (this.isSending = false))
			)
			.subscribe(() => this.modalSubject.next(true));
	}

	getValueToSave(): any {
		const value = this.formGroup.getRawValue();
		value.providerSection = value.providerSection.map((p) => {
			p.sendToIssueDate = MomentUtil.formatToServerDate(
				MomentUtil.convertToMoment(p.sendToIssueDate)
			);
			p.startDate = MomentUtil.formatToServerDate(
				MomentUtil.convertToMoment(p.startDate)
			);
			p.firstPaymentDate = MomentUtil.formatToServerDate(
				MomentUtil.convertToMoment(p.firstPaymentDate)
			);
			return p;
		});
		return value;
	}

	onClientToCallValueChanged(value: any): void {
		this.clientsInvolved$
			.pipe(map((result) => result.find((c) => c.value == value)))
			.subscribe((res) => {
				if (res) {
					this.mobile.patchValue(res.mobile);
				}
			});
	}

	dateValueParser = (date: Moment) => {
		return MomentUtil.formatToDisplayDate(date);
	};

	private pushProviderList(data: {
		provider: string;
		sendToIssueDate?: any;
		startDate?: any;
		firstPaymentDate?: any;
	}): void {
		this.providerSection.push(
			this.fb.group({
				provider: new FormControl(data.provider),
				sendToIssueDate: new FormControl(data.sendToIssueDate, [
					Validators.required,
				]),
				startDate: new FormControl(data.startDate, [Validators.required]),
				firstPaymentDate: new FormControl(data.firstPaymentDate, [
					Validators.required,
				]),
			})
		);
	}

	private prepopulateProvider(): void {
		const providers = this.finalStructure?.insurance
			?.map((i) => i.provider)
			?.filter((p) => Boolean(p));
		this.providers.patchValue(providers);
		const today = MomentUtil.convertToMoment(
			new Date(),
			staticConf.displayDateFormat
		);
		providers.forEach((provider) => {
			this.pushProviderList({
				provider: provider,
				startDate: today,
				sendToIssueDate: today,
			});
		});
	}

	// private prepopulateFrequencies(): void {
	// 	const providers = this.finalStructure?.insurance?.map((i) => i.provider);
	// 	this.providers.patchValue(providers);
	// 	const today = MomentUtil.convertToMoment(
	// 		new Date(),
	// 		staticConf.displayDateFormat
	// 	);
	// 	this.pushProviderList({
	// 		provider: e.value,
	// 		startDate: today,
	// 		sendToIssueDate: today,
	// 	});
	// }
}
