<form [formGroup]="form" [class.loatv2]="loatv2Enabled" [class.submitted]="submitted">
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Number Plate
			</label>
		</div>
		<div class="col-12 col-lg-4 mb-2 mb-lg-0">
			<input
				id="numberPlateId"
				class="form-control crt-form-control"
				formControlName="numberPlate"
				type="text"
			/>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold"> Model </label>
		</div>
		<div class="col-12 col-lg-4">
			<input
				id="modelId"
				class="form-control crt-form-control"
				formControlName="model"
				type="text"
			/>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Current Provider
			</label>
		</div>
		<div class="col-12 col-lg-4 mb-2 mb-lg-0">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectCurrentProviderId"
					formControlName="provider"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of currentProvider$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Current Premium
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<div class="dollar-icon">
				<input
					id="premiumId"
					class="form-control crt-form-control"
					formControlName="premium"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Last Reviewed
			</label>
		</div>
		<div class="col-12 col-lg-4 not-required mb-2 mb-lg-0">
			<app-date-input
				class="crt-form-control-field"
				textboxId="dateLastReviewedId"
				formControlName="lastReviewed"
				placeholder="dd/mm/yyyy"
				[attr.disabled]="viewMode"
			></app-date-input>
		</div>
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Current Excess
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<div class="dollar-icon">
				<input
					id="currentExcessId"
					class="form-control crt-form-control"
					formControlName="currentExcess"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-2 text-left">
			<label for="" class="position-relative font-weight-bold">
				Sum Insured
			</label>
		</div>
		<div class="col-12 col-lg-4">
			<div class="dollar-icon">
				<input
					id="sumInsuredId"
					class="form-control crt-form-control"
					formControlName="sumInsured"
					type="text"
					currencyMask
					appCurrencyMaskExt
				/>
				<i>$</i>
			</div>
		</div>
	</div>

	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				Claimed In Last 12 months?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectClaimIn12MonthsId"
					class="form-control crt-form-control"
					formControlName="hasClaimedInLast12Months"
					(change)="selectChange()"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>

	<ng-container *ngIf="showClaimInLast12Months">
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold">
					Driver
				</label>
			</div>
			<div class="col-12 col-lg-4">
				<input
					id="claimDriverId"
					class="form-control crt-form-control"
					formControlName="driverName"
					type="text"
				/>
			</div>
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold">
					What happened
				</label>
			</div>
			<div class="col-12 col-lg-4">
				<ng-container *ngIf="WhatHappened?.value === 'Other'; else whatHappendDropdown">
					<input
						id="whatHappenedOtherId"
						class="form-control crt-form-control"
						formControlName="whatHappenedOther"
						type="text"
						placeholder="Other"
					/>
				</ng-container>
				<ng-template #whatHappendDropdown>
					<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
						<select
							id="selectWhatHappenedId"
							formControlName="whatHappened"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value=""></option>
							<option *ngFor="let d of APCRTCLC$ | async" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</span>
				</ng-template>
			</div>
		</div>
		<div class="form-row align-items-center mb-2">
			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold"> Date </label>
			</div>
			<div class="col-12 col-lg-4 not-required">
				<app-date-input
					class="crt-form-control-field"
					id="claimDateId"
					formControlName="date"
					placeholder="dd/mm/yyyy"
					[attr.disabled]="viewMode"
				></app-date-input>
			</div>

			<div class="col-12 col-lg-2 text-left">
				<label for="" class="position-relative font-weight-bold"> Cost </label>
			</div>
			<div class="col-12 col-lg-4">
				<div class="dollar-icon">
					<input
						id="claimCostId"
						class="form-control crt-form-control"
						formControlName="cost"
						type="text"
						placeholder="Amount"
						currencyMask
						appCurrencyMaskExt
					/>
					<i>$</i>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				Does the car have a turbo?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectHasCarHaveTurboId"
					formControlName="hasCarHaveTurbo"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				Where is the vehicle parked?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectVehicleParkedId"
					formControlName="vehicleParked"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of APCRTVP$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				Has the vehicle been modified?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectHasVehicleBeenModifiedId"
					formControlName="hasVehicleBeenModified"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center mb-2">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				KM's driver per year?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectKilometerPerYearId"
					formControlName="kilometerPerYear"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option value=""></option>
					<option *ngFor="let d of APCRTKPY$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>
	<div class="form-row align-items-center">
		<div class="col-12 col-lg-4 text-left">
			<label for="" class="position-relative font-weight-bold">
				Excluded drivers under 25 years old?
			</label>
		</div>
		<div class="col-12 col-lg-2">
			<span class="w-100" [class.crt-custom-dropdown]="!viewMode">
				<select
					id="selectHasExcludeDriversUnder25YearsOldId"
					formControlName="hasExcludeDriversUnder25YearsOld"
					class="form-control theme-crm-field-disabled crt-form-control"
				>
					<option *ngFor="let d of APCRTYNNA$ | async" [value]="d.value">
						{{ d.display }}
					</option>
				</select>
			</span>
		</div>
	</div>

	<div class="form-buttons">
		<ng-container *ngIf="viewMode else upsertMode">
			<button
				id="closeCarPolicyBtn"
				type="button"
				class="btn-light btn-block mb-lg-0"
				(click)="cancelClick()"
			>
				Close
			</button>
		</ng-container>
		<ng-template #upsertMode>
			<button
				id="saveCarPolicyBtn"
				type="button"
				class="btn btn-primary tap-bg-primary text-light btn-block"
				(click)="save()"
				[disabled]="loading"
			>
				Save
				<i *ngIf="loading" class="fas fa-spinner fa-pulse"></i>
			</button>
			<button
				id="cancelCarPolicyBtn"
				type="button"
				class="btn-light btn-block mb-lg-0"
				(click)="cancelClick()"
				[disabled]="loading"
			>
				Cancel
			</button>
		</ng-template>
	</div>
</form>
