import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	Renderer2,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { sort, map as Rmap, addIndex, flatten, forEach } from 'ramda';
import { iif, Observable, Observer, of, Subject } from 'rxjs';
import {
	combineLatest,
	concatMap,
	debounceTime,
	exhaustMap,
	filter,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	removeCustomNotes,
	removeHTMLContentWhiteSpaces,
	removeMtWrappers,
	removeSoaDivs,
} from 'src/app/shared/converter/content-merge-tags';
import {
	AdviceProcessPageCodes,
	ATPSubSectionCodes,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { StatementOfAdviceState } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.model';
import { SignatureTypes } from 'src/app/shared/models/documents/document.model';
import { CurrentInsuranceModalComponent } from '../../../../shared/modal/crt/fact-find/current-insurance/current-insurance-modal.component';
import { SignatureModalComponent } from '../../../../shared/modal/signature-modal/signature-modal.component';
import { ClientAcceptanceMapper } from '../../../../shared/models/client-review-template/client-acceptance/client-acceptance.mapper';
import { CurrentInsuranceMapper } from '../../../../shared/models/client-review-template/current-insurance/current-insurance.mapper';
import {
	CurrentInsuranceState,
	LifeAssuredListState,
} from '../../../../shared/models/client-review-template/current-insurance/current-insurance.model';
import { SignatureState } from '../../../../shared/models/client-review-template/declaration/declaration.model';
import { PeopleState } from '../../../../shared/models/client-review-template/people/people.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../../../util/moment.util';
import { ClientAcceptanceService } from '../states/client-acceptance/client-acceptance.service';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';
import { LoatDocumentService } from '../states/document/loat-document.service';
import { StatementOfAdviceQuery } from '../states/statement-of-advice/statement-of-advice.query';
import { CrtMergeTagsService } from '../states/merge-tags/crt-merge-tags.service';
import { ScopeOfServiceService } from '../states/scope-of-service/scope-of-service.service';
import { StatementOfAdviceService } from '../states/statement-of-advice/statement-of-advice.service';
import { ProductsWithPerMonth } from 'src/app/shared/models/client-review-template/statement-of-advice/statement-of-advice.mapper';
import { CrtDocumentService } from '../../crt-page/_shared/service/crt-document.service';
import { ALLOWED_DOCUMENT_FILE_TYPES } from 'src/app/shared/modal/upload-modal/upload-modal.component';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';

@Component({
	selector: 'app-client-acceptance',
	templateUrl: './client-acceptance.component.html',
	styleUrls: ['./client-acceptance.component.scss'],
})
export class ClientAcceptanceComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();
	transferedSCI: PeopleState[];

	clientAcceptance$ = this.query.clientAcceptance$;
	clientAcceptanceWording$ = this.query.clientAcceptanceSettings$.pipe(
		map((x) => x?.clientAcceptanceWording),
		withLatestFrom(this.query.people$),
		map(([content, peopleCount]) =>
			content?.replace(/i\/we/gi, peopleCount?.length > 1 ? 'we' : 'I')
		)
	);
	isClientAcceptance$ = this.query.clientAcceptance$.pipe(map((x) => !!x));
	soa$ = this.soaQuery.finalizedSOA$;
	isSignatureImage$ = this.query.caSignature$.pipe(map((x) => !!x));
	signatureImage$ = this.query.caSignature$.pipe(map((x) => x.DocumentLink));
	proposedInsurance$ = this.query.caAlternativeStructure$.pipe(
		map((x) =>
			sort(
				(a, b) => a.priority - b.priority,
				(x ?? []).map((pi) => {
					const lifeAssured = pi?.lifeAssured?.map((la) => ({
						...la,
						allowDropdown: this.allowDropdown(la?.product),
						lifeAssuredName: this.convertLifeAssuredToName(la?.lifeAssured),
						showPerMonth: this.showPerMonth(la?.product),
					}));
					return {
						...pi,
						lifeAssured,
						lifeAssuredList: lifeAssured,
						sumOfLifeAssured: this.getSumOfLifeAssuredList(pi),
					};
				})
			)
		)
	);
	APCRTF$ = this.service.APCRTF$;
	APCRTB$ = this.service.APCRTB$;
	APCRTNE$ = this.service.APCRTNE$;
	LRP$ = this.service.LRP$;
	LRPR$ = this.service.LRPR$;
	people$ = this.service.peopleEntities$.pipe(
		map(people => people?.filter(person => +person.value)
	));

	lifeAssured$ = this.query.peopleFromCrmAndCrtExceptChildChoices$;
	currentInsuranceLifeAssured$ = this.query.currentInsurances$.pipe(
		map((x) => Rmap((l) => l?.lifeAssuredList ?? [], x)),
		map((x) => flatten(x)),
		map((x) => {
			const indexedForEach = addIndex(forEach);
			return indexedForEach(
				(lifeAssured: LifeAssuredListState, index) => (lifeAssured.id = index),
				x
			);
		})
	);

	form: UntypedFormGroup = this.fb.group({
		cRTId: [null],
		sOA: ['', [Validators.required]],
		presentedSOA: ['', [Validators.required]],
		alternativeStructure: [null, [Validators.required]],
		signatureDocument: [null],
		documentId: [null],

		signatures: this.fb.array([]),
	});
	public bsModalRef: BsModalRef;

	isEditSignature = false;
	isSavingWhichSOA = false;
	lifeAssuredList: ViewDisplayValue[] = [];

	isLoading$ = this.query.isLoadingClientAcceptance$;

	bsModalRefSign: BsModalRef;

	constructor(
		private fb: UntypedFormBuilder,
		private service: ClientAcceptanceService,
		private query: ClientReviewTemplateQuery,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private soaQuery: StatementOfAdviceQuery,
		private renderer: Renderer2,
		private loatDocumentService: LoatDocumentService,
		private mtService: CrtMergeTagsService,
		private sosService: ScopeOfServiceService,
		private soaService: StatementOfAdviceService,
		private crtDocService: CrtDocumentService
	) {}

	get SOA() {
		return this.form.get('sOA');
	}

	get PresentedSOA() {
		return this.form.get('presentedSOA');
	}

	get Signatures() {
		return this.form.get('signatures') as UntypedFormArray;
	}

	trackByFn(_index: number, item: StatementOfAdviceState) {
		return item?.cRTId;
	}

	ngOnInit(): void {
		this.query.transferedSCIList$
			.pipe(
				tap((x) => (this.transferedSCI = x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.service.lifeAssured$
			.pipe(take(1))
			.subscribe((x) => (this.lifeAssuredList = x));

		this.prepareData(false);

		// Set form value on every changes
		this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this.service.setFormValue(this.prepareFormValue());
		});
	}

	ngAfterViewInit(): void {}

	prepareData(isOnloadPage = true) {
		this.clientAcceptance$
			.pipe(
				filter((x) => !!x),
				combineLatest(this.service.people$),
				take(1)
			)
			.subscribe(([clientAcceptance, people]) => {
				this.form.reset();
				this.clearFormArray(this.Signatures);

				if (!!clientAcceptance) {
					this.form.reset(ClientAcceptanceMapper.mapToView(clientAcceptance));
					this.isEditSignature = false;
				}

				if (!!people) {
					people?.forEach((p) => {
						this.Signatures?.push(
							this.patchSignature(
								p,
								(clientAcceptance?.signatures ?? [])?.find(
									(x) => +x.referenceID === +p.cRTId
								)
							)
						);
					});
				}

				if (!!clientAcceptance && clientAcceptance?.signatures?.length > 0) {
					this.Signatures.controls?.forEach((s, i) => {
						this.getSignature(
							+s.get('secondaryReferenceID')?.value,
							i,
							isOnloadPage
						);
					});
				}

				// const patchTimer = setTimeout(() => {
				// 	this.service.setFormValue(this.prepareFormValue());
				// 	this.service.setHasFormChanges(false);
				// 	if (patchTimer) {
				// 		clearTimeout(patchTimer);
				// 	}
				// }, 100);
			});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
		// tslint:disable-next-line: semicolon
	};

	patchSignature(p?: PeopleState, s?: SignatureState) {
		return this.fb.group({
			referenceID: [p?.cRTId],
			secondaryReferenceID: [
				!!s?.secondaryReferenceID ? s?.secondaryReferenceID : null,
			],
			fullName: [{ value: p?.name, disabled: true }],
			date: [
				!!s?.dateValue
					? MomentUtil.formatDateToMoment(s?.dateValue)
					: MomentUtil.momentNowNz(),
			],
			signature: [''],
		});
	}

	/**
	 * Get the document signature and
	 * assigning the signature on specific for
	 * @param documentId signature dodument id
	 * @param i index of form
	 * @returns document object for the signaure
	 */
	getSignature(documentId: number, i: number, emitEvent: boolean) {
		if (!documentId) {
			if (!emitEvent) {
				this.service.setFormValue(this.prepareFormValue());
				this.service.setHasFormChanges(false);
			}
			return;
		}

		this.service
			.getSignature(+documentId)
			.pipe(take(1))
			.subscribe((data) => {
				this.Signatures.controls[i]
					.get('signature')
					.setValue(data?.DocumentLink, { emitEvent });

				if (!emitEvent) {
					this.service.setFormValue(this.prepareFormValue());
					this.service.setHasFormChanges(false);
				}
			});
	}

	/**
	 * Save Document for Declaration
	 * @param document Document to be uploaded
	 */
	saveSignatureDocument(document: string, name: string) {
		const request = {
			ReferenceId: this.query.getValue()?.declaration?.cRTId,
			Document: document,
			FileName: `${name ?? ' Client'}-Signature.png`,
			Type: SignatureTypes.AuthorityToProceed,
		};
		return this.service.saveDocument(request);
	}

	saveSignature(signature: string, index: number) {
		const name = this.Signatures.controls[index].get('value')?.value;
		return of(signature).pipe(
			concatMap((x) =>
				iif(
					() => !!x,
					this.saveSignatureDocument(x?.replace(/^data:(.*,)?/, ''), name),
					of(x)
				)
			),
			tap((x) => {
				this.Signatures.controls[index]?.get('secondaryReferenceID').setValue(x);
				this.Signatures.controls[index]?.get('signature').setValue('');
			}),
			concatMap(() =>
				this.service.updateClientAcceptance(this.prepareFormValue())
			),
			tap(() => this.prepareData()),
			take(1)
		);
	}

	/**
	 * Signing the form and openning the popup modal where to sign
	 * @param index Indexed of the form signature
	 */
	sign(index: number) {
		const saveFn$ = (signature: string) =>
			new Observable((obs) => {
				obs.next(signature as string);
				obs.complete();
			}).pipe(mergeMap((x: string) => this.saveSignature(x, index)));

		const initialState: any = {
			subHeader: 'Draw Signature',
			saveFn$,
		};

		this.bsModalRefSign = this.modalService.show(SignatureModalComponent, {
			initialState,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-dialog',
		});
	}

	onChangePresentedSOA(event: Event) {
		if (!(event.target as HTMLSelectElement).value) {
			return;
		}
		this.save(event);
	}

	onChangeSOA(event: Event) {
		if (!(event.target as HTMLSelectElement).value) {
			return;
		}
		this.form.get('presentedSOA').reset();
	}

	save(event: Event) {
		of((event.target as HTMLSelectElement).value)
			.pipe(
				tap(() => (this.isSavingWhichSOA = true)),
				withLatestFrom(this.isClientAcceptance$),
				map(([, x]) => x),
				mergeMap((x) =>
					iif(
						() => x,
						this.service.updateClientAcceptance(this.prepareFormValue()),
						this.service.addClientAcceptance(this.prepareFormValue())
					).pipe(
						map(() => +this.query.getValue().clientAcceptance.sOA),
						tap(() => (this.isSavingWhichSOA = false))
					)
				),
				debounceTime(100),
				concatMap((x) => this.saveSOADocument(x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	convertLifeAssuredToName(id: number) {
		const name = CurrentInsuranceMapper.getLifeAssuredName(
			id,
			this.lifeAssuredList,
			this.transferedSCI
		);

		if (!name) {
			return (
				this.query
					.getValue()
					.allSecondaryClients?.filter((x) => +x.customerID === +id)
					?.map((sci) => sci.firstName.concat(' ', sci.lastName)) ?? ''
			);
		}

		return name || '';
	}

	addCurrentInsurance = (model: CurrentInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next({
				...model,
				sectionCode: ATPSubSectionCodes.ProposedInsurance,
				parentCRTId: this.form.value?.cRTId,
			});
			obs.complete();
		}).pipe(
			switchMap((x) =>
				this.service.addCurrentInsurance(
					x,
					+this.route.snapshot.paramMap.get('adviceProcessId')
				)
			),
			switchMap((crtId) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.service.uploadDocument(model, crtId, +this.route.snapshot.paramMap.get('clientId')).pipe(map(() => crtId))
					: of(crtId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const currentInsurances =
						this.query.getValue().caAlternativeStructure;
					const m = currentInsurances.find((ci) => ci.cRTId === crtId);
					return this.service.updateCurrentInsurance(m);
				}
				return of(crtId);
			}),
			map((x) => ({...model, cRTId: +x}))
		);

	updateCurrentInsurance = (model: CurrentInsuranceState) =>
		new Observable<any>((obs) => {
			obs.next({
				...model,
				sectionCode: ATPSubSectionCodes.ProposedInsurance,
				parentCRTId: this.form.value?.cRTId,
			});
			obs.complete();
		}).pipe(
			mergeMap((x) => this.service.updateCurrentInsurance(x)),
			switchMap((crtId) =>
				model.policyDocumentsName && model.policyDocumentsName.length > 0
					? this.service.uploadDocument(model, crtId, +this.route.snapshot.paramMap.get('clientId')).pipe(map(() => crtId))
					: of(crtId)
			),
			exhaustMap((crtId) => {
				if (model.policyDocumentsName && model.policyDocumentsName.length > 0) {
					const currentInsurances =
						this.query.getValue().caAlternativeStructure;
					const m = currentInsurances.find((ci) => ci.cRTId === model.cRTId);
					return this.service.updateCurrentInsurance(m);
				}
				return of(crtId);
			}),
			map(() => model)
		);

	addProduct() {
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Proposed Insurance',
			message: `Proposed Insurance`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			getOwnerChoices: this.service.getOwnerChoices,
			currentInsuranceLifeAssured$: this.currentInsuranceLifeAssured$,
			isProposedInsurance: true,
			savefn: this.addCurrentInsurance,
			decline$: decline,
			isSingleUpload: true,
      restrictFileTypes: ALLOWED_DOCUMENT_FILE_TYPES
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	editProduct(insurance: CurrentInsuranceState) {
		const decline = new Observable((obs: Observer<any>) => obs.complete());
		const initState = {
			header: 'Proposed Insurance',
			message: `Proposed Insurance`,
			apcrtf$: this.APCRTF$,
			apcrtb$: this.APCRTB$,
			apcrtne$: this.APCRTNE$,
			lrp$: this.LRP$,
			lrpr$: this.LRPR$,
			policyOwners$: this.people$,
			lifeAssuredList$: this.lifeAssured$,
			getOwnerChoices: this.service.getOwnerChoices,
			currentInsuranceInfo: insurance,
			currentInsuranceLifeAssured$: this.currentInsuranceLifeAssured$,
			isProposedInsurance: true,
			savefn: this.updateCurrentInsurance,
			decline$: decline,
			isSingleUpload: true,
		};
		this.bsModalRef = this.modalService.show(CurrentInsuranceModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-xl modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	deleteCurrentInsurance(id: number) {
		this.service.deleteCurrentInsurance(id).pipe(take(1)).subscribe();
	}

	prepareFormValue() {
		const data = this.form.getRawValue();
		return {
			...data,
			sOA: +data?.sOA,
			presentedSOA:
				data.presentedSOA === 'Presented SOA'
					? +data?.sOA
					: data.presentedSOA === 'Alternative Structure'
					? 0
					: null,
			alternativeStructure: 0,
			signatures: data?.signatures?.map((y) => ({
				referenceID: y?.referenceID,
				secondaryReferenceID: !!y?.secondaryReferenceID
					? y?.secondaryReferenceID
					: 0,
				value: y?.fullName,
				dateValue: y?.date ? MomentUtil.formatDateToServerDate(y?.date) : null,
				signature: y?.signature,
			})),
		};
	}

	addClientAcceptance() {
		this.service
			.addClientAcceptance(this.prepareFormValue())
			.pipe(take(1))
			.subscribe();
	}

	updateClientAcceptance() {
		this.service
			.updateClientAcceptance(this.prepareFormValue())
			.pipe(take(1))
			.subscribe();
	}

	getSumOfLifeAssuredList(lifeAssured: CurrentInsuranceState) {
		return (
			lifeAssured?.lifeAssured?.reduce(
				(a, b) => Number(a) + Number(b.premium),
				0
			) +
			(!lifeAssured?.policyFee || lifeAssured?.policyFee === 'N/A'
				? 0
				: +lifeAssured?.policyFee)
		);
	}

	allowDropdown = (product) =>
		product
			? [
					'Medical Base Plan',
					'Specialists & Tests',
					'Medical',
					'Medical + S&T',
					'Dental & Optical'
			  ].includes(product)
			: false;

	showPerMonth = (product) => ProductsWithPerMonth.includes(product);

	downloadLink(id) {
		this.service
			.downloadLink(id)
			.pipe(
				tap((x) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
				}),
				take(1)
			)
			.subscribe();
	}

	saveSOADocument(soaId: number) {
		return this.soa$.pipe(
			map((x) => x.find((soa) => soa.cRTId === soaId)),
			filter((x) => !!x),
			concatMap((x: StatementOfAdviceState) =>
				this.getDocumentToPdf(
					x.document?.referenceId,
					x.document?.value?.replace(/(.*)\.[^.]+$/, '')
				).pipe(
					map((file) => ({
						file,
						name: x.name.concat('.pdf'),
					}))
				)
			),
			filter((x) => !!x),
			concatMap((x) =>
				this.crtDocService.saveDocument({
					ReferenceId: this.query.getValue().adviceProcessId,
					CustomerId: this.query.getValue().primaryClient?.customerID,
					Document: x.file,
					FileName: x.name,
					DocumentType: ServicesCodes.LR,
					Type: AdviceProcessPageCodes.SOA,
				})
			),
			filter((x) => !!x),
			concatMap((x) =>
				this.loatDocumentService.updateAdviceProcess(
					x,
					AdviceProcessPageCodes.SOA
				)
			),
			take(1)
		);
	}

	getDocumentToPdf(documentId: number, documentName?: string) {
		return this.crtDocService.getDocument(documentId).pipe(
			concatMap((x) =>
				this.crtDocService.getDocumentLink(x.DocumentLink, {
					responseType: 'text',
				})
			),
			switchMap((x) => this.cleanContentForDownload(x)),
			debounceTime(100),
			map((x) => `<div class="soa-pdf-file">${x}</div>`),
			concatMap((x) =>
				this.soaService.convertSoaTemplateToBase64Pdf(x, `${documentName}.pdf`)
			)
		);
	}

	cleanContentForDownload = (content = '') =>
		// Use this only when converting final contents of SOA to PDF
		of(content).pipe(
			concatMap((x) => this.updateSoaSosMt(x)),
			concatMap((x) => this.updateSoaSosPages(x)),
			map((x) => removeSoaDivs(x)),
			map((x) => removeCustomNotes(x)),
			map((x) => removeMtWrappers(x)),
			map((x) => removeHTMLContentWhiteSpaces(x, true)),
			take(1)
		);

	updateSoaSosMt = (content = '') =>
		of(true).pipe(
			withLatestFrom(
				this.mtService.mergeTags$,
				this.sosService.scopeOfService$,
				this.sosService.sosDefault$
			),
			switchMap(([, mergeTags, sosCrt, sosDefault]) =>
				this.soaService.updateSosMt(content, mergeTags, sosCrt, sosDefault)
			),
			take(1)
		);

	updateSoaSosPages = (content = '') =>
		of(true).pipe(
			withLatestFrom(
				this.sosService.scopeOfService$,
				this.sosService.sosDefault$
			),
			switchMap(([, sosCrt, sosDefault]) =>
				this.soaService.updateSosPages(content, sosCrt, sosDefault)
			),
			take(1)
		);

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
