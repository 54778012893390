<div class="document-page-bg clearfix py-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto"></div>
    </div>
    <div class="row text-right">
      <div class="col px-0 mr-0"></div>
    </div>
    <div class="row service-tabs document-tabs">
      <div class="px-0 mr-0 col">
        <tabset class="tap-tabset tap-tabset--border-radius-left-right">
          <tab *appRestrictTo="['FAMOD']" customClass="m-0" heading="Office Documents" (selectTab)="onSelect($event)" [id]="documentTypeCodes.Office">
            <app-tab-form
              [dataList]="odDocuments$ | async"
              [uploadDocFn$]="uploadDocFn$"
              [downloadLink$]="downloadLink$"
              (deleteEvent)="delete($event)"
              (transferEvent)="transfer($event)"
              [types]="types"
              [activeDocType]="documentTypeCodes.Office"
            ></app-tab-form>
          </tab>
          <tab *appRestrictTo="['FAMAD']" customClass="m-0" heading="Adviser Documents" (selectTab)="onSelect($event)" [id]="documentTypeCodes.Adviser">
            <app-tab-form
              [dataList]="adDocuments$ | async"
              [uploadDocFn$]="uploadDocFn$"
              [downloadLink$]="downloadLink$"
              (deleteEvent)="delete($event)"
              (transferEvent)="transfer($event)"
              [types]="types"
              [activeDocType]="documentTypeCodes.Adviser"
            ></app-tab-form>
          </tab>
          <tab *appRestrictTo="['FAMAD']" customClass="m-0" heading="FAP Documents" (selectTab)="onSelect($event)" [id]="documentTypeCodes.FAP">
            <app-tab-form
              [dataList]="fapDocuments$ | async"
              [uploadDocFn$]="uploadDocFn$"
              [downloadLink$]="downloadLink$"
              (deleteEvent)="delete($event)"
              (transferEvent)="transfer($event)"
              [types]="types"
              [activeDocType]="documentTypeCodes.FAP"
            ></app-tab-form>
          </tab>
          <tab *appRestrictTo="['FAMXD']" customClass="m-0" heading="Other Documents" (selectTab)="onSelect($event)" [id]="documentTypeCodes.Other">
            <app-tab-form
              [dataList]="xdDocuments$ | async"
              [uploadDocFn$]="uploadDocFn$"
              [downloadLink$]="downloadLink$"
              (deleteEvent)="delete($event)"
              (transferEvent)="transfer($event)"
              [types]="types"
              [activeDocType]="documentTypeCodes.Other"
            ></app-tab-form>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
