<div class="container-fluid">
  <app-email-status-form [emailType]="emailType$ | async" [emailStatus]="emailStatus$ | async" [activeRow]="activeRow" 
    [emailModeList]="emailModeList$" [resizeChange]="resizeChange" [getReport]="getReport" (valueChange)="acceptValueChange($event)"></app-email-status-form>

  <div class="col pt-5 pl-0 text-dark" *ngIf="isShowTable">
    <div class="row">
      <div class="col-4 col-sm-4 text-left">
        Total: {{ (tableResult$ | async).total }} &nbsp; Success: {{ (tableResult$ | async).success }} &nbsp; Failed: {{ (tableResult$ | async).failed }}
      </div>
      <div class="col-4 col-sm-4">
      </div>
      <div class="col-4 col-sm-4 text-right pr-0">
        {{  minRange }} - {{ maxRange }} of {{ (tableResult$ | async).total }}
      </div>
    </div>
  </div>

  <div class="col pt-2 px-0" *ngIf="isShowTable">
    <ngx-datatable #mydatatable 
    class="bootstrap" 
    [rows]="rows$ | async" 
    [columns]="columnsWithLink$ | async" 
    [columnMode]="'standard'"
    [headerHeight]="33" 
    [footerHeight]="50" 
    [rowHeight]="40" 
    [rowClass]="getRowClass" 
    (resize)="resize($event)"
    [scrollbarH]="true"
    
    [externalSorting]="true"
    (sort)="onSort($event)"
    
    [externalPaging]="true"
    [count]="page.totalElements"

    [limit]="page.size"
    >
    <ngx-datatable-column *ngFor="let c of columns$ | async" [prop]="c.prop" [width]="c.width" [resizeable]="true"
        [name]="c.name">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

          <ng-container [ngSwitch]="c.controlType">
            <ng-container *ngSwitchCase="'display'">
              <ng-container>
                <app-display [value]="value">
                </app-display>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'display-datetime'">
              <ng-container>
                <app-display [value]="value | momentDatetime: true">
                </app-display>
              </ng-container>
            </ng-container>
          </ng-container>

        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [prop]="linkColumn.prop" [name]="linkColumn.name" [width]="linkColumn.width"
        [resizeable]="false" headerClass="link-header">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *appRestrictTo="['BETR']">
            <ng-container *ngIf="!(rowsLoading$ | async)[row.EmailSettingsId]">
              <div class="d-flex justify-content-around align-items-center pt-2">
                <button class="tap-btn tap-btn--default" (click)='resend(row.EmailSettingsId)'>
                  Resend
                </button>
              </div>
            </ng-container>
            <app-manual-loader-mini class="d-flex clearfix align-items-center justify-content-center mt-2"
              *ngIf="(rowsLoading$ | async)[row.EmailSettingsId]"
              [isLoading]="(rowsLoading$ | async)[row.EmailSettingsId]"></app-manual-loader-mini>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
          let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
          <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]=" curPage"
            [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
            (change)="onFooterPage($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>

    </ngx-datatable>

  </div>

</div>