import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { ClientReviewTemplateQuery } from '../states/client-review-template.query';

@Injectable({
	providedIn: 'root',
})
export class AdviceProcessEndedGuard implements CanActivate {
	constructor(
		private router: Router,
		private api: ApiService,
		private query: ClientReviewTemplateQuery
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const code = next.paramMap.get('companyCode');
		const customerType = next.paramMap.get('customerType');
		const clientId = next.paramMap.get('clientId');
		const adviceProcessId = next.paramMap.get('adviceProcessId');
		const returnUrl = `${code}/crt/${customerType}/${clientId}/advice-process/${adviceProcessId}/fact-find/people`;

		return this.api.get<any>(`adviceprocesses/${adviceProcessId}`).pipe(
			// return this.query.adviceProcess$.pipe(
			map((x) => !(x?.status > 2 && x?.status < 5)),
			tap((x) => {
				if (!x) {
					this.router.navigateByUrl(returnUrl);
				}
			})
		);
	}
}
