import { GoalTrack } from './lrgtfy.model';
import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import { Injectable } from "@angular/core";

/**
 * LR Goal Track widget state model
 */
export interface LRGTFYState {
  /**
   * LR Goal Track data
   */
  GoalTrack: GoalTrack;
  /**
   * error message. will show up when not empty
   */
  error: string;
  /**
   * indicator if widget is currently fetching
   */
  isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): LRGTFYState {
  return {
    GoalTrack: {
      QuarterActual: 0,
      QuarterExpected: 0,
      YearActual: 0,
      YearExpected: 0,
      Quarter1: 0,
      Quarter2: 0,
      Quarter3: 0,
      Quarter4: 0
    },
    error: '',
    isLoading: false
  };
}

/**
 * LR Goal track widget data store
 */
@Injectable()
@StoreConfig({
  name: 'lrGoalTrackFinancialYear'
})
export class LRGTFYStore extends Store<LRGTFYState> {
  /**
   * LR Goal track store
   */
  constructor() {
    super(createInitialState());
  }

  /**
   * Set if widget is loading or not
   * @param isLoading if widget is loading
   */
  setLoading(isLoading: boolean): void {
    this.update(
      produce(draft => {
        draft.isLoading = isLoading;
      })
    );
  }
}
