import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { MortgageMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/mortgage/mortgage.mapper';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { MortgageInfo } from '../../../../../models/client-review-template/assets-liabilities/mortgage/mortgage.model';
import { logMessage } from '../../../../../error-message/error-message';
import { computeDateFromTodayInYears } from 'src/app/modules/crm/crt-page/_shared/calculations/fact-find';
import * as R from 'ramda';

@Component({
	selector: 'app-moat-mortgages-form',
	templateUrl: './mortgages-form.html',
	styleUrls: ['./mortgages-form.scss'],
})
export class MOATMortgagesFormComponent implements OnInit {
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Input() loanType: ViewDisplayValue[];
	@Input() lender: ViewDisplayValue[];
	@Input() mortgageFrequency: ViewDisplayValue[];
	@Input() borrowers: ViewDisplayValue[];
	@Input() securities: ViewDisplayValue[];
	@Input() mortgageInfo: MortgageInfo;
	@Input() isLoading: boolean;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;
	isLoadingBtn = false;

	cancelLoading: boolean;

	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	constructor(
		private fb: UntypedFormBuilder,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.prepareData();
	}

	prepareData() {
		this.form.reset(MortgageMapper.mapToView(this.mortgageInfo));
		this.computeRepayRemainingYears();
	}

	get LoanValue() {
		return this.form.get('loanValue');
	}
	get ActualRepayment() {
		return this.form.get('actualRepayment');
	}
	get MortgageFrequency() {
		return this.form.get('mortgageFrequency');
	}
	get LoanType() {
		return this.form.get('loanType');
	}
	get Purpose() {
		return this.form.get('purpose');
	}
	get Lender() {
		return this.form.get('lender');
	}
	get LoanRepaymentDate() {
		return this.form.get('loanRepaymentDate');
	}
	get LoanRepaymentRemainingYears() {
		return this.form.get('loanRepaymentRemainingYears');
	}

	buildForm() {
		this.form = this.fb.group({
			lender: ['', Validators.required],
			borrowingEntities: [''],
			security: [''],
			loanValue: ['', [Validators.required, Validators.min(0)]],
			actualRepayment: ['', Validators.required],
			mortgageFrequency: ['Fortnightly', Validators.required],
			interestRate: [''],
			loanType: ['', Validators.required],
			fixedPeriodEnd: [''],
			loanTerm: [''],
			purpose: ['', Validators.required],
			loanLimit: [''],
			loanRepaymentDate: [''],
			loanRepaymentRemainingYears: [0]
		});
	}

	save() {
    if (this.isLoading || this.cancelLoading) {
      return;
    }
    this.submitted = true;
    this.isLoadingBtn = true;
    let warningMessage = '';
    if (this.form.invalid) {
      if(this.LoanValue.hasError('min')) {
        warningMessage = `${logMessage.oat.shared.factFind.assetsLiabilities.mortgages.warning.minimum} `;
      }
      if (
				this.Lender.hasError('required') ||
				this.MortgageFrequency.hasError('required') ||
				this.LoanValue.hasError('required') ||
				this.LoanType.hasError('required') ||
				this.Purpose.hasError('required') ||
				this.ActualRepayment.hasError('required')
			) {
        warningMessage = warningMessage.concat(logMessage.shared.general.warning.required)
      }
      this.loggerService.Warning(null, warningMessage);
      this.isLoadingBtn = false;
      return;
    }
    const value = R.omit(['loanRepaymentRemainingYears'], this.form.getRawValue());
    this.saveEvent.emit(
      !!this.mortgageInfo
        ? {
            ...value,
            cRTId: this.mortgageInfo.CRTId,
            customerServiceID: this.mortgageInfo.CustomerServiceID,
          }
        : value
    );
    this.isLoadingBtn = false;
	}

	computeRepayRemainingYears() {
		const value = computeDateFromTodayInYears(this.LoanRepaymentDate?.value) || 0;
		this.LoanRepaymentRemainingYears.setValue(value);
	}

	cancelClick() {
		this.cancelLoading = true;
		this.cancelEvent.emit(true);
		setTimeout(() => this.cancelLoading = false, 500);
	}

	policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.borrowers);
	}
}
