import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ViewDisplayValue } from '@models/_general/display-value.viewmodel';
import { ServicingCalculatorState } from '@modules/tl-moat-settings/servicing-calculators/state/servicing-calculators-settings.model'

@Component({
	selector: 'app-service-calculator-modal',
	templateUrl: './service-calculator-modal.component.html',
})
export class ServiceCalculatorModalComponent
	implements OnInit, ConfirmModalModel
{
	header: string;
	message: string;
	secondaryMessage: string;

	adviceProcessId: number;
	clientFileName: string;
	downloadFn$: (model: any) => Observable<any>;

	form: UntypedFormGroup;
	submitted = false;

	bankList: ViewDisplayValue[];
	bankDetails: ServicingCalculatorState[];

	constructor(
		public bsModalRef: BsModalRef,
		private bsModalService: BsModalService
	) {}

	ngOnInit(): void {}

	closeModal() {
		this.bsModalRef.hide();
		setTimeout(() => this.bsModalService.hide(1), 500);
	}
}

export interface ConfirmModalModel {
	decline$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
