import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { ClientReferralSettingReferral } from '@modules/special-features/client-referral-setting/state/client-referral-setting.model';
import { logMessage } from '@shared/error-message/error-message';
import { AppFormGroup } from '@util/form-group';
import {
	BehaviorSubject,
	Observable,
	Subject,
	delay,
	finalize,
	map,
	take,
} from 'rxjs';

@Component({
	selector: 'app-refer-client-popup',
	templateUrl: './refer-client-popup.component.html',
	styleUrls: ['./refer-client-popup.component.scss'],
})
export class ReferClientPopupComponent implements OnInit {
	isLoading = true;

	subject: Subject<ClientReferralSettingReferral>;

	getReferrals: () => Observable<ClientReferralSettingReferral[]>;

	referrals$ = new BehaviorSubject<ClientReferralSettingReferral[]>([]);

	formGroup = new AppFormGroup({
		service: new FormControl(null, [Validators.required]),
	});

	get service(): AbstractControl {
		return this.formGroup.controls.service;
	}

	constructor(private loggerService: LoggerService) {}

	ngOnInit(): void {
		this.getReferrals()
			.pipe(
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((items) => this.referrals$.next(items));
	}

	close(): void {
		this.subject.next(null);
	}

	refer(): void {
		if (this.formGroup.invalid) {
			return this.loggerService.Warning(
				null,
				logMessage.shared.general.warning.required
			);
		}
		this.referrals$
			.pipe(
				take(1),
				map((items) => {
					const settingsId = +this.service.value;
					return items.find((r) => r.settingsId === settingsId);
				})
			)
			.subscribe((referral) => this.subject.next(referral));
	}
}
