/** Sec Group match
 * @param a
 * @param b
 * @returns {number}
 */
export const secGroupMatch = (regExp: RegExp, str: string): boolean => {
	const match = str.match(regExp);
	return match && str === match[0];
};

/** Sec Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const sorter = (a: string, b: string, type: string): number => {
	const matchA = secGroupMatch(new RegExp(type), a ? a.toLowerCase() : '');
	const matchB = secGroupMatch(new RegExp(type), b ? b.toLowerCase() : '');

	if ((matchA)
		< (matchB)) {
		return 1;
	} else if ((matchA)
		> (matchB)) {
		return -1;
	} else {
		return 0;
	}
};

/** Sec Group group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const groupSorter = (a: string, b: string): number => {
	return sorter(a, b, 'business owner') ||
		sorter(a, b, 'business manager') ||
		sorter(a, b, 'business admin - view') ||
		sorter(a, b, 'business admin - internal') ||
		sorter(a, b, 'business admin') ||
		sorter(a, b, 'adviser manager') ||
		sorter(a, b, 'adviser') ||
		sorter(a, b, 'lead generator') ||
		sorter(a, b, 'lead generator - internal');
};


/** Main Group sorter
 * @param a
 * @param b
 * @returns {number}
 */
export const mainSecGroupSorter = (a, b): number => {
	return groupSorter(a.securityGroupName, b.securityGroupName);
};
