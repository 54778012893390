import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
	ManageUsersListState,
	ManageUsersListStore,
} from './manage-users-list.store';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ManageUsersListQuery extends QueryEntity<ManageUsersListState> {
	isLoading$ = this.selectLoading();

	constructor(protected store: ManageUsersListStore) {
		super(store);
	}

	getDataByRange(
		startIndex: number,
		endIndex: number
	): Observable<ManageUsersListState[] | null> {
		const rangeCached =
			this.getValue().rangeIDs?.[`${startIndex}-${startIndex + endIndex}`];
		if (!rangeCached) {
			return of(null);
		}
		// @ts-ignore-next
		return this.selectMany(rangeCached);
	}
}
