<div class="simple-data-table">
	<div class="row m-0 p-0 simple-data-table__table">
		<table
			#matTable
			mat-table
			[dataSource]="listPerPage"
			class="ui-tbl table table-striped w-100 border-0"
			[class.disabled]="!!isLoading || isLoadingData"
		>
			<ng-container *ngFor="let col of tableColumns">
				<ng-container [matColumnDef]="col.columnId">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="header-title pl-0 font-weight-bold text-truncate px-2 py-2"
						[class]="col.headerCellClass"
						[width]="col.width"
					>
						{{ col?.columnName }}
					</th>
					<td
						mat-cell
						*matCellDef="let element"
						class="pl-0 text-truncate px-2"
						[class]="col.bodyCellClass"
					>
						<!-- Type: Text -->
						<ng-container *ngIf="col.type === 'text'">
							<span
								#cellText
								class="tbl-data tbl-data-text text-truncate"
								[tooltip]="!!col.showToolTip && !!cellText && isEllipsisActive(cellText) ? (element?.[col.columnId] || '') : ''"
								[class.d-block]="col.showToolTip"
								[class]="
									col.hasOwnClass
										? element.class + ' ' + col.columnId
										: col.columnId
								"
							>
								{{ element?.[col.columnId] || '' }}
							</span>
						</ng-container>

						<!-- Type: Moment Date -->
						<ng-container *ngIf="col.type === 'moment'">
							<span
								class="tbl-data tbl-data-moment"
								[class]="
									col.hasOwnClass
										? element.class + ' ' + col.columnId
										: col.columnId
								"
								>{{ element?.[col.columnId] | momentDatetime }}</span
							>
						</ng-container>

						<!-- Type: Conditional -->
						<ng-container *ngIf="col.type === 'conditional'">
							<span
								class="tbl-data tbl-data-conditional"
								[class]="
									col.hasOwnClass
										? element.class + ' Conditional'
										: col.columnId
								"
								>{{ element?.Conditional }}</span
							>
						</ng-container>

						<!-- Type: File Link -->
						<ng-container *ngIf="col.type === 'fileLink'">
							<span
								#cellText
								class="tbl-data tbl-data-text text-truncate clickable"
								[tooltip]="!!col.showToolTip && !!cellText && isEllipsisActive(cellText) ? (element?.[col.columnId] || '') : ''"
								[class.d-block]="col.showToolTip"
								[class]="
									col.hasOwnClass
										? element.class + ' ' + col.columnId
										: col.columnId
								"
								(click)="viewFile(element)"
							>
								{{ element?.[col.columnId] || ''}}
							</span>
						</ng-container>

						<!-- Type: Action -->
						<ng-container *ngIf="col?.type === 'action'">
							<span
								class="tbl-data tbl-data-action clickable"
								[class]="
									col.hasOwnClass
										? element.class + ' ' + col.columnId
										: col.columnId
								"
								(click)="
									!!col.actionCallback ? !!col.actionCallback(element) : null
								"
								>{{ col?.actionCallbackText || "" }}</span
							>
							<ng-container
								*ngIf="
									col?.actionCallbackTextTwo &&
									col.actionCallbackTwoCondition &&
									col.actionCallbackTwoCondition(element)
								"
							>
								<span
									class="tbl-data tbl-data-action clickable pl-3"
									[class]="
										col.hasOwnClass
											? element.class + ' ' + col.columnId
											: col.columnId
									"
									(click)="
										!!col.actionCallbackTwo
											? !!col.actionCallbackTwo(element)
											: null
									"
									>{{ col?.actionCallbackTextTwo || "" }}</span
								>
							</ng-container>
						</ng-container>

						<!-- Type: Tooltip -->
						<ng-container *ngIf="col?.type === 'tooltip'">
							<ng-template #dataTooltipGroup>
								<div
									class="form-row flex-column"
									style="min-width: 360px; max-height: 160px"
								>
									<div
										*ngFor="let item of element?.[col.columnId]?.data"
										class="col-6 pb-1"
									>
										{{ item }}
									</div>
								</div>
							</ng-template>
							<div class="d-flex align-items-center popover-content">
								<span
									#cellText
									class="tbl-data tbl-data-text text-truncate"
									[class]="
										col.hasOwnClass
											? element.class + ' ' + col.columnId
											: col.columnId
									"
									>{{ element?.[col.columnId]?.display || "" }}
								</span>
								<span
									#cellText
									class="material-icons hover-icon m-0 px-1"
									containerClass="data-tooltip"
									[tooltip]="element?.[col.columnId]?.data?.length ? dataTooltipGroup : null"
									[adaptivePosition]="false"
									placement="left"
									container="body"
									>visibility</span
								>
							</div>
						</ng-container>

						<!-- Type: Popover -->
						<ng-container *ngIf="col?.type === 'popover'">
							<ng-template #popTableTemplate>
								<div class="popover-table-info">
									<div class="list-container">
										<div
											class="list-item"
											*ngFor="let item of element?.[col.columnId]?.data"
										>
											{{ item }}
										</div>
									</div>
								</div>
							</ng-template>
							<div class="d-flex align-items-center popover-content">
								<div class="item info">
									<span
										#cellText
										class="tbl-data tbl-data-text text-truncate"
										[class]="
											col.hasOwnClass
												? element.class + ' ' + col.columnId
												: col.columnId
										"
										>{{ element?.[col.columnId]?.display || "" }}
									</span>
								</div>
								<div class="item icon">
									<button
										class="popover-btn d-flex"
										[ngClass]="{ 'no-item': !!!element?.[col.columnId]?.data?.length }"
										[placement]="'left'"
										[popover]="element?.[col.columnId]?.data?.length ? popTableTemplate : null"
										containerClass="popover-info-container"
										[triggers]="element?.[col.columnId]?.data?.length <= 18 ? 'mouseenter:mouseleave' : 'focus'"
										[outsideClick]="element?.[col.columnId]?.data?.length > 18 ? true : false"
									>
										<span #cellText class="material-icons hover-icon m-0"
											>visibility</span
										>
									</button>
								</div>
							</div>
						</ng-container>
					</td>
				</ng-container>
			</ng-container>

			<tr class="mat-row" *matNoDataRow>
				<td
					class="mat-cell text-center"
					[attr.colspan]="displayedColumns.length"
				>
					<em>No data to display</em>
				</td>
			</tr>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</div>
	<div
		class="row m-0 p-0 simple-data-table__pagination"
		*ngIf="!!enablePagination"
	>
		<ng-container *ngIf="!!dataSource && dataSource?.length > itemPerPage">
			<app-simple-paginator
				(pageChangedEvent)="setPage($event)"
				[pageIndex]="currentPage"
				[recordCount]="dataSource?.length || 0"
			>
			</app-simple-paginator>
		</ng-container>
	</div>
</div>
