import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { DomEventService } from '../../../../../core/dom-event/dom-event.service';
import { Subject, Observable, combineLatest } from 'rxjs';
import { merge, map, startWith, shareReplay, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BusinessConfigQuery } from '../../../../../domain/business-config/business-config.query';
import { staticConf } from '../../../../../core/config/static-config.service';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';

declare var $: any;
/**
 * Model for gridstack data that might be used
 * for shrinking consideration.
 * Not used for now. Remove next revisit.
 */
type GridStackAttr = {
  gsHeight: number;
  gsWidth: number;
};
/** Pipeline widget component.
 * Contains LR, Mortgage, Kiwisaver stats table.
 */
@Component({
  selector: 'app-p',
  templateUrl: './p.component.html',
  styleUrls: ['./p.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PComponent implements OnDestroy {
  public static widgetCode = 'p';
  public static widgetName = 'Pipelines';
  public static sizeX = 365;
  public static sizeY = 375;
  public static minSizeX = 365;
  public static minSizeY = 375;

  widgetCode = PComponent.widgetCode;

  /** destroy subjects. triggers all disposing in component. */
  private readonly onDestroy$ = new Subject<void>();

  /** indicates whether layout should be shrank
   * for cases where widget window is small.
   * Instantiated at `ngAfterViewInit` because it watches elements.
   */
  shrinkMode$: Observable<boolean> = this.observeGridStackAttr$().pipe(
    merge(this.domEventService.windowResize$),
    startWith({}),
    map(() => this.getIfShrink()),
    shareReplay(1),
  );

  /**
   * Currently active tab. used in template.
   *
   * Change to available service
   */
  activeTab = this.userQuery.isTapLevel()
    ? this.businessConfigQuery.getFirstServiceCode()
    : this.service.getFirstServiceCode();

  hasLR$ = this.service.hasLR$;
  hasM$ = this.service.hasM$;
  hasI$ = this.service.hasI$;
  hasK$ = this.service.hasK$;
  hasFG$ = this.service.hasFG$;

  constructor(
    private domEventService: DomEventService,
    private el: ElementRef,
    private businessConfigQuery: BusinessConfigQuery,
    private userQuery: UserQuery,
    private service: ClientProfileService,
  ) {}

  /** trigger `onDestroy$` to dispose everything that listens to it. */
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  setTabToLr() {
    this.activeTab = staticConf.lrServiceCode;
  }
  setTabToM() {
    this.activeTab = staticConf.mortgageServiceCode;
  }
  setTabToK() {
    this.activeTab = staticConf.kiwisaverServiceCode;
  }
  setTabToI() {
    this.activeTab = staticConf.investmentServiceCode;
  }
  setTabToFG() {
    this.activeTab = staticConf.fgServiceCode;
  }

  isTabToLr() {
    return this.activeTab === staticConf.lrServiceCode;
  }
  isTabToM() {
    return this.activeTab === staticConf.mortgageServiceCode;
  }
  isTabToK() {
    return this.activeTab === staticConf.kiwisaverServiceCode;
  }
  isTabToI() {
    return this.activeTab === staticConf.investmentServiceCode;
  }
  isTabToFG() {
    return this.activeTab === staticConf.fgServiceCode;
  }

  /** observe gridstack attributes of p component.
   * used to resize stuff accordingly.
   */
  private observeGridStackAttr$() {
    return new Observable<GridStackAttr>(obs => {
      const observer = new MutationObserver(records => {
        if (records.length < 1) {
          return;
        }
        const attributes: GridStackAttr = {
          gsHeight: $(this.el.nativeElement).data('gs-height'),
          gsWidth: $(this.el.nativeElement).data('gs-height')
        };
        obs.next(attributes);
      });
      observer.observe(this.el.nativeElement, {
        attributes: true,
        attributeFilter: ['data-gs-width', 'data-gs-height']
      });
      this.onDestroy$.subscribe(() => observer.disconnect());
    });
  }

  /** get size of component and figure out
   * if view should be in shrink mode
   */
  private getIfShrink(): boolean {
    const actualWidth = $(this.el.nativeElement).actual('outerWidth');
    return actualWidth < 400;
  }
}
