import { Injectable } from '@angular/core';
import { iif, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { PDGoalsSettingsState, PDGoalsSettingsType } from './pd-goals.model';
import { ApiService } from '@core/base/api.service';
import { objectUtil } from '@util/util';
import { PDGoalsSettingsStore } from './pd-goals.store';
import { isNil, omit } from 'ramda';

@Injectable({ providedIn: 'root' })
export class PDGoalsSettingsService {
	constructor(private api: ApiService, private store: PDGoalsSettingsStore) {}

	get(referenceId: number) {

		const endpoint = `staff/${referenceId}/settings/${PDGoalsSettingsType.PDGoals}`;

		this.store.setPDGoalsSettingsLoading(true);

		return this.api.get<PDGoalsSettingsState>(endpoint).pipe(
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			tap((x) => {
				if (x && x[0]) {
					this.store.setPDGoalsSettings(x[0]);
				}
				this.store.setPDGoalsSettingsLoading(false);
			}),
			catchError(() => {
				this.store.setPDGoalsSettingsLoading(false);
				return of(undefined);
			})
		);
	}

	updateSettings(settingsId: number, payload: any) {
		return of(payload).pipe(
			concatMap(() =>
				iif(() => isNil(settingsId), this.post(payload), this.put(payload))
			),
			catchError((error) => throwError(error))
		);
	}

	post(payload: any) {
		const endpoint = `staff/settings`;
		const newPayload = omit(['SettingsId'], payload);
		return this.api
			.post(endpoint, newPayload)
			.pipe(catchError(() => of(undefined)));
	}

	put(payload: any) {
		const endpoint = `staff/settings/${payload.SettingsId}`;
		return this.api
			.put(endpoint, payload)
			.pipe(catchError(() => of(undefined)));
	}
}
