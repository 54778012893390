import { defaultCrmProdConfigs } from './environment.default';

export const environment = {
	...defaultCrmProdConfigs,
	appVersion: defaultCrmProdConfigs.appVersion + '-dev',
	// apiUrl: 'tapnzdevapi.azurewebsites.net',
	// cpUrl: 'tapnzdevapi.azurewebsites.net',
	apiUrl: 'tapnzcpdevapi.azurewebsites.net',
	cpUrl: 'tapnzcpdevapi.azurewebsites.net',
	cpSiteUrl: 'https://hub-dev.myapp.co.nz',
	datadogRum: {
		...defaultCrmProdConfigs.datadogRum,
		env: 'env:dev',
	},
	defaultImgUrl: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz',
	defaultLoginLogo: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz/logo.png',
	defaultLoginBackground: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz/login-bg.png',
};
