import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StructureSoaSecurityStore, StructureSoaSecurityState } from './structure-soa-security.store';

@Injectable({ providedIn: 'root' })
export class StructureSoaSecurityQuery extends QueryEntity<StructureSoaSecurityState> {
	constructor(protected store: StructureSoaSecurityStore) {
		super(store);
	}
}
