import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import produce from 'immer';
import * as moment from 'moment';
import * as R from 'ramda';
import { concat, forkJoin, from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mergeMap,
  reduce,
  retry,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { ApiService, JsonResultStatus } from '../../../../../core/base/api.service';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { BLStaff } from '../../../../../domain/bl-staff/bl-staff.model';
import { BLStaffsQuery } from '../../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '../../../../../domain/user/user.query';
import { UserStore } from '../../../../../domain/user/user.store';
import { ActivityViewModel } from '../../../../../shared/models/_general/activity.viewmodel';
import { StaffSettings } from '../../../../../shared/models/_general/staff-settings.model';
import MomentUtil from '../../../../../util/moment.util';
import { columns, createRowFromPrototype, Metakey } from '../mortgage-datatable.config';
import { MortgageRequest, MortgageResponse } from '../mortgage-request.model';
import { MortgageQuery } from './mortgage.query';
import { MortgageStore } from './mortgage.store';

@Injectable()
export class MortgageService {
  // Get Active and (Paused and Inactive A and AM)
  public readonly staffList$: Observable<BLStaff[]> = this.blStaffsQuery.availableStaffs$.pipe(
    map(x => R.filter(y => y.SecurityGroup === 'BO' || y.SecurityGroup === 'AM' || y.SecurityGroup === 'A', x))
  );
  public readonly MP$ = this.dropdownValueQuery.orderedChoices$('MP'); // Provider
  public readonly MS$ = this.dropdownValueQuery.orderedChoices$('MS'); // Status
  public readonly PCLE$ = this.dropdownValueQuery.orderedChoices$('PCLE'); // Lead Origin
  public readonly MRS$ = this.dropdownValueQuery.orderedChoices$('MRS'); // Refix status
  public readonly PCLT$ = this.dropdownValueQuery.orderedChoices$('PCLT'); // Lead Type

  constructor(
    protected api: ApiService,
    private dropdownValueQuery: DropdownValueQuery,
    private blStaffsQuery: BLStaffsQuery,
    private mortgageStore: MortgageStore,
    private mortgageQuery: MortgageQuery,
    private userQuery: UserQuery,
    private userStore: UserStore,
    private activityService: ActivityService,
    private loggerService: LoggerService
  ) { }

  clear(): void {
    applyTransaction(() => {
      this.mortgageStore.reset();
      this.mortgageStore.uiStore.reset();
    });
  }

  openPopup = () => this.mortgageStore.uiStore.toggleColumnPopup(true);
  closePopup = () => this.mortgageStore.uiStore.toggleColumnPopup(false);
  togglePopup = () =>
    this.mortgageStore.uiStore.toggleColumnPopup(!this.mortgageQuery.uiQuery.getValue().columnFormPopupOpen);

  search2(req: MortgageRequest) {
    this.mortgageStore.uiStore.setSort(null, null);
    const batchLength = 100;

    const getIndexesToFetch: (res: MortgageResponse) => number[] = R.pipe(
      (res: MortgageResponse) => Math.ceil(res.TotalCount / batchLength - 1),
      (totalPages: number) =>
        Array(totalPages)
          ?.fill(1)
          ?.map((x, i) => i + 1)
          ?.slice(1)
    );
    const searchRequest = (request: MortgageRequest) => this.api.post3<MortgageResponse>('search/services/m', request);

    const firstPage$ = of(req).pipe(
      map(
        produce(draft => {
          draft.Paging = {
            Index: 2,
            Column: req.Paging.Column,
            Direction: req.Paging.Direction,
          };
        })
      ),
      mergeMap(searchRequest),
      shareReplay()
    );

    return firstPage$.pipe(
      mergeMap(res =>
        concat(
          firstPage$,
          from(getIndexesToFetch(res)).pipe(
            map(i =>
              produce(req, draft => {
                draft.Paging = {
                  Index: i + 1,
                  Column: req.Paging.Column,
                  Direction: req.Paging.Direction,
                };
              })
            ),
            concatMap(req2 => searchRequest(req2))
          )
        )
      ),
      reduce((acc, v) =>
        produce(acc, draft => {
          draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
          draft.IsComplete = true;
        })
      ),
      map(res =>
        applyTransaction(() => {
          return res;
        })
      )
    );
  }

  search(req: MortgageRequest) {
    this.mortgageStore.uiStore.setIsSearching(true);

    return this.api.post3<MortgageResponse>('search/services/m', req).pipe(
      withLatestFrom(this.mortgageQuery.templateRow$),
      switchMap(([x, templateRow]) => {
        if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
          // Saves initial fetch
          const rows: any[] = R.map(createRowFromPrototype(templateRow), x.SearchResults);
          this.mortgageStore.set([]);
          this.mortgageStore.set([...rows]);
          return this.search2(req);
        }
        return of(x);
      }),
      withLatestFrom(this.mortgageQuery.templateRow$, (res, template) => ({
        response: res,
        templateRow: template,
      })),
      map(res =>
        applyTransaction(() => {
          const rows: any[] = R.map(createRowFromPrototype(res.templateRow), res.response.SearchResults);

          if (req.Paging.Index === 1 && this.mortgageQuery.getCount() !== 0 && res.response.TotalCount > 500) {
            this.mortgageStore.set([]);
            res.response.IsComplete = false;
          }

          const a = this.mortgageQuery.getAll();
          if (req.Paging.Index === 1 && res.response.IsComplete && res.response.TotalCount <= 100) {
            this.mortgageStore.set([]);
            this.mortgageStore.set([...rows]);
          } else {
            this.mortgageStore.set([...a, ...rows]);
          }

          this.mortgageStore.update(state => ({
            ...state,
            count: res.response.TotalCount,
            totalAPI: res.response.TotalAPI,
            isComplete: res.response.IsComplete,
          }));

          return res.response;
        })
      ),
      finalize(() => {
        this.mortgageStore.setSearchForm(req);
        this.mortgageStore.uiStore.setIsSearching(false);
      })
    );
  }

  /**
   * @param {MortgageRequest} req request
   * Sets IsExport to true in akita store
   * @returns {Observable} Blob
   * Sets IsExport to false in akita store
   */
  export(req: MortgageRequest): Observable<any> {
    return this.api.post4('export/services/m', req).pipe(
      map(x => {
        const obj = this.tryParseJSON(x);
        if (!obj) {
          return new Blob([x], {
            type: 'text/plain',
          });
        } else {
          return obj;
        }
      }),
      retry(1),
      catchError(err => {
        this.loggerService.Log(err, logMessage.shared.export.error);
        return throwError(new Error(err));
      })
    );
  }

  tryParseJSON(jsonString: any): boolean {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  reloadData() {
    const data = this.mortgageQuery.getAll();
    this.mortgageStore.set([]);
    this.mortgageStore.set([...data]);
  }

  getColumns(): Observable<any> {
    return this.mortgageQuery
      .select(x => x.columns)
      .pipe(
        take(1),
        filter(x => (x ? x.length < 1 : false)),
        mergeMap(x => this.userQuery.staffSettings$),
        tap(x =>
          R.complement(R.either(R.isNil, R.isEmpty))(x)
            ? this.mortgageStore.setColumns(!!x.MortgageSearchColumns ? JSON.parse(x.MortgageSearchColumns) : [])
            : null
        )
      );
  }

  getColumnWidths(): Observable<any> {
    return this.mortgageQuery
      .select(x => x.columnWidths)
      .pipe(
        take(1),
        filter(x => (x ? x.length < 1 : false)),
        mergeMap(x => this.userQuery.staffSettings$),
        tap(x =>
          R.complement(R.either(R.isNil, R.isEmpty))(x)
            ? this.mortgageStore.setColumnWidths(
              !!x.MortgageSearchColumnsWidth ? JSON.parse(x.MortgageSearchColumnsWidth) : []
            )
            : null
        )
      );
  }

  reorderColumn = (oldIndex: number, newIndex: number) => {
    const newCol = produce(this.mortgageQuery.getValue().columns, draft => {
      const movedCol = draft?.splice(oldIndex, 1);
      draft?.splice(newIndex, 0, movedCol[0]);
    });
    const newColWith = produce(this.mortgageQuery.getValue().columnWidths, draft => {
      const movedCol = draft?.splice(oldIndex, 1);
      draft?.splice(newIndex, 0, movedCol[0]);
    });

    return of(newCol).pipe(
      tap(() => {
        this.mortgageStore.setColumns(newCol);
        this.mortgageStore.setColumnWidths(newColWith);
      }),
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          MortgageSearchColumns: JSON.stringify(newVal),
          MortgageSearchColumnsWidth: JSON.stringify(newColWith),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req).pipe(tap(() => this.userStore.update(req)))
      )
    );
  };

  resizeColumn(prop: string, width: number): Observable<any> {
    const oldColWidths = this.mortgageQuery.getValue().columnWidths?.filter(x => x);
    const newColWidths = produce(oldColWidths, draft => {
      const col = columns?.find(x => x.prop === prop);
      const exists = draft?.some(x => col.metakey === x.metakey);
      if (exists) {
        draft.find(x => col.metakey === x.metakey).width = width;
      } else {
        draft.push({ metakey: col.metakey, width });
      }
    });

    return of(newColWidths).pipe(
      tap(x => this.mortgageStore.setColumnWidths(x)),
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          MortgageSearchColumnsWidth: JSON.stringify(newVal),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) => (isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)))
    );
  }

  saveVisibleColumns = (metakeys: Metakey[]) => {
    const newColumns = metakeys;
    const oldColumns = this.mortgageQuery.getValue().columns;
    if (R.equals(newColumns, oldColumns)) {
      return of();
    }

    this.mortgageStore.uiStore.setIsColumnSaving(true);

    const newColumnMetakeys = newColumns;

    return of(newColumnMetakeys).pipe(
      withLatestFrom(this.userQuery.userInfo$),
      map(([newVal, user]) => ({
        ...user,
        StaffSettings: {
          ...user.StaffSettings,
          MortgageSearchColumns: JSON.stringify(newVal),
        },
      })),
      withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
      mergeMap(([req, isTap, user]) =>
        isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req).pipe(tap(() => this.userStore.update(req)))
      ),
      finalize(() => this.mortgageStore.uiStore.setIsColumnSaving(false)),
      tap(() => this.mortgageStore.setColumns(newColumnMetakeys))
    );
  };

  saveField(req: {
    CustomerID: number;
    CustomerServiceID: number;
    MetaKey: Metakey;
    MetaValue: string;
    CustomerServiceType: string;
  }): Observable<JsonResultStatus> {
    const record = this.mortgageQuery.getEntity(req.CustomerServiceID);
    const fields = R.omit(
      [
        'CustomerID',
        'CustomerServiceID',
        'link',
        'ActivityId',
        'BorrowingEntitiesList',
        'SecuritiesList',
        'ClientNextActivityId',
        'UserNextActivityId',
      ],
      record
    );
    const isCustomerDetail = Object.values(fields)?.find(x => x.metakey === req.MetaKey).isCustomerDetail;

    this.mortgageStore.uiStore.setLoad(req.CustomerServiceID, req.MetaKey, true);
    const apiUrl = isCustomerDetail
      ? `contacts/${req.CustomerID}?isPatch=true`
      : `services/${req.CustomerServiceID}?isPatch=true`;
    return this.api.put<JsonResultStatus>(`${apiUrl}`, R.omit(['CustomerServiceID', 'CustomerID'], req)).pipe(
      tap(() =>
        applyTransaction(() => {
          const stateReq = {
            ...req,
            CustomerServiceId: isCustomerDetail
              ? this.mortgageQuery
                .getAll({ filterBy: row => row.CustomerID === req.CustomerID })
                ?.map(x => x.CustomerServiceID)
              : req.CustomerServiceID,
          };
          this.mortgageStore.updateField(stateReq);
          this.mortgageStore.uiStore.setEdit(req.CustomerServiceID, req.MetaKey, false);
          this.setTempValue(req.CustomerServiceID, req.MetaKey, undefined);
        })
      ),
      finalize(() => this.mortgageStore.uiStore.setLoad(req.CustomerServiceID, req.MetaKey, false))
    );
  }

  edit = (customerServiceId: number, metakey: Metakey) =>
    this.mortgageStore.uiStore.setEdit(customerServiceId, metakey, true);
  cancel = (customerServiceId: number, metakey: Metakey) =>
    applyTransaction(() => {
      this.mortgageStore.uiStore.setEdit(customerServiceId, metakey, false);
      this.setTempValue(customerServiceId, metakey, undefined);
    });

  updateClientAndUserNextActivity = (customerId: number) => {
    return forkJoin(this.updateClientNextActivity(customerId), this.updateUserNextActivity(customerId));
  };

  updateClientNextActivity = (customerId: number) => {
    const customerServiceIds = this.mortgageQuery
      .getAll({ filterBy: row => row.CustomerID === customerId })
      ?.map(z => z.CustomerServiceID);
    customerServiceIds?.forEach(id => {
      this.mortgageStore.uiStore.setLoad(id, 'Client Next Activity', true);
    });
    return this.api
      .get<ActivityViewModel>(`activities/${customerId}/customer`, { nextActivityOnly: true })
      .pipe(
        tap(x => {
          this.mortgageStore.update(
            customerServiceIds,
            produce(draft => {
              if (x && !!x.ActivityId) {
                const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
                draft.ClientNextActivityId = x.ActivityId;
                // tslint:disable-next-line: max-line-length
                draft.ClientNextActivity.value =
                  formattedDate + ' - ' + x.AssignedToAdviserName + ' - ' + x.ActivityType + ' - ' + x.ActivityName;
              } else {
                draft.ClientNextActivityId = null;
                draft.ClientNextActivity.value = null;
              }
            })
          );
        }),
        finalize(() => {
          customerServiceIds?.forEach(id => {
            this.mortgageStore.uiStore.setLoad(id, 'Client Next Activity', false);
          });
        })
      );
  };

  updateUserNextActivity = (customerId: number) => {
    const customerServiceIds = this.mortgageQuery
      .getAll({ filterBy: row => row.CustomerID === customerId })
      ?.map(z => z.CustomerServiceID);
    customerServiceIds?.forEach(id => {
      this.mortgageStore.uiStore.setLoad(id, 'User Next Activity', true);
    });
    return this.api
      .get<ActivityViewModel>(`activities/${customerId}/adviser`, { nextActivityOnly: true })
      .pipe(
        tap(x => {
          this.mortgageStore.update(
            customerServiceIds,
            produce(draft => {
              if (x && !!x.ActivityId) {
                const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
                draft.UserNextActivityId = x.ActivityId;
                draft.UserNextActivity.value = formattedDate + ' - ' + x.ActivityType + ' - ' + x.ActivityName;
              } else {
                draft.UserNextActivityId = null;
                draft.UserNextActivity.value = null;
              }
            })
          );
        }),
        finalize(() => {
          customerServiceIds?.forEach(id => {
            this.mortgageStore.uiStore.setLoad(id, 'User Next Activity', false);
          });
        })
      );
  };

  createClientNextActivity = (ac: ActivityViewModel) =>
    of(ActivityViewModel.MapToAdd(ac)).pipe(
      mergeMap(x => this.activityService.Post(x)),
      mergeMap(
        y => {
          if (y) {
            return this.updateClientAndUserNextActivity(ac.Customer.CustomerId);
          }
        },
        o => o
      )
    );

  sort(propSort: string, sort: 'asc' | 'desc') {
    this.mortgageStore.uiStore.setSort(propSort, sort);
  }

  setTempValue = (customerId: number, metakey: string, value: any) =>
    this.mortgageStore.uiStore.setTempValue(customerId, metakey, value);

  updateNote = (customerServiceId: number, value: string) => {
    this.mortgageStore.update(
      customerServiceId,
      produce(draft => {
        const nzToday = MomentUtil.createMomentNz().format('DD/MM/YYYY');
        const ln =
          nzToday +
          ' - ' +
          this.userQuery.getValue().FirstName +
          ' ' +
          this.userQuery.getValue().LastName +
          ' - ' +
          value;
        draft.LastNote.value = ln;
      })
    );
  };

  setSearchForm = (req: MortgageRequest) => {
    this.mortgageStore.setSearchForm(req);
  }
}
