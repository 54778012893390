import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClientProfileQuery } from '@modules/crm/client-profile/states/client-profile.query';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import {
	ClientReferralPopupType,
	ReferClientPopupService,
} from '@shared/components/refer-client-popup/refer-client-popup.service';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';
import {
	BehaviorSubject,
	Observable,
	Observer,
	ReplaySubject,
	Subject,
	throwError,
	zip,
} from 'rxjs';
import {
	catchError,
	delay,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { LocalService } from 'src/app/core/services/local.service';
import { ActivityModel } from '../../shared/models/_general/activity.model';
import { ActivityViewModel } from '../../shared/models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';
import MomentUtil from '../../util/moment.util';
import { objectUtil, strUtil } from '../../util/util';
import { ActivityCancelModalComponent } from '../modal/activity/activity-cancel-modal/activity-cancel-modal.component';
import { ActivityModalComponent } from '../modal/activity/activity-modal/activity-modal.component';
import {
	DeleteModalComponent,
	DeleteModalModel,
} from '../modal/delete-modal/delete-modal.component';
import { PhoneCallAddModalComponent } from '../modal/phone-call-add-modal/phone-call-add-modal.component';
import { CustomerTypes } from '../models/_general/client.model';
import {
	ActivityState,
	NoteState,
} from '../models/activity-timeline/activity-timeline.model';
import { NoteFilters, NoteTypes } from '../models/notes/note.model';
import { ServicesCodes } from '../models/services/services.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { SasReference } from '@shared/models/client-profile/primary-client/primary-client.model';
import { BusinessProfileQuery } from '@modules/crm/business-profile/states/business-profile.query';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';

@Component({
	selector: 'app-activity-timeline',
	templateUrl: './activity-timeline.component.html',
	styleUrls: ['./activity-timeline.component.scss'],
})
export class ActivityTimelineComponent
	implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
	private onDestroy$ = new Subject<void>();

	@Input() location: string;
	@Input() isLead: boolean;
	@Input() customerId: number;
	@Input() customerName: string;
	@Input() pendingActivities: ActivityState[];
	@Input() pendingActivitiesSortBy: 'asc' | 'desc' = 'asc';
	@Input() allCompletedNotes: NoteState[];
	@Input() completedActivities: NoteState[];
	@Input() completedNotes: NoteState[];
	@Input() activityType$: Observable<ViewDisplayValue[]>;
	@Input() activityMeeting$: Observable<ViewDisplayValue[]>;
	@Input() adviserChoices$: Observable<ViewDisplayValue[]>;
	@Input() adviserCalendarChoices$: Observable<ViewDisplayValue[]>;
	@Input() addNoteFn$: (req: string) => Observable<any>;
	@Input() deleteNoteFn$: (
		req: number,
		isAp?: boolean,
		adviceProcessId?: string
	) => Observable<any>;
	@Input() addActivityFn$: (req: ActivityViewModel) => Observable<any>;
	@Input() updateActivityFn$: (req: ActivityViewModel) => Observable<any>;
	@Input() cancelActivityFn$: (req: any) => Observable<any>;
	@Input() deleteActivityFn$: (req: ActivityViewModel) => Observable<any>;
	@Input() addPhoneCallFn$: (req: ActivityViewModel) => Observable<any>;

	@Input() isBusiness = false;

	// tslint:disable-next-line: variable-name
	@Input() pinNoteFn$: ({
		note,
		pin,
	}: {
		note: NoteState;
		pin: boolean;
	}) => Observable<any>;

	@Output() deleteNoteEvent = new EventEmitter<any>();

	set notesFilter(value) {
		this.filterNotes(value);
	}
	get notesFilter() {
		return this._notesFilter;
	}
	private _notesFilter = 'all';
	notes$ = new BehaviorSubject<NoteState[]>([]);
	pinnedNotes$ = new BehaviorSubject<NoteState[]>([]);
	isSaving$ = new ReplaySubject<boolean>(1);
	isAddingNote$ = new ReplaySubject<boolean>(1);
	isloadingNotes$ = new ReplaySubject<boolean>(1);

	isNotesForm = false;
	isActivityForm = false;
	isNoteSaving = false;
	isActivityEdit = false;
	isActivitySaving = false;
	isAddNote = false;
	isAddActivity = false;

	formItem: ActivityViewModel = null;

	formNote: UntypedFormGroup = this.fb.group({
		note: this.fb.control(null, [Validators.required]),
	});

	public formData$: Observable<ActivityViewModel>;
	public readonly isEdit$: Observable<boolean>;

	pendingActiveId: number;
	completedActiveId: number;
	activeShowMoreIds: NoteState[] = [];

	currentPage = 1;
	isLastPage: boolean;
	itemsPerPage = 10;
	completedList = [];
	currentCompletedFilter: string;
	customerTypes = CustomerTypes;
	noteTypes = NoteTypes;
	serviceCodes = ServicesCodes;
	noteFilters = NoteFilters;

	@ViewChild('completedNotesElement') completedNotesElement: ElementRef;

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private activityService: ActivityService,
		private cd: ChangeDetectorRef,
		private localService: LocalService,
		private referClientPopupService: ReferClientPopupService,
		private clientProfileQuery: ClientProfileQuery,
		private clientProfileService: ClientProfileService,
		private businessConfigQuery: BusinessConfigQuery,
		private businessProfileQuery: BusinessProfileQuery,
		private businessProfileService: BusinessProfileService
	) {}

	setPage(event: PageChangedEvent, notes?: NoteState[]): void {
		this.isloadingNotes$.next(true);
		setTimeout(() => {
			this.currentPage = event.page;
			const startItem = 0;
			const endItem = event.page * event.itemsPerPage;
			this.completedList = notes;
			this.notes$.next(
				notes && notes.length > 0 ? notes?.slice(startItem, endItem) : []
			);

			this.isloadingNotes$.next(false);
			this.isLastPage =
				Math.ceil((this.completedList?.length ?? 0) / 10) === this.currentPage;
			this.cd.detectChanges();
		}, 100);
	}

	backToRecentNotes() {
		this.completedNotesElement.nativeElement.scrollIntoView({
			behavior: 'auto',
			block: 'start',
		});
	}

	ngOnInit() {
		this.filterNotes(this.notesFilter);
		this.notes$.pipe(takeUntil(this.onDestroy$)).subscribe();

		this.route.paramMap
			.pipe(
				map((x) => {
					if (x.has('activity')) {
						this.editActivity(+x.get('activity'));
					}
				})
			)
			.subscribe();
	}

	ngAfterViewInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (
				this.allCompletedNotes ||
				this.completedActivities ||
				this.completedNotes
			) {
				this.pinnedNotes$.next(
					this.allCompletedNotes?.filter((note) => note.isPinned)
				);
				this.filterNotes(this.notesFilter);
			}
		}
	}

	setActiveShowMoreIds(id) {
		if (id && !this.activeShowMoreIds?.some((x) => x === id)) {
			this.activeShowMoreIds.push(id);
		} else {
			this.activeShowMoreIds = this.activeShowMoreIds?.filter((x) => x !== id);
		}
	}

	existingActiveShowMoreIds(id) {
		return this.activeShowMoreIds?.some((x) => x === id);
	}

	tackByFn(index, item: ActivityState) {
		return item.activityId;
	}

	trackByNotesFn(index, item: NoteState) {
		return item.notesID;
	}

	hasShowMoreBtn(element: any, maxHeight: number) {
		const notes = element.querySelector('.item-wrapper');
		return (!!notes ? notes.offsetHeight : 0) > maxHeight;
	}

	/**
	 * Convert Date time
	 * @param date YYYY-MM-DDTHH:mm
	 */
	formatToDisplayDatetime(date) {
		return MomentUtil.formatToDisplayDatetime(moment(date));
	}

	filterNotes(noteFilter?: string) {
		let data = [];
		if (noteFilter && noteFilter === NoteFilters.Activities) {
			this._notesFilter = NoteFilters.Activities;
			data = this.completedActivities;
		} else if (noteFilter && noteFilter === NoteFilters.Notes) {
			this._notesFilter = NoteFilters.Notes;
			data = this.completedNotes;
		} else {
			this._notesFilter = NoteFilters.All;
			data = this.allCompletedNotes;
		}

		this.currentPage = 1;
		this.cd.detectChanges();
		this.setPage(
			{ page: this.currentPage, itemsPerPage: this.itemsPerPage },
			data
		);
	}

	isCall(acType: string) {
		return (
			!!acType &&
			(acType.toLowerCase()?.includes('call') ||
				acType?.toLowerCase()?.includes('phone'))
		);
	}

	addNote() {
		this.isAddNote = true;
	}

	prepareNoteFormValue() {
		const data = strUtil.safeTrim(this.formNote.value.note);
		return data;
	}

	saveNote() {
		if (!this.formNote.valid) {
			return;
		}
		this.isAddingNote$.next(true);
		this.addNoteFn$(this.prepareNoteFormValue()).subscribe(
			() => {
				this.formNote.reset();
				this.isAddNote = false;
			}, // Success
			() => {}, // Error
			() => {
				this.isAddingNote$.next(false);
			}
		);
	}

	cancelAddNote() {
		this.isAddNote = false;
	}

	deleteNote(notes: NoteState) {
		this.isSaving$.next(true);
		const isAp = notes.activityType === NoteTypes.AdviceProcess ? true : false;
		const adviceProcessId = notes.adviceProcessId
			? notes.adviceProcessId?.toString()
			: null;
		this.deleteNoteFn$(notes.notesID, isAp, adviceProcessId).subscribe(
			() => {
				this.deleteNoteEvent.emit(notes);
			},
			() => {},
			() => {
				this.isSaving$.next(false);
			}
		);
	}

	confirmDeleteNote(notes: NoteState) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteNote(notes);
			obs.complete();
		});
		const initState: DeleteModalModel = {
			delete$: confirm,
			header: 'Delete Note',
			message: `Are you sure you want to delete this note?`,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	get isLeadCustomer() {
		return !!this.isLead;
	}

	addPhoneCall() {
		const initState: any = {
			header: 'Phone Call',
			savefn: this.savePhoneCallFn,
			advisers$: this.adviserChoices$,
		};
		this.modalService.show(PhoneCallAddModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-md',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	savePhoneCallFn = (form: ActivityViewModel) => {
		this.isSaving$.next(true);
		const data = {
			...form,
			ActivityId: null,
			Adviser: !!form?.Adviser ? +form?.Adviser : null,
			Customer: {
				CustomerId: this.customerId,
				Name: this.customerName,
			},
			Duration: 1,
			Location: '',
			Appointment: null,
			IsCompleted: true,
		} as ActivityViewModel;

		return this.addPhoneCallFn$(data).pipe(
			tap(
				() => {},
				() => {},
				() => {
					this.isSaving$.next(false);
				}
			),
			catchError((err) => {
				this.isSaving$.next(false);
				return throwError(new Error(err));
			})
		);
	};

	addActivity() {
		const formItem = {
			Customer: { CustomerId: this.customerId, Name: this.customerName },
			Location: this.location,
		};
		const initState: any = {
			formItem,
			AT$: this.activityType$,
			AM$: this.activityMeeting$,
			adviserChoices$: this.adviserChoices$,
			adviserCalendarChoices$: this.adviserCalendarChoices$,
			header: 'Schedule Activity',
			hideClient: true,
			savefn: this.saveActivityFn,
			isModal: true,
			isEditForm: false,
			permissionsToComplete: this.isLeadCustomer ? ['FCLA'] : ['FCCA'],
		};
		this.modalService.show(ActivityModalComponent, {
			class: `modal-dialog-centered ${
				this.localService.getValue('loginType') === 'microsoft'
					? 'modal-dialog-outlook-xl'
					: 'modal-xl'
			}`,
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveActivityFn = (ac: ActivityViewModel) => {
		this.isSaving$.next(true);
		return this.addActivityFn$({
			...ac,
			Adviser: +ac.Adviser,
			IsCompleted: !!ac.IsCompleted,
		}).pipe(
			tap(
				() => {},
				() => {},
				() => {
					this.isSaving$.next(false);
					this.pendingActiveId = null;
					this.completedActiveId = null;
				}
			),
			catchError((e) => {
				this.isSaving$.next(false);
				return throwError(e);
			})
		);
	};

	clearIds = () => {
		this.pendingActiveId = null;
		this.completedActiveId = null;
	};

	editActivity(formItem: ActivityState | number) {
		if (formItem) {
			if (typeof formItem === 'number') {
				this.activityService.GetById(formItem).subscribe((ac) => {
					const activity = {
						...ac,
						DueDate: ac.DueDate ? moment(ac.DueDate) : '',
						Customer: ac.Client,
					};
					const initState: any = {
						formItem: activity,
						edit$: confirm,
						AT$: this.activityType$,
						AM$: this.activityMeeting$,
						adviserChoices$: this.adviserChoices$,
						adviserCalendarChoices$: this.adviserCalendarChoices$,
						header: 'Schedule Activity',
						hideClient: true,
						savefn: this.updateActivity,
						isModal: true,
						permissionsToComplete: this.isLeadCustomer ? ['FCLA'] : ['FCCA'],
						clearIds: this.clearIds,
					};
					this.modalService.show(ActivityModalComponent, {
						class: `modal-dialog-centered ${
							this.localService.getValue('loginType') === 'microsoft'
								? 'modal-dialog-outlook-xl'
								: 'modal-xl'
						}`,
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				});
			} else {
				const activity = ActivityViewModel.MapToViewModel(
					objectUtil.mapCamelCaseToPascalCase(formItem) as ActivityModel
				);
				const initState: any = {
					formItem: activity,
					edit$: confirm,
					AT$: this.activityType$,
					AM$: this.activityMeeting$,
					adviserChoices$: this.adviserChoices$,
					adviserCalendarChoices$: this.adviserCalendarChoices$,
					header: 'Schedule Activity',
					hideClient: true,
					savefn: this.updateActivity,
					isModal: true,
					permissionsToComplete: this.isLeadCustomer ? ['FCLA'] : ['FCCA'],
					clearIds: this.clearIds,
				};
				this.modalService.show(ActivityModalComponent, {
					class: `modal-dialog-centered ${
						this.localService.getValue('loginType') === 'microsoft'
							? 'modal-dialog-outlook-xl'
							: 'modal-xl'
					}`,
					initialState: initState,
					ignoreBackdropClick: true,
					keyboard: false,
				});
			}
		}
	}

	updateActivity = (ac: ActivityViewModel) => {
		this.isSaving$.next(true);
		return this.updateActivityFn$({
			...ac,
			Adviser: +ac.Adviser,
			IsCompleted: !!ac.IsCompleted,
		}).pipe(
			takeUntil(this.onDestroy$),
			tap(
				() => {},
				() => {},
				() => {
					this.isSaving$.next(false);
					this.pendingActiveId = null;
					this.completedActiveId = null;
				}
			),
			catchError((e) => {
				this.isSaving$.next(false);
				return throwError(e);
			})
		);
	};

	confirmCancelActivity = (ac: ActivityState) => {
		const activity = ActivityViewModel.MapToViewModel(
			objectUtil.mapCamelCaseToPascalCase(ac) as ActivityModel
		);
		const initState: any = {
			header: 'Cancel Activity',
			message: `Are you sure you want to cancel this activity?`,
			activity,
			savefn: this.cancelActivity,
		};
		this.modalService.show(ActivityCancelModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	};

	cancelActivity = (ac: { activity; reason }) => {
		this.isSaving$.next(true);
		return this.cancelActivityFn$(ac).pipe(
			tap(
				() => {},
				() => {},
				() => {
					this.isSaving$.next(false);
				}
			)
		);
	};

	confirmDeleteActivity = (ac: ActivityState) => {
		const data = ActivityViewModel.MapToViewModel(
			objectUtil.mapCamelCaseToPascalCase(ac) as ActivityModel
		);
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteActivity(data);
			obs.complete();
		});
		const initState: DeleteModalModel = {
			delete$: confirm,
			header: 'Delete Activity',
			message: `Are you sure you want to delete this activity?`,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	};

	deleteActivity = (ac: ActivityViewModel) => {
		this.isSaving$.next(true);
		return this.deleteActivityFn$(ac)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				() => {},
				() => {},
				() => {
					this.isSaving$.next(false);
				}
			);
	};

	checkIfEmail(email: string) {
		if (!email) {
			return false;
		}

		if (email?.toLowerCase()?.indexOf('email') > -1) {
			return true;
		}

		return false;
	}

	pinNote(note: NoteState, pin: boolean) {
		this.isSaving$.next(true);
		return this.pinNoteFn$({ note, pin }).subscribe(
			() => {},
			() => {},
			() => {
				this.isSaving$.next(false);
			}
		);
	}

	getAdviser(): Observable<number> {
		return this.businessConfigQuery.adviserReworkFeature$.pipe(
			take(1),
			map((adviserReworkEnabled) => {
				const primaryClient = this.isBusiness
					? this.businessProfileQuery.getValue()?.primaryCompany
					: this.clientProfileQuery.getValue()?.primaryClient;
				if (adviserReworkEnabled) {
					return SasReference.reduce((prev, cur) => {
						if (prev) {
							return prev;
						}
						prev = primaryClient[cur.id];
						return prev;
					}, null as number);
				}
				return +primaryClient.adviser;
			})
		);
	}

	openClientReferralPopUp(): void {
		this.getAdviser()
			.pipe(
				take(1),
				mergeMap((adviser) => {
					return this.referClientPopupService
						.show(
							ClientReferralPopupType.CRM,
							adviser,
							this.customerId,
							this.customerName,
							null,
							this.isBusiness
						)
						.pipe(
							take(1),
							mergeMap(() => {
								if (this.isBusiness) {
									const businessActivities$ = this.businessProfileService
										.getActivityTimeline(this.customerId)
										.pipe(take(1));
									const businessCriterias$ = this.businessProfileService
										.getCriterias(this.customerId)
										.pipe(take(1));
									return zip([businessActivities$, businessCriterias$]);
								}
								const activities$ = this.clientProfileService
									.getActivityTimeline(this.customerId)
									.pipe(take(1));
								const criteries$ = this.clientProfileService
									.getCriterias(this.customerId)
									.pipe(take(1));
								return zip([activities$, criteries$]);
							})
						);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
