import { StoreConfig, Store } from '@datorama/akita';
import { NblrOpportunity } from './nblr.model';
import { produce } from 'immer';
import { Injectable } from "@angular/core";

export interface NBLRState {
  NblrOpportunity: NblrOpportunity[];
  error: string;
  isLoading: boolean;
}

export function CreateInitialState(): NBLRState {
  return {
    NblrOpportunity: null,
    error: '',
    isLoading: false
  };
}

@Injectable()
@StoreConfig({
  name: 'NblrOpportunity'
})
export class NBLRStore extends Store<NBLRState> {
  constructor() {
    super(CreateInitialState());
  }

  setLoading(isLoading: boolean): void {
    this.update(
      produce(draft => {
        draft.isLoading = isLoading;
      })
    );
  }
}
