<!--GA - Access CRM Backend-->
<ng-container *appRestrictTo="['TTSA']">
    <div class="app-admin-container pt-2 pb-1 mt-4">
        <h6 class="d-flex clickable" (click)="isAdminCollapsed = !isAdminCollapsed" [appTheme]="{'color':'primarycolor'}">
            <i class="material-icons mr-1">&#xE838;</i> TAP LEVEL SETTINGS
        </h6>
        <div [ngClass]="{'app-admin-sublinks d-flex flex-column': !isAdminCollapsed}" [collapse]="isAdminCollapsed" class="app-backend-sidenav-sublinks d-flex flex-column pl-4">
            <!--TL - Businesses Access-->
            <ng-container *appRestrictTo="['TBA']">
                <a [routerLink]="adminBList" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
                   class="pb-2">Businesses</a>
            </ng-container>
            <!--TL - Businesses Access-->
            <ng-container *appRestrictTo="['TTUA']">
                <a [routerLink]="adminUList" routerLinkActive="active" class="pb-2">TAP Level Users</a>
            </ng-container>
        </div>
    </div>
</ng-container>
