<!-- Header -->
<div
	role="content-header"
	class="d-flex justify-content-between align-items-center"
>
	<app-header [pageHeader]="pageHeaders.ROA"></app-header>
</div>

<!-- Content -->
<div role="main-content">
	<app-manual-loader
		*ngIf="isLoading$ | async as isLoading"
		[isLoading]="isLoading"
	></app-manual-loader>
	<ng-container *ngIf="(hasStructureSOA$ | async) && !(isLoading$ | async)">
		<mat-horizontal-stepper
			[linear]="true"
			[@.disabled]="true"
			#stepper
			(selectionChange)="stepperSelectionChange($event)"
		>
			<!-- Loan Structure -->
			<mat-step [label]="moatStructurePages.loanStructure">
				<div class="horizontal-step-content">
					<app-loan
						#loanStructure
						[parentCRTId]="(structureSOA$ | async)?.cRTId"
						[adviceProcessId]="adviceProcessId"
						[getOwnerChoices]="getOwnerChoices"
						(saveCompleted)="handleSaveComplete($event)"
					></app-loan>
				</div>

				<div class="d-flex justify-content-end">
					<button
						*ngIf="!isLoading"
						mat-button
						[disabled]="loanIsUpdating$ | async"
						(click)="handleNext(sectionCodes?.LoanStructure)"
						class="tap-btn tap-btn--primary text-white ml-2"
					>
						Next
					</button>
					<app-manual-loader-mini
						*ngIf="isLoading"
						[isLoading]="isLoading"
					></app-manual-loader-mini>
				</div>
			</mat-step>

			<!-- Security -->
			<mat-step [label]="moatStructurePages.security">
				<div class="horizontal-step-content">
					<app-structure-soa-security
						#roaSecurity
						[parentCRTId]="(structureSOA$ | async)?.cRTId"
						[adviceProcessId]="adviceProcessId"
						(saveCompleted)="handleSaveComplete($event)"
					>
					</app-structure-soa-security>
				</div>

				<div class="d-flex justify-content-end">
					<button
						*ngIf="!(roaSecurity.isLoading$ | async)"
						mat-button
						class="tap-btn tap-btn--default ml-2"
						(click)="handleBack(sectionCodes?.Security)"
					>
						Back
					</button>
					<button
						*ngIf="!(roaSecurity.isLoading$ | async)"
						mat-button
						(click)="handleNext(sectionCodes?.Security)"
						class="tap-btn tap-btn--primary text-white ml-2"
					>
						Next
					</button>
					<app-manual-loader-mini
						*ngIf="roaSecurity.isLoading$ | async as isLoading"
						[isLoading]="isLoading"
					></app-manual-loader-mini>
				</div>
			</mat-step>

			<!-- Other Details -->
			<mat-step [label]="moatStructurePages.otherDetails">
				<div class="horizontal-step-content">
					<app-other-details
						#otherDetails
						[parentCRTId]="(structureSOA$ | async)?.cRTId"
						[adviceProcessId]="adviceProcessId"
						(saveCompleted)="handleSaveComplete($event)"
					></app-other-details>
				</div>

				<div class="d-flex justify-content-end">
					<app-manual-loader-mini
						*ngIf="
							isLoading || (isOtherDetailsLoading$ | async);
							else showOtherDetailsButtons
						"
						[isLoading]="true"
					></app-manual-loader-mini>
					<ng-template #showOtherDetailsButtons>
						<button
							mat-button
							class="tap-btn tap-btn--default ml-2"
							(click)="handleBack(sectionCodes?.OtherDetails)"
						>
							Back
						</button>
						<button
							mat-button
							class="tap-btn tap-btn--primary text-white ml-2"
							(click)="handleNext(sectionCodes?.OtherDetails)"
						>
							Next
						</button>
					</ng-template>
				</div>
			</mat-step>

			<!-- Statement of Advice -->
			<mat-step [label]="moatStructurePages.SOA">
				<div class="horizontal-step-content">
					<app-statement-of-advice
						#soa
						#statementOfAdvice="statementOfAdvice"
						[cRTId]="(structureSOA$ | async)?.cRTId"
						[data$]="structureSOA$"
						[adviceProcessId]="adviceProcessId"
						(saveCompleted)="handleSaveComplete($event)"
						(lastAutoSavedTime)="lastAutoSavedTimeApp($event)"
						(isAutoSaveLoading)="isAutoSaveLoading($event)"
					></app-statement-of-advice>
				</div>

				<div class="d-flex justify-content-end">
					<ng-container
						*ngIf="
							isLoading ||
								statementOfAdvice.isSaving ||
								statementOfAdvice.isLoading;
							else showRoaBtns
						"
					>
						<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
					</ng-container>
					<ng-template #showRoaBtns>
						<button
							class="tap-btn tap-btn--primary text-white mr-auto"
							mat-button
							*ngIf="statementOfAdvice.statementOfAdviceEmailEnabled$ | async"
							(click)="statementOfAdvice.sendEmail()"
						>
							Email Document
						</button>

						<span *ngIf="reviewAppLastSaved" class="pt-2 last-modified">
							Last saved {{ reviewAppLastSaved }}
						</span>
						<button
							mat-button
							class="tap-btn tap-btn--default ml-2"
							(click)="handleBack(sectionCodes?.StatementOfAdvice)"
						>
							Back
						</button>

						<button
							mat-button
							(click)="handleNext(sectionCodes?.StatementOfAdvice)"
							class="tap-btn tap-btn--primary text-white ml-2"
						>
							Save & Exit
						</button>
					</ng-template>
				</div>
			</mat-step>
		</mat-horizontal-stepper>
	</ng-container>
</div>

<!-- Footer -->
<div
	*ngIf="showFooter"
	role="content-footer"
	class="d-flex align-items-center justify-content-end"
>
	<app-footer [currentPageId]="pageIds.ROA" [sidebar]="sidebar"></app-footer>
</div>
