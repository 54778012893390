import { Injectable } from '@angular/core';
import { CriticalIllnessState } from '@shared/models/client-review-template/risk-analysis/critical-illness/critical-illness.model';
import { GoalsState } from '@shared/models/client-review-template/risk-analysis/goals/goals.model';
import { RiskProfileState } from '@shared/models/client-review-template/risk-analysis/risk-profile/risk-profile.model';
import {
	complement,
	divide,
	either,
	isEmpty,
	isNil,
	multiply,
	negate,
	subtract,
	sum,
} from 'ramda';
import { BehaviorSubject, Observable, iif, of } from 'rxjs';
import {
	concatMap,
	map,
	mergeMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { FactFindComputationState } from '../../../../../shared/models/client-review-template/income-budget/factfind-computation.model';
import { numUtil, objectUtil } from '../../../../../util/util';
import { convertToMonthly } from '../../income-budget/calculations/annual-conversion';
import { getTaxAcc } from '../../income-budget/calculations/tax-and-acc';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';
import { DisabilityStore } from './disability/disability.store';
import { LifeStore } from './life/life.store';
import { MedicalStore } from './medical/medical.store';
import { TpdStore } from './tpd/tpd.store';

@Injectable({
	providedIn: 'root',
})
@Injectable()
export class RiskAnalysisService extends ClientReviewTemplateService {
	people$ = this.query.peopleAndDependantsFromCRTOnly$;
	currentTabs$: Observable<PeopleState[]>;
	selectedPerson$ = new BehaviorSubject<number>(null);
	activePerson$ = new BehaviorSubject<PeopleState>(null);
	currentPersonIndex$ = new BehaviorSubject<number>(null);

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: ClientReviewTemplateStore,
		protected query: ClientReviewTemplateQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected api: ApiService,
		protected disabilityStore: DisabilityStore,
		protected lifeStore: LifeStore,
		protected medicalStore: MedicalStore,
		protected tpdStore: TpdStore
	) {
		super(dropdownValueQuery, store, query, customerService, businessService);
	}

	clearData() {
		this.selectedPerson$.next(null);
		this.activePerson$.next(null);
		this.currentPersonIndex$.next(null);
	}

	getRiskAnalysis(adviceProcessId: number) {
		return this.api
			.get(`crt/risk-analysis/advice-process/${adviceProcessId}`)
			.pipe(
				map(objectUtil.mapPascalCaseToCamelCase),
				tap((x) => {
					const goals = x?.goals
						?.map((item) => ({
							...item,
							adviceProcessId,
						}))
						.map(objectUtil.mapPascalCaseToCamelCase) as GoalsState;
					const life = x?.life;
					const disability = x?.disability;
					const tPDs = x?.tPDs;

					const criticalIllness = x?.criticalIllness
						?.map((item) => ({
							...item,
							adviceProcessId,
						}))
						.map(objectUtil.mapPascalCaseToCamelCase) as CriticalIllnessState[];
					const medicals = x?.medicals;
					const riskProfiles = x?.riskProfiles
						?.map((item) => ({
							...item,
							adviceProcessId,
						}))
						.map(objectUtil.mapPascalCaseToCamelCase) as RiskProfileState;

					this.store.setGoals(
						complement(either(isNil, isEmpty))(x?.goals)
							? goals[0]
							: ({
									adviceProcessId,
									shortTermGoals: [],
									longTermGoals: [],
									retirements: [],
									dangerousPastimes: [],
							  } as GoalsState)
					);

					this.store.setCriticalIllness(
						complement(either(isNil, isEmpty))(x?.criticalIllness)
							? criticalIllness
							: []
					);

					this.store.setRiskProfile(
						complement(either(isNil, isEmpty))(riskProfiles)
							? riskProfiles[0]
							: ({
									adviceProcessId,
									insurancePreferences: [],
									event: [],
							  } as RiskProfileState)
					);

					this.disabilityStore.set(disability);
					this.lifeStore.set(life);
					this.medicalStore.set(medicals);
					this.tpdStore.set(tPDs);
				})
			);
	}

	getCRTPeopleInfo(cRTId: number) {
		const peopleList = this.query.getValue().people || [];
		return peopleList.find((item) => +item.cRTId === +cRTId);
	}

	getLostAfterTaxIncomePerMonth(cRTId: number) {
		// Lost After Tax Income = Total Gross Income (Excluding Net Rental & Other Income)
		const getCrtInfo = this.getCRTPeopleInfo(cRTId);
		if (getCrtInfo?.customerId) {
			const totalGross = +this.getTotalGrossIncomeAnnual(cRTId);
			const taxAcc = +this.getTaxACCAnnual(cRTId);
			const monthlyAfterTax = +divide(subtract(totalGross, taxAcc), 12);
			return this.formatCurrency(monthlyAfterTax);
		}
		return 0;
	}

	formatCurrency(amount: number) {
		const result = !amount || isNaN(+amount) || !isFinite(amount) ? 0 : +amount;
		return +numUtil.formatToNumCurrency(result);
	}

	getFamilyIncome(yearsShortfallCovered, annualSurplusShortfallStopStart) {
		// Family Income = yearsShortfallCovered * (negative value of) annualSurplusShortfallStopStart
		const surplusShortFall = negate(annualSurplusShortfallStopStart) || 0;

		let result = multiply(yearsShortfallCovered || 0, +surplusShortFall || 0);
		result = result < 0 ? 0 : +result;

		return +numUtil.formatToNumCurrency(result);
	}

	getAnnualTaxableIndv(factFind: FactFindComputationState, annualGrossIncome) {
		// Individual / Per person
		const annualTaxable = sum([
			annualGrossIncome || 0,
			(factFind && factFind.totalNetRentalIncome) || 0,
			(factFind && factFind.totalAnnualIncome) || 0,
		]);
		return this.formatCurrency(annualTaxable);
	}

	getMonthlyTaxableIndv(factFind: FactFindComputationState, annualGrossIncome) {
		// Individual / Per person
		const annualTaxable = this.getAnnualTaxableIndv(
			factFind,
			annualGrossIncome
		);
		return this.formatCurrency(divide(+annualTaxable, 12));
	}

	getTaxACCMonthly(cRTId: number) {
		// TAX + ACC (Monthly)
		const total = 0;
		const getCrtInfo = this.getCRTPeopleInfo(cRTId);
		if (getCrtInfo?.customerId) {
			const income =
				this.query
					.getValue()
					.incomeSource?.filter(
						(i) => +i.incomeEarner === +getCrtInfo.customerId
					) || [];

			const result = income.reduce(
				(sum, { totalGrossIncome }) =>
					sum + convertToMonthly(getTaxAcc(totalGrossIncome)),
				0
			);
			return this.formatCurrency(result);
		}
		return total;
	}

	getTaxACCAnnual(cRTId: number) {
		// TAX + ACC (Annual)
		const getCrtInfo = this.getCRTPeopleInfo(cRTId);
		if (getCrtInfo?.customerId) {
			const income =
				this.query
					.getValue()
					.incomeSource?.filter(
						(i) => +i.incomeEarner === +getCrtInfo.customerId
					) || [];

			const result = income.reduce(
				(sum, { totalGrossIncome }) => sum + getTaxAcc(totalGrossIncome),
				0
			);
			return this.formatCurrency(result);
		}
		return 0;
	}

	getTotalGrossIncomeAnnual(cRTId: number) {
		// Get total gross income of a person in annual
		const getCrtInfo = this.getCRTPeopleInfo(cRTId);
		if (getCrtInfo?.customerId) {
			const income =
				this.query
					.getValue()
					.incomeSource?.filter(
						(i) => +i.incomeEarner === +getCrtInfo.customerId
					) || [];

			const result = income.reduce(
				(sum, { totalGrossIncome }) => sum + totalGrossIncome,
				0
			);
			return this.formatCurrency(result);
		}
		return 0;
	}

	getTotalGrossIncomeMonthly(cRTId: number) {
		// Get total gross income of a person in annual in monthly
		const getCrtInfo = this.getCRTPeopleInfo(cRTId);
		if (getCrtInfo?.customerId) {
			const income =
				this.query
					.getValue()
					.incomeSource?.filter(
						(i) => +i.incomeEarner === +getCrtInfo.customerId
					) || [];

			const totalGrossAnnual = income.reduce(
				(sum, { totalGrossIncome }) => sum + totalGrossIncome,
				0
			);
			const totalGrossMonthly = +divide(totalGrossAnnual, 12);
			return this.formatCurrency(totalGrossMonthly);
		}
		return 0;
	}

	getMonthlySurplusOrShortfallLost(cRTId: number) {
		// Monthly Surplus / (Shortfall) - Lost = Unallocated Monthly Income - Lost After Tax Income per month
		const lostAfterTaxIncome = this.getLostAfterTaxIncomePerMonth(cRTId);
		const unallocatedMonthly =
			this.query.getValue().monthlyExpense?.unallocatedMonthlyIncome || 0;

		return this.formatCurrency(
			subtract(+unallocatedMonthly, lostAfterTaxIncome)
		);
	}

	getShortfallOfPreTaxIncome(
		id: number,
		monthlySurplusShortfallStopOrStart: number
	) {
		// If Monthly Surplus / (Shortfall) is negative (shortfall) then:
		// Shortfall % of Pre Tax Income = - (A / B)
		let result = 0;
		if (monthlySurplusShortfallStopOrStart < 0) {
			// Total Gross Income in Annual
			const totalGrossIncome = this.getTotalGrossIncomeAnnual(id);
			// Get Monthly Surplus in Annual
			const monthlySurplusAnnual = monthlySurplusShortfallStopOrStart * 12;

			result = +negate(divide(monthlySurplusAnnual, totalGrossIncome));
			result = multiply(result, 100);
		}
		return this.formatCurrency(result);
	}

	getMonthlyIncomeCoverRequired(id: number, percent: number) {
		// Monthly Income Cover Required = Percentage of Montht Income Cover * Total Indv Gross Income
		const totalGrossIncome = this.getTotalGrossIncomeMonthly(id);
		const percentage = divide(percent, 100);
		const result = multiply(percentage, totalGrossIncome);

		return this.formatCurrency(result);
	}

	getPercentagePreTaxIncomeCover(id: number, monthlyCover: number) {
		const totalGrossIncome = this.getTotalGrossIncomeMonthly(id);
		const pretax =
			+totalGrossIncome === 0 ? 0 : divide(monthlyCover, totalGrossIncome);
		const result = pretax === 0 ? 0 : multiply(pretax, 100);

		return this.formatCurrency(result);
	}

	getLivingOffSavings(id: number, monthlySurplusShortfallStopOrStart: number) {
		// if Monthly Surplus / (Shortfall) is negative (shortfall) then:
		// Living off Savings = ((assetValue / -(monthlySurplusShortfallStopOrStart)) / 12 ) * 365
		const getCrtInfo = this.getCRTPeopleInfo(id);

		if (getCrtInfo?.customerId && monthlySurplusShortfallStopOrStart < 0) {
			const assets =
				this.query
					.getValue()
					.assets?.filter(
						(i) =>
							+i.owner.includes(getCrtInfo?.customerId.toString()) &&
							i.asset === 'Savings'
					) || [];
			const assetValue = assets.reduce((sum, { value }) => sum + +value, 0);

			const result = multiply(
				divide(
					divide(assetValue, negate(monthlySurplusShortfallStopOrStart)),
					12
				),
				365
			);
			return this.formatCurrency(result);
		}
		return 'N/A';
	}

	getPeriodAmount(id: number, period: number) {
		// Period of cover/partner off = Period * Total Indv Gross Income (Monthly)
		const totalGrossIncome = this.getTotalGrossIncomeMonthly(id);
		const result = multiply(+period, +totalGrossIncome);
		return this.formatCurrency(result);
	}

	getKiwisaverPaidOut(cRTId) {
		let total = 0;
		const getCrtInfo = this.getCRTPeopleInfo(cRTId);

		if (getCrtInfo?.customerId) {
			const kiwisaver =
				this.query
					.getValue()
					.kiwiSavers?.map((x) => ({
						...x,
						fundOwner: x?.fundOwner?.map((k) => +k),
					}))
					?.filter((i) => i?.fundOwner?.includes(+getCrtInfo.customerId)) || [];

			total = kiwisaver.reduce(
				(sum, { currentBalance }) => sum + currentBalance,
				0
			);
			return this.formatCurrency(total);
		}
		return total;
	}

	getCoverRequired(
		totalRequired,
		totalProperties,
		totalAssets,
		totalKiwiSaver
	) {
		// Life Cover Required = A - B
		// A = Total Required
		// B = (less Net Property Sales + less Kiwisaver Paid Out + less Asset Sales)
		const totalPropAssetKiwi = sum([
			totalProperties || 0,
			totalAssets || 0,
			totalKiwiSaver || 0,
		]);

		const total = +subtract(totalRequired || 0, totalPropAssetKiwi || 0);

		if (total < 0) {
			return 0;
		}
		return total;
	}

	getTotalLoanValue(lifeLoans, mortgageList, liabilityList) {
		// Total Loan Value is sum of all = Loan Repayment % * Monthly Loan Repayment
		const loansList = [...(mortgageList || []), ...(liabilityList || [])];
		const getFrequency = (cRTId) => {
			const data = loansList?.find((x) => +x?.cRTId === +cRTId);
			return data?.mortgageFrequency;
		};

		const total = lifeLoans
			?.filter(({ repay }) => repay)
			.reduce((sum, loan) => {
				const loanFrequency = getFrequency(+loan?.cRTId) || 'Monthly';
				const loanRepayValue =
					convertToMonthly(+loan.loanRepayment, loanFrequency, true) || 0;
				return (
					sum +
					(+loanRepayValue * parseFloat(loan.repay?.replace('%', ''))) / 100
				);
			}, 0);

		return +this.formatCurrency(total);
	}

	getPeopleTabs(showDependants: boolean): Observable<PeopleState[]> {
		this.currentPersonIndex$.next(null);

		this.currentTabs$ = this.people$.pipe(
			withLatestFrom(this.currentPersonIndex$),
			tap(([people, index]) => {
				if (index === null || this.activePerson$.getValue() === null) {
					this.selectedPerson$.next(people[0]?.cRTId);
					this.activePerson$.next(people[0]);
					this.currentPersonIndex$.next(0);
				}
			}),
			map(([people]) => {
				if (showDependants) {
					return people;
				}

				return people.filter((person) => !!person.cRTId);
			})
		);

		return this.currentTabs$;
	}

	nextPersonTab(): Observable<boolean> {
		return this.hasNextPersonTab().pipe(
			withLatestFrom(this.currentPersonIndex$),
			concatMap(([hasNext, index]) => {
				return this.setCurrentPersonIndex(++index, hasNext);
			})
		);
	}

	previousPersonTab(): Observable<boolean> {
		return this.hasPreviousPersonTab().pipe(
			withLatestFrom(this.currentPersonIndex$),
			concatMap(([hasPrevious, index]) => {
				return this.setCurrentPersonIndex(--index, hasPrevious);
			})
		);
	}

	hasNextPersonTab(): Observable<boolean> {
		return this.currentTabs$.pipe(
			withLatestFrom(this.selectedPerson$),
			take(1),
			map(([currentTabs, id]) => {
				const index = currentTabs.findIndex((x) => x.cRTId === id);
				const nextIndex = index + 1;

				return currentTabs.length > nextIndex;
			})
		);
	}

	hasPreviousPersonTab() {
		return this.currentTabs$.pipe(
			withLatestFrom(this.selectedPerson$),
			take(1),
			map(([currentTabs, id]) => {
				const index = currentTabs.findIndex((x) => x.cRTId === id);
				const previousIndex = index - 1;

				return previousIndex >= 0;
			})
		);
	}

	switchPersonTab(clickedNext: boolean) {
		return of(clickedNext).pipe(
			mergeMap(() => {
				return iif(
					() => clickedNext,
					this.nextPersonTab(),
					this.previousPersonTab()
				);
			})
		);
	}

	setCurrentPersonIndex(index, switchPerson: boolean) {
		this.currentPersonIndex$.next(index--);
		return this.currentPersonIndex$.pipe(
			take(1),
			withLatestFrom(this.currentTabs$),
			tap(([i, people]) => {
				if (switchPerson) {
					this.activePerson$.next(people?.[i]);
					this.selectedPerson$.next(people[i]?.cRTId);
				}
			}),
			map(() => switchPerson)
		);
	}
}
