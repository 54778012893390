import { appNotePreview } from './app-notes.template';
import { MergeTagTypeCode } from '../../../merge-tags.model';

export const appNotesMetaKey = {
	notesPurpose: 'APPLICATION_NOTES_PURPOSE',
	notesRecommendation: 'APPLICATION_NOTES_RECOMMENDATION',
	notesBackground: 'APPLICATION_NOTES_BACKGROUND',
	notesOtherInfo: 'APPLICATION_NOTES_OTHER_INFO',
};

export const appNotesMergeTag = [
	{
		metaKey: appNotesMetaKey.notesPurpose,
		description: 'Application > Notes > Purpose',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: appNotePreview.notes,
	},
	{
		metaKey: appNotesMetaKey.notesBackground,
		description: 'Application > Notes > Background',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: appNotePreview.notes,
	},
	{
		metaKey: appNotesMetaKey.notesRecommendation,
		description: 'Application > Notes > Recommendation',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: appNotePreview.notes,
	},
	{
		metaKey: appNotesMetaKey.notesOtherInfo,
		description: 'Application > Notes > Other Information',
		value: '',
		type: MergeTagTypeCode.text,
		secondaryValue: appNotePreview.notes,
	},
];
