import {
	Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged, finalize, of, Subject, Subscription, switchMap, takeUntil, tap } from 'rxjs';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ThemeConfig } from '@domain/business-config/theme-config.model';
import { CrmRoutingModule } from '@modules/crm/crm-routing.module';
import { TooltipV2Directive } from '@shared/directive/tooltip/tooltip-v2.directive';
import { ManualLoaderMiniModule } from '@shared/loaders/manual-loader-mini/manual-loader-mini.module';
import { SearchResultItemComponent } from './search-result-item/search-result-item.component';
import { TagType, Tag } from './global-search.types';
import { TAGS, TYPING_TIMER_TIMEOUT } from './global-search.constants';
import { GlobalSearchService } from './global-search.service';
import { GlobalSearchQuery } from './global-search.query';

@Component({
	selector: 'global-search',
	templateUrl: './global-search.component.html',
	styleUrls: ['./global-search.component.scss'],
  imports: [
    CommonModule, 
    FormsModule,
    CrmRoutingModule, 
    MatIconModule,
    SearchResultItemComponent,
    TooltipV2Directive,
    ManualLoaderMiniModule
	],
  standalone: true,
  providers: [
    GlobalSearchService, 
    GlobalSearchQuery,
  ]
})
export class GlobalSearchComponent implements OnInit, OnDestroy{
  @ViewChild('searchInput') searchInput: ElementRef;
	@ViewChild('searchWrapper') searchWrapper: ElementRef;
	@ViewChildren('resultItem') resultItems: QueryList<SearchResultItemComponent>;

  private isShownSubj = new BehaviorSubject(true);
	private isLoadingSubj = new BehaviorSubject(false);
  private themeConfig$ = this.businessConfigQuery.themeConfig$;
  private storeMostRecent$ = this.service.storeMostRecent();
	public readonly isShown$ = this.isShownSubj.asObservable();
	public readonly isLoading$ = this.isLoadingSubj.asObservable();
  public readonly data$ = this.service.searchResult$;
  public readonly noMatchMessage$ = this.service.noResultMessage$;
  onDestroy$ = new Subject<void>();
  selectedItem: number| null = null;

  isModal = false;
  selectedTag: TagType | null = null;
  isFocused: boolean = true;
  isShown: boolean = false;
	keyword = '';
  showMostRecentCount: number;

  typingTimer: ReturnType<typeof setTimeout>;
  searchApiCall: Subscription | undefined = undefined;

  tags:Array<Tag> = TAGS;
  primaryColor: string;

  onClose: () => void;

  get searchWrapperEl() {
		return this.searchWrapper?.nativeElement;
	}

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEventkeydown(event: KeyboardEvent) { 
    // exclude from preventDefault()
    if(event.key === 'Escape') {
      if(this.selectedItem !== null) {
        this.selectedItem = null;
        this.unHoverAllItems();
      } else {
        this.onClose();
      }
    }

    if (event.defaultPrevented) {
      return;
    }
    switch (event.key) {
      case "ArrowDown": {
        if(this.selectedItem === null) {
          this.selectedItem = 0;
        } else {
          this.selectedItem += 1;
        }
        this.unHoverAllItems();
        const hoveredItem = this.resultItems.toArray()[this.selectedItem];
        hoveredItem.hoverItem()
        hoveredItem.scrollIntoView();
      } break;
      case "ArrowUp": {
        if(this.selectedItem) {
          this.selectedItem -= 1;
        } else {
          this.selectedItem = 0;
        }
        this.unHoverAllItems();
        const hoveredItem = this.resultItems.toArray()[this.selectedItem];
        hoveredItem.hoverItem()
        hoveredItem.scrollIntoView();
      } break;
      case "Enter": {
        if(this.selectedItem !== null) {
          const hoveredItem = this.resultItems.toArray()[this.selectedItem];
          hoveredItem.click();
        }
      } break;
      default:
        return;
    }
  }

  constructor(
    private businessConfigQuery: BusinessConfigQuery,
    private service: GlobalSearchService
  ) {}

  ngOnInit(): void {
    if(this.isFocused) {
      setTimeout(() => {
        this.searchInput?.nativeElement.focus();
      }, 0);
    }
    this.themeConfig$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => this.setThemeConfig(x));
    
    this.service.fetchMostRecent(this.showMostRecentCount).subscribe();
    
  }

  ngOnDestroy(): void {
    this.storeMostRecent$.subscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onEnter(keyword: string) {
		this.setFocusInput();
		const searchInput = this.searchInput?.nativeElement;
		if (!searchInput?.value) {
			return;
		}
    this.search(keyword, this.selectedTag);
	}

  onSearchKeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.search(this.keyword, this.selectedTag), TYPING_TIMER_TIMEOUT);
  }

  onSearchKeypress() {
    clearTimeout(this.typingTimer);
  }

  onFocus(event?) {
		this.isFocused = !!event;
	}

  setFocusInput(f: boolean = true) {
    this.isFocused = f;
    if(f) {
      this.searchInput?.nativeElement?.focus();
    } else {
      this.searchInput?.nativeElement?.blur();
    }
	}

  onTagSelect(tag: Tag) {
    if(tag.disabled) {
      return;
    }
    this.selectedTag = tag.type === this.selectedTag ? null : tag.type;
    this.search(this.keyword, this.selectedTag);
  }

  blurSearch() {
		if (this.searchInput?.nativeElement?.value) {
			this.searchInput.nativeElement.value = '';
		}
		this.searchWrapperEl?.classList?.remove('active');
		this.isFocused = false;
	}

  trackByFn = (index: number, item: any) => {
    return index;
  };

  private setThemeConfig(theme: ThemeConfig) {
		this.primaryColor = theme?.primarycolor || '#00263e';
	}

  private search(k: string, f?: string) {
    this.searchApiCall && this.searchApiCall.unsubscribe();
    this.searchApiCall = of(k)
			.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$),
				tap(() => {
          this.isLoadingSubj.next(true);
          this.isShownSubj.next(false);
        }),
				switchMap((x) => this.service.search(x?.trim(), f)),
				finalize(() => {
          this.isShownSubj.next(true);
					this.isLoadingSubj.next(false);
				})
			)
			.subscribe({
        error: (err) => {
          throw new Error(err)
        }
      }
    );
  }

  private unHoverAllItems() {
    this.resultItems.toArray().forEach(item => item.hoverItem(false));
    this.setFocusInput(false);
  }
}