import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { LinkedContact } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { isNil } from 'ramda';
import { forkJoin, from, Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	finalize,
	mapTo,
	mergeMap,
	tap,
} from 'rxjs/operators';
import { EmailTypes } from 'src/app/shared/models/emails/crt/email.model';
import { DropdownValueService } from '../../../domain/dropdown-value/dropdown-value.service';
import { UserQuery } from '../../../domain/user/user.query';
import { PrimaryClient } from '../../../shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClient } from '../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrust } from '../../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusiness } from '../../../shared/models/client-profile/seondary-business/secondary-business.model';
import { CRTNoteState } from '../../../shared/models/client-review-template/note/crt-note.model';
import { AuthorityToProceedSettingsService } from '../../crt-settings/authority-to-proceed-settings/state/authority-to-proceed-settings.service';
import { FinalStructureSettingsService } from '../../crt-settings/final-structure-settings/state/final-structure-settings.service';
import { SettingsTypes } from '../../crt-settings/state/crt-settings.model';
import { ReferralTypes } from '../../referral-options/state/referral-options.model';
import { ClientProfileService } from '../client-profile/states/client-profile.service';
import { AssetsLiabilitiesService } from './states/assets-liabilities/assets-liabilities.service';
import { ClientAcceptanceService } from './states/client-acceptance/client-acceptance.service';
import { ClientReviewTemplateQuery } from './states/client-review-template.query';
import { ClientReviewTemplateService } from './states/client-review-template.service';
import { CurrentInsuranceService } from './states/current-insurance/current-insurance.service';
import { DeclarationService } from './states/declaration/declaration.service';
import { DisclosureService } from './states/disclosure/disclosure.service';
import { FgService } from './states/fg-insurance/fg.service';
import { FinalStructureService } from './states/final-structure/final-structure.service';
import { HistoryService } from './states/history/history.service';
import { IncomeService } from './states/income-budget/income.service';
import { IntroService } from './states/intro/intro.service';
import { CrtMergeTagsService } from './states/merge-tags/crt-merge-tags.service';
import { CrtNoteQuery } from './states/note/crt-note.query';
import { CrtNoteService } from './states/note/crt-note.service';
import { PeopleService } from './states/people/people.service';
import { CriticalIllnessService } from './states/risk-analysis/critical-illness/critical-illness.service';
import { DisabilityService } from './states/risk-analysis/disability/disability.service';
import { GoalsService } from './states/risk-analysis/goals/goals.service';
import { LifeService } from './states/risk-analysis/life/life.service';
import { RiskAnalysisMedicalService } from './states/risk-analysis/medical/medical.service';
import { RiskAnalysisService } from './states/risk-analysis/risk-analysis.service';
import { RiskProfileService } from './states/risk-analysis/risk-profile/risk-profile.service';
import { TpdService } from './states/risk-analysis/tpd/tpd.service';
import { ScopeOfServiceService } from './states/scope-of-service/scope-of-service.service';
import { StatementOfAdviceService } from './states/statement-of-advice/statement-of-advice.service';

const dropdownCodes: string[] = [
	'SCR',
	'PCE',
	'SCTT',
	'LRP',
	'LRPR',
	'APCRTVD',
	'APCRTG',
	'APCRTBP',
	'APCRTYN',
	'APCRTYNNA',
	'APCRTF',
	'APCRTFM',
	'APCRTKC',
	'APCRTTC',
	'APCRTI',
	'APCRTIST',
	'APCRTIT',
	'APCRTTM',
	'APCRTCLC',
	'APCRTVP',
	'APCRTKPY',
	'APCRTSI',
	'FGI',
	'SU',
	'SV1',
	'STI',
	'ST',
	'PCLT',
	'KP',
	'MP',
	'MLT',
	'MLP',
	'APCRTRP',
	'APCRTL',
	'APCRTA',
	'APCRTE',
	'APCRTESI',
	'APCRTIID',
	'APCRTLTCR',
	'APCRTDIP',
	'APCRTR',
	'APCRTGL',
	'APCRTDP',
	'APCRTTRC',
	'APCRTEC',
	'APCRTIP',
	'APCRTP',
	'KFT',
	'APCRTME',
	'AT',
	'APCRTCC',
	'APCRTB',
	'APCRTNE',
	'APCRTTIR',
	'APCRTMS',
	'APCRTHO',
	'APCRTWO',
	'APCRTFSCCO',
	'APCRTPWP',
];
@Injectable()
export class ClientReviewTemplateResolver implements Resolve<boolean> {
	constructor(
		private dropdownValueService: DropdownValueService,
		private crtservice: ClientReviewTemplateService,
		private crtNoteService: CrtNoteService,
		private crtNoteQuery: CrtNoteQuery,
		private peopleService: PeopleService,
		private alService: AssetsLiabilitiesService,
		private incomeService: IncomeService,
		private historyService: HistoryService,
		private fgService: FgService,
		private ciService: CurrentInsuranceService,
		private goalService: GoalsService,
		private disabilityService: DisabilityService,
		private lifeService: LifeService,
		private criticalService: CriticalIllnessService,
		private riskProfileService: RiskProfileService,
		private scopeService: ScopeOfServiceService,
		private soaService: StatementOfAdviceService,
		private disclosureService: DisclosureService,
		private declarationService: DeclarationService,

		private introService: IntroService,
		private tpdService: TpdService,
		private medicalService: RiskAnalysisMedicalService,
		private caService: ClientAcceptanceService,
		private fsService: FinalStructureService,
		private mtService: CrtMergeTagsService,
		private fsCrtSettingsService: FinalStructureSettingsService,
		private userQuery: UserQuery,
		private apService: AuthorityToProceedSettingsService,
		private query: ClientReviewTemplateQuery,
		private riskAnalysisService: RiskAnalysisService,
		private clientProfileService: ClientProfileService
	) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		// Clear all stored data
		this.crtservice.clear();
		this.riskAnalysisService.clearData();
		this.lifeService.clearData();
		this.tpdService.clearData();
		this.disabilityService.clearData();
		this.medicalService.clearData();
		this.crtNoteService.clearData();
		this.soaService.clearData();

		const dropdowns$ =
			this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		const adviceProcessId = parseInt(route.paramMap.get('adviceProcessId'), 10);
		const clientId = parseInt(route.paramMap.get('clientId'), 10);
		const isCompany =
			route.paramMap.get('customerType')?.toLowerCase() !== 'client';

		// let primaryClient$: Observable<PrimaryClient>;
		// let secondaryClients$: Observable<SecondaryClient[]>;
		// let secondaryTrust$: Observable<SecondaryTrust[]>;
		// let secondaryCompanies$: Observable<SecondaryBusiness[]>;
		// let crtNotesFactFind$: Observable<CRTNoteState[]>;
		let linkedContacts$: Observable<LinkedContact[]>;

		let loadCrtSections$: Observable<any>;
		this.crtservice.setIsCompany(isCompany);

		if (adviceProcessId) {
			this.crtservice.setAdviceProcessId(adviceProcessId);

			// primaryClient$ = this.crtservice
			// 	.getPrimaryClient(clientId)
			// 	.pipe(catchError(() => of(undefined)));
			// secondaryClients$ = this.crtservice
			// 	.getSecondaryClients(clientId)
			// 	.pipe(catchError(() => of([])));
			// secondaryTrust$ = this.crtservice
			// 	.getSecondaryTrusts(clientId)
			// 	.pipe(catchError(() => of([])));
			// secondaryCompanies$ = this.crtservice
			// 	.getSecondaryCompanies(clientId)
			// 	.pipe(catchError(() => of([])));
			// linkedContacts$ = this.crtservice
			// 	.getLinkedContacts(clientId)
			// 	.pipe(catchError(() => of([])));

			// CRT Notes for Fact and Find
			// crtNotesFactFind$ = this.crtNoteService
			// 	.getNotes(
			// 		adviceProcessId,
			// 		this.crtNoteQuery.getValue().crtNoteTypes.map((x) => x.value)
			// 	)
			// 	.pipe(catchError(() => of(undefined)));

			// Disclosure Document
			const getDisclosureDocument$ = isNil(
				this.disclosureService.snapshot.disclosureDocument
			)
				? this.disclosureService.getDisclosureDocument(+adviceProcessId)
				: of(undefined);

			const getIntro$ = isNil(this.introService.snapshot.intro)
				? this.introService.getIntro(0)
				: of(undefined);

			const getDeclarationDocument$ = isNil(
				this.declarationService.snapshot.declarationDocument
			)
				? this.declarationService.getDeclarationDocument(
						0,
						SettingsTypes.Declaration
				  )
				: of(undefined);

			const getDeclarationEmailSettings$ = isNil(
				this.declarationService.snapshot.declarationEmailSettings
			)
				? this.declarationService.getDeclarationEmailSettings(
						0,
						EmailTypes.Declaration
				  )
				: of(undefined);

			const getReviewApplicationEmailSettings$ = isNil(
				this.declarationService.snapshot.declarationEmailSettings
			)
				? this.declarationService.getDeclarationEmailSettings(
						0,
						EmailTypes.Declaration
				  )
				: of(undefined);

			const getDeclaration$ = isNil(
				this.declarationService.snapshot.declaration
			)
				? this.declarationService.getDeclaration(adviceProcessId)
				: of(undefined);

			// Scope Of Service
			const getScopeOfService$ = isNil(
				this.scopeService.snapshot.scopeOfService
			)
				? this.scopeService.getScopeOfService(adviceProcessId, 'SOS')
				: of(undefined);

			const getSosDefault$ = isNil(this.scopeService.snapshot.sosDefault)
				? this.scopeService.getSosDefault()
				: of(undefined);

			const incomeAndExpenses$ =
				this.incomeService.getIncomeData(adviceProcessId);

			// this.incomeService.getIncomeSources(adviceProcessId, 'FII').subscribe()
			// this.incomeService.getRentalIncome(adviceProcessId, 'FIR').subscribe()
			// this.incomeService.getOtherIncome(adviceProcessId, 'FIO').subscribe()
			// this.incomeService.getMonthlyExpense(adviceProcessId, 'FIM').subscribe()

			// const getIncomeSources$ = isNil(this.incomeService.snapshot.incomeSource)
			// 	? this.incomeService.getIncomeSources(adviceProcessId, 'FII')
			// 	: of(undefined);

			// const getRentalIncome$ = isNil(this.incomeService.snapshot.rentalDetails)
			// 	? this.incomeService.getRentalIncome(adviceProcessId, 'FIR')
			// 	: of(undefined);

			// const getOtherIncome$ = isNil(
			// 	this.incomeService.snapshot.otherIncomeDetails
			// )
			// 	? this.incomeService.getOtherIncome(adviceProcessId, 'FIO')
			// 	: of(undefined);

			// const getMonthlyExpense$ = isNil(
			// 	this.incomeService.snapshot.monthlyExpense
			// )
			// 	? this.incomeService.getMonthlyExpense(adviceProcessId, 'FIM')
			// 	: of(undefined);

			// Fact Find - F&G
			// const getHouseInsurance$ = isNil(this.fgService.snapshot.houseInsurance)
			// 	? this.fgService.getHouseInsurance(adviceProcessId, 'FFGHI')
			// 	: of(undefined);

			// const getCarInsurance$ = isNil(this.fgService.snapshot.carInsurance)
			// 	? this.fgService.getCarInsurance(adviceProcessId, 'FFGCI')
			// 	: of(undefined);

			// const getContentInsurance$ = isNil(
			// 	this.fgService.snapshot.contentInsurance
			// )
			// 	? this.fgService.getContentInsurance(adviceProcessId, 'FFGCOI')
			// 	: of(undefined);

			// check weather FG data is already fetched
			const fetchFGData =
				isNil(this.fgService.snapshot.houseInsurance) &&
				isNil(this.fgService.snapshot.carInsurance) &&
				isNil(this.fgService.snapshot.contentInsurance);

			const getFGData$ = fetchFGData ? this.fgService.getFGData(adviceProcessId) : of(undefined);

			const getBlanketQuote$ = isNil(this.fgService.snapshot.blanketQuote)
				? this.fgService.getBlanketQuote(0, 'BQ')
				: of(undefined);

			// const getBlanketOptions$ = isNil(this.fgService.snapshot.blanketOptions)
			// 	? this.fgService.getBlanketOptions(0, ReferralTypes.Blanket)
			// 	: of([]);

			// Risk Analysis - Goals
			const getGoals$ = isNil(this.goalService.snapshot.goals)
				? this.goalService.getGoals(adviceProcessId, 'RAG')
				: of(undefined);

			// Authority to Proceed
			const getAuthorityToProceedSettings$ =
				this.apService.getAuthorityToProceed(0, 'ATP');

			const getFinalStructureSettings$ =
				this.fsCrtSettingsService.getFinalStructure(0, 'FS');

			loadCrtSections$ = from([
				// getDisclosureCrtSettings$,
				getFinalStructureSettings$,

				getAuthorityToProceedSettings$,

				getDisclosureDocument$,
				getIntro$,
				getDeclaration$,
				getDeclarationDocument$,
				getDeclarationEmailSettings$,

				// Scope of Service
				getSosDefault$,
				// getScopeOfService$,

				// Fact Find - People
				// this.peopleService.getPeople(adviceProcessId, 'FPP'),
				// this.peopleService.getDependents(adviceProcessId, 'FPD'),
				// this.peopleService.getTrusts(adviceProcessId, 'FPT'),
				// this.peopleService.getCompany(adviceProcessId, 'FPC'),
				this.peopleService.getPeopleEntities(adviceProcessId),

				// Fact Find - Assets and Liabilities
				this.alService.getAssetLiabilities(adviceProcessId, 'ae').pipe(
					finalize(() => {
						this.alService.setIsLoading(false, 'FAP');
						this.alService.setIsLoading(false, 'FAK');
						this.alService.setIsLoading(false, 'FAA');
						this.alService.setIsLoading(false, 'FAM');
						this.alService.setIsLoading(false, 'FAL');
						this.alService.setIsLoading(false, 'FAO');
					})
				),
				// this.alService
				// 	.getAssetLiabilities(adviceProcessId, 'FAP')
				// 	.pipe(finalize(() => this.alService.setIsLoading(false, 'FAP'))),
				// this.alService
				// 	.getAssetLiabilities(adviceProcessId, 'FAK')
				// 	.pipe(finalize(() => this.alService.setIsLoading(false, 'FAK'))),
				// this.alService
				// 	.getAssetLiabilities(adviceProcessId, 'FAA')
				// 	.pipe(finalize(() => this.alService.setIsLoading(false, 'FAA'))),
				// this.alService
				// 	.getAssetLiabilities(adviceProcessId, 'FAM')
				// 	.pipe(finalize(() => this.alService.setIsLoading(false, 'FAM'))),
				// this.alService
				// 	.getAssetLiabilities(adviceProcessId, 'FAL')
				// 	.pipe(finalize(() => this.alService.setIsLoading(false, 'FAL'))),
				// this.alService
				// 	.getAssetLiabilities(adviceProcessId, 'FAO')
				// 	.pipe(finalize(() => this.alService.setIsLoading(false, 'FAO'))),

				// Fact Find - Income and budget
				// getIncomeSources$,
				// getRentalIncome$,
				// getOtherIncome$,
				incomeAndExpenses$,

				// getMonthlyExpense$,
				getBlanketQuote$,
				// getBlanketOptions$,

				// Fact Find - Medical History
				this.historyService.getMedicalHistory(adviceProcessId),
				// this.historyService.getBodyMeasures(adviceProcessId),
				// this.historyService.getMedical(adviceProcessId),
				// this.historyService.getFamily(adviceProcessId),
				// this.historyService.getGp(adviceProcessId),
				this.historyService.getProfessionalDoctorContacts(clientId),

				// Fact Find - Current Insurance
				this.ciService.getCurrentInsurance(adviceProcessId, 'FCL'),

				// Fact Find - F&G
				// getHouseInsurance$,
				// getCarInsurance$,
				// getContentInsurance$,
				getFGData$,

				this.riskAnalysisService.getRiskAnalysis(adviceProcessId),

				// // Risk Analysis - Goals
				// getGoals$,

				// // Risk Analysis - Life
				// this.lifeService.getLife(),

				// // Risk Analysis - Disability
				// this.disabilityService.getDisabilities(),

				// // Risk Analysis - TPD
				// this.tpdService.getTpd(),

				// // Risk Analysis - Medical
				// this.medicalService.getMedical(),

				// // Risk Analysis - Critical Illness
				// this.criticalService.getCriticalIllness(adviceProcessId, 'RACI'),

				// // Risk Analysis - Risk Profile
				// this.riskProfileService.getRiskProfile(adviceProcessId, 'RARP'),

				// SOA
				// this.soaService.getSoa(),
				// this.soaService.getDefaultSoaSettings(0, 'SOA'),

				// Client Acceptance
				this.caService.getClientAcceptance(adviceProcessId),
				this.caService.getCASettings(),

				// Final Structure
				this.fsService.getFinalStructure(adviceProcessId),
			]).pipe(mergeMap((x) => x));
		}
		return adviceProcessId
			? forkJoin([
					dropdowns$,
					this.soaService.getAdviceProcess(adviceProcessId),

					// Get Profiles
					// primaryClient$,
					// secondaryClients$,
					// secondaryTrust$,
					// secondaryCompanies$,
					// linkedContacts$,
					this.crtservice.getContacts(+clientId),
					// Merge Tags
					this.mtService.getDefaultMergeTags(),

					this.alService.getPropertyAddresses$(+clientId),
					this.alService.getCrmKiwiSavers$(+clientId),
					this.alService.getCrmMortgages$(+clientId),
					this.alService.getCrmLiabilities$(+clientId),

					// Get CRT Notes
					// crtNotesFactFind$,

					// Prepopulate CRT Fact Find
					// prepopulateCRT$(adviceProcessId),

					this.scopeService.getSosDefault(),
					this.scopeService.getScopeOfService(adviceProcessId, 'SOS'),

					this.soaService.getDefaultSoaSettings(0, 'SOA'),
					this.soaService.getSoa(),
					this.clientProfileService.getClientDocuments(clientId),
			  ]).pipe(
					delay(0),
					concatMap(() =>
						this.mtService.getAdviserProviderMt(
							this.query.getValue().adviceProcess?.adviser
						)
					),
					finalize(() => {
						loadCrtSections$.subscribe();
						setTimeout(() => this.crtservice.dataIsResolved$.next(true));
					}),
					mapTo(true)
			  )
			: forkJoin([dropdowns$]).pipe(mapTo(true));
	}
}
