import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { objectUtil } from 'src/app/util/util';
import { ApiService } from '../../../../../core/base/api.service';
import {
	ReloadDocumentType,
	SettingsTypes,
} from '../../../state/mortgage-settings.model';
import { MortgageSettingsQuery } from '../../../state/mortgage-settings.query';
import { MortgageSettingsStore } from '../../../state/mortgage-settings.store';
import { SosTemplateSettingsState } from '../../sos-template-settings/state/sos-template-settings.model';

@Injectable()
export class SosTemplateSettingsService {
	templateSettings$ = this.query.scopeOfServiceTemplateSettings$;

	constructor(
		private api: ApiService,
		private store: MortgageSettingsStore,
		private query: MortgageSettingsQuery
	) {}

	getSettings() {
		const endpoint = `crt/settings/0/${SettingsTypes.MOATSosTemplate}`;
		return this.api.get<SosTemplateSettingsState>(endpoint).pipe(
			tap((res) =>
				applyTransaction(() => {
					const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
					this.store.setScopeOfServiceTemplateSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateSettings(data) {
		const endpoint = `crt/settings/0`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<SosTemplateSettingsState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setScopeOfServiceTemplateSettings(data);
				})
			)
		);
	}

	getDefaultTemplateUrl() {
		const endpoint = `crt/oat-template/${ReloadDocumentType.SosTemplate}`;
		return this.api.get<string>(endpoint);
	}
}
