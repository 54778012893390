<div class="app-backend-sidenav-container d-flex flex-column align-items-start">
	<div class="app-user-container d-flex flex-column align-items-center mt-4">
		<ngx-avatars
			[src]="googleImageUrl"
			[name]="name"
			[round]="true"
			size="72"
		></ngx-avatars>
		<div class="app-user-name pt-4 pr-0 tap-text-color">{{ name }}</div>
	</div>
	<div class="app-backend-sidenav-links-container mt-4">
		<!--BL - Busincess Account Access-->
		<ng-container *appRestrictTo="['BBAA']">
			<div class="app-backend-sidenav-link mb-3">
				<a
					[routerLink]="BusinessLink"
					routerLinkActive="active"
					class="business-account p-0"
				>
					<h6 class="d-flex align-items-center tap-text-primmary">
						<i class="material-icons mr-2">&#xE88A;</i>
						{{ "Business Account" | uppercase }}
					</h6>
				</a>
			</div>
		</ng-container>
		<!--BL - Appearance Access-->
		<ng-container *appRestrictTo="['BAA']">
			<div class="app-backend-sidenav-link mb-3">
				<h6
					class="d-flex align-items-center clickable tap-text-primary"
					(click)="isAppearanceCollapsed = !isAppearanceCollapsed"
				>
					<i class="material-icons mr-2">&#xE3B7;</i> APPEARANCE
				</h6>
				<div
					[ngClass]="{
						'app-backend-sidenav-sublinks d-flex flex-column pl-4':
							!isAppearanceCollapsed
					}"
					[collapse]="isAppearanceCollapsed"
				>
					<!--BL - Change Logo-->
					<ng-container *appRestrictTo="['BCL']">
						<a
							[routerLink]="LogoLink"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }"
							class="pb-2"
							>Images</a
						>
					</ng-container>
					<!--BL - Change Themes-->
					<ng-container *appRestrictTo="['BCT']">
						<a
							[routerLink]="ThemeLink"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }"
							class="pb-2"
							>Theme</a
						>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<!--BL - Users Access-->
		<ng-container *ngIf="!isTapLevelUser && isViewingUserDetailsOnly">
			<div class="app-backend-sidenav-link mb-3">
				<h6
					class="d-flex align-items-center clickable tap-text-primary"
					(click)="isUsersCollapsed = !isUsersCollapsed"
				>
					<i class="material-icons mr-2">&#xE7FB;</i> USERS
				</h6>
				<div
					[ngClass]="{
						'app-backend-sidenav-sublinks d-flex flex-column pl-4':
							!isUsersCollapsed
					}"
					[collapse]="isUsersCollapsed"
				>
					<a
						[routerLink]="UserDetailsLink"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						>User Details</a
					>
				</div>
			</div>
		</ng-container>
		<ng-container *appRestrictTo="['BUA']">
			<div class="app-backend-sidenav-link mb-3">
				<h6
					class="d-flex align-items-center clickable tap-text-primary"
					(click)="isUsersCollapsed = !isUsersCollapsed"
				>
					<i class="material-icons mr-2">&#xE7FB;</i> USERS
				</h6>
				<div
					[ngClass]="{
						'app-backend-sidenav-sublinks d-flex flex-column pl-4':
							!isUsersCollapsed
					}"
					[collapse]="isUsersCollapsed"
				>
					<a
						[routerLink]="UserDetailsLink"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						*ngIf="!isTapLevelUser"
						>User Details</a
					>
					<a
						[routerLink]="UserListLink"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						>Manage Users</a
					>
				</div>
			</div>
		</ng-container>
		<!--BL - Views Access-->
		<ng-container *appRestrictTo="['BVA']">
			<div class="app-backend-sidenav-link mb-3">
				<h6
					class="d-flex align-items-center clickable tap-text-primary"
					(click)="isViewsCollapsed = !isViewsCollapsed"
				>
					<i class="material-icons mr-2">&#xE417;</i> VIEWS
				</h6>
				<div
					[ngClass]="{
						'app-backend-sidenav-sublinks d-flex flex-column pl-4':
							!isViewsCollapsed
					}"
					[collapse]="isViewsCollapsed"
				>
					<a
						[routerLink]="PrimaryCustomerLink"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						>Primary customer</a
					>
					<a
						[routerLink]="SecondaryCustomerLink"
						routerLinkActive="active"
						class="pb-2"
						>Secondary customer</a
					>
					<a [routerLink]="ServicesLink" routerLinkActive="active" class="pb-2"
						>Services</a
					>
					<a
						[routerLink]="ActivitiesLink"
						routerLinkActive="active"
						class="pb-2"
						>Activities</a
					>
				</div>
			</div>
		</ng-container>
		<!--BL - Settings Access-->
		<ng-container *appRestrictTo="['BSA']">
			<div class="app-backend-sidenav-link mb-3">
				<h6
					class="d-flex align-items-center clickable tap-text-primary"
					(click)="isSettingsCollapsed = !isSettingsCollapsed"
				>
					<i class="material-icons mr-2">&#xE8B8;</i> SETTINGS
				</h6>
				<div
					[ngClass]="{
						'app-backend-sidenav-sublinks d-flex flex-column pl-4':
							!isSettingsCollapsed
					}"
					[collapse]="isSettingsCollapsed"
				>
					<a
						*ngIf="isTapLevelUser"
						[routerLink]="SpecialFeatureLink"
						routerLinkActive="active"
						class="pb-2"
						>Special Features</a
					>

					<a
						*appRestrictTo="['BDI']"
						[routerLink]="DataImportLink"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						>Data Import</a
					>

					<a
						*appRestrictTo="['BE']"
						[routerLink]="EmailsLink"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						>Emails</a
					>

					<a
						*appRestrictTo="['BD']"
						[routerLink]="DownloadDocuments"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						>Download Documents</a
					>

					<a
						*appRestrictTo="['BAP']"
						[routerLink]="AdviceProcess"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						>Advice Process - Offline</a
					>

					<a
						*appRestrictTo="['BCRT']"
						[routerLink]="CrtSettings"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						[class.disabled]="!hasService.hasLR || !hasService.hasLOAT"
						>L&R Online Advice Tool</a
					>

					<a
						*appRestrictTo="['BCRT']"
						[routerLink]="MortgageSettings"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						[class.disabled]="!hasService.hasM || !hasService.hasMOAT"
						>Mortgage Online Advice Tool</a
					>

					<a
						*appRestrictTo="['BCRT']"
						[routerLink]="KiwiSaverSettings"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						[class.disabled]="!hasService.hasK || !hasService.hasKOAT"
						>KiwiSaver Online Advice Tool</a
					>

					<a
						*appRestrictTo="['BVCRT']"
						[routerLink]="CPSettings"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }"
						class="pb-2"
						[class.disabled]="!hasService.hasMOAT"
						>Client Portal</a
					>

					<ng-container *ngIf="hasService.hasCAR">
						<a
							*appRestrictTo="['BVCRT']"
							[routerLink]="hasService.hasCAR ? CARSettings : '#'"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }"
							class="pb-2"
							>Client Alteration Request</a
						>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<app-admin-section *ngIf="isTapLevelUser"></app-admin-section>
	</div>
	<div class="align-self-center footer-logo-container mb-3 mt-5">
		<app-logo [src]="tapLevelLogoUrl"></app-logo>
	</div>
</div>
