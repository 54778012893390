import { EmailHistoryModel } from '../../../../modules/emails/email-history/state/email-history.model';

export interface EmailHistory {
  name: string;
  value: string;
}

export interface EmailHistoryResponse {
  EmailHistoryStatuses: EmailHistoryModel[];
  Total: number;
  TotalFailed: number;
  TotalSuccess: number;
}

export interface TableResult {
  total: number;
  success: number;
  failed: number;
}

export class ValueReference {
  name: string;
  value: string;
}

export class Page {
	pageNumber: number;
	size: number;
	totalElements: number;
}
