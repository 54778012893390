import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { concatMap, finalize, take } from 'rxjs/operators';
import { ClientAcceptanceSettingsService } from './state/client-acceptance-settings.service';
import { ClientAcceptanceSettingsMapper } from './state/client-acceptance-settings.mapper';

@Component({
  selector: 'app-client-acceptance-settings',
  templateUrl: './client-acceptance-settings.component.html',
})
export class ClientAcceptanceSettingsComponent implements OnInit {
  isLoading: boolean;
  clientAcceptance$ = this.caSettingsService.clientAcceptance$;

  constructor(private caSettingsService: ClientAcceptanceSettingsService) { }

  ngOnInit(): void {
  }

  save(clientAcceptanceSettings) {
    this.isLoading = true;
    of(clientAcceptanceSettings).pipe(
      concatMap(data => {
        const formValue = ClientAcceptanceSettingsMapper.mapToUpsert({
          ...data,
         });
        return this.caSettingsService.updateFinalStructure(formValue)
      }),
      take(1),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe();
  }

}
