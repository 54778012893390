import { MemberSearch, MemberSearchState, MembersRequest } from './members.model';

export class MemberMapper {
  public static mapToView(members: MemberSearchState[]): MemberSearch[] {
    return members?.map((x) => ({
      CustomerId: x.customerId,
      IsCompany: x.isCompany,
      Name: x.name,
      PrimaryCustomerId: x.primaryCustomerId,
      CustomerType: x.customerType,
      IsAccessible: x.isAccessible,
    }));
  }

  public static mapToUpdate(
    customerId: number,
    directors: MemberSearch[],
    shareholders: MemberSearch[],
    others: MemberSearch[]
  ): MembersRequest & { customerId: number } {
    return {
      customerId,
      directors,
      shareholders,
      others,
    };
  }
}
