import {
  OtherIncomeState,
  OtherIncomeObjState,
} from './other-income.model';
import { FactFindComputationState } from './factfind-computation.model';

export class OtherIncomeMapper {
  /** Mapping for view */
  public static mapOtherIncome(data: OtherIncomeObjState) {
    return data?.otherIncome?.map(
      ({ incomeType, annualIncome, cRTId, adviceProcessId }) => ({
        cRTId,
        adviceProcessId,
        incomeType,
        annualIncome,
        btnSaveOther: false,
        btnEditOther: true,
      })
    );
  }

  public static mapGross(data: OtherIncomeObjState) {
    return +data.totalAnnualIncome;
  }

  /**
   * Map to update
   */
  public static mapToUpsert(
    data: OtherIncomeState,
    cid: number,
    adviceProcessId: number,
    tempFactFind?: FactFindComputationState
  ): OtherIncomeState {
    return {
      ...data,
      cRTId: cid,
      annualIncome: +data.annualIncome,
      adviceProcessId,
      sectionCode: 'FIO',
      factFindComputation: {
        ...tempFactFind,
        // adviceProcessId,
        sectionCode: 'FFC',
      },
    };
  }
}
