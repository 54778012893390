<app-secondary-base-widget
	headerText="Advice Process Tracking"
	[widgetCode]="widgetCode"
	class="eb-conversion"
>
	<app-manual-loader [isLoading]="(isLoading$ | async)"></app-manual-loader>

	<div *ngIf="!(isLoading$ | async)" class="ap-tracking-widget px-4 h-100">
		<div class="row">
			<div [ngClass]="['col-12 mb-3 stats', getStatusColor((customerCount$ | async), (target$ | async)) ]">
				<div class="row justify-content-center">
					<div class="col-auto p-2 info">
						<span>{{ (customerCount$ | async) || 0 }} / {{ (target$ | async) || 0 }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row row-list">
			<div class="col-12 p-0 pt-1 list">
				<app-apt-table [customers]="(customers$ | async)"></app-apt-table>
			</div>
		</div>
		<div class="row">
			<div class="col-12 link">
				<div class="row justify-content-center">
					<div class="col-auto p-2">
						<a [routerLink]="[link]">
							See all
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-secondary-base-widget>
