<form [formGroup]="form" class="form insurance-priority-event">
	<div class="form-row mb-2 label-background">
		<div class="col-7 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary"
				>Insurance Priority based on Event:</label
			>
		</div>
		<div class="col-5 col-lg-9 text-right iconShow">
			<button
				id="collapseInsurancePriorityBtn"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 collapse-background"
			>
				<span *ngIf="elseMinus; else elsePlus">
					<i (click)="collapseMore()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlus>
					<span>
						<i (click)="collapseLess()" class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div class="mb-4">
		Please indicate how high each event registers on the priority scale.
	</div>

	<div formArrayName="detailsList">
		<div class="collapse expandable" id="collapse">
			<!-- People -->
			<div class="form-row align-items-center mb-4">
				<div class="col-lg-4">
					<div class="font-weight-bold">Event</div>
				</div>
				<div class="col-lg-8">
					<div class="form-row align-items-center font-weight-bold">
						<ng-template
							ngFor
							let-item
							[ngForOf]="DetailsList.controls"
							let-i="index"
						>
							<div
								*ngIf="item.get('peopleCRTId')?.value > 0"
								class="col-lg-4 text-center"
							>
								<span
									class="p-1"
									[class]="
										item.invalid && item.touched ? 'ng-invalid ng-touched' : ''
									"
								>
									{{ item.get("personName").value }}
								</span>
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="form-row align-items-center mb-3">
				<div class="col-lg-4">
					{{ eventQuestions.prematureDeath.label }}
				</div>

				<div class="col-lg-8 col-12">
					<div class="form-row align-items-center">
						<ng-template
							ngFor
							let-item
							[ngForOf]="DetailsList.controls"
							let-i="index"
						>
							<div
								*ngIf="item.get('peopleCRTId')?.value > 0"
								class="
									col-12 col-lg-4
									text-center
									d-flex
									flex-row-reverse
									justify-content-center
								"
								[formGroupName]="i"
							>
								<div
									*ngFor="let d of APCRTP$"
									class="
										custom-control custom-radio custom-control-inline
										box
										mr-1
									"
								>
									<input
										type="radio"
										id="prematureDeath_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										class="custom-control-input"
										[value]="d.value"
										formControlName="prematureDeath"
									/>
									<label
										class="custom-control-label"
										[class.gray]="d.value === 'N/A'"
										[class.green]="d.value === 'Low'"
										[class.orange]="d.value === 'Medium'"
										[class.red]="d.value === 'High' || d.value === 'Top'"
										for="prematureDeath_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										>{{ d.display }}</label
									>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="form-row align-items-center mb-3">
				<div class="col-lg-4">
					{{ eventQuestions.unableToWorkPermanently.label }}
				</div>

				<div class="col-lg-8 col-12">
					<div class="form-row align-items-center">
						<ng-template
							ngFor
							let-item
							[ngForOf]="DetailsList.controls"
							let-i="index"
						>
							<div
								*ngIf="item.get('peopleCRTId')?.value > 0"
								class="
									col-12 col-lg-4
									text-center
									d-flex
									flex-row-reverse
									justify-content-center
								"
								[formGroupName]="i"
							>
								<div
									*ngFor="let d of APCRTP$"
									class="
										custom-control custom-radio custom-control-inline
										box
										mr-1
									"
								>
									<input
										type="radio"
										id="unableToWorkPermanently_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										class="custom-control-input"
										[value]="d.value"
										formControlName="unableToWorkPermanently"
									/>
									<label
										class="custom-control-label"
										[class.gray]="d.value === 'N/A'"
										[class.green]="d.value === 'Low'"
										[class.orange]="d.value === 'Medium'"
										[class.red]="d.value === 'High' || d.value === 'Top'"
										for="unableToWorkPermanently_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										>{{ d.display }}</label
									>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="form-row align-items-center mb-3">
				<div class="col-lg-4">
					{{ eventQuestions.sufferFromCriticalIllnessOrTrauma.label }}
				</div>
				<div class="col-lg-8">
					<div class="form-row align-items-center">
						<ng-template
							ngFor
							let-item
							[ngForOf]="DetailsList.controls"
							let-i="index"
						>
							<div
								*ngIf="item.get('peopleCRTId')?.value > 0"
								class="
									col-12 col-lg-4
									text-center
									d-flex
									flex-row-reverse
									justify-content-center
								"
								[formGroupName]="i"
							>
								<div
									*ngFor="let d of APCRTP$"
									class="
										custom-control custom-radio custom-control-inline
										box
										mr-1
									"
								>
									<input
										type="radio"
										id="sufferFromCriticalIllnessOrTrauma_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										class="custom-control-input"
										[value]="d.value"
										formControlName="sufferFromCriticalIllnessOrTrauma"
									/>
									<label
										class="custom-control-label"
										[class.gray]="d.value === 'N/A'"
										[class.green]="d.value === 'Low'"
										[class.orange]="d.value === 'Medium'"
										[class.red]="d.value === 'High' || d.value === 'Top'"
										for="sufferFromCriticalIllnessOrTrauma_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										>{{ d.display }}</label
									>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="form-row align-items-center mb-3">
				<div class="col-lg-4">
					{{ eventQuestions.unableToWorkDueToTemporarySickness.label }}
				</div>
				<div class="col-lg-8">
					<div class="form-row align-items-center">
						<ng-template
							ngFor
							let-item
							[ngForOf]="DetailsList.controls"
							let-i="index"
						>
							<div
								*ngIf="item.get('peopleCRTId')?.value > 0"
								class="
									col-12 col-lg-4
									text-center
									d-flex
									flex-row-reverse
									justify-content-center
								"
								[formGroupName]="i"
							>
								<div
									*ngFor="let d of APCRTP$"
									class="
										custom-control custom-radio custom-control-inline
										box
										mr-1
									"
								>
									<input
										type="radio"
										id="unableToWorkDueToTemporarySickness_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										class="custom-control-input"
										[value]="d.value"
										formControlName="unableToWorkDueToTemporarySickness"
									/>
									<label
										class="custom-control-label"
										[class.gray]="d.value === 'N/A'"
										[class.green]="d.value === 'Low'"
										[class.orange]="d.value === 'Medium'"
										[class.red]="d.value === 'High' || d.value === 'Top'"
										for="unableToWorkDueToTemporarySickness_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										>{{ d.display }}</label
									>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="form-row align-items-center mb-3">
				<div class="col-lg-4">
					{{ eventQuestions.medicalConditionRequiringHospitalTreatment.label }}
				</div>
				<div class="col-lg-8">
					<div class="form-row align-items-center">
						<ng-template
							ngFor
							let-item
							[ngForOf]="DetailsList.controls"
							let-i="index"
						>
							<div
								*ngIf="item.get('peopleCRTId')?.value > 0"
								class="
									col-12 col-lg-4
									text-center
									d-flex
									flex-row-reverse
									justify-content-center
								"
								[formGroupName]="i"
							>
								<div
									*ngFor="let d of APCRTP$"
									class="
										custom-control custom-radio custom-control-inline
										box
										mr-1
									"
								>
									<input
										type="radio"
										id="medicalConditionRequiringHospitalTreatment_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										class="custom-control-input"
										[value]="d.value"
										formControlName="medicalConditionRequiringHospitalTreatment"
									/>
									<label
										class="custom-control-label"
										[class.gray]="d.value === 'N/A'"
										[class.green]="d.value === 'Low'"
										[class.orange]="d.value === 'Medium'"
										[class.red]="d.value === 'High' || d.value === 'Top'"
										for="medicalConditionRequiringHospitalTreatment_{{
											d.value === 'N/A' ? 'NA' : d.value
										}}_{{ i }}"
										>{{ d.display }}</label
									>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="d-flex row-divider my-3"></div>

			<!-- Dependants -->
			<ng-container *ngIf="hasDependents">
				<div class="clearfix mb-4">
					<ng-template
						ngFor
						let-item
						[ngForOf]="DetailsList.controls"
						let-i="index"
					>
						<label
							*ngIf="item.get('peopleCRTId')?.value === 0"
							for=""
							class="font-weight-bold label-font tap-text-primary"
							[class.ng-invalid]="item.invalid && item.touched"
							[class.ng-touched]="item.invalid && item.touched"
							>Dependants</label
						>
					</ng-template>
				</div>
				<div class="form-row align-items-center mb-4">
					<div class="col-lg-4">
						<div class="font-weight-bold">Event</div>
					</div>
					<div class="col-lg-8">
						<div class="form-row align-items-center font-weight-bold">
							<div class="col-lg-4 text-center"></div>
						</div>
					</div>
				</div>

				<div class="form-row align-items-center mb-3">
					<div class="col-lg-4">
						{{ eventQuestions.prematureDeath.label }}
					</div>

					<div class="col-lg-8 col-12">
						<div class="form-row align-items-center">
							<ng-template
								ngFor
								let-item
								[ngForOf]="DetailsList.controls"
								let-i="index"
							>
								<div
									*ngIf="item.get('peopleCRTId')?.value === 0"
									class="
										col-12 col-lg-4
										text-center
										d-flex
										flex-row-reverse
										justify-content-center
									"
									[formGroupName]="i"
								>
									<div
										*ngFor="let d of APCRTP$"
										class="
											custom-control custom-radio custom-control-inline
											box
											mr-1
										"
									>
										<input
											type="radio"
											id="prematureDeath_{{
												d.value === 'N/A' ? 'NA' : d.value
											}}_{{ i }}"
											class="custom-control-input"
											[value]="d.value"
											formControlName="prematureDeath"
										/>
										<label
											class="custom-control-label"
											[class.gray]="d.value === 'N/A'"
											[class.green]="d.value === 'Low'"
											[class.orange]="d.value === 'Medium'"
											[class.red]="d.value === 'High' || d.value === 'Top'"
											for="prematureDeath_{{
												d.value === 'N/A' ? 'NA' : d.value
											}}_{{ i }}"
											>{{ d.display }}</label
										>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>

				<div class="form-row align-items-center mb-3">
					<div class="col-lg-4">
						{{ eventQuestions.sufferFromCriticalIllnessOrTrauma.label }}
					</div>
					<div class="col-lg-8">
						<div class="form-row align-items-center">
							<ng-template
								ngFor
								let-item
								[ngForOf]="DetailsList.controls"
								let-i="index"
							>
								<div
									*ngIf="item.get('peopleCRTId')?.value === 0"
									class="
										col-12 col-lg-4
										text-center
										d-flex
										flex-row-reverse
										justify-content-center
									"
									[formGroupName]="i"
								>
									<div
										*ngFor="let d of APCRTP$"
										class="
											custom-control custom-radio custom-control-inline
											box
											mr-1
										"
									>
										<input
											type="radio"
											id="sufferFromCriticalIllnessOrTrauma_{{
												d.value === 'N/A' ? 'NA' : d.value
											}}_{{ i }}"
											class="custom-control-input"
											[value]="d.value"
											formControlName="sufferFromCriticalIllnessOrTrauma"
										/>
										<label
											class="custom-control-label"
											[class.gray]="d.value === 'N/A'"
											[class.green]="d.value === 'Low'"
											[class.orange]="d.value === 'Medium'"
											[class.red]="d.value === 'High' || d.value === 'Top'"
											for="sufferFromCriticalIllnessOrTrauma_{{
												d.value === 'N/A' ? 'NA' : d.value
											}}_{{ i }}"
											>{{ d.display }}</label
										>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>

				
				<div class="form-row align-items-center mb-3">
					<div class="col-lg-4">
						{{
							eventQuestions.medicalConditionRequiringHospitalTreatment.label
						}}
					</div>
					<div class="col-lg-8">
						<div class="form-row align-items-center">
							<ng-template
								ngFor
								let-item
								[ngForOf]="DetailsList.controls"
								let-i="index"
							>
								<div
									*ngIf="item.get('peopleCRTId')?.value === 0"
									class="
										col-12 col-lg-4
										text-center
										d-flex
										flex-row-reverse
										justify-content-center
									"
									[formGroupName]="i"
								>
									<div
										*ngFor="let d of APCRTP$"
										class="
											custom-control custom-radio custom-control-inline
											box
											mr-1
										"
									>
										<input
											type="radio"
											id="medicalConditionRequiringHospitalTreatment_{{
												d.value === 'N/A' ? 'NA' : d.value
											}}_{{ i }}"
											class="custom-control-input"
											[value]="d.value"
											formControlName="medicalConditionRequiringHospitalTreatment"
										/>
										<label
											class="custom-control-label"
											[class.gray]="d.value === 'N/A'"
											[class.green]="d.value === 'Low'"
											[class.orange]="d.value === 'Medium'"
											[class.red]="d.value === 'High' || d.value === 'Top'"
											for="medicalConditionRequiringHospitalTreatment_{{
												d.value === 'N/A' ? 'NA' : d.value
											}}_{{ i }}"
											>{{ d.display }}</label
										>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="d-flex row-divider my-3"></div>
		</div>
	</div>
</form>
