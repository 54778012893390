import {
	Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SearchItemDetail, TagType } from '../global-search.types';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'search-result-item',
	templateUrl: './search-result-item.component.html',
	styleUrls: ['./search-result-item.component.scss'],
  imports: [
    CommonModule, 
    MatIconModule,
	],
  standalone: true,
})
export class SearchResultItemComponent implements OnInit {
  @Input() profilePicture: string;
  @Input() color: string;
  @Input() type: TagType;
  @Input() name: string;
  @Input() details: SearchItemDetail[];
  @Input() rightDetails: string;
  @Input() actions: Array<{
    text: string;
    type: 'button' | 'date';
    details?: string;
    func?: () => void;
  }>;
  hasProfilePicture: boolean = false;
  icon: string;
  isHoveredSubj = new BehaviorSubject(false);
  isHovered$ = this.isHoveredSubj.asObservable();


  @HostBinding('style') 
  get hostStyle(): SafeStyle {
    return this.sanitaizer.bypassSecurityTrustStyle(`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 15px;
      margin-right: 5px;
    `);
  }

  @HostListener('mouseenter') 
  onMouseEnter() {
    this.elementRef?.nativeElement.classList.add('item-hover');
    this.isHoveredSubj.next(true);
  }
  
  
  @HostListener('mouseleave') 
  onMouseLeave() {
    this.elementRef?.nativeElement.classList.remove('item-hover');
    this.isHoveredSubj.next(false);
  }

  constructor(
    private sanitaizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.icon = this.getItemIcon();
    this.details = this.details.filter(detail => !!detail.value);
    this.isHovered$.subscribe(hovered => {
      this.styleHost('background', hovered ? `color-mix(in srgb, ${this.color}, transparent 80%)` : 'unset');
      this.styleHost('border-radius', hovered ? '50px' : 'unset');
    })
  }

  public click() {
    this.elementRef?.nativeElement.click();
  }
  
  public hoverItem(hover: boolean = true) {
    this.isHoveredSubj.next(hover);
  }

  public scrollIntoView() {
    this.elementRef?.nativeElement.scrollIntoView();
  }

  private styleHost(property: string, value: string) {
    this.renderer.setStyle(this.elementRef?.nativeElement, property, value);
  }

  private getItemIcon(): string {
    switch(this.type) {
      case 'person':
        if(this.profilePicture) {
          return null;
        }
        return 'client';
      case 'files':
        return 'documents';
      case 'business':
        return 'client'
      case 'policy':
        return 'policies';
      case 'activity':
        return 'activities'
      default:
        return this.profilePicture ? null : 'client';
    }
  }
}