<form [formGroup]="form" class="form other-income-form"
	[ngClass]="isMoatV2 ? 'moat-v2' : 'moat-v1'">
	<div class="form-row mt-4 mb-2 p-2 p-lg-0 others-label-background">
		<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="main-content-section__header">
				Other Income
			</label>
		</div>
		<div class="col-5 col-lg-9 text-right iconShow">
			<button
				id="otherCollapseButton"
				type="button"
				class="
					btn-toggle
					bg-white
					collapse-btn
					border-0
					others-collapse-background
				"
			>
				<span *ngIf="elseMinusOthers; else elsePlusOthers">
					<i (click)="collapseMoreOthers()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlusOthers>
					<span>
						<i (click)="collapseLessOthers()" class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<div
		class="form-row d-none d-lg-flex mb-2 font-weight-bold"
		[class.tap-opacity-25]="otherArray?.controls?.length === 0"
	>
		<div class="col-lg-4">
			<span>Income Type</span>
		</div>
		<div class="col-lg-3">
			<span>Annual Income</span>
		</div>
	</div>

	<div class="collapse expandable" id="collapseOthers">
		<div formArrayName="otherArray" class="mb-4">
			<div
				class="form-row mb-lg-0 align-items-center"
				*ngFor="let item of otherArray.controls; let pointIndex = index; let last = last"
				[formGroupName]="pointIndex"
			>
				<!-- for large  screen -->
				<div class="d-md-none col-lg-2 text-right pr-2 large-screen">
					<ng-container *ngIf="!item.get('isLoading').value">
						<ng-container *ngIf="item.get('btnSaveOther').value">
							<button
								id="otherSaveOtherButton_{{ pointIndex }}"
								color="primary"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="
									item.get('isNew').value &&
									(!item.get('incomeType').value ||
										!item.get('annualIncome').value)
								"
								(click)="saveOther(pointIndex)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
						</ng-container>

						<ng-container *ngIf="item.get('btnEditOther').value">
							<button
								id="otherEditOtherButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="editOther(pointIndex)"
								[disabled]="editMode || addMode"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="item.get('isLoading').value">
						<app-manual-loader-mini
							[isLoading]="item.get('isLoading').value"
						></app-manual-loader-mini>
					</ng-container>

					<ng-container *ngIf="item.get('isNew').value">
						<button
							id="otherDeleteNewOtherButton_{{ pointIndex }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							(click)="deleteNewOther(pointIndex)"
						>
							<i class="material-icons md-16"> close </i>
						</button>
					</ng-container>
					<ng-container
						*ngIf="!item.get('isNew').value && item.get('btnEditOther').value"
					>
						<button
							id="otherDeleteOtherButton_{{ pointIndex }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							(click)="deleteOther(pointIndex)"
							[disabled]="editMode || addMode"
						>
							<i class="material-icons md-16"> delete </i>
						</button>
					</ng-container>

					<ng-container
						*ngIf="!item.get('isNew').value && item.get('btnSaveOther').value"
					>
						<button
							id="otherCancelEditButton_{{ pointIndex }}"
							type="button"
							class="icon-btn w-auto h-auto px-1"
							(click)="cancelEdit(pointIndex)"
						>
							<i class="material-icons md-16"> close </i>
						</button>
					</ng-container>
				</div>
				<div class="col-12 col-lg-4 mb-2" [class.border-bottom]="!last" [tooltip]="getTooltipValue(item.get('incomeType'))">
					<input
						id="otherIncomeType_{{ pointIndex }}"
						class="form-control crt-form-control"
						type="text"
						formControlName="incomeType"
						placeholder="Income Type"
					/>
				</div>
				<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="!last">
					<div class="dollar-icon">
						<input
							id="otherAnnualIncome_{{ pointIndex }}"
							class="form-control crt-form-control"
							type="text"
							formControlName="annualIncome"
							placeholder="Annual Income"
							currencyMask
							appCurrencyMaskExt
						/>
						<i>$</i>
					</div>
				</div>
				<div class="col-12 col-lg-1 mb-2" [class.border-bottom]="!last">
					<div class="form-check form-check-inline">
						<input
							type="checkbox"
							class="form-check-input"
							id="otherIsNonTaxable_{{ pointIndex }}"
							formControlName="isNonTaxable"
						/>
						<label class="form-check-label text-nowrap">Non-Taxable</label>
					</div>
				</div>
				<!-- for small screen -->
				<div class="d-none d-md-block col-lg-1 small-screen align-items-center mb-0 text-right" [class.border-bottom]="!last">
					<ng-container *ngIf="!item.get('isLoading').value">
						<ng-container *ngIf="item.get('btnSaveOther').value">
							<button
								id="otherSaveOtherButton_{{ pointIndex }}"
								color="primary"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								[disabled]="
									item.get('isNew').value &&
									(!item.get('incomeType').value ||
										!item.get('annualIncome').value)
								"
								(click)="saveOther(pointIndex)"
							>
								<i class="material-icons md-16"> save </i>
							</button>
						</ng-container>
						<ng-container *ngIf="item.get('btnEditOther').value">
							<button
								id="otherEditOtherButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="editOther(pointIndex)"
								[disabled]="editMode || addMode"
							>
								<i class="material-icons md-16"> edit </i>
							</button>
						</ng-container>

						<ng-container *ngIf="item.get('isNew').value">
							<button
								id="otherDeleteNewOtherButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="deleteNewOther(pointIndex)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>

						<ng-container
							*ngIf="!item.get('isNew').value && item.get('btnEditOther').value"
						>
							<button
								id="otherDeleteOtherButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="deleteOther(pointIndex)"
								[disabled]="editMode || addMode"
							>
								<i class="material-icons md-16"> delete </i>
							</button>
						</ng-container>

						<ng-container
							*ngIf="!item.get('isNew').value && item.get('btnSaveOther').value"
						>
							<button
								id="otherCancelEditButton_{{ pointIndex }}"
								type="button"
								class="icon-btn w-auto h-auto px-1"
								(click)="cancelEdit(pointIndex)"
							>
								<i class="material-icons md-16"> close </i>
							</button>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="item.get('isLoading').value">
						<app-manual-loader-mini
							[isLoading]="item.get('isLoading').value"
						></app-manual-loader-mini>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="col-4 col-lg-4 mb-4">
				<button
					id="otherAddNewOtherIncome"
					type="button"
					class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
					(click)="addNewOtherIncome()"
					[disabled]="isListLoading || editMode || addMode"
				>
					Add Income +
				</button>

				<span *ngIf="isListLoading">
					<app-manual-loader-mini
						[isLoading]="isListLoading"
					></app-manual-loader-mini>
				</span>
			</div>
			<div class="col-5 pt-1">
				<label class="w-100 text-right font-weight-bold tap-text-primary"
					>Net Other Income</label
				>
			</div>
			<div class="col-3 pt-1 text-legt">
				<label>
					{{ totalAnnual | currency }}
				</label>
			</div>
		</div>

		<div class="form-row line mb-3"></div>
	</div>
</form>
