import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { UserSettingsQuery } from 'src/app/modules/user/state/user-settings/user-settings.query';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { ApplicationStore } from '../../state/application.store';
import { PurposeMapper } from './state/purpose.mapper';
import { PurposeDetailsState, PurposeTypes } from './state/purpose.model';
import { PurposeQuery } from './state/purpose.query';
import { PurposeService } from './state/purpose.service';
@Component({
	selector: 'app-purpose',
	templateUrl: './purpose.component.html',
	styleUrls: ['./purpose.component.scss'],
})
export class PurposeComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Input() purpose: PurposeDetailsState;
	@Input() applicationBanks: ViewDisplayValue[];
	@Input() primaryBanks: Observable<ViewDisplayValue[]>;
	@Input() parentCRTId: number;
	@Input() isApplicationDocumentLoading: Observable<boolean>;
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}>();

	form: UntypedFormGroup;
	selectedTypes: string[] = [];
	purposeTypes = PurposeTypes;
	submitted = false;
	isLoading$ = this.query.selectLoading();
	isLoadingPCM$ = this.userSettingsQuery.selectLoading();

	optionsWysiswyg = {
		heightMax: 250,
		heightMin: 250,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};

	@ViewChild('contentEditor') editor: WysiwygComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private service: PurposeService,
		private query: PurposeQuery,
		private userSettingsQuery: UserSettingsQuery,
		private applicationStore: ApplicationStore,
		private loggerService: LoggerService,
	) {}

	get NewPurchase() {
		return this.form.get('newPurchase');
	}

	get LendingTopup() {
		return this.form.get('lendingTopup');
	}

	get Refinance() {
		return this.form.get('refinance');
	}

	get HasPurpose() {
		return !!this.NewPurchase?.value || !!this.LendingTopup?.value || !!this.Refinance?.value;
	}

	get BusinessLending() {
		return this.form.get('businessLending');
	}

	get ApplicationBank() {
		return this.form.get('bank');
	}

	get Reason() {
		return this.form.get('reason');
	}

	get IsPreApproval() {
		return this.form.get('isPreApproval');
	}

	get IsSellingProperties() {
		return this.form.get('isSellingProperties');
	}

	get CRTId() {
		return this.form.get('cRTId');
	}

	ngOnInit(): void {
		// this.applicationStore.update(this.parentCRTId, {
		// 	currentPage: 'Purpose',
		// });

		this.buildForm();
		this.service
			.get(this.parentCRTId)
			.pipe(
				takeUntil(this.onDestroy$),
				tap((data) => {
					if (data) {
						const dataToMap = Object.values(data)[0];
						if (dataToMap) {
							this.prepData(dataToMap);
						}
					}
				})
			)
			.subscribe();
	}

	buildForm(): void {
		this.form = this.fb.group({
			cRTId: [null],
			newPurchase: [false],
			lendingTopup: [false],
			refinance: [false],
			businessLending: [false],
			bank: [null, [Validators.required]],
			currentPrimaryBank: [null],
			isPreApproval: [false],
			isSellingProperties: [false],
			reason: [null],
			parentCRTId: [this.parentCRTId],
		});
	}

	prepData(data) {
		const mappedData: PurposeDetailsState = PurposeMapper.mapToView(data);
		this.form.reset(mappedData);
		this.selectedTypes = [...mappedData.types];
	}

	tickChange(event, type: string) {
		const index = this.selectedTypes?.indexOf(type);
		if (event?.target?.checked) {
			if (index >= 0) {
				return;
			} else {
				this.selectedTypes.push(type);
			}
		} else {
			if (index >= 0) {
				this.selectedTypes?.splice(index, 1);
			}
		}
	}

	formErrors() {
		const errors = [];
		const formValue = this.form.getRawValue();
		const hasPurpose = formValue?.newPurchase || formValue?.lendingTopup || formValue?.refinance;
		if (!hasPurpose) {
			errors.push('At least one Application Purpose is required');
		}
		if (!this.ApplicationBank.valid) {
			errors.push('Application Bank is a required field');
		}
		return errors;
	}

	save(isNext: boolean, checkValidations: boolean = true, redirect: boolean = true) {
		this.Reason.setValue(this.editor?.content);
		const formValue = this.form.getRawValue();
		const data = PurposeMapper.mapToUpsert(formValue, this.selectedTypes);
		const errors = this.formErrors();

		if (checkValidations && errors?.length > 0) {
			if (this.submitted) {
				// If already submitted, allow to proceed regardless of requirements
				this.submitted = false;
			} else {
				// If first time submission and incomplete form requirements
				this.submitted = true;
				this.saveCompleted.emit({ isSuccess: false, isNext, redirect });
				this.loggerService.MultipleWarnings(errors);
				return;
			}
		} else {
			this.submitted = false;
		}

		if (this.CRTId.value) {
			this.service
				.update(data, this.parentCRTId)
				.pipe(
					take(1),
					catchError(() => {
						this.saveCompleted.emit({ isSuccess: false, isNext: false, redirect });
						return EMPTY;
					})
				)
				.subscribe(() => {
					this.saveCompleted.emit({ isSuccess: true, isNext, redirect });
				});
		} else {
			this.service
				.add(data, this.parentCRTId)
				.pipe(
					tap((result) => {
						this.prepData(result);
					}),
					take(1),
					catchError(() => {
						this.saveCompleted.emit({ isSuccess: false, isNext: false, redirect });
						return EMPTY;
					})
				)
				.subscribe(() => {
					this.saveCompleted.emit({ isSuccess: true, isNext, redirect });
				});
		}
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	applicationBanksTrackBy(
		_index: number,
		dropdown: { display: string; value: string }
	): string {
		return dropdown.value;
	}
}
