import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-info-modal',
	templateUrl: './info-modal.component.html',
	styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements InfoModalModel, OnDestroy {
	private onDestroy$ = new Subject<void>();
	close$: Observable<any>;
	header: string;
	message: string | any;
	hasOkay: boolean;
	appearance: string = 'default'; // options: v3, default

	constructor(public bsModalRef: BsModalRef) {}

	close() {
		if (!!this.close$) {
			this.close$.pipe(takeUntil(this.onDestroy$)).subscribe();
		}
		this.bsModalRef.hide();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
export interface InfoModalModel {
	close$?: Observable<any>;
	header: string;
	message: string;
	hasOkay?: boolean;
	appearance?:  string;
}
