import { numUtil } from '../../../../../util/util';
import * as R from 'ramda';
import { FundingRequiredLoan } from '../../crt-mortgage/application/application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.model';
import { KiwiSaver } from '../../crt-mortgage/client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.model';
import { Asset } from '../../crt-mortgage/client-sop/assets-and-liabilities/state/asset/asset.model';
import { PropertyPurchaseState } from '../../crt-mortgage/application/application-steps/funding-required/property-purchase/state/property-purchase.store';
import { LoanRefinanceState } from '../../crt-mortgage/application/application-steps/funding-required/loan-refinance/state/loan-refinance.model';
import { TopupRequirementsState } from '../../crt-mortgage/application/application-steps/funding-required/topup-requirements/state/topup-requirements.store';
import { PropertySoldDetailsState } from '../../crt-mortgage/application/application-steps/funding-required/selling-section/property-sold/state/property-sold.model';
import { BankWillTake } from '../../crt-mortgage/structure-soa/security/state/structure-soa-security.model';
import { FundingRequiredLoanState } from '../../crt-mortgage/application/application-steps/funding-required/selling-section/loan-repaid/state/loan-repaid.store';
import { PropertySoldState } from '../../crt-mortgage/application/application-steps/funding-required/selling-section/property-sold/state/property-sold.store';

const newLendingRequired = (
	contribution: number,
	newPurchaseList: PropertyPurchaseState[] = [],
	refinanceList: LoanRefinanceState[] = [],
	topUpList: TopupRequirementsState[] = []
) => {
	const totalRefinance = +refinanceList?.reduce((a, c) => {
		if (c?.isRefinance) {
			return a + +c?.loanValue || 0;
		} else {
			return a;
		}
	}, 0);
	const totalTopUp = +totalTopupRequired(topUpList);

	const totalNewPurchase = +newPurchaseList?.reduce((a, c) => {
		return a + +c?.propertyValue || 0;
	}, 0);

	const totalOfLists = totalNewPurchase + totalRefinance + totalTopUp;
	const result = +R.subtract(totalOfLists, contribution || 0);
	return +numUtil.formatToNumCurrency(result);
};

const totalContribution = (data) => {
	let otherTotalValue = 0;
	if (data.other) {
		data.other.map((val) => (otherTotalValue += val.otherValue));
	}
	const result =
		(+data?.cash || 0) +
		(+data?.gift || 0) +
		(+data?.kiwiSaver || 0) +
		(+data?.cashFromExistingLoan || 0) +
		(+data?.other || 0) +
		(+data?.saleProceeds || 0) +
		otherTotalValue;
	return +numUtil.formatToNumCurrency(result || 0);
};

const totalAvailableCash = (data: Asset[] = []) => {
	const result = data?.reduce((a, c) => {
		if (c?.asset === 'Savings') {
			return R.sum([+a, +(c?.value || 0)]);
		}
		return +a;
	}, 0);
	return +numUtil.formatToNumCurrency(result);
};

const totalAvailableKiwiSaver = (data: KiwiSaver[]) => {
	const result = data?.reduce((a, c) => {
		return a + (+c?.currentBalance || 0);
	}, 0);
	return +numUtil.formatToNumCurrency(result);
};

const totalPurchasePrice = (properties = []) => {
	const result = properties?.reduce((a, c) => {
		if (c?.propertyValue) {
			return a + (+c?.propertyValue || 0);
		}
		return a;
	}, 0);
	return +numUtil.formatToNumCurrency(result);
};

const totalTopupRequired = (topups = []) => {
	const result = topups?.reduce((a, c) => {
		if (c?.topupRequirementValue) {
			return a + (+c?.topupRequirementValue || 0);
		}
		return a;
	}, 0);
	return +numUtil.formatToNumCurrency(result);
};

const totalNetProceeds = (
	properties: PropertySoldDetailsState[],
	loans: FundingRequiredLoan[]
) => {
	const propertyTotal = properties?.reduce((a, b) => a + +b.salesPrice, 0);
	const loanTotal = loans
		?.filter((val) => val.isTicked)
		?.reduce((a, b) => a + b.loanBalance, 0);
	return +numUtil.formatToNumCurrency(propertyTotal - loanTotal);
};

const totalLoansRepaid = (loansRepaidList: FundingRequiredLoanState[]) => {
	const result = loansRepaidList
		?.filter((x) => !!x?.isTicked)
		.reduce((a, c) => {
			return R.sum([a, +(c?.loanBalance || 0)]);
		}, 0);
	return +numUtil.formatToNumCurrency(result);
};

const totalPropertySold = (loansRepaidList: PropertySoldState[]) => {
	const result = loansRepaidList.reduce((a, c) => {
		return R.sum([a, +(c?.salesPrice || 0)]);
	}, 0);
	return +numUtil.formatToNumCurrency(result);
};

const totalNetLoanProceeds = (
	cashDeposit,
	propertySoldList: PropertySoldState[] | any[],
	loansRepaidList: FundingRequiredLoanState[] | any[],
) => {
	// TAPNZ-6274 Formula:
	// totalNetLoanProceeds = (Property to be Sold Price +/and/or Cash +/and/or Gift +/and/or KiwiSaver +/and/or Existing Loan +/and/or Other less Loans To be Repaid)
	const propertySoldPrice = totalPropertySold(propertySoldList) || 0;
	const otherTotalValue = cashDeposit?.other?.reduce((a, c) => {
		return a + +(c?.otherValue || 0);
	}, 0);
	const loansRepaid = totalLoansRepaid(loansRepaidList) || 0;
	const result = R.subtract(
		R.sum([
			propertySoldPrice,
			cashDeposit?.cash || 0,
			cashDeposit?.gift || 0,
			cashDeposit?.kiwiSaver || 0,
			cashDeposit?.cashFromExistingLoan || 0,
			otherTotalValue || 0,
		]),
		loansRepaid
	);

	return +numUtil.formatToNumCurrency(result);
};

const totalFundingRequired = (
	deposit: number,
	refinanceList: LoanRefinanceState[],
	topUpList: TopupRequirementsState[] | any[],
	totalPropertyPurchase: number
) => {
	// TAPNZ-6274 Formula:
	// totalFundingRequired = (Property Price +/and/or Refinanced Loan +/and/or Topup Requirement less Deposit)
	// Where Deposit is Total Contribution
	const totalRefinance = +refinanceList?.reduce((a, c) => {
		if (c?.isRefinance) {
			return a + +c?.loanValue || 0;
		} else {
			return a;
		}
	}, 0);
	const totalTopUp = +totalTopupRequired(topUpList);
	const totalOfLists = totalPropertyPurchase + totalRefinance + totalTopUp;
	const result = +R.subtract(totalOfLists, deposit);

	return +numUtil.formatToNumCurrency(result);
};

const proposedLVR = (securities: BankWillTake[], totalDebt: number) => {
	const totalSecurityValue = securities?.reduce((a, c) => {
		return a + +c?.amount || 0;
	}, 0);
	const result =
		totalSecurityValue > 0
			? R.divide(+(totalDebt || 0), +(totalSecurityValue || 0))
			: 0;
	return result;
};

const propertyLVR = (totalDebt: number, propertyValue: number) => {
	const result = R.divide(+(totalDebt || 0), +(propertyValue || 0));
	return +numUtil.formatToNumCurrency(result);
};

const scaledLVR = (totalDebt: number, maxLoanAmount: number) => {
	if (+maxLoanAmount === 0) {
		return 0;
	}
	let result = R.divide(+(totalDebt || 0), +(maxLoanAmount || 0));
	result = R.multiply(result, 100)
	return +numUtil.formatToNumCurrency(Math.ceil(result));
};

export const computeUtil = {
	newLendingRequired,
	totalContribution,
	totalAvailableCash,
	totalAvailableKiwiSaver,
	totalPurchasePrice,
	totalTopupRequired,
	totalNetProceeds,
	totalNetLoanProceeds,
	totalFundingRequired,
	totalLoansRepaid,
	totalPropertySold,
	proposedLVR,
	propertyLVR,
	scaledLVR,
};
