import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import { NoteSettingsService } from './state/note-settings.service';
import { NoteSettingsMapper } from './state/note-settings.mapper';
import { NoteSettingsState } from './state/note-settings.model';

@Component({
	selector: 'app-note-settings',
	templateUrl: './note-settings.component.html',
	styleUrls: ['./note-settings.component.scss'],
})
export class NoteSettingsComponent implements OnInit, OnDestroy {
	optionsWysiswyg = {
		heightMax: 250,
		heightMin: 150,
		toolbarSticky: false,
		quickInsertEnabled: false,
		linkAutoPrefix: '',
	};
	private onDestroy$ = new Subject<void>();
	isLoading = false;
	isSaving = false;
	@Input() parentCRTId: number;
	@Output() saveCompleted: EventEmitter<object> = new EventEmitter<object>();

	@ViewChild('purposeEditor') purposeEditor: WysiwygComponent;
	@ViewChild('clientBackgroundEditor') clientBackgroundEditor: WysiwygComponent;
	@ViewChild('recommendationEditor') recommendationEditor: WysiwygComponent;
	@ViewChild('otherInformationEditor') otherInformationEditor: WysiwygComponent;

	notes: NoteSettingsState;

	noteSettings$ = this.service.noteSettings$;

	constructor(private service: NoteSettingsService) {}

	ngOnInit(): void {
		this.isLoading = true;
		this.noteSettings$
			.pipe(
				tap((data) => this.prepData(data)),
				finalize(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	prepData(data) {
		this.notes = NoteSettingsMapper.mapToView(data);
	}

	saveNotes() {
		this.isSaving = true;
		const values = {
			purpose: this.purposeEditor.content,
			clientBackgroundAndCharacter: this.clientBackgroundEditor.content,
			recommendation: this.recommendationEditor.content,
			otherInformation: this.otherInformationEditor.content,
		};
		const mappedData = NoteSettingsMapper.mapToUpsert(values);
		this.service
			.updateNoteSettings(mappedData)
			.pipe(take(1))
			.subscribe(() => {
				this.isSaving = false;
			});
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
