import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserSettingsStore, UserSettingsState } from './user-settings.store';

@Injectable()
export class UserSettingsQuery extends Query<UserSettingsState> {
	/**
	 * @returns observable of User Details
	 */
	userDetails$ = this.select((state) => state.userDetails);
	/**
	 * @returns observable of User/Staff Settings
	 */
	staffSettings$ = this.select((state) => state.staffSettings);
	/**
	 * @returns observable of BL Staff > Merge Tags
	 */
	mergeTags$ = this.select((state) => state.mergeTags);

	/**
	 * @returns observable of BL Staff > Merge Tags
	 */
	pCTmergeTagsLoaded$ = this.select((state) => state.pCTmergeTagsLoaded);

	/**
	 * @returns observable of BL Staff > Disclosure Document Settings
	 */
	disclosureSettings$ = this.select((state) => state.disclosureSettings);

	/**
	 * @returns observable of BL Staff > Provider & Commission > L&R
	 */
	providerCommissionLR$ = this.select((state) => state.providerCommissionLR);

	/**
	 * @returns observable of BL Staff > Provider & Commission > Mortgage
	 */
	providerCommissionM$ = this.select((state) => state.providerCommissionM);

	/**
	 * @returns observable of BL Staff > Provider & Commission > F&G
	 */
	providerCommissionFG$ = this.select((state) => state.providerCommissionFG);

	/**
	 * @returns observable of BL Staff > Provider & Commission > KiwiSaver
	 */
	providerCommissionK$ = this.select((state) => state.providerCommissionK);

	emailAccessPerAdviceProcess$ = this.select(
		(state) => state.emailAccessPerAdviceProcess
	);

	pdtWidget$ = this.select(
		(state) => state.pdtWidget
	);

	constructor(protected store: UserSettingsStore) {
		super(store);
	}
}
