import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { RouteService } from '../../../../../../core/config/route.service';
import { numUtil } from '../../../../../../util/util';
import {
	BodyRow,
	HeaderRow
} from '../../../shared/simple-table/simple-table.model';
import { DashboardQuery } from '../../../state/dashboard.query';
import { KiwisaverPipeline } from '../state/p.model';
import { PQuery } from '../state/p.query';
import { PService } from '../state/p.service';

/**
 * Kiwisaver pipeline table. Uses simple table.
 */
@Component({
	selector: 'app-kp',
	templateUrl: './kp.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpComponent implements OnInit, OnDestroy {
	/**
	 * key pair value where the key is keys in mortgage pipeline response
	 * and value is the real status value.
	 *
	 * The real status value is used for values to search when user
	 * is routed to pipeline search due to clicking a status.
	 */
	// private readonly KiwisaverPropertyRealStatus = (() => {
	//   const statuses = {
	//     Lead: 'Lead',
	//     Submitted: 'Submitted',
	//     InfoRequired: 'Info Required'
	//   };
	//   return {
	//     Total: Object.values(statuses).join(','),
	//     ...statuses,
	//   };
	// })();

	/**
	 * Display name for Statuses.
	 * keys are the keys in Kiwisaver pipeline response.
	 */
	// private readonly KiwisaverPropertyName = {
	//   Total: 'Total',
	//   Lead: 'Leads',
	//   Submitted: 'Submitted',
	//   InfoRequired: 'Info Required'
	// };

	/**
	 * Destroy event observable. Is watched to know when to
	 * unsubscribe subscribers. emits on ngOnDestroy.
	 */
	private onDestroy$ = new Subject<void>();

	/**
	 * indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.KiwisaverIsLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.KiwisaverError$;
	/**
	 * Kiwisaver pipeline data
	 */
	data$: Observable<KiwisaverPipeline> = this.query.KiwisaverData$;

	isServiceLoading$: Observable<boolean> = this.query.isServiceLoading$;
	/**
	 * transform Kiwisaver data to simple-table object.
	 *
	 * For status label, show text from KiwisaverPropertyName
	 * For route get from routeService with status from KiwisaverPropertyRealStatus
	 */
	viewData$ = this.data$.pipe(
		withLatestFrom(
			this.dashboardQuery.hasSelectedAllAdvisers$,
			this.dashboardQuery.allAdvisersId$
		),
		map(([data, hasSelectedAllAdvisers, allAdviserIds]): BodyRow[] => {
			if (data === null) {
				return [];
			} else {
				const selectedAdvisers = this.dashboardQuery.getValue();
				const totalStatus = Object.keys(data)
					?.filter((key) => key !== 'Total')
					?.map((key) => data[key].Status);

				return Object.keys(data)?.map<BodyRow>((key) => {
					return [
						{
							text: data[key].Status,
							route: this.routeService.kiwisaverPipelineWithParams({
								advisers: !hasSelectedAllAdvisers
									? selectedAdvisers.adviserFilter?.join(',')
									: allAdviserIds?.join(','),
								statuses:
									data[key].Status !== 'Total'
										? data[key].Status
										: totalStatus?.join(','),
							}),
						},
						{ text: numUtil.formatToCount(data[key].Count) },
					];
				});
			}
		})
	);

	/** header for simple-table header object */
	header: HeaderRow = [{ text: 'Status' }, { text: 'Count' }];

	constructor(
		private routeService: RouteService,
		private dashboardQuery: DashboardQuery,
		private query: PQuery,
		private service: PService,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	/** trigger Kiwisaver pipeline data fetch when adviser changes */
	ngOnInit() {
		this.dashboardQuery.adviserFilter$
		.pipe(
			filter((x) => x.length > 0),
			withLatestFrom(
				this.isServiceLoading$
			),
			takeUntil(this.onDestroy$)
		)
		.subscribe(([advisers, isServiceLoading]) => {
			if (!isServiceLoading) {
				this.service.GetAllPipelines(advisers);
			}
		});
	}

	/**
	 * Gets onDestroy$ to emit and unsubscribe.
	 */
	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
