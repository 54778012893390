import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface RouteLoaderState {
	skipLoading: boolean
}

export function createInitialState(): RouteLoaderState {
	return {
		skipLoading: false,
	};
}

@Injectable()
@StoreConfig({ name: 'route-loader' })
export class RouteLoaderStore extends Store<RouteLoaderState> {
	constructor() {
		super(createInitialState());
	}

	setSkipLoading(value: boolean) {
		this.update({ skipLoading: value });
	}
}
