import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProviderSettingsService } from '@modules/kiwisaver-settings/fact-find-settings/providers/state/providers-settings.service';
import { KiwiSaverSettingsModule } from '@modules/kiwisaver-settings/kiwisaver-settings.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { SharedModule } from '../../../../shared/shared.module';
import { CrtSharedModule } from '../_shared/crt-shared.module';
import { CrtBannerModule } from './crt-banner/crt-banner.module';
import { CrtKiwisaverCanDeactivateGuard } from './crt-kiwisaver-can-deactivate.guard';
import { CrtKiwiSaverResolver } from './crt-kiwisaver-resolver';
import { CrtKiwiSaverRoutingModule } from './crt-kiwisaver-routing.module';
import { CrtKiwiSaverComponent } from './crt-kiwisaver.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { DeclarationService } from './declaration/state/declaration.service';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { DisclosureQuery } from './disclosure/state/disclosure.query';
import { DisclosureService } from './disclosure/state/disclosure.service';
import { DisclosureStore } from './disclosure/state/disclosure.store';
import { IntroComponent } from './introduction/introduction.component';
import { KoatIntroService } from './introduction/state/introduction.service';
import { DependantsQuery } from './people-entities/dependants/state/dependants.query';
import { DependantsService } from './people-entities/dependants/state/dependants.service';
import { DependantsStore } from './people-entities/dependants/state/dependants.store';
import { PeopleQuery } from './people-entities/people/state/people.query';
import { PeopleService } from './people-entities/people/state/people.service';
import { PeopleStore } from './people-entities/people/state/people.store';
import { ScopeOfServicesComponent } from './scope-of-services/scope-of-services.component';
import { ScopeOfServiceService } from './scope-of-services/state/scope-of-service.service';
import { CrtKiwiSaverService } from './state/crt-kiwisaver.service';
import { MergeTagsQuery } from './state/merge-tags/crt-kiwisaver-mergetags.query';
import { MergeTagsService } from './state/merge-tags/crt-kiwisaver-mergetags.service';
import { CrtKsFactFindTabColorsService } from './fact-find/states/crt-ks-fact-find-tab-colors.service';
import { AdviceSummaryGuard } from './guards/advice-summary.guard';
import { CrtBannerSimpleModule } from './crt-banner-simple/crt-banner-simple.module';
import { PromptUnsavedService } from '@shared/components/prompt-unsaved/prompt-unsaved.service';
import { SlidesModule } from '@shared/slides/slides.module';
import { PeopleModalComponent } from '../../../../shared/modal/crt/koat/people/people-modal.component';
import { PeopleFormComponent } from '../../../../shared/modal/crt/koat/people/people-form/people-form';

@NgModule({
	declarations: [
		CrtKiwiSaverComponent,
		DisclosureComponent,
		IntroComponent,
		ScopeOfServicesComponent,
		DeclarationComponent,
		PeopleModalComponent,
		PeopleFormComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		CrtSharedModule,
		CrtKiwiSaverRoutingModule,
		CrtBannerModule,
		CrtBannerSimpleModule,
		ButtonModule,
		SlidesModule,
		KiwiSaverSettingsModule,
	],
	providers: [
		CrtKiwiSaverResolver,
		// CrtKiwiSaverStore,
		// CrtKiwiSaverQuery,
		CrtKiwiSaverService,
		CrtKiwisaverCanDeactivateGuard,
		CrtKsFactFindTabColorsService,

		MergeTagsService,
		MergeTagsQuery,

		PeopleService,
		PeopleQuery,
		PeopleStore,
		DependantsService,
		DependantsQuery,
		DependantsStore,

		DisclosureService,
		DisclosureQuery,
		DisclosureStore,

		KoatIntroService,
		ScopeOfServiceService,
		ProviderSettingsService,
		PromptUnsavedService,
		DeclarationService,

		AdviceSummaryGuard
	],
})
export class CrtKiwiSaverModule {}
