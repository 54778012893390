import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ExpenseStore, ExpenseState } from './expenses.store';

@Injectable()
export class ExpenseQuery extends Query<ExpenseState> {
  constructor(protected store: ExpenseStore) {
    super(store);
  }

  expenses$ = this.select((state) => state.expenses);

  formValue$ = this.select((state) => state.formValue);

}
