import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { either, isNil, isEmpty } from 'ramda';
import { Observable, Observer, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	map,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import MomentUtil from 'src/app/util/moment.util';
import { MOATAssetsLiabilitiesModalComponent } from '../../../../../../../../../shared/modal/crt/moat/assets-liabilities/assets-liabilities.modal.component';
import { Mortgage } from '../../../../../client-sop/assets-and-liabilities/state/mortgage/mortgage.model';
import { LoanRefinanceMapper } from '../state/loan-refinance.mapper';
import { LoanRefinanceState } from '../state/loan-refinance.model';
import { LoanRefinanceQuery } from '../state/loan-refinance.query';
import { LoanRefinanceService } from '../state/loan-refinance.service';
import { PropertyState } from '@shared/models/client-review-template/assets-liabilities/property.model';

@Component({
	selector: 'app-loan-refinance-form',
	templateUrl: './loan-refinance-form.component.html',
})
export class LoanRefinanceFormComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();
	@Input() parentCRTId: number;
	@Input() loanRefinance$: Observable<LoanRefinanceState[]>;
	@Input() mortgages$: Observable<Mortgage[]>;
	@Input() isLoading$: Observable<boolean>;
	@Input() borrowers$: Observable<any[]>;
	@Input() securities$: Observable<any[]>;
	@Input() MP$: Observable<ViewDisplayValue[]>;
	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() MLT$: Observable<ViewDisplayValue[]>;
	@Input() addFn$: (req: any) => Observable<any>;
	@Input() updateFn$: (req: any) => Observable<any>;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];
  @Input() properties$: Observable<PropertyState[]>;

	sourceType$ = this.loanRefinanceQuery.sourceType$;

	isAdding: boolean;
	updateLoading: boolean;
	bsModalRef: BsModalRef;
	form: UntypedFormGroup;

	totalRefinance = 0;

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private loanRefinanceQuery: LoanRefinanceQuery,
		private service: LoanRefinanceService
	) {
		this.buildForm();
	}

	get LoanRefinanceList() {
		return this.form.get('loanRefinance') as UntypedFormArray;
	}

	buildForm(): void {
		this.form = this.fb.group({
			loanRefinance: this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.loanRefinance$
			.pipe(
				filter((loans) => !!loans),
				map((x) => LoanRefinanceMapper.mapToView(x)),
				takeUntil(this.onDestroy$)
			)
			.subscribe((loans) => {
				this.clearLoanRefinanceList();
				this.totalRefinance = 0;
				loans.map((x) => {
					this.prepData(x);
					if (x.isRefinance) {
						this.totalRefinance += x.loanValue;
					}
				});
			});
	}

	clearLoanRefinanceList() {
		while (this.LoanRefinanceList.length > 0) {
			this.LoanRefinanceList.removeAt(0);
		}
	}

	prepData(data: LoanRefinanceState) {
		const group = this.fb.group({
			parentCRTId: this.fb.control(this.parentCRTId),
			linkedCRTId: this.fb.control(data.linkedCRTId),
			adviceProcessId: this.fb.control(data?.adviceProcessId),
			borrower: this.fb.control(data?.borrower),
			cRTId: this.fb.control(data?.cRTId || null),
			lender: this.fb.control(data?.lender),
			loanValue: this.fb.control(data?.loanValue),
			interestRate: this.fb.control(data?.interestRate),
			fixedPeriodEnd: this.fb.control(data?.fixedPeriodEnd || null),
			loanRepayment: this.fb.control(data?.loanRepayment),
			mortgageFrequency: this.fb.control(data?.mortgageFrequency),
			loanType: this.fb.control(data?.loanType),
			loanTerm: this.fb.control(data?.loanTerm),
			security: this.fb.control(data?.security),
			purpose: this.fb.control(data?.purpose),
			isRefinance: this.fb.control(data?.isRefinance),
			isLoading: this.fb.control(false),
			hidden: this.fb.control(data?.hidden || false),
			sourceType: this.fb.control(data?.sourceType),
		});
		this.LoanRefinanceList.push(group);
	}

	addLoanRefinance() {
		this.isAdding = true;
		this.mortgageModal();
	}

	updateTick(index: number) {
		const formValue = this.form.getRawValue().loanRefinance[index];
		of(formValue)
			.pipe(
				tap(() => this.loanLoader(index, true)),
				concatMap((x) =>
					this.updateFn$({ ...x, isRefinance: !x?.isRefinance }).pipe(take(1))
				),
				finalize(() => {
					this.loanRefinanceControl(index)
						.get('isRefinance')
						.setValue(!formValue?.isRefinance);
					this.loanLoader(index, false);
					this.cancel();
				}),
				take(1)
			)
			.subscribe();
	}

	loanRefinanceControl(index: number) {
		return this.LoanRefinanceList.controls[index];
	}

	loanLoader(index: number, isLoading: boolean) {
		if (isLoading) {
			this.updateLoading = true;
			this.loanRefinanceControl(index).disable();
		} else {
			this.loanRefinanceControl(index).enable();
		}
		this.loanRefinanceControl(index).get('isLoading').setValue(isLoading);
	}

	addNew$ = (model) =>
		new Observable((obs: Observer<any>) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) => ({
				...x,
				isRefinance: true,
				fixedPeriodEnd:
					either(isNil, isEmpty)(x?.fixedPeriodEnd) &&
					typeof x?.fixedPeriodEnd === 'string'
						? x?.fixedPeriodEnd
						: MomentUtil.formatDateToServerDate(x?.fixedPeriodEnd),
			})),
			concatMap((data) =>
				this.addFn$(data).pipe(
					// tap((x) => this.prepData({ ...data, cRTId: +x })),
					take(1)
				)
			),
			concatMap(() => this.service.get(this.parentCRTId)),
			take(1),
			finalize(() => this.cancel())
		);

	mortgageModal() {
		const decline$ = new Observable((obs: Observer<any>) => {
			this.cancel();
			obs.complete();
		});

		const initState = {
			header: 'Loan to Refinance Details',
			message: `Mortgages`,
			crtInfo: null,
			borrowers: this.borrowers$,
			securities: this.securities$,
			mp$: this.MP$,
			mlt$: this.MLT$,
			apcrtf$: this.APCRTF$,
			getOwnerChoices: this.getOwnerChoices,
			saveFn$: this.addNew$,
			decline$,
		};
		this.bsModalRef = this.modalService.show(
			MOATAssetsLiabilitiesModalComponent,
			{
				class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			}
		);
	}

	cancel() {
		this.isAdding = false;
		this.updateLoading = false;
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
