import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
	CARSettingsState,
	CARSettingsStore,
} from './customer-alteration-request.store';
import { CARSettings } from './customer-alteration-request.model';

@Injectable({ providedIn: 'root' })
export class CARSettingsQuery extends Query<CARSettings> {
	carChangeRequest$ = this.select((settings) => settings.changeRequest);
	carUpdatedStructure$ = this.select((settings) => settings.updatedStructure);

	constructor(protected store: CARSettingsStore) {
		super(store);
	}
}
