import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CrtBannerComponent } from './crt-banner.component';

const components = [CrtBannerComponent];

@NgModule({
	declarations: components,
	exports: components,
	imports: [CommonModule],
})
export class CrtBannerModule {}
