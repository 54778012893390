import {
	Directive,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { ComponentBase } from '@core/base/component-base';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

interface featureConfig {
	// MetaKey of Feature from Business Configuration (from BE)
	id: string;

	// If show = true: Element will show only if feature is enabled
	// If show = false: Element should not show if the feature is enabled
	show: boolean;
}

@Directive({
	selector: '[featureToggle]',
})
export class FeatureToggleDirective
	extends ComponentBase
	implements OnInit, OnDestroy
{
	featConfig: featureConfig;
	@Input()
	set featureToggle(value: featureConfig) {
		this.featConfig = value;
	}

	constructor(
		private businessQuery: BusinessConfigQuery,
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {
		super();
	}

	ngOnInit(): void {
		super.subscribe(this.businessQuery.businessConfig$, (config) =>
			this.update(config)
		);
	}

	ngOnDestroy(): void {
		super.dispose();
	}

	update(config: any) {
		const featureId = this.featConfig?.id || '';
		const showOnFeature = !!this.featConfig?.show;
		const isFeatureEnabled = !!config?.[featureId];

		if (
			(showOnFeature && isFeatureEnabled) ||
			(!showOnFeature && !isFeatureEnabled)
		) {
			// Show elements
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		} else {
			// Hide elements
			this.viewContainerRef.clear();
		}
		return;
	}
}
