import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ActivityState, ActivityStore } from './activity.store';
import {
  map,
  withLatestFrom,
  combineLatest,
  auditTime,
  distinctUntilChanged,
  shareReplay,
  delay,
} from 'rxjs/operators';
import { columns, futureWorkColumns, templateRow } from '../activity-datatable.config';
import * as R from 'ramda';
import { Observable, of } from 'rxjs';
import { ActivityUiQuery } from './activity-ui.query';
import { Row } from './activity.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { mapToLookupObject } from 'src/app/domain/dropdown-value/dropdown-value.model';
import { util } from 'src/app/util/util';
import sort from 'fast-sort';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Injectable()
export class ActivityQuery extends QueryEntity<ActivityState, Row> {
  private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
    map(x => R?.map(y => ViewDisplayValue?.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
    map(x => (x ? x?.sort((a, b) => a.display?.localeCompare(b.display)) : x))
  );
	private activeStaffs$ = this.blstaffQuery.activeStaffs$.pipe(
    map(x => R?.map(y => ViewDisplayValue?.Map(y.StaffID?.toString(), `${y.FirstName} ${y.LastName}`), x)),
    map(x => (x ? x?.sort((a, b) => a.display?.localeCompare(b.display)) : x))
  );
  private AT$ = this.dropdownQuery.orderedChoices$('AT');
  private AM$ = this.dropdownQuery.orderedChoices$('AM');

  columns$ = this.select(x => x.columns);
  tableColumns$ = this.columns$.pipe(
    withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
    map(([tableColumns, adviserReworkFeature]) => {
      return tableColumns.filter(column => {
        return adviserReworkFeature ? 
          !(column === "Adviser Name") || futureWorkColumns.find(f => f.metakey === column) :
            !futureWorkColumns.find(f => f.metakey === column);
      })
      ?.map((col) => {
        let futureCol = null;

        if (adviserReworkFeature) {
          futureCol = futureWorkColumns.find(f => f.metakey === col);
        }

        return futureCol ? futureCol : columns?.find(y => y.metakey === col)})
        ?.filter(x => x);
    }),
    withLatestFrom(this.select(x => x.columnWidths)),
    map(([tableColumns, widths]) =>
      tableColumns?.map(column => {
        const widthConf = widths?.filter(x => x)?.find(width => width.metakey === column.metakey);
        const newColumn = { ...column, $$id: column.name };
        if (widthConf) {
          return { ...newColumn, width: widthConf.width };
        } else {
          return newColumn;
        }
      })
    ),
    combineLatest(this.activeStaffs$, this.AT$),
    auditTime(0),
    map(([cols, activeStaffs, at]) => {
      const meetingOptions = [
        {
          display: 'In Person',
          value: 'In Person',
          isDefault: true,
          isActive: true
        },
        {
          display: 'Phone Meeting',
          value: 'Phone Meeting',
          isDefault: false,
          isActive: true
        },
        {
          display: 'Video Meeting',
          value: 'Video Meeting',
          isDefault: false,
          isActive: true
        },
      ];

      return cols?.map(x => {
        if (x.metakey === 'Assigned To') {
          return {
            ...x,
            choices: activeStaffs,
            choicesAsObject: mapToLookupObject(activeStaffs),
          };
        } else if (x.metakey === 'Activity Type') {
          return {
            ...x,
            choices: at,
            choicesAsObject: mapToLookupObject(at),
          };
        } else if (x.metakey === 'Meeting') {
          return {
            ...x,
            choices: meetingOptions,
            choicesAsObject: mapToLookupObject(meetingOptions),
          };
        } else {
          return x;
        }
      });
    }),
    distinctUntilChanged((x, y) => R?.equals(x, y)),
    shareReplay(1)
  );

  hiddenTableColumns$ = this.columns$.pipe(
    withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
    map(([tableColumns, adviserReworkFeature]) => {
      
      const allColumns = [...columns, ...(adviserReworkFeature ? futureWorkColumns : [])];

      return allColumns?.filter(x => adviserReworkFeature ? x.metakey !== 'Adviser Name' && !tableColumns?.includes(x.metakey) : !tableColumns?.includes(x.metakey));
    }),
    shareReplay(1)
  );

  sorts$ = this.uiQuery.select(x => [{ dir: x.sort, prop: x.propSort }]);
  cellsLoading$ = this.uiQuery.select(state => state.cellLoadingStatus);
  cellsEditing$ = this.uiQuery.select(state => state.cellEditStatus);
  cellsTempvalue$ = this.uiQuery.select(state => state.cellTempValue);
  rowsLoading$ = this.uiQuery.select(state => state.rowLoadingStatus);
  isSearching$ = this.uiQuery.select(x => x.isSearching);
  searchForm$ = this.select(x => x.searchForm);
  isComplete$ = this.select(x => x.isComplete);
  allAdvisers$ = this.blstaffQuery.allStaffsChoices$;

  templateRow$: Observable<Row> = of(templateRow);
  rows$: Observable<Row[]> = this.selectAll();
  hasRows$: Observable<boolean> = this.selectCount().pipe(map(x => x > 0));

  count$ = this.select(x => x.count);

  sortedRows$ = this.uiQuery
    .select(x => [x.propSort, x.sort])
    .pipe(
      distinctUntilChanged((p, q) => R?.equals(p, q)),
      combineLatest(this.rows$),
      withLatestFrom(this.tableColumns$),
      withLatestFrom(this.isSearching$),
      withLatestFrom(this.allAdvisers$),
      delay(0),
      map(([[[[[prop, sortDirection], rows], tableColumns], isSearching], allAdvisers]) => {
        if (sortDirection === '' || prop === '' || isSearching) {
          return rows;
        } else {
          const column = tableColumns?.find(x => x.prop === prop);
          if (util.isNullOrEmpty(column)) {
            return rows;
          }
          const decorated = rows?.map<[number, any, number, Row]>(r => {
            const actualValue = column.sortValueGetter(r[prop], prop === 'AdviserName' ? allAdvisers : column.choices);
            return [this.spaceSortValueGetter(actualValue), R?.defaultTo('', actualValue), r.ActivityId, r];
          });
          return sort(decorated)
            .by([
              { asc: u => u[0] },
              {
                [sortDirection]: u => (u[1] && isNaN(u[1]) ? u[1]?.toLowerCase() : u[1]),
              } as any,
              { asc: u => u[2] },
            ])
            .map(x => x[3]);
        }
      })
    );

  constructor(
    protected dropdownQuery: DropdownValueQuery,
    protected blstaffQuery: BLStaffsQuery,
    protected store: ActivityStore,
    public uiQuery: ActivityUiQuery,
    protected userQuery: UserQuery,
    protected businessConfigQuery: BusinessConfigQuery
  ) {
    super(store);
  }

  /** Create a value usable as index for sorting.
   * Its only necessary to know if value is empty or not.
   * So if it is not empty, return 1 which is first in sort index.
   * And 2 if empty.
   */
  private spaceSortValueGetter(fieldValue: string | number | null | undefined): 1 | 2 {
    let stringValue: string;
    if (util.isNullOrEmpty(fieldValue)) {
      stringValue = '';
    } else if (typeof fieldValue === 'string') {
      stringValue = fieldValue?.trim();
    } else {
      stringValue = fieldValue?.toString();
    }
    if (stringValue === '') {
      return 2;
    } else {
      return 1;
    }
  }
}
