<div class="font-weight-bold mt-3 mb-3">Proposed Insurance</div>

<ng-container *ngIf="viewMode == 'view'">
  <div *ngFor="let insurance of proposedInsurances$ | async; let i = index">
    <h6 *ngIf="insurance?.provider">{{ insurance?.provider }}:</h6>
    <div class="form-row d-none d-lg-flex">
      <div class="col-12 col-lg-11">
        <div class="form-row">
          <div class="col-12 col-lg-3 mb-2">
            <label class="font-weight-bold">Life Assured</label>
          </div>
          <div class="col-12 col-lg-3 mb-2">
            <label class="font-weight-bold">Product</label>
          </div>
          <div class="col-12 col-lg-3 mb-2">
            <label class="font-weight-bold">Sum Assured</label>
          </div>
          <div class="col-12 col-lg-3 mb-2">
            <label class="font-weight-bold">Premium</label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12 col-lg-11">
        <div
          class="form-row mb-2 border-bottom"
          *ngFor="let p of insurance?.lifeAssured; let ip = index"
        >
          <div class="col-12 col-lg-3 mb-2">
            <input
              class="form-control crt-form-control"
              placeholder="Life Assured"
              type="text"
              [value]="p?.lifeAssuredName || ''"
              disabled
            />
          </div>
          <div class="col-12 col-lg-3 mb-2">
            <input
              class="form-control crt-form-control"
              placeholder="Product"
              type="text"
              value="{{ p.product }}"
              disabled
            />
          </div>
          <div class="col-12 col-lg-3 mb-2">
            <ng-container *ngIf="p.allowDropdown; else showInput">
              <input
                class="form-control crt-form-control"
                placeholder="Benefit"
                type="text"
                value="{{ p.benefit | currency }}{{
                  p.allowDropdown ? ' excess' : ''
                }}"
                disabled
              />
            </ng-container>
            <ng-template #showInput>
                <input
                  class="form-control crt-form-control"
                  placeholder="Benefit"
                  type="text"
                  value="{{ p.benefit | currency }}{{
                    p.showPerMonth ? '/month' : ''
                  }}"
                  currencyMask
                  disabled
                />
            </ng-template>
          </div>
          <div class="col-12 col-lg-3 mb-2">
            <div class="dollar-icon">
              <input
                class="form-control crt-form-control"
                placeholder="Premium"
                type="text"
                [(ngModel)]="p.premium"
                [ngModelOptions]="{ standalone: true }"
                currencyMask
                disabled
              />
              <i>$</i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-1">
        <button
          id="editProposedInsuranceBtn_{{i}}"
          type="button"
          class="icon-btn pr-0"
          (click)="editProduct(insurance)"
					[disabled]="isAddEditMode"
        >
          <i class="material-icons md-16"> edit </i>
        </button>
        <button
          id="deleteProposedInsuranceBtn_{{i}}"
          type="button"
          class="icon-btn pl-0"
          (click)="deleteProposedInsurance(insurance.cRTId)"
					[disabled]="isAddEditMode"
        >
          <i class="material-icons md-16"> delete </i>
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-lg-11">
        <div class="form-row mb-2">
          <div class="col-0 col-lg-6"></div>
          <div
            class="
              col-12 col-lg-3
              text-lg-right
              d-lg-flex
              justify-content-end
              align-items-center
            "
          >
            <label class="font-weight-bold tap-text-primary">Policy Fee</label>
          </div>
          <div class="col-12 col-lg-3">
            <div class="dollar-icon">
              <input
                class="form-control crt-form-control"
                placeholder="Policy Fee"
                type="text"
                [(ngModel)]="insurance.policyFee"
                [ngModelOptions]="{ standalone: true }"
                currencyMask
                disabled
              />
              <i>$</i>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-0 col-lg-6"></div>
          <div
            class="
              col-12 col-lg-3
              text-lg-right
              d-lg-flex
              justify-content-end
              align-items-center
            "
          >
            <label class="tap-text-primary font-weight-bold"
              >Total Premium</label
            >
          </div>
          <div class="col-12 col-lg-3">
            <div class="dollar-icon">
              <input
                class="form-control crt-form-control"
                placeholder="Premium"
                type="text"
                value="{{ insurance?.sumOfLifeAssured || 0 }}"
								appCurrencyFormatter
                disabled
              />
              <i>$</i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row mb-2" *ngFor="let d of insurance?.policyDocuments">
      <div class="col-12 col-lg-auto word-break font-weight-bold">
        <span
          id="downloadPolicyDocumentBtn_{{i}}"
          class="cursor-pointer px-1"
          (click)="downloadLink(d.referenceId)"
          >{{ d.value }}</span
        >
      </div>
    </div>
  </div>
</ng-container>

<div class="row">
  <div class="col-lg-4 my-4">
    <button
      id="addProposedInsuranceBtn"
      type="button"
      class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto mb-2"
      (click)="addProduct()"
			[disabled]="isAddEditMode"
    >
      Add New +
    </button>
  </div>
</div>
