import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { KiwiSaverAdviceProcessPageIds } from '@shared/models/advice-process/advice-process.model';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Sidebar } from '../_shared/models/sidebar.model';
import { AdviceSummaryReportBuilderStore } from './advice-summary/advice-summary-report-builder/states/advice-summary-report-builder.store';
import { AdviceSummaryService } from './advice-summary/states/advice-summary.service';
import { DeclarationService } from './declaration/state/declaration.service';
import { DisclosureService } from './disclosure/state/disclosure.service';
import { CrtKsFactFindTabColorsService } from './fact-find/states/crt-ks-fact-find-tab-colors.service';
import { KoatIntroService } from './introduction/state/introduction.service';
import { ScopeOfServiceService } from './scope-of-services/state/scope-of-service.service';
import { kiwisaverSidebars } from './state/crt-kiwisaver-sidebars';
import { CrtKiwiSaverQuery } from './state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from './state/crt-kiwisaver.service';

declare const $: JQueryStatic;

@Component({
	selector: 'app-crt-kiwisaver',
	templateUrl: './crt-kiwisaver.component.html',
	styleUrls: ['./crt-kiwisaver.component.scss'],
})
export class CrtKiwiSaverComponent implements OnInit, AfterContentChecked, OnDestroy {
	private onDestroy$ = new Subject<void>();

	sidebars: Sidebar[];
	sidebars$ = this.query.sidebars$.pipe(
		distinctUntilChanged(),
		withLatestFrom(this.query.isAdviceProcessEnded$),
		map(([sidebars, isEnded]) => this.disableTabs(sidebars, isEnded)),
		tap((x) => {
			this.sidebars = x;
			this.cd.detectChanges();
		}),
	);
	innerWidth: number;
	isToggleSidebar: boolean;
	familyName$ = this.query.primaryClient$.pipe(map((x: PrimaryClientState) => x?.groupName || ''));
	staffId$ = this.service.adviceProcess$.pipe(map((x) => x.adviser));
	isTabLoading$ = this.service.isTabLoading$;

	radios = of([
		{ text: 'One', value: 1 },
		{ text: 'Two', value: 2 },
		{ text: 'Three', value: 3 },
	]);

	currentRoute = '';
	currentRouteData = null;

	@ViewChild('oatHeader') oatHeader: ElementRef;

	isAdviceProcessEnded$ = this.query.isAdviceProcessEnded$;

	constructor(
		private service: CrtKiwiSaverService,
		private query: CrtKiwiSaverQuery,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private cd: ChangeDetectorRef,
		private ngZone: NgZone,
		private introService: KoatIntroService,
		private disclosureService: DisclosureService,
		private sosService: ScopeOfServiceService,
		private declarationService: DeclarationService,
		private ksFFTabColorsService: CrtKsFactFindTabColorsService,
		private adviceSummaryReportBuilderPeopleStore: AdviceSummaryReportBuilderStore,
		private adviceSummaryService: AdviceSummaryService,
	) {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.getChildRoutePath()),
				tap((x) => {
					this.currentRoute = x;
				}),
				takeUntil(this.onDestroy$),
			)
			.subscribe();
	}

	ngOnInit(): void {
		this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
			if (val instanceof NavigationStart) {
				this.hideSidebar();
			}
			if (val instanceof NavigationEnd) {
				this.cd.detectChanges();
			}
		});

		this.sidebars$.pipe(takeUntil(this.onDestroy$)).subscribe();

		this.initSidebarColors();
	}

	ngAfterContentChecked() {
		this.cd.detectChanges();
		const fHeight = $('.crt-main-page__sidebar__header').height();
		// const fOuterHeight = $('.crt-main-page__sidebar__header').outerHeight();
		const sidebarNav = $('[role="sidebar"]').outerHeight();
		$('app-header').height(fHeight);
		$('[role="content-header"]').height(fHeight);
		$('[role="main-content"]').css('min-height', `${sidebarNav}px`);
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.innerWidth = window.innerWidth;
		if (this.innerWidth < 992) {
			this.isToggleSidebar = false;
			$('.crt-main-page').removeClass('open');
		}
	}

	onActivate() {
		this.ngZone.run(() => {
			setTimeout(() => {
				this.oatHeader?.nativeElement?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}, 100);
		});
	}

	toggleSidebar() {
		this.isToggleSidebar = !this.isToggleSidebar;
		if (this.isToggleSidebar) {
			$('.crt-main-page').addClass('open');
		} else {
			$('.crt-main-page').removeClass('open');
		}
	}

	hideSidebar() {
		this.isToggleSidebar = false;
		$('.crt-main-page').removeClass('open');
	}

	triggerNavigate(path: string, isSub: boolean, _pageCode: string, subpath = null) {
		if (path === this.currentRoute) {
			return;
		}
		// const urlArr = this.router.url?.split('/') ?? [];
		// const end = urlArr.length - 1;
		// const baseUrl = urlArr.slice(1, end)?.join('/');
		// const navigateTo = subpath ? `${baseUrl}/${subpath}` : `${baseUrl}/${path}`;
		const sidebarMenu = kiwisaverSidebars;
		const paths = [path];
		// const isFactFindRoutes =
		// 	kiwisaverFactFindRoutes.findIndex((r) => r.path === path) !== -1;
		const menu = sidebarMenu.find((x) => x.link === path);
		if (menu?.parentRouterLink) {
			paths.unshift(menu.parentRouterLink);
		}
		// navigate relative to activatedroute so parent route guard or resolver will not invoke
		if (isSub) {
			paths.push(subpath);
		}
		this.router.navigate(paths, {
			relativeTo: this.activatedRoute,
		});
	}

	isActive(path: string) {
		if (path) {
			if (path.includes('/')) {
				path = path.split('/').slice(1)[0];
			}
			const getCurrentPath = this.getCurrentPath();
			return path === getCurrentPath;
		}
		return false;
	}

	getCurrentPath() {
		// Check if there's a sub path
		if (this.activatedRoute.snapshot.firstChild.firstChild) {
			if (this.activatedRoute.snapshot.firstChild.firstChild.url?.length) {
				// Handles Application path. If it has an Application Id, return 'application' only
				if (this.activatedRoute.snapshot.firstChild.firstChild.params?.applicationId) {
					return this.activatedRoute.snapshot.firstChild.url[0]?.path;
				}

				// Return the last bit of the Url instead
				const urlEnd = this.activatedRoute.snapshot.firstChild.firstChild.url.length - 1;

				return this.activatedRoute.snapshot.firstChild.firstChild.url[urlEnd]?.path;
			} else {
				return this.activatedRoute.snapshot.firstChild.url[0]?.path;
			}
		} else {
			// Return the last bit of the Url instead
			const urlEnd = this.activatedRoute.snapshot.firstChild.url?.length - 1;
			return this.activatedRoute.snapshot.firstChild.url[urlEnd]?.path;
		}
	}

	disableTabs(sidebar: Sidebar[], isEnded: boolean): Sidebar[] {
		const pageIds = KiwiSaverAdviceProcessPageIds;

		// Array of pages that are dependant on Your KiwiSaver Tab
		const factFindPageIds = [
			pageIds.WhyKiwiSaver,
			pageIds.HowKiwiSaverWorks,
			// pageIds.PassiveVsActive,
			// pageIds.RiskVsVolatility,
			pageIds.InvestmentStrategy,
			pageIds.KiwiSaverProjection,
			pageIds.RiskProfile,
		].map((x) => x?.toString());

		return sidebar?.map((x) => {
			if (x?.id === pageIds.FactFind) {
				return {
					...x,
					isDisabled: this.ksFFTabColorsService.disableFactFindTab(sidebar),
				};
			} else if (factFindPageIds.includes(x?.id)) {
				// Disable status dependant on Your KiwiSaver Tab
				return {
					...x,
					isDisabled:
						x?.id === pageIds.RiskProfile && isEnded
							? this.ksFFTabColorsService.disableFactFindTab(sidebar) // TAPNZ-12602
							: this.ksFFTabColorsService.disableTabsBasedFromYourKiwi(),
				};
			} else if (x?.id === pageIds.Providers) {
				return {
					...x,
					isDisabled: this.ksFFTabColorsService.disableTabs(sidebar, x?.id) || isEnded,
				};
			} else if (x?.id === pageIds.AdviceSummary) {
				return {
					...x,
					isDisabled: this.service.disableSidebarTab(sidebar, pageIds.AdviceSummary) || isEnded,
				};
			} else if (x?.id === pageIds.Declaration) {
				return {
					...x,
					isDisabled: !this.ksFFTabColorsService.riskProfileIsCompleted() || isEnded,
				};
			}
			return {
				...x,
				isDisabled: false || isEnded,
			};
		});
	}

	initSidebarColors() {
		combineLatest([
			this.introService.setTabColor(),
			this.disclosureService.setTabColor(),
			this.sosService.setTabColor(),
			this.declarationService.setTabColor(),
			this.ksFFTabColorsService.setTabColorYourKiwiSaver(),
			this.ksFFTabColorsService.setTabColorForOpenOnly(),
			this.ksFFTabColorsService.setTabColorRiskProfile(),
			this.adviceSummaryService.setTabColorAdviceSummary(),
		])
			.pipe(
				tap(() => this.cd.detectChanges()),
				takeUntil(this.onDestroy$),
			)
			.subscribe();
	}

	trackByFn(_index: number, item: Sidebar) {
		return item.id;
	}

	/**
	 * Get path from last child route
	 * @return string | null
	 */
	getChildRoutePath(): string | null {
		let child = this.activatedRoute.firstChild;
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else {
				let path = child.snapshot.url[0]?.path;
				this.currentRouteData = child.snapshot.data;
				if (!path || path === '') {
					path = this.getParentRoutePath(child);
				}
				return path;
			}
		}
		return '';
	}

	/**
	 * Get path from parent route
	 * until path is not null or empty string
	 * @return string | null
	 */
	getParentRoutePath(route: ActivatedRoute) {
		let parent = route.parent;
		while (parent) {
			const url = parent.snapshot.url;
			if (url?.length > 0 && url[0].path === '' && parent.parent) {
				parent = parent.parent;
			} else {
				this.currentRouteData = parent.snapshot.data;
				return parent.snapshot.url[0].path;
			}
		}
		return '';
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
		this.adviceSummaryReportBuilderPeopleStore.reset();
	}
}
