import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LeadSearchQuery } from '../state/lead-search.query';
import { LeadSearchService } from '../state/lead-search.service';
import {
  Metakey,
} from '../lead-search-page/lead-search-datatable.config';
import { Subject } from 'rxjs';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Row } from '../state/lead-search.model';
import { map, takeUntil } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-lead-search-columns-form',
  templateUrl: './lead-search-columns-form.component.html',
  styleUrls: ['./lead-search-columns-form.component.scss'],
})
export class LeadSearchColumnsFormComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy$: Subject<void> = new Subject<void>();

  visibleColumns$ = this.leadQuery.tableColumns$;
  hiddenColumns$ = this.leadQuery.hiddenTableColumns$;

  visibleColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];
  hiddenColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];

  isColumnSaving$ = this.leadQuery.uiStore.isColumnSaving$;

  form = new UntypedFormGroup({});
  formInitialized = false;

  constructor(
    private leadQuery: LeadSearchQuery,
    private leadService: LeadSearchService
  ) {}

  ngOnInit() {
    this.visibleColumns$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) =>
          (this.visibleColumns = res?.filter(
            (x) => x.metakey !== 'Name' && x.metakey !== 'bulk'
          ))
      );
		this.hiddenColumns$
			.pipe(
				map((res) => res?.filter((x) => x.metakey !== 'bulk')),
				takeUntil(this.onDestroy$)
			)
			.subscribe((res) => (this.hiddenColumns = res));
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      $('.disabled-sortable').parent().addClass('disabled d-none');
    });
  }

  prepareFormValue() {
    return [
      'Name',
      ...this.visibleColumns?.map((x) => x.metakey),
    ] as Metakey[];
  }

  save() {
    if (this.visibleColumns.length === 0) {
      return;
    }
    this.leadService
      .saveVisibleColumns(this.prepareFormValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => {},
        () => {},
        () => {
          this.leadService.closePopup();
        }
      );
  }
  cancel() {
    this.leadService.closePopup();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
