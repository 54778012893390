import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { applyTransaction } from '@datorama/akita';
import { EMPTY, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DocumentModelState } from 'src/app/shared/models/documents/document.model';
import { ApiService } from '../../../../../core/base/api.service';
import { BusinessService } from '../../../../../core/business/business.service';
import { CustomerService } from '../../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { IntroductionState } from '../../../../../shared/models/client-review-template/intro/intro.model';
import { objectUtil } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../client-review-template.query';
import { ClientReviewTemplateService } from '../client-review-template.service';
import { ClientReviewTemplateStore } from '../client-review-template.store';

@Injectable({
  providedIn: 'root',
})
export class IntroService extends ClientReviewTemplateService {
  intro$ = this.query.intro$;

  constructor(
    protected api: ApiService,
    protected dropdownValueQuery: DropdownValueQuery,
    protected store: ClientReviewTemplateStore,
    protected query: ClientReviewTemplateQuery,
    protected customerService: CustomerService,
    protected route: ActivatedRoute,
    protected businessService: BusinessService
  ) {
    super(dropdownValueQuery, store, query, customerService, businessService);
  }

  getIntro(adviceProcessId) {
    const endpoint = `crt/settings/${adviceProcessId}/IP`;
    return !!this.query.getValue().intro
      ? of(undefined)
      : this.api.get<IntroductionState>(endpoint).pipe(
        tap((x) => {
          applyTransaction(() => {
            const state = x ? objectUtil.mapPascalCaseToCamelCase(x) : {};
            this.store.setIntro(state);
          });
        }),
        catchError(() => of({}))
      );
  }

  updateIntro(intro) {
    const endpoint = `crt/settings/0`;
    const body = objectUtil.mapCamelCaseToPascalCase(intro);
    return this.api.put<any>(endpoint, body).pipe(
      tap((x) =>
        applyTransaction(() => {
          this.store.setIntro(intro);
        })
      ),
      catchError(() => EMPTY)
    );
  }

  getFile(id: number) {
    return this.api.get<DocumentModelState>(`documents/${id}`).pipe(catchError(() => EMPTY));
  }

  getDocumentFromURL(url: string) {
    return this.api.getExternalResource(url, { responseType: 'text' }).pipe(catchError(() => EMPTY));
  }
}
