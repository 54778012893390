import { Component } from '@angular/core';
import { fileUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, Subject } from 'rxjs';
import { delay, map, mergeMap, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { JsonResultStatus } from '../../../core/base/api.service';
import { logMessage, unallowedFileChars } from '../../error-message/error-message';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

/**
 * allowed file types in
 * LOAT - Declaration, Authority to Proceed
 * MOAT - Disclosure, Scope of Service, Declaration, Application > Documents
 */
export const ALLOWED_DOCUMENT_FILE_TYPES = '.pdf, image/jpg, image/jpeg, image/png, image/bmp, .docx, .csv';

@Component({
	selector: 'app-upload-modal',
	templateUrl: './upload-modal.component.html',
	styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent implements UploadModalModel {
	// set outside
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	upload: (req: FormData, dataForFrontUpdate?) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	customUpload: (req: any) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	data: any;
	isSingleUpload: boolean;
	maxFileSizeText = '25MB';
	maxFileSize: number; // File Size in KB
	restrict: string;
	additionalInfo: string;
	isFileList: boolean;
	headerTitle: string;

	fileform: FormData;
	filelist: FileList;
	filenames: string[];
	success: boolean;
	failed: boolean;
	uploading: boolean;
	message: string;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace types
	responseMessage: any;

	cancelLoading: boolean;

	encryptedFilePrompt: string;

	currentFileSize: number;
	allowedFileExtensions: string[];
	stripFileName = false;

	public bsConfigModalRef: BsModalRef;

	get dataForFrontUpdate() {
		return Object.assign({}, this.data, { filenames: this.filenames });
	}

	constructor(
		public bsModalRef: BsModalRef,
		private loggerService: LoggerService,
		private modalService: BsModalService,
	) {
		this.filenames = [];
		this.fileform = null;
		this.filelist = null;
		this.success = false;
		this.uploading = false;
		this.message = 'Successfully uploaded file!';
	}

	selectFile(_event) {
		if (this.isSingleUpload) {
			this.fileform = null;
			this.filelist = null;
			this.filenames = [];
		}
	}

	checkEncryptedFiles(fileList: FileList) {
		for (const file of Array.from(fileList)) {
			const reader = new FileReader();

			reader.addEventListener('load', () => {
				const subject = new Subject<boolean>();
				const result = reader.result as string;
				if (
					result.includes('Encrypt') ||
					result.substring(result.lastIndexOf('<<'), result.lastIndexOf('>>')).includes('/Encrypt')
				) {
					const confirm$ = new Observable((obs: Observer<void>) => {
						subject.next(true);
						obs.complete();
					});

					const decline$ = new Observable((obs: Observer<void>) => {
						subject.next(false);
						this.filelist = null;
						this.filenames = [];
						this.customUpload(null);
						this.bsConfigModalRef.hide();
						obs.complete();
					});

					const close$ = new Observable((obs: Observer<void>) => {
						subject.next(false);
						this.bsConfigModalRef.hide();
						obs.complete();
					});

					const initialState = {
						header: '',
						message: logMessage.shared.fileIsEncrypted.error,
						subMessage: this.encryptedFilePrompt,
						secondaryMessage: `Do you wish to proceed with uploading ${file.name}`,
						confirm$,
						decline$,
						close$,
					};
					this.bsConfigModalRef = this.modalService.show(ConfirmModalComponent, {
						class: 'modal-dialog-centered modal-dialog',
						initialState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				}
			});

			reader.readAsText(file);
		}
	}

	chooseFile(event) {
		if (this.uploading) {
			return;
		}
		const fileList: FileList = event.target.files;
		const limitSize = this.maxFileSize ? this.maxFileSize : 25 * 1024;
		if ((Array.from(fileList) as File[])?.some((x) => Math.round(x.size / 1024) > limitSize)) {
			this.loggerService.Warning(
				{},
				logMessage.shared.fileUploadSize.dynamic.error.replace('%fileSize%', this.maxFileSizeText),
			);
			return;
		}

		if (
			this.currentFileSize &&
			(Array.from(fileList) as File[])?.some((x) => Math.round(x.size / 1024) + this.currentFileSize > limitSize)
		) {
			this.loggerService.Warning(
				{},
				logMessage.shared.fileUploadSize.dynamic.error.replace('%fileSize%', this.maxFileSizeText),
			);
			return;
		}

		if (this.allowedFileExtensions?.length > 0) {
			const hasInvalidFile = (Array.from(fileList) as File[])?.some((x) => {
				const ext = x?.name?.split('.')?.reverse()?.[0];
				const allowedExtension = this.allowedFileExtensions?.map((x) => x?.toLowerCase());
				const extension = ext?.toLowerCase();
				return !allowedExtension?.includes(extension);
			});
			if (hasInvalidFile) {
				this.loggerService.Warning({}, logMessage.shared.fileInvalidExtension.error);
				return;
			}
		}

		if ((Array.from(fileList) as File[])?.some((f: File) => unallowedFileChars.some((x) => f.name?.includes(x)))) {
			this.loggerService.Warning({}, logMessage.shared.fileName.error);
			return;
		}

		if (this.encryptedFilePrompt) {
			this.checkEncryptedFiles(fileList);
		}

		const fileform: FormData = new FormData();

		if (fileList.length > 0) {
			Array.from(fileList)?.forEach((f) => {
				fileform.append('', f, this.stripFileName ? fileUtil.formatFileName(f.name) : f.name);
			});
			if (this.data) {
				Object.keys(this.data)?.forEach((d) => {
					fileform.append(d, this.data[d]);
				});
			}
			this.fileform = fileform;
			this.filelist = fileList;
			this.filenames = Array.from(fileList)?.map((x) =>
				this.stripFileName ? fileUtil.formatFileName(x.name) : x.name,
			);
		}
	}

	uploadClick = () => {
		if (this.cancelLoading) {
			return;
		}
		if (Array.from(this.filelist)?.some((f: File) => unallowedFileChars.some((x) => f.name?.includes(x)))) {
			this.loggerService.Warning({}, logMessage.shared.fileName.error);
			return;
		}

		return this.customUpload
			? new Observable((obs) => {
					this.uploading = true;
					obs.next();
					obs.complete();
				})
					.pipe(mergeMap(() => this.customUpload(this.filelist)))
					.subscribe({
						next: (_value) => (this.uploading = false),
						error: (_error) => (this.uploading = false),
						complete: () => this.close(),
					})
			: new Observable((obs) => {
					obs.next();
					obs.complete();
				})
					.pipe(
						tap(() => {
							this.uploading = true;
						}),
						map(() => (this.isFileList ? this.filelist : this.fileform)),
						mergeMap((req) =>
							!this.dataForFrontUpdate
								? this.upload(req as FormData)
								: this.upload(req as FormData, this.dataForFrontUpdate),
						),
						delay(1000),
					)
					.subscribe({
						next: (value) => (this.responseMessage = value),
						error: (_error) => (this.uploading = false),
						complete: () => {
							this.success = true;
							this.uploading = false;

							setTimeout(() => {
								this.close();
							}, 5000);
						},
					});
	};

	close() {
		this.cancelLoading = true;
		this.bsModalRef.hide();
		setTimeout(() => (this.cancelLoading = false), 500);
	}
}

export interface UploadModalModel {
	upload: (req: FormData) => Observable<JsonResultStatus>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	customUpload: (req: any) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	data: any;
	isSingleUpload: boolean; // Multiple file upload
	restrict: string; // File restrictions, by default no restrictions
	additionalInfo: string; // Additional direction information
	isFileList: boolean; // request as filelist
	headerTitle: string; // Header title
	allowedFileExtensions: string[]; // Allowed file extensions
}
