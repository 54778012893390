/**
 * Get all email body inner text without HTML tags
 * @param content string: html content or innerHtml
 * @returns string: content cleaned up
 */
export const getEmailContentNoHtml = (content = '') => {
	const template =
		content?.replace(/(<br>|<\/br>|<br \/>|<br[^>]*>)/gim, '\n') || '';
	const id = 'removeHtmlTags';
	const newHtml = document
		.createRange()
		.createContextualFragment(`<div id="${id}">${template}</div>`);

	const paragraphTexts = Array.from(
		newHtml.querySelectorAll(`#${id} > p, #${id} li`)
	)
		?.map((x: HTMLElement) => x?.innerText)
		?.join('\n');

	// replace less than and greater than sign to html entities in a string
	let stringWithoutHTML = paragraphTexts || '';
	[
		['<', '&lt;'],
		['>', '&gt;'],
	].forEach(([sign, htmlEntity]) => {
		// @ts-ignore-next
		stringWithoutHTML = stringWithoutHTML.replaceAll(sign, htmlEntity);
	});
	return stringWithoutHTML;
};
