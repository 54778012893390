<ng-container *ngIf="!!d.value; else offlineNoDocument">
	<ng-container *ngIf="isAPClaim; else nonClaimTemplate">
		<button
			type="button"
			class="tap-btn tap-btn--primary tap-text-color-h1 w-40 mr-1 px-sm-0"
			(click)="viewFile()"
		>
			View
		</button>
		<button
			*ngIf="isEditable"
			type="button"
			class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
			(click)="removeFile()"
		>
			Remove
		</button>
	</ng-container>
	<ng-template #nonClaimTemplate>
		<button
			type="button"
			class="tap-btn tap-btn--primary tap-text-color-h1 w-40 mr-1 px-sm-0"
			(click)="downloadFile(d?.value?.documentID, d?.field, d.value.documentName)"
		>
			View
		</button>
		<ng-container *appRestrictTo="['FEAP']">
			<ng-container *ngIf="isEditable">
				<button
					type="button"
					class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
					(click)="openUploadModal('Replace', d?.field)"
				>
					Replace
				</button>
			</ng-container>
		</ng-container>
	</ng-template>
</ng-container>
<ng-template #offlineNoDocument>
	<button
		*ngIf="isEditable"
		type="button"
		class="tap-btn tap-btn--default w-40 mr-1 px-sm-0"
		(click)="openUploadModal('Upload New', d?.field)"
	>
		Upload New
	</button>
	<ng-container *ngIf="isEditable">
		<button
			[disabled]="d.isUploading"
			type="button"
			class="tap-btn tap-btn--default w-40 px-sm-0 w-sm-50"
			title="Link Document"
			(click)="linkDocument(d?.field)"
		>
			Link Document
		</button>
	</ng-container>
</ng-template>
