import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	TemplateRef,
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { either, clone, equals, complement, isNil, isEmpty } from 'ramda';
import {
	combineLatest,
	EMPTY,
	forkJoin,
	iif,
	Observable,
	Observer,
	of,
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	concatMap,
	distinctUntilChanged,
	filter,
	finalize,
	map,
	mergeMap,
	skipWhile,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { ClientProfileQuery } from 'src/app/modules/crm/client-profile/states/client-profile.query';
import { ClientProfileStore } from 'src/app/modules/crm/client-profile/states/client-profile.store';
import {
	getUploadSuccess,
	logMessage,
} from 'src/app/shared/error-message/error-message';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { UploadModalComponent } from 'src/app/shared/modal/upload-modal/upload-modal.component';
import { DocumentGroupState } from 'src/app/shared/models/documents/document-group.model';
import {
	DocumentModelState,
	DocumentTypesMOAT,
} from 'src/app/shared/models/documents/document.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { LinkDocumentComponent } from 'src/app/shared/services/link-document/link-document.component';
import { convertUtil, fileUtil } from 'src/app/util/util';
import { CrtDocumentService } from '../../../../_shared/service/crt-document.service';
import { createMOATDocumentTemplate } from '../../../../_shared/templates/templates-utils/templates.mapper';
import { PeopleEntitiesQuery } from '../../../client-sop/people-entities/state/people-entities.query';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import { CashDepositQuery } from '../funding-required/cash-deposit/state/cash-deposit.query';
import { PropertyPurchaseQuery } from '../funding-required/property-purchase/state/property-purchase.query';
import { PurposeQuery } from '../purpose/state/purpose.query';
import {
	ApplicationDocument,
	documentFileName,
	downloadable,
} from './state/documents.model';
import { ApplicationDocumentQuery } from './state/documents.query';
import { ApplicationDocumentService } from './state/documents.service';
import { ApplicationDocumentMapper } from './state/documents.mapper';
import { DocumentList } from 'src/app/modules/crt-settings/soa-settings/document-list/state/document-list.model';

@Component({
	selector: 'app-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnChanges, OnDestroy {
	private onDestroy$ = new Subject<void>();
	optionModalRef: BsModalRef;
	bsModalRef: BsModalRef;
	linkModalRef: BsModalRef;
	form: UntypedFormGroup;
	submitted = false;
	isEdit = false;
	isAdd = false;
	init = true;
	showAddDropdown = false;
	uploadingIndex: number = null;
	isLoading$ = this.query.selectLoading();
	isSorting = false;
	documentsFromSettings = [];
	clientDocuments: DocumentGroupState;
	shouldUpdateSort = false;
	tempData: object;

	@Input() applicationDocuments: ApplicationDocument[];
	@Input() documentSettings: DocumentList[];
	@Input() parentCRTId: number;
	@Output() saveCompleted: EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}> = new EventEmitter<{
		isSuccess: boolean;
		isNext: boolean;
		redirect: boolean;
	}>();
	@Output() fetchClientDocuments = new EventEmitter<boolean>();

	downloadableDocs = downloadable.pdfList;
	downloadableBankFiles = downloadable.bankFileList;
	applicationSummary = documentFileName.ApplicationSummary;

	peopleName: Array<string>;
	peopleFirstName: Array<string>;
	propertyAddresses: Array<string>;
	businessLogo: string;
	applicationBank: string;
	depositGift: number;
	prevDocumentList: ApplicationDocument[];

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private service: ApplicationDocumentService,
		private loggerService: LoggerService,
		private zone: NgZone,
		private renderer: Renderer2,
		private mortgageQuery: CrtMortgageQuery,
		private query: ApplicationDocumentQuery,
		private clientProfileQuery: ClientProfileQuery,
		private clientProfileStore: ClientProfileStore,
		private crtDocService: CrtDocumentService,
		private businessConfigQuery: BusinessConfigQuery,
		private peopleEntitiesQuery: PeopleEntitiesQuery,
		private propertyPurchaseQuery: PropertyPurchaseQuery,
		private purposeQuery: PurposeQuery,
		private cashDepositQuery: CashDepositQuery
	) {}

	ngOnChanges(changes) {
		if (changes.applicationDocuments) {
			if (!this.isSorting) {
				this.refreshDocumentList();
			}
		}
	}

	ngOnInit(): void {
		combineLatest([
			this.peopleEntitiesQuery.people$,
			this.propertyPurchaseQuery.properties$,
			this.businessConfigQuery.businessLogoUrl$,
			this.purposeQuery.purpose$,
			this.cashDepositQuery.cashDeposit$,
		])
			.pipe(
				filter(
					([people, properties, businesslogo, purpose, cashDeposit]) =>
						!!people && !!cashDeposit && !!purpose
				),
				concatMap(
					([people, properties, businesslogo, purpose, cashDeposit]) => {
						const peopleName = [];
						const peopleFirstName = [];
						const propertyAddresses = [];

						people?.map((x) => peopleName?.push(x?.name));
						people?.map((x) =>
							peopleFirstName?.push(x?.name?.split(' ')?.shift())
						);
						properties?.map((x) => propertyAddresses?.push(x?.propertyAddress));

						const GIFT = 'gift';

						const obj = {
							peopleName,
							peopleFirstName,
							propertyAddresses,
							businesslogo,
							applicationBank:
								purpose && purpose.length > 0 ? purpose[0]?.bank : '',
							depositGift:
								cashDeposit && cashDeposit.length > 0
									? +cashDeposit[0][GIFT]?.cashDepositValue?.toFixed(2)
									: 0,
						};

						return of(obj);
					}
				),
				tap((res) => {
					this.peopleName = res.peopleName;
					this.peopleFirstName = res.peopleFirstName;
					this.propertyAddresses = res.propertyAddresses;
					this.businessLogo = res.businesslogo as string;
					this.depositGift = res.depositGift;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.refreshDocumentList();

		this.service.shouldUpdateDocumentSort.subscribe((x) => {
			this.updateSort().subscribe();
		});

		this.purposeQuery.purpose$
			.pipe(
				filter((x) => !!x && x?.length > 0),
				tap((x) => {
					if (this.init && !this.applicationBank) {
						this.applicationBank = x?.[0]?.bank || '';
						this.init = false;
					}
				}),
				distinctUntilChanged((x, y) => equals(x, y)),
				skipWhile((x) => {
					const check =
						this.init ||
						this.applicationBank === x?.[0]?.bank ||
						x?.length === 0;
					if (check) {
						this.refreshDocumentList();
					}
					return check;
				}),
				tap((purpose) => {
					this.applicationBank =
						purpose && purpose.length > 0 ? purpose[0]?.bank : '';
					this.refreshDocumentList();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.clientProfileQuery.documents$
			.pipe(
				tap((x) => (this.clientDocuments = clone(x))),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	get DocumentList() {
		return this.form?.get('applicationDocuments') as UntypedFormArray;
	}

	get LastOrderValue() {
		if (this.DocumentList.controls[this.DocumentList.length - 1]) {
			return +this.DocumentList.controls[this.DocumentList.length - 1].get(
				'order'
			).value;
		}

		return 0;
	}

	buildForm() {
		this.form = this.fb.group({
			applicationDocuments: this.fb.array([]),
		});
	}

	checkboxIsTick(doc: UntypedFormGroup, index: number): void {
		if (this.prevDocumentList?.length) {
			this.prevDocumentList[index].combine = !doc.value.combine as any;
		}
		this.shouldUpdateSort = true;
	}

	prepDocumentList() {
		while (this.DocumentList?.length > 0) {
			this.DocumentList.removeAt(0);
		}

		let appDocuments = ApplicationDocumentMapper.mapBankDocuments(
			this.applicationDocuments,
			this.applicationBank,
			this.downloadableBankFiles
		);

		const prevDocs = this.prevDocumentList as ApplicationDocument[];

		if (!!this.shouldUpdateSort) {
			appDocuments = prevDocs.map((x) => ({
				...appDocuments.find((d) => d?.cRTId === x?.cRTId),
				order: x?.order,
				combine: x?.show ? x?.order : x?.combine,
				isMultiple: x?.isMultiple || true,
			}));
		}

		if (appDocuments) {
			appDocuments = appDocuments
				?.map((doc) => {
					const setting = this.documentSettings?.find(
						(setting) =>
							setting.DocumentName === doc.document && setting.IsActive
					);
					return {
						...doc,
						isMultiple: setting ? setting.IsMultiple : true,
						isEnable: setting ? setting.IsEnable : true,
					};
				})
				?.filter((doc) => doc.isEnable);
		}

		appDocuments
			?.filter((doc) => doc.show)
			?.sort((a, b) => a.order - b.order)
			?.forEach((document: ApplicationDocument, index: number) => {
				this.DocumentList?.push(this.patchValue({ ...document }));
			});

		this.prevDocumentList = clone(this.DocumentList.getRawValue());
	}

	refreshDocumentList() {
		this.buildForm();
		this.prepDocumentList();
	}

	onSelectAddOption(selectedCRTId: string) {
		if (selectedCRTId === 'Other') {
			this.DocumentList?.push(
				this.patchValue({
					order: this.LastOrderValue + 1,
					combine: true,
					fromDocumentListSettings: false,
					notRequired: false,
					isEdit: true,
					cRTId: null,
					show: true,
				})
			);

			this.prevDocumentList = clone(this.DocumentList.getRawValue());
		} else {
			const existingDocument = this.documentsFromSettings?.find(
				(doc) => doc.settingsId?.toString() === selectedCRTId
			);
			this.DocumentList?.push(
				this.patchValue({
					document: existingDocument.title,
					fromDocumentListSettings: true,
					order: this.LastOrderValue + 1,
					cRTId: null,
					isEdit: true,
				})
			);

			this.prevDocumentList = clone(this.DocumentList.getRawValue());
		}
		this.showAddDropdown = false;
	}

	patchValue(doc): UntypedFormGroup {
		const previousValue = this.prevDocumentList?.find(
			(x) => +x?.cRTId === +doc?.cRTId
		)?.combine;
		const fileName = this.getDocumentFileName(doc?.documentId);
		return this.fb.group({
			...doc,
			cRTId: doc?.cRTId,
			parentCRTId: this.parentCRTId,
			document: [doc?.document, [Validators.required]],
			documentId: fileName?.length
				? [doc?.documentId, [Validators.required, Validators.min(1)]]
				: null,
			fileName,
			notRequired: doc?.notRequired,
			order: doc?.order,
			fromDocumentListSettings: doc?.fromDocumentListSettings,
			combine: complement(isNil)(previousValue) ? previousValue : doc?.combine,
			isEdit:
				this.isEdit &&
				this.uploadingIndex &&
				+this?.prevDocumentList[this.uploadingIndex]?.cRTId === doc?.cRTId
					? this.isEdit
					: doc?.isEdit,
			isLoading: [false],
		});
	}

	getDocumentFileName(documentId: number) {
		const documents = [].concat(
			this.clientDocuments?.aP || [],
			this.clientDocuments?.fG || [],
			this.clientDocuments?.k || [],
			this.clientDocuments?.lR || [],
			this.clientDocuments?.m || [],
			this.clientDocuments?.o || [],
			this.clientDocuments?.cP || []
		);
		return documentId > 0
			? documents
					?.filter((doc) => doc.id === documentId)
					.map((found) => found.fileName)
			: null;
	}

	deleteConfirm(index: number) {
		const confirm = new Observable((obs: Observer<any>) => {
			this.deleteDocument(index);
			obs.complete();
		});
		const initState = {
			header: 'Delete Application Document',
			message: `Are you sure you want to delete this document?`,
			delete$: confirm,
			canDelete: true,
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	toggleEdit(index: number) {
		const data = this.form.getRawValue()?.applicationDocuments[index];
		const toggleNewValue = !data?.isEdit;
		this.isEdit = toggleNewValue;

		if (toggleNewValue) {
			this.setTempData(data);
		} else {
			this.DocumentList.controls[index].patchValue(this.tempData);
			this.prevDocumentList = clone(this.DocumentList.getRawValue());
			this.setTempData(null);
		}

		setTimeout(() => {
			// Add delay before enabling the buttons
			this.DocumentList.controls[index].get('isEdit').setValue(toggleNewValue);
		}, 300);
	}

	toggleAddDropdown() {
		if (this.isAdd) {
			this.showAddDropdown = true;
		}
	}

	setTempData(data?) {
		this.tempData = data || null;
	}

	deleteDocument(index: number) {
		const cRTId = +this.DocumentList.controls[index].get('cRTId').value;
		this.shouldUpdateSort = true;

		this.updateSort()
			.pipe(
				concatMap(() => this.service.delete(cRTId)),
				tap(() => {
					this.DocumentList.removeAt(index);
					this.setTempData();
				}),
				take(1)
			)
			.subscribe();
	}

	saveDocument(index: number) {
		const formValue = this.DocumentList.controls[index].value;
		const cRTId = this.DocumentList.controls[index].get('cRTId').value;

		if (cRTId || cRTId !== null) {
			this.DocumentList.controls[index].get('isLoading').setValue(true);
			this.updateFn$(formValue, false)
				.pipe(
					finalize(() => {
						this.resetAddEdit();
						this.DocumentList.controls[index].get('isLoading').setValue(false);
						this.DocumentList.controls[index].get('isEdit').setValue(false);
					}),
					take(1)
				)
				.subscribe();
		} else {
			const promise = this.shouldUpdateSort
				? this.updateDocumentSorting()
				: of(null);

			promise
				.pipe(
					take(1),
					concatMap(() => this.service.add(formValue)),
					finalize(() => {
						this.resetAddEdit();
						this.DocumentList.controls[index].get('isLoading').setValue(false);
						this.DocumentList.controls[index].get('isEdit').setValue(false);
					})
				)
				.subscribe();
		}
	}

	updateDocumentSorting() {
		const data = this.updatePriority(this.DocumentList.getRawValue());
		this.isSorting = true;

		const documents = data
			?.filter((value) => !!value.cRTId)
			?.map((doc) => ({
				AdviceProcessId: doc?.adviceProcessId,
				CRTId: doc?.cRTId,
				ParentCRTId: doc?.parentCRTId,
				Document: doc?.document,
				DocumentId: doc?.documentId,
				Combine: doc?.combine,
				NotRequired: doc?.notRequired,
				Order: doc?.order,
				FromDocumentListSettings: doc?.fromDocumentListSettings,
				SectionCode: doc?.sectionCode,
			}));

		return this.service.updateDocumentSorting(documents).pipe(
			finalize(() => {
				this.shouldUpdateSort = false;
				this.isSorting = false;
			})
		);
	}

	resetAddEdit() {
		this.isAdd = false;
		this.isEdit = false;
		this.setTempData();
	}

	updateFn$ = (data, refetch) => {
		return this.service.update(data, refetch);
	};

	removeDocument(index: number) {
		const cRTId = this.DocumentList.controls[index].get('cRTId')?.value;

		if (cRTId) {
			this.toggleEdit(index);
		} else {
			this.DocumentList.removeAt(index);
			this.toggleAddDropdown();
		}
	}

	trackByFn = (i, data) => {
		return data.cRTId;
	};

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			if (this.prevDocumentList?.length) {
				moveItemInArray(
					this.prevDocumentList,
					event.previousIndex,
					event.currentIndex
				);
			}
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			if (this.prevDocumentList?.length) {
				transferArrayItem(
					this.prevDocumentList,
					event.container.data,
					event.previousIndex,
					event.currentIndex
				);
			}
			// if transfer, recalculate the order of previous (the list from drag)
			event.previousContainer.data.forEach((x, index) => {
				x.order = index;
			});
		}
		// always, recalculate the order of the container (the list to drag)
		event.container.data.forEach((x, index) => {
			x.order = index;
		});

		if (event.currentIndex !== event.previousIndex) {
			this.shouldUpdateSort = true;
			this.rearrangeDocumentList();

			this.shouldUpdateSort = true;
		}
		// this.updateSort().pipe(take(1)).subscribe();
	}

	rearrangeDocumentList() {
		let appDocuments = this.updatePriority(
			this.DocumentList.getRawValue()
		)?.sort((a, b) => a.order - b.order);

		const docSettings = this.documentSettings;

		if (appDocuments) {
			appDocuments = appDocuments
				?.map((doc) => {
					const setting = docSettings?.find(
						(setting) =>
							setting.DocumentName === doc.document && setting.IsActive
					);
					return {
						...doc,
						isMultiple: setting ? setting.IsMultiple : true,
						isEnable: setting ? setting.IsEnable : true,
					};
				})
				?.filter((doc) => doc.isEnable);
		}

		while (this.DocumentList?.length > 0) {
			this.DocumentList.removeAt(0);
		}

		appDocuments?.forEach((document: ApplicationDocument, index: number) => {
			this.DocumentList?.push(this.patchValue(document));
		});

		this.prevDocumentList = clone(this.DocumentList.getRawValue());
	}

	updatePriority(list) {
		return list?.map(({ order, show, ...item }, i) => ({
			...item,
			order: i,
		}));
	}

	openUploadOptionModal(template: TemplateRef<any>, i: number) {
		this.uploadingIndex = i;
		this.optionModalRef = this.modalService.show(template, {
			class: 'modal-dialog-centered',
			ignoreBackdropClick: true,
		});
	}

	resetLoading() {
		this.DocumentList.controls[this.uploadingIndex]
			.get('isLoading')
			.setValue(false);
	}

	uploadDocuments() {
		const isNew =
			!!!this.DocumentList.controls[this.uploadingIndex].get('documentId')
				.value;

		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

		// convert Image File -> Base64 -> HTML img -> PDF
		const convertImageToBase64 = (file: File): Observable<string> => {
			return new Observable((obs) => {
				obs.next(file);
				obs.complete();
			}).pipe(
				// convert file to base64
				mergeMap(() => convertUtil.simpleConvertToBase64(file)),
				// create a html img string and add the converted base64 image in src
				map((base64) => `<img src="${base64}" />`),
				// convert html img with base64 src to pdf
				mergeMap((img) => {
					return this.crtDocService.generatePDFbase64(img, {
						FileName: convertFilename(file),
					});
				})
				// switchMap((base64: string) => this.service.imageToPDF({
				//   document: base64,
				//   fileName: convertFilename(file),
				// })),
			);
		};

		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() =>
							// check if the file is image
							req[0].type.startsWith('image/'),
						// If the file is image. We need to convert it to pdf
						convertImageToBase64(req[0]),
						convertUtil.simpleConvertToBase64(x[0]).pipe(take(1))
					)
				),
				map((file) => {
					const f = ((file as string) ?? '')?.replace(/^data:(.*,)?/, '');
					const size = +fileUtil.getFileSizeKb(f);
					if (size > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileUploadSize.single.error
						);
						return throwError(logMessage.shared.fileUploadSize.single.error);
					}
					return file;
				}),
				filter((file) => !!file),
				map((file) => ({
					Document: `${((file as string) ?? '')?.replace(/^data:(.*,)?/, '')}`,
					FileName: convertFilename(req[0]),
					DocumentType: ServicesCodes.Mortgage,
					Type: DocumentTypesMOAT.Application,
					ReferenceId: this.mortgageQuery.getValue().adviceProcessId,
					CustomerId: this.mortgageQuery.getValue().primaryClient?.customerID,
				})),
				concatMap((x) => this.service.uploadDocument(x)),
				tap(() => {
					this.DocumentList.controls[this.uploadingIndex]
						.get('fileName')
						.setValue(convertFilename(req[0]));
				}),
				concatMap((documentId) =>
					this.updateDocument(documentId, false).pipe(map(() => documentId))
				),
				tap((doc) => {
					// this.shouldUpdateSort = true;
					// const document = this.DocumentList.controls[this.uploadingIndex]
					// 	.value as ApplicationDocument;
					this.DocumentList.controls[this.uploadingIndex]
						.get('documentId')
						.setValue(doc);
					// this.DocumentList.controls[this.uploadingIndex]
					// 	.get('fileName')
					// 	.setValue(convertFilename(req[0]));
					this.zone.run(() =>
						this.loggerService.Success(
							{},
							getUploadSuccess(convertFilename(req[0]))
						)
					);
				}),
				take(1)
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Save Document',
			restrict: '.pdf,image/jpeg,image/jpg,image/png',
			maxFileSize: 10000,
			maxFileSizeText: '10MB',
		};
		this.bsModalRef = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	linkDocument(document?: any) {
		const isNew =
			!!!this.DocumentList.controls[this.uploadingIndex].get('cRTId').value;
		const initState = {
			selectedDetail: 'Link Document',
			document: this.clientDocuments,
			initialSelectedTab: ServicesCodes.Mortgage?.toLowerCase(),
			maxFileSize: 10000,
			// tabs: [ServicesCodes.AdviceProcess?.toString()],
		};
		this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		this.linkModalRef.content.getSelectedDocumentValue$
			.pipe(
				filter((x) => !!x),
				tap((x: DocumentModelState) => {
					if (this.clientDocuments.cP?.some((d) => d.id === x.id)) {
						this.clientDocuments.cP = this.clientDocuments.cP.filter(
							(cd) => cd.id !== x.id
						);
						this.clientDocuments.m?.unshift({
							...x,
							fileName: x.fileName.replace(x.fileExtension, ''),
						});
						this.clientProfileStore.setDocuments(this.clientDocuments);
					}
				}),
				concatMap((doc: DocumentModelState) => {
					const isFromCP = this.clientDocuments.cP?.some(
						(d) => d.id === doc.id
					);
					this.DocumentList.controls[this.uploadingIndex]
						.get('fileName')
						.setValue(
							!isFromCP
								? doc.fileName
								: doc.fileName.replace(doc.fileExtension, '')
						);
					if (isNew) {
						this.DocumentList.controls[this.uploadingIndex]
							.get('documentId')
							.setValue(doc.id);
						// this.DocumentList.controls[this.uploadingIndex]
						// 	.get('fileName')
						// 	.setValue(doc.fileName);
						return EMPTY;
					} else {
						return this.updateDocument(doc.id, false).pipe(
							map((res) => {
								const NEWLY_CREATED_DOCUMENTS = Boolean(res?.documentID);
								if (NEWLY_CREATED_DOCUMENTS) {
									// this.applicationDocuments[this.uploadingIndex].documentId = res.documentID;
									res.id = res.documentID;
									this.clientDocuments?.aP.push(res);
									this.clientProfileStore.setDocuments(this.clientDocuments);
									this.prepDocumentList();
								}
								return res;
							})
						);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	updateDocument(documentId: number, refetch: boolean) {
		this.DocumentList.controls[this.uploadingIndex]
			.get('documentId')
			.setValue(documentId);
		const formValue = this.DocumentList.controls[this.uploadingIndex].value;
		return this.updateFn$(formValue, refetch);
	}

	downloadLink(id: number) {
		this.service
			.downloadLink(id)
			.pipe(
				tap((x) => {
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					this.renderer.setAttribute(a, 'href', x);
					a.click();
				}),
				take(1)
			)
			.subscribe();
	}

	updateSort() {
		const requests$ = [];
		const data = this.updatePriority(this.DocumentList.getRawValue());
		data
			?.filter((value) => !!value.cRTId)
			?.forEach((value) => requests$?.push(this.updateFn$(value, false)));
		this.isSorting = true;

		return this.shouldUpdateSort
			? forkJoin(requests$).pipe(
					concatMap(() =>
						this.service.getApplicationDocuments(this.parentCRTId)
					),
					finalize(() => {
						this.isSorting = false;
						this.shouldUpdateSort = false;
					}),
					take(1),
					catchError(() => EMPTY)
			  )
			: of(null).pipe(
					finalize(() => {
						this.isSorting = false;
						this.shouldUpdateSort = false;
					})
			  );
	}

	addNew() {
		this.isAdd = true;
		this.showAddDropdown = true;
	}

	hasEmptyFilesCombined() {
		// Check if a Document is ticked to 'include in the application' but has no documents linked
		const unlinkedDocs = this.updatePriority(this.DocumentList.getRawValue())
			?.filter((x) => !!x?.combine && either(isNil, isEmpty)(x?.documentId))
			?.filter((x) => x?.document !== this.applicationSummary);

		return unlinkedDocs?.length > 0;
	}

	emptyDocConfirm(isNext = false) {
		const confirm = new Observable((obs) => {
			this.continue(isNext);
			obs.next();
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});

		const initState = {
			header: 'Application Documents',
			message: `You haven't linked all Documents that will be included in the Application. Are you sure you want to proceed?`,
			confirm$: confirm,
			decline$: decline,
			isOkBtn: false,
			isAcceptBtn: false,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveSorting(isNext = false) {
		this.updateSort()
			.pipe(take(1))
			.subscribe((x) =>
				this.saveCompleted.emit({ isSuccess: true, isNext, redirect: true })
			);
	}

	next() {
		if (this.hasEmptyFilesCombined()) {
			this.emptyDocConfirm(true);
			return;
		}
		this.continue(true);
	}

	continue(isNext = false) {
		if (this.shouldUpdateSort) {
			this.updateDocumentSorting().subscribe({
				complete: () =>
					this.saveCompleted.emit({ isSuccess: true, isNext, redirect: true }),
			});
		} else {
			this.saveCompleted.emit({ isSuccess: true, isNext, redirect: true });
		}
	}

	previous() {
		if (this.hasEmptyFilesCombined()) {
			this.emptyDocConfirm();
			return;
		}
		this.continue();
	}

	downloadDocument(documentName: string, index: number) {
		this.DocumentList.controls[index].get('isLoading').setValue(true);

		const data = {
			businessLogo: this.businessLogo,
			dateToday: new Date().toLocaleString('default', {
				month: 'long',
				day: 'numeric',
				year: 'numeric',
			}),
			peopleName: this.peopleName,
			peopleFirstName: this.peopleFirstName,
			depositGift: this.depositGift,
			propertyAddresses: this.propertyAddresses,
			applicationBank: this.applicationBank,
			fileName: `${documentName}.pdf`,
		};

		of(documentName)
			.pipe(
				concatMap((x) =>
					iif(
						() => this.downloadableDocs?.includes(x),
						this.downloadPdf(index, x, data),
						this.downloadExternalFile(index, documentName, data)
					)
				),
				take(1)
			)
			.subscribe();
	}

	downloadExternalFile = (index: number, documentName: string, data) =>
		of(data).pipe(
			filter((x) => {
				if (!!x?.applicationBank) {
					return true;
				} else {
					this.DocumentList.controls[index].get('isLoading').setValue(false);
					return false;
				}
			}),
			concatMap((x) =>
				this.service.downloadBankFiles(x?.applicationBank?.replace(' ', ''))
			),
			tap((x) => {
				const a = this.renderer.createElement('a');
				this.renderer.setStyle(a, 'display', 'none');
				const url = window.URL.createObjectURL(x);
				this.renderer.setAttribute(a, 'href', url);
				this.renderer.setAttribute(a, 'download', `${documentName}.xlsm`);
				a.click();
				window.URL.revokeObjectURL(url);
			}),
			finalize(() =>
				this.DocumentList.controls[index].get('isLoading').setValue(false)
			),
			take(1)
		);

	downloadPdf = (index: number, documentName: string, data) =>
		this.crtDocService
			.downloadDocumentPDF(
				createMOATDocumentTemplate(documentName, data),
				`${documentName}.pdf`
			)
			.pipe(
				tap((x) => {
					const name = `${documentName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				finalize(() =>
					this.DocumentList.controls[index].get('isLoading').setValue(false)
				),
				catchError((err) => {
					this.DocumentList.controls[index].get('isLoading').setValue(false);
					return throwError(new Error(err));
				}),
				take(1)
			);

	duplicateDocument(document: UntypedFormGroup) {
		this.isSorting = true;
		const value = document.value;
		const data = {
			...value,
			documentId: null,
			fileName: null,
		};

		this.updateSort()
			.pipe(
				concatMap(() => {
					this.isSorting = true;
					return this.service.add(data);
				}),
				finalize(() => {
					this.isSorting = false;
					this.resetAddEdit();
				}),
				take(1)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
