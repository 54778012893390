<div class="container-fluid px-0 referral-options">
	<form [formGroup]="form" [class.submitted]="submitted">
		<div class="d-none d-lg-flex referral-options__form form-row pt-2">
			<div class="col-12 col-lg-2 referral-options__icons">
				
			</div>
			<div class="col-12 col-lg-6 pl-0 text-left referral-options__service-input">
				<h5 class="pl-1">Title</h5>
			</div>
			<div class="col-12 col-lg-2 pl-0 text-dark text-center">
				<h5 class="pl-1">Allow multiple?</h5>
			</div>
		</div>
		<div
			class="form-row mb-2 pt-2 referral-options__form d-block"
			formArrayName="documentList"
		>
			<div
				cdkDropList
				class="referral-options__list"
				(cdkDropListDropped)="drop($event)"
				[cdkDropListData]="DocumentList.controls"
			>
				<div
					[formGroup]="document"
					class="d-block d-lg-flex referral-options__list-box-drag"
					
					*ngFor="
						let document of DocumentList.controls;
						let i = index;
						trackBy: trackByFn
					"
					cdkDrag
					[cdkDragData]="document"
				>
					<ng-container *ngIf="document.get('isActive').value">
						<div
							class="
								col-12 col-lg-2
								pt-2 pb-2 d-flex flex-wrap
								align-items-center
								referral-options__icons
							"
						>
							<span class="mr-3 referral-options__icons__checkbox">
								<input
									type="checkbox"
									formControlName="isEnable"
								/>
							</span>
							<span class="referral-options__icons__drag sortable-item">
								<i class="material-icons md-18 tap-text-color-h6"
									>drag_indicator</i
								>
							</span>
						</div>
						<div
							class="
								col-12 col-lg-6
								pt-2 pb-2 pl-lg-0
								referral-options__service-input
							"
							[class.disabled]="document.get('isDefault').value === true"
						>
							<input
								class="form-control w-100"
								type="text"
								formControlName="documentName"
								(mousedown)="$event.stopPropagation()"
							/>
						</div>
						<div
							class="
								col-12 col-lg-2
								pb-4 pb-lg-2
								d-flex justify-content-center
							"
						>
							<span class="pt-3 mr-3 referral-options__icons__checkbox align-middle align-items-center">
								<input
									type="checkbox"
									formControlName="isMultiple"
								/>
							</span>
						</div>
						<div
							class="
								col-12 col-lg-1
								pb-4 pb-lg-2
								actions
								referral-options__actions
								text-right text-lg-left
							"
							[class.disabled]="document.get('isDefault').value === true"
						>
							<ng-container *ngIf="!document.value.isLoading">
								<ng-container>
									<button
										type="button"
										class="icon-btn pt-2"
										(click)="updateDocument(i)"
									>
										<i class="material-icons md-20 tap-text-color-h6">delete</i>
									</button>
								</ng-container>
							</ng-container>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="row mb-2 pt-4 referral-options__btns">
			<div class="col-12 col-lg-10 pr-0 d-flex justify-content-between">
				<button
					type="button"
					class="tap-btn tap-btn-outline border-0 px-1 col-auto mb-2"
					(click)="addNew()"
				>
					+ Add
				</button>
				<button class="btn btn-primary" (click)="save()" *ngIf="!saving">
					Save
				</button>
				<app-manual-loader-mini
					*ngIf="saving"
					[isLoading]="saving"
				></app-manual-loader-mini>
			</div>
		</div>
	</form>
</div>
