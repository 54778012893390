<div class="scope-of-service pr-2">
	<form [formGroup]="form" class="form">
		<div class="row pb-0 pl-3 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold">What we will talk about today</label>
					</div>
				</div>
			</div>
		</div>
		<div class="row pb-0 pl-3 pb-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<textarea
							#contentEditor
							class="form-control theme-crm-field-disabled"
							formControlName="whatWeWillTalkAboutToday"
							rows="18"
							id="contentEditor"
						>
							{{ WhatWeWillTalkAboutToday.value  }}
						</textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold">What we won't talk about today</label>
					</div>
				</div>
			</div>
		</div>
		<div class="row pb-0 pl-3 pb-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<textarea
							#contentEditor
							class="form-control theme-crm-field-disabled"
							formControlName="whatWeWontTalkAboutToday"
							rows="18"
							id="contentEditor"
						>
							{{ WhatWeWontTalkAboutToday.value  }}
						</textarea>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="row pb-0 pl-3 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-12">
						<label class="font-weight-bold">Other Advice:</label>
					</div>
				</div>
			</div>
		</div>

		<div class="row pb-0 pl-3 b-2 pt-2">
			<div class="col-12 col-lg-7">
				<div class="row">
					<div class="col-lg-12 col-12 pb-3">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="lifeAndRiskReview"
									formControlName="lifeAndRiskReview"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Life & Risk Review</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-12 scope-of-service__textbox">
								<textarea
									class="form-control"
									name="lifeAndRiskReviewDetails"
									id="lifeAndRiskReviewDetails"
									cols="30"
									rows="4"
									formControlName="lifeAndRiskReviewDetails"
								></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-12 pb-3">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="houseCarAndContentsInsurance"
									formControlName="houseCarAndContentsInsurance"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">
									House, Car & Contents Insurance
								</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-12 scope-of-service__textbox">
								<textarea
									class="form-control"
									name="houseCarAndContentsInsuranceDetails"
									id="houseCarAndContentsInsuranceDetails"
									cols="30"
									rows="4"
									formControlName="houseCarAndContentsInsuranceDetails"
								></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-12 pb-3">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="residentialMortgageOrLending"
									formControlName="residentialMortgageOrLending"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Residential Mortgage / Lending</label>
							</div>
						</div>

						<div class="row scope-of-service__services">
							<div class="col-12 scope-of-service__textbox">
								<textarea
									class="form-control"
									name="residentialMortgageOrLendingDetails"
									id="residentialMortgageOrLendingDetails"
									cols="30"
									rows="4"
									formControlName="residentialMortgageOrLendingDetails"
								></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-12">
						<div class="row scope-of-service__services">
							<div class="col-1 scope-of-service__services__checkbox">
								<input
									type="checkbox"
									class="chckbox form-control theme-crm-field-disabled"
									id="other"
									formControlName="other"
								/>
							</div>
							<div class="col-10 pt-1 pl-2 scope-of-service__services__label">
								<label class="form-check-label">Other</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<div class="row pb-0 pl-3 mb-4">
			<div class="col-12 col-lg-7 text-right">
				<app-manual-loader-mini
					[isLoading]="isLoading"
				></app-manual-loader-mini>
				<button
					type="button"
					class="btn btn-primary my-2 ml-1"
					[appTheme]="{ 'background-color': 'primarycolor' }"
					(click)="save()"
					*ngIf="!isLoading"
				>
					SAVE
				</button>
			</div>
		</div>
	</form>
</div>
