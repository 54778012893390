import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { MortgageService } from './states/mortgage.service';
import { Observable, forkJoin } from 'rxjs';
import { mapTo, tap, take } from 'rxjs/operators';

const dropdownCodes: string[] = ['MP', 'MS', 'AT', 'MRS', 'PCLE', 'PCLT', 'MT', 'MFPE', 'MRF', 'MLT'];

@Injectable()
export class MortgageResolver implements Resolve<boolean> {
	constructor(private dropdownValueService: DropdownValueService, private mortgageService: MortgageService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		// this.mortgageService.clear();
		return forkJoin([dropdown$]).pipe(
			tap(() => this.mortgageService.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.mortgageService.getColumnWidths().pipe(take(1)).subscribe()),
			mapTo(true)
		);
	}
}
