import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { find, propEq, complement, either, isNil, isEmpty } from 'ramda';
import { BehaviorSubject, combineLatest, Observable, Observer, of, Subject } from 'rxjs';
import {
	filter,
	finalize,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { SecondaryClientState } from '../../../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import {
	computeAnnualTaxable,
	computeMonthlyAfterTaxIncome,
	computeMonthlyTaxable,
} from '../../calculations/compute-taxable';
import { MOATIncomeModalComponent } from 'src/app/shared/modal/crt/moat/income/income-modal.component';
import { PrimaryClientState } from '../../../../../../shared/models/client-profile/primary-client/primary-client.model';
import {
	FactFindComputationState,
	IncomeSourceState,
} from '../../models/income.model';
import { IncomeSourceMapper } from '../../mapper/income.mapper';
import { PeopleDetailsState } from '../../../../../../shared/models/client-review-template/people/people-details.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { LoggerService } from '../../../../../../core/logger/logger.service';
import { numUtil } from '../../../../../../util/util';
import { logMessage } from '@errorMsgs';

declare var $: any;

@Component({
	selector: 'app-crt-income-source',
	templateUrl: './crt-income-source.component.html',
	styleUrls: ['./crt-income-source.component.scss'],
})
export class CrtIncomeSourceComponent implements OnInit, OnDestroy {
	@Input() deleteFn$: (id) => Observable<any>;
	@Input() updateFn$: (data) => Observable<any>;
	@Input() addNewFn$: (data) => Observable<any>;

	@Input() APCRTF$: Observable<ViewDisplayValue[]>;
	@Input() APCRTKC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTTC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTI$: Observable<ViewDisplayValue[]>;
	@Input() APCRTIST$: Observable<ViewDisplayValue[]>;
	@Input() APCRTIT$: Observable<ViewDisplayValue[]>;
	@Input() APCRTTM$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYN$: Observable<ViewDisplayValue[]>;
	@Input() APCRTYNNA$: Observable<ViewDisplayValue[]>;
	@Input() PCE$: Observable<ViewDisplayValue[]>;
	@Input() primaryClient$: Observable<PrimaryClientState>;
	@Input() secondaryClients$: Observable<SecondaryClientState[]>;
	@Input() incomeSource$: Observable<IncomeSourceState[]>;
	@Input() transferedSCIList$: Observable<PeopleState[]>;
	@Input() peopleFromCRMAndCRTExceptChild$: Observable<any[]>;
	@Input() people$: Observable<PeopleDetailsState[]>;
	@Input() factFindComputation$: Observable<FactFindComputationState>;
	@Input() allIncomeList$: Observable<any>;
	@Input() showTimeInJob: boolean;
	@Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

	onDestroy$ = new Subject<void>();

	elseMinusIncome = true;
	isListLoading = false;
	overallGross = 0;
	incomeList: IncomeSourceState[];
	factFind: FactFindComputationState = {};
	peopleList: ViewDisplayValue[];
	peopleList$ = new BehaviorSubject<ViewDisplayValue[]>([]);
	transferedSCI: PeopleState[];
	allIncomeList;

	public bsModalRef: BsModalRef;
	form: UntypedFormGroup;



	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private loggerService: LoggerService
	) {
		this.buildForm();
	}

	get IncomeArray() {
		return this.form.get('incomeArray') as UntypedFormArray;
	}

	@Input() deceasedSciList$: Observable<any[]>;

	ngOnInit(): void {
		this.getPeopleList();
		this.prepData();
		this.getAllIncome();

		this.factFindComputation$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.overallGross = +data?.overallTotalGrossIncome;
				this.factFind = data;
			});

		this.incomeSource$
			.pipe(
				filter((data) => !!data),
				takeUntil(this.onDestroy$)
			)
			.subscribe((data) => (this.incomeList = data));

		this.transferedSCIList$
			.pipe(
				filter((data) => !!data),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => (this.transferedSCI = x));
	}

	prepData() {
		combineLatest([this.incomeSource$, this.peopleList$])
			.pipe(
				tap(() => (this.isListLoading = true)),
				filter(([data,peopleList]) => !!data && peopleList.length>0),
				map(
					([data]) =>
						data?.map((item) => IncomeSourceMapper.mapIncomeToView(item)) || []
				),
				map(
					(data) =>
						data?.map((item) => ({
							...item,
						})) || []
				),
				finalize(() => (this.isListLoading = false)),
				take(1)
			)
			.subscribe((data) => {
				this.form.reset();
				data?.map((x) => this.addIncome(x));
				this.disableAll();
			});
	}

	buildForm() {
		this.form = this.fb.group({
			incomeArray: this.fb.array([]),
		});
	}

	addIncome(data?: any, isAdd: boolean = false) {
		this.IncomeArray.push(
			this.fb.group({
				cRTId: [data?.cRTId || null],
				incomeEarner: [data?.incomeEarner || ''],
				employment: [data?.employment || ''],
				occupation: [data?.occupation || ''],
				totalGrossIncome: [data?.totalGrossIncome || ''],
				isLoading: [false],
			})
		);
	}

	getIncomeEarnerName(id) {
		let getName = '';
		if (this.peopleList) {
			const nameDisplay = find(propEq('value', id))(
				this.peopleList
			) as ViewDisplayValue;

			getName = nameDisplay?.display;
		}
		return getName;
	}

	getPeopleList() {
		combineLatest([this.peopleFromCRMAndCRTExceptChild$, this.people$])
			.pipe(
				map(([crmPeople, crtPeople]) => [
					...IncomeSourceMapper.mapCRMPeopleToDd(crmPeople),
					...IncomeSourceMapper.mapCRTPeopleToDd(crtPeople),
				]),
				takeUntil(this.onDestroy$)
			)
			.subscribe((res) => {
				this.peopleList = res;
				this.peopleList$.next(res);
			});
	}

	getAllIncome() {
		this.allIncomeList$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.allIncomeList = x));
	}

	addEmployment() {
		this.isListLoading = true;
		const confirm = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const decline = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});

		const initState = {
			header: 'Employment Details',
			message: `EmploymentDetails`,
			confirm$: confirm,
			decline$: decline,
			apcrtf$: this.APCRTF$,
			apcrtkc$: this.APCRTKC$,
			apcrttc$: this.APCRTTC$,
			apcrti$: this.APCRTI$,
			apcrtist$: this.APCRTIST$,
			apcrtit$: this.APCRTIT$,
			apcrttm$: this.APCRTTM$,
			apcrtyn$: this.APCRTYN$,
			apcrtynna$: this.APCRTYNNA$,
			pce$: this.PCE$,
			people$: this.people$,
			primaryClient$: this.primaryClient$,
			secondaryClients$: this.secondaryClients$,
			factFindComputation$: this.factFindComputation$,
			transferedSCI: this.transferedSCI,
			incomeSources$: this.incomeSource$,
			peopleFromCRMAndCRTExceptChild$: this.peopleFromCRMAndCRTExceptChild$,
			getOwnerChoices: this.getOwnerChoices,
			savefn: this.addIncomeSource,
			cancelAddUpdate: this.doneLoading,
			isAdd: true,
			showTimeInJob: this.showTimeInJob || false,
			adviceProcessId: +this.route.snapshot.paramMap.get('adviceProcessId'),
			deceasedSciList$: this.deceasedSciList$
		};
		this.bsModalRef = this.modalService.show(MOATIncomeModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	editEmployment(id, index) {
		this.IncomeArray.controls[index].get('isLoading').setValue(true);
		const confirm = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const decline = new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		});
		const initState = {
			header: 'Employment Details',
			message: `EmploymentDetails`,
			confirm$: confirm,
			decline$: decline,
			apcrtf$: this.APCRTF$,
			apcrtkc$: this.APCRTKC$,
			apcrttc$: this.APCRTTC$,
			apcrti$: this.APCRTI$,
			apcrtist$: this.APCRTIST$,
			apcrtit$: this.APCRTIT$,
			apcrttm$: this.APCRTTM$,
			apcrtynna$: this.APCRTYNNA$,
			pce$: this.PCE$,
			people$: this.people$,
			primaryClient$: this.primaryClient$,
			secondaryClients$: this.secondaryClients$,
			factFindComputation$: this.factFindComputation$,
			transferedSCI: this.transferedSCI,
			incomeSources$: this.incomeSource$,
			peopleFromCRMAndCRTExceptChild$: this.peopleFromCRMAndCRTExceptChild$,
			getOwnerChoices: this.getOwnerChoices,
			savefn: this.updateIncomeSource,
			isAdd: false,
			cRTId: +id,
			index: +index,
			cancelAddUpdate: this.doneLoading,
			showTimeInJob: this.showTimeInJob || false,
			adviceProcessId: +this.route.snapshot.paramMap.get('adviceProcessId'),
			deceasedSciList$: this.deceasedSciList$
		};
		this.bsModalRef = this.modalService.show(MOATIncomeModalComponent, {
			class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteEmployment(id, index) {
		const data = this.incomeList?.find((x) => +x?.cRTId === +id);

		this.IncomeArray.controls[index].get('isLoading').setValue(true);
		this.deleteFn$(id)
			.pipe(
				take(1),
				finalize(() => {
					if (data?.incomeEarner && this.isSciInvalid(+data?.incomeEarner)) {
						this.invalidSciWarning();
					}
					this.IncomeArray.removeAt(index);
				})
			)
			.subscribe();
	}

	isSciInvalid(id: number) {
		const sci = this.transferedSCI?.filter((x) => +x?.customerId === +id);
		return complement(either(isNil, isEmpty))(sci);
	}

	invalidSciWarning() {
		this.loggerService.Warning(
			{},
			logMessage.oat.shared.warning.invalidSci
		);
	}

	addIncomeSource = (model) =>
		new Observable<IncomeSourceState>((obs) => {
			const tempFactFind = {
				...this.factFind,
				...this.recompute(model, this.factFind),
			};
			obs.next(IncomeSourceMapper.mapTempFactFind(model, tempFactFind));
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				this.addNewFn$(x).pipe(mergeMap((res) => this.addNewIncome(+res, x)))
			),
			finalize(() => {
				if (model?.incomeEarner && this.isSciInvalid(+model?.incomeEarner)) {
					this.invalidSciWarning();
				}
				this.isListLoading = false;
			})
		);

	addNewIncome = (cRTId, data) =>
		of(data).pipe(
			map((x) => IncomeSourceMapper.mapIncomeToView(x)),
			map((x) => ({
				...x,
				cRTId: +cRTId,
			})),
			tap((x) => {
				this.addIncome(x);
				this.disableAll();
			}),
			take(1)
		);

	updateIncomeSource = (model, index: number) =>
		new Observable<IncomeSourceState>((obs) => {
			const tempFactFind = {
				...this.factFind,
				...this.recompute(model, this.factFind),
			};
			obs.next(IncomeSourceMapper.mapTempFactFind(model, tempFactFind));
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				this.updateFn$(x).pipe(mergeMap((res) => this.updateIncome(index, x)))
			),
			finalize(() => {
				if (model?.incomeEarner && this.isSciInvalid(+model?.incomeEarner)) {
					this.invalidSciWarning();
				}
				this.IncomeArray.controls[index].get('isLoading').setValue(false);
			})
		);

	doneLoading = (index?: number) =>
		new Observable((obs: Observer<IncomeSourceState>) => {
			obs.complete();
		}).pipe(
			finalize(() => {
				if (isNaN(index)) {
					this.isListLoading = false;
				} else {
					this.IncomeArray.controls[index].get('isLoading').setValue(false);
				}
			})
		);

	collapseFalse() {
		this.elseMinusIncome = false;
	}

	updateIncome = (index, data) =>
		of(data).pipe(
			map((x) => IncomeSourceMapper.mapIncomeToView(x)),
			map((x) => ({
				...x,
			})),
			tap((x) => {
				this.IncomeArray.controls[index]
					.get('incomeEarner')
					.setValue(x?.incomeEarner);
				this.IncomeArray.controls[index]
					.get('employment')
					.setValue(x?.employment);
				this.IncomeArray.controls[index]
					.get('occupation')
					.setValue(x?.occupation);
				this.IncomeArray.controls[index]
					.get('totalGrossIncome')
					.setValue(x?.totalGrossIncome);
				this.disableAll();
			}),
			take(1)
		);

	recompute(model: IncomeSourceState, factFind: FactFindComputationState) {
		const cRTId = +model.cRTId;
		const newValue = +model.totalGrossIncome;
		const tempList = [...this.incomeList] || [];
		let tempTotalGrossIncome = 0;

		if (!cRTId) {
			tempList.push(model);
		}

		tempList?.map((income: any) => {
			if (cRTId === income.cRTId) {
				income.totalGrossIncome = newValue;
			}
			tempTotalGrossIncome += +income.totalGrossIncome || 0;
		});
		const annualTaxable = computeAnnualTaxable({
			...factFind,
			overallTotalGrossIncome: +tempTotalGrossIncome,
		});
		const monthlyTaxable = computeMonthlyTaxable(+annualTaxable);
		const monthlyAfterTax = computeMonthlyAfterTaxIncome(
			tempList,
			{
				...factFind,
				overallTotalGrossIncome: +tempTotalGrossIncome,
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
			},
			this.allIncomeList
		);

		return {
			overallTotalGrossIncome:
				+numUtil.formatToNumCurrency(tempTotalGrossIncome),
			annualTaxableJointIncome: +annualTaxable,
			monthlyTaxableJointIncome: +monthlyTaxable,
			monthlyAfterTaxIncome: +monthlyAfterTax,
		};
	}

	collapseMoreIncome() {
		$('#collapseIncome').toggle();
		this.elseMinusIncome = false;
	}

	collapseLessIncome() {
		$('#collapseIncome').toggle();
		this.elseMinusIncome = true;
	}

	disableAll() {
		this.form.disable();
	}

	trackByFn(_index: number) {
		return _index;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
		this.peopleList$.complete();
	}

	getTooltipValue = (form: UntypedFormGroup): string => {
		if(form) {
			const detail = this.peopleList.find(p => p.value === form.value)
			return detail?.display;
		}
		return '';
	}
}
