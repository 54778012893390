import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportsComponent } from './exports.component';
import { ExportsService } from './state/exports.service';

@NgModule({
	declarations: [ExportsComponent],
	imports: [CommonModule],
  exports: [ExportsComponent],
  providers: [ExportsService]
})
export class ExportsModule {}
