import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AdviceProcessSectionCodes } from '../../../../../shared/models/advice-process/advice-process.model';
import { PeopleState } from '../../../../../shared/models/client-review-template/people/people.model';
import { DropdownValueQuery } from '../../../../../domain/dropdown-value/dropdown-value.query';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { MedicalQuery } from '../../states/risk-analysis/medical/medical.query';
import { RiskAnalysisMedicalService } from '../../states/risk-analysis/medical/medical.service';
import { RiskAnalysisService } from '../../states/risk-analysis/risk-analysis.service';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';

@Component({
	selector: 'app-risk-medical',
	templateUrl: './medical.component.html',
	styleUrls: ['./medical.component.scss'],
})
export class RiskMedicalComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	form: UntypedFormGroup;
	activePerson: PeopleState;
	people$: Observable<PeopleState[]>;
	isLoading: boolean = true;
	isCollapse: boolean = true;

	APCRTME$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('APCRTME');
	activePerson$: Observable<PeopleState> = this.rService.activePerson$;
	riskMedical$: Observable<boolean> = this.medicalQuery.selectLoading();

	isAdviceProcessEnded$ = this.crtQuery.isAdviceProcessEnded$;

	@ViewChild('tpdTabset') lifeTabset: TabsetComponent;
	@ViewChild('medicalTabset') medicalTabset: TabsetComponent;

	constructor(
		private fb: UntypedFormBuilder,
		private crtQuery: ClientReviewTemplateQuery,
		private dropdownValueQuery: DropdownValueQuery,
		private medicalService: RiskAnalysisMedicalService,
		private medicalQuery: MedicalQuery,
		private rService: RiskAnalysisService
	) {
		this.form = this.fb.group({
			sectionCode: [AdviceProcessSectionCodes.Medical],
			adviceProcessId: [null],
			relyOnNewZealandPublicHealthSystem: [null, Validators.required],
			newZealandPrivateMedicalPolicyBasePlan: [null, Validators.required],
			medicalDrugTreatmentsApprovedforUseInNZ: [null, Validators.required],
			policyProvidingAccessToSpecialistsAndTests: [null, Validators.required],
			policyProvidingAccessToOpticalAndDentalTreatment: [
				null,
				Validators.required,
			],
			policyProvidingAccessToGPVisitsAndPrescriptions: [
				null,
				Validators.required,
			],
			whatLevelOfExcessAreYouComfortableSelfInsuring: [
				null,
				Validators.required,
			],
			parentCRTId: [null],
			cRTId: [null],
		});

		this.people$ = this.rService.getPeopleTabs(true);
		this.activePerson$
			.pipe(
				withLatestFrom(this.people$),
				tap(([person, people]) => {
					const index = people.findIndex((x) => x?.cRTId === person?.cRTId);
					if (person && this.medicalTabset?.tabs) {
						this.medicalTabset.tabs[index].active = true;
					}
				}),
				tap(([person]) => {
					if (!!person) {
						this.setActivePerson(person);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	get RelyOnNewZealandPublicHealthSystem() {
		return this.form.get('relyOnNewZealandPublicHealthSystem');
	}
	get NewZealandPrivateMedicalPolicyBasePlan() {
		return this.form.get('newZealandPrivateMedicalPolicyBasePlan');
	}
	get MedicalDrugTreatmentsApprovedforUseInNZ() {
		return this.form.get('medicalDrugTreatmentsApprovedforUseInNZ');
	}
	get PolicyProvidingAccessToSpecialistsAndTests() {
		return this.form.get('policyProvidingAccessToSpecialistsAndTests');
	}
	get PolicyProvidingAccessToOpticalAndDentalTreatment() {
		return this.form.get('policyProvidingAccessToOpticalAndDentalTreatment');
	}
	get PolicyProvidingAccessToGPVisitsAndPrescriptions() {
		return this.form.get('policyProvidingAccessToGPVisitsAndPrescriptions');
	}
	get WhatLevelOfExcessAreYouComfortableSelfInsuring() {
		return this.form.get('whatLevelOfExcessAreYouComfortableSelfInsuring');
	}

	ngOnInit(): void {
		this.crtQuery.people$
			.pipe(
				filter((data) => !!data),
				tap((people) => {
					if (people && people.length > 0) {
						this.rService.setCurrentPersonIndex(0, true);
						this.setActivePerson(people[0]);
					}
				}),
				take(1)
			)
			.subscribe();

		this.medicalQuery
			.selectEntityAction()
			.pipe(
				filter((data) => !!data),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	prepareData() {
		this.form?.reset();
		combineLatest([
			this.medicalQuery.selectEntity(this.activePerson?.cRTId),
			this.riskMedical$,
		])
			.pipe(
				filter(([, medical]) => !medical),
				tap(([data]) => {
					{
						this.form.reset({
							...data,
							sectionCode: AdviceProcessSectionCodes.Medical,
							parentCRTId: this.activePerson?.cRTId ?? null,
							adviceProcessId: this.crtQuery.getValue().adviceProcessId ?? null,
						});
					}
				}),
				tap(() => (this.isLoading = false)),
				tap(() => this.form.markAllAsTouched()),
				withLatestFrom(this.crtQuery.isAdviceProcessEnded$),
				tap(([, apEnded]) => {
					if (apEnded) {
						this.form.disable();
					}
				}),
				map(([x]) => x),
				take(1)
			)
			.subscribe();
	}

	onChange(event?: Event) {
		if (event) {
			const inputEl = event.target as HTMLInputElement;
			const controllerName = inputEl?.name;
			const val = inputEl?.value;
			if (controllerName === 'relyOnNewZealandPublicHealthSystem') {
				if (this.RelyOnNewZealandPublicHealthSystem.value === 'Yes') {
					this.NewZealandPrivateMedicalPolicyBasePlan.setValue('No');
					this.MedicalDrugTreatmentsApprovedforUseInNZ.setValue('No');
					this.PolicyProvidingAccessToSpecialistsAndTests.setValue('No');
					this.PolicyProvidingAccessToOpticalAndDentalTreatment.setValue('No');
					this.PolicyProvidingAccessToGPVisitsAndPrescriptions.setValue('No');
					this.WhatLevelOfExcessAreYouComfortableSelfInsuring.setValue('');
				} else {
					this.NewZealandPrivateMedicalPolicyBasePlan.setValue('Yes');
					this.MedicalDrugTreatmentsApprovedforUseInNZ.setValue('');
					this.PolicyProvidingAccessToSpecialistsAndTests.setValue('');
					this.PolicyProvidingAccessToOpticalAndDentalTreatment.setValue('');
					this.PolicyProvidingAccessToGPVisitsAndPrescriptions.setValue('');
				}
			}

			if (
				controllerName === 'newZealandPrivateMedicalPolicyBasePlan' &&
				this.NewZealandPrivateMedicalPolicyBasePlan.value === 'Yes'
			) {
				this.RelyOnNewZealandPublicHealthSystem.setValue('No');
			}
			this.form.get(controllerName).setValue(val);
		}

		const formValue = this.form.getRawValue();
		this.medicalService.setFormValue({
			...formValue,
			sectionCode: AdviceProcessSectionCodes.Medical,
			parentCRTId: this.activePerson?.cRTId ?? null,
			adviceProcessId: this.crtQuery.getValue().adviceProcessId ?? null,
		});
	}

	setActive(index: number) {
		this.rService.setCurrentPersonIndex(index, true).pipe(take(1)).subscribe();
	}

	setActivePerson(data: PeopleState) {
		this.activePerson = data;
		this.medicalService.setActiveParentCRTId(data.cRTId);

		this.prepareData();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
