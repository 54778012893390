import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { KiwisaverQuery } from '../states/kiwisaver.query';
import { UntypedFormGroup } from '@angular/forms';
import { KiwisaverService } from '../states/kiwisaver.service';
import { Metakey } from '../kiwisaver-datatable.config';
import { Subject } from 'rxjs';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Row } from '../states/kiwisaver.model';
import { takeUntil, withLatestFrom, map } from 'rxjs/operators';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
declare var $: any;

@Component({
  selector: 'app-kiwisaver-columns-form',
  templateUrl: './kiwisaver-columns-form.component.html',
  styleUrls: ['./kiwisaver-columns-form.component.scss'],
})
export class KiwisaverColumnsFormComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy$: Subject<void> = new Subject<void>();

  visibleColumns$ = this.kiwisaverQuery.tableColumns$;
  hiddenColumns$ = this.kiwisaverQuery.hiddenTableColumns$;

  visibleColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];
  hiddenColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];

  isColumnSaving$ = this.kiwisaverQuery.uiQuery.isColumnSaving$;

  form = new UntypedFormGroup({});
  formInitialized = false;

  constructor(
    private kiwisaverService: KiwisaverService,
    private kiwisaverQuery: KiwisaverQuery,
		private businessConfigQuery: BusinessConfigQuery
  ) {}

  ngOnInit() {
    this.visibleColumns$
      .pipe(
				withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
				map(([vcolumns, adviserRework]) =>
					vcolumns.filter((x) =>
						adviserRework
							? x.metakey != 'Adviser'
							: x.metakey != 'KS Adviser'
					)
				),
				takeUntil(this.onDestroy$)
			).subscribe(
        (res) =>
          (this.visibleColumns = res?.filter(
            (x) => x.metakey !== 'Fund Owner'
          ))
      );
    this.hiddenColumns$
      .pipe(
				withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
				map(([vcolumns, adviserRework]) =>
					vcolumns.filter((x) =>
						adviserRework
							? x.metakey != 'Adviser'
							: x.metakey != 'KS Adviser'
					)
				),
				takeUntil(this.onDestroy$)
			).subscribe((res) => (this.hiddenColumns = res));
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      $('.disabled-sortable').parent().addClass('disabled d-none');
    });
  }

  prepareFormValue() {
    return [
      'Fund Owner',
      ...this.visibleColumns?.map((x) => x.metakey),
    ] as Metakey[];
  }

  save() {
    if (this.visibleColumns.length === 0) {
      return;
    }
    this.kiwisaverService
      .saveVisibleColumns(this.prepareFormValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => {},
        () => {},
        () => {
          this.kiwisaverService.closePopup();
        }
      );
  }
  cancel() {
    this.kiwisaverService.closePopup();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
