import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild,
    HostBinding,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { CustomerSearchModel } from '../../models/_general/customerSearchModel';
import { CommandRoute } from '../../../core/config/route.service';
import { map, take, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { DomEventService } from '../../../core/dom-event/dom-event.service';
import { BusinessProfileService } from 'src/app/modules/crm/business-profile/states/business-profile.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';

@Component({
	selector: 'app-search-control',
	templateUrl: './search-control.component.html',
	styleUrls: ['./search-control.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SearchControlComponent,
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchControlComponent implements OnInit, ControlValueAccessor {
	@ViewChild('searchBox') set searchBox(v: ElementRef) {
		this._searchBox = v;
	}
	@Input('choices') set _choices(v: CustomerSearchModel[]) {
		this.isShown.next(true);
		this.choices = v;
	}

	constructor(
		private domEventService: DomEventService,
		private elementRef: ElementRef,
		private profile: BusinessProfileService,
		private modalService: BsModalService
	) { }

	@Input() tabindex: number;
	@Input() showQuickAddIcon: boolean;

	@Output() searchEvent: Subject<string> = new Subject();
	@Output() quickAddEvent = new EventEmitter<string>();
	@Output() selectEvent = new EventEmitter<number>();
	@Output() clearEvent = new EventEmitter<number>();

	choices: CustomerSearchModel[] = [];
	@Input() isLoading = false;
	@Input() routerLink: CommandRoute = [];
	@Input() textboxClass = '';
	@Input() textboxId = '';
	@Input() commandRoute: CommandRoute = [];
	@Input() businessInfo = null;
	/**
	 * remove the tabindex attribute from host element
	 * to remove duplicate tab index that is attached to input element
	 * */
	@HostBinding('attr.tabindex') get tabIndex() { return null; }

	_searchBox: ElementRef;

	private isShown = new BehaviorSubject<boolean>(false);
	public isShown$ = this.isShown.asObservable();

	private valueSubj = new ReplaySubject<CustomerSearchModel>(1);
	public value$ = this.valueSubj.asObservable();
	public valueName$ = this.valueSubj.pipe(map(x => (x ? x.Name : '')));
	public valuekey$ = this.valueSubj.pipe(map(x => (x ? x.PrimaryCustomerId : null)));
	public hasValue$ = this.value$.pipe(map(hasValue));
	public isAccessible$ = this.value$.pipe(map(x => (x ? x.IsAccessible : false)));

	private isDisabled = new BehaviorSubject<boolean>(false);
	public isDisabled$ = this.isDisabled.asObservable();
	public textboxNgClass$ = this.isDisabled$.pipe(
		map(isDisabled => ({
			[this.textboxClass]: isDisabled,
		}))
	);

	private onChange: (value: CustomerSearchModel) => void;

	public isValidSearch = (q: string) => validSearchValue(q);

	ngOnInit() {
		this.domEventService.DocumentClickEvent$.subscribe(x => {
			const targetElement = x.target;
			if (targetElement) {
				this.isShown.next(this.elementRef.nativeElement.contains(targetElement));
			}
		});
	}

	public setInputValueToNull = () => {
		if (this._searchBox) {
			this._searchBox.nativeElement.value = null;
			this.searchEvent.next('');
		}
	};

	private setValue = (v: CustomerSearchModel) => {
		this.valueSubj.next(v);
		this.selectEvent.emit(v?.CustomerId);
	};
	
	public clearValue = () => {
		this.value$.pipe(
			tap((data: CustomerSearchModel) => {
				if (!!data) {
					this.clearEvent.emit(+data?.CustomerId);
				}
			})
		).subscribe();
	};

	public search = (query: string) => {
		if (query && query?.trim()) {
			this.isShown.next(true);
		}
		this.searchEvent.next(query?.trim());
	};

	public quickAdd = (query: string) => {
		if (query && query?.trim()) {
			this.quickAddEvent.emit(query?.trim());
		} else {
			return;
		}
	};

	public select = ($event: CustomerSearchModel) => {
		this.setValue($event);
		this.onChange($event);
	};

	public clear = () => {
		this.clearValue();
		if (this.businessInfo) {
			this.checkIfLinked().subscribe(x => {
				if (!x) {
					this.select(null);
					this.searchEvent.next('');
				}
			});
		} else {
			this.select(null);
			this.searchEvent.next('');
		}
	};

	public writeValue = (obj?: CustomerSearchModel) => {
		this.setValue(obj);
	};

	public registerOnChange = (fn: (value: CustomerSearchModel) => void) => {
		this.onChange = fn;
	};

	public registerOnTouched = () => { };
	public setDisabledState = (isDisabled: boolean) => {
		this.isDisabled.next(isDisabled);
		this.setInputValueToNull();
	};

	checkIfLinked = () => {
		const id = this.businessInfo?.primaryContact?.customerId
			? this.businessInfo?.primaryContact?.customerId
			: this.businessInfo.CustomerId
				? this.businessInfo.CustomerId
				: null;

		if (!id) {
			return;
		}

		return this.profile.listOfServices(+id).pipe(
			tap(x => {
				const message = [];
				if (x) {
					x.lrs?.forEach(lr => {
						lr.customerServices?.forEach(cs => {
							const provider =
								cs.provider + ': ' + cs.policyNumber + (cs.policyNumberSuffix ? ' - ' + cs.policyNumberSuffix : '');

							message.push(` - L&R Policy Owner of ${provider} <br />`);
						});
					});

					x.mortgages?.forEach(m => {
						m.customerServices?.forEach(cs => {
							const provider =
								cs.provider + ': ' + cs.loanNumber + (cs.loanNumberSuffix ? ' - ' + cs.loanNumberSuffix : '');

							message.push(` - Borrowing Entity of ${provider} <br />`);
						});
					});

					x.propertyAssets?.forEach(pa => {
						message.push(` - Property Owner of ${pa.propertyAddress} <br />`);
					});

					x.fgs?.forEach(fg => {
						fg.customerServices?.forEach(cs => {
							const provider =
								cs.insurer + ': ' + cs.fGPolicyNumber + (cs.fGPolicyNumberSuffix ? ' - ' + cs.fGPolicyNumberSuffix : '');

							message.push(` - F&G Policy Owner of ${provider} <br />`);
						});
					});

					x.kiwiSavers?.forEach(ks => {
						const provider = ks.provider + ': ' + ks.memberNumber;
						message.push(` - Fund Owner of ${provider} <br />`);
					});
				}

				const name = this.businessInfo.primaryContact?.name
					? this.businessInfo.primaryContact?.name
					: this.businessInfo.Name
						? this.businessInfo.Name
						: null;

				const initState = {
					header: 'Delete customer',
					message: `'${name}' is a <br /> ${message?.toString()?.replace(/,/g, ' ')} Kindly remove ${message && message.length > 1 ? 'these' : 'this'
						} before deleting.`,
					canDelete: false,
				};
				if (!x) {
					return;
				}
				this.modalService.show(DeleteModalComponent, {
					class: 'modal-dialog-centered',
					initialState: initState,
					ignoreBackdropClick: true,
				});
			}),
			take(1)
		);
	};
}

export class SearchValueModel {
	key: number;
	value: string;
}

const validSearchValue = (q: string) => {
	return typeof q === typeof '' && q?.trim() !== '';
};

const hasValue = (v: CustomerSearchModel) => {
	return !R.isNil(v) && !R.isNil(R.propOr(v.PrimaryCustomerId)) && v.CustomerId !== 0;
};
