<form novalidate [formGroup]="form" class="filter-form">
	<div class="clearfix">
		<div class="row d-flex justify-content-between">
			<div class="col-auto align-self-center">
				<h1 class="d-inline tap-text-secondary tap-text-h2">Leads</h1>
				<h6
					class="
						mb-0
						px-0 px-md-2
						d-inline
						tap-text-secondary
						d-block d-md-inline-block
					"
				>
					{{ count$ | async | number }} Results
				</h6>
			</div>

			<div class="col-auto align-self-center ml-auto">
				<div class="form-row align-items-center">
					<div class="col-auto" *ngIf="(hasBulkItemCheckedEntity$ | async) && (bulkTransferFeature$ | async)">
						<button
							id="transferBulkItems"
							type="button"
							title="Transfer Bulk Items"
							class="icon-btn"
							(click)="transferBulkItems()"
							[disabled]="+(transferStatus$ | async) === 3 || (isSearching$ | async)"
						>
						<i class="material-icons md-20 tap-text-color-h6 pt-1">
							arrow_forward
						</i>
						</button>
					</div>
					<div class="col-auto">
						<button
							id="toggleFilterBtn"
							type="button"
							title="Filter"
							class="icon-btn font-weight-bold"
							(click)="collapse.Toggle(); toggle()"
						>
							<i class="material-icons md-20 tap-text-color-h6 pt-1">
								filter_list
							</i>
						</button>
					</div>
					<div class="col-auto">
						<button
							id="resetFilterBtn"
							type="button"
							title="Reset"
							class="icon-btn"
							(click)="reset()"
						>
							<i class="fas fa-undo fa-14 tap-text-color-h6 pt-1"></i>
						</button>
					</div>
					<ng-container *appRestrictTo="['FSL']">
						<div class="col-auto">
							<button
								id="searchBtn"
								type="submit"
								class="tap-btn tap-btn--default font-weight-bold px-lg-4"
								(click)="search()"
								[disabled]="isSearching$ | async"
							>
								Search
							</button>
						</div>
					</ng-container>
					<ng-container *appRestrictTo="['FEXL']">
						<div
							class="col-auto"
							(mouseenter)="showPopover()"
							[placement]="'bottom'"
							[popover]="(exportStatus$ | async) === 3 ? popTemplate : null"
							containerClass="popover-info-container"
							triggers=""
						>
							<button
								id="exportBtn"
								type="button"
								title="Export"
								class="icon-btn"
								(click)="export()"
								[disabled]="(isExporting$ | async) || (exportStatus$ | async) === 3 || (transferStatus$ | async) === 3"
								data-toggle="modal"
								data-target="#leadExportModal"
							>
								<i class="fas fa-download fa-14 tap-text-color-h6 pt-1"></i>
							</button>
						</div>
					</ng-container>
					<div class="col-auto">
						<app-manual-loader-mini
							[isLoading]="(isSearching$ | async) || (isExporting$ | async)"
						>
						</app-manual-loader-mini>
					</div>
					<div class="col-auto">
						<span class="position-relative d-inline-block">
							<app-lead-search-columns-form
								*ngIf="columnFromOpen$ | async"
								class="search-settings"
							>
							</app-lead-search-columns-form>
							<button
								id="settingsBtn"
								type="button"
								title="Settings"
								class="icon-btn"
								(click)="openColumnPopup()"
							>
								<i class="material-icons md-20 column-settings-icon pt-1">
									settings
								</i>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		app-collapse
		#collapse
		[initialExpandedState]="false"
		class="search-collapse"
	>
		<div class="container-fluid ml-0 px-0 pt-3">
			<div class="row">
				<div class="col-12 col-lg-6 col-xl-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="adviser"
								formControlName="Advisers"
								[choices]="availableStaffChoices$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">First Name</label>
						</div>
						<div class="col-12">
							<input
								id="firstNameId"
								type="text"
								class="form-control"
								formControlName="FirstName"
								id="firstName"
							/>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Lead Origin</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="leadOriginId"
								formControlName="LeadOrigins"
								[choices]="PCLE$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Lead Status</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="leadStatusId"
								formControlName="LeadStatuses"
								[choices]="LS$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Activity Date</label>
						</div>
						<div class="col-12 d-flex">
							<app-date-input
								textboxId="nextActivityDateMinId"
								formControlName="NextActivityDateMin"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
							<div class="col-auto align-self-center p-1">to</div>
							<app-date-input
								textboxId="nextActivityDateMaxId"
								formControlName="NextActivityDateMax"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-3">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Adviser Status</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="adviserStatusId"
								formControlName="AdviserStatuses"
								[choices]="adviserStatus$ | async"
								tabindex="12"
							>
							</app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Last Name</label>
						</div>
						<div class="col-12">
							<input
								id="lastNameId"
								type="text"
								class="form-control"
								formControlName="LastName"
								id="lastName"
							/>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Lead Type</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="leadTypeId"
								formControlName="LeadType"
								[choices]="PCLT$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Activity Type</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="nextActivityType"
								formControlName="NextActivityTypes"
								[choices]="AT$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Created Date</label>
						</div>
						<div class="col-12 d-flex">
							<app-date-input
								textboxId="createdDateMinId"
								formControlName="CreatedDateMin"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
							<div class="col-auto align-self-center px-1">to</div>
							<app-date-input
								textboxId="createdDateMaxId"
								formControlName="CreatedDateMax"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-3">

					<div class="row align-items-center mb-1"
						*featureToggle="{
							id: 'AdviserRework',
							show: false
						}">
						<div class="col-12">
							<label for="">Alt Adviser</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="AltAdvisersId"
								formControlName="AltAdvisers"
								[choices]="availableStaffChoices$ | async"
							>
							</app-chips>
						</div>
					</div>
					&nbsp;
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Contact Type</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="contactTypeId"
								formControlName="ContactTypes"
								[choices]="contactTypeChoices"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Lead Gen</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="leadGenId"
								[class.disabled]="
									(userInfo$ | async).SecurityGroup === 'LG' ||
									(userInfo$ | async).SecurityGroup === 'LGI'
								"
								formControlName="LeadGens"
								[choices]="leadGenChoices$ | async"
							></app-chips>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg-6 col-xl-2">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Next Activity Assignee</label>
						</div>
						<div class="col-12">
							<app-chips
								textboxId="nextActivityAssigneeId"
								formControlName="NextActivityAssignedTo"
								[choices]="staffChoices$ | async"
							></app-chips>
						</div>
					</div>
				</div>


				<div class="col-12 col-lg-6 col-xl">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Last Assigned</label>
						</div>
						<div class="col-12 d-flex">
							<app-date-input
								textboxId="lastAssignedDateMinId"
								formControlName="LastAssignedDateMin"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
							<div class="col-auto align-self-center px-1">to</div>
							<app-date-input
								textboxId="lastAssignedDateMaxId"
								formControlName="LastAssignedDateMax"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-3">
				</div>
				<div class="col-12 col-lg-6 col-xl-2"></div>
				<div class="col-12 col-lg-6 col-xl-2"></div>
				<div class="col-12 col-lg-6 col-xl-2"></div>
				<div class="col-12 col-lg-6 col-xl">
					<div class="row align-items-center mb-1">
						<div class="col-12">
							<label for="">Last Interaction</label>
						</div>
						<div class="col-12 d-flex">
							<app-date-input
								textboxId="lastInteractionDateMinId"
								formControlName="LastInteractionDateMin"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
							<div class="col-auto align-self-center px-1">to</div>
							<app-date-input
								textboxId="lastInteractionDateMaxId"
								formControlName="LastInteractionDateMax"
								textboxClass="theme-crm-field-disabled"
								placeholder="dd/mm/yyyy"
								style="flex: 1"
							>
							</app-date-input>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</form>

<!-- Modal -->
<ng-template #exportTemplate>
	<app-export-progress
		[progress]="progress"
		[percent]="percent"
		[msg]="msg"
	></app-export-progress>
</ng-template>
<!-- End Modal -->

<ng-template #popTemplate>
	<div class="popover-info">
		<p>
			Export currently in progress, please wait until its completion
		</p>
	</div>
</ng-template>
