import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { LoggerService } from '@core/logger/logger.service';
import { ClientProfileQuery } from '@modules/crm/client-profile/states/client-profile.query';
import { ClientProfileStore } from '@modules/crm/client-profile/states/client-profile.store';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { logMessage } from '@shared/error-message/error-message';
import { UploadModalComponent } from '@shared/modal/upload-modal/upload-modal.component';
import { Attachment } from '@shared/models/_general/attachment.model';
import { DocumentGroupState } from '@shared/models/documents/document-group.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { LinkDocumentComponent } from '@shared/services/link-document/link-document.component';
import { convertUtil, fileUtil, objectUtil } from '@util/util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, iif, of } from 'rxjs';
import {
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	concatMap,
	filter,
	catchError,
} from 'rxjs/operators';
import { clone } from 'ramda';

@Component({
	selector: 'app-client-referral-email-modal-form-attach-doc',
	templateUrl: './client-referral-email-modal-form-attach-doc.component.html',
})
export class ClientReferralEmailModalFormAttachDocComponent {
	@ViewChild('uploadOptionModal', { read: TemplateRef })
	uploadOptionModal: TemplateRef<any>;

	bsModalRef: BsModalRef;
	linkModalRef: BsModalRef;
	optionModalRef: BsModalRef;

	@Output() onLinked = new EventEmitter<any>();

	@Output() onUploaded = new EventEmitter<any>();

	@Input() clientDocuments: DocumentGroupState;

	@Input() defaultLinkDocumentTab: string;

	@Input() attachments: Attachment[] = [];

	@Input() allowDocumentLinking = false;

	@Input() documentInfo: {
		documentType: any;
		type: any;
		referenceId: number;
		customerId: number;
	};

	@Input() isSettings = true;

	// this is just a observable when file upload is successfull
	onUploadedDocument: () => Observable<any>;

	constructor(
		private modalService: BsModalService,
		private crtDocService: CrtDocumentService,
		private loggerService: LoggerService,
		private clientProfileStore: ClientProfileStore,
		private clientProfileQuery: ClientProfileQuery
	) {}

	show() {
		if (this.allowDocumentLinking) {
			this.clientDocuments = this.clientDocuments ?? clone(
				this.clientProfileQuery.getValue().documents
			);

			this.optionModalRef = this.modalService.show(this.uploadOptionModal, {
				class: 'modal-dialog-centered',
				ignoreBackdropClick: true,
			});
		} else {
			this.uploadDocuments();
		}
	}

	uploadDocuments() {
		const stop$ = new Subject<void>();

		const convertFilename = (file: File) => {
			if (!file.type.startsWith('image/')) {
				return file.name;
			}
			const filenameArr = file.name.split('.');
			const fileExt = filenameArr[filenameArr.length - 1];
			return file.name.replace(fileExt, 'pdf');
		};

		// convert Image File -> Base64 -> HTML img -> PDF
		const convertImageToBase64 = (file: File): Observable<string> => {
			return new Observable((obs) => {
				obs.next(file);
				obs.complete();
			}).pipe(
				// convert file to base64
				mergeMap(() => convertUtil.simpleConvertToBase64(file)),
				// create a html img string and add the converted base64 image in src
				map((base64) => `<img src="${base64}" />`),
				// convert html img with base64 src to pdf
				mergeMap((img) => {
					return this.crtDocService.generatePDFbase64(img, {
						FileName: convertFilename(file),
					});
				})
			);
		};

		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				concatMap((x) =>
					iif(
						() =>
							// check if the file is image
							req[0].type.startsWith('image/'),
						// If the file is image. We need to convert it to pdf
						convertImageToBase64(req[0]),
						convertUtil.simpleConvertToBase64(x[0]).pipe(take(1))
					)
				),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileUploadSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				map((file) => {
					const fileStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					return {
						Document: fileStr,
						OriginalDocument: file,
						FileName: convertFilename(req[0]),
						DocumentType: this.documentInfo.documentType,
						Type: this.documentInfo.type,
						ReferenceId: this.documentInfo.referenceId,
						CustomerId: this.documentInfo.customerId,
						Size: +fileUtil.getFileSizeKb(fileStr),
					};
				}),
				mergeMap((file) => {
					if (this.isSettings) {
						this.attachments.push(file as any);
						return of(true);
					}
					return this.crtDocService.uploadDocument(file).pipe(
						tap((x) => {
							const newFile = objectUtil.mapPascalCaseToCamelCase(file);
							const uploadedDoc = {
								// @ts-ignore-next
								DocumentID: +x,
								DocumentId: +x,
								FileName: newFile.fileName,
								GenerateContentCallback$: undefined,
								Content: newFile.document,
								PdfOptions: {
									orientation: '',
									format: '',
								},
								FileSize: newFile.size,
								Type: 'uploaded',
							}
							this.onUploaded.emit(uploadedDoc);
							this.attachments.push(uploadedDoc as any);
						}),
						mergeMap((documentId: number) =>
							this.pushUploadedDocumentToClientDocs(documentId)
						)
					);
				}),
				takeUntil(stop$),
				catchError((err) => {
					this.loggerService.Warning(
						{},
						logMessage.shared.fileUploadSize.single.error
					);
					return err;
				})
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: false,
			isFileList: true,
			headerTitle: 'Upload Document',
			restrict: '.pdf,image/jpeg,image/jpg,image/png',
			allowedFileExtensions:['pdf','jpg','jpeg','png'],
			maxFileSize: 10000, // kb
			maxFileSizeText: '10MB',
			currentFileSize: +this.getTotalAttachmentsSize(),
		};
		this.bsModalRef = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}

	getTotalAttachmentsSize() {
		return this.attachments?.reduce((a, b) => a + (+b.fileSize || 0), 0) ?? 0;
	}

	private pushUploadedDocumentToClientDocs(
		documentId: number
	): Observable<number> {
		return this.crtDocService.getDocument(documentId).pipe(
			map((doc) => objectUtil.mapPascalCaseToCamelCase(doc)),
			map((doc) => {
				const clientDocs = Object.assign({}, this.clientDocuments);
				const documentType = this.documentInfo.documentType;
				// lower case first letter of the document type just like the properties in clientDocuments
				const documentTypeFormatted =
					(documentType &&
						documentType[0].toLowerCase() + documentType.slice(1)) ||
					documentType;
				// get the reference of the client document by type array
				const clientDocumentsByType = clientDocs[documentTypeFormatted];
				if (clientDocumentsByType) {
					const appendNewDocs = [doc, ...clientDocumentsByType];
					this.clientProfileStore.setDocuments({
						...clientDocs,
						[documentTypeFormatted]: appendNewDocs,
					});
				}
				this.onUploadedDocument?.().pipe(take(1)).subscribe();
				return documentId;
			})
		);
	}

	onLinkDocumentsClicked(): void {
		this.linkDocument();
		this.optionModalRef.hide();
	}

	linkDocument() {
		const stop$ = new Subject<void>();
		const initialSelectedTab =
			this.defaultLinkDocumentTab?.toLowerCase() ??
			ServicesCodes.AdviceProcess?.toLowerCase();

		const initState = {
			initialSelectedTab,
			selectedDetail: 'Link Document',
			document: this.clientDocuments,
			maxFileSize: 10000,
			currentFileSize: +this.getTotalAttachmentsSize(),
			allowedFileExtensions:['pdf','jpg','jpeg','png'],
		};
		this.linkModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		let fileName = '';
		let documentId = '';
		this.linkModalRef.content.getSelectedDocumentValue$
			.pipe(
				filter((x) => !!x),
				mergeMap((x: any) => {
					documentId = x.id;
					fileName = x.fileName;
					return this.crtDocService.getDocumentLink(x.documentLink, {
						responseType: 'blob',
					});
				}),
				mergeMap((xx) => convertUtil.simpleConvertToBase64(xx)),
				map((file) => {
					const filStr = `${((file as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const size = fileUtil.getFileSizeKb(filStr);
					const totalSize = +size + this.getTotalAttachmentsSize();

					if (totalSize > 10000) {
						this.loggerService.Warning(
							{},
							logMessage.shared.fileLinkSize.single.error
						);
						stop$.next();
					}
					return file;
				}),
				tap((x) => {
					const fileStr = `${((x as string) ?? '')?.replace(
						/^data:(.*,)?/,
						''
					)}`;
					const linkedDocument = {
						// @ts-ignore-next
						DocumentId: +documentId,
						FileName: fileName,
						GenerateContentCallback$: undefined,
						Content: fileStr,
						PdfOptions: {
							orientation: '',
							format: '',
						},
						FileSize: +fileUtil.getFileSizeKb(fileStr),
						Type: 'linked',
					}
					this.onLinked.emit(linkedDocument);
					this.attachments.push(linkedDocument as any);
				}),
				takeUntil(stop$),
				catchError((err) => {
					return err;
				})
			)
			.subscribe();
	}

}
