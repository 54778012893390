import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import {
	concatMap,
	filter,
	finalize,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { MergeTagsService } from '../../../service/merge-tags.service';
import { ProviderCommissionService } from '../../../service/provider-commission.service';
import { BlStaffViewmodel } from '../../../viewmodels/bl-staff.viewmodel';

@Component({
	selector: 'app-provider-commission',
	templateUrl: './provider-commission.component.html',
})
export class ProviderCommissionComponent implements OnInit {
	@Input() data: BlStaffViewmodel;
	@Input() lrp: ViewDisplayValue[];

	staffId = 0;
	isProviderLoading$ = new BehaviorSubject<boolean>(null);

	constructor(
		private pcService: ProviderCommissionService,
		private mtService: MergeTagsService
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		of(this.data)
			.pipe(
				filter((data) => !!data),
				tap((data) => (this.staffId = data?.StaffID)),
				mergeMap((data) => this.getProviders(+data?.StaffID)),
				take(1)
			)
			.subscribe();
	}

	getProviders(id: number) {
		return of(id).pipe(
			tap(() => this.isProviderLoading$.next(true)),
			concatMap(() => this.pcService.getProviderCommissionLR(+id)),
			concatMap(() => this.pcService.getProviderCommissionFG(+id)),
			concatMap(() => this.pcService.getProviderCommissionM(+id)),
			concatMap(() => this.pcService.getProviderCommissionK(+id)),
			concatMap(() => this.mtService.getMergeTags(3, 'settings', +id, 'PCT').pipe(finalize(() => this.mtService.setPCTMergeTagsLoaded(true)))),
			finalize(() => this.isProviderLoading$.next(false))
		);
	}
}
