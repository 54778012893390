import { Directive, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BusinessConfig } from '@domain/business-config/business-config.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { Subscription, distinctUntilChanged, map, tap } from 'rxjs';

/**
	* ==================== USAGE ====================
	*	<div *appQuickwinsFlag="MOAT2Quickwins; else moatv1Template">
	*		this will show if moatv2 is enabled
	*	</div>
	* <ng-template #moatv1Template>
	*		this will show if moatv2 is disabled
	* </ng-template>
  *
	* - else template is optional
	*/
@Directive({
  selector: '[appQuickwinsFlag]'
})
export class QuickwinsFlagDirective implements OnInit {

	@Input() appQuickwinsFlag: keyof BusinessConfig;

	@Input() appQuickwinsFlagElse: TemplateRef<any>;

	private sub: Subscription;

	private viewRef: EmbeddedViewRef<any>;

  constructor(
		private businessConfigQuery: BusinessConfigQuery,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {}

	ngOnInit(): void {
		this.sub = this.businessConfigQuery.select()
			.pipe(
				map((state) => state?.config?.[this.appQuickwinsFlag]),
				distinctUntilChanged(),
				tap((isEnabled) => {
					this.viewContainerRef.clear();
					if (isEnabled) {
						this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
						return;
					}

					if (this.appQuickwinsFlagElse) {
						this.viewRef = this.viewContainerRef.createEmbeddedView(this.appQuickwinsFlagElse);
					}
				})
			).subscribe()
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
		this.viewRef?.destroy();
	}

}
