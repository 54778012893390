import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-property-assets-type-modal',
  templateUrl: './property-assets-type-modal.component.html',
  styleUrls: ['./property-assets-type-modal.component.scss']
})
export class PropertyAssetsTypeModalComponent {

  @Output() selectEvt: EventEmitter<any> = new EventEmitter();
  cancel: Observable<any>;

  header = '';
  message: string;
  KT: any;
  isSaving = false;
  isLoading = false;

  constructor(
    public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {
    this.setupProps();
    this.setupSubs();
  }
  form: UntypedFormGroup = this.fb.group({
    type: ['', [Validators.required]]
  });

  get type() {
    return this.form.get('type');
  }

  setupProps() {
    this.header = 'Confirm';
    this.message = 'Confirm?';
  }
  setupSubs() { }

  continue() {
    if (!this.form.value.type) {
      return;
    }
    this.isLoading = true
    this.selectEvt.emit(this.form.value.type);
    this.bsModalRef.hide();
    this.isLoading = false;
  }

  decline() {
    if (!!this.cancel) {
      this.cancel.subscribe();
    }
    this.bsModalRef.hide();
  }

}
