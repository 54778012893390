import { Store, StoreConfig } from '@datorama/akita';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { DisclosureDocumentState } from '../../../../shared/models/client-review-template/disclosure-document/disclosure-document.model';
import { ProviderCommissionSettingState } from '../../../../shared/models/provider-commission/provider-commission.model';
import { Injectable } from "@angular/core";
import { BlStaffModel, BlStaffSettingsModel } from '@shared/models/_general/bl-staff.model';
import { PDTWidgetModel } from '@shared/models/_general/pdt-widget.model';

export interface EmailAccessPerAdviceProcess {
	adviceProcessCount: number;
	clientFirstName: string;
	clientLastName: string;
	emailAddress: string;
}
export interface UserSettingsState {
	userDetails: BlStaffModel;
	staffSettings: BlStaffSettingsModel[];
	disclosureSettings: DisclosureDocumentState;
	providerCommissionLR: ProviderCommissionSettingState[];
	providerCommissionM: ProviderCommissionSettingState[];
	providerCommissionFG: ProviderCommissionSettingState[];
	providerCommissionK: ProviderCommissionSettingState[];
	mergeTags: MergeTagState[];
	emailAccessPerAdviceProcess: EmailAccessPerAdviceProcess[];
	pCTmergeTagsLoaded: boolean;
	pdtWidget: PDTWidgetModel,
}

export function createInitialState(): UserSettingsState {
	return {
		userDetails: null,
		staffSettings: null,
		disclosureSettings: null,
		providerCommissionLR: [],
		providerCommissionM: [],
		providerCommissionFG: [],
		providerCommissionK: [],
		mergeTags: [],
		emailAccessPerAdviceProcess: null,
		pCTmergeTagsLoaded: false,
		pdtWidget: null,
	};
}

@Injectable()
@StoreConfig({ name: 'userSettings' })
export class UserSettingsStore extends Store<UserSettingsState> {
	constructor() {
		super(createInitialState());
	}

	setUserDetails(data: BlStaffModel) {
		this.update({ userDetails: data });
	}

	setStaffSettings(data: BlStaffSettingsModel[]) {
		this.update({ staffSettings: data });
	}

	setDisclosureDocument(data: DisclosureDocumentState) {
		this.update({ disclosureSettings: data });
	}

	setProviderCommissionLR(data: ProviderCommissionSettingState[]) {
		this.update({ providerCommissionLR: data });
	}

	setProviderCommissionM(data: ProviderCommissionSettingState[]) {
		this.update({ providerCommissionM: data });
	}

	setProviderCommissionFG(data: ProviderCommissionSettingState[]) {
		this.update({ providerCommissionFG: data });
	}

	setProviderCommissionK(data: ProviderCommissionSettingState[]) {
		this.update({ providerCommissionK: data });
	}

	setMergeTags(data: MergeTagState[]) {
		this.update({ mergeTags: data });
	}

	setEmailAccessPerAdviceProcess(data: EmailAccessPerAdviceProcess[]) {
		this.update({ emailAccessPerAdviceProcess: data });
	}

	setPCTMergeTagsLoaded(data: boolean) {
		this.update({ pCTmergeTagsLoaded: data });
	}

	setPDTWidget(data: PDTWidgetModel) {
		this.update({ pdtWidget: data });
	}
}
