import { Injectable } from '@angular/core';
import { TLStaffSecGroup } from './tl-staff-security-group.model';
import { TLStaffSecGroupsStore } from './tl-staff-security-groups.store';
import { Observable, of } from 'rxjs';
import { TLStaffSecGroupsQuery } from './tl-staff-security-groups.query';
import { ApiService } from '../../core/base/api.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class TLStaffSecGroupsService {

	constructor(
		private api: ApiService,
		private secGroupStore: TLStaffSecGroupsStore,
		private secGroupQuery: TLStaffSecGroupsQuery) { }

	addList(): Observable<TLStaffSecGroup[]> | Promise<TLStaffSecGroup[]> {
		const sq = this.secGroupQuery.getAll();

		if (sq.length > 0) {
			return of(sq);
		}
		const endpoint = `security-groups?type=tl`;

		return this.api.get<TLStaffSecGroup[]>(endpoint).pipe(tap(x => this.secGroupStore.add(x)));
	}

}
