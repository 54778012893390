import { CommonModule } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnInit,
	forwardRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormsModule,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
	selector: 'app-input-radio-group',
	templateUrl: './input-radio-group.component.html',
	styleUrls: ['./input-radio-group.component.scss'],
	standalone: true,
	imports: [CommonModule, FormsModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputRadioGroupComponent),
			multi: true,
		},
	],
})
export class InputRadioGroupComponent<T>
	implements OnInit, ControlValueAccessor
{
	@HostBinding('class') get className(): string {
		return `d-flex ${this.layout === 'vertical' ? 'flex-column' : 'flex-row'}`;
	}

	@HostBinding('style.gap.px') get gap(): number {
		return this.radioGap;
	}

	@Input() name = 'radio';

	@Input() items: T[];

	@Input() layout: 'vertical' | 'horizontal' = 'horizontal';

	@Input() valueProperty: keyof T;

	@Input() displayProperty: keyof T;

	@Input() value: any;

	@Input() radioHeight = 20;

	@Input() radioWidth = 20;

	@Input() disabled = false;

	@Input() radioGap = 10;

	change: (value: any) => void;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

	writeValue(obj: any): void {
		this.value = obj;
		this.cdr.detectChanges();
	}

	registerOnChange(fn: (value: any) => void): void {
		this.change = fn;
	}

	registerOnTouched(fn: any): void {}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange(value: any): void {
		this.value = value;
		this.change?.(value);
	}
}
