import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import produce from 'immer';
import * as R from 'ramda';
import { AdviceProcessRequest } from '../advice-process-request.model';
import { AdviceProcessUiStore } from './advice-process-ui.store';
import { Row } from './advice-process.model';

interface ColumnWidth {
  metakey: string;
  width: number;
}

export interface AdviceProcessState extends EntityState<Row> {
  columns: string[];
  columnWidths: ColumnWidth[];
  count: number;
  isComplete: boolean;
  searchForm: AdviceProcessRequest;
}

@Injectable()
@StoreConfig({ name: 'AdviceProcesses', idKey: 'AdviceProcessID' })
export class AdviceProcessStore extends EntityStore<AdviceProcessState, Row> {
  constructor(public uiStore: AdviceProcessUiStore) {
    super({
      columns: [],
      count: 0,
      totalAPI: 0,
      isComplete: false,
      columnWidths: [],
      searchForm: null,
    });
  }

  setColumns(columns: string[]) {
    this.update(
      produce((x) => {
        x.columns = columns;
      })
    );
  }

  setColumnWidths(columnWidths: ColumnWidth[]) {
    this.update(
      produce((x) => {
        x.columnWidths = columnWidths;
      })
    );
  }

  setSearchForm(req: AdviceProcessRequest) {
    this.update(
      produce((x) => {
        x.searchForm = req;
      })
    );
  }

  updateField(req: {
    AdviceProcessID: number | number[];
    MetaKey: string;
    MetaValue: string;
  }) {
    this.update(
      req.AdviceProcessID,
      produce((draft) => {
        const fields = R.omit(
          [
            'CustomerID',
            'AdviserName',
            'ReviewedByName',
            'link',
            'ClientNextActivityId',
          ],
          draft
        );
        Object.values(fields).find((x) => x.metakey === req.MetaKey).value =
          req.MetaValue;
      })
    );
  }
}
