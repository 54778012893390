import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RiskAnalysisMedicalState } from '../../../../../../shared/models/client-review-template/risk-analysis/medical/medical.model';

export interface MedicalState extends EntityState<RiskAnalysisMedicalState> {
  activeParentCRTId: number;
  formValue: RiskAnalysisMedicalState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'medical', idKey: 'parentCRTId' })
export class MedicalStore extends EntityStore<MedicalState> {
  constructor() {
    super({
      activeParentCRTId: null,
      formValue: null,
    });
  }

  setActiveParentCRTId(parenCRTId) {
    this.update((state) => ({ activeParentCRTId: parenCRTId }));
  }

  setFormValue(formValue) {
    this.update((state) => ({ formValue }));
  }
}
