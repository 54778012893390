<header class="d-flex justify-content-center align-items-center">
  <div>
    <ng-container *ngIf="logo">
      <img [src]="logo" alt="Logo" />
    </ng-container>
  </div>
</header>
<div
  class="d-flex justify-content-center align-items-center app-main-container"
>
  <div
    class="d-flex flex-column justify-content-center align-items-center app-center-box w-auto text-center"
  >
    <h1 class="pb-2">Unauthorized Access</h1>
    <p class="mb-0">Sorry, you are not authorized to access this page.</p>
    <p class="mb-4">Try again with a different account.</p>
    <a *ngIf="isGoToLoginBtn"
      [routerLink]="loginPage"
      class="app-button app-login-button w-auto px-3 py-1"
      ><span>Go to login page</span></a
    >
  </div>
</div>
