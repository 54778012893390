import { Component } from '@angular/core';
import { KiwiSaverSettingsQuery } from '../../state/kiwisaver-settings.query';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html'
})
export class DocumentListComponent {
  documentList$ = this.query.documentList$;

  constructor(
    private query: KiwiSaverSettingsQuery
  ) {}
}
