import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'ramda';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { PeopleState } from '../../../../../shared/models/client-review-template/people/people.model';
import { util } from '../../../../../util/util';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { ClientReviewTemplateService } from '../../states/client-review-template.service';
import { DisabilityQuery } from '../../states/risk-analysis/disability/disability.query';
import { DisabilityService } from '../../states/risk-analysis/disability/disability.service';
import { RiskAnalysisService } from '../../states/risk-analysis/risk-analysis.service';
import { calcUtil } from '../calculations/disability-calculations';
import { Disability } from 'src/app/shared/models/client-review-template/risk-analysis/disability/disability.model';

@Component({
	selector: 'app-disability',
	templateUrl: './disability.component.html',
	styleUrls: ['./disability.component.scss'],
})
export class DisabilityComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();

	form: UntypedFormGroup;
	activePerson: PeopleState;

	people$ = this.crtQuery.people$.pipe(map((x) => x?.filter((p) => !!p.cRTId)));
	riskDisability$ = this.query.selectLoading();

	apcrte$: Observable<ViewDisplayValue[]> = this.crtService.APCRTE$;
	apcrtiid$: Observable<ViewDisplayValue[]> = this.crtService.APCRTIID$;
	apcrtesi$: Observable<ViewDisplayValue[]> = this.crtService.APCRTESI$;
	apcrtdip$: Observable<ViewDisplayValue[]> = this.crtService.APCRTDIP$;
	apcrtr$: Observable<ViewDisplayValue[]> = this.crtService.APCRTR$;
	apcrtpwp$: Observable<ViewDisplayValue[]> = this.crtService.APCRTPWP$;
	isLoading = true;

	isAdviceProcessEnded$ = this.crtQuery.isAdviceProcessEnded$;

	constructor(
		private fb: UntypedFormBuilder,
		private service: DisabilityService,
		private query: DisabilityQuery,
		private crtQuery: ClientReviewTemplateQuery,
		private crtService: ClientReviewTemplateService,
		private rService: RiskAnalysisService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.setDefaultPerson();

		this.query
			.selectEntityAction()
			.pipe(
				filter((data) => !!data),
				tap(() => (this.isLoading = false)),
				take(1)
			)
			.subscribe();
	}

	setDefaultPerson() {
		this.crtQuery.people$
			.pipe(
				filter((data) => !!data),
				tap((people) => {
					if (people && people.length > 0) {
						this.setActive(people[0] ?? null);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	get ExpensesCouldStopList() {
		return this.form.get('expensesCouldStopList') as UntypedFormArray;
	}

	get IncomeCouldStartList() {
		return this.form.get('incomeCouldStartList') as UntypedFormArray;
	}

	get ExpensesCouldStartList() {
		return this.form.get('expensesCouldStartList') as UntypedFormArray;
	}

	get HowLongWouldYouWantYourIncomeCoveredFor() {
		return this.form.get('howLongWouldYouWantYourIncomeCoveredFor');
	}

	get WhatPercentageOfPreTaxIncomeLikeCovered() {
		return this.form.get('whatPercentageOfPreTaxIncomeLikeCovered');
	}

	buildForm() {
		this.form = this.fb.group({
			sectionCode: [AdviceProcessSectionCodes.Disability],
			adviceProcessId: [null],
			expensesCouldStopList: this.fb.array([]),
			expensesCouldStartList: this.fb.array([]),
			incomeCouldStartList: this.fb.array([]),
			whatPercentageOfPreTaxIncomeLikeCovered: [null, Validators.required],
			howLongWouldYouWantYourIncomeCoveredFor: [null],
			availableSickLeave: [0],
			availableAnnualLeave: [0],
			preferredWaitPeriod: [null],
			parentCRTId: [null],
			cRTId: [null],
			monthlyIncomeCoverRequired: [null],

			// Disabled Fields
			lostAfterTaxIncomerPerMonth: [{ value: null, disabled: true }],
			monthlySurplusOrShortfallLost: [{ value: null, disabled: true }],
			monthlySurplusShortfallStopOrStart: [{ value: null, disabled: true }],
			shortfallOfPreTaxIncome: [{ value: null, disabled: true }],
			livingOffSavings: [{ value: null, disabled: true }],
			total: [{ value: null, disabled: true }],
		});
	}

	onSelectionChange(event: string) {
		if (!isEmpty(event) && +event === 0) {
			this.HowLongWouldYouWantYourIncomeCoveredFor.clearValidators();
			this.HowLongWouldYouWantYourIncomeCoveredFor.updateValueAndValidity();
		} else {
			this.HowLongWouldYouWantYourIncomeCoveredFor.setValidators(
				Validators.required
			);
			this.HowLongWouldYouWantYourIncomeCoveredFor.updateValueAndValidity();
		}
	}

	addExpensesCouldStopList() {
		this.ExpensesCouldStopList?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addIncomeCouldStartList() {
		this.IncomeCouldStartList?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	addExpensesCouldStartList() {
		this.ExpensesCouldStartList?.push(
			this.fb.group({
				dropdown: [''],
				value: [''],
			})
		);
	}

	prepareData() {
		this.clearFormArray(this.ExpensesCouldStopList);
		this.clearFormArray(this.ExpensesCouldStartList);
		this.clearFormArray(this.IncomeCouldStartList);

		combineLatest([
			this.query.selectEntity(this.activePerson?.cRTId),
			this.riskDisability$,
		])
			.pipe(
				filter(([, d]) => !d),
				map(([data]) => data),
				map((x) => {
					const expensesCouldStopList = x?.expensesCouldStopList ?? [];
					const expensesCouldStartList = x?.expensesCouldStartList ?? [];
					const incomeCouldStartList = x?.incomeCouldStartList ?? [];

					if (x) {
						return {
							...x,
							expensesCouldStopList,
							expensesCouldStartList,
							incomeCouldStartList,
						};
					} else {
						return x;
					}
				}),
				tap((x) => {
					this.populateExpenseIncome(x);

					this.form.patchValue({
						...x,
						...this.getComputations(x),
						sectionCode: AdviceProcessSectionCodes.Disability,
						parentCRTId: this.activePerson?.cRTId ?? null,
						adviceProcessId: this.crtQuery.getValue().adviceProcessId ?? null,
					});

					if (
						!isEmpty(
							this.form.getRawValue()?.whatPercentageOfPreTaxIncomeLikeCovered
						)
					) {
						this.onSelectionChange(
							this.form.getRawValue()?.whatPercentageOfPreTaxIncomeLikeCovered
						);
					}
				}),
				tap(() => (this.isLoading = false)),
				tap(() => this.form.markAllAsTouched()),
				withLatestFrom(this.crtQuery.isAdviceProcessEnded$),
				tap(([, apEnded]) => {
					if (apEnded) {
						this.form.disable();
					}
				}),
				map(([x]) => x),
				take(1)
			)
			.subscribe();
	}

	populateExpenseIncome(disability: Disability) {
		if (disability) {
			(disability.expensesCouldStopList.length > 0
				? disability.expensesCouldStopList
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) =>
				this.ExpensesCouldStopList?.push(this.pushArrayList(l))
			);
			(disability.expensesCouldStartList.length > 0
				? disability.expensesCouldStartList
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) =>
				this.ExpensesCouldStartList?.push(this.pushArrayList(l))
			);
			(disability.incomeCouldStartList.length > 0
				? disability.incomeCouldStartList
				: [{ dropdown: '', value: '' }]
			)?.forEach((l) => this.IncomeCouldStartList?.push(this.pushArrayList(l)));
		} else {
			this.ExpensesCouldStopList?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.ExpensesCouldStartList?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
			this.IncomeCouldStartList?.push(
				this.pushArrayList({ dropdown: '', value: '' })
			);
		}
	}

	pushArrayList(data: { dropdown: string; value: string }) {
		return this.fb.group({ dropdown: [data.dropdown], value: [data.value] });
	}

	setActive(data: PeopleState) {
		if (!data) {
			return;
		}

		this.activePerson = data;
		this.service.setActiveParentCRTId(data.cRTId);
		this.buildForm();
		this.prepareData();
	}

	getComputations(data: Disability) {
		const id = +this.activePerson?.cRTId;
		const lostAfterTaxIncomerPerMonth =
			+this.rService.getLostAfterTaxIncomePerMonth(id);
		const monthlySurplusOrShortfallLost =
			+this.rService.getMonthlySurplusOrShortfallLost(id);
		const totalExpenseCouldStop = +calcUtil.getTotalFromDropdownValue(
			data?.expensesCouldStopList
		);
		const totalIncomeCouldStartList = +calcUtil.getTotalFromDropdownValue(
			data?.incomeCouldStartList
		);
		const totalExpenseCouldStartList = +calcUtil.getTotalFromDropdownValue(
			data?.expensesCouldStartList
		);
		const monthlySurplusShortfallStopOrStart =
			+calcUtil.getMonthlySurplusShortfallStopOrStart(
				monthlySurplusOrShortfallLost,
				totalExpenseCouldStop,
				totalIncomeCouldStartList,
				totalExpenseCouldStartList
			);
		const shortfallOfPreTaxIncome = +this.rService.getShortfallOfPreTaxIncome(
			id,
			monthlySurplusShortfallStopOrStart
		);
		const percentCovered = +this.rService.getPercentagePreTaxIncomeCover(
			id,
			+data?.monthlyIncomeCoverRequired || 0
		);
		let monthlyIncomeCoverRequired = 0;
		if (percentCovered === +data?.whatPercentageOfPreTaxIncomeLikeCovered) {
			// TAPNZ-8527: Compute first if the saved monthly cover required matches the
			// percentage pre tax income based on the formula. If yes, then do not recompute.
			monthlyIncomeCoverRequired = +data?.monthlyIncomeCoverRequired;
		} else {
			// Recompute Monthly Income Cover Required
			monthlyIncomeCoverRequired = +this.rService.getMonthlyIncomeCoverRequired(
				id,
				+data?.whatPercentageOfPreTaxIncomeLikeCovered
			);
		}
		const livingOffSavings = this.rService.getLivingOffSavings(
			id,
			monthlySurplusShortfallStopOrStart
		);
		const total = calcUtil.getTotalDays(
			data?.availableSickLeave,
			data?.availableAnnualLeave,
			livingOffSavings
		);

		return {
			lostAfterTaxIncomerPerMonth,
			monthlySurplusOrShortfallLost,
			monthlySurplusShortfallStopOrStart,
			shortfallOfPreTaxIncome,
			monthlyIncomeCoverRequired,
			livingOffSavings,
			total,
		};
	}

	updateList(data: any) {
		const newData = data ? util.removeEmptyObjsFromArr(data) : [];
		return isEmpty(newData) ? null : newData;
	}

	onChange() {
		const formValue: Disability = this.form.getRawValue();

		formValue.expensesCouldStartList = this.updateList(
			formValue.expensesCouldStartList
		);
		formValue.expensesCouldStopList = this.updateList(
			formValue.expensesCouldStopList
		);
		formValue.incomeCouldStartList = this.updateList(
			formValue.incomeCouldStartList
		);
		const computations = this.getComputations(formValue);
		this.form.patchValue(computations);
		this.service.setFormValue({ ...formValue, ...computations });

		if (!isEmpty(formValue?.whatPercentageOfPreTaxIncomeLikeCovered)) {
			this.onSelectionChange(
				formValue?.whatPercentageOfPreTaxIncomeLikeCovered
			);
		}
	}

	computePreTaxIncome() {
		const id = +this.activePerson?.cRTId;
		const formValue = this.form.getRawValue();
		formValue.expensesCouldStartList = this.updateList(
			formValue.expensesCouldStartList
		);
		formValue.expensesCouldStopList = this.updateList(
			formValue.expensesCouldStopList
		);
		formValue.incomeCouldStartList = this.updateList(
			formValue.incomeCouldStartList
		);
		const percentCovered = +this.rService.getPercentagePreTaxIncomeCover(
			id,
			+formValue?.monthlyIncomeCoverRequired || 0
		);
		this.WhatPercentageOfPreTaxIncomeLikeCovered.setValue(percentCovered);
		this.service.setFormValue({
			...formValue,
			whatPercentageOfPreTaxIncomeLikeCovered: percentCovered,
		});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
	};

	removeList(formArray: UntypedFormArray, index: number) {
		formArray.removeAt(index);
		this.onChange();
	}

	clear(formArray: UntypedFormArray, index: number) {
		formArray.at(index).get('dropdown').setValue('');
		formArray.at(index).get('value').setValue('');
		this.onChange();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
		this.service.setShowInvalid(false);
	}
}
