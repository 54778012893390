import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
  NgZone,
} from '@angular/core';
import { FieldMetadata } from '../../dynamic-field/field-metadata.model';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';
import { CommandRoute } from 'src/app/core/config/route.service';

/** Component for dropdown control. */
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnChanges, OnDestroy {
  /** destroy subject that will trigger unsubscription */
  private onDestroy$ = new Subject<void>();
  /** display value. computed by `choiceChanges()` */
  public display: string;
  /** complete  */
  public completeChoices: Record<string, string>;
  /** route to go to */
  @Input() route: CommandRoute;
  /** value of dropdown */
  @Input() value: string;
  /** class for overdue color code */
  @Input() cellClass: any;
  /** index number for use in ID */
  @Input() index: any;
  /** field name for use in ID */
  @Input() fieldId: any;
  /** restrictions */
  @Input() restrict: string[];
  /** if currently saving */
  @Input() isLoading: boolean;
  /** if currently in edit mode */
  @Input() isEditing: boolean;
  /** if currently is adviser choice */
  @Input() hasSort?: boolean;
  /** to use on save event for now. maybe get rid later */
  @Input() metadata: FieldMetadata<any>;
  /** temporary value.
   *  To show when its not empty and in edit mode
   */
  @Input() tempValue: string;
  /** choices for dropdown */
  @Input() choices: ViewDisplayValue[];
  /** choices as object for faster lookup.
   * object key is `viewdisplayvalues`'s `value`.
   * Should be computed by the component that uses
   * this component so that computation does not
   * repeat every instance of dropdown.
   */
  @Input() choicesObject: Record<string, string>;
  /** choices for deleted values. include the item from here if it IS here. */
  @Input() allChoices: ViewDisplayValue[];
  /** choices Object for deleted values. include the item from here if it IS here. */
  @Input() allChoicesObject: Record<string, string>;
  /** input whether field is required */
  @Input() isRequired: boolean;
  /** input whether field is editable */
  @Input() isEditable: boolean;

  /** event for saving */
  @Output() saveEvent = new EventEmitter<FieldMetadata<any>>();
  /** event for cancel */
  @Output() cancelEvent = new EventEmitter<void>();
  /** event for edit */
  @Output() editEvent = new EventEmitter<void>();
  /** event for pending field changes. Used to store unsaved data. */
  @Output() fieldUpdateEvent = new EventEmitter<string>();

  /** form control for textbox */
  field = new UntypedFormControl();
  /** subscription reference to easily unsubscribe */
  fieldSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef, private ngZone: NgZone) {}

  /** on changes trigger all change checks */
  ngOnChanges(changes: SimpleChanges): void {
    this.editChanges(changes);
    this.valueChanges(changes);
    this.requiredChanges(changes);
    this.choiceChanges(changes);
  }
  /** unsubscribe to field subscription on destroy */
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  /** sets up `field`.
   * * if `tempValue` has value, set `tempValue` to `field`
   * * else set `value` to `field`
   * * start listening to field on edit to store unsaved data
   * * stop listening to field on NOT edit for performance
   */
  editChanges(changes: SimpleChanges) {
    const notChanged =
      changes.isEditing === undefined ||
      changes.isEditing.currentValue === changes.isEditing.previousValue;
    if (notChanged) {
      return;
    }

    if (changes.isEditing.currentValue) {
      const hasTempValue = this.tempValue !== undefined;
      const resetValue = hasTempValue ? this.tempValue : this.value;
      this.field.reset(resetValue);
      this.fieldSubscription = this.field.valueChanges
        .pipe(debounceTime(400), takeUntil(this.onDestroy$))
        .subscribe((v: string) => this.fieldUpdateEvent.emit(v));
    }
    if (!changes.isEditing.currentValue && this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
      this.fieldSubscription = undefined;
    }
  }
  /** if value changes, reset field value */
  valueChanges(changes: SimpleChanges) {
    const notChanged =
      changes.value === undefined ||
      changes.value.currentValue === changes.value.previousValue;
    if (notChanged) {
      return;
    }

    const hasTempValue = this.tempValue !== undefined;
    const resetValue = hasTempValue
      ? this.tempValue
      : changes.value.currentValue;
    this.field.reset(resetValue);
  }

  /** If required, add validators. else clear validators */
  requiredChanges(changes: SimpleChanges) {
    const notChanged =
      changes.isRequired === undefined ||
      changes.isRequired.currentValue === changes.isRequired.previousValue;
    if (notChanged) {
      return;
    }

    if (changes.isRequired.currentValue) {
      this.field.setValidators([Validators.required, NoWhitespaceValidator]);
    } else {
      this.field.clearValidators();
    }
  }
  /**
   * computes display value and choices that should include selected but deleted value.
   * @param changes
   */
  choiceChanges(changes: SimpleChanges) {
    const choicesNotChanged =
      changes.choicesObject === undefined ||
      changes.choicesObject.currentValue ===
        changes.choicesObject.previousValue;
    const allChoicesNotChanged =
      changes.allChoicesObject === undefined ||
      changes.allChoicesObject.currentValue ===
        changes.allChoicesObject.previousValue;
    const valueNotChanged =
      changes.value === undefined ||
      changes.value.currentValue === changes.value.previousValue;
    const notChanged =
      choicesNotChanged && allChoicesNotChanged && valueNotChanged;

    if (notChanged) {
      return;
    }
    const { choicesObject, allChoicesObject, value } = this;
    let completeChoices = choicesObject;

    const exists = !!value && choicesObject[value] !== undefined;
    if (!exists && allChoicesObject && allChoicesObject[value] !== undefined) {
      completeChoices = {
        ...completeChoices,
        [value]: allChoicesObject[value],
      };
    }
    this.completeChoices = completeChoices;

    // if (value) {
    //   this.display = completeChoices[value] || '';
    // }
    this.display = value ? (completeChoices[value] || '') : '';
  }
  /** trigger edit event */
  edit() {
    this.editEvent.emit();
  }
  /** trigger cancel event */
  cancel() {
    this.cancelEvent.emit();
  }
  /** trigger save event */
  save() {
    if (this.field.valid) {
      this.saveEvent.emit(this.prapareData());
    }
  }
  /** prepare data for saving */
  private prapareData(): FieldMetadata<string> {
    return {
      ...this.metadata,
      value: this.field.value.trim(),
    };
  }

  /**
   * routeLink click, gets stuck when using ngx-datatable.
   * Somehow solves it.
   */
  onClick() {
    this.ngZone.run(() => this.cd.detectChanges());
  }
}
