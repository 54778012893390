import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isNil } from 'ramda';
import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import {
	filter,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { PeopleState } from '../../../../../../shared/models/client-review-template/people/people.model';
import { CriticalIllnessMapper } from '../../../../../../shared/models/client-review-template/risk-analysis/critical-illness/critical-illness.mapper';
import {
	CriticalIllnessState,
	DropdownState,
} from '../../../../../../shared/models/client-review-template/risk-analysis/critical-illness/critical-illness.model';
import { ViewDisplayValue } from '../../../../../../shared/models/_general/display-value.viewmodel';
import { CriticalIllnessService } from '../../../states/risk-analysis/critical-illness/critical-illness.service';
import { RiskAnalysisService } from '../../../states/risk-analysis/risk-analysis.service';
import { calcUtil } from '../../calculations/disability-calculations';
import { DropdownValueState } from 'src/app/domain/dropdown-value/dropdown-value.store';

@Component({
	selector: 'app-illness-calculation',
	templateUrl: './illness-calculation.component.html',
	styleUrls: ['./illness-calculation.component.scss'],
})
export class IllnessCalculationComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;

	@Input() isAdviceProcessEnded: boolean;

	@Input() APCRTTRC$: Observable<ViewDisplayValue[]>;
	@Input() APCRTEC$: Observable<ViewDisplayValue[]>;
	@Input() criticalIllness$: Observable<CriticalIllnessState[]>;
	@Input() people$: Observable<PeopleState[]>;
	@Input() selectedCrt: Observable<number>;
	@Input() adviceProcessId: number;

	cRTId: number;
	isNew = false;
	submitted = false;
	form: UntypedFormGroup;
	personIllness: CriticalIllnessState;
	personInfo: PeopleState;
	parentCRTId: number;

	defaults = {
		partner: 'Primary Income (24 months)',
		cost: 'Extra Medical Costs',
	};

	constructor(
		private fb: UntypedFormBuilder,
		private illnessService: CriticalIllnessService,
		private rService: RiskAnalysisService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		combineLatest(this.criticalIllness$, this.people$)
			.pipe(
				filter(([illness, people]) => Boolean(illness) && Boolean(people)),
				take(1)
			)
			.subscribe((res) => this.prepData());
	}

	get PeriodOfCoverList() {
		return this.form.get('periodOfCoverList') as UntypedFormArray;
	}
	get PeriodOfPartnerOffList() {
		return this.form.get('periodOfPartnerOffList') as UntypedFormArray;
	}
	get ExtraCostsList() {
		return this.form.get('extraCostsList') as UntypedFormArray;
	}

	buildForm() {
		this.form = this.fb.group({
			cRTId: '',
			parentCRTId: '',
			adviceProcessId: this.adviceProcessId,
			periodOfCoverList: this.fb.array([]),
			periodOfPartnerOffList: this.fb.array([]),
			extraCostsList: this.fb.array([]),
			totalRequired: { value: '', disabled: true },
		});
	}

	clearForm() {
		this.form.reset({
			cRTId: null,
			parentCRTId: '',
			adviceProcessId: this.adviceProcessId,
			totalRequired: '',
		});

		this.PeriodOfCoverList.clear();
		this.PeriodOfPartnerOffList.clear();
		this.ExtraCostsList.clear();
	}

	prepData() {
		this.selectedCrt
			.pipe(
				mergeMap((crt) => iif(() => !!crt, this.selectCrt(crt), of(false))),
				withLatestFrom(this.criticalIllness$),
				tap(([, criticalIllness]) => {
					const current = criticalIllness?.find(
						(item) => item.parentCRTId === this.cRTId
					);
					if (isNil(this.form.get('cRTId').value) && current) {
						this.form.get('cRTId').setValue(current.cRTId);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	setDefaultValues() {
		this.form.reset({
			adviceProcessId: this.adviceProcessId,
			parentCRTId: this.cRTId,
			totalRequired: 0,
		});
		this.addCoverList();
		this.addPartnerList({});
		this.addExtraItem({});
		this.personIllness = this.form.getRawValue();
	}

	mapValues() {
		if (this.personIllness?.periodOfCoverList?.length > 0) {
			this.personIllness?.periodOfCoverList?.map((cost) => {
				this.addCoverList(cost);
			});
		} else {
			this.addCoverList();
		}

		this.personIllness?.periodOfPartnerOffList?.map((cost) => {
			this.addPartnerList(cost);
		});
		this.personIllness?.extraCostsList?.map((cost) => {
			this.addExtraItem(cost);
		});
	}

	addCoverList(data?: DropdownState) {
		this.PeriodOfCoverList?.push(
			this.fb.group({
				dropdown: [data?.dropdown || '', Validators.required],
				value: { value: data?.value || '0', disabled: true },
			})
		);
	}

	addPartnerList(data: DropdownValueState) {
		this.PeriodOfPartnerOffList?.push(
			this.fb.group({
				dropdown: [data?.dropdown || 'Partner’s Income', Validators.required],
				value: { value: data?.value || '0', disabled: false },
			})
		);
	}

	addExtraItem(data: DropdownValueState) {
		this.ExtraCostsList?.push(
			this.fb.group({
				dropdownValue: data?.dropdownValue || '',
				dropdown: [data?.dropdown || this.defaults.cost, Validators.required],
				value: data?.value || '0',
			})
		);
	}

	AddExpense() {
		this.addExtraItem({});
		this.onChanged();
	}

	deleteExtraItem(index: number) {
		this.ExtraCostsList?.removeAt(index);
		this.onChanged();
	}

	selectCrt = (id: number) =>
		of(id).pipe(
			tap((x) => {
				this.clearForm();
				this.cRTId = x;
			}),
			withLatestFrom(this.criticalIllness$, this.people$),
			tap(([, criticalIllness, people]) => {
				this.personIllness = criticalIllness?.find(
					(x: CriticalIllnessState) => x.parentCRTId === +this.cRTId
				);
				if (this.personIllness) {
					this.form.reset(this.personIllness);
					this.mapValues();
				} else {
					this.setDefaultValues();
				}
				this.recomputePrimaryIncome();

				this.personInfo = people?.find(
					(x: PeopleState) => x.cRTId === +this.cRTId
				);
			}),
			tap(() => this.form.markAllAsTouched()),
			tap(() => {
				if (this.isAdviceProcessEnded) {
					this.form.disable();
					this.PeriodOfCoverList.disable();
					this.PeriodOfPartnerOffList.disable();
					this.ExtraCostsList.disable();
				}
			}),
			take(1)
		);

	recomputePrimaryIncome() {
		const coverList = this.PeriodOfCoverList.controls || [];
		coverList?.forEach((_, i) => {
			this.selectPeriodCover(i);
		});
	}

	selectPeriodPartner(index: number) {
		const dropdown = this.PeriodOfPartnerOffList.controls[index]
			.get('dropdown')
			.value?.replace(/[^0-9]/g, '');
		const dropdownVal = dropdown?.value === '' ? 0 : dropdown;
		const partnerVal = this.rService.getPeriodAmount(this.cRTId, dropdownVal);

		this.PeriodOfPartnerOffList.controls[index]
			.get('value')
			.setValue(+partnerVal);
		this.recomputeTotal();
	}

	selectPeriodCover(index: number) {
		const dropdown = this.PeriodOfCoverList.controls[index]
			.get('dropdown')
			.value?.replace(/[^0-9]/g, '');
		const dropdownVal = dropdown?.value === '' ? 0 : +dropdown;
		const coverVal = Math.round(
			this.rService.getPeriodAmount(this.cRTId, dropdownVal)
		);

		this.PeriodOfCoverList.controls[index].get('value').setValue(+coverVal);
		this.recomputeTotal();
	}

	onChanged() {
		this.recomputeTotal();
	}

	onChangeOther(event: Event, item: UntypedFormGroup) {
		if (!(event.target as HTMLInputElement).value) {
			item.get('dropdown').setValue('');
		}
		this.updateState();
	}

	// onChangeOther(event, item) {
	// 	if (!event.target.value) {
	// 		item.get('dropdown').value = '';
	// 	}
	// 	this.updateState();
	// }

	recomputeTotal() {
		const data = this.form.getRawValue();
		const totalPeriodOfPartnerOffList = +calcUtil.getTotalFromDropdownValue(
			data?.periodOfPartnerOffList
		);
		const totalPeriodOfCoverList = +calcUtil.getTotalFromDropdownValue(
			data?.periodOfCoverList
		);
		const totalExtraCostsList = +calcUtil.getTotalFromDropdownValue(
			data?.extraCostsList
		);
		const totalRequired = +calcUtil.getTotalRequired(
			totalPeriodOfCoverList,
			totalPeriodOfPartnerOffList,
			totalExtraCostsList
		);

		this.form.get('totalRequired').setValue(totalRequired);
		this.updateState();
	}

	updateState() {
		const getData = CriticalIllnessMapper.mapToUpsert({
			...this.form.getRawValue(),
			touched: true,
		});
		this.illnessService.updateCriticalIllnessState(
			+this.personIllness?.parentCRTId,
			getData
		);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
