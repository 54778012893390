import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import MomentUtil from '@util/moment.util';
import { DefaultFileNames, DocumentTypes } from '@shared/models/documents/document.model';
import { LoatCffSettingState } from './loat-cff-template-settings.model';
import * as R from 'ramda';

export class LoatCffTemplateSettingsMapper {
  public static mapMergeTags(data: MergeTagState[]) {
    return data?.map((item) =>
      item?.metaKey === 'DATE_TODAY'
        ? {
            ...item,
            value: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
            secondaryValue: MomentUtil.createMomentNz().format('DD/MM/YYYY'),
          }
        : item
    );
  }

  public static mapDocumentUpload(document, template) {
    if (template === 0 || isNaN(template) || R.isNil(template)) {
      return {
        document,
        referenceId: 0,
        // TO-DO Use correct filename and type
        fileName: DefaultFileNames.LoatCff,
        type: DocumentTypes.ClientFactFind,
      };
    }
    return {
      document,
      documentId: +template,
    };
  }

  public static mapEmailSettingsToUpsert(data: LoatCffSettingState) {
    return {
      ...data,
      referenceId: data.referenceId || 0,
      mergeTag: data.mergeTag || [],
      // TO-DO: Use Correct Type
      type: 'DD',
    };
  }
}
