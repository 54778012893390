import { Component, OnInit, Input, QueryList, ViewChildren, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import * as R from 'ramda';
import { takeUntil } from 'rxjs/operators';
import { ViewDisplayValue } from '../../../../../shared/models/_general/display-value.viewmodel';
import { MemberMapper } from '../../../../../shared/models/business-profile/members/members.mapper';
import { MembersRequest } from '../../../../../shared/models/business-profile/members/members.model';
import { ClientSearchControlComponent } from '../../../../../shared/search-controls/client-search-control/client-search-control.component';

@Component({
  selector: 'app-form-shareholder',
  templateUrl: './form-shareholder.component.html'
})
export class FormShareholderComponent implements OnInit, OnChanges, OnDestroy {
  private onDestroy$ = new Subject<void>();
  @Input() isLead: boolean;
  @Input() updateFn$: (req: any) => Observable<any>;
  @Input() members: any[] = [];

  private _isSaving = false;
  isSaving$ = new ReplaySubject<boolean>(1);
  @Input()
  set isSaving(value) {
    this.isSaving$.next(value);
  }
  get isSaving(): boolean {
    return this._isSaving;
  }

  private _isEdit = false;
  isEdit$ = new ReplaySubject<boolean>(1);
  @Input()
  set isEdit(value) {
    this.isEdit$.next(value);
  }
  get isEdit(): boolean {
    return this._isEdit;
  }

  @Input() advisers: ViewDisplayValue[] = [];
  @Input() trustTypes: ViewDisplayValue[] = [];
  @Input() customerStatus: string;
  @Input() customerStatusChange: string;
  @Input() leadOriginChoice: ViewDisplayValue[] = [];
  @Input() leadTypeChoice: ViewDisplayValue[] = [];

  form: UntypedFormGroup = this.fb.group({
    shareholders: this.fb.array([])
  });
  shareholders = this.form.get('shareholders') as UntypedFormArray;
  shareholderIds: number[] = [];

  _search: QueryList<ClientSearchControlComponent>;
  @ViewChildren('searchClient') set search(s: QueryList<ClientSearchControlComponent>) {
    this._search = s;
  }

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this._isEdit = x;
      x ? this.form.enable() : this.form.disable();
    });
    this.isSaving$.pipe(takeUntil(this.onDestroy$)).subscribe(x => (this._isSaving = x));
  }

  ngOnChanges() {
    this.shareholderIds = this.members ? this.members?.map(x => x.customerId) : [];
    const shareholders = this.form.get('shareholders') as UntypedFormArray;
    this.clearFormArray(shareholders);
    const data = this.members
      ? Object.assign({}, { shareholders: MemberMapper.mapToView(this.members) })
      : this.form.value;
    Array.from(data.shareholders)?.forEach(() => this.addOnFormArray(shareholders));
    this.form.reset(data);
  }

  private addOnFormArray(formArray: UntypedFormArray) {
    const control = this.fb.control(null);
    if (this.form.disabled) {
      control.disable();
    }
    formArray.push(control);

    return formArray;
  }

  addShareholder = () => this.addOnFormArray(this.shareholders);

  isDeletable(d, ids) {
    if (!d || !d.value) {
      return true;
    }
    return ids?.some(x => x === d.value.CustomerId);
  }

  private clearFormArray(formArray: UntypedFormArray) {
    while (formArray.length > 0) {
      formArray?.removeAt(0);
    }
    return formArray;
  }

  removeFormArrayItem = (key, index: number) => {
    return (this.form.get(key) as UntypedFormArray)?.removeAt(index);
  };

  remove = (index: number) => this.removeFormArrayItem('shareholders', index);

  quickAdd = (value: any, index: number) => {
    let name: string;
    let primaryContactNumber: number;
    let IsCompany = false;

    // Client
    if (value.firstName !== undefined && value.lastName !== undefined) {
      name = value.firstName + ' ' + value.lastName;
    }
    // Business
    if (value.companyName !== undefined) {
      name = value.companyName;
      IsCompany = true;
    }
    // Trust (Secondary)
    if (value.trustName !== undefined) {
      name = value.trustName;
      primaryContactNumber = value.primaryCustomer;
    }
    const selectedContact = {
      CustomerId: +value.CustomerId,
      IsCompany,
      Name: name,
      PrimaryCustomerId: primaryContactNumber,
      IsAccessible: true
    };

    this._search?.filter(x => x.searchMode === value.searchMode)[index].setSearchedValue(selectedContact);
  }

	selectEvent = (value: number) => {
		let list = this.shareholderIds
			? this.shareholderIds || []
			: this.members?.map((x) => x.customerId) || [];
		if (!!value) {
			list.push(value);
		}
		list = R.uniq(list || []);
		this.shareholderIds = list?.filter(Boolean);
	};

	clearEvent = (value: number) => {
		if (!value) {
			return;
		}
		let list = this.shareholderIds
			? this.shareholderIds || []
			: this.members?.map((x) => x.customerId) || [];
		list = list?.filter((x) => +x !== +value)?.filter(Boolean);
		this.shareholderIds = R.uniq(list || []);
	};

  prepareFormValue() {
    const filterOutNull = (keyname: keyof MembersRequest) =>
      R.pipe(R.propOr([], keyname), R.filter(R.complement(R.isNil)));
    return {
      shareholders: filterOutNull('shareholders')(this.form.value)
    };
  }

  save() {
    this.isSaving$.next(true);
    this.updateFn$(this.prepareFormValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => {
          this.isEdit$.next(false);
        },
        () => {
          this.isEdit$.next(true);
        },
        () => {
          this.isSaving$.next(false);
        }
      );
  }

  edit() {
    this.isEdit$.next(true);
  }
  cancel() {
    this.isEdit$.next(false);
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
