<div class="property-sold">
  <div class="property-sold-header font-weight-bold mb-3">
    Loans to be repaid by sale
  </div>
  <ng-container *ngIf="isLoading; else showForm">
		<div class="pb-3">
			<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
		</div>
  </ng-container>
  <ng-template #showForm>
    <app-loan-repaid-form
      [isLoading]="isLoading"
			[parentCRTId]="parentCRTId"
      [loans]="loans"
      [updateFn$]="update"
      [netProceeds]="netProceeds"
      [borrowers$]="borrowers$"
      [securities$]="securities$"
      [MP$]="MP$"
      [MLT$]="MLT$"
      [APCRTF$]="APCRTF$"
      [APCRTYN$]="APCRTYN$"
      [APCRTYNNA$]="APCRTYNNA$"
      [APCRTL$]="APCRTL$"
      [addFn$]="add"
			[getOwnerChoices]="getOwnerChoices"
    >
    </app-loan-repaid-form>
  </ng-template>
</div>
