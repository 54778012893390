import { Component, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-secondary-base-widget',
  templateUrl: './secondary-base-widget.component.html',
  styleUrls: ['./secondary-base-widget.component.scss']
})
export class SecondaryBaseWidgetComponent implements AfterViewInit {

  hasControl = false;

  @ViewChild('widgetControl') control: ElementRef;
  @Input() headerText: string;
  @Input() subHeaderText: string;
  @Input() widgetCode: string;

  constructor(private cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.hasControl = !!this.control.nativeElement.innerHTML.trim();
    this.cd.detectChanges();
  }
}
