import { Injectable } from '@angular/core';
import { LoanStructureStore } from './loan-structure.store';
import { LoanStructure } from './loan-structure.model';
import { Observable, EMPTY, of } from 'rxjs';
import { tap, finalize, catchError, switchMap, map } from 'rxjs/operators';
import { ApiService } from '../../../../../../../../core/base/api.service';
import { LoanStructureQuery } from './loan-structure.query';
import { objectUtil, numUtil } from 'src/app/util/util';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';

import { sum } from 'ramda';
import { applyTransaction } from '@datorama/akita';

@Injectable({
	providedIn: 'root',
})
export class LoanStructureService {
	loanStructure$ = this.query.selectAll();
	isLoading$ = this.query.selectLoading();
	totalLoanAmount$ = this.query.loanStructures$.pipe(
		map((ls) => +numUtil.formatToNumCurrency(sum(ls.map((l) => l?.loanAmount || 0))))
	);

	constructor(
		private store: LoanStructureStore,
		private query: LoanStructureQuery,
		private api: ApiService
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	/*
	 * @description get single loan structure data
	 * @param cRTId {number} - loan structure cRTId
	 */
	get(cRTId: number): Observable<LoanStructure> {
		return this.api
			.get<LoanStructure>(`crt/${cRTId}`)
			.pipe(map((x) => objectUtil.mapPascalCaseToCamelCase(x)));
	}

	getAll(parentCRTId: number): Observable<LoanStructure[]> {
		this.store.setLoading(true);
		const endpoint = `crt/${AdviceProcessSectionCodes.Application}/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.ApplicationLoanStructure}`;
		return this.api.get<LoanStructure[]>(endpoint).pipe(
			map((x) => x?.map(objectUtil.mapPascalCaseToCamelCase)),
			tap((x) => this.store.set(x ?? [])),
			finalize(() => this.store.setLoading(false)),
			catchError(() => {
				this.store.setLoading(false);
				return EMPTY;
			})
		);
	}

	add(loanStructure: LoanStructure): Observable<LoanStructure> {
		const endpoint = `crt`;
		const data = {
			...loanStructure,
			sectionCode: AdviceProcessSectionCodes.ApplicationLoanStructure,
		};
		return this.api
			.post(endpoint, objectUtil.mapCamelCaseToPascalCase(data))
			.pipe(
				switchMap((cRTId: number) => this.get(cRTId)),
				tap((x) => this.store.add(x))
			);
	}

	update(loanStructure: LoanStructure): Observable<LoanStructure> {
		const endpoint = `crt/${loanStructure.parentCRTId}`;
		const data = {
			...loanStructure,
			sectionCode: AdviceProcessSectionCodes.ApplicationLoanStructure,
		};
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(data))
			.pipe(
				switchMap(() => this.get(loanStructure?.cRTId)),
				tap((x) => this.store.update(x?.cRTId, x)),
				catchError((err) => of(err))
			);
	}

	delete(parentCRTId: number): Observable<boolean> {
		this.store.setLoading(true);
		const endpoint = `crt/${parentCRTId}`;
		return this.api.delete<string>(endpoint).pipe(
			tap(() => this.store.setLoading(false)),
			tap(() => this.store.remove(parentCRTId)),
			finalize(() => this.store.setLoading(false)),
			catchError((err) => of(err))
		);
	}
}
