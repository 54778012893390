import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from '@core/base/api.service';
import { objectUtil } from '@util/util';
import { DocumentModelState } from '@shared/models/documents/document.model';
import { DocumentUploadState, LoatCffSettingState } from './loat-cff-template-settings.model';
import { CrtSettingsStore } from '@modules/crt-settings/state/crt-settings.store';
import { CrtSettingsQuery } from '@modules/crt-settings/state/crt-settings.query';
import { ReloadDocumentType } from '@modules/crt-settings/state/crt-settings.model';

@Injectable()
export class LoatCffTemplateSettingsService {
	loatCffSettings$ = this.query.loatCffSettings$;

	constructor(
		private api: ApiService,
    private store: CrtSettingsStore,
    private query: CrtSettingsQuery
	) {}

	getLoatCffDocument(data: number, settingsCode: string) {
		const endpoint = `crt/settings/${data}/${settingsCode}`;
		return this.api.get<LoatCffSettingState>(endpoint).pipe(
			tap((res) =>
				applyTransaction(() => {
					const state = res ? objectUtil.mapPascalCaseToCamelCase(res) : null;
					this.store.setLoatCffSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	updateClientFactFind(data: LoatCffSettingState) {
		const endpoint = `crt/settings/${data.referenceId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(data);
		return this.api.put<LoatCffSettingState>(endpoint, body).pipe(
			tap((res) =>
				applyTransaction(() => {
					this.store.setLoatCffSettings(res);
				})
			)
		);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getClientFactFindDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`);
	}

	getDocumentFromURL(url: string) {
		return this.api.getExternalResource(
			`${url}?p=${Math.floor(Date.now() / 1000)}`,
			{ responseType: 'text' }
		);
	}

	getDefaultTemplateUrl() {
		const endpoint = `crt/oat-template/${ReloadDocumentType.ClientFactFind}`;
		return this.api.get<string>(endpoint);
	}
}
