import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import {
	concatMap,
	filter,
	map,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { PropertyService } from '../../../../client-sop/assets-and-liabilities/state/property/property.service';
import { PeopleEntitiesService } from '../../../../client-sop/people-entities/state/people-entities.service';
import { CrtMortgageService } from '../../../../state/crt-mortgage.service';
import { LoanRefinanceState } from './state/loan-refinance.model';
import { LoanRefinanceMapper } from './state/loan-refinance.mapper';
import { LoanRefinanceService } from './state/loan-refinance.service';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { FundingRequiredLoanQuery } from '../selling-section/loan-repaid/state/loan-repaid.query';
import { LoanRefinanceQuery } from './state/loan-refinance.query';
import { ApplicationService } from '../../../state/application.service';
import { ApplicationQuery } from '../../../state/application.query';
import { PropertyStatus } from '../../../../client-sop/assets-and-liabilities/state/property/property.model';
import { Observable } from 'rxjs';
import { uniqBy, isNil } from 'ramda';

@Component({
	selector: 'app-loan-refinance',
	templateUrl: './loan-refinance.component.html',
})
export class LoanRefinanceComponent implements OnInit, OnDestroy {
	onDestroy$ = new Subject<void>();
	@Input() parentCRTId: number;

	isLoading: boolean;

	// loanRefinance$ = new BehaviorSubject<LoanRefinanceState[]>(null);
	loanRefinance$ = this.query.loanRefinance$;
	isLoading$ = new BehaviorSubject<boolean>(true);
	getOwnerChoices = this.crtMortgageService.getOwnerChoices;
	adviceProcessId = this.moatQuery.getValue().adviceProcessId;
	MP$ = this.crtMortgageService.MP$;
	APCRTF$ = this.crtMortgageService.APCRTF$;
	MLT$ = this.crtMortgageService.MLT$;
	borrowers$ = this.peopleEntitiesService.policyOwnersWithCRT$;
	securities$ = combineLatest([
		this.propertyService.propertyAddresses$,
		this.propertyService.properties$,
	]).pipe(
		map(([propertyAddresses, properties]) => {
			return uniqBy(
				({ customerServiceID }) => customerServiceID,
				[...propertyAddresses, ...properties]
			);
		}),
		map((x) =>
			x?.filter((p) => {
				const ACTIVE = 1;
				// @ts-ignore-next
				const isProperty = !isNil(p?.cRTId) && p?.status === ACTIVE;
				if (isProperty) {
					return true;
				}
				return p?.status === PropertyStatus.Current;
				// p?.status === PropertyStatus.Current
			})
		),
		map((x) =>
			x?.map((a) =>
				ViewDisplayValue.Map(`${a.customerServiceID}`, a.propertyAddress)
			)
		)
	);
	// securities$ = this.propertyService.propertyAddresses$.pipe(
	// 	map((x) => x?.filter((p) => p?.status === PropertyStatus.Current)),
	// 	map((x) =>
	// 		x?.map((a) =>
	// 			ViewDisplayValue.Map(`${a.customerServiceID}`, a.propertyAddress)
	// 		)
	// 	)
	// );

	@Input() deceasedSciList$: Observable<any[]>;

	constructor(
		private service: LoanRefinanceService,
		private crtMortgageService: CrtMortgageService,
		private peopleEntitiesService: PeopleEntitiesService,
		private propertyService: PropertyService,
		private moatQuery: CrtMortgageQuery,
		private query: LoanRefinanceQuery,
		private store: LoanRefinanceService,
		private fundingRequiredLoanQuery: FundingRequiredLoanQuery,
		private appService: ApplicationService,
		private appQuery: ApplicationQuery
	) {}

	ngOnInit(): void {
		this.fundingRequiredLoanQuery.isLoadedLoans$
			.pipe(
				// filter(loan => !!loan),
				concatMap(() => this.service.get(+this.parentCRTId)),
				// filter((x) => !!x),
				tap(() => this.isLoading$.next(false)),
				take(1)
			)
			.subscribe();

		this.appQuery.isUpdatedLoans$
			.pipe(
				filter((loan) => !!loan),
				withLatestFrom(this.fundingRequiredLoanQuery.loans$),
				map(([, loans]) => loans)
			)
			.subscribe((data: any) => {
				const ids = data
					?.filter((loan) => loan.isTicked)
					?.map((loan) => loan.linkedCRTId);
				this.store.updateStore(ids);
				this.appService.setIsUpdatedLoans(false);
			});
	}

	addRefinance = (data: LoanRefinanceState) => {
		return of(data).pipe(
			map((x) =>
				LoanRefinanceMapper.mapToUpsert({
					...x,
					parentCRTId: this.parentCRTId,
					adviceProcessId: this.adviceProcessId,
				})
			),
			switchMap((x) => this.service.add(x)),
			take(1)
		);
	};

	updateRefinance = (data: LoanRefinanceState) => {
		return of(data).pipe(
			map((x) =>
				LoanRefinanceMapper.mapToUpsert({
					...x,
					parentCRTId: this.parentCRTId,
					adviceProcessId: this.adviceProcessId,
				})
			),
			switchMap((x) => this.service.update(x)),
			take(1)
		);
	};

	clearData() {
		this.service.clearData();
	}

	ngOnDestroy(): void {
		this.clearData();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
