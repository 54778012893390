import Service from '../../../shared/models/_general/service';
import { BusinessConfig } from '../../../domain/business-config/business-config.model';

export class ViewBusiness {
	address1: string;
	address2: string;
	businessName: string;
	city: string;
	country: string;
	coverphoto: string;
	description: string;
	domain: string;
	emailAddress: string;
	firstName: string;
	h1color: string;
	h2color: string;
	h3color: string;
	h4color: string;
	h5color: string;
	h6color: string;
	isactive: string;
	lastName: string;
	loginphoto: string;
	logo: string;
	pcolor: string;
	fieldBackgroundColor: string;
	fieldTextColor: string;
	phoneNumber: string;
	position: string;
	postcode: string;
	primarycolor: string;
	secondarycolor: string;
	widgetColor1: string;
	widgetColor2: string;
	soaHeadingColor?: string;

	services: ViewBusinessServices[] = [];
	tertiarycolor: string;
	website: string;

	disclosureDocument: string | number;
	declarationDocument: string | number;
	scopeOfService: string | number;
	letterOfAuthority: string | number;
	businessOverview: string | number;
	businessHeaderLogo: string | number;
	businessFooterLogo: string | number;
	facebookLink: string;
	linkedInLink: string;
	businessPhoneNumber: string;
	businessEmailAddress: string;
	tradingName: string;
	unsubscribeGroupingID: string;
	fAP: string;
	fSPNumber: string;
	businessOATLogo: string;
	businessLOATFactFindCover: string;
	businessLOATSOAAestheticCover: string;
	businessMOATFactFindAestheticCover: string;
	businessMOATROAAestheticCover: string;
	businessKOATAdviceSummaryAestheticCover: string;
	lOATGenericCover: string;
	mOATGenericCover: string;
	kOATGenericCover: string;
	cARGenericCover: string;
	static MapFromModel(
		model: BusinessConfig,
		services: Service[]
	): ViewBusiness {
		return Object.assign(new ViewBusiness(), {
			address1: model.Address1,
			address2: model.Address2,
			businessName: model.BusinessName,
			city: model.City,
			country: model.Country,
			coverphoto: model.CoverPhoto,
			description: model.Description,
			domain: model.Domain,
			emailAddress: model.EmailAddress,
			firstName: model.FirstName,
			h1color: model.H1Color,
			h2color: model.H2Color,
			h3color: model.H3Color,
			h4color: model.H4Color,
			h5color: model.H5Color,
			h6color: model.H6Color,
			fieldBackgroundColor: model.FieldBackgroundColor,
			fieldTextColor: model.FieldTextColor,
			isactive: model.IsActive,
			lastName: model.LastName,
			loginphoto: model.LoginPhoto,
			logo: model.Logo,
			pcolor: model.PColor,
			phoneNumber: model.PhoneNumber,
			position: model.Position,
			postcode: model.Postcode,
			primarycolor: model.PrimaryColor,
			secondarycolor: model.SecondaryColor,
			widgetColor1: model.WidgetColor1,
			widgetColor2: model.WidgetColor2,
			soaHeadingColor: model.SOAHeadingColor,

			services: services.map((x) => {
				const exists = model.Services.some((y) => x.ServiceCode === y);
				return ViewBusinessServices.MapFromModel(
					x.Service,
					x.ServiceCode,
					exists
				);
			}),
			tertiarycolor: model.TertiaryColor,
			website: model.Website,

			disclosureDocument: model.DisclosureDocument,
			declarationDocument: model.DeclarationDocument,
			scopeOfService: model.ScopeOfService,
			letterOfAuthority: model.LetterOfAuthority,
			businessOverview: model.BusinessOverview,
			businessHeaderLogo: model.BusinessHeaderLogo,
			businessFooterLogo: model.BusinessFooterLogo,
			facebookLink: model.FacebookLink,
			linkedInLink: model.LinkedInLink,
			businessPhoneNumber: model.BusinessPhoneNumber,
			businessEmailAddress: model.BusinessEmailAddress,
			tradingName: model.TradingName,
			unsubscribeGroupingID: model.UnsubscribeGroupingID,

			fAP: model.FAP,
			fSPNumber: model.FSPNumber,
			businessOATLogo: model.BusinessOATLogo,

			businessLOATFactFindCover: model.BusinessLOATFactFindCover,
			businessLOATSOAAestheticCover: model.BusinessLOATSOAAestheticCover,
			businessMOATFactFindAestheticCover: model.BusinessMOATFactFindAestheticCover,
			businessMOATROAAestheticCover: model.BusinessMOATROAAestheticCover,
			businessKOATAdviceSummaryAestheticCover: model.BusinessKOATAdviceSummaryAestheticCover,
			lOATGenericCover: model.LOATGenericCover,
			mOATGenericCover: model.MOATGenericCover,
			kOATGenericCover: model.KOATGenericCover,
			cARGenericCover: model.CARGenericCover,
		} as ViewBusiness);
	}

	static MapToModel(vm: ViewBusiness): BusinessConfig {
		return {
			Address1: vm.address1,
			Address2: vm.address2,
			BusinessName: vm.businessName,
			City: vm.city,
			Country: vm.country,
			CoverPhoto: vm.coverphoto,
			Description: vm.description,
			Domain: vm.domain,
			EmailAddress: vm.emailAddress,
			FirstName: vm.firstName,
			H1Color: vm.h1color,
			H2Color: vm.h2color,
			H3Color: vm.h3color,
			H4Color: vm.h4color,
			H5Color: vm.h5color,
			H6Color: vm.h6color,
			FieldBackgroundColor: vm.fieldBackgroundColor,
			FieldTextColor: vm.fieldTextColor,
			IsActive: vm.isactive,
			LastName: vm.lastName,
			LoginPhoto: vm.loginphoto,
			Logo: vm.logo,
			PColor: vm.pcolor,
			PhoneNumber: vm.phoneNumber,
			Position: vm.position,
			Postcode: vm.postcode,
			PrimaryColor: vm.primarycolor,
			SecondaryColor: vm.secondarycolor,
			WidgetColor1: vm.widgetColor1,
			WidgetColor2: vm.widgetColor2,
			SOAHeadingColor: vm.soaHeadingColor,

			Services: vm.services.filter((x) => x.selected).map((x) => x.code),
			TertiaryColor: vm.tertiarycolor,
			Website: vm.website,

			DisclosureDocument: vm.disclosureDocument,
			DeclarationDocument: vm.declarationDocument,
			ScopeOfService: vm.scopeOfService,
			LetterOfAuthority: vm.letterOfAuthority,
			BusinessOverview: vm.businessOverview,
			BusinessHeaderLogo: vm.businessHeaderLogo,
			BusinessFooterLogo: vm.businessFooterLogo,
			FacebookLink: vm.facebookLink,
			LinkedInLink: vm.linkedInLink,
			BusinessPhoneNumber: vm.businessPhoneNumber,
			BusinessEmailAddress: vm.businessEmailAddress,
			TradingName: vm.tradingName,
			UnsubscribeGroupingID: vm.unsubscribeGroupingID,
			FAP: vm.fAP,
			FSPNumber: vm.fSPNumber,
			BusinessOATLogo: vm.businessOATLogo,

			BusinessLOATFactFindCover: vm.businessLOATFactFindCover,
			BusinessLOATSOAAestheticCover: vm.businessLOATSOAAestheticCover,
			BusinessMOATFactFindAestheticCover: vm.businessMOATFactFindAestheticCover,
			BusinessMOATROAAestheticCover: vm.businessMOATROAAestheticCover,
			BusinessKOATAdviceSummaryAestheticCover: vm.businessKOATAdviceSummaryAestheticCover,
			LOATGenericCover: vm.lOATGenericCover,
			MOATGenericCover: vm.mOATGenericCover,
			KOATGenericCover: vm.kOATGenericCover,
			CARGenericCover: vm.cARGenericCover,
		} as BusinessConfig;
	}
}

export class ViewBusinessServices {
	name: string;
	code: string;
	selected: boolean;

	static MapFromModel(
		name: string,
		code: string,
		selected: boolean
	): ViewBusinessServices {
		return Object.assign(new ViewBusinessServices(), {
			name,
			code,
			selected,
		} as ViewBusinessServices);
	}
}
