import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, iif, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, filter, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { convertUtil, objectUtil } from 'src/app/util/util';
import * as R from 'ramda';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { AuthorityToProceedState } from '../state/authority-to-proceed-settings.model';
import { AuthorityToProceedSettingsService } from '../state/authority-to-proceed-settings.service';
import { AuthorityToProceedMapper } from '../state/authority-to-proceed-settings.mapper';

@Component({
  selector: 'app-authority-to-proceed-email-settings',
  templateUrl: './authority-to-proceed-email-settings.component.html'
})
export class AuthorityToProceedEmailSettingsComponent implements OnInit {

  @Input() staffId: number;
  @Input() emailSettings: AuthorityToProceedState;
  @Input() mergeTags$: Observable<MergeTagState[]>;

  authorityToProceed$ = this.apService.authorityToProceed$;
  isLoading = false;
  bodyContent = '<p></p>';
  bodyId: number;
  shortCodes: object;

  constructor(
    private apService: AuthorityToProceedSettingsService
  ) { }

  ngOnInit(): void {
    this.prepData();
  }

  prepData() {
    combineLatest([this.authorityToProceed$, this.mergeTags$])
      .pipe(
        filter(([data, mt]) => !!data && !!mt),
        tap(
          ([data, mt]) =>
            (this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
        ),
        tap(([data, mt]) => (this.bodyId = +data?.body || 0)),

        map(([data, mt]) =>
          AuthorityToProceedMapper.mapEmailSettingsData(data)
        ),
        mergeMap((data) =>
          iif(
            () => R.isNil(data?.body),
            of(null),
            this.apService.getFile(+data.body)
          )
        ),
        map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
        mergeMap((res) =>
          iif(
            () => res?.documentLink,
            this.apService.getDocumentFromURL(res?.documentLink),
            of(null)
          )
        ),
        tap((data) => {
          if (data) {
            this.bodyContent = data;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  save(emailSettings) {
    this.isLoading = true;
    of(emailSettings)
      .pipe(
        mergeMap((currentTemplate) => {
          return convertUtil.convertToBase64(currentTemplate?.bodyContent)
        }),
        withLatestFrom(of(this.bodyId)),
        map(([currentTemplate, bodyId]) =>
          AuthorityToProceedMapper.mapStaffEmailBody(
            currentTemplate,
            bodyId,
            0
          )
        ),
        concatMap((data) =>
          iif(
            () => R.isNil(data.documentId),
            this.apService.newFileUpload(data).pipe(catchError((err) => of(err))),
            this.apService.updateFileUpload(data)
          )
        ),
        withLatestFrom(of(this.bodyId)),
        concatMap(([data, body]) => {
          const isNewBody = body === 0 || !body;
          const newBodyId = isNewBody ? data : body;
          const formValue = AuthorityToProceedMapper.mapEmailSettingsToUpsert({
            ...emailSettings,
            referenceId: 0,
            body: newBodyId,
          });
          this.bodyId = newBodyId;
          return this.apService.updateAuthorityToProceed(formValue);
        }),
        take(1),
        finalize(() => (this.isLoading = false)),

      )
      .subscribe();
  }

}
