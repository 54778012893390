import {
	lsSoaMergeTag,
	mortgageLenderMT,
} from '../../../shared/models/client-review-template/merge-tags/crt-mortgage/structure-soa/structure-soa.merge-tag';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { MergeTagsMapper } from '../../../shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '../../../shared/models/client-review-template/merge-tags/merge-tags.model';
import { MergeTagsCrtSettingsService } from '../merge-tags/state/merge-tags-crt-settings.service';
import { SoaSettingsState } from './state/soa-settings.model';
import { SoaSettingsService } from './state/soa-settings.service';
import { UserQuery } from 'src/app/domain/user/user.query';
import { sosMergeTags } from 'src/app/shared/models/client-review-template/merge-tags/crt-mortgage/scope-of-service/sos.merge-tag';
import { MoatSoaEmailSettingsService } from './moat-soa-email-settings/state/moat-soa-email-settings.service';

@Component({
	selector: 'app-soa-settings',
	templateUrl: './soa-settings.component.html',
})
export class SoaSettingsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	template: string;
	data: SoaSettingsState;
	mergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;

  statementOfAdviceEmailSettings$ = this.moatSoaEmailSettingsService.moatSoaEmailSettings$;

	constructor(
		private soaSettingsService: SoaSettingsService,
		private mtService: MergeTagsCrtSettingsService,
		private moatSoaEmailSettingsService: MoatSoaEmailSettingsService,
		private userQuery: UserQuery
	) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		combineLatest([
			this.soaSettingsService.statementOfAdvice$,
			this.mtService.mergeTags$,
		])
			.pipe(
				filter(([soa, mt]) => !!soa && !!mt),
				tap(([soa]) => {
					this.data = soa;
					this.template = soa?.template;
				}),
				map(([, mt]) =>
					MergeTagsMapper.mapMergeTags(
						[...mt, ...lsSoaMergeTag, ...sosMergeTags, ...mortgageLenderMT],
						true
					)
				),
				tap((mt) => this.mergeTags$.next(mt)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
