import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CrtKiwiSaverRiskProfileOutcome {
	id: number;
	outcome: string;
	score: number;
	status: number;
	isDefault: boolean;
}

export interface CrtKiwiSaverRiskProfileQuestionOutcome {
	referenceId: number;
	type: string;
	cRTSettingsId: number;
	outcomes: CrtKiwiSaverRiskProfileOutcome[];
}

export const CrtKiwisaverRiskProfileOutcomesStoreName =
	'CrtKiwisaver:FactFind - Risk Profile Outcomes';
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: CrtKiwisaverRiskProfileOutcomesStoreName })
export class RiskProfileOutcomesStore extends Store<CrtKiwiSaverRiskProfileQuestionOutcome> {
	constructor() {
		super({});
	}

	set(state: CrtKiwiSaverRiskProfileQuestionOutcome): void {
		this.update(() => state);
		this.setHasCache(true);
	}
}
