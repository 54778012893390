import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { catchError, of } from 'rxjs';
import {
	ManageUsersListFilter,
	ManageUsersSearchResult,
} from './manage-users-list.model';

@Injectable({
	providedIn: 'root',
})
export class ManageUsersListService {
	constructor(private api: ApiService) {}

	get = (filter: ManageUsersListFilter) => {
		return this.api
			.post3<ManageUsersSearchResult>(`search/users`, filter)
			.pipe(catchError(() => of([])));
	};
}
