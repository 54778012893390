import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteService } from '@core/config/route.service';
import { util } from '@core/util/util.service';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import {
	BlStaffPdTypes,
	BlStaffSettingsModel,
} from '@shared/models/_general/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import MomentUtil from '@util/moment.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	catchError,
	concatMap,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { validMomentDate } from '@shared/validator/valid-moment-date/valid-moment-date';
import { util as helperUtil } from '@util/util';

@Component({
	selector: 'app-user-pd-task-modal',
	templateUrl: './user-pd-task-modal.component.html',
	styleUrls: ['./user-pd-task-modal.component.scss'],
})
export class UserPdTaskModalComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	title: string = 'PD Tracking';
	data: BlStaffSettingsModel;
	documentTypeCode: string;
	type: string;
	btnText: string;
	limitSingleDoc: boolean;
	isLoading: boolean;
	staffId: number;
	UPDTP: ViewDisplayValue[] = []; // Provider Dropdown
	UPDTC: ViewDisplayValue[] = []; // Category Dropdown
	UPDTS: ViewDisplayValue[] = []; // Status Dropdown
	defaultStatus: string;
	initializeEdit = true;
	toggleCompletedDate = false;
	enableAllFields = true;

	form: FormGroup;
	documents = [];
	submitted = false;
	viewMode = false;
	isEditMode = true;
	showOtherProvider = false;

	securityGroups$: Observable<any[]>;
	// selectedSecurityGroups$: Observable<any[]>;
	prevSecurityGroups = [];

	users$: Observable<any[]>;
	selectedUsers$: Observable<any[]> = of([]);
	// isSelectAllUsers = false;

	public bsModalRefUpload: BsModalRef;

	upsertFn$: (ac: BlStaffSettingsModel) => Observable<BlStaffSettingsModel>;

	get RequirementName() {
		return this.form.get('requirementName');
	}
	get DueDate() {
		return this.form.get('dueDate');
	}
	get CompletedDate() {
		return this.form.get('completedDate');
	}
	get Provider() {
		return this.form.get('provider');
	}
	get Category() {
		return this.form.get('category');
	}
	get Status() {
		return this.form.get('status');
	}
	get StructuredHours() {
		return this.form.get('structuredHours');
	}
	get Details() {
		return this.form.get('details');
	}
	get OtherProvider() {
		return this.form.get('otherProvider');
	}
	get SecurityGroups() {
		return this.form.get('securityGroups');
	}
	get IsSelectAllSecurityGroups() {
		return this.form.get('selectAllSecurityGroups');
	}
	get Users() {
		return this.form.get('users');
	}
	get IsSelectAllUsers() {
		return this.form.get('selectAllUsers');
	}

	constructor(
		private fb: FormBuilder,
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private crtDocService: CrtDocumentService,
		private routeService: RouteService,
		private router: Router,
		private cd: ChangeDetectorRef,
	) {
		this.form = this.fb.group({
			requirementName: ['', [Validators.required]],
			dueDate: ['', [Validators.required, validMomentDate()]],
			completedDate: [''],
			provider: ['', [Validators.required]],
			otherProvider: [''],
			category: ['', [Validators.required]],
			status: ['', [Validators.required]],
			structuredHours: ['', [Validators.required]],
			details: [''],
			securityGroups: ['', [Validators.required]],
			selectAllSecurityGroups: [false],
			users: ['', [Validators.required]],
			selectAllUsers: [false],
		});
	}

	ngOnInit(): void {
		this.selectedUsers$ = this.users$;

		if (!!this.data) {
			const isOtherProvider = this.isOtherProvider(this.data?.Provider);
			this.showOtherProvider = isOtherProvider;
			this.form.patchValue({
				requirementName: this.data?.RequirementName,
				dueDate: this.formatValidDate(this.data?.DueDate),
				completedDate: this.formatValidDate(this.data?.CompletedDate),
				provider: this.data?.Provider || '',
				otherProvider: this.data?.OtherProvider || '',
				category: this.data?.Category,
				status: this.data?.Status,
				structuredHours: this.data?.StructuredHours,
				details: this.data?.Details,
				users: this.data?.Users,
				securityGroups: this.data?.SecurityGroups,
			});

			this.prepopulateUsers(
				this.data?.SecurityGroups,
				this.data?.Users
			).pipe(
				take(1),
			).subscribe();

			this.checkIfSecuritySelectedAll();
		} else {
			this.preselectStatus();
		}

		this.setValidators();
		this.setEnabledFields();
		this.onChangeStatus();

		this.SecurityGroups.valueChanges
			.pipe(withLatestFrom(this.users$), takeUntil(this.onDestroy$))
			.subscribe(([securityGroups, users]) => {
				const newSecurityGroups = this.convertToArray(securityGroups);
				this.populateUsers(
					newSecurityGroups,
					users,
					newSecurityGroups.length > this.prevSecurityGroups.length
				);

				this.checkIfSecuritySelectedAll();

				const usersValue = this.Users.value;
				this.checkIfUsersSelectedAll(
					(Array.isArray(usersValue)
						? usersValue
						: helperUtil.tryParseJson(usersValue)) || []
				);

				const currentUsers = this.convertToArray(this.Users.value);
				if (
					(newSecurityGroups || []).length === 0 &&
					currentUsers.length === 0
				) {
					this.selectedUsers$ = this.users$;
				}

				this.cd.detectChanges();
			});

		this.Users.valueChanges
			.pipe(
				map(
					(users) =>
						(Array.isArray(users) ? users : helperUtil.tryParseJson(users)) ||
						[]
				),
				mergeMap((ids) => {
					return this.mapIdsToUsers(ids);
				}),
				withLatestFrom(this.securityGroups$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([users, securityGroups]) => {
				this.populateSecurityGroups(users);
				this.checkIfUsersSelectedAll(users);

				// if (users.length > 0) {
				// 	this.populateSecurityGroups(users);
				// 	this.checkIfUsersSelectedAll(users);
				// } else {
				// 	const currentSG = this.convertToArray(this.SecurityGroups.value);
				// 	if (currentSG.length === 0) {
				// 		this.selectedUsers$ = this.users$;
				// 	}
				// }

				this.cd.detectChanges();
			});
	}

	preselectStatus() {
		if (!!this.defaultStatus) {
			this.Status.setValue(this.defaultStatus);
		}

		const defaultCategory = this.UPDTC?.find((item) => item.isDefault);
		if (!!defaultCategory) {
			this.Category.setValue(defaultCategory?.value);
		}

		const defaultProvider = this.UPDTP?.find((item) => item.isDefault);
		if (!!defaultProvider) {
			this.Provider.setValue(defaultProvider?.value);
		}
	}

	setEnabledFields() {
		if (!!this.initializeEdit) {
			this.enableFields();
		} else {
			this.isEditMode = false;
			this.form.disable();
		}
	}

	setValidators() {
		if (!!this.toggleCompletedDate) {
			this.CompletedDate.clearValidators();
		} else {
			this.CompletedDate.setValidators([
				Validators.required,
				validMomentDate(),
			]);
		}
		this.CompletedDate.updateValueAndValidity();
	}

	formatValidDate(value) {
		return util.isValidMoment(value)
			? value
			: MomentUtil.formatDateToMoment(value);
	}

	isOtherProvider(provider: string) {
		if (!provider) {
			return false;
		}
		if (provider === 'Other') {
			return true;
		}
		return !this.UPDTP?.find((x) => x?.value === provider);
	}

	enableFields() {
		if (!this.data || this.enableAllFields) {
			this.form.enable();
		} else {
			this.RequirementName.disable();
			this.DueDate.disable();
			this.Provider.disable();
			this.OtherProvider.disable();
			this.Category.disable();
			this.Details.disable();
			this.Status.enable();
			this.StructuredHours.enable();
			this.CompletedDate.enable();
			this.SecurityGroups.enable();
			this.Users.enable();
		}
	}

	save() {
		this.submitted = true;
		if (
			this.form.invalid ||
			(this.Provider.value === 'Other' && !this.OtherProvider.value?.trim())
		) {
			return;
		}
		this.isLoading = true;

		of(this.form.getRawValue())
			.pipe(
				map((formValue) => {
					return {
						RequirementName: formValue?.requirementName || '',
						DueDate: this.formatValidDate(formValue?.dueDate),
						CompletedDate: this.formatValidDate(formValue?.completedDate),
						Documents: this.documents?.filter((d) => !!d?.ReferenceId) || [],
						Provider: formValue?.provider || '',
						OtherProvider: formValue?.otherProvider || '',
						Category: formValue?.category || '',
						Status: formValue?.status || '',
						StructuredHours: !!formValue?.structuredHours
							? +formValue?.structuredHours
							: null,
						Details: formValue?.details || '',
						StaffId: this.staffId,
						StaffSettingsId: this.data?.StaffSettingsId,
						DocumentType: this.documentTypeCode,
						Type: this.type,
						SettingsCode: this.type,
						SecurityGroups:
							(Array.isArray(formValue?.securityGroups)
								? formValue?.securityGroups
								: helperUtil.tryParseJson(formValue?.securityGroups)) || [],
						Users:
							(Array.isArray(formValue?.users)
								? formValue?.users
								: helperUtil.tryParseJson(formValue?.users)) || [],
					} as BlStaffSettingsModel;
				}),
				tap(() => (this.isLoading = true)),
				concatMap(this.upsertFn$),
				catchError((error) => {
					if (error) {
						this.isLoading = false;
					}
					throw error;
				}),
				tap(() => {
					this.isLoading = false;
					this.close();
				}),
				take(1)
			)
			.subscribe();
	}

	allowEdit() {
		this.isEditMode = true;
		this.enableFields();
	}

	onChangeOther(event: Event) {
		this.OtherProvider.setValue('');
		this.showOtherProvider = this.isOtherProvider(this.Provider.value);
	}

	onChangeOtherTextbox(event: Event | any) {
		if (event?.code === 'CapsLock') {
			event?.preventDefault();
			return;
		}

		if (!(event.target as HTMLSelectElement).value) {
			this.Provider.setValue('');
		}

		this.showOtherProvider = this.isOtherProvider(this.Provider.value);
	}

	onChangeStatus() {
		if (this.Status.value === BlStaffPdTypes.Completed) {
			this.CompletedDate.setValidators([
				Validators.required,
				validMomentDate(),
			]);
		} else {
			this.CompletedDate.clearValidators();
		}
		if (!!this.toggleCompletedDate) {
			this.CompletedDate.setValue('');
		}
		this.CompletedDate.updateValueAndValidity();
	}

	selectAllSecurityGroups() {
		this.IsSelectAllSecurityGroups.setValue(
			!this.IsSelectAllSecurityGroups.value
		);

		this.securityGroups$.pipe(take(1)).subscribe((data) => {
			const secGroups = data.map((sg) => sg.value);

			if (this.IsSelectAllSecurityGroups.value) {
				this.SecurityGroups.setValue(secGroups);
				this.IsSelectAllSecurityGroups.setValue(true);
			} else {
				this.SecurityGroups.setValue([]);
				this.IsSelectAllSecurityGroups.setValue(false);
			}
		});
	}

	getUsersBySecurityGroups(securityGroups: string[]) {
		return this.users$.pipe(
			map((users) =>
				users.filter((user) => securityGroups.includes(user.securityGroup))
			)
		);
	}

	mapIdsToUsers(ids: number[]) {
		return this.users$.pipe(
			map((users) => {
				return (ids || []).map((id) => {
					return users.find((user) => user?.value === +id);
				});
			}),
			take(1)
		);
	}

	prepopulateUsers(securityGroups: string[], ids: number[]) {
		return this.getUsersBySecurityGroups(securityGroups).pipe(
			tap((users) => {
				this.populateUsers(securityGroups, users);
			}),
			tap(() => {
				this.checkIfUsersSelectedAll(ids);
			})
		);
	}

	compareArrays(a: any[], b: any[]) {
		const sortA = a.sort();
		const sortB = b.sort();

		return sortA.toString() === sortB.toString();
	}

	populateSecurityGroups(users: any) {
		const userSecurityGroups = [
			...new Set(users.map((user) => user.securityGroup)),
		];

		const currentSecurityGroups = this.convertToArray(
			this.SecurityGroups.value
		);

		if (this.compareArrays(userSecurityGroups, currentSecurityGroups)) {
			return;
		}

		this.SecurityGroups.setValue(userSecurityGroups);
		this.cd.detectChanges();
	}

	populateUsers(securityGroups: any, users: any, isAdditional?: boolean) {
		const filteredUsers = users.filter((user) =>
			securityGroups.includes(user.securityGroup)
		);

		this.selectedUsers$ = of(filteredUsers);

		const currentUsers = this.convertToArray(this.Users.value).map((id) => +id);

		if (
			currentUsers.every(
				(id) => !!filteredUsers.find((user) => `${user.value}` === id)
			)
		) {
			return;
		}

		if (!isAdditional) {
			const newUsers = currentUsers.filter((id) =>
				filteredUsers.find((user) => user.value === id)
			);
			this.Users.setValue(newUsers);
		}

		this.prevSecurityGroups = securityGroups;
	}

	convertToArray(value: any) {
		return (
			(Array.isArray(value) ? value : helperUtil.tryParseJson(value)) || []
		);
	}

	selectAllUsers() {
		this.IsSelectAllUsers.setValue(!this.IsSelectAllUsers.value);

		this.selectedUsers$.pipe(take(1)).subscribe((data) => {
			if (this.IsSelectAllUsers.value) {
				const ids = data.map((user) => user.value);
				this.Users.setValue(ids);
				this.IsSelectAllUsers.setValue(true);
			} else {
				this.Users.setValue([]);
				this.IsSelectAllUsers.setValue(false);
			}
		});
	}

	checkIfSecuritySelectedAll() {
		this.securityGroups$.pipe(take(1)).subscribe((data) => {
			const value = this.convertToArray(this.SecurityGroups.value);

			if (data.length === value.length) {
				this.IsSelectAllSecurityGroups.setValue(true);
			} else {
				this.IsSelectAllSecurityGroups.setValue(false);
			}
		});
	}

	checkIfUsersSelectedAll(current: any[]) {
		this.selectedUsers$.pipe(take(1)).subscribe((data) => {
			if (data.length === current.length) {
				this.IsSelectAllUsers.setValue(true);
			} else {
				this.IsSelectAllUsers.setValue(false);
			}

			if (data.length === 0 && current.length === 0) {
				this.IsSelectAllUsers.setValue(false);
			}
		});
	}

	close() {
		this.bsModalRef.hide();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
