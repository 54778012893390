import { util } from '../../../core/util/util.service';
import { TlStaffModel, TlStaffPostModel, TlStaffPutModel } from '../../../shared/models/_general/tl-staff.model';
import { Moment } from 'moment';

export class TlStaffViewModel {
	public StaffID: number;
	public FirstName: string;
	public LastName: string;
	public IsActive: number;
	public EmailAddress: string;
	public SecurityGroup: string;
	public StartDate: Moment;
	public BirthDate: Moment;
	public MobilePhone: string;
	public BusinessPhone: string;
	public HomeAddress: string;
	public Businesses: string[];
	public StaffSettings: any;

	constructor() {
		this.Businesses = [];
	}

	static MapToViewModel(m: TlStaffModel): TlStaffViewModel {
		return {
			StaffID: m.StaffID,
			FirstName: m.FirstName,
			LastName: m.LastName,
			IsActive: m.IsActive,
			EmailAddress: m.EmailAddress,
			SecurityGroup: m.SecurityGroup,
			StartDate: util.DateStringToMomentLoose(m.StartDate),
			BirthDate: util.DateStringToMomentLoose(m.BirthDate),
			MobilePhone: m.MobilePhone,
			BusinessPhone: m.BusinessPhone,
			HomeAddress: m.HomeAddress,
			Businesses: JSON.parse(m.Businesses),
			StaffSettings: {
				...m.StaffSettings,
			}
		} as TlStaffViewModel;
	}

	static MapToModel(v: TlStaffViewModel): TlStaffModel {
		return {
			StaffID: v.StaffID,
			FirstName: v.FirstName,
			LastName: v.LastName,
			IsActive: v.IsActive,
			EmailAddress: v.EmailAddress,
			SecurityGroup: v.SecurityGroup,
			StartDate: util.MomentToDateString(v.StartDate),
			BirthDate: util.MomentToDateString(v.BirthDate),
			MobilePhone: v.MobilePhone,
			BusinessPhone: v.BusinessPhone,
			HomeAddress: v.HomeAddress,
			Businesses: JSON.stringify(v.Businesses),
			StaffSettings: v.StaffSettings
		} as TlStaffModel;
	}

	static mapToTlAddRequest(v: TlStaffViewModel): TlStaffPostModel {
		return {
			BirthDate: util.MomentToDateString(v.BirthDate),
			StartDate: util.MomentToDateString(v.StartDate),
			BusinessPhone: v.BusinessPhone,
			Businesses: v.Businesses ? JSON.stringify(v.Businesses) : null,
			EmailAddress: v.EmailAddress,
			FirstName: v.FirstName,
			LastName: v.LastName,
			HomeAddress: v.HomeAddress,
			IsActive: isNaN(v.IsActive) ? v.IsActive : +v.IsActive,
			MobilePhone: v.MobilePhone,
			SecurityGroup: v.SecurityGroup,
			StaffSettings: v.StaffSettings,
		};
	}
	static mapToTlEditRequest(v: TlStaffViewModel): TlStaffPutModel {
		return {
			BirthDate: util.MomentToDateString(v.BirthDate),
			StartDate: util.MomentToDateString(v.StartDate),
			BusinessPhone: v.BusinessPhone,
			Businesses: v.Businesses ? JSON.stringify(v.Businesses) : null,
			EmailAddress: v.EmailAddress,
			FirstName: v.FirstName,
			LastName: v.LastName,
			HomeAddress: v.HomeAddress,
			IsActive: isNaN(v.IsActive) ? v.IsActive : +v.IsActive,
			MobilePhone: v.MobilePhone,
			SecurityGroup: v.SecurityGroup,
			StaffID: v.StaffID,
			StaffSettings: v.StaffSettings,
		};
	}
}
