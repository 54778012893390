import { TableColumn } from '@swimlane/ngx-datatable';
import { BusinessPageModel } from './state/business-page.model';
import { ViewDisplayValue } from '../../shared/models/_general/display-value.viewmodel';
import { datatableUtil } from 'src/app/util/datatable.util';

export type Metakey =
	| 'Business Name'
	| 'Contact Person'
	| 'L&R Insurance'
	| 'Mortgage'
	| 'F&G Insurance'
	| 'KiwiSaver'
	| 'Website'
	| 'Domain'
	| 'Phone Number'
	| 'Total Inforce API Current Calendar Year'
	| 'Total Inforce API Current Month'
	| 'PL Current Month'
	| 'AIA Current Month'
	| 'Fidelity Life Current Month'
	| 'Asteron Current Month'
	| 'Cigna Life Current Month'
	| 'Blanket Current Month'
	| '# of Clients'
	| '# of Advisers'
	| 'NIB Current Month';

export type controlType = 'display' | 'money' | 'number';
/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */

type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof BusinessPageModel;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (field: any, choices?: ViewDisplayValue[]) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
	columnId?: string;
	fieldId?: string;
};

export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'Business Name',
		prop: 'BusinessName',
		name: 'Business Name',
		width: 240,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold fixed-column',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Business Name'),
		fieldId: datatableUtil.formatFieldId('Business Name')
	},
	{
		metakey: 'Contact Person',
		prop: 'ContactPerson',
		name: 'Contact Person',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Contact Person'),
		fieldId: datatableUtil.formatFieldId('Contact Person')
	},
	{
		metakey: 'L&R Insurance',
		prop: 'LR',
		name: 'L&R Insurance',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('L&R Insurance'),
		fieldId: datatableUtil.formatFieldId('L&R Insurance')
	},
	{
		metakey: 'Mortgage',
		prop: 'Mortgage',
		name: 'Mortgage',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Mortgage'),
		fieldId: datatableUtil.formatFieldId('Mortgage')
	},
	{
		metakey: 'F&G Insurance',
		prop: 'FG',
		name: 'F&G Insurance',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('F&G Insurance'),
		fieldId: datatableUtil.formatFieldId('F&G Insurance')
	},
	{
		metakey: 'KiwiSaver',
		prop: 'KiwiSaver',
		name: 'KiwiSaver',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('KiwiSaver'),
		fieldId: datatableUtil.formatFieldId('KiwiSaver')
	},
	{
		metakey: 'Website',
		prop: 'Website',
		name: 'Website',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Website'),
		fieldId: datatableUtil.formatFieldId('Website')
	},
	{
		metakey: 'Domain',
		prop: 'Domain',
		name: 'Domain',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Domain'),
		fieldId: datatableUtil.formatFieldId('Domain')
	},
	{
		metakey: 'Phone Number',
		prop: 'PhoneNumber',
		name: 'Phone Number',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Phone Number'),
		fieldId: datatableUtil.formatFieldId('Phone Number')
	},

	{
		metakey: 'Total Inforce API Current Calendar Year',
		prop: 'TotalCurrentYear',
		name: 'API - Current Year',
		width: 335,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('TotalCurrentYear'),
		fieldId: datatableUtil.formatFieldId('TotalCurrentYear')
	},
	{
		metakey: 'Total Inforce API Current Month',
		prop: 'TotalCurrentMonth',
		name: 'API - Current Month',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('TotalCurrentMonth'),
		fieldId: datatableUtil.formatFieldId('TotalCurrentMonth')
	},
	{
		metakey: 'PL Current Month',
		prop: 'PLCurrentMonth',
		name: 'PL Current Month',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('PL Current Month'),
		fieldId: datatableUtil.formatFieldId('PL Current Month')
	},
	{
		metakey: 'AIA Current Month',
		prop: 'AIACurrentMonth',
		name: 'AIA Current Month',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('AIA Current Month'),
		fieldId: datatableUtil.formatFieldId('AIA Current Month')
	},
	{
		metakey: 'Fidelity Life Current Month',
		prop: 'FidelityLifeCurrentMonth',
		name: 'Fidelity Life Current Month',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('Fidelity Life Current Month'),
		fieldId: datatableUtil.formatFieldId('Fidelity Life Current Month')
	},
	{
		metakey: 'Asteron Current Month',
		prop: 'AsteronCurrentMonth',
		name: 'Asteron Current Month',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('Asteron Current Month'),
		fieldId: datatableUtil.formatFieldId('Asteron Current Month')
	},
	{
		metakey: 'Cigna Life Current Month',
		prop: 'CignaLifeCurrentMonth',
		name: 'Cigna Life Current Month',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('Cigna Life Current Month'),
		fieldId: datatableUtil.formatFieldId('Cigna Life Current Month')
	},
	{
		metakey: 'Blanket Current Month',
		prop: 'BlanketCurrentMonth',
		name: 'Blanket Current Month',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('Blanket Current Month'),
		fieldId: datatableUtil.formatFieldId('Blanket Current Month')
	},
	{
		metakey: '# of Clients',
		prop: 'NoOfClients',
		name: '# of Clients',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'number',
		columnId: datatableUtil.formatColumnId('NoOfClients'),
		fieldId: datatableUtil.formatFieldId('NoOfClients')
	},
	{
		metakey: '# of Advisers',
		prop: 'NoOfAdvisers',
		name: '# of Advisers',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'number',
		columnId: datatableUtil.formatColumnId('NoOfAdvisers'),
		fieldId: datatableUtil.formatFieldId('NoOfAdvisers')
	},
	{
		metakey: 'NIB Current Month',
		prop: 'NIBCurrentMonth',
		name: 'NIB Current Month',
		width: 240,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('NIB Current Month'),
		fieldId: datatableUtil.formatFieldId('NIB Current Month')
	},
];

/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 119,
};
