<div class="col p-2 pt-4">
	<!-- <div class="col mb-4">
		<div class="row">
			<div class="header">
				<h1>Providers</h1>
			</div>
		</div>
	</div> -->
	<div class="w-100 mb-4">
		<app-provider-form
			[providers]="(providersData && providersData.providers) || []"
			(saveEvent)="save($event)"
			[loading]="isLoading"
			[providerList]="providers$ | async"
		>
		</app-provider-form>
	</div>
</div>
