import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { DropdownValueService } from 'src/app/domain/dropdown-value/dropdown-value.service';
import { DisclosureSettingsService } from '../../service/disclosure-settings.service';
import { MergeTagsService } from '../../service/merge-tags.service';
import { ProviderCommissionService } from '../../service/provider-commission.service';
import { StaffSettingsService } from '@modules/user/service/staff-settings.service';
import { PDGoalsSettingsService } from '@modules/pd-goals/state/pd-goals.service';
const dropdownCodes: string[] = [
	'LRP',
	'SCS',
	'SCT',
	'SAS',
	'SQCKI',
	'UPDTP',
	'UPDTC',
	'UPDTS',
];

@Injectable()
export class UserSettingsResolver implements Resolve<boolean> {
	constructor(
		private ssService: StaffSettingsService,
		private dService: DisclosureSettingsService,
		private pcService: ProviderCommissionService,
		private mtService: MergeTagsService,
		private dropdownValueService: DropdownValueService,
		private pdService: PDGoalsSettingsService,
	) {}

	/**
	 * Syncing for Provider & Commission
	 * This will sync first then fetch the latest
	 * list of L&R/Mortgage/FG/KiwiSaver Provider & Commission
	 */
	triggerSync(staffId: number) {
		const getPCLR$ = this.pcService.syncProviderCommission(staffId, 'PCLR');
		const getPCM$ = this.pcService.syncProviderCommission(staffId, 'PCM');
		const getPCFG$ = this.pcService.syncProviderCommission(staffId, 'PCFG');
		const getPCK$ = this.pcService.syncProviderCommission(staffId, 'PCK');

		return of([getPCLR$, getPCM$, getPCFG$, getPCK$]);
	}

	resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
		const dropdowns$ =
			this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		this.pcService.clear();
		this.dService.clear();

		const staffId = +route.paramMap.get('id');

		const getStaffSettings$ = this.ssService.getBlStaffSettings(staffId);

		// Merge Tags
		const getMtGeneral$ = this.mtService.getMergeTags(0, 'general', 0);
		const getMtBusiness$ = this.mtService.getMergeTags(1, 'business', 0);
		const getMTStaff$ = this.mtService.getMergeTags(2, 'staff', +staffId);

		// Disclosure Document
		const getDDS$ = this.dService.getDisclosureDocumentByStaffId(staffId);

		// Personal Development
		const getPD$ = this.pdService.get(0);

		// PDT Widgets
		const getPDT$ = this.ssService.getPDTrackingWidgets(staffId);

		return forkJoin([
			getStaffSettings$,
			dropdowns$,
			getMtGeneral$,
			getMtBusiness$,
			getMTStaff$,
			getDDS$,
			getPD$,
			getPDT$,
		]).pipe(mapTo(true));
	}
}
