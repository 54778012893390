import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../../core/base/api.service';
import { PurposeStore } from '../state/purpose.store';
import { PurposeQuery } from '../state/purpose.query';
import { PurposeDetailsState } from './purpose.model';
import { objectUtil } from '../../../../../../../../util/util';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CrtMortgageQuery } from '../../../../state/crt-mortgage.query';
import { AdviceProcessSectionCodes } from '../../../../../../../../shared/models/advice-process/advice-process.model';
import { applyTransaction } from '@datorama/akita';

@Injectable()
export class PurposeService {
	purposes$ = this.query.purpose$;
	purposes = this.query.getAll();
	isLoading$ = this.query.selectLoading();

	constructor(
		private api: ApiService,
		protected store: PurposeStore,
		protected query: PurposeQuery,
		protected crtMortgageQuery: CrtMortgageQuery
	) {}

	clearData() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	get(parentCRTId: number) {
		this.store.setLoading(true);
		return this.api
			.get<PurposeDetailsState[]>(
				`crt/MA/${parentCRTId}/sub-section/${AdviceProcessSectionCodes.Purpose}`
			)
			.pipe(
				map((x) => (x || [])?.map(objectUtil.mapPascalCaseToCamelCase)),
				tap((x) => this.store.set(x || [])),
				finalize(() => this.store.setLoading(false)),
				catchError(() => of(undefined))
			);
	}

	add(data: PurposeDetailsState, parentCRTId: number) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.Purpose,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
			ParentCRTId: parentCRTId,
			CRTId: null,
		};
		return this.api.post(`crt`, body).pipe(
			concatMap((x) => this.api.get(`crt/${x}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.add(x)),
			catchError(() => of(undefined))
		);
	}

	update(data: PurposeDetailsState, parentCRTId: number) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.Purpose,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
			ParentCRTId: parentCRTId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			concatMap(() => this.api.get(`crt/${body.CRTId}`)),
			map(objectUtil.mapPascalCaseToCamelCase),
			tap((x) => this.store.upsert(x?.parentCRTId, x)),
			catchError(() => of(undefined))
		);
	}

	delete(data: PurposeDetailsState) {
		const body = {
			...objectUtil.mapCamelCaseToPascalCase(data),
			SectionCode: AdviceProcessSectionCodes.Purpose,
			AdviceProcessId: this.crtMortgageQuery.getValue().adviceProcessId,
		};
		return this.api.put(`crt/${body.CRTId}`, body).pipe(
			tap((x) => this.store.remove(body.parentCRTId)),
			catchError(() => of(undefined))
		);
	}
}
