import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, Subject, combineLatest } from 'rxjs';
import { finalize, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { DropdownValue } from 'src/app/domain/dropdown-value/dropdown-value.model';
import { AssetsLiabilitiesModalComponent } from 'src/app/shared/modal/crt/fact-find/assets-liabilities/assets-liabilities.modal.component';
import { DeleteModalComponent } from 'src/app/shared/modal/delete-modal/delete-modal.component';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { LiabilityMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/liability/liability.mapper';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { LiabilityState } from '../../../crt-page/crt-mortgage/client-sop/assets-and-liabilities/state/liability/liability.store';
import { AssetsLiabilitiesService } from '../../states/assets-liabilities/assets-liabilities.service';
import { ClientReviewTemplateQuery } from '../../states/client-review-template.query';
import { isNil } from 'ramda';
import { LiabilityInfo } from '@shared/models/client-review-template/assets-liabilities/liability/liability.model';

declare var $: any;

@Component({
	selector: 'app-liabilities',
	templateUrl: './liabilities.component.html',
})
export class LiabilitiesComponent implements OnInit {
	onDestroy$ = new Subject<void>();

	@Input() isAdviceProcessEnded: boolean;

	@Input() dropdownCodes: DropdownValue[];

	crmLiabilities$ = this.query.crmLiabilities$.pipe(
		// Use the filter operator to exclude items with Lender === 'Other'
		map(liabilities => { 
			if (!isNil(liabilities)) {
				return liabilities.filter(liability => liability.Status.toString() !== 'Discharged');
			}

			return [];
		}),
	);

	crmLiabilitiesChoices$: Observable<ViewDisplayValue<LiabilityInfo>[]>;
	crmLiabilitiesChoices: ViewDisplayValue<LiabilityInfo>[] = [];
	selectedLiability: LiabilityInfo;

	isAddNew = false;
	isAdding = false;

	form: UntypedFormGroup;
	bsModalRef: BsModalRef;
	elseMinusLiabilities = true;
	isEditing = false;
	editIndex: number;

	liabilities$ = this.service.liabilities$;
	adviceProcessId = this.query.getValue().adviceProcessId;
	securities$ = this.query.assets$.pipe(
		map((x) => x?.map((a) => ViewDisplayValue.Map(`${a.cRTId}`, `${a.asset}`)))
	);

	isLoading$ = this.query.isLiabilitiesLoading$;

	constructor(
		private modalService: BsModalService,
		private fb: UntypedFormBuilder,
		private service: AssetsLiabilitiesService,
		private query: ClientReviewTemplateQuery
	) {
		this.buildFrom();

		this.crmLiabilitiesChoices$ = (combineLatest([this.liabilities$, this.crmLiabilities$])
		.pipe(
			map(([liabilities, crmLiabilities]) => {
				// Convert the cRTId values from the liabilities
				if (!isNil(liabilities) && !isNil(crmLiabilities)) {
					const cRTIds = liabilities.map(item => item.customerServiceId);
					// Filter the crmLiabilities based on the condition
					const filteredCrmLiabilities = crmLiabilities.filter(item => !cRTIds.includes(item.CustomerServiceID));
					return filteredCrmLiabilities;
				}
				
				return [];
			})
		)).pipe(
			map(liabilities => {
					return liabilities
					.map((liability: any) => {
							const dropdownValue = ViewDisplayValue.Map<LiabilityInfo>(
								`${liability.CustomerServiceID}`,
								`${liability.Liability} - ${liability.Lender}`
							);
							dropdownValue.data = liability;
							return dropdownValue;
						}
					)
				}
			),
			takeUntil(this.onDestroy$)
		);
	}

	ngOnInit(): void {
		this.crmLiabilitiesChoices$.pipe(
			takeUntil(this.onDestroy$)
		).subscribe(liabilities => {
			this.crmLiabilitiesChoices = liabilities;
		});
	}

	buildFrom() {
		this.form = this.fb.group({});
	}

	collapseMoreLiabilities() {
		$('#collapseLiabilities').toggle();
		this.elseMinusLiabilities = false;
	}

	collapseLessLiabilities() {
		$('#collapseLiabilities').toggle();
		this.elseMinusLiabilities = true;
	}

	saveNew$ = (model: LiabilityState) =>
		new Observable((obs: Observer<LiabilityState>) => {
			this.isAddNew = false;
			obs.next(model);
			obs.complete();
			this.selectedLiability = undefined;
		}).pipe(
			map((x) => LiabilityMapper.mapToDetails(x, this.adviceProcessId)),
			mergeMap((x) =>
				this.service.addAL(x, AdviceProcessSectionCodes.Liabilities)
			)
		);

	updateFn$ = (model: LiabilityState) =>
		new Observable((obs: Observer<LiabilityState>) => {
			this.isAddNew = false;
			obs.next(model);
			obs.complete();
		}).pipe(
			map((x) => LiabilityMapper.mapToDetails(x, this.adviceProcessId)),
			mergeMap((x) =>
				this.service.updateAL(x, AdviceProcessSectionCodes.Liabilities)
			)
		);

	editLiabilities(crtId: number, index: number) {
		this.isEditing = true;
		this.editIndex = index;
		const decline = new Observable((obs: Observer<JSON>) => {
			this.isAddNew = false;
			obs.complete();
		});
		this.service
			.getCrtById(crtId)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.editIndex = undefined;
				}),
				take(1)
			)
			.subscribe((x) => {
				const initState = {
					header: 'Liabilities Details',
					message: `Liabilities`,
					borrowers: this.query.policyOwnersWithCRT$,
					getOwnerChoices: this.service.getOwnerChoices,
					securities: this.securities$,
					mp$: this.service.MP$,
					mlt$: this.service.MLT$,
					apcrtf$: this.service.APCRTF$,
					apcrtyn$: this.service.APCRTYN$,
					apcrtynna$: this.service.APCRTYNNA$,
					apcrtl$: this.service.APCRTL$,
					crtInfo: x,
					saveFn$: this.updateFn$,
					decline$: decline,
				};
				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	viewLiabilities(crtId: number, index: number) {
		this.isEditing = true;
		this.editIndex = index;
		const decline = new Observable((obs: Observer<JSON>) => {
			obs.complete();
		});
		this.service
			.getCrtById(crtId)
			.pipe(
				finalize(() => {
					this.isEditing = false;
					this.editIndex = undefined;
				}),
				take(1)
			)
			.subscribe((x) => {
				const initState = {
					header: 'Liabilities Details',
					message: `Liabilities`,
					borrowers: this.query.policyOwnersWithCRT$,
					getOwnerChoices: this.service.getOwnerChoices,
					securities: this.securities$,
					mp$: this.service.MP$,
					mlt$: this.service.MLT$,
					apcrtf$: this.service.APCRTF$,
					apcrtyn$: this.service.APCRTYN$,
					apcrtynna$: this.service.APCRTYNNA$,
					apcrtl$: this.service.APCRTL$,
					crtInfo: x,
					decline$: decline,
					viewMode: true
				};
				this.bsModalRef = this.modalService.show(
					AssetsLiabilitiesModalComponent,
					{
						class: 'modal-dialog-centered modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					}
				);
			});
	}

	deleteLiabilities(cRTId: number) {
		this.service
			.deleteAL(cRTId, AdviceProcessSectionCodes.Liabilities)
			.subscribe(()=> {
				this.isAddNew = false;
				this.selectedLiability = undefined;
			});
	}

	deleteModal(id: number) {
		const confirm = new Observable((obs: Observer<JSON>) => {
			this.deleteLiabilities(id);
			obs.complete();
		});
		const initState = {
			header: 'Delete Liability',
			message: `Are you sure you want to delete this?`,
			delete$: confirm,
			canDelete: true,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	addLiabilities() {
		if (this.crmLiabilitiesChoices.length !== 0) {
			this.isAddNew = true;
		} else {
			this.onSelectLiability('new');
		}
	}

	onSelectLiability(e: string) {
		if (e === 'new') {
			const decline = new Observable((obs: Observer<JSON>) => {
				this.isAddNew = false;
				this.selectedLiability = undefined;
				obs.complete()
			});
			const initState = {
				header: 'Liabilities Details',
				message: `Liabilities`,
				borrowers: this.query.policyOwnersWithCRT$,
				getOwnerChoices: this.service.getOwnerChoices,
				securities: this.securities$,
				mp$: this.service.MP$,
				mlt$: this.service.MLT$,
				apcrtf$: this.service.APCRTF$,
				apcrtyn$: this.service.APCRTYN$,
				apcrtynna$: this.service.APCRTYNNA$,
				apcrtl$: this.service.APCRTL$,
				saveFn$: this.saveNew$,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(AssetsLiabilitiesModalComponent, {
				class: 'modal-dialog-centered modal-lg modal-workflow',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.isAddNew = true;
			const newlySelectedLiability = this.crmLiabilitiesChoices.find(liabilities => liabilities.data.CustomerServiceID === +e);
			this.selectedLiability = newlySelectedLiability.data;
		}
	}

	saveLiability(): void {
		this.isAdding = true;
		
		const liabilityData = {
			AdviceProcessId: this.adviceProcessId,
			SectionCode: 'FAL',
			...this.selectedLiability,
			Borrower: !!this.selectedLiability?.Borrower
				? typeof this.selectedLiability.Borrower === 'string'
					? this.selectedLiability?.Borrower
					: JSON.stringify(this.selectedLiability?.Borrower)
				: null,
			MortgageFrequency: this.selectedLiability.RepaymentFrequency,
			PaidInFull: this.selectedLiability.PaidInFull,
			Security: this.selectedLiability.Security,
			CRTId: this.selectedLiability?.CRTId ?? null,
			Status: 1,
			PendingAdviserApproval: this.selectedLiability?.PendingAdviserApproval ?? false,
		}
		this.service.addAL(liabilityData, AdviceProcessSectionCodes.Liabilities)
			.pipe(
				finalize(() => {
					this.isAdding = false;
					this.isAddNew = false;
					this.selectedLiability = undefined;
				}),
				take(1)
			)
			.subscribe();
	}

	cancelAdd(): void {
		this.selectedLiability = undefined;
		this.isAddNew = false;
	}
}
