import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-export-progress',
  templateUrl: './export-progress.component.html',
})
export class ExportProgressComponent implements OnInit {

  constructor() { }
  @Input() progress = {
    width: '0%'
  };
  @Input() percent = 0;
  @Input() msg = '';

  ngOnInit() {
  }
}
