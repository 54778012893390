import { appDocsMergeTag, appDocsMetaKey } from './documents.merge-tags';
import {
	ApplicationDocument,
	bankFiles,
	documentFileName,
} from '../../../../../../../modules/crm/crt-page/crt-mortgage/application/application-steps/documents/state/documents.model';
import { PurposeState } from '@modules/crm/crt-page/crt-mortgage/application/application-steps/purpose/state/purpose.store';
import { DocumentListState } from '@modules/mortgage-settings/document-list/state/document-list.model';

export class AppDocsMergeTagMapper {
	public static getMergeTagValues(
		documents: ApplicationDocument[],
		documentSettings: DocumentListState[],
		purpose: PurposeState
	) {
		const list = documents
			?.filter((d) => {
				const bank = purpose?.bank?.toLowerCase();
				if (
					(bank !== 'anz' && d?.document === bankFiles?.ANZ) ||
					(bank !== 'westpac' && d?.document === bankFiles?.WestPac)
				) {
					return false;
				}
				if (d?.fromDocumentListSettings) {
					return documentSettings?.find((x) => x?.documentName === d?.document)
						?.isEnable;
				}
				return true;
			}, [])
			?.sort((a, b) => a?.order - b?.order);

		return appDocsMergeTag?.map((x) => {
			if (x?.metaKey === appDocsMetaKey.documents) {
				return {
					...x,
					value: list
						?.filter((i) => !!i?.combine && !!i?.documentId)
						?.map((i) => i?.document || ''),
				};
			}
			return x;
		});
	}
}
