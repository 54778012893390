import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { LiabilityMapper } from 'src/app/shared/models/client-review-template/assets-liabilities/liability/liability.mapper';
import { LiabilityInfo } from 'src/app/shared/models/client-review-template/assets-liabilities/liability/liability.model';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { logMessage } from '../../../../../error-message/error-message';

@Component({
  selector: 'app-moat-liabilities-form',
  templateUrl: './liabilities-form.html',
  styleUrls: ['./liabilities-form.scss'],
})
export class MOATLiabilitiesFormComponent implements OnInit {
  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Input() lender: ViewDisplayValue[];
  @Input() loanTypes: ViewDisplayValue[];
  @Input() mortgageFrequency: ViewDisplayValue[];
  @Input() agree: ViewDisplayValue[];
  @Input() liabilities: ViewDisplayValue[];
  @Input() liabilityInfo: LiabilityInfo;
  @Input() borrowers: ViewDisplayValue[];
  @Input() securities: ViewDisplayValue[];
  @Input() isLoading: boolean;

  public bsModalRef: BsModalRef;
  form: UntypedFormGroup;
  submitted = false;
  isLoadingBtn = false;

  cancelLoading: boolean;

  @Input() getOwnerChoices: (
		owners: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => ViewDisplayValue[];

  constructor(
    private fb: UntypedFormBuilder,
    private loggerService: LoggerService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.prepareData();
  }

  prepareData() {
    this.form.reset(LiabilityMapper.mapToView(this.liabilityInfo));
  }

  get Liability() {
    return this.form.get('liability');
  }
  get LoanLimit() {
    return this.form.get('loanLimit');
  }
  get LoanBalance() {
    return this.form.get('loanBalance');
  }
  get LoanRepayment() {
    return this.form.get('loanRepayment');
  }
  get Repayment() {
    return this.form.get('repayment');
  }
  get MortgageFrequency() {
    return this.form.get('mortgageFrequency');
  }

  buildForm() {
    this.form = this.fb.group({
      borrower: [''],
      security: [''],
      liability: ['', Validators.required],
      loanLimit: ['', Validators.required],
      lender: [''],
      loanBalance: ['', Validators.required],
      loanRepayment: ['', Validators.required],
      mortgageFrequency: ['Fortnightly', Validators.required],
      interestRate: [''],
      paidInFull: [''],
      loanType: [''],
    });
  }

  save() {
    if (this.isLoading || this.cancelLoading) {
      return;
    }
    this.submitted = true;
    this.isLoadingBtn = true;
    if (this.form.invalid) {
      this.loggerService.Warning(null, logMessage.shared.general.warning.required);
      this.isLoadingBtn = false;
      return;
    }
    const value = this.form.value;
    this.saveEvent.emit(
      !!this.liabilityInfo
        ? { ...value, cRTId: this.liabilityInfo.CRTId }
        : value
    );
    this.isLoadingBtn = false;
  }

  cancelClick() {
    this.cancelLoading = true;
    this.cancelEvent.emit(true);
    setTimeout(() => this.cancelLoading = true, 500);
  }

  policyOwnerChoices(owners: (string | number)[]) {
		return this.getOwnerChoices(owners, this.borrowers);
	}
}
