import * as R from 'ramda';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import { MonthlyExpenseDetailsState } from '../models/monthly-expense.model';

export class MonthlyExpenseMapper {
	/** Mapping for view */
	public static mapToView(data: MonthlyExpenseDetailsState) {
		const defaultFrequencyValue = 'Monthly';
		const annualFrequencyValue = 'Annually';
		return {
			...data,
			rentNowFrequency: data?.rentNowFrequency || defaultFrequencyValue,
			rentFutureFrequency: data?.rentFutureFrequency || defaultFrequencyValue,
			mortgagePaymentsFrequency: data?.mortgagePaymentsFrequency || defaultFrequencyValue,
			utilitiesFrequency: data?.utilitiesFrequency || defaultFrequencyValue,
			ownerOccupiedRatesFrequency: data?.ownerOccupiedRatesFrequency || annualFrequencyValue,
			investmentRatesFrequency: data?.investmentRatesFrequency || annualFrequencyValue,
			personalDebtFrequency: data?.personalDebtFrequency || annualFrequencyValue,
			healthMedicalCareFrequency: data?.healthMedicalCareFrequency || defaultFrequencyValue,
			householdContentsInsuranceFrequency: data?.householdContentsInsuranceFrequency || defaultFrequencyValue,
			houseInsuranceFrequency: data?.houseInsuranceFrequency || defaultFrequencyValue,
			vehicleInsuranceFrequency: data?.vehicleInsuranceFrequency || defaultFrequencyValue,
			hMFPInsuranceFrequency: data?.hMFPInsuranceFrequency || defaultFrequencyValue,
			lRInsuranceFrequency: data?.lRInsuranceFrequency || defaultFrequencyValue,
			groceriesFrequency: data?.groceriesFrequency || defaultFrequencyValue,
			ongoingHouseholdExpensesFrequency: data?.ongoingHouseholdExpensesFrequency || defaultFrequencyValue,
			childCareFrequency: data?.childCareFrequency || defaultFrequencyValue,
			childSupportMaintenanceFrequency: data?.childSupportMaintenanceFrequency || defaultFrequencyValue,
			personalCareClothingFrequency: data?.personalCareClothingFrequency || defaultFrequencyValue,
			publicEducationFrequency: data?.publicEducationFrequency || defaultFrequencyValue,
			privateEducationFrequency: data?.privateEducationFrequency || defaultFrequencyValue,
			transportFrequency: data?.transportFrequency || defaultFrequencyValue,
			kiwiSaverContributionFrequency: data?.kiwiSaverContributionFrequency || defaultFrequencyValue,
			entertainmentRecreationFrequency: data?.entertainmentRecreationFrequency || defaultFrequencyValue,
			tithingFrequency: data?.tithingFrequency || defaultFrequencyValue,
			totalExpenses: data.totalExpenses,
			expenseList: data?.expenseList || [],
			incomeSourceList: data?.incomeSourceList || [],
			bodyCorporateFrequency: data?.bodyCorporateFrequency || defaultFrequencyValue,
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: MonthlyExpenseDetailsState,
		adviceProcessId?: number,
		cid?: number
	): MonthlyExpenseDetailsState {
		return {
			...data,
			rentNow: this.setValue(data?.rentNow),
			rentNowNotContinuing: this.setValue(data?.rentNowNotContinuing),
			rentFuture: this.setValue(data?.rentFuture),
			rentFutureNotContinuing: this.setValue(data?.rentFutureNotContinuing),
			mortgagePayments: this.setValue(data?.mortgagePayments),
			mortgagePaymentsNotContinuing: this.setValue(data?.mortgagePaymentsNotContinuing),
			utilities: this.setValue(data?.utilities),
			utilitiesNotContinuing: this.setValue(data?.utilitiesNotContinuing),
			ownerOccupiedRates: this.setValue(data?.ownerOccupiedRates),
			ownerOccupiedRatesNotContinuing: this.setValue(data?.ownerOccupiedRatesNotContinuing),
			investmentRates: this.setValue(data?.investmentRates),
			investmentRatesNotContinuing: this.setValue(data?.investmentRatesNotContinuing),
			personalDebt: this.setValue(data?.personalDebt),
			personalDebtNotContinuing: this.setValue(data?.personalDebtNotContinuing),
			healthMedicalCare: this.setValue(data?.healthMedicalCare),
			healthMedicalCareNotContinuing: this.setValue(data?.healthMedicalCareNotContinuing),
			householdContentsInsurance: this.setValue(data?.householdContentsInsurance),
			householdContentsInsuranceNotContinuing: this.setValue(data?.householdContentsInsuranceNotContinuing),
			houseInsurance: this.setValue(data?.houseInsurance),
			houseInsuranceNotContinuing: this.setValue(data?.houseInsuranceNotContinuing),
			vehicleInsurance: this.setValue(data?.vehicleInsurance),
			vehicleInsuranceNotContinuing: this.setValue(data?.vehicleInsuranceNotContinuing),
			hMFPInsurance: this.setValue(data?.hMFPInsurance),
			hMFPInsuranceNotContinuing: this.setValue(data?.hMFPInsuranceNotContinuing),
			lRInsurance: this.setValue(data?.lRInsurance),
			lRInsuranceNotContinuing: this.setValue(data?.lRInsuranceNotContinuing),
			groceries: this.setValue(data?.groceries),
			groceriesNotContinuing: this.setValue(data?.groceriesNotContinuing),
			ongoingHouseholdExpenses: this.setValue(data?.ongoingHouseholdExpenses),
			ongoingHouseholdExpensesNotContinuing: this.setValue(data?.ongoingHouseholdExpensesNotContinuing),
			childCare: this.setValue(data?.childCare),
			childCareNotContinuing: this.setValue(data?.childCareNotContinuing),
			childSupportMaintenance: this.setValue(data?.childSupportMaintenance),
			childSupportMaintenanceNotContinuing: this.setValue(data?.childSupportMaintenanceNotContinuing),
			personalCareClothing: this.setValue(data?.personalCareClothing),
			personalCareClothingNotContinuing: this.setValue(data?.personalCareClothingNotContinuing),
			publicEducation: this.setValue(data?.publicEducation),
			publicEducationNotContinuing: this.setValue(data?.publicEducationNotContinuing),
			privateEducation: this.setValue(data?.privateEducation),
			privateEducationNotContinuing: this.setValue(data?.privateEducationNotContinuing),
			transport: this.setValue(data?.transport),
			transportNotContinuing: this.setValue(data?.transportNotContinuing),
			kiwiSaverContribution: this.setValue(data?.kiwiSaverContribution),
			kiwiSaverContributionNotContinuing: this.setValue(data?.kiwiSaverContributionNotContinuing),
			entertainmentRecreation: this.setValue(data?.entertainmentRecreation),
			entertainmentRecreationNotContinuing: this.setValue(data?.entertainmentRecreationNotContinuing),
			tithing: this.setValue(data?.tithing),
			tithingNotContinuing: this.setValue(data?.tithingNotContinuing),
			expenseList: data.expenseList || [],
			cRTId: cid || null,
			adviceProcessId: adviceProcessId || null,
			sectionCode: AdviceProcessSectionCodes.MonthlyExpense,
			status: 1,
			bodyCorporate: this.setValue(data?.bodyCorporate),
			bodyCorporateNotContinuing: this.setValue(data?.bodyCorporateNotContinuing),
		};
	}

	public static setValue(value) {
		return R.isEmpty(value) ? null : value;
	}
}
