<div class="statement-of-advice">
	<div
		class="statement-of-advice__editor mb-2"
		[class.wysiwyg-loading]="isLoading || isSaving"
	>
		<app-wysiwyg
			#contentEditor
			[content]="currentTemplate"
			[hasSidebarSections]="false"
			[isTapLevel]="isTapLevel"
			[hideMergeTagButtons]="true"
			designClass="pdf-design-v2-froala"
		></app-wysiwyg>
	</div>
</div>
