import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { CalcTemplateType } from '../state/tl-moat-settings.model';
import { ServicingCalculatorSettingsService } from './state/servicing-calculators-settings.service';

@Component({
	selector: 'app-servicing-calculators',
	templateUrl: './servicing-calculators.component.html',
	styleUrls: ['./servicing-calculators.component.scss'],
})
export class ServicingCalculatorsComponent implements OnInit {
	integratedTemplates$ = this.service.servicingCalculators$.pipe(
		map((x) => x?.filter((i) => i?.status === 1)),
		map((x) =>
			x?.filter((i) => i?.templateType === CalcTemplateType.Integrated)
		)
	);
	blankTemplates$ = this.service.servicingCalculators$.pipe(
		map((x) => x?.filter((i) => i?.status === 1)),
		map((x) => x?.filter((i) => i?.templateType === CalcTemplateType.Blank))
	);

	constructor(private service: ServicingCalculatorSettingsService) {}

	ngOnInit(): void {}
}
