import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { take, tap } from 'rxjs/operators';
import { UserQuery } from 'src/app/domain/user/user.query';
import { withLatestFrom } from 'rxjs/operators';
import { ActivityViewModel } from '../../models/_general/activity.viewmodel';
import { LoggerService } from 'src/app/core/logger/logger.service';
import {
	Fields,
	getInvalidWarning,
	getRequiredWarning,
} from '../../error-message/error-message';
import MomentUtil from 'src/app/util/moment.util';

@Component({
	selector: 'app-phone-call-add-modal',
	templateUrl: './phone-call-add-modal.component.html',
	styleUrls: ['./phone-call-add-modal.component.scss'],
})
export class PhoneCallAddModalComponent implements OnInit {
	savefn: (ac: ActivityViewModel) => Observable<any>;
	advisers$: Observable<ViewDisplayValue[]>;
	defaultAssignedTo: number;
	header: string;
	message: string;
	form: UntypedFormGroup;
	advisers: ViewDisplayValue[];
	isLoading = false;
	submitted = false;

	constructor(
		public bsModalRef: BsModalRef,
		private fb: UntypedFormBuilder,
		private userQuery: UserQuery,
		private loggerService: LoggerService
	) {
		this.header = 'Phone Call';
		this.form = this.fb.group({
			ActivityType: 'Phone Call',
			ActivityName: ['Call Notes', [Validators.required]],
			DueDate: [MomentUtil.createMomentNz(), [Validators.required]],
			DueTime: [
				MomentUtil.createMomentNz().format('HH:mm'),
				[Validators.required],
			],
			Adviser: [null, [Validators.required]],
			Details: '',
		});
	}

	get ActivityName() {
		return this.form.get('ActivityName');
	}
	get Adviser() {
		return this.form.get('Adviser');
	}
	get DueDate() {
		return this.form.get('DueDate');
	}
	get DueTime() {
		return this.form.get('DueTime');
	}

	ngOnInit(): void {
		this.advisers$
			.pipe(
				tap((x) => (this.advisers = x)),
				take(1)
			)
			.subscribe();

		this.userQuery.isTapLevel$
			.pipe(withLatestFrom(this.userQuery.userId$), take(1))
			.subscribe(([x, y]) => {
				if (!x) {
					this.form.get('Adviser').setValue(+y);
				}
			});
	}

	save() {
		this.submitted = true;

		if (!this.form.valid) {
			if (!this.ActivityName.value) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.ActivityName));
			}
			if (!this.DueDate.value) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Date));
			}
			if (!this.DueTime.value) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Time));
			}
			if (!this.Adviser.value) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Adviser));
			}
			return;
		} else {
			const time = this.DueTime.value
				? this.DueTime.value
						?.toString()
						?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
						this.DueTime?.value,
				  ]
				: null;
			if (time.length === 1) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.DueTime));
				return;
			}
		}

		this.isLoading = true;
		const data = this.form.getRawValue();
		const assignedTo =
			this.advisers?.find((i) => +i?.value === +data?.Adviser)?.display || '';

		this.savefn({ ...data, AssignedToAdviserName: assignedTo }).subscribe(
			() => {
				this.isLoading = false;
				this.close();
			},
			() => {
				this.isLoading = false;
			},
			() => {
				this.isLoading = false;
			}
		);
	}

	close = () => this.bsModalRef.hide();
}
