import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ReviewApplicationState } from './review-application.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'reviewApplication', idKey: 'parentCRTId' })
export class ReviewApplicationStore extends EntityStore<ReviewApplicationState> {
	constructor() {
		super();
	}
}
