import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, combineLatest, startWith } from 'rxjs/operators';
import { RMService } from './state/rm.service';
import { RMQuery } from './state/rm.query';
import { DashboardQuery } from '../../state/dashboard.query';
import { RouteService, CommandRoute } from '../../../../../core/config/route.service';
import { RefixMortgage } from './state/rm.model';
import MomentUtil from '../../../../../util/moment.util';
import { UntypedFormControl } from '@angular/forms';
import { DateRange } from '../../../../../shared/date-range/date-range.component';

/**
 * Refix mortgage widget component
 */
@Component({
  selector: 'app-rm',
  templateUrl: './rm.component.html',
  styleUrls: ['./rm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RmComponent implements OnInit, OnDestroy {
  public static widgetCode = 'rm';
  public static widgetName = 'Refix Mortgage';
  public static sizeX = 350;
  public static sizeY = 480;
  public static minSizeX = 350;
  public static minSizeY = 480;

  mortgageRoute: CommandRoute;

  widgetCode = RmComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * Refix mortgage data
   */
  data$: Observable<RefixMortgage[]> = this.query.data$;
  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;
  /**
   * Error message string. Show error if not empty.
   */
  error$ = this.query.error$;
  /** form control for date range.
   * Should default to 1 week behind up to now.
   */
  dateControl: UntypedFormControl = new UntypedFormControl({
    min: MomentUtil.formatToServerDate(MomentUtil.createMomentNz()),
    max: MomentUtil.formatToServerDate(MomentUtil.createMomentNz().add(2, 'M'))
  } as DateRange);

  constructor(
    private routeService: RouteService,
    private dashboardQuery: DashboardQuery,
    private service: RMService,
    private query: RMQuery
  ) { }

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of goal track.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilter$.pipe(
      filter(x => x.length > 0),
      combineLatest(this.dateControl.valueChanges.pipe(startWith(this.dateControl.value))),
      takeUntil(this.onDestroy$)
    ).subscribe(([advisers, dateRange]) => {
      this.service.GetRefixMortgage({
        advisers,
        dateRangeMin: dateRange.min,
        dateRangeMax: dateRange.max
      });

      this.mortgageRoute = this.routeService.mPipelineWithParams({
        statuses: 'Current Mortgage',
        advisers: advisers ? advisers.toString() : '',
        fixedPeriodStart: this.dateControl.value.min,
        fixedPeriodEnd: this.dateControl.value.max
      });
    });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  /**
   * Get a route for routerLink directive.
   *
   * @param customerId
   * customer id of the customer edit page to route to
   * @param isCompany
   * if it is a company, it will route to business edit page,
   * else, client edit page
   *
   * @returns route to either business or client edit apge
   */
  public customerRoute(customerId: number, isCompany: boolean): CommandRoute {
    if (isCompany) {
      return this.routeService.businessMPipeline(customerId);
    } else {
      return this.routeService.customerMPipeline(customerId);
    }
  }
}
