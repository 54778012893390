import * as R from 'ramda';
import { map } from 'rxjs/operators';
import { util } from '../../util/util';
import { MergeTagState } from '../models/client-review-template/merge-tags/merge-tags.model';
import { loatRiskAnalysisMetaKey } from '@shared/models/client-review-template/merge-tags/crt-lr-insurance/risk-analysis/risk-analysis.merge-tags';

const tagsToLoop = ['li', 'th', 'td'];
const repeatTableName = 'REPEAT_TABLE_ROW';
const repeatSectionName = 'REPEAT_SECTION';
const colWidth = 'auto';
// Merge Tag element classes
const mtagOrigClass = 'mtag-orig';
const mtagDelClass = 'mtag-delete';
const tableNoDataClassTr = 'no-data-display';
const tableNoDataHideTbl = 'mtag-hide-table';
const tableNoDataHideTbody = 'mtag-hide-tbody';
const noDataPlaceholderClass = 'no-data-placeholder';
const dynamicDependantsClass = 'dynamicDependants';
const allowHideOnTableRow = 'allow-hide-table-row-value';
export const contentEditableClass = 'contenteditable';
export const contentEditFalse = 'contenteditable="false"';
export const contentEditTrue = 'contenteditable="true"';

// Placeholders/Default texts
const listNoDataPlaceholder = `<em ${contentEditFalse} class="${noDataPlaceholderClass}">None selected</em>`;
const tableNoDataPlaceholder = `<em ${contentEditFalse} class="${noDataPlaceholderClass}">No data to display</em>`;
export const pageBreak =
	'<hr class="span.fr-deletable generated-pagebreak" name="pagebreak" contenteditable="false">';
export const notEmptyParagraph = `<p class="not-empty"><br></p>`;
export const BR = `<br />`;

export const placeholders = {
	class: noDataPlaceholderClass,
	li: listNoDataPlaceholder,
	table: tableNoDataPlaceholder,
};

export const contentEditable = {
	false: contentEditFalse,
	true: contentEditTrue,
};

/**
 * Convert all merge tag codes from HTML String
 * @param content: Current HTML <string>
 * @param mergeTags: List of Merge Tag Objects <Array[]>
 * @param skipNewLineUpdate: optional; Set to true, to maintain new line for textareas <boolean>
 * @param noWrap: optional; Set to true, to convert merge tag value without the <var> wrapper
 * @returns Updated HTML <string>
 */
export const getContentWithMergeTags = (
	content: string,
	mergeTags: MergeTagState[] = [],
	skipNewLineUpdate?: boolean,
	noWrap?: boolean,
) => {
	if (!skipNewLineUpdate) {
		content = content?.trim()?.replace(/(\r\n|\n|\r|\t)/gm, '');
	}

	if (content && mergeTags) {
		let updatedContent = content;
		// Update content from [REPEAT_SECTION] tags
		updatedContent = repeatSection(updatedContent, mergeTags);

		// Update content from [REPEAT_TABLE_ROW] tags
		updatedContent = repeatTableRow(updatedContent, mergeTags);

		// Update all other remaining tags
		updatedContent = mergeTags?.reduce((acc: any, itemTag) => {
			const { value } = itemTag;
			const mtag = noWrap ? {...itemTag, noWrap } : itemTag;
			if (typeof value === 'object') {
				return replaceMultiVal(acc, mtag);
			} else {
				return replaceStr(acc, mtag);
			}
		}, updatedContent);
		return updatedContent;
	}
	return content;
};

let MT_UID = 1;
const wrapText = (itemTag, markDelete: boolean = false) => {
	const value =
		typeof itemTag?.value === 'object' && itemTag.value?.value
			? [itemTag.value.value]
			: checkValue(itemTag?.value);
	const txtClass = markDelete
		? `mtag ${mtagDelClass}`
		: `mtag ${mtagOrigClass}`;

	const txtValue = `<var data-uid="${MT_UID++}" data-mergetag="${
		itemTag?.metaKey
	}" class="${txtClass}">${value?.join(', ')}</var>`;
	return itemTag?.noWrap ? value?.join(', ') : txtValue;
};

const wrapImg = (itemTag) => {
	const value = checkValue(itemTag?.value);
	return value?.reduce(
		(a, c) =>
			`${a} <img src="${c}" class="mtag ${mtagOrigClass}" data-mergetag="${itemTag?.metaKey}">`,
		''
	);
};

const wrapLink = (itemTag) => {
	const value = checkValue(itemTag?.value);
	return (
		value
			?.map(
				(c) =>
					`<a href="${c}" target="_blank" class="mtag ${mtagOrigClass}" data-mergetag="${itemTag?.metaKey}">${c}</a>`
			)
			?.join(' ') || ''
	);
};

const replaceStr = (acc: string, itemTag, markDelete: boolean = false) => {
	const { type } = itemTag;
	const metaKey = itemTag?.metaKey?.toUpperCase();
	const replace = `%${metaKey}%`;
	const regEx = new RegExp(replace, 'g');
	switch (type) {
		case 'I':
			const imgVal = wrapImg(itemTag);
			return acc?.toString()?.replace(regEx, imgVal);
			break;
		case 'L':
			const linkVal = wrapLink(itemTag);
			return acc?.toString()?.replace(regEx, linkVal);
			break;
		default:
			const textVal = wrapText(itemTag, markDelete);
			return acc?.toString()?.replace(regEx, textVal);
			break;
	}
};

const replaceMultiVal = (content, itemTag) => {
	const metaKey = itemTag?.metaKey?.toUpperCase();
	const getData = checkValue(itemTag?.value);
	let updatedContent = content || '';

	tagsToLoop?.forEach((htmlTag) => {
		const regEx = new RegExp(
			`(?:<${htmlTag}[^>]*>)(.*?)(?:<\/${htmlTag}>)`,
			'g'
		);
		const getTagsFromContent = updatedContent?.match(regEx);
		const contentWithMetaTags = getTagsFromContent?.filter((i) => {
			return i?.includes(`%${metaKey}%`);
		});

		if (contentWithMetaTags) {
			updatedContent = contentWithMetaTags?.reduce((acc, cur) => {
				if (['td', 'th'].includes(htmlTag) && getData?.length === 0) {
					// Add blank value so that the cell will still be created if merge tag has no value
					getData.push('');
				}
				if (htmlTag === 'li' && getData?.length === 0) {
					// Add blank value so that the list will still be created if merge tag has no value
					getData.push(listNoDataPlaceholder);
				}
				const finalValue = getData?.reduce((a, c, i) => {
					const isIndexNotZero = i !== 0;
					// Update tag values inside elements/cell
					const val =
						['', ' '].includes(c) && ['td', 'th'].includes(htmlTag)
							? '&nbsp;'
							: c;
					let newValue = replaceStr(
						cur,
						{ ...itemTag, type: 'T', value: val },
						isIndexNotZero
					);

					if (['td', 'th'].includes(htmlTag)) {
						if (getData?.length > 1) {
							// Add default cell width for table cells if data columns are more than 1
							newValue = addcolWidth(newValue, htmlTag);
						} else {
							// Remove colspan properties added by google during copy-paste
							newValue = removeColSpan(newValue);
						}
					}
					return `${a} ${newValue}`;
				}, '');
				// const exp = new RegExp(cur, 'g');
				// return acc.replace(exp, finalValue);
				return acc.replaceAll(cur, finalValue);
			}, updatedContent);
		}
	});

	// Update remaining image, links, and texts
	updatedContent = replaceStr(updatedContent, itemTag);

	return updatedContent;
};

const repeatTableRow = (content: string, mergeTags: MergeTagState[]) => {
	let updatedContent = content;
	const repeatRegEx = new RegExp(
		`(?:<p[^>]*>\\[${repeatTableName}\\]).*?(?:\\[\/${repeatTableName}\\]<\/p>)`,
		'g'
	);
	const getTagsFromContent = content.toString().match(repeatRegEx);

	// For copy pasted Regex
	const repeatCopyPasteRegEx = new RegExp(
		`(?:<p[^>]*>)(?:<span[^>]*>)(?:\\[${repeatTableName}\\]).*?(?:\\[\/${repeatTableName}\\])(?:<\/span>)(?:<\/p>)`,
		'g'
	);
	const getCopyPasteTagsFromContent = content
		.toString()
		.match(repeatCopyPasteRegEx);

	// All repeat table rows
	const allRepeatTableRow = [
		...(getTagsFromContent || []),
		...(getCopyPasteTagsFromContent || []),
	];

	allRepeatTableRow?.forEach((html) => {
		// Parse html to json
		const {
			rowCount,
			thead,
			tbody,
			hasNoData,
			hideTable,
			showNoDataDisplay,
			otherTableClass,
		} = htmlTableToJson(html, mergeTags);

		// Recreate table based from json
		const totalRows = Array.from(Array(+rowCount).keys());

		let tHeadContent = thead?.reduce((acc, cur) => {
			return `${acc} ${cur.outerHTML}`;
		}, '');

		let tableClass = '';
		let tRowContent = '';
		let tBodyContent = totalRows?.reduce((a, i) => {
			a = `${a} <tr>`;
			tRowContent = tbody?.reduce((acc, cur) => {
				let tdValue = '';
				let getMtValue = cur.metaKey ? cur.mergeTagData[i] : null;
				getMtValue = getMtValue === '' ? ' ' : getMtValue;

				if (getMtValue) {
					if (Array.isArray(getMtValue)) {
						// If value is Array, print values per columns but still repeat per row
						const newTd = getMtValue?.reduce((value, c, index) => {
							const val = ['', ' '].includes(c) ? '&nbsp;' : c;
							const width = getMtValue?.length > 1 ? colWidth : 'auto';
							const txtClass =
								index === 0 && i === 0
									? `mtag ${mtagOrigClass}`
									: `mtag ${mtagDelClass}`;
							return `${value} <td width="${width}"><var data-mergetag="${cur.mergeTag?.metaKey}" class="${txtClass}">${val}</var></td>`;
						}, '');
						tdValue = cur.outerHTML.replace(cur.outerHTML, newTd || '');
					} else {
						// Means value contains 1 data, either string or number
						const txtClass =
							i === 0 ? `mtag ${mtagOrigClass}` : `mtag ${mtagDelClass}`;
						tdValue = cur.outerHTML.replace(
							cur.innerHTML,
							`<var data-mergetag="${
								cur.mergeTag?.metaKey
							}" class="${txtClass}">${
								tryParse(cur.mergeTagData[i]) || ''
							}</var>`
						);
					}
				} else {
					if (i > 0) {
						// Add mtag-delete for non-merge tag cells that were repeated and needs to be deleted on reset
						tdValue = cur.outerHTML.replace(
							cur.innerHTML,
							`<var class='mtag ${mtagDelClass}'>${cur.innerHTML}</var>`
						);
					} else {
						tdValue = cur.outerHTML;
					}
				}

				return `${acc} ${tdValue}`;
			}, '');
			return `${a} ${tRowContent}</tr>`;
		}, '');

		tHeadContent = R.isEmpty(thead) ? '' : `<thead>${tHeadContent}</thead>`;
		tBodyContent = R.isEmpty(tbody) ? '' : tBodyContent;

		if (hasNoData) {
			// Special conditions if merge tag has no data for table
			tableClass = ' mtag-no-data';
			if (hideTable) {
				tableClass = ` ${tableNoDataHideTbl}${tableClass}`;
			} else if (showNoDataDisplay) {
				const colSpan = thead?.length || 1;
				tableClass = ` ${tableNoDataHideTbody}${tableClass}`;
				tBodyContent = `<tr class="${tableNoDataClassTr}"><td colSpan='${colSpan}'>${tableNoDataPlaceholder}</td></tr>${tBodyContent}`;
			}
		}

		tBodyContent = `<tbody>${tBodyContent}</tbody>`;
		tableClass = `${tableClass} ${otherTableClass?.join(' ')}`;
		const newTableContent = `<table width="100%" class="mtag-${repeatTableName}${tableClass}">${tHeadContent}${tBodyContent}</table>`;
		updatedContent = updatedContent.replace(html, newTableContent);
	});
	return updatedContent;
};

const repeatSection = (content: string, mergeTags: MergeTagState[]) => {
	let updatedContent = content;
	const repeatRegEx = new RegExp(
		`(?:<p[^>]*>\\[${repeatSectionName}\\]).*?(?:\\[\/${repeatSectionName}\\]<\/p>)`,
		'g'
	);
	const getTagsFromContent = content.toString()?.match(repeatRegEx);
	getTagsFromContent?.forEach((html) => {
		// RegEx for detecting merge tags
		const mtRegex = html?.match(new RegExp('\\%([^}>\\s]+)\\%', 'g'));
		const getUsedTags = mergeTags?.filter((i) =>
			mtRegex?.includes('%' + i.metaKey?.toUpperCase() + '%')
		);
		// Count total # repeat based on number of max values from merge tags
		const totalRepeat = getMaxLength(getUsedTags);

		// If there's no data, section content should still persist but hidden;
		// So, force 1 repeat with d-none class
		const isEmpty = totalRepeat === 0;
		const displayClass = isEmpty ? 'd-none' : '';
		const arrRepeat = Array.from(Array(isEmpty ? 1 : +totalRepeat).keys());

		const sectionValue = arrRepeat?.reduce((a, c, i) => {
			let newTags;
			if (totalRepeat === 1) {
				newTags = reformatMtSingle(getUsedTags);
			} else {
				newTags = reformatMt(getUsedTags, c);
			}
			const sectionClass =
				i > 0 ? `mtag ${mtagDelClass}` : `mtag ${mtagOrigClass}`;

			const newHtml = repeatTableRow(
				`<section class="mtag-${repeatSectionName} ${sectionClass} ${displayClass}">${html}</section>`,
				newTags
			);
			// Replace html with tags
			let newValue = newTags?.reduce((acc: any, itemTag) => {
				const { value: metaValue } = itemTag;
				if (
					metaValue?.value &&
					typeof metaValue?.value === 'object' &&
					metaValue?.value?.length > 1
				) {
					// if merge tag value is an object with multiple values/array of values
					return replaceMultiVal(acc, { ...itemTag, value: metaValue?.value });
				} else {
					return replaceStr(acc, itemTag);
				}
			}, newHtml);

			// Clean html
			newValue = newValue
				.replace(`[${repeatSectionName}]`, '')
				.replace(`[/${repeatSectionName}]`, '')
				.replace('<p></p>', '');
			return a + newValue;
		}, '');

		updatedContent = updatedContent?.replace(html, sectionValue);
	});
	return updatedContent;
};

const htmlTableToJson = (html: string, mergeTags: MergeTagState[] = []) => {
	const jsonTable = {
		thead: [],
		tbody: [],
		rowCount: 0,
		hasNoData: false,
		showNoDataDisplay: false,
		hideTable: false,
		otherTableClass: [],
	};
	// Parse html string to DOM parser
	const tableHTML = document.createRange().createContextualFragment(html);
	const getAllTableHeader: HTMLElement[] = Array.from(
		tableHTML.querySelectorAll('table thead th')
	);
	Array.from(tableHTML.querySelectorAll('table'))?.forEach((i) => {
		jsonTable.otherTableClass = Array.from(i?.classList);
	});
	// Get all td/th values from thead/tbody tags
	getAllTableHeader.forEach((item) => {
		jsonTable.thead.push({
			innerHTML: item.innerHTML,
			innerText: item.innerText,
			outerHTML: item.outerHTML,
		});
	});

	const getDataObj = (mergeTag: MergeTagState) => {
		if (R.complement(R.isNil)(mergeTag?.value)) {
			return checkValue(mergeTag?.value);
		}
		return [];
	};

	const getAllTableData: HTMLElement[] = Array.from(
		tableHTML.querySelectorAll('table tbody td')
	);
	getAllTableData.forEach((item) => {
		if (
			Array.from(item?.parentElement?.classList).includes(tableNoDataClassTr)
		) {
			// Remove table row of "No data display"
			item?.parentElement?.remove();
			item?.remove();
		} else {
			const metaRegEx = new RegExp(`\\%([a-zA-Z0-9_]*)\\%`, 'g');
			const getMergeTagKey = item.outerHTML.match(metaRegEx);
			const metaKey = getMergeTagKey ? getMergeTagKey[0] : null;
			const mergeTag = getMergeTagKey
				? mergeTags.find(
						(i) =>
							metaKey &&
							`%${i.metaKey?.toUpperCase()}%` === metaKey?.toUpperCase()
				  )
				: null;
			const mergeTagData = getDataObj(mergeTag);

			jsonTable.tbody.push({
				innerHTML: item.innerHTML,
				innerText: item.innerText,
				outerHTML: item.outerHTML,

				metaKey,
				mergeTag,
				mergeTagData,
			});

			// Count max row base on data list
			if (mergeTagData && +mergeTagData.length > jsonTable.rowCount) {
				jsonTable.rowCount = +mergeTagData.length;
			}
		}
	});

	if (jsonTable.rowCount === 0) {
		// If there are no merge tag values to display, force to add blank value
		jsonTable.rowCount = 1;
		jsonTable.hasNoData = true;

		if (jsonTable.thead?.length > 0) {
			// If table has a header, do not hide table but add "No data to display"
			// e.g: A&L tables
			jsonTable.showNoDataDisplay = true;
			jsonTable.hideTable = false;
		} else {
			if (
				jsonTable.tbody?.some(
					(x) => !x?.mergeTag && !x?.outerHTML?.includes(allowHideOnTableRow)
				)
			) {
				// If some rows have text only cells that are not merge tag
				// then, do not hide the table
				// e.g: Dependants table
				jsonTable.hideTable = false;
			} else {
				// Hide table if all cells are merge tags only with no values
				jsonTable.hideTable = true;
			}
		}

		jsonTable.tbody = jsonTable.tbody?.map((x) => {
			return {
				...x,
				mergeTagData: [''],
			};
		});
	}
	// return as json
	return jsonTable;
};

// For repeater
const reformatMt = (data = [], index: number = 0) => {
	const getVal = (val, i) => {
		const value = checkValue(val);
		return value?.length === 1 ? value : value[i] ?? [];
	};
	return data?.map((item) => ({
		...item,
		value: getVal(item?.value, index),
	}));
};

const reformatMtSingle = (data = []) => {
	const getVal = (val) => {
		if (val && typeof val === 'object' && val.length > 0) {
			return val[0];
		} else {
			return val;
		}
	};
	return data?.map((item) => ({
		...item,
		value: getVal(item?.value),
	}));
};

const addcolWidth = (content, tag) => {
	const regex = new RegExp(`(?:width).*?(?:;)`, 'g');
	const toReplace = content?.match(regex);
	let newData = content;

	if (toReplace?.length > 0) {
		// Replace existing width that has existing width attribute on cell
		newData = toReplace?.reduce((a, c) => {
			return a?.replace(c, `width:${colWidth};`);
		}, newData);
	} else {
		if ('style'.includes(content)) {
			// Add width on an already existing style with no existing width attribute
			const data = content?.split(`style="`, 2);
			if (data?.length > 0) {
				return `${data[0]} style="width:${colWidth};${data[1]}`;
			}
		} else {
			// Add width to cells with no existing style attribute
			const data = content?.split(`<${tag}`, 2);
			if (data?.length > 0) {
				return `<${tag} style="width:${colWidth};" ${data[1]}`;
			}
		}
	}

	// Remove colspan properties added by google during copy-paste
	newData = removeColSpan(newData);

	return newData;
};

const removeColSpan = (content) => {
	const regex = new RegExp(`(?:colspan=").*?(?:")`, 'g');
	const toReplace = content?.match(regex);
	let newData = content;

	if (toReplace?.length > 0) {
		newData = toReplace?.reduce((a, c) => {
			return a?.replace(c, '');
		}, newData);
	}

	return newData;
};

// Get max length of values from merge tags
// To know how many times to repeat the section
const getMaxLength = (data = []) =>
	data?.reduce((a, c) => {
		const value = checkValue(c?.value);
		return value.length > a ? value.length : a;
	}, 0);

// Always format values to array
const checkValue = (val) => (typeof val === 'object' ? val : [val]) || [''];

// Join strings as comma separated value
const tryParse = (data) => {
	if (data && typeof data === 'string' && util.tryCatchParseJoin(data)) {
		return JSON.parse(data).join(', ');
	} else {
		return data;
	}
};

/**
 * Update Dynamic Dependants from Tables
 * Hide columns on table if there are no Dependants
 * @param content: Current HTML <string>
 * @param hideDependants: If no dependants from Fact find <boolean>
 * @returns Updated HTML <string>
 */
export const updateDependantsTbl = (
	content: string = '',
	hideDependants: boolean = false
) => {
	// tblClass value configured from froala
	const tblClass = dynamicDependantsClass;
	const wrapId = 'dynamicUpdate';
	const cellWidth = 'auto';

	// Create fragment from HTML String
	const newHtml = document
		.createRange()
		.createContextualFragment(`<div id="${wrapId}">${content}</div>`);

	newHtml.querySelectorAll(`.${tblClass}`).forEach((i) => {
		i.classList.remove(`${tblClass}-hide`);
		i.classList.remove(`${tblClass}-show`);
	});

	newHtml.querySelectorAll(`.${tblClass}`).forEach((i) => {
		if (hideDependants) {
			i.classList.add(`${tblClass}-hide`);
		} else {
			i.classList.add(`${tblClass}-show`);
		}
	});

	const getTable: HTMLElement[] = Array.from(
		newHtml.querySelectorAll(`.${tblClass}`)
	);

	getTable.forEach((i) => {
		const thLen = Array.from(i.querySelectorAll('thead tr'))?.reduce((a, c) => {
			// Update cell widths of table with People + Dependants Data
			c?.querySelectorAll('th:not(:first-child)')?.forEach(
				(val: HTMLTableElement) => (val.style.width = cellWidth)
			);

			const cols = c?.querySelectorAll('th')?.length || 0;
			return +cols > a ? cols : a;
		}, 0);
		const tdLen = Array.from(i.querySelectorAll('tbody tr'))?.reduce(
			(a, c: HTMLTableRowElement) => {
				// Update cell widths of table with People + Dependants Data
				c?.querySelectorAll('td:not(:first-child)')?.forEach(
					(val: HTMLTableElement) => (val.style.width = cellWidth)
				);

				const cols = c?.querySelectorAll('td')?.length || 0;
				return +cols > a ? cols : a;
			},
			0
		);

		if (thLen > tdLen) {
			// Handler if BE did not return value from merge tags for dependants
			// Create the table cell
			i.querySelectorAll('tbody tr').forEach((x) =>
				x.appendChild(document.createElement('td'))
			);
		}
		if (thLen < tdLen) {
			// Remove excess cell
			Array.from(i.querySelectorAll(`td:last-child`)).forEach((val) =>
				val.remove()
			);
		}
	});

	// Return new HTML string
	return newHtml.querySelector(`#${wrapId}`).innerHTML || '';
};

/**
 * Reset Merge Tags Data to Code
 * @param content string: html content or innerHtml
 * @param excludeList List of merge tags to exclude from reverting back to code
 * @returns string: content cleaned up
 */
export const resetMergeTags = (content = '', excludeList = []) => {
	const id = 'resetMergeTags';
	const newHtml = document
		.createRange()
		.createContextualFragment(`<div id="${id}">${content}</div>`);

	// REPEAT_SECTION
	newHtml.querySelectorAll(`section`).forEach((e: HTMLElement) => {
		if (e.classList?.contains(mtagDelClass)) {
			e.remove();
		} else {
			const html = e?.innerHTML;
			e.before(
				document
					.createRange()
					.createContextualFragment(`<p>[${repeatSectionName}]</p>`)
			);

			e.after(
				document
					.createRange()
					.createContextualFragment(`<p>[/${repeatSectionName}]</p>`)
			);
			e.after(document.createRange().createContextualFragment(html));
			e.remove();
		}
	});

	// REPEAT_TABLE_ROW
	newHtml
		.querySelectorAll(`.mtag-${repeatTableName}`)
		.forEach((e: HTMLElement) => {
			e.before(
				document
					.createRange()
					.createContextualFragment(`<p>[${repeatTableName}]</p>`)
			);
			e.after(
				document
					.createRange()
					.createContextualFragment(`<p>[/${repeatTableName}]</p>`)
			);
			e.classList.remove(`mtag-${repeatTableName}`);
		});

	newHtml
		.querySelectorAll(`.${tableNoDataHideTbl}`)
		.forEach((e: HTMLElement) => {
			e.classList.remove(`${tableNoDataHideTbl}`);
		});

	newHtml
		.querySelectorAll(`.${tableNoDataHideTbody}`)
		.forEach((e: HTMLElement) => {
			e.classList.remove(`${tableNoDataHideTbody}`);
		});

	// ALL THE OTHER MERGE TAGS
	newHtml.querySelectorAll(`.mtag`).forEach((e: HTMLElement) => {
		if (e.classList?.contains(mtagOrigClass)) {
			const metaKey = e?.getAttribute('data-mergetag');
			if (metaKey) {
				if (!excludeList?.includes(metaKey)) {
					// if not in exclude list, append the innerHTML
					e.after(
						document
							.createRange()
							.createContextualFragment(
								`%${e?.getAttribute('data-mergetag').toUpperCase()}%`
							)
					);
				}
				e.remove();
			}
		}

		if (e.classList?.contains(mtagDelClass)) {
			if (e?.closest('li')) {
				e?.closest('li').remove();
			} else if (e?.closest('th')) {
				e?.closest('th').remove();
			} else if (e?.closest('td')) {
				e?.closest('td').remove();
			} else {
				e.remove();
			}
		}
	});

	return newHtml.querySelector(`#${id}`).innerHTML || '';
};

/**
 * Removes all white spaces in hmlt content
 * @param content string: html content or innerHtml
 * @param isSOA boolean: is Statement of Advice Document
 * @returns string: content cleaned up
 */
export const removeHTMLContentWhiteSpaces = (
	content = '',
	isSOA = false,
	removePadding = false
) => {
	const newHtml = document
		.createRange()
		.createContextualFragment(`<div id="removeWhiteSpaces">${content}</div>`);

	let isFirstPage = true;
	const firstHR = newHtml.querySelector('hr');
	const LOATSOASOS = Object.values(loatRiskAnalysisMetaKey) || [];

	newHtml.querySelectorAll('*').forEach((e: HTMLElement) => {
		if (isSOA && !!firstHR && e === firstHR) {
			isFirstPage = false;
		}
		if ((isSOA && !isFirstPage && !!firstHR && e !== firstHR) || !isSOA) {
			if (
				/^(?:\s|<br *\/>|<br\/>|<br>)*$/.test(e.innerHTML) &&
				!e?.classList?.contains('custom-notes') &&
				!LOATSOASOS?.includes(e?.parentElement?.id)
			) {
				e.innerHTML = '';
			}
			if (removePadding) {
				e.style.padding = '0px !important';
				e.style.paddingTop = '0px !important';
				e.style.paddingBottom = '0px !important';
				e.style.paddingLeft = '0px !important';
				e.style.paddingRight = '0px !important';
			}
		}
	});

	const newDataCleaned = newHtml.querySelector('#removeWhiteSpaces').innerHTML;
	return newDataCleaned;
};

/**
 * Removes all paragraph tags that are empty or blank (excluding BR and NBSP)
 * @param content string: html content or innerHtml
 * @returns string: content cleaned up
 */
export const removeEmptyParagraphs = (content = '') => {
	const newHtml = document
		.createRange()
		.createContextualFragment(
			`<div id="removeEmptyParagraphs">${content}</div>`
		);

	newHtml.querySelectorAll('p').forEach((e: HTMLElement) => {
		if (e?.innerHTML === '' && !e?.classList?.contains('not-empty')) {
			e.remove();
		}
	});

	return newHtml.querySelector('#removeEmptyParagraphs').innerHTML || '';
};

/**
 * Remove all first level div wrappers
 * @param content string: html content or innerHtml
 * @returns string: content cleaned up
 */
export const removeSoaDivs = (content = '') => {
	const id = 'removeDivs';
	const newHtml = document
		.createRange()
		.createContextualFragment(`<div id="${id}">${content}</div>`);

	newHtml.querySelectorAll(`#${id} > div`).forEach((e: HTMLElement) => {
		if (e?.style.display === 'none') {
			e.remove();
		} else {
			if (e?.innerHTML !== '') {
				e.after(document.createRange().createContextualFragment(e?.innerHTML));
			}
			e.remove();
		}
	});

	return newHtml.querySelector(`#${id}`).innerHTML || '';
};

/**
 * Remove all Merge Tag Wrappers from content of a textarea
 * @param content string: html content or innerHtml
 * @param convertToNonTextarea boolean: Flag to identify if the content value
 * will be assigned to a non text-area
 * @returns string: content cleaned up
 */
export const removeMtWrappersOnTextarea = (
	content: string,
	convertToNontextarea: boolean
) => {
	let value = content || '';
	if (convertToNontextarea) {
		// convert new lines/row to BR
		value = value?.trim()?.replace(/(?:\r\n|\r|\n)/gm, '<br />');
		value = removeMtWrappers(value);
	} else {
		// convert new lines/row to BR
		value = value?.trim()?.replace(/(?:\r\n|\r|\n)/gm, '<br />');
		// remove merge tags wrapper method
		value = removeMtWrappers(value);
		// revert back BR to new lines/row
		value = value?.trim()?.replace(/(?:<br \/>|<br\/>|<br>)/gm, '\n');
		// Normalize HTML symbols
		value = normalizeHTMLSymbols(value);
	}
	return value;
};
/**
 * Remove all Merge Tag Wrappers, to use for PDF
 * @param content string: html content or innerHtml
 * @returns string: content cleaned up
 */
export const removeMtWrappers = (content = '') => {
	const id = 'removeMtWrappers';
	const newHtml = document
		.createRange()
		.createContextualFragment(`<div id="${id}">${content}</div>`);

	newHtml.querySelectorAll(`var`).forEach((e: HTMLElement) => {
		if (e?.innerHTML !== '') {
			e.after(document.createRange().createContextualFragment(e?.innerHTML));
		}
		e.remove();
	});

	newHtml.querySelectorAll(`section`).forEach((e: HTMLElement) => {
		if (e?.innerHTML !== '' && !e.classList?.contains('d-none')) {
			e.after(document.createRange().createContextualFragment(e?.innerHTML));
		}
		e.remove();
	});

	return newHtml.querySelector(`#${id}`).innerHTML || '';
};

/**
 * Remove all custom notes boxes
 * @param content string: html content or innerHtml
 * @returns string: content cleaned up
 */
export const removeCustomNotes = (content = '') => {
	const id = 'removeCustomNotes';
	const newHtml = document
		.createRange()
		.createContextualFragment(`<div id="${id}">${content}</div>`);

	// span
	newHtml.querySelectorAll(`#${id} .custom-notes`).forEach((e: HTMLElement) => {
		if (e?.innerHTML !== '') {
			e.after(document.createRange().createContextualFragment(e?.innerHTML));
		}
		e.remove();
	});

	// table
	newHtml.querySelectorAll(`#${id} table`).forEach((e: HTMLElement) => {
		// Tbody TD Length
		const tdLen = Array.from(e.querySelectorAll('tbody tr'))?.reduce(
			(a, c: HTMLTableRowElement) => {
				const cols = c?.querySelectorAll('td')?.length || 0;
				return +cols > a ? cols : a;
			},
			0
		);
		// THead TD Length
		const thLen = Array.from(e.querySelectorAll('thead tr'))?.reduce(
			(a, c: HTMLTableRowElement) => {
				const cols = c?.querySelectorAll('th')?.length || 0;
				return +cols > a ? cols : a;
			},
			0
		);
		if (tdLen === 1 && thLen === 0) {
			if (e?.innerHTML !== '') {
				e.after(document.createRange().createContextualFragment(e?.innerHTML));
			}
			e.remove();
		}
	});

	return newHtml.querySelector(`#${id}`).innerHTML || '';
};

/**
 * Remove last occurence of paragraph with BR
 * @param content string: html content or innerHtml
 * @returns string: content cleaned up
 */
export const removeLastEmptyParagraph = (content: string) => {
	return (
		content?.replace(/<p><br><\/p>(?![\s\S]*<p><br><\/p>)(?![\s\S]*<p>)/, '') ||
		''
	);
};

/**
 * Replace all symbols converted to html
 * @param content Html or text
 * @returns string
 */
export const normalizeHTMLSymbols = (content = '') => {
	return content
		.replace(/&amp;/gm, '&')
		.replace(/&lt;/gm, '<')
		.replace(/&gt;/gm, '>')
		.replace(/&quot/gm, '"');
};

/**
 * Filters non email merge tags
 * @param mergeTags: List of Merge Tag Objects <Array[]>
 * @param includeMergeTags: Other merge tags to include
 * @returns mergeTags: List of Merge Tag Objects <Array[]>
 */
export const filterNonEmailMergeTags = (mergeTags$, includeMergeTags?) => {
	const includesOnly = [
		{ key: 'DATE_TODAY', exact: true },
		{ key: 'BUSINESS', exact: false },
		{ key: 'ADVISER', exact: false },
		{ key: 'PEOPLE_NAME', exact: true },
		{ key: 'PEOPLE_FIRST_NAME', exact: true },
		...(includeMergeTags || []),
	];

	return mergeTags$?.pipe(
		map((tags: MergeTagState[]) =>
			tags?.filter((tag) =>
				includesOnly.find((include) =>
					include.exact
						? tag.metaKey === include.key
						: tag.metaKey.split('_')[0] === include.key
				)
			) || []
		)
	);
};

/**
 * merge all non html string content tags and merge tags data
 */
export const mtMergeContent = (
	content: string,
	tags: any[],
	// when string replacing merge tag to content tag
	// by default we will include span tag (see. tagToString function)
	addHTMLTag = true
) => {
	/**
	 * - get only the tag that are in template to remove unnecessary iteration
	 * - match all uppercased string inside %
	 */
	const contentTags = content.match(/[A-Z0-9_]*(?=%)(.*?)(?:[A-Z0-9_])%/g);
	if (!contentTags?.length) {
		return content;
	}
	// convert merge tag to key value pair
	const tagsKeyValue = tagToKeyValuePair(tags);
	contentTags?.forEach((tag) => {
		// remove percent sign
		const key = tag.substring(1, tag.length - 1);
		const value = addHTMLTag
			? tagToStringWithHTMLTag(tagsKeyValue[key])
			: tagToStringWithoutHTMLTag(tagsKeyValue[key]);
		// @ts-ignore-next
		content = content.replaceAll(tag, value);
	});
	return content;
};

const toArray = (value: string | string[]): string[] => {
	if (Array.isArray(value)) {
		return value;
	}
	return R.isNil(value) ? [''] : [value];
};

const tagToStringWithHTMLTag = (tag: any): string => {
	if (R.isNil(tag)) {
		return '';
	}
	switch (tag.type) {
		case 'I': // image
			return tagToImg(tag);
		case 'L': // link
			return tagToLink(tag);
		case 'R': // reference
			return referenceToString(tag);
		default:
			return `<span data-mergetag="${
				tag?.['metaKey']
			}" class="${mtagOrigClass}">${toArray(tag?.['value'])?.filter(a => !R.isNil(a))?.join(
				', '
			)}</span>`;
	}
};

const tagToStringWithoutHTMLTag = (tag: any): string => {
	if (!tag) {
		return '';
	}
	switch (tag.type) {
		case 'I': // image
			return tagToImg(tag);
		case 'L': // link
			return tagToLink(tag);
		case 'R': // reference
			return referenceToString(tag);
			default:
				return toArray(tag?.['value'])?.filter(a => Boolean(a))?.join(', ');
		}
	};

const tagToImg = (tag: any): string => {
	return toArray(tag.value)?.reduce((prev, cur) => {
		return (
			prev +
			`<img src="${cur}" class="mtag ${mtagOrigClass}" data-mergetag="${tag?.metaKey}">`
		);
	}, '');
};

const tagToLink = (tag: any): string => {
	return toArray(tag.value).reduce((prev, cur) => {
		return (
			prev +
			`<a href="${cur}" target="_blank" class="mtag ${mtagOrigClass}" data-mergetag="${tag?.metaKey}">${cur}</a>`
		);
	}, '');
};

const referenceToString = (tag: any): string => {
  if (!tag.value || !Array.isArray(tag.value)) {
    return tag.referenceId ?? '';
  }
	return tag.value?.reduce((prev, cur) => {
		prev.push(cur.referenceId);
		return prev;
	}, [] as string[])?.filter(a => Boolean(a))?.toString();
};

// convert mergetags array into key value pair
// key = mergetag metakey and value is the mergetag object
// this will reduce iteration when merging contents
const tagToKeyValuePair = (mergeTags: any[]): { [key: string]: any } =>
	mergeTags.reduce((prev, cur) => {
		prev[cur.metaKey] = cur;
		return prev;
	}, {} as { [key: string]: any });
