import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchV2Component } from './global-search-v2.component';
import { ManualLoaderMiniModule } from '@shared/loaders/manual-loader-mini/manual-loader-mini.module';
import { FormsModule } from '@angular/forms';
import { GlobalSearchV2RoutingModule } from './global-search-v2-routing.module';
import { GlobalSearchV2Service } from './global-search-v2.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [GlobalSearchV2Component],
	exports: [GlobalSearchV2Component],
	imports: [
		CommonModule,
		GlobalSearchV2RoutingModule,
		ManualLoaderMiniModule,
		FormsModule,
		MatIconModule
	],
	providers: [GlobalSearchV2Service],
})
export class GlobalSearchV2Module {}
