import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, JsonResultStatus } from '../../base/api.service';
import {
	ActivityComplete,
	ActivityDelete,
	PutActivityParam,
	PostActivityParam,
	ActivityModel,
	GetActivityParam,
	GetActivitiesResponse
} from '../../../shared/models/_general/activity.model';
import { objectUtil } from 'src/app/util/util';

@Injectable()
export class ActivityService {
	constructor(private api: ApiService) { }

	GetById(id: number): Observable<ActivityModel> {
		const endpoint = `activities/${id}`;
		return this.api.get<ActivityModel>(endpoint);
	}

	Get(request: GetActivityParam): Observable<GetActivitiesResponse> {
		const endpoint = 'widget/GetActivitiesWidget';
		return this.api.post3<GetActivitiesResponse>(endpoint, request);
	}

	MarkComplete(request: ActivityComplete): Observable<JsonResultStatus> {
		const endpoint = 'activity/CompleteActivity';
		return this.api.post3<JsonResultStatus>(endpoint, request);
	}

	Delete(request: ActivityDelete): Observable<JsonResultStatus> {
		const endpoint = `activities/${request.activityId}`;
		return this.api.delete<JsonResultStatus>(endpoint);
	}

	Put(request: PutActivityParam | any): Observable<string> {
		const endpoint = `activities/${request.ActivityId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(request);
		return this.api.put(endpoint, body);
	}

	Post(request: PostActivityParam): Observable<number> {
		const body = objectUtil.mapCamelCaseToPascalCase(request);
		return this.api.post3<number>('activities', body);
	}

	QuickAddPost(request: PostActivityParam): Observable<number> {
		const body = objectUtil.mapCamelCaseToPascalCase(request);
		return this.api.post3<number>('activities/quick-add', body);
	}

	Cancel(request: PutActivityParam): Observable<JsonResultStatus> {
		const endpoint = `activities/${request.ActivityId}`;
		return this.api.put<JsonResultStatus>(endpoint, request);
	}

	CancelActivityTimeline(request: PutActivityParam): Observable<JsonResultStatus> {
		const endpoint = `activities/${request.ActivityId}`;
		return this.api.put<JsonResultStatus>(endpoint, request);
	}
}
