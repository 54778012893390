<div class="container-fluid">
  <div id="gridContainer">
    <div class="row grid-row mb-2">
      <div class="col-sm-2 d-flex align-items-center">Disclosure Document</div>
      <ng-container *ngIf="data.StaffSettings?.DisclosureDocument && !showDisclosureDocFileTemplate">
        <div class="col-sm-4 d-flex align-items-center">
            <button type="button" class="btn btn-link" (click)="downloadFile(data.StaffSettings?.DisclosureDocument, disclosure.DocumentName)">
              {{disclosure.DocumentName}}
            </button>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="data.StaffSettings?.DisclosureDocument && !showDisclosureDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm mr-2" type="button" (click)="uploadModal('Disclosure')">
            Replace
          </button>
        </ng-container>
        <ng-container *ngIf="data.StaffSettings?.DisclosureDocument && !showDisclosureDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Disclosure', true)">
            Delete
          </button>
        </ng-container>

        <ng-container *ngIf="!data.StaffSettings?.DisclosureDocument || showDisclosureDocFileTemplate">
          <div class="btn-toolbar" role="toolbar">
            <div class="btn-group mr-2" role="group">
              <button
                class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Disclosure')">
                Upload
              </button>
            </div>

          </div>
        </ng-container>
      </div>
    </div>

    <!-- Declaration -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 d-flex align-items-center">Declaration Document</div>
      <ng-container *ngIf="data.StaffSettings?.DeclarationDocument && !showDeclarationDocFileTemplate">
        <div class="col-sm-4 d-flex align-items-center">
          <button type="button" class="btn btn-link" (click)="downloadFile(data.StaffSettings?.DeclarationDocument, declaration.DocumentName)">
            {{declaration.DocumentName}}
          </button>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="data.StaffSettings?.DeclarationDocument && !showDeclarationDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm mr-2" type="button" (click)="uploadModal('Declaration')">
            Replace
          </button>
        </ng-container>
        <ng-container *ngIf="data.StaffSettings?.DeclarationDocument && !showDeclarationDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Declaration', true)">
            Delete
          </button>
        </ng-container>

        <ng-container *ngIf="!data.StaffSettings?.DeclarationDocument || showDeclarationDocFileTemplate">
          <div class="btn-toolbar" role="toolbar">
            <div class="btn-group mr-2" role="group">
              <button
                class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Declaration')">
                Upload
              </button>
            </div>

          </div>
        </ng-container>
      </div>
    </div>

    <!-- Scope of Service -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 d-flex align-items-center">Scope of Service</div>
      <ng-container *ngIf="data.StaffSettings?.ScopeOfService && !showScopeOfServiceDocFileTemplate">
        <div class="col-sm-4 d-flex align-items-center">
            <button type="button" class="btn btn-link" (click)="downloadFile(data.StaffSettings?.ScopeOfService, disclosure.DocumentName)">
              {{scopeOfService.DocumentName}}
            </button>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="data.StaffSettings?.ScopeOfService && !showScopeOfServiceDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm mr-2" type="button" (click)="uploadModal('Scope of Service')">
            Replace
          </button>
        </ng-container>
        <ng-container *ngIf="data.StaffSettings?.ScopeOfService && !showScopeOfServiceDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Scope of Service', true)">
            Delete
          </button>
        </ng-container>

        <ng-container *ngIf="!data.StaffSettings?.ScopeOfService || showScopeOfServiceDocFileTemplate">
          <div class="btn-toolbar" role="toolbar">
            <div class="btn-group mr-2" role="group">
              <button
                class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Scope of Service')">
                Upload
              </button>
            </div>

          </div>
        </ng-container>
      </div>
    </div>


    <!-- Letter of Authority -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 d-flex align-items-center">Letter of Authority</div>
      <ng-container *ngIf="data.StaffSettings?.LetterOfAuthority && !showLetterOfAuthorityDocFileTemplate">
        <div class="col-sm-4 d-flex align-items-center">
            <button type="button" class="btn btn-link" (click)="downloadFile(data.StaffSettings?.LetterOfAuthority, letterOfAuthority.DocumentName)">
              {{letterOfAuthority.DocumentName}}
            </button>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="data.StaffSettings?.LetterOfAuthority && !showLetterOfAuthorityDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm mr-2" type="button" (click)="uploadModal('Letter of Authority')">
            Replace
          </button>
        </ng-container>
        <ng-container *ngIf="data.StaffSettings?.LetterOfAuthority && !showLetterOfAuthorityDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Letter of Authority', true)">
            Delete
          </button>
        </ng-container>

        <ng-container *ngIf="!data.StaffSettings?.LetterOfAuthority || showLetterOfAuthorityDocFileTemplate">
          <div class="btn-toolbar" role="toolbar">
            <div class="btn-group mr-2" role="group">
              <button
                class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Letter of Authority')">
                Upload
              </button>
            </div>

          </div>
        </ng-container>
      </div>
    </div>

    <!-- Business Overview -->
    <div class="row grid-row mb-2">
      <div class="col-sm-2 d-flex align-items-center">Business Overview</div>
      <ng-container *ngIf="data.StaffSettings?.BusinessOverview && !showBusinessOverviewDocFileTemplate">
        <div class="col-sm-4 d-flex align-items-center">
            <button type="button" class="btn btn-link" (click)="downloadFile(data.StaffSettings?.BusinessOverview, letterOfAuthority.DocumentName)">
              {{businessOverview.DocumentName}}
            </button>
        </div>
      </ng-container>
      <div class="col-sm-auto d-flex align-items-center">

        <ng-container *ngIf="data.StaffSettings?.BusinessOverview && !showBusinessOverviewDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm mr-2" type="button" (click)="uploadModal('Business Overview')">
            Replace
          </button>
        </ng-container>
        <ng-container *ngIf="data.StaffSettings?.BusinessOverview && !showBusinessOverviewDocFileTemplate">
          <button class="tap-btn tap-btn--default btn-sm" type="button" (click)="removeDocument('Business Overview', true)">
            Delete
          </button>
        </ng-container>

        <ng-container *ngIf="!data.StaffSettings?.BusinessOverview || showBusinessOverviewDocFileTemplate">
          <div class="btn-toolbar" role="toolbar">
            <div class="btn-group mr-2" role="group">
              <button
                class="tap-btn tap-btn--default btn-sm" type="button" (click)="uploadModal('Business Overview')">
                Upload
              </button>
            </div>

          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>