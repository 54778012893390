import { Component, Input } from '@angular/core';
import { DeclarationEmailSettingsMapper } from '@modules/crt-settings/declaration-settings/declaration-email-settings/state/declaration-email-settings.mapper';
import { MergeTagsMapper } from '@shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { convertUtil, objectUtil } from '@util/util';
import { isNil } from 'ramda';
import { combineLatest, Observable, iif, of } from 'rxjs';
import {
	filter,
	map,
	tap,
	mergeMap,
	take,
	withLatestFrom,
	concatMap,
	catchError,
	finalize,
} from 'rxjs/operators';
import { SOAEmailSettings } from '../state/soa-settings.model';
import { SoaSettingsService } from '../state/soa-settings.service';

@Component({
	selector: 'app-soa-email-settings',
	templateUrl: './soa-email-settings.component.html',
	styleUrls: ['./soa-email-settings.component.scss'],
})
export class SoaEmailSettingsComponent {
	@Input() staffId: number;
	@Input() emailSettings: SOAEmailSettings;
	@Input() mergeTags$: Observable<MergeTagState[]>;

	declarationEmailSettings$ = this.soaSettingsService.emailSettings$;
	isLoading = false;
	bodyContent = '<p></p>';
	bodyId: number;
	shortCodes: object;

	constructor(private soaSettingsService: SoaSettingsService) {}

	ngOnInit(): void {
		this.prepData();
	}

	prepData() {
		combineLatest([this.declarationEmailSettings$, this.mergeTags$])
			.pipe(
				filter(([data, mt]) => !!data && !!mt),
				tap(
					([data, mt]) =>
						(this.shortCodes = MergeTagsMapper.mapTagsForWysiwyg(mt))
				),
				tap(([data, mt]) => {
					this.bodyId = +data?.body < 1 ? 0 : +data?.body;
				}),
				map(([data, mt]) =>
					DeclarationEmailSettingsMapper.mapEmailSettingsData(data)
				),
				mergeMap((data) =>
					iif(
						() => isNil(data?.body),
						of(null),
						this.soaSettingsService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.soaSettingsService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				take(1)
			)
			.subscribe();
	}

	save(emailSettings: SOAEmailSettings): void {
		this.isLoading = true;
		of(emailSettings)
			.pipe(
				mergeMap((currentTemplate) => {
					return convertUtil.convertToBase64(currentTemplate?.bodyContent);
				}),
				withLatestFrom(of(this.bodyId)),
				map(([currentTemplate, bodyId]) =>
					SoaSettingsService.mapStaffEmailBody(currentTemplate, bodyId, 0)
				),
				concatMap((data) => {
					return isNil(data.documentId) || data.documentId < 1
						? this.soaSettingsService
								.newFileUploadSOA(data)
								.pipe(catchError((err) => of(err)))
						: this.soaSettingsService.updateFileUploadSOA(data);
				}),
				withLatestFrom(of(this.bodyId)),
				concatMap(([data, body]) => {
					const isNewBody = body === 0 || !body;
					const newBodyId = isNewBody ? data : body;
					const formValue = SoaSettingsService.mapEmailSettingsToUpsert({
						...emailSettings,
						referenceId: 0,
						body: newBodyId,
					});
					this.bodyId = newBodyId;
					return this.soaSettingsService.updateEmailSettings(formValue);
				}),
				take(1),
				finalize(() => (this.isLoading = false))
			)
			.subscribe();
	}
}
