import * as R from 'ramda';
import {
	contentEditable,
	getContentWithMergeTags,
	removeMtWrappers,
} from '../../../../shared/converter/content-merge-tags';
import {
	convertAnnualToCustomFreq,
	convertToAnnual,
} from '../../../../modules/crm/client-review-template/income-budget/calculations/annual-conversion';
import { numUtil, objectUtil, util } from '../../../../util/util';
import {
	DefaultFileNames,
	DocumentTypes,
} from '../../documents/document.model';
import {
	createCurrentInsurancePremium,
	createProposedInsurancePremium,
	createProposedInsuranceStructure,
} from '../merge-tags/crt-lr-insurance/insurance/insurance.template';
import { MergeTagState } from '../merge-tags/merge-tags.model';
import { StatementOfAdviceState } from './statement-of-advice.model';
import { soaInsuranceMetaKey } from '../merge-tags/crt-lr-insurance/insurance/insurance.merge-tag';
import { appLoanSplitMetaKey } from '../merge-tags/crt-mortgage/structure-soa/structure-soa.merge-tag';
import { CurrentInsuranceState } from '../current-insurance/current-insurance.model';
import { PeopleState } from '../people/people.model';
import { DependentState } from '../dependent/dependent.model';
import { ProposedInsuranceState } from './insurance/soa-insurance.model';
import { curFreqType } from 'src/app/modules/crm/crt-page/_shared/calculations/annual-conversion';
import { loatRiskAnalysisPageIds } from '../merge-tags/crt-lr-insurance/risk-analysis/risk-analysis.merge-tags';

export const ProductsWithPerMonth: string[] = [
	'Income',
	'Income - Level',
	'Mortgage',
	'Mortgage - Level',
	'Household Expenses',
	'Income & Expenses',
	'Redundancy',
	'Business Overheads',
];


export const ProductsWithExcess: string[] = [
	'Medical Base Plan',
	'Specialists & Tests',
	'Medical',
	'Medical + S&T',
	'Dental & Optical',
];
export class StatementOfAdviceMapper {
	public static mapSoaWordings(data: MergeTagState[] = []) {
		return data?.map((item) =>
			item?.metaKey?.includes('SOA_')
				? {
						...item,
						value: [
							removeMtWrappers(
								getContentWithMergeTags(item?.value || '', data)
							) || '',
						],
				  }
				: item
		);
	}

	public static convertCIFrequency(data = [], frequency: curFreqType) {
		// Convert Current Insurance Frequency
		const convert = (amt = 0, freq) => {
			const res = +convertToAnnual(+amt, freq);
			return +convertAnnualToCustomFreq(res, frequency);
		};

		const parseData = (x) => {
			return util.tryCatchParse(x)
				? JSON.parse(x)?.map(objectUtil.mapPascalCaseToCamelCase)
				: x;
		};

		return data?.map((x) => {
			const freq = x?.premiumFrequency || 'Monthly';
			return {
				...x,
				totalPremium: this.isValidNumber(x?.totalPremium)
					? convert(x?.totalPremium, freq)
					: x?.totalPremium || ' ',
				policyFee: this.isValidNumber(x?.policyFee)
					? convert(x?.policyFee, freq)
					: x?.policyFee || ' ',
				lifeAssured:
					parseData(x?.lifeAssured)?.map((i) => {
						return {
							...i,
							premium:
								i?.premium && this.isValidNumber(i?.premium)
									? convert(i?.premium, freq)
									: i?.premium || ' ',
						};
					}) || [],
			};
		});
	}

	public static getTotalPolicyFee(data = []) {
		const total = data?.reduce((a, c) => {
			const val = +c?.policyFee || 0;
			return a + val;
		}, 0);
		return numUtil.formatToCurrency(total);
	}

	public static getTotalPremium(data = []) {
		const total = data?.reduce((a, c) => {
			const val = +c?.totalPremium || 0;
			return a + val;
		}, 0);
		return numUtil.formatToCurrency(total);
	}

	public static isValidNumber(value: string | number) {
		return (
			R.complement(R.either(R.isNil, R.isEmpty))(value) &&
			numUtil.isNumber(+value)
		);
	}

	public static parseData(data: string) {
		return util.tryCatchParse(data)
			? JSON.parse(data)?.map(objectUtil.mapPascalCaseToCamelCase)
			: data || [];
	}

	public static formatBenefit(data) {
		const benefit = this.isValidNumber(data?.benefit)
			? +data?.benefit
			: data?.benefit || '';

		return data &&
			[
				'Medical Base Plan',
				'Specialists & Tests',
				'Medical',
				'Medical + S&T',
				'Dental & Optical',
			].includes(data?.product)
			? `${numUtil.formatWholeNumNoDecimal(benefit)} excess`
			: `${numUtil.formatWholeNumNoDecimal(benefit)}${
					ProductsWithPerMonth.includes(data.product) ? '/month' : ''
			  }`;
	}

	public static groupByLifeAssured(
		insuranceList: any[],
		lifeAssuredPeople: any[],
		people?: PeopleState[],
		dependants?: DependentState[]
	) {
		if (!insuranceList || !lifeAssuredPeople) {
			return [];
		}
		const setName = (id) =>
			lifeAssuredPeople?.find((i) => +i?.value === +id)?.display;

		const insurances =
			(insuranceList?.reduce((a, c) => {
				const list = [];
				this.parseData(c?.lifeAssured)?.forEach((item) => {
					list.push({
						...item,
						benefit: this.formatBenefit(item),
						provider: c?.provider,
					});
				});
				return [...a, ...list];
			}, []) as any[]) || [];

		const peopleIds = this.orderPeople(people, dependants, insurances);
		const newList = peopleIds?.reduce((a, c) => {
			const lifeAssuredList =
				insurances?.filter((x) => +x?.lifeAssured === +c) || [];
			if (lifeAssuredList?.length > 0) {
				const data = {
					lifeAssured: +c,
					lifeAssuredName: setName(c) || '',
					lifeAssuredList,
				};
				return [...a, data];
			}
			return a;
		}, []);

		return newList || [];
	}

	public static orderPeople(
		people: PeopleState[] = [],
		dependants: DependentState[] = [],
		insurances: any[] = []
	) {
		// Order CustomerIDs based on:
		// CRT People > CRT Dependants > CRM people
		const crtPeople = R.uniq(
			R.pluck('customerId', [...(people || []), ...(dependants || [])])
		);
		const otherLifeAssured =
			R.uniq(R.pluck('lifeAssured', insurances || []))?.sort(
				(a, b) => +a - +b
			) || [];
		const ids = R.uniq([...crtPeople, ...otherLifeAssured]);

		return ids;
	}

	public static generateProposedInsuranceStucture(
		insuranceList: ProposedInsuranceState[],
		lifeAssuredPeople: any[],
		people?: PeopleState[],
		dependants?: DependentState[]
	) {
		const data = this.groupByLifeAssured(
			insuranceList,
			lifeAssuredPeople,
			people,
			dependants
		);
		const insuranceTables = [];
		const BR = '<br />';
		const placeholder = '<p>&nbsp;</p>';

		data?.forEach((i) => {
			const peopleName =
				people?.find((x) => +x?.customerId === +i?.lifeAssured)?.name ||
				i?.lifeAssuredName;
			const td = createProposedInsuranceStructure.td(i?.lifeAssuredList);
			const table = createProposedInsuranceStructure.table(td);
			const lifeAssuredName = `<p><strong>${peopleName}:</strong></p>`;

			insuranceTables.push(`${lifeAssuredName}${table}${BR}`);
		});

		const content = insuranceTables?.join('')?.toString();

		return `<div ${contentEditable.false} id="${
			soaInsuranceMetaKey.proposedInsuranceStructure
		}">${insuranceList?.length > 0 ? content : placeholder}</div>`;
	}

	public static generateProposedInsurancePremium(
		insuranceList: ProposedInsuranceState[],
		lifeAssuredPeople: any[],
		people: PeopleState[] = [],
		dependants: DependentState[] = [],
		frequency: curFreqType
	) {
		const data = this.groupByLifeAssured(
			insuranceList,
			lifeAssuredPeople,
			people,
			dependants
		);
		const insuranceTables = [];
		const BR = '<br />';
		const placeholder = '<p>&nbsp;</p>';

		data?.forEach((i) => {
			const peopleName =
				people?.find((x) => +x?.customerId === +i?.lifeAssured)?.name ||
				i?.lifeAssuredName;
			const td = createProposedInsurancePremium.td(i?.lifeAssuredList);
			const table = createProposedInsurancePremium.table(td);
			const lifeAssuredName = `<p><strong>${peopleName}:</strong></p>`;

			insuranceTables.push(`${lifeAssuredName}${table}${BR}`);
		});

		const totalPremium = createProposedInsurancePremium.totalTable({
			policyFee: this.getTotalPolicyFee(insuranceList) || '$0.00',
			totalPremium: this.getTotalPremium(insuranceList) || '$0.00',
			paymentFrequency: frequency || '',
		});

		const content = `${insuranceTables?.join('')?.toString()}${totalPremium}`;

		return `<div ${contentEditable.false} id="${
			soaInsuranceMetaKey.proposedInsurancePremium
		}">${insuranceList?.length > 0 ? content : placeholder}</div>`;
	}

	public static generateCurrentInsurancePremium(
		insuranceList: CurrentInsuranceState[],
		lifeAssuredPeople: any[],
		people: PeopleState[] = [],
		dependants: DependentState[] = [],
		frequency: curFreqType
	) {
		const newData = this.convertCIFrequency(insuranceList, frequency) || [];
		const data = this.groupByLifeAssured(
			newData,
			lifeAssuredPeople,
			people,
			dependants
		);
		const insuranceTables = [];
		const BR = '<br />';
		const placeholder = '<p>&nbsp;</p>';

		data?.forEach((i) => {
			const peopleName =
				people?.find((x) => +x?.customerId === +i?.lifeAssured)?.name ||
				i?.lifeAssuredName;
			const td = createCurrentInsurancePremium.td(i?.lifeAssuredList);
			const table = createCurrentInsurancePremium.table(td);
			const lifeAssuredName = `<p><strong>${peopleName}:</strong></p>`;

			insuranceTables.push(`${lifeAssuredName}${table}${BR}`);
		});

		const totalPremium = createCurrentInsurancePremium.totalTable({
			policyFee: this.getTotalPolicyFee(newData) || '$0.00',
			totalPremium: this.getTotalPremium(newData) || '$0.00',
			paymentFrequency: frequency || '',
		});

		const content = `${insuranceTables?.join('')?.toString()}${totalPremium}`;

		return `<div ${contentEditable.false} id="${
			soaInsuranceMetaKey.currentInsurancePremium
		}">${insuranceList?.length > 0 ? content : placeholder}</div>`;
	}

	public static generateNoCurrentInsuranceCover(hasFCLNO: boolean): string {
		const ciNoCoverText = hasFCLNO ? 'We discussed existing insurance and you confirmed you had no current insurance cover' : `&nbsp;`

		return `<div ${contentEditable.false} id="${
				soaInsuranceMetaKey.currentInsuranceNoCover
			}"><p>${ciNoCoverText}</p></div>`;
	}

	public static mapDocumentUpload(document, cRTId: number, docId: number) {
		if (+docId === 0 || isNaN(docId) || R.isNil(docId)) {
			return {
				document,
				referenceId: cRTId,
				fileName: DefaultFileNames.SOA,
				type: DocumentTypes.SOA,
			};
		}
		return {
			document,
			documentId: +docId,
		};
	}

	public static mapToUpsert(
		data: StatementOfAdviceState
	): StatementOfAdviceState {
		return {
			...data,
			sectionCode: 'SOA',
		};
	}

	public static hideLoanStructureFields(content: string) {
		const id = 'hideLoanStructureFields';
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${id}">${content}</div>`);

		let classList = [];
		newHtml
			.querySelectorAll('tr[class^="LOAN_STRUCTURE"]')
			.forEach((e: HTMLElement) => {
				const classes = Array.from(e?.classList);
				classList.push({
					key: classes,
					type: e.children[0].textContent,
					value: e.children[1].textContent,
				});

				const arr = classList
					.map((item) => item.key)
					.reduce((acc, val) => acc.concat(val), []);

				let display = '';

				if (
					arr.includes(appLoanSplitMetaKey?.loanType) &&
					arr.includes(appLoanSplitMetaKey?.interestOnlyPeriod)
				) {
					if (classList[0].value !== 'Interest Only') {
						display = 'none';
					}

					classList = [];
				}
				e.style.display = display;
			});
		classList = [];
		return newHtml.querySelector(`#${id}`).innerHTML || '';
	}

	public static isTemplateEmpty(content: string) {
		const id = 'isTemplateEmpty';
		const newHtml = document
			.createRange()
			.createContextualFragment(`<div id="${id}">${content || ''}</div>`);
		const isTemplateEmpty = !newHtml
			.querySelector(`#${id}`)
			?.textContent?.trim();

		return isTemplateEmpty;
	}

	/**
	 * Removes paragraphs with nbsp only on LOAT > Risk Analysis > Notes pages
	 * @param content string: html content or innerHtml
	 * @returns string: content cleaned up
	 */
	public static removeExtraNbspLoatRAPages(content: string) {
		const id = 'removeEmptyLoatRaNotes';
		const newHtml = document
			.createRange()
			.createContextualFragment(
				`<div id="${id}">${content || ''}</div>`
			);
		const ids = loatRiskAnalysisPageIds?.map((x) => `#${x} p`)?.join(', ');
		newHtml.querySelectorAll(`${ids}`).forEach((e: HTMLElement) => {
			if (e.innerHTML === '&nbsp;') {
				e.remove();
			}
		});
	
		return newHtml.querySelector(`#${id}`)?.innerHTML || '';
	};
}
