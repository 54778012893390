import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Pipe({
  name: 'momentDatetimeShort',
})
export class MomentDatetimeShortPipe implements PipeTransform {
  transform(value: Moment): any {
    try {
      return moment(value).format('MMM DD')
    } catch (error) {
      return '';
    }
  }
}
