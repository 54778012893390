<div class="row tap-container pt-3">
	<div class="col">
		<tabset [appTheme]="{ color: 'primarycolor' }">
			<tab heading="Services">
				<app-kiwi-sos-service-settings></app-kiwi-sos-service-settings>
			</tab>
			<tab heading="Scope of Service PDF">
				<app-kiwi-sos-template-settings></app-kiwi-sos-template-settings>
			</tab>
		</tabset>
	</div>
</div>
