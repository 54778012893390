import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MedicalStore, MedicalState } from './medical.store';

@Injectable({ providedIn: 'root' })
export class MedicalQuery extends QueryEntity<MedicalState> {

  constructor(protected store: MedicalStore) {
    super(store);
  }
}
