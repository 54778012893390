import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { EmailsComponent } from './emails.component';
import { EmailsRoutingModule } from './emails-routing.module';
import { EmailHistoryComponent } from './email-history/email-history.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { EmailStatusComponent } from './email-status/email-status.component';
import { SendManualEmailComponent } from './send-manual-email/send-manual-email.component';
import { SendTestEmailComponent } from './send-test-email/send-test-email.component';
import { EmailStatusFormComponent } from './email-status/email-status-form/email-status-form.component';
import { SendManualEmailFormComponent } from './send-manual-email/send-manual-email-form/send-manual-email-form.component';

import { SendManualEmailService } from './send-manual-email/send-manual-email.service';
import { SendTestEmailFormComponent } from './send-test-email/send-test-email-form/send-test-email-form.component';

import { SendTestEmailService } from './send-test-email/state/send-test-email.service';
import { SendTestEmailQuery } from './send-test-email/state/send-test-email.query';
import { SendTestEmailStore } from './send-test-email/state/send-test-email.store';
import { EmailHistoryFormComponent } from './email-history/email-history-form/email-history-form.component';

import { EmailHistoryResolver } from './email-history/email-history.resolver';
import { EmailHistoryService } from './email-history/state/email-history.service';
import { EmailHistoryQuery } from './email-history/state/email-history.query';
import { EmailHistoryStore } from './email-history/state/email-history.store';
import { BusinessEmailSettingsComponent } from './business-email-settings/business-email-settings.component';
import { EmailSettingsService } from './email-settings/state/email-settings.service';
import { EmailSettingsStore } from './email-settings/state/email-settings.store';
import { EmailSettingsQuery } from './email-settings/state/email-settings.query';
import { EmailSettingsFormComponent } from './email-settings/email-settings-form/email-settings-form.component';

import { BusinessEmailSettingsFormComponent } from './business-email-settings/business-email-settings-form/business-email-settings-form.component';
import { CRMMatTableModule } from '../../shared/mat-table/mat-table.module';

import { BusinessEmailSettingsService } from './business-email-settings/business-email-settings.service';

import { EmailStatusResolver } from './email-status/email-status.resolver';
import { EmailStatusService } from './email-status/state/email-status.service';
import { EmailStatusQuery } from './email-status/state/email-status.query';
import { EmailStatusStore } from './email-status/state/email-status.store';
import { EmailSettingsResolver } from './email-settings/email-settings.resolver';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { MatTableModule } from '@angular/material/table';
import { EmailTemplateModalModule } from '@shared/email-template-modal/email-template-modal.module';
import { EmailSignatureComponent } from './email-signature/email-signature.component';
import { InputModule } from '@shared/components/input/input.module';
import { InputSwitchComponent } from '@shared/components/input-switch/input-switch.component';
import { LoaderMiniModule } from '@shared/components/loader-mini/loader-mini.module';

@NgModule({
	imports: [
		CommonModule,
		EmailsRoutingModule,
		SharedModule,
		CRMMatTableModule,
		MatTableModule,
		EmailTemplateModalModule,
		InputModule,
		InputSwitchComponent,
		LoaderMiniModule,
	],
	declarations: [
		EmailsComponent,
		EmailHistoryComponent,
		EmailSettingsComponent,
		EmailStatusComponent,
		SendManualEmailComponent,
		SendTestEmailComponent,
		EmailStatusFormComponent,
		SendManualEmailFormComponent,
		BusinessEmailSettingsComponent,
		EmailHistoryFormComponent,
		SendTestEmailFormComponent,
		BusinessEmailSettingsComponent,
		EmailHistoryFormComponent,
		BusinessEmailSettingsComponent,
		BusinessEmailSettingsFormComponent,
		EmailSettingsFormComponent,
		EmailTemplateComponent,
		EmailSignatureComponent,
	],
	providers: [
		BusinessEmailSettingsService,

		EmailSettingsResolver,
		EmailSettingsStore,
		EmailSettingsQuery,
		EmailSettingsService,

		EmailStatusResolver,
		EmailStatusService,
		EmailStatusQuery,
		EmailStatusStore,

		SendManualEmailService,

		SendTestEmailService,
		SendTestEmailQuery,
		SendTestEmailStore,

		EmailHistoryResolver,
		EmailHistoryService,
		EmailHistoryQuery,
		EmailHistoryStore,
	],
})
export class EmailsModule {}
