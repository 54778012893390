import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CrtSettingsState, CrtSettingsStore } from './crt-settings.store';

@Injectable()
export class CrtSettingsQuery extends Query<CrtSettingsState> {
	/**
	 * @returns observable of Intro
	 */
	intro$ = this.select((state) => state.intro);

	/**
	 * @returns observable of Scope of Service
	 */
	scopeOfService$ = this.select((state) => state.scopeOfService);

	/**
	 * @returns observable of AuthorityToProceed
	 */
	authorityToProceed$ = this.select((state) => state.authorityToProceed);

	/**
	 * @returns observable of Declaration
	 */
	declaration$ = this.select((state) => state.declaration);

	/**
	 * @returns observable of Declaration Email
	 */
	declarationEmail$ = this.select((state) => state.declarationEmailSettings);

	/**
	 * @returns observable of Statement of Advice
	 */
	statementOfAdvice$ = this.select((state) => state.statementOfAdvice);

	/**
	 * @returns observable of Merge Tags
	 */
	mergeTags$ = this.select((state) => state.mergeTags);

	/**
	 * @returns observable of Final Structure
	 */
	finalStructure$ = this.select((state) => state.finalStructure);

	/**
	 * @returns observable of Client Acceptance
	 */
	clientAcceptance$ = this.select((state) => state.clientAcceptance);

	/**
	 * @returns observable of End Process Email Settings
	 */
	endProcessEmailSettings$ = this.select(
		(state) => state.endProcessEmailSettings
	);

	documentList$ = this.select((state) => state.documentList);

	soaEmailSettings$ = this.select((state) => state.soaEmailSettings);

	/**
	 * @returns observable of Client Fact Find Settings
	 */
	loatCffSettings$ = this.select(
		(state) => state.loatCffSettings
	);

	constructor(protected store: CrtSettingsStore) {
		super(store);
	}
}
