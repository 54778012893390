import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { BusinessConfigService } from './../../../../domain/business-config/business-config.service';

@Component({
	selector: 'app-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
	header = 'Add Business OAT Logo';
	data$ = this.businessConfigQuery.businessOATLogo$;
  loatFactFinderLogo$ = this.businessConfigQuery.businessOATLogo$; // TODO: update api for this
	isSaving$ = new Subject<boolean>();

	uploadPhoto$ = (req: { FileName: string; Photo: string }) =>
		this.uploadPhoto(req);

	constructor(
		private businessConfigQuery: BusinessConfigQuery,
		private businessConfigService: BusinessConfigService
	) {}

	ngOnInit() {}

	uploadPhoto(req) {
		return this.businessConfigService.updateBusinessOATLogo({ // TODO: update for api for fact finder cover
			...req,
			FileName: req?.FileName ?? null,
			Photo: !!req?.Photo ? req?.Photo?.replace(/^data:(.*,)?/, '') : null,
		});
	}
}
