import { Component } from '@angular/core';
import { ClientReferralSettingReferral } from '@modules/special-features/client-referral-setting/state/client-referral-setting.model';
import { ObservableUtil } from '@util/observable.util';
import { Observable, Subject, finalize, take } from 'rxjs';

@Component({
	selector: 'app-client-referral-link-modal',
	templateUrl: './client-referral-link-modal.component.html',
	styleUrls: ['./client-referral-link-modal.component.scss'],
})
export class ClientReferralLinkModalComponent {
	subject: Subject<ClientReferralSettingReferral>;

	saveFn$: (referral: ClientReferralSettingReferral) => Observable<boolean>;

	referral: ClientReferralSettingReferral;

	isSaving = false;

	constructor() {}

	close(): void {
		this.subject.next(null);
	}

	save(): void {
		if (this.isSaving) {
			return;
		}
		this.isSaving = true;
		this.saveFn$(this.referral)
			.pipe(
				take(1),
				finalize(() => (this.isSaving = false)),
				ObservableUtil.finalizeSuccess(() => {
					this.subject.next(this.referral);
				})
			)
			.subscribe();
	}
}
