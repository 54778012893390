<table class="table table-striped ui-tbl">
  <thead *ngIf="!hideTableHeader">
    <th class="absorber py-2 px-1 px-lg-2" width="5%">
      <label class="app-checkbox d-none">
        <input
					id="checkAllServices"
					type="checkbox"
					(change)="checkAll($event.target.checked)"
					[checked]="isAllChecked$ | async"
          [disabled]="disabled"
				>
        <span>&nbsp;</span>
      </label>
    </th>
    <th class="py-2 px-1 px-lg-2" width="95%">
      Service description
    </th>
  </thead>
  <tbody class="border-bottom">
    <tr *ngFor="let s of (model$ | async); let i = index;">
      <td class="py-0 px-1 text-center border-right">
        <label for="servicesOffered_{{i}}" class="pt-1 p-0 m-0 app-checkbox m-0">
          <input
						id="servicesOffered_{{i}}"
						type="checkbox"
						(change)="check(s.item.value, $event.target.checked, s)"
						[checked]="s.checked"
            [disabled]="disabled || isDisabled(s.item.value)"
					>
        </label>
      </td>
      <td class="p-2">
        <label
          for="servicesOffered_{{i}}">{{s.item.display.toLowerCase() === 'kiwisaver' ? 'KiwiSaver' : s.item.display}}</label>
      </td>
    </tr>
  </tbody>

</table>
