import { Injectable } from '@angular/core';
import { Model, ModelFactory } from '../../core/base/model.service';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { util } from '../../core/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class DateInputService {
  private model: Model<Moment>;
  public date$: Observable<Moment>;
  public get date() {
    return this.model.get();
  }

  constructor(private modelFactory: ModelFactory<Moment>) {
    this.model = this.modelFactory.createWithCustomClone(null, momentClone);
    this.date$ = this.model.data$;
  }
  set(mom: Moment) {
    this.model.set(mom);
  }
}

function momentClone(mom?: Moment) {
  return util.isValidMoment(mom) ? mom.clone() : null;
}
