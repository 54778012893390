<app-primary-base-widget headerText="Book Breakdown" subHeaderInlineText="(Last 12 Months)" [widgetCode]="widgetCode">
  <app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
  <div class="position-relative clrearfix h-100 scrollable-table">
    <app-simple-table
      [headerRow]="header"
      [rows]="viewData$ | async"
      class="position-relative h-100 d-block"
      style="min-width: 600px;"
    ></app-simple-table>
  </div>
</app-primary-base-widget>
