import { MatDataTableModel } from '@shared/models/_general/mat-data-table.model';

export const tableColumns: MatDataTableModel[] = [
	{
		columnId: 'FirstName',
		columnName: 'First Name',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: false,
		sticky: true,
		allowResize: true,
	},
	{
		columnId: 'LastName',
		columnName: 'Last Name',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'SecurityGroup',
		columnName: 'Security Group',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		showToolTip: true,
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'AdviserServices',
		columnName: 'Adviser Services',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		showToolTip: true,
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'BirthDate',
		columnName: 'Date of Birth',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'EmailAddress',
		columnName: 'Email Address',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		showToolTip: true,
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'MobilePhone',
		columnName: 'Mobile Phone',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'StartDate',
		columnName: 'Start Date',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'UserStatus',
		columnName: 'User Status',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'FSPNumber',
		columnName: 'FSP Number',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'Qualifications',
		columnName: 'Qualifications',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		showToolTip: true,
		visible: true,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'LastLogin',
		columnName: 'Last Login',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: true,
		draggable: true,
		allowResize: true,
	},
	// hidden columns below
	{
		columnId: 'ExitDate',
		columnName: 'Exit Date',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: false,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'ContractType',
		columnName: 'Contract Type',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: false,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'CommissionStructure',
		columnName: 'Commission Structure',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		showToolTip: true,
		visible: false,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'CorrespondenceEmail',
		columnName: 'Correspondence Address',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		showToolTip: true,
		visible: false,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'BusinessPhone',
		columnName: 'Business Phone',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		visible: false,
		draggable: true,
		allowResize: true,
	},
	{
		columnId: 'HomeAddress',
		columnName: 'Home Address',
		headerCellClass: '',
		bodyCellClass: '',
		type: 'route',
		showToolTip: true,
		visible: false,
		draggable: true,
		allowResize: true,
	},
];

/**
 * DEFAULTS
 */
const PAGE_SIZE = 10;
const PAGE_DIRECTION = 'asc';
const PAGE_COLUMN = 'Default';
const PAGE_INDEX = 1;
const COL_WIDTH = 150;
const DEFAULT_STATUS = 1;

const defaultSearchSort = {
	Column: PAGE_COLUMN,
	Direction: PAGE_DIRECTION,
};

const defaultSearchFilter = {
	FirstName: '',
	LastName: '',
	EmailAddress: '',
	CorrespondenceEmail: '',
	UserStatuses: [DEFAULT_STATUS],
	StartDateMin: null,
	StartDateMax: null,
	LastLoginMin: null,
	LastLoginMax: null,
	SecurityGroups: [],
	Paging: {
		Column: PAGE_COLUMN,
		Direction: PAGE_DIRECTION,
		// Index: PAGE_INDEX,
		// Size: PAGE_SIZE,
	},
};

export const defaultManageUserConfig = {
	direction: PAGE_DIRECTION,
	status: DEFAULT_STATUS,
	colWidth: COL_WIDTH,
	pageIndex: PAGE_INDEX,
	pageSize: PAGE_SIZE,
	sort: defaultSearchSort,
	filter: defaultSearchFilter,
};
