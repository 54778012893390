import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { uniqBy, prop } from 'ramda';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CrtMortgageStore, CrtMortgageState } from './crt-mortgage.store';

@Injectable()
export class CrtMortgageQuery extends Query<CrtMortgageState> {
	/**
	 * @returns observable object for loading of MOAT sections
	 */
	sectionsLoading$ = this.select((state) => state.sectionsLoading);
	/**
	 * @returns observable object of primaryClient
	 */
	primaryClient$ = this.select((state) => state.primaryClient);
	/**
	 * @returns observable object of secondary clients
	 */
	secondaryClients$ = this.select((state) => state.secondaryClients);
	/**
	 * @returns observable object of secondary trusts
	 */
	secondaryTrusts$ = this.select((state) => state.secondaryTrusts);
	/**
	 * @returns observable object of secondary companies
	 */
	secondaryCompanies$ = this.select((state) => state.secondaryCompanies);
	/**
	 * @returns observable object of secondary professional contacts
	 */
	 secondaryProfessionals$ = this.select((state) => state.secondaryProfessionals);

	sidebars$ = this.select((state) => state.sidebars);

	adviceProcessId$ = this.select((state) => state.adviceProcessId);
	adviceProcess$ = this.select((state) => state.adviceProcess);
	isCompany$ = this.select((state) => state.isCompany);

	mortApPageStarted$ = this.select((state) => state.mortApPageStarted);
	mortApPageCompleted$ = this.select((state) => state.mortApPageCompleted);

	intro$ = this.select((state) => state.intro);

	// Scope of Services
	scopeOfService$ = this.select((state) => state.scopeOfService);
	sosDefault$ = this.select((state) => state.sosDefault);
	sosTemplateSettings$ = this.select((state) => state.sosTemplateSettings);


	// Settings
	reviewApplicationSettings$ = this.select(
		(state) => state.reviewApplicationSettings
	);
	soaSettings$ = this.select((state) => state.soaSettings);

	/*
	* @returns observable of Merge Tags
	*/
	mergeTags$ = this.select((state) => state.mergeTags);

	declaration$ = this.select((state) => state.declaration);

	/**
	 * @returns observable list of people
	 */
	people$ = this.select((state) => state.people);

	/**
	 * @returns observable list of dependents
	 */
	dependents$ = this.select((state) => state.dependents);

	peopleAndDependentsFromCRTOnly$ = combineLatest([
		this.people$,
		this.dependents$,
	]).pipe(
		filter(([people, dependents]) => !!people && !!dependents),
		map(([people, dependents]) => {
			const list = uniqBy(prop('customerId') as any, [
				...people,
				...dependents,
			]);
			return list.sort((item1, item2) => item1.name?.localeCompare(item2.name));
		})
	);

	triggerLeaveSoa$ = this.select((state) => state.triggerLeaveSoa);

	hasReviewApplicationChanges$ = this.select((state) => state.hasReviewApplicationChanges);

	reviewApplicationChangeValue$ = this.select((state) => state.reviewApplicationChangeValue);

	reviewApplicationShowModal$ = this.select((state) => state.reviewApplicationShowModal);
	appCurrentTab$ = this.select((state) => state.appCurrentTab);

	// ROA
	triggerLeaveROA$ = this.select((state) => state.triggerLeaveROA);
	roaCurrentTab$ = this.select((state) => state.roaCurrentTab);

	constructor(protected store: CrtMortgageStore) {
		super(store);
	}
}
