import { Directive, ElementRef, Renderer2, Input, OnInit, OnDestroy } from '@angular/core';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {
  constructor(
    private configQuery: BusinessConfigQuery,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }
  @Input('appTheme') styleTheme: any;

  subs: Subscription[] = [];
  ngOnInit(): void {
    const sub = this.configQuery.themeConfig$.subscribe(conf => {
      this.changeStyle(conf);
    });
  }


  changeStyle(conf: any) {

    // for each props in  style theme pair object
    Object.keys(this.styleTheme)?.forEach(styleName => {
      if (!conf) {
        this.renderer.removeStyle(this.el.nativeElement, styleName)
      } else {
        // change the style value
        this.renderer.setStyle(
          this.el.nativeElement,
          // of style named
          styleName,
          // to value from theme converted from name
          this.mapThemeToStyle(conf, this.styleTheme[styleName])
        );
      }
    });
  }
  mapThemeToStyle(config: any, themeName: string): string {
    return config[themeName];
  }
  ngOnDestroy(): void {
    this.subs?.forEach(x => x.unsubscribe());
    this.subs = [];
  }
}
