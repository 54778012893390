<div class="main-current-insurance p-4">
	<div class="form-row">
		<div class="column col-lg-12">
			<ng-container *ngIf="!(isLoadingCurrentInsurance$ | async)">
				<div
					class="form-row mb-2 p-2 p-lg-0 insurance-label-background d-md-none"
				>
					<div class="col-7 col-lg-3 mt-1 mt-lg-0 mb-lg-3">
						<label for="" class="font-weight-bold label-font tap-text-primary">
							Current Insurance
						</label>
					</div>
					<div class="col-5 col-lg-9 text-right iconShow">
						<button
							id="current_collapseButton"
							type="button"
							class="btn-toggle bg-white collapse-btn border-0 insurance-collapse-background"
						>
							<span *ngIf="elseMinusProvider; else elsePlusProvider">
								<i
									(click)="collapseMoreInsurance()"
									class="material-icons md-20 pt-1"
									>expand_more</i
								>
							</span>
							<ng-template #elsePlusProvider>
								<span>
									<i
										(click)="collapseLessInsurance()"
										class="material-icons md-20 pt-1"
										>expand_less</i
									>
								</span>
							</ng-template>
						</button>
					</div>
				</div>

				<div class="pb-2" id="collapseInsurance">
					<div
						class="statement-of-advice__header__title mb-3 mb-lg-2 pr-0 pr-lg-3"
					>
						<label class="mr-3" for="SOA_PaymentFrequency"
							>Payment Frequency
            </label>
						<label>
							<span [class.crt-custom-dropdown]="!(isAdviceProcessEnded$ | async)">
								<select
									id="SOA_PaymentFrequency"
									name="SOA_PaymentFrequency"
									class="d-inline-block form-control crt-form-control"
									(change)="updatePaymentFrequency()"
									[(ngModel)]="paymentFrequency"
                  [attr.disabled]="(isAdviceProcessEnded$ | async) ? '' : null"
								>
									<option
										*ngFor="let f of paymentFrequency$ | async"
										[value]="f.value"
									>
										{{ f.display }}
									</option>
								</select>
							</span>
						</label>
					</div>

					<div
						*ngFor="let insurance of currentInsurances$ | async; let i = index"
					>
						<div class="form-row mb-2">
							<div class="col-12 col-lg-11 mb-2">
								<label
									class="font-weight-bold label-font mb-2 tap-text-primary"
									>{{ insurance?.provider }}</label
								>
							</div>
							<div class="col-12 col-lg-1 mb-2 text-right">
								<ng-container
									*ngIf="!isLoading || isLoadingId !== insurance.cRTId"
								>
									<ng-container *ngIf="!(isAdviceProcessEnded$ | async) else viewOnly">
										<button
											id="current_editProductButton_{{ i }}"
											type="button"
											class="icon-btn w-auto h-auto px-1"
											(click)="editProduct(insurance)"
										>
											<i class="material-icons md-16"> edit </i>
										</button>
										<button
											id="current_deleteCurrentInsuranceButton_{{ i }}"
											type="button"
											class="icon-btn w-auto h-auto px-1"
											(click)="deleteCurrentInsurance(insurance.cRTId)"
										>
											<i class="material-icons md-16"> delete </i>
										</button>
									</ng-container>
									<ng-template #viewOnly>
										<button
											id="current_viewProductButton_{{ i }}"
											type="button"
											class="icon-btn w-auto h-auto px-1"
											(click)="viewProduct(insurance)"
										>
											<i class="material-icons md-16"> info </i>
										</button>
									</ng-template>
									<ng-container
										*ngIf="isLoading && isLoadingId === insurance.cRTId"
									>
										<app-manual-loader-mini
											[isLoading]="isLoading"
										></app-manual-loader-mini>
									</ng-container>
								</ng-container>
							</div>
						</div>
						<div class="form-row mb-2">
							<div class="col-12 col-lg-2 mb-2">
								<label class="font-weight-bold">Life Assured</label>
							</div>
							<div class="col-12 col-lg-3 mb-2">
								<label class="font-weight-bold">Product</label>
							</div>
							<div class="col-12 col-lg-3 mb-2">
								<label class="font-weight-bold">Sum Assured</label>
							</div>
							<div class="col-12 col-lg-3 mb-2">
								<label class="font-weight-bold">Premium</label>
							</div>
						</div>
						<div
							class="form-row mb-2"
							*ngFor="
								let p of insurance?.lifeAssuredList;
								let ip = index;
								let l = last
							"
						>
							<div class="col-12 col-lg-2 mb-2" [class.border-bottom]="!l">
								<input
									id="current_lifeAssured_{{ i }}"
									class="form-control crt-form-control"
									placeholder="Life Assured"
									type="text"
									[value]="p?.lifeAssuredName || ''"
									disabled
								/>
							</div>
							<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!l">
								<input
									id="current_product_{{ i }}"
									class="form-control crt-form-control"
									placeholder="Product"
									type="text"
									value="{{ p.product }}"
									disabled
								/>
							</div>
							<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!l">
								<ng-container *ngIf="p.allowDropdown; else showInput">
									<input
										id="current_benefit_{{ i }}"
										class="form-control crt-form-control"
										placeholder="Benefit"
										type="text"
										value="{{ p.benefit | currency }}{{
											p.allowDropdown ? ' excess' : ''
										}}"
										disabled
									/>
								</ng-container>
								<ng-template #showInput>
									<input
										id="current_benefit_{{ i }}"
										class="form-control crt-form-control"
										placeholder="Benefit"
										type="text"
										value="{{ p.benefit | currency }}{{
											p.showPerMonth ? '/month' : ''
										}}"
										disabled
									/>
								</ng-template>
							</div>
							<div class="col-12 col-lg-3 mb-2" [class.border-bottom]="!l">
								<div class="dollar-icon">
									<input
										id="current_premium_{{ i }}"
										class="form-control crt-form-control"
										placeholder="Premium"
										type="text"
										[(ngModel)]="p.premium"
										[ngModelOptions]="{ standalone: true }"
										currencyMask
										disabled
									/>
									<i>$</i>
								</div>
							</div>
							<div
								class="col-12 col-lg-1 mb-2"
								[class.border-bottom]="!l"
							></div>
						</div>

						<div class="form-row mb-2">
							<div class="col-0 col-lg-5"></div>
							<div
								class="col-12 col-lg-3 text-lg-right d-lg-flex justify-content-end align-items-center"
							>
								<label class="tap-text-primary font-weight-bold"
									>Policy Fee</label
								>
							</div>
							<div class="col-12 col-lg-3">
								<div class="dollar-icon">
									<input
										id="current_policyFee_{{ i }}"
										class="form-control crt-form-control"
										placeholder="Policy Fee"
										type="text"
										[(ngModel)]="insurance.policyFee"
										[ngModelOptions]="{ standalone: true }"
										currencyMask
										disabled
									/>
									<i>$</i>
								</div>
							</div>
						</div>
						<div class="form-row mb-2">
							<div class="col-0 col-lg-5"></div>
							<div
								class="col-12 col-lg-3 text-lg-right d-lg-flex justify-content-end align-items-center"
							>
								<label class="font-weight-bold tap-text-primary"
									>Total Premium</label
								>
							</div>
							<div class="col-12 col-lg-3">
								<div class="dollar-icon">
									<input
										id="current_premium_{{ i }}"
										appCurrencyFormatter
										class="form-control crt-form-control"
										placeholder="Premium"
										type="text"
										value="{{ insurance?.sumOfLifeAssured || 0 }}"
										disabled
									/>
									<i>$</i>
								</div>
							</div>
						</div>
						<div
							class="form-row mb-2"
							*ngIf="
								insurance?.policyDocumentsList &&
								insurance?.policyDocumentsList?.length > 0
							"
						>
							<label class="font-weight-bold label-font mb-3 tap-text-primary"
								>Policy Documents</label
							>
						</div>
						<div
							class="form-row"
							*ngFor="let d of insurance?.policyDocumentsList"
						>
							<div class="col-12 col-lg-auto word-break font-weight-bold mb-1">
								<div class="dollar-icon">
									<span
										class="ml-3 cursor-pointer"
										(click)="downloadLink(d.referenceId)"
										>{{ d.value }}</span
									>
									<i class="material-icons md-20 tap-text-color-h6 pr-5 pb-2"
										>description</i
									>
								</div>
							</div>
						</div>

						<div class="form-row line mb-3 mt-2"></div>
					</div>
				</div>
			</ng-container>

			<div class="form-row my-4">
				<div class="col-lg-4">
					<ng-container *ngIf="!(isAdviceProcessEnded$ | async)">
						<button
							id="current_addProductButton"
							type="button"
							class="tap-btn tap-btn-outline tap-btn--shadow col-auto pr-4 pl-4"
							(click)="addProduct()"
							[disabled]="isAddNew || (isLoadingCurrentInsurance$ | async)"
						>
							Add New +
						</button>
					</ng-container>
					<ng-container *ngIf="isLoadingCurrentInsurance$ | async as loading">
						<app-manual-loader-mini
							[isLoading]="loading"
						></app-manual-loader-mini>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
