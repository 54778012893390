import {
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { numUtil } from '@util/util';
import * as Chartist from 'chartist';
import { IBarChartOptions, IChartistData } from 'chartist';
import { ChartEvent, ChartType, ChartistComponent } from 'ng-chartist';
import * as R from 'ramda';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import '../../../assets/js/chartist-plugin-bar-label';

@Component({
	selector: 'app-vertical-bar-chart',
	templateUrl: './vertical-bar-chart.component.html',
	styleUrls: ['./vertical-bar-chart.component.scss'],
})
export class VerticalBarChartComponent implements OnInit, OnChanges, OnDestroy {
	static BAR_STROKE_WIDTH = 170;

	onDestroy$ = new Subject<void>();
	@Input() data: IChartistData = {
		series: [],
		labels: [],
	};
	@Input() otherOptions: IBarChartOptions;

	@Input() onDraw?: (data: any) => void;

	type: ChartType = 'Bar';
	events: ChartEvent;
	options: IBarChartOptions;
	primaryWidgetColor: string;

	/** Theme config object */
	themeConfig$ = this.businessConfigQuery.themeConfig$.pipe(filter((x) => !!x));

	currencyFormatter = numUtil.intlCurrencyFormatterFactory();

	constructor(
		private cd: ChangeDetectorRef,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	/**
	 * For Manual refresh. Chartist doesn't expose its renderChart method
	 * so we'll refresh the data instead
	 */
	public refresh() {
		this.data = R.clone(this.data);
		this.renderChart();
		this.cd.detectChanges();
	}

	ngOnInit(): void {
		this.options = {
			...this.options,
			...this.otherOptions,
		};
		this.themeConfig$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this.primaryWidgetColor = x.primarycolor;
		});
		this.renderChart();
	}

	ngOnChanges(): void {
		this.options = {
			...this.options,
			...this.otherOptions,
		};
	}

	renderChart() {
		this.options = {
			...this.options,
			plugins: [
				Chartist.plugins.ctBarLabels({
					customizeBarLabels: true,
					labelInterpolationFnc: (data) =>
						this.currencyFormatter.format(data?.value.y || 0),
				}),
			],
		};

		this.events = {
			draw: (data) => {
				if (data.type === this.type.toLocaleLowerCase()) {
					data.element.animate({
						y2: {
							dur: '0.5s',
							from: data.y1,
							to: data.y2,
							easing: 'easeOutQuad',
						} as Chartist.IChartistAnimationOptions,
					});

					const WINDOW_WIDTH = document.documentElement.clientWidth;
					const barWidth =
						WINDOW_WIDTH < 610
							? data.axisX.stepLength - 10 * 0.3
							: data.axisX.stepLength - 10 * 1.2;
					const style = `stroke-width: ${barWidth}px; stroke: ${this.primaryWidgetColor};`;
					data.element.attr({ style });
				}
				if (this.onDraw) {
					this.onDraw(data);
				}
			},
		};
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
