import { Moment } from 'moment';
import * as moment from 'moment';
import { staticConf } from '../core/config/static-config.service';
import { util } from '../core/util/util.service';

/** Util Object consisting of moment transformation */
export default class MomentUtil {

	/** Convert date instance to moment instance */
	public static readonly convertToMoment = (date: Date | string | number, format?: string): Moment => {
		return moment(date, format);
	}

	/** Convert date from server to display date format */
	public static readonly serverDateToDisplayDate = (date: string): string => {
		const momentDate = moment(date, staticConf.serverDateFormat);
		return MomentUtil.formatToDisplayDate(momentDate);
	}

  /** Get `moment` now in NZ timezone */
  public static readonly createMomentNz = () =>
    moment.tz(staticConf.momentTimezone);

  /** Get `moment` now in local timezone */
  public static readonly createMomentLocal = () =>
    moment.tz(moment.tz.guess());

  /** moment with no datetime. */
  public static readonly createEmptyMoment = () =>
    moment.invalid();

  /** Convert `moment` to current NZ time but in local timezone */
  public static readonly convertNzMomentToLocalMoment =
    (nzMoment: Moment) =>
      nzMoment
        .clone()
        .utcOffset(moment().utcOffset(), true)
        .tz(moment.tz.guess())

  /** Check if `moment` is Moment and is valid */
  public static readonly isValidMoment =
    (mom: Moment | any): mom is Moment =>
      (moment.isMoment(mom) && mom.isValid());

  /** Get `moment` in NZ but in local timezone */
  public static readonly formatToServerDateToMomentNowNz = () => MomentUtil.DatetimeStringToMoment(MomentUtil.formatToServerDate(MomentUtil.createMomentNz()))

  /** Get `moment` in NZ but in local timezone */
  public static readonly momentNowNz = () =>
    MomentUtil.convertNzMomentToLocalMoment(
      moment.tz(staticConf.momentTimezone)
    );

  /** Convert `moment` to string in server date format
   *    (currently YYYY-MM-DD)
   */
  public static readonly formatToServerDate =
    (mom: Moment): string => MomentUtil.isValidMoment(mom)
      ? mom.clone().format(staticConf.serverDateFormat)
      : null;

  /** Convert `moment` to string in server date & time format
   * (currently YYYY-MM-DDTHH:mm)
   */
  public static readonly formatToServerDatetime = (mom: Moment): string =>
    MomentUtil.isValidMoment(mom)
      ? mom.clone().format(staticConf.serverDatetimeFormat)
      : null;

  /** Convert `moment` to string in server date & time format
   * (currently YYYY-MM-DDTHH:mm:ss)
   */
  public static readonly formatToServerDatetimeSecond = (mom: Moment): string =>
    MomentUtil.isValidMoment(mom)
      ? mom.clone().format(staticConf.serverDatetimeSecondFormat)
      : null;

  /** Convert `moment` to string in display date format
   * (currently DD/MM/YYYY)
   */
  public static readonly formatToDisplayDate = (mom: Moment, format: string = staticConf.displayDateFormat): string =>
    MomentUtil.isValidMoment(mom) ? mom.clone().format(format) : '';

  /** Convert `moment` to string in display date format with no year
   * (currently DD/MM)
   */
  public static readonly formatToDisplayDateNoYear = (mom: Moment, format: string = staticConf.displayDateFormatNoYear): string =>
    MomentUtil.isValidMoment(mom) ? mom.clone().format(format) : '';

  /** Convert `moment` to string in display date & time format
   * (currently DD/MM/YYYY HH:mm)
   */
  public static readonly formatToDisplayDatetime = (mom: Moment): string =>
    MomentUtil.isValidMoment(mom)
      ? mom.clone().format(staticConf.displayDatetimeFormat)
      : '';

  /** Convert date string to `moment` (expected date format: YYYY-MM-DD)
   *
   */
  public static readonly DateStringToMoment = (date: string) =>
    moment(date, staticConf.serverDateFormat, false);

  /** Convert date & time string to `moment`
   * (expected datetime format: YYYY-MM-DDTHH:mm)
   */
  public static readonly DatetimeStringToMoment = (date: string): Moment =>
    moment(date, staticConf.serverDatetimeFormat, false);

  /**
   * Validate date
   * @param date date string
   * @param format format of date
   * @return boolean
   */
  public static readonly IsValidDateFormat = (date: string, format: string): boolean =>
    moment(date, format).isValid();

  /**
   * Format date to string YYYY-MM-DD
   * @param date string or Moment date
   */
  public static readonly formatDateToServerDate = (date: string | Moment): string => {
    return typeof date === 'string'
      ? MomentUtil.formatToServerDate(util.DateStringToMomentLoose(date))
      : MomentUtil.formatToServerDate(date);
  }

  /**
   * Format date to Moment
   * @param date string or Moment date
   */
  public static readonly formatDateToMoment = (date: string | Moment, format = staticConf.displayDateFormat): Moment => {
    return typeof date === 'string'
      ? util.DateStringToMomentLoose(date)
      : util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date, format));
  }

  /** Convert `moment` to string in server time format (24 hour format)
   * (currently HH:mm)
   */
  public static readonly formatToServerTime = (mom: Moment): string =>
    MomentUtil.isValidMoment(mom)
      ? mom.clone().format(staticConf.displayTimeOnlyFormat)
      : null;

  /**
   * Convert string server date to DD/MM/YYY format
   */
  public static readonly formatServerDateToDateDisplay = (date: string): string => {
		if (!date) {
			return '';
		}
    const d = moment(date, staticConf.serverDateFormat, false);
			return MomentUtil.isValidMoment(d)
      ? d.clone().format(staticConf.displayDateFormat)
      : '';
		}
}
