import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ExpenseDetailsState } from './expenses.model';

export interface ExpenseState {
	expenses: ExpenseDetailsState;
  // expenses form values
  formValue: boolean;
}

export function createInitialState(): ExpenseState {
	return {
		expenses: null,
    formValue: null
	}
}

@Injectable()
@StoreConfig({ name: 'expenseDetails', idKey: 'cRTId' })
export class ExpenseStore extends Store<ExpenseState> {

	constructor() {
		super(createInitialState());
	}

	setExpenseState(p: ExpenseDetailsState) {
		this.update({ expenses: p });
	}

  setFormValue(values: any): void {
    this.update({ formValue: values });
  }

}
