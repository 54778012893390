import { CurrencyPipe } from '@angular/common';
import {
  Directive,
  HostListener,
  Self,
  Input,
  ElementRef,
  Optional,
  OnInit
} from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Use this if CurrentMask directive from ngx-currency is not applicable/does not work on your form
 * @returns reformatted currency for amount fields to reflect on UI on blur/view only
 */

@Directive({
  selector: '[appCurrencyFormatter]',
})
export class CurrencyFormatterDirective implements OnInit {
  @Input()
  set maxDigits(maxDigits: number) {
    this.setRegex(maxDigits);
  }

  constructor(
    @Self() @Optional() private ngControl: NgControl,
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
    this.setRegex();
  }

  get isFocused() {
    return this.el.dataset.isFocused;
  }
  private digitRegex: RegExp;

  private el: HTMLInputElement;

  // variable to store last valid input
  private lastValid = '';
  // build the regex based on max pre decimal digits allowed
  private regexString(max?: number) {
    const maxStr = max ? `{0,${max}}` : `+`;
    return `^(\\d${maxStr}(\\.\\d{0,2})?|\\.\\d{0,2})`;
  }
  private setRegex(maxDigits?: number) {
    this.digitRegex = new RegExp(this.regexString(maxDigits), 'g');
  }

  ngOnInit() {
    this.updateValue(this.el.value);
  }

  updateValue(value) {
    if (this.el.value !== '') {
      this.el.value = this.currencyPipe
        .transform(value, 'USD')
        ?.replace('$', '');
    }
  }

  updateFocus(p) {
    this.el.dataset.isFocused = p;
  }

  @HostListener('focus', ['$event'])
  onFocus(event) {
    // on focus remove currency formatting
    if (this.el.readOnly || this.el.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.updateFocus(true);
      const val = `$${event.target.value}`;
      this.el.value = val?.replace(/[^0-9.]+/g, '');
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    // on blur, add currency formatting
    if (this.el.readOnly || this.el.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.updateFocus(false);
      this.updateValue(event.target.value);
    }
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(event: KeyboardEvent) {
    // on value change for reactive forms patchValue/setValue, add currency formatting
    if (this.ngControl && (!this.isFocused || this.isFocused === 'false')) {
      this.updateValue(this.ngControl.value);
    }
  }
  @HostListener('input', ['$event'])
  onInput(event) {
    // on input, run regex to only allow certain characters and format
    const cleanValue = (event.target.value?.match(this.digitRegex) || []).join(
      ''
    );
    if (cleanValue || !event.target.value) { this.lastValid = cleanValue; }
    this.el.value = cleanValue || this.lastValid;
  }
}
