<div class="user-detail-documents__onboarding container-fluid">
	<div class="row mt-4 mb-3">
		<div class="col-12">
			<span class="section-header tap-text-primary"
				>Onboarding Documents
				<app-manual-loader-mini [isLoading]="isLoading">
				</app-manual-loader-mini
			></span>
		</div>
	</div>
	<div class="row">
		<div
			class="col-12 col-md-4 col-lg-4 pr-4 mb-3"
			*ngFor="let sd of standardDocuments; let sdi = index"
		>
			<div
				class="container user-detail-documents__onboarding__boxes border"
				[ngClass]="{ 'has-file': !!sd?.StaffSettingsId }"
			>
				<div class="row border-bottom py-4 px-2">
					<div class="col-12">
						<span
							#docName
							class="d-block doc-name text-truncate"
							[tooltip]="
								isEllipsisActive(docName) ? sd.DocumentName || '' : null
							"
						>
							{{ sd.DocumentName || "" }}
						</span>
					</div>
					<div class="col-12">
						<span
							#fileName
							class="doc-file d-block w-100 text-truncate view-document clickable"
							(click)="viewDocument(sd)"
							*ngIf="!!sd?.StaffSettingsId"
							[tooltip]="!!sd.Documents?.[0]?.Value && isEllipsisActive(fileName) ? (sd.Documents?.[0]?.Value || '') : null"
							>{{ sd.Documents?.[0]?.Value || "" }}</span
						>
						<span *ngIf="!sd?.StaffSettingsId">&nbsp;</span>
					</div>
				</div>
				<div class="row py-4 px-2">
					<div class="col-12">
						<span
							class="doc-add clickable"
							*ngIf="!sd?.StaffSettingsId"
							(click)="addDocument(sd)"
							>Link Document</span
						>
						<span
							class="doc-update clickable"
							*ngIf="!!sd?.StaffSettingsId"
							(click)="replaceDocument(sd)"
							>Replace Document</span
						>
					</div>
				</div>
			</div>
		</div>

		<!-- Other Onboarding -->
		<div
			class="col-12 col-md-4 col-lg-4 pr-4 mb-3"
			*ngFor="let sd of otherDocuments; let sdi = index"
		>
			<div
				class="container user-detail-documents__onboarding__boxes border"
				[ngClass]="{ 'has-file': !!sd?.StaffSettingsId }"
			>
				<div class="row border-bottom py-4 px-2">
					<div class="col-12">
						<span
							#docName2
							class="d-block doc-name text-truncate"
							[tooltip]="
								isEllipsisActive(docName2) ? sd.DocumentName || '' : null
							"
						>
							{{ sd.DocumentName || "" }}
						</span>
						<span
							class="float-right doc-remove-others clickable"
							(click)="removeDocument(sd)"
							><i class="material-icons">close</i></span
						>
					</div>
					<div class="col-12">
						<span
							#fileName2
							class="doc-file d-block w-100 text-truncate view-document clickable"
							(click)="viewDocument(sd)"
							*ngIf="!!sd?.StaffSettingsId"
							[tooltip]="isEllipsisActive(fileName2) ? (sd.Documents?.[0]?.Value || '') : null"
							>{{ sd.Documents?.[0]?.Value || "" }}</span
						>
						<span *ngIf="!sd?.StaffSettingsId">&nbsp;</span>
					</div>
				</div>
				<div class="row py-4 px-2">
					<div class="col-12">
						<span
							class="doc-add clickable"
							*ngIf="!sd?.StaffSettingsId"
							(click)="addDocument(sd, true)"
							>Link Document</span
						>
						<span
							class="doc-update clickable"
							*ngIf="!!sd?.StaffSettingsId"
							(click)="replaceDocument(sd, true)"
							>Replace Document</span
						>
					</div>
				</div>
			</div>
		</div>

		<!-- Add -->
		<div class="col-12 col-md-4 col-lg-4 pr-4 mb-3" *appRestrictTo="['BEU']">
			<div
				class="container user-detail-documents__onboarding__boxes border add-onboarding add-file"
				(click)="addDocument(otherD, true)"
			>
				<div class="row">
					<div class="col-12 text-center">
						<span> + Add Onboarding<br />Document </span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
