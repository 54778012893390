import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/base/api.service';
import { mergeMap, withLatestFrom, map, finalize } from 'rxjs/operators';
import { BusinessConfigStore } from '../../../domain/business-config/business-config.store';
import { createBusinessConfig, BusinessConfig } from '../../../domain/business-config/business-config.model';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { of } from 'rxjs';

@Injectable()
export class BusinessEmailSettingsService {
	constructor(
		private api: ApiService,
		private businessConfigStore: BusinessConfigStore,
		private businessConfigQuery: BusinessConfigQuery
	) {}

	save(req, companyCode) {
		const endpoint = `businesses/${companyCode}`;
		let config;
		return of(req).pipe(
			withLatestFrom(this.businessConfigQuery.businessConfig$),
			map(([r, b]) => {
				config = { ...b, ...r };
				return config;
			}),
			mergeMap(x => this.api.put<BusinessConfig>(endpoint, x)),
			finalize(() =>
				this.businessConfigStore.update(() => ({
					config: createBusinessConfig(config),
				}))
			)
		);
	}

	getDocument(documentId) {
		return this.api.get<any>(`documents/${documentId}`);
	}

	uploadDocument(file) {
		return this.api.post('documents', file);
	}

	downloadLink(documentID) {
		return this.api.get<string>(`documents/download/${documentID}`);
	}
}
