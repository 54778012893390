import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { displayedColumns, tableColumns } from './manage-users-table-config';
import { SimpleDataTableModel } from '@shared/models/_general/simple-data-table.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserPdTaskModalComponent } from '@shared/modal/user-pd-task-modal/user-pd-task-modal.component';
import { DropdownValueService } from '@domain/dropdown-value/dropdown-value.service';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { Observable, combineLatest, iif, of } from 'rxjs';
import {
	BlStaffPdTypes,
	BlStaffSettingTypes,
} from '@shared/models/_general/bl-staff.model';
import { BLStaffSecGroupsQuery } from '@domain/bl-staff-security-group/bl-staff-security-groups.query';
import { viewSecGroup } from '@modules/user/viewmodels/viewSecGroup';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { UserQuery } from '@domain/user/user.query';
import { ManagePdTrackingService } from './states/manage-pd-tracking.service';
import { ComponentBase } from '@core/base/component-base';
import { util } from '@core/util/util.service';

const dropdownCodes: string[] = [
	'LRP',
	'SCS',
	'SCT',
	'SAS',
	'SQCKI',
	'UPDTP',
	'UPDTC',
	'UPDTS',
];

@Component({
	selector: 'app-manage-pd-tracking',
	templateUrl: './manage-pd-tracking.component.html',
	styleUrls: ['./manage-pd-tracking.component.scss'],
})
export class ManagePdTrackingComponent extends ComponentBase implements OnInit {
	dataSource: any[] = [];
	displayedDataColumns: string[] = [];
	tableDataColumns: SimpleDataTableModel[] = [];

	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');
	SCS$ = this.dropdownValueQuery.orderedChoices$('SCS');
	SCT$ = this.dropdownValueQuery.orderedChoices$('SCT');
	SQCKI$ = this.dropdownValueQuery.orderedChoices$('SQCKI');
	UPDTP$ = this.dropdownValueQuery.orderedChoices$('UPDTP');
	UPDTC$ = this.dropdownValueQuery.orderedChoices$('UPDTC');
	UPDTS$ = this.dropdownValueQuery.orderedChoices$('UPDTS');

	staffChoices$ = this.blStaffQuery.activeStaffs$;
	adviserIdsAssigned$ = this.userQuery.adviserIdsAssigned$;

	securityGroups = this.blStaffSecGroupQuery.getAll();

	securityGroups$: Observable<any>;

	isLoadingData = false;

	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		protected dropdownValueQuery: DropdownValueQuery,
		private dropdownValueService: DropdownValueService,
		private blStaffSecGroupQuery: BLStaffSecGroupsQuery,
		private blStaffQuery: BLStaffsQuery,
		private userQuery: UserQuery,
		private pdtService: ManagePdTrackingService
	) {
		super();
		this.displayedDataColumns = displayedColumns;
	}

	ngOnInit(): void {
		this.dropdownValueService
			.getDropdownValueList$(dropdownCodes)
			.pipe(take(1))
			.subscribe();

		this.isLoadingData = true;

		this.tableDataColumns = tableColumns.map((x) => ({
			...x,
			actionCallback: (pd: any) => this.editPD(pd),
			actionCallbackTwo: (pd: any) => this.retractPD(pd),
			actionCallbackTwoCondition: (pd: any) =>
				pd?.Status === BlStaffPdTypes.Completed ||
				pd?.Status !== BlStaffPdTypes.DidntAttend,
		}));

		this.pdtService
			.getSecurityGroups()
			.pipe(
				tap((data) => {
					this.securityGroups$ = of(data);
				}),
				tap(() => this.getPDManagement()),
				take(1)
			)
			.subscribe();
	}

	getPDManagement() {
		this.pdtService.getPDTM().subscribe((data) => {
			this.setTableData(data);
			this.isLoadingData = false;
		});
	}

	setTableData(data: any[]) {
		this.staffChoices$.pipe(take(1)).subscribe((staffChoices: any) => {
			this.dataSource = data
				?.map((x) => ({
					...x,
					ProviderBusiness:
						x?.Provider === 'Other' ? x?.OtherProvider : x?.Provider,
					SecurityGroups: {
						display: `${
							x?.SecurityGroups ? x?.SecurityGroups?.length : '0'
						} Security Group`,
						data: x?.SecurityGroups?.map(
							(sg) =>
								this.securityGroups.find((sg2) => sg2.SecurityGroupCode === sg)
									?.SecurityGroupName
						),
					},
					Users: {
						display: `${x?.Users ? x?.Users?.length : '0'} Users`,
						data: x?.Users?.map(
							(userId) =>
								staffChoices.find((staff) => staff?.StaffID === userId)
									?.FullName || ''
						),
					},
					CompletedPercentage: `${
						(Number.isInteger(x?.CompletedPercentage)
							? x?.CompletedPercentage
							: (x?.CompletedPercentage).toFixed(2)) || '0'
					}%`,
				}))
				?.sort(
					(a, b) =>
						new Date(b.DueDate).valueOf() - new Date(a.DueDate).valueOf()
				);

			this.cd.detectChanges();
		});
	}

	createPD() {
		this.openPDModal();
	}

	editPD = (pd: any) => {
		this.pdtService
			.findPDTM(pd?.StaffSettingsId)
			.pipe(take(1))
			.subscribe((data) => {
				this.openPDModal(data);
			});
	};

	retractPD = (pd: any) => {
		if (
			pd?.Status === BlStaffPdTypes.Completed ||
			pd?.Status === BlStaffPdTypes.DidntAttend
		) {
			return;
		}

		this.pdtService
			.findPDTM(pd?.StaffSettingsId)
			.pipe(
				mergeMap((data) => {
					data.Status = 'Didnt Attend';
					return this.pdtService.updatePDTM(+data?.StaffSettingsId, data);
				}),
				tap(() => {
					this.getPDManagement();
				}),
				take(1)
			)
			.subscribe();
	};

	openPDModal(pd?: any) {
		const mappedSG = viewSecGroup
			.mapSecGroups(this.securityGroups)
			.map((sec) => ({
				display: sec.securityGroupName,
				value: sec.securityGroupCode,
			}));

		combineLatest([
			this.SCS$,
			this.SCT$,
			this.SQCKI$,
			this.UPDTP$,
			this.UPDTC$,
			this.UPDTS$,
			this.staffChoices$,
			this.adviserIdsAssigned$,
		])
			.pipe(take(1))
			.subscribe(
				([
					_scs,
					_sct,
					_sqcki,
					updtp,
					updtc,
					updts,
					staffChoices,
					adviserIdsAssigned,
				]: any) => {
					const staffList = staffChoices
						.filter((staff) => adviserIdsAssigned.includes(staff.StaffID))
						.map((staff) => ({
							display: staff.FullName,
							value: staff.StaffID,
							securityGroup: staff.SecurityGroup,
						}));

					const initialState = {
						title: 'Personal Development Task',
						data: !!pd ? pd : null,
						limitSingleDoc: true,
						initializeEdit: !!pd
							? pd.Status === BlStaffPdTypes.Completed
								? false
								: true
							: true,
						staffId: this.userQuery.getValue().StaffID,
						UPDTP: updtp,
						UPDTC: updtc,
						UPDTS: updts,
						// defaultStatus: BlStaffPdTypes.Completed,
						type: BlStaffSettingTypes.PersonalDevelopmentManagement,
						btnText: 'Save',
						upsertFn$: this.upsertPdManagement$,
						downloadDocumentFn$: null,
						securityGroups$: this.securityGroups$,
						users$: of(staffList),
					};

					this.modalService.show(UserPdTaskModalComponent, {
						class:
							'modal-dialog-centered modal-dialog modal-lg user-pd-task-modal',
						ignoreBackdropClick: true,
						keyboard: false,
						initialState,
					});
				}
			);
	}

	upsertPdManagement$ = (data) => {
		return of(data).pipe(
			map((data) => {
				return {
					...data,
					CompletedDate: util.MomentToDateString(data?.CompletedDate),
					DueDate: util.MomentToDateString(data?.DueDate),
				};
			}),
			mergeMap((data) =>
				iif(
					() => !!data?.StaffSettingsId,
					this.pdtService.updatePDTM(+data?.StaffSettingsId, data),
					this.pdtService.addPDTM(data)
				)
			),
			tap(() => {
				this.getPDManagement();
			})
		);
	};

	ngOnDestroy(): void {
		super.dispose();
	}
}
