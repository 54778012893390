import {
	ChangeDetectorRef,
	Component,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { BusinessConfig } from '@domain/business-config/business-config.model';
import { Attachment } from '@shared/models/_general/attachment.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { ServiceAdviceProcessState } from '@shared/models/advice-process/advice-process.model';
import { DocumentState } from '@shared/models/client-review-template/declaration/declaration.model';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import {
	Observable,
	catchError,
	combineLatest,
	delay,
	filter,
	finalize,
	iif,
	map,
	mergeMap,
	of,
	take,
	tap,
	throwError,
} from 'rxjs';
import { EmailFormComponent } from '../email-form/email-form.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FinalStructureSettingsService } from '@modules/crt-settings/final-structure-settings/state/final-structure-settings.service';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { FinalStructureDocumentMapper } from '@modules/crt-settings/final-structure-settings/state/final-structure-settings.mapper';
import * as R from 'ramda';
import { objectUtil } from '@util/util';
import { EmailTemplateService } from '@modules/emails/email-settings/state/email-template.service';
import { EmailTemplateModel } from '@modules/emails/email-settings/state/email-template.store';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { PrimaryCustomerCompanyState } from '@shared/models/business-profile/business/business.model';

@Component({
	selector: 'app-email-business-modal',
	templateUrl: './email-business-modal.component.html',
	styleUrls: ['./email-business-modal.component.scss'],
})
export class EmailBusinessModalComponent {
	saveEmailFn$: (model: any) => Observable<any>;
	sendEmailFn$: (model: any) => Observable<any>;
	downloadOfferTermsFn$: (model: any) => Observable<any>;
	beforeSendFn$: (model: any) => Observable<any>;
	generateFinalisedEmailTemplateFn$: Observable<any>;
	decline$: Observable<any>;
	next$: Observable<any>;
	sent$: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
	emailSettings$: Observable<any>;
	emailDocumentTemplateBase64$: Observable<string>;
	emailDocumentTemplate: string;
	bodyContent = '<p></p>';
	shortCodes = null;
	peopleDropdown: Observable<any>;
	peopleList: ViewDisplayValue[];
	showEmailTemplate: boolean;
	bodyId: number;
	attachments: Attachment[];
	successMessage: string;
	attachFiles = true;
	offerTerms: DocumentState[];
	businessConfig$: Observable<BusinessConfig>;
	primaryCompany: PrimaryCustomerCompanyState;
	showBusinessRecipient: boolean;
	adviceProcess: ServiceAdviceProcessState;
	defaultLinkDocumentTab: string;
	serviceCodeType: string = '';
	adviserId: number;
	disableEmailUpdate = true;
	showNextBtn = false;
	sendToLabel = 'To';
	initFormValue = null;
	getCrtInfoFn: (value: any) => Observable<any>;
	showAttachments = false;
	clientDocumentsLoader: () => Observable<any>;
	onModalClosed: () => {};

	uploadedDocument = false;
	onUploadedDocument = () =>
		of(true).pipe(tap(() => (this.uploadedDocument = true)));

	attachmentsLoader$: () => Observable<Attachment[]>;
	isLoading = false;
	isLoadingECList = false;

	mergeTags$: Observable<MergeTagState[]>;

	@ViewChild(EmailFormComponent) emailFormComponent: EmailFormComponent;

	documentInfo: any;

	isEmailClientTemplate = true;
	emailClientTemplateList$: Observable<EmailTemplateModel[]>;
	emailClientTemplateList: EmailTemplateModel[] = [];

	get showAttachmentUI(): boolean {
		return Boolean(this.attachments?.length) || this.showAttachments;
	}

	constructor(
		public bsModalRef: BsModalRef,
		private fsService: FinalStructureSettingsService,
		private crtDocService: CrtDocumentService,
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef,
		private emailTemplateService: EmailTemplateService,
		private businessProfileService: BusinessProfileService,
	) {}

	ngOnInit(): void {
		this.emailClientTemplateList$ = this.emailTemplateService
			.getEmailTemplatesByType(this.serviceCodeType)
			.pipe(
				map((res) => res || []),
				tap(() => (this.isLoadingECList = true)),
				finalize(() => (this.isLoadingECList = false))
			);

		this.prepData();
		this.peopleDropdown.pipe(take(1)).subscribe((data) => {
			const pList = data;
			pList.push({ display: 'Other', value: 'Other' });
			this.peopleList = pList;
		});

		if (this.attachmentsLoader$) {
			this.isLoading = true;
			this.cdr.detectChanges();
			this.attachmentsLoader$()
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe((attachments) => {
					this.cdr.detectChanges();
					if (!attachments?.length) {
						return;
					}
					this.attachments.push(...attachments);
				});
		}
	}

	prepData() {
		combineLatest([this.emailSettings$, this.emailClientTemplateList$])
			.pipe(
				filter(([data]) => !!data),
				tap(([data, ecTemplateList]) => {
					this.bodyId = +data?.body || 0;
					this.emailClientTemplateList = ecTemplateList;
				}),
				map(([data]) =>
					FinalStructureDocumentMapper.mapEmailSettingsData(data)
				),
				mergeMap((data) =>
					iif(
						() => R.isNil(data?.body),
						of(null),
						this.fsService.getFile(+data.body)
					)
				),
				map((res) => (res ? objectUtil.mapPascalCaseToCamelCase(res) : null)),
				mergeMap((res) =>
					iif(
						() => res?.documentLink,
						this.fsService.getDocumentFromURL(res?.documentLink),
						of(null)
					)
				),
				tap((data) => {
					if (data) {
						this.bodyContent = data;
					}
				}),
				catchError((err) => {
					return throwError(err);
				}),
				take(1)
			)
			.subscribe();
	}

	sent() {
		this.bsModalRef.hide();
		this.sent$?.pipe(take(1)).subscribe();
		this.reloadDocuments();
	}

	decline() {
		if (!!this.decline$) {
			this.decline$.pipe(take(1)).subscribe();
		}
		this.bsModalRef.hide();
		this.reloadDocuments();
	}

	next(): void {
		this.bsModalRef.hide();
		this.next$?.pipe(take(1)).subscribe();
		this.reloadDocuments();
	}

	private reloadDocuments(): void {
		if (this.uploadedDocument) {
			this.businessProfileService
				.getClientDocuments(this.documentInfo.customerId)
				.pipe(take(1))
				.subscribe();
		}
		this.businessProfileService
			.getClientHistories(this.documentInfo.customerId)
			.pipe(take(1))
			.subscribe();
	}

	downloadPdfAttachment(attachment: Attachment) {
		if (attachment.generateContentCallback$) {
			attachment.generateContentCallback$
				.pipe(
					delay(100),
					mergeMap((content) =>
						this.renderPdfDownload(content, attachment.newPdfOptions)
					),
					take(1)
				)
				.subscribe();
		}

		if (attachment.fileUrl) {
			this.downloadFile(attachment.fileUrl, attachment.fileName);
		}
	}

	downloadFile(fileUrl: any, fileName: string) {
		this.crtDocService
			.getDocumentLink(fileUrl, {
				responseType: 'blob',
			})
			.pipe(
				tap((file) => {
					const newFile = new File([file], fileName);
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(newFile);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', fileName);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			)
			.subscribe();
	}

	downloadOfferTerms(referenceId) {
		this.downloadOfferTermsFn$(referenceId).pipe(take(1)).subscribe();
	}

	renderPdfDownload = (content, pdfOptions?) =>
		this.crtDocService
			.downloadDocumentPDF(content, pdfOptions?.FileName, pdfOptions)
			.pipe(
				tap((x) => {
					const name = `${pdfOptions?.FileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1)
			);

	openAttachmentModal() {
		this.emailFormComponent.openUploadOptionModal();
	}

	removeAttachment(index: number) {
		this.attachments.splice(index, 1);
	}

	close(): void {
		this.bsModalRef.hide();
		this.reloadDocuments();
	}
}

export interface ConfirmModalModel {
	decline$?: Observable<any>;
	next$?: Observable<any>;
	sent$?: Observable<any>;
	header: string;
	message: string;
	secondaryMessage: string;
}
