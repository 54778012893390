import { TableColumn } from '@swimlane/ngx-datatable';
import produce from 'immer';
import * as R from 'ramda';
import { datatableUtil } from 'src/app/util/datatable.util';
import { computeUtil, numUtil,util } from 'src/app/util/util';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { AdviceProcess, Row } from './states/advice-process.model';

/**
 * possible metakeys
 */
export type Metakey =
	| 'Advice Process'
	| 'Clients Involved'
	| 'Advice Status'
	| 'Adviser'
  | 'AdviceOnlineOffline'
	| 'Reviewed By'
	| 'Required Documents'
	| 'Status Clock'
	| 'Start Date'
	| 'End Date'
	| 'Last Note'
	| 'Next Activity'
	| 'Client Type'
	| 'Advice Stage'
	| 'Advice Service'
	| 'Complaint Outcome'
	| 'Complaint Type'
	| 'Lead Status'
	|	'Reference Number'
	| 'Claim Type'
	| 'Claim Outcome'
	|	'Total Paid';

/** possible control types. Move this somewhere more appropriate. */
export type controlType =
	| 'display'
	| 'long-display'
	| 'textbox'
	| 'dropdown'
	| 'note'
	| 'date'
	| 'address'
	| 'activity'
	| 'multiselect'
	| 'textarea'
	| 'money'
	| 'checkbox';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
export type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof Row;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (
		field: FieldMetadata<any>,
		choices?: ViewDisplayValue[],
		row?: Row
	) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
	isTwoDecimal?: boolean;
	columnId?: string;
	fieldId?: string;
};

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return R.propOr<string, ViewDisplayValue, string>(
		'',
		'display' as keyof FieldMetadata<any>,
		choiceItem
	);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceIndex = choices?.findIndex((x) => x.value === field.value);
	return choiceIndex > -1 ? choiceIndex : undefined;
};
/** Get multi value display */
const getMultiselectValues = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value || field.value === '[]' || field.value.length === 0) {
		return '';
	}
	const values: string[] =
		typeof field.value === 'string'
			? Array.isArray(JSON.parse(field.value))
				? JSON.parse(field.value)
				: []
			: [];
	return values
		?.map((v) =>
			R.propOr(
				'',
				'display',
				choices?.find((c) => `${c.value}` === `${v}`)
			)
		)
		?.filter((x) => x)
		.join(', ');
};

const contactStatus = (value: string) => {
	switch (value) {
		case 'L':
			return 'Lead';
		case 'C':
			return 'Client';
		case 'X':
			return 'Ex-Client';
	}
};
/** get sort value for checkboxes */
const getCheckboxSortValue = (field: FieldMetadata<any>) => {
	const isTrue: boolean =
		field.value === true ||
		(typeof field.value === typeof '' && field.value?.toLowerCase() === 'true');
	return R.defaultTo('', isTrue ? '1' : '2');
};
/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
	const b = R.defaultTo('', date)?.split(/\D/);
	return b?.reverse().join('/');
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
	const splicedDate = R.defaultTo('', note)?.slice(0, 10);
	return reformatDateString(splicedDate);
};

const statusClock = (field: FieldMetadata<any>) => {
	const arr = field.value?.split(' ');
	const timePeriod = arr?.pop();
	return timePeriod === 'year' || timePeriod === 'years'
		? +(arr[0] * 365)
		: R.complement(R.isEmpty)(field.value)
			? +arr[0]
			: '';
};

const claimType = (value:string) =>{
	if(value){
		if(value === '[]'){
			return null;
		}
		const claimdata = util.tryParseJson(value);
		const altData = value.replace(/[\[\]\\"]/g, '').split(',');
		return claimdata?value:JSON.stringify(altData);
	}
	return null;
}

const editableClaimStatus = (value:string) =>{
	if(value==='1'||value==='5')
		return true;
	return false
}


/**
 * possible columns with their own configurations
 */
export const columns: EnhancedTableColumn[] = [
	// Default columns to show
	{
		metakey: 'Advice Process',
		prop: 'AdviceProcess',
		name: 'Advice Process',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		cellClass: 'font-weight-bold fixed-column',
		columnId: datatableUtil.formatColumnId('Advice Process'),
		fieldId: datatableUtil.formatFieldId('Advice Process')
	},
	{
		metakey: 'Clients Involved',
		prop: 'ClientsInvolved',
		name: 'Clients Involved',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'long-display',
		isRequired: true,
		cellClass: 'font-weight-bold fixed-column',
		columnId: datatableUtil.formatColumnId('Clients Involved'),
		fieldId: datatableUtil.formatFieldId('Clients Involved')
	},
	{
		metakey: 'Advice Status',
		prop: 'AdviceStatus',
		name: 'Advice Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Advice Status'),
		fieldId: datatableUtil.formatFieldId('Advice Status')
	},
	{
		metakey: 'AdviceOnlineOffline',
		prop: 'AdviceOnlineOffline',
		name: 'Online/Offline',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('AdviceOnlineOffline'),
		fieldId: datatableUtil.formatFieldId('AdviceOnlineOffline'),
	},
	{
		metakey: 'Adviser',
		prop: 'Adviser',
		name: 'Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser')
	},
	{
		metakey: 'Reviewed By',
		prop: 'ReviewedBy',
		name: 'Reviewed By',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Reviewed By'),
		fieldId: datatableUtil.formatFieldId('Reviewed By')
	},
	{
		metakey: 'Required Documents',
		prop: 'RequiredDocuments',
		name: 'Required Documents',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'long-display',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Required Documents'),
		fieldId: datatableUtil.formatFieldId('Required Documents')
	},
	{
		metakey: 'Status Clock',
		prop: 'StatusClock',
		name: 'Status Clock',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => statusClock(f),
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Status Clock'),
		fieldId: datatableUtil.formatFieldId('Status Clock')
	},
	{
		metakey: 'Start Date',
		prop: 'StartDate',
		name: 'Start Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Start Date'),
		fieldId: datatableUtil.formatFieldId('Start Date')
	},
	{
		metakey: 'End Date',
		prop: 'EndDate',
		name: 'End Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('End Date'),
		fieldId: datatableUtil.formatFieldId('End Date')
	},
	{
		metakey: 'Last Note',
		prop: 'LastNote',
		name: 'Last Note',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'note',
		columnId: datatableUtil.formatColumnId('Last Note'),
		fieldId: datatableUtil.formatFieldId('Last Note')
	},
	{
		metakey: 'Next Activity',
		prop: 'NextActivity',
		name: 'Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('Next Activity'),
		fieldId: datatableUtil.formatFieldId('Next Activity')
	},
	{
		metakey: 'Client Type',
		prop: 'ClientType',
		name: 'Client Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Client Type'),
		fieldId: datatableUtil.formatFieldId('Client Type')
	},
	{
		metakey: 'Advice Stage',
		prop: 'AdviceStage',
		name: 'Advice Stage',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Advice Stage'),
		fieldId: datatableUtil.formatFieldId('Advice Stage')
	},
	{
		metakey: 'Advice Service',
		prop: 'AdviceService',
		name: 'Advice Service',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Advice Service'),
		fieldId: datatableUtil.formatFieldId('Advice Service')
	},
	{
		metakey: 'Complaint Outcome',
		prop: 'ComplaintOutcome',
		name: 'Complaint Outcome',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Complaint Outcome'),
		fieldId: datatableUtil.formatFieldId('Complaint Outcome')
	},
	{
		metakey: 'Complaint Type',
		prop: 'ComplaintType',
		name: 'Complaint Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Complaint Type'),
		fieldId: datatableUtil.formatFieldId('Complaint Type')
	},
	{
		metakey: 'Lead Status',
		prop: 'LeadStatus',
		name: 'Lead Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Lead Status'),
		fieldId: datatableUtil.formatFieldId('Lead Status')
	},
	{
		metakey: 'Reference Number',
		prop: 'ReferenceNumber',
		name: 'Claim Reference Number',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('Reference Number'),
		fieldId: datatableUtil.formatFieldId('Reference Number')
	},
	{
		metakey: 'Claim Type',
		prop: 'ClaimType',
		name: 'Claim Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter:  (f, c) => f.value,
		controlType: 'multiselect',
		columnId: datatableUtil.formatColumnId('Claim Type'),
		fieldId: datatableUtil.formatFieldId('Claim Type')
	},
	{
		metakey: 'Claim Outcome',
		prop: 'ClaimOutcome',
		name: 'Claim Outcome',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Claim Outcome'),
		fieldId: datatableUtil.formatFieldId('Claim Outcome')
	},
	{
		metakey: 'Total Paid',
		prop: 'ClaimTotalPaid',
		name: 'Claim Total Paid',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('Total Paid'),
		fieldId: datatableUtil.formatFieldId('Total Paid')
	},
];

/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 18,
};

/**
 * template for Row
 */
export const templateRow: Row = {
	AdviceProcessID: null,
	CustomerID: null,
	ClientNextActivityId: null,
	AdviserName: null,
	ReviewedByName: null,
	AdviceProcess: {
		metakey: 'Advice Process',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Advice Process').prop,
		required: false,
		editable: false,
	},
	ClientsInvolved: {
		metakey: 'Clients Involved',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Clients Involved').prop,
		required: false,
		editable: false,
		hasToolTip: true,
	},
	AdviceOnlineOffline: {
		metakey: 'AdviceOnlineOffline',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'AdviceOnlineOffline').prop,
		required: true,
		editable: false,
		hasToolTip: true,
	},
	AdviceStatus: {
		metakey: 'Advice Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Advice Status').prop,
		required: false,
		editable: false,
	},
	Adviser: {
		metakey: 'Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Adviser').prop,
		required: false,
		editable: false,
	},
	ReviewedBy: {
		metakey: 'Reviewed By',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Reviewed By').prop,
		required: false,
		editable: false,
	},
	RequiredDocuments: {
		metakey: 'Required Documents',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Required Documents').prop,
		required: false,
		editable: false,
	},
	StatusClock: {
		metakey: 'Status Clock',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Status Clock').prop,
		required: false,
		editable: false,
	},
	StartDate: {
		metakey: 'Start Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Start Date').prop,
		required: false,
		editable: false,
	},
	EndDate: {
		metakey: 'End Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'End Date').prop,
		required: false,
		editable: false,
	},
	LastNote: {
		metakey: 'Last Note',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Last Note').prop,
		required: false,
		editable: true,
		hasToolTip: true,
		restrict: ['FAN'],
	},
	NextActivity: {
		metakey: 'Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	ClientType: {
		metakey: 'Client Type',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Client Type').prop,
		required: false,
		editable: false,
	},
	AdviceStage: {
		metakey: 'Advice Stage',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Advice Stage').prop,
		required: false,
		editable: false,
	},
	AdviceService: {
		metakey: 'Advice Service',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Advice Service').prop,
		required: false,
		editable: false,
	},
	ComplaintOutcome: {
		metakey: 'Complaint Outcome',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Complaint Outcome').prop,
		required: false,
		editable: false,
	},
	ComplaintType: {
		metakey: 'Complaint Type',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Complaint Type').prop,
		required: false,
		editable: false,
	},
	LeadStatus: {
		metakey: 'Lead Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Lead Status').prop,
		required: false,
		editable: false,
	},
	// Floating action buttons
	link: null,
	//Claims
	ReferenceNumber:{
		metakey: 'Reference Number',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Reference Number').prop,
		required: false,
		editable: true,
	},
	ClaimType:{
		metakey: 'Claim Type',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Claim Type').prop,
		required: false,
		editable: true,
	},
	ClaimOutcome:{
		metakey: 'Claim Outcome',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Claim Outcome').prop,
		required: false,
		editable: true,
	},
	ClaimTotalPaid:{
		metakey: 'Total Paid',
		value: '0.00',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Total Paid').prop,
		required: false,
		editable: true,
	}
};

export const createRowFromPrototype = R.curry(
	(template: Row, row: AdviceProcess) =>
		produce<Row>(template, (draft) => {
			draft.CustomerID = row.CustomerID;
			draft.AdviceProcessID = row.AdviceProcessID;
			draft.ClientNextActivityId = row.ClientNextActivityId;
			draft.AdviserName = row.AdviserName;
			draft.ReviewedByName = row.ReviewedByName;

			draft.AdviceProcess.metakey = 'Advice Process';
			draft.AdviceProcess.value = row.AdviceProcess;
			draft.ClientsInvolved.metakey = 'Clients Involved';
			draft.ClientsInvolved.value = row.ClientsInvolved;

			draft.AdviceStatus.metakey = 'Advice Status';
			draft.AdviceStatus.value = [
				{ display: 'In progress', value: '1' },
				{ display: 'In progress', value: '5' },
				{ display: 'Ended - Completed', value: '3' },
				{ display: 'Ended - Incomplete', value: '4' },
				{ display: 'Cancelled', value: '6' },
			]?.find((x) => x.value === row.AdviceStatus).display;

			draft.Adviser.metakey = 'Adviser';
			draft.Adviser.value = row.Adviser;

			draft.AdviceOnlineOffline.metakey = 'AdviceOnlineOffline';
			draft.AdviceOnlineOffline.value = row.AdviceOnlineOffline;

			draft.ReviewedBy.metakey = 'Reviewed By';
			draft.ReviewedBy.value = !row.AdviceProcess.includes('Claim') ? row.ReviewedBy:row.ClaimManagerID;

			draft.RequiredDocuments.metakey = 'Required Documents';
			draft.RequiredDocuments.value = row.RequiredDocuments;

			draft.StatusClock.metakey = 'Status Clock';
			draft.StatusClock.value = computeUtil.calcStatusClock(row.StatusClock);

			draft.StartDate.metakey = 'Start Date';
			draft.StartDate.value = row.StartDate;

			draft.EndDate.metakey = 'End Date';
			draft.EndDate.value = row.EndDate;

			draft.LastNote.metakey = 'Last Note';
			draft.LastNote.value = row.LastNote;

			draft.NextActivity.metakey = 'Next Activity';
			draft.NextActivity.value = row.NextActivity;

			draft.ClientType.metakey = 'Client Type';
			draft.ClientType.value = contactStatus(row.ClientType);

			draft.AdviceStage.metakey = 'Advice Stage';
			draft.AdviceStage.value = row.AdviceStage;

			draft.AdviceService.metakey = 'Advice Service';
			draft.AdviceService.value = row.AdviceService;

			draft.ComplaintOutcome.metakey = 'Complaint Outcome';
			draft.ComplaintOutcome.value = row.ComplaintOutcome;

			draft.ComplaintType.metakey = 'Complaint Type';
			draft.ComplaintType.value = row.ComplaintType;

			draft.LeadStatus.metakey = 'Lead Status';
			draft.LeadStatus.value = row.LeadStatus;

			draft.link = {
				CustomerID: row.CustomerID,
				AdviceProcessID: row.AdviceProcessID,
				IsCompany: row.IsCompany,
			};
			draft.ReferenceNumber.metakey = 'Reference Number';
			draft.ReferenceNumber.value = row.ReferenceNumber;
			draft.ReferenceNumber.editable = editableClaimStatus(row.AdviceStatus) ? row.AdviceProcess.includes('Claim') : false;

			draft.ClaimType.metakey = 'Claim Type';
			draft.ClaimType.value = claimType(row.ClaimType);
			draft.ClaimType.editable = editableClaimStatus(row.AdviceStatus) ? row.AdviceProcess.includes('Claim') : false;

			draft.ClaimOutcome.metakey = 'Claim Outcome';
			draft.ClaimOutcome.value = row.ClaimOutcome;
			draft.ClaimOutcome.editable = editableClaimStatus(row.AdviceStatus) ? row.AdviceProcess.includes('Claim') : false;
			
			draft.ClaimTotalPaid.metakey = 'Total Paid';
			draft.ClaimTotalPaid.value =  row.ClaimTotalPaid?.toString()??draft.ClaimTotalPaid.value;
			draft.ClaimTotalPaid.editable = editableClaimStatus(row.AdviceStatus) ? row.AdviceProcess.includes('Claim') : false;
		})
);

