import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { CustomerService } from '@core/customer/customer.service';
import { applyTransaction } from '@datorama/akita';
import { SidebarStatus } from '@modules/crm/crt-page/_shared/models/sidebar.model';
import { HtmlToPdfService } from '@modules/crm/crt-page/_shared/service/html-pdf/html-to-pdf.service';
import { KiwiSaverSettingsStore } from '@modules/kiwisaver-settings/state/kiwisaver-settings.store';
import { EmailSettingsModel } from '@shared/models/_general/email-settings.model';
import {
	AdviceProcessPageCodes,
	KiwiSaverAdviceProcessPageIds,
} from '@shared/models/advice-process/advice-process.model';
import {
	DocumentModelState,
	DocumentUpload,
	DocumentUploadState,
} from '@shared/models/documents/document.model';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import {
	catchError,
	concatMap,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import { convertUtil, objectUtil } from '../../../../../../util/util';
import { CrtKiwiSaverQuery } from '../../state/crt-kiwisaver.query';
import { CrtKiwiSaverService } from '../../state/crt-kiwisaver.service';
import { CrtKiwiSaverStore } from '../../state/crt-kiwisaver.store';
import { AdviceSummaryQuery } from './advice-summary.query';
import { AdviceSummary, AdviceSummaryStore } from './advice-summary.store';

export const adviceSummaryPdfOptions = {
	FileName: 'STATEMENT-OF-ADVICE',
	DPI: '120',
	EnableIntelligentShrinking: 'false',
	MinimumFontSize: '9',
	LoadZoomFactor: '1.22',
	LoadJSDelay: '1500',
};

@Injectable({
	providedIn: 'root',
})
export class AdviceSummaryService {
	constructor(
		protected api: ApiService,
		protected koatSettingsStore: KiwiSaverSettingsStore,
		protected store: AdviceSummaryStore,
		protected koatStore: CrtKiwiSaverStore,
		private adviceSummaryQuery: AdviceSummaryQuery,
		protected koatQuery: CrtKiwiSaverQuery,
		protected koatService: CrtKiwiSaverService,
		private htmlToPdfService: HtmlToPdfService,
		protected customerService: CustomerService
	) {}

	get(adviceProcessId: number): Observable<AdviceSummary> {
		const endpoint = `crt/${adviceProcessId}/KOATAS`;
		const request$ = this.api.get(endpoint).pipe(
			map(objectUtil.mapPascalCaseToCamelCase),
			map((result) => result?.[0]),
			tap((adviceSummary) => this.store.set(adviceSummary))
		);

		return this.adviceSummaryQuery.adviceSummary$.pipe(
			take(1),
			mergeMap((adviceSummary) => {
				return Boolean(adviceSummary?.cRTId) ? of(adviceSummary) : request$;
			})
		);
	}

	add(summary: AdviceSummary): Observable<number> {
		return this.api
			.post(`crt`, objectUtil.mapCamelCaseToPascalCase(summary))
			.pipe(map((id: string) => +id));
	}

	update(summary: AdviceSummary) {
		const endpoint = `crt/${summary.cRTId}`;
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(summary))
			.pipe(
				tap(() => {
					applyTransaction(() => {
						this.store.update(summary);
					});
				}),
				catchError(() => of(null))
			);
	}

	getOrAdd(adviceProcessId: number): Observable<AdviceSummary> {
		return this.get(adviceProcessId).pipe(
			map((summary) => summary),
			mergeMap((summary) => {
				if (summary?.cRTId) {
					return of(summary);
				}
				const unsavedSummary: AdviceSummary = {
					adviceProcessId,
					cRTId: 0,
					currentPage: 'PLACEHOLDER',
					sectionCode: 'KOATAS',
				};
				return this.add(unsavedSummary).pipe(
					take(1),
					map((cRTId) => {
						unsavedSummary.cRTId = cRTId;
						return unsavedSummary;
					})
				);
			})
		);
	}

	getDocumentFromURL(url: string) {
		const dateTime = new Date().getTime();
		url = `${url}?${dateTime}`;

		return this.api
			.getExternalResource(url, { responseType: 'text' })
			.pipe(catchError(() => EMPTY));
	}

	getDocumentFile(id: number) {
		return this.api.get<DocumentModelState>(`documents/${id}`).pipe(
			map((x) => (x ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			catchError(() => EMPTY)
		);
	}

	newFileUpload(req: DocumentUploadState) {
		const endpoint = 'documents';
		return this.api.post3<any>(endpoint, req);
	}

	updateFileUpload(req: DocumentUploadState) {
		const endpoint = `documents/${req.documentId}/document-link`;
		const body = objectUtil.mapCamelCaseToPascalCase(req);
		return this.api.put<DocumentUploadState>(endpoint, body);
	}

	getEmailSettings(
		adviceProcessId: number,
		settingsCode: string
	): Observable<EmailSettingsModel> {
		const endpoint = `crt/settings/${adviceProcessId}/${settingsCode}`;
		return this.api.get<EmailSettingsModel>(endpoint).pipe(
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : null;
					this.koatStore.setAdviceSummaryEmailSettings(state);
				})
			),
			catchError(() => of(undefined))
		);
	}

	setTabColorAdviceSummary() {
		return combineLatest([
			this.koatQuery.adviceProcess$,
			this.koatQuery.pageStarted$,
		]).pipe(
			tap(([ap, pageStarted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (pageStarted?.includes(AdviceProcessPageCodes.AdviceSummary)) {
					status = SidebarStatus.Incomplete;
					warning = 'Document must be saved to file';
				}

				if (
					!!ap?.documents &&
					ap?.documents?.length > 0 &&
					ap?.documents?.some(
						(x) => x?.field === 'Advice Summary' && !!x?.value
					)
				) {
					status = SidebarStatus.Completed;
					warning = null;
				}

				this.koatService.setSideSidebarStatus(
					KiwiSaverAdviceProcessPageIds.AdviceSummary,
					false,
					status,
					warning
				);
			})
		);
	}

	convertAdviceSummaryTemplateToBase64Pdf(template: string, name?: string) {
		return of(template).pipe(
			concatMap((x) =>
				this.htmlToPdfService.downloadDocumentPDF(x, 'ADVICE-SUMMARY', {
					...adviceSummaryPdfOptions,
					FileName: name,
				})
			),
			concatMap((x) => convertUtil.convertToBase64(x)),
			catchError(() => EMPTY)
		);
	}

	saveDocument(req: DocumentUpload): Observable<number> {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}
	saveCombineDocument(req: DocumentUpload): Observable<number> {
		return this.customerService
			.UploadDocument(req)
			.pipe(catchError(() => EMPTY));
	}
}
