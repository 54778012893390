import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, filter, map, takeUntil } from 'rxjs/operators';
import { BarChartComponent } from 'src/app/shared/bar-chart/bar-chart.component';
import { DonutChartComponent } from 'src/app/shared/donut-chart/donut-chart.component';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { DashboardQuery } from '../../state/dashboard.query';
import { BlanketNewBusinessGoalTrack } from './state/bnb.model';
import { BNBQuery } from './state/bnb.query';
import { BNBService } from './state/bnb.service';

@Component({
	selector: 'app-bnb',
	templateUrl: './bnb.component.html'
})
export class BnbComponent implements OnInit, OnDestroy {
	public static widgetCode = 'bnb';
	public static widgetName = 'Blanket New Business';
	public static sizeX = 400;
	public static sizeY = 480;
	public static minSizeX = 400;
	public static minSizeY = 480;

	widgetCode = BnbComponent.widgetCode;

	/**
	 * destroy event observable. Is watched to know when to
	 * unsubscribe subscribers. emits on ngOnDestroy.
	 */
	private onDestroy$ = new Subject<void>();
	/**
	 * LR Goal Track data
	 */
	data$: Observable<BlanketNewBusinessGoalTrack> = this.query.data$;

	/** Pluck Quarter Actual data. Used for donut chart */
	quarterActual$ = this.data$.pipe(map(data => data.QuarterActual));
	/** Pluck Quarter Expected data. Used for donut chart */
	quarterExpected$ = this.data$.pipe(map(data => data.QuarterExpected));
	/** Pluck Year Actual data. Used for donut chart */
	yearActual$ = this.data$.pipe(map(data => data.YearActual));
	/** Pluck Year Expected data. Used for donut chart */
	yearExpected$ = this.data$.pipe(map(data => data.YearExpected));

	/**
	 * Goal track data transformed to bar chart component input
	 */
	barChartData$ = this.data$.pipe(
		map(data => {
			return {
				series: [data.Quarter1, data.Quarter2, data.Quarter3, data.Quarter4],
				labels: ['Q1', 'Q2', 'Q3', 'Q4']
			};
		})
	);

	/**
	 * Indicator if the widget is currently fetching data
	 */
	isLoading$: Observable<boolean> = this.query.isLoading$;
	/**
	 * Error message string. Show error if not empty.
	 */
	error$ = this.query.error$;

	/**
	 * Bar chart element. referenced for manual refreshing
	 * when gridstack resizes div.
	 */
	@ViewChild(BarChartComponent) barChart: BarChartComponent;

	/**
	 * Donut chart element. referenced for manual refreshing
	 * when gridstack resizes div.
	 */
	@ViewChildren(DonutChartComponent) donuts: QueryList<DonutChartComponent>;

	constructor(
		private dashboardQuery: DashboardQuery,
		private service: BNBService,
		private query: BNBQuery,
		private dashboard: DashboardComponent
	) { }

	/**
	 * On widget init, subscribe to adviserFilter and
	 *   trigger fetching of goal track.
	 *
	 * Chart does not automatically resize on div resize(gridstack)
	 *   so listen to resize event to trigger manual resize via refreshing
	 *   of data.
	 *
	 * @caveat
	 * Currently, I haven't figured out how to listen to specific widget
	 *   and listen specifically to resize events only, so it triggers
	 *   refresh on every event
	 */
	ngOnInit() {
		this.dashboardQuery.adviserFilter$
			.pipe(
				filter(x => x.length > 0),
				takeUntil(this.onDestroy$)
			)
			.subscribe(advisers => {
				this.service.GetLRGoalTrack(advisers);
			});

		this.dashboard.gridChangeEvent.pipe(delay(200), takeUntil(this.onDestroy$)).subscribe(res => {
			if (res === BnbComponent.widgetCode) {
				this.barChart.refresh();
				this.donuts?.forEach(x => x.refresh());
			}
		});
	}

	/**
	 * Gets onDestroy$ to emit and unsubscribe.
	 */
	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

