import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { DeleteModalComponent } from '@shared/modal/delete-modal/delete-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer, Subject } from 'rxjs';
import { ExportsService } from './state/exports.service';
import { ExportsQuery } from './state/exports.query';
import { takeUntil } from 'rxjs/operators';
import { ExportsStatus } from './state/exports.model';

@Component({
	selector: 'app-exports',
	templateUrl: './exports.component.html',
	styleUrls: ['./exports.component.scss'],
})
export class ExportsComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@Output() closeExportPopupEvent = new EventEmitter<void>();

	isExpanded = true;
	progress: any;
	percentage = 0;

	timeStr = '';

	reportLabel$ = this.exportsQuery.reportLabel$;
	error$ = this.exportsQuery.error$;

	hasError = false;

	constructor(
		private modalService: BsModalService,
		private exportsService: ExportsService,
		private exportsQuery: ExportsQuery
	) {}

	ngOnInit(): void {
		this.exportsQuery.timeRemaining$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((timeRemaining) => {
				this.timeStr = this.convertMsToTime(timeRemaining);
			});

		this.exportsQuery.progress$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((progress) => {
				this.percentage = progress;
				this.progress = {
					width: `${progress}%`,
				};
			});

		this.error$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((status) => {
				if (!status) {
					this.hasError = false;
				}
				if (status) {
					this.hasError = true;
				}
			});
	}

	convertMsToTime(milliseconds: number) {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;
		hours = hours % 24;

		if (hours) {
			return `${hours}h ${minutes}m ${seconds}s`;
		}

		if (minutes) {
			return `${minutes}m ${seconds}s`;
		}

		return `${seconds}s`;
	}

	calculateProgress(value: number, total: number) {
		this.percentage = +((value * 100) / total).toFixed(2);
		this.progress = {
			width: this.percentage + '%',
		};
	}

	toggleMode() {
		this.isExpanded = !this.isExpanded;
	}

	cancelExport() {
		const status = this.exportsQuery.getValue().status;

		if (status && status === ExportsStatus.COMPLETE) {
			this.closeExport();
			return;
		}

		const confirm = new Observable((obs: Observer<any>) => {
			this.closeExport();
			obs.complete();
		});

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: {
				header: 'Cancel Export',
				message: `Are you sure you want to cancel this data export? <br> All progress will be lost.`,
				confirmButton: 'Yes',
				confirmButton2: 'No',
				delete$: confirm,
			},
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	closeExport() {
		this.exportsService.stopPolling();
		this.closeExportPopupEvent.emit();
		// this.exportsService.resetExport();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
