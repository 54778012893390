import { Component } from '@angular/core';
import { UserQuery } from '@domain/user/user.query';

@Component({
	selector: 'app-tl-moat-settings',
	templateUrl: './tl-moat-settings.component.html',
})
export class TlMoatSettingsComponent {
	userSecurityGroup$ = this.userQuery.userSecurityGroup$;
	constructor(private userQuery: UserQuery) {}
}
