<ng-template [ngIf]="routerLink" [ngIfElse]="logo">
  <a [routerLink]="routerLink">
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </a>
</ng-template>

<ng-template #logo>
  <div class="fill d-flex justify-content-center align-items-center">
    <img *ngIf="src" [src]="imgUrl" alt="logo" class="max-fill" />
  </div>
</ng-template>
