import { Component, OnInit } from '@angular/core';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import * as R from 'ramda';
import { EmailSettingsQuery } from '../email-settings/state/email-settings.query';

@Component({
	selector: 'app-send-manual-email',
	templateUrl: './send-manual-email.component.html'
})
export class SendManualEmailComponent implements OnInit {
	private onDestroy$ = new Subject<void>();
	public settings$: Observable<ViewDisplayValue[]> = this.emailSettingsQuery
		.selectAll({ filterBy: e => e.IsActive === 1 })
		.pipe(
			map(emailType =>
				R.map(
					y => ViewDisplayValue.Map(!y.EmailCode ? '' : y.EmailCode?.toString(), `${y.EmailType}`),
					emailType
				)?.sort((a, b) => a.display.localeCompare(b.display))
			),
		);

	constructor(private emailSettingsQuery: EmailSettingsQuery) {}

  ngOnInit(): void {}
}
