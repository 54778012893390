<div
  class="container-fluid tap-business-profile clearfix"
  [class.tap-business-profile--collapsed]="isCollapsed"
  [class.is-mobile-mode]="isMobileMode"
>
  <div class="row position-relative">
    <div class="col tap-business-profile__main-content py-4">
      <div class="row">
        <div class="col">
          <app-business-profile-group
            class="d-block"
            [addMode]="false"
            [isLead]="isLead$ | async"
            [data]="primaryCompany$ | async"
            [advisers]="advisers$ | async"
						[allStaffChoices]="allStaffChoices$ | async"
            [altAdvisers]="altAdviserChoices$ | async"
            [leadGens]="leadGenChoices$ | async"
            [adviserGenChoices]="adviserGenChoices$ | async"
            [PCT]="PCT$ | async"
            [PCE]="PCE$ | async"
            [PCPC]="PCPC$ | async"
            [PCLE]="PCLE$ | async"
            [PCLT]="PCLT$ | async"
            [PCR]="PCR$ | async"
            [LS]="LS$ | async"
            [LT]="LT$ | async"
						[SAS]="SAS$ | async"
            [criterias]="currentActivityCriterias$ | async"
						[allStaff]="allStaff$ | async"
            (selectServiceTab)="selectTab($event)"
          >
          </app-business-profile-group>
        </div>
      </div>
      <div class="row">
        <div class="col px-0">
          <app-business-profile-tabs
            class="d-block"
            [isLead]="isLead$ | async"
            [addMode]="false"
            [primaryCompany]="primaryCompany$ | async"
          >
          </app-business-profile-tabs>
        </div>
      </div>
    </div>
    <div
      #activityTimeline
      class="col tap-business-profile__activity-timeline"
      [class.tap-business-profile__activity-timeline--collapsed]="isCollapsed"
      [class.is-mobile-mode]="isMobileMode"
    >
      <app-activity-timeline
        #activityTimelineComponent
				[isBusiness]="true"
        [class.d-none]="isCollapsed"
        [isLead]="isLead$ | async"
        [customerId]="customerID$ | async"
        [customerName]="fullName$ | async"
        [activityType$]="activityType$"
        [activityMeeting$]="activityMeeting$"
        [adviserChoices$]="allActiveStaffs$"
        [adviserCalendarChoices$]="adviserCalendarChoices$"
        [pendingActivities]="pendingActivities$ | async"
        [allCompletedNotes]="allCompletedNotes$ | async"
        [completedActivities]="completedActivities$ | async"
        [completedNotes]="completedNotes$ | async"
        [addNoteFn$]="addActivityNote$"
        [deleteNoteFn$]="deleteActivityNote$"
        [addActivityFn$]="addActivity$"
        [updateActivityFn$]="updateActivity$"
        [cancelActivityFn$]="cancelActivity$"
        [deleteActivityFn$]="deleteActivity$"
				[addPhoneCallFn$]="addPhoneCall$"
        [location]="(primaryCompany$ | async)?.physicalAddress"
        [pinNoteFn$]="pinNoteFn$"
        (deleteNoteEvent)="deleteNoteEvent($event)"
      ></app-activity-timeline>
    </div>
    <span class="tap-btn-collapse-expand material-icons" (click)="collapseProfile()">
      {{ isCollapsed ? 'chevron_left' : isMobileMode ? 'close' : 'chevron_right' }}
    </span>
  </div>
</div>
