import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-transfer-client-modal',
  templateUrl: './transfer-client-modal.component.html',
  styleUrls: ['./transfer-client-modal.component.scss']
})
export class TransferClientModalComponent implements OnInit {
	transfer$: Observable<any>;
	header: string;
	message: string | any;
	canTransfer = false;
	confirmButton: string;
	cancelLoading: boolean;
	confirmation: boolean;

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
		this.setupSubs();
	}

	setupProps() {
		this.header = 'Transfer';
		this.message = 'Transfer?';
	}

	setupSubs() {}

	transfer() {
		if (this.cancelLoading) {
			return;
		}
		this.transfer$.subscribe();
		this.decline();
	}

	decline() {
		this.cancelLoading = true;
		this.bsModalRef.hide();
		setTimeout(() => this.cancelLoading = false, 500);
	}

  ngOnInit(): void {
  }
}
