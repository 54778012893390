<button
  class="tap-btn tap-btn-outline modal-btn-close"
  (click)="bsModalRef.hide()"
  id="closePropertyAssetModal"
>
  <span class="material-icons"> close </span>
</button>
<div class="modal-header">
  <h5 class="modal-title">
    {{ header }}
  </h5>
</div>
<div class="modal-body">
  <form novalidate [formGroup]="form">
    <div class="d-flex justify-content-center form-group form-inline">
      <div class="col-2 px-0">
        <span class="tap-required-field" *ngIf="type.invalid">*</span>
        <span class="px-0">Type</span>
      </div>
      <div class="col-10 col-lg-4 px-0">
        <span class="custom-dropdown w-100">
          <select class='form-control theme-crm-field-disabled' formControlName="type" id="propertyAssetType">
            <option value=""></option>
            <option *ngFor="let d of KT" [value]="d.value">{{d.display}}</option>
          </select>
        </span>
      </div>
    </div>
    <div class="justify-content-end float-right modal-footer">
      <ng-container>
        <button type="button" class="tap-btn tap-btn--default" id="addPropertyAsset" (click)="continue(); form.value.type ? isLoading = true : isLoading = false " [disabled]="isLoading">Add</button>
        <button type="button" class="tap-btn tap-btn--default " id="cancelPropertyAsset" data-dismiss="modal" (click)="decline()">Cancel</button>
      </ng-container>
    </div>
  </form>
</div>