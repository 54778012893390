import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LRCTState, LRCTStore } from './lrct.store';

/**
 * Query L&R Anniversary widget data
 */
@Injectable()
export class LRCTQuery extends Query<LRCTState> {
  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create L&R Anniversary query
   */
  data$ = this.select(state => state.LRCommissionTracker);

  /**
   * Create L&R Anniversary query
   */
  constructor(protected store: LRCTStore) {
    super(store);
  }
}
