import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { EmailHistoryService } from './state/email-history.service';

@Injectable()
export class EmailHistoryResolver implements Resolve<boolean> {
	constructor(
		private emailHistoryService: EmailHistoryService) { }

	resolve(
	): Observable<boolean> {
		this.emailHistoryService.clear();
		return of(true);
	}
}

