import { TrustDetailsState } from './trust-details';
import * as R from 'ramda';
export class TrustMapper {
    /** Mapping for view */
    static mapToView(data: TrustDetailsState) {
        if (!data) {
            return { ...data };
        }
        const val = {
            ...data
        };
        return val;
    }

    /**
     * Map to update
     */
    public static mapToUpsert(data: TrustDetailsState, trustInfo: TrustDetailsState): TrustDetailsState {
        return {
            ...data,
            customerID: trustInfo?.customerID,
            adviceProcessId: trustInfo?.adviceProcessId,
            cRTId: trustInfo?.cRTId,
            sectionCode: 'FPT',
            status: trustInfo?.status,
            trustee: data?.trustee && Array.isArray(data?.trustee) ? JSON.stringify(data.trustee?.filter(value => !R.isNil(value) && !R.isEmpty(value))) : null,
            independentTrustName: data?.independentTrustName && Array.isArray(data?.independentTrustName) ? JSON.stringify(data.independentTrustName?.filter(value => !R.isNil(value) && !R.isEmpty(value))) : null,
            beneficiary: data?.beneficiary && Array.isArray(data?.beneficiary) ? JSON.stringify(data.beneficiary?.filter(value => !R.isNil(value) && !R.isEmpty(value))) : null,
        };
    }
}
