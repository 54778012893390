<div class="client-acceptance clearfix">
	<div class="client-acceptance__content clearfix">
		<ng-container *ngIf="isLoading$ | async as isLoading">
			<app-manual-loader-mini [isLoading]="isLoading"></app-manual-loader-mini>
		</ng-container>
		<ng-container *ngIf="!(isLoading$ | async)">
			<form [formGroup]="form" novalidate>
				<div class="form-row align-items-center mb-3">
					<div class="col-12 col-lg-12">
						<div class="form-row mb-2 align-items-center">
							<div class="col-lg-3 col-12">
								<span class="font-weight-bold">
									Which SOA was presented to the client?
								</span>
							</div>
							<div class="col-lg-3 col-12">
								<span class="crt-custom-dropdown w-100">
									<select
										id="soaId"
										class="form-control crt-form-control"
										formControlName="sOA"
										(change)="onChangeSOA($event)"
									>
										<option [ngValue]="null"></option>
										<option
											*ngFor="
												let soa of soa$ | async;
												let index = i;
												trackBy: trackByFn
											"
											[value]="soa.cRTId"
										>
											{{ soa?.name }}
										</option>
									</select>
								</span>
							</div>
						</div>
						<div class="form-row align-items-center">
							<div class="col-lg-3 col-12">
								<span class="font-weight-bold">
									What does the client want to proceed with?
								</span>
							</div>
							<div class="col-lg-3 col-12">
								<span class="crt-custom-dropdown w-100">
									<select
										id="presentedSoaId"
										class="form-control crt-form-control"
										formControlName="presentedSOA"
										(change)="onChangePresentedSOA($event)"
										[attr.disabled]="form.get('sOA').value ? null : true"
									>
										<option value=""></option>
										<option value="Presented SOA">Presented SOA</option>
										<option value="Alternative Structure">
											Alternative Structure
										</option>
									</select>
								</span>
							</div>
							<div class="col-lg-auto" *ngIf="isSavingWhichSOA">
								<app-manual-loader-mini
									[isLoading]="isSavingWhichSOA"
								></app-manual-loader-mini>
							</div>
						</div>
					</div>
				</div>

				<div
					class="form-row mb-3"
					style="min-height: 200px"
					*ngIf="PresentedSOA?.value === 'Alternative Structure'"
				>
					<div class="col-12">
						<div class="d-block clearfix mb-3">
							<label class="h6 font-weight-bold tap-text-primary"
								>Proposed Insurance</label
							>
						</div>

						<div
							*ngFor="
								let insurance of proposedInsurance$ | async;
								let i = index
							"
						>
							<div class="form-row align-items-center justify-content-between">
								<div class="col-lg-3">
									<h6 *ngIf="insurance?.provider" class="">{{ insurance?.provider }}:</h6>
								</div>
								<div class="col-12 col-lg-1 mb-3 align-self-end mb-2">
									<button
										id="editInsuranceBtn_{{i}}"
										type="button"
										class="icon-btn w-auto h-auto px-1"
										(click)="editProduct(insurance)"
									>
										<i class="material-icons md-16 pr-0"> edit </i>
									</button>
									<button
										id="deleteInsuranceBtn_{{i}}"
										type="button"
										class="icon-btn w-auto h-auto px-1"
										(click)="deleteCurrentInsurance(insurance.cRTId)"
									>
										<i class="material-icons md-16 pr-0"> delete </i>
									</button>
								</div>
							</div>

							<div class="form-row d-none d-lg-flex">
								<div class="col-12 col-lg-12">
									<div class="form-row">
										<div class="col-12 col-lg-3 mb-2">
											<label class="font-weight-bold">Life Assured</label>
										</div>
										<div class="col-12 col-lg-3 mb-2">
											<label class="font-weight-bold">Product</label>
										</div>
										<div class="col-12 col-lg-3 mb-2">
											<label class="font-weight-bold">Benefit</label>
										</div>
										<div class="col-12 col-lg-3 mb-2">
											<label class="font-weight-bold">Premium</label>
										</div>
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-12 col-lg-12">
									<div
										class="form-row mb-2"
										*ngFor="
											let p of insurance?.lifeAssured;
											let ip = index;
											let l = last
										"
									>
										<div class="col-12 col-lg-3 mb-2 border-bottom">
											<input
												class="form-control crt-form-control"
												placeholder="Life Assured"
												type="text"
												[value]="p?.lifeAssuredName || ''"
												disabled
											/>
										</div>
										<div class="col-12 col-lg-3 mb-2 border-bottom">
											<input
												class="form-control crt-form-control"
												placeholder="Product"
												type="text"
												value="{{ p.product }}"
												disabled
											/>
										</div>
										<div class="col-12 col-lg-3 mb-2 border-bottom">
											<ng-container
												*ngIf="p.allowDropdown; else showInput"
											>
												<input
													class="form-control crt-form-control"
													placeholder="Benefit"
													type="text"
													value="{{ p.benefit | currency }}{{
														p.allowDropdown ? ' excess' : ''
													}}"
													disabled
												/>
											</ng-container>
											<ng-template #showInput>
													<input
														class="form-control crt-form-control"
														placeholder="Benefit"
														type="text"
														value="{{ p.benefit | currency }}{{
															p.showPerMonth ? '/month' : ''
														}}"
														currencyMask
														disabled
													/>
											</ng-template>
										</div>
										<div class="col-12 col-lg-3 mb-2 border-bottom">
											<div class="dollar-icon">
												<input
													class="form-control crt-form-control"
													placeholder="Premium"
													type="text"
													[(ngModel)]="p.premium"
													[ngModelOptions]="{ standalone: true }"
													currencyMask
													disabled
												/>
												<i>$</i>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="form-row">
								<div class="col-12 col-lg-12">
									<div class="form-row mb-2">
										<div class="col-0 col-lg-6"></div>
										<div
											class="
												col-12 col-lg-3
												text-lg-right
												d-lg-flex
												justify-content-end
												align-items-center
											"
										>
											<label class="font-weight-bold tap-text-primary"
												>Policy Fee</label
											>
										</div>
										<div class="col-12 col-lg-3">
											<div class="dollar-icon">
												<input
													class="form-control crt-form-control"
													placeholder="Premium"
													type="text"
													[(ngModel)]="insurance.policyFee"
													[ngModelOptions]="{ standalone: true }"
													currencyMask
													disabled
												/>
												<i>$</i>
											</div>
										</div>
									</div>
									<div class="form-row mb-2">
										<div class="col-0 col-lg-6"></div>
										<div
											class="
												col-12 col-lg-3
												text-lg-right
												d-lg-flex
												justify-content-end
												align-items-center
											"
										>
											<label class="font-weight-bold tap-text-primary"
												>Total Premium</label
											>
										</div>
										<div class="col-12 col-lg-3">
											<div class="dollar-icon">
												<input
													appCurrencyFormatter
													class="form-control crt-form-control"
													placeholder="Premium"
													type="text"
													value="{{ insurance?.sumOfLifeAssured || 0 }}"
													disabled
												/>
												<i>$</i>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								class="form-row mb-2"
								*ngIf="
									insurance?.policyDocuments &&
									insurance?.policyDocuments?.length > 0
								"
							>
								<label class="h6 font-weight-bold mb-3 tap-text-primary"
									>Policy Documents</label
								>
							</div>
							<div
								class="form-row"
								*ngFor="let d of insurance?.policyDocuments; let docIndex = index"
							>
								<div
									class="col-12 col-lg-auto word-break font-weight-bold mb-1"
								>
									<div
										id="downloadPolicyDocumentBtn_{{i}}_{{docIndex}}"
										class="d-flex cursor-pointer"
										(click)="downloadLink(d.referenceId)"
									>
										<i class="material-icons md-16 tap-text-color-h6 mr-2 mb-2"
											>description</i
										>
										<span>{{ d.value }}</span>
									</div>
								</div>
							</div>

							<div
								class="clearfix mb-2"
								[class.border-bottom]="insurance?.lifeAssured?.length > 1"
							></div>
						</div>

						<div class="form-row my-4">
							<div class="col-auto">
								<button
									id="addNewInsuranceBtn"
									type="button"
									class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
									(click)="addProduct()"
								>
									Add New +
								</button>
							</div>
						</div>
					</div>
				</div>

				<ng-container *ngIf="!!SOA.value && !!PresentedSOA?.value">
					<div class="clearfix mb-3">
						<label class="h6 font-weight-bold tap-text-primary"
							>Client Confirmation</label
						>
					</div>

					<ng-container *ngIf="PresentedSOA?.value === 'Presented SOA'">
						<div class="clearfix mb-3 font-weight-bold">
							{{ Signatures?.controls?.length > 1 ? "We" : "I" }} understand the
							statement of advice that was presented and would like to proceed
							with the recommended insurance applications.
						</div>
					</ng-container>

					<ng-container *ngIf="PresentedSOA?.value === 'Alternative Structure'">
						<div class="clearfix mb-3 font-weight-bold">
							{{ Signatures?.controls?.length > 1 ? "We" : "I" }} understand the
							Statement of Advice that was presented, however,
							{{ Signatures?.controls?.length > 1 ? "we" : "I" }} would like to
							proceed to applications based on the structure outlined above.
						</div>
					</ng-container>

					<div class="clearfix mb-3 note-details text-break">
						{{ clientAcceptanceWording$ | async }}
					</div>

					<!-- Signature -->
					<div class="clearfix mb-3 crt-signature-comp" formArrayName="signatures">
						<div
							class="
								form-row
								flex-column flex-lg-row
								align-items-end
								signature-image-container
							"
							*ngFor="let s of Signatures.controls; let i = index"
							[formGroupName]="i"
						>
							<div class="col col-lg-3 mb-2 name-field-padding"
								[tooltip]="s.get('fullName')?.value">
								<input
									type="text"
									class="form-control crt-form-control"
									id="fullNameId_{{i}}"
									formControlName="fullName"
									placeholder="Enter Full Name"
								/>
								<div class="font-weight-bold">Full Name</div>
							</div>
							<div class="col mb-2 date-field-padding">
								<app-date-input
									class="crt-form-control-field"
									#date
									ngProjectAs="input"
									textboxClass="theme-crm-field-disabled"
									placeholder="Enter date  dd/mm/yyyy"
									formControlName="date"
									textboxId="dateId_{{i}}"
								>
								</app-date-input>
								<div class="font-weight-bold">Date</div>
							</div>
							<div class="col mb-2">
								<div
									class="
										clearfix
										border-bottom
										d-flex
										justify-content-end
										align-items-end
										w-100
									"
								>
									<div class="flex-fill align-self-start text-center">
										<img
											*ngIf="!!s.get('signature')?.value"
											[src]="s.get('signature')?.value"
											alt="Signature"
											class="img-fluid"
										/>
									</div>
									<button
										id="signatureBtn_{{i}}"
										class="icon-btn h-auto"
										title="Sign"
										(click)="sign(i)"
									>
										<i
											class="fas fa-file-signature fa-14 tap-text-color-h6"
										></i>
									</button>
								</div>
								<div class="font-weight-bold">Signature</div>
							</div>
						</div>
					</div>
				</ng-container>
			</form>
		</ng-container>
	</div>
</div>
