export let PrimaryCustomerIndividual = [
  'First Name',
  'Last Name',
  'Mailing Address 1',
  'City',
  'Postcode',
  'Gender',
  'Date of Birth',
  'Mobile',
  'Email',
  'Adviser'
];
