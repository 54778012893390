<button id="hideModalButton" class="tap-btn tap-btn-outline modal-btn-close" (click)="bsModalRef.hide()">
	<span class="material-icons"> close </span>
</button>
<div>
	<div class="modal-header">
		<h5 class="modal-title">
			{{ header }}
		</h5>
	</div>
	<div class="modal-body">
		<form novalidate [formGroup]="form">
			<div class="form-group">
				<div class="container-fluid">
					<div class="form-group form-inline row align-items-center" *ngIf="!selectedContactType">
						<div class="col-md-12 py-3 d-flex justify-content-center">
							<label>This contact is not in the database. Would you like to add them?</label>
						</div>
						<div class="col-md-12 d-flex justify-content-center pb-3">
							<span class="custom-dropdown w-50">
								<select class="form-control" formControlName="contactType" aria-placeholder="Select contact type">
									<option value=""></option>
									<option *ngFor="let c of contactTypes" [value]="c">{{ c }}</option>
								</select>
							</span>
						</div>
					</div>

					<div class="form-group form-inline row" *ngIf="selectedContactType === 'Client'">
						<!-- Client -->
						<div class="col-md-2 py-1">
							First name
							<span class="tap-required" *ngIf="firstName?.invalid">*</span>
						</div>
						<div class="col-md-10 py-1">
							<input id="firstName" type="text" class="form-control" formControlName="firstName" />
						</div>

						<div class="col-md-2 py-1">
							Last name
							<span class="tap-required" *ngIf="lastName?.invalid">*</span>
						</div>
						<div class="col-md-10 py-1">
							<input id="lastName" type="text" class="form-control" formControlName="lastName" />
						</div>

						<div class="col-md-2 py-1">Mobile</div>
						<div class="col-md-10 py-1">
							<input id="mobile" type="text" class="form-control" formControlName="mobile" />
						</div>

						<div class="col-md-2 py-1">Email</div>
						<div class="col-md-10 py-1">
							<input id="email" type="text" class="form-control" formControlName="email" />
						</div>

						<div class="col-md-2 py-1">Date of Birth</div>
						<div class="col-md-10 py-1 d-flex">
							<app-date-input
								textboxId="dateOfBirth"
								class="removed-col"
								textboxClass="theme-crm-field-disabled"
								[max]="maxDate"
								formControlName="dateOfBirth"
							></app-date-input>
						</div>

						<div class="col-md-2 py-1">Physical Address</div>
						<div class="col-md-10 py-1">
							<app-address-input
								inputId="physicalAddress"
								ngProjectAs="input"
								formControlName="physicalAddress"
								textboxClass="theme-crm-field-disabled"
							></app-address-input>
						</div>

						<ng-container *ngIf="searchMode !== 'ClientsInvolved'">
							<div class="col-md-2 py-1">
								Adviser
								<span class="tap-required" *ngIf="adviser?.invalid">*</span>
							</div>
							<div class="col-md-10 py-1">
								<span class="custom-dropdown w-100">
									<select id="adviser" class="form-control btn" formControlName="adviser">
										<option value=""></option>
										<option *ngFor="let d of advisers" [value]="d.value">{{ d.display }}</option>
									</select>
								</span>
							</div>
						</ng-container>

						<div class="col-md-2 py-1">Add a note</div>
						<div class="col-md-10 py-1">
							<textarea
								maxlength="250"
								class="form-control"
								name="notes"
								id="note"
								cols="30"
								rows="6"
								formControlName="notes"
							></textarea>
						</div>
					</div>

					<div class="form-group form-inline row" *ngIf="selectedContactType === 'Business'">
						<!-- Business -->
						<div class="col-md-2 py-1">
							Business name
							<span class="tap-required" *ngIf="businessName?.invalid">*</span>
						</div>
						<div class="col-md-10 py-1">
							<input id="businessName" type="text" class="form-control" formControlName="businessName" />
						</div>

						<div class="col-md-2 py-1">Work Phone</div>
						<div class="col-md-10 py-1">
							<input id="work" type="text" class="form-control" formControlName="work" />
						</div>

						<div class="col-md-2 py-1">Physical Address</div>
						<div class="col-md-10 py-1">
							<app-address-input
								inputId="physicalAddress"
								ngProjectAs="input"
								formControlName="physicalAddress"
								textboxClass="theme-crm-field-disabled"
							></app-address-input>
						</div>

						<div class="col-md-2 py-1">
							Adviser
							<span class="tap-required" *ngIf="adviser?.invalid">*</span>
						</div>
						<div class="col-md-10 py-1">
							<span class="custom-dropdown w-100">
								<select id="adviser" class="form-control btn" formControlName="adviser">
									<option value=""></option>
									<option *ngFor="let d of advisers" [value]="d.value">{{ d.display }}</option>
								</select>
							</span>
						</div>

						<div class="col-md-2 py-1">Add a note</div>
						<div class="col-md-10 py-1">
							<textarea
								maxlength="250"
								class="form-control"
								name="notes"
								id="notes"
								cols="30"
								rows="6"
								formControlName="notes"
							></textarea>
						</div>
					</div>

					<div class="form-group form-inline row" *ngIf="selectedContactType === 'Trust'">
						<!-- Trust -->
						<div class="col-md-2 py-1">
							Trust name
							<span class="tap-required" *ngIf="trustName?.invalid">*</span>
						</div>
						<div class="col-md-10 py-1">
							<input id="trustName" type="text" class="form-control" formControlName="trustName" />
						</div>

						<div class="col-md-2 py-1">
							Trust type
							<span class="tap-required" *ngIf="trustType?.invalid">*</span>
						</div>
						<div class="col-md-10 py-1">
							<span class="custom-dropdown w-100">
								<select id="trustType" formControlName="trustType" class="form-control theme-crm-field-disabled" tabindex="57">
									<option value=""></option>
									<option *ngFor="let d of trustTypes" [value]="d.value">{{ d.display }}</option>
								</select>
							</span>
						</div>

						<ng-container *ngIf="searchMode !== 'ClientsInvolved'">
							<div class="col-md-2">
								Client
								<span class="tap-required" *ngIf="customer?.invalid">*</span>
							</div>
							<div class="col-md-10 py-1">
								<app-client-search-control
									textboxId="customer"
									ngProjectAs="input"
									searchMode="PrimaryCustomerIndividual"
									textboxClass="theme-crm-field-disabled"
									formControlName="customer"
								></app-client-search-control>
							</div>
						</ng-container>

						<div class="col-md-2 py-1">Add a note</div>
						<div class="col-md-10 py-1">
							<textarea
								maxlength="250"
								class="form-control"
								name="notes"
								id="note"
								cols="30"
								rows="6"
								formControlName="notes"
							></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="justify-content-end modal-footer">
				<ng-container *ngIf="!isSaving; else loader">
					<ng-container *ngIf="!selectedContactType">
						<button id="addButton" type="button" class="tap-btn tap-btn--default" (click)="add()">Add</button>
					</ng-container>
					<ng-container *ngIf="selectedContactType">
						<button id="saveButton" type="button" class="tap-btn tap-btn--default" [disabled]="form?.invalid" (click)="save(form.value)">
							Save
						</button>
					</ng-container>
					<button id="declineButton" type="button" class="tap-btn tap-btn--default" data-dismiss="modal" (click)="decline()">
						Cancel
					</button>
				</ng-container>
				<ng-template #loader>
					<app-manual-loader-mini [isLoading]="isSaving"></app-manual-loader-mini>
				</ng-template>
			</div>
		</form>
	</div>
</div>
