import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MortgageSettingsComponent } from './mortgage-settings.component';
import { MortgageSettingsResolver } from './mortgage-settings.resolver';
import { MortgageSettingsRoutingModule } from './mortgage-settings-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeclarationTemplateSettingsService } from './declaration-settings/declaration-template-settings/state/declaration-template-settings.service';
import { ScopeOfServiceSettingsComponent } from './scope-of-service-settings/scope-of-service-settings.component';
import { ScopeOfServiceService } from './scope-of-service-settings/state/scope-of-service.service';
import { MortgageSettingsStore } from './state/mortgage-settings.store';
import { MortgageSettingsQuery } from './state/mortgage-settings.query';
import { MergeTagsCrtSettingsService } from './merge-tags/state/merge-tags-crt-settings.service';
import { ScopeOfServiceEmailSettingsComponent } from './scope-of-service-settings/scope-of-service-email-settings/scope-of-service-email-settings.component';
import { ScopeOfServiceSettingsService } from './scope-of-service-settings/state/scope-of-service-settings.service';
import { SosTemplateSettingsService } from './scope-of-service-settings/sos-template-settings/state/sos-template-settings.service';
import { SosServiceSettingsComponent } from './scope-of-service-settings/sos-service-settings/sos-service-settings.component';
import { DeclarationSettingsComponent } from '../mortgage-settings/declaration-settings/declaration-settings.component';
import { DeclarationTemplateSettingsComponent } from '../mortgage-settings/declaration-settings/declaration-template-settings/declaration-template-settings.component';
import { DeclarationEmailSettingsComponent } from '../mortgage-settings/declaration-settings/declaration-email-settings/declaration-email-settings.component';
import { IntroSettingsComponent } from './intro-settings/intro-settings.component';
import { MoatIntroSettingsService } from './intro-settings/state/intro-settings.service';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentListFormComponent } from './document-list/document-list-form/document-list-form.component';
import { DocumentListService } from './document-list/state/document-list.service';
import { SoaSettingsComponent } from './soa-settings/soa-settings.component';
import { MergeTagsComponent } from '../mortgage-settings/merge-tags/merge-tags.component';
import { MergeTagsWordingComponent } from '../mortgage-settings/soa-settings/merge-tags-wording/merge-tags-wording.component';
import { SoaDocumentTemplateComponent } from '../mortgage-settings/soa-settings/soa-document-template/soa-document-template.component';
import { SoaSettingsService } from './soa-settings/state/soa-settings.service';
import { ReviewApplicationSettingsComponent } from './review-application-settings/review-application-settings.component';
import { ReviewApplicationSettingsService } from './review-application-settings/state/review-application-settings.service';
import { DeclarationEmailSettingsService } from './declaration-settings/declaration-email-settings/state/declaration-email-settings.service';
import { ClientFactFindTemplateSettingsComponent } from './declaration-settings/client-fact-find-template-settings/client-fact-find-template-settings.component';
import { ClientFactFindSettingsService } from './declaration-settings/client-fact-find-template-settings/state/client-fact-find-template-settings.service';
import { ReviewApplicationEmailSettingsComponent } from '../mortgage-settings/review-application-settings/review-application-email-settings/review-application-email-settings.component';
import { ReviewApplicationEmailSettingsService } from '../mortgage-settings/review-application-settings/review-application-email-settings/state/review-application-email-settings.service';
import { SosTemplateSettingsComponent } from './scope-of-service-settings/sos-template-settings/sos-template-settings.component';
import { MoatSoaEmailSettingsComponent } from './soa-settings/moat-soa-email-settings/moat-soa-email-settings.component';
import { MoatSoaEmailSettingsService } from './soa-settings/moat-soa-email-settings/state/moat-soa-email-settings.service';
import { NoteSettingsComponent } from './review-application-settings/note-settings/note-settings.component';
import { NoteSettingsService } from './review-application-settings/note-settings/state/note-settings.service';
import { MortgageEndProcessComponent } from './mortgage-end-process/mortgage-end-process.component';
import { MortgageEndProcessEmailSettingsComponent } from './mortgage-end-process/mortgage-end-process-email-settings/mortgage-end-process-email-settings.component';
import { MortgageEndProcessSettingsService } from './mortgage-end-process/state/mortgage-end-process.service';

@NgModule({
	declarations: [
		MortgageSettingsComponent,
		IntroSettingsComponent,
		ScopeOfServiceSettingsComponent,
		ScopeOfServiceEmailSettingsComponent,
		SosServiceSettingsComponent,
		DeclarationSettingsComponent,
		DeclarationTemplateSettingsComponent,
		DeclarationEmailSettingsComponent,
		ReviewApplicationEmailSettingsComponent,
		DocumentListComponent,
		DocumentListFormComponent,
		SoaSettingsComponent,
		MergeTagsComponent,
		MergeTagsWordingComponent,
		SoaDocumentTemplateComponent,
		ReviewApplicationSettingsComponent,
		ClientFactFindTemplateSettingsComponent,
		SosTemplateSettingsComponent,
		MoatSoaEmailSettingsComponent,
		NoteSettingsComponent,
		MortgageEndProcessComponent,
		MortgageEndProcessEmailSettingsComponent
	],
	imports: [CommonModule, MortgageSettingsRoutingModule, SharedModule],
	providers: [
		MortgageSettingsResolver,
		MortgageSettingsStore,
		MortgageSettingsQuery,

		MergeTagsCrtSettingsService,
		MoatIntroSettingsService,
		DeclarationTemplateSettingsService,
		DeclarationEmailSettingsService,
		ReviewApplicationEmailSettingsService,
		ScopeOfServiceService,
		ScopeOfServiceSettingsService,
		SosTemplateSettingsService,
		MortgageSettingsStore,
		MortgageSettingsQuery,
		DocumentListService,
		SoaSettingsService,
		MergeTagsCrtSettingsService,
		ReviewApplicationSettingsService,
		ClientFactFindSettingsService,
		MoatSoaEmailSettingsService,
		MortgageEndProcessSettingsService,
		NoteSettingsService
	],
})
export class MortgageSettingsModule {}
