import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ClientAcceptanceSettingsState } from '../state/client-acceptance-settings.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

@Component({
  selector: 'app-client-acceptance-settings-form',
  templateUrl: './client-acceptance-settings-form.component.html',
})
export class ClientAcceptanceSettingsFormComponent implements OnInit {
  form: UntypedFormGroup;
  @Input() isLoading: boolean;
  @Input() clientAcceptance: ClientAcceptanceSettingsState;
  @Output() save = new EventEmitter();

  readonly enableSignedDocumentLabel = 'Enable Client Signed Document:';
  readonly clientAcceptanceWordingLabel = 'Client Acceptance Wording';

  loatV2_3QuickWins$ = this.businessConfigQuery.loatV2_3QuickWins$

  constructor(private fb: UntypedFormBuilder,
    private businessConfigQuery: BusinessConfigQuery)
   {
    this.buildForm();
  }

  ngOnInit(): void {
    this.prepData();
  }

  buildForm() {
    this.form = this.fb.group({
      clientAcceptanceWording: [null],
      enableClientSignedDocument: [true],
    });
  }

  prepData() {
    of(this.clientAcceptance)
    .pipe(take(1))
    .subscribe((data) => {
      if (data) {
        this.form.reset(data);
      }
    });
  }

  onSave() {
    const formValue = this.form.getRawValue();
    this.save.emit({...formValue})
  }
}
