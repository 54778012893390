import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/base/api.service';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { IncomeQuery } from './income.query';
import { CrtMortgageQuery } from '../../../state/crt-mortgage.query';
import { CrtMortgageService } from '../../../state/crt-mortgage.service';
import { CrtMortgageStore } from '../../../state/crt-mortgage.store';
import { catchError, map, tap } from 'rxjs/operators';
import { numUtil, objectUtil } from 'src/app/util/util';
import { combineLatest, of } from 'rxjs';
import { applyTransaction } from '@datorama/akita';
import {
	IncomeSourceState,
	MonthlyExpenseDetailsState,
	OtherIncomeObjState,
	OtherIncomeState,
	PreviousIncomeSourceDetails,
	PreviousIncomeSourceState,
	RentalIncomeObjState,
	RentalIncomeState,
} from '../../../../_shared/models/income.model';
import { complement, either, isNil, isEmpty } from 'ramda';
import {
	computeAnnualTaxable,
	computeMonthlyAfterTaxIncome,
	computeMonthlyTaxable,
} from '@modules/crm/crt-page/_shared/calculations/compute-taxable';
import { PropertyQuery } from '../../assets-and-liabilities/state/property/property.query';
import { AdviceProcessSectionCodes } from 'src/app/shared/models/advice-process/advice-process.model';
import {
	AdviceProcessPageCodes,
	MortgageAdviceProcessPageIds,
} from '../../../../../../../shared/models/advice-process/advice-process.model';
import { SidebarStatus } from '../../../../_shared/models/sidebar.model';
import { AllIncomeListState } from '@modules/crm/crt-page/_shared/models/income-source-details.model';

@Injectable({
	providedIn: 'root',
})
export class IncomeService extends CrtMortgageService {
	incomeSource$ = this.iQuery.incomeSource$;
	previousIncomeSource$ = this.iQuery.previousIncomeSource$;
	totalPreviousIncome$ = this.iQuery.totalPreviousIncome$;
	netRentalIncome$ = this.iQuery.netRentalIncome$;
	otherIncome$ = this.iQuery.otherIncome$;
	monthlyExpense$ = this.iQuery.monthlyExpense$;
	factFindComputation$ = this.iQuery.factFindComputation$;
	properties$ = this.propertyQuery.properties$;

	constructor(
		protected api: ApiService,
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: CrtMortgageStore,
		protected query: CrtMortgageQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
		protected iQuery: IncomeQuery,
		protected propertyQuery: PropertyQuery
	) {
		super(
			dropdownValueQuery,
			store,
			query,
			api,
			customerService,
			businessService
		);
	}

	getInfoByCRT(crtId: number) {
		const endpoint = `crt/${crtId}`;
		return this.api.get<any>(endpoint).pipe(
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => of([]))
		);
	}

	getIncomeSources(adviceProcessId: number, sectionCode: string = 'FII') {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = x
						?.map((item) => ({
							...item,
							adviceProcessId,
						}))
						?.map(objectUtil.mapPascalCaseToCamelCase);
					const state = x ? data : [];
					this.store.setIncomeSource(state);
					this.setOverallTotalGross(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addIncomeSource(incomeSource: IncomeSourceState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(incomeSource);
		body.AdviceProcessId = adviceProcessId;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().incomeSource,
						{
							cRTId: x,
							incomeEarner: incomeSource.incomeEarner,
							employment: incomeSource.employment,
							occupation: incomeSource.occupation,
							totalGrossIncome: incomeSource.totalGrossIncome,
							adviceProcessId,
							incomeType: incomeSource.incomeType,
						},
					] as IncomeSourceState[];

					this.store.setIncomeSource(data);
					this.setOverallTotalGross(data);
				})
			)
		);
	}

	deleteIncomeSource(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.incomeSource?.filter((y) => y.cRTId !== id);
					this.store.setIncomeSource(data);
					this.setOverallTotalGross(data);
				});
			})
		);
	}

	updateIncomeSource(incomeSource: IncomeSourceState) {
		const endpoint = `crt/${incomeSource.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(incomeSource);
		return this.api.put<IncomeSourceState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = this.query.getValue().incomeSource?.map((y) =>
						y.cRTId === incomeSource.cRTId
							? {
									incomeEarner: incomeSource.incomeEarner,
									employment: incomeSource.employment,
									occupation: incomeSource.occupation,
									totalGrossIncome: incomeSource.totalGrossIncome,
									adviceProcessId: incomeSource.adviceProcessId,
									cRTId: incomeSource.cRTId,
									incomeType: incomeSource.incomeType,
							  }
							: y
					) as IncomeSourceState[];
					this.store.setIncomeSource(data);
					this.setOverallTotalGross(data);
				})
			)
		);
	}

	// Previous Income
	getPreviousIncomeSources(
		adviceProcessId: number,
		sectionCode: string = AdviceProcessSectionCodes.PreviousIncome
	) {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = x
						.map((item: PreviousIncomeSourceDetails) => {
							return {
								...item,
								adviceProcessId,
							};
						})
						.map(objectUtil.mapPascalCaseToCamelCase);
					const state = x ? data : [];
					this.store.setPreviousIncomeSource(state);
				})
			),
			catchError(() => of([]))
		);
	}

	addPreviousIncomeSource(
		incomeSource: PreviousIncomeSourceState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(incomeSource);
		body.AdviceProcessId = adviceProcessId;
		body.SectionCode = AdviceProcessSectionCodes.PreviousIncome;
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const data = [
						...this.query.getValue().previousIncomeSource,
						{
							cRTId: x,
							incomeEarner: incomeSource.incomeEarner,
							employer: incomeSource.employer,
							previousOccupation: incomeSource.previousOccupation,
							periodOfEmployment: incomeSource.periodOfEmployment,
							adviceProcessId: incomeSource.adviceProcessId,
						},
					] as PreviousIncomeSourceState[];

					this.store.setPreviousIncomeSource(data);
				})
			)
		);
	}

	deletePreviousIncomeSource(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query
						.getValue()
						.previousIncomeSource.filter((y) => y.cRTId !== id);
					this.store.setPreviousIncomeSource(data);
				});
			})
		);
	}

	updatePreviousIncomeSource(incomeSource: PreviousIncomeSourceState) {
		const endpoint = `crt/${incomeSource.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(incomeSource);
		body.SectionCode = AdviceProcessSectionCodes.PreviousIncome;
		return this.api.put<PreviousIncomeSourceState>(endpoint, body).pipe(
			tap((x) => {
				applyTransaction(() => {
					const data = this.query.getValue().previousIncomeSource.map((y) =>
						y.cRTId === incomeSource.cRTId
							? {
									periodOfEmployment: incomeSource.periodOfEmployment,
									incomeEarner: incomeSource.incomeEarner,
									employer: incomeSource.employer,
									previousOccupation: incomeSource.previousOccupation,
									adviceProcessId: incomeSource.adviceProcessId,
									cRTId: incomeSource.cRTId,
							  }
							: y
					) as PreviousIncomeSourceState[];
					this.store.setPreviousIncomeSource(data);
				});
			})
		);
	}

	getRentalIncome(adviceProcessId: number, sectionCode: string = 'FIR') {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? x?.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
					this.store.setRentalIncome(state);
					this.setTotalNetRentalIncome(state, true);
				})
			),
			catchError(() => of([]))
		);
	}

	updateRentalIncome(netRentalIncome: RentalIncomeState) {
		const endpoint = `crt/${netRentalIncome.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(netRentalIncome);
		return this.api.put<RentalIncomeObjState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.rentalDetails?.netRentalIncomeList?.map((y) =>
							y.cRTId === netRentalIncome.cRTId
								? {
										status: 1,
										propertyAsset: netRentalIncome.propertyAsset,
										netRentalIncome: netRentalIncome.netRentalIncome,
										adviceProcessId: netRentalIncome.adviceProcessId,
										cRTId: netRentalIncome.cRTId,
										incomeType: netRentalIncome?.incomeType,
								  }
								: y
						) as RentalIncomeState[];
					const data = {
						netRentalIncomeList: updateIncome,
						totalNetRentalIncome:
							this.query.getValue().rentalDetails.totalNetRentalIncome,
					};
					this.store.setRentalIncome(data);
					this.setTotalNetRentalIncome(data);
				})
			)
		);
	}

	addRentalIncome(
		netRentalIncome: RentalIncomeState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(netRentalIncome);
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const netRentalList =
						this.query.getValue().rentalDetails?.netRentalIncomeList || [];
					const totalNetRental =
						+this.query.getValue().rentalDetails?.totalNetRentalIncome || 0;
					const updateIncome = [
						...netRentalList,
						{
							cRTId: x,
							status: 1,
							propertyAsset: netRentalIncome.propertyAsset,
							netRentalIncome: +netRentalIncome.netRentalIncome,
							adviceProcessId: netRentalIncome.adviceProcessId,
							incomeType: netRentalIncome?.incomeType,
						},
					] as RentalIncomeState[];
					const data = {
						netRentalIncomeList: updateIncome,
						totalNetRentalIncome:
							+netRentalIncome.netRentalIncome + +totalNetRental,
					};
					this.store.setRentalIncome(data);
					this.setTotalNetRentalIncome(data);
				})
			)
		);
	}

	deleteRentalIncome(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.rentalDetails.netRentalIncomeList?.filter((y) => y.cRTId !== id);
					const data = {
						netRentalIncomeList: updateIncome,
						totalNetRentalIncome:
							+this.query.getValue().rentalDetails.totalNetRentalIncome,
					};
					this.store.setRentalIncome(data);
					this.setTotalNetRentalIncome(data);
				});
			})
		);
	}

	getOtherIncome(adviceProcessId: number, sectionCode: string = 'FIO') {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? x?.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
					this.store.setOtherIncome(state);
					this.setTotalAnnualIncome(state, true);
				})
			),
			catchError(() => of([]))
		);
	}

	updateOtherIncome(otherIncome: OtherIncomeState) {
		const endpoint = `crt/${otherIncome.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(otherIncome);
		return this.api.put<OtherIncomeObjState>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.otherIncomeDetails?.otherIncome?.map((y) =>
							y.cRTId === otherIncome.cRTId
								? {
										status: 1,
										incomeType: otherIncome.incomeType,
										annualIncome: otherIncome.annualIncome,
										adviceProcessId: otherIncome.adviceProcessId,
										cRTId: otherIncome.cRTId,
										isNonTaxable: otherIncome.isNonTaxable
								  }
								: y
						) as OtherIncomeState[];
					const data = {
						otherIncome: updateIncome,
						totalAnnualIncome:
							+this.query.getValue().otherIncomeDetails.totalAnnualIncome,
					};
					this.store.setOtherIncome(data);
					this.setTotalAnnualIncome(data);
				})
			)
		);
	}

	addOtherIncome(otherIncome: OtherIncomeState, adviceProcessId?: number) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(otherIncome);
		return this.api.post3<number>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					const otherIncomeList =
						this.query.getValue().otherIncomeDetails?.otherIncome || [];
					const totalOtherIncome =
						+this.query.getValue().otherIncomeDetails?.totalAnnualIncome || 0;
					const updateIncome = [
						...otherIncomeList,
						{
							cRTId: x,
							status: 1,
							incomeType: otherIncome.incomeType,
							annualIncome: +otherIncome.annualIncome,
							adviceProcessId: otherIncome.adviceProcessId,
							isNonTaxable: otherIncome.isNonTaxable
						},
					] as OtherIncomeState[];
					const data = {
						otherIncome: updateIncome,
						totalAnnualIncome: +otherIncome.annualIncome + +totalOtherIncome,
					};
					this.store.setOtherIncome(data);
					this.setTotalAnnualIncome(data);
				})
			)
		);
	}

	deleteOtherIncome(id: number) {
		const endpoint = `crt/${id}`;
		return this.api.delete<any>(endpoint).pipe(
			tap(() => {
				applyTransaction(() => {
					const updateIncome = this.query
						.getValue()
						.otherIncomeDetails.otherIncome?.filter((y) => y.cRTId !== id);
					const data = {
						otherIncome: updateIncome,
						totalAnnualIncome:
							+this.query.getValue().otherIncomeDetails.totalAnnualIncome,
					};
					this.store.setOtherIncome(data);
					this.setTotalAnnualIncome(data);
				});
			})
		);
	}

	getMonthlyExpense(adviceProcessId: number, sectionCode: string = 'FIM') {
		const endpoint = `crt/fact-find/${adviceProcessId}/${sectionCode}`;
		return this.api.get<any>(endpoint).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(x)
						? x?.map(objectUtil.mapPascalCaseToCamelCase)[0]
						: [];
					this.store.setMonthlyExpense(state);
				})
			),
			catchError(() => of([]))
		);
	}

	updateMonthlyExpenseState(monthlyExpense: MonthlyExpenseDetailsState) {
		this.setHasFormChanges(true);
		this.store.setMonthlyExpense(monthlyExpense);
	}

	updateMonthlyExpense(monthlyExpense: MonthlyExpenseDetailsState) {
		const endpoint = `crt/${monthlyExpense.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(monthlyExpense);
		return this.api.put<MonthlyExpenseDetailsState[]>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setMonthlyExpense(monthlyExpense);
				})
			)
		);
	}

	addMonthlyExpense(
		monthlyExpense: MonthlyExpenseDetailsState,
		adviceProcessId?: number
	) {
		const endpoint = `crt`;
		const body = objectUtil.mapCamelCaseToPascalCase(monthlyExpense);
		return this.api.post3<MonthlyExpenseDetailsState[]>(endpoint, body).pipe(
			tap((x) =>
				applyTransaction(() => {
					this.store.setMonthlyExpense({ ...monthlyExpense, cRTId: +x });
				})
			)
		);
	}

	setOverallTotalGross(data) {
		let overallGross = 0;
		const factFindComputation = this.query.getValue().factFindComputation;
		data?.map((item: any) => {
			overallGross += +item.totalGrossIncome;
		});

		this.store.setFactFindComputation({
			...factFindComputation,
			overallTotalGrossIncome: +numUtil.formatToNumCurrency(overallGross),
		});
		setTimeout(() => {
			this.computeTaxable();
		}, 10);
	}

	setTotalNetRentalIncome(data, isGet: boolean = false) {
		let totalNetRental = 0;
		const factFindComputation = this.query.getValue().factFindComputation;
		if (isGet) {
			totalNetRental = data.totalNetRentalIncome;
		} else {
			data.netRentalIncomeList?.map((item: any) => {
				totalNetRental += +item.netRentalIncome;
			});
		}
		this.store.setFactFindComputation({
			...factFindComputation,
			totalNetRentalIncome: +numUtil.formatToNumCurrency(totalNetRental),
		});
		setTimeout(() => {
			this.computeTaxable();
		}, 10);
	}

	setTotalAnnualIncome(data, isGet: boolean = false) {
		let totalAnnual = 0; // excludes non taxable
		let totalAnnualAll = 0; // includes taxable
		const factFindComputation = this.query.getValue().factFindComputation;

		data.otherIncome?.map((item: any) => {
			if (!item.isNonTaxable) {
				totalAnnual += +item.annualIncome;
			}

			totalAnnualAll += +item.annualIncome;
		});

		this.store.setFactFindComputation({
			...factFindComputation,
			totalAnnualIncome: +numUtil.formatToNumCurrency(totalAnnual),
			totalAnnualAllIncome: +numUtil.formatToNumCurrency(totalAnnualAll),
		});
		setTimeout(() => {
			this.computeTaxable();
		}, 10);
	}

	computeTaxable(returnOnly: boolean = false) {
		const factFind = this.query.getValue().factFindComputation;
		let annualTaxable = 0;
		let monthlyTaxable = 0;
		let monthlyAfterTax = 0;
		if (factFind) {
			annualTaxable = computeAnnualTaxable(factFind);
			monthlyTaxable = computeMonthlyTaxable(+annualTaxable);
			monthlyAfterTax = computeMonthlyAfterTaxIncome(
				this.query.getValue().incomeSource || [],
				{
					...factFind,
					annualTaxableJointIncome: +annualTaxable,
					monthlyTaxableJointIncome: +monthlyTaxable,
				},
				this.getIncomeLists()
			);

			const taxables = {
				annualTaxableJointIncome: +annualTaxable,
				monthlyTaxableJointIncome: +monthlyTaxable,
				monthlyAfterTaxIncome: +monthlyAfterTax,
			};

			if (returnOnly) {
				return { ...factFind, ...taxables };
			} else {
				this.store.setFactFindComputation({ ...factFind, ...taxables });
			}
		}
	}

	getIncomeLists() {
		const incomeSource = this.query.getValue().incomeSource || [];
		const rentalDetails = this.query.getValue().rentalDetails;
		const otherIncomeDetails = this.query.getValue().otherIncomeDetails;
		const rentalList = rentalDetails?.netRentalIncomeList || [];
		const otherList = otherIncomeDetails?.otherIncome || [];
		const properties = this.propertyQuery.getAll() || [];

		return {
			incomeSource,
			rentalList,
			otherList,
			properties,
		} as AllIncomeListState;
	}

	setTabColor() {
		return combineLatest([
			this.query.adviceProcess$,
			this.incomeSource$,
			this.query.mortApPageStarted$,
			this.query.mortApPageCompleted$,
		]).pipe(
			tap(([ap, income, pageStarted]) => {
				let status = SidebarStatus.Unopened;
				let warning = null;

				if (
					pageStarted?.includes(AdviceProcessPageCodes.Income) ||
					(income?.length > 0 && income?.every((x) => +x?.totalGrossIncome < 1))
				) {
					status = SidebarStatus.Incomplete;
					warning = 'Please add at least one Income Source.';
				}

				if (
					income?.length > 0 &&
					income?.some((x) => +x?.totalGrossIncome > 0)
				) {
					status = SidebarStatus.Completed;
				}

				this.setSideSidebarStatus(
					MortgageAdviceProcessPageIds.Income,
					false,
					status,
					warning
				);
			})
		);
	}
}
