import { Moment } from 'moment';
import MomentUtil from '../../../../util/moment.util';
import { util } from '../../../../core/util/util.service';
import { PrimaryCustomerCompanyState } from './business.model';
import { objectUtil } from '../../../../util/util';
import { SasReference } from '@shared/models/client-profile/primary-client/primary-client.model';
import { BLStaff } from '@domain/bl-staff/bl-staff.model';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';

export class BusinessMapper {
	/** Mapping for view */
	static mapToView(
		data: PrimaryCustomerCompanyState
	): PrimaryCustomerCompanyState {
		return {
			...data,
			adviser: data.adviser?.toString(),
			lastReview: this.formatDateToMoment(data.lastReview),
			nextReview: this.formatDateToMoment(data.nextReview),
			altAdviser:
				data.altAdviser && typeof data.altAdviser === 'string'
					? typeof JSON.parse(data.altAdviser) === 'object' &&
					  JSON.parse(data.altAdviser).length > 0
						? JSON.parse(data.altAdviser)?.map(String)
						: []
					: null,
			primaryContact: data.primaryContact
				? objectUtil.mapCamelCaseToPascalCase(data.primaryContact)
				: null,
			leadPending: util.toBool(data.leadPending as string),
		};
	}

	/** Mapping for view */
	static mapToUpsert(
		data: PrimaryCustomerCompanyState
	): PrimaryCustomerCompanyState {
		return {
			...data,
			adviser: +data.adviser,
			lastReview: this.formatDateToServerDate(data.lastReview),
			nextReview: this.formatDateToServerDate(data.nextReview),
			altAdviser:
				data.altAdviser && Array.isArray(data.altAdviser)
					? JSON.stringify(data.altAdviser)
					: null,
			primaryContact:
				data.primaryContact &&
				(+data.primaryContact.CustomerId > 0 ||
					+data.primaryContact.customerId > 0)
					? objectUtil.mapPascalCaseToCamelCase(data.primaryContact)
					: null,
			leadPending: util.toBool(data.leadPending as string),
			isActive: 1,
			saveAnyway:false
		};
	}

	/**
	 * Format date to string YYYY-MM-DD
	 * @param date string or Moment date
	 */
	static formatDateToServerDate(date: string | Moment): string {
		return typeof date === 'string'
			? MomentUtil.formatToServerDate(util.DateStringToMomentLoose(date))
			: MomentUtil.formatToServerDate(date);
	}

	/**
	 * Format date to Moment
	 * @param date string or Moment date
	 */
	static formatDateToMoment(date: string | Moment): Moment {
		return typeof date === 'string'
			? util.DateStringToMomentLoose(date)
			: util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
	}

	/**
	 * Adviser Rework Mappers
	 */
	static mapAdviserReworkServicesView(data: PrimaryCustomerCompanyState) {
		const result = SasReference?.reduce((a, c) => {
			if (!!data?.[c?.id]) {
				const ifExists = a?.find((x) => +x?.adviserId === +data?.[c?.id]);
				if (ifExists) {
					return a?.map((x) => {
						if (+x?.adviserId === +data?.[c?.id]) {
							return {
								adviserId: +x?.adviserId,
								services: [...(x?.services || []), c?.display],
							};
						} else {
							return x;
						}
					});
				} else {
					return [...a, { adviserId: data?.[c?.id], services: [c?.display] }];
				}
			}
			return a;
		}, []);
		return result;
	}

	static mapAdviserReworkServicesUpsert(adviserServices) {
		const getAdviser = (id) => {
			const serviceName = SasReference?.find((s) => s?.id === id)?.display;
			const adviser = adviserServices?.find(
				(x) => !!x?.services?.includes(serviceName)
			)?.adviserId;
			return !!adviser ? +adviser : null;
		};

		return {
			lRAdviser: getAdviser('lRAdviser'),
			mortgageAdviser: getAdviser('mortgageAdviser'),
			fGAdviser: getAdviser('fGAdviser'),
			kSAdviser: getAdviser('kSAdviser'),
			investmentAdviser: getAdviser('investmentAdviser'),
			gIAdviser: getAdviser('gIAdviser'),
		};
	}

	static getAdviserReworkChoices(
		adviserId: number,
		allStaff: BLStaff[]
	): ViewDisplayValue[] {
		const staffDetails = allStaff?.find((x) => +x?.StaffID === adviserId);
		const adviserServices = staffDetails?.StaffSettings?.AdviserServices;
		const adviserSettings = !!staffDetails
			? (!!staffDetails?.Services
				? JSON.parse(staffDetails?.Services?.toString())
				: [])
			: [];
			
		if (!adviserServices) {
			return [];
		}
		const availableServices = JSON.parse(adviserServices || '[]') || [];
		return availableServices
			?.map((service) => {
				return {
					display: service,
					value: service,
				};
			})
			?.filter((x, _i, _arr) => {
				// TAPNZ-13977 Group Insurance - only shows if L&R turned on for the
				const a = !(
					x?.value === 'Group Insurance' &&
					adviserSettings?.find((a) => a?.value === 'LR')
				);
				return a;
			});
	}
}
