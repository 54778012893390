import { ProgressStatus } from './progress';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-progress-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-popup.component.html',
  styleUrls: ['./progress-popup.component.scss']
})
export class ProgressPopupComponent {

  @Input() reportLabel;
  @Input() percentage = 0;
  @Input() isError = false;
  @Input() title = "";

  @Output() onCancel = new EventEmitter();
  @Output() onClosePopup = new EventEmitter();

  public isExpanded = true;


  toggleMode() {
		this.isExpanded = !this.isExpanded;
	}

  cancel() {
    const status = '';

		if (status && status === ProgressStatus.COMPLETE) {
			this.closePopup();
			return;
		}

    this.onCancel.emit();
  }

  closePopup() {
		// this.exportsService.stopPolling();
		this.onClosePopup.emit();
		// this.exportsService.resetExport();
	}
}
