import { TopupRequirementDetails, TopupRequirementsDetailsState } from './topup-requirements.model';
import { util } from '../../../../../../../../../util/util'
import { AdviceProcessSectionCodes } from '../../../../../../../../../shared/models/advice-process/advice-process.model';

export class TopupRequirementsMapper {

  public static mapToView(data: TopupRequirementsDetailsState): TopupRequirementsDetailsState {
    return {
      ...data,
    }
  }

  public static mapToUpsert(data: TopupRequirementsDetailsState): TopupRequirementsDetailsState {
    return {
      ...data,
    }
  }

  public static parseData(data) {
    return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
  }

  public static mapToTopupDetails = (model: TopupRequirementsDetailsState): TopupRequirementDetails => {
		return {
			CRTId: model?.cRTId ?? null,
			AdviceProcessId: model.adviceProcessId,
      ParentCRTId: model.parentCRTId,
			SectionCode: AdviceProcessSectionCodes.TopupRequirements,
			TopupRequirement: model.topupRequirement,
      TopupRequirementValue: +model.topupRequirementValue,
      Other: model.other ?? null
		};
	};
}
