<div class="p-0">
  <div class="clearfix">
    <h4 class="px-3 pt-3 pb-3 mb-0 font-weight-bolder tap-text-primary">
      <span class="tap--border-secondary border-bottom pb-2"
        >Column Selection</span
      >
    </h4>
    <div class="wrapper clearfix">
      <div class="column-settings px-3 pt-2 pb-1">
        <h5 class="m-0 font-weight-bold">Visible</h5>
      </div>
      <div class="visible-columns px-3">
        <bs-sortable
          [(ngModel)]="visibleColumns"
          fieldName="name"
          [itemTemplate]="visibleItem"
          itemClass="sortable-item py-1"
          itemActiveClass="sortable-item-active"
          placeholderItem="Drag here"
          placeholderClass="placeholder-drag text-muted"
          wrapperClass="visible-sortable-wrapper py-2"
        >
        </bs-sortable>
      </div>
      <div class="column-settings px-3 pt-3 pb-1 not-visible-columns-title">
        <h5 class="m-0 font-weight-bold">Not Visible</h5>
      </div>
      <div class="not-visible-columns px-3">
        <bs-sortable
          [(ngModel)]="hiddenColumns"
          fieldName="name"
          [itemTemplate]="hiddenItem"
          itemClass="sortable-item py-1"
          itemActiveClass="sortable-item-active"
          placeholderItem="Drag here"
          placeholderClass="placeholder-drag text-muted"
          wrapperClass="hidden-sortable-wrapper py-2"
        >
        </bs-sortable>
      </div>
    </div>
    <div class="d-flex px-3 py-3 flex-row-reverse align-items-center">
      <button type="button" class="tap-btn tap-btn--default" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="tap-btn tap-btn--default font-weight-bold btn-save-settings ml-2"
        (click)="save()"
        [disabled]="(isColumnSaving$ | async) || visibleColumns.length === 0"
      >
        Save
      </button>
    </div>
  </div>
</div>

<ng-template #visibleItem let-item="item" let-index="index">
  <span class="sortable-icon mr-2"
    ><i class="material-icons md-18">drag_indicator</i></span
  >
  <span
    class="sortable-name"
    [ngClass]="{ 'disabled-sortable': item.value === 'Business Name' }"
    >{{ item.value }}</span
  >
</ng-template>

<ng-template #hiddenItem let-item="item" let-index="index">
  <span class="sortable-icon mr-2"
    ><i class="material-icons md-18">drag_indicator</i></span
  >
  <span
    class="sortable-name"
    [ngClass]="{ 'disabled-sortable': item.value === 'Business Name' }"
    >{{ item.value }}</span
  >
</ng-template>
