export interface ApplicationDocument {
  adviceProcessId: number,
  sectionCode: string,
  cRTId: number,
  parentCRTId: number,
  document: string,
  documentId: number,
  combine: number | boolean,
  notRequired: number,
  order: number,
  fromDocumentListSettings: boolean,
  fileName?: string
	show?: boolean;
  isMultiple?: boolean;
}

export enum documentFileName {
	ApplicationSummary = 'Application Summary'
};

const documents = [
	'Deed of Acknowledgement of Debt',
	'Financial Declaration',
	'Gifting Letter',
	'Boarder Confirmation',
];

export const bankFiles = {
	ANZ: 'ANZ New Application Declaration',
	WestPac: 'Westpac Responsible Lending Declaration',
};

export const downloadable = {
	bankFiles,
	bankFileList: Object.values(bankFiles)?.map((x) => x?.toString()),
	pdfList: documents
}
