export enum TapIcons {
  Activities='activities',
  Add='add',
  AdviceProcesses='advice-processes',
  ArrowLeft='arrow-left',
  ArrowRight='arrow-right',
  Business='business',
  Businesses='businesses',
  BusinessLead='business-lead',
  Calender='calender',
  CaretDown='caret-down',
  CaretLeft='caret-left',
  CaretRight='caret-right',
  CaretUp='caret-up',
  Circle='circle',
  CircleCheck='circle-check',
  CircleSolid='circle-solid',
  Client='client',
  Clog='clog',
  Close='close',
  Dashboard='dashboard',
  Documents='documents',
  Download='download',
  Drag='drag',
  Duplicate='duplicate',
  Edit='edit',
  Email='email',
  EmailTime='email-time',
  Enter='enter',
  Equal='equal',
  FG='f&g',
  IconsSprite='Icons-sprite',
  Investment='investment',
  LR='l&r',
  Leads='leads',
  Lighting='lighting',
  Link='link',
  Logout='logout',
  Menu='menu',
  Mortgage='mortgage',
  NotEqual='not-equal',
  Notifications='notifications',
  Number='number',
  People='people',
  Percent='percent',
  PipelineAdviceProcess='pipeline-ap',
  PipelineFG='pipeline-fg',
  PipelineInvestment='pipeline-i',
  PipelineKiwisaver='pipeline-k',
  PipelineLR='pipeline-lr',
  PipelineMortgage='pipeline-m',
  pipelines='pipelines',
  pipelinesLetter='pipelines-letter',
  Policies='policies',
  Products='products',
  Refer='refer',
  Save='save',
  Search='search',
  Settings='settings',
  Star='star',
  Sun='sun',
  Time='time',
  Tooltips='tooltips',
  Trash='trash',
  Uploaded='uploaded'
}