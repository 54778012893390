<form
	[formGroup]="form"
	class="form long-term-goals-form"
	[class.submitted]="submitted"
>
	<div class="form-row mb-2 label-background">
		<div class="col-7 mt-1 mt-lg-0 mb-lg-3">
			<label for="" class="font-weight-bold label-font tap-text-primary"
				>Long term goals</label
			>
		</div>
		<div class="col-5 text-right iconShow">
			<button
				id="collapseLongTermGoalsBtn"
				type="button"
				class="btn-toggle bg-white collapse-btn border-0 collapse-background"
			>
				<span *ngIf="elseMinus; else elsePlus">
					<i (click)="collapseMore()" class="material-icons md-20 pt-1"
						>expand_more</i
					>
				</span>
				<ng-template #elsePlus>
					<span>
						<i (click)="collapseLess()" class="material-icons md-20 pt-1"
							>expand_less</i
						>
					</span>
				</ng-template>
			</button>
		</div>
	</div>

	<ng-template #showLoader>
		<span class="mb-4 d-block">
			<app-manual-loader-mini
				[isLoading]="isListLoading"
			></app-manual-loader-mini>
		</span>
	</ng-template>

	<div formArrayName="detailsList" *ngIf="!isListLoading; else showLoader">
		<div class="collapse expandable" id="collapse">
			<div
				class="d-flex align-items-center mb-2"
				*ngFor="
					let item of DetailsList.controls;
					let i = index;
					let last = last
				"
				[formGroupName]="i"
				[class.border-bottom]="!last"
			>
				<div
					class="flex-fill mb-2"
					*ngIf="item.get('dropdown').value !== 'Other'"
				>
					<span
						class="w-100"
						[class.crt-custom-dropdown]="!isAdviceProcessEnded"
					>
						<select
							id="longTermGoalsId_{{ i }}"
							formControlName="dropdown"
							class="form-control crt-form-control theme-crm-field-disabled"
							[class.invalid-control]="!(isValid$ | async)"
							(change)="selectGoal(i)"
						>
							<option *ngFor="let item of APCRTGL" [value]="item.value">
								{{ item.display }}
							</option>
						</select>
					</span>
				</div>
				<div
					class="flex-fill mb-2"
					*ngIf="item.get('dropdown').value === 'Other'"
				>
					<input
						id="othersId_{{ i }}"
						class="form-control crt-form-control"
						placeholder="Others"
						formControlName="value"
						(keyup)="onChange()"
						(focusout)="onFocusOut($event, item)"
						type="text"
					/>
				</div>

				<div class="">
					<ng-container *ngIf="!isAdviceProcessEnded">
						<button
							id="deleteLongTermGoalBtn_{{ i }}"
							type="button"
							class="icon-btn h-auto w-auto px-1"
							(click)="deleteGoal(i)"
						>
							<i class="material-icons md-16">close</i>
						</button>
					</ng-container>
				</div>
			</div>
			<div class="form-row my-4">
				<div class="col-8 col-lg-8">
					<ng-container *ngIf="!isAdviceProcessEnded">
						<button
							id="addLongTermGoalBtn"
							type="button"
							class="tap-btn tap-btn-outline tap-btn--shadow px-4 col-auto"
							(click)="addNewGoal()"
						>
							Add Goal +
						</button>
					</ng-container>
				</div>
				<div class="col-4 pt-1 col-lg-4 text-lg-left text-right"></div>
			</div>
			<!-- <div class="form-row line mb-3"></div> -->
		</div>
	</div>
</form>
