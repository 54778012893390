import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LRAState, LRAStore } from './lra.store';

/**
 * Query L&R Anniversary widget data
 */
@Injectable()
export class LRAQuery extends Query<LRAState> {
  /**
   * indicator if widget is loading.
   */
  isLoading$ = this.select(state => state.isLoading);
  /**
   * error message to show.
   */
  error$ = this.select(state => state.error);
  /**
   * Create L&R Anniversary query
   */
  data$ = this.select(state => state.LRAnniversaryList);

  /**
   * Create L&R Anniversary query
   */
  constructor(protected store: LRAStore) {
    super(store);
  }
}
