import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AdviserStat } from './state/adv.model';
import { BodyRow, HeaderRow } from '../../shared/simple-table/simple-table.model';
import { map, filter, takeUntil, startWith, combineLatest } from 'rxjs/operators';
import { DashboardQuery } from '../../state/dashboard.query';
import { AdviserStatsService } from './state/adv.service';
import { AdviserStatsQuery } from './state/adv.query';
import { numUtil } from '../../../../../util/util';
import { UntypedFormControl } from '@angular/forms';
import MomentUtil from '../../../../../util/moment.util';
import { DateRange } from '../../../../../shared/date-range/date-range.component';

@Component({
  selector: 'app-adv',
  templateUrl: './adv.component.html',
  styleUrls: ['./adv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvComponent implements OnInit, OnDestroy {
  public static widgetCode = 'adv';
  public static widgetName = 'Adviser Stats';
  public static sizeX = 800;
  public static sizeY = 250;
  public static minSizeX = 350;
  public static minSizeY = 250;

  widgetCode = AdvComponent.widgetCode;

  /**
   * destroy event observable. Is watched to know when to
   * unsubscribe subscribers. emits on ngOnDestroy.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * indicator if the widget is currently fetching data
   */
  isLoading$: Observable<boolean> = this.query.isLoading$;
  /**
   * Error message string. Show error if not empty.
   */
  error$ = this.query.error$;

  /**
   * Adviser Stats Data
   */
  data$: Observable<AdviserStat[]> = this.query.AdviserStatData$;

  /** form control for date range.
   * Should default to 1 week behind up to now.
   */
  dateControl: UntypedFormControl = new UntypedFormControl({
    min: MomentUtil.formatToServerDate(MomentUtil.createMomentNz().startOf('Q')),
    max: MomentUtil.formatToServerDate(MomentUtil.createMomentNz().endOf('Q'))
  } as DateRange);

  viewData$ = this.data$.pipe(
    map((data): BodyRow[] => {
      if (data === null) {
        return [];
      } else {
        return Object.keys(data)?.map<BodyRow>(key => {
          return [
            { text: `${data[key].Adviser}`, class: 'col' },
            { text: numUtil.formatToCurrency(data[key].Submitted), class: 'col' },
            { text: numUtil.formatToCurrency(data[key].Completed), class: 'col' },
            { text: numUtil.formatToCurrency(data[key].Lost), class: 'col' },
            { text: numUtil.formatToCount(data[key].KiwiSavers), class: 'col' }
          ];
        });
      }
    })
  );

  /** header for simple-table header object */
  header: HeaderRow = [
    { text: 'Adviser', class: 'col' },
    { text: 'Submitted API', class: 'col' },
    { text: 'Issued API', class: 'col' },
    { text: 'Cancelled API', class: 'col' },
    { text: 'KiwiSaver', class: 'col' }
  ];

  constructor(
    private dashboardQuery: DashboardQuery,
    private query: AdviserStatsQuery,
    private service: AdviserStatsService
  ) {}

  /**
   * On widget init, subscribe to adviserFilter and
   *   trigger fetching of annual api stats.
   *
   * Chart does not automatically resize on div resize(gridstack)
   *   so listen to resize event to trigger manual resize via refreshing
   *   of data.
   */
  ngOnInit() {
    this.dashboardQuery.adviserFilterWithoutInactive$
      .pipe(
        filter(x => x.length > 0),
        combineLatest(this.dateControl.valueChanges.pipe(startWith(this.dateControl.value))),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([advisers, dateRange]) => {
        this.service.GetAdviserStats({
          advisers,
          dateRangeMin: dateRange.min,
          dateRangeMax: dateRange.max
        });
      });
  }

  /**
   * Gets onDestroy$ to emit and unsubscribe.
   */
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
