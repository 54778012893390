import { StoreConfig, Store, } from '@datorama/akita';
import produce from 'immer';
import { BlanketNewBusinessGoalTrack } from './bnb.model';
import { Injectable } from "@angular/core";

/**
 * BNB Goal Track widget state model
 */
export interface BNBState {
	/**
	 * BNB Goal Track data
	 */
	GoalTrack: BlanketNewBusinessGoalTrack;
	/**
	 * error message. will show up when not empty
	 */
	error: string;
	/**
	 * indicator if widget is currently fetching
	 */
	isLoading: boolean;
}

/**
 * Create initial state store
 */
export function createInitialState(): BNBState {
	return {
		GoalTrack: {
			QuarterActual: 0,
			QuarterExpected: 0,
			YearActual: 0,
			YearExpected: 0,
			Quarter1: 0,
			Quarter2: 0,
			Quarter3: 0,
			Quarter4: 0
		},
		error: '',
		isLoading: false
	};
}

/**
 * BNB Goal track widget data store
 */
@Injectable()
@StoreConfig({
	name: 'bnbGoalTrack'
})
export class BNBStore extends Store<BNBState> {
	/**
	 * BNB Goal track store
	 */
	constructor() {
		super(createInitialState());
	}

	/**
	 * Set if widget is loading or not
	 * @param isLoading if widget is loading
	 */
	setLoading(isLoading: boolean): void {
		this.update(produce(draft => { draft.isLoading = isLoading; }));
	}
}
