import { util } from '@utilService';
import MomentUtil from '@momentUtil';
import { AdviceProcessSectionCodes } from '../../advice-process/advice-process.model';
import {
	KOATPeopleDetailsMapped,
	KOATPeopleDetailsState,
	LOATPeopleDetailsMapped,
	LOATPeopleDetailsState,
	MOATPeopleDetailsMapped,
	MOATPeopleDetailsState,
	ResidencyStatus,
} from './people-details.model';

export class PeopleMapper {
	/** Mapping for view */
	static mapToView(data: LOATPeopleDetailsState): LOATPeopleDetailsMapped {
		if (!data) {
			return { ...data };
		}
		const { age, ...others } = data;
		const val = {
			...others,
			dateOfBirth: util.isValidMoment(data.dateOfBirth)
				? data.dateOfBirth
				: MomentUtil.formatDateToMoment(data.dateOfBirth),
			gender: data.gender,
		};
		return val;
	}

	/**
	 * Map to update
	 */

	public static mapToUpsert(
		data: LOATPeopleDetailsState | MOATPeopleDetailsState | KOATPeopleDetailsState,
		personInfo: LOATPeopleDetailsState | MOATPeopleDetailsState | KOATPeopleDetailsState
	): LOATPeopleDetailsState | MOATPeopleDetailsState | KOATPeopleDetailsState {
		const { age, ...others } = data;
		return {
			...personInfo,
			...{
				...others,
				age,
				dateOfBirth: MomentUtil.formatDateToServerDate(data.dateOfBirth),
				manual: data.manual ? +data.manual : null,
				customerID: personInfo?.customerID,
				adviceProcessId: personInfo?.adviceProcessId,
				cRTId: personInfo?.cRTId,
				customerType: personInfo?.customerType,
				sectionCode: AdviceProcessSectionCodes.People,
				status: personInfo?.status,
				countryOfOrigin:
					data?.residencyStatus === ResidencyStatus.nzCitizen
						? ''
						: data?.countryOfOrigin,
			},
		};
	}
}

export class MOATPeopleMapper extends PeopleMapper {
	static mapToView(data: MOATPeopleDetailsState): MOATPeopleDetailsMapped {
		if (!data) {
			return { ...data };
		}
		const { age, ...others } = data;
		const val = {
			...others,
			dateOfBirth: data.dateOfBirth 
				? (util.isValidMoment(data.dateOfBirth)
					? data.dateOfBirth
					: MomentUtil.formatDateToMoment(data.dateOfBirth))
				: '',
			gender: data.gender,
			title: data.title || '',
			timeInResidencePhysicalAddress: data.timeInResidencePhysicalAddress || '',
			timeInResidencePreviousAddress: data.timeInResidencePreviousAddress || '',
		};
		return val;
	}
}

export class KOATPeopleMapper extends PeopleMapper {
	static mapToView(data: KOATPeopleDetailsState): KOATPeopleDetailsMapped {
		if (!data) {
			return { ...data };
		}
		const { age, ...others } = data;
		const val = {
			...others,
			dateOfBirth:
				data.dateOfBirth && util.isValidMoment(data.dateOfBirth)
					? data.dateOfBirth
					: data.dateOfBirth
					? MomentUtil.formatDateToMoment(data.dateOfBirth)
					: '',
			gender: data.gender || '',
			title: data.title || '',
			employment: data.employment || '',
			relationship: data.relationship || '',
			residencyStatus: data.residencyStatus || '',
			timeInResidencePhysicalAddress: data.timeInResidencePhysicalAddress || '',
			timeInResidencePreviousAddress: data.timeInResidencePreviousAddress || '',
		};
		return val;
	}
}
