<div class="app-user-container fluid-container">
  <div class="app-user-header">
    <h1 class="py-2 px-3 mb-0">{{ businessName$ | async }}</h1>
  </div>
  <div
    *ngIf="(coverPhoto$ | async); else coverHolder"
    class="app-banner-container px-0 col-12"
    [ngStyle]="{ 'background-image': 'url(' + (coverPhoto$ | async) + ')' }"
  ></div>
  <ng-template #coverHolder>
    <div class="app-banner-container px-0 col-12">{{ " " }}</div>
  </ng-template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-form
          [data]="viewBusiness$ | async"
          [isEdit]="isEdit"
          [isLoading]="isLoading"
          [hasPermission]="hasPermission$(['BVCRT']) | async"
					[clientAlterationRequestEnabled]="clientAlterationRequestEnabled$ | async"
          (saveEvent)="save($event)"
          (editEvent)="edit()"
          (cancelEvent)="cancel()"
        ></app-form>
      </div>
    </div>
  </div>
</div>
